"use strict";

import React from "react";
import ImportDataManipulationTable from "../dataImportWizardStep/import_data_manipulation_table";
import { can } from "../../../utils/ui_permissions";
import * as CommonUtils from "../../../../server/common/generic/common_utils";
import { CommonSorting } from "../../../../server/common/generic/common_sorting";

/**
 * @typedef {ImportDataManipulationTableProps} ImportDependencySelectionTableProps
 * @property securityAction {*}
 */

const DEFAULT_PROPS = {
  enableSingleSelection: true,
};

const COLUMN_SCHEMA = {
  CONTINUOUS_DATA: {
    "Key": 1,
    "Name": 2,
    "Parent": 3,
    "Unit Operation": 4
  },
  DEFAULT: {
    "Key": 1,
    "Name": 2
  }
};

/**
 * Extends {ImportDataManipulationTable} to also check the permissions for each attribute
 */
export class ImportDependencySelectionTable extends ImportDataManipulationTable {
  /**
   * Creates a new instance of the {ImportDependencySelectionTable} class
   * @param {ImportDependencySelectionTableProps} props
   */
  constructor(props) {
    super(props);

    this.shouldIncludeRow = this.shouldIncludeRow.bind(this);
  }

  isInitialFiltrationEnabled() {
    return true;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      const orderedUOList = this.props.orderedUOList || [];
      const nextOrderedUOList = nextProps.orderedUOList || [];

      return orderedUOList.length !== nextOrderedUOList.length;
    }

    return true;
  }

  /**
   * Just to show checkbox for selection, id and name columns
   */
  generateColumns() {
    const {importContinuousData} = this.props;
    const columns = super.generateColumns();
    const columnSchema = importContinuousData ? COLUMN_SCHEMA.CONTINUOUS_DATA : COLUMN_SCHEMA.DEFAULT;
    return columns
      .filter(column => columnSchema[column.title] || column.isCheckboxSelectionColumn)
      .sort((a, b) => {
        if (a.isCheckboxSelectionColumn) {
          return -1;
        } else if (b.isCheckboxSelectionColumn) {
          return 1;
        } else {
          return columnSchema[a.title] - columnSchema[b.title];
        }
      });
  }

  /**
   * Checks the specified {rowData} object to check whether the row should be disabled.
   * @param rowData {*} The data to be displayed in the row
   * @returns {boolean} Indicates whether to let the row disabled.
   */
  isRowDisabled(rowData) {
    const {securityAction} = this.props;
    const {typeCode} = rowData;
    let disabled = false;
    if (typeCode) {
      disabled = !can(securityAction, CommonUtils.getModelNameForTypeCode(typeCode));
    }
    return disabled;
  }

  /**
   * This method can be used to filter which rows should appear in the results
   * @param rowData {*} The value to be added in the row
   * @returns {boolean} Indicates whether or not the row should be included.
   */
  shouldIncludeRow(rowData) {
    // does not show attributes you don't have permission to see
    return !this.isRowDisabled(rowData);
  }

  /**
   * This applies proper sorting on records before they are shown on datatables
   * @param records
   */
  prepareRecordsForDisplay(records) {
    const {orderedUOList, modelName, importContinuousData} = this.props;

    if (orderedUOList) {
      if (importContinuousData || modelName !== "UnitOperation") {
        records = records.sort(CommonSorting.sortAttributesByParentAndTypeCodeAndSomeField(orderedUOList));
      } else {
        const uoIndexGetterFunc = (orderedUOList, attribute) => orderedUOList.indexOf(attribute);
        const typeCodeGetterFunc = ignore => "";
        const nameGetterFunc = ignore => "";
        records = records.sort(CommonSorting.sortAttributesByParentAndTypeCodeAndSomeField(orderedUOList, "unitOperation", "name", uoIndexGetterFunc, typeCodeGetterFunc, nameGetterFunc));
      }
    }

    return records;
  }
}

ImportDependencySelectionTable.defaultProps = Object.assign(
  {},
  ImportDataManipulationTable.defaultProps,
  DEFAULT_PROPS,
);
