"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BasePage from "../../base_page";
import CommonEditablesPageTitleBar from "../../widgets/pageTitleBar/common_editables_page_title_bar";
import ErrorBar from "../../widgets/bars/error_bar";
import UserListTable from "./user_list_table";
import UserDeletePopup from "../accountManagement/user_delete_popup";
import { can, generateTooltip } from "../../utils/ui_permissions";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import * as FailHandlers from "../../utils/fail_handlers";
import CommonSecurity from "../../../server/common/generic/common_security";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import {QBDLink} from "../../utils/withRouter";

/* This class shows the list of users. */

// i18next-extract-mark-ns-start users
class UserListPage extends BasePage {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.showAPIKeys = this.context.getParameterByName("showAPIKeys") === "true";
    this.loadData();

    super.componentDidMount();
  }

  loadData() {
    const {t} = this.props;
    document.title = this.showAPIKeys ? t("QbDVision API Keys") : t("QbDVision Users");

    UIUtils.setLoadingDisabled(true);

    this.setStateSafely({
      breadcrumb: {
        current: this.showAPIKeys ? t("API Keys") : t("Users"),
      },
      isLoading: true,
      showDeleteModal: false,
    });

    this.reload();
  }

  toggleUI() {
    this.showAPIKeys = !this.showAPIKeys;
    this.loadData();
  }

  getURLPrefix() {
    return this.showAPIKeys ? "apiKeys/" : "";
  }

  handleAdd() {
    window.location.href = UIUtils.getSecuredURL(this.getURLPrefix() + "viewEdit.html?operation=Add");
  }

  reload() {
    this.setStateSafely({instances: [], isLoading: true});
    UIUtils.secureAjaxGET("users/list" + (this.showAPIKeys ? "?showAPIKeys=true" : ""), undefined, true, FailHandlers.defaultStopLoadingFailFunction.bind(this))
      .done(this.handleReceiveDataFromServer);
  }

  handleReceiveDataFromServer(results) {
    // Uncomment for verbose logging
    // console.log("Received users list results: " + UIUtils.stringify(results));
    this.setStateSafely({instances: results, isLoading: false});
    // UIUtils.setLoadingDisabled(true);
  }

  handleShowDelete(result) {
    const fullName = result.firstName + " " + result.lastName;
    // Uncomment for verbose logging
    // console.log("Deleting " + fullName + " (" + result.id + ")");
    this.setStateSafely({showDeleteModal: true, deleteModalId: result.id, deleteModalName: fullName});
  }

  handleActualDelete() {
    UIUtils.setLoadingDisabled(false);
    // Uncomment for verbose logging
    // console.log("Deleting " + this.state.deleteModalId + "...");
    UIUtils.secureAjaxGET("users/delete/" + this.state.deleteModalId)
      .done(this.handleDeleteResponse);
    if (this.userDeletePopup) {
      $(this.userDeletePopup).modal("hide");
    }
  }

  handleDeleteResponse() {
    this.handleHideModal();
    this.reload();
  }

  handleHideModal() {
    this.setStateSafely({showDeleteModal: false});
  }

  getInfoTooltip() {
    return (
      <InfoTooltip id="infoAPI"
                   verbiage={<div>
                     An API key can be used to interact with QbDVision programmatically, allowing for other systems to
                     communicate with QbDVision. The OpenAPI documentation can be found
                     <a href="/restAPI"
                        rel="noopener noreferrer"
                        id="infoAPILink"
                     >here</a>.
                   </div>}
      />
    );
  }

  renderPage() {
    const {t} = this.props;

    return (
      <div>
        <CommonEditablesPageTitleBar name={this.showAPIKeys ? t("API Keys") : t("Users")} hasNoLinksInTitleBar={true} />
        <div className="container-spacer" />
        <div className="container">
          <ErrorBar />
          <div className="row row-white shadow">
            <div className="col-6">
              <div className="btn-group">
                <button className="btn btn-lg btn-primary" id="addButton"
                        style={{marginTop: "20px"}}
                        onClick={this.handleAdd}
                        title={t(generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.USER))}
                        disabled={!can(CommonSecurity.Actions.ADD, CommonSecurity.Types.USER)}
                >
                  {t("Add")}
                </button>
              </div>
            </div>
            <div className="col-6">
              <div className="text-right user-page-api-keys-margin-top">
                <QBDLink to={"/users/list.html?showAPIKeys=" + !this.showAPIKeys}
                         onClick={this.toggleUI}
                         rel="noopener noreferrer"
                         id="userShowAPIKeys"
                >
                  <FontAwesomeIcon icon={faChevronCircleRight} /> {this.showAPIKeys ? t("Users") : t("API Keys")}
                </QBDLink>
              </div>
            </div>
            <div className="col-12">
              <UserListTable users={this.state.instances}
                             showAPIKeys={this.showAPIKeys}
                             onShowDelete={this.handleShowDelete}
                             isLoading={this.isLoading()}
              />
            </div>
          </div>
        </div>
        {this.state.showDeleteModal ?
          <UserDeletePopup
            modalRef={popup => this.userDeletePopup = popup}
            fullName={this.state.deleteModalName}
            onHideModal={this.handleHideModal}
            onNukeEm={this.handleActualDelete}
            isAPIKey={this.showAPIKeys}
          />
          : ""}
      </div>
    );
  }
}

export default I18NWrapper.wrap(UserListPage, "users");
// i18next-extract-mark-ns-stop users
