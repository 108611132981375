"use strict";

import React from "react";
import * as styles from "./style/version_cell.module.scss";
import * as UIUtils from "../../../ui_utils";

const getCssStyle = (LastVersion) => {
  const currentState = LastVersion?.currentState;
  switch (currentState) {
    case UIUtils.VERSION_STATES.APPROVED:
      return `${styles["version-style"]} ${styles["approved"]}`;
    case UIUtils.VERSION_STATES.ARCHIVED:
      return `${styles["version-style"]} ${styles["archived"]}`;
    default:
      return `${styles["version-style"]}`;
  }
};

export const VersionCell = ({dataItem, className, style, dataIndex}) => {
  const {LastVersion} = dataItem;
  return (
    <td
      id={`versionField_${dataIndex}`}
      style={style}
      className={className}
    >
      {LastVersion ? <span className={getCssStyle(LastVersion)}>
        {LastVersion.majorVersion}.{LastVersion.minorVersion}
      </span> : ""
      }
    </td>
  );
};
