"use strict";

import React from "react";
import BaseDiffableTable from "../widgets/tables/base_diffable_table";
import ReactDOM from "react-dom";
import LinkAttachment from "../editor/widgets/link_attachment";
import TableButton from "../widgets/tables/table_button";
import * as UIUtils from "../ui_utils";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import { createHTMLForDocsLinksDiff, createHTMLForWholeTextDiff } from "../helpers/diff_helper";
import CommonURLs from "../../server/common/generic/common_urls";
import { getUserFullName } from "../../server/common/editables/common_users";

// i18next-extract-mark-ns-start batches

/**
 * This is responsible for rendering the batch file links set by the user while importing process capability links.
 */
class BatchLinksTable extends BaseDiffableTable {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    const {records, diffedVersion} = this.props;
    const nextDiffedVersion = nextProps.diffedVersion;

    return (!records
      && nextProps.records)
      || (records && nextProps.records && records.length !== nextProps.records.length)
      || diffedVersion !== nextDiffedVersion;
  }

  getColumns() {
    const {t} = this.props;

    return [
      this.generateFileLinkColumn(t("Import File"), "importFileData", "50%"),
      this.generateTextColumn("", "createdBy", "50%"),
      this.generateManageColumnDef(),
    ];
  }

  getTableInitializationOptions() {
    return {
      dom: "<t>",
      data: this.prepareRecordsForDisplay(this.props.records),
      columns: this.getColumns(),
      paging: false,
      stateSave: true,
    };
  }

  generateFileLinkColumn(title, prop, width) {
    return {
      title,
      width,
      containsHTML: true,
      data: result => result[`${prop}Name`],
      createdCell: (td, cellData, rowData) => {
        const dataPropToRender = this.props.isDiffingVersions ? `${prop}LinkDiffHTML` : prop;
        let dataToRender = rowData[dataPropToRender] || {};
        if (this.props.isDiffingVersions) {
          dataToRender = {
            linkDiffHTML: rowData[dataPropToRender]
          };
        }

        ReactDOM.render(
          <LinkAttachment name={cellData}
                          index={rowData.importId}
                          data={dataToRender}
                          showCaption={false}
                          disabled={false}
                          rowIsInEditMode={false}
                          isDiffingVersions={this.props.isDiffingVersions}
                          onFileDownload={this.props.onFileDownload}
          />, td);
      },
    };
  }

  /**
   * @param title {string}
   * @param prop {string}
   * @param [width] {boolean}
   * @returns {{containsHTML: boolean, data: (function(*): *), width, title, createdCell: createdCell}}
   */
  generateTextColumn(title, prop, width) {
    return {
      title,
      width,
      containsHTML: true,
      data: result => result[`${prop}Name`],
      createdCell: (td, cellData, rowData) => {
        const dataPropToRender = this.props.isDiffingVersions ? `${prop}DiffHTML` : prop;
        let dataToRender = rowData[dataPropToRender] || {};
        ReactDOM.render(<span>{dataToRender}</span>, td);
      },
    };
  }

  generateManageColumnDef() {
    const {t} = this.props;

    return {
      orderable: false,
      data: result => result.importId,
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          (
            <div>
              <TableButton href={CommonURLs.getImportResultsReviewURL(this.props.projectId, rowData.modelName, rowData.dependency, "Process Capability", rowData.importId)}
                           target="_blank"
                           className="table-icon"
                           title={t("View {{ baseTypeName }} Details", {baseTypeName: t(UIUtils.convertCamelCaseToSpacedOutWords(this.props.capitalizedBaseTypeName))})}
                           id={"viewButton_" + rowData.importId}
                           fontAwesomeIcon={faChevronRight}
              />
            </div>
          ), td);
      },
    };
  }

  /**
   * Prepares the batch import file links for display depending on weather the user is looking at the version diff view or
   * just the record display view.
   * @param importRecords An array of import records related to the batch history.
   * @returns {[]|*}
   */
  prepareRecordsForDisplay(importRecords = []) {
    const {oldBatchVersion} = this.props;

    if (this.props.isDiffingVersions) {
      const oldImportRecords = oldBatchVersion && oldBatchVersion.imports || [];
      let diffedRows = [];

      // Diff new and modified rows
      for (let importRecord of importRecords) {
        let oldImportRecord = oldImportRecords.find(oldImportRecord => oldImportRecord.id === importRecord.id);
        diffedRows.push(this.createImportLinkDiffRow(importRecord, oldImportRecord));
      }

      // Diff removed rows
      for (let oldImportRecord of oldImportRecords.filter(oldIBatchHistoryToImport => !importRecords.some(batchHistoryToImport => batchHistoryToImport.id === oldIBatchHistoryToImport.id))) {
        diffedRows.push(this.createImportLinkDiffRow(null, oldImportRecord));
      }

      return diffedRows;
    } else {
      return importRecords
        .map(importRecord => {
          const {id, modelName, dependency} = importRecord;
          let importFileData = this.getFileData(importRecord, "links");
          let createdBy = this.getImportedByInfo(importRecord);
          return {
            importId: id,
            modelName,
            dependency,
            importFileDataName: (importFileData && importFileData.fileName) || "",
            createdByName: createdBy || "",
            importFileData,
            createdBy,
          };
        })
        .sort(UIUtils.sortBy("importId"));
    }
  }

  /**
   * Creates a diff row for comparing the import links for the selected batch version and the one before it.
   * @param newImportRecord The currently selected batch record version
   * @param oldImportRecord The previous batch record version
   * @returns {{modelName: *, importId: *, dependency: *, supportFileDataLinkDiffHTML: *, importFileDataLinkDiffHTML: *}}
   */
  createImportLinkDiffRow(newImportRecord, oldImportRecord) {
    const {onFileDownload} = this.props;
    const {id} = newImportRecord || oldImportRecord;
    const {modelName, dependency} = newImportRecord || oldImportRecord;

    let newImportFileData = this.getFileData(newImportRecord, "links");
    let oldImportFileData = this.getFileData(oldImportRecord, "links");
    let newCreatedByUserInfo = this.getImportedByInfo(newImportRecord);
    let oldCreatedByUserInfo = this.getImportedByInfo(oldImportRecord);

    return {
      importId: id,
      modelName,
      dependency,
      importFileDataName: (newImportFileData && newImportFileData.fileName) || "",
      createdByName: newCreatedByUserInfo || "",
      importFileDataLinkDiffHTML: createHTMLForDocsLinksDiff(id, oldImportFileData, newImportFileData, onFileDownload),
      createdByDiffHTML: createHTMLForWholeTextDiff(oldCreatedByUserInfo, newCreatedByUserInfo),
    };
  }

  /**
   * Retrieves the file data object from an import record
   * @param importRecord The record to find the import information in
   * @param fileDataProp The record prop to get the file data from
   */
  getFileData(importRecord, fileDataProp) {
    let fileData = importRecord && importRecord[fileDataProp];
    fileData = fileData && JSON.parse(fileData);
    return fileData && fileData[0];
  }

  getImportedByInfo(importRecord) {
    return importRecord
      ? `Imported on ${UIUtils.getDateForDisplayToUser(importRecord.createdAt, UIUtils.DATE_FORMAT_FOR_DISPLAY)} by ${getUserFullName(importRecord.createdByUser)}`
      : null;
  }

  render() {
    return (
      <div className="col-12 batch-links-container">
        <div>
          <table ref={ref => this.tableRef = ref}
                 className="table" id="batchImportLinksTable"
                 style={{width: "100%"}}
          />
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(BatchLinksTable, ["batches", "base_page"]);

// i18next-extract-mark-ns-stop batches
