"use strict";

import { BasePDFObjectBuilder } from "./base_pdf_object_builder";
import pdfJS from "./pdf_js_bridge";

/**
 * Configures and builds the text layer factory used by pdfJS to render the PDF text.
 * @class
 * @extends {BasePDFObjectBuilder<pdfJS.viewer.TextLayerFactory, HTMLElement>}
 */
export class PDFTextLayerBuilder extends BasePDFObjectBuilder {

  /**
   * @param container {React.RefObject<!HTMLElement|*>} The container in which the DOM objects will be created.
   */
  constructor(container) {
    super(container, {});

    /**
     * @type {boolean}
     * @private
     */
    this.enhanceTextSelection = true;
  }

  /**
   * Specifies whether the viewer should use enhanced text selection.
   * @param value {boolean}
   * @returns {PDFTextLayerBuilder}
   */
  setEnhanceTextSelection(value) {
    this.enhanceTextSelection = value;
    return this;
  }

  /**
   * Retrieves the {@link pdfJS.viewer.TextLayerFactory} instance
   * @inheritDoc
   */
  build() {
    let instance = super.build();
    let builder = this;

    return {
      ...instance,
      createTextLayerBuilder(textLayerDiv, pageIndex, viewport) {
        return new pdfJS.viewer.TextLayerBuilder({
          textLayerDiv,
          pageIndex,
          viewport,
          enhanceTextSelection: builder.enhanceTextSelection,
          eventBus: builder.getEventBus(),
        });
      }
    };
  }
}