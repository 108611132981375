"use strict";

import { can, generateTooltip } from "../utils/ui_permissions";
import CommonSecurity from "../../server/common/generic/common_security";
import CommonLibraryMaterial from "../../server/common/editables/common_library_material";

export const LIBRARY_MATERIAL_TYPE_CODE = "MTL";

export const ADD_LIBRARY_TYPES = [
  {
    value: "Library Materials",
    key: "addLibraryMaterial",
    disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.LIBRARY_MATERIAL),
    title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.LIBRARY_MATERIAL),
  },
];

/**
 * Common wizard steps enum
 * @type {{MaterialLibrarySelection: string}}
 */
export const COMMON_MATERIAL_STEPS = {
  MaterialLibrarySelection: "Select Material",
};

/**
 * @type {enum string}
 */
export const CREATE_MATERIAL_STEPS = {
  CreateMaterialPreferences: "Set Preferences",
};


export const SYNC_MATERIAL_STEPS = {
  SyncMaterialOptions: "Material Options",
};

export const PROJECT_MATERIALS_CONSTANTS = {
  ALERT_DIV: "#operationAlertDiv"
};

export const FROM_LIBRARY_STATUS = CommonLibraryMaterial.FROM_LIBRARY_STATUS;