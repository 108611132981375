var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { classNames } from '@progress/kendo-react-common';
import { messages, pagerMobileSelect, pagerPageNumberLabel } from '../messages';
import { provideLocalizationService } from '@progress/kendo-react-intl';
var dots = '...';
/**
 * @hidden
 */
var PagerNumericButtons = /** @class */ (function (_super) {
    __extends(PagerNumericButtons, _super);
    function PagerNumericButtons() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handlePageChange = function (e, page) {
            e.preventDefault();
            _this.props.pageChange(page, e);
        };
        _this.handleSelectPageChange = function (e) {
            e.preventDefault();
            _this.props.pageChange(e.target.value, e);
        };
        return _this;
    }
    PagerNumericButtons.prototype.render = function () {
        var _this = this;
        var localizationService = provideLocalizationService(this);
        var prevDots = this.start > 1 && this.renderDots(this.start - 1);
        var postDots = this.end < this.props.totalPages && this.renderDots(this.end + 1);
        var prevOptionDots = this.start > 1 && this.renderOptionDots(this.start - 1);
        var postOptionDots = this.end < this.props.totalPages && this.renderOptionDots(this.end + 1);
        var mobileSelectMessage = this.props.messagesMap ? this.props.messagesMap(pagerMobileSelect) :
            ({ messageKey: pagerMobileSelect, defaultMessage: messages[pagerMobileSelect] });
        var pageNumberLabelMessage = this.props.messagesMap ? this.props.messagesMap(pagerPageNumberLabel) :
            ({ messageKey: pagerPageNumberLabel, defaultMessage: messages[pagerPageNumberLabel] });
        var buttons = [];
        for (var idx = this.start; idx <= this.end; idx++) {
            buttons.push(idx);
        }
        var numerics = buttons.map(function (page) { return (React.createElement("li", { key: page },
            React.createElement("a", { role: "button", "aria-label": localizationService.toLanguageString(pageNumberLabelMessage.messageKey, pageNumberLabelMessage.defaultMessage) + ' ' + page, "aria-current": _this.props.currentPage === page ? true : undefined, href: "#", onClick: function (e) { return _this.handlePageChange(e, page); }, className: classNames('k-link', {
                    'k-selected': _this.props.currentPage === page
                }) }, page))); });
        var options = buttons.map(function (page) { return (React.createElement("option", { key: page, className: classNames('k-link') }, page)); });
        return (React.createElement("div", { className: "k-pager-numbers-wrap" },
            React.createElement("select", { className: "k-dropdown", "aria-label": localizationService.toLanguageString(mobileSelectMessage.messageKey, mobileSelectMessage.defaultMessage), value: this.props.currentPage, onChange: function (e) { return _this.handleSelectPageChange(e); } },
                prevOptionDots,
                options,
                postOptionDots),
            React.createElement("ul", { className: "k-pager-numbers" },
                prevDots,
                numerics,
                postDots)));
    };
    PagerNumericButtons.prototype.renderDots = function (page) {
        var _this = this;
        return (React.createElement("li", null,
            React.createElement("a", { className: "k-link", onClick: function (e) { return _this.handlePageChange(e, page); }, href: "#" }, dots)));
    };
    PagerNumericButtons.prototype.renderOptionDots = function (page) {
        return (React.createElement("option", { className: "k-link", value: page }, dots));
    };
    Object.defineProperty(PagerNumericButtons.prototype, "end", {
        get: function () {
            return Math.min((this.start + this.props.buttonCount) - 1, this.props.totalPages);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PagerNumericButtons.prototype, "start", {
        get: function () {
            var page = this.props.currentPage;
            var buttonCount = this.props.buttonCount;
            if (page > buttonCount) {
                var reminder = (page % buttonCount);
                return (reminder === 0) ? (page - buttonCount) + 1 : (page - reminder) + 1;
            }
            return 1;
        },
        enumerable: false,
        configurable: true
    });
    return PagerNumericButtons;
}(React.Component));
export { PagerNumericButtons };
