import {Modal} from "react-bootstrap";
import React from "react";
import TypeaheadAttribute from "../../editor/attributes/typeahead_attribute.jsx";
import {Button} from "@qbdvision-inc/component-library";
import {VoidDelegate} from "../../framework/delegates";
import {ProjectAndProcessLabel} from "../../editor/attributes/project_and_process_label";
import BaseReactComponent from "../../base_react_component";
import {ParentProxy} from "../../editor/parent_proxy.js";

export interface IChangeProjectAndProcessModalProps {
  projectId: number;
  processId: number;
  parent: React.ReactElement | any;
  dialogRef: React.Ref<typeof Modal>;
  onClose: VoidDelegate;
  // eslint-disable-next-line no-unused-vars
  onOK: (projectId: number, processId: number) => void;
  show: boolean;
  subject: any;
  project: any;
  process: any;
  isLoading: boolean;
  parentVersionId: number;
  parentId: number;
}


const NONE = "NONE";

/**
 * Displays a modal window that allows the user to edit the project and process of a document.
 * @param props
 * @constructor
 */
export class ChangeProjectAndProcessModal extends BaseReactComponent<IChangeProjectAndProcessModalProps, any> {
  private readonly _toProcessTypeahead: React.RefObject<TypeaheadAttribute>;

  constructor(props) {
    super(props);

    this._toProcessTypeahead = React.createRef<TypeaheadAttribute>();
    this.setStateSafely({
      ToProjectId: null,
      ToProcessId: null,
      changed: true,
    });
  }

  render() {
    const {
      parent,
      dialogRef,
      onClose,
      onOK,
      show,
      subject,
      project,
      process,
    } = this.props;

    const {ToProjectId, ToProcessId, changed} = this.state;

    const current = this;

    const proxy = new ParentProxy(parent);
    const handleParentChange = parent.handleChangeValue.bind(parent);
    /**
     * Creates a copy of the parent object that overrides the behavior of some methods
     * so we can store locally the state of the destination project and process and
     * only make changes to the right properties of the parent object when the confirmation button is clicked.
     */
    const patchedParent = proxy.createPatchedParent({
      state: {
        ...parent.state,
        ToProjectId,
        ToProcessId,
      },

      handleChangeValue(name: string, value: any) {
        handleParentChange(name, value);

        if (name === "ToProjectId") {
          current.setStateSafely({
            ToProjectId: value,
            ToProcessId: NONE,
            changed: true,
          });
        } else if (name === "ToProcessId") {
          current.setStateSafely({
            ToProcessId: value,
            changed: true,
          });
        }
        parent.forceUpdateSafely();
      }
    });

    function clear() {
      handleParentChange("ToProjectId", null);
      handleParentChange("ToProcessId", null);
      current.setStateSafely({
        ToProjectId: null,
        ToProcessId: null,
      });
    }

    const handleCancel: VoidDelegate = () => {
      clear();
      onClose();
    };

    const handleOK: VoidDelegate = () => {
      clear();
      onOK(ToProjectId, ToProcessId);
      onClose();
    };

    const additionalMessage = parent.state["documentContent"] ? " and use updated project information in Smart Content" : "";

    // if the From project is not set or null, it's equivalent to NONE
    let normalizedFromProjectId = project?.id ?? NONE;
    let normalizedFromProcessId = process?.id ?? NONE;

    // if the To project is not set, we treat it as null
    // (null means the field is blank, NONE means No Project or No Process)
    let normalizedToProjectId = ToProjectId ?? null;
    let normalizedToProcessId = ToProcessId ?? null;

    let disabled = !changed || (
      normalizedToProjectId === normalizedFromProjectId
      && normalizedToProcessId === normalizedFromProcessId
    ) || (normalizedToProjectId === null || normalizedToProcessId === null);

    return (
      <Modal
        ref={dialogRef}
        show={show}
        onHide={onClose}
        backdrop={"static"}
        keyboard={false}
      >
        <Modal.Header closeButton>
          {parent.getCurrentOperation()} project for {subject?.name?.trim() || "New Document"}
        </Modal.Header>
        <Modal.Body>
          <div className={"document-project-process-modal"}>
            <p>
              If you change the project, this document will move{additionalMessage}.
            </p>
            <div className="col-sm-12 mb-3" id="originalProject">
              <label className="col-form-label base-attribute col-form-label-add-or-edit">
                <span>From project, process:</span>
              </label>
              <div className={"view-attribute"}>
                <ProjectAndProcessLabel
                  project={project}
                  process={process}
                  isDiff={false}
                  id={"ModalProjectAndProcess"}
                  showEmptyProject={true}
                />
              </div>
            </div>
            <TypeaheadAttribute
              name="toProject"
              typeaheadType="Project"
              parent={patchedParent}
              displayName={"To Project"}
              options={[{label: "Global / No Project", id: NONE}]}
              isLoading={this.props.isLoading}
              parentVersionId={this.props.parentVersionId}
              parentId={this.props.parentId}
            />
            <TypeaheadAttribute
              name="toProcess"
              ref={this._toProcessTypeahead}
              disabled={typeof ToProjectId !== "number"}
              typeaheadType="Process"
              parent={patchedParent}
              options={[{label: "No Process", id: NONE}]}
              filter={(item) => (item.ProjectId === ToProjectId || item.id === NONE)}
              instructions={!ToProjectId ? "Select a Project first to enable this field." : ""}
              isLoading={this.props.isLoading}
              parentVersionId={this.props.parentVersionId}
              parentId={this.props.parentId}
              {...(typeof ToProjectId === "number" ? {projectId: ToProjectId} : {})}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type={"secondary"}
            onClick={handleCancel}
            id={"cancelChangeProject"}
          >Cancel</Button>
          <Button
            type={"primary"}
            onClick={handleOK}
            id={"confirmChangeProject"}
            isDisabled={disabled}
            title={disabled ? "Please select a valid combination of Project and Process that is different from the original one to enable this button." : ""}
          >Change Project and move Document</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
