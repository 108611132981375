"use strict";

import React from "react";
import ReactDOM from "react-dom";
import BaseReactComponent from "../../base_react_component";

export default class ConfirmationPopup extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    $(ReactDOM.findDOMNode(this)).modal("show");
    $(ReactDOM.findDOMNode(this)).on("hidden.bs.modal", this.props.onHideConfirmationPopup);
  }

  render() {
    const {
      modalRef, headerText,
      message, onOkButtonClick,
      useYesNoOptions,
      showCancelButton,
      className,
      yesButtonText,
      noButtonText,
      okButtonText,
      cancelButtonText,
      centered,
    } = this.props;

    return (
      <div className={`modal fade ${className}`}
           ref={modalRef}
      >
        <div className={`modal-dialog ${centered ? "modal-dialog-centered" : ""}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h1 id="confirmationHeader"
                  className="modal-title"
              >
                {headerText}
              </h1>
              <button type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-container">
                <div className="row">
                  <div className="col-sm-12">
                    <span id="informationMessage">
                      {message}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="btn-group">
                <button id="okButton"
                        type="button"
                        className="btn btn-primary"
                        onClick={onOkButtonClick}
                >
                  {useYesNoOptions ? yesButtonText : okButtonText}
                </button>
                {showCancelButton ?
                  <button id="cancelButton"
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                  >
                    {useYesNoOptions ? noButtonText : cancelButtonText}
                  </button> :
                  ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ConfirmationPopup.defaultProps = {
  className: "confirmation-popup",
  yesButtonText: "Yes, Confirm",
  noButtonText: "No, Cancel",
  okButtonText: "Ok",
  cancelButtonText: "Cancel",
};
