var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Reveal } from '@progress/kendo-react-animation';
import { classNames } from '@progress/kendo-react-common';
/**
 * The GridColumnMenuItemContent that will be used inside the Grid ColumnMenu.
 */
var GridColumnMenuItemContent = /** @class */ (function (_super) {
    __extends(GridColumnMenuItemContent, _super);
    function GridColumnMenuItemContent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @hidden
     */
    GridColumnMenuItemContent.prototype.render = function () {
        return (React.createElement("div", { id: this.props.id, className: classNames('k-columnmenu-item-content', this.props.className), style: this.props.style },
            React.createElement(Reveal, { style: { position: 'relative', display: 'block' } }, this.props.show ? this.props.children : null)));
    };
    return GridColumnMenuItemContent;
}(React.Component));
export { GridColumnMenuItemContent };
