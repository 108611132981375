"use strict";
// The functions in this file are responsible for sharing constants (but not model data) between the front and back end.

/**
 * @enum {string}
 */
module.exports.ACTIONS = {
  ARCHIVE: "archive",
  RESTORE: "restore",
};

/**
 * @enum {string}
 */
module.exports.TYPE_CODE = {
  PROJECT: "PRJ",
  PROCESS: "PR",
  UNIT_OPERATION: "UO",
  STEP: "STP",
  MATERIAL: "MT",
  PROCESS_COMPONENT: "PRC",
  MATERIAL_ATTRIBUTE: "MA",
  PROCESS_PARAMETER: "PP",
  IA: "IA",
  IQA: "IQA",
  IPA: "IPA",
  FA: "FA",
  FQA: "FQA",
  FPA: "FPA",
  LIBRARY_MATERIAL: "MTL",
  CONTROL_METHOD: "CM",
  GA: "GA",
  TPP: "TPP",
  SUPPLIER: "SUP",
};

module.exports.PROCESS_LEVEL_ID = -1;

// We can use this to debug by throttling the execution of the bulk operations
module.exports.MAX_CONCURRENT_BULK_OPERATIONS = 500;

module.exports.CALCULATED_RISK_ATTRIBUTES = [
  "riskLabelColor",
  "riskLabel",
  "scoreLabelColor",
  "scoreLabel",
  "criticalityRaw",
  "criticalityPercentage",
  "maxCriticalityRaw",
  "maxCriticalityPercentage",
  "processRiskRaw",
  "processRiskPercentage",
  "rpnRaw",
  "rpnPercentage",
  "techTransferRPNRaw",
  "techTransferRPNPercentage",
];
