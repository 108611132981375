"use strict";

import { BasePDFRenderer } from "./base_pdf_renderer";
import { PDFPageBuilder } from "./pdf_page_builder";
import * as UIUtils from "../../../ui_utils";
import { Ensure } from "../../../../server/common/generic/common_ensure";
import { Log, LOG_GROUP } from "../../../../server/common/logger/common_log";

// eslint-disable-next-line no-unused-vars
const Logger = Log.group(LOG_GROUP.Documents, "PDFDocumentRenderer");

export class PDFDocumentRenderer extends BasePDFRenderer {

  /**
   * @param viewerRoot {React.RefObject<HTMLElement>} The container in which the document will be rendered.
   * @param pageRenderer {PDFPageRenderer} The renderer used to render the page.
   */
  constructor(viewerRoot, pageRenderer) {
    super(viewerRoot);

    this.pageRenderer = pageRenderer;
    this.isReady = false;
  }

  shouldRender(target, context) {
    Logger.verbose("ShouldRender");

    Ensure.virtual("shouldRender", {target, context});
    return true;
  }

  async renderCore(target, context) {
    Logger.verbose("RenderCore");

    Ensure.virtual("render", {target, context});

    let renderObject = target.renderObject;

    let cancellationHandler = (
      typeof renderObject.cancellationHandler !== "function"
        ? renderObject.cancellationHandler
        : () => false
    );

    if (cancellationHandler()) {
      return renderObject;
    }

    let pagesRendered = [];

    if (!renderObject.isRendered) {
      renderObject.viewerArea.empty();
    }

    /**
     * @type {IPDFRenderContext}
     */
    const pageRenderContext = {
      currentPage: UIUtils.isInteger(context.currentPage) ? UIUtils.parseInt(context.currentPage) : 1,
      pageCount: renderObject.pageCount,
    };

    if (renderObject.shouldRender) {
      this.adjustContainerHeight(target);

      for (let pageNumber = 1; pageNumber <= renderObject.pageCount; pageNumber++) {
        if (!cancellationHandler() && renderObject.isVisible) {
          let page = await target.getCachedPage(pageNumber);
          let renderedPage = await this.renderPage(target, page, pageRenderContext);

          if (renderedPage.rendered && !renderedPage.skipped) {
            pagesRendered.push(renderedPage.number);
          }
          page.renderObject = renderedPage;
        }
      }

      if (pagesRendered.length > 0) {
        Logger.debug(() => `Rendered ${Log.symbol(pagesRendered.length)} of ${Log.symbol(renderObject.pageCount)}: `, Log.object(pagesRendered));
      }

      if (cancellationHandler()) {
        return renderObject;
      }

      this.postRenderQueue.push(async() => {
        if (!this.isReady && renderObject) {
          Logger.info("Document viewer is ready.", Log.object(target.fingerprint));
          const onReady = renderObject.onReadyHandler;

          // raises an event when the document is rendered for the first time
          if (onReady && typeof onReady === "function") {
            await onReady(renderObject);
          } else {
            Logger.debug("No handler specified for onReady");
          }
          this.pageRenderer.isReady = true;
          this.isReady = true;
        }
      });
      // using a promise here to ensure that we'll wait if the event handler returns a promise
      await Promise.resolve()
        .then(() => renderObject.onDocumentRenderedHandler({pagesRendered}))
        .catch(UIUtils.displayCriticalError);
    }
    return renderObject;
  }

  /**
   * @param document {ICachedPDFDocument}
   * @param page {ICachedPDFPage}
   * @param context {IPDFRenderContext}
   * @returns {Promise<IPDFPage>}
   */
  async renderPage(document, page, context) {
    const renderObject = document.renderObject;

    Logger.verbose("Rendering page: ", renderObject.zoomLevel, renderObject.pageWidth);
    let builder = new PDFPageBuilder(renderObject.viewerAreaRef, page)
      .setPageWidth(renderObject.pageWidth)
      .setVisibility(renderObject.isVisible)
      .setZoomLevel(renderObject.zoomLevel)
      .rescale()
      .configurePageView()
      .configurePageWrapper()
      .configureLoadingIndicator()
      .configureCanvasWrapper()
      .configureCanvas()
      .configureTextLayer()
    ;

    const renderPage = builder.build();
    page.renderObject = renderPage;

    await this.pageRenderer.render(renderPage, context);

    const offset = renderPage.pageWrapper.offset();
    let showIndicator = renderPage.skipped && !renderPage.rendered;

    if (offset) {
      renderPage.top = offset.top;
      renderPage.left = offset.left;
    }

    renderPage.rendered = renderPage.shouldRender ? !renderPage.skipped : renderPage.rendered;
    renderPage.loadingIndicator.toggleClass("d-none", !showIndicator);

    return renderPage;
  }

  /**
   * @inheritDoc
   */
  async endRender(target, context) {
    Logger.verbose("End Render");
    Ensure.virtual("endRender", {target, context});
    const renderObject = target && target.renderObject;

    const cancelled = renderObject && renderObject.cancelled;
    if (cancelled) {
      Logger.debug("Render cancelled", Log.stackTrace());
    }

    if (this.renderingInProcess) {
      this.renderingInProcess = false;
      this.shouldCancelRender = false;

      let postRenderTasks = [];

      for (let item = 0; item < 100 && this.postRenderQueue.length > 0; item++) {
        let action = this.postRenderQueue.shift();
        if (action && typeof action === "function") {
          let task = new Promise((resolve, reject) => {
            return Promise.resolve()
              .then(() => Logger.verbose("Running post-render event.", Log.object(action)))
              .then(() => action({isCancel: cancelled}))
              .then(() => Logger.verbose("Post-render event completed.", Log.object(action)))
              .then(resolve)
              .catch(reject);
          });
          postRenderTasks.push(task);
        }
      }
      Logger.verbose("Waiting for post-render tasks to complete: ", postRenderTasks.length);
      await Promise.all(postRenderTasks);
      Logger.verbose("All post-render tasks complete: ", postRenderTasks.length);
      Logger.verbose("Render completed");
    } else {
      Logger.verbose("Rendering is not in progress");
    }
    // adjust height again, since this will run even when render is not executed
    this.adjustContainerHeight(target);

    return target;
  }

  adjustContainerHeight(target) {
    if (target) {
      let renderObject = target && target.renderObject;
      if (renderObject) {
        const onCalculateHeight = renderObject.onCalculateHeightHandler;

        let containerRef = this.getVisibleContainer(renderObject);

        let parentContainer = containerRef && containerRef.current;

        if (onCalculateHeight) {
          Logger.verbose("Calculating height");
          let height = onCalculateHeight({viewerRoot: containerRef});
          const $container = renderObject.container;
          $container.height(height);
          $container.css("min-height", height);
          $container.css("max-height", height);
        } else {
          Logger.warn("No height calculation function defined.", Log.object(target));
        }

        let viewerArea = renderObject.viewerArea;

        if (parentContainer && viewerArea) {
          /* This is a trick to avoid extending the page width infinitely when zooming in the PDF. For some reason, setting
           the width of the div to 100% does not constrain it to the parent's width. On the contrary, then zooming in the
           pdf, the canvas container width increases and along with it the page bootstrap container div. When setting the
           div to a fixed size, this does not happen anymore. The trick below just makes sure that the container div has
           the width of its parent simulating a width of 100% using pixes for size.
         */
          const size = {
            width: parentContainer.clientWidth - renderObject.scrollBarWidth,
            height: parentContainer.clientHeight,
          };
          viewerArea.width(size.width);
          viewerArea.height(size.height);
        }
      } else {
        Logger.verbose("renderObject is falsy", Log.object(target));
      }
    } else {
      Logger.verbose("target is falsy. Doing nothing");
    }
  }
}
