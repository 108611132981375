"use strict";

import React from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import TechTransferVariableGridDetailsTable from "./widgets/tech_transfer_variable_grid_details_table";
import { process } from "@progress/kendo-data-query";
import TechTransferColumnGenerator from "./columnGenerator/tech_transfer_column_generator";
import TechTransferVariableParentColumnGenerator
  from "./columnGenerator/tech_transfer_variable_parent_column_generator";
import TechTransferTable from "./tech_transfer_table";
import {
  createExpandColumnCell,
  createExpandHeaderCell,
  renderRow, updateExpandButtonStyle
} from "../../configurableTables/tables/configurable_tables_helper";

/**
 * This table class works as the base for PRC/MT
 */
export default class TechTransferVariableParentTable extends TechTransferTable {

  get columnGenerator() {
    return new TechTransferVariableParentColumnGenerator(this.props);
  }

  handleExpandChange({dataItem, dataIndex}) {
    const {fromProcess, toProcess, onReviewRecord} = this.props;
    dataItem.expanded = !dataItem.expanded;
    dataItem.fromProcess = fromProcess;
    dataItem.toProcess = toProcess;
    dataItem.onReviewRecord = onReviewRecord;
    dataItem.columnGenerator = new TechTransferColumnGenerator(this.props);
    this.setStateSafely({...this.state},
      () => updateExpandButtonStyle(dataItem, dataIndex));
  }

  render() {
    const {
      tableState,
      records,
      onColumnOperationsChange,
      columnOperationsAdapter,
    } = this.props;

    return (
      <Grid
        data={process(records.slice(0),
          columnOperationsAdapter.adaptFilterNames(this.props))}
        onDataStateChange={onColumnOperationsChange}
        sortable={true}
        style={{height: "100%"}}
        {...tableState}
        detail={TechTransferVariableGridDetailsTable}
        expandField="expanded"
        ref={this.grid}
        rowRender={renderRow}
      >
        <GridColumn field="expanded"
                    headerCell={createExpandHeaderCell}
                    cell={(props) => createExpandColumnCell(props, this.handleExpandChange)}
                    width="40px" locked
        />
        {this.getColumns()}
      </Grid>
    );
  }
}
