"use strict";

import * as UIUtils from "../../../../ui_utils";
import { BaseDataTransform } from "../../base_data_transform";
import { INVALID_DATE, TRAINING_STATUS } from "./constants/constants";
import CommonTraining from "../../../../../server/common/editables/common_training";

/**
 * This class is the base class for the report data transforms used in training..
 * @abstract
 */
export class BaseTrainingDataTransform extends BaseDataTransform {
  getSupersededStatus(currentRecord, allRecords) {
    return allRecords && allRecords.find(doc =>
      currentRecord.status === "Assigned" &&
      UIUtils.parseInt(doc.documentVersion) > UIUtils.parseInt(currentRecord.documentVersion) &&
      doc.documentId === currentRecord.documentId);
  }

  getIncompleteStatus(currentRecord, allRecords) {
    let trainingStatus = this.getTrainingStatus(currentRecord, allRecords, false);

    return (
      (
        currentRecord.completionDate
        && currentRecord.completionDate !== INVALID_DATE
        && currentRecord.status !== "TrainedOutside"
        && currentRecord.status !== "Assigned"
      ) ? TRAINING_STATUS.COMPLETED
        : trainingStatus
    );
  }

  getTrainingStatus(currentRecord, allRecords, completedAsDate = true) {
    let completionDate = UIUtils.getDateForDisplayToUser(currentRecord.completionDate, UIUtils.DATE_FORMAT_FOR_DISPLAY);
    let effectiveDate = UIUtils.getDateForDisplayToUser(currentRecord.effectiveDate, UIUtils.DATE_FORMAT_FOR_DISPLAY);
    let assignmentDate = UIUtils.getDateForDisplayToUser(currentRecord.assignmentDate, UIUtils.DATE_FORMAT_FOR_DISPLAY);

    let record = {
      ...currentRecord,
      completionDate,
      effectiveDate,
      assignmentDate,
    };

    return CommonTraining.getCompletionStatus(record, allRecords, {completedAsDate});
  }

  getRetrainingSpecifier(currentRecord) {
    return currentRecord.isRetraining ? " (retrained)" : "";
  }
}
