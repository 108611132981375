"use strict";

import React from "react";
import { BaseOptionsMenu } from "../../widgets/base_options_menu";

/**
 * This class is for producing the context menu in the process explorer that provides options such as View, Edit, Archive, etc.
 */
export default class ContextOptionsMenu extends BaseOptionsMenu {
  constructor(props) {
    super(props);
  }

  render() {
    let {id} = this.props;

    return (
      <ul id={id} className="dropdown-menu table-row-options-button hide">
        {this.renderItems()}
      </ul>
    );
  }

}
