"use strict";

export const LEADING_SECTION = "Leading";
export const TECH_TRANSFER_SECTION = "Tech Transfer";

export const ABOUT_SECTION = "About";
export const CRITICALITY_ASSESSMENT_SECTION = "Criticality Assessment";
export const ACCEPTANCE_CRITERIA_SECTION = "Acceptance Criteria";
export const PROCESS_CAPABILITY_SECTION = "Process Capability";
export const RISK_CONTROL_SECTION = "Risk Control";
export const REFERENCES_SECTION = "References & Standards";
export const ACTIONS_SECTION = "Actions";

export const FORMULATION_QUANTITY_SECTION = "Formulation Quantity";
export const QUALIFICATION_SECTION = "Qualification";
export const REGULATORY_SECTION = "Regulatory";
export const PROPERTIES_SECTION = "Properties";

export const COMPONENT_QUALIFICATION_SECTION = "Component Qualification";
export const UNIT_QUALIFICATION_SECTION = "Unit Qualification";
export const COMPONENT_RISK_SECTION = "Component Risk";

export const METHOD_DEVELOPMENT_SECTION = "Method Development";

export const AUDIT_SECTION = "Audit";
export const COMMON_SECTIONS = [
  {
    title: "",
    name: LEADING_SECTION,
  },
  {
    title: "Tech Transfer Assessment",
    name: TECH_TRANSFER_SECTION,
  }
];

export const VARIABLES_COMMON_SECTIONS = [
  {
    title: "About",
    name: ABOUT_SECTION,
  },
  {
    title: "Criticality Assessment",
    name: CRITICALITY_ASSESSMENT_SECTION,
  },
  {
    title: "Acceptance Criteria",
    name: ACCEPTANCE_CRITERIA_SECTION,
  },
  {
    title: "Process Capability",
    name: PROCESS_CAPABILITY_SECTION,
  },
  {
    title: "Risk Control",
    name: RISK_CONTROL_SECTION,
  },
  {
    title: "References",
    name: REFERENCES_SECTION,
  }
];

export const SECTIONS = {
  ["Quality Attributes"]: [
    ...VARIABLES_COMMON_SECTIONS,
  ],
  ["Performance Attributes"]: [
    ...VARIABLES_COMMON_SECTIONS,
  ],
  ["General Attributes"]: [
    {
      title: "About",
      name: ABOUT_SECTION,
    },
  ],
  ["Target Product Profile"]: [
    {
      title: "About",
      name: ABOUT_SECTION,
    },
  ],
  ["Unit Operations"]: [
    {
      title: "About",
      name: ABOUT_SECTION,
    },
  ],
  ["Steps"]: [
    {
      title: "About",
      name: ABOUT_SECTION,
    },
  ],
  ["Process Parameters"]: [
    ...COMMON_SECTIONS,
    ...VARIABLES_COMMON_SECTIONS,
  ],
  ["Material Attributes"]: [
    ...COMMON_SECTIONS,
    ...VARIABLES_COMMON_SECTIONS,
  ],
  ["IQAs"]: [
    ...COMMON_SECTIONS,
    ...VARIABLES_COMMON_SECTIONS,
  ],
  ["IPAs"]: [
    ...COMMON_SECTIONS,
    ...VARIABLES_COMMON_SECTIONS,
  ],
  ["Process Components"]: [
    ...COMMON_SECTIONS,
    {
      title: "About",
      name: ABOUT_SECTION,
    },
    {
      title: "Component Qualification",
      name: COMPONENT_QUALIFICATION_SECTION,
    },
    {
      title: "Unit Qualification",
      name: UNIT_QUALIFICATION_SECTION,
    },
    {
      title: "Component Risk",
      name: COMPONENT_RISK_SECTION,
    },
    {
      title: "References",
      name: REFERENCES_SECTION,
    }
  ],
  ["Materials"]: [
    ...COMMON_SECTIONS,
    {
      title: "About",
      name: ABOUT_SECTION,
    },
    {
      title: "Formulation Quantity",
      name: FORMULATION_QUANTITY_SECTION,
    },
    {
      title: "Qualification",
      name: QUALIFICATION_SECTION,
    },
    {
      title: "Regulatory",
      name: REGULATORY_SECTION,
    },
    {
      title: "Properties",
      name: PROPERTIES_SECTION,
    },
    {
      title: "References",
      name: REFERENCES_SECTION,
    }
  ],
  ["Library Materials"]: [
    {
      title: "",
      name: LEADING_SECTION,
    },
    {
      title: "About",
      name: ABOUT_SECTION,
    },
    {
      title: "Qualification",
      name: QUALIFICATION_SECTION,
    },
    {
      title: "Regulatory",
      name: REGULATORY_SECTION,
    },
    {
      title: "Properties",
      name: PROPERTIES_SECTION,
    },
    {
      title: "References",
      name: REFERENCES_SECTION,
    }
  ],
  ["Control Methods"]: [
    {
      title: "About",
      name: ABOUT_SECTION
    },
    {
      title: "Method Development",
      name: METHOD_DEVELOPMENT_SECTION
    },
    {
      title: "References",
      name: REFERENCES_SECTION,
    },
  ],
  ["Suppliers"]: [
    {
      title: "",
      name: LEADING_SECTION,
    },
    {
      title: "About",
      name: ABOUT_SECTION,
    },
    {
      title: "Audit",
      name: AUDIT_SECTION,
    },
    {
      title: "Qualification",
      name: QUALIFICATION_SECTION,
    },
    {
      title: "Risk",
      name: RISK_CONTROL_SECTION,
    }
  ]
};

export const COLUMN_SELECTION_ONLY_SECTIONS = [LEADING_SECTION, TECH_TRANSFER_SECTION];
