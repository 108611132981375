"use strict";

import * as UIUtils from "../../ui_utils";
import * as CannedReportHelper from "../canned_reports/canned_report_helper";
import { REPORT_OPTIONS_ENUM, TEMPLATES_PATH } from "../constants/report_constants";
import canvasDrawer from "../../helpers/canvas/pcm_dashboard_canvas_drawer";
import { ALIGNMENT } from "../../helpers/canvas/canvas_drawer_base";
import canvasDownloader from "../../helpers/canvas/canvas_downloader";
import React from "react";
import { isQualitativeMeasure } from "../../../server/common/editables/common_editables";

export function loadChartImagesAndExportAsPNG(chartImagesMap, exportConfig, reportType, acceptanceCriteria, batches, measure) {
  chartImagesMap.set("logo", "/images/logo-full-32.png");

  let loadedImagesMap = new Map();
  chartImagesMap.forEach((chartImage, name) => {
    let image = new Image();
    image.onload = () => {
      loadedImagesMap.set(name, image);
      if (loadedImagesMap.size === chartImagesMap.size) {
        exportDashboardAsPNG(loadedImagesMap, exportConfig, reportType, acceptanceCriteria, batches, measure);
      }
    };
    image.src = chartImage;
  });
}

function exportDashboardAsPNG(chartImagesMap, exportConfig, reportType, acceptanceCriteria, batches, measure) {
  let yLegendMargin = 5;
  let fontFamily = "Roboto, Open Sans";
  let defaultBackgroundColor = "#FFFFFF";
  let largeFontSize = "20px";
  let infoBoxesFontSize = "16px";
  let BoldFontWeight = "bold";
  let reportHeaderWidth;
  let finalCanvas;

  let reportMargins = {
    left: 30,
    top: 15,
    right: 30,
    bottom: 30
  };

  let margins = canvasDrawer.resetMargins();
  let emptyCanvas = canvasDrawer.getEmptyCanvas();

  //Draw pcm info boxes
  let dashboardCanvas = emptyCanvas;
  let infoBoxes = getDashboardInfoBoxes(reportType, acceptanceCriteria);
  let infoBoxesCanvas = emptyCanvas;

  for (let i = 0; i < infoBoxes.length; i++) {
    margins.left = i === 0 ? 0 : 5;
    infoBoxesCanvas = canvasDrawer.mergeCanvasToRight(infoBoxesCanvas, canvasDrawer.drawInfoBox(infoBoxes[i].title, exportConfig[infoBoxes[i].name],
      fontFamily, infoBoxesFontSize, BoldFontWeight, margins, infoBoxes[i].backgroundColor));
  }

  dashboardCanvas = canvasDrawer.mergeCanvasToBottom(dashboardCanvas, infoBoxesCanvas);

  //Draw pcm first index boxes
  let indicesCharts = getDashboardIndices(reportType, acceptanceCriteria);
  indicesCharts.push({
    renderIn: "capabilityPlotChart",
    indexName: "capabilityPlotChart",
    displayName: "Capability Plot",
    backgroundColor: "#effafe"
  });

  let indicesChartsCanvas = emptyCanvas;
  margins = canvasDrawer.resetMargins();
  margins.top = yLegendMargin;
  for (let i = 0; i < indicesCharts.length; i++) {
    let value = exportConfig[indicesCharts[i].indexName];
    let chart = chartImagesMap.get(indicesCharts[i].renderIn);
    let isCapabilityPlot = indicesCharts[i].renderIn === "capabilityPlotChart";
    value = isCapabilityPlot && chart ? "" : isCapabilityPlot && !chart ? "N/A" : value;

    indicesChartsCanvas = canvasDrawer.mergeCanvasToRight(indicesChartsCanvas, canvasDrawer.drawIndexChart(indicesCharts[i].displayName, value,
      fontFamily, infoBoxesFontSize, BoldFontWeight, chart, margins, isCapabilityPlot && chart ? defaultBackgroundColor : indicesCharts[i].backgroundColor));

    if ((i + 1) % 3 === 0) {
      dashboardCanvas = canvasDrawer.mergeCanvasToBottom(dashboardCanvas, indicesChartsCanvas);
      indicesChartsCanvas = emptyCanvas;
      margins.left = 0;
    } else {
      margins.left = 5;
    }
  }
  dashboardCanvas = canvasDrawer.mergeCanvasToBottom(dashboardCanvas, indicesChartsCanvas);

  //Draw pcm control charts
  let controlChartsCanvas = emptyCanvas;
  let controlCharts = getDashboardCharts(reportType, batches, measure);
  margins = canvasDrawer.resetMargins();
  margins.left = 5;
  for (let i = 0; i < controlCharts.length; i++) {
    margins.top = i === 0 ? 0 : 5;
    controlChartsCanvas = canvasDrawer.mergeCanvasToBottom(controlChartsCanvas, canvasDrawer.drawControlChart(controlCharts[i].title, fontFamily, largeFontSize,
      controlCharts[i].backgroundColor, BoldFontWeight, chartImagesMap.get(controlCharts[i].renderIn), margins));
  }

  dashboardCanvas = canvasDrawer.mergeCanvasToRight(dashboardCanvas, controlChartsCanvas);
  reportHeaderWidth = dashboardCanvas.width;

  margins = reportMargins;
  dashboardCanvas = canvasDrawer.applyMarginsToCanvas(dashboardCanvas, margins);

  //Draw the report header
  margins = canvasDrawer.resetMargins();
  let reportHeaderCanvas = canvasDrawer.drawReportHeader({
    reportTitle: "Process Capability Dashboard",
    projectName: exportConfig.projectName,
    exportDate: exportConfig.exportDate,
    reportWidth: reportHeaderWidth,
    modelLabel: exportConfig.modelLabel,
    latestVersion: exportConfig.latestVersion,
    currentState: exportConfig.currentState,
    processLabel: exportConfig.processLabel,
  });

  margins = canvasDrawer.resetMargins();
  margins.top = 30;
  margins.bottom = 15;
  reportHeaderCanvas = canvasDrawer.applyMarginsToCanvas(reportHeaderCanvas, margins);

  // Draw the report header separator line
  let ctx = reportHeaderCanvas.getContext("2d");
  ctx.fillStyle = "#1FBCFF";
  ctx.fillRect(0, reportHeaderCanvas.height - 2, reportHeaderCanvas.width, 2);

  finalCanvas = canvasDrawer.mergeCanvasToBottom(reportHeaderCanvas, dashboardCanvas, 0, ALIGNMENT.CENTER);

  //Draw the report footer
  margins = canvasDrawer.resetMargins();
  let reportFooterCanvas = canvasDrawer.drawReportFooter({
    logoImage: chartImagesMap.get("logo"),
    reportWidth: finalCanvas.width,
    reportMargins: reportMargins
  });

  margins.top = 15;
  margins.bottom = 15;
  reportFooterCanvas = canvasDrawer.applyMarginsToCanvas(reportFooterCanvas, margins);
  finalCanvas = canvasDrawer.mergeCanvasToBottom(finalCanvas, reportFooterCanvas, 0, ALIGNMENT.CENTER);

  //Export to png
  finalCanvas.isDrawingMode = false;
  canvasDownloader.downloadCanvas(finalCanvas, "pcm_dashboard.png");
}

export function exportDashboardAsPDF(chartImagesMap, exportConfig, reportType, modelType) {
  let report = new window.Stimulsoft.Report.StiReport();
  report.loadFile(TEMPLATES_PATH+ "/" + REPORT_OPTIONS_ENUM[reportType].templateName);

  for (let chart of window.HighCharts.charts.filter(chart => chart && chart.series.length > 0)) {
    let chartImage = window.Stimulsoft.Base.Drawing.StiImageConverter.stringToImage(chartImagesMap.get(chart.renderTo.id));

    let stimulsoftImageHolderId = chart.renderTo.id;
    let charts = REPORT_OPTIONS_ENUM[reportType].charts;

    if (chart.renderTo.id === "lastSubgroupsChart" || chart.renderTo.id === "xChart" || chart.renderTo.id === "pChart") {
      exportConfig.firstChartTitle = charts.find(c => c.renderIn === chart.renderTo.id).title;
      stimulsoftImageHolderId = "firstChart";
    } else if (chart.renderTo.id === "xbarChart" || chart.renderTo.id === "mrChart" || chart.renderTo.id === "cumulativeDefectiveChart") {
      exportConfig.secondChartTitle = charts.find(c => c.renderIn === chart.renderTo.id).title;
      stimulsoftImageHolderId = "secondChart";
    } else if (chart.renderTo.id === "cpkChart" || chart.renderTo.id === "cpuChart" || chart.renderTo.id === "cplChart") {
      stimulsoftImageHolderId = "cpVarChart";
    } else if (chart.renderTo.id === "ppkChart" || chart.renderTo.id === "ppuChart" || chart.renderTo.id === "pplChart") {
      stimulsoftImageHolderId = "ppVarChart";
    }

    let chartImageVar = report.dictionary.variables.getByName(stimulsoftImageHolderId);
    chartImageVar.valueObject = chartImage;
    exportConfig[`is${UIUtils.capitalize(chart.renderTo.id)}Visible`] = true;

    if (chart.renderTo.id === "cpuChart") {
      exportConfig.cpVarChartTitle = "Cpu";
      exportConfig.cpVar = exportConfig.cpu;
      exportConfig.isCpVarChartVisible = exportConfig.isCpuChartVisible;
    } else if (chart.renderTo.id === "cplChart") {
      exportConfig.cpVarChartTitle = "Cpl";
      exportConfig.cpVar = exportConfig.cpl;
      exportConfig.isCpVarChartVisible = exportConfig.isCplChartVisible;
    } else if (chart.renderTo.id === "cpkChart" || !exportConfig.cpVarChartTitle) {
      exportConfig.cpVarChartTitle = "Cpk";
      exportConfig.cpVar = exportConfig.cpk;
      exportConfig.isCpVarChartVisible = exportConfig.isCpkChartVisible;
    }

    if (chart.renderTo.id === "ppuChart") {
      exportConfig.ppVarChartTitle = "Ppu";
      exportConfig.ppVar = exportConfig.ppu;
      exportConfig.isPpVarChartVisible = exportConfig.isPpuChartVisible;
    } else if (chart.renderTo.id === "pplChart") {
      exportConfig.ppVarChartTitle = "Ppl";
      exportConfig.ppVar = exportConfig.ppl;
      exportConfig.isPpVarChartVisible = exportConfig.isPplChartVisible;
    } else if (chart.renderTo.id === "ppkChart" || !exportConfig.ppVarChartTitle) {
      exportConfig.ppVarChartTitle = "Ppk";
      exportConfig.ppVar = exportConfig.ppk;
      exportConfig.isPpVarChartVisible = exportConfig.isPpkChartVisible;
    }
  }

  let dataSet = new window.Stimulsoft.System.Data.DataSet("DataSet");
  dataSet.readJson(exportConfig);
  report.dictionary.databases.clear();
  report.regData(dataSet.dataSetName, "", dataSet);

  // Uncomment for verbose logging
  // console.log("Post Processing (PCM Dashboard) Data: ", JSON.stringify(exportConfig));

  report.renderAsync(() => {
    CannedReportHelper.exportAs(CannedReportHelper.getFileType("pdf"), "." + "pdf", report,
      reportType, modelType, true);
  });
}

export function isIndexValueEmpty(value) {
  return !value || value === "N/A";
}

export function getDashboardIndices(reportType, acceptanceCriteria) {
  let filter = ["cp", "cpk", "cpm", "pp", "ppk"];
  let indicesCharts = REPORT_OPTIONS_ENUM[reportType].indices;

  if (acceptanceCriteria && isIndexValueEmpty(acceptanceCriteria.usl) &&
    !isIndexValueEmpty(acceptanceCriteria.lsl)) {
    filter = ["cp", "cpl", "cpm", "pp", "ppl"];
  } else if (acceptanceCriteria && isIndexValueEmpty(acceptanceCriteria.lsl) &&
    !isIndexValueEmpty(acceptanceCriteria.usl)) {
    filter = ["cp", "cpu", "cpm", "pp", "ppu"];
  }

  return indicesCharts.filter(chart => filter.includes(chart.indexName));
}

export function getDashboardInfoBoxes(reportType, acceptanceCriteria, measure) {
  let infoBoxes = REPORT_OPTIONS_ENUM[reportType].infoBoxes;
  let unit = acceptanceCriteria.unit;

  if (isQualitativeMeasure(measure)) {
    infoBoxes = infoBoxes.filter(infoBox => infoBox.title !== "LSL");
  }

  return infoBoxes.map(infoBox => {
    let value = acceptanceCriteria[infoBox.name];
    infoBox.value = value ? value + (unit ? (isQualitativeMeasure(measure) ? `${unit}` : ` ${unit}`) : "") : "N/A";
    return infoBox;
  });
}

export function getDashboardCharts(reportType, subgroups, measure) {
  let filter = ["Last Subgroups Chart", "X-bar Chart"];
  let charts = REPORT_OPTIONS_ENUM[reportType].charts;
  if (isQualitativeMeasure(measure)) {
    filter = ["P Chart", "Cumulative %Defective Chart"];
  } else if (allSampleSizesAreOnes(subgroups)) {
    filter = ["X Chart", "MR Chart"];
  }
  return charts.filter(chart => filter.includes(chart.title));
}

export function allSampleSizesAreOnes(subgroups) {
  return subgroups.every(subgroup => subgroup.totalMeasurements === 1);
}
