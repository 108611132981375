"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import BasePage from "../../base_page";
import EditablesPageTitleBar from "../../widgets/pageTitleBar/editables_page_title_bar";
import BulkProposalApprovalTitleBar from "../../widgets/pageTitleBar/bulk_proposal_approval_title_bar";
import NavBar from "../../widgets/bars/nav_bar";
import ErrorBar from "../../widgets/bars/error_bar";
import Heading from "../../widgets/headers/heading";
import BulkProposalReviewTable from "./bulk_proposal_review_table";
import BulkProposalResultsTable from "./bulk_proposal_results_table";
import QuickPanel from "../../editor/quick/quick_panel";
import ApprovalRequestPopup from "../../editor/approval/approval_request_popup";
import { EDITOR_OPERATIONS, EDITOR_TYPES, RECORD_OPERATIONS } from "../../editor/editor_constants";
import * as RecordValidationHelper from "../../utils/verification/record_verification_helper";
import { AUTO_VALIDATION_MODE_ENUM, SAVE_BEHAVIOR_ENUM } from "../../editor/base_approvable_editor";
import {
  BulkOperationsStatusDisplayService
} from "../../services/bulkOperations/bulk_operations_status_display_service";
import { BulkProposalMessageHandler } from "../../services/bulkOperations/bulk_proposal_message_handler";
import { EditablesService } from "../../services/editables/editables_service";
import { BULK_PROPOSAL_STATUS, DO_NOTHING, MAX_RECORDS_LIMIT } from "../constants/bulk_operations_constants";
import { QUICK_PANEL_BUTTONS } from "../../widgets/quickPanel/quick_panel_buttons";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { loadRMP } from "../../helpers/risk_helper";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import { DATATABLES_DOM } from "../../widgets/tables/base_table";
import * as CommonUtils from "../../../server/common/generic/common_utils";
import DeleteRiskLinksConfirmationPopup from "../../widgets/risk/delete_risk_links_confirmation_popup";
import { EMPTY_STRING } from "../../helpers/constants/constants";
import EditRiskLinksConfirmationPopup from "../../widgets/risk/edit_risk_links_confirmation_popup";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import {
  getApprovalValue,
  handleApprovalChangeValue,
  handleApprovalChangeValues
} from "../../editor/approval/approval_utils";
import { QBDLink, withRouter } from "../../utils/withRouter";

const Logger = Log.group(LOG_GROUP.Editables, "BulkProposalPage");

/* This class shows the bulk proposal page. */
class BulkProposalPage extends BasePage {

  constructor(props) {
    super(props);

    this.handleApprovalChangeValue = (attributeName, attributeValue) => {
      const handle = handleApprovalChangeValue.bind(this);
      return handle(attributeName, attributeValue, {recordSaved: false});
    };

    this.handleApprovalChangeValues = (keyValuePair, callback) => {
      const handle = handleApprovalChangeValues.bind(this);
      return handle(keyValuePair, callback, {recordSaved: false});
    };

    this.statusService = new BulkOperationsStatusDisplayService(this.displayStatus, DO_NOTHING);
    this.messageHandler = new BulkProposalMessageHandler(this.handleWebSocketConnectionMessage, this.statusService);
    this.quickPanel = React.createRef();
    this.instances = [];
    this.nonDraftInstances = [];
    this.getApprovalValue = getApprovalValue.bind(this);

    this.initializeParameters();
    this.state = this.getInitialState();
  }

  componentDidMount() {
    super.componentDidMount();

    document.title = "QbDVision Bulk Proposal";
    this.initializeParameters();
    this.initializeData();
  }

  getInitialState() {
    return {
      project: {id: this.projectId},
      bulkProposalStatus: BULK_PROPOSAL_STATUS.EDITING,
      quickPanelOperation: EDITOR_OPERATIONS.VIEW,
    };
  }

  initializeParameters() {
    this.projectId = this.props.getParameterByName("projectId");
    this.requestedByHomePage = !!this.props.getParameterByName("requestedByHomePage");
    this.isTopLevelRecord = !!this.props.getParameterByName("isTopLevelRecord");
    this.cascadingProposal = !!this.props.getParameterByName("cascadingProposal");

    this.requirementIds = JSON.parse(
      sessionStorage["bulkProposalRequirementIds"] || "[]"
    ).filter(
      (requirementId) => UIUtils.parseKey(requirementId?.fullKey)?.typeCode !== "DCON"
    );

    this.actualRequirementIdsLength = this.requirementIds.length;
    this.requirementIds = this.requirementIds.splice(0, MAX_RECORDS_LIMIT);

    /* For any reason no requirements have been loaded from the session storage we redirect the user to the project
       dashboard.
     */
    if (!this.requirementIds) {
      this.props.navigate(UIUtils.getSecuredURL(`/projects/dashboard.html?projectId=${this.projectId}`));
    }

    let projects = new TypeaheadObjectCache("Project").getOptionsFromCacheIncludingArchived();
    this.parentProject = projects.length > 0 && projects.find(project => project.id === UIUtils.parseInt(this.projectId));
    this.isProjectArchived = (this.parentProject && !!this.parentProject.deletedAt);
  }

  initializeData() {
    this.setStateSafely({
      ...this.getInitialState(),
      project: this.parentProject
    });

    if (this.requirementIds.length > 0) {
      UIUtils.setLoadingDisabled(false);
      UIUtils.setHideLoadingOnAjaxStop(false);
      UIUtils.showLoadingImage(`Loading ${this.requirementIds.length} Records`);

      this.loadRecords();
    }
  }

  loadRecords() {
    /* Chuck the data to be loaded into buckets of 500 records each. For number of records above this size, the lambda
         handler runs into the danger of exceeding the 30 seconds API Gateway limit and failing.
       */
    const requirementIds = [...this.requirementIds];
    const recordChunkSize = 500;
    this.recordChunkLength = Math.ceil(requirementIds.length / recordChunkSize);
    let chunkCounter = 0;
    this.loadedRecordChunks = 0;
    for (let i = 0, j = requirementIds.length; i < j; i += recordChunkSize) {
      const recordIdsSlice = requirementIds.slice(i, i + recordChunkSize);
      chunkCounter++;

      UIUtils.secureAjaxPUT(`editables/multiple/list/${this.projectId}?` +
        "onlyLoadDetails=true" +
        "&includeHistory=true" +
        "&includeLastViewedVersion=true" +
        "&requestedByHomePage=" + this.requestedByHomePage.toString() +
        "&includeRiskInfo=true" +
        (this.useWriterDB ? "&useWriterDB=true" : ""), {
        requirementIds: recordIdsSlice,
      }, false).done((results) => this.onAjaxResult(results, chunkCounter));
    }
  }

  onAjaxResult(results, chunkCounter) {
    for (const record of results.instances) {
      this.instances.push(record);
      UIUtils.showLoadingImage(`Loading ${this.instances.length}/${this.requirementIds.length} Records`);
    }

    this.handlePartialLoadingCompleted(chunkCounter);
  }

  handlePartialLoadingCompleted(chunkNumber) {
    this.loadedRecordChunks++;
    Logger.verbose(() => `Loading completed in ${Date.now() - this.startLoadingTime} ms for chunk ${chunkNumber}`);
    if (this.loadedRecordChunks === this.recordChunkLength) {
      UIUtils.showLoadingImage("Preparing data for display");

      // split records according to state
      this.nonDraftInstances = this.instances.filter((instance) => instance.currentState !== "Draft");
      this.instances = this.instances.filter((instance) => instance.currentState === "Draft");

      const instances = this.instances;
      setTimeout(() => {
        this.handleReceiveDataFromServer({
          instances
        });
      }, 50);
    }
  }

  finalizeDataPreparation() {
    if (this.instances.length === this.requirementIds.length && !this.state.instances) {
      UIUtils.showLoadingImage("Preparing data for display");

      // split records according to state
      this.nonDraftInstances = this.instances.filter((instance) => instance.currentState !== "Draft");
      this.instances = this.instances.filter((instance) => instance.currentState === "Draft");

      setTimeout(() => {
        this.handleReceiveDataFromServer({
          instances: this.instances,
        });
      }, 50);
    }
  }

  getAttributesInformationStatusChange(message) {
    if (message && message.data) {
      this.instances.push(message.data);
      UIUtils.showLoadingImage(`Loading ${this.instances.length}/${this.requirementIds.length} Records`);
    }
  }

  handleReceiveDataFromServer(results) {
    // Uncomment for verbose logging
    // console.log("Received bulk proposal results: " + UIUtils.stringify(results));

    let formattedResults = this.formatBulkProposalData(results);

    this.setStateSafely({
      ...formattedResults,
      selectedRows: formattedResults.instances.filter(instance => !instance.disabled),
      recordSaved: false,
      searchItems: formattedResults.instances.map(instance => {
        return {
          id: instance.id,
          name: instance.name,
          label: `${UIUtils.getRecordLabelForDisplay(
            UIUtils.getTypeCodeForModelName(instance.modelName), instance.id, instance.name)}`
        };
      })
    });
  }

  formatBulkProposalData(data, modifiedRequirementId) {
    for (let instance of data.instances) {
      let instanceVersions = instance[instance.modelName + "Versions"];
      let lastDraftVersion = instanceVersions[0];
      let lastViewedVersion = instanceVersions.find(version => version.id === (instance.lastViewed && instance.lastViewed.versionId));
      instance.lastDraftVersion = `v${lastDraftVersion.majorVersion}.${lastDraftVersion.minorVersion}`;
      instance.lastDraftAuthor = lastDraftVersion.createdByUser;
      if (!instance.lastViewedVersion || modifiedRequirementId === instance.id) {
        instance.lastViewedVersion = lastViewedVersion ? `v${lastViewedVersion.majorVersion}.${lastViewedVersion.minorVersion}` : null;
      }

      //This will trigger a validation for proposal.
      instance.approvalInfo = {};
      instance.formValidationMode = CommonUtils.FORM_VALIDATION_MODE.PROPOSE;

      let errors = RecordValidationHelper.verifyRecordForPropose(instance);
      if (instance.modelName === "RMP") {
        let {modelName, id} = instance;

        instance.disabled = true;
        instance.disabledTooltip =
          (
            <span>
              Risk Management Plans should be proposed from the&nbsp;
              <a href={getURLByTypeCodeAndId(modelName, "View", id)}
                 rel="noopener noreferrer"
                 target="_blank"
              >
                Risk Management section
              </a>
            </span>
          );
        instance.showAsInformative = true;
      } else if (errors.length > 0) {
        instance.disabled = true;
        instance.disabledTooltip = errors;
      } else {
        instance.disabled = false;
        delete instance.disabledTooltip;
      }

      //We need to reset this back to null as saving the requirement on the backend in the quick edit will fail
      instance.approvalInfo = null;
    }

    return data;
  }

  handleRowClick(rowData, cellIndex, callback) {
    if (rowData.ProjectId && this.requestedByHomePage) {
      loadRMP((rmp, project) => {
        rowData.RMP = rmp;
        rowData.ProjectWithAllVersions = project;
        rowData.requestedByHomePage = this.requestedByHomePage;
        this.handleReceiveRMP(rowData, callback);
      }, rowData.ProjectId);
    } else {
      this.handleReceiveRMP(rowData, callback);
    }
  }

  handleReceiveRMP(rowData, callback) {
    $("[data-toggle='popover']").popover("hide");

    let {modelName, id} = rowData;
    const quickPanel = this.quickPanel.current;

    if (modelName === "RMP" || !(quickPanel && quickPanel.isVisible())) {
      window.open(UIUtils.getSecuredURL(getURLByTypeCodeAndId(modelName, "View", id)), "_blank");
      return;
    }

    this.setStateSafely({
      quickPanelInfo: {
        selectedTypeCode: UIUtils.getTypeCodeForModelName(rowData.modelName),
        selectedRecord: rowData,
        operation: RECORD_OPERATIONS.BULK_PROPOSE,
      }
    }, () => {
      if (callback) {
        callback();
      }
    });

    quickPanel.expandToOpen();
  }

  handleSelectedRowsChanged(rows) {
    if (!this.state.processingBulkProposal) {
      this.setStateSafely({
        selectedRows: rows.selectedRows,
        recordSaved: false
      });
    }
  }

  handleSaveRequirementButtonClick(requirement) {
    let modelName = this.state.quickPanelInfo.selectedRecord.modelName;
    let modifiedRequirementIndex = this.state.instances.findIndex(instance => instance.id === requirement.id && instance.modelName === modelName);
    this.state.instances[modifiedRequirementIndex] = requirement;

    let newState = {
      instances: this.formatBulkProposalData({instances: this.state.instances}, requirement.id).instances,
      modifiedRequirement: requirement,
      recordSaved: true,
      quickPanelInfo: {
        selectedRecord: requirement,
        selectedTypeCode: this.state.quickPanelInfo.selectedTypeCode,
        operation: this.state.quickPanelInfo.operation,
      }
    };

    this.setStateSafely(newState);
  }

  handleHideModal() {
    this.setStateSafely({
      showApprovalRequestPopup: false,
      recordSaved: false
    });
  }

  bulkProposeButtonClick() {
    this.setStateSafely({
      showApprovalRequestPopup: true,
      approvalInfo: null,
      recordSaved: false
    });
  }


  /**
   * Displays the status message
   * @param message {string}
   * @param details {*}
   */
  displayStatus(message, details) {
    // only sets the status if there's a valid value
    if (message) {
      this.setStateSafely({
        bulkProposalStatus: message,
        ...(details || {}),
      });
    }
    // Uncomment for verbose logging
    //console.log("Displaying status:", message);
  }

  handleWebSocketConnectionMessage(message) {
    const messageContent = message.data;
    const displayMessage = (message.message || messageContent.message) || "";

    let proposalResult = {
      result: message.status,
      message: displayMessage.split("\n"),
      id: messageContent.id,
      modelName: messageContent.modelName
    };

    let selectedRows = this.state.selectedRows;
    let processedRow = selectedRows.find(row => row.modelName === proposalResult.modelName && row.id === proposalResult.id);
    if (!processedRow) {
      throw new Error(`Unable to find a selected row for ${proposalResult.modelName} with id ${proposalResult.id}. Selected rows length: ${selectedRows.length}`);
    }
    processedRow.proposalResult = proposalResult;

    this.setStateSafely({
      proposalResult,
      selectedRows,
      processedRows: this.state.processedRows + (message.status === "success" ? 1 : 0),
      failedRows: this.state.failedRows + (message.status === "failure" ? 1 : 0),
      recordSaved: false
    });
  }

  async handleSendApprovalRequest() {
    /* We need to clean the session storage so that reloading the page does not reload the same requirements for which
       a response has been submitted.
     */
    this.context.clearPreventNavigation();
    sessionStorage["bulkProposalRequirementIds"] = "[]";

    const editablesService = new EditablesService(this.messageHandler, this.statusService);
    const payload = {
      approvalInfo: {
        approvers: this.getApprovalValue("approvers"),
        approveByDate: this.getApprovalValue("approveByDate"),
        comment: this.getApprovalValue("comment"),
      },
      requirements: JSON.stringify(this.state.selectedRows.map(row => {
        return {
          id: `${UIUtils.getTypeCodeForModelName(row.modelName)}-${row.id}`,
          LastVersionId: row.LastVersionId
        };
      }))
    };

    const promise = editablesService.propose(payload, {model: "multiple"})
      .catch(UIUtils.defaultFailFunction)
      .finally(() => {
        this.context.clearPreventNavigation();
        this.setStateSafely({bulkProposalStatus: BULK_PROPOSAL_STATUS.COMPLETED, recordSaved: false});
      });

    this.runPromise(promise);

    if (this.approvalRequestPopup) {
      $(this.approvalRequestPopup).modal("hide");
    }

    this.setStateSafely(
      {
        processingBulkProposal: true,
        processedRows: 0,
        failedRows: 0,
        recordSaved: false
      }, () => {
        this.props.preventNavigation();
      }
    );
  }

  /**
   * This is called when the user has chosen to cancel the loading of a new record in the quick panel.
   */
  handleCancelQuickEditLoad(oldTypeCode, oldId) {
    let oldSelectedRecord = this.state.instances.find(instance => {
      return instance.id === oldId
        && instance.modelName === UIUtils.convertToId(UIUtils.getModelNameForTypeCode(oldTypeCode));
    });

    this.setStateSafely({
      quickPanelInfo: {
        selectedTypeCode: oldTypeCode,
        selectedRecord: oldSelectedRecord
      }
    });
  }

  /**
   * This is called when the user has chosen to switch from edit to view mode.
   */
  handleChangeEditorOperation(newEditorOperation, data, callback) {
    this.setStateSafely({quickPanelOperation: newEditorOperation}, () => UIUtils.invokeCallback(callback, data));
  }


  handleRecordLastViewedVersionUpdated(modelName, id, version) {
    let updatedRecord = this.state.instances.find(instance => instance.id === id && instance.modelName === modelName);
    updatedRecord.lastViewedVersion = version;
    this.setStateSafely(this.state);
  }

  renderRiskLinkConfirmationDialog() {
    const {show, isDeleteConfirmation, message, documents, onHideModal, onChangeRiskLinks} = this.state;
    let result = EMPTY_STRING;
    if (show) {
      if (isDeleteConfirmation) {
        result = (
          <DeleteRiskLinksConfirmationPopup
            modalRef={riskConfirmationPopup => this.deleteRiskConfirmationPopup = riskConfirmationPopup}
            action={"Remove Risk"}
            message={message}
            documents={documents}
            onHideModal={onHideModal}
            onDeleteRiskLinksAction={onChangeRiskLinks}
            parent={this}
          />
        );
      } else {
        result = (
          <EditRiskLinksConfirmationPopup
            modalRef={riskConfirmationPopup => this.editRiskConfirmationPopup = riskConfirmationPopup}
            action={"Change Risk"}
            message={message}
            documents={documents}
            onHideModal={onHideModal}
            onEditRiskLinksAction={onChangeRiskLinks}
            parent={this}
          />
        );
      }
    }
    return result;
  }

  onRiskLinkConfirmationDialogRender(show, isDeleteConfirmation, message, documents, onHideModal, onChangeRiskLinks) {
    const showPopup = this.state.show;
    if (showPopup !== show || (show === true && !this.deleteRiskConfirmationPopup) || (show === true && !this.editRiskConfirmationPopup)) {
      this.setStateSafely({
        show, isDeleteConfirmation, message, documents, onHideModal, onChangeRiskLinks
      });
    }
    if (isDeleteConfirmation && this.deleteRiskConfirmationPopup && !show) {
      $(this.deleteRiskConfirmationPopup).modal("hide");
    } else if (!isDeleteConfirmation && this.editRiskConfirmationPopup && !show) {
      $(this.editRiskConfirmationPopup).modal("hide");
    }
  }

  renderPage() {
    let {
      instances,
      modifiedRequirement,
      recordSaved,
      selectedRows,
      processingBulkProposal,
      bulkProposalStatus,
      proposalResult,
      showApprovalRequestPopup,
      quickPanelInfo,
      processedRows,
      failedRows,
      quickPanelOperation,
      project
    } = this.state ? this.state : {};

    project = project || {};

    const {
      selectedTypeCode,
      selectedRecord,
      operation,
    } = quickPanelInfo ? quickPanelInfo : {};

    const allRows = instances || [];
    selectedRows = selectedRows || [];
    const isBulkProcessingComplete = bulkProposalStatus === BULK_PROPOSAL_STATUS.COMPLETED;

    let canUserPropose = !selectedRows.find(row => row.disabled);
    let quickPanelConfig = {
      showHistory: false,
      saveBehavior: SAVE_BEHAVIOR_ENUM.SAVE,
      quickPanelButtons: [QUICK_PANEL_BUTTONS.SAVE, QUICK_PANEL_BUTTONS.CHECK_FOR_PROPOSE, QUICK_PANEL_BUTTONS.DIVIDER, QUICK_PANEL_BUTTONS.RESET, QUICK_PANEL_BUTTONS.SWITCH_MODE],
      sectionsCollapsed: false,
      autoValidationMode: AUTO_VALIDATION_MODE_ENUM.PROPOSE
    };

    return (
      <div>
        {this.renderRiskLinkConfirmationDialog()}
        {this.requestedByHomePage || this.isTopLevelRecord ?
          <BulkProposalApprovalTitleBar title="Propose" /> :
          <Fragment>
            <EditablesPageTitleBar projectId={project.id}
                                   projectName={project.name}
                                   projectDeletedAt={project.deletedAt}
                                   isDemoProject={project.isDemo}
                                   currentState={project.currentState}
            />
            <NavBar selected="Dashboard" projectId={this.projectId} />
          </Fragment>}
        <div className="container">
          {this.requestedByHomePage || this.isTopLevelRecord ? <div className="row-space-below" /> :
            <div id="dashboardHeader">
              <Heading level={1} className="header-banner">{!processingBulkProposal ? "Propose" : "Processing Bulk Proposal"}</Heading>
              <div className="bulk-proposal-title-bar-breadcrumb row-space-below">
                <span>
                &larr;
                  <QBDLink to={`/projects/dashboard.html?projectId=${this.projectId}`} id="backToDashboardLink"> Back to Dashboard</QBDLink>
              </span>
              </div>
            </div>}
          <ErrorBar />
          {!processingBulkProposal ? (
            <QuickPanel ref={this.quickPanel}
                        typeCode={selectedTypeCode}
                        operation={operation}
                        id={selectedRecord ? selectedRecord.id : null}
                        config={quickPanelConfig}
                        editorType={EDITOR_TYPES.QUICK_PANEL}
                        editorOperation={quickPanelOperation}
                        data={selectedRecord}
                        onSaveCompleted={this.handleSaveRequirementButtonClick}
                        originalWidth={700}
                        onCancelLoad={this.handleCancelQuickEditLoad}
                        onChangeEditorOperation={this.handleChangeEditorOperation}
                        onRiskLinkConfirmationDialogRender={this.onRiskLinkConfirmationDialogRender}
            />
          ) : ""}
          <div className="row row-white shadow row-space-below">
            <div className="col-sm-12">
              {processingBulkProposal ? (
                <div className="row bulk-operation-bar-margin">
                  <div id="proposalResultsInfoDiv" className="col-sm-12 alert alert-info">
                    {isBulkProcessingComplete
                      ? `${processedRows} of ${processedRows + failedRows} records processed successfully.`
                      : bulkProposalStatus === BULK_PROPOSAL_STATUS.EMAILS
                        ? "Sending approval request emails..."
                        : "Leave this page open only if you want to monitor the progress."}
                  </div>
                </div>
              ) : ""}
              {this.actualRequirementIdsLength > MAX_RECORDS_LIMIT && !processingBulkProposal ?
                <div className="row">
                  <div id="bulkOperationWarningDiv"
                       className="alert alert-warning bulk-operation-warning"
                  >
                    Too many drafts to load for bulk proposal. Showing the first <span>{MAX_RECORDS_LIMIT}</span> drafts.
                    Please use the bulk proposal functionality inside each project to limit
                    the number of drafts shown to each project.
                  </div>
                </div>
                : this.nonDraftInstances.length ?
                  <div className="row">
                    <div id="bulkOperationWarningDiv"
                         className="alert alert-warning bulk-operation-warning"
                    >
                      <span>{this.nonDraftInstances.length}</span> record{this.nonDraftInstances.length > 1 ? "s" : ""} do not
                      have drafts and {this.nonDraftInstances.length > 1 ? "have" : "has"} been removed from the table.
                    </div>
                  </div>
                  : ""}
              <div className="row">
                {processingBulkProposal ? "" :
                  <div className="col-10 bulk-proposal-approval-header-title">
                    {allRows.length} Pending Drafts
                  </div>
                }
                <div className="col-2">
                  {!processingBulkProposal ? "" : (
                    <Fragment>
                      <div className="progress bulk-proposal-progress-bar">
                        <div className="progress-bar bg-success"
                             role="progressbar"
                             style={{width: `${selectedRows.length > 0 ? (isBulkProcessingComplete ? 100 : 90) * processedRows / selectedRows.length + "%" : "0%"}`}}
                             aria-valuenow="0"
                             aria-valuemin="0"
                             aria-valuemax="100"
                        />
                        <div className="progress-bar bg-danger"
                             role="progressbar"
                             style={{width: `${selectedRows.length > 0 ? (isBulkProcessingComplete ? 100 : 90) * failedRows / selectedRows.length + "%" : "0%"}`}}
                             aria-valuenow="0"
                             aria-valuemin="0"
                             aria-valuemax="100"
                        />
                      </div>
                      <div id="bulkProposalProgressbarTitle">
                        <span>{"Processed " + (processedRows + failedRows) + "/" + selectedRows.length}</span>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              <div className="row dashboard-bulk-operation-bottom-margin">
                <div className="col-sm-12">
                  {processingBulkProposal ? (
                    <BulkProposalResultsTable id="bulkProposalResultsTable"
                                              data={this.state.selectedRows || []}
                                              proposalResult={proposalResult}
                                              hideCheckBoxes={true}
                                              requestedByHomePage={this.requestedByHomePage}
                                              paging={false}
                                              datatablesDomOptions={"<t>"}
                                              projectId={this.projectId}
                    />
                  ) : (
                    <BulkProposalReviewTable id="bulkProposalReviewTable"
                                             pageLength={50}
                                             parent={this}
                                             data={instances || []}
                                             paging={false}
                                             searchItems={this.state.searchItems || []}
                                             modifiedRequirement={modifiedRequirement}
                                             recordSaved={recordSaved}
                                             showSearchBar={true}
                                             requestedByHomePage={this.requestedByHomePage}
                                             selectedRequirement={selectedRecord}
                                             showSelectedRowsInfo={false}
                                             datatablesDomOptions={DATATABLES_DOM}
                                             columnsToTriggerQuickPanel={["ID", "Name"]}
                                             onRowClick={this.handleRowClick}
                                             onSelectedRowsChanged={this.handleSelectedRowsChanged}
                                             onRecordLastViewedVersionUpdated={this.handleRecordLastViewedVersionUpdated}
                                             defaultSortColumnIndex={2}
                                             projectId={this.projectId}
                    />
                  )}
                </div>
              </div>
              {!processingBulkProposal ? (
                <div className="row">
                  <div className="col-sm-12">
                    <button id="bulkProposeButton"
                            onClick={this.bulkProposeButtonClick}
                            className={"btn btn-lg btn-primary dashboard-bulk-proposal-button"}
                            title={this.isProjectArchived
                              ? "Bulk proposal of requirements is not allowed for archived projects."
                              : !canUserPropose
                                ? "One or more selected requirements cannot be proposed"
                                : selectedRows.length === 0
                                  ? "Please select at least one row to include in the bulk proposal"
                                  : ""}
                            disabled={this.isProjectArchived || !canUserPropose || selectedRows.length === 0}
                    >
                      Propose
                    </button>
                  </div>
                </div>
              ) : ""}
            </div>
          </div>
        </div>
        {showApprovalRequestPopup && !processingBulkProposal ?
          <ApprovalRequestPopup modalRef={approvalRequestPopup => this.approvalRequestPopup = approvalRequestPopup}
                                action={this.state.action}
                                parent={this}
                                project={project}
                                onHideModal={this.handleHideModal}
                                isBulkProposal={true}
                                editorType={EDITOR_TYPES.QUICK_PANEL}
                                selectedRequirements={this.state.selectedRows}
                                onSendApprovalRequest={this.handleSendApprovalRequest}
          />

          : ""}
      </div>
    );
  }
}

export default withRouter(BulkProposalPage);