"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import CompanyLoginHeader from "../../widgets/headers/company_login_header";
import PasswordRequirementsPanel from "../../widgets/user/password_requirements_panel";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseReactComponent from "../../base_react_component";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import { RetryPleaseError, RetryWrapper } from "../../helpers/retry_wrapper";
import * as CognitoHelper from "../../helpers/cognito_helper";
import { Button } from "@qbdvision-inc/component-library";
import CommonUtils from "../../../server/common/generic/common_utils";
import { Auth } from "aws-amplify";


const Logger = Log.group(LOG_GROUP.Users, "UserResetPassword");

// i18next-extract-mark-ns-start users
class UserResetPassword extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.setStateSafely(
      {
        verificationCode: "",
        newPassword: "",
        confirmPassword: "",
        action: UIUtils.getParameterByName("action"),
      });
  }

  componentDidMount() {
    const {t} = this.props;
    document.title = t("QbDVision Manage Account");

    super.componentDidMount();
  }

  async resetPassword(event) {
    event.preventDefault();
    event.stopPropagation();
    const {t} = this.props;

    //data-match does not work with bootstrap validator
    //https://github.com/1000hz/bootstrap-validator/issues/449
    //https://github.com/1000hz/bootstrap-validator/issues/450
    let inputs = $(".form-control");
    let formIsValid = true;

    $.each(inputs, (key, val) => {
      formIsValid = formIsValid && !($(val).closest(".form-group").is(".has-error"));
    });

    if (event.target.checkValidity() && formIsValid) {

      UIUtils.showLoadingImage();
      const username = UIUtils.getParameterByName("username");

      if (this.state.action === "resetSigningPin") {
        const result = await UIUtils.secureAjaxGET(`users/userAPI?activity=resetSigningPin`,
          {
            username,
            verificationCode: this.state.verificationCode,
            newSigningPin: this.state.newSigningPin,
          });

        if (result.error) {
          UIUtils.showError(result.error);
          UIUtils.hideLoadingImage();
        } else {
          console.log("Signing pin confirmed!");
          window.location.href = UIUtils.getSecuredURL("/index.html");
        }

        UIUtils.hideLoadingImage();
      } else {
        const {verificationCode, newPassword} = this.state;
        CognitoHelper.configureUserPool();

        // noinspection JSIgnoredPromiseFromCall
        await new RetryWrapper(
          async() => {
            try {
              await Auth.forgotPasswordSubmit(username, verificationCode, newPassword);
              console.log("Password confirmed!");
              window.location.href = UIUtils.getSecuredURL("/index.html");
            } catch (err) {
              if (CognitoHelper.isCognitoErrorRetryable(err)) {
                Logger.info(() => "Retrying because of " + UIUtils.stringify(err));
                throw new RetryPleaseError();
              } else {
                UIUtils.showError(err.message || JSON.stringify(err));
                UIUtils.hideLoadingImage();
                throw err;
              }
            }
          },
          (ignored, waitInMS) => UIUtils.showError(t("Cannot reset password. Retrying in {{ retryWait }} seconds...", {retryWait: waitInMS / 1000}))
        ).retryFunction();
      }
    }
  }

  handleChange(event) {
    this.setStateSafely({[event.target.name]: event.target.value});
  }

  render() {
    const {t} = this.props;
    const isResetSigningPin = this.state.action === "resetSigningPin";
    const showCreateCompanyButton = !CommonUtils.isCommercialEnvironment();

    return (
      <div className="container-fluid">
        <CompanyLoginHeader firstHeader={t("Let's get you back on your feet.")}
                            paragraph={<div className="text-center">
                              <p>{t(`Please check your email ({{ email }}) for the verification code.`, {email: UIUtils.getParameterByName("email")})}<br />
                                {t("If the verification code doesn't arrive in a few minutes, please check your spam folder.")}
                              </p>
                            </div>}
        />
        {this.state.action === "resetSigningPin" ? "" : <PasswordRequirementsPanel />}
        <br />
        <div className="center-single-column-grid">
          <div className="alert alert-danger d-none" role="alert" id="alertDiv" />
          <div className="row">
            <div className="col-sm-12">
              <form data-toggle="validator" role="form" onSubmit={this.resetPassword}>
                <div className="form-group">
                  <input type="text"
                         className="form-control"
                         placeholder={t("Verification Code")}
                         id="verificationCodeInput"
                         required={true}
                         name="verificationCode"
                         onChange={this.handleChange}
                         data-error={t("Verification code is required.")}
                  />
                  <div id="verificationCodeInputErrorDiv" className="help-block with-errors" />
                </div>
                {isResetSigningPin ?
                  <div className="form-group">
                    <input type="password"
                           className="form-control"
                           placeholder={t("New Signing Pin")}
                           name="newSigningPin"
                           autoComplete="one-time-code"
                           id="signingPinInput"
                           data-minlength="6"
                           data-error={t("Password is required.")}
                           pattern="^(?=.*).{6,}$"
                           data-pattern-error={t("Pins must be at least 6 characters long.")}
                           required
                           onChange={this.handleChange}
                    />
                    <div id="newPinInputErrorDiv" className="help-block with-errors" />
                  </div> :
                  <div className="form-group">
                    <input type="password"
                           className="form-control"
                           name="newPassword"
                           id="newPasswordInput"
                           data-minlength="8"
                           data-error={t("Password is required.")}
                           pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$"
                           data-pattern-error={t("Password is incorrect. See password requirements above.")}
                           required placeholder={t("New Password")}
                           onChange={this.handleChange}
                    />
                    <div id="newPasswordInputErrorDiv" className="help-block with-errors" />
                  </div>}
                {isResetSigningPin ?
                  <div className="form-group">
                    <input type="password"
                           className="form-control"
                           name="confirmPin"
                           placeholder={t("Confirm Signing Pin")}
                           autoComplete="one-time-code"
                           id="confirmSigningPinInput"
                           data-match="#signingPinInput"
                           data-error={t("Pin does not match.")}
                           data-required-error={t("Confirm Pin is required.")}
                           required
                           onChange={this.handleChange}
                    />
                    <div id="confirmPinInputErrorDiv" className="help-block with-errors" />
                  </div> :
                  <div className="form-group">
                    <input type="password"
                           className="form-control"
                           name="confirmPassword"
                           id="confirmPasswordInput"
                           data-match="#newPasswordInput"
                           data-error={t("Password does not match.")}
                           data-required-error={t("Confirm Password is required.")}
                           required placeholder={t("Confirm Password")}
                           onChange={this.handleChange}
                    />
                    <div id="confirmPasswordInputErrorDiv" className="help-block with-errors" />
                  </div>}
                <br />
                <div className="form-group">
                  <Button id="resetPasswordButton"
                          isSubmit
                          label={isResetSigningPin ? t("Reset Signing Pin") : t("Reset Password")}
                          isFullWidth
                  />
                </div>
                <br />
                <div className="form-group">
                  <p className="text-center loginPageLinks-text">
                    <a href="../index.html">{t("Login")}</a>
                    {showCreateCompanyButton ?
                      <Fragment><span style={{color: "black"}}> | </span><a id="createCompanyButton" href="./createCompany.html">{t("Create Company")}</a></Fragment>
                      : ""}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(UserResetPassword, "users");
// i18next-extract-mark-ns-stop users
