var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { FindAndReplaceDialog } from '../dialogs/FindReplace';
import { onDownPreventDefault } from './utils';
import { messages } from './../messages';
import { EditorToolsSettings } from './../config/toolsSettings';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { classNames } from '@progress/kendo-react-common';
var settings = EditorToolsSettings.findAndReplace;
/**
 * @hidden
 */
var FindAndReplace = /** @class */ (function (_super) {
    __extends(FindAndReplace, _super);
    function FindAndReplace() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * @hidden
         */
        _this.state = { showDialog: false };
        _this.onClose = function () { return _this.setState({ showDialog: false }); };
        _this.onOpen = function () { return _this.setState({ showDialog: true }); };
        return _this;
    }
    /**
     * @hidden
     */
    FindAndReplace.prototype.render = function () {
        var _a = this.props, view = _a.view, buttonProps = __rest(_a, ["view"]);
        var props = settings.props;
        var localization = provideLocalizationService(this);
        var titleKey = settings.messages.findReplaceToolTitle;
        var disabled = !view;
        return [(React.createElement(Button, __assign({ onClick: disabled ? undefined : this.onOpen, "aria-disabled": disabled ? true : undefined }, onDownPreventDefault, { title: localization.toLanguageString(titleKey, messages[titleKey]), key: "tool" }, props, buttonProps, { className: classNames(buttonProps.className, props.className, { 'k-disabled': disabled }) }))), this.state.showDialog && view && (React.createElement(FindAndReplaceDialog, { view: view, onClose: this.onClose, dir: buttonProps.dir, key: "dialog" }))];
    };
    return FindAndReplace;
}(React.Component));
export { FindAndReplace };
registerForLocalization(FindAndReplace);
