var style = function (name, value) {
    return { name: name, value: value };
};
export var alignLeftRules = [
    { node: 'paragraph', style: [style('text-align', 'left')] },
    { node: 'heading', style: [style('text-align', 'left')] }
];
export var alignRightRules = [
    { node: 'paragraph', style: [style('text-align', 'right')] },
    { node: 'heading', style: [style('text-align', 'right')] }
];
export var alignCenterRules = [
    { node: 'paragraph', style: [style('text-align', 'center')] },
    { node: 'heading', style: [style('text-align', 'center')] }
];
export var alignJustifyRules = [
    { node: 'paragraph', style: [style('text-align', 'justify')] },
    { node: 'heading', style: [style('text-align', 'justify')] }
];
export var alignRemoveRules = [
    { node: 'paragraph', style: [style('text-align', '')] },
    { node: 'heading', style: [style('text-align', '')] }
];
