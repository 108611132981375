"use strict";

import * as UIUtils from "../../../ui_utils";
import { BaseDataTransform } from "../base_data_transform";
import {
  checkEmptySummaryData,
  convertLinksToJson,
  convertLinksToOneLine, processDraftRegularities,
  processEmptyFields,
  sortTPPs,
  isDraft,
} from "../../canned_reports/canned_report_helper";
import { REPORT_DRAFT_ENUM } from "../../constants/report_constants";

export class TPPSummaryDataTransform extends BaseDataTransform {

  get type() {
    return "tppSummary";
  }

  shouldRun(options) {
    return true;
  }

  getSupportedRecordTypes() {
    return ["tpps"];
  }

  transform(input, options) {
    const result = input;
    const data = result.instances;

    if (data.tpps) {
      // sorts TPPs by id (ascending)
      data.tpps.sort(sortTPPs);

      data.tpps.forEach((tpp, index) => {
        tpp.sortIndex = index + 1;
        tpp.name = UIUtils.secureString(tpp.name);
        tpp.links = convertLinksToJson(tpp.links, tpp.id);
        tpp.linkSummary = convertLinksToOneLine(tpp.links, {allowHTML: true});
        tpp.FQAs = tpp.FQAs.join("\r\n");
        tpp.version = tpp.minorVersion === 0 ? (tpp.majorVersion + "." + tpp.minorVersion) : "No Approved Version";
        tpp.draftMarker = isDraft(tpp) ? REPORT_DRAFT_ENUM.DraftMarker : "";
        processEmptyFields(tpp);
        options.showDraftAlerts = options.showDraftAlerts ? options.showDraftAlerts : isDraft(tpp);
      });
    }

    processDraftRegularities(data);
    checkEmptySummaryData(data, data.tpps);

    result.instances = data;
    return result;
  }
}
