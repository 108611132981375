"use strict";

/**
 * State mutation actions for the whole flow
 */
export const DEFAULT_ACTIONS = {
  NavTabClicked: "NavTabClicked",
  PreviousStep: "PreviousStep",
  StepDataUpdated: "StepDataUpdated",
  NextButtonClicked: "NextButtonClicked",
  LibraryMaterialsLoaded: "LibraryMaterialsLoaded",
  UpdateStepState: "UpdateStepState",
  ReloadState: "ReloadState",
};

/**
 * State mutation actions for the material options step.
 */
export const MATERIAL_OPTIONS_ACTIONS = {
  UpdateFieldValue: "UpdateFieldValue",
};