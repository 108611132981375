"use strict";

/* Use this file to add any functions that transform information in any of the editables records to some
other format.
 */

const {isUpperLimitDisabled, isLowerLimitDisabled} = require("./common_editables");
const CommonUtils = require("../../common/generic/common_utils");

/**
 * Formats the acceptance criteria of an attribute and returns it as a string.
 * @param record The attribute record.
 * @returns {string}
 */
module.exports.getAttributeMeasurementInfoTechTransferFormat = function(record = {}) {
  const {
    lowerLimit,
    target,
    upperLimit,
    measurementUnits,
    measure,
  } = record;

  let acceptanceCriteriaSummary;
  switch (measure) {
    case CommonUtils.MEASUREMENT_TYPES.UPPER_LIMIT:
      // NMT <USL>, "Target" <target> <Measurement Units>
      acceptanceCriteriaSummary = `NMT${upperLimit ? `: ${upperLimit}` : ""}`;
      break;
    case CommonUtils.MEASUREMENT_TYPES.LOWER_LIMIT:
      // NLT <LSL>, "Target" <target> <Measurement Units>
      acceptanceCriteriaSummary = `NLT${lowerLimit ? `: ${lowerLimit}` : ""}`;
      break;
    case CommonUtils.MEASUREMENT_TYPES.RANGE: {
      // <Measurement Type> <LSL> "-" <USL>, "Target" <target> <Measurement Units>
      const limitText =
        lowerLimit && upperLimit ? `${lowerLimit} - ${upperLimit}` :
          lowerLimit || upperLimit ? `${lowerLimit || "None"} - ${upperLimit || "None"}` : "";
      acceptanceCriteriaSummary = `${measure}${limitText ? `: ${limitText}` : ""}`;
      break;
    }
    case CommonUtils.MEASUREMENT_TYPES.DEFECTS:
      // <Measurement Type> "<" <USL>, "Target" <target> <Measurement Units>
      acceptanceCriteriaSummary = `${measure}${upperLimit ? ` < ${upperLimit}` : ""}`;
      break;
    case CommonUtils.MEASUREMENT_TYPES.CONFORMS:
      // <Measurement Type> "Target" <target> <Measurement Units>
      acceptanceCriteriaSummary = measure;
      break;
  }

  acceptanceCriteriaSummary += target ? `, Target: ${target}` : "";
  acceptanceCriteriaSummary += (lowerLimit || upperLimit || target) && measurementUnits ? ` ${measurementUnits}` : "";
  return acceptanceCriteriaSummary !== "undefined" ? acceptanceCriteriaSummary : "";
};

/**
 * Formats the acceptance criteria of an attribute and returns it as a string.
 * @param attribute The attribute record.
 * @returns {string|string}
 */
module.exports.acceptanceCriteriaToString = function(attribute) {
  const lowerLimit = isLowerLimitDisabled(attribute.measure) ? "" : attribute.lowerLimit || attribute.lsl || "";
  const upperLimit = isUpperLimitDisabled(attribute.measure) ? "" : attribute.upperLimit || attribute.usl || "";
  return lowerLimit || upperLimit
    ? `[${lowerLimit} - ${upperLimit}]`
    : "None";
};

/**
 * Formats the acceptance criteria of an attribute and returns it as a string.
 * @param attribute The attribute record.
 * @returns {string|string}
 */
module.exports.acceptanceCriteriaToStringForBatches = function(attribute) {
  /* TO DO Unify this method with the one above, acceptanceCriteriaToString. We should have one common method for
     showing acceptance criteria on the UI.
   */
  let acceptanceCriteria = "";

  const lowerLimit = attribute.lowerLimit || attribute.lsl || "";
  const upperLimit = attribute.upperLimit || attribute.usl || "";

  if (lowerLimit) {
    acceptanceCriteria += lowerLimit;
  }
  if (upperLimit) {
    acceptanceCriteria += "-" + (upperLimit.startsWith("-") ? `(${upperLimit})` : upperLimit);
  }

  return acceptanceCriteria;
};

module.exports.getAttributeMeasurementInfo = function(attribute, isTechTransferFormat = false) {
  const acceptanceCriteria = exports.acceptanceCriteriaToStringForBatches(attribute);
  const measurementInfo = [];

  if (attribute.measure) {
    measurementInfo.push(attribute.measure);
  }

  if (attribute.measurementUnits && !isTechTransferFormat) {
    measurementInfo.push(attribute.measurementUnits);
  }

  if (acceptanceCriteria) {
    measurementInfo.push(acceptanceCriteria);
  }

  if (attribute.measurementUnits && isTechTransferFormat) {
    measurementInfo.push(attribute.measurementUnits);
  }

  return measurementInfo.join(", ");
};

/**
 * Parses the JSON array that contains the categories in the database
 * and converts it to a comma-separated list of strings to be displayed in the report
 * @param value The attribute value to format
 * @param prefix {String=}
 * @param separator {String} The separator
 * @returns {String} A string containing the formatted list of categories.
 */
module.exports.formatMultiSelectValueForDisplay = function(value, prefix, separator = ", ") {
  return typeof value === "string" && value.startsWith("[")
    ? JSON.parse(value).map(item => prefix ? `${prefix}${item}` : item).join(separator)
    : value;
};

/**
 * Parses the JSON array that contains the Control Strategy value fromthe database
 * and converts it to a comma-separated list of strings to be displayed in the reports and the UI.
 * @param value the Control Strategy value
 * @param useNoneInsteadOfEmptyString Set this to false if you want to display an empty string instead of 'None'
 * when no Control Strategy value has been specified. The default is to display 'None'
 */
module.exports.formatControlStrategyForDisplay = function(value, useNoneInsteadOfEmptyString = true) {
  let formattedValue = exports.formatMultiSelectValueForDisplay(value);
  if (!formattedValue) {
    formattedValue = useNoneInsteadOfEmptyString ? "None" : formattedValue;
  }

  return formattedValue;
};

/**
 * This method converts a multi select typeahead value to a valid json string for saving the user selection
 * to the database.
 * @param value
 */
module.exports.multiSelectToDBString = function(value) {
  return value
    ? `[${value.split(CommonUtils.getMultiValueSeparator(value)).map(val => `"${val.trim()}"`).join(", ")}]`
    : "[]";
};
