"use strict";

import { SpecificationTransformStrategy } from "./specification_transform_strategy";
import { sortIQAVersions } from "../../../../canned_reports/canned_report_helper";

export class ProductSpecificationTransformStrategy extends SpecificationTransformStrategy {
  sortRecords(records, options) {
    records.sort((i1, i2) => i1.fQAId - i2.fQAId);
  }

  fillGeneralInformation(record, requirement, options) {
    record.fQAId = Number(requirement.FQAId);
  }

  fillParentInformation(record, requirement, options) {
    // does not get changed in this report
  }

  fillAcceptanceCriteria(record, requirement, options) {
    // does not get changed in this report
  }

  fillControlInformation(record, requirement, options) {
    // does not get changed in this report
  }

  wrapUp(result, options) {
    if (!result.instances.fqas || result.instances.fqas.length === 0) {
      result.instances.isEmptySummary = true;
    }
    return result;
  }
}
