"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import ImplementationNeededError from "../../utils/implementation_needed_error";
import BaseReactComponent from "../../base_react_component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

/**
 * This is the base class for the all panels that expand and contract horizontally.
 * @abstract
 */
export default class BaseHorizontalCollapsiblePanel extends BaseReactComponent {
  constructor(props, baseIdName) {
    super(props);

    this.baseIdName = baseIdName;
    this.basePanelId = this.baseIdName + "Panel";
    this.capitalizedBaseIdName = UIUtils.capitalize(this.baseIdName);
  }

  /**
   * Override this to add extra buttons to the top of the panel, like the reset button on the risk map filters.
   */
  renderExtraButtons() {
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener("resize", this.resizePanel);
  }

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener("resize", this.resizePanel);
  }

  componentDidUpdate() {
    this.resizePanel();
  }

  /**
   * Handle resizing the panel.
   */
  resizePanel() {
    let panel = $("#" + this.basePanelId);
    this.resizePanelOnly(panel);
  }

  resizePanelOnly(panel) {
    panel.css("height", this.getContentsHeight());
  }

  /**
   * This is used to decide when to show or hide the vertical scrollbar on the panel. When more
   * settings/filters are added to the panel, this constant will have to be recalculated for the scrollbars
   * to work nicely.
   *
   * @return {number} the approximate height of your contents.
   * @abstract
   */
  getContentsHeight() {
    throw new ImplementationNeededError();
  }

  /**
   * @return {{}} An icon to show regardless of being shown or collapsed.
   */
  getIcon() {
    throw new ImplementationNeededError();
  }

  collapse() {
    $("#" + this.basePanelId).collapse("hide");
  }

  /**
   * @return {string} the prefix of the custom css classes.
   * @abstract
   */
  getCSSPrefix() {
    throw new ImplementationNeededError();
  }

  /**
   * Render the contents of what should appear in the panel.
   * @abstract
   */
  renderPanelContent() {
    throw new ImplementationNeededError();
  }

  render() {
    return (
      <Fragment>
        <div id={this.baseIdName + "ShowButtonDiv"}
             className={`${this.baseIdName}-show-button-div ${this.getCSSPrefix()}-${this.baseIdName}-show-button-div generic-map-button`}
        >
          <button type="button"
                  id={"show" + this.capitalizedBaseIdName + "Button"}
                  className="btn btn-primary"
                  aria-label={"Show/Hide " + this.baseIdName}
                  aria-expanded="false"
                  aria-controls={this.basePanelId}
                  data-toggle="collapse"
                  data-target={"#" + this.basePanelId}
          >
            <FontAwesomeIcon icon={this.getIcon()} />
          </button>
        </div>
        <div className={`${this.baseIdName}-panel collapse horizontal-collapsible width ${this.getCSSPrefix()}-${this.baseIdName}-panel`}
             id={this.basePanelId}
        >
          {this.renderExtraButtons()}
          <div id={this.baseIdName + "CloseButtonDiv"}
               className={this.baseIdName + "-close-button-div"}
          >
            <button type="button"
                    className="btn btn-secondary btn-sm btn-close"
                    aria-label={"Hide " + this.baseIdName}
                    aria-expanded="false"
                    aria-controls={this.basePanelId}
                    data-toggle="collapse"
                    data-target={"#" + this.basePanelId}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className={`horizontal-collapsible-content ${this.getCSSPrefix()}-${this.baseIdName}-content`}>

            {this.renderPanelContent()}
          </div>
        </div>
      </Fragment>
    );
  }
}
