"use strict";

import Typeahead from "../../widgets/typeahead";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import * as UIUtils from "../../ui_utils";
import React from "react";
import TypeaheadObjectCacheFactory from "../../utils/cache/typeahead_object_cache_factory";
import BaseObjectCache from "../../utils/cache/base_object_cache";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import BaseReactComponent from "../../base_react_component";

const Logger = Log.group(LOG_GROUP.Library, "SimpleTypeaheadControl");

class SimpleTypeaheadControl extends BaseReactComponent {

  constructor(props) {
    super(props);
    this.isTypeaheadLoadStarted = new Map();
    this.attemptToLoadTypeahead();
  }

  getValue() {
    const {value} = this.props;
    return value ? value : null;
  }

  typeAheadToLoad() {
    const {typeaheadType, ProjectId, ProcessId} = this.props;
    return `${typeaheadType + ProjectId + ProcessId}`;
  }

  componentDidUpdate() {
    const typeAheadToLoad = this.typeAheadToLoad();
    if (!this.isTypeaheadLoadStarted.has(typeAheadToLoad)) {
      this.attemptToLoadTypeahead();
    }
  }

  attemptToLoadTypeahead() {
    if (this.usesTypeaheadCache()) {
      const {typeaheadType, ProjectId, ProcessId} = this.props;
      const typeaheadObjectCache = TypeaheadObjectCacheFactory.createTypeaheadObjectCacheIfPossible(
        typeaheadType, () => ProjectId, () => ProcessId
      );
      if (typeaheadObjectCache) {
        typeaheadObjectCache.loadOptions(this.handleTypeaheadResultsFromServer);
        const typeAheadToLoad = this.typeAheadToLoad();
        this.isTypeaheadLoadStarted.set(typeAheadToLoad, true);
      }
    }
  }

  handleTypeaheadResultsFromServer() {
    this.forceUpdateSafely();
  }

  getTypeaheadOptions(props = this.props) {
    const {typeaheadType, filter, ProjectId, ProcessId, options, loadArchived = false} = props;
    let typeaheadOptions = [];
    if (typeaheadType) {
      const typeaheadObjectCache = TypeaheadObjectCacheFactory
        .createTypeaheadObjectCacheIfPossible(typeaheadType, () => ProjectId, () => ProcessId);

      let typeaheadTypeOptions = [];
      if (typeaheadObjectCache) {
        if (loadArchived) {
          typeaheadTypeOptions = typeaheadObjectCache.getOptionsFromCacheIncludingArchived();
        } else {
          typeaheadTypeOptions = typeaheadObjectCache.getOptionsFromCache().filter(option => !option.deletedAt);
        }
      }

      if (!BaseObjectCache.isObjectCacheStillLoading(typeaheadTypeOptions)) {
        typeaheadOptions = typeaheadOptions.concat(typeaheadTypeOptions);
      }
    } else {
      typeaheadOptions = [options].flat();
    }

    if (filter) {
      typeaheadOptions = typeaheadOptions.filter(filter);
    }

    return typeaheadOptions;
  }

  getInputId() {
    return this.props.name + "Typeahead";
  }

  getSelectedOptionsForValue(parentAttributeName, value, typeaheadOptions) {
    let selectedOptions = [];
    if (!BaseObjectCache.isObjectCacheStillLoading(typeaheadOptions)) {
      const option = typeaheadOptions.find(option => {
        return option.id === value;
      });
      if (option) {
        selectedOptions.push(option);
      } else if (value && typeaheadOptions && typeaheadOptions.length > 0 && value !== "UNDEFINED") {
        Logger.warn(() => `WARNING: Could not find an option for ${value} in typeahead ${this.getInputId()} with values: ${Log.json(typeaheadOptions)}`);
      }
    }
    selectedOptions = selectedOptions.filter(option => !option.deletedAt);
    return selectedOptions;
  }

  isDisabled() {
    return typeof this.props.disabled === "boolean" ? this.props.disabled : false;
  }

  isLoading() {
    let returnVal = super.isLoading();

    // Look to see if the TypeaheadCache is still loading...
    const {typeaheadType, ProjectId, ProcessId} = this.props;
    const typeaheadObjectCache = TypeaheadObjectCacheFactory.createTypeaheadObjectCacheIfPossible(
      typeaheadType, () => ProjectId, () => ProcessId);
    if (typeaheadObjectCache) {
      const typeaheadTypeOptions = typeaheadObjectCache.getOptionsFromCacheIncludingArchived();
      returnVal = BaseObjectCache.isObjectCacheStillLoading(typeaheadTypeOptions);
    }

    return returnVal;
  }

  usesTypeaheadCache() {
    return this.props.typeaheadType;
  }

  getAttributeName() {
    const {name} = this.props;
    return UIUtils.convertToId(name) + "Id";
  }

  handleChange(options) {
    const {handleChangeValue} = this.props;
    let value = options.length === 0 ? null : options[0].id;
    handleChangeValue(this.getAttributeName(), value);
    this.currentTypeaheadOptions = this.getTypeaheadOptions();
    this.currentSelectedOptions = this.getSelectedOptionsForValue(this.getAttributeName(), value, this.currentTypeaheadOptions);
  }

  render() {
    const {name, required} = this.props;
    let value = this.getValue();
    let typeaheadOptions = this.getTypeaheadOptions();
    let selectedOptions = this.getSelectedOptionsForValue(this.getAttributeName(), value, typeaheadOptions);
    return (
      <div key={name} className={"col form-group"}>
        <label
          htmlFor={`${name}DropDown`}
          className="col-form-label w-100"
        >
          {this.getDisplayText()} <span className="base-attribute-value-specified">{required ? " *" : ""}</span>
        </label>
        <Typeahead
          id={`${UIUtils.convertToCamelCaseId(name)}Typeahead`}
          inputProps={{
            id: `${UIUtils.convertToCamelCaseId(name)}TypeaheadInput`,
            autoComplete: "off",
          }}
          className={this.getClassForLoading()}
          options={typeaheadOptions}
          selected={selectedOptions}
          onChange={this.handleChange}
          disabled={this.isDisabled()}
          placeholder={`Select ${this.getDisplayText()}`}
          labelKey="name"
          selectHintOnEnter
        />
      </div>
    );
  }

  getDisplayText() {
    const {name} = this.props;
    return UIUtils.convertCamelCaseToSpacedOutWords(name);
  }
}


export default I18NWrapper.wrap(SimpleTypeaheadControl, "widgets");