"use strict";

import ConfigurableTablesColumnOperationsHandler
  from "../../configurableTables/columnOperations/configurable_tables_column_operations_handler";
import * as UIUtils from "../../ui_utils";

export default class MaterialLibraryColumnOperationHandler extends ConfigurableTablesColumnOperationsHandler {

  constructor(props) {
    super(props);
  }

  handleDefaultColumnOperationsFromURL(handleColumnOperationCallback) {
    let state = {};
    super.handleDefaultColumnOperationsFromURL(state);
    let filter = UIUtils.getParameterByName("category");
    let showArchived = UIUtils.getParameterByName("showArchived");
    state = {
      filter,
      showArchived,
      ...state
    };
    handleColumnOperationCallback(state);
  }
}

