"use strict";

import * as UIUtils from "../../../ui_utils";
import {RMP} from "../../../common/models/rmp";
import * as DirectScopeWidgetParser from "../../utils/parser/direct_scope_widget_parser";
import * as DocumentLinkParser from "../../utils/parser/elementParser/document_link_parser";
import * as AcceptanceCriteriaRangeParser from "../../utils/parser/elementParser/acceptance_criteria_range_parser";
import {getRiskLinks} from "../../../helpers/risk_helper";

/**
 * When we create the direct scope element, we only save the text node using the data at that
 * time. If we update that data, its text won't get updated. We need to use the latest data to
 * change the text node to the latest data if necessary.
 *
 * @param editorContent
 * @param directScopeWidgetsData
 * @param rmp
 */
export function setLatestDataForAllElements(
  editorContent: string,
  directScopeWidgetsData: Record<string, Array<any>>,
  rmp: RMP,
) {
  const node = new DOMParser().parseFromString(editorContent, "text/html");
  const directScopeElements = Array.from(
    node.querySelectorAll<HTMLElement>(".qbd-output-direct-scope-widget"),
  );
  for (const element of directScopeElements) {
    const id = element.getAttribute("data-record-id");
    const modelName = element.getAttribute("data-record-model-name");
    const columnName = element.getAttribute("data-record-column-name");
    const subModelName = element.getAttribute("data-record-sub-model-name");
    const subModelData = element.getAttribute("data-record-sub-model-data");
    let records = directScopeWidgetsData[modelName] || [];
    let record = records.find(
      (record) => record.id === UIUtils.convertToNumber(id),
    );
    let actualValue: any;
    if (subModelName) {
      const originalData = JSON.parse(subModelData);
      if (DocumentLinkParser.isLinkAttribute(subModelName)) {
        records =
          DocumentLinkParser.getValues(record, modelName, subModelName) || [];
        record = records.find(
          (record) => record.link?.uuid === originalData?.link?.uuid,
        );
      } else if (subModelName === "Criticality") {
        records = getRiskLinks(record) || [];
        record = records.find((record) => record.id === originalData?.id);
      } else if (subModelName === "AcceptanceCriteriaRange") {
        records = AcceptanceCriteriaRangeParser.getValues(record) || [];
        record = records.find(
          (record) =>
            record.uuid === originalData.uuid ||
            (record.group === originalData?.group &&
              record.label === originalData?.label)
        );
      }
      actualValue = DirectScopeWidgetParser.getActualValue(
        record,
        subModelName,
        columnName,
        rmp,
      );
    } else {
      actualValue = DirectScopeWidgetParser.getActualValue(
        record,
        modelName,
        columnName,
        rmp,
      );
    }

    if (actualValue?.element) {
      element.innerHTML = actualValue.element.outerHTML;
    } else if (actualValue?.content) {
      element.innerText = actualValue.content;
    }
  }

  return node.documentElement.outerHTML;
}
