"use strict";

import * as UIUtils from "../ui_utils";
import TypeaheadObjectCache from "./cache/typeahead_object_cache";
import MemoryCache from "./cache/memory_cache";

export const PROJECT_TYPES = {
  DRUG_SUBSTANCE: "Drug Substance",
  DRUG_PRODUCT: "Drug Product",
  RAW_MATERIAL: "Raw Material",
  DP_AND_DS: "Drug Product and Drug Substance"
};

/**
 * The functions in this file are helper methods for working with projects.
 */

export function getProjectFromCache(projectIdParam) {
  const memoryCache = MemoryCache.getNamedInstance("project_helper");
  const cacheKey = "project_" + projectIdParam;
  let project = memoryCache.get(cacheKey);
  if (!project) {
    let projectId = UIUtils.parseInt(projectIdParam);
    let typeaheadOptions = new TypeaheadObjectCache("Project").getOptionsFromCacheIncludingArchived();
    project = typeaheadOptions.find(option => option.id === projectId);
    if (project) {
      memoryCache.set(cacheKey, project);
    }
  }

  return project;
}
