import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isSmartContentChanged, createSmartContentHash} from "../../documentEditor/utils";
import React, {useEffect, useState} from "react";
import * as UIUtils from "../../ui_utils";
import EditorParser from "../../documentEditor/utils/parser/parser";

type SmartContent = Record<string, Record<string, any> | Array<any>>;

type DocumentContentChangesNotificationProps = {
  documentRecord: {
    id: number;
    ProjectId: number;
    ProcessId: number;
    isQbdSrcDoc: boolean;
    currentState: string;
    documentContent: { content: string };
  };
  fileData: { documentContentVersionId: number; smartContentHash: string };
  currentSmartContent: SmartContent;
};

/**
 * The notification component indicates whether the underlying data of smart content
 * has changed after the PDF was generated
 * @param props {DocumentContentChangesNotificationProps}
 */
export default function DocumentContentChangesNotification(
  props: DocumentContentChangesNotificationProps,
) {
  const {documentRecord, fileData, currentSmartContent} = props;
  const [isContentChanged, setIsContentChanged] = useState(false);
  const {currentState, documentContent} = documentRecord;

  useEffect(() => {
    if (
      documentContent &&
      currentSmartContent &&
      fileData &&
      fileData.documentContentVersionId
    ) {
      const fields = EditorParser.getFields(documentContent.content, {
        ProjectId: documentRecord.ProjectId,
        ProcessId: documentRecord.ProcessId,
        DocumentId: documentRecord.id,
      });
      const smartContentHash = createSmartContentHash(documentContent.content, fields, currentSmartContent);
      setIsContentChanged(
        isSmartContentChanged(
          fileData.smartContentHash,
          smartContentHash,
        ),
      );
    }
  }, [currentSmartContent, documentContent, fileData]);

  if (currentState === UIUtils.VERSION_STATES.APPROVED || !isContentChanged) {
    return null;
  }

  return (
    <div className="d-flex flex-row col-sm-12 alert alert-info">
      <div className="mr-1">
        <FontAwesomeIcon
          className="info-icon-blue"
          icon={faExclamationCircle}
        />{" "}
      </div>
      <p id="popup-info-message">
        The data connected to this document has been updated since this PDF was
        generated.
      </p>
    </div>
  );
}
