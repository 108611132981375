"use strict";

import * as UIUtils from "../../../../ui_utils";
import React from "react";
import { FIELD_NAMES } from "../constants/constants";
import moment from "moment/moment";
import { INVALID_DATE } from "../../../../reports/data_transform/canned_reports/training/constants/constants";

/**
 * This class fetches data from tables.
 */
export class DocumentBulkAddTableFetcher {

  set rowIndex(rowIndex) {
    this._rowIndex = rowIndex;
  }

  getValue(fieldName) {
    return $(`#${fieldName}_${this._rowIndex}`).val() || "";
  }

  getText(fieldName) {
    return $(`#${fieldName}_${this._rowIndex}`).text();
  }

  get effectiveDate() {
    let effectiveDate = this.getValue(FIELD_NAMES.EFFECTIVE_DATE);
    effectiveDate = effectiveDate ? moment(effectiveDate).format(UIUtils.DATE_FORMAT_FOR_STORAGE) : "";
    return effectiveDate === INVALID_DATE ? "" : effectiveDate;
  }
}
