"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import BaseEditor from "./base_editor";
import TrialBar from "../widgets/bars/trial_bar";
import CookiesBar from "../widgets/bars/cookies_bar";
import CompanyHeader from "../widgets/headers/company_header";
import Heading from "../widgets/headers/heading";
import ErrorBar from "../widgets/bars/error_bar";
import EditablesPageTitleBar from "../widgets/pageTitleBar/editables_page_title_bar";
import { can, generateTooltip } from "../utils/ui_permissions";
import TermsPopup from "../terms/terms_popup";
import CommonSecurity from "../../server/common/generic/common_security";
import { DeveloperTools } from "../widgets/developer/developer_tools";

/**
 * This is the base class that every editable that wants to be displayed as a full page should extend from.
 */
export default class BaseEditorPage extends BaseEditor {
  constructor(props, baseTypeName, capitalizedBaseTypeName, displayName) {
    super(props, baseTypeName, capitalizedBaseTypeName, displayName);

    this.setStateSafely({
      breadcrumb: {
        current: "",
        parent: "",
        parentLink: "",
      },
    });
  }

  handleTermsPopupApproveButtonClick() {
    if (this.termsPopup) {
      $(this.termsPopup).modal("hide");
    }
  }

  renderPageTitleBar() {
    return <EditablesPageTitleBar projectId={this.getProjectId()}
                                  projectName={this.getProjectName()}
                                  isDemoProject={this.isDemoProject()}
    />;
  }

  createEditButton() {
    return (<div className="btn-group save-cancel-top">
      <button id="editButton" onClick={this.handleEdit}
              className={"btn btn-lg btn-primary"}
              title={generateTooltip(CommonSecurity.Actions.EDIT, CommonSecurity.Types.USER, this.state)}
              disabled={!can(CommonSecurity.Actions.EDIT, CommonSecurity.Types.USER, this.state)}
      >
        Edit
      </button>
    </div>);
  }

  render() {
    return (
      <div id="bodyDiv">
        <TrialBar />
        {this.renderCompanyHeader()}
        <form data-toggle="validator"
              id="baseEditorForm"
              role="form"
              onSubmit={UIUtils.ignoreHandler}
        >
          <div className={this.isView() ? "editor-view" : this.isEdit() ? "editor-edit" : "editor-add"}>
            {this.renderPageTitleBar()}
            <div className="container">
              <ErrorBar />
              {this.props.showAlert /* Set this to include an alert at the top of the page. */}
              <div className="row">
                <div className="col-8">
                  {this.renderTypeHeader()}
                </div>
                <div className="col-4">
                  {this.isView() ? (
                    this.createEditButton()
                  ) : this.createSaveCancelButtons(true)}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {this.renderAttributes()}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  {!this.isView() ? this.createSaveCancelButtons(false) : ""}
                </div>
              </div>
            </div>
          </div>
        </form>
        <CookiesBar />
        <TermsPopup modalRef={termsPopup => this.termsPopup = termsPopup}
                    onApproveButtonCLick={this.handleTermsPopupApproveButtonClick}
        />
        <DeveloperTools />
      </div>
    );
  }

  renderTypeHeader() {
    return <Heading level={1}>
      {this.displayName}
    </Heading>;
  }

  renderCompanyHeader() {
    return <CompanyHeader breadcrumb={this.state.breadcrumb} />;
  }
}
