var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/**
 * @hidden
 */
export var RANGE_ACTION;
(function (RANGE_ACTION) {
    RANGE_ACTION["start"] = "start";
    RANGE_ACTION["end"] = "end";
    RANGE_ACTION["min"] = "min";
    RANGE_ACTION["max"] = "max";
    RANGE_ACTION["increase"] = "increase";
    RANGE_ACTION["decrease"] = "decrease";
})(RANGE_ACTION || (RANGE_ACTION = {}));
/**
 * @hidden
 */
export var rangeReducer = function (state, action) {
    var newState;
    var newValue;
    switch (action.type) {
        case RANGE_ACTION.start:
            if (action.payload === undefined) {
                return state;
            }
            newState = {
                start: action.payload > state.end ? state.end : Math.max(action.payload, action.min),
                end: state.end
            };
            break;
        case RANGE_ACTION.end:
            if (action.payload === undefined) {
                return state;
            }
            newState = {
                start: state.start,
                end: action.payload < state.start ? state.start : Math.min(action.payload, action.max)
            };
            break;
        case RANGE_ACTION.min:
            if (action.key === 'start') {
                newState = __assign(__assign({}, state), { start: action.min });
            }
            else {
                newState = __assign(__assign({}, state), { end: Math.max(action.min, state.start) });
            }
            break;
        case RANGE_ACTION.max:
            if (action.key === 'start') {
                newState = __assign(__assign({}, state), { start: Math.min(action.max, state.end) });
            }
            else {
                newState = __assign(__assign({}, state), { end: action.max });
            }
            break;
        case RANGE_ACTION.increase:
            if (action.step === undefined || action.key === undefined) {
                return state;
            }
            newValue = state[action.key] + action.step;
            if (action.key === 'start') {
                if (newValue < action.min) {
                    newState = __assign(__assign({}, state), { start: action.min });
                }
                else {
                    newState = __assign(__assign({}, state), { start: Math.min(newValue, state.end) });
                }
            }
            else {
                if (newValue > action.max) {
                    newState = __assign(__assign({}, state), { end: action.max });
                }
                else {
                    newState = __assign(__assign({}, state), { end: Math.max(newValue, state.start) });
                }
            }
            break;
        case RANGE_ACTION.decrease:
            if (action.step === undefined || action.key === undefined) {
                return state;
            }
            newValue = state[action.key] - action.step;
            if (action.key === 'start') {
                if (newValue < action.min) {
                    newState = __assign(__assign({}, state), { start: action.min });
                }
                else {
                    newState = __assign(__assign({}, state), { start: Math.min(newValue, state.end) });
                }
            }
            else {
                if (newValue > action.max) {
                    newState = __assign(__assign({}, state), { end: action.max });
                }
                else {
                    newState = __assign(__assign({}, state), { end: Math.max(newValue, state.start) });
                }
            }
            break;
        default:
            newState = state;
            break;
    }
    return newState;
};
