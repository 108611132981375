@import "../../../../css/_brand_colors.scss";

.boolean-cell-container {
  display: inline-flex;
  max-width: 100%;
  align-items: center;
  justify-items: center;
  gap: 8px;

  :global(span) {
    color: $brand-black;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

