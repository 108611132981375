"use strict";

import * as UIUtils from "../../ui_utils";
import React, {forwardRef, useRef, useState} from "react";
import {
  createHTMLForDocsLinksDiff,
  createHTMLForWholeTextDiff,
} from "../../helpers/diff_helper";
import LabelTooltip from "../../widgets/tooltips/label_tooltip";
import * as icon from "../../images/icons/document";
import TableOptionsButton from "../../widgets/tables/table_options_button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faUpload,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import DocumentSaveBeforeLeavingPopup from "../../documents/components/document_save_before_leaving_popup";

/**
 This is a widget for document attachment attribute, It's used for showing in the view screen.
 this.props.docAttachmentControlName must be identified with the button name to be able to select right state.
 */
type ActionButton = {
  action: {
    title: string;
    icon: IconDefinition;
  };
  title: string;
  id: string;
  // eslint-disable-next-line no-unused-vars
  onClick: (event: MouseEvent) => void;
};

type Props = {
  name: string;
  className?: string;
  instance?: any;
  docAttachmentControlName?: string;
  onDownload?: Function;
  onUpload?: React.MouseEventHandler;
  onFileRemove?: Function;
  isLoading?: boolean;
  isView: boolean;
  navLink?: string;
  openOnSamePage?: boolean;
  docDateKey?: string;
  isQbDSrcDoc?: boolean;
  isDataModified?: boolean;
  olderVersion: any;
  isDiffingVersions: boolean;
  actionButtonOptions?: Array<ActionButton>;
};

const DocumentAttachmentViewAttribute = forwardRef(
  (props: Props, refIgnored) => {
    const {
      className,
      name,
      navLink,
      isView,
      openOnSamePage,
      docDateKey,
      isQbDSrcDoc,
      instance,
      isDataModified,
      olderVersion,
      isDiffingVersions,
      actionButtonOptions,
    } = props;
    const [showConfirmation, setShowConfirmation] = useState(false);
    let confirmationRef = useRef();

    function getDocObject(getOlderValue: boolean = false) {
      let docInfo = getOlderValue ? olderVersion : props.instance;

      let currentValue = null;
      if (docInfo) {
        currentValue = docInfo[props.docAttachmentControlName];
      }

      if (currentValue && typeof currentValue === "string") {
        currentValue = JSON.parse(currentValue);
      }

      if (Array.isArray(currentValue)) {
        currentValue = currentValue?.[0];
      }

      return currentValue;
    }

    function getDocAttribute(
      attributeName: string,
      getOlderValue = false
    ): string {
      let docObject = getDocObject(getOlderValue);
      return docObject ? docObject[attributeName] : "";
    }

    function getDocDateAttribute(
      attributeName: string,
      getOlderValue = false
    ): string {
      let someDate = getDocAttribute(attributeName, getOlderValue);
      return someDate ? UIUtils.getDateForDisplayToUser(someDate) : "";
    }

    function hasFile(): boolean {
      return !!getDocAttribute("fileName");
    }

    function getClassForLoading(): string {
      return UIUtils.getClassForLoading(props.isLoading);
    }

    function getActionIcon(hasFile: boolean): React.ReactElement {
      return hasFile ? (
        <TableOptionsButton
          id={`${props.name}OptionsButton`}
          iconOrientation="horizontal"
          options={
            actionButtonOptions
              ? actionButtonOptions
              : [
                {
                  action: {
                    title: "Replace document",
                    icon: faUpload,
                  },
                  title: "Replace title",
                  id: `${props.name}ReplaceButton`,
                  onClick: props.onUpload,
                },
                {
                  action: {
                    title: "Remove document",
                    icon: faTrash,
                  },
                  title: "Remove title",
                  id: `${props.name}RemoveButton`,
                  onClick: props.onFileRemove,
                },
              ]
          }
        />
      ) : (
        <button
          className={`btn btn-secondary table-options-button`}
          style={{color: "#1f46a1"}}
          type="button"
          id={`${props.name}UploadButton`}
          onClick={props.onUpload}
        >
          <FontAwesomeIcon icon={faUpload} />
        </button>
      );
    }

    function renderLabel(): React.ReactElement {
      const {isQbDSrcDoc, isView} = props;
      return (
        <div>
          <LabelTooltip
            id={props.name + "Label"}
            text={isQbDSrcDoc ? "Source Document" : "Final Document"}
            className={
              "col-form-label base-attribute" +
              (isView ? "" : " col-form-label-add-or-edit")
            }
          />
        </div>
      );
    }

    const onFileClickHandler = (e) => {
      // If instance does not have id, it means the document record hasn't created yet.
      // We need to save it before opening Doc Builder
      if (!isView && isQbDSrcDoc && (!instance.id || isDataModified)) {
        e.preventDefault();
        setConfirmationPopupOpened(true);
      } else {
        onDownloadHandler(e);
      }
    };

    const setConfirmationPopupOpened = (showDialog: boolean) => {
      setShowConfirmation(showDialog);
      if (!showDialog && confirmationRef && confirmationRef.current) {
        // @ts-ignore
        $(confirmationRef).modal("hide");
      }
    };

    const onDownloadHandler = (e) => {
      if (props.onDownload) {
        e.preventDefault();
        props.onDownload(getDocObject());
      } else {
        return false;
      }
    };

    let lastModified = getDocDateAttribute(docDateKey || "lastModified");

    return (
      <>
        {isView && renderLabel()}
        <div
          className={
            "document-info-widget col-sm-12 " +
            (hasFile() && "document-info-widget--has-doc ") +
            (className ?? "")
          }
        >
          <div className="document-info-widget__container">
            <div className="col-sm-1 document-info-widget__icon">
              <img
                src={
                  hasFile() ? (navLink ? icon.qbdDoc : icon.pdf) : icon.pdfGrey
                }
                alt={"Document Icon"}
              />
            </div>
            <div className="col-sm-10">
              <div
                id={name + "FilenameDiv"}
                className={
                  "document-info-widget__primary-text" + getClassForLoading()
                }
              >
                {isDiffingVersions ? (
                  createHTMLForDocsLinksDiff(
                    "",
                    getDocObject(true),
                    getDocObject(),
                    onDownloadHandler
                  )
                ) : hasFile() ? (
                  <a
                    id={`${name}Filename`}
                    href={navLink}
                    onClick={onFileClickHandler}
                    target={`${openOnSamePage ? "_self" : "_blank"}`}
                    rel="noreferrer"
                    className="links-table-link"
                    data-filename={getDocAttribute("fileName") ?? ""}
                  >
                    {getDocAttribute("fileName")}
                  </a>
                ) : (
                  <span id={`${name}Filename`}>No PDF uploaded yet</span>
                )}
              </div>
              <div>
                <div
                  id={name + "ModifiedDiv"}
                  className={
                    "document-info-widget__details" + getClassForLoading()
                  }
                >
                  {isDiffingVersions
                    ? createHTMLForWholeTextDiff(
                      getDocDateAttribute(docDateKey || "lastModified", true),
                      lastModified
                    )
                    : hasFile() && (
                    <span id={name + "Modified"}>
                          Last modified at {lastModified}
                        </span>
                  )}
                </div>
              </div>
            </div>
            <div className="document-info-view-action col-sm-1">
              {!isView && getActionIcon(hasFile())}
            </div>
          </div>
        </div>
        {showConfirmation && (
          <DocumentSaveBeforeLeavingPopup
            modalRef={(modalRef) => (confirmationRef = modalRef)}
            setPopupOpened={setConfirmationPopupOpened}
          />
        )}
      </>
    );
  }
);

export default DocumentAttachmentViewAttribute;
