"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import ReactDOMServer from "react-dom/server";
import { getURLByKey, getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { generateTooltip } from "../../utils/ui_permissions";
import CommonSecurity from "../../../server/common/generic/common_security";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { LibraryHelper } from "../../library/helpers/library_helper";
import BaseRelatedRecordsTableWithProcess from "./base_related_records_table_with_process";

/**
 * This is responsible for rendering the related records of an attribute in the view attribute page.
 */
export default class RelatedRecordsPanel extends BaseRelatedRecordsTableWithProcess {
  constructor(props) {
    super(props);
  }

  isLoading() {
    return this.props.isLoading;
  }

  getTableInitializationOptions() {
    return {
      dom: "<t>",
      data: this.prepareRecordsForDisplay(this.props.records),
      columns: this.getColumns(),
      bSort: false,
      scrollY: "250px",
      scrollCollapse: true,
      paging: false,
      stateSave: true,
    };
  }

  /**
   * Returns the full key of a record given the model name and the record id
   * @param record
   * @returns {string}
   */
  getRecordFullKey(record) {
    return `${UIUtils.getTypeCodeForModelName(this.props.modelName)}-${record.id}`;
  }

  /**
   * Creates a react element that can be used as a link on the UI and redirect the user to a particular record.
   * @param record The
   * @returns {*}
   */
  generateRecordLink(record) {
    let isLinkedToLibrary = false;
    let libraryDifferences = [];

    // this is used when it is called from quick panel
    const librarySpecifications = this.getParentRecord().fromLibrary?.specifications;
    if (librarySpecifications) {
      const recordLastVersion = record?.[record.modelName + "Versions"]?.find(version => version.id === record.LastVersionId);
      if (recordLastVersion) {
        recordLastVersion.measure = record.measure;
        isLinkedToLibrary = LibraryHelper.recordHasLibrarySpecificationAttached(recordLastVersion, librarySpecifications);
        libraryDifferences = LibraryHelper.getLibrarySpecificationDifferences(recordLastVersion, librarySpecifications);
      } else {
        isLinkedToLibrary = LibraryHelper.recordHasLibrarySpecificationAttached(record, librarySpecifications);
        libraryDifferences = LibraryHelper.getLibrarySpecificationDifferences(record, librarySpecifications);
      }
    }

    let recordKey = this.getRecordFullKey(record);
    return (<Fragment><a href={getURLByKey(recordKey, "View", false)}
                         target="_blank"
                         rel="noreferrer noopener"
    >
      {record.name}
      {record.isApproved ? (
        <sup className="results-table-approved-flag">approved</sup>
      ) : ""}
    </a> <span className="key">{recordKey}</span>
    <span className="icons" data-test={record.name}>
      {libraryDifferences.length > 0 ? (<FontAwesomeIcon title={"The acceptance criteria for this material attribute is not within the linked library specification values."} className="tab-nav-bar-warning-icon-yellow" icon={faTriangleExclamation} />) : ""}
      {isLinkedToLibrary ? (<FontAwesomeIcon className="link" title={"Linked to Library Specification"} icon={faLink} />) : ""}
    </span>
    </Fragment>);
  }

  getAddNewRecordURL() {
    const {modelName, parentId, parentModelName, projectId} = this.props;

    let typeCode = UIUtils.getTypeCodeForModelName(modelName);
    let parentTypeCode = parentModelName ? UIUtils.getTypeCodeForModelName(parentModelName) : "";
    let parentRecordId = parentId;

    let redirectionURL = UIUtils.getSecuredURL(getURLByTypeCodeAndId(typeCode, "Add", projectId));

    if (parentRecordId && parentTypeCode) {
      redirectionURL += "&parentId=" + parentRecordId;
      redirectionURL += "&parentTypeCode=" + parentTypeCode;
      redirectionURL += "&returnURL=" + encodeURIComponent(UIUtils.FRONT_END_URL + getURLByTypeCodeAndId(parentTypeCode, "View", parentRecordId));
    }

    return redirectionURL;
  }

  getColumns() {
    return [
      {
        className: "related-records-table-column",
        data: (item, type) => {
          if (type === "display") {
            return ReactDOMServer.renderToStaticMarkup(this.generateRecordLink(item));
          } else {
            return item.id;
          }
        }
      }
    ];
  }

  prepareRecordsForDisplay(records) {
    const parentState = this.props.parent.state.currentState;
    return records.filter(record => parentState?.startsWith(UIUtils.VERSION_STATES.ARCHIVED) || (record && !record.deletedAt))
      .map(record => {
        record.isApproved = record.LastApprovedVersionId === record.LastVersionId;
        return record;
      }).sort((a, b) => {
        return (a.isApproved ? -1 : 1) - (b.isApproved ? -1 : 1);
      });
  }

  handleAddButtonClick() {
    if (this.state.canAdd) {
      UIUtils.setLoadingDisabled(false);
      UIUtils.showLoadingImage();
      window.location = this.getAddNewRecordURL();
    }
  }

  getParentRecord() {
    let parent = this.props.parent;
    if (this.props.parent?.state?.id) {
      parent = this.props.parent?.state;
    }


    return parent;
  }

  render() {
    let thisProject = this.getProject();
    let thisProcess = this.getProcess();
    let parent = this.getParentRecord();

    let isLinkedToLibrary = false;
    let compareUrl = "";
    let hasChildrenLinkedToLibrary = false;
    if (parent) {
      isLinkedToLibrary = LibraryHelper.recordHasLibraryAttached(parent);
      hasChildrenLinkedToLibrary = this.props.records.filter(record => LibraryHelper.recordHasLibrarySpecificationAttached(record, parent.fromLibrary?.specifications)).length > 0;
    }

    if (parent && thisProcess && thisProject) {
      compareUrl = UIUtils.getSecuredURL(UIUtils.FRONT_END_URL + `/library/projectMaterialAttributes/compare.html?materialId=${parent.id}&projectId=${thisProject.id}&processId=${thisProcess.id}`);
    }

    return (
      <div className="related-records-container row-white">
        <div className="related-records-title">
          <div>
            {this.props.header}
          </div>
          <div>
            {!(thisProject && thisProject.deletedAt) && !(thisProcess && thisProcess.deletedAt) ? (
              <button id={`add${this.props.modelName}RelatedRecordButton`}
                      title={generateTooltip(CommonSecurity.Actions.ADD, this.modelFullName)}
                      className="btn btn-sm btn-secondary related-record-add-button"
                      onClick={this.handleAddButtonClick}
                      disabled={!this.state.canAdd}
              >
                Add
              </button>
            ) : ""}
          </div>
        </div>
        <div>
          <table ref={ref => this.tableRef = ref}
                 className={"table table-hover related-records-table" + this.getClassForLoading()}
                 id={`${this.props.id}Table`}
                 style={{width: "100%"}}
          />
        </div>
        { isLinkedToLibrary && hasChildrenLinkedToLibrary ? (<div>
          <a className="compareLink" href={compareUrl} rel="noreferrer" target="_blank">Compare with library material specifications</a>
        </div>) : "" }
      </div>
    );
  }
}
