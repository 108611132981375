var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { getHtml, setHtml, indentHtml, trimWhitespace } from '@progress/kendo-editor-common';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { messages } from './../messages';
import { editorPropsKey } from './../utils/props-key';
/**
 * @hidden
 */
var ViewHtmlDialog = /** @class */ (function (_super) {
    __extends(ViewHtmlDialog, _super);
    function ViewHtmlDialog() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.htmlArea = null;
        _this.onUpdate = function () {
            var _a = _this.props, view = _a.view, settings = _a.settings;
            var html = trimWhitespace(_this.htmlArea ? _this.htmlArea.value : '');
            var preserveWhitespace = editorPropsKey.getState(view.state).preserveWhitespace;
            setHtml(html, settings.commandName, { preserveWhitespace: preserveWhitespace })(view.state, view.dispatch);
            _this.onClose();
        };
        _this.onClose = function () {
            _this.props.view.focus();
            _this.props.onClose();
        };
        return _this;
    }
    /**
     * @hidden
     */
    ViewHtmlDialog.prototype.render = function () {
        var _this = this;
        var _a = this.props, view = _a.view, settings = _a.settings, dir = _a.dir, render = _a.render;
        var localization = provideLocalizationService(this);
        var _b = settings.messages, viewHtmlDialogTitle = _b.viewHtmlDialogTitle, viewHtmlCancel = _b.viewHtmlCancel, viewHtmlUpdate = _b.viewHtmlUpdate;
        var content = (React.createElement("textarea", { className: "k-textarea k-editor-textarea", style: {
                height: '280px',
                width: '490px'
            }, ref: function (e) { return _this.htmlArea = e; }, defaultValue: indentHtml(getHtml(view.state)), autoFocus: true }));
        var actionButtons = [(React.createElement(Button, { onClick: this.onClose, dir: dir, key: "cancel" }, localization.toLanguageString(viewHtmlCancel, messages[viewHtmlCancel]))), (React.createElement(Button, { onClick: this.onUpdate, themeColor: 'primary', dir: dir, key: "update" }, localization.toLanguageString(viewHtmlUpdate, messages[viewHtmlUpdate])))
        ];
        var dialog = (React.createElement(Dialog, { title: localization.toLanguageString(viewHtmlDialogTitle, messages[viewHtmlDialogTitle]), onClose: this.onClose, key: "dialog", closeIcon: true, dir: dir, style: dir === 'rtl' ? { textAlign: 'right' } : undefined, appendTo: document.body },
            content,
            React.createElement.apply(React, __spreadArray([DialogActionsBar, null], actionButtons, false))));
        return render ? render(dialog, { content: content, actionButtons: actionButtons }) : dialog;
    };
    return ViewHtmlDialog;
}(React.Component));
export { ViewHtmlDialog };
registerForLocalization(ViewHtmlDialog);
