"use strict";

import React from "react";
import BaseQuickEditableListTable from "../../base_quick_editable_list_table";
import { TABLE_COLUMN_INDEX } from "../../helpers/constants/constants";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { COLUMN_FORMATTERS } from "../../widgets/tables/column_formatters";

/* This shows the table of TPP Sections on the TPP Product tab. */

// i18next-extract-mark-ns-start tpp
class TPPList extends BaseQuickEditableListTable {
  constructor(props) {
    super(props, "TPPSection", true, false);
  }

  generateColumns(props) {
    const {t} = props;

    return [
      this.generateIDColumn(),
      this.generateNameColumn(),
      {
        ...COLUMN_FORMATTERS.CURRENT_STATE,
        data: this.createMappedCell(result => this.getCurrentState(result)),
        title: t("Status"),
      },
      this.generateManageColumn()
    ];
  }

  handleChangeShowRemoved(showRemoved) {
    this.getDataFromServer(undefined, showRemoved);
  }

  collapseAllSections() {
    return false;
  }

  getOrderByColumnIndex() {
    return TABLE_COLUMN_INDEX.ID;
  }

  generateColumnDefsArray() {
    return [
      {className: "results-table-column-id", targets: [0]},
      this.generateManageColumnDef(3)
    ];
  }
}

export default I18NWrapper.wrap(TPPList, ["tpp", "base_page"]);
// i18next-extract-mark-ns-stop tpp
