"use strict";

/**
 * @typedef IWebSocketEvents {Object}
 * @property {Function} [onOpen]
 * @property {Function} [onMessage]
 * @property {Function} [onReconnect]
 * @property {Function} [onMaximum]
 * @property {Function} [onClose]
 * @property {Function} [onError]
 */

import * as UIUtils from "../../ui_utils";
import { WebSocketsLibraryWrapper } from "./websockets_library_wrapper";

module.exports.getAPIURL = function() {
  return UIUtils.WS_API_URL + `?authToken=${UIUtils.getAccessToken()}`;
};

/**
 * Gets a wrapped instance of the websockets library
 * @param {IWebSocketEvents} events
 * @returns {WebSocketsLibraryWrapper}
 */
module.exports.getClient = function(events) {
  //@formatter:off
  let {onOpen, onMessage, onReconnect, onMaximum, onClose, onError} = events;
  onOpen = onOpen ? onOpen : () => {};
  onMessage = onMessage ? onMessage : () => {};
  onReconnect = onReconnect ? onReconnect : () => {};
  onMaximum = onMaximum ? onMaximum : () => {};
  onClose = onClose ? onClose : () => {};
  onError = onError ? onError : () => {};
  //@formatter:on

  console.log("Opening Websockets Request...");
  return new WebSocketsLibraryWrapper(exports.getAPIURL(), {
    timeout: 1000,
    onopen: e => onOpen(e),
    onmessage: e => onMessage(e),
    onreconnect: e => onReconnect(e),
    onmaximum: e => onMaximum(e),
    onclose: e => onClose(e),
    onerror: e => onError(e),
  });
};
