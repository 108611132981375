import { BaseParser } from "../base_parser";
import * as CommonEditablesFormatter from "../../../../../server/common/editables/common_editables_formatter";

export class AcceptanceCriteriaRangeParser extends BaseParser {
  parse() {
    const {parentModelName, modelName, columnName} = this.getNodeInfo();
    const parentWidgetNode = this.findParentWidgetNode(this.node);
    // This is the special case for putting the AcceptanceCriteria inside a widget
    // with a model that is not the AcceptanceCriteria. For example, we put the
    // ProcessParameter.AcceptanceCriteria.measure inside the widget ProcessParameter
    if (
      parentModelName &&
      parentWidgetNode &&
      parentWidgetNode.getAttribute("model") === parentModelName
    ) {
      const parentModelField = this.addModelAndColumnToField(
        this.fields,
        parentModelName,
      );
      this.addModelAndColumnToField(parentModelField, modelName);
    } else {
      this.addModelAndColumnToField(this.fields, modelName);
    }
    const newElement = document.createElement(this.node.tagName);
    if (this.data && parentModelName && columnName) {
      let currentAC = this.data;
      // If the data doesn't have group, it means that it is not the AcceptanceCriteria
      // record. We need to find the default one to get the value from it
      if (
        !this.data["group"] &&
        this.data.Requirement &&
        this.data.Requirement.AcceptanceCriteriaRanges
      ) {
        currentAC = this.data.Requirement.AcceptanceCriteriaRanges.find(
          (AC) => {
            return AC.isDefault;
          },
        );

        // The acceptance criteria doesn't have the measure field. We need to get
        // it from the record itself
        currentAC.measure = this.data.measure;
      }
      newElement.innerText = currentAC[columnName];
    } else {
      newElement.innerText =
        CommonEditablesFormatter.getAttributeMeasurementInfoTechTransferFormat(
          this.data,
        );
    }
    return [newElement];
  }
}

export function getValues(data) {
  return (data?.Requirement?.AcceptanceCriteriaRanges || []).map(ac => {
    return {...ac, measure: data.measure};
  });
}
