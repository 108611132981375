/**
 * @hidden
 */
var POPUP_ALIGN = {
    vertical: 'top',
    horizontal: 'left'
};
/**
 * @hidden
 */
var POPUP_ALIGN_RTL = {
    vertical: 'top',
    horizontal: 'right'
};
/**
 * @hidden
 */
var VERTICAL_COLLISION = {
    vertical: 'flip',
    horizontal: 'fit'
};
/**
 * @hidden
 */
var HORIZONTAL_COLLISION = {
    vertical: 'fit',
    horizontal: 'flip'
};
/**
 * @hidden
 */
export var POPUP_SETTINGS_RTL = {
    downward: {
        anchorAlign: {
            vertical: 'bottom',
            horizontal: 'right'
        },
        popupAlign: POPUP_ALIGN_RTL,
        collision: VERTICAL_COLLISION
    },
    leftward: {
        anchorAlign: {
            vertical: 'top',
            horizontal: 'left'
        },
        popupAlign: POPUP_ALIGN_RTL,
        collision: HORIZONTAL_COLLISION
    }
};
/**
 * @hidden
 */
export var POPUP_SETTINGS = {
    downward: {
        anchorAlign: {
            vertical: 'bottom',
            horizontal: 'left'
        },
        popupAlign: POPUP_ALIGN,
        collision: VERTICAL_COLLISION
    },
    rightward: {
        anchorAlign: {
            vertical: 'top',
            horizontal: 'right'
        },
        popupAlign: POPUP_ALIGN,
        collision: HORIZONTAL_COLLISION
    }
};
