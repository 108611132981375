@import "../../../../css/_brand_colors.scss";

.cell-container {
  display: flex;
  align-items: center;
}

.dataItem-id {
  color: $brand-dark-grey;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.002px;
  text-transform: uppercase;
  margin-left: 3px;
}

.content-zone {
  display: flex;
  align-items: baseline;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.actions-zone {
  display: inline-flex;
  gap: 8px;
  margin-left: auto;
  align-items: center;
  justify-content: center;
  color: $brand-medium-grey;
  background-color: $brand-white;

  :global(.table-options-button) {
    height: 25px;
    padding: 0 5px;
    margin-right: 6px;

    &:hover {
      border: 1px solid #859099;
      background-color: #F0F3F5;
      box-shadow: none;
    }

    &:active:focus {
      border: 1px solid #859099;
      background-color: #F0F3F5 !important;
      box-shadow: 0 0 0 0.1rem rgba(130, 138, 145, 0.5) !important;
    }

    &:global(.btn-secondary:focus),
    &:global(.btn-secondary:hover) {
      background-color: unset !important;
    }
  }

  :global(.dropdown.show) {

    :global(.table-options-button) {
      box-shadow: none;
      border: 1px solid #859099;
      background-color: #F0F3F5;

      &:focus {
        background-color: #F0F3F5 !important;
      }
    }
  }

  .chevron-link {
    cursor: pointer;
    text-decoration: none;
    padding: 1.1px 7.5px;

    &:hover {
      border: 1px solid #859099;
      background-color: #F0F3F5;
      box-shadow: none;
      border-radius: 5px
    }

    &:active:focus {
      background-color: #F0F3F5;
      box-shadow: 0 0 0 0.1rem rgba(130, 138, 145, 0.5) !important;
    }

    :global(.svg-inline--fa) {
      margin-left: unset;
    }

    .link-icon {
      color: $brand-medium-grey;
    }
  }
}

:global(.k-grid a).name-link {
  color: $brand-primary-color !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 20px;
}

.name-tooltip > :global(.tooltip-inner) {
  padding: 8px 17px 8px 12px;
  background-color: #fff;
  color: $brand-black;
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px 0 rgba(0, 0, 0, 0.20);
  text-align: left;
}

.name-tooltip > :global(.arrow::before) {
  border-top-color: $brand-white;
  border-width: 7px;
}

:global(.k-master-row.k-selected) td .actions-zone-container {
  background-color: #E8F8FF !important;
}