import { BaseParser } from "./base_parser";
import { FileType } from "../../common/constants";

/**
 * Parser that parses all page breaks
 */
export class PageBreakParser extends BaseParser {
  get selector() {
    return ".page-break";
  }

  getElements() {
    return Array.from(this.node.querySelectorAll(this.selector));
  }

  parse() {
    const elements = this.getElements();
    if (this.fileType !== FileType.DOC) {
      return elements;
    }

    const newElements = [];
    for (const element of elements) {
      const newElement = this.createElementFromText(
        "<span><br clear=all style='mso-special-character:line-break; page-break-before: always'></span>"
      );
      element.after(newElement);
      element.remove();
      newElements.push(newElement);
    }

    return newElements;
  }
}
