var _a;
/**
 * @hidden
 */
export var labelsOptional = 'labels.optional';
/**
 * @hidden
 */
export var messages = (_a = {},
    _a[labelsOptional] = '(Optional)',
    _a);
