var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { validatePackage } from '@progress/kendo-licensing';
import { packageMetadata } from '../package-metadata';
import { classNames, guid } from '@progress/kendo-react-common';
/**
 * Represents the [KendoReact StackLayout component]({% slug overview_stacklayout %}).
 * Arranges its inner elements horizontally, or vertically in a stack. Nesting stack layouts is supported to build more complex layouts.
 *
 * @example
 * ```jsx
 *
 * const App = () => {
 *   return (
 *     <StackLayout
 *       orientation="vertical"
 *       align={{horizontal: 'stretch', vertical: 'stretch'}}
 *       gap={10}
 *     >
 *       <div>Box</div>
 *       <div>Box</div>
 *       <div>Box</div>
 *       <div>Box</div>
 *     </StackLayout>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var StackLayout = React.forwardRef(function (props, ref) {
    validatePackage(packageMetadata);
    var elementRef = React.useRef(null);
    var getImperativeHandle = React.useCallback(function () { return ({
        element: elementRef.current
    }); }, []);
    React.useImperativeHandle(ref, getImperativeHandle);
    var className = props.className, style = props.style, id = props.id, children = props.children;
    var layoutId = React.useMemo(function () { return guid(); }, []);
    var orientation = React.useMemo(function () { return props.orientation || defaultProps.orientation; }, [props.orientation]);
    var isHorizontal = orientation === 'horizontal';
    var hAlign = React.useMemo(function () { return props.align && props.align.horizontal ? props.align.horizontal : defaultProps.hAlign; }, [props.align]);
    var vAlign = React.useMemo(function () { return props.align && props.align.vertical ? props.align.vertical : defaultProps.vAlign; }, [props.align]);
    var stackLayoutClasses = React.useMemo(function () { return classNames('k-stack-layout', {
        'k-hstack': orientation === 'horizontal',
        'k-vstack': orientation === 'vertical',
        'k-justify-content-start': isHorizontal && hAlign === 'start' || !isHorizontal && vAlign === 'top',
        'k-justify-content-center': isHorizontal && hAlign === 'center' || !isHorizontal && vAlign === 'middle',
        'k-justify-content-end': isHorizontal && hAlign === 'end' || !isHorizontal && vAlign === 'bottom',
        'k-justify-content-stretch': isHorizontal && hAlign === 'stretch' || !isHorizontal && vAlign === 'stretch',
        'k-align-items-start': !isHorizontal && hAlign === 'start' || isHorizontal && vAlign === 'top',
        'k-align-items-center': !isHorizontal && hAlign === 'center' || isHorizontal && vAlign === 'middle',
        'k-align-items-end': !isHorizontal && hAlign === 'end' || isHorizontal && vAlign === 'bottom',
        'k-align-items-stretch': !isHorizontal && hAlign === 'stretch' || isHorizontal && vAlign === 'stretch'
    }, className); }, [orientation, isHorizontal, hAlign, vAlign, className]);
    var stackLayoutStyles = __assign({ gap: "".concat(typeof props.gap === 'number' ? props.gap + 'px' : props.gap) }, style);
    return (React.createElement("div", { ref: elementRef, className: stackLayoutClasses, style: stackLayoutStyles, id: id || layoutId }, children));
});
var defaultProps = {
    orientation: 'horizontal',
    hAlign: 'stretch',
    vAlign: 'stretch'
};
StackLayout.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any,
    id: PropTypes.string,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    align: PropTypes.shape({
        vertical: PropTypes.oneOf(['top', 'middle', 'bottom', 'stretch']),
        horizontal: PropTypes.oneOf(['start', 'center', 'end', 'stretch'])
    })
};
StackLayout.defaultProps = defaultProps;
StackLayout.displayName = 'KendoReactStackLayout';
