"use strict";

import ImplementationNeededError from "../../../../../utils/implementation_needed_error";

export const NONE = "None";
export const EMPTY_UNIT_OPERATION = {
  modelType: "UO",
  name: "No Unit Operation",
  PreviousUnitId: null,
  id: -1,
};


/**
 * This class acts like an interface to ensure all derived types implement its methods
 * @abstract
 */
export class SpecificationTransformStrategy {
  /**
   * Implements the logic to sort the results
   * @param records
   * @param options
   * @abstract
   */
  // eslint-disable-next-line no-unused-vars
  sortRecords(records, options) {
    throw new ImplementationNeededError("The method 'sortRecords' needs to be implemented on a derived class");
  }

  /**
   * Fills in information about the parent of this requirement
   * @param record
   * @param requirement
   * @param options
   * @abstract
   */
  // eslint-disable-next-line no-unused-vars
  fillParentInformation(record, requirement, options) {
    throw new ImplementationNeededError("The method 'fillParentInformation' needs to be implemented on a derived class");
  }

  /**
   * Fills in controlMethod info about this requirement
   * @param record
   * @param requirement
   * @param options
   * @abstract
   */
  // eslint-disable-next-line no-unused-vars
  fillGeneralInformation(record, requirement, options) {
    throw new ImplementationNeededError("The method 'fillGeneralInformation' needs to be implemented on a derived class");
  }

  /**
   * Fills in control methods info about this requirement
   * @param record
   * @param requirement
   * @param options
   * @abstract
   */
  // eslint-disable-next-line no-unused-vars
  fillControlInformation(record, requirement, options) {
    throw new ImplementationNeededError("The method 'fillControlInformation' needs to be implemented on a derived class");
  }

  /**
   * Fills in acceptance criteria info about this requirement
   * @param record
   * @param requirement
   * @param options
   * @abstract
   */
  // eslint-disable-next-line no-unused-vars
  fillAcceptanceCriteria(record, requirement, options) {
    throw new ImplementationNeededError("The method 'fillAcceptanceCriteria' needs to be implemented on a derived class");
  }

  /**
   * Performs a final pass to format the entire result if needed.
   * @param results
   * @param options
   * @abstract
   */
  // eslint-disable-next-line no-unused-vars
  wrapUp(results, options) {
    throw new ImplementationNeededError("The method 'wrapUp' needs to be implemented on a derived class");
  }

  /**
   * Retrieves the accepted record types for this report
   * @param options
   */
  getFilteredRecordTypes(options) {
    const {filterOptions, reportOptions} = options;
    return filterOptions ? filterOptions.type : reportOptions.defaultFilter.type;
  }
}

