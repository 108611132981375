"use strict";

import * as pillStyles from "../filter.module.scss";
import React from "react";

export const MenuItem = ({children}) => {
  return (
    <div className={pillStyles["menu-item"]}>
      {children}
    </div>
  );
};