import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from "prosemirror-view";
// https://discuss.prosemirror.net/t/passing-data-between-plugins/1843
export var textHighlightKey = new PluginKey('highlight');
export function textHighlight(key) {
    if (key === void 0) { key = textHighlightKey; }
    return new Plugin({
        key: key,
        state: {
            init: function () { return null; },
            apply: function (tr) { return tr.getMeta(this.spec.key); }
        },
        props: {
            decorations: function (state) {
                var decorations = (this.spec.key.getState(state) || [])
                    .map(function (d) { return Decoration.inline(d.from, d.to, d.attrs); });
                return DecorationSet.create(state.doc, decorations);
            }
        }
    });
}
