import * as React from "react";
import {Button} from "@progress/kendo-react-buttons";
import pageBreakIcon from "../../images/documentEditor/page-break-icon.svg";
import {getAttributes} from "../utils";
import {EditorUtils} from "@progress/kendo-react-editor";
import {ToolProps} from "../common/types";
import { isInWidget } from "../common/editorSchemas/widget_node";
import {NodeSpec} from "@progress/kendo-editor-common";

export const NON_EDITABLE_PAGE_BREAK: NodeSpec = {
  name: "nonEditablePageBreak",
  inline: false,
  group: "block",
  marks: "",
  attrs: {
    contenteditable: {default: false},
    class: {default: null},
    style: {default: null},
  },
  draggable: false,
  atom: true,
  parseDOM: [
    {
      tag: "div.page-break",
      getAttrs: (node) => node instanceof HTMLElement ? getAttributes(node) : {},
      priority: 51,
    },
  ],
  toDOM: (node) => {
    return ["div", {...node.attrs}];
  },
};

/**
 * The editor tool to add a page break
 */
export default function PageBreak(props: ToolProps) {
  const {view} = props;

  const handleButtonClick = () => {
    const {schema} = view.state;
    const nodeType = schema.nodes[NON_EDITABLE_PAGE_BREAK.name];
    const node = nodeType.createAndFill(
      {class: "page-break"},
    );
    EditorUtils.insertNode(view, node);
    view.focus();
  };

  const isInTable = () => {
    const state = view?.state;

    if (!state) {
      return false;
    }

    let $head = state.selection.$head;
    for (let d = $head.depth; d > 0; d--)
      if ($head.node(d).type.spec.tableRole === "row") {
        return true;
      }
    return false;
  };

  return (
    <Button
      id="pageBreakTool"
      onClick={handleButtonClick}
      disabled={
        isInTable() || isInWidget(view)
      }
      onMouseDown={(e) => e.preventDefault()}
      onPointerDown={(e) => e.preventDefault()}
      title="Page Break"
      imageUrl={pageBreakIcon}
    />
  );
}
