"use strict";

import * as UIUtils from "../../ui_utils";
import ReactDOMServer from "react-dom/server";
import moment from "moment";
import * as DocumentTransferHelper from "../../helpers/document_transfer_helper";
import { DATE_COLUMNS, TABLE_COLUMN_WIDTH, TEXTRACT_CONFIDENCE_LEVELS } from "../constants/import_constants";
import { prefillImportTemplateWithData } from "./import_template_prefiller";
import CommonUtils from "../../../server/common/generic/common_utils";
import { CommonSorting } from "../../../server/common/generic/common_sorting";
import XlsxPopulate from "xlsx-populate";

/**
 * The scrollbar width used in the software. This is important for calculating the available canvas size for rendering
 * the pdfs on the pdf viewer widget.
 * @type {number}
 */
export const SCROLL_BAR_WIDTH = 13;

/**
 * This function change all dates within a specific model to the format we use for the UI
 * @param modelName
 * @param objects
 * @return {*}
 */
export function fixDatesForDisplay(modelName, objects) {
  for (let object of objects) {
    if (modelName === "Supplier") {
      if (object.dateCompleted) {
        object.dateCompleted = fixDateForDisplay(object.dateCompleted);
      }
      if (object.nextAudit) {
        object.nextAudit = fixDateForDisplay(object.nextAudit);
      }
    } else {
      break;
    }
  }

  return objects;
}

function fixDateForDisplay(date) {
  return date ? moment(date).format(UIUtils.DATE_FORMAT_FOR_DISPLAY) : null;
}

export function getModelNameForDisplay(modelName) {
  const firstFewChars = modelName.substring(0, 3);
  return firstFewChars === firstFewChars.toUpperCase() ? modelName :
    UIUtils.convertCamelCaseToSpacedOutWords(modelName).toLowerCase();
}

export function getTemplateFileLocation(options) {
  return getFileLocation("Template", options);
}

export function getSampleFileLocation(options) {
  return getFileLocation("Sample", options);
}

export function downloadProcessCapabilityTemplate(importTemplateFileName, data, modelName, parentRecords, templateIsForContinuousData) {
  return new XlsxPopulate.Promise((resolve, reject) => {
    let req = new XMLHttpRequest();
    let url = `/import/templates/${importTemplateFileName}`;
    req.open("GET", url, true);
    req.responseType = "arraybuffer";
    req.onreadystatechange = () => {
      if (req.readyState === 4) {
        if (req.status === 200) {
          resolve(XlsxPopulate.fromDataAsync(req.response));
        } else {
          reject("Received a " + req.status + " HTTP code.");
        }
      }
    };
    req.send();
  }).then(workbook => {
    return prefillImportTemplateWithData(workbook, modelName, data, parentRecords, templateIsForContinuousData).then(workbook => {
      return workbook.outputAsync();
    });
  }).then(blob => {
    // MS Edge doesn't work with data URLs.  Also, Chrome keeps clamping down on their security.  Blobs are safer.
    DocumentTransferHelper.download(`QbDVision_${CommonUtils.stripAllWhitespaces(modelName)}_Import-${moment().format("YYYY-MM-DD")}.xlsx`, window.URL.createObjectURL(blob));
  }).catch(err => {
    console.log(err.message || err);
    throw err;
  });
}

function getFileLocation(fileType, options) {
  const {dependency} = options;
  let modelName = UIUtils.stripAllWhitespaces(options.modelName);

  let url = `/import/templates/${modelName}_${fileType}`;

  if (dependency) {
    url += `_${UIUtils.stripAllWhitespaces(dependency)}`;
  }
  url += `.xlsx`;
  return url;
}

/**
 * Downloads a file given an S3 file information
 * @param event can either be an event of a file selector or directly the file data
 * @param fileData S3 file information
 */
export function downloadFile(event, fileData) {
  if(event.preventDefault) {
    event.preventDefault();
  } else {
    fileData = event;
  }

  UIUtils.secureAjaxGET("s3/preSignedURL", {
    payload: JSON.stringify(fileData),
    operation: "get"
  }, false).done((url) => {
    DocumentTransferHelper.download(fileData.fileName, url.url);
  });
}

/**
 * This function combines all props from different objects with different props.
 * For objects that do have measurements like in process capability this function
 * will put them in their right location.
 * @param objects The objects to get props for
 */
export function generateProps(objects) {
  let aggregatedProps = ["defectivePercentage", "min", "max", "average", "sd"];
  let isProcessCapabilityImport = false;

  const objWithDefectivePercentage = objects.find(obj => obj.defectivePercentage);
  const objWithAverage = objects.find(obj => obj.average);

  if (objWithDefectivePercentage && objWithAverage) {
    isProcessCapabilityImport = true;
  } else if (objWithDefectivePercentage && !objWithAverage) {
    aggregatedProps = ["defectivePercentage"];
    isProcessCapabilityImport = true;
  } else if (!objWithDefectivePercentage && objWithAverage) {
    aggregatedProps = ["min", "max", "average", "sd"];
    isProcessCapabilityImport = true;
  }

  if (objects && objects.length > 0) {
    let props = [];
    let allMeasurementLabels = [];
    for (const object of objects) {
      const measurementLabels = object.measurements ? object.measurements.map(measurement => measurement.label) : [];
      allMeasurementLabels = allMeasurementLabels.concat(measurementLabels);
      for (const prop in object) {
        if (Object.prototype.hasOwnProperty.call(object, prop)
          && !props.includes(prop)
          && !measurementLabels.includes(prop)
          && !Array.isArray(object[prop])) {
          props.push(prop);
        }
      }
    }

    const measurementProps = Array.from(new Set(allMeasurementLabels)).sort(CommonSorting.stringAndNumberAscending(item => item));

    let nonMeasurementProps = props.filter(prop => !measurementProps.includes(prop));
    let finalProps = nonMeasurementProps;

    if (isProcessCapabilityImport) {
      finalProps = nonMeasurementProps.filter(prop => !aggregatedProps.includes(prop));
    }

    if (measurementProps && nonMeasurementProps
      && (
        (nonMeasurementProps.includes("attributeID") && nonMeasurementProps.includes("attributeName"))
        || nonMeasurementProps.includes("batchId")
      )) {

      const key = nonMeasurementProps.includes("acceptanceCriteria")
        ? "acceptanceCriteria"
        : nonMeasurementProps.includes("batchId") ? "batchId" : "attributeName";
      finalProps.splice(finalProps.indexOf(key) + 1, 0, ...measurementProps);

      if (isProcessCapabilityImport) {
        finalProps.splice(finalProps.indexOf(measurementProps[measurementProps.length - 1]) + 1, 0, ...aggregatedProps);
      }
    }

    return finalProps.map(prop => {
      return {
        name: prop,
        isMeasurementProp: measurementProps.includes(prop)
      };
    });
  }
}

function formatColumnValue(columnName, columnValue) {
  let result = columnValue;

  if (typeof columnValue === "undefined" || columnValue === null) {
    result = "";
  }

  if (typeof columnValue === "string" && columnValue.startsWith("[") && columnValue.endsWith("]")) {
    try {
      const resultArray = JSON.parse(columnValue);
      result = resultArray.join(",");
    }
    catch {
      result = columnValue;
    }
  } else if (typeof columnValue === "boolean") {
    result = columnValue ? "Yes" : "No";
  } else if (typeof columnValue === "function") {
    result = columnValue();
  } else if (columnValue && DATE_COLUMNS.includes(columnName)) {
    result = moment(columnValue, UIUtils.DATE_FORMAT_FOR_STORAGE).format(UIUtils.DATE_FORMAT_FOR_DISPLAY);
  }
  return result;
}

function adjustColumnWidth(columnName, column) {
  const upperCaseColumnName = columnName.toUpperCase();
  if (Object.keys(TABLE_COLUMN_WIDTH).includes(upperCaseColumnName)) {
    column.width = TABLE_COLUMN_WIDTH[upperCaseColumnName];
  }
}

/**
 * Creates a column used by datatables.
 * @param columnName The name of the column. From this, the header title is generated.
 * @param renamedColumns A map of columns that should be renamed. The map contains the name of the column expected to
 * be passed in this function and the one that it should be renamed to.
 * @param isMeasurementColumn A flag indicating if the columnName refers to a measurement column or not. If this refers
 * to a measurement column, then the column name passed in is not modified in any way but instead it is presented the
 * same way the user entered it.
 * @returns {{data: function(*): *, title: string}}
 */
export function createColumn(columnName, renamedColumns = new Map(), isMeasurementColumn) {
  let columnTitle = isMeasurementColumn ? columnName : UIUtils.convertCamelCaseToSpacedOutWords(columnName);
  let renamedColumnTitle = renamedColumns.get(columnTitle);
  columnTitle = renamedColumnTitle ? renamedColumnTitle : columnTitle;

  let column = {
    title: ReactDOMServer.renderToString(columnTitle),
    render: $.fn.dataTable.render.text(),
    data: (result) => {
      return formatColumnValue(columnName, result[columnName]);
    },
  };

  column.type = "string";

  adjustColumnWidth(columnName, column);
  return column;
}

export function getTextractConfidenceLevel(confidence) {
  return UIUtils.isNumber(confidence) ? (
    confidence >= TEXTRACT_CONFIDENCE_LEVELS.GREY.level ? TEXTRACT_CONFIDENCE_LEVELS.GREY :
      confidence >= TEXTRACT_CONFIDENCE_LEVELS.YELLOW.level ? TEXTRACT_CONFIDENCE_LEVELS.YELLOW :
        TEXTRACT_CONFIDENCE_LEVELS.ORANGE) : "";
}

/**
 * This calculates the percentage of zoom level applied to a document based on the original document dimensions
 * and the new ones after it has been stretched to fit the width of a new container.
 * @param zoomLevel The zoom level already applied by the user by using the zoom controls
 * @param paperWidth The new document width
 * @param paperHeight The new document height
 * @param originalDocumentWidth The original document width
 * @param originalDocumentHeight The original document height
 * @returns {number}
 */
export function calculateZoomPercentage(zoomLevel, paperWidth, paperHeight, originalDocumentWidth, originalDocumentHeight) {
  let xScalingFactor = 1;
  let hasScrollBar = false;

  let clientWidth = paperWidth || 0;
  if (originalDocumentWidth && clientWidth) {
    xScalingFactor = clientWidth / originalDocumentWidth;
    hasScrollBar = xScalingFactor * originalDocumentHeight > paperHeight;
    /* If a scrollbar is about to show up, then recalculate the actual client height and scaling factor by taking the
       scrollbar into account.
     */
    if (hasScrollBar) {
      clientWidth -= SCROLL_BAR_WIDTH;
      xScalingFactor = clientWidth / originalDocumentWidth;
    }
  }

  return zoomLevel * xScalingFactor / 100;
}
