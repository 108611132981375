"use strict";

import React, { Fragment } from "react";
import ReactDOM from "react-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import * as URLHelper from "../../helpers/url_helper";
import * as UIUtils from "../../ui_utils";
import { ModelFinder } from "../../../server/common/generic/common_model_finder";
import { Ensure } from "../../../server/common/generic/common_ensure";
import * as CommonTraining from "../../../server/common/editables/common_training";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseDiffableTable from "../../widgets/tables/base_diffable_table";
import { can } from "../../utils/ui_permissions";
import * as CommonSecurity from "../../../server/common/generic/common_security";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import { FormattedDate } from "../../widgets/generic/formatted_date";

const Logger = Log.group(LOG_GROUP.Training, "DocumentsInTrainingTable");

const RETRAIN_ICON = faRedo;

// i18next-extract-mark-ns-start training
/**
 * This is responsible for rendering a list of documents with their status,
 * with buttons to allow the documents to be retrained.
 */
export class DocumentsInTrainingTable extends BaseDiffableTable {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    Ensure.virtual("shouldComponentUpdate", {nextProps, nextState, nextContext});

    return (
      this.detectChangeInArray(this.props.records, nextProps.records)
      || this.detectChangeInArray(this.props.allDocuments, nextProps.allDocuments)
      || this.detectChangeInArray(this.props.assignedDocuments, nextProps.assignedDocuments)
      || this.detectChangeInArray(this.props.allTrainingRecords, nextProps.allTrainingRecords)
      || this.props.isLoading !== nextProps.isLoading
    );
  }

  /**
   * Detects whether there has been a change in an array of records.
   * @param first {IEntity[]} The first array.
   * @param second {IEntity[]} The second array.
   * @return {boolean} Returns true if they are different.
   */
  detectChangeInArray(first, second) {
    // Short circuiting logic will return when the least expensive possible comparison succeeds,
    // so we only create the big string if all others are the same
    return !!(
      (!first && second)
      || (second && !first)
      || (first && second && first.length !== second.length)
      || (
        (first || []).map(item => item.id).join("-") !== (second || []).map(item => item.id).join("-")
      )
    );
  }

  getTableInitializationOptions() {
    return {
      dom: "<t>",
      data: this.prepareRecordsForDisplay(this.props.records),
      columns: this.getColumns(),
      paging: false,
      ordering: false,
      stateSave: false,
      autoWidth: false,
    };
  }

  getColumns() {
    const {t, allowRetraining} = this.props;

    const columns = [
      this.generateAttributeColumn(),
      this.generateColumn(t("Version"), "documentVersion", "documentVersion"),
      this.generateDateColumn(t("Assigned Date"), "assignmentDate", "assignmentDate"),
      this.generateColumn(t("Status"), "status", "status", "", this.formatStatusLabel),
    ];

    if (allowRetraining) {
      columns.push(this.generateActionColumn());
    }
    return columns;
  }

  generateActionColumn() {
    const {t, type, onCheckPermissions} = this.props;
    return {
      width: 0,
      orderable: false,
      data: this.getDocumentVersionId,
      createdCell: (td, cellData, rowData, rowIndex) => {
        const userHasPermission = can(CommonSecurity.Actions.PROPOSE, "ITP", this.state, onCheckPermissions);
        const isAlreadyTrained = rowData.status === CommonTraining.TRAINING_STATUS.COMPLETED || rowData.status === CommonTraining.TRAINING_STATUS.TRAINED_OUTSIDE;

        const isLatestTraining = rowData.isLatestRetraining && rowData.isLatestVersion;
        const isAvailableForRetraining = isLatestTraining && isAlreadyTrained;

        const isEnabled = isAvailableForRetraining && userHasPermission;

        const state = isEnabled ? "enabled" : "disabled";

        let title;
        if (isEnabled) {
          title = t("Retrain");
        } else if (isAvailableForRetraining && !userHasPermission) {
          title = t("You do not have access to assign a document for retraining.");
        } else if (isLatestTraining && !isAlreadyTrained) {
          title = t("This document is currently assigned for training.");
        } else {
          title = t("This is an obsolete version and cannot be retrained.");
        }

        const showHackerErrorMessage = () => {
          if (!userHasPermission) {
            UIUtils.showError(title);
          }
        };

        ReactDOM.render(
          (
            rowData.isGroupRow ? "" : (
              <button
                id={`retrain${UIUtils.convertToId(type.typeName)}Item_Button_${rowIndex}`}
                onClick={isEnabled ? this.handleActionClick.bind(this, "Retrain", rowData, cellData) : showHackerErrorMessage}
                disabled={!isEnabled}
                className={`table-action-button retraining-icon ${state}`}
                title={title}
              >
                <FontAwesomeIcon icon={RETRAIN_ICON}/>
              </button>
            )
          ), td);
        $(td).addClass("table-action-cell");
      }
    };
  }

  generateAttributeColumn() {
    const {t, type} = this.props;
    const modelName = type.modelName;
    const modelDeclaration = ModelFinder.instance().findFromModelName(modelName);

    return {
      title: t(modelDeclaration.modelName),
      width: 300,
      containsHTML: true,
      data: this.getAttributeCellData,
      createdCell: this.createAttributeCell,
    };
  }

  getAttributeCellData(rowData) {
    return rowData && (
      rowData.label
      || (rowData.typeCode ? UIUtils.getRecordCustomLabelForDisplay(rowData) : rowData.name)
    );
  }

  createAttributeCell(td, cellData, rowData) {
    const typeCode = rowData.typeCode || "DOC";
    const fullName = this.getAttributeCellData(rowData);

    let url;
    if (rowData.linkToVersion) {
      const versionId = this.getDocumentVersionId(rowData);
      url = URLHelper.getURLByTypeCodeAndIdAndVersionId(typeCode, "View", rowData.id, true, versionId);
    } else {
      url = URLHelper.getURLByTypeCodeAndId(typeCode, "View", rowData.id, true);
    }

    ReactDOM.render(
      <div>
        <div>
          <a href={url}
             rel="noopener noreferrer"
             target="_blank"
          >
            {fullName}
          </a> {typeCode === "DOC" && rowData.retrainingCount > 0 ? (
          <span className="retrained">(retrained)</span>) : ""}
        </div>
      </div>, td);
  }

  handleActionClick(action, rowData, cellData) {
    if (this.props.onActionClick) {
      this.props.onActionClick(action, rowData, cellData);
    }
  }

  generateDateColumn(title, prop, propForSorting, className) {
    const formatRowFunc = rowData => {
      const rawDate = rowData[prop] || "";
      return this.formatDateForDisplay(rawDate);
    };

    return this.generateColumn(title, prop, propForSorting, className, formatRowFunc);
  }

  /**
   * Formats the specified date to be displayed in the page.
   * @param rawDate {Date|moment|string|number} A value representing the date to be displayed.
   * @return {JSX.Element|string}
   */
  formatDateForDisplay(rawDate) {
    return rawDate ? <FormattedDate value={rawDate}/> : "N/A";
  }

  formatVersion(record) {
    return record ? `${record.majorVersion}.${record.minorVersion}` : "";
  }

  /**
   * Prepares the training records for display.
   * @param records {ITrainingRecord[]} The training records related to this control.
   * @returns {*|*[]}
   */
  prepareRecordsForDisplay(records = []) {
    let context = this.createItemsToDisplayContext();
    for (let currentGroup of this.getGroupsOfItemsToDisplay(context)) {
      /*
       * Documents displayed in the list could comes from two sources:
       *
       * 1) They can be directly assigned to the ITP or Curriculum we are displaying
       *    - Among those, there are those that have already training records (it's approved)
       *    - But there may be also those that doesn't have training records yet (it's the draft being shown)
       *
       * 2) They can come from the Training Records:
       *    - Information about older versions of documents.
       *    - The information about the training for a document that is assigned directly
       *    - Let's say you have the same document in a curriculum assigned from a department
       *      and in another curriculum that is directly assigned to you, we should show the
       *      training status for the document version regardless of where it's coming from.
       * Once those items are processed, they are merged, so they appear as a single, consolidated
       * view with the items assigned and their status.
       */
      this.addAssignedDocumentsToItemsToDisplay(currentGroup);
      this.addTrainingRecordDocumentsToItemsToDisplay(records, currentGroup);
    }

    let items = this.applyRecordFilter([...context.itemsToDisplay.values()]);
    return this.sortItemsToDisplay(items);
  }

  createItemsToDisplayContext() {
    let {allDocuments, allTrainingRecords} = this.props;
    // ensures all objects contain at least an empty array.
    const documentMap = this.getEntriesMappedByField(allDocuments);
    const trainingRecordByDocumentVersionMap = this.getTrainingRecordsGroupedByDocumentVersion(allTrainingRecords);
    const trainingRecordByDocumentMap = this.getEntriesMappedByField(allTrainingRecords, "trainingDocumentId");

    let itemsToDisplay = new Map();

    return {
      documentMap,
      trainingRecordByDocumentVersionMap,
      trainingRecordByDocumentMap,
      allTrainingRecords,
      itemsToDisplay
    };
  }

  getGroupsOfItemsToDisplay(context) {
    let {assignedDocuments} = this.props;
    assignedDocuments = assignedDocuments || [];
    const assignedDocumentsMap = this.getEntriesMappedByField(assignedDocuments);

    return [{context, assignedDocuments, assignedDocumentsMap}];
  }

  sortItemsToDisplay(items) {
    return [...items.sort(UIUtils.sortBy("sortExpressionForView"))];
  }

  getTrainingRecordsToProcess(records, currentGroup) {
    Ensure.virtual("getTrainingRecordsToProcess", {records, currentGroup});
    return [...records].sort(UIUtils.sortBy("id"));
  }

  addAssignedDocumentsToItemsToDisplay(currentGroup) {
    const {showDraftAssignments} = this.props;
    const {
      context,
      assignedDocuments,
    } = currentGroup;

    const {
      documentMap,
      trainingRecordByDocumentVersionMap,
      trainingRecordByDocumentMap,
      allTrainingRecords,
      itemsToDisplay,
    } = context;


    for (let documentRef of assignedDocuments) {
      // If not found, it has not been loaded yet and this will run again.
      const document = documentMap.get(documentRef.id);
      if (document) {
        const versionId = this.getDocumentVersionId(document);
        let trainingRecords = trainingRecordByDocumentVersionMap.get(versionId);

        if (!trainingRecords) {
          const documentRecord = trainingRecordByDocumentMap.get(document.id);
          if (documentRecord) {
            trainingRecords = [documentRecord];
          } else if (showDraftAssignments) {
            trainingRecords = [null];
          } else {
            trainingRecords = [];
          }
        }

        for (let trainingRecord of trainingRecords) {
          const key = this.getTrainingRecordKey(trainingRecord, document);
          const isLatestVersion = versionId === document.LastApprovedVersionId;
          const isLatestRetraining = this.isLatestRetraining(trainingRecords, trainingRecord);

          let documentToAdd = {
            ...document,
            typeCode: "DOC",
            DocumentId: documentRef.id,
            versionId,
            customID: document.customID,
            status: trainingRecord ? CommonTraining.getCompletionStatus(trainingRecord, allTrainingRecords, {completedAsDate: false}) : "Draft",
            documentVersion: trainingRecord ? this.formatVersion(trainingRecord) : this.formatVersion(document),
            assignmentDate: trainingRecord ? trainingRecord.assignmentDate : null,
            completionDate: trainingRecord ? trainingRecord.completionDate : null,
            linkToVersion: !isLatestVersion,
            isLatestVersion,
            isLatestRetraining,
            retrainingCount: trainingRecord ? trainingRecord.retrainingCount : 0,
          };
          this.addExtraInformationToItemToDisplay(documentToAdd, currentGroup);

          if (isLatestVersion) {
            itemsToDisplay.set(key, documentToAdd);
          }
        }
      }
    }
  }

  isLatestRetraining(trainingRecords, trainingRecord) {
    return CommonTraining.isLatestRetraining(trainingRecords, trainingRecord);
  }

  addTrainingRecordDocumentsToItemsToDisplay(records, currentGroup) {
    const {showUnassignedCompletedTraining} = this.props;

    const {
      context,
      assignedDocumentsMap,
    } = currentGroup;

    const {
      itemsToDisplay,
      allTrainingRecords,
      documentMap,
    } = context;

    const trainingRecords = this.getTrainingRecordsToProcess(records, currentGroup);

    for (let trainingRecord of trainingRecords) {
      const versionId = this.getDocumentVersionId(trainingRecord);
      const key = this.getTrainingRecordKey(trainingRecord);

      let existingEntry = itemsToDisplay.get(key) || {};

      const documentId = trainingRecord.trainingDocumentId;
      /**
       * Ensures documents that have been already trained but that are no longer assigned
       * will not show up in the listing.
       */
      const assignedDocument = assignedDocumentsMap.get(documentId);

      if (assignedDocument || showUnassignedCompletedTraining) {
        const document = documentMap.get(documentId);
        if (!document) {
          Logger.warn("Document not found with id: ", Log.symbol(documentId), Log.object(documentMap));
          continue;
        }
        const lastApprovedDocumentVersionId = document.LastApprovedVersionId;

        const isLatestVersion = versionId === lastApprovedDocumentVersionId;
        const isLatestRetraining = this.isLatestRetraining(trainingRecords, trainingRecord);

        const retrainingCount = trainingRecord ? trainingRecord.retrainingCount : 0;
        let fixedDocument = {
          ...trainingRecord,
          ...existingEntry,
          trainingRecordId: trainingRecord.id,
          customID: trainingRecord.customID,
          name: trainingRecord.name,
          DocumentId: documentId,
          versionId,
          status: trainingRecord ? CommonTraining.getCompletionStatus(trainingRecord, allTrainingRecords, {completedAsDate: false}) : "Draft",
          documentVersion: this.formatVersion(trainingRecord),
          assignmentDate: trainingRecord ? trainingRecord.assignmentDate : existingEntry.assignmentDate,
          completionDate: trainingRecord ? trainingRecord.completionDate : null,
          typeCode: "DOC",
          linkToVersion: !isLatestVersion,
          isLatestVersion,
          retrainingCount,
          isLatestRetraining,
        };
        fixedDocument = this.addExtraInformationToItemToDisplay(fixedDocument, currentGroup, trainingRecord);

        if (isLatestVersion) {
          itemsToDisplay.set(key, fixedDocument);
        }
      }
    }
  }

  addExtraInformationToItemToDisplay(itemToDisplay, currentGroup, trainingRecord = undefined) {
    Ensure.virtual("addExtraInformationToItemToDisplay", {itemToDisplay, currentGroup, trainingRecord});

    const customIDParams = {
      customID: itemToDisplay.customID,
      typeCode: itemToDisplay.typeCode,
      id: itemToDisplay.DocumentId,
    };

    const customIDForSorting = UIUtils.getRecordCustomIdForSorting(customIDParams);
    const versionSortingExpression = (Number.MAX_SAFE_INTEGER - itemToDisplay.versionId).toString().padStart(6, "0");
    const retrainingSortingExpression = (Number.MAX_SAFE_INTEGER - itemToDisplay.retrainingCount).toString().padStart(6, "0");

    itemToDisplay.sortExpressionForView = `${customIDForSorting}-${versionSortingExpression}-${retrainingSortingExpression}`;
    return itemToDisplay;
  }

  /**
   * Retrieves the document version ID given an object.
   * @param entity
   * @return {*}
   */
  getDocumentVersionId(entity) {
    return (
      // We can use this method with training records.
      entity.trainingDocumentVersionId
      // A typeahead object should contain this
      || entity.versionId
      // This is the regular casing for this field when using a document
      || entity.LastApprovedVersionId
      // but... Bulk approval uses this casing.
      || entity.lastApprovedVersionId
    );
  }

  /**
   * Returns a map with the contents of an array mapped by a field (defauilts to "id").
   * @template T {IDocument|ICurriculum|IDocumentVersion|ICurriculumVersion}
   * @param array {T[]} The array containing the items
   * @param [fieldName] {string} The field to be used for indexing (defaults to ID)
   * @return {Map<number, T>}
   */
  getEntriesMappedByField(array, fieldName = "id") {
    array = array || [];
    return new Map(array.map(entry => [entry[fieldName], entry]));
  }

  /**
   * Returns a map with the contents of an array grouped by the document version
   * @template T {IDocument|ICurriculum|IDocumentVersion|ICurriculumVersion}
   * @param array {T[]} The array containing the items
   * @return {Map<number, T>}
   */
  getTrainingRecordsGroupedByDocumentVersion(array) {
    const processedArray = [...(array || [])];
    // processes records in creation order
    processedArray.sort(UIUtils.sortBy("id"));
    const groupingMap = new Map();

    for (let item of processedArray) {
      const key = item.trainingDocumentVersionId;

      if (!groupingMap.has(key)) {
        groupingMap.set(key, []);
      }
      let groupEntryArray = groupingMap.get(key);

      // uses the retraining count as the index for the array
      groupEntryArray[item.retrainingCount || 0] = item;
      groupingMap.set(key, groupEntryArray);
    }
    return groupingMap;
  }

  applyRecordFilter(records) {
    records = records || [];
    const {searchTerm} = this.props;
    let result = records;

    // no need to loop if we don't have a search term
    if (searchTerm) {
      result = records.filter(attribute => attribute.name.match(searchTerm));
    }
    return result;
  }

  formatStatusLabel(rowData) {
    const {completionDate, status} = rowData;

    const statusClass = `training-badge-${UIUtils.convertToCamelCaseId(status || "none")}`;
    return (
      <Fragment>
        <span className={`training-badge ${statusClass}`}>{status}</span>{
        (status === "Completed" && completionDate)
          ? (<span className="training-event-date"> on {this.formatDateForDisplay(completionDate)}</span>)
          : ""
      }
      </Fragment>
    );
  }

  render() {
    const {id} = this.props;

    return (
      <div className="col-12 group-by-table-container training-editor-table" id={`${id}Container`}>
        <table ref={ref => this.tableRef = ref}
               className={"table dataTable no-footer" + this.getClassForLoading()} id={`${id}Table`}
               style={{width: "100%"}}
        />
      </div>
    );
  }

  getTrainingRecordKey(trainingRecord, document) {
    const versionId = this.getDocumentVersionId(trainingRecord || document);
    return `${versionId}${trainingRecord && trainingRecord.retrainingCount > 0 ? "$" + trainingRecord.retrainingCount : ""}`;
  }
}

DocumentsInTrainingTable.defaultProps = {
  onCheckPermissions: () => true,
};

export default I18NWrapper.wrap(DocumentsInTrainingTable, ["training", "base_page"]);

// i18next-extract-mark-ns-stop training
