"use strict";

import React from "react";
import SmartUploadFilePicker from "./smart_upload_file_picker";
import Typeahead from "../../../../widgets/typeahead";
import ETLData from "../extractAndEditWizardStep/etl_data";
import FieldTooltip from "../../../../widgets/tooltips/field_tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretSquareDown } from "@fortawesome/free-regular-svg-icons";
import ToggleInput from "react-switch";
import TypeaheadObjectCache from "../../../../utils/cache/typeahead_object_cache";
import * as ProcessCache from "../../../../processExplorer/process/process_cache";
import BaseReactComponent from "../../../../base_react_component";
import ImportProcessSelect from "../../general/import_process_select";
import ImportProcessInfo from "../../general/import_process_info";
import * as UIUtils from "../../../../ui_utils";
import { StatusDisplayService } from "../../../../services/status_display_service";
import { ImportMessageHandler } from "../../../../services/import/import_message_handler";
import { BatchesService } from "../../../../services/import/batches_service";
import { MODEL_DECLARATIONS } from "../../../../../server/common/generic/common_models";

/**
 * This shows the Inputs panel for the Smart Upload Import flow.
 */
export default class SmartUploadInputsPanel extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      processes: [],
    };

    this.batchesDisplayService = new StatusDisplayService();
    this.batchDataMessageService = new ImportMessageHandler(this.batchesDisplayService);
    this.batchesDataService = new BatchesService(this.batchDataMessageService, this.batchesDisplayService);

    if (props.projectId) {
      new TypeaheadObjectCache("Process", props.projectId).loadOptions(this.handleProcessesLoaded);
    }
  }

  // eslint-disable-next-line no-unused-vars
  async componentDidUpdate(prevProps, prevState, snapshot) {
    const {selectedDependencyRecord} = this.props;
    let dependencyRecordId = selectedDependencyRecord ? selectedDependencyRecord.id : null;
    let prevDependencyRecordId = prevProps.selectedDependencyRecord ? prevProps.selectedDependencyRecord.id : null;
    let attributeKey = "materialAttributes";
    const isLibrary = selectedDependencyRecord?.typeCode === MODEL_DECLARATIONS.LIBRARY_MATERIAL.typeCode;
    if (isLibrary)  {
      attributeKey = "specifications";
    }
    if (dependencyRecordId && dependencyRecordId !== prevDependencyRecordId) {
      const selectedDependencyRecordId = UIUtils.getParameterByName("selectedDependencyRecordId");
      if (UIUtils.parseKey(selectedDependencyRecord.id).id ===
        UIUtils.parseInt(selectedDependencyRecordId) &&
        selectedDependencyRecord[attributeKey]
      ) {
        UIUtils.setHideLoadingOnAjaxStop(false);

        let materialAttributesWithMeasurements = [];
        if (isLibrary) {
          materialAttributesWithMeasurements = await this.batchesDataService.loadLibraryMaterialSpecificationMeasurements({
            attributeIds: selectedDependencyRecord[attributeKey].map(ma => ma.id),
          });
        } else {
          materialAttributesWithMeasurements = await this.batchesDataService.loadMaterialAttributeMeasurements({
            attributeIds: selectedDependencyRecord[attributeKey].map(ma => ma.id),
          });
        }

        selectedDependencyRecord.batchMeasurementsLoaded = true;

        for (const materialAttribute of selectedDependencyRecord[attributeKey]) {
          const materialAttributeWithMeasurements =
            materialAttributesWithMeasurements.find(
              (maWithMeasurements) => maWithMeasurements.id === materialAttribute.id,
            );
          materialAttribute.batches = materialAttributeWithMeasurements && materialAttributeWithMeasurements.batches;
        }

        UIUtils.setHideLoadingOnAjaxStop(true);
        UIUtils.hideLoadingImage();
      }

      this.props.onETLDataUpdated(new ETLData({
        batchData: null,
        dataAttributes: selectedDependencyRecord[attributeKey],
      }));
    }
  }

  handleProcessesLoaded(processes) {
    processes = processes.filter(process => !process.deletedAt);
    const {onWizardStepDataUpdated, process, projectId} = this.props;
    const processId = process && process.id
      || ProcessCache.getProcessIdUsedRecently(projectId)
      || processes[0] && processes[0].id;
    const newSelectedProcess = processes.find(process => process.id === processId);

    onWizardStepDataUpdated({process: newSelectedProcess}, () => {
      this.setStateSafely({processes});
    });
  }

  handleFileSelected() {
    this.dependencySelectionTypeahead?.blur();
  }

  handleDependencyRecordSelected(value) {
    if (value && value[0]) {
      this.props.onMaterialSelected(value[0]);
    } else {
      this.props.onMaterialSelected(null);
    }
  }

  handleProcessSelected(e) {
    const {processes} = this.state;
    const {onWizardStepDataUpdated, onSelectedProcessChanged} = this.props;
    const process = processes.find(process => process.name === e.target.value);
    onWizardStepDataUpdated({
      process,
      selectedDependencyRecord: null,
    }, () => {
      onSelectedProcessChanged(process);
      this.dependencySelectionTypeahead.clear();
    });
  }

  render() {
    const {
      allowedFiles, selectedFile, textractResults, dependencyRecords, selectedDependencyRecord, showAdvancedOptions,
      onAdvancedColumnsToggleChange, process, allowProcessSelection, hideDependencyTypeahead,
    } = this.props;
    const {processes} = this.state;

    let materials = dependencyRecords.filter(rec => rec.name !== "");
    let attributeKey = "materialAttributes";
    if (dependencyRecords.length > 0) {
      if (dependencyRecords[0].typeCode === MODEL_DECLARATIONS.LIBRARY_MATERIAL.typeCode) {
        attributeKey = "specifications";
      }

      materials = dependencyRecords.filter(rec => rec[attributeKey] && rec[attributeKey].length > 0);
    }

    const columnsDetected = textractResults
      && textractResults.columns
      && textractResults.columns.length > 0;
    let allColumns = columnsDetected ? textractResults.columns : [];
    const numOfSelectedAttributesColumns = allColumns.filter(column => column.isAttributesColumn).length;
    const numOfSelectedResultsColumns = allColumns.filter(column => column.isResultsColumn).length;
    const showContentToImport = selectedDependencyRecord && textractResults && textractResults.jobId;
    const hasMaterialsWithNoAttributes = materials.length < dependencyRecords.length;

    return (
      <div className="import-smart-select-div">
        <div className="row smart-select-dependency-selection-div">
          <div id="dependencySelectionTypeaheadDiv"
               className="col-4"
          >
            {
              allowProcessSelection ? (
                <ImportProcessSelect processes={processes}
                                     process={process}
                                     showRequiredMark={true}
                                     onProcessChange={this.handleProcessSelected}
                />

              ) : <ImportProcessInfo process={process} />
            }
          </div>
        </div>
        { !hideDependencyTypeahead ?
        <div className="smart-select-dependency-selection-div">
          <span className="col-form-label">{this.getSelectMaterialLabel()}
            <span className={selectedDependencyRecord ? "base-attribute-value-specified" : "base-attribute-value-missing"}>
              {" *"}
            </span>
          </span>
          <div id="dependencySelectionTypeaheadDiv"
               className="smart-select-dependency-field-with-tooltip-div"
          >
            <Typeahead id="dependencySelectionTypeahead"
                       inputProps={{id: "dependencySelectionTypeaheadInput"}}
                       ref={(ref) => this.dependencySelectionTypeahead = ref}
                       options={materials}
                       multiple={false}
                       selected={selectedDependencyRecord ? [selectedDependencyRecord] : null}
                       onChange={this.handleDependencyRecordSelected}
            />
            {hasMaterialsWithNoAttributes ? (
              <FieldTooltip id="dependencySelectionField"
                            text={this.getMaterialWithNoAttributesLabel()}
              />
            ) : ""}
          </div>
        </div> : <></> }
        <SmartUploadFilePicker allowedFiles={allowedFiles}
                               selectedFile={selectedFile}
                               onFileSelected={this.props.onFileSelected}
        />
        {showContentToImport ? (
          <div className="smart-select-content-selection-div">
            <div className="smart-select-content-selection-header">
              <span className="col-form-label">Columns</span>
            </div>
            <div className="material-attributes-div">
              <div className="material-attributes-shape" />
              <span className="material-attributes-label">{this.getAttributesLabel()}</span>
              <span id="selectedAttributeColumns">{numOfSelectedAttributesColumns} column{numOfSelectedAttributesColumns > 1 ? "s" : ""} selected</span>
            </div>
            <div className="results-div">
              <div className="results-attributes-shape" />
              <span className="results-label">Results</span>
              <span id="selectedResultsColumns">{numOfSelectedResultsColumns} column{numOfSelectedResultsColumns > 1 ? "s" : ""} selected</span>
            </div>
            <div className="advanced-options-div">
              <ToggleInput id="showAdvancedColumnsOptionsToggle"
                           className="control-checkbox-toggle"
                           checked={!!showAdvancedOptions}
                           onChange={onAdvancedColumnsToggleChange}
                           disabled={!columnsDetected}
                           height={16}
                           width={26}
                           checkedIcon={false}
                           uncheckedIcon={false}
                           activeBoxShadow="0 0 2px 3px #014768"
                           onColor="#DBE1E4"
                           offColor="#DBE1E4"
                           onHandleColor="#1F46A1"
              />
              <div className="advanced-options-text-div">
                <div>
                  Change selected columns
                </div>
                <div className="advanced-options-info-div">
                  {!columnsDetected ? (
                    <div className="alert alert-warning">
                      No columns detected.
                    </div>
                  ) : showAdvancedOptions ? (
                    <div>
                      <span>Select from the {
                        <FontAwesomeIcon icon={faCaretSquareDown} size="sm" />
                      } button what the columns contain</span>
                    </div>
                  ) : ""}
                </div>
              </div>
            </div>
          </div>
        ) : ""}
      </div>
    );
  }

  isLibraryMaterial() {
    return !this.props.projectId;
  }

  getSelectMaterialLabel() {
    return this.isLibraryMaterial() ? "Select Library Material" : "Select Material";
  }

  getAttributesLabel() {
    return this.isLibraryMaterial() ? "Library Material Specifications" : "Material Attributes";
  }

  getMaterialWithNoAttributesLabel() {
    return this.isLibraryMaterial() ? "Only approved Library Materials with Specifications are shown." : "Only Materials with Material Attributes are shown.";
  }
}
