"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BasePage from "../../base_page";
import { SortingFunctions } from "../../utils/filter_helper";
import PageFrameLayout from "../../widgets/layout/page_frame_layout";
import { FilteredList } from "../../widgets/tables/filtered_list";
import { TRAINING_TABS, TRAINING_TABS_ENUM } from "../../helpers/constants/constants";
import { Trans } from "react-i18next";
import { can, generateTooltip } from "../../utils/ui_permissions";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import ReactDOM from "react-dom";
import CommonSecurity from "../../../server/common/generic/common_security";
import { COLUMN_FORMATTERS } from "../../widgets/tables/column_formatters";

/* This shows the list of individual training plans / curricula. */
// i18next-extract-mark-ns-start training
class CurriculumListPage extends BasePage {
  constructor(props) {
    super(props);

    const {t} = props;
    this.getTrainingStatus = this.getTrainingStatus.bind(this);
    this.handleQuickPanelInstanceReceived = this.handleQuickPanelInstanceReceived.bind(this);
    this.handleDataPreProcess = this.handleDataPreProcess.bind(this);

    this.filters = [];
    UIUtils.setHideLoadingOnAjaxStop(false);

    this.setStateSafely({
      breadcrumb: {
        current: t("Training"),
      },
    });
  }

  componentDidMount() {
    document.title = "QbDVision Training";

    super.componentDidMount();
  }

  handleAdd() {
    window.location.href = UIUtils.getSecuredURL("./viewEdit.html?operation=Add");
  }

  handleDataPreProcess(data) {
    data.instances = data.instances.map(item => ({
      ...item,
      departments: item.departments ? JSON.parse(item.departments) : [],
    }));
    return data;
  }

  handleQuickPanelInstanceReceived(curriculumVersion) {
    const result = {
      ...curriculumVersion,
      departments: curriculumVersion.departments ? JSON.parse(curriculumVersion.departments).sort(SortingFunctions.stringAscending(item => item)) : [],
    };
    console.log("CURRICULA", result);
    return result;
  }

  renderPage() {
    const {t} = this.props;
    return (
      <PageFrameLayout title={t("Training")} tabs={TRAINING_TABS} selectedTab={TRAINING_TABS_ENUM.Curricula}>
        <FilteredList
          parent={this}
          topLevel
          hideRemoveButton
          hideShowArchivedButton
          showFilterBar={false}
          filters={this.filters}
          editableName="Curriculum"
          editableDisplayName={t("Curriculum")}
          onQuickPanelInstanceReceived={this.handleQuickPanelInstanceReceived}
          onDataPreProcess={this.handleDataPreProcess}
          onGenerateColumns={() => {
            return [
              {
                ...COLUMN_FORMATTERS.VERSION,
                title: t("Version"),
              },
              {
                title: t("Assigned to"),
                width: 200,
                data: item => item.departments,
                createdCell: (td, cellData) => {
                  ReactDOM.render(
                    <Trans t={t} count={cellData.length}><span>{{count: cellData.length}}</span><span> Department</span></Trans>, td);
                },
              },
              {
                ...COLUMN_FORMATTERS.CURRENT_STATE,
                title: t("Status"),
              },
              {
                title: t("Last Modified"),
                width: 50,
                data: item => item.updatedAt,
                ...COLUMN_FORMATTERS.DATE(),
              }
            ];
          }}
          onRenderHeader={(props, state) => {
            const {filteredItems} = state;
            const {editableDisplayName} = props;
            const itemCount = filteredItems && filteredItems.length > 0 ? filteredItems.length : 0;

            return (
              <div className="col-sm-12">
                <h2 className="header-inline">
                  <span className="header-item-count">{itemCount} </span>
                  <span className="header-item-type">{UIUtils.pluralize(editableDisplayName, itemCount)}</span>
                </h2>
                <button className="btn btn-lg btn-primary button-float-right"
                        id="addButton"
                        onClick={this.handleAdd}
                        title={t(generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.CURRICULUM))}
                        disabled={!can(CommonSecurity.Actions.ADD, CommonSecurity.Types.CURRICULUM)}
                >
                  {t("Add")}
                </button>
              </div>
            );
          }}
        />
      </PageFrameLayout>
    );
  }

  getTrainingStatus(item) {
    return item.Documents && item.Documents.length > 0 && item.LastApprovedVersionId > 0 ? "Assigned" : "Not Assigned";
  }
}

export default I18NWrapper.wrap(CurriculumListPage, ["documents", "users"]);
// i18next-extract-mark-ns-stop training
