import * as React from 'react';
import { noop } from '../../noop';
/**
 * @hidden
 */
export var DragContext = React.createContext([
    undefined,
    noop
]);
/**
 * @hidden
 */
export var DropContext = React.createContext([
    undefined,
    noop
]);
/**
 * @hidden
 */
export var DragsContext = React.createContext([[], noop, noop]);
/**
 * @hidden
 */
export var DropsContext = React.createContext([[], noop, noop]);
