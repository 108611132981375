/* stylelint-disable-next-line import-notation */
@import "../../../../../css/brand_colors";


.container {
  display: flex;
  position: fixed;
  bottom: 31px;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  user-select: none;

  .notification {
    display: inline-flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0 auto;
    border-radius: 4px;
    background: $brand-dark-grey;
    border-bottom: 4px solid $brand-dark-grey;
  }

  .message {
    color: $brand-white;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
