"use strict";

import React, { useReducer, useState } from "react";
import ErrorBar from "../../../widgets/bars/error_bar";
import { LibraryHelper } from "../../helpers/library_helper";
import { MaterialSpecifications } from "../createProjectMaterial/components/project_material_specifications";
import {
  ProjectMaterialOptionsContainer
} from "../createProjectMaterial/components/project_material_options_container";
import { preferencesReducer } from "../createProjectMaterial/project_material_reducer";
import { MATERIAL_OPTIONS_ACTIONS } from "../createProjectMaterial/project_material_actions";
import { useWizardContext } from "../../hooks/use_wizard_context_hook";
import { WizardExitStrategies } from "../wizard_exit_strategies";

/**
 * The second flow step, the user will pick up project, process, unit operation and step information to define the material.
 * and will pick up few specifications to be created as material attributes.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function ProjectMaterialOptionsStep(props) {

  const {
    config,
    action,
    wizardStepsConfig,
    currentStep,
    isStartingFromProject
  } = props;

  const {initialState} = config;

  const [
    materialLibrary,
    setMaterialLibrary
  ] = useState(initialState);

  const context = useWizardContext();
  const {WizardHelper} = context;

  const wizardHelper = new WizardHelper(context);

  // noinspection JSCheckFunctionSignatures
  const [state, dispatch] = useReducer(preferencesReducer, {
    ProjectId: props.ProjectId,
    ProcessId: props.ProcessId,
    UnitOperationId: props.UnitOperationId,
    StepId: props.StepId,
    keepSynced: true,
  });

  const {
    ProjectId,
    ProcessId,
    UnitOperationId,
  } = state;

  const specifications = materialLibrary.Specifications;

  // eslint-disable-next-line no-unused-vars
  const handleChangeValue = (attributeName, attributeValue) => {
    switch (attributeName) {
      case "ProjectId":
      case "ProcessId":
      case "UnitOperationId":
      case "StepId":
      case "keepSynced":
        // noinspection JSCheckFunctionSignatures
        dispatch({type: MATERIAL_OPTIONS_ACTIONS.UpdateFieldValue, attributeName, attributeValue});
        break;
      default:
        break;
    }
  };

  const handleOnSelect = ({position, toggle}) => {
    const newMaterialLibrary = LibraryHelper.onSelectSpecificationClick(materialLibrary, position, toggle);
    setMaterialLibrary(newMaterialLibrary);
  };

  const onExitClick = () => {
    const onExit = WizardExitStrategies[action];
    return onExit(props);
  };

  return (
    <div className={"row-white material-library-wizard-steps-container"
      + (currentStep === 1 ? " first-wizard-step" : "")
      + (!config.visible ? " hidden" : "")}
    >
      <div className="col-12 material-library-wizard-step-inner-container material-options-container">
        <ErrorBar id="operationAlertDiv"
                  className="create-from-project-error-bar mt-4 mb-2"
        />
        <div className="row">
          <div className="col">
              <h2 className="create-header">{initialState.name} <span className="identifier">MTL-{initialState.id}</span></h2>
          </div>
        </div>
        <div className="row">
          <ProjectMaterialOptionsContainer
            filterSteps={(option) => option.UnitOperationId === UnitOperationId}
            handleChangeValue={handleChangeValue}
            {...state}
            isStartingFromProject={isStartingFromProject}
            showInCard={false}
          />
        </div>
        <div className="row">
          <div className="col">
            <div className="pt-3 border-top create-attributes-container">
              <h2 className="create-attributes-header">Material Attributes</h2>
              <MaterialSpecifications specifications={specifications}
                                      onSelect={handleOnSelect}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row action-buttons-box">
        <div className="col">
          <button className="btn btn-lg btn-primary float-right"
                  id="createButton"
                  disabled={!ProjectId || !ProcessId || !UnitOperationId}
                  onClick={
                    () => {
                      LibraryHelper.onCreateProjectMaterialClick(
                        () => LibraryHelper.preprocessRequestCallback({
                          ...state,
                          instance: materialLibrary,
                        }), LibraryHelper.redirectToMaterial
                      );
                    }
                  }
          >
            Create
          </button>
          <button className="btn btn-lg btn-secondary float-right mr-2" onClick={() => wizardHelper.goToPreviousStep({
            config,
            wizardStepsConfig
          })}
          >
            Previous
          </button>
          <button className="btn btn-lg btn-secondary float-left" id="exitButton" onClick={onExitClick}>
            Exit
          </button>
        </div>
      </div>
    </div>
  );
}
