"use strict";

import React from "react";
import * as UIUtils from "../../ui_utils";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseReactComponent from "../../base_react_component";

/**
 * This class is for producing a div mimicking an empty table
 */

// i18next-extract-mark-ns-start widgets
class EmptyTable extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {modelName, t} = this.props;

    return (
      <div id={`${UIUtils.stripAllWhitespaces(modelName)}EmptyTable`}
           className="empty-table">
        {t("Add a {{ modelName }}", {modelName})}
      </div>
    );
  }
}

export default I18NWrapper.wrap(EmptyTable, "widgets");
// i18next-extract-mark-ns-stop widgets
