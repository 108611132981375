"use strict";

import React, { Fragment } from "react";
import BaseReactComponent from "../../../base_react_component";
import Typeahead from "../../../widgets/typeahead";

/**
 * This renders the project selection typeahead rendered in various places in the import flow.
 */
export default class ImportProjectSelect extends BaseReactComponent {
  render() {
    const {projects, projectId, onTypeaheadChange} = this.props;
    let selectedProjects;
    if (projectId) {
      selectedProjects = (projects || []).filter(project => project.id === projectId);
    }

    return (
      <Fragment>
        <div>
          <label id="projectSelectionDropDownLabel"
                 htmlFor="chooseProjectTypeahead"
          >
            Project
          </label>
        </div>
        <div>
          <Typeahead id="chooseProjectTypeahead"
                     inputProps={{id: "chooseProjectTypeaheadInput", autoComplete: "off"}}
                     options={projects}
                     selected={selectedProjects}
                     onChange={onTypeaheadChange}
                     multiple={false}
          />
        </div>
      </Fragment>
    );
  }
}