"use strict";

import React from "react";
import BaseLinksAttachmentsAttribute from "./base_links_attachments_attribute";
import { WIDGET_FIELDS } from "./constants/doc_links_attribute_constants";

/**
 * This class is responsible for handling documents links and attachments within sections.
 */
export default class DocLinksAttribute extends BaseLinksAttachmentsAttribute {
  constructor(props, widgetFields) {
    super(props, (Array.isArray(widgetFields) && widgetFields)
      || (props.hideAppliesToColumn && WIDGET_FIELDS.filter(field => field.fieldName !== "appliesTo"))
      || WIDGET_FIELDS);
  }

  getAppliesToOptions() {
    return this.props.getAttributeOptions();
  }
}
