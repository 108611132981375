"use strict";

import dottie from "dottie";
import * as UIUtils from "../../ui_utils";
import { TEXT_DIFF_METHOD_ENUM } from "../../helpers/constants/constants";

/**
 * Indicates what a field is required for.
 * @enum {string}
 */
export const FIELD_REQUIRED_FOR = {
  none: "none",
  save: "save",
  proposal: "proposal",
};

/**
 * The input type that will be used for user input for the particular field in the rows inline editor.
 * @enum {string}
 */
export const FIELD_INPUT_TYPE = {
  text: "text",
  textarea: "textarea",
  number: "number",
  select: "select",
  typeahead: "typeahead",
  link: "link",
  linkedTypeahead: "linkedTypeahead",
  linkIcon: "linkIcon",
  checkbox: "checkbox",
  date: "date",
  criticality: "criticality",
  nonEditable: "nonEditable",
  customInput: "customInput"
};

/**
 * @typedef IWidgetField
 * @property {string} [fieldName] The name of attribute that will be used for storing the field value. Needs to be a proper attribute id.
 * @property {TEXT_DIFF_METHOD_ENUM} [diffMethod] Can be any value from the TEXT_DIFF_METHOD_ENUM (whole & differential). This controls how the diff for the particular filed will be
 *     rendered when viewing a version of an object the widget belongs to.
 * @property {FIELD_REQUIRED_FOR} [requiredFor] Can be (none, proposal, save). Makes the field mandatory when saving/proposing and throws an error if no value is specified.
 * @property {string} [placeholder] The text which will show up on the fields input control in the widget's row inline editor.
 * @property {boolean} [belongsToMasterRow] True if the field will be rendered as part of the master row, false if it should be rendered on the child row.
 * @property {number} order The order the field should show up. The order is not reset for child rows. It is an incremental id, starting from 1.
 * @property {FIELD_INPUT_TYPE} inputType The input type that will be used for user input for the particular field in the rows inline editor.
 *     Allowed values are: text, textarea, number, select, typeahead, link, linkedTypeahead, linkIcon, checkbox and date.
 *     The link and linkedTypeahead are supported in the child base classes, base_links_attachments_attribute.jsx & base_linked_entities_attribute.jsx
 *     The link input type allows for a link to be specified or an attachment to be uploaded and the linkedTypeahead allows a linked
 *     entity to be selected.
 * @property {string} [fieldPath] The path to the field if nested inside a property in the row object
 *                                (if it's not nested, it will use the field name instead)
 * @property {string} [displayName] The name that will be used as a caption for the field in the table header.
 * @property {FIELD_REQUIRED_FOR} [requiredFor]
 * @property {string} [tooltipText] The tooltip text that will be shown when clicking on the field's column header.
 * @property {boolean} [forceUpdate] If set to true, forces the widget's parent state to be updated (i.e the page holding the widget) when the inline editor is saved.
 * @property {*} [defaultValue] A default value for the field for newly added rows. This can be a simple string, a json structure or a method defined on the control itself.
 *     Child controls can provide the method for getting the default value for a given field. The control first checks if the provided string is a method
 *     and then if not, it uses that string as the default value. If the provided value is a json object, then that object is cloned and assigned to the field.
 * @property {boolean} [multiSelect] Applicable for typeahead input fields. Allows multi selection on the typeahead.
 * @property {string} [getOptions] Applicable for typeahead and select input fields. This is the name of a method defined on a child class which will be executed
 *     in order to provide the options for the typeahead or the select input controls.
 * @property {number|string} [width] Optional. Can be specified to control the field's column width. For the master row, this should be a numerical value representing pixels.
 *     For the child rows, this can be either a numerical value or a percentage value in the form of "x%", representing the column width
 *     in the child row as a percentage. When numerical values are specified, then all controls will adjust their width proportionally to
 *     the numerical values and they will all fit into a single child row. When percentages are used then it is up to the developer to
 *     decide in how many child rows the fields should be split. Once a row is populated with fields and before the total width exceeds 100%,
 *     the next fields will be split to a new row. This offers great flexibility on how to organize the controls into multiple child rows within
 *     then child section of the data table. If both numerical and percentage values are specified for the child section, then an error will be
 *     raised.
 * @property {number} [rows] Applicable for the textarea input type. Controls the number of rows the textarea control will use in the row inline editor.
 * @property {boolean|string} [disabled] Applicable for all input fields during data input. When set to true the field it corresponds to will not accept any input from the user.
 *     disabled can also be a function name, in which case the return value of that function will determine if the field will be disabled or not.
 * @property {boolean} [allowDecimalNumbers] This will make the number field accept decimal numbers with any step value and not only integers.
 * @property {boolean} [orderable] This is true by default but when set to false will make this column unsortable
 * @property {boolean} [singleLineOptions] Applicable for the typeahead input type. This will make it so that each typeahead option is
 *     rendered on its own line.*
 * @property {string} formatValue The name of a function that formats the value to be displayed.
 * @property {string} additionalPropsGetter The name of a function that retrieves additional props to be included in the input field.
 * @property {number} maxLength The maximum length of the field (if a text field or autocomplete typeahead)
 * @property {boolean} hidden The optional property to hide the field
 */

/**
 * Holds a map with the count unnamed fields per attribute.
 * @type {Map<any, any>}
 */
const unnamedFieldsMap = new Map();

/**
 * This class represents a field used in {@link BaseJsonAttribute}, as defined in WIDGET_FIELDS,
 * but with additional functionality.
 * @implements {IWidgetField}
 */
export class WidgetField {
  /**
   * @param field {IWidgetField} The widget field declaration
   * @param attributeName {string} The name for the {@link BaseJsonAttribute}.
   */
  constructor(field, attributeName) {
    this._attributeName = attributeName;

    // Sets the public default values
    this.defaultValue = "";
    this.belongsToMasterRow = true;
    this.diffMethod = TEXT_DIFF_METHOD_ENUM.whole;
    this.disabled = false;
    this.multiSelect = false;
    this.allowDecimalNumbers = false;
    this.forceUpdate = false;
    this.placeholder = "";
    this.tooltipText = "";
    this.width = 200;
    this.orderable = true;
    this.requiredFor = FIELD_REQUIRED_FOR.none;
    this.singleLineOptions = false;
    this.fieldPath = undefined;
    this.getOptions = undefined;
    this.displayName = UIUtils.convertCamelCaseToSpacedOutWords(attributeName);
    this.fieldName = undefined;
    this.inputType = undefined;
    this.order = undefined;
    this.rows = undefined;
    this.formatValue = undefined;

    // Copies properties from the field object.
    Object.assign(this, field);
  }

  /**
   * Retrieves the dictionary of unnamed fields for this object.
   * @param attributeName
   */
  getUnnamedFields(attributeName) {
    if (!unnamedFieldsMap.has(attributeName)) {
      unnamedFieldsMap.set(attributeName, []);
    }
    return unnamedFieldsMap.get(attributeName);
  }

  /**
   * Retrieves the path to the property that contains the value of the field.
   * @return {string}
   */
  getFieldPath() {
    return this.fieldPath || this.fieldName;
  }

  /**
   * Gets the value for the current field in the specified object.
   * @param obj {*} The object that contains the field.
   * @return {*} The value of the field in the given object
   */
  getValue(obj) {
    let path = this.getFieldPath();
    return path ? dottie.get(obj, path) : obj[path];
  }

  /**
   * Sets the value for the current field in the specified object.
   * @param obj {*} The object that contains the field.
   * @param value {*} The value to be set.
   * @return {*} The object value that was set.
   */
  setValue(obj, value) {
    const path = this.getFieldPath();
    if (path) {
      dottie.set(obj, path, value);
      return dottie.get(obj, path);
    } else {
      obj[path] = value;
      return obj[path];
    }
  }

  /**
   * Gets the default value for the current field in the specified object.
   * @param component {*} The object that contains the method to retrieve the default value
   * @param dataSource {*} The object that contains the field data.
   */
  getDefaultValue(component, dataSource) {
    const field = this;
    let defaultValue = dottie.get(component, field.defaultValue);

    if (typeof defaultValue === "function") {
      return defaultValue(field, dataSource);
    } else {
      if (typeof field.defaultValue === "number") {
        return field.defaultValue;
      } else {
        return UIUtils.deepClone(field.defaultValue);
      }
    }
  }

  /**
   * Retrieves the field value formatted for display using the function specified in the
   * {@link WidgetField.formatValue} property.
   * If not specified, just returns the raw value retrieved with {@link getFormattedValue}.
   *
   * @param component {*} The object that contains the formatting method.
   * @param rowData {*} The data for the row.
   * @return {*} The value, formatted for display (or raw, if no formatter function specified).
   */
  getFormattedValue(component, rowData) {
    return this.invokeMethod(component, this.formatValue, rowData) || this.getValue(rowData);
  }

  /**
   * Returns additional props to be added to the input field using the function specified in the
   * {@link WidgetField.additionalPropsGetter} property.
   * If not specified, just returns an empty object, so no new prop will be added to the input field..
   *
   * @param component {*} The object that contains the formatting method.
   * @param rowData {*} The data for the row.
   * @return {*} The value, formatted for display (or raw, if no formatter function specified).
   */
  getAdditionalProps(component, rowData) {
    return this.invokeMethod(component, this.additionalPropsGetter, rowData) || {};
  }

  /**
   * Verifies whether the specified field is disabled for the specified row in the specified component.
   * @param component
   * @param rowData
   * @return {boolean|*}
   */
  isDisabled(component, rowData) {
    if (typeof component[this.disabled] == "function") {
      return component[this.disabled](rowData, this);
    } else {
      return this.disabled;
    }
  }

  /**
   * Retrieves the field value formatted for display using the method specified in the
   * {@link methodName} parameter.
   * @param component {*} The object that contains the method.
   * @param methodName {string} The name of the method to be invoked
   * @param rowData {*} The data for the row.
   * @return {*} The result of the method invocation.
   */
  invokeMethod(component, methodName, rowData) {
    let result;

    if (methodName) {
      let methodToInvoke = dottie.get(component, methodName);

      if (typeof methodToInvoke === "function") {
        result = methodToInvoke(this, rowData);
      }
    }
    return result;
  }
}