"use strict";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import CommonEditablesFormatter from "../../../../server/common/editables/common_editables_formatter";
import { EMPTY_STRING } from "../../../helpers/constants/constants";
import { getURLByTypeCodeAndId } from "../../../helpers/url_helper";

export const MaterialAttributeCell = ({dataItem, icon, field = EMPTY_STRING}) => {
  const dataValue = dataItem[field] === null ? "" : dataItem[field];
  const dataValue2 = CommonEditablesFormatter.getAttributeMeasurementInfoTechTransferFormat(dataItem);
  return (
    <td>
      <div className="specification-cell-outer-div">
        <span className="specification-cell-value">
          <a target="_blank" rel="noreferrer" href={getURLByTypeCodeAndId("MA", "View", dataItem.id, false)}>
            {dataValue}
          </a>

        </span>
        <span className="specification-cell-secondary">{dataValue2}</span>
        <FontAwesomeIcon icon={icon} />
      </div>
    </td>
  );
};