"use strict";

import * as UIUtils from "../../../../ui_utils";
import React from "react";
import BaseReactComponent from "../../../../base_react_component";

/**
 * This implements the Smart Upload drag and drop panel.
 */
export default class SmartUploadFileDropPanel extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  handleDragEnter(e) {
    this.handleDrag(e, true);
  }

  handleDragOver(e) {
    this.handleDrag(e, true);
  }

  handleDragLeave(e) {
    this.handleDrag(e, false);
  }

  handleDrop(e) {
    this.handleDrag(e, false);
    let files = e.dataTransfer && e.dataTransfer.files;
    if (files.length > 0) {
      this.props.onFileDroped(files[0]);
    }
  }

  /**
   * This handles drag start/stopping by applying to appropriate class and ensuring the event isn't propagated.
   * @param e The event
   * @param isStart {boolean} true if dragging is starting, false otherwise.
   */
  handleDrag(e, isStart) {
    UIUtils.ignoreHandler(e);
    if(isStart) {
      $("#fileDragAndDrop").addClass("highlight-for-dnd");
    } else {
      $("#fileDragAndDrop").removeClass("highlight-for-dnd");
    }
  }

  render() {
    const {visible} = this.props;

    return (
      <div id="fileDragAndDrop"
           className={"file-drag-and-drop-div" + (!visible ? " hidden" : "")}
           onDragEnter={this.handleDragEnter}
           onDragOver={this.handleDragOver}
           onDragLeave={this.handleDragLeave}
           onDrop={this.handleDrop}>
        <div className="file-drag-and-drop-label">
          {"Drag and drop a file here"}
        </div>
      </div>
    );
  }
}
