"use strict";

import React, { Fragment } from "react";
import * as I18NWrapper from "../../../i18n/i18n_wrapper";
import TechTransferReviewRow from "../widgets/tech_transfer_review_row";
import TechTransferVariableReviewBase from "../tech_transfer_variable_review_base";

// i18next-extract-mark-ns-start tech_transfer
/**
 *  This class shows the screen when a user is reviewing a tech transfer record.
 */
class TechTransferIntermediateAttributeReview extends TechTransferVariableReviewBase {
  renderCriticalityAssessment() {
    const {hideUnchangedFields} = this.state;
    const {reviewRecord, onViewChangeCriteria,} = this.props;
    const transferredTo = reviewRecord;
    const transferredFrom = reviewRecord.techTransferredFrom || {};
    const {totalChangesCount, changeCriteria,} = reviewRecord;

    const commonProps = {
      hideUnchangedFields,
      transferredFrom,
      transferredTo,
      changeCriteria,
      totalChangesCount,
      onViewChangeCriteria,
    };

    const usePartialDiffCommonProps = {
      usePartialDiff: true,
      ...commonProps,
    };

    return <Fragment>
      <div className="row">
        <div className="col-12 tech-transfer-review-section-title-container">
          CRITICALITY ASSESSMENT
        </div>
      </div>
      <TechTransferReviewRow name="riskLinksSummary"
                             displayName="Risk Links"
                             {...usePartialDiffCommonProps}
      />
      <TechTransferReviewRow name="maxCriticality"
                             displayName="Criticality (Raw)"
                             {...commonProps}
      />
      <TechTransferReviewRow name="maxCriticalityPercWithoutLabel"
                             prop="criticalityPerc"
                             displayName="Criticality (%)"
                             {...commonProps}
      />
      <TechTransferReviewRow name="scaleDependent"  {...commonProps} />
      <TechTransferReviewRow name="scaleJustification"  {...usePartialDiffCommonProps} />
      <TechTransferReviewRow name="recommendedActions"  {...usePartialDiffCommonProps} />
      <TechTransferReviewRow name="criticalityAssessmentLinks" displayName="Source Documents" isDocs={true} {...usePartialDiffCommonProps} />
    </Fragment>;
  }

  renderAcceptanceCriteria() {
    const {hideUnchangedFields} = this.state;
    const {reviewRecord, onViewChangeCriteria,} = this.props;
    const transferredTo = reviewRecord;
    const transferredFrom = reviewRecord.techTransferredFrom || {};
    const {totalChangesCount, changeCriteria,} = reviewRecord;

    const usePartialDiffCommonProps = {
      usePartialDiff: true,
      hideUnchangedFields,
      transferredFrom,
      transferredTo,
      changeCriteria,
      totalChangesCount,
      onViewChangeCriteria,
    };

    const commonProps = {
      hideUnchangedFields,
      transferredFrom,
      transferredTo,
    };

    return <Fragment>
      <div className="row">
        <div className="col-12 tech-transfer-review-section-title-container">
          ACCEPTANCE CRITERIA
        </div>
      </div>
      <TechTransferReviewRow name="dataSpace"  {...commonProps} />
      <TechTransferReviewRow name="measure"  {...commonProps} />
      <TechTransferReviewRow name="measurementUnits"  {...commonProps} />
      <TechTransferReviewRow name="lowerLimit"
                             displayName="LSL"
                             {...commonProps}
      />
      <TechTransferReviewRow name="target"  {...commonProps} />
      <TechTransferReviewRow name="upperLimit"
                             displayName="USL"
                             {...commonProps} />
      <TechTransferReviewRow name="controlMethods"
                             displayName="Control Methods"
                             {...commonProps} />
      <TechTransferReviewRow name="samplingPlan" {...commonProps} />
      <TechTransferReviewRow name="targetJustification"  {...usePartialDiffCommonProps} />
      <TechTransferReviewRow name="acceptanceCriteriaLinks"
                             displayName="Source Documents"
                             isDocs={true}
                             {...usePartialDiffCommonProps} />
    </Fragment>;
  }
}

export default I18NWrapper.wrap(TechTransferIntermediateAttributeReview, ["tech_transfer", "base_page"]);
// i18next-extract-mark-ns-stop tech_transfer
