"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GridColumn = void 0;
var PropTypes = require("prop-types");
/**
 * @hidden
 */
var GridColumn = function (_) { return null; };
exports.GridColumn = GridColumn;
/**
 * @hidden
 */
exports.GridColumn.displayName = 'KendoReactGridColumn';
/**
 * @hidden
 */
exports.GridColumn.defaultProps = {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true
};
/**
 * @hidden
 */
exports.GridColumn.propTypes = {
    field: PropTypes.string,
    title: PropTypes.string,
    editable: PropTypes.bool,
    sortable: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            allowUnsort: PropTypes.bool.isRequired
        })
    ]),
    cell: PropTypes.any,
    filterCell: PropTypes.any,
    filterTitle: PropTypes.string,
    filterable: PropTypes.bool,
    filter: PropTypes.oneOf(['text', 'numeric', 'boolean', 'date']),
    editor: PropTypes.oneOf(['text', 'numeric', 'boolean', 'date']),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minResizableWidth: PropTypes.number,
    headerCell: PropTypes.any,
    footerCell: PropTypes.any,
    headerSelectionValue: PropTypes.bool,
    format: PropTypes.string,
    headerClassName: PropTypes.string,
    className: PropTypes.string,
    reorderable: PropTypes.bool,
    resizable: PropTypes.bool,
    orderIndex: PropTypes.number,
    groupable: PropTypes.bool,
    columnMenu: PropTypes.any,
    locked: PropTypes.bool
};
