"use strict";

import React from "react";
import { createHTMLForWholeTextDiff } from "../../../helpers/diff_helper";
import BaseAttribute from "../base_attribute";
import { getNormalizedRiskScore, hasNotAssessedRiskScale } from "../../../helpers/risk_helper";
import * as UIUtils from "../../../ui_utils";
import RiskUtils from "../../../../server/common/misc/common_risk_utils";

/**
 * All the attributes that aggregations like average, max and total values from the risk tables should inherit this.
 * Child classes need to implement both getValue() and getOldValue().
 */
export class BaseAggregateRiskScaleAttribute extends BaseAttribute {
  constructor(props) {
    super(props);

    // Bind functions to this
    this.renderInput = this.renderInput.bind(this);
  }

  shouldRenderSubscriptLabel() {
    return this.state.shouldRenderSubscriptLabel;
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.state.shouldRenderSubscriptLabel !== !!(this.props.riskInfo && this.props.riskInfo.Criticality)) {
      this.setStateSafely({
        shouldRenderSubscriptLabel: !!(this.props.riskInfo && this.props.riskInfo.Criticality),
      });
    }
  }

  getNextValue(nextProps, allowNull) {
    let nextValue = super.getNextValue(nextProps, allowNull);
    if (!nextValue && this.riskType && nextProps.RMP && nextProps.riskInfo) {
      const hasNotAssessedScale = hasNotAssessedRiskScale(this.riskType, nextProps.RMP);
      if (hasNotAssessedScale) {
        let rawValue = nextProps.riskInfo.Criticality.value * UIUtils.parseInt(nextProps.capabilityRisk) * UIUtils.parseInt(nextProps.detectabilityRisk);
        let normalizedValue = getNormalizedRiskScore(this.riskType, nextProps.RMP, rawValue);
        if (RiskUtils.riskValueIsNotAssessed(normalizedValue, this.riskType, nextProps.RMP)) {
          nextValue = RiskUtils.getRiskLabel(this.riskType, nextProps.RMP, normalizedValue, nextProps.parent.state, false, true, true);
        }
      }
    }

    return nextValue;
  }

  componentDidUpdate() {
    if (this.state.shouldRenderSubscriptLabel !== !!(this.props.riskInfo && this.props.riskInfo.Criticality)) {
      this.setStateSafely({
        shouldRenderSubscriptLabel: !!(this.props.riskInfo && this.props.riskInfo.Criticality),
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      let value = this.getNextValue(nextProps);
      return (this.currentValue !== value) ||
        nextProps.riskInfo?.Criticality.value !== this.props.riskInfo?.Criticality.value ||
        nextProps.olderRiskInfo?.Criticality.value !== this.props.olderRiskInfo?.Criticality.value ||
        nextProps.parent?.id !== this.props.parent?.id ||
        nextProps.parent?.getOlderVersion()?.id !== this.props.parent?.getOlderVersion()?.id ||
        (nextState.shouldRenderSubscriptLabel !== this.state.shouldRenderSubscriptLabel);
    }
    return true;
  }

  renderInput() {
    let input = "Input type not defined: " + this.props.type;
    let inputId = this.getInputId();
    this.currentValue = this.getValue() ? this.getValue() : "";
    // Uncomment for verbose logging
    // console.log("Render: Generating " + this.getInputId() + " with value: " + value);
    if (this.isDiffingVersions()) {
      let oldValue = this.getOldValue();
      input = createHTMLForWholeTextDiff(oldValue, this.currentValue);
    } else {
      input = (<span id={inputId}>{this.currentValue}</span>);
    }

    return input;
  }
}
