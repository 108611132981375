"use strict";
// This file contains constants to determine what kind of container an editor is being shown.

export const EDITOR_TYPES = {
  FULL_SCREEN: "FULL_SCREEN",
  STATIC_PANEL: "STATIC_PANEL",
  STATIC_PANEL_FULL_WIDTH: "STATIC_PANEL_FULL_WIDTH",
  QUICK_PANEL: "QUICK_PANEL",
  TRAINING_PANEL: "TRAINING_PANEL",
};

export const EDITOR_OPERATIONS = {
  VIEW: "View",
  ADD: "Add",
  EDIT: "Edit",
};

export const RECORD_OPERATIONS = {
  BULK_PROPOSE: "BULK_PROPOSE",
  BULK_APPROVE: "BULK_APPROVE",
};

export const SIDE_PANEL_TABS_ENUM = {
  RELATED_RECORDS: {
    title: "Related",
    disabled: false,
  },
  HISTORY: {
    title: "History",
    disabled: false,
  },
};

