import * as React from 'react';
import { classNames } from '@progress/kendo-react-common';
/**
 * @hidden
 */
export var ButtonItem = function (props) {
    var _a;
    var handleClick = React.useCallback(function (event) {
        props.onClick(event, props.index);
    }, [props]);
    var As = props.dataItem.render || props.item || (props.item === undefined ? props.render : null);
    var text = props.dataItem.text !== undefined ? props.dataItem.text : (props.textField ? props.dataItem[props.textField] : props.dataItem);
    var item = (React.createElement("li", { id: props.id, className: classNames('k-item', props.className, { 'k-focus': props.focused }), tabIndex: -1, onClick: handleClick, onMouseDown: props.onDown, onPointerDown: props.onDown, role: "menuitem", "aria-disabled": props.dataItem.disabled || undefined }, (React.createElement("span", { tabIndex: -1, className: classNames('k-link k-menu-link', {
            'k-selected': props.dataItem.selected,
            'k-disabled': props.dataItem.disabled
        }), key: "icon" }, As
        ? (React.createElement(As, { item: props.dataItem, itemIndex: props.index }))
        : (React.createElement(React.Fragment, null,
            (props.dataItem.icon || props.dataItem.iconClass) && (React.createElement("span", { className: classNames('k-icon', (_a = {},
                    _a["k-i-".concat(props.dataItem.icon)] = props.dataItem.icon,
                    _a["".concat(props.dataItem.iconClass)] = props.dataItem.iconClass,
                    _a)) })),
            (props.dataItem.imageUrl) && (React.createElement("img", { role: "presentation", alt: "", src: props.dataItem.imageUrl, className: 'k-icon' })),
            (text) && (React.createElement("span", { className: "k-menu-link-text" }, text))))))));
    return ((props.item !== undefined && /* to be removed in 5.0.0 */
        props.render !== undefined) ? props.render.call(undefined, item, props) : item);
};
ButtonItem.displayName = 'KendoReactButtonItem';
