var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { InsertLinkDialog as LinkDialog } from './insertLink';
import { InsertImageDialog as ImageDialog } from './insertImage';
import { ViewHtmlDialog as ViewHtmlDialogX } from './viewHtml';
import { FindAndReplaceDialog as FindAndReplaceDialogX } from './FindReplace';
/**
 * Represents a wrapping namespace for the dialog components and props of the Editor.
 */
export var EditorDialogs;
(function (EditorDialogs) {
    /**
     * The InsertLinkDialog component.
     */
    var InsertLinkDialog = /** @class */ (function (_super) {
        __extends(InsertLinkDialog, _super);
        function InsertLinkDialog() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return InsertLinkDialog;
    }(LinkDialog));
    EditorDialogs.InsertLinkDialog = InsertLinkDialog;
    /**
     * The InsertImageDialog component.
     */
    var InsertImageDialog = /** @class */ (function (_super) {
        __extends(InsertImageDialog, _super);
        function InsertImageDialog() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return InsertImageDialog;
    }(ImageDialog));
    EditorDialogs.InsertImageDialog = InsertImageDialog;
    /**
     * The ViewHtmlDialog component.
     */
    var ViewHtmlDialog = /** @class */ (function (_super) {
        __extends(ViewHtmlDialog, _super);
        function ViewHtmlDialog() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return ViewHtmlDialog;
    }(ViewHtmlDialogX));
    EditorDialogs.ViewHtmlDialog = ViewHtmlDialog;
    /**
     * The FindAndReplaceDialog component.
     */
    var FindAndReplaceDialog = /** @class */ (function (_super) {
        __extends(FindAndReplaceDialog, _super);
        function FindAndReplaceDialog() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return FindAndReplaceDialog;
    }(FindAndReplaceDialogX));
    EditorDialogs.FindAndReplaceDialog = FindAndReplaceDialog;
})(EditorDialogs || (EditorDialogs = {}));
