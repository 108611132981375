import { TYPE_CODE } from "../process_explorer_constants";

export default class StaticPanelHelper {
  static getRecordsFromProcessExplorerResult(results, processId) {
    let allRecords = [TYPE_CODE.PROCESS + "-" + processId];

    allRecords = allRecords.concat(Object.keys(results.uoMap).map(id => TYPE_CODE.UNIT_OPERATION + "-" + id));
    allRecords = allRecords.concat(Object.keys(results.stpMap).map(id => TYPE_CODE.STEP + "-" + id));
    allRecords = allRecords.concat(Object.keys(results.ppMap).map(id => TYPE_CODE.PROCESS_PARAMETER + "-" + id));
    allRecords = allRecords.concat(Object.keys(results.prcMap).map(id => TYPE_CODE.PROCESS_COMPONENT + "-" + id));
    allRecords = allRecords.concat(Object.keys(results.maMap).map(id => TYPE_CODE.MATERIAL_ATTRIBUTE + "-" + id));
    allRecords = allRecords.concat(Object.keys(results.mtMap).map(id => TYPE_CODE.MATERIAL + "-" + id));
    allRecords = allRecords.concat(Object.keys(results.iqaMap).map(id => TYPE_CODE.IQA + "-" + id));
    allRecords = allRecords.concat(Object.keys(results.ipaMap).map(id => TYPE_CODE.IPA + "-" + id));

    return allRecords;
  }
}