import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
/**
 * @hidden
 */
export var DialogTitleBar = function (_a) {
    var children = _a.children, onCloseButtonClick = _a.onCloseButtonClick, id = _a.id, closeIcon = _a.closeIcon;
    return (React.createElement("div", { className: "k-window-titlebar k-dialog-titlebar", id: id },
        React.createElement("div", { className: "k-window-title k-dialog-title" }, children),
        React.createElement("div", { className: "k-window-actions k-dialog-actions" }, closeIcon &&
            React.createElement(Button, { role: "button", "aria-label": "Close", onClick: onCloseButtonClick, icon: "x", fillMode: "flat", className: "k-window-action k-dialog-action" }))));
};
