"use strict";

import React from "react";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseFinalAttribute from "./base_final_attribute";

// i18next-extract-mark-ns-start qtpp
/**
 * This is the class that renders the Add/Edit/View page for FPAs.
 */
class FPA extends BaseFinalAttribute {
  constructor(props) {
    const {t} = props;
    super(props, "fpa", "FPA", t("Final Performance Attribute (FPA)"));
  }

  componentDidMount() {
    document.title = "QbDVision Final Performance Attribute (FPA)";

    return super.componentDidMount();
  }

  getInfoTooltip() {
    const {t} = this.props;

    return (
      <InfoTooltip id="infoFPA"
                   verbiage={<div>
                     {t("A property or measure of the final product that is not considered a quality attribute but is indicative of the overall performance of the process relative to the batch or release. Examples include yield, total cell density (TCD), viable cell density (VCD), viability, or productivity.")}
                   </div>}
      />
    );
  }
}

export default I18NWrapper.wrap(FPA, "qtpp");
// i18next-extract-mark-ns-stop qtpp
