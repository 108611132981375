/* stylelint-disable-next-line import-notation */
@import "../../../../../../../css/brand_colors";

.clear,
.filter-entry {
  display: inline-flex;
  gap: 2px;
  background: $brand-light-grey;
  height: 30px;
  align-items: center;
  border-radius: 25px;
  padding: 5px 8px 5px 12px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;

  &:has(:global(a)) {
    padding-right: 12px;
  }

  :global(a),
  .show-more,
  :global(span) {
    color: $brand-dark-grey;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    height: 100%;
    line-height: 20px;
    user-select: none;
  }

  &:has(.show-more) {
    padding-right: 10px;
    box-sizing: border-box;
    height: auto;

    &:hover {
      cursor: pointer;
      border: 1px solid $brand-primary-color;
    }
  }

  &.menu-open {
    border: 1px solid $brand-primary-color;
  }

  :global(a) {
    color: $brand-primary-color;
  }

  .field {
    font-weight: 400;
  }

  .separator {
    margin: 0 2px;
    font-weight: 600;
  }

  .value {
    font-weight: 600;
  }

  .close-btn {
    display: inline-flex;
    width: 12px;
    height: 12px;
    align-items: stretch;
    color: #859399;
    font-size: 10px;
    font-style: normal;
    cursor: pointer;
  }
}

.menu {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 14px;
  margin: 0;
  position: absolute;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: white;
  border-radius: 5px;
  border: 1px solid #1f46a1;
  min-width: 225px;
}

.menu-item {
  display: block;
  outline: none;
}