"use strict";

import * as UIUtils from "../../../ui_utils";
import moment from "moment-timezone";
import { BaseDataTransform } from "../base_data_transform";
import { getEffectiveRMPByModelName, getEffectiveRMPForDate } from "../../../helpers/risk_helper";
import {
  fillInDependenciesInfo,
  fillInRequirementReportVersionData,
  getApprovedProposedVersions, getVersionsForModel
} from "../../canned_reports/canned_report_helper";
import * as CommonEditablesFormatter from "../../../../server/common/editables/common_editables_formatter";

export class RequirementElementDataTransform extends BaseDataTransform {

  get type() {
    return "requirementElement";
  }

  shouldRun() {
    return true;
  }

  transform(input, options) {
    const {
      rmpVersions,
      projectWithAllVersions,
      typeaheadOptions,
      modelType,
      dependenciesTypeaheadOptions,
      reportType,
    } = options;

    const result = input;
    const data = result.instances.requirement;
    data.fullElementName = UIUtils.secureString(data.fullElementName);

    if (result.process) {
      data.process = result.process;
    }

    if (data.lastVersion) {
      let effectiveRMP = getEffectiveRMPForDate(projectWithAllVersions, rmpVersions, moment(), modelType); // For the RequirementElementReport we need to take into account the latest RMP approved version only
      fillInRequirementReportVersionData(data, data.lastVersion, effectiveRMP, typeaheadOptions.concat(dependenciesTypeaheadOptions), UIUtils.getTypeCodeForModelName(modelType));
      data.lastVersion.name = UIUtils.secureString(data.lastVersion.name);
      data.lastVersion.controlStrategy = UIUtils.secureString(
        CommonEditablesFormatter.formatControlStrategyForDisplay(data.lastVersion.controlStrategy)
      );
    }

    if (data.approvedVersionsWithDetails) {
      data.approvedVersionsWithDetails.forEach(version => {
        let effectiveRMP = getEffectiveRMPByModelName(options.projectWithAllVersions, rmpVersions, version, modelType);
        fillInRequirementReportVersionData(data, version, effectiveRMP, typeaheadOptions.concat(dependenciesTypeaheadOptions), modelType);
        version.controlStrategy = UIUtils.secureString(
          CommonEditablesFormatter.formatControlStrategyForDisplay(version.controlStrategy)
        );
      });
    }

    fillInDependenciesInfo(data, dependenciesTypeaheadOptions, reportType, modelType);
    data.approvedVersions = getApprovedProposedVersions(getVersionsForModel(data, modelType), modelType);
    options.showDraftAlerts = data.approvedVersions.length === 0;

    data.detailedRiskLinks = data.detailedRiskLinks || "None";
    data.controlStrategy = UIUtils.secureString(
      CommonEditablesFormatter.formatControlStrategyForDisplay(data.controlStrategy)
    );
    result.instances = data;
    return result;
  }
}
