var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Popup } from '@progress/kendo-react-popup';
import { classNames } from '@progress/kendo-react-common';
/**
 * @hidden
 */
var ANCHOR_VERTICAL_ALIGN = 'bottom';
/**
 * @hidden
 */
var POPUP_VERTICAL_ALIGN = 'top';
/**
 * @hidden
 */
export var Picker = function (props) {
    var popupSettings = props.popupSettings || {};
    var horizontalAlign = props.dir === 'rtl' ? 'right' : 'left';
    var anchorAlign = React.useMemo(function () { return ({ horizontal: horizontalAlign, vertical: ANCHOR_VERTICAL_ALIGN }); }, [horizontalAlign]);
    var popupAlign = React.useMemo(function () { return ({ horizontal: horizontalAlign, vertical: POPUP_VERTICAL_ALIGN }); }, [horizontalAlign]);
    return (React.createElement(React.Fragment, null,
        props.input,
        props.button,
        React.createElement(Popup, __assign({ style: __assign(__assign({}, (popupSettings || {}).style), { direction: props.dir }) }, popupSettings, { anchor: props.popupAnchor, anchorAlign: anchorAlign, popupAlign: popupAlign, show: props.open, onOpen: props.onOpen, onClose: props.onClose, className: classNames('k-reset', popupSettings.className) }), props.content)));
};
Picker.displayName = 'KendoPickerComponent';
