"use strict";

import React from "react";
import { useWizardContext } from "../../../hooks/use_wizard_context_hook";

/**
 * The wizard navigation bar
 * @param currentFlowStep
 * @param wizardStepsConfig
 * @returns {JSX.Element}
 * @constructor
 */
export function ProjectMaterialWizardNavBar({currentFlowStep, wizardStepsConfig}) {

  let index = 1;
  const context = useWizardContext();
  const {
    WizardHelper
  } = context;
  const wizardHelper = new WizardHelper(context);

  return (
    <div className="row import-nav-tabs">
      {
        wizardStepsConfig.map(config => {
          let caption = config.step;
          return (
            <div key={config.navTabId}
                 role="presentation"
                 className={"col-auto import-nav-tab align-items-center" + (currentFlowStep === config.step ? " active" : "")}
            >
              <a onClick={() => {
                if (!config.disabled) {
                  wizardHelper.onNavTabClick(config, wizardStepsConfig);
                }
              }}
                 id={config.navTabId}
                 className={config.disabled ? "import-nav-inactive" : ""}
              >
                {`${index++}- ${caption}`}
              </a>
            </div>);
        })
      }
    </div>
  );
}