import * as React from "react";
import {Button} from "@progress/kendo-react-buttons";
import {EditorUtils, EditorToolsSettings} from "@progress/kendo-react-editor";
import * as DocumentTransferHelper from "../../helpers/document_transfer_helper";
import {FILE_STATUS} from "../../helpers/document_transfer_helper";
import * as UIUtils from "../../ui_utils";
import {ChangeEvent, useRef} from "react";
import {ToolProps} from "../common/types";
import useForceUpdate from "../hooks/use_force_update";

const IMAGE_SETTINGS = EditorToolsSettings.image;

/**
 * The editor tool to insert an image
 */
export default function InsertImage(props: ToolProps) {
  const {view} = props;
  const inputRef = useRef<HTMLInputElement>();
  const forceUpdate = useForceUpdate();

  const getInitialValue = () => {
    return {
      linkType: "",
      S3TmpKey: "",
      S3TmpVersion: "",
      xhr: null,
      link: "",
      linkVersion: "",
      fileName: "",
      progress: 0,
      fileStatus: FILE_STATUS.NOT_SPECIFIED,
      size: "",
    };
  };

  const handleUpdateFileData = (
    fileData: Record<string, any>,
    files: Array<File>,
  ) => {
    if (fileData.fileStatus === FILE_STATUS.UPLOADED) {
      onInsert(files, fileData);
      UIUtils.hideLoadingImage();
    }
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = [...Array.from(event.target.files)];
    const fileData = getInitialValue();
    UIUtils.showLoadingImage();
    DocumentTransferHelper.handleUpload(
      event,
      fileData,
      null,
      UIUtils.clearError,
      UIUtils.showError,
      forceUpdate,
      (fileData: Record<string, any>) => handleUpdateFileData(fileData, files),
    );
  };

  const onInsert = (files: Array<File>, fileData: Record<string, any>) => {
    const {view} = props;

    if (!files && !files.length) {
      return;
    }

    const nodes = view.state.schema.nodes;
    const nodeType = nodes[IMAGE_SETTINGS.node];
    const file = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64String = (reader.result as string)
        .replace("data:", "")
        .replace(/^.+,/, "");

      // We will use this attribute to parse the fileData and download the image
      const data = {
        src: `data:${file.type};base64, ${base64String}`,
        filedata: JSON.stringify(fileData),
      };
      const newImage = nodeType.createAndFill(data);
      EditorUtils.insertNode(view, newImage, true);
      view.focus();
    };
  };

  const state = view && view.state;
  const nodeType = state ? state.schema.nodes[IMAGE_SETTINGS.node] : null;

  return (
    <React.Fragment>
      <input
        id="insertImageUpload"
        ref={inputRef}
        type="file"
        accept=".jpg,.jpeg,.png"
        style={{display: "none"}}
        multiple={false}
        onChange={onFileChange}
      />
      <Button
        id="insertImageTool"
        onClick={() => {
          if (inputRef) {
            inputRef?.current?.click();
          }
        }}
        disabled={
          !nodeType || !state || !EditorUtils.canInsert(state, nodeType)
        }
        onMouseDown={(e) => e.preventDefault()}
        onPointerDown={(e) => e.preventDefault()}
        title="Insert Image"
        {...IMAGE_SETTINGS.props}
      />
    </React.Fragment>
  );
}
