var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, noop } from '@progress/kendo-react-common';
import { CSSTransition } from 'react-transition-group';
import util from './util';
var styles = util.styles;
var AnimationChild = /** @class */ (function (_super) {
    __extends(AnimationChild, _super);
    function AnimationChild() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.elementRef = React.createRef();
        return _this;
    }
    Object.defineProperty(AnimationChild.prototype, "element", {
        /**
         * The element that is being animated.
         */
        get: function () {
            return this.elementRef.current;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    AnimationChild.prototype.render = function () {
        var _this = this;
        var _a = this.props, children = _a.children, style = _a.style, appear = _a.appear, enter = _a.enter, exit = _a.exit, transitionName = _a.transitionName, transitionEnterDuration = _a.transitionEnterDuration, transitionExitDuration = _a.transitionExitDuration, className = _a.className, onEnter = _a.onEnter, onEntering = _a.onEntering, onEntered = _a.onEntered, onExit = _a.onExit, onExiting = _a.onExiting, onExited = _a.onExited, onAfterExited = _a.onAfterExited, mountOnEnter = _a.mountOnEnter, unmountOnExit = _a.unmountOnExit, animationEnteringStyle = _a.animationEnteringStyle, animationEnteredStyle = _a.animationEnteredStyle, animationExitingStyle = _a.animationExitingStyle, animationExitedStyle = _a.animationExitedStyle, other = __rest(_a, ["children", "style", "appear", "enter", "exit", "transitionName", "transitionEnterDuration", "transitionExitDuration", "className", "onEnter", "onEntering", "onEntered", "onExit", "onExiting", "onExited", "onAfterExited", "mountOnEnter", "unmountOnExit", "animationEnteringStyle", "animationEnteredStyle", "animationExitingStyle", "animationExitedStyle"]);
        var childAnimationContainerClassNames = classNames(className, styles['child-animation-container']);
        var defaultStyle = __assign({ transitionDelay: '0ms' }, style);
        var animationStyle = {
            entering: __assign({ transitionDuration: "".concat(transitionEnterDuration, "ms") }, animationEnteringStyle),
            entered: __assign({}, animationEnteredStyle),
            exiting: __assign({ transitionDuration: "".concat(transitionExitDuration, "ms") }, animationExitingStyle),
            exited: __assign({}, animationExitedStyle)
        };
        var transitionProps = {
            in: this.props.in,
            appear: appear,
            enter: enter,
            exit: exit,
            mountOnEnter: mountOnEnter,
            unmountOnExit: unmountOnExit,
            timeout: {
                enter: transitionEnterDuration,
                exit: transitionExitDuration
            },
            onEnter: (function () {
                if (onEnter) {
                    onEnter.call(undefined, { animatedElement: _this.element, target: _this });
                }
            }),
            onEntering: (function () {
                if (onEntering) {
                    onEntering.call(undefined, { animatedElement: _this.element, target: _this });
                }
            }),
            onEntered: (function () {
                if (onEntered) {
                    onEntered.call(undefined, { animatedElement: _this.element, target: _this });
                }
            }),
            onExit: (function () {
                if (onExit) {
                    onExit.call(undefined, { animatedElement: _this.element, target: _this });
                }
            }),
            onExiting: (function () {
                if (onExiting) {
                    onExiting.call(undefined, { animatedElement: _this.element, target: _this });
                }
            }),
            onExited: (function () {
                if (onAfterExited) {
                    onAfterExited.call(undefined, { animatedElement: _this.element, target: _this });
                }
                if (onExited) {
                    onExited.call(undefined, { animatedElement: _this.element, target: _this });
                }
            }),
            classNames: {
                appear: styles["".concat(transitionName, "-appear")] || "".concat(transitionName, "-appear"),
                appearActive: styles["".concat(transitionName, "-appear-active")] || "".concat(transitionName, "-appear-active"),
                enter: styles["".concat(transitionName, "-enter")] || "".concat(transitionName, "-enter"),
                enterActive: styles["".concat(transitionName, "-enter-active")] || "".concat(transitionName, "-enter-active"),
                exit: styles["".concat(transitionName, "-exit")] || "".concat(transitionName, "-exit"),
                exitActive: styles["".concat(transitionName, "-exit-active")] || "".concat(transitionName, "-exit-active")
            }
        };
        return (React.createElement(CSSTransition, __assign({}, transitionProps, other, { nodeRef: this.elementRef }), function (status) {
            return (React.createElement("div", { style: __assign(__assign({}, defaultStyle), animationStyle[status]), className: childAnimationContainerClassNames, ref: _this.elementRef }, children));
        }));
    };
    /**
     * @hidden
     */
    AnimationChild.propTypes = {
        in: PropTypes.bool,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ]),
        transitionName: PropTypes.string.isRequired,
        className: PropTypes.string,
        appear: PropTypes.bool,
        enter: PropTypes.bool,
        exit: PropTypes.bool,
        transitionEnterDuration: PropTypes.number.isRequired,
        transitionExitDuration: PropTypes.number.isRequired,
        mountOnEnter: PropTypes.bool,
        unmountOnExit: PropTypes.bool,
        animationEnteringStyle: PropTypes.object,
        animationEnteredStyle: PropTypes.object,
        animationExitingStyle: PropTypes.object,
        animationExitedStyle: PropTypes.object
    };
    /**
     * @hidden
     */
    AnimationChild.defaultProps = {
        mountOnEnter: true,
        unmountOnExit: false,
        onEnter: noop,
        onEntering: noop,
        onEntered: noop,
        onExit: noop,
        onExiting: noop,
        onExited: noop,
        onAfterExited: noop,
        animationEnteringStyle: {},
        animationEnteredStyle: {},
        animationExitingStyle: {},
        animationExitedStyle: {}
    };
    return AnimationChild;
}(React.Component));
export { AnimationChild };
