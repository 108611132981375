"use strict";

import { MODEL_TYPES } from "../product_constants";
import React, { memo, useEffect, useState } from "react";
import * as UIUtils from "../../ui_utils";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import * as styles from "../../processExplorer/widgets/topBar/titleBar/styles/title_area.module.scss";
import { CustomDropdownButton } from "../../configurableTables/widgets/dropDown/dropdown_button";

const modelTypeOptions = MODEL_TYPES.map(type => {
  return {
    disabled: false,
    key: UIUtils.convertToId(type),
    title: type,
    value: type
  };
});

const TableViewTitleArea = ({onModelTypeChange}) => {

  const [
    selectedModelType,
    setSelectedModelType
  ] = useState(UIUtils.getParameterByName("selectedModelType") || "Quality Attributes");

  const onModelChange = ({target}) => {
    const {textContent} = target;
    setSelectedModelType(textContent);
    if (selectedModelType !== textContent) {
      onModelTypeChange(textContent);
    }
  };

  useEffect(() => {
    onModelTypeChange(selectedModelType);
  }, []);

  return (
    <CustomDropdownButton
      id="productModelTypeSelect"
      options={modelTypeOptions}
      label={selectedModelType}
      className={styles["drop-down-container"]}
      onOptionsSelect={onModelChange}
      size="small"
      title="Product Selection"
      type="secondary"
      icon={faChevronDown}
    />
  );
};

export default memo(TableViewTitleArea);