"use strict";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import React, { memo } from "react";
import * as UIUtils from "../../../../../../../ui_utils";
import * as styles from "./filter.module.scss";

const Filter = ({field, value, fieldClass, operationsHandler}) => {
  return (
    <div className={styles["filter-entry"]} key={fieldClass}>
      <span className={styles["field"]}>
        {UIUtils.convertCamelCaseToSpacedOutWords(field)}
      </span>
      <span className={styles["separator"]}>·</span>
      <span className={styles["value"]}>{value}</span>
      <FontAwesomeIcon className={styles["close-btn"]}
                       size="sm"
                       icon={faTimes}
                       onClick={() => operationsHandler.handleColumnFilterCancelClick(field)}
      />
    </div>
  );
};

export default memo(Filter);