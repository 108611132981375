"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import CannedReport from "./canned_report";
import * as CannedReportHelper from "./canned_report_helper";

/* This class shows Requirement Summary canned report */
export default class RequirementSummaryCannedReport extends CannedReport {
  constructor(props) {
    super(props);

    UIUtils.setHideLoadingOnAjaxStop(false);
    UIUtils.showLoadingImage("Retrieving Data");
  }

  renderFilters() {
    if (this.results) {
      return <Fragment>
        <div className="col-sm-3">
          {this.renderTypeFilter()}
        </div>
        <div className="col-sm-3">
          {this.renderCriticalityFilter()}
        </div>
      </Fragment>;
    }
  }

  renderTypeFilter() {
    let modelTypes = ["All", "FQAs", "FPAs", "IQAs", "IPAs", "MAs", "PPs"];
    let types = modelTypes.map(modelType => (
      <div key={modelType} className="data-report-filter-option">
        <label className="col-form-label">
          <input
            id={`${modelType.toLowerCase()}TypeFilterCheckbox`}
            type="checkbox"
            checked={this.state.filterOptions.type.includes(modelType.toLowerCase())}
            onChange={this.handleTypeFilterChange.bind(this, modelType)}
          />
          {modelType}
        </label>
      </div>
    ));
    return (
      <div id="typeFilter">
        <label className="base-attribute data-report-filter-title">
          Type:
        </label>
        <div className="data-report-filter-container">
          {types}
        </div>
      </div>
    );
  }

  handleTypeFilterChange(modelType, event) {
    UIUtils.setHideLoadingOnAjaxStop(false);
    UIUtils.showLoadingImage("Processing Data");

    let filterOptions = this.state.filterOptions;
    modelType = modelType.toLowerCase();

    if (event.target.checked) {
      if (modelType === "all") {
        filterOptions.type = ["all", "fqas", "fpas", "iqas", "ipas", "mas", "pps"];
      } else {
        filterOptions.type.push(modelType);
        if (filterOptions.type.length === 4 && !filterOptions.type.includes("all")) {
          filterOptions.type.unshift("all");
        }
      }
    } else {
      if (modelType === "all") {
        filterOptions.type = [];
      } else {
        filterOptions.type.splice(filterOptions.type.indexOf(modelType), 1);
        if (filterOptions.type.includes("all")) {
          filterOptions.type.splice(filterOptions.type.indexOf("all"), 1);
        }
      }
    }

    let riskLabels = CannedReportHelper.getRiskLabelsForRequirementsSummaryReport(this.state.projectWithAllVersions, this.state.rmpVersions, this.state.reportDate);
    filterOptions.criticality = filterOptions.criticality.filter(label => riskLabels.includes(label));

    const reportResults = CannedReportHelper.jsonPreProcessing(this.results, this.props.reportType,
      this.props.reportOptions, this.state.projectWithAllVersions, this.state.rmpVersions, "Requirement", this.props.typeaheadOptions,
      this.props.dependencyTypeahead, this.state.reportDate, filterOptions);

    // Uncomment for verbose logging
    // console.log("Post Processing Data: ", JSON.stringify(this.state.reportResults));

    this.setStateSafely({
      reportResults,
      filterOptions
    });
  }

  renderCriticalityFilter() {
    let riskLabels = CannedReportHelper.getRiskLabelsForRequirementsSummaryReport(this.state.projectWithAllVersions, this.state.rmpVersions, this.state.reportDate);
    let labels = riskLabels.map((label, index) => (
      <div key={index} className="data-report-filter-option">
        <label className="col-form-label">
          <input
            id={`${UIUtils.stripAllWhitespaces(label.toLowerCase())}CriticalityFilterCheckbox`}
            type="checkbox"
            checked={this.state.filterOptions.criticality.includes(label)}
            onChange={this.handleCriticalityFilterChange.bind(this, label)}
          />
          {label}
        </label>
      </div>
    ));
    return (
      <div id="criticalityFilter">
        <label className="base-attribute data-report-filter-title">
          Criticality:
        </label>
        <div className="data-report-criticality-filter-container">
          {labels}
        </div>
      </div>
    );
  }

  handleCriticalityFilterChange(riskLabel, event) {
    UIUtils.setHideLoadingOnAjaxStop(false);
    UIUtils.showLoadingImage("Processing Data");

    let filterOptions = this.state.filterOptions;
    if (event.target.checked) {
      if (riskLabel === "All") {
        filterOptions.criticality = this.allRiskLabels;
      } else {
        filterOptions.criticality.push(riskLabel);
        if (riskLabel === "None") {
          filterOptions.criticality.push("Not Assessed");
        }
        if (filterOptions.criticality.length === this.allRiskLabels.length &&
          !filterOptions.criticality.includes("All")) {
          filterOptions.criticality.unshift("All");
        }
      }
    } else {
      if (riskLabel === "All") {
        filterOptions.criticality = [];
      } else {
        filterOptions.criticality = filterOptions.criticality.filter(option => option !== riskLabel);
        if (riskLabel === "None") {
          filterOptions.criticality.splice(filterOptions.criticality.indexOf("Not Assessed"), 1);
        }
        if (filterOptions.criticality.includes("All")) {
          filterOptions.criticality.splice(filterOptions.criticality.indexOf("All"), 1);
        }
      }
    }

    const reportResults = CannedReportHelper.jsonPreProcessing(this.results, this.props.reportType,
      this.props.reportOptions, this.state.projectWithAllVersions, this.state.rmpVersions, "Requirement", this.props.typeaheadOptions,
      this.props.dependencyTypeahead, this.state.reportDate, filterOptions);

    this.setStateSafely({
      reportResults,
      filterOptions
    });
  }
}
