"use strict";

import React, { memo, useEffect, useRef, useState } from "react";
import { Pill } from "./widgets/pill";
import { PillContainer } from "./widgets/pill_container";
import { PillRow } from "./widgets/pill_row";
import { buildPillRows } from "./cells_helper";

export const PillsCell = memo(({pills, className, overlayCssClass, renderOverlayContent, cache}) => {

  const containerRef = useRef();
  const [pillRows, setPillRows] = useState([]);

  useEffect(() => {
    if (containerRef.current && pills.length) {
      const rows = buildPillRows(containerRef.current, pills, cache);
      setPillRows(rows);
    }
  }, [pills]);

  return (
    <td className={className}>
      <PillContainer forwardedRef={containerRef}>
        {pillRows && pillRows.map(({row}, idx) => (
          <PillRow key={idx}>
            {row.map((pill, index) =>
              <Pill key={index} {...pill} renderOverlayContent={renderOverlayContent} overlayCssClass={overlayCssClass} />)}
          </PillRow>
        ))}
      </PillContainer>
    </td>
  );
});
