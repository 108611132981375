"use strict";

import { BaseDataTransform } from "../base_data_transform";
import {
  isCritical,
  getEffectiveRMPForDate,
} from "../../../helpers/risk_helper";
import { RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import moment from "moment-timezone";
import { orderAndIndexUnits } from "../../../processExplorer/indexers/uo_indexer";
import * as UIUtils from "../../../ui_utils";

const INTERMEDIATE_MODEL_NAMES = [
  "iqa",
  "ipa"
];

const FINAL_MODEL_NAMES = [
  "fqa",
  "fpa"
];

export class InfluenceMapDataTransform extends BaseDataTransform {

  // eslint-disable-next-line no-unused-vars
  shouldRun(options) {
    return true;
  }

  getCriticalRecords({reportData, rmpVersions, projectWithAllVersions}, modeTypes) {
    const records = [];
    for (const modelType of modeTypes) {
      const effectiveRMP = getEffectiveRMPForDate(projectWithAllVersions, rmpVersions, moment(), modelType);
      for (const [key, value] of Object.entries(reportData[`${modelType}Map`])) {
        if (isCritical(value, effectiveRMP, modelType)) {
          records.push(value);
        }
      }
    }
    return records;
  }

  getInfluenceRisks(options, riskLinkType) {

    const {
      orderedUnitOperations,
      intermediateCriticalRecords,
      finalCriticalRecords
    } = options;

    const records = [];

    for (let record of intermediateCriticalRecords) {
      for (let riskLink of record[`${record.typeCode}To${riskLinkType}s`]) {
        if (riskLink.effect) {
          const finalRecord = finalCriticalRecords
            .filter(record => record.modelName === riskLinkType)
            .find((record) => record.id === riskLink[`${riskLinkType}Id`]);

          if (finalRecord) {
            if (!finalRecord.riskInfo) {
              throw new Error("Missing RiskInfo");
            }

            const riskScale = finalRecord.riskInfo[RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel;

            finalRecord.category = Array.isArray(finalRecord.category) ?
              finalRecord.category :
              finalRecord.category.startsWith("[") ?
                JSON.parse(finalRecord.category) : [finalRecord.category];

            const unitOperation = orderedUnitOperations
              .find(uo => uo.id === record.UnitOperation.id);

            const cellData = {
              "unitOperation": record.UnitOperation.name,
              "unitOperationIndex": UIUtils.parseInt(unitOperation.index),
              "finalRiskName": finalRecord.name,
              "riskLabel": riskScale.riskLabel,
              "effectOnRisk": [riskLink.effect],
              "modelType": riskLinkType,
              "category": finalRecord.category.join(", "),
            };

            if (records.length) {
              let record = records.find((record) =>
                (record.unitOperation === cellData.unitOperation) &&
                (record.finalRiskName === cellData.finalRiskName)
              );
              if (record) {
                if (!record.effectOnRisk.includes(riskLink.effect)) {
                  record.effectOnRisk.push(riskLink.effect);
                }
              } else {
                records.push(cellData);
              }
            } else {
              records.push(cellData);
            }
          }
        }
      }
    }
    records.forEach((record) => {
      record.effectOnRisk = record.effectOnRisk.join("\n");
    });
    return records;
  }

  transform(input, options) {
    const {rmpVersions, projectWithAllVersions} = options;
    const reportData = input.instances.reportData;
    const unitOperations = Object.values(input.instances.reportData.uoMap);
    const orderedUnitOperations = orderAndIndexUnits(unitOperations);

    const criticalRecordsOptions = {reportData, rmpVersions, projectWithAllVersions};
    const intermediateCriticalRecords = this.getCriticalRecords(criticalRecordsOptions, INTERMEDIATE_MODEL_NAMES);
    const finalCriticalRecords = this.getCriticalRecords(criticalRecordsOptions, FINAL_MODEL_NAMES);

    const influenceRisksOptions = {
      orderedUnitOperations,
      rmpVersions,
      projectWithAllVersions,
      intermediateCriticalRecords,
      finalCriticalRecords
    };

    const fqaRisks = this.getInfluenceRisks(influenceRisksOptions, "FQA");
    const fpaRisks = this.getInfluenceRisks(influenceRisksOptions, "FPA");

    input.instances.instances = fqaRisks.concat(fpaRisks);

    return input;
  }

  getSupportedRecordTypes() {
    return ["fqas", "iqas", "fpas", "ipas"];
  }
}
