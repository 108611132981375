"use strict";

import * as UIUtils from "../../ui_utils";
import { can } from "../../utils/ui_permissions";
import CommonSecurity from "../../../server/common/generic/common_security";

/**
 * This class handles all conditions needed by the user.jsx
 */
export default class UserConditionChecker {

  constructor(props) {
    this._isEdit = props.isEdit;
    this._isView = props.isView;
    this._loggedInUser = props.loggedInUser;
    this._companyId = props.companyId;
    this._id = props.id;
  }

  get isEdit() {
    return this._isEdit || false;
  }

  get isView() {
    return this._isView || false;
  }

  get loggedInUser() {
    return this._loggedInUser;
  }

  get companyId() {
    return this._companyId;
  }

  get id() {
    return this._id;
  }

  isAdminUserInAddMode() {
    const {loggedInUser, isAdd} = this;
    return loggedInUser && loggedInUser.isAdmin && isAdd;
  }

  isInternalAdminUserInEditMode() {
    const {loggedInUser, isEdit, isView} = this;
    return loggedInUser && loggedInUser.isAdmin && (isEdit || isView) &&
      loggedInUser.companyLicense === CommonSecurity.CompanyLicenseTypes.INTERNAL;
  }

  isInternalUserEditingOtherCompaniesUsers() {
    const {loggedInUser, companyId} = this;
    let loggedInUserCompanyId = loggedInUser && loggedInUser.companyId;
    return this.isInternalAdminUserInEditMode(loggedInUser) && companyId !== loggedInUserCompanyId;
  }

  isRoleDropdownEnabled() {
    return can(CommonSecurity.Actions.ADD, CommonSecurity.Types.USER);
  }

  isProjectsTypeaheadEnabled() {
    return can(CommonSecurity.Actions.ADD, CommonSecurity.Types.USER);
  }

  isSupervisorEnabled() {
    return can(CommonSecurity.Actions.ADD, CommonSecurity.Types.USER) || this.isEditingDifferentUser();
  }

  isTrainingCoordinatorEnabled() {
    return can(CommonSecurity.Actions.ADD, CommonSecurity.Types.USER);
  }

  isExternalProviderFieldEnabled() {
    return can(CommonSecurity.Actions.ADD, CommonSecurity.Types.USER) && this.isEditingDifferentUser();
  }

  isEnabledFieldEnabled() {
    return can(CommonSecurity.Actions.ADD, CommonSecurity.Types.USER) && this.isEditingDifferentUser();
  }

  isMfaResetEnabled() {
    return can(CommonSecurity.Actions.ADD, CommonSecurity.Types.USER);
  }

  isEditingDifferentUser() {
    return this.id !== UIUtils.getUserId();
  }

  isDepartmentFieldEnabled() {
    return can(CommonSecurity.Actions.ADD, CommonSecurity.Types.USER) && this.isEditingDifferentUser();
  }
}
