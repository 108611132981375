"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import BasePage from "../base_page";
import PageFrameLayout from "../widgets/layout/page_frame_layout";
import { FilteredList } from "../widgets/tables/filtered_list";
import { EDITOR_TYPES } from "../editor/editor_constants";
import { QUICK_PANEL_BUTTONS } from "../widgets/quickPanel/quick_panel_buttons";
import MarkAsTrainedPopup from "./popups/mark_as_trained_popup";
import { TrainingService } from "./services/training_service";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import { USER_TABS, USER_TABS_ENUM } from "../helpers/constants/constants";
import { SortingFunctions } from "../utils/filter_helper";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";
import ApprovalResponsePopup from "../editor/approval/approval_response_popup";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";
import MyTrainingListTable from "./tables/my_training_list_table";
import * as CommonTraining from "../../server/common/editables/common_training";
import { COLUMN_FORMATTERS } from "../widgets/tables/column_formatters";

const Logger = Log.group(LOG_GROUP.Training, "MyTraining");

// i18next-extract-mark-ns-start training
class MyTraining extends BasePage {
  constructor(props) {
    super(props);

    const {t} = props;

    /**
     * @type {ITableFilter[]}
     */
    this.filters = [
      {
        key: "ToTrain",
        name: t("To train"),
        filter: (item) => (
          item.completionStatus === CommonTraining.TRAINING_STATUS.PENDING
          || item.completionStatus === CommonTraining.TRAINING_STATUS.OVERDUE
        ),
        order: -2,
        selected: (ignored) => true,
      },
      {
        key: "Trained",
        name: t("Trained on"),
        filter: (item) => (
          item.completionStatus === CommonTraining.TRAINING_STATUS.TRAINED_OUTSIDE
          || item.completionStatus === CommonTraining.TRAINING_STATUS.COMPLETED
        ),
        order: -1,
        goToVersion: true,
      },
      {
        key: t("All"),
        filter: (item) => !!item.isInTrainingPlan,
        selected: (items) => items.length === 0,
        goToVersion: true,
      }
    ];

    this.trainingService = new TrainingService();

    new TypeaheadObjectCache("User").loadOptions(this.handleUserLoad);

    const users = new TypeaheadObjectCache("User").getOptionsFromCacheIncludingArchived();
    const id = UIUtils.getParameterByName("id");
    this.setStateSafely({
      users,
      currentUser: this.findUserToDisplay(users),
      downloadedDocumentVersions: this.trainingService.getDownloadedDocumentVersions(),
      id,
    });
  }

  componentDidMount() {
    document.title = "QbDVision Training";

    super.componentDidMount();
  }

  handleUserLoad(users) {
    const {t} = this.props;
    let currentUser = null;

    if (users) {
      currentUser = this.findUserToDisplay(users);
      const stateChange = {users, currentUser};

      if (this._isMounted) {
        this.setStateSafely(stateChange);
      } else {
        this.state = {
          ...this.state,
          ...stateChange,
        };
      }
    }

    this.setStateSafely({
      breadcrumb: {
        current: this.state.currentUser.fullName,
        parent: t("Users"),
        parentLink: "/users/list.html",
      }
    });
  }

  handleQuickPanelActionClick(action, state, callback) {
    // uncomment for verbose logging
    // Logger.debug(">> Action clicked: ", action);

    switch (action?.id) {
      case QUICK_PANEL_BUTTONS.TRAINING_SIGN_OFF.id:
        UIUtils.clearError();
        this.setStateSafely({
          isApprovalPopupOpen: true,
          isPopupOpen: true,
          selectedItem: state,
          success: null,
          message: null,
          initializeQuickPanel: callback,
        });
        break;
      case QUICK_PANEL_BUTTONS.DOWNLOAD.id:
        UIUtils.clearError();
        callback(true);
        break;
      default:
        Logger.warn("Action not implemented: ", action.id);
        break;
    }
    return false;
  }

  handleQuickPanelInstanceReceived(document, data, rawData) {
    const {trainingRecords} = rawData;

    if (!document.trainingRecords) {
      document.trainingRecords = new Map();
    }

    const trainingRecordsForDocument = trainingRecords.filter(item => item.documentId === document.id);

    for (let trainingRecord of trainingRecordsForDocument) {
      const documentRecord = {...trainingRecord};

      const key = `${documentRecord.documentVersionId}-${documentRecord.retrainingCount}`;
      if (!document.trainingRecords.has(key)) {
        let curricula = (documentRecord.curriculumNames || "").split(", ").filter(item => item.trim().length > 0);
        let departmentCurricula = (documentRecord.departmentCurriculumNames || "").split(", ").filter(item => item.trim().length > 0);
        let curriculumNames = "";

        const curriculaSet = new Set([...curricula, ...departmentCurricula]);
        const uniqueCurricula = [...curriculaSet.values()];

        Logger.verbose(() => "Unique Curricula", uniqueCurricula);

        uniqueCurricula.sort(SortingFunctions.stringAscending(cur => cur));
        curriculumNames = uniqueCurricula.join(", ");

        documentRecord.completionStatus = CommonTraining.getCompletionStatus(documentRecord, trainingRecords, {completedAsDate: false});
        documentRecord.DocumentId = documentRecord.documentId;
        documentRecord.typeCode = "DOC";
        documentRecord.deletedAt = null;
        documentRecord.baseRecordId = documentRecord.documentId;
        documentRecord.versionId = documentRecord.documentVersionId;
        documentRecord.LastVersionId = documentRecord.documentVersionId;
        documentRecord.id = documentRecord.documentVersionId;
        documentRecord.trainingStatus = CommonTraining.getAssignmentStatusForTrainingRecord(documentRecord, trainingRecords);
        documentRecord.isInTrainingPlan = true;
        documentRecord.curriculumNames = curriculumNames;
        documentRecord.isApprovedVersion = true;
        documentRecord.customID = documentRecord.customId;
        documentRecord.assignmentDate = documentRecord.assignmentDate || "";
        documentRecord.completionDate = (
          documentRecord.completionStatus === CommonTraining.TRAINING_STATUS.COMPLETED
          && documentRecord.completionDate ? documentRecord.completionDate : ""
        );

        if (documentRecord.name.includes("CAPA") || documentRecord.name.includes("Corporate")) {
          Logger.local(Log.object(documentRecord.name), Log.object(documentRecord.trainingStatus), Log.object(documentRecord.completionStatus), Log.object(documentRecord.versionId), Log.object(documentRecord));
        }

        document.trainingRecords.set(key, documentRecord);
      }
    }
    return document;
  }

  handleDataReceived(results, args) {
    const instances = new Map();
    for (let instance of results.instances) {
      for (let [key, item] of instance.trainingRecords.entries()) {
        if (!instances.has(key)) {
          instances.set(key, {...instance, ...item, versionId: item.trainingDocumentVersionId});
        }
      }
    }
    results.instances = [...instances.values()];
    args.applyFilters = true;
  }

  handleDataPreProcess(data) {
    return this.trainingService.loadTrainingInfo(this.getUserId())
      .then(result => ({...data, ...result}));
  }

  getUserId() {
    const user = this.findUserToDisplay(this.state.users);
    return user ? user.id : UIUtils.getUserId();
  }

  handleGenerateColumns() {
    const {t} = this.props;
    return [
      {
        title: t("Version"),
        ...COLUMN_FORMATTERS.VERSION,
        data: item => item.documentVersion,
      },
      {
        title: t("Curricula"),
        width: 50,
        data: item => item.curriculumNames
      },
      {
        title: t("Date assigned"),
        width: 50,
        data: item => item.assignmentDate || "",
        ...COLUMN_FORMATTERS.DATE(),
      },
      {
        title: t("Date completed"),
        width: 50,
        containsHTML: true,
        data: item => item.trainingStatus !== "Superseded" && item.trainingStatus !== "TrainedOutside"
          ? (item.completionDate || "")
          : t(UIUtils.convertCamelCaseToSpacedOutWords(item.trainingStatus)),
        ...COLUMN_FORMATTERS.DATE(),
      },
    ];
  }

  async handleSendApproval(instance, approve, approvalInfo) {
    approvalInfo = approvalInfo || {};

    let payload = {
      versionId: instance.LastApprovedVersionId,
      comment: approvalInfo.comment,
      email: approvalInfo.email,
      password: approvalInfo.password,
      signingPin: approvalInfo.signingPin,
      baseId: instance.id
    };

    try {
      let signOffResult = await this.trainingService.signOff(approve, payload, {useTwoWayCommunication: false});
      let updatedData = await this.reloadDataFromServer(signOffResult);

      if (approve) {
        ApprovalResponsePopup.setApprovalSucceededInThisSession();
      }

      instance.trainingStatus = "Trained";

      this.displaySignOffSuccessMessage(instance);

      return updatedData;
    } catch (results) {
      return this.failCallback(results);
    }
  }

  displaySignOffSuccessMessage(instance) {
    const documentLabel = UIUtils.getRecordCustomLabelForDisplay({
      ...instance,
      typeCode: "DOC"
    });

    this.setStateSafely({
      success: true,
      message: `Document "${documentLabel}" successfully signed off as trained.`
    }, () => {
      if (this.state.initializeQuickPanel) {
        this.state.initializeQuickPanel(true);
      }
    });
  }

  async reloadDataFromServer(result) {
    let data = result;

    if (this.list) {
      data = await this.list.getDataFromServer(true);
    }
    return data;
  }

  findUserToDisplay(users) {
    return users.find(user => user.id === UIUtils.parseInt(UIUtils.getParameterByName("id") || UIUtils.getUserId()));
  }

  renderPage() {
    const {t} = this.props;
    const {
      selectedItem,
      isApprovalPopupOpen,
      currentUser,
      success,
      message,
      id,
    } = this.state;

    if (success && message) {
      UIUtils.showSuccess(message);
    }

    return (
      <PageFrameLayout
        title={t("Users")}
        tabs={USER_TABS}
        selectedTab={USER_TABS_ENUM.Training}
        recordName={currentUser ? currentUser.fullName : ""}
        showLinksInTitleBar
        urlParams={currentUser ? {id: currentUser.id} : {id}}
      >
        <FilteredList
          parent={this}
          ref={list => this.list = list}
          topLevel
          listTableComponent={MyTrainingListTable}
          hideRemoveButton
          hideManageColumn
          hideShowArchivedButton
          showIdInSearchBox
          hideApprovedLabel
          showVersionInQuickPanel
          showVersionInSearchBox
          navigateToVersion
          filterSelector={item => [item.name, item.customID, item.curriculumNames]}
          editableName="Document"
          editableDisplayName={t("Document")}
          editorType={EDITOR_TYPES.TRAINING_PANEL}
          filters={this.filters}
          quickPanelConfig={{
            isMyTraining: true,
            originalWidth: 720,
          }}
          quickPanelButtons={[QUICK_PANEL_BUTTONS.TRAINING_SIGN_OFF]}
          onGenerateColumns={this.handleGenerateColumns}
          onDataPreProcess={this.handleDataPreProcess}
          onQuickPanelInstanceReceived={this.handleQuickPanelInstanceReceived}
          onDataReceived={this.handleDataReceived}
          additionalRequestData={{
            includeModelAdditionalInfo: false,
            includeAllVersions: false,
            includeLatestApprovedOrDraft: false,
            includeAllApprovedVersions: true,
          }}
          onQuickPanelActionClick={this.handleQuickPanelActionClick}
          showQuickPanelButtonsInNewLine={true}
        />
        {
          isApprovalPopupOpen
            ? (
              <MarkAsTrainedPopup
                baseTypeName={"Training Record"}
                instance={selectedItem}
                onPopupVisibilityChange={this.toggleApprovalPopup}
                onSendApproval={this.handleSendApproval}
              />
            ) : ""
        }
      </PageFrameLayout>
    );
  }

  toggleApprovalPopup(visible) {
    return this.setStateSafely({isApprovalPopupOpen: visible});
  }
}

export default I18NWrapper.wrap(MyTraining, ["documents", "users"]);
// i18next-extract-mark-ns-stop training
