import {
  EditorTools,
  EditorToolsSettings,
  EditorUtils,
} from "@progress/kendo-react-editor";
import React from "react";
import {FormatBlockFontSize, FormatBlockType} from "../common/constants";
import {DropDownListChangeEvent, DropDownListCloseEvent} from "@progress/kendo-react-dropdowns";
import {ToolProps} from "../common/types";

const {FormatBlock: TelerikFormatBlock} = EditorTools;

const NORMAL_TEXT = {
  text: "Normal Text",
  value: "p",
  style: {
    display: "block",
    fontSize: FormatBlockFontSize[FormatBlockType.NormalText],
    marginLeft: 0,
  },
};
const TITLE = {
  text: "Title",
  value: "title",
  style: {
    display: "block",
    fontSize: FormatBlockFontSize[FormatBlockType.Title],
    marginLeft: 0,
  },
};
const HEADING1 = {
  text: "Heading 1",
  value: "h1",
  style: {
    display: "block",
    fontSize: FormatBlockFontSize[FormatBlockType.Heading1],
    fontWeight: "bold",
    marginLeft: 0,
  },
};
const HEADING2 = {
  text: "Heading 2",
  value: "h2",
  style: {
    display: "block",
    fontSize: FormatBlockFontSize[FormatBlockType.Heading2],
    fontWeight: "bold",
    marginLeft: 0,
  },
};
const HEADING3 = {
  text: "Heading 3",
  value: "h3",
  style: {
    display: "block",
    fontSize: FormatBlockFontSize[FormatBlockType.Heading3],
    fontWeight: "bold",
    marginLeft: 0,
  },
};
const HEADING4 = {
  text: "Heading 4",
  value: "h4",
  style: {
    display: "block",
    fontSize: FormatBlockFontSize[FormatBlockType.Heading4],
    fontWeight: "bold",
    marginLeft: 0,
  },
};
const CAPTION = {
  text: "Caption",
  value: "caption",
  style: {
    display: "block",
    fontSize: FormatBlockFontSize[FormatBlockType.Caption],
    marginLeft: 0,
  },
};
EditorToolsSettings.formatBlock.items = [
  NORMAL_TEXT,
  TITLE,
  HEADING1,
  HEADING2,
  HEADING3,
  HEADING4,
  CAPTION,
];

/**
 * The FormatBlock tool component to format a text selection as paragraph,
 * heading 1, or heading 2...
 */
export default function FormatBlock(props: ToolProps): React.ReactElement {
  const {view} = props;

  const getCurrentValue = () => {
    const state = view && view.state;
    const styles = state
      ? EditorUtils.getInlineStyles(state, {
        name: "block-type",
        value: /^.+$/,
      })
      : [];
    const currentTextType = new Set(styles).size === 1 ? styles[0] : null;
    const formats = state ? EditorUtils.getBlockFormats(state) : [];
    const currentFormatBlock = new Set(formats).size === 1 ? formats[0] : null;

    if (currentFormatBlock === "p") {
      if (currentTextType === TITLE.value) {
        return TITLE;
      }

      if (currentTextType === CAPTION.value) {
        return CAPTION;
      }
    }

    return EditorToolsSettings.formatBlock.items.find(function (item) {
      return item.value === currentFormatBlock;
    });
  };

  let shouldUpdateValueOnClose = true;
  const handleChange = (event: DropDownListChangeEvent) => {
    // We don't want to update on close when the onChange event is triggered. If
    // we don't have this flag, the formatBlock method will be triggered twice,
    // and it will mess up with the undo and redo
    shouldUpdateValueOnClose = false;
    formatBlock(event);
  };

  const onClose = (event: DropDownListCloseEvent) => {
    // The onChange event is only triggered if the value is different. We need to
    // do this trick to reset the font size when user select the same format for
    // the selected text
    if (!view || !shouldUpdateValueOnClose) {
      return;
    }

    const item = event.target.value;
    const formats = EditorUtils.getBlockFormats(view.state);
    const currentFormatBlock = new Set(formats).size === 1 ? formats[0] : null;
    let elementFontSize = `${FormatBlockFontSize[currentFormatBlock]}pt`;
    if (item.text === TITLE.text || item.text === CAPTION.text) {
      const blockTypes = EditorUtils.getInlineStyles(view.state, {
        name: "block-type",
        value: /^.+$/,
      });
      const currentBlockType = new Set(blockTypes).size === 1 ? blockTypes[0] : null;
      elementFontSize = `${FormatBlockFontSize[currentBlockType]}pt`;
    }

    const styles = EditorUtils.getInlineStyles(view.state, {
      name: "font-size",
      value: /^.+$/,
    });
    const currentFontSize = new Set(styles).size === 1 ? styles[0] : null;
    if (currentFontSize && elementFontSize !== currentFontSize) {
      formatBlock(event);
    }
  };

  const formatBlock = (event: DropDownListChangeEvent | DropDownListCloseEvent) => {
    if (!view) {
      return;
    }

    const item = event.target.value;
    // We set this meta for the transaction, so the set_data_numbered_list.ts can reset
    // the font size for the list item
    const tr = view.state.tr.setMeta(
      "commandName",
      EditorToolsSettings.fontSize.commandName
    );
    EditorUtils.toggleInlineFormat(view, {mark: "style"}, tr);
    if (item.text === TITLE.text || item.text === CAPTION.text) {
      EditorUtils.formatBlockElements(
        view,
        "p",
        EditorToolsSettings.formatBlock.commandName
      );
      EditorUtils.applyInlineStyle(
        view,
        {
          style: "block-type",
          value: item.value,
        },
        EditorToolsSettings.formatBlock.commandName
      );
    } else {
      EditorUtils.formatBlockElements(
        view,
        item.value,
        EditorToolsSettings.formatBlock.commandName
      );
    }

    const {syntheticEvent} = event;
    if (syntheticEvent && syntheticEvent.type === "click") {
      view.focus();
    }
  };

  return (
    <TelerikFormatBlock
      value={getCurrentValue()}
      onChange={handleChange}
      onClose={onClose}
      {...props}
    />
  );
}
