"use strict";

import * as go from "gojs";
import { MIN_HEADER_HEIGHT, PoolLayout } from "./pool_layout";
// eslint-disable-next-line no-unused-vars
import * as HyperlinkText from "./hyperlink_text";
import { REPORT_OPTIONS_ENUM } from "../../constants/report_constants";
import Cookies from "js-cookie";
import { getVisibleNodesInGroup } from "../process_map_report_helper";
import { ELEMENT_FILTER_KEYS_TO_NAMES } from "../process_map_filter";
import BaseAutoBind from "../../../base_auto_bind";
import { GOJS_DIAGRAM_DEFAULTS } from "../../../processExplorer/diagram/process_explorer_diagram_facade";
import { MODEL_DECLARATIONS } from "../../../../server/common/generic/common_models";
import {TYPE_CODE, TYPE_CODE_TO_HEADER_BACKGROUND_COLOR, TYPE_CODE_TO_BORDER_COLOR, TYPE_CODE_TO_BACKGROUND_COLOR} from "../../../processExplorer/process_explorer_constants";

// noinspection JSUnresolvedFunction
const cookie = Cookies.get("PROCESS_FLOW_MAP_STATEMENT");

if (cookie) {
  go.Diagram.licenseKey = window.atob(cookie);
}

export const EXPORT_IMAGE_SIZE_OPTIONS = {
  FULL: {
    scale: 1,
    maxSize: new go.Size(Infinity, Infinity),
  },
  SCREEN: {}
};

const GO = go.GraphObject.make;
const COMMON_GROUP_STYLE = [
  {
    background: "transparent",  // can grab anywhere in bounds
    resizable: false,
    avoidable: false,  // Links can go through this object
    selectable: false,
  },
];
const SWIMLANE_INITIAL_UO_LAYOUT_SPACING = 35;
const SWIMLANE_UO_LAYOUT_SPACING = 110;
const SWIMLANE_STEP_LAYOUT_SPACING = 40;
const SWIMLANE_WIDTH = 400;
const HEADERS_SWIMLANES_SPACING = 0;

const OUTPUT_TYPECODES = new Set(["IQA", "IPA", "FQA", "FPA"]);

/**
 * This implements the process flow map diagram organized in swimlanes. The class initializes and configures the
 * GoJS diagram properties, templates and layouts. This class encapsulates the presentation layer for the process flow map report.
 */
export class ProcessFlowMapDiagramFacade extends BaseAutoBind {
  /**
   * @param div {HTMLElement} The div that the swim lanes should be rendered to.
   * @param model {{}} The nodes & links you want to draw.
   * @param filters {{}} An object where the key is from ELEMENT_FILTER_KEYS_TO_NAMES and the value is a boolean if the
   * filter is turned on or off by the user.
   * @param allOperations {[{typecode: string, id: number}]} An array listing all operations (UOs and Steps) in order.
   */
  constructor(div, model, filters, allOperations) {
    super();
    this.allOperations = allOperations;

    this._diagram = GO(go.Diagram, div, {
      ...GOJS_DIAGRAM_DEFAULTS,
      layout: GO(go.GridLayout, {
        alignment: go.GridLayout.Position,
        wrappingColumn: ProcessFlowMapDiagramFacade.computeNumberOfColumns(filters),
        wrappingWidth: Infinity,
        spacing: new go.Size(0, HEADERS_SWIMLANES_SPACING),
        cellSize: GO(go.Size, {width: 0, height: 0}),
        isRealtime: false,
        comparer: function(laneA, laneB) { // Sort the swimlanes based on the columnSort property of the model data.
          let da = laneA.data;
          let db = laneB.data;
          return da.columnSort - db.columnSort;
        },
        isOngoing: false,  // Don't invalidate layout when nodes or links are added or removed,
      }),
      "animationManager.isEnabled": false,
      "undoManager.isEnabled": true,
      SelectionMoved: () => {
        this.reLayoutLanes();
      },
      computeBounds: this.computeDiagramBounds,
      ViewportBoundsChanged: this.diagramViewportBoundsChanged,
      contentAlignment: go.Spot.TopCenter,
      initialAutoScale: go.Diagram.UniformToFill,
      InitialLayoutCompleted: this.initialLayoutCompleted
    });
    let foregroundLayer = this._diagram.findLayer("Foreground");
    this._diagram.addLayerAfter(GO(go.Layer, {name: "HeaderPanelLayer"}), foregroundLayer);
    this._diagram.addLayerBefore(GO(go.Layer, {name: "LinksLayer"}), foregroundLayer);
    this._diagram.addLayerBefore(GO(go.Layer, {name: "NodesLayer"}), foregroundLayer);
    this._diagram.toolManager.hoverDelay = 300;
    this._diagram.nodeTemplateMap = this.getTemplateMap();
    this._diagram.groupTemplateMap = this.getGroupTemplateMap();
    this._diagram.linkTemplate = this.getLinkTemplate();
    this._diagram.model = model;
    this._diagram.model.isReadOnly = true;
  }

  get diagram() {
    return this._diagram;
  }

  static computeNumberOfColumns(filters) {
    const keys = Object.keys(ELEMENT_FILTER_KEYS_TO_NAMES);
    let columns = 1; // For the UO column that the user can't remove
    for (const key of keys) {
      if (filters[key]) {
        columns += 1;
      }
    }

    return columns;
  }

  /**
   * Call this if you want to update the model to draw.
   * @param newModel {{}} The nodes & links you want to draw.
   * @param filters {{string: boolean}} A mapping of ELEMENT_FILTER_KEYS_TO_NAMES to booleans to show/filter swimlanes.
   * @param allOperations {[{typecode: string, id: number}]} An array listing all operations (UOs and Steps) in order.
   */
  redrawSwimlanes(newModel, filters, allOperations) {
    this.allOperations = allOperations;
    this._diagram.clear();
    this._diagram.model = newModel;
    this._diagram.model.isReadOnly = true;

    // Change the number of wrapping columns based on the number of showing filters
    this._diagram.layout.wrappingColumn = ProcessFlowMapDiagramFacade.computeNumberOfColumns(filters);
  }

  /**
   * This computed the diagram bounds. Although this would typically happen automatically, we need to override this
   * method and manually calculate the document bounds taking into account that the swimlane headers are now
   * fixed and not always above the swimlanes. In case the user scrolls, the swimlane headers move so that they
   * always stay to the top of the viewport, but then the automated calculation of the document boundaries fails,
   * since it does not include the swimlane headers height.
   * @returns {go.Rect | *}
   */
  computeDiagramBounds() {
    let lanesRectangle = this.diagram.computePartsBounds(this.diagram.findTopLevelGroups());
    lanesRectangle.top -= (MIN_HEADER_HEIGHT + HEADERS_SWIMLANES_SPACING);
    return lanesRectangle;
  }

  /**
   * This is called every time the user changes the diagram viewport, that is every time he zooms in
   * or scrolls. The swimlane headers are moved so that they always show up on the top of the viewport,
   * no matter where the vertical scroll position is.
   * @param e
   */
  diagramViewportBoundsChanged(e) {
    e.diagram.removeDiagramListener("ViewportBoundsChanged", this.diagramViewportBoundsChanged);
    e.diagram.commit(diagram => {
      let lanes = {};

      diagram.findTopLevelGroups().each(pool => {
        if (pool.category === REPORT_OPTIONS_ENUM.ProcessFlowMapReport.PoolGroupCategory) {

          pool.memberParts.each((lane) => {
            if (lane.category === REPORT_OPTIONS_ENUM.ProcessFlowMapReport.SwimlanesGroupCategory) {
              lanes[lane.key + "Header"] = lane;
            }
          });

          e.diagram.nodes.each(part => {
            if (part.category === REPORT_OPTIONS_ENUM.ProcessFlowMapReport.SwimlaneHeaderCategory) {
              let headerPanel = part.findObject("FixedHeaderPanel");
              let newHeaderPanelPosition;
              if (diagram.documentBounds.height < diagram.viewportBounds.height) {
                newHeaderPanelPosition = lanes[part.key].position.y - MIN_HEADER_HEIGHT - HEADERS_SWIMLANES_SPACING;
              } else {
                newHeaderPanelPosition = diagram.viewportBounds.y > 0 ? diagram.viewportBounds.y : 0;
              }

              headerPanel.position = new go.Point(headerPanel.position.x, newHeaderPanelPosition);
            }
          });
        }
      });
    }, "fix Parts");
    e.diagram.addDiagramListener("ViewportBoundsChanged", this.diagramViewportBoundsChanged);
  }

  /**
   * Call this to export the image of this diagram for the user to downlaod.
   * @param exportFullScreen {boolean} True if the export should be full screen, false to export only what you see on the screen.
   */
  exportImage(exportFullScreen) {
    // Set the headers back to the top of the document before we export the image full screen.
    if (exportFullScreen) {
      this.diagram.removeDiagramListener("ViewportBoundsChanged", this.diagramViewportBoundsChanged);
      this.diagram.commit(diagram => {
        diagram.nodes.each(part => {
          if (part.category === REPORT_OPTIONS_ENUM.ProcessFlowMapReport.SwimlaneHeaderCategory) {
            let headerPanel = part.findObject("FixedHeaderPanel");
            headerPanel.position = new go.Point(headerPanel.position.x, 0);
          }
        });
      }, "Reset header");
      this.diagram.addDiagramListener("ViewportBoundsChanged", this.diagramViewportBoundsChanged);
    }

    const imageData = this.diagram.makeImageData({
      returnType: "Image",
      ...(exportFullScreen ? EXPORT_IMAGE_SIZE_OPTIONS.FULL : EXPORT_IMAGE_SIZE_OPTIONS.SCREEN),
    });

    // Set the headers back to the top of the screen
    this.diagramViewportBoundsChanged({diagram: this.diagram});
    return imageData;
  }

  /**
   * This is called when the initial layout of the diagram is completed.
   * Here is where the first rendering of the diagram occurs and the nodes are
   * properly positioned in the swimlanes.
   * @param e The event object holding a reference to the diagram.
   */
  initialLayoutCompleted(e) {
    let diagram = e.diagram;
    let lanes = [];
    let laneHeaderNameToTileNode = {};
    let laneHeaderNameToRecordNode = {};
    let currentYPosition = 0;

    diagram.nodes.each((lane) => {
      if (lane instanceof go.Group && lane.category === REPORT_OPTIONS_ENUM.ProcessFlowMapReport.SwimlanesGroupCategory) {
        let headerPanel = diagram.findNodeForKey(lane.key + "Header");
        let headerTextPart = headerPanel.findObject("PanelTextBlock");

        /*
        The currentYPosition is used for positioning the report nodes layer by layer horizontally.
        These coordinates are relative to the swimlane, so we set the starting point to be right
        after the header panel.
         */
        currentYPosition = currentYPosition < headerPanel.actualBounds.height ? headerPanel.actualBounds.height : currentYPosition;

        // Center all swimlane nodes in the swimlane, horizontally and vertically, except UOs & Steps that have to be separate.
        if (lane.key !== "UOs") {
          let swimlaneCenter = lane.findObject("LanePanel").getDocumentBounds().center;
          let swimLaneMembersCenter = diagram.computePartsBounds(getVisibleNodesInGroup(lane)).center;
          diagram.moveParts(lane.memberParts, swimlaneCenter.subtract(swimLaneMembersCenter));
        }

        // Create a map of swimlanes and nodes, so that we can iterate on them easily later on and do the initial layout
        lanes.push(lane);
        // noinspection JSUnresolvedVariable
        const headerText = headerTextPart.text;
        if (!laneHeaderNameToTileNode[headerText]) {
          laneHeaderNameToTileNode[headerText] = [];
          // Uncomment to help with debugging
          // laneHeaderNameToTileNode[headerText].headerText = headerText;
          laneHeaderNameToRecordNode[headerText] = [];
          // Uncomment to help with debugging
          // laneHeaderNameToRecordNode[headerText].headerText = headerText;
        }
        lane.memberParts.each(tileNode => {
          if (tileNode.type.name !== "Link") {
            laneHeaderNameToTileNode[headerText].push(tileNode);
            // noinspection JSUnresolvedVariable
            tileNode.memberParts.each(node => {
              if (node.type.name !== "Link") {
                laneHeaderNameToRecordNode[headerText].push(node);
              }
            });
          }
        });
      }
    });

    /*
     * Iterate through the swimlanes nodes and position each one of the tiles & nodes inside the tiles in the right Y
     * coordinate within the swimlane so that all nodes related to the same UO/tile are aligned on top and also the next
     * horizontal layer of nodes starts right after the previous one finishes, taking into account the layout spacing as
     * well.
     */
    let uoCount = 0;
    const allOps = this.allOperations;
    for (let opCount = 0; opCount < allOps.length; opCount++) {
      let {typeCode} = allOps[opCount];
      if (opCount === 0) {
        currentYPosition += SWIMLANE_INITIAL_UO_LAYOUT_SPACING;
      } else {
        currentYPosition += (typeCode === MODEL_DECLARATIONS.UO.typeCode ? SWIMLANE_UO_LAYOUT_SPACING : SWIMLANE_STEP_LAYOUT_SPACING);
      }

      if (typeCode === MODEL_DECLARATIONS.UO.typeCode) {
        for (let tiles of Object.values(laneHeaderNameToTileNode)) {
          let tile = tiles[uoCount];
          tile.position = new go.Point(tile.actualBounds.x, currentYPosition - 30);
        }
        uoCount++;
      }

      for (let nodes of Object.values(laneHeaderNameToRecordNode)) {
        if (nodes.length <= opCount) {
          continue;
        }
        let node = nodes[opCount];
        node.position = new go.Point(node.actualBounds.x, currentYPosition);
      }
      let maxNodeHeight = this.findMaxNodeHeight(laneHeaderNameToRecordNode, opCount);
      currentYPosition += maxNodeHeight;

      const nextOp = opCount + 1;
      if ((nextOp < allOps.length && allOps[nextOp].typeCode === MODEL_DECLARATIONS.UO.typeCode) || nextOp === allOps.length) {
        // This is the end of the current UO, so set the size of the tile
        for (let tiles of Object.values(laneHeaderNameToTileNode)) {
          let tile = tiles[uoCount - 1];
          const tilePanel = tile.elt(0);
          const tileRectangle = tilePanel.elt(0);
          tileRectangle.height = currentYPosition - tile.position.y + 30;
        }
      }
    }
    currentYPosition += SWIMLANE_UO_LAYOUT_SPACING;

    // Set all of the swimlanes height to be the calculated total pool height.
    for (let lane of lanes) {
      let shape = lane.resizeObject;
      if (shape !== null) {
        shape.height = currentYPosition;
      }
    }
  }

  /**
   * Finds the maximum height among all nodes in all swimlanes that belong to the same horizontal layer.
   * Those would typically be the nodes associated with the same UO.
   * @param laneNodes A map holding the swimlanes and their nodes.
   * @param layer An index, pointing the horizontal layer the maximum node height is calculated for
   * @returns {any}
   */
  findMaxNodeHeight(laneNodes, layer) {
    return Object.values(laneNodes).reduce((maxHeight, laneNodes) => {
      return laneNodes[layer]
      && laneNodes[layer].visible
      && maxHeight < laneNodes[layer].actualBounds.height
        ? laneNodes[layer].actualBounds.height
        : maxHeight;
    }, 0);
  }

  /**
   * This will make sure the swimlanes are properly arranged every time a node is moved around.
   */
  reLayoutLanes() {
    this._diagram.layout.invalidateLayout();
    this._diagram.findTopLevelGroups().each(group => {
      if (group.category === REPORT_OPTIONS_ENUM.ProcessFlowMapReport.PoolGroupCategory) {
        group.layout.invalidateLayout();
      }
    });
    this._diagram.layoutDiagram();
  }

  /**
   * This will trigger the diagram to be layed out
   */
  reLayoutDiagram() {
    this._diagram.nodes.each(node => {
      if (node.category === REPORT_OPTIONS_ENUM.ProcessFlowMapReport.SwimlanesGroupCategory) {
        node.layout.isValidLayout = false;
      } else if (node.category === REPORT_OPTIONS_ENUM.ProcessFlowMapReport.SwimlaneHeaderCategory) {
        node.invalidateLayout();
      }
    });

    this._diagram.layoutDiagram();
  }

  // noinspection JSMethodCanBeStatic
  /**
   * This creates the template map used by the diagram groups. The groups for the
   * process flow map diagram are the swimlanes and the pool holding the swimlanes.
   * @returns {Map<any, any>}
   */
  getGroupTemplateMap() {
    const groupTemplateMap = new go.Map();

    const swimlaneTemplate = GO(go.Group, go.Group.Vertical, COMMON_GROUP_STYLE,
      {
        layerName: "Background",
        selectionObjectName: "LanePanel",  // Selecting a lane causes the body of the lane to be highlight, not the header
        layout: GO(go.GridLayout, {
          alignment: go.GridLayout.Location,
          wrappingColumn: 1,
          wrappingWidth: Infinity,
          isRealtime: false,
          isInitial: false,  // Don't even do initial layout
          isOngoing: false,  // Don't invalidate layout when nodes or links are added or removed,
        }),
        computesBoundsAfterDrag: true,
        computesBoundsIncludingLinks: false,  // To reduce occurrences of links going briefly outside the lane
        computesBoundsIncludingLocation: true,  // To support empty space at top-left corner of lane
        handlesDragDropForMembers: true,  // Don't need to define handlers on member Nodes and Links
      },
      new go.Binding("visible", "visible"),
      GO(go.Panel, go.Panel.Table,
        {
          stretch: go.GraphObject.Fill,
        },
        GO(go.RowColumnDefinition, {
          row: 0,
          isRow: true
        }),
        GO(go.RowColumnDefinition, {
          row: 1,
          isRow: true
        }),
        GO(go.Panel, go.Panel.Auto,
          {
            row: 0,
            stretch: go.GraphObject.Fill,
            name: "HeaderPanel",
            alignment: go.Spot.Center,
            visible: false
          },
          GO(go.Panel, go.Panel.Table,
            {name: "HeaderCaptionPanel"},
            GO(go.RowColumnDefinition, {
              column: 0,
              isRow: false
            }),
            GO(go.RowColumnDefinition, {
              column: 1,
              isRow: false
            }),
          )
        ),
        GO(go.Panel, go.Panel.Auto,
          {
            row: 1, column: 0,
            stretch: go.GraphObject.Fill,
            name: "LanePanel",
            // alignment: go.Spot.Center
          },
          GO(go.Shape, "Rectangle",
            new go.Binding("fill", "fill"),
            {
              fill: "white",
              strokeWidth: 0,
            },
          ),
          GO(go.Placeholder,
            {},
          )
        )
      )
    );

    const tileTemplate = GO(go.Group, go.Group.Vertical, COMMON_GROUP_STYLE,
      {
        layerName: "Background",
        layout: GO(go.GridLayout, {
          alignment: go.GridLayout.Location,
          wrappingColumn: 1,
          wrappingWidth: Infinity,
          isRealtime: false,
          isInitial: true,  // Don't even do initial layout
          isOngoing: false,  // Don't invalidate layout when nodes or links are added or removed,
        }),
      },
      new go.Binding("visible", "visible"),
      GO(go.Panel, go.Panel.Auto,
        GO(go.Shape, "Rectangle",
          new go.Binding("fill", "group", (group) => TYPE_CODE_TO_BACKGROUND_COLOR.get(group)),
          {
            name: "TileRectangle",
            strokeWidth: 0,
          },
          new go.Binding("desiredSize", "group",
            (group) => new go.Size(SWIMLANE_WIDTH + (group === TYPE_CODE.UNIT_OPERATION ? 50 : 0), NaN)),
        ),
      ),
    );

    const poolTemplate = GO(go.Group, go.Group.Auto, COMMON_GROUP_STYLE,
      { // Use a simple layout that ignores links to stack the "lane" Groups next to each other
        layerName: "Background",
        layout: GO(PoolLayout, {
          alignment: go.GridLayout.Position,
          spacing: new go.Size(0, 0),
          isRealtime: false,
          comparer: function(laneA, laneB) { // Sort the swimlanes based on the columnSort property of the model data.
            let da = laneA.data;
            let db = laneB.data;
            return da.columnSort - db.columnSort;
          }
        }),
      },
      GO(go.Shape,
        new go.Binding("fill", "color"),
        {
          fill: "white",
          stroke: "transparent"
        }),
      GO(go.Panel, "Table",
        {defaultRowSeparatorStroke: "transparent"},
        GO(go.Placeholder,
          {row: 0})
      )
    );

    groupTemplateMap.add(REPORT_OPTIONS_ENUM.ProcessFlowMapReport.PoolGroupCategory, poolTemplate);
    groupTemplateMap.add(REPORT_OPTIONS_ENUM.ProcessFlowMapReport.SwimlanesGroupCategory, swimlaneTemplate);
    groupTemplateMap.add(REPORT_OPTIONS_ENUM.ProcessFlowMapReport.TileCategory, tileTemplate);

    return groupTemplateMap;
  }

  /**
   * This creates a template map used by the diagram nodes in the swimlanes. There are 2 types
   * of templates. The one used by the UOs and the one used by everything else which contains child
   * records as well.
   * @returns {Map<any, any>}
   */
  getTemplateMap() {
    let templateMap = new go.Map();

    function highlightLinks(diagram, node, highlighted) {
      diagram.startTransaction("highlight");
      node.isHighlighted = highlighted;
      node.findLinksOutOf().each(link => {
        link.isHighlighted = highlighted;
      });
      diagram.commitTransaction("highlight");
    }

    // This function is fired each time the mouse enters any node on the diagram
    let onMouseEnter = (e, node) => {
      let diagram = node.diagram;

      highlightLinks(diagram, node, true);
    };

    // This function is fired each time the mouse leaves any node on the diagram
    let onMouseLeave = (e, node) => {
      let diagram = node.diagram;

      highlightLinks(diagram, node, false);
    };

    let swimLaneHeaderTemplate = GO(go.Node, go.Panel.Auto,
      {
        layerName: "HeaderPanelLayer",
        selectable: false,
        stretch: go.GraphObject.Fill,
        name: "FixedHeaderPanel",
      },
      new go.Binding("visible", "visible"),
      GO(go.Shape, "Rectangle",
        new go.Binding("fill", "columnType", (columnType) => TYPE_CODE_TO_HEADER_BACKGROUND_COLOR.get(columnType)),
        {
          name: "MainPanelShape",
          strokeWidth: 0,
          stretch: go.GraphObject.Fill,
        },
      ),
      GO(go.Panel, go.Panel.Table,
        {
          name: "FixedHeaderCaptionPanel",
        },
        GO(go.RowColumnDefinition, {
          column: 0,
          isRow: false
        }),
        GO(go.RowColumnDefinition, {
          column: 1,
          isRow: false
        }),
        GO(go.Picture,
          new go.Binding("source", "thumbnail"),
          new go.Binding("background", "transparent"),
          {
            background: "transparent",
            width: 40, height: 40,
            row: 0, column: 0,
            margin: GO(go.Margin, go.Margin.parse("0, 10, 0, 10")),
          }),
        GO(go.TextBlock,
          new go.Binding("text", "title"),
          new go.Binding("stroke", "columnType", columnType => {
            switch (columnType) {
              case TYPE_CODE.IA:
              case TYPE_CODE.FA:
                return "white";
              default:
                return "black";
            }
          }),
          {
            font: "bold 22px Roboto",
            textAlign: "left",
            verticalAlignment: go.Spot.Center,
            alignment: go.Spot.Left,
            margin: GO(go.Margin, go.Margin.parse("20, 20, 20, 0")),
            row: 0, column: 1,
            name: "PanelTextBlock",
            wrap: go.TextBlock.WrapFit
          }
        )
      )
    );

    let UOTemplate = GO(go.Node, go.Panel.Auto, COMMON_GROUP_STYLE,
      {
        layerName: "NodesLayer",
        mouseEnter: onMouseEnter,
        mouseLeave: onMouseLeave,
      },
      new go.Binding("locationSpot", "typeCode", (typeCode) =>
        typeCode === MODEL_DECLARATIONS.UO.typeCode ?
          new go.Spot(0.0, 0, -20, 0)
          : new go.Spot(0.0, 0, -120, 0)),
      GO(go.Shape,
        new go.Binding("strokeWidth", "isHighlighted", (h) => {
          return h ? 3 : 1;
        }).ofObject(),
        new go.Binding("width", "typeCode",
          (typeCode) => typeCode === MODEL_DECLARATIONS.UO.typeCode ? 400 : 300),
        new go.Binding("stroke", "columnName", (columnName) => {
          return TYPE_CODE_TO_BORDER_COLOR.get(columnName);
        }),
        {
          figure: "Rectangle",
          fill: "#ffffff",
        }),
      GO(go.Panel, go.Panel.Vertical,
        {
          defaultStretch: go.GraphObject.Horizontal,
        },
        new go.Binding("width", "typeCode",
          (typeCode) => typeCode === MODEL_DECLARATIONS.UO.typeCode ? 400 : 300),
        GO(go.Panel, go.Panel.Vertical,
          GO("HyperlinkText",
            (node) => node.data,
            new go.Binding("font", "typeCode",
              (typeCode) => (typeCode === MODEL_DECLARATIONS.UO.typeCode ? "bold " : "") + "22px Open Sans"),
            {
              name: "PanelTextBlock",
              font: "22px Open Sans",
              textAlign: "center",
              alignment: go.Spot.Left,
              margin: new go.Margin(15, 10, 15, 20),
            }
          ),
        )
      )
    );

    let recordsTableTemplate = GO(go.Node, go.Panel.Auto, COMMON_GROUP_STYLE,
      new go.Binding("visible", "visible"),
      {
        locationSpot: new go.Spot(0.0, 0, -20, 0),
        layerName: "NodesLayer",
        fromLinkable: true,
        toLinkable: true,
        selectable: false,
        mouseEnter: onMouseEnter,
        mouseLeave: onMouseLeave,
      },
      GO(go.Shape,
        new go.Binding("strokeWidth", "isHighlighted", (h) => {
          return h ? 3 : 1;
        }).ofObject(),
        new go.Binding("stroke", "columnName", (columnName) => {
          return TYPE_CODE_TO_BORDER_COLOR.get(columnName);
        }),
        {
          figure: "Rectangle",
          fill: "white",
          width: 300,
        }),
      GO(go.Panel, go.Panel.Vertical,
        new go.Binding("itemArray", "records"),
        {
          name: "ItemTemplatePanel",
          width: 300,
          defaultStretch: go.GraphObject.None,
          margin: new go.Margin(10, 10, 10, 20),
          itemTemplate:
            GO(go.Panel, go.Panel.Vertical,
              GO(go.Panel, go.Panel.Horizontal,
                {
                  alignment: go.Spot.Right,
                  stretch: go.GraphObject.Vertical,
                },
                GO(go.Shape,
                  {
                    name: "CriticalityRectangle",
                    figure: "Rectangle",
                    width: 15,
                    height: 15,
                    stroke: "#FFFFFF",
                    alignment: go.Spot.Top,
                  },
                  new go.Binding("margin", "modelType", modelType => {
                    return new go.Margin(10, 10, 0, OUTPUT_TYPECODES.has(modelType) ? 35 : 10);
                  }),
                  new go.Binding("visible", "isCritical"),
                  new go.Binding("fill", "color"),
                ),
                GO("HyperlinkText",
                  (node) => node.data,
                  {
                    name: "PanelTextBlock",
                    font: "22px Open Sans",
                  },
                  new go.Binding("margin", "", data => {
                    if (data.isRecordHeader) {
                      return new go.Margin(data.isSecondRecordHeader ? 10 : 5, 5, 0, 0);
                    } else if (OUTPUT_TYPECODES.has(data.modelType)) {
                      // Indent these past their headers
                      return new go.Margin(5, 10, 5, data.isCritical ? 0 : 60);
                    } else {
                      return new go.Margin(5, 10, 5, data.isCritical ? 0 : 35);
                    }
                  }),
                  new go.Binding("width", "", data => {
                    // Any more and the text will go off the side of the screen. See QI-4068.
                    return OUTPUT_TYPECODES.has(data.modelType) ? 220 : 240;
                  }),
                  new go.Binding("font", "font"),
                  new go.Binding("stroke", "stroke"),
                )
              )
            )
        })
    );

    templateMap.add("SwimlaneHeaderTemplate", swimLaneHeaderTemplate);
    templateMap.add("UnitOperationTemplate", UOTemplate);
    templateMap.add("StepTemplate", UOTemplate);
    templateMap.add("ItemsTableTemplate", recordsTableTemplate);

    return templateMap;
  }

  // noinspection JSMethodCanBeStatic
  /**
   * This creates a template for controlling the links look and feel between the swimlanes nodes.
   * @returns {Adornment | Panel | GraphObject | InstanceType<Link>}
   */
  getLinkTemplate() {
    const linkColoring = {
      stroke: "#727373",
      fill: "grey",
    };
    return GO(go.Link,
      {
        layerName: "LinksLayer",
        routing: go.Link.None,
      },
      new go.Binding("fromSpot", "fromSpot"),
      new go.Binding("toSpot", "toSpot"),
      GO(go.Shape,
        linkColoring,
        new go.Binding("strokeWidth", "isHighlighted", (h) => {
          return h ? 2 : 1;
        }).ofObject()),
      GO(go.Shape,
        linkColoring,
        new go.Binding("strokeWidth", "isHighlighted", (h) => {
          return h ? 2 : 1;
        }).ofObject(),
        {toArrow: "Standard"})
    );
  }

  /**
   * This will scale the diagram so that its full width fits in the browser. If the report is taller than its width
   * then a vertical scrollbar will show up allowing the user to scroll and see the rest of it.
   */
  zoomToFitWidth() {
    // Zoom the document to fit perfectly
    let documentBounds = this._diagram.documentBounds;
    let viewportBounds = this._diagram.viewportBounds;
    const viewportWidth = viewportBounds.width;
    let viewportAspectRatio = viewportWidth > 0 ? viewportBounds.height / viewportWidth : 1;
    let width = Math.max(documentBounds.width, viewportWidth); // don't zoom in too far if we're only showing a few columns.
    // Uncomment for verbose logging
    // console.log("Zooming in to " + width + " compared to viewport width " + viewportWidth);
    let zoomRect = new go.Rect(documentBounds.x, documentBounds.y, width, width * viewportAspectRatio);
    this._diagram.zoomToRect(zoomRect); // This zooms into a given rectangle, rather than zooming the document to fit in this rectangle.

    // Give an extra bit on each side for the fly-out windows like the filter & legend
    viewportBounds = this._diagram.viewportBounds;
    width = viewportBounds.width + (2 * 85 / this._diagram.scale);
    zoomRect = new go.Rect(viewportBounds.x, viewportBounds.y, width, width * viewportAspectRatio);
    this._diagram.zoomToRect(zoomRect); // This zooms into a given rectangle, rather than zooming the document to fit in this rectangle.
  }
}
