"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import Section from "../../editor/widgets/section";
import TextAttribute from "../../editor/attributes/text_attribute";
import TextAreaAttribute from "../../editor/attributes/text_area_attribute";
import TypeaheadAttribute from "../../editor/attributes/typeahead_attribute";
import ReferenceDocuments from "@cherrycircle/fda-docs";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import { EDITOR_TYPES } from "../../editor/editor_constants";
import BaseOperationsEditor from "../attributes/base_operations_editor";

/**
 * This is the class that renders the Add/Edit Step editor in the process explorer.
 */
// i18next-extract-mark-ns-start process_explorer
class Step extends BaseOperationsEditor {
  /*class Step extends BaseAttributeParent {*/
  constructor(props) {
    super(props, "step", "Step", "Step (STP)");

    // Bind stuff
    this.getHTMLURLPrefix = Step.getHTMLURLPrefix;

    const processId = UIUtils.parseInt(UIUtils.getParameterByName("processId"));
    this.setStateSafely({
      processId,
      originalUOId: this.state.UnitOperationId,
    });
  }

  componentDidMount() {
    document.title = "QbDVision Steps";

    super.componentDidMount();
  }

  static getHTMLURLPrefix() {
    return "/processExplorer/steps";
  }

  initializeValue(attributeName, attributeValue) {
    // Uncomment for verbose logging
    // console.log("Setting previous unit: ", attributeName, attributeValue);

    // Initialize the previous step with the latest one
    if (attributeName === "PreviousStepId" && this.isAdd()) {
      let previousStepId = UIUtils.getParameterByName("prevStepId");
      if (previousStepId) {
        super.initializeValue(attributeName, UIUtils.parseInt(previousStepId));
      } else {
        super.initializeValue(attributeName, attributeValue);
      }
    } else {
      super.initializeValue(attributeName, attributeValue);
    }
  }

  getAttributeNames() {
    return ["ProcessComponent", "Material", "MaterialAttribute", "ProcessParameter", "IQA", "IPA"];
  }

  getAttributeParentId() {
    return "StepId";
  }

  updateChildAttributes(childAttributes) {
    this.setStateSafely({
      processParameters: childAttributes.filter(childAttribute => childAttribute.typeCode === "PP" && childAttribute.StepId === this.getAttributeParentIdValue()),
      childAttributes,
    });
  }

  onDataReceivedFromServer() {
    super.onDataReceivedFromServer();
    this.setStateSafely({originalUOId: this.state.UnitOperationId});
    this.handleTypeaheadLoaded();
  }

  /**
   * This is a filter function that determines if a child attribute is a child of the currently selected record.
   * @param childAttribute
   * @returns {boolean}
   */
  filterChildAttribute(childAttribute) {
    return (childAttribute.StepId && childAttribute.StepId === this.getAttributeParentIdValue())
      || (childAttribute.Steps && childAttribute.Steps.includes(this.getAttributeParentIdValue()));
  }

  getInfoTooltip() {
    return (
      <InfoTooltip id="infoSTP"
                   fdaGuidanceURL={ReferenceDocuments.Q11_GUIDELINE}
                   fdaGuidancePage={11}
                   fdaGuidanceOffset={190}
                   verbiage={
                     <div>
                       A design space might be determined per unit operation (e.g. reaction, crystallization,
                       distillation, purification), or a combination of selected unit operations and steps.
                     </div>
                   }
      />
    );
  }

  getTabName() {
    return "Process Explorer";
  }

  beforeDataSavedToServer(callback) {
    if (this.state.PreviousStepId === "Start") {
      this.setStateSafely({PreviousStepId: null}, () => super.beforeDataSavedToServer(callback));
    } else {
      super.beforeDataSavedToServer(callback);
    }
  }

  handleSaveResults(result) {
    if (this.getEditorType() !== EDITOR_TYPES.STATIC_PANEL) { // The main panel will take care of reloading typeaheads.
      const cache = new TypeaheadObjectCache("Step", this.getProjectId(), this.getProcessId());
      cache.invalidateCacheOptionsAsync().then(() => {
        cache.loadOptions(() => {
          super.handleSaveResults(result);
        }, {useWriterDB: true});
      });
    } else {
      super.handleSaveResults(result);
    }
  }

  handleSendApproval(approve) {
    return super.handleSendApproval(approve).then((approvalCompletedSuccessfully) => {
      if (approvalCompletedSuccessfully) {
        const cache = new TypeaheadObjectCache("Step", this.getProjectId(), this.getProcessId());
        cache.invalidateCacheOptionsAsync().then(() => {
          cache.loadOptions(() => {
            this.forceUpdateSafely();
          });
        });
      }
    });
  }

  shouldCollapseAllSections() {
    return false;
  }

  stepsFilterFunction(option) {
    return option.id !== this.getAttributeParentIdValue() &&
      (
        option.UnitOperationId
          ? option.UnitOperationId === this.state.UnitOperationId
          : true
      );
  }

  getParentId() {
    return this.state.UnitOperationId || UIUtils.getParameterByName("parentId");
  }

  handleUnitOperationChange(value) {
    if (value != this.state.UnitOperationId) {
      this.setStateSafely({PreviousStepId: null});
    }
  }

  handleUOVerification() {
    let verificationErrors = [];

    if (!this.getParentId()) {
      verificationErrors.push(this.props.t("A Unit Operation (UO) is required"));
    }

    verificationErrors = this.verifyChildAttributesOnParentUOChange(verificationErrors);

    return verificationErrors.length > 0 ? verificationErrors.join("") : null;
  }

  /**
   * Gets the currently selected Unit Operation Ids for the record being edited
   * @returns {Set<*>}
   */
  getCurrentUOIds() {
    const {UnitOperationId} = this.state;
    return new Set(UnitOperationId ? [UnitOperationId] : []);
  }

  /**
   * Finds and returns any Unit Operations that were removed from the record while being edited.
   * @returns {*}
   */
  getRemovedUOs() {
    const {UnitOperationId, originalUOId} = this.state;
    return UnitOperationId !== originalUOId ? [{id: originalUOId}] : [];
  }

  renderAttributes() {
    return (
      <div>
        <Section parent={this}
                 header={this.getGeneralHeader()}
                 collapsible={false}
                 showDocLinks={true}
        >
          <div className="row">
            <TextAttribute name="name"
                           parent={this}
            />
            <TypeaheadAttribute name="process"
                                default={this.getProcessId()}
                                typeaheadType="Process"
                                target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                                instructions={`This can only be modified on add.`}
                                disabled={!this.isAdd()}
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
                                projectId={this.getProjectId()}
                                processId={this.getProcessId()}
            />
          </div>
          <div className="row">
            <TypeaheadAttribute name="unitOperation"
                                default={this.getParentId()}
                                typeaheadType="UnitOperation"
                                target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                                onValidate={this.handleUOVerification}
                                projectId={this.getProjectId()}
                                processId={this.getProcessId()}
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
                                onChangeValue={this.handleUnitOperationChange}
            />
            <TypeaheadAttribute name="previousStep"
                                typeaheadType="Step"
                                options={["Start"]}
                                emptyOption={"Start"}
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
                                relatedRecordId={this.state.UnitOperationId}
                                filter={this.stepsFilterFunction}
            />
          </div>
          <div className="row">
            <TextAreaAttribute name="description"
                               className="col-sm-12"
                               parent={this}
            />
          </div>
        </Section>
        {this.renderTechTransferAssessmentSection()}
        {this.renderTagsSection()}
      </div>
    );
  }
}

export default I18NWrapper.wrap(Step, ["process_explorer", "editor"]);
// i18next-extract-mark-ns-stop process_explorer
