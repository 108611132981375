"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { orderAndIndexUnits } from "../processExplorer/indexers/uo_indexer";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";
import BaseAttributePage from "./base_attribute_page";
import { EDITOR_TYPES } from "./editor_constants";
import MultiTypeTypeaheadAttribute from "./attributes/multi_type_typeahead_attribute";
import BaseObjectCache from "../utils/cache/base_object_cache";
import ImplementationNeededError from "../utils/implementation_needed_error";
import TextAreaAttribute from "./attributes/text_area_attribute";
import MemoryCache from "../utils/cache/memory_cache";

/**
 * This is the base react class for attributes that are linked the Assets (MTs/PRCs) like MAs, and PPs.
 */
// i18next-extract-mark-ns-start process_explorer
export default class BaseAssetAttributePage extends BaseAttributePage {
  constructor(props, baseTypeName, capitalizedBaseTypeName, displayName) {
    super(props, baseTypeName, capitalizedBaseTypeName, displayName);

    // Handle URL params, if provided.
    const parentTypeCodeParam = UIUtils.getParameterByName("parentTypeCode");
    let parentIdParam = UIUtils.getParameterByName("parentId");
    if (parentTypeCodeParam && parentIdParam) {
      parentIdParam = UIUtils.parseInt(parentIdParam);
      switch (parentTypeCodeParam) {
        case "MT":
          this.setStateSafely({MaterialId: parentIdParam});
          break;
        case "PRC":
          this.setStateSafely({ProcessComponentId: parentIdParam});
          break;
        default:
          throw new ImplementationNeededError();
      }
    }
  }

  getTypesToCache() {
    return ["Material", "ProcessComponent", ...super.getTypesToCache()];
  }

  handleUOTypeaheadResultsFromServer(callback, uosFromCache) {
    let orderedUOList = orderAndIndexUnits(uosFromCache);
    const newState = {
      _orderedUOList: orderedUOList,
      _thisUOIndex: orderedUOList.findIndex(uo => uo.id === this.state.UnitOperationId)
    };

    this.setStateSafely(newState, callback);
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (this.state.ProcessComponentId !== prevState.ProcessComponentId
      || this.state.MaterialId !== prevState.MaterialId) {
      this.handleChangeParent(false);
    }
  }

  /**
   * This is called on Save/Propose to verify the UO is valid for the chosen MA/PRC.
   *
   * @return {*} The error to be displayed.
   */
  handleParentVerification() {
    if (!this.getParentId() && !this.state.UnitOperationId) {
      return this.props.t(`Either a Unit Operation or a Material/Process Component is required.`);
    }

    return null;
  }

  handleUOVerification() {
    let returnValue = this.handleParentVerification();

    if (!returnValue && this.getParentId() && !this.state.UnitOperationId) {
      // Make sure the parent MT/PRC doesn't have any UOs specified.
      const parent = this.getParentFromTypeahead();

      if (parent.UnitOperations.length > 0) {
        const uos = this.state._orderedUOList.filter(uo => parent.UnitOperations.includes(uo.id));
        return ReactDOMServer.renderToString(
          <span>
            {this.props.t(`A Unit Operation (UO) is required because ${parent.label} is assigned to one or more UOs:`)}
            <ul>
              {uos.map(uo => (
                <li key={uo.id}>{uo.label}</li>
              ))}
            </ul>
          </span>
        );
      }
    }

    return returnValue;
  }

  handleStepVerification() {
    if (this.state.UnitOperationId) {
      // Make sure the parent can be in the same step.
      const parent = this.getParentFromTypeahead();
      const allSteps = this.getFromTypeaheadCache("Step");
      const selectedUO = this.getUOFromTypeahead();
      const selectedStep = this.getStepFromTypeahead();

      if (this.state.StepId) {
        if (parent && !this.canParentExistInStep(parent, allSteps, selectedUO, selectedStep)) {
          let {Steps} = parent;
          let parentSteps = allSteps.filter(stp => Steps.includes(stp.id));
          return ReactDOMServer.renderToString(
            <span>
            {this.props.t(`The step ${selectedStep.label} cannot be selected because ${parent.label} is only assigned to these steps:`)}
              <ul>
              {parentSteps.map(stp => (
                <li key={stp.id}>{stp.label}</li>
              ))}
            </ul>
          </span>
          );
        }
      } else {
        if (parent && !this.canParentExistInStep(parent, allSteps, selectedUO, null)) {
          let parentSteps = allSteps.filter(stp => parent.Steps.includes(stp.id));
          return ReactDOMServer.renderToString(
            <span>
            {this.props.t(`A Step (STP) is required because ${parent.label} is only assigned to the following steps in ${selectedUO.label}:`)}
              <ul>
              {parentSteps.map(stp => (
                <li key={stp.id}>{stp.label}</li>
              ))}
            </ul>
          </span>
          );
        }
      }
    }
    return null;
  }

  canParentExistInStep(parent, allSteps, selectedUO, selectedStep) {
    const isParentInUO = parent.UnitOperations.includes(selectedUO.id);
    if (isParentInUO) {
      const stepsForUOSet = new Set(allSteps.filter(step => step.UnitOperationId === selectedUO.id).map(step => step.id));
      const isParentInStepsInThisUO = !!parent.Steps.find(stepId => stepsForUOSet.has(stepId));
      if (isParentInStepsInThisUO) {
        return selectedStep && parent.Steps.includes(selectedStep.id);
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  /**
   * Uses the typeahead to lookup the parent (MT/PRC) for this variable.
   * @return {Object} The MT/PRC from the typeahead.
   */
  getParentFromTypeahead() {
    const parentId = this.getParentId();
    if (parentId) {
      const parentModelName = this.getParentModelName();
      const parents = this.getFromTypeaheadCache(parentModelName);
      return parents.find(parent => parent.id === parentId);
    } else {
      return null;
    }
  }

  /**
   * Uses the typeahead to lookup the step that's currently assigned.
   * @return {Object|null} The Step from the typeahead, or null if nothing is defined.
   */
  getStepFromTypeahead() {
    if (this.state.StepId) {
      const steps = this.getFromTypeaheadCache("Step");
      return steps.find(step => step.id === this.state.StepId);
    } else {
      return null;
    }
  }

  /**
   * Uses the typeahead to lookup the Unit Operation that's currently assigned.
   * @return {Object|null} The Unit Operation from the typeahead, or null if nothing is defined.
   */
  getUOFromTypeahead() {
    if (this.state.UnitOperationId) {
      const uos = this.getFromTypeaheadCache("UnitOperation");
      return uos.find(uo => uo.id === this.state.UnitOperationId);
    } else {
      return null;
    }
  }

  getParentModelName() {
    if (this.state.MaterialId) {
      return "Material";
    } else if (this.state.ProcessComponentId) {
      return "ProcessComponent";
    } else {
      return null;
    }
  }

  isParentAMaterial() {
    return !!this.state.MaterialId;
  }

  hasRelatedItemsTab() {
    return true;
  }

  isParentAProcessComponent() {
    return !!this.state.ProcessComponentId;
  }

  isParentUndefined() {
    return !this.state.MaterialId && !this.state.ProcessComponentId;
  }

  /**
   * This is called when the parent (PRC/MT/UO) changes. It's used to constrain typeahead options to just whatever has
   * been chosen.
   *
   * @param isUOChange {boolean} True if this was triggered by a change in the Unit Operation field. False if the MT/PRC
   *    was changed. Don't change the UnitOperationId if this is true because then it will reset what the user typed in
   *    the typeahead.
   * @param [callback] {function} Called after the state has been updated.
   */
  handleChangeParent(isUOChange, callback) {
    let callbackCalled = false;
    if (!this.isView()) {
      if (isUOChange) {
        let materials = this.getParentTypeaheadOptions("Material");
        const processComponents = this.getParentTypeaheadOptions("ProcessComponent");
        if (!BaseObjectCache.isObjectCacheStillLoading(materials) && !BaseObjectCache.isObjectCacheStillLoading(processComponents)) {
          let parents = materials.concat(processComponents);

          if (this.state.UnitOperationId) {
            // Fix the filter
            const parentsToFilterBy = parents.filter(parent => parent.UnitOperations.includes(this.state.UnitOperationId)).map(parent => parent.id);
            const newState = {
              _parentTypeaheadFilterFunction: option => parentsToFilterBy.includes(option.id),
              _thisUOIndex: this.findUnitOperationById(this.state.UnitOperationId),
            };

            // Reset the parent if it doesn't match
            const parentId = this.getParentId();
            const parentModelName = this.getParentModelName();
            if (parentModelName && !parentsToFilterBy.includes(UIUtils.getTypeCodeForModelName(parentModelName) + "-" + parentId)) {
              newState.MaterialId = null;
              newState.ProcessComponentId = null;
            }

            newState.StepId = this.getStepForCurrentUnitOperation(this.state.UnitOperationId, this.state.StepId);

            this.setStateSafely(newState, callback);
          } else {
            this.setStateSafely({
              _parentTypeaheadFilterFunction: null,
              _thisUOIndex: this.findUnitOperationById(this.state.UnitOperationId),
            }, callback);
          }
          callbackCalled = true;
        }
      } else if (!this.state.UnitOperationId) {
        // If there is only one UO for this parent then save the user a click.
        const parent = this.getParentFromTypeahead();
        if (parent && parent.UnitOperations.length === 1) {
          this.setStateSafely({UnitOperationId: parent.UnitOperations[0]}, callback);
          callbackCalled = true;
        }
      }
    }

    if (!callbackCalled && callback) {
      callback();
    }
  }


  handleChangeValue(attributeName, attributeValue, callback, attributeType) {
    if (attributeName === "UnitOperationId" && UIUtils.parseInt(attributeValue) !== UIUtils.parseInt(this.state.UnitOperationId)) {
      // Clear out the StepId as well if the UO changed.
      this.setStateSafely({StepId: null}, () => {
        super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
      });
    } else {
      super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
    }
  }

  getParentTypeaheadOptions(parentModelName) {
    const typeCode = UIUtils.getTypeCodeForModelName(parentModelName);
    const options = this.getFromTypeaheadCache(parentModelName);
    return BaseObjectCache.isObjectCacheStillLoading(options) ? options : options.map(option => {
      const clonedOption = {...option};
      clonedOption.id = typeCode + "-" + option.id;
      return clonedOption;
    });
  }

  getFromTypeaheadCache(type) {
    const memoryCache = MemoryCache.getNamedInstance("base_asset_attribute_page");
    const cacheKey = `${type}_${this.getProjectId()}_${this.getProcessId()}`;

    if (memoryCache.get(cacheKey)) {
      return memoryCache.get(cacheKey);
    }

    const options = new TypeaheadObjectCache(type, this.getProjectId(), this.getProcessId()).getOptionsFromCacheIncludingArchived();
    if (!BaseObjectCache.isObjectCacheStillLoading(options)) {
      memoryCache.set(cacheKey,options);
    }

    return options;
  }

  getStepForCurrentUnitOperation(unitOperationId, stepId) {
    let {_steps} = this.state;
    if (_steps) {
      let steps = _steps.filter(step => step.UnitOperationId === unitOperationId);
      return steps.some(step => step.id === stepId) ? stepId : null;
    } else {
      return stepId;
    }
  }

  getParentId() {
    return this.state.MaterialId || this.state.ProcessComponentId || UIUtils.parseInt(UIUtils.getParameterByName("parentId"));
  }

  renderParentAttribute() {
    return (
      <MultiTypeTypeaheadAttribute displayName="Material / Process Component"
                                   attributeNameToTypeaheadType={{
                                     ProcessComponentId: "ProcessComponent",
                                     MaterialId: "Material",
                                   }}
                                   target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                                   filter={this.state._parentTypeaheadFilterFunction}
                                   instructions={"These options depend on which Unit Operation is selected. Clear the Unit Operation to see all possible options."}
                                   onValidate={this.handleParentVerification}
                                   parent={this}
                                   projectId={this.getProjectId()}
                                   processId={this.getProcessId()}
                                   isLoading={this.state.isLoading}
                                   parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                   parentId={this.state.id}
      />
    );
  }

  renderPotentialFailureModes() {
    return <div className="row">
      <TextAreaAttribute name="potentialFailureModes"
                         className="col-sm-12"
                         parent={this}
      />
    </div>;
  }
}
