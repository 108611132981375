"use strict";

import ImplementationNeededError from "../../utils/implementation_needed_error";
import * as UIUtils from "../../ui_utils";
import * as CommonUtils from "../../../server/common/generic/common_utils";
import { EditablesService } from "../../services/editables/editables_service";
import CommonConstants from "../../../server/common/generic/common_constants";
import ApprovalRequestPopup from "../../editor/approval/approval_request_popup";
import React from "react";
import {
  getApprovalValue,
  handleApprovalChangeValue,
  handleApprovalChangeValues
} from "../../editor/approval/approval_utils";
import BasePage from "../../base_page";
import { Trans } from "react-i18next";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { TABLE_MODE } from "./configurable_tables_helper";
import { createImportLinkURL } from "../../import/helpers/import_url_factory";
import { TYPE_CODE_TO_IMPORT_KEY_MAPPING } from "../../import/constants/import_constants";

/**
 * The base class for all the configurable table pages.
 */
export default class ConfigurableTablePage extends BasePage {
  constructor(props) {
    super(props);

    this.resetState(props);

    this.handleApprovalChangeValue = handleApprovalChangeValue.bind(this);
    this.handleApprovalChangeValues = handleApprovalChangeValues.bind(this);
    this.getApprovalValue = getApprovalValue.bind(this);
  }

  createModalNotifications(record) {
    const {
      t,
      modelName,
    } = this.props;
    const typeCode = UIUtils.getTypeCodeForModelName(modelName);

    this.setStateSafely({
      instance: record,
      message:
        <Trans t={t}>
          Archiving will remove {" "}
          <a href={getURLByTypeCodeAndId(typeCode, "View", record.id)} rel="noopener noreferrer"
             target="_blank"
          >
            <b>{UIUtils.getRecordCustomLabelForDisplay(record)}</b>
          </a>
          {" "} from view, but will not delete it permanently.
        </Trans>,
    });
  }

  loadTableData() {
    throw new ImplementationNeededError();
  }

  // eslint-disable-next-line no-unused-vars
  adaptInstance(instance) {
  }

  getInstanceToPropose() {

    const {
      action,
      instance,
      approvalInfo,
    } = this.state;

    const record = Object.assign({}, instance);
    this.adaptInstance(record);

    return {
      ...record,
      approvalInfo: {...approvalInfo, action},
    };
  }

  hideModal() {
    this.setStateSafely({
      warning: undefined,
      message: undefined,
      showApprovalPopup: false
    });
    $(this.approvalPopup).modal("hide");
  }

  showModal(action) {
    this.setStateSafely({
      action,
      showApprovalPopup: true
    });
  }

  async handleSendApprovalRequest() {

    this.hideModal();
    UIUtils.setLoadingDisabled(false);

    const {
      selectedModelType
    } = this.props;

    const instance = this.getInstanceToPropose();
    UIUtils.setHideLoadingOnAjaxStop(false);

    const parameters = {
      model: selectedModelType,
      global: true,
      urlPrefix: CommonUtils.pluralize(selectedModelType),
      useTwoWayCommunication: true,
    };

    UIUtils.setLoadingDisabled(false);
    UIUtils.setHideLoadingOnAjaxStop(false);
    const editableService = new EditablesService();
    try {
      await editableService.save(instance, parameters);
      await this.loadTableData();
    } catch (error) {
      UIUtils.setHideLoadingOnAjaxStop(true);
      UIUtils.defaultFailFunction(error);
      UIUtils.hideLoadingImage();
    } finally {
      UIUtils.setLoadingDisabled(true);
    }
  }

  // eslint-disable-next-line no-unused-vars
  filterAdaptedRows(rows) {
  }

  // eslint-disable-next-line no-unused-vars
  getAdaptedInfo(records) {
    throw new ImplementationNeededError();
  }

  handleReceivedDataFromServer(records) {
    const adaptedRecords = this.getAdaptedInfo(records);
    const {
      selectedModelType, onVisibleColumnsChange
    } = this.props;
    UIUtils.setHideLoadingOnAjaxStop(true);
    this.setStateSafely({
      records,
      adaptedRecords,
      selectedModelType,
      filteredRows: this.filterAdaptedRows(adaptedRecords),
    }, () => {
      this.columnSelectionHandler.handleResetColumnVisibility(onVisibleColumnsChange);
      UIUtils.hideLoadingImage();
    });
  }

  handleRestoreButton(row) {
    this.setStateSafely({
      instance: row,
    });
    this.showModal(CommonConstants.ACTIONS.RESTORE);
  }

  handleArchiveButton(row) {
    this.createModalNotifications(row);
    this.showModal(CommonConstants.ACTIONS.ARCHIVE);
  }

  resetState(props) {
    this.setStateSafely({
      action: undefined,
      warning: undefined,
      message: undefined,
      instance: undefined,
      showApprovalPopup: false,
      tableMode: TABLE_MODE.VIEW,
      showArchived: props.showArchived,
      selectedModelType: props.selectedModelType,
      securityTypeName: this.getSecurityTypeName(props.selectedModelType),
    });
  }

  renderArchivePopup() {

    const {
      action,
      warning,
      message,
      showApprovalPopup
    } = this.state;

    return (
      showApprovalPopup &&
      <ApprovalRequestPopup modalRef={approvalRequestPopup => this.approvalPopup = approvalRequestPopup}
                            action={action}
                            parent={this}
                            warning={warning}
                            message={message}
                            onHideModal={this.hideModal}
                            onSendApprovalRequest={this.handleSendApprovalRequest}
      />
    );

  }

  handleImport(e) {
    UIUtils.ignoreHandler(e);
    const {projectId, processId, modelName} = this.props;
    const typeCode = UIUtils.getTypeCodeForModelName(modelName);
    const key = TYPE_CODE_TO_IMPORT_KEY_MAPPING[typeCode];

    if (!key) {
      throw new Error("Please Provide a valid model name that can be imported!");
    }

    const config = {
      key,
      projectId,
      processId,
    };

    window.location.href = createImportLinkURL(config);
  }

  handleEmptyTableAddButton(e) {
    UIUtils.ignoreHandler(e);
    const {modelName} = this.props;
    const typeCode = UIUtils.getTypeCodeForModelName(modelName || e.target.innerText);
    const redirectionURL = UIUtils.getSecuredURL(
      getURLByTypeCodeAndId(typeCode, "Add", this.projectId)
    );
    window.open(redirectionURL, "_self");
  }

  getSecurityTypeName(selectedModelName) {
    switch (selectedModelName) {
      case "Target Product Profile":
        return "TPP Section";
      case "Quality Attributes":
        return "FQA";
      case "Performance Attributes":
        return "FPA";
      default:
        return UIUtils.singularize(selectedModelName);
    }
  }

  async handleBulkEdit(e) {
    UIUtils.ignoreHandler(e);
    await this.loadTableData();
  }
}