import React from "react";
import {EditorToolsSettings} from "@progress/kendo-react-editor";
import {ColorPicker, ColorPickerChangeEvent} from "@progress/kendo-react-inputs";
import {ToolProps} from "../common/types";
import {COLOR_PALETTE} from "../common/constants";

/**
 * The editor tool to set background color of a table cell
 */
export default function TableCellColor(
  props: ToolProps
): React.ReactElement {
  const {view} = props;

  const handleChange = (event: ColorPickerChangeEvent) => {
    if (!view) {
      return;
    }

    // Find the nearest table cell of the current selection
    for (let cellNumber = 0 ; cellNumber < view.state.selection.ranges.length; cellNumber++) {
      const cell = view.state.selection.ranges[cellNumber];
      const anchor = cell.$from;
      let targetNode = null;
      let targetNodePos = null;
      let targetParentNode = null;
      for (let d = anchor.depth; d > 0; d--) {
        const node = anchor.node(d);
        if (!targetNode && node.type.name === "table_cell") {
          targetNode = node;
          targetNodePos = anchor.pos - 1;
        }

        if (node.type.name === "table_row") {
          targetParentNode = node;
          break;
        }
      }

      if (!targetNode || !targetParentNode) {
        return;
      }

      let found = false;
      view.state.doc.descendants((node, pos, parent) => {
        // If we found the node, we will stop descending into node. If we don't do this,
        // it will keep descending into another node.
        if (found) {
          return false;
        }

        if (node.eq(targetNode) && targetParentNode.eq(parent)) {
          if (targetNodePos === pos) {
            let newStyle = targetNode.attrs.style || "";
            // If the element style already has background-color, we need to replace it
            // with the new value
            if (newStyle.includes("background-color")) {
              const startIndex = newStyle.indexOf("background-color");
              const endIndex = newStyle.indexOf(";", startIndex);
              const currentBackgroundColorValue = newStyle.substring(startIndex, endIndex + 1);
              newStyle = newStyle.replace(
                currentBackgroundColorValue,
                `background-color: ${event.value};`
              );
            } else {
              newStyle = `${newStyle}background-color: ${event.value};`;
            }
            view.dispatch(view.state.tr.setNodeAttribute(pos, "style", newStyle));
            found = true;
          }
        }
      });
    }
  };

  return (
    <ColorPicker
      {...EditorToolsSettings.backColor.colorPickerProps}
      paletteSettings={{
        palette: COLOR_PALETTE,
      }}
      onChange={handleChange}
      onActiveColorClick={handleChange}
      defaultValue="#FFFFFF"
      title="Table cell background color"
    />
  );
}
