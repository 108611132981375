"use strict";

import React from "react";
import BaseCopyRecordPlugin from "./base_copy_record_plugin";
import * as I18NWrapper from "../../../i18n/i18n_wrapper";
import InfoTooltip from "../../tooltips/info_tooltip";

/**
 * A checkbox control that can be used as a plugin for the copy record popup. This plugin
 * controls whether the Tech Transfer Assessments will be cleared for all child records
 * when copying a Process
 */

// i18next-extract-mark-ns-start widgets
class ResetTechTransferPlugin extends BaseCopyRecordPlugin {
  constructor(props) {
    super(props);

    // Delete all Tech Transfer Assessment Fields by default
    const {attributeToUpdate} = this.props.configProps;
    const {parent} = this.props;

    this.state[attributeToUpdate] = true;
    parent.setStateSafely({
      [attributeToUpdate]: true,
    });
  }

  handleChangeCheckboxValue(event) {
    this.handleChangeValue(event.target.checked);
  }

  render() {
    const {t} = this.props;
    const {
      id,
      label,
      attributeToUpdate,
      infoIconText
    } = this.props.configProps;


    return <div>
      <input type="checkbox"
             id={id}
             checked={!!this.state[attributeToUpdate]}
             onChange={this.handleChangeCheckboxValue}
      />
      <span className={"col-form-label"}>{t(label)}</span>
      <InfoTooltip id={`${id}InfoTooltip`}
                   verbiage={<div>
                     {infoIconText}
                   </div>}
      />
    </div>;
  }

}

export default I18NWrapper.wrap(ResetTechTransferPlugin, "widgets");
// i18next-extract-mark-ns-stop widgets
