var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { GridColumnMenuWrapper } from '../columnMenu/GridColumnMenuWrapper';
/**
 * @example
 * ```jsx
 * class App extends React.PureComponent {
 *    state = {
 *        details: false,
 *        data: [
 *               { foo: 'A1', bar: 'B1', b1: 1, b2:2 },
 *               { foo: 'A2', bar: 'B2', b1: 3, b2:4 },
 *               { foo: 'A3', bar: 'B2', b1: 5, b2:6 }
 *           ]
 *    };
 *
 *    CustomHeaderCell = (props) =>
 *        <span>
 *            {props.title || props.field + ' '}
 *            <button onClick={() => this.setState({ barDetails: !this.state.barDetails })}>
 *                {this.state.barDetails ? 'collapse' : 'expand'}
 *            </button>
 *            {props.children}
 *        </span>
 *
 *    render() {
 *        return (
 *            <Grid
 *                style={{ height: '420px' }}
 *                data={this.state.data}
 *                reorderable={true}
 *            >
 *                <GridColumn field="foo" />
 *                <GridColumn field="bar" headerCell={this.CustomHeaderCell}>
 *                    {this.state.barDetails && [
 *                        <GridColumn field="b1" />,
 *                        <GridColumn field="b2" />
 *                    ]}
 *                </GridColumn>
 *            </Grid>
 *        );
 *    }
 * }
 *
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var GridHeaderCell = /** @class */ (function (_super) {
    __extends(GridHeaderCell, _super);
    function GridHeaderCell() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @hidden
     */
    GridHeaderCell.prototype.render = function () {
        var columnMenuWrapperProps = this.props.columnMenuWrapperProps;
        var clickProps = this.props.onClick ? {
            onClick: this.props.onClick
        } : {};
        var defaultRendering = (React.createElement("span", { className: 'k-cell-inner' },
            React.createElement("span", __assign({ className: 'k-link' }, clickProps),
                React.createElement("span", { className: 'k-column-title' }, this.props.title || this.props.field || '\u00A0'),
                this.props.children),
            columnMenuWrapperProps.columnMenu && React.createElement(GridColumnMenuWrapper, __assign({}, columnMenuWrapperProps))));
        if (this.props.render) {
            return this.props.render.call(undefined, defaultRendering, this.props);
        }
        return defaultRendering;
    };
    return GridHeaderCell;
}(React.Component));
export { GridHeaderCell };
