"use strict";

import { generateDocumentsValue, generateVersionValue } from "../../export/configurable_tables_export_value_generator";
import {
  ABOUT_SECTION,
  LEADING_SECTION, PROPERTIES_SECTION,
  QUALIFICATION_SECTION,
  FORMULATION_QUANTITY_SECTION, REFERENCES_SECTION, REGULATORY_SECTION
} from "../constants/configurable_tables_sections_config";
import {
  COMMON_VARIABLE_PARENT_COLUMNS,
  RECEIVING_SITE_COMMON_COLUMNS_PROPS,
  SENDING_SITE_COMMON_COLUMNS_PROPS, TECH_TRANSFER_ASSESSMENT_COLUMNS
} from "../constants/configurable_tables_columns_config";
import { FIELD_PROPS } from "../constants/configurable_tables_field_props_config";
import { FILTER_TYPES } from "../../columnOperations/configurable_tables_column_operations_constants";
import RecordFields from "../record_fields";
import {
  ABOUT_COL_SPAN, PROPERTIES_COL_SPAN,
  QUALIFICATION_COL_SPAN,
  FORMULATION_QUANTITY_COL_SPAN, REFERENCE_AND_STANDARD_COL_SPAN, REGULATORY_COL_SPAN
} from "../constants/configurable_tables_col_spans_config";
import CommonEditables from "../../../../server/common/editables/common_editables";

/**
 * This class lists all Material fields to be shown in export, column selection and tables.
 */
export default class MaterialFields extends RecordFields {

  get title() {
    return "Materials";
  }

  get fields() {
    return super.filterTechTransferFields([
      {
        title: "Category / Use",
        prop: FIELD_PROPS.CATEGORY_USE,
        section: LEADING_SECTION,
        defaultTechTransferValue: true,
        isCompositeField: true,
        value: (record) => record.typeCode === "MT" ? `${record.category || "None"} / ${record.use || "None"}` : "",
        tableValue: (columnGenerator, process) => columnGenerator.generateCategoryUseColumn(process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Category / Use",
        prop: FIELD_PROPS.CATEGORY_USE,
        value: (record) => record.typeCode === "MT" ? `${record.category || "None"} / ${record.use || "None"}` : "",
        tableValue: (columnGenerator, process) => columnGenerator.generateCategoryUseColumn(process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      ...COMMON_VARIABLE_PARENT_COLUMNS,
      // About
      {
        title: "Name",
        prop: FIELD_PROPS.NAME,
        section: ABOUT_SECTION,
        colspan: ABOUT_COL_SPAN,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) => columnGenerator.generateNameColumn("Name", FIELD_PROPS.NAME, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Name",
        prop: FIELD_PROPS.NAME,
        colspan: ABOUT_COL_SPAN,
        tableValue: (columnGenerator, process) => columnGenerator.generateNameColumn("Name", FIELD_PROPS.NAME, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Version",
        prop: FIELD_PROPS.VERSION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateVersionValue,
        tableValue: (columnGenerator, process) => columnGenerator.generateVersionColumn("Version", process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Version",
        prop: FIELD_PROPS.VERSION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        value: generateVersionValue,
        tableValue: (columnGenerator, process) => columnGenerator.generateVersionColumn("Version", process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Function",
        prop: FIELD_PROPS.FUNCTION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        defaultTechTransferValue: true,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextColumn(
          "Function", FIELD_PROPS.FUNCTION, process
        ),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Function",
        prop: FIELD_PROPS.FUNCTION,
        colspan: ABOUT_COL_SPAN,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextColumn(
          "Function", FIELD_PROPS.FUNCTION, process, true
        ),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Use",
        prop: FIELD_PROPS.USE,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Use",
        prop: FIELD_PROPS.USE,
        colspan: ABOUT_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Category",
        prop: FIELD_PROPS.CATEGORY,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Category",
        prop: FIELD_PROPS.CATEGORY,
        colspan: ABOUT_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Unit Operations",
        prop: FIELD_PROPS.UNIT_OPERATIONS,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        sortByOrder: true,
        filterType: FILTER_TYPES.LOOKUP,
        value: (record) => record[FIELD_PROPS.UNIT_OPERATION_NAME],
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateParentColumn("Unit Operations", FIELD_PROPS.UNIT_OPERATION, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Unit Operations",
        prop: FIELD_PROPS.UNIT_OPERATIONS,
        colspan: ABOUT_COL_SPAN,
        sortByOrder: true,
        filterType: FILTER_TYPES.LOOKUP,
        value: (record) => record[FIELD_PROPS.UNIT_OPERATION_NAME],
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateParentColumn("Unit Operations", FIELD_PROPS.UNIT_OPERATION, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Steps",
        prop: FIELD_PROPS.STEPS,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        sortByOrder: true,
        filterType: FILTER_TYPES.LOOKUP,
        value: (record) => record[FIELD_PROPS.STEP_NAME],
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateParentColumn("Steps", FIELD_PROPS.STEP, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Steps",
        prop: FIELD_PROPS.STEPS,
        colspan: ABOUT_COL_SPAN,
        sortByOrder: true,
        filterType: FILTER_TYPES.LOOKUP,
        value: (record) => record[FIELD_PROPS.STEP_NAME],
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateParentColumn("Steps", FIELD_PROPS.STEP, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Description",
        prop: FIELD_PROPS.DESCRIPTION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextAreaColumn("Description", FIELD_PROPS.DESCRIPTION, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Description",
        prop: FIELD_PROPS.DESCRIPTION,
        colspan: ABOUT_COL_SPAN,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextAreaColumn("Description", FIELD_PROPS.DESCRIPTION, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Source Documents",
        prop: FIELD_PROPS.LINKS,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) => columnGenerator.generateDocumentsColumn("Source Documents", process, FIELD_PROPS.LINKS),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Source Documents",
        colspan: ABOUT_COL_SPAN,
        prop: FIELD_PROPS.LINKS,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) => columnGenerator.generateDocumentsColumn("Source Documents", process, FIELD_PROPS.LINKS, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      // Formulation Quantity
      {
        title: "Descriptive Unit (Absolute)",
        prop: FIELD_PROPS.DESCRIPTIVE_UNIT_ABSOLUTE,
        colspan: FORMULATION_QUANTITY_COL_SPAN,
        section: FORMULATION_QUANTITY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Descriptive Unit (Absolute)",
        prop: FIELD_PROPS.DESCRIPTIVE_UNIT_ABSOLUTE,
        colspan: FORMULATION_QUANTITY_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Quantity per Batch (Absolute)",
        prop: FIELD_PROPS.QUANTITY_ABSOLUTE,
        colspan: FORMULATION_QUANTITY_COL_SPAN,
        section: FORMULATION_QUANTITY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.NUMERIC,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Quantity per Batch (Absolute)",
        prop: FIELD_PROPS.QUANTITY_ABSOLUTE,
        colspan: FORMULATION_QUANTITY_COL_SPAN,
        filterType: FILTER_TYPES.NUMERIC,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Quantity (Relative %)",
        prop: FIELD_PROPS.QUANTITY_RELATIVE,
        colspan: FORMULATION_QUANTITY_COL_SPAN,
        section: FORMULATION_QUANTITY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.NUMERIC,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Quantity (Relative %)",
        prop: FIELD_PROPS.QUANTITY_RELATIVE,
        colspan: FORMULATION_QUANTITY_COL_SPAN,
        filterType: FILTER_TYPES.NUMERIC,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Quantity per Dose (Absolute)",
        prop: FIELD_PROPS.QUANTITY_PER_DOSE,
        colspan: FORMULATION_QUANTITY_COL_SPAN,
        section: FORMULATION_QUANTITY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.NUMERIC,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Quantity per Dose (Absolute)",
        prop: FIELD_PROPS.QUANTITY_PER_DOSE,
        colspan: FORMULATION_QUANTITY_COL_SPAN,
        filterType: FILTER_TYPES.NUMERIC,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Formulation Quantity Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.FORMULATION_QUANTITY_LINKS,
        colspan: FORMULATION_QUANTITY_COL_SPAN,
        section: FORMULATION_QUANTITY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.FORMULATION_QUANTITY_LINKS),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Formulation Quantity Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.FORMULATION_QUANTITY_LINKS,
        colspan: FORMULATION_QUANTITY_COL_SPAN,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.FORMULATION_QUANTITY_LINKS, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      // Qualification
      {
        title: "Supplier",
        prop: FIELD_PROPS.SUPPLIER,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Supplier",
        prop: FIELD_PROPS.SUPPLIER,
        colspan: QUALIFICATION_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Material/Part Qualified",
        prop: FIELD_PROPS.MATERIAL_QUALIFIED,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("Material/Part Qualified", FIELD_PROPS.MATERIAL_QUALIFIED)
      },
      {
        title: "Material/Part Qualified",
        prop: FIELD_PROPS.MATERIAL_QUALIFIED,
        colspan: QUALIFICATION_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("Material/Part Qualified", FIELD_PROPS.MATERIAL_QUALIFIED)
      },
      {
        title: "GMP",
        prop: FIELD_PROPS.GMP,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "GMP",
        prop: FIELD_PROPS.GMP,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Supplier Part Number",
        prop: FIELD_PROPS.PART_NUMBER,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Supplier Part Number",
        prop: FIELD_PROPS.PART_NUMBER,
        colspan: QUALIFICATION_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Internal Part Number",
        prop: FIELD_PROPS.INTERNAL_PART_NUMBER,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Internal Part Number",
        prop: FIELD_PROPS.INTERNAL_PART_NUMBER,
        colspan: QUALIFICATION_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Effective Date",
        prop: FIELD_PROPS.EFFECTIVE_DATE,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        filterType: FILTER_TYPES.DATE,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Effective Date",
        prop: FIELD_PROPS.EFFECTIVE_DATE,
        colspan: QUALIFICATION_COL_SPAN,
        filterType: FILTER_TYPES.DATE,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Expiration Date",
        prop: FIELD_PROPS.EXPIRATION_DATE,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        filterType: FILTER_TYPES.DATE,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Expiration Date",
        prop: FIELD_PROPS.EXPIRATION_DATE,
        colspan: QUALIFICATION_COL_SPAN,
        filterType: FILTER_TYPES.DATE,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Qualification Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.QUALIFICATION_LINKS,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.QUALIFICATION_LINKS),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Qualification Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.QUALIFICATION_LINKS,
        colspan: QUALIFICATION_COL_SPAN,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.QUALIFICATION_LINKS, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      // Regulatory
      {
        title: "Regulatory Filing",
        prop: FIELD_PROPS.REGULATORY_FILING,
        colspan: REGULATORY_COL_SPAN,
        section: REGULATORY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Regulatory Filing",
        prop: FIELD_PROPS.REGULATORY_FILING,
        colspan: REGULATORY_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Reference Number",
        prop: FIELD_PROPS.REFERENCE_NUMBER,
        colspan: REGULATORY_COL_SPAN,
        section: REGULATORY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Reference Number",
        prop: FIELD_PROPS.REFERENCE_NUMBER,
        colspan: REGULATORY_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Letter of Authorization",
        prop: FIELD_PROPS.AUTHORIZATION_LETTER,
        colspan: REGULATORY_COL_SPAN,
        section: REGULATORY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Letter of Authorization",
        prop: FIELD_PROPS.AUTHORIZATION_LETTER,
        colspan: REGULATORY_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Regulatory Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.REGULATORY_LINKS,
        colspan: REGULATORY_COL_SPAN,
        section: REGULATORY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.REGULATORY_LINKS),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Regulatory Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.REGULATORY_LINKS,
        colspan: REGULATORY_COL_SPAN,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.REGULATORY_LINKS, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      // Properties
      {
        title: "Drug Substance Type",
        prop: FIELD_PROPS.DRUG_SUBSTANCE_TYPE,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Drug Substance Type",
        prop: FIELD_PROPS.DRUG_SUBSTANCE_TYPE,
        filterType: FILTER_TYPES.COMBO_BOX,
        colspan: PROPERTIES_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Form",
        prop: FIELD_PROPS.FORM,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Form",
        prop: FIELD_PROPS.FORM,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Density",
        prop: FIELD_PROPS.DENSITY,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Density",
        prop: FIELD_PROPS.DENSITY,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Density Conditions",
        prop: FIELD_PROPS.DENSITY_CONDITIONS,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Density Conditions",
        prop: FIELD_PROPS.DENSITY_CONDITIONS,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Chemical Structure",
        prop: FIELD_PROPS.CHEMICAL_STRUCTURE,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Chemical Structure",
        prop: FIELD_PROPS.CHEMICAL_STRUCTURE,
        colspan: PROPERTIES_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Empirical Formula",
        prop: FIELD_PROPS.EMPIRICAL_FORMULA,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Empirical Formula",
        prop: FIELD_PROPS.EMPIRICAL_FORMULA,
        colspan: PROPERTIES_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Molecular Weight",
        prop: FIELD_PROPS.MOLECULAR_WEIGHT,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.NUMERIC,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Molecular Weight",
        prop: FIELD_PROPS.MOLECULAR_WEIGHT,
        colspan: PROPERTIES_COL_SPAN,
        filterType: FILTER_TYPES.NUMERIC,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Chemical Name (CAS)",
        prop: FIELD_PROPS.CHEMICAL_NAME_CAS,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Chemical Name (CAS)",
        prop: FIELD_PROPS.CHEMICAL_NAME_CAS,
        colspan: PROPERTIES_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Chemical Name (IUPAC)",
        prop: FIELD_PROPS.CHEMICAL_NAME_IUPAC,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Chemical Name (IUPAC)",
        prop: FIELD_PROPS.CHEMICAL_NAME_IUPAC,
        colspan: PROPERTIES_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Other Names",
        prop: FIELD_PROPS.OTHER_NAMES,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Other Names",
        prop: FIELD_PROPS.OTHER_NAMES,
        colspan: PROPERTIES_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "INN/USAN",
        prop: FIELD_PROPS.INN_USAN,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "INN/USAN",
        prop: FIELD_PROPS.INN_USAN,
        colspan: PROPERTIES_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "CAS Registry Number",
        prop: FIELD_PROPS.CAS_REGISTRY_NUMBER,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "CAS Registry Number",
        prop: FIELD_PROPS.CAS_REGISTRY_NUMBER,
        colspan: PROPERTIES_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Compendial Standard",
        prop: FIELD_PROPS.STANDARD_GRADE,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
        tableValue: (columnGenerator) => columnGenerator.generateMultiSelectionColumn("Compendial Standard", FIELD_PROPS.STANDARD_GRADE, false),

      },
      {
        title: "Compendial Standard",
        prop: FIELD_PROPS.COMPENDIAL_STANDARD,
        colspan: PROPERTIES_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
        tableValue: (columnGenerator) => columnGenerator.generateMultiSelectionColumn("Compendial Standard", FIELD_PROPS.STANDARD_GRADE, false, true),
      },
      {
        title: "COA/COC",
        prop: FIELD_PROPS.CERTIFICATE_OF_ANALYSIS,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
        filterOptions: () => CommonEditables.BOOLEAN_VALUES,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("COA/COC", FIELD_PROPS.CERTIFICATE_OF_ANALYSIS)
      },
      {
        title: "COA/COC",
        prop: FIELD_PROPS.CERTIFICATE_OF_ANALYSIS,
        colspan: PROPERTIES_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
        filterOptions: () => CommonEditables.BOOLEAN_VALUES,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("COA/COC", FIELD_PROPS.CERTIFICATE_OF_ANALYSIS, true)
      },
      {
        title: "Properties Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.PROPERTIES_LINKS,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.PROPERTIES_LINKS),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Properties Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.PROPERTIES_LINKS,
        colspan: PROPERTIES_COL_SPAN,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.PROPERTIES_LINKS, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      // References & Standards
      {
        title: `References & Standards`,
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.REFERENCES,
        colspan: REFERENCE_AND_STANDARD_COL_SPAN,
        section: REFERENCES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.REFERENCES),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: `References & Standards`,
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.REFERENCES,
        colspan: REFERENCE_AND_STANDARD_COL_SPAN,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.REFERENCES, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      ...TECH_TRANSFER_ASSESSMENT_COLUMNS,
    ]);
  }
}
