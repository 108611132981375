"use strict";

import * as UIUtils from "../ui_utils";
import * as CommonUtils from "../../server/common/generic/common_utils";
import { RetryPleaseError, RetryWrapper } from "./retry_wrapper";
import { DO_NOTHING } from "../dashboard/constants/bulk_operations_constants";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Attachments, "DocumentTransferHelper");

/**
 * The functions in this file are responsible for helping with downloading documents.
 */

export const FILE_STATUS = {
  NOT_SPECIFIED: "Not specified",
  NEW_FILE_SELECTED: "New file selected",
  UPLOADING: "Uploading",
  UPLOADED: "Uploaded",
};

/**
 * Handle a user trying to upload a file.
 *
 * @param eventOrFile The UI event for the button that opens the file picker or the actual file from the file picker.
 * @param fileData The standard object we use to store file data, that contains attributes like fileName, S3TmpKey, etc.
 * @param progressBarSelector The CSS selector for the progress bar to update.
 * @param clearErrorFunction A function that should be called when errors should be cleared in the UI.
 * @param setErrorFunction A function that should be called when errors should be shown.
 * @param forceUpdateFunction A function that should be called when an event has happened and the UI should be updated.
 * @param updateFileData A function that should be called when the fileData is modified.
 * @param validateFileData A function that verifies the file data.  If it returns false, then the file upload is never started.
 */
module.exports.handleUpload = function(eventOrFile, fileData, progressBarSelector, clearErrorFunction, setErrorFunction,
                                       forceUpdateFunction, updateFileData = DO_NOTHING, validateFileData = () => true) {
  let isFileValid = false;
  let input = eventOrFile && eventOrFile.target ? $("#" + eventOrFile.target.id)[0] : {files: [eventOrFile]};

  if (input.files && input.files.length > 0 && input.files[0]) {
    let file = input.files[0];
    fileData.linkType = "Attachment";
    fileData.fileName = file.name;
    fileData.size = UIUtils.formatBytes(file.size);
    fileData.lastModified = file.lastModified;
    isFileValid = validateFileData(fileData);

    if (isFileValid) {

      fileData.fileStatus = FILE_STATUS.UPLOADING;
      fileData.progress = "30";
      setProgressBar(progressBarSelector, "30");
      clearErrorFunction(true);

      UIUtils.secureAjaxGET("s3/preSignedURL", {
        environment: CommonUtils.getRemoteEnvironment(),
        payload: JSON.stringify(fileData),
        operation: "put",
      }, false).done((url) => {

        exports.upload(fileData, url.url, file, progressBarSelector, clearErrorFunction, setErrorFunction,
          forceUpdateFunction, updateFileData);

        clearErrorFunction(true);
      });
    }
  }

  if (eventOrFile && eventOrFile.target) {
    eventOrFile.target.value = null;
  }

  return isFileValid;
};

function handleError(errorMessage, setErrorFunction) {
  console.error(errorMessage);
  setErrorFunction(errorMessage);
}

/**
 * Uploads a file to AWS using a pre-signed S3 URL
 *
 * @param fileData The standard object we use to store file data, that contains attributes like fileName, S3TmpKey, etc.
 * @param url The S3 pre-signed URL to use for uploading the attachment
 * @param file The actual file that was picked by the file picker from the local file system
 * @param progressBarSelector The CSS selector for the progress bar to update.
 * @param clearErrorFunction A function that should be called when errors should be cleared in the UI.
 * @param setErrorFunction A function that should be called when errors should be shown.
 * @param forceUpdateFunction A function that should be called when an event has happened and the UI should be updated.
 * @param updateFileData A function that should be called when the fileData is modified.
 */
module.exports.upload = function(fileData, url, file, progressBarSelector, clearErrorFunction, setErrorFunction,
                                 forceUpdateFunction, updateFileData = DO_NOTHING) {

  // Set the default error function, if needed.
  if (typeof setErrorFunction !== "function") {
    setErrorFunction = UIUtils.showError;
  }

  return new RetryWrapper(
    () => {
      return new Promise((resolve, reject) => {
        fileData.progress = "30";

        let xhr = new XMLHttpRequest();
        fileData.xhr = xhr;

        xhr.onerror = (e) => {
          UIUtils.showError("Error on uploading.", e);
          // handle failure
          fileData.fileStatus = FILE_STATUS.NOT_SPECIFIED;
          fileData.S3TmpKey = "";
          fileData.S3TmpVersion = "";
          updateFileData(fileData);
          clearErrorFunction(true);
          reject();
        };

        xhr.upload.addEventListener("progress", e => {
          // handle notifications about upload progress: e.loaded / e.total
          fileData.progress = (100 * (e.loaded / e.total) * 0.7 + 30).toFixed(0).toString();
          setProgressBar(progressBarSelector, fileData.progress);
        }, false);

        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status >= 200 && xhr.status <= 299) {
              // New file uploaded, wipe out the old prod links
              fileData.link = "";
              fileData.linkVersion = "";
              fileData.fileStatus = FILE_STATUS.UPLOADED;

              clearErrorFunction(false);
            } else if (xhr.status !== 0) {
              // failed with error message from server
              fileData.S3TmpKey = "";
              fileData.S3TmpVersion = "";
              fileData.fileStatus = FILE_STATUS.NOT_SPECIFIED;

              const errorMessage = "Upload cannot be completed. Upload failed with code: " + xhr.status;
              if (UIUtils.parseInt(xhr.status) === 500) {
                throw RetryPleaseError(errorMessage);
              }
              handleError(errorMessage, setErrorFunction);
            } else if (xhr.status === 0) {
              // The request was cancelled.
              fileData.fileStatus = FILE_STATUS.NOT_SPECIFIED;
              fileData.S3TmpKey = "";
              fileData.S3TmpVersion = "";
              fileData.linkType = "";
              fileData.fileName = "";
            }

            forceUpdateFunction();
            resolve();
          } else if (xhr.readyState === XMLHttpRequest.HEADERS_RECEIVED) {
            fileData.S3TmpVersion = xhr.getResponseHeader("x-amz-version-id");
            //Regular Expression retrieved from Appendix B: of http://www.ietf.org/rfc/rfc3986.txt
            let pattern = RegExp("^(([^:/?#]+):)?(//([^/?#]*))?([^?#]*)(\\?([^#]*))?(#(.*))?");
            fileData.S3TmpKey = decodeURIComponent(xhr.responseURL.match(pattern)[5]);
            if (fileData.S3TmpKey.charAt(0) === "/") {
              fileData.S3TmpKey = fileData.S3TmpKey.slice(1);
            }
          }

          updateFileData(fileData, file);
        };

        xhr.open("PUT", url, true);
        xhr.setRequestHeader("x-amz-server-side-encryption", "AES256");
        xhr.setRequestHeader("Content-type", "application/octet-stream");
        xhr.send(file);
        fileData.fileStatus = FILE_STATUS.UPLOADING;

        updateFileData(fileData);
      });
    },
    undefined,
    retryPleaseError => handleError(retryPleaseError.message, setErrorFunction),
  ).retryFunction();
};

/**
 * This sets the progress on the file upload progress bar
 * @param progressBarSelector The selector to be used for finding the progress bar in the document.
 * @param progress The progress to set the progress bar to. This is expressed in %.
 */
function setProgressBar(progressBarSelector, progress) {
  let uploadProgressBar = $(progressBarSelector);
  if (uploadProgressBar && uploadProgressBar[0]) {
    uploadProgressBar.css("width", progress + "%");
    uploadProgressBar.css("color", Number(progress) < 20 ? "#BBB" : "#fff");
    uploadProgressBar[0].innerHTML = progress + "%";
    uploadProgressBar.attr("aria-valuenow", progress);
  }
}

/**
 * Retrieves a pre-signed URL for a file in S3 identified by the specified data.
 *
 * @param fileData The standard object we use to store file data, that contains attributes like fileName, S3TmpKey, etc.
 * @param throwOnError {boolean} If true, it will reject the promise instead of showing the error in the UI (the default behavior)
 */
module.exports.getPreSignedURL = function(fileData, throwOnError = false) {
  const clonedFileData = {...fileData};
  // We saved smartContent in fileData to check whether the underlying data has changed or not
  // We need to remove the smartContent before downloading since it will make the getPreSignedURL
  // way too long and that will cause an error.
  if (clonedFileData.smartContent) {
    delete clonedFileData.smartContent;
  }
  return new Promise((resolve, reject) => {
    UIUtils.secureAjaxGET(
      "s3/preSignedURL",
      {
        environment: CommonUtils.getRemoteEnvironment(),
        payload: JSON.stringify(fileData),
        operation: "get",
      },
      false,
      throwOnError ? (error) => {
        Logger.error("An error happened while getting the pre-signed URL: ", Log.error(error));
        const errorObj = error && (error.responseJSON || {message: `Unexpected error: ${error.responseText}`});
        const message = errorObj && errorObj.message;
        const newError = new Error(message || "Unexpected error.");
        for (let [prop, value] of Object.entries(errorObj)) {
          newError[prop] = value;
        }
        reject(newError);
      } : undefined,
    ).done(url => {
      resolve(url);
    });
  });
};

/**
 * Handle downloading a file
 *
 * @param fileData The standard object we use to store file data, that contains attributes like fileName, S3TmpKey, etc.
 */
module.exports.handleFileDownload = async function(fileData) {
  let url = await exports.getPreSignedURL(fileData);

  // Code Purpose: Test Validation Strategy
  //
  // Breakage Scenario 3.1: This test will be broken by providing wrong attachment download URL.
  //
  // DANGEROUS: Uncomment this to break the attachment download feature intentionally.
  // url.url = "https://www.invalidattachmenturl.com";

  return module.exports.download(fileData.fileName, url.url);
};

module.exports.downloadAsBuffer = async function(fileData, options = {mimeType: "application/octet-stream"}) {
  let urlInfo = await exports.getPreSignedURL(fileData, true);
  const requestParams = {
    method: "GET",
    cache: "force-cache",
    mode: "cors",
    headers: {
      "Content-Type": options.mimeType,
    },
  };
  Logger.info("Fetching file: ", Log.object(fileData));
  // jQuery ajax will always cause the result to be converted to string and that corrupts the PDF document
  // thus, we use the browser's native fetch API directly.
  let response = await fetch(urlInfo.url, requestParams);
  Logger.info("Fetch complete:", Log.object(response.status), Log.object(response.statusText));
  const buffer = await response.arrayBuffer();
  return {data: buffer};
};

module.exports.downloadAsDataUrl = async function(fileData, options = {mimeType: "application/octet-stream"}) {
  const downloadResult = await exports.downloadAsBuffer(fileData);
  const array = new Uint8Array(downloadResult.data);

  const data = array.reduce((accumulator, byte) => accumulator + String.fromCharCode(byte), "");
  const result = `data:${options.mimeType};base64,${btoa(data)}`;
  return {url: result};
};

/**
 * Downloads an attachment initializing a global download anchor and simulating a click on it
 *
 * @param fileName The standard object we use to store file data, that contains attributes like fileName, S3TmpKey, etc.
 * @param url The URL where the data is stored.
 */
module.exports.download = function(fileName, url) {
  let link = $("#global_download_anchor");
  if (link.length === 0) {
    link = document.createElement("a");
    link.id = "global_download_anchor";
    document.body.appendChild(link);
  } else {
    link = link[0];
  }

  link.download = fileName;
  link.href = url;

  let beforeUnloadEventExists = !!$._data(window, "events").beforeunload;
  if (beforeUnloadEventExists) {
    UIUtils.clearPreventNavigationForBrowser();
  }
  link.click();
  if (beforeUnloadEventExists) {
    UIUtils.preventNavigationForBrowser();
  }
};
