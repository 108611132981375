"use strict";

import React from "react";
import CommonSecurity from "../../../server/common/generic/common_security";
import LibraryMaterialFields from "../../configurableTables/fieldsConfig/assets/library_material_fields";
import { MaterialLibraryTable } from "./material_library_table";
import MaterialLibraryTableAdapter from "./material_library_table_adapter";
import ConfigurableTablesCustomFiltersHandler
  from "../../configurableTables/columnOperations/configurable_tables_custom_filters_handler";

export const LIBRARY_MODELS_CONFIG = {
  ["Library Materials"]: {
    table: (props) => <MaterialLibraryTable {...props} />,
    adapter: (params) => new MaterialLibraryTableAdapter(params, "mtlMap", "LibraryMaterials"),
    customFiltersHandler: new ConfigurableTablesCustomFiltersHandler(),
    fieldsConfig: new LibraryMaterialFields(),
    modelName: "LibraryMaterial",
    securityModelType: CommonSecurity.Types.LIBRARY_MATERIAL
  },
};
