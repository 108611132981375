"use strict";

import * as styles from "./side_menu.module.scss";
import React, {useEffect, useState} from "react";
import TabNavBar from "../../../widgets/bars/tab_nav_bar";
import DirectScopeSmartContentTree from "./smartContent/direct_scope_smart_content_tree";
import * as DirectScopeService from "../../services/direct_scope_service";
import {DirectScopeData, DirectScopeRecord} from "../../services/direct_scope_service";
import useDirectScopeRecordsCache from "../../hooks/use_direct_scope_records_cache";
import {EditablesService} from "../../../services/editables/editables_service";
import {EditorView, Node} from "@progress/kendo-editor-common";
import {Project} from "../../../common/models/project";
import {EditorUtils} from "@progress/kendo-react-editor";
import * as UIUtils from "../../../ui_utils";
import {DocumentRecord} from "../../../common/models/document";
import {RMP} from "../../../common/models/rmp";
import Widget from "./widget/widget";
import {NON_EDITABLE_QBD_FIELD_NODE} from "../../common/editorSchemas/qbd_field_node";
import {isInWidget} from "../../common/editorSchemas/widget_node";
import {FIELD_KEY} from "../../common/editables_map";
import {WIDGET_KIND} from "./widget/widget_constants";

const MENU_TABS = {
  SMART_CONTENT: {
    title: "Smart Content",
  },
  WIDGETS: {
    title: "Widgets",
  },
};

type MenuTabItem = (typeof MENU_TABS)[keyof typeof MENU_TABS];

type SideMenuProps = {
  editorView?: EditorView;
  project: Project;
  documentRecord?: DocumentRecord;
  rmp: RMP;
  record?: any;
  // eslint-disable-next-line no-unused-vars
  onAddRecord?: (record: any) => void;
};

export default function SideMenu(props: SideMenuProps): React.ReactElement {
  const {editorView, project, documentRecord, rmp, record, onAddRecord} = props;
  const [directScopeData, setDirectScopeData] = useState<DirectScopeData>({
    typeCodeToRecords: {},
    processExplorerData: null,
  });
  const [selectedTab, setSelectedTab] = useState<MenuTabItem>(
    MENU_TABS.SMART_CONTENT,
  );
  const [previousSelectedTab, setPreviousSelectedTab] = useState<MenuTabItem>(
    null,
  );
  const [selectedRecord, setSelectedRecord] = useState<DirectScopeRecord>(null);
  const [selectedRecordData, setSelectedRecordData] = useState(null);
  const [cache, saveCache] = useDirectScopeRecordsCache();

  useEffect(() => {
    if (record) {
      setSelectedRecord(record);
      setSelectedRecordData(record);
    }
  }, [record]);

  useEffect(() => {
    // If there is a selected record, we don't do anything since we are
    // already in the place that we should be
    if (selectedRecord || !editorView) {
      return;
    }

    // When we toggle the side menu, we should switch to the Widgets tab
    // if we are already in a widget. We only switch to Smart Content tab
    // if the previous selected tab is Smart Content. Otherwise, we don't
    // do anything
    let tab = MENU_TABS.SMART_CONTENT;
    if (
      isInWidget(editorView) &&
      !isInWidget(editorView, WIDGET_KIND.Header) &&
      !isInWidget(editorView, WIDGET_KIND.Footer)
    ) {
      setSelectedTab(MENU_TABS.WIDGETS);
      tab = MENU_TABS.WIDGETS;
    } else if (previousSelectedTab === MENU_TABS.SMART_CONTENT) {
      setSelectedTab(MENU_TABS.SMART_CONTENT);
    }

    // We only set the previousSelectedTab when it is null that means
    // we opened the side menu for the first time
    if (!previousSelectedTab) {
      setPreviousSelectedTab(tab);
    }
  }, [editorView?.state?.selection?.$anchor?.pos]);

  const resetState = () => {
    setSelectedRecord(null);
    setSelectedRecordData(null);
  };

  const handleTabChanged = (tab: MenuTabItem) => {
    setPreviousSelectedTab(tab);
    setSelectedTab(tab);
    resetState();
  };

  const handleOnBack = () => {
    resetState();
  };

  const handleSelectedRecord = async (record: DirectScopeRecord) => {
    if (cache[record.modelName] && cache[record.modelName][record.id]) {
      setSelectedRecordData(cache[record.modelName][record.id]);
      setSelectedRecord(record);
      return;
    }

    setSelectedRecord(record);
    UIUtils.setLoadingDisabled(true);
    const editablesService = new EditablesService();
    const recordData = await editablesService.get({
      urlPrefix: `editables/${record.modelName}`,
      action: record.id,
      urlParameters: {approved: false},
    });
    UIUtils.setLoadingDisabled(false);
    saveCache(recordData);
    setSelectedRecordData(recordData);
  };

  const getRecordPath = (
    record: DirectScopeRecord,
    field: any,
  ): Array<string> => {
    const path = [];
    if (!record.key) {
      path.push(`${record.typeCode}[name='${record.name}']`);
    } else {
      const match = record.key?.match(/(\w+-\d+)+/g);
      if (match) {
        for (let i = 0; i < match.length; i++) {
          for (const node of directScopeData.processExplorerData.nodes) {
            if (node.staticPanelKey === match[i]) {
              const {typeCode} = UIUtils.parseKey(node.staticPanelKey);
              path.push(`${typeCode}[name='${node.fullName}']`);
            }
          }
        }
      }
    }

    path.push(field.name);
    return path;
  };

  const handleOnAddDirectScope = (record: DirectScopeRecord, field: any) => {
    if (onAddRecord) {
      onAddRecord(field);
    }

    if (!editorView) {
      return;
    }

    const {schema} = editorView.state;
    const nodeType = schema.nodes[NON_EDITABLE_QBD_FIELD_NODE.name];

    let node: Node;
    if (field.key === FIELD_KEY.DocBuilderFields) {
      node = nodeType.createAndFill(
        {
          class: `qbd-output ${isInWidget(editorView) ? "qbd-output-widget" : ""}`,
        },
        schema.text(field.value),
      );
    } else {
      const path = getRecordPath(record, field);
      node = nodeType.createAndFill(
        {
          class: `qbd-output qbd-output-direct-scope-widget ${
            field.actualValue.element ? field.actualValue.element.className : ""
          }`,
          "data-record-path": JSON.stringify(path),
          "data-record-id": record.id,
          "data-record-model-name": record.modelName,
          "data-record-column-name": field.name,
          "data-record-sub-model-name": field.subModelName,
          "data-record-sub-model-data": field.subModelData,
        },
        // If it has an element, it means it is color element for risk color. We couldn't find
        // a way to create a node from the html. This is a hacking solution to make it show the
        // small color square
        field.actualValue.element
          ? schema.text(" ")
          : schema.text(`${field.actualValue.content || " "}`),
      );
    }

    EditorUtils.insertNode(editorView, node);
    editorView.focus();
  };

  const handleLoadRecordsForTypeCode = async (typeCode: string) => {
    if (typeCode === "ACR") {
      return;
    }
    UIUtils.setLoadingDisabled(true);
    const newDirectScopeData = await DirectScopeService.loadData(
      typeCode,
      directScopeData,
      documentRecord.ProjectId,
      documentRecord.ProcessId,
    );
    UIUtils.setLoadingDisabled(false);
    // If user expand many sections too quickly, we will have a race condition. Therefore, we
    // need to merge them with the previous direct scope
    setDirectScopeData((prevDirectScopeData) =>
      DirectScopeService.mergeData(prevDirectScopeData, newDirectScopeData),
    );
  };

  return (
    <div id="side-menu" className={`${styles["side-menu-container"]}`}>
      <div className={styles["side-menu-header-sticky"]}>
        <TabNavBar
          className={`${styles["side-menu-tab-navbar"]}`}
          selected={selectedTab}
          onTabChanged={handleTabChanged}
          tabs={record ? [MENU_TABS.SMART_CONTENT] : MENU_TABS}
        />
        <div
          className={`${styles["side-menu-separator"]} ${styles["side-menu-separator-header"]}`}
        />
      </div>
      {selectedTab === MENU_TABS.SMART_CONTENT ? (
        <DirectScopeSmartContentTree
          directScopeData={directScopeData}
          project={project}
          selectedRecord={selectedRecord}
          selectedRecordData={selectedRecordData}
          documentRecord={documentRecord}
          rmp={rmp}
          hideHeaderRecord={!!record}
          onSelectedRecord={handleSelectedRecord}
          onAdd={handleOnAddDirectScope}
          onLoadRecordsForTypeCode={handleLoadRecordsForTypeCode}
          onBackButtonClick={handleOnBack}
        />
      ) : (
        <Widget editorView={editorView} project={project} />
      )}
    </div>
  );
}
