export var avatarType;
(function (avatarType) {
    avatarType["TEXT"] = "text";
    avatarType["IMAGE"] = "image";
    avatarType["ICON"] = "icon";
})(avatarType || (avatarType = {}));
export var cardOrientation;
(function (cardOrientation) {
    cardOrientation["HORIZONTAL"] = "horizontal";
    cardOrientation["VERTICAL"] = "vertical";
})(cardOrientation || (cardOrientation = {}));
export var cardType;
(function (cardType) {
    cardType["DEFAULT"] = "default";
    cardType["primary"] = "primary";
    cardType["INFO"] = "info";
    cardType["SUCCESS"] = "success";
    cardType["WARNING"] = "warning";
    cardType["ERROR"] = "error";
})(cardType || (cardType = {}));
export var cardActionsLayout;
(function (cardActionsLayout) {
    cardActionsLayout["START"] = "start";
    cardActionsLayout["CENTER"] = "center";
    cardActionsLayout["END"] = "end";
    cardActionsLayout["STRETCHED"] = "stretched";
})(cardActionsLayout || (cardActionsLayout = {}));
