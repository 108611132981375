// @ts-check
"use strict";


import * as UIUtils from "../../../ui_utils";
import React from "react";
import ImplementationNeededError from "../../../utils/implementation_needed_error";
import BaseReactComponent from "../../../base_react_component";
import * as ProcessCache from "../../../processExplorer/process/process_cache";

/**
 * Serves as a base class for the Clone dialog supported plugins
 */
export default class BaseCopyRecordPlugin extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  /**
   * Handles updating both the state of the plugin and the state of the clone dialog with the new plugin value
   * @param value
   */
  handleChangeValue(value) {
    const {attributeToUpdate} = this.props.configProps;
    if (this.state) {
      this.setStateSafely({[attributeToUpdate]: value}, () => {
        this.props.parent.handlePluginValueChange(attributeToUpdate, this.state[attributeToUpdate]);
      });
    }
  }

  getProjectId() {
    let parent = this.props.parent;
    while (parent && parent.props && !parent.projectId) {
      parent = parent.props.parent;
    }
    return parent && parent.projectId ? parent.projectId : UIUtils.getParameterByName("projectId");
  }

  getProcessId() {
    let parent = this.props.parent;
    while (parent && parent.props && !parent.processId) {
      parent = parent.props.parent;
    }
    return parent && parent.processId ? parent.processId : ProcessCache.getProcessIdUsedRecently(this.getProjectId());
  }

  render() {
    throw new ImplementationNeededError("You need to implement the render method in the derived classes.");
  }
}
