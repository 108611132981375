import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from "prosemirror-view";
// The plugin resolves the following problems:
//
// 1. white-space: pre-wrap does not work with text-align: justify in firefox
//  https://bugzilla.mozilla.org/show_bug.cgi?id=1253840
//  https://bugzilla.mozilla.org/show_bug.cgi?id=299721
// 2. Align right in pre-wrap doesn't give the expected outcome,
//  as in pre-wrap it will preserve the white-space when the text breaks based on the container width.
// Additional info here:
// https://github.com/ProseMirror/prosemirror/issues/651
// https://github.com/ProseMirror/prosemirror/issues/857
var spaces = /\s+/g;
var align = /text-align/;
var aligned = function (node) { return align.test((node && node.attrs && node.attrs.style) || ''); };
export var spacesFix = function () { return new Plugin({
    key: new PluginKey('spaces-fix'),
    props: {
        decorations: function (state) {
            var decorations = [];
            var doc = state.doc;
            var start, match, length, i;
            doc.nodesBetween(0, doc.content.size, function (node, position, parent) {
                if (node.type.isText && aligned(parent)) {
                    match = spaces.exec(node.text || '');
                    while (match !== null) {
                        start = position + match.index;
                        length = match[0].length;
                        if (match.index + length < match.input.length) {
                            for (i = 0; i <= length - 1; i += 2) {
                                decorations.push(Decoration.inline(start + i, start + i + 1, {
                                    style: 'white-space: normal'
                                }));
                            }
                        }
                        match = spaces.exec(node.text || '');
                    }
                }
            });
            return DecorationSet.create(doc, decorations);
        }
    }
}); };
