"use strict";

import * as UIUtils from "../../../ui_utils";
import { QTPPSummaryDataTransform } from "./qtpp_summary_data_transform";
import {
  checkEmptySummaryData,
  getRawRiskScoreText,
  getRiskScaleColor,
  processEmptyFields,
  sortAttributes,
} from "../../canned_reports/canned_report_helper";
import {
  getFilteredRMPForType,
  getRiskScale,
  hasNotAssessedRiskScale
} from "../../../helpers/risk_helper";
import { RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import { REPORT_DRAFT_ENUM } from "../../constants/report_constants";
import moment from "moment-timezone";
import RiskUtils from "../../../../server/common/misc/common_risk_utils";

export class FQARiskRankingDataTransform extends QTPPSummaryDataTransform {

  get type() {
    return "fqaRiskRanking";
  }

  transform(input, options) {

    let result = super.transform(input, options);
    let fqas = result.instances.fqas;
    let instances = [];

    for (const fqa of fqas) {

      if (!fqa.riskInfo) {
        throw new Error("Missing RiskInfo");
      }

      const effectiveRMP = getFilteredRMPForType(options.rmpVersions.find(rmpVersion => rmpVersion.id === fqa.effectiveRMPVersionId), "FQA");
      const label = UIUtils.getRecordLabelForDisplay("FQA", fqa.id, fqa.name);
      const hasNotAssessed = hasNotAssessedRiskScale(RISK_TYPE_ENUM.CRITICALITY, effectiveRMP);

      if (fqa.riskLinks.length === 0 && hasNotAssessed) {
        const commonParams = {
          label,
          name: fqa.name,
          category: fqa.category,
          draftMarker: fqa.draftMarker,
          generalAttributeName: "",
          criticality: 0,
        };

        let criticalityRiskScale = fqa.riskInfo[RISK_TYPE_ENUM.CRITICALITY].scale;

        instances.push({
          ...commonParams,
          value: `${criticalityRiskScale.scoreLabel}`,
          riskLabel: "Criticality",
          color: getRiskScaleColor(criticalityRiskScale),
        });

        instances.push({
          ...commonParams,
          value: `${fqa.controlStrategy}`,
          riskLabel: "Control Strategy",
          generalAttributeName: "",
        });

        instances.push({
          ...commonParams,
          value: `${fqa.controlMethods}`,
          riskLabel: "Control Method (Status)",
          generalAttributeName: "",
        });
      }

      for (const riskLink of fqa.riskLinks) {

        const commonParams = {
          label,
          name: fqa.name,
          category: fqa.category,
          draftMarker: fqa.draftMarker,
          generalAttributeName: riskLink.name,
        };

        let riskScale = getRiskScale(RISK_TYPE_ENUM.IMPACT, effectiveRMP, riskLink.impact);
        let color = getRiskScaleColor(riskScale);
        const impact = RiskUtils.riskValueIsNotAssessed(riskLink.impact, RISK_TYPE_ENUM.IMPACT, effectiveRMP) ? riskScale.scoreLabel : riskLink.impact;
        const impactEntry = {
          ...commonParams,
          value: `${impact}`,
          riskLabel: "Impact",
          color,
        };
        processEmptyFields(impactEntry);
        instances.push(impactEntry);

        riskScale = getRiskScale(RISK_TYPE_ENUM.UNCERTAINTY, effectiveRMP, riskLink.uncertainty);
        color = getRiskScaleColor(riskScale);
        const uncertainty = RiskUtils.riskValueIsNotAssessed(riskLink.uncertainty, RISK_TYPE_ENUM.UNCERTAINTY, effectiveRMP) ? riskScale.scoreLabel : riskLink.uncertainty;

        const UncertaintyEntry = {
          ...commonParams,
          value: `${uncertainty}`,
          riskLabel: "Uncertainty",
          color,
        };
        processEmptyFields(UncertaintyEntry);
        instances.push(UncertaintyEntry);

        const maxCriticality = RiskUtils.riskValueIsNotAssessed(fqa.criticality, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP) ? riskScale.scoreLabel : fqa.criticality;
        instances.push({
          ...commonParams,
          value: `${maxCriticality}`,
          riskLabel: "Criticality",
          generalAttributeName: "",
          color: fqa.criticalityColor,
        });

        instances.push({
          ...commonParams,
          value: `${fqa.controlStrategy}`,
          riskLabel: "Control Strategy",
          generalAttributeName: "",
        });

        instances.push({
          ...commonParams,
          value: `${fqa.controlMethods}`,
          riskLabel: "Control Method (Status)",
          generalAttributeName: "",
        });
      }
    }

    instances.sort(sortAttributes);
    result.instances = instances;

    const informationDate = moment(options.reportDate).format(UIUtils.DATE_FORMAT_FOR_DISPLAY);
    result.draftMessage = REPORT_DRAFT_ENUM.DraftMessage.replace("{informationDate}", informationDate);

    checkEmptySummaryData(result, result.instances);
    return result;
  }
}

