import BaseQuickEditableListTable from "../../base_quick_editable_list_table";
import * as UIUtils from "../../ui_utils";
import ReactDOMServer from "react-dom/server";
import React, { Fragment } from "react";
import { getCSSClassForLegend } from "../../reports/risk_map_reports/utilities/risk_map_report_helper";
import RiskUtils from "../../../server/common/misc/common_risk_utils";
import { COLUMN_FORMATTERS } from "../../widgets/tables/column_formatters";

// i18next-extract-mark-ns-start qtpp
export default class BaseFinalAttributeList extends BaseQuickEditableListTable {

  constructor(props, capitalizedBaseTypeName, showRemoveBtn = true, enablePaging = true) {
    super(props, capitalizedBaseTypeName, showRemoveBtn, enablePaging);
  }

  generateColumns(props) {
    const {t} = props;

    return [
      this.generateIDColumn(),
      this.generateNameColumn("view", 150),
      {
        title: t("Category"),
        width: 75,
        data: (result) => {
          let {category} = result;
          if (typeof category === "string" && category.startsWith("[")) {
            category = JSON.parse(category).join(", ");
          }
          return t(category);
        }
      },
      {
        ...COLUMN_FORMATTERS.CURRENT_STATE,
        data: this.createMappedCell(result => this.getCurrentState(result)),
        title: t("Status"),
      },
      {
        title: t("Criticality Score"),
        width: 100,
        data: record => {
          const score = record.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].value;
          return score === RiskUtils.NOT_ASSESSED_SCORE ? "0" : (score || "");
        }
      },
      {
        title: t("Risk Label"),
        width: 50,
        containsHTML: true,
        data: record => {
          record = UIUtils.deepClone(record);
          delete record.majorVersion; // Because of QI-3902
          delete record.minorVersion;
          const riskScale = record.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel;

          return ReactDOMServer.renderToStaticMarkup(this.state.isLoadingRMP ? (
            <div className="skeleton" />
          ) : riskScale && riskScale.riskLabel ? (
            <Fragment>
              <div className={`list-table-risk-label ${getCSSClassForLegend(riskScale.color)}`} />
              <span className="list-table-risk-label-label">{t(riskScale.riskLabel)}</span>
            </Fragment>
          ) : "");
        }
      },
      this.generateManageColumn()
    ];
  }

  handleChangeShowRemoved(showRemoved) {
    this.getDataFromServer(undefined, showRemoved);
  }

  generateColumnDefsArray() {
    return [
      {className: "results-table-column-id", targets: [0]},
      this.generateManageColumnDef(6)
    ];
  }
}
// i18next-extract-mark-ns-stop qtpp
