var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { NodeSelection, insertNode } from '@progress/kendo-editor-common';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { messages } from './../messages';
/**
 * @hidden
 */
var InsertImageDialog = /** @class */ (function (_super) {
    __extends(InsertImageDialog, _super);
    function InsertImageDialog() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.src = null;
        _this.altText = null;
        _this.title = null;
        _this.width = null;
        _this.height = null;
        _this.onClose = function () {
            _this.props.view.focus();
            _this.props.onClose();
        };
        _this.onInsert = function () {
            var _a = _this.props, view = _a.view, settings = _a.settings;
            var nodes = view.state.schema.nodes;
            var nodeType = nodes[settings.node];
            var data = {
                src: _this.src ? _this.src.value : null,
                title: _this.title ? _this.title.value : null,
                alt: _this.altText ? _this.altText.value : null,
                width: _this.width ? _this.width.value : null,
                height: _this.height ? _this.height.value : null
            };
            var attrs = Object.keys(data)
                .filter(function (key) { return data[key] !== null && data[key] !== ''; })
                .reduce(function (acc, curr) {
                var _a;
                return Object.assign(acc, (_a = {}, _a[curr] = data[curr], _a));
            }, {});
            var newImage = nodeType.createAndFill(attrs);
            if (newImage) {
                var insertImageCmd = insertNode(newImage, true);
                insertImageCmd(view.state, function (tr) { return view.dispatch(tr.setMeta('commandName', settings.commandName).setMeta('args', attrs)); });
            }
            _this.onClose();
        };
        return _this;
    }
    /**
     * @hidden
     */
    InsertImageDialog.prototype.render = function () {
        var _this = this;
        var _a = this.props, view = _a.view, settings = _a.settings, dir = _a.dir, render = _a.render;
        var _b = settings.messages, insertImageDialogTitle = _b.insertImageDialogTitle, insertImageAddress = _b.insertImageAddress, insertImageTitle = _b.insertImageTitle, insertImageAltText = _b.insertImageAltText, insertImageWidth = _b.insertImageWidth, insertImageHeight = _b.insertImageHeight, insertImageCancel = _b.insertImageCancel, insertImageInsert = _b.insertImageInsert;
        var localization = provideLocalizationService(this);
        var state = view && view.state;
        var attrs = {};
        if (state && state.selection instanceof NodeSelection &&
            state.selection.node.type === state.schema.nodes[settings.node]) {
            attrs = state.selection.node.attrs;
        }
        var content = (React.createElement("div", { className: "k-edit-form-container" },
            React.createElement("div", { className: "k-edit-label" },
                React.createElement("label", { htmlFor: "k-editor-image-url" }, localization.toLanguageString(insertImageAddress, messages[insertImageAddress]))),
            React.createElement("div", { className: "k-edit-field" },
                React.createElement("input", { type: "text", className: "k-input", id: "k-editor-image-url", defaultValue: attrs.src, ref: function (e) { return _this.src = e; }, autoFocus: true })),
            React.createElement("div", { className: "k-edit-label" },
                React.createElement("label", { htmlFor: "k-editor-image-alt" }, localization.toLanguageString(insertImageAltText, messages[insertImageAltText]))),
            React.createElement("div", { className: "k-edit-field" },
                React.createElement("input", { type: "text", className: "k-input", id: "k-editor-image-alt", defaultValue: attrs.alt, ref: function (e) { return _this.altText = e; } })),
            React.createElement("div", { className: "k-edit-label" },
                React.createElement("label", { htmlFor: "k-editor-image-title" }, localization.toLanguageString(insertImageTitle, messages[insertImageTitle]))),
            React.createElement("div", { className: "k-edit-field" },
                React.createElement("input", { type: "text", className: "k-input", id: "k-editor-image-title", defaultValue: attrs.title, ref: function (e) { return _this.title = e; } })),
            React.createElement("div", { className: "k-edit-label" },
                React.createElement("label", { htmlFor: "k-editor-image-width" }, localization.toLanguageString(insertImageWidth, messages[insertImageWidth]))),
            React.createElement("div", { className: "k-edit-field" },
                React.createElement("input", { type: "text", className: "k-input", id: "k-editor-image-width", defaultValue: attrs.width, ref: function (e) { return _this.width = e; } })),
            React.createElement("div", { className: "k-edit-label" },
                React.createElement("label", { htmlFor: "k-editor-image-height" }, localization.toLanguageString(insertImageHeight, messages[insertImageHeight]))),
            React.createElement("div", { className: "k-edit-field" },
                React.createElement("input", { type: "text", className: "k-input", id: "k-editor-image-height", defaultValue: attrs.height, ref: function (e) { return _this.height = e; } }))));
        var actionButtons = [(React.createElement(Button, { onClick: this.onClose, dir: dir, key: "cancel" }, localization.toLanguageString(insertImageCancel, messages[insertImageCancel]))), (React.createElement(Button, { onClick: this.onInsert, themeColor: 'primary', dir: dir, key: "insert" }, localization.toLanguageString(insertImageInsert, messages[insertImageInsert])))];
        var dialog = (React.createElement(Dialog, { title: localization.toLanguageString(insertImageDialogTitle, messages[insertImageDialogTitle]), onClose: this.onClose, key: "dialog", closeIcon: true, dir: dir, style: dir === 'rtl' ? { textAlign: 'right' } : undefined, appendTo: document.body },
            content,
            React.createElement.apply(React, __spreadArray([DialogActionsBar, null], actionButtons, false))));
        return render ? render(dialog, { content: content, actionButtons: actionButtons }) : dialog;
    };
    return InsertImageDialog;
}(React.Component));
export { InsertImageDialog };
registerForLocalization(InsertImageDialog);
