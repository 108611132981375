"use strict";

/* This class shows the PCM dashboard */
import * as UIUtils from "../../ui_utils";
import React from "react";
import DataReportPage from "../data_report_page";
import FlyoutPanel from "../../widgets/layout/flyout_panel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ImplementationNeededError from "../../utils/implementation_needed_error";
import PCMDashboardProcessDataTable from "../pcm_dashboard/pcm_dashboard_process_data_table";
import { DATATABLES_DOM_WITH_SEARCH_AND_BUTTONS } from "../../widgets/tables/base_table";

/**
 * This shows the Process Analytics dashboard (but not the control charts).
 */
export default class BaseChartsReportPage extends DataReportPage {
  constructor(props) {
    super(props);

    let selectedSubgroupIds = UIUtils.getParameterByName("selectedIds") || undefined;
    if (selectedSubgroupIds) {
      selectedSubgroupIds = JSON.parse(selectedSubgroupIds);
    }

    this.setStateSafely({
      selectedSubgroupIds: selectedSubgroupIds,
      shouldShowRightPanel: false,
    });

    window.onpopstate = this.handleHistory;
  }

  /**
   * This is called when the user hits the back button
   */
  handleHistory(event) {
    // Uncomment for verbose logging
    // console.log("Moving to state: " + UIUtils.stringify(event.state));
    this.setStateSafely(event.state, () => {
      this.loadDataFromServer(false);
    });
  }

  /**
   * This is called when there are new subgroups chosen by the user.
   * @param selectedRows
   */
  handleChangeSelectedSubgroupsApply(selectedRows) {
    const {allSubgroups} = this.state;
    const selectedSubgroups = Array.from(selectedRows);
    let selectedSubgroupIds = selectedSubgroups.map(row => row.id);

    if (JSON.stringify(selectedSubgroupIds) !== JSON.stringify(this.state.selectedSubgroupIds)) {
      selectedSubgroupIds = selectedSubgroups.length !== allSubgroups.length ? selectedSubgroupIds : null;
      this.setStateSafely({selectedSubgroups, selectedSubgroupIds}, () => {
        this.loadDataFromServer();
        this.closeRightPanel();
      });
    }
  }


  /**
   * Trigger to load the data from the server. This parent method only sets the history (URL). The actual loading is
   * expected to be done by the child class.
   */
  loadDataFromServer(shouldPushHistory = true) {
    if (shouldPushHistory) {
      this.pushURLHistory();
    }
  }

  pushURLHistory() {
    const value = this.state.selectedTypeaheadOption;
    if (value) {
      const newParams = {
        reportType: this.reportType,
        modelLabel: `${value.typeCode}-${value.id}`,
        selectedIds: this.state.selectedSubgroupIds ? JSON.stringify(this.state.selectedSubgroupIds) : undefined,
      };

      if (this.projectId) {
        newParams.projectId = this.projectId;
      }

      if (this.state.processId) {
        newParams.processId = this.state.processId;
      }

      UIUtils.pushHistoryURLWithParameterChanges(this.state, newParams, this.state.selectedSubgroupIds ? null : {selectedIds: true});
    } else {
      UIUtils.pushHistoryURLWithParameterChanges(this.state, null,
        {modelLabel: true, selectedIds: true});
    }
  }

  openRightPanel() {
    this.setStateSafely({shouldShowRightPanel: true});
  }

  closeRightPanel() {
    this.setStateSafely({shouldShowRightPanel: false});
  }

  selectByDefault(row) {
    return this.state.selectedSubgroupIds ? this.state.selectedSubgroupIds.includes(row.customID) : true;
  }

  renderPage() {
    return (
      <FlyoutPanel
        id="chartReportSplitPanel"
        className={this.state.shouldShowRightPanel ? "" : "pcm-dashboard-flyout-collapsed"}
        shouldShowRightPanel={this.state.shouldShowRightPanel}
        minSize={[100, 300]}
        leftPanel={this.renderLeftPanel()}
        rightPanel={this.renderBatchManufacturingData(true)}
      />
    );
  }

  renderLeftPanel() {
    throw new ImplementationNeededError();
  }

  renderBatchManufacturingData(isInRightPanel) {
    const {allSubgroups, selectedSubgroupIds, selectedSubgroups, shouldShowRightPanel, measure} = this.state;
    const displayedSubgroups = isInRightPanel ? allSubgroups : selectedSubgroups;
    const selectedIds = selectedSubgroupIds || (displayedSubgroups || []).map(subgroup => subgroup.customID);
    const addRightCSS = isInRightPanel ? "-right" : "";
    const addRightId = isInRightPanel ? "Right" : "";
    return isInRightPanel && !shouldShowRightPanel ? "" : (
      <div className={"pcm-dashboard-process-data-table" + addRightCSS}>
        <h2 className="pcm-dashboard-process-data-table-title">
          Subgroups
          {isInRightPanel ? (
            <FontAwesomeIcon id={"chartsSubgroupsDataTableCloseButton"}
                             icon={faTimes}
                             className="float-right"
                             size="sm"
                             aria-label="Close Panel"
                             onClick={this.closeRightPanel}
            />
          ) : ""}
        </h2>
        <PCMDashboardProcessDataTable id={"chartsSubgroupsDataTable" + addRightId}
                                      hideCheckBoxes={!isInRightPanel}
                                      data={displayedSubgroups || []}
                                      selectedIds={selectedIds}
                                      rowIdProp="customID"
                                      defaultSortColumnIndex={1}
                                      datatablesDomOptions={DATATABLES_DOM_WITH_SEARCH_AND_BUTTONS}
                                      measure={measure}
                                      projectId={this.projectId}
                                      showSelectedRowsInfo={true}
                                      selectByDefault={this.selectByDefault}
                                      onApply={this.handleChangeSelectedSubgroupsApply}
        />
      </div>
    );
  }
}
