"use strict";

import ConfigurableTablesExport from "../../configurableTables/export/configurable_tables_export";
import moment from "moment/moment";
import * as UIUtils from "../../ui_utils";
import { FIELD_PROPS } from "../../configurableTables/fieldsConfig/constants/configurable_tables_field_props_config";
import { TYPE_CODE } from "../../../server/common/generic/common_constants";
import * as library_tables_export from "./files/library_tables_report.mrt";

/**
 * This class adapts/transforms the data before exporting it.
 */
export class MaterialLibraryConfigurableTablesExport extends ConfigurableTablesExport {

  constructor(props) {
    super(props);

    const {showArchived} = props;
    this.records = showArchived ? this.records : this.records.filter(record => !record.deletedAt);
  }

  get exportTemplateName() {
    return library_tables_export;
  }

  getProcessedRecords(record, visibleColumns) {
    let instances = super.getProcessedRecords(record, visibleColumns);

    /**
     * Remove USED_IN_PROJECTS, it will be shown in its own sheet in the generated Excel.
     * Remove VERSION, it is already shown alongside the Record Status
     */
    instances = instances.filter(instance => !["Used in Projects", "Version"].includes(instance.columnName));

    /**
     * Add Category Column
     */
    instances.splice(1, 0, {
      columnName: UIUtils.convertCamelCaseToSpacedOutWords(FIELD_PROPS.CATEGORY),
      variable: UIUtils.getRecordCustomIdForDisplay(record),
      value: record.category,
    });

    /**
     * Add Record Status column.
     */
    instances.splice(2, 0, {
      columnName: "Record Status",
      variable: UIUtils.getRecordCustomIdForDisplay(record),
      value: `${record.currentState} v${record?.LastVersion.majorVersion}.${record.LastApprovedVersionId ? 0 : record?.LastVersion.minorVersion}`,
    });

    /**
     * Add Number of Project Uses column.
     */
    instances.splice(3, 0, {
      columnName: "Number of Project Uses",
      variable: UIUtils.getRecordCustomIdForDisplay(record),
      value: UIUtils.parseInt(record.projectUseCount),
    });

    /**
     * Add Number of Specifications column.
     */
    instances.splice(4, 0, {
      columnName: "Number of Specifications",
      variable: UIUtils.getRecordCustomIdForDisplay(record),
      value: UIUtils.parseInt(record.specificationsCount),
    });

    /**
     * Add Number of Lots column.
     */
    instances.splice(5, 0, {
      columnName: "Number of Lots",
      variable: UIUtils.getRecordCustomIdForDisplay(record),
      value: UIUtils.parseInt(record.batchesCount),
    });

    return instances
      .filter(column =>
        column.columnName !== UIUtils.convertCamelCaseToSpacedOutWords(FIELD_PROPS.ACTIONS)
      );
  }

  preprocessRecordsForExport(records, exportOutput, visibleColumns) {
    exportOutput.projectUses = [];
    exportOutput.specifications = [];

    records = records
      .map(({Materials, Batches, Specifications, ...rest}) => {
        return ({
          ...rest,
          projectUseCount: Materials?.length,
          batchesCount: Batches?.length,
          specificationsCount: Specifications?.length,
          Materials,
          Specifications
        });
      })
      // This to maintain the order of json attributes as id, name, category, projectUseCount, then the result of the attributes
      .map(({id, name, category, projectUseCount, specificationsCount, batchesCount, ...rest}) => ({
        id,
        name,
        category,
        projectUseCount,
        specificationsCount,
        batchesCount, ...rest
      }));


    for (const record of records) {
      exportOutput.projectUses = exportOutput.projectUses.concat(this.getProjectUses(record));
      exportOutput.specifications = exportOutput.specifications.concat(this.getMaterialSpecifications(record));
    }

    super.preprocessRecordsForExport(records, exportOutput, visibleColumns);
  }

  getProjectUses(record) {
    return record.Materials.map(({id: materialId, fromLibraryStatus, Project, Process}) => ({
      id: `${TYPE_CODE.LIBRARY_MATERIAL}-${record.id}`,
      name: record.name,
      status: fromLibraryStatus,
      materialId: `${TYPE_CODE.MATERIAL}-${materialId}`,
      projectId: `${TYPE_CODE.PROJECT}-${Project.id}`,
      projectName: Project.name,
      processId: Process ? `${TYPE_CODE.PROCESS}-${Process.id}` : undefined,
      processName: Process?.name,
    }));
  }

  getMaterialSpecifications(record) {
    return record.Specifications.map(({id, name, measure, lowerLimit, target, upperLimit, measurementUnits}) => ({
      libraryMaterialId: `${TYPE_CODE.LIBRARY_MATERIAL}-${record.id}`,
      libraryMaterialName: record.name,
      specificationId: id,
      specificationName: name,
      measure,
      lowerLimit,
      target,
      upperLimit,
      measurementUnits
    }));
  }

  getFileName() {
    const {
      selectedModelType,
      feature,
    } = this;
    let exportFileName = `${feature}_${selectedModelType}`;
    exportFileName += `_${moment().format(UIUtils.DATE_FORMAT_FOR_STORAGE)}`;
    return exportFileName;
  }
}