"use strict";

import * as UIUtils from "../../ui_utils";
import React, { useState } from "react";
import Typeahead from "../../widgets/typeahead";
import DatePicker from "../../widgets/date_picker";
import { EDITOR_TYPES } from "../editor_constants";
import LabelTooltip from "../../widgets/tooltips/label_tooltip";
import { canUser, canUserAccessProject } from "../../utils/ui_permissions";
import * as CommonSecurity from "../../../server/common/generic/common_security";
import moment from "moment-timezone";
import ProjectAdminsTooltip from "../widgets/project_admins_tooltip";
import ApprovalPopupTable from "./approval_popup_table";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import * as CommonUsers from "../../../server/common/editables/common_users";
import CommonConstants, { TYPE_CODE } from "../../../server/common/generic/common_constants";
import * as CommonEditables from "../../../server/common/editables/common_editables";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import BasePopup from "./base_popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { EMPTY_STRING } from "../../helpers/constants/constants";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";

// i18next-extract-mark-ns-start editor
const TYPEAHEAD_TYPE = "User";

const LibraryStatusWarning = ({instance, action}) => {

  const [record] = useState({
    ...instance.LibraryMaterial,
    typeCode: TYPE_CODE.LIBRARY_MATERIAL
  });

  const shouldRender =
    (action === CommonConstants.ACTIONS.ARCHIVE)
    && (instance?.typeCode === TYPE_CODE.MATERIAL)
    && instance?.LibraryMaterial;

  const url = (
    <a href={getURLByTypeCodeAndId(TYPE_CODE.LIBRARY_MATERIAL, "View", record.id)}
       target="_blank"
       rel="noopener noreferrer"
    >
      <b>{
        UIUtils.getRecordCustomLabelForDisplay(record)
      }</b>
    </a>
  );

  return shouldRender
    ? (
      <div className="alert alert-warning">
        <div>
          {"Upon Archive approval, this Material will be unsynced and unlinked from the Library Material "}{url}.
        </div>
      </div>
    )
    : EMPTY_STRING;
};

const LinkToLibraryInfo = ({instance}) => {

  const {
    name,
    relatedProjects
  } = instance;
  const materialsCount = relatedProjects?.length;

  return materialsCount
    ? (
      <div className="alert alert-info library-status-info">
        <FontAwesomeIcon icon={faInfoCircle}
                         className="library-status-info-icon"
                         id="libraryStatusInfoIcon"
        />
        <span>
          {`"${name}" is synced to ${materialsCount} ${UIUtils.pluralize("Project Material record", materialsCount)}. Once approved, all synced Project Material records will be updated. Note: any pending approvals for Project Material records will be withdrawn automatically.`}
        </span>
      </div>
    ) : EMPTY_STRING;
};

class ApprovalRequestPopup extends BasePopup {
  constructor(props) {
    super(props);

    this.state = {
      errorText: "",
      users: [],
      isLoadingUsers: true,
      approveByDate: null, // Keep track of this here just because the date picker needs it.
    };

    new TypeaheadObjectCache(TYPEAHEAD_TYPE).loadOptions(this.handleTypeaheadResultsFromServer);
  }

  handleCancel() {
    super.handleCancel();
    if (this.props.onCancelApprovalRequest) {
      this.props.onCancelApprovalRequest();
    }
    if (this.props.setPopupOpened) {
      this.props.setPopupOpened(false);
    }
  }

  handleApproversChange(event) {
    this.setStateSafely({approvers: event});
    this.clearErrorText();
  }

  handleApproveByDateChange(event) {
    const {t} = this.props;

    // Event is a moment (see moment.js)
    let dateString;
    if (typeof event === "string") {
      dateString = event;
    } else {
      // Event is a date.
      event = moment(event);
      if (moment().diff(event, "days") > 0) {
        this.setErrorText(t("Approve by date cannot be in the past."));
        return;
      }

      dateString = event.format(UIUtils.DATE_FORMAT_FOR_STORAGE);
    }


    this.setStateSafely({
      approveByDate: event,
      approveByDateString: dateString
    });
    this.clearErrorText();
  }

  handleCommentChange(event) {
    this.setStateSafely({comment: event.target.value});
    this.clearErrorText();
  }

  handleSendApproval(event) {
    const {t, parent, action} = this.props;
    const {approvers, comment, approveByDateString} = this.state;

    const instanceToSave = this.getInstance();

    // Drafts with no approved versions can be self-proposed for archival
    const modelName = this.getModelName();
    const canBeSelfApproved = CommonEditables.canBeSelfApproved(instanceToSave, action, modelName);

    if (!approvers || approvers.length === 0) {
      this.setErrorText(t("At least one approver is required."));
    } else if (approvers.length === 1 && UIUtils.isCurrentUser(approvers[0].cognitoUUID) && !canBeSelfApproved) {
      this.setErrorText(t("You cannot be the only approver."));
    } else if (!comment || comment.length === 0) {
      this.setErrorText(t("A comment is required."));
    } else {
      this.clearErrorText();
      try {
        parent.handleApprovalChangeValues({
          approvers: approvers,
          comment: comment,
          approveByDate: approveByDateString
        }, () => {
          return this.props.onSendApprovalRequest(event, this.sendApprovalRequestCallback);
        });


      } catch (error) {
        this.setErrorText(error.message);
      }
    }

    return false;
  }

  sendApprovalRequestCallback(result) {
    const {parent} = this.props;

    if (result.error) {
      this.setErrorText(result.error.message);
    } else if (result.success) {
      $(this.modalRef).modal("hide");
      if (parent && parent.setPopupOpened) {
        parent.setPopupOpened(false);
      }
    }
  }

  setErrorText(message) {
    // Uncomment for verbose logging
    // console.log("Setting error text to: " + message);
    this.setStateSafely({
      errorText: message,
    });
  }

  clearErrorText() {
    this.setStateSafely({
      errorText: null,
    });
  }

  handleTypeaheadResultsFromServer(result) {
    this.setStateSafely({
      isLoadingUsers: false,
      users: result
    });

  }

  shouldComponentUpdate(nextProps, nextState) {
    let currentDate = this.state["approveByDate"] ? moment(this.state["approveByDate"]).format(UIUtils.DATE_FORMAT_FOR_STORAGE) : this.state["approveByDate"];
    let nextDate = nextState["approveByDate"] ? moment(nextState["approveByDate"]).format(UIUtils.DATE_FORMAT_FOR_STORAGE) : nextState["approveByDate"];
    return (nextProps.action !== this.props.action)
      || (nextProps.message !== this.props.message)
      || (nextProps.warning !== this.props.warning)
      || (nextDate !== currentDate)
      || (nextState.errorText !== this.state.errorText)
      || (nextState.isLoadingUsers !== this.state.isLoadingUsers)
      || (nextState.comment !== this.state.comment)
      || (nextState.approveByDateString !== this.state.approveByDateString)
      || (JSON.stringify(nextState.approvers) !== JSON.stringify(this.state.approvers))
      || (nextState.selectedRequirements !== this.state.selectedRequirements);
  }

  render() {
    const {t, parent, project, action, isBulkProposal, onCheckPermissions} = this.props;
    const {users, isLoadingUsers} = this.state;

    let instance = this.getInstance();
    let warnings = [];
    let secondaryWarnings = [];
    let errors = [];
    let messages = [];

    if (this.props.warning) {
      warnings.push(this.props.warning);

    }
    if (this.props.secondaryWarning) {
      secondaryWarnings.push(this.props.secondaryWarning);
    }

    if (this.state.errorText) {
      errors.push(this.state.errorText);
    }

    if (this.props.message) {
      messages.push(this.props.message);
    }

    if (instance && instance.modelType === "Document") {
      if (instance.isQbdSrcDoc && instance.documentContent) {
        const uploadDocumentLinks = JSON.parse(instance.uploadDocumentLinks);
        if (
          !uploadDocumentLinks ||
          !uploadDocumentLinks.length ||
          !uploadDocumentLinks[0].link
        ) {
          messages.push(
            <div className="d-flex flex-row">
              <div className="mr-1">
                <FontAwesomeIcon
                  className="info-icon-blue"
                  icon={faExclamationCircle}
                />{" "}
              </div>
              <p id="popup-info-message">
                A PDF will be created from your QbDVision doc and sent to
                approvers for review.
              </p>
            </div>,
          );
        } else {
          const documentLink = uploadDocumentLinks[0];
          const {documentContent} = instance;
          if (
            documentLink.documentContentVersionId !==
            documentContent.LastVersionId
          ) {
            messages.push(
              <div className="d-flex flex-row">
                <div className="mr-1">
                  <FontAwesomeIcon
                    className="info-icon-blue"
                    icon={faExclamationCircle}
                  />{" "}
                </div>
                <p id="popup-info-message">
                  There have been changes made to the QbDVision doc since the
                  PDF was last saved. The PDF will be updated with these
                  changes and sent to approvers for review.
                </p>
              </div>,
            );
          }
        }
      }

      if (instance.effectiveDate) {
        let approveByDate = this.state.approveByDate;
        approveByDate = approveByDate
          ? moment(this.state.approveByDate)
          : approveByDate;

        let effectiveDate = moment(instance.effectiveDate);
        let effectiveDateDisplayFormat = effectiveDate.format(
          UIUtils.DATE_FORMAT_FOR_DISPLAY,
        );

        if (moment().diff(effectiveDate, "days") > 0) {
          errors.push(
            `This document has an effective date (${effectiveDateDisplayFormat}) in the past.`,
          );
        }

        if (moment(approveByDate).diff(effectiveDate, "days") > 0) {
          errors.push(
            `The document effective date (${effectiveDateDisplayFormat}) is before the required approval date.`,
          );
        }
      }
    }

    // Change the label to be the job title
    if (!isLoadingUsers) {
      for (const user of users) {
        user.label = CommonUsers.formatUserToShowTitle(user);
      }
    }

    /* Figure out which users have permission to approve this and intersect those with the users that have
       access to the project or project entity being proposed. If this is a company wise entity, like Suppliers,
       then the project access filter is ignored. */
    let actionRequired;
    switch (action) {
      case CommonConstants.ACTIONS.ARCHIVE:
        actionRequired = CommonSecurity.Actions.APPROVE_FOR_ARCHIVE;
        break;
      case CommonConstants.ACTIONS.RESTORE:
        actionRequired = CommonSecurity.Actions.APPROVE_FOR_RESTORE;
        break;
      default:
        actionRequired = CommonSecurity.Actions.APPROVE;
    }

    let projectId;
    if (isBulkProposal) {
      projectId = project?.id;
    } else {
      if (this.getModelName() === "Project"
        && parent.isAdd
        && parent.isAdd()) {
        projectId = -1;
      } else {
        projectId = parent.getProjectId && parent.getProjectId();
      }
    }

    let projectName = isBulkProposal
      ? project?.name
      : parent.getProjectName && parent.getProjectName();

    let selectedModelTypes = [];
    if (isBulkProposal) {
      for (let {modelName} of this.props.selectedRequirements) {
        let securityTypeName = UIUtils.convertCamelCaseToSpacedOutWords(modelName);
        if (!selectedModelTypes.includes(securityTypeName)) {
          selectedModelTypes.push(securityTypeName);
        }
      }
    }

    let usersWithAccessToApprove = users.filter(user => {

      const additionalPermissionCheck = onCheckPermissions ? (params) => onCheckPermissions({...params, user}) : null;
      const canUserProposeRecordsInBulkPropose = isBulkProposal &&
        (user.isAdmin || selectedModelTypes.reduce((canPropose, modelName) => {
          return canUser(user, actionRequired, modelName, null, additionalPermissionCheck) && canPropose;
        }, true));

      const canUserProposeRecords = !isBulkProposal
        && canUser(user, actionRequired, this.getModelName(parent), this.getInstance(), additionalPermissionCheck);

      return canUserAccessProject(user, projectId)
        && user.enabled === true
        && (canUserProposeRecordsInBulkPropose || canUserProposeRecords);
    }).sort(UIUtils.sortBy("lastName", "firstName"));

    return (
      <div className="modal fade"
           ref={this.setModalRef}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">
                {t("Propose for {{ action }}", {action: action ? t(UIUtils.capitalize(action)) : t("Approval")})}
              </h1>
              <button type="button"
                      className="close"
                      onClick={this.handleCancel}
                      aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-container">
                <div className="row">
                  {errors.length > 0 ?
                    <div className="col-sm-12 alert alert-danger"
                         id="approvalRequestPopupErrorMessage"
                    >
                      {errors.map((error, index) => {
                        return (<div key={index}>{error}</div>);
                      })}
                    </div>
                    : ""}
                  {messages.length ? (
                    <div className="col-sm-12 alert alert-info"
                         id="approvalRequestMessage"
                    >
                      {messages.map((message, index) => {
                        return (<div key={index}>{message}</div>);
                      })}
                    </div>
                  ) : ""}
                  {warnings.length > 0 ? (
                    <div className="col-sm-12 alert alert-warning"
                         id="approvalRequestWarning"
                    >
                      {warnings.map((warning, index) => {
                        return (<div key={index}>{warning}</div>);
                      })}
                    </div>
                  ) : ""}
                  {secondaryWarnings.length > 0 ? (
                    <div className="col-sm-12 alert alert-warning"
                         id="approvalRequestSecondaryWarning"
                    >
                      {secondaryWarnings.map((warning, index) => {
                        return (<div key={index}>{warning}</div>);
                      })}
                    </div>
                  ) : ""}
                  <div className="col-sm-12" id="linkToLibraryInfo">
                    <LinkToLibraryInfo instance={instance} />
                  </div>
                  <div className="col-sm-12 library-status-warning"
                       id="libraryStatusWarning"
                  >
                    <LibraryStatusWarning instance={instance} action={action} />
                  </div>
                </div>
                {this.props.isBulkProposal ? (
                  <div className="row">
                    <div className="col-sm-12">
                      {t("You are proposing:")} {" "}
                      <div className="approval-proposal-response-popup-table">
                        <ApprovalPopupTable data={this.props.selectedRequirements} />
                      </div>
                    </div>
                  </div>
                ) : ""}
                <div className="row approval-pad-row">
                  <div className="col-12">
                    <LabelTooltip id="proposeUserLabel"
                                  text={`${t("Approvers")} *`}
                                  placement="bottom"
                                  tooltipText={
                                    <ProjectAdminsTooltip users={users}
                                                          useSuspense={false}
                                                          projectId={projectId}
                                                          projectName={projectName}
                                                          message={!this.props.editorType || this.props.editorType === EDITOR_TYPES.FULL_SCREEN
                                                            ? t("Approvers need access to this project and permissions to approve this record type. If you don't see the appropriate approver, please contact your Administrator to request that your approver be given access.")
                                                            : t("Approvers need access to this project and permissions to approve the selected record types. If you don't see the appropriate approver, please contact your Administrator to request that your approver be given access.")}
                                    />
                                  }
                    />
                    <div id="proposeUserTypeaheadDiv">
                      {isLoadingUsers ? (<div className="skeleton">Loading users...</div>) :
                        (<Typeahead multiple
                                    options={usersWithAccessToApprove}
                                    id="proposeUserTypeahead"
                                    inputProps={{id: "proposeUserTypeaheadInput", autoComplete: "off"}}
                                    onChange={this.handleApproversChange}
                                    ref={typeahead => this.typeahead = typeahead}
                        />)}
                    </div>
                  </div>
                </div>
                <div className="row approval-pad-row">
                  <div className="col-3">
                    <LabelTooltip text={t("Approve By")}
                                  tooltipText={t("QbDVision will use this date to send emails to remind approvers to make a decision.")}
                    />
                  </div>
                  <div className="col-6">
                    <DatePicker id="proposeApproveByDateInput"
                                selected={UIUtils.convertMomentToDate(this.state.approveByDate)}
                                className="propose-date-picker"
                                minDate={new Date()}
                                dateFormat={UIUtils.DATE_FORMAT_FOR_DISPLAY_DATEPICKER}
                                onChange={this.handleApproveByDateChange}
                    />
                  </div>
                </div>
                <div className="row approval-pad-row">
                  <div className="col-12">
                    <label htmlFor="proposeComments">
                      {t("Description")} *:
                    </label>
                    <textarea id="proposeComments"
                              rows="5"
                              style={{
                                width: "100%",
                              }}
                              placeholder={t("Describe the proposed changes so that an approver can understand the impact to this electronic record.")}
                              onChange={this.handleCommentChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="modal-container">
                <div className="btn-group">
                  <button id="sendButton"
                          type="button"
                          disabled={errors.length > 0}
                          title={errors.length > 0 ? "Please resolve all errors first." : ""}
                          className="btn btn-primary"
                          onClick={this.handleSendApproval}
                  >
                    {t("Send")}
                  </button>
                  <button id="cancelProposalButton"
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={this.handleCancel}
                  >
                    {t("Cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getModelName() {
    const {parent} = this.props;
    return parent.securityTypeName || parent.baseTypeName || parent.state.securityTypeName;
  }

  getInstance() {
    const {parent} = this.props;
    const parentState = parent.state;
    // In a listing, the selected instance is in state.instance.
    return parentState && parentState.instance ? parentState.instance : parentState;
  }
}

export default I18NWrapper.wrap(ApprovalRequestPopup, "editor");
// i18next-extract-mark-ns-stop editor
