"use strict";

import {
  generateDocumentsValue, generateVersionValue
} from "../../export/configurable_tables_export_value_generator";
import {
  ABOUT_SECTION, FORMULATION_QUANTITY_SECTION,
  PROPERTIES_SECTION, QUALIFICATION_SECTION,
  REFERENCES_SECTION, REGULATORY_SECTION
} from "../constants/configurable_tables_sections_config";
import {
  COMMON_VARIABLE_PARENT_COLUMNS, HIDDEN,
} from "../constants/configurable_tables_columns_config";
import { FIELD_PROPS } from "../constants/configurable_tables_field_props_config";
import { FILTER_TYPES } from "../../columnOperations/configurable_tables_column_operations_constants";
import RecordFields from "../record_fields";
import { EXTRA_LARGE_COLUMN } from "../../tables/configurable_tables_constants";
import {
  ABOUT_COL_SPAN, FORMULATION_QUANTITY_COL_SPAN,
  PROPERTIES_COL_SPAN, QUALIFICATION_COL_SPAN,
  REFERENCE_AND_STANDARD_COL_SPAN,
  REGULATORY_COL_SPAN
} from "../constants/configurable_tables_col_spans_config";
import CommonEditables from "../../../../server/common/editables/common_editables";

/**
 * This class lists all Library Material fields to be shown in export, column selection and tables.
 */
export default class LibraryMaterialFields extends RecordFields {

  get title() {
    return "Library Materials";
  }

  get fields() {
    return [
      ...COMMON_VARIABLE_PARENT_COLUMNS,
      // About
      {
        title: "Name",
        prop: FIELD_PROPS.NAME,
        section: ABOUT_SECTION,
        colspan: ABOUT_COL_SPAN,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) => columnGenerator.generateNameColumn("Name", FIELD_PROPS.NAME, process),
      },
      {
        title: "Version",
        prop: FIELD_PROPS.VERSION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        showColumnSelectionOn: HIDDEN,
        value: generateVersionValue,
        tableValue: (columnGenerator) => columnGenerator.generateVersionColumn("Version"),
      },
      {
        title: "Category",
        prop: FIELD_PROPS.CATEGORY,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
      },
      {
        title: "Used in Projects",
        prop: FIELD_PROPS.USED_IN_PROJECTS,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        tableValue: (columnGenerator) => columnGenerator.generateUsedInProjectsColumn(),
      },
      {
        title: "Description",
        prop: FIELD_PROPS.DESCRIPTION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator) => columnGenerator.generateTextAreaColumn("Description", FIELD_PROPS.DESCRIPTION),
      },
      // Quantity
      {
        title: "Descriptive Unit (Absolute)",
        prop: FIELD_PROPS.DESCRIPTIVE_UNIT_ABSOLUTE,
        colspan: FORMULATION_QUANTITY_COL_SPAN,
        section: FORMULATION_QUANTITY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
      },
      // Qualification
      {
        title: "Supplier",
        prop: FIELD_PROPS.SUPPLIER,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        defaultValue: true,
      },
      {
        title: "Material/Part Qualified",
        prop: FIELD_PROPS.MATERIAL_QUALIFIED,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("Material/Part Qualified", FIELD_PROPS.MATERIAL_QUALIFIED)
      },
      {
        title: "Supplier Part Number",
        prop: FIELD_PROPS.PART_NUMBER,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
      },
      {
        title: "Internal Part Number",
        prop: FIELD_PROPS.INTERNAL_PART_NUMBER,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
      },
      {
        title: "Effective Date",
        prop: FIELD_PROPS.EFFECTIVE_DATE,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        filterType: FILTER_TYPES.DATE,
      },
      {
        title: "Expiration Date",
        prop: FIELD_PROPS.EXPIRATION_DATE,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        filterType: FILTER_TYPES.DATE,
      },
      {
        title: "GMP",
        prop: FIELD_PROPS.GMP,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: () => CommonEditables.BOOLEAN_VALUES,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("GMP", FIELD_PROPS.GMP)
      },
      {
        title: "Qualification Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.QUALIFICATION_LINKS,
        colspan: QUALIFICATION_COL_SPAN,
        section: QUALIFICATION_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.QUALIFICATION_LINKS),
      },
      // Regulatory
      {
        title: "Regulatory Filing",
        prop: FIELD_PROPS.REGULATORY_FILING,
        colspan: REGULATORY_COL_SPAN,
        section: REGULATORY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        filterType: FILTER_TYPES.COMBO_BOX,
      },
      {
        title: "Reference Number",
        prop: FIELD_PROPS.REFERENCE_NUMBER,
        colspan: REGULATORY_COL_SPAN,
        section: REGULATORY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
      },
      {
        title: "Letter of Authorization",
        prop: FIELD_PROPS.AUTHORIZATION_LETTER,
        colspan: REGULATORY_COL_SPAN,
        section: REGULATORY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
      },
      {
        title: "Regulatory Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.REGULATORY_LINKS,
        colspan: REGULATORY_COL_SPAN,
        section: REGULATORY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.REGULATORY_LINKS),
      },
      // Properties
      {
        title: "Drug Substance Type",
        prop: FIELD_PROPS.DRUG_SUBSTANCE_TYPE,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
      },
      {
        title: "Form",
        prop: FIELD_PROPS.FORM,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
      },
      {
        title: "Density",
        prop: FIELD_PROPS.DENSITY,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
      },
      {
        title: "Density Conditions",
        prop: FIELD_PROPS.DENSITY_CONDITIONS,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
      },
      {
        title: "Chemical Structure",
        prop: FIELD_PROPS.CHEMICAL_STRUCTURE,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
      },
      {
        title: "Empirical Formula",
        prop: FIELD_PROPS.EMPIRICAL_FORMULA,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
      },
      {
        title: "Molecular Weight",
        prop: FIELD_PROPS.MOLECULAR_WEIGHT,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.NUMERIC,
      },
      {
        title: "Chemical Name (CAS)",
        prop: FIELD_PROPS.CHEMICAL_NAME_CAS,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
      },
      {
        title: "Chemical Name (IUPAC)",
        prop: FIELD_PROPS.CHEMICAL_NAME_IUPAC,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
      },
      {
        title: "Other Names",
        prop: FIELD_PROPS.OTHER_NAMES,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
      },
      {
        title: "INN/USAN",
        prop: FIELD_PROPS.INN_USAN,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
      },
      {
        title: "CAS Registry Number",
        prop: FIELD_PROPS.CAS_REGISTRY_NUMBER,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
      },
      {
        title: "Compendial Standard",
        prop: FIELD_PROPS.COMPENDIAL_STANDARD,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        tableValue: (columnGenerator) => columnGenerator.generateMultiSelectionColumn("Compendial Standard", FIELD_PROPS.COMPENDIAL_STANDARD, false, EXTRA_LARGE_COLUMN),
      },
      {
        title: "COA/COC",
        prop: FIELD_PROPS.CERTIFICATE_OF_ANALYSIS,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: () => CommonEditables.BOOLEAN_VALUES,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("COA/COC", FIELD_PROPS.CERTIFICATE_OF_ANALYSIS)
      },
      {
        title: "Properties Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.PROPERTIES_LINKS,
        colspan: PROPERTIES_COL_SPAN,
        section: PROPERTIES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) => columnGenerator.generateDocumentsColumn("Source Documents", process, FIELD_PROPS.PROPERTIES_LINKS),
      },
      // References & Standards
      {
        title: `References & Standards`,
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.REFERENCES,
        colspan: REFERENCE_AND_STANDARD_COL_SPAN,
        section: REFERENCES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) => columnGenerator.generateDocumentsColumn("Source Documents", process, FIELD_PROPS.REFERENCES),
      },
    ];
  }
}
