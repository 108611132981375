"use strict";

import BaseAutoBind from "../../base_auto_bind";

/**
 * This class is used to store a key (typeCode and id) for a given record.
 */
export default class ProcessMapKey extends BaseAutoBind {
  constructor(typeCode, id) {
    super();
    this._typeCode = typeCode;
    this._id = id;
  }

  get typeCode() {
    return this._typeCode;
  }

  get id() {
    return this._id;
  }
}
