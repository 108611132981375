"use strict";

import * as UIUtils from "../../ui_utils";
import { formatDocuments } from "../export/configurable_tables_export_value_generator";
import BaseReactComponent from "../../base_react_component";
import { RISK_TYPE_ENUM } from "../../helpers/constants/constants";
import { FIELD_PROPS } from "../fieldsConfig/constants/configurable_tables_field_props_config";
import { formatFloat, formatValue } from "../fieldsConfig/fields_formatter";
import CommonRiskUtils from "../../../server/common/misc/common_risk_utils";
import { PROCESS_LEVEL_ID } from "../../techTransfer/tech_transfer_constants";
import { fillInCriticalities, fillInRiskLinks } from "../../reports/canned_reports/canned_report_helper";

/**
 * This class holds functions to adapt some of composite values (ex. risk values) within records
 */
export default class ConfigurableTablesAdapterHelper extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.results = props.results;
    this.project = props.project;
    this.activeMap = props.activeMap;
    this.parentPropName = props.parentPropName;
  }

  formatRecordData(record, typeaheadOptions, effectiveRMP, documents) {
    this.attachParent(record);

    if (record.riskLinks) {
      if (Array.isArray(record.riskLinks)) {
        documents = UIUtils.deepClone(record.riskLinks);
      } else {
        // eslint-disable-next-line no-unused-vars
        documents = JSON.parse(record.riskLinks);
      }
    }

    record.riskLinks = fillInRiskLinks(record, typeaheadOptions);

    record.effectiveModelRMP = CommonRiskUtils.filterRMPByType(effectiveRMP, record.typeCode);

    record.unitOperationId = record.UnitOperation ? record.UnitOperation.id : PROCESS_LEVEL_ID;
    record.unitOperation = record.UnitOperation ? record.UnitOperation.name : "";

    record.stepId = record.Step ? record.Step.id : PROCESS_LEVEL_ID;
    record.step = record.Step ? record.Step.name : "";

    if (this.modelName === "Process Parameters") {
      record.lowerOperatingLimit = formatFloat(record.lowerOperatingLimit);
      record.upperOperatingLimit = formatFloat(record.upperOperatingLimit);
    }

    record.lowerLimit = record.lowerLimit === "NA" ? "" : record.lowerLimit;
    record.upperLimit = record.upperLimit === "NA" ? "" : record.upperLimit;
    record.estimatedSampleSize = formatFloat(record.estimatedSampleSize);

    record.ccp = formatValue(record.ccp);
    record.samplingPlan = formatValue(record.samplingPlan);

    fillInCriticalities(record.effectiveModelRMP, record, true);
    this.parseRiskValuesToNumbers(record);
    this.fillInObjectListInformation(record, FIELD_PROPS.CONTROL_METHODS, record.ControlMethods);
  }

  getRiskLinksSummary(riskLinks) {
    let links = UIUtils.deepClone(riskLinks);
    return links.map(link => {
      let message = `${link.riskLinkWithoutId}`;
      if (link.effect && link.effect.length > 0) {
        message += ` Effect on Attribute: ${link.effect}`;
      }

      message += ` Impact: ${link.impact}`;
      message += ` Uncertainty: ${link.uncertainty}`;
      message += link.justification ? ` Justification: ${link.justification}` : "";
      return message;
    });
  }

  addAcceptanceCriteriaInfo(record) {
    const acceptanceCriteriaRanges = record?.Requirement?.AcceptanceCriteriaRanges;
    if (acceptanceCriteriaRanges && acceptanceCriteriaRanges.length === 1) {
      const acceptanceCriteria = acceptanceCriteriaRanges[0];
      record.lowerLimit = acceptanceCriteria.lowerLimit;
      record.upperLimit = acceptanceCriteria.upperLimit;
      record.measurementUnits = acceptanceCriteria.measurementUnits;
      record.target = acceptanceCriteria.target;
      record.targetJustification = acceptanceCriteria.targetJustification;
    }
  }

  formatDocuments(record, prop) {
    return formatDocuments(record, prop);
  }

  formatRecordsList(list) {
    return (list || []).map(record => record.name).sort();
  }

  fillInObjectListInformation(record, prop, list) {
    record[`${prop}FilterOptions`] = this.formatRecordsList(list);
    record[prop] = record[`${prop}FilterOptions`].sort().join(", ");
    record[`${prop}FormattedValue`] = list;
  }

  getRiskLabel(record, prop, riskType, rmp, getForRiskLabel = false) {
    if (!record.riskInfo) {
      throw new Error("Missing RiskInfo");
    }

    let riskScale = getForRiskLabel ? record.riskInfo[riskType].scaleForRiskLabel : record.riskInfo[riskType].scale;
    if (!riskScale) {
      riskScale = record.riskInfo[riskType].scale;
    }

    return getForRiskLabel ? riskScale?.riskLabel : riskScale?.scoreLabel;
  }

  addCommonRiskPropsForFiltration(record, hasRiskLinks = false) {
    if (this.project.productRiskAssessmentType !== "Risk Ranking" && !hasRiskLinks) {
      record.impactLabel = this.getRiskLabel(record, FIELD_PROPS.IMPACT, RISK_TYPE_ENUM.IMPACT, record.effectiveModelRMP, false);
      record.uncertaintyLabel = this.getRiskLabel(record, FIELD_PROPS.UNCERTAINTY, RISK_TYPE_ENUM.UNCERTAINTY, record.effectiveModelRMP, false);
    }
    record.capabilityRiskLabel = this.getRiskLabel(record, FIELD_PROPS.CAPABILITY_RISK, RISK_TYPE_ENUM.CAPABILITY_RISK, record.effectiveModelRMP, false);
    record.detectabilityRiskLabel = this.getRiskLabel(record, FIELD_PROPS.DETECTABILITY_RISK, RISK_TYPE_ENUM.DETECTABILITY_RISK, record.effectiveModelRMP, false);
  }

  attachParent(record) {
    if (record) {
      let source;
      if (record.MaterialId) {
        source = this.results.mtMap[record.MaterialId];
      } else if (record.ProcessComponentId) {
        source = this.results.prcMap[record.ProcessComponentId];
      }
      record.source = source;
    }
  }

  parseRiskValuesToNumbers(record) {
    const riskInfo = record.riskInfo;
    record.impact = parseFloat(riskInfo[RISK_TYPE_ENUM.IMPACT].value);
    record.uncertainty = parseFloat(riskInfo[RISK_TYPE_ENUM.UNCERTAINTY].value);
    record.capabilityRisk = parseFloat(riskInfo[RISK_TYPE_ENUM.CAPABILITY_RISK].value);
    record.detectabilityRisk = parseFloat(riskInfo[RISK_TYPE_ENUM.DETECTABILITY_RISK].value);
    record.criticality = parseFloat(riskInfo[RISK_TYPE_ENUM.CRITICALITY].value);
    record.criticalityPerc = parseFloat(riskInfo[RISK_TYPE_ENUM.CRITICALITY].normalizedValue);
    // We no longer use Max Criticality. We always use the Criticality value
    record.maxCriticality = parseFloat(riskInfo[RISK_TYPE_ENUM.CRITICALITY].value);
    record.maxCriticalityPerc = parseFloat(riskInfo[RISK_TYPE_ENUM.CRITICALITY].normalizedValue);
    record.processRisk = parseFloat(riskInfo[RISK_TYPE_ENUM.PROCESS_RISK].value);
    record.processRiskPerc = parseFloat(riskInfo[RISK_TYPE_ENUM.PROCESS_RISK].normalizedValue);
    record.RPN = parseFloat(riskInfo[RISK_TYPE_ENUM.RPN].value);
    record.RPNPerc = parseFloat(riskInfo[RISK_TYPE_ENUM.RPN].normalizedValue);
  }
}

