import * as React from "react";
import {Button} from "@progress/kendo-react-buttons";
import alignTopIcon from "../../images/documentEditor/align-top-icon.svg";
import alignMiddleIcon from "../../images/documentEditor/align-middle-icon.svg";
import alignBottomIcon from "../../images/documentEditor/align-bottom-icon.svg";
import {ToolProps} from "../common/types";

const VERTICAL_ALIGNMENT = {
  TOP: "Top",
  MIDDLE: "Middle",
  BOTTOM: "Bottom",
};

const VerticalAlignTool =
  (align: (typeof VERTICAL_ALIGNMENT)[keyof typeof VERTICAL_ALIGNMENT]) =>
    (props: ToolProps) => {
      const {view} = props;
      const onClick = () => {
        if (!view) {
          return;
        }

        // Find the nearest table cell of the current selection
        for (let cellNumber = 0; cellNumber < view.state.selection.ranges.length; cellNumber++) {
          const cell = view.state.selection.ranges[cellNumber];
          const anchor = cell.$from;
          let targetNode = null;
          let targetParentNode = null;
          for (let d = anchor.depth; d > 0; d--) {
            const node = anchor.node(d);
            if (!targetNode && node.type.name === "table_cell") {
              targetNode = node;
            }

            if (node.type.name === "table_row") {
              targetParentNode = node;
              break;
            }
          }

          if (!targetNode || !targetParentNode) {
            return;
          }

          let found = false;
          view.state.doc.descendants((node, pos, parent) => {
            // If we found the node, we will stop descending into node. If we don't do this,
            // it will keep descending into another node.
            if (found) {
              return false;
            }

            if (node.eq(targetNode) && targetParentNode.eq(parent)) {
              let newStyle = targetNode.attrs.style || "";
              // If the element style already has vertical-align, we need to replace it
              // with the new value
              if (newStyle.includes("vertical-align")) {
                const startIndex = newStyle.indexOf("vertical-align");
                const endIndex = newStyle.indexOf(";", startIndex);
                const currentBackgroundColorValue = newStyle.substring(startIndex, endIndex + 1);
                newStyle = newStyle.replace(
                  currentBackgroundColorValue,
                  `vertical-align: ${align};`
                );
              } else {
                newStyle = `${newStyle}vertical-align: ${align};`;
              }
              view.dispatch(view.state.tr.setNodeAttribute(pos, "style", newStyle));
              found = true;
            }
          });
        }
      };

      let icon = alignTopIcon;

      if (align === VERTICAL_ALIGNMENT.MIDDLE) {
        icon = alignMiddleIcon;
      } else if (align === VERTICAL_ALIGNMENT.BOTTOM) {
        icon = alignBottomIcon;
      }

      return <Button
        onClick={onClick}
        id={"verticalAlign" + align}
        imageUrl={icon}
        title={align + " align"}
        onMouseDown={e => e.preventDefault()}
        onPointerDown={e => e.preventDefault()}
      />;
    };

export const VerticalAlignTopTool = VerticalAlignTool(VERTICAL_ALIGNMENT.TOP);
export const VerticalAlignMiddleTool = VerticalAlignTool(VERTICAL_ALIGNMENT.MIDDLE);
export const VerticalAlignBottomTool = VerticalAlignTool(VERTICAL_ALIGNMENT.BOTTOM);
