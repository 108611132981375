"use strict";

import React, { Component, Fragment } from "react";
import CommonUtils from "../../../../server/common/generic/common_utils";
import { getURLByTypeCodeAndId } from "../../../helpers/url_helper";
import { getStampFromState } from "../utils/risk_tables_helper";
import { ValueCell } from "./value_cell";

/**
 * Renders a criticality justification in the standard way for a risk table report.
 */
export class CriticalityJustification extends Component {

  renderJustification(justification) {
    return <ValueCell value={justification} showNoneIfEmpty />;
  }

  renderAttribute(item) {
    const key = CommonUtils.parseKey(item.attributeKey);

    return key ? (
      <Fragment>
        <div>
          <a href={getURLByTypeCodeAndId(key.typeCode, "View", key.id)}
             target="_blank"
             rel="noopener noreferrer"
             className="risk-tables-row-header-cell"
          >
            {item.attributeKey + " - " + item.attributeName}
          </a>
        </div>
        <div>
          <span className="risk-tables-column-stamp">{getStampFromState(item.state)}</span>
        </div>
      </Fragment>
    ) : "";
  }

  render() {
    const {item} = this.props;

    return (
      <div className="risk-tables-multi-link">
        {this.renderAttribute(item)}
        {this.renderJustification(item.justification)}
      </div>
    );
  }
}
