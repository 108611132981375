"use strict";

import React from "react";
import ComboBoxEditor from "../editors/combo_box_editor";
import TextEditor from "../editors/text_editor";
import { PAPER_DATA_UPDATE_TYPE } from "../../../../constants/import_constants";
import { isQualitativeMeasure } from "../../../../../../server/common/editables/common_editables";
import BaseReactComponent from "../../../../../base_react_component";

/**
 * This is responsible for rendering the editor for a single Attribute measurement in the AI import batch data
 * edit screen
 */
export default class MeasurementEditor extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {id, attribute, measurement, index, disabled, onChange} = this.props;

    return <div className="attribute-measurement">
      {isQualitativeMeasure(attribute.type) ? (
        <ComboBoxEditor id={id}
                        options={["Pass", "Fail"]}
                        value={measurement.measurement}
                        confidence={measurement.confidence}
                        validationStatus={measurement.validationStatus}
                        disabled={disabled}
                        supportsDelete={true}
                        onDeleteButtonClicked={onChange.bind(this, {
                          type: PAPER_DATA_UPDATE_TYPE.DELETE_MEASUREMENT,
                          attributeUUID: attribute.uuid,
                          measurementIndex: index
                        })}
                        onChange={onChange.bind(this, {
                          type: PAPER_DATA_UPDATE_TYPE.MODIFY_MEASUREMENT,
                          attributeUUID: attribute.uuid,
                          measurementIndex: index
                        })}
        />
      ) : (
        <TextEditor id={id}
                    value={measurement.measurement}
                    validationStatus={measurement.validationStatus}
                    confidence={measurement.confidence}
                    disabled={disabled}
                    type="numeric"
                    supportsDelete={true}
                    required={true}
                    onDeleteButtonClicked={onChange.bind(this, {
                      type: PAPER_DATA_UPDATE_TYPE.DELETE_MEASUREMENT,
                      attributeUUID: attribute.uuid,
                      measurementIndex: index
                    })}
                    onChange={onChange.bind(this, {
                      type: PAPER_DATA_UPDATE_TYPE.MODIFY_MEASUREMENT,
                      attributeUUID: attribute.uuid,
                      measurementIndex: index
                    })}
        />
      )}
    </div>;
  }
}
