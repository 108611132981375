/**
 * @hidden
 */
export var defaultFormat = 'd';
/**
 * @hidden
 */
export var defaultFormatPlaceholder = 'wide';
/**
 * @hidden
 */
export var padZero = function (length) { return new Array(Math.max(length, 0)).fill('0').join(''); };
/**
 * @hidden
 */
export var approximateStringMatching = function (oldTextOrigin, oldFormat, newTextOrigin, selectionStart) {
    /*
      Remove the right part of the cursor.
      oldFormat = oldFormat.substring(0, caret + oldText.length - newText.length);
    */
    var oldTextSeparator = oldTextOrigin[selectionStart + oldTextOrigin.length - newTextOrigin.length];
    var oldText = oldTextOrigin.substring(0, selectionStart + oldTextOrigin.length - newTextOrigin.length);
    var newText = newTextOrigin.substring(0, selectionStart);
    var diff = [];
    /* Handle the typing of a single character over the same selection. */
    if (oldText === newText && selectionStart > 0) {
        diff.push([oldFormat[selectionStart - 1], newText[selectionStart - 1]]);
        return diff;
    }
    if (oldText.indexOf(newText) === 0 &&
        (newText.length === 0 || oldFormat[newText.length - 1] !== oldFormat[newText.length])) {
        /* Handle Delete/Backspace. */
        var deletedSymbol = '';
        /*
            The whole text is replaced by the same character.
            A nasty patch is required to keep the selection in the first segment.
        */
        if (newText.length === 1) {
            diff.push([oldFormat[0], newText[0]]);
        }
        for (var i = newText.length; i < oldText.length; i++) {
            if (oldFormat[i] !== deletedSymbol && oldFormat[i] !== '_') {
                deletedSymbol = oldFormat[i];
                diff.push([deletedSymbol, '']);
            }
        }
        return diff;
    }
    /*
        Handle the insertion of the text (the new text is longer than the previous one).
        Handle the typing over a literal as well.
    */
    if (newText.indexOf(oldText) === 0 || oldFormat[selectionStart - 1] === '_') {
        var symbol = oldFormat[0];
        for (var i = Math.max(0, oldText.length - 1); i < oldFormat.length; i++) {
            if (oldFormat[i] !== '_') {
                symbol = oldFormat[i];
                break;
            }
        }
        return [[symbol, newText[selectionStart - 1]]];
    }
    /* Handle the entering of a space or a separator for navigating to the next item. */
    if (newText[newText.length - 1] === ' ' || newText[newText.length - 1] === oldTextSeparator) {
        return [[oldFormat[selectionStart - 1], '_']];
    }
    /* Handle typing over a correctly selected part. */
    return [[oldFormat[selectionStart - 1], newText[selectionStart - 1]]];
};
/**
 * @hidden
 */
export var dateSymbolMap = function (map, part) { map[part.pattern[0]] = part.type; return map; };
/**
 * @hidden
 */
export var isInRange = function (candidate, min, max) { return (candidate === null || !((min && min > candidate) || (max && max < candidate))); };
/**
 * @hidden
 */
export var invalidClasses = ['k-invalid'];
/** @hidden */
export var wrapperClasses = ['k-widget', 'k-dateinput'];
