var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Draggable, guid, classNames, focusFirstFocusableChild, getTabIndex, createPropsContext, withPropsContext } from '@progress/kendo-react-common';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
import { Slider } from './../main';
import { fitIntoBounds, isPresent } from './utils/misc';
import { getColorFromHue, parseColor, getHSV, getColorFromHSV, getRGBA, getColorFromRGBA } from './utils/color-parser';
import { cacheHsva, removeCachedColor, getCachedHsva, getCachedRgba, getCachedHex, cacheRgba, cacheHex } from './utils/color-cache';
import ColorInput from './ColorInput';
import { ColorContrastLabels } from './ColorContrastLabels';
import { ColorContrastSvg } from './ColorContrastSvg';
import { provideLocalizationService } from '@progress/kendo-react-intl';
import { messages, colorGradientHueSliderLabel, colorGradientAlphaSliderLabel } from './../messages';
var FORMAT = 'rgba';
var DEFAULT_SELECTED_COLOR = 'rgba(255, 255, 255, 1)';
/** @hidden */
var ColorGradientWithoutContext = /** @class */ (function (_super) {
    __extends(ColorGradientWithoutContext, _super);
    function ColorGradientWithoutContext(props) {
        var _this = _super.call(this, props) || this;
        /**
         * @hidden
         */
        _this.wrapper = null;
        /**
         * @hidden
         */
        _this.onHexChange = function (hex, value, event) {
            if (_this.isUncontrolled) {
                var hsva = getHSV(value);
                _this.setState({ hsva: hsva, backgroundColor: getColorFromHue(hsva.h), rgba: getRGBA(value), hex: hex });
            }
            else {
                cacheHex(_this.state.guid, value, hex);
            }
            _this.dispatchChangeEvent(value, event, event.nativeEvent);
        };
        /**
         * @hidden
         */
        _this.onRgbaChange = function (rgba, event) {
            var value = getColorFromRGBA(rgba);
            if (_this.isUncontrolled) {
                var hsva = getHSV(value);
                var hex = parseColor(value, 'hex');
                _this.setState({ hsva: hsva, backgroundColor: getColorFromHue(hsva.h), rgba: rgba, hex: hex });
            }
            else {
                cacheRgba(_this.state.guid, value, rgba);
            }
            _this.dispatchChangeEvent(value, event.syntheticEvent, event.nativeEvent);
        };
        /**
         * @hidden
         */
        _this.onAlphaSliderChange = function (event) {
            _this.handleHsvaChange(Object.assign({}, _this.state.hsva, { a: event.value / 100 }), event.syntheticEvent, event.nativeEvent);
        };
        /**
         * @hidden
         */
        _this.onHueSliderChange = function (event) {
            _this.handleHsvaChange(Object.assign({}, _this.state.hsva, { h: event.value }), event.syntheticEvent, event.nativeEvent);
        };
        /**
         * @hidden
         */
        _this.onDrag = function (e) {
            _this.gradientWrapper.classList.add('k-dragging');
            _this.changePosition(e.event);
        };
        /**
         * @hidden
         */
        _this.onRelease = function () {
            _this.gradientWrapper.classList.remove('k-dragging');
        };
        /**
         * @hidden
         */
        _this.onGradientWrapperClick = function (event) {
            _this.changePosition(event);
        };
        /**
         * @hidden
         */
        _this.changePosition = function (event) {
            var gradientRectMetrics = _this.getGradientRectMetrics();
            var deltaX = event.clientX - gradientRectMetrics.left;
            var deltaY = event.clientY - gradientRectMetrics.top;
            var left = fitIntoBounds(deltaX, 0, gradientRectMetrics.width);
            var top = fitIntoBounds(deltaY, 0, gradientRectMetrics.height);
            var hsva = Object.assign({}, _this.state.hsva, { s: left / gradientRectMetrics.width, v: 1 - top / gradientRectMetrics.height });
            _this.handleHsvaChange(hsva, event, event.nativeEvent);
        };
        /**
         * @hidden
         */
        _this.onFocus = function (nativeEvent) {
            if (_this.props.onFocus) {
                _this.props.onFocus.call(undefined, { nativeEvent: nativeEvent, target: _this });
            }
        };
        /**
         * @hidden
         */
        _this.focus = function () {
            focusFirstFocusableChild(_this.wrapper);
        };
        validatePackage(packageMetadata);
        var value = props.value || props.defaultValue || parseColor(DEFAULT_SELECTED_COLOR, FORMAT);
        _this.state = Object.assign({}, ColorGradientWithoutContext.getStateFromValue(value), { isFirstRender: true, guid: guid() });
        return _this;
    }
    /**
     * @hidden
     */
    ColorGradientWithoutContext.getDerivedStateFromProps = function (props, state) {
        if (props.value && !state.isFirstRender) {
            // The component is in controlled mode.
            return ColorGradientWithoutContext.getStateFromValue(props.value, state.guid);
        }
        return null;
    };
    /**
     * @hidden
     */
    ColorGradientWithoutContext.prototype.componentDidMount = function () {
        this.setAlphaSliderBackground(this.state.backgroundColor);
        if (this.state.isFirstRender) {
            this.setState({ isFirstRender: false });
        }
    };
    /**
     * @hidden
     */
    ColorGradientWithoutContext.prototype.componentWillUnmount = function () {
        removeCachedColor(this.state.guid);
    };
    /**
     * @hidden
     */
    ColorGradientWithoutContext.prototype.componentDidUpdate = function (_, prevState) {
        if (prevState.backgroundColor !== this.state.backgroundColor) {
            this.setAlphaSliderBackground(this.state.backgroundColor);
        }
    };
    /**
     * @hidden
     */
    ColorGradientWithoutContext.prototype.renderRectangleDragHandle = function () {
        var style = {};
        if (!this.state.isFirstRender) {
            var gradientRectMetrics = this.getGradientRectMetrics();
            var top_1 = (1 - this.state.hsva.v) * gradientRectMetrics.height;
            var left = this.state.hsva.s * gradientRectMetrics.width;
            style.top = "".concat(top_1, "px");
            style.left = "".concat(left, "px");
        }
        return React.createElement("div", { className: "k-hsv-draghandle k-draghandle", style: style });
    };
    /**
     * @hidden
     */
    ColorGradientWithoutContext.prototype.handleHsvaChange = function (hsva, syntheticEvent, nativeEvent) {
        var value = getColorFromHSV(hsva);
        if (this.isUncontrolled) {
            var hex = parseColor(value, 'hex');
            this.setState({ hsva: hsva, backgroundColor: getColorFromHue(hsva.h), rgba: getRGBA(value), hex: hex });
        }
        else {
            cacheHsva(this.state.guid, value, hsva);
        }
        this.dispatchChangeEvent(value, syntheticEvent, nativeEvent);
    };
    /**
     * @hidden
     */
    ColorGradientWithoutContext.prototype.dispatchChangeEvent = function (value, syntheticEvent, nativeEvent) {
        if (this.props.onChange) {
            this.props.onChange.call(undefined, { syntheticEvent: syntheticEvent, nativeEvent: nativeEvent, target: this, value: value });
        }
    };
    /**
     * @hidden
     */
    ColorGradientWithoutContext.getStateFromValue = function (value, componentGuid) {
        if (!isPresent(parseColor(value, 'hex'))) {
            // Validate/sanitize the input.
            value = DEFAULT_SELECTED_COLOR;
        }
        var hsva = getCachedHsva(componentGuid, value) || getHSV(value);
        var rgba = getCachedRgba(componentGuid, value) || getRGBA(value);
        var hex = getCachedHex(componentGuid, value) || parseColor(value, 'hex');
        var backgroundColor = getColorFromHue(hsva.h);
        return { hsva: hsva, backgroundColor: backgroundColor, rgba: rgba, hex: hex };
    };
    /**
     * @hidden
     */
    ColorGradientWithoutContext.prototype.setAlphaSliderBackground = function (backgroundColor) {
        if (this.props.opacity && this.alphaSlider && this.alphaSlider.sliderTrack) {
            this.alphaSlider.sliderTrack.style.background = "linear-gradient(to top, transparent, ".concat(backgroundColor, ")");
        }
    };
    Object.defineProperty(ColorGradientWithoutContext.prototype, "isUncontrolled", {
        /**
         * @hidden
         */
        get: function () {
            return this.props.value === undefined;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    ColorGradientWithoutContext.prototype.getGradientRectMetrics = function () {
        return this.gradientWrapper.getBoundingClientRect();
    };
    /**
     * @hidden
     */
    ColorGradientWithoutContext.prototype.render = function () {
        var _this = this;
        var lS = provideLocalizationService(this);
        var className = classNames('k-colorgradient', { 'k-disabled': this.props.disabled });
        return (React.createElement("div", { id: this.props.id, className: className, "aria-disabled": this.props.disabled ? 'true' : undefined, style: this.props.style, ref: function (el) { return _this.wrapper = el; }, tabIndex: getTabIndex(this.props.tabIndex, this.props.disabled), "aria-labelledby": this.props.ariaLabelledBy, "aria-describedby": this.props.ariaDescribedBy, onFocus: this.onFocus },
            React.createElement("div", { className: "k-colorgradient-canvas k-hstack" },
                React.createElement("div", { className: "k-hsv-rectangle", style: { background: this.state.backgroundColor } },
                    React.createElement(Draggable, { onDrag: this.onDrag, onRelease: this.onRelease, ref: function (el) { return _this.gradientWrapper = el ? el.element : undefined; } },
                        React.createElement("div", { className: "k-hsv-gradient", style: { touchAction: 'none' }, onClick: this.onGradientWrapperClick }, this.renderRectangleDragHandle())),
                    this.props.backgroundColor && !this.state.isFirstRender &&
                        React.createElement(ColorContrastSvg, { metrics: this.gradientWrapper ? this.getGradientRectMetrics() : undefined, hsva: this.state.hsva, backgroundColor: this.props.backgroundColor })),
                React.createElement("div", { className: "k-hsv-controls k-hstack" },
                    React.createElement(Slider, { value: this.state.hsva.h, buttons: false, vertical: true, min: 0, max: 360, step: 5, onChange: this.onHueSliderChange, className: "k-hue-slider k-colorgradient-slider", disabled: this.props.disabled, ariaLabel: lS.toLanguageString(colorGradientHueSliderLabel, messages[colorGradientHueSliderLabel]) }),
                    this.props.opacity &&
                        React.createElement(Slider, { value: isPresent(this.state.hsva.a) ? this.state.hsva.a * 100 : 100, buttons: false, vertical: true, min: 0, max: 100, step: 1, ariaLabel: lS.toLanguageString(colorGradientAlphaSliderLabel, messages[colorGradientAlphaSliderLabel]), onChange: this.onAlphaSliderChange, className: "k-alpha-slider k-colorgradient-slider", disabled: this.props.disabled, ref: function (el) { return _this.alphaSlider = el; } }))),
            React.createElement(ColorInput, { rgba: this.state.rgba, onRgbaChange: this.onRgbaChange, hex: this.state.hex, onHexChange: this.onHexChange, opacity: this.props.opacity, disabled: this.props.disabled }),
            this.props.backgroundColor && (React.createElement(ColorContrastLabels, { bgColor: getRGBA(this.props.backgroundColor), rgba: this.state.rgba }))));
    };
    ColorGradientWithoutContext.displayName = 'ColorGradient';
    /**
     * @hidden
     */
    ColorGradientWithoutContext.propTypes = {
        defaultValue: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        opacity: PropTypes.bool,
        backgroundColor: PropTypes.string,
        disabled: PropTypes.bool,
        style: PropTypes.any,
        id: PropTypes.string,
        ariaLabelledBy: PropTypes.string,
        ariaDescribedBy: PropTypes.string
    };
    /**
     * @hidden
     */
    ColorGradientWithoutContext.defaultProps = {
        opacity: true
    };
    return ColorGradientWithoutContext;
}(React.Component));
export { ColorGradientWithoutContext };
/**
 * Represents the PropsContext of the `ColorGradient` component.
 * Used for global configuration of all `ColorGradient` instances.
 *
 * For more information, refer to the [Inputs Props Context]({% slug props-context_inputs %}) article.
 */
export var ColorGradientPropsContext = createPropsContext();
/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the component the same as the type */
/**
 * Represents the [KendoReact ColorGradient component]({% slug overview_colorgradient %}).
 *
 * Accepts properties of type [ColorGradientProps]({% slug api_inputs_colorgradientprops %}).
 * Obtaining the `ref` returns an object of type [ColorGradientHandle]({% slug api_inputs_colorgradienthandle %}).
 *
 * @example
 * ```jsx
 * class App extends React.Component {
 *     render() {
 *         return <ColorGradient />;
 *     }
 * }
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var ColorGradient = withPropsContext(ColorGradientPropsContext, ColorGradientWithoutContext);
ColorGradient.displayName = 'KendoReactColorGradient';
