"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import BaseFilter from "../common/base_filter";

const DEFAULT_FILTER_VALUES = {
  showCriticalOnly: false,
  showMTs: false,
  showPRCs: false,
  showMAs: true,
  showPPs: true,
  showIAs: true,
  showFAs: true,
};

export const ELEMENT_FILTER_KEYS_TO_NAMES = {
  showMTs: "Input Materials",
  showPRCs: "Process Components",
  showMAs: "Material Attributes",
  showPPs: "Process Parameters",
  showIAs: "Intermediate Attributes",
  showFAs: "Final Attributes",
};

export default class ProcessMapFilter extends BaseFilter {
  constructor(props) {
    super(props);
    this.projectId = this.props.projectId;
  }

  initializeDefaultFilters() {
    super.initializeDefaultFilters();
    const savedUserData = JSON.parse(localStorage.getItem("PROCESS_MAP_FILTERS" + this.projectId) || "[]");
    const filters = Array.isArray(savedUserData) ? DEFAULT_FILTER_VALUES : savedUserData;

    this.setStateSafely({
      filters,
    }, () => {
      this.props.onFiltersUpdated(JSON.stringify(this.state.filters));
    });
  }

  getCSSPrefix() {
    return "process-map";
  }

  getContentsHeight() {
    return 350;
  }

  resizePanelOnly(filterPanel) {
    // Make the height no larger than the canvas
    let canvasHeight = $(".process-map-canvas")[0].clientHeight - 20;
    const panelHeight = Math.min(canvasHeight, this.getContentsHeight() + 5);
    filterPanel.css("height", panelHeight);
  }

  resetFilters(event) {
    super.resetFilters(event);
    let defaultFilterValue = UIUtils.deepClone(DEFAULT_FILTER_VALUES);
    this.setStateSafely({
      filters: defaultFilterValue,
    }, () => {
      this.props.onFiltersUpdated(JSON.stringify(this.state.filters));
    });
  }

  filtersChanged(filter, value) {
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    filters[filter] = value;
    this.setStateSafely({
      filters,
    }, () => {
      this.props.onFiltersUpdated(JSON.stringify(this.state.filters));
    });
  }

  handleCheckBoxFilterChange(filter, event) {
    this.filtersChanged(filter, event.target.checked);
  }

  renderPanelContent() {
    if (this.state && this.state.filters) {
      const {filters} = this.state;
      return (
        <Fragment>
          <div className="process-map-filter-item-row-header">
            <div className="process-map-filter-header">Show:</div>
          </div>
          <div className="process-map-filter-item-row">
            <div className="process-map-filter-field">
              <label id="showCriticalFilterLabel"
                     className="col-form-label"
              >
                <input type="checkbox"
                       id="showCriticalOnlyFilter"
                       checked={!!filters.showCriticalOnly}
                       onChange={this.handleCheckBoxFilterChange.bind(this, "showCriticalOnly")}
                /> Critical/Key Only
              </label>
            </div>
          </div>
          <div className="process-map-filter-item-row-header">
            <div className="process-map-filter-header">Filter by:</div>
          </div>
          <div className="process-map-filter-item-row">
            <div className="process-map-filter-category">Element:</div>
          </div>
          {Object.keys(ELEMENT_FILTER_KEYS_TO_NAMES).map(elementFilter => (
            <div className="process-map-filter-item-row" key={elementFilter}>
              <div className="process-map-filter-field" key={elementFilter}>
                <label id={elementFilter + "FilterLabel"}
                       className="col-form-label"
                >
                  <input type="checkbox"
                         id={elementFilter + "Filter"}
                         checked={!!filters[elementFilter]}
                         onChange={this.handleCheckBoxFilterChange.bind(this, elementFilter)}
                  /> {ELEMENT_FILTER_KEYS_TO_NAMES[elementFilter]}
                </label>
              </div>
            </div>
          ))}
        </Fragment>
      );
    } else {
      return null;
    }
  }
}
