/* This SCSS file is responsible for the Document module's styles
 */

.document-quick-panel-footer {
  border-top: 1px solid #b0aeae;
  bottom: 0;
  font-weight: 600;
  height: 2.75rem;
  padding: 0.4rem 1rem;
  position: absolute;
  width: 100%;
}

.document-quick-panel-blank-state {
  color: #434952;
  font-weight: 600;
  line-height: 25px;
  margin: 8.5rem auto 0;
  text-align: center;
  white-space: pre-wrap;
  width: 45%;
}

.doc-editor-link-icon {
  height: 1rem;
  vertical-align: center;
}

.more-info-button:focus {
  box-shadow: none;
}

/* stylelint-disable selector-id-pattern */
:global(body:has(#documentPage) .dropdown-menu) {
  /* The .stick-to-top has z-index of 9 but the dropdown-menu has z-index of 2500.
    We need to set it to 8 to fix the bug QI-6048 */
  z-index: 8;
}

:global(body:has(.modal) .dropdown-menu) {
  /* Reset it back ton 2500 if there is a modal */
  z-index: 2500 !important;
}

