"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import BaseReactComponent from "../../base_react_component";
import { RouterContext } from "../../utils/router_context";

/**
 * This class handles adding entries to alerts panel
 */
export default class AlertsEntry extends BaseReactComponent {
  static contextType = RouterContext;

  constructor(props) {
    super(props);
  }

  onAlertEntryClick(event) {
    event.stopPropagation();

    let {object} = this.props;
    let {id, modelType} = object;
    if (modelType === "USR") {
      if (this.props.currentUser.permissions.Reports.includes("Individual Training Report")) {
        this.context.navigate("/reports/cannedReports/base.html?reportType=IndividualTrainingReport&user=" + id, "_self");
      }
    } else {
      this.context.navigate(getURLByTypeCodeAndId(modelType, "View", id), "_self");
    }
  }

  render() {
    let {className, object} = this.props;
    let {id, name, modelType, dueDate} = object;
    let isTrainingOverdueAlert = false;
    if (modelType && modelType === "USR") {
      isTrainingOverdueAlert = true;
    }
    return (modelType && id && name) ? (
      <div id="alertLink" onClick={this.onAlertEntryClick}
           className={"home-page-alerts-entry " + (className || "")}
      >
        <div className="home-page-alerts-entry-left-div">
          <div className="home-page-alerts-entry-title">
            {isTrainingOverdueAlert ?
              <a>
                {name}
              </a>
              :
              <a href={getURLByTypeCodeAndId(modelType, "View", id)}>
                {UIUtils.getRecordLabelForDisplay(modelType, id, name)}
              </a>
            }
          </div>
          <div className="home-page-alerts-entry-due-date-type">
            {isTrainingOverdueAlert ?
              <p>{dueDate.type}</p>
              :
              <p>{dueDate.type}: {dueDate.value}</p>
            }
          </div>
        </div>
        <div className="home-page-alerts-entry-separator" />
      </div>
    ) : "";
  }
}