import { DOCUMENT_CONTENT_HEADER, DOCUMENT_CONTENT_FOOTER } from "../../common/data";

export function setHeaderAndFooter(editorContent: string) {
  // If the existing document contents don't have Header and Footer, we will insert them
  // automatically
  const node = new DOMParser().parseFromString(editorContent, "text/html");
  const headerWidget = node.querySelector(".widget[kind='Header']");
  const footerWidget = node.querySelector(".widget[kind='Header']");

  if (!headerWidget) {
    const placeholder = document.createElement("div");
    placeholder.innerHTML = DOCUMENT_CONTENT_HEADER;
    node.body.insertBefore(
      placeholder.firstElementChild,
      node.body.firstElementChild
    );
  }

  if (!footerWidget) {
    const placeholder = document.createElement("div");
    placeholder.innerHTML = DOCUMENT_CONTENT_FOOTER;
    node.body.appendChild(placeholder.firstElementChild);
  }

  return node.documentElement.outerHTML;
}
