"use strict";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus, faSearchMinus, faExpandArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { WIDGET_VIEW } from "./ocr_widget";
import BaseReactComponent from "../../../../base_react_component";

/**
 * This is responsible for rendering the zoom & pagination controls on the OCR Widget control in the Upload and Extract wizard step
 * of the AI import workflow.
 */
export default class OCRControlBar extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  handlePageChange(e) {
    this.props.onPageChange(e.target.value);
  }

  handleViewFileClick() {
    this.props.onViewChange(this.props.isPDF ? WIDGET_VIEW.PDF : WIDGET_VIEW.PNG);
  }

  handleViewConvertedTextClick() {
    this.props.onViewChange(WIDGET_VIEW.TEXT);
  }

  render() {
    const {totalPages, onResetZoom, onZoomIn, onZoomOut, currentPage, selectedView} = this.props;

    return (
      <div className="import-data-ocr-control-bar">
        <div className="btn-group ocr-control-bar-view-controls">
          <button id={"viewOriginalFileButton"}
                  className={"btn btn-sm " + (selectedView === WIDGET_VIEW.TEXT ? "btn-secondary" : "btn-primary")}
                  onClick={this.handleViewFileClick}
          >
            Original File
          </button>
          <button id={"viewConvertedTextButton"}
                  className={"btn btn-sm " + (selectedView === WIDGET_VIEW.TEXT ? "btn-primary" : "btn-secondary")}
                  onClick={this.handleViewConvertedTextClick}
          >
            Converted to Text
          </button>
        </div>
        <div className="ocr-control-bar-pagination-controls">
          <input id="ocrPageInput"
                 type="number"
                 min={1}
                 max={totalPages}
                 className="form-control"
                 value={currentPage}
                 onChange={this.handlePageChange}
          />
          <span className="ocr-control-bar-pagination-item">/ {totalPages}</span>
        </div>
        <div className="ocr-control-bar-zoom-controls">
          <button id="zoomInButton"
                  onClick={onZoomIn}
                  className="btn btn-links btn-secondary"
                  title="Zoom in"
          >
            <FontAwesomeIcon icon={faSearchPlus} />
          </button>
          <button id="zoomOutButton"
                  onClick={onZoomOut}
                  className="btn btn-links btn-secondary"
                  title="Zoom out"
          >
            <FontAwesomeIcon icon={faSearchMinus} />
          </button>
          <button id="resetButton"
                  onClick={onResetZoom}
                  className="btn btn-links btn-secondary"
                  title="Reset back to full size"
          >
            <FontAwesomeIcon icon={faExpandArrowsAlt} />
          </button>
        </div>
      </div>
    );
  }
}
