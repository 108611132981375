import * as React from 'react';
import * as PropTypes from 'prop-types';
import { validatePackage } from '@progress/kendo-licensing';
import { classNames } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
/**
 * Represents the BreadcrumbListItem component.
 */
export var BreadcrumbListItem = React.forwardRef(function (props, ref) {
    validatePackage(packageMetadata);
    var target = React.useRef(null);
    var breadcrumbLIRef = React.useRef(null);
    var focus = React.useCallback(function () {
        if (breadcrumbLIRef.current) {
            breadcrumbLIRef.current.focus();
        }
    }, [breadcrumbLIRef]);
    React.useImperativeHandle(target, function () { return ({
        element: breadcrumbLIRef.current,
        focus: focus,
        props: props
    }); });
    React.useImperativeHandle(ref, function () { return target.current; });
    return (React.createElement("li", { ref: breadcrumbLIRef, id: props.id, style: props.style, className: classNames('k-flex-none k-breadcrumb-item', props.className) }, props.children));
});
var propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.any,
    style: PropTypes.object
};
BreadcrumbListItem.displayName = 'KendoReactBreadcrumbListItem';
BreadcrumbListItem.propTypes = propTypes;
