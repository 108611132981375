"use strict";

import * as UIUtils from "../ui_utils";

/**
 * This displays WebSocket messages on the UI. Overwrite this to customize how the messages are displayed or implement
 * callbacks for React controls where the state needs to be updated with each message received by the client. See
 * StatusCallbackService as an example.
 */
export class StatusDisplayService {
  /**
   * Displays a status message
   * @param message {string} The status message to be displayed
   * @param details {*} The details for the message
   */
  displayStatus(message, details = null) {
    UIUtils.showLoadingImage(message);
  }

  /**
   * Hides the status message
   * @param immediately {boolean} Indicates that the status should be hidden even if there's a pending operation
   */
  hideStatus(immediately = false) {
    if (immediately || UIUtils.getHideLoadingOnAjaxStop()) {
      UIUtils.hideLoadingImage();
    }
  }
}
