"use strict";

import { ServiceBase } from "../service_base";

/**
 * @typedef IImportServiceParams {Object}
 * @property {boolean} [useTwoWayCommunication]
 * @property {boolean} [idempotent]
 * @property {boolean} [clearUIErrors]
 * @property {boolean} [global]
 * @property {Map<string, string>} [statusMap]
 * @property {boolean} [autoClose]
 */

/**
 * Service layer for Batches functionality. Use this to execute Batch data operations on the backend.
 */
export class BatchesService extends ServiceBase {
  /**
   * Extracts the text from a pdf or image file (png, jpg)
   * @param payload The payload for the request
   */
  loadMaterialAttributeMeasurements(payload) {
    return this.loadMeasurements("MaterialAttribute", payload);
  }

  loadLibraryMaterialSpecificationMeasurements(payload) {
    return this.loadMeasurements("Specification", payload);
  }

  /**
   * Retrieves the batches data for the specified variable.
   * @param payload The payload for the request
   */
  loadMeasurements(modelName, payload) {
    if (!modelName) {
      throw new TypeError("modelName must be truthy");
    }
    let parameters = {
      global: false,
      action: "getEditables",
      useTwoWayCommunication: true,
    };

    payload.idOrAction = "getAttributesProcessData";
    payload.modelName = modelName;

    return this.sendToBackend(payload, parameters);
  }
}