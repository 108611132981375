var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { messages, pagerItemPerPage, pagerPageSelection } from './../messages';
import { DropDownList } from '@progress/kendo-react-dropdowns';
/**
 * @hidden
 */
var PagerPageSizes = /** @class */ (function (_super) {
    __extends(PagerPageSizes, _super);
    function PagerPageSizes() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.pageSizeChange = function (e) {
            if (_this.props.pageChange) {
                _this.props.pageChange({ skip: 0, take: parseInt(e.target.value, 10) }, e);
            }
        };
        return _this;
    }
    PagerPageSizes.prototype.render = function () {
        var _this = this;
        var sizes = this.props.pageSizes.slice();
        if (sizes.filter(function (s) { return s === _this.props.pageSize; }).length === 0) {
            sizes.unshift(this.props.pageSize);
        }
        var itemPerPageMessage = this.props.messagesMap ? this.props.messagesMap(pagerItemPerPage) :
            ({ messageKey: pagerItemPerPage, defaultMessage: messages[pagerItemPerPage] });
        var pageSelectionMessage = this.props.messagesMap ? this.props.messagesMap(pagerPageSelection) :
            ({ messageKey: pagerPageSelection, defaultMessage: messages[pagerPageSelection] });
        return (React.createElement("span", { className: "k-pager-sizes k-label" },
            React.createElement(DropDownList, { value: this.props.pageSize, data: sizes, onChange: this.pageSizeChange, ariaLabel: provideLocalizationService(this).toLanguageString(pageSelectionMessage.messageKey, pageSelectionMessage.defaultMessage) }),
            provideLocalizationService(this).toLanguageString(itemPerPageMessage.messageKey, itemPerPageMessage.defaultMessage)));
    };
    return PagerPageSizes;
}(React.Component));
export { PagerPageSizes };
registerForLocalization(PagerPageSizes);
