"use strict";

import ConfigurableTablesColumnOperationsHandler
  from "../../configurableTables/columnOperations/configurable_tables_column_operations_handler";
import * as UIUtils from "../../ui_utils";

export default class ControlMethodColumnOperationHandler extends ConfigurableTablesColumnOperationsHandler {

  constructor(props) {
    super(props);
  }

  handleDefaultColumnOperationsFromURL(handleColumnOperationCallback) {
    let filter = UIUtils.getParameterByName("filter");
    const state = {filter};
    super.handleDefaultColumnOperationsFromURL(state);
    handleColumnOperationCallback(state);
  }

}

