"use strict";

import * as UIUtils from "../../../ui_utils";
import { NEW_LINE, RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import {
  addTopLevelInformation,
  convertLinkedArrayToString,
  exportAs,
  fillInCriticalities,
  getFileType,
  getReportTemplateFullPath,
} from "../../canned_reports/canned_report_helper";
import { REPORT_OPTIONS_ENUM, RISK_TABLE_REPORTS_ENUM } from "../../constants/report_constants";
import { isPivot, getShowRiskLabelFromLabelAction, getRiskScoreWithLabel } from "./risk_tables_helper";
import {
  parseControlMethodsInRawData,
  parseControlMethodString,
  parseCriticalityInformationFromRawData,
} from "./risk_tables_parser";
import {
  formatCriticalityJustificationForExport,
  formatGroupingLabelForExport,
} from "./risk_tables_formatter";
import { getSortPriority, sortInstances, sortUnitOperationRecordsToTheEnd } from "./risk_tables_sorter";
import * as CommonEditablesFormatter from "../../../../server/common/editables/common_editables_formatter";
import RiskUtils from "../../../../server/common/misc/common_risk_utils";
import { getRawRiskScore, getRiskScale } from "../../../helpers/risk_helper";

/**
 * This function uses Stimulsoft to only export risktables to excel
 * It creates the report from the given template and doesn't show it in
 * the viewer like all other reports. I am cloning results as this all
 * happens in memory, so if I kept using results object, next time I try
 * same operation it will fail as main object has changed.
 * @param rmp Risk Map Report last version
 * @param reportKey Risk tables report type
 * @param results Results returned from server
 * @param filteredData Results returned from filtering risk tables
 * @param reportDate When report is being created
 * @param reportOptions The options for this report
 * @param labelAction Depending on its value, it shows risk label or score label, or effect label, and the corresponding colors
 * @param showRawScore Show risk raw value
 * @param exportToExcel If false the function will just return the instances object
 * @param orderedUOList Ordered unit operations list
 * @param orderUOByProp Ordered unit operations by which property
 */
export function exportRiskTables(reportKey, rmp, results, filteredData, reportDate, reportOptions,
                                 labelAction, showRawScore, exportToExcel = true, orderedUOList,
                                 steps, orderUOByProp = "id") {

  let showRiskLabel = getShowRiskLabelFromLabelAction(labelAction);
  let data = JSON.parse(JSON.stringify(results));
  data.instances = filteredData ? filteredData : data.instances.riskTablesData;

  if (orderedUOList) {
    // Find the list of unit operations
    const uoIdToIndexMap = new Map(orderedUOList.map((uo, uoIndex) => [uo[orderUOByProp], uoIndex]));

    // Sort the tables by UO order
    data.instances = data.instances.sort((a, b) => {
      return uoIdToIndexMap.get(a.groupByUOId) - uoIdToIndexMap.get(b.groupByUOId);
    });
  }

  let reportType = "RiskTablesDataReport";
  if (isPivot(reportKey)) {
    // Uncomment for verbose logging
    // console.log("(Risk Tables) Data: ", JSON.stringify(data.instances));

    data.instances = cleanInstances(data.instances);
    data.instances.forEach(instance => {
      instance.groupingLabel = formatGroupingLabelForExport(instance);
      instance.effectiveRMP = rmp;
      instance.sourceModelControlStrategy = UIUtils.secureString(
        CommonEditablesFormatter.formatControlStrategyForDisplay(instance.sourceModelControlStrategy),
      );
    });
    data.instances = handleRiskTablesData(reportType, reportKey, rmp, data.instances, reportOptions, labelAction, showRawScore);
  } else {
    parseCriticalityInformationFromRawData(parseControlMethodsInRawData(data.instances));

    data.instances.forEach(instance => {
      fillInCriticalities(rmp, instance, showRiskLabel, showRawScore, RiskUtils.NOT_ASSESSED_LABEL);
      instance.controlMethods = convertLinkedArrayToString(instance.controlMethods, "CM", NEW_LINE);
      instance.CriticalityJustification = formatCriticalityJustificationForExport(instance.CriticalityJustification);
      instance.controlStrategy = UIUtils.secureString(
        CommonEditablesFormatter.formatControlStrategyForDisplay(instance.controlStrategy),
      );
    });
  }

  const options = {
    reportType: reportType,
    modelType: reportKey,
    reportDate: reportDate,
    reportOptions: REPORT_OPTIONS_ENUM[reportType],
  };

  let processedData = addTopLevelInformation(data, options);
  if (exportToExcel) {
    let report = new window.Stimulsoft.Report.StiReport();
    report.loadFile(getReportTemplateFullPath(reportType, reportKey));

    let dataSet = new window.Stimulsoft.System.Data.DataSet("DataSet");
    dataSet.readJson(processedData);
    report.dictionary.databases.clear();
    report.regData(dataSet.dataSetName, "", dataSet);

    report.renderAsync(() => {
      // Uncomment for verbose logging
      console.log(JSON.stringify(processedData));

      exportAs(getFileType("xlsx"), "." + "xlsx", report, reportType, reportKey);
    });
  } else {
    return data;
  }
}

/**
 * This function cleans the risk tables' original data to fit within Stimulsoft.
 * @param instances risk tables data
 * @returns {Array} cleaned data
 */
function cleanInstances(instances) {
  let cleanedInstances = [];
  let uniqueIdSet = new Set();

  for (let instance of instances) {
    const uniqueId = instance.sourceModelId + "||" + instance.groupByUOId;
    if (!uniqueIdSet.has(uniqueId)) {
      let sourceWithTargets = instances.filter(record => record.sourceModelId === instance.sourceModelId && record.impact);
      if (sourceWithTargets.length > 0) {
        cleanedInstances = cleanedInstances.concat(sourceWithTargets);
      } else {
        cleanedInstances.push(instance);
      }
      uniqueIdSet.add(uniqueId);
    }
  }

  return cleanedInstances;
}

function hasRelationshipToSource(currentData, source, relationship) {
  return currentData.relationships.find(currentRelationship =>
    currentRelationship.sourceModelId === source.sourceModelId &&
    currentRelationship.sourceModelType === source.sourceModelType &&
    currentRelationship.targetModelId === relationship.targetModelId &&
    currentRelationship.targetModelType === relationship.targetModelType);
}

/**
 * This function structures a JSON Stimulsoft can understand, Results
 * returned by the backed can be one of the following 3 types.
 * 1- SourceModel, which is the source model (ex. FQA, IQA, ... etc)
 * 2- TargetModel, which is the target model (ex. IQA, MA, PP, ... etc)
 * 3- Parent, which is the parent of source/target models (ex. UnitOperation)
 * @param rmp Risk Map Report last version
 * @param reportType Canned report type (Ex. RiskTablesDataReport)
 * @param reportKey Risk tables report type
 * @param instances Original result.instances.riskTablesData returned from server
 * @param reportOptions The options for the report
 * @param labelAction Show risk label and corresponding colors
 * @param showRawScore Show risk raw value
 * @return {Array} Instances after being processed in the way Stimulsoft reports understand
 */
function handleRiskTablesData(reportType, reportKey, rmp, instances, reportOptions, labelAction, showRawScore) {
  const uoIDToInstancesMap = new Map();
  for (let i = 0; i < instances.length; i++) {
    const instance = instances[i];
    let processedInstance = uoIDToInstancesMap.get(instance.groupByUOId);
    if (!processedInstance) {
      processedInstance = {
        sources: [],
        relationships: [],
      };
      uoIDToInstancesMap.set(instance.groupByUOId, processedInstance);
    }
    fillSourcesAndRelationships(instance, processedInstance, reportOptions, rmp, reportType, reportKey, labelAction, showRawScore);
  }

  const reportData = Array.from(uoIDToInstancesMap.values());
  for (let i = 0; i < reportData.length; i++) {
    let processesSources = [];
    const currentData = reportData[i];

    // ensures the sources are in the correct order (by model name)
    currentData.sources.sort((i1, i2) => {
      let sortResult = getSortPriority(reportOptions, i1.sourceModelType) - getSortPriority(reportOptions, i2.sourceModelType);

      if (sortResult === 0) {
        const textA = i1.sourceModel.toUpperCase();
        const textB = i2.sourceModel.toUpperCase();
        sortResult = (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      }

      if (sortResult === 0) {
        sortResult = i1.sourceModelId - i2.sourceModelId;
      }

      return sortResult;
    });

    // processes all sources
    for (const source of currentData.sources) {
      if (!processesSources.includes(source.sourceModelId)) {
        source.riskLinks = currentData.relationships.filter(relationship =>
          relationship.sourceModelId === source.sourceModelId);
        let showRiskLabelOnAggregation = getShowRiskLabelFromLabelAction(labelAction, {isAggregation: true});
        fillInCriticalities(rmp, source, showRiskLabelOnAggregation, showRawScore, RiskUtils.NOT_ASSESSED_LABEL);
        processesSources.push(source.sourceModelId);
      }
    }

    // processes all relationships
    for (const relation of currentData.relationships) {
      if (!relation.targetModelId && currentData.nonEmptyTarget) {
        relation.targetModelId = currentData.nonEmptyTarget.targetModelId;
        relation.targetModel = currentData.nonEmptyTarget.targetModel;
        relation.targetModelType = currentData.nonEmptyTarget.targetModelType;
      }
    }

    /**
     * Now comes the tricky part:
     *
     * In order to be able to keep sort both the columns and the rows by the ID of the
     * object, we need to ensure that all rows have all columns. Otherwise, when we
     * process the first row, it will add its only column as the first one, and that will
     * break the sorting.
     */
    for (const source of currentData.sources) {
      for (const relationship of currentData.relationships) {
        if (!hasRelationshipToSource(currentData, source, relationship)) {
          currentData.relationships.push({
            sourceModel: source.sourceModel,
            sourceModelId: source.sourceModelId,
            sourceModelType: source.sourceModelType,
            sourceParentLabel: source.sourceParentLabel,
            groupByUO: source.groupByUO,
            groupByUOId: source.groupByUOId,
            groupByUOCurrentState: source.groupByUOCurrentState,
            stepId: source.stepId,
            stepName: source.stepName,
            groupingLabel: source.groupingLabel,
            targetModel: relationship.targetModel,
            targetModelId: relationship.targetModelId,
            targetModelType: relationship.targetModelType,
            targetParentLabel: relationship.targetParentLabel,
          });
        }
      }
    }

    /**
     * Now we sort based on 7 factors:
     *
     * 1) The priority of the row model type (for instance: IQA should appear before FQA)
     * 2) The step of the source model (column)
     * 3) The name of the target model (row)
     * 4) The id of the target model (row)
     * 5) The priority of the column model type (for instance: IQA should appear before FQA)
     * 6) The name of the source model (column)
     * 7) The id of the source model (column)
     *
     * This ensures the items appear
     */
    const sortingFunction = (i1, i2) => sortInstances(i1, i2, reportOptions);
    currentData.relationships.sort(sortingFunction);
    currentData.sources.sort(sortingFunction);

    // Now after applying all sorting strategies, move all UO level records to the end.
    currentData.relationships.sort(sortUnitOperationRecordsToTheEnd);
    currentData.sources.sort(sortUnitOperationRecordsToTheEnd);
  }


  // Uncomment for verbose logging
  // console.log("Processed Data: ", JSON.stringify(reportData));
  return reportData;
}

function getTargetParentLabel(reportKey, record) {
  return record.targetParentModelId && RISK_TABLE_REPORTS_ENUM[reportKey] === RISK_TABLE_REPORTS_ENUM.IQAs_to_IQAs ?
    UIUtils.getRecordLabelForDisplay(record.targetParentModelType, record.targetParentModelId, record.targetParentModel) : "";
}

/**
 * This function fills in the JSON object with sources and relationships in a format
 * Stimulsoft report can understand and process, if a source doesnt have any relationships
 * then it must be assigned to any target without an impact, uncertainty or a criticality
 * that't where nonEmptyTarget is used, if not done this way, Stimulsoft Crosstab will create
 * a fake relationship with a target with id and name of null and look like FQA-0 - null
 * @param instance Original instance from results.instances.riskTablesData
 * @param processedInstance An instance already has been processed with data
 * @param reportOptions The options for this report
 * @param rmp Risk Map Report last version
 * @param reportType Canned report type (Ex. RiskTablesDataReport)
 * @param reportKey Risk tables report type
 * @param labelAction Show risk label and corresponding colors
 * @param showRawScore Show risk raw value
 */
function fillSourcesAndRelationships(instance, processedInstance, reportOptions, rmp, reportType, reportKey, labelAction, showRawScore) {
  let showRiskLabel = getShowRiskLabelFromLabelAction(labelAction);

  let source = processedInstance.sources.find(source =>
    source.sourceModelId === instance.sourceModelId &&
    source.sourceModelType === instance.sourceModelType);
  let relationship = processedInstance.relationships.find(relationship =>
    relationship.sourceModelId === instance.sourceModelId &&
    relationship.sourceModelType === instance.sourceModelType &&
    relationship.targetModelId === instance.targetModelId &&
    relationship.targetModelType === instance.targetModelType);

  source = source || {};
  relationship = relationship || {};

  if (!source.sourceModelId) {
    for (const prop in instance) {
      if (Object.prototype.hasOwnProperty.call(instance, prop) &&
        (prop.toLowerCase().includes("sourcemodel") ||
          prop.toLowerCase().includes("uo"))) {
        source[prop] = instance[prop];
      }
    }

    source.sourceModelControlMethods = convertLinkedArrayToString(
      parseControlMethodString(source.sourceModelControlMethods),
      "CM", NEW_LINE);

    source.sourceParentLabel = instance.sourceParentModelId ?
      UIUtils.getRecordLabelForDisplay(instance.sourceParentModelType, instance.sourceParentModelId, instance.sourceParentModel) : "";

    source.stepName = instance.stepName;
    source.stepId = instance.stepId;
    source.groupingLabel = instance.groupingLabel;
    source.targetParentLabel = getTargetParentLabel(reportKey, instance);
    source.capabilityRisk = source.sourceModelCapabilityRisk;
    source.detectabilityRisk = source.sourceModelDetectabilityRisk;
    source.riskInfo = instance.sourceRiskInfo;
    source.linkRiskInfo = instance.linkRiskInfo;
    source.effectiveRMPId = instance.sourceEffectiveRMPId;
    source.effectiveRMPVersionId = instance.sourceEffectiveRMPVersionId;
    processedInstance.sources.push(source);
  }

  if (!relationship.sourceModelId) {
    for (const prop in instance) {
      if (Object.prototype.hasOwnProperty.call(instance, prop)) {
        relationship[prop] = instance[prop];
      }
    }

    relationship.sourceParentLabel = instance.sourceParentModelId ?
      UIUtils.getRecordLabelForDisplay(instance.sourceParentModelType, instance.sourceParentModelId, instance.sourceParentModel) : "";
    relationship.targetParentLabel = getTargetParentLabel(reportKey, instance);


    if (relationship.impact && relationship.uncertainty) {
      // we compute the criticality on a single risk link (not on the source or target)
      relationship.criticality = getRawRiskScore(RISK_TYPE_ENUM.CRITICALITY, rmp, relationship, relationship.detailedRiskLinks);

      relationship.riskInfo = {};
      relationship.riskInfo[RISK_TYPE_ENUM.CRITICALITY] = {
        value: relationship.criticality,
        scale: getRiskScale(RISK_TYPE_ENUM.CRITICALITY, rmp, relationship.criticality, relationship, false),
        scaleForRiskLabel: getRiskScale(RISK_TYPE_ENUM.CRITICALITY, rmp, relationship.criticality, relationship, true)
      };

      relationship.criticalityLabel = getRiskScoreWithLabel(relationship, RISK_TYPE_ENUM.CRITICALITY, rmp, showRawScore, labelAction, true, "");

      let riskScale = getRiskScale(RISK_TYPE_ENUM.CRITICALITY, rmp, relationship.criticality, relationship, showRiskLabel);
      relationship.criticalityColor = riskScale.color;
    }

    if (relationship.targetModelId) {
      processedInstance.nonEmptyTarget = relationship;
    }

    processedInstance.relationships.push(relationship);
  }

  for (const prop in instance) {
    if (Object.prototype.hasOwnProperty.call(instance, prop) && prop.toLowerCase().includes("uo")) {
      processedInstance[prop] = instance[prop];
    }
  }

  if (reportOptions.includeParentInfo) {
    processedInstance.sourceUOLabel = processedInstance.sourceUOId ? UIUtils.getRecordLabelForDisplay("UO", processedInstance.sourceUOId, processedInstance.sourceUO) : "";
    processedInstance.groupByUOLabel = processedInstance.groupByUOId ? UIUtils.getRecordLabelForDisplay("UO", processedInstance.groupByUOId, processedInstance.groupByUO) : "";
  }
}
