"use strict";

import * as UIUtils from "../../../ui_utils";
import * as CommonTraining from "../../../../server/common/editables/common_training";
import { INVALID_DATE, TRAINING_STATUS } from "../../data_transform/canned_reports/training/constants/constants";

/*
 * The functions in this file are responsible for filtering data needed for the training reports.
 */

/**
 * Returns a copy of the current training assigned document version with the
 * @param currentRecord {IAssignedDocumentVersion}
 */
export function formatTrainingDatesForDisplay(currentRecord) {
  let completionDate = UIUtils.getDateForDisplayToUser(currentRecord.completionDate, UIUtils.DATE_FORMAT_FOR_DISPLAY);
  let effectiveDate = UIUtils.getDateForDisplayToUser(currentRecord.effectiveDate, UIUtils.DATE_FORMAT_FOR_DISPLAY);
  let assignmentDate = UIUtils.getDateForDisplayToUser(currentRecord.assignmentDate, UIUtils.DATE_FORMAT_FOR_DISPLAY);

  return {
    ...currentRecord,
    completionDate,
    effectiveDate,
    assignmentDate,
  };
}


/**
 * This function filters results by the training status
 * @param instances {[]} The instances to be filtered
 * @param status {TRAINING_STATUS} The status to filter with
 * @param [allInstances] {[]} If specified, it will consider those as all available instances, without
 * any previous filter that might have been applied over the first parameter.
 * @returns {[]} filtered results
 */
export function filterByStatus(instances, status, allInstances = instances) {

  if (status === "all") {
    return instances;
  } else {
    const instancesWithStatus = instances.map(instance => {
      return {
        ...instance,
        trainingStatus: CommonTraining.getCompletionStatus(formatTrainingDatesForDisplay(instance), allInstances, {completedAsDate: true}),
      };
    });

    let result = instancesWithStatus.filter(instance => {

      if (status === TRAINING_STATUS.COMPLETED) {
        return instance.completionDate && instance.completionDate !== INVALID_DATE && instance.trainingStatus !== TRAINING_STATUS.PENDING;
      } else if (status === TRAINING_STATUS.PENDING) {
        return instance.trainingStatus === status && !instances
          .find(record => instance.documentId === record.documentId &&
            UIUtils.parseInt(record.documentVersion) > UIUtils.parseInt(instance.documentVersion));
      }
      return instance.trainingStatus === status;
    });
    return result;
  }
}
