"use strict";

import React from "react";
import { useSidePanel } from "../../widgets/side_panel_provider";
import * as styles from "./column_selection.module.scss";

export const ColumnSelectionFooter = ({onColumnSelectionChange}) => {

  const {toggle} = useSidePanel();

  return (
    <div className={styles["column-selection-footer"]}>
      <div className="btn-group">
        <button id="columnSelectionApplyButton"
                type="button"
                className="btn btn-primary"
                onClick={onColumnSelectionChange}
        >
          Apply
        </button>
        <button id="cancelColumnSelection"
                type="button"
                className="btn btn-secondary"
                onClick={toggle}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};