var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames } from '../classNames';
import { SIZE_CLASSES } from './constants';
import { useMouse } from '../main';
/**
 * Represents the [KendoReact Icon component]({% slug overview_icon %}).
 *
 * @example
 * ```jsx
 * const App = () => {
 *   return (
 *       <Icon name="home"/>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var Icon = React.forwardRef(function (props, ref) {
    var className = props.className, name = props.name, themeColor = props.themeColor, size = props.size, flip = props.flip, style = props.style, id = props.id, tabIndex = props.tabIndex, others = __rest(props, ["className", "name", "themeColor", "size", "flip", "style", "id", "tabIndex"]);
    var target = React.useRef(null);
    var elementRef = React.useRef(null);
    React.useImperativeHandle(target, function () { return ({
        element: elementRef.current
    }); });
    React.useImperativeHandle(ref, function () { return target.current; });
    var themeColorProp = React.useMemo(function () { return themeColor || defaultProps.themeColor; }, [themeColor]);
    var sizeProp = React.useMemo(function () { return size || defaultProps.size; }, [size]);
    var flipProp = React.useMemo(function () { return flip || defaultProps.flip; }, [flip]);
    var fontClassNames = React.useMemo(function () { return classNames('k-icon', 'k-i-' + name, 'k-color-' + themeColorProp, {
        'k-flip-h': flipProp === 'horizontal' || flipProp === 'both',
        'k-flip-v': flipProp === 'vertical' || flipProp === 'both'
    }, SIZE_CLASSES[sizeProp], className); }, [name, themeColorProp, sizeProp, flipProp, className]);
    var mouseProps = useMouse(props, target);
    return (React.createElement("span", __assign({ ref: elementRef }, others, mouseProps, { className: fontClassNames, id: id, tabIndex: tabIndex, style: style, role: "presentation" })));
});
Icon.propTypes = {
    style: PropTypes.object,
    classNames: PropTypes.string,
    name: PropTypes.string,
    themeColor: PropTypes.oneOf([
        'inherit', 'primary', 'secondary', 'tertiary',
        'info', 'success', 'error', 'warning',
        'dark', 'light', 'inverse'
    ]),
    size: PropTypes.oneOf(['default', 'xsmall', 'small', 'medium', 'large', 'xlarge']),
    flip: PropTypes.oneOf(['default', 'horizontal', 'vertical', 'both'])
};
var defaultProps = {
    size: 'default',
    themeColor: 'inherit',
    flip: 'default'
};
Icon.displayName = 'KendoIcon';
