"use strict";

let nameToEditableMap = new Map();
let editable = {};


editable = {name: "Batch", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("Batch", editable);
editable.attributesMap.set("customID", {name: "customID", displayName: "Custom I D", type: "STRING"});
editable.attributesMap.set("type", {name: "type", displayName: "Type", type: "Enumeration"});
editable.attributesMap.set("description", {name: "description", displayName: "Description", type: "TEXT"});
editable.attributesMap.set("site", {name: "site", displayName: "Site", type: "TEXT"});
editable.attributesMap.set("scale", {name: "scale", displayName: "Scale", type: "TEXT"});
editable.attributesMap.set("startDate", {name: "startDate", displayName: "Start Date", type: "STRING"});
editable.attributesMap.set("manufactureDate", {name: "manufactureDate", displayName: "Manufacture Date", type: "STRING"});
editable.attributesMap.set("releaseDate", {name: "releaseDate", displayName: "Release Date", type: "STRING"});
editable.attributesMap.set("expirationDate", {name: "expirationDate", displayName: "Expiration Date", type: "STRING"});
editable.attributesMap.set("links", {name: "links", displayName: "Links", type: "TEXT"});
editable.attributesMap.set("batchType", {name: "batchType", displayName: "Batch Type", type: "TEXT"});
editable.attributesMap.set("purpose", {name: "purpose", displayName: "Purpose", type: "TEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "ControlMethod", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("ControlMethod", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("type", {name: "type", displayName: "Type", type: "Enumeration"});
editable.attributesMap.set("category", {name: "category", displayName: "Category", type: "Enumeration"});
editable.attributesMap.set("compendialStandard", {name: "compendialStandard", displayName: "Compendial Standard", type: "TEXT"});
editable.attributesMap.set("description", {name: "description", displayName: "Description", type: "TEXT"});
editable.attributesMap.set("stabilityIndicating", {name: "stabilityIndicating", displayName: "Stability Indicating", type: "Enumeration"});
editable.attributesMap.set("equipment", {name: "equipment", displayName: "Equipment", type: "TEXT"});
editable.attributesMap.set("controlMethodLinks", {name: "controlMethodLinks", displayName: "Control Method Links", type: "TEXT"});
editable.attributesMap.set("status", {name: "status", displayName: "Status", type: "Enumeration"});
editable.attributesMap.set("developmentLinks", {name: "developmentLinks", displayName: "Development Links", type: "TEXT"});
editable.attributesMap.set("referencesLinks", {name: "referencesLinks", displayName: "References Links", type: "TEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "Curriculum", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("Curriculum", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "TEXT"});
editable.attributesMap.set("description", {name: "description", displayName: "Description", type: "TEXT"});
editable.attributesMap.set("departments", {name: "departments", displayName: "Departments", type: "TEXT"});
editable.attributesMap.set("referencesLinks", {name: "referencesLinks", displayName: "References Links", type: "TEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "Document", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("Document", editable);
editable.attributesMap.set("uploadDocumentLinks", {name: "uploadDocumentLinks", displayName: "Upload Document Links", type: "TEXT"});
editable.attributesMap.set("size", {name: "size", displayName: "Size", type: "STRING"});
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("type", {name: "type", displayName: "Type", type: "Enumeration"});
editable.attributesMap.set("customID", {name: "customID", displayName: "Custom I D", type: "TEXT"});
editable.attributesMap.set("category", {name: "category", displayName: "Category", type: "TEXT"});
editable.attributesMap.set("site", {name: "site", displayName: "Site", type: "STRING"});
editable.attributesMap.set("purpose", {name: "purpose", displayName: "Purpose", type: "TEXT"});
editable.attributesMap.set("scope", {name: "scope", displayName: "Scope", type: "TEXT"});
editable.attributesMap.set("author", {name: "author", displayName: "Author", type: "STRING"});
editable.attributesMap.set("sme", {name: "sme", displayName: "Sme", type: "STRING"});
editable.attributesMap.set("trainingRequired", {name: "trainingRequired", displayName: "Training Required", type: "BOOLEAN"});
editable.attributesMap.set("trainingLinks", {name: "trainingLinks", displayName: "Training Links", type: "TEXT"});
editable.attributesMap.set("referencesLinks", {name: "referencesLinks", displayName: "References Links", type: "TEXT"});
editable.attributesMap.set("controlled", {name: "controlled", displayName: "Controlled", type: "BOOLEAN"});
editable.attributesMap.set("effectiveDate", {name: "effectiveDate", displayName: "Effective Date", type: "STRING"});
editable.attributesMap.set("originalDocumentLinks", {name: "originalDocumentLinks", displayName: "Original Document Links", type: "TEXT"});
editable.attributesMap.set("regulatoryDocument", {name: "regulatoryDocument", displayName: "Regulatory Document", type: "STRING"});
editable.attributesMap.set("ectdSection", {name: "ectdSection", displayName: "Ectd Section", type: "STRING"});
editable.attributesMap.set("dhfSection", {name: "dhfSection", displayName: "Dhf Section", type: "STRING"});
editable.attributesMap.set("dmrSection", {name: "dmrSection", displayName: "Dmr Section", type: "STRING"});
editable.attributesMap.set("k510Section", {name: "k510Section", displayName: "K510 Section", type: "STRING"});
editable.attributesMap.set("pmaSection", {name: "pmaSection", displayName: "Pma Section", type: "STRING"});
editable.attributesMap.set("isBulkAdd", {name: "isBulkAdd", displayName: "Is Bulk Add", type: "BOOLEAN"});
editable.attributesMap.set("isQbdSrcDoc", {name: "isQbdSrcDoc", displayName: "Is Qbd Src Doc", type: "BOOLEAN"});
editable.attributesMap.set("documentContentVersionId", {name: "documentContentVersionId", displayName: "Document Content Version", type: "INTEGER"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "DocumentContent", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("DocumentContent", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("content", {name: "content", displayName: "Content", type: "LONGTEXT"});
editable.attributesMap.set("fields", {name: "fields", displayName: "Fields", type: "TEXT"});
editable.attributesMap.set("uploadImageLinks", {name: "uploadImageLinks", displayName: "Upload Image Links", type: "TEXT"});
editable.attributesMap.set("orientation", {name: "orientation", displayName: "Orientation", type: "TEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "FPA", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("FPA", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("scope", {name: "scope", displayName: "Scope", type: "TEXT"});
editable.attributesMap.set("type", {name: "type", displayName: "Type", type: "Enumeration"});
editable.attributesMap.set("category", {name: "category", displayName: "Category", type: "TEXT"});
editable.attributesMap.set("description", {name: "description", displayName: "Description", type: "TEXT"});
editable.attributesMap.set("dataSpace", {name: "dataSpace", displayName: "Data Space", type: "Enumeration"});
editable.attributesMap.set("measure", {name: "measure", displayName: "Measure", type: "Enumeration"});
editable.attributesMap.set("measurementUnits", {name: "measurementUnits", displayName: "Measurement Units", type: "STRING"});
editable.attributesMap.set("lowerLimit", {name: "lowerLimit", displayName: "Lower Limit", type: "STRING"});
editable.attributesMap.set("target", {name: "target", displayName: "Target", type: "STRING"});
editable.attributesMap.set("upperLimit", {name: "upperLimit", displayName: "Upper Limit", type: "STRING"});
editable.attributesMap.set("targetJustification", {name: "targetJustification", displayName: "Target Justification", type: "TEXT"});
editable.attributesMap.set("acceptanceCriteriaLinks", {name: "acceptanceCriteriaLinks", displayName: "Acceptance Criteria Links", type: "TEXT"});
editable.attributesMap.set("impact", {name: "impact", displayName: "Impact", type: "INTEGER"});
editable.attributesMap.set("uncertainty", {name: "uncertainty", displayName: "Uncertainty", type: "INTEGER"});
editable.attributesMap.set("capabilityRisk", {name: "capabilityRisk", displayName: "Capability Risk", type: "INTEGER"});
editable.attributesMap.set("capabilityJustification", {name: "capabilityJustification", displayName: "Capability Justification", type: "TEXT"});
editable.attributesMap.set("detectabilityRisk", {name: "detectabilityRisk", displayName: "Detectability Risk", type: "INTEGER"});
editable.attributesMap.set("detectabilityJustification", {name: "detectabilityJustification", displayName: "Detectability Justification", type: "TEXT"});
editable.attributesMap.set("criticalityJustification", {name: "criticalityJustification", displayName: "Criticality Justification", type: "TEXT"});
editable.attributesMap.set("recommendedActions", {name: "recommendedActions", displayName: "Recommended Actions", type: "TEXT"});
editable.attributesMap.set("riskLinks", {name: "riskLinks", displayName: "Risk Links", type: "TEXT"});
editable.attributesMap.set("ccp", {name: "ccp", displayName: "Ccp", type: "BOOLEAN"});
editable.attributesMap.set("samplingPlan", {name: "samplingPlan", displayName: "Sampling Plan", type: "BOOLEAN"});
editable.attributesMap.set("controlStrategy", {name: "controlStrategy", displayName: "Control Strategy", type: "TEXT"});
editable.attributesMap.set("controlStrategyJustification", {name: "controlStrategyJustification", displayName: "Control Strategy Justification", type: "TEXT"});
editable.attributesMap.set("riskControlLinks", {name: "riskControlLinks", displayName: "Risk Control Links", type: "TEXT"});
editable.attributesMap.set("referencesLinks", {name: "referencesLinks", displayName: "References Links", type: "TEXT"});
editable.attributesMap.set("estimatedSampleSize", {name: "estimatedSampleSize", displayName: "Estimated Sample Size", type: "STRING"});
editable.attributesMap.set("detailedRiskLinks", {name: "detailedRiskLinks", displayName: "Detailed Risk Links", type: "BOOLEAN"});
editable.attributesMap.set("rmpVersionId", {name: "rmpVersionId", displayName: "Rmp Version", type: "INTEGER"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "FQA", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("FQA", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("scope", {name: "scope", displayName: "Scope", type: "TEXT"});
editable.attributesMap.set("type", {name: "type", displayName: "Type", type: "Enumeration"});
editable.attributesMap.set("category", {name: "category", displayName: "Category", type: "TEXT"});
editable.attributesMap.set("description", {name: "description", displayName: "Description", type: "TEXT"});
editable.attributesMap.set("dataSpace", {name: "dataSpace", displayName: "Data Space", type: "Enumeration"});
editable.attributesMap.set("measure", {name: "measure", displayName: "Measure", type: "Enumeration"});
editable.attributesMap.set("measurementUnits", {name: "measurementUnits", displayName: "Measurement Units", type: "STRING"});
editable.attributesMap.set("lowerLimit", {name: "lowerLimit", displayName: "Lower Limit", type: "STRING"});
editable.attributesMap.set("target", {name: "target", displayName: "Target", type: "STRING"});
editable.attributesMap.set("upperLimit", {name: "upperLimit", displayName: "Upper Limit", type: "STRING"});
editable.attributesMap.set("targetJustification", {name: "targetJustification", displayName: "Target Justification", type: "TEXT"});
editable.attributesMap.set("acceptanceCriteriaLinks", {name: "acceptanceCriteriaLinks", displayName: "Acceptance Criteria Links", type: "TEXT"});
editable.attributesMap.set("impact", {name: "impact", displayName: "Impact", type: "INTEGER"});
editable.attributesMap.set("uncertainty", {name: "uncertainty", displayName: "Uncertainty", type: "INTEGER"});
editable.attributesMap.set("capabilityRisk", {name: "capabilityRisk", displayName: "Capability Risk", type: "INTEGER"});
editable.attributesMap.set("capabilityJustification", {name: "capabilityJustification", displayName: "Capability Justification", type: "TEXT"});
editable.attributesMap.set("detectabilityRisk", {name: "detectabilityRisk", displayName: "Detectability Risk", type: "INTEGER"});
editable.attributesMap.set("detectabilityJustification", {name: "detectabilityJustification", displayName: "Detectability Justification", type: "TEXT"});
editable.attributesMap.set("criticalityJustification", {name: "criticalityJustification", displayName: "Criticality Justification", type: "TEXT"});
editable.attributesMap.set("recommendedActions", {name: "recommendedActions", displayName: "Recommended Actions", type: "TEXT"});
editable.attributesMap.set("riskLinks", {name: "riskLinks", displayName: "Risk Links", type: "TEXT"});
editable.attributesMap.set("ccp", {name: "ccp", displayName: "Ccp", type: "BOOLEAN"});
editable.attributesMap.set("samplingPlan", {name: "samplingPlan", displayName: "Sampling Plan", type: "BOOLEAN"});
editable.attributesMap.set("stabilityIndicating", {name: "stabilityIndicating", displayName: "Stability Indicating", type: "BOOLEAN"});
editable.attributesMap.set("controlStrategy", {name: "controlStrategy", displayName: "Control Strategy", type: "TEXT"});
editable.attributesMap.set("controlStrategyJustification", {name: "controlStrategyJustification", displayName: "Control Strategy Justification", type: "TEXT"});
editable.attributesMap.set("riskControlLinks", {name: "riskControlLinks", displayName: "Risk Control Links", type: "TEXT"});
editable.attributesMap.set("referencesLinks", {name: "referencesLinks", displayName: "References Links", type: "TEXT"});
editable.attributesMap.set("estimatedSampleSize", {name: "estimatedSampleSize", displayName: "Estimated Sample Size", type: "STRING"});
editable.attributesMap.set("detailedRiskLinks", {name: "detailedRiskLinks", displayName: "Detailed Risk Links", type: "BOOLEAN"});
editable.attributesMap.set("rmpVersionId", {name: "rmpVersionId", displayName: "Rmp Version", type: "INTEGER"});
editable.attributesMap.set("obligatoryCQA", {name: "obligatoryCQA", displayName: "Obligatory C Q A", type: "BOOLEAN"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "GeneralAttribute", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("GeneralAttribute", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("target", {name: "target", displayName: "Target", type: "TEXT"});
editable.attributesMap.set("links", {name: "links", displayName: "Links", type: "TEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "IPA", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("IPA", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("type", {name: "type", displayName: "Type", type: "Enumeration"});
editable.attributesMap.set("description", {name: "description", displayName: "Description", type: "TEXT"});
editable.attributesMap.set("dataSpace", {name: "dataSpace", displayName: "Data Space", type: "Enumeration"});
editable.attributesMap.set("measure", {name: "measure", displayName: "Measure", type: "Enumeration"});
editable.attributesMap.set("measurementUnits", {name: "measurementUnits", displayName: "Measurement Units", type: "STRING"});
editable.attributesMap.set("lowerLimit", {name: "lowerLimit", displayName: "Lower Limit", type: "STRING"});
editable.attributesMap.set("target", {name: "target", displayName: "Target", type: "STRING"});
editable.attributesMap.set("riskLinks", {name: "riskLinks", displayName: "Risk Links", type: "TEXT"});
editable.attributesMap.set("upperLimit", {name: "upperLimit", displayName: "Upper Limit", type: "STRING"});
editable.attributesMap.set("targetJustification", {name: "targetJustification", displayName: "Target Justification", type: "TEXT"});
editable.attributesMap.set("acceptanceCriteriaLinks", {name: "acceptanceCriteriaLinks", displayName: "Acceptance Criteria Links", type: "TEXT"});
editable.attributesMap.set("recommendedActions", {name: "recommendedActions", displayName: "Recommended Actions", type: "TEXT"});
editable.attributesMap.set("capabilityRisk", {name: "capabilityRisk", displayName: "Capability Risk", type: "INTEGER"});
editable.attributesMap.set("capabilityJustification", {name: "capabilityJustification", displayName: "Capability Justification", type: "TEXT"});
editable.attributesMap.set("detectabilityRisk", {name: "detectabilityRisk", displayName: "Detectability Risk", type: "INTEGER"});
editable.attributesMap.set("detectabilityJustification", {name: "detectabilityJustification", displayName: "Detectability Justification", type: "TEXT"});
editable.attributesMap.set("ccp", {name: "ccp", displayName: "Ccp", type: "BOOLEAN"});
editable.attributesMap.set("samplingPlan", {name: "samplingPlan", displayName: "Sampling Plan", type: "BOOLEAN"});
editable.attributesMap.set("controlStrategy", {name: "controlStrategy", displayName: "Control Strategy", type: "TEXT"});
editable.attributesMap.set("controlStrategyJustification", {name: "controlStrategyJustification", displayName: "Control Strategy Justification", type: "TEXT"});
editable.attributesMap.set("riskControlLinks", {name: "riskControlLinks", displayName: "Risk Control Links", type: "TEXT"});
editable.attributesMap.set("referencesLinks", {name: "referencesLinks", displayName: "References Links", type: "TEXT"});
editable.attributesMap.set("estimatedSampleSize", {name: "estimatedSampleSize", displayName: "Estimated Sample Size", type: "STRING"});
editable.attributesMap.set("techTransferComment", {name: "techTransferComment", displayName: "Tech Transfer Comment", type: "TEXT"});
editable.attributesMap.set("techTransferImpact", {name: "techTransferImpact", displayName: "Tech Transfer Impact", type: "INTEGER"});
editable.attributesMap.set("techTransferImpactDescription", {name: "techTransferImpactDescription", displayName: "Tech Transfer Impact Description", type: "TEXT"});
editable.attributesMap.set("techTransferUncertainty", {name: "techTransferUncertainty", displayName: "Tech Transfer Uncertainty", type: "INTEGER"});
editable.attributesMap.set("techTransferUncertaintyJustification", {name: "techTransferUncertaintyJustification", displayName: "Tech Transfer Uncertainty Justification", type: "TEXT"});
editable.attributesMap.set("techTransferDetectability", {name: "techTransferDetectability", displayName: "Tech Transfer Detectability", type: "INTEGER"});
editable.attributesMap.set("techTransferControlStrategy", {name: "techTransferControlStrategy", displayName: "Tech Transfer Control Strategy", type: "TEXT"});
editable.attributesMap.set("techTransferRiskMitigationStrategy", {name: "techTransferRiskMitigationStrategy", displayName: "Tech Transfer Risk Mitigation Strategy", type: "TEXT"});
editable.attributesMap.set("techTransferRecommendedActions", {name: "techTransferRecommendedActions", displayName: "Tech Transfer Recommended Actions", type: "TEXT"});
editable.attributesMap.set("isTechTransferOnlyChange", {name: "isTechTransferOnlyChange", displayName: "Is Tech Transfer Only Change", type: "BOOLEAN"});
editable.attributesMap.set("techTransferAssessmentStatus", {name: "techTransferAssessmentStatus", displayName: "Tech Transfer Assessment Status", type: "Enumeration"});
editable.attributesMap.set("techTransferLinks", {name: "techTransferLinks", displayName: "Tech Transfer Links", type: "TEXT"});
editable.attributesMap.set("rmpVersionId", {name: "rmpVersionId", displayName: "Rmp Version", type: "INTEGER"});
editable.attributesMap.set("tags", {name: "tags", displayName: "Tags", type: "LONGTEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "IQA", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("IQA", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("type", {name: "type", displayName: "Type", type: "Enumeration"});
editable.attributesMap.set("description", {name: "description", displayName: "Description", type: "TEXT"});
editable.attributesMap.set("dataSpace", {name: "dataSpace", displayName: "Data Space", type: "Enumeration"});
editable.attributesMap.set("measure", {name: "measure", displayName: "Measure", type: "Enumeration"});
editable.attributesMap.set("measurementUnits", {name: "measurementUnits", displayName: "Measurement Units", type: "STRING"});
editable.attributesMap.set("lowerLimit", {name: "lowerLimit", displayName: "Lower Limit", type: "STRING"});
editable.attributesMap.set("target", {name: "target", displayName: "Target", type: "STRING"});
editable.attributesMap.set("riskLinks", {name: "riskLinks", displayName: "Risk Links", type: "TEXT"});
editable.attributesMap.set("upperLimit", {name: "upperLimit", displayName: "Upper Limit", type: "STRING"});
editable.attributesMap.set("targetJustification", {name: "targetJustification", displayName: "Target Justification", type: "TEXT"});
editable.attributesMap.set("acceptanceCriteriaLinks", {name: "acceptanceCriteriaLinks", displayName: "Acceptance Criteria Links", type: "TEXT"});
editable.attributesMap.set("recommendedActions", {name: "recommendedActions", displayName: "Recommended Actions", type: "TEXT"});
editable.attributesMap.set("capabilityRisk", {name: "capabilityRisk", displayName: "Capability Risk", type: "INTEGER"});
editable.attributesMap.set("capabilityJustification", {name: "capabilityJustification", displayName: "Capability Justification", type: "TEXT"});
editable.attributesMap.set("detectabilityRisk", {name: "detectabilityRisk", displayName: "Detectability Risk", type: "INTEGER"});
editable.attributesMap.set("detectabilityJustification", {name: "detectabilityJustification", displayName: "Detectability Justification", type: "TEXT"});
editable.attributesMap.set("ccp", {name: "ccp", displayName: "Ccp", type: "BOOLEAN"});
editable.attributesMap.set("samplingPlan", {name: "samplingPlan", displayName: "Sampling Plan", type: "BOOLEAN"});
editable.attributesMap.set("controlStrategy", {name: "controlStrategy", displayName: "Control Strategy", type: "TEXT"});
editable.attributesMap.set("controlStrategyJustification", {name: "controlStrategyJustification", displayName: "Control Strategy Justification", type: "TEXT"});
editable.attributesMap.set("riskControlLinks", {name: "riskControlLinks", displayName: "Risk Control Links", type: "TEXT"});
editable.attributesMap.set("referencesLinks", {name: "referencesLinks", displayName: "References Links", type: "TEXT"});
editable.attributesMap.set("estimatedSampleSize", {name: "estimatedSampleSize", displayName: "Estimated Sample Size", type: "STRING"});
editable.attributesMap.set("techTransferComment", {name: "techTransferComment", displayName: "Tech Transfer Comment", type: "TEXT"});
editable.attributesMap.set("techTransferImpact", {name: "techTransferImpact", displayName: "Tech Transfer Impact", type: "INTEGER"});
editable.attributesMap.set("techTransferImpactDescription", {name: "techTransferImpactDescription", displayName: "Tech Transfer Impact Description", type: "TEXT"});
editable.attributesMap.set("techTransferUncertainty", {name: "techTransferUncertainty", displayName: "Tech Transfer Uncertainty", type: "INTEGER"});
editable.attributesMap.set("techTransferUncertaintyJustification", {name: "techTransferUncertaintyJustification", displayName: "Tech Transfer Uncertainty Justification", type: "TEXT"});
editable.attributesMap.set("techTransferDetectability", {name: "techTransferDetectability", displayName: "Tech Transfer Detectability", type: "INTEGER"});
editable.attributesMap.set("techTransferControlStrategy", {name: "techTransferControlStrategy", displayName: "Tech Transfer Control Strategy", type: "TEXT"});
editable.attributesMap.set("techTransferRiskMitigationStrategy", {name: "techTransferRiskMitigationStrategy", displayName: "Tech Transfer Risk Mitigation Strategy", type: "TEXT"});
editable.attributesMap.set("techTransferRecommendedActions", {name: "techTransferRecommendedActions", displayName: "Tech Transfer Recommended Actions", type: "TEXT"});
editable.attributesMap.set("isTechTransferOnlyChange", {name: "isTechTransferOnlyChange", displayName: "Is Tech Transfer Only Change", type: "BOOLEAN"});
editable.attributesMap.set("techTransferAssessmentStatus", {name: "techTransferAssessmentStatus", displayName: "Tech Transfer Assessment Status", type: "Enumeration"});
editable.attributesMap.set("techTransferLinks", {name: "techTransferLinks", displayName: "Tech Transfer Links", type: "TEXT"});
editable.attributesMap.set("rmpVersionId", {name: "rmpVersionId", displayName: "Rmp Version", type: "INTEGER"});
editable.attributesMap.set("tags", {name: "tags", displayName: "Tags", type: "LONGTEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "ITP", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("ITP", editable);
editable.attributesMap.set("referencesLinks", {name: "referencesLinks", displayName: "References Links", type: "TEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});
editable.attributesMap.set("userId", {name: "userId", displayName: "User", type: "INTEGER"});

editable = {name: "LibraryMaterial", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("LibraryMaterial", editable);
editable.attributesMap.set("category", {name: "category", displayName: "Category", type: "Enumeration"});
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("chemicalNameCAS", {name: "chemicalNameCAS", displayName: "Chemical Name C A S", type: "STRING"});
editable.attributesMap.set("chemicalNameIUPAC", {name: "chemicalNameIUPAC", displayName: "Chemical Name I U P A C", type: "STRING"});
editable.attributesMap.set("otherNames", {name: "otherNames", displayName: "Other Names", type: "STRING"});
editable.attributesMap.set("innUsan", {name: "innUsan", displayName: "Inn Usan", type: "STRING"});
editable.attributesMap.set("casRegistryNumber", {name: "casRegistryNumber", displayName: "Cas Registry Number", type: "STRING"});
editable.attributesMap.set("description", {name: "description", displayName: "Description", type: "TEXT"});
editable.attributesMap.set("links", {name: "links", displayName: "Links", type: "TEXT"});
editable.attributesMap.set("drugSubstanceType", {name: "drugSubstanceType", displayName: "Drug Substance Type", type: "Enumeration"});
editable.attributesMap.set("form", {name: "form", displayName: "Form", type: "STRING"});
editable.attributesMap.set("density", {name: "density", displayName: "Density", type: "STRING"});
editable.attributesMap.set("densityConditions", {name: "densityConditions", displayName: "Density Conditions", type: "TEXT"});
editable.attributesMap.set("chemicalStructure", {name: "chemicalStructure", displayName: "Chemical Structure", type: "STRING"});
editable.attributesMap.set("empiricalFormula", {name: "empiricalFormula", displayName: "Empirical Formula", type: "STRING"});
editable.attributesMap.set("molecularWeight", {name: "molecularWeight", displayName: "Molecular Weight", type: "STRING"});
editable.attributesMap.set("compendialStandard", {name: "compendialStandard", displayName: "Compendial Standard", type: "TEXT"});
editable.attributesMap.set("certificateOfAnalysis", {name: "certificateOfAnalysis", displayName: "Certificate Of Analysis", type: "BOOLEAN"});
editable.attributesMap.set("propertiesLinks", {name: "propertiesLinks", displayName: "Properties Links", type: "TEXT"});
editable.attributesMap.set("partNumber", {name: "partNumber", displayName: "Part Number", type: "STRING"});
editable.attributesMap.set("internalPartNumber", {name: "internalPartNumber", displayName: "Internal Part Number", type: "STRING"});
editable.attributesMap.set("gmp", {name: "gmp", displayName: "Gmp", type: "BOOLEAN"});
editable.attributesMap.set("materialQualified", {name: "materialQualified", displayName: "Material Qualified", type: "Enumeration"});
editable.attributesMap.set("regulatoryFiling", {name: "regulatoryFiling", displayName: "Regulatory Filing", type: "Enumeration"});
editable.attributesMap.set("effectiveDate", {name: "effectiveDate", displayName: "Effective Date", type: "STRING"});
editable.attributesMap.set("expirationDate", {name: "expirationDate", displayName: "Expiration Date", type: "STRING"});
editable.attributesMap.set("qualificationLinks", {name: "qualificationLinks", displayName: "Qualification Links", type: "TEXT"});
editable.attributesMap.set("referenceNumber", {name: "referenceNumber", displayName: "Reference Number", type: "STRING"});
editable.attributesMap.set("authorizationLetter", {name: "authorizationLetter", displayName: "Authorization Letter", type: "Enumeration"});
editable.attributesMap.set("regulatoryLinks", {name: "regulatoryLinks", displayName: "Regulatory Links", type: "TEXT"});
editable.attributesMap.set("referencesLinks", {name: "referencesLinks", displayName: "References Links", type: "TEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "Material", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("Material", editable);
editable.attributesMap.set("category", {name: "category", displayName: "Category", type: "Enumeration"});
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("chemicalNameCAS", {name: "chemicalNameCAS", displayName: "Chemical Name C A S", type: "STRING"});
editable.attributesMap.set("chemicalNameIUPAC", {name: "chemicalNameIUPAC", displayName: "Chemical Name I U P A C", type: "STRING"});
editable.attributesMap.set("otherNames", {name: "otherNames", displayName: "Other Names", type: "STRING"});
editable.attributesMap.set("innUsan", {name: "innUsan", displayName: "Inn Usan", type: "STRING"});
editable.attributesMap.set("casRegistryNumber", {name: "casRegistryNumber", displayName: "Cas Registry Number", type: "STRING"});
editable.attributesMap.set("function", {name: "function", displayName: "Function", type: "STRING"});
editable.attributesMap.set("description", {name: "description", displayName: "Description", type: "TEXT"});
editable.attributesMap.set("libraryDescription", {name: "libraryDescription", displayName: "Library Description", type: "TEXT"});
editable.attributesMap.set("gmp", {name: "gmp", displayName: "Gmp", type: "BOOLEAN"});
editable.attributesMap.set("use", {name: "use", displayName: "Use", type: "Enumeration"});
editable.attributesMap.set("links", {name: "links", displayName: "Links", type: "TEXT"});
editable.attributesMap.set("drugSubstanceType", {name: "drugSubstanceType", displayName: "Drug Substance Type", type: "Enumeration"});
editable.attributesMap.set("form", {name: "form", displayName: "Form", type: "STRING"});
editable.attributesMap.set("density", {name: "density", displayName: "Density", type: "STRING"});
editable.attributesMap.set("densityConditions", {name: "densityConditions", displayName: "Density Conditions", type: "TEXT"});
editable.attributesMap.set("fromLibraryStatus", {name: "fromLibraryStatus", displayName: "From Library Status", type: "Enumeration"});
editable.attributesMap.set("fromLibraryVersionId", {name: "fromLibraryVersionId", displayName: "From Library Version", type: "INTEGER"});
editable.attributesMap.set("fromLibraryModel", {name: "fromLibraryModel", displayName: "From Library Model", type: "STRING"});
editable.attributesMap.set("chemicalStructure", {name: "chemicalStructure", displayName: "Chemical Structure", type: "STRING"});
editable.attributesMap.set("empiricalFormula", {name: "empiricalFormula", displayName: "Empirical Formula", type: "STRING"});
editable.attributesMap.set("molecularWeight", {name: "molecularWeight", displayName: "Molecular Weight", type: "STRING"});
editable.attributesMap.set("compendialStandard", {name: "compendialStandard", displayName: "Compendial Standard", type: "TEXT"});
editable.attributesMap.set("certificateOfAnalysis", {name: "certificateOfAnalysis", displayName: "Certificate Of Analysis", type: "BOOLEAN"});
editable.attributesMap.set("propertiesLinks", {name: "propertiesLinks", displayName: "Properties Links", type: "TEXT"});
editable.attributesMap.set("descriptiveUnitAbsolute", {name: "descriptiveUnitAbsolute", displayName: "Descriptive Unit Absolute", type: "STRING"});
editable.attributesMap.set("quantityAbsolute", {name: "quantityAbsolute", displayName: "Quantity Absolute", type: "STRING"});
editable.attributesMap.set("quantityRelative", {name: "quantityRelative", displayName: "Quantity Relative", type: "STRING"});
editable.attributesMap.set("quantityPerDose", {name: "quantityPerDose", displayName: "Quantity Per Dose", type: "STRING"});
editable.attributesMap.set("formulationQuantityLinks", {name: "formulationQuantityLinks", displayName: "Formulation Quantity Links", type: "TEXT"});
editable.attributesMap.set("partNumber", {name: "partNumber", displayName: "Part Number", type: "STRING"});
editable.attributesMap.set("internalPartNumber", {name: "internalPartNumber", displayName: "Internal Part Number", type: "STRING"});
editable.attributesMap.set("materialQualified", {name: "materialQualified", displayName: "Material Qualified", type: "Enumeration"});
editable.attributesMap.set("effectiveDate", {name: "effectiveDate", displayName: "Effective Date", type: "STRING"});
editable.attributesMap.set("expirationDate", {name: "expirationDate", displayName: "Expiration Date", type: "STRING"});
editable.attributesMap.set("qualificationLinks", {name: "qualificationLinks", displayName: "Qualification Links", type: "TEXT"});
editable.attributesMap.set("regulatoryFiling", {name: "regulatoryFiling", displayName: "Regulatory Filing", type: "Enumeration"});
editable.attributesMap.set("referenceNumber", {name: "referenceNumber", displayName: "Reference Number", type: "STRING"});
editable.attributesMap.set("authorizationLetter", {name: "authorizationLetter", displayName: "Authorization Letter", type: "Enumeration"});
editable.attributesMap.set("regulatoryLinks", {name: "regulatoryLinks", displayName: "Regulatory Links", type: "TEXT"});
editable.attributesMap.set("referencesLinks", {name: "referencesLinks", displayName: "References Links", type: "TEXT"});
editable.attributesMap.set("techTransferComment", {name: "techTransferComment", displayName: "Tech Transfer Comment", type: "TEXT"});
editable.attributesMap.set("techTransferLinks", {name: "techTransferLinks", displayName: "Tech Transfer Links", type: "TEXT"});
editable.attributesMap.set("techTransferImpact", {name: "techTransferImpact", displayName: "Tech Transfer Impact", type: "INTEGER"});
editable.attributesMap.set("techTransferImpactDescription", {name: "techTransferImpactDescription", displayName: "Tech Transfer Impact Description", type: "TEXT"});
editable.attributesMap.set("techTransferUncertainty", {name: "techTransferUncertainty", displayName: "Tech Transfer Uncertainty", type: "INTEGER"});
editable.attributesMap.set("techTransferUncertaintyJustification", {name: "techTransferUncertaintyJustification", displayName: "Tech Transfer Uncertainty Justification", type: "TEXT"});
editable.attributesMap.set("techTransferDetectability", {name: "techTransferDetectability", displayName: "Tech Transfer Detectability", type: "INTEGER"});
editable.attributesMap.set("techTransferControlStrategy", {name: "techTransferControlStrategy", displayName: "Tech Transfer Control Strategy", type: "TEXT"});
editable.attributesMap.set("techTransferRiskMitigationStrategy", {name: "techTransferRiskMitigationStrategy", displayName: "Tech Transfer Risk Mitigation Strategy", type: "TEXT"});
editable.attributesMap.set("techTransferRecommendedActions", {name: "techTransferRecommendedActions", displayName: "Tech Transfer Recommended Actions", type: "TEXT"});
editable.attributesMap.set("isTechTransferOnlyChange", {name: "isTechTransferOnlyChange", displayName: "Is Tech Transfer Only Change", type: "BOOLEAN"});
editable.attributesMap.set("techTransferAssessmentStatus", {name: "techTransferAssessmentStatus", displayName: "Tech Transfer Assessment Status", type: "Enumeration"});
editable.attributesMap.set("rmpVersionId", {name: "rmpVersionId", displayName: "Rmp Version", type: "INTEGER"});
editable.attributesMap.set("tags", {name: "tags", displayName: "Tags", type: "LONGTEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "MaterialAttribute", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("MaterialAttribute", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("type", {name: "type", displayName: "Type", type: "Enumeration"});
editable.attributesMap.set("description", {name: "description", displayName: "Description", type: "TEXT"});
editable.attributesMap.set("dataSpace", {name: "dataSpace", displayName: "Data Space", type: "Enumeration"});
editable.attributesMap.set("potentialFailureModes", {name: "potentialFailureModes", displayName: "Potential Failure Modes", type: "TEXT"});
editable.attributesMap.set("measure", {name: "measure", displayName: "Measure", type: "Enumeration"});
editable.attributesMap.set("measurementUnits", {name: "measurementUnits", displayName: "Measurement Units", type: "STRING"});
editable.attributesMap.set("lowerLimit", {name: "lowerLimit", displayName: "Lower Limit", type: "STRING"});
editable.attributesMap.set("target", {name: "target", displayName: "Target", type: "STRING"});
editable.attributesMap.set("linkedToSpecificationVersionId", {name: "linkedToSpecificationVersionId", displayName: "Linked To Specification Version", type: "INTEGER"});
editable.attributesMap.set("riskLinks", {name: "riskLinks", displayName: "Risk Links", type: "TEXT"});
editable.attributesMap.set("upperLimit", {name: "upperLimit", displayName: "Upper Limit", type: "STRING"});
editable.attributesMap.set("targetJustification", {name: "targetJustification", displayName: "Target Justification", type: "TEXT"});
editable.attributesMap.set("acceptanceCriteriaLinks", {name: "acceptanceCriteriaLinks", displayName: "Acceptance Criteria Links", type: "TEXT"});
editable.attributesMap.set("recommendedActions", {name: "recommendedActions", displayName: "Recommended Actions", type: "TEXT"});
editable.attributesMap.set("capabilityRisk", {name: "capabilityRisk", displayName: "Capability Risk", type: "INTEGER"});
editable.attributesMap.set("capabilityJustification", {name: "capabilityJustification", displayName: "Capability Justification", type: "TEXT"});
editable.attributesMap.set("detectabilityRisk", {name: "detectabilityRisk", displayName: "Detectability Risk", type: "INTEGER"});
editable.attributesMap.set("detectabilityJustification", {name: "detectabilityJustification", displayName: "Detectability Justification", type: "TEXT"});
editable.attributesMap.set("ccp", {name: "ccp", displayName: "Ccp", type: "BOOLEAN"});
editable.attributesMap.set("samplingPlan", {name: "samplingPlan", displayName: "Sampling Plan", type: "BOOLEAN"});
editable.attributesMap.set("controlStrategy", {name: "controlStrategy", displayName: "Control Strategy", type: "TEXT"});
editable.attributesMap.set("controlStrategyJustification", {name: "controlStrategyJustification", displayName: "Control Strategy Justification", type: "TEXT"});
editable.attributesMap.set("riskControlLinks", {name: "riskControlLinks", displayName: "Risk Control Links", type: "TEXT"});
editable.attributesMap.set("referencesLinks", {name: "referencesLinks", displayName: "References Links", type: "TEXT"});
editable.attributesMap.set("estimatedSampleSize", {name: "estimatedSampleSize", displayName: "Estimated Sample Size", type: "STRING"});
editable.attributesMap.set("techTransferComment", {name: "techTransferComment", displayName: "Tech Transfer Comment", type: "TEXT"});
editable.attributesMap.set("techTransferLinks", {name: "techTransferLinks", displayName: "Tech Transfer Links", type: "TEXT"});
editable.attributesMap.set("techTransferImpact", {name: "techTransferImpact", displayName: "Tech Transfer Impact", type: "INTEGER"});
editable.attributesMap.set("techTransferImpactDescription", {name: "techTransferImpactDescription", displayName: "Tech Transfer Impact Description", type: "TEXT"});
editable.attributesMap.set("techTransferUncertainty", {name: "techTransferUncertainty", displayName: "Tech Transfer Uncertainty", type: "INTEGER"});
editable.attributesMap.set("techTransferUncertaintyJustification", {name: "techTransferUncertaintyJustification", displayName: "Tech Transfer Uncertainty Justification", type: "TEXT"});
editable.attributesMap.set("techTransferDetectability", {name: "techTransferDetectability", displayName: "Tech Transfer Detectability", type: "INTEGER"});
editable.attributesMap.set("techTransferControlStrategy", {name: "techTransferControlStrategy", displayName: "Tech Transfer Control Strategy", type: "TEXT"});
editable.attributesMap.set("techTransferRiskMitigationStrategy", {name: "techTransferRiskMitigationStrategy", displayName: "Tech Transfer Risk Mitigation Strategy", type: "TEXT"});
editable.attributesMap.set("techTransferRecommendedActions", {name: "techTransferRecommendedActions", displayName: "Tech Transfer Recommended Actions", type: "TEXT"});
editable.attributesMap.set("isTechTransferOnlyChange", {name: "isTechTransferOnlyChange", displayName: "Is Tech Transfer Only Change", type: "BOOLEAN"});
editable.attributesMap.set("techTransferAssessmentStatus", {name: "techTransferAssessmentStatus", displayName: "Tech Transfer Assessment Status", type: "Enumeration"});
editable.attributesMap.set("rmpVersionId", {name: "rmpVersionId", displayName: "Rmp Version", type: "INTEGER"});
editable.attributesMap.set("tags", {name: "tags", displayName: "Tags", type: "LONGTEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "Process", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("Process", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("description", {name: "description", displayName: "Description", type: "TEXT"});
editable.attributesMap.set("site", {name: "site", displayName: "Site", type: "TEXT"});
editable.attributesMap.set("gmp", {name: "gmp", displayName: "Gmp", type: "BOOLEAN"});
editable.attributesMap.set("scale", {name: "scale", displayName: "Scale", type: "TEXT"});
editable.attributesMap.set("referencesLinks", {name: "referencesLinks", displayName: "References Links", type: "TEXT"});
editable.attributesMap.set("techTransferEnabled", {name: "techTransferEnabled", displayName: "Tech Transfer Enabled", type: "BOOLEAN"});
editable.attributesMap.set("integrations", {name: "integrations", displayName: "Integrations", type: "TEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "ProcessComponent", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("ProcessComponent", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("type", {name: "type", displayName: "Type", type: "Enumeration"});
editable.attributesMap.set("function", {name: "function", displayName: "Function", type: "TEXT"});
editable.attributesMap.set("description", {name: "description", displayName: "Description", type: "TEXT"});
editable.attributesMap.set("certificateOfAnalysis", {name: "certificateOfAnalysis", displayName: "Certificate Of Analysis", type: "BOOLEAN"});
editable.attributesMap.set("links", {name: "links", displayName: "Links", type: "TEXT"});
editable.attributesMap.set("componentQualified", {name: "componentQualified", displayName: "Component Qualified", type: "Enumeration"});
editable.attributesMap.set("effectiveDate", {name: "effectiveDate", displayName: "Effective Date", type: "STRING"});
editable.attributesMap.set("expirationDate", {name: "expirationDate", displayName: "Expiration Date", type: "STRING"});
editable.attributesMap.set("componentQualificationLinks", {name: "componentQualificationLinks", displayName: "Component Qualification Links", type: "TEXT"});
editable.attributesMap.set("acceptanceTesting", {name: "acceptanceTesting", displayName: "Acceptance Testing", type: "Enumeration"});
editable.attributesMap.set("qualificationStatus", {name: "qualificationStatus", displayName: "Qualification Status", type: "Enumeration"});
editable.attributesMap.set("calibration", {name: "calibration", displayName: "Calibration", type: "Enumeration"});
editable.attributesMap.set("unitQualificationLinks", {name: "unitQualificationLinks", displayName: "Unit Qualification Links", type: "TEXT"});
editable.attributesMap.set("drugProductContact", {name: "drugProductContact", displayName: "Drug Product Contact", type: "BOOLEAN"});
editable.attributesMap.set("contactRisk", {name: "contactRisk", displayName: "Contact Risk", type: "STRING"});
editable.attributesMap.set("contactRiskJustification", {name: "contactRiskJustification", displayName: "Contact Risk Justification", type: "TEXT"});
editable.attributesMap.set("cleaningValidation", {name: "cleaningValidation", displayName: "Cleaning Validation", type: "Enumeration"});
editable.attributesMap.set("sterilizationValidation", {name: "sterilizationValidation", displayName: "Sterilization Validation", type: "Enumeration"});
editable.attributesMap.set("componentRiskLinks", {name: "componentRiskLinks", displayName: "Component Risk Links", type: "TEXT"});
editable.attributesMap.set("referencesLinks", {name: "referencesLinks", displayName: "References Links", type: "TEXT"});
editable.attributesMap.set("partNumber", {name: "partNumber", displayName: "Part Number", type: "STRING"});
editable.attributesMap.set("internalPartNumber", {name: "internalPartNumber", displayName: "Internal Part Number", type: "STRING"});
editable.attributesMap.set("unitId", {name: "unitId", displayName: "Unit", type: "STRING"});
editable.attributesMap.set("techTransferComment", {name: "techTransferComment", displayName: "Tech Transfer Comment", type: "TEXT"});
editable.attributesMap.set("techTransferLinks", {name: "techTransferLinks", displayName: "Tech Transfer Links", type: "TEXT"});
editable.attributesMap.set("techTransferImpact", {name: "techTransferImpact", displayName: "Tech Transfer Impact", type: "INTEGER"});
editable.attributesMap.set("techTransferImpactDescription", {name: "techTransferImpactDescription", displayName: "Tech Transfer Impact Description", type: "TEXT"});
editable.attributesMap.set("techTransferUncertainty", {name: "techTransferUncertainty", displayName: "Tech Transfer Uncertainty", type: "INTEGER"});
editable.attributesMap.set("techTransferUncertaintyJustification", {name: "techTransferUncertaintyJustification", displayName: "Tech Transfer Uncertainty Justification", type: "TEXT"});
editable.attributesMap.set("techTransferDetectability", {name: "techTransferDetectability", displayName: "Tech Transfer Detectability", type: "INTEGER"});
editable.attributesMap.set("techTransferControlStrategy", {name: "techTransferControlStrategy", displayName: "Tech Transfer Control Strategy", type: "TEXT"});
editable.attributesMap.set("techTransferRiskMitigationStrategy", {name: "techTransferRiskMitigationStrategy", displayName: "Tech Transfer Risk Mitigation Strategy", type: "TEXT"});
editable.attributesMap.set("techTransferRecommendedActions", {name: "techTransferRecommendedActions", displayName: "Tech Transfer Recommended Actions", type: "TEXT"});
editable.attributesMap.set("isTechTransferOnlyChange", {name: "isTechTransferOnlyChange", displayName: "Is Tech Transfer Only Change", type: "BOOLEAN"});
editable.attributesMap.set("techTransferAssessmentStatus", {name: "techTransferAssessmentStatus", displayName: "Tech Transfer Assessment Status", type: "Enumeration"});
editable.attributesMap.set("rmpVersionId", {name: "rmpVersionId", displayName: "Rmp Version", type: "INTEGER"});
editable.attributesMap.set("tags", {name: "tags", displayName: "Tags", type: "LONGTEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "ProcessParameter", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("ProcessParameter", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("type", {name: "type", displayName: "Type", type: "Enumeration"});
editable.attributesMap.set("description", {name: "description", displayName: "Description", type: "TEXT"});
editable.attributesMap.set("dataSpace", {name: "dataSpace", displayName: "Data Space", type: "Enumeration"});
editable.attributesMap.set("measure", {name: "measure", displayName: "Measure", type: "Enumeration"});
editable.attributesMap.set("measurementUnits", {name: "measurementUnits", displayName: "Measurement Units", type: "STRING"});
editable.attributesMap.set("lowerLimit", {name: "lowerLimit", displayName: "Lower Limit", type: "STRING"});
editable.attributesMap.set("target", {name: "target", displayName: "Target", type: "STRING"});
editable.attributesMap.set("riskLinks", {name: "riskLinks", displayName: "Risk Links", type: "TEXT"});
editable.attributesMap.set("upperLimit", {name: "upperLimit", displayName: "Upper Limit", type: "STRING"});
editable.attributesMap.set("targetJustification", {name: "targetJustification", displayName: "Target Justification", type: "TEXT"});
editable.attributesMap.set("lowerOperatingLimit", {name: "lowerOperatingLimit", displayName: "Lower Operating Limit", type: "STRING"});
editable.attributesMap.set("upperOperatingLimit", {name: "upperOperatingLimit", displayName: "Upper Operating Limit", type: "STRING"});
editable.attributesMap.set("acceptanceCriteriaLinks", {name: "acceptanceCriteriaLinks", displayName: "Acceptance Criteria Links", type: "TEXT"});
editable.attributesMap.set("potentialFailureModes", {name: "potentialFailureModes", displayName: "Potential Failure Modes", type: "TEXT"});
editable.attributesMap.set("scaleDependent", {name: "scaleDependent", displayName: "Scale Dependent", type: "Enumeration"});
editable.attributesMap.set("scaleJustification", {name: "scaleJustification", displayName: "Scale Justification", type: "TEXT"});
editable.attributesMap.set("recommendedActions", {name: "recommendedActions", displayName: "Recommended Actions", type: "TEXT"});
editable.attributesMap.set("techTransferComment", {name: "techTransferComment", displayName: "Tech Transfer Comment", type: "TEXT"});
editable.attributesMap.set("techTransferLinks", {name: "techTransferLinks", displayName: "Tech Transfer Links", type: "TEXT"});
editable.attributesMap.set("techTransferImpact", {name: "techTransferImpact", displayName: "Tech Transfer Impact", type: "INTEGER"});
editable.attributesMap.set("techTransferImpactDescription", {name: "techTransferImpactDescription", displayName: "Tech Transfer Impact Description", type: "TEXT"});
editable.attributesMap.set("techTransferUncertainty", {name: "techTransferUncertainty", displayName: "Tech Transfer Uncertainty", type: "INTEGER"});
editable.attributesMap.set("techTransferUncertaintyJustification", {name: "techTransferUncertaintyJustification", displayName: "Tech Transfer Uncertainty Justification", type: "TEXT"});
editable.attributesMap.set("techTransferDetectability", {name: "techTransferDetectability", displayName: "Tech Transfer Detectability", type: "INTEGER"});
editable.attributesMap.set("techTransferControlStrategy", {name: "techTransferControlStrategy", displayName: "Tech Transfer Control Strategy", type: "TEXT"});
editable.attributesMap.set("techTransferRiskMitigationStrategy", {name: "techTransferRiskMitigationStrategy", displayName: "Tech Transfer Risk Mitigation Strategy", type: "TEXT"});
editable.attributesMap.set("techTransferRecommendedActions", {name: "techTransferRecommendedActions", displayName: "Tech Transfer Recommended Actions", type: "TEXT"});
editable.attributesMap.set("capabilityRisk", {name: "capabilityRisk", displayName: "Capability Risk", type: "INTEGER"});
editable.attributesMap.set("capabilityJustification", {name: "capabilityJustification", displayName: "Capability Justification", type: "TEXT"});
editable.attributesMap.set("detectabilityRisk", {name: "detectabilityRisk", displayName: "Detectability Risk", type: "INTEGER"});
editable.attributesMap.set("detectabilityJustification", {name: "detectabilityJustification", displayName: "Detectability Justification", type: "TEXT"});
editable.attributesMap.set("ccp", {name: "ccp", displayName: "Ccp", type: "BOOLEAN"});
editable.attributesMap.set("samplingPlan", {name: "samplingPlan", displayName: "Sampling Plan", type: "BOOLEAN"});
editable.attributesMap.set("controlStrategy", {name: "controlStrategy", displayName: "Control Strategy", type: "TEXT"});
editable.attributesMap.set("controlStrategyJustification", {name: "controlStrategyJustification", displayName: "Control Strategy Justification", type: "TEXT"});
editable.attributesMap.set("riskControlLinks", {name: "riskControlLinks", displayName: "Risk Control Links", type: "TEXT"});
editable.attributesMap.set("referencesLinks", {name: "referencesLinks", displayName: "References Links", type: "TEXT"});
editable.attributesMap.set("estimatedSampleSize", {name: "estimatedSampleSize", displayName: "Estimated Sample Size", type: "STRING"});
editable.attributesMap.set("isTechTransferOnlyChange", {name: "isTechTransferOnlyChange", displayName: "Is Tech Transfer Only Change", type: "BOOLEAN"});
editable.attributesMap.set("techTransferAssessmentStatus", {name: "techTransferAssessmentStatus", displayName: "Tech Transfer Assessment Status", type: "Enumeration"});
editable.attributesMap.set("rmpVersionId", {name: "rmpVersionId", displayName: "Rmp Version", type: "INTEGER"});
editable.attributesMap.set("tags", {name: "tags", displayName: "Tags", type: "LONGTEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "Project", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("Project", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("isDemo", {name: "isDemo", displayName: "Is Demo", type: "BOOLEAN"});
editable.attributesMap.set("customProjectId", {name: "customProjectId", displayName: "Custom Project", type: "STRING"});
editable.attributesMap.set("type", {name: "type", displayName: "Type", type: "Enumeration"});
editable.attributesMap.set("category", {name: "category", displayName: "Category", type: "Enumeration"});
editable.attributesMap.set("links", {name: "links", displayName: "Links", type: "TEXT"});
editable.attributesMap.set("purposeAndScope", {name: "purposeAndScope", displayName: "Purpose And Scope", type: "TEXT"});
editable.attributesMap.set("objectives", {name: "objectives", displayName: "Objectives", type: "TEXT"});
editable.attributesMap.set("purposeLinks", {name: "purposeLinks", displayName: "Purpose Links", type: "TEXT"});
editable.attributesMap.set("qualityByDesignPhase", {name: "qualityByDesignPhase", displayName: "Quality By Design Phase", type: "Enumeration"});
editable.attributesMap.set("cmcPhase", {name: "cmcPhase", displayName: "Cmc Phase", type: "Enumeration"});
editable.attributesMap.set("validationPhase", {name: "validationPhase", displayName: "Validation Phase", type: "Enumeration"});
editable.attributesMap.set("dosageForm", {name: "dosageForm", displayName: "Dosage Form", type: "STRING"});
editable.attributesMap.set("routeOfAdministration", {name: "routeOfAdministration", displayName: "Route Of Administration", type: "STRING"});
editable.attributesMap.set("regulatoryPath", {name: "regulatoryPath", displayName: "Regulatory Path", type: "Enumeration"});
editable.attributesMap.set("regulatoryPhase", {name: "regulatoryPhase", displayName: "Regulatory Phase", type: "Enumeration"});
editable.attributesMap.set("referenceListedDrug", {name: "referenceListedDrug", displayName: "Reference Listed Drug", type: "STRING"});
editable.attributesMap.set("productRiskAssessmentType", {name: "productRiskAssessmentType", displayName: "Product Risk Assessment Type", type: "Enumeration"});
editable.attributesMap.set("rmpVersionId", {name: "rmpVersionId", displayName: "Rmp Version", type: "INTEGER"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});
editable.attributesMap.set("manufacturingLeadId", {name: "manufacturingLeadId", displayName: "Manufacturing Lead", type: "INTEGER"});
editable.attributesMap.set("projectManagerId", {name: "projectManagerId", displayName: "Project Manager", type: "INTEGER"});
editable.attributesMap.set("projectSponsorId", {name: "projectSponsorId", displayName: "Project Sponsor", type: "INTEGER"});
editable.attributesMap.set("cmcLeadId", {name: "cmcLeadId", displayName: "Cmc Lead", type: "INTEGER"});
editable.attributesMap.set("deviceLeadId", {name: "deviceLeadId", displayName: "Device Lead", type: "INTEGER"});
editable.attributesMap.set("qcLeadId", {name: "qcLeadId", displayName: "Qc Lead", type: "INTEGER"});
editable.attributesMap.set("qaLeadId", {name: "qaLeadId", displayName: "Qa Lead", type: "INTEGER"});
editable.attributesMap.set("regulatoryLeadId", {name: "regulatoryLeadId", displayName: "Regulatory Lead", type: "INTEGER"});

editable = {name: "Requirement", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("Requirement", editable);
editable.attributesMap.set("refTypeCode", {name: "refTypeCode", displayName: "Ref Type Code", type: "STRING"});
editable.attributesMap.set("refId", {name: "refId", displayName: "Ref", type: "INTEGER"});
editable.attributesMap.set("refVersionId", {name: "refVersionId", displayName: "Ref Version", type: "INTEGER"});
editable.attributesMap.set("projectId", {name: "projectId", displayName: "Project", type: "INTEGER"});
editable.attributesMap.set("event", {name: "event", displayName: "Event", type: "STRING"});

editable = {name: "RMP", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("RMP", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("description", {name: "description", displayName: "Description", type: "TEXT"});
editable.attributesMap.set("minRiskScore", {name: "minRiskScore", displayName: "Min Risk Score", type: "INTEGER"});
editable.attributesMap.set("maxRiskScore", {name: "maxRiskScore", displayName: "Max Risk Score", type: "INTEGER"});
editable.attributesMap.set("rMPLinks", {name: "rMPLinks", displayName: "R M P Links", type: "TEXT"});
editable.attributesMap.set("useUncertainty", {name: "useUncertainty", displayName: "Use Uncertainty", type: "BOOLEAN"});
editable.attributesMap.set("useDetectability", {name: "useDetectability", displayName: "Use Detectability", type: "BOOLEAN"});
editable.attributesMap.set("configureByType", {name: "configureByType", displayName: "Configure By Type", type: "BOOLEAN"});
editable.attributesMap.set("configureByTypeSettings", {name: "configureByTypeSettings", displayName: "Configure By Type Settings", type: "TEXT"});
editable.attributesMap.set("useRulesBasedCriticality", {name: "useRulesBasedCriticality", displayName: "Use Rules Based Criticality", type: "BOOLEAN"});
editable.attributesMap.set("usePotential", {name: "usePotential", displayName: "Use Potential", type: "BOOLEAN"});
editable.attributesMap.set("potentialLabel", {name: "potentialLabel", displayName: "Potential Label", type: "STRING"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "Step", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("Step", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("description", {name: "description", displayName: "Description", type: "TEXT"});
editable.attributesMap.set("links", {name: "links", displayName: "Links", type: "TEXT"});
editable.attributesMap.set("tags", {name: "tags", displayName: "Tags", type: "TEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "Supplier", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("Supplier", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("address", {name: "address", displayName: "Address", type: "TEXT"});
editable.attributesMap.set("phone", {name: "phone", displayName: "Phone", type: "STRING"});
editable.attributesMap.set("website", {name: "website", displayName: "Website", type: "STRING"});
editable.attributesMap.set("servicesOrProducts", {name: "servicesOrProducts", displayName: "Services Or Products", type: "TEXT"});
editable.attributesMap.set("supplierRank", {name: "supplierRank", displayName: "Supplier Rank", type: "Enumeration"});
editable.attributesMap.set("auditMethod", {name: "auditMethod", displayName: "Audit Method", type: "Enumeration"});
editable.attributesMap.set("classification", {name: "classification", displayName: "Classification", type: "Enumeration"});
editable.attributesMap.set("dateCompleted", {name: "dateCompleted", displayName: "Date Completed", type: "STRING"});
editable.attributesMap.set("nextAudit", {name: "nextAudit", displayName: "Next Audit", type: "STRING"});
editable.attributesMap.set("auditLinks", {name: "auditLinks", displayName: "Audit Links", type: "TEXT"});
editable.attributesMap.set("additionalAuditComments", {name: "additionalAuditComments", displayName: "Additional Audit Comments", type: "TEXT"});
editable.attributesMap.set("additionalQualificationComments", {name: "additionalQualificationComments", displayName: "Additional Qualification Comments", type: "TEXT"});
editable.attributesMap.set("qualificationStatus", {name: "qualificationStatus", displayName: "Qualification Status", type: "Enumeration"});
editable.attributesMap.set("effectiveDate", {name: "effectiveDate", displayName: "Effective Date", type: "STRING"});
editable.attributesMap.set("expirationDate", {name: "expirationDate", displayName: "Expiration Date", type: "STRING"});
editable.attributesMap.set("qualityAgreement", {name: "qualityAgreement", displayName: "Quality Agreement", type: "Enumeration"});
editable.attributesMap.set("supplyAgreement", {name: "supplyAgreement", displayName: "Supply Agreement", type: "Enumeration"});
editable.attributesMap.set("qualificationLinks", {name: "qualificationLinks", displayName: "Qualification Links", type: "TEXT"});
editable.attributesMap.set("riskRating", {name: "riskRating", displayName: "Risk Rating", type: "Enumeration"});
editable.attributesMap.set("riskJustification", {name: "riskJustification", displayName: "Risk Justification", type: "TEXT"});
editable.attributesMap.set("riskMitigation", {name: "riskMitigation", displayName: "Risk Mitigation", type: "TEXT"});
editable.attributesMap.set("riskControl", {name: "riskControl", displayName: "Risk Control", type: "TEXT"});
editable.attributesMap.set("riskLinks", {name: "riskLinks", displayName: "Risk Links", type: "TEXT"});
editable.attributesMap.set("primaryContactName", {name: "primaryContactName", displayName: "Primary Contact Name", type: "STRING"});
editable.attributesMap.set("primaryContactPhone", {name: "primaryContactPhone", displayName: "Primary Contact Phone", type: "STRING"});
editable.attributesMap.set("primaryContactEmail", {name: "primaryContactEmail", displayName: "Primary Contact Email", type: "STRING"});
editable.attributesMap.set("primaryContactTitle", {name: "primaryContactTitle", displayName: "Primary Contact Title", type: "STRING"});
editable.attributesMap.set("regulatoryContactName", {name: "regulatoryContactName", displayName: "Regulatory Contact Name", type: "STRING"});
editable.attributesMap.set("regulatoryContactPhone", {name: "regulatoryContactPhone", displayName: "Regulatory Contact Phone", type: "STRING"});
editable.attributesMap.set("regulatoryContactEmail", {name: "regulatoryContactEmail", displayName: "Regulatory Contact Email", type: "STRING"});
editable.attributesMap.set("regulatoryContactTitle", {name: "regulatoryContactTitle", displayName: "Regulatory Contact Title", type: "STRING"});
editable.attributesMap.set("qualityContactName", {name: "qualityContactName", displayName: "Quality Contact Name", type: "STRING"});
editable.attributesMap.set("qualityContactPhone", {name: "qualityContactPhone", displayName: "Quality Contact Phone", type: "STRING"});
editable.attributesMap.set("qualityContactEmail", {name: "qualityContactEmail", displayName: "Quality Contact Email", type: "STRING"});
editable.attributesMap.set("qualityContactTitle", {name: "qualityContactTitle", displayName: "Quality Contact Title", type: "STRING"});
editable.attributesMap.set("otherContactName", {name: "otherContactName", displayName: "Other Contact Name", type: "STRING"});
editable.attributesMap.set("otherContactPhone", {name: "otherContactPhone", displayName: "Other Contact Phone", type: "STRING"});
editable.attributesMap.set("otherContactEmail", {name: "otherContactEmail", displayName: "Other Contact Email", type: "STRING"});
editable.attributesMap.set("otherContactTitle", {name: "otherContactTitle", displayName: "Other Contact Title", type: "STRING"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "TPPSection", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("TPPSection", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("target", {name: "target", displayName: "Target", type: "TEXT"});
editable.attributesMap.set("annotations", {name: "annotations", displayName: "Annotations", type: "TEXT"});
editable.attributesMap.set("comments", {name: "comments", displayName: "Comments", type: "TEXT"});
editable.attributesMap.set("links", {name: "links", displayName: "Links", type: "TEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});

editable = {name: "UnitOperation", attributesMap: new Map([["id", {name: "id", displayName: "Id", type: "INTEGER"}]])};
nameToEditableMap.set("UnitOperation", editable);
editable.attributesMap.set("name", {name: "name", displayName: "Name", type: "STRING"});
editable.attributesMap.set("description", {name: "description", displayName: "Description", type: "TEXT"});
editable.attributesMap.set("input", {name: "input", displayName: "Input", type: "STRING"});
editable.attributesMap.set("output", {name: "output", displayName: "Output", type: "STRING"});
editable.attributesMap.set("links", {name: "links", displayName: "Links", type: "TEXT"});
editable.attributesMap.set("risk", {name: "risk", displayName: "Risk", type: "Enumeration"});
editable.attributesMap.set("tags", {name: "tags", displayName: "Tags", type: "LONGTEXT"});
editable.attributesMap.set("currentState", {name: "currentState", displayName: "Current State", type: "STRING"});
editable.attributesMap.set("clonedFromVersionId", {name: "clonedFromVersionId", displayName: "Cloned From Version", type: "INTEGER"});
editable.attributesMap.set("clonedFromModel", {name: "clonedFromModel", displayName: "Cloned From Model", type: "STRING"});
module.exports = {
nameToEditableMap,
};
