"use strict";

/*
This file includes all column's configuration per model type that shows up on the excel export
and what columns show per model type.
 */

export const FIELD_PROPS = {
  LINKED_ASSETS: "linkedAssets",
  ACCEPTANCE_CRITERIA: "acceptanceCriteria",
  SUPPLIER_OR_ACCEPTANCE_CRITERIA: "supplierOrAcceptanceCriteria",
  RISK_LINKS: "riskLinksSummary",
  RISK_LINKS_DOCUMENTS: "riskLinksDocuments",
  SOURCE_DOCUMENTS: "sourceDocuments",
  CONTROL: "control",
  CATEGORY_USE: "categoryUse",

  MAX_CRITICALITY: "maxCriticality",
  MAX_CRITICALITY_PERCENTAGE: "maxCriticalityPerc",

  CHANGE: "change",
  COMMENT: "techTransferComment",
  ASSESSMENT_STATUS: "techTransferAssessmentStatus",
  DOCUMENT_REFERENCE: "documentReferences",
  TECH_TRANSFER_REFERENCE: "techTransferLinks",
  IMPACT_DESCRIPTION: "techTransferImpactDescription",
  TECH_TRANSFER_IMPACT: "techTransferImpact",
  UNCERTAINTY_JUSTIFICATION: "techTransferUncertaintyJustification",
  TECH_TRANSFER_UNCERTAINTY: "techTransferUncertainty",
  TECH_TRANSFER_CONTROL_STRATEGY: "techTransferControlStrategy",
  TECH_TRANSFER_DETECTABILITY: "techTransferDetectability",
  OUTCOME: "outcome",
  RISK_MITIGATION: "techTransferRiskMitigationStrategy",
  TECH_TRANSFER_RECOMMENDED_ACTIONS: "techTransferRecommendedActions",

  ID: "id",
  NAME: "name",
  TYPE: "type",
  SCOPE: "scope",
  PARENT: "parent",
  RISK: "risk",
  INPUT: "input",
  OUTPUT: "output",
  TPP_SECTIONS: "tPPSections",
  GENERAL_ATTRIBUTES: "generalAttributes",
  DESCRIPTION: "description",

  UNIT_OPERATION: "unitOperation",
  UNIT_OPERATION_NAME: "unitOperationName",
  UNIT_OPERATIONS: "unitOperations",
  PREVIOUS_UNIT: "previousUnit",
  PREVIOUS_STEP: "previousStep",

  STEP: "step",
  STEPS: "steps",
  STEP_NAME: "stepName",

  USE: "use",
  CATEGORY: "category",
  FUNCTION: "function",
  LINKS: "links",

  DATA_SPACE: "dataSpace",
  MEASURE: "measure",
  MEASUREMENT_UNITS: "measurementUnits",
  LOWER_LIMIT: "lowerLimit",
  TARGET: "target",
  ANNOTATIONS: "annotations",
  COMMENTS: "comments",
  UPPER_LIMIT: "upperLimit",
  CONTROL_METHODS: "controlMethods",
  SAMPLING_PLAN: "samplingPlan",
  STABILITY_INDICATING: "stabilityIndicating",
  TARGET_JUSTIFICATION: "targetJustification",

  LOWER_OPERATING_LIMIT: "lowerOperatingLimit",
  UPPER_OPERATING_LIMIT: "upperOperatingLimit",
  ACCEPTANCE_CRITERIA_LINKS: "acceptanceCriteriaLinks",

  IMPACT: "impact",
  EFFECT: "effect",
  UNCERTAINTY: "uncertainty",
  OBLIGATORY_CQA: "obligatoryCQA",

  DETECTABILITY: "detectability",
  DETECTABILITY_RISK: "detectabilityRisk",
  RPN: "RPN",
  RPN_PERCENTAGE: "RPNPerc",
  DETECTABILITY_JUSTIFICATION: "detectabilityJustification",
  CONTROL_STRATEGY: "controlStrategy",
  CCP: "ccp",
  CONTROL_STRATEGY_JUSTIFICATION: "controlStrategyJustification",
  RISK_CONTROL_LINKS: "riskControlLinks",

  CAPABILITY_RISK: "capabilityRisk",
  CAPABILITY_JUSTIFICATION: "capabilityJustification",
  PROCESS_RISK: "processRisk",
  PROCESS_RISK_PERCENTAGE: "processRiskPerc",
  ESTIMATED_SAMPLE_SIZE: "estimatedSampleSize",

  POTENTIAL_FAILURES: "potentialFailureModes",
  SCALE_DEPENDENT: "scaleDependent",
  SCALE_JUSTIFICATION: "scaleJustification",
  RECOMMENDED_ACTIONS: "recommendedActions",
  CRITICALITY_JUSTIFICATION: "criticalityJustification",
  CRITICALITY: "criticality",
  JUSTIFICATION: "justification",
  CRITICALITY_PERCENTAGE: "criticalityPerc",
  CRITICALITY_ASSESSMENT_LINKS: "criticalityAssessmentLinks",

  REFERENCES: "referencesLinks",

  DESCRIPTIVE_UNIT_ABSOLUTE: "descriptiveUnitAbsolute",
  QUANTITY_ABSOLUTE: "quantityAbsolute",
  QUANTITY_RELATIVE: "quantityRelative",
  QUANTITY_PER_DOSE: "quantityPerDose",
  FORMULATION_QUANTITY_LINKS: "formulationQuantityLinks",

  SUPPLIER: "supplier",
  PART_NUMBER: "partNumber",
  INTERNAL_PART_NUMBER: "internalPartNumber",
  MATERIAL_QUALIFIED: "materialQualified",
  COMPONENT_QUALIFIED: "componentQualified",
  EFFECTIVE_DATE: "effectiveDate",
  EXPIRATION_DATE: "expirationDate",
  GMP: "gmp",
  FORM: "form",
  DENSITY: "density",
  DENSITY_CONDITIONS: "densityConditions",
  USED_IN_PROJECTS: "Materials",
  ACTIONS: "actions",
  QUALIFICATION_LINKS: "qualificationLinks",
  COMPONENT_QUALIFICATION_LINKS: "componentQualificationLinks",

  REGULATORY_FILING: "regulatoryFiling",
  REFERENCE_NUMBER: "referenceNumber",
  AUTHORIZATION_LETTER: "authorizationLetter",
  REGULATORY_LINKS: "regulatoryLinks",

  DRUG_SUBSTANCE_TYPE: "drugSubstanceType",
  CHEMICAL_STRUCTURE: "chemicalStructure",
  EMPIRICAL_FORMULA: "empiricalFormula",
  MOLECULAR_WEIGHT: "molecularWeight",
  CHEMICAL_NAME_CAS: "chemicalNameCAS",
  CHEMICAL_NAME_IUPAC: "chemicalNameIUPAC",
  OTHER_NAMES: "otherNames",
  INN_USAN: "innUsan",
  CAS_REGISTRY_NUMBER: "casRegistryNumber",
  STANDARD_GRADE: "compendialStandard",
  CERTIFICATE_OF_ANALYSIS: "certificateOfAnalysis",
  PROPERTIES_LINKS: "propertiesLinks",

  ACCEPTANCE_TESTING: "acceptanceTesting",
  QUALIFICATION_STATUS: "qualificationStatus",
  UNIT_ID: "unitId",
  UNIT_QUALIFICATION_LINKS: "unitQualificationLinks",

  DRUG_PRODUCT_CONTACT: "drugProductContact",
  CONTACT_RISK: "contactRisk",
  CONTACT_RISK_JUSTIFICATION: "contactRiskJustification",
  CLEANING_VALIDATION: "cleaningValidation",
  STERILIZATION_VALIDATION: "sterilizationValidation",
  COMPONENT_RISK_LINKS: "componentRiskLinks",

  COMPENDIAL_STANDARD: "compendialStandard",
  EQUIPMENT: "equipment",
  STATUS: "status",
  VERSION: "version",
  CONTROL_METHOD_LINKS: "controlMethodLinks",
  DEVELOPMENT_LINKS: "developmentLinks",

  ADDRESS: "address",
  PHONE: "phone",
  WEBSITE: "website",
  SERVICES_OR_PRODUCTS: "servicesOrProducts",
  SUPPLIER_RANK: "supplierRank",
  AUDIT_METHOD: "auditMethod",
  DATE_COMPLETED: "dateCompleted",
  NEXT_AUDIT: "nextAudit",
  ADDITIONAL_COMMENTS: "additionalComments:",
  QUALITY_AGREEMENT: "qualityAgreement",
  SUPPLY_AGREEMENT: "supplyAgreement",
  RISK_RATING: "riskRating",
  RISK_JUSTIFICATION: "riskJustification",
  SUPPLIER_RISK_MITIGATION: "riskMitigation",
  RISK_CONTROL: "riskControl",
  CURRENT_STATE: "currentState",
};

export const SECTION_LABELS = {
  ABOUT: "About",
  CRITICALITY: "Criticality Assessment",
  ACCEPTANCE_CRITERIA: "Acceptance Criteria",
  RISK_CONTROL: "Risk Control",
  REFERENCES: "References"
};
