"use strict";

import * as styles from "../view/table_tool_bar.module.scss";
import React, { memo } from "react";
import { BulkEditButton } from "./actions/bulk_edit_button";
import { BulkProposeButton } from "./actions/bulk_propose_button";
import { useTableState } from "../../../../table_state_provider";
import * as UIUtils from "../../../../../../ui_utils";
import { EXPERIMENTS } from "../../../../../../../server/common/generic/common_experiments";

export const EditTableToolBar = memo(({modelName}) => {

  const {selectedCount, clearSelectedRows} = useTableState();

  const onClick = () => {
    clearSelectedRows();
  };

  return (
    <div id="toolBarDiv" className={styles["container"]}>

      <div className={styles["selection-container"]}>
        <span className={styles["selection-count"]}>{selectedCount} selected</span>
        <a
          onClick={onClick}
          className={styles["clear-selection"]}
        >Clear</a>
      </div>

      <div className={styles["bulk-actions-area"]}>
        <BulkProposeButton modelName={modelName} />
        {UIUtils.isExperimentEnabled(EXPERIMENTS.BulkEdit) && <BulkEditButton />}
      </div>

    </div>
  );
});