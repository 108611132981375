"use strict";

import BaseReactComponent from "../../base_react_component";
import * as UIUtils from "../../ui_utils";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { Ensure } from "../../../server/common/generic/common_ensure";
import Cookies from "js-cookie";
import { SHOW_REMOVED } from "../../utils/filter_helper";
import { GLOBAL_COLLAPSE_STATES } from "../../widgets/constants/constants";
import React, { Fragment } from "react";
import SearchBox from "../../widgets/tables/search_box";
import ExpandButton from "../../widgets/generic/expand_button";

export default class BaseListPage extends BaseReactComponent {

  constructor(props) {
    super(props);
  }

  /**
   * Get list page related types
   * @returns {string[]}
   */
  getRelatedTypes() {
    Ensure.notImplemented("getRelatedTypes");
  }

  /**
   * Must be implemented to render the page for the selected record type.
   * @returns {QTPP|TPP}
   */
  // eslint-disable-next-line no-unused-vars
  renderSelectedRecord(commonProps) {
    Ensure.notImplemented("renderSelectedRecord");
  }

  /**
   * Must be implemented to render AddButton for the selected record type.
   * @returns {AddButton}
   */
  renderAddRecordButton() {
    Ensure.notImplemented("renderAddRecordButton");
  }

  handleSuggestionSelect(suggestion) {
    let key = UIUtils.parseKey(suggestion.label);
    window.location.href = UIUtils.getSecuredURL(getURLByTypeCodeAndId(key.typeCode, "View", key.id));
  }

  handleSearchTermChange(searchTerm) {
    this.setStateSafely({
      clearSearchTerm: false,
      searchTerm,
    }, () => {
      const {childrenDataTables} = this.state;
      for (const table of childrenDataTables) {
        table.search(searchTerm).draw();
      }
    });
  }

  handleUpdateRefs(childrenReferences) {
    const childrenDataTables = [];
    const childrenTables = [];

    for (const prop in childrenReferences) {
      if (Object.prototype.hasOwnProperty.call(childrenReferences, prop)) {
        const childrenReference = childrenReferences[prop];
        const dataTable = $(childrenReference.tableRef).DataTable();

        childrenDataTables.push(dataTable);
        dataTable.search("").draw();

        childrenTables.push(childrenReference);
      }
    }

    this.setStateSafely({childrenTables, childrenDataTables});
  }

  handleGlobalExpandStatusChange(globalExpandStatus) {
    this.setStateSafely({
      globalExpandStatus,
      localExpandStatus: null,
    });
  }

  handleLocalExpandStatusChange(localExpandStatus) {
    this.setStateSafely({
      localExpandStatus,
      globalExpandStatus: null,
    });
  }

  componentDidUpdate(prevProps) {
    const {showArchived} = this.props;
    const oldShowArchived = prevProps.showArchived;
    if (showArchived !== oldShowArchived) {
      for (const type of this.getRelatedTypes()) {
        Cookies.set(`${SHOW_REMOVED}_${type}`, showArchived);
      }

      const {childrenTables} = this.state;
      for (const child of childrenTables || []) {
        child.handleChangeShowRemoved(showArchived);
      }
    }
  }

  handleTypeaheadOptionsReceived(instances) {
    instances = this.props.showArchived ?
      instances : instances.filter(instance => !instance.deletedAt);

    instances = instances.sort(UIUtils.sortBy({
      name: "modelName",
      reverse: true,
    }, {
      name: "name"
    }));

    const typeaheadOptions = new Set();
    for (const instance of instances) {
      let label = UIUtils.getRecordLabelForDisplay(UIUtils.getTypeCodeForModelName(instance.modelName), instance.id, instance.name);
      typeaheadOptions.add(label);
    }

    this.setStateSafely({
      typeaheadOptions: [...typeaheadOptions].map(label => {
        return {label};
      }),
    });
  }

  render() {
    const {
      clearSearchTerm,
      globalExpandStatus,
      localExpandStatus,
      searchTerm,
      typeaheadOptions,
      project
    } = this.state;

    const {
      onUpdateRecordCount,
    } = this.props;

    const commonProps = {
      globalExpandStatus: localExpandStatus ? GLOBAL_COLLAPSE_STATES.NONE : globalExpandStatus,
      onLocalExpandStatusChange: this.handleLocalExpandStatusChange,
      onUpdateRefs: this.handleUpdateRefs,
      onTypeaheadOptionsReceived: this.handleTypeaheadOptionsReceived,
      searchTerm,
      onUpdateRecordCount,
      project
    };

    return (
      <Fragment>
        <div className="row product-settings-bar">
          <div className="col-sm-4 product-search-box">
            <SearchBox
              id="productSearchTypeahead"
              items={typeaheadOptions || []}
              clearSearchTerm={clearSearchTerm}
              onSuggestionSelected={this.handleSuggestionSelect}
              onSearchTermChange={this.handleSearchTermChange}
            />
          </div>
          <div className="col-5" />
          <div className="col-sm-3">
            <div className="product-buttons-container">
              <ExpandButton id="productExpandButton"
                            parent={this}
                            localExpandStatus={localExpandStatus}
                            onGlobalExpandStatusChange={this.handleGlobalExpandStatusChange}
              />
              {this.renderAddRecordButton()}
            </div>
          </div>
        </div>
        {this.renderSelectedRecord(commonProps)}
      </Fragment>);
  }
}