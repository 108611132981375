"use strict";

import React from "react";
import Typeahead from "../../widgets/typeahead";
import { renderTypeaheadItems } from "../chart_reports/chart_report_helper";
import DropdownButton from "../../widgets/generic/dropdown_button";
import BaseReactComponent from "../../base_react_component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { MODEL_DECLARATIONS } from "../../../server/common/generic/common_models";
import { REPORT_TYPES_ENUM } from "../constants/report_constants";

export default class PCMDashboardToolbar extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.processId !== this.props.processId && this.typeahead) {
      this.typeahead.getInstance().clear();
    }
  }

  filterOptions(options) {
    const {
      parentId,
      reportType
    } = this.props;

    if (parentId && reportType === REPORT_TYPES_ENUM.LibraryProcessCapabilityDashboard) {
      return options.filter(option => option.libraryMaterialId === parentId);
    }
    return options;
  }

  render() {
    let selectedTypeaheadOption = this.props.selectedTypeaheadOption;
    const isSelectedTypeaheadOptionSet = selectedTypeaheadOption && selectedTypeaheadOption.label;
    return (
      <div id="pcmDashboardOptionsPanel" className="pcm-dashboard-toolbar-container row"
      >
        <div className="col align-items-center">
          <div className="row">
            <div className="col-auto">
              <label htmlFor="pcmDashboardSearchTypehead"
                     className="pcm-dashboard-search-label"
              >
                Requirement:
              </label>
            </div>
            <div className={"col" + (isSelectedTypeaheadOptionSet ? " pr-0" : "")}>
              <div id="pCMDashboardTypeaheadDiv" className="pcm-dashboard-typeahead">
                <Typeahead id="pCMDashboardTypeahead"
                           inputProps={{id: "pCMDashboardTypeaheadInput", autoComplete: "off"}}
                           renderMenuItemChildren={renderTypeaheadItems.bind(this)}
                           options={this.filterOptions(this.props.typeaheadOptions)}
                           onChange={this.props.onSearchSelectionChanged}
                           selected={isSelectedTypeaheadOptionSet ? [selectedTypeaheadOption.label] : null}
                           maxResults={100}
                           className="risk-map-search-typeahead"
                           selectHintOnEnter={true}
                           ref={typeahead => this.typeahead = typeahead}
                           multiple={false}
                />
              </div>
            </div>
            {isSelectedTypeaheadOptionSet ? (
              <div className="col-auto pl-0 align-self-center">
                {selectedTypeaheadOption.typeCode !== MODEL_DECLARATIONS.SPECIFICATION.typeCode &&
                  (<a id="chartViewRequirement"
                      href={getURLByTypeCodeAndId(selectedTypeaheadOption.typeCode, "View", selectedTypeaheadOption.id)}
                      target="_blank"
                      rel="noopener noreferrer"
                      title={"View " + selectedTypeaheadOption.label}
                >
                  <FontAwesomeIcon id="chartsViewRecord"
                                   icon={faExternalLinkAlt}
                  />
                </a>) }
              </div>
            ) : ""}
          </div>
        </div>
        <div className="col-auto align-items-center">
          <label className="pcm-dashboard-search-label">
            Showing:
          </label>
          <span id="chartReportShowingMessage">
            {this.props.selectedSubgroups.length} of {this.props.allSubgroups.length} subgroups.
              </span>
          <a id="chartsChangeSubgroups" onClick={this.props.onChangeData}>Change</a>
        </div>
        <div className="col-auto pcm-dashboard-toolbar-btn-container">
          <DropdownButton className="btn-group pcm-dashboard-export-button"
                          options={this.props.reportOptions.exportAs}
                          text="Export As"
                          id="pcmDashboardExport"
                          isDisabled={this.props.disableDashboardButtons}
                          onOptionsSelect={this.props.onDashboardExport}
          />
          <a id="pcmDashboardControlChartsButton"
             onClick={this.props.onDashboardControlChartClick}
          >
            Control Charts &gt;
          </a>
        </div>
      </div>
    );
  }
}
