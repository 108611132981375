"use strict";

import React from "react";
import { RISK_MAP_COLORS } from "../../constants/report_constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { GHOST_NODE_OPACITY } from "../risk_map";
import * as CommonUtils from "../../../../server/common/generic/common_utils";
import { RISK_COLORS } from "../../../rmps/constants/rmp_constants";
import BaseReactComponent from "../../../base_react_component";

export default class RiskMapShape extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  getIQAPolygonPoints(numberOfSides, itemWidth, itemHeight, shapeSize, borderWidth) {
    let polygonPoints = [];

    let x = itemWidth / 2;
    let y = itemHeight / 2;
    let size = shapeSize / 2 - borderWidth;
    polygonPoints.push((x + size * Math.cos(0)) + "," + y);
    for (let i = 1; i <= numberOfSides; i += 1) {
      polygonPoints.push((x + size * Math.cos(i * 2 * Math.PI / numberOfSides)) + "," + (y + size * Math.sin(i * 2 * Math.PI / numberOfSides)));
    }

    return polygonPoints;
  }

  getMAPolygonPoints(spikes, itemWidth, itemHeight, shapeSize) {
    let polygonPoints = [];

    let outerRadius = shapeSize / 2;
    let innerRadius = shapeSize / 6;
    let rot = Math.PI / 2 * 3;
    let step = Math.PI / spikes;
    let x = itemWidth / 2;
    let y = itemHeight / 2;
    let cx = x;
    let cy = y;

    polygonPoints.push(cx + "," + (cy - outerRadius));
    for (let i = 0; i < spikes; i++) {
      x = cx + Math.cos(rot) * outerRadius;
      y = cy + Math.sin(rot) * outerRadius;
      polygonPoints.push(x + "," + y);
      rot += step;

      x = cx + Math.cos(rot) * innerRadius;
      y = cy + Math.sin(rot) * innerRadius;
      polygonPoints.push(x + "," + y);
      rot += step;
    }
    polygonPoints.push(cx + "," + (cy - outerRadius));

    return polygonPoints;
  }

  getPPPolygonPoints(itemWidth, itemHeight, shapeWidth, shapeHeight) {
    let polygonPoints = [];

    let x = (itemWidth - shapeWidth) / 2;
    let y = (itemHeight - shapeHeight) / 2;
    polygonPoints.push(x + "," + (y + shapeHeight));
    polygonPoints.push((x + shapeWidth) + "," + (y + shapeHeight));
    polygonPoints.push((x + (shapeWidth / 2)) + "," + y);

    return polygonPoints;
  }

  getShape(type, itemHeight, itemWidth, shapeHeight, shapeWidth, color, currentState, selected) {
    let stroke;
    let strokeDasharray;
    let fillColor;
    let borderWidth = typeof this.props.borderWidth === "number" ? this.props.borderWidth : 2;

    color = color ? color : RISK_COLORS.NONE;
    if (currentState !== CommonUtils.VERSION_STATES.APPROVED) {
      strokeDasharray = shapeWidth / 15;
    }

    if (type === "TPP" || type === "GA") {
      stroke = selected ? RISK_MAP_COLORS.tpp.highlight.border : RISK_MAP_COLORS.tpp.border;
      fillColor = selected ? RISK_MAP_COLORS.tpp.highlight.background : RISK_MAP_COLORS.tpp.background;
    } else {
      stroke = selected ? RISK_MAP_COLORS[color].highlight.border : RISK_MAP_COLORS[color].border;
      fillColor = selected ? RISK_MAP_COLORS[color].highlight.background : RISK_MAP_COLORS[color].background;
    }

    let strokeOpacity = 1.0;
    let fillOpacity = 1.0;
    if (!this.props.renderFullColor) {
      strokeOpacity = GHOST_NODE_OPACITY + (selected ? 0.3 : 0);
      fillOpacity = GHOST_NODE_OPACITY + (selected ? 0.3 : 0);
    }

    switch (type) {
      case "FQA":
      case "FPA":
        return <circle cx={itemWidth / 2}
                       cy={itemHeight / 2}
                       r={shapeWidth / 2 - borderWidth}
                       stroke={borderWidth > 0 ? stroke : ""}
                       strokeWidth={borderWidth > 0 ? borderWidth : ""}
                       strokeDasharray={strokeDasharray ? strokeDasharray : ""}
                       strokeOpacity={strokeOpacity}
                       fill={fillColor}
                       fillOpacity={fillOpacity}
                       className="risk-map-timeline-item-shape" />;
      case "TPP":
      case "GA":
        return <rect x={(itemWidth - shapeWidth + borderWidth) / 2}
                     y={(itemHeight - shapeHeight + borderWidth) / 2}
                     width={shapeWidth - borderWidth}
                     height={shapeHeight - borderWidth}
                     stroke={stroke}
                     strokeWidth={borderWidth}
                     strokeDasharray={strokeDasharray ? strokeDasharray : ""}
                     strokeOpacity={strokeOpacity}
                     fill={fillColor}
                     fillOpacity={fillOpacity}
                     className="risk-map-timeline-item-shape" />;
      case "IQA":
      case "IPA":
        return <polygon points={this.getIQAPolygonPoints(6, itemWidth, itemHeight, shapeWidth, borderWidth).join(" ")}
                        stroke={stroke}
                        strokeWidth={borderWidth}
                        strokeDasharray={strokeDasharray ? strokeDasharray : ""}
                        strokeOpacity={strokeOpacity}
                        fill={fillColor}
                        fillOpacity={fillOpacity}
                        className="risk-map-timeline-item-shape" />;
      case "MA":
        return <polygon points={this.getMAPolygonPoints(5, itemWidth, itemHeight, shapeWidth).join(" ")}
                        stroke={stroke}
                        strokeWidth={borderWidth}
                        strokeDasharray={strokeDasharray ? strokeDasharray : ""}
                        strokeOpacity={strokeOpacity}
                        fill={fillColor}
                        fillOpacity={fillOpacity}
                        className="risk-map-timeline-item-shape" />;
      case "PP":
        return <polygon points={this.getPPPolygonPoints(itemWidth, itemHeight, shapeWidth, shapeHeight).join(" ")}
                        stroke={stroke}
                        strokeWidth={borderWidth}
                        strokeDasharray={strokeDasharray ? strokeDasharray : ""}
                        strokeOpacity={strokeOpacity}
                        fill={fillColor}
                        fillOpacity={fillOpacity}
                        className="risk-map-timeline-item-shape" />;
    }
  }

  render() {
    let itemHeight = this.props.itemHeight;
    let itemWidth = this.props.itemWidth;
    let shapeHeight = this.props.shapeHeight;
    let shapeWidth = this.props.shapeWidth;

    if (this.props.selected) {
      itemHeight = itemHeight * 1.2;
      itemWidth = itemWidth * 1.2;
      shapeHeight = shapeHeight * 1.2;
      shapeWidth = shapeWidth * 1.2;
    }

    let shape = this.getShape(
      this.props.type,
      itemHeight,
      itemWidth,
      shapeHeight,
      shapeWidth,
      this.props.color,
      this.props.currentState,
      !!this.props.selected);

    return <div>
      <svg height={itemHeight}
           width={itemWidth}>
        <svg height={itemHeight}
             width={itemWidth}>
          {shape}
        </svg>
        {this.props.currentState === CommonUtils.VERSION_STATES.ARCHIVED || this.props.currentState === CommonUtils.VERSION_STATES.ARCHIVED_CASCADED ?
          <FontAwesomeIcon icon={faTimes}
                           className="risk-map-timeline-item-archived-shape"
                           height={shapeHeight / 2}
                           width={shapeWidth / 2}
                           x={itemWidth / 2 - shapeWidth / 4}
                           y={this.props.type === "PP" ? itemHeight / 2 - shapeHeight / 8
                             : itemHeight / 2 - shapeHeight / 4} /> : ""}
      </svg>
      {this.props.legend ?
        <div className="risk-map-timeline-item-legend">
          {this.props.legend}
        </div> : ""}
    </div>;
  }
}
