"use strict";

import * as UIUtils from "../ui_utils";
import { measurePerformanceEnd, measurePerformanceStart } from "../ui_utils";
import React, { Fragment } from "react";
import InfoTooltip from "../widgets/tooltips/info_tooltip";
import Section from "../editor/widgets/section";
import ComboBoxAttribute from "../editor/attributes/combo_box_attribute";
import LinksAttribute from "../editor/attributes/links_attribute";
import NumberAttribute from "../editor/attributes/number_attribute";
import CalculatedRiskAttribute from "../editor/attributes/calculated_risk_attribute";
import CheckboxAttribute from "../editor/attributes/checkbox_attribute";
import TextAreaAttribute from "../editor/attributes/text_area_attribute";
import {
  getCalculationFormulaTooltip,
  getRawRiskScore,
  getRiskFieldOptions,
  getRiskFieldTooltip,
  getRiskScaleTooltip,
} from "../helpers/risk_helper";
import { EMPTY_STRING, RISK_TYPE_ENUM } from "../helpers/constants/constants";
import { getMeasureDefaultUnit, getMinSampleSize } from "../helpers/attribute_helper";
import { isQualitativeMeasure } from "../../server/common/editables/common_editables";
import ReportURLGenerator from "../reports/report_url_builder";
import { REPORT_OPTIONS_ENUM, REPORT_TYPES_ENUM } from "../reports/constants/report_constants";
import RelatedBatchesPanel from "../widgets/relatedRecords/related_batches_panel";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";
import AcceptanceCriteria from "./widgets/acceptance_criteria";
import { TABLE_MODE } from "./attributes/base_json_attribute";
import { BaseMultipleSupportDocumentsAttribute } from "../product/editors/base_multiple_support_documents_attribute";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCSSClassForLegend } from "../reports/risk_map_reports/utilities/risk_map_report_helper";
import RiskUtils from "../../server/common/misc/common_risk_utils";
import { RecordSpecificationStatusBar } from "../library/widgets/record_specification_status_bar";
import MemoryCache from "../utils/cache/memory_cache";
import BaseObjectCache from "../utils/cache/base_object_cache";
import TypeaheadObjectCacheFactory from "../utils/cache/typeahead_object_cache_factory";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Editables, "BaseRequirementAttributePage");

/**
 * This is the base react class for FQAs, MAs, PPs and IQAs.
 */
export default class BaseRequirementAttributePage extends BaseMultipleSupportDocumentsAttribute {
  constructor(props, baseTypeName, capitalizedBaseTypeName, displayName) {
    super(props, baseTypeName, capitalizedBaseTypeName, displayName);

    if (this.isAdd()) {
      this.state.measure = this.getMeasureFieldDefaultOption();
      this.state.measurementUnits = "";
    }

    if (this.isAdd()) {
      this.setStateSafely({
        riskTableInitialized: false,
        Requirement: {
          AcceptanceCriteriaRanges: [{
            typeCode: "ACR",
            uuid: UIUtils.generateUUID(),
            label: "Default",
            group: "Default",
            isDefault: true,
            lowerLimit: null,
            upperLimit: null,
            target: null,
            measurementUnits: null,
            justification: "",
          }],
        },
      });
    } else {
      this.setStateSafely({
        riskTableInitialized: false
      });
    }
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.riskTable) {
      this.setStateSafely({
        riskTableInitialized: true,
        riskTableInstance: this.riskTable.getInstance()
      });
    }
  }

  componentDidUpdate(prevState) {
    if (!prevState.ProjectId && this.state.ProjectId) {
      this.loadRiskLinksFromAllRecords();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
    if (!shouldUpdate) {
      shouldUpdate = this.state.riskTableInitialized !== nextState.riskTableInitialized ||
        JSON.stringify(this.state.riskTableInstance) !== JSON.stringify(nextState.riskTableInstance);
    }
    return shouldUpdate;
  }

  getTypesToCache() {
    return ["Project", "Process", "ControlMethod", "AcceptanceCriteriaRange", ...super.getTypesToCache()];
  }

  /* This is used by components inheriting this base requirement component to set the risk
     table ref to the state and control when the risks have changed and when to rerender the
     component.
   */
  setRiskTableToState(riskTable) {
    if (riskTable) {
      this.riskTable = riskTable;

      if (this._isMounted) {
        this.setStateSafely({
          riskTableInitialized: true,
          riskTableInstance: riskTable.getInstance()
        });
      }
    }
  }

  handleTypeaheadResultsFromServer(callback, results, typeCode, remainingTypesToCache) {
    super.handleTypeaheadResultsFromServer(callback, results, typeCode, remainingTypesToCache);
    if (results) {
      if (typeCode === "ACR") {
        this.setStateSafely({
          allAcceptanceCriteriaRanges: results || [],
        });
      }
    }
  }

  beforeDataSavedToServer(callback) {
    new TypeaheadObjectCache("AcceptanceCriteriaRange").invalidateCacheOptions();
    super.beforeDataSavedToServer(callback);
  }

  preprocessReceivedData(result) {
    let ranges = (
      this.isApproved()
        ? result?.RequirementVersion?.AcceptanceCriteriaRangeLinkedVersions
        : result?.Requirement?.AcceptanceCriteriaRanges
    ) ?? [];
    let primaryReportingCriteria = ranges.find(range => range.isDefault);

    if (primaryReportingCriteria) {
      result.primaryReportingCriteria = primaryReportingCriteria.uuid;
    }
    Logger.debug(">> PREPROCESS RECEIVED DATA: ", Log.object(result));
    return super.preprocessReceivedData(result);
  }

  /**
   * This gets the measure options used in the measure drop down control. An empty string is also provided
   * as an item for the drop down list to provide the ability to the user to leave the field empty.
   * @returns {string[]}
   */
  getMeasureOptions() {
    return [""].concat(Object.values(UIUtils.MEASUREMENT_TYPES));
  }

  handleChangeValue(fieldName, value, callback, attributeType) {
    const previousValue = this.state[fieldName];

    super.handleChangeValue(fieldName, value, callback, attributeType);
    if (fieldName === "measure") {
      /* As part of QI-3482, we should reset the Target field to an empty string if the user switches between the "Conforms"
         option in the Measure field and anything else.
       */
      const shouldResetTarget = previousValue === UIUtils.MEASUREMENT_TYPES.CONFORMS
        || value === UIUtils.MEASUREMENT_TYPES.CONFORMS;

      this.setStateSafely({
        measure: value,
        measurementUnits: getMeasureDefaultUnit(value),
        updateControls: true,
        lowerLimit: this.shouldClearFieldValue("lowerLimit", value) ? "" : this.state.lowerLimit,
        upperLimit: this.shouldClearFieldValue("upperLimit", value) ? "" : this.state.upperLimit,
        target: shouldResetTarget ? "" : this.state.target,

        Requirement: {
          ...this.state.Requirement,
          AcceptanceCriteriaRanges: this.state.Requirement.AcceptanceCriteriaRanges.map(range => ({
            ...range,
            measurementUnits: getMeasureDefaultUnit(value),
            lowerLimit: this.shouldClearFieldValue("lowerLimit", value) ? "" : this.state.lowerLimit,
            upperLimit: this.shouldClearFieldValue("upperLimit", value) ? "" : this.state.upperLimit,
            target: shouldResetTarget ? "" : this.state.target,
          })),
        },

      }, () => {
        this.setStateSafely({
          updateControls: false,
        });
      });
    } else if (fieldName === "ProcessId" && value) {
      const cache = new TypeaheadObjectCache("UnitOperation", this.getProjectId(), value);
      cache.loadOptions(() => this.setStateSafely({ProcessId: value}));
      if (UIUtils.parseInt(value) !== UIUtils.parseInt(this.state.ProcessId)) {
        // Clear out the UnitOperationId && StepId if the Process changed.
        this.setStateSafely({UnitOperationId: null, StepId: null});
      }
    } else if (fieldName === "UnitOperationId" && value) {
      if (UIUtils.parseInt(value) !== UIUtils.parseInt(this.state.UnitOperationId)) {
        // Clear out the StepId as well if the UO changed.
        this.setStateSafely({StepId: null});
      }
    }
  }

  shouldClearFieldValue(fieldName, value) {
    switch (fieldName) {
      case "lowerLimit":
        return value === UIUtils.MEASUREMENT_TYPES.UPPER_LIMIT
          || value === UIUtils.MEASUREMENT_TYPES.DEFECTS
          || value === UIUtils.MEASUREMENT_TYPES.CONFORMS;
      case "upperLimit":
        return value === UIUtils.MEASUREMENT_TYPES.LOWER_LIMIT
          || value === UIUtils.MEASUREMENT_TYPES.CONFORMS;
    }
  }

  // noinspection JSMethodCanBeStatic
  getMeasureFieldDefaultOption() {
    return EMPTY_STRING;
  }

  getRelatedBatches() {
    return this.state.batches || [];
  }

  renderGeneralSection() {
    throw Error("You must override this method.");
  }

  renderRiskLinksAttribute() {
    return EMPTY_STRING;
  }

  renderCriticalityAssessmentSection() {
    throw Error("You must override this method.");
  }

  isDetailedRiskLinks() {
    return false;
  }

  renderAcceptanceCriteriaSection() {
    const interactionName = "renderAcceptanceCriteriaSection";
    measurePerformanceStart(interactionName);

    const requirement = this.state.Requirement;
    const ranges = (requirement && requirement.AcceptanceCriteriaRanges) || [];
    const {primaryReportingCriteria} = this.state;

    const defaultCriteriaProps = [
      {id: "lowerLimit", label: "LSL"},
      {id: "target", label: "Target"},
      {id: "upperLimit", label: "USL"},
      {id: "measurementUnits", label: "Measurement Units"},
      {id: "targetJustification", label: "Justification"},
    ];

    let rangeOptions = [
      ...defaultCriteriaProps,
      ...ranges.map((criteria) => {
        const label = this.getDisplayNameForAcceptanceCriteriaRange(criteria);
        return {id: label, label};
      }),
    ];

    const acceptanceCriteriaRangeOptions = this.getAcceptanceCriteriaRangeOptions();
    const shouldShowPrimaryReportingCriteria = (
      (this.isEdit() || this.isAdd())
      && acceptanceCriteriaRangeOptions.length > 1
    );

    const section = <Section
      id="acceptanceCriteria"
      parent={this}
      showDocLinks={true}
      header={
        <span>Acceptance Criteria <InfoTooltip
          id="infoAcceptanceCriteria"
          verbiage={<div>
            Acceptance criteria means numerical limits, ranges, or
            other criteria for tests that are used for or in
            making a decision to accept or reject a unit, lot, or
            batch
            of a drug product.
          </div>}
        /></span>}
      addOptions={rangeOptions}
    >
      <RecordSpecificationStatusBar record={this.state}
                                    isDiffingVersions={this.isDiffingVersions()}
                                    olderVersion={this.getOlderVersion()}
      />
      <div className="row">
        <ComboBoxAttribute name="dataSpace"
                           className="col-sm-4"
                           options={["Knowledge", "Design", "Control"]}
                           tooltipText={(<div>
                             <b>Knowledge Space</b> – The total set of variables that contribute to process
                             stability for each unit operation across a practical range of variability.
                             <br />
                             <br />
                             <b>Design Space</b> - The multidimensional combination and interaction of input
                             variables (e.g., material attributes) and process parameters that have been
                             demonstrated
                             to provide assurance of quality. Working within the design space is not considered a
                             change. Movement out of the design space is considered to be a chance and
                             would normally initiate a regulatory post-approval change process.
                             <br />
                             <br />
                             <b>Control Space</b> - A narrower portion of the Design Space that represents the
                             recommended limits allowed in the master batch record. Also defined as the Normal
                             Operating Range.
                           </div>)}
                           parent={this}
                           isLoading={this.state.isLoading}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           parentId={this.state.id}
        />
        <ComboBoxAttribute name="measure"
                           className="col-sm-4"
                           options={this.getMeasureOptions()}
                           disabled={!!this.state.hasBatchData || this.state.acceptanceCriteriaEditorMode === TABLE_MODE.EDITOR_OPEN}
                           instructions={"This field will be disabled while editing an item in the Acceptance Criteria Range table or once a measure has Batch/Lot data."}
                           default={this.getMeasureFieldDefaultOption()}
                           parent={this}
                           isLoading={this.state.isLoading}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           parentId={this.state.id}
        />
        {
          // This is a weird field, because it actually edits the values in other objects
          // so, we only show it in add or edit mode.
          shouldShowPrimaryReportingCriteria ? (
            <ComboBoxAttribute name="primaryReportingCriteria"
                               className="col-sm-4"
                               options={acceptanceCriteriaRangeOptions
                                 // does not include items with empty label (happens when you click the add button).
                                 .filter(item => item.label)
                               }
                               disabled={this.state.acceptanceCriteriaEditorMode === TABLE_MODE.EDITOR_OPEN}
                               instructions={"This field will be disabled while editing an item in the Acceptance Criteria Range table. This criteria will be used as default for reports. You can always change it later"}
                               parent={this}
                               onChange={this.handlePrimaryReportingCriteriaChanged}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
          ) : ""
        }
      </div>
      <AcceptanceCriteria parent={this}
                          isDiff={this.isDiffingVersions()}
                          measure={this.state.measure}
                          allAcceptanceCriteriaRanges={this.getAllAcceptanceCriteriaRanges()}
                          requirement={UIUtils.deepClone(this.state.Requirement)}
                          requirementVersion={UIUtils.deepClone(this.state.RequirementVersion)}
                          primaryReportingCriteria={primaryReportingCriteria || this.getDefaultAcceptanceCriteriaRange()}
                          onEditorModeChanged={this.handleAcceptanceCriteriaEditorModeChanged}
      />
      <div className="row">
        <LinksAttribute name="ControlMethod"
                        tooltipText="Specific testing methodology to measure a critical parameter and ensure it meets defined specification."
                        className="col-sm-12"
                        parent={this}
                        parentId={this.state.id}
                        isLoading={this.state.isLoading}
                        projectId={this.getProjectId()}
                        processId={this.getProcessId()}
        />
      </div>
      <div className="row">
        <CheckboxAttribute name="samplingPlan"
                           tooltipText="A lot acceptance sampling plan (LASP) is a sampling scheme and a set of rules for making decisions. The decision, based on counting the number of defectives in a sample, can be to accept the lot, reject the lot, or even, for multiple or sequential sampling schemes, to take another sample and then repeat the decision process."
                           parent={this}
        />
        {this.renderStabilityIndicating()}
      </div>
      {this.renderAcceptanceCriteriaSectionExtension()}
    </Section>;

    measurePerformanceEnd(interactionName);

    return section;
  }

  renderStabilityIndicating() {
    return EMPTY_STRING;
  }

  handleAcceptanceCriteriaEditorModeChanged(mode) {
    Logger.warn("Editor mode changed?", mode);
    this.setStateSafely({acceptanceCriteriaEditorMode: mode});
  }

  getAcceptanceCriteriaRangeOptions() {
    const ranges = this.getAcceptanceCriteriaRangesFromState();
    return ranges
      // adds the mandatory fields for a list item to the range object
      .map(item => ({...item, key: item.uuid, value: this.getDisplayNameForAcceptanceCriteriaRange(item)}));
  }

  getDefaultAcceptanceCriteriaRange() {
    const {primaryReportingCriteria} = this.state;
    let result = "";
    const ranges = this.getAllAcceptanceCriteriaRanges();
    const range = ranges.find(rowData => !!rowData.isDefault || (rowData.uuid === primaryReportingCriteria));
    if (range) {
      result = range.uuid;
    }
    return result;
  }

  getOptions() {
    return this.getAllAcceptanceCriteriaRanges();
  }

  getAllAcceptanceCriteriaRanges() {
    return (this?.state?.allAcceptanceCriteriaRanges || [])
      .sort(UIUtils.sortBy("group", "label"));
  }

  getDisplayNameForAcceptanceCriteriaRange(acc) {
    return acc.group === "Default" && acc.label === "Default"
      ? "Default criteria"
      : `${acc.group} - ${acc.label}`;
  }

  handlePrimaryReportingCriteriaChanged(sender, event, value) {
    const allRanges = this.getAcceptanceCriteriaRangesFromState();

    let changed = false;
    for (let range of allRanges) {
      const newValue = (range.uuid === value);
      changed = (newValue !== range.isDefault);
      range.isDefault = newValue;
    }

    if (changed) {
      this.setStateSafely({
        primaryReportingCriteria: value,
        Requirement: {
          ...this.state.Requirement,
          AcceptanceCriteriaRanges: allRanges,
        },
      }, () => {
        Logger.warn(">> DEFAULT ACC CHANGED: VALUE: ", Log.object({
          value,
          AcceptanceCriteriaRanges: this.state.Requirement?.AcceptanceCriteriaRanges
        }));
      });
    }
  }

  getAcceptanceCriteriaRangesFromState() {
    const ranges = this.isApproved() || this.isDiffingVersions()
      ? this.state?.RequirementVersion?.AcceptanceCriteriaRangeLinkedVersions
      : this.state?.Requirement?.AcceptanceCriteriaRanges;

    return ([...(ranges ?? [])])
      .sort(UIUtils.sortBy("group", "label"));
  }

  renderAcceptanceCriteriaSectionExtension() {
    return EMPTY_STRING;
  }

  renderProcessCapabilitySection(effectiveRMP, olderRMP) {
    const interactionName = "renderProcessCapabilitySection-2";
    const isLoading = !effectiveRMP;
    measurePerformanceStart(interactionName);

    const section = <Section id="processCapability"
                             parent={this}
                             header={<span>Process Capability <InfoTooltip id="infoProcessCapability"
                                                                           verbiage={<div>
                                                                             Process capability is determined by comparing the
                                                                             output of a process to the acceptance criteria
                                                                           </div>}
                             /></span>}
                             headerLink={this.renderViewAnalytics()}
    >
      <div className="row">
        <ComboBoxAttribute name="capabilityRisk"
                           displayName="Capability Risk/Occurrence"
                           className="col-sm-4"
                           isNumber={true}
                           options={effectiveRMP && getRiskFieldOptions(effectiveRMP, "RMPToCapabilityRiskLinkedVersions")}
                           olderOptions={olderRMP && getRiskFieldOptions(olderRMP, "RMPToCapabilityRiskLinkedVersions")}
                           default={RiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.CAPABILITY_RISK, effectiveRMP)}
                           getTooltipCallback={getRiskFieldTooltip.bind(this, RISK_TYPE_ENUM.CAPABILITY_RISK, effectiveRMP)}
                           isLoading={isLoading}
                           parent={this}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           parentId={this.state.id}
        />
        <CalculatedRiskAttribute name="processRisk"
                                 className="col-sm-4"
                                 displayName="Process Risk (Raw)"
                                 riskFactor1="capabilityRisk"
                                 riskFactor2={getRawRiskScore.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP)}
                                 riskType={RISK_TYPE_ENUM.PROCESS_RISK}
                                 RMP={effectiveRMP}
                                 olderRMP={olderRMP}
                                 getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.PROCESS_RISK, effectiveRMP, this.state, false)}
                                 getSubscriptTooltipCallback={getCalculationFormulaTooltip.bind(this, RISK_TYPE_ENUM.PROCESS_RISK, effectiveRMP, this.state, true, false, null, this.isDetailedRiskLinks())}
                                 subscriptText="How is this calculated?"
                                 valueType="raw"
                                 isLoading={isLoading}
                                 parent={this}
                                 olderVersion={this.getOlderVersion()}
        />
        <CalculatedRiskAttribute name="processRiskPercentage"
                                 className="col-sm-4"
                                 displayName="Process Risk (%)"
                                 riskFactor1="capabilityRisk"
                                 riskFactor2={getRawRiskScore.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP)}
                                 riskType={RISK_TYPE_ENUM.PROCESS_RISK}
                                 RMP={effectiveRMP}
                                 olderRMP={olderRMP}
                                 getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.PROCESS_RISK, effectiveRMP, this.state, true)}
                                 getSubscriptTooltipCallback={getCalculationFormulaTooltip.bind(this, RISK_TYPE_ENUM.PROCESS_RISK, effectiveRMP, this.state, false, true, null, this.isDetailedRiskLinks())}
                                 subscriptText="How is this calculated?"
                                 valueType="percentage"
                                 isLoading={isLoading}
                                 parent={this}
                                 olderVersion={this.getOlderVersion()}
        />
      </div>
      {this.renderProcessCapabilitySectionExtension()}
    </Section>;

    measurePerformanceEnd(interactionName);

    return section;
  }

  renderViewAnalytics() {
    let reportOptions = REPORT_OPTIONS_ENUM.ProcessCapabilityDashboard;
    let reportType = REPORT_TYPES_ENUM.ProcessCapabilityDashboard;

    let urlParams = {
      reportType: reportType,
      projectId: this.getProjectId(),
      processId: this.getProcessId(),
      modelLabel: this.getAttributeKey(),
    };

    const hasBatchData = this.state.batches?.length >= 2;
    return (
      hasBatchData ?
        <Fragment>
          <a
            id="viewAnalyticsLink"
            className="links-btn-enabled"
            href={ReportURLGenerator.generateURL(reportOptions, urlParams)}
            target="_blank"
            rel="noopener noreferrer"
          >View Analytics
            <FontAwesomeIcon className="m-0 ml-1"
                             icon={faUpRightFromSquare}
                             size={"sm"}
            />
          </a>

        </Fragment> :
        <Fragment>
          <span
            className="links-btn-disabled"
            id="viewAnalyticsLink"
            title="Add at least 2 batches to view analytics"
          >View Analytics</span>
        </Fragment>
    );
  }

  getAttributeKey() {
    return `${UIUtils.getTypeCodeForModelName(this.baseTypeName)}-${this.state.id}`;
  }

  renderProcessCapabilitySectionExtension() {
    return <Fragment>
      <div className="row">
        <NumberAttribute name="estimatedSampleSize"
                         className="col-sm-4"
                         instructions={this.getEstimatedSampleSizeInstructions()}
                         warning={this.getEstimatedSampleSizeWarning()}
                         tooltipText="Estimated sample size for your Batch/Lot data. The value is used to validate actual sample sizes calculated from your input data."
                         parent={this}
        />
      </div>
      <div className="row">
        <TextAreaAttribute name="capabilityJustification"
                           displayName="Justification"
                           className="col-sm-12"
                           tooltipText="Capability Risk values should be justified by literature, data, or other science-based rationales."
                           parent={this}
        />
      </div>
    </Fragment>;
  }

  getEstimatedSampleSizeInstructions() {
    if (isQualitativeMeasure(this.state.measure) && !!this.state.target) {
      return <table className="risk-calculation-tooltip-table">
        <tr>
          {`Based on your Target value, we estimate this value should be ${getMinSampleSize(this.state.target)}.`}
        </tr>
        <tr>
          <td className="risk-calculation-tooltip-cell">
            Sample Size
          </td>
          <td>
            = Round (
          </td>
          <td className="risk-calculation-tooltip-cell">
            - ln (1 - &gamma;)
          </td>
          <td>
            /
          </td>
          <td className="risk-calculation-tooltip-cell">
            Target
          </td>
          <td>
            )
          </td>
        </tr>
        <tr>
          <td />
          <td>
            = Round (
          </td>
          <td className="risk-calculation-tooltip-cell">
            - ln (1 - 0.9)
          </td>
          <td>
            /
          </td>
          <td className="risk-calculation-tooltip-cell">
            {this.state.target / 100}
          </td>
          <td>
            )
          </td>
        </tr>
        <tr>
          <td />
          <td>
            =
          </td>
          <td className="risk-calculation-tooltip-cell">
            {getMinSampleSize(this.state.target)}
          </td>
        </tr>
      </table>;
    }
    return EMPTY_STRING;
  }

  getEstimatedSampleSizeWarning() {
    if (!isQualitativeMeasure(this.state.measure) &&
      this.state.hasBatchData &&
      UIUtils.parseInt(this.state.estimatedSampleSize) === 1 &&
      this.state.hasBatchWithMultipleMeasurements) {
      return "Batch/Lot data contains records with an Actual Sample Size > 1, however, your Estimated Sample Size is set to 1. " +
        "This changes the charts used in the Analytics Dashboard.";
    }
  }

  renderRiskControlSection(effectiveRMP, olderRMP) {
    const interactionName = "renderRiskControlSection-2";
    const isLoading = !effectiveRMP;
    measurePerformanceStart(interactionName);

    const section = <Section id="riskControl"
                             parent={this}
                             showDocLinks={true}
                             header={<span>Risk Control<InfoTooltip id="infoRiskControl"
                                                                    fdaGuidanceURL="https://www.fda.gov/media/167721/download"
                                                                    fdaGuidancePage={11}
                                                                    fdaGuidanceOffset={650}
                                                                    verbiage={<div>
                                                                      Risk control includes decision making to reduce and/or accept
                                                                      risks. The purpose of risk control is to reduce the risk to
                                                                      an acceptable level. The amount of effort
                                                                      used for risk control should be proportional to the
                                                                      significance of the risk. Decision makers might use different
                                                                      processes, including benefit-cost analysis, for
                                                                      understanding the optimal level of risk control.
                                                                    </div>}
                             /></span>}
    >
      <div className="row">
        <ComboBoxAttribute name="detectabilityRisk"
                           className="col-sm-4"
                           isNumber={true}
                           disabled={!(effectiveRMP && effectiveRMP.useDetectability)}
                           options={effectiveRMP && getRiskFieldOptions(effectiveRMP, "RMPToDetectabilityRiskLinkedVersions")}
                           olderOptions={olderRMP && getRiskFieldOptions(olderRMP, "RMPToDetectabilityRiskLinkedVersions")}
                           default={RiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.DETECTABILITY_RISK, effectiveRMP)}
                           getTooltipCallback={getRiskFieldTooltip.bind(this, RISK_TYPE_ENUM.DETECTABILITY_RISK, effectiveRMP)}
                           isLoading={isLoading}
                           parent={this}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           parentId={this.state.id}
        />
        <CalculatedRiskAttribute name="rPN"
                                 className="col-sm-4"
                                 displayName="RPN (Raw)"
                                 riskFactor1="detectabilityRisk"
                                 riskFactor2={getRawRiskScore.bind(this, RISK_TYPE_ENUM.PROCESS_RISK, effectiveRMP)}
                                 riskType={RISK_TYPE_ENUM.RPN}
                                 RMP={effectiveRMP}
                                 olderRMP={olderRMP}
                                 getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.RPN, effectiveRMP, this.state, false)}
                                 getSubscriptTooltipCallback={getCalculationFormulaTooltip.bind(this, RISK_TYPE_ENUM.RPN, effectiveRMP, this.state, true, false, null, this.isDetailedRiskLinks())}
                                 subscriptText="How is this calculated?"
                                 valueType="raw"
                                 isLoading={isLoading}
                                 parent={this}
                                 olderVersion={this.getOlderVersion()}
        />
        <CalculatedRiskAttribute name="rPNPercentage"
                                 className="col-sm-4"
                                 displayName="RPN (%)"
                                 riskFactor1="detectabilityRisk"
                                 riskFactor2={getRawRiskScore.bind(this, RISK_TYPE_ENUM.PROCESS_RISK, effectiveRMP)}
                                 riskType={RISK_TYPE_ENUM.RPN}
                                 RMP={effectiveRMP}
                                 olderRMP={olderRMP}
                                 getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.RPN, effectiveRMP, this.state, true)}
                                 getSubscriptTooltipCallback={getCalculationFormulaTooltip.bind(this, RISK_TYPE_ENUM.RPN, effectiveRMP, this.state, false, true, null, this.isDetailedRiskLinks())}
                                 subscriptText="How is this calculated?"
                                 valueType="percentage"
                                 isLoading={isLoading}
                                 parent={this}
                                 olderVersion={this.getOlderVersion()}
        />
        <TextAreaAttribute name="detectabilityJustification"
                           displayName="Detectability Justification"
                           className="col-sm-12"
                           tooltipText="Detectability Risk values should be justified by literature, data, or other science-based rationales."
                           parent={this}
        />
      </div>
      <div className="row">
        {this.renderControlStrategyField()}
      </div>
      <div className="row">
        <CheckboxAttribute name="ccp"
                           displayName="CCP"
                           tooltipText="Critical control point (CCP) is a step at which control can be applied and is essential to prevent or eliminate a pharmaceutical quality hazard or reduce it to an acceptable level. Checking this box indicates that this attribute or parameter is a critical control point in the manufacturing process."
                           tooltipGuidanceURL="https://www.gmp-compliance.org/files/guidemgr/WHO_TRS_908_Annex7.pdf"
                           parent={this}
        />
        <TextAreaAttribute name="controlStrategyJustification"
                           displayName="Control Strategy Justification"
                           className="col-sm-12"
                           tooltipText="Justification for frequency and methodology of testing derived from current product and process understanding."
                           parent={this}
        />
      </div>
    </Section>;
    measurePerformanceEnd(interactionName);

    return section;
  }

  renderControlStrategyField() {
    return EMPTY_STRING;
  }

  renderReferenceSection() {
    const interactionName = "renderReferenceSection";
    measurePerformanceStart(interactionName);

    const section = <Section id="references"
                             parent={this}
                             showDocLinks={true}
                             addOptions={[
                               {id: "references", label: "References"},
                               {id: "standards", label: "Standards"},
                               {id: "guidances", label: "Guidances"}]}
                             header={<span>References & Standards <InfoTooltip id="infoRefAndStandardsControl"
                                                                               verbiage={<div>
                                                                                 References, standards, safety data sheets, etc. for
                                                                                 highly characterized specimens of drug substances,
                                                                                 excipients, food ingredients, impurities,
                                                                                 degradation products,
                                                                                 dietary supplements, compendial reagents, tests,
                                                                                 assay, and performance calibrators. (e.g. USP, BP,
                                                                                 EP, JP, ISO, etc.)
                                                                               </div>}
                             /></span>}
    >
    </Section>;

    measurePerformanceEnd(interactionName);

    return section;
  }

  getRmpInfo() {
    const memoryCacheForRMP = MemoryCache.getNamedInstance("RMPInfo");

    let cacheKey = "instance_" + this.state.typeCode + "_" + this.state.id + "_" + this.state.versionId;
    if (this.state.currentDiffingVersion) {
      cacheKey = "instanceForDiff_" + this.state.typeCode + "_" + this.state.currentDiffingVersion.versionId;
    }

    const effectiveRMPCacheKey = cacheKey + "_effectiveRMP";
    if (!memoryCacheForRMP.get(effectiveRMPCacheKey)) {
      memoryCacheForRMP.set(effectiveRMPCacheKey, this.getEffectiveRMPByModelName(this.capitalizedBaseTypeName));
    }

    const olderRMPCacheKey = cacheKey + "_olderRMP";
    if (!memoryCacheForRMP.get(olderRMPCacheKey)) {
      memoryCacheForRMP.set(olderRMPCacheKey, this.getOlderRMP(this.capitalizedBaseTypeName));
    }

    let effectiveRMP = memoryCacheForRMP.get(effectiveRMPCacheKey);
    let olderRMP = memoryCacheForRMP.get(olderRMPCacheKey);

    return {effectiveRMP, olderRMP};
  }

  renderAttributes() {
    const interactionName = "renderAttributes";
    measurePerformanceStart(interactionName);

    const {effectiveRMP, olderRMP} = this.getRmpInfo();

    // riskTable is initialized part of renderCriticalityAssessmentSection, but we manually force the refresh by changing the state
    // TODO: optimize this process to first computing the riskTable and then rendering of the componennts
    const riskTableInstance = this.state.riskTableInstance;

    const section = <div>
      {this.renderLibrarySection()}
      {this.renderGeneralSection()}
      {this.renderCriticalityAssessmentSection(effectiveRMP, olderRMP, riskTableInstance)}
      {this.renderAcceptanceCriteriaSection()}
      {this.renderProcessCapabilitySection(effectiveRMP, olderRMP, riskTableInstance)}
      {this.renderRiskControlSection(effectiveRMP, olderRMP, riskTableInstance)}
      {this.renderTechTransferAssessmentSection()}
      {this.renderTagsSection()}
      {this.renderReferenceSection()}
    </div>;

    measurePerformanceEnd(interactionName);

    return section;
  }

  renderLibrarySection() {
    return EMPTY_STRING;
  }

  renderSidePanelWidget() {
    return (
      <>
        <RelatedBatchesPanel id="batchRelatedData"
                             header={`Data (${this.getRelatedBatches().length})`}
                             projectId={this.getProjectId()}
                             processId={this.getProcessId()}
                             showAdd={!this.state.hasLibraryMaterial}
                             parent={this}
                             parentId={this.state.id}
                             isLoading={this.state.isLoading}
                             records={this.getRelatedBatches()}
        />
      </>
    );
  }

  getRiskAndParentsInfo(option, typeCode, key) {
    const rmp = this.getEffectiveRMPByModelName(typeCode);
    if (!rmp) {
      return;
    }

    const memoryCache = this.getMemoryCacheForRiskInfo();

    const recordsKey = `${typeCode}_${this.getProjectId()}_${this.getProcessId()}`;
    let records = memoryCache.get(recordsKey);
    if (!records) {
      return;
    }

    const record = records.find(cachedRecord => cachedRecord.id === option[key]);
    if (!record) {
      return;
    }

    const result = {};
    if (typeCode === "GeneralAttribute") {
      return result;
    }

    if (!record.riskInfo) {
      throw new Error("Missing RiskInfo");
    }

    const riskScale = record.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel;
    if (riskScale) {
      result.riskCriticalityLabel = riskScale.riskLabel;
      result.riskCriticalityStyle = getCSSClassForLegend(riskScale.color);
    }

    // we don't wait for the cache to load, we expect it is already loaded when is viewed from process explorer
    // TO DO: refactor code to pre-load this information from componentDidUpdate
    const unitOperationsCacheKey = `UnitOperation_${this.getProjectId()}_${this.getProcessId()}`;
    let unitOperations = memoryCache.get(unitOperationsCacheKey);
    if (!unitOperations) {
      unitOperations = new TypeaheadObjectCache("UnitOperation", this.getProjectId(), this.getProcessId()).getOptionsFromCache();
      memoryCache.set(unitOperationsCacheKey, unitOperations);
    }

    const stepsCacheKey = `Step_${this.getProjectId()}_${this.getProcessId()}`;
    let steps = memoryCache.get(stepsCacheKey);
    if (!steps) {
      steps = new TypeaheadObjectCache("Step", this.getProjectId(), this.getProcessId()).getOptionsFromCache();
      memoryCache.set(stepsCacheKey, steps);
    }

    const materialCacheKey = `MaterialAttribute_${this.getProjectId()}_${this.getProcessId()}`;
    let materials = memoryCache.get(materialCacheKey);
    if (!materials) {
      materials = new TypeaheadObjectCache("Material", this.getProjectId(), this.getProcessId()).getOptionsFromCache();
      memoryCache.set(materialCacheKey, materials);
    }

    const processComponentCacheKey = `ProcessComponent_${this.getProjectId()}_${this.getProcessId()}`;
    let processComponents = memoryCache.get(processComponentCacheKey);
    if (!processComponents) {
      processComponents = new TypeaheadObjectCache("ProcessComponent", this.getProjectId(), this.getProcessId()).getOptionsFromCache();
      memoryCache.set(processComponentCacheKey, processComponents);
    }

    // load parents
    const parents = [];
    if (record.UnitOperationId) {
      const uo = unitOperations.find(item => item.id === record.UnitOperationId);
      if (uo) {
        parents.push(uo);
      }

      if (!record.StepId) {
        if (record.ProcessComponentId) {
          const prc = processComponents.find(item => item.id === record.ProcessComponentId);
          if (prc) {
            parents.push(prc);
          }
        }

        if (record.MaterialId) {
          const mt = materials.find(item => item.id === record.MaterialId);
          if (mt) {
            parents.push(mt);
          }
        }
      }
    }

    if (record.StepId) {
      const step = steps.find(item => item.id === record.StepId);
      if (step) {
        parents.push(step);
      }

      if (record.ProcessComponentId) {
        const prc = processComponents.find(item => item.id === record.ProcessComponentId);
        if (prc) {
          parents.push(prc);
        }
      }

      if (record.MaterialId) {
        const mt = materials.find(item => item.id === record.MaterialId);
        if (mt) {
          parents.push(mt);
        }
      }
    }

    result.parents = parents;
    return result;
  }

  async loadRiskLinksFromAllRecords() {
    let risks = [];
    const memoryCache = this.getMemoryCacheForRiskInfo();
    const typeCodes = ["MaterialAttribute", "ProcessParameter", "IQA", "IPA", "FQA", "FPA"];
    for (let i = 0; i < typeCodes.length; i++) {
      const typeCode = typeCodes[i];

      const cacheKey = `${typeCode}_${this.getProjectId()}_${this.getProcessId()}`;
      let records = memoryCache.get(cacheKey);
      if (!records) {
        const typeaheadObjectCache = TypeaheadObjectCacheFactory.createTypeaheadObjectCacheIfPossible(
          typeCode, this.getProjectId, this.getProcessId);

        if (!typeaheadObjectCache) {
          Logger.debug(() => `BaseRequirementAttributePage :: cannot load typeaheadObjectCache for ${typeCode}. Project ID: ${this.getProjectId()}. Process ID: ${this.getProcessId()}`);
          continue;
        }

        records = typeaheadObjectCache.getOptionsFromCacheIncludingArchived();
        if (BaseObjectCache.isObjectCacheStillLoading(records)) {
          await typeaheadObjectCache.loadOptions().promise();
          records = typeaheadObjectCache.getOptionsFromCacheIncludingArchived();
        }

        memoryCache.set(cacheKey, records);
      }

      if (!records) {
        continue;
      }

      for (let j = 0; j < records.length; j++) {
        const record = records[j];
        const keys = Object.keys(record).filter(key => key.indexOf(typeCode + "To") !== -1 && key.indexOf("LinkedVersions") === -1);
        keys.forEach(key => {
          if (record[key] && Array.isArray(record[key])) {
            risks = risks.concat(record[key]);
          }
        });
      }
    }

    this.setStateSafely({
      risksFromAllRecords: risks,
    });
  }

  getMemoryCacheForRiskInfo() {
    return MemoryCache.getNamedInstance(`base_requirements_attribute_page_getRiskAndParentsInfo_` + this.getProjectId() + "_" + this.getProcessId());
  }
}
