"use strict";

import React from "react";
import { FIELD_PROPS } from "../../configurableTables/fieldsConfig/constants/configurable_tables_field_props_config";
import { GridColumn } from "@progress/kendo-react-grid";
import {
  TEXT_COLUMN
} from "../../configurableTables/tables/configurable_tables_constants";
import ConfigurableTablesColumnGenerator
  from "../../configurableTables/tables/columnGenerator/configurable_tables_columns_generator";
import { UsedInProjectsCell } from "../../configurableTables/tables/cells/used_in_projects_cell";

class MaterialLibraryColumnGenerator extends ConfigurableTablesColumnGenerator {

  constructor(props) {
    super(props);
    const {columnOperationsAdapter} = this.props;
    this.getColumnOperationsProps = columnOperationsAdapter.getColumnOperationsProps;
  }

  generateUsedInProjectsColumn() {
    const prop = FIELD_PROPS.USED_IN_PROJECTS.toLowerCase();
    return (
      <GridColumn width={TEXT_COLUMN}
                  key={prop}
                  field={prop}
                  title="Used in Projects"
                  headerCell={(props) => this.columnHeaderCell(props)}
                  headerClassName={`column-header ${prop}`}
                  cell={props => this.createUsedInProjectsCell(props)}
      />
    );
  }

  createUsedInProjectsCell(props) {
    return (
      <UsedInProjectsCell {...props} prop={FIELD_PROPS.USED_IN_PROJECTS} cache={this.pillsCache} />
    );
  }
}

module.exports = {
  MaterialLibraryColumnGenerator,
};