"use strict";

import React from "react";

export function getProcessName(process, isReceivingSite, includeBrackets = false) {
  const processName = process && typeof process === "object" ?
    process.name : process || isReceivingSite ? "Receiving" : "Sending";
  return includeBrackets ? `(${processName})` : processName;
}

export function getValueWithTitle(label, prop) {
  return prop ?
    <div>
      <div className="two-lines-header">
        {label}:
      </div>
      {prop}
    </div> : "";
}

export function getClassName(columnProp, isReceivingSite = false) {
  return `${columnProp}_${isReceivingSite ? "receiving" : "sending"}`;
}

export function getFieldName(prop, isReceivingSite) {
  return isReceivingSite ? prop : `techTransferredFrom.${prop}`;
}
