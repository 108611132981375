"use strict";

import { CommonSorting } from "../../server/common/generic/common_sorting";
import * as UIUtils from "../ui_utils";

export const SortingFunctions = CommonSorting;

/**
 * Exposes the prefix of the cookie set when show removed is clicked
 * @type {string}
 */
export const SHOW_REMOVED = "SHOW_REMOVED_COOKIE";

/**
 * Creates a function that applies a filter based on a search term string
 * @param getSearchTermFunction {function(): string} A function that returns the search term string
 * @param filterSelector {function (*): string | *[]} The fields included in the filter (defaults to ["name"])
 * @param ignoredExpression {RegExp} A regular expression that contains a text pattern that will be
 *                                  ignored when applying the filter (by default, ignores the character '-')
 * @return {function(*=): boolean}
 */
export function createSearchTermFilter(getSearchTermFunction, filterSelector = (item) => item.name, ignoredExpression = /-/g) {
  return (item, index = 0, allItems = [], globalFiltersOnly = false) => {
    // if the current item is falsy or if we're evaluating only global filters (which don't include search term filter)
    // we just return true so all items are listed
    if (globalFiltersOnly || !item) {
      return true;
    }

    const searchTerm = getSearchTermFunction();

    // if the search term is empty or a white space string, returns true, so all items are displayed
    const isSearchTermEmptyOrWhiteSpace = !searchTerm || (typeof searchTerm === "string" && searchTerm.trim().length === 0);

    if (isSearchTermEmptyOrWhiteSpace) {
      return true;
    }

    // if filterSelector contains more than one property, performs the search in all specified properties (using or)
    // the default filter selector returns just the value of name property
    let values = filterSelector(item);

    // if the function returns a string, creates an array, so it can handle arrays and strings in the same way
    if (typeof values === "string") {
      values = [values];
    }

    let result = false;

    // attempts to match the search term to each property value
    for (let value of values) {
      if (value) {
        const lowerCasedSearchTerm = searchTerm.trim().toLowerCase();
        let baseValue = value.toString().toLowerCase();

        // first, tries to search for the value as is
        result = baseValue.indexOf(lowerCasedSearchTerm) !== -1;

        // second, if not matched yet,
        // verifies if there is an expression to be excluded from the search value and tries again
        // this can be used to allow IDs such as FQA-1 or SOP-001 to be searched as fqa1 or sop001
        if (!result && ignoredExpression) {
          result = baseValue.replace(ignoredExpression, "").indexOf(lowerCasedSearchTerm) !== -1;
        }
      }
      // no need to continue if the condition is true. This means we already have a match.
      if (result) {
        break;
      }
    }
    return !!result;
  };
}

/**
 * Determines whether the specified item is archived or not.
 * @param item {*} The item to verify.
 * @return {boolean}
 */
export function isItemArchived(item) {
  const lastVersion = (item && item.LastVersion) || item;
  return !!(lastVersion && lastVersion.deletedAt);
}

/**
 * If the parameter {@param filterFunction} is defined, returns it. Otherwise, returns a function that will
 * return true if the item IS archived and false if the item IS NOT archived.
 * @param filterFunction {function(*): boolean}
 * @return {*|(function(*=): boolean)}
 */
export function getArchivedFilterFunctionOrDefault(filterFunction = null) {
  return filterFunction || ((item) => !isItemArchived(item));
}

/**
 * Creates a function that applies a filter based on a search term string
 * @param getShowArchivedFunction {function(): boolean} A function that returns whether to show archived records
 * @param filterFunction {function(*): boolean}
 * @return {function(*=): boolean}
 */
export function createArchivedFilter(getShowArchivedFunction, filterFunction = null) {
  /**
   * @type {function(*): boolean}
   */
  const isNotArchived = getArchivedFilterFunctionOrDefault(filterFunction);

  return (item) => {
    const showArchived = getShowArchivedFunction();

    return isNotArchived(item) || showArchived;
  };
}

/**
 * Creates a filter function that combines one or more filter functions.
 * @param filters {function(*, ?boolean)} The filters to be combined.
 * @return {function(*, number, *[], ?boolean): boolean}
 */
export function combineFilters(...filters) {
  return (item, index, allItems, globalFiltersOnly = false) => {
    let result = true;

    for (let filter of filters) {
      // ignores null filters (this is useful to allow conditional filters
      if (filter) {
        result = result && filter(item, index, allItems, globalFiltersOnly);
      }
    }
    return result;
  };
}

/**
 * Returns the latest version from an object if available (i.e.: if the LastVersion property exists)
 * @param item {* | {?LastVersion: *}} T
 * @return {*}
 */
export function mapLatestVersionForFilter(item) {
  const modelName = item.modelName || UIUtils.getModelNameForTypeCode(item.typeCode);
  let idField = modelName ? `${UIUtils.convertToId(modelName)}Id` : null;

  return item.LastVersion ? {
    name: item.name,
    ...item.LastVersion,
    id: item[idField] || item.id,
    versionId: item.LastVersion.id,
    modelName: item.modelName,
    typeCode: item.typeCode,
    isFilteredLatestVersion: true,
  } : item;
}

/**
 * Returns the item as is if the latest version is not archived. If it is, returns the archived version.
 * @param item {* | {?LastVersion: *}} T
 * @return {*}
 */
export function defaultListingMap(item) {
  const lastVersion = item && item.LastVersion;
  // If the record is archived, returns the latest version so archived items don't show up in the listing
  return lastVersion && lastVersion.deletedAt
    ? mapLatestVersionForFilter(item)
    : item;
}

/**
 * Runs a filter object over an array and returns a filtered array.
 * @param items {*[]} The items to be filtered
 * @param filterObject {*} The Filter object used to filter the contents
 * @param globalFiltersOnly {boolean} A boolean indicating whether to consider only global filters
 * @param sortFunction {function(item1: *, item2: *): number} A sorting function to be applied on results (if null or not specified, does not sort the results).
 * @see SortingFunctions}
 * @return {*[]} An array with only the items that match the specified item
 */
export function filterItems(items, filterObject, globalFiltersOnly = false, sortFunction = null) {
  const result = items.filter((item, index) => filterObject.filter(item, index, items, globalFiltersOnly));

  if (typeof sortFunction === "function") {
    result.sort(sortFunction);
  }

  return result;
}

/**
 * Gets the filters defined by the user of a component and combines them with the default filters that provide
 * filtering for archived records and for the search term selected in the search box.
 * @param userDefinedFilters
 * @param state {*} The current state for the object
 * @return {(*|{filter: (function(*, number, *[], ?boolean): boolean)})[]}
 */
export function consolidateFilters(userDefinedFilters, state) {
  userDefinedFilters = userDefinedFilters || [];
  const defaultFilters = state.defaultFilters || [];

  // Merges default filters with user defined filters.
  // Overriding information from the default filter if it finds one with the same name

  const filterMap = new Map([
    ...(defaultFilters.map(filter => [filter.key, filter])),
  ]);

  for (let userDefinedFilter of userDefinedFilters) {
    const key = userDefinedFilter.key;

    if (filterMap.has(key)) {
      filterMap.set(key, {
        ...(filterMap.get(key)),
        ...userDefinedFilter,
      });
    } else {
      filterMap.set(userDefinedFilter.key, userDefinedFilter);
    }
  }

  let filters = [...filterMap.values()];
  filters.sort((filter1, filter2) => filter1.order - filter2.order);

  filters = filters.map(filterObject => {
    // if no filter specified, uses only the search term filter
    const archivedFilter = filterObject.disableShowArchived ? null : state.archivedFilterFunction;
    const searchTermFilter = state.searchTermFilterFunction;
    const originalFilter = typeof filterObject.filter === "function" ? filterObject.filter : null;

    return ({
      ...filterObject,
      filter: combineFilters(
        archivedFilter,
        searchTermFilter,
        originalFilter,
      ),
    });
  });
  return filters;
}
