"use strict";

import React from "react";
import ReactDOM from "react-dom";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import * as go from "gojs";
import * as UIUtils from "../../../ui_utils";
import ContextOptionsMenu from "../../widgets/context_options_menu";
import { getProjectFromCache } from "../../../utils/project_helper";
import OptionsMenuBuilder from "../../../editor/options_menu_builder";
import { ACTION_TO_ICON_ENUM } from "../../../helpers/constants/constants";
import TypeaheadObjectCache from "../../../utils/cache/typeahead_object_cache";
import BaseRecordButtonBuilder from "./base_record_button_builder";
import { TYPE_CODE } from "../../process_explorer_constants";

/**
 * A div with this ID will house the menu.
 */
export const PROCESS_EXPLORER_CONTEXT_MENU_ID = "processExplorerContextMenu";
/**
 * This is where the PROCESS_EXPLORER_CONTEXT_MENU_ID div will be rendered into.
 */
export const PROCESS_EXPLORER_CONTEXT_MENU_OUTER_DIV_ID = PROCESS_EXPLORER_CONTEXT_MENU_ID + "OuterDiv";

/*
  * This class is responsible for assembling all of the GoJS parts to provide the meatball button that shows the
  * context menu in the process explorer. See also OptionsMenuBuilder that builds the data for the menu that drops down.
  *
  * The basics of this were originally borrowed from: https://gojs.net/latest/extensions/Buttons.js
 */
export default class ContextButtonBuilder extends BaseRecordButtonBuilder {
  /**
   *                           on and the second arg is true for a left click and false for a right click.
   *                                      parameter is an entry in ACTION_TO_ICON_ENUM that tells you which option was
   *                                      clicked on.
   *                                      UIPermissions.generateTooltip())
   * @param goJSButtonName {string} The name to use when registering this button with GoJS.
   * @param diagramFacade {{}} The DiagramFacade we're rendering to.
   * @param diagram {{}} The GoJS diagram we're rendering to.
   * @param onClick {function} A function that is called on either left or right click. The first arg is the node clicked
   * @param t {function} For translation
   * @param projectId {int} The id of the project.
   * @param processId {int} The id of the process.
   * @param onContextMenuClick {function} A function that is called when the user clicks in the context menu. The first
   * @param onCheckPermissions {function} A function called when checking for permissions (see UIPermissions.can() and
   */
  constructor(goJSButtonName, diagramFacade, diagram, onClick, t, projectId, processId, onContextMenuClick, onCheckPermissions) {
    super(goJSButtonName, PROCESS_EXPLORER_CONTEXT_MENU_ID, PROCESS_EXPLORER_CONTEXT_MENU_OUTER_DIV_ID, diagramFacade, diagram, onClick, t, projectId, processId);
    this.onContextMenuClick = onContextMenuClick;
    this.onCheckPermissions = onCheckPermissions;
  }

  /**
   * This utility decides if the archive context option will be disabled for the selected node category.
   * @param node The selected node
   * @param processes The cache of processes in this project.
   * @returns {boolean} Whether archive should be disabled for this node.
   */
  isArchiveDisabled(node, processes) {
    let disableArchiveOption = false;
    if (node.category && node.category === TYPE_CODE.PROCESS) {
      disableArchiveOption = (processes && processes.length === 1);
    }
    return disableArchiveOption;
  }

  /**
   * This utility to decide if copy context option can be activated for the selected node category, by default all node categories can activate copy options.
   * @param node the selected node
   * @returns {boolean}
   */
  isCopyDisabled(node) {
    return node.deletedAt != null;
  }

  handleContextMenuClick(action, event) {
    this.annotateEvent(event);
    this.onContextMenuClick(action, event);
    this.diagram.currentTool.stopTool();
  }

  annotateEvent(event) {
    event.isParent = false;
    return event;
  }

  /**
   * See parent for definition.
   */
  getButtonGoJSProperties() {
    return {
      margin: new go.Margin(0, 0, 0, 5),
    };
  }

  /**
   * See parent for definition.
   */
  getIcon() {
    return faEllipsisV;
  }

  /**
   * See parent for definition.
   */
  getIconSize() {
    return {width: 5, height: 15};
  }

  /**
   * See parent for definition.
   */
  showMenu(obj, positionUnderButton) {
    this.renderMenu();
    let menuElement = document.getElementById("processExplorerContextMenu");
    menuElement.classList.add("d-block");
    menuElement.classList.remove("d-none");

    // Set the position to where the mouse was last seen
    const diagramCanvasOffset = $("#processExplorerContainerDiv").offset();
    if (positionUnderButton) {
      let lastPoint = this.diagram.lastInput.documentPoint;
      lastPoint.x = lastPoint.x - 7;
      lastPoint.y = lastPoint.y + 11;
      lastPoint = this.diagram.transformDocToView(lastPoint);
      menuElement.style.left = (diagramCanvasOffset.left + lastPoint.x) + "px";
      menuElement.style.top = this.getTopButNotOffScreen(diagramCanvasOffset.top + lastPoint.y, menuElement) + "px";
    } else {
      let mousePt = this.diagram.lastInput.viewPoint;
      menuElement.style.left = (diagramCanvasOffset.left + mousePt.x + 5) + "px";
      menuElement.style.top = this.getTopButNotOffScreen(diagramCanvasOffset.top + mousePt.y, menuElement) + "px";
    }
  }

  /**
   * See parent for definition.
   */
  renderMenu() {
    let thisProject = getProjectFromCache(this.projectId);
    const processes = new TypeaheadObjectCache("Process", this.projectId).getOptionsFromCache();
    let thisProcess = processes.find(process => process.id === this.processId);
    const selectedNodes = this.diagramFacade.getSelectedNodeData();
    const areMultipleRecordsSelected = selectedNodes && selectedNodes.length > 1;

    let contextMenuBuilder;
    let selectedNode;
    if (selectedNodes && selectedNodes.length > 0) {
      // Get the latest selectedNode since this.diagramFacade might be stale after a propose for Archive/Restore
      selectedNode = this.diagram.findNodeForKey(selectedNodes[0].key).data;
      if (selectedNode && selectedNode.staticPanelKey) {
        const securityTypeName = selectedNode && UIUtils.getModelNameForTypeCode(UIUtils.parseKey(selectedNode.staticPanelKey).typeCode);

        if (selectedNode) {
          contextMenuBuilder = new OptionsMenuBuilder(selectedNode, securityTypeName, thisProject, thisProcess, this.onCheckPermissions, this.t);
          contextMenuBuilder.addView({
            id: "processExplorerContextView",
            onClick: this.handleContextMenuClick.bind(this, ACTION_TO_ICON_ENUM.VIEW.title),
          });
          contextMenuBuilder.addEdit({
            id: "processExplorerContextEdit",
            onClick: this.handleContextMenuClick.bind(this, ACTION_TO_ICON_ENUM.EDIT.title),
          });
          if (selectedNode.category === "UO" || selectedNode.category === "STP") {
            contextMenuBuilder.addPropose({
              id: "processExplorerContextPropose",
              onClick: this.handleContextMenuClick.bind(this, ACTION_TO_ICON_ENUM.PROPOSE.title),
            });
          }
          contextMenuBuilder.addArchive({
            id: "processExplorerContextArchive",
            onClick: this.handleContextMenuClick.bind(this, ACTION_TO_ICON_ENUM.ARCHIVE.title),
            disabled: (areMultipleRecordsSelected || this.isArchiveDisabled(selectedNode, processes)),
            tooltipIfDisabled: "Only 1 record can be archived at a time.",
          });
          contextMenuBuilder.addRestore({
            id: "processExplorerContextRestore",
            onClick: this.handleContextMenuClick.bind(this, ACTION_TO_ICON_ENUM.RESTORE.title),
            disabled: areMultipleRecordsSelected,
            tooltipIfDisabled: "Only 1 record can be restored at a time.",
          });
          contextMenuBuilder.addSeparator();
          contextMenuBuilder.addCopy({
            id: "processExplorerContextCopy",
            onClick: this.handleContextMenuClick.bind(this, ACTION_TO_ICON_ENUM.COPY.title),
            disabled: (areMultipleRecordsSelected || this.isCopyDisabled(selectedNode)),
            tooltipIfDisabled: "Only 1 record can be copied at a time.",
            tooltipIfAccessAllowed: "Copy",
          });
        }
      }
    }

    // Make sure there's a place to render the menu.
    let elementToRenderIn = this.ensureElementToRenderInExists();

    // Render the menu.
    const optionsMenu = <ContextOptionsMenu id={this.menuDivId}
                                            options={contextMenuBuilder ? contextMenuBuilder.build() : []}
    />;
    ReactDOM.render(optionsMenu, elementToRenderIn);
  }
}


