"use strict";

import { showBullet } from "../../export/configurable_tables_export_value_generator";
import React, { memo, useState } from "react";
import { Link } from "./widgets/link";
import * as commonStyles from "./style/common_cell.module.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as styles from "./style/links_cell.module.scss";
import { getLinkAttributes } from "./cells_helper";

const MoreLink = ({showTooltip, text, onMouseEnter, onMouseLeave, renderTooltip}) => (
  <OverlayTrigger
    show={showTooltip}
    placement="bottom-start"
    trigger={["hover", "focus"]}
    delay={{hide: 300}}
    overlay={
      renderTooltip()
    }
  >
    <Link text={text}
          className={styles["show-more"]}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
    />
  </OverlayTrigger>
);

const TooltipContent = ({links, bullet}) => (
  <>
    {links.map((link, idx) => (
      <Link key={idx} {...getLinkAttributes(link, bullet)} />
    ))}
  </>
);

const renderLinks = (links, bullet) => (
  links.map((link, idx) => (
    <Link
      key={idx}
      {...getLinkAttributes(link, bullet)}
    />
  ))
);

export const LinksCell = memo(({className, links}) => {

  const [
    showTooltip,
    setShowTooltip
  ] = useState(false);

  const [
    first,
    ...others
  ] = links;

  const bullet = showBullet(links);

  const renderTooltip = () => (
    <Tooltip id={`tooltip`}
             className={`${commonStyles["field-tooltip"]} ${styles["tooltip-container"]}`}
             onMouseEnter={() => setShowTooltip(true)}
             onMouseLeave={() => setShowTooltip(false)}
    >
      <TooltipContent links={others} bullet={bullet} />
    </Tooltip>
  );

  return (
    <td className={`${className} ${commonStyles["cell-container"]}`}>
      {links.length <= 2 ? renderLinks(links, bullet) : (
        <>
          <Link
            {...getLinkAttributes(first, bullet)}
          />
          <MoreLink showTooltip={showTooltip}
                    text={`${showBullet(links)}${others.length} more`}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    renderTooltip={renderTooltip}

          />
        </>
      )}
    </td>
  );
});