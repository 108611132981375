"use strict";

import React from "react";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import BaseReactComponent from "../base_react_component";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import * as UIUtils from "../ui_utils";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.System, "DatePicker");

/**
 * This class is responsible for setting a few sensible defaults on the react-datepicker object.
 */
// i18next-extract-mark-ns-start widgets
class DatePicker extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  getInstance() {
    return this.datePicker;
  }

  handleDateChange(parsedDate, event) {
    Logger.verbose(() => "handleDateChange called with: " + parsedDate + " event: " + (event ? event.target.value : undefined));
    // Don't allow dates through if the year isn't there. Otherwise when the tests run trying to type in bogus dates, partial dates will match and create mayhem.
    let allowDateToBeSet;
    const inputDate = event && event.target.value ? event.target.value : null;
    if (inputDate) {
      allowDateToBeSet = /... \d{1,2}, \d{4}/.test(inputDate) && moment(parsedDate).format(UIUtils.DATE_FORMAT_FOR_DISPLAY) === inputDate;
    } else {
      allowDateToBeSet = true;
    }

    if (allowDateToBeSet) {
      this.props.onChange(parsedDate, event);
    }
  }

  render() {
    return (
      <ReactDatePicker
        {...this.props}
        onChange={this.handleDateChange}
        ref={datePicker => this.datePicker = datePicker}
        locale={this.props.i18n.language}
      />
    );
  }
}

DatePicker.defaultProps = {
  className: "form-control",
  required: false,
  autoComplete: "off",
  showYearDropdown: true,
  scrollableYearDropdown: true,
  yearDropdownItemNumber: 3,
  dateFormat: UIUtils.DATE_FORMAT_FOR_DISPLAY_DATEPICKER,
};

export default I18NWrapper.wrap(DatePicker, "widgets");
// i18next-extract-mark-ns-stop widgets
