"use strict";

import * as UIUtils from "../../ui_utils";
import Cookies from "js-cookie";
import { EXPERIMENTS } from "../../helpers/constants/constants";
import BaseEditorPage from "../../editor/base_editor_page";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import * as AjaxWrapper from "../../utils/ajax_wrapper";
import UserConditionChecker from "./user_condition_checker";
import UserRecordRenderer from "./user_record_renderer";
import Section from "../../editor/widgets/section";
import TextAttribute from "../../editor/attributes/text_attribute";
import React, { Fragment } from "react";
import CheckboxAttribute from "../../editor/attributes/checkbox_attribute";
import ComboBoxAttribute from "../../editor/attributes/combo_box_attribute";
import TextAreaAttribute from "../../editor/attributes/text_area_attribute";
import LinksAttribute from "../../editor/attributes/links_attribute";
import LabelTooltip from "../../widgets/tooltips/label_tooltip";
import ExperimentsAttribute from "../../editor/attributes/experiments_attribute";
import CommonUtils from "../../../server/common/generic/common_utils";
import CommonSecurity from "../../../server/common/generic/common_security";
import CommonUsers from "../../../server/common/editables/common_users";
import { Ensure } from "../../../server/common/generic/common_ensure";
import ResetMFAPopup from "./reset_mfa_popup";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Editables, "BaseUserRecord");

export class BaseUserRecord extends BaseEditorPage {

  constructor(props) {
    super(props, "user");
  }

  componentDidMount() {
    super.componentDidMount();

    this.userChanged();

    const projectCache = new TypeaheadObjectCache("Project");
    let invalidateCachePromise = Promise.resolve(true);
    if (this.isAdd() || this.isEdit()) {
      invalidateCachePromise = projectCache.invalidateCacheOptionsAsync();
    }

    invalidateCachePromise.then(() => {
      projectCache.loadOptions();
    });

    const userCache = new TypeaheadObjectCache("User");
    userCache.invalidateCacheOptionsAsync().then(() => {
      userCache.loadOptions();
    });
  }

  userChanged() {
    UIUtils.clearError();

    const id = this.context.getParameterByName("id");
    this.setStateSafely({
      enabled: true,
      enableEmailNotifications: true,
      showHiddenExperiments: false,
      role: CommonSecurity.Roles.STANDARD_USER,
      language: "en",
      companyLicense: UIUtils.getCompanyLicense(),
      showIdentityProvider: true,
      id,
    });

    this.useTwoWayCommunication = false;
    this.redirectOnSave = true;
    this.userConditionChecker = new UserConditionChecker({});
    this.userRecordRenderer = new UserRecordRenderer({});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    super.componentDidUpdate();
    if (snapshot.userChanged) {
      this.userChanged();
      this.initialize();
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    return {userChanged: prevProps.locationSearch !== this.props.locationSearch};
  }

  onDataReceivedFromServer() {
    let {
      identityProviders,
      identityProvider,
      role,
      Projects,
      isMasterAccount,
      hasUniqueEmailInCompany,
      username,
      email,
      Company,
    } = this.state;

    const stateObject = {};

    if (identityProviders && !Company?.forceIdpAuthentication) {
      identityProviders.unshift("default");
      identityProviders = identityProviders
        .map(provider => CommonUtils.stripAllWhitespaces(provider))
        .filter(provider => provider !== "");

      if (identityProviders.length === 1 && identityProviders[0] === "default") {
        stateObject.showIdentityProvider = false;
      }
    }

    stateObject.Projects = role === CommonSecurity.Roles.ADMINISTRATOR ? null : Projects;
    stateObject.identityProviders = identityProviders;
    stateObject.identityProvider = identityProvider || "default";
    stateObject.username = isMasterAccount && !hasUniqueEmailInCompany ? email : username;

    const userName = this.state.firstName + " " + this.state.lastName;
    this.setStateSafely({
      ...stateObject,
      breadcrumb: {
        current: userName,
        parent: this.getBreadcrumbParentName(),
        parentLink: this.getBreadcrumbParentLink(),
      },
    });
  }

  /**
   * @return The name that should appear in the breadcrumb in the header before this record's name.
   * @abstract
   */
  getBreadcrumbParentName() {
    Ensure.notImplemented("getBreadcrumbParentName");
  }

  /**
   * @return The name that should appear when this record type (ex. "user") is referenced in a sentence.
   * @abstract
   */
  getRecordTypeName() {
    Ensure.notImplemented("getRecordTypeName");
  }

  /**
   * @return The name that should appear when this record type (ex. "user") is referenced in a title (ex. "New User")
   * @abstract
   */
  getCapitalizedRecordTypeName() {
    Ensure.notImplemented("getCapitalizedRecordTypeName");
  }

  getBreadcrumbParentLink() {
    return "/users/list.html";
  }

  beforeDataSavedToServer(callback) {
    Logger.debug(() => `Saving state: ${this.state}`);

    // hireDate can't be an empty string
    const {role, hireDate, supervisorId, identityProvider} = this.state;
    let stateObject = {
      hireDate: hireDate || null,
      identityProvider: identityProvider || null,
      SupervisorId: supervisorId,
    };

    this.setStateSafely(role === CommonSecurity.Roles.ADMINISTRATOR ? {
      Projects: null,
      ...stateObject,
    } : stateObject, () => {
      super.beforeDataSavedToServer(callback);
    });
  }

  /**
   * Overwrites the parent class "save" method as it needs to take special care of Cognito exceptions.
   * This method handles the case where the provided username for a new user already exists or the provided
   * email address already exists by displaying a proper error or informational message to the user.
   */
  async attemptSave() {
    Logger.debug(() => `Validating and then saving: ${JSON.stringify(this.state, null, 2)}`);

    try {
      this.validateClientInstance();
      Logger.debug("URL Prefix = " + this.getURLPrefix());
      UIUtils.setHideLoadingOnAjaxStop(false); // We're going to redirect anyways once the typeahead is reloaded.
      UIUtils.secureAjaxPUT(this.getURLPrefix() + "/addOrEdit", this.state, true, (result) => {
        try {
          let error = result.responseJSON;
          if (error.code === "UsernameExistsException") {
            if (this.state.username) {
              UIUtils.showError("This username is already registered. Please use a different one for this user.");
            } else {
              UIUtils.showInfo("This email address is already registered. If you want to create a new user with the same email address, please specify a username below which the user should use for signing in.");
            }
            this.setStateSafely({
              usernameRequired: true,
              showIdentityProvider: false,
            });
            UIUtils.setHideLoadingOnAjaxStop(true);
          } else {
            UIUtils.defaultFailFunction(result);
            UIUtils.setHideLoadingOnAjaxStop(true);
          }
        } catch (error) {
          UIUtils.defaultFailFunction(result);
          UIUtils.setHideLoadingOnAjaxStop(true);
        }
      }).done((result) => {
        // Update the experiments right away after save.
        if (this.state.id && UIUtils.getUserId() && (this.state.id === UIUtils.getUserId())) {
          Cookies.set("EXPERIMENTS", this.state.experiments);
        }

        const isTranslationExperimentEnabled = UIUtils.isExperimentEnabled(EXPERIMENTS.Translation);
        if (isTranslationExperimentEnabled) {
          Cookies.set("language", this.state.language);
        } else {
          Cookies.remove("language");
        }

        const cache = new TypeaheadObjectCache("User");
        cache.invalidateCacheOptionsAsync().then(() => {
          cache.loadOptions(() => {
            this.handleSaveResults(result);
          });
        });
      });
    } catch (error) {
      if (this.isView()) {
        error.message = error.message + "\n" + "Press \"Edit\" to fill in the missing information.";
      }

      UIUtils.clearError();
      UIUtils.showError(error && error.message ? error.message : error);
      UIUtils.setLoadingDisabled(true);
    }
  }

  renderPageTitleBar() {
    return this.userRecordRenderer.renderPageTitleBar();
  }

  hideResetMfaModal() {
    if (this.resetMfaPopup) {
      $(this.resetMfaPopup).modal("hide");
    }

    this.setStateSafely({
      shouldShowResetMfaPopup: false,
    });
  }

  async resetMfaForUser() {
    const {username} = this.state;
    UIUtils.setLoadingDisabled(false);
    UIUtils.showLoadingImage();
    this.hideResetMfaModal();
    try {
      await UIUtils.secureAjaxPUT(`users/modifyMfa`, {resetTarget: username});
    } catch (e) {
      UIUtils.defaultFailFunction(e);
      UIUtils.setHideLoadingOnAjaxStop(true);
    }
    UIUtils.setLoadingDisabled(true);
  }

  handleUserResetPassword() {
    AjaxWrapper.secureAjaxPUT(`users/addOrEdit?resendUserInvitation=true&id=${this.state.id}`, {})
      .done(() => {
        this.setStateSafely({
          expired: false
        }, () => {
          UIUtils.showSuccess(`A new invitation including a temporary password was sent to user 
          ${this.state.firstName} ${this.state.lastName} and the expiration period was reset.`);
        });
      });
  }

  handleUserResetSigningPin() {
    AjaxWrapper.secureAjaxPUT(`users/addOrEdit?resendUserInvitation=true&id=${this.state.id}`, {})
      .done(() => {
        this.setStateSafely({}, () => {
          UIUtils.showSuccess(`Please check your email for a link to reset your pin.`);
        });
      });
  }

  handleEnablePartyMode() {
    this.setStateSafely({showHiddenExperiments: !this.state.showHiddenExperiments});
  }

  createEditButton() {
    return this.userRecordRenderer.createEditButton();
  }

  createSaveCancelButtons(isTop) {
    return this.userRecordRenderer.createSaveCancelButtons(isTop);
  }

  updateDependencies() {
    let {id, companyId, loggedInUser} = this.state;

    this.userConditionChecker = new UserConditionChecker({
      isEdit: this.isEdit(),
      isView: this.isView(),
      id,
      companyId,
      loggedInUser,
    });

    this.userRecordRenderer = new UserRecordRenderer({
      isAdd: this.isAdd(),
      parent: this,
      userConditionChecker: this.userConditionChecker,
    });
  }

  filterOutSelf(item) {
    return parseInt(item.id) !== parseInt(this.state.id);
  }

  renderAttributes() {

    const {
      role,
      companyLicense, license, Projects,
      userProjectsFilteredOutDueToPermissions, shouldShowResetMfaPopup
    } = this.state;

    const isInternalUserEditingOtherCompaniesUsers = this.userConditionChecker.isInternalUserEditingOtherCompaniesUsers();
    const isLicenseFieldEnabled = this.userConditionChecker.isInternalAdminUserInEditMode();
    const userLicense = this.isAdd() ? CommonUsers.getUserLicense(companyLicense) : license;

    return (
      <div>
        {this.renderHeaderWarning()}
        <Section parent={this}
                 header={this.getGeneralHeader()}
                 collapsible={false}
        >
          {this.renderGeneralSection()}
        </Section>
        {this.renderContactSection(isInternalUserEditingOtherCompaniesUsers)}
        {this.renderAttachmentsSection()}
        <Section id="security"
                 header="Access & Permissions"
        >
          {isInternalUserEditingOtherCompaniesUsers ?
            <ComboBoxAttribute name="license"
                               className="col-sm-6"
                               options={Object.values(CommonSecurity.UserLicenseTypes)}
                               disabled={!isLicenseFieldEnabled}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            /> :
            <Fragment>
              <div className="row">
                <ComboBoxAttribute name="department"
                                   className="col-sm-6"
                                   options={Object.values(CommonSecurity.Departments)}
                                   tooltipText={this.userRecordRenderer.getDepartmentTooltip()}
                                   disabled={!this.userConditionChecker.isDepartmentFieldEnabled()}
                                   parent={this}
                                   isLoading={this.state.isLoading}
                                   parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                   parentId={this.state.id}
                />
                {this.userRecordRenderer.renderRoleField()}
              </div>
              <div className="row">
                {this.isAdd() ?
                  <div className="col-sm-6">
                    <label id="licenseInputLabel"
                           className="col-form-label base-attribute"
                    >
                      <b>License:</b>
                    </label>
                    <div id="licenseInputDiv">
                      <span id="licenseInput">
                        <b>{userLicense}</b>
                      </span>
                    </div>
                  </div> :
                  <ComboBoxAttribute name="license"
                                     className="col-sm-6"
                                     options={Object.values(CommonSecurity.UserLicenseTypes)}
                                     disabled={!isLicenseFieldEnabled}
                                     parent={this}
                                     isLoading={this.state.isLoading}
                                     parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                     parentId={this.state.id}
                  />}
                {this.userRecordRenderer.renderEnabledField()}
              </div>
              {
                (role && role !== CommonSecurity.Roles.ADMINISTRATOR
                  && (!Projects || (Projects && Projects.length === 0))) ? (
                  <div className="row">
                    <div id="userProjectsWarning"
                         className="alert alert-warning col-6"
                    >
                      No projects have been specified for this {this.getRecordTypeName()}.
                    </div>
                  </div>
                ) : ""
              }
              {
                (this.isView() && role && role !== CommonSecurity.Roles.ADMINISTRATOR &&
                  userProjectsFilteredOutDueToPermissions) ? (
                  <div className="row">
                    <div id="userNotAllProjectsDisplayedWarning"
                         className="alert alert-warning"
                    >
                      This user may have access to additional projects for which you don&apos;t have permission.
                    </div>
                  </div>
                ) : ""
              }
              {role && role !== CommonSecurity.Roles.ADMINISTRATOR ? (
                <Fragment>
                  <div className="row">
                    <LinksAttribute name="Project"
                                    tooltipText={
                                      `Use this to control project visibility for this ${this.getRecordTypeName()}. This is effective unless the role is Administrator, since that grants access to all projects by default.`
                                    }
                                    displayName="Access to Projects"
                                    className="col-sm-12"
                                    disabled={!this.userConditionChecker.isProjectsTypeaheadEnabled()}
                                    filter={(project) => {
                                      return project.deletedAt === null;
                                    }}
                                    parent={this}
                                    parentId={this.state.id}
                                    isLoading={this.state.isLoading}
                    />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="row">
                    <div className="attribute-container col-sm-12">
                      <LabelTooltip id={"ProjectLabel"}
                                    tooltipText={
                                      `Use this to control project visibility for this ${this.getRecordTypeName()}. This is effective unless the role is Administrator, since that grants access to all projects by default.`
                                    }
                                    text="Access to Projects"
                                    className={"col-form-label base-attribute" + (this.isView() ? "" : " col-form-label-add-or-edit")}
                                    parent={this}
                      />
                      <div>
                      <span id="administratorsProjectAccessMessage"
                            className="user-projects-admin-access-notification"
                      >
                        Administrators are granted by default access to all projects.
                      </span>
                      </div>
                    </div>
                  </div>
                </Fragment>
              )
              }
              <div className="row">
                <CheckboxAttribute name="isTrainingCoordinator"
                                   displayName="Training Coordinator"
                                   isLoading={this.state.isLoading}
                                   parent={this}
                                   disabled={!this.userConditionChecker.isTrainingCoordinatorEnabled()}
                                   tooltipText={"Training Coordinator allows you to create, edit, approve, and archive Training related records."}
                />
              </div>
            </Fragment>}
        </Section>
        {this.renderExperimentalSection()}
        {shouldShowResetMfaPopup &&
          <ResetMFAPopup modalRef={resetMfaPopup => this.resetMfaPopup = resetMfaPopup}
                         onHideModal={this.hideResetMfaModal}
                         onMfaReset={this.resetMfaForUser}
                         id="ResetMfaPopup"
          />
        }
      </div>
    );
  }

  renderHeaderWarning() {
    return "";
  }

  /**
   * @return The DOM for the top general section where the user enters the basic info like name.
   * @abstract
   */
  renderGeneralSection() {
    Ensure.notImplemented("renderGeneralSection");
  }

  renderContactSection(isInternalUserEditingOtherCompaniesUsers) {
    const {usernameRequired} = this.state;
    return (
      <Section
        id="contact"
        header="Contact"
      >
        <div className="row">
          <TextAttribute name="email"
                         parent={this}
                         isLoading={this.state.isLoading}
                         required={true}
                         disabled={!!usernameRequired}
                         className="col-sm-4"
          />
          {isInternalUserEditingOtherCompaniesUsers ? "" :
            <TextAttribute name="phone" isLoading={this.state.isLoading} parent={this} className="col-sm-4" />}
        </div>
        {isInternalUserEditingOtherCompaniesUsers ? "" :
          <div className="row">
            <TextAreaAttribute name="address" isLoading={this.state.isLoading} parent={this} className="col-sm-12" />
          </div>}
      </Section>
    );
  }

  renderAttachmentsSection() {
    return (<Section id="attachments"
                     parent={this}
                     showDocLinks={true}
                     isLoading={this.state.isLoading}
                     addOptions={[
                       {id: "certificate", label: "Certificates"},
                       {id: "jobDescription", label: "Job Description"},
                       {id: "resume", label: "Resume/CV"},
                       {id: "trainingQuiz", label: "Training Quizzes"},
                     ]}
                     header="Attachments & Links"
    />);
  }

  renderExperimentalSection(isAPI = false) {
    const {showHiddenExperiments} = this.state;

    return CommonUtils.areExperimentsEnabled() ? (
      <Section id="experimental" header="Experimental">
        <div className="row">
          <div className="alert alert-warning col">Watch out! These features are experimental and should be used
            with
            caution<a onClick={this.handleEnablePartyMode}>.</a>
          </div>
        </div>
        <ExperimentsAttribute name="experiments" parent={this} showHiddenExperiments={showHiddenExperiments} isAPI={isAPI} />
      </Section>
    ) : "";
  }

  render() {
    this.updateDependencies();
    return super.render();
  }
}
