"use strict";

import React from "react";
import BaseReactComponent from "../../base_react_component";
import loadingImg from "../../images/loading.gif";

export class LoadingIndicator extends BaseReactComponent {
  render() {
    return (
      <div className="loading-indicator">
        <img height={128} width={128}
             src={loadingImg}
             alt="Loading..."
        />
      </div>
    );
  }
}
