"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BasePageTitleBar from "./base_page_title_bar";

/* This shows the title bar for all common records for a given project */
export default class CommonEditablesPageTitleBar extends BasePageTitleBar {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
    if (!shouldUpdate) {
      shouldUpdate = nextProps.recordName !== this.props.recordName ||
                     nextProps.name !== this.props.name ||
                     nextProps.recordId !== this.props.recordId ||
                     nextProps.record !== this.props.record ||
                     nextProps.subTitle !== this.props.subTitle;
    }

    return shouldUpdate;
  }

  getPrimaryName() {
    const {name, recordName, recordId, typeCode, record} = this.props;
    let primaryName;

    // if the record is passed in directly, tries to extract the information from there
    // and overrides it with the values defined on specific properties
    // the idea here is that if you want, you can just pass in the editable instance instead of getting
    // its properties manually and then passing them in
    if (record) {
      const displayRecord = {
        ...record,
        typeCode,
        id: recordId || record.id,
        name: recordName || record.name,
      };

      // this method uses the CustomID if available
      primaryName = UIUtils.getRecordCustomLabelForDisplay(displayRecord, {defaultName: name});
    } else {
      // otherwise, keeps the existing logic as it was before
      primaryName = recordName ? recordName : name;

      if (recordName && recordId) {
        primaryName = UIUtils.getRecordLabelForDisplay(typeCode, recordId, recordName);
      }
    }
    return primaryName;
  }

  renderTitleBar() {
    const {name, recordName, backLink, subTitle} = this.props;
    let primaryName = this.getPrimaryName();

    return (
      <div className="page-title-bar-title"
           id="pageTitleBar">
        <span className="page-title-bar-breadcrumbs">
          {recordName ? (
            <a href={backLink || `/${name.toLowerCase()}/list.html`}
               id={`${name.toLowerCase()}LinkInTitleBar`}>&larr;</a>
          ) : ""}
        </span>
        <span className="page-title-bar-primary"
              id="pageTitleBarPrimary">
              {primaryName}
        </span>
        {subTitle ? <small id="pageTitleBarSubTitle"> {subTitle}</small> : null}
      </div>
    );
  }
}
