import * as React from 'react';
import { createId, ZERO_LEVEL_ZERO_ITEM_ID } from './itemsIdsUtils';
import { MenuItem } from '../components/MenuItem';
/**
 * @hidden
 */
export function prepareInputItemsForInternalWork(models, menuChildren) {
    if (models && models.length > 0) {
        return {
            items: convertInputModelsToInternalOnesHelper(models),
            inputItems: models
        };
    }
    else if (getMenuItemChildComponents(menuChildren).length > 0) {
        var inputModels = convertInputComponentsToInputModelsHelper(getMenuItemChildComponents(menuChildren));
        return { items: convertInputModelsToInternalOnesHelper(inputModels), inputItems: inputModels };
    }
    else {
        return { items: [], inputItems: [] };
    }
}
function copyInputItemWithoutChildren(inputModel, inputComponent) {
    var result = {};
    var _a = inputModel ? inputModel : inputComponent.props, text = _a.text, url = _a.url, icon = _a.icon, disabled = _a.disabled, cssClass = _a.cssClass, cssStyle = _a.cssStyle, render = _a.render, linkRender = _a.linkRender, contentRender = _a.contentRender, data = _a.data;
    if (text !== undefined) {
        result.text = text;
    }
    if (url !== undefined) {
        result.url = url;
    }
    if (icon !== undefined) {
        result.icon = icon;
    }
    if (disabled !== undefined) {
        result.disabled = disabled;
    }
    if (cssClass !== undefined) {
        result.cssClass = cssClass;
    }
    if (cssStyle !== undefined) {
        result.cssStyle = cssStyle;
    }
    if (render !== undefined) {
        result.render = render;
    }
    if (linkRender !== undefined) {
        result.linkRender = linkRender;
    }
    if (contentRender !== undefined) {
        result.contentRender = contentRender;
    }
    if (data !== undefined) {
        result.data = data;
    }
    return result;
}
function getMenuItemChildComponents(menuChildren) {
    return React.Children.toArray(menuChildren)
        .filter(function (child) { return child && child.type === MenuItem; });
}
function convertInputComponentsToInputModelsHelper(components) {
    var result = [];
    for (var index = 0; index < components.length; index++) {
        var comp = components[index];
        var model = copyInputItemWithoutChildren(undefined, comp);
        var items = convertInputComponentsToInputModelsHelper(getMenuItemChildComponents(comp.props.children));
        if (items.length > 0) {
            model.items = items;
        }
        result.push(model);
    }
    return result;
}
function convertInputModelsToInternalOnesHelper(inputModels, parentId) {
    var result = [];
    for (var index = 0; index < inputModels.length; index++) {
        var inputModel = inputModels[index];
        var internalModel = copyInputItemWithoutChildren(inputModel);
        internalModel.id = createId(index.toString(), parentId);
        internalModel.isLastFromSiblings = index === inputModels.length - 1;
        internalModel.items = copyOrCreateModelChildren(inputModel, internalModel);
        result.push(internalModel);
    }
    return result;
}
function copyOrCreateModelChildren(inputModel, internalModel) {
    if (inputModel.contentRender) {
        return [{
                contentParentItemId: internalModel.id,
                id: createId(ZERO_LEVEL_ZERO_ITEM_ID, internalModel.id),
                isLastFromSiblings: true,
                contentRender: inputModel.contentRender,
                items: []
            }];
    }
    else if (inputModel.items) {
        return convertInputModelsToInternalOnesHelper(inputModel.items, internalModel.id);
    }
    else {
        return [];
    }
}
