"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import ReactDOM from "react-dom";
import { getURLByTypeCodeAndId } from "../../../helpers/url_helper";
import { createColumn, generateProps } from "../../helpers/import_helper";
import ReportURLGenerator from "../../../reports/report_url_builder";
import { REPORT_OPTIONS_ENUM, REPORT_TYPES_ENUM } from "../../../reports/constants/report_constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { DATATABLES_DOM } from "../../../widgets/tables/base_table";
import { MODEL_DECLARATIONS } from "../../../../server/common/generic/common_models";
import BaseReactComponent from "../../../base_react_component";

const excludeColumns = [
  "id",
  "key",
  "projectid",
  "materialid",
  "unitoperationid",
  "processcomponentid",
  "attributeid",
  "measurements",
  "previousunitid",
  "isnewuo",
  "currentstate",
  "isquantitativemeasure",
  "batchrecordid",
];

let renamedColumns = new Map();
renamedColumns.set("Defective Percentage", "Defective %");

export default class ImportResultSummaryTable extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  generateColumns() {
    const {modelName, records, dependency, dataSectionName} = this.props;

    if ((dependency === "Attribute" || modelName === "Attribute") &&
      !excludeColumns.includes("attributename")) {
      excludeColumns.push("attributename");
    }

    if (modelName === "Specification") {
      renamedColumns.set("Attribute Name", "Specification");
      renamedColumns.set("Batch/Lot ID", "Lot ID");
    }

    let columns = [];
    if (records && records.length > 0) {
      for (let prop of generateProps(records)) {
        const {name, isMeasurementProp} = prop;

        if (!excludeColumns.includes(name.toLowerCase()) &&
          !name.toLowerCase().includes("links")) {
          const column = createColumn(name, renamedColumns, isMeasurementProp);
          columns.push(column);
        }
      }

      if (dataSectionName === "Process Capability") {
        columns.push({
          title: "",
          width: 20,
          data: (result) => {
            return result.attributeID;
          },
          type: "string",
        });
      }
    }
    return columns;
  }

  componentDidMount() {
    super.componentDidMount();

    const {modelName} = this.props;
    this.initializeTable();

    if (modelName === "Attribute") {
      excludeColumns.push("attributename");
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    $(this.tableRef)
      .DataTable()
      .destroy(true);
  }

  initializeTable() {
    const columns = this.generateColumns();
    const columnDefs = this.getColumnDefs(columns);

    if (this.table) {
      $(this.tableRef)
        .DataTable()
        .destroy();
    }

    this.table = $(this.tableRef).DataTable({
      dom: DATATABLES_DOM,
      scrollX: true,
      data: this.props.records,
      iDisplayLength: 100,
      ordering: false,
      columns: columns,
      stateSave: true,
      columnDefs: columnDefs
    });
    setTimeout(() => {
      this.reloadDataTable();
    }, 50);
  }

  /**
   * Builds the column definitions according to the settings defined in the props, ensuring that all values will be rendered using react
   * @param columns {*[]} The array of columns
   * @returns {{targets: *, createdCell: (function(*=, *=): *)}[]}
   */
  getColumnDefs(columns) {
    const {
      disableNameColumnLink,
      dataSectionName,
      modelName,
    } = this.props;

    let targets = columns.map((ignore, index) => index);
    targets = disableNameColumnLink ? targets : targets.slice(1);
    targets = (dataSectionName === "Process Capability") ? targets.slice(1, targets.length - 1) : targets;

    const columnDefs = [{
      targets: targets,
      createdCell: (td, cellData) => {
        ReactDOM.render(cellData, td);
      }
    }];

    if (!disableNameColumnLink) {
      columnDefs.push({
        targets: 0,
        createdCell: (td, cellData, rowData) => {
          const keyMatch = rowData.attributeID && rowData.attributeID.match(/^(.*?)-(\d+)/);
          const typeCode = dataSectionName && keyMatch
            ? keyMatch[1]
            : UIUtils.getTypeCodeForModelName(modelName);
          const recordId = dataSectionName && keyMatch
            ? keyMatch[2]
            : rowData.id;

          ReactDOM.render(
            (
              typeCode !== MODEL_DECLARATIONS.SPECIFICATION.typeCode ?
                <a href={getURLByTypeCodeAndId(typeCode, "View", recordId, false)}
                   rel="noreferrer noopener"
                   target="_blank"
                >{cellData}</a> : <>{cellData}</>
            ), td);
        }
      });
    } else {
      columnDefs.push({
        targets: 0,
        createdCell: (td, cellData, rowData) => {
          const batchId = rowData.batchRecordId;
          const typeCode = UIUtils.getTypeCodeForModelName("Batch");
          ReactDOM.render(
            (
              typeCode !== MODEL_DECLARATIONS.SPECIFICATION.typeCode ?
                <a href={getURLByTypeCodeAndId(typeCode, "View", batchId, false)}
                   rel="noreferrer noopener"
                   target="_blank"
                >
                  {cellData}
                </a> : <>{cellData}</>
            ), td);
        }
      });
    }

    if (dataSectionName === "Process Capability") {
      columnDefs.push({
        targets: columns.length - 1,
        createdCell: (td, cellData, rowData, rowIndex) => {
          ReactDOM.render(
            (
              <FontAwesomeIcon id={"processCapabilityContinuesReportLink_" + rowIndex}
                               icon={faChartLine}
                               className="import-result-pc-link-icon"
                               onClick={this.handleProcessCapabilityReportLinkClick.bind(this, cellData, rowData)}
                               aria-label="Click to view the process capability continuous report for the attribute."
              />
            ), td);
        }
      });
    }

    return columnDefs;
  }

  handleProcessCapabilityReportLinkClick(attributeID, rowData) {
    let key = UIUtils.parseKey(attributeID);
    let reportOptions, reportType;

    const isLibrary = key.typeCode === MODEL_DECLARATIONS.SPECIFICATION.typeCode;
    if (rowData.isQuantitativeMeasure) {
      reportOptions = isLibrary ? REPORT_OPTIONS_ENUM.LibraryControlChartsContinuousReport : REPORT_OPTIONS_ENUM.ControlChartsContinuousReport;
      reportType = isLibrary ? REPORT_TYPES_ENUM.LibraryControlChartsContinuousReport : REPORT_TYPES_ENUM.ControlChartsContinuousReport;
    } else {
      reportOptions = isLibrary ? REPORT_OPTIONS_ENUM.LibraryControlChartsDefectivesReport : REPORT_OPTIONS_ENUM.ControlChartsDefectivesReport;
      reportType = isLibrary ? REPORT_TYPES_ENUM.LibraryControlChartsDefectivesReport : REPORT_TYPES_ENUM.ControlChartsDefectivesReport;
    }

    const {projectId, processId} = this.props;
    let urlParams = {
      reportType: reportType,
      projectId: projectId,
      processId: processId,
      modelLabel: `${key.typeCode}-${key.id}`,
    };

    if (projectId) {
      urlParams.projectId = projectId;
    }

    if (processId) {
      urlParams.processId = processId;
    }

    window.open(ReportURLGenerator.generateURL(reportOptions, urlParams));
  }

  componentDidUpdate() {
    if (this.props.allowDynamicColumnsCreation) {
      this.initializeTable();
    } else {
      this.reloadDataTable();
    }
  }

  reloadDataTable() {
    if (this.tableRef) {
      const table = $(this.tableRef).DataTable();
      table.clear();
      table.rows.add(this.props.records);
      table.draw();
    }
  }

  render() {
    return (
      <div>
        <table ref={ref => this.tableRef = ref}
               className="table table-bordered table-hover results-table"
               id="importResultsSummaryTable" style={{width: "100%"}}
        />
      </div>
    );
  }
}
