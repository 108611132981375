import * as React from "react";
import {Button} from "@progress/kendo-react-buttons";
import {ToolProps} from "../common/types";
import pageOrientationIcon from "../../images/documentEditor/page-orientation-icon.svg";

export const PAGE_ORIENTATION = {
  PORTRAIT:"Portrait",
  LANDSCAPE:"Landscape"
};

export const getCurrentPageOrientation = () => {
  const node = document.querySelector(".col-12-landscape");
  return node ? PAGE_ORIENTATION.LANDSCAPE : PAGE_ORIENTATION.PORTRAIT;
};

export let pageOrientation = getCurrentPageOrientation();

export default function PageOrientationTool(props: ToolProps) {
  const {view} = props;

  const handleButtonClick = () => {
    if (!view || !view.state) {
      return 0;
    }

    let documentNode : any = view.dom.parentNode.parentNode.parentNode.parentNode;
    pageOrientation = getCurrentPageOrientation();
    if (pageOrientation === PAGE_ORIENTATION.PORTRAIT) {
      pageOrientation = PAGE_ORIENTATION.LANDSCAPE;
      documentNode.classList.remove("col-12");
      documentNode.classList.add("col-12-landscape");
    } else {
      pageOrientation = PAGE_ORIENTATION.PORTRAIT;
      documentNode.classList.remove("col-12-landscape");
      documentNode.classList.add("col-12");

    }
  };

  return (
    <Button
      id="pageOrientationTool"
      onClick={handleButtonClick}
      title="Page Orientation"
      imageUrl={pageOrientationIcon}
    />
  );
}