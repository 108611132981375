/* stylelint-disable-next-line import-notation */
@import "../../../../../../css/brand_colors";

.container {
  display: flex;
  gap: 16px;
  height: 58px;
  min-height: 58px;
  z-index: 100;
  background: $brand-white;
  align-items: center;
  margin: 0 2%;

  .rows-count-zone {
    display: inline-flex;
    align-items: center;
    margin-left: auto;

    :global(span) {
      color: $brand-dark-grey;
      text-wrap: nowrap;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .filters-zone {
    display: inline-flex;
    width: 100%;
    align-items: center;
    gap: 18px;

    .vertical-separator {
      height: 30px;
      border-left: 1.5px solid #C0C8CC;
    }

    :global(.category-filter-outer-div) {
      margin-left: unset;
    }
  }
}

.configure-zone {
  display: inline-flex;
  align-items: center;
  gap: 12px;
}

.selection-container {
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.selection-count {
  color: $brand-black;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.bulk-actions-area {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
}

.clear-selection {
  color: $brand-primary-color;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.configure-widget {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  padding: 8px;
  user-select: none;
  height: 40px;

  :global(.fa-sliders) {
    margin: unset;
    color: #859399;
  }

  :global(span) {
    color: $brand-dark-grey;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &.widget-open {
    border-radius: 4px;
    background-color: #C0ECFF;

    :global(.fa-sliders) {
      color: $brand-primary-color;
    }

    :global(span) {
      color: $brand-primary-color;
    }
  }

}

.side-panel {
  display: flex;
  z-index: 3000;
  position: fixed;
  right: 0;
  bottom: 0;
  width: 400px;
  height: calc(100vh - 147px);
  justify-content: center;
  gap: 194px;
  flex-shrink: 0;
  background: $brand-white;
  /* stylelint-disable-next-line color-function-notation */
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.20), 0 3px 1px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14); /* stylelint-disable-line alpha-value-notation */
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  &.show {
    transform: translateX(0);
  }
}
