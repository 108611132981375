"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseLinkedEntitiesAttribute from "../../editor/attributes/base_linked_entities_attribute";
import { RISK_ATTRIBUTES_TABS_TO_RISK_TYPES, RISK_COLORS } from "../constants/rmp_constants";
import { CUSTOM_ERROR_TYPE } from "../../editor/attributes/base_json_attribute";
import { getRMPForModel } from "../rmp_helper";
import * as RiskHelper from "../../helpers/risk_helper";
import * as CommonUtils from "../../../server/common/generic/common_utils";
import RiskUtils from "../../../server/common/misc/common_risk_utils";

export const RMP_RISK_ATTRIBUTE_TYPE = {
  CRITICALITY: "criticality scale",
  PROCESS_RISK: "process risk",
  RPN: "RPN",
};

const Decimal = require("decimal.js");

/**
 * This is class is responsible for handling Risk Management Plan risk scale attributes.
 */
export default class RMPRiskScaleAttribute extends BaseLinkedEntitiesAttribute {
  constructor(props, widgetFields) {
    super(props, widgetFields);

    // Bind functions to this
    this.convertRiskScalesToDecimals = this.convertRiskScalesToDecimals.bind(this);
    this.getRiskColors = this.getRiskColors.bind(this);
    this.getRecordDescription = this.getRecordDescription.bind(this);
    this.validateFromToFields = this.validateFromToFields.bind(this);
    this.getFromFieldDefaultValue = this.getFromFieldDefaultValue.bind(this);
    this.getOverlappingScoreRange = this.getOverlappingScoreRange.bind(this);
    this.getMinRiskPercentageValue = this.getMinRiskPercentageValue.bind(this);

    this.loaded = false;
  }

  isColorDisabled(rowData) {
    return this.isNotAssessedRow(rowData);
  }

  isScaleDisabled(rowData) {
    return this.isNotAssessedRow(rowData);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const ruleBasedCriticalityChanged = this.props?.rmp?.useRulesBasedCriticality !== nextProps.props?.rmp?.useRulesBasedCriticality;

    if (ruleBasedCriticalityChanged) {
      return true;
    }

    if (((nextProps.configureByType && !nextProps.modelName) ||
      (!nextProps.configureByType && nextProps.modelName))) {
      return false;
    } else if (!nextProps.isParentTabActive && nextProps.formValidationMode !== CommonUtils.FORM_VALIDATION_MODE.PROPOSE) {
      return false;
    }

    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      let rmp = UIUtils.deepClone(this.props.rmp);
      let nextRmp = UIUtils.deepClone(nextProps.rmp);

      let modelRMP = getRMPForModel(rmp, this.props.modelName, nextProps.parent);
      modelRMP.boundaries = RiskHelper.getRiskBoundaries(modelRMP);
      let nextModelRMP = getRMPForModel(nextRmp, nextProps.modelName, nextProps.parent);
      nextModelRMP.boundaries = RiskHelper.getRiskBoundaries(nextModelRMP);
      let riskScales = RiskHelper.getRiskScales(RISK_ATTRIBUTES_TABS_TO_RISK_TYPES[nextProps.riskType], modelRMP) || [];
      let nextRiskScales = RiskHelper.getRiskScales(RISK_ATTRIBUTES_TABS_TO_RISK_TYPES[nextProps.riskType], nextModelRMP) || [];

      let rowRiskScales = riskScales.map(riskScale => `${riskScale.from}-${riskScale.to}`).join(",");
      let nextRowRiskScales = nextRiskScales.map(riskScale => `${riskScale.from}-${riskScale.to}`).join(",");

      return !this.loaded ||
        nextProps[this.minSourceParameter1] !== this.props[this.minSourceParameter1] ||
        nextProps[this.maxSourceParameter1] !== this.props[this.maxSourceParameter1] ||
        nextProps[this.minSourceParameter2] !== this.props[this.minSourceParameter2] ||
        nextProps[this.maxSourceParameter2] !== this.props[this.maxSourceParameter2] ||
        nextProps.isParentTabActive !== this.props.isParentTabActive ||
        nextProps.configureByType !== this.props.configureByType ||
        nextProps.useUncertainty !== this.props.useUncertainty ||
        nextProps.useDetectability !== this.props.useDetectability ||
        nextProps.useRulesBasedCriticality !== this.props.useRulesBasedCriticality ||
        nextProps.usePotential !== this.props.usePotential ||
        rowRiskScales !== nextRowRiskScales;
    }
    return true;
  }

  componentDidUpdate(prevProps) {
    if (((this.props.configureByType && !this.props.modelName) ||
      (!this.props.configureByType && this.props.modelName))) {
      return false;
    } else if (!this.props.isParentTabActive && this.props.formValidationMode !== CommonUtils.FORM_VALIDATION_MODE.PROPOSE) {
      return false;
    }

    super.componentDidUpdate(prevProps);
    if (!this.loaded) {
      this.props.onRowsUpdated(this.getValueAsObject(), this.type);
      this.loaded = true;
    } else if (this.props.useUncertainty !== prevProps.useUncertainty ||
      this.props.useDetectability !== prevProps.useDetectability) {
      this.props.onRowsUpdated(this.getValueAsObject(), this.type);
    }
  }

  // noinspection JSMethodCanBeStatic
  getRecordDescription(record) {
    if (record.scoreLabel) {
      return "\"" + record.scoreLabel + "\"";
    } else {
      return "\"(" + record.from + " - " + record.to + ")\"";
    }
  }

  getOverlappingScoreRange(scale1, scale2) {
    let scale1Min = parseFloat(scale1.from);
    let scale1Max = parseFloat(scale1.to);
    let scale2Min = parseFloat(scale2.from);
    let scale2Max = parseFloat(scale2.to);

    if ((scale1Min < scale2Max) && (scale1Max > scale2Min)) {
      return {
        start: scale1Min < scale2Min ? this.convertToDecimalString(scale2Min) : this.convertToDecimalString(scale1Min),
        end: scale1Max < scale2Max ? this.convertToDecimalString(scale1Max) : this.convertToDecimalString(scale2Max),
      };
    }

    return null;
  }

  // noinspection JSMethodCanBeStatic
  convertToDecimalString(value) {
    value = value ? Number(value) : value;
    return Number.isInteger(value) ? value.toFixed(1) : value;
  }

  getFromFieldDefaultValue() {
    let allRows = this.getValueAsObject();
    let defaultValue;

    if (this.editedRows.length === 0 && allRows.length === 0) {
      defaultValue = this.getMinRiskPercentageValue();
    } else {
      let sortedRiskScoreRanges = allRows.slice(0).sort(
        UIUtils.sortBy({
          name: "from",
          primer: parseFloat,
        }, {
          name: "to",
          primer: parseFloat,
        }));

      let uncoveredRiskScales = this.getUncoveredRiskScales();
      defaultValue = uncoveredRiskScales[0] ? uncoveredRiskScales[0].from : sortedRiskScoreRanges[0].to;
    }

    return this.convertToDecimalString(defaultValue);
  }

  getValueAsObject(linkObjectFilter) {
    return this.convertRiskScalesToDecimals(super.getValueAsObject(linkObjectFilter));
  }

  getOldValue(attributeName) {
    return this.convertRiskScalesToDecimals(super.getOldValue(attributeName));
  }

  // noinspection JSMethodCanBeStatic
  convertRiskScalesToDecimals(rows) {
    if (rows) {
      for (let row of rows) {
        row.from = this.convertToDecimalString(row.from);
        row.to = this.convertToDecimalString(row.to);
      }
    }
    return rows;
  }

  handleSave(rowData, rows, setValueCallback) {
    let editedRow = this.getEditedRowByUUID(rowData.uuid);
    rows = rows ? rows : this.getValueAsObject();

    if (editedRow.critical) {
      rows.filter(row => parseFloat(row.from) >= parseFloat(editedRow.to)).map(row => {
        row.critical = true;
      });

      this.editedRows.filter(row => parseFloat(row.from) >= parseFloat(editedRow.to)).map(row => {
        row.critical = true;
      });
    }

    editedRow.critical = parseFloat(editedRow.to) === 100 ? true : editedRow.critical;
    rowData.modelName = this.props.modelName;
    super.handleSave(rowData, rows, setValueCallback);
  }

  getCriticalDefaultValue(field, rowData) {
    let rows = this.getValueAsObject();
    return !!rows.find(row => parseFloat(rowData.from) >= parseFloat(row.to) && row.critical);
  }

  isCriticalDisabled(rowData) {
    if (this.isNotAssessedRow(rowData)) {
      return true;
    }

    return false;
  }

  handleAddToList(editedRow, e) {
    editedRow = editedRow ? editedRow : {modelName: this.props.modelName};
    editedRow.uuid = this.generateUUID(editedRow);
    super.handleAddToList(editedRow, e);
  }

  getMinComputedRiskPercentageValue() {
    let min;
    let maxScoreLength;
    let boundaries = this.props.parent.state;
    let {modelName} = this.props;

    let maxImpact = boundaries[`${modelName}maxImpact`];
    let maxUncertainty = boundaries[`${modelName}maxUncertainty`];
    let maxCapabilityRisk = boundaries[`${modelName}maxCapabilityRisk`];
    let maxDetectabilityRisk = boundaries[`${modelName}maxDetectabilityRisk`];

    if (this.type === RMP_RISK_ATTRIBUTE_TYPE.CRITICALITY) {
      if (maxImpact === Number.MIN_SAFE_INTEGER ||
        maxUncertainty === Number.MIN_SAFE_INTEGER) {
        return 1;
      }

      maxScoreLength = CommonUtils.getMaxTextLengthOfNumbers(maxImpact, maxUncertainty);
      min = Decimal.div(1, Decimal.mul(maxImpact, maxUncertainty));
    } else if (this.type === RMP_RISK_ATTRIBUTE_TYPE.PROCESS_RISK) {
      if (maxImpact === Number.MIN_SAFE_INTEGER ||
        maxUncertainty === Number.MIN_SAFE_INTEGER ||
        maxCapabilityRisk === Number.MIN_SAFE_INTEGER) {
        return 1;
      }

      let maxCriticalityScore = Decimal.mul(maxImpact, maxUncertainty);
      maxScoreLength = CommonUtils.getMaxTextLengthOfNumbers(maxImpact, maxUncertainty, maxCapabilityRisk);
      min = Decimal.div(1, Decimal.mul(maxCriticalityScore, maxCapabilityRisk));
    } else if (this.type === RMP_RISK_ATTRIBUTE_TYPE.RPN) {
      if (maxImpact === Number.MIN_SAFE_INTEGER ||
        maxUncertainty === Number.MIN_SAFE_INTEGER ||
        maxCapabilityRisk === Number.MIN_SAFE_INTEGER ||
        maxDetectabilityRisk === Number.MIN_SAFE_INTEGER) {
        return 1;
      }

      let maxProcessRiskScore = Decimal.mul(Decimal.mul(maxImpact, maxUncertainty), maxCapabilityRisk);
      maxScoreLength = CommonUtils.getMaxTextLengthOfNumbers(maxImpact, maxUncertainty, maxCapabilityRisk, maxDetectabilityRisk);
      min = Decimal.div(1, Decimal.mul(maxProcessRiskScore, maxDetectabilityRisk));
    }
    return Decimal.mul(CommonUtils.fixToMostSignificantDigit(min.toNumber(), maxScoreLength), 100).toNumber();
  }

  /**
   * This method will provide the minimum allowed value for an entry in a risk scale attribute row to be 0,
   * so we won't confuse/force the user to input a computed value as minimum(as done in getMinComputedRiskPercentageValue),
   * since having intervals such as ranging from 0.89% to 100% won't be intuitive to the common user.
   */
  getMinRiskPercentageValue() {
    return 0;
  }

  getRiskColors() {
    return Object.keys(RISK_COLORS).map(color => {
      return <option key={color} value={RISK_COLORS[color]}>
        {RISK_COLORS[color]}
      </option>;
    });
  }

  /**
   * Overwrite this method in child classes to control how a table is reloaded provided a collection of rows
   * @param rowData
   */
  reloadDataTable(rowData) {
    rowData.forEach(row => {
      row.fromOriginal = UIUtils.extactNumberFromReactElement(row.from);
      row.toOriginal = UIUtils.extactNumberFromReactElement(row.to);
    });

    rowData = rowData.sort(CommonUtils.sortBy("fromOriginal", "toOriginal"));

    super.reloadDataTable(rowData);

    if (this.tableRef) {
      const table = $(this.tableRef).DataTable();
      try {
        // uncomment this when debugging
        // console.warn(">> [RMPRiskScaleAttribute] Adjusting data table widths: ", this.tableRef, table);
        table.columns.adjust();
      } catch (error) {
        // if an error happens while adjusting column widths, we can ignore it
        console.error("[RMPRiskScaleAttribute] Error adjusting column widths", error, this.tableRef, table);
      }
    }
  }

  /**
   * Overwrite this in child classes to customize the rendering of a particular cell.
   * This method will cover the field types returned by BaseJsonAttribute.getSupportedFieldTypes()
   * Overwrite getFieldInput to further customize the input controls used in table cell inline editors
   * @param field The field to customize the column cell for
   * @param rowData The row data object
   */
  getColumnDef(field, rowData) {
    let columnDef = super.getColumnDef(field, rowData);

    if (field.fieldName === "color" && !this.isRowInEditMode(rowData.uuid) && !this.isDiffingVersions()) {
      const id = this.props.name + "_" + CommonUtils.capitalize(field.fieldName) + "_" + rowData.index;
      let fieldValue = field.getValue(rowData);

      columnDef = (
        <div className="links-manage">
          <div id={id}
               className={"rmp-criticality-color rmp-criticality-color-" + fieldValue.toLowerCase()}
          />
        </div>
      );
    }

    return columnDef;
  }

  /**
   * Updates the state of the parent object with the value that need to be passed to the backed for persisting it into the database.
   * You can overwrite this in child classes to support different structures other than JSON for more complex scenarios.
   * @param rows
   */
  setValue(rows) {
    super.setValue(rows);
    this.props.onRowsUpdated(rows, this.type);
  }

  /**
   * Updates the UI with a custom error
   * @param error The error to show on the UI
   * @param errorType The error type based on if it was raised due to a save or propose action.
   */
  setError(error, errorType = CUSTOM_ERROR_TYPE.FOR_SAVE) {
    let errorStr = "";

    if (Array.isArray(error)) {
      errorStr = error.length > 1 ? error.map(e => e.includes("•") ? e : `• ${e}`) : error.map(e => e.replace("•", "").trim());
    } else {
      errorStr = error.replace("•", "").trim();
    }

    super.setError(errorStr, errorType);
    this.props.parent.setError(this.props.modelName + this.tabContainerType.title + "TabError", errorStr);
  }

  clearErrorText(forceUpdate) {
    super.clearErrorText(forceUpdate);
    this.props.parent.clearError(this.props.modelName + this.tabContainerType.title + "TabError");
  }

  /**
   * Validates the data this specific attribute holds against proposing. For each field in each row that is not
   * good for proposing, it renders an error on the UI.
   * @param formValidationMode Checks if validation is running against save or propose
   * @returns {boolean}
   */
  validate(formValidationMode) {
    if (formValidationMode === CommonUtils.FORM_VALIDATION_MODE.SAVE) {
      return this.validateEditedRows();
    }

    let isValid = super.validate(formValidationMode);
    if (isValid) {
      let errors = [];
      let allRows = this.getValueAsObject();
      const hasNotAssessed = allRows.find(row => this.isNotAssessedRow(row));

      if (allRows.length < 2 && !hasNotAssessed) {
        errors.push(`At least 2 ${CommonUtils.capitalize(this.type)} records need to be specified.`);
      }

      if (this.props[this.minSourceParameter1] === Number.MAX_SAFE_INTEGER &&
        this.sourceParameter1 === "Detectability Risk" && this.props.useDetectability && !hasNotAssessed) {
        errors.push(`No ${this.sourceParameter1} rows have been specified.`);
      } else if (this.props[this.minSourceParameter1] === Number.MAX_SAFE_INTEGER &&
        this.sourceParameter1 !== "Detectability Risk" && !hasNotAssessed) {
        errors.push(`No ${this.sourceParameter1} rows have been specified.`);
      }

      if (this.props[this.minSourceParameter2] === Number.MAX_SAFE_INTEGER &&
        this.sourceParameter2 === "Uncertainty" && this.props.useUncertainty && !hasNotAssessed) {
        errors.push(`No ${this.sourceParameter2} rows have been specified.`);
      } else if (this.props[this.minSourceParameter2] === Number.MAX_SAFE_INTEGER &&
        this.sourceParameter2 !== "Uncertainty" && !hasNotAssessed) {
        errors.push(`No ${this.sourceParameter2} rows have been specified.`);
      }

      if (this.props[this.minSourceParameter1] !== Number.MAX_SAFE_INTEGER && this.props[this.minSourceParameter2] !== Number.MAX_SAFE_INTEGER) {
        let minComputedScale = this.getMinComputedRiskPercentageValue();
        let maxTotalScale = 100;
        //Verify that for all risk scales from is less than to and at least the from is above the computed minimum
        for (let row of allRows) {
          if (!(parseFloat(row.from) === RiskUtils.NOT_ASSESSED_SCALE && parseFloat(row.to) === RiskUtils.NOT_ASSESSED_SCALE)) {
            //If both from and to are lower than the minimum add a warning
            if (row.from < minComputedScale && row.to < minComputedScale) {
              errors.push(`The ${this.type} percentage is invalid. The lowest possible value is ${minComputedScale}.`);
            }
            if (row.from < 0) {
              errors.push(`A from ${this.type} percentage of ${row.from} is defined for ${this.getRecordDescription(row)}, but the lowest possible ${this.type} percentage is 0`);
            }
            if (row.to > maxTotalScale) {
              errors.push(`A to ${this.type} percentage of ${row.to} is defined for ${this.getRecordDescription(row)}, but the highest possible ${this.type} percentage is ${this.convertToDecimalString(maxTotalScale)}`);
            }
          }
        }

        //Verify that risk scales are not overlapping
        let verifiedCriticalityScales = allRows.slice(0);
        for (let i = 0; i <= allRows.length - 1; i++) {
          let index = verifiedCriticalityScales.indexOf(allRows[i]);
          verifiedCriticalityScales.splice(index, 1);

          for (let j = 0; j <= verifiedCriticalityScales.length - 1; j++) {
            let overlappingRange = this.getOverlappingScoreRange(allRows[i], verifiedCriticalityScales[j]);
            if (overlappingRange) {
              errors.push(`The ${this.type} percentages ${this.getRecordDescription(allRows[i])} and ${this.getRecordDescription(verifiedCriticalityScales[j])} overlap in values from ${overlappingRange.start} to ${overlappingRange.end}`);
            }
          }
        }

        //Verify that all risk percentages have been covered
        let uncoveredRiskScales = this.getUncoveredRiskScales(minComputedScale, maxTotalScale);
        if (uncoveredRiskScales.length > 0) {
          let uncoveredScoreRangesErrors = [];
          uncoveredScoreRangesErrors.push(`The percentage ranges below are not covered by any ${this.type} row: `);

          for (let uncoveredRiskScale of uncoveredRiskScales) {
            uncoveredScoreRangesErrors.push(`    • From ${uncoveredRiskScale.from} to ${uncoveredRiskScale.to}`);
          }

          if (uncoveredScoreRangesErrors.length > 1) {
            errors = errors.concat(uncoveredScoreRangesErrors);
          }
        }
      }

      if (errors.length > 0) {
        this.setError(errors);
        this.forceUpdateSafely();
        isValid = false;
      }
    }

    return isValid;
  }

  getUncoveredRiskScales(minTotalScore = this.getMinComputedRiskPercentageValue(), maxTotalScore = 100) {
    let allRows = this.getValueAsObject();

    let sortedRiskScoreRanges = allRows.slice(0).sort(
      UIUtils.sortBy({
        name: "from",
        primer: parseFloat,
      }, {
        name: "to",
        primer: parseFloat,
      }));

    let uncoveredRiskScales = [];

    if (sortedRiskScoreRanges && sortedRiskScoreRanges.length > 0) {
      if (sortedRiskScoreRanges[0].from > minTotalScore) {
        uncoveredRiskScales.push({
          from: minTotalScore,
          to: sortedRiskScoreRanges[0].from,
        });
      }

      for (let i = 0; i < sortedRiskScoreRanges.length - 1; i++) {
        if (parseFloat(sortedRiskScoreRanges[i].from) > minTotalScore || parseFloat(sortedRiskScoreRanges[i].to) > minTotalScore) {
          if (parseFloat(sortedRiskScoreRanges[i + 1].from) > parseFloat(sortedRiskScoreRanges[i].to)) {
            uncoveredRiskScales.push({
              from: sortedRiskScoreRanges[i].to,
              to: sortedRiskScoreRanges[i + 1].from
            });
          }
        }
      }

      if (parseFloat(sortedRiskScoreRanges[sortedRiskScoreRanges.length - 1].to) < parseFloat(maxTotalScore)) {
        uncoveredRiskScales.push({
          from: sortedRiskScoreRanges[sortedRiskScoreRanges.length - 1].to,
          to: maxTotalScore,
        });
      }
    }

    return this.convertRiskScalesToDecimals(uncoveredRiskScales.sort(
      UIUtils.sortBy({
        name: "from",
        primer: parseFloat,
      }, {
        name: "to",
        primer: parseFloat,
      })));
  }

  validateFromToFields(field, editedRow) {
    let index = editedRow.index;
    let minInputId = "#" + this.props.name + "_From_" + index;
    let maxInputId = "#" + this.props.name + "_To_" + index;
    let minValue = parseFloat($(minInputId).val());
    let maxValue = parseFloat($(maxInputId).val());

    if (minValue >= maxValue && !this.isNotAssessedRow(editedRow)) {
      $(minInputId)[0].setCustomValidity("From should be < To");
      $(maxInputId)[0].setCustomValidity("To should be > From");
      return false;
    }
    return true;
  }

  isNotAssessedRow(row) {
    return RiskUtils.scaleIsNotAssessed(row);
  }

  isDeleteButtonDisabled(rowData) {
    return this.isNotAssessedRow(rowData);
  }

  getDeleteButtonDisabledReason(rowData) {
    if (this.isNotAssessedRow(rowData)) {
      return "It is not possible to delete the row because this RMP has been marked to use the 'Not Assessed Score'.";
    }
  }
}