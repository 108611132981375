"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import TextAttribute from "../../editor/attributes/text_attribute";
import ComboBoxAttribute from "../../editor/attributes/combo_box_attribute";
import TextAreaAttribute from "../../editor/attributes/text_area_attribute";
import BaseAttributePage from "../../editor/base_attribute_page";
import { EDITOR_TYPES } from "../../editor/editor_constants";
import ReferenceDocuments from "@cherrycircle/fda-docs";
import TypeaheadAttribute from "../../editor/attributes/typeahead_attribute";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import Section from "../../editor/widgets/section";
import MultiDocumentsRiskLinksAttribute from "../../editor/attributes/multi_documents_risk_links_attribute";

/**
 * This is the class that renders the Add/Edit IQA editor in the process explorer.
 */
// i18next-extract-mark-ns-start process_explorer
class IQA extends BaseAttributePage {
  constructor(props) {
    super(props, "iqa", "IQA", "Intermediate Quality Attribute (IQA)");

    // Bind stuff
    this.getHTMLURLPrefix = IQA.getHTMLURLPrefix;
  }

  componentDidMount() {
    document.title = "QbDVision Intermediate Quality Attribute (IQA)ß";

    super.componentDidMount();
  }

  static getHTMLURLPrefix() {
    return "/processExplorer/iqas";
  }

  hasRelatedItemsTab() {
    return true;
  }

  getInfoTooltip() {
    return (
      <InfoTooltip id="infoPRC"
                   fdaGuidanceURL={ReferenceDocuments.Q8_GUIDELINE}
                   fdaGuidancePage={16}
                   fdaGuidanceOffset={200}
                   verbiage={<div>
                     A physical, chemical, biological, or microbiological property or characteristic that should be
                     within an appropriate limit, range, or distribution to ensure the
                     desired product quality.
                   </div>}
      />
    );
  }

  getParentId() {
    return this.state.UnitOperationId || UIUtils.getParameterByName("parentId");
  }

  filterSteps(option) {
    return option.UnitOperationId === this.state.UnitOperationId;
  }

  renderRiskLinksAttribute() {
    const isLoading = !this.state.RMP || !this.state.ProjectWithAllVersions || this.isLoading() || this.isLoadingRiskLinks();
    return (
      <div className="row">
        <MultiDocumentsRiskLinksAttribute name="risk"
                                          linkToObjectDisplayName="Intermediate or Final Attributes"
                                          linkToObject={["IPA", "IQA", "FPA", "FQA"]}
                                          linkToObjectId={["IPAId", "TargetIQAId", "FPAId", "FQAId"]}
                                          linkObject={["IQAToIPA", "IQAToIQA", "IQAToFPA", "IQAToFQA"]}
                                          optionsFilter={this.filterRiskLinkTypeaheadOptions}
                                          displayName="Risk"
                                          className="col-sm-12"
                                          ref={(riskTable) => this.setRiskTableToState(riskTable)}
                                          isLoading={isLoading}
                                          triggerChildUpdate={this.state.triggerChildUpdate}
                                          modelName={this.baseTypeName}
                                          RMP={this.state.RMP}
                                          projectWithAllVersions={this.state.ProjectWithAllVersions}
                                          parent={this}
                                          onValidate={this.handleRiskVerification}
                                          onDelete={this.handleDelete}
                                          onSave={this.handleRiskLinkSave}
                                          risksFromAllRecords={this.state.risksFromAllRecords}
        />
      </div>
    );
  }

  renderGeneralSection() {
    return <Section parent={this}
                    header={this.getGeneralHeader()}
                    collapsible={false}
    >
      <div className="row">
        <TextAttribute name="name"
                       parent={this}
        />
        <TypeaheadAttribute name="process"
                            default={this.getProcessId()}
                            typeaheadType="Process"
                            target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                            instructions={`This can only be modified on add.`}
                            disabled={!this.isAdd()}
                            parent={this}
                            isLoading={this.state.isLoading}
                            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                            parentId={this.state.id}
                            projectId={this.getProjectId()}
                            processId={this.getProcessId()}
        />
      </div>
      <div className="row">
        <TypeaheadAttribute name="unitOperation"
                            default={this.getParentId()}
                            typeaheadType="UnitOperation"
                            target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                            parent={this}
                            isLoading={this.state.isLoading}
                            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                            parentId={this.state.id}
                            projectId={this.getProjectId()}
                            processId={this.getProcessId()}
        />
        <TypeaheadAttribute name="step"
                            typeaheadType="Step"
                            target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                            filter={this.filterSteps}
                            parent={this}
                            isLoading={this.state.isLoading}
                            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                            parentId={this.state.id}
                            projectId={this.getProjectId()}
                            processId={this.getProcessId()}
                            relatedRecordId={this.state.UnitOperationId}
        />
        <ComboBoxAttribute name="type"
                           options={["Physical",
                             "Chemical",
                             "Biological",
                             "Microbiological"]}
                           default="Chemical"
                           parent={this}
                           isLoading={this.state.isLoading}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           parentId={this.state.id}
        />
        <TextAreaAttribute name="description"
                           className="col-sm-12"
                           parent={this}
        />
      </div>
    </Section>;
  }
}

export default I18NWrapper.wrap(IQA, ["process_explorer", "editor"]);
// i18next-extract-mark-ns-stop process_explorer
