"use strict";

import * as UIUtils from "../../../../ui_utils";
import React from "react";
import pdfJs from "pdfjs-dist";
import * as pdfJsWorker from "pdfjs-dist/build/pdf.worker.entry";
import * as pdfJsViewer from "pdfjs-dist/web/pdf_viewer";
import { DOM_UPDATE_TIMEOUT } from "../../../constants/import_constants";
import { SCROLL_BAR_WIDTH } from "../../../helpers/import_helper";
import BaseReactComponent from "../../../../base_react_component";

pdfJs.GlobalWorkerOptions.workerSrc = pdfJsWorker;

/**
 * This is responsible for displaying a pdf in a div.
 */
export default class OCRPDFViewer extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.canvas = null;
    this.renderingInProcess = false;
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const {dataUrl, zoomLevel, panelSize, textractResults} = this.props;
    const nextDataUrl = nextProps.dataUrl;
    const nextZoomLevel = nextProps.zoomLevel;
    const nextPanelSize = nextProps.panelSize;
    const isPDF = nextProps.isPDF;
    const nextTextractResults = nextProps.textractResults;

    const numOfTextractBlocks = textractResults &&
      textractResults.blocks &&
      textractResults.blocks.length;
    const numOfTextractColumns = textractResults &&
      textractResults.columns &&
      textractResults.columns.length;
    const nextNumOfTextractBlocks = nextTextractResults &&
      nextTextractResults.blocks &&
      nextTextractResults.blocks.length;
    const nextNumOfTextractColumns = nextTextractResults &&
      nextTextractResults.columns &&
      nextTextractResults.columns.length;

    return isPDF && (zoomLevel !== nextZoomLevel
      || panelSize !== nextPanelSize
      || dataUrl !== nextDataUrl
      || numOfTextractBlocks !== nextNumOfTextractBlocks
      || numOfTextractColumns !== nextNumOfTextractColumns);
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.props.isPDF) {
      this.renderingInProcess = true;
      setTimeout(this.renderPDF, DOM_UPDATE_TIMEOUT);
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isPDF && !this.renderingInProcess) {
      this.renderingInProcess = true;
      clearTimeout(this.isScrolling);
      setTimeout(this.renderPDF, DOM_UPDATE_TIMEOUT);
    }
  }

  handleScroll() {
    if (!this.renderingInProcess) {
      this.scrollDiv && this.props.onScroll(this.scrollDiv.scrollTop, this.scrollDiv.scrollLeft, false);

      clearTimeout(this.isScrolling);
      this.isScrolling = setTimeout(() => {
        if (!this.renderingInProcess) {
          this.scrollDiv && this.props.onScroll(this.scrollDiv.scrollTop, this.scrollDiv.scrollLeft, true);
        }
      }, 100);
    }
  }

  renderPDF() {
    let src = this.props.dataUrl;
    let parentDomContainer = $(".import-document-div")[0];
    let container = $("#importOCRPDFViewerDiv");
    let domContainer = container[0];
    let containerWidth, scale, defaultViewport;
    let pdfPageWidth, pdfPageHeight;

    container.empty();

    /* This is a trick to avoid extending the page width infinitely when zooming in the PDF. For some reason, setting
       the width of the div to 100% does not constrain it to the parent's width. On the contrary, then zooming in the
       pdf, the canvas container width increases and along with it the page bootstrap container div. When setting the
       div to a fixed size, this does not happen anymore. The trick below just makes sure that the container div has
       the width of its parent simulating a width of 100% using pixes for size.
     */
    container.width(parentDomContainer.clientWidth);
    container.height(parentDomContainer.clientHeight);

    if (src) {
      let numberOfPages;
      const loadingTask = pdfJs.getDocument(src);

      return loadingTask.promise.then(pdf => {
        numberOfPages = pdf.numPages;
        let shouldRenderPDF = numberOfPages < this.props.maxPDFPages;
        let renderPromise = Promise.resolve();

        if (shouldRenderPDF && this._isMounted) {
          for (let pageNum = 1; pageNum <= numberOfPages; pageNum++) {
            renderPromise = renderPromise.then(() => {
              return pdf.getPage(pageNum).then(pdfPage => {
                containerWidth = parentDomContainer.clientWidth - SCROLL_BAR_WIDTH;
                defaultViewport = pdfPage.getViewport({scale: 1.0});
                scale = this.props.zoomLevel * containerWidth / defaultViewport.width / 100;
                let scaledViewport = pdfPage.getViewport({scale});

                let pdfPageView = new pdfJsViewer.PDFPageView({
                  container: domContainer,
                  id: pageNum,
                  scale,
                  defaultViewport: scaledViewport,
                  renderer: "canvas"
                });

                pdfPageView.setPdfPage(pdfPage);
                pdfPageView.viewport = scaledViewport;
                return pdfPageView.draw().then(() => {
                  /*The pdfPageView does not respect the scaledViewport. When we call setPdfPage on the pdfPageView the
                    viewport is reset to what the default non scaled viewport is, similar to the one returned by calling
                    pdfPage.getViewport({scale: 1.0}). To work around this, besides resetting the viewport to the scaled one
                    right above, we need to reset the widths and heights of the generated divs by the pdfPageView.draw() method.
                    Those divs are scaled based on the scale factor which is calculated above in the code. The generated HTML5
                    canvas object is also scaled. A margin of 20 pixels is subtracted from the width to make room for the
                    vertical scrollbar so that a horizontal scrollbar is not shown when not needed.
                   */
                  let canvas = container.find(".page .canvasWrapper canvas");
                  let canvasWrapper = canvas.parent();
                  let pageWrapper = canvasWrapper.parent();
                  let pageWidth = defaultViewport.width * scale;
                  let pageHeight = defaultViewport.height * scale;

                  pdfPageWidth = pageWidth;
                  pdfPageHeight = pageHeight;
                  pageWrapper.width(pageWidth);
                  pageWrapper.height(pageHeight);
                  canvasWrapper.width(pageWidth);
                  canvasWrapper.height(pageHeight);
                  canvas.width(pageWidth);
                });
              });
            });
          }
        }

        return renderPromise.then(() => {
          this.props.onPDFRenderingCompleted(numberOfPages,
            {
              width: defaultViewport ? defaultViewport.width : 0,
              height: defaultViewport ? defaultViewport.height : 0,
              pdfPageHeight,
              pdfPageWidth,
            }, () => {
              this.renderingInProcess = false;
            });
        });
      }).catch(error => {
        if (error.name === "InvalidPDFException") {
          UIUtils.showError("PDF file too large or corrupted.");
        }
        UIUtils.hideLoadingImage();
        this.renderingInProcess = false;
      });
    } else {
      this.renderingInProcess = false;
    }
  }

  render() {
    return (
      <div className="import-data-ocr-pdf-div">
        <div id="importOCRPDFViewerDiv"
             ref={scrollDiv => this.scrollDiv = scrollDiv}
             onScroll={this.handleScroll}
        />
      </div>
    );
  }
}
