import { __assign } from "tslib";
import { NodeSelection, Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { dataResizeDirImage, resizeHandle } from "../config/constants";
import { changeStylesString } from "../utils";
import { directions, handles } from './resize-utils';
export var imageResizeKey = new PluginKey('image-resize');
var setSize = function (domNode, sizeType, value) {
    domNode.style[sizeType] = value + 'px';
};
var reSize = /[^\-]width:|[^\-]height:/;
var reAnyValue = /^.+$/;
var ResizeState = /** @class */ (function () {
    function ResizeState(activeHandle, dragging, rect, nodePosition) {
        this.activeHandle = activeHandle;
        this.dragging = dragging;
        this.rect = rect;
        this.nodePosition = nodePosition;
    }
    ResizeState.prototype.apply = function (tr) {
        var state = this, next = tr.getMeta(imageResizeKey);
        if (next) {
            return new ResizeState(next.activeHandle, next.setDragging, next.rect, next.nodePosition);
        }
        return state;
    };
    return ResizeState;
}());
var handleMouseMove = function (view, event, options) {
    var state = imageResizeKey.getState(view.state);
    var rect = state.rect, dragging = state.dragging, nodePosition = state.nodePosition, activeHandle = state.activeHandle;
    if (!dragging || !rect) {
        return;
    }
    var img = view.nodeDOM(nodePosition);
    var dir = directions[activeHandle];
    var diffX = (event.clientX - dragging.startX) * dir.x;
    var diffY = (event.clientY - dragging.startY) * dir.y;
    var width = dir.x ? diffX + img.width : rect.width;
    var height = dir.y ? diffY + img.height : rect.height;
    if (options.lockRatio && dir.x && dir.y) {
        var ratio = Math.min(width / img.width, height / img.height);
        var lockWidth = img.width * ratio;
        var lockHeight = img.height * ratio;
        dragging.startX = event.clientX - (width - lockWidth) * dir.x;
        dragging.startY = event.clientY - (height - lockHeight) * dir.y;
        width = lockWidth;
        height = lockHeight;
    }
    else {
        dragging.startX = dir.x ? event.clientX : dragging.startX;
        dragging.startY = dir.y ? event.clientY : dragging.startY;
    }
    setSize(img, 'width', width);
    setSize(img, 'height', height);
    rect.top = img.offsetTop;
    rect.left = img.offsetLeft;
    rect.width = img.offsetWidth;
    rect.height = img.offsetHeight;
    var handlesWrapper = img.nextElementSibling;
    handlesWrapper.style.width = rect.width + 'px';
    handlesWrapper.style.height = rect.height + 'px';
    handlesWrapper.style.top = rect.top + 'px';
    handlesWrapper.style.left = rect.left + 'px';
};
var handleMouseUp = function (view) {
    var _a = imageResizeKey.getState(view.state), rect = _a.rect, dragging = _a.dragging, nodePosition = _a.nodePosition;
    if (dragging && rect) {
        var selection = view.state.selection;
        if (selection instanceof NodeSelection) {
            var currAttrs = selection.node.attrs;
            var width = rect.width;
            var height = rect.height;
            var attrs = void 0;
            if (reSize.test(currAttrs.style || '')) {
                var changedWidth = changeStylesString(currAttrs.style, { style: 'width', value: reAnyValue, newValue: width + 'px' });
                var style = changeStylesString(changedWidth.style || '', { style: 'height', value: reAnyValue, newValue: height + 'px' }).style;
                attrs = __assign(__assign({}, currAttrs), { style: style });
            }
            else {
                attrs = __assign(__assign({}, currAttrs), { width: width, height: height });
            }
            var newImage = selection.node.type.createAndFill(attrs);
            if (newImage) {
                var tr = view.state.tr;
                tr.replaceWith(nodePosition, nodePosition + 1, newImage);
                tr.setSelection(NodeSelection.create(tr.doc, nodePosition));
                tr.setMeta('commandName', 'image-resize');
                tr.setMeta('args', attrs);
                tr.setMeta(imageResizeKey, {
                    setDragging: null,
                    activeHandle: null,
                    rect: rect,
                    nodePosition: nodePosition
                });
                view.dispatch(tr);
            }
        }
    }
};
var handleMouseDown = function (view, event, options) {
    var target = event.target;
    var activeHandle = target.getAttribute(dataResizeDirImage);
    if (!activeHandle) {
        return false;
    }
    var resizeState = imageResizeKey.getState(view.state);
    event.preventDefault();
    var transaction = view.state.tr;
    transaction.setMeta(imageResizeKey, {
        setDragging: { startX: event.clientX, startY: event.clientY },
        activeHandle: activeHandle,
        rect: resizeState.rect,
        nodePosition: resizeState.nodePosition
    });
    transaction.setMeta('addToHistory', false);
    view.dispatch(transaction);
    function move(e) {
        handleMouseMove(view, e, options);
    }
    function finish(e) {
        e.view.removeEventListener('mouseup', finish);
        e.view.removeEventListener('mousemove', move);
        handleMouseUp(view);
    }
    event.view.addEventListener('mouseup', finish);
    event.view.addEventListener('mousemove', move);
    return true;
};
export var imageResizing = function (options) {
    if (options === void 0) { options = { node: 'image', lockRatio: true }; }
    return new Plugin({
        key: imageResizeKey,
        view: function (viewObj) { return ({
            resize: function () {
                if (imageResizeKey.getState(viewObj.state).rect) {
                    viewObj.dispatch(viewObj.state.tr.setMeta('resize', true));
                }
            },
            get window() {
                return viewObj.dom.ownerDocument && viewObj.dom.ownerDocument.defaultView;
            },
            attachResize: function () {
                var win = this.window;
                if (win) {
                    win.removeEventListener('resize', this.resize);
                    win.addEventListener('resize', this.resize);
                }
            },
            removeResize: function () {
                var win = this.window;
                if (win) {
                    win.removeEventListener('resize', this.resize);
                }
            },
            update: function (view, prevState) {
                var state = view.state;
                var selection = state.selection;
                var nodeType = state.schema.nodes[options.node];
                var pluginState = imageResizeKey.getState(state);
                var prevRect = pluginState.rect;
                if (selection instanceof NodeSelection && nodeType === selection.node.type) {
                    var img = view.nodeDOM(selection.from);
                    var rect = {
                        top: img.offsetTop,
                        left: img.offsetLeft,
                        width: img.offsetWidth,
                        height: img.offsetHeight
                    };
                    if (!prevState.selection.eq(selection) ||
                        (prevRect && (prevRect.width !== rect.width || prevRect.height !== rect.height ||
                            prevRect.top !== rect.top || prevRect.left !== rect.left))) {
                        var tr = state.tr;
                        tr.setMeta(imageResizeKey, { rect: rect, nodePosition: selection.from });
                        view.dispatch(tr);
                        this.attachResize();
                    }
                }
                else if (prevRect) {
                    pluginState.rect = null;
                    pluginState.nodePosition = -1;
                }
            },
            destroy: function () {
                this.removeResize();
            }
        }); },
        state: {
            init: function () {
                return new ResizeState('', null, null, -1);
            },
            apply: function (tr, prev) {
                return prev.apply(tr);
            }
        },
        props: {
            handleDOMEvents: {
                mousedown: function (view, event) {
                    return handleMouseDown(view, event, options);
                }
            },
            decorations: function (state) {
                var selection = state.selection;
                var nodeType = state.schema.nodes[options.node];
                var rect = imageResizeKey.getState(state).rect;
                if (rect && selection instanceof NodeSelection && nodeType === selection.node.type) {
                    var wrapper = document.createElement('div');
                    wrapper.className = 'k-editor-resize-handles-wrapper';
                    wrapper.style.width = rect.width + 'px';
                    wrapper.style.height = rect.height + 'px';
                    wrapper.style.top = rect.top + 'px';
                    wrapper.style.left = rect.left + 'px';
                    for (var i = 0; i < handles.length; i++) {
                        var dom = document.createElement('div');
                        dom.className = resizeHandle + ' ' + handles[i];
                        dom.setAttribute(dataResizeDirImage, handles[i]);
                        wrapper.appendChild(dom);
                    }
                    return DecorationSet.create(state.doc, [Decoration.widget(state.selection.from + 1, wrapper)]);
                }
                return DecorationSet.empty;
            }
        }
    });
};
