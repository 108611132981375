import { __assign } from "tslib";
import { PluginKey } from 'prosemirror-state';
import { TableMap } from 'prosemirror-tables';
import { changeStylesString } from '../../utils';
export var reAnyValue = /^.+$/;
export var parseStyle = function (styleText) {
    var styles = (styleText || '').split(/\s*;\s*/).filter(Boolean).map(function (s) {
        var _a;
        var nameValue = s.split(/\s*:\s*/);
        return _a = {}, _a[nameValue[0]] = nameValue[1], _a;
    }).reduce(function (acc, val) { return (__assign(__assign({}, acc), val)); }, {});
    return styles;
};
export function setNodeStyle(nodeAttrs, styleType, value) {
    var attrs;
    if (new RegExp('[^-]?' + styleType + ':').test(nodeAttrs.style || '')) {
        var style = changeStylesString(nodeAttrs.style || '', { style: styleType, value: reAnyValue, newValue: value }).style;
        attrs = __assign(__assign({}, nodeAttrs), { style: style });
    }
    else if (nodeAttrs.style) {
        attrs = __assign(__assign({}, nodeAttrs), { style: nodeAttrs.style.replace(/;$/, '') + '; ' + styleType + ': ' + value + ';' });
    }
    else {
        attrs = __assign(__assign({}, nodeAttrs), { style: styleType + ': ' + value + ';' });
    }
    return attrs;
}
export var tableResizeKey = new PluginKey('table-resize');
export var tableColumnResizeKey = new PluginKey('table-column-resizing');
export var tableRowResizeKey = new PluginKey('table-row-resizing');
export function otherResizing(current, state) {
    var activeResize = false;
    activeResize = activeResize ||
        (current !== tableResizeKey && Boolean(tableResizeKey.get(state)) && tableResizeKey.getState(state).dragging);
    activeResize = activeResize ||
        (current !== tableColumnResizeKey && Boolean(tableColumnResizeKey.get(state)) && tableColumnResizeKey.getState(state).dragging);
    activeResize = activeResize ||
        (current !== tableRowResizeKey && Boolean(tableRowResizeKey.get(state)) && tableRowResizeKey.getState(state).dragging);
    return activeResize;
}
export function otherResizeHandle(current, state) {
    var activeResize = false;
    activeResize = activeResize ||
        (current !== tableColumnResizeKey &&
            Boolean(tableColumnResizeKey.get(state)) &&
            tableColumnResizeKey.getState(state).activeHandle > -1);
    activeResize = activeResize ||
        (current !== tableRowResizeKey && Boolean(tableRowResizeKey.get(state)) && tableRowResizeKey.getState(state).activeHandle > -1);
    return activeResize;
}
export function getTable(dom) {
    if (dom && dom.firstChild && dom.firstChild.nodeName === 'TABLE') {
        return dom.firstChild;
    }
    return dom;
}
export function domCellAround(target) {
    while (target && target.nodeName !== 'TD' && target.nodeName !== 'TH') {
        target = target.classList.contains('ProseMirror') ? null : target.parentNode;
    }
    return target;
}
function tableSpansMap(table) {
    var rows = Array.from((table && table.rows) || []);
    var colsCount = Array.from((rows && rows[0] && rows[0].cells) || [])
        .map(function (c) { return c.colSpan; })
        .reduce(function (prev, cur) { return prev + cur; }, 0);
    var map = rows.map(function () { return new Array(colsCount); });
    rows.forEach(function (row, r) {
        var curColSpan = 0;
        Array.from(row.cells).forEach(function (c) {
            for (var colSp = 0; colSp < c.colSpan; colSp++) {
                for (var rowSp = 0; rowSp < c.rowSpan; rowSp++) {
                    var ind = map[r + rowSp].findIndex(function (val, curInd) { return curInd >= curColSpan && !val; });
                    map[r + rowSp][ind] = c;
                }
                curColSpan++;
            }
        });
    });
    return map;
}
export function cellIndexes(dataCell) {
    var map = tableSpansMap(dataCell.closest('table'));
    var result = null;
    var _loop_1 = function (r) {
        var row = map[r];
        row.forEach(function (cell, c) {
            if (dataCell === cell) {
                result = { rowIndex: r, cellIndex: c };
            }
        });
        if (result) {
            return "break";
        }
    };
    for (var r = 0; r < map.length; r++) {
        var state_1 = _loop_1(r);
        if (state_1 === "break")
            break;
    }
    return result || { rowIndex: -1, cellIndex: -1 };
}
export function parentNode(pos, predicate) {
    for (var depth = pos.depth; depth > 0; depth--) {
        var node = pos.node(depth);
        if (predicate(node)) {
            return { node: node, depth: depth };
        }
    }
    return null;
}
export function edgeCell(view, event, indexes) {
    var found = view.posAtCoords({ left: event.clientX, top: event.clientY });
    if (!found) {
        return -1;
    }
    var $pos = view.state.doc.resolve(found.pos);
    var parentTable = parentNode($pos, function (n) { return n.type.spec.tableRole === 'table'; });
    if (parentTable === null) {
        return -1;
    }
    var tablePos = $pos.start(parentTable.depth);
    var tableNode = parentTable.node;
    var map = TableMap.get(tableNode);
    var cell = tablePos + map.map[(map.width * indexes.rowIndex) + indexes.cellIndex];
    return cell;
}
