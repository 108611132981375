"use strict";

import { useSidePanel } from "../widgets/side_panel_provider";
import * as styles from "./table_tool_bar.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSliders } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { SIDE_PANEL_ACTIONS } from "../../constants";

export const Configure = () => {
  const {toggle, showSidePanel} = useSidePanel();

  const onClick = () => {
    toggle(SIDE_PANEL_ACTIONS.CONFIGURE);
  };

  return (
    <div id="columnSelectionButton"
         className={`${styles["configure-widget"]} ${showSidePanel && styles["widget-open"]}`}
         onClick={onClick}>
      <FontAwesomeIcon icon={faSliders} />
      <span>Configure</span>
    </div>
  );
};
