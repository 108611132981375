"use strict";

import React from "react";
import ApproverTooltip from "./approver_tooltip";
import BaseReactComponent from "../../base_react_component";

/* This class is responsible for showing all of the circles with approver initials for a particular version. */
export default class ApproverTooltips extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let version = this.props.version;
    let versionTransition = this.props.versionTransition;
    let approvalRequest = versionTransition.approvalRequest;
    let userIdToApprovalResponse = new Map();
    if (versionTransition.approvalResponses) {
      for (let approvalResponse of versionTransition.approvalResponses) {
        userIdToApprovalResponse[approvalResponse.createdByUserId] = approvalResponse;
      }
    }

    return (
      <div>
        {approvalRequest ? (
          <div className={"col-sm-12 history-item-approver-icons"}>
            <div className="col-sm-12">
              {approvalRequest.approvalReqToUsers.map(
                approverRequested => (
                  <ApproverTooltip key={approverRequested.userId}
                                   index={this.props.index}
                                   approver={approverRequested.user}
                                   approverResponse={userIdToApprovalResponse[approverRequested.userId]}
                                   version={version}
                                   approvedVersion={this.props.approvedVersion}
                                   isVersionTransitionTooltip={!!this.props.isVersionTransitionTooltip}
                  />
                )
              )}
            </div>
          </div>) : ""}

      </div>
    );
  }
}
