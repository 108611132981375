"use strict";

import React, { Fragment, useRef, useState } from "react";
import SimpleTypeaheadControl from "../../../widgets/simple_typeahead_control";
import ToggleInput from "react-switch";

/**
 * Add To Project options like specify Project, Unit Operation, etc...
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const ProjectMaterialOptions = (props) => {

  const {
    filterSteps,
    handleChangeValue,
    ProjectId,
    ProcessId,
    UnitOperationId,
    StepId,
    keepSynced,
    isStartingFromProject,
  } = props;

  // Keep track of initial Project, Process, Unit Operation and Step to decide if the attributes will be read only or nor.
  const [state] = useState({...props});

  const toggleRef = useRef(keepSynced);

  return (
    <Fragment>
      <div className="row">
        <SimpleTypeaheadControl name="Project"
                                typeaheadType="Project"
                                required={true}
                                ProjectId={ProjectId}
                                ProcessId={ProcessId}
                                value={ProjectId}
                                loadArchived={false}
                                disabled={(!!state.ProjectId && isStartingFromProject)}
                                handleChangeValue={handleChangeValue}
        />
        <SimpleTypeaheadControl name="Process"
                                typeaheadType="Process"
                                required={true}
                                ProjectId={ProjectId}
                                ProcessId={ProcessId}
                                value={ProcessId}
                                loadArchived={false}
                                handleChangeValue={handleChangeValue}
                                disabled={!ProjectId || (!!state.ProcessId && isStartingFromProject)}
        />
      </div>
      <div className="row">
        <SimpleTypeaheadControl name="Unit Operation"
                                typeaheadType="UnitOperation"
                                required={true}
                                ProjectId={ProjectId}
                                ProcessId={ProcessId}
                                value={UnitOperationId}
                                loadArchived={false}
                                handleChangeValue={handleChangeValue}
                                disabled={!ProcessId || (!!state.UnitOperationId && isStartingFromProject)}
        />
        <SimpleTypeaheadControl name="Step"
                                typeaheadType="Step"
                                required={false}
                                ProjectId={ProjectId}
                                ProcessId={ProcessId}
                                value={StepId}
                                filter={filterSteps}
                                loadArchived={false}
                                handleChangeValue={handleChangeValue}
                                relatedRecordId={state.UnitOperationId}
                                disabled={!UnitOperationId || (!!state.StepId && isStartingFromProject)}
        />
      </div>
      <div className={`row align-items-center mt-3`}>
        <div className="col sync-attribute">
          <ToggleInput id="syncInput"
                       className="control-checkbox-toggle"
                       checked={toggleRef.current}
                       onChange={(value) => {
                         toggleRef.current = value;
                         handleChangeValue("keepSynced", value);
                       }}
                       height={15}
                       width={31}
                       checkedIcon={false}
                       uncheckedIcon={false}
                       activeBoxShadow="0 0 2px 3px #014768"
                       onColor="#DBE1E4"
                       offColor="#DBE1E4"
                       onHandleColor="#1F46A1"
          />
          <span className="ml-3">Sync</span>
        </div>
      </div>
      <div className="row">
        <span className="col sync-attribute-description">
          The Material will be synced to the Library and will automatically update to show changes made in the Library.
        </span>
      </div>
    </Fragment>
  );
};