"use strict";

import React from "react";
import BasePage from "./base_page";
import QuickPanel from "./editor/quick/quick_panel";
import { EDITOR_OPERATIONS, EDITOR_TYPES } from "./editor/editor_constants";
import FooterBar from "./widgets/bars/footer_bar";
import TrialBar from "./widgets/bars/trial_bar";
import CompanyHeader from "./widgets/headers/company_header";
import CookiesBar from "./widgets/bars/cookies_bar";
import TermsPopup from "./terms/terms_popup";
import * as UIUtils from "./ui_utils";
import { QUICK_PANEL_BUTTONS } from "./widgets/quickPanel/quick_panel_buttons";
import { getURLByTypeCodeAndIdAndVersionId } from "./helpers/url_helper";

/**
 * This is the base class that every page should extend from in case they have multiple tables
 * and want a single quick panel for the whole page.
 */
export default class BaseQuickPage extends BasePage {
  constructor(props) {
    super(props);

    this.quickPanel = React.createRef();
  }

  collapseAllSections() {
    return true;
  }

  getQuickPanelButtons() {
    return Array.isArray(this.props.quickPanelButtons) ? this.props.quickPanelButtons : [QUICK_PANEL_BUTTONS.VIEW];
  }

  handleCancelQuickEditLoad(oldTypeCode, oldId, oldVersionId) {
    this.setStateSafely({
      quickPanelInfo: {typeCode: oldTypeCode, id: oldId, versionId: oldVersionId}
    });
  }

  handleQuickEditRecord(typeCode, versionInfo) {
    const {id, versionId} = versionInfo;
    const {showVersionInQuickPanel} = this.props;

    const selectedRecord = this.instances.find(record => {
      const recordTypeCode = UIUtils.getTypeCodeForModelName(record.modelName);
      return showVersionInQuickPanel
        ? record.id === versionId && recordTypeCode === typeCode
        : record.id === id && recordTypeCode === typeCode;
    });

    const quickPanelInfo = {typeCode, id, versionId};

    const quickPanel = this.quickPanel.current;
    if (quickPanel && quickPanel.isVisible()) {
      this.setStateSafely({
        selectedRecord,
        quickPanelInfo,
      });

      quickPanel.expandToOpen();
    } else {
      // This is for screen less than 500x500 pixels so it opens in a new window instead of the quick panel
      let typeCodeToDisplay = selectedRecord.typeCode || UIUtils.findTypeCodeForModelName(selectedRecord.modelName) || this.baseTypeCode;
      window.open(getURLByTypeCodeAndIdAndVersionId(typeCodeToDisplay, "View", selectedRecord.id, false, selectedRecord.versionId), "_blank");
    }
  }

  handleQuickPanelInstanceReceived(result) {
    this.instances = result && result.instances ? this.instances.concat(result.instances) : [];
    this.props.onTypeaheadOptionsReceived(this.instances);
  }

  renderQuickPanel() {
    const {t, showVersionInQuickPanel} = this.props;
    let {quickPanelInfo, selectedRecord} = this.state;
    let quickPanelConfig = {
      showHistory: false,
      quickPanelButtons: this.getQuickPanelButtons(),
      sectionsCollapsed: this.collapseAllSections(quickPanelInfo),
      showButtonsInNewLine: !!this.props.showButtonsInNewLine,
      ...this.props.quickPanelConfig,
    };

    return (
      <QuickPanel typeCode={quickPanelInfo ? quickPanelInfo.typeCode : undefined}
                  ref={this.quickPanel}
                  id={quickPanelInfo ? quickPanelInfo.id : undefined}
                  versionId={showVersionInQuickPanel && quickPanelInfo ? quickPanelInfo.versionId : undefined}
                  config={quickPanelConfig}
                  editorType={this.props.editorType ? this.props.editorType : EDITOR_TYPES.QUICK_PANEL}
                  editorOperation={this.props.editorOperation ? this.props.editorOperation : EDITOR_OPERATIONS.VIEW}
                  emptySelectionMessage={t("Click on any record to view it here.")}
                  data={selectedRecord}
                  onCancelLoad={this.handleCancelQuickEditLoad}
                  onActionClick={(action, state, callback) => {
                    return typeof this.props.onQuickPanelActionClick === "function"
                      ? this.props.onQuickPanelActionClick(action, state, callback)
                      : null;
                  }}

      />
    );
  }

  render() {
    let footerBar = this.isMinified() ? null : (<FooterBar />);
    return (
      <div id="bodyDiv">
        <TrialBar minified={this.isMinified()} />
        <CompanyHeader minified={this.isMinified()}
                       project={this.state.project}
                       extraPrintItems={this.extraPrintItems}
                       title={this.state.title}
        />
        {this.renderQuickPanel()}
        {this.renderPage()}
        {footerBar}
        <CookiesBar />
        <TermsPopup modalRef={termsPopup => this.termsPopup = termsPopup}
                    onApproveButtonCLick={this.handleTermsPopupApproveButtonClick}
        />
      </div>
    );
  }
}
