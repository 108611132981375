import React, { useRef } from "react";
import {Button} from "@qbdvision-inc/component-library";
import QRCodeSVG from "qrcode.react";
import QbDVisionLogo from "../../images/logo-128.png";


interface MFAFormProps {
  mfaSecret: string;
  // eslint-disable-next-line no-unused-vars
  handleMfaSubmission: (event: React.MouseEvent<HTMLButtonElement>, action: string, mfaCode?: string) => void;
  username: string;
}

export default function MFASetup({ mfaSecret, handleMfaSubmission, username }: MFAFormProps) {
  const mfaInputRef = useRef<HTMLInputElement>(null);
  const qr = "otpauth://totp/AWSCognito:" + username + "?secret=" + mfaSecret + "&issuer=" + "QbDVision";

  return (
    <>
    <div className="mfa-step">
      <h2>Download Authenticator App</h2>
      <p>Download and install an authenticator app on your mobile device.
        Popular authenticator apps are Google Authenticator, Microsoft Authenticator, and Authy.</p>
    </div>
    <div className="mfa-step">
      <h2>Scan QR Code</h2>
      <p>Open your authenticator app and follow the prompts to scan the QR code displayed on the screen to set up MFA.</p>
      <QRCodeSVG value={qr}
                 size={128}
                 bgColor={"white"}
                 fgColor={"#1F46A1"}
                 imageSettings={{src: QbDVisionLogo, excavate: true, height: 28, width: 28}}
                 data-secret={mfaSecret}
                 id={"mfaSecretQr"}
      />
    </div>
    <div className="mfa-step">
      <h2>Enter Verification Code</h2>
      <p>
        <label htmlFor="mfaInput">Enter the 6 digit verification code generated by the authenticator app</label>
        <input type="number" id="mfaInput" name="mfaInput" ref={mfaInputRef} />
      </p>
      <Button onClick={(e) => {handleMfaSubmission(e, "setup", mfaInputRef.current.value);}}
              id="mfaSubmitButton"
              label={"Login"}
              isSubmit
      />
    </div>
    </>
  );
}