"use strict";

import React from "react";
import BaseAttribute from "./base_attribute";
import ToggleInput from "react-switch";
import LabelTooltip from "../../widgets/tooltips/label_tooltip";

export default class ToggleAttribute extends BaseAttribute {
  constructor(props) {
    super(props);
    this.state = {};

    // Bind functions to this
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(checked) {
    if (this.props.onChange) {
      this.props.onChange(this.props.name, checked);
    } else {
      this.props.parent.handleChangeValue(this.props.name, checked);
    }
  }

  // noinspection JSMethodCanBeStatic
  getInitialValue() {
    return this.props.value ? this.props.value : false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      let value = this.getNextValue(nextProps);
      return (this.currentValue !== !!value);
    }
    return true;
  }

  render() {
    let inputId = this.getInputId();
    this.currentValue = this.getValue();
    return (
      <div className="col-sm-12">
        <div className="col-form-label-toggle">
          <LabelTooltip id={inputId + "Label"}
                        tooltipText={this.props.tooltipText}
                        style={this.props.labelStyle ? this.props.labelStyle : {}}
                        noColon={true}
                        text={(
                          <div className={"toggle-control-text " + (this.props.tooltipText ? " col-form-label-tooltip" : "")}>{this.getDisplayName()}</div>
                        )} />
        </div>
        <ToggleInput id={inputId}
                     className="control-checkbox-toggle"
                     checked={this.currentValue}
                     onChange={this.handleChange}
                     disabled={this.isDisabled()}
                     height={22}
                     width={38}
                     checkedIcon={false}
                     uncheckedIcon={false}
                     activeBoxShadow="0 0 2px 3px #014768"
                     onColor="#DBE1E4"
                     offColor="#DBE1E4"
                     onHandleColor="#1F46A1"
        />
      </div>
    );
  }

  renderInput() {
    return undefined;
  }
}
