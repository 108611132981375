"use strict";

import { orderAndIndexUnits } from "../../processExplorer/indexers/uo_indexer";
import ImplementationNeededError from "../../utils/implementation_needed_error";
import TechTransferAdapterHelper from "./tech_transfer_adapter_helper";
import RecordsIndexer from "../../processExplorer/indexers/records_indexer";

/**
 * This class adapts/transforms the tech transfer data coming from the server
 * to be suitable for UI digestion.
 */
export default class TechTransferAdapterBase {

  /**
   * @param params includes the following params
   * @param activeMapName Main map of the adapter
   * @param modelName ModelName the adapter is for
   * @param parentPropName Parent of the model (ex. PRC or MT)
   * techTransferResults {ProcessExplorerResults} The results from the process explorer handler
   * fromProcessId {int} The id of the from Process in the comparison
   * typeaheadOptions Options for risk links
   * effectiveRMP Effective RMP
   * selectedModelType Adapt results depending on requested model type
   */
  constructor(params, activeMapName, modelName, parentPropName) {
    this.params = params;
    this.results = params.techTransferResults;
    this.typeaheadOptions = params.typeaheadOptions;
    this.effectiveRMP = params.effectiveRMP;
    this.effectiveTechTransferRMP = params.effectiveTechTransferRMP;
    this.selectedModelType = params.selectedModelType;
    this.activeMap = this.results[activeMapName];
    this.changeCriteria = params.changeCriteria;
    this.modelName = modelName;
    this.parentPropName = parentPropName;
    this.activeMapName = activeMapName;

    // Put the UOs in the from Process in order.
    const fromUOs = Object.values(params.techTransferResults.uoMap).filter(uo => uo.processId === params.fromProcessId);
    this.orderedUOList = orderAndIndexUnits(fromUOs, "id", false);
    this.techTransferAdapterHelper = new TechTransferAdapterHelper({
      results: this.results,
      activeMap: this.activeMap,
      effectiveTechTransferRMP: this.effectiveTechTransferRMP,
      parentPropName: this.parentPropName,
    });
  }

  getRows() {
    throw new ImplementationNeededError("You need to implement the getRows method in the derived classes.");
  }

  wrapResults(records) {
    let sortedAndGrouppedRows = [];

    this.results.processes.forEach(process => {
      const processResults = {...this.results};
      const filterByProcess = (entityKey) => Object.values(processResults[entityKey])
        .filter(entity => entity.processId === process.id)
        .reduce((acc, curr) => (acc[curr.id] = curr, acc), {});
      const maps = ["uoMap", "mtMap", "ppMap", "prcMap", "stpMap"];

      maps.forEach(mapKey => {
        processResults[mapKey] = filterByProcess(mapKey);
      });

      const recordsIndexer = new RecordsIndexer({
        results: processResults
      }, this.activeMapName, this.modelName);

      const recordFromProcess = records.filter(record => record.processId === process.id);

      const sortedByOrderRows = recordsIndexer.fillInSortByOrderProperty(recordFromProcess);
      const groupedRows = recordsIndexer.groupRowsByUOAndStep(sortedByOrderRows);
      sortedAndGrouppedRows = sortedAndGrouppedRows.concat(groupedRows);
    });

    // attach formatted rows
    for (let row of sortedAndGrouppedRows.filter(groupedRow => groupedRow.techTransferredFrom)) {
      const techTransferredFrom = sortedAndGrouppedRows.find(groupedRow => groupedRow.LastVersionId === row.techTransferredFrom.LastVersionId);
      if (!techTransferredFrom) {
        continue;
      }

      row.techTransferredFrom = techTransferredFrom;
      // keeps the sorting index
      row.techTransferredFrom.unitOperations = row.techTransferredFrom.unitOperation;
      row.techTransferredFrom.steps = row.techTransferredFrom.step;
    }

    return sortedAndGrouppedRows;
  }
}
