"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { can, generateTooltip } from "../../utils/ui_permissions";
import CommonSecurity from "../../../server/common/generic/common_security";
import { ACTION_TO_ICON_ENUM } from "../../helpers/constants/constants";
import ImplementationNeededError from "../../utils/implementation_needed_error";
import { TYPE_CODE } from "../process_explorer_constants";
import { TYPE_HEADER } from "../adapters/diagram_results_adapter";

/**
 *  This class is responsible for creating the menu structure to add new items when a user clicks on a plus button
 *  in the process explorer.
 */
// i18next-extract-mark-ns-start process_explorer
const BUTTON_TYPE = {
  Main: "Main",
  Add: "Add",
  Copy: "Copy",
  Select: "Select",
};

export default class AddMenuBuilder {
  /**
   * @param typeCode {string} The item that was clicked on.
   * @param projectId {int} The id of the project.
   * @param onAdd {function} A function to call when the user is trying to add something.
   * @param t {*} Used to do translation.
   */
  constructor(typeCode, headerTypeCode, projectId, onAdd, t, onHeaderMenuClick) {
    this.typeCode = typeCode;
    this.headerTypeCode = headerTypeCode;
    this.projectId = projectId;
    this.t = t;
    this.onAdd = onAdd;
    this.options = [];
    this.onHeaderMenuClick = onHeaderMenuClick;
  }

  /**
   * Add a separator in the list.
   */
  addSeparator() {
    this.options.push({action: ACTION_TO_ICON_ENUM.SEPARATOR});
  }

  /**
   * Get the final list of options that can be passed to TableOptionsButton.
   *
   * @return {[]}
   */
  build() {
    if (this.typeCode === TYPE_HEADER) {
      return this.buildOptionForHeader();
    }

    switch (this.typeCode) {
      case TYPE_CODE.PROCESS:
        this.addOption(TYPE_CODE.UNIT_OPERATION);
        this.addSeparator();
        this.addMaterialOption();
        this.addOption(TYPE_CODE.PROCESS_COMPONENT);
        break;
      case TYPE_CODE.UNIT_OPERATION:
        this.addOption(
          TYPE_CODE.STEP,
          {
            icon: faChevronRight,
            buttonType: BUTTON_TYPE.Main,
          },
          [
            this.createOption(TYPE_CODE.STEP, {
              optionText: "Add new",
              buttonType: BUTTON_TYPE.Add
            }),
            {action: ACTION_TO_ICON_ENUM.SEPARATOR},
            this.createOption(
              TYPE_CODE.STEP,
              {
                optionText: "Copy from",
                optionSubText: "Copy from other project or process",
                onClickAction: (event) => {
                  this.onHeaderMenuClick(TYPE_CODE.STEP, ACTION_TO_ICON_ENUM.COPY.title, event);
                },
                buttonType: BUTTON_TYPE.Copy
              }
            )
          ]);
        this.addSeparator();
      // eslint-disable-next-line no-fallthrough
      case TYPE_CODE.STEP:
        this.addMaterialOption();
        this.addOption(TYPE_CODE.PROCESS_COMPONENT);
        this.addSeparator();
        this.addOption(TYPE_CODE.MATERIAL_ATTRIBUTE);
        this.addOption(TYPE_CODE.PROCESS_PARAMETER);
        this.addSeparator();
        this.addOption(TYPE_CODE.IQA);
        this.addOption(TYPE_CODE.IPA);
        break;
      case TYPE_CODE.MATERIAL:
      case TYPE_CODE.PROCESS_COMPONENT:
        this.addOption(TYPE_CODE.MATERIAL_ATTRIBUTE);
        this.addOption(TYPE_CODE.PROCESS_PARAMETER);
        break;
      default:
        throw new ImplementationNeededError();
    }
    return this.options;
  }

  addMaterialOption() {
    this.addOption(
      TYPE_CODE.MATERIAL,
      {
        icon: faChevronRight,
        buttonType: BUTTON_TYPE.Main,
      },
      [
        this.createOption(TYPE_CODE.MATERIAL, {
          optionText: "Add new",
          buttonType: BUTTON_TYPE.Add
        }),
        {action: ACTION_TO_ICON_ENUM.SEPARATOR},
        this.createOption(
          TYPE_CODE.MATERIAL,
          {
            optionText: "Select from Library",
            onClickAction: (event) => {
              this.onHeaderMenuClick(TYPE_CODE.MATERIAL, ACTION_TO_ICON_ENUM.ADD_FROM_LIBRARY.title, event);
            },
            buttonType: BUTTON_TYPE.Select
          }
        )
      ]);
  }

  buildOptionForHeader() {
    switch (this.headerTypeCode) {
      case TYPE_CODE.UNIT_OPERATION:
        this.addOption(TYPE_CODE.UNIT_OPERATION, {
          optionText: "Add new",
          buttonType: BUTTON_TYPE.Add
        });
        this.addSeparator();
        this.addOption(
          TYPE_CODE.UNIT_OPERATION,
          {
            optionText: "Copy from",
            optionSubText: "Copy from other project or process",
            onClickAction: (event) => {
              this.onHeaderMenuClick(TYPE_CODE.UNIT_OPERATION, ACTION_TO_ICON_ENUM.COPY.title, event);
            },
            buttonType: BUTTON_TYPE.Copy
          },
        );
        break;
      case TYPE_CODE.MATERIAL:
        this.addOption(TYPE_CODE.MATERIAL, {
          optionText: "Add new",
          buttonType: BUTTON_TYPE.Add
        });
        this.addSeparator();
        this.addOption(
          TYPE_CODE.MATERIAL,
          {
            optionText: "Select from Library",
            onClickAction: (event) => {
              this.onHeaderMenuClick(TYPE_CODE.MATERIAL, ACTION_TO_ICON_ENUM.ADD_FROM_LIBRARY.title, event);
            },
            buttonType: BUTTON_TYPE.Add
          },
        );
        break;
      default:
        this.addOption(this.headerTypeCode, {
          optionText: "Add new",
          buttonType: BUTTON_TYPE.Add
        });
    }
    return this.options;
  }

  addOption(typeCode, customOptions, childOptions) {
    this.options.push(this.createOption(typeCode, customOptions, childOptions));
  }

  createOption(typeCode, customOptions = {}, childOptions) {
    const modelName = UIUtils.findModelNameForTypeCode(typeCode);
    const {buttonType, optionText, optionSubText, onClickAction, icon} = customOptions;
    let displayName;

    switch (typeCode) {
      case "MT":
        displayName = "Material (MT)";
        break;
      case "MA":
        displayName = "Material Attribute (MA)";
        break;
      case "PRC":
        displayName = "Process Component (PRC)";
        break;
      case "PP":
        displayName = "Process Parameter (PP)";
        break;
      case "STP":
        displayName = "Step (STP)";
        break;
      case "IQA":
        displayName = "Intermediate Quality Attribute (IQA)";
        break;
      case "IPA":
        displayName = "Intermediate Performance Attribute (IPA)";
        break;
      case "UO":
        displayName = "Unit Operation (UO)";
        break;
    }

    return {
      action: {
        title: optionText || displayName,
        rightIcon: icon,
      },
      id: `processExplorer${buttonType ? buttonType : "Add"}-${typeCode}`,
      title: optionText || this.t(generateTooltip(CommonSecurity.Actions.ADD, modelName)),
      disabled: !can(CommonSecurity.Actions.ADD, modelName),
      onClick: onClickAction ? onClickAction :
        childOptions ? (event) => {
          event.preventDefault();
        } : () => this.onAdd(typeCode),
      subTitle: optionSubText,
      childOptions,
    };
  }
}
// i18next-extract-mark-ns-stop process_explorer
