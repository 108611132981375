"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseReactComponent from "../../base_react_component";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import { PDFCache } from "./rendering/pdf_cache";
import { PDFDocumentBuilder } from "./rendering/pdf_document_builder";
import { PDFDocumentRenderer } from "./rendering/pdf_document_renderer";
import { PDFPageRenderer } from "./rendering/pdf_page_renderer";

const Logger = Log.group(LOG_GROUP.Documents, "PDFDocument");

export const DOM_UPDATE_TIMEOUT = 100;

/**
 * This is responsible for displaying a pdf in a div.
 */
export default class PDFDocument extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.canvas = null;

    this.viewerArea = React.createRef();

    this.isRendered = false;
    this.isLoaded = false;

    this.parentContainer = React.createRef();

    this.lastDocumentSource = null;
    this.lastVisiblePage = null;
    this.document = null;

    this.postRenderQueue = [];

    /**
     * @type {JQuery}
     */
    this._parentJQueryContainer = null;

    this.documentRenderer = new PDFDocumentRenderer(this.parentContainer, new PDFPageRenderer(this.parentContainer));

    this.lastVisibleSource = null;
  }

  get parentJQueryContainer() {
    if (!this._parentJQueryContainer) {
      this._parentJQueryContainer = $(this.parentContainer.current);
    }
    return this._parentJQueryContainer;
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const {
      documentSource,
      zoomLevel,
      panelSize,
      currentPage,
      showingState,
      isVisible,
    } = this.props;

    const {windowSize} = this.state;

    const nextDocumentSource = nextProps.documentSource;
    const nextZoomLevel = nextProps.zoomLevel;
    const nextPanelSize = nextProps.panelSize;
    const nextPage = nextProps.currentPage;
    const nextShowingState = nextProps.showingState;
    const nextIsVisible = nextProps.isVisible;

    const nextWindowSize = nextState.windowSize;

    const shouldUpdate = !!(
      zoomLevel !== nextZoomLevel
      || panelSize !== nextPanelSize
      || documentSource !== nextDocumentSource
      || currentPage !== nextPage
      || showingState !== nextShowingState
      || isVisible !== nextIsVisible
      || windowSize !== nextWindowSize
    );

    if (!PDFCache.isSameSource(this.lastVisibleSource, nextDocumentSource)) {
      this.isRendered = false;
    }

    if (this.isDocLoading) {
      const loadedDocument = {
        pageCount: this.document && this.document.numPages || 0,
        fileData: this.props.fileData,
        document: this.document,
      };
      this.props.onLoaded(loadedDocument);
      this.isDocLoading = false;
    }

    if (!this.isRendered) {
      return shouldUpdate;
    }

    if (shouldUpdate) {
      this.delayPDFRender(true);
    }
    return false;
  }

  componentDidMount() {
    super.componentDidMount();
    const $window = $(window);

    $window.resize(() => {
      let width = $window.width();
      let height = $window.height();

      if (!this.documentRenderer.renderingInProcess) {
        this.setStateSafely({windowSize: {width, height}});
      }
    });
    this.delayPDFRender();
  }

  componentDidUpdate() {
    this.delayPDFRender(this.isRendered);
  }

  delayPDFRender(forceReRender = false, enqueued = false, isCancel = false) {
    Logger.verbose("DelayPDFRender");
    if (this.documentRenderer.renderingInProcess) {
      this.documentRenderer.shouldCancelRender = true;

      if (forceReRender && !enqueued && !isCancel) {
        Logger.verbose("Enqueuing Render");
        this.documentRenderer.postRenderQueue.push(({isCancel}) => this.delayPDFRender(forceReRender, true, isCancel));
      } else {
        Logger.debug("Skipping rendering: ", Log.object(forceReRender), Log.object(enqueued), Log.object(isCancel));
      }
    } else {
      this.documentRenderer.renderingInProcess = true;
      clearTimeout(this.isScrolling);
      clearTimeout(this.renderTimeoutHandle);
      this.renderTimeoutHandle = setTimeout(async() => {
        Logger.verbose("Delaying render");
        await this.renderPDF();
      }, DOM_UPDATE_TIMEOUT);
    }
  }

  handleScroll() {
    const scrollContainer = this.parentContainer.current;

    const {scrollTop, scrollLeft} = scrollContainer;

    Logger.verbose(() => "PDF Document Scroll: ", Log.symbol(scrollTop), Log.symbol(scrollLeft));

    if (!this.documentRenderer.renderingInProcess) {
      this.props.onScroll(scrollTop, scrollLeft, false);

      clearTimeout(this.isScrolling);
      this.isScrolling = setTimeout(() => {
        if (!this.documentRenderer.renderingInProcess) {
          this.props.onScroll(scrollTop, scrollLeft, true);
        }
      }, 100);
    }
  }


  findVisiblePaperDiv(scrollTop = -1) {
    const scrollContainer = this.parentContainer.current;

    scrollTop = scrollTop >= 0 ? scrollTop : scrollContainer.scrollTop;

    let container = $(scrollContainer);
    let pages = container.find(".page").toArray();
    let visiblePage;

    for (let index = 1; index <= this.document.numPages; index++) {
      visiblePage = pages[index - 1];
      const isVisible = (
        visiblePage
        && scrollTop <= (visiblePage.offsetTop + visiblePage.clientHeight - scrollContainer.clientHeight * 0.1)
      );
      if (isVisible) {
        break;
      }
    }

    if (visiblePage && visiblePage !== this.lastVisiblePage) {
      Logger.debug(() => `Visible page: ${Log.symbol(visiblePage.offsetTop + visiblePage.clientHeight)} ${Log.symbol(scrollTop)}`);
      this.lastVisiblePage = visiblePage;
    }
    return visiblePage;
  }

  scaleView(scaleFactor) {
    const {zoomLevel} = this.state;

    const paper = this.findVisiblePaperDiv();
    const parent = this.parentContainer.current;

    if (paper) {
      let newScrollTop = paper.offsetTop * scaleFactor;
      let newScrollLeft = paper.offsetLeft * scaleFactor;
      let newZoomLevel = zoomLevel * scaleFactor;
      parent.scrollTop = newScrollTop;
      parent.scrollLeft = newScrollLeft;

      this.setStateSafely({
        zoomLevel: newZoomLevel,
      }, () => {
        this.setScrollPosition(newScrollTop, newScrollLeft);
      });
    } else {
      Logger.warn("No page found. Cannot scale view.");
    }
  }

  setScrollPosition(scrollTop, scrollLeft, enqueued = false) {
    const wasRenderingInProcess = this.documentRenderer.renderingInProcess;

    const parent = this.parentContainer.current;
    parent.scrollTop = scrollTop;
    parent.scrollLeft = scrollLeft;

    // if the rendering is still happening, we scroll again in the end of the rendering to ensure we're in the right place
    if (wasRenderingInProcess && !enqueued) {
      this.documentRenderer.postRenderQueue.push(() => this.setScrollPosition(scrollTop, scrollLeft, true));
    }
  }

  goToPage(number, enqueued = false) {
    if (this.documentRenderer.renderingInProcess) {
      if (!enqueued) {
        Logger.debug("Rendering still in progress. Enqueuing moving to page");
        this.documentRenderer.postRenderQueue.push(() => this.goToPage(number, true));
      } else {
        Logger.verbose("Rendering still in progress, but already enqueued. Skipping.");
      }
    } else {
      const page = $(`[data-page-number=${number}]`)[0];

      if (page) {
        this.setScrollPosition(page.offsetTop, 0, enqueued);
        this.delayPDFRender(true);
      } else {
        Logger.warn(`Page ${number} not found in PDF.`);
      }
    }
  }

  async getDocument(src) {
    const {
      onLoaded,
      fileData,
      onLoading,
      pdfCache,
    } = this.props;

    if (!PDFCache.isSameSource(src, this.lastVisibleSource)) {
      await pdfCache.invalidateRenderCache(src);
    }

    if (src && (!this.document || src !== this.lastDocumentSource)) {
      this.isDocLoading = true;
      onLoading({fileData, message: "Loading PDF document..."});
      let document;
      Logger.debug(() => "Loading PDF document", Log.object(src));
      document = await pdfCache.getDocument(src);
      this.lastDocumentSource = src;
      this.document = document;

      onLoaded({pageCount: document.numPages, fileData, document});
      this.isDocLoading = false;
    }
    return this.document;
  }

  findFocusedPage(scrollTop = -1) {
    let result = 1;
    const page = this.findVisiblePaperDiv(scrollTop);
    if (page) {
      result = page.getAttribute("data-page-number");
    } else {
      Logger.warn("No page found. Cannot find focused page.");
    }
    return result;
  }

  async renderPDF() {
    Logger.verbose("Render PDF");

    const {
      maxPDFPages,
      documentSource,
      maxWidth,
      isVisible,
      zoomLevel,
      currentPage,
      onReady,
      fileData,
      onRendering,
      onRenderCompleted,
      onPageRendered,
      onCalculateHeight,
      onValidationError,
      onCriticalError,
    } = this.props;

    try {
      let parentComponent = this.parentContainer;

      if (!parentComponent || !parentComponent.current) {
        throw new Error("'parentContainer' must be a valid react component ref");
      }

      this._parentDOMContainer = $(parentComponent.current);
      let document = this.document;

      if (documentSource && this._isMounted && this.parentContainer.current) {
        document = await this.getDocument(documentSource);

        const onReadyHandler = (status) => onReady({...status, fileData, document});

        const builder = new PDFDocumentBuilder(this.parentContainer)
          .setViewerArea(this.viewerArea)
          .setVisibility(isVisible)
          .setZoomLevel(zoomLevel)
          .setCancellationHandler(() => this.documentRenderer.shouldCancelRender)
          .setOnRenderingHandler(onRendering)
          .setOnDocumentRenderedHandler(onRenderCompleted)
          .setOnPageRenderedHandler(onPageRendered)
          .setOnCalculateHeightHandler(onCalculateHeight)
          .setOnReadyHandler(onReadyHandler)
          .setMaxPages(maxPDFPages)
          .setDocument(document)
          .setMaxPageWidth(maxWidth)
          .setRenderStatus(this.isRendered)
        ;

        document = builder.build();

        Logger.debug("Starting document render: ", Log.object(document));
        this.document = await this.documentRenderer.render(document, {currentPage});

        if (isVisible && !PDFCache.isSameSource(documentSource, this.lastVisibleSource)) {
          this.lastVisibleSource = documentSource;
        }
      }
    } catch (error) {
      if (error.name === "InvalidPDFException") {
        const validationError = new Error("PDF file too large or corrupted.");
        error.doNotSendStackTrace = true;
        onValidationError(validationError);
      } else {
        onCriticalError(error);
      }
    } finally {
      await this.documentRenderer.endRender(this.document, {currentPage});
    }
  }

  render() {
    const {
      name,
      className,
      isVisible,
      documentSource,
    } = this.props;

    this.isRendered = true;

    if (isVisible && !PDFCache.isSameSource(documentSource, this.lastVisibleSource)) {
      this.lastVisibleSource = documentSource;
    }

    return (
      <div
        className={`document-preview-container ${className} ${isVisible ? "" : "d-none"}`}
        ref={this.parentContainer}
        onScroll={this.handleScroll}
      >
        <div id={`${name}PDFViewerDiv`}
             className={"document-preview-area"}
             ref={this.viewerArea}
        />
      </div>
    );
  }
}

PDFDocument.defaultProps = {
  isVisible: true,
  name: "current",
  className: "",
  zoomLevel: 100,
  maxPDFPages: 1000,
  totalPages: 1,
  renderer: "canvas",
  currentPage: 1,
  preloadedPages: 3,
  maxWidth: 1024,
  fileData: null,
  /**
   * @type {PDFCache}
   */
  pdfCache: PDFCache.getDefault(),
  onLoading: (status) => {
    UIUtils.showLoadingImage(status.message || "Loading PDF document");
  },
  onLoaded: (ignored) => {
  },
  onReady: (ignored) => {
    UIUtils.hideLoadingImage();
  },
  onRendering: (status) => {
    Logger.verbose(() => "Render progress reported", Log.object(status));
  },
  onRenderCompleted: (status) => {
    Logger.verbose(() => "Render completed", Log.object(status));
  },
  onValidationError: (error) => {
    UIUtils.defaultFailFunction(error);
  },
  onCriticalError: (error) => {
    UIUtils.defaultFailFunction(error);
  },
  onPageRendered: () => {
  },
  onScroll: () => {
  },
  onCalculateHeight: () => {
    return "100%";
  },
};
