"use strict";

import { getURLByTypeCodeAndId } from "../../../helpers/url_helper";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import React, { memo, useMemo } from "react";
import * as styles from "./style/name_cell.module.scss";
import * as commonStyles from "./style/common_cell.module.scss";
import { addProjectParameterToUrl, buildOptions } from "./cells_helper";
import TableOptionsMenu from "../../widgets/table_options_menu";

const NameTooltip = ({dataIndex, name, content, url}) => {
  return (
    <OverlayTrigger placement="top"
                    overlay={
                      <Tooltip id={`nameTooltip_${dataIndex}`}
                               className={`${commonStyles["field-tooltip"]} ${styles["name-tooltip"]}`}
                      >
                        {content}
                      </Tooltip>
                    }
                    delay={{show: 400, hide: 200}}
    >
      <a href={url}
         rel="noopener noreferrer"
         target="_blank"
         className={styles["name-link"]}
         data-dataitemname={name}
      >
        {name}
      </a>
    </OverlayTrigger>
  );
};

export const NameCell = memo((props) => {

  const {
    t,
    dataItem,
    style,
    dataIndex,
    className,
    onCheckPermissions,
    handleArchiveButton,
    handleRestoreButton
  } = props;

  if (!onCheckPermissions && !handleArchiveButton && !handleRestoreButton) {
    throw Error(`The component must accept [onCheckPermissions, handleArchiveButton, handleRestoreButton] operation handlers`);
  }

  const {id, typeCode, currentState, name} = dataItem;
  const recordURL = getURLByTypeCodeAndId(typeCode, "View", id) + addProjectParameterToUrl();

  const options = useMemo(() => {
    return buildOptions(dataItem, dataIndex, onCheckPermissions, handleArchiveButton, handleRestoreButton, t);
  }, [dataIndex, currentState]);

  return (
    <td style={style}
        className={className}
    >
      <div className={styles["cell-container"]} data-dataitemname={name} data-dataindex={dataIndex}>

        <div className={styles["content-zone"]}>
          <NameTooltip dataIndex={dataIndex}
                       name={name}
                       content={`${name} ${typeCode}-${id}`}
                       url={recordURL}
          />
          <span className={styles["dataItem-id"]}>
            {typeCode}-{id}
          </span>
        </div>

        <div className={`${styles["actions-zone"]} ${styles["actions-zone-container"]}`}>
          <a href={recordURL}
             rel="noopener noreferrer"
             target="_blank"
             className={styles["chevron-link"]}
          >
            <FontAwesomeIcon icon={faChevronRight}
                             className={styles["link-icon"]}
            />
          </a>
          <TableOptionsMenu id={dataIndex}
                            typeCode={typeCode}
                            key={dataIndex}
                            iconOrientation="horizontal"
                            alignRight={true}
                            options={options}
          />
        </div>

      </div>
    </td>
  );
});
