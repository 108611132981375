"use strict";

import * as UIUtils from "../../../../../../../ui_utils";
import { FILTER_TYPES } from "../../../../../../columnOperations/configurable_tables_column_operations_constants";
import { formatDate } from "../../../../../../fieldsConfig/fields_formatter";
import { createHtmlElement } from "../../../../../cells/cells_helper";
import * as filterStyles from "./filter.module.scss";
import HtmlBuilder from "./html_builder";
import { pushHistoryURLWithParameterChanges } from "../../../../../configurable_tables_helper";

function getPills(filters = [], visibleTableColumns, operationsAdapter) {

  $(".filter-selected-entry").removeClass("filter-selected-entry");

  return filters.map(({filters}) => {

    const {field} = filters[0];
    const filteredColumn = operationsAdapter.getFieldInformation(visibleTableColumns, field);

    if (filteredColumn) {
      const filterValues = filters.map(({value}) => value || "Empty");
      const fieldClass = UIUtils.convertToCamelCaseId(field);

      let value;
      if (filteredColumn && filteredColumn.filterType === FILTER_TYPES.DATE) {
        value = formatDate(filterValues[0]);
      } else {
        value = filterValues.length > 1 ? filterValues.join(", ") : filterValues;
      }

      setImmediate(() => $(`.${fieldClass}`).addClass("filter-selected-entry"));

      return {field, value, fieldClass};
    }

  }).filter(Boolean);
}

module.exports.buildFilters = function buildFilters(div, filter = {}, visibleTableColumns, operationsAdapter) {

  const {filters = []} = filter;

  const filterPills = getPills(filters, visibleTableColumns, operationsAdapter);

  const widthList = [];
  const filterBoxMap = new Map();

  const container = createHtmlElement("div", filterStyles["filter-entry"], document.body, div.offsetWidth);
  for (const filterPill of filterPills) {
    const {field, value} = filterPill;

    const filterContainer = new HtmlBuilder("div", filterStyles["filter-entry"], container, null)
      .addChild("span", filterStyles["field"], field, null)
      .addChild("span", filterStyles["separator"], "·", null)
      .addChild("span", filterStyles["value"], value, null)
      .addChild("span", filterStyles["close-btn"], "+", 17)
      .build();

    const {offsetWidth} = filterContainer;
    widthList.push(offsetWidth);
    filterBoxMap.set(field, {field, value, width: offsetWidth});
    filterContainer.remove();
  }
  container.remove();

  const CLEAR_PILL_WIDTH = 60;
  const SHOW_REMAINING_WIDTH = 95;
  const FLEX_GAP = (widthList.length - 1) * 8;

  let overallWidth = widthList.reduce((result, value) => result + value, 0);
  const moreFilters = new Map();

  let showMoreWidth = 0;
  const boxes = Array.from(filterBoxMap.entries());

  while (overallWidth + CLEAR_PILL_WIDTH + FLEX_GAP + showMoreWidth > div.offsetWidth && boxes.length) {
    const [field, filter] = boxes.pop();
    const {width} = filter;
    overallWidth -= width;

    if (showMoreWidth === 0) {
      showMoreWidth = SHOW_REMAINING_WIDTH;
    }

    moreFilters.set(field, filter);
  }

  return {
    filters: boxes.map(([, value]) => value),
    more: [...moreFilters.values()],
  };
};

module.exports.stringifyFiltersForURL = function(tableState, filters) {
  const {sort, filter, skip, take} = tableState;

  if (filters) {
    const encodedFilter = filter ? encodeURIComponent(JSON.stringify(filter)) : filter;
    pushHistoryURLWithParameterChanges({
      sort: JSON.stringify(sort),
      filter: encodedFilter,
      paging: JSON.stringify({skip, take})
    });
  }
};
