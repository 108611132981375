"use strict";

import React, { useEffect, useState } from "react";
import ToggleInput from "react-switch";
import * as UIUtils from "../../ui_utils";
import { stripAllWhitespaces } from "../../ui_utils";

export const ShowArchiveToggle = ({t, type, onShowArchiveChange}) => {

  const [
    isShowArchived,
    setShowArchived
  ] = useState(UIUtils.getParameterByName("showArchived") === "true" || false);

  useEffect(() => {
    onShowArchiveChange(isShowArchived);
  }, []);

  const onChange = (toggle) => {
    setShowArchived(toggle);
    onShowArchiveChange(toggle);
  };

  return (
    <div className="show-archived-div">
      <ToggleInput id={`showArchived${stripAllWhitespaces(type)}Input`}
                   className="show-archived-checkbox-toggle"
                   checked={isShowArchived}
                   onChange={onChange}
                   height={15}
                   width={30}
                   checkedIcon={false}
                   uncheckedIcon={false}
                   activeBoxShadow="0 0 2px 3px #014768"
                   onColor="#DBE1E4"
                   offColor="#DBE1E4"
                   onHandleColor="#1F46A1"
      />
      <span>{t("Show Archived")}</span>
    </div>
  );
};