"use strict";


const DEFAULT_ID = "DEFAULT";
const IGNORE_ID = "IGNORE";
const CREATE_ID = "CREATE";

/*
  Common constants used for the library material in both client and server side.
 */

module.exports.getChangedAttributes = function(material, libraryMaterial) {

  const libraryAttributes = new Map(
    [
      ["name", "Name"],
      ["category", "Category"],
      ["gmp", "GMP"],
      ["description", "Description"],
      ["SupplierId", "Supplier"],
      ["partNumber", "Supplier Part Number"],
      ["materialQualified", "Material/Part Qualified"],
      ["internalPartNumber", "Internal Part Number"],
      ["effectiveDate", "Effective Date"],
      ["expirationDate", "Expiration Date"],
      ["qualificationLinks", "Qualification Supporting Documents"],
      ["regulatoryFiling", "Regulatory Filing"],
      ["referenceNumber", "Reference Number"],
      ["authorizationLetter", "Letter of Authorization"],
      ["regulatoryLinks", "Regulatory Supporting Documents"],
      ["chemicalStructure", "Chemical Structure"],
      ["empiricalFormula", "Empirical Formula"],
      ["molecularWeight", "Molecular Weight"],
      ["form", "Form"],
      ["density", "Density"],
      ["densityConditions", "Density Conditions"],
      ["compendialStandard", "Compendial Standard"],
      ["casRegistryNumber", "CAS Registry Number"],
      ["certificateOfAnalysis", "COA/COC"],
      ["propertiesLinks", "Properties Supporting Documents"],
      ["otherNames", "Other Names"],
      ["innUsan", "INN/USAN"],
      ["chemicalNameCAS", "Chemical Name (CAS)"],
      ["chemicalNameIUPAC", "Chemical Name (IUPAC)"],
      ["drugSubstanceType", "Drug Substance Type"],
      ["referencesLinks", "References & Standards"],
    ]
  );

  const mappedAttributes = new Map([
    ["description", "libraryDescription"]
  ]);

  const changedAttributes = [];
  if (!material || !libraryMaterial) {
    return changedAttributes;
  }

  // Fix the format of SupplierId before processing.
  if (!libraryMaterial.SupplierId && libraryMaterial.Supplier) {
    libraryMaterial.SupplierId = libraryMaterial.Supplier.id;
  }

  for (let [attribute, label] of libraryAttributes.entries()) {
    let mappedAttribute = mappedAttributes.get(attribute) || attribute;
    if (material[mappedAttribute] !== libraryMaterial[attribute]) {
      changedAttributes.push({attribute: mappedAttribute, label, newValue: libraryMaterial[attribute]});
    }
  }

  return changedAttributes;
};

module.exports.getChangedSpecificationAttributes = function(specification, materialAttribute) {

  const libraryAttributes = new Map(
    [
      ["specification", "Specification"],
      ["measure", "Measure"],
      ["measurementUnits", "Measurement Units"],
      ["lowerLimit", "Lower Limit"],
      ["upperLimit", "Upper Limit"],
      ["target", "Target"],
    ]
  );

  const attributesMapping = new Map(
    [
      ["specification", "name"],
      ["measure", "measure"],
      ["measurementUnits", "measurementUnits"],
      ["lowerLimit", "lowerLimit"],
      ["upperLimit", "upperLimit"],
      ["target", "target"],
    ]
  );

  const changedAttributes = [];
  if (!specification || !materialAttribute) {
    return changedAttributes;
  }

  for (let [attribute, label] of libraryAttributes.entries()) {
    const attributeMapping = attributesMapping.get(attribute);
    if (specification[attribute] !== materialAttribute[attributeMapping]) {
      changedAttributes.push({attributeMapping, label, newValue: specification[attribute]});
    }
  }

  return changedAttributes;
};

module.exports.isSyncActionID = function(id) {
  return (id === DEFAULT_ID || id === CREATE_ID || id === IGNORE_ID);
};

module.exports.FROM_LIBRARY_STATUS = {
  SYNCED: "Synced",
  LINKED: "Linked",
  PROJECT_ONLY: "Project Only"
};

module.exports.DEFAULT_ID = DEFAULT_ID;
module.exports.CREATE_ID = CREATE_ID;
module.exports.IGNORE_ID = IGNORE_ID;