const MODELS_WITH_CONTROL_METHODS = [
  "ProcessParameter",
  "MaterialAttribute"
];

const MODELS_WITH_ACCEPTANCE_CRITERIA = [
  "ProcessParameter",
  "MaterialAttribute",
  "IQA",
  "IPA",
  "FQA",
  "FPA"
];

const PROCESS_MODELS_WITH_ACCEPTANCE_CRITERIA = [
  "Process Parameters",
  "Material Attributes",
  "IQAs",
  "IPAs",
];

const MODELS_WITH_CONTROL_SUPPLIERS = [
  "ProcessComponent",
  "Material",
  "LibraryMaterial",
  "UnitOperation",
  "ControlMethod",
];

const STANDARD_ATTRIBUTES = [
  "id",
  "name",
  "deletedAt",
  "LastVersionId",
  "currentState"
];

const RECORD_ORDER_MODELS = [
  "UnitOperation",
  "Step",
];

const PROCESS_LEVEL_MODEL_TYPES = [
  "ProcessParameter",
  "MaterialAttribute",
  "ProcessComponent",
  "Material",
  "UnitOperation",
  "Step",
  "IQA",
  "IPA",
];

const TECH_TRANSFER_ENABLED_MODELS = [
  "ProcessParameter",
  "MaterialAttribute",
  "ProcessComponent",
  "Material",
  "IQA",
  "IPA"
];

const TECH_TRANSFER_MODELS_WITH_TRANSFERRED_FROM = [
  ...TECH_TRANSFER_ENABLED_MODELS,
  "UnitOperation",
  "Step",
];

const TECH_TRANSFER_ENABLED_VARIABLES = [
  "ProcessParameter",
  "MaterialAttribute",
  "IQA",
  "IPA"
];

const TECH_TRANSFER_ENABLED_VARIABLE_PARENTS = [
  "ProcessComponent",
  "Material"
];

module.exports.MODELS_WITH_CONTROL_METHODS = MODELS_WITH_CONTROL_METHODS;
module.exports.MODELS_WITH_CONTROL_SUPPLIERS = MODELS_WITH_CONTROL_SUPPLIERS;
module.exports.STANDARD_ATTRIBUTES = STANDARD_ATTRIBUTES;
module.exports.PROCESS_LEVEL_MODEL_TYPES = PROCESS_LEVEL_MODEL_TYPES;
module.exports.PROCESS_MODELS_WITH_ACCEPTANCE_CRITERIA = PROCESS_MODELS_WITH_ACCEPTANCE_CRITERIA;
module.exports.RECORD_ORDER_MODELS = RECORD_ORDER_MODELS;
module.exports.TECH_TRANSFER_ENABLED_MODELS = TECH_TRANSFER_ENABLED_MODELS;
module.exports.TECH_TRANSFER_ENABLED_VARIABLES = TECH_TRANSFER_ENABLED_VARIABLES;
module.exports.TECH_TRANSFER_ENABLED_VARIABLE_PARENTS = TECH_TRANSFER_ENABLED_VARIABLE_PARENTS;
module.exports.TECH_TRANSFER_MODELS_WITH_TRANSFERRED_FROM = TECH_TRANSFER_MODELS_WITH_TRANSFERRED_FROM;
module.exports.MODELS_WITH_ACCEPTANCE_CRITERIA = MODELS_WITH_ACCEPTANCE_CRITERIA;
