"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import BasePage from "../../base_page";
import PageFrameLayout from "../../widgets/layout/page_frame_layout";
import TrainingOnboardingStep from "./widgets/training_onboarding_step";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import {
  TRAINING_ONBOARDING_TABS,
  USER_TABS_ENUM,
  ONBOARDING_STATUS_TO_NEXT_STATE,
} from "../../helpers/constants/constants";
import CommonTraining from "../../../server/common/editables/common_training";

const TRAINING_ONBOARDING_STEPS = [
  {
    action: "Disable",
    key: "DisableNotifications",
    title: "Disable Training Notifications",
    description:
      <span>This option will disable all user notifications around training assignments.
        It is intended to be used during onboarding when adding historical training.
        When disabled the system <b>will not</b> send notifications about assigned training to end-users.</span>,
  },
  {
    action: "Confirm",
    key: "Confirmation",
    title: "Training Assigned Confirmation",
    description: "By clicking the Confirm button, you are confirming that all documents have been uploaded in bulk as part of the onboarding activities and these documents have been assigned for training via assignment to Curricula or to Individual Training Plans.",
  },
  {
    action: "Update All",
    key: "UpdateAll",
    title: "Mark All Assigned and Pending Training as Not Tracked",
    description: <span>This action will change all Pending training in the system to "Not Tracked"
      and mark assigned training as "Not Tracked" meaning that training is recorded outside of QbDVision.
      This action should only be used for onboarding purposes.
      To review training status and see what will be updated with this action,
      please use the <a target="_blank"
                        href={UIUtils.getSecuredURL("/reports/cannedReports/base.html?reportType=TrainingMatrixReport")} rel="noreferrer"
      >
        Training Matrix Report</a>.
      This action cannot be undone. Any training already marked as complete will be skipped.</span>,
  },
  {
    action: "Enable",
    key: "EnableNotifications",
    title: "Enable Training Notifications",
    description: "This is the last step. It enables all user notifications around training assignments. Training notifications should be enabled after onboarding has been completed so that the system can send notifications about assigned training to end-user.",
  },
];

// i18next-extract-mark-ns-start training
/**
 * This class shows the training onboarding page
 */
class TrainingOnboarding extends BasePage {
  constructor(props) {
    super(props);

    this.setStateSafely(this.getDefaultState());

    this.handleDisableNotifications = this.handleDisableNotifications.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.handleUpdateAll = this.handleUpdateAll.bind(this);
    this.handleEnableNotifications = this.handleEnableNotifications.bind(this);
    this.handleOnboardingStatusReceived = this.handleOnboardingStatusReceived.bind(this);

    UIUtils.secureAjaxPUT("training/onboarding/get", {}, true)
      .done((status) => this.handleOnboardingStatusReceived(status));
  }

  componentDidMount() {
    document.title = "QbDVision Training Onboarding";

    super.componentDidMount();
  }

  getDefaultState() {
    return {
      isDisableNotificationsDisabled: false,
      isConfirmationDisabled: true,
      isUpdateAllDisabled: true,
      isEnableNotificationsDisabled: true,
    };
  }

  handleOnboardingStatusReceived(data) {

    if (data === CommonTraining.ONBOARDING_STATUS.NONE) {
      this.setStateSafely(this.getDefaultState());
    } else {
      let status = data.status;

      let currentState = ONBOARDING_STATUS_TO_NEXT_STATE.get(status);
      let otherStates = Array.from(ONBOARDING_STATUS_TO_NEXT_STATE.values())
        .filter(state => state !== currentState);

      let stateObject = {
        [currentState]: false,
      };

      for (let state of otherStates) {
        stateObject[state] = true;
      }
      this.setStateSafely(stateObject);
    }
  }

  handleDisableNotifications() {
    UIUtils.secureAjaxPUT("training/onboarding/disableNotifications",
      {}, true).done((status) => {
      this.handleOnboardingStatusReceived(status);
    });
  }

  handleConfirmation() {
    UIUtils.secureAjaxPUT("training/onboarding/confirmAssignment",
      {}, true).done((status) => {
      this.handleOnboardingStatusReceived(status);
    });
  }

  handleUpdateAll() {
    UIUtils.secureAjaxPUT("training/onboarding/updateAllTraining",
      {}, true).done((status) => {
      this.handleOnboardingStatusReceived(status);
    });
  }

  handleEnableNotifications() {
    UIUtils.secureAjaxPUT("training/onboarding/enableNotifications",
      {}, true).done((status) => {
      this.handleOnboardingStatusReceived(status);
    });
  }

  renderPage() {
    const {t} = this.props;
    const {loggedInUser} = this.state;

    return (
      <PageFrameLayout
        title={t("Training")}
        tabs={TRAINING_ONBOARDING_TABS}
        selectedTab={USER_TABS_ENUM.Onboarding}
        showLinksInTitleBar
      >
        <div>
          <div className="row">
            <h1>
              Training Onboarding Helper
            </h1>
          </div>
          <div className="row-white training-onboarding-container shadow">
            {loggedInUser.isAdmin ?
              <Fragment>
                <div className="alert alert-warning training-onboarding-warning">
                  Warning: This is a special tool to help with onboarding of training related information. Please ensure you've consulted with a CCS team member prior to using it.
                </div>
                {
                  TRAINING_ONBOARDING_STEPS
                    .map(step => {
                      const {key} = step;
                      return (
                        <TrainingOnboardingStep
                          id={`${UIUtils.convertToCamelCaseId(key)}Button`}
                          disabled={this.state[`is${key}Disabled`]}
                          handler={this[`handle${key}`]}
                          {...step} />
                      );
                    })
                }
              </Fragment> :
              <div id="onboardingAlertDiv" className="alert alert-danger">
                Access denied.
              </div>}
          </div>
        </div>
      </PageFrameLayout>
    );
  }
}

export default I18NWrapper.wrap(TrainingOnboarding, "training");
// i18next-extract-mark-ns-stop training
