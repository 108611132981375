"use strict";

import * as UIUtils from "../../../../ui_utils";
import CommonTraining from "../../../../../server/common/editables/common_training";
import { BaseTrainingDataTransform } from "./base_training_data_transform";

/**
 * This class is responsible for converting the data returned from the server into JSON format Stimulsoft needs
 * to create the Training Matrix Report.
 */
export class TrainingMatrixReportTransform extends BaseTrainingDataTransform {

  get type() {
    return "individualTrainingReport";
  }

  shouldRun(options) {
    UIUtils.Ensure.virtual("shouldRun", {options});

    return true;
  }

  transform(input, options) {
    UIUtils.Ensure.virtual("transform", {options});

    let data = input.instances.reportData;
    let {instances, users} = data;
    let results = [];

    instances = CommonTraining.getDocumentsLatestVersionsForMatrix(instances);
    let uniqueDocumentIds = new Set(instances.map(instance => instance.documentId));
    const modelName = "Document";

    for (let instance of instances) {
      let customId = UIUtils.getRecordCustomIdForDisplay({
        ...instance,
        id: instance.documentId,
        modelName,
      });

      let user = users.find(user => user.id === instance.userId);
      if (user) {
        const userName = `${user.firstName} ${user.lastName}`;
        let trainingStatus = this.getTrainingStatus(instance, instances);
        const incompleteStatus = this.getIncompleteStatus(instance, instances);
        const completionDate = DOMPurify.sanitize(trainingStatus);

        const itemToAdd = {
          ...instance,
          modelName,
          customId,
          userName: userName,
          department: user.department,
          completionDate,
          incomplete: incompleteStatus,
        };
        results.push(itemToAdd);
      }
    }

    for (let user of users.filter(user => instances.map(instance => instance.userId).includes(user.id))) {
      let instancesPerUser = instances.filter(instance => instance.userId === user.id);
      let instancesNotAssignedToUser = instancesPerUser.map(instance => instance.documentId);
      instancesNotAssignedToUser = Array.from(uniqueDocumentIds).filter(id => !instancesNotAssignedToUser.includes(id));

      for (let instanceId of instancesNotAssignedToUser) {
        let instance = instances.find(instance => instance.documentId === instanceId);
        let customId = instance.customId || UIUtils.getRecordCustomIdForDisplay({
          ...instance,
          id: instance.documentId,
          modelName,
        });

        results.push({
          ...instance,
          customId,
          userName: `${user.firstName} ${user.lastName}`,
          department: user.department,
          completionDate: "N/A",
          incomplete: "None"
        });
      }
    }

    return {
      instances: results,
      isEmptySummary: instances.length === 0,
    };
  }
}
