"use strict";

import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownAZ,
  faArrowDownWideShort,
  faArrowUpAZ,
  faArrowUpWideShort
} from "@fortawesome/free-solid-svg-icons";
import { GridColumnMenuWrapper } from "@progress/kendo-react-grid";
import { onColumnActionsClick, setColumnOperationIcon, toggleMenuActiveClass } from "./cells_helper";
import * as commonStyles from "./style/common_cell.module.scss";
import { PopupPropsContext } from "@progress/kendo-react-popup";
import useAdjustHeight from "../hooks/use_adjust_height";
import { addWindowListener, removeWindowListener } from "../configurable_tables_helper";

const getSortMetadata = (props, isSortingByOrder) => {
  const isSorted = props?.children?.[0];
  let isAsc = true;  // default to ascending

  if (isSorted) {
    isAsc = isSorted.props.className === "k-icon k-i-sort-asc-small";
  }

  const sortConfig = {
    order: {
      asc: {
        icon: faArrowDownWideShort,
        title: "Sorted by Order (First to Last)"
      },
      desc: {
        icon: faArrowUpWideShort,
        title: "Sorted by Order (Last to First)"
      },
      default: {
        icon: faArrowDownWideShort,
        title: "Click to Sort by Order (First to Last)"
      }
    },
    alphabetical: {
      asc: {
        icon: faArrowDownAZ,
        title: "Sorted Ascending (A-Z)"
      },
      desc: {
        icon: faArrowUpAZ,
        title: "Sorted Descending (Z-A)"
      },
      default: {
        icon: faArrowDownAZ,
        title: "Click to Sort Ascending (A-Z)"
      }
    }
  };

  const sortingMethod = isSortingByOrder ? "order" : "alphabetical";
  const sortType = isSorted ? (isAsc ? "asc" : "desc") : "default";

  const icon = sortConfig[sortingMethod][sortType].icon;
  const title = sortConfig[sortingMethod][sortType].title;
  const className = `${isSorted ? (isAsc ? "sort-asc" : "sort-desc") : ""}`;

  return {
    icon,
    title,
    className
  };
};

const PopupConfig = {
  anchorAlign: {
    "horizontal": "right",
    "vertical": "bottom"
  },
  popupAlign: {
    "horizontal": "right",
    "vertical": "top"
  },
  margin: {
    horizontal: 4,
    vertical: -6,
  }
};

export const HeaderCell = (props) => {

  const {isSortingByOrder} = props;
  const filterRef = useRef(null);
  const [isMenuActive, setMenuActive] = useState(false);

  useEffect(() => {
    toggleMenuActiveClass(filterRef, isMenuActive);
  }, [isMenuActive]);

  useEffect(() => {
    setColumnOperationIcon(filterRef);

    const onClick = onColumnActionsClick(filterRef, setMenuActive);
    addWindowListener("click", onClick);

    return () => removeWindowListener("click", onClick);
  }, []);


  const height = useAdjustHeight(filterRef, 75);
  const sortData = getSortMetadata(props, isSortingByOrder);

  const icon = (
    <FontAwesomeIcon
      title={sortData.title}
      icon={sortData.icon}
      className={`sort-icon ${sortData.className}`}
    />
  );

  return (
    <div className={commonStyles["header-cell"]}>
            <span ref={filterRef}>
                {props?.columnMenuWrapperProps?.columnMenu && (
                  <PopupPropsContext.Provider
                    value={(props) => ({...props, ...PopupConfig, style: {height}})}
                  >
                    <GridColumnMenuWrapper {...props.columnMenuWrapperProps} />
                  </PopupPropsContext.Provider>
                )}
            </span>
      <div className="k-link"
           onClick={props.onClick}
      >
        <div className={commonStyles["header-title"]}>
          <span className={sortData.className}>{props.title}</span>
        </div>
        {icon}
      </div>
    </div>
  );
};

