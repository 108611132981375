var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Draggable } from '@progress/kendo-react-common';
/**
 * @hidden
 */
var keys = ['n', 'e', 's', 'w', 'se', 'sw', 'ne', 'nw'];
/**
 * @hidden
 */
var ResizeHandlers = /** @class */ (function (_super) {
    __extends(ResizeHandlers, _super);
    function ResizeHandlers() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ResizeHandlers.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", null,
            " ",
            keys.map(function (key, index) {
                return (React.createElement(Draggable, { key: index, onDrag: function (data) {
                        var event = data.event;
                        event.originalEvent.preventDefault();
                        _this.props.onResize(event, { end: false, direction: key });
                    }, onRelease: function (data) {
                        var event = data.event;
                        event.originalEvent.preventDefault();
                        _this.props.onResize(event, { end: true, direction: key });
                    } },
                    React.createElement("div", { className: 'k-resize-handle k-resize-' + key, style: { display: 'block', touchAction: 'none' } })));
            })));
    };
    return ResizeHandlers;
}(React.Component));
export { ResizeHandlers };
