"use strict";

import ConfigurableTablesColumnSelectionHandler
  from "../../configurableTables/columnSelection/configurable_tables_column_selection_handler";
import BaseReactComponent from "../../base_react_component";

export default class TechTransferReviewScreenHandler extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.parent = props.parent;
    this.columnSelectionHandler = new ConfigurableTablesColumnSelectionHandler(props, true,
      "defaultTechTransferValue");
  }

  handleIgnoreChanges(ignoredChangesInfo) {
    const {sendingRecordId, receivingRecordId} = ignoredChangesInfo;
    let {loggedInUser, techTransferRows} = this.parent.state;
    const {fullName} = loggedInUser;

    techTransferRows = techTransferRows.map(row => {
      if (row.id === receivingRecordId && row.techTransferredFrom.id === sendingRecordId) {
        let changes = {
          ...ignoredChangesInfo,
          user: fullName,
          createdAt: Date(),
        };

        row.changes = changes;
        row.techTransferredFrom.changes = changes;
      }
      return row;
    });

    this.parent.setStateSafely({
      techTransferRows,
      ignoredChangesInfo,
    }, this.columnSelectionHandler.handleResetColumnVisibility);
  }

  handleTechTransferSave(sendingRecordId, receivingRecordId, techTransferUpdateInfo) {
    let {techTransferRows, reviewRecord,} = this.parent.state;

    techTransferRows = techTransferRows.map(row => {
      if (row.id === receivingRecordId && row.techTransferredFrom.id === sendingRecordId) {
        row = {
          ...row,
          ...techTransferUpdateInfo,
        };
      }
      return row;
    });

    this.parent.setStateSafely({
      reviewRecord: {
        ...reviewRecord,
        LastVersionId: techTransferUpdateInfo.LastVersionId,
      },
      techTransferRows,
      techTransferUpdateInfo,
    }, this.columnSelectionHandler.handleResetColumnVisibility());
  }

  handleHideReviewScreen() {
    this.parent.setStateSafely({
      shouldShowRightPanel: false,
    });
  }
}
