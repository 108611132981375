"use strict";

import React, { Fragment } from "react";
import AttributeMeasurementsEditor from "./attribute_measurements_editor";
import { PAPER_DATA_UPDATE_TYPE } from "../../../../constants/import_constants";
import { MODEL_DECLARATIONS } from "../../../../../../server/common/generic/common_models";
import BaseReactComponent from "../../../../../base_react_component";

/**
 * This is responsible for rendering the attributes measurements table in the AI import batch data edit screen.
 */
export default class BatchDataAttributesEditor extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  isLibraryMaterial() {
    return !this.props.batchData.projectId;
  }

  render() {
    const {etlData} = this.props;
    const addButtonDisabled = etlData.batchData.attributes.length >= etlData.dataAttributes.length;
    let attributeRowIndex = 0;

    return (
      <div className="attribute-measurements-container">
        <div className="row attribute-measurements-row-header">
          <div className="col-6">
            <span>{this.isLibraryMaterial() ? "Specifications" : "Attributes"}</span>
          </div>
          <div className="col-1">
            <span>LSL</span>
          </div>
          <div className="col-1">
            <span>USL</span>
          </div>
          <div className="col-4">
            <span>Results</span>
          </div>
          <div />
        </div>
        <div className="attribute-measurements-rows">
          <Fragment>
            {this.props.batchData.attributes.map(attribute => {
              return (
                <AttributeMeasurementsEditor key={attribute.uuid}
                                             index={attributeRowIndex++}
                                             disabled={this.props.disabled}
                                             attribute={attribute}
                                             typeCode={this.props.batchData.projectId ? MODEL_DECLARATIONS.MA.typeCode : MODEL_DECLARATIONS.SPECIFICATION.typeCode}
                                             dataAttributes={etlData.dataAttributes}
                                             onChange={this.props.onChange}
                />
              );
            })}
            <div className={"row add-attribute-row"}>
              <div className="col-12">
                <button title={addButtonDisabled ? this.getAddNewDescription() : this.getAddNewLabel()}
                        disabled={this.props.disabled || addButtonDisabled}
                        className="btn btn-xs btn-secondary add-attribute-button"
                        id="addNewAttributeButton"
                        onClick={this.props.onChange.bind(this, {
                          type: PAPER_DATA_UPDATE_TYPE.ADD_ATTRIBUTE
                        })}
                >
                  Add {this.isLibraryMaterial() ? "Specification" : "Attribute"}
                </button>
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    );
  }

  getAddNewLabel() {
    return "Add a new " + (this.isLibraryMaterial() ? "specification" : "attribute");
  }

  getAddNewDescription() {
    return this.isLibraryMaterial() ? "You cannot add more specifications than those defined in the selected Library Material." :
      "You cannot add more attributes than those defined in the selected Material.";
  }
}
