"use strict";

import { Button, DropdownButton } from "@qbdvision-inc/component-library";
import React, { memo } from "react";

const AddButton = ({t, table, options, onClick, isDisabled = false}) => {

  if (options.length === 1) {

    const [
      {
        title,
        disabled,
        key,
      }
    ] = options;

    return (
      <Button
        id={key}
        label={t("Add")}
        size={"small"}
        type="secondary"
        title={title}
        isDisabled={disabled}
        onClick={onClick}
      />
    );
  }

  return (
    <DropdownButton options={options}
                    id={`${table.toLowerCase()}ElementsAdd`}
                    iconPlacement={"right"}
                    type={"secondary"}
                    label={t("Add")}
                    isDisabled={isDisabled}
                    onOptionsSelect={onClick}
                    size="small"
    />
  );
};

export default memo(AddButton);