"use strict";

import React from "react";
import BasePopup from "./base_popup";

export default class WithdrawPopup extends BasePopup {
  constructor(props) {
    super(props);
  }

  handleCommentChange(event) {
    this.setStateSafely({text: event.target.value});
  }

  handleClick(event) {
    this.props.parent.handleApprovalChangeValues({
      "comment": this.state.text
    }, () => {
      this.props.onWithdraw(event);
    });
  }

  render() {
    return (
      <div className="modal fade" ref={this.setModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Withdraw</h1>
              <button type="button" className="close" onClick={this.handleCancel} aria-label="Close">
                <span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              <div className="modal-container">
                <div className="row">
                  <div className="col-sm-12">
                    Are you sure you want to withdraw the most recent proposal?
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label htmlFor="proposeComments">
                      Comments
                    </label>
                  </div>
                  <div className="col-sm-12">
                    <textarea id="proposeComments" rows="3" style={{width: "100%"}}
                              onChange={this.handleCommentChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="modal-container">
                <div className="btn-group">
                  <button id="withdrawButtonOnPopup" type="button" className="btn btn-primary"
                          onClick={this.handleClick}
                  >
                    Withdraw
                  </button>
                  <button id="cancelButton" type="button" className="btn btn-secondary"
                          onClick={this.handleCancel} data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
