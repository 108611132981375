"use strict";

import React, { useEffect, useReducer } from "react";
import CommonEditablesPageTitleBar from "../../../../widgets/pageTitleBar/common_editables_page_title_bar";
import StepWizard from "react-step-wizard";
import { ProjectMaterialWizardNavBar } from "./project_material_wizard_nav_bar";
import useLibraryMaterials from "../../../hooks/use_library_materials_hook";
import { defaultReducer } from "../project_material_reducer";
import { DEFAULT_ACTIONS } from "../project_material_actions";
import { getWizardSteps } from "../../steps/config";
import { WizardContext } from "../../../hooks/use_wizard_context_hook";
import { FROM_LIBRARY_STATUS } from "../../../library_constants";
import * as UIUtils from "../../../../ui_utils";

/**
 * The main container component for creating project material starting from the project.
 */
export const ProjectMaterialWizardController = ({t, title, WizardHelper, initialState}) => {

  // noinspection JSCheckFunctionSignatures
  const [state, wizardDispatcher] = useReducer(defaultReducer, initialState);

  const {forceRedirectToStepId} = state;
  const wizardRef = React.useRef();
  const [libraryMaterials] = useLibraryMaterials();

  useEffect(() => {
    if (libraryMaterials?.length) {
      // noinspection JSCheckFunctionSignatures
      wizardDispatcher({
        type: DEFAULT_ACTIONS.LibraryMaterialsLoaded,
        libraryMaterials
      });
    }
  }, [libraryMaterials]);

  useEffect(() => {
    if (forceRedirectToStepId) {
      wizardRef.current.goToStep(forceRedirectToStepId);
    }
  }, [forceRedirectToStepId]);

  const context = {
    // Here we add all state/functions that we want to share with all subcomponents on this flow...
    t,
    wizardRef,
    wizardDispatcher,
    WizardHelper,
  };

  const getTitle = () => {
    const {name, fromLibraryStatus} = initialState?.material || {};
    return fromLibraryStatus === FROM_LIBRARY_STATUS.LINKED
      ? `Sync Material ${name}`
      : title ? title : t("Use Material From Library");
  };

  const getSubTitle = () => {
    const record = initialState?.material;
    if (!UIUtils.isEmpty(record)) {
      return UIUtils.getRecordCustomLabelForDisplay(record);
    } else {
      return "";
    }
  };

  return (
    <WizardContext.Provider value={context}>
      <CommonEditablesPageTitleBar name={getTitle()} subTitle={getSubTitle()} />
      <div className="container-spacer" />
      <div className="container material-library-wizard-div">
        <StepWizard nav={
          <ProjectMaterialWizardNavBar currentFlowStep={state.currentFlowStep} wizardStepsConfig={state.wizardStepsConfig} />}
                    instance={wizard => wizardRef.current = wizard}
                    transitions={
                      {
                        enterRight: "",
                        enterLeft: "",
                        exitRight: "",
                        exitLeft: ""
                      }
                    }
                    isLazyMount={true}
        >
          {getWizardSteps(state)}
        </StepWizard>
      </div>
    </WizardContext.Provider>
  );

};