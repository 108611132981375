import * as React from 'react';
/**
 * @hidden
 */
var defaultContext = {
    animation: true,
    expanded: false,
    mode: 'overlay',
    position: 'start',
    mini: false,
    dir: 'ltr',
    width: 240,
    miniWidth: 50
};
/** @hidden */
export var DrawerContext = React.createContext(defaultContext);
