"use strict";

import React from "react";
import ReactDOM from "react-dom";
import SmartUploadProgressPanel from "./smart_upload_progress_panel";
import { SMART_IMPORT_UPLOAD_STATUS } from "../../../../importWizardSteps/import_smart_upload_step";
import BaseReactComponent from "../../../../../base_react_component";

/**
 * This is the popup that shows the File upload and Amazon Textract progress in the Smart Upload import flow
 */
export default class SmartUploadProgressPopup extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    const {progressStatus, headerText, totalPages, textractMessage} = this.props;

    let nextTextractMessage = nextProps.textractMessage;
    let nextProgressStatus = nextProps.progressStatus;
    let nextHeaderText = nextProps.headerText;
    let nextTotalPages = nextProps.totalPages;

    return (progressStatus !== nextProgressStatus
      || textractMessage !== nextTextractMessage
      || headerText !== nextHeaderText
      || totalPages !== nextTotalPages);
  }

  componentDidMount() {
    super.componentDidMount();
    $(ReactDOM.findDOMNode(this)).modal({backdrop: "static", keyboard: false});
    $(ReactDOM.findDOMNode(this)).on("hidden.bs.modal", this.props.onHideConfirmationPopup);
  }

  componentDidUpdate() {
    const {progressStatus} = this.props;
    if (progressStatus === SMART_IMPORT_UPLOAD_STATUS.COMPLETED) {
      $(ReactDOM.findDOMNode(this)).modal("hide");
    }
  }

  handleCancelProcess() {
    this.props.onUploadCancelled();
    $(ReactDOM.findDOMNode(this)).modal("hide");
  }

  render() {
    const {textractMessage, progressStatus, isPDF, totalPages} = this.props;

    /* The formula below was calculated experimentally by trying different PDFs of various page sizes. This method
       is not expected to be 100% accurate. It just provides a worse case scenario to the user on how much time
       Textract will need to process the user file. There could be a case that for larger PDFs, Textract might take
       longer depending on Amazon's internal service queue, something we do not have visibility or can monitor ourselves.
       For Images, Textract did not need more than 20 seconds to deliver the results.
       For PDFs between 1-25 pages, Textract did not need more than 1 minute.
       For PDFs up to 50 pages, Textract did not need more than 2 minutes.
       To accommodate for the worse case scenario, we double up the times above in our calculations.
     */

    let estimatedNumberOfMinutes = 1;
    if (isPDF && totalPages < 25) {
      estimatedNumberOfMinutes = 2;
    } else if (isPDF && totalPages <= 50) {
      estimatedNumberOfMinutes = 5;
    } else if (isPDF) {
      estimatedNumberOfMinutes = (totalPages % 50) * 5;
    }
    let remainingTimeMessage = isPDF ? `About ${estimatedNumberOfMinutes} minutes to go` : "This will take a few seconds.";

    return (
      <div className="modal fade confirmation-popup"
           ref={this.props.modalRef}
           id="smartUploadProgressPopup"
      >
        <div className="modal-dialog">
          <div className="modal-content smart-upload-progress-popup">
            <div className="modal-header">
              <h1 id="confirmationHeader"
                  className="modal-title"
              >{this.props.headerText}</h1>
              <button type="button"
                      className="close"
                      onClick={this.handleCancelProcess}
                      aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-container">
                <div className="row">
                  <div className="col-sm-12 smart-upload-remaining-time-label">
                    <span>{remainingTimeMessage}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <span>{"Grab a cup of coffee ☕ while we work."}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <span>Or if you insist </span>
                    <a id="goToProjectsLink"
                       href="/projects/list.html" target="_blank"
                    >Go to Projects</a>
                    <span> and continue working</span>
                  </div>
                </div>
                <SmartUploadProgressPanel
                  progressStatus={progressStatus}
                  textractMessage={textractMessage}
                />
              </div>
            </div>
            <div className="modal-footer">
              <div className="btn-group">
                <button id="cancelButton"
                        type="button"
                        className="btn btn-secondary"
                        onClick={this.handleCancelProcess}
                >
                  {"Cancel"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
