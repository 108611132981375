"use strict";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseInfoTooltip from "./base_info_tooltip";

/**
 * This class is for showing a tooltip with both an explanation and possibly a link to document attachment.
 *
 */
export default class SupportDocumentsInfoTooltip extends BaseInfoTooltip {

  constructor(props) {
    super(props);
  }

  render() {
    const color = "#859399";
    const {className, id, textDirection, icon, text} = this.props;
    return (
      <span className={`${className || ""} ml-2 links-count-label border rounded`} id={id}
            data-toggle="popover"
            data-html="true"
            data-container="body"
            data-placement={textDirection || "top"}
            ref={popover => this.popover = popover}
            data-content={this.renderPopupContent()}
            data-boundary="viewport"
      >
        <FontAwesomeIcon icon={icon} size={"sm"} color={color} className={"fa-align-middle no-select"} />
        <span className={"ml-1 mr-1 no-select"}>{text}</span>
      </span>
    );
  }

}

