"use strict";

import React from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseReactComponent from "../../../base_react_component";
import { DATATABLES_DOM_WITH_SEARCH } from "../../../widgets/tables/base_table";

/* This shows the table of save Risk Map layouts. */
export default class RiskMapReportLayoutsListTable extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Name",
        width: 50,
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            <span>{result.name}</span>
          );
        }
      },
      {
        title: "Description",
        width: 200,
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            <span>{result.description}</span>
          );
        }
      },
      {
        title: "Manage",
        width: 20,
        data: result => result.id
      },
    ];
  }

  componentDidMount() {
    super.componentDidMount();
    this.table = $(this.tableRef).DataTable({
      dom: DATATABLES_DOM_WITH_SEARCH,
      data: this.props.layouts,
      columns: this.columns,
      select: {
        items: "row",
        info: false,
        style: "single"
      },
      columnDefs: [
        {
          targets: 2,
          width: "20px",
          createdCell: (td, cellData, rowData, rowIgnore, colIgnore) => ReactDOM.render(
            <FontAwesomeIcon icon={faTrash}
                             alt="Delete"
                             className="risk-map-layout-delete-image"
                             onClick={this.handleLayoutDelete.bind(this, rowData)}
            />, td)

        }
      ],
      stateSave: true
    });

    this.table.on("select", this.onTableSelection);
    this.table.on("deselect", this.onTableDeselection);
  }

  shouldComponentUpdate(nextProps) {
    return (this.props.layouts &&
      this.props.layouts.length !== nextProps.layouts.length);
  }

  componentDidUpdate() {
    if (this.tableRef) {
      const table = $(this.tableRef)
        .DataTable();

      table.clear();
      table.rows.add(this.props.layouts);
      table.draw();
    }
  }

  onTableSelection(e, dt, type, indexes) {
    if (type === "row") {
      let data = this.table.rows(indexes).data().pluck("id");
      // do something with the ID of the selected items
      if (data[0]) {
        this.props.onLayoutSelection(data[0]);
      }
    }
  }

  onTableDeselection() {
    this.props.onLayoutSelection(null);
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    $(this.tableRef)
      .DataTable()
      .destroy(true);
  }

  handleLayoutDelete(rowData) {
    this.props.onLayoutDelete(rowData);
    this.props.onLayoutSelection(null);
  }

  render() {
    return (
      <div>
        <table ref={ref => this.tableRef = ref}
               className="table table-bordered table-hover results-table"
               id="layoutsListTable"
               style={{
                 width: "100%"
               }}
        />
      </div>
    );
  }

}
