"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import CannedReport from "./canned_report";
import moment from "moment";
import DatePicker from "../../widgets/date_picker";
import * as CannedReportHelper from "./canned_report_helper";
import { loadRMP } from "../../helpers/risk_helper";
import { MODEL_TYPES_ENUM } from "../constants/report_constants";
import * as ProcessCache from "../../processExplorer/process/process_cache";

/* This class shows FMEA canned report */
export default class FMEACannedReport extends CannedReport {
  constructor(props) {
    super(props);

    let currentDate = UIUtils.getEndOfDayForDate(moment()).toDate();
    this.state.referenceDate = currentDate;
    this.state.reviewDate = currentDate;
  }

  getCustomPayloadData() {
    let referenceDate = CannedReportHelper.getCorrectedDateForDatePicker(this.state.referenceDate);
    let reviewDate = CannedReportHelper.getCorrectedDateForDatePicker(this.state.reviewDate);

    this.setStateSafely({
      filterOptions: {
        ...this.state.filterOptions,
        referenceDate,
        reviewDate,
      }
    });

    return {
      referenceDate: CannedReportHelper.getUTCDateForURL(referenceDate),
      reviewDate: CannedReportHelper.getUTCDateForURL(reviewDate)
    };
  }

  renderFilters() {
    return <Fragment>
      <div className="col-sm-3">
        {this.renderReferenceDateFilter()}
      </div>
      <div className="col-sm-3">
        {this.renderReviewDateFilter()}
      </div>
    </Fragment>;
  }

  renderReferenceDateFilter() {
    return this.createFilter("reference", this.handleReferenceDateChange);
  }

  renderReviewDateFilter() {
    return this.createFilter("review", this.handleReviewDateChange);
  }

  handleReferenceDateChange(event) {
    this.handleDateFilterChange(event, "referenceDate");
  }

  handleReviewDateChange(event) {
    this.handleDateFilterChange(event, "reviewDate");
  }

  handleDateFilterChange(event, dateFilterName) {
    if (event && event instanceof Date) {
      let date = CannedReportHelper.getCorrectedDateForDatePicker(moment(event));
      let stateObject = {
        reportResults: null,
        [dateFilterName]: date,
        filterOptions: {
          ...this.state.filterOptions,
          [dateFilterName]: date
        },
      };

      // Review date shouldn't be before reference date
      if (dateFilterName === "referenceDate" && date > this.state.reviewDate) {
        stateObject.reviewDate = date;
      }

      this.setStateSafely(stateObject,
        () => {
          const {selectedTypeaheadOption} = this.state;

          const processId = this.props.processId || ProcessCache.getProcessIdUsedRecently(this.props.projectId);
          const requestPayload = {
            projectId: this.props.projectId,
            processId,
            editableId: selectedTypeaheadOption.id,
            editableName: MODEL_TYPES_ENUM[selectedTypeaheadOption.typeCode],
            ...this.getCustomPayloadData()
          };

          loadRMP(this.getDataFromServer.bind(this, requestPayload));
        }
      );
    }
  }

  createFilter(name, handler) {
    let date = this.state[`${name}Date`];
    let selectedTypeaheadOption = this.state.selectedTypeaheadOption ||
      this.props.selectedTypeaheadOption || null;
    return (
      <div id={`${name}DateFilter`} className="attribute-container">
        <label id={`${name}DatePickerLabel`}
               htmlFor={`${name}DatePicker`}
               className="col-form-label base-attribute"
        >
          {name === "reference" ? "Reference Date:" : "Review Date:"}
        </label>
        <DatePicker id={`${name}DateInput`}
                    dropdownMode="scroll"
                    disabled={this.isDatePickerDisabled(selectedTypeaheadOption)}
                    selected={UIUtils.convertMomentToDate(date)}
                    maxDate={new Date()}
                    minDate={name === "review" ? moment(this.state.referenceDate).toDate() : null}
                    ref={datePicker => this.datePicker = datePicker}
                    onChange={handler}
        />
      </div>
    );
  }

  renderInput() {
    return this.state.reportResults ?
      (
        <div id="dataReportViewer" className="data-report-viewer-full-screen" />
      ) : "";
  }
}
