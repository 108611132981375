var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { getMark, applyLink } from '@progress/kendo-editor-common';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { messages } from './../messages';
/**
 * @hidden
 */
var InsertLinkDialog = /** @class */ (function (_super) {
    __extends(InsertLinkDialog, _super);
    function InsertLinkDialog() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.href = null;
        _this.title = null;
        _this.target = null;
        _this.onClose = function () {
            _this.props.view.focus();
            _this.props.onClose();
        };
        _this.onInsert = function () {
            var view = _this.props.view;
            var mark = _this.props.settings.mark;
            var attrs = {
                href: _this.href ? _this.href.value : undefined,
                title: _this.title ? _this.title.value : undefined,
                target: _this.target && _this.target.checked ? '_blank' : undefined
            };
            applyLink({ mark: mark, attrs: attrs }, _this.props.settings.commandName)(view.state, view.dispatch);
            _this.onClose();
        };
        return _this;
    }
    /**
     * @hidden
     */
    InsertLinkDialog.prototype.render = function () {
        var _this = this;
        var _a = this.props, view = _a.view, settings = _a.settings, dir = _a.dir, render = _a.render;
        var localization = provideLocalizationService(this);
        var _b = settings.messages, insertHyperlinkDialogTitle = _b.insertHyperlinkDialogTitle, insertHyperlinkAddress = _b.insertHyperlinkAddress, insertHyperlinkTitle = _b.insertHyperlinkTitle, insertHyperlinkNewWindow = _b.insertHyperlinkNewWindow, insertHyperlinkCancel = _b.insertHyperlinkCancel, insertHyperlinkInsert = _b.insertHyperlinkInsert;
        var linkMark = view ?
            getMark(view.state, view.state.schema.marks[settings.mark])
            : false;
        var content = (React.createElement("div", { className: "k-edit-form-container" },
            React.createElement("div", { className: "k-edit-label" },
                React.createElement("label", { htmlFor: "k-editor-link-url" }, localization.toLanguageString(insertHyperlinkAddress, messages[insertHyperlinkAddress]))),
            React.createElement("div", { className: "k-edit-field" },
                React.createElement("input", { type: "text", className: "k-input", id: "k-editor-link-url", ref: function (e) { return _this.href = e; }, defaultValue: (linkMark && linkMark.attrs.href) || undefined, autoFocus: true })),
            React.createElement("div", { className: "k-edit-label k-editor-link-text-row" },
                React.createElement("label", { htmlFor: "k-editor-link-text" }, localization.toLanguageString(insertHyperlinkTitle, messages[insertHyperlinkTitle]))),
            React.createElement("div", { className: "k-edit-field k-editor-link-text-row" },
                React.createElement("input", { type: "text", className: "k-input", id: "k-editor-link-text", ref: function (e) { return _this.title = e; }, defaultValue: (linkMark && linkMark.attrs.title) || undefined })),
            settings.linkTarget !== false && React.createElement("div", { className: "k-edit-field" },
                React.createElement("input", { type: "checkbox", className: "k-checkbox k-checkbox-md k-rounded-md", id: "k-editor-link-target", ref: function (e) { return _this.target = e; }, defaultChecked: (linkMark && !!linkMark.attrs.target) || undefined }),
                React.createElement("label", { htmlFor: "k-editor-link-target", className: "k-checkbox-label" }, localization.toLanguageString(insertHyperlinkNewWindow, messages[insertHyperlinkNewWindow])))));
        var actionButtons = [(React.createElement(Button, { onClick: this.onClose, dir: dir, key: "cancel" }, localization.toLanguageString(insertHyperlinkCancel, messages[insertHyperlinkCancel]))), (React.createElement(Button, { onClick: this.onInsert, themeColor: 'primary', dir: dir, key: "insert" }, localization.toLanguageString(insertHyperlinkInsert, messages[insertHyperlinkInsert])))
        ];
        var dialog = (React.createElement(Dialog, { title: localization.toLanguageString(insertHyperlinkDialogTitle, messages[insertHyperlinkDialogTitle]), onClose: this.onClose, closeIcon: true, dir: dir, style: dir === 'rtl' ? { textAlign: 'right' } : undefined, appendTo: document.body },
            content,
            React.createElement.apply(React, __spreadArray([DialogActionsBar, null], actionButtons, false))));
        return render ? render(dialog, { content: content, actionButtons: actionButtons }) : dialog;
    };
    return InsertLinkDialog;
}(React.Component));
export { InsertLinkDialog };
registerForLocalization(InsertLinkDialog);
