"use strict";

import {
  Typeahead as ReactBootstrapTypeahead,
  AsyncTypeahead as ReactBootstrapAsyncTypeahead,
} from "react-bootstrap-typeahead";
import defaultFilterBy from "react-bootstrap-typeahead/lib/utils/defaultFilterBy";
import React from "react";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import { createSearchTermFilter } from "../utils/filter_helper";
import BaseReactComponent from "../base_react_component";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.System, "DatePicker");

/**
 * This class is responsible for setting a few sensible defaults on the typeahead object.
 */
// i18next-extract-mark-ns-start widgets
class Typeahead extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  blur() {
    this.typeahead.blur();
  }

  clear() {
    this.typeahead.clear();
  }

  focus() {
    this.typeahead.focus();
  }

  getInstance() {
    return this.typeahead;
  }

  /**
   * This shows all of the values when you click into the typeahead, even if something is selected.  See more
   * here: https://stackoverflow.com/a/50376581/491553
   */
  static showAllOnClick(option, props) {
    const {
      multiple,
      selected,
      filterSelector,
      text,
      ignoredExpression,
    } = props;
    Logger.verbose(() => `ShowAllOnClick was called with text ${text} for ${props.id}`);

    if (multiple) {
      const newProps = {...props, filterBy: ["label"]};
      return defaultFilterBy(option, newProps);
    } else {
      // Display all the options if there's a selection or no text at all.
      if ((selected && selected.length) || text === "") {
        return true;
      }

      // creates and executes a simple search term filter
      const search = createSearchTermFilter(() => text, filterSelector, ignoredExpression);
      return search(option);
    }
  }

  render() {
    const {t, inputProps, filterBy, disabled} = this.props;
    const ComponentToRender = (this.props.async) ? ReactBootstrapAsyncTypeahead : ReactBootstrapTypeahead;
    if (inputProps.autoComplete !== "off") {
      inputProps.autoComplete = "off";
    }

    // Only show all on click when the typeahead field is not disabled.
    const attributes = {...this.props};
    if (!filterBy && !disabled) {
      attributes.filterBy = filterBy ? filterBy : !disabled ? Typeahead.showAllOnClick : filterBy;
    }

    if (attributes.selected) {
      attributes.selected = attributes.selected.filter(selected => selected);
    }

    Logger.verbose(() => `Typeahead render called with id ${this.props.id} and # of options: ${this.props.options.length}`);

    return (
      <span id={this.props.id} className={this.props.className}>
        <ComponentToRender
          {...attributes}
          emptyLabel={t("No matches found.")}
          ref={typeahead => this.typeahead = typeahead}
        />
      </span>
    );
  }
}

Typeahead.defaultProps = {
  positionFixed: true, // Display the typeahead above scrollbars
  inputProps: {autoComplete: "off"}, // QI-3016 Turn off autocomplete in Chrome
  async: false,
  filterSelector: (item) => item.label,
};

export default I18NWrapper.wrap(Typeahead, "widgets");
// i18next-extract-mark-ns-stop widgets
