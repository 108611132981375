"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import moment from "moment";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import Section from "../../editor/widgets/section";
import TextAttribute from "../../editor/attributes/text_attribute";
import ComboBoxAttribute from "../../editor/attributes/combo_box_attribute";
import TextAreaAttribute from "../../editor/attributes/text_area_attribute";
import CheckboxAttribute from "../../editor/attributes/checkbox_attribute";
import TypeaheadAttribute from "../../editor/attributes/typeahead_attribute";
import DateAttribute, { SET_EXPLICITLY } from "../../editor/attributes/date_attribute";
import { EDITOR_TYPES } from "../../editor/editor_constants";
import LinksAttribute from "../../editor/attributes/links_attribute";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseAttributeParent from "../attributes/base_attribute_parent";

/**
 * This is the class that renders the Add/Edit process component editor in the process explorer.
 */
// i18next-extract-mark-ns-start process_explorer
class ProcessComponent extends BaseAttributeParent {
  constructor(props) {
    super(props, "processComponent", "ProcessComponent", "Process Component (PRC)");
    this.getHTMLURLPrefix = ProcessComponent.getHTMLURLPrefix;
  }

  componentDidMount() {
    document.title = "QbDVision Process Component";

    super.componentDidMount();
  }

  static getHTMLURLPrefix() {
    return "/processExplorer/processComponents";
  }

  getTabName() {
    return "Process Explorer";
  }

  getInfoTooltip() {
    return (
      <InfoTooltip id="infoPRC"
                   verbiage={
                     <div>
                       Process components are equipment, parts or chemicals needed for a particular stage of a
                       process (Unit Operation). This could be anything from a simple bowl, the bleach to clean that
                       bowl,
                       or something
                       more complex like an oven.
                     </div>
                   }
      />
    );
  }

  getAttributeNames() {
    return ["MaterialAttribute", "ProcessParameter"];
  }

  getAttributeParentId() {
    return "ProcessComponentId";
  }

  onDataReceivedFromServer() {
    super.onDataReceivedFromServer();
    if (this.state.expirationDate) {
      this.handleChangeValue("expirationDate" + SET_EXPLICITLY, true);
    }
    this.setStateSafely({originalUOs: UIUtils.deepClone(this.state.UnitOperations)});
    this.handleTypeaheadLoaded();
  }

  handleChangeValue(attributeName, attributeValue, callback, attributeType) {
    super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
    if (attributeName === "drugProductContact") {
      if (attributeValue !== true) {
        this.handleChangeValue("contactRisk", "", callback);
        this.handleChangeValue("contactRiskJustification", "", callback);
      }
    } else if (attributeName === "SupplierId") {
      if (this.isSupplierNotQualified(attributeValue)) {
        this.handleChangeValue("componentQualified", "No", callback);
      }
      if (!this.showSupplierQualificationError("componentQualified", "No", attributeValue)) {
        this.componentQualifiedField.clearValidationErrors();
      }
    } else if (attributeName === "effectiveDate") {
      if (!this.state["expirationDate" + SET_EXPLICITLY]) {
        let attributeDate = moment(attributeValue, UIUtils.DATE_FORMAT_FOR_STORAGE);
        this.expirationDateRef.setDate(attributeDate.add(1, "year"));
      }
    }
  }

  getParentId() {
    return this.state.UnitOperationId || UIUtils.getParameterByName("parentId");
  }

  handleValidateComponentQualificationStatus() {
    return this.showSupplierQualificationError("componentQualified")
      ? "The Component Qualified field cannot be set to Yes. The linked Supplier's \"Qualification Status\" needs to be \"Qualified\" or \"Re-qualified\" to set this value."
      : "";
  }

  filterSteps(option) {
    const {UnitOperations} = this.state;
    const uoIds = UnitOperations ? UnitOperations.map(uo => uo.id) : [];
    return uoIds.includes(option.UnitOperationId);
  }

  renderAttributes() {
    return (
      <div>
        <Section parent={this}
                 header={this.getGeneralHeader()}
                 collapsible={false}
                 showDocLinks={true}
        >
          <div className="row">
            <TextAttribute name="name"
                           parent={this}
            />
            <TypeaheadAttribute name="process"
                                default={this.getProcessId()}
                                typeaheadType="Process"
                                target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                                instructions={`This can only be modified on add.`}
                                disabled={!this.isAdd()}
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
                                projectId={this.getProjectId()}
                                processId={this.getProcessId()}
            />
          </div>
          <div className="row">
            <LinksAttribute name="UnitOperation"
                            displayName="Unit Operations"
                            onValidate={this.handleUOVerification}
                            target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                            parent={this}
                            parentId={this.state.id}
                            isLoading={this.state.isLoading}
                            projectId={this.getProjectId()}
                            processId={this.getProcessId()}

            />
            <LinksAttribute name="Step"
                            displayName="Steps"
                            onValidate={this.handleStepVerification}
                            target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                            filter={this.filterSteps}
                            parent={this}
                            parentId={this.state.id}
                            isLoading={this.state.isLoading}
                            projectId={this.getProjectId()}
                            processId={this.getProcessId()}
                            relatedRecord={this.state.UnitOperations}
            />
          </div>
          <div className="row">
            <ComboBoxAttribute name="type"
                               options={["Equipment",
                                 "Instruments/Sensors",
                                 "Part-Not Disposable",
                                 "Part-Disposable",
                                 "Chemical",
                                 "Other"]}
                               default="Equipment"
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
            <CheckboxAttribute name="certificateOfAnalysis"
                               displayName="COA/COC"
                               tooltipText="A Certificate of Analysis (COA) or Conformance (COC) is a document issued by Quality Assurance that confirms that a regulated product meets its product specification. They commonly contain the actual results obtained from testing performed as part of quality control of an individual batch of a product."
                               parent={this}
            />
            <TextAreaAttribute name="function"
                               parent={this}
            />
          </div>
          <div className="row">
            <TextAreaAttribute name="description"
                               className="col-sm-12"
                               parent={this}
            />
          </div>
        </Section>
        <Section id="componentQualification"
                 parent={this}
                 showDocLinks={true}
                 header="Component Qualification"
        >
          <div className="row">
            <TypeaheadAttribute name="supplier"
                                typeaheadType="Supplier"
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
            />
            <ComboBoxAttribute name="componentQualified"
                               ref={ref => this.componentQualifiedField = ref}
                               options={["Yes", "No", "Pending"]}
                               default="No"
                               tooltipText="Component qualification should be in accordance with your corporate SOPs. The Supplier should be qualified prior to qualifying the material."
                               instructions={"To set a value of Yes, the \"Supplier\" above must have its \"Qualification Status\" field set to either \"Qualified\" or \"Re-qualified.\""}
                               onValidate={this.handleValidateComponentQualificationStatus}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
          </div>
          <div className="row">
            <TextAttribute name="partNumber"
                           displayName="Supplier Part Number"
                           parent={this}
            />
            <TextAttribute name="internalPartNumber"
                           parent={this}
            />
          </div>
          <div className="row">
            <DateAttribute name="effectiveDate"
                           disabled={this.state.componentQualified !== "Yes"}
                           tooltipText="The date the component qualification goes into effect."
                           instructions={"Enabled only when the \"Component Qualified\" field is set to \"Yes\""}
                           parent={this}
            />
            <DateAttribute name="expirationDate"
                           ref={dateAttribute => this.expirationDateRef = dateAttribute}
                           disabled={this.state.componentQualified !== "Yes"}
                           tooltipText="The date the component qualification expires."
                           instructions={"Enabled only when the \"Component Qualified\" field is set to \"Yes\""}
                           parent={this}
            />
          </div>
        </Section>
        <Section id="unitQualification"
                 parent={this}
                 showDocLinks={true}
                 header="Unit Qualification"
        >
          <div className="row">
            <ComboBoxAttribute name="acceptanceTesting"
                               options={["Not Applicable",
                                 "Pending",
                                 "FAT Completed",
                                 "SAT Completed"]}
                               tooltipText={<div>
                                 <b>Factory Acceptance Test (FAT)</b> - documented evidence that a piece of equipment or
                                 system has been adequately tested at the manufacturer’s facility and performed to the
                                 end user’s expectations prior to delivery to the end user. <br />
                                 <b>Site Acceptance Test (SAT)</b> - documented evidence that a piece of equipment or
                                 system has not been affected in the transportation and has been adequately tested at
                                 the end user’s facility and performed to end user’s expectation.
                               </div>}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
            <ComboBoxAttribute name="qualificationStatus"
                               options={["Not Applicable",
                                 "Pending",
                                 "Design Qualified (DQ)",
                                 "Installation Qualified (IQ)",
                                 "Operation Qualified (OQ)",
                                 "Performance Qualified (PQ)"]}
                               tooltipText={<div>
                                 <b>Design Qualification</b> - Establishing by objective evidence that the facilities,
                                 equipment, or systems&apos; proposed design are suitable for the intended purpose. See ICH Q7 and EU Annex 15. <br />
                                 <b>Installation Qualification</b> - Establishing confidence that process equipment and
                                 ancillary systems are compliant with appropriate codes and approved design intentions,
                                 and that manufacturer recommendations are suitably considered. <br />
                                 <b>Operational Qualification</b> - Establishing by objective evidence process control
                                 limits and action levels which result in product that meets all predetermined
                                 requirements. <br />
                                 <b>Performance Qualification</b> - Establishing by objective evidence that the process,
                                 under anticipated conditions, consistently produces a product which meets all
                                 predetermined requirements.
                               </div>}
                               tooltipGuidanceURL="http://www.imdrf.org/docs/ghtf/final/sg3/technical-docs/ghtf-sg3-n99-10-2004-qms-process-guidance-04010.pdf"
                               tooltipGuidancePage={5}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
            <TextAttribute name="unitId"
                           parent={this}
            />
            <ComboBoxAttribute name="calibration"
                               options={["Not Applicable",
                                 "Pending",
                                 "Completed"]}
                               tooltipText={
                                 <div>Calibration is a necessary component to ensure the legitimacy of Qualification and Validation. <strong>Calibration</strong> is a process that demonstrates a particular instrument or device produces results within specified limits, as compared to those produced by a traceable standard over an appropriate range of measurements. Calibration activities must be performed with qualified instruments by an accredited laboratory. Calibration is of utmost importance in building a solid Quality System Management with experts and well-trained specialists.
                                 </div>}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
          </div>
        </Section>
        <Section id="componentRisk"
                 parent={this}
                 showDocLinks={true}
                 header="Component Risk"
        >
          <div className="row">
            <CheckboxAttribute name="drugProductContact"
                               className="col-sm-4"
                               tooltipText="Yes if the drug product or any material component or intermediate comes into contact with any surface of the process component."
                               parent={this}
            />
            <TypeaheadAttribute name="contactRisk"
                                className="col-sm-8"
                                multiple
                                options={["None",
                                  "Absorptive",
                                  "Additive",
                                  "Reactive",
                                  "Unknown"]}
                                disabled={this.state.drugProductContact !== true}
                                instructions={"Enabled only when the \"Drug Product Contact\" field is checked"}
                                tooltipText="Equipment shall be constructed so that surfaces that contact components, in-process materials, or drug products shall not be reactive, additive, or absorptive so as to alter the safety, identity, strength, quality, or purity of the drug product beyond the official or other established requirements."
                                tooltipGuidanceURL="https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfcfr/CFRSearch.cfm?fr=211.65"
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
            />
            <TextAreaAttribute name="contactRiskJustification"
                               tooltipText="Describe which surfaces or materials of the process component come into contact with a material component, intermediate, or drug product and the potential risk."
                               className="col-sm-12"
                               parent={this}
            />
            <ComboBoxAttribute name="cleaningValidation"
                               options={["Not Applicable", "Pending", "Completed"]}
                               tooltipText="Cleaning validation is the methodology used to assure that a cleaning process removes residues of the active pharmaceutical ingredients of the product manufactured in a piece of equipment, the cleaning aids utilized in the cleaning process and the microbial attributes."
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
            <ComboBoxAttribute name="sterilizationValidation"
                               options={["Not Applicable", "Pending", "Completed"]}
                               tooltipText="Validation of processes used to sterilize drug products and equipment are the most critical validation activities undertaken. The objective of sterilization validation is to determine that the sterilization process will consistently achieve sterility and that it won't have an undesirable effect on the device or its packaging."
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
          </div>
        </Section>
        {this.renderTechTransferAssessmentSection()}
        {this.renderTagsSection()}
        <Section id="references"
                 parent={this}
                 showDocLinks={true}
                 addOptions={[
                   {id: "references", label: "References"},
                   {id: "standards", label: "Standards"},
                   {id: "guidances", label: "Guidances"}]}
                 header={<span>
              References & Standards
              <InfoTooltip id="infoRefAndStandardsControl"
                           verbiage={
                             <div>
                               References, standards, safety data sheets, etc. for highly characterized specimens
                               of drug substances, excipients, food ingredients, impurities, degradation products,
                               dietary supplements, compendial reagents, tests, assay, and performance calibrators.
                               (e.g. USP, BP, EP, JP, ISO, etc.)
                             </div>}
              />
              </span>}
        >
        </Section>
      </div>
    );
  }
}

export default I18NWrapper.wrap(ProcessComponent, ["process_explorer", "editor"]);
// i18next-extract-mark-ns-stop process_explorer
