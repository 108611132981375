"use strict";

import React from "react";
import BasePopup from "../../editor/approval/base_popup";
import { TEMPLATE_VALUE, TemplateSelection } from "./document_select_template_popup";

/**
 * The popup that contains all the editable record and its attribute
 * where user can insert them to the editor
 */
export default class DocumentSwitchSourceConfirmationPopup extends BasePopup {
  constructor(props) {
    super(props);

    this.state = {
      template: TEMPLATE_VALUE.BLANK_TEMPLATE,
    };
  }

  getTextOptionsForDocumentSource() {
    const {isQbdSrcDoc} = this.props;
    if (isQbdSrcDoc) {
      return {
        title: "Switch to External Source Document",
        detailedMessage:
          "If you wish to use an External Source Document, QbDVision Doc will be removed.",
        switchButtonText: "Switch to External Source Document",
      };
    }

    return {
      title: "Switch to QbDVision Doc",
      detailedMessage:
        "If you wish to use a QbDVision Doc, External Source Document(s) will be removed.",
      switchButtonText: "Create QbDVision Doc",
      bodyContent: () => (
        <div>
          <p>Create</p>
          <TemplateSelection onChange={this.handleTemplateChange} />
        </div>
      ),
    };
  }

  handleTemplateChange(template) {
    this.setStateSafely({template});
  }

  render() {
    const {onSwitch} = this.props;
    const textOption = this.getTextOptionsForDocumentSource();

    return (
      <div className="modal fade" ref={this.setModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">{textOption.title}</h1>
              <button
                type="button"
                className="close"
                onClick={this.handleCancel}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-container">
                <p id="popup-info-message">{textOption.detailedMessage}</p>
                {textOption.bodyContent && textOption.bodyContent()}
              </div>
            </div>
            <div className="modal-footer p-0">
              <div className="modal-container">
                <div className="btn-group">
                  <button
                    id="createButtonOnPopup"
                    type="button"
                    className="btn btn-primary"
                    onClick={() => onSwitch(this.state.template)}
                  >
                    {textOption.switchButtonText}
                  </button>
                  <button
                    id="cancelButtonOnPopup"
                    type="button"
                    className="btn btn-secondary"
                    onClick={this.handleCancel}
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
