import { __assign, __rest } from "tslib";
import { changeStylesString } from './utils';
import { ReplaceAroundStep } from 'prosemirror-transform';
import { Fragment, Slice } from 'prosemirror-model';
import { NodeSelection } from 'prosemirror-state';
/**
 * @hidden
 */
var canChangeType = function (stateDoc, pos, type) {
    var $pos = stateDoc.resolve(pos), index = $pos.index();
    return $pos.parent.canReplaceWith(index, index + 1, type);
};
/**
 * Changes the type or the attributes of the passed node.
 *
 * @example
 * See `cleanTextBlockFormatting` or `formatBlockElements` function.
 */
export var changeTextBlock = function (tr, node, nodeType, attrs) {
    if (!nodeType.isTextblock) {
        return false;
    }
    var ranges = tr.selection.ranges;
    ranges.forEach(function (range) {
        var mapFrom = tr.steps.length;
        var from = range.$from.pos;
        var to = range.$to.pos;
        tr.doc.nodesBetween(from, to, function (currNode, pos) {
            if (currNode.eq(node) &&
                currNode.isTextblock &&
                !currNode.hasMarkup(nodeType, attrs) &&
                canChangeType(tr.doc, tr.mapping.slice(mapFrom).map(pos), nodeType)) {
                // Ensure all markup that isn't allowed in the new node type is cleared
                tr.clearIncompatible(tr.mapping.slice(mapFrom).map(pos, 1), nodeType);
                var mapping = tr.mapping.slice(mapFrom);
                var startM = mapping.map(pos, 1), endM = mapping.map(pos + currNode.nodeSize, 1);
                var slice = new Slice(Fragment.from(nodeType.create(attrs, null, currNode.marks)), 0, 0);
                tr.step(new ReplaceAroundStep(startM, endM, startM + 1, endM - 1, slice, 1, true));
                return false; // this will skip the node children
            }
        });
    });
};
/**
 * Traverses the selection and returns the block nodes in it.
 *
 * @example
 * See `cleanTextBlockFormatting` function.
 */
export var blockNodes = function (state, options) {
    if (options === void 0) { options = { blocksInSelection: false }; }
    var doc = state.doc, selection = state.selection;
    var $from = selection.$from, ranges = selection.ranges;
    var blocksInSelection = options.blocksInSelection;
    var result = [];
    var selNode = selection instanceof NodeSelection ? selection.node : undefined;
    if (selNode) {
        if (selNode.isBlock) {
            result.push(selNode);
            selNode.nodesBetween(0, selNode.content.size, function (node) {
                if (node.isBlock) {
                    result.push(node);
                }
            });
        }
        else if (!blocksInSelection && $from.parent && $from.parent.isBlock) {
            result.push($from.parent);
        }
    }
    else {
        ranges.forEach(function (range) {
            var from = range.$from.pos;
            var to = range.$to.pos;
            doc.nodesBetween(from, to, function (node, pos, _parent, _index) {
                if (node.isBlock && (!blocksInSelection || (pos >= from && pos + node.content.size + 2 <= to))) {
                    result.push(node);
                }
            });
        });
    }
    return result;
};
export var formatBlockElements = function (value, commandName) {
    return function (state, dispatch) {
        var blocks = blockNodes(state);
        var nodes = state.schema.nodes;
        var tr = state.tr;
        tr.setMeta('commandName', commandName);
        tr.setMeta('args', { value: value });
        blocks.forEach(function (node) {
            if (node.type.isTextblock) {
                if (value === 'p') {
                    var _a = node.attrs, level = _a.level, attrs = __rest(_a, ["level"]);
                    changeTextBlock(tr, node, nodes.paragraph, attrs);
                }
                else if (/^h[1-6]$/i.test(value)) {
                    var level = parseInt(value.substr(1), 10);
                    changeTextBlock(tr, node, nodes.heading, __assign(__assign({}, node.attrs), { level: level }));
                }
                else if (value === 'blockquote') {
                    var _b = node.attrs, level = _b.level, attrs = __rest(_b, ["level"]);
                    changeTextBlock(tr, node, nodes.blockquote, attrs);
                }
            }
        });
        var result = tr.docChanged;
        if (result) {
            dispatch(tr.scrollIntoView());
        }
        return result;
    };
};
/**
 * Removes the style and class attributes of text block elements.
 * Requires to dispatch the transaction.
 *
 * @example
 * See `cleanFormatting` function.
 */
export var cleanTextBlockFormatting = function (tr, options) {
    if (options === void 0) { options = { blocksInSelection: true }; }
    var doc = tr.doc, selection = tr.selection;
    var blocksInSelection = options.blocksInSelection, blockNodeType = options.blockNodeType;
    var nodes = blockNodes({ doc: doc, selection: selection }, { blocksInSelection: blocksInSelection });
    var textBlocks = nodes.filter(function (node) { return node.isTextblock; });
    textBlocks.forEach(function (node) {
        var _a = node.attrs || {}, _b = _a.style, style = _b === void 0 ? '' : _b, _c = _a.class, className = _c === void 0 ? '' : _c, attrs = __rest(_a, ["style", "class"]);
        var nodeType = blockNodeType || node.type;
        if (style || className || nodeType !== node.type) {
            changeTextBlock(tr, node, nodeType, attrs);
        }
    });
};
export var getBlockFormats = function (state) {
    var blocks = blockNodes(state);
    var nodes = state.schema.nodes;
    var result = [];
    blocks.forEach(function (node) {
        if (node.type === nodes.paragraph) {
            result.push('p');
        }
        else if (node.type === nodes.heading) {
            result.push("h" + node.attrs.level);
        }
        else if (node.type === nodes.blockquote && nodes.blockquote.isTextblock) {
            // In case the blockquote can have inline content.
            result.push('blockquote');
        }
    });
    return result;
};
export var addStyles = function (node, stylesToApply) {
    var currentStyles = node.attrs.style, changedStyleResult, toChange;
    if (currentStyles) {
        stylesToApply.forEach(function (style) {
            toChange = { style: style.name, value: /^.+$/, newValue: style.value };
            changedStyleResult = changeStylesString(currentStyles, toChange);
            currentStyles = changedStyleResult.changed ? changedStyleResult.style : currentStyles;
        });
    }
    var reducer = function (acc, curr) { return ((acc && curr.value ? acc + ' ' : '') + curr.value ? curr.name + ": " + curr.value + ";" : ''); };
    currentStyles = currentStyles || stylesToApply.reduce(reducer, '');
    return Object.assign({}, node.attrs, { style: currentStyles || null });
};
export var hasNode = function (state, nodeType) {
    var _a = state.selection, from = _a.from, to = _a.to;
    var result = false;
    state.doc.nodesBetween(from, to, function (node) {
        result = result || node.type === nodeType;
        return !result;
    });
    return result;
};
export var parentBlockFormat = function (state) {
    var formats = getBlockFormats(state);
    return new Set(formats).size === 1 ? formats[0] : null;
};
/**
 * **Deprecated.** Use `parentBlockFormat` function instead.
 */
export var activeNode = function (state) {
    return { tag: parentBlockFormat(state) || '' };
};
