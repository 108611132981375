"use strict";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useSidePanel } from "../../widgets/side_panel_provider";
import * as styles from "./column_selection.module.scss";

export const ColumnSelectionHeader = ({onResetSelection}) => {

  const {toggle} = useSidePanel();

  return (
    <div className={styles["column-selection-header"]}>
      <div>
        Table settings&nbsp;
        <a id="columnSelectionResetToDefaultButton" onClick={() => onResetSelection()}>
          Reset to default
        </a>
      </div>
      <div id="columnSelectionClose" onClick={toggle}>
        <FontAwesomeIcon className="column-selection-close-icon"
                         size="sm"
                         icon={faTimes}
        />
      </div>
    </div>
  );
};