"use strict";

import React, { memo } from "react";
import ReactDOM from "react-dom";
import { useNotification } from "../notification_provider";
import * as styles from "./info_notification.module.scss";
import { NOTIFICATION } from "../../topBar/constants";

const InfoNotification = () => {
  const {notification} = useNotification();

  if (!notification) {
    return null;
  }

  const {message, type} = notification;
  if (type !== NOTIFICATION.INFO) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles["container"]}>
      <span className={`${styles["notification"]} ${styles["message"]}`}>{message}</span>
    </div>,
    document.body
  );
};

export default memo(InfoNotification);
