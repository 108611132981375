import { canUseDOM } from './canUseDOM';
/** @hidden */
export var hasRelativeStackingContext = function (elementSource) {
    if (!canUseDOM) {
        return false;
    }
    // Component need to pass element to make sure document owner is correct.
    // This however might be performance hit if checked for example on each drag event.
    var currentDocument = elementSource ? elementSource.ownerDocument : document;
    if (!currentDocument || !currentDocument.body) {
        return false;
    }
    var top = 10;
    var parent = currentDocument.createElement('div');
    parent.style.transform = 'matrix(10, 0, 0, 10, 0, 0)';
    var child = currentDocument.createElement('div');
    child.appendChild(currentDocument.createTextNode('child'));
    child.style.position = 'fixed';
    child.style.top = top + 'px';
    parent.appendChild(child);
    currentDocument.body.appendChild(parent);
    var isDifferent = child.getBoundingClientRect().top !== top;
    currentDocument.body.removeChild(parent);
    return isDifferent;
};
