var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { windowStage } from './StageEnum';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import { messages, dialogsWindowMaximizeButton, dialogsWindowMinimizeButton, dialogsWindowRestoreButton, dialogsWindowCloseButton } from './messages';
/**
 * @hidden
 */
var WindowTitleBarComponent = function (props) {
    var children = props.children, onCloseButtonClick = props.onCloseButtonClick, onMinimizeButtonClick = props.onMinimizeButtonClick, onFullScreenButtonClick = props.onFullScreenButtonClick, onRestoreButtonClick = props.onRestoreButtonClick, onDoubleClick = props.onDoubleClick, stage = props.stage, forwardedRef = props.forwardedRef, id = props.id;
    var localization = useLocalization();
    var minimizeButtonRender = props.minimizeButton ?
        React.createElement(props.minimizeButton, { onClick: onMinimizeButtonClick, stage: stage }) :
        (React.createElement(Button, { fillMode: "flat", icon: "window-minimize", className: "k-window-action", onClick: onMinimizeButtonClick, "aria-label": localization.toLanguageString(dialogsWindowMinimizeButton, messages[dialogsWindowMinimizeButton]) }));
    var maximizeButtonRender = props.maximizeButton ?
        React.createElement(props.maximizeButton, { onClick: onFullScreenButtonClick, stage: stage }) :
        (React.createElement(Button, { fillMode: "flat", icon: "window-maximize", className: "k-window-action", onClick: onFullScreenButtonClick, "aria-label": localization.toLanguageString(dialogsWindowMaximizeButton, messages[dialogsWindowMaximizeButton]) }));
    var restoreButtonRender = props.restoreButton ?
        React.createElement(props.restoreButton, { onClick: onRestoreButtonClick, stage: stage }) :
        (React.createElement(Button, { fillMode: "flat", icon: "window-restore", className: "k-window-action", onClick: onRestoreButtonClick, "aria-label": localization.toLanguageString(dialogsWindowRestoreButton, messages[dialogsWindowRestoreButton]) }));
    var closeButtonRender = props.closeButton ?
        React.createElement(props.closeButton, { onClick: onCloseButtonClick, stage: stage }) :
        (React.createElement(Button, { fillMode: "flat", icon: "close", className: "k-window-action", onClick: onCloseButtonClick, "aria-label": localization.toLanguageString(dialogsWindowCloseButton, messages[dialogsWindowCloseButton]) }));
    return (React.createElement("div", { className: "k-window-titlebar k-dialog-titlebar", style: { touchAction: 'none' }, ref: forwardedRef, onDoubleClick: onDoubleClick },
        React.createElement("div", { className: "k-window-title", id: id }, children ? children : ''),
        React.createElement("div", { className: "k-window-actions" },
            (stage === windowStage.DEFAULT) && minimizeButtonRender,
            (stage === windowStage.DEFAULT) && maximizeButtonRender,
            (stage !== windowStage.DEFAULT) && restoreButtonRender,
            closeButtonRender)));
};
/**
 * @hidden
 */
export var WindowTitleBar = React.forwardRef(function (props, ref) { return (React.createElement(WindowTitleBarComponent, __assign({}, props, { forwardedRef: ref }))); });
