var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Fade } from '@progress/kendo-react-animation';
import { classNames, guid } from '@progress/kendo-react-common';
var TabStripContent = /** @class */ (function (_super) {
    __extends(TabStripContent, _super);
    function TabStripContent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.contentId = guid();
        _this.childFactory = function (child) {
            return React.cloneElement(child, __assign(__assign({}, child.props), { in: child.props.children.props.id === String(_this.contentId + _this.props.selected) }));
        };
        return _this;
    }
    /**
     * @hidden
     */
    TabStripContent.prototype.render = function () {
        var _a = this.props, children = _a.children, selected = _a.selected;
        var selectedTab = children && typeof selected === 'number' &&
            React.Children.toArray(children)[selected];
        var contentClasses = classNames('k-content', 'k-active', selectedTab && selectedTab.props.contentClassName);
        return (React.createElement("div", { className: contentClasses, style: this.props.style }, this.renderContent(children)));
    };
    TabStripContent.prototype.renderContent = function (children) {
        var _this = this;
        return !this.props.keepTabsMounted
            ? this.renderChild(React.Children.toArray(children)[this.props.selected], this.props.selected)
            : React.Children.map(this.props.children, function (tab, idx) {
                return _this.renderChild(tab, idx);
            });
    };
    TabStripContent.prototype.renderChild = function (tab, idx) {
        var visible = idx === this.props.selected;
        var contentProps = {
            'role': 'tabpanel',
            'aria-expanded': true,
            'aria-labelledby': idx.toString(),
            'style': {
                'display': visible ? undefined : 'none'
            }
        };
        var animationStyle = {
            position: 'initial',
            display: visible ? undefined : 'none'
        };
        if (tab.props.disabled) {
            return null;
        }
        return this.props.animation
            ? (React.createElement(Fade, { appear: true, exit: this.props.keepTabsMounted, style: animationStyle, childFactory: this.props.keepTabsMounted ? this.childFactory : undefined },
                React.createElement("div", __assign({}, contentProps, { id: String(this.contentId + idx), key: idx }), tab.props.children)))
            : (React.createElement("div", __assign({}, contentProps, { key: idx }), tab.props.children));
    };
    /**
     * @hidden
     */
    TabStripContent.propTypes = {
        animation: PropTypes.bool,
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element)
        ]),
        selected: PropTypes.number,
        style: PropTypes.object
    };
    return TabStripContent;
}(React.Component));
export { TabStripContent };
