"use strict";

import React from "react";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import { FilteredListUnwrapped } from "../widgets/tables/filtered_list";
import BatchesListTable from "./batches_list_table";
import * as PropTypes from "prop-types";

// i18next-extract-mark-ns-start batches
/**
 * This implements the batches & CoAs filtered lists in the data tab.
 */
class BatchesFilteredList extends FilteredListUnwrapped {
  constructor(props) {
    super(props);
  }

  get quickPanelAPI() {
    return this.table.quickPanel.current;
  }

  getFilteredRowsNum() {
    return this.table ? this.table.getFilteredRowsNum() : 0;
  }

  render() {
    const {t, onDataRefreshRequired} = this.props;

    const selectedFilter = this.state.filterObject;

    return (
      <BatchesListTable
        {...this.props}
        ref={ref => this.table = ref}
        editableDisplayName={t("Batch")}
        filter={selectedFilter}
        onDataRefreshRequired={onDataRefreshRequired}
        onShowRemovedToggle={this.handleShowRemovedToggle}
      />
    );
  }
}

BatchesFilteredList.propTypes = {
  handleArchivedOnClient: PropTypes.bool,
  hideNameColumn: PropTypes.bool,
  editableName: PropTypes.string,
};

BatchesFilteredList.defaultProps = {
  handleArchivedOnClient: true,
  hideNameColumn: true,
  editableName: "Batch",
};

export default I18NWrapper.wrap(BatchesFilteredList, ["batches", "documents"]);

// i18next-extract-mark-ns-stop batches