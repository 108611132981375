"use strict";

import * as UIUtils from "../../../ui_utils";
import { RequirementSummaryDataTransform } from "./requirement_summary_data_transform";
import {
  IntermediateSpecificationTransformStrategy
} from "./strategies/specification/intermediate_specification_transform_strategy";
import {
  RawMaterialSpecificationTransformStrategy
} from "./strategies/specification/raw_material_specification_transform_strategy";
import {
  ComponentSpecificationTransformStrategy
} from "./strategies/specification/component_specification_transform_strategy";
import {
  ProcessSpecificationTransformStrategy
} from "./strategies/specification/process_specification_transform_strategy";
import {
  ProductSpecificationTransformStrategy
} from "./strategies/specification/product_specification_transform_strategy";
import * as CommonEditablesFormatter from "../../../../server/common/editables/common_editables_formatter";

export class SpecificationDataTransform extends RequirementSummaryDataTransform {
  constructor(config, options) {
    super(config, options);
    this.strategies = new Map([
      ["Product", new ProductSpecificationTransformStrategy()],
      ["Process", new ProcessSpecificationTransformStrategy()],
      ["Intermediates", new IntermediateSpecificationTransformStrategy()],
      ["RawMaterial", new RawMaterialSpecificationTransformStrategy()],
      ["Components", new ComponentSpecificationTransformStrategy()],
    ]);
  }

  get type() {
    return "specification";
  }

  /**
   *
   * @param options
   * @returns {SpecificationTransformStrategy}
   */
  getStrategy(options) {
    const {filterOptions, reportOptions} = options;
    const {subReport} = filterOptions;
    const strategyType = subReport || reportOptions.defaultSubReport || this.defaultStrategy;

    if (this.strategies.has(strategyType)) {
      return this.strategies.get(strategyType);
    }
    throw new Error(`There is no transform strategy specified for sub-report '${strategyType}'`);
  }

  sortRecords(records, options) {
    const result = [...records];
    this.getStrategy(options).sortRecords(result, options);
    return result;
  }

  getModelNameForView(recordType) {
    return recordType;
  }

  fillGeneralInformation(record, requirement, options) {
    super.fillGeneralInformation(record, requirement, options);
    record.majorVersion = UIUtils.secureString(requirement.majorVersion);
    record.minorVersion = UIUtils.secureString(requirement.minorVersion);
    this.getStrategy(options).fillGeneralInformation(record, requirement, options);
  }

  fillParentInformation(record, requirement, options) {
    super.fillParentInformation(record, requirement, options);
    this.getStrategy(options).fillParentInformation(record, requirement, options);
  }

  fillControlInformation(record, requirement, options) {
    super.fillControlInformation(record, requirement);
    record.controlStrategy = UIUtils.secureString(
      CommonEditablesFormatter.formatControlStrategyForDisplay(requirement.controlStrategy, false)
    );
    record.controlStrategyJustification = UIUtils.secureString(requirement.controlStrategyJustification);
    this.getStrategy(options).fillControlInformation(record, requirement, options);
  }

  fillAcceptanceCriteria(record, requirement, options) {
    super.fillAcceptanceCriteria(record, requirement);
    this.getStrategy(options).fillAcceptanceCriteria(record, requirement, options);
  }

  getFilteredRecordTypes(options) {
    return this.getStrategy(options).getFilteredRecordTypes(options);
  }

  wrapUp(result, options) {
    let finalResult = super.wrapUp(result, options);
    return this.getStrategy(options).wrapUp(finalResult, options);
  }

  getSupportedRecordTypes() {
    return super.getSupportedRecordTypes().concat("attributes");
  }
}


