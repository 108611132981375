/* stylelint-disable-next-line import-notation */
@import "../../../../../css/brand_colors";

.container {
  display: inline-flex;
  gap: 12px;
  align-items: center;

  .slash-icon {
    &:global(.fa-slash) {
      color: #C0C8CC;
      font-size: 1.1em;
      font-weight: bold;
    }
  }
}

.drop-down-container {
  :global(button) {
    font-size: 1.2857rem;
    margin: unset;
    padding: 8px 12px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    gap: 10px;
    height: 38px;
    color: #000;
    font-family: Roboto, sans-serif;
    font-weight: 600;
    border: 1px solid transparent;
    outline: 0 none !important;
    box-shadow: none;

    &::after {
      cursor: pointer;
      display: none !important;
    }

    &:hover {
      box-shadow: unset;
      border: 1px solid #c3c3c2 !important;
      background-color: $brand-light-grey !important;
    }

    &:focus {
      box-shadow: unset;
      background-color: $brand-light-grey !important;
    }

    :global(.fa-caret-down) {
      display: none !important;
    }

    :global(.fa-chevron-down) {
      color: $brand-dark-grey !important;
    }
  }
}