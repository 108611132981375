import { BaseParser, createColorElement } from "./base_parser";
import * as UIUtils from "../../../ui_utils";
import { getCalculatedRiskAttributeContent } from "./elementParser/calculated_risk_attributes_parser";
import { CALCULATED_RISK_ATTRIBUTES } from "../../../../server/common/generic/common_constants";
import { getControlMethodValue } from "./elementParser/control_method_parser";
import { formatText } from "./elementParser/element_parser";
import { getSupplierValue } from "./elementParser/supplier_parser";
import * as DocumentLinkParser from "./elementParser/document_link_parser";
import { getCriticalityContent } from "./elementParser/criticality_parser";
import { getRiskLinks } from "../../../helpers/risk_helper";

/**
 * Parser that parses all QbD fields that are not in a widget
 */
export class DirectScopeWidgetParser extends BaseParser {
  get selector() {
    return ".qbd-output-direct-scope-widget";
  }

  get fieldKey() {
    return "directScopeWidgets";
  }

  getElements() {
    return Array.from(this.node.querySelectorAll(this.selector));
  }

  parse() {
    const directScopeElements = this.getElements();
    for (const directScopeElement of directScopeElements) {
      const id = UIUtils.convertToNumber(
        directScopeElement.getAttribute("data-record-id"),
      );
      const modelName = directScopeElement.getAttribute(
        "data-record-model-name",
      );
      const columnName = directScopeElement.getAttribute(
        "data-record-column-name",
      );
      const subModelName = directScopeElement.getAttribute(
        "data-record-sub-model-name",
      );
      const subModelDataStr = directScopeElement.getAttribute(
        "data-record-sub-model-data",
      );
      const modelField = this.addModelAndColumnToField(
        this.fields,
        modelName.replace(" ", ""),
      );
      const idToRecordField = this.addModelAndColumnToField(
        modelField,
        id,
        columnName,
      );
      if (subModelName) {
        this.addModelAndColumnToField(idToRecordField, subModelName);
      }

      if (!this.parseOnly && this.data && this.data[this.fieldKey]) {
        const data = this.data[this.fieldKey][modelName.replace(" ", "")];
        let record = null;
        if (data && Array.isArray(data)) {
          record = data.find((element) => element.id === id);
        }

        if (subModelName && record) {
          const subModelData = JSON.parse(subModelDataStr);
          if (subModelName === "Criticality") {
            const riskLinks = getRiskLinks(record) || [];
            record = riskLinks.find(
              (riskLink) => riskLink.id === subModelData.id,
            );
          } else if (subModelName === "AcceptanceCriteriaRange") {
            const acceptanceCriteriaRanges =
              record?.Requirement?.AcceptanceCriteriaRanges || [];
            record = acceptanceCriteriaRanges.find(
              (ac) =>
                ac.group === subModelData.group &&
                ac.label === subModelData.label,
            );
          } else if (DocumentLinkParser.isLinkAttribute(subModelName)) {
            const links = DocumentLinkParser.getValues(
              record,
              modelName,
              subModelName,
            );
            record = links.find(
              ({link}) =>
                link.name === subModelData?.link?.name ||
                link.uuid === subModelData?.link?.uuid,
            );
          }
        }

        const actualValue = getActualValue(
          record,
          subModelName || modelName,
          columnName,
          this.rmp,
        );
        if (actualValue?.hasField) {
          if (actualValue.element) {
            directScopeElement.outerHTML = actualValue.element.outerHTML;
          } else {
            directScopeElement.outerHTML = UIUtils.secureString(actualValue.content || "");
          }
        } else {
          directScopeElement.outerHTML =
            this.createErrorElement(directScopeElement, "direct-scope-widget-error");
        }
      }
    }
    return directScopeElements;
  }
}

export function getActualValue(record, modelName, columnName, rmp) {
  if (!record) {
    return null;
  }

  const result = {
    hasField: true,
    isRiskCalculatedField: false,
    element: null,
    content: record[columnName],
  };
  if (modelName === "Criticality") {
    result.content = getCriticalityContent(record, columnName, rmp);
    if (["riskLabelColor", "scoreLabelColor"].includes(columnName)) {
      result.element = createColorElement(result.content);
    }
  } else if (DocumentLinkParser.isLinkAttribute(modelName)) {
    const {link} = record;
    if (link) {
      if (columnName === "linkOrAttachment") {
        if (link.linkType === "Attachment") {
          result.content = link.fileName;
        } else {
          result.content = link.link;
        }
      } else {
        result.content = link[columnName];
      }
    }
  } else if (CALCULATED_RISK_ATTRIBUTES.includes(columnName)) {
    result.isRiskCalculatedField = true;
    result.content = getCalculatedRiskAttributeContent(record, columnName, rmp);
    if (["riskLabelColor", "scoreLabelColor"].includes(columnName)) {
      result.element = createColorElement(result.content);
    }
  } else if (columnName === "controlMethod") {
    result.content = getControlMethodValue(record);
  } else if (columnName === "supplier") {
    result.content = getSupplierValue(record);
  } else if (!(columnName in record)) {
    result.hasField = false;
  }

  result.content = formatText(result.content);

  return result;
}
