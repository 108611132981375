/* stylelint-disable-next-line import-notation */
@import "../css/_brand_colors.scss";

.document-editor-button-group {
  button:not(:last-child) {
    margin-right: 5px;
  }
}

/* Override Telerik editor style */
:global(#document-editor #toolbar .k-toolbar) {
  border: none;
  background-color: initial;

  .k-button {
    border: none;
    background-color: $brand-white;
  }
}

:global(#document-editor #toolbar .k-toolbar .k-button:not(.k-selected)) {
  border: none;
  background-color: $brand-white;
}

:global(#document-editor #toolbar .k-toolbar .k-button-group:not(:last-child)) {
  border-right: 1px solid $brand-mild-grey;
  padding-right: 3px;
}

:global(#document-editor #toolbar .k-toolbar .k-dropdownlist) {
  margin-right: 4px;
  width: 14em;
}
