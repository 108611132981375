"use strict";

import FQA from "../product/editors/fqa";
import FPA from "../product/editors/fpa";
import Material from "../processExplorer/assets/material";
import MaterialAttribute from "../processExplorer/variables/material_attribute";
import IQA from "../processExplorer/variables/iqa";
import IPA from "../processExplorer/variables/ipa";
import GeneralAttribute from "../product/editors/general_attribute";
import Process from "../processExplorer/parentPages/process";
import Batch from "../batches/batch";
import ProcessComponent from "../processExplorer/assets/process_component";
import ProcessParameter from "../processExplorer/variables/process_parameter";
import UnitOperation from "../processExplorer/operations/unit_operation";
import ControlMethod from "../controlMethod/control_method";
import TPPSection from "../product/editors/tppSection";
import Project from "../projects/project";
import Supplier from "../suppliers/supplier";
import Training from "../training/editors/training";
import RMP from "../rmps/rmp";
import Document from "../documents/document";
import Curriculum from "../training/editors/curriculum";
import Step from "../processExplorer/operations/step";
import LibraryMaterial from "../library/editor/library_material";


/**
 * Defines the mapping between model type codes to their respective editors.
 * @type {Map<string, React.Component>}
 */
export const EDITOR_COMPONENT_MAP = new Map([
  ["FQA", FQA],
  ["FPA", FPA],
  ["MT", Material],
  ["MA", MaterialAttribute],
  ["IQA", IQA],
  ["IPA", IPA],
  ["GA", GeneralAttribute],
  ["PRC", ProcessComponent],
  ["PP", ProcessParameter],
  ["UO", UnitOperation],
  ["CM", ControlMethod],
  ["TPP", TPPSection],
  ["PRJ", Project],
  ["SUP", Supplier],
  ["RMP", RMP],
  ["DOC", Document],
  ["CUR", Curriculum],
  ["ITP", Training],
  ["PR", Process],
  ["BAT", Batch],
  ["STP", Step],
  ["MTL", LibraryMaterial]
]);
