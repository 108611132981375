"use strict";

import { useMenu } from "../menu_provider";
import * as pillStyles from "../filter.module.scss";
import React from "react";

export const More = ({more}) => {

  const {toggle, isOpen} = useMenu();

  if (!more.length) {
    return null;
  }

  return (
    <div className={`${pillStyles["filter-entry"]} ${isOpen && pillStyles["menu-open"]}`} onClick={toggle}>
      <span className={pillStyles["show-more"]}>{more.length} More</span>
    </div>
  );
};