"use strict";

import * as UIUtils from "../../../ui_utils";
import OptionsMenuBuilder from "../../../editor/options_menu_builder";
import { getURLByTypeCodeAndId } from "../../../helpers/url_helper";
import * as styles from "./style/pills_cell.module.scss";
import * as commonStyles from "./style/common_cell.module.scss";
import { RISK_COLORS } from "../../../rmps/constants/rmp_constants";
import { downloadFile } from "../../../import/helpers/import_helper";
import { EMPTY_STRING } from "../../../helpers/constants/constants";

export const ELEMENT_TYPE = {
  DIV: "div",
  SPAN: "span"
};

export const addProjectParameterToUrl = () => {
  const projectId = UIUtils.parseInt(UIUtils.getParameterByName("projectId"));
  if (projectId) {
    return "&projectId=" + projectId;
  }
  return EMPTY_STRING;
};

export const onColumnActionsClick = (ref, setMenuActive) => event => {
  const {target} = event;
  const isOperationIcon = target?.classList.contains(commonStyles["column-operation-icon"]);

  // Click inside the ref element
  if (ref.current.contains(target) && isOperationIcon) {
    setMenuActive((prevActive) => !prevActive);
  } else {
    // Click outside the ref element
    setMenuActive(false);
  }
};

export const buildOptions = (row, dataIndex, onCheckPermissions, handleArchiveButton, handleRestoreButton, t) => {

  const {typeCode} = row;
  const securityTypeName = UIUtils.getModelNameForTypeCode(typeCode);
  const optionsMenuBuilder = new OptionsMenuBuilder(row, securityTypeName, null, null, onCheckPermissions, t);

  optionsMenuBuilder.addView({
    id: "viewButton_" + dataIndex,
    title: `View ${UIUtils.getModelNameForTypeCode(typeCode)} Details`,
    url: getURLByTypeCodeAndId(typeCode, "View", row.id) + addProjectParameterToUrl(),
    openUrlInNewTab: true
  });

  optionsMenuBuilder.addEdit({
    id: "editButton_" + dataIndex,
    url: getURLByTypeCodeAndId(typeCode, "Edit", row.id) + addProjectParameterToUrl(),
    openUrlInNewTab: true
  });

  optionsMenuBuilder.addArchive({
    id: "archiveButton_" + dataIndex,
    onClick: () => {
      handleArchiveButton(row);
    },
  });

  optionsMenuBuilder.addRestore({
    id: "restoreButton_" + dataIndex,
    onClick: () => {
      handleRestoreButton(row);
    },
  });

  return optionsMenuBuilder.build();
};
export const toggleMenuActiveClass = (ref, isMenuActive) => {
  const iconElement = ref?.current.querySelector(`.${commonStyles["column-operation-icon"]}`);
  if (iconElement) {
    if (isMenuActive) {
      iconElement.classList.add(commonStyles["operation-menu-active"]);
    } else {
      iconElement.classList.remove(commonStyles["operation-menu-active"]);
    }
  }
};

/**
 * Update the Grid Header Column Operation's Icon.
 * @param ref
 */
export const setColumnOperationIcon = ref => {
  const {current} = ref;
  if (current) {
    const iconElement = current.querySelector(".k-icon");
    if (iconElement) {
      iconElement.classList.replace("k-i-more-vertical", "k-i-arrow-chevron-down");
      iconElement.classList.add(commonStyles["column-operation-icon"]);
    }
  }
};

export const createOffScreenElement = (type, cssClass) => {
  const element = document.createElement(type);
  element.className = cssClass;
  element.style.position = "absolute";
  element.style.left = "-9999px";
  element.style.visibility = "hidden";
  document.body.appendChild(element);
  return element;
};

export const createHtmlElement = (type, cssClass, parent, width, textContent) => {
  const element = document.createElement(type);
  element.classList.add(cssClass);
  element.style.visibility = "hidden";
  if (width) {
    element.style.width = `${width}px`;
  }
  if (textContent) {
    element.textContent = textContent;
  }
  parent.appendChild(element);
  return element;
};

const buildPillBoxes = (measurementContainer, pills, cache) => {
  let width;
  let pillElement;
  const pillBoxes = [];

  for (const pill of pills) {
    const key = pill.length;
    if (cache[key]) {
      width = cache[key];
    } else {
      if (!pillElement) {
        // Create pillElement once inside measurementContainer for width measurement if we can't get the width from cache.
        pillElement = createHtmlElement(ELEMENT_TYPE.SPAN, styles["pill-item"], measurementContainer);
      }
      pillElement.textContent = pill; // Update textContent for each pill
      width = pillElement.offsetWidth;
      cache[key] = width;
    }

    pillBoxes.push({content: pill, width});
  }

  // Cleanup: remove pillElement after measurements
  if (pillElement) {
    pillElement.remove();
  }

  return pillBoxes;
};

module.exports.getColorCode = function(riskColorClass) {
  if (typeof riskColorClass !== "string" || !riskColorClass) {
    return "";
  }
  const color = riskColorClass.split("-").pop();
  switch (UIUtils.capitalize(color)) {
    case RISK_COLORS.BLUE:
      return ["#1D88EA", "#E5F3FF"];
    case RISK_COLORS.GREEN:
      return ["#54AA93", "#EEF6F4"];
    case RISK_COLORS.YELLOW:
      return ["#FFC107", "#FEFDED"];
    case RISK_COLORS.ORANGE:
      return ["#F29D39", "#FEF5EB"];
    case RISK_COLORS.GREY:
      return ["#808080", "#F0F3F5"];
    case RISK_COLORS.RED:
      return ["#BD401E", "#F8ECE8"];
  }
};

module.exports.getLinkAttributes = function(link, bullet) {
  const linkTypeMap = {
    Attachment: {
      id: "downloadImportFile",
      onClick: downloadFile.bind(this, link),
      text: `${bullet}${link.name || link.fileName}`,
      type: "File",
    },
    Link: {
      href: `${UIUtils.cleanUpURL(link.link)}`,
      text: `${bullet}${link.name || link.link}`,
      type: "Link",
    },
  };
  const {name, linkType, typeCode} = link;
  const {href, onClick, id, text, type} = linkTypeMap[linkType] || {};
  return {
    id,
    type,
    onClick,
    href: href || !linkType && typeCode && getURLByTypeCodeAndId(link.typeCode, "View", link.id),
    text: text || !linkType && `${bullet}${name}`,
  };
};

module.exports.buildPillRows = function(divElement, pills, cache) {

  const maxRows = 2;
  const maxWidth = divElement?.offsetWidth;

  const measurementContainer = createOffScreenElement(ELEMENT_TYPE.DIV, styles["container"]);
  const pillBoxes = buildPillBoxes(measurementContainer, pills, cache);

  let totalWidth = 0;
  let currentRowIndex = 0;
  const rowStyle = new Map();
  const rows = [[]];

  for (const pill of pillBoxes) {
    const {width} = pill;

    if (totalWidth + width < maxWidth) {
      rows[currentRowIndex].push(pill);
      totalWidth += width;
    } else {
      if (currentRowIndex < maxRows - 1) {
        currentRowIndex++;
        rows[currentRowIndex] = [pill];
        totalWidth = width;
      } else {

        const overlayContent = pillBoxes.slice(rows.flat().length);
        const [morePillsBox] = buildPillBoxes(measurementContainer, [`+${overlayContent.length}`], cache);
        const {width} = morePillsBox;
        const row = rows[currentRowIndex];

        if (totalWidth + width < maxWidth) {
          row.push({...morePillsBox, overlayContent: pills});
        } else {
          row[row.length - 1] = {
            ...morePillsBox,
            content: `+${overlayContent.length + 1}`,
            overlayContent: pills
          };
        }
        break;
      }
    }
  }

  measurementContainer.remove();

  return rows
    .filter(row => row.length)
    .map((row, idx) => {
      const style = rowStyle.get(idx) || {};
      return {
        ...style,
        row
      };
    });
};