"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import ImportRecordInstructions from "./import_record_instructions";
import { can, generateTooltip } from "../../../utils/ui_permissions";
import { getImportConfigFromKey } from "../../constants/import_constants";
import BaseReactComponent from "../../../base_react_component";

export default class ImportRecordWidget extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  handleImportNewRecord() {
    const {importKey, projectId, projectType, processId, selectedTab} = this.props;
    const importConfig = getImportConfigFromKey(importKey);
    const {importProjectData} = importConfig;

    if (importProjectData) {
      let url = "/import/importProject.html?isProjectImport=true";
      url += projectId ? `&projectId=${projectId}` : "";
      url += projectType ? `&projectType=${projectType}` : "";
      url += processId ? `&processId=${processId}` : "";
      url += selectedTab ? `&selectedTab=${selectedTab}` : "";
      window.location.href = UIUtils.getSecuredURL(url);
    } else {
      window.location.href = UIUtils.getSecuredURL(`/import/importFile.html?key=${importKey}`);
    }
  }

  render() {
    const {importKey} = this.props;
    const importConfig = getImportConfigFromKey(importKey);
    const {
      title,
      modelName,
      importButtonTitle,
      instructions,
      showDownloads,
      importProjectData,
      showInstructions,
      securityAction,
      hasProjectTypeDependedTemplates,
    } = importConfig;

    const hasPermission = can(securityAction, modelName);
    return (
      <div className={"import-record-widget col-sm-6 col-md-3"}>
        <div className="row">
          <div className="import-record-widget-content col-sm-12">
            <div className="row">
              <div className="col-sm-12 import-record-widget-title">
                {title}
              </div>
              <div className="import-record-widget-btn col-sm-12">
                <button className="btn btn-lg btn-primary long-text-button"
                        id={"importNew" + modelName}
                        onClick={this.handleImportNewRecord}
                        title={generateTooltip(securityAction, modelName, null, "Import")}
                        disabled={!hasPermission}
                >
                  {importButtonTitle}
                </button>
              </div>
              <ImportRecordInstructions
                modelName={modelName}
                instructions={instructions}
                showDownloads={showDownloads}
                showNewProjectLink={importProjectData}
                showInstructions={showInstructions}
                hasProjectTypeDependedTemplates={hasProjectTypeDependedTemplates}
                className="import-record-widget-instructions-panel"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
