"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import Section from "../../editor/widgets/section";
import TextAttribute from "../../editor/attributes/text_attribute";
import CheckboxAttribute from "../../editor/attributes/checkbox_attribute";
import TextAreaAttribute from "../../editor/attributes/text_area_attribute";
import TypeaheadAttribute from "../../editor/attributes/typeahead_attribute";
// i18next-extract-mark-ns-start process_explorer
// noinspection JSFileReferences
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseQuickEditor from "../../editor/base_quick_editor";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import { EDITOR_TYPES } from "../../editor/editor_constants";
import { EMPTY_STRING } from "../../helpers/constants/constants";
import ToggleInput from "react-switch";

/**
 * This is the class that renders the Add/Edit/View process screen.
 */
class Process extends BaseQuickEditor {
  constructor(props) {
    super(props, "process", "Process", "Process (PR)");

    // Bind stuff
    this.getHTMLURLPrefix = Process.getHTMLURLPrefix;

    this.projectId = UIUtils.parseInt(UIUtils.getParameterByName("projectId"));
    if (this.isRecordCached()) {
      // For when this record is loaded in the side panel.
      // For unknown reason isProcessTypeaheadLoaded is lost when the record is edited in a quick panel.
      // As a workaround, if cacheData exists we assume all the required data is loaded
      this.setStateSafely({
        enableSendingSite: this.state.techTransferEnabled,
        isProcessTypeaheadLoaded: true
      });
    }
  }

  componentDidMount() {
    document.title = "QbDVision Process";

    super.componentDidMount();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (super.shouldComponentUpdate(nextProps, nextState)) {
      return true;
    }

    return this.state.isProcessTypeaheadLoaded !== nextState.isProcessTypeaheadLoaded;
  }

  isLoading() {
    return super.isLoading();
  }

  getTypesToCache() {
    return ["Process", "Supplier", ...super.getTypesToCache()];
  }

  static getHTMLURLPrefix() {
    return "/processExplorer/process";
  }

  getInfoTooltip() {
    return (
      <InfoTooltip id="infoPR"
                   fdaGuidanceURL="https://www.fda.gov/files/drugs/published/Process-Validation--General-Principles-and-Practices.pdf"
                   verbiage={
                     <div>
                       A Process models real and potential manufacturing processes. Each process contains its own distinct records (e.g., Process Components, Parameters). By creating a process for each variation, site, and scale in your manufacturing, you can more easily understand and assess changes across your project.
                     </div>
                   }
      />
    );
  }

  handleTypeaheadResultsFromServer(callback, results, typeCode, remainingTypesToCache) {
    if (typeCode === "PR") {
      this.setStateSafely({isProcessTypeaheadLoaded: true}, () => {
        super.handleTypeaheadResultsFromServer(callback, results, typeCode, remainingTypesToCache);
      });
    }
  }

  handleSaveResults(result) {
    if (this.getEditorType() !== EDITOR_TYPES.STATIC_PANEL) { // The main panel will take care of reloading typeaheads.
      this.getProcessCache().invalidateCacheOptionsAsync().then(() => {
        this.getProcessCache().loadOptions(() => {
          super.handleSaveResults(result);
        });
      });
    } else {
      super.handleSaveResults(result);
    }
  }

  handleSave(event, callback) {
    UIUtils.setLoadingDisabled(false);
    super.handleSave(event, callback);
  }

  preprocessReceivedData(result) {
    // For when this record is loaded full screen.
    result.enableSendingSite = result ? result.techTransferEnabled : false;
    return super.preprocessReceivedData(result);
  }

  handleChangeValue(attributeName, attributeValue, callback, attributeType) {
    super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
    if (attributeName === "enableSendingSite") {
      if (!this.state.SendingId && this.state.clonedFrom) {
        this.handleChangeValue("SendingId", this.state.clonedFrom.ProcessId);
      }
      this.handleChangeValue("techTransferEnabled", !!attributeValue);
    }
  }

  validateClientInstance() {
    const {id, name} = this.state;
    const processes = this.getProcessCache().getOptionsFromCache();
    const duplicatedProcesses = processes.filter(process => process.name === name && process.id !== id);
    if (duplicatedProcesses.length > 0) {
      throw new Error("You can not use a process name that already exists.");
    }
    super.validateClientInstance();
  }

  getProcessCache() {
    if (!this.processCache || this.processCache.projectId !== this.getProjectId()) {
      this.processCache = new TypeaheadObjectCache("Process", this.getProjectId());
    }
    return this.processCache;
  }

  getTabName() {
    return "Process Explorer";
  }

  getSendingProcess() {
    const {SendingId, clonedFrom} = this.state;
    const projectId = this.getProjectId();
    const processes = this.getProcessCache().getOptionsFromCache();
    let sendingProcess = !processes.isLoading ?
      processes.find(process => process.id === (SendingId || clonedFrom?.ProcessId)) : null;

    if (sendingProcess) {
      sendingProcess.projectId = projectId;
    }

    return sendingProcess;
  }


  renderLoadingSkeleton(configureByType) {
    return (
      <div className="attribute-container col-sm-6">
        {
          this.state.clonedFrom &&
          configureByType === true &&
          this.state.isProcessTypeaheadLoaded !== true
            ?
            <div className={"view-attribute skeleton"}>
              <span id="skeletonPlaceHolder"></span>
            </div>
            : EMPTY_STRING
        }
      </div>
    );
  }


  handleChangeTechTransfer() {
    if (this.state.techTransferEnabled) {
      this.handleChangeValue("enableSendingSite", false);
    } else if ((this.state.SendingId || this.state.clonedFrom) && !this.state.techTransferEnabled) {
      this.handleChangeValue("enableSendingSite", true);
    } else {
      this.loadLinkTechTransferPage();
    }
  }

  loadLinkTechTransferPage() {
    const {project, id} = this.state;

    const url = "/processExplorer/linkTechTransfer/link.html?" + `projectId=${project.id}` + `&processId=${id}`;
    window.location.href = UIUtils.getSecuredURL(url);
  }

  renderAttributes() {
    const {t} = this.props;
    return (
      <div>
        <Section parent={this}
                 header={this.getGeneralHeader()}
                 collapsible={false}
        >
          <div className="row">
            <TextAttribute name="name"
                           parent={this}
            />
          </div>
          <div className="row">
            <TextAreaAttribute name="description"
                               className="col-sm-12"
                               parent={this}
            />
          </div>
          <div className="row">
            <TextAttribute name="site"
                           className="col-sm-6"
                           tooltipText="The location where this process is expected to execute."
                           parent={this}
            />
            <CheckboxAttribute name="gmp"
                               tooltipText="Whether this process is designed to follow Good Manufacturing Practices (GMP)."
                               parent={this}
            />
          </div>
          <div className="row">
            <TextAttribute name="scale"
                           tooltipText="The scale of the process (e.g., 1000 Liters)."
                           parent={this}
            />
            <TypeaheadAttribute name="supplier"
                                typeaheadType="Supplier"
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
            />
          </div>
        </Section>
        {!this.isAdd() ?
          <Section id="techTransfer"
                   parent={this}
                   collapsible={false}
                   header={<span>
                     <ToggleInput id="enableSendingSiteInput"
                                  className="control-checkbox-toggle"
                                  checked={!!this.state.techTransferEnabled}
                                  onChange={this.handleChangeTechTransfer}
                                  disabled={this.isView()}
                                  height={22}
                                  width={38}
                                  checkedIcon={false}
                                  uncheckedIcon={false}
                                  activeBoxShadow="0 0 2px 3px #014768"
                                  onColor="#DBE1E4"
                                  offColor="#DBE1E4"
                                  onHandleColor="#1F46A1"
                     /> &nbsp;Tech Transfer
                   </span>}
          >
            {this.getSendingProcess() ?
              <span className={this.isView() ? "disabled" : ""}>
                Sending Process: <b className={!this.isView() ? "tech-transfer-process" : ""}>
                {UIUtils.getRecordCustomLabelForDisplay(this.getSendingProcess())}
              </b>
                {this.state.clonedFrom ?
                  <InfoTooltip id="techTransferTooltip"
                               verbiage={<div>
                                 {t(`This process is copied from 
                               ${UIUtils.getRecordCustomLabelForDisplay(this.getSendingProcess())}, 
                               so we’ve automatically selected that as Sending Process.`)}
                               </div>}
                  /> : null}
              </span>
              : null
            }
          </Section> : null
        }
        <Section id="integrations"
                 parent={this}
                 showIntegrationsTable={true}
                 header={<span>Integrations</span>}
        />
        <Section id="references"
                 parent={this}
                 showDocLinks={true}
                 addOptions={[
                   {id: "references", label: "References"},
                   {id: "standards", label: "Standards"},
                   {id: "guidances", label: "Guidances"}]}
                 header={<span>
                   References & Standards
                   <InfoTooltip id="infoRefAndStandardsControl"
                                verbiage={
                                  <div>
                                    {t(`References, standards, safety data sheets, etc. for highly characterized specimens
                                    of drug substances, excipients, food ingredients, impurities, degradation products,
                                    dietary supplements, compendial reagents, tests, assay, and performance calibrators.
                                    (e.g. USP, BP, EP, JP, ISO, etc.)`)}
                                  </div>}
                   />
                   </span>}
        >
        </Section>
      </div>
    );
  }
}

export default I18NWrapper.wrap(Process, ["process_explorer", "editor"]);
// i18next-extract-mark-ns-stop process_explorer
