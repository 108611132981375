import { messages } from './messages';
/**
 * @hidden
 */
export var filterLogicList = [
    { text: 'grid.filterAndLogic', operator: 'and' },
    { text: 'grid.filterOrLogic', operator: 'or' }
];
/**
 * @hidden
 */
export var operators = {
    'text': [
        { text: 'grid.filterContainsOperator', operator: 'contains' },
        { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
        { text: 'grid.filterEqOperator', operator: 'eq' },
        { text: 'grid.filterNotEqOperator', operator: 'neq' },
        { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
        { text: 'grid.filterEndsWithOperator', operator: 'endswith' },
        { text: 'grid.filterIsNullOperator', operator: 'isnull' },
        { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' },
        { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
        { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' }
    ],
    'numeric': [
        { text: 'grid.filterEqOperator', operator: 'eq' },
        { text: 'grid.filterNotEqOperator', operator: 'neq' },
        { text: 'grid.filterGteOperator', operator: 'gte' },
        { text: 'grid.filterGtOperator', operator: 'gt' },
        { text: 'grid.filterLteOperator', operator: 'lte' },
        { text: 'grid.filterLtOperator', operator: 'lt' },
        { text: 'grid.filterIsNullOperator', operator: 'isnull' },
        { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' }
    ],
    'date': [
        { text: 'grid.filterEqOperator', operator: 'eq' },
        { text: 'grid.filterNotEqOperator', operator: 'neq' },
        { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
        { text: 'grid.filterAfterOperator', operator: 'gt' },
        { text: 'grid.filterBeforeOperator', operator: 'lt' },
        { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' },
        { text: 'grid.filterIsNullOperator', operator: 'isnull' },
        { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' }
    ],
    'boolean': [
        { text: 'grid.filterEqOperator', operator: 'eq' }
    ]
};
/**
 * @hidden
 */
export var IsUnaryFilter = function (operator) {
    return operator === 'isnull' || operator === 'isnotnull' || operator === 'isempty' || operator === 'isnotempty';
};
/**
 * @hidden
 */
export var operatorMap = function (iterable, service) { return iterable.map(function (operator) { return ({
    text: service.toLanguageString(operator.text, messages[operator.text]),
    operator: operator.operator
}); }); };
/**
 * @hidden
 */
export var defaultBooleanOperator = 'eq';
/**
 * @hidden
 */
export var booleanFilterValues = [
    { text: 'grid.filterBooleanAll', operator: '' },
    { text: 'grid.filterIsTrue', operator: true },
    { text: 'grid.filterIsFalse', operator: false }
];
/**
 * @hidden
 */
export var defaultHideSecondFilter = {
    text: false,
    numeric: false,
    date: false,
    boolean: true
};
/**
 * @hidden
 */
export var cellInputChange = function (value, e, props) {
    var defaultOperator = getDefaultOperator(props.operators);
    var operator = props.operator;
    switch (props.filterType) {
        case 'numeric':
            if (!operator || IsUnaryFilter(operator)) {
                // change the operator to default
                operator = defaultOperator;
            }
            if (value === null && operator === defaultOperator) {
                // clear only the default operator
                operator = '';
            }
            break;
        case 'date':
            if (!operator || IsUnaryFilter(operator)) {
                operator = defaultOperator;
            }
            if (value === null && operator === defaultOperator) {
                operator = '';
            }
            break;
        case 'text':
            if (!operator || IsUnaryFilter(operator)) {
                operator = defaultOperator;
            }
            if (!value && operator === defaultOperator) {
                operator = '';
            }
            break;
        default: return;
    }
    props.onChange({ value: value, operator: operator, syntheticEvent: e });
};
/**
 * @hidden
 */
export var getDefaultOperator = function (filterOperators, filterType) {
    if (filterType) {
        return filterOperators[filterType][0].operator;
    }
    else {
        return filterOperators[0].operator;
    }
};
/**
 * @hidden
 */
export var getFilterType = function (filterType) {
    return filterType || 'text';
};
/**
 * @hidden
 */
export var cellBoolDropdownChange = function (event, onChange) {
    var item = event.target.value;
    onChange({
        value: item.operator,
        operator: item.operator === '' ? '' : defaultBooleanOperator,
        syntheticEvent: event.syntheticEvent
    });
};
/**
 * @hidden
 */
export var cellOperatorChange = function (event, currentValue, onChange) {
    var value = currentValue;
    var item = event.target.value;
    if (!event.target.state.opened) {
        return;
    }
    if (IsUnaryFilter(item.operator)) {
        // clear the value to avoid confusion on what is filtered
        value = null;
    }
    if (currentValue === null && !IsUnaryFilter(item.operator)) {
        value = undefined;
    }
    onChange({ value: value, operator: item.operator, syntheticEvent: event.syntheticEvent });
};
