"use strict";

import React from "react";
import FieldTooltip from "../../../../widgets/tooltips/field_tooltip";
import BaseReactComponent from "../../../../base_react_component";

/**
 * This implements the file picker button for the Smart Upload Import flow.
 */
export default class SmartUploadFilePicker extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevPropsIgnore, prevStateIgnore, snapshotIgnore) {
    let {selectedFile} = this.props;
    if (!selectedFile) {
      $("#smartUploadDocumentInput").prop("value", "");
    }
  }

  handleFileSelected(e) {
    let input = $("#" + e.target.id)[0];
    let selectedFile = input.files && input.files[0];
    if (selectedFile) {
      this.props.onFileSelected(selectedFile);
    }
  }

  handleReplaceDocument() {
    $("#smartUploadDocumentInput").prop("value", "");
  }

  render() {
    const {allowedFiles} = this.props;
    let {selectedFile} = this.props;

    return (
      <div className="smart-select-document-selection-div">
        <span className="col-form-label">Document
          <span className={selectedFile ? "base-attribute-value-specified" : "base-attribute-value-missing"}>
            {" *"}
          </span>
        </span>
        {selectedFile ? (<span>{selectedFile.name}</span>) : ""}
        <div className="smart-select-dependency-field-with-tooltip-div">
          <label id={"smartUploadDocumentLabel"}
                 className={selectedFile ? "" : "btn btn-lg btn-secondary"}
          >
            {selectedFile ? (
              <a>{"Replace Document"}</a>
            ) : (
              <span>{"Upload from local drive"}</span>
            )}
            <input id="smartUploadDocumentInput"
                   type="file"
                   accept={allowedFiles}
                   className="links-file-input form-control"
                   onChange={this.handleFileSelected}
                   onClick={this.handleReplaceDocument}
            />
          </label>
          <FieldTooltip id="coaFilePickerField"
                        text="Tip: High-quality PDFs and images work best."
          />
        </div>
      </div>
    );
  }
}
