import { BrowserSupportService } from './browser-support.service';
import { canUseDOM } from './canUseDOM';
/**
 * @hidden
 */
export var getScrollbarWidth = function () {
    if (!canUseDOM || !document.body) {
        return false;
    }
    var scrollbarWidth = new BrowserSupportService().scrollbarWidth;
    return scrollbarWidth;
};
/**
 * @hidden
 */
export var setScrollbarWidth = function () {
    if (!canUseDOM || !document.body) {
        return false;
    }
    var scrollbarWidth = new BrowserSupportService().scrollbarWidth;
    document.body.style.setProperty('--kendo-scrollbar-width', "".concat(scrollbarWidth, "px"));
};
