"use strict";

import * as UIUtils from "../../ui_utils";
import React, {ReactNode} from "react";
import FieldTooltip from "../../widgets/tooltips/field_tooltip";
import LabelTooltip from "../../widgets/tooltips/label_tooltip";
// @ts-ignore
// noinspection JSFileReferences
import CommonModelVerifier from "../../../server/common/verification/common_model_verifier.js";
import ValidationIcon from "../../widgets/generic/validation_icon.jsx";
import BaseReactComponent, {IBaseComponentStateAndProps} from "../../base_react_component";

import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Framework, "UIUtils");

export interface IAttributeParent extends React.Component {
  id: string;
  capitalizedBaseTypeName: string;

  // eslint-disable-next-line no-unused-vars
  addRef(ref: any): void;
  // eslint-disable-next-line no-unused-vars
  initializeValue(name: string, value: any): void;

  getCurrentOperation(): string;

  state: IBaseAttributeState;

  handleChildDidMount(): void;

  // eslint-disable-next-line no-unused-vars
  handleChangeValue(name: string, value: any): void;

  isInProposeValidationMode(): boolean;

  getInstance(): any;

  isDiffingVersions(): boolean;

  // eslint-disable-next-line no-unused-vars
  getOlderVersion(state?: IBaseComponentStateAndProps): any;
}

export interface IAttributeProps extends IBaseComponentStateAndProps {
  id?: String;
  name: string;
  parent: IAttributeParent | any;
  editorOperation?: string;
}

export interface IBaseAttributeState extends IBaseComponentStateAndProps {
}

/**
 * This is the base class for widgets (like a text field, drop down or typeahead) that saves data back to the database.
 * To extend this, you must implement renderInput() and getInitialValue().
 *
 * NOTE: IF YOU AREN'T USING THIS OR IT'S CHILDREN FOR SAVING DATA TO A RECORD, IT's NOT WHAT YOU ARE LOOKING FOR.
 */
abstract class BaseAttribute<TValue, P extends IAttributeProps, S extends IBaseAttributeState> extends BaseReactComponent<P, S> {
  protected id: string;
  protected isParentInitializedWithDefault: boolean;
  protected previousIsLoading: boolean;
  protected recordId: number;
  protected recordVersionId: number;
  protected olderRecordVersionId: number;
  protected requirementId: number;
  protected requirementVersionId: number;
  protected parentIsLoading: boolean;

  static defaultProps = {
    className: "col-sm-6",
    type: "text"
  };

  constructor(props) {
    super(props);

    // Lookup parameters
    if (this.isEdit() || this.isView()) {
      this.id = this.props.parent.id;
    }

    // Let the parent know we exist so the section can find us
    this.props.parent.addRef(this);

    // This is needed so the typeahead doesn't try to re-initialize with the default value on every keystroke
    this.isParentInitializedWithDefault = false;
    this.initializeParentValueIfNecessary();

    // This is needed because in shouldComponentUpdate, `nextProps.parent === this.props.parent` so we can't tell if
    // the loading has changed or not.
    this.previousIsLoading = this.isLoading();
  }

  /**
   * Make sure the parent value is set if it's missing.
   */
  initializeParentValueIfNecessary() {
    if (!this.isLoading()) {
      let parentValue = this.props.parent.state[this.getAttributeName()];
      if (!this.isParentInitializedWithDefault && this.isAdd() && (parentValue === null || parentValue === undefined)) {
        const newValue = this.getValue();
        if (newValue !== null && newValue !== undefined) {
          this.props.parent.initializeValue(this.getAttributeName(), newValue);
          this.isParentInitializedWithDefault = true;
        }
      } else if (parentValue !== null && parentValue !== undefined) {
        this.isParentInitializedWithDefault = true;
      }
    }
  }

  /**
   * @inheritDoc
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    UIUtils.Ensure.virtual("componentDidUpdate", {prevProps, prevState, snapshot});
    this.initializeParentValueIfNecessary();
  }

  /**
   * @abstract
   * @return {*} the default value that this attribute should be if the user is adding a new record or if this attribute is
   * not defined when loading the record for add.
   */
  abstract getInitialValue(): TValue;

  // Override this in child classes to return the right attribute name matching the attribute on the model.
  getAttributeName() {
    return this.props.name;
  }

  getCurrentOperation() {
    const {editorOperation} = this.props;
    return editorOperation ? editorOperation : this.props.parent.getCurrentOperation();
  }

  isView() {
    return this.getCurrentOperation() === "View";
  }

  isAdd() {
    return this.getCurrentOperation() === "Add";
  }

  isEdit() {
    return this.getCurrentOperation() === "Edit";
  }

  // Overwrite in parent controls to implement custom validation.
  validate() {
    return true;
  }

  clearValidationErrors() {
  }

  // Triggers the handleChildDidMount event on the parent to deal with controls being dynamically created on the UI.
  // The parent control calls the form.validator('update') method to register those controls to the bootstrap validator plugin.
  componentDidMount() {
    super.componentDidMount();
    this.props.parent.handleChildDidMount();
  }

  getDisplayName() {
    return this.getCapitalizedName();
  }

  getCapitalizedName() {
    if (this.props.displayName) {
      return this.props.displayName;
    } else {
      return UIUtils.convertCamelCaseToSpacedOutWords(this.getAttributeName());
    }
  }

  handleChange(event) {
    // Uncomment for verbose logging
    // console.log("Changing " + this.props.name + " to '" + event.target.value + "'");
    this.props.parent.handleChangeValue(this.getAttributeName(), event.target.value);
  }

  getInputId() {
    return this.getAttributeName() + "Input";
  }

  getValue(): TValue {
    let value = this.props.parent.state[this.getAttributeName()];
    return value || value === 0 ? value : this.getInitialValue();
  }

  shouldUpdateControls() {
    return this.props.parent.state.updateControls;
  }

  isPopupOpened() {
    return this.props.parent.state.isPopupOpened;
  }

  getNextValue(nextProps, allowNull) {
    let value = nextProps.parent ? nextProps.parent.state[this.getAttributeName()] : null;
    return (value || value === 0) ? value : (allowNull ? null : "");
  }

  hasValue() {
    let value = this.getValue();
    return (value !== null) && (value !== undefined) && (String(value) !== "undefined") && (value.toString().trim() !== "");
  }

  getRequiredFieldIndicatorClass() {
    let required = this.isRequiredForSaving() || this.isRequiredForProposing();
    if (!this.hasValue() && required) {
      return "base-attribute-value-missing";
    } else if (this.hasValue() && required) {
      return "base-attribute-value-specified";
    }

    return "";
  }

  isRequired() {
    return (this.isRequiredForSaving() || (this.isRequiredForProposing() && this.props.parent.isInProposeValidationMode()));
  }

  isRequiredForSaving() {
    return this.props.required || CommonModelVerifier.isRequiredForSaving(this.props.parent.getInstance(), this.props.parent.capitalizedBaseTypeName, this.getAttributeName().toLowerCase());
  }

  isRequiredForProposing() {
    return CommonModelVerifier.isRequiredForProposing(this.props.parent.getInstance(), this.props.parent.capitalizedBaseTypeName, this.getAttributeName().toLowerCase());
  }

  isDiffingVersions(): boolean {
    if (typeof this.props.parent.isDiffingVersions === "function") {
      return this.props.parent.isDiffingVersions();
    } else {
      return false;
    }
  }

  getOldValue(attributeName) {
    let olderVersion = this.props.olderVersion ?? this.props.parent.getOlderVersion();
    let oldValue = null;

    if (olderVersion) {
      oldValue = olderVersion[attributeName];
    }
    return oldValue;
  }

  hasOldValue(attributeName) {
    const oldValue = this.getOldValue(attributeName);
    if ((oldValue === null || oldValue === undefined) && this.isFirstVersion()) {
      return false;
    }

    return true;
  }

  isFirstVersion() {
    const {parent} = this.props;
    const {state} = parent;
    const {minorVersion, majorVersion, showMajorVersionsOnly} = state;
    const version = `${majorVersion}.${minorVersion}`;
    return version === "0.1" || showMajorVersionsOnly;
  }

  isDisabled() {
    return typeof this.props.disabled === "boolean" ? this.props.disabled : false;
  }

  shouldRenderSubscriptLabel() {
    return false;
  }

  getInstructionsText(object) {
    if (typeof object === "string") {
      return object;
    } else if (object && object.$$typeof && typeof object.$$typeof === "symbol" && object.props && object.props.children && object.props.children.length > 0) {
      return typeof object.props.children === "string"
        ? object.props.children
        : object.props.children.map(childObject => this.getInstructionsText(childObject)).join(" ");
    } else {
      return object;
    }
  }

  // This function is being implemented in most of attributes to decide what makes
  // a component either re-renders or not, each child attribute calls this super
  // function which checks for common props, as well checks if there is a popup opened,
  // also if controls need to be forced to be updated or not
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    UIUtils.Ensure.virtual("shouldComponentUpdate", {nextProps, nextState, nextContext});

    const shouldUpdateControls = this.shouldUpdateControls();
    const parentHasChanged = this.parentHasChanged(nextProps);

    return !this.isPopupOpened() && (
      this.propsAreDifferent(nextProps, "name")
      || this.propsAreDifferent(nextProps, "displayName")
      || this.propsAreDifferent(nextProps, "required", true)
      || this.propsAreDifferent(nextProps, "disabled", true)
      || this.propsAreDifferent(nextProps, "visible", true)
      || this.propsAreDifferent(nextProps, "warning")
      || this.propsAreDifferent(nextProps, "className")
      || this.propsAreDifferent(nextProps, "subscriptText")
      || this.propsAreDifferent(nextProps, "tooltipGuidanceURL")
      || this.propsAreDifferent(nextProps, "tooltipGuidancePage")
      || this.propsAreDifferent(nextProps, "tooltipGuidanceOffset")
      || this.propsAreDifferent(nextProps, "labelStyle")
      || this.propsAreDifferent(nextProps, "isLoading", true)
      || this.propsAreDifferent(nextProps, "parentVersionId")
      || this.propsAreDifferent(nextProps, "parentId")
      || this.propsAreDifferent(nextProps, "updatedAt")
      || this.propsAreDifferent(nextProps, "showMajorVersionsOnly")
      || this.stateIsDifferent(nextState, "isLoading", true)
      || this.stateIsDifferent(nextState, "shouldRenderSubscriptLabel", true)
      || this.valuesAreDifferent(this.getInstructionsText(nextProps.instructions), this.getInstructionsText(this.props.instructions), "instructions")
      || this.valuesAreDifferent(!!shouldUpdateControls, false, "shouldUpdateControls")
      || parentHasChanged
    );
  }

  /**
   * We should remove this and introduce the following properties on all components extending this: isLoading, parentVersionId, parentId, "updatedAt"
   * @param nextProps
   * @returns {boolean}
   */
  parentHasChanged(nextProps: any): boolean {
    const parentHasChanged = (!!nextProps.parent?.state?.isLoading !== !!this.parentIsLoading)
      || (nextProps.parent?.state?.id !== this.recordId)
      || (nextProps.parent?.state?.versionId !== this.recordVersionId)
      || (nextProps.parent?.state?.olderVersion?.id !== this.olderRecordVersionId)
      || (nextProps.parent?.state?.RequirementId !== this.requirementId)
      || (nextProps.parent?.state?.RequirementVersionId !== this.requirementVersionId);

    // this is required as the parent.state is the same in nextProps and this.props
    // we need to refactor the code to send id and versionId as property
    this.recordId = this.props.parent?.state?.id;
    this.recordVersionId = this.props.parent?.state?.versionId;
    this.olderRecordVersionId = this.props.parent?.state?.olderVersion?.id;
    this.requirementId = this.props.parent?.state?.RequirementId;
    this.requirementVersionId = this.props.parent?.state?.RequirementVersionId;
    this.parentIsLoading = this.props.parent?.state?.isLoading;

    return parentHasChanged;
  }

  /**
   * Method used to debug differences for shouldComponentUpdate
   * @param nextProps
   * @param name
   * @param isBoolean
   * @returns {boolean}
   */
  propsAreDifferent(nextProps: any, name: string, isBoolean: boolean = false): boolean {
    if (isBoolean) {
      return this.valuesAreDifferent(!!nextProps[name], !!this.props[name], name);
    } else {
      return this.valuesAreDifferent(nextProps[name], this.props[name], name);
    }
  }

  stateIsDifferent(nextState: any, name: string, isBoolean: boolean = false): boolean {
    if (isBoolean) {
      return this.valuesAreDifferent(!!nextState[name], !!this.state[name], name);
    } else {
      return this.valuesAreDifferent(nextState[name], this.state[name], name);
    }
  }

  /**
   * Method used to debug differences for shouldComponentUpdate
   * @param value1
   * @param value2
   * @param field
   * @returns {boolean}
   */
  valuesAreDifferent(value1: any, value2: any, field: string): boolean {
    const isDifferent = value1 !== value2;
    if (isDifferent) {
      Logger.debug(() => `${this.props.name} has ${field} different (Old value: ${value1}, New value: ${value2}) `);
    }

    return isDifferent;
  }

  shouldDisplayValidationErrors() {
    return true;
  }

  /**
   * @abstract
   * @return {string|jsx.Element}
   */
  abstract renderInput(): ReactNode;

  isLoading() {
    return this.props.isLoading || this.props.parent.state.isLoading;
  }

  render() {
    let input = this.renderInput();
    let inputId = this.getInputId();
    let visible = input && (typeof this.props.visible === "boolean" ? this.props.visible : true);
    const hasInstructions = !this.isView() && this.props.instructions && this.props.instructions !== "";
    const hasWarning = !this.isView() && this.props.warning && this.props.warning !== "";
    this.previousIsLoading = this.isLoading();
    const classForLoading = this.getClassForLoading();

    return (
      visible ? (
        <div className={
          "attribute-container "
          + this.props.className
        }
        >
          {this.renderLabel(inputId)}
          <div className={this.getOuterDivClass() + classForLoading}
               id={inputId + "Div"}
          >
            {hasInstructions ? (
              <div>
                <div className="attribute-with-instructions">
                  {input}
                </div>
                <FieldTooltip id={inputId}
                              text={this.props.instructions}
                />
              </div>
            ) : hasWarning ?
              <div>
                <div className="attribute-with-instructions">
                  {input}
                </div>
                <ValidationIcon
                  id={inputId}
                  tooltip={this.props.warning}
                  visible={true} isWarning={true}
                />
              </div> : input}
            {this.isView() && this.shouldDisplayValidationErrors() ? "" :
              <div className="help-block with-errors"
                   id={inputId + "ErrorDiv"}
              />}
          </div>
          {this.shouldRenderSubscriptLabel() ? (
            <div id={inputId + "SubscriptDiv"}>
              <LabelTooltip id={inputId + "Subscript"}
                            tooltipText={this.props.getSubscriptTooltipCallback ? this.props.getSubscriptTooltipCallback() : ""}
                            text={this.props.subscriptText}
                            className="control-subscript"
                            noColon
              />
            </div>
          ) : ""}
        </div>
      ) : null
    );
  }

  getOuterDivClass() {
    return (this.isView() || this.shouldRenderSubscriptLabel()) ? "view-attribute" : "form-group";
  }

  renderLabel(inputId) {
    return <LabelTooltip id={inputId + "Label"}
                         tooltipText={this.props.getTooltipCallback ? this.props.getTooltipCallback() : this.props.tooltipText}
                         tooltipGuidanceURL={this.props.tooltipGuidanceURL}
                         tooltipGuidancePage={this.props.tooltipGuidancePage}
                         tooltipGuidanceOffset={this.props.tooltipGuidanceOffset}
                         getTooltipCallback={this.props.getTooltipCallback}
                         style={this.props.labelStyle ? this.props.labelStyle : {}}
                         text={this.getDisplayName()}
                         indicator={(
                           <span className={this.getRequiredFieldIndicatorClass()}>
                             {!this.isView() && this.isRequiredForSaving() ?
                               " *" :
                               !this.isView() && this.isRequiredForProposing() ?
                                 " **" : ""}
                           </span>
                         )}
                         indicatorClassName={this.getRequiredFieldIndicatorClass()}
                         className={"col-form-label base-attribute" + (this.isView() ? "" : " col-form-label-add-or-edit")}
    />;
  }
}

export default BaseAttribute;
