"use strict";
import React from "react";
import BaseAttribute from "./base_attribute";

export default class NonEditableAttribute extends BaseAttribute {
  constructor(props) {
    super(props);

    // Bind functions to this
    this.renderInput = this.renderInput.bind(this);
  }

  // noinspection JSMethodCanBeStatic
  getInitialValue() {
    return this.props.defaultValue ? this.props.defaultValue : "";
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      let value = this.getNextValue(nextProps);
      return (this.currentValue !== value) ||
        (nextProps.defaultValue !== this.props.defaultValue);
    }
    return true;
  }

  shouldDisplayValidationErrors() {
    return false;
  }

  renderInput() {
    let input;
    let inputId = this.getInputId();
    this.currentValue = this.getValue() ? this.getValue() : "";

    input = (<span id={inputId}>{this.currentValue}</span>);

    return input;
  }
}
