"use strict";

import { Log, LOG_GROUP } from "../../../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Reports, "RiskMapLayoutHelper");

const graphNodesSpaceX = 40;
const graphNodesSpaceY = 40;
const graphRowsSpaceY = 140;
const graphNodeGroupsSpaceX = 140;
const graphNodeGroupsStepY = 140;
const firstUOBoxDefaultMargin = 50;

/**
 * Compute where the nodes belong in the default view. This just computes the nodes but does not send them to Vis.js to
 * necessarily draw them in that location.
 *
 * @param network The vis.js network.
 * @param nodesDataset The nodes dataset with which the network was initialized.
 * @param filters The filters object
 */
module.exports.computeDefaultView = function(network, nodesDataset, filters) {

  let tpps = [];
  let fqas = [];
  let fpas = [];
  let gas = [];
  let mas = [];
  let processRecords = [];
  let unitOperationGroups = new Map();
  let entitiesBoundingRect;
  let layoutBoxesBoundingRects = [];
  let lastAlignedRowBoundingRect;
  let nodes = nodesDataset.get();
  let firstGroupIsMAs = false;

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];
    let nodeRect = {
      node: node,
      rect: network.getBoundingBox(node.id)
    };

    switch (node.type) {
      case "TPP":
        tpps.push(nodeRect);
        break;
      case "GA":
        gas.push(nodeRect);
        break;
      case "FQA":
        fqas.push(nodeRect);
        break;
      case "FPA":
        fpas.push(nodeRect);
        break;
      case "MA":
        if (!node.unitOperationId) {
          if (node.parentType === "PRC") {
            processRecords.push(nodeRect);
          } else {
            mas.push(nodeRect);
          }
        }
        break;
      case "PP":
        if (!node.unitOperationId) {
          processRecords.push(nodeRect);
        }
        break;
    }

    if (node.unitOperationId) {
      if (!unitOperationGroups.has(node.unitOperationId)) {
        unitOperationGroups.set(node.unitOperationId, {
          order: node.unitOperationOrder,
          iqas: [],
          ipas: [],
          pps: [],
          mas: []
        });
      }

      let unitOperationGroup = unitOperationGroups.get(node.unitOperationId);
      switch (node.type) {
        case "IQA":
          unitOperationGroup.iqas.push(nodeRect);
          break;
        case "IPA":
          unitOperationGroup.ipas.push(nodeRect);
          break;
        case "PP":
          unitOperationGroup.pps.push(nodeRect);
          break;
        case "MA":
          unitOperationGroup.mas.push(nodeRect);
          break;
      }
    }
  }

  // Invoke layoutNodesInRectangle to arrange the TPPs, General Attributes and FQAs in rows
  // layoutNodesInRectangle returns a rectangle that includes all graph nodes passed in as an argument. This is then used for arranging the next sequence of nodes.
  entitiesBoundingRect = layoutNodesInRectangle(filters.riskTracedToFilter === "traceToTPPs" ? tpps : gas);
  entitiesBoundingRect = layoutNodesInRectangle(fqas.concat(fpas), entitiesBoundingRect);
  lastAlignedRowBoundingRect = entitiesBoundingRect;

  // Use layoutNodesInMultiRowRectangle to arrange a set of nodes into a multi row rectangle.
  // Multi row rectangles are rendered on the same level, from left to right.
  entitiesBoundingRect = layoutNodesInMultiRowRectangle(mas, entitiesBoundingRect);
  if (mas.length > 0) {
    layoutBoxesBoundingRects.push(entitiesBoundingRect);
    firstGroupIsMAs = true;
  }

  entitiesBoundingRect = layoutNodesInMultiRowRectangle(processRecords, entitiesBoundingRect);
  if (processRecords.length > 0) {
    layoutBoxesBoundingRects.push(entitiesBoundingRect);
  }

  let sortedUnitOperationGroups = new Map([...unitOperationGroups.entries()].sort((a, b) => {
    return a[1].order - b[1].order;
  }));

  let uoGroupCounter = 1;
  sortedUnitOperationGroups.forEach((value) => {
    // Add some margin if this is the first UO box and there is no MAs box.
    let rows = buildBoxRows(value, (uoGroupCounter === 1 && !firstGroupIsMAs) ? firstUOBoxDefaultMargin : 0);
    entitiesBoundingRect = layoutNodesInUOMultiRowRectangle(rows, entitiesBoundingRect);
    if (rows.length > 0) {
      layoutBoxesBoundingRects.push(entitiesBoundingRect);
    }
    uoGroupCounter++;
  });

  alignLayoutBoxesWithLastRow(lastAlignedRowBoundingRect, layoutBoxesBoundingRects, firstGroupIsMAs);
  return nodes;
};

function buildBoxRows(nodes, initialBoxMargin) {
  let rows = [];
  let orderedNodes;
  if (nodes.iqas || nodes.ipas) {
    orderedNodes = nodes.iqas.slice(0).concat(nodes.ipas.slice(0)).concat(nodes.pps.slice(0).concat(nodes.mas.slice(0)));
  } else {
    orderedNodes = nodes.slice(0);
  }

  let uoNodes = orderedNodes.filter(nodeObject => nodeObject.node.unitOperationId && !nodeObject.node.stepId);
  let stepNodes = orderedNodes.filter(nodeObject => nodeObject.node.unitOperationId && nodeObject.node.stepId);

  // sort the nodes per steps to show them in the correct order (the reverse of the order they appear in process explorer)
  stepNodes = stepNodes.sort((a, b) => b.node.stepOrder - a.node.stepOrder);

  // Group nodes per steps, map will maintain the node's insertion order...
  stepNodes = stepNodes.reduce((stepNodes, nodeObject) => {
    let stepId = nodeObject.node.stepId;
    if (stepId) {
      stepNodes.has(stepId) ? stepNodes.get(stepId).push(nodeObject) : stepNodes.set(stepId, [nodeObject]);
    }
    return stepNodes;
  }, new Map());

  // Calculate the highest number of elements per UO and Steps. start with UO nodes and reduce step by step to get the highest number of elements
  let biggestSize = Object.values(Object.fromEntries(stepNodes)) // Convert stepNodes Map to object to be able to reduce its entries. Because Map does not support reduce operation...
    .reduce((size, nodes) => Math.max(size, nodes.length), uoNodes.length);

  let nodesPerRow = Math.ceil(Math.sqrt(biggestSize));

  while (uoNodes.length > 0) {
    let rowNodes = uoNodes.splice(0, nodesPerRow);
    rows.push({
      elements: rowNodes
    });
  }

  // Add some margin for the last row, to differentiate between UOs and Steps boxes.
  let addMarginForLastRow = (rows, margin) => {
    let lastRow = rows[rows.length - 1];
    if (lastRow) {
      lastRow.marginBotton = margin;
    }
  };

  addMarginForLastRow(rows, 100);

  for (let elements of stepNodes.values()) {
    while (elements.length > 0) {
      let rowNodes = elements.splice(0, nodesPerRow);
      rows.push({
        elements: rowNodes
      });
    }
    addMarginForLastRow(rows, 100);
  }

  // Add initial box margin by setting it to the first row in the box.
  if (rows.length > 0 && initialBoxMargin > 0) {
    let row = rows[0];
    row.marginTop = initialBoxMargin;
  }

  return rows;
}

/**
 * Resets the Risk Map report to the default view.
 * TPPs are laid down in a row, then just below them the FQAs and below them in rectangular boxes, MAs, PPs and IQAs, grouped by unit operation
 * @param network The vis.js network
 * @param nodesDataset The nodes dataset with which the network was initialized
 * @param filters
 */
module.exports.resetGraphToDefaultView = function(network, nodesDataset, filters) {
  let nodes = exports.computeDefaultView(network, nodesDataset, filters);

  nodesDataset.clear();
  nodesDataset.add(nodes);
  nodesDataset.flush();
};

/**
 * Calculates the coordinates of the passed in nodes so that they are all aligned in a straight line and centered below the passed in previousBoundingRect.
 *
 * @param nodes The nodes to arrange.
 * @param previousBoundingRect The previous bounding rect based on which the new bounding rect, containing the nodes, will be created.
 * @returns A rectangle that bounds all of the arranged nodes.
 */
function layoutNodesInRectangle(nodes, previousBoundingRect) {
  // Initialise the row bounding rect based on the passed in previous rect
  let nodesBoundingRect = calculateBoundingRect(nodes, true);
  if (previousBoundingRect) {
    nodesBoundingRect.topLeftX = previousBoundingRect.topLeftX + ((previousBoundingRect.width - nodesBoundingRect.width) / 2);
    nodesBoundingRect.topLeftY = previousBoundingRect.bottomRightY + graphRowsSpaceY;
    nodesBoundingRect.bottomRightX = nodesBoundingRect.topLeftX + nodesBoundingRect.width;
    nodesBoundingRect.bottomRightY = nodesBoundingRect.topLeftY + nodesBoundingRect.height;
  }

  // Align all nodes on the Y axes so that their centers are in a straight line
  let nodesY = nodesBoundingRect.topLeftY + ((nodesBoundingRect.bottomRightY - nodesBoundingRect.topLeftY) / 2);

  // Move all nodes in a row so that they are included in the calculated bounding rect
  let currentX = nodesBoundingRect.topLeftX;
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i].node;
    const nodeRect = nodes[i].rect;
    let nodeWidth = nodeRect.right - nodeRect.left;
    Logger.verbose(() => `Found node ${node.id} with left: ${nodeRect.left} vs right: ${nodeRect.right} and width: ${nodeWidth}`);
    node.x = currentX + (nodeWidth / 2);
    currentX += nodeWidth + graphNodesSpaceX;
    node.y = nodesY;
  }

  return nodesBoundingRect;
}

/**
 * Calculates the coordinates of the nodes passed in so that they are all organized in multiple rows in a rectangle
 * @param nodes The nodes to arrange
 * @param previousBoundingRect The previous bounding rect based on which the new bounding rect, containing the nodes, will be created
 * @returns A rectangle that bounds all the arranged nodes.
 */
function layoutNodesInMultiRowRectangle(nodes, previousBoundingRect) {
  //A collection of all nodes
  let orderedNodes;
  let previousBoxRowBoundingRect;

  if (nodes.iqas || nodes.ipas) {
    orderedNodes = nodes.iqas.slice(0).concat(nodes.ipas.slice(0)).concat(nodes.pps.slice(0).concat(nodes.mas.slice(0)));
  } else {
    orderedNodes = nodes.slice(0);
  }

  if (orderedNodes.length === 0) {
    return previousBoundingRect;
  }

  //Initialize the bounding rectangle based on the previous passed in rectangle
  let nodesBoundingRect = {
    topLeftX: previousBoundingRect.nextRectInNewRow ? 0 : previousBoundingRect.bottomRightX + graphNodeGroupsSpaceX,
    topLeftY: previousBoundingRect.nextRectInNewRow ? previousBoundingRect.bottomRightY + graphRowsSpaceY : previousBoundingRect.topLeftY,
    rowBoundingRects: [],
    nextRectInNewRow: false
  };

  //Calculate how many nodes per row in the rectangle we should include
  let nodesPerRectangleRow = Math.round(Math.sqrt(orderedNodes.length));

  //Get the nodes in groups of nodesPerRectangleRow rows and arrange them in a row
  //The logic followed in the loop below is very similar to the logic applied when laying down rows of nodes one below the other by using the layoutNodesInRectangle method
  //The only difference is that each row in this case will need to fit in the bounding rect
  while (orderedNodes.length > 0) {
    let rowNodes = orderedNodes.splice(0, nodesPerRectangleRow);
    let lastRectIndex = nodesBoundingRect.rowBoundingRects.push({
      rect: calculateBoundingRect(rowNodes, true),
      nodes: rowNodes
    });

    //Initialize the row bounding rect based on the previous rect, unless this is the first row
    let boxRowBoundingRect = nodesBoundingRect.rowBoundingRects[lastRectIndex - 1].rect;
    if (previousBoxRowBoundingRect) {
      boxRowBoundingRect.topLeftX = previousBoxRowBoundingRect.topLeftX + ((previousBoxRowBoundingRect.width - boxRowBoundingRect.width) / 2);
      boxRowBoundingRect.topLeftY = previousBoxRowBoundingRect.bottomRightY + graphNodesSpaceY;
      boxRowBoundingRect.bottomRightX = boxRowBoundingRect.topLeftX + boxRowBoundingRect.width;
      boxRowBoundingRect.bottomRightY = boxRowBoundingRect.topLeftY + boxRowBoundingRect.height;
    }
    previousBoxRowBoundingRect = boxRowBoundingRect;

    //Align all nodes on the Y axes so that their centers are in a straight line
    let nodesY = boxRowBoundingRect.topLeftY + ((boxRowBoundingRect.bottomRightY - boxRowBoundingRect.topLeftY) / 2);

    //Move all nodes in the row based on the calculated row rectangle
    let currentX = boxRowBoundingRect.topLeftX;
    for (let i = 0; i < rowNodes.length; i++) {
      const node = rowNodes[i].node;
      const nodeRect = rowNodes[i].rect;
      let nodeWidth = nodeRect.right - nodeRect.left;
      node.x = currentX + (nodeWidth / 2);
      currentX += nodeWidth + graphNodesSpaceX;
      node.y = nodesY;
    }
  }

  //At this point all nodes are laid out correctly relatively to each other in the box.
  //The below method will move them in the right x,y coordinates inside their containing box rectangle.
  shiftNodes(nodesBoundingRect.topLeftX, nodesBoundingRect.topLeftY, nodesBoundingRect);

  //While the box row bounding rects are shifted to the left so that they are all centered, there is a chance that one of the rows
  //exceeds the left boundary of its box container. In that case, we need to shift everything to the right by on offset that is equal to the
  //max offset of all row bounding rects in the box container.
  let leftMostBoxRowBoundingRectX = nodesBoundingRect.rowBoundingRects.reduce((min, cur) => {
    return Math.min(min, cur.rect.topLeftX);
  }, Number.MAX_SAFE_INTEGER);
  if (leftMostBoxRowBoundingRectX < nodesBoundingRect.topLeftX) {
    let xShift = nodesBoundingRect.topLeftX - leftMostBoxRowBoundingRectX;
    shiftNodes(xShift, 0, nodesBoundingRect);
  }

  //Calculate the bottom right x and y of the box bounding rect
  nodesBoundingRect.bottomRightX = nodesBoundingRect.rowBoundingRects.reduce((max, cur) => {
    return Math.max(max, cur.rect.bottomRightX);
  }, Number.MIN_SAFE_INTEGER);
  nodesBoundingRect.bottomRightY = nodesBoundingRect.rowBoundingRects.reduce((max, cur) => {
    return Math.max(max, cur.rect.bottomRightY);
  }, Number.MIN_SAFE_INTEGER);
  //Store the width and height as well for ease of access
  nodesBoundingRect.width = nodesBoundingRect.bottomRightX - nodesBoundingRect.topLeftX;
  nodesBoundingRect.height = nodesBoundingRect.bottomRightY - nodesBoundingRect.topLeftY;

  return nodesBoundingRect;
}


function layoutNodesInUOMultiRowRectangle(rows, previousBoundingRect) {
  let previousBoxRowBoundingRect;

  if (rows.length === 0) {
    return previousBoundingRect;
  }

  //Initialize the bounding rectangle based on the previous passed in rectangle
  let nodesBoundingRect = {
    topLeftX: previousBoundingRect.nextRectInNewRow ? 0 : previousBoundingRect.bottomRightX + graphNodeGroupsSpaceX,
    topLeftY: previousBoundingRect.nextRectInNewRow ? previousBoundingRect.bottomRightY + graphRowsSpaceY : previousBoundingRect.topLeftY,
    rowBoundingRects: [],
    nextRectInNewRow: false
  };

  //Calculate how many nodes per row in the rectangle we should include

  //Get the nodes in groups of nodesPerRectangleRow rows and arrange them in a row
  //The logic followed in the loop below is very similar to the logic applied when laying down rows of nodes one below the other by using the layoutNodesInRectangle method
  //The only difference is that each row in this case will need to fit in the bounding rect
  for (let row of rows) {
    let rowNodes = row.elements;
    let marginBotton = row.marginBotton;
    let marginTop = row.marginTop;

    let lastRectIndex = nodesBoundingRect.rowBoundingRects.push({
      rect: calculateBoundingRect(rowNodes, true),
      nodes: rowNodes
    });

    //Initialize the row bounding rect based on the previous rect, unless this is the first row
    let boxRowBoundingRect = nodesBoundingRect.rowBoundingRects[lastRectIndex - 1].rect;
    if (previousBoxRowBoundingRect) {
      boxRowBoundingRect.topLeftX = previousBoxRowBoundingRect.topLeftX + ((previousBoxRowBoundingRect.width - boxRowBoundingRect.width) / 2);
      boxRowBoundingRect.topLeftY = previousBoxRowBoundingRect.bottomRightY + graphNodesSpaceY;
      boxRowBoundingRect.bottomRightX = boxRowBoundingRect.topLeftX + boxRowBoundingRect.width;
      boxRowBoundingRect.bottomRightY = boxRowBoundingRect.topLeftY + boxRowBoundingRect.height;
    }
    previousBoxRowBoundingRect = boxRowBoundingRect;

    // Set the top margin if required.
    boxRowBoundingRect.topLeftY += marginTop ? marginTop : 0;

    //Align all nodes on the Y axes so that their centers are in a straight line
    let nodesY = boxRowBoundingRect.topLeftY + ((boxRowBoundingRect.bottomRightY - boxRowBoundingRect.topLeftY) / 2);

    // Set the margin if the row has margins
    boxRowBoundingRect.bottomRightY += marginBotton ? marginBotton : 0;

    //Move all nodes in the row based on the calculated row rectangle
    let currentX = boxRowBoundingRect.topLeftX;
    for (let i = 0; i < rowNodes.length; i++) {
      const node = rowNodes[i].node;
      const nodeRect = rowNodes[i].rect;
      let nodeWidth = nodeRect.right - nodeRect.left;
      node.x = currentX + (nodeWidth / 2);
      currentX += nodeWidth + graphNodesSpaceX;
      node.y = nodesY;
    }
  }

  //At this point all nodes are laid out correctly relatively to each other in the box.
  //The below method will move them in the right x,y coordinates inside their containing box rectangle.
  shiftNodes(nodesBoundingRect.topLeftX, nodesBoundingRect.topLeftY, nodesBoundingRect);

  //While the box row bounding rects are shifted to the left so that they are all centered, there is a chance that one of the rows
  //exceeds the left boundary of its box container. In that case, we need to shift everything to the right by on offset that is equal to the
  //max offset of all row bounding rects in the box container.
  let leftMostBoxRowBoundingRectX = nodesBoundingRect.rowBoundingRects.reduce((min, cur) => {
    return Math.min(min, cur.rect.topLeftX);
  }, Number.MAX_SAFE_INTEGER);
  if (leftMostBoxRowBoundingRectX < nodesBoundingRect.topLeftX) {
    let xShift = nodesBoundingRect.topLeftX - leftMostBoxRowBoundingRectX;
    shiftNodes(xShift, 0, nodesBoundingRect);
  }

  //Calculate the bottom right x and y of the box bounding rect
  nodesBoundingRect.bottomRightX = nodesBoundingRect.rowBoundingRects.reduce((max, cur) => {
    return Math.max(max, cur.rect.bottomRightX);
  }, Number.MIN_SAFE_INTEGER);
  nodesBoundingRect.bottomRightY = nodesBoundingRect.rowBoundingRects.reduce((max, cur) => {
    return Math.max(max, cur.rect.bottomRightY);
  }, Number.MIN_SAFE_INTEGER);
  //Store the width and height as well for ease of access
  nodesBoundingRect.width = nodesBoundingRect.bottomRightX - nodesBoundingRect.topLeftX;
  nodesBoundingRect.height = nodesBoundingRect.bottomRightY - nodesBoundingRect.topLeftY;

  return nodesBoundingRect;
}

/**
 * Calculates the bounding rect that includes all passed in nodes and returns it.
 * @param nodes The nodes for which the bounding rect will be calculated
 * @param nextRectInNewRow This is used by the alignment algorithm to determine if the next set of nodes need to be rendered on the same level as the previous ones or in a new row
 * @returns A rectangle that bounds all the passed in nodes
 */
function calculateBoundingRect(nodes, nextRectInNewRow) {
  let boundingRect = {
    topLeftX: 0,
    topLeftY: 0,
    bottomRightX: 0,
    bottomRightY: 0,
    width: 0,
    height: 0,
    nextRectInNewRow: nextRectInNewRow
  };

  for (let i = 0; i < nodes.length; i++) {
    const nodeRect = nodes[i].rect;
    let nodeWidth = nodeRect.right - nodeRect.left;
    let nodeHeight = Math.abs(nodeRect.bottom - nodeRect.top);
    boundingRect.bottomRightX += ((i !== nodes.length - 1) ? nodeWidth + graphNodesSpaceX : nodeWidth);
    boundingRect.bottomRightY = (boundingRect.bottomRightY > nodeHeight ? boundingRect.bottomRightY : nodeHeight);
  }

  boundingRect.width = boundingRect.bottomRightX - boundingRect.topLeftX;
  boundingRect.height = boundingRect.bottomRightY - boundingRect.topLeftY;

  return boundingRect;
}

/**
 * Used to center the last row of nodes arranged into rectangles with multiple rows on the same level with the last row of FQAs
 * @param lastAlignedRowBoundingRect A rectangle that contains the last row of nodes aligned in a horizontal line, by default the FQAs
 * @param layoutBoxesBoundingRects The bounding rects that contain the nodes aligned in multi row rectangles, one for each unit operation
 * @param firstGroupIsMAs Set to true if the first group is material attributes that are raised up
 */
function alignLayoutBoxesWithLastRow(lastAlignedRowBoundingRect, layoutBoxesBoundingRects, firstGroupIsMAs) {
  if (layoutBoxesBoundingRects.length === 0) {
    return;
  }

  let totalLayoutBoxesWidth = layoutBoxesBoundingRects.reduce((acc, cur) => {
    return acc + cur.width;
  }, 0);
  totalLayoutBoxesWidth += graphNodeGroupsSpaceX * (layoutBoxesBoundingRects.length - 1);

  let x = lastAlignedRowBoundingRect.topLeftX - ((totalLayoutBoxesWidth - lastAlignedRowBoundingRect.width) / 2);
  for (let i = 0; i < layoutBoxesBoundingRects.length; i++) {
    const boxBoundingRect = layoutBoxesBoundingRects[i];
    if (i === 0 && firstGroupIsMAs) {
      shiftNodes(x, 0, boxBoundingRect);
    } else {
      shiftNodes(x, graphNodeGroupsStepY * (layoutBoxesBoundingRects.length - i - 1), boxBoundingRect);
    }
  }
}

/**
 * Used to move all the nodes in a multi row rectangular shape by the specified offset
 * @param x The offset to mode all nodes in the x axis
 * @param y The offset to mode all nodes in the x axis
 * @param boundingRect The bounding rect that contains the passed in nodes. This will be shifted as well by x, y
 */
function shiftNodes(x, y, boundingRect) {
  for (let i = 0; i < boundingRect.rowBoundingRects.length; i++) {
    const boxRowBoundingRect = boundingRect.rowBoundingRects[i];
    boxRowBoundingRect.rect.topLeftX += x;
    boxRowBoundingRect.rect.topLeftY += y;
    boxRowBoundingRect.rect.bottomRightX += x;
    boxRowBoundingRect.rect.bottomRightY += y;

    for (let j = 0; j < boxRowBoundingRect.nodes.length; j++) {
      const node = boxRowBoundingRect.nodes[j].node;
      node.x += x;
      node.y += y;
    }
  }
}
