var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { dispatchEvent, Keys, classNames, Draggable, getTabIndex, createPropsContext, withPropsContext } from '@progress/kendo-react-common';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { messages, sliderIncreaseValue, sliderDecreaseValue, sliderDragTitle } from './../messages';
import { SLIDER_LABEL_ATTRIBUTE } from './SliderLabel';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
import { Button } from '@progress/kendo-react-buttons';
/** @hidden */
var SliderWithoutContext = /** @class */ (function (_super) {
    __extends(SliderWithoutContext, _super);
    function SliderWithoutContext(props) {
        var _this = _super.call(this, props) || this;
        /**
         * @hidden
         */
        _this.state = {
            value: _this.props.defaultValue === undefined ? _this.props.min : _this.props.defaultValue,
            focused: false,
            dir: _this.props.dir
        };
        _this._sliderTrack = null;
        _this._element = null;
        /**
         * @hidden
         */
        _this.buttons = function (props) {
            if (!props.enabled) {
                return props.children;
            }
            return (React.createElement(React.Fragment, null,
                React.createElement(Button, { className: "k-button-decrease", rounded: 'full', icon: _this.props.vertical ? 'caret-alt-down' : 'caret-alt-left', title: props.decrementTitle, onClick: props.decrement }),
                props.children,
                React.createElement(Button, { className: "k-button-increase", rounded: 'full', icon: _this.props.vertical ? 'caret-alt-up' : 'caret-alt-right', title: props.incrementTitle, onClick: props.increment })));
        };
        /**
         * @hidden
         */
        _this.focus = function () {
            if (_this._element) {
                _this._element.focus();
            }
        };
        _this.isLabel = function (target) {
            var currentTarget = target;
            while (currentTarget) {
                if (Boolean(currentTarget.getAttribute(SLIDER_LABEL_ATTRIBUTE))) {
                    return true;
                }
                currentTarget = currentTarget.parentElement;
            }
            return false;
        };
        _this.onFocus = function () {
            _this.setState({ focused: true });
        };
        _this.onBlur = function () {
            _this.setState({ focused: false });
        };
        _this.onKeyDown = function (e) {
            var newValue = undefined;
            if (e.keyCode === Keys.left || e.keyCode === Keys.down) {
                newValue = _this.state.value - (_this.props.step || 0);
            }
            else if (e.keyCode === Keys.right || e.keyCode === Keys.up) {
                newValue = _this.state.value + (_this.props.step || 0);
            }
            else if (e.keyCode === Keys.home) {
                newValue = _this.props.min;
            }
            else if (e.keyCode === Keys.end) {
                newValue = _this.props.max;
            }
            if (newValue !== undefined) {
                e.preventDefault();
                _this.change(e, newValue);
            }
        };
        _this.decrement = function (e) {
            e.preventDefault();
            _this.change(e, _this.state.value - (_this.props.step || 0));
        };
        _this.increment = function (e) {
            e.preventDefault();
            _this.change(e, _this.state.value + (_this.props.step || 0));
        };
        _this.dragStart = function (e) {
            if (!_this.isLabel(e.event.originalEvent.target)) {
                if (e.event.isTouch) {
                    e.event.originalEvent.preventDefault();
                }
                _this.drag(e);
            }
        };
        _this.dragOver = function (e) {
            e.event.originalEvent.preventDefault();
            _this.drag(e);
        };
        _this.drag = function (e) {
            var computed = e.element.getBoundingClientRect();
            var distance = _this.props.vertical
                ? (computed.bottom - e.event.clientY)
                : (_this.state.dir === 'rtl'
                    ? computed.right - e.event.clientX
                    : e.event.clientX - computed.left);
            var size = _this.props.vertical ? computed.height : computed.width;
            var percentage = (distance / size);
            _this.change(e, _this.props.min + percentage * (_this.props.max - _this.props.min));
        };
        validatePackage(packageMetadata);
        return _this;
    }
    /**
     * @hidden
     */
    SliderWithoutContext.getDerivedStateFromProps = function (props, state) {
        var value = props.value !== undefined ? props.value : state.value;
        var min = props.min, max = props.max;
        return value === undefined ? null :
            { value: Math.min(Math.max(value, min), max) };
    };
    /**
     * @hidden
     */
    SliderWithoutContext.prototype.componentDidMount = function () {
        if (!this.state.dir && window && this._element) {
            // Note: getComputedStyle forces reflow
            var direction = window.getComputedStyle(this._element).direction;
            if (direction) {
                this.setState({ dir: direction });
            }
        }
    };
    /**
     * @hidden
     */
    SliderWithoutContext.prototype.render = function () {
        var _a;
        var _this = this;
        var lS = provideLocalizationService(this);
        var percentValue = ((this.state.value - this.props.min) / (this.props.max - this.props.min)) * 100;
        var trackStyles = this.props.vertical
            ? { marginTop: '0.5rem', marginBottom: '0.5rem' }
            : { marginLeft: '0.5rem', marginRight: '0.5rem' };
        var sliderItemsStyle = this.props.vertical
            ? { paddingTop: 0, height: '100%' }
            : {};
        return (React.createElement("div", { ref: function (el) { return _this._element = el; }, dir: this.state.dir, id: this.props.id, style: __assign({ gap: 0 }, this.props.style), onFocus: this.onFocus, onBlur: this.onBlur, onKeyDown: this.onKeyDown, className: classNames('k-slider k-widget', {
                'k-focus': this.state.focused,
                'k-disabled': this.props.disabled,
                'k-slider-horizontal': !this.props.vertical,
                'k-slider-vertical': this.props.vertical
            }, this.props.className) },
            React.createElement(this.buttons, { enabled: this.props.buttons, decrement: this.decrement, increment: this.increment, incrementTitle: lS.toLanguageString(sliderIncreaseValue, messages[sliderIncreaseValue]), decrementTitle: lS.toLanguageString(sliderDecreaseValue, messages[sliderDecreaseValue]) },
                React.createElement(Draggable, { onDrag: this.dragOver, onPress: this.dragStart },
                    React.createElement("div", { className: "k-slider-track-wrap", style: __assign({ flexGrow: 1, position: 'relative', touchAction: 'none' }, trackStyles) },
                        this.props.children && (React.createElement("ul", { className: "k-reset k-slider-items", style: __assign({ margin: 0 }, sliderItemsStyle) }, React.Children.map(this.props.children, function (child) {
                            return child && React.cloneElement(child, {
                                position: 100 * (child.props.position - _this.props.min) /
                                    (_this.props.max - _this.props.min),
                                vertical: _this.props.vertical
                            }, child.props.children);
                        }))),
                        React.createElement("div", { className: "k-slider-track", style: this.props.vertical
                                ? { bottom: 0, height: '100%' }
                                : (_a = {}, _a[this.state.dir === 'rtl' ? 'right' : 'left'] = 0, _a.width = '100%', _a), ref: function (el) { return _this._sliderTrack = el; } },
                            React.createElement("div", { className: "k-slider-selection", style: this.props.vertical
                                    ? { height: percentValue + '%' }
                                    : { width: percentValue + '%' } }),
                            React.createElement("span", { role: "slider", "aria-valuemin": this.props.min, "aria-valuemax": this.props.max, "aria-valuenow": this.state.value, "aria-valuetext": String(this.state.value), "aria-orientation": this.props.vertical ? 'vertical' : undefined, "aria-disabled": this.props.disabled ? 'true' : undefined, "aria-labelledby": this.props.ariaLabelledBy, "aria-describedby": this.props.ariaDescribedBy, "aria-label": this.props.ariaLabel, tabIndex: getTabIndex(this.props.tabIndex, this.props.disabled, undefined), className: "k-draghandle", title: lS.toLanguageString(sliderDragTitle, messages[sliderDragTitle]), style: this.props.vertical
                                    ? { bottom: 'calc(' + percentValue + '%)', zIndex: 1 }
                                    : this.state.dir === 'rtl' ? { right: 'calc(' + percentValue + '% - 13px)', zIndex: 1 }
                                        : { left: 'calc(' + percentValue + '%)', zIndex: 1 } })))))));
    };
    Object.defineProperty(SliderWithoutContext.prototype, "sliderTrack", {
        /**
         * @hidden
         */
        get: function () {
            return this._sliderTrack;
        },
        enumerable: false,
        configurable: true
    });
    SliderWithoutContext.prototype.change = function (e, value) {
        value = Math.min(Math.max(value, this.props.min), this.props.max);
        this.setState({ value: value });
        dispatchEvent(this.props.onChange, e, this, { value: value });
    };
    SliderWithoutContext.displayName = 'Slider';
    /**
     * @hidden
     */
    SliderWithoutContext.propTypes = {
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        value: PropTypes.number,
        vertical: PropTypes.bool,
        id: PropTypes.string,
        ariaLabelledBy: PropTypes.string,
        ariaDescribedBy: PropTypes.string,
        ariaLabel: PropTypes.string
        // TODO: validation when buttons is set to true, but no step is provided
    };
    return SliderWithoutContext;
}(React.Component));
export { SliderWithoutContext };
/**
 * Represents the PropsContext of the `Slider` component.
 * Used for global configuration of all `Slider` instances.
 *
 * For more information, refer to the [Inputs Props Context]({% slug props-context_inputs %}) article.
 */
export var SliderPropsContext = createPropsContext();
/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the component the same as the type */
/**
 * Represents the [KendoReact Slider component]({% slug overview_slider %}).
 *
 * Accepts properties of type [SliderProps]({% slug api_inputs_sliderprops %}).
 * Obtaining the `ref` returns an object of type [SliderHandle]({% slug api_inputs_sliderhandle %}).
 */
export var Slider = withPropsContext(SliderPropsContext, SliderWithoutContext);
Slider.displayName = 'KendoReactSlider';
registerForLocalization(SliderWithoutContext);
