import ReactDOM from "react-dom";
import { React, StrictMode } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import UserLoginI18n from "./users/user_login";
import HomePageI18n from "./homePage/home_page";
import ReportsPage from "./reports/reports_page";
import DataReportPage from "./reports/data_report_page";
import RMPListPageI18n from "./rmps/rmp_list_page";
import RMP from "./rmps/rmp";

import RiskMapReportPage from "./reports/risk_map_reports/risk_map_report_page";
import RiskTablesReportPage from "./reports/risk_tables/risk_tables_report_page";
import ProcessMapReportPage from "./reports/process_map_reports/process_map_report_page";
import ProcessCapabilityDashboardPage from "./reports/pcm_dashboard/pcm_dashboard_page";
import ChartsReportPage from "./reports/chart_reports/charts_report_page";

import CompanyPage from "./admin/internal/company_page";
import AdminPanelListPage from "./admin/internal/admin_internal_list_page";
import PageNotFound from "./404";
import BatchListPageI18n from "./batches/batch_list_page";
import BatchI18n from "./batches/batch";
import TrialExpiredPage from "./billing/trial_expired_page";
import ControlMethodsPageI18n from "./controlMethod/control_methods_page";
import ControlMethodsI18n from "./controlMethod/control_method";
import DocumentEditorPage from "./documentEditor/document_editor_page";
import DocumentListInProjectPageI18n from "./documents/document_list_in_project_page";
import DocumentListPageI18n from "./documents/document_list_page";
import Document from "./documents/document";
import ImportDashboardPage from "./import/import_dashboard_page";
import ImportFilePage from "./import/import_file_page";
import ImportProjectRecordPage from "./import/import_project_record_page";
import ImportResultReviewPage from "./import/import_result_review_page";
import LibraryMaterialI18n from "./library/editor/library_material";
import LinkProjectMaterialAttributePageI18n from "./library/wizardActions/link/link_project_material_attribute_page";
import UnlinkProjectMaterialAttributePageI18n
  from "./library/wizardActions/unlink/unlink_project_material_attribute_page";
import CreateProjectMaterialPageI18n
  from "./library/wizardActions/createProjectMaterial/fromProject/create_project_material_page";
import SwapProjectMaterialPageI18n from "./library/wizardActions/swap/swap_project_material_page";
import SyncProjectMaterialPageI18n from "./library/wizardActions/sync/sync_project_material_page";
import CompareProjectMaterialAttributePage
  from "./library/wizardActions/compare/compare_project_material_attribute_page";
import UnlinkProjectMaterialPageI18n from "./library/wizardActions/unlink/unlink_project_material_page";
import UnsyncProjectMaterialPageI18n from "./library/wizardActions/unsync/unsync_project_material_page";
import LibraryPageI18n from "./library/library";
import ProcessComponentI18n from "./processExplorer/assets/process_component";
import ProcessExplorerPageI18n from "./processExplorer/process_explorer_page";
import MaterialI18n from "./processExplorer/assets/material";
import UnitOperationI18n from "./processExplorer/operations/unit_operation";
import IPAI18n from "./processExplorer/variables/ipa";
import IQAI18n from "./processExplorer/variables/iqa";
import LinkTechTransferPageI18n from "./processExplorer/linkTechTransfer/link_tech_transfer_page";
import MaterialAttributeI18n from "./processExplorer/variables/material_attribute";
import ProcessParameterI18n from "./processExplorer/variables/process_parameter";
import StepI18n from "./processExplorer/operations/step";
import ProcessI18n from "./processExplorer/parentPages/process";
import ProductI18n from "./product/product";
import FQAI18n from "./product/editors/fqa";
import FPAI18n from "./product/editors/fpa";
import GeneralAttributeI18n from "./product/editors/general_attribute";
import TPPSectionI18n from "./product/editors/tppSection";
import Project from "./projects/project";
import ProjectListPageI18n from "./projects/project_list_page";
import ProjectDashboardPage from "./dashboard/project_dashboard_page";
import BulkApprovalPage from "./dashboard/approval/bulk_approval_page";
import BulkProposalPage from "./dashboard/proposal/bulk_proposal_page";
import ProjectChangeRMPPage from "./projects/project_change_rmp_page";
import RestAPIPage from "./restAPI/rest_api_page";
import CheckI18n from "./sysChecks/check";
import TechTransferPage from "./techTransfer/tech_transfer_page";
import TrainingListPage from "./training/list_pages/training_list_page";
import TrainingI18n from "./training/editors/training";
import MyTrainingI18n from "./training/my_training";
import TrainingOnboardingI18n from "./training/onboarding/training_onboarding";
import CurriculumI18n from "./training/editors/curriculum";
import CurriculumListPage from "./training/list_pages/curriculum_list_page";
import SupplierListPage from "./suppliers/supplier_list_page";
import Supplier from "./suppliers/supplier";
import APIKey from "./users/userRecordManagement/api_key";
import UserAccountI18n from "./users/accountManagement/user_create_account";
import UserForgotPasswordI18n from "./users/passwordManagement/user_forgot_password_page";
import UserListPageI18n from "./users/list/user_list_page";
import UserResetPasswordI18n from "./users/passwordManagement/user_reset_password_page";
import UserSignupVerificationI18n from "./users/accountManagement/user_signup_verification";
import User from "./users/userRecordManagement/user";
import * as SentryWrapper from "./utils/sentry_wrapper";
import * as Sentry from "@sentry/react";
import { withRouter } from "./utils/withRouter";

//Routes are listed in alphabetical order and grouped by folders
export default function App() {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  const RoutedComponent = withRouter(SentryRoutes);

  return (
    <BrowserRouter>
      <RoutedComponent>
        <Route path="/admin/internal/editCompany.html" element={<div id="company_page"><CompanyPage /></div>} />
        <Route path="/admin/internal/listOfUsers.html" element={<div id="adminInternalListPage"><AdminPanelListPage />
        </div>}
        />

        <Route path="/batches/list.html" element={<div id="batchListPage"><BatchListPageI18n /></div>} />
        <Route path="/batches/viewEdit.html" element={<div id="batchPage"><BatchI18n /></div>} />

        <Route path="/billing/trialExpired.html" element={<div id="trialExpiredPage"><TrialExpiredPage /></div>} />

        <Route path="/controlMethods/list.html" element={<div id="controlMethodsPage"><ControlMethodsPageI18n />
        </div>}
        />
        <Route path="/controlMethods/viewEdit.html" element={<div id="cmPage"><ControlMethodsI18n /></div>} />

        <Route path="/documentEditor/documentEditor.html" element={<div id="document-editor"><DocumentEditorPage />
        </div>}
        />

        <Route path="/documents/documentsListInProject.html" element={<div id="documentsListInProjectPage">
          <DocumentListInProjectPageI18n /></div>}
        />
        <Route path="/documents/list.html" element={<div id="documentListPage"><DocumentListPageI18n /></div>} />
        <Route path="/documents/viewEdit.html" element={<div id="documentPage"><Document /></div>} />

        <Route path="/homePage/dashboard.html" element={<div id="homePage"><HomePageI18n /></div>} />

        <Route path="/import/importDashboard.html" element={<div id="importDashboardPage"><ImportDashboardPage />
        </div>}
        />
        <Route path="/import/importFile.html" element={<div id="importFilePage"><ImportFilePage /></div>} />
        <Route path="/import/importProject.html" element={<div id="importProjectRecordPage"><ImportProjectRecordPage />
        </div>}
        />
        <Route path="/import/importResultReview.html" element={<div id="ImportResultReviewPage">
          <ImportResultReviewPage /></div>}
        />

        <Route path="/library/materials/viewEdit.html" element={<div id="libraryMaterialPage"><LibraryMaterialI18n />
        </div>}
        />
        <Route path="/library/projectMaterialAttributes/compare.html" element={
          <div id="compareProjectMaterialAttributePage"><CompareProjectMaterialAttributePage /></div>}
        />
        <Route path="/library/projectMaterialAttributes/link.html" element={<div id="linkProjectMaterialAttributePage">
          <LinkProjectMaterialAttributePageI18n /></div>}
        />
        <Route path="/library/projectMaterialAttributes/unlink.html" element={
          <div id="unlinkProjectMaterialAttributePage"><UnlinkProjectMaterialAttributePageI18n /></div>}
        />
        <Route path="/library/projectMaterials/create.html" element={<div id="createProjectMaterialPage">
          <CreateProjectMaterialPageI18n /></div>}
        />
        <Route path="/library/projectMaterials/swap.html" element={<div id="swap-project-material-page">
          <SwapProjectMaterialPageI18n /></div>}
        />
        <Route path="/library/projectMaterials/sync.html" element={<div id="sync-project-material-page">
          <SyncProjectMaterialPageI18n /></div>}
        />
        <Route path="/library/projectMaterials/unlink.html" element={<div id="unlinkProjectMaterialPage">
          <UnlinkProjectMaterialPageI18n /></div>}
        />
        <Route path="/library/projectMaterials/unsync.html" element={<div id="unsyncProjectMaterialPage">
          <UnsyncProjectMaterialPageI18n /></div>}
        />
        <Route path="/library/list.html" element={
          <div id="library-list-page" className="full-screen-display">
            <LibraryPageI18n />
          </div>
        }
        />

        <Route path="/processExplorer/ipas/viewEdit.html" element={<div id="ipaPage"><IPAI18n /></div>} />
        <Route path="/processExplorer/iqas/viewEdit.html" element={<div id="iqaPage"><IQAI18n /></div>} />
        <Route path="/processExplorer/linkTechTransfer/link.html" element={<div id="linkTechTransferPage">
          <LinkTechTransferPageI18n /></div>}
        />
        <Route path="/processExplorer/materialAttributes/viewEdit.html" element={<div id="materialAttributePage">
          <MaterialAttributeI18n /></div>}
        />
        <Route path="/processExplorer/materials/viewEdit.html" element={<div id="materialPage"><MaterialI18n />
        </div>}
        />
        <Route path="/processExplorer/processComponents/viewEdit.html" element={<div id="processComponentPage">
          <ProcessComponentI18n /></div>}
        />
        <Route path="/processExplorer/processes/viewEdit.html" element={<div id="processPage"><ProcessI18n /></div>} />
        <Route path="/processExplorer/processParameters/viewEdit.html" element={<div id="processParameterPage">
          <ProcessParameterI18n /></div>}
        />
        <Route path="/processExplorer/steps/viewEdit.html" element={<div id="stepPage"><StepI18n /></div>} />
        <Route path="/processExplorer/unitOperations/viewEdit.html" element={<div id="unitOperationPage">
          <UnitOperationI18n /></div>}
        />
        <Route path="/processExplorer/detailed.html" element={<div id="processExplorerDetailedContainer">
          <ProcessComponentI18n /></div>}
        />
        <Route path="/processExplorer/processExplorer.html" element={
          <div id="processExplorerPage" className="full-screen-display">
            <ProcessExplorerPageI18n />
          </div>
        }
        />

        <Route path="/product/qtpp/fpas/viewEdit.html" element={<div id="fpaPage"><FPAI18n /></div>} />
        <Route path="/product/qtpp/fqas/viewEdit.html" element={<div id="fqaPage"><FQAI18n /></div>} />
        <Route path="/product/qtpp/generalAttributes/viewEdit.html" element={<div id="generalAttributeViewEditPage">
          <GeneralAttributeI18n /></div>}
        />
        <Route path="/product/tpp/viewEdit.html" element={<div id="tppViewEditPage"><TPPSectionI18n /></div>} />
        <Route path="/product/list.html" element={
          <div id="productPage" className="full-screen-display">
            <ProductI18n />
          </div>
        }
        />

        <Route path="/projects/bulkApproval.html" element={<div id="bulkApprovalPage"><BulkApprovalPage /></div>} />
        <Route path="/projects/bulkProposal.html" element={<div id="bulkProposalPage"><BulkProposalPage /></div>} />
        <Route path="/projects/changeRMP.html" element={<div id="projectChangeRMPPage"><ProjectChangeRMPPage />
        </div>}
        />
        <Route path="/projects/dashboard.html" element={<div id="projectDashboardPage"><ProjectDashboardPage />
        </div>}
        />
        <Route path="/projects/list.html" element={<div id="projectListPage"><ProjectListPageI18n /></div>} />
        <Route path="/projects/viewEdit.html" element={<div id="projectPage"><Project /></div>} />

        <Route path="/reports/cannedReports/base.html" element={<div id="cannedReportPage"><DataReportPage /></div>} />
        <Route path="/reports/chartReports.html" element={<div id="chartReportsPage"><ChartsReportPage /></div>} />
        <Route path="/reports/list.html" element={<div id="reportsPage"><ReportsPage /></div>} />
        <Route path="/reports/processCapabilityDashboard.html" element={<div id="processCapabilityDashboard">
          <ProcessCapabilityDashboardPage /></div>}
        />
        <Route path="/reports/processMapReports.html" element={
          <div id="processMapReportsPage" className="full-screen-display">
            <ProcessMapReportPage />
          </div>
        }
        />
        <Route path="/reports/riskMap.html" element={
          <div id="riskMapReportPage" className="full-screen-display">
            <RiskMapReportPage />
          </div>
        }
        />
        <Route path="/reports/riskTables.html" element={<div id="riskTablesReportPage"><RiskTablesReportPage />
        </div>}
        />

        <Route path="/restAPI/index.html" element={<div id="restAPIPage"><RestAPIPage /></div>} />

        <Route path="/rmps/list.html" element={<div id="rmpListPage"><RMPListPageI18n /></div>} />
        <Route path="/rmps/viewEdit.html" element={<div id="rmpPage"><RMP /></div>} />

        <Route path="/suppliers/viewEdit.html" element={<div id="supplierPage"><Supplier /></div>} />
        <Route path="/suppliers/list.html" element={<div id="supplierListPage"><SupplierListPage /></div>} />

        <Route path="/sysChecks/check.html" element={<div id="check"><CheckI18n /></div>} />

        <Route path="/techTransfer/techTransfer.html" element={
          <div id="techTransferPage" className="full-screen-display">
            <TechTransferPage />
          </div>
        }
        />

        <Route path="/training/curricula/list.html" element={<div id="curriculumListPage"><CurriculumListPage />
        </div>}
        />
        <Route path="/training/curricula/viewEdit.html" element={<div id="curriculumPage"><CurriculumI18n /></div>} />
        <Route path="/training/list.html" element={<div id="trainingListPage"><TrainingListPage /></div>} />
        <Route path="/training/myTraining.html" element={<div id="myTrainingPage"><MyTrainingI18n /></div>} />
        <Route path="/training/onboardingAdmin.html" element={<div id="trainingOnboardingPage">
          <TrainingOnboardingI18n /></div>}
        />
        <Route path="/training/viewEdit.html" element={<div id="trainingPage"><TrainingI18n /></div>} />


        <Route path="/users/apiKeys/viewEdit.html" element={<div id="apiKeyPage"><APIKey /></div>} />
        <Route path="/users/createCompany.html" element={<div id="createAccountPage"><UserAccountI18n /></div>} />
        <Route path="/users/createAccount.html" element={<Navigate to="/users/createCompany.html" />} />
        <Route path="/users/forgotPassword.html" element={<div id="forgotPasswordPage"><UserForgotPasswordI18n />
        </div>}
        />
        <Route path="/users/list.html"
               element={<div id="userListPage"><UserListPageI18n /></div>} />
        <Route path="/users/resetPassword.html" element={<div id="resetPasswordPage"><UserResetPasswordI18n /></div>} />
        <Route path="/users/signupVerification.html" element={<div id="signupVerification">
          <UserSignupVerificationI18n /></div>}
        />
        <Route path="/users/viewEdit.html" element={<div id="userPage"><User /></div>} />

        <Route path="/" element={<div id="loginPage"><UserLoginI18n /></div>} />
        <Route path="/index.html" element={<div id="loginPage"><UserLoginI18n /></div>} />

        <Route path="*" element={<PageNotFound />} />

      </RoutedComponent>
    </BrowserRouter>
  );
}

const AppWrappedWithSentry = SentryWrapper.wrap(App);
ReactDOM.render(
  <StrictMode>
    <AppWrappedWithSentry />
  </StrictMode>
  , document.getElementById("root"));
