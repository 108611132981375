"use strict";

import React from "react";
import DocLinksAttribute from "../../editor/attributes/doc_links_attribute";
import { WIDGET_FIELDS } from "./supporting_documents_attribute_constants";

/**
 * This class is responsible for handling the supporting documents for a batch record.
 */
export default class SupportingDocumentsAttribute extends DocLinksAttribute {
  constructor(props) {
    super(props, WIDGET_FIELDS);
  }

  getAppliesToOptions() {
    return this.props.getBatchImportFiles();
  }
}
