var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Popup } from '@progress/kendo-react-popup';
import PopupGrid from './popupGrid';
/**
 * @hidden
 */
export var InsertTablePopupNS;
(function (InsertTablePopupNS) {
    /**
     * @hidden
     */
    var InsertTablePopup = /** @class */ (function (_super) {
        __extends(InsertTablePopup, _super);
        function InsertTablePopup() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.pointerdown = false;
            _this.onWindowDown = function (event) {
                var anchor = _this.props.anchor;
                if (anchor && (anchor === event.target || anchor.firstElementChild === event.target)) {
                    return;
                }
                if (_this.props.show && !_this.pointerdown) {
                    _this.props.onClose();
                }
                _this.pointerdown = false;
            };
            _this.onPointerDown = function () { return _this.pointerdown = true; };
            return _this;
        }
        /**
         * @hidden
         */
        InsertTablePopup.prototype.componentDidUpdate = function (prevProps) {
            // The case when the popup is shown and the view is updated (selection or document is changed).
            if (prevProps.show && this.props.show) {
                this.props.onClose();
            }
        };
        /**
         * @hidden
         */
        InsertTablePopup.prototype.componentDidMount = function () {
            window.addEventListener('mousedown', this.onWindowDown);
            window.addEventListener('pointerdown', this.onWindowDown);
        };
        /**
         * @hidden
         */
        InsertTablePopup.prototype.componentWillUnmount = function () {
            window.removeEventListener('mousedown', this.onWindowDown);
            window.removeEventListener('pointerdown', this.onWindowDown);
        };
        /**
         * @hidden
         */
        InsertTablePopup.prototype.render = function () {
            var _a = this.props, show = _a.show, anchor = _a.anchor, dir = _a.dir, _b = _a.rows, rows = _b === void 0 ? InsertTablePopup.defaultProps.rows : _b, _c = _a.columns, columns = _c === void 0 ? InsertTablePopup.defaultProps.columns : _c;
            var horizontal = dir === 'rtl' ? 'right' : 'left';
            return (React.createElement(Popup, { anchor: anchor, anchorAlign: { horizontal: horizontal, vertical: 'bottom' }, popupAlign: { horizontal: horizontal, vertical: 'top' }, popupClass: "k-ct-popup k-group k-reset k-state-border-up", show: show, style: dir === 'rtl' ? { direction: 'rtl' } : undefined },
                React.createElement(PopupGrid, { rows: rows, columns: columns, createTableMessage: this.props.createTableMessage, createTableHintMessage: this.props.createTableHintMessage, onCellClick: this.props.onTableInsert, onDown: this.onPointerDown })));
        };
        /**
         * @hidden
         */
        InsertTablePopup.propTypes = {
            show: PropTypes.bool.isRequired,
            createTableMessage: PropTypes.string.isRequired,
            createTableHintMessage: PropTypes.string.isRequired,
            onClose: PropTypes.func.isRequired,
            onTableInsert: PropTypes.func.isRequired,
            anchor: PropTypes.any,
            rows: PropTypes.number,
            columns: PropTypes.number,
            dir: PropTypes.string
        };
        /**
         * @hidden
         */
        InsertTablePopup.defaultProps = {
            rows: 6,
            columns: 8
        };
        return InsertTablePopup;
    }(React.Component));
    InsertTablePopupNS.InsertTablePopup = InsertTablePopup;
})(InsertTablePopupNS || (InsertTablePopupNS = {}));
