"use strict";

import { fillInCriticalities } from "../../reports/canned_reports/canned_report_helper";
import * as UIUtils from "../../ui_utils";
import CommonRiskUtils from "../../../server/common/misc/common_risk_utils";
import CommonUtils from "../../../server/common/generic/common_utils";
import ConfigurableTablesAdapter from "./configurable_tables_adapter";
import { formatFloat, formatValue } from "../fieldsConfig/fields_formatter";
import { FIELD_PROPS } from "../fieldsConfig/constants/configurable_tables_field_props_config";

/**
 * This class adapts/transforms the server data for Final Attributes UI rendering.
 */
export default class ConfigurableTablesAttributeAdapter extends ConfigurableTablesAdapter {
  getRows() {
    const rows = [];
    const {
      fillInObjectListInformation,
      addCommonRiskPropsForFiltration,
      parseRiskValuesToNumbers,
      addAcceptanceCriteriaInfo,
    } = this.configurableTablesAdapterHelper;

    const records = Object.values(this.activeMap);
    super.adaptRows(records);

    for (const record of records) {
      record.effectiveModelRMP = CommonRiskUtils.filterRMPByType(this.effectiveRMP, record.typeCode);

      record.categoryFormattedValue = record.category.startsWith("[") ?
        JSON.parse(record.category) : [record.category];

      record.scopeFormattedValue = record.scope?.startsWith("[") ?
        JSON.parse(record.scope) : (record.scope ? [record.scope] : []);

      const {controlStrategy} = record;
      record.controlStrategyFormattedValue = controlStrategy !== "[]" && controlStrategy.trim()
        ? JSON.parse(controlStrategy) : null;

      record.controlStrategy = controlStrategy !== "[]" && record.controlStrategy.trim()
        ? record.controlStrategy : null;

      record.obligatoryCQA = formatValue(record.obligatoryCQA);
      record.ccp = formatValue(record.ccp);
      record.samplingPlan = formatValue(record.samplingPlan);
      record.stabilityIndicating = formatValue(record.stabilityIndicating);

      record.lowerLimit = record.lowerLimit === "NA" ? "" : record.lowerLimit;
      record.upperLimit = record.upperLimit === "NA" ? "" : record.upperLimit;
      record.estimatedSampleSize = formatFloat(record.estimatedSampleSize);

      record.gmp = formatValue(!!record.gmp);

      fillInObjectListInformation(record, FIELD_PROPS.TPP_SECTIONS, record.TPPSections);
      fillInObjectListInformation(record, FIELD_PROPS.GENERAL_ATTRIBUTES, record.GeneralAttributes);
      fillInObjectListInformation(record, FIELD_PROPS.CONTROL_METHODS, record.ControlMethods);

      let documents = [];
      if (record.riskLinks) {
        if (Array.isArray(record.riskLinks)) {
          documents = UIUtils.deepClone(record.riskLinks);
        } else {
          documents = JSON.parse(record.riskLinks);
        }
      }

      record.riskLinks = record[`${UIUtils.getTypeCodeForModelName(this.modelName)}ToGeneralAttributeRisks`];
      record.isGroupRow = true;

      fillInCriticalities(record.effectiveModelRMP, record, true);
      parseRiskValuesToNumbers(record);
      addCommonRiskPropsForFiltration(record);
      addAcceptanceCriteriaInfo(record);

      const details = [];
      let sourceDocuments = [];
      const generalAttributes = Object.values(this.results?.gaMap);
      for (const riskLink of record.riskLinks) {
        const generalAttribute = generalAttributes.find(ga => ga.id === riskLink.GeneralAttributeId);

        let riskLinkSourceDocuments = riskLink?.links ?? "";
        riskLinkSourceDocuments = riskLinkSourceDocuments.startsWith("[") ?
          JSON.parse(riskLinkSourceDocuments) : [];
        sourceDocuments = sourceDocuments
          .concat(riskLinkSourceDocuments.map(
            sourceDocument => {
              if (!sourceDocument.appliesTo) {
                return sourceDocument;
              }

              const appliesTo = sourceDocument.appliesTo.map(appliedToItem => {
                const [typeCode, id] = appliedToItem.split("-");
                const generalAttribute = generalAttributes.find(ga => ga.id === UIUtils.parseInt(id));
                return UIUtils.getRecordLabelForDisplay(typeCode, generalAttribute.id, generalAttribute.name);
              });
              return {
                ...sourceDocument,
                appliesTo: appliesTo.join(" ,"),
              };
            }
          ));

        details.push({
          id: generalAttribute.id,
          name: generalAttribute.name,
          impact: riskLink.impact,
          effect: riskLink.effect,
          uncertainty: riskLink.uncertainty,
          typeCode: generalAttribute.typeCode,
          justification: riskLink.justification,
          links: riskLink.links,
          effectiveModelRMP: record.effectiveModelRMP,
          riskInfo: riskLink.riskInfo,
          label: UIUtils.getRecordLabelForDisplay(generalAttribute.typeCode, generalAttribute.id, generalAttribute.name),
          criticality: (riskLink.impact * riskLink.uncertainty),
        });
      }

      const uniqueSourceDocuments = [];
      for (const sourceDocument of sourceDocuments) {
        const doesSourceDocumentExists = uniqueSourceDocuments
          .find(uniqueDocument => uniqueDocument.uuid === sourceDocument.uuid);
        if (!doesSourceDocumentExists) {
          uniqueSourceDocuments.push(sourceDocument);
        }
      }

      record.criticalityAssessmentLinks = (documents || []).concat(uniqueSourceDocuments).sort(CommonUtils.sortBy("index"));
      record.riskLinksDetails = details.sort(CommonUtils.sortBy("id"));
      rows.push(record);
    }

    return super.sanitizeEmptyValues(rows);
  }
}
