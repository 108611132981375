var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames } from '@progress/kendo-react-common';
import { cloneDate, getDate, isEqualDate } from '@progress/kendo-date-math';
import { registerForIntl, provideIntlService } from '@progress/kendo-react-intl';
import { Virtualization } from '../../virtualization/Virtualization';
import { View } from './View';
import { Header } from './Header';
import { dateInRange, getToday, isInRange, shiftWeekNames } from '../../utils';
import { CalendarViewEnum } from '../models';
import { TodayCommand } from './TodayCommand';
var VIEWS_COUNT = 5;
/**
 * @hidden
 */
var ViewList = /** @class */ (function (_super) {
    __extends(ViewList, _super);
    function ViewList(props) {
        var _this = _super.call(this, props) || this;
        _this.virtualization = null;
        _this.calendarView = null;
        _this.table = null;
        _this.intl = null;
        _this.bottomOffset = 0;
        _this.viewOffset = 0;
        _this.viewHeight = 0;
        _this._element = null;
        _this.isActive = false;
        _this.animateToIndex = true;
        _this.shouldScroll = false;
        _this.focusActiveDate = function () {
            if (!_this._element) {
                return;
            }
            var focusedCell = _this._element.querySelector('td.k-focus');
            var nextActiveCell = _this._element.querySelector('.k-state-pending-focus');
            if (focusedCell && focusedCell[0]) {
                focusedCell[0].classList.remove('k-focus');
            }
            if (nextActiveCell) {
                nextActiveCell.classList.add('k-focus');
            }
            _this.isActive = true;
        };
        _this.blurActiveDate = function () {
            if (!_this._element) {
                return;
            }
            var focusedCell = _this._element.querySelector('td.k-focus');
            if (focusedCell) {
                focusedCell.classList.remove('k-focus');
            }
            _this.isActive = false;
        };
        _this.handleVirtualizationMount = function (virtualization) {
            _this.virtualization = virtualization;
            if (_this.virtualization && _this.table) {
                _this.table.style.transform = "translateY(".concat(_this.viewOffset, "px)");
                var viewDate = dateInRange(_this.props.focusedDate, _this.props.min, _this.props.max);
                var indexToScroll = _this.props.service.skip(viewDate, _this.props.min);
                _this.virtualization.scrollToIndex(indexToScroll);
            }
        };
        _this.buildMonthView = function (cols, weekNames) {
            return (React.createElement("table", { key: "calendar-view-list-weekdays", className: "k-calendar-table k-calendar-weekdays" },
                React.createElement("colgroup", null, cols.map(function (_, idx) { return (React.createElement("col", { key: idx })); })),
                React.createElement("thead", { className: "k-calendar-thead" },
                    React.createElement("tr", { className: "k-calendar-tr" }, weekNames.map(function (name, idx) { return (React.createElement("th", { key: idx, className: "k-calendar-th" }, name)); })))));
        };
        _this.buildDates = function (cols, dates) {
            var cellUID = _this.props.cellUID;
            return (React.createElement("table", { className: "k-calendar-table", ref: function (table) { return _this.table = table; }, role: "grid", "aria-activedescendant": cellUID + _this.props.focusedDate.getTime() },
                React.createElement("colgroup", null, cols.map(function (_, idx) { return (React.createElement("col", { key: idx })); })),
                dates.map(function (date) { return (React.createElement(View, { ref: function (el) { if (!_this.calendarView) {
                        _this.calendarView = el;
                    } }, key: date.getTime(), activeView: _this.props.activeView, viewDate: date, min: _this.props.min, max: _this.props.max, cellUID: cellUID, focusedDate: _this.props.focusedDate, cell: _this.props.cell, selectedDate: _this.props.value, showWeekNumbers: _this.weekNumber, onChange: _this.handleDateChange, bus: _this.props.bus, service: _this.props.service, weekCell: _this.props.weekCell })); })));
        };
        _this.calculateHeights = function () {
            if (!_this.props.dom) {
                return;
            }
            var scrollableHeight = _this.props.activeView === CalendarViewEnum.month ?
                _this.props.dom.scrollableContentHeight :
                _this.props.dom.scrollableYearContentHeight;
            _this.bottomOffset = scrollableHeight - _this.props.dom.viewHeight(_this.props.activeView);
            _this.viewOffset = -1 * _this.props.dom.headerHeight;
            _this.viewHeight = _this.props.dom.viewHeight(_this.props.activeView) || 1;
        };
        _this.getTake = function (skip, total) {
            return Math.min(total - skip, _this.take);
        };
        _this.handleScrollAction = function (_a) {
            var index = _a.index, scrollAction = _a.scrollAction, pageAction = _a.pageAction;
            var skip = pageAction ? pageAction.skip : _this.state.skip;
            if (_this.state.index !== index || _this.state.skip !== skip) {
                _this.setState({ index: index, skip: skip });
            }
            if (_this.table && scrollAction) {
                var translate = "translateY(".concat(scrollAction.offset, "px)");
                _this.table.style.transform = translate;
            }
        };
        _this.handleTodayClick = function (event) {
            _this.shouldScroll = true;
            _this.handleDateChange.call(undefined, event, true);
        };
        _this.handleDateChange = function (event, isTodayClick) {
            if (isTodayClick === void 0) { isTodayClick = false; }
            var onChange = _this.props.onChange;
            if (onChange) {
                var args = {
                    syntheticEvent: event.syntheticEvent,
                    nativeEvent: event.nativeEvent,
                    value: cloneDate(event.value),
                    target: _this,
                    isTodayClick: isTodayClick
                };
                onChange.call(undefined, args);
            }
        };
        _this.lastView = _this.props.activeView;
        _this.lastFocus = _this.props.focusedDate;
        _this.state = {
            skip: _this.props.service.skip(_this.props.focusedDate, _this.props.min),
            index: _this.props.service.skip(_this.props.focusedDate, _this.props.min)
        };
        return _this;
    }
    Object.defineProperty(ViewList.prototype, "element", {
        get: function () {
            return this._element;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ViewList.prototype, "weekNames", {
        get: function () {
            this.intl = provideIntlService(this);
            var weekNames = shiftWeekNames(this.intl.dateFormatNames({ nameType: 'short', type: 'days' }), this.intl.firstDay());
            return this.weekNumber ? [''].concat(weekNames) : weekNames;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ViewList.prototype, "weekNumber", {
        get: function () {
            return Boolean(this.props.showWeekNumbers && this.props.activeView === CalendarViewEnum.month);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ViewList.prototype, "take", {
        get: function () {
            return this.props.take !== undefined ? this.props.take : ViewList.defaultProps.take;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ViewList.prototype, "animate", {
        get: function () {
            return Boolean(this.props.smoothScroll && this.animateToIndex);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ViewList.prototype, "todayIsInRange", {
        get: function () {
            return isInRange(getToday(), getDate(this.props.min), getDate(this.props.max));
        },
        enumerable: false,
        configurable: true
    });
    ViewList.prototype.componentDidUpdate = function (_prevProps, _prevState) {
        this.shouldScroll = false;
        if (this.indexToScroll !== undefined && this.virtualization) {
            this.virtualization[this.animate ? 'animateToIndex' : 'scrollToIndex'](this.indexToScroll);
        }
        if (this.isActive) {
            this.focusActiveDate();
        }
        this.lastView = this.props.activeView;
        // this.lastFocus = this.props.focusedDate;
        this.indexToScroll = undefined;
    };
    ViewList.prototype.render = function () {
        var _this = this;
        this.calculateHeights();
        var didViewChange = this.lastView !== this.props.activeView;
        var focusedDate = dateInRange(this.props.focusedDate, this.props.min, this.props.max);
        var skip = !didViewChange ? this.state.skip : this.props.service.skip(focusedDate, this.props.min);
        var total = this.props.service.total(this.props.min, this.props.max);
        var take = this.getTake(skip, total);
        var viewDate = this.props.service.addToDate(this.props.min, skip);
        var activeDate = this.props.service.addToDate(this.props.min, this.state.index);
        var cols = new Array(this.props.service.rowLength(this.weekNumber)).fill('');
        var didViewChanged = this.props.activeView !== this.lastView;
        this.animateToIndex = !didViewChanged;
        if (didViewChanged
            || !isEqualDate(this.lastFocus, focusedDate)
            || this.shouldScroll
            || (!this.props.shouldScroll || this.props.shouldScroll())) {
            this.indexToScroll = this.props.service.skip(focusedDate, this.props.min);
        }
        this.lastFocus = focusedDate;
        var rootClassNames = classNames('k-calendar-view k-vstack', {
            'k-calendar-monthview': this.props.activeView === CalendarViewEnum.month,
            'k-calendar-yearview': this.props.activeView === CalendarViewEnum.year,
            'k-calendar-decadeview': this.props.activeView === CalendarViewEnum.decade,
            'k-calendar-centuryview': this.props.activeView === CalendarViewEnum.century
        });
        var dates = this.buildDates(cols, this.props.service.datesList(viewDate, take));
        var viewListBody = (React.createElement(React.Fragment, null,
            React.createElement(Header, { key: "calendar-view-list-header", currentDate: activeDate, min: this.props.min, max: this.props.max, activeView: this.props.activeView, bus: this.props.bus, service: this.props.service, headerTitle: this.props.headerTitle, commands: (React.createElement(React.Fragment, null,
                    React.createElement(TodayCommand, { min: this.props.min, max: this.props.max, onClick: this.handleTodayClick, disabled: !this.todayIsInRange, tabIndex: this.props.tabIndex }))) }),
            this.props.activeView === CalendarViewEnum.month && this.buildMonthView(cols, this.weekNames),
            React.createElement(Virtualization, { key: "calendar-view-list-content", skip: skip, take: this.take, total: total, itemHeight: this.viewHeight, topOffset: this.viewOffset, bottomOffset: this.bottomOffset, scrollOffsetSize: this.viewOffset, maxScrollDifference: this.viewHeight, onScroll: this.props.onScroll, onScrollAction: this.handleScrollAction, onMount: this.handleVirtualizationMount, children: dates, tabIndex: this.props.tabIndex })));
        return (React.createElement("div", { ref: function (el) { _this._element = el; }, className: rootClassNames }, this.props.dom.didCalculate ? viewListBody : null));
    };
    ViewList.propTypes = {
        activeView: PropTypes.number.isRequired,
        bottomOffset: PropTypes.number,
        cellUID: PropTypes.string.isRequired,
        focusedDate: PropTypes.instanceOf(Date).isRequired,
        max: PropTypes.instanceOf(Date).isRequired,
        min: PropTypes.instanceOf(Date).isRequired,
        onChange: PropTypes.func,
        showWeekNumbers: PropTypes.bool,
        smoothScroll: PropTypes.bool,
        take: PropTypes.number,
        value: PropTypes.instanceOf(Date),
        viewHeight: PropTypes.number,
        viewOffset: PropTypes.number,
        tabIndex: PropTypes.number
    };
    ViewList.defaultProps = {
        take: VIEWS_COUNT,
        showWeekNumbers: false,
        smoothScroll: true
    };
    return ViewList;
}(React.Component));
export { ViewList };
registerForIntl(ViewList);
