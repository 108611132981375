"use strict";

import * as UIUtils from "../../../ui_utils";
import {filterFields} from "../../columnSelection/configurable_tables_column_selection_helper";
import ConfigurableTablesColumnGenerator from "../columnGenerator/configurable_tables_columns_generator";
import {EMPTY_STRING} from "../../../helpers/constants/constants";

export const IGNORED_COLUMNS = ["expanded", "selected"];
const COLUMN_PREFERENCES_VERSION = 1;

export function saveColumnPreferences(eventColumns: any[], storageKey: string) {
  const columns = eventColumns.map(({field, orderIndex, width}) => ({field, orderIndex, width}));
  UIUtils.pushHistoryURLWithNewParameter({}, storageKey, JSON.stringify(columns));
  localStorage.setItem(storageKey, JSON.stringify(columns));
  return columns;
}

export function getPreferencesStorageKey(selectedModelType: string) {
  const projectId = UIUtils.getParameterByName("projectId");
  const key = `${UIUtils.stripAllWhitespaces(selectedModelType)}TableColumnPreferences`;
  return `${key}${projectId ?? EMPTY_STRING}_v${COLUMN_PREFERENCES_VERSION}`;
}

export function getColumnPreferences(storageKey: string) {
  const preferences = UIUtils.getParameterByName(storageKey) || localStorage.getItem(storageKey);
  return JSON.parse(preferences);
}

export function adjustColumnWidths(columns: any[], width: number, storageKey: string) {

  const totalWidth = columns.reduce((total: number, col: any) => total + parseFloat(col.width), 0);
  if (totalWidth >= width) {
    return saveColumnPreferences(columns, storageKey);
  }

  const offset = (width - totalWidth) / columns.length;
  let adjustedWidth = 0;

  columns.forEach((column: any) => {
    if (IGNORED_COLUMNS.includes(column.field)) {
      return;
    }

    const colWidth = parseFloat(column.width);
    column.width = colWidth + Math.trunc(offset);
    adjustedWidth += parseFloat(column.width);
  });

  // console.debug("Adjusted Width is: ", adjustedWidth, " And Table Width is: ", width);
  const sortedColumns: any[] = [...columns].sort(UIUtils.sortBy("width"));

  if (adjustedWidth > width) {
    const difference: number = adjustedWidth - width;
    const widestColumn: any = sortedColumns[sortedColumns.length - 1];

    columns.forEach((col: any) => {
      if (col.field === widestColumn.field) {
        col.width = parseFloat(col.width) - difference;
      }
    });

    // console.log("After Adjustment Width is greater than table");
    // console.log("Second Adjusted Width is: ", columns.reduce((total, col) => total += parseFloat(col.width), 0));
  } else if (width > adjustedWidth) {
    const difference: number = width - adjustedWidth;
    const smallestColumn: any = sortedColumns[0];

    columns.forEach((col: any) => {
      if (col.field === smallestColumn.field) {
        col.width = parseFloat(col.width) + difference;
      }
    });

    // Add small pixels to the last column to remove the noise.
    columns[columns.length - 1].width = parseFloat(columns[columns.length - 1].width) + 0.2;

    // console.log("After Adjustment Width is smaller than table");
    // console.log("Second Adjusted Width is: ", columns.reduce((total, col) => total += parseFloat(col.width), 0));
  }

  return saveColumnPreferences(columns, storageKey);
}

export function filterTableFields(fields: string, project: string, filterFieldsFunction: any) {
  const filterFunction = filterFieldsFunction || filterFields;
  return filterFunction(fields, project);
}

/**
 * This is required because the user might update the visibleColumns and the remaining saved column's sizes are
 * smaller than the table width, in this case we add an extra offset to each column.
 * @param columns
 * @param tableWidth
 * @returns {number}
 */
export function getColumnOffset(columns: any[], tableWidth: number): number {
  // Filter out the "expanded" [>] column in the materDetail table
  const filteredColumns = columns.filter(col => col.props.field !== "expanded");
  const totalWidth = filteredColumns.reduce((total, column) => (total += parseFloat(column.props.width)), 0);

  const columnsCount = filteredColumns.length;
  return tableWidth > totalWidth ? (tableWidth - totalWidth) / columnsCount : 0;
}

export function generateColumns(columnGenerator: ConfigurableTablesColumnGenerator, fields: any, columnPreferences: any[], tableWidth: any) {
  const columns = columnGenerator.generateColumns(fields);
  const orderedColumns = applyColumnOrder(columns, columnPreferences);
  return applyColumnWidth(orderedColumns, columnPreferences, tableWidth);
}

export const applyColumnOrder = (columns: any[], columnOrder: any[]) => {
  if (!columns.length || !columnOrder) {
    return columns;
  }

  for (const column of columns) {
    const field = column.props?.field || column.field || UIUtils.convertToCamelCaseId(column["columnName"]);
    const col = columnOrder?.find(col => col.field === field);
    if (col) {
      if (column.props) {
        column.props.orderIndex = col.orderIndex;
      } else {
        column.orderIndex = col.orderIndex;
      }
    }
  }

  return columns;
};

export const applyColumnWidth = (columns, resizedColumns, tableWidth) => {

  columns = columns.map(col => {
    const resizedCol = resizedColumns?.find(resizedCol => resizedCol.field === col.props.field);
    return resizedCol ? {...col, props: {...col.props, width: resizedCol.width}} : col;
  });

  const offset = getColumnOffset(columns, tableWidth);
  columns = columns?.map(col => {
    if (IGNORED_COLUMNS.includes(col.props.field)) {
      return col;
    }
    return {
      ...col,
      props: {...col.props, width: col.props.width + offset}
    };
  });

  return columns;
};