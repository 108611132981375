/**
 * @hidden
 */
export var MIN_RATIO = 0.00001;
/**
 * @hidden
 */
export var LABEL_DECIMALS = 3;
/**
 * @hidden
 */
export var DEFAULT_ANIMATION_DURATION = 400;
/**
 * @hidden
 */
export var NO_ANIMATION = 0;
