"use strict";

import React from "react";
import * as styles from "./styles/more_actions.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";

export const MoreActionsLink = ({id, label, onClick}) => {
  return (
    <a id={id} className={`${styles["container"]} ${styles["link"]}`} onClick={onClick}>
      {label}
      <FontAwesomeIcon icon={faExternalLink} className={styles["icon"]} />
    </a>
  );
};
