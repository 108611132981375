import { BaseParser } from "./base_parser";

/**
 * Parser that parses all reference block widgets
 */
export class ReferenceBlockParser extends BaseParser {
  getElements() {
    // We start at 2 since the first two elements are "widget-main" and "widget-content"
    return Array.from(this.node.childNodes).slice(2);
  }

  parse() {
    const newElements = [];
    const elements = this.getElements();

    for (let i = 0; i < elements.length; i++) {
      const childNode = elements[i];
      const orderedNumberSpan = document.createElement("span");
      orderedNumberSpan.innerText = `(${i + 1}) `;
      childNode.prepend(orderedNumberSpan);
      newElements.push(childNode);
    }

    return newElements;
  }
}
