import React from "react";
import BaseEditableListTable from "../../base_editable_list_table";
import * as UIUtils from "../../ui_utils";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { COLUMN_FORMATTERS } from "../../widgets/tables/column_formatters";

// i18next-extract-mark-ns-start library
class LibraryBatchList extends BaseEditableListTable {
  constructor(props) {
    super(props, UIUtils.capitalize(UIUtils.convertToCamelCaseId(props.editableName)));

    this.columns = this.generateColumns(props);
    this.filteredRowsNum = this.getInstances().length;
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    const table = $(this.tableRef).DataTable();
    const dataRows = table.rows().data();
    this.filteredRowsNum = dataRows.length;
  }

  generateColumns() {
    const {t} = this.props;
    const columnDateFormatter = COLUMN_FORMATTERS.DATE(UIUtils.DATE_FORMAT_FOR_DISPLAY);

    let staticColumns = [
      {
        title: t("Manufacture Date"),
        data: item => item.manufactureDate || "",
        ...columnDateFormatter,
      },
      {
        title: t("Records"),
        data: item => item.attributes.length,
      },
      {
        title: t("Status"),
        data: item => item.currentState || "",
      },
    ];

    const columns = [
      this.generateIDColumn(),
      ...(staticColumns.map(column => {
        return {
          ...column,
          data: this.createMappedCell(column.data),
          createdCell: column.createdCell,
        };
      })),
      this.generateManageColumn(),
    ];

    return columns;
  }

  generateIDColumn(action = "View") {
    let idColumn = super.generateIDColumn(action);
    idColumn.title = "Lot ID";
    return idColumn;
  }

  getOrderByColumnIndex() {
    return 0;
  }

  generateColumnDefsArray() {
    return [
      {className: "results-table-column-id", targets: [0]},
      ...(this.props.hideManageColumn ? [] : [this.generateManageColumnDef(this.columns.length - 1)]),
    ];
  }
  getShowArchivedCheckboxId() {
    return "batchesListTable_showArchivedCheckbox" + this.baseTypeCode;
  }
}

export default I18NWrapper.wrap(LibraryBatchList, ["library", "base_page"]);
// i18next-extract-mark-ns-stop library