"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import PCMDashboardToolbar from "./pcm_dashboard_toolbar";
import PCMDashboardIndexChart from "./pcm_dashboard_index_chart";
import PCMDashboardInfoBox from "./pcm_dashboard_info_box";
import { MODEL_TYPES_ENUM } from "../constants/report_constants";
import { generateCapabilityPlotChart } from "../chart_reports/chart_config_builder";
import {
  exportDashboardAsPDF,
  loadChartImagesAndExportAsPNG,
  isIndexValueEmpty,
  getDashboardIndices,
  getDashboardInfoBoxes,
  getDashboardCharts,
} from "./pcm_dashboard_helper";
import ErrorBar from "../../widgets/bars/error_bar";
import * as CannedReportHelper from "../canned_reports/canned_report_helper";
import * as ProcessCache from "../../processExplorer/process/process_cache";
import { isQualitativeMeasure, isQuantitativeMeasure } from "../../../server/common/editables/common_editables";
import CommonChartUtils from "../../../server/common/misc/common_chart_utils";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import BaseChartsReportPage from "../chart_reports/base_charts_report_page";
import { MODEL_DECLARATIONS } from "../../../server/common/generic/common_models";


/**
 * This shows the Process Analytics dashboard (but not the control charts/Process Analytics Report).
 */
export default class ProcessCapabilityDashboardPage extends BaseChartsReportPage {
  constructor(props) {
    super(props);

    this.setStateSafely({
      indices: CommonChartUtils.getEmptyIndicesObject(),
      subgroupsCount: 0,
      acceptanceCriteria: CommonChartUtils.getEmptyAcceptanceCriteriaObject(),
      isCapabilityPlotLoaded: false,
      isUICleared: true,
      isManufacturingDataCleared: true,
      dataLoaded: false,
      parentId: UIUtils.getParameterByName("parentId"),
      reportType: UIUtils.getParameterByName("reportType")
    });
    this.typeaheadLoadFinished = false;
    this.allCharts = new Map();

    // For export later on
    if (this.projectId) {
      new TypeaheadObjectCache("Process", this.projectId).loadOptions();
    }
  }

  handleProcessChange(processId) {
    super.handleProcessChange(processId);
    this.clearCharts();
  }

  handleSearchSelectionChanged(shouldSetState, options, callback) {
    let value = options.length === 0 ? null : options[0];
    if (value) {
      this.modelType = value.typeCode;
      this.typeaheadSelectedOption = value;

      this.clearCharts(() => {
        if (shouldSetState) {
          this.setStateSafely({
            selectedTypeaheadOption: value,
            allSubgroups: null,
          }, () => {
            this.loadDataFromServer();
            if (typeof callback === "function") {
              callback();
            }
          });
        } else {
          this.loadDataFromServer();
          if (typeof callback === "function") {
            callback();
          }
        }
      });
    }
  }

  loadDataFromServer(shouldPushHistory = true) {
    super.loadDataFromServer(shouldPushHistory);
    const value = this.state.selectedTypeaheadOption;

    let {processId, versionId, selectedSubgroupIds} = this.state;
    if (!processId) {
      processId = UIUtils.getParameterByName("processId");
    }

    let urlRequestParams = `?editableId=${value.id}`
      + (this.reportOptions.multiModelReport ? "&editableName=" + MODEL_TYPES_ENUM[value.typeCode] : "")
      + (versionId && !this.typeaheadLoadFinished ? "&versionId=" + versionId : "")
      + (selectedSubgroupIds ? "&selectedIds=" + JSON.stringify(selectedSubgroupIds) : "")
    ;

    if (this.projectId) {
      urlRequestParams += `&projectId=${this.projectId}`;
    }

    if (processId) {
      urlRequestParams += `&processId=${processId}`;
    }

    UIUtils.secureAjaxGET("reports/" + this.reportType + urlRequestParams).done(this.handleReceivedDataFromServer);
  }

  componentDidUpdate() {
    const {dataLoaded} = this.state;
    if (dataLoaded) {

      /* This clears any previously left Highcharts tooltips, since these are not automatically cleared up when
           re-rendering the report. This causes multiple instances of Highcharts tooltip elements to be left
           dangling in the DOM.
         */
      $(".highcharts-tooltip-container").remove();

      /* At this point do a clean up before rendering the charts again. The charts can be cleaned up by calling
         the chart.destroy() method.
       */
      for (let chart of window.HighCharts.charts.filter(chart => chart)) {
        chart.destroy();
      }
      window.HighCharts.charts.splice(0, window.HighCharts.charts.length);

      for (let [renderIn, value] of this.allCharts.entries()) {
        const {chartConfig, chartConfigParams} = value;
        const chart = window.HighCharts.chart(renderIn, chartConfig);

        if (chartConfigParams) {
          // Uncomment for verbose logging
          // let series1 = chartConfig.chart.series[0];
          // console.log(series1 ? JSON.stringify(series1.points.map(p => `[${p.x},${p.y}]`)) : "Series 1 not found");
          // let series2 = chartConfig.chart.series.length > 1 ? chartConfig.chart.series[1] : null;
          // console.log(chart.name, series2 ? JSON.stringify(series2.points.map(p => `[${p.x},${p.y}]`)) : "Series 2 not found");

          let pointsOutsideLimits = chartConfigParams.pointsOutsideLimits;
          if (pointsOutsideLimits && pointsOutsideLimits.length > 0) {
            for (let point of pointsOutsideLimits) {
              chart.series[0].data[point].update({
                color: "#cc3300"
              }, false);
            }
          }
        }

        /**
         * This part uses chart reflow to adapt to window resizing. If this part is
         * removed, charts will be cut on whatever UI structure before UI is updated.
         */
        chart.reflow();
      }

      this.setStateSafely({dataLoaded: false});
    }
  }

  handleReceivedDataFromServer(results) {
    if (results && results.instances) {
      let requirement = results.instances.requirement;
      let {
        id,
        allSubgroups,
        subgroups: selectedSubgroups,
        subgroupsFiltered,
        name,
        specification,
        lowerLimit,
        target,
        upperLimit,
        measurementUnits,
        measure,
        currentState,
        estimatedSampleSize,
      } = requirement;
      let {selectedSubgroupIds} = this.state;

      const versionId = this.typeaheadLoadFinished ? id : this.state.versionId;
      const shortElementName = CannedReportHelper.getShortElementName(this.reportType, this.modelType);
      const modelLabel = UIUtils.getRecordLabelForDisplay(shortElementName, id, name ?? specification);
      const subgroupWithOneMeasurementExists = !!selectedSubgroups.find(subgroup => subgroup.totalMeasurements === 1);
      const subgroupWithOtherMeasurementsExists = !!selectedSubgroups.find(subgroup => subgroup.totalMeasurements !== 1);
      selectedSubgroupIds = subgroupsFiltered ? selectedSubgroupIds : null;

      if (!selectedSubgroups || selectedSubgroups.length === 0) {
        UIUtils.showError(UIUtils.secureString(`Requirement (${modelLabel}) needs more subgroups to calculate process analytics.`));
        this.setStateSafely({
          modelLabel,
          versionId,
          allSubgroups,
          selectedSubgroups,
          selectedSubgroupIds,
          isManufacturingDataCleared: false,
        }, this.pushURLHistory); // Allows you to fix the problem if not enough subgroups are chosen.
      } else if (selectedSubgroups.length < 2) {
        UIUtils.showError(`At least 2 subgroups are required for the process analytics reports.`);
        this.setStateSafely({
          modelLabel,
          versionId,
          allSubgroups,
          selectedSubgroups,
          selectedSubgroupIds,
          isManufacturingDataCleared: false,
        }, this.pushURLHistory); // Allows you to fix the problem if not enough subgroups are chosen.
      } else if (subgroupWithOneMeasurementExists && subgroupWithOtherMeasurementsExists) {
        UIUtils.showError(`Calculations are different for subgroups that have 1 sample vs multiple samples. Ensure all subgroups have either 1 sample or more than 1 sample.`);
        this.setStateSafely({
          modelLabel,
          versionId,
          allSubgroups,
          selectedSubgroups,
          selectedSubgroupIds,
        }, this.pushURLHistory);
      } else {
        this.allCharts = new Map();
        let acceptanceCriteria = {
          lsl: lowerLimit && UIUtils.isNumber(lowerLimit) ? lowerLimit : null,
          target: target && UIUtils.isNumber(target) ? target : null,
          usl: upperLimit && UIUtils.isNumber(upperLimit) ? upperLimit : null,
          unit: measurementUnits,
        };

        let capabilityPlotConfigParams;
        let capabilityPlotConfig;
        if (isQuantitativeMeasure(measure)) {
          capabilityPlotConfigParams = this.getChartConfigFromRequirement(requirement, "capabilityPlotChartConfig");

          capabilityPlotConfig = {};
          capabilityPlotConfig.config = generateCapabilityPlotChart(capabilityPlotConfigParams, false, 130, true, false, true);
          capabilityPlotConfig.indices = capabilityPlotConfigParams.indices;
          capabilityPlotConfig.validations = capabilityPlotConfigParams.validations;
        }

        const indices = (capabilityPlotConfig && capabilityPlotConfig.indices)
          || CommonChartUtils.getEmptyIndicesObject();
        const isCapabilityPlotLoaded = capabilityPlotConfig
          && capabilityPlotConfig.config.series.length > 0
          && !isIndexValueEmpty(acceptanceCriteria.lsl)
          && !isIndexValueEmpty(acceptanceCriteria.usl);

        getDashboardIndices(this.reportType, acceptanceCriteria).forEach(indexChart => {
          if (indices[indexChart.indexName] !== "N/A") {
            let chartConfig = {};
            chartConfig = indexChart.loader(indices[indexChart.indexName]);
            this.allCharts.set(indexChart.renderIn, {chartConfig});
          }
        });

        getDashboardCharts(this.reportType, selectedSubgroups, requirement.measure)
          .forEach(chart => {
            let chartConfigParams = this.getChartConfigFromRequirement(requirement, `${chart.renderIn}Config`);
            chartConfigParams.title = chart.title;
            chartConfigParams.yAxisLabel = chart.yAxisLabel;
            chartConfigParams.showUSL = chart.showUSL;
            chartConfigParams.showLSL = chart.showLSL;
            chartConfigParams.fixToMostDecimalPlaces = chart.fixToMostDecimalPlaces;

            let chartConfig = chart.loader(chartConfigParams, chart.enableExport, chart.showTitle,
              chart.showBorders, chart.height, chart.cleanChartUI, chart.isCenterLineDashed);
            this.allCharts.set(chart.renderIn, {chartConfig, chartConfigParams});
          });

        if (isCapabilityPlotLoaded) {
          this.allCharts.set("capabilityPlotChart", {chartConfig: capabilityPlotConfig.config});
        }

        this.setStateSafely({
          indices,
          acceptanceCriteria,
          isCapabilityPlotLoaded,
          isUICleared: false,
          isManufacturingDataCleared: false,
          estimatedSampleSize,
          allSubgroups,
          selectedSubgroups,
          selectedSubgroupIds,
          measure,
          currentState,
          modelLabel,
          versionId,
          subgroupsCount: requirement.subgroupsCount,
          dataLoaded: true,
        }, this.pushURLHistory);
      }
    }
  }

  getChartConfigFromRequirement(requirement, chartKey) {
    const {chartGroups} = requirement;
    for (let chartGroup of chartGroups) {
      const chartConfig = chartGroup.find(chart => chart.key === chartKey);
      if (chartConfig) {
        return chartConfig.data;
      }
    }
  }

  clearCharts(callback) {
    this.setStateSafely({
      isCapabilityPlotLoaded: false,
      indices: CommonChartUtils.getEmptyIndicesObject(),
      acceptanceCriteria: CommonChartUtils.getEmptyAcceptanceCriteriaObject(),
      subgroupsCount: 0,
      isUICleared: true,
      isManufacturingDataCleared: true,
    }, callback);
  }

  handleDashboardControlChartClick() {
    if (this.typeaheadSelectedOption) {
      let reportType;
      if (isQualitativeMeasure(this.state.measure)) {
        reportType = "ControlChartsDefectivesReport";
      } else {
        reportType = "ControlChartsContinuousReport";
      }

      if (this.typeaheadSelectedOption.typeCode === MODEL_DECLARATIONS.SPECIFICATION.typeCode) {
        reportType = "Library" + reportType;
      }

      let urlRequestParams = `reportType=${reportType}`;
      urlRequestParams += `&modelLabel=${this.typeaheadSelectedOption.typeCode}-${this.typeaheadSelectedOption.id}`;
      urlRequestParams += this.state.versionId ? ("&versionId=" + this.state.versionId) : "";
      urlRequestParams += this.state.parentId ? ("&parentId=" + this.state.parentId) : "";
      urlRequestParams += (this.state.selectedSubgroupIds ? "&selectedIds=" + JSON.stringify(this.state.selectedSubgroupIds) : "");

      if (this.projectId) {
        urlRequestParams += `&projectId=${this.projectId}`;
      }

      window.open(UIUtils.getSecuredURL(`chartReports.html?${urlRequestParams}`));
    }
  }

  handleDashboardExport(event) {
    let chartImagesMap = new Map();
    let exportConfig = this.state.indices;
    let {usl, lsl, target, unit} = this.state.acceptanceCriteria;
    let indicesCharts = getDashboardIndices(this.reportType).map(chart => chart.name);

    if (this.state.acceptanceCriteria) {
      exportConfig.usl = usl ? `${usl}${unit ? ` ${unit}` : ""}` : "N/A";
      exportConfig.lsl = lsl ? `${lsl}${unit ? ` ${unit}` : ""}` : "N/A";
      exportConfig.target = target ? `${target}${unit ? (isQualitativeMeasure(this.state.measure) ? `${unit}` : ` ${unit}`) : ""}` : "N/A";
    }

    getDashboardIndices(this.reportType).forEach(
      indexChart => {
        exportConfig[`is${UIUtils.capitalize(indexChart.name)}Visible`] = false;
      },
    );

    exportConfig.isCapabilityPlotVisible = this.state.isCapabilityPlotLoaded;
    if (this.projectId) {
      exportConfig.projectName = this.state.project.name;
      const processes = new TypeaheadObjectCache("Process", this.projectId).getOptionsFromCache();
      const processId = ProcessCache.getProcessIdUsedRecently(this.projectId);
      exportConfig.processLabel = UIUtils.getRecordCustomLabelForDisplay(processes.find(process => process.id === processId));
    } else {
      exportConfig.projectName = "";
      exportConfig.processLabel = "";
    }

    exportConfig.modelLabel = this.state.modelLabel;
    exportConfig.currentState = this.state.currentState ?? "";

    CannedReportHelper.addTopLevelInformation(exportConfig, {reportType: this.reportType});

    // Covert highCharts SVG to base64
    const toDataURL = require("svgtodatauri");

    for (let chart of window.HighCharts.charts.filter(chart => chart && chart.series.length > 0)) {
      if (indicesCharts.includes(chart.renderTo.id)) {
        chart.yAxis[0].userOptions.labels.enabled = false;
      }

      let svgString = chart.getSVG();
      if (chart.renderTo.id === "capabilityPlotChart") {
        svgString = chart.getSVG({
          chart: {
            width: 220,
            height: 120,
          },
        });
      }

      let parser = new DOMParser();
      let svgElem = parser.parseFromString(svgString, "image/svg+xml").documentElement;
      let base64 = toDataURL(svgElem);

      chartImagesMap.set(chart.renderTo.id, base64);
    }

    if (event.target.name === "pdf") {
      exportDashboardAsPDF(chartImagesMap, exportConfig, this.reportType, this.modelType);
    } else {
      loadChartImagesAndExportAsPNG(chartImagesMap, exportConfig, this.reportType,
        this.state.acceptanceCriteria, this.state.selectedSubgroups, this.state.measure);
    }
  }

  renderIndices() {
    return (
      getDashboardIndices(this.reportType, this.state.acceptanceCriteria).map((indexChart, index) => {
        return (
          <PCMDashboardIndexChart
            key={index}
            title={indexChart.displayName}
            value={this.state.indices[indexChart.indexName]}
            hideChart={false}
            id={indexChart.name}
          />
        );
      })
    );
  }

  renderInfoBoxes() {
    return (
      getDashboardInfoBoxes(this.reportType, this.state.acceptanceCriteria, this.state.measure)
        .map((infoBox, index) => {
          return (
            <PCMDashboardInfoBox
              key={index}
              title={infoBox.title}
              value={infoBox.value}
              id={infoBox.name}
            />
          );
        })
    );
  }

  renderControlCharts() {
    return (
      getDashboardCharts(this.reportType, this.state.selectedSubgroups, this.state.measure)
        .map((chart, index) => {
          return (
            <div key={index} className={"pcm-dashboard-control-charts" +
              (chart.isLastChart ? " pcm-dashboard-control-charts-last" : "")}
            >
              <div className="col-form-label pcm-dashboard-index-chart-label">
                <label className="pcm-dashboard-control-chart-title">
                  {this.getChartTitle(chart)}
                </label>
              </div>
              <div id={chart.renderIn} className="pcm-dashboard-control-chart" />
            </div>
          );
        })
    );
  }

  getChartTitle(chart) {
    return chart.renderIn === "lastSubgroupsChart" ? `${this.state.subgroupsCount > 0 ?
      `${this.state.subgroupsCount}` : ""} Subgroups` : chart.title;
  }

  renderLeftPanel() {
    return this.renderDashboard();
  }

  renderDashboard() {
    let selectedTypeaheadOption = this.state.selectedTypeaheadOption || null;
    if (!this.typeaheadLoadFinished && selectedTypeaheadOption && selectedTypeaheadOption.label) {
      this.handleSearchSelectionChanged(false, [selectedTypeaheadOption], () => {
        this.typeaheadLoadFinished = true;
      });
    }

    return (
      <div className="pcm-dashboard-container container-fluid">
        <PCMDashboardToolbar
          processId={this.state.processId}
          reportOptions={this.reportOptions}
          disableDashboardButtons={this.state.isUICleared}
          requirementName={this.state.modelLabel}
          parentId={this.state.parentId}
          reportType={this.state.reportType}
          typeaheadOptions={this.state.typeaheadOptions}
          selectedTypeaheadOption={this.state.selectedTypeaheadOption}
          onDashboardExport={this.handleDashboardExport}
          onDashboardControlChartClick={this.handleDashboardControlChartClick}
          onSearchSelectionChanged={this.handleSearchSelectionChanged.bind(this, true)}
          onChangeData={this.openRightPanel}
          allSubgroups={this.state.allSubgroups || []}
          selectedSubgroups={this.state.selectedSubgroups || []}
        />
        <ErrorBar className="pcm-dashboard-error-bar" />
        {this.state.isUICleared ?
          <div className="pcm-dashboard-canvas-transparent-container" /> :
          <div id="pcm-dashboard-canvas" className="pcm-dashboard-canvas-container">
            <div id="pcmDashboardLeftCanvas" className="pcm-dashboard-canvas-left">
              {this.renderInfoBoxes()}
              {!isQualitativeMeasure(this.state.measure) ?
                <Fragment>
                  {this.renderIndices()}
                  <PCMDashboardIndexChart
                    title="Capability Plot"
                    hideChart={!this.state.isCapabilityPlotLoaded}
                    titleBackgroundClassName={this.state.isCapabilityPlotLoaded ?
                      "pcm-dashboard-index-chart-white-background" : null}
                    value={this.state.isCapabilityPlotLoaded ? null : "N/A"}
                    id="capabilityPlotChart"
                  />
                </Fragment> : ""}
            </div>
            <div id="pcmDashboardRightCanvas" className="pcm-dashboard-canvas-right">
              {this.renderControlCharts()}
            </div>
          </div>}
        {this.state.isManufacturingDataCleared ? "" :
          this.renderBatchManufacturingData()}
      </div>
    );
  }

}
