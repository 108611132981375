"use strict";

import React, { useEffect, useRef } from "react";
import { Grid } from "@progress/kendo-react-grid";
import { RISK_TYPE_ENUM } from "../../helpers/constants/constants";
import { generateTableIds } from "./configurable_tables_helper";
import { FIELD_PROPS } from "../fieldsConfig/constants/configurable_tables_field_props_config";
import { LARGE_COLUMN, MEDIUM_COLUMN, SMALL_COLUMN } from "./configurable_tables_constants";
import { WIDGET_FIELDS } from "../../editor/attributes/constants/multi_documents_risk_links_attribute_constants";

const TABLE_ID = "criticalityAssessmentTable";

const  ConfigurableRiskDetailsTable = ({dataItem}) => {
  const tableRef = useRef();

  useEffect(() => {
    generateTableIds(tableRef, TABLE_ID);
  }, []);

  const {riskLinksDetails} = dataItem;

  const {IMPACT, UNCERTAINTY, CRITICALITY, EFFECT} = RISK_TYPE_ENUM;
  const showEffect = !WIDGET_FIELDS.find(widget => widget.fieldName === "effect").ignoredOnModels.includes(dataItem.modelName.toLowerCase());

  return (
      <div className="configurable-risk-details-table">
        {
          riskLinksDetails?.length > 0 && (
            <div>
              <h2>Criticality Assessment</h2>
              <Grid style={{width: "fit-content"}}
                    data={riskLinksDetails}
                    ref={tableRef}>
                {dataItem.columnGenerator.generateIdColumn(false)}

                {dataItem.columnGenerator.generateNameColumn(false)}
                { showEffect &&
                    dataItem.columnGenerator.generateTextColumn(EFFECT, FIELD_PROPS.EFFECT, false, LARGE_COLUMN)
                }
                {dataItem.columnGenerator.generateRiskColumn(IMPACT, FIELD_PROPS.IMPACT, IMPACT, false)}
                {dataItem.columnGenerator.generateRiskColumn(UNCERTAINTY, FIELD_PROPS.UNCERTAINTY, UNCERTAINTY, false)}
                {dataItem.columnGenerator.generateRiskColumn(CRITICALITY, FIELD_PROPS.CRITICALITY, CRITICALITY, false)}
                {dataItem.columnGenerator.generateTextColumn("Justification", FIELD_PROPS.JUSTIFICATION, false)}
              </Grid>
            </div>
          )
        }
      </div>
  );
};

export default React.memo(ConfigurableRiskDetailsTable);
