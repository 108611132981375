import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames } from '@progress/kendo-react-common';
import { labelsOptional, messages } from './messages';
import { useLocalization } from '@progress/kendo-react-intl';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from './package-metadata';
/**
 * Represents the KendoReact Label component.
 *
 * @example
 * ```jsx
 * const sizes = ["X-Small", "Small", "Medium", "Large", "X-Large", "2X-Large"];
 * const App = () => {
 *     const ddlRef = React.useRef(null);
 *     const labelId = 'ddl-sizes-label';
 *     const editorId = 'ddl-sizes';
 *
 *     return (
 *         <div>
 *             <Label id={labelId} editorId={editorId} editorRef={ddlRef}>
 *                 Shirt Size:
 *             </Label>
 *             <DropDownList
 *                 ref={ddlRef}
 *                 id={editorId}
 *                 ariaLabelledBy={labelId}
 *                 data={sizes}
 *             />
 *             <br />
 *         </div>
 *     );
 * };
 *
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var Label = function (props) {
    validatePackage(packageMetadata);
    var id = props.id, editorId = props.editorId, editorRef = props.editorRef, editorDisabled = props.editorDisabled, children = props.children, editorValid = props.editorValid, style = props.style, className = props.className, optional = props.optional;
    var localizationService = useLocalization();
    var localizedOptional = optional ? localizationService
        .toLanguageString(labelsOptional, messages[labelsOptional]) : '';
    var optionalElement = localizedOptional && (React.createElement("span", { className: 'k-label-optional' }, localizedOptional));
    var onLabelClick = React.useCallback(function (e) {
        if (editorRef && editorRef.current && !editorDisabled) {
            if (editorRef.current.focus) {
                e.preventDefault();
                editorRef.current.focus();
            }
            var editorActionElement = editorRef.current.actionElement;
            if (editorActionElement) {
                e.preventDefault();
                editorActionElement.click();
            }
        }
    }, [editorRef]);
    var labelClassName = classNames({
        'k-label': true,
        'k-label-empty': !children,
        'k-text-error': editorValid === false,
        'k-text-disabled': editorDisabled === true
    }, className);
    return (React.createElement("label", { id: id, htmlFor: editorId, onClick: onLabelClick, style: style, className: labelClassName },
        children,
        optionalElement));
};
Label.propTypes = {
    id: PropTypes.string,
    editorId: PropTypes.string,
    editorRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any })
    ]),
    editorValid: PropTypes.bool,
    editorDisabled: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string,
    optional: PropTypes.bool
};
Label.displayName = 'KendoReactLabel';
