"use strict";

import { WidgetParser } from "./widget_parser";
import { QbDFieldParser } from "./qbd_field_parser";
import { ImageParser } from "./image_parser";
import * as DocumentTransferHelper from "../../../helpers/document_transfer_helper";
import { getAttributes } from "../index";
import { FileType } from "../../common/constants";
import { PageBreakParser } from "./page_break_parser";
import { DirectScopeWidgetParser } from "./direct_scope_widget_parser";

/**
 * The parser will parse all our widgets and QbD field in the text editor
 * and replace them with the actual values.
 */
export default class EditorParser {
  static parseHTMLAndReplaceWithValue(
    telerikReportContent,
    data,
    rmp,
    project,
    process,
    fileType
  ) {
    const node = new DOMParser().parseFromString(
      telerikReportContent,
      "text/html"
    );
    return new Parser(node, data, fileType, rmp, project, process, false)
      .parseFields()
      .output();
  }

  static getFields(telerikReportContent, currentState) {
    const node = new DOMParser().parseFromString(
      telerikReportContent,
      "text/html"
    );
    return new Parser(node, currentState).parseFields().fields;
  }

  static cleanImageElementsBeforeSave(telerikReportContent) {
    const node = new DOMParser().parseFromString(
      telerikReportContent,
      "text/html"
    );
    const parser = new Parser(node, null, FileType.PDF, null, null, null, true, [
      ImageParser,
    ]).parseFields();

    return {
      uploadImageLinks: parser.fields["images"],
      content: parser.output(),
    };
  }

  static parseImagesAndUpdateSrc(telerikReportContent, uploadImageLinks, imageUrl = null) {
    const node = new DOMParser().parseFromString(
      telerikReportContent,
      "text/html"
    );
    const data = {images: {}};
    uploadImageLinks.forEach((uploadImageLink) => {
      data.images[uploadImageLink.S3TmpKey] = {
        url: imageUrl,
        uploadImageLink,
      };
    });

    return new Parser(node, data, FileType.PDF, null, null, null, false, [
      ImageParser,
    ])
      .parseFields()
      .output();
  }

  static downloadImagesAsync(uploadImageLinks, onFinishedDownloadingImage) {
    for (const uploadImageLink of uploadImageLinks) {
      DocumentTransferHelper.downloadAsDataUrl(
        uploadImageLink,
        {mimeType: "image/png"}
      ).then((imageData) => {
        onFinishedDownloadingImage(uploadImageLink, imageData);
      });
    }
  }

  static getWidgetByKind(telerikReportContent, kind) {
    const node = new DOMParser().parseFromString(
      telerikReportContent,
      "text/html"
    );
    return Array.from(node.querySelectorAll(".widget"))
      .filter((widget) => widget.getAttribute("kind") === kind)
      .map((widget) => getAttributes(widget));
  }
}

class Parser {
  constructor(
    node,
    data,
    fileType = FileType.PDF,
    rmp = null,
    project = null,
    process = null,
    parseOnly = true,
    parsers = [QbDFieldParser, DirectScopeWidgetParser, WidgetParser, PageBreakParser]
  ) {
    this.node = node;
    this.data = data;
    this.fileType = fileType;
    this.rmp = rmp;
    this.project = project;
    this.process = process;
    this.parseOnly = parseOnly;
    this.fields = {};
    this.parsers = parsers;
  }

  parseFields() {
    for (const ParserClass of this.parsers) {
      const parser = new ParserClass(
        this.node,
        this.node,
        this.node,
        this.data,
        this.fileType,
        this.rmp,
        this.project,
        this.process,
        this.parseOnly
      );
      parser.parse();

      if (this.parseOnly && parser.fieldKey) {
        this.fields[parser.fieldKey] = parser.fields;
      }
    }
    return this;
  }

  output() {
    return this.node.documentElement.outerHTML;
  }
}
