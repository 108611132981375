/**
 * @hidden
 */
export var isDifferentOffset = function (oldOffset, newOffset) {
    var oldLeft = oldOffset.left, oldTop = oldOffset.top;
    var newLeft = newOffset.left, newTop = newOffset.top;
    return Math.abs(oldLeft - newLeft) >= 1 || Math.abs(oldTop - newTop) >= 1;
};
/**
 * @hidden
 */
export var hasBoundingRect = function (elem) { return !!elem.getBoundingClientRect; };
/**
 * @hidden
 */
export var FRAME_DURATION = 1000 / 60; // 1000ms divided by 60fps
/**
 * @hidden
 */
export var throttle = function (func, wait, options) {
    if (options === void 0) { options = {}; }
    var timeout, context, args, result;
    var previous = 0;
    options = options || {};
    var later = function () {
        previous = options.leading === false ? 0 : new Date().getTime();
        timeout = undefined;
        result = func.apply(context, args);
        if (!timeout) {
            context = args = null;
        }
    };
    var throttled = function () {
        var now = new Date().getTime();
        if (!previous && options.leading === false) {
            previous = now;
        }
        var remaining = wait - (now - previous);
        context = undefined; // this
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = undefined;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) {
                context = args = null;
            }
        }
        else if (!timeout && options.trailing !== false) {
            timeout = window.setTimeout(later, remaining);
        }
        return result;
    };
    return throttled;
};
