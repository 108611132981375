"use strict";

import * as UIUtils from "../ui_utils";
import React, {Fragment} from "react";
import DiffMatchPatchLib from "diff-match-patch";
import {RISK_TYPE_ENUM, TEXT_DIFF_METHOD_ENUM} from "./constants/constants";
import {getRawRiskScore} from "./risk_helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faPaperclip} from "@fortawesome/free-solid-svg-icons";
import CommonUtils from "../../server/common/generic/common_utils";
import {CommonString} from "../../server/common/generic/common_string";

let DiffMatchPatch = new DiffMatchPatchLib();

/**
 * This compares character by character to figure out the changes in the text.
 * @param oldText 
 * @param newText 
 * @param ignoreWhiteSpace should ignore whiteSpace during compare, default No
 * @returns {Array} An array of spans with the diff-inserted, diff-deleted or diff-equal class on them.
 */
export function createHTMLForTextDiff(oldText, newText, ignoreWhiteSpace = false) {
  oldText = oldText ? oldText.toString() : "";
  newText = newText ? newText.toString() : "";
  // noinspection JSCheckFunctionSignatures
  let diffs = DiffMatchPatch.diff_main(oldText, newText);
  DiffMatchPatch.diff_cleanupSemantic(diffs);
  // Uncomment for verbose logging.
  // console.log("Diffs = " + UIUtils.stringify(diffs));
  let spans = [];
  let counter = 0;
  for (let diff of diffs) {
    if (ignoreWhiteSpace && CommonString.isFalsyOrWhiteSpaceString(diff[1])) {
      continue;
    }

    let className;
    if (diff[0] === DiffMatchPatchLib.DIFF_INSERT) {
      className = "diff-inserted";
    } else if (diff[0] === DiffMatchPatchLib.DIFF_DELETE) {
      className = "diff-deleted";
    } else {
      className = "diff-equal";
    }
    spans.push(
      <span className={className}
            key={counter++}
      >{diff[1]}</span>
    );
  }
  return spans;
}

/**
 * This compares the entire text, and returns the spans as a big block.  This is useful for comparing things like combo
 * box values where you don't necessarily want a character by character diff.
 *
 * For example, if you're comparing "True" to "False", this method will mark them as being completely different, but
 * createHTMLForTextDiff will show that they both end with an "e".
 *
 * @returns {Array} An array of spans with the diff-inserted, diff-deleted or diff-equal class on them.
 */
export function createHTMLForWholeTextDiff(oldText, newText) {
  oldText = oldText || oldText === 0 ? oldText.toString() : "";
  newText = newText || newText === 0 ? newText.toString() : "";

  let spans = [];
  if (oldText !== "" && newText === "") {
    spans.push(
      <span className="diff-deleted"
            key={0}
      >{oldText}</span>
    );
  } else if (oldText === "" && newText !== "") {
    spans.push(
      <span className="diff-inserted"
            key={0}
      >{newText}</span>
    );
  } else if (oldText === newText) {
    spans.push(
      <span className="diff-equal"
            key={0}
      >{oldText}</span>
    );
  } else { // Modified
    spans.push(
      <span className="diff-deleted"
            key={0}
      >{oldText}</span>
    );
    spans.push(<br key={1} />);
    spans.push(
      <span className="diff-inserted"
            key={2}
      >{newText}</span>
    );
  }
  return spans;
}

/**
 * This compares the two arrays of strings.
 *
 * @returns {Array} An array of spans with the diff-inserted, diff-deleted or diff-equal class on them.
 */
export function createHTMLForArrayDiff(oldArray, newArray, formatValue = (value) => value) {
  oldArray = oldArray ? oldArray : [];
  newArray = newArray ? newArray : [];
  let diffs = diffLists(oldArray, newArray, null, (oldVal, newVal) => oldVal === newVal);

  let spans = [];
  let counter = 0;

  function createSpan(value, className) {
    return <span key={counter++}
                 className={className}
    >{formatValue(value, className)}</span>;
  }

  for (let oldValue of oldArray) {
    let deleted = diffs.deleted.filter(link => link === oldValue);
    // Nothing could be modified here.  That would mean the key is the same but the comparison function above would return false.
    if (deleted.length > 0) {
      let deletedValue = deleted[0];
      // Remove it so we don't accidentally mark something else as deleted.
      diffs.deleted.splice(diffs.deleted.indexOf(deletedValue), 1);
      spans.push(
        createSpan(oldValue, "diff-deleted")
      );
    } else {
      spans.push(
        createSpan(oldValue, "diff-equal")
      );
    }
  }

  // Now add in the new Links
  for (let addedValue of diffs.added) {
    spans.push(
      createSpan(addedValue, "diff-inserted")
    );
  }

  return spans;
}

function renderLinkForDiff(someLink, downloadCallback) {
  console.log(someLink, this);
  return someLink.linkType === "Attachment" ? (
    <a download={someLink.fileName}
       href=""
       onClick={someLink.uuid ? downloadCallback.bind(this, someLink) : downloadCallback}
    ><FontAwesomeIcon icon={faPaperclip} className="links-clip-icon" /></a>
  ) : someLink.link ? (
    <a href={UIUtils.cleanUpURL(someLink.link)}
       rel="noopener noreferrer"
       target="_blank"
    ><FontAwesomeIcon icon={faLink} />
    </a>
  ) : null;
}

export function createHTMLForDocsLinksDiff(idPrefix, oldLink, newLink, downloadCallback) {
  if ((!oldLink || !oldLink.link) &&
    (!newLink || !newLink.link)) {
    // old and new links are empty
    return (
      <span>Custom QbDVision Document Template</span>
    );
  }
  if (!oldLink) {
    // Added
    return (
      <span>
        <span className="links-view-link">
          <span className="diff-inserted">{newLink.linkType === "Attachment" ? newLink.fileName : newLink.link}</span>
        </span>
        {renderLinkForDiff(newLink, downloadCallback)}
      </span>
    );
  } else if (!newLink) {
    // Deleted
    return (
      <span>
        <span className="links-view-link">
          <span className="diff-deleted">{oldLink.linkType === "Attachment" ? oldLink.fileName : oldLink.link}</span>
        </span>
        {renderLinkForDiff(oldLink, downloadCallback)}
      </span>
    );
  } else if (areBaseLinksEqual(oldLink, newLink)) {
    // Equal
    return (
      <span>
        <span className="links-view-link">
          <span className="diff-equal">{oldLink.linkType === "Attachment" ? oldLink.fileName : oldLink.link}</span>
        </span>
        {renderLinkForDiff(oldLink, downloadCallback)}
      </span>
    );
  } else {
    // Modified
    if (oldLink.linkType !== newLink.linkType) {
      return (
        <span>
          <span className="links-view-link">
            <span className="diff-deleted">{oldLink.linkType === "Attachment" ? oldLink.fileName : oldLink.link}</span>
          </span>
          {renderLinkForDiff(oldLink, downloadCallback)}
          <span className="links-view-link">
            <span className="diff-inserted">{newLink.linkType === "Attachment" ? newLink.fileName : newLink.link}</span>
          </span>
          {renderLinkForDiff(newLink, downloadCallback)}
        </span>
      );
    } else {
      return (
        <span>
          <span className="links-view-link">
            {oldLink.linkType === "Attachment" ?
              createHTMLForTextDiff(oldLink.fileName, newLink.fileName)
              : createHTMLForTextDiff(oldLink.link, newLink.link)}
          </span>
          {renderLinkForDiff(newLink, downloadCallback)}
        </span>
      );
    }
  }
}

export function createHTMLForTypeaheadDiff(oldOptions, newOptions) {
  oldOptions = oldOptions ? oldOptions : [];
  let diffs = diffLists(oldOptions, newOptions, "id",
    (oldItem, newItem) => oldItem.id === newItem.id);
  // Uncomment for verbose logging
  // console.log("Links Diffs = " + UIUtils.stringify(diffs));

  let divs = [];
  let counter = 0;
  for (let oldOption of oldOptions) {
    let modifiedOptions = diffs.modified.filter(option => option.id === oldOption.id);
    let deleted = diffs.deleted.filter(option => option.id === oldOption.id);
    if (modifiedOptions.length > 0) {
      throw Error("Unexpected error: A typeahead cannot be modified."); // Defensive programing. It's either added, removed or the same.
    } else if (deleted.length > 0) {
      // Remove it so we don't accidentally mark something else as deleted.
      diffs.deleted = diffs.deleted.filter(option => option.id !== deleted[0].id);
      divs.push(
        <span key={counter++} className="typeahead-diff-container diff-deleted">{oldOption.label}</span>
      );
    } else {
      divs.push(
        <span key={counter++} className="typeahead-diff-container diff-equal">{oldOption.label}</span>
      );
    }
  }

  // Now add in the new Links
  for (let newOption of diffs.added) {
    divs.push(
      <span key={counter++} className="typeahead-diff-container diff-inserted">{newOption.label}</span>
    );
  }

  return divs;
}

export function createHTMLForLinksDiff(idPrefix, oldLinks, newLinks, typeCode) {
  let diffs = diffLists(oldLinks, newLinks, "id",
    (oldItem, newItem) => oldItem.id === newItem.id);
  // Uncomment for verbose logging
  // console.log("Links Diffs = " + UIUtils.stringify(diffs));

  let divs = [];
  let counter = 0;
  for (let oldLink of oldLinks) {
    oldLink.typeCode = typeCode;
    let modifiedLinks = diffs.modified.filter(link => link.id === oldLink.id);
    let deleted = diffs.deleted.filter(link => link.id === oldLink.id);
    if (modifiedLinks.length > 0) {
      throw Error("Unexpected error: A link cannot be modified."); // It's either added, removed or the same.
    } else if (deleted.length > 0) {
      // Remove it so we don't accidentally mark something else as deleted.
      diffs.deleted = diffs.deleted.filter(link => link.id !== deleted[0].id);
      divs.push(
        <div key={counter++}
             className="links-diff-container"
             id={idPrefix + "Of" + UIUtils.convertToId(oldLink.name)}
        >
          <span className="links-view-name diff-deleted">{UIUtils.getRecordCustomLabelForDisplay(oldLink)}</span>
        </div>
      );
    } else {
      divs.push(
        <div key={counter++}
             className="links-diff-container"
             id={idPrefix + "Of" + UIUtils.convertToId(oldLink.name)}
        >
          <span className="links-view-name diff-equal">{UIUtils.getRecordCustomLabelForDisplay(oldLink)}</span>
        </div>
      );
    }
  }

  // Now add in the new Links
  for (let newLink of diffs.added) {
    newLink.typeCode = typeCode;
    divs.push(
      <div key={counter++}
           className="links-diff-container"
           id={idPrefix + "Of" + UIUtils.convertToId(newLink.name)}
      >
        <span className="links-view-name diff-inserted">{UIUtils.getRecordCustomLabelForDisplay(newLink)}</span>
      </div>
    );
  }

  return divs;
}

export const DIFF_STATE_ENUM = {
  ADDED: "ADDED",
  DELETED: "DELETED",
  EQUAL: "EQUAL",
  MODIFIED: "MODIFIED"
};

export function getClassNameForDiffState(diffState) {
  switch (diffState) {
    case DIFF_STATE_ENUM.ADDED:
      return "diff-inserted";
    case DIFF_STATE_ENUM.DELETED:
      return "diff-deleted";
    case DIFF_STATE_ENUM.EQUAL:
      return "diff-equal";
    case DIFF_STATE_ENUM.MODIFIED:
      return "diff-equal";
    default:
      throw new Error("Unknown diff state: " + diffState);
  }
}

/**
 * Gets the label for a typeahead item
 * @param typeaheadField The typeahead field to get the options from
 * @param allItems All the items for the attribute
 * @param itemId The typeahead item id to get the label from
 * @returns {string|null}
 */
function getLabel(typeaheadField, allItems, itemId) {
  let foundItem = allItems.find(opt => opt.id === itemId);
  if (!foundItem) {
    let message = `Unable to find item in typeahead ${typeaheadField.fieldName} for id ${itemId}.`;
    console.warn(message, allItems);
    return null;
  }
  return foundItem.label;
}

/**
 * Gets the typeahead display names for the specified link
 * @param link The link to get the typeahead from
 * @param typeaheadField The typeahead field to get the options from
 * @param attributeOptions All items for the attribute
 * @returns {[]}
 */
function getTypeaheadDisplayNamesForLink(link, typeaheadField, attributeOptions) {
  return attributeOptions ? link[typeaheadField.fieldName]?.map(option => getLabel(typeaheadField, attributeOptions, option)) : [];
}

export function createRowDataDiffForJSONAttribute(idPrefix, oldLinks, newLinks, fieldsToCompare, attributeOptions, downloadCallback) {
  oldLinks = oldLinks ? oldLinks : [];

  let diffs = diffLists(oldLinks, newLinks, "uuid", areLinksEqual, fieldsToCompare);
  // Uncomment for verbose logging
  // console.log("Links Diffs = " + UIUtils.stringify(diffs));

  let diffRows = [];
  let counter = 0;
  let typeaheadField = fieldsToCompare.find(field => {
    return field.inputType === "typeahead";
  });
  for (let oldLink of oldLinks) {
    let newLink;
    let modifiedLink = diffs.modified.find(link => link.uuid === oldLink.uuid);
    let diffRow = {
      index: counter++,
      uuid: oldLink.uuid
    };
    let oldTypeaheadDisplayNames = getTypeaheadDisplayNamesForLink(oldLink, typeaheadField, attributeOptions);
    if (modifiedLink) {
      newLink = modifiedLink;
      diffRow.diffState = DIFF_STATE_ENUM.MODIFIED;
      if (attributeOptions) {
        let newTypeaheadDisplayNames = getTypeaheadDisplayNamesForLink(newLink, typeaheadField, attributeOptions);
        diffRow[typeaheadField.fieldName] = createHTMLForArrayDiff(oldTypeaheadDisplayNames, newTypeaheadDisplayNames);
      }
    } else if (diffs.deleted.includes(oldLink)) {
      newLink = null;
      diffRow.diffState = DIFF_STATE_ENUM.DELETED;
      if (attributeOptions) {
        diffRow[typeaheadField.fieldName] = createHTMLForArrayDiff(oldTypeaheadDisplayNames, null);
      }
    } else {
      newLink = oldLink;
      diffRow.diffState = DIFF_STATE_ENUM.EQUAL;
      if (attributeOptions) {
        let newTypeaheadDisplayNames = getTypeaheadDisplayNamesForLink(newLink, typeaheadField, attributeOptions);
        diffRow[typeaheadField.fieldName] = createHTMLForArrayDiff(oldTypeaheadDisplayNames, newTypeaheadDisplayNames);
      }
    }

    diffRow.linkDiffHTML = createHTMLForDocsLinksDiff(idPrefix, oldLink, newLink, downloadCallback);
    createHTMLTextDiffForFields(diffRow, fieldsToCompare.filter(field => field.inputType !== "typeahead" && field.inputType !== "linkIcon"),
      oldLink, newLink, diffRow.diffState);

    //The linkIcons are not diffed. We just show in the UI the already prepared HTML stored in the old and new links collections.
    for (let field of fieldsToCompare.filter(field => field.inputType === "linkIcon")) {
      let newLinkValue = field.getValue(newLink);
      let oldLinkValue = field.getValue(oldLink);
      let diffRowValue = newLink ? newLinkValue : oldLinkValue;

      field.setValue(diffRow, diffRowValue);
    }

    diffRows.push(diffRow);
  }

  // Now add in the new Links
  for (let newLink of diffs.added) {
    let diffRow = {
      index: counter++
    };
    diffRow.uuid = newLink.uuid;
    diffRow.diffState = DIFF_STATE_ENUM.ADDED;
    diffRow.linkDiffHTML = createHTMLForDocsLinksDiff(idPrefix, null, newLink, downloadCallback);
    if (attributeOptions) {
      let newTypeaheadDisplayNames = getTypeaheadDisplayNamesForLink(newLink, typeaheadField, attributeOptions);
      diffRow[typeaheadField.fieldName] = createHTMLForArrayDiff(null, newTypeaheadDisplayNames);
    }
    createHTMLTextDiffForFields(diffRow, fieldsToCompare.filter(field => field.inputType !== "typeahead" && field.inputType !== "linkIcon"), null, newLink, diffRow.diffState);

    //The linkIcons are not diffed. We just show in the UI the already prepared HTML stored in the old and new links collections.
    for (let field of fieldsToCompare.filter(field => field.inputType === "linkIcon")) {
      let newLinkValue = field.getValue(newLink);

      field.setValue(diffRow, newLinkValue);
    }

    diffRows.push(diffRow);
  }

  return diffRows;
}

export function createRowDataDiffForLinkedEntities(idPrefix, oldLinks, newLinks, fieldsToCompare, linkToObjectId, downloadCallback) {
  oldLinks = oldLinks ? oldLinks : [];
  let diffs = diffLists(oldLinks, newLinks, linkToObjectId ? linkToObjectId : "uuid", areLinksEqual, fieldsToCompare);

  // Uncomment for verbose logging
  // console.log("Links Diffs = " + UIUtils.stringify(diffs));

  let diffRows = [];
  let counter = 0;
  for (let oldLink of oldLinks) {
    let newLink;
    let modifiedLink = diffs.modified.find(link => (linkToObjectId ? (link[linkToObjectId] === oldLink[linkToObjectId]) : (link.uuid === oldLink.uuid)));
    let diffRow = {
      index: counter++,
      uuid: oldLink.uuid
    };
    if (modifiedLink) {
      newLink = modifiedLink;
      diffRow.diffState = DIFF_STATE_ENUM.MODIFIED;
    } else if (diffs.deleted.includes(oldLink)) {
      newLink = null;
      diffRow.diffState = DIFF_STATE_ENUM.DELETED;
    } else {
      newLink = oldLink;
      diffRow.diffState = DIFF_STATE_ENUM.EQUAL;
    }

    if (linkToObjectId) {
      diffRow[linkToObjectId] = oldLink[linkToObjectId];
    }
    diffRow.linkDiffHTML = compareRiskDocLinks(idPrefix, oldLink, newLink, downloadCallback);

    createHTMLTextDiffForFields(diffRow, fieldsToCompare.filter(field => field.inputType !== "typeahead" || field.autoComplete), oldLink, newLink, diffRow.diffState);
    diffRows.push(diffRow);
  }

  // Now add in the new Links
  for (let newLink of diffs.added) {
    let diffRow = {
      index: counter++,
      uuid: newLink.uuid
    };
    diffRow.diffState = DIFF_STATE_ENUM.ADDED;
    diffRow[linkToObjectId] = newLink[linkToObjectId];
    diffRow.linkDiffHTML = compareRiskDocLinks(idPrefix, null, newLink, downloadCallback);

    createHTMLTextDiffForFields(diffRow, fieldsToCompare, null, newLink, diffRow.diffState);
    diffRows.push(diffRow);
  }

  for (let diffRow of diffRows) {
    const oldLink = oldLinks.find(row => row[linkToObjectId] === diffRow[linkToObjectId]);
    const newLink = newLinks.find(row => row[linkToObjectId] === diffRow[linkToObjectId]);

    const compareResult = compareRiskDocLinksAll(idPrefix, oldLink, newLink, downloadCallback);
    diffRow.linkDiffHTMLAll = compareResult.fragment;
    diffRow.numberOfLinks = compareResult.numberOfLinks;
  }

  return diffRows;
}

export function createRowDataDiffForRiskLinks(idPrefix, oldLinks, newLinks, fieldsToCompare, linkToObjectId, downloadCallback, rmp, olderRMP) {
  // we do not use RiskInfo because we calculate criticality at link level (not entire record)
  
  oldLinks = oldLinks ? oldLinks : [];
  let diffs = diffLists(oldLinks, newLinks, linkToObjectId, areRiskLinksEqual, fieldsToCompare);
  let oldLinksMap = new Map(oldLinks.map(oldLink => [oldLink[linkToObjectId], oldLink]));
  let addedDiffsMap = new Map(diffs.added.map(diff => [diff[linkToObjectId], diff]));
  let modifiedDiffsMap = new Map(diffs.modified.map(diff => [diff[linkToObjectId], diff]));
  let diffRows = createRowDataDiffForLinkedEntities(idPrefix, oldLinks, newLinks, fieldsToCompare, linkToObjectId, downloadCallback);

  for (let diffRow of diffRows) {
    if (diffRow.diffState === DIFF_STATE_ENUM.ADDED) {
      let newLink = addedDiffsMap.get(diffRow[linkToObjectId]);
      diffRow.criticality = createHTMLForWholeTextDiff("", getRawRiskScore(RISK_TYPE_ENUM.CRITICALITY, rmp, newLink));
    } else {
      let oldLink = oldLinksMap.get(diffRow[linkToObjectId]);
      let newLink = null;
      if (diffRow.diffState === DIFF_STATE_ENUM.EQUAL) {
        newLink = oldLink;
      } else if (diffRow.diffState === DIFF_STATE_ENUM.MODIFIED) {
        newLink = modifiedDiffsMap.get(diffRow[linkToObjectId]);
      }
      diffRow.criticality = createHTMLForWholeTextDiff(getRawRiskScore(RISK_TYPE_ENUM.CRITICALITY, olderRMP, oldLink),
        newLink ? getRawRiskScore(RISK_TYPE_ENUM.CRITICALITY, rmp, newLink) : "");
    }
  }

  return diffRows;
}

function createHTMLTextDiffForFields(diffRow, fieldsToCompare, oldLink, newLink, diffState) {
  for (let field of fieldsToCompare) {
    let diffMethod = field.diffMethod === TEXT_DIFF_METHOD_ENUM.whole ? createHTMLForWholeTextDiff : createHTMLForTextDiff;
    let oldLinkValue = oldLink && field.getValue(oldLink);
    let newLinkValue = newLink && field.getValue(newLink);
    let diffValue;

    switch (diffState) {
      case DIFF_STATE_ENUM.MODIFIED:
        diffValue = diffMethod(oldLinkValue, newLinkValue);
        break;
      case DIFF_STATE_ENUM.ADDED:
        diffValue = diffMethod("", newLinkValue);
        break;
      case DIFF_STATE_ENUM.DELETED:
        diffValue = diffMethod(oldLinkValue, "");
        break;
      case DIFF_STATE_ENUM.EQUAL:
        diffValue = diffMethod(oldLinkValue, oldLinkValue);
        break;
      default:
        throw new Error(`Invalid diff state: ${diffState}`);
    }

    field.setValue(diffRow, diffValue);
  }
}

function areBaseLinksEqual(oldItem, newItem) {
  let areLinksEqual = (oldItem && newItem && oldItem?.linkType === newItem?.linkType);
  if (areLinksEqual) {
    if (newItem.linkType === "Attachment") {
      areLinksEqual = (oldItem.S3TmpKey === newItem.S3TmpKey) && (oldItem.S3TmpVersion === newItem.S3TmpVersion) &&
        (oldItem.link === newItem.link) && (oldItem.linkVersion === newItem.linkVersion);
    } else if (newItem.linkType === "Link") {
      areLinksEqual = (oldItem.link === newItem.link);
    } else if (newItem.linkType === "") { //Link has not been set in this case yet.
      areLinksEqual = true;
    } else {
      throw new Error("Unknown link type: " + newItem.linkType);
    }
  }
  return areLinksEqual;
}

function areLinksEqual(oldItem, newItem, fieldsToCompare) {
  let areEqual = ((oldItem.linkType && newItem.linkType) ? areBaseLinksEqual(oldItem, newItem) : true);

  return areEqual &&
    fieldsToCompare.reduce((result, field) => {
      const oldItemValue = field.getValue(oldItem);
      const newItemValue = field.getValue(newItem);
      return result && (oldItemValue === newItemValue || (!oldItemValue && !newItemValue));
    }, true);
}

function areRiskLinksEqual(oldRiskLink, newRiskLink, fieldsToCompare) {
  let oldLinks = oldRiskLink.links ? JSON.parse(oldRiskLink.links) : null;
  let newLinks = newRiskLink.links ? JSON.parse(newRiskLink.links) : null;

  let areEqual = ((oldLinks && newLinks) ? areBaseLinksEqual(oldLinks[0], newLinks[0]) : true);

  return areEqual &&
    fieldsToCompare.reduce((result, field) => {
      let oldRiskLinkValue = field.getValue(oldRiskLink);
      let newRiskLinkValue = field.getValue(newRiskLink);

      return result && (oldRiskLinkValue === newRiskLinkValue || (!oldRiskLinkValue && !newRiskLinkValue));
    }, true);
}

function compareRiskDocLinksAll(idPrefix, oldRow, newRow, downloadCallback) {
  let oldLinks = [];
  if (oldRow && oldRow.links) {
    oldLinks = JSON.parse(oldRow.links);
  }
  let newLinks = [];
  if (newRow && newRow.links) {
    newLinks = JSON.parse(newRow.links);
  }

  oldLinks = oldLinks.sort(CommonUtils.sortBy("index"));
  newLinks = newLinks.sort(CommonUtils.sortBy("index"));

  const rows = [];
  let numberOfLinks = oldLinks.length;
  for (let i = 0; i < oldLinks.length; i++) {
    const oldLink = oldLinks[i];
    const newLink = newLinks.find(link => link.uuid === oldLink.uuid);

    if (!newLink) {
      numberOfLinks--;
    }

    rows.push(<div>{createHTMLForDocsLinksDiff(idPrefix, oldLink, newLink, downloadCallback)}</div>);
  }

  const addedLinks = newLinks.filter(newLink => !oldLinks.find(oldLink => oldLink.uuid === newLink.uuid));
  for (let i = 0; i < addedLinks.length; i++) {
    const addedLink = addedLinks[i];
    numberOfLinks++;
    rows.push(<div>{createHTMLForDocsLinksDiff(idPrefix, null, addedLink, downloadCallback)}</div>);
  }

  return {
    numberOfLinks,
    fragment: <Fragment>{rows}</Fragment>
  };
}

function compareRiskDocLinks(idPrefix, oldRow, newRow, downloadCallback) {
  // We pull out the links and set the name as the filename so we can re-use the comparison
  let oldLink = null;
  if (oldRow && oldRow.links) {
    oldLink = JSON.parse(oldRow.links)[0];
  }
  let newLink = null;
  if (newRow && newRow.links) {
    newLink = JSON.parse(newRow.links)[0];
  }
  if (oldLink || newLink) {
    return createHTMLForDocsLinksDiff(idPrefix, oldLink, newLink, downloadCallback);
  } else {
    return null;
  }
}

// Ideas for this were borrowed from https://github.com/edasarl/node-diff-list/blob/master/index.js
function diffLists(oldList, newList, key, equalFunc, fieldsToCompare) {

  let transforms = {
    modified: [],
    added: [],
    deleted: []
  };
  let pkToListItemSet = {};
  let pk;

  for (let oldItem of oldList) {
    let pk;
    if (key) {
      pk = oldItem[key];
    } else {
      pk = oldItem;
    }
    if (!pkToListItemSet[pk]) {
      pkToListItemSet[pk] = [];
    }
    pkToListItemSet[pk].push(oldItem);
  }

  for (let newItem of newList) {
    let pk;
    if (key) {
      pk = newItem[key];
    } else {
      pk = newItem;
    }
    // list same and different items
    let sameItems = [];
    let diffItems = (pkToListItemSet[pk] || []).filter(function(oldItem) {
      let same = fieldsToCompare ? equalFunc(oldItem, newItem, fieldsToCompare) : equalFunc(oldItem, newItem);
      if (same) {
        sameItems.push(oldItem);
      }
      return !same;
    });
    let oldItems;
    if (sameItems.length === 0) {
      // nothing's the same so either modified or added
      if (diffItems.length > 0) {
        // set any item aside and leave the others to be marked as deleted
        diffItems.shift();
        transforms.modified.push(newItem);
      } else {
        // new item
        transforms.added.push(newItem);
      }
      oldItems = diffItems;
    } else {
      sameItems.shift();
      oldItems = sameItems.concat(diffItems);
    }

    if (oldItems.length === 0) {
      delete pkToListItemSet[pk];
    } else {
      pkToListItemSet[pk] = oldItems;
    }
  }

  for (pk in pkToListItemSet) {
    transforms.deleted = transforms.deleted.concat(pkToListItemSet[pk]);
  }

  return transforms;
}
