"use strict";

import * as UIUtils from "../ui_utils";
import React, { Fragment } from "react";
import CommonEditablesPageTitleBar from "../widgets/pageTitleBar/common_editables_page_title_bar";
import BasePage from "../base_page";
import { Button, InfoCard, QuickAccessCard } from "@qbdvision-inc/component-library";
import ProjectInfoCard from "./widgets/project_info_card";
import AlertsPanel from "./widgets/alerts_panel";
import NotificationsPanel from "./widgets/notifications_panel";
import pendingApprovalsIcon from "./icons/approvals-pending.svg";
import pendingTrainingIcon from "./icons/training-pending.svg";
import { EMPTY_DATA, TYPE_TO_ICON_ENUM } from "./constants";
import { can, generateTooltip } from "../utils/ui_permissions";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import ErrorBar from "../widgets/bars/error_bar";
import CommonSecurity from "../../server/common/generic/common_security";
import { formatDate } from "../configurableTables/fieldsConfig/fields_formatter";
import { QBDLink } from "../utils/withRouter";

/**
 * This class handles showing information needed for the home page
 */
// i18next-extract-mark-ns-start homePage
class HomePage extends BasePage {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      breadcrumb: {
        current: props.t("Home"),
      }
    };
  }

  componentDidMount() {
    document.title = "QbDVision Home Page";

    const {t} = this.props;

    UIUtils.setLoadingDisabled(true);
    this.setStateSafely({
      isLoading: true,
      breadcrumb: {
        current: t("Home"),
      }
    });
    UIUtils.secureAjaxGET("homePage").done(this.handleReceiveDataFromServer);

    super.componentDidMount();
  }

  handleReceiveDataFromServer(results) {
    const {t} = this.props;

    //Projects Dates will be updated in it proper react component
    results.alerts.instances = this.updateInstancesWithCorrectDate(results.alerts,
      "instances", "dueDate", "value", null, EMPTY_DATA.alerts);

    results.notifications.activities = this.updateInstancesWithCorrectDate(results.notifications,
      "activities", "lastActivity", null,
      UIUtils.LONG_DATE_FORMAT_FOR_DISPLAY, EMPTY_DATA.notifications);

    results.notifications.frequentlyAccessed = this.updateInstancesWithCorrectDate(results.notifications,
      "frequentlyAccessed", "lastActivity", null,
      UIUtils.LONG_DATE_FORMAT_FOR_DISPLAY, EMPTY_DATA.notifications);

    // Processing pending approvals information
    const {
      approvalsWaitingOnMe,
      approvalsWaitingOnOthers,
      pendingApprovals,
    } = results.pendingApprovals;
    const {
      pendingDraftsCount,
    } = results.pendingDrafts;

    results.pendingApprovals = {
      count: {
        title: t("My Approvals Pending"),
        value: approvalsWaitingOnMe,
      },
      stats: [
        {
          title: t("Waiting for others"),
          count: approvalsWaitingOnOthers,
        },
        {
          title: t("Pending Drafts"),
          count: pendingDraftsCount,
          onClick: this.handleHandleBulkDrafts,
          id: "pendingDraftsLink",
        },
      ],
      instances: pendingApprovals,
    };

    // Processing pending training information
    let {
      pendingTraining,
      trainedLastMonth,
    } = results.trainings || {};

    results.trainings = {
      count: {
        title: t("My Training Pending"),
        value: pendingTraining
      },
      stats: [
        {
          title: t("Trained in the last month"),
          count: trainedLastMonth
        }
      ],
    };

    // Processing project summary information
    results.projects = results.projects.map(project => {
      let instance = {
        ...project,
        stats: [
          {
            title: t("Total Records"),
            count: project.totalCount
          },
          {
            title: t("Unit Operation", {count: project.UOsCount}),
            count: project.UOsCount
          },
          {
            title: t("{{ name }} updated last week", {name: t("Record", {count: project.lastWeekActivity})}),
            count: project.lastWeekActivity
          },
        ]
      };

      delete instance.totalCount;
      delete instance.UOsCount;
      delete instance.lastWeekActivity;

      return instance;
    });

    // Processing quick access information
    results.quickAccess = [
      {
        title: t("Document", {count: results.quickAccess.documentsCount}),
        count: results.quickAccess.documentsCount,
        icon: TYPE_TO_ICON_ENUM.DOCUMENT.icon,
        url: TYPE_TO_ICON_ENUM.DOCUMENT.url,
      },
      {
        title: t("Supplier", {count: results.quickAccess.suppliersCount}),
        count: results.quickAccess.suppliersCount,
        icon: TYPE_TO_ICON_ENUM.SUPPLIER.icon,
        url: TYPE_TO_ICON_ENUM.SUPPLIER.url,
      },
      {
        title: t("Risk Management Plan", {count: results.quickAccess.RMPsCount}),
        count: results.quickAccess.RMPsCount,
        icon: TYPE_TO_ICON_ENUM.RMP.icon,
        url: TYPE_TO_ICON_ENUM.RMP.url,
      }
    ];

    this.setStateSafely({data: results, isLoading: false});
  }

  updateInstancesWithCorrectDate(object, instancesPropName, datePropName, dateValuePropName, format, empty_data) {
    return object && object[instancesPropName] ?
      object[instancesPropName].filter(record => !!record)
        .map(record => {
          let date = record[datePropName];
          if (dateValuePropName) {
            date = date[dateValuePropName];
            record[datePropName][dateValuePropName] = formatDate(date, format);
          } else {
            record[datePropName] = formatDate(date, format);
          }
          return record;
        }) : empty_data[instancesPropName];
  }

  handleAddProject() {
    this.context.navigate(UIUtils.getSecuredURL("/projects/viewEdit.html?operation=Add"));
  }

  handleHandleBulkDrafts(event) {
    UIUtils.ignoreHandler(event);

    let {data} = this.state;
    if (data) {
      let {pendingDrafts} = data;

      sessionStorage["bulkProposalRequirementIds"] = JSON.stringify(pendingDrafts.pendingDrafts);
      this.context.navigate(UIUtils.getSecuredURL("/projects/bulkProposal.html?requestedByHomePage=true"));
    }
  }

  handleHandleOpenMyTraining() {
    this.context.navigate(UIUtils.getSecuredURL("/training/myTraining.html"));
  }

  handleHandleBulkApprovals(event) {
    UIUtils.ignoreHandler(event);

    let {data} = this.state;
    if (data) {
      let {pendingApprovals} = data;

      sessionStorage["bulkApprovalRequirementIds"] = JSON.stringify(pendingApprovals.instances);
      this.context.navigate(UIUtils.getSecuredURL("/projects/bulkApproval.html?requestedByHomePage=true"));
    }
  }

  renderPage() {
    const {t} = this.props;

    let {
      pendingApprovals,
      alerts, projects, projectsTotalCount, trainings,
      quickAccess, notifications
    } = this.state.data || EMPTY_DATA;
    let cannotAddProjects = !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.PROJECT, this.state);

    return (
      <Fragment>
        <CommonEditablesPageTitleBar name={t("Home")} hasNoLinksInTitleBar={true} />
        <div id="homePageDiv" className="container">
          <ErrorBar className="home-page-error-bar" />
          <div className="row">
            <div className="col-xs-12 col-lg-9 home-page-summary-panel">
              <div className="row">
                <div className="col col-md-3 card-mt">
                  <InfoCard icon={pendingApprovalsIcon}
                            alt={t("Approvals Pending")}
                            id="pendingApprovals"
                            onInfoCardClick={this.handleHandleBulkApprovals}
                            data={pendingApprovals}
                            isLoading={this.isLoading()}
                  />
                </div>
                <div className="col col-md-3 card-mt">
                  <InfoCard icon={pendingTrainingIcon}
                            alt={t("Training Pending")}
                            id="pendingTraining"
                            onInfoCardClick={this.handleHandleOpenMyTraining}
                            data={trainings}
                            isLoading={this.isLoading()}
                  />
                </div>
                <div className="col">
                  <AlertsPanel data={alerts}
                               isLoading={this.isLoading()}
                               currentUser={this.state.loggedInUser}
                  />
                </div>
              </div>
              <div className="home-page-projects-header">
            <span className="home-page-projects-header-title">
              <span
                className={this.getClassForLoading()}
              >{projects.length}</span> {t("Project", {count: projects.length})}
              {projects.length < projectsTotalCount ? (
                <span className="home-page-projects-header-title-count">
                  &nbsp;{t("of {{projectsTotalCount}}", {
                  projectsTotalCount
                })}
                  </span>
              ) : ""
              }
            </span>
                <QBDLink className="home-page-projects-header-link"
                         to="/projects/list.html"
                >
                  {t("Go to projects")}
                </QBDLink>
                <div className="home-page-projects-header-button">
                  <Button id="homePageAddProjectButton"
                          type={"secondary"}
                          onClick={this.handleAddProject}
                          title={generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.PROJECT, this.state)}
                          isDisabled={cannotAddProjects}
                          label={t("Add Project")}
                  />
                </div>
              </div>
              <div>
                {
                  projects.length > 0 ?
                    projects.map((project, index) => {
                      return (
                        <ProjectInfoCard object={project} key={index} isLoading={this.isLoading()} />
                      );
                    }) :
                    <ProjectInfoCard cannotAddProjects={cannotAddProjects} isEmpty={true} isLoading={this.isLoading()} />
                }
              </div>
              <div className="home-page-projects-header">
                <span className="home-page-projects-header-title">{t("Quick Access")}</span>
              </div>
              <div className="row">
                {
                  quickAccess.map((instance, index) => {
                    return (
                      <div className="col card-mt" key={index}>
                        <QuickAccessCard object={instance}
                                         isLoading={this.isLoading()}
                        />
                      </div>
                    );
                  })
                }
              </div>
            </div>
            <div className="col-xs-12 col-lg-3 home-page-notifications-panel-container">
              <NotificationsPanel notifications={notifications}
                                  isLoading={this.isLoading()}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default I18NWrapper.wrap(HomePage, "homePage");
// i18next-extract-mark-ns-stop homePage
