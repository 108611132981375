var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { ColorPaletteService } from './utils/color-palette.service';
import { classNames, Keys, dispatchEvent, guid, getTabIndex, withPropsContext, createPropsContext } from '@progress/kendo-react-common';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
import { PALETTEPRESETS } from './models/palette-presets';
import { isPresent } from './utils/misc';
import { parseColor } from './utils/color-parser';
/**
 * @hidden
 */
export var DEFAULT_TILE_SIZE = 24;
/**
 * @hidden
 */
export var DEFAULT_COLUMNS_COUNT = 10;
/**
 * @hidden
 */
export var DEFAULT_PRESET = 'office';
/** @hidden */
var ColorPaletteWithoutContext = /** @class */ (function (_super) {
    __extends(ColorPaletteWithoutContext, _super);
    function ColorPaletteWithoutContext(props) {
        var _this = _super.call(this, props) || this;
        /**
         * @hidden
         */
        _this.wrapper = null;
        _this.paletteService = null;
        _this.guid = guid();
        /**
         * @hidden
         */
        _this.focus = function () {
            if (_this.wrapper) {
                _this.wrapper.focus();
            }
        };
        _this.onKeyDown = function (event) {
            switch (event.keyCode) {
                case Keys.down:
                    _this.handleCellNavigation(0, 1);
                    break;
                case Keys.up:
                    _this.handleCellNavigation(0, -1);
                    break;
                case Keys.right:
                    _this.handleCellNavigation(1, 0);
                    break;
                case Keys.left:
                    _this.handleCellNavigation(-1, 0);
                    break;
                case Keys.enter:
                    _this.handleEnter(event);
                    break;
                default: return;
            }
            event.preventDefault();
        };
        _this.onColorClick = function (color, event) {
            if (_this.isUncontrolled) {
                _this.setState({ selectedColor: color, focusedColor: color });
            }
            else {
                _this.setState({ focusedColor: color });
            }
            _this.dispatchChangeEvent(color, event);
        };
        _this.onFocus = function (nativeEvent) {
            if (!_this.paletteService) {
                return;
            }
            _this.setState({ focusedColor: _this.state.selectedColor || _this.paletteService.colorRows[0][0] });
            if (_this.props.onFocus) {
                _this.props.onFocus.call(undefined, nativeEvent);
            }
        };
        _this.onBlur = function () {
            _this.setState({ focusedColor: undefined });
        };
        validatePackage(packageMetadata);
        // The empty string for the `value` prop means no selection but controlled mode.
        _this.state = {
            selectedColor: _this.props.value !== undefined ? _this.props.value : _this.props.defaultValue,
            isFirstRender: true
        };
        return _this;
    }
    /**
     * @hidden
     */
    ColorPaletteWithoutContext.prototype.render = function () {
        var _this = this;
        var paletteInfo = this.getPaletteInfo();
        var svc = this.paletteService = new ColorPaletteService();
        svc.setColorMatrix(paletteInfo.colors, paletteInfo.columns);
        var selectedCellCoords = svc.getCellCoordsFor(this.state.selectedColor);
        var focusedCellCoords = svc.getCellCoordsFor(this.state.focusedColor);
        var className = classNames('k-colorpalette', { 'k-disabled': this.props.disabled });
        if (paletteInfo.colors.length) {
            return (React.createElement("div", { id: this.props.id, className: className, onFocus: this.onFocus, onBlur: this.onBlur, onKeyDown: this.onKeyDown, "aria-disabled": this.props.disabled ? 'true' : undefined, "aria-labelledby": this.props.ariaLabelledBy, "aria-describedby": this.props.ariaDescribedBy, tabIndex: getTabIndex(this.props.tabIndex, this.props.disabled), ref: function (el) { return _this.wrapper = el; } },
                React.createElement("div", { className: "k-colorpalette-table-wrap", role: "grid" },
                    React.createElement("table", { className: "k-colorpalette-table k-palette", role: "presentation" },
                        React.createElement("tbody", { role: "rowgroup" }, this.renderRows(svc.colorRows, selectedCellCoords, focusedCellCoords))))));
        }
        else {
            // In this way, the code prevents an eventual focus of the component when no cells are available.
            // This is needed because upon focus the first cell gets focused.
            return '';
        }
    };
    /**
     * @hidden
     */
    ColorPaletteWithoutContext.getDerivedStateFromProps = function (props, state) {
        if (!state.isFirstRender && props.value !== undefined) {
            // The component is in controlled mode.
            if (props.value === '' && state.selectedColor !== undefined) {
                // The selection has to be removed.
                return { selectedColor: undefined };
            }
            else if (props.value !== '' && props.value !== state.selectedColor) {
                return { selectedColor: props.value };
            }
        }
        else if (state.isFirstRender) {
            return { isFirstRender: false };
        }
        return null;
    };
    ColorPaletteWithoutContext.prototype.handleCellNavigation = function (horizontalStep, verticalStep) {
        if (!this.paletteService) {
            return;
        }
        if (this.focusedColorCooridanates) {
            var newCoords = this.paletteService.getNextCell(this.focusedColorCooridanates, horizontalStep, verticalStep);
            this.setState({ focusedColor: this.paletteService.getColorAt(newCoords) });
        }
        else {
            this.setState({ focusedColor: this.paletteService.colorRows[0][0] });
        }
    };
    ColorPaletteWithoutContext.prototype.handleEnter = function (event) {
        if (this.isUncontrolled) {
            this.setState({ selectedColor: this.state.focusedColor });
        }
        this.dispatchChangeEvent(this.state.focusedColor, event);
    };
    ColorPaletteWithoutContext.prototype.dispatchChangeEvent = function (value, event) {
        dispatchEvent(this.props.onChange, event, this, { value: value, rgbaValue: parseColor(value, 'rgba') });
    };
    Object.defineProperty(ColorPaletteWithoutContext.prototype, "focusedColorCooridanates", {
        get: function () {
            return this.state.focusedColor && this.paletteService ? this.paletteService.getCellCoordsFor(this.state.focusedColor) : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ColorPaletteWithoutContext.prototype, "isUncontrolled", {
        get: function () {
            return this.props.value === undefined;
        },
        enumerable: false,
        configurable: true
    });
    ColorPaletteWithoutContext.prototype.getPaletteInfo = function () {
        if (typeof this.props.palette === 'string') {
            var preset = PALETTEPRESETS[this.props.palette];
            if (isPresent(preset)) {
                return {
                    colors: preset.colors,
                    columns: this.props.columns || preset.columns || DEFAULT_COLUMNS_COUNT
                };
            }
            else {
                return { colors: [], columns: 0 };
            }
        }
        else {
            return { colors: this.props.palette || [], columns: this.props.columns || DEFAULT_COLUMNS_COUNT };
        }
    };
    ColorPaletteWithoutContext.prototype.renderRows = function (rows, selectedCellCoords, focusedCellCoords) {
        var _this = this;
        return rows.map(function (row, i) {
            return React.createElement("tr", { role: "row", key: i }, _this.renderColumns(row, i, selectedCellCoords, focusedCellCoords));
        });
    };
    ColorPaletteWithoutContext.prototype.renderColumns = function (columns, rowIndex, selectedCellCoords, focusedCellCoords) {
        var _this = this;
        var rowIsSelected = selectedCellCoords !== undefined && selectedCellCoords.row === rowIndex;
        var selectedColumn = selectedCellCoords && selectedCellCoords.col;
        var rowIsFocused = focusedCellCoords !== undefined && focusedCellCoords.row === rowIndex;
        var focusedColumn = focusedCellCoords && focusedCellCoords.col;
        var tileSize = typeof this.props.tileSize !== 'number' ?
            this.props.tileSize : { width: this.props.tileSize, height: this.props.tileSize };
        var width = tileSize.width + 'px';
        var height = tileSize.height + 'px';
        return columns.map(function (color, i) {
            var isSelected = rowIsSelected && selectedColumn === i;
            var className = classNames('k-colorpalette-tile', {
                'k-selected': isSelected,
                'k-focus': rowIsFocused && focusedColumn === i
            });
            return (React.createElement("td", { role: "gridcell", className: className, "aria-label": color, "aria-selected": isSelected ? true : _this.props.disabled ? undefined : false, style: { backgroundColor: color, width: width, height: height, minWidth: width }, onClick: function (event) { return _this.onColorClick(color, event); }, id: _this.createCellId({ row: rowIndex, col: i }), key: i }));
        });
    };
    ColorPaletteWithoutContext.prototype.createCellId = function (cellCoords) {
        return "".concat(this.guid, "_").concat(cellCoords.row, "_").concat(cellCoords.col);
    };
    ColorPaletteWithoutContext.displayName = 'ColorPalette';
    /**
     * @hidden
     */
    ColorPaletteWithoutContext.propTypes = {
        palette: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string.isRequired), PropTypes.string]),
        columns: PropTypes.number,
        tileSize: PropTypes.any,
        defaultValue: PropTypes.string,
        value: PropTypes.string,
        disabled: PropTypes.bool,
        tabIndex: PropTypes.number,
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        id: PropTypes.string,
        ariaLabelledBy: PropTypes.string,
        ariaDescribedBy: PropTypes.string
    };
    /**
     * @hidden
     */
    ColorPaletteWithoutContext.defaultProps = {
        palette: DEFAULT_PRESET,
        tileSize: DEFAULT_TILE_SIZE
    };
    return ColorPaletteWithoutContext;
}(React.Component));
export { ColorPaletteWithoutContext };
/**
 * Represents the PropsContext of the `ColorPalette` component.
 * Used for global configuration of all `ColorPalette` instances.
 *
 * For more information, refer to the [Inputs Props Context]({% slug props-context_inputs %}) article.
 */
export var ColorPalettePropsContext = createPropsContext();
/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the component the same as the type */
/**
 * Represents the [KendoReact ColorPalette component]({% slug overview_colorpalette %}).
 *
 * Accepts properties of type [ColorPaletteProps]({% slug api_inputs_colorpaletteprops %}).
 * Obtaining the `ref` returns an object of type [ColorPaletteHandle]({% slug api_inputs_colorpalettehandle %}).
 *
 * ```jsx
 * class App extends React.Component {
 *     render() {
 *         return <ColorPalette palette='basic' />;
 *     }
 * }
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var ColorPalette = withPropsContext(ColorPalettePropsContext, ColorPaletteWithoutContext);
ColorPalette.displayName = 'KendoReactColorPalette';
