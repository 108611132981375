"use strict";

import { TYPE_CODE } from "../../../../../server/common/generic/common_constants";
import React from "react";

export const RiskIcon = ({typeCode, color, width = 12, height = 12}) => {
  switch (typeCode) {
    case TYPE_CODE.PROCESS_PARAMETER:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12" fill="none">
          <path d="M5.13176 1.51943C5.51565 0.847611 6.48435 0.847611 6.86824 1.51943L11.1451 9.00386C11.526 9.67052 11.0446 10.5 10.2768 10.5H1.72318C0.955357 10.5 0.47399 9.67052 0.854937 9.00386L5.13176 1.51943Z" fill={color} />
        </svg>
      );
    case TYPE_CODE.MATERIAL_ATTRIBUTE:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12" fill="none">
          <path d="M6 1L4.06031 4.33024L0.293661 5.1459L2.86151 8.01976L2.47329 11.8541L6 10.3L9.52671 11.8541L9.13849 8.01976L11.7063 5.1459L7.93969 4.33024L6 1Z" fill={color} />
        </svg>
      );
    case TYPE_CODE.IQA:
    case TYPE_CODE.IPA:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12" fill="none">
          <path d="M5.5 11.7113C5.8094 11.89 6.1906 11.89 6.5 11.7113L10.6962 9.28868C11.0056 9.11004 11.1962 8.77992 11.1962 8.42265V3.57735C11.1962 3.22008 11.0056 2.88996 10.6962 2.71132L6.5 0.288675C6.1906 0.110043 5.8094 0.110043 5.5 0.288675L1.30385 2.71132C0.994447 2.88996 0.803848 3.22009 0.803848 3.57735V8.42265C0.803848 8.77992 0.994447 9.11004 1.30385 9.28868L5.5 11.7113Z" fill={color} />
        </svg>
      );
    case TYPE_CODE.FQA:
    case TYPE_CODE.FPA:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12" fill="none">
          <circle cx="6" cy="6" r="5" fill={color} />
        </svg>
      );
    default:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12" fill="none">
          <rect x="1" y="1" width="10" height="10" rx="1" fill={color} />
        </svg>
      );
  }
};