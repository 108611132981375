var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SignaturePad } from '@progress/kendo-inputs-common';
import { Button } from '@progress/kendo-react-buttons';
import { classNames, createPropsContext, dispatchEvent, getTabIndex, kendoThemeMaps, Keys, noop, usePropsContext } from '@progress/kendo-react-common';
import { validatePackage } from '@progress/kendo-react-common';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useLocalization } from '@progress/kendo-react-intl';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { messages, signatureClear, signatureMaximize, signatureMinimize } from '../messages';
import { packageMetadata } from '../package-metadata';
import { hasParent } from './utils';
var DEFAULT_WIDTH = 250;
var DEFAULT_HEIGHT = 84;
var DEFAULT_POPUP_SCALE = 3;
var DEFAULT_EXPORT_SCALE = 2;
var DEFAULT_COLOR = '#000000';
var DEFAULT_BACKGROUND_COLOR = '#ffffff';
/**
 * @hidden
 */
var isControlled = function (prop) {
    return prop !== undefined;
};
/**
 * Represents the PropsContext of the `Signature` component.
 * Used for global configuration of all `Signature` instances.
 *
 * For more information, refer to the [Inputs Props Context]({% slug props-context_inputs %}) article.
 */
export var SignaturePropsContext = createPropsContext();
/**
 * Represents the [KendoReact Signature component]({% slug overview_signature %}).
 */
export var Signature = React.forwardRef(function (directProps, target) {
    var _a;
    validatePackage(packageMetadata);
    var props = usePropsContext(SignaturePropsContext, directProps);
    var localizationService = useLocalization();
    var elementRef = React.useRef(null);
    var canvasRef = React.useRef(null);
    var dialogRef = React.useRef(null);
    var _b = React.useState(), instance = _b[0], setInstance = _b[1];
    var _c = React.useState(false), focused = _c[0], setFocused = _c[1];
    var _d = React.useState(false), isDrawing = _d[0], setIsDrawing = _d[1];
    var _e = React.useState(), stateValue = _e[0], setStateValue = _e[1];
    var _f = React.useState(), popupValue = _f[0], setPopupValue = _f[1];
    var isValueControlled = isControlled(props.value);
    var value = isValueControlled ? props.value : stateValue;
    var _g = React.useState(false), stateOpen = _g[0], setStateOpen = _g[1];
    var isOpenControlled = isControlled(props.open);
    var open = isOpenControlled ? props.open : stateOpen;
    var showMaximize = !(props.maximized || isDrawing || !props.maximizable || props.disabled);
    var showMinimize = props.maximized && !isDrawing;
    var isEmpty = !(props.value || stateValue);
    var showClear = !(isEmpty || isDrawing || props.readOnly || props.disabled);
    var maximizeTitle = localizationService.toLanguageString(signatureMaximize, messages[signatureMaximize]);
    var minimizeTitle = localizationService.toLanguageString(signatureMinimize, messages[signatureMinimize]);
    var clearTitle = localizationService.toLanguageString(signatureClear, messages[signatureClear]);
    var onValueChange = function (nextValue) {
        setStateValue(nextValue);
        if (props.onChange) {
            props.onChange({ value: nextValue });
        }
    };
    var onDialogChange = function (e) {
        instance === null || instance === void 0 ? void 0 : instance.loadImage(e.value);
        onValueChange(e.value);
    };
    React.useEffect(function () {
        if (props.value !== stateValue) {
            setStateValue(props.value);
            instance === null || instance === void 0 ? void 0 : instance.loadImage(props.value);
        }
    }, [props.value]);
    var reset = function () {
        instance === null || instance === void 0 ? void 0 : instance.clear();
        onValueChange();
    };
    var setOpen = React.useCallback(function (nextOpen) {
        if (!isOpenControlled) {
            setStateOpen(nextOpen);
        }
    }, [isOpenControlled]);
    var onDialogClose = function (event) {
        onMinimizeClick(event);
        focusElement();
    };
    var colorGetter = React.useCallback(function () {
        var defaultColor = DEFAULT_COLOR;
        if (!props.color && typeof document !== 'undefined' && elementRef.current) {
            defaultColor = getComputedStyle(elementRef.current).color;
        }
        return props.color || defaultColor;
    }, [props.color]);
    var backgroundColorGetter = React.useCallback(function () {
        var defaultBackgroundColor = DEFAULT_BACKGROUND_COLOR;
        if (!props.backgroundColor && typeof document !== 'undefined' && elementRef.current) {
            defaultBackgroundColor = getComputedStyle(elementRef.current).backgroundColor;
        }
        return props.backgroundColor || defaultBackgroundColor;
    }, [props.backgroundColor]);
    var getOptions = function () {
        return {
            scale: props.maximized ? props.popupScale : 1,
            color: colorGetter(),
            backgroundColor: backgroundColorGetter(),
            strokeWidth: props.strokeWidth,
            smooth: props.smooth,
            readonly: props.readOnly
        };
    };
    var exportImage = function (exportSize) { return __awaiter(void 0, void 0, void 0, function () {
        var width, height;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    width = exportSize.width, height = exportSize.height;
                    return [4 /*yield*/, (instance === null || instance === void 0 ? void 0 : instance.exportImage({
                            width: width * (props.exportScale || DEFAULT_EXPORT_SCALE),
                            height: height * (props.exportScale || DEFAULT_EXPORT_SCALE)
                        }))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    React.useEffect(function () {
        var canvas = canvasRef.current;
        var pad = new SignaturePad(canvas, getOptions());
        if (value) {
            pad.loadImage(value);
        }
        setInstance(pad);
        return function () { return pad.destroy(); };
    }, []);
    React.useEffect(function () {
        instance === null || instance === void 0 ? void 0 : instance.setOptions({
            onChange: function () { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = onValueChange;
                        return [4 /*yield*/, exportImage(elementSize())];
                    case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
                }
            }); }); },
            onDraw: function () { return setIsDrawing(true); },
            onDrawEnd: function () { return setIsDrawing(false); }
        });
    }, [instance]);
    React.useLayoutEffect(function () { return instance === null || instance === void 0 ? void 0 : instance.setOptions(getOptions()); }, [props.readOnly, props.color, props.backgroundColor, props.strokeWidth, props.smooth]);
    React.useEffect(function () {
        var _a, _b;
        var overlay = (_b = (_a = dialogRef.current) === null || _a === void 0 ? void 0 : _a.element) === null || _b === void 0 ? void 0 : _b.querySelector('.k-overlay');
        if (!overlay) {
            return;
        }
        var onOverlayClick = function () { return setOpen(false); };
        overlay.addEventListener('click', onOverlayClick);
        return function () { return overlay.removeEventListener('click', onOverlayClick); };
    }, [open]);
    React.useEffect(function () {
        if (!open || typeof document === 'undefined') {
            return;
        }
        var onDocumentKeydown = function (e) {
            if (e.keyCode === Keys.esc) {
                setOpen(false);
            }
        };
        document.addEventListener('keypress', onDocumentKeydown);
        return function () { return document.removeEventListener('keypress', onDocumentKeydown); };
    }, [open]);
    var focusElement = React.useCallback(function () { var _a; return (_a = canvasRef.current) === null || _a === void 0 ? void 0 : _a.focus(); }, []);
    var valueGetter = React.useCallback(function () { return props.value; }, [props.value]);
    var nameGetter = React.useCallback(function () { return props.name; }, [props.name]);
    var requiredGetter = React.useCallback(function () { return props.required; }, [props.required]);
    var validityGetter = React.useCallback(function () {
        var customError = props.validationMessage !== undefined;
        var currentValue = valueGetter();
        var valueMissing = !currentValue;
        var valid = props.valid !== undefined ?
            props.valid :
            (!requiredGetter() || !valueMissing);
        return {
            customError: customError,
            valid: valid,
            valueMissing: valueMissing
        };
    }, [props.validationMessage, props.valid, valueGetter, requiredGetter]);
    var validityStylesGetter = React.useCallback(function () {
        return props.validityStyles;
    }, [props.validityStyles]);
    var propsGetter = React.useCallback(function () {
        return props;
    }, [props]);
    var getImperativeHandle = React.useCallback(function () {
        var options = {
            element: elementRef.current,
            focus: focusElement
        };
        Object.defineProperty(options, 'name', { get: nameGetter });
        Object.defineProperty(options, 'value', { get: valueGetter });
        Object.defineProperty(options, 'validity', { get: validityGetter });
        Object.defineProperty(options, 'validityStyles', { get: validityStylesGetter });
        Object.defineProperty(options, 'required', { get: requiredGetter });
        Object.defineProperty(options, 'props', { get: propsGetter });
        Object.defineProperty(options, 'color', { get: colorGetter });
        Object.defineProperty(options, 'backgroundColor', { get: backgroundColorGetter });
        return options;
    }, [nameGetter, valueGetter, validityGetter, validityStylesGetter, requiredGetter, focusElement, propsGetter, colorGetter, backgroundColorGetter]);
    React.useImperativeHandle(target, getImperativeHandle);
    var onFocus = React.useCallback(function (event) {
        if (focused || props.maximized) {
            return;
        }
        setFocused(true);
        dispatchEvent(props.onFocus, event, getImperativeHandle(), {});
    }, [focused, props.onFocus, getImperativeHandle]);
    var onBlur = React.useCallback(function (event) {
        var insideWrapper = hasParent(event.relatedTarget, elementRef.current);
        if (insideWrapper) {
            return;
        }
        setFocused(false);
        dispatchEvent(props.onBlur, event, getImperativeHandle(), {});
    }, [focused, props.onBlur, getImperativeHandle]);
    var onMaximizeClick = React.useCallback(function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = setPopupValue;
                    return [4 /*yield*/, exportImage(popupSize())];
                case 1:
                    _a.apply(void 0, [_b.sent()]);
                    setOpen(true);
                    dispatchEvent(props.onOpen, event, getImperativeHandle(), {});
                    return [2 /*return*/];
            }
        });
    }); }, [open, isOpenControlled, props.onOpen, props.value, stateValue, getImperativeHandle]);
    var onMinimizeClick = React.useCallback(function (event) {
        setOpen(false);
        dispatchEvent(props.onClose, event, getImperativeHandle(), {});
    }, [open, isOpenControlled, props.onClose, getImperativeHandle]);
    var onClear = function () {
        reset();
        focusElement();
    };
    var elementSize = function () {
        var _a, _b;
        var width = props.width || ((_a = elementRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) || DEFAULT_WIDTH;
        var height = props.height || ((_b = elementRef.current) === null || _b === void 0 ? void 0 : _b.offsetHeight) || DEFAULT_HEIGHT;
        return {
            width: width,
            height: height
        };
    };
    var popupSize = function () {
        var _a = elementSize(), width = _a.width, height = _a.height;
        var popupScale = props.popupScale || DEFAULT_POPUP_SCALE;
        return {
            width: width * popupScale,
            height: height * popupScale
        };
    };
    var isValid = !validityStylesGetter() || validityGetter().valid;
    return (React.createElement("div", { ref: elementRef, dir: props.dir, style: __assign({ width: props.width, height: props.height }, props.style), className: classNames('k-input', 'k-signature', (_a = {
                'k-signature-maximized': props.maximized
            },
            _a["k-signature-".concat(kendoThemeMaps.sizeMap[props.size] || props.size)] = props.size,
            _a["k-input-".concat(props.fillMode)] = props.fillMode,
            _a["k-rounded-".concat(kendoThemeMaps.roundedMap[props.rounded] || props.rounded)] = props.rounded,
            _a['k-invalid'] = !isValid,
            _a['k-required'] = props.required,
            _a['k-disabled'] = props.disabled,
            _a['k-focus'] = focused,
            _a), props.className), onFocus: onFocus, onBlur: onBlur },
        React.createElement("div", { className: 'k-signature-actions k-signature-actions-top' },
            showMaximize && (React.createElement(Button, { className: 'k-signature-action k-signature-maximize', icon: 'hyperlink-open', shape: null, fillMode: 'flat', size: props.size, onClick: onMaximizeClick, "aria-label": maximizeTitle, title: maximizeTitle })),
            showMinimize && (React.createElement(Button, { className: 'k-signature-action k-signature-minimize k-rotate-180', icon: 'hyperlink-open', shape: null, fillMode: 'flat', size: props.size, onClick: onMinimizeClick, "aria-label": minimizeTitle, title: minimizeTitle }))),
        React.createElement("div", { className: 'k-signature-canvas', ref: canvasRef, tabIndex: getTabIndex(props.tabIndex, props.disabled), role: "img", id: props.id, "aria-label": props.ariaLabel, "aria-labelledby": props.ariaLabelledBy, "aria-describedby": props.ariaDescribedBy, "aria-disabled": props.disabled ? 'true' : undefined }),
        !props.hideLine && (React.createElement("div", { className: 'k-signature-line', style: { zIndex: 2, pointerEvents: 'none' } })),
        React.createElement("div", { className: 'k-signature-actions k-signature-actions-bottom' }, showClear && (React.createElement(Button, { className: 'k-signature-action k-signature-clear', icon: 'close', shape: null, fillMode: 'flat', size: props.size, onClick: onClear, "aria-label": clearTitle, title: clearTitle }))),
        open && (React.createElement(Dialog, { ref: dialogRef },
            React.createElement(Signature, __assign({}, props, popupSize(), { value: popupValue, maximized: true, open: false, onChange: onDialogChange, onClose: onDialogClose }))))));
});
Signature.propTypes = {
    value: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    tabIndex: PropTypes.number,
    dir: PropTypes.string,
    ariaDescribedBy: PropTypes.string,
    ariaLabelledBy: PropTypes.string,
    ariaLabel: PropTypes.string,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
    validationMessage: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    size: PropTypes.oneOf([null, 'small', 'medium', 'large']),
    rounded: PropTypes.oneOf([null, 'small', 'medium', 'large']),
    fillMode: PropTypes.oneOf([null, 'solid', 'flat', 'outline'])
};
Signature.displayName = 'KendoSignature';
Signature.defaultProps = {
    strokeWidth: 1,
    smooth: false,
    popupScale: DEFAULT_POPUP_SCALE,
    exportScale: DEFAULT_EXPORT_SCALE,
    maximizable: true,
    disabled: false,
    required: false,
    validityStyles: true,
    onChange: function (_) { return noop; },
    onFocus: function (_) { return noop; },
    onBlur: function (_) { return noop; },
    onOpen: function (_) { return noop; },
    onClose: function (_) { return noop; },
    size: 'medium',
    rounded: 'medium',
    fillMode: 'solid'
};
