"use strict";

import React from "react";
import { FROM_LIBRARY_STATUS } from "../library_constants";
import BaseAttribute from "../../editor/attributes/base_attribute";
import { EDITOR_OPERATIONS } from "../../editor/editor_constants";
import CommonUtils from "../../../server/common/generic/common_utils";

/**
 * Clone each attribute to force adding EDITOR_OPERATIONS.VIEW editor operation as a property to get the benefit of styling attribute in view mode.
 * @param children {DetailedReactHTMLElement}
 * @returns {JSXElement}
 */
const recursivelyUpdateAllChildrenWidgets = children => {
  return React.Children.map(children, child => {
    let childProps = {};
    if (child.type && child.type.prototype instanceof BaseAttribute) {
      const {parent} = child.props;
      childProps = {
        editorOperation: EDITOR_OPERATIONS.VIEW,
        value: (child.props.value) ? child.props.value : "-",
      };

      /**
       * We should not show the "-" from the previous step as a new change in the field,
       * so if the user is showing history changes, we fall back to the original field
       * value if it is empty.
       */
      if (parent.isView() && parent.isDiffingVersions()) {
        childProps = {...childProps, value: child.props.value};
      }

    }

    childProps.children = recursivelyUpdateAllChildrenWidgets(child.props.children);
    return React.cloneElement(child, childProps);
  });
};

/**
 * The container holding the common fields from the library.
 * @param section
 * @param fromLibraryStatus
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const LibraryFieldsContainer = ({section, fromLibraryStatus, children}) => {
  const className = fromLibraryStatus === FROM_LIBRARY_STATUS.SYNCED ? "synced-attributes" : "";

  const skipRenderUpdatedChildren =
    !fromLibraryStatus ||
    fromLibraryStatus === FROM_LIBRARY_STATUS.PROJECT_ONLY ||
    fromLibraryStatus === FROM_LIBRARY_STATUS.LINKED;

  return (
    <div id={`${section}${CommonUtils.stripAllWhitespaces(fromLibraryStatus)}WrapperDiv`} className={className}>
      {
        skipRenderUpdatedChildren ?
          children :
          recursivelyUpdateAllChildrenWidgets(children)
      }
    </div>
  );
};