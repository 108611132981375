"use strict";

import { BaseDataTransform } from "./base_data_transform";
import { NoOpDataTransform } from "./no_op_data_transform";

/**
 * @typedef IDataTransformFactoryOptions {Object}
 * @property reportType {string}
 * @property subReportType {string}
 */

/**
 * @typedef IReportConfig {Object}
 * @property dataTransform {Function[]}
 */

/**
 * @typedef ITransformCreationOptions {Object}
 * @property type {Function}
 * @property factory {DataTransformFactory}
 * @property reportType {string?}
 * @property children {ITransformOptions[]?}
 */

/**
 * Implements the logic to create new instances of classes derived from {BaseDataTransform}
 */
export class DataTransformFactory {
  /**
   * Creates a new instance of the {DataTransformFactory} class
   * @param config {*} The reports configuration
   */
  constructor(config) {
    if (!config) {
      throw new TypeError("Parameter 'config' must not be falsy.");
    }

    /**
     * Contains the configuration for all reports (REPORTS_OPTIONS_ENUM)
     * @type {*}
     * @protected
     */
    this.config = config;
    /**
     * Holds an instance of a transform that will do nothing (Null-Object pattern).
     * Since it does nothing, we can create it only once per factory lifetime.
     * @see https://sourcemaking.com/design_patterns/null_object
     * @type {BaseDataTransform}
     */
    this.NO_OP = this.createTransform({type: NoOpDataTransform});


    // binds methods
    this.create = this.create.bind(this);
    this.getTransformOptions = this.getTransformOptions.bind(this);
    this.createTransform = this.createTransform.bind(this);
    this.getReportConfig = this.getReportConfig.bind(this);
  }

  /**
   * Creates a new instance of a type derived from {BaseDataTransform}
   * @param options {IDataTransformFactoryOptions}
   * @returns {BaseDataTransform} A proper instance of a type derived of {BaseDataTransform} or, if none found, an instance of {NoOpDataTransform} (that returns the object as is).
   */
  create(options) {
    const {reportType} = options;

    if (!reportType) {
      return this.NO_OP;
    }

    const dataTransform = this.getTransformOptions(reportType);
    return this.createTransform(dataTransform);
  }

  /**
   * Creates a new transform for the specified type.
   * @param options {ITransformCreationOptions} The reference to the data transform class
   * @returns {BaseDataTransform}
   */
  createTransform(options) {
    const {type, reportType} = options;
    if (!type) {
      throw new TypeError(`The data transform type specified in ${reportType || "the"} options must not be null.`);
    }

    return new type(this.config, options);
  }

  /**
   * Returns the current configuration for the specified report type
   * @param reportType {string}
   * @private
   */
  getReportConfig(reportType) {
    /**
     * @type IReportConfig
     */
    const reportConfig = this.config[reportType];

    if (!reportConfig) {
      throw new TypeError(`Unable to find a report configuration for report type "${reportType}" in REPORT_OPTIONS_ENUM.`);
    }
    return reportConfig;
  }

  /**
   * Gets the transform configuration for the specified report type
   * @param reportType {string}
   * @returns {ITransformCreationOptions}
   * @private
   */
  getTransformOptions(reportType) {
    const reportConfig = this.getReportConfig(reportType);

    // if no data transform is specified, we use the legacy one (used to make transition smoother)
    const transformConfig = reportConfig.dataTransform || this.NO_OP;
    return {
      ...(transformConfig),
      reportType,
      factory: this,
    };
  }
}
