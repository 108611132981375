"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseQuickPage from "../../base_quick_page";
import FQAListTable from "../lists/fqa_list";
import FPAListTable from "../lists/fpa_list";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import ReferenceDocuments from "@cherrycircle/fda-docs";
import GeneralAttributeListTable from "../lists/general_attributes_list";
import TableCollapseCard from "../../widgets/tables/table_collapse_card";
import ErrorBar from "../../widgets/bars/error_bar";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";

/* This shows the QTPP tab in the product page. */

// i18next-extract-mark-ns-start qtpp
class QTPP extends BaseQuickPage {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
    const {t} = this.props;
    document.title = t("QbDVision QTPP");

    this.instances = [];
    this.projectId = UIUtils.parseInt(UIUtils.getParameterByName("projectId"));
    new TypeaheadObjectCache("Project").loadOptions(projects => this.handleProjectTypeaheadResultsFromServer(projects));


    this.setStateSafely({
      fqasCount: null,
      fpasCount: null,
      generalAttributesCount: null,
    });

    this.childRefs = [];

    super.componentDidMount();
  }

  addRef(someRef) {
    this.childRefs.push(someRef);
  }

  handleFQAsDataReceived(FQAs) {
    this.props.onUpdateRefs(this.tableRefs);
    this.handleQuickPanelInstanceReceived(FQAs);
    let fqasCount = FQAs ? FQAs.instances.length : 0;
    this.setStateSafely({fqasCount});
    this.props.onUpdateRecordCount(fqasCount + (this.state.fpasCount || 0) + (this.state.generalAttributesCount || 0));
  }

  handleFPAsDataReceived(FPAs) {
    this.props.onUpdateRefs(this.tableRefs);
    this.handleQuickPanelInstanceReceived(FPAs);
    let fpasCount = FPAs ? FPAs.instances.length : 0;
    this.setStateSafely({fpasCount});
    this.props.onUpdateRecordCount(fpasCount + (this.state.fqasCount || 0) + (this.state.generalAttributesCount || 0));
  }

  handleGeneralAttributesDataReceived(generalAttributes) {
    this.handleQuickPanelInstanceReceived(generalAttributes);
    let generalAttributesCount = generalAttributes ? generalAttributes.instances.length : 0;
    this.setStateSafely({generalAttributesCount});
    this.props.onUpdateRecordCount(generalAttributesCount + (this.state.fqasCount || 0) + (this.state.fpasCount || 0));
  }

  collapseAllSections(quickPanelInfo) {
    return quickPanelInfo ? quickPanelInfo.typeCode === "FQA" || quickPanelInfo.typeCode === "FPA" :
      super.collapseAllSections();
  }

  renderPage() {
    const {t, globalExpandStatus, onLocalExpandStatusChange, searchTerm} = this.props;
    const {fqasCount, fpasCount, generalAttributesCount} = this.state;
    const commonTableProperties = {
      parent: this,
      className: "list-table-not-borders",
      allowRecordCopy: true,
      showAsOfDate: true,
      projectId: this.projectId,
      hideQuickPanelUI: true,
      hideShowArchivedButton: true,
      hideShowTableInfo: true,
      onQuickEditRecord: this.handleQuickEditRecord,
      searchTerm,
    };

    return (
      <div className="ml-5 mb-5 mr-5">
        <ErrorBar className="product-error-bar" />
        <TableCollapseCard
          title={
            <span>
              {t("General Attributes")} (<span id="generalAttributesCount">{generalAttributesCount}</span>)
            </span>
          }
          infoTooltip={<InfoTooltip id="infoGeneralAttribute"
                                    verbiage={
                                      <div>
                                        {t("A General Attribute is a general characteristic of a drug product or drug substance such as intended use in clinical setting, route of administration, dosage form, dosage strength(s). etc.")}
                                      </div>
                                    }
          />}
          name="GeneralAttributeListTable"
          modelName={t("General Attribute")}
          globalExpandStatus={globalExpandStatus}
          onLocalExpandStatusChange={onLocalExpandStatusChange}
          rowsCount={generalAttributesCount}
          body={<GeneralAttributeListTable
            id="generalAttributeResultsTable"
            ref={ref => this.addTableRef(ref)}
            onDataReceived={this.handleGeneralAttributesDataReceived}
            {...commonTableProperties} />}
        />
        <TableCollapseCard
          title={
            <span>
              {t("Quality Attributes")} (<span id="qualityAttributesCount">{fqasCount}</span>)
            </span>
          }
          infoTooltip={
            <InfoTooltip id="infoFQA"
                         fdaGuidanceURL={ReferenceDocuments.Q8_GUIDELINE}
                         fdaGuidancePage={16}
                         fdaGuidanceOffset={200}
                         verbiage={
                           <div>
                             {t("A physical, chemical, biological, or microbiological property or characteristic that should be within an appropriate limit range, or distribution to ensure the desired product quality.")}
                           </div>
                         }
                         explanation={
                           <div>
                             {t("These are things about the final product that should be measured to make sure they're correct.")}
                           </div>
                         }
            />}
          name="FQAListTable"
          rowsCount={fqasCount}
          modelName={t("Quality Attribute")}
          onLocalExpandStatusChange={onLocalExpandStatusChange}
          globalExpandStatus={globalExpandStatus}
          body={<FQAListTable ref={ref => this.addTableRef(ref)}
                              id="qualityAttributeResultsTable"
                              className="list-table-not-borders"
                              onDataReceived={this.handleFQAsDataReceived}
                              {...commonTableProperties} />}
        />

        <TableCollapseCard
          title={
            <span>
              {t("Performance Attributes")} (<span id="performanceAttributesCount">{fpasCount}</span>)
            </span>
          }
          infoTooltip={
            <InfoTooltip id="infoFPA"
                         verbiage={
                           <div>
                             {t("A property or measure of the final product that is not considered a quality attribute but is indicative of the overall performance of the process relative to the batch or release. Examples include yield, total cell density (TCD), viable cell density (VCD), viability, or productivity.")}
                           </div>
                         }
                         explanation={
                           <div>
                             {t("These are things about the final product that should be measured to make sure they're correct.")}
                           </div>
                         }
            />}
          name="FPAListTable"
          rowsCount={fpasCount}
          modelName={t("Performance Attribute")}
          onLocalExpandStatusChange={onLocalExpandStatusChange}
          globalExpandStatus={globalExpandStatus}
          body={<FPAListTable ref={ref => this.addTableRef(ref)}
                              id="performanceAttributeResultsTable"
                              className="list-table-not-borders"
                              onDataReceived={this.handleFPAsDataReceived}
                              {...commonTableProperties} />}
        />
      </div>
    );
  }

  render() {
    return (
      <div id="bodyDiv">
        {this.renderQuickPanel()}
        {this.renderPage()}
      </div>
    );
  }
}

export default I18NWrapper.wrap(QTPP, "qtpp");
// i18next-extract-mark-ns-stop qtpp
