var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * @hidden
 */
var update = function (arr, idx, value) { return (__spreadArray(__spreadArray([], arr.slice(0, idx + 1), true), (arr.slice(idx + 1).map(function (x) { return x + value; })), true)); };
/**
 * @hidden
 */
var RowHeightService = /** @class */ (function () {
    function RowHeightService(total, rowHeight, detailRowHeight) {
        if (total === void 0) { total = 0; }
        this.total = total;
        this.rowHeight = rowHeight;
        this.detailRowHeight = detailRowHeight;
        this.offsets = [];
        this.heights = [];
        var agg = 0;
        for (var idx = 0; idx < total; idx++) {
            this.offsets.push(agg);
            agg += rowHeight;
            this.heights.push(rowHeight);
        }
    }
    RowHeightService.prototype.height = function (rowIndex) {
        return this.heights[rowIndex];
    };
    RowHeightService.prototype.expandDetail = function (rowIndex) {
        if (this.height(rowIndex) === this.rowHeight) {
            this.updateRowHeight(rowIndex, this.detailRowHeight);
        }
    };
    RowHeightService.prototype.collapseDetail = function (rowIndex) {
        if (this.height(rowIndex) > this.rowHeight) {
            this.updateRowHeight(rowIndex, this.detailRowHeight * -1);
        }
    };
    RowHeightService.prototype.index = function (position) {
        for (var i = 0; i < this.offsets.length; i++) {
            if (position === this.offsets[i]) {
                return i;
            }
            if (position < this.offsets[i]) {
                return i - 1;
            }
        }
        return this.total - 1;
    };
    RowHeightService.prototype.offset = function (rowIndex) {
        return this.offsets[rowIndex];
    };
    RowHeightService.prototype.totalHeight = function () {
        return this.heights.reduce(function (prev, curr) { return prev + curr; }, 0);
    };
    RowHeightService.prototype.updateRowHeight = function (rowIndex, value) {
        this.heights[rowIndex] += value;
        this.offsets = update(this.offsets, rowIndex, value);
    };
    return RowHeightService;
}());
export { RowHeightService };
