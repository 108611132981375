"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { can, generateTooltip } from "../../utils/ui_permissions";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import CommonSecurity from "../../../server/common/generic/common_security";

import TableButton from "../../widgets/tables/table_button";
import TableOptionsButton from "../../widgets/tables/table_options_button";
import { ACTION_TO_ICON_ENUM } from "../../helpers/constants/constants";
import { DATATABLES_DOM } from "../../widgets/tables/base_table";
import BaseReactComponent from "../../base_react_component";
import { RouterContext } from "../../utils/router_context";

/* This shows the table of users. */

// i18next-extract-mark-ns-start users
class UserListTable extends BaseReactComponent {
  static contextType = RouterContext;
  constructor(props) {
    super(props);
    const {t} = props;

    this.columns = [
      {
        title: t("Name"),
        width: 150,
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            <div>
              <a href={this.getURLPrefix() + "viewEdit.html?operation=View&id=" + result.id}>
                {result.firstName + " " + result.lastName}
              </a>
              <br />
              {result.title ? result.title : ""}
            </div>
          );
        }
      },
      {
        title: t("Department"),
        width: 100,
        data: (result) => {
          return result.department ? t(result.department) : "";
        }
      },
      {
        title: t("Role"),
        width: 70,
        data: (result) => {
          return t(result.role);
        }
      },
      {
        title: t("Manage"),
        width: 100,
        data: result => result,
      },
      {
        title: "ID",
        width: 50,
        data: result => result.id,
      },
    ];
  }

  getURLPrefix() {
    return this.props.showAPIKeys ? "apiKeys/" : "";
  }

  componentDidMount() {
    super.componentDidMount();

    this.id = this.context.getParameterByName("id");

    const {t, i18n} = this.props;

    $(this.tableRef).DataTable({
      dom: DATATABLES_DOM,
      data: this.props.users,
      columns: this.columns,
      language: {
        url: UIUtils.getLanguageUrlForDataTable(i18n.language)
      },
      columnDefs: [
        {
          targets: [1, 2],
          createdCell: (td, cellData) => ReactDOM.render(cellData, td)
        },
        {
          targets: 3,
          orderable: false,
          // This is separate from the columns above because the onClick events won't be included using ReactDOMServer
          createdCell: (td, userToRender, result, row, ignored2) => {
            let options = [
              {
                action: ACTION_TO_ICON_ENUM.VIEW,
                id: "viewButton_" + row,
                title: t("View"),
                url: this.getURLPrefix() + "viewEdit.html?operation=View&id=" + userToRender.id,
              },
              {
                action: ACTION_TO_ICON_ENUM.EDIT,
                id: "editButton_" + row,
                title: t(generateTooltip(CommonSecurity.Actions.EDIT, CommonSecurity.Types.USER, userToRender)),
                disabled: !can(CommonSecurity.Actions.EDIT, CommonSecurity.Types.USER, userToRender),
                url: this.getURLPrefix() + "viewEdit.html?operation=Edit&id=" + userToRender.id,
              },
              {
                action: ACTION_TO_ICON_ENUM.DELETE,
                id: "deleteButton_" + row,
                title: UIUtils.isCurrentUser(userToRender.cognitoUUID) ? t("You cannot delete yourself")
                  : t(generateTooltip(CommonSecurity.Actions.DELETE, CommonSecurity.Types.USER, userToRender)),
                disabled: !can(CommonSecurity.Actions.DELETE, CommonSecurity.Types.USER, userToRender),
                onClick: this.props.onShowDelete.bind(this, result)
              }
            ];
            return ReactDOM.render(
              (
                <div className="table-manage">
                  <TableOptionsButton options={options} />
                  <TableButton href={this.getURLPrefix() + "viewEdit.html?operation=View&id=" + userToRender.id}
                               className="table-icon"
                               title={t("View")}
                               id={"viewButton" + row}
                               fontAwesomeIcon={faChevronRight}
                  />
                </div>
              ), td);
          },
        },
        {
          targets: 4,
          visible: false
        }
      ],
      stateSave: true,
      lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, t("All")]],
    });
  }

  componentDidUpdate() {
    this.reloadDataTable();
  }

  reloadDataTable() {
    if (this.tableRef && this.props.users) {
      const table = $(this.tableRef)
        .DataTable();
      table.clear();
      table.rows.add(this.props.users);
      table.draw();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    $(this.tableRef)
      .DataTable()
      .destroy(true);
  }

  getAdditiveSkeletonClass() {
    return "skeleton-table";
  }

  render() {
    return (
      <div>
        <table ref={ref => this.tableRef = ref}
               className={"table table-bordered table-hover" + this.getClassForLoading()}
               id="resultsTable"
               style={{width: "100%"}}
        />
      </div>
    );
  }
}

export default I18NWrapper.wrap(UserListTable, "users");
// i18next-extract-mark-ns-stop users
