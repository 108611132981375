"use strict";

import React from "react";
import * as UIUtils from "../../ui_utils";
import BaseReactComponent from "../../base_react_component";
import ImplementationNeededError from "../../utils/implementation_needed_error";
import ReactDOMServer from "react-dom/server";

export default class ConfigurableTablesTooltipBase extends BaseReactComponent {
  componentDidMount() {
    super.componentDidMount();

    $(this.popover).popover({
      sanitizeFn: UIUtils.sanitizePopoverData,
    });

    $(this.popover).on("inserted.bs.popover", () => {
      this.show();
    });
  }

  show() {
    throw new ImplementationNeededError();
  }

  renderBody() {
    throw new ImplementationNeededError();
  }

  wrapWithinAnchor() {
    return true;
  }

  tooltipBody() {
    return <span id={"changeColumnTooltip"}
                 data-toggle="popover"
                 data-html="true"
                 data-container="body"
                 data-placement={"auto"}
                 data-trigger="click hover"
                 data-template={ReactDOMServer.renderToString(
                   <div className="popover" role="tooltip">
                     <div className="arrow" />
                     <h3 className="popover-header" />
                     <div className="popover-body p-0" />
                   </div>
                 )}
                 ref={popover => this.popover = popover}
                 data-content={this.renderPopupContent()}
                 data-boundary="viewport"
    >
      {this.renderBody()}
    </span>;
  }

  render() {
    return (this.wrapWithinAnchor() ? <a>{this.tooltipBody()}</a> : this.tooltipBody());
  }
}
