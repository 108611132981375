"use strict";

import React, {Fragment} from "react";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";

import TrainingContentsAttribute from "../widgets/training_contents_attribute";
import DocumentsInTrainingTable from "../tables/documents_in_training_table";
import CurriculaInTrainingTable from "../tables/curricula_in_training_table";
import BaseReactComponent from "../../base_react_component";
import RetrainingDialog from "../popups/retraining_popup";
import { TrainingService } from "../services/training_service";
import * as UIUtils from "../../ui_utils";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";

const Logger = Log.group(LOG_GROUP.Training, "TrainingEditorAttribute");

/**
 * @typedef ITrainingEditorTypeDeclaration Contains a set of parameters used when creating a Training Editor.
 * @property typeName {string} The name sued to identify this training editor type.
 * @property modelName {string} The name of the model that is edited when this type is active.
 */

/**
 * @enum {ITrainingEditorTypeDeclaration}
 */
export const TRAINING_EDITOR_TYPE = {
  CURRICULUM: {
    typeName: "Curriculum",
    modelName: "Curriculum",
    linkToObject: ["Curriculum"],
    linkToObjectId: ["CurriculumId"],
    linkObject: ["CurriculumAssignment"],
    className: "col-sm-12",
    showDraftAssignments: true,
  },
  DEPARTMENT_CURRICULUM: {
    typeName: "DepartmentCurriculum",
    modelName: "Curriculum",
    linkToObject: ["Curriculum"],
    linkToObjectId: ["id"],
    linkObject: ["CurriculaFromDepartmentInstance"],
    readOnly: true,
    className: "col-sm-12",
    showDraftAssignments: false,
  },
  DOCUMENT: {
    typeName: "Document",
    modelName: "Document",
    linkToObject: ["Document"],
    linkToObjectId: ["id"],
    linkObject: ["Document"],
    isSimpleManyToMany: true,
    className: "col-sm-12",
    showDraftAssignments: true,
  },
};

export default class TrainingEditorAttribute extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.activeComponent = React.createRef();
    this.trainingService = new TrainingService();
  }

  handleActionClick(action, rowData) {
    if (action === "Retrain") {
      this.setStateSafely({
        showRetrainingDialog: true,
        retrainingRecord: rowData,
      });
    }
  }

  handleRetrainingDialogConfirm(event){
    const {onRetrainedSuccess} = this.props;
    const {user, record, justification} = event;
    UIUtils.clearError();

    const label = UIUtils.getRecordCustomLabelForDisplay(record);
    const payload = {userId: user.id, documentVersionId: record.versionId, justification};
    const url = getURLByTypeCodeAndId(record.typeCode, "View", record.id, true);

    UIUtils.setLoadingDisabled(false);
    UIUtils.showLoadingImage();
    this.trainingService.assignRetraining(payload, {useTwoWayCommunication: false})
      .then(() => {
        const message = (
          <div>Document <a href={url} target="_blank" rel="noopener noreferrer">{label}</a> successfully reassigned for training.</div>
        );
        UIUtils.showSuccess(message, null, true);
        if (this.props.onRetrainedSuccess) {
          onRetrainedSuccess(event);
        }
      })
      .catch(UIUtils.defaultFailFunction);
  }

  handleRetrainingDialogHide() {
    this.setStateSafely({
      showRetrainingDialog: false,
      retrainingRecord: null,
    });
  }

  render() {
    /**
     * Chooses the component to be rendered based on the props.
     */
    const {type, isDiffingVersions, isView, readOnly, hideDetails, user} = this.props;
    const {showRetrainingDialog, retrainingRecord} = this.state;
    const modelName = type.modelName;

    let ComponentType = TrainingContentsAttribute;
    let typeSpecificProps = {};

    /**
     * If it's in view mode and not diffing, we try to select a special viewer that shows information
     * about the documents.
     */
    if (!hideDetails && (readOnly || (isView && !isDiffingVersions))) {
      switch (modelName) {
        case "Document":
          ComponentType = DocumentsInTrainingTable;
          break;
        case "Curriculum":
          ComponentType = CurriculaInTrainingTable;
          break;
        default:
          Logger.warn("No special viewer found for type: ", Log.object(modelName));
      }
      typeSpecificProps = {
        onActionClick: this.handleActionClick,
      };
    }

    return (
      <Fragment>
        <ComponentType {...type} {...this.props} {...typeSpecificProps} ref={this.activeComponent} />
        <RetrainingDialog
          visible={showRetrainingDialog}
          record={retrainingRecord}
          user={user}
          onConfirm={this.handleRetrainingDialogConfirm}
          onHide={this.handleRetrainingDialogHide}
        />
      </Fragment>
    );
  }
}

TrainingEditorAttribute.defaultProps = {
  onRetrainedSuccess: (event) => {
    Logger.warn("onRetrainedSuccess not implemented", Log.object(event));
  }
};
