import { RemoveMarkStep } from 'prosemirror-transform';
import { MarkType } from 'prosemirror-model';
export var markApplies = function (doc, ranges, type) {
    var loop = function (i) {
        var ref = ranges[i];
        var $from = ref.$from;
        var $to = ref.$to;
        var can = $from.depth === 0 ? doc.type.allowsMarkType(type) : false;
        doc.nodesBetween($from.pos, $to.pos, function (node) {
            if (can) {
                return false;
            }
            can = node.inlineContent && node.type.allowsMarkType(type);
        });
        if (can) {
            return { v: true };
        }
    };
    for (var i = 0; i < ranges.length; i++) {
        var returned = loop(i);
        if (returned) {
            return returned.v;
        }
    }
    return false;
};
export var toggleMark = function (markType, attrs, tr) { return function (state, dispatch) {
    var _a = state.selection, empty = _a.empty, $cursor = _a.$cursor, ranges = _a.ranges;
    if ((empty && !$cursor) || !markApplies(state.doc, ranges, markType)) {
        return false;
    }
    if (dispatch) {
        if ($cursor) {
            if (markType.isInSet(state.storedMarks || $cursor.marks())) {
                dispatch(tr.removeStoredMark(markType));
            }
            else {
                dispatch(tr.addStoredMark(markType.create(attrs)));
            }
        }
        else {
            var has = false;
            for (var i = 0; !has && i < ranges.length; i++) {
                var _b = ranges[i], $from = _b.$from, $to = _b.$to;
                has = state.doc.rangeHasMark($from.pos, $to.pos, markType);
            }
            for (var i = 0; i < ranges.length; i++) {
                var _c = ranges[i], $from = _c.$from, $to = _c.$to;
                if (has) {
                    tr.removeMark($from.pos, $to.pos, markType);
                }
                else {
                    tr.addMark($from.pos, $to.pos, markType.create(attrs));
                }
            }
            dispatch(tr.scrollIntoView());
        }
    }
    return true;
}; };
export var removeMark = function (tr, from, to, mark) {
    if (mark === void 0) {
        mark = null;
    }
    var matched = [], step = 0;
    tr.doc.nodesBetween(from, to, function (node, pos) {
        if (!node.isInline) {
            return;
        }
        step++;
        var toRemove = null;
        if (mark instanceof MarkType) {
            var found = mark.isInSet(node.marks);
            if (found) {
                toRemove = [found];
            }
        }
        else if (mark) {
            if (mark.isInSet(node.marks)) {
                toRemove = [mark];
            }
        }
        else {
            toRemove = node.marks;
        }
        if (toRemove && toRemove.length) {
            var end = Math.min(pos + node.nodeSize, to);
            for (var i = 0; i < toRemove.length; i++) {
                var style = toRemove[i], found$1 = (void 0);
                for (var j = 0; j < matched.length; j++) {
                    var m = matched[j];
                    if (m.step === step - 1 && style.eq(m.style)) {
                        found$1 = m;
                    }
                }
                if (found$1) {
                    found$1.to = end;
                    found$1.step = step;
                }
                else {
                    matched.push({ style: style, from: Math.max(pos, from), to: end, step: step });
                }
            }
        }
    });
    matched.forEach(function (m) { return tr.step(new RemoveMarkStep(m.from, m.to, m.style)); });
    return tr;
};
export var removeMarks = function (marks, state, dispatch, tr) {
    var _a = state.selection, $cursor = _a.$cursor, ranges = _a.ranges;
    tr = tr || state.tr;
    if ($cursor) {
        marks.forEach(function (m) {
            if (m.isInSet(state.storedMarks || $cursor.marks())) {
                dispatch(tr.removeStoredMark(m));
            }
        });
    }
    else {
        var _loop_1 = function (i) {
            var _b = ranges[i], $from = _b.$from, $to = _b.$to;
            marks.forEach(function (m) {
                removeMark(tr, $from.pos, $to.pos, m);
            });
        };
        for (var i = 0; i < ranges.length; i++) {
            _loop_1(i);
        }
        dispatch(tr.scrollIntoView());
    }
    return true;
};
var toArray = function (x) { return x instanceof Array ? x : [x]; };
export var removeAllMarks = function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.except, except = _c === void 0 ? [] : _c;
    return function (state, dispatch) {
        var tr = state.tr;
        cleanMarks(tr, { except: toArray(except) });
        if (tr.docChanged) {
            dispatch(tr);
        }
    };
};
/**
 * Removes the marks from the selection base on the passed parameter.
 * Requires to dispatch the transaction.
 *
 * @example
 * See `removeAllMarks` or `cleanFormatting` function.
 */
export var cleanMarks = function (tr, _a) {
    var except = _a.except;
    var doc = tr.doc, selection = tr.selection;
    var schema = doc.type.schema;
    var empty = selection.empty, ranges = selection.ranges;
    var excludedMarkTypes = (except || []).map(function (mt) { return mt.name; });
    if (!empty) {
        var marks_1 = Object.keys(schema.marks)
            .map(function (m) { return schema.marks[m]; })
            .filter(function (mt) { return excludedMarkTypes.indexOf(mt.name) === -1; });
        ranges.forEach(function (_a) {
            var $from = _a.$from, $to = _a.$to;
            marks_1.forEach(function (mark) { return tr.removeMark($from.pos, $to.pos, mark); });
        });
    }
};
/**
 * Checks if a mark exists in the selection.
 * Used for checking the state of bold, italic, ... and unlink tools.
 */
export var hasMark = function (state, options) {
    var marks = state.schema.marks;
    var altMarks = (options.altMarks || []).filter(function (m) { return marks[m]; });
    var altStyle = options.altStyle;
    var _a = state.selection, from = _a.from, $from = _a.$from, to = _a.to, empty = _a.empty;
    var type = marks[options.mark];
    var doc = state.doc;
    var result = false;
    var currMarks;
    if (empty) {
        currMarks = state.storedMarks || $from.marks();
        result = (type && type.isInSet(currMarks)) || altMarks.some(function (m) { return marks[m].isInSet(currMarks); });
    }
    else {
        result = (type && doc.rangeHasMark(from, to, type)) || altMarks.some(function (m) { return doc.rangeHasMark(from, to, marks[m]); });
    }
    if (!result && altStyle && marks.style) {
        return selectionMarks(state, marks.style)
            .some(function (mark) { return styleValue(mark, altStyle) !== null; });
    }
    return Boolean(result);
};
export var styleValue = function (mark, style) {
    var styleText = (mark && mark.attrs.style) || '';
    var styles = styleText.split(/\s*;\s*/).filter(function (s) { return Boolean(s); });
    for (var i = 0; i < styles.length; i++) {
        var nameValue = styles[i].split(/\s*:\s*/);
        if (nameValue[0].toLowerCase() === style.name && style.value.test(nameValue[1])) {
            return nameValue[1];
        }
    }
    return null;
};
/**
 * Returns the marks of a specific type for every inline node in the selection.
 */
export var selectionMarks = function (state, markType) {
    var _a = state.selection, from = _a.from, $from = _a.$from, to = _a.to, empty = _a.empty;
    var marks = [];
    if (empty) {
        marks.push(markType.isInSet(state.storedMarks || $from.marks()));
    }
    else {
        state.doc.nodesBetween(from, to, function (node) {
            if (node.isInline) {
                marks.push(markType.isInSet(node.marks));
            }
        });
    }
    return marks;
};
/**
 * Returns the specified mark which wraps the selection.
 * Used by link tools.
 */
export var getMark = function (state, markType) {
    var marks = selectionMarks(state, markType);
    var filtered = marks.filter(function (m) { return Boolean(m); });
    return marks.length === filtered.length ? marks[0] : undefined;
};
/**
 * **Deprecated.** Use `selectionMarks` function instead.
 */
export var getActiveMarks = function (state, markType) {
    var marks = selectionMarks(state, markType);
    var filtered = marks.filter(function (m) { return Boolean(m); });
    var hasNodesWithoutMarks = marks.length !== filtered.length;
    return {
        hasNodesWithoutMarks: hasNodesWithoutMarks,
        marks: filtered
    };
};
