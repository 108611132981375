"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import ImportPageTitleBar from "../widgets/pageTitleBar/import_page_title_bar";
import BasePage from "../base_page";
import ErrorBar from "../widgets/bars/error_bar";
import { downloadFile, getModelNameForDisplay } from "./helpers/import_helper";
import ImportResultsPanel from "./widgets/general/import_results_panel";


export default class ImportResultReviewPage extends BasePage {
  constructor(props) {
    super(props);

    let modelName = UIUtils.getParameterByName("modelName");
    let modelNameForDisplay = getModelNameForDisplay(modelName);
    let projectId = UIUtils.getParameterByName("projectId");
    let processId = UIUtils.getParameterByName("processId");
    let importId = UIUtils.getParameterByName("importId");
    let dependency = UIUtils.getParameterByName("dependency");
    let dataSectionName = UIUtils.getParameterByName("dataSectionName");
    let title = `${dataSectionName ? `${dataSectionName} data` : modelNameForDisplay}
    ${dependency ? ` for ${dependency}` : ""} Import Results`;
    let isContinuousData = modelName === "ProcessCapability(MultipleAttributes)";

    this.state = {
      headerTitle: title,
      modelName,
      modelNameForDisplay,
      projectId,
      processId,
      dependency,
      dataSectionName,
      fileData: {fileName: ""},
      isContinuousData
    };

    this.handleFileDownload = this.handleFileDownload.bind(this);
    this.handleImportDataReceivedFromServer = this.handleImportDataReceivedFromServer.bind(this);

    UIUtils.showLoadingImage();
    UIUtils.secureAjaxGET(`import/get/findImportById`, {importId},
      false).done(this.handleImportDataReceivedFromServer);
  }

  componentDidMount() {
    document.title = "QbDVision Import";

    super.componentDidMount();
  }

  handleImportDataReceivedFromServer(importResults) {
    importResults.importedRecords = JSON.parse(importResults.data);
    this.setStateSafely({
      fileData: JSON.parse(importResults.links)[0],
      dependency: importResults.dependency,
      isPaperImport: importResults.isPaperImport,
      importResults,
    }, () => {
      UIUtils.hideLoadingImage();
    });
  }

  handleFileDownload(e) {
    downloadFile(e, this.state.fileData);
  }

  renderPage() {
    return (
      <div>
        <ImportPageTitleBar name={this.state.headerTitle}
                            importDataRecords={!!this.state.projectId}
                            hasNoLinksInTitleBar={true}
                            projectId={this.state.projectId}
                            processId={this.state.processId}
        />
        <div className="container-spacer" />
        <div className="container row-white import-results-review-container">
          <ErrorBar className="alert alert-danger d-none import-error-bar" />
          <ImportResultsPanel dataSectionName={this.state.dataSectionName}
                              projectId={this.state.projectId}
                              modelName={this.state.modelName}
                              modelNameForDisplay={this.state.modelNameForDisplay}
                              dependency={this.state.dependency}
                              selectedDependencyRecord={this.state.selectedDependencyRecord}
                              isPaperImport={this.state.isPaperImport}
                              importResults={this.state.importResults}
                              batches={this.state.importResults && this.state.importResults.batches}
                              allowDynamicColumnsCreation={true}
                              importContinuousData={this.state.isContinuousData}
                              showImportCompleteLabel={false}
                              fileData={this.state.fileData}
          />

        </div>
      </div>
    );
  }
}
