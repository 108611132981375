"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import BaseReactComponent from "../../base_react_component";


export default class CopyToClipboardWidget extends BaseReactComponent {

  componentDidMount() {
    super.componentDidMount();
    $(this.popover).popover({
      content: this.renderPopupContent,
      sanitizeFn: UIUtils.sanitizePopoverData,
    });
  }

  renderPopupContent() {
    return ReactDOMServer.renderToString(
      <div id={this.props.id + "TooltipDiv"}>
        Copied!
      </div>
    );
  }

  handleCopyToClipboard() {
    const dummy = document.createElement("input");
    const text = window.location.href;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    $(this.popover).popover("show");

    setTimeout(() => {
      $(this.popover).popover("hide");
    }, 1000);
  }

  render() {
    const {className} = this.props;
    return (<span className="info-tooltip-icon"
                  data-toggle="popover"
                  data-html="true"
                  data-container="body"
                  data-placement="top"
                  ref={popover => this.popover = popover}
                  data-trigger="manual"
                  data-boundary="viewport"
                  data-delay="400"
      >
        <button id="copyURLButton"
                type="button"
                onClick={this.handleCopyToClipboard}
                title="Copy URL to share with other users"
                className={`btn btn-secondary configurable-export-button mt-0 ${className || ""}`}
        >
          <FontAwesomeIcon icon={faLink} /> Copy
        </button>
      </span>
    );
  }
}
