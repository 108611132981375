import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";

type Props = {
  className?: string;
  id: string;
  isExpanded: boolean;
  expansionToggle: React.MouseEventHandler;
  expandedLabel?: string;
  collapsedLabel?: string;
}

export default function SeeMoreButton({className, id, isExpanded, expansionToggle, expandedLabel, collapsedLabel}: Props) {
  return (
    <button className={"btn btn-link " + (className ?? "")}
            id={id}
            onClick={expansionToggle}>
      {isExpanded ?
        <span><FontAwesomeIcon className="m-0 ml-1" icon={faChevronUp} size="sm" /> {expandedLabel ?? "Less Information"}</span>
        :
        <span><FontAwesomeIcon className="m-0 ml-1" icon={faChevronDown} size="sm" /> {collapsedLabel ?? "More Information"}</span>
      }
    </button>
  );
}
