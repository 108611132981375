"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import BaseReactComponent from "../../base_react_component";

/**
 * This class is for showing a tooltip with both an explanation and possibly a link to the FDA doc.
 *
 * YSK this class uses a lot of JQuery because it's using bootstrap's popover functionality that doesn't play nicely
 * with React.
 */
// i18next-extract-mark-ns-start widgets
export default class BaseInfoTooltip extends BaseReactComponent {

  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.id !== this.props.id;
  }

  componentDidMount() {
    super.componentDidMount();
    $(this.popover).popover({
      sanitizeFn: UIUtils.sanitizePopoverData,
    });
    $(this.popover).on("inserted.bs.popover", () => {
      this.show();
    });
  }

  show() {
    // Set the new popover content
    let htmlToShow = ReactDOMServer.renderToStaticMarkup(this.props.verbiage);
    const contentElement = $("#" + this.props.id + "Content");
    contentElement.html(htmlToShow);

    // Change the css classes
    contentElement.addClass("info-tooltip-content-verbiage");
  }

  renderPopupContent() {
    const {t} = this.props;
    /*
      This gets converted to HTML and passed around, so don't bother putting onClick handlers here.
      Put them in componentDidMount() above instead.
     */
    return ReactDOMServer.renderToString(
      <div id={this.props.id + "TooltipDiv"}>
        <div id={this.props.id + "Content"} className="info-tooltip-content" />
        {this.props.fdaGuidanceURL ?
          <div id={this.props.id + "Footer"}>
            <a
              href={UIUtils.computePdfURL(this.props.fdaGuidanceURL, this.props.fdaGuidancePage, this.props.fdaGuidanceOffset)}
              target="_blank"
              rel="noopener noreferrer"
              className="info-tooltip-footer"
              id={this.props.id + "GuidanceLink"}
            >
              {t("Guidance")}
            </a>
          </div>
          : ""
        }
      </div>
    );
  }

  render() {
    const {className, id, textDirection} = this.props;

    return (
      <span className={`info-tooltip-icon ${className || ""}`} id={id}
            data-toggle="popover"
            data-html="true"
            data-container="body"
            data-placement={textDirection || "top"}
            ref={popover => this.popover = popover}
            data-content={this.renderPopupContent()}
            data-boundary="viewport"
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>
    );
  }

}