import ComboBoxAttribute from "../../editor/attributes/combo_box_attribute";
import * as UIUtils from "../../ui_utils";

/**
 * Unlike other calculated risk attributes TT ones need to be initialized not only on
 * Add but View and Edit as well as they have a lot of scenarios to enable TT through
 * cloning where TT values might not be properly initialized.
 */
export default class TechTransferComboBoxAttribute extends ComboBoxAttribute {
  initializeParentValueIfNecessary() {
    const attributeName = this.getAttributeName();
    const parentValue = this.props.parent.state[attributeName];

    if (!this.isParentInitializedWithDefault && this.valueIsEmpty(parentValue)) {
      const newValue = this.getValue();
      if (!this.valueIsEmpty(newValue)) {
        if (!this.props.parent.isView()) {
          this.props.parent.initializeValue(attributeName, newValue);
          this.isParentInitializedWithDefault = true;
        }
      }
    } else if (!this.valueIsEmpty(parentValue)) {
      this.isParentInitializedWithDefault = true;
    }
  }

  getOldValue(attributeName) {
    const {olderDefault} = this.props;
    let olderVersion = this.props.parent.getOlderVersion();
    let oldValue = null;

    if (olderVersion) {
      oldValue = olderVersion[attributeName];

      if (this.valueIsEmpty(oldValue) && !this.valueIsEmpty(olderDefault)) {
        oldValue = olderDefault;
      }
    }

    if (UIUtils.isNumber(oldValue)) {
      return this.props.olderOptions?.find(olderOption => olderOption.key === oldValue)?.value ?? oldValue;
    }

    return oldValue;
  }

  valueIsEmpty(value) {
    return value === null || value === undefined || value === "";
  }
}
