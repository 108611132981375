"use strict";

import * as styles from "./widget_item.module.scss";

import React, {Fragment, useState} from "react";
import {EditorUtils} from "@progress/kendo-react-editor";
import {FIELD_KEY, FieldItem, getFields} from "../../../common/editables_map";
import EditorParser from "../../../utils/parser/parser";
import {COMMON_ATTRIBUTES, WIDGET_KIND, WIDGET_KIND_VALUE, WIDGET_NAME_TO_ATTRIBUTES,} from "./widget_constants";
import {EditorView} from "@progress/kendo-editor-common";
import {setErrorText} from "../../../utils";
import * as UIUtils from "../../../../ui_utils";
import MultipleFilters, {Filter} from "../../../components/multiple_filters";
import {Project} from "../../../../common/models/project";
import {isWidget} from "../../../common/editorSchemas/widget_node";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export type WidgetData = {
  model?: string;
  attribute?: string;
  filters?: Array<Filter>;
  areFiltersValid?: boolean;
};

type WidgetItemProps = {
  name: WIDGET_KIND_VALUE;
  selected: boolean;
  tooltipContent?: () => React.ReactElement;
  editorView: EditorView;
  enabledModels?: Array<string>;
  project: Project;
  // eslint-disable-next-line no-unused-vars
  onSelectWidget: (widgetName: string) => void;
  // eslint-disable-next-line no-unused-vars
  onAddWidget: (inputValues: WidgetData) => void;
};

export default function WidgetItem(props: WidgetItemProps) {
  const {
    name,
    selected,
    editorView,
    enabledModels,
    project,
    tooltipContent,
    onSelectWidget,
    onAddWidget,
  } = props;
  const [inputValues, setInputValues] = useState<WidgetData>({});

  const handleInputChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setInputValues({...inputValues, [key]: event.target.value});
  };

  const handleUpdateFilters = (
    filters: Array<Filter>,
    areFiltersValid: boolean,
  ) => {
    setInputValues({...inputValues, filters, areFiltersValid});
  };

  const renderSubCategory = (classNames: Map<string, FieldItem>) => {
    const model = inputValues[COMMON_ATTRIBUTES.Model.key];
    const options: Array<FieldItem> = [];
    const clazz = classNames.get(model);
    if (clazz) {
      for (const attribute of clazz.attributesMap.values()) {
        if (attribute.attributesMap) {
          options.push(attribute);
        }
      }
    }

    return (
      <div className="col-sm-12 form-group">
        <div className="row col-sm-12 m-0 p-0 justify-content-between align-items-center">
          <label htmlFor="submodelComboBox" className="col-form-label">
            Submodel:
          </label>
          <OverlayTrigger
            placement="auto"
            overlay={
              <Tooltip id={`${name}Tooltip`}>
                <div>Select a submodel when referencing field names that appear multiple times in a record.</div>
                <div>Common examples include:</div>
                <ul>
                  <li>{`"Name" or "Description" of a Supporting Document`}</li>
                  <li>{`"Effect on Attribute", "Impact/Severity", or "Justification" of a Risk Link in Criticality Assessment section`}</li>
                  <li>{`"Name" or "Description" of a Control Method`}</li>
                </ul>
                <div>Widgets with submodels will only render when the widget is inserted in another widget with a matching model</div>
              </Tooltip>
            }
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </OverlayTrigger>
        </div>
        <select
          id="submodelComboBox"
          className="form-control"
          value={inputValues.attribute}
          onChange={(event) => handleInputChange("attribute", event)}
          data-validate="true"
          title="Submodel"
          disabled={!model}
        >
          <option value="">None</option>
          {options.map((option) => {
            return (
              <option key={option.name} value={option.name}>
                {option.displayName || option.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const getOptionStateByKind = () => {
    const editorContent = EditorUtils.getHtml(editorView.state);
    const referenceBlockWidgets = EditorParser.getWidgetByKind(
      editorContent,
      WIDGET_KIND.ReferenceBlock,
    );
    const REFERENCE_BLOCK_ATTRIBUTES =
      WIDGET_NAME_TO_ATTRIBUTES[WIDGET_KIND.ReferenceBlock];
    if (name === WIDGET_KIND.ReferenceBlock) {
      return {
        renderElement: () => {
          return (
            <div className="col-sm-12 form-group">
              <label htmlFor="nameInput" className="col-form-label">
                Name:
              </label>
              <input
                id="nameInput"
                className="form-control"
                value={
                  inputValues[REFERENCE_BLOCK_ATTRIBUTES.ReferenceBlockName.key]
                }
                onChange={(event) =>
                  handleInputChange(
                    REFERENCE_BLOCK_ATTRIBUTES.ReferenceBlockName.key,
                    event,
                  )
                }
                title="Value"
              />
            </div>
          );
        },
        areAllInputsValid: () => {
          for (const attribute of Object.values(REFERENCE_BLOCK_ATTRIBUTES)) {
            if (!inputValues[attribute.key]) {
              return {isValid: false};
            }
          }

          const allReferenceBlockNames = referenceBlockWidgets.map(
            (referenceBlockWidget) =>
              referenceBlockWidget[
                REFERENCE_BLOCK_ATTRIBUTES.ReferenceBlockName.key
              ],
          );
          if (
            allReferenceBlockNames.includes(
              inputValues[REFERENCE_BLOCK_ATTRIBUTES.ReferenceBlockName.key],
            )
          ) {
            return {isValid: false, errorMessage: "Reference block name must be unique."};
          }

          return {isValid: true};
        },
      };
    }

    if (name === WIDGET_KIND.ReferenceList) {
      const REFERENCE_LIST_ATTRIBUTES =
        WIDGET_NAME_TO_ATTRIBUTES[WIDGET_KIND.ReferenceList];
      return {
        renderElement: () => {
          return (
            <Fragment>
              <div className="col-sm-12 form-group">
                <label
                  htmlFor="referenceBlockComboBox"
                  className="col-form-label"
                >
                  Reference Block:
                </label>
                <select
                  id="referenceBlockComboBox"
                  className="form-control"
                  value={
                    inputValues[REFERENCE_LIST_ATTRIBUTES.ReferenceBlock.key]
                  }
                  onChange={(event) =>
                    handleInputChange(
                      REFERENCE_LIST_ATTRIBUTES.ReferenceBlock.key,
                      event,
                    )
                  }
                  data-validate="true"
                  title="Reference Block"
                >
                  <option value="">Choose Here</option>
                  {referenceBlockWidgets.map((referenceBlockWidget) => {
                    return (
                      <option
                        value={
                          referenceBlockWidget[
                            REFERENCE_BLOCK_ATTRIBUTES!.ReferenceBlockName.key
                            ]
                        }
                        key={
                          referenceBlockWidget[
                            REFERENCE_BLOCK_ATTRIBUTES.ReferenceBlockName.key
                            ]
                        }
                      >
                        {
                          referenceBlockWidget[
                            REFERENCE_BLOCK_ATTRIBUTES.ReferenceBlockName.key
                            ]
                        }
                      </option>
                    );
                  })}
                </select>
              </div>
            </Fragment>
          );
        },
        areAllInputsValid: () => {
          for (const attribute of Object.values(REFERENCE_LIST_ATTRIBUTES)) {
            if (!inputValues[attribute.key]) {
              return {isValid: false};
            }
          }
          return {isValid: true};
        },
      };
    }

    if (name === WIDGET_KIND.TableOfContents) {
      return {
        areAllInputsValid: () => {
          return {isValid: true};
        },
      };
    }

    if (name === WIDGET_KIND.Table || name === WIDGET_KIND.Repeater) {
      const FIELDS = getFields(project);
      const mergedMap = FIELDS.get(FIELD_KEY.RecordFields).attributesMap;
      const classNames = Array.from(mergedMap.keys())
        .sort()
        .filter((clazz) => enabledModels.includes(clazz));

      return {
        renderElement: () => {
          return (
            <Fragment>
              <div className="col-sm-12 form-group">
                <label htmlFor="modelComboBox" className="col-form-label">
                  Model:
                </label>
                <select
                  id="modelComboBox"
                  className="form-control"
                  value={inputValues[COMMON_ATTRIBUTES.Model.key]}
                  onChange={(event) =>
                    handleInputChange(COMMON_ATTRIBUTES.Model.key, event)
                  }
                  data-validate="true"
                  title="Model"
                >
                  <option value="">Select a model</option>
                  {classNames.map((clazz) => {
                    const item = mergedMap.get(clazz);
                    return (
                      <option value={clazz} key={clazz}>
                        {item.displayName ||
                          UIUtils.convertCamelCaseToSpacedOutWords(clazz)}
                      </option>
                    );
                  })}
                </select>
              </div>
              {renderSubCategory(mergedMap)}
              <MultipleFilters
                model={inputValues[COMMON_ATTRIBUTES.Model.key]}
                subModel={inputValues.attribute}
                disabledAddButton={!inputValues[COMMON_ATTRIBUTES.Model.key]}
                onUpdateFilters={handleUpdateFilters}
                project={project}
              />
            </Fragment>
          );
        },
        areAllInputsValid: () => {
          const model = inputValues[COMMON_ATTRIBUTES.Model.key];
          const subModel = inputValues.attribute;
          const anchor = editorView?.state?.selection?.$anchor;

          for (let d = anchor.depth; d > 0 && model; d--) {
            const node = anchor.node(d);
            if (!isWidget(node)) {
              continue;
            }

            if (node.attrs.model === model) {
              if (!subModel) {
                return {isValid: false, errorMessage: "Submodel must be selected to insert this widget"};
              }

              if (node.attrs.model === `${model}.${subModel}`) {
                return {isValid: false, errorMessage: `Couldn't add the ${name} of model ${model}.${subModel}`};
              }
            }

            break;
          }

          const {areFiltersValid} = inputValues;
          const filters = inputValues[COMMON_ATTRIBUTES.Filters.key];
          return {
            isValid: inputValues[COMMON_ATTRIBUTES.Model.key] &&
              (!filters || !filters.length || areFiltersValid)
          };
        },
      };
    }

    return null;
  };

  const handleAddWidget = () => {
    onAddWidget(inputValues);
    setInputValues({});
  };

  const renderOptionsByKind = () => {
    const optionState = getOptionStateByKind();
    if (!optionState) {
      return;
    }
    const inputState = optionState.areAllInputsValid() as {
      isValid: boolean;
      errorMessage?: string;
    };
    return (
      <Fragment>
        {optionState.renderElement ? optionState.renderElement() : null}
        <div className="col-sm-12">
          <button
            id="insert-button-on-side-menu"
            type="button"
            className="btn btn-primary mr-1"
            onClick={handleAddWidget}
            title={inputState.errorMessage || ""}
            disabled={!optionState || !inputState.isValid}
          >
            Insert
          </button>
          <button
            id="cancel-button-on-side-menu"
            type="button"
            className="btn btn-secondary"
            onClick={() => onSelectWidget(null)}
            data-dismiss="modal"
          >
            Cancel
          </button>
        </div>
      </Fragment>
    );
  };

  const renderTooltip = () => {
    return (
      <OverlayTrigger
        placement="auto"
        overlay={
          <Tooltip id={`${name}Tooltip`}>
            {tooltipContent && tooltipContent()}
          </Tooltip>
        }
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </OverlayTrigger>
    );
  };

  if (selected) {
    return (
      <div className="card pt-3 pb-3">
        <div className="card-body">
          <div className="row col-sm-12 m-0 justify-content-between">
            <div data-widget-type={name} className="font-weight-bold">{name}</div>
            {renderTooltip()}
          </div>
          {renderOptionsByKind()}
        </div>
      </div>
    );
  }

  return (
    <div
      data-widget-type={name}
      className={`${styles["widget-item"]} row m-0 p-3 justify-content-between`}
      onClick={() => onSelectWidget(name)}
    >
      <div className="font-weight-bold">{name}</div>
      {renderTooltip()}
    </div>
  );
}
