"use strict";

import React from "react";

/**
 * If we want to wrap the project material options component in card, like what we are doing now when we create
 * a material starting from a library, We show a modal what has an options section wrapped in a Card.
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export const ProjectMaterialOptionsCard = ({children}) => {
  return (
    <div className="section">
      <div className="section-content">
        {children}
      </div>
    </div>
  );
};