import * as React from 'react';
import * as PropTypes from 'prop-types';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
import { classNames } from '@progress/kendo-react-common';
/**
 * Represents the [KendoReact BottomNavigationItem component]({% slug overview_bottomnavigation %}).
 */
export var BottomNavigationItem = React.forwardRef(function (props, ref) {
    validatePackage(packageMetadata);
    var elementRef = React.useRef(null);
    React.useImperativeHandle(ref, function () { return ({
        element: elementRef.current
    }); });
    var className = props.className, style = props.style, selected = props.selected, disabled = props.disabled, item = props.item, render = props.render, dataItem = props.dataItem, icon = props.icon, text = props.text, id = props.id, onSelect = props.onSelect, onKeyDown = props.onKeyDown, index = props.index, tabIndex = props.tabIndex;
    var itemClasses = React.useMemo(function () { return classNames('k-bottom-nav-item', {
        'k-selected': selected,
        'k-disabled': disabled
    }, className); }, [selected, disabled, className]);
    var handleClick = React.useCallback(function (event) {
        if (onSelect && index !== undefined && !disabled) {
            onSelect(event, index);
        }
    }, [onSelect, index, disabled]);
    var handleKeyDown = React.useCallback(function (event) {
        if (onKeyDown && index !== undefined && !disabled) {
            onKeyDown(event, index);
        }
    }, [onKeyDown, index, disabled]);
    var renderer = render;
    var ItemComp = item;
    var bottomNavItem = (React.createElement("span", { ref: elementRef, className: itemClasses, style: style, role: 'link', id: id, tabIndex: tabIndex, onClick: handleClick, onKeyDown: handleKeyDown, "aria-selected": selected, "aria-disabled": disabled }, ItemComp ? React.createElement(ItemComp, { itemIndex: index, item: dataItem }) :
        (React.createElement(React.Fragment, null,
            icon && React.createElement("span", { className: "k-bottom-nav-item-icon k-icon k-i-".concat(icon) }),
            text && React.createElement("span", { className: 'k-bottom-nav-item-text', style: { userSelect: 'none' } }, text)))));
    return renderer !== undefined ? renderer.call(undefined, bottomNavItem, props) : bottomNavItem;
});
var defaultProps = {
    tabIndex: 0
};
BottomNavigationItem.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    icon: PropTypes.string,
    text: PropTypes.string,
    tabIndex: PropTypes.number
};
BottomNavigationItem.defaultProps = defaultProps;
BottomNavigationItem.displayName = 'KendoReactBottomNavigationItem';
