"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import LabelTooltip from "../../widgets/tooltips/label_tooltip";
import { FILE_STATUS } from "../../helpers/document_transfer_helper";
import ValidationIcon from "../../widgets/generic/validation_icon";
import { INVALID_FILE_EXTENSION_ERROR } from "../attributes/constants/attributes_tooltips";
import BaseReactComponent from "../../base_react_component";


/**
 * This class is responsible for encapsulating widget for entering links or uploading attachments.
 * It is embedded into other attributes like the risk_links_attribute or the doc_links_attribute and the process_capability_attribute
 */
export default class LinkAttachment extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  handleChangeLink(rowData, index, e) {
    $("[data-toggle='validator']").validator("update");
    let target = $(e.target);
    if (target && target[0]) {
      target[0].setCustomValidity("");
      target.trigger("input");
    }

    rowData.linkType = (e.target.value === "" ? "" : "Link");
    rowData.link = e.target.value;

    let uploadDiv = $("#" + this.props.name + "_UploadFileDiv_" + index)[0];
    if (uploadDiv) {
      if (rowData.link !== "") {
        uploadDiv.classList.add("d-none");
      } else {
        uploadDiv.classList.remove("d-none");
      }
    }
  }

  handleFileUpload(rowData, e) {
    UIUtils.ignoreHandler(e);

    let input = $("#" + e.target.id)[0];

    if (input.files) {
      let file = input.files[0];
      let fileName = file.name;
      if (!UIUtils.isFileNameValidForUpload(fileName)) {
        this.setStateSafely({
          error: INVALID_FILE_EXTENSION_ERROR
        });
        return false;
      }
    } else {
      this.setStateSafely({
        error: null
      });
    }

    this.props.onFileUpload(rowData, e);
    return true;
  }

  getDefaultCaption() {
    return this.props.caption ? this.props.caption : "Link or Attachment";
  }

  render() {
    let index = this.props.index;
    let rowData = this.props.data;
    let rowIsInEditMode = this.props.rowIsInEditMode;
    let isDiffingVersions = this.props.isDiffingVersions;

    return (
      <div>
        {this.props.showCaption ? <LabelTooltip text={`${this.getDefaultCaption()}:`}
                                                className="col-form-label links-details-field-label-tooltip"
                                                noColon={true}
        /> : ""}
        {!rowIsInEditMode
          ? (
            isDiffingVersions
              ? (
                <div id={this.props.name + "_Link_" + index}>
                  {rowData.linkDiffHTML}
                </div>
              )
              : (rowData.linkType !== "Attachment"
                  ? (
                    <div className="links-manage">
                      <a id={this.props.name + "_Link_" + index}
                         href={UIUtils.cleanUpURL(rowData.link)}
                         rel="noopener noreferrer"
                         target="_blank"
                         className="links-table-link"
                      >
                        {rowData.link}
                      </a>
                    </div>)
                  : (
                    <div className="links-manage">
                      <a
                        id={this.props.name + "_Link_" + index}
                        download={rowData.fileName}
                        onClick={(e) => {
                          this.props.onFileDownload.bind(this, rowData)(e);
                        }}
                        className="links-table-link"
                      >
                        {rowData.fileName}
                      </a>
                    </div>
                  )
              )
          )
          : // else we're in Edit mode
          (!rowData.linkType || (rowData.linkType === "" || rowData.linkType
            === "Link")) ? (
            <div id={this.props.name + "_LinkFormGroup_" + index}
                 className="form-group"
            >
              <div className="links-div-row">
                <input type="text"
                       id={this.props.name + "_InputLink_" + index}
                       className="form-control"
                       placeholder="Add a Link"
                       disabled={this.props.disabled}
                       defaultValue={rowData.link}
                       required={this.props.required}
                       data-required-error={`${this.getDefaultCaption()} is required.`}
                       onChange={this.handleChangeLink.bind(this, rowData, index)}
                />
              </div>
              <div id={this.props.name + "_UploadFileDiv_" + index}
                   className={"links-div-row" + (!rowData.link ? "" : " hidden")}
              >
                <div className="links-div-or-for-upload">
                  <label className="links-btn-label-left links-vertical-centered">
                    OR
                  </label>
                </div>
                <div className="links-div-button">
                  <label id={this.props.name + "_UploadFileLabel_" + index}
                         className="btn btn-primary btn-links-upload-file"
                  >
                    Upload a File
                    <input id={this.props.name + "_UploadFileButton_" + index}
                           type="file"
                           disabled={this.props.disabled}
                           className="links-file-input"
                           onChange={this.handleFileUpload.bind(this, rowData)}
                    />
                  </label>
                  <ValidationIcon
                    id={`${this.props.name}_UploadFileButtonErrorIcon_` + index}
                    tooltip={this.state.error}
                    trigger="click"
                    visible={!!this.state.error}
                  />
                </div>
              </div>
              <div className="links-div-row">
                <div className="help-block with-errors"
                     id={this.props.name + "_Link_" + index + "ErrorDiv"}
                />
              </div>
            </div>) : rowData.fileStatus === FILE_STATUS.UPLOADING ? (
            <div id={this.props.name + "_LinkFormGroup_" + index}
                 className="form-group"
            >
              <div className="links-div-attachment-row">
                <div className="links-vertical-centered">
                  <FontAwesomeIcon className="links-clip-icon"
                                   icon={faPaperclip}
                  />
                  <span id={this.props.name + "_UploadingFile_" + index}>{rowData.fileName}</span>
                  <button id={this.props.name + "_CancelUploadButton_" + index}
                          type="button"
                          className="btn btn-primary btn-links btn-sm"
                          aria-label="Cancel Upload"
                          onClick={this.props.onCancelUpload.bind(this, rowData)}
                  >
                    <FontAwesomeIcon icon={faTimes} className="no-margins" />
                  </button>
                </div>
              </div>
              <div>
                <div className="progress">
                  <div id={this.props.name + "_UploadProgressBar_" + index}
                       className="progress-bar"
                       role="progressbar"
                       aria-valuenow={rowData.progress}
                       aria-valuemin="30"
                       aria-valuemax="100"
                       style={{
                         width: rowData.progress + "%"
                       }}
                  >
                    {rowData.progress + "%"}
                  </div>
                </div>
              </div>
            </div>) : (
            <div id={this.props.name + "_LinkFormGroup_" + index}
                 className="form-group"
            >
              <div className="links-div-attachment-row">
                <div className="links-vertical-centered">
                  <FontAwesomeIcon className="links-clip-icon"
                                   icon={faPaperclip}
                  />
                  <a id={this.props.name + "_Link_" + index}
                     download={rowData.fileName}
                     onClick={this.props.onFileDownload.bind(this, rowData)}
                  >
                    {rowData.fileName}
                  </a>
                  <button id={this.props.name + "_DeleteAttachmentButton_" + index}
                          type="button"
                          className="btn btn-primary btn-links btn-sm"
                          aria-label="Cancel"
                          onClick={this.props.onDeleteAttachment.bind(this, rowData)}
                  >
                    <FontAwesomeIcon icon={faTimes} className="no-margins" />
                  </button>
                </div>
              </div>
            </div>)}
      </div>
    );
  }
}
