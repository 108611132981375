"use strict";

import * as UIUtils from "../../../../ui_utils";
import React from "react";
import * as styles from "./styles/about_actions_area.module.scss";

export const AboutActionsArea = ({aboutPanelRecord}) => {
  return (
    <span className={`${styles["container"]} quick-panel-title-state`} id="pageTitleBarStatus">
      {"[" + (aboutPanelRecord.currentState || UIUtils.VERSION_STATES.DRAFT) + "]"}
    </span>
  );
};