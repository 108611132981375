"use strict";

import * as UIUtils from "../../../ui_utils";
import { BaseDataTransform } from "../base_data_transform";
import { REPORT_DRAFT_ENUM } from "../../constants/report_constants";
import {
  checkEmptySummaryData,
  fillInCriticalities,
  fillInRiskLinks,
  formatJustification,
  getRequirementsReportModelNameForView,
  processDraftRegularities,
  processEmptyFields,
  isDraft,
} from "../../canned_reports/canned_report_helper";
import * as CommonEditablesFormatter from "../../../../server/common/editables/common_editables_formatter";

export class RequirementSummaryDataTransform extends BaseDataTransform {

  get type() {
    return "requirementSummary";
  }

  /**
   * @inheritDoc
   */
  shouldRun(ignore) {
    return true;
  }

  /**
   * @inheritDoc
   * @override
   */
  transform(input, options) {
    const result = input;
    const data = result.instances;

    let allRecordTypes = this.getSupportedRecordTypes();
    let allRecords = [];

    this.fillRegulatoryPhaseInfo(data);

    for (let i = 0; i < allRecordTypes.length; i++) {
      let recordType = allRecordTypes[i];
      const recordTypeOptions = {...options, recordType};
      const currentResultSet = data[recordType];

      if (currentResultSet) {
        let records = [];
        for (let j = 0; j < currentResultSet.length; j++) {
          let requirement = currentResultSet[j];
          let record = this.buildRecordFromRequirement(requirement, recordTypeOptions);

          // this needs to update the options original options object (would not work on a copy)
          this.updateDraftOptionsFromRequirement(options, requirement);

          records.push(record);
          allRecords.push(record);
        }

        records = this.filterResultsByCriticality(records, options);
        records = this.sortRecords(records, recordTypeOptions);

        let modelNameForView = this.getModelNameForView(recordType);
        delete data[modelNameForView];
        data[modelNameForView] = records;
      }
    }

    processDraftRegularities(data);
    checkEmptySummaryData(data, allRecords);

    result.instances = data;
    return this.wrapUp(result, options);
  }

  buildRecordFromRequirement(requirement, recordTypeOptions) {
    let record = this.getRecordInstance(requirement, recordTypeOptions);
    this.fillGeneralInformation(record, requirement, recordTypeOptions);
    this.fillParentInformation(record, requirement, recordTypeOptions);
    this.fillAcceptanceCriteria(record, requirement, recordTypeOptions);
    this.fillCriticalityInformation(record, requirement, recordTypeOptions);
    this.fillRiskLinks(record, requirement, recordTypeOptions);
    this.fillControlInformation(record, requirement, recordTypeOptions);

    processEmptyFields(record);
    processEmptyFields(requirement);
    return record;
  }

  getRecordInstance() {
    return {};
  }

  /**
   * Returns a sorted array based on the original records array.
   * @param records
   * @param options
   * @returns {*}
   */
  sortRecords(records, ignore) {
    // the default implementation is to do nothing, so it returns the same array
    // this may be overridden in derived classes
    return records.sort(UIUtils.sortBy("modelType", "name"));
  }

  getModelNameForView(recordType) {
    return getRequirementsReportModelNameForView(recordType);
  }

  updateDraftOptionsFromRequirement(options, requirement) {
    options.showDraftAlerts = options.showDraftAlerts ? options.showDraftAlerts : isDraft(requirement);
  }

  filterResultsByCriticality(records, options) {
    let filteredRecordCriticalities = this.getFilteredRecordCriticalities(options);

    if (filteredRecordCriticalities && filteredRecordCriticalities.length > 0 &&
      !filteredRecordCriticalities.includes("All")) {
      records = records.filter(record => filteredRecordCriticalities.includes(record.riskLabel));
    }
    return records;
  }

  getFilteredRecordCriticalities(options) {
    const {filterOptions, reportOptions} = options;
    return filterOptions ? filterOptions.criticality : reportOptions.defaultFilter.criticality;
  }

  getFilteredRecordTypes(options) {
    const {filterOptions, reportOptions} = options;
    return filterOptions ? filterOptions.type : reportOptions.defaultFilter.type;
  }

  fillAcceptanceCriteria(record, requirement, ignore) {
    record.LSL = UIUtils.secureString(requirement.LSL);
    record.USL = UIUtils.secureString(requirement.USL);
    record.target = UIUtils.secureString(requirement.target);
    record.targetJustification = UIUtils.secureString(requirement.targetJustification);
  }

  fillGeneralInformation(record, requirement, options) {
    const {recordType} = options;
    let filteredRecordTypes = this.getFilteredRecordTypes(options);

    record.name = UIUtils.secureString(requirement.name);
    record.category = UIUtils.secureString(CommonEditablesFormatter.formatMultiSelectValueForDisplay(requirement.category));
    record.modelType = UIUtils.secureString(requirement.modelType);
    record.draftMarker = isDraft(requirement) ? REPORT_DRAFT_ENUM.DraftMarker : "";
    record.showSeparator = filteredRecordTypes.includes(recordType);
    record.showData = filteredRecordTypes.includes(recordType);
  }

  fillControlInformation(record, requirement, ignore) {
    if (requirement.ControlMethods) {
      record.controlMethods = UIUtils.secureArray(requirement.ControlMethods).join("<br/>\r\n");
    }
  }

  fillRegulatoryPhaseInfo(data) {
    if (data.infoRegulatoryPhase) {
      data.infoRegulatoryPhase.regulatoryPhase = UIUtils.secureString(data.infoRegulatoryPhase.regulatoryPhase);
      data.infoRegulatoryPhase.currentRegulatoryPhase = UIUtils.secureString(data.infoRegulatoryPhase.currentRegulatoryPhase);
    }
  }

  fillCriticalityInformation(record, requirement, options) {
    const {rmpVersions} = options;
    if (requirement.riskInfo) {
      let effectiveRMP = rmpVersions.find(rmpVersion => rmpVersion.id === requirement.effectiveRMPVersionId);
      fillInCriticalities(effectiveRMP, requirement, true);
      record.criticalityScore = requirement.criticality;
      record.criticality = UIUtils.secureString(this.formatCriticality(requirement));
      record.riskLabel = UIUtils.secureString(requirement.criticalityRiskLabel);
      record.justification = UIUtils.secureString(requirement.justification);
    }
  }

  formatCriticality(requirement) {
    return requirement.criticality ? `${requirement.criticality} (${requirement.criticalityLabel} - ${requirement.criticalityRiskLabel})` : "";
  }

  fillRiskLinks(record, requirement, options) {
    const {
      typeaheadOptions,
      reportOptions,
    } = options;

    /**
     * For IQA, MAs and PPs justification field is constructed as following:
     * 1- We put justification of risk link with highest criticality
     * 2- If multiple criticalities with same criticality then we need to combine them in a list
     */
    let riskLinks = fillInRiskLinks(requirement, typeaheadOptions);
    if (riskLinks && riskLinks.length > 0) {
      riskLinks = riskLinks.filter(link => link.justification || reportOptions.includeRisksWithoutJustification)
        .map(link => {
          return {
            criticality: link.criticality,
            justification: link.justification || "None",
            riskLinkTo: link.riskLinkTo,
          };
        });

      if (riskLinks && riskLinks.length > 0) {
        riskLinks.sort((a, b) => b.criticality - a.criticality);
        const maxCriticality = riskLinks[0].criticality;
        let risksWithHighestCriticality = riskLinks.filter(link => link.criticality === maxCriticality);

        record.justification = formatJustification(risksWithHighestCriticality);
        record.maxCriticalityScore = maxCriticality;
      }
    }
  }

  fillParentInformation() {
    // does nothing, but may be overridden in derived classes
  }

  /**
   * Performs a final formatting pass on the results
   * @param result
   * @returns {*}
   * @protected
   */
  wrapUp(result) {
    // does nothing by default. May be overridden in a derived class.
    return result;
  }
}

