"use strict";

import { ServiceBase } from "../service_base";

/**
 * @typedef IImportServiceParams {Object}
 * @property {boolean} [useTwoWayCommunication]
 * @property {boolean} [idempotent]
 * @property {boolean} [clearUIErrors]
 * @property {boolean} [global]
 * @property {Map<string, string>} [statusMap]
 * @property {boolean} [autoClose]
 */

/**
 * Service layer for Import functionality. Use this to execute import operations on the backend.
 */
export class ImportService extends ServiceBase {
  /**
   * Validates records that were uploaded in an Excel file (not a smart import).
   *
   * @param payload The payload for the request
   */
  validate(payload) {
    let parameters = {
      global: true,
      action: "importValidate",
      useTwoWayCommunication: true,
    };

    return this.sendToBackend(payload, parameters);
  }

  /**
   * Saves records that were uploaded in an Excel file (not a smart import).
   *
   * @param payload The payload for the request
   */
  saveRecords(payload) {
    let parameters = {
      global: true,
      action: "importObjects",
      useTwoWayCommunication: true,
    };

    return this.sendToBackend(payload, parameters);
  }

  /**
   * Extracts the text from a pdf or image file (png, jpg)
   * @param payload The payload for the request
   */
  extractText(payload) {
    let parameters = {
      global: false,
      action: "extractText",
      useTwoWayCommunication: true,
    };

    return this.sendToBackend(payload, parameters);
  }

  /**
   * Extracts the text from a pdf or image file (png, jpg)
   * @param payload The payload for the request
   */
  mapData(payload) {
    let parameters = {
      global: false,
      action: "mapData",
      useTwoWayCommunication: true,
    };

    return this.sendToBackend(payload, parameters);
  }

  extractColumns(payload) {
    let parameters = {
      global: false,
      action: "extractColumns",
      useTwoWayCommunication: true,
    };

    return this.sendToBackend(payload, parameters);
  }
}
