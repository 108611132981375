"use strict";

import React from "react";
import Draggable from "react-draggable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGripLinesVertical,
  faWindowMaximize,
  faWindowMinimize,
  faWindowRestore
} from "@fortawesome/free-solid-svg-icons";
import quickPanelIconLarge from "../../images/quickPanel/quick-panel-icon-large.svg";
import { EDITOR_OPERATIONS } from "../editor_constants";
import BaseQuickPanel from "./base_quick_panel";

export const StateEnum = {
  CLOSED: "CLOSED",
  EXPANDING_TO_OPEN: "EXPANDING_TO_OPEN", // In the process of expanding to the open state
  OPEN: "OPEN",
  COLLAPSING: "COLLAPSING", // In the process of collapsing back to closed
  EXPANDING_TO_MAXIMIZED: "EXPANDING_TO_MAXIMIZED",
  MAXIMIZED: "MAXIMIZED",
};

const ANIMATION_TIME = 500;
const MINIMUM_WIDTH = 400;
const COLLAPSED_WIDTH = 65;
const SCROLL_BAR_WIDTH = 15;
const RIGHT_MARGIN_WIDTH = SCROLL_BAR_WIDTH + 5; // On expansion we leave some space so the scroll bars aren't beside each other
const EXPAND_WIDTH_TOKEN = "QUICK_EDIT_EXPAND_WIDTH";

/**
 * @typedef {Object} QuickPanelConfig
 * @property {boolean} showHistory Set to true if you want the quick panel to show the records history when in view mode
 * @property {string} quickPanelButtons An array of button to show in the quick panel title bar
 * @property {SAVE_BEHAVIOR_ENUM} saveBehavior Control if a save action should save and reload the whole page or just save
 * the quick panel record but not reload the page
 * @property {boolean} sectionsCollapsed Set this to true to display a record in the quick panel with all sections being
 * @property {AUTO_VALIDATION_MODE_ENUM} set this to control the initial validation triggered in a quick edit form when it
 * opens for the first time.
 */

export default class QuickPanel extends BaseQuickPanel {
  constructor(props) {
    super(props);

    this.setStateSafely({
      showingState: StateEnum.CLOSED,
    });

    // Refs that will be set later once they're rendered
    this.borderRef = null;

    this.expandWidth = localStorage.getItem(EXPAND_WIDTH_TOKEN);
    this.originalWidth = this.props.originalWidth || MINIMUM_WIDTH;
    this.expandWidth = (!this.expandWidth || this.expandWidth === "NaN" || this.expandWidth < this.originalWidth || this.expandWidth > $(window).width())
      ? this.originalWidth.toString()
      : this.expandWidth;
  }

  getMsToShowLoadingIndicator() {
    let msToShowLoadingIndicator = 200;
    if (this.state.showingState !== StateEnum.OPEN) {
      msToShowLoadingIndicator += ANIMATION_TIME;
    }
    return msToShowLoadingIndicator;
  }

  handleExpandOrCollapseEditPanel() {
    if (this.state.showingState === StateEnum.CLOSED) {
      this.expandToOpen();
    } else {
      this.collapse();
    }
  }

  handleCollapseEditPanel() {
    this.collapse();
  }

  handleMaximizeOrRestoreEditPanel() {
    if (this.state.showingState === StateEnum.MAXIMIZED) {
      this.expandToOpen();
    } else {
      this.expandToMaximized();
    }
  }

  handleDrag(event) {
    const windowWidth = $(window).width();
    const mousePosition = event.clientX;

    if (mousePosition) {
      const containerWidth = windowWidth - mousePosition;
      // Uncomment for verbose logging
      // console.log(`Window width: ${windowWidth}, Mouse: ${mousePosition}, Container Width: ${containerWidth}`);

      /* Reset the quick edit panel width.
       */
      const containerRef = this.getContainerRef().current;
      if (containerWidth >= MINIMUM_WIDTH) {
        $(containerRef).width(containerWidth - RIGHT_MARGIN_WIDTH);
        $(containerRef).css({left: mousePosition + "px"});
      } else {
        $(containerRef).width(MINIMUM_WIDTH);
        $(containerRef).css({left: (windowWidth - (MINIMUM_WIDTH + RIGHT_MARGIN_WIDTH)) + "px"});
      }
    }
  }

  handleDragStop(event) {
    const windowWidth = $(window).width();
    const mousePosition = event.clientX;
    const containerWidth = Math.max(windowWidth - mousePosition - RIGHT_MARGIN_WIDTH + 2, MINIMUM_WIDTH); // 2 is for the 1px border

    this.expandWidth = containerWidth;
    localStorage.setItem(EXPAND_WIDTH_TOKEN, containerWidth.toString());
    this.setStateSafely({panelSize: containerWidth});
    // If you drag the border off the screen, this brings it back on so you don't lose it.
    setTimeout(() => {
      this.resetLeftBorder();
    }, 500);
  }

  /**
   *  This resets the border correctly from the edge of the container.
   */
  resetLeftBorder() {
    $(this.borderRef).css({"transform": "translate(0px, 0px)"});
  }

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener("resize", this.resizePanel);
    window.quickPanelRendered = true;
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener("resize", this.resizePanel);
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    if (prevProps && this.props.id && this.props.id !== prevProps.id && this.props.versionId !== prevProps.versionId && this.state.showingState === StateEnum.CLOSED) {
      this.expandToOpen();
    }
  }

  expandToOpen() {
    if (this.state.showingState !== StateEnum.OPEN) {
      this.setStateSafely(
        {
          showingState: StateEnum.EXPANDING_TO_OPEN,
          dataSaved: false
        },
        () => {
          const containerRef = this.getContainerRef().current;
          $(containerRef).animate(this.getOpenCSS(),
            ANIMATION_TIME, "swing", () => {
              this.setStateSafely({showingState: StateEnum.OPEN});
            });
        }
      );
    }
  }

  expandToMaximized() {
    this.setStateSafely(
      {
        showingState: StateEnum.EXPANDING_TO_MAXIMIZED,
        dataSaved: false
      },
      () => {
        const containerRef = this.getContainerRef().current;
        $(containerRef).animate(this.getMaximizedCSS(),
          ANIMATION_TIME, "swing", () => {
            this.setStateSafely({showingState: StateEnum.MAXIMIZED});
          });
      });
  }

  collapse() {
    this.setStateSafely({
      showingState: StateEnum.COLLAPSING,
      dataSaved: false
    }, () => {

      const containerRef = this.getContainerRef().current;
      $(containerRef).animate(this.getClosedCSS(),
        ANIMATION_TIME, "swing", () => {
          this.setStateSafely({showingState: StateEnum.CLOSED});
        });
    });
  }

  resizePanel() {
    const containerRef = this.getContainerRef().current;
    if (this.state.showingState === StateEnum.OPEN) {
      $(containerRef).css(this.getOpenCSS());
    } else if (this.state.showingState === StateEnum.MAXIMIZED) {
      $(containerRef).css(this.getMaximizedCSS());
    } else if (this.state.showingState === StateEnum.CLOSED) {
      $(containerRef).css(this.getClosedCSS());
    }
  }

  getOpenCSS() {
    const windowWidth = $(window).width();
    const windowHeight = $(window).height();

    return {
      "border-bottom-left-radius": "0px",
      height: (windowHeight - 150) + "px",
      width: this.expandWidth + "px",
      left: (windowWidth - this.expandWidth - RIGHT_MARGIN_WIDTH) + "px",
      top: "150px",
      right: RIGHT_MARGIN_WIDTH + "px",
      bottom: "0px",
    };
  }

  getMaximizedCSS() {
    const windowWidth = $(window).width();
    const windowHeight = $(window).height();

    return {
      "border-bottom-left-radius": "0px",
      height: (windowHeight - 100) + "px",
      width: (windowWidth - 100) + "px",
      left: "50px",
      top: "50px",
      right: "50px",
      bottom: "50px",
    };
  }

  getClosedCSS() {
    const windowWidth = $(window).width();

    return {
      "border-bottom-left-radius": "20px",
      height: "46px",
      width: COLLAPSED_WIDTH + "px",
      left: (windowWidth - COLLAPSED_WIDTH) + "px",
      top: "150px",
      right: "0px",
      bottom: "190px",
    };
  }

  render() {
    let editorOperation = this.getEditorOperation();
    let {showingState} = this.state;

    return (
      <div id="quickPanelContainer"
           className={`quick-panel-outer-container quick-panel-state-${showingState.toLowerCase()}`}
           ref={this.containerRef}
      >

        <div className="quick-panel-header">
          <div className="quick-panel-header-text"
               onClick={this.handleExpandOrCollapseEditPanel}
          >
            <img className="quick-panel-header-icon"
                 src={quickPanelIconLarge}
                 aria-label="Click to open the quick panel"
                 alt="Click to open the quick panel"
            />
            {showingState === StateEnum.CLOSED ? "" :
              editorOperation === EDITOR_OPERATIONS.EDIT ? "Quick Edit" :
                editorOperation === EDITOR_OPERATIONS.ADD ? "Quick Add" : "Quick View"}
          </div>
          {showingState !== StateEnum.CLOSED ? (
            <div className="quick-panel-header-right-icons">
              <FontAwesomeIcon id="quickPanelMinimizeWindow"
                               className="quick-panel-header-icon"
                               icon={faWindowMinimize}
                               onClick={this.handleCollapseEditPanel}
                               aria-label="Click to minimize the quick edit panel"
              />
              <FontAwesomeIcon id={showingState !== StateEnum.MAXIMIZED ? "quickPanelMaximizeWindow" : "quickPanelRestoreWindow"}
                               className="quick-panel-header-icon"
                               icon={showingState !== StateEnum.MAXIMIZED ? faWindowMaximize : faWindowRestore}
                               onClick={this.handleMaximizeOrRestoreEditPanel}
                               aria-label="Click to maximize the quick edit panel"
              />
            </div>
          ) : ""}
        </div>

        <div id="quickPanelOpenContainer" className={showingState === StateEnum.CLOSED ? "d-none" : "d-block"}>
          <Draggable axis="x"
                     position={{x: 0, y: 0}}
                     onDrag={this.handleDrag}
                     onStop={this.handleDragStop}
          >
            <div className="quick-panel-left-border"
                 ref={thisDiv => this.borderRef = thisDiv}
            >
              <div id="quickPanelGripDiv">
                <FontAwesomeIcon id="quickPanelGrip"
                                 icon={faGripLinesVertical}
                                 aria-label="Drag and drop to resize the quick edit panel"
                />
              </div>
            </div>
          </Draggable>
          <div id="quickPanelInnerContainer" className={`quick-panel-inner-container container-fluid quick-panel-state-${showingState.toLowerCase()}`}>
            {this.renderPanelContent()}
          </div>
        </div>
      </div>
    );
  }
}
