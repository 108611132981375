"use strict";

import React from "react";
import BasePageTitleBar from "./base_page_title_bar";

/* This shows the title bar for the admin portal */
export default class AdminPageTitleBar extends BasePageTitleBar {
  constructor(props) {
    super(props);
  }

  renderTitleBar() {
    const {name} = this.props;

    return (
      <div className={"page-title-bar-title"}
           id="pageTitleBar">
        <span className="page-title-bar-breadcrumbs">
                <a href="/admin/internal/listOfUsers.html?activeTab=Companies"
                   id="adminDashboardLinkInTitleBar">&larr;</a>
        </span>
        <span className="page-title-bar-primary"
              id="pageTitleBarPrimary">
              {name}
        </span>
      </div>
    );
  }
}
