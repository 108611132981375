"use strict";

import { orderAndIndexUnits, orderAndIndexSteps } from "../../../processExplorer/indexers/uo_indexer";
import * as CommonRiskTable from "./risk_tables_helper";


/**
 * This is required to sort UO records to come to the end of the list.
 * We could not make this part of the original sorting function as we need to have different sorting strategies then move all UO records to the end.
 * @param firstElement
 * @param secondElement
 * @returns {number}
 */
export function sortUnitOperationRecordsToTheEnd(i1, i2) {
  return (!i1.stepId) ? 1 : (!i2.stepId) ? -1 : 0;
}

/**
 * Returns the sort priority for a row, based on its model type and the report settings
 * @param reportSettings {*} The settings for this report
 * @param modelType {*} The type of the current model.
 * @return {number} A number that represents the sorting priority.
 */
export function getRowSortPriorityForModelType(reportSettings, modelType) {
  const priorities = reportSettings.rowSortPriority || [];
  return priorities.indexOf(modelType);
}

export function getSortPriority(reportOptions, modelType) {
  return getRowSortPriorityForModelType(reportOptions, modelType);
}

/**
 * We sort based on 7 factors:
 *
 * 1) The priority of the row model type (for instance: IQA should appear before FQA)
 * 2) The step of the source model (column)
 * 3) The name of the target model (row)
 * 4) The id of the target model (row)
 * 5) The priority of the column model type (for instance: IQA should appear before FQA)
 * 6) The name of the source model (column)
 * 7) The id of the source model (column)
 *
 * This ensures the items appear
 */
export function sortInstances(i1, i2, reportOptions) {
  let sortResult = getSortPriority(reportOptions, i1.targetModelType) - getSortPriority(reportOptions, i2.targetModelType);

  if (sortResult === 0) {
    if (i1.stepId && i2.stepId) {
      sortResult = i1.stepId - i2.stepId;
    }
  }

  if (sortResult === 0) {
    const textA = (i1.targetModel || "").toUpperCase();
    const textB = (i2.targetModel || "").toUpperCase();
    sortResult = (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  }

  if (sortResult === 0) {
    sortResult = i1.targetModelId - i2.targetModelId;
  }

  if (sortResult === 0) {
    sortResult = getSortPriority(reportOptions, i1.sourceModelType) - getSortPriority(reportOptions, i2.sourceModelType);
  }

  if (sortResult === 0) {
    const textA = i1.sourceModel.toUpperCase();
    const textB = i2.sourceModel.toUpperCase();
    sortResult = (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  }

  if (sortResult === 0) {
    sortResult = i1.sourceModelId - i2.sourceModelId;
  }

  return sortResult;
}

export function sortObjectLocationsByPreviousId(instances, steps, unitOperations) {

  let locationIds = [];

  // Make sure unitOperations are ordered.
  let orderedUnitOperations = orderAndIndexUnits(unitOperations);
  orderedUnitOperations.forEach(uo => {

    // Ensure steps are ordered per each unitOperation
    let orderedSteps = orderAndIndexSteps(
      steps.filter(step => step.UnitOperationId === uo.id)
    );

    locationIds.push(`${uo.id}`);
    if (orderedSteps.length > 0) {
      orderedSteps
        .map(step => `${CommonRiskTable.getLocationId(uo.id, step.id)}`)
        .forEach(locationId => locationIds.push(locationId));
    }

  });

  // Build locationId and set in the instances to use it for datatables.net sort.
  instances.forEach(instance => instance.locationId = CommonRiskTable.getLocationId(instance.unitOperationId, instance.stepId));

  // Sort instances based on their location in UO/Step.
  instances.sort((obj1, obj2) => {
    return locationIds.indexOf(obj1.locationId) - locationIds.indexOf(obj2.locationId);
  });

  // Give numeric sortOrder value for to use it in datatables table.
  instances.forEach((instance, index) => instance.sortOrder = ++index);

  return instances;
}