"use strict";

import * as UIUtils from "./ui_utils";
import React, { Fragment } from "react";
import ReactDOMServer from "react-dom/server";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faPen } from "@fortawesome/free-solid-svg-icons";
import { getURLByTypeCodeAndId, getURLByTypeCodeAndIdAndVersionId } from "./helpers/url_helper";
import Cookies from "js-cookie";
import ApprovalRequestPopup from "./editor/approval/approval_request_popup";
import CopyRecordPopup from "./widgets/cloning/copy_record_popup";
import { TABLE_COLUMN_INDEX } from "./helpers/constants/constants";
import { Trans } from "react-i18next";
import OptionsMenuBuilder from "./editor/options_menu_builder";
import TableButton from "./widgets/tables/table_button";
import TableOptionsButton from "./widgets/tables/table_options_button";
import { CLONING_PLUGINS } from "./widgets/cloning/copy_record_popup_plugins/plugins_config";
import { EditablesService } from "./services/editables/editables_service";
import { SHOW_REMOVED } from "./utils/filter_helper";
import * as CommonUtils from "../server/common/generic/common_utils";
import CommonConstants from "../server/common/generic/common_constants";
import BaseReactComponent from "./base_react_component";
import { getProjectFromCache } from "./utils/project_helper";
import TypeaheadObjectCache from "./utils/cache/typeahead_object_cache";
import * as FailHandlers from "./utils/fail_handlers";
import {
  getApprovalValue,
  handleApprovalChangeValue,
  handleApprovalChangeValues
} from "./editor/approval/approval_utils";

/* This is the base class for tables that show editables. */
// i18next-extract-mark-ns-start base_page
export default class BaseEditableListTable extends BaseReactComponent {
  constructor(props, capitalizedBaseTypeName, showRemoveBtn = true, enablePaging = true) {
    super(props);

    this.handleApprovalChangeValue = handleApprovalChangeValue.bind(this);
    this.handleApprovalChangeValues = handleApprovalChangeValues.bind(this);
    this.getApprovalValue = getApprovalValue.bind(this);

    UIUtils.setLoadingDisabled(true); // Use the skeleton loader.
    this.initialize({}, {capitalizedBaseTypeName, showRemoveBtn, enablePaging});

    this.tableInitCompleted = false;

    if (!this.isListingTopLevel()) {
      new TypeaheadObjectCache("Project").loadOptions(this.handleTypeaheadResultsFromServer);
    }

    // noinspection JSIgnoredPromiseFromCall
    if (!this.hasCachedData()) {
      this.getDataFromServer();
    }

    this.columns = this.generateColumns(props);
  }

  // eslint-disable-next-line no-unused-vars
  generateColumns(props) {
    return [];
  }

  hasCachedData() {
    return this.props.cachedInstances;
  }

  /**
   * Runs initialization logic for the table (this is called when a pushState navigation occurs)
   * @param additionalState {*} Set this on an override or when calling to set more data to the state in a single call.
   * @param initializeOptions {IEditorInitializeOptions} The options for the initialization
   */
  initialize(additionalState = {}, initializeOptions = {}) {
    const {capitalizedBaseTypeName, enablePaging, showRemoveBtn} = initializeOptions || {};
    this.capitalizedBaseTypeName = capitalizedBaseTypeName || this.props.capitalizedBaseTypeName;

    this.baseTypeCode = this.capitalizedBaseTypeName ? UIUtils.getTypeCodeForModelName(this.capitalizedBaseTypeName) : "";
    let showRemoved = Cookies.get(SHOW_REMOVED + "_" + this.baseTypeCode) === "true";
    this._securityTypeName = UIUtils.convertCamelCaseToSpacedOutWords(this.capitalizedBaseTypeName);

    const state = {
      ...(additionalState || {}),
      showApprovalPopup: false,
      showCopyPopup: false,
      showRemoved,
      approvalInfo: {},
    };

    this.setStateSafely(state);

    this.showRemoveBtn = !!showRemoveBtn;
    this.enablePaging = !!enablePaging;
    this.activeFilter = null;
  }

  getInstances(props = this.props, state = this.state) {
    return props.cachedInstances || state.instances;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {instances, showApprovalPopup, showCopyPopup, showRemoved, action, message, warning} = this.state;
    const {
      cachedInstances,
      hideApprovedLabel,
      projectId,
      allowRecordCopy,
      topLevel,
      showArchivedText,
      filter,
      showAsOfDate,
      parent,
    } = this.props;

    const nextInstances = nextState.instances;
    const nextShowApprovalPopup = nextState.showApprovalPopup;
    const nextShowCopyPopup = nextState.showCopyPopup;
    const nextShowRemoved = nextState.showRemoved;
    const nextAction = nextState.action;
    const nextMessage = nextState.message;
    const nextWarning = nextState.warning;

    const nextCachedInstances = nextProps.cachedInstances;
    const nextHideApprovedLabel = nextProps.hideApprovedLabel;
    const nextProjectId = nextProps.projectId;
    const nextAllowRecordCopy = nextProps.allowRecordCopy;
    const nextTopLevel = nextProps.topLevel;
    const nextShowArchivedText = nextProps.showArchivedText;
    const nextFilter = nextProps.filter;
    const nextShowAsOfDate = nextProps.showAsOfDate;

    return !showApprovalPopup && !showCopyPopup &&
      ((parent && !parent.isView || (parent && parent.isView()))
        || (!instances && nextInstances)
        || (instances &&
          nextInstances
          && JSON.stringify(instances) !== JSON.stringify(nextInstances))
        || (!cachedInstances && nextCachedInstances)
        || (cachedInstances &&
          nextCachedInstances
          && JSON.stringify(cachedInstances) !== JSON.stringify(nextCachedInstances))
        || showApprovalPopup !== nextShowApprovalPopup
        || showCopyPopup !== nextShowCopyPopup
        || showRemoved !== nextShowRemoved
        || action !== nextAction
        || message !== nextMessage
        || warning !== nextWarning
        || hideApprovedLabel !== nextHideApprovedLabel
        || projectId !== nextProjectId
        || allowRecordCopy !== nextAllowRecordCopy
        || topLevel !== nextTopLevel
        || showArchivedText !== nextShowArchivedText
        || filter !== nextFilter
        || showAsOfDate !== nextShowAsOfDate);
  }

  get securityTypeName() {
    return this._securityTypeName;
  }

  handleTypeaheadResultsFromServer() {
    this.forceUpdateSafely();
  }

  getURLForObject(item, action, approved = undefined) {
    let url = item.isFilteredLatestVersion
      ? getURLByTypeCodeAndIdAndVersionId(this.baseTypeCode, action, item.id, false, item.LastVersionId)
      : getURLByTypeCodeAndId(this.baseTypeCode, action, item.id, approved);

    if (this.props.projectId) {
      url += "&projectId=" + this.props.projectId;
    }

    return url;
  }

  generateIDColumn(action = "View") {
    const {t, customId} = this.props;

    return {
      title: t("ID"),
      width: customId ? 50 : 10,
      containsHTML: true,
      data: this.createMappedCell((result, type) => {
        result = {...result, typeCode: result.typeCode || this.baseTypeCode};
        if (type === "display") {
          return UIUtils.getRecordCustomIdForDisplay(result);
        } else {
          // For sorting / filtering
          return UIUtils.getRecordCustomIdForSorting(result);
        }
      }),
      createdCell: (td, cellData, rowData) => {
        const translatedTypeCode = t(this.baseTypeCode);
        let typeCode = rowData.typeCode || this.baseTypeCode;
        const isTypeCodeTranslated = translatedTypeCode !== typeCode;

        rowData = {...rowData, typeCode: translatedTypeCode};

        const url = this.getURLForObject(rowData, action);
        ReactDOM.render(
          (
            <div>
              <a href={url}>
                {<Fragment>{UIUtils.getRecordCustomIdForDisplay(rowData, {isTypeCodeTranslated})}</Fragment>}
              </a>
            </div>
          ), td);
      },
    };
  }

  generateNameColumn(action = "View", width = 300) {
    const {t} = this.props;

    return {
      title: t("Name"),
      width: width,
      containsHTML: true,
      data: this.createMappedCell((result) => {
        const url = this.getURLForObject(result, action);

        return ReactDOMServer.renderToStaticMarkup(
          <div>
            <a href={url}>
              {result.name}
              {(result.currentState === UIUtils.VERSION_STATES.APPROVED || result.isApprovedVersion) && !this.props.hideApprovedLabel ? (
                <sup className="results-table-approved-flag"> {t("approved")}</sup>
              ) : ""}
            </a>
          </div>,
        );
      }),
    };
  }

  generateManageColumn(width = 1) {
    const {t} = this.props;

    return {
      width: width,
      containsHTML: true,
      data: this.createMappedCell((result) => {
        let url = this.getURLForObject(result, "Edit");
        return ReactDOMServer.renderToStaticMarkup(
          <div className="table-manage">
            <a href={url}
               title={t("Edit")}
            >
              <FontAwesomeIcon icon={faPen} />
            </a>
          </div>,
        );
      }),
    };
  }

  generateColumnDefsArray() {
    return [
      {className: "results-table-column-id", targets: [0]},
      this.generateManageColumnDef(2),
    ];
  }

  generateManageColumnDef(targetColumn) {
    const {t} = this.props;
    // noinspection JSUnusedGlobalSymbols
    return {
      targets: targetColumn,
      orderable: false,
      createdCell: this.createMappedColumnDef((td, cellData, rowData, row) => {

        let thisProject;
        const isListingTopLevel = this.isListingTopLevel();
        if (!isListingTopLevel) {
          thisProject = getProjectFromCache(this.props.projectId);
        }

        const optionsMenuBuilder = new OptionsMenuBuilder(rowData, this.securityTypeName, thisProject, null, this.props.onCheckPermissions, this.props.t);
        let editUrl = this.getURLForObject(rowData, "Edit", false);

        optionsMenuBuilder.addView({
          id: "viewButton_" + row,
          title: t("View {{ baseTypeName }} Details", {baseTypeName: t(UIUtils.convertCamelCaseToSpacedOutWords(this.capitalizedBaseTypeName))}),
          url: this.getURLForObject(rowData, "View"),
        });

        optionsMenuBuilder.addEdit({
          id: "editButton_" + row,
          url: editUrl,
        });

        if (this.showRemoveBtn) {
          optionsMenuBuilder.addArchive({
            id: "archiveButton_" + row,
            onClick: this.handleArchiveButton.bind(this, rowData),
          });
        }

        optionsMenuBuilder.addRestore({
          id: "restoreButton_" + row,
          onClick: this.handleRestoreButton.bind(this, rowData),
        });

        if (this.props.allowRecordCopy) {
          optionsMenuBuilder.addSeparator();
          optionsMenuBuilder.addCopy({
            id: "copyButton_" + row,
            tooltipIfAccessAllowed: t("Copy {{ securityName }}", {securityName: t(this.securityTypeName)}),
            onClick: this.handleCopyButtonClick.bind(this, rowData),
            disabled: rowData.currentState === "Archived",
          });
        }

        ReactDOM.render(
          (
            <div className="table-manage">
              {this.getManageColumnControls(optionsMenuBuilder, rowData, row)}
            </div>
          ), td);
      }),
    };
  }

  getManageColumnControls(optionsMenuBuilder, rowData, row) {
    const {t} = this.props;
    let controls = [];

    controls.push(
      <TableOptionsButton key={"optionsButton_" + row}
                          id={"optionsButton_" + row}
                          alignRight={true}
                          options={optionsMenuBuilder.build()}
      />);
    controls.push(
      <TableButton key={"viewButton_" + row}
                   id={"viewButton_" + row}
                   href={this.getURLForObject(rowData, "View")}
                   className="table-icon"
                   title={t("View {{ baseTypeName }} Details", {baseTypeName: t(UIUtils.convertCamelCaseToSpacedOutWords(this.capitalizedBaseTypeName))})}
                   fontAwesomeIcon={faChevronRight}
      />);

    return controls;
  }

  /**
   * @returns {boolean} true if we're listing a top level item that doesn't belong to a project.
   */
  isListingTopLevel() {
    return this.props.topLevel;
  }

  handleReceiveDataFromServer(results) {
    // Uncomment for verbose logging
    // console.log("Received list results: " + UIUtils.stringify(results));

    if (this.props && this.props.onDataReceived) {
      this.props.onDataReceived(results);
    }

    let instances = results.instances;
    if (results.instances && typeof this.props.onInstanceReceived === "function") {
      results.instances = instances.map(this.props.onInstanceReceived);
    }

    this.setStateSafely({...results, isDataLoading: false});
  }

  /**
   * This is important to keep separate from setting the table results. Once the results (table rows) are set, the table
   * will reload/redraw. Tests will immediately start scanning the table for results once the isLoading indicator goes
   * away and then the redraw will cause webdriver.io to fail with errors like:
   *
   *     Error: stale element reference: element is not attached to the page document
   */
  turnOffLoading() {
    if (this.state.isLoading) {
      setImmediate(() => this.setStateSafely({isLoading: false}));
    }
  }

  handleChangeShowRemoved(event) {
    const {onDataRefreshRequired} = this.props;
    const showRemoved = event.target.checked;
    Cookies.set(SHOW_REMOVED + "_" + this.baseTypeCode, showRemoved);

    this.setStateSafely({showRemoved}, () => {
      const handleArchivedOnClientSide = typeof this.props.onShowRemovedToggle === "function";
      if (handleArchivedOnClientSide) {
        this.props.onShowRemovedToggle(showRemoved);
      } else {
        // noinspection JSIgnoredPromiseFromCall
        if (this.hasCachedData()) {
          onDataRefreshRequired && onDataRefreshRequired(this.state.showRemoved);
        } else {
          this.getDataFromServer();
        }
      }
    });
  }

  getCachedData() {
    return this.props.cachedInstances;
  }

  getDataFromServer(useWriterDB, showRemoved) {
    return new Promise((resolve) => {
      this.setStateSafely({instances: [], isLoading: true, isDataLoading: true});
      if (this.capitalizedBaseTypeName) {
        const handleArchivedOnClientSide = typeof this.props.onShowRemovedToggle === "function";
        showRemoved = typeof showRemoved === "boolean" ? showRemoved : !!(this.state.showRemoved || handleArchivedOnClientSide);

        const urlParameters = {
          showRemoved,
          includeHistory: true,
          loadFullHistory: true,
          shouldCompress: true,
          ...(useWriterDB ? {useWriterDB: true} : {}),
          ...this.getAdditionalRequestData(),
        };

        UIUtils.secureAjaxGET(
          `editables/${this.capitalizedBaseTypeName}/list${this.props.projectId ? "/" + this.props.projectId : ""}`,
          urlParameters,
          true,
          FailHandlers.defaultStopLoadingFailFunction.bind(this),
        ).done((results) => {
          return resolve(results);
        });
      }
    }).then(this.handleReceiveDataFromServer);
  }

  // noinspection JSMethodCanBeStatic
  createdRow(row, data) {
    if (data.deletedAt !== null) {
      $(row).addClass("results-table-row-archived");
    }
  }

  initComplete() {
    const {searchTerm} = this.props;
    const table = $(this.tableRef).DataTable();
    if (searchTerm) {
      table.search(searchTerm);
    }
    const pageInfo = table && table.page.info();
    table.draw((pageInfo && pageInfo.pages > pageInfo.page) ? "full-hold" : "full-reset");

    this.tableInitCompleted = true;
    this.renderShowArchived();

    if (!this.state.isDataLoading) {
      this.turnOffLoading();
    }
  }

  getShowArchivedCheckboxId() {
    return "showArchivedCheckbox" + this.baseTypeCode;
  }

  renderShowArchived() {
    const {t} = this.props;
    const showRemovedDiv = $("#" + this.getShowRemovedId());

    if (this.showArchived() && showRemovedDiv && showRemovedDiv[0]) {
      const disabled = (this.props && this.props.filter && !!this.props.filter.disableShowArchived) || this.isLoading();
      ReactDOM.render(
        <label htmlFor={this.getShowArchivedCheckboxId()}
               className={this.isLoading() ? "skeleton" : ""}
        >
          <input type="checkbox"
                 id={this.getShowArchivedCheckboxId()}
                 defaultChecked={this.state.showRemoved}
                 onChange={this.handleChangeShowRemoved}
                 disabled={disabled}
          />
          <span>
            {this.props.showArchivedText || t("Show Archived")}
          </span>
        </label>
        , showRemovedDiv[0]);
    }
  }

  showArchived() {
    return !this.props.hideShowArchivedButton;
  }

  // Children override this when there's more than one list on the page
  getShowRemovedId() {
    return this.props.id ? `${this.props.id}_datatablesShowRemoved` : "datatablesShowRemoved";
  }

  /**
   * @return Column index of which table to be sorted by
   */
  getOrderByColumnIndex() {
    return TABLE_COLUMN_INDEX.NAME;
  }

  componentDidMount() {
    super.componentDidMount();
    this.initializeDataTables();
  }

  initializeDataTables() {
    this.table = $(this.tableRef).DataTable({
      ...this.getTableInitializationOptions(),
    });
  }

  getTableInitializationOptions() {
    // Make sure that every column that specifically doesn't mention that it allows HTML has a renderer that blocks XSS
    for (const column of this.columns) {
      if (!column.containsHTML) {
        column.render = $.fn.dataTable.render.text();
      }
    }

    const hideTableInfo = this.props.hideShowTableInfo;
    const {t} = this.props;
    const options = {
      language: {
        url: UIUtils.getLanguageUrlForDataTable(this.props.i18n.language),
      },
      dom: `<t<"row dataTables-nav"<"col-auto"l><"#${this.getShowRemovedId()}.col-auto.dataTables-show-removed"><"col">${hideTableInfo ? "" : `<"col-auto"i><"col-auto"p>`}>>`,
      data: [],
      columns: this.columns,
      columnDefs: this.generateColumnDefsArray(),
      stateSave: Object.prototype.hasOwnProperty.call(this.props, "saveState") ? this.props.saveState : true,
      paging: this.enablePaging,
      pageLength: this.props.pageLength ? this.props.pageLength : 10,
      lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, t("All")]],
      stateSaveCallback: function(settings, data) {
        localStorage.setItem(BaseEditableListTable.getStateSaveKey(settings), JSON.stringify(data));
      },
      stateLoadCallback: function(settings) {
        // Load the previous saved state, and if it's null, then initialize the default order
        let previousState = JSON.parse(localStorage.getItem(BaseEditableListTable.getStateSaveKey(settings)));
        if (!previousState) {
          // noinspection JSPotentiallyInvalidUsageOfClassThis
          previousState = {
            time: new Date().getTime(),
            order: [[this.getOrderByColumnIndex(), "asc"]],
          };
        }
        return previousState;
      }.bind(this),
      createdRow: this.createdRow,
      initComplete: this.initComplete,
      deferRender: true,
      scroller: false,
    };

    let filterFunc = this.getFilterFunction();
    if (filterFunc) {
      options.filter = filterFunc;
    }

    if (typeof this.props.fixedOrderedColumn === "number") {
      options.orderFixed = [this.props.fixedOrderedColumn, "asc"];
    }

    return options;
  }

  getFilterFunction(props = this.props) {
    return props.filter ? props.filter.filter : null;
  }

  static getStateSaveKey(settings) {
    return "DataTables_" + settings.sInstance + "_" + window.location.pathname;
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this.tableInitCompleted) {
      try {
        $(this.tableRef).DataTable().destroy(true);
      } catch (e) {
        console.warn("DataTables unmounted poorly:", e);
        // Keep going. An error here will block the user from proceeding even though this table isn't on the screen.
      }
      this.tableInitCompleted = false;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.filter && this.props.filter.key !== this.activeFilter) {
      this.activeFilter = this.props.filter.key;
    }

    if (this.state.isLoading === prevState.isLoading) { // If the isLoading is changing, then don't reload the table, or else the tests will fail randomly.
      this.reloadDataTable(this.getInstances());
    }
    this.renderShowArchived();
  }

  reloadDataTable(rowData) {
    if (this.tableRef && rowData) {
      const table = $(this.tableRef).DataTable();
      const filterFunc = this.getFilterFunction();
      const isFilterFunction = (typeof filterFunc === "function");
      const data = isFilterFunction ? rowData.filter(filterFunc) : rowData;

      table.search("");
      table.clear();
      table.rows.add(this.prepareRecordsForDisplay(data));
      table.draw("full-hold");

      // re-initialization needs to happen after redraw events are completed
      // otherwise, this will fail intermittently
      setTimeout(this.initComplete, 0);
    }
  }

  prepareRecordsForDisplay(data) {
    return data;
  }

  handleArchiveButton(rowData) {
    UIUtils.setLoadingDisabled(false);
    const {t} = this.props;
    let instance = this.getInstances().find(record => record.id === rowData.id);

    if (!instance) {
      throw new TypeError(`Unexpected error: Unable to find an instance for "${this.baseTypeCode}-${rowData.id}" in the current page. Please contact QbdVision support and inform about this error.`);
    }
    instance.typeCode = this.baseTypeCode;

    this.setStateSafely({
      instance: instance,
      action: CommonConstants.ACTIONS.ARCHIVE,
      message:
        <Trans t={t}>
          Archiving will remove {" "}
          <a href={getURLByTypeCodeAndId(this.baseTypeCode, "View", instance.id)} rel="noopener noreferrer"
             target="_blank"
          >
            <b>{UIUtils.getRecordCustomLabelForDisplay(instance)}</b>
          </a>
          {" "} from view, but will not delete it permanently.
        </Trans>,
    }, () => {
      let url = this.getURLToLoadChildObjects(instance.id, this.state.action);
      UIUtils.secureAjaxGET(url).done(this.handleReceivedChildObjectsFromServer);
    });
  }

  handleConfirmation() {
    UIUtils.setLoadingDisabled(false);
    this.setStateSafely({showApprovalPopup: false});
    $(this.approvalPopup).modal("hide");

    let instance = Object.assign({}, this.state.instance);
    instance.approvalInfo = Object.assign({}, this.state.approvalInfo);
    instance.approvalInfo.action = this.state.action;

    UIUtils.setHideLoadingOnAjaxStop(false);

    let parameters = {
      model: this.capitalizedBaseTypeName,
      global: true,
      urlPrefix: CommonUtils.pluralize(this.capitalizedBaseTypeName),
      useTwoWayCommunication: true,
    };

    const editablesService = new EditablesService();
    return editablesService.save(instance, parameters)
      .then(this.handleApprovalConfirmationFromServer)
      .catch(this.failCallback)
      .finally(() => {
        UIUtils.setLoadingDisabled(true);
      });
  }

  failCallback(results) {
    UIUtils.setHideLoadingOnAjaxStop(true);
    UIUtils.defaultFailFunction(results);
    UIUtils.hideLoadingImage();
  }

  handleCopyCompletion() {
    const {onDataRefreshRequired} = this.props;
    $(this.copyRecordPopup).modal("hide");

    let showRemoved = Cookies.get(SHOW_REMOVED + "_" + this.baseTypeCode) === "true";
    // noinspection JSIgnoredPromiseFromCall
    if (this.hasCachedData()) {
      onDataRefreshRequired && onDataRefreshRequired();
    } else {
      this.getDataFromServer(true, showRemoved);
    }

    this.setStateSafely({showCopyPopup: false});
  }

  handleRestoreButton(rowData) {
    UIUtils.setLoadingDisabled(false);
    let instance = this.getInstances().find(record => record.id === rowData.id);
    this.setStateSafely({
      instance: instance,
      showApprovalPopup: true,
      approvalInfo: null,
      action: CommonConstants.ACTIONS.RESTORE,
    }, () => {
      let url = this.getURLToLoadChildObjects(instance.id);
      UIUtils.secureAjaxGET(url).done(this.handleReceivedChildObjectsFromServer);
    });
  }

  handleReceivedChildObjectsFromServer(results) {
    const {t} = this.props;

    this.setStateSafely({
      showApprovalPopup: true,
      approvalInfo: null,
      warning: results.length > 0 ?
        (<div>
          <div>
            {this.state.action === CommonConstants.ACTIONS.ARCHIVE ? t("Archiving will also remove the following child objects:") : t("Restoring will also restore the following child objects:")}
          </div>
          <br />
          {results.map(result => {
            return (
              <div key={result.typeCode + "-" + result.id}>
                <a href={getURLByTypeCodeAndId(result.typeCode, "View", result.id)}
                   rel="noopener noreferrer"
                   target="_blank"
                ><b>{UIUtils.getRecordCustomIdForDisplay(result)}</b></a>
              </div>
            );
          })}
        </div>) : null,
    });
    UIUtils.setLoadingDisabled(true);
  }

  handleCopyButtonClick(rowData) {
    UIUtils.setLoadingDisabled(false);
    let instance = this.getInstances().find(record => record.id === rowData.id);
    this.setStateSafely({
      instance: instance,
    }, () => {
      let url = "editables/" + this.capitalizedBaseTypeName + "/" + this.state.instance.id;
      UIUtils.secureAjaxGET(url, {approved: false}).done(this.handleObjectToCopyLoadedFromServer);
    });
  }

  handleObjectToCopyLoadedFromServer(objectToCopy) {
    this.setStateSafely({
      showCopyPopup: true,
      objectToCopy: objectToCopy,
    });
    UIUtils.setLoadingDisabled(true);
  }

  getURLToLoadChildObjects(instanceId) {
    return "editables/" + this.capitalizedBaseTypeName + "/" + instanceId + "?includeHistory=false&approved=false&childrenOnly=" + this.state.action;
  }

  handleRestoreConfirmation() {
    // noinspection JSIgnoredPromiseFromCall
    this.handleConfirmation(CommonConstants.ACTIONS.RESTORE);
  }

  handleApprovalConfirmationFromServer() {
    const {onDataRefreshRequired} = this.props;
    UIUtils.setHideLoadingOnAjaxStop(true);

    let showRemoved = Cookies.get(SHOW_REMOVED + "_" + this.baseTypeCode) === "true";
    // noinspection JSIgnoredPromiseFromCall
    if (this.hasCachedData()) {
      onDataRefreshRequired && onDataRefreshRequired();
    } else {
      this.getDataFromServer(true, showRemoved);
    }
  }

  handleHideApprovalPopup() {
    this.setStateSafely({showApprovalPopup: false});
  }

  handleHideCopyButtonClick() {
    this.setStateSafely({showCopyPopup: false});
  }

  /**
   * Higher order function that allows the creation of a column that can display different values based on
   * the current filter's map function
   * @param dataFunction {function(*, ...[*]): *} The function to actually process the cell data
   * @return {function(...[*]): *}
   */
  createMappedCell(dataFunction) {
    return (item, ...args) => {
      return this.props.filter && typeof this.props.filter.map === "function"
        ? dataFunction.bind(this)(this.props.filter.map(item), ...args)
        : dataFunction.bind(this)(item, ...args);
    };
  }

  /**
   * Higher order function that allows the creation of a column definition that can display different values based on
   * the current filter's map function
   * @param dataFunction {function(*, *, *, *): *} The function to actually process the cell data
   * @return {function(*, *, *, *): *}
   */
  createMappedColumnDef(dataFunction) {
    return (td, cellData, rowData, row) => {
      return this.props.filter && typeof this.props.filter.map === "function"
        ? dataFunction.bind(this)(td, cellData, this.props.filter.map(rowData), row)
        : dataFunction.bind(this)(td, cellData, rowData, row);
    };
  }

  /**
   * Override this to return additional url parameters for the requests
   */
  getAdditionalRequestData() {
    return this.props.additionalRequestData || {};
  }

  getAdditiveSkeletonClass() {
    return "skeleton-table";
  }

  render() {
    const {id, readOnly} = this.props;

    let className = "table table-bordered table-hover " + this.getClassForLoading();
    if (readOnly) {
      className += " table-read-only";
    }

    return (
      <div>
        <table ref={ref => this.tableRef = ref}
               className={className}
               id={id || "resultsTable"} style={{width: "100%"}}
        />
        {this.renderApprovalPopup()}
        {this.renderCopyPopup()}
      </div>
    );
  }

  renderApprovalPopup() {
    return <div>
      {this.state.showApprovalPopup ? (
        <ApprovalRequestPopup modalRef={approvalPopup => this.approvalPopup = approvalPopup}
                              action={this.state.action}
                              message={this.state.message}
                              warning={this.state.warning}
                              onHideModal={this.handleHideApprovalPopup}
                              onSendApprovalRequest={this.handleConfirmation}
                              parent={this}
        />
      ) : ""}
    </div>;
  }

  renderCopyPopup() {
    let plugins = [];

    if (this.capitalizedBaseTypeName === "FQA") {
      plugins.push(CLONING_PLUGINS.COPY_TO_IQA);
      plugins.push(CLONING_PLUGINS.COPY_TO_NEW_UO);
    } else if (this.capitalizedBaseTypeName === "FPA") {
      plugins.push(CLONING_PLUGINS.COPY_TO_IPA);
      plugins.push(CLONING_PLUGINS.COPY_TO_NEW_UO);
    } else if (this.capitalizedBaseTypeName === "IQA" || this.capitalizedBaseTypeName === "IPA") {
      plugins.push(CLONING_PLUGINS.COPY_TO_NEW_UO);
    }

    return <div>
      {this.state.showCopyPopup ? (
        <CopyRecordPopup modalRef={copyRecordPopup => this.copyRecordPopup = copyRecordPopup}
                         onHideModal={this.handleHideCopyButtonClick}
                         onCopyCompletion={this.handleCopyCompletion}
                         parent={this}
                         showAsOfDate={this.props.showAsOfDate}
                         objectToCopy={this.state.objectToCopy}
                         plugins={plugins}
        />
      ) : ""}
    </div>;
  }
}
// i18next-extract-mark-ns-stop base_page
