"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import TechTransferReviewEditor from "./tech_transfer_review_editor";
import { EditablesService } from "../../services/editables/editables_service";
import ImplementationNeededError from "../../utils/implementation_needed_error";
import { can, generateTooltip } from "../../utils/ui_permissions";
import BaseReactComponent from "../../base_react_component";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { faArrowCircleRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import ConfirmationPopup from "../../widgets/generic/confirmation_popup";
import ErrorBar from "../../widgets/bars/error_bar";
import { formatDate } from "../../configurableTables/fieldsConfig/fields_formatter";

// noinspection NpmUsedModulesInstalled
import * as PropTypes from "prop-types";
import CommonSecurity from "../../../server/common/generic/common_security";
import CommonRiskUtils from "../../../server/common/misc/common_risk_utils";

/**
 * This class is responsible for showing the review screen that has both the
 * diff rows and the tech transfer editor section that shows in the split screen.
 */
export default class TechTransferReviewBase extends BaseReactComponent {

  constructor(props) {
    super(props);
    this.setStateSafely({
      showIgnoreChangesConfirmation: false,
      areChangesIgnored: false,
      dataModified: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.reviewRecord.id !== prevProps.reviewRecord.id) {
      this.setStateSafely({
        showIgnoreChangesConfirmation: false,
        areChangesIgnored: false,
        dataModified: false,
      });
    }
  }
  handleTechTransferInfoUpdated(techTransferRecord) {
    this.setStateSafely({techTransferRecord, dataModified: true,});
  }

  handleSave() {
    const {reviewRecord, projectId, onTechTransferSave, effectiveTechTransferRMP} = this.props;
    const {maxImpact, maxUncertainty, maxDetectabilityRisk, minImpact} = effectiveTechTransferRMP.boundaries;
    const transferredTo = reviewRecord;
    const transferredFrom = reviewRecord.techTransferredFrom || {};
    const {id, processId, LastVersionId, name, UnitOperations,} = transferredTo;
    const {techTransferRecord} = this.state;

    let {techTransferImpact, techTransferUncertainty, techTransferDetectability} = techTransferRecord || {};
    techTransferImpact = techTransferImpact || reviewRecord.techTransferImpact;
    techTransferUncertainty = techTransferUncertainty || reviewRecord.techTransferUncertainty;
    techTransferDetectability = techTransferDetectability || reviewRecord.techTransferDetectability;
    const isNotAssessedAllowed = CommonRiskUtils.scoreIsNotAssessed(minImpact);

    const objectToSave = {
      ...techTransferRecord,
      id,
      name,
      projectId,
      processId,
      LastVersionId,
      UnitOperations,
      techTransferImpact: isNotAssessedAllowed ? techTransferImpact : UIUtils.parseInt(techTransferImpact || maxImpact),
      techTransferUncertainty: isNotAssessedAllowed ? techTransferUncertainty : UIUtils.parseInt(techTransferUncertainty || maxUncertainty),
      techTransferDetectability: isNotAssessedAllowed ? techTransferDetectability : UIUtils.parseInt(techTransferDetectability || maxDetectabilityRisk),
    };

    let parameters = {
      model: transferredFrom.modelName,
      global: true,
      urlPrefix: UIUtils.pluralize(transferredFrom.modelName),
      useTwoWayCommunication: true,
    };

    return new EditablesService()
      .save(objectToSave, parameters)
      .then((result) => {
        onTechTransferSave(transferredFrom.id, transferredTo.id, {
          ...objectToSave,
          LastVersionId: result.LastVersionId,
        });
      })
      .catch(result => UIUtils.defaultFailFunction(result, null, null, "#techTransferReviewError"));
  }

  handleHideUnchangedFields(checked) {
    this.setStateSafely({
      hideUnchangedFields: checked,
    });
  }

  renderDiff() {
    throw new ImplementationNeededError();
  }

  showIgnoreChangesConfirmation(event) {
    UIUtils.ignoreHandler(event);
    this.setStateSafely({showIgnoreChangesConfirmation: true,});
  }

  handleHideIgnoreChangesConfirmation() {
    this.setStateSafely({showIgnoreChangesConfirmation: false,});
  }

  handleIgnoreChangesConfirmation() {
    const {reviewRecord, projectId, onIgnoreChanges} = this.props;
    const transferredTo = reviewRecord;
    const transferredFrom = reviewRecord.techTransferredFrom || {};
    const ignoredChangesInfo = {
      projectId,
      modelName: transferredFrom.typeCode,
      sendingRecordId: transferredFrom.id,
      receivingRecordId: transferredTo.id,
      sendingVersion: transferredFrom.version,
      receivingVersion: transferredTo.version,
    };

    UIUtils.secureAjaxPUT(
      `techTransfer/ignoreChanges`, ignoredChangesInfo
    ).done(() => {
      $(this.ignoreChangesConfirmationPopup).modal("hide");

      this.setStateSafely({
        showIgnoreChangesConfirmation: false,
        areChangesIgnored: true,
      }, () => onIgnoreChanges(ignoredChangesInfo));
    });
  }

  renderChangeRelatedTab(transferredFromUrl, transferredToUrl) {
    const {reviewRecord, parent, onViewChangeCriteria} = this.props;
    const {loggedInUser} = parent.state;
    const {fullName} = loggedInUser;
    const {totalChangesCount, totalDiffsCount, } = reviewRecord;

    const transferredTo = reviewRecord;
    const transferredFrom = reviewRecord.techTransferredFrom || {};
    const ignoredChangesInformation = this.state.areChangesIgnored ?
      null : (transferredTo.changes || transferredFrom.changes);

    const areChangesIgnored = this.state.areChangesIgnored ||
      (ignoredChangesInformation &&
        ignoredChangesInformation.sendingVersion === transferredFrom.version &&
        ignoredChangesInformation.receivingVersion === transferredTo.version);

    const user = areChangesIgnored && ignoredChangesInformation ? ignoredChangesInformation.user : fullName;
    const date = areChangesIgnored && ignoredChangesInformation ?
      formatDate(ignoredChangesInformation.createdAt) :
      formatDate(null, null, true);

    let message;
    if (areChangesIgnored) {
      message =
        <Fragment>
          <span className="dot mr-1" />
          <span>
            {`${totalChangesCount} changes have been ignored.`}
          </span>
        </Fragment>;
    } else if (totalChangesCount > 0) {
      message =
        <Fragment>
          <span className="dot mr-1" />
          <span>
            {`${totalChangesCount} ${UIUtils.pluralize("change", totalChangesCount)}`}
          </span>
        </Fragment>;
    } else if (totalDiffsCount > 0) {
      message = `${totalDiffsCount} ${UIUtils.pluralize("difference", totalDiffsCount)}, none evaluated in change criteria`;
    }

    return (
      <div className="tech-transfer-review-screen-ignore-changes-container">
        <div className="d-flex align-items-center">
          <div>
            <span id="changesSummary">{message}</span>
            {areChangesIgnored ?
              <InfoTooltip id="infoTechTransferAssessmentIgnoreChanges"
                           verbiage={
                             <div>
                               The changes between
                               <br />
                               {transferredFromUrl} ({transferredFrom.version}) and
                               <br />
                               {transferredToUrl} ({transferredTo.version})
                               <br />
                               have been ignored <br />
                               by {user} on {date}
                             </div>
                           }
              /> : ""}
          </div>
          {totalChangesCount > 0 && !areChangesIgnored ?
            <div>
              <a id="ignoreChangesButton"
                 onClick={this.showIgnoreChangesConfirmation}
                 className="ml-2 font-weight-bold"
              >
                Ignore Changes
              </a>
            </div> : ""
          }
        </div>
        <div>
          <a id="viewChangeCriteriaButton"
             onClick={() => onViewChangeCriteria(true)}
             className="font-weight-bold"
          >
            View change criteria
          </a>
        </div>
      </div>
    );
  }

  renderConfirmationPopup(transferredFromUrl, transferredToUrl) {
    return (
      <ConfirmationPopup
        modalRef={ignoreChangesConfirmationPopup => this.ignoreChangesConfirmationPopup = ignoreChangesConfirmationPopup}
        headerText={"Ignore Changes"}
        message={
          <div>
            <div className="tech-transfer-ignore-changes-confirmation-text">
              Changes between the following records will be ignored:
            </div>
            <div>{transferredFromUrl} and {transferredToUrl}</div>
            <br />
            <div className="tech-transfer-ignore-changes-confirmation-text">
              Any future changes to fields in either sending or receiving records will reset this filter.
              They must be reviewed and actioned again through this process.
              Changes to the Tech Transfer Assessment section of the receiving will not reset this field.
            </div>
            <br />
            <div className="tech-transfer-ignore-changes-confirmation-text">
              To revert this action, make an edit to either record.
            </div>
          </div>
        }
        useYesNoOptions={true}
        showCancelButton={true}
        onOkButtonClick={this.handleIgnoreChangesConfirmation}
        onHideConfirmationPopup={this.handleHideIgnoreChangesConfirmation}
      />
    );
  }

  render() {
    const {reviewRecord, effectiveTechTransferRMP, onHideReviewScreen, securityModelType} = this.props;
    const {showIgnoreChangesConfirmation, dataModified} = this.state;
    const {totalChangesCount, totalDiffsCount,} = reviewRecord;
    const transferredTo = reviewRecord;
    const transferredFrom = reviewRecord.techTransferredFrom || {};

    const transferredFromLabel = UIUtils.getRecordCustomLabelForDisplay(transferredFrom);
    const transferredToLabel = UIUtils.getRecordCustomLabelForDisplay(transferredTo);

    const transferredFromUrl = (
      <a href={getURLByTypeCodeAndId(transferredFrom.typeCode, "View", transferredFrom.id)}
         rel="noopener noreferrer"
         target="_blank"
      >
        {transferredFromLabel}
      </a>
    );

    const transferredToUrl = (
      <a href={getURLByTypeCodeAndId(transferredTo.typeCode, "View", transferredTo.id)}
         rel="noopener noreferrer"
         target="_blank"
      >
        {transferredToLabel}
      </a>
    );

    return (
      <div className="tech-transfer-review-screen-container">
        <div className="tech-transfer-review-screen-header">
          <div>
            {transferredFromUrl}
            <FontAwesomeIcon className="tech-transfer-compare-icon ml-1 mr-1"
                             size="sm"
                             icon={faArrowCircleRight}
            />
            {transferredToUrl}
          </div>
          <div className="tech-transfer-review-screen-header-actions">
            <div className="btn-group">
              <button id="saveReviewButton"
                      type="button"
                      title={generateTooltip(CommonSecurity.Actions.EDIT, securityModelType)}
                      disabled={!can(CommonSecurity.Actions.EDIT, securityModelType) || !dataModified}
                      className="btn btn-primary"
                      onClick={this.handleSave}
              >
                Save
              </button>
              <button id="cancelReviewButton"
                      type="button"
                      className="btn btn-secondary"
                      onClick={onHideReviewScreen}
              >
                Cancel
              </button>
            </div>
            <div className="tech-transfer-review-screen-header-close-icon"
                 onClick={onHideReviewScreen}
            >
              <FontAwesomeIcon className="conlumn-selection-close-icon"
                               size="sm"
                               icon={faTimes}
              />
            </div>
          </div>
        </div>
        <ErrorBar
          id="techTransferReviewError"
          className="mt-2"
        />
        {totalChangesCount === 0 && totalDiffsCount === 0 ?
          "" :
          <div className="col-9">
            {this.renderChangeRelatedTab(transferredFromUrl, transferredToUrl)}
          </div>}
        <div className="tech-transfer-review-screen-body pt-0">
          <div className="col-9 tech-transfer-diff-container"
               id="techTransferDiffContainer"
          >
            {this.renderDiff()}
          </div>
          <div className="col-3 tech-transfer-editor-container mt-0">
            <TechTransferReviewEditor
              key={reviewRecord.id}
              effectiveRMP={effectiveTechTransferRMP}
              record={transferredTo}
              onTechTransferInfoUpdated={this.handleTechTransferInfoUpdated}
            />
          </div>
        </div>
        {showIgnoreChangesConfirmation ?
          this.renderConfirmationPopup(transferredFromUrl, transferredToUrl)
          : ""}
      </div>
    );
  }
}

TechTransferReviewBase.propTypes = {
  handleSave: PropTypes.func,
  transferredFromId: PropTypes.number,
};
