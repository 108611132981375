var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { classNames } from '@progress/kendo-react-common';
import * as React from 'react';
/**
 * Represents the KendoReact ExpansionPanelContent component.
 */
export var ExpansionPanelContent = React.forwardRef(function (props, ref) {
    return (React.createElement("div", __assign({ ref: ref }, props, { className: classNames('k-expander-content-wrapper', props.className) }),
        React.createElement("div", { className: 'k-expander-content' }, props.children)));
});
ExpansionPanelContent.displayName = 'KendoReactExpansionPanelContent';
