var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { HeaderCell } from './HeaderCell';
import { normalize } from './SortSettings';
import { ColumnResizer } from '../drag/ColumnResizer';
import { ColumnDraggable } from '../drag/ColumnDraggable';
import { HeaderThElement } from './HeaderThElement';
import { classNames, Icon, Keys } from '@progress/kendo-react-common';
import { registerForLocalization, provideLocalizationService as intl } from '@progress/kendo-react-intl';
import { messages, sortAriaLabel } from '../messages';
/**
 * @hidden
 */
var sortSeqMap = {
    true: { 'asc': 'desc', 'desc': '', '': 'asc' },
    false: { 'asc': 'desc', 'desc': 'asc', '': 'asc' }
};
/**
 * @hidden
 */
var ariaSortMap = {
    'none': 'none',
    'asc': 'ascending',
    'desc': 'descending'
};
/**
 * @hidden
 */
var HeaderRow = /** @class */ (function (_super) {
    __extends(HeaderRow, _super);
    function HeaderRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.cellClick = function (e, column) {
            e.preventDefault();
            if (!_this.props.sortChange) {
                return;
            }
            var _a = normalize(_this.props.sortable || false, column.sortable || false), allowUnsort = _a.allowUnsort, mode = _a.mode;
            var oldDescriptor = (_this.props.sort || []).filter(function (d) { return d.field === column.field; })[0];
            var dir = sortSeqMap[allowUnsort][oldDescriptor && oldDescriptor.dir || ''];
            var newDescriptor = (mode === 'single') ?
                [] : (_this.props.sort || []).filter(function (d) { return d.field !== column.field; });
            if (dir !== '' && column.field) {
                newDescriptor.push({ field: column.field, dir: dir });
            }
            _this.props.sortChange(e, newDescriptor, column.field);
        };
        _this.cellKeyDown = function (event, column) {
            if (event.isDefaultPrevented()) {
                return;
            }
            if (event.keyCode === Keys.enter) {
                _this.cellClick(event, column);
            }
        };
        _this.cells = function (rowIndexes) { return rowIndexes.map(function (columnIndex, index) {
            var column = _this.props.columns[columnIndex];
            var columnResize = _this.props.columnResize;
            var resizable = columnResize && columnResize.resizable && column.resizable;
            var sortable = _this.props.sortable && column.sortable;
            var sortIndex = _this.props.sort ? _this.props.sort.findIndex(function (s) { return s.field === column.field; }) : -1;
            var sortDir = sortIndex >= 0 ? ((_this.props.sort)[sortIndex].dir || 'none') : 'none';
            var headerCellProps = {
                field: column.field,
                onClick: sortable && (function (e) { return _this.cellClick(e, column); }) || undefined,
                selectionChange: _this.props.selectionChange,
                title: column.title,
                selectionValue: column.headerSelectionValue,
                render: _this.props.cellRender,
                children: _this.sortIcon(sortIndex)
            };
            var ColumnMenu = column.columnMenu || _this.props.columnMenu;
            var className = classNames({
                'k-first': column.kFirst,
                'k-filterable': Boolean(ColumnMenu),
                'k-header': true,
                'k-grid-header-sticky': column.locked,
                'k-sorted': _this.props.sort && _this.props.sort.some(function (descriptor) { return descriptor.field === column.field; })
            }, column.headerClassName);
            var style = (column.locked !== undefined) ? {
                left: column.left,
                right: column.right,
                borderRightWidth: column.rightBorder ? '1px' : ''
            } : {};
            var sortLabel = intl(_this).toLanguageString(sortAriaLabel, messages[sortAriaLabel]);
            var ariaAttrs = column.isAccessible ? {
                ariaSort: sortable ? ariaSortMap[sortDir] : undefined,
                role: 'columnheader',
                ariaColumnIndex: column.ariaColumnIndex,
                ariaSelected: false,
                ariaDescription: sortable ? sortLabel : ''
            } : {
                role: 'presentation'
            };
            var columnMenuProps = {
                sort: _this.props.sort,
                onSortChange: _this.props.sortChange,
                filter: _this.props.columnMenuFilter,
                onFilterChange: _this.props.columnMenuFilterChange,
                field: column.field
            };
            return (React.createElement(HeaderThElement, __assign({ key: index, colSpan: column.colSpan, rowSpan: column.rowSpan, className: className, style: style, columnId: column.id, navigatable: column.navigatable, onKeyDown: sortable && (function (e) { return _this.cellKeyDown(e, column); }) || undefined }, ariaAttrs),
                column.headerCell && React.createElement(column.headerCell, __assign({}, headerCellProps)),
                !column.headerCell && (React.createElement("span", { className: 'k-cell-inner' },
                    React.createElement(HeaderCell, __assign({}, headerCellProps)),
                    ColumnMenu && (React.createElement(ColumnMenu, __assign({}, columnMenuProps))))),
                columnResize && resizable &&
                    React.createElement(ColumnResizer, { key: "ColumnResizer", resize: function (e, element, end) { return columnResize.dragHandler(e, column, element, end); } })));
        }); };
        return _this;
    }
    HeaderRow.prototype.sortIcon = function (sortIndex) {
        if (!this.props.sort) {
            return null;
        }
        return sortIndex >= 0 && [
            React.createElement(Icon, { key: 1, name: "sort-".concat(this.props.sort[sortIndex].dir, "-small") }),
            this.props.sort.length > 1 && React.createElement("span", { key: 2, className: "k-sort-order" }, sortIndex + 1)
        ];
    };
    HeaderRow.prototype.render = function () {
        var _this = this;
        return this.props.columnsMap.map(function (rowIndexes, i) {
            return _this.props.pressHandler && (React.createElement(ColumnDraggable, { key: i, pressHandler: _this.props.pressHandler, dragHandler: _this.props.dragHandler, releaseHandler: _this.props.releaseHandler, ariaRowIndex: i + 1 }, _this.cells(rowIndexes))) || React.createElement("tr", { "aria-rowindex": i + 1, role: 'row' }, _this.cells(rowIndexes));
        });
    };
    return HeaderRow;
}(React.Component));
export { HeaderRow };
registerForLocalization(HeaderRow);
