"use strict";

import { BaseDataTransform } from "./base_data_transform";

/**
 * Implements a data transform that does nothing.
 * (This is an implementation of the Null Object design pattern).
 * @see https://sourcemaking.com/design_patterns/null_object
 */
export class NoOpDataTransform extends BaseDataTransform {

  get type() {
    return "noOp";
  }

  /**
   * @inheritDoc
   */
  transform(input, options) {
    return input;
  }

  /**
   * @inheritDoc
   */
  shouldRun(options) {
    return true;
  }
}

