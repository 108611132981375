"use strict";

import {
  ASSESSMENT_MODE_SELECTOR,
  ASSESSMENT_MODE_SELECTOR_ACCEPTANCE_CRITERIA_RANGES,
  ASSESSMENT_MODE_SELECTOR_CRITICALITY_ASSESSMENT,
  ASSESSMENT_MODE_SELECTOR_TOOLTIP_TEXT
} from "../../../../../../columnSelection/constants";
import * as styles from "../../../../../../columnSelection/assessmentModeSelector/assessment-mode-selector.module.css";
import RadioButton from "../../../../../../widgets/radioButton/radio_button";
import React, { useEffect } from "react";
import { TooltipIcon } from "./tooltip_icon";

export const AssessmentModeSelector = (
  {
    enableAssessmentOptions,
    showAssessmentOptions,
    assessmentModeChange,
    onAssessmentChange
  }) => {

  if (!showAssessmentOptions) {
    return null;
  }

  useEffect(() => {
    $(`#assessmentModeSelectorTooltipIcon`).tooltip({
      boundary: "viewport",
      container: ".configurable-tables-column-selection-body-container",
      html: true,
      placement: "top",
      title: ASSESSMENT_MODE_SELECTOR_TOOLTIP_TEXT,
      trigger: "click",
    });
  }, []);

  const onSelectionChange = ({target}) => {
    onAssessmentChange(target.value);
  };

  return (
    <div id={ASSESSMENT_MODE_SELECTOR} className={styles["assessment-mode-selector-class"]}>
      <div className="column-selection-entry-group">View on expand</div>

      <div className={styles["assessment-mode-selector-row"]}>
        <RadioButton id={ASSESSMENT_MODE_SELECTOR_CRITICALITY_ASSESSMENT}
                     name={ASSESSMENT_MODE_SELECTOR}
                     onChange={onSelectionChange}
                     checked={assessmentModeChange === ASSESSMENT_MODE_SELECTOR_CRITICALITY_ASSESSMENT}
                     label="Criticality Assessment"
                     disabled={!enableAssessmentOptions}
        />
        {!enableAssessmentOptions && <TooltipIcon />}
      </div>

      <div className={styles["assessment-mode-selector-row"]}>
        <RadioButton id={ASSESSMENT_MODE_SELECTOR_ACCEPTANCE_CRITERIA_RANGES}
                     name={ASSESSMENT_MODE_SELECTOR}
                     label="Acceptance Criteria Ranges"
                     onChange={onSelectionChange}
                     checked={assessmentModeChange === ASSESSMENT_MODE_SELECTOR_ACCEPTANCE_CRITERIA_RANGES}
                     disabled={!enableAssessmentOptions}
        />
      </div>
    </div>);
};