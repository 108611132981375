var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, Keys, useDir, getTabIndex, createPropsContext, usePropsContext, kendoThemeMaps } from '@progress/kendo-react-common';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
import { Picker } from './Picker';
import { ColorGradient } from './ColorGradient';
import { ColorPalette, DEFAULT_PRESET, DEFAULT_TILE_SIZE } from './ColorPalette';
import { Button } from '@progress/kendo-react-buttons';
import { messages, colorPickerDropdownButtonAriaLabel } from '../messages';
import { useLocalization } from '@progress/kendo-react-intl';
/**
 * @hidden
 */
var DEFAULT_GRADIENT_SETTINGS = {
    opacity: true
};
/**
 * @hidden
 */
var DEFAULT_PALETTE_SETTINGS = {
    palette: DEFAULT_PRESET,
    tileSize: DEFAULT_TILE_SIZE
};
/**
 * @hidden
 */
var isControlled = function (prop) {
    return prop !== undefined;
};
/**
 * Represents the PropsContext of the `ColorPicker` component.
 * Used for global configuration of all `ColorPicker` instances.
 *
 * For more information, refer to the [Inputs Props Context]({% slug props-context_inputs %}) article.
 */
export var ColorPickerPropsContext = createPropsContext();
/**
 * Represents the [KendoReact ColorPicker component]({% slug overview_colorpicker %}).
 *
 * @example
 * ```jsx
 * class App extends React.Component {
 *     render() {
 *         return <ColorPicker />;
 *     }
 * }
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var ColorPicker = React.forwardRef(function (directProps, target) {
    var _a, _b;
    validatePackage(packageMetadata);
    var props = usePropsContext(ColorPickerPropsContext, directProps);
    var localization = useLocalization();
    var _c = props.size, size = _c === void 0 ? defaultProps.size : _c, _d = props.rounded, rounded = _d === void 0 ? defaultProps.rounded : _d, _e = props.fillMode, fillMode = _e === void 0 ? defaultProps.fillMode : _e, popupSettings = props.popupSettings, gradientSettings = props.gradientSettings, paletteSettings = props.paletteSettings, valid = props.valid, disabled = props.disabled, tabIndex = props.tabIndex, view = props.view, icon = props.icon, iconClassName = props.iconClassName, onChange = props.onChange, onFocus = props.onFocus, onBlur = props.onBlur, onActiveColorClick = props.onActiveColorClick;
    var focusableElementRef = React.useRef(null);
    var gradientRef = React.useRef(null);
    var paletteRef = React.useRef(null);
    var blurTimeoutRef = React.useRef();
    var buttonRef = React.useRef(null);
    var focusElement = React.useCallback(function () {
        if (focusableElementRef.current) {
            focusableElementRef.current.focus();
        }
    }, []);
    React.useImperativeHandle(target, function () { return ({
        // we agreed that each element will have focus method exposed
        element: focusableElementRef.current,
        actionElement: buttonRef.current,
        focus: focusElement
    }); });
    var _f = React.useState(false), focused = _f[0], setFocused = _f[1];
    var _g = React.useState(props.defaultValue), stateValue = _g[0], setStateValue = _g[1];
    var _h = React.useState(false), stateOpen = _h[0], setStateOpen = _h[1];
    var isValueControlled = isControlled(props.value);
    var isOpenControlled = isControlled(props.open);
    var value = isValueControlled ? props.value : stateValue;
    var open = isOpenControlled ? props.open : stateOpen;
    var setOpen = React.useCallback(function (nextOpen, isBlur) {
        if (!isOpenControlled) {
            if (!nextOpen && !isBlur && focusableElementRef && focusableElementRef.current) {
                focusableElementRef.current.focus();
            }
            setStateOpen(nextOpen);
        }
    }, [isOpenControlled]);
    var onKeyDownHandler = React.useCallback(function (event) {
        var altKey = event.altKey, keyCode = event.keyCode;
        if (keyCode === Keys.esc) {
            setOpen(false);
            return;
        }
        if (keyCode === Keys.enter && !isOpenControlled) {
            event.preventDefault();
            event.stopPropagation();
            setOpen(!open);
            return;
        }
        if (altKey && (keyCode === Keys.up || keyCode === Keys.down)) {
            event.preventDefault();
            event.stopPropagation();
            if (keyCode === Keys.up && focusableElementRef && focusableElementRef.current) {
                focusableElementRef.current.focus();
                setOpen(false);
            }
            if (keyCode === Keys.down) {
                setOpen(true);
            }
        }
    }, [open, isOpenControlled]);
    var onOpenHandler = React.useCallback(function () {
        // Skip content autofocus when in controlled mode
        if (!isOpenControlled) {
            if (gradientRef.current) {
                gradientRef.current.focus();
            }
            else if (paletteRef.current) {
                paletteRef.current.focus();
            }
        }
    }, [isOpenControlled]);
    var onClickHandler = React.useCallback(function () {
        setOpen(!open, true);
    }, [open]);
    var onActiveColorClickHandler = React.useCallback(function (event) {
        if (onActiveColorClick) {
            onActiveColorClick.call(undefined, {
                syntheticEvent: event,
                nativeEvent: event.nativeEvent,
                value: value
            });
        }
    }, [onActiveColorClick, value]);
    var onFocusHandler = React.useCallback(function (event) {
        if (blurTimeoutRef.current) {
            clearTimeout(blurTimeoutRef.current);
            blurTimeoutRef.current = undefined;
            // case where moving back to input from popup
            // if (event.target === focusableElementRef.current) {
            // setOpen(false); // in this case we should focus input on keydown
            // }
        }
        else {
            setFocused(true);
        }
        if (onFocus) {
            onFocus.call(undefined, {
                nativeEvent: event.nativeEvent,
                syntheticEvent: event
            });
        }
    }, [onFocus]);
    var onBlurTimeout = React.useCallback(function () {
        setOpen(false, true);
        setFocused(false);
        blurTimeoutRef.current = undefined;
    }, []);
    var onBlurHandler = React.useCallback(function (event) {
        clearTimeout(blurTimeoutRef.current);
        blurTimeoutRef.current = window.setTimeout(onBlurTimeout);
        if (onBlur) {
            onBlur.call(undefined, {
                nativeEvent: event.nativeEvent,
                syntheticEvent: event
            });
        }
    }, [onBlur]);
    var onChangeHandler = React.useCallback(function (event, isPalette) {
        var currentValue = isPalette ? event.rgbaValue : event.value;
        if (!isValueControlled) {
            setStateValue(currentValue);
        }
        if (isPalette) {
            setOpen(false);
        }
        if (onChange) {
            onChange.call(undefined, {
                value: currentValue,
                nativeEvent: event.nativeEvent,
                syntheticEvent: event.syntheticEvent
            });
        }
    }, [isValueControlled, onChange]);
    var onPaletteChangeHandler = React.useCallback(function (event) { return onChangeHandler(event, true); }, [isControlled, onChangeHandler]);
    var dir = useDir(focusableElementRef, props.dir);
    var isValid = valid !== false;
    return (React.createElement("span", { id: props.id, "aria-labelledby": props.ariaLabelledBy, "aria-describedby": props.ariaDescribedBy, className: classNames('k-colorpicker', 'k-picker', 'k-icon-picker', (_a = {},
            _a["k-picker-".concat(kendoThemeMaps.sizeMap[size] || size)] = size,
            _a["k-picker-".concat(fillMode)] = fillMode,
            _a["k-rounded-".concat(kendoThemeMaps.roundedMap[rounded] || rounded)] = rounded,
            _a['k-invalid'] = !isValid,
            _a['k-disabled'] = disabled,
            _a['k-focus'] = focused,
            _a)), ref: focusableElementRef, tabIndex: getTabIndex(tabIndex, disabled), title: props.title, onKeyDown: onKeyDownHandler, onFocus: onFocusHandler, onBlur: onBlurHandler, dir: dir },
        React.createElement(Picker, { dir: dir, open: open, onOpen: onOpenHandler, popupAnchor: focusableElementRef.current || undefined, popupSettings: __assign({}, popupSettings), input: (React.createElement("span", { onClick: onActiveColorClickHandler, className: 'k-input-inner' },
                React.createElement("span", { className: classNames('k-value-icon', 'k-color-preview', {
                        'k-no-color': !value,
                        'k-icon-color-preview': (icon || iconClassName)
                    }) },
                    (iconClassName || icon) && React.createElement("span", { className: classNames('k-color-preview-icon', iconClassName, (_b = {},
                            _b["k-icon k-i-".concat(icon)] = (icon && !iconClassName),
                            _b)) }),
                    React.createElement("span", { className: "k-color-preview-mask", style: { backgroundColor: value } })))), button: (React.createElement(Button, { tabIndex: -1, type: "button", onClick: onClickHandler, className: "k-input-button", rounded: null, icon: 'caret-alt-down', "aria-label": localization.toLanguageString(colorPickerDropdownButtonAriaLabel, messages[colorPickerDropdownButtonAriaLabel]) })), content: (React.createElement(React.Fragment, null,
                (view === 'combo' || view === 'gradient') && (React.createElement(ColorGradient, __assign({}, gradientSettings, { tabIndex: 0, ref: gradientRef, value: value, onChange: onChangeHandler }))),
                (view === 'combo' || view === 'palette') && (React.createElement(ColorPalette, __assign({}, paletteSettings, { ref: paletteRef, value: value, onChange: onPaletteChangeHandler }))))) })));
});
ColorPicker.propTypes = {
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    view: PropTypes.oneOf(['gradient', 'palette', 'combo']),
    dir: PropTypes.string,
    id: PropTypes.string,
    ariaLabelledBy: PropTypes.string,
    ariaDescribedBy: PropTypes.string,
    size: PropTypes.oneOf([null, 'small', 'medium', 'large']),
    rounded: PropTypes.oneOf([null, 'small', 'medium', 'large', 'full']),
    fillMode: PropTypes.oneOf([null, 'solid', 'flat', 'outline'])
};
var defaultProps = {
    size: 'medium',
    rounded: 'medium',
    fillMode: 'solid',
    view: 'palette',
    gradientSettings: DEFAULT_GRADIENT_SETTINGS,
    paletteSettings: DEFAULT_PALETTE_SETTINGS
};
ColorPicker.defaultProps = defaultProps;
ColorPicker.displayName = 'KendoColorPicker';
