"use strict";

import * as UIUtils from "../../../../../ui_utils";
import { EMPTY_UNIT_OPERATION } from "./specification_transform_strategy";
import { orderAndIndexUnits } from "../../../../../processExplorer/indexers/uo_indexer";
import { VariableSpecificationTransformStrategy } from "./variable_specification_transform_strategy";

export class ComponentSpecificationTransformStrategy extends VariableSpecificationTransformStrategy {
  constructor() {
    super("processComponentId", "processcomponents");
  }

  fillGeneralInformation(record, requirement) {
    super.fillGeneralInformation(record, requirement);

    if (requirement.modelType === "PRC") {
      record.id = Number(requirement.ProcessComponentId);
      record.unitOperationId = requirement.UnitOperations && requirement.UnitOperations.length > 0 ?
        requirement.UnitOperations.map(uo => uo.id) : -1;
      record.name = UIUtils.secureString(requirement.name);
      record.type = UIUtils.secureString(requirement.type);
    }
  }

  fillParentInformation(record, requirement) {
    if (requirement.ProcessComponent) {
      record.processComponentId = Number(requirement.ProcessComponent.id);
      record.processComponent = UIUtils.secureString(requirement.ProcessComponent.name);
      record.processComponentType = UIUtils.secureString(requirement.ProcessComponent.type);
    }
  }

  wrapUp(result) {
    const instances = result.instances;

    if (instances) {
      const attributes = [];
      const knownUOs = new Set();
      const unitOperations = [];
      const variablesToProcess = this.mergeEntities(instances);
      let flattenedProcessComponents = this.flattenParents(instances.processcomponents);

      for (let processComponent of flattenedProcessComponents) {
        const context = {parent: processComponent, knownUOs, instances, unitOperations};
        const variables = variablesToProcess
          .filter(attr => attr.processComponentId === processComponent.id && attr.unitOperationId === processComponent.unitOperationId);

        if (variables.length === 0) {
          // creates empty attribute to force the display of the process component in the report
          const attribute = this.createAttribute();
          attribute.processComponentId = processComponent.id;
          attribute.processComponent = processComponent.name;
          attribute.processComponentType = processComponent.type;
          attribute.unitOperationId = processComponent.unitOperationId;
          this.setUnitOperation(attribute, context);
          attributes.push(attribute);
        } else {
          for (const attribute of variables) {
            if (processComponent && processComponent.unitOperationId) {
              this.setUnitOperation(attribute, context);
              attributes.push(attribute);
            }
          }
        }
      }

      attributes.sort(this.sortAttributes);

      instances.uos = orderAndIndexUnits(instances.uos, "id", false);
      instances.uos.push(EMPTY_UNIT_OPERATION);

      instances.uos = instances.uos.filter(uo => knownUOs.has(uo.id));
      instances.uos = instances.uos.map(uo => {
        uo.typeCode = uo.modelType;
        return {
          ...uo,
          label: uo.id === -1 ? uo.name : UIUtils.getRecordCustomLabelForDisplay(uo),
        };
      });

      instances.attributes = attributes;

      // ensures an empty summary will appear
      instances.isEmptySummary = (
        (!instances.attributes || instances.attributes.length === 0)
        || (!instances.uos || instances.uos.length === 0)
      );
    } else {
      result.instances = {
        isEmptySummary: true,
      };
    }

    return result;
  }

}
