var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { StepperContext } from './context/StepperContext';
import { classNames, focusFirstFocusableChild, useRtl, dispatchEvent, Keys } from '@progress/kendo-react-common';
import { Step } from './Step';
import { ProgressBar } from '@progress/kendo-react-progressbars';
import { DEFAULT_ANIMATION_DURATION, NO_ANIMATION } from './contants';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
/**
 * Represents the [KendoReact Stepper component]({% slug overview_stepper %}).
 *
 * @example
 * ```jsx
 * const steps = [
 *    { label: 'Step 1' },
 *    { label: 'Step 2' },
 *    { label: 'Step 3', optional: true }
 * ];
 *
 * const App = () => {
 *   return (
 *       <Stepper items={steps} value={1} />
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var Stepper = React.forwardRef(function (props, target) {
    validatePackage(packageMetadata);
    var animationDuration = props.animationDuration, children = props.children, className = props.className, disabled = props.disabled, errorIcon = props.errorIcon, item = props.item, items = props.items, linear = props.linear, mode = props.mode, orientation = props.orientation, style = props.style, successIcon = props.successIcon, onChange = props.onChange, onFocus = props.onFocus;
    var elementRef = React.useRef(null);
    var focusElement = React.useCallback(function () {
        if (elementRef.current) {
            focusFirstFocusableChild(elementRef.current);
        }
    }, []);
    var getImperativeHandle = React.useCallback(function () { return ({
        element: elementRef.current,
        focus: focusElement
    }); }, [focusElement]);
    React.useImperativeHandle(target, getImperativeHandle);
    var value = props.value || defaultProps.value;
    var _a = React.useState(value), focusedIdx = _a[0], setFocusedIdx = _a[1];
    var numOfSteps = items ? items.length : 0;
    var isVertical = orientation === 'vertical';
    var dir = useRtl(elementRef, props.dir);
    var animation = typeof animationDuration === 'number'
        ? animationDuration
        : animationDuration !== false
            ? DEFAULT_ANIMATION_DURATION
            : NO_ANIMATION;
    var dispatchChangeEvent = React.useCallback(function (event, val) {
        var prevIdx = val === value - 1;
        var currIdx = val === value;
        var nextIdx = val === value + 1;
        var allowClick = !linear || (prevIdx || currIdx || nextIdx);
        if (value !== val && onChange && !disabled && allowClick) {
            dispatchEvent(onChange, event, getImperativeHandle(), { value: val });
            setFocusedIdx(val);
        }
    }, [value, linear, onChange, disabled, setFocusedIdx]);
    var handleChange = React.useCallback(function (event) {
        var currentValue = event.value;
        var syntethicEvent = event.syntheticEvent;
        dispatchChangeEvent(syntethicEvent, currentValue);
    }, [dispatchChangeEvent]);
    var handleFocus = React.useCallback(function (event) {
        if (onFocus && !disabled) {
            dispatchEvent(onFocus, event.syntheticEvent, getImperativeHandle(), undefined);
        }
    }, [onFocus, disabled]);
    var handleEnter = React.useCallback(function (event) {
        dispatchChangeEvent(event, focusedIdx);
    }, [dispatchChangeEvent, focusedIdx]);
    var handleKeyDown = React.useCallback(function (event) {
        var isRtl = dir === 'rtl';
        var currIndex = focusedIdx;
        var maxNavIndex = items.length - 1;
        switch (event.keyCode) {
            case Keys.left:
                event.preventDefault();
                if (!isRtl && currIndex > 0) {
                    setFocusedIdx(currIndex - 1);
                }
                if (isRtl && currIndex < maxNavIndex) {
                    setFocusedIdx(currIndex + 1);
                }
                break;
            case Keys.right:
                event.preventDefault();
                if (!isRtl && currIndex < maxNavIndex) {
                    setFocusedIdx(currIndex + 1);
                }
                if (isRtl && currIndex > 0) {
                    setFocusedIdx(currIndex - 1);
                }
                break;
            case Keys.up:
                event.preventDefault();
                if (!isRtl && currIndex > 0) {
                    setFocusedIdx(currIndex - 1);
                }
                if (isRtl && currIndex > 0) {
                    setFocusedIdx(currIndex - 1);
                }
                break;
            case Keys.down:
                event.preventDefault();
                if (!isRtl && currIndex < maxNavIndex) {
                    setFocusedIdx(currIndex + 1);
                }
                if (isRtl && currIndex < maxNavIndex) {
                    setFocusedIdx(currIndex + 1);
                }
                break;
            case Keys.home:
                event.preventDefault();
                setFocusedIdx(0);
                break;
            case Keys.end:
                event.preventDefault();
                setFocusedIdx(maxNavIndex);
                break;
            case Keys.space:
                event.preventDefault();
                break;
            case Keys.enter:
                event.preventDefault();
                if (!items[currIndex].disabled) {
                    handleEnter(event);
                }
                break;
            default:
        }
    }, [items, setFocusedIdx, focusedIdx, dir, handleEnter]);
    var stepperClasses = React.useMemo(function () { return classNames('k-stepper', {
        'k-stepper-linear': linear
    }, className); }, [linear, className]);
    var stepperStyles = React.useMemo(function () { return (__assign({ display: 'grid', gridTemplateColumns: !isVertical ? 'repeat(' + numOfSteps * 2 + ', 1fr)' : undefined, gridTemplateRows: isVertical ? 'repeat(' + numOfSteps + ', 1fr)' : undefined }, style)); }, [isVertical, numOfSteps, style]);
    var listClasses = React.useMemo(function () { return classNames('k-step-list', {
        'k-step-list-horizontal': !isVertical,
        'k-step-list-vertical': isVertical
    }); }, [isVertical]);
    var listStyles = React.useMemo(function () { return ({
        gridColumnStart: !isVertical ? 1 : undefined,
        gridColumnEnd: !isVertical ? -1 : undefined,
        gridRowStart: isVertical ? 1 : undefined,
        gridRowEnd: isVertical ? -1 : undefined
    }); }, [isVertical]);
    var progressbarStyles = React.useMemo(function () { return ({
        gridColumnStart: !isVertical ? 2 : undefined,
        gridColumnEnd: !isVertical ? numOfSteps * 2 : undefined,
        gridRowStart: isVertical ? 1 : undefined,
        gridRowEnd: isVertical ? numOfSteps : undefined,
        top: isVertical ? 17 : undefined
    }); }, [isVertical, numOfSteps]);
    var steps = (items && (items.map(function (element, index) {
        var stepProps = __assign({ index: index, disabled: disabled || element.disabled, focused: index === focusedIdx, current: index === value }, element);
        var ItemComp = item || Step;
        return (React.createElement(ItemComp, __assign({ key: index }, stepProps)));
    })));
    return (React.createElement(StepperContext.Provider, { value: {
            animationDuration: animationDuration,
            isVertical: isVertical,
            item: item,
            linear: linear,
            mode: mode,
            numOfSteps: numOfSteps,
            value: value,
            successIcon: successIcon,
            errorIcon: errorIcon,
            onChange: handleChange,
            onFocus: handleFocus
        } },
        React.createElement("nav", { className: stepperClasses, style: stepperStyles, dir: dir, onKeyDown: handleKeyDown },
            React.createElement("ol", { className: listClasses, style: listStyles }, steps ? steps : children),
            React.createElement(ProgressBar, { style: progressbarStyles, animation: { duration: animation }, "aria-hidden": true, max: numOfSteps - 1, labelVisible: false, orientation: orientation, reverse: orientation === 'vertical', value: value, disabled: disabled, tabIndex: -1 }))));
});
Stepper.propTypes = {
    animationDuration: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    children: PropTypes.any,
    className: PropTypes.string,
    dir: PropTypes.string,
    disabled: PropTypes.bool,
    errorIcon: PropTypes.string,
    item: PropTypes.any,
    items: PropTypes.any,
    linear: PropTypes.bool,
    mode: PropTypes.oneOf(['steps', 'labels']),
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    style: PropTypes.object,
    successIcon: PropTypes.string,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    onFocus: PropTypes.func
};
var defaultProps = {
    value: 0
};
Stepper.defaultProps = defaultProps;
Stepper.displayName = 'KendoStepper';
