"use strict";

import * as UIUtils from "../../ui_utils";
import { Feedback } from "../generic/feedback";
import PropTypes from "prop-types";
import React from "react";
import UserSettingsMenu from "./user_settings_menu";
import BaseReactComponent from "../../base_react_component";

export default class UserSettings extends BaseReactComponent {

  shouldComponentUpdate(nextProps) {
    return nextProps.userName !== this.props.userName;
  }

  render() {
    return <div className="user-settings-div">
      {this.props.userName ? (
        <nav className={"header-nav"}>
          <div className="company-header-nav">

            <div id="companyNameDiv" className="company-header-name">
              {UIUtils.getCompany()}
            </div>
            <Feedback />
            <UserSettingsMenu title={this.props.userName}
                              href={this.props.href}
            />
          </div>
        </nav>
      ) : ""}
    </div>;
  }
}

UserSettings.propTypes = {
  userName: PropTypes.any,
  minified: PropTypes.bool,
  href: PropTypes.string,
};
