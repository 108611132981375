"use strict";

// i18next-extract-mark-ns-start process_explorer
/*
 * The functions in this file are responsible for ordering and indexing everything for the diagram in the process explorer.
 */

import { orderAndIndexSteps, orderAndIndexUnits } from "../indexers/uo_indexer";
import { UOToRecordResultsAdapter } from "./uo_to_record_results_adapter";
import { TYPE_CODE } from "../process_explorer_constants";
import { ProcessParameterIndexer } from "../indexers/process_parameter_indexer";
import { CommonSorting } from "../../../server/common/generic/common_sorting";

const TYPE_CODES_WITH_VISIBLE_INCOMING_LINKS = new Set([
  TYPE_CODE.UNIT_OPERATION,
  TYPE_CODE.STEP,
  TYPE_CODE.MATERIAL,
  TYPE_CODE.PROCESS_COMPONENT,
  TYPE_CODE.PROCESS_PARAMETER,
  TYPE_CODE.IQA,
  TYPE_CODE.IPA,
]);

/**
 * This is the category type of the header nodes (ex. "Unit Operations (x)", "Process Components (x)", etc)
 *
 * @type {string}
 */
export const TYPE_HEADER = "Header";

/**
 * @typedef ProcessExplorerResults
 * @property {Object<number, Object>} uoMap  A map of Unit Operation (UO) Ids to UOs.
 * @property {Object<number, Object>} iqaMap  A map of Intermediate Quality Attribute (IQA) Ids to IQAs.
 * @property {Object<number, Object>} ipaMap  A map of Performance Quality Attribute (IPA) Ids to IQAs.
 * @property {Object<number, Object>} maMap  A map of Material Attribute (MA) Ids to MAs.
 * @property {Object<number, Object>} ppMap  A map of Process Parameter (PP) Ids to PPs.
 * @property {Object<number, Object>} mtMap  A map of Material (MT) Ids to MTs.
 * @property {Object<number, Object>} prcMap  A map of Process Component (PRC) Ids to PRCs.
 * @property {Object<number, Object>} stpMap  A map of Step (STP) Ids to STPs.
 */

/**
 * This class adapts/transforms the process explorer results into arrays of nodes and links between those nodes
 * needed for the GoJS diagram in the process explorer.
 */
export class DiagramResultsAdapter extends UOToRecordResultsAdapter {

  /**
   * @param processExplorerResults {ProcessExplorerResults} The results from the process explorer handler.
   * @param isProjectArchived {boolean} Set to true if the project is archived.
   * @param showArchived Set to true if you want to show archived items in the process explorer.
   * @param t {function} For translation
   */
  constructor(processExplorerResults, isProjectArchived, showArchived, t) {
    super(processExplorerResults);
    this.nodes = [];
    this.links = [];
    this.isProjectArchived = isProjectArchived;
    this.showArchived = showArchived;
    this.t = t;
    this.categoryToCount = new Map();

    // Precalculate maps of unit operations to various types
    this.addType(this.results.stpMap, TYPE_CODE.STEP);
    this.addType(this.results.maMap, TYPE_CODE.MATERIAL_ATTRIBUTE);
    this.addType(this.results.ppMap, TYPE_CODE.PROCESS_PARAMETER);
    this.addType(this.results.mtMap, TYPE_CODE.MATERIAL);
    this.addType(this.results.prcMap, TYPE_CODE.PROCESS_COMPONENT);
    this.addType(this.results.iqaMap, TYPE_CODE.IQA);
    this.addType(this.results.ipaMap, TYPE_CODE.IPA);

    // Put the UOs in order
    this.orderedUOList = orderAndIndexUnits(Object.values(this.results.uoMap), "id", true);
    this.processParameterIndexer = new ProcessParameterIndexer(this.results, this.uoToPPMap, this.stpToPPMap);

    // Go through the results recursively.
    this.addProcess();
  }

  getOrderedUOList() {
    return this.orderedUOList;
  }

  addType(recordMap, recordTypeCode) {
    for (const record of Object.values(recordMap)) {
      record.typeCode = recordTypeCode;
    }
  }

  addToUOToIDToRecordMap(uoToIdToRecordMap, unitOperationId, record) {
    if (!uoToIdToRecordMap.has(unitOperationId)) {
      uoToIdToRecordMap.set(unitOperationId, new Map());
    }
    const idToRecordMap = uoToIdToRecordMap.get(unitOperationId);
    idToRecordMap.set(record.id, record);
  }

  preventAssetsWithStepsOrUOsFromShowingAsGlobal(records) {
    let objects = Array.from(records);
    return objects.filter(record => record.UnitOperations.length === 0 && record.Steps.length === 0);
  }

  preventVariablesWithStepOrUOFromShowingAsGlobal(records) {
    let objects = Array.from(records);
    return objects.filter(record => !record.UnitOperation && !record.Step);
  }

  addProcess() {
    if (!this.results.processes || !this.results.processes[0]) {
      throw new TypeError("No process selected.");
    }
    const process = this.results.processes[0];
    const processKey = "PR-" + process.id;
    const {t} = this;
    const processNode = this.addNode(
      {
        category: TYPE_CODE.PROCESS,
        fullName: process.name,
        key: processKey,
        staticPanelKey: processKey,
        isParentArchived: false,
        isTreeExpanded: true,
        record: process,
      });

    this.addAllUOs(t, process, processNode);

    // Add the Materials at the process level
    const idToMTs = this.uoToMTMap.get(null);
    let records = idToMTs ? idToMTs.values() : [];
    records = this.preventAssetsWithStepsOrUOsFromShowingAsGlobal(records);
    this.addAllOfTypeWithChildNodes(t, TYPE_CODE.MATERIAL, null, processKey, process.deletedAt, t("Materials"), true, true, true, records);

    // Add the Process Component at the process level
    const idToPRCs = this.uoToPRCMap.get(null);
    records = idToPRCs ? idToPRCs.values() : [];
    records = this.preventAssetsWithStepsOrUOsFromShowingAsGlobal(records);
    this.addAllOfTypeWithChildNodes(t, TYPE_CODE.PROCESS_COMPONENT, null, processKey, process.deletedAt, t("Process Components"), true, true, true, records);

    // These shouldn't happen, but it's handy if something goes wrong.
    // Add the MAs that aren't on any UO, if they exist.
    records = this.filterUnassignedRecords(this.uoToMAMap.get(null), idToPRCs || new Map(), idToMTs || new Map());
    records = this.preventVariablesWithStepOrUOFromShowingAsGlobal(records);
    if (records && records.length > 0) {
      this.addAllOfType(t, TYPE_CODE.MATERIAL_ATTRIBUTE, process, processKey, null, null, process.deletedAt, t("Material Attributes"), true, false, undefined);
    }

    // Add the PPs that aren't on any UO, if they exist.
    records = this.filterUnassignedRecords(this.uoToPPMap.get(null), idToPRCs || new Map(), idToMTs || new Map());
    records = this.preventVariablesWithStepOrUOFromShowingAsGlobal(records);
    if (records && records.length > 0) {
      this.addAllOfType(t, TYPE_CODE.PROCESS_PARAMETER, process, processKey, null, null, process.deletedAt, t("Process Parameters"), true, false, undefined);
    }
  }

  initIdToTypeMapIfRequired(idToTypes) {
    return !idToTypes ? new Map().set("", new Map()) : idToTypes;
  }

  addAllUOs(t, process, processNode) {
    // Add the UO header at the process level
    const uosHeaderNode = this.addTypeHeader(processNode.key, process, process.deletedAt, TYPE_CODE.UNIT_OPERATION, t("Unit Operations"), processNode.key + "-UOs", this.orderedUOList, true);

    for (const uo of this.orderedUOList) {
      // Add the UO itself
      const uoKey = TYPE_CODE.UNIT_OPERATION + "-" + uo.id;
      const uoNode = this.addNode(
        {
          category: TYPE_CODE.UNIT_OPERATION,
          fullName: uo.name,
          key: uoKey,
          staticPanelKey: uoKey,
          isParentArchived: process.deletedAt,
          isTreeExpanded: false,
          unitOperationId: uo.id,
          record: uo,
        });
      this.addLink(uosHeaderNode.key, uoNode.key, true);

      const isParentArchived = process.deletedAt || uo.deletedAt;

      // Add the Steps
      const idToSTPs = this.uoToSTPMap.get(uo.id);
      let orderedSteps = idToSTPs ? orderAndIndexSteps(Array.from(idToSTPs.values()), "id", true) : [];
      this.addAllStepsWithChildNodes(t, uo, uoNode.key, isParentArchived, t("Steps"), false, orderedSteps);

      // Add the Process Parameters
      const pps = this.processParameterIndexer.prepareRecordsInUO(uo.id);
      this.addAllOfType(t, TYPE_CODE.PROCESS_PARAMETER, uo, uoKey, uo.id, null, isParentArchived, t("Process Parameters"), false, pps, undefined);

      // Add the Materials
      let idToMTs = this.uoToMTMap.get(uo.id);
      this.addAllOfTypeWithChildNodes(t, TYPE_CODE.MATERIAL, uo, uoKey, isParentArchived, t("Materials"), false, false, false, idToMTs ? idToMTs.values() : []);

      // Add the PRCs
      let idToPRCs = this.uoToPRCMap.get(uo.id);
      this.addAllOfTypeWithChildNodes(t, TYPE_CODE.PROCESS_COMPONENT, uo, uoKey, isParentArchived, t("Process Components"), false, false, false, idToPRCs ? idToPRCs.values() : []);

      let records;
      // Add the Unassigned MAs
      idToMTs = this.initIdToTypeMapIfRequired(idToMTs);
      idToPRCs = this.initIdToTypeMapIfRequired(idToPRCs);
      records = this.filterUnassignedRecords(this.uoToMAMap.get(uo.id), idToPRCs, idToMTs);
      this.addAllOfType(t, TYPE_CODE.MATERIAL_ATTRIBUTE, uo, uoKey, uo.id, null, isParentArchived, t("Material Attributes"), false, records, undefined);

      // Add the IQAs
      records = this.uoToIQAMap.get(uo.id);
      records = records ? records.values() : [];
      this.addAllOfType(t, TYPE_CODE.IQA, uo, uoKey, uo.Id, null, isParentArchived, t("Intermediate Quality Attributes"), false, records, undefined);

      // Add the IPAs
      records = this.uoToIPAMap.get(uo.id);
      records = records ? records.values() : [];
      this.addAllOfType(t, TYPE_CODE.IPA, uo, uoKey, uo.Id, null, isParentArchived, t("Intermediate Performance Attributes"), false, records, undefined);
    }
  }

  filterUnassignedRecords(idToRecords, idToPRCs, idToMTs) {
    let records = idToRecords ? idToRecords.values() : [];
    records = records ? Array.from(records).filter(record => (!record.ProcessComponentId || !idToPRCs.has(record.ProcessComponentId))
      && (!record.MaterialId || !idToMTs.has(record.MaterialId))) : [];
    return records;
  }

  /**
   * Add an array of nodes and their children to the process explorer tree. This creates the node above for the header
   * type too.
   *
   * @param t The function that provides translation.
   * @param typeCode {string} The typeCode of the records to add.
   * @param unitOperation {{}} The UO that is above this that can supply the `name` and `id` attributes.
   * @param parentKey {string} The GoJS key of the parent above the headerNode.
   * @param isParentArchived {boolean} true if the parent has been archived, false otherwise.
   * @param headerName {string} The name of the header for these nodes.
   * @param isTreeExpanded {boolean} True if the tree should start off with this node expanded
   * @param alwaysShowAddButton {boolean} True if the add button should always be shown in the header.
   * @param records {[object]} The records to add nodes for after under the header.
   */
  addAllOfTypeWithChildNodes(t, typeCode, unitOperation, parentKey, isParentArchived, headerName, isTreeExpanded, alwaysShowAddButton, includePPs, records) {
    const unitOperationId = unitOperation && unitOperation.id;
    this.addAllOfType(t, typeCode, unitOperation, parentKey, unitOperationId, null, isParentArchived, headerName, isTreeExpanded, records, parent => {

      // Add child MAs
      let mas = this.filterAllOfType(typeCode, parent, this.uoToMAMap.get(unitOperationId));
      this.addAllOfType(t, TYPE_CODE.MATERIAL_ATTRIBUTE, parent, `${parentKey}-${typeCode}-${parent.id}`, unitOperationId, null, isParentArchived || parent.deletedAt, t("Material Attributes"), false, mas);

      if (includePPs) {
        // Add child PPs
        let pps = this.filterAllOfType(typeCode, parent, this.uoToPPMap.get(unitOperationId));
        this.addAllOfType(t, TYPE_CODE.PROCESS_PARAMETER, parent, `${parentKey}-${typeCode}-${parent.id}`, unitOperationId, null, isParentArchived || parent.deletedAt, t("Process Parameters"), false, pps);
      }
    });
  }

  addAllStepsWithChildNodes(t, unitOperation, uoKey, isParentArchived, stepsHeaderName, isTreeExpanded, steps) {

    let typeCode = TYPE_CODE.STEP;
    const unitOperationId = unitOperation && unitOperation.id;

    this.addAllOfType(t, typeCode, unitOperation, uoKey, unitOperationId, null, isParentArchived, stepsHeaderName, isTreeExpanded, steps, step => {
      let stepKey = `${uoKey}-${typeCode}-${step.id}`;

      // Add the Process Parameters
      const pps = this.processParameterIndexer.prepareRecordsInStep(step.id);

      this.addAllOfType(t, TYPE_CODE.PROCESS_PARAMETER, step, stepKey, unitOperationId, step.id, isParentArchived || step.deletedAt, t("Process Parameters"), false, pps, undefined);

      // Add child MTs
      let stpToMTs = this.stpToMTMap.get(step.id);
      let mts = stpToMTs ? Array.from(stpToMTs.values()) : [];

      this.addAllOfType(t, TYPE_CODE.MATERIAL, step, stepKey, unitOperationId, step.id, isParentArchived || step.deletedAt, t("Materials"), false, mts, parent => {

        // Add child MAs
        let mas = this.filterAllOfType(parent.typeCode, parent, this.stpToMAMap.get(step.id));
        this.addAllOfType(t, TYPE_CODE.MATERIAL_ATTRIBUTE, parent, `${stepKey}-${parent.typeCode}-${parent.id}`, unitOperationId, step.id, isParentArchived || step.deletedAt, t("Material Attributes"), false, mas, undefined);

      });

      // Add child PRCs
      let stpToPRCs = this.stpToPRCMap.get(step.id);
      let prcs = stpToPRCs ? Array.from(stpToPRCs.values()) : [];
      this.addAllOfType(t, TYPE_CODE.PROCESS_COMPONENT, step, stepKey, unitOperationId, step.id, isParentArchived || step.deletedAt, t("Process Components"), false, prcs, parent => {

        // Add child MAs
        let mas = this.filterAllOfType(parent.typeCode, parent, this.stpToMAMap.get(step.id));
        this.addAllOfType(t, TYPE_CODE.MATERIAL_ATTRIBUTE, parent, `${stepKey}-${parent.typeCode}-${parent.id}`, unitOperationId, step.id, isParentArchived || step.deletedAt, t("Material Attributes"), false, mas);

      });


      // Init the map in case it has nothing...
      stpToMTs = this.initIdToTypeMapIfRequired(stpToMTs);
      stpToPRCs = this.initIdToTypeMapIfRequired(stpToPRCs);

      // Add child MAs
      let mas = this.filterUnassignedRecords(this.stpToMAMap.get(step.id), stpToPRCs, stpToMTs);
      this.addAllOfType(t, TYPE_CODE.MATERIAL_ATTRIBUTE, step, `${uoKey}-${typeCode}-${step.id}`, unitOperationId, step.id, isParentArchived || step.deletedAt, t("Material Attributes"), false, mas, false);

      // Add child IQAs
      let stpToIQAs = this.stpToIQAMap.get(step.id);
      let iqas = stpToIQAs ? Array.from(stpToIQAs.values()) : [];
      this.addAllOfType(t, TYPE_CODE.IQA, step, `${uoKey}-${typeCode}-${step.id}`, unitOperationId, step.id, isParentArchived || step.deletedAt, t("Intermediate Quality Attributes"), false, iqas, undefined);

      // Add child IPAs
      let stpToIPAs = this.stpToIPAMap.get(step.id);
      let ipas = stpToIPAs ? Array.from(stpToIPAs.values()) : [];
      this.addAllOfType(t, TYPE_CODE.IPA, step, `${uoKey}-${typeCode}-${step.id}`, unitOperationId, step.id, isParentArchived || step.deletedAt, t("Intermediate Performance Attributes"), false, ipas, undefined);

    });
  }

  /**
   * Add an array of nodes of a given type to the process explorer tree. This creates the node above for the header
   * type too.
   *
   * @param t The function that provides translation.
   * @param typeCode {string} The typeCode of the records to add.
   * @param parent {{}} The parent that can supply the `name` and `id` attributes.
   * @param parentKey {string} The GoJS key of the parent above the headerNode.
   * @param unitOperationId {integer} The id of the unit operation all of this is under.
   * @param stepId {integer} The id of the step all of this is under.
   * @param isParentArchived {boolean} true if the parent has been archived, false otherwise.
   * @param headerName {string} The name of the header for these nodes.
   * @param isTreeExpanded True if the header for this node should be expanded by default.
   * @param records {[object]} The records to add nodes for after under the header.
   * @param [callback] {function} An optional function to call on each node added.
   */
  addAllOfType(t, typeCode, parent, parentKey, unitOperationId, stepId, isParentArchived, headerName, isTreeExpanded = false, records, callback) {
    records = records ? Array.from(records) : [];

    // Skip sorting if the records are of type Step. They have been sorted already before, so we don't want to mess up their order.
    const TYPE_CODES_TO_NOT_SORT = [TYPE_CODE.STEP, TYPE_CODE.PROCESS_PARAMETER];
    records = TYPE_CODES_TO_NOT_SORT.includes(typeCode) ? records : records.sort(CommonSorting.sortRecord);

    if (records && records.length > 0) {
      // Steps shouldn't have a header
      let headerNode;
      if (typeCode === TYPE_CODE.STEP) {
        headerNode = {key: parentKey};
      } else {
        headerNode = this.addTypeHeader(parentKey, parent, isParentArchived, typeCode, headerName, `${parentKey}-${typeCode}s`, records, isTreeExpanded, unitOperationId, stepId);
      }

      for (const record of records.filter(record => record)) {
        const key = typeCode + "-" + record.id;
        this.addNode(
          {
            category: typeCode,
            fullName: record.name,
            key: parentKey + "-" + key,
            staticPanelKey: key,
            isParentArchived: isParentArchived,
            isTreeExpanded: false,
            unitOperationId,
            stepId: typeCode === TYPE_CODE.STEP ? record.id : stepId,
            record,
          });
        this.addLink(headerNode.key, parentKey + "-" + key, TYPE_CODES_WITH_VISIBLE_INCOMING_LINKS.has(typeCode));
        callback && callback(record);
      }
    }
  }

  filterAllOfType(typeCode, parent, idToRecords) {
    let records = idToRecords ? Array.from(idToRecords.values()) : [];
    if (typeCode === TYPE_CODE.MATERIAL) {
      records = records.filter(ma => ma.MaterialId === parent.id);
    } else {
      records = records.filter(ma => ma.ProcessComponentId === parent.id);
    }
    return records;
  }

  /**
   * Add a header for a group of nodes, like "Process Components (2)". It'll have a different style than the other nodes.
   *
   * @param parentNodeKey {string} The GoJS key of the parent node (ex. UO-3-STP-4).
   * @param parent {{}} The object representing the parent that this header is attached to
   * @param isParentArchived {boolean} true if the parent has been archived, false otherwise.
   * @param typeCode {string} The typeCode of the records under this header.
   * @param categoryName {string} The text that should appear on the node for this category.
   * @param nodeKey {string} The key for this node.
   * @param recordsArray {[]} An array of records that can be used to tell how many objects are under this node.
   * @param isTreeExpanded True if this node should be expanded by default.
   * @param unitOperationId {integer} The id of the unit operation all of this is under.
   * @param stepId {integer} The id of the step all of this is under.
   * @return {{deletedAt: *|undefined, visible: boolean, isTreeExpanded: boolean, isProjectArchived: boolean, fullName: string, isParentArchived: boolean, userHasAccess: boolean, category: string, currentState: *|undefined, italic: boolean, key: string, order: number|any|undefined}}
   */
  addTypeHeader(parentNodeKey, parent, isParentArchived, typeCode, categoryName, nodeKey, recordsArray, isTreeExpanded = false, unitOperationId, stepId) {
    const categoryHeaderNode = this.addNode(
      {
        category: TYPE_HEADER,
        fullName: categoryName,
        key: nodeKey,
        staticPanelKey: null,
        headerTypeCode: typeCode,
        headerParentKey: parent ? parent.typeCode + "-" + parent.id : null,
        isParentArchived,
        isTreeExpanded: isTreeExpanded,
        alwaysShowAddButton: true,
        unitOperationId,
        stepId,
        recordsArray,
        showMenu: typeCode === TYPE_CODE.UNIT_OPERATION || TYPE_CODE.MATERIAL,
      });
    this.addLink(parentNodeKey, categoryHeaderNode.key);
    return categoryHeaderNode;
  }

  /**
   * Add a new node
   *
   * @param options {{}} An object with multiple options, detailed below
   * @param options.category {string} The category is used by the DiagramFacade to figure out how to draw it
   * @param options.fullName {string} The name to be displayed on the node
   * @param options.key {string} The key to use when referencing this node in a link
   * @param options.staticPanelKey {string|null} An optional key (ex. MT-11) to use when loading this record in the Quick Panel
   * @param options.headerTypeCode {string|null} The types being stored under this header, or null if this isn't a header.
   * @param options.headerParentKey {string|null} The key (ex. UO-12) of the object that is the parent of this header, or null if this isn't a header.
   * @param options.isParentArchived {boolean} True if the parent node is archived
   * @param options.isTreeExpanded {boolean} True if the tree should start off with this node expanded
   * @param options.alwaysShowAddButton {boolean} True if the add button should always be shown in this (used for headers)
   * @param options.unitOperationId {int} The Id of the UO directly above this node (not a list of all UOs this record might link to)
   * @param options.stepId {int} The Id of the Step directly above this node (not a list of all Steps this record might link to)
   * @param options.record {{}} The record itself to gather context menu info (ie. right click actions)
   * @param options.recordsArray {[]} An array of records to compute the numbers for a header
   * @private
   */
  addNode(options) {
    let userHasAccess = true;
    let {
      category,
      fullName,
      key,
      staticPanelKey,
      headerTypeCode,
      headerParentKey,
      isParentArchived,
      isTreeExpanded,
      alwaysShowAddButton,
      unitOperationId,
      stepId,
      record,
      recordsArray,
      showMenu,
    } = options;

    const node = {
      category,
      fullName,
      key,
      headerTypeCode,
      headerParentKey,
      isParentArchived,
      isTreeExpanded,
      alwaysShowAddButton,
      unitOperationId,
      stepId,
      isProjectArchived: this.isProjectArchived,
      currentState: record?.currentState,
      deletedAt: record?.deletedAt,
      order: this.addToCount(category),
      visible: (
        this.showArchived || category === TYPE_CODE.PROCESS
          ? true
          : (
            category === TYPE_HEADER
              ? !isParentArchived
              : !record?.deletedAt
          )
      ),
      isGhosted: record?.isGhosted,
      italic: false,
      showAddButton: false,
      showContextMenuButton: false,
      userHasAccess,
      showMenu,
    };

    if (record?.isGhosted) {
      const childrenInStep = this.nodes.filter(x => x.stepId && x.stepId === stepId &&
        x.parent?.staticPanelKey === staticPanelKey);

      if (childrenInStep.length > 0 && childrenInStep.filter(x => x.currentState !== "Archived").length === 0) {
        node.ghostHasNoChilren = true;
      }
    }

    if (record?.parent) {
      node.parent = {
        category: record.ProcessComponentId ? TYPE_CODE.PROCESS_COMPONENT : TYPE_CODE.MATERIAL,
        fullName: record.parent.name,
        staticPanelKey: record.ProcessComponentId ? "PRC-" + record.ProcessComponentId : "MT-" + record.MaterialId,
        userHasAccess,
      };
    }

    if (staticPanelKey) {
      node.staticPanelKey = staticPanelKey;
    }

    if (category === TYPE_HEADER) {
      node.showArchivedCount = recordsArray ? recordsArray.length : 0;

      let nodesInHeader = recordsArray ? recordsArray.filter(node => node && !node.deletedAt) : [];
      nodesInHeader = nodesInHeader.filter(recordInHeader => {
        const me = this;
        if (!recordInHeader.isGhosted) {
          return true;
        } else {
          const ghostRecordKey = recordInHeader.typeCode + "-" + recordInHeader.id;
          const parentsInStep = me.nodes.filter(x =>
            x.stepId === stepId &&
            x.parent?.staticPanelKey === ghostRecordKey);

          return !(parentsInStep.length > 0 && parentsInStep.filter(x => x.currentState !== "Archived").length === 0);
        }
      });

      node.count = nodesInHeader.length;
    }


    this.nodes.push(node);

    return node;
  }

  addToCount(category) {
    this.categoryToCount.set(category, this.categoryToCount.get(category) + 1 || 1);
    return this.categoryToCount.get(category);
  }

  addLink(fromKey, toKey, isVisible = true) {
    const link = {from: fromKey, to: toKey, key: fromKey + "|" + toKey, isVisible};
    this.links.push(link);
    return link;
  }

  /**
   * @return {{nodes: [], links: []}} Nodes and links for building a GoJS diagram
   */
  getNodesAndLinks() {
    return {nodes: this.nodes, links: this.links};
  }
}

// i18next-extract-mark-ns-stop process_explorer