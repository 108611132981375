/**
 * @hidden
 */
var Mask = /** @class */ (function () {
    function Mask() {
        this.symbols = '';
        this.partMap = [];
    }
    return Mask;
}());
export { Mask };
