"use strict";

import React from "react";
import BaseReactComponent from "../../../base_react_component";
import { ListOption } from "../../../editor/widgets/list_option";
import ValidationIcon from "../../../widgets/generic/validation_icon";

/**
 * This renders a drop down for batch records which the user can use to select a batch among batches.
 */
export default class ImportBatchSelect extends BaseReactComponent {
  render() {
    const {selectedBatch, onBatchSelected, isMultiProcess} = this.props;
    let batches = this.props.batches || [];

    return (
      <div>
        <div>
          <label id="processSelectionDropDownLabel"
                 htmlFor="processSelectionDropDown"
          >
                  <span>
                    Select Batch
                  </span>
          </label>
        </div>
        <div className="import-batch-select-div">
          <select className="form-control"
                  id="batchSelectionDropDown"
                  value={selectedBatch && selectedBatch.customID}
                  onChange={onBatchSelected}
          >
            {batches.map(batch => <ListOption item={batch.customID} key={batch.customID} />)}
          </select>
          <ValidationIcon id="batchSelectionErrorIcon"
                          trigger="hover"
                          tooltip={isMultiProcess
                            ? "The selected process has no batch records. Please create a batch record first."
                            : "No batch records found in the system. Please create a batch record first."}
                          visible={batches.length === 0}
          />
        </div>
      </div>
    );
  }
}