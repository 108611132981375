"use strict";

import React, { createContext, useContext, useState } from "react";

const SidePanelContext = createContext();

export const useSidePanel = () => useContext(SidePanelContext);

export const SidePanelProvider = ({children}) => {

  const [
    showSidePanel,
    setShowSidePanel
  ] = useState(false);

  const [
    action,
    setAction
  ] = useState(null);

  const toggle = (action) => {
    if (action) {
      setAction(action);
    }
    setShowSidePanel(prev => !prev);
  };

  return (
    <SidePanelContext.Provider value={{action, showSidePanel, toggle}}>
      {children}
    </SidePanelContext.Provider>
  );
};
