var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { classNames } from '@progress/kendo-react-common';
/**
 * @hidden
 */
var SplitterPane = /** @class */ (function (_super) {
    __extends(SplitterPane, _super);
    function SplitterPane() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SplitterPane.prototype.render = function () {
        var _a;
        var _b = this.props, id = _b.id, size = _b.size, collapsed = _b.collapsed, overlay = _b.overlay, containsSplitter = _b.containsSplitter, collapsible = _b.collapsible, resizable = _b.resizable, scrollable = _b.scrollable, keepMounted = _b.keepMounted;
        var fixedSize = size && size.length > 0;
        var style = {
            flexBasis: size
        };
        var paneClasses = classNames('k-pane', (_a = {},
            _a['k-hidden'] = collapsed,
            _a['hidden'] = collapsed,
            _a['k-pane-flex'] = containsSplitter,
            _a['k-pane-static'] = !resizable && !collapsible || fixedSize,
            _a['k-scrollable'] = scrollable,
            _a));
        return (React.createElement("div", { id: id, style: style, className: paneClasses },
            !collapsed || keepMounted ? this.props.children : undefined,
            overlay ? React.createElement("div", { className: 'k-splitter-overlay k-overlay' }) : undefined));
    };
    return SplitterPane;
}(React.Component));
export { SplitterPane };
