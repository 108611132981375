"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseReactComponent from "../../base_react_component";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faFileExport } from "@fortawesome/free-solid-svg-icons";
import { ALL_UOS_OPTION } from "../../reports/risk_tables/risk_tables_report_page";
import TechTransferColumnSelection from "./tech_transfer_column_selection";
import TableOptionsButton from "../../widgets/tables/table_options_button";
import CopyToClipboardWidget from "../../widgets/generic/copy_to_clipboard_widget";
import { ALL_OPTION, EMPTY_OPTION } from "../../configurableTables/tables/configurable_tables_constants";
import { DropdownButton } from "@qbdvision-inc/component-library";

const MODEL_TYPES = ["Process Parameters", "Material Attributes", "Process Components", "Materials", "IQAs", "IPAs"];

/**
 *  This allows the user to pick needed information for tech transfer processes comparison.
 */
// i18next-extract-mark-ns-start tech_transfer
class TechTransferTopBar extends BaseReactComponent {
  handleModelTypeChange(event) {
    const selectedModelType = event.target.textContent;
    if (selectedModelType !== this.props.selectedModelType) {
      this.props.onModelTypeChange(selectedModelType);
    }
  }

  handleUOChange(event) {
    const value = event.target.textContent;
    const unitOperationId = value === "All" ? "All" : UIUtils.parseKey(value).id;
    this.setStateSafely({
      unitOperationId,
    }, () => {
      if (unitOperationId !== this.props.unitOperationId) {
        this.props.onUnitOperationChange(unitOperationId);
      }
    });
  }

  handleProcessChange(fieldName, event) {
    const {processes} = this.props;

    const selectedProcess = event.target.textContent;
    const selectedProcessId = processes.find(process => process.name === selectedProcess).id;

    if (selectedProcessId !== this.state[fieldName]) {
      let stateObject = {[fieldName]: selectedProcessId};
      if (fieldName === "fromProcessId") {
        stateObject.toProcessId = this.getToProcessId(selectedProcessId);
      }

      this.setStateSafely(stateObject, () => {

        const processId = processes.find(process => process.id === UIUtils.parseInt(selectedProcessId)).id;
        if (fieldName === "toProcessId") {
          this.props.onprocessChange({toProcessId: processId});
        } else if (fieldName === "fromProcessId") {
          this.props.onprocessChange({
            fromProcessId: processId,
            toProcessId: this.getToProcessId(selectedProcessId)
          });
        }
      });
    }
  }

  getToProcessId(selectedProcessId) {
    const {processes} = this.props;
    const processId = processes.find(process => process.id === UIUtils.parseInt(selectedProcessId)).id;
    const toProcessesIds = processes
      .filter(process => process.SendingId === processId)
      .map(process => process.id);
    return toProcessesIds[0];
  }

  render() {
    let {
      fromProcessId,
      toProcessId,
      unitOperationId,
      processes,
      selectedModelType,
      onExport,
      onVisibleTableColumnsChanged,
      onResetToDefaults,
      onViewChangeCriteria,
      visibleTableColumns,
    } = this.props;

    processes = processes || [];
    processes = processes.filter(process => !process.deletedAt);

    const unitOperations = this.props.orderedUOList ? [...this.props.orderedUOList] : [];
    const unitOperationOptions = [EMPTY_OPTION, ALL_OPTION, ...unitOperations.map(uo => {
      return {
        disabled: false,
        key: uo.id,
        title: uo.id,
        value: uo.id === ALL_UOS_OPTION.id ? uo.name : UIUtils.getRecordLabelForDisplay("UO", uo.id, uo.name)
      };
    })];

    let unitOperationDropdownLabel = unitOperationOptions?.find(option => option.key === unitOperationId)?.value || "Select Unit Operation";

    const modelTypeOptions = MODEL_TYPES.map(type => {
      return {
        disabled: false,
        key: UIUtils.stripAllWhitespaces(type),
        title: type,
        value: type
      };
    });

    // From the valid receiving processes, figure out what are the sending processes.
    const sendingSites = processes.filter(process => process.SendingId);
    const sendingSitesIds = sendingSites.map(process => process.id);
    const fromProcesses = processes.filter(process => !process.SendingId || sendingSitesIds.includes(process.id));

    const fromProcessOptions = [EMPTY_OPTION, ...fromProcesses.map(process => {
      return {
        disabled: false,
        key: UIUtils.convertToId(process.name),
        title: process.id,
        value: process.name
      };
    })];

    const fromProcessDropdownLabel = fromProcessOptions?.find(option => option.title === fromProcessId)?.value;

    // Figure out the valid receiving processes that we can tech transfer to since they have sending processes defined.
    const toProcesses = processes.filter(process => process.SendingId === fromProcessId && process.techTransferEnabled);
    if (!toProcesses.find(process => process.id === toProcessId)) {
      toProcessId = undefined;
    }

    const toProcessOptions = [EMPTY_OPTION, ...toProcesses.map(process => {
      return {
        disabled: false,
        key: UIUtils.convertToId(process.name),
        title: process.id,
        value: process.name
      };
    })];

    const toProcessDropdownLabel = toProcessOptions?.find(option => option.title === toProcessId)?.value || "";

    return (
      <div className="tech-transfer-bar-container">
        <div className="container-fluid tech-transfer-bar">
          <span className="tech-transfer-title">
            Tech Transfer Assessment
          </span>
          <div className="tech-transfer-bar-comparison">
            <DropdownButton
              id="techTransferFromSiteSelect"
              options={fromProcessOptions}
              label={fromProcessDropdownLabel}
              onOptionsSelect={this.handleProcessChange.bind(this, "fromProcessId")}
              size="small"
              title="From Site Selection"
              type="secondary"
            />
            <FontAwesomeIcon className="tech-transfer-compare-icon"
                             icon={faArrowCircleRight}
            />
            <DropdownButton
              id="techTransferToSiteSelect"
              options={toProcessOptions}
              label={toProcessDropdownLabel}
              onOptionsSelect={this.handleProcessChange.bind(this, "toProcessId")}
              size="small"
              title="From Site Selection"
              type="secondary"
            />
          </div>
          <div className="tech-transfer-bar-comparison">
            <div className="mr-1">
              <DropdownButton
                id="techTransferUnitOperationSelect"
                options={unitOperationOptions}
                label={unitOperationDropdownLabel}
                onOptionsSelect={this.handleUOChange}
                size="small"
                title="Unit Operation Selection"
                type="secondary"
                isDisabled={unitOperations.length === 0}
              />
            </div>
            <div>
              <DropdownButton
                id="techTransferModelTypeSelect"
                options={modelTypeOptions}
                label={selectedModelType}
                onOptionsSelect={this.handleModelTypeChange}
                size="small"
                title="Record Type Selection"
                type="secondary"
                disabled={unitOperations.length === 0}
              />
            </div>
            <TableOptionsButton
              className="ml-1"
              key={"extendedOptions"}
              id={"extendedOptionsButton"}
              options={[{
                action: {
                  title: "Change Criteria",
                },
                id: "viewChangeCriteriaButton",
                onClick: onViewChangeCriteria.bind(this, false),
              }]}
            />
            <CopyToClipboardWidget />
            <TechTransferColumnSelection
              selectedModelType={selectedModelType}
              visibleTableColumns={visibleTableColumns}
              onVisibleTableColumnsChanged={onVisibleTableColumnsChanged}
              onResetToDefaults={onResetToDefaults}
            />
            <button id="exportButton"
                    type="button"
                    className="btn btn-primary configurable-export-button"
                    onClick={onExport}
                    aria-label="Export"
                    title="Export the Tech Transfer Report to excel"
            >
              <FontAwesomeIcon icon={faFileExport} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(TechTransferTopBar, "tech_transfer");
// i18next-extract-mark-ns-stop tech_transfer
