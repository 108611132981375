"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import moment from "moment-timezone";
import * as CommonUsers from "../../../server/common/editables/common_users";
import BaseReactComponent from "../../base_react_component";

/* This shows the person's initials with a circle around it. */
export default class ApproverTooltip extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    $(this.popover).popover({sanitizeFn: UIUtils.sanitizePopoverData});
  }

  render() {
    let approver = this.props.approver;
    let lastApproverResponse = this.props.approverResponse;
    let approverResponses = lastApproverResponse ? lastApproverResponse.approvalResponseHistories : [];

    let popupContent = (
      <div>
        <div className="history-item-popup-title">
          {CommonUsers.formatUserToShowTitle(approver)}
        </div>
        {approverResponses && approverResponses.length > 0 ? (
          approverResponses.map(response => {
            return (
              <div key={response.id}
                   className="history-item-transition-popup-container"
              >
                <div className={"history-item-transition-popup-response " + (response.approve ? "approved" : "rejected")}>
                  {response.approve ? "Approved" : "Rejected"}
                </div>
                <div className="history-item-transition-popup-date">
                  {moment(response.updatedAt).format(UIUtils.LONG_DATE_FORMAT_FOR_DISPLAY) + " "}
                </div>
                <div className="history-item-popup-comment-title">Comment:</div>
                <div className="history-item-popup-comment">
                  {response.comment}
                </div>
              </div>
            );
          })
        ) : (
          <div className="history-item-transition-popup-container">
            <div className="history-item-transition-popup-response pending">
              Pending
            </div>
          </div>
        )}
      </div>
    );
    let approverInitials = approver?.firstName[0].toUpperCase()
      + approver?.lastName[0].toUpperCase();
    let approverIconClassName = "";
    if (lastApproverResponse) {
      approverIconClassName = lastApproverResponse.approve ?
        "history-item-approver-icon-approved" :
        "history-item-approver-icon-rejected";
    }
    const majorVersion = this.props.approvedVersion ? this.props.approvedVersion.majorVersion : this.props.version.majorVersion;
    const minorVersion = this.props.approvedVersion ? this.props.approvedVersion.minorVersion : this.props.version.minorVersion;
    return (
      <div className={"history-item-approver-icon " + approverIconClassName}
           data-toggle="popover" data-html="true" data-container="body"
           data-content={ReactDOMServer.renderToStaticMarkup(popupContent)}
           id={(this.props.isVersionTransitionTooltip ? "historyItemTransitionApproverIcon-" : "historyItemApproverIcon-") + approverInitials + "-"
             + majorVersion + "-" + minorVersion + "-" + this.props.index}
           data-placement="top" ref={popover => this.popover = popover}
      >
        {approverInitials}
      </div>
    );
  }

}
