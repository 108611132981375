"use strict";

import React, { Fragment } from "react";
import { getRiskFilterNameFromRiskScale, getRiskScales } from "../../../helpers/risk_helper";
import { getCSSClassForLegend, showByRiskLabel } from "../utilities/risk_map_report_helper";
import { NEEDS_ANALYSIS_TERM } from "../../constants/report_constants";
import BaseLegend from "../../common/base_legend";

export default class RiskMapLegend extends BaseLegend {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    return this.props.RMP !== nextProps.RMP ||
      this.props.riskType !== nextProps.riskType ||
      JSON.stringify(this.props.filters) !== JSON.stringify(nextProps.filters) ||
      this.props.scaleNetworkToFit !== nextProps.scaleNetworkToFit;
  }

  componentDidUpdate() {
    super.componentDidUpdate();

    if (typeof this.props.scaleNetworkToFit === "boolean" && this.props.scaleNetworkToFit) {
      this.collapse();
    }
  }

  getRiskMapLegendRiskLevels() {
    let rmp = this.props.RMP;
    if (rmp) {
      let riskScales = getRiskScales(this.props.riskType, rmp);
      riskScales.sort((a, b) => {
        return (a.modelName < b.modelName) ? -1 : (a.modelName > b.modelName) ? 1 : 0;
      });

      let legendModels = [];
      let labels = riskScales.map((riskScale, index) => {
        let showRiskLabel = showByRiskLabel(this.props.filters, this.props.riskType);
        let modelExists = legendModels.includes(riskScale.modelName);
        let legendHTML = (
          <div key={index} className="risk-map-legend-item-row">
            {modelExists ? "" : rmp.configureByType ?
              <Fragment>
                <div className={`risk-map-legend-shape risk-map-legend-${riskScale.modelName.toLowerCase()}`} />
                <div className="risk-map-legend-item-description risk-map-legend-item-parent">{riskScale.modelName}</div>
              </Fragment> : ""
            }
            <div className={rmp.configureByType ? "risk-map-legend-child-item" : ""}>
              <div className={"risk-map-legend-shape risk-map-legend-risk-shape " + getCSSClassForLegend(riskScale.color)} />
              <div className="risk-map-legend-item-description">{showRiskLabel ? riskScale.riskLabel : riskScale.scoreLabel}</div>
            </div>
          </div>);

        if (!modelExists) {
          legendModels.push(riskScale.modelName);
        }
        return legendHTML;
      });

      let labelName = getRiskFilterNameFromRiskScale(null);
      labels.unshift((
        <div key={labelName} className="risk-map-legend-item-row">
          <div className={"risk-map-legend-shape risk-map-legend-risk-shape " + getCSSClassForLegend(null)} />
          <div className="risk-map-legend-item-description">{NEEDS_ANALYSIS_TERM}</div>
        </div>
      ));

      return labels;
    }
    return null;
  }

  resizePanelOnly(legendPanel) {
    // Redo the height because the risk map is special.
    let canvasHeight = $("#riskMapCanvas")[0].clientHeight;
    let timelineHeight = $("#riskMapTimelinePanel")[0].clientHeight;
    let grapshZoomInHeight = $("#riskMapGraphZoomInButtonDiv")[0].clientHeight;
    let grapshZoomOutHeight = $("#riskMapGraphZoomOutButtonDiv")[0].clientHeight;
    let panelHeight = canvasHeight - timelineHeight - grapshZoomOutHeight - grapshZoomInHeight - 42; //Subtracting margins
    panelHeight = Math.min(panelHeight, this.getContentsHeight() + 5);
    legendPanel.css("height", panelHeight);
  }

  getContentsHeight() {
    return 580;
  }

  getCSSPrefix() {
    return "risk-map";
  }

  renderPanelContent() {
    return (
      <Fragment>
        <div className="risk-map-legend-item-row">
          <div className="risk-map-legend-category">Types</div>
        </div>
        <div className="risk-map-legend-item-row">
          <div className="risk-map-legend-shape" />
          <div className="risk-map-legend-item-description">{this.props.filters.riskTracedToFilter === "traceToTPPs" ? "TPPs" : "GAs"}</div>
        </div>
        <div className="risk-map-legend-item-row">
          <div className="risk-map-legend-shape risk-map-legend-fqa" />
          <div className="risk-map-legend-item-description">FQAs / FPAs</div>
        </div>
        <div className="risk-map-legend-item-row">
          <div className="risk-map-iqa-legend-parent">
            <div className="risk-map-legend-shape risk-map-legend-iqa" />
          </div>
          <div className="risk-map-legend-item-description">IQAs / IPAs</div>
        </div>
        <div className="risk-map-legend-item-row">
          <div className="risk-map-legend-shape risk-map-pp up border" />
          <div className="risk-map-legend-item-description">PPs</div>
        </div>
        <div className="risk-map-legend-item-row">
          <div className="risk-map-legend-shape risk-map-legend-ma" />
          <div className="risk-map-legend-item-description">MAs</div>
        </div>
        <div className="risk-map-legend-item-row-separator" />
        <div className="risk-map-legend-item-row">
          <div id="riskMapLegendRiskTypeSpan" className="risk-map-legend-category">{this.props.riskType ? this.props.riskType.replace(" Risk", "") : ""}</div>
        </div>
        <div key="riskMapDynamicLegends">
          {this.getRiskMapLegendRiskLevels()}
        </div>
        <div className="risk-map-legend-item-row">
          <div className="risk-map-legend-category">Status</div>
        </div>
        <div className="risk-map-legend-item-row">
          <div className="risk-map-legend-item-description-approved">
            <div className="risk-map-legend-item-description">Approved</div>
          </div>
          <div className="risk-map-legend-item-description-draft">
            <div className="risk-map-legend-item-description">Not Approved</div>
          </div>
        </div>
        <div className="risk-map-legend-item-row">
          <div className="risk-map-legend-category">Size</div>
        </div>
        <div className="risk-map-legend-item-row">
          <div className="risk-map-legend-item-text-wrapper">
            <div className="risk-map-legend-item-description">Relative to<br />the number<br />of links</div>
          </div>
        </div>
      </Fragment>
    );
  }
}
