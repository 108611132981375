var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { NumericTextBox } from '@progress/kendo-react-inputs';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { messages, filterNumericFilterAriaLabel } from '../../messages';
/**
 * The NumericFilter component used for editing numeric value of FilterDescriptor object.
 */
var NumericFilter = /** @class */ (function (_super) {
    __extends(NumericFilter, _super);
    function NumericFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onChange = function (event) {
            _this.props.onFilterChange.call(undefined, {
                nextFilter: __assign(__assign({}, _this.props.filter), { value: event.value })
            });
        };
        return _this;
    }
    /**
     * @hidden
     */
    NumericFilter.prototype.render = function () {
        var locService = provideLocalizationService(this);
        var _a = this.props, filter = _a.filter, _b = _a.ariaLabel, ariaLabel = _b === void 0 ? locService.toLanguageString(filterNumericFilterAriaLabel, messages[filterNumericFilterAriaLabel]) : _b;
        return (React.createElement(NumericTextBox, { value: typeof filter.value === 'number' ? filter.value : null, onChange: this.onChange, ariaLabel: ariaLabel }));
    };
    /**
     * @hidden
     */
    NumericFilter.propTypes = {
        filter: PropTypes.object.isRequired,
        onFilterChange: PropTypes.func.isRequired
    };
    return NumericFilter;
}(React.Component));
export { NumericFilter };
registerForLocalization(NumericFilter);
