"use strict";

import PropTypes from "prop-types";
import React, { Fragment } from "react";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import ToggleInput from "react-switch";
import { BaseDropdown } from "./base_dropdown";

// i18next-extract-mark-ns-start widgets
/**
 * This class is used to show the multi-tables page filter panel
 */
class FilterDropdown extends BaseDropdown {

  constructor(props) {
    super(props);
  }

  handleShowArchivedChange(checked) {
    if (this.props.onShowArchivedChange) {
      this.props.onShowArchivedChange(checked);
    }
  }

  handleViewModeChange(newVal) {
    console.log("User clicked on " + newVal);
  }

  getButtonId() {
    return "filterDropDownButton";
  }

  renderDropDownButtonText() {
    return this.props.title;
  }

  getOuterClassName() {
    return `filter-panel-dropdown ${this.props.className}`;
  }

  isMenuRightAligned() {
    return true;
  }

  renderMenuItems() {
    const {
      showArchived,
      t,
    } = this.props;

    return (
      <Fragment>
        <div className={`row filter-panel-body align-items-center` + this.getClassForLoading()}>
          <div className="col">
            <span className="mr-3">{t("Show Archived")}</span>
            <ToggleInput id="showArchivedInput"
                         className="show-archived-checkbox-toggle"
                         checked={showArchived}
                         onChange={this.handleShowArchivedChange}
                         height={15}
                         width={31}
                         checkedIcon={false}
                         uncheckedIcon={false}
                         activeBoxShadow="0 0 2px 3px #014768"
                         onColor="#DBE1E4"
                         offColor="#DBE1E4"
                         onHandleColor="#1F46A1"
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

FilterDropdown.propTypes = {
  title: PropTypes.any,
  href: PropTypes.string,
};

export default I18NWrapper.wrap(FilterDropdown, "widgets");
// i18next-extract-mark-ns-stop widgets
