"use strict";

import React, { Fragment } from "react";
import BaseLegend from "../common/base_legend";

/**
 * This is the legend that pops out on the right of the Process Map if you click it.
 */
export default class ProcessMapLegend extends BaseLegend {
  constructor(props) {
    super(props);
  }

  getContentsHeight() {
    return 220;
  }

  getCSSPrefix() {
    return "process-map";
  }

  resizePanelOnly(legendPanel) {
    // Make the height no larger than the canvas
    let canvasHeight = $(".process-map-canvas")[0].clientHeight - 20;
    const panelHeight = Math.min(canvasHeight, this.getContentsHeight() + 5);
    legendPanel.css("height", panelHeight);
  }

  renderPanelContent() {
    return (
      <Fragment>
        <div className="process-map-legend-item-row">
          <div className="process-map-legend-category">Criticality</div>
        </div>
        <div className="process-map-legend-item-row">
          <div className="process-map-legend-shape" />
          <div className="process-map-legend-item-description">Critical</div>
        </div>
        <div className="process-map-legend-item-row">
          <div className="process-map-legend-category">Material Type</div>
        </div>
        <div className="process-map-legend-item-row">
          <div className="process-map-legend-item-text">[R]</div>
          <div className="process-map-legend-item-description">Raw/Input Material</div>
        </div>
        <div className="process-map-legend-item-row">
          <div className="process-map-legend-item-text">[I]</div>
          <div className="process-map-legend-item-description">Intermediate Material</div>
        </div>
      </Fragment>
    );
  }
}
