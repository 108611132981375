import { ConfigurableTable } from "../../configurableTables/tables/configurable_table";
import SupplierFields from "../../configurableTables/fieldsConfig/global/supplier_fields";
import CommonSecurity from "../../../server/common/generic/common_security";
import React from "react";
import SupplierTableAdapter from "../supplier_table_adapter";
import ConfigurableTablesCustomFiltersHandler
  from "../../configurableTables/columnOperations/configurable_tables_custom_filters_handler";

export const SUPPLIER_CONFIG = {
  ["Suppliers"]: {
    table: (props) => <ConfigurableTable {...props} />,
    adapter: (params) => new SupplierTableAdapter(params),
    fieldsConfig: new SupplierFields(),
    customFiltersHandler: new ConfigurableTablesCustomFiltersHandler(),
    modelName: "Supplier",
    securityModelType: CommonSecurity.Types.SUPPLIER
  },
};