"use strict";

import React, { useState, useEffect, useRef } from "react";
import ConfigurableRiskDetailsTable from "./configurable_risk_details_table";
import AcceptanceCriteriaRangesDetailsTable from "./acceptance_criteria_ranges_details_table";
import {
  ASSESSMENT_MODE_SELECTOR,
  ASSESSMENT_MODE_SELECTOR_ACCEPTANCE_CRITERIA_RANGES,
  ASSESSMENT_MODE_SELECTOR_CRITICALITY_ASSESSMENT,
} from "../columnSelection/constants";

const DetailsTableContainer = (props) => {
  const DetailsTableRef = useRef();
  const [assessmentModeSelector, setAssessmentModeSelector] = useState(ASSESSMENT_MODE_SELECTOR_CRITICALITY_ASSESSMENT);

  const showSelectedTable = (selectedValue) => {
    const assessmentModeSelectorValue = selectedValue || localStorage.getItem(ASSESSMENT_MODE_SELECTOR);

    const shouldShowMAC = (assessmentModeSelectorValue === ASSESSMENT_MODE_SELECTOR_ACCEPTANCE_CRITERIA_RANGES);

    if (shouldShowMAC) {
      setAssessmentModeSelector(ASSESSMENT_MODE_SELECTOR_ACCEPTANCE_CRITERIA_RANGES);
    } else {
      setAssessmentModeSelector(ASSESSMENT_MODE_SELECTOR_CRITICALITY_ASSESSMENT);
    }
  };

  useEffect(() => {
    showSelectedTable();

    const callback = () => {
      showSelectedTable(DetailsTableRef.current?.className);
    };

    const targetNode = DetailsTableRef.current;

    const config = {attributes: true};

    const observer = new MutationObserver(callback);

    observer.observe(targetNode, config);

    return () => observer.disconnect();
  }, []);

  return (
    <div id="DetailsTableContainer" ref={DetailsTableRef} className={assessmentModeSelector}>
      {
        assessmentModeSelector === ASSESSMENT_MODE_SELECTOR_ACCEPTANCE_CRITERIA_RANGES ?
        <AcceptanceCriteriaRangesDetailsTable requirement={props?.dataItem?.Requirement} /> :
        <ConfigurableRiskDetailsTable {...props} />
      }
    </div>
  );
};

export default DetailsTableContainer;
