import { fieldList } from './fieldList';
var getterCache = {};
getterCache.undefined = function () { return undefined; };
/**
 * Can be used for getting value from object using field path. For example `users[index].name`.
 *
 * @example
 * ```jsx-no-run
 * const values = {users:[{lastName: 'Doe'}]};
 * const firstUserLastName = getter('user[0].lastName');
 *
 * console.log(firstUserLastName(values))
 *
 * // result: 'Doe'
 * ```
 */
export function getter(field) {
    if (getterCache[field]) {
        return getterCache[field];
    }
    var fields = fieldList(field);
    getterCache[field] = function (obj) {
        var result = obj;
        for (var idx = 0; idx < fields.length && result; idx++) {
            result = result[fields[idx]];
        }
        return result;
    };
    return getterCache[field];
}
