"use strict";

import React, { Fragment } from "react";
import * as UIUtils from "../../../ui_utils";
import CommonUtils from "../../../../server/common/generic/common_utils";
import { getURLByTypeCodeAndId } from "../../../helpers/url_helper";
import { TYPE_CODE } from "../../../processExplorer/process_explorer_constants";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RecordItem = ({index, material}) => (
  <li className="record-item">
    <div className="record-item-content">

      <div className="location-label">
        {material.UnitOperation && <span>{material.UnitOperation.name}</span>}
        {material.Step && <span> {" > "} {material.Step.name}</span>}
        {
          (!material.UnitOperation && !material.Step) &&
          <span>Process Only</span>
        }
      </div>

      <div className="material-link">
            <span>
                {
                  UIUtils.getRecordCustomIdForDisplay(
                    {
                      ...material,
                      typeCode: TYPE_CODE.MATERIAL
                    })
                }
              {" · "}{material.fromLibraryStatus}
            </span>
        <span className="chevron-button">
               <a
                 id={`linkToProjectMaterial_${index}`}
                 href={getURLByTypeCodeAndId(TYPE_CODE.MATERIAL, "View", material.id)} rel="noopener noreferrer"
                 target="_blank"
               >
                    <FontAwesomeIcon icon={faChevronRight} />
               </a>
            </span>
      </div>

    </div>
  </li>
);

const RecordHeader = ({record}) => {

  const {
    project,
    process,
    materials
  } = record;

  return (
    <div className="record-header">
      <div className="project-label">{project?.name}</div>
      <span className="process-label">{process?.name}</span>
      <span className="usage-label">({materials?.length})</span>
    </div>
  );
};

const RecordGroup = ({record}) => {

  const {
    project,
    materials
  } = record;

  const projectNameNoWhiteSpaces = CommonUtils.stripAllWhitespaces(project?.name);
  return (
    <div className="record-group">
      <RecordHeader record={record} />
      <ul data-test-name={projectNameNoWhiteSpaces}
          className="materials-list"
      >
        {materials.map((material, index) =>
          <RecordItem
            key={index}
            material={material}
            index={index}
          />
        )}
      </ul>
    </div>
  );
};

/**
 *
 * @param records {Material}
 * @returns {JSX.Element}
 * @constructor
 */
const RecordGroups = ({records = []}) => {

  return CommonUtils.isEmptyArray(records)
    ? (
      <div className="empty-records">
        No data available in table
      </div>
    )
    : (
      <Fragment>
        {
          records.map((record, index) =>
            <RecordGroup key={index}
                         record={record}
            />
          )
        }
      </Fragment>
    );
};

export const ProjectUsageInformationPanel = ({id, showFromTooltip = false, records = []}) => {

  const className = !showFromTooltip ? "row-white " : "";
  const minWidthClass = showFromTooltip ? "related-projects-min-width" : "";
  return (
    <div id={id} className={className + " related-projects-container " + minWidthClass}>
      {
        showFromTooltip
        ||
        <div className="related-panel-header">
          Used in Projects
        </div>
      }
      <div className="related-panel-content">
        <RecordGroups records={records} />
      </div>
    </div>
  );
};