"use strict";

import React, { memo, useEffect, useRef, useState } from "react";
import * as styles from "./style/text_cell.module.scss";
import LabelTooltip from "../../../widgets/tooltips/label_tooltip";
import * as commonStyles from "./style/common_cell.module.scss";

export const TextAreaCell = memo(({dataItem, dataIndex, className, prop}) => {
  const textRef = useRef(null);
  const [enableShowMore, setEnableShowMore] = useState(false);

  useEffect(() => {
    const textElement = textRef.current;
    setEnableShowMore(textElement.scrollHeight > textElement.clientHeight);
  }, [textRef?.current?.clientHeight]);

  const text = dataItem[prop];
  return (
    <td className={className}>
      <div ref={textRef} className={`${commonStyles["cell-container"]} ${styles["text-area-cell-container"]}`}>
        {text}
        {enableShowMore && <LabelTooltip id={`showMore_${dataIndex}`}
                                         noColon={true}
                                         tooltipText={text}
                                         className={styles["show-more"]}
                                         moreInfo={<a onClick={() => {
                                         }}
                                         >Show more</a>}
        />}
      </div>
    </td>);
});
