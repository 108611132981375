"use strict";


import ConfigurableTablesAdapter from "../../configurableTables/adapters/configurable_tables_adapter";
import * as CommonEditablesFormatter from "../../../server/common/editables/common_editables_formatter";

export default class ControlMethodTableAdapter extends ConfigurableTablesAdapter {

  constructor(params) {
    super(params, "cmMap", "ControlMethods");
  }

  getRows() {
    const records = Object.values(this.activeMap);
    super.adaptRows(records);
    const sanitizedRecords = records.map(record => ({
      ...record,
      stabilityIndicating: record.stabilityIndicating === null ? "No" : record.stabilityIndicating,
      compendialStandard: CommonEditablesFormatter.formatMultiSelectValueForDisplay(record.compendialStandard),
      supplier: record.Supplier?.name,
    }));
    return super.sanitizeEmptyValues(sanitizedRecords);
  }

}
