"use strict";

import * as UIUtils from "./ui_utils";
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import * as URLHelper from "./helpers/url_helper";
import QuickPanel from "./editor/quick/quick_panel";
import BaseEditableListTable from "./base_editable_list_table";
import { EDITOR_OPERATIONS, EDITOR_TYPES } from "./editor/editor_constants";
import { QUICK_PANEL_BUTTONS } from "./widgets/quickPanel/quick_panel_buttons";
import * as FailHandlers from "./utils/fail_handlers";

/* This is the base class for tables that show editables with quick panel. */
// i18next-extract-mark-ns-start base_page
export default class BaseQuickEditableListTable extends BaseEditableListTable {
  constructor(props, capitalizedBaseTypeName, showRemoveBtn = true, enablePaging = true) {
    super(props, capitalizedBaseTypeName, showRemoveBtn, enablePaging);

    this.capitalizedBaseTypeName = capitalizedBaseTypeName;
    this.handleQuickEditRecord = this.props.onQuickEditRecord || this.handleQuickEditRecord.bind(this);

    this.quickPanel = React.createRef();

    const initialState = {};

    initialState.selectedTab = props && props.selectedTab || props.defaultTab;
    initialState.selectedEditorTab = props && props.selectedEditorTab || props.defaultEditorTab;

    this.setStateSafely(initialState);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const shouldComponentUpdate = super.shouldComponentUpdate(nextProps, nextState);
    if (shouldComponentUpdate) {
      return true;
    }

    const {selectedRecord, showApprovalPopup, showCopyPopup} = this.state;
    const {
      showVersionInQuickPanel, disableQuickPanel, showButtonsInNewLine, editorType, quickPanelButtons, hideQuickPanelUI,
      id
    } = this.props;

    const nextSelectedRecord = nextState.selectedRecord;
    const nextShowVersionInQuickPanel = nextProps.showVersionInQuickPanel;
    const nextDisableQuickPanel = nextProps.disableQuickPanel;
    const nextShowButtonsInNewLine = nextProps.showButtonsInNewLine;
    const nextEditorType = nextProps.editorType;
    const nextQuickPanelButtons = nextProps.quickPanelButtons;
    const nextHideQuickPanelUI = nextProps.hideQuickPanelUI;
    const nextId = nextProps.id;
    const emptySelectedRecord = {
      typeCode: "",
      id: ""
    };

    return !showApprovalPopup && !showCopyPopup &&
      ((!selectedRecord && nextSelectedRecord)
        || (selectedRecord || emptySelectedRecord).typeCode !== (nextSelectedRecord || emptySelectedRecord).typeCode
        || (selectedRecord || emptySelectedRecord).id !== (nextSelectedRecord || emptySelectedRecord).id
        || showVersionInQuickPanel !== nextShowVersionInQuickPanel
        || disableQuickPanel !== nextDisableQuickPanel
        || showButtonsInNewLine !== nextShowButtonsInNewLine
        || editorType !== nextEditorType
        || (quickPanelButtons || []).join(",") !== (nextQuickPanelButtons || []).join(",")
        || hideQuickPanelUI !== nextHideQuickPanelUI
        || id !== nextId);
  }

  generateIDColumn(action = "View") {
    if (this.props.disableQuickPanel) {
      return super.generateIDColumn(action);
    }

    const {t} = this.props;

    return {
      title: t("ID"),
      width: 10,
      data: this.createMappedCell(this.getRecordId),
      createdCell: this.createIdCell
    };
  }

  getRecordId(result, type) {
    const {showVersionInQuickPanel} = this.props;
    let modelName = UIUtils.convertToId(UIUtils.getModelNameForTypeCode(result.typeCode || this.baseTypeCode));
    if (showVersionInQuickPanel) {
      modelName = modelName + "Version";
    }

    const item = {
      ...result,
      modelName,
    };

    if (type === "display") {
      return UIUtils.getRecordCustomIdForDisplay(item);
    } else {
      // For sorting / filtering
      return UIUtils.getRecordCustomIdForSorting(item);
    }
  }

  createIdCell(td, cellData, rowData) {
    const {t, showVersionInQuickPanel} = this.props;
    const versionInfo = this.getVersionInformation(rowData);
    const translatedTypeCode = t(this.baseTypeCode);
    let typeCode = rowData.typeCode || this.baseTypeCode;
    const isTypeCodeTranslated = translatedTypeCode !== typeCode;

    let modelName = UIUtils.convertToId(UIUtils.getModelNameForTypeCode(rowData.typeCode || this.baseTypeCode));
    if (showVersionInQuickPanel) {
      modelName = modelName + "Version";
    }

    const item = {
      ...rowData,
      modelName,
      typeCode: translatedTypeCode
    };

    ReactDOM.render(
      (
        <div>
          {
            <a onClick={this.handleQuickEditRecord.bind(this, typeCode, versionInfo)}>
              {UIUtils.getRecordCustomIdForDisplay(item, {isTypeCodeTranslated})}
            </a>
          }
        </div>
      ), td);
  }

  getVersionInformation(rowData) {
    const {showVersionInQuickPanel} = this.props;
    const idField = UIUtils.convertToId(UIUtils.getModelNameForTypeCode(this.baseTypeCode)) + "Id";
    let id;
    let versionId;
    let majorVersion;
    let minorVersion;

    if (showVersionInQuickPanel) {
      id = rowData[idField];
      versionId = rowData.id;
      majorVersion = rowData.majorVersion;
      minorVersion = rowData.minorVersion;
    } else {
      id = rowData.id;
      versionId = rowData.LastVersionId;
    }
    return {id, versionId, majorVersion, minorVersion};
  }

  generateNameColumn(action = "View", width = 300) {
    if (this.props.disableQuickPanel) {
      return super.generateNameColumn(action, width);
    }

    const {t} = this.props;

    return {
      title: t("Name"),
      width: width,
      data: this.createMappedCell((result) => {
        return result.name;
      }),
      createdCell: this.createMappedColumnDef((td, cellData, rowData) => {
        let typeCode = rowData.typeCode || UIUtils.findTypeCodeForModelName(rowData.modelName) || this.baseTypeCode;
        const versionInfo = this.getVersionInformation(rowData);
        const content = (
          <Fragment>
            {this.getItemName(rowData)}
            {this.isApprovedVersion(rowData) && !this.props.hideApprovedLabel ? (
              <sup className="results-table-approved-flag"> {t("approved")}</sup>
            ) : ""}
          </Fragment>
        );
        ReactDOM.render(
          (
            <div>
              {
                <a onClick={this.handleQuickEditRecord.bind(this, typeCode, versionInfo)}>
                  {content}
                </a>
              }
            </div>
          ), td);
      }),
    };
  }

  getItemName(rowData) {
    const {t} = this.props;
    return this.capitalizedBaseTypeName === "TPPSection" ||
    this.capitalizedBaseTypeName === "GeneralAttribute" ?
      t(rowData.name) : rowData.name;
  }

  isApprovedVersion(version) {
    return UIUtils.parseInt(version.minorVersion) === 0;
  }

  handleReceiveDataFromServer(results) {
    const {disableQuickPanel} = this.props;

    let promise = Promise.resolve(results);

    if (typeof (this.props.onDataPreProcess) === "function") {
      promise = promise.then(data => this.props.onDataPreProcess(data));
    }

    if (disableQuickPanel) {
      promise = promise.then(data => super.handleReceiveDataFromServer(data));
    } else {
      promise = promise.then(data => data && this.loadQuickPanelData(data.instances, data));
    }
    return promise;
  }

  raiseOnDataReceived(results) {
    if (this.props && typeof this.props.onDataReceived === "function") {
      this.props.onDataReceived(results);
    }
  }

  loadQuickPanelData(instances, rawData) {
    let requirementIds = new Set();

    for (const instance of instances) {
      requirementIds.add(this.baseTypeCode + "-" + instance.id);
    }
    const handleArchivedOnClientSide = typeof this.props.onShowRemovedToggle === "function";

    UIUtils.setHideLoadingOnAjaxStop(true);
    const urlParameters = {
      showRemoved: !!(this.state.showRemoved || handleArchivedOnClientSide),
      onlyLoadDetails: true,
      returnLatestApprovedOrDraftVersionsOnly: true,
      includeHistory: true,
      ...this.getAdditionalRequestData()
    };

    UIUtils.secureAjaxPUT(`editables/multiple/list/${this.props.projectId}?${UIUtils.objectToURLParameters(urlParameters)}`,
      {
        requirementIds: [...requirementIds],
      },
      true,
      FailHandlers.defaultStopLoadingFailFunction.bind(this)
    ).done(data => this.handleReceiveQuickPanelDataFromServer(data, rawData));
  }

  handleReceiveQuickPanelDataFromServer(results, rawResults) {
    let instances = results.instances || [];

    if (typeof this.props.onQuickPanelInstanceReceived === "function") {
      results.instances = instances.map(item => {
        let mergedItem = this.mergeQuickPanelDataItem(rawResults, item);
        return this.props.onQuickPanelInstanceReceived(mergedItem, results, rawResults);
      });
    } else {
      results.instances = instances.map(item => this.mergeQuickPanelDataItem(rawResults, item));
    }

    this.raiseOnDataReceived(results);
    this.setStateSafely({instances: results.instances, isDataLoading: false});
  }

  mergeQuickPanelDataItem(rawResults, item) {
    const rawItem = rawResults.instances.find(rawItem => rawItem.id === item.id);

    return {
      ...(rawItem || {}),
      ...item,
    };
  }

  handleQuickEditRecord(typeCode, versionInfo) {
    const {id, versionId} = versionInfo;

    const {showVersionInQuickPanel, filter} = this.props;

    const selectedRecord = this.getInstances().find(record => {
      return showVersionInQuickPanel
        ? record.id === versionId
        : record.id === id;
    });

    const quickPanelInfo = {typeCode, ...versionInfo};

    const quickPanel = this.quickPanel.current;
    if (quickPanel && quickPanel.isVisible()) {
      this.setStateSafely({
        selectedRecord,
        quickPanelInfo,
      });
      quickPanel.expandToOpen();
    } else {
      // This is for screen less than 500x500 pixels so it opens in a new window instead of the quick panel

      const url = showVersionInQuickPanel && filter.goToVersion
        ? URLHelper.getURLByTypeCodeAndIdAndVersionId(typeCode, "View", id, false, versionId)
        : URLHelper.getURLByTypeCodeAndId(typeCode, "View", id, false);
      window.open(url, "_blank");
    }
  }

  handleCancelQuickEditLoad(oldTypeCode, oldId, oldVersionId) {
    this.setStateSafely({
      quickPanelInfo: {typeCode: oldTypeCode, id: oldId, versionId: oldVersionId}
    });
  }

  renderQuickPanel() {
    let {t, showVersionInQuickPanel, editorType} = this.props;
    let {quickPanelInfo, selectedRecord, selectedTab, selectedEditorTab} = this.state;

    editorType = editorType ? editorType : EDITOR_TYPES.QUICK_PANEL;

    const configFromProps = this.props.quickPanelConfig;
    let quickPanelConfig = {
      showHistory: false,
      quickPanelButtons: this.getQuickPanelButtons(),
      selectedEditorTab,
      selectedTab,
      sectionsCollapsed: this.collapseAllSections(),
      showButtonsInNewLine: !!this.props.showButtonsInNewLine,
      ...configFromProps,
    };
    const originalWidth = quickPanelConfig.originalWidth;

    return (
      <QuickPanel typeCode={this.baseTypeCode}
                  ref={this.quickPanel}
                  id={quickPanelInfo ? quickPanelInfo.id : undefined}
                  versionId={showVersionInQuickPanel && quickPanelInfo ? quickPanelInfo.versionId : undefined}
                  config={quickPanelConfig}
                  originalWidth={originalWidth}
                  editorType={editorType}
                  editorOperation={this.props.editorOperation ? this.props.editorOperation : EDITOR_OPERATIONS.VIEW}
                  emptySelectionMessage={t("Click on any record to view it here.")}
                  data={selectedRecord}
                  onCancelLoad={this.handleCancelQuickEditLoad}
                  onActionClick={(action, state, callback) => {
                    return typeof this.props.onQuickPanelActionClick === "function"
                      ? this.props.onQuickPanelActionClick(action, state, callback)
                      : null;
                  }}

      />
    );
  }

  getQuickPanelButtons() {
    return Array.isArray(this.props.quickPanelButtons)
      ? this.props.quickPanelButtons
      : [QUICK_PANEL_BUTTONS.VIEW];
  }

  collapseAllSections() {
    return true;
  }

  getCurrentState(record) {
    const {filter, t} = this.props;
    const isApprovedFilter = filter && filter.key === UIUtils.VERSION_STATES.APPROVED;

    return UIUtils.parseInt(record.minorVersion) === 0 && isApprovedFilter ? t(UIUtils.VERSION_STATES.APPROVED) : t(record.currentState);
  }

  render() {
    const {disableQuickPanel, hideQuickPanelUI, id, className} = this.props;

    return (
      <div>
        <table ref={ref => this.tableRef = ref}
               className={"table table-bordered table-hover" + (className ? ` ${className}` : "") + this.getClassForLoading()}
               id={id || "resultsTable"} style={{width: "100%"}}
        />
        {disableQuickPanel || hideQuickPanelUI ? "" : this.renderQuickPanel()}
        {this.renderApprovalPopup()}
        {this.renderCopyPopup()}
      </div>
    );
  }
}
// i18next-extract-mark-ns-stop base_page
