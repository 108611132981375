"use strict";

import * as UIUtils from "../../../../../ui_utils";
import {SORT_AFTER, SORT_BEFORE, SORT_SAME, sortIQAVersions} from "../../../../canned_reports/canned_report_helper";
import {SpecificationTransformStrategy} from "./specification_transform_strategy";
import {orderAndIndexUnits} from "../../../../../processExplorer/indexers/uo_indexer";

export class IntermediateSpecificationTransformStrategy extends SpecificationTransformStrategy {
  sortRecords(records) {
    records.sort(sortIQAVersions);
  }

  fillGeneralInformation(record, requirement) {
    record.iQAId = Number(requirement.IQAId);

    if (requirement.modelType === "UO") {
      record.id = Number(requirement.UnitOperationId);
      record.currentState = requirement.currentState;
      record.PreviousUnitId = requirement.UnitOperation && requirement.UnitOperation.PreviousUnitId
        ? Number(requirement.UnitOperation.PreviousUnitId)
        : null;
    }
  }

  fillParentInformation(record, requirement) {
    record.unitOperationId = UIUtils.secureString(requirement.unitOperationId);
    record.unitOperation = UIUtils.secureString(requirement.unitOperation);
    record.output = UIUtils.secureString(requirement.output);
  }

  fillAcceptanceCriteria() {
    // does not get changed in this report
  }

  fillControlInformation() {
    // does not get changed in this report
  }

  wrapUp(result) {
    const instances = result.instances;

    if (!instances.iqas || instances.iqas.length === 0) {
      instances.isEmptySummary = true;
    }

    instances.uos = orderAndIndexUnits(instances.uos);
    instances.iqas.sort(this.sortIQAVersions(instances.uos));

    return result;
  }

  sortIQAVersions(uos = []) {
    return (i1, i2) => {
      let result = SORT_SAME;
      const uo1Index = uos.length > 0 ? uos.findIndex(uo => uo.id === i1.unitOperationId) : i1.unitOperationId;
      const uo2Index = uos.length > 0 ? uos.findIndex(uo => uo.id === i2.unitOperationId) : i2.unitOperationId;

      if (uo1Index < uo2Index) {
        result = SORT_BEFORE;
      } else if (uo1Index > uo2Index) {
        result = SORT_AFTER;
      } else if (i1.iQAId < i2.iQAId) {
        result = SORT_BEFORE;
      } else if (i1.iQAId > i2.iQAId) {
        result = SORT_AFTER;
      }
      return result;
    };
  }
}
