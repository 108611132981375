"use strict";

import * as UIUtils from "../../ui_utils";
import Sockette from "sockette";
import {CommonString} from "../../../server/common/generic/common_string";
import {Log, LOG_GROUP} from "../../../server/common/logger/common_log";
import CommonCompression from "../../../server/common/generic/common_compression";

const Logger = Log.group(LOG_GROUP.ClientServices, "WebSocketsLibraryWrapper");

const MAX_CHARACTERS_PER_MESSAGE = 32768;

export class WebSocketsLibraryWrapper {
  /**
   * Creates a new instance of the wrapper library
   * @param url
   * @param options
   */
  constructor(url, options) {
    /* This is a message decorator which makes sure that that all packages of a message have been received on the
       client before calling the actual onMessage event attached to the options property. The server will split the message send
       to the client in chunks of data that do not exceed 32Kb, due to the WebSockets frame size limitation. It will
       then send each package individually to the client. The client will then use this event decorator to
       reconstruct the original message by getting all the packages and joining them together. Once the full message
       has been received, then it will be made available to the UI through the original option.onMessage event passed
       in the constructor.
     */
    this.onMessageDecorator = this.onMessageDecorator.bind(this);

    // Decorate the original onmessage event so that it fires only when all message packages have been received
    options.onmessage = this.onMessageDecorator(options.onmessage);

    /**
     * @private
     * @type {{}}
     */
    this.sessionDataPackages = {};

    /**
     * @private
     * @type {Sockette}
     */
    this.sockette = new Sockette(url, options);

    /**
     * @private
     * @type {string}
     */
    this.url = url;
  }

  /**
   * * This is an event decorator which only triggers the original WebSockets on message event when all the
   * message packages have been received on the client. For messages larger than 32Kb, the server will be splitting
   * those into smaller chunks and sending them to the client in a dataPackage object. The dataPackage object holds
   * a message correlation id to correlate packages of the same message, a packageNumber, so that the packages can be
   * used on the client to reconstruct the original message and the total number of packages expected for each message,
   * so that the client knows when all the packages of a message have been received. Once all the packages of a message
   * have been received, the client reconstructs the original message and triggers the original WebSockets onMessage
   * event, passing in the full message.
   * @param originalMessage The original message being decorated
   * @returns {function(...[*]=)}
   */
  onMessageDecorator(originalMessage) {
    Logger.debug(() => "onMessageDecorator:", Log.object(originalMessage));
    return e => {
      // decodes url and parses the json so the handler doesn't need to care about it
      const event = {...e};
      const dataPackage = JSON.parse(e.data);
      if (!this.sessionDataPackages[dataPackage.messageCorrelationId]) {
        this.sessionDataPackages[dataPackage.messageCorrelationId] = [];
      }
      let dataChunks = this.sessionDataPackages[dataPackage.messageCorrelationId];
      if (!dataChunks.find(dataChunk => dataChunk.packageNumber === dataPackage.packageNumber)) {
        dataChunks.push(dataPackage);
      } else {
        Logger.error(() => `Same package retrieved twice: ${dataPackage.packageNumber} for correlation id ${dataPackage.messageCorrelationId}`);
      }

      if (dataChunks.length === dataPackage.totalPackages) {
        const compressedEncodedData = dataChunks.sort(UIUtils.sortBy("packageNumber"))
          .map(sessionPackage => sessionPackage.rawData)
          .join("");
        const compressedData = CommonString.base64ToLatin1(compressedEncodedData);
        const data = CommonCompression.decompress(compressedData);
        event.data = JSON.parse(data);

        // Clean up the packages from the buffer.
        delete this.sessionDataPackages[dataPackage.messageCorrelationId];

        // Invoke the original onMessage event
        return originalMessage(event);
      }
    };
  }

  /**
   * Sends a message to the websocket
   * @param data {*} The data to be sent
   */
  send(data) {
    Logger.debug(() => "Sending data:", Log.object(data));
    return this.sockette.send(data);
  }

  /**
   * Sends a json message to the websocket
   * @param data {*} The data to be sent
   */
  json(data) {
    Logger.info(() => `Connecting to websockets: ${data.action} Model: ${data.model} URL Prefix: ${data.urlPrefix}`);

    let completeData = {
      headers: {
        Referer: window.location.href,
        "User-Agent": navigator.userAgent,
        "X-QbDVision-Url": this.url,
        "X-QbDVision-RequestId": UIUtils.generateUUID(),
      },
      token: UIUtils.getAccessToken(),
      ...data,
      data: "",
    };
    const headerDataSize = JSON.stringify(completeData).length + 150 /* For the data package header */;
    const MAX_PAYLOAD_SIZE = MAX_CHARACTERS_PER_MESSAGE - headerDataSize;
    Logger.verbose(() => "Max Payload size:", MAX_PAYLOAD_SIZE);

    /* If the data we need to send to the server is larger than 128Kb, then we cannot use the sockette library
       and the sockette.json() method as we will hit the 128Kb message limit size in WebSockets. In this case, we need
       split the payload into chunks of data and send it using multiple WebSocket messages. Before sending the data
       we create a base64 representation of it to deal with UTF characters in the payload also. This way we can split
       the payload to equal data packages. Without doing this conversion, it is too hard to decide how to split the
       original payload into chunks as the UTF characters can use up to 4 bytes and are not necessarily expected to be
       distributes equally in the payload.
     */
    let stringifiedData = typeof data.data === "object" ? JSON.stringify(data.data) : data.data;
    let isCompressed = stringifiedData.length > MAX_PAYLOAD_SIZE / 4;
    Logger.verbose(() => "Is Compressed?", isCompressed, "Original Data Size:", stringifiedData.length);
    let base64Data;
    if (isCompressed) {
      stringifiedData = CommonCompression.compress(stringifiedData);
      Logger.verbose(() => "After compression. Size after: " + stringifiedData.length);
      base64Data = CommonString.latin1ToBase64(stringifiedData);
    } else {
      base64Data = CommonString.stringToBase64(stringifiedData);
    }
    Logger.verbose(() => "After conversion to base64. Size after: " + base64Data.length);

    let promiseChain = Promise.resolve();
    let messageCorrelationId = UIUtils.generateUUID();

    const totalPackages = Math.ceil(base64Data.length / MAX_PAYLOAD_SIZE);
    Logger.verbose(() => "Sending total packages:", totalPackages);
    for (let packageNumber = 1, packageStartingIndex = 0; packageNumber <= totalPackages; ++packageNumber, packageStartingIndex += MAX_PAYLOAD_SIZE) {
      let dataPackage = {
        rawData: base64Data.substring(packageStartingIndex, packageStartingIndex + MAX_PAYLOAD_SIZE),
        packageNumber,
        totalPackages,
        isCompressed,
        messageCorrelationId,
      };

      promiseChain = promiseChain.then(() => {
        Logger.info(() => `Sending package ${packageNumber} of ${totalPackages}...`);
        return this.sockette.json({
          ...completeData,
          dataPackage,
          data: ""
        });
      });
    }

    return promiseChain;

  }

  /**
   * Closes the websocket connection
   * @param code {number?} The status code to be sent
   * @param reason {string?} The message to be sent
   */
  close(code, reason) {
    Logger.debug(() => `Closing because [Code: ${code}] ${reason}`);
    this.sockette.close(code, reason);
  }

  /**
   * Reconnects to the websocket
   */
  reconnect() {
    Logger.debug(() => `Reconnecting...`);
    this.sockette.reconnect();
  }

  /**
   * Opens a websocket connection;
   */
  open() {
    Logger.debug(() => `Opening...`);
    this.sockette.open();
  }
}
