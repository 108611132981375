import React from "react";
import { RISK_TYPE_ENUM } from "../../helpers/constants/constants";

export const RMP_RISK_TABLES_DISPLAY_LABEL_ENUM = {
  RISK_LABEL: "riskLabel",
  SCORE_LABEL: "scoreLabel",
  BOTH: "both"
};

export const RISK_COLORS = {
  NONE: "None",
  BLUE: "Blue",
  GREEN: "Green",
  YELLOW: "Yellow",
  ORANGE: "Orange",
  RED: "Red",
  GREY: "Grey"
};

export const RISK_ATTRIBUTES_TABS_TO_RISK_TYPES = {
  Impact: RISK_TYPE_ENUM.IMPACT,
  Uncertainty: RISK_TYPE_ENUM.UNCERTAINTY,
  Criticality: RISK_TYPE_ENUM.CRITICALITY,
  Capability: RISK_TYPE_ENUM.CAPABILITY_RISK,
  Process: RISK_TYPE_ENUM.PROCESS_RISK,
  Detectability: RISK_TYPE_ENUM.DETECTABILITY_RISK,
  RPN: RISK_TYPE_ENUM.RPN,
};

export const RISK_ATTRIBUTES_TABS = {
  IMPACT: {
    title: "Impact",
    info: ""
  },
  UNCERTAINTY: {
    title: "Uncertainty",
    info: "",
    disabledTooltip: "Check Use Uncertainty to enable this tab"
  },
  CRITICALITY_SCALE: {
    title: "Criticality",
    info: ""
  },
  CAPABILITY_RISK: {
    title: "Capability",
    info: ""
  },
  PROCESS_RISK_SCALE: {
    title: "Process",
    info: ""
  },
  DETECTABILITY_RISK: {
    title: "Detectability",
    info: "",
    disabledTooltip: "Check Use Detectability to enable this tab"
  },
  RPN_SCALE: {
    title: "RPN",
    info: ""
  }
};

export const RISK_ATTRIBUTES = [
  "Impact", "Uncertainty",
  "CriticalityScale", "CapabilityRisk",
  "ProcessRiskScale", "DetectabilityRisk", "RPNScale"
];

export const RISK_BOUNDARIES_ATTRIBUTES = [
  "Impact", "Uncertainty",
  "Criticality", "CapabilityRisk",
  "ProcessRisk", "DetectabilityRisk"
];

let RiskAttributeOptions = [
  "Uncertainty",
  "Detectability",
];

export const REQUIREMENT_TABS = {
  FQA: {
    id: "fqa",
    active: true,
    displayName: "FQA",
    fullName: "Final Quality Attributes",
    riskAttributes: RISK_ATTRIBUTES,
    riskAttributeOptions: RiskAttributeOptions
  },
  IQA: {
    id: "iqa",
    active: false,
    displayName: "IQA",
    fullName: "Intermediate Quality Attributes",
    riskAttributes: RISK_ATTRIBUTES,
    riskAttributeOptions: RiskAttributeOptions
  },
  PP: {
    id: "processParameter",
    active: false,
    displayName: "PP",
    fullName: "Process Parameters",
    riskAttributes: RISK_ATTRIBUTES,
    riskAttributeOptions: RiskAttributeOptions
  },
  MA: {
    id: "materialAttribute",
    active: false,
    displayName: "MA",
    fullName: "Material Attributes",
    riskAttributes: RISK_ATTRIBUTES,
    riskAttributeOptions: RiskAttributeOptions
  },
  PA: {
    id: "performanceAttribute",
    active: false,
    displayName: "Performance Attribute",
    fullName: "Performance Attributes",
    riskAttributes: RISK_ATTRIBUTES,
    riskAttributeOptions: RiskAttributeOptions
  },
  TT: {
    id: "techTransfer",
    active: false,
    displayName: "Tech Transfer",
    fullName: "Tech Transfer",
    riskAttributes: RISK_ATTRIBUTES,
    riskAttributeOptions: RiskAttributeOptions
  },
};

export const COPY_RMP_REQUIREMENT_OPTIONS = [
  {
    value: "Copy to all",
    key: "copyToAll",
    title: "Copy to all"
  },
  {
    value: "Final Quality Attribute (FQA)",
    key: "copyFinalQualityAttribute",
    title: "FQA"
  },
  {
    value: "Intermediate Quality Attribute (IQA)",
    key: "copyIntermediateQualityAttribute",
    title: "IQA"
  },
  {
    value: "Process Parameter (PP)",
    key: "copyProcessParameter",
    title: "PP"
  },
  {
    value: "Material Attribute (MA)",
    key: "copyMaterialAttribute",
    title: "MA"
  }, {
    value: "Performance Attribute",
    key: "copyPerformanceAttribute",
    title: "PA"
  }, {
    value: "Tech Transfer",
    key: "copyTechTransfer",
    title: "TT"
  }
];

export const RMP_VERBIAGE = <div>
  Effective risk analysis is a progression that starts with risks
  to patient safety and efficacy. Product attributes that may impact
  patient requirements are evaluated next. Finally, process risks to
  product quality attributes are evaluated and mitigated with proper
  definition of acceptance criteria. In this way, risk-based
  development arrives at a well-controlled process.<br /><br />

  QbDVision uses a Risk Assessment model that seeks to rationalize
  the various definitions of risk into a consistent set that can be
  applied to any type of product development scenario
  (drug, medical device, diagnostic, etc.) while staying consistent
  with international standards such as ISO 14971.<br /><br />

  The presence of risk is associated with a hazardous situation that has
  the potential to cause harm. The first layer of risk assessment starts
  with Criticality, which is the product of Impact (severity) of the harm
  and the Uncertainty of the harm. In our Risk Assessment model,
  Impact is conceptually the same as the severity of harm. Similarly,
  Uncertainty is conceptually the same as the Likelihood of harm.
  So, in the context of ISO 14971, Criticality is the product of the severity
  of harm and the Likelihood of harm, even though this definition is not
  immediately apparent in the standard.<br /><br />

  The second layer of risk folds in the Capability Risk, which is conceptually
  the same as the Occurrence of measured data outside the acceptable range.
  If measured data are frequently outside of the acceptable range, the Occurrence
  is high. Put another way, the risk related to the process capability is high.
  The product of Criticality and Occurrence provides a measure of Process Risk,
  which matches the definition of risk in ISO 14971. In other words, the product
  of Impact (severity) and probability of occurrence (Likelihood of harm * Occurrence)
  is equal to the product of Criticality and Occurrence. Even though the ISO 14971
  standard does not include Detectability in its discussion of risk, this final layer
  of risk has also been included in the comparison to yield the Risk Priority Number (RPN),
  which is a measure of overall risk.<br /><br />

  It is important to note Criticality is driven by Impact (severity) and Uncertainty
  (Likelihood) and is not improved by reduction in Capability Risk (Occurrence) or
  Detectability Risk. Better process capability which reduces the probability of an
  attribute or parameter being outside of the acceptable range as demonstrated by
  manufacturing data will reduce Process Risk. Similarly, earlier or more sensitive
  detection capability will reduce Detectability Risk and drive lower overall risk.
  With this structure, an attribute or parameter can be Critical but have low overall
  risk with robust process capability and control. This is the methodology used to
  define your design space, where these attributes and parameters can vary within
  predefined limits without impacting critical quality attributes (CQAs).
</div>;
