import * as React from 'react';
import { useDraggable } from './hooks';
/**
 * Represents the KendoReact Draggable component.
 *
 * Accepts properties of type [DraggableProps]({% slug api_common_draggableprops %}),
 * and returns an object of type [DraggableHandle]({% slug api_common_draggablehandle %}) when the `ref` is obtained.
 */
export var Draggable = React.forwardRef(function (props, ref) {
    var childRef = React.useRef(null);
    var target = React.useRef(null);
    var getElement = React.useCallback(function () { return childRef.current && childRef.current.element
        ? childRef.current.element
        : childRef.current; }, [childRef]);
    React.useImperativeHandle(target, function () { return ({ element: getElement() || null }); });
    React.useImperativeHandle(ref, function () { return target.current; });
    React.useImperativeHandle(props.childRef, function () { return childRef.current; });
    var handlePress = React.useCallback(function (event) {
        if (!props.onPress) {
            return;
        }
        props.onPress({
            element: getElement(),
            target: target.current,
            event: event
        });
    }, [getElement, props.onPress]);
    var handleRelease = React.useCallback(function (event) {
        if (!props.onRelease) {
            return;
        }
        props.onRelease({
            element: getElement(),
            target: target.current,
            event: event
        });
    }, [getElement, props.onRelease]);
    var handleDragStart = React.useCallback(function (event) {
        if (!props.onDragStart) {
            return;
        }
        props.onDragStart({
            element: getElement(),
            target: target.current,
            event: event
        });
    }, [props, getElement]);
    var handleDrag = React.useCallback(function (event) {
        if (!props.onDrag) {
            return;
        }
        props.onDrag({
            element: getElement(),
            target: target.current,
            event: event
        });
    }, [getElement, props.onDrag]);
    var handleDragEnd = React.useCallback(function (event) {
        if (!props.onDragEnd) {
            return;
        }
        props.onDragEnd({
            element: getElement(),
            target: target.current,
            event: event
        });
    }, [getElement, props.onDragEnd]);
    useDraggable(childRef, {
        onPress: handlePress,
        onRelease: handleRelease,
        onDragStart: handleDragStart,
        onDrag: handleDrag,
        onDragEnd: handleDragEnd
    }, {
        mouseOnly: props.mouseOnly,
        autoScroll: props.autoScroll,
        hint: props.hint,
        scrollContainer: props.scrollContainer
    });
    return props.children
        ? (React.cloneElement(React.Children.only(props.children), { ref: childRef }))
        : null;
});
Draggable.displayName = 'KendoReactDraggable';
