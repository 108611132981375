import { DATA_DIALOGS_ID, DIALOGS_SELECTOR, ZINDEX_DIALOGS_STEP } from './constants';
/** @hidden */
export var getMaxZIndex = function (currentZIndex, currentDocument, currentWindowId) {
    var maxZindex = currentZIndex;
    if (currentDocument && currentDocument.defaultView) {
        var allWindows = currentDocument.querySelectorAll(DIALOGS_SELECTOR);
        var zIndexChanged_1 = false;
        allWindows.forEach(function (KRwindow) {
            var computedStyle = currentDocument.defaultView.getComputedStyle(KRwindow, null);
            var dataId = KRwindow.getAttribute(DATA_DIALOGS_ID);
            if (dataId !== currentWindowId && computedStyle.zIndex !== null) {
                var zIndexValue = parseInt(computedStyle.zIndex, 10);
                if (zIndexValue >= maxZindex) {
                    maxZindex = zIndexValue;
                    zIndexChanged_1 = true;
                }
            }
        });
        return zIndexChanged_1 ? (maxZindex + ZINDEX_DIALOGS_STEP) : maxZindex;
    }
    return maxZindex;
};
