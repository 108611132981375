var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { orderBy as kendoDataQueryOrderBy, filterBy as kendoDataQueryFilterBy } from '@progress/kendo-data-query';
// Gets and sets the children. Returns the children or the re-created item with the new children.
function children(item, subItemsField, subItems) {
    var _a;
    if (subItems) {
        return __assign(__assign({}, item), (_a = {}, _a[subItemsField] = subItems.length ? __spreadArray([], subItems, true) : undefined, _a));
    }
    else {
        return item && item[subItemsField] ? __spreadArray([], item[subItemsField], true) : [];
    }
}
function sortChildren(dataItem, sort, subItemsField) {
    var _a;
    if (dataItem[subItemsField]) {
        var item = children(dataItem, subItemsField, kendoDataQueryOrderBy(children(dataItem, subItemsField), sort));
        var subItems = children(item, subItemsField).map(function (root) { return sortChildren(root, sort, subItemsField); });
        return __assign(__assign({}, item), (_a = {}, _a[subItemsField] = subItems, _a));
    }
    return dataItem;
}
/**
 * Orders the specified tree according to the provided sort descriptors.
 *
 * @param {T[]} data - The data that will be sorted.
 * @param {SortDescriptor[]} descriptors - The descriptors by which the data will be sorted.
 * @param {string} subItemsField - The field which points to the subitems collection of each data item.
 * @returns {T[]} - The sorted data.
 */
export function orderBy(data, descriptors, subItemsField) {
    var sorted = kendoDataQueryOrderBy(data, descriptors);
    sorted = sorted.map(function (dataItem) { return sortChildren(dataItem, descriptors, subItemsField); });
    return sorted;
}
function filterChildren(dataItem, filters, subItemsField) {
    var subItems = children(dataItem, subItemsField);
    if (subItems && subItems.length) {
        subItems = subItems.map(function (item) { return filterChildren(item, filters, subItemsField); });
        var hasChildrenFilter = { operator: function (item) { return Boolean((item[subItemsField] || []).length); } };
        var filter = {
            filters: [
                { filters: filters, logic: 'and' },
                hasChildrenFilter
            ],
            logic: 'or'
        };
        var filtered = kendoDataQueryFilterBy(subItems, filter);
        return children(dataItem, subItemsField, filtered);
    }
    return dataItem;
}
/**
 * Filters the provided data tree according to the specified `Array<FilterDescriptor|CompositeFilterDescriptor>`.
 *
 * @param {T[]} data - The data that will be filtered.
 * @param {FilterDescriptor[]|CompositeFilterDescriptor[]} descriptors - The filter criteria that will be applied.
 * @param {string} subItemsField - The field which points to the subitems collection of each data item.
 * @returns {T[]} - The filtered data.
 */
export function filterBy(data, descriptors, subItemsField) {
    var _a;
    var filtered = (_a = {}, _a[subItemsField] = __spreadArray([], data, true), _a);
    filtered = filterChildren(filtered, descriptors, subItemsField);
    return filtered[subItemsField] || [];
}
