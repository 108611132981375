"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import ReactDOMServer from "react-dom/server";
import { getURLByKey, getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { can, generateTooltip } from "../../utils/ui_permissions";
import CommonSecurity from "../../../server/common/generic/common_security";
import { getProjectFromCache } from "../../utils/project_helper";
import RelatedRecordsPanel from "./related_records_panel";

/**
 * This is responsible for rendering the related records of an attribute in the view attribute page.
 */
export default class RelatedBatchesPanel extends RelatedRecordsPanel {
  constructor(props) {
    super(props);
  }

  /**
   * Returns the full key of a record given the model name and the record id
   * @param record
   * @returns {string}
   */
  getRecordFullKey(record) {
    return `${UIUtils.getTypeCodeForModelName(this.modelFullName)}-${record.id}`;
  }

  /**
   * Creates a react element that can be used as a link on the UI and redirect the user to a particular record.
   * @param record The
   * @returns {*}
   */
  generateRecordLink(record) {
    let recordKey = this.getRecordFullKey(record);
    let displayText = record.customID;
    let id = UIUtils.convertToId(record.customID);
    return <div
      id={`relatedBatch-${id}`}
    >
      <div>
        <div>
          <a href={getURLByKey(recordKey, "View", false)}
             target="_blank" rel="noopener noreferrer"
          >
            {displayText}
            {record.isApproved ? (
              <sup className="results-table-approved-flag">approved</sup>
            ) : ""}
          </a>
        </div>
        <div className="related-batch-import-date">
          Imported on {UIUtils.getDateForDisplayToUser(record.createdAt, UIUtils.DATE_FORMAT_FOR_DISPLAY)}
        </div>
      </div>
    </div>;
  }

  getAddNewRecordURL() {
    const {modelName, parent, parentModelName, projectId} = this.props;

    let typeCode = UIUtils.getTypeCodeForModelName(modelName);
    let parentTypeCode = parentModelName ? UIUtils.getTypeCodeForModelName(parentModelName) : "";
    let parentRecordId = parent.state.id;

    let redirectionURL = UIUtils.getSecuredURL(getURLByTypeCodeAndId(typeCode, "Add", projectId));

    if (parentRecordId && parentTypeCode) {
      redirectionURL += "&parentId=" + parentRecordId;
      redirectionURL += "&parentTypeCode=" + parentTypeCode;
      redirectionURL += "&returnURL=" + encodeURIComponent(UIUtils.FRONT_END_URL + getURLByTypeCodeAndId(parentTypeCode, "View", parentRecordId));
    }

    return redirectionURL;
  }

  getColumns() {
    return [
      {
        className: "related-records-table-column",
        data: (item, type) => {
          if (type === "display") {
            return ReactDOMServer.renderToStaticMarkup(this.generateRecordLink(item));
          } else {
            return item.id;
          }
        }
      }
    ];
  }

  prepareRecordsForDisplay(records) {
    const parentState = this.props.parent.getCurrentState();
    return records.filter(record => parentState.startsWith(UIUtils.VERSION_STATES.ARCHIVED) || (record && !record.deletedAt))
      .map(record => {
        return record;
      }).sort((a, b) => {
        return (a.isApproved ? -1 : 1) - (b.isApproved ? -1 : 1);
      });
  }

  handleAddButtonClick() {
    if (can(CommonSecurity.Actions.ADD, this.modelFullName)) {
      UIUtils.setLoadingDisabled(false);
      UIUtils.showLoadingImage();
      window.location = this.getAddNewRecordURL();
    }
  }

  render() {
    let thisProject = getProjectFromCache(this.props.projectId);
    let thisProcess = this.getProcessFromCache();

    return (
      <div className="related-records-container row-white">
        <div className="related-records-title">
          <div>
            {this.props.header}
          </div>
          <div>
            {!(thisProject && thisProject.deletedAt) && this.props.showAdd && !(thisProcess && thisProcess.deletedAt) ? (
              <Fragment>
                <button id={`add${this.props.modelName}RelatedRecordButton`}
                        title={generateTooltip(CommonSecurity.Actions.ADD, this.modelFullName)}
                        className="btn btn-sm btn-secondary related-record-add-button"
                        onClick={this.handleAddButtonClick}
                        disabled={!can(CommonSecurity.Actions.ADD, this.modelFullName)}
                >
                  Add
                </button>
              </Fragment>
            ) : ""}
          </div>
        </div>
        <div>
          <table ref={ref => this.tableRef = ref}
                 className="table table-hover related-records-table"
                 id={`${this.props.id}Table`}
                 style={{width: "100%"}}
          />
        </div>
      </div>
    );
  }

  getBatchesListURL() {
    let thisProject = getProjectFromCache(this.props.projectId);

    if (!thisProject) {
      return null;
    }

    const urlParams = UIUtils.objectToURLParameters({
      projectId: thisProject.id,
    });
    return UIUtils.getSecuredURL(`/batches/list.html?${urlParams}`, {});
  }
}

RelatedBatchesPanel.defaultProps = {
  modelName: "Batch",
};
