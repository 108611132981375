"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import ImportPageTitleBar from "../widgets/pageTitleBar/import_page_title_bar";
import BasePage from "../base_page";
import ErrorBar from "../widgets/bars/error_bar";
import TabNavBar from "../widgets/bars/tab_nav_bar";
import ImportProjectRecordWidget from "./widgets/general/import_project_record_widget";
import { IMPORT_TABS_ENUM, IMPORT_TYPES } from "./constants/import_constants";
import { can } from "../utils/ui_permissions";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";
import ImportProcessSelect from "./widgets/general/import_process_select";
import ImportProjectSelect from "./widgets/general/import_project_select";
import * as ProcessCache from "../processExplorer/process/process_cache";
import { getImportDependencies } from "./helpers/import_security";
import * as FailHandlers from "../utils/fail_handlers";


/* This class shows the list of objects that can be imported for a given project. */
export default class ImportProjectRecordPage extends BasePage {
  constructor(props) {
    super(props);

    let projectId = UIUtils.getParameterByName("projectId");
    let projectType = UIUtils.getParameterByName("projectType");
    let selectedTab = UIUtils.getParameterByName("selectedTab");
    let processId = UIUtils.getParameterByName("processId");
    if (projectId) {
      projectId = UIUtils.parseInt(projectId);
      processId = processId || ProcessCache.getProcessIdUsedRecently(projectId);
    }

    this.state = {
      importTabs: IMPORT_TABS_ENUM,
      selectedTab: this.getInitialSelectedTab(selectedTab),
      dependenciesStatus: new Map(),
      isValidProject: !!projectId,
      projects: [],
      processes: [],
      projectId,
      projectType,
      processId,
      hasLibraryMaterial: true
    };

    UIUtils.clearError();
    window.addEventListener("popstate", this.handleHistory);

    new TypeaheadObjectCache("project").loadOptions(this.handleProjectsLoaded);
    if (projectId) {
      new TypeaheadObjectCache("Process", projectId).loadOptions(this.handleProcessesLoaded.bind(this, false));
    }

    this.setStateSafely({
      breadcrumb: {
        current: "Project Data",
        parent: "Import",
        parentLink: "/import/importDashboard.html",
      }
    });
  }

  componentDidMount() {
    document.title = "QbDVision Import";

    super.componentDidMount();
  }

  handleHistory(event) {
    this.setStateSafely(event.state);
  }

  handleProjectsLoaded(projects) {
    this.setStateSafely({projects: projects.filter(project => !project.deletedAt)});
    this.checkLibraryMaterial();
  }

  checkLibraryMaterial() {
    this.setStateSafely({
      hasLibraryMaterial: true
    });

    UIUtils.secureAjaxGET(
      `materialLibrary/hasData`,
      {},
      true,
      FailHandlers.defaultStopLoadingFailFunction.bind(this)
    ).then(results => {
      this.setStateSafely(results);
    });
  }

  onProjectChanged(options) {
    let value = options.length === 0 ? null : options[0];
    if (value) {
      this.setStateSafely({
        projectId: value.id,
        projectType: value.type,
        isValidProject: true
      }, () => {
        new TypeaheadObjectCache("Process", this.state.projectId).loadOptions(this.handleProcessesLoaded.bind(this, true));
      });
    } else {
      this.setStateSafely({
        projectId: null,
        projectType: null,
        isValidProject: false
        , processes: []
      });
    }
  }

  loadDependencies() {
    const {projectId, processId} = this.state;
    getImportDependencies(projectId, processId).then(result => this.handleResultsFromServer(result));
  }

  handleProcessesLoaded(pushStateToHistory, processes) {
    const {projectId} = this.state;
    processes = processes.filter(process => !process.deletedAt);

    let processId = this.state.processId
      || ProcessCache.getProcessIdUsedRecently(projectId)
      || (processes[0] && processes[0].id);

    // This is for the case the project selection changed and the processId is not any more part of the selected project.
    if (!processes.find(process => process.id === UIUtils.parseInt(processId))) {
      processId = processes[0] && processes[0].id;
    }

    this.setStateSafely({processId, processes}, () => {
      const {projectId, projectType, processId} = this.state;

      if (pushStateToHistory) {
        UIUtils.pushHistoryURLWithParameterChanges(this.state, {projectId, projectType, processId});
      }
      this.loadDependencies();
    });
  }

  handleProcessChanged(e) {
    const processes = this.state.processes || [];
    const process = processes.find(process => process.name === e.target.value);
    this.setStateSafely({processId: process && process.id}, () => {
      UIUtils.pushHistoryURLWithParameterChanges(this.state, {processId: this.state.processId});
      this.loadDependencies();
    });
  }

  getInitialSelectedTab(selectedTab) {
    let canImportAttribute = false;
    let selectedTabEnum = selectedTab
      ? Object.entries(IMPORT_TABS_ENUM).find(entry => entry[1].title === selectedTab)
      : null;
    selectedTabEnum = selectedTabEnum && selectedTabEnum[1];

    for (let attribute of IMPORT_TYPES.BY_TYPE) {
      canImportAttribute = canImportAttribute || can(attribute.securityAction, attribute.modelName);
    }

    return canImportAttribute
      ? (selectedTabEnum || IMPORT_TABS_ENUM.BY_TYPE)
      : IMPORT_TABS_ENUM.PROCESS_DATA;
  }

  handleResultsFromServer(result) {
    if (result) {
      this.setStateSafely({dependenciesStatus: result});
    }
  }

  handleTabChanged(tab) {
    this.setStateSafely({
      selectedTab: tab
    }, () => {
      UIUtils.pushHistoryURLWithParameterChanges(this.state, {selectedTab: this.state.selectedTab.title});
    });
  }

  getCurrentWidgets() {
    const selectedTabKey = this.state.selectedTab.title.toUpperCase().replace(/\s/g, "_");
    return IMPORT_TYPES[selectedTabKey]
      .filter(widget => !widget.experiment || (widget.experiment && UIUtils.isExperimentEnabled(widget.experiment)))
      .filter(widget => !widget.deprecatedWhenLibraryMaterialIsActive || (widget.deprecatedWhenLibraryMaterialIsActive && !this.state.hasLibraryMaterial))
      .filter(widget => !widget.isLibrary);
  }

  renderPage() {
    const {
      projects, processes, projectId, processId, projectType, projectLabel, isValidProject,
      dependenciesStatus, selectedTab, importTabs
    } = this.state;
    const process = (processes || []).find(process => process.id === UIUtils.parseInt(processId));
    const widgets = this.getCurrentWidgets();

    let options = widgets.map((widget, index) => {
      return (
        <ImportProjectRecordWidget
          key={widget.key ? widget.key : index}
          widgetParams={{
            ...widget,
            projectId,
            processId,
            projectType,
            dependenciesStatus,
            selectedTab: selectedTab.title,
            explicitDisable: !isValidProject,
            allowProcessSelection: false,
          }}
        />
      );
    });

    return (
      <div>
        <ImportPageTitleBar name={projectLabel}
                            projectId={projectId}
        />
        <div className="container row-white import-project-container">
          <div className={"col-12"}>
            <ErrorBar className="import-error-bar" />
            <div className="row import-project-page-toolbar">
              <div className="col-4">
                <ImportProjectSelect projects={projects}
                                     projectId={projectId}
                                     onTypeaheadChange={this.onProjectChanged}
                />

              </div>
              <div className="col-4">
                <ImportProcessSelect processes={processes}
                                     process={process}
                                     onProcessChange={this.handleProcessChanged}
                />
              </div>
            </div>
            <div className="row import-nav-bar-container">
              <div className="col-12">
                <TabNavBar selected={selectedTab}
                           onTabChanged={this.handleTabChanged}
                           tabs={UIUtils.deepClone(importTabs)}
                           parent={this}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           parentId={this.state.id}
                />
              </div>
            </div>
            <div className="row">
              <table className="import-project-record-widgets-container">
                <thead>
                <tr className="import-project-record-widgets-row import-project-record-widgets-header-row">
                  <th className="import-project-record-description-column">
                    Description
                  </th>
                  <th className="import-project-record-details-column">
                    Format
                  </th>
                  <th className="import-project-record-instructions-column">
                    Instructions
                  </th>
                  <th className="import-project-record-button-column" />
                </tr>
                </thead>
                <tbody>
                {options}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
