"use strict";

import * as UIUtils from "../../ui_utils";
import * as CommonUtils from "../../../server/common/generic/common_utils";
import * as go from "gojs";
import { REPORT_OPTIONS_ENUM } from "../constants/report_constants";
import { MODEL_DECLARATIONS } from "../../../server/common/generic/common_models";
import BaseAutoBind from "../../base_auto_bind";
import { ProcessMapResultsAdapter } from "./process_map_results_adapter";
import ProcessMapKey from "./process_map_key";
import { TYPE_CODE } from "../../processExplorer/process_explorer_constants";
import { ProcessParameterIndexer } from "../../processExplorer/indexers/process_parameter_indexer";
import RiskUtils from "../../../server/common/misc/common_risk_utils";
import { getFilteredRMPForType } from "../../helpers/risk_helper";

const TILE_POSTFIX = "-Tile";

/**
 * This provides the data layer for the process flow map report. All data manipulation and preparation for the report
 * take place in this class.
 */
export default class ProcessFlowMapReport extends BaseAutoBind {
  constructor(data, filters, rmpVersions, projectWithAllVersions) {
    super();
    this._filters = filters;
    this._rmpVersions = rmpVersions;
    this._projectWithAllVersions = projectWithAllVersions;

    this._allOperations = [];
    this.results = new ProcessMapResultsAdapter(data).transformData();

    this._nodeDataArray = this._getNodeDataArray();
    this._linkDataArray = this._getLinkDataArray(this._nodeDataArray);
  }

  get nodeDataArray() {
    return this._nodeDataArray;
  }

  get linkDataArray() {
    return this._linkDataArray;
  }

  /**
   * An array of all operations (UOs and Steps), in order.
   * @return {[{typecode: string, id: number}]} An array listing all operations.
   */
  get allOperations() {
    return this._allOperations;
  }

  /**
   * This creates a nodes array which can be used to render a GoJS diagram, given a raw data set.
   * @returns {Array}
   */
  _getNodeDataArray() {
    let nodeDataArray = [];

    this.buildSwimlaneHeaders(nodeDataArray);

    //Add the swimlanes pool node
    nodeDataArray.push({
      key: "diagramGroup",
      category: REPORT_OPTIONS_ENUM.ProcessFlowMapReport.PoolGroupCategory,
      isGroup: true,
      columnSort: 9,
      column: 8,
    });

    this.buildSwimlanes(nodeDataArray);

    //Add the individual nodes per swimlane
    this._createDataNode({
      nodeDataArray,
      columnName: TYPE_CODE.UNIT_OPERATION,
    });

    this._createDataNode({
      nodeDataArray,
      columnName: TYPE_CODE.PROCESS_COMPONENT,
      getNodesFunc: () => {
        let allNodes = [];
        for (const prc of this.results.processcomponents) {
          /* 1. PRCs should show up in the map and link with all the UOs they are linked with. (QI-3337)
             2. Global PRCs should show in the map linked with all UOs (QI-3337)
           */
          const stepsForThisPRC = new Set(prc.Steps.map(step => step.id));
          let prcUnitOperations = prc.UnitOperations && prc.UnitOperations.length > 0
            ? prc.UnitOperations : this.results.uos;
          for (const unitOperation of prcUnitOperations) {
            const steps = this.results.uoToSteps.get(unitOperation.id).filter(step => stepsForThisPRC.has(step.id));
            if (steps && steps.length > 0) {
              for (const step of steps) {
                allNodes.push({
                  ...prc,
                  typeCode: CommonUtils.getTypeCodeForModelName("prc"),
                  isCritical: !!prc.isCritical,
                  id: prc.ProcessComponentId,
                  unitOperationId: unitOperation.id,
                  stepId: step.id,
                  visible: true,
                });
              }
            } else {
              allNodes.push({
                ...prc,
                typeCode: CommonUtils.getTypeCodeForModelName("prc"),
                isCritical: !!prc.isCritical,
                id: prc.ProcessComponentId,
                unitOperationId: unitOperation.id,
                visible: true,
              });
            }
          }
        }

        return allNodes;
      },
    });

    this._createDataNode({
      nodeDataArray: nodeDataArray,
      columnName: TYPE_CODE.MATERIAL_ATTRIBUTE,
      getNodesFunc: () => {
        let allNodes = [];

        /* 1. An MA under a non-global PRC should show up linked with the UO it has a direct link with. (QI-3337)
           2. An MA in a global PRC should show in the map linked with all UOs. (QI-3337)
         */
        for (let ma of this.results.mas) {
          let maUnitOperations = ma.UnitOperation ? [ma.UnitOperation] : this.results.uos;
          for (let maUnitOperation of maUnitOperations) {
            let newMA = {
              ...ma,
              unitOperationId: maUnitOperation.id,
              typeCode: CommonUtils.getTypeCodeForModelName("mas"),
              isCritical: !!ma.isCritical,
              id: ma.MaterialAttributeId,
              name: ma.name + (ma.Material ? " [R]" : ""),
              riskInfo: ma.riskInfo,
              effectiveRMPVersionId: ma.effectiveRMPVersionId,
              visible: this._isItemVisible(ma),
            };
            newMA.parent = this._createParent(ma);
            newMA.effectiveRMP = getFilteredRMPForType(this._rmpVersions.find(rmpVersion => rmpVersion.id === newMA.effectiveRMPVersionId), "MA");
            newMA.rawCriticality = newMA.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].value;
            newMA.criticality = newMA.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].normalizedValue;
            allNodes.push(newMA);
          }
        }

        /* Add to the map any IQA as an Input Material, when the IQA is linked with another IQA downstream in the
           process. The Unit Operation the IQA will be linked with is the Unit Operation of the IQA it links to
           downstream in the process.
         */
        const iqasWithDownstreamLink = this.results.iqas.filter(iqa =>
          iqa.IQAToIQALinkedVersions && iqa.IQAToIQALinkedVersions.length > 0 ||
          iqa.IQAToIPALinkedVersions && iqa.IQAToIPALinkedVersions.length > 0);

        for (let iqa of iqasWithDownstreamLink) {
          for (const modelType of ["IQA", "IPA"]) {
            for (let link of iqa[`IQATo${modelType}LinkedVersions`]) {

              const linkedIQA = this.results.iqas.find(iqa => modelType === "IQA" ?
                iqa.IQAId === link.TargetIQAId : link.IPAId);

              if (linkedIQA) {
                let newIQA = {
                  ...iqa,
                  unitOperationId: linkedIQA.UnitOperationId,
                  typeCode: UIUtils.getTypeCodeForModelName("iqas"),
                  isCritical: !!iqa.isCritical,
                  id: iqa.IQAId,
                  name: iqa.name + " [I]",
                  riskInfo: iqa.riskInfo,
                  effectiveRMPVersionId: iqa.effectiveRMPVersionId,
                  visible: this._isItemVisible(iqa),
                };

                newIQA.effectiveRMP = getFilteredRMPForType(this._rmpVersions.find(rmpVersion => rmpVersion.id === newIQA.effectiveRMPVersionId), "IQA");
                newIQA.rawCriticality = newIQA.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].value;
                newIQA.criticality = newIQA.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].normalizedValue;

                // only adds the IQA as input parameter once for each UO
                if (!allNodes.some(existingIQA =>
                  existingIQA.typeCode === newIQA.typeCode
                  && existingIQA.id === newIQA.id
                  && existingIQA.unitOperationId === newIQA.unitOperationId)
                ) {
                  allNodes.push(newIQA);
                }
              }
            }
          }
        }
        return allNodes;
      },
    });

    this._createDataNode({
      nodeDataArray,
      columnName: TYPE_CODE.PROCESS_PARAMETER,
      getNodesFunc: () => {
        let allNodes = [];

        for (let pp of this.results.pps) {
          /* 1. A PP in a global PRC should show in the map linked with all UOs. (QI-3337)
             2. A PP under a non-global PRC should show up linked with the UO it has a direct link with. (QI-3337)
           */
          let ppUnitOperations = pp.UnitOperation ? [pp.UnitOperation] : this.results.uos;
          for (let ppUnitOperation of ppUnitOperations) {
            const newPP = {
              ...pp,
              typeCode: CommonUtils.getTypeCodeForModelName("pps"),
              isCritical: !!pp.isCritical,
              id: pp.ProcessParameterId,
              riskInfo: pp.riskInfo,
              effectiveRMPVersionId: pp.effectiveRMPVersionId,
              unitOperationId: ppUnitOperation.id,
              visible: this._isItemVisible(pp),
            };
            newPP.parent = this._createParent(pp);
            newPP.effectiveRMP = getFilteredRMPForType(this._rmpVersions.find(rmpVersion => rmpVersion.id === newPP.effectiveRMPVersionId), "PP");
            newPP.rawCriticality = newPP.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].value;
            newPP.criticality = newPP.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].normalizedValue;
            allNodes.push(newPP);
          }
        }

        return allNodes;
      },
    });

    this._createDataNode({
      nodeDataArray,
      columnName: TYPE_CODE.MATERIAL,
      getNodesFunc: () => {
        let allNodes = [];
        for (const mt of this.results.materials) {
          /* 1. Global MTs should show in the map linked with all UOs when a viewing "Input Materials". (QI-3337)
             2. MTs should show up under all UOs they are linked with when they are not global. (QI-3337)
           */
          const stepsForThisMT = new Set(mt.Steps.map(step => step.id));
          let materialUnitOperations = mt.UnitOperations && mt.UnitOperations.length > 0
            ? mt.UnitOperations
            : this.results.uos;
          for (const unitOperation of materialUnitOperations) {
            const steps = this.results.uoToSteps.get(unitOperation.id).filter(step => stepsForThisMT.has(step.id));
            if (steps && steps.length > 0) {
              for (const step of steps) {
                allNodes.push({
                  ...mt,
                  typeCode: CommonUtils.getTypeCodeForModelName("mt"),
                  isCritical: !!mt.isCritical,
                  id: mt.MaterialId,
                  unitOperationId: unitOperation.id,
                  riskInfo: mt.riskInfo,
                  effectiveRMPVersionId: mt.effectiveRMPVersionId,
                  stepId: step.id,
                  visible: true,
                });
              }
            } else {
              allNodes.push({
                ...mt,
                typeCode: CommonUtils.getTypeCodeForModelName("mt"),
                isCritical: !!mt.isCritical,
                id: mt.MaterialId,
                riskInfo: mt.riskInfo,
                effectiveRMPVersionId: mt.effectiveRMPVersionId,
                unitOperationId: unitOperation.id,
                visible: true,
              });
            }
          }
        }

        return allNodes;
      },
    });

    this._createDataNode({
      nodeDataArray,
      columnName: TYPE_CODE.IA,
      getNodesFunc: () => {
        return this.results.intermediateAttributes.map(intermediateAttribute => {
          const idProp = `${intermediateAttribute.modelType}Id`;

          let newIntermediateAttribute = {
            ...intermediateAttribute,
            unitOperationId: intermediateAttribute.UnitOperationId,
            typeCode: CommonUtils.getTypeCodeForModelName(UIUtils.pluralize(intermediateAttribute.modelType)),
            isCritical: !!intermediateAttribute.isCritical,
            id: intermediateAttribute[`${idProp}`],
            riskInfo: intermediateAttribute.riskInfo,
            effectiveRMPVersionId: intermediateAttribute.effectiveRMPVersionId,
            visible: this._isItemVisible(intermediateAttribute),
          };

          newIntermediateAttribute.effectiveRMP = getFilteredRMPForType(this._rmpVersions.find(rmpVersion => rmpVersion.id === newIntermediateAttribute.effectiveRMPVersionId), "IPA");
          newIntermediateAttribute.rawCriticality = newIntermediateAttribute.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].value;
          newIntermediateAttribute.criticality = newIntermediateAttribute.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].normalizedValue;
          return newIntermediateAttribute;
        });
      },
      reverseLinkDirection: true,
    });

    this._createDataNode({
      nodeDataArray,
      columnName: TYPE_CODE.FA,
      getNodesFunc: () => {
        let allNodes = [];
        for (let finalAttribute of this.results.finalAttributes) {

          const typeCode = CommonUtils.getTypeCodeForModelName(UIUtils.pluralize(finalAttribute.modelType));
          const idProp = `${finalAttribute.modelType}Id`;
          const allOperations = this.results.outputKeyToOperationMap.get(typeCode + "-" + finalAttribute[idProp]);

          if (allOperations) {
            for (let operation of allOperations.values()) {
              let newFinalAttribute = {
                ...finalAttribute,
                typeCode,
                id: finalAttribute[idProp],
                [`${finalAttribute.modelType.toLowerCase()}Category`]: finalAttribute.category,
                isCritical: !!finalAttribute.isCritical,
                riskInfo: finalAttribute.riskInfo,
                effectiveRMPVersionId: finalAttribute.effectiveRMPVersionId,
                visible: this._isItemVisible(finalAttribute),
              };
              const isOperationUO = operation.typeCode === MODEL_DECLARATIONS.UO.typeCode;
              newFinalAttribute[isOperationUO ? "unitOperationId" : "stepId"] = operation.id;

              delete newFinalAttribute.category;
              newFinalAttribute.effectiveRMP = getFilteredRMPForType(this._rmpVersions.find(rmpVersion => rmpVersion.id === newFinalAttribute.effectiveRMPVersionId), "FQA");
              newFinalAttribute.rawCriticality = newFinalAttribute.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].value;
              newFinalAttribute.criticality = newFinalAttribute.riskInfo[RiskUtils.RISK_TYPE_ENUM.CRITICALITY].normalizedValue;
              allNodes.push(newFinalAttribute);
            }
          }
        }
        return allNodes;
      },
      reverseLinkDirection: true,
    });

    return nodeDataArray;
  }

  _createParent(ma) {
    const parent = ma.ProcessComponent || ma.Material || {};
    let returnValue = {};
    if (parent) {
      if (ma.Material) {
        returnValue = {
          type: "MT",
          typeName: "Material",
        };
      } else if (ma.ProcessComponent) {
        returnValue = {
          type: "PRC",
          typeName: "Component",
        };
      }
      returnValue.id = parent.id;
      returnValue.name = parent.name;
    }

    return returnValue;
  }

  buildSwimlaneHeaders(nodeDataArray) {
    nodeDataArray.push(
      this._generateNodeData(TYPE_CODE.MATERIAL, 1, 0, "Input Materials", "MTMAIcon", this._filters.showMTs, true, true),
      this._generateNodeData(TYPE_CODE.PROCESS_COMPONENT, 2, 1, "Process Components", "PPIcon", this._filters.showPRCs, true, true),
      this._generateNodeData(TYPE_CODE.MATERIAL_ATTRIBUTE, 3, 2, "Material Attributes", "PPMAIcon", this._filters.showMAs, true, true),
      this._generateNodeData(TYPE_CODE.PROCESS_PARAMETER, 4, 3, "Process Parameters", "PPIcon", this._filters.showPPs, true, true),
      this._generateNodeData(TYPE_CODE.UNIT_OPERATION, 5, 4, "Unit Operations", "UOIcon", true, true, true),
      this._generateNodeData(TYPE_CODE.IA, 6, 5, "Intermediate Attributes", "IntermediateAttribute", this._filters.showIAs, false, true),
      this._generateNodeData(TYPE_CODE.FA, 7, 6, "Final Attributes", "FinalAttributeIcon", this._filters.showFAs, false, true),
    );
  }

  buildSwimlanes(nodeDataArray) {
    nodeDataArray.push(
      this._generateNodeData(TYPE_CODE.MATERIAL, 1, 0, "Input Materials", "MTMAIcon", this._filters.showMTs),
      this._generateNodeData(TYPE_CODE.PROCESS_COMPONENT, 2, 1, "Process Components", "PPIcon", this._filters.showPRCs),
      this._generateNodeData(TYPE_CODE.MATERIAL_ATTRIBUTE, 3, 2, "Material Attributes", "MAIcon", this._filters.showMAs),
      this._generateNodeData(TYPE_CODE.PROCESS_PARAMETER, 4, 3, "Process Parameters", "PPIcon", this._filters.showPPs),
      this._generateNodeData(TYPE_CODE.UNIT_OPERATION, 5, 4, "Unit Operations", "UOIcon", true),
      this._generateNodeData(TYPE_CODE.IA, 6, 5, "Intermediate Attributes", "IntermediateAttribute", this._filters.showIAs, false),
      this._generateNodeData(TYPE_CODE.FA, 7, 6, "Final Attributes", "FinalAttributeIcon", this._filters.showFAs, false),
    );
  }

  _getFontColor(key) {
    switch (key) {
      case "FinalAttributesHeader":
      case "IntermediateAttributesHeader":
        return "white";
      default:
        return "black";
    }
  }

  _generateNodeData(key, columnSort, column, title, thumbnail, filterLogic, isCenter = true, isHeader = false) {
    let nodeData = {
      key: isHeader ? key + "Header" : key,
      columnType: key,
      columnSort,
      column,
      category: REPORT_OPTIONS_ENUM.ProcessFlowMapReport[isHeader ? "SwimlaneHeaderCategory" : "SwimlanesGroupCategory"],
      title,
      alignment: go.Spot[isCenter ? "Center" : "TopLeft"],
      fill: "transparent",
      thumbnail: `/images/reports/process_flow_map_reports/${thumbnail}.png`,
      visible: filterLogic,
      fontColor: this._getFontColor(key),
    };

    return isHeader ? nodeData : {
      ...nodeData,
      group: "diagramGroup",
      isGroup: true,
    };
  }

  _isItemVisible(item) {
    return !!(!this._filters.showCriticalOnly || item.isCritical);
  }

  /**
   * This adds a new node to the nodesDataArray array for each box you see on the screen of a given type. Given
   * different parameters, the node that is added is either a UO node or a node containing multiple requirements.
   *
   * @param params A set of parameters to define the kind of node being added, the swimlane it is being added and the way
   * records within the node are being grouped together.
   */
  _createDataNode(params) {
    const {
      nodeDataArray,
      columnName,
      getNodesFunc,
      reverseLinkDirection,
    } = params;

    if (columnName === TYPE_CODE.UNIT_OPERATION) {
      this._allOperations = [];
      for (let record of this.results.uos) {
        let fromRecord = this.results.uos.find(rec => rec.id === record.PreviousUnitId);

        const toUOKey = `UO-${record.id}`;
        const fromUOKey = fromRecord ? `UO-${fromRecord.id}` : null;
        this._allOperations.push(new ProcessMapKey("UO", record.id));

        // This is for the background tile behind the UO & Step nodes
        const groupKey = toUOKey + TILE_POSTFIX;
        nodeDataArray.push({
          key: groupKey,
          fromKey: fromUOKey ? fromUOKey + TILE_POSTFIX : null,
          toKey: toUOKey ? toUOKey + TILE_POSTFIX : null,
          category: REPORT_OPTIONS_ENUM.ProcessFlowMapReport.TileCategory,
          group: columnName,
          isGroup: true,
          visible: true,
        });

        // This is for the node with the UO name in it.
        nodeDataArray.push({
          key: toUOKey,
          fromKey: fromUOKey,
          toKey: toUOKey,
          id: record.id,
          typeCode: MODEL_DECLARATIONS.UO.typeCode,
          name: record.name,
          category: "UnitOperationTemplate",
          group: groupKey,
          columnName,
          visible: true,
        });

        const steps = this.results.uoToSteps.get(record.id);
        for (let step of steps) {
          const toKey = `STP-${step.id}`;
          this._allOperations.push(new ProcessMapKey("STP", step.id));
          const fromKey = toUOKey;

          nodeDataArray.push({
            key: toKey,
            fromKey,
            toKey,
            id: step.id,
            typeCode: MODEL_DECLARATIONS.STP.typeCode,
            name: step.name,
            category: "StepTemplate",
            group: groupKey,
            columnName,
            visible: true,
          });
        }
      }
    } else {
      let groupKey;
      const allRecords = getNodesFunc(this.results);
      for (let {typeCode, id} of this._allOperations) {

        let fromKey = `${columnName}-${typeCode}-${id}`;
        const thisKey = fromKey;
        let toKey = `${typeCode}-${id}`;
        const operation = toKey;

        // Just swap the from & to keys using a nice ES6 destructuring trick
        if (reverseLinkDirection) {
          [fromKey, toKey] = [toKey, fromKey];
        }

        let records = allRecords.filter(record => {
          let groupByValue = null;
          if (typeCode === "STP") {
            groupByValue = record.stepId || record.StepId;
          } else if (!record.stepId && !record.StepId) {
            groupByValue = record.unitOperationId || record.UnitOperationId;
          }
          return (groupByValue === id || groupByValue === -1) && record.visible;
        });

        if (columnName === TYPE_CODE.PROCESS_PARAMETER) {
          // Find the operation with the PP order and sort the PPs accordingly.
          let operation;
          if (typeCode === "UO") {
            operation = this.results.uos.find(uo => uo.id === id);
          } else {
            operation = this.results.steps.find(uo => uo.id === id);
          }
          const processParameterOrder = JSON.parse(operation?.processParameterOrder || "[]");
          records = ProcessParameterIndexer.sortRecordsBasedOnIDArray(processParameterOrder, records);
        } else {
          records = records.sort((a, b) => a.name.localeCompare(b.name));
        }

        if (records.length > 0 && (columnName === TYPE_CODE.FA || columnName === TYPE_CODE.IA)) {
          // Sort records by model type (ex. IQA comes before IPA)
          records.sort(UIUtils.sortBy({
            name: "modelType",
            reverse: true,
          }));

          const commonHeaderProps = {
            isCritical: false,
            color: null,
            stroke: "#859099",
          };

          // Add Quality Attributes header
          const includesQualityAttributes = (columnName === TYPE_CODE.FA && records.find(item => item.modelType === "FQA"))
            || (columnName === TYPE_CODE.IA && records.find(item => item.modelType === "IQA"));
          if (includesQualityAttributes) {
            records.unshift({
              name: "Quality:",
              ...commonHeaderProps,
              isRecordHeader: true,
            });
          }

          // Add Performance Attributes header
          if ((columnName === TYPE_CODE.FA && records.find(item => item.modelType === "FPA"))
            || (columnName === TYPE_CODE.IA && records.find(item => item.modelType === "IPA"))) {

            const performanceAttributesIndex = records.map(item => item.modelType)
              .findIndex(item => item === (columnName === TYPE_CODE.FA ? "FPA" : "IPA"));

            records.splice(performanceAttributesIndex, 0, {
              name: "Performance:",
              ...commonHeaderProps,
              // If there are Quality Attributes we increase the top margin for better visibility
              isRecordHeader: true,
              isSecondRecordHeader: !!includesQualityAttributes,
            });
          }
        }

        if (typeCode === "UO") {
          // This is for the background tile behind the record nodes
          groupKey = thisKey + TILE_POSTFIX;
          nodeDataArray.push({
            key: groupKey,
            uoKey: thisKey,
            category: REPORT_OPTIONS_ENUM.ProcessFlowMapReport.TileCategory,
            group: columnName,
            isGroup: true,
            visible: this.isLaneVisible(columnName, nodeDataArray),
          });
        }

        const visible = records && records.length > 0 && this.isLaneVisible(columnName, nodeDataArray);
        nodeDataArray.push({
          key: thisKey,
          fromKey,
          toKey,
          records,
          category: "ItemsTableTemplate",
          group: groupKey,
          columnName,
          operation,
          visible,
        });
      }
    }
  }

  /**
   * This creates a links array which can be used to render a GoJS diagram, given a raw data set.
   * @param nodeDataArray An array holding all the GoJS diagram nodes.
   * @returns {Array}
   */
  _getLinkDataArray(nodeDataArray) {
    let linkDataArray = [];

    for (let node of nodeDataArray.filter(node => node.visible && node.fromKey && node.toKey)) {
      let fromKey = node.fromKey;
      let toKey = node.toKey;

      const isStepNode = node.typeCode === "STP";
      const isTile = node.category === REPORT_OPTIONS_ENUM.ProcessFlowMapReport.TileCategory;
      const isUONode = node.typeCode === "UO";

      if (!isUONode) {
        linkDataArray.push({
          from: fromKey,
          to: toKey,
          fromSpot: isStepNode ?
            new go.Spot(0.001, 1.0, 150, 0) // Bottom, 150px in
            : isTile ?
              new go.Spot(0.5, 1.0, 0, 0) // Bottom, 150px in
              : new go.Spot(1.0, 0.001, 0, 20), // Right, 20px down
          toSpot: isStepNode ?
            new go.Spot(0.001, 0.0, 50, 0) // Top, 50px in
            : isTile ?
              new go.Spot(0.5, 0.0, 0, 0) // Top, middle
              : new go.Spot(0.0, 0.001, 0, 20), // Left, 20px down
        });
      }
    }

    return linkDataArray;
  }

  isLaneVisible(columnName, nodeDataArray) {
    return nodeDataArray.find(node => node.key === columnName).visible;
  }
}
