import {drawDOM, exportPDF} from "@progress/kendo-drawing";
import {downloadFile} from "../index";

/**
 * The exporter util to export a PDF file
 */
export class PDFExporter {
  static async getDataUri(node: HTMLElement, isLandscape: boolean) {
    if (!node) {
      return "";
    }

    const header = node.querySelector(".header-container") as HTMLElement;
    const footer = node.querySelector(".footer-container") as HTMLElement;
    let top = 20;
    let bottom = 20;
    // We only need to check for the header since header and footer coexists
    if (header) {
      top = header.clientHeight + 20;
      bottom = footer.clientHeight + 70;
      header.remove();
      footer.remove();
    }

    const clonedNode = this.getNodeBeforeExporting(node);
    const group = await drawDOM(clonedNode, {
      landscape: isLandscape,
      // Disabled the link of table of content item
      avoidLinks: "a.table-of-content-link",
      paperSize: "A4",
      scale: 0.8,
      margin: {top, bottom, left: 20, right: 20},
      forcePageBreak: ".page-break",
      template: (context) => {
        return header ? this.getTemplate(header, footer, context) : "";
      },
    });
    clonedNode.remove();

    // Add back header and footer after removing them in previous step
    if (header && !node.querySelector(".header-container")) {
      node.insertBefore(header, node.firstElementChild);
      node.appendChild(footer);
    }

    return await exportPDF(group, {
      author: "QbDVision",
      multiPage: true,
    });
  }

  static getTemplate(header: HTMLElement, footer: HTMLElement, context: any) {
    this.setFieldValue(header, context);
    this.setFieldValue(footer, context);
    return `
        <div>
          <div style="position: absolute; top: 40px; left: 40px; right: 40px">
            ${header.outerHTML}
          </div>
           <div style="position: absolute; bottom: 20px; left: 40px; right: 40px">
            ${footer.outerHTML}
          </div>
        </div>
    `;
  }

  static setFieldValue(node: HTMLElement, context: any) {
    const pageFields = Array.from<HTMLElement>(
      node ? node.querySelectorAll(".doc-builder-field") : []
    );
    let coverPage = false;
    for (const pageField of pageFields) {
      if (pageField.getAttribute("value") === "pageNumberExcludingCoverPage")
      {
        coverPage = true;
        break;
      }
    }
    for (const pageField of pageFields) {
      let content = "";
      const field = pageField.getAttribute("value");
      switch (field) {
        case "pageNumber":
          content = context.pageNum;
          break;
        case "totalPages":
          content = coverPage ? context.totalPages - 1 : context.totalPages;
          break;
        case "pageNumberExcludingCoverPage":
          content = context.pageNum > 1 ? (context.pageNum - 1).toString() : "Cover";
          break;
        default:
          content = pageField.innerText;
      }
      pageField.innerText = content;
    }
  }

  async export(node: HTMLElement, fileName: string, isLandscape: boolean) {
    const dataUri = await PDFExporter.getDataUri(node, isLandscape);
    downloadFile(dataUri, fileName);
  }

  /**
   * We adjust original node before exporting
   *
   * @param node
   * @private
   */
  private static getNodeBeforeExporting(node: HTMLElement) {
    // We need to clone the node since we don't want change we make here affect the
    // original node
    const clonedNode = node.cloneNode(true) as HTMLElement;
    // We insert to the DOM since we want to get the width of colgroup element
    node.after(clonedNode);
    const tables = Array.from(clonedNode.querySelectorAll("table"));
    for (const table of tables) {
      // We need to convert px to %. If we use pixel, it will be out of the page
      if (table.style.width) {
        table.style.width = `${
          (parseInt(table.style.width) * 100) / table.offsetWidth
        }%`;
      }

      const colgroup = table.querySelector("colgroup");
      if (!colgroup) {
        continue;
      }

      const cols = Array.from(colgroup.querySelectorAll("col"));
      if (!cols || !cols.length) {
        continue;
      }

      const tableRows = Array.from(table.querySelectorAll("tr"));
      for (const tableRow of tableRows) {
        const tableColumns = tableRow.querySelectorAll("td");
        tableColumns.forEach((tableColumn, index) => {
          if (cols.length > index && cols[index].style.width) {
            // We need to convert px to %. If we use pixel, it will be out of the page
            tableColumn.style.width = `${
              (parseInt(cols[index].style.width) * 100) / colgroup.offsetWidth
            }%`;
          }
        });
      }
      colgroup.remove();
    }

    // drawDOM cannot render the pseudo :before, so we need to set it manually before exporting
    const elements = Array.from(
      clonedNode.querySelectorAll("li")
    );
    for (const element of elements) {
      const data = element.getAttribute("data");
      if (!data) {
        continue;
      }
      const spanElement = document.createElement("span");
      spanElement.innerText = `${data}.`;
      element.prepend(spanElement);
    }

    return clonedNode;
  }
}
