/**
 * @hidden
 */
var MouseOverHandler = /** @class */ (function () {
    function MouseOverHandler(openOnClick, resetMenu, openItem) {
        this.openOnClick = openOnClick;
        this.resetMenu = resetMenu;
        this.openItem = openItem;
        this.mouseDown = false;
        this.openOnClick = openOnClick;
        this.isMouseOverEnabled = openOnClick ? false : true;
    }
    Object.defineProperty(MouseOverHandler.prototype, "OpenOnClick", {
        set: function (value) {
            if (Boolean(value) !== Boolean(this.openOnClick)) {
                this.mouseDown = false;
                this.isMouseOverEnabled = value ? false : true;
            }
            this.openOnClick = value;
        },
        enumerable: false,
        configurable: true
    });
    MouseOverHandler.prototype.handleItemSelectedViaKeyboard = function () {
        if (this.openOnClick) {
            this.isMouseOverEnabled = false;
            this.resetMenu();
        }
    };
    Object.defineProperty(MouseOverHandler.prototype, "IsMouseOverEnabled", {
        get: function () {
            return this.isMouseOverEnabled;
        },
        enumerable: false,
        configurable: true
    });
    MouseOverHandler.prototype.handleItemMouseDown = function () {
        this.mouseDown = true;
    };
    MouseOverHandler.prototype.handleItemFocus = function () {
        // Keep opening on mouse over upon tabbing
        // and keyboard navigation. However, discard the event
        // upon click because a cycle occurs and the item does not open.
        if (this.openOnClick && !this.mouseDown) {
            this.isMouseOverEnabled = true;
        }
        this.mouseDown = false;
    };
    MouseOverHandler.prototype.handleItemClick = function (itemId, clickedItemIsWithDefaultClose) {
        if (this.openOnClick) {
            if (this.isMouseOverEnabled) {
                if (clickedItemIsWithDefaultClose) {
                    this.isMouseOverEnabled = false;
                    this.resetMenu();
                }
            }
            else {
                this.isMouseOverEnabled = true;
                this.openItem(itemId);
            }
        }
    };
    return MouseOverHandler;
}());
export { MouseOverHandler };
