var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import * as PropTypes from 'prop-types';
/**
 * Represents the KendoReact ToolbarItem component.
 * To add a tool to the Toolbar, wrap it inside a ToolbarItem component
 * ([see example]({% slug content_toolbar %})).
 */
var ToolbarItem = /** @class */ (function (_super) {
    __extends(ToolbarItem, _super);
    function ToolbarItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._element = null;
        return _this;
    }
    Object.defineProperty(ToolbarItem.prototype, "element", {
        /**
         * Returns the HTML element of the ToolbarItem component.
         */
        get: function () {
            return this._element;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    ToolbarItem.prototype.render = function () {
        var _this = this;
        return (React.createElement("span", { id: this.props.id, className: this.props.className, style: this.props.style, ref: function (e) { return _this._element = e; } }, this.props.children));
    };
    /**
     * @hidden
     */
    ToolbarItem.propTypes = {
        className: PropTypes.string
    };
    return ToolbarItem;
}(React.PureComponent));
export { ToolbarItem };
