var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { RadioButton } from './RadioButton';
import { useRtl, guid, classNames, focusFirstFocusableChild, dispatchEvent, createPropsContext, usePropsContext } from '@progress/kendo-react-common';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
/**
 * Represents the PropsContext of the `RadioGroup` component.
 * Used for global configuration of all `RadioGroup` instances.
 *
 * For more information, refer to the [Inputs Props Context]({% slug props-context_inputs %}) article.
 */
export var RadioGroupPropsContext = createPropsContext();
/**
 * Represents the [KendoReact RadioGroup component]({% slug overview_radiogroup %}).
 *
 * Accepts properties of type [RadioGroupProps]({% slug api_inputs_radiogroupprops %}).
 * Obtaining the `ref` returns an object of type [RadioGroupHandle]({% slug api_inputs_radiogrouphandle %}).
 */
export var RadioGroup = React.forwardRef(function (directProps, target) {
    validatePackage(packageMetadata);
    var props = usePropsContext(RadioGroupPropsContext, directProps);
    var ariaLabelledBy = props.ariaLabelledBy, ariaDescribedBy = props.ariaDescribedBy, className = props.className, data = props.data, defaultValue = props.defaultValue, disabled = props.disabled, name = props.name, labelPlacement = props.labelPlacement, layout = props.layout, style = props.style, value = props.value, valid = props.valid, _a = props.item, Item = _a === void 0 ? defaultProps.item : _a, onChange = props.onChange, onFocus = props.onFocus;
    var elementRef = React.useRef(null);
    var focusElement = React.useCallback(function () {
        if (elementRef.current) {
            focusFirstFocusableChild(elementRef.current);
        }
    }, []);
    var getImperativeHandle = React.useCallback(function () { return ({
        element: elementRef.current,
        focus: focusElement
    }); }, [focusElement]);
    React.useImperativeHandle(target, getImperativeHandle);
    var _b = React.useState(defaultValue), stateChecked = _b[0], setStateChecked = _b[1];
    var isControlled = value !== undefined;
    var checkedRadioValue = isControlled ? value : stateChecked;
    var handleChange = React.useCallback(function (event) {
        var currentValue = event.value;
        if (!isControlled && !disabled) {
            setStateChecked(currentValue);
        }
        if (onChange && !disabled) {
            dispatchEvent(onChange, event.syntheticEvent, getImperativeHandle(), { value: currentValue });
        }
    }, [setStateChecked, onChange, disabled]);
    var handleFocus = React.useCallback(function (event) {
        if (onFocus && !disabled) {
            dispatchEvent(onFocus, event.syntheticEvent, getImperativeHandle(), undefined);
        }
    }, [onFocus, disabled]);
    var radioGroupName = React.useMemo(function () { return guid(); }, []);
    var radioGroupClasses = classNames('k-radio-list', {
        'k-list-horizontal': layout === 'horizontal',
        'k-list-vertical': layout === 'vertical' || layout === undefined
    }, className);
    var dir = useRtl(elementRef, props.dir);
    var radioOptions = (data && data.map(function (option, index) {
        var isCurrentlyChecked = checkedRadioValue === option.value;
        var noOptionChecked = checkedRadioValue === null || checkedRadioValue === undefined;
        var radioButtonProps = __assign(__assign({}, option), { valid: valid, checked: isCurrentlyChecked, disabled: (option.disabled || disabled) ? true : false, labelPlacement: option.labelPlacement ? option.labelPlacement : labelPlacement, tabIndex: option.tabIndex
                ? option.tabIndex
                : (noOptionChecked && index === 0) || isCurrentlyChecked
                    ? 0
                    : -1, index: index, name: name || radioGroupName, onChange: handleChange, onFocus: handleFocus });
        return (React.createElement(Item, { className: classNames('k-radio-item', { 'k-disabled': radioButtonProps.disabled || disabled }), key: index, role: 'none' },
            React.createElement(RadioButton, __assign({}, radioButtonProps))));
    }));
    return (React.createElement("ul", { role: "radiogroup", className: radioGroupClasses, ref: elementRef, dir: dir, style: style, "aria-labelledby": ariaLabelledBy, "aria-describedby": ariaDescribedBy }, radioOptions));
});
var defaultProps = {
    item: function (props) { return React.createElement("li", __assign({}, props)); }
};
RadioGroup.propTypes = {
    ariaLabelledBy: PropTypes.string,
    ariaDescribedBy: PropTypes.string,
    className: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.object),
    defaultValue: PropTypes.any,
    dir: PropTypes.string,
    disabled: PropTypes.bool,
    labelPlacement: PropTypes.string,
    layout: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onFocus: PropTypes.func
};
RadioGroup.defaultProps = defaultProps;
RadioGroup.displayName = 'KendoRadioGroup';
