var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';
/**
 * @hidden
 */
export var createFilterComponent = function (settings) {
    var Cell = /** @class */ (function (_super) {
        __extends(FilterComponent, _super);
        function FilterComponent() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.filterChange = function (event) {
                if (_this.props.onFilterChange) {
                    _this.props.onFilterChange(event);
                }
            };
            _this.inputChange = function (e) {
                var value = e.target.value;
                var _a = _this.props, _b = _a.filter, filter = _b === void 0 ? [] : _b, _c = _a.field, field = _c === void 0 ? '' : _c;
                var existing = _this.findCellFilter();
                var filterResult = existing ?
                    filter.map(function (f) { return f.field === field ? __assign(__assign({}, existing), { value: value }) : f; }) : __spreadArray(__spreadArray([], filter, true), [__assign(__assign({}, settings.emptyFilter), { value: value, field: field })], false);
                _this.filterChange({
                    filter: filterResult,
                    field: field,
                    syntheticEvent: e.syntheticEvent
                });
            };
            _this.operatorChange = function (e) {
                var operator = e.target.value.operator;
                var _a = _this.props, _b = _a.filter, filter = _b === void 0 ? [] : _b, _c = _a.field, field = _c === void 0 ? '' : _c;
                var existing = _this.findCellFilter();
                var filterResult = existing ?
                    filter.map(function (f) { return f.field === field ? __assign(__assign({}, existing), { operator: operator }) : f; }) : __spreadArray(__spreadArray([], filter, true), [__assign(__assign({}, settings.emptyFilter), { field: field, operator: operator })], false);
                _this.filterChange({
                    filter: filterResult,
                    field: field,
                    syntheticEvent: e.syntheticEvent
                });
            };
            _this.clearButtonClick = function (e) {
                var _a = _this.props, _b = _a.filter, filter = _b === void 0 ? [] : _b, _c = _a.field, field = _c === void 0 ? '' : _c;
                _this.filterChange({
                    filter: filter.filter(function (f) { return f.field !== field; }),
                    field: field,
                    syntheticEvent: e
                });
            };
            _this.findCellFilter = function () {
                var _a = _this.props, _b = _a.filter, filter = _b === void 0 ? [] : _b, field = _a.field;
                return filter.find(function (f) { return f.field === field; });
            };
            return _this;
        }
        FilterComponent.prototype.render = function () {
            var _a;
            var _b = this.props, _c = _b.clearButtonTitle, clearButtonTitle = _c === void 0 ? '' : _c, _d = _b.operators, operators = _d === void 0 ? [] : _d;
            var filter = this.findCellFilter() || __assign(__assign({}, settings.emptyFilter), { operator: '' });
            var operator = filter.operator, value = filter.value;
            var operatorComponentProps = {
                ariaLabel: 'filter',
                value: operators.find(function (item) { return item.operator === operator; }) || null,
                onChange: this.operatorChange,
                className: 'k-dropdown-operator',
                iconClassName: 'k-i-filter k-icon',
                data: operators,
                textField: 'text',
                popupSettings: { width: '' },
                components: this.props.components
            };
            return (React.createElement("div", { className: "k-filtercell" },
                React.createElement("span", null,
                    settings.inputComponent({
                        value: value,
                        onChange: this.inputChange,
                        components: this.props.components,
                        ariaLabel: this.props.ariaLabel
                    }),
                    React.createElement("div", { className: "k-filtercell-operator" },
                        settings.operatorComponent(operatorComponentProps, this.props),
                        "\u00A0",
                        React.createElement(Button, { className: classNames((_a = {},
                                _a['k-clear-button-visible'] = (!(value === null || value === '') || operator),
                                _a)), title: clearButtonTitle, type: "button", onClick: this.clearButtonClick, icon: "filter-clear", disabled: !(!(value === null || value === '') || operator) })))));
        };
        return FilterComponent;
    }(React.Component));
    return Cell;
};
