"use strict";

import {
  getEffectiveRMPByModelName,
  getRiskBoundaries,
  isCritical
} from "../../../helpers/risk_helper";

const CQA_MODEL_NAME = "FQA";

/**
 * Common Utility functions for CQA Control Strategy canned report
 */
export class CQAReportUtils {

  static filterTypeAheadOptions(project, rmp, typeaheadOptions) {
    const filterCriticalFQAs = (fqa) => {
      const effectiveRmp = getEffectiveRMPByModelName(project, rmp.approvedVersionsWithDetails, fqa, CQA_MODEL_NAME);
      if (!effectiveRmp.boundaries) {
        effectiveRmp.boundaries = getRiskBoundaries(effectiveRmp);
      }

      return isCritical(fqa, effectiveRmp, CQA_MODEL_NAME);
    };

    return typeaheadOptions.filter(fqa => filterCriticalFQAs(fqa));
  }
}
