"use strict";

import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import * as styles from "./confirmation_popup.module.scss";

export const ConfirmationPopup = ({showPopup, toggle, onConfirm}) => {

  const modalRef = useRef(null);

  useEffect(() => {

    if (showPopup) {
      $(modalRef.current).modal({show: true, backdrop: "static", keyboard: false});
    } else {
      $(modalRef.current).modal("hide");
    }

  }, [showPopup]);


  const onClick = async() => {
    toggle();
    await onConfirm();
  };

  return createPortal(
    <div id="confirmationPopup"
         className={`modal fade ${styles["confirmation-modal"]}`}
         ref={modalRef}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">Confirmation</h1>
            <button
              type="button"
              className="close"
              onClick={toggle}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="modal-container">
              The selected records will be changed, are you sure you want to continue?
            </div>
          </div>
          <div className={`modal-footer`}>
            <div className="modal-container">
              <div className="btn-group">
                <button
                  id={`confirmBtn`}
                  type="button"
                  className="btn btn-primary"
                  onClick={onClick}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>, document.body,
  );
};

