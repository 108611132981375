"use strict";

import { StatusDisplayService } from "../status_display_service";

/**
 * @callback StatusHideCallback
 * @callback StatusDisplayCallback
 * @param immediately {boolean} Indicates that the status should be hidden even if there's a pending operation
 */

export class BulkOperationsStatusDisplayService extends StatusDisplayService {
  /**
   *
   * @param displayCallback {StatusDisplayCallback}
   * @param hideCallback {StatusHideCallback}
   */
  constructor(displayCallback, hideCallback) {
    super();

    /**
     * @type {StatusDisplayCallback}
     */
    this.displayCallback = displayCallback;
    /**
     * @type {StatusHideCallback}
     */
    this.hideCallback = hideCallback;
  }

  /**
   * Displays a status message
   * @param message {string} The status message to be displayed
   * @param details {*} The details for the message
   */
  displayStatus(message, details = null) {
    return this.displayCallback(message, details);
  }

  /**
   * Hides the status message
   * @param immediately {boolean} Indicates that the status should be hidden even if there's a pending operation
   */
  hideStatus(immediately = false) {
    return this.hideCallback ? this.hideCallback(immediately) : super.hideStatus(immediately);
  }
}
