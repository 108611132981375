var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { guid, classNames, getTabIndex, dispatchEvent, useDir, createPropsContext, usePropsContext, kendoThemeMaps } from '@progress/kendo-react-common';
/**
 * Represents the PropsContext of the `TextArea` component.
 * Used for global configuration of all `TextArea` instances.
 *
 * For more information, refer to the [Inputs Props Context]({% slug props-context_inputs %}) article.
 */
export var TextAreaPropsContext = createPropsContext();
/**
 * Represents the [KendoReact TextArea component]({% slug overview_textarea %}).
 *
 *
 * Accepts properties of type [TextAreaProps]({% slug api_inputs_textareaprops %}).
 * Obtaining the `ref` returns an object of type [TextAreaHandle]({% slug api_inputs_textareahandle %}).
 *
 * @example
 * ```jsx
 * class App extends React.Component {
 *     render() {
 *         return <TextArea />;
 *     }
 * }
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var TextArea = React.forwardRef(function (directProps, target) {
    var _a;
    var props = usePropsContext(TextAreaPropsContext, directProps);
    var _b = props, size = _b.size, rounded = _b.rounded, fillMode = _b.fillMode, ariaDescribedBy = _b.ariaDescribedBy, ariaLabelledBy = _b.ariaLabelledBy, autoSize = _b.autoSize, className = _b.className, defaultValue = _b.defaultValue, disabled = _b.disabled, readOnly = _b.readOnly, required = _b.required, rows = _b.rows, id = _b.id, name = _b.name, placeholder = _b.placeholder, style = _b.style, tabIndex = _b.tabIndex, value = _b.value, valid = _b.valid, validationMessage = _b.validationMessage, validityStyles = _b.validityStyles, onChange = _b.onChange, onFocus = _b.onFocus, onBlur = _b.onBlur, 
    // Removed to support direct use in Form Field component
    visited = _b.visited, touched = _b.touched, modified = _b.modified, others = __rest(_b, ["size", "rounded", "fillMode", "ariaDescribedBy", "ariaLabelledBy", "autoSize", "className", "defaultValue", "disabled", "readOnly", "required", "rows", "id", "name", "placeholder", "style", "tabIndex", "value", "valid", "validationMessage", "validityStyles", "onChange", "onFocus", "onBlur", "visited", "touched", "modified"]);
    var elementRef = React.useRef(null);
    var focusElement = React.useCallback(function () {
        if (elementRef.current) {
            elementRef.current.focus();
        }
    }, []);
    var _c = React.useState(defaultValue), stateValue = _c[0], setStateValue = _c[1];
    var isControlled = value !== undefined;
    var currentValue = isControlled ? value : stateValue;
    var getImperativeHandle = React.useCallback(function () {
        return ({
            element: elementRef,
            focus: focusElement,
            get value() { return currentValue; },
            get name() { return elementRef.current && elementRef.current.name; }
        });
    }, [focusElement, currentValue]);
    React.useImperativeHandle(target, getImperativeHandle);
    var _d = React.useState('auto'), textAreaHeight = _d[0], setTextAreaHeight = _d[1];
    var calculatedId = React.useMemo(function () { return guid(); }, []);
    var dir = useDir(elementRef, props.dir);
    var isValid = valid !== undefined ?
        valid :
        !required ?
            true :
            currentValue ? true : false;
    React.useEffect(function () {
        if (elementRef.current && elementRef.current.setCustomValidity) {
            elementRef.current.setCustomValidity(isValid
                ? ''
                : (validationMessage || ''));
        }
    }, [isValid, validationMessage]);
    React.useLayoutEffect(function () {
        if (elementRef.current) {
            setTextAreaHeight("".concat(elementRef.current.scrollHeight, "px"));
        }
    }, [currentValue]);
    var handleChange = React.useCallback(function (event) {
        var newValue = event.target.value;
        setTextAreaHeight('auto');
        if (!isControlled && !disabled) {
            setStateValue(newValue);
        }
        if (onChange && !disabled) {
            dispatchEvent(onChange, event, __assign(__assign({}, getImperativeHandle()), { value: newValue }), { value: newValue });
        }
    }, [setStateValue, onChange, disabled, isControlled]);
    var handleFocus = React.useCallback(function (event) {
        if (onFocus && !disabled) {
            dispatchEvent(onFocus, event, getImperativeHandle(), undefined);
        }
    }, [onFocus, disabled]);
    var handleBlur = React.useCallback(function (event) {
        if (onBlur && !disabled) {
            dispatchEvent(onBlur, event, getImperativeHandle(), undefined);
        }
    }, [onBlur, disabled]);
    var textAreaProps = __assign(__assign({ id: id || calculatedId, name: name, className: 'k-input-inner !k-overflow-auto', ref: elementRef, disabled: disabled, rows: rows, placeholder: placeholder, readOnly: readOnly, required: required, tabIndex: getTabIndex(tabIndex, disabled), style: autoSize ? { resize: 'none', overflow: 'hidden', height: textAreaHeight } : {}, 'aria-labelledby': ariaLabelledBy, 'aria-describedby': ariaDescribedBy, 'aria-multiline': true, 'aria-disabled': disabled || undefined, value: isControlled ? value : stateValue }, others), { onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur });
    return (React.createElement("span", { className: classNames('k-input', 'k-textarea', (_a = {},
            _a["k-input-".concat(kendoThemeMaps.sizeMap[size] || size)] = size,
            _a["k-input-".concat(fillMode)] = fillMode,
            _a["k-rounded-".concat(kendoThemeMaps.roundedMap[rounded] || rounded)] = rounded,
            _a['k-invalid'] = !(isValid || validityStyles !== undefined || validityStyles === true),
            _a['k-required'] = required,
            _a['k-disabled'] = disabled,
            _a), className), style: style, dir: dir },
        React.createElement("textarea", __assign({}, textAreaProps))));
});
TextArea.propTypes = {
    ariaDescribedBy: PropTypes.string,
    ariaLabelledBy: PropTypes.string,
    autoSize: PropTypes.bool,
    className: PropTypes.string,
    defaultValue: PropTypes.string,
    dir: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    rows: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    style: PropTypes.object,
    tabIndex: PropTypes.number,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.number
    ]),
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    size: PropTypes.oneOf([null, 'small', 'medium', 'large']),
    rounded: PropTypes.oneOf([null, 'small', 'medium', 'large', 'full']),
    fillMode: PropTypes.oneOf([null, 'solid', 'flat', 'outline'])
};
var defaultProps = {
    size: 'medium',
    rounded: 'medium',
    fillMode: 'solid'
};
TextArea.displayName = 'KendoTextArea';
TextArea.defaultProps = defaultProps;
