import * as React from "react";
import {Button} from "@progress/kendo-react-buttons";
import {EditorToolsSettings} from "@progress/kendo-react-editor";
import {isInWidget, isWidget} from "../common/editorSchemas/widget_node";
import {WIDGET_KIND} from "../components/sideMenu/widget/widget_constants";
import {ToolProps} from "../common/types";

const DELETE_TABLE_SETTINGS = EditorToolsSettings.deleteTable;

/**
 * The editor tool to delete a widget or table. The widget and table cannot be deleted by
 * backspace, so we have to make this tool
 */
export default function DeleteWidget(props: ToolProps) {
  const {view} = props;

  const handleDeleteButtonClick = () => {
    return deleteElement(!isInWidget(view, WIDGET_KIND.Table));
  };

  const deleteElement = (hasTableOnly: boolean) => {
    const {state, dispatch} = view;
    const anchor = state.selection.$anchor;
    let shouldDeleteNode = false;
    for (let d = anchor.depth; d > 0; d--) {
      const node = anchor.node(d);

      if (hasTableOnly && node.type.spec.tableRole === "table") {
        shouldDeleteNode = true;
      } else if (isWidget(node)) {
        shouldDeleteNode = true;
      }

      if (shouldDeleteNode) {
        if (dispatch) {
          dispatch(
            state.tr.delete(anchor.before(d), anchor.after(d)).scrollIntoView(),
          );
        }
        return true;
      }
    }
    return false;
  };

  const isInTable = () => {
    const state = view?.state;

    if (!state) {
      return false;
    }

    let $head = state.selection.$head;
    for (let d = $head.depth; d > 0; d--)
      if ($head.node(d).type.spec.tableRole === "row") {
        return true;
      }
    return false;
  };

  return (
    <Button
      id="deleteWidgetTool"
      onClick={handleDeleteButtonClick}
      disabled={
        !isInTable() &&
        (!isInWidget(view) ||
          isInWidget(view, WIDGET_KIND.Header) ||
          isInWidget(view, WIDGET_KIND.Footer))
      }
      onMouseDown={(e) => e.preventDefault()}
      onPointerDown={(e) => e.preventDefault()}
      title="Delete Widget/Table"
      {...DELETE_TABLE_SETTINGS.props}
    />
  );
}
