import { BaseParser } from "./base_parser";
import { getRiskLinks } from "../../../helpers/risk_helper";
import { Filters } from "../../components/multiple_filters";
import * as DocumentLinkParser from "./elementParser/document_link_parser";
import * as AcceptanceCriteriaRangeParser from "./elementParser/acceptance_criteria_range_parser";
import { COMMON_ATTRIBUTES } from "../../components/sideMenu/widget/widget_constants";

/**
 * Parser that parses all page breaks
 */
export class BaseWidgetParser extends BaseParser {
  getRecords(parentModelName, subModelName) {
    if (!this.data) {
      return [];
    }

    const modelName = subModelName ? subModelName : parentModelName;
    let data = this.data;
    let records = [];
    if (modelName === "ControlMethod") {
      records = this.data?.ControlMethods || this.data?.ControlMethod || [];
    } else if (modelName === "AcceptanceCriteriaRange" && data.Requirement) {
      records = AcceptanceCriteriaRangeParser.getValues(this.data);
    } else if (DocumentLinkParser.isLinkAttribute(modelName)) {
      records = DocumentLinkParser.getValues(data, parentModelName, modelName);
    } else if (modelName === "Criticality") {
      records = getRiskLinks(this.data);
    } else if (data[modelName]) {
      if (Array.isArray(data[modelName])) {
        records = data[modelName];
      } else {
        records = [data[modelName]];
      }
    } else if (data[`${modelName}s`] && Array.isArray(data[`${modelName}s`])) {
      records = data[`${modelName}s`];
    }

    const filters = JSON.parse(this.node.getAttribute("filters") || "[]");
    if (filters && filters.length) {
      const recordFilters = new Filters(filters);
      return recordFilters.apply(records, this.rmp);
    }

    return records;
  }

  addAttributesInFiltersToFields() {
    const filterStr = this.node.getAttribute(COMMON_ATTRIBUTES.Filters.key);
    const filters = JSON.parse(filterStr || "[]");
    if (!Array.isArray(filters)) {
      return;
    }

    for (const filter of filters) {
      const modelParts = filter.model.split(".");
      const modelName = modelParts[modelParts.length - 1];
      const attributeParts = filter.attribute.split(".");
      const columnName = attributeParts[attributeParts.length - 1];
      this.addModelAndColumnToField(this.fields, modelName, columnName);
    }
  }
}
