var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// Gets and sets the children. Returns the children or the re-created item with the new children.
function children(item, subItemsField, subItems) {
    var _a;
    if (subItems) {
        return __assign(__assign({}, item), (_a = {}, _a[subItemsField] = subItems.length ? __spreadArray([], subItems, true) : undefined, _a));
    }
    else {
        return item && item[subItemsField] ? __spreadArray([], item[subItemsField], true) : [];
    }
}
function mapItem(dataItem, subItemsField, callbackfn) {
    if (dataItem[subItemsField]) {
        var subItems = children(dataItem, subItemsField).map(function (child) { return mapItem(child, subItemsField, callbackfn); });
        return callbackfn(children(dataItem, subItemsField, subItems));
    }
    return callbackfn(dataItem);
}
/**
 * Creates a new array with the results of calling the provided callback function
 * on every element in the provided data tree.
 *
 * @param {any[]} tree - The data tree.
 * @param {string} subItemsField - The field which points to the subitems collection of each data item.
 * @param {(value: any) => any} callback - The callback function.
 * @returns {any[]} - The new data tree.
 */
export var mapTree = function (tree, subItemsField, callback) {
    return __spreadArray([], tree.map(function (item) { return mapItem(item, subItemsField, callback); }), true);
};
/**
 * Creates a new array with the results of calling the provided callback function
 * on the element which match the `level` in the provided data tree.
 *
 * @param {any[]} tree - The data tree.
 * @param {number[]} level - An array of indexes of each parent and current item in the data tree.
 * @param {string} subItemsField - The field which points to the subitems collection of each data item.
 * @param {(value: any) => any} callback - The callback function.
 * @returns {any[]} - The new data tree.
 */
export var mapTreeItem = function (tree, level, subItemsField, callback) {
    var curData = tree;
    var parentNodes = [];
    for (var i = 0; i < level.length; i++) {
        curData = curData[level[i]];
        curData = Array.isArray(curData) ? curData : curData[subItemsField];
        parentNodes.push(curData);
    }
    var parent = parentNodes.length > 1 ? parentNodes[parentNodes.length - 2] : tree;
    var newItemIndex = level[level.length - 1];
    var newItem = callback(parent[newItemIndex]);
    parent.splice(newItemIndex, 1, newItem);
};
/**
 * Similar to the `Object.assign` function. Additionally, creates a new array for the subitems.
 *
 * @param {object} item - The source data item.
 * @param {string} subItemsField - The field which points to the subitems collection of each data item.
 * @param {object} propsToExtend - The props with which the source data item will be extended.
 * @returns {object} - The target data item.
 */
export var extendDataItem = function (item, subItemsField, propsToExtend) {
    var _a;
    return Object.assign({}, item, item[subItemsField] ? (_a = {}, _a[subItemsField] = item[subItemsField].slice(), _a) : {}, propsToExtend || {});
};
/**
 * Returns the data item path in the tree based on the level parameter.
 *
 * @param {any[]} tree - The data tree.
 * @param {number[]} level - The level of the target tree item.
 * @param {string} subItemsField - The field which points to the subitems collection of each data item.
 * @returns {any[]} - The path of the data item.
 */
export var getItemPath = function (tree, level, subItemsField) {
    var nodes = [];
    var itemLevel = __spreadArray([], level, true);
    if (itemLevel.length) {
        var element = tree[itemLevel.shift() || 0];
        nodes.push(element);
        while (itemLevel.length && subItemsField) {
            element = element[subItemsField][itemLevel.shift() || 0];
            nodes.push(element);
        }
    }
    return nodes;
};
