"use strict";

import React from "react";
import { WIDGET_FIELDS } from "./constants/rmp_links_attribute_constants";
import BaseLinksAttachmentsAttribute from "../editor/attributes/base_links_attachments_attribute";
import { CUSTOM_ERROR_TYPE } from "../editor/attributes/base_json_attribute";

/**
 * This is class is responsible for handling Risk Management Plan links and attachments.
 */
export default class RMPAttachmentsAttribute extends BaseLinksAttachmentsAttribute {
  constructor(props) {
    super(props, WIDGET_FIELDS);
  }

  /**
   * Updates the UI with a custom error
   * @param error The error to show on the UI
   * @param errorType The error type based on if it was raised due to a save or propose action.
   */
  setError(error, errorType = CUSTOM_ERROR_TYPE.FOR_SAVE) {
    super.setError(error, errorType);
    this.props.parent.setError("attachmentsSectionError", error);
  }

  clearErrorText(forceUpdate) {
    super.clearErrorText(forceUpdate);
    this.props.parent.clearError("attachmentsSectionError");
  }
}
