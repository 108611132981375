"use strict";

import * as UIUtils from "../ui_utils";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";
import CommonUtils from "../../server/common/generic/common_utils";
import { isAuthenticationError, redirectToLoginPage } from "../utils/ajax_wrapper";
import CommonCompression from "../../server/common/generic/common_compression";

const Logger = Log.group(LOG_GROUP.WebSockets, "WebSocketsMessageHandler");

const DEFAULT_STATUS_MAP = new Map(
  [
    ["started", ""],
    ["authenticating", ""],
  ]
);

/**
 * Base class for implementing WebSocket events. Overwrite this in child classed to customize the functionality inside
 * WebSocket events.
 */
export class WebSocketsMessageHandler {

  /**
   * Creates a new instance
   * @param statusService {StatusDisplayService}
   */
  constructor(statusService) {
    /**
     * The service that reports status to the user
     * @type {StatusDisplayService}
     */
    this.statusService = statusService;

    /**
     * The interval in milliseconds between attempts to close the websocket connection with a valid result
     * after we received the "DONE" message from the server.
     * @type {number}
     */
    this.waitForCompleteInterval = 100;

    /**
     * The timeout in milliseconds until we give up to close the websocket connection with a valid result
     * after we received the "DONE" message from the server.
     *
     * (this means we close it anyway, even if a valid result didn't arrive)
     *
     * @type {number}
     */
    this.waitForCompleteTimeout = CommonUtils.isEnvironmentLocal() ? 500 : 3000;

    this.handleOpen = this.handleOpen.bind(this);
    this.onCustomMessage = this.onCustomMessage.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
    this.displayStatus = this.displayStatus.bind(this);
    this.onProgress = this.onProgress.bind(this);
    this.onDone = this.onDone.bind(this);
    this.getDisplayMessage = this.getDisplayMessage.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleMaximum = this.handleMaximum.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleReconnect = this.handleReconnect.bind(this);
    this.hideStatus = this.hideStatus.bind(this);
    this.onError = this.onError.bind(this);
    this.onGone = this.onGone.bind(this);
    this.onInvalidMessage = this.onInvalidMessage.bind(this);
    this.onUnexpectedError = this.onUnexpectedError.bind(this);
    this.rejectWithMessage = this.rejectWithMessage.bind(this);
    this.finalize = this.finalize.bind(this);
    this.waitForComplete = this.waitForComplete.bind(this);
  }

  /**
   * Creates a handler for the onOpen event of the websocket.
   * @param event {Event}
   * @param context {IConnectionContext}
   * @returns {void}
   */
  handleOpen(event, context) {
    const {client, parameters, payload} = context;
    const {action} = parameters || {};

    this.displayStatus("Loading...");
    Logger.info(() => "Opening websocket connection");

    client.json({
      action,
      data: JSON.stringify(payload),
    }).then(() => {
      Logger.info(() => "Websocket open");
    });
  }

  /**
   * Creates a handler for the the onMessage event of the websocket
   * @param event {MessageEvent}
   * @param context {IConnectionContext}
   * @returns {Function}
   */
  handleMessage(event, context) {
    let message = event.data;

    Logger.debug("Websocket message received: ", Log.object(message));
    if (typeof message === "object") {
      if (message.status) {
        switch (message.status) {
          case "progress":
            this.onProgress(message, context);
            break;
          case "success":
            this.onSuccess(message, context);
            break;
          case "done":
            this.onDone(message, context);
            break;
          case "error":
            if (message.error.statusCode === 410) {
              this.onGone(message, context);
            } else {
              this.onError(message, context);
            }
            break;
          default:
            this.onCustomMessage(message, context);
            break;
        }
      } else if (message.message) {
        this.onUnexpectedError(message, context);
      } else {
        this.onInvalidMessage(message, context);
      }
    } else {
      this.onInvalidMessage(message, context);
    }
  }

  /**
   * Handles unexpected error messages (not in the standard format)
   * @param message {*}
   * @param context {IConnectionContext}
   * @protected
   */
  onUnexpectedError(message, context) {
    this.rejectWithMessage(context, message.message, message);
  }

  /**
   * Handles invalid messages (not in the standard format)
   * @param message {*}
   * @param context {IConnectionContext}
   * @protected
   */
  onInvalidMessage(message, context) {
    this.rejectWithMessage(context, "WebSockets message is in an invalid format.", message);
  }

  /**
   * Handles a message that indicates the progress of the operation
   * @param message {*}
   * @param context {IConnectionContext}
   * @protected
   */
  onProgress(message, context) {
    this.displayStatus(message, context);
  }

  /**
   * Handles The error that occurs when the websocket gives a 410 error
   * @param message {*}
   * @param context {IConnectionContext}
   * @protected
   */
  // eslint-disable-next-line no-unused-vars
  onGone(message, context) {
    Logger.warn(() => "WebSocket gone (410):", message.error);
    UIUtils.sendErrorTelemetry(message.error, message);
  }

  /**
   * Handles a custom (not standard) message
   * @param message {*}
   * @param context {IConnectionContext}
   * @protected
   */
  // eslint-disable-next-line no-unused-vars
  onCustomMessage(message, context) {
    Logger.warn(() => "Unexpected status: ", message.status);
  }

  /**
   * Handles an error message
   * @param message {*}
   * @param context {IConnectionContext}
   * @protected
   */
  onError(message, context) {
    this.hideStatus(true);

    if (message.error && !message.error.doNotSendStackTrace) {
      Logger.error(() => `Backend error: ${Log.symbol(message.error.message || "Unexpected")}`, message.error.stack ? Log.error(message.error) : Log.object(message.error));
    }

    context.hasResult = true;
    context.error = message.error;

    // This is an application error, so we still wait for the `done` message to arrive to close the connection gracefully.
    // This is the error flow that is expected to happen if validation or business logic errors occur.
  }

  /**
   * Actually ends the connection, resolving or rejecting the pending promise
   * @param context {IConnectionContext} The websocket connection context
   * @protected
   */
  finalize(context) {
    if (context?.data[0] && Object.prototype.hasOwnProperty.call(context.data[0], "password")) {
      context.data[0].password = "***********";
    }
    Logger.info(() => "WebSocket connection closed. Results: ", Log.json(context.error || context.data));
    let error = context.error;

    if (error) {
      // Appends extra information to the error object so it can appear on telemetry.
      error.requestParams = {
        transport: "websocket",
        ...(context.parameters || {}),
        payload: context.payload,
        data: context.data,
      };

      context.reject(error);
      if (isAuthenticationError(error)) {
        redirectToLoginPage(error);
      }
    } else {
      const data = context.data || [];
      if (data && data.length === 1) {
        context.resolve(data[0]);
      } else {
        context.resolve(data);
      }
    }

    context.client.close();

    // hides the status when the operation is complete (unless explicitly required)
    if (!(context.parameters && context.parameters.keepStatusAfterSuccess)) {
      this.hideStatus();
    }
  }

  /**
   * Waits until the context has received any result (or until it times out waiting)
   *
   * @description This is required because in distributed systems we can't actually ensure that the `done` message will arrive
   * after the `success` or `error` message(s). So, we wait up to a configurable timeout to increase the likelihood that
   * a result has been received.
   *
   * @param context {IConnectionContext} The websocket connection context
   * @protected
   */
  waitForComplete(context) {
    let pendingItems = [];

    if (!context.hasResult) {
      pendingItems.push("Result");
    }
    if (!context.isDone) {
      pendingItems.push("Done");
    }

    const maxAttempts = this.waitForCompleteTimeout / this.waitForCompleteInterval;

    // If we are done or if we exceeded the max attempts or if we are complete,
    // we finalize it right away (even if no result or DONE received)
    if (pendingItems.length === 0 || context.finalizeAttempt >= maxAttempts) {
      if (pendingItems.length > 0) {
        const message = `Timed out waiting for ${pendingItems.join(" and ")} after calling waitForComplete.`;
        Logger.warn(() => message);
        const error = new Error(message);

        try {
          Error.captureStackTrace(error);
        } catch {
          console.error("CaptureStackTrace is not supported. Error returned: " + message);
        }


        UIUtils.sendErrorTelemetry(error, {eventName: "WEBSOCKET_FINALIZER_TIMEOUT"});
      }
      this.finalize(context);
    } else {
      // if we don't have a result or DONE message, but we still have time to wait for the
      // result to arrive, we wait a bit and try again
      Logger.verbose(() => `Attempt ${context.finalizeAttempt}: No ${pendingItems.join(", ")} received yet. Waiting...`);
      context.finalizeAttempt++;
      setTimeout(() => this.waitForComplete(context), this.waitForCompleteInterval);
    }
  }


  /**
   * Handles the message that states that the operation is complete
   * @param message {*}
   * @param context {IConnectionContext}
   * @protected
   */
  onDone(message, context) {
    context.isDone = true;
    this.waitForComplete(context);
  }

  /**
   * Handles the message that states that the operations has succeeded
   * @param message {*}
   * @param context {IConnectionContext}
   * @protected
   */
  onSuccess(message, context) {
    this.displayStatus("success", context);

    context.hasResult = true;

    if (message?.data?.shouldCompress) {
      message.data = CommonCompression.decompressAndParseJSON(message.data.data);
    }

    // there may be multiple success messages in a bulk operation, so we aggregate them here instead of considering it done
    context.data = [
      ...context.data,
      message.data,
    ];
  }

  /**
   * Hides the status message
   * @param immediately {boolean} If set to true, hides the status immediately (even if there is another pending operation)
   * @protected
   */
  hideStatus(immediately = false) {
    this.statusService.hideStatus(immediately);
  }

  /**
   * Displays a status message with the progress information
   * @param message {*} The message to be displayed
   * @param context {IConnectionContext} The context of this operation
   * @param details {*} The details to be displayed
   * @protected
   */
  displayStatus(message, context= {}, details = null) {
    const {parameters} = context || {};
    let displayMessage = this.getDisplayMessage(message, parameters);

    this.statusService.displayStatus(displayMessage, details);
  }

  /**
   * Rejects the received message (usually happens if the message is not in the correct format).
   * @param context {IConnectionContext} The context of this operation
   * @param messageText {string} The message to display when rejecting
   * @param additionalData {*} Additional data to be included in the message
   * @protected
   */
  rejectWithMessage(context, messageText, additionalData = null) {
    if (additionalData) {
      Logger.error(messageText, Log.object(additionalData));
    }

    this.hideStatus(true);
    context.error = new Error(messageText);
    context.hasResult = true;

    // If the message is in an incorrect format, this is a programming error,
    // so we show the error right away (if it's not the "done" message, this may cause a 410 in the backend,
    // which is good in this case, because it shouldn't happen in production. If it does, we get a notification).
    this.waitForComplete(context);
  }

  /**
   * Creates a handler for the the onError event of the websocket (a protocol error, not an application error)
   * @param event {Event} The error event
   * @param context {IConnectionContext}
   * @returns {Function}
   */
  handleError(event, context) {
    Logger.error(() => "WebSocket error", Log.object(event), "\nContext: \n", Log.object(context));

    context.error = event;
    context.hasResult = true;

    // If a websocket error (a protocol error, not an application error), this may be a programming error,
    // so we show the error right away (if this happens before the "done" message, this may cause a 410 in the backend,
    // which is good in this case, because it shouldn't happen in production. If it does, we get a notification).
    UIUtils.sendErrorTelemetry(context.error, event);
    this.waitForComplete(context);
  }

  /**
   * Handles websocket reconnection
   * @param event {*} The web socket event
   * @param context {IConnectionContext} The current connection context
   */
  handleReconnect(event, context) {
    Logger.info(() => "RECONNECT", Log.object(event), Log.object(context));
  }

  /**
   * Handles websocket close event
   * @param event {*} The web socket event
   * @param context {IConnectionContext} The current connection context
   */
  handleClose(event, context) {
    if (Object.prototype.hasOwnProperty.call(context.data[0] || {}, "password")) {
      context.payload.password = "*******";
    }
    Logger.info(() => "CLOSE", Log.object(event), Log.object(context));
  }

  /**
   * Handles websocket error when maximum number of connections is reached
   * @param event {*} The web socket event
   * @param context {IConnectionContext} The current connection context
   */
  handleMaximum(event, context) {
    Logger.error(() => `MAXIMUM Error. [Code ${event.code}] ${event.reason}`, );
  }

  /**
   * Retrieves the status message to display given a status map instance.
   * @param message
   * @param parameters
   * @return {*}
   */
  getDisplayMessage(message, parameters) {
    const {statusMap} = parameters || {};
    let finalStatusMap = statusMap ? new Map([...DEFAULT_STATUS_MAP].concat([...statusMap])) : DEFAULT_STATUS_MAP;
    let displayMessage = typeof message.data === "string" ? message.data : (message.message || "");

    // if the message is in the status translation map, uses the translated value
    if (finalStatusMap && finalStatusMap.has(displayMessage)) {
      displayMessage = finalStatusMap.get(displayMessage);
    }
    return displayMessage;
  }
}

