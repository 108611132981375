"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import moment from "moment-timezone";
import Heading from "../headers/heading";
import BasePopup from "../../editor/approval/base_popup";
import { TYPE_CODE } from "../../processExplorer/process_explorer_constants";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import Typeahead from "../typeahead";
import { CloningService } from "../../services/cloning/cloning_service";
import RecordLoader from "../../processExplorer/recordLoader/record_loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { EDITOR_OPERATIONS } from "../../editor/editor_constants";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import TypeaheadObjectCacheFactory from "../../utils/cache/typeahead_object_cache_factory";
import { orderAndIndexSteps, orderAndIndexUnits } from "../../../server/common/indexers/common_uo_indexer";

const FROM_PROJECT_OPTIONS = {
  typeCode: TYPE_CODE.PROJECT,
  type: "Project",
  dependsOn: null,
  label: "Project",
  filter: (projects) => projects.filter(project => !project.deletedAt),
  sort: projects => projects.sort(UIUtils.sortBy("name")),
};

const BASE_PROCESS_OPTIONS = {
  typeCode: TYPE_CODE.PROCESS,
  type: "Process",
  dependsOn: TYPE_CODE.PROJECT,
  label: "Process",
  filter: (processes, props) => {
    const {toProcess} = props;
    return processes.filter(process => !process.deletedAt && process.id !== toProcess?.id);
  },
  sort: processes => processes.sort(UIUtils.sortBy("name")),
};

const BASE_UNIT_OPERATION_OPTIONS = {
  typeCode: TYPE_CODE.UNIT_OPERATION,
  type: "UnitOperation",
  dependsOn: TYPE_CODE.PROCESS,
  label: "Unit Operation",
  filter: uos => uos.filter(uo => !uo.deletedAt),
  sort: uos => orderAndIndexUnits(uos, "id", false),
};

const BASE_STEP_OPTIONS = {
  typeCode: TYPE_CODE.STEP,
  type: "Step",
  dependsOn: TYPE_CODE.UNIT_OPERATION,
  label: "Step",
};

const INPUTS = {
  UO: {
    from: [
      FROM_PROJECT_OPTIONS,
      BASE_PROCESS_OPTIONS,
      {
        ...BASE_UNIT_OPERATION_OPTIONS,
        previousId: "PreviousUnitId",
        selectedModelType: "Unit Operations",
        isTarget: true,
      },
    ],
    to: [
      {
        label: "Project",
        propsKey: "toProject",
      },
      {
        label: "Process",
        propsKey: "toProcess",
      },
    ],
  },
  STP: {
    from: [
      FROM_PROJECT_OPTIONS,
      BASE_PROCESS_OPTIONS,
      {
        ...BASE_UNIT_OPERATION_OPTIONS,
        selectedNodeId: "unitOperationId",
      },
      {
        ...BASE_STEP_OPTIONS,
        filter: (steps, props, state, isTargetTypeahead) => {
          const record = state.fromUnitOperation;
          let results;

          if (isTargetTypeahead) {
            results = steps.filter(step => !step.deletedAt && step.UnitOperationId === props.selectedNode.unitOperationId);
          } else {
            results = steps.filter((step) => !step.deletedAt && (!record || step.UnitOperationId === record?.id));
          }

          return results;
        },
        sort: steps => orderAndIndexSteps(steps, "id", false),
        previousId: "PreviousStepId",
        isTarget: true,
        selectedModelType: "Steps",
      },
    ],
    to: [
      {
        label: "Project",
        propsKey: "toProject"
      },
      {
        label: "Process",
        propsKey: "toProcess"
      },
      {
        label: "Unit Operation",
        propsKey: "selectedNode"
      }
    ]
  },
};

/**
 * This popup component will be used for cloning a record from another project
 * or process.
 */
export default class CopyRecordFromPopup extends BasePopup {
  constructor(props) {
    super(props);

    this.cloningService = new CloningService();
    this.inputs = INPUTS[props.copyFromForTypeCode];
  }

  componentDidMount() {
    super.componentDidMount();

    const {toProject, toProcess} = this.props;
    const firstInput = this.inputs.from.find((input) => !input.dependsOn);

    new TypeaheadObjectCache(firstInput.type).loadOptions((result) => {
      this.handleTypeaheadResultsFromServer(result, firstInput);
    });

    const target = this.inputs.from.find((input) => input.isTarget);
    new TypeaheadObjectCache(
      target.type,
      toProject.id,
      toProcess.id
    ).loadOptions((result) => {
      this.handleTypeaheadResultsFromServer(result, target, true);
    });
  }

  findAllDependedInputs(typeCode) {
    const record = this.inputs.from.find((r) => r.typeCode === typeCode);
    const dependedRecords = [];

    const findDependedInputs = (record) => {
      const dependedRecord = this.inputs.from.find(
        (r) => r.dependsOn === record.typeCode
      );
      if (!dependedRecord) {
        return dependedRecords;
      }
      dependedRecords.push(dependedRecord);
      return findDependedInputs(dependedRecord);
    };

    return findDependedInputs(record);
  }

  /**
   * When we change an input, we need to reset all the descendent input and update
   * the value of the input we just changed
   * @param currentInput {any}
   * @param value {any}
   * @param callback {function}
   */
  resetAndUpdateStateWhenInputChange(currentInput, value, callback = () => {
  }) {
    const newState = {
      allChildRecords: [],
      fromTarget: null,
      copyTargetName: "",
      copyTargetOrder: null,
      enabledTargetTypeahead: false,
    };
    const {typeCode} = currentInput;
    const dependedInputs = this.findAllDependedInputs(typeCode);
    for (const dependedInput of dependedInputs) {
      const key = `from${dependedInput.type}`;
      newState[key] = null;
    }
    newState[`from${currentInput.type}`] = value;

    if (currentInput.typeCode === TYPE_CODE.PROJECT) {
      newState.fromProjectId = value.id;
    } else if (currentInput.typeCode === TYPE_CODE.PROCESS) {
      newState.fromProcessId = value.id;
    }

    this.setStateSafely(newState, callback);
  }

  handleFromRecordValueChange(values, input) {
    if (!values || !values[0]) {
      return;
    }

    this.resetAndUpdateStateWhenInputChange(input, values[0], () => {
      const dependedRecord = this.inputs.from.find(
        (r) => r.dependsOn === input.typeCode
      );
      if (dependedRecord) {
        new TypeaheadObjectCache(
          dependedRecord.type,
          this.state.fromProjectId,
          this.state.fromProcessId
        ).loadOptions((result) =>
          this.handleTypeaheadResultsFromServer(result, dependedRecord)
        );
      }
    });
  }

  handleTargetChange(values) {
    if (!values || !values[0]) {
      return;
    }

    this.setStateSafely(
      {
        allChildRecords: [],
        fromTarget: values[0],
        copyTargetName: `Copy of ${values[0].name}`,
        copyTargetOrder: null,
      },
      () => {
        const ALL_CHILDREN_RECORDS = [
          "Material",
          "ProcessComponent",
          "IQA",
          "IPA",
          "MaterialAttribute",
          "ProcessParameter",
          "Step",
        ];
        const multipleTypeaheadObjectCache =
          TypeaheadObjectCacheFactory.createMultipleTypeaheadObjectCacheIfPossible(
            ALL_CHILDREN_RECORDS,
            () => this.state.fromProjectId,
            () => this.state.fromProcessId
          );
        if (multipleTypeaheadObjectCache) {
          multipleTypeaheadObjectCache.loadOptions((result, typeCode) => {
            this.loadAllChildForTargetRecord(result, typeCode);
          });
        }
      }
    );
  }

  loadAllChildForTargetRecord(result, typeCode) {
    const targetInput = this.inputs.from.find(input => input.isTarget);
    const name = UIUtils.getModelNameForTypeCode(typeCode);
    if (name) {
      const childRecords = result.filter((record) => {
        if (record[`${targetInput.type}s`] && Array.isArray(record[`${targetInput.type}s`])) {
          return record[`${targetInput.type}s`].includes(
            UIUtils.parseInt(this.state.fromTarget.id)
          );
        }
        return record[`${targetInput.type}Id`] === UIUtils.parseInt(this.state.fromTarget.id);
      });

      if (childRecords.length > 0) {
        const allChildRecords = this.state.allChildRecords || [];
        allChildRecords.push(`${childRecords.length} ${UIUtils.pluralize(name, childRecords.length)}`);
        const allChildFullRecords = this.state.allChildFullRecords || [];
        this.setStateSafely({
          allChildRecords,
          allChildFullRecords: [...allChildFullRecords, ...childRecords],
        });
      }
    }
  }

  /**
   * When we get the results from the server, we need to update the state
   * @param result {any} the results from the server
   * @param typeaheadOptions {any} the record typeahead options
   * @param isTargetTypeahead {boolean} whether or not the results refer to a typeahead for the target record
   */
  handleTypeaheadResultsFromServer(result, typeaheadOptions, isTargetTypeahead = false) {
    const isCopyFromRecord = !isTargetTypeahead
      && typeaheadOptions.typeCode === this.props.copyFromForTypeCode;
    let key;

    // Based on the type of data loaded, we need to update a specific variable in the state
    if (isTargetTypeahead) {
      key = "toPreviousTargetOptions";
    } else if (isCopyFromRecord) {
      key = "targetOptions";
    } else {
      key = `${typeaheadOptions.type}Options`;
    }

    const newState = {
      [key]: result,
      enabledTargetTypeahead: isCopyFromRecord
    };

    if (typeaheadOptions.filter) {
      newState[key] = typeaheadOptions.filter(newState[key], this.props, this.state, isTargetTypeahead);
    }

    if (typeaheadOptions.sort) {
      newState[key] = typeaheadOptions.sort(newState[key]);
    }

    if (isTargetTypeahead) {
      newState[key].unshift({id: "Start", name: "Start", label: "Start"});
    }

    this.setStateSafely(newState);
  }

  handleTargetName(event) {
    this.setStateSafely({copyTargetName: event.target.value});
  }

  handleTargetOrderChange(values) {
    if (!values || !values[0]) {
      return;
    }
    this.setStateSafely({copyTargetOrder: values[0]});
  }

  /**
   * We will disable the Copy and Add button if we haven't filled in all the
   * inputs on the popup
   * @returns {boolean}
   */
  shouldDisableCopyButton() {
    for (const input of this.inputs.from) {
      if (!this.state[`from${input.type}`] && !input.isTarget) {
        return true;
      }
    }

    const keys = ["fromTarget", "copyTargetName"];
    for (const key of keys) {
      if (!this.state[key]) {
        return true;
      }
    }

    return (
      this.state.toPreviousTargetOptions &&
      this.state.toPreviousTargetOptions.length &&
      !this.state.copyTargetOrder
    );
  }

  handleCopyCompleted() {
    const {toProject, toProcess, onCopyCompletion} = this.props;
    if (onCopyCompletion) {
      onCopyCompletion(toProject.id, toProcess.id);
    }
  }

  async handleCopyAndAdd() {
    this.setStateSafely({isStartCopying: true});
    const {toProject, toProcess, copyFromForTypeCode, selectedNode, onCopyStarted} = this.props;
    const {
      fromProjectId,
      fromTarget,
      copyTargetName,
      copyTargetOrder,
    } = this.state;

    if (onCopyStarted) {
      onCopyStarted();
    }

    const recordLoader = new RecordLoader([`${copyFromForTypeCode}-${fromTarget.id}`], fromProjectId, true);
    await recordLoader.startLoading();
    let record;
    if (recordLoader.isLoading()) {
      record = recordLoader.findRecordIfLoaded(
        copyFromForTypeCode + "-" + fromTarget.id
      );
    } else {
      record = recordLoader.keyToRecordMap.get(
        copyFromForTypeCode + "-" + fromTarget.id
      );
    }
    const payload = {
      ...record,
      toProject,
      model: record.modelName,
      name: copyTargetName,
      ProjectId: toProject.id,
      ProcessId: toProcess.id,
      asOfDate: UIUtils.getEndOfDayForDate(moment()).tz("UTC").toDate(),
    };
    const targetInput = this.inputs.from.find(input => input.isTarget);
    const previousInput = this.inputs.from.find(input => input.typeCode === targetInput.dependsOn);
    if (previousInput && previousInput.selectedNodeId) {
      payload[`${previousInput.type}Id`] = selectedNode[previousInput.selectedNodeId];
    }

    if (targetInput.previousId && copyTargetOrder) {
      payload[targetInput.previousId] = copyTargetOrder.id === "Start" ? null : copyTargetOrder.id;
    }
    this.cloningService
      .clone(payload)
      .then(this.handleCopyCompleted)
      .catch((result) => {
        UIUtils.defaultFailFunction(
          result,
          null,
          null,
          UIUtils.PRIMARY_ALERT_DIV
        );
        $(this.modalRef).modal("hide");
      })
      .finally(() => {
        UIUtils.setLoadingDisabled(true);
      });
  }

  getTableViewUrl() {
    const {fromProject, fromProcess} = this.state;
    const targetInput = this.inputs.from.find((input) => input.isTarget);
    const previousInput = this.inputs.from.find(input => input.typeCode === targetInput.dependsOn);
    const strBuilder = ["selectedPanel=table", `selectedModelType=${targetInput.selectedModelType}`];

    if (fromProject) {
      strBuilder.push(`projectId=${fromProject.id}`);
    }

    if (fromProcess) {
      strBuilder.push(`processId=${fromProcess.id}`);
    }

    if (previousInput.selectedNodeId && this.state[`from${previousInput.type}`]) {
      strBuilder.push(`${previousInput.selectedNodeId}=${this.state[`from${previousInput.type}`].id}`);
    }

    return UIUtils.getSecuredURL(`${UIUtils.FRONT_END_URL}/processExplorer/processExplorer.html?${strBuilder.join("&")}`);
  }

  render() {
    const {copyFromForTypeCode} = this.props;
    const {
      fromTarget,
      fromProject,
      targetOptions,
      copyTargetName,
      copyTargetOrder,
      toPreviousTargetOptions,
      allChildRecords,
      enabledTargetTypeahead,
      isStartCopying
    } = this.state;
    const typeCodeName = UIUtils.getModelNameForTypeCode(copyFromForTypeCode);
    const targetInput = this.inputs.from.find(input => input.isTarget);

    return (
      <div className="copy-record-from-popup modal fade" ref={this.setModalRef}>
        <div className="modal-dialog modal-dialog-scrollable modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Copy {typeCodeName}</h1>
              <button
                type="button"
                className="close"
                onClick={this.handleCancel}
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-container">
                <div className="row">
                  <div className="from-to-section col-6">
                    <div className="section">
                      <Heading level={2}>From</Heading>
                      <div className="section-content">
                        {this.inputs.from
                          .filter((input) => !input.isTarget)
                          .map((input) => {
                            let disabled = false;
                            if (input.dependsOn) {
                              const parentInput = this.inputs.from.find(
                                (i) => i.typeCode === input.dependsOn
                              );
                              if (
                                parentInput &&
                                !this.state[`from${parentInput.type}`]
                              ) {
                                disabled = true;
                              }
                            }
                            return (
                              <div key={input.typeCode} className="form-group">
                                <label
                                  htmlFor="projectDropDown"
                                  className="col-form-label"
                                >
                                  {input.label}
                                </label>
                                <Typeahead
                                  id={`${UIUtils.convertToCamelCaseId(input.type)}Typeahead`}
                                  inputProps={{
                                    id: `${UIUtils.convertToCamelCaseId(input.type)}TypeaheadInput`,
                                    autoComplete: "off",
                                  }}
                                  options={
                                    this.state[`${input.type}Options`] || []
                                  }
                                  selected={
                                    this.state[`from${input.type}`]
                                      ? [this.state[`from${input.type}`]]
                                      : []
                                  }
                                  onChange={(values) =>
                                    this.handleFromRecordValueChange(
                                      values,
                                      input
                                    )
                                  }
                                  disabled={disabled}
                                  placeholder={`Select ${input.label}`}
                                  labelKey="name"
                                  selectHintOnEnter
                                />
                              </div>
                            );
                          })}
                      </div>
                      <hr />
                      <div id="target-header" className="row">
                        <Heading className="col align-self-start" level={2}>{typeCodeName}</Heading>
                        <div>
                          <a href={this.getTableViewUrl()}
                             className={`btn btn-link${!fromProject ? " disabled" : ""}`}
                             rel="noopener noreferrer"
                             target="_blank"
                          >
                            Look up {copyFromForTypeCode}s in the table view <FontAwesomeIcon icon={faExternalLinkAlt} />
                          </a>
                        </div>
                      </div>
                      <div className="section-content">
                        <div className="form-group">
                          <Typeahead
                            id={`${UIUtils.convertToCamelCaseId(targetInput.type)}Typeahead`}
                            inputProps={{
                              id: `${UIUtils.convertToCamelCaseId(targetInput.type)}TypeaheadInput`,
                              autoComplete: "off",
                            }}
                            options={targetOptions || []}
                            onChange={this.handleTargetChange}
                            selectHintOnEnter
                            placeholder={`Select ${typeCodeName}`}
                            disabled={!enabledTargetTypeahead}
                            selected={fromTarget ? [fromTarget] : []}
                            labelKey="name"
                          />
                          <a href={getURLByTypeCodeAndId(copyFromForTypeCode, EDITOR_OPERATIONS.VIEW, fromTarget?.id)}
                             className={`btn btn-link${!fromTarget ? " disabled" : ""}`}
                             rel="noopener noreferrer"
                             target="_blank"
                          >
                            View selected {typeCodeName} <FontAwesomeIcon icon={faExternalLinkAlt} />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div id="arrow">
                      <div className="bar" />
                      <div className="chevron-down" />
                    </div>
                    <div className="section to-section">
                      <Heading level={2}>To</Heading>
                      <div className="section-content">
                        {this.inputs.to.map((input) => (
                          <div key={input.label} className="form-group">
                            <label className="col-form-label">
                              {input.label}
                            </label>
                            <div className="view-attribute">
                              {this.props[input.propsKey].name ||
                                this.props[input.propsKey].fullName}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="copy-options-section col-6">
                    <h2>Copy Options</h2>
                    <div className="form-group">
                      <label htmlFor="nameInput" className="col-form-label">
                        Name
                      </label>
                      <input
                        id={`${UIUtils.convertToCamelCaseId(targetInput.type)}NameInput`}
                        className="form-control"
                        placeholder={`${typeCodeName} name`}
                        disabled={!fromTarget}
                        value={copyTargetName || ""}
                        onChange={this.handleTargetName}
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="previousUnitOperationDropDown"
                        className="col-form-label"
                      >
                        Order (Previous {typeCodeName})
                      </label>
                      <Typeahead
                        id={`previous${targetInput.type}Typeahead`}
                        inputProps={{
                          id: `previous${targetInput.type}TypeaheadInput`,
                          autoComplete: "off",
                        }}
                        options={toPreviousTargetOptions || []}
                        onChange={this.handleTargetOrderChange}
                        selectHintOnEnter
                        placeholder={`Select previous ${typeCodeName}`}
                        disabled={!fromTarget}
                        selected={copyTargetOrder ? [copyTargetOrder] : []}
                        labelKey="name"
                      />
                    </div>
                    <br />
                    <b>Risk links</b>
                    <div>
                      We will retain all the risk links within the {typeCodeName}. Risk links outside of the {typeCodeName} will
                      not be copied.
                    </div>
                    <br />
                    <b>Children records</b>
                    <div>
                      We will make copies of the children records and import it
                      along with the {typeCodeName}.
                    </div>
                    {allChildRecords && allChildRecords.length ? (
                      <Fragment>
                        <div>
                          Selected {typeCodeName} has the following records:
                        </div>
                        <ul id="childrenRecordsContent">
                          {allChildRecords.map((childRecords) => (
                            <li key={childRecords}>{childRecords}</li>
                          ))}
                        </ul>
                      </Fragment>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="modal-container">
                <div className="btn-group">
                  <button
                    id="addButton"
                    type="button"
                    disabled={isStartCopying || this.shouldDisableCopyButton()}
                    className="btn btn-primary"
                    onClick={this.handleCopyAndAdd}
                  >
                    Copy and Add
                  </button>
                  <button
                    id="cancelButton"
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={this.handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
