"use strict";

export const ACCEPTANCE_CRITERIA_COL_SPAN = "Acceptance Criteria";
export const ACCEPTANCE_CRITERIA_SUMMARY_COL_SPAN = "Acceptance Criteria Summary";
export const PROCESS_CAPABILITY_COL_SPAN = "Process Capability";
export const RISK_CONTROL_COL_SPAN = "Risk Control";
export const REFERENCE_AND_STANDARD_COL_SPAN = "References & Standards";
export const SUPPLIER_COL_SPAN = "Supplier";
export const TECH_TRANSFER_COL_SPAN = "Tech Transfer";
export const ABOUT_COL_SPAN = "About";
export const ACTION_COL_SPAN = "Action";
export const COMPONENT_QUALIFICATION_COL_SPAN = "Component Qualification";
export const UNIT_QUALIFICATION_COL_SPAN = "Unit Qualification";
export const COMPONENT_RISK_COL_SPAN = "Component Risk";
export const QUALIFICATION_COL_SPAN = "Qualification";
export const FORMULATION_QUANTITY_COL_SPAN = "Formulation Quantity";
export const REGULATORY_COL_SPAN = "Regulatory";
export const PROPERTIES_COL_SPAN = "Properties";
export const CRITICALITY_ASSESSMENT_COL_SPAN = "Criticality Assessment";
export const AUDIT_COL_SPAN = "Audit";
export const METHOD_DEVELOPMENT_COL_SPAN = "Method Development";
