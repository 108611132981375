/**
 * @hidden
 */
export var FONT_SIZE_CLASSES = {
    'xsmall': 'k-fs-xs',
    'small': 'k-fs-sm',
    'medium': 'k-fs-md',
    'large': 'k-fs-lg',
    'xlarge': 'k-fs-xl'
};
/**
 * @hidden
 */
export var FONT_WEIGHT_CLASSES = {
    'light': 'k-font-weight-light',
    'normal': 'k-font-weight-normal',
    'bold': 'k-font-weight-bold'
};
/**
 * @hidden
 */
export var MARGIN_ENUM_CLASSES = {
    'xsmall': 'k-m-xs',
    'small': 'k-m-sm',
    'medium': 'k-m-md',
    'large': 'k-m-lg',
    'xlarge': 'k-m-xl',
    'thin': 'k-m-thin',
    'hair': 'k-m-hair'
};
/**
 * @hidden
 */
export var MARGIN_ENUM_VALUES = {
    'xsmall': 'xs',
    'small': 'sm',
    'medium': 'md',
    'large': 'lg',
    'xlarge': 'xl',
    'thin': 'thin',
    'hair': 'hair'
};
/**
 * @hidden
 */
export var MARGIN_SIDES_CLASSES = {
    'top': 'k-mt-',
    'right': 'k-mr-',
    'bottom': 'k-mb-',
    'left': 'k-ml-'
};
/**
 * @hidden
 */
export var TEXT_ALIGN_CLASSES = {
    'left': 'k-text-left',
    'right': 'k-text-right',
    'center': 'k-text-center',
    'justify': 'k-text-justify'
};
/**
 * @hidden
 */
export var TEXT_TRANSFORM_CLASSES = {
    'lowercase': 'k-text-lowercase',
    'uppercase': 'k-text-uppercase',
    'capitalize': 'k-text-capitalize'
};
/**
 * @hidden
 */
export var THEME_COLOR_CLASSES = {
    'inherit': 'k-color-inherit',
    'primary': 'k-color-primary',
    'secondary': 'k-color-secondary',
    'tertiary': 'k-color-tertiary',
    'info': 'k-color-info',
    'success': 'k-color-success',
    'warning': 'k-color-warning',
    'error': 'k-color-error',
    'dark': 'k-color-dark',
    'light': 'k-color-light',
    'inverse': 'k-color-inverse'
};
