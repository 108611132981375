"use strict";

import * as UIUtils from "../../../../../ui_utils";
import { EMPTY_UNIT_OPERATION } from "./specification_transform_strategy";
import { orderAndIndexUnits } from "../../../../../processExplorer/indexers/uo_indexer";
import { VariableSpecificationTransformStrategy } from "./variable_specification_transform_strategy";

export class ProcessSpecificationTransformStrategy extends VariableSpecificationTransformStrategy {
  constructor() {
    super("processComponentId", "processcomponents");
  }

  fillGeneralInformation(record, requirement) {
    super.fillGeneralInformation(record, requirement);
  }

  fillParentInformation(record, requirement) {
    if (requirement.ProcessComponent) {
      record.parentId = Number(requirement.ProcessComponent.id);
      record.parent = UIUtils.secureString(requirement.ProcessComponent.name);
      record.parentType = "Process Component";
      record.parentShortType = "PRC";
    } else if (requirement.Material) {
      record.parentId = Number(requirement.Material.id);
      record.parent = UIUtils.secureString(requirement.Material.name);
      record.parentType = "Material";
      record.parentShortType = "MT";
    } else {
      record.parentId = "0";
    }
  }

  wrapUp(result) {
    const instances = result.instances;

    if (instances) {
      const attributes = [];
      const knownUOs = new Set();
      const variablesToProcess = this.mergeEntities(instances);
      const unitOperations = [];

      for (const attribute of variablesToProcess) {
        const context = {parent: attribute, knownUOs, instances, unitOperations};
        this.setUnitOperation(attribute, context);
        attributes.push(attribute);
      }

      attributes.sort(this.sortAttributes);

      instances.uos = orderAndIndexUnits(instances.uos, "id", false);
      instances.uos.push(EMPTY_UNIT_OPERATION);

      instances.uos = instances.uos.filter(uo => knownUOs.has(uo.id));
      instances.uos = instances.uos.map(uo => {
        uo.typeCode = uo.modelType;
        return {
          ...uo,
          label: uo.id === -1 ? uo.name : UIUtils.getRecordCustomLabelForDisplay(uo),
        };
      });

      instances.attributes = attributes;

      // ensures an empty summary will appear
      instances.isEmptySummary = (
        (!instances.attributes || instances.attributes.length === 0)
        || (!instances.uos || instances.uos.length === 0)
      );
    } else {
      result.instances = {
        isEmptySummary: true,
      };
    }

    return result;
  }

  sortAttributes(attribute1, attribute2) {
    let result = 0;
    if (attribute1.unitOperationId < attribute2.unitOperationId) {
      result = -1;
    } else if (attribute1.unitOperationId > attribute2.unitOperationId) {
      result = 1;
    } else if (attribute1.modelType < attribute2.modelType) {
      result = -1;
    } else if (attribute1.modelType > attribute2.modelType) {
      result = 1;
    } else if (attribute1.name < attribute2.name) {
      result = -1;
    } else if (attribute1.name > attribute2.name) {
      result = 1;
    }
    return result;
  }
}
