import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames } from '@progress/kendo-react-common';
/**
 * Returns the action bar of the Dialog.
 *
 * @returns React.ReactElement
 */
export var DialogActionsBar = function (props) {
    var _a;
    var _b = props.layout, layout = _b === void 0 ? defaultProps.layout : _b, children = props.children;
    var wrapperClasses = classNames('k-dialog-buttongroup', 'k-actions', (_a = {},
        _a["k-actions-".concat(layout)] = layout,
        _a));
    return (React.createElement("div", { className: wrapperClasses }, children));
};
DialogActionsBar.propTypes = {
    children: PropTypes.any,
    layout: PropTypes.oneOf(['start', 'center', 'end', 'stretched'])
};
var defaultProps = {
    layout: 'stretched'
};
