"use strict";

import React from "react";
import BaseEditableListTable from "../base_editable_list_table";
import CopyRecordPopup from "../widgets/cloning/copy_record_popup";
import * as I18NWrapper from "../i18n/i18n_wrapper";

/* This shows the table of RMPs. */

// i18next-extract-mark-ns-start rmps
class RMPListTable extends BaseEditableListTable {
  constructor(props) {
    super(props, "RMP");
  }

  // eslint-disable-next-line no-unused-vars
  generateColumns(props) {
    return [
      this.generateIDColumn("View"),
      this.generateNameColumn("View"),
      this.generateManageColumn()
    ];
  }

  renderCopyPopup() {
    return <div>
      {this.state.showCopyPopup ? (
        <CopyRecordPopup modalRef={copyRecordPopup => this.copyRecordPopup = copyRecordPopup}
                         onHideModal={this.handleHideCopyButtonClick}
                         onCopyCompletion={this.handleCopyCompletion}
                         parent={this}
                         showAsOfDate={this.props.showAsOfDate}
                         objectToCopy={this.state.objectToCopy}
        />
      ) : ""}
    </div>;
  }
}

export default I18NWrapper.wrap(RMPListTable, ["rmps", "base_page"]);
// i18next-extract-mark-ns-stop rmps
