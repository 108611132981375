"use strict";

import { can, generateTooltip } from "../utils/ui_permissions";
import CommonSecurity from "../../server/common/generic/common_security";
import { faList, faTable } from "@fortawesome/free-solid-svg-icons";

export const PRODUCT_VIEW_TABS = {
  LISTS: {
    title: "Lists",
    code: "LISTS",
    icon: faList,
  },
  TABLES: {
    title: "Tables",
    code: "TABLES",
    icon: faTable,
  }
};

export const PRODUCT_LIST_TABS = {
  TPP: {
    title: "Target Product Profile",
    code: "TPP"
  },
  QTPP: {
    title: "Quality Target Product Profile",
    code: "QTPP"
  }
};

export const MODEL_TYPES = [
  "Target Product Profile",
  "General Attributes",
  "Quality Attributes",
  "Performance Attributes",
];

export const ADD_QUALITY_ATTRIBUTES_TYPES = [
  {
    value: "General Attribute",
    key: "addGeneralAttribute",
    disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.GENERAL_ATTRIBUTE),
    title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.GENERAL_ATTRIBUTE),
  },
  {
    value: "Quality Attribute",
    key: "addQualityAttribute",
    disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.FQA),
    title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.FQA),
  },
  {
    value: "Performance Attribute",
    key: "addPerformanceAttribute",
    disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.FPA),
    title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.FPA),
  },
];

export const ADD_TPPs_TYPES = [
  {
    value: "Target Product Profile Element",
    key: "addTPP",
    disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.TPP_SECTION),
    title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.TPP_SECTION),
  },
];

export const PRODUCT_RELATED_TYPES = ["TPP", "FQA", "FPA", "GA"];
