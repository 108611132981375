"use strict";

import * as UIUtils from "../../ui_utils";
import { DEFAULT_TECH_TRANSFER_SORTING, EMPTY_STATE } from "../tech_transfer_constants";
import { getModelChangeCriteria } from "../tech_transfer_column_value_generator";
import TechTransferReviewScreenHandler from "./tech_transfer_review_screen_handlers";
import TechTransferCommonHandler from "./tech_transfer_common_handlers";
import { process } from "@progress/kendo-data-query";
import TechTransferExport from "../export/tech_transfer_export";
import ConfigurableTablesColumnSelectionHandler
  from "../../configurableTables/columnSelection/configurable_tables_column_selection_handler";
import BaseReactComponent from "../../base_react_component";
import { pushHistoryURLWithParameterChanges } from "../../configurableTables/tables/configurable_tables_helper";
import { TECH_TRANSFER_MODELS_CONFIG } from "../tech_transfer_config";

export default class TechTransferTopBarHandler extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.parent = props.parent;
    this.projectId = props.projectId;

    this.columnOperationsAdapter = props.columnOperationsAdapter;
    this.columnOperationsHandler = props.columnOperationsHandler;
    this.reviewScreenHandler = new TechTransferReviewScreenHandler(props);
    this.commonHandler = new TechTransferCommonHandler(props);
    this.columnSelectionHandler = new ConfigurableTablesColumnSelectionHandler(props, true,
      "defaultTechTransferValue");
  }

  handleViewChangeCriteria(isChangeCriteriaForReviewRecord) {
    this.parent.setStateSafely({
      viewChangeCriteria: true,
      isChangeCriteriaForReviewRecord,
    });
  }

  handleCloseChangeCriteriaPopup() {
    this.parent.setStateSafely({
      viewChangeCriteria: false,
    });
  }

  handleProcessChanged(processes, event) {
    UIUtils.ignoreHandler(event);

    this.parent.setStateSafely({...processes, unitOperationId: undefined}, () => {
      this.commonHandler.rememberProcessComparisons();

      const {
        fromProcessId,
        toProcessId,
        unitOperationId,
        selectedModelType,
      } = this.parent.state;

      if (!toProcessId) {
        UIUtils.showError(`You have chosen a process that doesn't have any receiving sites.`);
        this.parent.setStateSafely(EMPTY_STATE);
      } else {
        this.commonHandler.initializeAndLoadData(fromProcessId, toProcessId, unitOperationId, selectedModelType);
      }
    });
  }

  handleUnitOperationChange(unitOperationId) {
    UIUtils.setHideLoadingOnAjaxStop(false);
    UIUtils.showLoadingImage();

    this.parent.setStateSafely({
      unitOperationId,
    }, () => {
      const {selectedModelType} = this.parent.state;
      const techTransferData = this.commonHandler.getAdaptedTechTransferInfo(null, selectedModelType);
      const techTransferRows = techTransferData.techTransferRows;
      this.parent.setStateSafely({
        techTransferRows: techTransferRows || [],
      }, () => {
        this.columnOperationsHandler.handleClearAllFilters(true);
        this.columnSelectionHandler.handleResetColumnVisibility();
        this.reviewScreenHandler.handleHideReviewScreen();
        pushHistoryURLWithParameterChanges({unitOperationId});
      });
    });
  }

  handleModelTypeChange(selectedModelType) {
    UIUtils.setHideLoadingOnAjaxStop(false);
    UIUtils.showLoadingImage();

    const techTransferData = this.commonHandler.getAdaptedTechTransferInfo(null, selectedModelType);
    this.parent.setStateSafely({
      techTransferRows: techTransferData.techTransferRows,
      selectedModelType,
      tableState: null,
      visibleTableColumns: [],
    }, () => {
      this.columnOperationsHandler.handleClearAllFilters(true);
      this.columnSelectionHandler.handleResetColumnVisibility();
      this.reviewScreenHandler.handleHideReviewScreen();
      pushHistoryURLWithParameterChanges({selectedModelType});
    });
  }

  handleExport() {
    const {
      techTransferRows,
      selectedModelType,
      fromProcess,
      toProcess,
      visibleTableColumns,
      unitOperationId,
      orderedUOList,
      results,
    } = this.parent.state;

    const unitOperation = orderedUOList.find(uo => uo.id === unitOperationId);
    const records = process(techTransferRows
      .slice(0), this.columnOperationsAdapter.adaptFilterNames(this.parent.state));

    for (const record of records.data) {
      if (record.details) {
        const sortedDetails = process(record.details.slice(0), {sort: DEFAULT_TECH_TRANSFER_SORTING});
        record.details = sortedDetails.data;
      }
    }

    new TechTransferExport({
      records: records.data,
      selectedModelType,
      fromProcess,
      toProcess,
      visibleTableColumns,
      feature: "TechTransfer",
      modelsConfig: TECH_TRANSFER_MODELS_CONFIG,
      projectName: results.project.name,
      unitOperationName: unitOperation ? unitOperation.name : "All",
    }).exportReport();
  }

  handleUpdateChangeCriteria(changeCriteria) {
    const techTransferRows = this.parent.state.techTransferRows.map(row => {
      return {
        ...row,
        changeCriteria: getModelChangeCriteria(changeCriteria, row.pluralizedModelName),
      };
    });

    this.parent.setStateSafely({
      changeCriteria,
      techTransferRows,
    }, () => {
      this.columnSelectionHandler.handleResetColumnVisibility();
      this.reviewScreenHandler.handleHideReviewScreen();
    });
  }
}
