"use strict";

import * as UIUtils from "../../../../ui_utils";
import React from "react";
import ValidationIcon from "../../../../widgets/generic/validation_icon";
import ImplementationNeededError from "../../../../utils/implementation_needed_error";
import BaseReactComponent from "../../../../base_react_component";

/**
 *  This is the base class for other document bulk add table controls.
 */
export default class TableEditorBase extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    $("[data-toggle='popover']").popover({sanitizeFn: UIUtils.sanitizePopoverData});
  }

  renderInput() {
    throw new ImplementationNeededError("renderInput should be implemented in derived class of TableEditorBase.");
  }

  render() {
    let {id, tooltip} = this.props;

    return (
      <div className="form-group">
        {this.renderInput()}
        <ValidationIcon id={id}
                        trigger="click"
                        className="document-bulk-add-validation-icon
                        document-bulk-add-validation-icon-hidden"
                        tooltip={tooltip}
                        visible={true}
        />
      </div>
    );
  }
}
