"use strict";

export const BULK_PROPOSAL_STATUS = {
  EDITING: "Editing",
  EMAILS: "Sending emails",
  COMPLETED: "Completed",
};

export const BULK_APPROVAL_STATUS = {
  REVIEWING: "Reviewing",
  EMAILS: "Sending emails",
  COMPLETED: "Completed",
};

export const BULK_ADD_STATUS = {
  SAVING: "Saving",
  EMAILS: "Sending emails",
  COMPLETED: "Completed",
};

export const DO_NOTHING = () => {
};

export const MAX_RECORDS_LIMIT = 1500;
