"use strict";

import React from "react";
import BaseTable from "../../../../widgets/tables/base_table";
import * as UIUtils from "../../../../ui_utils";
import moment from "moment";
import { getURLByTypeCodeAndId } from "../../../../helpers/url_helper";

/**
 * This implements the batch panel showing batch information while reviewing continuous data in a process data import flow.
 */
export default class ContinuousDataMeasurementsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.attributeToAcceptanceCriteriaMap = new Map();
  }

  getTableInitializationOptions() {
    return {
      dom: "<ts>",
      data: this.props.records,
      bSort: false,
      columns: this.getColumns(),
      deferRender: true,
      scrollY: 291,
      scrollCollapse: true,
      scroller: true,
      createdRow: (row, data) => {
        /* This attaches a css class to each row that is out of specifications, so that it can be marked on the UI.
           We use a Map in this case to make the code a bit more efficient, since there could be thousands of
           measurements shown in the table.
         */
        const {attribute} = this.props;
        if (attribute && UIUtils.isNumber(data.value)) {
          const value = Number(data.value);
          let lowerLimit, upperLimit;
          if (this.attributeToAcceptanceCriteriaMap.has(attribute.attributeID)) {
            const attributeAcceptanceCriteria = this.attributeToAcceptanceCriteriaMap.get(attribute.attributeID);
            lowerLimit = attributeAcceptanceCriteria.lowerLimit;
            upperLimit = attributeAcceptanceCriteria.upperLimit;
          } else {
            lowerLimit = attribute.lowerLimit || attribute.lsl;
            lowerLimit = UIUtils.convertToNumber(lowerLimit);
            upperLimit = attribute.upperLimit || attribute.usl;
            upperLimit = UIUtils.convertToNumber(upperLimit);
            this.attributeToAcceptanceCriteriaMap.set(attribute.attributeID, {
              lowerLimit, upperLimit
            });
          }

          if (lowerLimit && value < lowerLimit || upperLimit && value > upperLimit) {
            $(row).addClass("warning");
          }
        }
      }
    };
  }

  getColumns() {
    return [
      this.generateColumn("Timestamp", "timestamp"),
      this.generateColumn("Label", "label"),
      this.generateColumn("Measurement", "value", "dt-body-right"),
    ];
  }

  reloadDataTable() {
    if (this.tableRef) {
      UIUtils.showLoadingImage();
      const table = $(this.tableRef).DataTable();
      table.clear();
      table.rows.add(this.prepareRecordsForDisplay(this.props.records));
      table.draw();
      UIUtils.hideLoadingImage();
    }
  }

  prepareRecordsForDisplay(records) {
    return records.map(rec => {
      return {
        timestamp: this.formatTimestampForDisplay(rec.timestamp),
        label: rec.label,
        value: rec.value,
      };
    });
  }

  formatTimestampForDisplay(timestamp) {
    return timestamp && moment(timestamp, UIUtils.DATE_TIME_FORMAT_FOR_STORAGE_WITHOUT_MILLISECONDS)
      .format(UIUtils.DATE_TIME_FORMAT_FOR_DISPLAY_WITHOUT_MILLISECONDS);
  }

  render() {
    const {attributeID, attributeName} = this.props.attribute || {};
    const attributeKey = attributeID && UIUtils.parseKey(attributeID);
    const {typeCode, id} = attributeKey || {};

    return (
      <div className="continuous-data-measurements-panel">
        <div className="continuous-data-measurements-header">
          {this.props.attribute ? (
            <a id="continuousDataMeasurementsHeader"
               href={getURLByTypeCodeAndId(typeCode, "View", id)}
               target="_blank"
               rel="noreferrer noopener"
            >
              {attributeID} {attributeName}
            </a>
          ) : ""}
        </div>
        <div className="continuous-data-measurements-table">
          <table ref={ref => this.tableRef = ref}
                 className="table table-hover"
                 id="continuousDataMeasurementsTable"
                 style={{width: "100%"}}
          />
        </div>
      </div>
    );
  }
}
