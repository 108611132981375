"use strict";

// This file handle rendering UI common parts used by add and results tables

import * as UIUtils from "../../../../ui_utils";
import ReactDOMServer from "react-dom/server";
import React, { Fragment } from "react";
import LabelTooltip from "../../../../widgets/tooltips/label_tooltip";
import ReactDOM from "react-dom";

/**
 * This method returns a table header with a tooltip.
 * @param title The column header title.
 * @param tooltip The column header tooltip.
 * @param required If the field itself is required so we put an astrix.
 * @returns {undefined|*}
 */
module.exports.getLabelTooltip = function(title, tooltip, required = false) {
  return (ReactDOMServer.renderToStaticMarkup(
    <Fragment>
      <LabelTooltip id={`${UIUtils.convertToCamelCaseId(title)}Tooltip`}
                    text={title}
                    className="links-details-field-label"
                    noColon={true}
                    placement="auto"
                    getTooltipCallback={() => {
                      return tooltip;
                    }} />
      {required ?
        <span className="document-bulk-add-required">*</span> : ""
      }
    </Fragment>));
};

/**
 * Render results text column
 * @param td The td container to render the control in
 * @param id The id of he control
 * @param value The value to put on the rendered span
 */
module.exports.renderResultsColumn = function(td, id, value) {
  return ReactDOM.render((<span id={id}>{value}</span>), td);
};
