import { IntlService } from './Intl/IntlService';
import { LocalizationService } from './Localization/LocalizationService';
import { GlobalizationContext } from './globalization/GlobalizationContext';
/* eslint-disable max-len */
/**
 * Provides an internationalization service. When the passed component is a direct or indirect child of `IntlProvider`, the returned service uses the locale of the provider. Otherwise, uses `en` as a default locale. To handle locale changes, call the method on each `render`.
 *
 * @param componentClass - The React component class that will use the internationalization service.
 */
export function provideIntlService(component) {
    if (!component && process.env.NODE_ENV !== 'production') {
        throw "Passed component - ".concat(component, " is invalid.");
    }
    var context = component.context;
    return context && context.intl
        ? context.intl
        : new IntlService('en');
}
/**
 * Provides a localization service. When the passed component is a direct or indirect child of `LocalizationProvider`, the returned service uses the language of the provider. To handle locale changes, call the method on each `render`.
 *
 * @param componentClass - The React component class that will use the internationalization service.
 */
export function provideLocalizationService(component) {
    if (!component && process.env.NODE_ENV !== 'production') {
        throw "Passed component - ".concat(component, " is invalid.");
    }
    var context = component.context;
    return context && context.localization
        ? context.localization
        : new LocalizationService();
}
/**
 * A method which registers a component class or a functional stateless component for internationalization. When a component of that type is a direct or indirect child of `IntlProvider`, the locale of the provider is used. Otherwise, uses `en` as a default locale.
 *
 * @param component - The React component class that will use the internationalization methods.
 */
export function registerForIntl(component) {
    component.contextType = GlobalizationContext;
}
/**
 * A method which registers a component class or a stateless functional component for localization. When a component of that type is a direct or indirect child of `LocalizationProvider`, the language of the provider is used.
 *
 * @param component - The React component class that will use the internationalization methods.
 */
export function registerForLocalization(component) {
    component.contextType = GlobalizationContext;
}
// tslint:enable:max-line-length
