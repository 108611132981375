"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseFullScreenPage from "../../base_full_screen_page";
import * as go from "gojs";
import TrialBar from "../../widgets/bars/trial_bar";
import CompanyHeader from "../../widgets/headers/company_header";
import CookiesBar from "../../widgets/bars/cookies_bar";
import TermsPopup from "../../terms/terms_popup";
import PropTypes from "prop-types";
import {
  getRiskBoundaries,
  loadRMP
} from "../../helpers/risk_helper";
import { RISK_TYPE_ENUM } from "../../helpers/constants/constants";
import { ProcessFlowMapDiagramFacade } from "./extensions/process_flow_map_diagram_facade";
import ProcessFlowMapReport from "./process_flow_map_report";
import { exportReportAsPng } from "./process_map_report_helper";
import ProcessMapToolbar from "./process_map_report_toolbar";
import ProcessMapFilter from "./process_map_filter";
import ProcessMapLegend from "./process_map_legend";
import ErrorBar from "../../widgets/bars/error_bar";
import * as ProcessCache from "../../processExplorer/process/process_cache";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";


const Logger = Log.group(LOG_GROUP.Reports, "ProcessMapReportPage");

/**
 * This class is responsible for rendering the Process Flow Map report
 */
export default class ProcessMapReportPage extends BaseFullScreenPage {
  constructor(props) {
    super(props);

    this.projectId = UIUtils.getParameterByName("projectId");
    this.reportType = UIUtils.getParameterByName("reportType");
    this.diagramInitialized = false;
    this.setStateSafely({
      title: "Process Flow Map",
      filters: {},
    });

    // For export later on
    new TypeaheadObjectCache("Process", this.projectId).loadOptions(this.handleProcessesReceivedFromServer.bind(this));
  }

  componentDidMount() {
    document.title = "QbDVision Process Flow Map";

    super.componentDidMount();
  }

  componentDidUpdate() {
    // So the tests know the diagram has been drawn
    UIUtils.incrementReactComponentDidUpdateCounter();
  }

  /**
   * Loads the report data
   */
  loadReportData() {
    loadRMP((rmp, project) => {
      const processId = this.state.processId;
      let processParam = processId ? "&processId=" + processId : "";
      UIUtils.secureAjaxGET("reports/" + this.reportType + "?projectId=" + this.projectId + processParam)
        .done(this.handleReceiveDataFromServer.bind(this, rmp, project));
    }, this.projectId);
  }

  handleProcessesReceivedFromServer(processes) {
    const projectId = this.projectId;

    const processId = (
      this.state.processId
      || ProcessCache.getProcessIdUsedRecently(projectId, processes)
    );

    let process = processes.find(process => process.id === processId);

    this.setStateSafely({
      processes,
      processId,
      process,
    }, this.loadReportData);
  }

  handleProcessChange(processId, event) {
    event.preventDefault();
    let process = (this.state.processes || []).find(process => process.id === processId);
    this.setStateSafely({processId, process}, this.loadReportData);
  }

  /**
   * Called when the user wants to export the process flow map.
   * @param exportSize {string} "full" for full size, or "screen" for just what's on the screen.
   */
  handleExportToImage(exportSize) {
    let exportInfo = {
      reportTitle: "Process Flow Map",
      projectId: this.state.project.id,
      projectName: this.state.project.name,
      processLabel: UIUtils.getRecordCustomLabelForDisplay(this.state.process),
      softwareVersion: UIUtils.getSoftwareVersion()
    };
    let reportImage = this.processMapDiagram.exportImage(exportSize === "full");
    exportReportAsPng(reportImage, exportInfo, `process_flow_map_${exportSize}.png`);
  }

  handleReceiveDataFromServer(cachedRMP, project, results) {
    // Uncomment for verbose logging
    // console.log("Received process flow map results: " + UIUtils.stringify(results));

    //Do some pre-processing and mark any records that are critical.
    const data = results.instances;
    this.markCriticalRecords(cachedRMP, data);

    data.finalAttributes = data.fqas.concat(data.fpas);
    delete data.fqas;
    delete data.fpas;

    data.intermediateAttributes = data.iqas.concat(data.ipas);

    let rmpVersions = cachedRMP.approvedVersionsWithDetails.map(rmpVersion => {
      rmpVersion.boundaries = getRiskBoundaries(rmpVersion);
      return rmpVersion;
    });

    //Create a new process flow map report and save it in state.
    this.setStateSafely({
      processFlowMapReport: new ProcessFlowMapReport(data, this.state.filters, rmpVersions, project),
      project: results.project,
      data,
      rmpVersions,
      projectWithAllVersions: project
    });
  }

  /**
   * Sets the diagram as uninitialized and makes the div free to receive a new diagram
   */
  resetDiagram() {
    this.diagramInitialized = false;
    this.processMapDiagram.diagram.div = null;
  }

  // noinspection JSMethodCanBeStatic
  /**
   * This marks any records retrieved from the database for which the criticality is set to "Critical"
   * @param rmp The effective RMP for the project as of today
   * @param data The data retrieved from the database
   */
  markCriticalRecords(rmp, data) {
    data = data || [];

    let allRecords = data.iqas
      .concat(data.ipas)
      .concat(data.pps)
      .concat(data.mas)
      .concat(data.fqas)
      .concat(data.fpas);

    for (let record of allRecords) {
      if (UIUtils.RISK_MODELS.find(riskModel => riskModel.model === record.modelType)) {
        if (!record.riskInfo) {
          throw new Error("Missing RiskInfo");
        }

        const scale = record.riskInfo[RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel;
        if (scale) {
          record.isCritical = record.riskInfo[RISK_TYPE_ENUM.CRITICALITY].isCritical;
          record.color = scale.color === "None" ? "#000000" : scale.color;
        } else {
          record.isCritical = false;
          record.color = "#000000";
        }
      }
    }
  }

  handleFilterUpdated(filtersAsString) {
    const filters = JSON.parse(filtersAsString);
    localStorage.setItem("PROCESS_MAP_FILTERS" + this.projectId, filtersAsString);
    this.setStateSafely({
      filters,
      processFlowMapReport: this.state.data ? new ProcessFlowMapReport(this.state.data, filters, this.state.rmpVersions, this.state.projectWithAllVersions) : null,
    });
  }

  renderPage() {
    return (
      <div className="process-map-container full-screen-container">
        <ProcessMapToolbar
          onExportToImage={this.handleExportToImage}
        />
        <div className="process-map-canvas">
          <div id="processMapContainerDiv" className="process-map-canvas-container" />
          <div id="processFlowMapTooltipDiv" className="process-map-tooltip" />
          <ProcessMapFilter onFiltersUpdated={this.handleFilterUpdated} projectId={this.projectId} />
          <ProcessMapLegend />
        </div>
      </div>
    );
  }

  render() {
    if (!this.diagramInitialized && this.state.processFlowMapReport) {
      try {
        const {nodeDataArray, linkDataArray, allOperations} = this.state.processFlowMapReport;
        this.processMapDiagram = new ProcessFlowMapDiagramFacade("processMapContainerDiv", new go.GraphLinksModel(nodeDataArray, linkDataArray), this.state.filters, allOperations);
        this.processMapDiagram.reLayoutDiagram();
        this.processMapDiagram.zoomToFitWidth();
        this.diagramInitialized = true;
      } catch (error) {
        // This error will get swallowed up in future renders if we don't print it out now.
        Logger.error(() => "Caught some weird error:", error);
        throw error;
      }

      /* This is required to make the diagram accessible by the tests. The tests use the browser.execute method
        to access the GoJS diagram object and the API exposed by it. This way they can get the on-screen status
        of the diagram and compare it with the expected output.
       */
      window.processFlowMapDiagram = this.processMapDiagram.diagram;

      /* This code calls onProcessFlowMapLoaded on QTPP Full Report after process map is loaded so it can export it
        as an image and use it within the report
       */
      let parent = window.parent;
      if (parent && parent.onProcessFlowMapLoaded) {
        let reportImage = {
          data: this.processMapDiagram.diagram.makeImageData({
            returnType: "string",
            type: "image/jpeg",
            size: new go.Size(1244, NaN)
          }),
          height: this.processMapDiagram.diagram.makeImageData({
            returnType: "ImageData",
            size: new go.Size(1244, NaN)
          }).height
        };
        parent.onProcessFlowMapLoaded(reportImage);
      }
    } else if (this.state.processFlowMapReport) {
      const {nodeDataArray, linkDataArray, allOperations} = this.state.processFlowMapReport;
      this.processMapDiagram.redrawSwimlanes(new go.GraphLinksModel(nodeDataArray, linkDataArray), this.state.filters, allOperations);
      this.processMapDiagram.reLayoutDiagram();
      this.processMapDiagram.zoomToFitWidth();
    }

    return (
      <div id="bodyDiv" className="process-map-report-page-body">
        <TrialBar minified={this.isMinified()} />
        <CompanyHeader minified={this.isMinified()}
                       breadcrumb={this.state.breadcrumb}
                       project={this.state.project}
                       extraPrintItems={this.extraPrintItems}
                       renderProcessSelector={true}
                       onProcessChange={this.handleProcessChange}
                       processId={this.state.processId}
                       title={this.state.title}
        />
        <ErrorBar className="risk-map-error-bar" />
        {this.renderPage()}
        <CookiesBar />
        <TermsPopup modalRef={termsPopup => this.termsPopup = termsPopup}
                    onApproveButtonCLick={this.handleTermsPopupApproveButtonClick}
        />
      </div>
    );
  }
}

ProcessMapReportPage.propTypes = {
  minified: PropTypes.bool.isRequired,
};

ProcessMapReportPage.defaultProps = {
  minified: true,
};
