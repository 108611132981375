"use strict";

import * as UIUtils from "../../../../ui_utils";
import React from "react";
import { PAPER_DATA_UPDATE_TYPE } from "../../../constants/import_constants";
import moment from "moment-timezone";
import ReportURLGenerator from "../../../../reports/report_url_builder";
import { REPORT_OPTIONS_ENUM, REPORT_TYPES_ENUM } from "../../../../reports/constants/report_constants";
import { isQualitativeMeasure } from "../../../../../server/common/editables/common_editables";
import { calculateZScore } from "../../../../../server/common/editables/common_batches";

/**
 * @typedef ETLDataOptions
 * @property {Object} batchData An object with the batch info attributes and their confidence levels as well as
 * a collection of attributes with mapped measurements and their confidence levels
 * @property {Object} dataAttributes The attributes the Material CofA contains
 * @property {Object} userUpdates An array of user actions while working updating the measurements mapped automatically
 * by the software
 */

/**
 * @typedef UpdateOptions A list of options provided to the ETLData object containing information about the entity
 * being updated.
 * @property type The type of update being performed on the batch data as defined in PAPER_DATA_UPDATE_TYPE
 * @property attributeUUID The uuid of the attribute being modified
 * @property measurementIndex The index of the measurement being modified
 * @property newValue The new value of the entity being modified
 * @property callback a callback function to be called after the update is done
 */

/**
 * @typedef UserUpdateOptions Options for tracking user activity on the batch data.
 * @property action The type of user update as defined in PAPER_DATA_UPDATE_TYPE
 * @property oldValue The old value of the entity being modified
 * @property newValue The new value of the entity being modified
 * @property uuid A unique id of the entity being modified.
 */

/**
 * This is responsible for managing all operations and verifying the extracted data from a PNG or PDF prior to importing
 * them as Batch data in the database.
 */
export default class ETLData {
  /**
   * Instantiates an ETLData object
   * @param {ETLDataOptions} etlDataOptions
   */
  constructor(etlDataOptions) {
    const {batchData, dataAttributes, userUpdates} = etlDataOptions || {};
    this._batchData = batchData ? UIUtils.deepClone(batchData) : {batchInfo: {}, attributes: []};
    this._dataAttributes = dataAttributes ? UIUtils.deepClone(dataAttributes) : [];
    this._userUpdates = userUpdates ? UIUtils.deepClone(userUpdates) : [];

    //Bind private methods
    this._verifyBatchId = this._verifyBatchId.bind(this);
    this._verifyBatchDates = this._verifyBatchDates.bind(this);
    this._verifyAttribute = this._verifyAttribute.bind(this);
    this._clearDuplicateAttributeErrors = this._clearDuplicateAttributeErrors.bind(this);
    this._verifyAttributesForDuplicate = this._verifyAttributesForDuplicate.bind(this);
    this._verifyAttributeMeasurements = this._verifyAttributeMeasurements.bind(this);
    this._verifyMeasurement = this._verifyMeasurement.bind(this);
    this._verifyMeasurementsSpecified = this._verifyMeasurementsSpecified.bind(this);
    this._verifyRequired = this._verifyRequired.bind(this);
    this._verifyNumericValue = this._verifyNumericValue.bind(this);
    this._verifyZScore = this._verifyZScore.bind(this);
    this._clearValidationStatus = this._clearValidationStatus.bind(this);
    this._setValidationStatus = this._setValidationStatus.bind(this);
    this._parseBatchDate = this._parseBatchDate.bind(this);
    this._updateDateProp = this._updateDateProp.bind(this);
    this._modifyAttribute = this._modifyAttribute.bind(this);
    this._addAttribute = this._addAttribute.bind(this);
    this._deleteAttribute = this._deleteAttribute.bind(this);
    this._deleteMeasurement = this._deleteMeasurement.bind(this);
    this._modifyMeasurement = this._modifyMeasurement.bind(this);
    this._addMeasurement = this._addMeasurement.bind(this);
    this._modifyBatchId = this._modifyBatchId.bind(this);
    this._registerUserUpdate = this._registerUserUpdate.bind(this);
    this._findAttributeByName = this._findAttributeByName.bind(this);
    this._getAttributeKey = this._getAttributeKey.bind(this);

    // Bind public methods
    this.resetData = this.resetData.bind(this);
    this.updateData = this.updateData.bind(this);
    this.resetErrorsAndVerifyData = this.resetErrorsAndVerifyData.bind(this);
    this.getNumOfWarnings = this.getNumOfWarnings.bind(this);
    this.hasErrors = this.hasErrors.bind(this);
    this.hasData = this.hasData.bind(this);
    this.setAttributeError = this.setAttributeError.bind(this);
    this.addBatchInfoError = this.addBatchInfoError.bind(this);
    this.getAggregateUsageStatistics = this.getAggregateUsageStatistics.bind(this);
  }

  get batchData() {
    return this._batchData;
  }

  get dataAttributes() {
    return this._dataAttributes;
  }

  get userUpdates() {
    return this._userUpdates;
  }

  /**
   * Verifies the batch id property of the object
   * @param onlyWarnings A flag indicating if only warnings should be reported
   * @private
   */
  _verifyBatchId(onlyWarnings = false) {
    const {batchId} = this._batchData.batchInfo;
    this._batchData.batchInfo.batchIdValidationStatus = this._verifyRequired(batchId, onlyWarnings);
  }

  /**
   * Parses a batch date and return a moment object
   * @param dateString The string representation of the date
   * @returns {*}
   * @private
   */
  _parseBatchDate(dateString) {
    return dateString ? moment(dateString, UIUtils.DATE_FORMAT_FOR_STORAGE) : null;
  }

  /**
   * Verifies the dates of the batch
   * @param onlyWarnings A flag indicating if only warnings should be reported
   * @private
   */
  _verifyBatchDates(onlyWarnings = false) {
    let batchDateProperties = ["startDate", "manufactureDate", "releaseDate", "expirationDate"];
    let batchDateValidationStatuses = ["startDateValidationStatus", "manufactureDateValidationStatus",
      "releaseDateValidationStatus", "expirationDateValidationStatus"];

    this._clearValidationStatus(batchDateValidationStatuses);

    for (let i = 0; i < batchDateProperties.length - 1; i++) {
      for (let j = i + 1; j < batchDateProperties.length; j++) {
        const firstDate = this._parseBatchDate(this._batchData.batchInfo[batchDateProperties[i]]);
        const secondDate = this._parseBatchDate(this._batchData.batchInfo[batchDateProperties[j]]);

        if (!onlyWarnings && firstDate && secondDate && firstDate.isAfter(secondDate)) {
          let formatDateField = UIUtils.convertCamelCaseToSpacedOutWords;
          this._setValidationStatus([batchDateValidationStatuses[i], batchDateValidationStatuses[j]],
            {
              error: `The ${formatDateField(batchDateProperties[i])} must be before the ${formatDateField(batchDateProperties[j])}.`,
              isError: true
            });
        }
      }
    }
  }

  /**
   * Triggers verification of an attribute in the batch and its measurements
   * @param attribute The attribute to verify
   * @param onlyWarnings A flag indicating if only warnings should be reported
   * @private
   */
  _verifyAttribute(attribute, onlyWarnings = false) {
    attribute.validationStatus = this._verifyRequired(attribute.fullName, onlyWarnings);

    if (!attribute.validationStatus || !attribute.validationStatus.error) {
      this._verifyAttributesForDuplicate(attribute.fullName, onlyWarnings);
    }

    attribute.validationStatus = attribute.validationStatus && attribute.validationStatus.error
      ? attribute.validationStatus
      : this._verifyMeasurementsSpecified(attribute.measurements, onlyWarnings);
  }

  /**
   * Verifies the measurements of an attribute
   * @param attribute The attribute
   * @param onlyWarnings A flag indicating if only warnings should be reported
   * @private
   */
  _verifyAttributeMeasurements(attribute, onlyWarnings = false) {
    for (let measurement of attribute.measurements) {
      this._verifyMeasurement(attribute, measurement, onlyWarnings);
    }
  }

  /**
   * Verifies a single measurement
   * @param attribute The attribute which the measurement belongs to
   * @param measurement The measurement to verify
   * @param onlyWarnings A flag indicating if only warnings should be reported
   * @private
   */
  _verifyMeasurement(attribute, measurement, onlyWarnings = false) {
    measurement.validationStatus = this._verifyRequired(measurement.measurement, onlyWarnings);
    if (!isQualitativeMeasure(attribute.type)) {
      let dataAttribute = this._findAttributeByName(attribute.fullName);
      let lowerLimit = dataAttribute ? dataAttribute.lowerLimit : null;
      let upperLimit = dataAttribute ? dataAttribute.upperLimit : null;
      let batches = dataAttribute ? dataAttribute.batches : null;
      let pastMeasurements = batches && batches.reduce((measurements, batch) => {
        measurements = measurements.concat(batch.measurements || []).map(measurement => Number(measurement));
        return measurements;
      }, []);

      measurement.validationStatus = measurement.validationStatus && measurement.validationStatus.error
        ? measurement.validationStatus
        : this._verifyNumericValue(measurement.measurement, lowerLimit, upperLimit, onlyWarnings);

      measurement.validationStatus = measurement.validationStatus && measurement.validationStatus.error
        ? measurement.validationStatus
        : this._verifyZScore(attribute.fullName, measurement.measurement, pastMeasurements);
    }
  }

  /**
   * Verifies a measurement value against the Z-Score of past measurements
   * @param attributeName The attribute name the measurement value belongs to
   * @param value The measurement value
   * @param pastMeasurements past measurement values for the attribute
   * @returns {{error: string}}
   * @private
   */
  _verifyZScore(attributeName, value, pastMeasurements) {
    let validationStatus = {
      error: ""
    };
    let valueDefined = value !== "" && value !== null && typeof value !== "undefined";

    /* We are only testing the measurement value against the Z-Score if there are at least 5
       measurements for this attribute. (See QI-3326)
     */
    if (valueDefined && pastMeasurements && pastMeasurements.length >= 5) {
      let zScore = calculateZScore(value, pastMeasurements);

      if (Math.abs(zScore) > 1) {
        let attributeKey = this._getAttributeKey(attributeName);
        let urlParams = attributeKey.typeCode === "MTLS" ?
          {
            reportType: REPORT_TYPES_ENUM.LibraryControlChartsContinuousReport,
            modelLabel: `${attributeKey.typeCode}-${attributeKey.id}`
          }
          :
          {
            reportType: REPORT_TYPES_ENUM.ControlChartsContinuousReport,
            projectId: this._batchData.projectId,
            modelLabel: `${attributeKey.typeCode}-${attributeKey.id}`
          };
        validationStatus.error = (
          <div>
            <div>This value is {Math.abs(zScore).toFixed(2)} standard deviations {zScore > 0 ? "above" : "below"} the mean of the prior data for this attribute.</div>
            <div className="batch-data-zscore-link">
              <a id="seePriorDataLink"
                 href={ReportURLGenerator.generateURL(REPORT_OPTIONS_ENUM.ControlChartsContinuousReport, urlParams)}
                 rel="noreferrer"
                 target="_blank"
              >
                See prior data.
              </a>
            </div>
          </div>
        );
        validationStatus.isError = false;
      }
    }

    return validationStatus;
  }

  /**
   * Verifies at least one measurement is set for the attribute
   * @param measurements An array holding attribute measurements
   * @param onlyWarnings A flag indicating if only warnings should be reported
   * @returns {{error: string}}
   * @private
   */
  // eslint-disable-next-line no-unused-vars
  _verifyMeasurementsSpecified(measurements, onlyWarnings = false) {
    let validationStatus = {
      error: ""
    };

    if (measurements.length === 0) {
      validationStatus.error = "Will be ignored because there are no measurements";
      validationStatus.isError = false;
    }

    return validationStatus;
  }

  /**
   * Verifies a value for a required property
   * @param value The value to verify
   * @param onlyWarnings A flag indicating if only warnings should be reported
   * @returns {{error: string}}
   * @private
   */
  _verifyRequired(value, onlyWarnings = false) {
    let validationStatus = {
      error: ""
    };

    if (!onlyWarnings && (value === "" || value === null || typeof value === "undefined")) {
      validationStatus.error = "This is required";
      validationStatus.isError = true;
    }

    return validationStatus;
  }

  /**
   * Clears any errors for an attribute which has no more a duplicate
   * @param attributeName The attribute name to check
   * @private
   */
  _clearDuplicateAttributeErrors(attributeName) {
    let duplicateAttributes = this._batchData.attributes.filter(attribute => attribute.fullName === attributeName);
    if (duplicateAttributes.length === 1) {
      this._verifyAttribute(duplicateAttributes[0]);
    }
  }

  /**
   * Verifies that an attribute is not specified twice in the batch editor
   * @param attributeName The attribute full name
   * @param onlyWarnings A flag indicating if only warnings should be reported
   * @private
   */
  _verifyAttributesForDuplicate(attributeName, onlyWarnings) {
    let duplicateAttributes = this._batchData.attributes.filter(attribute => attribute.fullName === attributeName);
    if (!onlyWarnings && duplicateAttributes.length > 1) {
      for (let duplicateAttribute of duplicateAttributes) {
        duplicateAttribute.validationStatus = {
          error: `${duplicateAttribute.fullName} has been specified more than once`,
          isError: true,
        };
      }
    }
  }

  /**
   * Verifies a quantitative measurement is within the allowed limits
   * @param value the value to verify
   * @param lowerLimit The lower limit
   * @param upperLimit The upper limit
   * @param onlyWarnings A flag indicating if only warnings should be reported
   * @returns {{error: string}}
   * @private
   */
  _verifyNumericValue(value, lowerLimit, upperLimit, onlyWarnings = false) {
    let validationStatus = {
      error: ""
    };

    let valueDefined = value !== "" && value !== null && typeof value !== "undefined";
    if (valueDefined && UIUtils.isNumber(lowerLimit) && Number(value) < lowerLimit) {
      validationStatus.error = `This value is below the LSL of ${lowerLimit}`;
      validationStatus.isError = false;
    }

    if (valueDefined && UIUtils.isNumber(upperLimit) && Number(value) > upperLimit) {
      validationStatus.error = `This value is above the USL of ${upperLimit}`;
      validationStatus.isError = false;
    }

    if (!onlyWarnings && valueDefined && !UIUtils.isNumber(value)) {
      validationStatus.error = "This must be a number";
      validationStatus.isError = true;
    }

    return validationStatus;
  }

  /**
   * Clears any errors and warnings logged for any of the provided properties of the batch
   * @param properties The properties to clear any error and warning logs
   * @private
   */
  _clearValidationStatus(properties) {
    for (let property of properties) {
      this._batchData.batchInfo[property] = {error: ""};
    }
  }

  /**
   * Sets the verification status (error/warning) for a list of batch properties
   * @param properties The batch properties
   * @param validationStatus The verification status object
   * @private
   */
  _setValidationStatus(properties, validationStatus) {
    for (let property of properties) {
      if (Object.prototype.hasOwnProperty.call(this._batchData.batchInfo, property)) {
        this._batchData.batchInfo[property] = validationStatus;
      }
    }
  }

  /**
   * Updates a batch date property
   * @param dateProp the date property to update
   * @param confidenceProp The confidence property corresponding to the date prop
   * @param newValue The new date to set
   * @param {PAPER_DATA_UPDATE_TYPE} type The type of update as defined in the enumeration
   * @private
   */
  _updateDateProp(dateProp, confidenceProp, newValue, type) {
    const userUpdateOptions = {
      action: type,
      oldValue: this._batchData.batchInfo[dateProp],
      newValue,
    };
    this._registerUserUpdate(userUpdateOptions);
    this._batchData.batchInfo[dateProp] = newValue;
    this._batchData.batchInfo[confidenceProp] = null;
    this._verifyBatchDates();
  }

  /**
   * Modifies an attribute within the batch data
   * @param {UpdateOptions} updateOptions
   * @private
   */
  _modifyAttribute(updateOptions) {
    const {
      attributeUUID,
      newValue,
    } = updateOptions;

    let modifiedRecord = this._batchData.attributes.find(attribute => attribute.uuid === attributeUUID);
    let attribute = this._findAttributeByName(newValue);
    let attributeKey = attribute && this._getAttributeKey(newValue);
    let nameKey = "name";
    let attributeFullName = attributeKey && UIUtils.getRecordLabelForDisplay(attributeKey.typeCode, attributeKey.id, attribute[nameKey]);
    if (modifiedRecord &&
      (!attribute || modifiedRecord.fullName !== attributeFullName)) {
      let attributeTypeChanged = attribute && modifiedRecord.type !== attribute.type;
      let oldAttribute = modifiedRecord.fullName;
      modifiedRecord.fullName = attribute ? attributeFullName : "";
      modifiedRecord.type = attribute ? attribute.measure : "";
      modifiedRecord.confidence = null;
      if (attributeTypeChanged) {
        modifiedRecord.measurements = [];
      }
      this._clearDuplicateAttributeErrors(oldAttribute);
      this._verifyAttribute(modifiedRecord);
      const userUpdateOptions = {
        action: PAPER_DATA_UPDATE_TYPE.MODIFY_ATTRIBUTE,
        oldValue: oldAttribute,
        newValue: modifiedRecord.fullName,
      };
      this._registerUserUpdate(userUpdateOptions);
    }
  }

  /**
   * Adds a new Attribute in the batch data
   * @param {UpdateOptions} updateOptions
   * @private
   */
  // eslint-disable-next-line no-unused-vars
  _addAttribute(updateOptions) {
    let newAttribute = {
      uuid: UIUtils.generateUUID(),
      key: "",
      type: "",
      measurements: []
    };
    this._batchData.attributes.push(newAttribute);
    this._verifyAttribute(newAttribute);
    const userUpdateOptions = {
      action: PAPER_DATA_UPDATE_TYPE.ADD_ATTRIBUTE,
    };
    this._registerUserUpdate(userUpdateOptions);
  }

  /**
   * Deletes an attribute in the batch data
   * @param {UpdateOptions} updateOptions
   * @private
   */
  _deleteAttribute(updateOptions) {
    const {
      attributeUUID,
    } = updateOptions;

    let attributeIndex = this._batchData.attributes.findIndex(attribute => attribute.uuid === attributeUUID);
    let attributeFullName = this._batchData.attributes[attributeIndex].fullName;
    const userUpdateOptions = {
      action: PAPER_DATA_UPDATE_TYPE.DELETE_ATTRIBUTE,
      oldValue: this._batchData.attributes[attributeIndex].key
    };
    this._registerUserUpdate(userUpdateOptions);
    this._batchData.attributes.splice(attributeIndex, 1);
    this._clearDuplicateAttributeErrors(attributeFullName);
  }

  /**
   * Deletes an attribute measurement in the batch data
   * @param {UpdateOptions} updateOptions
   * @private
   */
  _deleteMeasurement(updateOptions) {
    const {
      attributeUUID,
      measurementIndex,
    } = updateOptions;

    let modifiedRecord = this._batchData.attributes.find(attribute => attribute.uuid === attributeUUID);
    const userUpdateOptions = {
      uuid: modifiedRecord.measurements[measurementIndex].uuid,
      action: PAPER_DATA_UPDATE_TYPE.DELETE_MEASUREMENT,
      oldValue: modifiedRecord.measurements[measurementIndex].measurement,
    };
    this._registerUserUpdate(userUpdateOptions);
    modifiedRecord.measurements.splice(measurementIndex, 1);
    this._verifyAttribute(modifiedRecord);
  }

  /**
   * Modifies an attribute measurement in the batch data
   * @param {UpdateOptions} updateOptions
   * @private
   */
  _modifyMeasurement(updateOptions) {
    const {
      attributeUUID,
      measurementIndex,
      newValue,
    } = updateOptions;

    let modifiedRecord = this._batchData.attributes.find(attribute => attribute.uuid === attributeUUID);
    let modifiedMeasurement = modifiedRecord.measurements[measurementIndex];
    const userUpdateOptions = {
      uuid: modifiedMeasurement.uuid,
      action: PAPER_DATA_UPDATE_TYPE.MODIFY_MEASUREMENT,
      oldValue: modifiedMeasurement.measurement,
      newValue,
    };
    this._registerUserUpdate(userUpdateOptions);

    modifiedMeasurement.measurement = newValue;
    modifiedMeasurement.confidence = null;
    this._verifyMeasurement(modifiedRecord, modifiedMeasurement);
  }

  /**
   * Adds a new measurement in an attribute within the batch
   * @param {UpdateOptions} updateOptions
   * @private
   */
  _addMeasurement(updateOptions) {
    const {
      attributeUUID,
      newValue,
    } = updateOptions;

    let modifiedRecord = this._batchData.attributes.find(attribute => attribute.uuid === attributeUUID);
    const newMeasurement = {
      uuid: UIUtils.generateUUID(),
      measurement: newValue,
    };
    modifiedRecord.measurements.push(newMeasurement);
    this._verifyAttribute(modifiedRecord);
    this._verifyMeasurement(modifiedRecord, newMeasurement);
    const userUpdateOptions = {
      uuid: newMeasurement.uuid,
      action: PAPER_DATA_UPDATE_TYPE.ADD_MEASUREMENT,
      newValue,
    };
    this._registerUserUpdate(userUpdateOptions);
  }

  /**
   * Modifies the batch id in the batch
   * @param {UpdateOptions} updateOptions
   * @private
   */
  _modifyBatchId(updateOptions) {
    const {
      newValue,
    } = updateOptions;

    const userUpdateOptions = {
      action: PAPER_DATA_UPDATE_TYPE.MODIFY_BATCH_ID,
      oldValue: this._batchData.batchInfo.batchId,
      newValue,
    };
    this._registerUserUpdate(userUpdateOptions);
    this._batchData.batchInfo.batchId = newValue;
    this._batchData.batchInfo.confidence = null;
    this._verifyBatchId();
  }

  /**
   * This registers a user action modifying any of the batch fields, attributes or measurements
   * @param {UserUpdateOptions} userUpdateOptions
   * @private
   */
  _registerUserUpdate(userUpdateOptions) {
    this._userUpdates.push({
      uuid: userUpdateOptions.uuid,
      action: userUpdateOptions.action,
      oldValue: userUpdateOptions.oldValue,
      newValue: userUpdateOptions.newValue,
    });
  }

  /**
   *  Gets the key part (typeCode-id) from a fully typed attribute including the typeCode, id and name
   * @param attributeFullName
   * @returns {*}
   * @private
   */
  _getAttributeKey(attributeFullName) {
    return attributeFullName
      ? UIUtils.parseKey(attributeFullName.split(" - ")[0])
      : null;
  }

  /**
   * Finds an attribute by it's full name
   * @param attributeFullName
   * @returns {*}
   * @private
   */
  _findAttributeByName(attributeFullName) {
    let key = this._getAttributeKey(attributeFullName);
    return key && key.id && this._dataAttributes.find(dataAttribute => dataAttribute.id === key.id);
  }

  /**
   * Resets/clears all data in this object.
   */
  resetData() {
    this._batchData = {batchInfo: {}, attributes: []};
  }

  /**
   * @typedef UpdateMetaData
   * @property type The type of update as defined in PAPER_DATA_UPDATE_TYPE enum
   * @property attributeUUID The uuid of the attribute being modified
   * @property measurementIndex The index of the measurement being modified
   *
   */

  /**
   * Updates the batch data
   * @param {UpdateMetaData} updateMetaData
   * @param newValue The new value to set
   * @param callback A callback function to call once the update is done
   */
  updateData(updateMetaData, newValue, callback) {
    const {type, attributeUUID, measurementIndex} = updateMetaData;
    const updateOptions = {
      type,
      attributeUUID,
      measurementIndex,
      newValue,
      callback
    };

    switch (type) {
      case PAPER_DATA_UPDATE_TYPE.MODIFY_ATTRIBUTE:
        this._modifyAttribute(updateOptions);
        break;
      case PAPER_DATA_UPDATE_TYPE.ADD_ATTRIBUTE:
        this._addAttribute(updateOptions);
        break;
      case PAPER_DATA_UPDATE_TYPE.DELETE_ATTRIBUTE:
        this._deleteAttribute(updateOptions);
        break;
      case PAPER_DATA_UPDATE_TYPE.DELETE_MEASUREMENT:
        this._deleteMeasurement(updateOptions);
        break;
      case PAPER_DATA_UPDATE_TYPE.MODIFY_MEASUREMENT:
        this._modifyMeasurement(updateOptions);
        break;
      case PAPER_DATA_UPDATE_TYPE.ADD_MEASUREMENT:
        this._addMeasurement(updateOptions);
        break;
      case PAPER_DATA_UPDATE_TYPE.MODIFY_BATCH_ID:
        this._modifyBatchId(updateOptions);
        break;
      case PAPER_DATA_UPDATE_TYPE.MODIFY_BATCH_START_DATE:
        this._updateDateProp("startDate", "startDateConfidence", newValue, type);
        break;
      case PAPER_DATA_UPDATE_TYPE.MODIFY_BATCH_MANUFACTURE_DATE:
        this._updateDateProp("manufactureDate", "manufactureDateConfidence", newValue, type);
        break;
      case PAPER_DATA_UPDATE_TYPE.MODIFY_BATCH_RELEASE_DATE:
        this._updateDateProp("releaseDate", "releaseDateConfidence", newValue, type);
        break;
      case PAPER_DATA_UPDATE_TYPE.MODIFY_BATCH_EXPIRATION_DATE:
        this._updateDateProp("expirationDate", "expirationDateConfidence", newValue, type);
        break;
      case PAPER_DATA_UPDATE_TYPE.MODIFY_SCALE:
        this._batchData.batchInfo.scale = newValue;
        break;
      case PAPER_DATA_UPDATE_TYPE.MODIFY_SUPPLIER:
        this._batchData.batchInfo.supplier = newValue ? newValue.label : null;
        this._batchData.batchInfo.supplierId = newValue ? newValue.id : null;
        break;
    }

    if (callback) {
      callback();
    }
  }

  /**
   * Resets all batch errors and verifies all batch data again.
   * @param onlyWarnings
   */
  resetErrorsAndVerifyData(onlyWarnings = false) {
    this._verifyBatchId(onlyWarnings);
    this._verifyBatchDates(onlyWarnings);

    for (let attribute of this._batchData.attributes) {
      this._verifyAttribute(attribute, onlyWarnings);
      this._verifyAttributeMeasurements(attribute, onlyWarnings);
    }
  }

  /**
   * Return the number of warnings across all the batch data, attributes and measurements
   * @returns {number}
   */
  getNumOfWarnings() {
    let numOfWarnings = 0;

    for (let attribute of this._batchData.attributes) {
      if (attribute.validationStatus && attribute.validationStatus.isError === false) {
        numOfWarnings++;
      }
      for (let measurement of attribute.measurements) {
        if (measurement.validationStatus && measurement.validationStatus.isError === false) {
          numOfWarnings++;
        }
      }
    }

    return numOfWarnings;
  }

  /**
   * Returns true id the batch has at least one error
   * @returns {*}
   */
  hasErrors() {
    let hasErrors = false;
    const {
      batchIdValidationStatus,
      manufactureDateValidationStatus,
      releaseDateValidationStatus,
      expirationDateValidationStatus,
    } = this._batchData.batchInfo;

    hasErrors = hasErrors
      || batchIdValidationStatus && batchIdValidationStatus.isError
      || manufactureDateValidationStatus && manufactureDateValidationStatus.isError
      || releaseDateValidationStatus && releaseDateValidationStatus.isError
      || expirationDateValidationStatus && expirationDateValidationStatus.isError;

    for (let attribute of this._batchData.attributes) {
      hasErrors = hasErrors || (attribute.validationStatus && attribute.validationStatus.isError);
      if (hasErrors) {
        break;
      }
      for (let measurement of attribute.measurements) {
        hasErrors = hasErrors || (measurement.validationStatus && measurement.validationStatus.isError);
        if (hasErrors) {
          break;
        }
      }
    }

    return hasErrors;
  }

  /**
   * Returns true if the batch has at least one attribute with one measurement.
   * @returns {boolean}
   */
  hasData() {
    return !!(this._batchData.attributes
      && this._batchData.attributes.length > 0
      && this._batchData.attributes.find(attribute => attribute.measurements && attribute.measurements.length > 0));
  }

  /**
   * Registers an error on one of the batch attributes.
   * @param attributeFullName The attribute full name
   * @param validationError The error
   */
  setAttributeError(attributeFullName, validationError) {
    let attributes = this._batchData.attributes.filter(attribute => attribute.fullName === attributeFullName);
    for (let attribute of attributes) {
      if (validationError && validationError.errors.length > 0) {
        attribute.validationStatus = {
          error: validationError.errors,
          isError: true
        };
      } else if (validationError && validationError.warnings.length > 0) {
        attribute.validationStatus = {
          error: validationError.warnings,
          isError: false
        };
      }
    }
  }

  /**
   * Registers an error on the batch level.
   * @param validationError The error to register
   * @param validationProperty The batch property for which the error is registered
   */
  addBatchInfoError(validationError, validationProperty) {
    if (validationError && validationError.errors.length > 0) {
      this._batchData.batchInfo[`${validationProperty}ValidationStatus`] = {
        error: validationError.errors,
        isError: true
      };
    } else if (validationError && validationError.warnings.length > 0) {
      this._batchData.batchInfo[`${validationProperty}ValidationStatus`] = {
        error: validationError.warnings,
        isError: true
      };
    }
  }

  /**
   * Gets aggregated user statistics regarding the user activity in the batch. This allowes us later on to calculate some
   * metrics regarding how many of the measurements/attributes and rest of the batch data is modified by the user or
   * accepted as generated by the smart import.
   * @returns {{atLeastOneMeasurementModified: boolean, totalMeasurements: *, userUpdates: ([]|*), rawData: {attributes: [], batchInfo: {}}, successfullyMappedMeasurements: *}}
   */
  getAggregateUsageStatistics(originalBatchData) {
    const batchIdModified = originalBatchData.batchInfo.batchId !== this.batchData.batchInfo.batchId;
    const startDateModified = originalBatchData.batchInfo.startDate !== this.batchData.batchInfo.startDate;
    const manufactureDateModified = originalBatchData.batchInfo.manufactureDate !== this.batchData.batchInfo.manufactureDate;
    const releaseDateModified = originalBatchData.batchInfo.releaseDate !== this.batchData.batchInfo.releaseDate;
    const expirationDateModified = originalBatchData.batchInfo.expirationDate !== this.batchData.batchInfo.expirationDate;

    const allMeasurements = this.batchData.attributes.reduce((allMeasurements, attribute) => {
      allMeasurements = allMeasurements.concat(attribute.measurements);
      return allMeasurements;
    }, []);
    const originalMeasurements = originalBatchData.attributes.reduce((allMeasurements, attribute) => {
      allMeasurements = allMeasurements.concat(attribute.measurements);
      return allMeasurements;
    }, []);

    let totalMeasurements = originalMeasurements.length;
    let totalModifiedMeasurements = originalMeasurements.filter(originalMeasurement => {
      let newMeasurement = allMeasurements.find(newMeasurement => newMeasurement.uuid === originalMeasurement.uuid);
      return !newMeasurement || originalMeasurement.measurement !== newMeasurement.measurement;
    }).length;

    return {
      totalModifiedMeasurements,
      totalMeasurements,
      atLeastOneMeasurementModified: totalModifiedMeasurements !== 0,
      batchIdModified,
      startDateModified,
      manufactureDateModified,
      releaseDateModified,
      expirationDateModified,
    };
  }
}
