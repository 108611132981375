"use strict";
/*
 * This file provides an error you should throw when adapting the required changes is too hard.
 *
 * Note: Server side in Node you should not follow this, but create something like HttpHelper.NoStackError (errors work different in NodeJS, unfortunately).
 */

export default class CannotAdaptError extends Error {
  constructor(message = "These changes are too difficult to adapt client side. The page should be reloaded.") {
    super(message);
    this.name = "CannotAdaptError";
  }
}
