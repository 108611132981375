"use strict";

const UIUtils = require("../../ui_utils");
const ImplementationNeededError = require("../../utils/implementation_needed_error");
const {MaterialLibraryService} = require("../../services/library/material_library_service");
const {PROJECT_MATERIALS_CONSTANTS, FROM_LIBRARY_STATUS} = require("../library_constants");
const {TYPE_CODE} = require("../../../server/common/generic/common_constants");

const SELECT_ALL_INDEX = -1;

const MATERIAL_ACTIONS = {
  SWAP: "Swap",
  SYNC: "Sync",
  UNLINK: "Unlink",
  UNSYNC: "Unsync",
  CREATE: "Create"
};

const MATERIAL_ATTRIBUTE_ACTIONS = {
  LINK: "linkSpecification",
  UNLINK: "unlinkSpecification",
};

class LibraryHelper {

  static getLibrarySpecificationDifferences(record, libraryMaterialSpecifications) {
    let specification = record?.fromLibrary?.specification;
    let acceptanceCriteriaRecord = {};
    if (specification) {
      acceptanceCriteriaRecord = record?.RequirementVersion?.AcceptanceCriteriaRangeLinkedVersions?.find(version => version.isDefault);
      if (!LibraryHelper.recordHasLibrarySpecificationAttached(record, libraryMaterialSpecifications) || !acceptanceCriteriaRecord) {
        return [];
      }

      acceptanceCriteriaRecord.measure = record.measure;
    } else {
      specification = libraryMaterialSpecifications?.find(specification => specification.id === record.linkedToSpecificationVersionId);
      acceptanceCriteriaRecord = record?.Requirement?.AcceptanceCriteriaRanges?.find(range => range.isDefault);
      if (!specification || !acceptanceCriteriaRecord) {
        return [];
      }

      acceptanceCriteriaRecord.measure = record.measure;
    }

    return LibraryHelper.getLibrarySpecificationRecordDifferences(acceptanceCriteriaRecord, specification);
  }

  static getLibrarySpecificationRecordDifferences(record, specification) {
    const differences = [];

    switch (record["measure"]) {
      case "Lower Limit (NLT)":
        if (parseFloat(record["lowerLimit"]) < parseFloat(specification["lowerLimit"])) {
          differences.push("lowerLimit");
        }

        if (parseFloat(record["target"]) < parseFloat(specification["target"])) {
          differences.push("target");
        }
        break;
      case "Defects (Pass/Fail)":
      case "Upper Limit (NMT)":
        if (parseFloat(record["upperLimit"]) > parseFloat(specification["upperLimit"])) {
          differences.push("upperLimit");
        }

        if (parseFloat(record["target"]) > parseFloat(specification["target"])) {
          differences.push("target");
        }
        break;
      case "Range":
        if (parseFloat(record["lowerLimit"]) < parseFloat(specification["lowerLimit"])) {
          differences.push("lowerLimit");
        }

        if (parseFloat(record["upperLimit"]) > parseFloat(specification["upperLimit"])) {
          differences.push("upperLimit");
        }

        if (record["target"] !== specification["target"]) {
          differences.push("target");
        }
        break;
      case "Conforms (Pass/Fail)":
        // QI-5555
        // No rules for flagging.
        // Justification: Only rule possible (MA Target does not match Spec Target) is too restrictive.
        // E.g. With this rule, “Colorless” and “Clear” will be flagged even though they are the same.
        // Also, “Passes Visual Inspection” will be flagged as different than “Appearance”, “Particulates”, “Color”, etc.
        break;
    }

    return differences;
  }

  static recordFieldValueExists(record, field) {
    if (!record) {
      return false;
    }

    return record[field] !== undefined && record[field] !== null;
  }

  static getRecordFieldValue(record, field) {
    if (!LibraryHelper.recordFieldValueExists(record, field)) {
      return "";
    }

    return record[field].toString();
  }

  static recordHasLibrarySpecificationAttached(record, libraryMaterialSpecifications = []) {
    if (!record.linkedToSpecificationVersionId || !libraryMaterialSpecifications) {
      return false;
    }

    const specification = libraryMaterialSpecifications.find(specification => specification.id === record.linkedToSpecificationVersionId);
    return !!specification;
  }

  static recordHasLibraryAttached(record, olderVersion, isDiffingVersions) {
    if (!isDiffingVersions && !record.fromLibrary?.instanceId) {
      return false;
    }

    return !(isDiffingVersions && !record.fromLibrary?.instanceId && !olderVersion?.fromLibrary?.instanceId);
  }

  static onUnlinkMaterialAttributeClick(materialAttribute) {
    const {
      id,
      ProjectId,
      ProcessId
    } = materialAttribute;
    const unlinkUrl = UIUtils.FRONT_END_URL + `/library/projectMaterialAttributes/unlink.html?materialAttributeId=${id}&materialId=${materialAttribute.MaterialId}&projectId=${ProjectId}&processId=${ProcessId}`;
    window.location.href = UIUtils.getSecuredURL(unlinkUrl);
  }

  static onLinkMaterialAttributeClick(materialAttribute) {
    window.location.href = LibraryHelper.getLinkMaterialAttributeUrl(materialAttribute);
  }

  static getLinkMaterialAttributeUrl(materialAttribute) {
    const {
      id,
      ProjectId,
      ProcessId
    } = materialAttribute;
    return UIUtils.getSecuredURL(UIUtils.FRONT_END_URL + `/library/projectMaterialAttributes/link.html?materialAttributeId=${id}&materialId=${materialAttribute.MaterialId}&projectId=${ProjectId}&processId=${ProcessId}`);
  }

  static onUnlinkClick(material) {
    const {
      id,
      ProjectId,
      ProcessId
    } = material;
    const unlinkUrl = UIUtils.FRONT_END_URL + `/library/projectMaterials/unlink.html?materialId=${id}&projectId=${ProjectId}&processId=${ProcessId}`;
    window.location.href = UIUtils.getSecuredURL(unlinkUrl);
  }

  static onSwapWithLibraryClick(material) {
    const {
      id,
      ProjectId,
      ProcessId
    } = material;
    const swapWithLibrary = UIUtils.FRONT_END_URL + `/library/projectMaterials/swap.html?materialId=${id}&projectId=${ProjectId}&processId=${ProcessId}`;
    window.location.href = UIUtils.getSecuredURL(swapWithLibrary);
  }

  static onLookupInLibrary() {
    window.open(UIUtils.getSecuredURL(`${UIUtils.FRONT_END_URL}/library/list.html`), "_blank");
  }

  onCreateFromLibraryClick(typeCode, projectId, processId, selectedNodes) {
    const {unitOperationId, stepId} = selectedNodes && selectedNodes[0];
    let createFromLibrary = UIUtils.FRONT_END_URL;
    switch (typeCode) {
      case TYPE_CODE.MATERIAL:
        createFromLibrary += `/library/projectMaterials/create.html?projectId=${projectId}&processId=${processId}&start=Project`;
        createFromLibrary += `${unitOperationId ? `&unitOperationId=${unitOperationId}` : ""}`;
        createFromLibrary += `${stepId ? `&stepId=${stepId}` : ""}`;
        window.location.href = UIUtils.getSecuredURL(createFromLibrary);
        break;
      default:
        throw new ImplementationNeededError();
    }
  }

  static isSyncButtonEnabled(specifications) {
    return specifications.reduce((isEnabled, spec) => {
      return isEnabled && !!spec.materialAttribute;
    }, true);
  }

  static onSyncWithLibraryClick(materialId, projectId, processId) {
    let syncWithLibrary = UIUtils.FRONT_END_URL + `/library/projectMaterials/sync.html?materialId=${materialId}&projectId=${projectId}&processId=${processId}`;
    window.location.href = UIUtils.getSecuredURL(syncWithLibrary);
  }

  static onUnsyncWithLibraryClick(materialId, projectId, processId) {
    let syncWithLibrary = UIUtils.FRONT_END_URL + `/library/projectMaterials/unsync.html?materialId=${materialId}&projectId=${projectId}&processId=${processId}`;
    window.location.href = UIUtils.getSecuredURL(syncWithLibrary);
  }

  static redirectToProcessExplorer() {
    const projectId = UIUtils.getParameterByName("projectId");
    const processId = UIUtils.getParameterByName("processId");
    let processExplorer = UIUtils.FRONT_END_URL;
    processExplorer += `/processExplorer/processExplorer.html?projectId=${projectId}&processId=${processId}`;
    window.location.href = UIUtils.getSecuredURL(processExplorer);
  }

  static redirectToMaterialAttribute(result) {
    let materialAttributeId;
    if (Array.isArray(result)) {
      const materialAttribute = result.find(record => record.typeCode === TYPE_CODE.MATERIAL_ATTRIBUTE);
      materialAttributeId = materialAttribute.id;
    } else {
      materialAttributeId = result.id;
    }
    window.location.href = UIUtils.getSecuredURL(UIUtils.FRONT_END_URL + `/processExplorer/materialAttributes/viewEdit.html?operation=View&id=${materialAttributeId}`);
  }

  static redirectToMaterial(result) {
    let materialId;
    if (Array.isArray(result)) {
      const material = result.find(record => record.typeCode === TYPE_CODE.MATERIAL);
      materialId = material.id;
    } else {
      materialId = result.id;
    }
    window.location.href = UIUtils.getSecuredURL(UIUtils.FRONT_END_URL + `/processExplorer/materials/viewEdit.html?operation=View&id=${materialId}`);
  }

  static onCreateProjectMaterialClick(preprocessRequestCallback, onCreateCompleted) {
    UIUtils.setLoadingDisabled(false);

    const instance = preprocessRequestCallback();
    UIUtils.setHideLoadingOnAjaxStop(false);

    let parameters = {
      global: true,
      useTwoWayCommunication: true,
    };

    const materialLibraryService = new MaterialLibraryService();

    return materialLibraryService.create(instance, parameters)
      .then(onCreateCompleted)
      .catch((result) => {
        UIUtils.defaultFailFunction(result, null, null, PROJECT_MATERIALS_CONSTANTS.ALERT_DIV);
      })
      .finally(() => {
        UIUtils.setLoadingDisabled(true);
      });
  }

  static onActionClick(preprocessRequestCallback, requestCompleteCallback, action) {
    UIUtils.setLoadingDisabled(false);

    const instance = preprocessRequestCallback();
    UIUtils.setHideLoadingOnAjaxStop(false);

    let parameters = {
      global: true,
      useTwoWayCommunication: true,
    };

    const service = new MaterialLibraryService();
    const operation = UIUtils.convertToCamelCaseId(action);

    return service[operation](instance, parameters)
      .then(requestCompleteCallback)
      .catch((result) => {
        UIUtils.defaultFailFunction(result, null, null, PROJECT_MATERIALS_CONSTANTS.ALERT_DIV);
      })
      .finally(() => {
        UIUtils.setLoadingDisabled(true);
      });
  }

  /**
   * Update selection state of material specifications.
   * @param instance Library Material object
   * @param position The position of the spec in the Array
   * @param toggle The flag to toggle all specs
   * @returns {*&{Specifications: unknown[]}}
   */
  static onSelectSpecificationClick(instance, position, toggle) {
    return {
      ...instance,
      Specifications: instance.Specifications.map((spec, index) => {
        if (position === SELECT_ALL_INDEX) {
          return {
            ...spec,
            checked: toggle,
          };
        } else if (index === position) {
          return {
            ...spec,
            checked: !spec.checked,
          };
        } else {
          return spec;
        }
      })
    };
  }

  static preprocessRequestCallback(state) {
    const request = UIUtils.deepClone(state);
    request.fromLibraryModel = "LibraryMaterial";
    request.fromLibraryVersionId = request.instance.LastApprovedVersionId;
    request.instance.specifications = request.instance.Specifications.filter(spec => spec.checked);
    delete request.instance.Specifications;
    return request;
  }

  static preprocessSyncRequestCallback(state) {

    const {
      material,
      keepSynced,
      specifications,
      libraryMaterial
    } = state;

    let {
      changedAttributes
    } = state;

    if (!changedAttributes) {
      changedAttributes = [];
    }

    if (!keepSynced) {
      changedAttributes.length = 0;
    }

    const fieldChanges = changedAttributes.reduce((fieldChanges, changedAttribute) => {
      material[changedAttribute.attribute] = changedAttribute.newValue;
      return fieldChanges;
    }, {});

    const {
      id,
      LastApprovedVersionId,
    } = libraryMaterial;

    const materialChanges = {
      ...material,
      model: "Material",
      fromLibraryModel: "LibraryMaterial",
      LibraryMaterialId: id,
      fromLibraryVersionId: LastApprovedVersionId,
      ...fieldChanges,
      fromLibraryStatus: (keepSynced === true) ? FROM_LIBRARY_STATUS.SYNCED : FROM_LIBRARY_STATUS.LINKED,
    };

    return {
      specifications,
      material: materialChanges,
    };
  }
}

module.exports = {
  LibraryHelper,
  MATERIAL_ACTIONS,
  SELECT_ALL_INDEX,
  MATERIAL_ATTRIBUTE_ACTIONS,
};