var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { createFilterComponent } from './FilterComponent';
import { DropDownList } from '@progress/kendo-react-dropdowns';
var booleanOperator = 'eq';
var settings = {
    emptyFilter: { operator: booleanOperator, value: '' },
    inputComponent: function (_props) { return null; },
    operatorComponent: function (props, cellProps) {
        var _a = cellProps.filter, filter = _a === void 0 ? [] : _a, field = cellProps.field;
        var current = filter.find(function (f) { return f.field === field; });
        return (React.createElement(DropDownList, __assign({}, props, { value: props.data.find(function (x) { return x.operator === (current ? current.value : ''); }), onChange: function (e) {
                if (!cellProps.onFilterChange) {
                    return;
                }
                var value = e.target.value.operator;
                if (value === '') {
                    if (current) {
                        cellProps.onFilterChange.call(undefined, {
                            filter: filter.filter(function (f) { return f.field !== current.field; }),
                            field: field,
                            syntheticEvent: e.syntheticEvent
                        });
                    }
                    return;
                }
                var filterResult = current ?
                    filter.map(function (f) { return f.field === field ? __assign(__assign({}, current), { value: value }) : f; }) : __spreadArray(__spreadArray([], filter, true), [{ value: value, field: field, operator: booleanOperator }], false);
                cellProps.onFilterChange.call(undefined, {
                    filter: filterResult,
                    field: field,
                    syntheticEvent: e.syntheticEvent
                });
            } })));
    }
};
/**
 * @hidden
 */
var BooleanFilterCell = /** @class */ (function (_super) {
    __extends(BooleanFilterCell, _super);
    function BooleanFilterCell() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return BooleanFilterCell;
}(createFilterComponent(settings)));
export { BooleanFilterCell };
