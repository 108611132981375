"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import DatePicker from "../date_picker";
import moment from "moment-timezone";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { CloningService } from "../../services/cloning/cloning_service";
import BaseReactComponent from "../../base_react_component";

// i18next-extract-mark-ns-start widgets
class CopyRecordPopup extends BaseReactComponent {
  constructor(props) {
    super(props);
    const {t} = props;

    this.state = UIUtils.deepClone(this.props.objectToCopy);
    this.state.name = t("Copy of {{ name }}", {name: this.state.name});
    this.state.asOfDate = UIUtils.getEndOfDayForDate(moment()).tz("UTC").toDate();
    this.state.isClone = true;

    this.cloningService = new CloningService();
  }

  componentDidMount() {
    super.componentDidMount();
    $(this.modalRef).modal("show");
    $(this.modalRef).on("hidden.bs.modal", this.props.onHideModal);
    $("[data-toggle='validator']").validator("update");
  }

  handleNameChange(event) {
    if (this.state) {
      const name = event.target.value;
      this.validateName(name);
      this.setStateSafely({name});
    }
  }

  validateName(name) {
    let {t, customNameValidator} = this.props;
    let newNameControl = $("#newNameInput");

    // Reset the previous error message if exists.
    newNameControl[0].setCustomValidity("");
    newNameControl.trigger("input");

    if (customNameValidator) {
      let {error} = customNameValidator(name);
      if (error) {
        newNameControl[0].setCustomValidity(t(`${error}`));
        newNameControl.trigger("input");
      }
    }
    if (name === "" || name == null) {
      newNameControl[0].setCustomValidity(t(`A new name is required.`));
      newNameControl.trigger("input");
    }
  }

  handleAsOfDateChange(event) {
    let selectedDate = moment(event);

    this.validateCopyAsOfDate(event);
    if (this.state && selectedDate.isValid()) {
      const asOfDate = UIUtils.getEndOfDayForDate(selectedDate).tz("UTC").toDate();
      this.setStateSafely({asOfDate});
    }
  }

  handleCopyAsOfDateChangeRaw(event) {
    this.validateCopyAsOfDate(event && event.target.value);
  }

  handlePluginValueChange(attributeName, attributeValue) {
    let newState = {
      [attributeName]: attributeValue
    };

    this.setStateSafely(newState);
  }

  /**
   * Since react-datepicker does not provide out of the box validation, we need to trigger the bootstrap validation manually
   * for the Copy As Of date field, which is what this function does.
   * @param date The date to validate for cloning a project.
   * @returns {boolean}
   */
  validateCopyAsOfDate(date) {
    const {t, i18n, objectToCopy} = this.props;
    let asOfDateControl = $("#copyAsOfDateInput");

    // Sets locale for moment before using
    moment.locale(i18n.language);

    if (asOfDateControl[0]) {
      let objectCreatedDate = moment(objectToCopy.createdAt);
      let selectedDate = date ? UIUtils.getEndOfDayForDate(moment(date, UIUtils.DATE_FORMAT_FOR_DISPLAY, true)) : null;

      if (!selectedDate) {
        asOfDateControl[0].setCustomValidity(t(`Date is required.`));
        asOfDateControl.trigger("input");
        return false;
      }

      if (!selectedDate.isValid()) {
        asOfDateControl[0].setCustomValidity(
          t(`Invalid date format. Please enter the date in the format: {{ dateFormat }}`, {dateFormat: UIUtils.DATE_FORMAT_FOR_DISPLAY}));
        asOfDateControl.trigger("input");
        return false;
      }

      if (selectedDate < objectCreatedDate) {
        asOfDateControl[0].setCustomValidity(
          t(`The copy as of date cannot be less than the {{ objectToCopyName }} created date, {{ objectCreatedDate }}.`,
            {
              objectToCopyName: objectToCopy.name,
              objectCreatedDate: objectCreatedDate.format(UIUtils.DATE_FORMAT_FOR_DISPLAY)
            }));
        asOfDateControl.trigger("input");
        return false;
      }

      if (selectedDate > UIUtils.getEndOfDayForDate(moment())) {
        asOfDateControl[0].setCustomValidity(t(`The copy as of date cannot be in the future.`));
        asOfDateControl.trigger("input");
        return false;
      }

      asOfDateControl[0].setCustomValidity("");
      asOfDateControl.trigger("input");
      return true;
    }

    return false;
  }

  handleCopyRecord(event) {
    event.preventDefault();

    // Trigger name validation on submit.
    let {name} = this.state;
    this.validateName(name);

    let form = event.target.form ? event.target.form : event.target;
    if (form.checkValidity() === true) {
      let payload = {
        model: this.props.parent.capitalizedBaseTypeName || this.props.objectToCopy.modelName,
        ...this.state
      };
      if (this.props.onCopyStarted) {
        this.props.onCopyStarted();
      }
      UIUtils.setLoadingDisabled(false);
      this.cloningService.clone(payload)
        .then(result => this.handleCopyCompleted(result))
        .catch(result => {
          UIUtils.defaultFailFunction(result, null, null, UIUtils.PRIMARY_ALERT_DIV);
          $(this.modalRef).modal("hide");
        })
        .finally(() => {
          UIUtils.setLoadingDisabled(true);
        });
    }
  }

  handleCopyCompleted(result) {
    if (this.props.onCopyCompletion) {
      this.props.onCopyCompletion(result);
    }
  }

  renderPopupContents() {
    return [
      this.renderCloningProcessInfo(),
      this.renderCopyNameInput(),
      this.renderCopyAsDateInput(),
      ...this.renderPlugins()
    ];
  }

  renderCloningProcessInfo() {
    return this.state.errorMessage ? (
      <div key="cloningProgressInfoDiv" className="row">
        <div id="cloningInfoDiv"
             className={"col-sm-12 alert alert-danger"}
        >
          <span>{this.state.errorMessage}</span>
        </div>
      </div>
    ) : "";
  }

  renderCopyNameInput() {
    const {t} = this.props;

    return (
      <div key="newNameInput" className="row">
        <div className="form-group">
          <label htmlFor="newNameInput" className="col-form-label">
            {t("New Name *:")}
          </label>
          <input type="text"
                 className="form-control"
                 placeholder={t("New name")}
                 id="newNameInput"
                 data-validate="true"
                 required
                 value={this.state ? this.state.name : ""}
                 onChange={this.handleNameChange}
          />
          <div id="newNameInputErrorDiv" className="help-block with-errors" />
        </div>
      </div>);
  }

  renderCopyAsDateInput() {
    const {t} = this.props;

    return this.props.showAsOfDate ? (
      <div key="copyAsOfDateInput" className="row">
        <div className="form-group">
          <label htmlFor="newNameInput" className="col-form-label">
            {t("Copy as of Date:")}
          </label>
          <DatePicker id="copyAsOfDateInput"
                      selected={this.state && this.state.asOfDate ? moment(this.state.asOfDate).toDate() : null}
                      minDate={this.props.objectToCopy ? moment(this.props.objectToCopy.createdAt).toDate() : null}
                      maxDate={new Date()}
                      onChange={this.handleAsOfDateChange}
                      onChangeRaw={this.handleCopyAsOfDateChangeRaw}
          />
          <div id="copyAsOfDateInputErrorDiv" className="help-block with-errors" />
        </div>
      </div>) : "";
  }

  renderPlugins() {
    let counter = 1;
    return (this.props.plugins || []).map(pluginConfig => {
      let Plugin = pluginConfig.plugin;

      return <Plugin key={counter++}
                     parent={this}
                     type={pluginConfig.type}
                     objectToCopy={this.props.objectToCopy}
                     asOfDate={this.state && this.state.asOfDate ? this.state.asOfDate : null}
                     configProps={pluginConfig.props}
      />;
    });
  }

  render() {
    const {t, objectToCopy} = this.props;

    return (
      <div className="modal fade copy-record-popup"
           ref={modalNode => {
             this.props.modalRef(modalNode);
             this.modalRef = modalNode;
           }}
      >
        <form data-toggle="validator"
              onSubmit={this.handleCopyRecord}
              role="form"
              id="copyRecordForm"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">{t("Create a copy of '{{ objectToCopyName }}'", {objectToCopyName: objectToCopy.name})}</h1>
                <button type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="modal-container popup-container">
                  <div className="copy-record-popup-container">
                    {this.renderPopupContents()}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="modal-container">
                  <div className="btn-group">
                    <button id="copyButton"
                            type="submit"
                            className="btn btn-primary"
                    >
                      {t("Copy")}
                    </button>
                    <button id="cancelCopyButton"
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                    >
                      {t("Cancel")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default I18NWrapper.wrap(CopyRecordPopup, "widgets");
// i18next-extract-mark-ns-stop widgets
