var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { classNames } from '@progress/kendo-react-common';
/**
 * The `CalendarWeekCell` component is internally used for rendering the week cells inside the `month` view of the Calendar. Also used as a [custom week cell for the Calendar]({% slug api_dateinputs_calendarprops %}#toc-weekcell) and the [MultiViewCalendar]({% slug api_dateinputs_multiviewcalendarprops %}#toc-weekcell) components.
 *
 * * [Customizing week-column cells in the Calendar]({% slug custom_rendering_calendar %}#toc-cells-inside-the-week-column)
 * * [Customizing week-column cells in the MultiViewCalendar]({% slug custom_rendering_multiviewcalendar %}#toc-cells-inside-the-week-column)
 */
var CalendarWeekCell = /** @class */ (function (_super) {
    __extends(CalendarWeekCell, _super);
    function CalendarWeekCell() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @return
     * Returns a `<td />` element with the [`value`]({% slug api_dateinputs_calendarweekcellprops %}#toc-value) as a child.
     */
    CalendarWeekCell.prototype.render = function () {
        var _a = this.props, className = _a.className, firstDate = _a.firstDate, other = __rest(_a, ["className", "firstDate"]);
        return (React.createElement("td", __assign({ className: classNames('k-calendar-td', className) }, other), this.props.children));
    };
    return CalendarWeekCell;
}(React.Component));
export { CalendarWeekCell };
