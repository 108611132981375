"use strict";

import { TableAdapter } from "./table_adapter";
import * as CommonRiskTable from "../../utils/risk_tables_helper";
import * as UIUtils from "../../../../ui_utils";
import { RISK_TYPE_ENUM } from "../../../../helpers/constants/constants";
import { MODEL_TYPES_ENUM, RISK_TABLE_REPORTS_ENUM } from "../../../constants/report_constants";
import { getURLByTypeCodeAndId } from "../../../../helpers/url_helper";
import ReactDOMServer from "react-dom/server";
import ReactDOM from "react-dom";
import QuickPanelLink from "../../../../editor/quick/quick_panel_link";
import React from "react";
import { getRawRiskScore, getRiskScale, getTableCellClassFromRiskScore, getNormalizedRiskScore } from "../../../../helpers/risk_helper";
import * as RiskTableRenderer from "../../utils/risk_tables_renderer";
import { getTypeCodeForModelName } from "../../../../../server/common/generic/common_utils";
import * as StickyAdapter from "../../../../utils/sticky_adapter";


const AGGREGATIONS_ENUM = {
  CriticalityMax: "Criticality",
  ProcessRisk: "Process Risk",
  RPN: "RPN",
  RecommendedActions: "Recommended Actions",
  ControlStrategy: "Control Strategy",
  ControlMethod: "Control Methods",
  ScaleDependent: "Scale Dependent",
  PotentialFailureModes: "Potential Failure Modes",
};

/**
 * This is parent table adapter for all pivot tables. Table adapter is mainly intended to adapt from our data model types to datatables.net dom objects.
 */
export class PivotTableAdapter extends TableAdapter {

  constructor({reportKey, tableRef, tableData, props}) {
    super(reportKey, tableData, props);
    this.tableRef = tableRef;
    this.unitOperationId = tableData.unitOperationId;

    this.aggregationFunctions = {
      [AGGREGATIONS_ENUM.CriticalityMax]: CommonRiskTable.getRiskScoreWithLabel,
      [AGGREGATIONS_ENUM.ProcessRisk]: RiskTableRenderer.renderProcessRisk,
      [AGGREGATIONS_ENUM.RPN]: RiskTableRenderer.renderRPN,
      [AGGREGATIONS_ENUM.RecommendedActions]: RiskTableRenderer.renderRecommendedActions,
      [AGGREGATIONS_ENUM.ControlStrategy]: RiskTableRenderer.renderControlStrategy,
      [AGGREGATIONS_ENUM.ControlMethod]: RiskTableRenderer.renderControlMethod,
      [AGGREGATIONS_ENUM.ScaleDependent]: RiskTableRenderer.renderScaleDependency,
      [AGGREGATIONS_ENUM.PotentialFailureModes]: RiskTableRenderer.renderPotentialFailureModes,
    };

    this.aggregationKeys = {};
    for (let [key, value] of Object.entries(AGGREGATIONS_ENUM)) {
      this.aggregationKeys[value] = key;
    }
  }

  getColumnsForReport() {
    let columns = [];
    columns.push({
      title: this.reportTitle,
      width: 50,
      data: (result) => {
        return `${CommonRiskTable.getPrefixForSorting(result, this.props)}-${result.targetModel}`;
      }
    });

    for (let column of this.dynamicColumns.values()) {
      columns.push({
        title: ReactDOMServer.renderToStaticMarkup(column),
        width: 50,
        data: (result) => {
          return result[column] ? result[column] : null;
        }
      });
    }

    return columns;
  }

  getColumnDefinitionsForReport() {
    let columnDefinitions = [
      {
        targets: 0,
        createdCell: (td, cellData, rowData) => {
          const {
            targetModelType, targetModelId,
            isTargetLastVersion, targetVersionId,
            targetModel, targetParentModelType,
            targetParentModelId, targetParentModel,
            targetMajorVersion, targetMinorVersion,
            targetModelCurrentState,
          } = rowData;
          const quickPanelLinkId = CommonRiskTable.getQuickPanelLinkId(targetModelType, targetModelId);

          ReactDOM.render(
            (
              <div>
                <div>
                  <div>
                    <QuickPanelLink
                      id={quickPanelLinkId}
                      onClick={window.handleQuickEditRecord.bind(this, targetModelType, targetModelId,
                        isTargetLastVersion ? null : targetVersionId)}
                      className="risk-tables-row-header-cell"
                    >
                      {getTypeCodeForModelName(targetModelType) + "-" + targetModelId + " - " + targetModel}
                    </QuickPanelLink>
                  </div>
                  {
                    this.reportSettings.showParentInRows && targetParentModelType ? (
                      <div className="risk-tables-row-header-cell-uo">
                        <a href={getURLByTypeCodeAndId(targetParentModelType, "View", targetParentModelId)}
                           rel="noopener noreferrer"
                           target="_blank"
                        >
                          {targetParentModelType + "-" + targetParentModelId + " - " + targetParentModel}
                        </a>
                      </div>
                    ) : ""
                  }
                  <div>
                    {
                      isTargetLastVersion ? "" : (
                        <span className="risk-tables-column-version">{`Version: ${targetMajorVersion}.${targetMinorVersion}&nbsp;`}</span>
                      )
                    }
                    <span className="risk-tables-column-stamp">{CommonRiskTable.getStampFromState(targetModelCurrentState)}</span>
                  </div>
                </div>
              </div>
            ), td);
        }
      }];

    let counter = 1;
    for (let [key] of this.dynamicColumns) {
      columnDefinitions.push({
        targets: counter++,
        orderable: false,
        createdCell: (td, cellData, rowData) => {
          let criticalityRiskScores = {
            impact: rowData[`impact_${key}`],
            uncertainty: rowData[`uncertainty_${key}`],
            effect: rowData[`effect_${key}`],
            riskInfo: rowData[`linkRiskInfo_${key}`],
          };

          let rmp = this.props.RMP;
          let riskScoreWithLabel = "";
          let tableCellClassFromRiskScore = "";
          // we compute the criticality on a single risk link (not on entire record)
          let hasRiskInfo = criticalityRiskScores.riskInfo &&  criticalityRiskScores.riskInfo[RISK_TYPE_ENUM.CRITICALITY];
          if (!hasRiskInfo && (criticalityRiskScores.impact >= 0 || criticalityRiskScores.uncertainty >= 0)) {
            criticalityRiskScores.criticality = getRawRiskScore(RISK_TYPE_ENUM.CRITICALITY, rmp, criticalityRiskScores);
            criticalityRiskScores.riskInfo = {};
            criticalityRiskScores.riskInfo[RISK_TYPE_ENUM.CRITICALITY] = {
              value: criticalityRiskScores.criticality,
              scale: getRiskScale(RISK_TYPE_ENUM.CRITICALITY, rmp, criticalityRiskScores.criticality, criticalityRiskScores, false),
              scaleForRiskLabel: getRiskScale(RISK_TYPE_ENUM.CRITICALITY, rmp, criticalityRiskScores.criticality, criticalityRiskScores, true)
            };
            criticalityRiskScores.riskInfo[RISK_TYPE_ENUM.CRITICALITY].normalizedValue = getNormalizedRiskScore(RISK_TYPE_ENUM.CRITICALITY, rmp, criticalityRiskScores.criticality);
            hasRiskInfo = true;
          }

          if (hasRiskInfo) {
            criticalityRiskScores.criticality = criticalityRiskScores.riskInfo[RISK_TYPE_ENUM.CRITICALITY].value;
            let labelAction = this.props.labelAction;
            riskScoreWithLabel = CommonRiskTable.getRiskScoreWithLabel(criticalityRiskScores, RISK_TYPE_ENUM.CRITICALITY, rmp, this.props.showRawScore, labelAction, true, "");
            let showRiskLabel = CommonRiskTable.getShowRiskLabelFromLabelAction(labelAction);
            tableCellClassFromRiskScore = getTableCellClassFromRiskScore(RISK_TYPE_ENUM.CRITICALITY, rmp, rowData[key], criticalityRiskScores, showRiskLabel);
          }

          ReactDOM.render(
            (
              <span data-trigger="click hover"
                    data-html="true"
                    data-placement="right"
                    data-container="body"
                    data-toggle="popover"
                    className={tableCellClassFromRiskScore}
                    data-content={rowData[key] ?
                      RiskTableRenderer.renderCriticalityTooltip(
                        rmp,
                        rowData["impact_" + key],
                        rowData["uncertainty_" + key],
                        UIUtils.parseKey(key).typeCode,
                      )
                      : ""}
              >
                {riskScoreWithLabel === "" && tableCellClassFromRiskScore?.length > 0 ? " - " : riskScoreWithLabel}
              </span>
            ), td);
          $(td).addClass(tableCellClassFromRiskScore);
        }
      });
    }
    return columnDefinitions;
  }

  createReportHeader() {
    const {steps, unitOperations} = this.props;
    const tableId = CommonRiskTable.getRiskTableId(this.unitOperationId, true);

    let columns = [];
    for (let [key] of this.dynamicColumns) {
      columns.push(this.columnAttributes[key]);
    }

    // If there is no steps, don't display the step level grouping column.
    if (columns.filter(column => column.stepId).length === 0) {
      return;
    }

    // Group by steps to get the step count. then calculate the colspan based on the step count...
    const ColSpanSizes = columns.reduce((columnGroup, column) => {
      let {stepId} = column;
      let locationId = CommonRiskTable.getLocationId(this.unitOperationId, stepId);
      columnGroup.has(locationId)
        ? columnGroup.set(locationId, columnGroup.get(locationId) + 1)
        : columnGroup.set(locationId, 1);

      return columnGroup;
    }, new Map());


    let firstTR = $(`${tableId} thead tr:first`);
    let headerTR = $("<tr class=\"step-grouping-row\"/>").insertBefore(firstTR);

    let firstTh = firstTR.find("th:first");
    firstTh.attr("rowspan", 2).detach().appendTo(headerTR);

    for (let [key, value] of ColSpanSizes) {

      const step = steps.find(step => {
        const {id, UnitOperationId} = step;
        let locationId = CommonRiskTable.getLocationId(UnitOperationId, id);
        return locationId === key;
      });

      const unitOperation = unitOperations.find(uo => {
        let idSeparatorIndex = key.indexOf("-");
        return (idSeparatorIndex === -1) ?
          uo.id === UIUtils.parseInt(key) :
          uo.id === UIUtils.parseInt(key.slice(0, idSeparatorIndex));
      });

      const {id, typeCode, name} = step ?? unitOperation;
      const modelName = step ? MODEL_TYPES_ENUM.STP : MODEL_TYPES_ENUM.UO;

      $(`<th colspan=${value}>
          <a href=${getURLByTypeCodeAndId(typeCode, "View", id)} 
             rel="noopener noreferrer"
             target="_blank">
              ${getTypeCodeForModelName(modelName) + "-" + id + " - " + name}
          </a>
        </th>`).appendTo(headerTR);
    }
  }

  createReportFooter() {
    let numberOfDynamicColumns = this.dynamicColumns.size;
    let aggregations = [
      AGGREGATIONS_ENUM.CriticalityMax,
      AGGREGATIONS_ENUM.ProcessRisk,
      AGGREGATIONS_ENUM.RPN
    ];

    if (this.report === RISK_TABLE_REPORTS_ENUM.XQAs_to_PPs) {
      aggregations.push(
        AGGREGATIONS_ENUM.PotentialFailureModes,
        AGGREGATIONS_ENUM.ScaleDependent
      );
    }

    aggregations.push(
      AGGREGATIONS_ENUM.RecommendedActions,
      AGGREGATIONS_ENUM.ControlStrategy,
      AGGREGATIONS_ENUM.ControlMethod
    );

    // Prepare the footer rows in advance so that datatables can take care of the footer during initialization
    // https://datatables.net/forums/discussion/27829/add-table-footer-with-javascript-only
    let footer = $("<tfoot/>").appendTo(this.tableRef);

    for (let i = 0; i < aggregations.length; i++) {
      const aggregation = aggregations[i];
      let footerTR = $("<tr/>").appendTo(footer);

      //Add the first footer cell with the aggregation
      if (i === 0) {
        $(`<th class="risk-tables-footer-cell-divider risk-tables-footer-header-cell">${aggregation}</th>`).appendTo(footerTR);
      } else {
        $(`<th class="risk-tables-footer-header-cell">${aggregation}</th>`).appendTo(footerTR);
      }

      //Add footer cells for all dynamic columns
      for (let j = 0; j < numberOfDynamicColumns; j++) {
        if (i === 0) {
          $("<th class=\"risk-tables-footer-cell risk-tables-footer-cell-divider\"/>").appendTo(footerTR);
        } else {
          $("<th class=\"risk-tables-footer-cell\"/>").appendTo(footerTR);
        }
      }
    }
  }

  handleCreatedHeaderRowCallback(thead) {
    // Apply below only for the none step grouping header rows.
    if (!$(thead).hasClass("step-grouping-row")) {
      super.handleCreatedHeaderRowCallback(thead);
    }
  }

  handleCreatedFooterRowCallback(tableId) {
    let api = $(tableId).dataTable().api();
    for (let footerRow of $("tr", api.table().footer())) {
      let rowCells = $("th", footerRow);

      if (!rowCells[0] || !rowCells[0].innerHTML) {
        throw new Error("Unable to find a valid table header to be used as an aggregation key.");
      }

      // Make the first cell in every row sticky if the user scrolls left/right
      const firstCell = $(rowCells[0]);
      StickyAdapter.makeItSticky(firstCell, window, StickyAdapter.STICK_TO.LEFT);
      $(firstCell).addClass("risk-tables-report-table-firstCell");

      let aggregationKey = rowCells[0].innerHTML.trim();
      let aggregation = this.aggregationKeys[aggregationKey];

      let riskType;
      switch (AGGREGATIONS_ENUM[aggregation]) {
        case AGGREGATIONS_ENUM.CriticalityMax:
          riskType = RISK_TYPE_ENUM.CRITICALITY;
          break;
        case AGGREGATIONS_ENUM.ProcessRisk:
          riskType = RISK_TYPE_ENUM.PROCESS_RISK;
          break;
        case AGGREGATIONS_ENUM.RPN:
          riskType = RISK_TYPE_ENUM.RPN;
          break;
      }

      let columnsIterator = this.dynamicColumns.keys();
      for (let i = 1; i < rowCells.length; i++) {
        const cell = $(rowCells[i]);
        let columnAttribute = this.columnAttributes[columnsIterator.next().value];
        let rawRiskScore = getRawRiskScore(riskType, this.props.RMP, columnAttribute, columnAttribute.detailedRiskLinks);
        const aggregationFunction = this.aggregationFunctions[AGGREGATIONS_ENUM[aggregation]];

        let labelAction = this.props.labelAction;
        let showRiskLabel = CommonRiskTable.getShowRiskLabelFromLabelAction(labelAction, {isAggregation: true});

        if (aggregationFunction) {
          cell.html(
            aggregationFunction(columnAttribute, riskType, this.props.RMP, this.props.showRawScore, showRiskLabel)
          );
        } else {
          console.warn("Aggregation function not found: ", aggregation, AGGREGATIONS_ENUM[aggregation]);
        }

        if (riskType) {
          cell.addClass(getTableCellClassFromRiskScore(riskType, this.props.RMP, rawRiskScore, columnAttribute, showRiskLabel));
        }
      }
    }
  }

  // eslint-disable-next-line no-unused-vars
  markColumnsRequiresSorting(tableId, defaultOrder = "asc") {
  }

}
