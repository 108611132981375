"use strict";
// The functions in this file are responsible for checking the system is available

import * as UIUtils from "../ui_utils";
import React, {Fragment} from "react";
import BaseReactComponent from "../base_react_component";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import logoImage from "../static/images/logo-full.png";
import {CommonUtils} from "../../server/common/generic/common_utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlay, faStop, faSync} from "@fortawesome/free-solid-svg-icons";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.System, "Check");

/**
 * This class handles showing information needed for the home page
 */
// i18next-extract-mark-ns-start check
class Check extends BaseReactComponent {
  constructor(props) {
    super();
    const {t} = props;
    UIUtils.setLoadingDisabled(true); // Use the skeleton loader.
    this.checkStatus();
    this.setStateSafely({
      overallResult: t("Checking..."),
      overallResultClass: "",
      primaryDBResult: t("Checking..."),
      secondaryDBResult: t("Checking..."),
      responseTime: t("Checking..."),
      responseTimeClass: "",
      lambdaConnectivityStatus: t("Checking..."),
      dbClusterStatus: t("Checking..."),
      isLoading: true,
    });

    // Refresh the status every once in a while.
    window.setInterval(() => {
      if (!this.state.isLoading) {
        Logger.info(() => "Timer is up! Refreshing...");
        this.checkStatus();
      }
    }, 60 * 1000);
  }

  checkStatus() {
    this.setStateSafely({isLoading: true});
    $.ajax({
      url: UIUtils.getURL("checkSystem"),
      error: this.handleFailure,
    }).done(this.statusReceived);
  }

  statusReceived(result) {
    if (result.overallMessage && result.primaryDBResult && result.secondaryDBResult && result.responseTime
      && result.lambdaConnectivityStatus) {
      this.handleSuccess(result);
    } else {
      this.handleFailure(result);
    }
    this.setStateSafely({result});
  }

  handleSuccess(result) {
    console.log("Success.  Result = " + JSON.stringify(result));
    this.setStateSafely({
      overallResult: result.overallMessage,
      primaryDBResult: result.primaryDBResult,
      secondaryDBResult: result.secondaryDBResult,
      lambdaConnectivityStatus: result.lambdaConnectivityStatus,
      dbClusterStatus: result.clusterStatus,
      isLoading: false,
    });
    this.setResponseTime(result);
  }

  setResponseTime(result) {
    let responseTime = UIUtils.parseInt(result.responseTime) / 1000;
    let responseTimeClass;
    if (responseTime > 20) {
      responseTimeClass = "bg-danger";
    } else if (responseTime > 10) {
      responseTimeClass = "bg-warning";
    } else {
      responseTimeClass = "bg-success";
    }
    this.setStateSafely({
      responseTime: responseTime + "s",
      responseTimeClass,
    });
  }

  /**
   * Safely set a field to be green if it was successful.
   */
  getClassForResult(result) {
    const formattedResult = result?.toLowerCase();

    if (formattedResult?.startsWith("success") || formattedResult?.startsWith("available")) {
      return "bg-success";
    } else if (formattedResult?.startsWith("starting") || formattedResult?.startsWith("stopping")) {
      return "bg-warning";
    } else {
      return "bg-danger";
    }
  }

  handleFailure(result) {
    console.log("Fail.  Result = " + JSON.stringify(result));
    let errorText = "Unknown Error!";
    if (result && result.responseJSON && result.responseJSON.overallMessage) {
      errorText = "Error: " + result.responseJSON.overallMessage;
    }

    if (result && result.responseJSON) {
      result = result.responseJSON;
    } else {
      result = {
        primaryDBResult: "Unknown",
        secondaryDBResult: "Unknown",
        responseTime: "Unknown",
        lambdaConnectivityStatus: "Unknown",
        clusterStatus: "Unknown",
      };
    }

    this.setStateSafely({
      overallResult: errorText,
      overallResultClass: "bg-danger",
      primaryDBResult: result.primaryDBResult,
      secondaryDBResult: result.secondaryDBResult,
      lambdaConnectivityStatus: result.lambdaConnectivityStatus,
      dbClusterStatus: result.clusterStatus,
      isLoading: false,
    });
    this.setResponseTime(result);
  }

  handleStartDatabase() {
    console.log("Starting database...");
    this.setStateSafely({isLoading: true});
    UIUtils.showWarning("Starting database...");
    $.ajax(
      {
        url: UIUtils.getURL("system/startDatabase"),
        error: this.handleOperationFailed.bind(this, "start"),
        method: "PUT",
        body: {environment: process.env.REMOTE_ENV},
      },
    ).done(this.handleOperationResult.bind(this, "started"));
  }

  handleStopDatabase() {
    console.log("Stopping database...");
    UIUtils.showWarning("Stopping database...");
    this.setStateSafely({isLoading: true});
    $.ajax(
      {
        url: UIUtils.getURL("system/stopDatabase"),
        error: this.handleOperationFailed.bind(this, "stop"),
        method: "PUT",
        body: {environment: process.env.REMOTE_ENV},
      },
    ).done(this.handleOperationResult.bind(this, "stopped"));
  }

  handleOperationResult(action, result) {
    console.log("Result: ", result);
    UIUtils.showWarning(`Database ${action} successfully. Checking status again...`);
    this.checkStatus();
  }

  handleOperationFailed(action, result) {
    console.log("Result: ", result);
    if (action === "start") {
      UIUtils.showWarning(`Database ${action} timed out. Checking status again...`);
    } else {
      UIUtils.showError(`Database ${action} failed. Checking status again...`);
    }
    this.checkStatus();
  }

  handleRefresh() {
    this.checkStatus();
  }


  render() {
    const {t} = this.props;
    const {
      result,
      overallResult,
      overallResultClass,
      primaryDBResult,
      secondaryDBResult,
      responseTime,
      responseTimeClass,
      lambdaConnectivityStatus,
      dbClusterStatus,
    } = this.state;

    // Only shows development database controls in case it's a dev or CICD db.
    const shouldShowDevelopmentDatabaseControls = (
      (!CommonUtils.isProductionOrStaging()
        && !CommonUtils.isEnvironmentPublicToClients())
      || CommonUtils.isEnterpriseStagingEnvironment()
    );
    const clusterStatus = result?.clusterStatus?.toLowerCase();

    return <Fragment>
      <div className="container" style={{marginTop: "50px"}}>
        <div className="row" style={{marginBottom: "20px"}}>
          <div className="col-12">
            <a href="/">
              <img src={logoImage}
                   height="48"
                   className="mx-auto d-block"
                   alt="QbDVision Logo"
              />
            </a>
          </div>
          <div className="col-12">
            <h1 className="text-center">{t("System Status")}
              <button onClick={this.handleRefresh} className="btn btn-sm">
                <FontAwesomeIcon id={"refresh"} icon={faSync} size="sm" />
              </button>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="alert alert-danger d-none error-bar" role="alert" id="alertDiv" />
        </div>
        <div className="row">
          <div className="col-md-3 offset-3 header">
            {t("Overall Status:")}
          </div>
          <div id="overallResult"
               className={"col-md-3 bg-primary result "
              + (overallResultClass || this.getClassForResult(overallResult))
              + this.getClassForLoading()}
          >
            {overallResult}
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 offset-3 header">
            {t("Primary Database Status:")}
          </div>
          <div id="primaryDBResult"
               className={"col-md-3 bg-primary result " + this.getClassForResult(primaryDBResult) + this.getClassForLoading()}
          >
            {primaryDBResult}
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 offset-3 header">
            {t("Secondary Database Status:")}
          </div>
          <div id="secondaryDBResult"
               className={"col-md-3 bg-primary result " + this.getClassForResult(secondaryDBResult) + this.getClassForLoading()}
          >
            {secondaryDBResult}
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 offset-3 header">
            {t("Response Time:")}
          </div>
          <div id="responseTime"
               className={"col-md-3 bg-primary result " + responseTimeClass + this.getClassForLoading()}
          >
            {responseTime}
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 offset-3 header">
            {t("Lambda Internet Connectivity:")}
          </div>
          <div id="lambdaConnectivityStatus"
               className={"col-md-3 bg-primary result " + this.getClassForResult(lambdaConnectivityStatus) + this.getClassForLoading()}
          >
            {lambdaConnectivityStatus}
          </div>
        </div>
        {
          shouldShowDevelopmentDatabaseControls
            ? (
              <Fragment>
                <div className="row mt-3">
                  <div className="col-md-3 offset-3 header">
                    Database Cluster Status ({process.env.REMOTE_ENV}):
                  </div>
                  <div id="dbClusterStatus"
                       className={"col-md-3 bg-primary result " + this.getClassForResult(dbClusterStatus) + this.getClassForLoading()}
                  >
                    {dbClusterStatus}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 offset-3 header">
                    Start/Stop database:
                  </div>
                  <div className="col-md-3 p-0 toolbar">
                    <button onClick={this.handleStartDatabase} disabled={["available", "starting", "stopping"].includes(clusterStatus)} className="btn btn-sm">
                      <FontAwesomeIcon id={"startDatabase"} icon={faPlay} size="sm" />
                    </button>
                    <button onClick={this.handleStopDatabase} disabled={["stopped", "starting", "stopping"].includes(clusterStatus)} className="btn btn-sm">
                      <FontAwesomeIcon id={"stopDatabase"} icon={faStop} size="sm" />
                    </button>
                  </div>
                </div>
              </Fragment>
            ) : ""
        }
        <div className="row" style={{marginTop: "30px"}}>
          <p className="col-12 text-center">
            <a href="http://status.aws.amazon.com/">{t("Check AWS Status")}</a>
          </p>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <a href="/">
              Back to Login
            </a>
          </div>
        </div>
      </div>
    </Fragment>;
  }
}

export default I18NWrapper.wrap(Check, "check");
// i18next-extract-mark-ns-stop check;
