var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { getNestedValue } from './../utils';
import { useInternationalization } from '@progress/kendo-react-intl';
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { GRID_COL_INDEX_ATTRIBUTE } from '../constants';
import { classNames } from '@progress/kendo-react-common';
export var GridCell = function (props) {
    var _a;
    var defaultRendering = null;
    var intl = useInternationalization();
    var navigationAttributes = useTableKeyboardNavigation(props.id);
    if (props.rowType === 'groupFooter') {
        defaultRendering = React.createElement("td", { className: props.className });
    }
    else if (props.field !== undefined && props.rowType !== 'groupHeader') {
        var data = getNestedValue(props.field, props.dataItem);
        var dataAsString = '';
        if (data !== undefined && data !== null) {
            dataAsString = props.format ?
                intl.format(props.format, data) :
                data.toString();
        }
        var className = classNames(props.className, { 'k-selected': props.isSelected });
        defaultRendering = (React.createElement("td", __assign({ colSpan: props.colSpan, style: props.style, className: className, role: 'gridcell', "aria-colindex": props.ariaColumnIndex, "aria-selected": props.isSelected }, (_a = {}, _a[GRID_COL_INDEX_ATTRIBUTE] = props.columnIndex, _a), navigationAttributes), dataAsString));
    }
    return props.render ?
        props.render.call(undefined, defaultRendering, props) :
        defaultRendering;
};
