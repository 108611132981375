/**
 * @hidden
 */
export function getNestedValue(fieldName, dataItem) {
    var path = (fieldName || '').split('.');
    var data = dataItem;
    path.forEach(function (p) {
        data = data ? data[p] : undefined;
    });
    return data;
}
/**
 * @hidden
 */
export function isArray(operation) {
    return operation.length !== undefined;
}
