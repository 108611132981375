"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { getURLByKey } from "../../helpers/url_helper";
import TableWithCheckboxes from "../../widgets/tables/table_with_checkboxes";

/* This shows the "Drafts Pending" table on the dashboard. */
class DraftsPendingTable extends TableWithCheckboxes {
  constructor(props) {
    super(props);
  }

  isLoading() {
    return this.props.parent.state.isLoading;
  }

  getColumns() {
    return [
      {
        title: "",
        width: 1,
        data: (result) => result.fullKey
      },
      {
        title: "ID",
        width: 50,
        data: (result, type) => {
          if (type === "display") {
            return ReactDOMServer.renderToStaticMarkup(
              <a href={this.getViewURL(result.fullKey)}>
                {result.customId || result.fullKey}
              </a>
            );
          } else if (type === "sort") {
            return UIUtils.getKeyForSorting(result.fullKey);
          } else {
            // Checkboxes send in type === "type" and break if we use the sorting key above. "Filter" is another type that gets sent in.
            return result.fullKey;
          }
        }
      },
      {
        title: "Name",
        width: 200,
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            <a href={this.getViewURL(result.fullKey)}>
              {result.name}
            </a>
          );
        }
      },
    ];
  }

  getViewURL(keyString) {
    return getURLByKey(keyString, "View", false, this.props.projectId);
  }

  // noinspection JSMethodCanBeStatic
  getColumnsDefs() {
    return super.getColumnsDefs().concat(
      [
        {
          className: "results-table-column-id",
          targets: 1
        }
      ]
    );
  }

  componentDidMount() {
    super.componentDidMount();

    this.table.button().add(0,
      {
        text: "Propose",
        className: "btn btn-lg btn-primary dashboard-bulk-proposal-button",
        action: this.proposeSelectedRecords
      }
    );

    // To initialize the select all checkbox and disable the propose button until the data has loaded.
    this.handleSelectedRowsChanged();
  }

  proposeSelectedRecords() {
    let selectedRows = this.getSelectedRows();

    if (selectedRows.length > 0) {
      let selectedRequirements = [];
      for (let i = 0; i < selectedRows.length; i++) {
        selectedRequirements.push(selectedRows[i].fullKey);
      }

      sessionStorage["bulkProposalRequirementIds"] = JSON.stringify(selectedRequirements);
      this.context.navigate(UIUtils.getSecuredURL(`/projects/bulkProposal.html?projectId=${this.props.projectId}`));
    }
  }

  /**
   * @inheritDoc
   */
  handleSelectedRowsChanged() {
    super.handleSelectedRowsChanged();
    this.updateProposeButton();
  }

  updateProposeButton() {
    let selectedRows = this.getSelectedRows().length;
    let proposeButton = $("#draftsPendingTable_wrapper .dt-buttons .btn-primary");

    // Update the propose button status based on the number of selected rows.
    proposeButton.prop("disabled", selectedRows === 0);
    proposeButton.prop("title", selectedRows === 0 ? "Select one or more requirements for proposal." : "");
  }
}

export default DraftsPendingTable;