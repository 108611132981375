"use strict";

import { useSidePanel } from "./side_panel_provider";
import { createPortal } from "react-dom";
import * as styles from "../view/table_tool_bar.module.scss";
import { ColumnSelection } from "../view/columnSelection/column_selection";
import React from "react";
import { SIDE_PANEL_ACTIONS } from "../../constants";
import BulkEdit from "../edit/bulk_edit";

const renderPanelContent = (action, props) => {
  switch (action) {
    case SIDE_PANEL_ACTIONS.CONFIGURE:
      return (
        <ColumnSelection {...props} />
      );
    case SIDE_PANEL_ACTIONS.BULK_EDIT:
      return (
        <BulkEdit {...props} />
      );
  }
};

export const SidePanelWidget = (props) => {

  const {action, showSidePanel} = useSidePanel();

  return createPortal(
    <div id="columnSelectionDiv" className={`${styles["side-panel"]} ${showSidePanel ? styles["show"] : ""}`}>
      {renderPanelContent(action, props)}
    </div>,
    document.body,
  );
};