"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import { createHTMLForWholeTextDiff } from "../../helpers/diff_helper";
import { ListOption } from "../widgets/list_option";
import BaseCustomErrorAttribute from "./base_custom_error_attribute";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";

const EMPTY_ITEM = {key: "", value: ""};

const Logger = Log.group(LOG_GROUP.Editables, "ComboBoxAttribute");

/**
 * This class is responsible for showing a dropdown with a list of options. If the options are variable (ie. loaded from
 * the DB or an AJAX call), then please use the TypeaheadAttribute since it provides a better UI experience for the User.
 */
export default class ComboBoxAttribute extends BaseCustomErrorAttribute {
  constructor(props) {
    super(props);

    // Check to make sure the default is in the list of options
    if (this.props.default && this.props.options) {
      if (!this.props.options.find(option => Object.prototype.hasOwnProperty.call(option, "key") ? option.key === this.props.default : option === this.props.default)) {
        throw new Error("The default option for the " + this.props.name + " combo box attribute ("
          + this.props.default + ") is not an option in the list of options: " + JSON.stringify(this.props.options));
      }
    }
  }

  handleChange(event) {
    const value = event.target.value;
    const {isNumber, onChange} = this.props;

    // Parses the value of the event target in case it's a number
    const parsedEvent = {
      ...event,
      target: {
        ...(event.target || {}),
        value: isNumber ? parseInt(value) : value,
      }
    };

    // If a prop is set, it allows us to change the default behavior.
    if (typeof onChange === "function") {
      let shouldContinue = onChange(this, parsedEvent, value);

      // Allows the developer to skip the default behavior if needed.
      if (shouldContinue === false) {
        Logger.debug(`Skipping default behavior for handleChange in ${this.id}`);
        return;
      }
    }

    super.handleChange(parsedEvent);
  }

  getOptions(props) {
    props = props || this.props;

    const {
      options,
      filter,
    } = props;

    const areOptionsExplicitlyDefined = options && options.length;

    let resultingOptions = [];
    if (options && !this.isKeyValuePair(options[0])) {
      resultingOptions = options.map(entry => {
        return {key: entry, value: entry};
      });
    } else if (options) {
      resultingOptions = options;
    }

    if (resultingOptions && filter) {
      resultingOptions = resultingOptions.filter(filter);
    }

    if (!areOptionsExplicitlyDefined && !this.isRequired()) {
      resultingOptions = [
        EMPTY_ITEM,
        ...resultingOptions,
      ];
    }
    return resultingOptions;
  }

  getInitialValue() {
    let initialValue = null;

    const options = this.getOptions(this.props);
    const {
      // sets an alias to default, as it's a keyword
      default: defaultValue,
    } = this.props;

    if (defaultValue) {
      initialValue = defaultValue;
    } else if (options && options.length > 0) {
      initialValue = options[0];
    }
    let optionKey = this.getOptionKey(initialValue);
    return typeof optionKey === "undefined" ? null : optionKey;
  }

  getOptionKey(option) {
    return this.isKeyValuePair(option) ? option.key : option;
  }

  isKeyValuePair(option) {
    return option ? Object.prototype.hasOwnProperty.call(option, "key") : false;
  }

  getDisplayValue(value, props) {
    props = props || this.props;

    let options = this.getOptions(props);
    if (options && options.length > 0 && this.isKeyValuePair(options[0])) {
      if (!value && options[0].key === 0) {
        value = 0;
      } else if (!value) {
        return value;
      }

      const selectedOption = options.find(option => {
        let found = option.key === value;
        if (!found) {
          found = option.value === value;
        }
        return found;
      });
      return selectedOption ? selectedOption.value : value;
    }

    return value;
  }

  getAttributeName() {
    return this.props.attributeName || super.getAttributeName();
  }

  getInputId() {
    return this.props.name + "ComboBox";
  }

  getParentAttributeName() {
    return UIUtils.uncapitalize(this.props.name);
  }

  isValueSet() {
    return !(this.getValue() === null ||
      this.getValue() === undefined ||
      this.getValue().toString().trim() === "");
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      title,
      default: defaultValue,
    } = this.props;

    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
    if (!shouldUpdate) {
      const options = this.getOptions(this.props);
      const newOptions = this.getOptions(nextProps);
      const nextValue = this.getNextValue(nextProps);
      const currentValue = this.isKeyValuePair(this.currentValue) ? this.currentValue.key : this.currentValue;
      const newDefaultValue = nextProps.default;
      shouldUpdate = (
        currentValue !== nextValue
        || (Array.isArray(this.currentOptions) ? JSON.stringify(this.currentOptions) : this.currentOptions) !== JSON.stringify(newOptions)
        || nextProps.title !== title
        || JSON.stringify(newOptions) !== JSON.stringify(options)
        || JSON.stringify(this.props.olderVersion) !== JSON.stringify(nextProps.olderVersion)
        || defaultValue !== newDefaultValue
      );
    }

    return shouldUpdate;
  }

  getOldValue(attributeName) {
    let oldValue = super.getOldValue(attributeName);
    if (UIUtils.isNumber(oldValue)) {
      return this.props.olderOptions?.find(olderOption => olderOption.key === oldValue)?.value ?? oldValue;
    }

    if (oldValue !== null && oldValue !== undefined) {
      return oldValue;
    }

    return this.isFirstVersion() ? "" : this.getInitialValue();
  }

  hasOldValue(attributeName) {
    const oldValue = super.getOldValue(attributeName);
    if ((oldValue === null || oldValue === undefined) && this.isFirstVersion()) {
      return false;
    }

    return true;
  }

  isFirstVersion() {
    const {parent} = this.props;
    const {state} = parent;
    const {minorVersion, majorVersion, showMajorVersionsOnly} = state;
    const version = `${majorVersion}.${minorVersion}`;
    return version === "0.1" || showMajorVersionsOnly;
  }

  renderInput() {
    const {
      title,
      isLinkToDBObject,
      shouldDisplayValue,
    } = this.props;

    const options = this.getOptions();
    this.currentOptions = JSON.stringify(options);

    let input;
    let inputId = this.getInputId();

    if (this.isView() || this.props.readonly) {
      this.currentValue = this.getValue() || "";

      if (this.isDiffingVersions()) {
        let hasOldValue = this.hasOldValue(this.getAttributeName());
        let oldValue = this.getOldValue(this.getAttributeName());
        input = createHTMLForWholeTextDiff(hasOldValue ? this.getDisplayValue(oldValue) : "", this.getDisplayValue(this.currentValue));
      } else {
        if (isLinkToDBObject) {
          input = (
            <a href={getURLByTypeCodeAndId(isLinkToDBObject.typeCode, "View", this.currentValue)}
               id={inputId}
               target="_blank"
               rel="noreferrer  noopener"
            >
              {UIUtils.getRecordLabelForDisplay(isLinkToDBObject.typeCode, this.currentValue, this.getDisplayValue(this.currentValue))}
            </a>);
        } else {
          input = (<span id={inputId}>{shouldDisplayValue ? this.getDisplayValue(this.currentValue) : ""}</span>);
        }
      }
    } else {
      this.currentValue = this.isAdd() && options
        ? (this.getValue() || options[0])
        : (this.getValue() || "");

      let dataRequiredErrorMsg = this.getCapitalizedName() + " is required.";
      input = (
        <select className="form-control"
                id={inputId}
                value={this.currentValue}
                onChange={this.handleChange}
                data-validate="true"
                data-required-error={dataRequiredErrorMsg}
                required={this.isRequired()}
                disabled={this.isDisabled()} title={title}
        >
          {options.map(optionValue => {
            return this.getOptionKey(optionValue) === undefined ? "" : (
              <ListOption item={optionValue} key={this.getOptionKey(optionValue)} />
            );
          })}
        </select>
      );
    }

    return input;
  }
}

ComboBoxAttribute.defaultProps = {
  shouldDisplayValue: true,
  className: "col-sm-6",
  type: "text"
};
