"use strict";

import React, { useEffect, useRef } from "react";
import { generateTableIds } from "../../../configurableTables/tables/configurable_tables_helper";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  LARGE_COLUMN,
  MEDIUM_COLUMN, SMALL_COLUMN
} from "../../../configurableTables/tables/configurable_tables_constants";
import SpecificationCell from "./specification_cell";

const TABLE_HEADERS = [
  {
    title: "name",
    label: "Specification",
  },
  {
    title: "measure",
    label: "Measure",
    width: LARGE_COLUMN,
  },
  {
    title: "lowerLimit",
    label: "LSL",
    width: MEDIUM_COLUMN,
  },
  {
    title: "target",
    label: "Target",
    width: MEDIUM_COLUMN,
  },
  {
    title: "upperLimit",
    label: "USL",
    width: MEDIUM_COLUMN,
  },
  {
    title: "measurementUnits",
    label: "Units",
    width: LARGE_COLUMN,
  },
  {
    title: "analyticsUrl",
    label: "Analytics",
    width: SMALL_COLUMN,
  },
];

const  SpecificationTable = ({data, tableId, title, parentName, parentLink, parentKey, firstColumnLabel}) => {
  const tableRef = useRef();

  if (firstColumnLabel) {
    TABLE_HEADERS[0].label = firstColumnLabel;
  }

  useEffect(() => {
    generateTableIds(tableRef, tableId);
  }, []);

  return (
    data?.length > 0 && (
        <div className={"specification-table"} id={tableId}>
          <h2>{title}</h2> <a className={"header-link"} href={parentLink} target="_blank" rel="noreferrer noopener">{parentName}</a> <span className="key">{parentKey}</span>
          <Grid style={{ width: "fit-content", textTransform: "capitalize" }}
                data={data}
                ref={tableRef}>
              {
                TABLE_HEADERS.map(({title, label, field, width}) => <GridColumn key={title}
                                                                                field={field || title}
                                                                                title={label || title}
                                                                                headerClassName={`column-header ${title}`}
                                                                                width={width}
                                                                                cell={SpecificationCell}

                />)
              }
            </Grid>
        </div>)
  );
};

export default React.memo(SpecificationTable);
