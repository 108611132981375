"use strict";
import { TEXT_DIFF_METHOD_ENUM } from "../../../helpers/constants/constants";
import { FIELD_INPUT_TYPE, FIELD_REQUIRED_FOR } from "../../widgets/widget_field";

/**
 * @type {IWidgetField[]}
 */
export const WIDGET_FIELDS = [
  {
    isUnique: true,
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    requiredFor: FIELD_REQUIRED_FOR.save,
    forceUpdate: false,
    belongsToMasterRow: true,
    order: 1,
    inputType: FIELD_INPUT_TYPE.linkedTypeahead,
    width: 250,
  },
  {
    fieldName: "impact",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    displayName: "Impact/Severity",
    requiredFor: "proposal",
    forceUpdate: true,
    defaultValue: "getDefaultImpactOption",
    placeholder: "",
    belongsToMasterRow: true,
    order: 2,
    inputType: FIELD_INPUT_TYPE.select,
    getOptions: "getImpactOptions",
    width: 130,
  },
  {
    fieldName: "uncertainty",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    displayName: "Uncertainty/Likelihood",
    requiredFor: "proposal",
    forceUpdate: true,
    defaultValue: "getDefaultUncertaintyOption",
    placeholder: "",
    belongsToMasterRow: true,
    disabled: "useUncertainty",
    order: 3,
    inputType: FIELD_INPUT_TYPE.select,
    getOptions: "getUncertaintyOptions",
    width: 130,
  },
  {
    fieldName: "criticality",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    displayName: "Criticality",
    requiredFor: FIELD_REQUIRED_FOR.none,
    forceUpdate: false,
    belongsToMasterRow: true,
    order: 4,
    inputType: FIELD_INPUT_TYPE.criticality,
    width: 1,
  },
  {
    fieldName: "justification",
    diffMethod: TEXT_DIFF_METHOD_ENUM.differential,
    displayName: "Justification",
    tooltipText: "Impact and uncertainty values should be justified by literature, data, or other science-based rationales.",
    requiredFor: "proposal",
    forceUpdate: false,
    defaultValue: "",
    placeholder: "",
    belongsToMasterRow: false,
    order: 5,
    rows: 4,
    inputType: FIELD_INPUT_TYPE.textarea,
  },
  {
    fieldName: "links",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    displayName: "Link or Attachment",
    requiredFor: FIELD_REQUIRED_FOR.none,
    defaultValue: "",
    belongsToMasterRow: false,
    order: 6,
    inputType: FIELD_INPUT_TYPE.link,
  }]
;
