"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import ReactDOMServer from "react-dom/server";
import BaseLinkedEntitiesAttribute from "./base_linked_entities_attribute";
import { createRowDataDiffForRiskLinks } from "../../helpers/diff_helper";
import {
  getEffectiveRMPByModelName,
  getMaxCriticality,
  getRawRiskScore,
  getRiskFieldTooltip,
  getRiskLabel,
  getRiskScaleTooltip,
} from "../../helpers/risk_helper";
import { RISK_TYPE_EFFECTS, RISK_TYPE_ENUM } from "../../helpers/constants/constants";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import RiskUtils from "../../../server/common/misc/common_risk_utils";
import { getCSSClassForLegend } from "../../reports/risk_map_reports/utilities/risk_map_report_helper";
import LabelTooltip from "../../widgets/tooltips/label_tooltip";

const Logger = Log.group(LOG_GROUP.Editables, "RiskLinksAttribute");
export default class BaseRiskLinksAttribute extends BaseLinkedEntitiesAttribute {
  constructor(props, widgets) {
    super(props, widgets);

    this.props.parent.addOnDataReceivedListener(() => {
      if (this.getRMP() && this._isMounted) {
        this.refDataTable.clear();
        this.reloadDataTable(this.preProcessDataForTable());
      }
    }, this);

    this.errorMessages.editedRowPending = "At least one risk link is in edit state. Please save or cancel all risk links in edit state.";
  }

  useUncertainty() {
    let rmp = this.getRMP();
    if (!rmp) {
      return false;
    }

    return !rmp.useUncertainty;
  }

  getMaxCriticality() {
    let riskRows = this.getRowsForCriticalityCalculations();
    return getMaxCriticality(riskRows, this.getRMP());
  }

  getOldMaxCriticality() {
    let riskRows = this.getOldRowsForCriticalityCalculations();
    return getMaxCriticality(riskRows, this.props.parent.getOlderRMP());
  }

  getRowsForCriticalityCalculations() {
    let riskRows;
    if (this.isDiffingVersions()) {
      riskRows = [];
      for (let linkObject of this.props.linkObject) {
        let riskRowsForLinkedObject = this.getValueAsObject(linkObject);
        if (riskRowsForLinkedObject) {
          riskRows = riskRows.concat(riskRowsForLinkedObject);
        }
      }
    } else {
      riskRows = [];
      for (let row of this.getValueAsObject()) {
        // Find the rows that aren't being edited
        if (!this.editedRows.find(editedRow => editedRow.uuid === row.uuid)) {
          riskRows.push(row);
        }
      }
      riskRows = riskRows.concat(this.editedRows);
    }
    return riskRows;
  }

  getOldRowsForCriticalityCalculations() {
    let riskRows = [];
    for (let linkObject of this.props.linkObject) {
      let oldRows = this.getOldValue(this.getParentStateVersionsName(linkObject));
      if (oldRows) {
        riskRows = riskRows.concat(oldRows);
      }
    }

    return riskRows;
  }

  prepareRiskValuesForDisplay(rows, rmp = null) {
    if (!rows) {
      return [];
    }

    //We need to deep clone both the arrays and objects within here, otherwise we will change the source data values
    //with the labels.
    let copiedRows = UIUtils.deepClone(rows);
    if (!rmp) {
      rmp = this.getRMP();
    }

    if (!rmp) {
      return [];
    }

    if (copiedRows instanceof Array) {
      for (let row of copiedRows) {
        for (const key of Object.keys(row)) {
          if (key === "impact") {
            row.impact = getRiskLabel(RISK_TYPE_ENUM.IMPACT, rmp, row.impact, null, false, false);
          } else if (key === "uncertainty") {
            row.uncertainty = getRiskLabel(RISK_TYPE_ENUM.UNCERTAINTY, rmp, row.uncertainty, null, false, false);
          }
        }
      }
    }

    return copiedRows;
  }

  getEffectOptions(field, rowData) {
    const allRiskLinks = (this.props.risksFromAllRecords || []).concat(this.getRiskLinksFromState());
    let availableRiskEffects = new Set(allRiskLinks.map(range => range.effect).concat(RISK_TYPE_EFFECTS));
    const options = [...availableRiskEffects].sort();
    const currentItemValue = this.getTypeaheadInput(rowData, field)?.value;
    if (currentItemValue && !availableRiskEffects.has(currentItemValue)) {
      options.push(currentItemValue);
    }

    const finalOptions = options.filter(item => item).map(item => ({label: item, id: item}));
    return finalOptions;
  }

  getRiskLinksFromState() {
    const {parent} = this.props;
    let risks = [];
    const modelName = parent.state.modelName;
    const keys = Object.keys(parent.state).filter(key => key.indexOf(modelName + "To") != -1 && key.indexOf("LinkedVersions") === -1);
    keys.forEach(key => {
      if (parent.state[key] && Array.isArray(parent.state[key])) {
        risks = risks.concat(parent.state[key]);
      }
    });

    return risks;
  }

  getTypeaheadInput(rowData, field) {
    let input;
    if (field.inputType === "typeahead") {
      const typeahead = this.getTypeaheadField(rowData, field);
      input = typeahead?.getInput();
      Logger.verbose("Typeahead input: ", Log.object(input));
    }
    return input;
  }

  getDefaultImpactOption() {
    const rmp = this.getRMP();
    if (!rmp) {
      return;
    }

    return RiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.IMPACT, rmp);
  }

  getImpactOptions() {
    let options = [];
    let rmp = this.getRMP();
    if (rmp) {
      options = rmp.RMPToImpactLinkedVersions.map(impact =>
        <option key={impact.riskScore}
                value={impact.riskScore}
        >
          {impact.riskScore + ". " + impact.scoreLabel}
        </option>
      );
    }

    return options;
  }

  getDefaultUncertaintyOption() {
    let rmp = this.getRMP();
    if (!rmp) {
      return;
    }

    return RiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.UNCERTAINTY, rmp);
  }

  getUncertaintyOptions() {
    let rmp = this.getRMP();
    if (!rmp) {
      return [];
    }

    return rmp.RMPToUncertaintyLinkedVersions.map(uncertainty =>
      <option value={uncertainty.riskScore}
              key={uncertainty.riskScore}
      >
        {uncertainty.riskScore + ". " + uncertainty.scoreLabel}
      </option>
    );
  }

  /**
   * Adds a new row to the rows collection managed by this attribute and assigns the default value for each field
   * as specified in the WIDGET_FIELDS.
   * @param editedRow Optional row provided by a child class, where it is already initialized..
   */
  handleAddToList(editedRow) {
    const rmp = this.getRMP();

    if (rmp) {
      editedRow = editedRow ? editedRow : {};
      editedRow.uuid = this.generateUUID(editedRow);
      editedRow.impact = rmp.RMPToImpactLinkedVersions[rmp.RMPToImpactLinkedVersions.length - 1].riskScore;
      editedRow.uncertainty = rmp.RMPToUncertaintyLinkedVersions[rmp.RMPToUncertaintyLinkedVersions.length - 1].riskScore;

      super.handleAddToList(editedRow);
    }
  }

  getFieldTooltipText(field) {
    let rmp = this.getRMP();
    if (rmp) {
      if (field.fieldName === "impact") {
        return getRiskFieldTooltip(RISK_TYPE_ENUM.IMPACT, rmp);
      } else if (field.fieldName === "uncertainty") {
        return getRiskFieldTooltip(RISK_TYPE_ENUM.UNCERTAINTY, rmp);
      } else if (field.fieldName === "criticality") {
        let allRows = this.getValueAsObject().map(row => {
          let newRow = UIUtils.deepClone(row);
          let selectedOption = this.getLinkSelectedOption(row);
          newRow.name = selectedOption ? selectedOption.label : "";
          return newRow;
        });
        return getRiskScaleTooltip(RISK_TYPE_ENUM.CRITICALITY, rmp, {riskLinks: allRows}, true, true, true);
      }
    }
    return super.getFieldTooltipText(field);
  }

  reloadDataTable(rowData) {
    // Reset the column headers, in case the RMP has changed between diffing version.
    if (this.refTable) {
      const table = $(this.refTable.current).DataTable();
      this.initializeColumns();

      // created an index for all columns, ignoring first 2 columns
      const columnIndexes = table.columns()[0].reduce((previousValue, currentValue) => {
        if (currentValue >= 2) {
          previousValue.push(currentValue);
        }
        return previousValue;
      }, []);
      this.setColumnTitles(table, columnIndexes);
    }
    super.reloadDataTable(rowData);
  }

  setColumnTitles(table, columnIndexes) {
    for (const columnIndex of columnIndexes) {
      $(table.column(columnIndex).header()).html(this.columns[columnIndex]?.title);
    }
  }

  getColumn(field, result, type) {
    let column;
    if (field.fieldName === "impact" || field.fieldName === "uncertainty" || field.fieldName === "criticality") {
      let fieldValue = field.getValue(result);

      if (type === "display") {
        column = ReactDOMServer.renderToString(
          <span>{fieldValue}</span>
        );
      } else {
        if (this.isDiffingVersions() && result.diffState && fieldValue[0]) {
          // A bit of a hack to get the field of the first component rendered
          column = UIUtils.parseInt(fieldValue[0].props.children);
        } else {
          column = UIUtils.parseInt(fieldValue);
        }
      }
    } else {
      column = super.getColumn(field, result, type);
    }

    return column;
  }

  getColumnDef(field, rowData) {
    // we do not use RiskInfo because we calculate criticality at link level (not entire record)
    let columnDef = super.getColumnDef(field, rowData);

    if (this.isView()) {
      if (field.fieldName === "impact") {
        const scale = rowData.riskInfo?.Impact?.scale;
        if (!this.isRowInEditMode(rowData.uuid) && !this.isDiffingVersions() && scale) {
          const title = scale.scoreLabel + (scale.alwaysCritical ? " - Always Critical" : "");
          columnDef = <Fragment>
            <div className="scoreInfo">
              <div className="value">{scale.riskScore}</div>
              <div className={"list-table-risk-label " + getCSSClassForLegend(scale.color)}></div>
              <div className="label">{scale.scoreLabel}</div>
            </div>

          </Fragment>;
        }
      }

      if (field.fieldName === "uncertainty") {
        const scale = rowData.riskInfo?.Uncertainty?.scale;
        if (!this.isRowInEditMode(rowData.uuid) && !this.isDiffingVersions() && scale) {
          columnDef = <Fragment>
            <div className="scoreInfo">
              <div className="value">{scale.riskScore}</div>
              <div className={"list-table-risk-label " + getCSSClassForLegend(scale.color)}></div>
              <div className="label">{scale.scoreLabel}</div>
            </div>
          </Fragment>;
        }
      }

      if (!columnDef && field.fieldName === "criticality") {
        const criticalityInfo = rowData.riskInfo?.Criticality;
        if (!this.isRowInEditMode(rowData.uuid) && !this.isDiffingVersions() && criticalityInfo) {
          const scale = criticalityInfo.scaleForRiskLabel;
          const isOverwritten = scale && scale.rule && scale.rule !== RiskUtils.CRITICALITY_RULES.MAXIMUM;

          columnDef = <Fragment>
            <div className="scoreInfo">
              <div className="value">{criticalityInfo.value}</div>
              <div className={"list-table-risk-label " + getCSSClassForLegend(scale.color)}></div>
              <div className="label">{scale.riskLabel}</div>
            </div>
            {
              isOverwritten && (<LabelTooltip id={rowData.uuid + "Subscript"}
                                              tooltipText={RiskUtils.getCriticalityOverrideTooltip(scale.rule, scale.riskLabel)}
                                              text="Overridden"
                                              className="control-subscript"
                                              noColon
              />)
            }
          </Fragment>;
        }
      }
    }

    if (!columnDef && field.inputType === "criticality") {
      columnDef = (
        !this.isRowInEditMode(rowData.uuid) ?
          (<div className="links-manage">
                  <span
                    id={this.props.name + "_Criticality_" + rowData.index}
                  >{this.isDiffingVersions() && rowData.diffState ? rowData.criticality : getRawRiskScore(RISK_TYPE_ENUM.CRITICALITY, this.getRMP(), rowData, false, false, true)}</span>
          </div>)
          :
          (
            <div id={this.props.name + "_CriticalityFormGroup_" + rowData.index}
                 className="form-group"
            >
              <div className="links-manage">
                <input type="number"
                       className="form-control"
                       id={this.props.name + "_Criticality_" + rowData.index}
                       value={getRawRiskScore(RISK_TYPE_ENUM.CRITICALITY, this.getRMP(), this.getEditedRowByUUID(rowData.uuid), false, false, true)}
                       readOnly
                />
              </div>
            </div>
          )
      );
    }

    return columnDef;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      return JSON.stringify(nextProps.risksFromAllRecords) !== JSON.stringify(this.props.risksFromAllRecords);
    }

    return true;
  }

  preProcessDataForTable() {
    if (this.isDiffingVersions()) {
      let diffedRowData = [];

      let olderRMP = this.props.parent.getOlderRMP();

      for (let i = 0; i < this.props.linkObject.length; i++) {
        let linkObject = this.props.linkObject[i];
        let linkToObject = this.props.linkToObject[i];
        let oldValue = this.prepareRiskValuesForDisplay(this.getOldValue(this.getParentStateVersionsName(linkObject)), olderRMP);
        let currentValue = this.prepareRiskValuesForDisplay(this.getValueAsObject(linkObject));

        diffedRowData = diffedRowData.concat(createRowDataDiffForRiskLinks(this.props.name,
          oldValue,
          currentValue,
          this.widgetFields,
          this.getLinkToId(linkToObject),
          this.handleFileDownload,
          this.getRMP(),
          olderRMP));
      }

      for (let i = 0; i < diffedRowData.length; i++) {
        diffedRowData[i].index = i;
      }

      return diffedRowData;
    } else {
      return this.prepareRiskValuesForDisplay(this.getValueAsObject());
    }
  }

  /**
   * @inheritDoc
   */
  showRecordValidationErrors(editedRow, errorReport) {
    let index = editedRow.index;
    if (index >= 0) {
      let message;
      let errorReportKey = "";
      const errorDiv = $(`#${this.props.name}_LinkTo_${index}ErrorDiv`);
      const displayName = this.getLinkToObjectDisplayName();

      if (errorReport.has("unique")) {
        errorReportKey = "unique";
      } else if (errorReport.has("required")) {
        errorReportKey = "required";
      }

      if (errorReportKey) {
        const errors = errorReport.get(errorReportKey);
        if (errors && errors.find(err => err.field.inputType === "linkedTypeahead")) {
          message = errorReportKey === "unique"
            ? `${displayName} must be unique.`
            : `${displayName} is required.`;
        }
      }

      if (message) {
        errorDiv.text(message);
        errorDiv.show();

        const formGroupDiv = $(`#${this.props.name}_LinkToFormGroup_${index}`);
        formGroupDiv.addClass("has-error");
      }
    }
  }

  getFieldValueInRow(field, row) {
    let result = null;
    if (row) {
      result = super.getFieldValueInRow(field, row);
      const item = row.selectedOption || row;

      // if a result is not found, tries to get using
      // the linkToObject and linkToObjectId fields
      if (!result) {
        let idFields = this.props.linkToObjectId;

        if (!idFields || idFields.length === 0) {
          if (this.props.linkToObject) {
            idFields = this.props.linkToObject.map(objToLink => objToLink + "Id");
          } else {
            idFields = [];
          }
        }

        if (!idFields || !idFields.length) {
          Logger.verbose(() => "ID Fields: ", idFields, this.props);
        }

        for (let i = 0; i < idFields.length; i++) {
          let fieldName = idFields[i];
          result = item[fieldName];

          let typeCode = this.props.linkToObject[i] || item.typeCode;
          if (result && typeCode) {
            result = `${typeCode}-${result}`;
          }

          Logger.verbose(
            "Attempting to get ID:",
            fieldName,
            item,
            result,
          );
          if (result) {
            break;
          }
        }
      }
    }
    return result;
  }

  getRMP() {
    if (!this.props.RMP || !this.props.projectWithAllVersions) {
      return;
    }

    const instance = {...this.props.parent.state};
    return getEffectiveRMPByModelName(this.props.projectWithAllVersions, this.props.RMP.approvedVersionsWithDetails, instance, this.props.modelName, this.isEdit());
  }


}
