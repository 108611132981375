import BasePage from "../../base_page";
import * as UIUtils from "../../ui_utils";
import * as FailHandlers from "../../utils/fail_handlers";
import React, { Fragment } from "react";
import { SyncUtils } from "./sync/sync_util";
import { LibraryHelper } from "../helpers/library_helper";
import CommonEditablesPageTitleBar from "../../widgets/pageTitleBar/common_editables_page_title_bar";
import { MaterialAttributesReconciliation } from "./steps/components/material_attributes_reconciliation_table";

/**
 * This contains common methods to usync and unlink material attributes from library
 *  @abstract
 */
export default class ProjectMaterialBasePage extends BasePage {
  constructor(props) {
    super(props);

    this.setStateSafely({
      material: null,
    });
  }

  componentDidMount() {
    super.componentDidMount();
    this.loadData();
  }

  loadData() {
    this.setStateSafely({isLoading: true});
    const urlParameters = {
      includeHistory: true,
      loadFullHistory: true,
      includeChildRecords: true,
    };

    const materialId = UIUtils.getParameterByName("materialId");
    UIUtils.secureAjaxGET(
      `editables/Material/${materialId}`,
      urlParameters,
      true,
      FailHandlers.defaultStopLoadingFailFunction.bind(this)).then(results => this.handleResultsReceivedFromServer(results));
  }

  handleResultsReceivedFromServer(result) {
    const libraryMaterial = result.fromLibrary;
    libraryMaterial.id = libraryMaterial.LibraryMaterialId;
    libraryMaterial.Specifications = libraryMaterial.specifications.map(spec => {
      return {
        ...spec,
        LibraryMaterialId: libraryMaterial.LibraryMaterialId,
        specificationId: spec.recordId,
      };
    });

    this.setStateSafely({
      material: result,
      libraryMaterial,
    });
  }

  // eslint-disable-next-line no-unused-vars
  getTitle(material) {
    throw Error("You must override this method.");
  }

  getSubtitle() {
    throw Error("You must override this method.");
  }

  getSpecificationSubtitle() {
    throw Error("You must override this method.");
  }

  getAction() {
    throw Error("You must override this method.");
  }

  getActionButtonLabel() {
    throw Error("You must override this method.");
  }

  handleOnExit() {
    throw Error("You must override this method.");
  }


  isLinkIconShowing() {
    return true;
  }

  renderPage() {
    const {material, libraryMaterial} = this.state;

    if (!material || !libraryMaterial) {
      return (
        <Fragment>
          <div className="skeleton">Loading...</div>
        </Fragment>);
    }

    const title = this.getTitle(material);
    const materialAttributes = SyncUtils.getMaterialAttributes(material, false);
    const specifications = SyncUtils.addMatchingMaterialAttribute(libraryMaterial.Specifications, SyncUtils.getMaterialAttributes(material));

    return (
      <Fragment>
        <CommonEditablesPageTitleBar name={title} />
        <div className="container-spacer" />
        <div className="container material-library-wizard-div min-vh-100">
          <div className="row-white material-library-wizard-steps-container">
            <div className="col-12 material-library-wizard-step-inner-container material-options-container">
              <div className="row">
                <div className="col">
                  <h2>Material</h2>
                  <div>
                    <p> {this.getSubtitle()} </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="material-attributes-container">
                    <h2>Material Attributes</h2>
                    <p>{this.getSpecificationSubtitle()}</p>
                    <MaterialAttributesReconciliation
                      readonly={true}
                      dataSource={specifications}
                      dropDownSource={materialAttributes}
                      isShowIcon={this.isLinkIconShowing()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row action-buttons-box">
              <div className="col">
                <button className="btn btn-lg btn-primary float-right"
                        id={this.getAction().toLowerCase() + "Button"}
                        onClick={() => this.onActionButtonClick(material, libraryMaterial)}
                >
                  {this.getActionButtonLabel()}
                </button>
                <button id="exitButton" className="btn btn-lg btn-secondary float-left" onClick={this.handleOnExit}>
                  Exit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  onActionButtonClick(material, libraryMaterial) {
    LibraryHelper.onActionClick(
      () => LibraryHelper.preprocessSyncRequestCallback({
        ...this.state,
        material,
        libraryMaterial,
      }), LibraryHelper.redirectToMaterial, this.getAction());
  }
}