"use strict";
import ReactDOM from "react-dom/server";
import React from "react";
import { Log, LOG_GROUP } from "../../../../server/common/logger/common_log";
import { Ensure } from "../../../../server/common/generic/common_ensure";
import pdfJS from "./pdf_js_bridge";

// eslint-disable-next-line no-unused-vars
const Logger = Log.group(LOG_GROUP.Documents, "BasePDFObjectBuilder");

/**
 * @type {pdfJS.viewer.EventBus}
 */
const DEFAULT_EVENT_BUS = new pdfJS.viewer.EventBus();

/**
 * This class provides base functionality for a class that will build a PdfJS objects to be used within
 * the application.
 *
 * Those objects may be the viewer class, the document class and other classes that we use.
 * Since those components use the DOM directly, we need to have some compatibility layer to be able to run them
 * on React. That's why those classes exist.
 * @class
 * @template R {*}
 * @template E {HTMLElement|*}
 * @abstract
 */
export class BasePDFObjectBuilder {

  /**
   * @param container {React.RefObject<E>} The container in which the DOM objects will be created.
   * @param [instance] {R} An instance with the default values that will be used to build
   * the result of this builder.
   */
  constructor(container, instance = {}) {
    Ensure.that({container}).isNotFalsy();

    /**
     * The page instance being built by this builder
     * @type {R}
     * @protected
     */
    this._instance = instance;

    /**
     * @type {React.RefObject<E>}
     * @protected
     */
    this.containerRef = container || React.createRef();

    /**
     *
     * @type {Map<string, IPDFPageViewerElement>}
     * @protected
     */
    this.children = new Map();


    /**
     * @type {pdfJS.viewer.EventBus}
     * @private
     */
    this.eventBus = DEFAULT_EVENT_BUS;
  }

  /**
   * Retrieves the instance that is being built (this is protected so that only this class and its subclasses
   * can see it.
   * @returns {R}
   * @protected
   */
  get instance() {
    return this._instance;
  }

  /**
   * Retrieves
   * @returns {E}
   * @protected
   */
  getContainer() {
    return this.containerRef.current;
  }

  /**
   * @returns {pdfJS.viewer.EventBus}
   */
  getEventBus() {
    return this.eventBus;
  }

  /**
   * Sets the {@link pdfJS.viewer.EventBus} instance used to handle PDF events
   * @param eventBus
   */
  setEventBus(eventBus) {
    Ensure.that({eventBus}).isNotFalsy();

    this.eventBus = eventBus;
  }

  /**
   * @param {JSX.Element} component
   * @returns {JQuery}
   * @protected
   */
  renderToJQuery(component) {
    return $(ReactDOM.renderToString(component));
  }

  /**
   * Registers a child element of a PDF page viewer
   * @param pageViewerElement {IPDFPageViewerElement}
   * @returns {this} This instance of the builder, so you can chain calls to it.
   * @protected
   */
  registerChildElement(pageViewerElement) {
    this.children.set(pageViewerElement.name, pageViewerElement);
    return this;
  }

  /**
   * Merges the specified default values with the values specified by the caller and the specified props.
   * @param defaults {!IPDFPageViewerElement}
   * @param [values] {!IPDFPageViewerElement}
   * @param [props] {*}
   * @returns {!IPDFPageViewerElement}
   * @protected
   */
  combineElementWithDefaults(defaults, values = undefined, props = {}) {
    Ensure.that({defaults}).isNotFalsy();

    /**
     * @type {IPDFPageViewerElement}
     */
    const result = {
      ...defaults,
      ...(values || {}),
    };
    result.props = {
      ...(result.props || {}),
      ...props,
    };
    Logger.verbose("Registering child element: ", Log.object(result));
    return result;
  }

  /**
   * Returns the viewer child element with the specified name
   * @param name
   * @returns {IPDFPageViewerElement}
   * @protected
   */
  getChildElement(name) {
    Ensure.that({name}).isNotFalsyNorWhiteSpaceString();

    // noinspection JSValidateTypes
    return this.children.get(name);
  }

  /**
   * Adds a child element to the specified parent container
   * @param element {!IPDFPageViewerElement}
   * @returns {this}
   * @protected
   */
  ensureChildExists(element) {
    Ensure.that({element}).isNotFalsy();
    let {
      name,
      component,
      props,
      parent,
      selector,
    } = element;

    let page = this.instance;

    let child;
    let parentElement;

    if (!page.shouldRender && page[name]) {
      child = page[name];
    } else if (parent) {
      parentElement = page[parent.name];
      Ensure.that({parentElement}).isNotFalsy(`The parent for element "${name}" must be truthy.`);
      const selectorValue = typeof selector === "function" ? selector(page) : selector;
      child = parentElement.find(selectorValue);
    } else {
      child = null;
    }

    if (!child[0]) {
      child = this.renderToJQuery(React.createElement(component, props));

      if (parentElement) {
        parentElement.append(child);
      }
    }
    page[name] = child;

    return this;
  }

  /**
   * Executes the action defined on the specified function for the specified child element.
   * @param element {!IPDFPageViewerElement}
   * @param action {function (child: JQuery<HTMLElement>): void}
   * @returns {this}
   * @protected
   */
  executeForChild(element, action) {
    Ensure.that({element, action}).areNotFalsy();
    Ensure.that({action}).isOfType(Ensure.TYPES.FUNCTION);

    let page = this.instance;
    let child = page[element.name];
    action(child);
    return this;
  }

  /**
   * Retrieves the final instance built by this builder.
   * @returns {R}
   */
  build() {
    return this.instance;
  }
}
