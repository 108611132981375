"use strict";

import * as UIUtils from "../../../ui_utils";
import React, { Fragment } from "react";
import ImportWizardNavBar from "./import_wizard_nav_bar";
import StepWizard from "react-step-wizard";
import { IMPORT_STEPS } from "../../constants/import_constants";
import ConfirmationPopup from "../../../widgets/generic/confirmation_popup";
import ETLData from "../smartUpload/extractAndEditWizardStep/etl_data";
import ImportFileSelectionStep from "../../importWizardSteps/import_file_selection_step";
import ImportDataReviewStep from "../../importWizardSteps/import_data_review_step";
import ImportDataStep from "../../importWizardSteps/import_data_step";
import ImportExtractAndEditStep from "../../importWizardSteps/import_extract_and_edit_step";
import ImportSmartUploadStep, { SMART_IMPORT_UPLOAD_STATUS } from "../../importWizardSteps/import_smart_upload_step";
import SmartUploadProgressPopup from "../smartUpload/smartUploadStep/progressPopup/smart_upload_progress_popup";
import { can } from "../../../utils/ui_permissions";
import BaseReactComponent from "../../../base_react_component";
import ImportContinuousDataStep from "../../importWizardSteps/import_continuous_data_step";
import GenerateTemplatePopup from "./generate_template_popup";

/**
 * This shows the import wizard. The wizard steps differ based on the import workflow which is determined by the
 * params provided to this control
 */
export default class ImportWizard extends BaseReactComponent {
  constructor(props) {
    super(props);

    const {importConfig} = this.props;
    this.state = {
      fileData: {fileName: ""},
      supportFileData: {fileName: ""},
      validationHasErrors: true,
      dataReviewRequired: false,
      records: [],
      currentImportStep: importConfig.isPaperImport ? IMPORT_STEPS.SmartUpload : IMPORT_STEPS.FileSelection,
      showConfirmationPopup: false,
      showUploadAndTextExtractPopup: false,
      showGenerateImportTemplatePopup: false,
      smartImportUploadProgressStatus: SMART_IMPORT_UPLOAD_STATUS.NOT_STARTED,
      etlData: new ETLData(),
      wizardStepsConfig: this.createWizardConfiguration(),
      hasPermissionToImport: this.hasPermissionToImport(),
      importedRecords: [],
    };
  }

  hasPermissionToImport() {
    const {importConfig} = this.props;
    const {modelNameForSecurity} = importConfig;

    return can(importConfig.securityAction, modelNameForSecurity);
  }

  handleConfirmationPopupOkButton() {
    if (this.confirmationPopup) {
      $(this.confirmationPopup).modal("hide");
    }

    const {wizardStepsConfig} = this.state;
    let stepIndex = wizardStepsConfig.find(config => config.step === this.state.stepToMoveOnConfirmation).stepIndex;
    this.wizard.goToStep(stepIndex);
  }

  handleHideConfirmationPopup() {
    this.setStateSafely({
      showConfirmationPopup: false,
      showUploadAndTextExtractPopup: false,
      showGenerateImportTemplatePopup: false,
    });
  }

  handleSmartUploadCancelled() {
    this.setStateSafely({
      smartImportUploadProgressStatus: SMART_IMPORT_UPLOAD_STATUS.CANCELLED,
    }, () => {
      let {fileData} = this.state;
      if (fileData && fileData.xhr) {
        fileData.xhr.abort();
      }

      this.handleWizardStepDataUpdated({
        stepsDisabledStatus: {
          [IMPORT_STEPS.DataExtract]: true,
          [IMPORT_STEPS.DataImport]: true
        }
      });
    });
  }

  createWizardConfiguration() {
    const {importConfig} = this.props;
    return importConfig.isPaperImport
      ? this.createSmartImportWizardConfiguration()
      : this.createExcelImportConfiguration();

  }

  createExcelImportConfiguration() {
    const {importConfig} = this.props;

    let wizardStepsConfig = [];
    let stepIndex = 1;

    wizardStepsConfig.push(...[{
      type: ImportFileSelectionStep,
      navTabId: "uploadFileNavItem",
      step: IMPORT_STEPS.FileSelection,
      disabled: false,
      stepIndex: stepIndex++,
      visible: true,
    }, {
      type: ImportDataReviewStep,
      navTabId: "reviewDataNavItem",
      step: IMPORT_STEPS.DataReview,
      disabled: true,
      stepIndex: stepIndex++,
    }, {
      type: importConfig.importContinuousData ? ImportContinuousDataStep : ImportDataStep,
      navTabId: "importNavItem",
      step: IMPORT_STEPS.DataImport,
      disabled: true,
      stepIndex: stepIndex++,
    }]);

    return wizardStepsConfig;
  }

  createSmartImportWizardConfiguration() {
    let stepIndex = 1;

    return [{
      type: ImportSmartUploadStep,
      navTabId: "smartUploadNavItem",
      step: IMPORT_STEPS.SmartUpload,
      disabled: false,
      stepIndex: stepIndex++,
      visible: true,
    }, {
      type: ImportExtractAndEditStep,
      navTabId: "etlDataNavItem",
      step: IMPORT_STEPS.DataExtract,
      disabled: true,
      stepIndex: stepIndex++,
    }, {
      type: ImportDataStep,
      navTabId: "importNavItem",
      step: IMPORT_STEPS.DataImport,
      disabled: true,
      stepIndex: stepIndex++,
    }];
  }

  getWizardSteps() {
    const {importConfig} = this.props;

    return this.state.wizardStepsConfig.map(wizardStepConfig => {
      let stepName = UIUtils.stripAllWhitespaces(wizardStepConfig.step);

      return React.createElement(wizardStepConfig.type,
        {
          key: stepName,
          ref: (step) => {
            return this[stepName] = step;
          },
          config: wizardStepConfig,
          onWizardStepDataUpdated: this.handleWizardStepDataUpdated,
          onImportCompleted: this.props.onImportCompleted,
          handleMoveToStep: this.handleMoveToStep,
          ...this.props,
          ...importConfig,
          ...this.state,
        });
    });
  }

  handleMoveToStep(step) {
    const {wizardStepsConfig} = this.state;
    let newStepIndex = wizardStepsConfig.find(config => config.step === step).stepIndex;
    let currentStepName = UIUtils.stripAllWhitespaces(this.state.currentImportStep);
    this[currentStepName].deactivate(newStepIndex);
  }

  handleWizardStepChange(changeInfo) {
    const {wizardStepsConfig} = this.state;
    let stepConfig = wizardStepsConfig.find(config => config.stepIndex === changeInfo.activeStep);
    let stepName = UIUtils.stripAllWhitespaces(stepConfig.step);
    this[stepName].activate(changeInfo);
  }

  handleWizardStepDataUpdated(params, callback) {
    let newState = {};

    for (let param in params) {
      if (Object.prototype.hasOwnProperty.call(params, param)) {
        if (param === "stepsDisabledStatus") {
          let wizardStepsConfig = UIUtils.deepClone(this.state.wizardStepsConfig);
          let disabledStepsStatus = params[param];

          for (let step in disabledStepsStatus) {
            if (Object.prototype.hasOwnProperty.call(disabledStepsStatus, step)) {
              let stepConfiguration = wizardStepsConfig.find(wizardStepConfig => wizardStepConfig.step === step);
              stepConfiguration.disabled = disabledStepsStatus[step];
            }
          }
          newState.wizardStepsConfig = wizardStepsConfig;
        } else {
          newState[param] = params[param];
        }
      }
    }

    this.setStateSafely(newState, callback);
  }

  render() {
    const {importConfig, project} = this.props;
    /* The user selected process can come either from the props of the wizard when it is preselected before the wizard
       is opened, or from process selection drop down on the file selection wizard step. The process selection drop
       down has of course precedence over any preselected process.
     */
    const process = this.state.process || this.props.process;
    const {
      currentImportStep,
      showConfirmationPopup,
      showUploadAndTextExtractPopup,
      confirmationMessage,
      selectedBatch,
      wizardStepsConfig,
      textractMessage,
      totalPages,
      smartImportUploadProgressStatus,
      isPDF,
      showGenerateImportTemplatePopup
    } = this.state;
    const {dependency} = importConfig;

    return (
      <Fragment>
        <StepWizard nav={<ImportWizardNavBar parent={this}
                                             wizardStepsConfig={wizardStepsConfig}
                                             currentImportStep={currentImportStep}
                                             onTabNavItemClicked={this.handleMoveToStep}
                                             dependency={dependency}
        />}
                    instance={wizard => this.wizard = wizard}
                    onStepChange={this.handleWizardStepChange}
                    transitions={
                      {
                        enterRight: "",
                        enterLeft: "",
                        exitRight: "",
                        exitLeft: ""
                      }
                    }
                    isLazyMount={true}
        >
          {this.getWizardSteps()}
        </StepWizard>
        {showConfirmationPopup ?
          <ConfirmationPopup modalRef={confirmationPopup => this.confirmationPopup = confirmationPopup}
                             headerText={"Confirmation Required"}
                             message={confirmationMessage}
                             showCancelButton={true}
                             onOkButtonClick={this.handleConfirmationPopupOkButton}
                             onHideConfirmationPopup={this.handleHideConfirmationPopup}
          />
          : ""}
        {showUploadAndTextExtractPopup ?
          <SmartUploadProgressPopup modalRef={confirmationPopup => this.confirmationPopup = confirmationPopup}
                                    headerText={"Working on it..."}
                                    isPDF={isPDF}
                                    totalPages={totalPages}
                                    progressStatus={smartImportUploadProgressStatus}
                                    textractMessage={textractMessage}
                                    onUploadCancelled={this.handleSmartUploadCancelled}
                                    onHideConfirmationPopup={this.handleHideConfirmationPopup}
          />
          : ""}
        {showGenerateImportTemplatePopup
          ? <GenerateTemplatePopup modalRef={importTemplatePopup => this.importTemplatePopup = importTemplatePopup}
                                   modelName={importConfig.modelName}
                                   projectId={project.id}
                                   processId={process.id}
                                   selectedBatch={selectedBatch}
                                   importConfig={importConfig}
                                   enableImportConfigSelection={false}
                                   parent={this}
                                   onHidePopup={this.handleHideConfirmationPopup}
          />
          : ""}
      </Fragment>
    );
  }
}
