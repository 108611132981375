"use strict";

import React from "react";

import { BasePDFObjectBuilder } from "./base_pdf_object_builder";
import { Log, LOG_GROUP } from "../../../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Documents, "PDFDocumentBuilder");

/**
 * @typedef IPDFRenderDocument
 * @property isRendered {boolean}
 * @property container {JQuery}
 * @property viewerArea {JQuery}
 * @property pageCount {number}
 * @property maxPages {number}
 * @property zoomLevel {number}
 * @property isVisible {boolean}
 * @property cancellationHandler {function() : boolean}
 * @property pageWidth {number|string}
 * @property maxPageWidth {number|string}
 * @property minPageWidth {number|string}
 * @property scrollBarWidth {number}
 * @property viewAreaPadding {number}
 * @property shouldRender {boolean}
 * @property renderTextLayer {boolean}
 * @property onDocumentRenderedHandler {function(status): void}
 * @property onPageRenderedHandler {function(status): void}
 * @property onRenderingHandler {function(status): void}
 * @property onCalculateHeightHandler {function(status): number}
 * @property onCalculateWidthHandler {function(status): number}
 * @property onReadyHandler {function(IDocumentHandler): Promise<void>|void}
 * @property cancelled {boolean}
 */

/**
 * Creates the necessary objects and prepares the DOM to be able to render a PDF document.
 * @extends BasePDFObjectBuilder<ICachedPDFDocument, HTMLElement>
 */
export class PDFDocumentBuilder extends BasePDFObjectBuilder {
  /**
   * @param container {React.RefObject<HTMLElement>} The root container in which the PDF will be rendered to.
   */
  constructor(container) {
    super(container, {});

    /**
     * Indicates whether or not the current document has been rendered already.
     * @private
     * @type {boolean}
     */
    this.isRendered = false;

    /**
     * The PDF document being built to be rendered.
     * @private
     * @type {?ICachedPDFDocument}
     */
    this.cachedDocument = null;

    /**
     * @private
     * @type {React.RefObject<HTMLElement>}
     */
    this.viewerAreaRef = React.createRef();

    /**
     * The maximum number of pages to render.
     * @type {number}
     * @private
     */
    this.maxPages = Number.MAX_SAFE_INTEGER;

    /**
     * A callback that checks whether the operation should be cancelled.
     * @returns {boolean}
     * @private
     */
    this.cancellationHandler = () => false;

    /**
     * A callback that informs when the rendering progress changed.
     * @private
     */
    this.onRenderingHandler = (ignoredStatus) => {
      // does nothing by default
    };

    /**
     * A callback that informs when the document has finished being rendered.
     * @private
     */
    this.onDocumentRenderedHandler = (ignoredStatus) => {
      // does nothing by default
    };

    /**
     * A callback that informs when a page has finished being rendered.
     * @private
     */
    this.onPageRenderedHandler = (ignoredStatus) => {
      // does nothing by default
    };

    /**
     * A callback that calculates the height of the container
     * @private
     */
    this.onCalculateHeightHandler = (ignoredStatus) => {
      return "100%";
    };

    /**
     * A callback that will be raised after the first rendering iteration happens.
     * @private
     */
    this.onReadyHandler = (ignoredStatus) => {
    };

    /**
     * A callback that calculates the width of the container
     * @private
     */
    this.onCalculateWidthHandler = (target = this) => {
      const container = target.containerRef ? target.containerRef.current : this.containerRef.current;
      const $container = $(container);
      return $container.innerWidth() - target.scrollBarWidth - (target.viewAreaPadding * 2);
    };

    /**
     * The width that the pages will be displayed with.
     * @type {string|number}
     * @private
     */
    this.pageWidth = "100%";

    /**
     * The maximum width that the pages can be displayed with.
     * @private
     * @type {number}
     */
    this.maxPageWidth = 1024;

    /**
     * The minimum width that the pages can be displayed with.
     * @private
     * @type {number}
     */
    this.minPageWidth = 120;

    /**
     * The width of the scrollbars.
     * @private
     * @type {number}
     */
    this.scrollBarWidth = 16;

    /**
     * The padding of the view area.
     * @type {number}
     * @private
     */
    this.viewAreaPadding = 20;

    /**
     * Toggles the rendering of the PDF text layer
     * @type {boolean}
     */
    this.renderTextLayer = true;
  }

  /**
   * Sets the maximum width that a page can be displayed with.
   * @param value
   * @returns {this}
   */
  setMaxPageWidth(value) {
    this.maxPageWidth = value;
    return this;
  }

  /**
   * Sets the minimum width that a page can be displayed with.
   * @param value
   * @returns {this}
   */
  setMinPageWidth(value) {
    this.maxPageWidth = value;
    return this;
  }

  /**
   * Sets the maximum number of pages to render (if the document has more than that, it will cause an error).
   * @param numberOfPages {number} The maximum number of pages to render.
   * @returns {this}
   */
  setMaxPages(numberOfPages) {
    this.maxPages = numberOfPages;
    return this;
  }

  /**
   *
   * @param isRendered
   * @returns {this}
   */
  setRenderStatus(isRendered) {
    this.isRendered = isRendered;
    return this;
  }

  /**
   * Sets the document to be rendered.
   * @param document {ICachedPDFDocument}
   * @returns {this}
   */
  setDocument(document) {
    this.cachedDocument = document;
    return this;
  }

  /**
   * Prepares the container that contains the PDF Viewer rendering area
   * @param viewerArea {React.RefObject<HTMLElement>}
   * @returns {this}
   */
  setViewerArea(viewerArea) {
    this.viewerAreaRef = viewerArea;
    return this;
  }

  /**
   * @param handlerFunction {function(): boolean}
   */
  setCancellationHandler(handlerFunction) {
    this.cancellationHandler = handlerFunction;
    return this;
  }

  setOnRenderingHandler(handlerFunction) {
    this.onRenderingHandler = handlerFunction;
    return this;
  }

  setOnPageRenderedHandler(handlerFunction) {
    this.onPageRenderedHandler = handlerFunction;
    return this;
  }

  setOnDocumentRenderedHandler(handlerFunction) {
    this.onDocumentRenderedHandler = handlerFunction;
    return this;
  }

  setOnCalculateHeightHandler(handlerFunction) {
    this.onCalculateHeightHandler = handlerFunction;
    return this;
  }

  setOnCalculateWidthHandler(handlerFunction) {
    this.onCalculateWidthHandler = handlerFunction;
    return this;
  }

  setOnReadyHandler(handlerFunction) {
    this.onReadyHandler = handlerFunction;
    return this;
  }

  /**
   * Enables or disables the rendering PDF text layer (a layer with selectable text).
   * @param value {boolean}
   * @returns {this}
   */
  setRenderTextLayer(value) {
    this.renderTextLayer = value;
    return this;
  }

  /**
   * Prepares the viewer area to receive a rendered page (cleans it if needed).
   * @param viewerArea
   * @returns {this}
   */
  prepareViewerArea(viewerArea = undefined) {
    if (viewerArea) {
      this.setViewerArea(viewerArea);
    }
    return this;
  }

  getCurrentPageWidth(target = this) {
    const container = target.containerRef ? target.containerRef.current : this.containerRef.current;
    const $container = $(container);
    const containerWidth = this.onCalculateWidthHandler(target) || target.minPageWidth;
    const width = Math.max(Math.min(containerWidth, target.maxPageWidth), target.minPageWidth);
    Logger.verbose("Calculating page width: ", Log.object(width), Log.object($container[0]), Log.object(containerWidth), Log.object(target));
    return width;
  }

  setZoomLevel(zoomLevel) {
    this.zoomLevel = zoomLevel;
    return this;
  }

  setVisibility(isVisible) {
    this.isVisible = isVisible;
    return this;
  }

  /**
   * @inheritDoc
   * @returns {ICachedPDFDocument|R}
   */
  build() {
    const builder = this;
    const renderObject = {
      ...super.build(),
      container: $(this.containerRef.current),
      viewerArea: $(this.viewerAreaRef.current),
      containerRef: this.containerRef,
      viewerAreaRef: this.viewerAreaRef,
      isRendered: this.isRendered,
      maxPages: this.maxPages,
      maxPageWidth: this.maxPageWidth,
      scrollBarWidth: this.scrollBarWidth,
      viewAreaPadding: this.viewAreaPadding,
      zoomLevel: this.zoomLevel,
      isVisible: this.isVisible,
      shouldRender: this.cachedDocument.numPages <= this.maxPages,
      pageCount: this.cachedDocument.numPages,
      renderTextLayer: this.renderTextLayer,
      cancellationHandler: () => {
        const cancelled = this.cancellationHandler();
        if (cancelled) {
          Logger.warn("Rendering cancelled: ", Log.object(renderObject));
        }
        renderObject.cancelled = cancelled;
        return cancelled;
      },
      onDocumentRenderedHandler: this.onDocumentRenderedHandler,
      onPageRenderedHandler: this.onPageRenderedHandler,
      onRenderingHandler: this.onRenderingHandler,
      onCalculateHeightHandler: this.onCalculateHeightHandler,
      onReadyHandler: this.onReadyHandler,
      cancelled: false,
      get pageWidth() {
        return builder.getCurrentPageWidth(builder);
      }
    };
    this.cachedDocument.renderObject = renderObject;
    return this.cachedDocument;
  }
}
