var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { NumericTextBox, Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { messages, filterClearButton, filterChooseOperator } from '../messages';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { cellBoolDropdownChange, cellInputChange, cellOperatorChange } from '../filterCommon';
import { classNames } from '@progress/kendo-react-common';
var GridFilterCell = /** @class */ (function (_super) {
    __extends(GridFilterCell, _super);
    function GridFilterCell(props) {
        var _this = _super.call(this, props) || this;
        _this.renderOperatorEditor = function (localization) {
            if (_this.props.filterType === 'boolean') {
                return;
            }
            var value = _this.props.operators.find(function (item) { return item.operator === _this.props.operator; }) || null;
            return (React.createElement(DropDownList, { value: value, onChange: _this.operatorChange, className: "k-dropdown-operator", iconClassName: "k-i-filter k-icon", data: _this.props.operators, textField: "text", title: localization.toLanguageString(filterChooseOperator, messages[filterChooseOperator]), popupSettings: {
                    width: ''
                } }));
        };
        _this.inputChange = _this.inputChange.bind(_this);
        _this.clear = _this.clear.bind(_this);
        _this.operatorChange = _this.operatorChange.bind(_this);
        _this.boolDropdownChange = _this.boolDropdownChange.bind(_this);
        return _this;
    }
    /**
     * @hidden
     */
    GridFilterCell.prototype.render = function () {
        var _a;
        var localizationService = provideLocalizationService(this);
        var defaultRendering = (React.createElement("div", { className: "k-filtercell" },
            React.createElement("span", null,
                this.filterComponent(this.props.filterType, this.props.value, this.props.booleanValues),
                React.createElement("div", { className: "k-filtercell-operator" },
                    this.renderOperatorEditor(localizationService),
                    "\u00A0",
                    React.createElement(Button, { icon: 'filter-clear', className: classNames((_a = {},
                            _a['k-clear-button-visible'] = Boolean(!(this.props.value === null || this.props.value === '') || this.props.operator),
                            _a)), title: localizationService.toLanguageString(filterClearButton, messages[filterClearButton]), type: "button", onClick: this.clear, disabled: !(!(this.props.value === null || this.props.value === '') || this.props.operator) })))));
        if (this.props.render) {
            return this.props.render.call(undefined, defaultRendering, this.props);
        }
        return defaultRendering;
    };
    GridFilterCell.prototype.inputChange = function (value, e) {
        cellInputChange(value, e, this.props);
    };
    GridFilterCell.prototype.operatorChange = function (event) {
        cellOperatorChange(event, this.props.value, this.props.onChange);
    };
    GridFilterCell.prototype.boolDropdownChange = function (event) {
        cellBoolDropdownChange(event, this.props.onChange);
    };
    GridFilterCell.prototype.clear = function (e) {
        e.preventDefault();
        this.props.onChange({ value: '', operator: '', syntheticEvent: e });
    };
    GridFilterCell.prototype.filterComponent = function (filterType, value, booleanValues) {
        var _this = this;
        switch (filterType) {
            case 'numeric':
                return (React.createElement(NumericTextBox, { value: value, onChange: function (e) { _this.inputChange(e.value, e.syntheticEvent); }, title: this.props.title, ariaLabel: this.props.ariaLabel }));
            case 'date':
                return (React.createElement(DatePicker, { value: value, onChange: function (e) { _this.inputChange(e.value, e.syntheticEvent); }, title: this.props.title, ariaLabel: this.props.ariaLabel }));
            case 'boolean':
                var noFilterSet_1 = function (filter) { return filter === null || filter === undefined; };
                return (React.createElement(DropDownList, { onChange: this.boolDropdownChange, value: booleanValues.find(function (item) { return item.operator === (noFilterSet_1(value) ? '' : value); }), data: booleanValues, textField: "text", title: this.props.title, ariaLabel: this.props.ariaLabel }));
            default: return (React.createElement(Input, { value: value || '', onChange: function (e) { _this.inputChange(e.target.value, e.syntheticEvent); }, title: this.props.title, "aria-label": this.props.ariaLabel }));
        }
    };
    return GridFilterCell;
}(React.Component));
export { GridFilterCell };
registerForLocalization(GridFilterCell);
