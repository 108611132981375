"use strict";

import "./side_menu_tree.module.scss";

import React from "react";
import {
  processTreeViewItems,
  TreeView,
  TreeViewExpandChangeEvent,
  TreeViewItemClickEvent,
} from "@progress/kendo-react-treeview";
import TreeItem, {TreeItemType} from "../tree_item";

type SideMenuTreeProps = {
  treeData: Array<TreeItemType>;
  // eslint-disable-next-line no-unused-vars
  onExpandChange: (event: TreeViewExpandChangeEvent) => void;
  // eslint-disable-next-line no-unused-vars
  onItemClick: (event: TreeViewItemClickEvent) => void;
};

export default function SideMenuTree(props: SideMenuTreeProps) {
  const {treeData, onExpandChange, onItemClick} = props;
  return (
    <div id="side-menu-tree">
      <TreeView
        data={processTreeViewItems(treeData, {})}
        expandIcons={true}
        aria-multiselectable={true}
        onExpandChange={onExpandChange}
        onItemClick={onItemClick}
        item={TreeItem}
      />
    </div>
  );
}
