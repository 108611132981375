var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { formatString } from '../utils';
var cellClass = 'k-ct-cell k-disabled';
var selectedClass = 'k-selected';
/**
 * @hidden
 */
var PopupGrid = /** @class */ (function (_super) {
    __extends(PopupGrid, _super);
    function PopupGrid() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { row: -1, col: -1 };
        return _this;
    }
    PopupGrid.prototype.renderCell = function (index, selRow, selCol) {
        var _this = this;
        var row = Math.floor(index / this.props.columns);
        var col = index % this.props.columns;
        var selected = col <= selCol && row <= selRow;
        return (React.createElement("span", { className: cellClass + (selected ? " ".concat(selectedClass) : ''), onMouseEnter: function () { return _this.setState({ row: row, col: col }); }, onClick: function () { return _this.props.onCellClick.call(undefined, row, col); }, key: index }));
    };
    PopupGrid.prototype.render = function () {
        var _this = this;
        var cells = [];
        var total = this.props.columns * this.props.rows;
        var _a = this.state, row = _a.row, col = _a.col;
        var onDown = this.props.onDown;
        var message = this.props.createTableMessage;
        if (col > -1) {
            message = formatString(this.props.createTableHintMessage, row + 1, col + 1);
        }
        for (var i = 0; i < total; i++) {
            cells.push(this.renderCell(i, row, col));
        }
        return [(React.createElement("div", { onMouseLeave: function () { return _this.setState({ row: -1, col: -1 }); }, key: "cells", style: { borderColor: 'inherit' }, onPointerDown: onDown, onMouseDown: onDown }, cells)), (React.createElement("div", { className: "k-status", key: "status", onPointerDown: onDown, onMouseDown: onDown }, message))];
    };
    return PopupGrid;
}(React.Component));
export default PopupGrid;
