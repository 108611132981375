"use strict";

import React from "react";
import BaseInfoTooltip from "../../widgets/tooltips/base_info_tooltip";
import * as I18NWrapper from "../../i18n/i18n_wrapper";

class LibraryStatusTooltip extends BaseInfoTooltip {

  render() {
    const {id, textDirection, status} = this.props;

    return (
      <span className={`library-status-tooltip`} id={id}
            data-toggle="popover"
            data-html="true"
            data-container="body"
            data-placement={textDirection || "top"}
            ref={popover => this.popover = popover}
            data-content={this.renderPopupContent()}
            data-boundary="viewport"
      >
       {status}
      </span>
    );
  }
}

export default I18NWrapper.wrap(LibraryStatusTooltip, "widgets");