import * as coreIntl from '@progress/kendo-intl';
import * as licensing from '@progress/kendo-licensing';
import { packageMetadata } from '../package-metadata';
/* eslint-disable max-len */
/**
 * A service which provides internationalization methods and is bound to a specific locale.
 */
var IntlService = /** @class */ (function () {
    /**
     * Creates a new instance of the internationalization service.
     *
     * @param locale - The locale that will be used by the internationalization methods.
     */
    function IntlService(locale) {
        this.locale = locale;
        if (typeof licensing !== 'undefined') {
            licensing.validatePackage(packageMetadata);
        }
        else {
            var message = "License activation failed for ".concat(packageMetadata.name, "\n");
            message += 'The @progress/kendo-licensing script is not loaded.\n';
            message += "See ".concat(packageMetadata.licensingDocsUrl, " for more information.\n");
            console.warn(message);
        }
        if (locale === '' && process.env.NODE_ENV !== 'production') {
            throw 'Locale should not be empty string';
        }
    }
    /**
     * Formats a string with placeholders such as `Total amount {0:c}`.
     *
     * @param format - The format string.
     * @param values - One or more values to output in the format string placeholders.
     * @return - The formatted string.
     */
    IntlService.prototype.format = function (format) {
        var values = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            values[_i - 1] = arguments[_i];
        }
        /* The following code retains backward compatibility with the old API */
        if (values.length === 1 && Array.isArray(values[0])) {
            return coreIntl.format(format, values[0], this.locale);
        }
        return coreIntl.format(format, values, this.locale);
    };
    /**
     * Converts a `Date` object to a string based on the specified format. If no format is provided, the default short date format is used.
     *
     * @param value - The date which will be formatted.
     * @param format - The format string or options.
     * @return - The formatted date.
     */
    IntlService.prototype.formatDate = function (value, format) {
        return coreIntl.formatDate(value, format, this.locale);
    };
    /**
     * Converts an object to a string based on the specified format.
     *
     * @param value - The value which will be formatted.
     * @param format - The format to use.
     * @return - The formatted object.
     */
    IntlService.prototype.toString = function (value, format) {
        return coreIntl.toString(value, format, this.locale);
    };
    /**
     * Converts a string to a `Number`.
     *
     * @param value - The string which will be parsed.
     * @param format - The format string or options.
     * @return - The parsed number.
     */
    IntlService.prototype.parseNumber = function (value, format) {
        return coreIntl.parseNumber(value, this.locale, format);
    };
    /**
     * Converts a string to a `Date` object based on the specified format.
     *
     * @param value - The string which will be converted.
     * @param format - The format strings or options.
     * @return - The parsed date.
     */
    IntlService.prototype.parseDate = function (value, format) {
        return coreIntl.parseDate(value, format, this.locale);
    };
    /**
     * Converts a `Number` to a string based on the specified format.
     *
     * @param value - The number which will be formatted.
     * @param format - The format string or options.
     * @return - The formatted number.
     */
    IntlService.prototype.formatNumber = function (value, format) {
        return coreIntl.formatNumber(value, format, this.locale);
    };
    /**
     * Returns a localized date field name based on specific `dateFieldName` options.
     *
     * @param options - The detailed configuration for the desired date field name.
     * @returns - The localized date field name from the current locale based on the option.
     */
    IntlService.prototype.dateFieldName = function (options) {
        return coreIntl.dateFieldName(options, this.locale);
    };
    /**
     * Returns the day names from the current locale based on the option.
     *
     * @param options - The detailed configuration for the desired date format.
     * @return - The day names from the current locale based on the option.
     */
    IntlService.prototype.dateFormatNames = function (options) {
        return coreIntl.dateFormatNames(this.locale, options);
    };
    /**
     * Splits the date format into objects which contain information about each part of the pattern.
     *
     * @param format - The format string or options.
     * @returns - The date format parts.
     */
    IntlService.prototype.splitDateFormat = function (format) {
        return coreIntl.splitDateFormat(format, this.locale);
    };
    /**
     * Returns the number symbols from the current locale.
     *
     * @return - The number symbols from the current locale.
     */
    IntlService.prototype.numberSymbols = function () {
        return coreIntl.numberSymbols(this.locale);
    };
    /**
     * Returns the first day index, starting from Sunday.
     *
     * @return - The index of the first day of the week (0 == Sunday).
     */
    IntlService.prototype.firstDay = function () {
        return coreIntl.firstDay(this.locale);
    };
    return IntlService;
}());
export { IntlService };
// tslint:enable:max-line-length
