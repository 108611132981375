"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import BasePage from "../base_page";
import EditablesPageTitleBar from "../widgets/pageTitleBar/editables_page_title_bar";
import NavBar from "../widgets/bars/nav_bar";
import ErrorBar from "../widgets/bars/error_bar";
import Heading from "../widgets/headers/heading";
import InfoTooltip from "../widgets/tooltips/info_tooltip";
import TabNavBar from "../widgets/bars/tab_nav_bar";
import { REPORT_THUMBNAILS_ENUM, REPORT_TABS_ENUM } from "./constants/report_constants";
import ReportThumbnail from "./report_thumbnail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { canViewReport } from "../utils/ui_permissions";
import UpgradeNowBanner from "../widgets/generic/upgrade_now_banner";
import CommonSecurity from "../../server/common/generic/common_security";


const numberOfRecentlyUsedReportsToShow = 6;

/* This class shows the list of reports */
export default class ReportsPage extends BasePage {

  constructor(props) {
    super(props);

    UIUtils.setLoadingDisabled(true);
    this.tabsToReportsMap = new Map();
    this.projectId = UIUtils.getParameterByName("projectId");

    REPORT_TABS_ENUM.RECENTLY_USED.isTabLoading = true;
    this.setStateSafely({
      selectedTab: REPORT_TABS_ENUM.ALL_REPORTS,
      reportTabs: REPORT_TABS_ENUM,
      filterValue: "",
      isLoading: true,
    });

    UIUtils.secureAjaxGET("reports/recently_used?projectId=" + this.projectId)
      .done(this.handleReceiveDataFromServer);

    // Initialize the report tabs
    this.handleReceiveDataFromServer(null);
  }

  componentDidMount() {
    document.title = "QbDVision Reports";

    super.componentDidMount();
  }

  /**
   * Returns a boolean indicating whether a report entry should be shown.
   * It will return <c>true</c> if:
   *  - there is no experiment,
   *  - if the experiment is NOT about REMOVING the item, it returns <c>true</c> if the experiment is ENABLED
   *  - if the experiment is about REMOVING the item, it returns <c>true</c> if the experiment is DISABLED
   * @param reportEntry {*} The report tab or thumbnail entry
   * @param dependencyObject {*} Can be any object that decides a report should be shown or not
   * @return {boolean} A boolean indicating whether the report entry should be shown.
   */
  static shouldShowReport(reportEntry, dependencyObject) {
    return !!(
      (!reportEntry.experiment
        || (
          (!reportEntry.experimentType || reportEntry.experimentType !== "REMOVE")
          && UIUtils.isExperimentEnabled(reportEntry.experiment)
        )
        || (
          reportEntry.experimentType && reportEntry.experimentType === "REMOVE"
          && !UIUtils.isExperimentEnabled(reportEntry.experiment)
        )) && (reportEntry.hideReport ? !reportEntry.hideReport(dependencyObject) : true)
    );
  }

  handleReceiveDataFromServer(results) {

    let enabledTabs = Object.values(REPORT_TABS_ENUM)
      .filter(tab => {
        return ReportsPage.shouldShowReport(tab, results && results.project);
      });

    this.tabsToReportsMap = new Map();
    for (let tab of enabledTabs) {
      this.tabsToReportsMap.set(tab.title, []);
    }

    let enabledThumbnails = Object.values(REPORT_THUMBNAILS_ENUM)
      .filter(report => {
        return ReportsPage.shouldShowReport(report, results && results.project);
      }).filter(thumb => !thumb.companyLevel);

    for (let report of enabledThumbnails) {
      this.tabsToReportsMap.get(report.tab.title).push(report);
      const allReportsTab = this.tabsToReportsMap.get(REPORT_TABS_ENUM.ALL_REPORTS.title);
      if (!allReportsTab.find(tabReport => tabReport.title === report.title)) {
        allReportsTab.push(report);
      }
    }

    for (let tab of enabledTabs) {
      tab.info = `(${this.tabsToReportsMap.get(tab.title).length})`;
    }

    //Just show the top 5 most recently used reports
    let recentlyUsedReports = (results && results.instances && results.instances.recentlyUsedReports) || [];

    let recentlyUsedReportsInTab = this.tabsToReportsMap.get(REPORT_TABS_ENUM.RECENTLY_USED.title);
    if (recentlyUsedReports.length > 0) {
      for (let recentlyUsedReport of recentlyUsedReports) {
        let report = Object.values(REPORT_THUMBNAILS_ENUM).find(report => {
          return recentlyUsedReport.reportType === report.type;
        });
        if (!recentlyUsedReportsInTab.includes(report)
          && recentlyUsedReportsInTab.length < numberOfRecentlyUsedReportsToShow
          && ReportsPage.shouldShowReport(report, null)) {
          recentlyUsedReportsInTab.push(report);
        }
      }
    }

    REPORT_TABS_ENUM.RECENTLY_USED.info = `(${recentlyUsedReportsInTab.length})`;
    const isLoading = !results || !results.project;
    REPORT_TABS_ENUM.RECENTLY_USED.isTabLoading = isLoading;
    this.setStateSafely({recentlyUsedReports, isLoading});
  }

  handleTabChanged(tab) {
    this.setStateSafely({
      selectedTab: tab
    });
  }

  handleReportFilterChange(event) {
    let filterValue = event ? event.target.value : "";

    for (let [key, value] of this.tabsToReportsMap) {
      const tab = Object.values(REPORT_TABS_ENUM).find(tab => tab.title === key);
      tab.info = "(" + value.filter(report => {
        return !filterValue || filterValue.length === 0 || report.title.toLowerCase().includes(filterValue.toLowerCase());
      }).length + ")";
    }

    this.setStateSafely({
      filterValue: filterValue,
      reportTabs: REPORT_TABS_ENUM
    });
  }

  resetSearchFilter() {
    this.handleReportFilterChange(null);
  }

  renderReportThumbnails() {
    let appliedFilter = this.state.filterValue;

    //Apply the user filter to the currently selected tab
    const selectedTab = this.tabsToReportsMap.get(this.state.selectedTab?.title);
    let reportsInTab = selectedTab ? selectedTab.filter(report => {
      return !appliedFilter || appliedFilter.length === 0 || report.title.toLowerCase().includes(appliedFilter.toLowerCase());
    }).map(report => {
      //Clone the report object from the json structure so that the one in the json structure is not
      //directly modified
      let clonedReport = Object.assign({}, report);

      //Users can see reports they have access to in top of the report's list
      if (canViewReport(clonedReport.type)) {
        clonedReport.order = -1;
      }

      if (this.state.selectedTab === REPORT_TABS_ENUM.RECENTLY_USED &&
        this.state.recentlyUsedReports) {
        //The recently used reports come from the database in the order they need to be displayed, from the most
        //recently used one to the least. Set that order on the report object.
        clonedReport.order = this.state.recentlyUsedReports.indexOf(report) + 1;
      }
      return clonedReport;
    }).sort((a, b) => {
      //Sort the reports based on their priority
      return a.order - b.order;
    }) : [];

    //Group the reports into rows, with 2 reports showing in each row
    //If user dont have access to some reports because of licensing,
    //he/she will get a banner in-between to tell him to upgrade
    let rowThumbnails;

    let viewableReportThumbnails = [];
    let viewableReport = reportsInTab.filter(report => canViewReport(report.type));
    for (let i = 0; i < viewableReport.length; i++) {
      let rowIndex = Math.floor(i / 2);
      if (!viewableReportThumbnails[rowIndex]) {
        viewableReportThumbnails[rowIndex] = [];
      }

      viewableReportThumbnails[rowIndex].push(viewableReport[i]);
    }

    let unViewableReportThumbnails = [];
    let unViewableReport = reportsInTab.filter(report => !canViewReport(report.type));
    for (let i = 0; i < unViewableReport.length; i++) {
      let rowIndex = Math.floor(i / 2);
      if (!unViewableReportThumbnails[rowIndex]) {
        unViewableReportThumbnails[rowIndex] = [];
      }

      unViewableReportThumbnails[rowIndex].push(unViewableReport[i]);
    }

    //If user has reports he can't access because of licensing
    //a banner will be inserted in-between reports he has/n't access to
    rowThumbnails = viewableReportThumbnails;
    if (unViewableReportThumbnails.length > 0) {
      rowThumbnails.push({
        title: "UPGRADE NOW"
      });
      rowThumbnails = rowThumbnails.concat(unViewableReportThumbnails);
    }

    let rowKeyCounter = 1;
    return (
      <div className="col-sm-12">
        {rowThumbnails.map(row => {
          return (
            <div className="row"
                 key={`thumbnailsReportRow_${rowKeyCounter++}`}
            >
              {row.title && row.title === "UPGRADE NOW" ?
                <UpgradeNowBanner /> :
                row.map(thumbnail => {
                  return (
                    <ReportThumbnail key={thumbnail.title}
                                     type={thumbnail.type}
                                     description={thumbnail.description}
                                     disabled={!canViewReport(thumbnail.type)}
                                     projectId={this.projectId}
                                     thumbnailSrc={thumbnail.thumbnailURL}
                                     name={thumbnail.title}
                    />
                  );
                })}
            </div>
          );
        })}
      </div>
    );
  }

  renderPage() {
    let {loggedInUser} = this.state;
    return (
      <div>
        <EditablesPageTitleBar projectId={this.projectId}
                               projectName={this.state.project ? this.state.project.name : ""}
                               projectDeletedAt={this.state.project ? this.state.project.deletedAt : ""}
                               isDemoProject={this.state.project ? this.state.project.isDemo : ""}
                               currentState={this.state.project ? this.state.project.currentState : null}
        />
        <NavBar selected="Reports"
                projectId={this.projectId}
        />
        <div className="container">
          <Heading level={1} className="header-banner">
            Reports
            <InfoTooltip id="infoReports"
                         verbiage={<div>
                           These reports should help provide insight into your overall quality. They also could help
                           regulatory bodies understand the process you went through to get to the
                           manufacturing process you have decided on.
                         </div>}
            />
          </Heading>
          <ErrorBar />
          <div className="row row-white shadow">
            <div className="col-sm-12">
              <div className="row row-white">
                <div id="reportsFilterDiv" className="col-sm-4">
                  <input type="text"
                         className="form-control"
                         id="reportsFilter"
                         value={this.state.filterValue}
                         placeholder="Search for a report"
                         onChange={this.handleReportFilterChange}
                  />
                  <FontAwesomeIcon icon={faTimes}
                                   onClick={this.resetSearchFilter}
                                   id="reportSearchClearButton"
                  />
                  <div id="reportsSearchHint"
                       className="report-searching-for"
                  >
                    {this.state.filterValue ? `Searching for "${this.state.filterValue}"` : (<br />)}
                  </div>
                </div>
              </div>
              {loggedInUser.license === CommonSecurity.UserLicenseTypes.ESSENTIAL ? "" :
                <div className="row row-white">
                  <TabNavBar selected={this.state.selectedTab}
                             onTabChanged={this.handleTabChanged}
                             tabs={UIUtils.deepClone(this.state.reportTabs)}
                             parent={this}
                             parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                             parentId={this.state.id}
                  />
                </div>}
              <div className="row row-white">
                {this.renderReportThumbnails()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
