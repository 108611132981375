"use strict";

import BaseRelatedRecordsTable from "../../../../widgets/relatedRecords/base_related_records_table";
import ReactDOMServer from "react-dom/server";
import * as UIUtils from "../../../../ui_utils";
import React from "react";

/**
 * This implements the batch panel showing batch information while reviewing continuous data in a process data import flow.
 */
export default class ContinuousDataAttributesTable extends BaseRelatedRecordsTable {
  constructor(props) {
    super(props);
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const {records} = this.props;
    const previousRecords = nextProps.records;

    return records.length !== previousRecords.length;
  }

  getTableInitializationOptions() {
    return {
      dom: "<t>",
      data: this.props.records,
      columns: this.getColumns(),
      bSort: false,
      paging: false,
      stateSave: true,
    };
  }

  getColumns() {
    return [
      {
        data: (attribute, type) => {
          if (type === "display") {
            return ReactDOMServer.renderToStaticMarkup(
              <div className="continuous-data-attribute-div">
                <div>
                  <span>{attribute.attributeID} {attribute.attributeName}</span>
                </div>
                {attribute.totalMeasurementsOFL > 0 ? (
                  <div className="continuous-measurements-ofl-div">
                    <span>{attribute.totalMeasurementsOFL}</span>
                  </div>
                ) : ""}
              </div>
            );
          } else if (type === "sort") {
            return UIUtils.getKeyForSorting(attribute.attributeID);
          } else {
            return attribute.attributeID;
          }
        }
      },
    ];
  }

  componentDidMount() {
    super.componentDidMount();

    const tBody = $(`#${this.props.id}Table tbody`);
    let table = this.table;
    let handleRowClick = this.handleRowClick;

    tBody.off("click", "tr");
    tBody.on("click", "tr", this.getRowOnClickFunction(table, handleRowClick));
  }

  getRowOnClickFunction(table, handleClick) {
    return function(e) {
      let row = table.row(this);

      if (!$(this).hasClass("selected")) {
        table.$("tr.selected").removeClass("selected");
        $(this).addClass("selected");

        handleClick(e, row);
      }
    };
  }

  initializeDataTables() {
    this.table = $(this.tableRef).DataTable({
      ...this.getTableInitializationOptions(),
      createdRow: (row) => {
        $(row).addClass("base-table-row");
      }
    });

    setTimeout(() => {
      this.reloadDataTable();
    }, 200);
  }

  handleRowClick(e, row) {
    UIUtils.ignoreHandler(e);
    this.props.onSelectedAttributeChanged(row.data());
  }

  reloadDataTable() {
    super.reloadDataTable();
    if (this.tableRef) {
      const table = $(this.tableRef).DataTable();
      if (table.row(0)) {
        $(table.row(0).node()).trigger("click");
      }
    }
  }


  render() {
    return (
      <div className="continuous-data-attributes-table">
        <table ref={ref => this.tableRef = ref}
               className="table table-hover"
               id={`${this.props.id}Table`}
               style={{width: "100%"}}
        />
      </div>
    );
  }
}
