@import "../css/_brand_colors.scss";

/* 50px of trialBar, 55px of companyHeader, 50px of title bar, 55px of toolBar,
   and 10px of padding */
/* stylelint-disable-next-line selector-id-pattern */
:global(#bodyDiv:has(.trial-bar) .document-editor-main) {
  height: calc(100vh - 50px - 55px - 50px - 55px - 10px);
}

/* remove an extra 100px for the quick panel header and title bar */
:global(#bodyDiv:has(.quick-panel-outer-container) .document-editor-main) {
  height: calc(100vh - 50px - 55px - 50px - 55px - 10px - 100px);
}

.off-screen {
  position: absolute;
  left: -10000px;
  top: 0;
  height: 10px;
}

.document-editor-main-with-side-menu {
  overflow: initial !important;
  padding: 0;
  margin: 0;
}

.document-editor-viewer-container-with-side-menu {
  overflow: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: $brand-light-grey;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

:global(.k-editor-content > div[role="textbox"]) {
  overflow: hidden;
}

.document-editor-main {
  height: calc(100vh - 55px - 50px - 55px - 10px);
  overflow: auto;
}

.document-editor-side-menu {
  background-color: $brand-white;
  overflow: auto;
  height: 100%;
}


.document-editor-toolbar {
  border-bottom: 1px solid $brand-mild-grey;
}

.container {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 40px;
}

.container-preview-only {
  width: fit-content !important;
}

.document-editor-viewer:global(.col-12) {
  width: 210mm;
  padding-left: 40px;
  padding-right: 40px;
}

.document-editor-viewer:global(.col-12-landscape) {
  width: 297mm;
  padding-left: 40px;
  padding-right: 40px;
}


:global(.document-editor-main ol) {
  list-style-type: none;
  margin: 0;
  padding: 0 0 0 30px;

  li {
    display: block;
    width: 100%;
  }

  li::before {
    content: attr(data) ".\a0";
    display: inline;
    width: 0;
  }

  li p, li h1, li h2, li h3, li h4 {
    display: inline;
  }
}

.document-editor-viewer:has(:global(#qbd-editor-preview)) {
  width: 100vh;
  overflow: hidden;
}

.document-editor-viewer {
  min-height: 100vh;

  /* If you update the font-size here, you also need to update in the src/main/client/documentEditor/constants.ts */
  h1:not(:global(.modal-title)) {
    font-size: 20pt;
  }

  h2 {
    font-size: 16pt;
    margin-top: 0;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 14pt;
    font-weight: bold;
  }

  h4 {
    font-size: 12pt;
    font-weight: bold;
  }

  p {
    font-size: 11pt;
    margin-bottom: 5px;
  }

  span[style*="block-type: title"] {
    font-size: 32pt;
  }

  span[style*="block-type: caption"] {
    font-size: 10pt;
  }
}

.document-editor-viewer > div {
  height: 100%;
}

.document-editor-pdf-viewer {
  font-variant-ligatures: initial;
  padding: 40px 10px 20px;
  display: flex;
  flex-direction: column;
  min-height: 100%;

  p {
    min-height: 12px;
  }
}

.document-preview-hidden {
  display: none;
}

.document-editor-typeahead {
  label:not(:first-child) {
    margin-left: 15px;
  }
}

.document-editor-typeahead:global(.row) {
  margin-left: 0;
}

.font-size-tool {
  margin-right: 4px;
}

.document-project-process-modal {
  .rbt-input {
    width: 100%;
  }
}

:global(#document-editor .qbd-output) {
  background-image: url("../images/logo-128.png");
  background-size: 20px 20px;
  padding-left: 22px;
  background-position-x: left;
  background-position-y: bottom;
  background-repeat: no-repeat;
  border: 1px dashed $brand-highlight-color;
}

:global(#document-editor .page-break) {
  border-top: 1px dashed $brand-mild-grey;
  height: 20px;
}

@font-face {
  font-family: "DejaVu Sans";
  src: url("../fonts/DejaVuSans.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("../fonts/DejaVuSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("../fonts/DejaVuSans-Oblique.ttf") format("truetype");
}

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("../fonts/DejaVuSans-BoldOblique.ttf") format("truetype");
}

/* Change the appearance of the content when exporting to PDF */
:global(.document-editor-main .k-pdf-export) {
  /* Telerik cannot export Unicode characters if we don't use TrueType fonts that have
   a Unicode mapping. Refer to this link for detail explanation:
   https://www.telerik.com/kendo-react-ui/components/drawing/pdf-output/embedded-fonts/ */
  font-family: "DejaVu Sans", sans-serif;

  :local(.document-editor-pdf-viewer) {
    border: none;
    height: fit-content;
  }

  img {
    /* If the image is too high, the pdf export library cannot fit it in one page
      and it will be cut off when exporting. We need to set max height to fix it */
    max-height: 750px;
  }

  :global(.page-break) {
    display: none;
  }
}

:global(.document-editor-main img) {
  /* We need to set max-width so the image won't be cut-off when exporting to PDF
    and Word */
  max-width: 100%;
}

:global(#document-editor .widget) {
  width: 100%;
}

:global(#document-editor .widget-item) {
  width: fit-content;
  background-color: $warning-color;
  padding: 0 10px;
}

:global(#document-editor .widget-content-container) {
  width: 100%;
  min-height: 50px;
  border: 1px dotted $brand-highlight-color;
  padding: 10px;
}

:global(.widget[contenteditable="false"] .widget-content-container) {
  background-color: $brand-mild-grey;
}

:global(.document-editor-main table) {
  display: table;
  box-sizing: border-box;
  border-spacing: 2px;
  border-color: grey;
  margin: 0;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  overflow: hidden;
  white-space: pre-wrap;
}

:global(.document-editor-main table td) {
  display: table-cell;
  min-width: 1em;
  border: 1px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  box-sizing: border-box;
  word-wrap: break-word;
}

:global(.square-sm) {
  display: inline-block;
  height: 12px;
  width: 12px;
  border-style: none;
}

:global(.document-editor-main .table-of-content-item) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

:global(.document-editor-main .table-of-content-item p) {
  margin: 0;
}

:global(.document-editor-main .table-of-content-link) {
  color: $brand-black;
}

:global(.document-editor-main .p-h1) {
  margin-left: 0;
}

:global(.document-editor-main .p-h2) {
  margin-left: 15px;
}

:global(.document-editor-main .p-h3) {
  margin-left: 25px;
}

:global(.document-editor-main .p-h4) {
  margin-left: 35px;
}

:global(.document-editor-main .p-h5) {
  margin-left: 45px;
}

:global(.document-editor-main .p-h6) {
  margin-left: 55px;
}

:global(.document-editor-main .footer-container) {
  margin-top: auto;
}

:global(.document-editor-main .header-container table td),
:global(.document-editor-main .footer-container table td) {
  border: none;
  padding: 0;
}

:global(.document-editor-main .header-container p),
:global(.document-editor-main .footer-container p) {
  min-height: 0;
  margin-bottom: 0.2rem;
}

:global(.document-editor-main .image-skeleton) {
  display: block;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: shine 1.5s ease-in-out infinite;
  height: 200px;
  max-width: 100%;

  img {
    display: none;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

/* Override Telerik editor style */
:global(#document-editor .k-editor) {
  height: 100%;
  width: 100%;
  border: none;
}

:global(#document-editor .k-editor .k-toolbar) {
  border: none;
  background-color: initial;
  visibility: hidden;
}

:global(#document-editor .k-editor .k-editor-content p) {
  font-family: "Open Sans", sans-serif;
}

:global(.k-colorpalette-table .k-colorpalette-tile[aria-label="#EFEFEF"]),
:global(.k-colorpalette-table .k-colorpalette-tile[aria-label="#F3F3F3"]),
:global(.k-colorpalette-table .k-colorpalette-tile[aria-label="#FFFFFF"]) {
  border: #d9d9d9 0.6px solid;
}

:global(#document-editor .k-colorpicker) {
  margin-right: 4px;
}

/* End overriding Telerik editor style */

:global(.resize-cursor) {
  cursor: col-resize;
}

:global(.resize-cursor-vertical) {
  cursor: row-resize;
}

:global(#document-editor .k-editor-resize-wrap-element) {
  position: relative;
  display: inline-block;
  max-width: 800px !important;
}

:global(#document-editor .k-editor-resize-handle) {
  position: absolute;
  visibility: visible;
  background-color: #fff;
  border: 1px solid #000;
  z-index: 100;
  width: 5px;
  height: 5px;
}

:global(#document-editor .k-editor-resize-handle.southeast) {
  right: 0;
  bottom: 0;
  transform: translate(50%, 50%);
  cursor: se-resize;
}

:global(#document-editor .k-editor-resize-handle.east) {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
  cursor: e-resize;
}

:global(#document-editor .k-editor-resize-handle.south) {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  cursor: s-resize;
}

:global(#document-editor .k-editor-resize-handle.north) {
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: n-resize;
}

:global(#document-editor .k-editor-resize-handle.west) {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  cursor: w-resize;
}

:global(#document-editor .k-editor-resize-handle.southwest) {
  left: 0;
  bottom: 0;
  transform: translate(-50%, 50%);
  cursor: sw-resize;
}

:global(#document-editor .k-editor-resize-handle.northwest) {
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
  cursor: nw-resize;
}

:global(#document-editor .k-editor-resize-handle.northeast) {
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  cursor: ne-resize;
}

:global(.inactive-link) {
  pointer-events: none;
  cursor: default;
}

:global(.document-editor-main .k-editor ol[dynamiclist="true"]) {
  > li::before {
    content: attr(data) "*.\a0";
  }
}

:global(.document-editor-main .k-editor ol[continuous="true"]) {
  > li::before {
    color: $warning-color;
  }
}

:global(.document-editor-main .k-editor ol[prefixnearest="true"]) {
  > li::before {
    color: $success-color;
  }
}

:global(.document-editor-main .k-editor ol:has(> li[selected="true"])) {
  > li::before {
    background-color: $hover-color;
  }
}

:global(.document-editor-main li:has(> h1)) {
  font-size: 20pt;
  font-weight: bold;
}

:global(.document-editor-main li:has(> h2)) {
  font-size: 16pt;
  font-weight: bold;
}

:global(.document-editor-main li:has(> h3)) {
  font-size: 14pt;
  font-weight: bold;
}

:global(.document-editor-main li:has(> h4)) {
  font-size: 12pt;
  font-weight: bold;
}

:global(.document-editor-main li:has(> p > span[style*="block-type: title"])) {
  font-size: 32pt;
}

:global(.document-editor-main li:has(> p > span[style*="block-type: caption"])) {
  font-size: 10pt;
}

/* stylelint-disable selector-class-pattern */
:global(.ProseMirror-gapcursor) {
  display: none;
  pointer-events: none;
  position: absolute;
}

:global(.ProseMirror-gapcursor::after) {
  content: "";
  display: block;
  position: absolute;
  height: 20px;
  border-left: 1px solid $brand-black;
  animation: prose-mirror-cursor-blink 1.1s steps(2, start) infinite;
}

@keyframes prose-mirror-cursor-blink {
  to {
    visibility: hidden;
  }
}

:global(.ProseMirror-focused .ProseMirror-gapcursor) {
  display: block;
}

:global(#document-editor .ProseMirror td) {
  position: relative;
}

:global(#document-editor .ProseMirror .selectedCell::after) {
  z-index: 2;
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #E8F8FF;
  opacity: 0.7;
  pointer-events: none;
}

/* stylelint-enable selector-class-pattern */
