"use strict";

import { Ensure } from "../../../../server/common/generic/common_ensure";
import { Log, LOG_GROUP } from "../../../../server/common/logger/common_log";
import { CachedPDFPage } from "./cached_pdf_page";
// eslint-disable-next-line no-unused-vars
const Logger = Log.group(LOG_GROUP.Documents, "CachedPDFDocument");

/**
 * @typedef {typeof import("pdfjs-dist/index.d.ts").PDFDocumentProxy} ICachedPDFDocument
 * @property {IPDFRenderDocument} renderObject
 * @property {Map<number, ICachedPDFPage>} _pageCache
 * @property {function(number): PDFPromise<PDFPageProxy>} getPage
 * @property {function(number): ICachedPDFPage} getCachedPage
 * @property {function(number, ICachedPDFPage): ICachedPDFPage} setCachedPagePage
 */

// noinspection JSClosureCompilerSyntax
/**
 * Wraps an instance of a {@link PDFDocumentProxy} and adds additional functionality required for our context.
 * @extends {PDFDocumentProxy|ICachedPDFDocument}
 */
export class CachedPDFDocument {
  /**
   * Extends a {@link PDFDocumentProxy} (the class used by pdfJS) so that it can store information
   * we use during the rendering and also cache its pages.
   * @param pdfDocument {PDFDocumentProxy|ICachedPDFDocument}
   */
  static fromDocument(pdfDocument) {
    Ensure.that({pdfDocument}).isNotFalsy();

    pdfDocument.renderObject = {};
    /**
     * @type {Map<number, ICachedPDFPage>}
     * @private
     */
    pdfDocument._pageCache = new Map();
    const oldGetPage = pdfDocument.getPage.bind(pdfDocument);

    const setCachedPage = (number, page) => {
      pdfDocument._pageCache.set(number, CachedPDFPage.fromPage(page));
      return page;
    };

    const getCachedPage = async (number) => {
      let page = pdfDocument._pageCache.get(number);
      if (!page) {
        Logger.verbose(() => "Loading PDF Page", Log.object(number));
        page = await pdfDocument.getPage(number);
      }
      return page;
    };

    const newGetPage = (number) => {
      return oldGetPage(number).then((page) => {
        page.renderObject = page.renderObject || {};
        return pdfDocument.setCachedPage(number, page);
      });
    };

    pdfDocument.setCachedPage = setCachedPage.bind(pdfDocument);
    pdfDocument.getCachedPage = getCachedPage.bind(pdfDocument);
    pdfDocument.getPage = newGetPage.bind(pdfDocument);
    Logger.verbose(() => "Extended PDFDocumentProxy with additional properties", Log.object(pdfDocument));
    return pdfDocument;
  }
}
