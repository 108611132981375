"use strict";

import * as styles from "./chat.module.scss";

import * as UIUtils from "../ui_utils";
import React from "react";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import * as CommonUtils from "../../server/common/generic/common_utils";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";
import BasePopup from "../editor/approval/base_popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faPaperPlane, faPerson, faUser } from "@fortawesome/free-solid-svg-icons";
import { ChatService } from "../services/chat/chat_service";
import mentaIcon from "../images/icons/menta.png";
import MarkdownIt from "markdown-it";
import ExampleQuestionGenerator from "./example_question_generator";
import { Button, IconButton } from "@qbdvision-inc/component-library";
import InfoTooltip from "../widgets/tooltips/info_tooltip";
import { StatusCallbackService } from "../services/status_callback_service";
import { ChatMessageHandler } from "../services/chat/chat_message_handler";

const Logger = Log.group(LOG_GROUP.ClientServices, "ChatPopup");

const SPEAKERS = {
  USER: {
    icon: faUser,
    role: "user",
  },
  MENTA: {
    image: mentaIcon,
    role: "assistant", // This is the name ChatGPT uses to refer to itself.
  }
};

const ANSWER_STATE = {
  WAITING_FOR_FIRST_TOKEN: "WAITING_FOR_FIRST_TOKEN",
  WAITING_FOR_LAST_TOKEN: "WAITING_FOR_LAST_TOKEN",
  ANSWER_COMPLETE: "ANSWER_COMPLETE",
};

// i18next-extract-mark-ns-start users
/**
 * For getting advice from a super amazing computer.
 */
class ChatPopup extends BasePopup {
  constructor(props) {
    super(props);

    this.chatTranscriptDiv = null;
    this.setStateSafely({
      userQuestion: "",
      transcriptArray: [],
      answerState: null,
      questionErrorText: null,
      partialMessages: [],
    });

    /*
     * The websocket partial messages from ChatGPT don't come in order, so we have to reorder them. Unfortunately,
     * the state might not update in time for the next message to come in so this has to be a class variable.
     */
    this.maxMessageNum = 0;
    this.areMessagesInOrder = true;
    this.statusService = new StatusCallbackService(this.handlePartialChatMessage);
    this.messageHandler = new ChatMessageHandler(this.statusService);
    this.chatService = new ChatService(this.messageHandler, this.statusService);
    this.exampleQuestions = new ExampleQuestionGenerator().generateQuestions(5);
    this.chatInputRef = null;
  }

  componentDidMount() {
    super.componentDidMount();
    $(this.modalRef).on("shown.bs.modal", () => {
      this.chatInputRef.focus();
    });
  }

  handleUserQuestionChange(event) {
    this.setStateSafely({userQuestion: event.target.value}, () => this.forceUpdate());
  }

  handleSendQuestion() {
    const question = this.state.userQuestion;
    if (!question) {
      this.setStateSafely({questionErrorText: "A message is required"});
    } else {
      this.setStateSafely({questionErrorText: null});
      this.addToTranscript(SPEAKERS.USER.role, question, async() => {
        await this.loadAnswerFromMenta();
      });
    }
  }

  handleSendExample(exampleText) {
    this.addToTranscript(SPEAKERS.USER.role, exampleText, async() => {
      await this.loadAnswerFromMenta();
    });
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.handleSendQuestion();
    }
  }

  componentWillUnmount() {
    this.chatService.disconnect();
    super.componentWillUnmount();
    // Refresh the example questions for next time.
    this.exampleQuestions = new ExampleQuestionGenerator().generateQuestions(5);
  }

  async loadAnswerFromMenta() {
    this.setStateSafely({
      userQuestion: "",
      answerState: ANSWER_STATE.WAITING_FOR_FIRST_TOKEN,
      partialMessages: []
    }, () => {
      this.scrollToBottomOfChat();
    });
    try {
      this.maxMessageNum = 0;
      this.areMessagesInOrder = true;
      const transcript = CommonUtils.deepClone(this.state.transcriptArray);
      // Clean out the html before sending it to ChatGPT
      transcript.forEach(message => delete message.htmlContent);
      await this.chatService.chat({transcript});
    } catch (err) {
      Logger.error(() => "Received error:", Log.error(err));
      this.addToTranscript(SPEAKERS.MENTA.role, "I'm sorry, that question produced an error on my side. Please contact QbDVision support.");
    } finally {
      // Response is now completely received.
      this.setStateSafely({answerState: ANSWER_STATE.ANSWER_COMPLETE});
    }
  }

  addToTranscript(role, content, callback) {
    const transcriptArray = CommonUtils.deepClone(this.state.transcriptArray);
    transcriptArray.push({
      role,
      content,
      key: transcriptArray.length,
    });
    this.setStateSafely({transcriptArray, userQuestion: ""}, () => {
      // Scroll to the bottom.
      this.scrollToBottomOfChat();
      if (callback) {
        callback();
      }
    });
  }

  scrollToBottomOfChat() {
    const chatTranscriptDiv = this.chatTranscriptDiv;
    if (chatTranscriptDiv) {
      chatTranscriptDiv.scrollTop = chatTranscriptDiv.scrollHeight;
    }
  }

  handlePartialChatMessage(partialMessage) {
    if (partialMessage.status === "partialMessage") {
      const partialContent = partialMessage.data;
      if (this.state.answerState === ANSWER_STATE.WAITING_FOR_FIRST_TOKEN) {
        // This is the first token of the response
        this.setStateSafely({answerState: ANSWER_STATE.WAITING_FOR_LAST_TOKEN});
        this.addMentaResponse(partialMessage);
      } else {
        const transcriptArray = CommonUtils.deepClone(this.state.transcriptArray);
        const message = transcriptArray[transcriptArray.length - 1];
        const partialMessages = CommonUtils.deepClone(this.state.partialMessages);
        partialMessages.push(partialMessage);
        if (this.areMessagesInOrder) {
          if (this.maxMessageNum === partialMessage.messageNum - 1) {
            // Messages are in order.
            message.content += partialContent;
          } else {
            // We received some future message. Wait for the interim messages before adding this message to the content.
            this.areMessagesInOrder = false;
          }
        } else {
          // We have some of the messages, but not all of them. So we sort them and include what we have.
          partialMessages.sort((message1, message2) => message1.messageNum - message2.messageNum);
          message.content = "";
          let isBreak = false;
          for (let i = 0; i < partialMessages.length; i++) {
            const partial = partialMessages[i];
            if (partial.messageNum === i) {
              message.content += partial.data;
            } else {
              // There's a break in the messages. Don't keep going.
              isBreak = true;
              break;
            }
          }
          this.areMessagesInOrder = !isBreak && this.maxMessageNum === (partialMessages.length - 1);
        }
        Logger.verbose(() => "maxMessageNum:", this.maxMessageNum,
          "areMessagesInOrder:", this.areMessagesInOrder,
          "partial.data:", partialMessage.data,
          "partial.messageNum:", partialMessage.messageNum,
          "partialMessages.length:", partialMessages.length,
          "partialMessages:", Log.object(partialMessages));
        this.maxMessageNum = Math.max(partialMessage.messageNum, this.maxMessageNum);
        this.updateHTMLContent(message);
        this.setStateSafely({transcriptArray, partialMessages});
      }
      this.scrollToBottomOfChat();
      Logger.verbose("Received partial message:", partialContent);
    }
  }

  /**
   * Create a new response from Menta.
   * @param partialMessage {string} The text that is the start of the response.
   */
  addMentaResponse(partialMessage) {
    const content = partialMessage.data;
    const transcriptArray = CommonUtils.deepClone(this.state.transcriptArray);
    const message = {
      role: SPEAKERS.MENTA.role,
      content,
      key: transcriptArray.length,
    };
    const partialMessages = [partialMessage];
    this.updateHTMLContent(message);
    transcriptArray.push(message);
    this.setStateSafely({transcriptArray, partialMessages});
  }

  /**
   * ChatGPT doesn't warn you (as of early 2023) when it's returning markdown or HTML, so you have to figure it out for
   * yourself. We keep the original content and then update a separate htmlContent with the updated message.
   * @param message {object} The message object with a content. The message will be updated in place to include the htmlContent.
   */
  updateHTMLContent(message) {
    const md = new MarkdownIt();
    let html;
    const content = message.content;
    if (content.startsWith("<p>") || content.includes("<table>")) {
      // The content is in HTML
      html = content;
    } else {
      // The content is in Markdown
      html = md.render(content);
    }

    if (html) {
      message.htmlContent = html;
    }
  }

  getCSSClassForMessage(message) {
    let cssClass = "";
    if (message.role === SPEAKERS.MENTA.role) {
      cssClass += styles["chat-transcript-dialog-" + message.role];

      if (this.state.answerState === ANSWER_STATE.ANSWER_COMPLETE) {
        cssClass += " chat-complete";
      }
    }
    return cssClass;
  }

  render() {
    const {t} = this.props;
    return (
      <div className="modal fade terms-modal-on-top"
           ref={this.setModalRef}
      >
        <div className="modal-dialog modal-xl" id={styles["chat-modal"]}>
          <div className="modal-content">
            <div className={`modal-header ${styles["modal-header"]}`}>
              <div className="modal-container full-width">
                <div className="row flex-grow-1">
                  <div className="col flex-grow-1 align-items-center d-inline-flex">
                    <h1 className="modal-title">
                      <img src={mentaIcon} className={styles["menta-icon-header"]} alt="Menta" />
                      <span className={`qbd ${styles["chat-header-menta"]}`}>{t("Menta")}&trade;</span>
                      <span className={styles["chat-header-beta-pill"]}>{t("Beta")}</span>
                    </h1>
                  </div>
                  <div className="col-auto">
                    <Button onClick={() => window.open(UIUtils.getSecuredURL("https://support.qbdvision.com"), "_blank",)}
                            type="secondary"
                    >
                      <span className={styles["chat-person-icon"]}><FontAwesomeIcon icon={faPerson} /></span>
                      Contact a human
                    </Button>
                  </div>
                  <div className="col-auto align-items-center d-inline-flex">
                    <button type="button" className={`close ${styles["chat-close"]}`} data-dismiss="modal" aria-label="Close">
                      <span>&times;</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className={`modal-body ${styles["modal-body"]}`}>
              <div className="modal-container popup-container">
                {this.state.transcriptArray.length === 0 ? (
                  <div className={styles["chat-transcript"]}>
                    <div className="row">
                      <div className="col-8 offset-2 col-lg-6 offset-lg-3">
                        <div className={styles["chat-transcript-welcome"]}>
                          What would you like to know about?
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8 offset-2 col-lg-6 offset-lg-3">
                        <div className={styles["chat-transcript-welcome-examples"]}>
                          {this.exampleQuestions
                            .map((question) => <ChatPopupExample key={question}
                                                                 onClick={() => this.handleSendExample(question)}
                                                                 exampleQuestion={question}
                            />)}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <div className={styles["chat-transcript"]} ref={div => this.chatTranscriptDiv = div}>
                        {this.state.transcriptArray.map(message =>
                          <div className="row" key={message.key}>
                            <div className={`col-auto ${styles["chat-transcript-role"]}`} key={message.key}>
                              {message.role === SPEAKERS.USER.role ?
                                <FontAwesomeIcon icon={SPEAKERS.USER.icon} />
                                :
                                <img src={SPEAKERS.MENTA.image} className={styles["menta-icon"]} alt={"Menta"} />
                              }
                            </div>
                            <div className={`col flex-grow-1 ${(this.getCSSClassForMessage(message))}`}>
                              {this.renderMessageContent(message)}
                            </div>
                          </div>
                        )}
                        {this.state.answerState === ANSWER_STATE.WAITING_FOR_FIRST_TOKEN ? (
                          <div className="row">
                            <div className={`col-auto ${styles["chat-transcript-role"]}`}>
                              <img src={SPEAKERS.MENTA.image} className={styles["menta-icon"]} alt={"Menta"} />
                            </div>
                            <div className={`col flex-grow-1`}>
                              <div className={`${styles["chat-waiting"]} skeleton`}></div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <div className="modal-container full-width">
                <div className="row">
                  <div className="col-11">
                    <input type="text"
                           ref={ref => this.chatInputRef = ref}
                           className={`form-control ${styles["chat-question-input"]}`}
                           id="chatUserInput"
                           value={this.state.userQuestion}
                           onChange={this.handleUserQuestionChange}
                           onKeyDown={this.handleKeyDown}
                    />
                  </div>
                  <div className="col-1">
                    <IconButton onClick={this.handleSendQuestion}
                                id="chatUserSendButton"
                                icon={faPaperPlane}
                    />
                  </div>
                  {this.state.questionErrorText ? (
                    <div className="col-12 help-block with-errors has-error">
                      {this.state.questionErrorText}
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  <div className="col-12 pt-1">
                    <span>
                      ⚠️ Do not enter trade secrets, sensitive or confidential information. This is a beta product provided for experimental purposes only.
                      See <a className={styles["beta-link"]} href="/terms/testTermsOfService.html" target="_blank">Beta Terms</a>.
                      <ChatLLMTooltip id="infoTooltipBottom" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /**
   * @param message {string} The message (including content) from either the user or Menta to render.
   * @returns {JSX.Element}
   */
  renderMessageContent(message) {
    return <span dangerouslySetInnerHTML={{__html: message.htmlContent || message.content}} />;
  }
}

function ChatPopupExample(props) {
  const {exampleQuestion, onClick} = props;
  return (
    <a onClick={onClick} className={styles["chat-popup-examples-link"]}>
      <div key={exampleQuestion} className={styles["chat-popup-examples-link-box"]}>
        {exampleQuestion}
        <span className={styles["chat-popup-examples-arrow"]}><FontAwesomeIcon icon={faArrowRight} /></span>
      </div>
    </a>
  );

}

function ChatLLMTooltip(props) {
  const {id, className} = props;
  return <InfoTooltip id={id} className={className} verbiage={
    <div>
      This software uses a large language model that is still in beta testing.
      <ul>
        <li>While we strive to provide accurate information, we cannot guarantee the reliability of the content generated.</li>
        <li>QbDVision, Inc. is not responsible for any errors, inaccuracies, or omissions in the output.</li>
        <li>We strongly advise against solely using the output from this software for decisions.</li>
        <li>Please do not disclose any confidential, trade secret, or sensitive information through this experimental software.</li>
        <li>Consult a qualified professional for any critical decisions.</li>
      </ul>
    </div>}
  />;
}


export default I18NWrapper.wrap(ChatPopup, "users");

// i18next-extract-mark-ns-stop users
