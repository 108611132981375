"use strict";

import * as UIUtils from "../../ui_utils";
import moment from "moment";
import React from "react";
import BaseEditorPage from "../../editor/base_editor_page";
import AdminPageTitleBar from "../../widgets/pageTitleBar/admin_page_title_bar";
import TextAttribute from "../../editor/attributes/text_attribute";
import ComboBoxAttribute from "../../editor/attributes/combo_box_attribute";
import Section from "../../editor/widgets/section";
import DateAttribute from "../../editor/attributes/date_attribute";
import CommonSecurity from "../../../server/common/generic/common_security";
import CheckboxAttribute from "../../editor/attributes/checkbox_attribute";
import { Button } from "@qbdvision-inc/component-library";
import { MFAService } from "../../services/bulkOperations/mfa_service";

export default class CompanyPage extends BaseEditorPage {
  constructor(props) {
    super(props, "company");

    this.setStateSafely({
      breadcrumb: {
        current: this.state.name ?? "Company",
        parent: "Admin Dashboard",
        parentLink: "/admin/internal/listOfUsers.html?activeTab=Companies",
      },
      trialExpiryDate: null,
      renewalDate: null,
      lastPaymentDate: null,
      license: "trial",
      totalLicenses: 0,
      canChangeLicense: true,
    });

    this.useTwoWayCommunication = false;
  }

  componentDidMount() {
    document.title = "Admin Dashboard";
    super.componentDidMount();
  }

  onDataReceivedFromServer() {
    const {BillingStates, identityProviders,} = this.state;
    if (BillingStates && BillingStates.length > 0) {
      const billing = BillingStates[0];
      const {
        trialExpiryDate, renewalDate, lastPaymentDate,
        license, totalLicenses,
      } = billing;

      this.setStateSafely({
        trialExpiryDate,
        renewalDate,
        lastPaymentDate,
        license,
        totalLicenses,
        identityProviders: identityProviders ? JSON.parse(identityProviders).join(",") : null,
        hasAccess: true,
        breadcrumb: {
          current: this.state.name ?? "Company",
          parent: "Admin Dashboard",
          parentLink: "/admin/internal/listOfUsers.html?activeTab=Companies",
        }
      });
    }
  }

  renderPageTitleBar() {
    return <AdminPageTitleBar name={this.state.name || "Company"} />;
  }

  getURLPrefix() {
    return "admin";
  }

  handleEdit() {
    window.location.href = UIUtils.getSecuredURL(`editCompany.html?operation=Edit&id=${this.state.id}`);
  }

  validateDate(date) {
    switch (date) {
      case !date:
        return "Date is required";
      case !moment(date, UIUtils.DATE_FORMAT_FOR_DISPLAY).isValid():
        return `Invalid date format. Please enter the date in the format: ${UIUtils.DATE_FORMAT_FOR_DISPLAY}`;
      case moment(date, UIUtils.DATE_FORMAT_FOR_STORAGE).isBefore(moment()):
        return "The date cannot be in the past";
      default:
        return null;
    }
  }

  getLicenseTypes() {
    return Object.values(CommonSecurity.CompanyLicenseTypes)
      .map(licenceType => {
        return {key: licenceType, value: UIUtils.capitalize(licenceType)};
      });
  }

  handleKillMfa() {
    const mfaService = new MFAService(); // move to constructor
    UIUtils.setHideLoadingOnAjaxStop(false);
    UIUtils.setLoadingDisabled(false);
    UIUtils.showLoadingImage();
    mfaService.killMfa({companyName: this.state.name})
      .then(() => {this.setStateSafely({forceMfa: false, dataModified: true});})
      .catch(error => {throw new Error(error);})
      .finally(() => {
        UIUtils.hideLoadingImage();
      }
      );
  }

  renderAttributes() {
    const {canChangeLicense} = this.state;

    return (
      <div>
        <Section parent={this}
                 header={this.getGeneralHeader()}
                 collapsible={false}
        >
          <div className="row">
            <TextAttribute
              name="name"
              parent={this}
              className="col-sm-6"
              data-error={"Company name is required."}
              required
            />
            <CheckboxAttribute
              className="col-sm-6"
              displayName="Force MFA"
              name="forceMfa"
              parent={this}
            />
          </div>
          {this.isEdit() &&
            <Button onClick={this.handleKillMfa}
                    id="killMfaButton"
                    label={"Kill MFA"}
                    className={"col-sm-6"}
            />
          }
        </Section>

        <Section header="Billing State">
          <div className="row">
            <ComboBoxAttribute
              name="license"
              className="col-sm-6"
              disabled={!canChangeLicense}
              options={this.getLicenseTypes()}
              parent={this}
              isLoading={this.state.isLoading}
              parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
              parentId={this.state.id}
            />
            <TextAttribute
              name="totalLicenses"
              parent={this}
              className="col-sm-6"
              dataPatternError="Invalid total licenses."
              pattern="^[0-9]*$"
            />
          </div>
          <div className="row">
            <DateAttribute
              name="trialExpiryDate"
              className="col-sm-4"
              parent={this}
              minDate={moment()}
              validateDate={this.validateDate}
              required
            />
            <DateAttribute
              name="renewalDate"
              className="col-sm-4"
              parent={this}
            />
            <DateAttribute
              name="lastPaymentDate"
              className="col-sm-4"
              parent={this}
            />
          </div>
          <div className="row">
            <TextAttribute
              name="identityProviders"
              parent={this}
              className="col-sm-4"
            />
            <CheckboxAttribute
              name="forceIdpAuthentication"
              className="col-sm-4"
              parent={this}
              tooltipText={
                <div>
                  Checking this will:
                  <ul>
                    <li>Remove default option from the user&apos;s identity provider dropdown
                    </li>
                    <li>User will not be able to login using passwords</li>
                    <li>Identity providers cannot be empty</li>
                  </ul>
                </div>
              }
            />
          </div>
        </Section>
      </div>
    );
  }
}
