var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames } from '@progress/kendo-react-common';
var MenuItemLink = /** @class */ (function (_super) {
    __extends(MenuItemLink, _super);
    function MenuItemLink() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @hidden
     */
    MenuItemLink.prototype.render = function () {
        if (this.props.url) {
            return (React.createElement("a", { className: this.getMenuItemClassName(), role: "presentation", href: this.props.url, tabIndex: -1 }, this.props.children));
        }
        else {
            return (React.createElement("span", { id: this.props.id, className: classNames(this.getMenuItemClassName(), this.props.className), style: this.props.style, role: "presentation" }, this.props.children));
        }
    };
    MenuItemLink.prototype.getMenuItemClassName = function () {
        return classNames('k-link', 'k-menu-link', {
            'k-active': this.props.opened
        });
    };
    /**
     * @hidden
     */
    MenuItemLink.propTypes = {
        opened: PropTypes.bool,
        url: PropTypes.string
    };
    return MenuItemLink;
}(React.Component));
export { MenuItemLink };
