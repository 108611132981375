"use strict";

import { FIELD_PROPS } from "../fieldsConfig/constants/configurable_tables_field_props_config";
import {
  DEFAULT_SORTING,
  FILTER_TYPES,
  DATE_NULL_FILTER_DESCRIPTOR
} from "./configurable_tables_column_operations_constants";
import {
  ColumnOperationsMenu,
  ComboBoxColumnOperationsMenu,
  ParentLookupColumnOperationsMenu
} from "../tables/widgets/column_operations_menu";

export default class ConfigurableTablesColumnOperationsAdapter {

  /**
   * Sometimes a column is sorted by a field different from the field used for filtration.
   * For ex, the tech transfer impact column that is sorted by risk score but filtered by
   * the risk label. In this function, we change the field name used for sorting by the field name
   * used for filtration.
   */

  extractCustomFilters(tableState, visibleTableColumns) {
    let customFilters = null;
    if (tableState && tableState.filter) {
      const {filters = []} = tableState.filter;

      // Extract only the custom filters
      customFilters = filters
        .map(outerFilter => (outerFilter.filters || []).filter(filter => {
          const {filterType} = this.getFieldInformation(visibleTableColumns, filter.field) || {};
          return filterType === FILTER_TYPES.LINKS;
        }))
        .flat();
    }

    return customFilters;
  }

  adaptFilterNames({tableState, visibleTableColumns}) {
    if (tableState) {
      const {filter} = tableState;
      const {filters} = filter || {};
      const filterObject = {};

      if (filter && visibleTableColumns?.length > 0 && filters) {
        filterObject.filter = {
          filters: filters.map(parentFilter => {
            const {filters, logic} = parentFilter;
            return {
              filters: filters.filter(filter => {
                const {filterType} = this.getFieldInformation(visibleTableColumns, filter.field) || {};
                return filterType !== FILTER_TYPES.LINKS;
              }).map(filter => {
                const field = this.getFieldInformation(visibleTableColumns, filter.field);
                return {
                  value: filter.value,
                  operator: field ? this.fixFieldOperator(field, filter.operator) : filter.operator,
                  field: field ? this.fixFieldName(field) : filter.field,
                };
              }),
              logic,
            };
          }),
          logic: "and",
        };
      }

      filterObject.sort = this.getSortDescriptor(tableState, visibleTableColumns);
      return filterObject;
    }

    return {
      sort: this.getDefaultSorting()
    };
  }

  getSortDescriptor(tableState, visibleTableColumns) {
    const {sort} = tableState || {};
    if (!sort || sort.length === 0 || !visibleTableColumns) {
      return this.getDefaultSorting();
    }

    const sortField = sort[0].field;
    const sortDirection = sort[0].dir;
    const tableColumns = visibleTableColumns.filter(tableColumn => tableColumn.prop === sortField);
    if (tableColumns.length > 0 && tableColumns[0].compare) {
      sort[0].compare = tableColumns[0].compare(sortDirection);
    }

    return sort;
  }

  getDefaultSorting(tableState) {
    const {sort} = tableState || {};
    return sort && sort.length > 0 ? sort : DEFAULT_SORTING;
  }

  /**
   * This function resets all dates to UTC so date filters (Ex. expiration date)
   * works properly. See ticket www.telerik.com/account/support-tickets/view-ticket/1530688
   */
  adaptDateFilters(filter, fixTimezone = false) {
    if (filter && filter.filters) {
      const {filters} = filter || {};
      return {
        filters: filters.map(parentFilter => {
          const {filters, logic} = parentFilter;
          const adaptedParentFilter = {
            filters: filters.map(filter => {
              let {value, field} = filter;
              const isDate = field.includes("Date");

              if (isDate && fixTimezone) {
                const currentOffsetMilliseconds = new Date().getTimezoneOffset() * 60000;
                const filterDate = new Date(value);
                const isZeroHour = filterDate.getHours() === 0;
                value = isZeroHour ? new Date(filterDate.getTime() - currentOffsetMilliseconds) : value;
              } else if (isDate) {
                value = new Date(value);
              }

              return {
                ...filter,
                field,
                value,
              };
            }),
            logic,
          };

          adaptedParentFilter.filters[0].field.includes("Date") ? adaptedParentFilter.filters.push({
            ...DATE_NULL_FILTER_DESCRIPTOR,
            field: adaptedParentFilter.filters[0].field
          }) : null;
          return adaptedParentFilter;
        }),
        logic: "and",
      };
    }
  }

  getObjectListFilterOptions(records, prop) {
    let options = [];
    for (const record of records) {
      options = options.concat(record[`${prop}FilterOptions`]);
    }
    return [...new Set([...options])].sort();
  }

  getRiskFilterOptions(records, prop) {
    prop = this.adaptRiskFilterName(prop);
    records.sort((a, b) => a[prop] - b[prop]);
    return records.map(record => record[`${prop}RiskLabel`] || record[`${prop}Label`]);
  }

  getFilterOptions(records, prop) {
    return records.map(record => {
      const value = record[prop];
      return value ? {[prop]: value} : value;
    }).filter(option => option && option[prop]);
  }

  fixFieldOperator(field, operator) {
    return field?.filterLogic || operator;
  }

  fixFieldName(field) {
    const prop = this.adaptRiskFilterName(field?.prop);
    return field.isRiskScaleField ? `${prop}RiskLabel` :
      field.isRiskScoreField ? `${prop}Label` : prop;
  }

  adaptRiskFilterName(prop) {
    switch (prop) {
      case FIELD_PROPS.PROCESS_RISK_PERCENTAGE:
        return FIELD_PROPS.PROCESS_RISK;
      case FIELD_PROPS.CRITICALITY_PERCENTAGE:
        return FIELD_PROPS.CRITICALITY;
      case FIELD_PROPS.RPN_PERCENTAGE:
        return FIELD_PROPS.RPN;
      case FIELD_PROPS.MAX_CRITICALITY_PERCENTAGE:
        return FIELD_PROPS.MAX_CRITICALITY;
      case FIELD_PROPS.UNIT_OPERATIONS:
      case FIELD_PROPS.UNIT_OPERATION:
        return FIELD_PROPS.UNIT_OPERATION_NAME;
      case FIELD_PROPS.STEPS:
      case FIELD_PROPS.STEP:
        return FIELD_PROPS.STEP_NAME;
      default:
        return prop;
    }
  }

  getColumnOperationsMenu(filterType, data, prop) {
    switch (filterType) {
      case FILTER_TYPES.COMBO_BOX: {
        const {visibleTableColumns, records} = data;
        const field = this.getFieldInformation(visibleTableColumns, prop);
        const options = field?.filterOptions ?
          this.getFieldFilterOptions(field, prop, records)
          : this.getFilterOptions(records || [], prop);
        return ComboBoxColumnOperationsMenu.bind(this, options);
      }
      case FILTER_TYPES.LOOKUP:
        return ParentLookupColumnOperationsMenu;
      case FILTER_TYPES.NUMERIC:
      case FILTER_TYPES.DATE:
        return ColumnOperationsMenu;
      default:
        return ColumnOperationsMenu;
    }
  }

  getFieldFilterOptions(field, prop, records) {
    return field.filterOptions(records, this)
      .map(option => {
        return {[prop]: option};
      }).filter(option => option[prop]);
  }

  /**
   * This function is responsible for adding needed props for
   * column operations (ex. filter, sort, format, etc.)
   * @param enableColumnOperations Not every column has filter/sorting enabled
   * @param filterType Whether this filter is a dropdown, text, numeric, etc.
   * @param data table records
   * @param propName field prop to get filter options
   */
  getColumnOperationsProps = (enableColumnOperations, filterType, data, propName) => {
    let operationsObject = enableColumnOperations ?
      {
        filter: ["text", "numeric", "boolean", "date"].includes(filterType) ? filterType : "text",
        columnMenu: this.getColumnOperationsMenu(filterType, data || {}, propName),
      } :
      {sortable: false,};

    if (enableColumnOperations && filterType === FILTER_TYPES.DATE) {
      operationsObject.format = "{0:MMM dd, yyyy}";
    }
    return operationsObject;
  };

  getFieldInformation(visibleTableColumns, prop) {
    return visibleTableColumns.find(column => column.prop === prop);
  }
}
