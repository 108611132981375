var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { guid, classNames, createPropsContext, withPropsContext } from '@progress/kendo-react-common';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
/** @hidden */
var InputWithoutContext = /** @class */ (function (_super) {
    __extends(InputWithoutContext, _super);
    function InputWithoutContext(props) {
        var _this = _super.call(this, props) || this;
        _this._input = null;
        _this._inputId = guid();
        /**
         * @hidden
         */
        _this.focus = function () {
            if (_this._input) {
                _this._input.focus();
            }
        };
        _this.isInvalid = function (state) {
            var result = false;
            for (var prop in state) {
                if (state.hasOwnProperty(prop)) {
                    result = result || Boolean(state[prop]);
                }
            }
            return result;
        };
        /**
         * @hidden
         */
        _this.setValidity = function () {
            if (_this._input && _this._input.setCustomValidity) {
                _this.validity.valid || !_this.validityStyles
                    ? _this._input.classList.remove('k-invalid')
                    : _this._input.classList.add('k-invalid');
                _this._input.setCustomValidity(_this.validity.valid
                    ? ''
                    : _this.props.validationMessage || '');
            }
        };
        _this.handleChange = function (event) {
            _this.setState({
                value: event.target.value
            });
            _this.valueDuringOnChange = event.target.value;
            if (_this.props.onChange) {
                _this.props.onChange.call(undefined, {
                    syntheticEvent: event,
                    nativeEvent: event.nativeEvent,
                    value: event.target.value,
                    target: _this
                });
            }
            _this.valueDuringOnChange = undefined;
        };
        /**
         * @hidden
         */
        _this.handleAutoFill = function (e) {
            if (e.animationName === 'autoFillStart') {
                var parent_1 = e.target.parentNode;
                if (parent_1 && parent_1.classList.contains('k-empty')) {
                    parent_1.classList.remove('k-empty');
                }
            }
        };
        validatePackage(packageMetadata);
        _this.state = {
            value: _this.props.defaultValue || InputWithoutContext.defaultProps.defaultValue
        };
        return _this;
    }
    Object.defineProperty(InputWithoutContext.prototype, "element", {
        /**
         * Gets the native input element of the Input component.
         */
        get: function () {
            return this._input;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InputWithoutContext.prototype, "value", {
        /**
         * Gets the value of the Input.
         */
        get: function () {
            return this.valueDuringOnChange !== undefined
                ? this.valueDuringOnChange
                : this.props.value !== undefined
                    ? this.props.value
                    : this.state.value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InputWithoutContext.prototype, "name", {
        /**
         * Gets the `name` property of the Input.
         */
        get: function () {
            return this.props.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InputWithoutContext.prototype, "validity", {
        /**
         * Represents the validity state into which the Input is set.
         */
        get: function () {
            var result = {
                badInput: this._input ? this._input.validity.badInput : false,
                patternMismatch: this._input
                    ? this._input.validity.patternMismatch
                    : false,
                rangeOverflow: this._input ? this._input.validity.rangeOverflow : false,
                rangeUnderflow: this._input ? this._input.validity.rangeUnderflow : false,
                stepMismatch: this._input ? this._input.validity.stepMismatch : false,
                tooLong: this._input ? this._input.validity.tooLong : false,
                typeMismatch: this._input ? this._input.validity.typeMismatch : false,
                valueMissing: this._input ? this._input.validity.valueMissing : false
            };
            return __assign(__assign({}, result), { customError: this.props.validationMessage !== undefined, valid: this.props.valid !== undefined
                    ? this.props.valid
                    : this._input
                        ? !this.isInvalid(result)
                        : true });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InputWithoutContext.prototype, "validityStyles", {
        /**
         * @hidden
         */
        get: function () {
            return this.props.validityStyles !== undefined
                ? this.props.validityStyles
                : InputWithoutContext.defaultProps.validityStyles;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    InputWithoutContext.prototype.componentDidMount = function () {
        this.forceUpdate();
    };
    /**
     * @hidden
     */
    InputWithoutContext.prototype.componentDidUpdate = function () {
        this.setValidity();
    };
    /**
     * @hidden
     */
    InputWithoutContext.prototype.render = function () {
        var _this = this;
        var _a = this.props, className = _a.className, label = _a.label, id = _a.id, validationMessage = _a.validationMessage, defaultValue = _a.defaultValue, valid = _a.valid, 
        // Removed to support direct use in Form Field component
        visited = _a.visited, touched = _a.touched, modified = _a.modified, ariaLabelledBy = _a.ariaLabelledBy, ariaDescribedBy = _a.ariaDescribedBy, validityStyles = _a.validityStyles, style = _a.style, ariaLabel = _a.ariaLabel, props = __rest(_a, ["className", "label", "id", "validationMessage", "defaultValue", "valid", "visited", "touched", "modified", "ariaLabelledBy", "ariaDescribedBy", "validityStyles", "style", "ariaLabel"]);
        var inputId = id || this._inputId;
        var isValid = !this.validityStyles || this.validity.valid;
        var inputClassName = classNames(className, 'k-input k-input-md k-rounded-md k-input-solid');
        var textbox = (React.createElement("input", __assign({ "aria-labelledby": ariaLabelledBy, "aria-describedby": ariaDescribedBy, "aria-disabled": this.props.disabled || undefined, "aria-invalid": !isValid || undefined, "aria-label": ariaLabel || undefined }, props, { style: !label
                ? style
                : undefined, value: this.value, id: inputId, className: inputClassName, onChange: this.handleChange, onAnimationStart: this.handleAutoFill, ref: function (el) {
                _this._input = el;
            } })));
        return label ?
            (React.createElement(FloatingLabel, { label: label, editorId: inputId, editorValue: String(this.value), editorValid: isValid, editorDisabled: props.disabled, editorPlaceholder: props.placeholder, children: textbox, style: style, dir: props.dir }))
            : textbox;
    };
    InputWithoutContext.displayName = 'Input';
    /**
     * @hidden
     */
    InputWithoutContext.propTypes = {
        label: PropTypes.string,
        validationMessage: PropTypes.string,
        required: PropTypes.bool,
        validate: PropTypes.bool,
        id: PropTypes.string,
        ariaLabelledBy: PropTypes.string,
        ariaDescribedBy: PropTypes.string,
        ariaLabel: PropTypes.string
    };
    /**
     * @hidden
     */
    InputWithoutContext.defaultProps = {
        defaultValue: '',
        required: false,
        validityStyles: true
    };
    return InputWithoutContext;
}(React.Component));
export { InputWithoutContext };
/**
 * Represents the PropsContext of the `Input` component.
 * Used for global configuration of all `Input` instances.
 *
 * For more information, refer to the [Inputs Props Context]({% slug props-context_inputs %}) article.
 */
export var InputPropsContext = createPropsContext();
;
/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the component the same as the type */
/**
 * Represents the [KendoReact Input component]({% slug overview_textbox %}).
 *
 * Accepts properties of type [InputProps]({% slug api_inputs_inputprops %}).
 * Obtaining the `ref` returns an object of type [InputHandle]({% slug api_inputs_inputhandle %}).
 */
export var Input = withPropsContext(InputPropsContext, InputWithoutContext);
Input.displayName = 'KendoReactInput';
