"use strict";

import CommonUtils from "../../../server/common/generic/common_utils";
import ConfigurableTablesAdapter from "./configurable_tables_adapter";
import { formatJson, formatValue } from "../fieldsConfig/fields_formatter";
import * as CommonEditablesFormatter from "../../../server/common/editables/common_editables_formatter";
import * as UIUtils from "../../ui_utils";

/**
 * This class adapts/transforms the server data for Variables UI rendering.
 */
export default class ConfigurableTablesVariableAdapter extends ConfigurableTablesAdapter {
  getRows() {

    const rows = [];
    const {
      addCommonRiskPropsForFiltration,
      formatRecordData,
      addAcceptanceCriteriaInfo,
    } = this.configurableTablesAdapterHelper;

    const records = Object.values(this.activeMap);
    super.adaptRows(records);

    for (const record of records) {
      let documents = [];
      formatRecordData(record, this.typeaheadOptions, this.effectiveRMP, documents);

      record.obligatoryCQA = formatValue(record.obligatoryCQA);
      record.parent = record.source ? UIUtils.getRecordCustomLabelForDisplay(record.source) : "";
      record.controlStrategyFormattedValue = formatJson(record, "controlStrategy");
      record.controlStrategy = CommonEditablesFormatter.formatControlStrategyForDisplay(record.controlStrategy, false);
      record.compendialStandard = CommonEditablesFormatter.formatMultiSelectValueForDisplay(record.compendialStandard);

      addCommonRiskPropsForFiltration(record, true);
      addAcceptanceCriteriaInfo(record);

      const details = [];
      let sourceDocuments = [];
      for (const riskLink of record.riskLinks) {

        sourceDocuments = sourceDocuments.concat(Array.isArray(riskLink.links) ? riskLink.links : []);
        const riskLinkDetails = riskLink.riskLink;

        details.push({
          id: riskLinkDetails.id,
          name: riskLinkDetails.name,
          impact: riskLink.impact,
          uncertainty: riskLink.uncertainty,
          typeCode: riskLinkDetails.typeCode,
          justification: riskLink.justification,
          effect: riskLink.effect,
          links: riskLink.links,
          effectiveModelRMP: record.effectiveModelRMP,
          riskInfo: riskLink.riskInfo,
          label: UIUtils.getRecordLabelForDisplay(riskLinkDetails.typeCode, riskLinkDetails.id, riskLinkDetails.name),
          criticality: (riskLink.impact * riskLink.uncertainty),
        });
      }

      const uniqueSourceDocuments = [];
      for (const sourceDocument of sourceDocuments) {
        const doesSourceDocumentExists = uniqueSourceDocuments
          .find(uniqueDocument => uniqueDocument.uuid === sourceDocument.uuid);
        if (!doesSourceDocumentExists) {
          uniqueSourceDocuments.push(sourceDocument);
        }
      }

      record.criticalityAssessmentLinks = (documents || []).concat(uniqueSourceDocuments).sort(CommonUtils.sortBy("index"));
      record.riskLinksDetails = details;
      rows.push(record);
    }

    return this.wrapResults(rows);
  }
}
