"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import ImportPageTitleBar from "../widgets/pageTitleBar/import_page_title_bar";
import BasePage from "../base_page";
import ErrorBar from "../widgets/bars/error_bar";
import ImportRecordWidget from "./widgets/general/import_record_widget";
import Heading from "../widgets/headers/heading";
import { IMPORT_TYPES } from "./constants/import_constants";


export default class ImportDashboardPage extends BasePage {
  constructor(props) {
    super(props);

    let projectId = UIUtils.getParameterByName("projectId");
    let projectType = UIUtils.getParameterByName("projectType");
    let processId = UIUtils.getParameterByName("processId");
    let selectedTab = UIUtils.getParameterByName("selectedTab");

    this.state = {
      projectId,
      projectType,
      processId,
      selectedTab,
    };

    this.setStateSafely({
      breadcrumb: {
        current: "Import Data",
      },
    });
  }

  componentDidMount() {
    document.title = "QbDVision Import";

    super.componentDidMount();
  }

  renderPage() {
    const {projectId, projectType, processId, selectedTab} = this.state;

    return (
      <div>
        <ImportPageTitleBar name="Import Data" hasNoLinksInTitleBar={true} />
        <div className="container">
          <Heading level={1} className="header-banner">Choose data to import</Heading>
          <div className="row row-white import-dashboard-page">
            <ErrorBar className="import-error-bar" />
            <div className="import-dashboard-container col-sm-12">
              <div className="row">
                <ImportRecordWidget
                  importKey={IMPORT_TYPES.PROJECTS.key}
                  projectId={projectId}
                  projectType={projectType}
                  processId={processId}
                  selectedTab={selectedTab}
                />
                <ImportRecordWidget importKey={IMPORT_TYPES.SUPPLIERS.key} />
                <ImportRecordWidget importKey={IMPORT_TYPES.USERS.key} />
                <ImportRecordWidget importKey={IMPORT_TYPES.LIBRARY_MATERIAL.key} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
