"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import Cookies from "js-cookie";
import * as CommonUtils from "../../server/common/generic/common_utils";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import BaseReactComponent from "../base_react_component";

// i18next-extract-mark-ns-start users
/**
 * Update this whenever you updated the terms and conditions.
 */
class TermsPopup extends BaseReactComponent {
  constructor(props) {
    super(props);

    // Make sure they've logged in
    this.isUserLoggedIn = !!UIUtils.getAccessToken();
    if (!this.isUserLoggedIn) {
      UIUtils.clearSessionInfoForLogout();

      window.location.href = UIUtils.getSecuredURL("/index.html?reason=Expired&returnTo=" + encodeURIComponent(UIUtils.getSecuredURL(window.location.href, {
        enforceHostWithinQbDVisionDomain: true,
      })));
    }

    this.state = {
      termsAccepted: Cookies.get("TERMS_ACCEPTED") === "true",
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (!this.state.termsAccepted) {
      // These options are so that you can't click the background to make the dialog disappear.
      $(this.thisRef).modal({backdrop: "static", keyboard: false});
    }
  }

  handleApproveButton(event) {
    UIUtils.ignoreHandler(event);

    Cookies.set("TERMS_ACCEPTED", true);
    UIUtils.secureAjaxPUT("users/addOrEdit?updateTerms=true", {})
      .done(this.props.onApproveButtonCLick());
  }

  handleRejectButton(event) {
    UIUtils.ignoreHandler(event);
    UIUtils.clearSessionInfoForLogout();

    window.location.href = UIUtils.getSecuredURL("/index.html?reason=Terms");
  }

  render() {
    const {t} = this.props;
    let environment = CommonUtils.getDeployEnvironment();

    return this.isUserLoggedIn && !this.state.termsAccepted ? (
      <div className="modal fade terms-modal-on-top"
           ref={thisRef => {
             this.thisRef = thisRef;
             this.props.modalRef(thisRef);
           }}
      >
        <div className="modal-dialog modal-xl" id="termsModal">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">{t("Terms and Conditions")}</h1>
            </div>
            <div className="modal-body">
              <div className="modal-container popup-container">
                <div className="row">
                  <div className="col-sm-12">
                    {environment === "prod" || environment === "validated" ?
                      <iframe id="termsIFrame"
                              src="/terms/prodTermsOfService.html"
                      /> :
                      <iframe id="termsIFrame"
                              src="/terms/testTermsOfService.html"
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="modal-container">
                <div className="btn-group">
                  <button id="approveButton"
                          type="button"
                          className="btn btn-primary"
                          onClick={this.handleApproveButton}
                  >
                    {t("Approve")}
                  </button>
                  <button id="rejectButton"
                          type="button"
                          className="btn btn-danger"
                          onClick={this.handleRejectButton}
                  >
                    {t("Reject")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : "";
  }
}

export default I18NWrapper.wrap(TermsPopup, "users");
// i18next-extract-mark-ns-stop users
