var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { GridFilterCell } from '../cells/GridFilterCell';
import { operatorMap, booleanFilterValues, getFilterType } from '../filterCommon';
import { tableKeyboardNavigationTools as navigationTools, HeaderThElement } from '@progress/kendo-react-data-tools';
import { filterAriaLabel, messages } from '../messages';
/**
 * @hidden
 */
export var FILTER_ROW_CLASS = 'k-filter-row';
/**
 *
 * @hidden
 */
var FilterRow = /** @class */ (function (_super) {
    __extends(FilterRow, _super);
    function FilterRow() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FilterRow.prototype.headerCellClassName = function (field, locked) {
        var result = "".concat(locked ? 'k-grid-header-sticky' : '');
        if (this.props.sort &&
            this.props.sort.filter(function (descriptor) { return descriptor.field === field; }).length > 0) {
            result += ' k-sorted';
        }
        return result;
    };
    FilterRow.prototype.setFilter = function (value, operator, field, e) {
        if (!this.props.filterChange) {
            return;
        }
        var filters = [];
        if (value !== '' && value !== null || operator !== '') {
            filters.push({
                field: field,
                operator: operator,
                value: value
            });
        }
        if (this.props.filter && this.props.filter.filters) {
            var oldFilters = this.props.filter.filters || [];
            oldFilters.forEach(function (filter) {
                var descriptor = filter;
                if (descriptor && descriptor.field !== field) {
                    filters.push(descriptor);
                }
            });
        }
        var logic = this.props.filter && this.props.filter.logic ? this.props.filter.logic : 'and';
        this.props.filterChange(filters.length > 0 ? { logic: logic, filters: filters } : null, e);
    };
    FilterRow.prototype.render = function () {
        var _this = this;
        var intl = provideLocalizationService(this);
        var oldFilters = this.props.filter && this.props.filter.filters || [];
        var activeFilter = function (field) {
            if (field === undefined) {
                return;
            }
            var currentFilters = oldFilters.filter(function (filter) { return filter.field === field; });
            return currentFilters.length ? currentFilters[0] : undefined;
        };
        var serviceIndex = 0, index = -1;
        var filterCells = this.props.columns.filter(function (c) { return c.children.length === 0; })
            .map(function (column) {
            var filterType = getFilterType(column.filter);
            var currentActiveFilter = activeFilter(column.field);
            var ariaLabel = column.filterable ? intl.toLanguageString(filterAriaLabel, messages[filterAriaLabel]) : undefined;
            var value = currentActiveFilter && currentActiveFilter.value;
            if (value === undefined) {
                value = filterType === 'text' ? '' : null;
            }
            var filterCellProps = column.filterable && {
                render: _this.props.cellRender,
                field: column.field,
                title: column.filterTitle,
                value: value,
                operator: currentActiveFilter && currentActiveFilter.operator,
                operators: operatorMap(_this.props.filterOperators[filterType] || [], intl),
                booleanValues: operatorMap(booleanFilterValues, intl),
                filterType: filterType,
                ariaLabel: ariaLabel,
                onChange: function (e) { _this.setFilter(e.value, e.operator, column.field, e.syntheticEvent); }
            };
            var key = (column.declarationIndex >= 0) ? ++index : --serviceIndex;
            var ariaAttrs = {
                ariaLabel: ariaLabel,
                ariaColumnIndex: column.ariaColumnIndex
            };
            var style = column.left !== undefined
                ? !_this.props.isRtl
                    ? { left: column.left, right: column.right }
                    : { left: column.right, right: column.left }
                : {};
            return (React.createElement(HeaderThElement, __assign({ key: key, columnId: navigationTools.getFilterColumnId(column.id), navigatable: column.navigatable, style: style, className: _this.headerCellClassName(column.field, column.locked) || undefined }, ariaAttrs), filterCellProps && (column.filterCell ?
                React.createElement(column.filterCell, __assign({}, filterCellProps)) :
                React.createElement(GridFilterCell, __assign({}, filterCellProps)))));
        });
        return React.createElement("tr", { className: FILTER_ROW_CLASS, "aria-rowindex": this.props.ariaRowIndex, role: "row" }, filterCells);
    };
    return FilterRow;
}(React.Component));
export { FilterRow };
registerForLocalization(FilterRow);
