import React from "react";
import GenericEditableListTable from "../../widgets/tables/generic_editable_list_table";
import { Trans } from "react-i18next";
import * as I18NWrapper from "../../i18n/i18n_wrapper";

// i18next-extract-mark-ns-start training
export class MyTrainingListTable extends GenericEditableListTable {
  getItemName(rowData) {
    const {t} = this.props;
    if (!rowData) {
      throw new Error("A training document must have a name. Name is falsy.");
    }

    let name = rowData.name;

    if (rowData.retrainingCount > 0) {
      name = (<Trans t={t}>{{ name }} <sup className="retrained">(retrained)</sup></Trans>);
    }
    return name;
  }
}

export default I18NWrapper.wrap(MyTrainingListTable, ["training", "base_page"]);
// i18next-extract-mark-ns-stop training
