var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { NumericTextBox, Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { cellBoolDropdownChange, cellInputChange, cellOperatorChange } from '../filterCommon';
/**
 * @hidden
 */
var GridColumnMenuFilterCell = /** @class */ (function (_super) {
    __extends(GridColumnMenuFilterCell, _super);
    function GridColumnMenuFilterCell(props) {
        var _this = _super.call(this, props) || this;
        _this.renderOperatorEditor = function () {
            if (_this.props.filterType === 'boolean') {
                return;
            }
            var value = _this.props.operators.find(function (item) { return item.operator === _this.props.operator; }) || null;
            return (React.createElement(DropDownList, { value: value, onChange: _this.operatorChange, data: _this.props.operators, textField: "text" }));
        };
        _this.inputChange = _this.inputChange.bind(_this);
        _this.operatorChange = _this.operatorChange.bind(_this);
        _this.boolDropdownChange = _this.boolDropdownChange.bind(_this);
        return _this;
    }
    /**
     * @hidden
     */
    GridColumnMenuFilterCell.prototype.render = function () {
        return (React.createElement(React.Fragment, null,
            this.renderOperatorEditor(),
            this.filterComponent(this.props.filterType, this.props.value, this.props.booleanValues)));
    };
    GridColumnMenuFilterCell.prototype.inputChange = function (value, e) {
        cellInputChange(value, e, this.props);
    };
    GridColumnMenuFilterCell.prototype.operatorChange = function (event) {
        cellOperatorChange(event, this.props.value, this.props.onChange);
    };
    GridColumnMenuFilterCell.prototype.boolDropdownChange = function (event) {
        cellBoolDropdownChange(event, this.props.onChange);
    };
    GridColumnMenuFilterCell.prototype.filterComponent = function (filterType, value, booleanValues) {
        var _this = this;
        switch (filterType) {
            case 'numeric':
                return (React.createElement(NumericTextBox, { value: value, onChange: function (e) { _this.inputChange(e.value, e.syntheticEvent); }, rangeOnEnter: false }));
            case 'date':
                return (React.createElement(DatePicker, { value: value, onChange: function (e) { _this.inputChange(e.value, e.syntheticEvent); } }));
            case 'boolean':
                var noFilterSet_1 = function (filter) { return filter === null || filter === undefined; };
                return (React.createElement(DropDownList, { onChange: this.boolDropdownChange, value: booleanValues.find(function (item) { return item.operator === (noFilterSet_1(value) ? '' : value); }), data: booleanValues, textField: "text" }));
            default: return (React.createElement(Input, { value: value || '', onChange: function (e) { _this.inputChange(e.target.value, e.syntheticEvent); } }));
        }
    };
    return GridColumnMenuFilterCell;
}(React.Component));
export { GridColumnMenuFilterCell };
