"use strict";

import { TEXT_DIFF_METHOD_ENUM } from "../../helpers/constants/constants";
import { FIELD_INPUT_TYPE, FIELD_REQUIRED_FOR } from "../../editor/widgets/widget_field";

/**
 * @type {IWidgetField[]}
 */
export const WIDGET_FIELDS = [
  {
    fieldName: "from",
    customValidator: "validateFromToFields",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    displayName: "From (%)",
    requiredFor: FIELD_REQUIRED_FOR.save,
    forceUpdate: false,
    defaultValue: "getFromFieldDefaultValue",
    placeholder: "",
    belongsToMasterRow: true,
    allowDecimalNumbers: true,
    min: "getMinRiskPercentageValue",
    order: 1,
    inputType: FIELD_INPUT_TYPE.number,
    width: 200,
    disabled: "isScaleDisabled",
  },
  {
    fieldName: "to",
    customValidator: "validateFromToFields",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    displayName: "To (%)",
    requiredFor: FIELD_REQUIRED_FOR.save,
    forceUpdate: false,
    defaultValue: "",
    placeholder: "",
    belongsToMasterRow: true,
    allowDecimalNumbers: true,
    min: "getMinRiskPercentageValue",
    max: 100,
    order: 2,
    inputType: FIELD_INPUT_TYPE.number,
    width: 200,
    disabled: "isScaleDisabled",
  },
  {
    fieldName: "critical",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    displayName: "Critical",
    requiredFor: FIELD_REQUIRED_FOR.none,
    forceUpdate: true,
    defaultValue: "getCriticalDefaultValue",
    belongsToMasterRow: true,
    orderable: false,
    order: 3,
    inputType: FIELD_INPUT_TYPE.checkbox,
    width: 100,
    disabled: "isCriticalDisabled",
  },
  {
    fieldName: "scoreLabel",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    displayName: "Score Label",
    requiredFor: FIELD_REQUIRED_FOR.save,
    forceUpdate: false,
    defaultValue: "",
    placeholder: "",
    maxLength: 15,
    belongsToMasterRow: true,
    order: 4,
    inputType: FIELD_INPUT_TYPE.text,
    width: 300,
  },
  {
    fieldName: "riskLabel",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    displayName: "Risk Label",
    requiredFor: FIELD_REQUIRED_FOR.proposal,
    forceUpdate: false,
    defaultValue: "",
    placeholder: "",
    belongsToMasterRow: true,
    order: 5,
    inputType: FIELD_INPUT_TYPE.text,
    width: 600,
  },
  {
    fieldName: "color",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    displayName: "Color",
    requiredFor: FIELD_REQUIRED_FOR.save,
    forceUpdate: false,
    defaultValue: "Blue",
    placeholder: "",
    belongsToMasterRow: true,
    order: 6,
    inputType: FIELD_INPUT_TYPE.select,
    getOptions: "getRiskColors",
    width: 100,
    disabled: "isColorDisabled",
  },
  {
    fieldName: "developmentActivity",
    diffMethod: TEXT_DIFF_METHOD_ENUM.differential,
    displayName: "Development Activity",
    requiredFor: FIELD_REQUIRED_FOR.none,
    forceUpdate: false,
    defaultValue: "",
    placeholder: "",
    belongsToMasterRow: false,
    order: 7,
    rows: 4,
    inputType: FIELD_INPUT_TYPE.textarea,
  },
  {
    fieldName: "controlStrategy",
    diffMethod: TEXT_DIFF_METHOD_ENUM.differential,
    displayName: "Control Strategy",
    requiredFor: FIELD_REQUIRED_FOR.none,
    forceUpdate: false,
    defaultValue: "",
    placeholder: "",
    belongsToMasterRow: false,
    order: 8,
    rows: 4,
    inputType: FIELD_INPUT_TYPE.textarea,
  }
];
