"use strict";

import React from "react";
import ErrorBar from "../widgets/bars/error_bar";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import * as UIUtils from "../ui_utils";
import * as styles from "../configurableTables/tables/styles/configurable_table.module.scss";
import ConfigurableTablesColumnOperationsAdapter
  from "../configurableTables/columnOperations/configurable_tables_column_operations_adapter";
import ConfigurableTablesColumnSelectionHandler
  from "../configurableTables/columnSelection/configurable_tables_column_selection_handler";
import ConfigurableTablesExportColumnGenerator
  from "../configurableTables/export/configurable_tables_export_column_generator";
import ConfigurableTablePage from "../configurableTables/tables/configurable_table_page";
import { TableToolBar } from "../configurableTables/tables/widgets/topBar/toolBar/table_tool_bar";
import { ShowArchiveToggle } from "../configurableTables/widgets/show_archive_toggle";
import { SUPPLIER_CONFIG } from "./tables/supplier_table_config";
import ConfigurableTablesColumnOperationsHandler
  from "../configurableTables/columnOperations/configurable_tables_column_operations_handler";
import * as FailHandlers from "../utils/fail_handlers";
import { TableStateProvider } from "../configurableTables/tables/table_state_provider";
import { WithNotification } from "../configurableTables/tables/widgets/notification/with_notification";
import { TableSettingsProvider } from "../configurableTables/tables/table_settings_provider";

class SupplierTablePage extends ConfigurableTablePage {
  constructor(props) {
    super(props);

    this.resetState(props);

    this.columnOperationsAdapter = new ConfigurableTablesColumnOperationsAdapter();
    this.columnOperationsHandler = new ConfigurableTablesColumnOperationsHandler({
      parent: this,
      columnOperationsAdapter: this.columnOperationsAdapter,
    });

    this.columnSelectionHandler = new ConfigurableTablesColumnSelectionHandler({
      parent: this,
      modelsConfig: SUPPLIER_CONFIG,
      columnOperationsAdapter: this.columnOperationsAdapter,
      exportColumnGenerator: ConfigurableTablesExportColumnGenerator,
    }, false, "defaultValue");

    props.onUpdateTableDependencies({
      columnSelectionHandler: this.columnSelectionHandler,
      columnOperationsHandler: this.columnOperationsHandler,
      columnOperationsAdapter: this.columnOperationsAdapter,
    });

    this.loadTableData();
  }

  filterAdaptedRows(rows) {
    const {showArchived} = this.props;
    return rows.filter(supplier => showArchived || supplier.deletedAt === null);
  }


  loadTableData() {
    const urlParameters = {
      showRemoved:true,
      includeHistory: true,
      loadFullHistory: true,
    };

    let stateObject = this.state;
    this.columnOperationsHandler.handleDefaultColumnOperationsFromURL(stateObject);
    this.setStateSafely({...stateObject}, () => {
      UIUtils.secureAjaxGET(`editables/Supplier/list`,
        urlParameters,
        true,
        FailHandlers.defaultStopLoadingFailFunction.bind(this))
        .done(this.handleReceivedDataFromServer);
    });
  }

  getAdaptedInfo(records) {
    const {selectedModelType, onTableDataUpdated} = this.props;
    const resultAdapter = SUPPLIER_CONFIG["Suppliers"]
      .adapter(
        {
          results: UIUtils.deepClone(records),
          selectedModelType,
        }
      );

    const adaptedData = resultAdapter.getRows();
    onTableDataUpdated(adaptedData);
    return adaptedData;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      showArchived,
      onTableStateChange,
      selectedModelType,
      onVisibleColumnsChange
    } = this.props;
    const {
      records,
      tableState
    } = this.state;
    const oldShowArchived = prevProps.showArchived;

    if (records
      && ((JSON.stringify(records) !== JSON.stringify(prevState.records)) || (oldShowArchived !== showArchived))) {
      const adaptedRecords = this.getAdaptedInfo(records);

      this.setStateSafely(
        {
          adaptedRecords,
          selectedModelType,
          filteredRows: this.filterAdaptedRows(adaptedRecords),
          securityTypeName: this.getSecurityTypeName(selectedModelType),
        }, () =>
          this.columnSelectionHandler.handleResetColumnVisibility(onVisibleColumnsChange));
    }

    if (JSON.stringify(tableState) !== JSON.stringify(prevState.tableState)) {
      onTableStateChange(tableState);
    }
  }

  render() {

    const {
      t,
      selectedModelType,
      handleShowArchive,
      onVisibleColumnsChange
    } = this.props;

    const {
      filteredRows,
      tableState,
      visibleTableColumns,
    } = this.state;

    const {
      handleColumnOperationsChange,
    } = this.columnOperationsHandler;

    const {customFilters} = tableState || {};
    const config = SUPPLIER_CONFIG[selectedModelType];
    const {fieldsConfig, customFiltersHandler, modelName, table} = config || {};
    const sort = this.columnOperationsAdapter.getDefaultSorting(tableState);
    const supplierTable = config ?
      table({
        id: "supplierTable",
        records: filteredRows || [],
        selectedModelType,
        fieldsConfig,
        modelName,
        tableState: {
          ...tableState,
          sort,
        },
        t,
        customFilters,
        customFiltersHandler,
        onImportButtonClick: this.handleImport,
        onEmptyTableAddClicked: this.handleEmptyTableAddButton,
        handleArchiveButton: this.handleArchiveButton,
        handleRestoreButton: this.handleRestoreButton,
        visibleTableColumns: visibleTableColumns || [],
        columnOperationsAdapter: this.columnOperationsAdapter,
        onColumnOperationsChange: handleColumnOperationsChange,
        parent: this,
      }) : "";

    return (
      <TableStateProvider>
        <>
        <TableToolBar selectedModelType={selectedModelType}
                      modelName={selectedModelType}
                      showAssessmentOptions={false}
                      visibleTableColumns={visibleTableColumns}
                      tableState={tableState}
                      customFilters={customFilters}
                      customFiltersHandler={customFiltersHandler}
                      operationsAdapter={this.columnOperationsAdapter}
                      operationsHandler={this.columnOperationsHandler}
                      onResetToDefaults={this.columnSelectionHandler?.handleResetToDefaults}
                      onVisibleTableColumnsChanged={(map) =>
                        this.columnSelectionHandler?.handleVisibleTableColumnsChanged(map, onVisibleColumnsChange)}
                      records={filteredRows}
                      quickFiltersZone={
                        <ShowArchiveToggle t={t}
                                           type={selectedModelType}
                                           onShowArchiveChange={handleShowArchive}
                        />
                      }

        />
        <div className={styles["configurable-table"]}>
          <div id="supplierContainer" className={styles["configurable-table-inner-container"]}>
            <ErrorBar className={`mt-2 ${styles["error-bar"]}`} />
            <TableSettingsProvider visibleTableColumns={visibleTableColumns}>
              {supplierTable}
            </TableSettingsProvider>
          </div>
        </div>
        {this.renderArchivePopup()}
        </>
      </TableStateProvider>
    );
  }
}

export default I18NWrapper.wrap(WithNotification(SupplierTablePage), "suppliers");


