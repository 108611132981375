"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ErrorBar from "../../widgets/bars/error_bar";
import TPPListTable from "../lists/tpp_list";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import TableCollapseCard from "../../widgets/tables/table_collapse_card";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import BaseQuickPage from "../../base_quick_page";

/* This shows the TPP tab in the product page. */

// i18next-extract-mark-ns-start tpp
class TPP extends BaseQuickPage {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {t} = this.props;
    document.title = t("QbDVision TPP");
    this.projectId = UIUtils.parseInt(UIUtils.getParameterByName("projectId"));
    this.setStateSafely({TPPsCount: null});

    super.componentDidMount();
  }

  handleTPPsDataReceived(TPPs) {
    this.props.onUpdateRefs(this.tableRefs);
    const TPPsCount = TPPs ? TPPs.instances.length : 0;
    this.setStateSafely({TPPsCount});
    this.handleQuickPanelInstanceReceived(TPPs);
    this.props.onUpdateRecordCount(TPPsCount);
  }

  handleQuickPanelInstanceReceived(result) {
    this.instances = result && result.instances ? result.instances : [];
    this.props.onTypeaheadOptionsReceived(this.instances);
  }

  collapseAllSections() {
    return false;
  }

  renderPage() {
    const {TPPsCount} = this.state;
    const {globalExpandStatus, onLocalExpandStatusChange, searchTerm, showArchived, t} = this.props;

    return (
      <div className="ml-5 mb-5 mr-5">
        <ErrorBar className="product-error-bar" />
        <TableCollapseCard
          title={
            <span>
              {t(`Target Product Profile`)} (<span id="tppsCount">{TPPsCount || 0}</span>)
            </span>
          }
          infoTooltip={
            <InfoTooltip id="infoTPP"
                         verbiage={
                           <div>
                             {t("The Target Product Profile (TPP) provides a statement of the overall intent of the drug development program and gives information about the drug at a particular time in development. The TPP is organized according to the key sections in the drug labeling and links drug development activities to specific concepts intended for inclusion in the drug labeling.")}
                           </div>
                         }
            />}
          name="TPPListTable"
          rowsCount={TPPsCount}
          globalExpandStatus={globalExpandStatus}
          onLocalExpandStatusChange={onLocalExpandStatusChange}
          modelName="TPP"
          body={<TPPListTable
            parent={this}
            ref={ref => this.addTableRef(ref)}
            className="list-table-not-borders"
            allowRecordCopy={true}
            showAsOfDate={true}
            projectId={this.projectId}
            hideQuickPanelUI={true}
            hideShowArchivedButton={true}
            hideShowTableInfo={true}
            saveState={false}
            searchTerm={searchTerm}
            showArchived={showArchived}
            onDataReceived={this.handleTPPsDataReceived}
            onQuickEditRecord={this.handleQuickEditRecord} />}
        />
      </div>
    );
  }

  render() {
    return (
      <div id="bodyDiv">
        {this.renderQuickPanel()}
        {this.renderPage()}
      </div>
    );
  }
}

export default I18NWrapper.wrap(TPP, "tpp");
// i18next-extract-mark-ns-stop tpp
