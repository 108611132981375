var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { footerColumns } from './../utils';
/**
 * @hidden
 */
var FooterRow = /** @class */ (function (_super) {
    __extends(FooterRow, _super);
    function FooterRow() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._element = null;
        _this.renderCell = function (column, index) {
            var tdProps = {
                colSpan: column.colSpan !== 1 ? column.colSpan : undefined,
                style: column.left !== undefined
                    ? !_this.props.isRtl
                        ? { left: column.left, right: column.right }
                        : { left: column.right, right: column.left }
                    : {},
                className: column.locked && column.left !== undefined ? 'k-grid-footer-sticky' : ''
            };
            var cellProps = __assign({ field: column.field, ariaColumnIndex: column.ariaColumnIndex }, tdProps);
            return (column.footerCell && React.createElement(column.footerCell, __assign({}, cellProps, { key: index }))) || (React.createElement("td", __assign({ key: index }, tdProps, { role: 'columnfooter', "aria-selected": false, "aria-colindex": column.ariaColumnIndex })));
        };
        return _this;
    }
    Object.defineProperty(FooterRow.prototype, "element", {
        get: function () {
            return this._element;
        },
        enumerable: false,
        configurable: true
    });
    FooterRow.prototype.render = function () {
        return React.createElement("tr", { role: "row", "aria-rowindex": this.props.ariaRowIndex }, footerColumns(this.props.columns).map(this.renderCell));
    };
    return FooterRow;
}(React.Component));
export { FooterRow };
