"use strict";

import React from "react";
import * as UIUtils from "../../ui_utils";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { EditablesService } from "../../services/editables/editables_service";
import BaseAutoBind from "../../base_auto_bind";
import CommonConstants from "../../../server/common/generic/common_constants";

export default class RecordOperationsHandler extends BaseAutoBind {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.projectId = props.projectId;
  }

  handleShowArchived(showArchived) {
    const {parent} = this;
    const {processId, processes} = parent.state;
    let process = processes.find(process => process.id === processId);

    // If selected process is an archived one, move to process explorer page.
    if (process && process.deletedAt !== null) {
      window.location.href = UIUtils.getSecuredURL(UIUtils.FRONT_END_URL
        + "/processExplorer/processExplorer.html?projectId=" + this.projectId);
    } else {
      let visibleProcesses = showArchived ? processes : processes.filter(process => process.deletedAt === null);
      parent.setStateSafely({showArchived, visibleProcesses}, () => {
        UIUtils.pushHistoryURLWithNewParameter({}, "showArchived", showArchived);
      });
    }
  }

  handleArchiveOrRestoreButton(nodeData, action) {
    const {parent} = this;
    UIUtils.setLoadingDisabled(false);
    parent.processExplorerHelper.waitForRecordLoading().then(() => {
      const {typeCode, id} = UIUtils.parseKey(nodeData.staticPanelKey);
      const isArchive = action === CommonConstants.ACTIONS.ARCHIVE;
      const staticPanelRecord = parent.processExplorerHelper.findStaticPanelRecord(typeCode, id);
      let instance = Object.assign({}, staticPanelRecord);
      parent.setStateSafely({
        action,
        instance,
        securityTypeName: UIUtils.getModelNameForTypeCode(typeCode),
        message: (
          <div>
            {isArchive ? "Archiving will remove" : "Restoring will restore"}&nbsp;
            <a href={getURLByTypeCodeAndId(typeCode, "View", id)} target="_blank" rel="noopener noreferrer">
              <b>{nodeData.fullName}</b>
            </a>
            &nbsp; {isArchive ? "from view, but will not delete the data permanently." : ""}
          </div>
        ),
      }, () => {
        let url = "editables/" + UIUtils.convertToId(UIUtils.getModelNameForTypeCode(typeCode))
          + "/" + id + "?includeHistory=false&approved=false&childrenOnly=" + action;
        let parentObjectDetails = {
          typeCode,
          id,
          name: nodeData.fullName
        };
        UIUtils.secureAjaxGET(url).done(parent.dataReceiver.handleReceivedChildObjectsFromServer.bind(this, action, parentObjectDetails));
      });
    });
  }


  handleHideApprovalPopup() {
    const {parent} = this;
    parent.setStateSafely({
      showApprovalPopup: false
    });
    $(parent.approvalPopup).modal("hide");
  }

  async handleProposeForArchiveOrRestoreConfirmation() {
    const {parent} = this;
    parent.setStateSafely({
      showApprovalPopup: false
    });
    $(parent.approvalPopup).modal("hide");

    UIUtils.setLoadingDisabled(false);
    try {
      await parent.processExplorerHelper.waitForRecordLoading();
      const {selectedNode} = parent.state;
      const {typeCode, id} = UIUtils.parseKey(selectedNode.staticPanelKey);
      const staticPanelRecord = parent.processExplorerHelper.findStaticPanelRecord(typeCode, id);
      let instance = Object.assign({}, staticPanelRecord);
      instance.approvalInfo = Object.assign({}, parent.state.approvalInfo);
      instance.approvalInfo.action = parent.state.action;

      let model = UIUtils.getModelNameForTypeCode(typeCode);

      const editablesService = new EditablesService();

      let parameters = {
        model,
        urlPrefix: UIUtils.pluralize(model),
        useTwoWayCommunication: true,
      };

      let savedInstance = await editablesService.save(instance, parameters);
      return parent.staticPanelOperator.handleSaveInStaticPanel(savedInstance, true, model);
    } catch (result) {
      UIUtils.defaultFailFunction(result, null, null, UIUtils.PRIMARY_ALERT_DIV);
      $(parent.approvalPopup).modal("hide");
    } finally {
      UIUtils.setLoadingDisabled(true);
    }
  }

  handleBulkPropose(selectedNode, isFromProcessExplorer = true) {
    const {parent} = this;
    const {typeCode, id} = UIUtils.parseKey(isFromProcessExplorer ? selectedNode.staticPanelKey : selectedNode);
    const proposedRecords = [];

    proposedRecords.push(`${typeCode}-${id}`);
    UIUtils.setLoadingDisabled(false);
    UIUtils.showLoadingImage("Processing records for bulk propose...");
    isFromProcessExplorer ? parent.processExplorerHelper.waitForRecordLoading().then(() => {
      this.getBulkProposeRecords(proposedRecords, typeCode, id);
    }) : this.getBulkProposeRecords(proposedRecords, typeCode, id);
  }

  getBulkProposeRecords(proposedRecords, typeCode, id) {

    let url = "editables/" + UIUtils.convertToId(UIUtils.getModelNameForTypeCode(typeCode))
      + "/" + id + "?includeHistory=false&approved=false&childrenOnly=propose";
    UIUtils.secureAjaxGET(url).done((childRecords) => {

      proposedRecords.push(...childRecords.map((record) => `${record.typeCode}-${record.id}`));
      sessionStorage["bulkProposalRequirementIds"] = JSON.stringify(proposedRecords);

      window.location.href = UIUtils.getSecuredURL(`/projects/bulkProposal.html?projectId=${this.projectId}&cascadingProposal=true`);
    });
  }

}
