var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { EditorToolsSettings } from '../config/toolsSettings';
import { InsertTableToolNS, InsertTablePopupNS } from './insertTable';
import * as AlignTool from './align';
import { FontStyleToolNS } from './fontStyle';
import { ApplyColorTool } from './applyColor';
import { FormatBlockToolNS } from './formatBlock';
import { HistoryToolNS } from './history';
import { IndentToolNS } from './indent';
import { InlineFormatToolNS } from './inlineFormat';
import * as InsertImageTool from './insertImage';
import { LinkToolNS } from './insertLink';
import { ListToolNS } from './lists';
import { OutdentToolNS } from './outdent';
import { TableEditNS } from './tableEdit';
import { UnlinkToolNS } from './unlink';
import { ViewHtmlToolNS } from './viewHtml';
import { Print as PrintTool } from './print';
import { Pdf as PdfTool } from './pdf';
import { SelectAll as SelectAllTool } from './selectAll';
import { CleanFormatting as CleanFormattingTool } from './cleanFormatting';
import { FindAndReplace as FindAndReplaceTool } from './findReplace';
import { ListTool } from './lists-styled';
/**
 * Represents a wrapping namespace for the tool components, props, and functions of the Editor.
 */
export var EditorTools;
(function (EditorTools) {
    /**
     * Creates the Align tool component of the Editor.
     *
     * @param {AlignSettings} settings
     * @returns {React.ComponentClass<AlignToolProps>} - Editor's Align tool component.
     */
    function createAlignTool(settings) { return AlignTool.createAlignTool(settings); }
    EditorTools.createAlignTool = createAlignTool;
    /**
     * The AlignLeft tool component.
     */
    var AlignLeft = /** @class */ (function (_super) {
        __extends(AlignLeft, _super);
        function AlignLeft() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return AlignLeft;
    }(createAlignTool(EditorToolsSettings.alignLeft)));
    EditorTools.AlignLeft = AlignLeft;
    /**
     * The AlignRight tool component.
     */
    var AlignRight = /** @class */ (function (_super) {
        __extends(AlignRight, _super);
        function AlignRight() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return AlignRight;
    }(createAlignTool(EditorToolsSettings.alignRight)));
    EditorTools.AlignRight = AlignRight;
    /**
     * The AlignCenter tool component.
     */
    var AlignCenter = /** @class */ (function (_super) {
        __extends(AlignCenter, _super);
        function AlignCenter() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return AlignCenter;
    }(createAlignTool(EditorToolsSettings.alignCenter)));
    EditorTools.AlignCenter = AlignCenter;
    /**
     * The AlignJustify tool component.
     */
    var AlignJustify = /** @class */ (function (_super) {
        __extends(AlignJustify, _super);
        function AlignJustify() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return AlignJustify;
    }(createAlignTool(EditorToolsSettings.alignJustify)));
    EditorTools.AlignJustify = AlignJustify;
    /**
     * Creates the InsertTable tool component of the Editor.
     *
     * @param {InsertTableSettings} - The settings of the InsertTable tool.
     * @returns {React.ComponentClass<InsertTableProps>} - The InsertTable tool component of the Editor.
     */
    function createInsertTableTool(settings) { return InsertTableToolNS.createInsertTableTool(settings); }
    EditorTools.createInsertTableTool = createInsertTableTool;
    /**
     * The InsertTable tool component.
     */
    var InsertTable = /** @class */ (function (_super) {
        __extends(InsertTable, _super);
        function InsertTable() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return InsertTable;
    }(createInsertTableTool(EditorToolsSettings.insertTable)));
    EditorTools.InsertTable = InsertTable;
    /**
     * The `InsertTablePopup` component.
     */
    var InsertTablePopup = /** @class */ (function (_super) {
        __extends(InsertTablePopup, _super);
        function InsertTablePopup() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return InsertTablePopup;
    }(InsertTablePopupNS.InsertTablePopup));
    EditorTools.InsertTablePopup = InsertTablePopup;
    /**
     * Creates the DropDownList tool component of the Editor which applies inline styles to inline HTML elements.
     *
     * @param {StyleDropDownListSettings} settings
     * @returns {React.ComponentClass<DropDownListToolProps>} - The DropDownList tool component of the Editor.
     */
    function createStyleDropDownList(settings) { return FontStyleToolNS.createStyleDropDownList(settings); }
    EditorTools.createStyleDropDownList = createStyleDropDownList;
    /**
     * The FontSize tool component.
     */
    var FontSize = /** @class */ (function (_super) {
        __extends(FontSize, _super);
        function FontSize() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return FontSize;
    }(createStyleDropDownList(EditorToolsSettings.fontSize)));
    EditorTools.FontSize = FontSize;
    /**
     * The FontName tool component.
     */
    var FontName = /** @class */ (function (_super) {
        __extends(FontName, _super);
        function FontName() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return FontName;
    }(createStyleDropDownList(EditorToolsSettings.fontName)));
    EditorTools.FontName = FontName;
    /**
     * The ForeColor tool component.
     */
    EditorTools.ForeColor = function (props) {
        return React.createElement(ApplyColorTool, __assign({}, EditorToolsSettings.foreColor, props));
    };
    /**
     * The BackColor tool component.
     */
    EditorTools.BackColor = function (props) {
        return React.createElement(ApplyColorTool, __assign({}, EditorToolsSettings.backColor, props));
    };
    /**
     * Creates the FormatBlock tool component of the Editor.
     *
     * @param {FormatBlockDropDownListSettings} settings
     * @returns {React.ComponentClass<FormatBlockProps>} - The FormatBlock tool component of the Editor.
     */
    function createFormatBlockDropDownList(settings) { return FormatBlockToolNS.createFormatBlockDropDownList(settings); }
    EditorTools.createFormatBlockDropDownList = createFormatBlockDropDownList;
    /**
     * The FormatBlock tool component.
     */
    var FormatBlock = /** @class */ (function (_super) {
        __extends(FormatBlock, _super);
        function FormatBlock() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return FormatBlock;
    }(createFormatBlockDropDownList(EditorToolsSettings.formatBlock)));
    EditorTools.FormatBlock = FormatBlock;
    /**
     * Creates the Undo tool component of the Editor.
     *
     * @param {ToolSettings} settings
     * @returns {React.ComponentClass<UndoProps>} - The Undo tool component of the Editor.
     */
    function createUndoTool(settings) { return HistoryToolNS.createUndoTool(settings); }
    EditorTools.createUndoTool = createUndoTool;
    /**
     * Creates the Redo tool component of the Editor.
     *
     * @param {ToolSettings} settings
     * @returns {React.ComponentClass<RedoProps>} - The Redo tool component of the Editor.
     */
    function createRedoTool(settings) { return HistoryToolNS.createRedoTool(settings); }
    EditorTools.createRedoTool = createRedoTool;
    /**
     * The Undo tool component of the Editor.
     */
    var Undo = /** @class */ (function (_super) {
        __extends(Undo, _super);
        function Undo() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return Undo;
    }(createUndoTool(EditorToolsSettings.undo)));
    EditorTools.Undo = Undo;
    /**
     * The Redo tool component of the Editor.
     */
    var Redo = /** @class */ (function (_super) {
        __extends(Redo, _super);
        function Redo() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return Redo;
    }(createRedoTool(EditorToolsSettings.redo)));
    EditorTools.Redo = Redo;
    /**
     * Creates the Indent tool component of the Editor.
     *
     * @param {IndentationSettings} settings
     * @returns {React.ComponentClass<IndentProps>} - The Indent tool component of the Editor.
     */
    function createIndentTool(settings) { return IndentToolNS.createIndentTool(settings); }
    EditorTools.createIndentTool = createIndentTool;
    /**
     * The Indent tool component.
     */
    var Indent = /** @class */ (function (_super) {
        __extends(Indent, _super);
        function Indent() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return Indent;
    }(createIndentTool(EditorToolsSettings.indent)));
    EditorTools.Indent = Indent;
    /**
     * Creates the InlineFormat tool component of the Editor.
     *
     * @param {InlineFormatSettings} settings
     * @returns {React.ComponentClass<InlineFormatToolProps>} - Editor's InlineFormat tool component.
     */
    function createInlineFormatTool(settings) {
        return InlineFormatToolNS.createInlineFormatTool(settings);
    }
    EditorTools.createInlineFormatTool = createInlineFormatTool;
    /**
     * The Bold tool component.
     */
    var Bold = /** @class */ (function (_super) {
        __extends(Bold, _super);
        function Bold() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return Bold;
    }(createInlineFormatTool(EditorToolsSettings.bold)));
    EditorTools.Bold = Bold;
    /**
     * The Italic tool component.
     */
    var Italic = /** @class */ (function (_super) {
        __extends(Italic, _super);
        function Italic() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return Italic;
    }(createInlineFormatTool(EditorToolsSettings.italic)));
    EditorTools.Italic = Italic;
    /**
     * The Underline tool component.
     */
    var Underline = /** @class */ (function (_super) {
        __extends(Underline, _super);
        function Underline() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return Underline;
    }(createInlineFormatTool(EditorToolsSettings.underline)));
    EditorTools.Underline = Underline;
    /**
     * The Strikethrough tool component.
     */
    var Strikethrough = /** @class */ (function (_super) {
        __extends(Strikethrough, _super);
        function Strikethrough() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return Strikethrough;
    }(createInlineFormatTool(EditorToolsSettings.strikethrough)));
    EditorTools.Strikethrough = Strikethrough;
    /**
     * The Subscript tool component.
     */
    var Subscript = /** @class */ (function (_super) {
        __extends(Subscript, _super);
        function Subscript() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return Subscript;
    }(createInlineFormatTool(EditorToolsSettings.subscript)));
    EditorTools.Subscript = Subscript;
    /**
     * The Superscript tool component.
     */
    var Superscript = /** @class */ (function (_super) {
        __extends(Superscript, _super);
        function Superscript() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return Superscript;
    }(createInlineFormatTool(EditorToolsSettings.superscript)));
    EditorTools.Superscript = Superscript;
    /**
     * Creates the InsertImage tool component of the Editor.
     *
     * @param {ImageSettings} settings
     * @returns {React.ComponentClass<InsertImageProps>} - The InsertImage tool component of the Editor.
     */
    function createInsertImageTool(settings) { return InsertImageTool.createInsertImageTool(settings); }
    EditorTools.createInsertImageTool = createInsertImageTool;
    /**
     * The InsertImage tool component.
     */
    var InsertImage = /** @class */ (function (_super) {
        __extends(InsertImage, _super);
        function InsertImage() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return InsertImage;
    }(createInsertImageTool(EditorToolsSettings.image)));
    EditorTools.InsertImage = InsertImage;
    /**
     * Creates the Link tool component of the Editor.
     *
     * @param {LinkSettings} settings
     * @returns {React.ComponentClass<LinkProps>} - The Link tool component of the Editor.
     */
    function createLinkTool(settings) { return LinkToolNS.createLinkTool(settings); }
    EditorTools.createLinkTool = createLinkTool;
    /**
     * The Link tool component.
     */
    var Link = /** @class */ (function (_super) {
        __extends(Link, _super);
        function Link() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return Link;
    }(createLinkTool(EditorToolsSettings.link)));
    EditorTools.Link = Link;
    /**
     * The InsertFile tool component.
     */
    var InsertFile = /** @class */ (function (_super) {
        __extends(InsertFile, _super);
        function InsertFile() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return InsertFile;
    }(createLinkTool(EditorToolsSettings.insertFile)));
    EditorTools.InsertFile = InsertFile;
    /**
     * Creates the List tool component of the Editor.
     *
     * @param {ToolSettings} settings
     * @returns {React.ComponentClass<ListToolProps>} - The List tool component of the Editor.
     */
    function createListTool(settings) { return ListToolNS.createListTool(settings); }
    EditorTools.createListTool = createListTool;
    /**
     * The basic OrderedList tool component. Will render a button which applies `<ol>` HTML element.
     */
    var OrderedList = /** @class */ (function (_super) {
        __extends(OrderedList, _super);
        function OrderedList() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return OrderedList;
    }(createListTool(EditorToolsSettings.orderedList)));
    EditorTools.OrderedList = OrderedList;
    /**
     * The basic UnorderedList tool component. Will render a button which applies `<ul>` HTML element.
     */
    var UnorderedList = /** @class */ (function (_super) {
        __extends(UnorderedList, _super);
        function UnorderedList() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return UnorderedList;
    }(createListTool(EditorToolsSettings.bulletList)));
    EditorTools.UnorderedList = UnorderedList;
    /**
     * The BulletedList tool component.
     * Will render a SplitButton which applies `<ol>` HTML element with predefined styles - disc and square.
     */
    EditorTools.BulletedList = function (props) {
        var ulItems = [
            { icon: 'list-unordered', text: 'Disc', style: 'disc' },
            { icon: 'list-unordered-outline', text: 'Circle', style: 'circle' },
            { icon: 'list-unordered-square', text: 'Square', style: 'square' }
        ];
        return (React.createElement(ListTool, __assign({ listType: EditorToolsSettings.bulletList.listType, items: ulItems, icon: "list-unordered", titleKey: EditorToolsSettings.bulletList.messages.title }, props)));
    };
    /**
     * The NumberedList tool component.
     * Will render a SplitButton which applies `<ul>` HTML element with predefined styles - upper-roman, lower-roman, upper-latin and lower-latin.
     */
    EditorTools.NumberedList = function (props) {
        var olItems = [
            { icon: 'list-ordered', text: 'Decimal' },
            // { icon: 'list-leading-zero', text: 'Decimal with leading zero', styleType: 'decimal-leading-zero' },
            { icon: 'list-roman-upper', text: 'Upper roman', style: 'upper-roman' },
            { icon: 'list-roman-lower', text: 'Lower roman', style: 'lower-roman' },
            { icon: 'list-latin-big', text: 'Upper latin', style: 'upper-latin' },
            { icon: 'list-latin-small', text: 'Lower latin', style: 'lower-latin' }
        ];
        return (React.createElement(ListTool, __assign({ listType: EditorToolsSettings.orderedList.listType, items: olItems, icon: "list-ordered", titleKey: EditorToolsSettings.orderedList.messages.title }, props)));
    };
    /**
     * Creates the Outdent tool component of the Editor.
     *
     * @param {IndentationSettings} settings
     * @returns {React.ComponentClass<OutdentProps>} - The Outdent tool component of the Editor.
     */
    function createOutdentTool(settings) { return OutdentToolNS.createOutdentTool(settings); }
    EditorTools.createOutdentTool = createOutdentTool;
    /**
     * The Outdent tool component.
     */
    var Outdent = /** @class */ (function (_super) {
        __extends(Outdent, _super);
        function Outdent() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return Outdent;
    }(createOutdentTool(EditorToolsSettings.outdent)));
    EditorTools.Outdent = Outdent;
    /**
     * The Print tool component.
     * Designed to work only if Editor's defaultEditMode prop is set to 'iframe'.
     */
    EditorTools.Print = function (props) { return React.createElement(PrintTool, __assign({}, props)); };
    /**
     * The Export to PDF tool component.
     *
     * To modify the default PDF export options, see the following example:
     *
     * @example
     * ```tsx-no-run
     * import { Editor, EditorTools } from '@progress/kendo-react-editor';
     * const { Bold, Italic, Pdf } = EditorTools;
     *
     * const PdfTool = (props: EditorTools.PdfProps) => (
     *     <Pdf
     *         {...props}
     *         // https://www.telerik.com/kendo-react-ui/components/pdfprocessing/api/PDFExportProps/
     *         savePdfOptions={{
     *             fileName: 'editor-export.pdf',
     *             paperSize: 'A5',
     *             margin: '1cm'
     *         }}
     *     />
     * );
     *
     * class App extends React.Component<any, any> {
     *     render() {
     *         return (
     *             <Editor
     *                 tools={[[ Bold, Italic, PdfTool ]]}
     *                 contentStyle={{ height: 100 }}
     *                 defaultContent="<p>text</p>"
     *             />
     *         );
     *     }
     * }
     * ```
     */
    EditorTools.Pdf = function (props) { return React.createElement(PdfTool, __assign({}, props)); };
    /**
     * The SelectAll tool component.
     */
    EditorTools.SelectAll = function (props) { return React.createElement(SelectAllTool, __assign({}, props)); };
    /**
     * The CleanFormatting tool component.
     */
    EditorTools.CleanFormatting = function (props) { return React.createElement(CleanFormattingTool, __assign({}, props)); };
    /**
     * Creates the AddRowBefore tool component of the Editor.
     *
     * @param {ToolSettings} - The settings of the AddRowBefore tool.
     * @returns {React.ComponentClass<AddRowBeforeProps>} - The AddRowBefore tool component of the Editor.
     */
    function createAddRowBeforeTool(settings) { return TableEditNS.createAddRowBeforeTool(settings); }
    EditorTools.createAddRowBeforeTool = createAddRowBeforeTool;
    /**
     * Creates the AddRowAfter tool component of the Editor.
     *
     * @param {ToolSettings} - The settings of the AddRowAfter tool.
     * @returns {React.ComponentClass<AddRowAfterProps>} - The AddRowAfter tool component of the Editor.
     */
    function createAddRowAfterTool(settings) { return TableEditNS.createAddRowAfterTool(settings); }
    EditorTools.createAddRowAfterTool = createAddRowAfterTool;
    /**
     * Creates the AddColumnBefore tool component of the Editor.
     *
     * @param {ToolSettings} - The settings of the AddColumnBefore tool.
     * @returns {React.ComponentClass<AddColumnBeforeProps>} - The AddColumnBefore tool component of the Editor.
     */
    function createAddColumnBeforeTool(settings) {
        return TableEditNS.createAddColumnBeforeTool(settings);
    }
    EditorTools.createAddColumnBeforeTool = createAddColumnBeforeTool;
    /**
     * Creates the AddColumnAfter tool component of the Editor.
     *
     * @param {ToolSettings} - The settings of the AddColumnAfter tool.
     * @returns {React.ComponentClass<AddColumnAfterProps>} - The AddColumnAfter tool component of the Editor.
     */
    function createAddColumnAfterTool(settings) { return TableEditNS.createAddColumnAfterTool(settings); }
    EditorTools.createAddColumnAfterTool = createAddColumnAfterTool;
    /**
     * Creates the DeleteRow tool component of the Editor.
     *
     * @param {ToolSettings} - The settings of the DeleteRow tool.
     * @returns {React.ComponentClass<DeleteRowProps>} - The DeleteRow tool component of the Editor.
     */
    function createDeleteRowTool(settings) { return TableEditNS.createDeleteRowTool(settings); }
    EditorTools.createDeleteRowTool = createDeleteRowTool;
    /**
     * Creates the DeleteColumn tool component of the Editor.
     *
     * @param {ToolSettings} - The settings of the DeleteColumn tool.
     * @returns {React.ComponentClass<DeleteColumnProps>} - The DeleteColumn tool component of the Editor.
     */
    function createDeleteColumnTool(settings) { return TableEditNS.createDeleteColumnTool(settings); }
    EditorTools.createDeleteColumnTool = createDeleteColumnTool;
    /**
     * Creates the DeleteTable tool component of the Editor.
     *
     * @param {ToolSettings} - The settings of the DeleteTable tool.
     * @returns {React.ComponentClass<DeleteTableProps>} - The DeleteTable tool component of the Editor.
     */
    function createDeleteTableTool(settings) { return TableEditNS.createDeleteTableTool(settings); }
    EditorTools.createDeleteTableTool = createDeleteTableTool;
    /**
     * Creates the MergeCells tool component of the Editor.
     *
     * @param {ToolSettings} - The settings of the MergeCells tool
     * @returns {React.ComponentClass<MergeCellsProps>} - The MergeCells tool component of the Editor.
     */
    function createMergeCellsTool(settings) { return TableEditNS.createMergeCellsTool(settings); }
    EditorTools.createMergeCellsTool = createMergeCellsTool;
    /**
     * Creates the SplitCell tool component of the Editor.
     *
     * @param {ToolSettings} - The settings of the SplitCell tool.
     * @returns {React.ComponentClass<SplitCellProps>} - The SplitCell tool component of the Editor.
     */
    function createSplitCellTool(settings) { return TableEditNS.createSplitCellTool(settings); }
    EditorTools.createSplitCellTool = createSplitCellTool;
    /**
     * The AddRowBefore tool component.
     */
    var AddRowBefore = /** @class */ (function (_super) {
        __extends(AddRowBefore, _super);
        function AddRowBefore() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return AddRowBefore;
    }(createAddRowBeforeTool(EditorToolsSettings.addRowBefore)));
    EditorTools.AddRowBefore = AddRowBefore;
    /**
     * The AddRowAfter tool component.
     */
    var AddRowAfter = /** @class */ (function (_super) {
        __extends(AddRowAfter, _super);
        function AddRowAfter() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return AddRowAfter;
    }(createAddRowAfterTool(EditorToolsSettings.addRowAfter)));
    EditorTools.AddRowAfter = AddRowAfter;
    /**
     * The AddColumnBefore tool component.
     */
    var AddColumnBefore = /** @class */ (function (_super) {
        __extends(AddColumnBefore, _super);
        function AddColumnBefore() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return AddColumnBefore;
    }(createAddColumnBeforeTool(EditorToolsSettings.addColumnBefore)));
    EditorTools.AddColumnBefore = AddColumnBefore;
    /**
     * The AddColumnAfter tool component.
     */
    var AddColumnAfter = /** @class */ (function (_super) {
        __extends(AddColumnAfter, _super);
        function AddColumnAfter() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return AddColumnAfter;
    }(createAddColumnAfterTool(EditorToolsSettings.addColumnAfter)));
    EditorTools.AddColumnAfter = AddColumnAfter;
    /**
     * The DeleteRow tool component.
     */
    var DeleteRow = /** @class */ (function (_super) {
        __extends(DeleteRow, _super);
        function DeleteRow() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return DeleteRow;
    }(createDeleteRowTool(EditorToolsSettings.deleteRow)));
    EditorTools.DeleteRow = DeleteRow;
    /**
     * The DeleteColumn tool component.
     */
    var DeleteColumn = /** @class */ (function (_super) {
        __extends(DeleteColumn, _super);
        function DeleteColumn() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return DeleteColumn;
    }(createDeleteColumnTool(EditorToolsSettings.deleteColumn)));
    EditorTools.DeleteColumn = DeleteColumn;
    /**
     * The DeleteTable tool component.
     */
    var DeleteTable = /** @class */ (function (_super) {
        __extends(DeleteTable, _super);
        function DeleteTable() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return DeleteTable;
    }(createDeleteTableTool(EditorToolsSettings.deleteTable)));
    EditorTools.DeleteTable = DeleteTable;
    /**
     * The MergeCells tool component.
     */
    var MergeCells = /** @class */ (function (_super) {
        __extends(MergeCells, _super);
        function MergeCells() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return MergeCells;
    }(createMergeCellsTool(EditorToolsSettings.mergeCells)));
    EditorTools.MergeCells = MergeCells;
    /**
     * The SplitCell tool component.
     */
    var SplitCell = /** @class */ (function (_super) {
        __extends(SplitCell, _super);
        function SplitCell() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return SplitCell;
    }(createSplitCellTool(EditorToolsSettings.splitCell)));
    EditorTools.SplitCell = SplitCell;
    /**
     * Creates the Unlink tool component of the Editor.
     *
     * @param {UnlinkSettings} settings
     * @returns {React.ComponentClass<UnlinkProps>} - The Unlink tool component of the Editor.
     */
    function createUnlinkTool(settings) { return UnlinkToolNS.createUnlinkTool(settings); }
    EditorTools.createUnlinkTool = createUnlinkTool;
    /**
     * The Unlink tool component.
     */
    var Unlink = /** @class */ (function (_super) {
        __extends(Unlink, _super);
        function Unlink() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return Unlink;
    }(createUnlinkTool(EditorToolsSettings.unlink)));
    EditorTools.Unlink = Unlink;
    /**
     * Creates the ViewHtml tool component of the Editor.
     *
     * @param {ViewHtmlSettings} settings
     * @returns {React.ComponentClass<ViewHtmlProps>} - The ViewHtml tool component of the Editor.
     */
    function createViewHtmlTool(settings) { return ViewHtmlToolNS.createViewHtmlTool(settings); }
    EditorTools.createViewHtmlTool = createViewHtmlTool;
    /**
     * The ViewHtml tool component.
     */
    var ViewHtml = /** @class */ (function (_super) {
        __extends(ViewHtml, _super);
        function ViewHtml() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return ViewHtml;
    }(createViewHtmlTool(EditorToolsSettings.viewHtml)));
    EditorTools.ViewHtml = ViewHtml;
    /**
     * The FindAndReplace tool component.
     */
    var FindAndReplace = /** @class */ (function (_super) {
        __extends(FindAndReplace, _super);
        function FindAndReplace() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        return FindAndReplace;
    }(FindAndReplaceTool));
    EditorTools.FindAndReplace = FindAndReplace;
})(EditorTools || (EditorTools = {}));
