"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import ReactDOM from "react-dom";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import AdminBaseListTable from "./admin_list_table_base";
import AdminUtils from "../admin_utils";
import TableButton from "../../../widgets/tables/table_button";
import { ACTION_TO_ICON_ENUM } from "../../../helpers/constants/constants";
import TableOptionsButton from "../../../widgets/tables/table_options_button";
import BaseReactComponent from "../../../base_react_component";

export default class CompanyListTable extends BaseReactComponent {
  constructor(props) {
    super(props);
    
    this.columns = [
      {
        title: "ID",
        width: "80px",
        data: result => result.id ? result.id : ""
      },
      {
        title: "Company Name",
        defaultFilteredValue: props.selectedCompany ? props.selectedCompany.name : null,
        data: result => result.name ? result.name : "",
        createdCell: (td, cellData, rowData) =>
          ReactDOM.render(
            <a href={"editCompany.html?operation=View&id=" + rowData.id}>
              {cellData}
            </a>,
            td
          )
      },
      {
        title: "Created On",
        data: (result, type) => AdminUtils.getDateAsData(type, result.createdAt)
      },
      {
        title: "License",
        data: result => this.extractCompanyInfo(result, "license")
      },
      {
        title: "Trial Expiry Date",
        data: (result, type) => {
          const trialExpiryDate = this.extractCompanyInfo(result, "trialExpiryDate");
          return AdminUtils.getDateAsData(type, trialExpiryDate);
        }
      },
      {
        title: "Total Logins",
        width: "80px",
        data: result => result.totalLogins
      },
      {
        title: "Total Projects",
        width: "80px",
        data: result => result.totalProjects
      },
      {
        title: "Total Users",
        width: "80px",
        data: result => result.totalActiveUsers
      },
      {
        title: "",
        width: "150px",
        containsHTML: true,
        hideSearch: true,
        orderable: false,
        data: result => result,
        createdCell: (td, cellData, rowData, row) => {
          let options = [
            {
              action: ACTION_TO_ICON_ENUM.VIEW,
              id: "viewButton_" + row,
              title: "View",
              url: "editCompany.html?operation=View&id=" + rowData.id
            },
            {
              action: ACTION_TO_ICON_ENUM.EDIT,
              id: "editButton_" + row,
              title: "Edit",
              url: "editCompany.html?operation=Edit&id=" + rowData.id
            },
            {
              action: ACTION_TO_ICON_ENUM.USERS,
              id: "viewUsersButton_" + row,
              title: "View Users",
              onClick: () => this.handleClickCompany(rowData)
            }
          ];
          return ReactDOM.render(
            (
              <div className="table-manage">
                <TableOptionsButton options={options} />
                <TableButton href={"editCompany.html?operation=View&id=" + rowData.id}
                             className="table-icon"
                             title="View"
                             id={"viewUsersButton_" + row}
                             fontAwesomeIcon={faChevronRight}
                />
              </div>
            ), td);
        }
      }
    ];
    this.columnDefs = [{type: "date", targets: [2, 4]}];
  }

  /**
   * @typedef IBillingState {Object}
   * @property {string} trialExpiryDate
   * @property {string} license
   * @property {number} id
   * @property {number} totalLicenses
   * @property {string} lastPaymentDate
   * @property {string} renewalDate
   *  }
   */

  /**
   * Returns the value of the specified field from the company object
   * @param company {{BillingStates: IBillingState[]}}
   * @param field
   * @return {string|*}
   */
  extractCompanyInfo(company, field) {
    /**
     * @type {*[]}
     */
    const billingStates = company && company.BillingStates ? company.BillingStates : [];
    /**
     * @type {IBillingState|null}
     */
    let latestBillingState = null;

    if (billingStates.length > 0) {
      // sorts the billing states by descending expiry date order, so we show the correct information in the listing table
      billingStates.sort(
        (a, b) => a.trialExpiryDate < b.trialExpiryDate ? 1 : (a.trialExpiryDate > b.trialExpiryDate ? -1 : 0)
      );

      latestBillingState = billingStates[0];
    }

    if (latestBillingState) {
      switch (field) {
        case "license":
          return UIUtils.capitalize(latestBillingState.license);
        case "trialExpiryDate":
          return latestBillingState.trialExpiryDate;
      }
    }
    return "";
  }

  handleClickCompany(company) {
    this.props.onSelectCompany(company);
  }

  handleClearColumnSearch(column) {
    if (column.title === "Company Name" && this.props.resetSelectedCompany) {
      this.props.resetSelectedCompany();
    }
  }

  render() {
    return (
      <AdminBaseListTable
        id="companiesTable"
        columns={this.columns}
        columnDefs={this.columnDefs}
        data={this.props.companies}
        onClearColumnSearch={this.handleClearColumnSearch}
      />
    );
  }
}
