var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
/**
 * The GridColumnMenuItem component that is used inside the Grid ColumnMenu.
 */
var GridColumnMenuItem = /** @class */ (function (_super) {
    __extends(GridColumnMenuItem, _super);
    function GridColumnMenuItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onClick = function (e) {
            if (_this.props.onClick) {
                _this.props.onClick(e);
            }
        };
        return _this;
    }
    /**
     * @hidden
     */
    GridColumnMenuItem.prototype.render = function () {
        var _a = this.props, title = _a.title, iconClass = _a.iconClass, selected = _a.selected;
        return (React.createElement("div", { onClick: this.onClick, className: "k-columnmenu-item ".concat(selected ? 'k-selected' : '') },
            iconClass && React.createElement("span", { className: "k-icon ".concat(iconClass) }),
            title));
    };
    return GridColumnMenuItem;
}(React.Component));
export { GridColumnMenuItem };
