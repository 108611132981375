"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Trans } from "react-i18next";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseReactComponent from "../../base_react_component";

export const SUBSCRIBE_NOW_URL = "https://www.qbdvision.com/qbdvision-plans/";

/**
 *  This shows the "Trial days left" at the top of the screen
 */
// i18next-extract-mark-ns-start widgets
class TrialBar extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  static handleSubscribeNow() {
    window.location.href = UIUtils.getSecuredURL(SUBSCRIBE_NOW_URL);
  }

  handleClose() {
    Cookies.remove("TRIAL");
    this.forceUpdate();
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const {t} = this.props;
    let trialInfo = Cookies.getJSON("TRIAL");
    if (trialInfo) {
      let daysLeft = UIUtils.getDaysFromToday(trialInfo.trialExpiryDate);
      return (
        <div className="trial-bar">
          <div className="container-fluid-body container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="trial-trial-text">
                  <Trans t={t}>
                    Trial: <span id="trialDaysLeft">{{daysLeft}}</span> days left
                  </Trans>
                </div>
                <a className="trial-X"
                   onClick={this.handleClose}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </a>
                <button className="btn btn-float-right trial-bar-subscribe-now-button"
                        id="subscribeNowButton"
                        onClick={TrialBar.handleSubscribeNow}
                >
                  {t("Subscribe Now")}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default I18NWrapper.wrap(TrialBar, "widgets");
// i18next-extract-mark-ns-stop widgets
