"use strict";

import { faArrowRight, faEye, faLevelDownAlt, faCheck, faUndo } from "@fortawesome/free-solid-svg-icons";
import { EDITOR_OPERATIONS, EDITOR_TYPES } from "../../editor/editor_constants";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { TrainingService } from "../../training/services/training_service";
import ImplementationNeededError from "../../utils/implementation_needed_error";

const trainingService = new TrainingService();


/**
 * @typedef IEditorTabInfo
 * @property {string} [key]
 * @property {string} [title]
 */

/**
 * @enum {IEditorTabInfo}
 */
export const EDITOR_TABS = {
  DEFAULT: {},
};

export const QUICK_PANEL_BUTTONS = {
  SAVE: {
    id: "quickPanelSaveButton",
    icon: faCheck,
    propsMethod: "onSave",
    className: "btn-primary quick-panel-title-bar-button-save",
    tooltipText: (props) => !props.dataModified ? "Modify at least one field to save the form" : "Save",
    visible: (editorType, editorOperation) => editorOperation === EDITOR_OPERATIONS.ADD || editorOperation === EDITOR_OPERATIONS.EDIT,
    disabled: (props) => !props.dataModified,
    type: "button"
  },
  MARK_REVIEWED: {
    id: "markAsReviewedButton",
    text: "Mark as Reviewed",
    propsMethod: "onMarkAsReviewed",
    className: "btn-primary",
    tooltipText: "Mark the requirement as reviewed",
    visible: (editorType, editorOperation) => editorOperation === EDITOR_OPERATIONS.VIEW,
    disabled: false,
    type: "button"
  },
  VIEW: {
    id: "quickPanelViewButton",
    icon: faArrowRight,
    text: "View record in a new page",
    propsMethod: "onView",
    className: "btn-primary quick-panel-external-link-rotate",
    tooltipText: "View record in a new page",
    visible: (editorType, editorOperation) => editorOperation === EDITOR_OPERATIONS.VIEW,
    disabled: false,
    type: "button"
  },
  DIVIDER: {
    isDivider: true,
    visible: (editorType, editorOperation) => editorOperation === EDITOR_OPERATIONS.EDIT,
  },
  RESET: {
    id: "quickPanelResetButton",
    icon: faUndo,
    propsMethod: "onReset",
    className: "btn-secondary quick-panel-title-bar-button-reset",
    tooltipText: "Reset to original",
    visible: (editorType, editorOperation) => editorOperation === EDITOR_OPERATIONS.EDIT,
    disabled: false,
    type: "button"
  },
  CHECK_FOR_PROPOSE: {
    id: "quickPanelCheckForProposeButton",
    icon: faLevelDownAlt,
    propsMethod: "onVerifyForPropose",
    className: "btn-secondary quick-panel-title-bar-button-check-propose",
    tooltipText: "Find the next issue",
    visible: (editorType, editorOperation) => editorOperation === EDITOR_OPERATIONS.EDIT,
    disabled: false,
    type: "button"
  },
  SWITCH_MODE: {
    id: "quickPanelSwitchModeButton",
    icon: (editorType, editorOperation) => {
      switch (editorOperation) {
        case EDITOR_OPERATIONS.EDIT:
          return faEye;
        case EDITOR_OPERATIONS.VIEW:
          return faEdit;
        default:
          throw new ImplementationNeededError();
      }
    },
    propsMethod: "onSwitchViewMode",
    arguments: (editorType, editorOperation) => {
      let result = "";
      switch (editorOperation) {
        case EDITOR_OPERATIONS.EDIT:
          result = EDITOR_OPERATIONS.VIEW;
          break;
        case EDITOR_OPERATIONS.VIEW:
          result = EDITOR_OPERATIONS.EDIT;
          break;
      }
      // uncomment for verbose logging
      // console.log(
      //   "Retrieving arguments: ",
      //   "\n - editorType", editorType,
      //   "\n - editorOperation", editorOperation,
      //   "\n - result", result,
      // );
      return result;
    },
    className: (editorType, editorOperation) => {
      switch (editorOperation) {
        case EDITOR_OPERATIONS.EDIT:
          return "btn-secondary quick-panel-title-bar-button-switch-view";
        case EDITOR_OPERATIONS.VIEW:
          return "btn-primary quick-panel-title-bar-button-switch-edit";
        default:
          return "";
      }
    },
    visible: (editorType, editorOperation) => editorOperation === EDITOR_OPERATIONS.EDIT || editorOperation === EDITOR_OPERATIONS.VIEW,
    tooltipText: props => {
      switch (props.editorOperation) {
        case EDITOR_OPERATIONS.EDIT:
          return "Switch to view mode";
        case EDITOR_OPERATIONS.VIEW:
          return "Switch to edit mode";
        default:
          return "";
      }
    },
    disabled: false,
    type: "button"
  },
  TRAINING_SIGN_OFF: {
    id: "markAsTrainedButton",
    text: "Mark as Trained",
    className: "btn-primary",
    tooltipText: ({instance}) => {
      const status = trainingService.getTrainingSignOffStatus(instance.userId, instance);
      return status.tooltipText;
    },
    visible: editorType => editorType === EDITOR_TYPES.TRAINING_PANEL,
    disabled: ({instance}) => {
      const status = trainingService.getTrainingSignOffStatus(instance.userId, instance);
      return status.disabled;
    },
    type: "button"
  },
  DOWNLOAD: {
    id: "downloadButton",
    text: "Download",
    className: "btn-primary",
    visible: (editorType) => editorType === EDITOR_TYPES.TRAINING_PANEL,
    disabled: (props) => props.instance.currentState === "Obsolete",
    tooltipText: (props) => (
      props.instance.currentState === "Obsolete"
        ? "This document version has been superseded. You should train on the latest version of this document."
        : "Download this document."
    ),
    type: "button"
  },
};
