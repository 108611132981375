"use strict";
//The functions in this file support the client side verification of the models before being saved or proposed.
//Include any custom condition functions referenced in the UML in this file and the will be referenced by the auto generated common_model_verifier.js

function isMissing(attribute, isCollection) {
  return attribute === undefined || attribute === null || (isCollection ? attribute.length === 0 : attribute.toString().trim() === "");
}

// Attribute rules
module.exports.requiresValidationPhase = function(instance) {
  return instance.cmcPhase === "Validation" && (isMissing(instance.validationPhase) || instance.validationPhase === "Not Applicable");
};

module.exports.materialHasNoEffectiveDate = function(instance) {
  return instance.materialQualified === "Yes" && isMissing(instance.effectiveDate);
};

module.exports.hasNoCriticalityJustification = function(instance) {
  if (instance.detailedRiskLinks) {
    return false;
  }
  return !instance.criticalityJustification;
};

module.exports.isFileNotAttachedToDocument = function(instance) {
  return !(instance && instance.isQbdSrcDoc)
    && (isMissing(instance.uploadDocumentLinks) || !(JSON.parse(instance.uploadDocumentLinks)[0].fileName));
};

// Relationship rules
/**
 * Only starts verification if there is no attached IPA and Final Attributes.
 * (condition to verify whether there is attached IQA)
 * @param instance {*} The editable being verified
 * @return {boolean} Whether or not the verification should start
 */
module.exports.isMAUnattachedToIPAAndFAs = function(instance) {
  return !(
    (instance.MaterialAttributeToIPAs && instance.MaterialAttributeToIPAs.length > 0) ||
    (instance.MaterialAttributeToFPAs && instance.MaterialAttributeToFPAs.length > 0) ||
    (instance.MaterialAttributeToFQAs && instance.MaterialAttributeToFQAs.length > 0)
  );
};

/**
 * Only starts verification if there is no attached IQA and Final Attributes.
 * (condition to verify whether there is attached IPA)
 * @param instance {*} The editable being verified
 * @return {boolean} Whether or not the verification should start
 */
module.exports.isMAUnattachedToIQAAndFAs = function(instance) {
  return !(
    (instance.MaterialAttributeToIQAs && instance.MaterialAttributeToIQAs.length > 0) ||
    (instance.MaterialAttributeToFPAs && instance.MaterialAttributeToFPAs.length > 0) ||
    (instance.MaterialAttributeToFQAs && instance.MaterialAttributeToFQAs.length > 0)
  );
};

/**
 * Only starts verification if there is no attached FPA and Intermediate Attributes.
 * (condition to verify whether there is attached FPA)
 * @param instance {*} The editable being verified
 * @return {boolean} Whether or not the verification should start
 */
module.exports.isMAUnattachedToFQAAndIAs = function(instance) {
  return !(
    (instance.MaterialAttributeToIQAs && instance.MaterialAttributeToIQAs.length > 0) ||
    (instance.MaterialAttributeToIPAs && instance.MaterialAttributeToIPAs.length > 0) ||
    (instance.MaterialAttributeToFQAs && instance.MaterialAttributeToFQAs.length > 0)
  );
};

/**
 * Only starts verification if there is no attached FQA and Intermediate Attributes.
 * (condition to verify whether there is attached FQA)
 * @param instance {*} The editable being verified
 * @return {boolean} Whether or not the verification should start
 */
module.exports.isMAUnattachedToFPAAndIAs = function(instance) {
  return !(
    (instance.MaterialAttributeToIQAs && instance.MaterialAttributeToIQAs.length > 0) ||
    (instance.MaterialAttributeToIPAs && instance.MaterialAttributeToIPAs.length > 0) ||
    (instance.MaterialAttributeToFPAs && instance.MaterialAttributeToFPAs.length > 0)
  );
};


/**
 * Only starts verification if there is no attached IPA and Final Attributes.
 * (condition to verify whether there is attached IQA)
 * @param instance {*} The editable being verified
 * @return {boolean} Whether or not the verification should start
 */
module.exports.isPPUnattachedToIPAAndFAs = function(instance) {
  return !(
    (instance.ProcessParameterToIPAs && instance.ProcessParameterToIPAs.length > 0) ||
    (instance.ProcessParameterToFPAs && instance.ProcessParameterToFPAs.length > 0) ||
    (instance.ProcessParameterToFQAs && instance.ProcessParameterToFQAs.length > 0)
  );
};

/**
 * Only starts verification if there is no attached IQA and Final Attributes.
 * (condition to verify whether there is attached IPA)
 * @param instance {*} The editable being verified
 * @return {boolean} Whether or not the verification should start
 */
module.exports.isPPUnattachedToIQAAndFAs = function(instance) {
  return !(
    (instance.ProcessParameterToIQAs && instance.ProcessParameterToIQAs.length > 0) ||
    (instance.ProcessParameterToFPAs && instance.ProcessParameterToFPAs.length > 0) ||
    (instance.ProcessParameterToFQAs && instance.ProcessParameterToFQAs.length > 0)
  );
};

/**
 * Only starts verification if there is no attached FPA and Intermediate Attributes.
 * (condition to verify whether there is attached FPA)
 * @param instance {*} The editable being verify
 * @return {boolean} Whether or not the verification should start
 */
module.exports.isPPUnattachedToFQAAndIAs = function(instance) {
  return !(
    (instance.ProcessParameterToIQAs && instance.ProcessParameterToIQAs.length > 0) ||
    (instance.ProcessParameterToIPAs && instance.ProcessParameterToIPAs.length > 0) ||
    (instance.ProcessParameterToFQAs && instance.ProcessParameterToFQAs.length > 0)
  );
};

/**
 * Only starts verification if there is no attached FQA and Intermediate Attributes.
 * (condition to verify whether there is attached FQA)
 * @param instance {*} The editable being verified
 * @return {boolean} Whether or not the verification should start
 */
module.exports.isPPUnattachedToFPAAndIAs = function(instance) {
  return !(
    (instance.ProcessParameterToIQAs && instance.ProcessParameterToIQAs.length > 0) ||
    (instance.ProcessParameterToIPAs && instance.ProcessParameterToIPAs.length > 0) ||
    (instance.ProcessParameterToFPAs && instance.ProcessParameterToFPAs.length > 0)
  );
};

module.exports.hasNoIQAToIPAAndFinalAttributes = function(instance) {
  return !(
    (instance.IQAToFPAs && instance.IQAToFPAs.length > 0) ||
    (instance.IQAToFQAs && instance.IQAToFQAs.length > 0) ||
    (instance.IQAToIPAs && instance.IQAToIPAs.length > 0)
  );
};

module.exports.hasNoIQAToIQAAndFinalAttributes = function(instance) {
  return !(
    (instance.IQAToFPAs && instance.IQAToFPAs.length > 0) ||
    (instance.IQAToFQAs && instance.IQAToFQAs.length > 0) ||
    (instance.IQAToIQAs && instance.IQAToIQAs.length > 0)
  );
};

module.exports.hasNoIPAToIPAAndFinalAttributes = function(instance) {
  return !(
    (instance.IPAToFPAs && instance.IPAToFPAs.length > 0) ||
    (instance.IPAToFQAs && instance.IPAToFQAs.length > 0) ||
    (instance.IPAToIPAs && instance.IPAToIPAs.length > 0)
  );
};

module.exports.hasNoIPAToIQAAndFinalAttributes = function(instance) {
  return !(
    (instance.IPAToFPAs && instance.IPAToFPAs.length > 0) ||
    (instance.IPAToFQAs && instance.IPAToFQAs.length > 0) ||
    (instance.IPAToIQAs && instance.IPAToIQAs.length > 0)
  );
};

module.exports.isFQANotAttachedToGeneralAttribute = function(instance) {
  return instance.detailedRiskLinks && !(instance.FQAToGeneralAttributeRisks && instance.FQAToGeneralAttributeRisks.length > 0);
};

module.exports.isFPANotAttachedToGeneralAttribute = function(instance) {
  return instance.detailedRiskLinks && !(instance.FPAToGeneralAttributeRisks && instance.FPAToGeneralAttributeRisks.length > 0);
};

/**
 * Only starts verification if there is no attached Document.
 * (condition to verify whether there is attached Curricula)
 * @param instance {*} The editable being verified
 * @return {boolean} Whether or not the verification should start
 */
module.exports.isDocumentNotAttachedToITP = function(instance) {
  return !(instance.Documents && instance.Documents.length > 0);
};

/**
 * Only starts verification if there is no attached Curriculum.
 * (condition to verify whether there is attached Document)
 * @param instance {*} The editable being verified
 * @return {boolean} Whether or not the verification should start
 */
module.exports.isCurriculumNotAttachedToITP = function(instance) {
  return !(instance.CurriculumAssignments && instance.CurriculumAssignments.length > 0);
};
