"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import { EDITOR_TYPES } from "../../editor/editor_constants";
import CommonEditablesPageTitleBar from "../../widgets/pageTitleBar/common_editables_page_title_bar";
import Section from "../../editor/widgets/section";
import TextAttribute from "../../editor/attributes/text_attribute";
import TypeaheadAttribute from "../../editor/attributes/typeahead_attribute";
import ComboBoxAttribute from "../../editor/attributes/combo_box_attribute";
import * as CommonEditables from "../../../server/common/editables/common_editables";
import TextAreaAttribute from "../../editor/attributes/text_area_attribute";
import NumberAttribute from "../../editor/attributes/number_attribute";
import DateAttribute from "../../editor/attributes/date_attribute";
import CheckboxAttribute from "../../editor/attributes/checkbox_attribute";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";

import { MaterialSpecificationsAttribute } from "./attributes/material_specifications_attribute";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import LibraryMaterialTitleBar from "../widgets/library_material_title_bar";
import CreateProjectMaterialPopup
  from "../wizardActions/createProjectMaterial/fromLibrary/create_project_material_popup";
import BaseQuickEditor from "../../editor/base_quick_editor";
import TabNavBar from "../../widgets/bars/tab_nav_bar";
import { ProjectUsageInformationPanel } from "../widgets/relatedProjects/project_usage_information_panel";
import { mapToListRecordData } from "../widgets/relatedProjects/related_projects_utils";
import LibraryBatchList from "./library_batch_list";
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MODEL_DECLARATIONS } from "../../../server/common/generic/common_models";
import { SHOW_REMOVED } from "../../utils/filter_helper";
import Cookies from "js-cookie";
import ConfirmationModal from "../wizardActions/createProjectMaterial/fromLibrary/confirmation_dialog";
import { LibraryHelper } from "../helpers/library_helper";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import { TYPE_CODE } from "../../../server/common/generic/common_constants";
import { RelatedPanelNotice } from "../../editor/widgets/related_panel_notice";

class LibraryMaterial extends BaseQuickEditor {

  constructor(props) {
    super(props, "libraryMaterial", "LibraryMaterial", "Library Material (MTL)");
    this.setStateSafely({
      isConfirmationOpen: false
    });
    if (this.isAdd()) {
      this.setStateSafely({
        breadcrumb: {
          current: "New Library Material",
          parent: "Library Materials",
          parentLink: "/library/list.html",
        },
      });
    }
    const initialState = {showRemovedCoaBatches: (Cookies.get(SHOW_REMOVED + "_BAT") === "true"),};
    this.setStateSafely(initialState);
  }

  componentDidMount() {
    document.title = "QbDVision Library Material";

    super.componentDidMount();
  }

  onShowArchivedCoa(showRemoved) {
    this.setStateSafely({showRemovedCoaBatches: showRemoved});
  }
  onDataReceivedFromServer() {
    super.onDataReceivedFromServer();

    const {Specifications, id} = this.state;

    if (Specifications) {
      const updatedSpecifications = Specifications.map(specification => {
        return {
          ...specification,
          reportLink: `/reports/processCapabilityDashboard.html?reportType=LibraryProcessCapabilityDashboard&parentId=${id}&modelLabel=${MODEL_DECLARATIONS.SPECIFICATION.typeCode}-${specification.recordId}`,
        };
      });

      const breadcrumbCurrent = UIUtils.getRecordCustomLabelForDisplay({
        ...this.state,
        typeCode: TYPE_CODE.LIBRARY_MATERIAL
      });

      const breadcrumb = {
        current: breadcrumbCurrent,
        parent: "Library Materials",
        parentLink: "/library/list.html",
      };

      this.setStateSafely({Specifications: updatedSpecifications, breadcrumb});
    }
  }

  getTabName() {
    return "Library Material";
  }

  shouldShowNav() {
    return false;
  }

  hasRelatedItemsTab() {
    return true;
  }

  getURLToLoadData(shouldShowApproved) {
    let url = super.getURLToLoadData(shouldShowApproved);
    url += "&includeCOABatches=true";
    return url;
  }

  shouldLoadBatchData() {
    return !this.isAdd();
  }

  renderSidePanel(versions) {
    const {
      selectedTab, sidePanelTabs,
    } = this.state;

    return this.hasRelatedItemsTab() ? (
      <Fragment>
        <TabNavBar selected={selectedTab}
                   className="related-tab-nav-margin"
                   onTabChanged={this.handleTabChanged}
                   tabs={UIUtils.deepClone(sidePanelTabs)}
                   parent={this}
                   parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                   parentId={this.state.id}
        />
        {
          selectedTab?.title === sidePanelTabs.HISTORY?.title
            ? this.renderHistoryContainer(versions)
            : <div>{this.renderSidePanelWidget()}</div>
        }
      </Fragment>
    ) : this.renderHistoryContainer(versions);
  }

  renderSidePanelWidget() {
    const records = mapToListRecordData(this.getUsageInformation());
    return (
      <>
        <RelatedPanelNotice isApproved={this.isApproved()} />
        <ProjectUsageInformationPanel id="projectUsageData"
                                      records={records}
        />
      </>
    );
  }

  getUsageInformation() {
    return this.state.relatedProjects || [];
  }

  renderDefaultTitleBar() {
    return (
      <LibraryMaterialTitleBar currentOperation={this.getCurrentOperation()}
                               currentState={this.getCurrentState()}
                               instance={this.state}
                               instanceTypeCode={UIUtils.getTypeCodeForModelName(this.baseTypeName)}
                               sectionName={this.getTabName()}
                               displayName={this.displayName}
                               selectedTab={this.getSelectedEditorTab()}
      />
    );
  }

  renderPageTitleBar() {
    return this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ?
      <CommonEditablesPageTitleBar recordName={this.state.name}
                                   recordId={this.state.id}
                                   name="Library"
                                   typeCode={UIUtils.getTypeCodeForModelName(this.baseTypeName)}
                                   currentState={this.getCurrentState()}
      /> : false;
  }

  renderAttributes() {
    const {t} = this.props;
    const {coaBatches, showRemovedCoaBatches} = this.state;
    const archivedFilter = batch => batch?.currentState !== UIUtils.VERSION_STATES.ARCHIVED || batch?.deletedAt === null;
    return (
      <div>
        <Section parent={this}
                 header={this.getGeneralHeader()}
                 collapsible={false}
                 showDocLinks={true}
        >
          <div className="row">
            <TextAttribute name="name"
                           parent={this}
            />
            <ComboBoxAttribute name="category"
                               options={CommonEditables.MATERIAL_CATEGORIES}
                               tooltipText={(
                                 <div>
                                   <p>
                                     {`This field tracks the type of raw or process material.  Note: the "Source Raw Material"
                                     and "Biologically-Derived" categories are legacy selections that will be phased out over time.
                                     If you have selected this type for any previously entered materials, please change them to one of the other listed selections.`}
                                   </p>
                                 </div>
                               )}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
          </div>
          <div className="row">
            <CheckboxAttribute name="gmp"
                               displayName="GMP"
                               parent={this}
                               tooltipText="Indicates if the material has been manufactured, collected, or processed according to GMP regulations."
            />
          </div>
          <div className="row">
            <TextAreaAttribute name="description"
                               className="col-sm-12"
                               parent={this}
            />
          </div>
        </Section>
        <Section id="specifications"
                 parent={this}
                 showDocLinks={false}
                 header={"Material Specifications"}
        >
          <div className="row">
            <MaterialSpecificationsAttribute
              name={"specifications"}
              displayName="Specifications"
              linkObject={["Specification"]}
              isViewMode={this.isView()}
              specifications={this.state.Specifications}
              className="col-sm-12"
              ref={(specTable) => {
                this.specTable = specTable;
                if (!this._specsTableInitialized) {
                  this._specsTableInitialized = true;
                  this.forceUpdateSafely();
                }
              }}
              parent={this}
              addButtonText={"Add specification"}
            />
          </div>
        </Section>
        {this.shouldLoadBatchData() &&
        <Section  id="data"
                  parent={this}
                  showDocLinks={false}
                  headerLink={
                    <a
                      className="links-btn-enabled"
                      id="importCoA"
                      onClick={this.handleImportCoA}
                    >
                    <FontAwesomeIcon
                      className="m-0 ml-1"
                      icon={faFileImport}
                      size={"sm"}
                    /> {t("Import CoA")}
                    </a>
                  }
                  header={<span> {showRemovedCoaBatches ? coaBatches?.length : coaBatches?.filter(archivedFilter).length} Lots
                           <InfoTooltip id="infoLot"
                                        verbiage={
                                          <div>
                                            A material lot is a batch of material produced under the same conditions, resulting in material uniform in character and quality.
                                          </div>}
                           />
                           </span>}
          >

                <LibraryBatchList
                  cachedInstances={coaBatches?.filter(
                    batch => showRemovedCoaBatches || archivedFilter(batch)
                  ) ?? []}
                  dataType={"LibraryMaterial"}
                  editableName={"Batch"}
                  id={"batchesListTable"}
                  onShowRemovedToggle={this.onShowArchivedCoa}
                  parent={this}
                  topLevel
                />

          </Section>
        }
        <Section id="qualification"
                 parent={this}
                 showDocLinks={true}
                 header="Qualification"
        >
          <div className="row">
            <TypeaheadAttribute name="supplier"
                                typeaheadType="Supplier"
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
            />
            <ComboBoxAttribute name="materialQualified"
                               ref={ref => this.materialQualifiedField = ref}
                               displayName="Material/Part Qualified"
                               options={["Yes", "No", "Pending"]}
                               default="No"
                               tooltipText="Material qualification should be in accordance with your corporate SOPs. The Supplier should be qualified prior to qualifying the material."
                               instructions={"To set a value of Yes, the \"Supplier\" above must have its \"Qualification Status\" field set to either \"Qualified\" or \"Re-qualified.\""}
                               onValidate={this.handleValidateMaterialQualificationStatus}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
          </div>
          <div className="row">
            <TextAttribute name="partNumber"
                           displayName="Supplier Part Number"
                           parent={this}
            />
            <TextAttribute name="internalPartNumber"
                           parent={this}
            />
          </div>
          <div className="row">
            <DateAttribute name="effectiveDate"
                           instructions={`To edit this field, the "Material Qualified" field above must be set to "Yes"`}
                           disabled={this.state.materialQualified !== "Yes"}
                           tooltipText="The date the material qualification goes into effect."
                           parent={this}
            />
            <DateAttribute name="expirationDate"
                           ref={dateAttribute => this.expirationDateRef = dateAttribute}
                           instructions={`To edit this field, the "Material Qualified" field above must be set to "Yes"`}
                           disabled={this.state.materialQualified !== "Yes"}
                           tooltipText="The date the material qualification expires."
                           parent={this}
            />
          </div>
        </Section>
        <Section id="regulatory"
                 parent={this}
                 showDocLinks={true}
                 header="Regulatory"
        >
          <div className="row">
            <ComboBoxAttribute name="regulatoryFiling"
                               options={CommonEditables.MATERIAL_REGULATORY_FILING}
                               default="Not Applicable"
                               tooltipText={(
                                 <div>
                                   <b>A Drug Master File (DMF)</b> is a submission to the Food and Drug Administration
                                   (FDA) that may be used to provide confidential detailed information about facilities,
                                   processes, or articles used in the manufacturing, processing, packaging, and storing
                                   of one or more human drugs.<br />
                                   <br />
                                   <a target="_blank"
                                      rel="noopener noreferrer"
                                      className="label-tooltip-guidance"
                                      href="https://www.fda.gov/drugs/forms-submission-requirements/drug-master-files-dmfs"
                                   >
                                     Official FDA Guidance
                                   </a><br />
                                   <br />
                                   A <b>Medical Device Master File (MAF)</b> is a submission to the U.S. FDA that may be
                                   used in support of premarket submissions to provide confidential detailed information
                                   about establishments, processes, or articles used in the manufacturing, processing,
                                   packaging, and storing of one or more medical devices. <br />
                                   <br />
                                   A <b>Biologics Master File (BMF)</b> is a submission to the Food and Drug
                                   Administration (FDA) specific to biologics drugs that may be used to provide
                                   confidential detailed information about facilities, processes, or articles used in
                                   the
                                   manufacturing, processing, packaging, and storing of one or more of these human
                                   drugs.
                                 </div>
                               )}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
            <TextAttribute name="referenceNumber"
                           tooltipText="This reference number identifies the supplier filing with the FDA which can be referenced with a Letter of Authorization from the supplier"
                           parent={this}
            />
            <ComboBoxAttribute name="authorizationLetter"
                               displayName="Letter of Authorization"
                               options={["Yes", "No", "Not Applicable"]}
                               default="Not Applicable"
                               tooltipText="Letter of authorization means a written statement by the holder or designated agent or representative permitting FDA to refer to information in the DMF, MAF, or BMF in support of another person's submission."
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
          </div>
        </Section>
        <Section id="properties"
                 parent={this}
                 header="Properties"
                 showDocLinks={true}
        >
          <div className="row">
            <ComboBoxAttribute name="drugSubstanceType"
                               options={[...[""],
                                 ...CommonEditables.MATERIAL_DRUG_SUBSTANCE_TYPE]}
                               instructions={`To edit this field, the "Category" field above must be set to "Drug Substance (API)"`}
                               disabled={this.state.category !== "Drug Substance (API)"}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
            <TypeaheadAttribute name="form"
                                options={CommonEditables.MATERIAL_FORM_OPTIONS}
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
            />
          </div>
          <div className="row">
            <TextAttribute name="empiricalFormula"
                           parent={this}
            />
            <div className="row col-sm-6">
              <NumberAttribute name="density"
                               displayName="Density (g/ml)"
                               instructions={`To edit this field, the “Form” field above cannot be set to “Solid”`}
                               min={0.000001}
                               disabled={this.state.form === "Solid"}
                               parent={this}
              />
              <TextAttribute name="densityConditions"
                             instructions={`To edit this field, the “Form” field above cannot be set to “Solid”`}
                             disabled={this.state.form === "Solid"}
                             parent={this}
              />
            </div>
          </div>
          <div className="row">
            <TextAttribute name="chemicalStructure"
                           tooltipText="Can be a text description of the chemical structure.  Diagram of structure can be added as an attachment."
                           parent={this}
            />
            <NumberAttribute name="molecularWeight"
                             displayName="Molecular Weight (g/mol)"
                             min={0.000001}
                             parent={this}
            />
          </div>
          <div className="row">
            <TextAttribute name="chemicalNameCAS"
                           displayName="Chemical Name (CAS)"
                           tooltipText={
                             <p>Name assigned to chemical by Chemical Abstracts Service&nbsp;
                               <a target="_blank"
                                  rel="noopener noreferrer"
                                  className="tooltip-link"
                                  href="https://commonchemistry.cas.org"
                               >
                                 (CAS)
                               </a>
                             </p>
                           }
                           parent={this}
            />
            <TextAttribute name="chemicalNameIUPAC"
                           displayName="Chemical Name (IUPAC)"
                           tooltipText={
                             <p>Name assigned to chemical by International Union of Pure and Applied Chemistry&nbsp;
                               <a target="_blank"
                                  rel="noopener noreferrer"
                                  className="tooltip-link"
                                  href="https://iupac.org/"
                               >
                                 (IUPAC)
                               </a>
                             </p>
                           }
                           parent={this}
            />
          </div>
          <div className="row">
            <TextAttribute name="otherNames"
                           tooltipText="Any other name that might be used to identify the chemical substance"
                           parent={this}
            />
            <TextAttribute name="innUsan"
                           displayName="INN/USAN"
                           tooltipText={
                             <p>An International Nonproprietary Name (INN) is an official generic and nonproprietary name given to a pharmaceutical drug or active ingredient. The USAN (United States Adopted Name) is a national naming scheme is almost always identical to the&nbsp;
                               <a target="_blank"
                                  rel="noopener noreferrer"
                                  className="tooltip-link"
                                  href="http://www.who.int/medicines/services/inn/innquidance/en/"
                               >
                                 INN.
                               </a>
                             </p>
                           }
                           parent={this}
            />
          </div>
          <div className="row">
            <TextAttribute name="casRegistryNumber"
                           displayName="CAS Registry Number"
                           tooltipText={
                             <p>The&nbsp;
                               <a target="_blank"
                                  rel="noopener noreferrer"
                                  className="tooltip-link"
                                  href="https://commonchemistry.cas.org"
                               >
                                 CAS Registry Number
                               </a>
                               &nbsp;is a unique numeric identifier for a single chemical substance.
                             </p>
                           }
                           parent={this}
            />
            <TypeaheadAttribute name="compendialStandard"
                                options={CommonEditables.COMPENDIAL_STANDARD}
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
                                multiple={true}
                                instructions={`To edit this field, the "Category" field above must be set to "Drug Substance (API)" or "Excipient"`}
                                disabled={this.state.category !== "Drug Substance (API)" && this.state.category !== "Excipient"}
                                tooltipText={(<div>
                                    <p>
                                      Compendial Name: The name of an article for which a monograph is provided in an
                                      official compendia (e.g., United States Pharmacopeia, National Formulary, or
                                      Homeopathic Pharmacopeia) recognized by regulatory agencies.
                                    </p>
                                    <p>
                                      An article may be an official substance or official preparation. Examples of
                                      Compendial Standards include:
                                      <ul>
                                        <li>
                                          <b>USP</b> - US Pharmacopeia
                                        </li>
                                        <li>
                                          <b>NF</b> - National Formulary
                                        </li>
                                        <li>
                                          <b>BP</b> - British Pharmacopeia
                                        </li>
                                        <li>
                                          <b>EP</b> - European Pharmacopeia
                                        </li>
                                        <li>
                                          <b>JP</b> - Japanese Pharmacopeia
                                        </li>
                                        <li>
                                          <b>CP</b> - Chinese Pharmacopeia
                                        </li>
                                      </ul>
                                    </p>
                                  </div>
                                )}
            />
          </div>
          <div className="row">
            <CheckboxAttribute name="certificateOfAnalysis"
                               displayName="COA/COC"
                               tooltipText="A Certificate of Analysis (COA) or Conformance (COC) is a document issued by Quality Assurance that confirms that a regulated product meets its product specification. They commonly contain the actual results obtained from testing performed as part of quality control of an individual batch of a product."
                               parent={this}
            />
          </div>
        </Section>
        <Section id="references"
                 parent={this}
                 showDocLinks={true}
                 addOptions={[
                   {id: "references", label: "References"},
                   {id: "standards", label: "Standards"},
                   {id: "guidances", label: "Guidances"}]}
                 header={<span>
                   References & Standards
                   <InfoTooltip id="infoRefAndStandardsControl"
                                verbiage={
                                  <div>
                                    References, standards, safety data sheets, etc. for highly characterized specimens
                                    of drug substances, excipients, food ingredients, impurities, degradation products,
                                    dietary supplements, compendial reagents, tests, assay, and performance calibrators.
                                    (e.g. USP, BP, EP, JP, ISO, etc.)
                                  </div>}
                   />
                   </span>}
        >
        </Section>
        {this.renderCreateMaterialPopup()}
        {this.renderCreateCompletedConfirmation()}
      </div>
    );
  }

  getAdditionalButtons() {
    let buttons = super.getAdditionalButtons();
    if (buttons && this.isView() && this.getCurrentState() === UIUtils.VERSION_STATES.APPROVED) {
      buttons.push({
        id: "createProjectMaterialButton",
        onClick: () => this.toggleCreateProjectMaterialPopup(true),
        text: "Add to Project",
        isPrimary: true,
      });
    }
    return buttons;
  }

  toggleCreateProjectMaterialPopup(visible = false) {
    return this.setStateSafely({isCreateMTPPopupOpen: visible});
  }

  handleCreateMaterialCompleted(createMaterialResponse) {
    $(this.createMtpPopup).modal("hide");
    this.setStateSafely({
      createMaterialResponse,
      isConfirmationOpen: true
    });
  }

  renderCreateMaterialPopup() {
    const libraryMaterialPage = this;
    return <div>
      {this.state.isCreateMTPPopupOpen ? (
        <CreateProjectMaterialPopup modalRef={createMtpPopup => this.createMtpPopup = createMtpPopup}
                                    title={"Add to Project"}
                                    instance={libraryMaterialPage.state}
                                    onHideModal={() => this.toggleCreateProjectMaterialPopup(false)}
                                    onCreateCompleted={this.handleCreateMaterialCompleted}
        />
      ) : ""}
    </div>;
  }

  renderCreateCompletedConfirmation() {
    const setNewState = () => {
      this.setStateSafely({
        isConfirmationOpen: false
      });
    };

    const handleStay = () => {
      setNewState();
      const {createMaterialResponse} = this.state;
      const material = getMaterial(createMaterialResponse);
      window.location.href = UIUtils.getSecuredURL(UIUtils.FRONT_END_URL + `/library/list.html?filter=${encodeURIComponent(material.category)}`);
    };
    const handleGoToRecord = () => {
      setNewState();
      const {createMaterialResponse} = this.state;
      LibraryHelper.redirectToMaterial(createMaterialResponse);
    };

    const getMaterial = result => {
      return Array.isArray(result)
        ? result.find(m => m.typeCode === "MT")
        : result.typeCode === "MT" ? result : {};
    };

    const getProcess = (material) => {
      return new TypeaheadObjectCache("Process", material.project.id)
        .getOptionsFromCache()
        .find(process => process.id === material.ProcessId);
    };
    const getMessage = () => {
      const {createMaterialResponse} = this.state;
      const material = getMaterial(createMaterialResponse);
      const process = getProcess(material);
      return `${material.name} ${UIUtils.getRecordCustomIdForDisplay(material)} has been added to PRJ-${material.project.id} - ${process.name}`;
    };

    return (
      <div>
        {this.state.isConfirmationOpen ? (
          <ConfirmationModal
            onStay={handleStay}
            message={getMessage()}
            onGoToRecord={handleGoToRecord}
          />
        ) : null}
      </div>
    );
  }

  handleImportCoA() {
    window.location.href = "/import/importFile.html?key=LIBRARY_COA&selectedDependencyRecordId=" + this.state.id;
  }
}

export default I18NWrapper.wrap(LibraryMaterial, ["library"]);
