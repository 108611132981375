"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faFile } from "@fortawesome/free-solid-svg-icons";
import BaseLinksAttachmentsAttribute from "./base_links_attachments_attribute";
import { createRowDataDiffForLinkedEntities, getClassNameForDiffState } from "../../helpers/diff_helper";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import ReactDOMServer from "react-dom/server";
import { ATTRIBUTE_TYPE } from "./constants/attribute_type";
import Typeahead from "../../widgets/typeahead";
import LabelTooltip from "../../widgets/tooltips/label_tooltip";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import BaseObjectCache from "../../utils/cache/base_object_cache";
import MultipleTypeaheadObjectCache from "../../utils/cache/multiple_typeahead_object_cache";
import { TABLE_ITEM_MODE } from "./base_json_attribute";
import { Menu, MenuItem } from "react-bootstrap-typeahead";
import SupportDocumentsInfoTooltip from "../../widgets/tooltips/support_documents_info_tooltip";
import MemoryCache from "../../utils/cache/memory_cache";

const Logger = Log.group(LOG_GROUP.JSONAttribute, "BaseLinkedEntitiesAttribute");

const LINKED_VERSIONS_SUFFIX = "LinkedVersions";

/**
 * In contrast to the parent class which saves all provided widget fields as a json string on a given database field,
 * the BaseLinkedEntitiesAttribute saves the actual rows of the widget to a linked entity database table, i.e the IQATOFQAs table.
 * The provided widget fields for this control cannot be anything. They have to comply with the table fields of the linked entity
 * the widget will be updating. This control supports all input types the parent classes support and in addition the linkedTypeahead
 * input. This is a special typeahead which gets its options from the linked entity table it points to. In the IQAToFQAs linked entity
 * for example, the linked typeahead would be populated with the FQAs that can be linked to the IQA being edited.
 */
export default class BaseLinkedEntitiesAttribute extends BaseLinksAttachmentsAttribute {
  constructor(props, widgetFields) {
    super(props, props.widgetFields ? props.widgetFields : widgetFields);

    // this.projectId is how we watch to see if the projectId has been set in the parent yet.
    this.projectId = this.props.parent.getProjectId();
    if (!this.needsProjectId() || this.projectId) {
      this.loadTypeaheadOptions();
    } else {
      this.props.parent.addOnDataReceivedListener(() => {
        this.projectId = this.props.parent.getProjectId();
        if (!this.needsProjectId() || this.projectId) {
          this.loadTypeaheadOptions();
        }
      }, this);
    }

    this.errorMessages.editedRowPending = "At least one object is in edit state. Please save or cancel all objects in edit state.";
  }

  /**
   * Overwrite this if the base class doesn't need the parent's project Id (ie. in a document).
   * @return {boolean}
   */
  needsProjectId() {
    return true;
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
    if (!shouldUpdate) {
      shouldUpdate = (JSON.stringify(this.props.linkObject) !== JSON.stringify(nextProps.linkObject))
        || (JSON.stringify(this.props.linkToObject) !== JSON.stringify(nextProps.linkToObject))
        || (JSON.stringify(this.props.linkToObjectId) !== JSON.stringify(nextProps.linkToObjectId));
    }

    return shouldUpdate;
  }

  /**
   * Overwrite this in child classes to customize the rendering of a particular field in the table column
   * @param field The field to display a title for
   */
  getFieldDisplayName(field) {
    if (field.inputType === "linkedTypeahead" && !field.useDisplayName) {
      return this.getLinkToObjectDisplayName();
    } else {
      return super.getFieldDisplayName(field);
    }
  }

  /**
   * Returns the column name for the linked entities typeahead
   */
  getLinkToObjectDisplayName() {
    if (this.hasLinkToObject()) {
      return this.hasSingleLinkToObject() ?
        UIUtils.convertCamelCaseToSpacedOutWords(this.props.linkToObject[0]) :
        this.props.linkToObjectDisplayName || this.props.linkToObject.map(object => UIUtils.convertCamelCaseToSpacedOutWords(object)).join(" or ");
    } else {
      return "";
    }
  }

  /**
   * Overwrite this in child classes to customize the rendering of a particular field in a table column
   * This method returns the DOM object used to populate the data property of the datatables columns element.
   * @param field The field to render a column for
   * @param result The datatables row data
   * @param type The datatables display mode flag
   * @returns {*}
   */
  getColumn(field, result, type) {
    let column = super.getColumn(field, result, type);
    if (!column && field.inputType === "linkedTypeahead") {
      if (type === "display") {
        column = ReactDOMServer.renderToStaticMarkup(
          <span>{this.hasSingleLinkToObject() ? result[this.getLinkToId()] : ""}</span>
        );
      } else {
        // For sorting / filtering
        let selectedOption = this.getLinkSelectedOption(result);
        if (selectedOption) {
          const label = selectedOption.label;
          column = this.props.linkToObject.indexOf(selectedOption.typeCode)
            + label.substring(label.indexOf(" - ") + 3);
        }
      }
    }

    return column;
  }

  /**
   * Overwrite this in child classes to customize the rendering of a particular cell.
   * This method will cover the field types returned by BaseJsonAttribute.getSupportedFieldTypes()
   * Overwrite getFieldInput to further customize the input controls used in table cell inline editors
   * @param field The field to customize the column cell for
   * @param rowData The row data object
   */
  getColumnDef(field, rowData) {
    Logger.verbose("Entering BaseLinkedEntitiesAttribute.getColumnDef", Log.object(field), Log.object(rowData));
    let columnDef = super.getColumnDef(field, rowData);

    if (!columnDef && field.inputType === "linkedTypeahead") {
      // Find the options and the selected one.
      let allTypeaheadOptions = this.getAllTypeaheadOptions();
      let typeaheadOptionsAvailable;
      let selectedOption = this.isRowInEditMode(rowData.uuid) ?
        this.getEditedRowByUUID(rowData.uuid).selectedOption
        : this.getLinkSelectedOption(rowData);

      // Filter out the typeahead options already taken by the other rows (but not this one).
      if (this.isRowInEditMode(rowData.uuid)) {
        let allRows = this.getValueAsObject();
        let setOfLinkedKeysInOtherRows = allRows.map(row => {
          let thisRowOption = this.getLinkSelectedOption(row);
          return thisRowOption ? thisRowOption.typeCode + "-" + thisRowOption.id : null;
        });
        if (selectedOption) {
          setOfLinkedKeysInOtherRows.splice(setOfLinkedKeysInOtherRows.indexOf(selectedOption.typeCode + "-" + selectedOption.id), 1);
        }
        // Remove any nulls
        setOfLinkedKeysInOtherRows = setOfLinkedKeysInOtherRows.filter(option => !!option);

        typeaheadOptionsAvailable = allTypeaheadOptions ?
          allTypeaheadOptions.filter(option => !setOfLinkedKeysInOtherRows.includes(option.typeCode + "-" + option.id)) : null;
      }

      if (!this.isDiffingVersions() && this.props.optionsFilter) {
        typeaheadOptionsAvailable = typeaheadOptionsAvailable ?
          typeaheadOptionsAvailable.filter(this.props.optionsFilter) : null;
      }

      let onChangeHandler = field.forceUpdate ? this.handleChangeFieldAndUpdateParent : this.handleChangeField;

      const id = this.props.name + "_LinkTo_" + rowData.index;
      const itemTooltip = this.getItemTooltip(rowData, selectedOption);
      const versionLink = (
        <a href={selectedOption ? getURLByTypeCodeAndId(selectedOption.typeCode, "View", selectedOption.id) : ""}
           rel="noopener noreferrer"
           target="_blank"
        >
          <FontAwesomeIcon icon={faLink} />
        </a>
      );

      const link = this.renderLinkedTypeaheadLink(id, selectedOption, field, rowData);

      const diffLink = this.isDiffingVersions() && rowData.diffState
        ? (
          <Fragment>
           <span className="links-linkTo-diff">
              <span className={getClassNameForDiffState(rowData.diffState)}>
                {selectedOption ? this.getLabelKey(selectedOption) : ""}
              </span>
            </span>
            {versionLink}
            {rowData.numberOfLinks > 0 &&
              (
                <SupportDocumentsInfoTooltip id={`linkedDocumentsInfo_${rowData.index}`}
                                             textDirection="bottom"
                                             icon={faFile}
                                             text={rowData.numberOfLinks > 0 ? rowData.numberOfLinks : ""}
                                             verbiage={rowData.linkDiffHTMLAll}
                />
              )}
          </Fragment>
        )
        : "";

      columnDef = (
        !this.isRowInEditMode(rowData.uuid)
          ? (
            this.isDiffingVersions() && rowData.diffState
              ? (
                <span id={id}>
                  {itemTooltip
                    ? (
                      <LabelTooltip
                        id={`${id}LabelTooltip`}
                        noColon
                        showOnHover
                        tooltipText={itemTooltip}
                        text={diffLink}
                      />
                    ) : (
                      diffLink
                    )
                  }
                  </span>
              ) : (
                <div className={"links-manage"}>
                  {selectedOption ? (
                    itemTooltip ? (
                      <LabelTooltip
                        id={`${id}LabelTooltip`}
                        noColon
                        showOnHover
                        tooltipText={itemTooltip}
                        text={link}
                      />
                    ) : link
                  ) : (<div className={"skeleton"} />)}
                </div>
              )
          )
          : // else we're in Edit mode
          (
            <div id={this.props.name + "_LinkToFormGroup_" + rowData.index}
                 className="form-group"
            >
              <div id={id + "Div"}
                   className="links-manage"
              >
                <Typeahead options={typeaheadOptionsAvailable ? typeaheadOptionsAvailable : []}
                           id={id}
                           inputProps={this.getTypeheadInputProps(id)}
                           labelKey={this.getLabelKey}
                           selected={selectedOption ? [selectedOption] : null}
                           renderMenu={this.renderMenu}
                           disabled={this.isFieldDisabled(field)}
                           onChange={onChangeHandler.bind(this, rowData, field)}
                />
              </div>
              <div className="help-block with-errors"
                   id={id + "ErrorDiv"}
              />
            </div>
          )
      );
    }

    return columnDef;
  }

  getLabelKey(option) {
    return option.label;
  }

  getTypeheadInputProps(id) {
    return {id: id + "Input", autoComplete: "off"};
  }

  renderMenu(options, menuProps) {
    const items = options.map((option, index) => {
      return (
        <MenuItem key={index} option={option}>
          <span>{option.label}</span>
        </MenuItem>
      );
    });

    return <Menu {...menuProps}>{items}</Menu>;
  }

  /**
   * @param item {*} The item to gather the tooltip for.
   * @param typeaheadItem {*} The typeahead item that represents that item.
   * @returns {string} The tooltip for the item.
   * @protected
   */
  // eslint-disable-next-line no-unused-vars
  getItemTooltip(item, typeaheadItem) {
    return "";
  }

  /**
   * Returns the attribute name
   * @returns {*}
   */
  getAttributeName() {
    return this.getParentStateName(this.props.linkObject[0]);
  }

  /**
   * Returns true if at least one link to object has been provided through the attribute properties, otherwise false
   */
  hasLinkToObject() {
    return this.props.linkToObject;
  }

  /**
   * Returns true if this control supports a single link to a single linked entity or false if more than one linked
   * entities have been specified in the object properties
   * @returns {boolean}
   */
  hasSingleLinkToObject() {
    return this.hasLinkToObject() ? this.props.linkToObject.length === 1 : false;
  }

  /**
   * Returns the FK field for a given linked entity
   * @param linkToObject The linked entity for which the FK id will be returned
   * @returns {*}
   */
  getLinkToId(linkToObject) {
    if (this.props.linkToObjectId) {
      let linkToObjIndex = this.props.linkToObject.indexOf(linkToObject);
      return this.props.linkToObjectId[linkToObjIndex];
    } else if (this.hasLinkToObject()) {
      if (!linkToObject) {
        return this.props.linkToObject[0] + "Id";
      } else {
        return linkToObject + "Id";
      }
    } else {
      return null;
    }
  }

  getFieldValueInRow(field, row) {
    let result = null;

    if (row) {
      if (field.inputType === "linkedTypeahead") {
        const value = row && row.selectedOption;
        result = value && value.id;

        if (result && value.typeCode) {
          result = `${value.typeCode}-${result}`;
        }
      } else {
        result = super.getFieldValueInRow(field, row);
      }
    }
    return result;
  }

  /**
   * Returns the value of the control as a rows collection from the underlying parent control state
   * Depending on whether the parent control holds version link values or not, this method will take care of it and
   * return back the right rows
   * @param linkObjectFilter Optionally specify the link object to return the values for that particular link only
   */
  getValue(linkObjectFilter) {
    const {isDiffingVersions, isEdit, showsVersionsInView, isAdd, state} = this.props.parent;

    let rows = [];
    const filteredLinkObjects = this.props.linkObject.filter(obj => {
      return !linkObjectFilter || obj === linkObjectFilter;
    });
    for (let linkObject of filteredLinkObjects) {
      const parentVersionStateProp = this.getParentStateVersionsName(linkObject);
      const shouldShowVersionInformation = !isAdd()
        && !isEdit()
        && (isDiffingVersions()
          || showsVersionsInView())
        && state[parentVersionStateProp];

      const key = shouldShowVersionInformation
        ? parentVersionStateProp
        : this.getParentStateName(linkObject);

      const thisObjRows = state[key];

      Logger.verbose(`GetValue: Rows to add to result (state key: ${Log.symbol(key)}: `, Log.object(thisObjRows), Log.object(state));

      if (thisObjRows) {
        rows = rows.concat(thisObjRows);
      }
    }
    Logger.verbose(`GetValue: Linked rows:`, Log.object(rows));

    /* This makes sure that each row has a row index. Row indexes are important for sorting the rows in all
       controls inheriting from this one. They are used for making sure that new rows by default are added to the
       bottom of the list and also for crafting the ids of each element based on the row they belong in.
     */
    let totalRowsWithIndex = rows.filter(row => UIUtils.isInteger(row.index)).length;
    for (let row of rows.filter(row => row.mode !== TABLE_ITEM_MODE.ADD && !UIUtils.isInteger(row.index))) {
      row.index = totalRowsWithIndex++;
    }
    Logger.verbose(`GetValue: Rows NOT in ADD mode:`, Log.object(rows));
    // Then the rows being in Add mode. Those should be shown down to the bottom of the table.
    for (let row of rows.filter(row => row.mode === TABLE_ITEM_MODE.ADD && !UIUtils.isInteger(row.index))) {
      row.index = totalRowsWithIndex++;
    }
    Logger.verbose(`GetValue: Filtered Rows:`, Log.object(rows));
    const rowsWithIds = this.setRowIds(rows);
    Logger.verbose(`GetValue: Rows to return`, Log.object(rows));
    return UIUtils.deepClone(rowsWithIds);
  }

  /**
   * Returns the value (rows) of this control as an object
   * @returns {*[] | *}
   */
  getValueAsObject(linkObjectFilter) {
    return this.getValue(linkObjectFilter);
  }

  /**
   * Updates the parent control state with the value (rows) this control manages
   * @param rows
   */
  setValue(rows) {
    let linkObjectToRowsMap = new Map();
    // Initialize the arrays so no old values are there
    for (let linkObject of this.props.linkObject) {
      linkObjectToRowsMap.set(linkObject, []);
    }

    // Split the rows according to their typeCodes
    for (let row of rows) {
      let foundAHome = false;
      if (this.hasLinkToObject()) {
        for (let i = 0; i < this.props.linkToObject.length; i++) {
          let linkToObject = this.props.linkToObject[i];
          if (row[this.getLinkToId(linkToObject)]) {
            let linkObject = this.props.linkObject[i];
            linkObjectToRowsMap.get(linkObject).push(row);
            foundAHome = true;
            break;
          }
        }
      }

      if (!foundAHome) {
        linkObjectToRowsMap.get(this.props.linkObject[this.props.linkObject.length - 1]).push(row);
      }
    }

    let index = 0;
    for (let linkObject of linkObjectToRowsMap.keys()) {
      index += 1;
      const currentIndex = index;
      this.props.parent.handleChangeValue(
        this.getParentStateName(linkObject),
        linkObjectToRowsMap.get(linkObject),
        () => this.handleAfterChangingValue(currentIndex === linkObjectToRowsMap.size),
        ATTRIBUTE_TYPE.JSON_ATTRIBUTE,
      );
    }
  }

  handleAfterChangingValue(isUpdatingAll) {
    // We only call this method once we already updated all the linked entities, so we don't
    // update the parent state multiple times
    if (isUpdatingAll) {
      this.props.parent.handleAfterUpdatingLinkedEntities(this.props.name === "risk");
    }
  }

  /**
   * Returns the link or Attachment object used for initializing the LinkAttachment control in the getChildRowFieldInput
   * method. This can be overwritten in child classes to customize the way this link/attachment object is returned
   * @param rowData
   */
  getLinkData(rowData) {
    let uuid = rowData.uuid;
    return this.isDiffingVersions() && rowData.diffState ? rowData : this.isRowInEditMode(uuid) ? this.getEditedFileDataById(uuid) : this.getFileData(rowData);
  }

  /**
   * Overwrite in child classes to customize the way attachment file attachment data is extracted from an underlying datatables row data
   * @param editedRow The row data to extract file attachment information from
   * @returns {*}
   */
  getFileData(editedRow) {
    if (editedRow.links) {
      let links = editedRow.links;
      if (typeof links === "string") {
        links = JSON.parse(links);
      }
      let fileData = links[0];
      fileData.index = editedRow.index;
      return fileData;
    } else {
      // Versions pass in just the file data.
      return editedRow;
    }
  }

  /**
   * Updates the raw data row with file and attachment object information from the inline editor and the edited row attached to it
   * @param row The raw data to assign file & attachment information to
   * @param editedRow The EditedRow object being edited by the user
   */
  setFileData(row, editedRow) {
    let docLink = {};
    let editedRowLink = this.getFileData(editedRow);
    docLink.uuid = editedRowLink.uuid;
    docLink.linkType = editedRowLink.linkType;
    docLink.fileName = editedRowLink.fileName;
    docLink.S3TmpKey = editedRowLink.S3TmpKey;
    docLink.S3TmpVersion = editedRowLink.S3TmpVersion;
    docLink.link = editedRowLink.link;
    docLink.linkVersion = editedRowLink.linkVersion;
    row.links = JSON.stringify([docLink]);
  }

  /**
   * Returns file attachment data information provided the id of the editing row
   * @param id The unique id of the editing row
   */
  getEditedFileDataById(id) {
    return this.getFileData(this.getEditedRowByUUID(id));
  }

  /**
   * Returns file attachment data information for the row in the attribute's state, given the row id of the datatables row
   * @param id The unique id of the row in the datatables
   */
  getNonEditedFileDataById(id) {
    let row = this.getValueAsObject().find(row => row.uuid === id);
    return this.getFileData(row);
  }

  /**
   * Validates the input field before saving for the row being edited and return true if it is valid, false otherwise.
   * Overwrite this method in child classes to customize the default validation behavior of any input field.
   * @param editedRow The rows data of the row being edited
   * @param field The field to validate
   * @param rows {[]} The array with all the rows in the control
   * @param errorReport {Map<string, *>} An object containing information about the errors that occurred
   */
  isEditingRowFieldValid(editedRow, field, rows, errorReport) {
    let isValid = true;
    if (field.inputType === "linkedTypeahead") {
      isValid = this.isTypeaheadRequired(isValid, editedRow, field, rows, errorReport);
      isValid = this.isEntryUnique(!!editedRow.selectedOption, editedRow, field, rows, errorReport);
    } else {
      isValid = super.isEditingRowFieldValid(editedRow, field, rows, errorReport);
    }
    !field.fieldName && Logger.verbose(() => ">> Validation result:", isValid, editedRow, field, rows, errorReport);
    return isValid;
  }

  /**
   * Generic handler to update the edited row field with a user provided value
   * @param rowData The row to update the edited row for
   * @param field The field to update with the provided data
   * @param e The event object holding the user provided data
   */
  handleChangeField(rowData, field, e) {
    if (field.inputType === "linkedTypeahead") {
      $("[data-toggle='validator']").validator("update");
      let editedRow = this.getEditedRowByUUID(rowData.uuid);
      Logger.warn(">>> EDITED ROW: ", Log.object(editedRow));
      editedRow.selectedOption = e.length === 0 ? null : e[0];

      // Hide the error message if it's showing
      const errorDiv = $("#" + this.props.name + "_LinkTo_" + rowData.index + "ErrorDiv");
      Logger.verbose(() => ">> HIDING ERROR MESSAGE");
      errorDiv.empty();
    } else {
      super.handleChangeField(rowData, field, e);
    }
  }

  /**
   * Cancels pending changes in an edited row and turns off the row inline editor
   * @param rowData The row for which any pending changes should be discarded
   */
  handleCancel(rowData) {
    let editedRow = this.getEditedRowByUUID(rowData.uuid);

    super.handleCancel(rowData);
    if (editedRow.mode !== "Add") {
      this.props.parent.forceUpdate();
    }
  }

  /**
   * Updates the rows collection with the updated edited row data
   * @param rowData The datatables row to update with the edited data
   * @param rows The rows object passed in from the child object updated with the child object edited row fields and values
   * @param setValueCallback Callback to execute to set the value of edited row to the parent state
   * @param errorReport {Map<string, string>} A map containing the errors happened during this operation
   * @returns {boolean} A boolean indicating whether the validation inside this method was successful or not.
   */
  handleSave(rowData, rows, setValueCallback, errorReport = new Map()) {
    $("[data-toggle='validator']").validator("update");
    let editedRow = this.getEditedRowByUUID(rowData.uuid);
    if (this.isEditingRecordValid(editedRow, rows, errorReport)) {
      rows = rows ? rows : this.getValueAsObject();

      // Since each inheritance level can have its own validation logic, we return a boolean indicating whether the
      // validation succeeded. If it failed, we don't proceed.
      if (super.handleSave(rowData, rows, null, errorReport)) {
        let row = rows.find(row => row.uuid === editedRow.uuid);
        if (this.hasLinkToObject()) {

          let selectedOption = editedRow.selectedOption;
          let modelName = UIUtils.getModelNameForTypeCode(selectedOption.typeCode);
          modelName = UIUtils.stripAllWhitespaces(modelName);

          const selectedLinkToId = this.getLinkToId(modelName);

          if (this.props.linkToObject && this.props.linkToObject.length > 1) {
            // Delete the old IDs that might be lingering.  See QI-2822.
            for (const linkToObject of this.props.linkToObject) {
              const linkToObjectId = this.getLinkToId(linkToObject);
              if (linkToObjectId !== selectedLinkToId && row[linkToObjectId]) {
                delete row[linkToObjectId];
                delete row.id;
              }
            }
          }

          row[selectedLinkToId] = selectedOption.id;
        }

        if (setValueCallback) {
          setValueCallback(rows);
        }

        delete row.selectedOption;
        return true;
      }
    }
    this.showRecordValidationErrors(editedRow, errorReport);
    return false;
  }

  /**
   * Initializes the links/attachment object properties when adding a new row
   * @param editedRow The new row to initialize the link information for
   */
  initializeLink(editedRow) {
    let editedRowLink = {
      uuid: editedRow.uuid,
      linkType: "",
      fileName: "",
      S3TmpKey: "",
      S3TmpVersion: "",
      link: "",
      linkVersion: "",
    };

    editedRow.links = [editedRowLink];
  }

  /**
   * Turns on the inline editor for the provided row.
   * @param rowData The row data to populate the inline editor controls with
   * @param editedRow Optional the edited row, if this method is called from a child class where the edited row is already initialized.
   * @param forceUpdate Set to true to clear any control errors and force a react update
   */
  handleEdit(rowData, editedRow, forceUpdate = true) {
    editedRow = editedRow ? editedRow : {};
    super.handleEdit(rowData, editedRow, false);
    editedRow.selectedOption = this.getLinkSelectedOption(rowData);
    this.clearErrorText(forceUpdate);
  }

  /**
   * Handles the edit even of a datatables row. This method will populate the edited row properties from the raw data row and will
   * display the inline editor
   * @param editedRow The edited row to populate with the row fields and values
   * @param row The raw data row for which the inline editor will be displayed
   */
  handleEditLink(editedRow, row) {
    editedRow.links = [{}];

    let rowLink = this.getFileData(row);
    let editedRowLink = this.getFileData(editedRow);
    editedRowLink.uuid = editedRow.uuid;
    editedRowLink.linkType = rowLink.linkType ? rowLink.linkType : "Link";
    editedRowLink.fileName = rowLink.fileName;
    editedRowLink.S3TmpKey = rowLink.S3TmpKey;
    editedRowLink.S3TmpVersion = rowLink.S3TmpVersion;
    editedRowLink.link = rowLink.link;
    editedRowLink.linkVersion = rowLink.linkVersion;
  }

  /**
   * Returns the typeahead option if it exists from the selected id on the underlying row
   * @param row The row given which the typeahead option is searched
   * @returns {null}
   */
  getLinkSelectedOption(row) {
    if (this.hasLinkToObject()) {
      for (let linkToObject of this.props.linkToObject) {
        if (row[this.getLinkToId(linkToObject)]) {
          const memoryCache = MemoryCache.getNamedInstance(`base_linked_entities_attributes_getLinkSelectedOption`);
          const cacheKey = `${linkToObject}_${this.props.parent.getProjectId()}_${this.props.parent.getProcessId()}_${this.isDiffingVersions() ? "diff" : ""}`;
          let typeaheadOptions = memoryCache.get(cacheKey);
          if (!typeaheadOptions) {
            const cache = new TypeaheadObjectCache(linkToObject, this.props.parent.getProjectId(), this.props.parent.getProcessId());
            typeaheadOptions = this.isDiffingVersions() ? cache.getOptionsFromCacheIncludingArchived() : cache.getOptionsFromCache();
            if (!BaseObjectCache.isObjectCacheStillLoading(typeaheadOptions)) {
              memoryCache.set(cacheKey, typeaheadOptions);
            }
          }

          return typeaheadOptions.find(option => {
            return option.id === row[this.getLinkToId(linkToObject)];
          });
        }
      }
    }

    return null;
  }

  /**
   * Loads all typeahead options from the linked entities specified in the linkToObject property
   */
  loadTypeaheadOptions() {
    if (this.hasLinkToObject()) {
      const requestData = this.getTypeaheadRequestData();
      let processId;
      if (this.props.parent.getProcessId) {
        processId = this.props.parent.getProcessId();
      }

      const memoryCache = MemoryCache.getNamedInstance("base_linked_entities_attribute");
      const remainingTypesToCache = this.props.linkToObject.filter(typeCode => !memoryCache.get(this.getMemoryCacheKeyForTypeahead(typeCode)));

      if (remainingTypesToCache.length > 0) {
        new MultipleTypeaheadObjectCache(remainingTypesToCache, this.projectId, processId).loadOptions((results, typeCode) => {
          memoryCache.set(this.getMemoryCacheKeyForTypeahead(typeCode), results);
          this.handleTypeaheadResultsFromServer(results, typeCode);
        }, requestData);
      } else {
        for (let typeCode of this.props.linkToObject) {
          const results = memoryCache.get(this.getMemoryCacheKeyForTypeahead(typeCode));
          if (results) {
            this.handleTypeaheadResultsFromServer(results, typeCode);
          }
        }
      }
    }
  }

  getMemoryCacheKeyForTypeahead(typeCode) {
    let processId;
    if (this.props.parent.getProcessId) {
      processId = this.props.parent.getProcessId();
    }

    return `typeahead_${typeCode}_${this.projectId}_${processId}`;
  }

  /**
   * Override to change the request data sent to load the typeahead options
   * @return {*}
   * @protected
   */
  getTypeaheadRequestData() {
    return {};
  }

  /**
   * Forces an update when all typeahead options are loaded from the server
   */
  handleTypeaheadResultsFromServer() {
    if (this._isMounted) {
      this._forceFullRedraw = true;
      this.forceUpdate();
    }
  }

  /**
   * Loads and returns all typeahead options for all linked entities
   */
  getAllTypeaheadOptions() {
    if (this.hasLinkToObject()) {

      const memoryCache = MemoryCache.getNamedInstance("base_linked_entities_attribute_all");

      let allTypeaheadOptions = [];
      const cacheKey = `allTypeaheadOptions_${this.props.linkToObject.join("_")}_${this.props.parent.getProjectId()}_${this.props.parent.getProcessId()}_${this.isDiffingVersions() ? "_diff" : ""}`;
      if (!memoryCache.get(cacheKey)) {
        let atLeasOneObjectIsStillLoading = false;
        for (let linkToObject of this.props.linkToObject) {
          const cache = new TypeaheadObjectCache(linkToObject, this.props.parent.getProjectId(), this.props.parent.getProcessId());
          let typeaheadOptions = this.isDiffingVersions() ? cache.getOptionsFromCacheIncludingArchived() : cache.getOptionsFromCache();
          if (BaseObjectCache.isObjectCacheStillLoading(typeaheadOptions)) {
            atLeasOneObjectIsStillLoading = true;
          }

          if (typeaheadOptions.length > 0 && !BaseObjectCache.isObjectCacheStillLoading(typeaheadOptions)) {
            allTypeaheadOptions = allTypeaheadOptions.concat(typeaheadOptions);
          }
        }

        // we cache only when all objects are loaded
        if (!atLeasOneObjectIsStillLoading) {
          memoryCache.set(cacheKey, allTypeaheadOptions);
        }
      } else {
        allTypeaheadOptions = memoryCache.get(cacheKey);
      }

      return allTypeaheadOptions;
    }
  }

  /**
   * Returns the attribute name used to persist in the parent object state the linked entity row information
   * @param linkObject The linked entity for which the state attribute name will be returned
   * @returns {*}
   */
  getParentStateName(linkObject) {
    if (this.hasLinkToObject()) {
      if (this.hasSingleLinkToObject()) {
        return UIUtils.pluralize(this.removeLinkedVersionsSuffix(this.props.linkObject[0]));
      } else {
        return UIUtils.pluralize(this.removeLinkedVersionsSuffix(linkObject));
      }
    } else {
      return UIUtils.pluralize(this.removeLinkedVersionsSuffix(this.props.linkObject[0]));
    }
  }

  /**
   * This method will remove the "LinkedVersions" suffix if present.
   * @param name
   * @return {*|string}
   */
  removeLinkedVersionsSuffix(name) {
    const suffixes = [UIUtils.singularize(LINKED_VERSIONS_SUFFIX), LINKED_VERSIONS_SUFFIX];

    if (name) {
      for (let suffix of suffixes) {
        if (name.endsWith(suffix)) {
          return name.substring(0, name.length - suffix.length);
        }
      }
    }
    return name;
  }

  /**
   * This method will append the "LinkedVersions" suffix to a string (only if it doesn't have it already)
   * @param name
   * @return {*|string}
   */
  appendLinkedVersionsSuffix(name) {
    if (name.endsWith(LINKED_VERSIONS_SUFFIX)) {
      return name;
    } else {
      return name + LINKED_VERSIONS_SUFFIX;
    }
  }

  /**
   * Returns the versions attribute name used to persist in the parent object state the linked entity row information
   * @param linkObject The linked entity for which the state attribute name will be returned
   * @returns {*}
   */
  getParentStateVersionsName(linkObject) {
    if (this.hasLinkToObject()) {
      if (this.hasSingleLinkToObject()) {
        return this.appendLinkedVersionsSuffix(this.props.linkObject[0]);
      } else {
        return this.appendLinkedVersionsSuffix(linkObject);
      }
    } else {
      return this.appendLinkedVersionsSuffix(this.props.linkObject[0]);
    }
  }

  /**
   * Prepares the table data for display when we are viewing the attribute in version diff mode and
   * just returns the underlying data when viewing in edit or view mode
   */
  preProcessDataForTable() {
    if (this.isDiffingVersions()) {
      let diffedRowData = [];
      for (let i = 0; i < this.props.linkObject.length; i++) {
        let linkObject = this.props.linkObject[i];
        let linkToObject = this.hasLinkToObject() ? this.props.linkToObject[i] : null;
        let parentStateVersionsName = this.getParentStateVersionsName(linkObject);
        let oldValue = this.prepareCheckboxFieldsForDisplay(this.getOldValue(parentStateVersionsName));
        let currentValue = this.prepareCheckboxFieldsForDisplay(this.getValueAsObject(linkObject));

        diffedRowData = diffedRowData.concat(createRowDataDiffForLinkedEntities(this.props.name,
          oldValue,
          currentValue,
          this.widgetFields,
          this.getLinkToId(linkToObject),
          this.handleFileDownload));
      }

      for (let i = 0; i < diffedRowData.length; i++) {
        diffedRowData[i].index = i;
      }

      return diffedRowData;
    } else {
      return this.getValueAsObject();
    }
  }

  /**
   * This will set the uuid to all the rows and links where it is not set. The uuid is essential for all types of
   * controls inheriting from this one for manipulating and editing the rows in datatables. Operations like edit, save,
   * and delete depend on the uuid being set on the row and links level. For the links in particular, we currently set
   * uuid of the link to be the same as the one set on the row. This works since each row can have one link at maximum.
   * Later on, if we ever change this behavior and make it so that each row can have more than one links, this code will
   * have to be modified. However, this is not possible yet without a UI and the right event handler in place, since there
   * is no control yet that operates on the link level for adding/editing/deleting links or attachments in the same record.
   * @param rows The rows to set the uuid on
   * @returns {*}
   */
  setRowIds(rows) {
    for (let row of rows) {
      row.uuid = this.generateUUID(row);
      if (typeof row.links === "string") {
        row.links = JSON.stringify(JSON.parse(row.links).map(rowLink => {
          rowLink.uuid = this.generateUUID(row);
          return rowLink;
        }));
      } else if (Array.isArray(row.links)) {
        row.links = row.links.map(rowLink => {
          rowLink.uuid = this.generateUUID(row);
          return rowLink;
        });
      }
    }

    return rows;
  }

  /**
   * Prepares the checkbox fields for display when we are viewing the attribute in version diff mode
   */
  prepareCheckboxFieldsForDisplay(records) {
    let checkboxFields = this.widgetFields.filter(field => field.inputType === "checkbox");
    records = UIUtils.deepClone(records);

    return records ? records.map(record => {
      for (let checkboxField of checkboxFields) {
        record[checkboxField.fieldName] = record[checkboxField.fieldName] ? "Yes" : "No";
      }
      return record;
    }) : records;
  }

  /**
   * Verifies if the given field is missing while it is being required for proposal. This is used in validate method to
   * show an error when at least one or more fields are required before being able to propose an object for approval.
   * @param row The data row which is being validated for proposal
   * @param field The field which is being validated for proposal
   */
  isFieldMissingForProposal(row, field) {
    if (field.inputType === "link") {
      let fieldValue = field.getValue(row);
      let links = fieldValue ? JSON.parse(fieldValue) : {};
      return (!fieldValue || links[0].link === "") && ((links[0].linkType === "Link") || (links[0].linkType === ""));
    } else {
      return super.isFieldMissingForProposal(row, field);
    }
  }

  // eslint-disable-next-line no-unused-vars
  renderLinkedTypeaheadLink(id, selectedOption, field, rowData) {
    return selectedOption
      ? (
        <a href={getURLByTypeCodeAndId(selectedOption.typeCode, "View", selectedOption.id)}
           rel="noopener noreferrer"
           target="_blank"
        ><span id={id}>{selectedOption.label}</span></a>
      )
      : "";
  }

  getTypeaheadInput(rowData, field) {
    let input;
    if (field.inputType === "typeahead") {
      const typeahead = this.getTypeaheadField(rowData, field);
      input = typeahead?.getInput();
      Logger.verbose("Typeahead input: ", Log.object(input));
    }
    return input;
  }

  getTypeaheadField(rowData, field) {
    let typeahead;
    if (field.inputType === "typeahead") {
      let cellRef = this.getCellRef(rowData, field);
      const control = cellRef?.control;
      typeahead = control?.getInstance();
      Logger.verbose("Typeahead field: ", Log.object(typeahead));
    }
    return typeahead;
  }
}