"use strict";

/**
 * @returns an empty capability indices object
 */
module.exports.getEmptyIndicesObject = function() {
  return {
    cp: "N/A",
    cpl: "N/A",
    cpu: "N/A",
    cpk: "N/A",
    cpm: "N/A",
    pp: "N/A",
    ppl: "N/A",
    ppu: "N/A",
    ppk: "N/A",
    stdOverall: "N/A",
    stdWithin: "N/A"
  };
};

/**
 * @returns an empty validation object
 */
module.exports.getEmptyValidationObject = function() {
  return {
    name: "",
    errors: [],
    warnings: []
  };
};

/**
 * @returns an empty acceptance criteria object
 */
module.exports.getEmptyAcceptanceCriteriaObject = function() {
  return {
    lsl: "N/A",
    target: "N/A",
    usl: "N/A"
  };
};

/**
 * This function checks if a certain value is empty
 * @param value to check
 * @returns {boolean} if value is empty or not
 */
module.exports.isEmpty = function(value) {
  return value === "" || value === null
    || typeof value === "undefined" || value === "null";
};
