import { useNotification } from "../../notification/notification_provider";
import { DropdownButton } from "@qbdvision-inc/component-library";
import React from "react";

const onCopy = () => {
  const text = window.location.href;
  try {
    const element = document.createElement("input");
    document.body.appendChild(element);
    element.value = text;
    element.select();
    document.execCommand("copy");
    element.remove();
  } catch (e) {
    console.error("Copy to clipboard failed:", e);
  }
};

const getOptions = (onExport) => {
  let shareOptions = [{
    disabled: false,
    key: "copyLink",
    title: "Copy",
    value: "Copy link",
  }];

  if (onExport) {
    shareOptions.push({
      disabled: false,
      key: "export",
      title: "Export",
      value: "Export to Excel",
    });
  }
  return shareOptions;
};

export const ShareButton = ({onExport}) => {
  const {showNotification} = useNotification();

  const onClick = ({target}) => {
    const {textContent} = target;

    switch (textContent) {
      case "Copy link":
        onCopy();
        showNotification({message: "Link copied to clipboard!", duration: 3}); // 3 seconds
        break;
      case "Export to Excel":
        onExport();
        showNotification({message: "Excel file exported!", duration: 3});
        break;
    }
  };

  return (
    <DropdownButton
      iconPlacement="right"
      label={"Share"}
      type="secondary"
      size="small"
      id="export"
      onOptionsSelect={onClick}
      options={getOptions(onExport)}
    />
  );
};
