import * as React from 'react';
/**
 * @hidden
 */
export var useMouse = function (props, target, extend) {
    if (extend === void 0) { extend = {}; }
    var handleMouseDown = React.useCallback(function (event) {
        if (extend.onMouseDown) {
            extend.onMouseDown.call(undefined, event);
        }
        if (props.onMouseDown) {
            props.onMouseDown.call(undefined, {
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [extend.onMouseDown, props.onMouseDown, target]);
    var handleMouseUp = React.useCallback(function (event) {
        if (extend.onMouseUp) {
            extend.onMouseUp.call(undefined, event);
        }
        if (props.onMouseUp) {
            props.onMouseUp.call(undefined, {
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [extend.onMouseUp, props.onMouseUp, target]);
    var handleClick = React.useCallback(function (event) {
        if (extend.onClick) {
            extend.onClick.call(undefined, event);
        }
        if (props.onClick) {
            props.onClick.call(undefined, {
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [extend.onClick, props.onClick, target]);
    var handleDoubleClick = React.useCallback(function (event) {
        if (extend.onDoubleClick) {
            extend.onDoubleClick.call(undefined, event);
        }
        if (props.onDoubleClick) {
            props.onDoubleClick.call(undefined, {
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [extend.onDoubleClick, props.onDoubleClick, target]);
    var handleMouseEnter = React.useCallback(function (event) {
        if (extend.onMouseEnter) {
            extend.onMouseEnter.call(undefined, event);
        }
        if (props.onMouseEnter) {
            props.onMouseEnter.call(undefined, {
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [extend.onMouseEnter, props.onMouseEnter, target]);
    var handleMouseLeave = React.useCallback(function (event) {
        if (extend.onMouseLeave) {
            extend.onMouseLeave.call(undefined, event);
        }
        if (props.onMouseLeave) {
            props.onMouseLeave.call(undefined, {
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [extend.onMouseLeave, props.onMouseLeave, target]);
    var handelMouseMove = React.useCallback(function (event) {
        if (extend.onMouseMove) {
            extend.onMouseMove.call(undefined, event);
        }
        if (props.onMouseMove) {
            props.onMouseMove.call(undefined, {
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [extend.onMouseMove, props.onMouseMove, target]);
    var handleMouseOut = React.useCallback(function (event) {
        if (extend.onMouseOut) {
            extend.onMouseOut.call(undefined, event);
        }
        if (props.onMouseOut) {
            props.onMouseOut.call(undefined, {
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [extend.onMouseOut, props.onMouseOut, target]);
    var handleMouseOver = React.useCallback(function (event) {
        if (extend.onMouseOver) {
            extend.onMouseOver.call(undefined, event);
        }
        if (props.onMouseOver) {
            props.onMouseOver.call(undefined, {
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [extend.onMouseOver, props.onMouseOver, target]);
    return {
        onClick: handleClick,
        onMouseUp: handleMouseUp,
        onMouseDown: handleMouseDown,
        onDoubleClick: handleDoubleClick,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onMouseMove: handelMouseMove,
        onMouseOut: handleMouseOut,
        onMouseOver: handleMouseOver
    };
};
