var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useInternationalization, useLocalization } from '@progress/kendo-react-intl';
import { classNames, guid, getTabIndex, dispatchEvent, createPropsContext, usePropsContext, kendoThemeMaps } from '@progress/kendo-react-common';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
import { numericIncreaseValue, messages, numericDecreaseValue } from '../messages';
import { formatValue, sanitizeNumber, rangeValue, increaseValue, decreaseValue, getStateOrPropsValue, getInitialState } from './utils';
import { Button } from '@progress/kendo-react-buttons';
var VALIDATION_MESSAGE = 'Please enter a valid value!';
/**
 * Represents the PropsContext of the `NumericTextBox` component.
 * Used for global configuration of all `NumericTextBox` instances.
 *
 * For more information, refer to the [Inputs Props Context]({% slug props-context_inputs %}) article.
 */
export var NumericTextBoxPropsContext = createPropsContext();
/**
 * Represents the [KendoReact NumericTextBox component]({% slug overview_numerictextbox %}).
 */
export var NumericTextBox = React.forwardRef(function (directProps, target) {
    var _a;
    validatePackage(packageMetadata);
    var props = usePropsContext(NumericTextBoxPropsContext, directProps);
    var calculatedId = React.useMemo(function () { return guid(); }, []);
    var inputId = props.id || calculatedId;
    var intlService = useInternationalization();
    var localizationService = useLocalization();
    var elementRef = React.useRef(null);
    var valueDuringChangeRef = React.useRef();
    var _b = React.useState(false), forceUpdate = _b[0], setForceUpdate = _b[1];
    var stateRef = React.useRef(getInitialState());
    var isPasteRef = React.useRef(false);
    var prevLooseValueRef = React.useRef();
    var stateValueRef = React.useRef(props.defaultValue);
    var looseValue = formatValue(stateRef.current.focused && !props.disabled ?
        stateRef.current.currentLooseValue :
        getStateOrPropsValue(props.value, stateValueRef.current), props.format, intlService);
    prevLooseValueRef.current = looseValue;
    React.useEffect(function () {
        if (elementRef.current && elementRef.current.setCustomValidity) {
            elementRef.current.setCustomValidity(validityGetter().valid
                ? ''
                : props.validationMessage || VALIDATION_MESSAGE);
        }
    });
    var focusElement = React.useCallback(function () {
        if (elementRef.current) {
            elementRef.current.focus();
        }
    }, []);
    var valueGetter = React.useCallback(function () {
        if (valueDuringChangeRef.current !== undefined) {
            return valueDuringChangeRef.current;
        }
        else {
            return getStateOrPropsValue(props.value, stateValueRef.current);
        }
    }, [props.value]);
    var nameGetter = React.useCallback(function () {
        return props.name;
    }, [props.name]);
    var requiredGetter = React.useCallback(function () {
        return props.required;
    }, [props.required]);
    var validityGetter = React.useCallback(function () {
        var customError = props.validationMessage !== undefined;
        var currentValue = valueGetter();
        var valid = props.valid !== undefined ?
            props.valid :
            !stateRef.current.valueIsOutOfRange &&
                (!requiredGetter() || (currentValue !== null && currentValue !== undefined));
        return {
            customError: customError,
            valid: valid,
            valueMissing: currentValue === null || currentValue === undefined
        };
    }, [props.validationMessage, props.valid, valueGetter, requiredGetter]);
    var validityStylesGetter = React.useCallback(function () {
        return props.validityStyles;
    }, [props.validityStyles]);
    var propsGetter = React.useCallback(function () {
        return props;
    }, [props]);
    var getImperativeHandle = React.useCallback(function () {
        var options = {
            element: elementRef.current,
            focus: focusElement
        };
        Object.defineProperty(options, 'name', { get: nameGetter });
        Object.defineProperty(options, 'value', { get: valueGetter });
        Object.defineProperty(options, 'validity', { get: validityGetter });
        Object.defineProperty(options, 'validityStyles', { get: validityStylesGetter });
        Object.defineProperty(options, 'required', { get: requiredGetter });
        Object.defineProperty(options, 'props', { get: propsGetter });
        return options;
    }, [nameGetter, valueGetter, validityGetter, validityStylesGetter, requiredGetter, focusElement, propsGetter]);
    React.useImperativeHandle(target, getImperativeHandle);
    var getCurrentState = React.useCallback(function () {
        return {
            eventValue: getStateOrPropsValue(props.value, stateValueRef.current),
            prevLooseValue: prevLooseValueRef.current,
            currentLooseValue: elementRef.current.value,
            selectionStart: elementRef.current.selectionStart,
            selectionEnd: elementRef.current.selectionEnd,
            decimalSelect: false,
            valueIsCorrected: false,
            valueIsOutOfRange: false,
            isPaste: isPasteRef.current,
            focused: stateRef.current.focused
        };
    }, [props.value]);
    var triggerChange = React.useCallback(function (event, newState) {
        if (props.disabled) {
            return;
        }
        valueDuringChangeRef.current = newState.eventValue;
        stateValueRef.current = newState.eventValue;
        var formattedValue = formatValue(rangeValue(newState.eventValue, props.min, props.max), props.format, intlService);
        var rangedValue = rangeValue(intlService.parseNumber(formattedValue, props.format), props.min, props.max);
        if (rangedValue !== newState.eventValue) {
            newState.valueIsOutOfRange = true;
            newState.eventValue = rangedValue;
            stateValueRef.current = rangedValue;
        }
        var shouldFireEvent = props.value !== newState.eventValue;
        if (shouldFireEvent) {
            dispatchEvent(props.onChange, event, getImperativeHandle(), { value: newState.eventValue });
        }
        valueDuringChangeRef.current = undefined;
        stateRef.current = newState;
        setForceUpdate(function (_x) { return !_x; });
    }, [props.value, props.onChange, props.disabled, setForceUpdate, getImperativeHandle]);
    var onChangeHandler = React.useCallback(function (event) {
        var stateCandidate = getCurrentState();
        isPasteRef.current = false;
        triggerChange(event, sanitizeNumber(stateCandidate, props.format, intlService));
    }, [props.format, props.onChange, intlService, triggerChange, getCurrentState]);
    var onKeyDown = React.useCallback(function (event) {
        var newState = getCurrentState();
        var currentValue = intlService.parseNumber(String(newState.currentLooseValue), props.format);
        // Select All
        if (newState.selectionEnd > newState.selectionStart &&
            newState.selectionEnd - newState.selectionStart === String(newState.currentLooseValue).length) {
            isPasteRef.current = true;
            return;
        }
        switch (event.keyCode) {
            case 38:
                // Arrow up
                increaseValue(currentValue, newState, props.step, props.min, props.max, props.format, intlService);
                break;
            case 40:
                // Arrow down
                decreaseValue(currentValue, newState, props.step, props.min, props.max, props.format, intlService);
                break;
            case 13:
                // Enter - range values
                if (props.rangeOnEnter === false) {
                    return;
                }
                var formattedValue = formatValue(rangeValue(currentValue, props.min, props.max), props.format, intlService);
                var rangedValue = rangeValue(intlService.parseNumber(formattedValue, props.format), props.min, props.max);
                newState.eventValue = rangedValue;
                newState.currentLooseValue = formatValue(rangedValue, props.format, intlService);
                newState.selectionStart = newState.selectionEnd = newState.currentLooseValue.length;
                break;
            case 110:
                // Numpad decimal key
                var element = elementRef.current;
                var symbols = intlService.numberSymbols();
                if (element) {
                    newState.currentLooseValue = newState.currentLooseValue.slice(0, newState.selectionStart) +
                        symbols.decimal +
                        newState.currentLooseValue.slice(newState.selectionEnd);
                    newState.selectionStart = newState.selectionEnd = newState.selectionStart + 1;
                    newState = sanitizeNumber(newState, props.format, intlService);
                }
                break;
            default:
                return;
        }
        event.preventDefault();
        triggerChange(event, newState);
    }, [props.format, props.min, props.max, props.step, props.onChange, props.rangeOnEnter, triggerChange, getCurrentState]);
    var onPasteHandler = React.useCallback(function () {
        isPasteRef.current = true;
    }, []);
    var onIncrease = React.useCallback(function (event) {
        if (props.readOnly ||
            props.disabled) {
            return;
        }
        var newState = getCurrentState();
        increaseValue(intlService.parseNumber(String(newState.currentLooseValue), props.format), newState, props.step, props.min, props.max, props.format, intlService);
        triggerChange(event, newState);
    }, [props.format, props.min, props.max, props.step, props.onChange, props.readOnly, props.disabled, triggerChange, getCurrentState]);
    var onDecrease = React.useCallback(function (event) {
        if (props.readOnly ||
            props.disabled) {
            return;
        }
        var newState = getCurrentState();
        decreaseValue(intlService.parseNumber(String(newState.currentLooseValue), props.format), newState, props.step, props.min, props.max, props.format, intlService);
        triggerChange(event, newState);
    }, [props.format, props.min, props.max, props.step, props.onChange, props.readOnly, props.disabled, triggerChange, getCurrentState]);
    var onWheelHandler = React.useCallback(function (event) {
        if (!document ||
            document.activeElement !== elementRef.current ||
            !elementRef.current ||
            props.readOnly ||
            props.disabled) {
            return;
        }
        if (event.nativeEvent.deltaY < 0) {
            onIncrease(event);
        }
        if (event.nativeEvent.deltaY > 0) {
            onDecrease(event);
        }
    }, [onIncrease, onDecrease, props.disabled, props.readOnly]);
    var onFocus = React.useCallback(function (event) {
        stateRef.current.currentLooseValue = prevLooseValueRef.current;
        stateRef.current.focused = true;
        dispatchEvent(props.onFocus, event, getImperativeHandle(), {});
        setForceUpdate(function (x) { return !x; });
    }, [props.onFocus, setForceUpdate, getImperativeHandle]);
    var onBlur = React.useCallback(function (event) {
        stateRef.current = getInitialState();
        dispatchEvent(props.onBlur, event, getImperativeHandle(), {});
        setForceUpdate(function (x) { return !x; });
    }, [props.onBlur, setForceUpdate, getImperativeHandle]);
    var onMouseDown = React.useCallback(function (e) {
        if (document && elementRef.current) {
            e.preventDefault();
            if (document.activeElement !== elementRef.current) {
                elementRef.current.focus();
            }
        }
    }, []);
    React.useLayoutEffect(function () {
        if (elementRef.current && stateRef.current.selectionStart !== undefined && stateRef.current.selectionEnd !== undefined) {
            elementRef.current.selectionStart = stateRef.current.selectionStart;
            elementRef.current.selectionEnd = stateRef.current.selectionEnd;
            stateRef.current.selectionStart = undefined;
            stateRef.current.selectionEnd = undefined;
        }
    }, [forceUpdate]);
    var isValid = !validityStylesGetter() || validityGetter().valid;
    var numerictextbox = (React.createElement("span", { dir: props.dir, style: !props.label
            ? __assign({ width: props.width }, props.style) : props.style, className: classNames('k-input', 'k-numerictextbox', (_a = {},
            _a["k-input-".concat(kendoThemeMaps.sizeMap[props.size] || props.size)] = props.size,
            _a["k-input-".concat(props.fillMode)] = props.fillMode,
            _a["k-rounded-".concat(kendoThemeMaps.roundedMap[props.rounded] || props.rounded)] = props.rounded,
            _a['k-invalid'] = !isValid,
            _a['k-required'] = props.required,
            _a['k-disabled'] = props.disabled,
            _a), props.className), "aria-disabled": props.disabled ? 'true' : undefined },
        React.createElement("input", { value: looseValue === null ? '' : looseValue, tabIndex: getTabIndex(props.tabIndex, props.disabled), accessKey: props.accessKey, disabled: props.disabled, title: props.title, "aria-valuemin": props.min, "aria-valuemax": props.max, "aria-label": props.ariaLabel, "aria-labelledby": props.ariaLabelledBy, "aria-describedby": props.ariaDescribedBy, placeholder: props.placeholder, spellCheck: false, autoComplete: 'off', autoCorrect: 'off', type: props.inputType || 'tel', className: 'k-input-inner', id: inputId, name: props.name, readOnly: props.readOnly, style: props.inputStyle, onChange: onChangeHandler, onFocus: onFocus, onBlur: onBlur, onKeyDown: onKeyDown, onPaste: onPasteHandler, onWheel: onWheelHandler, ref: elementRef }),
        props.children,
        props.spinners &&
            (React.createElement("span", { className: "k-input-spinner k-spin-button", onMouseDown: onMouseDown },
                React.createElement(Button, { tabIndex: -1, type: "button", icon: 'caret-alt-up', rounded: null, className: "k-spinner-increase", "aria-label": localizationService.toLanguageString(numericIncreaseValue, messages[numericIncreaseValue]), title: localizationService.toLanguageString(numericIncreaseValue, messages[numericIncreaseValue]), onClick: onIncrease }),
                React.createElement(Button, { tabIndex: -1, type: "button", icon: 'caret-alt-down', rounded: null, className: "k-spinner-decrease", "aria-label": localizationService.toLanguageString(numericDecreaseValue, messages[numericDecreaseValue]), title: localizationService.toLanguageString(numericDecreaseValue, messages[numericDecreaseValue]), onClick: onDecrease })))));
    return props.label
        ? (React.createElement(FloatingLabel, { label: props.label, editorId: inputId, editorValue: looseValue === null ? '' : looseValue, editorValid: isValid, editorDisabled: props.disabled, editorPlaceholder: props.placeholder, children: numerictextbox, style: { width: props.width }, dir: props.dir }))
        : numerictextbox;
});
NumericTextBox.propTypes = {
    value: PropTypes.number,
    defaultValue: PropTypes.number,
    step: PropTypes.number,
    format: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            style: PropTypes.oneOf(['decimal', 'currency', 'percent', 'scientific', 'accounting']),
            currency: PropTypes.string,
            currencyDisplay: PropTypes.oneOf(['symbol', 'code', 'name']),
            useGrouping: PropTypes.bool,
            minimumIntegerDigits: PropTypes.number,
            minimumFractionDigits: PropTypes.number,
            maximumFractionDigits: PropTypes.number
        })
    ]),
    width: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    tabIndex: PropTypes.number,
    accessKey: PropTypes.string,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    spinners: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    dir: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    validationMessage: PropTypes.string,
    required: PropTypes.bool,
    id: PropTypes.string,
    rangeOnEnter: PropTypes.bool,
    ariaLabelledBy: PropTypes.string,
    ariaDescribedBy: PropTypes.string,
    ariaLabel: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    size: PropTypes.oneOf([null, 'small', 'medium', 'large']),
    rounded: PropTypes.oneOf([null, 'small', 'medium', 'large', 'full']),
    fillMode: PropTypes.oneOf([null, 'solid', 'flat', 'outline'])
};
NumericTextBox.displayName = 'KendoNumericTextBox';
NumericTextBox.defaultProps = {
    step: 1,
    spinners: true,
    disabled: false,
    required: false,
    validityStyles: true,
    rangeOnEnter: true,
    onChange: function (_) { return; },
    onFocus: function (_) { return; },
    onBlur: function (_) { return; },
    size: 'medium',
    rounded: 'medium',
    fillMode: 'solid'
};
