var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { isCompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GridColumnMenuItem } from './GridColumnMenuItem';
import { GridColumnMenuItemGroup } from './GridColumnMenuItemGroup';
import { GridColumnMenuItemContent } from './GridColumnMenuItemContent';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { operatorMap, getDefaultOperator, filterLogicList, defaultHideSecondFilter, booleanFilterValues, getFilterType, IsUnaryFilter } from '../filterCommon';
import { messages, filterClearButton, filterSubmitButton, filterTitle } from '../messages';
import { GridColumnMenuFilterUI } from './GridColumnMenuFilterUI';
import { Button } from '@progress/kendo-react-buttons';
/**
 * @hidden
 */
export var rootFilterOrDefault = function (rootFilter) {
    return (rootFilter || { filters: [], logic: 'and' });
};
/**
 * @hidden
 */
export var filterGroupByField = function (field, filter) {
    var rootFilter = rootFilterOrDefault(filter);
    var compositeFilters = rootFilter.filters
        .filter(function (f) {
        if (isCompositeFilterDescriptor(f)) {
            return f.filters &&
                f.filters.length &&
                f.filters.length <= 2 &&
                !f.filters.find(function (nf) { return isCompositeFilterDescriptor(nf) || nf.field !== field; });
        }
        return false;
    });
    return compositeFilters[0] || null;
};
/**
 * @example
 * ```jsx-no-run
 * class TextColumnMenu extends React.Component {
 *     render() {
 *         return (
 *         <div>
 *             <GridColumnMenuSort {...this.props} />
 *             <GridColumnMenuFilter {...this.props} />
 *         </div>);
 *     }
 * }
 *
 * class App extends React.PureComponent {
 *     constructor(props) {
 *         super(props);
 *
 *         this.state = this.createAppState({
 *             take: 10,
 *             skip: 0
 *         });
 *     }
 *
 *     createAppState(dataState) {
 *         return {
 *             result: process(products.slice(0), dataState),
 *             dataState: dataState
 *         };
 *     }
 *
 *     dataStateChange = (event) => {
 *         this.setState(this.createAppState(event.dataState));
 *     }
 *
 *     render() {
 *         return (
 *             <div>
 *                 <Grid
 *                     data={this.state.result}
 *                     {...this.state.dataState}
 *                     onDataStateChange={this.dataStateChange}
 *                     sortable={true}
 *                     pageable={true}
 *                 >
 *                     <Column field="ProductID" title="Product ID" />
 *                     <Column field="ProductName" columnMenu={TextColumnMenu} />
 *                 </Grid>
 *                 <br />
 *             </div>
 *         );
 *     }
 * }
 *
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var GridColumnMenuFilter = /** @class */ (function (_super) {
    __extends(GridColumnMenuFilter, _super);
    function GridColumnMenuFilter(props) {
        var _this = _super.call(this, props) || this;
        /**
         * @hidden
         */
        _this.removeGroup = function (group, rootFilter) {
            var filters = __spreadArray([], rootFilter.filters, true);
            var groupIndex = filters
                .findIndex(function (f) { return f === group; });
            if (groupIndex > -1) {
                filters.splice(groupIndex, 1);
            }
            return __assign(__assign({}, rootFilter), { filters: filters });
        };
        /**
         * @hidden
         */
        _this.insertGroup = function (group, rootFilter) {
            return __assign(__assign({}, rootFilter), { filters: __spreadArray([
                    group
                ], rootFilter.filters, true) });
        };
        /**
         * @hidden
         */
        _this.isControlled = function () { return _this.props.expanded !== undefined; };
        /**
         * @hidden
         */
        _this.onFilterExpand = function () {
            var isControlled = _this.isControlled();
            var nextValue = !(isControlled ? _this.props.expanded : _this.state.expanded);
            if (_this.props.onExpandChange) {
                _this.props.onExpandChange(nextValue);
            }
            if (!isControlled) {
                _this.setState({
                    expanded: nextValue
                });
            }
        };
        /**
         * @hidden
         */
        _this.firstFilterChange = function (event) {
            _this.filterChange(0, event);
        };
        /**
         * @hidden
         */
        _this.secondFilterChange = function (event) {
            _this.filterChange(1, event);
        };
        /**
         * @hidden
         */
        _this.filterChange = function (filterIndex, e) {
            var filters = _this.state.filterGroup.filters.map(function (f, i) {
                if (i === filterIndex) {
                    return __assign(__assign({}, f), { value: e.value, operator: e.operator });
                }
                return f;
            });
            _this.setState({
                filterGroup: __assign(__assign({}, _this.state.filterGroup), { filters: filters })
            });
        };
        /**
         * @hidden
         */
        _this.logicChange = function (event) {
            _this.setState({
                filterGroup: __assign(__assign({}, _this.state.filterGroup), { logic: event.target.value.operator })
            });
        };
        /**
         * @hidden
         */
        _this.clear = function (event) {
            event.preventDefault();
            if (!_this.props.onFilterChange) {
                return;
            }
            var field = _this.props.column.field;
            var rootFilter = rootFilterOrDefault(_this.props.filter);
            var filterGroup = filterGroupByField(field, _this.props.filter);
            var rootFilters = rootFilter.filters.filter(function (f) { return f !== filterGroup; });
            if (!rootFilters.length) {
                _this.props.onFilterChange(null, event);
            }
            else {
                _this.props.onFilterChange(__assign(__assign({}, rootFilter), { filters: rootFilters }), event);
            }
            if (_this.props.onCloseMenu) {
                _this.props.onCloseMenu();
            }
        };
        /**
         * @hidden
         */
        _this.currentFilterGroup = function () {
            return __assign(__assign({}, _this.state.filterGroup), { filters: _this.state
                    .filterGroup
                    .filters
                    .filter(function (nf) {
                    return (nf.value !== undefined && nf.value !== null && nf.value !== '') ||
                        (nf.value === null && nf.operator);
                }) });
        };
        /**
         * @hidden
         */
        _this.submit = function (event) {
            event.preventDefault();
            if (!_this.props.onFilterChange) {
                return;
            }
            var field = _this.props.column.field;
            var rootFilter = rootFilterOrDefault(_this.props.filter);
            var filterGroup = filterGroupByField(field, _this.props.filter);
            var currentFilterGroup = _this.currentFilterGroup();
            var updatedFilter = null;
            if (filterGroup && currentFilterGroup.filters.length > 0) {
                var rootFilters = rootFilter.filters.map(function (f) {
                    if (f === filterGroup) {
                        return currentFilterGroup;
                    }
                    return f;
                });
                updatedFilter = __assign(__assign({}, rootFilter), { filters: rootFilters });
            }
            else if (currentFilterGroup.filters.length === 0) {
                var rootFilters = rootFilter
                    .filters
                    .filter(function (f) { return f !== filterGroup; });
                if (rootFilters.length) {
                    updatedFilter = __assign(__assign({}, rootFilter), { filters: rootFilters });
                }
            }
            else {
                updatedFilter = __assign(__assign({}, rootFilter), { filters: __spreadArray(__spreadArray([], rootFilter.filters, true), [currentFilterGroup], false) });
            }
            _this.props.onFilterChange(updatedFilter, event);
            if (_this.props.onCloseMenu) {
                _this.props.onCloseMenu();
            }
        };
        var filterGroup;
        if (props.column && props.column.field) {
            var filterType = getFilterType(props.column.filter);
            var defaultOperator = getDefaultOperator(props.filterOperators, filterType);
            filterGroup = filterGroupByField(props.column.field, props.filter);
            filterGroup = filterGroup ? __assign(__assign({}, filterGroup), { filters: filterGroup.filters.map(function (f) {
                    return __assign({}, f);
                }) }) : {
                logic: 'and',
                filters: [
                    { field: props.column.field, operator: defaultOperator, value: IsUnaryFilter(defaultOperator) ? null : undefined },
                    { field: props.column.field, operator: defaultOperator, value: IsUnaryFilter(defaultOperator) ? null : undefined }
                ]
            };
            if (filterGroup.filters.filter(function (x) { return x.field === props.column.field; }).length === 1) {
                filterGroup.filters.splice(1, 0, { field: props.column.field, operator: defaultOperator });
            }
        }
        _this.state = {
            expanded: props.expanded || false,
            filterGroup: filterGroup
        };
        return _this;
    }
    /**
     * @hidden
     */
    GridColumnMenuFilter.prototype.render = function () {
        var _a = this.props, column = _a.column, filterUI = _a.filterUI, hideSecondFilter = _a.hideSecondFilter, filterOperators = _a.filterOperators;
        if (!column || !column.field) {
            return React.createElement("div", null);
        }
        var filterType = getFilterType(column.filter);
        var currentHideSecondFilter = hideSecondFilter !== undefined ?
            hideSecondFilter : defaultHideSecondFilter[filterType];
        var localizationService = provideLocalizationService(this);
        var filters = this.state.filterGroup.filters;
        var currentOperators = operatorMap(filterOperators[filterType], localizationService);
        var booleanValues = operatorMap(booleanFilterValues, localizationService);
        var firstFilterCellProps = {
            field: column.field,
            value: filters[0].value,
            operator: filters[0].operator,
            operators: currentOperators,
            booleanValues: booleanValues,
            onChange: this.firstFilterChange,
            filterType: filterType
        };
        var secondFilterCellProps = {
            field: column.field,
            value: filters[1].value,
            operator: filters[1].operator,
            operators: currentOperators,
            booleanValues: booleanValues,
            onChange: this.secondFilterChange,
            filterType: filterType
        };
        var filterLogic = this.state.filterGroup.logic;
        var logicData = operatorMap(filterLogicList, localizationService);
        var logicProps = {
            value: logicData.find(function (item) { return item.operator === (filterLogic === null ? '' : filterLogic); }),
            onChange: this.logicChange,
            data: logicData
        };
        var FilterUI = filterUI;
        var filterUIProps = {
            firstFilterProps: firstFilterCellProps,
            secondFilterProps: secondFilterCellProps,
            logicData: logicProps.data,
            logicValue: logicProps.value,
            onLogicChange: logicProps.onChange,
            hideSecondFilter: currentHideSecondFilter
        };
        var isFilterValid = this.currentFilterGroup().filters.length !== 0 ||
            IsUnaryFilter(filters[0].operator) ||
            IsUnaryFilter(filters[1].operator);
        var expandState = this.isControlled() ? this.props.expanded : this.state.expanded;
        return (React.createElement(GridColumnMenuItemGroup, null,
            React.createElement(GridColumnMenuItem, { title: localizationService.toLanguageString(filterTitle, messages[filterTitle]), iconClass: 'k-i-filter', onClick: this.onFilterExpand }),
            React.createElement(GridColumnMenuItemContent, { show: !!expandState },
                React.createElement("div", { className: 'kendo-grid-filter-menu-container' },
                    React.createElement("form", { className: 'k-filter-menu k-group k-reset k-state-border-up', onSubmit: this.submit, onReset: this.clear, onKeyDown: function (e) { return e.stopPropagation(); } },
                        React.createElement("div", { className: 'k-filter-menu-container' },
                            FilterUI ?
                                React.createElement(FilterUI, __assign({}, filterUIProps)) :
                                React.createElement(GridColumnMenuFilterUI, __assign({}, filterUIProps)),
                            React.createElement("div", { className: 'k-actions k-actions-stretched' },
                                React.createElement(Button, { type: 'reset' }, localizationService.toLanguageString(filterClearButton, messages[filterClearButton])),
                                React.createElement(Button, { themeColor: "primary", disabled: !isFilterValid }, localizationService.toLanguageString(filterSubmitButton, messages[filterSubmitButton])))))))));
    };
    /* eslint-disable max-len */
    /**
     * Can be used to check if filtering is applied to a specific field ([see example]({% slug column_menu_grid %}#toc-styling-the-column-menu-icon)). Useful for creating active filter indicators.
     */
    // tslint:enable:max-line-length
    GridColumnMenuFilter.active = function (field, filter) {
        return !!filterGroupByField(field, filter);
    };
    return GridColumnMenuFilter;
}(React.Component));
export { GridColumnMenuFilter };
registerForLocalization(GridColumnMenuFilter);
