var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { columnMenuFilterEqOperator, columnMenuFilterNotEqOperator, columnMenuFilterIsNullOperator, columnMenuFilterIsNotNullOperator, columnMenuFilterIsEmptyOperator, columnMenuFilterIsNotEmptyOperator, columnMenuFilterStartsWithOperator, columnMenuFilterContainsOperator, columnMenuFilterNotContainsOperator, columnMenuFilterEndsWithOperator, columnMenuFilterGteOperator, columnMenuFilterGtOperator, columnMenuFilterLteOperator, columnMenuFilterLtOperator, columnMenuFilterAfterOrEqualOperator, columnMenuFilterAfterOperator, columnMenuFilterBeforeOperator, columnMenuFilterBeforeOrEqualOperator, columnMenuFilterIsTrue } from '../messages';
var textOperators = [
    { text: columnMenuFilterContainsOperator, operator: 'contains' },
    { text: columnMenuFilterNotContainsOperator, operator: 'doesnotcontain' },
    { text: columnMenuFilterEqOperator, operator: 'eq' },
    { text: columnMenuFilterNotEqOperator, operator: 'neq' },
    { text: columnMenuFilterStartsWithOperator, operator: 'startswith' },
    { text: columnMenuFilterEndsWithOperator, operator: 'endswith' },
    { text: columnMenuFilterIsNullOperator, operator: 'isnull' },
    { text: columnMenuFilterIsNotNullOperator, operator: 'isnotnull' },
    { text: columnMenuFilterIsEmptyOperator, operator: 'isempty' },
    { text: columnMenuFilterIsNotEmptyOperator, operator: 'isnotempty' }
];
var numericOperators = [
    { text: columnMenuFilterEqOperator, operator: 'eq' },
    { text: columnMenuFilterNotEqOperator, operator: 'neq' },
    { text: columnMenuFilterGteOperator, operator: 'gte' },
    { text: columnMenuFilterGtOperator, operator: 'gt' },
    { text: columnMenuFilterLteOperator, operator: 'lte' },
    { text: columnMenuFilterLtOperator, operator: 'lt' },
    { text: columnMenuFilterIsNullOperator, operator: 'isnull' },
    { text: columnMenuFilterIsNotNullOperator, operator: 'isnotnull' }
];
var dateOperators = [
    { text: columnMenuFilterEqOperator, operator: 'eq' },
    { text: columnMenuFilterNotEqOperator, operator: 'neq' },
    { text: columnMenuFilterAfterOrEqualOperator, operator: 'gte' },
    { text: columnMenuFilterAfterOperator, operator: 'gt' },
    { text: columnMenuFilterBeforeOperator, operator: 'lt' },
    { text: columnMenuFilterBeforeOrEqualOperator, operator: 'lte' },
    { text: columnMenuFilterIsNullOperator, operator: 'isnull' },
    { text: columnMenuFilterIsNotNullOperator, operator: 'isnotnull' }
];
var booleanOperators = [
    { text: columnMenuFilterIsTrue, operator: '' }
];
/**
 * Represents the operators for the ColumnMenuTextFilter, ColumnMenuNumericFilter,
 * ColumnMenuDateFilter and ColumnMenuBooleanFilter components.
 *
 * The text field of each operator object will be resolved according to the
 * [localization messages]({% slug globalization_datatools %}#toc-messages).
 */
var ColumnMenuOperators = /** @class */ (function () {
    function ColumnMenuOperators() {
    }
    Object.defineProperty(ColumnMenuOperators, "text", {
        /**
         * An array containing the operators for the ColumnMenuTextFilter component.
         *
         * The operators are:
         *
         * - { text: 'columnMenu.filterContainsOperator', operator: 'contains' }
         * - { text: 'columnMenu.filterNotContainsOperator', operator: 'doesnotcontain' }
         * - { text: 'columnMenu.filterEqOperator', operator: 'eq' }
         * - { text: 'columnMenu.filterNotEqOperator', operator: 'neq' }
         * - { text: 'columnMenu.filterStartsWithOperator', operator: 'startswith' }
         * - { text: 'columnMenu.filterEndsWithOperator', operator: 'endswith' }
         * - { text: 'columnMenu.filterIsNullOperator', operator: 'isnull' }
         * - { text: 'columnMenu.filterIsNotNullOperator', operator: 'isnotnull' }
         * - { text: 'columnMenu.filterIsEmptyOperator', operator: 'isempty' }
         * - { text: 'columnMenu.filterIsNotEmptyOperator', operator: 'isnotempty' }
         */
        get: function () {
            return __spreadArray([], textOperators, true);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ColumnMenuOperators, "numeric", {
        /**
         * An array containing the operators for the ColumnMenuNumericFilter component.
         *
         * The operators are:
         *
         * - { text: 'columnMenu.filterEqOperator', operator: 'eq' }
         * - { text: 'columnMenu.filterNotEqOperator', operator: 'neq' }
         * - { text: 'columnMenu.filterGteOperator', operator: 'gte' }
         * - { text: 'columnMenu.filterGtOperator', operator: 'gt' }
         * - { text: 'columnMenu.filterLteOperator', operator: 'lte' }
         * - { text: 'columnMenu.filterLtOperator', operator: 'lt' }
         * - { text: 'columnMenu.filterIsNullOperator', operator: 'isnull' }
         * - { text: 'columnMenu.filterIsNotNullOperator', operator: 'isnotnull' }
         */
        get: function () {
            return __spreadArray([], numericOperators, true);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ColumnMenuOperators, "date", {
        /**
         * An array containing the operators for the ColumnMenuDateFilter component.
         *
         * The operators are:
         *
         * - { text: 'columnMenu.filterEqOperator', operator: 'eq' }
         * - { text: 'columnMenu.filterNotEqOperator', operator: 'neq' }
         * - { text: 'columnMenu.filterAfterOrEqualOperator', operator: 'gte' }
         * - { text: 'columnMenu.filterAfterOperator', operator: 'gt' }
         * - { text: 'columnMenu.filterBeforeOperator', operator: 'lt' }
         * - { text: 'columnMenu.filterBeforeOrEqualOperator', operator: 'lte' }
         * - { text: 'columnMenu.filterIsNullOperator', operator: 'isnull' }
         * - { text: 'columnMenu.filterIsNotNullOperator', operator: 'isnotnull' }
         */
        get: function () {
            return __spreadArray([], dateOperators, true);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ColumnMenuOperators, "boolean", {
        /**
         * An array containing the operators for the ColumnMenuBooleanFilter component.
         *
         * The operators are:
         *
         * - { text: 'columnMenu.filterIsTrue', operator: '' }
         */
        get: function () {
            return __spreadArray([], booleanOperators, true);
        },
        enumerable: false,
        configurable: true
    });
    return ColumnMenuOperators;
}());
export { ColumnMenuOperators };
