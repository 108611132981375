"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import { createColumn, generateProps } from "../../helpers/import_helper";
import BaseReactComponent from "../../../base_react_component";

const excludeColumns = {
  TPP: ["Name"],
  FQA: ["Measurements"],
  UnitOperation: ["Measurements"],
  Material: ["Measurements"],
  MaterialAttribute: ["Measurements"],
  Specification: ["Attribute ID"],
  Attribute: ["Attribute Name", "Attribute ID", "Measurements"],
};

let renamedColumns = new Map();
renamedColumns.set("Defective Percentage", "Defective %");
renamedColumns.set("Measure Units", "Measure (Units)");

/**
 * @typedef {Object} ImportDataManipulationTableProps
 * @property enableSingleSelection {boolean}
 * @property id {string}
 * @property records {*[]}
 * @property modelName {string}
 * @property handleObjectsSelection {Function}
 */

// This control is used for import data review and dependency selection for batches import feature
export default class ImportDataManipulationTable extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.selectAllCheckboxId = `${this.props.id}SelectAll`;
    this.state = {
      shouldRedrawColumns: true,
    };

    if (props.modelName === "Specification") {
      renamedColumns.set("Attribute Name", "Specification");
      renamedColumns.set("Batch/Lot ID", "Lot ID");
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.shouldRedrawColumns
      || JSON.stringify(nextProps.records) !== JSON.stringify(this.props.records);
  }

  onTableSelection(e, dt, type, indexes) {
    for (let i = 0; i < indexes.length; i++) {
      let controlId = (this.props.enableSingleSelection ? `#${this.props.id}_radio_` : `#${this.props.id}_checkbox_`) + indexes[i];
      $(controlId).prop("checked", true);
    }

    if (!this.props.enableSingleSelection) {
      let selectedRows = this.table.rows(".selected").data().length;
      let allRows = this.table.rows().data().length;
      if (selectedRows === allRows) {
        $(`#${this.selectAllCheckboxId}`).prop("checked", true);
      }
    }

    this.updateParent();
  }

  onTableDeselection(e, dt, type, indexes) {
    for (let i = 0; i < indexes.length; i++) {
      let controlId = (this.props.enableSingleSelection ? `#${this.props.id}_radio_` : `#${this.props.id}_checkbox_`) + indexes[i];
      $(controlId).prop("checked", false);
    }

    if (!this.props.enableSingleSelection) {
      $(`#${this.selectAllCheckboxId}`).prop("checked", false);
    }

    UIUtils.clearError();
    this.updateParent();
  }

  updateParent() {
    let data = this.table.rows(".selected").data().toArray();
    this.props.onObjectsSelection(data);
  }

  onSelectAll(e) {
    if ($(e.target).is(":checked")) {
      this.table.rows().select();
      UIUtils.clearError();
    } else {
      this.table.rows().deselect();
    }
  }

  generateColumns() {
    let columns = [];
    columns.push({
      isCheckboxSelectionColumn: true,
      width: 5,
      data: null
    });

    if (this.props.records && this.props.records.length > 0) {
      for (let prop of generateProps(this.props.records)) {
        let excludedColumn = excludeColumns[this.props.modelName] ? excludeColumns[this.props.modelName] : [];
        if (!excludedColumn.includes(UIUtils.convertCamelCaseToSpacedOutWords(prop.name))) {
          const column = createColumn(prop.name, renamedColumns, prop.isMeasurementProp);
          columns.push(column);
        }
      }
    }

    return columns;
  }

  getFirstColumnHeaderTitle() {
    return this.props.enableSingleSelection ? "" :
      ReactDOMServer.renderToStaticMarkup(
        <input id={this.selectAllCheckboxId} type="checkbox" defaultChecked="checked" />
      );
  }

  getFirstColumnHeaderData(row) {
    return this.props.enableSingleSelection ? <input id={this.props.id + "_radio_" + row} type='radio' /> :
      <input id={this.props.id + "_checkbox_" + row} type='checkbox' defaultChecked />;
  }

  componentDidMount() {
    super.componentDidMount();
    this.initializeDataTable();
  }

  componentDidUpdate() {
    $(this.tableRef)
      .DataTable()
      .destroy();

    $(this.tableRef)
      .empty();

    this.initializeDataTable();
  }

  initializeDataTable() {
    const columns = this.generateColumns();
    const columnDefs = this.getColumnDefs(columns);
    const {enableSingleSelection} = this.props;

    this.table = $(this.tableRef).DataTable({
      dom: "<ft<\"datatables-nav\"i>>",
      scrollX: true,
      data: this.getRecords(),
      ordering: false,
      scrollY: $(window).height() > 700 ? "calc(100vh - 600px)" : "450px",
      scrollCollapse: true,
      columns: columns,
      paging: false,
      select: {
        items: "row",
        info: false,
        style: enableSingleSelection ? "single" : "multiple"
      },
      columnDefs: columnDefs,
      stateSave: true
    });

    if (!enableSingleSelection) {
      $(`#${this.selectAllCheckboxId}`).change(this.onSelectAll);
      this.table.rows().select();
    } else {
      this.table.rows().deselect();
    }

    this.table.on("select", this.onTableSelection);
    this.table.on("deselect", this.onTableDeselection);

    if (this.props.searchFor && this.isInitialFiltrationEnabled()) {
      this.table.search(this.props.searchFor).draw();
    } else {
      this.table.search("").draw();
    }

    this.updateParent();

    this.setStateSafely({
      shouldRedrawColumns: false,
    }, () => {
      setTimeout(() => {
        this.reloadDataTable();
      }, 200);
    });
  }

  isInitialFiltrationEnabled() {
    return false;
  }

  /**
   * Builds the column definitions according to the settings defined in the props, ensuring that all values will be rendered using react
   * @param columns {*[]} The array of columns
   * @returns {{targets: *, createdCell: (function(*=, *=): *)}[]}
   */
  getColumnDefs(columns) {
    const targets = columns.map((ignore, index) => index);

    const columnDefs = [{
      targets: targets.slice(1),
      createdCell: this.onCellCreated
    }];

    columnDefs.push({
      targets: 0,
      width: 5,
      title: this.getFirstColumnHeaderTitle(),
      createdCell: this.onFirstColumnCellCreated
    });

    return columnDefs;
  }

  // noinspection JSMethodCanBeStatic
  /**
   * Handles how to render the cell
   * @param td {HTMLTableCellElement} The cell to be rendered to
   * @param cellData {*} The data to add to the cell
   * @returns {*}
   */
  onCellCreated(td, cellData) {
    ReactDOM.render(cellData, td);
  }

  /***
   * Handles how to render the first column cell
   * @param td {HTMLTableCellElement} The cell to be rendered to
   * @param cellData {*} The data to add to the cell
   * @param row {HTMLTableRowElement} The row to be added to
   * @param rowData {*} The data to be added to the row
   * @returns {*}
   */
  onFirstColumnCellCreated(td, cellData, rowData, row) {
    ReactDOM.render(this.getFirstColumnHeaderData(row, rowData), td);
  }


  /**
   * This method can be used to filter which rows should appear in the results
   * @returns {*[]} The filtered items to be included
   */
  getRecords() {
    const {records} = this.props;
    return this.prepareRecordsForDisplay(records.filter(this.shouldIncludeRow));
  }

  /**
   * This applies proper sorting on records before they are shown on datatables
   * @param records
   */
  prepareRecordsForDisplay(records) {
    return records;
  }

  /**
   * This method can be used to filter which rows should appear in the results
   * @returns {boolean} Indicates whether or not the row should be included.
   */
  shouldIncludeRow() {
    return true;
  }


  componentWillUnmount() {
    super.componentWillUnmount();

    $(this.tableRef)
      .DataTable()
      .destroy(true);
  }

  reloadDataTable() {
    if (this.tableRef) {
      const table = $(this.tableRef).DataTable();
      table.clear();
      table.rows.add(this.getRecords());
      table.draw();

      if (this.isSelectAll()) {
        table.rows().select();
      } else {
        table.rows().deselect();
      }
    }
  }

  isSelectAll() {
    return $(`#${this.selectAllCheckboxId}`).prop("checked");
  }

  render() {
    return (
      <div>
        <table ref={ref => this.tableRef = ref}
               id={`${this.props.id}Table`}
               style={{width: "100%"}}
               className="table table-bordered table-hover results-table"
        />
      </div>
    );
  }
}

ImportDataManipulationTable.defaultProps = {
  enableSingleSelection: false,
};
