"use strict";

import * as UIUtils from "../../ui_utils";
import {
  FILE_TYPES_ENUM,
  REPORT_DRAFT_ENUM,
  REPORT_OPTIONS_ENUM,
  LINK_TYPE_ENUM,
  REPORT_TYPES_ENUM, NEEDS_ANALYSIS_TERM,
  TEMPLATES_PATH,
} from "../constants/report_constants";
import moment from "moment-timezone";
import {
  getMaxCriticality,
  getRawRiskScore,
  getRiskLinks,
  getRiskScales,
  getEffectiveRMPForDate, hasNotAssessedRiskScale,
} from "../../helpers/risk_helper";
import Cookies from "js-cookie";
import { RISK_COLORS } from "../../rmps/constants/rmp_constants";
import { RISK_TYPE_ENUM } from "../../helpers/constants/constants";
import { DataTransformFactory } from "../data_transform/data_transform_factory";
import {
  getRiskScoreWithLabel,
} from "../risk_tables/utils/risk_tables_helper";
import * as CommonUtils from "../../../server/common/generic/common_utils";
import * as CommonMath from "../../../server/common/generic/common_math";
import * as CommonEditablesFormatter from "../../../server/common/editables/common_editables_formatter";
import RiskUtils from "../../../server/common/misc/common_risk_utils";

export const SORT_AFTER = 1;
export const SORT_BEFORE = -1;
export const SORT_SAME = 0;

const JSON_ARRAY_FORMAT = /\[.*]$/;
const allowedEmptyFields = new Set([
  "draftMarker",
  "PreviousUnitId",
]);

// lazy loads the transform factory
// (works around some problems due to this helper being used in many places, leading to a circular reference)
let _transformFactory;

function getTransformFactory() {
  if (!_transformFactory) {
    _transformFactory = new DataTransformFactory(REPORT_OPTIONS_ENUM);
  }
  return _transformFactory;
}

export function registerStimulsoftLicense() {
  const cookie = Cookies.get("CANNED_REPORT_STATEMENT");
  if (cookie) {
    window.Stimulsoft.Base.StiLicense.key = window.atob(cookie);
  }
}

export function sortIQAVersions(i1, i2) {
  let result = SORT_SAME;
  if (i1.unitOperationId < i2.unitOperationId) {
    result = SORT_BEFORE;
  } else if (i1.unitOperationId > i2.unitOperationId) {
    result = SORT_AFTER;
  } else if (i1.iQAId < i2.iQAId) {
    result = SORT_BEFORE;
  } else if (i1.iQAId > i2.iQAId) {
    result = SORT_AFTER;
  }
  return result;
}

/**
 * This function exports report to multiple formats (PDF, Word, Excel and CSV)
 * @param fileType
 * @param etx .pdf, .xlsx, .csv or .docx
 * @param report report to be printed
 * @param reportType Could be any of TPPSectionReport, RequirementElementReport, RequirementHistoricalSummaryReport, QTPPSummaryReport, TPPSummaryReport, TPPFullReport
 * @param modelType each report can has multiple models (ex. requirement report has FQA, IQA, MA and PP)
 * @param exportFileName custom file name for export
 * @param isChartReport
 */
export function exportAs(fileType, etx, report, reportType, modelType, isChartReport = false, exportFileName) {
  let settings;
  let service;
  let format;

  for (let i = 0; i < report.pages.list.length; i++) {
    let page = report.pages.list[i];
    /*
    This condition makes sure reports that exports each page to an excel sheet have their
    tables in the right sheet.
     */
    if (reportType === REPORT_TYPES_ENUM.QTPPFullReport ||
      reportType === REPORT_TYPES_ENUM.RiskManagementPlan) {
      page.unlimitedHeight = fileType === FILE_TYPES_ENUM.EXCEL ? true : i === report.pages.list.length - 1;
    }
    /*
    Hyperlinks cause excel export to break and they also do not work on any other export,
    so we are removing them from the report.
     */
    if (page.components && page.components.list) {
      for (let component of page.components.list) {
        clearUpReportComponentHyperlinksForExport(component, true, fileType);
      }
    }
  }

  switch (fileType) {
    case FILE_TYPES_ENUM.PDF:
      settings = new window.Stimulsoft.Report.Export.StiPdfExportSettings();
      service = new window.Stimulsoft.Report.Export.StiPdfExportService();
      format = window.Stimulsoft.Report.StiExportFormat.Pdf;
      break;
    case FILE_TYPES_ENUM.WORD:
      settings = new window.Stimulsoft.Report.Export.StiWord2007ExportSettings();
      service = new window.Stimulsoft.Report.Export.StiWord2007ExportService();
      format = window.Stimulsoft.Report.StiExportFormat.Word2007;
      break;
    case FILE_TYPES_ENUM.EXCEL:
      settings = new window.Stimulsoft.Report.Export.StiExcel2007ExportSettings();
      settings.useOnePageHeaderAndFooter = true;
      settings.exportEachPageToSheet =
        reportType === REPORT_TYPES_ENUM.RiskManagementPlan ||
        reportType === REPORT_TYPES_ENUM.QTPPFullReport ||
        reportType === REPORT_TYPES_ENUM.TechTransferReport;

      service = new window.Stimulsoft.Report.Export.StiExcel2007ExportService();
      format = window.Stimulsoft.Report.StiExportFormat.Excel2007;
      break;
    case FILE_TYPES_ENUM.CSV:
      settings = new window.Stimulsoft.Report.Export.StiCsvExportSettings();
      service = new window.Stimulsoft.Report.Export.StiCsvExportService();
      format = window.Stimulsoft.Report.StiExportFormat.Csv;
      break;
  }

  window.Stimulsoft.StiOptions.Export.Word.normalStyleDefaultFontSize = 14;

  let fileName = `${isChartReport ? "Chart" : "Data"}Report`;
  fileName += `_${reportType ? reportType.replace(/\s/g, "") : report.reportAlias}`;
  fileName += isChartReport ? "" : `_${modelType}`;
  fileName = exportFileName || fileName;

  UIUtils.showLoadingImage("Exporting Report");
  setTimeout(() => {
    report.renderAsync(() => {
      report.exportDocumentAsync(data => {
        Object.saveAs(data, fileName + etx, "application/" + fileType);
        UIUtils.hideLoadingImage();
      }, format, service, settings);
    });
  }, 50);
}

/**
 * This function removes any hyperlinks from any report components before the report is exported.
 * @param component The component to remove the hyperlinks from.
 * @param removeSeparatorBand Wither to remove the green band from exported file or not.
 * @param fileType file type to export to.
 */
export function clearUpReportComponentHyperlinksForExport(component, removeSeparatorBand, fileType) {
  const interaction = component && component.interaction;

  if (interaction) {
    if (fileType !== FILE_TYPES_ENUM.PDF) {
      interaction.hyperlink = "";
    }
  }
  if (component.components && component.components.list) {
    for (let childComponent of component.components.list) {
      childComponent.enabled = !(removeSeparatorBand && childComponent.name.toLowerCase().includes("separatorband"));
      if (fileType === FILE_TYPES_ENUM.WORD && childComponent.name.toLowerCase() === ("generatedbyversion")) {
        childComponent.printOn = window.Stimulsoft.Report.Components.StiPrintOnType["AllPages"];
      }
      clearUpReportComponentHyperlinksForExport(childComponent, removeSeparatorBand, fileType);
    }
  }
}

/**
 * Function used as a comparer for Array.sort, so we can order FQAs by criticality (descending), then name (ascending)
 * @param attr1 {{criticality: number, name: string}} The first Attribute to compare
 * @param att2 {{criticality: number, name: string}} The second Attribute to compare
 * @returns {number} -1 to sort before, 1 to sort after, or 0 to keep in the same order.
 */
export function sortAttributes(attr1, att2) {
  // This function has been developed to put readability before simplicity
  let order;

  // order descending by criticality, then ascending by name
  if (attr1.criticality > att2.criticality) {
    order = SORT_BEFORE;
  } else if (attr1.criticality < att2.criticality) {
    order = SORT_AFTER;
  } else if (attr1.name > att2.name) {
    order = SORT_AFTER;
  } else if (attr1.name < att2.name) {
    order = SORT_BEFORE;
  } else {
    order = SORT_SAME;
  }

  // Uncomment for verbose logging
  // console.log("Sorting FQA: ", fqa1, fqa2, order);
  return order;
}

/**
 * Function used as a comparer for Array.sort, so we can order TPPs by ID
 * @param tpp1 {{tPPSectionId: number}} The first TPP to compare
 * @param tpp2 {{tPPSectionId: number}} The second TPP to compare
 * @returns {number} -1 to sort before, 1 to sort after, or 0 to keep in the same order.
 */
export function sortTPPs(tpp1, tpp2) {
  // This function has been developed to put readability before simplicity
  let order;

  if (tpp1.tPPSectionId > tpp2.tPPSectionId) {
    order = SORT_AFTER;
  } else if (tpp1.tPPSectionId < tpp2.tPPSectionId) {
    order = SORT_BEFORE;
  } else {
    order = SORT_SAME;
  }
  // Uncomment for verbose logging
  // console.log("Sorting TPP:", tpp1, tpp2, order);
  return order;
}

/**
 * Before the report is created, we need to build a standard JSON model to work as report dataset
 * @param result The data returned from server
 * @param reportType Could be any of TPPSectionReport, RequirementElementReport, RequirementHistoricalSummaryReport, QTPPSummaryReport, TPPSummaryReport, TPPFullReport
 * @param reportOptions Json structure with report options as defined in REPORT_OPTIONS_ENUM
 * @param rmpVersions The approved versions of the RMP associated with the project
 * @param modelType each report can has multiple models (ex. requirement report has FQA, IQA, MA and PP)
 * @param typeaheadOptions A list of options holding risk links information for a given object
 * @param dependenciesTypeaheadOptions A list of parent dependency objects which are used for detecting the parent relationship of a given object
 * @param reportDate The date that this report should be generated as of
 * @param filterOptions These are options for canned report filters
 */
export function jsonPreProcessing(result, reportType, reportOptions, projectWithAllVersions, rmpVersions, modelType,
                                  typeaheadOptions, dependenciesTypeaheadOptions, reportDate,
                                  filterOptions, selectedTypeaheadLabel) {

  let finalResult = result;
  const factory = getTransformFactory();
  const transform = factory.create({reportType});
  const options = {
    result,
    reportType,
    reportOptions,
    projectWithAllVersions,
    rmpVersions,
    modelType,
    typeaheadOptions,
    dependenciesTypeaheadOptions,
    reportDate,
    filterOptions,
    selectedTypeaheadLabel,
  };

  if (transform.shouldRun(options)) {
    finalResult = transform.transform(result, options);
  }

  return finalResult;
}

export function addTopLevelInformation(processedData, options) {
  const {
    reportType,
    modelType,
    reportDate,
    reportOptions,
    filterOptions,
  } = options;

  // If we are in a subreport, attempts to get the info from it.
  let actualType = filterOptions && filterOptions.subReport ? filterOptions.subReport : modelType;

  /* Additional top level data */
  processedData.header = getEditableHeader(reportType, actualType);
  processedData.fullElementName = getFullElementName(reportType, actualType);
  processedData.shortElementName = getShortElementName(reportType, actualType);
  processedData.informationDate = getLocalDateFromDate(reportDate, UIUtils.DATE_FORMAT_FOR_DISPLAY);
  processedData.referenceLink = "/" + getReportReference(reportType, actualType) + "/viewEdit.html?operation=View&id=" + processedData.id;
  processedData.currentUser = UIUtils.getUserFullName();
  processedData.company = UIUtils.getCompany();
  processedData.author = getAuthor(processedData);
  processedData.exportDate = moment.tz(moment.tz.guess()).format(UIUtils.LONG_DATE_FORMAT_FOR_DISPLAY);
  processedData.releaseEdition = UIUtils.RELEASE_EDITION;
  processedData.release = UIUtils.RELEASE;
  processedData.releaseNumber = UIUtils.RELEASE_NUMBER;

  if (reportOptions && reportOptions.isRiskTablesReport) {
    processedData.columnModelName = getColumnModelName(reportType, modelType);
    processedData.rowModelName = getRowModelName(reportType, modelType);
    processedData.parentModelName = getParentModelName(reportType, modelType);
    processedData.rowReferenceLink = "/" + getRowReferenceLink(reportType, modelType) + "/viewEdit.html?operation=View&id=";
    processedData.columnReferenceLink = "/" + getColumnReferenceLink(reportType, modelType) + "/viewEdit.html?operation=View&id=";
    processedData.parentReferenceLink = "/" + getParentReferenceLink(reportType, modelType) + "/viewEdit.html?operation=View&id=";
  }

  return processedData;
}

export function convertJsonArrayToString(value, separator = ", ") {
  if (value && typeof value === "string" && JSON_ARRAY_FORMAT.test(value)) {
    value = (JSON.parse(value) || []).join(separator);
  } else {
    value = undefined;
  }
  return value;
}

/**
 * This function fills in all needed details for requirement report versions
 * It was made as a function, as in historical reports, we need to process this data
 * for all approved versions including the last version
 */
export function fillInRequirementReportVersionData(data, version, effectiveRMP, typeaheadOptions, modelType) {
  version.category = version.category && (modelType === "FQA" ||
    modelType === "FQAVersion" ||
    modelType === "FPA" ||
    modelType === "FPAVersion")
    ? UIUtils.secureString(CommonEditablesFormatter.formatMultiSelectValueForDisplay(version.category))
    : version.category;

  version.ccp = version.ccp ? "Yes" : "No";
  version.samplingPlan = version.samplingPlan ? "Yes" : "No";
  version.acceptanceCriteriaLinks = convertLinksToJson(version.acceptanceCriteriaLinks);
  version.riskControlLinks = convertLinksToJson(version.riskControlLinks);
  version.componentQualificationLinks = convertLinksToJson(version.componentQualificationLinks);
  version.unitQualificationLinks = convertLinksToJson(version.unitQualificationLinks);
  version.componentRiskLinks = convertLinksToJson(version.componentRiskLinks);
  version.propertiesLinks = convertLinksToJson(version.propertiesLinks);
  version.formulationQuantityLinks = convertLinksToJson(version.formulationQuantityLinks);
  version.qualificationLinks = convertLinksToJson(version.qualificationLinks);
  version.regulatoryLinks = convertLinksToJson(version.regulatoryLinks);
  version.referencesLinks = convertLinksToJson(version.referencesLinks);

  version.contactRisk = convertJsonArrayToString(version.contactRisk);

  version.riskLinks = (modelType === "FPA" || modelType === "FQA") && !version.detailedRiskLinks ?
    convertLinksToJson(version.riskLinks) : fillInRiskLinks(version, typeaheadOptions, effectiveRMP);
  version.TPPSections = (modelType === "FPA" || modelType === "FQA") ? convertLinkedArrayToString(version.TPPSections, "TPP", " | ") : "";
  version.GeneralAttributes = (modelType === "FPA" || modelType === "FQA") ? convertLinkedArrayToString(version.GeneralAttributes, "GA", " | ") : "";
  version.ControlMethods = convertLinkedArrayToString(version.ControlMethods, "CM", " | ");

  version.createdAt = version.createdAt ? moment(version.createdAt).format(UIUtils.DATE_FORMAT_FOR_DISPLAY) : null;
  version.effectiveDate = version.effectiveDate ? moment(version.effectiveDate).format(UIUtils.DATE_FORMAT_FOR_DISPLAY) : null;
  version.expirationDate = version.expirationDate ? moment(version.expirationDate).format(UIUtils.DATE_FORMAT_FOR_DISPLAY) : null;

  fillInCriticalities(effectiveRMP, version);
  processEmptyFields(version);
}

/**
 * VersionId name changes per requirement
 * This function will return which Id should we add to our required/response
 */
export function getVersionIdForModel(requestOrResponse, modelType) {
  switch (modelType) {
    case "FQA":
      return requestOrResponse.FQAVersionId;
    case "FPA":
      return requestOrResponse.FPAVersionId;
    case "IQA":
      return requestOrResponse.IQAVersionId;
    case "IPA":
      return requestOrResponse.IPAVersionId;
    case "MA":
      return requestOrResponse.MaterialAttributeVersionId;
    case "PP":
      return requestOrResponse.ProcessParameterVersionId;
    case "PRC":
      return requestOrResponse.ProcessComponentVersionId;
    case "MT":
      return requestOrResponse.MaterialVersionId;
  }
}

/**
 * Each model (ex. IQA, PP and MA) has a dependency (Ex. UO for IQA, PRC for PP and MT for MA)
 * This function fills the dependency information depending on which report we are generating
 */
export function fillInDependenciesInfo(data, dependenciesTypeaheadOptions, reportType, modelType) {
  if (reportType === REPORT_TYPES_ENUM.IndividualMaterialReport || reportType === REPORT_TYPES_ENUM.IndividualProcessComponentReport) {
    if (data.UnitOperations && data.UnitOperations.length > 0) {
      data.unitOperationLabel = data.UnitOperations.map(uo => UIUtils.getRecordLabelForDisplay("UO", uo.id, uo.name)).join("\n");
    } else {
      data.unitOperationLabel = "None";
    }
  }

  if (modelType !== "FQA" && modelType !== "FPA") {
    data.dependencyName = getReportDependencyName(reportType, modelType);
    let shortName = getReportDependencyShortName(reportType, modelType);

    if (Array.isArray(shortName)) {
      shortName = data.ProcessComponentId ? "PRC" : data.MaterialId ? "MT" : "";
      data.dependencyName = data.ProcessComponentId ? "Process Component" : data.MaterialId ? "Material" : "";
    }

    const id = data.ProcessComponentId || data.MaterialId || data.UnitOperationId || "";
    const dependencies = dependenciesTypeaheadOptions.filter(dep => dep.id === id && dep.typeCode === shortName) || [];
    data.dependencyValue = dependencies.map(dependency => dependency.label).join("\n") || "";

    if (!data.unitOperationLabel) {
      data.unitOperationLabel = data.UnitOperationId ?
        dependenciesTypeaheadOptions.filter(dep => dep.id === data.UnitOperationId && dep.typeCode === "UO") :
        {label: "None"};
    }
  }

  if (modelType === "MT" || modelType === "PRC") {
    let id = data.SupplierId || "";
    data.supplier = dependenciesTypeaheadOptions.find(dep => dep.id === id && dep.typeCode === "SUP");
    data.supplierLabel = data.supplier ? UIUtils.getRecordLabelForDisplay("SUP", data.supplier.id, data.supplier.name) : "None";
  }
}

/**
 * This function returns model versions depending on its type
 * ex. IQAVersions for IQA, FQAVersions for FQA, ... etc
 */
export function getVersionsForModel(data, modelType) {
  switch (modelType) {
    case "FQA":
      return data.FQAVersions;
    case "FPA":
      return data.FPAVersions;
    case "IQA":
      return data.IQAVersions;
    case "IPA":
      return data.IPAVersions;
    case "MA":
      return data.MaterialAttributeVersions;
    case "PP":
      return data.ProcessParameterVersions;
    case "PRC":
      return data.ProcessComponentVersions;
    case "MT":
      return data.MaterialVersions;
    case "RMP":
      return data.RMPVersions;
  }
}

/**
 * This function fills in riskLinks of IQA, PP and MA
 */
export function fillInRiskLinks(data, typeaheadOptions, effectiveRMP) {
  // we do not use RiskInfo because we calculate criticality at link level (not entire record)

  const riskLinks = getRiskLinks(data);
  if (riskLinks && riskLinks.length > 0) {
    riskLinks.forEach(riskLink => {
      riskLink.links = convertLinksToJson(riskLink.links);
      riskLink.linkSummary = convertLinksToOneLine(riskLink.links);
      riskLink.criticality = getRawRiskScore(RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, riskLink);

      // The dependency id prop name in riskLink object can have multiple values (ex. FQAId, TargetIQAId, ... etc)
      // Searching for Id, as each of these riskLinks has an id, so I want to get the prop that has Id as part of its property name
      const {typeCode, fieldName} = getLinkToModelName(riskLink);
      const id = riskLink[fieldName];
      const riskLinkTo = typeaheadOptions.find(option => option.id === id && option.typeCode === typeCode);
      riskLink.riskLinkTo = riskLinkTo ? riskLinkTo.label : "";
      riskLink.riskLinkWithoutId = riskLinkTo ? `${riskLinkTo.typeCode} - ${riskLinkTo.name}` : "";
      riskLink.riskLink = riskLinkTo || {};
    });
  }
  return riskLinks;
}

export function getLinkToModelName(riskLink) {

  if (riskLink.GeneralAttributeId) {
    return {typeCode: "GA", fieldName: "GeneralAttributeId"};
  } else if (riskLink.FQAId) {
    return {typeCode: "FQA", fieldName: "FQAId"};
  } else if (riskLink.FPAId) {
    return {typeCode: "FPA", fieldName: "FPAId"};
  } else if (riskLink.TargetIPAId) {
    return {typeCode: "IPA", fieldName: "TargetIPAId"};
  } else if (riskLink.IPAId && riskLink.source !== "IPA") {
    return {typeCode: "IPA", fieldName: "IPAId"};
  } else if (riskLink.TargetIQAId) {
    return {typeCode: "IQA", fieldName: "TargetIQAId"};
  } else if (riskLink.IQAId && riskLink.source !== "IQA") {
    return {typeCode: "IQA", fieldName: "IQAId"};
  }
  throw new Error("Unsupported risk link type. Please edit canned_report_helper.getLinkToModelName");
}

/**
 * This function puts None in any empty property of any object
 */
export function processEmptyFields(data) {
  for (const prop in data) {
    if (prop === "obligatoryCQA") {
      continue;
    }

    if (Object.prototype.hasOwnProperty.call(data, prop) && !allowedEmptyFields.has(prop) && (data[prop] === "" || data[prop] === null
      || typeof data[prop] === "undefined" || data[prop] === "null")) {
      data[prop] = "None";
    }
  }
}

/**
 * This function convert links to one line for reports
 * @param links any linked entity to original object
 * @param prefix each link has different type (ex. TPP, CM)
 * @param separator The separator to use when concatenating the links into a single string
 */
export function convertLinkedArrayToString(links, prefix, separator) {
  let linksTraceOneLine = "";
  if (links && links.length > 0) {
    linksTraceOneLine = links.map(link => {
      return prefix + "-" + link.id + " - " + link.name;
    }).join(separator);
  } else {
    linksTraceOneLine = "None";
  }
  return linksTraceOneLine;
}

/**
 * This function checks if summary reports has data, if it doesn't,
 * it adds a marker for report to show an empty row that says
 * "No Data Available for Date (Info Date)
 */
export function checkEmptySummaryData(data, instances, paramName = "isEmptySummary") {
  data[paramName] = instances && instances.length === 0;
}

/**
 * This function fills in criticality information like value, label and color
 * @param effectiveRMP The RMP in effect for the given version
 * @param instance requirement to fill criticality information for (ex. FQA, CM, PP, ... etc)getForRiskLabel
 * @param getForRiskLabel If true will return risk scale putting always critical into consideration
 * @param showRawScore Show risk raw value
 * @param defaultValue
 */
export function fillInCriticalities(effectiveRMP, instance, getForRiskLabel = false, showRawScore = false, defaultValue = "None") {
  if (!instance) {
    return;
  }

  const recordSupportsRiskInfo = RiskUtils.recordSupportsRiskInfo(instance);
  if (!recordSupportsRiskInfo) {
    return;
  }

  if (!instance.riskInfo && recordSupportsRiskInfo) {
    console.error("Missing riskInfo from record", instance);
    throw new Error("Missing riskInfo from record");
  }

  if (!effectiveRMP) {
    return;
  }

  const hasNotAssessed = hasNotAssessedRiskScale(RISK_TYPE_ENUM.CRITICALITY, effectiveRMP);

  if (hasNotAssessed && defaultValue === NEEDS_ANALYSIS_TERM) {
    defaultValue = RiskUtils.NOT_ASSESSED_LABEL;
  }

  instance.criticality = getRawRiskScoreText(instance.riskInfo[RISK_TYPE_ENUM.CRITICALITY].value, instance);
  instance.criticalityPerc = getRiskScoreWithLabel(instance, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, showRawScore, getForRiskLabel, true, defaultValue);
  const criticalityRiskScale = instance.riskInfo[RISK_TYPE_ENUM.CRITICALITY][getForRiskLabel ? "scaleForRiskLabel" : "scale"];
  instance.criticalityLabel = getRiskScaleLabel(criticalityRiskScale);
  instance.criticalityRiskLabel = getRiskLabel(criticalityRiskScale);
  instance.criticalityColor = getRiskScaleColor(criticalityRiskScale);

  instance.capabilityRisk = getRawRiskScoreText(instance.riskInfo[RISK_TYPE_ENUM.CAPABILITY_RISK].value, instance);
  const capabilityRiskScale = instance.riskInfo[RISK_TYPE_ENUM.CAPABILITY_RISK].scale;
  instance.capabilityRiskScoreLabel = getRiskScaleLabel(capabilityRiskScale);
  instance.capabilityRiskColor = getRiskScaleColor(capabilityRiskScale);

  instance.processRisk = getRawRiskScoreText(instance.riskInfo[RISK_TYPE_ENUM.PROCESS_RISK].value, instance);
  const processRiskScale = instance.riskInfo[RISK_TYPE_ENUM.PROCESS_RISK].scale;
  instance.processRiskPerc = getRiskScoreWithLabel(instance, RISK_TYPE_ENUM.PROCESS_RISK, effectiveRMP, showRawScore, getForRiskLabel, true, defaultValue);
  instance.processRiskPercWithoutLabel = getRiskScoreWithLabel(instance, RISK_TYPE_ENUM.PROCESS_RISK, effectiveRMP, showRawScore, getForRiskLabel, false, defaultValue);
  instance.processRiskLabel = getRiskScaleLabel(processRiskScale);
  instance.processRiskRiskLabel = getRiskLabel(processRiskScale);
  instance.processRiskColor = getRiskScaleColor(processRiskScale);

  instance.detectabilityRisk = getRawRiskScoreText(instance.riskInfo[RISK_TYPE_ENUM.DETECTABILITY_RISK].value, instance);
  const detectabilityRiskScale = instance.riskInfo[RISK_TYPE_ENUM.DETECTABILITY_RISK].scale;
  instance.detectabilityRiskScoreLabel = getRiskScaleLabel(detectabilityRiskScale);
  instance.detectabilityRiskColor = getRiskScaleColor(detectabilityRiskScale);

  instance.RPN = getRawRiskScoreText(instance.riskInfo[RISK_TYPE_ENUM.RPN].value, instance);
  const rpnRiskScale = instance.riskInfo[RISK_TYPE_ENUM.RPN].scale;
  instance.RPNPerc = getRiskScoreWithLabel(instance, RISK_TYPE_ENUM.RPN, effectiveRMP, showRawScore, getForRiskLabel, true, defaultValue);
  instance.RPNPercWithoutLabel = getRiskScoreWithLabel(instance, RISK_TYPE_ENUM.RPN, effectiveRMP, showRawScore, getForRiskLabel, false, defaultValue);
  instance.RPNLabel = getRiskScaleLabel(rpnRiskScale);
  instance.RPNRiskLabel = getRiskLabel(rpnRiskScale);
  instance.RPNColor = getRiskScaleColor(rpnRiskScale);

  instance.maxCriticality = getMaxCriticality(instance.riskLinks, effectiveRMP);
  instance.maxCriticalityPerc = getRiskScoreWithLabel(instance, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, showRawScore, getForRiskLabel, true, defaultValue);
  instance.maxCriticalityPercWithoutLabel = getRiskScoreWithLabel(instance, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, showRawScore, getForRiskLabel, false, defaultValue);

  const maxCriticalityRiskScale = instance.riskInfo[RISK_TYPE_ENUM.CRITICALITY][getForRiskLabel ? "maxScaleForRiskLabel" : "maxScale"];
  instance.maxCriticalityLabel = getRiskScaleLabel(maxCriticalityRiskScale);
  instance.maxCriticalityRiskLabel = getRiskLabel(maxCriticalityRiskScale);
  instance.maxCriticalityColor = getRiskScaleColor(maxCriticalityRiskScale);

  if (hasNotAssessed) {
    if ((!instance.maxCriticality || RiskUtils.scoreIsNotAssessed(instance.maxCriticality)) && maxCriticalityRiskScale) {
      instance.maxCriticality = `${RiskUtils.NOT_ASSESSED_SCORE} (${maxCriticalityRiskScale.scoreLabel})`;
      instance.maxCriticalityPerc = `${RiskUtils.NOT_ASSESSED_SCORE} (${maxCriticalityRiskScale.scoreLabel})`;
      instance.maxCriticalityPercWithoutLabel = maxCriticalityRiskScale.scoreLabel;
    }

    if ((!instance.criticality || RiskUtils.scoreIsNotAssessed(instance.criticality)) && criticalityRiskScale) {
      instance.criticality = criticalityRiskScale.scoreLabel;
      instance.criticalityPerc = `${RiskUtils.NOT_ASSESSED_SCALE} (${criticalityRiskScale.scoreLabel})`;
    }

    if ((RiskUtils.scoreIsNotAssessed(instance.processRisk)) && processRiskScale) {
      instance.processRisk = processRiskScale.scoreLabel;
    }

    if ((instance.processRiskPerc === "None" || instance.processRiskPercWithoutLabel === "null") && processRiskScale) {
      instance.processRiskPerc = `${RiskUtils.NOT_ASSESSED_SCALE} (${processRiskScale.scoreLabel})`;
      instance.processRiskPercWithoutLabel = processRiskScale.scoreLabel;
      instance.processRiskPercWithoutLabel = processRiskScale.scoreLabel;
    }

    if ((RiskUtils.scoreIsNotAssessed(instance.RPN)) && rpnRiskScale) {
      instance.RPN = rpnRiskScale.scoreLabel;
    }

    if ((instance.RPNPerc === "None" || instance.RPNPercWithoutLabel === "null") && rpnRiskScale) {
      instance.RPNPerc = `${RiskUtils.NOT_ASSESSED_SCALE} (${rpnRiskScale.scoreLabel})`;
      instance.RPNPercWithoutLabel = rpnRiskScale.scoreLabel;
    }
  }
}

export function getRawRiskScoreText(riskScore, instance, riskProcessor) {
  let rawRiskScore = riskProcessor ? riskProcessor(instance) : riskScore;
  return (rawRiskScore || RiskUtils.scoreIsNotAssessed(rawRiskScore)) ? rawRiskScore.toString() : "";
}

/**
 * Helper function to return a risk score label given a risk scale or "" when no risk has been specified
 * @param riskScale The risk scale to return the risk label for
 * @returns {string}
 */
export function getRiskScaleLabel(riskScale) {
  return riskScale ? riskScale.scoreLabel : RiskUtils.NOT_ASSESSED_LABEL;
}

/**
 * Helper function to return a risk label given a risk scale or "" when no risk has been specified
 * @param riskScale The risk scale to return the risk label for
 * @returns {string}
 */
export function getRiskLabel(riskScale) {
  return riskScale ? riskScale.riskLabel : RiskUtils.NOT_ASSESSED_LABEL;
}

/**
 * Helper function to return a risk color given a risk scale or Grey when no risk has been specified
 * @param riskScale The risk scale to return the risk color for
 * @returns {string}
 */
export function getRiskScaleColor(riskScale) {
  return riskScale ? riskScale.color : RISK_COLORS.NONE;
}

/**
 * This function adds markers to draft Regularities
 */
export function processDraftRegularities(data) {
  if (data.infoRegulatoryPhase) {
    data.infoRegulatoryPhase.draftMarker = data.infoRegulatoryPhase &&
    data.infoRegulatoryPhase.currentState !== UIUtils.VERSION_STATES.APPROVED ? REPORT_DRAFT_ENUM.DraftMarker : "";
  }
  if (data.currentRegulatoryPhase) {
    data.currentRegulatoryPhase.draftMarker = data.currentRegulatoryPhase &&
    data.currentRegulatoryPhase.currentState !== UIUtils.VERSION_STATES.APPROVED ? REPORT_DRAFT_ENUM.DraftMarker : "";
  }
}

/**
 * This function adds needed alerts on both Report and UI
 * If summary report, it's not needed to show error in UI,
 * as each of records represent that within the report,
 * only needed for non summarized reports
 */
export function addDraftVersionAlerts(data, options) {
  const {
    reportOptions,
    reportType,
    modelType,
    reportDate,
  } = options;

  if (options.showDraftAlerts) {
    data.draftMarker = REPORT_DRAFT_ENUM.DraftMarker;
    data.draftMessage = REPORT_DRAFT_ENUM.DraftMessage.replace("{informationDate}", getLocalDateFromDate(reportDate, UIUtils.DATE_FORMAT_FOR_DISPLAY));

    if (!reportOptions.isSummaryReport) {
      let shortElementName = getShortElementName(reportType, modelType);
      let recordName = UIUtils.getRecordLabelForDisplay(shortElementName, data.id, data.name);
      UIUtils.showError(UIUtils.secureString(`${reportOptions.elementLabel} (${recordName}) doesn't have an approved version.`));
    }
  } else {
    data.draftMarker = "";
    data.draftMessage = "";
  }
  return data;
}

/**
 * This function converts links to a JSON object. It uses the same format as when we save links as strings to the database.
 * @param links all links exist within an editable
 * @param linkToId Report works with datasources, all data
 * need to come from the same data source, so I am adding a ref
 * to the actual element to be able to build a relationship between
 * links and original element
 * Sometimes links are sent from a risklink which doesnt have a complete S3 mandatory fields (name), thus this condition
 * && (links.fileName || links.link)
 * @param linkType The type of link to convert into json as defined in LINK_TYPE_ENUM constant
 * @param isDefectiveMeasure check if attribute measure is Defects (Pass/Fail) or Conforms (Pass/Fail) or not
 * @param shouldProcessEmptyFields if true it will fill the empty fields to None
 * @return [] are changed from string to object, so it need to be returned as it's a new object type
 */
export function convertLinksToJson(links, linkToId, linkType, isDefectiveMeasure, shouldProcessEmptyFields = true) {
  if (links && (links.fileName || links.link || links.id)) {
    links = JSON.parse(links);
    links.forEach(link => {
      if (linkType === LINK_TYPE_ENUM.ProcessCapability) {
        link.measurements = (link.measurements ? JSON.parse(link.measurements) : []).map(m => parseFloat(m));
        link.startDate = formatDate(link.startDate);
        link.manufactureDate = formatDate(link.manufactureDate);
        link.releaseDate = formatDate(link.releaseDate);
        link.expirationDate = formatDate(link.expirationDate);
        link.site = link.site ? link.site : "";
        link.gmp = link.gmp ? link.gmp : "";
        link.scale = link.scale ? link.scale : "";
        link.defectivePercentage = link.defectivePercentage && isDefectiveMeasure ? link.defectivePercentage : "";
        link.minValue = UIUtils.isNumber(link.minValue) && !isDefectiveMeasure ? link.minValue : "";
        link.maxValue = UIUtils.isNumber(link.maxValue) && !isDefectiveMeasure ? link.maxValue : "";
        link.average = UIUtils.isNumber(link.average) && !isDefectiveMeasure ? link.measurements.length > 0 ? CommonMath.getCorrectedAverage(link.measurements, true) : link.average : "";
        link.sd = UIUtils.isNumber(link.sd) && !isDefectiveMeasure ? link.measurements.length > 0 ? CommonMath.getCorrectedStd(link.measurements, true) : link.average : "";
      }
      link.elementId = linkToId;
      link.fileName = link?.linkType === "Link" ? link.link : link.fileName;
      link.appliesTo = Array.isArray(link.appliesTo) ? (link.appliesTo || []).map(
        applyItem => UIUtils.isNumber(applyItem) ? applyItem : UIUtils.convertCamelCaseToSpacedOutWords(applyItem),
      ).join(" | ") : link.appliesTo;
      link.description = link.description ? link.description : "";

      delete link.importResults;
      delete link.measurements;
      if (shouldProcessEmptyFields) {
        processEmptyFields(link);
      }
    });
  }
  return links ? links : [];
}

export function formatDate(date) {
  return date ? moment(date, UIUtils.DATE_FORMAT_FOR_STORAGE).format(UIUtils.DATE_FORMAT_FOR_DISPLAY) : "";
}

/**
 * In summary reports, links are added as one line
 * This function return links as one line for viewing
 * You MUST call convertLinksToJson(links) before using this function
 * We always process the document name, but in case of other documents (Ex. in risk links),
 * they don't have document name, so I am using fileName
 * @param {any} links
 * @param {{allowHTML: boolean}} options Indicates the options used to convert the links to one line.
 */
export function convertLinksToOneLine(links, options = {allowHTML: false}) {
  const {allowHTML} = options || {};

  let result = [];
  if (links) {
    for (let i = 0; i < links.length; i++) {
      let link = links[i];

      if (link.name && link.name !== "None") {
        result.push(`- ${link.name}`);
      }

      if (link.fileName && link.fileName !== "None") {
        if (link.linkType === "Link" && allowHTML) {
          result.push(`<a href="${link.fileName}">${UIUtils.getSecuredURL(link.fileName)}</a>`);
        } else {
          result.push(link.fileName);
        }
      }
    }
  }
  if (result.length === 0) {
    result.push("None");
  }
  return result.join(`${allowHTML ? "<br/>" : ","}\r\n`);
}

/**
 * This function returns all proposed versions got approved
 * @param versions all versions of an editable either approved, proposed, draft or rejected
 * @param modelType the model to get the approved versions for
 */
export function getApprovedProposedVersions(versions, modelType) {
  let approvedProposalsOnly = [];
  if (versions) {
    for (let version of versions) {

      // This code handles our default RMPs created by system which doesnt have any proposed versions
      if (UIUtils.isSystemUserId(version.createdByUserId) && modelType === "RMP" &&
        version.majorVersion === 1 && version.minorVersion === 0) {

        let systemApprovalInfo = {
          comment: "",
          createdByUserId: -1,
          RMPVersionId: -1,
          approveByDate: "",
          updatedAt: "",
          createdAt: "",
          createdByUser: {
            id: -1,
            firstName: "System",
            lastName: "User",
            title: "",
          },
        };

        version.approvalDate = moment(version.approvalDate).format(UIUtils.DATE_FORMAT_FOR_DISPLAY),
        version.updatedAt = "";
        version.approvalRequest = systemApprovalInfo;
        version.approvalResponses = [{
          ...systemApprovalInfo,
          comment: "This record is a default record created by the System.",
        }];
        approvedProposalsOnly.push(version);
        continue;
      }

      for (let versionTransition of version.versionTransitions) {
        if (versionTransition.transition === UIUtils.VERSION_STATES.PROPOSED
          && versionTransition.approvalResponses
          && versionTransition.approvalResponses.length > 0) {
          version.updatedAt = moment(versionTransition.createdAt).format(UIUtils.DATE_FORMAT_FOR_DISPLAY);
          version.approvalResponses = versionTransition.approvalResponses;
          version.approvalRequest = versionTransition.approvalRequest;

          let isRejected = version.approvalResponses.find(y => y.approve === false);
          if (!isRejected) {
            version.approvalRequest.versionId = getVersionIdForModel(version.approvalRequest, modelType);
            version.approvalRequest.createdByUser.title = version.approvalRequest.createdByUser.title ?
              "(" + version.approvalRequest.createdByUser.title + ")" : "";
            version.approvalResponses.forEach(response => {
              response.createdAt = moment(response.createdAt).format(UIUtils.DATE_FORMAT_FOR_DISPLAY);
              response.versionId = getVersionIdForModel(response, modelType);
              response.createdByUser.title = response.createdByUser.title ?
                "(" + response.createdByUser.title + ")" : "";
            });
            version.approvalDate = version.approvalResponses.sort((a, b) => b.id - a.id)[0].createdAt;
            version.majorVersion = UIUtils.parseInt(version.majorVersion) + 1;
            version.minorVersion = 0;
            approvedProposalsOnly.push(version);
            break;
          }
        }
      }
    }
  }
  return approvedProposalsOnly;
}

/**
 * This function gets the last person who made a proposal for a version that got approved
 */
export function getAuthor(data) {
  let approvalRequestUser;
  if (data && data.approvedVersions && data.approvedVersions.length > 0) {
    approvalRequestUser = data.approvedVersions[0].approvalRequest.createdByUser;
  }
  return approvalRequestUser ? (approvalRequestUser.firstName + " " + approvalRequestUser.lastName) : "";
}

export function getViewerOptions(reportType, enableZoom = true) {
  let options = new window.Stimulsoft.Viewer.StiViewerOptions();
  options.appearance.showTooltips = false;
  options.appearance.bookmarksPrint = false;
  options.appearance.bookmarksTreeWidth = 0;
  options.appearance.openLinksWindow =
    reportType === REPORT_TYPES_ENUM.TPPFullReport ||
    reportType === REPORT_TYPES_ENUM.RiskManagementPlan ||
    reportType === REPORT_TYPES_ENUM.QTPPFullReport ? "_self" : "_blank";
  options.toolbar.visible = false;
  options.toolbar.showBookmarksButton = false;
  options.toolbar.viewMode = window.Stimulsoft.Viewer.StiWebViewMode.Continuous;

  if (enableZoom) {
    options.toolbar.zoom = window.Stimulsoft.Viewer.StiZoomMode.PageWidth;
  }
  return options;
}

export function getReportDependencyName(reportType, editableName) {
  return reportType && editableName ? REPORT_OPTIONS_ENUM[reportType].editableModelNames[editableName].dependencyName : "";
}

export function getReportDependencyShortName(reportType, editableName) {
  return reportType && editableName ? REPORT_OPTIONS_ENUM[reportType].editableModelNames[editableName].dependencyShortName : "";
}

export function getReportReference(reportType, editableName) {
  return reportType && editableName ? REPORT_OPTIONS_ENUM[reportType].editableModelNames[editableName].referenceLink : "";
}

export function getColumnModelName(reportType, editableName) {
  return reportType && editableName ? REPORT_OPTIONS_ENUM[reportType].editableModelNames[editableName].columnModelName : "";
}

export function getRowModelName(reportType, editableName) {
  return reportType && editableName ? REPORT_OPTIONS_ENUM[reportType].editableModelNames[editableName].rowModelName : "";
}

export function getParentModelName(reportType, editableName) {
  return reportType && editableName ? REPORT_OPTIONS_ENUM[reportType].editableModelNames[editableName].parentModelName : "";
}

export function getRowReferenceLink(reportType, editableName) {
  return reportType && editableName ? REPORT_OPTIONS_ENUM[reportType].editableModelNames[editableName].rowReferenceLink : "";
}

export function getColumnReferenceLink(reportType, editableName) {
  return reportType && editableName ? REPORT_OPTIONS_ENUM[reportType].editableModelNames[editableName].columnReferenceLink : "";
}

export function getParentReferenceLink(reportType, editableName) {
  return reportType && editableName ? REPORT_OPTIONS_ENUM[reportType].editableModelNames[editableName].parentReferenceLink : "";
}

export function getFullElementName(reportType, editableName) {
  return reportType && editableName ? REPORT_OPTIONS_ENUM[reportType].editableModelNames[editableName].fullElementName : "";
}

export function getShortElementName(reportType, editableName) {
  return reportType && editableName ? REPORT_OPTIONS_ENUM[reportType].editableModelNames[editableName].shortElementName : "";
}

export function getEditableHeader(reportType, editableName) {
  return reportType && editableName ? REPORT_OPTIONS_ENUM[reportType].editableModelNames[editableName].header : "";
}

export function getUTCDateFromDate(someDate, format) {
  return someDate ? moment(someDate).tz("UTC").format(format) : null;
}

export function getUTCDateForURL(someDate) {
  return someDate ? moment(someDate).toISOString() : null;
}

export function getLocalDateFromDate(someDate, format) {
  return someDate ? moment(someDate).format(format) : null;
}

export function getCorrectedDateForDatePicker(event) {
  const today = UIUtils.getEndOfDayForDate(moment());

  let selectedMoment = UIUtils.getEndOfDayForDate(event);
  if (selectedMoment.isAfter(today)) {
    selectedMoment = today;
  }

  return selectedMoment.toDate();
}

export function isDraft(requirement) {
  return requirement.majorVersion === 0 && requirement.minorVersion > 0;
}

export function getReportTemplateFullPath(reportType, editableName) {
  if (!reportType || !editableName) {
    return "";
  }

  //we must declare variable here otherwise we get reference undefined error
  const reportsEnum = REPORT_OPTIONS_ENUM;
  return TEMPLATES_PATH + "/" + reportsEnum[reportType].editableModelNames[editableName].templateName;

}

export function getRequirementsReportModelNameForView(recordType) {
  return CommonUtils.pluralize(CommonUtils.singularize(recordType).toUpperCase());
}

export function getFileType(fileExt) {
  switch (fileExt) {
    case "pdf":
      return FILE_TYPES_ENUM.PDF;
    case "docx":
      return FILE_TYPES_ENUM.WORD;
    case "xlsx":
      return FILE_TYPES_ENUM.EXCEL;
    case "csv":
      return FILE_TYPES_ENUM.CSV;
    case "png":
      return FILE_TYPES_ENUM.PNG;
  }
}

export function getReportOptions(reportType) {
  return REPORT_OPTIONS_ENUM[reportType];
}

/**
 * This function returns all criticality risk labels sorted by highest criticality
 * @param rmpVersions The approved versions of the RMP associated with the project
 * @param reportDate The date that this report should be generated as of
 */
export function getRiskLabelsForRequirementsSummaryReport(projectWithAllVersions, rmpVersions, reportDate) {
  let effectiveRMP = getEffectiveRMPForDate(projectWithAllVersions, rmpVersions, reportDate);
  let options = getRiskScales(RISK_TYPE_ENUM.CRITICALITY, effectiveRMP);
  options = options.sort((a, b) => b.from - a.from).map(version => version.riskLabel);

  let result = [];
  result.push("All");
  for (let option of options) {
    if (!result.includes(option)) {
      result.push(option);
    }
  }

  if (effectiveRMP.configureByTypeSettings) {
    const configureByTypeSettings = JSON.parse(effectiveRMP.configureByTypeSettings);
    for (const configKey in configureByTypeSettings) {
      if (configKey.startsWith("potentialLabel") && !result.includes(configureByTypeSettings[configKey])) {
        result.push(configureByTypeSettings[configKey]);
      }
    }

    for (const configKey in configureByTypeSettings) {
      if (configKey.startsWith("keyLabel") && !result.includes(configureByTypeSettings[configKey])) {
        result.push(configureByTypeSettings[configKey]);
      }
    }
  }

  result.push("None");
  return result;
}

export function formatJustification(linkJustificationArray) {
  if (linkJustificationArray.length > 1) {
    let justificationArray = linkJustificationArray.map(link => formatRiskLink(link));
    justificationArray = UIUtils.secureArray(justificationArray);
    return justificationArray.join("\n");
  } else if (linkJustificationArray.length > 0) {
    return UIUtils.secureString(linkJustificationArray[0].justification);
  }
  return "";
}

function formatRiskLink(link) {
  return `• ${link.riskLinkTo} - ${link.justification}`;
}
