import * as styles from "./table_title_bar.module.scss";
import InfoTooltip from "../../../../../widgets/tooltips/info_tooltip";
import React from "react";

import { MODELS } from "../constants";

export const DefaultTitleView = ({t, selectedModelType, modelName}) => {
  return (
    <>
      <span className={styles["default-title"]}>
        {selectedModelType}
      </span>
      {MODELS.CONTROL_METHODS === modelName && (
        <InfoTooltip
          id="infoCM"
          fdaGuidanceURL="https://www.fda.gov/media/87801/download"
          verbiage={<div>
            {t(
              "Analytical method validation is the process of demonstrating that an analytical procedure is suitable for its intended purpose. The methodology and objective of the analytical procedures should be clearly defined and understood before initiating validation studies. This understanding is obtained from scientifically-based method development and optimization studies. Validation data must be generated under a protocol approved by the sponsor following current good manufacturing practices with the description of methodology of each validation characteristic and predetermined and justified acceptance criteria, using qualified instrumentation. Protocols for both drug substance and product analytes or mixture of analytes in respective matrices should be developed and executed. You should include details of the validation studies and results with your application."
            )}
          </div>}
        />
      )}
    </>
  );
};