// noinspection JSCheckFunctionSignatures

"use strict";

import React, { Fragment, useReducer } from "react";
import ToggleInput from "react-switch";
import ErrorBar from "../../../widgets/bars/error_bar";
import { LibraryHelper, MATERIAL_ACTIONS } from "../../helpers/library_helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SYNC_MATERIAL_ACTIONS } from "../sync/sync_material_actions";
import { useWizardContext } from "../../hooks/use_wizard_context_hook";
import CommonUtils from "../../../../server/common/generic/common_utils";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { syncDefaultState, syncReducer } from "../sync/sync_material_reducer";
import { MaterialAttributesReconciliation } from "./components/material_attributes_reconciliation_table";
import { SyncUtils } from "../sync/sync_util";

/**
 * Initialize Component state.
 * @param material
 * @param libraryMaterial
 * @returns {{materialAttributes, material, specifications, changedAttributes: *[], keepSynced: boolean}}
 */
const initializeState = (material, libraryMaterial) => {

  const changedAttributes = SyncUtils.getChangedAttributes(material, libraryMaterial);
  const materialAttributes = SyncUtils.getMaterialAttributes(material, true);
  const specifications = SyncUtils.addMatchingMaterialAttribute(libraryMaterial.Specifications, SyncUtils.getMaterialAttributes(material));

  return {
    ...syncDefaultState,
    material,
    specifications,
    changedAttributes,
    materialAttributes,
  };
};

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function SyncMaterialOptionsStep({config, wizardStepsConfig, currentStep, material, action, forceRedirectToStepId}) {

  const context = useWizardContext();
  const wizardHelper = new context.WizardHelper(context);
  const libraryMaterial = config.initialState;

  const [state, dispatch] = useReducer(syncReducer, {
    ...initializeState(material, libraryMaterial)
  });

  const onTableDataChange = (specs) => {
    dispatch({
      type: SYNC_MATERIAL_ACTIONS.UpdateSpecifications,
      specifications: specs,
    });
  };

  const {
    keepSynced,
    specifications,
    changedAttributes,
    materialAttributes
  } = state;

  return (
    <div className={"row-white material-library-wizard-steps-container"
      + (currentStep === 1 ? " first-wizard-step" : "")
      + (!config.visible ? " hidden" : "")}
    >
      <div className="col-12 material-library-wizard-step-inner-container material-options-container">
        <ErrorBar id="operationAlertDiv"
                  className="create-from-project-error-bar mt-4 mb-2"
        />
        <div className="row">
          <div className="col">
            <h2 className="create-header">{libraryMaterial.name} <span className="identifier">MTL-{libraryMaterial.id}</span></h2>
            <p>The Material will be updated with the latest fields from the Library. New drafts will be created for all records.</p>
          </div>
        </div>

        {action !== MATERIAL_ACTIONS.SYNC &&
        <div className={`row align-items-center mt-3`}>
          <div className="col sync-attribute">
            <ToggleInput id="syncInput"
                         className="control-checkbox-toggle"
                         checked={keepSynced}
                         onChange={(value) => {
                           dispatch({
                             type: SYNC_MATERIAL_ACTIONS.UpdateField,
                             field: "keepSynced",
                             value: value,
                           });
                         }}
                         height={15}
                         width={31}
                         checkedIcon={false}
                         uncheckedIcon={false}
                         activeBoxShadow="0 0 2px 3px #014768"
                         onColor="#DBE1E4"
                         offColor="#DBE1E4"
                         onHandleColor="#1F46A1"
            />
            <span className="ml-3">Sync</span>
          </div>
        </div>}
        
        <div className="row">
          <span className="col sync-attribute-description">The Material will be synced to the Library and will automatically update to show changes made in the Library.</span>
        </div>
        <div className="row">
          <div className="col">
            {
              (!keepSynced || CommonUtils.isEmptyArray(changedAttributes))
              ||
              <Fragment>
                <div className="alert alert-warning changed-attributes-container" role="alert">
                  <FontAwesomeIcon icon={faExclamationTriangle} size={"lg"} />
                  <span className="ml-2">We will override the data in following fields with the Library.</span>
                  <ul className="changed-attributes">
                    {
                      changedAttributes.map((attribute, index) => {
                        return <li key={index}>{attribute.label}</li>;
                      })
                    }
                  </ul>
                </div>
                <div className="changed-attributes-bottom-border" />
              </Fragment>
            }
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="material-attributes-container">
              <h2>Material Attributes</h2>
              <p>Map the Material&rsquo;s specifications to the existing Material Attributes to link them. You can create new Material Attributes from Library specifications on the go.</p>

              <MaterialAttributesReconciliation
                dataSource={specifications}
                dropDownSource={materialAttributes}
                onDataChange={onTableDataChange}
                isMandatory={true}
              />
            </div>
          </div>
        </div>

      </div>
      <div className="row action-buttons-box">
        <div className="col">
          <button className="btn btn-lg btn-primary float-right"
                  disabled={!LibraryHelper.isSyncButtonEnabled(specifications)}
                  id={config.actionButton.toLowerCase() + "Button"}
                  onClick={
                    () => {
                      LibraryHelper.onActionClick(
                        () => LibraryHelper.preprocessSyncRequestCallback({
                          ...state,
                          material,
                          libraryMaterial,
                        }), LibraryHelper.redirectToMaterial, action
                      );
                    }
                  }
          >
            {config.actionButton}
          </button>
          { !forceRedirectToStepId ? (<button id="previousButton" className="btn btn-lg btn-secondary float-right mr-2" onClick={() => wizardHelper.goToPreviousStep({
            config,
            wizardStepsConfig
          })}
          >
            Previous
          </button>): ""}
          <button id="exitButton" className="btn btn-lg btn-secondary float-left" onClick={() => LibraryHelper.redirectToMaterial(material)}>
            Exit
          </button>
        </div>
      </div>
    </div>
  );
}