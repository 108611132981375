"use strict";

import TechTransferAdapterBase from "./tech_transfer_adapter_base";
import { getModelChangeCriteria, getPluralizedModelName } from "../tech_transfer_column_value_generator";
import TechTransferVariableAdapter from "./tech_transfer_variable_adapter";
import { formatValue } from "../../configurableTables/fieldsConfig/fields_formatter";
import * as CommonEditablesFormatter from "../../../server/common/editables/common_editables_formatter";

/**
 * This class adapts/transforms the tech transfer data from server for both PRC and MT.
 */
export default class TechTransferVariableParentAdapter extends TechTransferAdapterBase {
  getRows() {
    const techTransferRows = [];
    const pps = new TechTransferVariableAdapter(this.params,
      "ppMap", "Process Parameters").getRows();
    const mas = new TechTransferVariableAdapter(this.params,
      "maMap", "MaterialAttributes").getRows();

    const {
      getUnderlyingChangesCount,
      addCommonFilterProps,
      attachTransformationObjects,
    } = this.techTransferAdapterHelper;

    for (const record of Object.values(this.activeMap)) {
      attachTransformationObjects(record);

      let underlyingDiffsCount = 0;
      underlyingDiffsCount += getUnderlyingChangesCount(pps, record.id, this.parentPropName);
      underlyingDiffsCount += getUnderlyingChangesCount(mas, record.id, this.parentPropName);

      record.unitOperations = record.UnitOperations ? record.UnitOperations.map(uo => uo.name).join(", ") : "";
      record.steps = record.Steps ? record.Steps.map(step => step.name).join(", ") : "";

      record.supplier = record.Supplier ? record.Supplier.name : "";
      record.effectiveTechTransferRMP = this.effectiveTechTransferRMP;
      record.changeCriteria = getModelChangeCriteria(this.changeCriteria, this.modelName);
      record.underlyingDiffsCount = underlyingDiffsCount;

      record.certificateOfAnalysis = formatValue(record.certificateOfAnalysis);
      record.drugProductContact = formatValue(record.drugProductContact);
      record.compendialStandard = CommonEditablesFormatter.formatMultiSelectValueForDisplay(record.compendialStandard);

      record.effectiveDate = record.effectiveDate ? new Date(record.effectiveDate) : null;
      record.expirationDate = record.effectiveDate ? new Date(record.expirationDate) : null;
      record.isGroupRow = true;
      record.gmp = record.gmp ? "Yes" : "No";

      techTransferRows.push(record);
    }

    const wrappedResults = this.wrapResults(techTransferRows);
    const results = [];

    for (const record of wrappedResults) {
      addCommonFilterProps(record);

      const details = [];
      const parentId = record.id || record.techTransferredFrom.id;
      details.push(...pps.filter(pp => pp[this.parentPropName] === parentId || pp.techTransferredFrom[this.parentPropName] === parentId));
      details.push(...mas.filter(ma => ma[this.parentPropName] === parentId));
      record.details = details;
      results.push(record);
    }

    return results;
  }

  wrapResults(records) {
    const recordsToSort = [...records];

    /*
    This linked records will make any other than activeMap being skipped because they are process before this step.
    For example: a process component has 3 process parameters which 1 has no sending record, other has no receiving record,
    and final one has both records linked. These 3 process parameters will be filtered as linked because they were processed in
    the process parameters adapter.
     */
    let linkedRecords = records.filter(record => record.techTransferredFrom);
    let linkedRecordsIds = linkedRecords.map(record => record.techTransferredFromId || record.transferredFromId);
    const {fromProcessId, toProcessId} = this.params;
    const {
      getReceivingUnitOperationObject,
      getSendingUnitOperationObject,
    } = this.techTransferAdapterHelper;

    const getUnlinkedSendingRecords = (linkedRecordsIds) => Object.values(this.activeMap).filter(record =>
      record.processId === fromProcessId &&
      !linkedRecordsIds.includes(record.id));

    const getUnlinkedReceivingRecords = () => Object.values(this.activeMap).filter(record =>
      record.processId === toProcessId &&
      (!record.techTransferredFromId || record.techTransferredFromId === record.id) &&
      (!record.transferredFromId || record.transferredFromId === record.id)
    );

    for (const record of getUnlinkedSendingRecords(linkedRecordsIds)) {
      if (!recordsToSort.find(x => x.id === record.id)) {
        recordsToSort.push(record);
      }
    }

    for (const record of getUnlinkedReceivingRecords()) {
      if (!recordsToSort.find(x => x.id === record.id)) {
        recordsToSort.push(record);
      }
    }

    const sortedRecords = super.wrapResults(recordsToSort);

    linkedRecords = sortedRecords.filter(record => record.techTransferredFrom);
    linkedRecordsIds = linkedRecords.map(record => record.techTransferredFromId || record.transferredFromId);

    const unlinkedSendingRecords = getUnlinkedSendingRecords(linkedRecordsIds).map(record => {

      let sortedRecord = sortedRecords.find(x => x.id === record.id);
      if (!sortedRecord) {
        sortedRecord = record;
      }

      if (sortedRecord) {
        sortedRecord.unitOperations = sortedRecord.unitOperation;
        sortedRecord.steps = sortedRecord.step;
      }

      return {
        ...getReceivingUnitOperationObject(sortedRecord),
        isGroupRow: true,
        typeCode: sortedRecord.typeCode,
        techTransferredFrom: sortedRecord,
        pluralizedModelName: getPluralizedModelName(sortedRecord),
      };
    });

    const unlinkedReceivingRecords = getUnlinkedReceivingRecords().map(record => {
      let sortedRecord = sortedRecords.find(x => x.id === record.id);
      if (!sortedRecord) {
        sortedRecord = record;
      }

      return {
        ...sortedRecord,
        techTransferredFrom: {
          ...getSendingUnitOperationObject(sortedRecord),
          unitOperation: sortedRecord.unitOperation,
          unitOperations: sortedRecord.unitOperation,
          step: sortedRecord.step,
          steps: sortedRecord.steps,
        },
      };
    });

    return [
      ...linkedRecords,
      ...unlinkedSendingRecords,
      ...unlinkedReceivingRecords,
    ];
  }
}
