"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseQuickEditableListTable from "../../base_quick_editable_list_table";
import { getArchivedFilterFunctionOrDefault } from "../../utils/filter_helper";


/* This shows the table of Documents when viewing the Documents page. */
export default class GenericEditableListTable extends BaseQuickEditableListTable {
  constructor(props) {
    super(props, UIUtils.capitalize(UIUtils.convertToCamelCaseId(props.editableName)), !props.hideRemoveButton, !props.hidePaging);

    this.createdRow = this.createdRow.bind(this);
  }

  generateColumns(props) {
    let columns = typeof props.onGenerateColumns === "function" ? props.onGenerateColumns(props) : [];

    columns = [
      this.generateIDColumn(),
      ...(props.hideNameColumn ? [] : [this.generateNameColumn("View", 180)]),
      ...(columns.map(column => {
        return {
          ...column,
          data: this.createMappedCell(column.data),
          createdCell: column.createdCell,
        };
      })),
      ...(props.hideManageColumn ? [] : [this.generateManageColumn()]),
    ];

    return columns;
  }

  createdRow(row, data) {
    const item = data && data.LastVersion || data;
    const filterFunction = getArchivedFilterFunctionOrDefault(this.props.archivedItemsFilter);
    const shouldShow = !filterFunction(item);

    // this may look repeated if you see the default filter function, but it is not
    if (shouldShow && item.deletedAt !== null) {
      $(row).addClass("results-table-row-archived");
    }
  }

  generateColumnDefsArray() {
    return [
      {className: "results-table-column-id", targets: [0]},
      ...(this.props.hideManageColumn ? [] : [this.generateManageColumnDef(this.columns.length - 1)]),
    ];
  }
}
