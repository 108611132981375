"use strict";

import { useEffect, useState } from "react";
import * as UIUtils from "../../ui_utils";
import { defaultFailFunction } from "../../utils/fail_handlers";

const ActiveMap = "mtlMap";

const mapForUIDisplay = (libraryMaterials) => {
  return libraryMaterials.map(item => {
    return {
      ...item,
      Specifications: item.Specifications.map(spec => {
        return {
          ...spec,
          checked: false
        };
      })
    };
  });
};

export default function useLibraryMaterials() {
  const [materials, setMaterials] = useState([]);
  useEffect(() => {
    (
      async() => {
        try {
          UIUtils.showLoadingImage();
          const response = await getAllLibraryMaterials();
          if (response[ActiveMap]) {
            const result = Object.values(response[ActiveMap]);
            setMaterials(
              mapForUIDisplay(result)
            );
          }
        } finally {
          UIUtils.hideLoadingImage();
        }
      }
    )();
  }, []);

  return [materials];
}


function getAllLibraryMaterials() {
  return new Promise((resolve) => {
    UIUtils.secureAjaxGET(`configurableTables?source=systemLibrary&latestApproved=true&latestVersion=true`,
      {},
      true,
      defaultFailFunction.bind(this)
    ).done((results) => {
      return resolve(results);
    });
  });
}