"use strict";

import React from "react";
import { faExpandAlt, faCompressAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GLOBAL_COLLAPSE_STATES } from "../constants/constants";
import BaseReactComponent from "../../base_react_component";

/**
 * This class is responsible for showing global expand/collapse button
 */
export default class ExpandButton extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.setStateSafely({
      isExpanded: true,
    });

    this.handleExpandButtonClick = this.handleExpandButtonClick.bind(this);
  }

  handleExpandButtonClick() {
    this.setStateSafely({
      isExpanded: !this.state.isExpanded,
    }, () => {
      const expandStatus = this.state.isExpanded ?
        GLOBAL_COLLAPSE_STATES.EXPANDED : GLOBAL_COLLAPSE_STATES.COLLAPSED;

      if (this.props.onGlobalExpandStatusChange) {
        this.props.onGlobalExpandStatusChange(expandStatus);
      }
    });
  }

  render() {
    const {id} = this.props;
    const {className, isExpanded} = this.state;

    return (
      <div id={id}
           className={`btn btn-secondary expand-button ${className || ""}`}
           onClick={this.handleExpandButtonClick}>
        <FontAwesomeIcon className="expand-button-icon"
                         icon={isExpanded ? faCompressAlt : faExpandAlt}
        />
      </div>
    );
  }
}
