"use strict";

import * as UIUtils from "../../../../ui_utils";
import React, { Fragment } from "react";
import { calculateZoomPercentage, getTextractConfidenceLevel } from "../../../helpers/import_helper";
import { DOM_UPDATE_TIMEOUT } from "../../../constants/import_constants";
import OCRResultsColumn from "./ocr_results_column";
import { WIDGET_VIEW } from "./ocr_widget";
import BaseReactComponent from "../../../../base_react_component";

/**
 * This is responsible for rendering the converted text by Textract AWS service in the Extract and Upload step of the
 * AI import wizard workflow.
 */
export default class OCRTextViewer extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    const {
      zoomLevel,
      textractResults,
      originalDocumentWidth,
      originalDocumentHeight,
      paperWidth,
      pdfPageHeight,
      showAdvancedOptions,
    } = this.props;
    const nextPaperWidth = nextProps.paperWidth;
    const nextZoomLevel = nextProps.zoomLevel;
    const nextTextractResults = nextProps.textractResults;
    const nextOriginalDocumentWidth = nextProps.originalDocumentWidth;
    const nextOriginalDocumentHeight = nextProps.originalDocumentHeight;
    const nextPdfPageHeight = nextProps.pdfPageHeight;
    const nextShowAdvancedOptions = nextProps.showAdvancedOptions;

    if (!textractResults || !textractResults.blocks
      || !nextTextractResults || !nextTextractResults.blocks) {
      return true;
    }

    return (textractResults.blocks.length !== nextTextractResults.blocks.length
      || textractResults.columns.length !== nextTextractResults.columns.length
      || zoomLevel !== nextZoomLevel
      || originalDocumentWidth !== nextOriginalDocumentWidth
      || originalDocumentHeight !== nextOriginalDocumentHeight
      || paperWidth !== nextPaperWidth
      || pdfPageHeight !== nextPdfPageHeight
      || showAdvancedOptions !== nextShowAdvancedOptions
    );
  }

  componentDidMount() {
    super.componentDidMount();
    setTimeout(this.props.onTextRenderingCompleted, DOM_UPDATE_TIMEOUT);
  }

  componentDidUpdate() {
    setTimeout(this.props.onTextRenderingCompleted, DOM_UPDATE_TIMEOUT);
  }

  handleScroll() {
    this.scrollDiv && this.props.onScroll(this.scrollDiv.scrollTop, this.scrollDiv.scrollLeft, false);

    clearTimeout(this.isScrolling);
    this.isScrolling = setTimeout(() => {
      this.scrollDiv && this.props.onScroll(this.scrollDiv.scrollTop, this.scrollDiv.scrollLeft, true);
    }, 100);
  }

  getTextSize(block, zoomPercent) {
    let fontOptions = {
      fontFamily: "Open Sans",
      fontSize: this.getFontSize(block, zoomPercent),
    };
    return UIUtils.getTextSize(fontOptions, block.text);
  }

  getFontSize(block, zoomPercent) {
    const {originalDocumentHeight} = this.props;
    return (block.height * 0.8 * originalDocumentHeight * zoomPercent) + "px";
  }

  render() {
    const {
      zoomLevel, textractResults, originalDocumentWidth, originalDocumentHeight, totalPages, paperWidth, paperHeight,
      pdfPageHeight, pdfPageWidth, isPDF, selectedContent, hideNonTaggedColumns,
      showAdvancedOptions
    } = this.props;
    const blocks = textractResults && textractResults.blocks ? textractResults.blocks : [];

    /* Adjust the final applied zoom level for the rendered text based on the image scaling factor which is automatically
      applied to the image due to stretching
    */
    let maxHeight = 0;
    let maxWidth = 0;
    let zoomPercent = calculateZoomPercentage(zoomLevel, paperWidth, paperHeight, originalDocumentWidth, originalDocumentHeight);

    const wordBlocksFilterFunction = (pageNum, block) => {
      return block.blockType === "WORD"
        && (!UIUtils.isNumber(pageNum)
          || !UIUtils.isNumber(block.page)
          || Number(pageNum) === Number(block.page));
    };

    let pageDivs = [];
    if (paperWidth && textractResults) {
      for (let i = 1; i <= totalPages; i++) {
        let pageBlocks = blocks.filter(wordBlocksFilterFunction.bind(this, i));

        maxHeight = isPDF ? pdfPageHeight : originalDocumentHeight * zoomPercent;
        maxWidth = isPDF ? pdfPageWidth : originalDocumentWidth * zoomPercent;

        pageDivs.push(<div key={`import-data-ocr-page_${i}`}
                           className="import-data-ocr-page"
                           style={{width: `${maxWidth}px`, height: `${maxHeight}px`}}
                           data-page-number={i}
        >
          {pageBlocks.map(block => {
            const left = block.left * originalDocumentWidth * zoomPercent;
            const top = block.top * originalDocumentHeight * zoomPercent;
            const height = block.height * originalDocumentHeight * zoomPercent;
            const textSize = this.getTextSize(block, zoomPercent);

            // Figure out the bottom border length
            const confidence = block.confidence;
            const borderTop = top + textSize.ascent;
            const borderHeight = 2 * zoomPercent;
            let borderColor = "import-paper-bottom-border-" + getTextractConfidenceLevel(confidence).color;

            return (
              <Fragment key={block.id}>
                    <span className="import-data-ocr-text-block"
                          style={{
                            left: left + "px",
                            top: top + "px",
                            height: height + "px",
                            fontSize: this.getFontSize(block, zoomPercent)
                          }}
                    >
                      {block.text}
                    </span>
                <div style={{left: left + "px", top: borderTop + "px", width: textSize.width + "px"}}
                     data-toggle="popover"
                     data-html="true"
                     data-trigger="hover click"
                     data-placement="auto"
                     data-content={"Confidence: " + confidence.toFixed(1) + "%"}
                     className="import-paper-bottom-border-container"
                >
                  <div style={{width: textSize.width + "px", height: borderHeight + "px"}}
                       className={borderColor}
                  />
                </div>
              </Fragment>
            );
          })}
        </div>);
      }
    }

    return (
      <div className="import-data-ocr-text-div"
           ref={scrollDiv => this.scrollDiv = scrollDiv}
           onScroll={this.handleScroll}
      >
        {paperWidth > 0 ? (pageDivs.map(div => div)) : ""}
        <OCRResultsColumn zoomLevel={zoomLevel}
                          selectedView={WIDGET_VIEW.TEXT}
                          isLibraryMaterial={this.props.isLibraryMaterial}
                          pageHeight={maxHeight}
                          selectedContent={selectedContent}
                          hideNonTaggedColumns={hideNonTaggedColumns}
                          textractResults={textractResults}
                          originalDocumentWidth={originalDocumentWidth}
                          originalDocumentHeight={originalDocumentHeight}
                          showAdvancedOptions={showAdvancedOptions}
                          paperWidth={paperWidth}
                          paperHeight={paperHeight}
        />
      </div>
    );
  }
}
