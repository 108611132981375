"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as StickyAdapter from "../../utils/sticky_adapter";
import { EDITOR_OPERATIONS, RECORD_OPERATIONS } from "../../editor/editor_constants";
import BaseTitleBar from "./base_title_bar";
import TabNavBar from "../bars/tab_nav_bar";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Framework, "QuickPanelTitleBar");

/**
 *  This shows the title bar at the top of the quick panel that slides out from the left.
 */
export default class QuickPanelTitleBar extends BaseTitleBar {
  constructor(props) {
    super(props);

    this.position = null;

    this.setStateSafely({selectedTab: props.selectedTab});
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
    if (!shouldUpdate) {
      shouldUpdate = JSON.stringify(nextProps.buttons) !== JSON.stringify(this.props.buttons);
    }

    return shouldUpdate;
  }

  handleTabChanged(tab) {
    const {onTabChanged} = this.props;

    this.setStateSafely({selectedTab: tab});

    if (onTabChanged) {
      onTabChanged(tab);
    }
  }

  // noinspection JSMethodCanBeStatic
  renderTitle(instance, instanceTypeCode, primaryName) {
    return (
      <div className="quick-panel-title-bar-title-container" id="titleBar">
        <div className="quick-panel-title-bar-primary">
          {this.props.editorOperation === EDITOR_OPERATIONS.ADD ? (
            <span id="titleBarPrimary" className="title-bar-primary-name">
              {primaryName || "Add " + instanceTypeCode}
            </span>
          ) : this.renderNameOnEdit(instanceTypeCode, instance, primaryName)}
          <span className="quick-panel-title-state" id="pageTitleBarStatus">
            {"[" + (instance.currentState || UIUtils.VERSION_STATES.DRAFT) + "]"}
          </span>
        </div>
      </div>
    );
  }

  renderNameOnEdit(instanceTypeCode, instance, primaryName) {
    return (
      <Fragment>
        <a id="titleBarPrimary"
           className="title-bar-primary-name"
           href={this.getURLForInstance(instanceTypeCode, instance)}
           rel="noopener noreferrer"
           target="_blank"
        >
          {primaryName}
        </a>
        {
          instance && typeof UIUtils.isInteger(instance.majorVersion) && UIUtils.isInteger(instance.minorVersion)
            ? (
              <span id="titleBarVersion" className="title-bar-version">v{instance.majorVersion}.{instance.minorVersion}</span>
            )
            : ""
        }
      </Fragment>
    );
  }

  getURLForInstance(instanceTypeCode, instance) {
    let idOrProjectId;
    let operation;
    if (this.props.editorOperation === EDITOR_OPERATIONS.ADD) {
      idOrProjectId = this.props.parent.getProjectId();
      operation = EDITOR_OPERATIONS.ADD;
    } else {
      idOrProjectId = this.props.versionId && this.props.id ? this.props.id : instance.id;
      operation = EDITOR_OPERATIONS.VIEW;
    }

    if (idOrProjectId) {
      let url = getURLByTypeCodeAndId(instanceTypeCode, operation, idOrProjectId, this.props.operation !== RECORD_OPERATIONS.BULK_APPROVE);
      if (this.props.projectId) {
        url += "&projectId=" + this.props.projectId;
      }

      return url;
    } else {
      return "";
    }
  }

  componentDidMount() {
    super.componentDidMount();
    StickyAdapter.makeItSticky(this, "#quickPanelInnerContainer");
  }

  render() {
    const {
      instance,
      instanceTypeCode,
      buttons,
      showButtonsInNewLine,
    } = this.props;

    const primaryName = this.getPrimaryName();

    if ((buttons && showButtonsInNewLine) || (this.shouldShowTabs())) {
      return (
        <div className="row quick-panel-title-bar-container" ref={ref => this.thisRef = ref}>
          <div className="title-bar title-bar-line-1">
            {this.renderTitle(instance, instanceTypeCode, primaryName)}
          </div>
          <div className="title-bar title-bar-line-2">
            <div className="quick-panel-title-bar-buttons quick-panel-title-bar-buttons-break">
              {this.renderButtons()}
            </div>
            {this.renderTabs()}
            <div className="title-bar-bottom-border" />
          </div>
        </div>
      );
    } else {
      return (
        <div className="row quick-panel-title-bar-container">
          <div className="title-bar">
            {this.renderTitle(instance, instanceTypeCode, primaryName)}
            {buttons ? (
              <div className="quick-panel-title-bar-buttons">
                {this.renderButtons()}
              </div>
            ) : null}
            <div className="title-bar-bottom-border" />
          </div>
        </div>
      );
    }
  }

  renderButtons() {
    return this.props.buttons.filter(button => button.visible).reverse().map(button => {
      if (button.isDivider) {
        return (
          <div key="dividerDiv">
            <div className="quick-panel-title-bar-button-divider" />
          </div>
        );
      } else {
        const onClick = (...args) => {
          if (button.propsMethod && this.props[button.propsMethod]) {
            // uncomment for verbose logging
            Logger.debug("Clicking action :", button.propsMethod,
              "\n - button ID", button.id,
              "\n - button:", button,
              "\n - arguments", args,
            );
            const action = this.props[button.propsMethod].bind(this);
            return action(button, ...args);
          } else if (typeof this.props.onActionClick === "function") {
            return this.props.onActionClick(button, ...args);
          } else {
            Logger.warn("No handler attached for button ", button.id);
            return null;
          }
        };
        return (
          <div key={`${button.id}Div`} className={`quick-panel-title-bar-button-div`}>
            <button onClick={onClick.bind(this, button.arguments)}
                    className={"btn " + (button.icon ? "btn-links quick-panel-title-bar-button " : "") + (button.className)}
                    id={button.id}
                    type={button.type}
                    title={button.tooltipText}
                    disabled={button.disabled}
                    key={button.id}
            >
              {button.icon ? (
                <FontAwesomeIcon icon={button.icon} />
              ) : (
                <span className={"quick-panel-title-bar-button-text"}>{button.text}</span>
              )}
            </button>
          </div>
        );
      }
    });
  }

  renderTabs() {
    const {tabs} = this.props;
    const {selectedTab} = this.state;

    if (this.shouldShowTabs()) {
      return (
        <div className="quick-panel-title-bar-tabs">
          <TabNavBar
            tabs={UIUtils.deepClone(tabs)}
            selected={selectedTab}
            onTabChanged={this.handleTabChanged}
            className="quick-panel-tabs"
          />
        </div>
      );
    } else {
      return "";
    }
  }

  shouldShowTabs() {
    const {tabs} = this.props;
    return tabs && tabs.length > 1;
  }
}
