"use strict";

import { BaseDataTransform } from "../base_data_transform";
import {
  addDraftVersionAlerts,
  addTopLevelInformation,
  processEmptyFields
} from "../../canned_reports/canned_report_helper";

export class AddTopLevelInformationTransform extends BaseDataTransform {

  get type() {
    return "topLevelInformation";
  }

  getSupportedRecordTypes() {
    return ["currentRegulatoryPhase"];
  }

  shouldRun() {
    return true;
  }

  transform(input, options) {
    const {reportOptions} = options;

    let result = input;
    result.instances = addDraftVersionAlerts(result.instances, options);
    if (result.instances && result.instances.currentRegulatoryPhase) {
      processEmptyFields(result.instances.currentRegulatoryPhase);
    }

    let processedData = reportOptions.isSummaryReport ? result : result.instances;
    result = addTopLevelInformation(processedData, options);
    return result;
  }
}
