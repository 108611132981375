"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { getURLByKey } from "../../helpers/url_helper";
import BaseReactComponent from "../../base_react_component";

/* This shows the "Approvals Pending" table on the dashboard. */
export default class ApprovalPopupTable extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  getColumns() {
    return [
      {
        title: "ID",
        width: 50,
        data: (result, type) => {
          let fullKey = UIUtils.getTypeCodeForModelName(result.modelName) + "-" + result.id;
          if (type === "display") {
            return ReactDOMServer.renderToStaticMarkup(
              <a href={getURLByKey(fullKey, "View", false)}
                 rel="noopener noreferrer"
                 target="_blank"
              >
                {UIUtils.getRecordCustomIdForDisplay(result)}
              </a>
            );
          } else if (type === "sort") {
            return UIUtils.getKeyForSorting(fullKey);
          } else {
            // Checkboxes send in type === "type" and break if we use the sorting key above. "Filter" is another type that gets sent in.
            return fullKey;
          }
        }
      },
      {
        title: "Name",
        width: 300,
        data: (result) => {
          let fullKey = UIUtils.getTypeCodeForModelName(result.modelName) + "-" + result.id;
          return ReactDOMServer.renderToStaticMarkup(
            <a href={getURLByKey(fullKey, "View", false)}
               rel="noopener noreferrer"
               target="_blank"
            >
              {result.name}
            </a>
          );
        }
      }
    ];
  }

  componentDidMount() {
    super.componentDidMount();

    this.table = $(this.tableRef).DataTable({
      dom: "<t>",
      data: this.props.data,
      columns: this.getColumns(),
      scrollY: "150px",
      paging: false,
      scrollCollapse: true,
      stateSave: true,
    });

    this.waitForTableToShow(() => {
      this.reloadDataTable();
    });
  }

  waitForTableToShow(callback) {
    if ($(this.tableRef).is(":visible")) {
      callback();
    } else {
      setTimeout(() => {
        this.waitForTableToShow(callback);
      }, 200);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    const table = $(this.tableRef).DataTable();
    if (table) {
      table.destroy(true);
    }
  }

  componentDidUpdate() {
    this.reloadDataTable();
  }

  reloadDataTable() {
    if (this.tableRef) {
      const table = $(this.tableRef).DataTable();
      table.clear();
      table.rows.add(this.props.data);
      table.draw();
    }
  }

  render() {
    return (
      <div>
        <table ref={ref => this.tableRef = ref}
               className="table table-bordered table-hover"
               id="approvalPopupTable"
               style={{width: "100%"}}
        />
      </div>
    );
  }
}
