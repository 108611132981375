"use strict";

import { ABOUT_COL_SPAN } from "../constants/configurable_tables_col_spans_config";
import { FIELD_PROPS } from "../constants/configurable_tables_field_props_config";
import { ABOUT_SECTION } from "../constants/configurable_tables_sections_config";
import GeneralAttributeFields from "./general_attribute_fields";

/**
 * This class lists all Target Product Profile fields to be shown in export, column selection and tables.
 */
export default class TargetProductProfileFields extends GeneralAttributeFields {

  get title() {
    return "Target Product Profile";
  }

  get additionGenericFields() {
    return [
      {
        title: "Annotations",
        prop: FIELD_PROPS.ANNOTATIONS,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        tableValue: (columnGenerator) => columnGenerator.generateTextAreaColumn("Annotations", FIELD_PROPS.ANNOTATIONS),
      },
      {
        title: "Comments",
        prop: FIELD_PROPS.COMMENTS,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        tableValue: (columnGenerator) => columnGenerator.generateTextAreaColumn("Comments", FIELD_PROPS.COMMENTS),
      }
    ];
  }
}
