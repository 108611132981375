import React, { Fragment } from "react";
import BaseReactComponent from "../../base_react_component";
import { Trans } from "react-i18next";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import ConfirmationPopup from "../../widgets/generic/confirmation_popup";
import * as UIUtils from "../../ui_utils";

// i18next-extract-mark-ns-start training
class RetrainingDialog extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
  }

  handleConfirm() {
    const {
      onConfirm,
      user,
      record,
    } = this.props;

    const {justification} = this.state;

    if (onConfirm) {
      onConfirm({user, record, justification});
      $(this.modalRef.current).modal("hide");
      this.setStateSafely({justification: ""});
    }
  }

  handleHide() {
    const {onHide} = this.props;

    if (onHide) {
      onHide();
    }
  }

  render() {
    const {
      t,
      visible,
      user,
      record,
    } = this.props;

    const {justification} = this.state;

    if (!visible) {
      return "";
    }

    const userName = user.fullName;
    const recordLabel = UIUtils.getRecordCustomLabelForDisplay(record);

    return (
      <ConfirmationPopup
        headerText={t("Retrain")}
        modalRef={this.modalRef}
        useYesNoOptions
        showCancelButton
        yesButtonText={t("Yes, Retrain")}
        noButtonText={t("No, Cancel")}
        message={
          <Fragment>
            <p>
              <Trans t={t} userName={userName}>
                Are you sure you want to retrain <b>{{userName: userName}}</b> on <b>{{record: recordLabel}}</b>
              </Trans>
            </p>
            <div className="attribute-container">
              <label htmlFor="retrainingJustification">{t("Justification")}:</label>
            <textarea className="form-control"
                      id="retrainingJustificationInput"
                      data-validate="true"
                      data-error={"Justification is required."}
                      value={justification}
                      onChange={this.handleChange}
                      required
            />
            </div>
          </Fragment>
        }
        onOkButtonClick={this.handleConfirm}
        onHideConfirmationPopup={this.handleHide}
      />
    );
  }

  handleChange(event) {
    this.setStateSafely({justification: event.target.value});
  }
}

RetrainingDialog.defaultProps = {
  onConfirm: () => {
  },
  onHide: () => {
  },
};

export default I18NWrapper.wrap(RetrainingDialog, ["training", "base_page"]);
// i18next-extract-mark-ns-stop training
