"use strict";

import React from "react";
import * as UIUtils from "../../../ui_utils";
import ReactDOMServer from "react-dom/server";
import ConfigurableTablesTooltipBase from "../../../configurableTables/widgets/configurable_tables_tooltip_base";

export default class TechTransferViewChangeCriteriaTooltip extends ConfigurableTablesTooltipBase {

  show() {
    $("#techTransferViewChangeCriteriaTooltipLink").on("click", this.handleViewChangeCriteria);
  }

  handleViewChangeCriteria(event) {
    UIUtils.ignoreHandler(event);
    $(this.popover).popover("hide");
    this.props.onViewChangeCriteria(true);
  }

  wrapWithinAnchor() {
    return false;
  }

  renderPopupContent() {
    const {changeWithinCriteria} = this.props;
    return ReactDOMServer.renderToString(
      <div id="changeCriteriaTooltipDiv"
           className="tech-transfer-change-criteria-popup-tooltip m-2"
      >
        {changeWithinCriteria ?
          <div>This field belongs to change criteria</div> :
          <div>This field does not belong to change criteria. So this does not contribute to the changes.</div>
        }
        <a id={"techTransferViewChangeCriteriaTooltipLink"}>
          View change criteria
        </a>
      </div>
    );
  }

  renderBody() {
    const {title} = this.props;
    return (
      <span id={`${UIUtils.convertToCamelCaseId(title)}ReviewField`}>
        <u>{title}</u>
      </span>
    );
  }
}
