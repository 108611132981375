"use strict";

import React, { createContext, memo, useContext, useState, } from "react";

const TableStateContext = createContext();

export const useTableState = () => useContext(TableStateContext);

export const TableStateProvider = memo(({children}) => {

  const [selectedState, setSelectedState] = useState({});
  const selectedCount = Object.keys(selectedState).length;
  const selectedIds = Object.keys(selectedState);

  const clearSelectedRows = () => {
    setSelectedState({});
  };

  const adjustSelectedRows = (state) => {
    setSelectedState(state);
  };

  return (
    <TableStateContext.Provider value={{
      selectedState,
      selectedIds,
      clearSelectedRows,
      adjustSelectedRows,
      selectedCount
    }}
    >
      {children}
    </TableStateContext.Provider>
  );
});
