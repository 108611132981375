"use strict";

import React, { Fragment } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BasePopup from "../../editor/approval/base_popup";
import ConfirmationPopup from "../../widgets/generic/confirmation_popup";
import * as UIUtils from "../../ui_utils";
import { TECH_TRANSFER_MODELS_CONFIG } from "../tech_transfer_config";
import { can, generateTooltip } from "../../utils/ui_permissions";
import CommonSecurity from "../../../server/common/generic/common_security";
import { formatDate } from "../../configurableTables/fieldsConfig/fields_formatter";
import {
  COLUMN_SELECTION_ONLY_SECTIONS,
  SECTIONS
} from "../../configurableTables/fieldsConfig/constants/configurable_tables_sections_config";
import { SENDING_SITE_LABEL } from "../../configurableTables/fieldsConfig/constants/configurable_tables_columns_config";

/**
 * This class is responsible for showing the change criteria popup which user
 * uses to select which columns are considered as a change.
 */
export default class TechTransferChangeCriteria extends BasePopup {

  constructor(props) {
    super(props);
    this.setStateSafely({
      showChangeCriteriaConfirmation: false,
    });
  }

  handleCancel() {
    super.handleCancel();
    this.props.onCloseChangeCriteriaPopup();
  }

  handleApply() {
    this.setStateSafely({
      showChangeCriteriaConfirmation: true,
    });
  }

  handleHideChangeCriteriaConfirmation() {
    this.setStateSafely({
      showChangeCriteriaConfirmation: false,
    });
  }

  handleChangeCriteriaConfirmation() {
    const fields = this.getFields();
    const config = [];

    for (const field of fields) {
      const {prop} = field;
      const checkbox = $(`#${prop}_checkbox`)[0];
      const {checked, name} = checkbox;
      if (checked) {
        config.push(name);
      }
    }

    const {projectId, onUpdateChangeCriteria, selectedModelType} = this.props;
    UIUtils.secureAjaxPUT(
      `techTransfer/updateChangeCriteria`, {
        projectId,
        modelName: UIUtils.getTypeCodeForModelName(selectedModelType),
        config: JSON.stringify(config)
      }).done((changeCriteria) => {
      $(this.ignoreChangesConfirmationPopup).modal("hide");
      this.setStateSafely({
        showChangeCriteriaConfirmation: false,
      }, () => {
        this.handleCancel();
        onUpdateChangeCriteria(changeCriteria);
      });
    });
  }

  renderConfirmationPopup() {
    return (
      <ConfirmationPopup
        modalRef={ignoreChangesConfirmationPopup => this.ignoreChangesConfirmationPopup = ignoreChangesConfirmationPopup}
        headerText={"Apply changes"}
        message="Are you sure you want to apply changes to change criteria?"
        useYesNoOptions={true}
        showCancelButton={true}
        centered={true}
        onOkButtonClick={this.handleChangeCriteriaConfirmation}
        onHideConfirmationPopup={this.handleHideChangeCriteriaConfirmation}
      />
    );
  }

  getFields() {
    const {selectedModelType} = this.props;
    const fieldsConfig = TECH_TRANSFER_MODELS_CONFIG[selectedModelType].fieldsConfig;
    return fieldsConfig.fields.filter(field =>
      field.originatedFromSite === SENDING_SITE_LABEL &&
      !COLUMN_SELECTION_ONLY_SECTIONS.includes(field.section)
    );
  }

  handleChange(event) {
    const {name, checked} = event.target;
    this.setStateSafely({[name]: checked, defaultChanged: true});
  }

  isChecked(prop, config, defaultChangeCriteriaValue) {
    const {defaultChanged} = this.state;
    if (config && config.length >= 0 && !defaultChanged) {
      return config.includes(prop);
    } else {
      return this.state[prop] === undefined && !config ?
        defaultChangeCriteriaValue : this.state[prop];
    }
  }

  handleResetToDefaults() {
    const fields = this.getFields();
    const stateObject = {};
    for (const field of fields) {
      const {prop, defaultChangeCriteriaValue} = field;
      const checkbox = $(`#${prop}_checkbox`)[0];
      $(checkbox).prop("checked", defaultChangeCriteriaValue);
      stateObject[prop] = defaultChangeCriteriaValue;
    }
    this.setStateSafely({...stateObject, defaultChanged: true});
  }

  handleClearAll() {
    const fields = this.getFields();
    const stateObject = {};
    for (const field of fields) {
      const {prop} = field;
      const checkbox = $(`#${prop}_checkbox`)[0];
      $(checkbox).prop("checked", false);
      stateObject[prop] = false;
    }
    this.setStateSafely({...stateObject, defaultChanged: true});
  }

  render() {
    const {selectedModelType, securityModelType, changeCriteria,} = this.props;
    const {showChangeCriteriaConfirmation} = this.state;
    const {config, user, createdAt} = changeCriteria;
    const message = user ? `(Last changed by ${user} on ${formatDate(createdAt, null, true)})` : "";
    const fields = this.getFields();

    const options = SECTIONS[selectedModelType]
      .filter(section => !COLUMN_SELECTION_ONLY_SECTIONS.includes(section.name))
      .map((section, index) => {
        const {name, title} = section;
        return (
          <Fragment key={index}>
            <div className={`column-selection-entry-group ${index === 0 ? "mt-0" : ""}`}>
              {title}
            </div>
            {fields
              .filter(field => field.section === name)
              .map((field, index) => {
                const {prop, columnSelectionTitle, defaultChangeCriteriaValue, title} = field;
                const checked = this.isChecked(prop, config, defaultChangeCriteriaValue);

                return (
                  <div className="column-selection-entry"
                       key={index}
                  >
                    <div className="col-10">
                      {columnSelectionTitle || title}
                    </div>
                    <div className="col-2 column-selection-checkbox">
                      <input type="checkbox"
                             id={`${prop}_checkbox`}
                             name={prop}
                             onChange={this.handleChange}
                             checked={checked}
                             title={generateTooltip(CommonSecurity.Actions.CHANGE_CRITERIA, securityModelType)}
                             disabled={!can(CommonSecurity.Actions.CHANGE_CRITERIA, securityModelType)}
                      />
                    </div>
                  </div>
                );
              })}
          </Fragment>
        );
      });

    return (
      <Fragment>
        <div className="modal fade" ref={this.setModalRef}>
          <div className="modal-dialog tech-transfer-change-criteria-popup">
            <div className="modal-content">
              <div className="modal-header align-items-center">
                <h1 id="confirmationHeader"
                    className="modal-title"
                >
                  Change Criteria
                </h1>
                <span className="ml-1">{message}</span>
                <button type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={this.handleCancel}
                >
                  <FontAwesomeIcon size="sm"
                                   icon={faTimes}
                  />
                </button>
              </div>
              <div className="modal-body tech-transfer-change-criteria-popup-body">
                <div className="modal-container">
                  <div className="row">
                    <div className="col-sm-12">
                      {options}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                {can(CommonSecurity.Actions.CHANGE_CRITERIA, securityModelType) ?
                  <div className="ml-2">
                    <a className="font-weight-bold"
                       onClick={this.handleResetToDefaults}
                       id="resetToDefaultButton"
                    >
                      Reset to default
                    </a>
                    <a className="ml-2 font-weight-bold"
                       onClick={this.handleClearAll}
                       id={"clearAllButton"}
                    >
                      Clear all
                    </a>
                  </div> :
                  <div className="ml-2">
                    <span className="font-weight-bold links-btn-disabled">
                      Reset to default
                    </span>
                    <span className="ml-2 font-weight-bold links-btn-disabled">
                      Clear All
                    </span>
                  </div>}
                <div className="btn-group">
                  <button id="applyButton"
                          type="button"
                          className="btn btn-primary"
                          onClick={this.handleApply}
                          title={generateTooltip(CommonSecurity.Actions.CHANGE_CRITERIA, securityModelType)}
                          disabled={!can(CommonSecurity.Actions.CHANGE_CRITERIA, securityModelType)}
                  >
                    Apply
                  </button>
                  <button id="cancelButton"
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={this.handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showChangeCriteriaConfirmation ? this.renderConfirmationPopup() : ""}
      </Fragment>
    );
  }
}
