"use strict";

import React, { Component } from "react";

/**
 * Renders a simple cell for an empty value in the standard way for a risk table report.
 */
export class NoDataCell extends Component {
  render() {
    return (
      <div>
        <span className="risk-tables-cell-no-data">None</span>
      </div>
    );
  }
}
