"use strict";

import React from "react";
import BaseReactComponent from "../../base_react_component";
import { FILTER_TYPES } from "./configurable_tables_column_operations_constants";
import { formatDate } from "../fieldsConfig/fields_formatter";
import { FILTER_OPERATORS } from "../tables/configurable_tables_constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { process } from "@progress/kendo-data-query";
import { pushHistoryURLWithParameterChanges } from "../tables/configurable_tables_helper";
import * as UIUtils from "../../ui_utils";

export default class ConfigurableTablesColumnOperationsBar extends BaseReactComponent {
  getFormattedField(field) {
    return UIUtils.convertCamelCaseToSpacedOutWords(field);
  }

  getFieldClass(field) {
    return UIUtils.convertToCamelCaseId(field);
  }

  renderFilterRecord(field, value, operator, fieldClass) {
    const {columnOperationsHandler} = this.props;
    return (
      <div className="filter-entry pl-0 ml-1 mb-1"
           key={fieldClass}
      >
        <span className="ml-1">{this.getFormattedField(field)}</span>
        <span className="font-weight-bold"> {FILTER_OPERATORS[operator]} </span>
        <span className="mr-1">{value}</span>
        <FontAwesomeIcon className="column-operation-close mr-1"
                         size="sm"
                         icon={faTimes}
                         onClick={() => columnOperationsHandler.handleColumnFilterCancelClick(field)}
        />
      </div>
    );
  }

  getSummaryTitleSuffix() {
    return "";
  }

  render() {
    const {
      tableState,
      records,
      selectedModelType,
      visibleTableColumns,
      columnOperationsHandler,
      columnOperationsAdapter,
    } = this.props;

    const {sort, filter, skip, take} = tableState || {};
    const {filters} = filter || {};
    const renderedFilters = [];

    $(".filter-selected-entry").removeClass("filter-selected-entry");

    if (filters?.length > 0 && visibleTableColumns?.length > 0) {
      for (const filter of filters) {
        const internalFilters = filter.filters;
        const {field, operator} = internalFilters[0];
        const filteredColumn = columnOperationsAdapter
          .getFieldInformation(visibleTableColumns, field);

        if (filteredColumn) {
          const filterValues = internalFilters.map(filter => filter.value || "Empty");
          const fieldClass = this.getFieldClass(field);

          let value;
          if (filteredColumn && filteredColumn.filterType === FILTER_TYPES.DATE) {
            value = formatDate(filterValues[0]);
          } else {
            value = filterValues.length > 1 ? filterValues.join(", ") : filterValues;
          }

          renderedFilters.push(this.renderFilterRecord(field, value, operator, fieldClass));
          setImmediate(() => $(`.${fieldClass}`).addClass("filter-selected-entry"));
        }
      }
    }

    const encodedFilter = filter ? encodeURIComponent(JSON.stringify(filter)) : filter;
    pushHistoryURLWithParameterChanges({
      sort: JSON.stringify(sort),
      filter: encodedFilter,
      paging: JSON.stringify({ skip, take })
    });

    const filteredData = process((records || []).slice(0),
      columnOperationsAdapter.adaptFilterNames(this.props));

    return (renderedFilters.length > 0 ?
        <div className="ml-1 mb-3 mt-3 d-flex flex-wrap align-items-center">
          <span className="configurable-tables-filtered-records-title ml-1 mr-2"
                id="configurableTablesRecordsCountSummary"
          >
            {filteredData.data.length} of {(records || []).length} {selectedModelType} {this.getSummaryTitleSuffix()}
          </span>
          {renderedFilters}
          <a className="ml-1"
             id="configurableTablesClearAllFiltersButton"
             onClick={() => columnOperationsHandler.handleClearAllFilters()}
          >
            Clear all
          </a>
        </div> : ""
    );
  }
}
