"use strict";
import * as UIUtils from "../../ui_utils";
import * as go from "gojs";
import Cookies from "js-cookie";
// eslint-disable-next-line no-unused-vars
import * as ExpanderButton from "./buttons/expander_button";
import AddButtonBuilder from "./buttons/add_button_builder";
import ContextButtonBuilder from "./buttons/context_button_builder";
import { TYPE_HEADER } from "../adapters/diagram_results_adapter";
import ProcessExplorerTreeLayout from "./process_explorer_tree_layout";
import { TYPE_CODE, TYPE_CODE_TO_BACKGROUND_COLOR, TYPE_CODE_TO_BORDER_COLOR } from "../process_explorer_constants";
import BaseAutoBind from "../../base_auto_bind";
import ParentContextButtonBuilder from "./buttons/parent_context_button_builder";

// i18next-extract-mark-ns-start process_explorer

const cookie = Cookies.get("PROCESS_FLOW_MAP_STATEMENT");

if (cookie) {
  // eslint-disable-next-line no-import-assign
  go.licenseKey = window.atob(cookie);
}

const GO = go.GraphObject.make;
const BRAND_MILD_GREY = "#c0c6cc";
const BRAND_HIGHLIGHT_COLOR = "#1fbcff";
const DEFAULT_RECORD_WIDTH = 230;
const SELECTED_PARENT = "SELECTED_PARENT";
const SELECTED_CHILD = "SELECTED_CHILD";

export const GOJS_DIAGRAM_DEFAULTS = {
  // Read more about these here: https://gojs.net/latest/api/symbols/Diagram.html#allowClipboard
  allowClipboard: false,
  allowCopy: false,
  allowDelete: false,
  allowDrop: false,
  allowGroup: false,
  allowInsert: false,
  allowLink: false,
  allowMove: false,
  allowRelink: false,
  allowReshape: false,
  allowResize: false,
  allowRotate: false,
  allowTextEdit: false,
  allowUndo: false,
  allowUngroup: false,
  hoverDelay: 10,
};

/**
 * This wraps the GoJS code needed to create the flow diagram on the process explorer page. The class initializes and
 * configures the GoJS diagram properties, templates and layouts. This class essentially encapsulates the presentation layer.
 */
export class ProcessExplorerDiagramFacade extends BaseAutoBind {

  /**
   * @param div {string} The name of the div to draw in
   * @param nodes {[{}]} An array of node objects (see GoJS docs)
   * @param links {[{}]} An array o link objects (see GoJS docs)
   * @param showArchived Set to true if you want archived nodes to be shown.
   * @param onClick {function} A function that is called on either left or right click. The first arg is the node clicked
   *                           on and the second arg is true for a left click and false for a right click.
   * @param onAdd {function} A function that is called when the user tries to add a new instance.
   * @param onContextMenuClick {function} A function that is called when the user clicks in the context menu. The first
   *                                      parameter is an entry in ACTION_TO_ICON_ENUM that tells you which option was
   *                                      clicked on.
   * @param onCheckPermissions {function} A function called when checking for permissions (see UIPermissions.can() and
   *                                      UIPermissions.generateTooltip())
   * @param t {function} For translation
   * @param projectId {int} The id of the project.
   * @param processId {int} The id of the process.
   */
  constructor(div, nodes, links, showArchived, onClick, onAdd, onContextMenuClick, onHeaderMenuClick, onCheckPermissions, t, projectId, processId) {
    super();
    this.onClick = onClick;
    this.nodes = nodes;
    this.model = new go.GraphLinksModel(nodes, links);
    this.model.linkKeyProperty = "key";
    this.showArchived = showArchived;
    this.t = t;
    this.lastSelectedNode = null;
    this.onContextMenuClick = onContextMenuClick;
    this.onHeaderMenuClick = onHeaderMenuClick;

    // A function for making sure the nodes are in order
    const comparer = (v1, v2) => {
      const data1 = v1.node ? v1.node.data : v1.parent.node ? v1.parent.node.data : v1.parent.parent.node.data;
      const data2 = v2.node ? v2.node.data : v2.parent.node ? v2.parent.node.data : v2.parent.parent.node.data;
      // Uncomment for verbose logging
      // console.log(`Comparing ${data1.key} (${data1.order}) - ${data2.key} (${data2.order}) = ${data1.order - data2.order}`);
      return data1 && data2 ? data1.order - data2.order : 0;
    };

    let relayoutCount = 0;
    this._diagram = GO(go.Diagram, div, {
      ...GOJS_DIAGRAM_DEFAULTS,
      layout: GO(ProcessExplorerTreeLayout, {
        // This is for the header nodes (ie. Unit Operations, Process Components, etc)
        alignment: go.TreeLayout.AlignmentStart,
        layerSpacing: 30, // left/right spacing
        layerSpacingParentOverlap: 0,
        nodeIndent: 0,
        nodeIndentPastParent: 0,
        nodeSpacing: 0, // Up/down spacing
        portSpot: new go.Spot(1, 0.001, 0, 17),
        sorting: go.TreeLayout.SortingAscending,
        comparer: comparer,

        // This is what makes the alternating (record) nodes appear correctly
        treeStyle: go.TreeLayout.StyleAlternating,
        alternateAlignment: go.TreeLayout.AlignmentStart,
        alternateLayerSpacing: 30, // Left/right spacing
        alternateLayerSpacingParentOverlap: 1.0,
        alternateNodeIndent: 0,
        alternateNodeIndentPastParent: 1.0,
        alternateNodeSpacing: 5, // Up/down spacing
        alternatePortSpot: new go.Spot(0.001, 1, 15, 0),
        alternateSorting: go.TreeLayout.SortingAscending,
        alternateComparer: comparer,
      }),

      // Make the root node (Process) links work like the alternate ones
      "LayoutCompleted": e => {
        e.diagram.startTransaction("Completing Layout");

        e.diagram.findTreeRoots().each(root => {
          let bounds = root.actualBounds;
          root.position = new go.Point(bounds.x + 255, bounds.y - (bounds.height));
          root.findLinksConnected().each(function(link) {
            link.fromSpot = new go.Spot(0.001, 0.5, 0, 0);
          });

          relayoutCount++;
          if (relayoutCount < 2) {
            e.diagram.alignDocument(go.Spot.TopLeft, go.Spot.TopLeft);
          }
        });

        e.diagram.commitTransaction("Completing Layout");
        const elementsFromAbove = [".trial-bar", ".nav-bar", ".page-title-bar", "#companyHeader", ".process-explorer-container > div:first-child"];
        const height = Array.from(document.querySelectorAll(elementsFromAbove)).reduce((sum, curr) => sum + curr.clientHeight, 0);
        e.diagram.div.style.height = (window.innerHeight - height - 10) + "px";
      },

      "undoManager.isEnabled": true,
      "draggingTool.dragsTree": true,
      padding: new go.Margin(50, 20, 50, 0),
      contentAlignment: go.Spot.TopLeft,
    });

    // Register the menu buttons with GoJS
    new AddButtonBuilder(this, this._diagram, this.onClick, t, projectId, processId, onAdd, onHeaderMenuClick).buildButton();
    this.contextButtonBuilder = new ContextButtonBuilder("ContextButton", this, this._diagram, this.onClick, t, projectId, processId, onContextMenuClick, onCheckPermissions);
    this.contextButtonBuilder.buildButton(false);
    this.parentContextButtonBuilder = new ParentContextButtonBuilder("ParentContextButton", this, this._diagram, this.onClick, t, projectId, processId, onContextMenuClick, onCheckPermissions);
    this.parentContextButtonBuilder.buildButton(true);

    // Create the node & link templates that decide how nodes & links are rendered.
    let foregroundLayer = this._diagram.findLayer("Foreground");
    this._diagram.addLayerBefore(GO(go.Layer, {name: "LinksLayer"}), foregroundLayer);
    this._diagram.addLayerBefore(GO(go.Layer, {name: "NodesLayer"}), foregroundLayer);
    this._diagram.nodeTemplateMap = this.getTemplateMap();
    this._diagram.linkTemplate = this.getLinkTemplate();
    this._diagram.model = this.model;
  }

  get diagram() {
    return this._diagram;
  }

  // noinspection JSMethodCanBeStatic

  /**
   * This creates a template map used by the diagram nodes.
   * @returns {Map<string, any>}
   */
  getTemplateMap() {
    let templateMap = new go.Map();

    const recordTemplate = this.createRecordTemplate();
    for (const typeCodeKey of Object.keys(TYPE_CODE)) {
      const typeCode = TYPE_CODE[typeCodeKey];
      if (typeCode === TYPE_CODE.PROCESS_PARAMETER) {
        templateMap.add(typeCode, this.createRecordTemplate(true));
      } else {
        templateMap.add(typeCode, recordTemplate);
      }
    }

    templateMap.add(TYPE_HEADER, this.createHeaderTemplate());

    return templateMap;
  }

  getFullNameForModel(modelName) {
    switch (modelName) {
      case "IQA":
        return "Intermediate Quality Attribute";
      case "IPA":
        return "Intermediate Performance Attribute";
      default:
        return modelName;
    }
  }

  /**
   * This is used to style the records (ex. "UO-1 Receive Materials", "PRC-3 Mixer").
   *
   * @param isProcessParameter {boolean} True if this is a process parameter, false otherwise.
   * @return {Adornment | Panel | GraphObject | InstanceType<Node>}
   */
  createRecordTemplate(isProcessParameter = false) {
    const template = GO(go.Node, go.Panel.Auto,
      {
        layerName: "NodesLayer",
        avoidableMargin: new go.Margin(5, 5, 5, 5),
        alignment: go.Spot.Left,
        isTreeExpanded: false,
        selectionAdorned: false
      },
      new go.Binding("isTreeExpanded").makeTwoWay(),
      new go.Binding("visible", "visible"),
      // fix for nodes overlap
      new go.Binding("width", "", this.getOuterRecordWidth),
    );
    const outerPanel = GO(go.Panel, "Horizontal");
    outerPanel.add(this.createInnerRecordPanel());

    if (isProcessParameter) {
      outerPanel.add(
        this.createInnerParentRecordPanel()
      );
    }

    template.add(outerPanel);
    return template;
  }

  /**
   * Creates the inner block. Process parameters have 2 inner blocks - one for itself and one for the parent.
   * @return {*}
   */
  createInnerRecordPanel() {
    let contextMenu = this.contextButtonBuilder.buildMenu(false, false, false);
    return GO(go.Panel, "Auto",
      new go.Binding("width", "", this.getRecordWidth),
      {
        mouseHover: (e, obj) => {
          let node = obj.part;
          this.setShowButtons(node, true);
        },
        mouseLeave: (e, obj) => {
          let node = obj.part;
          this.setShowButtons(node, false);
        },
        click: (e, obj) => {
          this.handleClick(e, obj.part.data, false);
        },
        contextMenu,
      },
      GO(go.Shape,
        // -1 for the right border to be visible
        new go.Binding("width", "", (data) => this.getRecordWidth(data) - 1),
        {
          figure: "Rectangle",
          strokeWidth: 1,
          alignment: go.Spot.Left,
        },
        new go.Binding("stroke", "", this.getRecordStroke).ofObject(),
        new go.Binding("fill", "", this.getRecordFill).ofObject(),
      ),
      GO(go.Panel, go.Panel.Horizontal,
        {
          alignment: go.Spot.Left,
          stretch: go.GraphObject.Fill,
        },
        new go.Binding("margin", "isTreeLeaf",
          (isTreeLeaf) => new go.Margin(0, 0, 0, isTreeLeaf ? 10 : 5)).ofObject(),
        GO("ExpanderButton"),
        GO(go.TextBlock,
          {
            name: "PanelTextBlock",
            textAlign: "left",
            alignment: go.Spot.Left,
            margin: new go.Margin(10, 0, 10, 5),
            wrap: go.TextBlock.WrapDesiredSize,
          },
          new go.Binding("width", "", this.getRecordTextWidth),
          new go.Binding("font", "", this.getRecordFont),
          new go.Binding("text", "fullName"),
          new go.Binding("stroke", "textColor", textColor => {
            return textColor || "#000000";
          }),
        ),
        GO("AddButton"),
        GO("ContextButton")
      ),
    );
  }

  handleClick(e, data, clickedParent) {
    let dataToUpdate = data;
    if (clickedParent) {
      dataToUpdate = data.parent;
    }

    // Update the model to say which side was clicked on
    this.model.commit((model) => {
      model.set(data, "clickedPart", clickedParent ? SELECTED_PARENT : SELECTED_CHILD);
      if (this.lastSelectedNode && this.lastSelectedNode !== data) {
        model.set(this.lastSelectedNode, "clickedPart", null);
      }
      this.lastSelectedNode = data;
    }, "updateClickedParent");


    // Update the parent
    if (!e._hasBeenSeen // This is set by the buttons so the request isn't processed multiple times.
      && dataToUpdate
      && dataToUpdate.staticPanelKey
      && dataToUpdate.userHasAccess) {
      this.onClick(dataToUpdate, true);
    }
  }

  createInnerParentRecordPanel() {
    let contextMenu = this.parentContextButtonBuilder.buildMenu(false, false, false);
    return GO(go.Panel, "Auto",
      {
        mouseHover: (e, obj) => {
          let node = obj.part;
          this.setShowButtons(node, true, true);
        },
        mouseLeave: (e, obj) => {
          let node = obj.part;
          this.setShowButtons(node, false, true);
        },
        click: (e, obj) => {
          this.handleClick(e, obj.part.data, true);
        },
        contextMenu,
      },
      GO(go.Shape,
        new go.Binding("fill", "", this.getParentRecordFill).ofObject(),
        new go.Binding("width", "", data => data.parent ? DEFAULT_RECORD_WIDTH : 0),
        {
          figure: "Rectangle",
          strokeWidth: 0,
          alignment: go.Spot.Left,
        }),
      GO(go.Panel, go.Panel.Horizontal,
        {
          alignment: go.Spot.Left,
        },
        new go.Binding("margin", "isTreeLeaf",
          (isTreeLeaf) => new go.Margin(0, 0, 0, isTreeLeaf ? 10 : 5)).ofObject(),
        GO("ExpanderButton"),
        GO(go.TextBlock,
          {
            name: "PanelTextBlock",
            textAlign: "right",
            alignment: go.Spot.Right,
            margin: new go.Margin(10, 0, 10, 5),
            wrap: go.TextBlock.WrapDesiredSize,
          },
          new go.Binding("width", "", this.getRecordTextWidth),
          new go.Binding("font", "", this.getRecordFont),
          new go.Binding("text", "", (data) => {
            return data.parent.fullName;
          }),
          new go.Binding("stroke", "textColor", textColor => {
            return textColor || "#000000";
          }),
        ),
        GO("ParentContextButton"),
      ),
    );
  }

  getRecordStroke(node) {
    const {data} = node;
    if (data.isGhosted) {
      return BRAND_MILD_GREY;
    } else if (node.isSelected) {
      return BRAND_HIGHLIGHT_COLOR;
    } else {
      return TYPE_CODE_TO_BORDER_COLOR.get(data.category);
    }
  }

  getRecordFill(node) {
    const {data} = node;

    if (data.isGhosted) {
      return "#ffffff";
    } else if (data.clickedPart === SELECTED_CHILD) {
      return BRAND_MILD_GREY;
    } else {
      return TYPE_CODE_TO_BACKGROUND_COLOR.get(data.category);
    }
  }

  getParentRecordFill(node) {
    const {data} = node;
    let recordFill;

    if (data.isGhosted) {
      return "transparent"; // make it the same as the record's color.
    } else if (data.clickedPart === SELECTED_PARENT) {
      return BRAND_MILD_GREY;
    } else if (data.parent) {
      recordFill = TYPE_CODE_TO_BACKGROUND_COLOR.get(data.parent.category);
    } else {
      recordFill = TYPE_CODE_TO_BACKGROUND_COLOR.get(data.category);
    }

    return recordFill;
  }

  getRecordFont(data) {
    const italics = data.italic ? "Italic " : "";
    const fontSize = (data.category === TYPE_CODE.UNIT_OPERATION || data.category === TYPE_CODE.PROCESS) ? 14 : 12;
    return italics + fontSize + "px Open Sans";
  }

  getOuterRecordWidth(data) {
    if (!data.isTreeExpanded) {
      return this.getRecordWidth(data) + 300;
    }

    return this.getRecordWidth(data);
  }

  getRecordWidth(data) {
    switch (data.category) {
      case TYPE_CODE.UNIT_OPERATION:
        return 260;
      case TYPE_CODE.STEP:
        return 210; // Steps are smaller because they have to fit under UOs.
      case TYPE_CODE.PROCESS_PARAMETER:
        return data.parent ? DEFAULT_RECORD_WIDTH : 2 * DEFAULT_RECORD_WIDTH;
      default:
        return DEFAULT_RECORD_WIDTH;
    }
  }

  getRecordTextWidth(data, obj) {
    const extraRoomForExpander = obj.part.isTreeLeaf ? 18 : 0;
    let textWidth;

    switch (data.category) {
      case TYPE_CODE.UNIT_OPERATION:
        textWidth = 180;
        break;
      case TYPE_CODE.STEP:
        textWidth = 130; // Steps are smaller because they have to fit under UOs.
        break;
      case TYPE_CODE.PROCESS:
      case TYPE_CODE.MATERIAL:
      case TYPE_CODE.PROCESS_COMPONENT:
        textWidth = 148; // Leave space for the add button as well as the meatball context menu.
        break;
      case TYPE_CODE.PROCESS_PARAMETER:
        return (data.parent ? 0 : DEFAULT_RECORD_WIDTH) + 185;
      default:
        textWidth = 170;
    }

    return textWidth + extraRoomForExpander;
  }

  setShowButtons(node, showButtons, isParent = false) {
    // all model changes should happen in a transaction
    this.model.commit(function(model) {
      const data = node.data;
      model.set(data, "showAddButton", showButtons);
      if (data.category !== TYPE_HEADER) {
        if (isParent) {
          model.set(data, "showParentContextMenuButton", showButtons);
        } else {
          model.set(data, "showContextMenuButton", showButtons);
        }
      }
    }, "changeShowButtons");
  }

  /**
   * @return {[object]} the node data for all selected objects
   */
  getSelectedNodeData() {
    let selectedNodeData = [];
    this._diagram.selection.each(function(node) {
      if (node instanceof go.Node) {  // ignore any selected Links and simple Parts
        selectedNodeData.push(node.data);
      }
    });
    return selectedNodeData;
  }

  /**
   * This is used to style the type headers (ex. "Material Attributes (3)").
   *
   * @return {Adornment | Panel | GraphObject | InstanceType<Node>}
   */
  createHeaderTemplate() {
    return GO(go.Node, go.Panel.Auto,
      {
        layerName: "NodesLayer",
        avoidableMargin: new go.Margin(5, 3, 5, 3),
        isTreeExpanded: false,
        selectionAdornmentTemplate:
          GO(go.Adornment, "Auto",
            GO(go.Shape, "Rectangle",
              {fill: null, stroke: BRAND_HIGHLIGHT_COLOR, strokeWidth: 0}),
            GO(go.Placeholder)
          ),
        mouseHover: (e, obj) => {
          let node = obj.part;
          this.setShowButtons(node, true);
        },
        mouseLeave: (e, obj) => {
          let node = obj.part;
          this.setShowButtons(node, false);
        },
      },
      new go.Binding("visible", "visible"),
      new go.Binding("isTreeExpanded").makeTwoWay(),
      GO(go.Panel, go.Panel.Horizontal,
        new go.Binding("width", "", (data) => 600),
        GO("ExpanderButton"),
        GO(go.Panel, go.Panel.Horizontal,
          new go.Binding("width", "", (data) => 220),
          GO(go.TextBlock,
            {
              name: "PanelTextBlock",
              font: "12px Open Sans",
              textAlign: "left",
              alignment: go.Spot.Left,
              wrap: go.TextBlock.WrapDesiredSize,
              margin: new go.Margin(10, 0, 10, 0),
            },
            new go.Binding("width", "",
              (data) => 200),
            new go.Binding("text", "",
              data => data.fullName + " (" + (data.showArchived ? data.showArchivedCount : data.count) + ")"),
          )
        ),
        GO("AddButton")
      )
    );
  }

  // noinspection JSMethodCanBeStatic
  /**
   * This creates a template for controlling the links look and feel between the swimlanes nodes.
   * @returns {Adornment | Panel | GraphObject | InstanceType<Link>}
   */
  getLinkTemplate() {
    return GO(go.Link,
      {
        layerName: "LinksLayer",
        routing: go.Link.AvoidsNodes,
        curve: go.Link.None,
        selectable: false,
        fromEndSegmentLength: 10,
        toEndSegmentLength: 10,
      },
      new go.Binding("fromSpot", "fromSpot", go.Spot.parse),
      new go.Binding("toSpot", "toSpot", go.Spot.parse),
      new go.Binding("isLayoutPositioned", "isLayoutPositioned"),
      GO(go.Shape, // the link color and width
        {
          strokeWidth: 1,
        },
        new go.Binding("stroke", "isVisible", (isVisible) => isVisible ? BRAND_MILD_GREY : "#ffffff"), // Brand mild grey or white
      ),
    );
  }

  /**
   * Call this if you want to update the model to draw.
   */
  mergeInNodesAndLinks(newNodes, newLinks) {
    this.model.startTransaction("Merge in new nodes and links");
    // Copy over the isTreeExpanded property, so everything isn't collapsed
    const oldKeyToNodeMap = new Map(this.nodes.map(node => [node.key, node]));
    for (const newNode of newNodes) {
      if (oldKeyToNodeMap.has(newNode.key)) {
        newNode.isTreeExpanded = !!oldKeyToNodeMap.get(newNode.key).isTreeExpanded;
      }
    }

    // Merge in the data
    this.model.mergeNodeDataArray(newNodes);
    this.model.mergeLinkDataArray(newLinks);
    this.setShowArchived(this.showArchived);
    this.model.commitTransaction("Merge in new nodes and links");
  }

  /**
   * This will trigger the diagram to be laid out
   */
  reLayoutDiagram() {
    this._diagram.startTransaction("reLayout");
    this._diagram.nodes.each(node => {
      node.invalidateLayout();
    });
    this._diagram.commitTransaction("reLayout");
    this._diagram.layoutDiagram();
  }

  select(selectedRecord) {
    if (selectedRecord && selectedRecord.id) {
      const recordKey = UIUtils.getTypeCodeForModelName(selectedRecord.modelName) + "-" + selectedRecord.id;
      const nodeObject = this.nodes.find(node => node.staticPanelKey === recordKey);
      if (nodeObject) {
        const node = this._diagram.findNodeForKey(nodeObject.key);

        // Make sure its parents are expanded so the user can see the selected node.
        const parentSet = node.findTreeParentChain();
        if (parentSet && parentSet.count > 0) {
          this.model.startTransaction("ExpandParentNodes");
          let iterator = parentSet.iterator;
          while (iterator.next()) {
            const parentNode = this.nodes.find(node => node.key === iterator.value.data.key);
            if (parentNode && parentNode.key !== node.key) {
              this._diagram.model.set(parentNode, "isTreeExpanded", true);
            }
          }
          this.model.commitTransaction("ExpandParentNodes");
        }

        this._diagram.select(node);
      } else {
        throw new Error("The selected node just disappeared off of the diagram, and that should never happen. Please contact support.");
      }
    }
  }

  zoomIn() {
    this._diagram.commandHandler.increaseZoom();
  }

  zoomOut() {
    this._diagram.commandHandler.decreaseZoom();
  }

  zoomExpand() {
    this._diagram.commandHandler.resetZoom();
  }

  /**
   * Checks if a given node should be visible or not
   * @param node any node within a process explorer diagram
   * @returns {boolean} either this node should be visible or not
   */
  isNodeVisible(node) {
    const {
      category,
      deletedAt,
      isParentArchived,
      headerTypeCode,
      isGhosted,
      visible
    } = node;
    const {showArchived} = this;

    switch (category) {
      case TYPE_CODE.PROCESS:
        return true;
      case TYPE_HEADER: {
        if (headerTypeCode === "UO") {
          return (showArchived || (!deletedAt && !isParentArchived));
        }

        return showArchived ? node.showArchivedCount > 0 : node.count > 0;
      }
      default:
        if (!showArchived && node.ghostHasNoChilren) {
          return false;
        }

        return (showArchived || (!deletedAt && !isParentArchived));
    }
  }

  /**
   * Checks if a given parent has nodes beneath it or not
   * @param node a header node
   * @returns {boolean} either a header has nodes beneath it or not
   */
  doesHeaderHasNodes(node) {
    const {nodes} = this;
    const children = nodes
      .filter(record => record.category === node.headerTypeCode &&
        record.key.includes(node.key.slice(0, -1)));
    return !!children.find(child => !child.deletedAt);
  }

  /**
   * Checks if a given parent node has any children
   * @param node this can be anything that might have a child except a process
   * @returns {boolean} either this parent has children or not
   */
  doesNodeHasChildren(node) {
    const {category} = node;
    const {showArchived} = this;
    switch (category) {
      case TYPE_CODE.UNIT_OPERATION:
      case TYPE_CODE.STEP:
      case TYPE_CODE.PROCESS_COMPONENT:
      case TYPE_CODE.MATERIAL: {
        const {nodes} = this;
        const children = nodes
          .filter(record => record.key.includes(`${node.key}-`) &&
            record.category !== TYPE_HEADER);
        return showArchived ? children.length > 0 : !!children.find(child => !child.deletedAt);
      }
    }

    return undefined;
  }

  setShowArchived(showArchived) {
    this.showArchived = showArchived;
    this._diagram.startTransaction("Change show archived");
    this._diagram.nodes.each(node => {
      const {data} = node;
      const isVisible = this.isNodeVisible(data);
      this._diagram.model.set(data, "hasChildren", this.doesNodeHasChildren(data));
      this._diagram.model.set(data, "visible", isVisible);

      const displayAsArchived = data.deletedAt || data.isParentArchived || data.isProjectArchived;
      this._diagram.model.set(data, "textColor", displayAsArchived ? "#999999" : data.textColor);
      this._diagram.model.set(data, "italic", displayAsArchived ? true : data.italic);
      this._diagram.model.set(data, "showArchived", showArchived);
    });
    this._diagram.commitTransaction("Change show archived");
  }

  expandAll() {
    this._diagram.startTransaction("Expand Tree");
    this._diagram.nodes.each(node => {
      if (!node.isTreeLeaf) {
        node.expandTree(1);
        UIUtils.incrementReactComponentDidUpdateCounter();
      }
    });
    this._diagram.commitTransaction("Expand Tree");
    UIUtils.incrementReactComponentDidUpdateCounter();
  }

  collapseAll() {
    this._diagram.startTransaction("Collapse Tree");
    this._diagram.nodes.each(node => {
      node.collapseTree();
      node.wasTreeExpanded = false;
      UIUtils.incrementReactComponentDidUpdateCounter();
    });

    // Expand the Process node
    this._diagram.nodes.each(node => {
      if (node.data.category === TYPE_CODE.PROCESS) {
        node.expandTree(3);
        UIUtils.incrementReactComponentDidUpdateCounter();
      }
    });
    this._diagram.commitTransaction("Collapse Tree");
    UIUtils.incrementReactComponentDidUpdateCounter();
  }
}

// i18next-extract-mark-ns-stop process_explorer
