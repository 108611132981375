"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import Section from "../../editor/widgets/section";
import TextAreaAttribute from "../../editor/attributes/text_area_attribute";
import TextAttribute from "../../editor/attributes/text_attribute";
import BaseQuickEditor from "../../editor/base_quick_editor";
import TabNavBar from "../../widgets/bars/tab_nav_bar";
import HistoryContainer from "../../editor/history/history_container";
import RelatedRecordsPanel from "../../widgets/relatedRecords/related_records_panel";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { RelatedPanelNotice } from "../../editor/widgets/related_panel_notice";

// i18next-extract-mark-ns-start qtpp
class GeneralAttribute extends BaseQuickEditor {
  constructor(props) {
    const {t} = props;
    super(props, "generalAttribute", "GeneralAttribute", t("General Attribute (GA)"));

    this.projectId = UIUtils.getParameterByName("projectId");
    this.state.project = {id: this.projectId};
  }

  componentDidMount() {
    document.title = "General Attribute (GA)";

    super.componentDidMount();
  }

  getTabName() {
    const {t} = this.props;
    return t("QTPP");
  }

  getURLToLoadData(shouldShowApproved) {
    return `${super.getURLToLoadData(shouldShowApproved)}&includeRelatedRecords=true`;
  }

  hasRelatedItemsTab() {
    return true;
  }

  renderSidePanel(versions) {
    const {
      selectedTab, sidePanelTabs, clonedFrom,
      showMajorVersionsOnly, childInstances
    } = this.state;

    const {t} = this.props;

    return (
      <Fragment>
        <TabNavBar selected={selectedTab}
                   className="related-tab-nav-margin"
                   onTabChanged={this.handleTabChanged}
                   tabs={UIUtils.deepClone(sidePanelTabs)}
                   parent={this}
                   parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                   parentId={this.state.id}
        />
        {selectedTab?.title === sidePanelTabs.HISTORY?.title ? (
          <HistoryContainer parent={this}
                            isLoading={this.isLoading()}
                            versions={versions}
                            showWithRelatedRecords={true}
                            baseTypeName={this.baseTypeName}
                            clonedFrom={clonedFrom}
                            showMajorVersionsOnly={showMajorVersionsOnly}
          />
        ) : (
          <div>
            <RelatedPanelNotice isApproved={this.isApproved()} />

            <RelatedRecordsPanel id="generalAttributeFQARelatedRecords"
                                 header={t("Final Quality Attributes")}
                                 modelName="FQA"
                                 RMP={this.state.RMP}
                                 projectId={this.getProjectId()}
                                 isLoading={this.state.isLoading}
                                 parent={this}
                                 parentId={this.state.id}
                                 records={childInstances
                                   .filter(record => record.modelName === "FQA") || []}
            />
            <RelatedRecordsPanel id="generalAttributeFPARelatedRecords"
                                 header={t("Final Performance Attributes")}
                                 modelName="FPA"
                                 RMP={this.state.RMP}
                                 projectId={this.getProjectId()}
                                 isLoading={this.state.isLoading}
                                 parent={this}
                                 parentId={this.state.id}
                                 records={childInstances
                                   .filter(record => record.modelName === "FPA") || []}
            />
          </div>
        )}
      </Fragment>
    );
  }

  renderAttributes() {
    if (!this.projectId) {
      this.projectId = this.state.ProjectId;
    }

    const {t} = this.props;

    return (
      <div>
        <Section parent={this}
                 header={this.getGeneralHeader()}
                 collapsible={false}
                 showDocLinks={true}
                 removeOptions={["name"]}
        >
          <div className="row">
            <TextAttribute name="name"
                           parent={this}
                           tooltipText={<div>
                             {t("Label describing the General Attribute in the Quality Target Product Profile (e.g. Dosage Form, Route of Administration, Stability, etc.)")}
                           </div>}
                           className="col-sm-6"
            />
          </div>
          <div className="row">
            <TextAreaAttribute name="target"
                               rows={6}
                               tooltipText={<div>
                                 {t("This field should include the target state or value for the General Attribute.")}
                               </div>}
                               className="col-sm-12"
                               parent={this}
            />
          </div>
        </Section>
      </div>
    );
  }
}


export default I18NWrapper.wrap(GeneralAttribute, "qtpp");
// i18next-extract-mark-ns-stop qtpp
