"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import BasePage from "../../base_page";
import EditablesPageTitleBar from "../../widgets/pageTitleBar/editables_page_title_bar";
import BulkProposalApprovalTitleBar from "../../widgets/pageTitleBar/bulk_proposal_approval_title_bar";
import NavBar from "../../widgets/bars/nav_bar";
import ErrorBar from "../../widgets/bars/error_bar";
import Heading from "../../widgets/headers/heading";
import BulkApprovalReviewTable from "./bulk_approval_review_table";
import BulkApprovalResultsTable from "./bulk_approval_results_table";
import ApprovalResponsePopup from "../../editor/approval/approval_response_popup";
import { getLatestApprovalRequestVersionTransition } from "../../helpers/approval_helper";
import { can } from "../../utils/ui_permissions";
import QuickPanel from "../../editor/quick/quick_panel";
import { EDITOR_OPERATIONS, EDITOR_TYPES, RECORD_OPERATIONS } from "../../editor/editor_constants";
import { AUTO_VALIDATION_MODE_ENUM, SAVE_BEHAVIOR_ENUM } from "../../editor/base_approvable_editor";
import { EditablesService } from "../../services/editables/editables_service";
import {
  BulkOperationsStatusDisplayService
} from "../../services/bulkOperations/bulk_operations_status_display_service";
import { BulkOperationMessageHandler } from "../../services/bulkOperations/bulk_operation_message_handler";
import { BULK_APPROVAL_STATUS, MAX_RECORDS_LIMIT } from "../constants/bulk_operations_constants";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { loadRMP } from "../../helpers/risk_helper";
import { QUICK_PANEL_BUTTONS } from "../../widgets/quickPanel/quick_panel_buttons";
import { TrainingService } from "../../training/services/training_service";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import CommonSecurity from "../../../server/common/generic/common_security";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import {
  getApprovalValue,
  handleApprovalChangeValue,
  handleApprovalChangeValues
} from "../../editor/approval/approval_utils";
import { QBDLink, withRouter } from "../../utils/withRouter";

const Logger = Log.group(LOG_GROUP.Editables, "BulkApprovalPage");

class BulkApprovalMessageHandler extends BulkOperationMessageHandler {
  constructor(onMessage, statusService) {
    super(onMessage, statusService);
    this.getInProgressMessage = this.getInProgressMessage.bind(this);
  }
  getInProgressMessage() {
    return BULK_APPROVAL_STATUS.REVIEWING;
  }
}

/* This class shows the bulk approval page. */
class BulkApprovalPage extends BasePage {
  constructor(props) {
    super(props);

    this.statusService = new BulkOperationsStatusDisplayService(this.displayStatus, this.hideStatus);
    this.messageHandler = new BulkApprovalMessageHandler(this.handleWebSocketConnectionMessage, this.statusService);
    this.trainingService = new TrainingService();

    this.initializeParameters();
    this.state = this.getInitialState();
  }

  componentDidMount() {
    super.componentDidMount();

    document.title = "QbDVision Bulk Approval";
    this.initializeParameters();
    this.initializePage();
  }

  getInitialState() {
    return {
      project: {id: this.projectId},
      reviewedRows: [],
      bulkApprovalStatus: BULK_APPROVAL_STATUS.REVIEWING,
      breadcrumb: {
        current: "Bulk Approval",
      },
    };
  }

  initializeParameters() {
    this.requestedByHomePage = !!this.props.getParameterByName("requestedByHomePage");

    this.projectId = this.props.getParameterByName("projectId");

    const projects = new TypeaheadObjectCache("Project").getOptionsFromCacheIncludingArchived();
    this.parentProject = projects.length > 0 && projects.find(project => project.id === this.projectId);
    this.isProjectArchived = (this.parentProject && !!this.parentProject.deletedAt);
    this.quickPanel = React.createRef();
    this.instances = [];
  }

  initializePage() {
    this.setStateSafely(this.getInitialState());

    if (this.requestedByHomePage) {
      UIUtils.secureAjaxGET("homePage/getApprovalsPending").done(this.initializeComponent.bind(this));
    } else {
      this.initializeComponent();
    }
  }

  initializeComponent(res) {
    this.requirementIds = this.requestedByHomePage ? res.pendingApprovals.pendingApprovals
      : JSON.parse(sessionStorage["bulkApprovalRequirementIds"] || "[]");
    this.actualRequirementIdsLength = this.requirementIds.length;
    this.requirementIds = this.requirementIds.splice(0, MAX_RECORDS_LIMIT);

    /* For any reason no requirements have been loaded we redirect the user to the project
       dashboard.
     */
    if (!this.requirementIds) {
      this.props.navigate(UIUtils.getSecuredURL(`/projects/dashboard.html?projectId=${this.projectId}`));
    }

    if (this.requirementIds.length > 0) {
      UIUtils.setLoadingDisabled(false);
      UIUtils.setHideLoadingOnAjaxStop(false);
      UIUtils.showLoadingImage(`Loading ${this.requirementIds.length} Records`);

      this.loadRecords();
    }
  }


  loadRecords() {
    /* Chuck the data to be loaded into buckets of 500 records each. For number of records above this size, the lambda
         handler runs into the danger of exceeding the 30 seconds API Gateway limit and failing.
       */
    const requirementIds = [...this.requirementIds];
    const recordChunkSize = 500;
    this.recordChunkLength = Math.ceil(requirementIds.length / recordChunkSize);
    let chunkCounter = 0;
    this.loadedRecordChunks = 0;
    for (let i = 0, j = requirementIds.length; i < j; i += recordChunkSize) {
      const recordIdsSlice = requirementIds.slice(i, i + recordChunkSize);
      chunkCounter++;

      UIUtils.secureAjaxPUT(`editables/multiple/list/${this.projectId}?` +
        "onlyLoadDetails=true" +
        "&includeCOAImports=true" +
        "&includeHistory=true" +
        "&includeFromLibrary=true" +
        "&includeLastViewedVersion=true" +
        "&loadFullHistory=true" +
        "&loadRelatedRecords=true" +
        "&requestedByHomePage=" + this.requestedByHomePage.toString() +
        "&includeRiskInfo=true" +
        (this.useWriterDB ? "&useWriterDB=true" : ""), {
        requirementIds: recordIdsSlice,
      }, false).done((results) => this.onAjaxResult(results, chunkCounter));
    }
  }

  onAjaxResult(results, chunkCounter) {
    for (const record of results.instances) {
      this.instances.push(record);
      UIUtils.showLoadingImage(`Loading ${this.instances.length}/${this.requirementIds.length} Records`);
    }

    this.handlePartialLoadingCompleted(chunkCounter);
  }

  handlePartialLoadingCompleted(chunkNumber) {
    this.loadedRecordChunks++;
    Logger.verbose(() => `Loading completed in ${Date.now() - this.startLoadingTime} ms for chunk ${chunkNumber}`);
    if (this.loadedRecordChunks === this.recordChunkLength) {
      UIUtils.showLoadingImage("Preparing data for display");

      const instances = this.instances;
      setTimeout(() => {
        this.handleReceiveDataFromServer({
          instances
        });
      }, 50);
    }
  }

  handleReceiveDataFromServer(results) {
    // Uncomment for verbose logging
    // console.log("Received bulk approval results: " + UIUtils.stringify(results));

    let formattedResults = this.formatBulkApprovalData(results);
    let project = results.length > 0 ? results[0].project : this.state.project;

    this.setStateSafely({
      ...formattedResults,
      selectedRows: formattedResults.instances.filter(instance => !instance.disabled),
      project,
      searchItems: formattedResults.instances.map(instance => {
        return {
          id: instance.id,
          name: instance.name,
          label: `${UIUtils.getRecordLabelForDisplay(
            UIUtils.getTypeCodeForModelName(instance.modelName), instance.id, instance.name)}`
        };
      })
    });
  }

  formatBulkApprovalData(data) {
    for (let instance of data.instances) {
      let instanceVersions = instance[instance.modelName + "Versions"];
      let lastDraftVersion = instanceVersions[0];
      let lastViewedVersion = instanceVersions.find(version => version.id === (instance.lastViewed && instance.lastViewed.versionId));
      instance.majorVersion = lastDraftVersion.majorVersion;
      instance.minorVersion = lastDraftVersion.minorVersion;
      instance.lastDraftVersion = `v${lastDraftVersion.majorVersion}.${lastDraftVersion.minorVersion}`;
      instance.lastDraftAuthor = lastDraftVersion.createdByUser;
      instance.lastViewedVersion = lastViewedVersion ? `v${lastViewedVersion.majorVersion}.${lastViewedVersion.minorVersion}` : null;

      let approvalRequestTransition = getLatestApprovalRequestVersionTransition(lastDraftVersion.versionTransitions);
      let approvalRequest = approvalRequestTransition.approvalRequest;

      // Check if this version can be approved and if not, set the disabled flag on the instance.
      if (approvalRequest) {
        instance.proposedBy = approvalRequest.createdByUser;
        instance.dueDate = approvalRequest.approveByDate;
        instance.approvalRequestComment = approvalRequest.comment;
        let myselfInApproversList = approvalRequest.approvalReqToUsers.filter(
          reqToUser => UIUtils.isCurrentUser(reqToUser.user.cognitoUUID)).length === 1;
        if (!myselfInApproversList) {
          instance.disabled = true;
          instance.disabledReason = "You are not listed among the approvers for this requirement.";
        }
      }

      if (approvalRequestTransition) {
        if (approvalRequestTransition.transition === "Approved") {
          instance.disabled = true;
          instance.disabledReason = "Record already approved";
        }

        if (approvalRequestTransition.transition === "Proposed" &&
          approvalRequestTransition.approvalResponses.find(approvalResponse => approvalResponse.approve && approvalResponse.createdByUserId === UIUtils.getUserId())) {
          instance.disabled = true;
          instance.disabledReason = "Record already approved. Pending approval from other users.";
        }
      }

      // TODO: find a decent way of doing this
      const additionalCheck = instance.modelName === "ITP" ? this.trainingService.handleCheckPermissions : null;

      // Check the current user permissions to approve this requirement
      let userIsAllowedToApprove = can(CommonSecurity.Actions.APPROVE, UIUtils.convertCamelCaseToSpacedOutWords(instance.modelName), instance, additionalCheck);
      if (!userIsAllowedToApprove) {
        instance.disabled = true;
        instance.disabledReason = "You do not have enough permissions to approve this requirement. Please contact your administrator.";
      }

      if (instance.modelName === "RMP") {
        instance.disabled = true;
        instance.disabledReason = "Risk Management Plans should be approved from the Risk Management section";
      }
    }

    return data;
  }

  handleRowClick(rowData, cellIndex, callback) {
    if (rowData.ProjectId && this.requestedByHomePage) {
      loadRMP((rmp, project) => {
        rowData.RMP = rmp;
        rowData.ProjectWithAllVersions = project;
        rowData.requestedByHomePage = this.requestedByHomePage;
        this.handleReceiveRMPs(rowData, callback);
      }, rowData.ProjectId);
    } else {
      this.handleReceiveRMPs(rowData, callback);
    }
  }

  handleReceiveRMPs(rowData, callback) {
    $("[data-toggle='popover']").popover("hide");

    let {modelName, typeCode, id} = rowData;
    const quickPanel = this.quickPanel.current;

    if (modelName === "RMP" || !(quickPanel && quickPanel.isVisible())) {
      window.open(UIUtils.getSecuredURL(getURLByTypeCodeAndId(typeCode ?? modelName, "View", id, false)), "_blank");
      return;
    }

    this.setStateSafely({
      quickPanelInfo: {
        selectedTypeCode: UIUtils.getTypeCodeForModelName(rowData.modelName),
        selectedRecord: rowData,
        operation: RECORD_OPERATIONS.BULK_APPROVE,
      }
    }, () => {
      if (callback) {
        callback();
      }
    });

    quickPanel.expandToOpen();
  }

  handleSelectedRowsChanged(rows) {
    if (!this.state.processingBulkApproval) {
      this.setStateSafely({
        selectedRows: rows.selectedRows,
      });
    }
  }

  handleMarkAsReviewed(requirement) {
    let reviewedRows = this.state.reviewedRows;
    const requirementAlreadyReviewed = reviewedRows
      .find(row => row.id === requirement.id &&
        row.modelName === requirement.modelName);

    if (!requirementAlreadyReviewed) {
      let reviewedRequirement = this.state.instances
        .find(instance => instance.id === requirement.id &&
          instance.modelName === requirement.modelName);

      reviewedRows.push(reviewedRequirement);

      this.setStateSafely({
        instances: this.state.instances,
        reviewedRows,
        reviewedRequirement: {
          id: reviewedRequirement.id,
          modelName: reviewedRequirement.modelName
        },
      });
    }
  }

  handleHideModal() {
    this.setStateSafely({
      showApprovalResponsePopup: false,
    });
  }

  bulkApproveButtonClick() {
    this.setStateSafely({
      showApprovalResponsePopup: true,
      approvalInfo: null,
    });
  }

  /**
   * Displays the status message
   * @param message {string}
   * @param details {*}
   */
  displayStatus(message, details) {
    // only sets the status if there's a valid value
    if (message) {
      this.setStateSafely({
        bulkApprovalStatus: message,
        ...(details || {}),
      });
    }
  }

  /**
   * Hides the status message
   * @param immediately
   */
  hideStatus() {
    // do nothing
  }

  handleWebSocketConnectionMessage(message) {
    const messageContent = message.data;
    const displayMessage = (message.message || messageContent.message) || "";

    let approvalResult = {
      result: message.status,
      message: displayMessage,
      id: messageContent.id,
      modelName: messageContent.modelName
    };

    let selectedRows = this.state.selectedRows;
    let processedRow = selectedRows.find(row => row.modelName === approvalResult.modelName && row.id === approvalResult.id);
    if (processedRow) {
      const previousResult = processedRow.approvalResult;
      processedRow.approvalResult = approvalResult;

      const state = {
        approvalResult: approvalResult,
        selectedRows: selectedRows,
        processedRows: this.state.processedRows,
        failedRows: this.state.failedRows,
      };

      if (previousResult && previousResult.result === "success" && approvalResult.result === "failure") {
        state.processedRows--;
        state.failedRows++;
      } else if (approvalResult.result === "success") {
        state.processedRows++;
      } else if (approvalResult.result === "failure") {
        state.failedRows++;
      }

      this.setStateSafely(state);
    }
  }

  handleSendApproval(approve) {
    /* We need to clean the session storage so that reloading the page does not reload the same requirements for which
       a response has been submitted.
     */
    sessionStorage["bulkApprovalRequirementIds"] = "[]";

    const editablesService = new EditablesService(this.messageHandler, this.statusService);
    const selectedRows = this.state.selectedRows.map(row => `${UIUtils.getTypeCodeForModelName(row.modelName)}-${row.id}`);

    const getApprovalValueFacade = getApprovalValue.bind(this);
    const payload = {
      comment: getApprovalValueFacade("comment"),
      email: getApprovalValueFacade("email"),
      password: getApprovalValueFacade("password"),
      signingPin: getApprovalValueFacade("signingPin"),
      requirementIds: selectedRows.join(","),
    };

    const promise = editablesService
      .approve(approve, payload, {model: "multiple"})
      .then(result => {
        // if we got a successful approval, we set the cookie so password won't be asked again
        if (approve) {
          ApprovalResponsePopup.setApprovalSucceededInThisSession();
        }
        return result;
      })
      .catch(UIUtils.defaultFailFunction)
      .finally(() => {
        this.context.clearPreventNavigation();
        return this.setStateSafely({bulkApprovalStatus: BULK_APPROVAL_STATUS.COMPLETED});
      });

    if (this.approvalResponsePopup) {
      $(this.approvalResponsePopup).modal("hide");
    }

    this.setStateSafely(
      {
        processingBulkApproval: true,
        processedRows: 0,
        failedRows: 0
      }, () => {
        $(window).on("beforeunload", UIUtils.onWindowBeforeUnload);
      }
    );

    return promise;
  }

  handleRecordLastViewedVersionUpdated(modelName, id, version) {
    let updatedRecord = this.state.instances.find(instance => instance.id === id && instance.modelName === modelName);
    updatedRecord.lastViewedVersion = version;
    this.setStateSafely({
      instances: this.state.instances,
    });
  }

  renderPage() {
    let {
      instances,
      reviewedRequirement,
      selectedRows,
      reviewedRows,
      processingBulkApproval,
      bulkApprovalStatus,
      approvalResult,
      showApprovalResponsePopup,
      quickPanelInfo,
      processedRows,
      failedRows,
    } = this.state ? this.state : {};

    const {
      selectedTypeCode,
      selectedRecord,
      operation,
    } = quickPanelInfo ? quickPanelInfo : {};

    let allRows = instances || [];
    selectedRows = selectedRows || [];
    reviewedRows = reviewedRows || [];

    let canUserApprove = !selectedRows.find(row => row.disabled);

    let allSelectedRowsMarkedAsReviewed = !selectedRows.find(selectedRow => {
      return !reviewedRows.find(reviewedRow => reviewedRow.id === selectedRow.id && reviewedRow.modelName === selectedRow.modelName);
    });

    let quickPanelConfig = {
      showHistory: true,
      saveBehavior: SAVE_BEHAVIOR_ENUM.SAVE,
      quickPanelButtons: [QUICK_PANEL_BUTTONS.MARK_REVIEWED],
      sectionsCollapsed: false,
      autoValidationMode: AUTO_VALIDATION_MODE_ENUM.NONE,
      isBulkApproval: true,
    };

    const isBulkProcessingComplete = bulkApprovalStatus === BULK_APPROVAL_STATUS.COMPLETED;

    return (
      <div>
        {this.requestedByHomePage ?
          <BulkProposalApprovalTitleBar title="Approve / Reject" /> :
          <Fragment>
            <EditablesPageTitleBar projectId={this.state.project.id}
                                   projectName={this.state.project.name}
                                   projectDeletedAt={this.state.project.deletedAt}
                                   isDemoProject={this.state.project.isDemo}
                                   currentState={this.state.project.currentState}
            />
            <NavBar selected="Dashboard" projectId={this.projectId} />
          </Fragment>}
        <div className="container">
          {this.requestedByHomePage ? <div className="row-space-below" /> :
            <div id="dashboardHeader">
              <Heading level={1} className="header-banner">{!processingBulkApproval ? "Approve / Reject" : "Processing Bulk Approval / Reject"}</Heading>
              <div className="bulk-approval-title-bar-breadcrumb row-space-below">
              <span>
                &larr;
                <QBDLink to={`/projects/dashboard.html?projectId=${this.projectId}`} id="backToDashboardLink"> Back to Dashboard</QBDLink>
              </span>
              </div>
            </div>}
          <ErrorBar />
          {!processingBulkApproval ? (
            <QuickPanel ref={this.quickPanel}
                        typeCode={selectedTypeCode}
                        id={selectedRecord ? selectedRecord.id : null}
                        config={quickPanelConfig}
                        operation={operation}
                        editorType={EDITOR_TYPES.QUICK_PANEL}
                        editorOperation={EDITOR_OPERATIONS.VIEW}
                        data={selectedRecord}
                        onMarkAsReviewed={this.handleMarkAsReviewed}
                        originalWidth={700}
            />
          ) : ""}
          <div className="row row-white shadow row-space-below">
            <div className="col-sm-12">
              {this.actualRequirementIdsLength > MAX_RECORDS_LIMIT && !processingBulkApproval ?
                <div className="row">
                  <div id="bulkOperationWarningDiv"
                       className="alert alert-warning bulk-operation-warning"
                  >
                    Too many approval requests to load for bulk approval. Showing the first <span>{MAX_RECORDS_LIMIT}</span> approval requests.
                    Please use the bulk approval functionality inside each project to limit the number of approval requests shown to each project.
                  </div>
                </div>
                : ""}
              {processingBulkApproval ? (
                <div className="row bulk-operation-bar-margin">
                  <div id="approvalResultsInfoDiv" className="col-sm-12 alert alert-info">
                    {isBulkProcessingComplete
                      ? `${processedRows} of ${processedRows + failedRows} records processed successfully.`
                      : bulkApprovalStatus === BULK_APPROVAL_STATUS.EMAILS
                        ? "Sending approval response emails..."
                        : "Leave this page open only if you want to monitor the progress."}
                  </div>
                </div>
              ) : (
                <div className="row bulk-operation-bar-margin">
                  <div id="approvalReviewInfoDiv" className="col-sm-12 alert alert-info">
                    To approve or reject records in bulk, first review each item in the table below by opening the record and clicking the <strong>Mark as Reviewed</strong> button, to indicate your review. Once you have reviewed all records, use the leftmost checkbox to select the records you want to bulk process, and click the <strong>Approve/Reject</strong> button to begin the process.
                  </div>
                </div>
              )}
              <div className="row">
                {processingBulkApproval ? "" :
                  <div className="col-9 bulk-proposal-approval-header-title">
                    {allRows.length} Pending Approvals
                  </div>}
                <div className="col-3">
                  {!processingBulkApproval ? (
                    <Fragment>
                      <div className="progress bulk-approval-progress-bar">
                        <div id="bulkApprovalReviewedProgressbar"
                             className="progress-bar"
                             role="progressbar"
                             style={{width: `${allRows.length > 0 ? 100 * reviewedRows.length / allRows.length + "%" : "0%"}`}}
                             aria-valuenow="0"
                             aria-valuemin="0"
                             aria-valuemax="100"
                        />
                      </div>
                      <div id="bulkApprovalProgressbarTitle">
                        <span>{allRows.length > 0 ? "Reviewed " + reviewedRows.length + "/" + allRows.length : "Please select at least one record for approval or rejection."}</span>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="progress bulk-approval-progress-bar">
                        <div className="progress-bar bg-success"
                             role="progressbar"
                             style={{width: `${selectedRows.length > 0 ? (isBulkProcessingComplete ? 100 : 90) * processedRows / selectedRows.length + "%" : "0%"}`}}
                             aria-valuenow="0"
                             aria-valuemin="0"
                             aria-valuemax="100"
                        />
                        <div className="progress-bar bg-danger"
                             role="progressbar"
                             style={{width: `${selectedRows.length > 0 ? (isBulkProcessingComplete ? 100 : 90) * failedRows / selectedRows.length + "%" : "0%"}`}}
                             aria-valuenow="0"
                             aria-valuemin="0"
                             aria-valuemax="100"
                        />
                      </div>
                      <div id="bulkApprovalProgressbarTitle">
                        <span>{"Processed " + (processedRows + failedRows) + "/" + selectedRows.length}</span>
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  {processingBulkApproval ? (
                    <BulkApprovalResultsTable id="bulkApprovalResultsTable"
                                              data={this.state.selectedRows || []}
                                              approvalResult={approvalResult}
                                              hideCheckBoxes={true}
                                              datatablesDomOptions={"<t>"}
                                              requestedByHomePage={this.requestedByHomePage}
                                              paging={false}
                                              projectId={this.projectId}
                    />
                  ) : (
                    <BulkApprovalReviewTable id="bulkApprovalReviewTable"
                                             data={instances || []}
                                             reviewedRequirement={reviewedRequirement}
                                             selectedRequirement={selectedRecord}
                                             searchItems={this.state.searchItems || []}
                                             showSelectedRowsInfo={false}
                                             pageLength={100}
                                             paging={false}
                                             datatablesDomOptions={"<t>"}
                                             showSearchBar={true}
                                             requestedByHomePage={this.requestedByHomePage}
                                             columnsToTriggerQuickPanel={["ID", "Name"]}
                                             onRowClick={this.handleRowClick}
                                             onRecordLastViewedVersionUpdated={this.handleRecordLastViewedVersionUpdated}
                                             onSelectedRowsChanged={this.handleSelectedRowsChanged}
                                             projectId={this.projectId}
                    />
                  )}
                </div>
              </div>
              {!processingBulkApproval ? (
                <div className="row">
                  <div className="col-sm-12">
                    <button id="bulkApproveButton"
                            onClick={this.bulkApproveButtonClick}
                            className={"btn btn-lg btn-primary dashboard-bulk-approval-button"}
                            title={this.isProjectArchived
                              ? "Bulk approval of requirements is not allowed for archived projects."
                              : !canUserApprove
                                ? "One or more selected requirements cannot be approved"
                                : selectedRows.length === 0
                                  ? "Please select at least one row to include in the bulk approval or rejection."
                                  : !allSelectedRowsMarkedAsReviewed
                                    ? "Please mark all selected records as Reviewed."
                                    : ""}
                            disabled={this.isProjectArchived || !canUserApprove || selectedRows.length === 0 || !allSelectedRowsMarkedAsReviewed}
                    >
                      Approve / Reject
                    </button>
                  </div>
                </div>
              ) : ""}
            </div>
          </div>
        </div>
        {showApprovalResponsePopup && !processingBulkApproval ?
          <ApprovalResponsePopup modalRef={approvalResponsePopup => this.approvalResponsePopup = approvalResponsePopup}
                                 onSendApproval={this.handleSendApproval}
                                 onHideModal={this.handleHideModal}
                                 isBulkApproval={true}
                                 projectId={this.state.project.id}
                                 selectedRequirements={this.state.selectedRows}
                                 onApprovalChangeValue={handleApprovalChangeValue.bind(this)}
                                 onApprovalChangeValues={handleApprovalChangeValues.bind(this)}

          />
          : ""}
      </div>
    );
  }
}

export default withRouter(BulkApprovalPage);
