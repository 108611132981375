import * as ProcessExplorerData from "./process_explorer_service";
import TypeaheadObjectCache, {TYPES_TO_IGNORE_PROJECT_ID} from "../../utils/cache/typeahead_object_cache";
import {Project} from "../../common/models/project";
import * as UIUtils from "../../ui_utils";

export type DirectScopeRecord = {
  name: string;
  id: number;
  typeCode: string;
  modelName: string;
  order?: number;
  key?: string;
};

export type DirectScopeData = {
  processExplorerData: ProcessExplorerData.ProcessExplorerData;
  typeCodeToRecords: Record<string, Array<DirectScopeRecord>>;
};

export async function loadData(
  typeCode: string,
  directScopeData: DirectScopeData,
  projectId: number,
  processId: number,
): Promise<DirectScopeData> {
  if (directScopeData.typeCodeToRecords[typeCode]) {
    return directScopeData;
  }

  const modelName = (
    UIUtils.getModelNameForTypeCode(typeCode) as string
  ).replace(" ", "");
  const promises = [];
  if (projectId || TYPES_TO_IGNORE_PROJECT_ID[modelName]) {
    const loadTypeaheadPromise = new TypeaheadObjectCache(
      modelName,
      projectId,
      processId,
    )
      .loadOptions()
      .promise();
    promises.push(loadTypeaheadPromise);
  }

  if (
    [
      "Process",
      "UnitOperation",
      "Material",
      "ProcessComponent",
      "IQA",
      "IPA",
      "MaterialAttribute",
      "ProcessParameter",
      "Step",
    ].includes(modelName) &&
    projectId &&
    processId &&
    !directScopeData.processExplorerData
  ) {
    // We need to load process explorer data, so we can get the path and record
    // order
    promises.push(ProcessExplorerData.load(projectId, processId));
  }

  const results = await Promise.all(promises);
  let typeaheadRecords = results.length ? results[0] : [];
  let processExplorerData: ProcessExplorerData.ProcessExplorerData = null;
  if (results.length > 0) {
    processExplorerData = results[1];
  }

  // We only want to get the current project
  if (modelName === "Project" && projectId) {
    typeaheadRecords = [
      typeaheadRecords.find((record: Project) => record.id === projectId),
    ];
  }

  typeaheadRecords = typeaheadRecords.map((record: any) => {
    let node = null;
    const nodes =
      directScopeData.processExplorerData?.nodes || processExplorerData?.nodes;
    if (nodes) {
      node = nodes.find(
        (node) =>
          node.staticPanelKey === `${typeCode}-${record.id}` &&
          node.fullName === record.name,
      );
    }

    return {
      ...record,
      modelName,
      order: node?.order || 0,
      key: node?.key,
    };
  });

  if (["UO", "STP", "PP"].includes(typeCode)) {
    typeaheadRecords.sort(
      (a: DirectScopeRecord, b: DirectScopeRecord) => a.order - b.order,
    );
  }

  const newDirectScopeData = {
    ...directScopeData,
    typeCodeToRecords: {
      ...directScopeData.typeCodeToRecords,
      [typeCode]: typeaheadRecords,
    },
  };
  if (processExplorerData) {
    newDirectScopeData.processExplorerData = processExplorerData;
  }

  return newDirectScopeData;
}

export function mergeData(
  originalData: DirectScopeData,
  newData: DirectScopeData,
) {
  let mergedData = {...originalData};
  // We assume that the process explorer data will be the same for all
  if (!mergedData.processExplorerData) {
    mergedData = {
      ...mergedData,
      processExplorerData: newData.processExplorerData,
    };
  }

  if (!mergedData.typeCodeToRecords) {
    return {
      processExplorerData: mergedData.processExplorerData,
      typeCodeToRecords: newData.typeCodeToRecords,
    };
  }

  for (const [key, records] of Object.entries(newData.typeCodeToRecords)) {
    if (!mergedData.typeCodeToRecords[key]) {
      mergedData.typeCodeToRecords[key] = [...records];
    }
  }
  return mergedData;
}
