"use strict";

import React from "react";
import BasePageTitleBar from "../../widgets/pageTitleBar/base_page_title_bar";

/* This shows the title bar for process linking page */
export default class LinkTechTransferPageTitleBar extends BasePageTitleBar {
  constructor(props) {
    super(props);
  }

  renderTitleBar() {
    const {backLink} = this.props;

    return (
      <div className="page-title-bar-title"
           id="pageTitleBar"
      >
        <span className="page-title-bar-breadcrumbs">
            <a href={backLink}
               id="projectLinkInTitleBar"
            >&larr;</a>
        </span>
        <span className="page-title-bar-primary"
              id="pageTitleBarPrimary"
        >
              Link Processes for Tech Transfer
        </span>
      </div>
    );
  }
}
