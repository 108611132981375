"use strict";
import documentIcon from "./icons/document.png";
import supplierIcon from "./icons/supplier.png";
import rmpIcon from "./icons/rmp.png";

export const HOME_PAGE_TABS_ENUM = {
  FREQUENTLY_VIEWED: {
    title: "Frequently viewed"
  },
  ACTIVITY: {
    title: "Activity"
  }
};

export const TYPE_TO_ICON_ENUM = {
  DOCUMENT: {
    icon: {
      image: documentIcon,
      width: "34px",
      height: "42"
    },
    url: "/documents/list.html"
  },
  SUPPLIER: {
    icon: {
      image: supplierIcon,
      width: "40px",
      height: "41px"
    },
    url: "/suppliers/list.html"
  },
  RMP: {
    icon: {
      image: rmpIcon,
      width: "41px",
      height: "38px"
    },
    url: "/rmps/list.html"
  }
};

export const EMPTY_DATA = {
  pendingApprovals: {
    count: {
      title: "My Approvals Pending",
      value: 0
    },
    stats: [
      {
        title: "Waiting for others",
        count: 0
      },
      {
        title: "Pending Drafts",
        count: 0
      }
    ]
  },
  pendingDrafts: {
    count: {
      title: "Drafts pending",
      value: 0
    },
    stats: [
      {
        title: "My Drafts",
        count: 0
      },
      {
        title: "Other Drafts",
        count: 0
      }
    ]
  },
  trainings: {
    count: {
      title: "My Training Pending",
      value: 0
    },
    stats: [
      {
        title: "Trained in the last month",
        count: 0
      },
    ]
  },
  alerts: {
    count: 0,
    instances: []
  },
  projects: [],
  projectsTotalCount: 0,
  quickAccess: [
    {
      title: "Documents",
      count: 0,
      icon: TYPE_TO_ICON_ENUM["DOCUMENT"].icon,
      url: TYPE_TO_ICON_ENUM["DOCUMENT"].url
    },
    {
      title: "Suppliers",
      count: 0,
      icon: TYPE_TO_ICON_ENUM["SUPPLIER"].icon,
      url: TYPE_TO_ICON_ENUM["SUPPLIER"].url
    },
    {
      title: "Risk Management Plans",
      count: 0,
      icon: TYPE_TO_ICON_ENUM["RMP"].icon,
      url: TYPE_TO_ICON_ENUM["RMP"].url
    }
  ],
  notifications: {
    activities: [],
    frequentlyAccessed: []
  }
};
