"use strict";

import * as React from "react";
import quickPanelIconTiny from "../../images/quickPanel/quick-panel-icon-tiny.png";
import BaseReactComponent from "../../base_react_component";

/**
 * This class is used to provide a link to something that will open in the QuickPanel.
 */
export default class QuickPanelLink extends BaseReactComponent {
  render() {
    // Pull out the last word to make sure it and the icon don't wrap
    const MAX_UNBREAKABLE_WORD_LENGTH = 30;
    const {children} = this.props;
    const allWords = children.split(" ");
    const allWordsExceptLastWord = allWords.slice(0, -1);
    let lastWord = allWords.slice(-1);
    lastWord = lastWord && lastWord[0];

    return (
      <a {...this.props}>
        {allWordsExceptLastWord.join(" ") + " "}
        <span className={lastWord && lastWord.length > MAX_UNBREAKABLE_WORD_LENGTH ? "quick-panel-link-wrap" : "quick-panel-link-no-wrap"}>
          {lastWord}
          <img className="quick-panel-icon"
               src={quickPanelIconTiny}
               alt="Click to open in the quick edit panel"
          />
        </span>
      </a>
    );
  }
}
