"use strict";
import React from "react";
import { debounce } from "lodash";

import BaseAttribute from "./base_attribute";
import { createHTMLForTextDiff } from "../../helpers/diff_helper";

class TextAreaAttribute extends BaseAttribute {
  constructor(props) {
    super(props);

    this.state = {
      currentValue: this.getValue() || null, prevValueFromParent: null, gotValueFromParent: false,
    };

    // Bind functions to this
    this.renderInput = this.renderInput.bind(this);
    this.debouncedHandleInputChange = debounce(this.debouncedHandleInputChange, 300);
  }

  handleInputChange(event) {
    this.setStateSafely({currentValue: event.target.value}, () => this.forceUpdate());
    this.debouncedHandleInputChange();
  }

  debouncedHandleInputChange() {
    this.handleChange({
      target: {
        value: this.state.currentValue
      }
    });
  }

  // noinspection JSMethodCanBeStatic
  getInitialValue() {
    return this.props.value || "";
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      let value = this.getNextValue(nextProps);
      return (this.currentValue !== value) ||
        (nextProps.type !== this.props.type) ||
        (nextProps.rows !== this.props.rows);
    }
    return true;
  }

  componentDidUpdate() {
    const {currentValue, gotValueFromParent, prevValueFromParent} = this.state;
    const valueFromParent = this.props.parent?.state[this.getAttributeName()];
    const shouldInitiateStateFirstTime = !gotValueFromParent && valueFromParent && currentValue === null;
    const isValueChangedFromOutside = currentValue !== valueFromParent && currentValue === prevValueFromParent;
    const isValueChangedFromInside = currentValue === valueFromParent && prevValueFromParent !== valueFromParent;

    if (shouldInitiateStateFirstTime) {
      //This will run only this first time to initiate the state with the value that comes from the parent. This value takes time to be passes here that's why we use componentDidUpdate.
      this.setStateSafely({
        currentValue: valueFromParent, prevValueFromParent: valueFromParent, gotValueFromParent: true
      }, () => this.forceUpdate());
    } else if (isValueChangedFromOutside) {
      // This should run when changes comes from outside, like when we change measure dropdown then it will change the text attribute value
      this.setStateSafely({
        currentValue: valueFromParent,
        prevValueFromParent: valueFromParent
      }, () => this.forceUpdate());
    } else if (isValueChangedFromInside) {
      // This should run after we finish typing and debounce function runs and update the parent's value, then we just need to update the prev value of the prop
      this.setStateSafely({prevValueFromParent: valueFromParent});
    }
  }

  renderInput() {
    let input = "Input type not defined: " + this.props.type;
    let inputId = this.getInputId();

    this.currentValue = this.getValue() ? this.getValue() : "";
    if (this.isView()) {
      let innerInput;
      if (this.isDiffingVersions()) {
        let oldValue = this.getOldValue(this.props.name);
        innerInput = createHTMLForTextDiff(oldValue, this.state.currentValue);
      } else {
        innerInput = (<span id={inputId}>{this.state.currentValue}</span>);
      }

      input = (
        <div className="textarea-attribute-view">
          {innerInput}
        </div>
      );
    } else {
      let dataErrorMsg = this.getCapitalizedName() + " is required.";
      input = (<textarea className="form-control"
                         id={inputId}
                         data-validate="true"
                         data-error={dataErrorMsg}
                         rows={this.props.rows}
                         value={this.state.currentValue || ""}
                         disabled={this.isDisabled()}
                         onChange={this.handleInputChange}
                         required={this.isRequired()}
      />);
    }

    return input;
  }
}

TextAreaAttribute.defaultProps.rows = 3;

export default TextAreaAttribute;
