var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { getNestedValue } from './../utils';
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
import { useLocalization } from '@progress/kendo-react-intl';
import { Keys } from '@progress/kendo-react-common';
import { detailCollapse, detailExpand, messages } from '../messages';
/**
 * @hidden
 */
export var GridHierarchyCell = function (props) {
    var defaultRendering = null;
    var expanded = getNestedValue(props.field, props.dataItem);
    var navigationAttributes = useTableKeyboardNavigation(props.id);
    var localization = useLocalization();
    var onKeyDownHandler = React.useCallback(function (event) {
        if (event.isDefaultPrevented()) {
            return;
        }
        if (event.keyCode === Keys.enter && props.onChange) {
            event.preventDefault();
            props.onChange({
                dataItem: props.dataItem,
                dataIndex: props.dataIndex,
                syntheticEvent: event,
                field: props.field,
                value: !expanded
            });
        }
    }, [props.dataItem, props.dataIndex, props.field, props.onChange, expanded]);
    if (props.rowType === 'groupFooter') {
        defaultRendering = React.createElement("td", __assign({ className: "k-hierarchy-cell" }, navigationAttributes));
    }
    else if (props.rowType !== 'groupHeader') {
        var className = expanded ? 'k-icon k-i-minus' : 'k-icon k-i-plus';
        var baseMessage = expanded ? detailCollapse : detailExpand;
        var message = localization.toLanguageString(baseMessage, messages[baseMessage]);
        defaultRendering = (React.createElement("td", __assign({ className: "k-hierarchy-cell", onKeyDown: onKeyDownHandler, "aria-expanded": expanded ? 'true' : 'false', role: 'gridcell', "aria-colindex": props.ariaColumnIndex }, navigationAttributes),
            React.createElement("a", { onClick: function (e) {
                    e.preventDefault();
                    if (props.onChange) {
                        props.onChange({
                            dataItem: props.dataItem,
                            dataIndex: props.dataIndex,
                            syntheticEvent: e,
                            field: props.field,
                            value: !expanded
                        });
                    }
                }, "aria-label": message, className: className, href: "#", tabIndex: -1 })));
    }
    return props.render ?
        props.render.call(undefined, defaultRendering, props) :
        defaultRendering;
};
