"use strict";

export const FIELD_INPUT_TYPE = {
  text: "text",
  link: "link",
  radio: "radiobutton",
};
/**
 * @type {IWidgetField[]}
 */
export const WIDGET_FIELDS = [
  {
    fieldName: "link",
    displayName: "Supporting Document",
    order: 1,
    defaultValue: "",
    tooltipText: "",
    textDecoration: false,
    dynamicToolTip: false,
    inputType: FIELD_INPUT_TYPE.link
  },
  {
    fieldName: "appliesTo",
    displayName: "Applies To Risk(s)",
    order: 2,
    defaultValue: [],
    tooltipText: "",
    textDecoration: true,
    dynamicToolTip: true,
    inputType: FIELD_INPUT_TYPE.text,
  },
  {
    fieldName: "update",
    displayName: "Update",
    order: 3,
    defaultValue: false,
    tooltipText: "",
    disabled: false,
    textDecoration: false,
    dynamicToolTip: false,
    inputType: FIELD_INPUT_TYPE.radio,
  },
  {
    fieldName: "removeAppliesTo",
    displayName: "Remove applies to",
    order: 4,
    defaultValue: false,
    tooltipText: "",
    disabled: false,
    textDecoration: false,
    dynamicToolTip: false,
    inputType: FIELD_INPUT_TYPE.radio,
  },
  {
    fieldName: "remove",
    displayName: "Remove",
    order: 5,
    defaultValue: false,
    tooltipText: "",
    disabled: false,
    textDecoration: false,
    dynamicToolTip: false,
    inputType: FIELD_INPUT_TYPE.radio,
  },
];

export const UPDATE_ROW_OPTIONS = {
  update: true,
  remove: false,
  removeAppliesTo: false,
};

