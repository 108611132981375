"use strict";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearchMinus,
  faSearchPlus,
  faExpandArrowsAlt,
  faExpandAlt,
  faCompressAlt
} from "@fortawesome/free-solid-svg-icons";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseReactComponent from "../../base_react_component";

export const MAX_NODES_TO_EXPAND_OR_COLLAPSE = 500;

/**
 *  This shows the toolbar on the bottom of the diagram
 */
// i18next-extract-mark-ns-start process_explorer
class DiagramBottomBar extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      shouldExpand: true,
    };
  }

  render() {
    const isTooBigToExpandOrCollapse = this.props.numberOfNodes > MAX_NODES_TO_EXPAND_OR_COLLAPSE;
    const t = this.props.t;
    const shouldExpand = this.state.shouldExpand;
    let expandOrContractTitle = shouldExpand ? t("Expand all nodes") : t("Collapse all nodes");
    expandOrContractTitle = isTooBigToExpandOrCollapse ? t("Expand/Collapse is unavailable for processes of this size") : expandOrContractTitle;
    return (
      <div className="procexp-bottom-bar">
        <FontAwesomeIcon id="processExplorerZoomInButton"
                         icon={faSearchPlus}
                         className="procexp-bottom-bar-button"
                         aria-label={t("Zoom in")}
                         title={t("Zoom in")}
                         onClick={this.props.onZoomIn}
        />
        <FontAwesomeIcon id="processExplorerZoomOutButton"
                         icon={faSearchMinus}
                         className="procexp-bottom-bar-button"
                         aria-label={t("Zoom out")}
                         title={t("Zoom out")}
                         onClick={this.props.onZoomOut}
        />
        <FontAwesomeIcon id="processExplorerZoomExpandButton"
                         icon={faExpandArrowsAlt}
                         className="procexp-bottom-bar-button"
                         aria-label={t("Reset Zoom")}
                         title={t("Reset Zoom")}
                         onClick={this.props.onZoomExpand}
        />
        <FontAwesomeIcon id="processExplorerExpandOrCollapseButton"
                         icon={shouldExpand ? faExpandAlt : faCompressAlt}
                         className={"procexp-bottom-bar-button" + (isTooBigToExpandOrCollapse ? " anchor-disabled" : "")}
                         aria-label={expandOrContractTitle}
                         title={expandOrContractTitle}
                         onClick={() => {
                           if (!isTooBigToExpandOrCollapse) {
                             shouldExpand ? this.props.onExpandAll() : this.props.onCollapseAll();
                             this.setStateSafely(prevState => ({shouldExpand: !prevState.shouldExpand}));
                           }
                         }}
        />
      </div>
    );
  }

}

export default I18NWrapper.wrap(DiagramBottomBar, "process_explorer");
// i18next-extract-mark-ns-stop process_explorer
