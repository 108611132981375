"use strict";
import { TEXT_DIFF_METHOD_ENUM } from "../../helpers/constants/constants";
import { FIELD_INPUT_TYPE, FIELD_REQUIRED_FOR } from "../../editor/widgets/widget_field";

/**
 * @type {IWidgetField[]}
 */
export const WIDGET_FIELDS = [
  {
    fieldName: "link",
    displayName: "Link or Attachment",
    requiredFor: FIELD_REQUIRED_FOR.proposal,
    forceUpdate: false,
    defaultValue: "",
    belongsToMasterRow: true,
    order: 1,
    inputType: FIELD_INPUT_TYPE.link,
  },
  {
    fieldName: "name",
    diffMethod: TEXT_DIFF_METHOD_ENUM.differential,
    displayName: "Name",
    requiredFor: FIELD_REQUIRED_FOR.save,
    forceUpdate: false,
    defaultValue: "",
    placeholder: "",
    belongsToMasterRow: true,
    order: 2,
    inputType: FIELD_INPUT_TYPE.text,
  },
  {
    fieldName: "description",
    diffMethod: TEXT_DIFF_METHOD_ENUM.differential,
    displayName: "Description",
    requiredFor: FIELD_REQUIRED_FOR.none,
    forceUpdate: false,
    defaultValue: "",
    placeholder: "",
    belongsToMasterRow: true,
    order: 3,
    rows: 4,
    inputType: FIELD_INPUT_TYPE.textarea,
  }
];
