"use strict";

import * as UIUtils from "../../../../../ui_utils";
import React, { Fragment } from "react";
import TypeaheadEditor from "../editors/typeahead_editor";
import { PAPER_DATA_UPDATE_TYPE } from "../../../../constants/import_constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faTrash } from "@fortawesome/free-solid-svg-icons";
import MeasurementEditor from "./measurement_editor";
import { isQualitativeMeasure } from "../../../../../../server/common/editables/common_editables";
import BaseReactComponent from "../../../../../base_react_component";

/**
 * This is responsible for rendering the qualitative and quantitative measurements for a single attribute
 * in the AI import batch data edit screen.
 */
export default class AttributeMeasurementsEditor extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      numberOfMeasurements: this.props.attribute ? this.props.attribute.measurements.length : 0
    };
  }

  shouldComponentUpdate(nextProps) {
    const {attribute, index} = this.props;
    const nextAttribute = nextProps.attribute;
    const nextIndex = nextProps.index;

    return attribute.fullName !== nextAttribute.fullName
      || (attribute.validationStatus && attribute.validationStatus.error) !== (nextAttribute.validationStatus && nextAttribute.validationStatus.error)
      || index !== nextIndex
      || attribute.measurements.length !== nextAttribute.measurements.length
      || attribute.measurements.map(m => m.measurement).join(",") !== nextAttribute.measurements.map(m => m.measurement).join(",")
      || attribute.measurements.map(m => m.validationStatus.error).join(",") !== nextAttribute.measurements.map(m => m.validationStatus.error).join(",");
  }

  componentDidUpdate(prevProps, prevState, snapshotIgnore) {
    const {attribute} = this.props;
    const {numberOfMeasurements} = this.state;
    const previousNumberOfMeasurements = prevState.numberOfMeasurements;
    if (numberOfMeasurements > previousNumberOfMeasurements) {
      let measurementEditorId = `#Row_${this.props.index}_MeasurementEditor_${attribute.measurements.length - 1}`;
      $(measurementEditorId).focus();
    }
  }

  handleTypeaheadChange(attribute, newValue) {
    this.setStateSafely({
      numberOfMeasurements: 0
    }, () => {
      this.props.onChange({
        type: PAPER_DATA_UPDATE_TYPE.MODIFY_ATTRIBUTE,
        attributeUUID: attribute.uuid
      }, newValue);
    });
  }

  handleMeasurementChange(updateMetaData, newValue) {
    const {type} = updateMetaData;
    this.props.onChange(updateMetaData, newValue, () => {
      if (type === PAPER_DATA_UPDATE_TYPE.DELETE_MEASUREMENT) {
        this.setStateSafely({
          numberOfMeasurements: this.props.attribute.measurements.length - 1
        });
      }
    });
  }

  handleAddNewMeasurementButtonClick(updateMetaData, newValue) {
    this.props.onChange(updateMetaData, newValue, () => {
      this.setStateSafely({
        numberOfMeasurements: this.props.attribute.measurements.length + 1
      });
    });
  }

  render() {
    const {attribute, typeCode, index, dataAttributes, disabled, onChange} = this.props;
    const newMeasurementDefaultValue = isQualitativeMeasure(attribute.type) ? "Pass" : "";
    const newMeasurementAddMetaData = {
      type: PAPER_DATA_UPDATE_TYPE.ADD_MEASUREMENT,
      attributeUUID: attribute.uuid,
    };

    const nameKey = "name";
    let allAttributeOptions = dataAttributes ? dataAttributes.map(attribute => {
      return {
        id: UIUtils.getRecordLabelForDisplay(typeCode, attribute.id, attribute[nameKey]),
        label: attribute[nameKey]
      };
    }) : [];

    let matchingTypeaheadAttribute = dataAttributes.find(dataAttribute =>
      UIUtils.getRecordLabelForDisplay(typeCode, dataAttribute.id, dataAttribute[nameKey]) === attribute.fullName);
    attribute.lowerLimit = matchingTypeaheadAttribute && UIUtils.isNumber(matchingTypeaheadAttribute.lowerLimit)
      ? Number(matchingTypeaheadAttribute.lowerLimit)
      : "";
    attribute.upperLimit = matchingTypeaheadAttribute && UIUtils.isNumber(matchingTypeaheadAttribute.upperLimit)
      ? Number(matchingTypeaheadAttribute.upperLimit)
      : "";
    let measurementsIndex = 0;

    return (
      <div className="row attribute-measurements-row">
        <div className="col-6 attribute-measurement typeahead">
          <TypeaheadEditor id={`Row_${index}_AttributeEditor`}
                           options={allAttributeOptions}
                           value={attribute.fullName}
                           validationStatus={attribute.validationStatus}
                           disabled={disabled}
                           confidence={attribute.confidence}
                           onChange={this.handleTypeaheadChange.bind(this, attribute)}
          />
        </div>
        <div className="col-1 attribute-read-only-container">
          <span id={`LSL_${index}_Span`}>{attribute.lowerLimit}</span>
        </div>
        <div className="col-1 attribute-read-only-container">
          <span id={`USL_${index}_Span`}>{attribute.upperLimit}</span>
        </div>
        <div className="col-4 attribute-measurement-container">
          <Fragment>
            {attribute.measurements.map(measurement => {
              return (
                <MeasurementEditor key={measurementsIndex++}
                                   id={`Row_${index}_MeasurementEditor_${measurementsIndex}`}
                                   attribute={attribute}
                                   type="Editor"
                                   measurement={measurement}
                                   index={measurementsIndex}
                                   disabled={disabled}
                                   onChange={this.handleMeasurementChange}
                />
              );
            })}
            <div className="add-attribute-measurement">
              <input key={measurementsIndex++}
                     readOnly={true}
                     id={`AddMeasurementButton_${index}`}
                     className="form-control add-measurement-button"
                     onClick={disabled ? null : this.handleAddNewMeasurementButtonClick.bind(this,
                       newMeasurementAddMetaData, newMeasurementDefaultValue)}
                     onFocus={disabled ? null : this.handleAddNewMeasurementButtonClick.bind(this,
                       newMeasurementAddMetaData, newMeasurementDefaultValue)}
                     placeholder="+ Add"
              />
            </div>
          </Fragment>
          <div className="delete-attribute-button dropdown"
               title="Delete attribute and measurements"
          >
            <span id={`Row_${index}_OptionsButton`}
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </span>
            <div className="dropdown-menu dropdown-menu-right"
                 aria-labelledby={`Row_${index}_OptionsButton`}
            >
              <div id={`Row_${index}_DeleteOptionButton`}
                   className="dropdown-item"
                   onClick={disabled ? null : onChange.bind(this, {
                     type: PAPER_DATA_UPDATE_TYPE.DELETE_ATTRIBUTE,
                     attributeUUID: attribute.uuid
                   })}
              >
                <span>
                  <FontAwesomeIcon icon={faTrash} />
                </span> Delete
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
