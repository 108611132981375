"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import { IMPORT_STEPS } from "../../constants/import_constants";
import BaseReactComponent from "../../../base_react_component";

export default class ImportWizardNavBar extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let index = 1;

    return (
      <div className="row import-nav-tabs">
        {
          this.props.wizardStepsConfig.map(wizardStepConfig => {
            let caption = wizardStepConfig.step !== IMPORT_STEPS.DependencySelection
              ? wizardStepConfig.step
              : `${UIUtils.convertCamelCaseToSpacedOutWords(this.props.dependency)} Selection`;
            return (
              <div key={wizardStepConfig.navTabId}
                   role="presentation"
                   className={"col-auto import-nav-tab align-items-center" + (this.props.currentImportStep === wizardStepConfig.step ? " active" : "")}
              >
                <a onClick={() => {
                  if (!wizardStepConfig.disabled) {
                    this.props.onTabNavItemClicked(wizardStepConfig.step);
                  }
                }}
                   id={wizardStepConfig.navTabId}
                   className={wizardStepConfig.disabled ? "import-nav-inactive" : ""}
                >
                  {`${index++}- ${caption}`}
                </a>
              </div>);
          })
        }
      </div>
    );
  }
}

