var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Popup } from '@progress/kendo-react-popup';
/**
 * The GridColumnMenuWrapper component.
 */
var GridColumnMenuWrapper = /** @class */ (function (_super) {
    __extends(GridColumnMenuWrapper, _super);
    function GridColumnMenuWrapper() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /** @hidden */
        _this.state = {
            show: false
        };
        /** @hidden */
        _this._anchor = null;
        /** @hidden */
        _this._content = null;
        /** @hidden */
        _this.blurTimeout = undefined;
        /** @hidden */
        _this.willBlur = false;
        /** @hidden */
        _this.blur = function () {
            if (_this.willBlur) {
                _this.willBlur = false;
                return;
            }
            clearTimeout(_this.blurTimeout);
            _this.blurTimeout = window.setTimeout(function () { _this.closeMenu(); });
        };
        /** @hidden */
        _this.focus = function () {
            clearTimeout(_this.blurTimeout);
        };
        /** @hidden */
        _this.anchorClick = function () {
            _this.setState({ show: !_this.state.show }, function () { return _this.state.show && _this._content && _this._content.focus(); });
        };
        /** @hidden */
        _this.closeMenu = function () {
            _this.setState({ show: false });
        };
        /** @hidden */
        _this.onAnchorMouseDown = function (event) {
            _this.willBlur = _this.state.show && event.currentTarget === _this._anchor;
        };
        return _this;
    }
    GridColumnMenuWrapper.prototype.render = function () {
        var _this = this;
        var _a = this.props, ColumnMenu = _a.columnMenu, others = __rest(_a, ["columnMenu"]);
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: 'k-grid-header-menu k-grid-column-menu', ref: function (e) { return _this._anchor = e; }, onClick: this.anchorClick, onMouseDown: this.onAnchorMouseDown },
                React.createElement("span", { className: 'k-icon k-i-more-vertical' })),
            React.createElement(Popup, { anchor: this._anchor, show: this.state.show },
                React.createElement("div", { ref: function (e) { return _this._content = e; }, className: 'k-grid-columnmenu-popup', tabIndex: 0, onBlur: this.blur, onFocus: this.focus, style: { outline: 'none' } }, ColumnMenu && (React.createElement(ColumnMenu, __assign({}, others, { onCloseMenu: this.closeMenu })))))));
    };
    return GridColumnMenuWrapper;
}(React.Component));
export { GridColumnMenuWrapper };
