"use strict";

import * as UIUtils from "../ui_utils";
import { SUBSCRIBE_NOW_URL } from "../widgets/bars/trial_bar";
import React from "react";
import BaseReactComponent from "../base_react_component";
import QbDVisionLogo from "../images/logo.svg";

/* This class shows when the user's trial has expired. */
export default class TrialExpiredPage extends BaseReactComponent {

  componentDidMount() {
    document.title = "QbDVision Trial Expired";

    super.componentDidMount();
  }

  static handleSubscribeNow() {
    window.location.href = UIUtils.getSecuredURL(SUBSCRIBE_NOW_URL);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row login-jumbotron">
          <img src={QbDVisionLogo}
               width={256} height={256}
               className="mx-auto d-block"
               alt="QbDVision Logo"
          />
        </div>

        <div className="row">
          <div className="col-sm-4 offset-4 text-center">
            <h1 className="text-center">
              Oh no! Your trial has expired.
            </h1>
            <p className="trial-expired-text">
              Don&apos;t fret. This is the perfect time to upgrade to a paid plan.
            </p>
            <button className="btn btn-lg btn-primary "
                    id="subscribeNowButton"
                    onClick={TrialExpiredPage.handleSubscribeNow}
            >
              Subscribe Now
            </button>
            <a href="mailto:sales@qbdvision.com" className="trial-expired-contact-sales">
              Contact Sales
            </a>
            <p className="trial-expired-text">
              P.S. We&apos;ll hold on to your data for at least 90 days in case you need to come back.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
