"use strict";

export const SMALL_COLUMN = 80;
export const MEDIUM_COLUMN = 100;
export const LARGE_COLUMN = 150;
export const EXTRA_LARGE_COLUMN = 200;
export const TEXT_COLUMN = 300;

export const FILTER_OPERATORS = {
  contains: "contains",
  doesnotcontain: "does not contain",
  eq: "=",
  neq: "!=",
  startswith: "starts with",
  endswith: "ends with",
  isempty: "is empty",
  isnotempty: "is not empty",
  gte: ">=",
  gt: ">",
  lte: "<=",
  lt: "<"
};

export const FORMATTED_FIELD_LABEL = "FormattedValue";
export const NOT_STARTED = "Not started";
export const VARIABLE_PARENTS = ["PRC", "MT"];
export const VARIABLE_TYPES = ["PP", "MA", "IQA", "IPA"];
export const RISK_RANKING_ENABLED_MODELS = ["Quality Attributes", "Performance Attributes"];

/*
When you have changes in Dynamic Tables columns, this version should be increased or
local storage would still use the old version with the old columns before your change
 */
export const LOCAL_STORAGE_VERSION = 11;

export const EMPTY_OPTION = {
  disabled: true,
  key: "EMPTY",
  title: "",
  value: ""
};
export const ALL_OPTION = {
  disabled: false,
  key: "All",
  title: "All",
  value: "All"
};
export const EXPORT_WORD_CHARS_LIMIT = 50;
