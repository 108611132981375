"use strict";

import * as UIUtils from "../../ui_utils";
import { CompositeDataTransform } from "./composite_data_transform";

/**
 * Creates a data transform that will execute a series of other transforms in the order they are declared
 * and add each of the transform output as a prop of that object with the name of the transform.
 */
export class BuildUpDataTransform extends CompositeDataTransform {

  transform(input, options) {
    let result = {};
    for (let currentTransform of this.children) {
      if (currentTransform.shouldRun(options)) {
        let transformReportType = `${currentTransform.type}${currentTransform.defaultStrategy || ""}`;
        result[transformReportType] = currentTransform.transform(UIUtils.deepClone(input), options);

        if (result[transformReportType].instances) {
          let allRecordTypes = currentTransform.getSupportedRecordTypes();
          if (allRecordTypes) {
            let instances = result[transformReportType].instances;
            for (const prop in instances) {
              if (Object.prototype.hasOwnProperty.call(instances, prop) && !allRecordTypes.includes(prop)) {
                delete instances[prop];
              }
            }
          }
        }
      }
    }
    return result;
  }

  get type() {
    return "buildUp";
  }
}
