"use strict";

import {
  generateDocumentsValue,
  generateRiskLinksValue,
  generateRiskValue, generateVersionValue,
} from "../export/configurable_tables_export_value_generator";
import { RISK_TYPE_ENUM } from "../../helpers/constants/constants";
import { getClassName } from "../../techTransfer/tables/tech_transfer_table_helper";
import { FIELD_PROPS } from "./constants/configurable_tables_field_props_config";
import {
  ABOUT_SECTION,
  ACCEPTANCE_CRITERIA_SECTION,
  CRITICALITY_ASSESSMENT_SECTION, PROCESS_CAPABILITY_SECTION, REFERENCES_SECTION, RISK_CONTROL_SECTION,
} from "./constants/configurable_tables_sections_config";
import {
  COMMON_VARIABLE_COLUMNS,
  HIDDEN,
  RECEIVING_SITE_COMMON_COLUMNS_PROPS,
  SENDING_SITE_COMMON_COLUMNS_PROPS, TECH_TRANSFER_ASSESSMENT_COLUMNS,
} from "./constants/configurable_tables_columns_config";
import { FILTER_TYPES } from "../columnOperations/configurable_tables_column_operations_constants";
import {
  ABOUT_COL_SPAN,
  ACCEPTANCE_CRITERIA_COL_SPAN,
  CRITICALITY_ASSESSMENT_COL_SPAN, PROCESS_CAPABILITY_COL_SPAN, REFERENCE_AND_STANDARD_COL_SPAN, RISK_CONTROL_COL_SPAN
} from "./constants/configurable_tables_col_spans_config";
import CommonEditables from "../../../server/common/editables/common_editables";

/* This configuration file contains the main rules for each model type. That includes
- prop: this fields will be used by filtering and sorting inside the table
- value: this fields will be used by exporting (to excel)
- tableValue: this fields will be used for rendering the table
*/

export default class RecordFields {

  constructor(title = null, modelType = null, isTechTransfer = false) {
    this._modelType = modelType;
    this._title = title;
    this._isTechTransfer = isTechTransfer;
  }

  filterTechTransferFields(fields) {
    return fields.filter(field => this._isTechTransfer ? field.title !== "Version" : true);
  }

  get title() {
    return this._title;
  }

  get modelType() {
    return this._modelType;
  }

  get aboutSectionFields() {
    return this.filterTechTransferFields([
      // About
      {
        title: "ID",
        prop: FIELD_PROPS.ID,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        showColumnSelectionOn: HIDDEN,
        tableValue: (columnGenerator) => columnGenerator.generateIdColumn(),
      },
      {
        title: "Name",
        prop: FIELD_PROPS.NAME,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) => columnGenerator.generateNameColumn("Name", FIELD_PROPS.NAME, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Name",
        prop: FIELD_PROPS.NAME,
        colspan: ABOUT_COL_SPAN,
        tableValue: (columnGenerator, process) => columnGenerator.generateNameColumn("Name", FIELD_PROPS.NAME, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Version",
        prop: FIELD_PROPS.VERSION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateVersionValue,
        tableValue: (columnGenerator, process) => columnGenerator.generateVersionColumn("Version", process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Version",
        prop: FIELD_PROPS.VERSION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        value: generateVersionValue,
        tableValue: (columnGenerator, process) => columnGenerator.generateVersionColumn("Version", process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Unit Operation",
        prop: FIELD_PROPS.UNIT_OPERATION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        sortByOrder: true,
        filterType: FILTER_TYPES.LOOKUP,
        value: (record) => record[FIELD_PROPS.UNIT_OPERATION_NAME],
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateParentColumn("Unit Operation", FIELD_PROPS.UNIT_OPERATION, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Unit Operation",
        prop: FIELD_PROPS.UNIT_OPERATION,
        colspan: ABOUT_COL_SPAN,
        sortByOrder: true,
        filterType: FILTER_TYPES.LOOKUP,
        value: (record) => record[FIELD_PROPS.UNIT_OPERATION_NAME],
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateParentColumn("Unit Operation", FIELD_PROPS.UNIT_OPERATION, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Step",
        prop: FIELD_PROPS.STEP,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        sortByOrder: true,
        filterType: FILTER_TYPES.LOOKUP,
        value: (record) => record[FIELD_PROPS.STEP_NAME],
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateParentColumn("Step", FIELD_PROPS.STEP, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Step",
        prop: FIELD_PROPS.STEP,
        colspan: ABOUT_COL_SPAN,
        sortByOrder: true,
        filterType: FILTER_TYPES.LOOKUP,
        value: (record) => record[FIELD_PROPS.STEP_NAME],
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateParentColumn("Step", FIELD_PROPS.STEP, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Type",
        prop: FIELD_PROPS.TYPE,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Type",
        prop: FIELD_PROPS.TYPE,
        colspan: ABOUT_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Description",
        prop: FIELD_PROPS.DESCRIPTION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextAreaColumn("Description", FIELD_PROPS.DESCRIPTION, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Description",
        colspan: ABOUT_COL_SPAN,
        prop: FIELD_PROPS.DESCRIPTION,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextAreaColumn("Description", FIELD_PROPS.DESCRIPTION, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
    ]);
  }

  get criticalityAssessmentSectionFields() {
    return [
      // Criticality Assessment
      {
        title: "Potential Failure Modes",
        prop: FIELD_PROPS.POTENTIAL_FAILURES,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultTechTransferValue: true,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextAreaColumn("Potential Failure Modes", FIELD_PROPS.POTENTIAL_FAILURES, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Potential Failure Modes",
        prop: FIELD_PROPS.POTENTIAL_FAILURES,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextAreaColumn("Potential Failure Modes", FIELD_PROPS.POTENTIAL_FAILURES, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Risk Links",
        prop: FIELD_PROPS.RISK_LINKS,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultTechTransferValue: true,
        defaultChangeCriteriaValue: false,
        value: generateRiskLinksValue,
        tableValue: (columnGenerator, process) => columnGenerator.generateRiskLinksColumn(process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Risk Links",
        prop: FIELD_PROPS.RISK_LINKS,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        value: generateRiskLinksValue,
        tableValue: (columnGenerator, process) => columnGenerator.generateRiskLinksColumn(process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Criticality (Raw)",
        prop: FIELD_PROPS.MAX_CRITICALITY,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.MAX_CRITICALITY),
        value: generateRiskValue.bind(this, FIELD_PROPS.MAX_CRITICALITY, RISK_TYPE_ENUM.CRITICALITY),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateRiskColumn("Criticality (Raw)", FIELD_PROPS.MAX_CRITICALITY,
            RISK_TYPE_ENUM.CRITICALITY, getClassName(FIELD_PROPS.MAX_CRITICALITY),
            process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Criticality (Raw)",
        prop: FIELD_PROPS.MAX_CRITICALITY,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterLabel: `${FIELD_PROPS.MAX_CRITICALITY}Label`,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.MAX_CRITICALITY, true),
        value: generateRiskValue.bind(this, FIELD_PROPS.MAX_CRITICALITY, RISK_TYPE_ENUM.CRITICALITY, true),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateRiskColumn("Criticality (Raw)", FIELD_PROPS.MAX_CRITICALITY,
            RISK_TYPE_ENUM.CRITICALITY, getClassName(FIELD_PROPS.MAX_CRITICALITY, true),
            process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Criticality (%)",
        prop: FIELD_PROPS.CRITICALITY_PERCENTAGE,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.CRITICALITY_PERCENTAGE),
        value: generateRiskValue.bind(this, FIELD_PROPS.CRITICALITY_PERCENTAGE, RISK_TYPE_ENUM.CRITICALITY),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateRiskColumn("Criticality (%)", FIELD_PROPS.CRITICALITY_PERCENTAGE,
            RISK_TYPE_ENUM.CRITICALITY, getClassName(FIELD_PROPS.CRITICALITY_PERCENTAGE),
            process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Criticality (%)",
        prop: FIELD_PROPS.CRITICALITY_PERCENTAGE,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.CRITICALITY_PERCENTAGE, true),
        value: generateRiskValue.bind(this, FIELD_PROPS.CRITICALITY_PERCENTAGE, RISK_TYPE_ENUM.CRITICALITY),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateRiskColumn("Criticality (%)", FIELD_PROPS.CRITICALITY_PERCENTAGE,
            RISK_TYPE_ENUM.CRITICALITY, getClassName(FIELD_PROPS.CRITICALITY_PERCENTAGE, true),
            process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Recommended Actions",
        prop: FIELD_PROPS.RECOMMENDED_ACTIONS,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextAreaColumn("Recommended Actions", FIELD_PROPS.RECOMMENDED_ACTIONS, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Recommended Actions",
        prop: FIELD_PROPS.RECOMMENDED_ACTIONS,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextAreaColumn("Recommended Actions", FIELD_PROPS.RECOMMENDED_ACTIONS, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Criticality Assessment Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.CRITICALITY_ASSESSMENT_LINKS,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.CRITICALITY_ASSESSMENT_LINKS),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Criticality Assessment Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.CRITICALITY_ASSESSMENT_LINKS,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.CRITICALITY_ASSESSMENT_LINKS, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
    ];
  }

  get acceptanceCriteriaSectionFields() {
    return [
      // Acceptance Criteria
      {
        title: "Data Space",
        prop: FIELD_PROPS.DATA_SPACE,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        section: ACCEPTANCE_CRITERIA_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Data Space",
        prop: FIELD_PROPS.DATA_SPACE,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Measure",
        prop: FIELD_PROPS.MEASURE,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        section: ACCEPTANCE_CRITERIA_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Measure",
        prop: FIELD_PROPS.MEASURE,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Measurement Units",
        prop: FIELD_PROPS.MEASUREMENT_UNITS,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        section: ACCEPTANCE_CRITERIA_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Measurement Units",
        prop: FIELD_PROPS.MEASUREMENT_UNITS,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Lower Limit",
        prop: FIELD_PROPS.LOWER_LIMIT,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        section: ACCEPTANCE_CRITERIA_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.TEXT,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Lower Limit",
        prop: FIELD_PROPS.LOWER_LIMIT,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        filterType: FILTER_TYPES.TEXT,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Target",
        prop: FIELD_PROPS.TARGET,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        section: ACCEPTANCE_CRITERIA_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.TEXT,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Target",
        prop: FIELD_PROPS.TARGET,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        filterType: FILTER_TYPES.TEXT,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Upper Limit",
        prop: FIELD_PROPS.UPPER_LIMIT,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        section: ACCEPTANCE_CRITERIA_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.TEXT,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Upper Limit",
        prop: FIELD_PROPS.UPPER_LIMIT,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        filterType: FILTER_TYPES.TEXT,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Control Methods",
        prop: FIELD_PROPS.CONTROL_METHODS,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        section: ACCEPTANCE_CRITERIA_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterLogic: "contains",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnAdapter) =>
          columnAdapter.getObjectListFilterOptions(records, FIELD_PROPS.CONTROL_METHODS),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateObjectListColumn(process, FIELD_PROPS.CONTROL_METHODS, "CM", "Control Methods"),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Control Methods",
        prop: FIELD_PROPS.CONTROL_METHODS,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateObjectListColumn(process, FIELD_PROPS.CONTROL_METHODS, "CM", "Control Methods", true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Sampling Plan",
        prop: FIELD_PROPS.SAMPLING_PLAN,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        section: ACCEPTANCE_CRITERIA_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
        filterOptions: () => CommonEditables.BOOLEAN_VALUES,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("Sampling Plan", FIELD_PROPS.SAMPLING_PLAN)
      },
      {
        title: "Sampling Plan",
        prop: FIELD_PROPS.SAMPLING_PLAN,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
        filterOptions: () => CommonEditables.BOOLEAN_VALUES,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("Sampling Plan", FIELD_PROPS.SAMPLING_PLAN, true),
      },
      {
        title: "Target Justification",
        prop: FIELD_PROPS.TARGET_JUSTIFICATION,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        section: ACCEPTANCE_CRITERIA_SECTION,
        defaultTechTransferValue: false,
        defaultValue: false,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextColumn("Target Justification", FIELD_PROPS.TARGET_JUSTIFICATION, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Target Justification",
        prop: FIELD_PROPS.TARGET_JUSTIFICATION,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextColumn("Target Justification", FIELD_PROPS.TARGET_JUSTIFICATION, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Acceptance Criteria Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.ACCEPTANCE_CRITERIA_LINKS,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        section: ACCEPTANCE_CRITERIA_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.ACCEPTANCE_CRITERIA_LINKS),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Acceptance Criteria Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.ACCEPTANCE_CRITERIA_LINKS,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.ACCEPTANCE_CRITERIA_LINKS, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
    ];
  }

  get processCapabilitySectionFields() {
    return [
      // Process Capability
      {
        title: "Capability Risk",
        prop: FIELD_PROPS.CAPABILITY_RISK,
        colspan: PROCESS_CAPABILITY_COL_SPAN,
        section: PROCESS_CAPABILITY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        isRiskScoreField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.CAPABILITY_RISK),
        value: generateRiskValue.bind(this, FIELD_PROPS.CAPABILITY_RISK, RISK_TYPE_ENUM.CAPABILITY_RISK),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateRiskColumn("Capability Risk", FIELD_PROPS.CAPABILITY_RISK,
            RISK_TYPE_ENUM.CAPABILITY_RISK, getClassName(FIELD_PROPS.CAPABILITY_RISK),
            process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Capability Risk",
        prop: FIELD_PROPS.CAPABILITY_RISK,
        colspan: PROCESS_CAPABILITY_COL_SPAN,
        isRiskScoreField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.CAPABILITY_RISK, true),
        value: generateRiskValue.bind(this, FIELD_PROPS.CAPABILITY_RISK, RISK_TYPE_ENUM.CAPABILITY_RISK),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateRiskColumn("Capability Risk", FIELD_PROPS.CAPABILITY_RISK,
            RISK_TYPE_ENUM.CAPABILITY_RISK, getClassName(FIELD_PROPS.CAPABILITY_RISK, true),
            process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Process Risk (Raw)",
        prop: FIELD_PROPS.PROCESS_RISK,
        colspan: PROCESS_CAPABILITY_COL_SPAN,
        section: PROCESS_CAPABILITY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.PROCESS_RISK),
        value: generateRiskValue.bind(this, FIELD_PROPS.PROCESS_RISK, RISK_TYPE_ENUM.PROCESS_RISK),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateRiskColumn("Process Risk (Raw)", FIELD_PROPS.PROCESS_RISK,
            RISK_TYPE_ENUM.PROCESS_RISK, getClassName(FIELD_PROPS.PROCESS_RISK),
            process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Process Risk (Raw)",
        prop: FIELD_PROPS.PROCESS_RISK,
        colspan: PROCESS_CAPABILITY_COL_SPAN,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.PROCESS_RISK, true),
        value: generateRiskValue.bind(this, FIELD_PROPS.PROCESS_RISK, RISK_TYPE_ENUM.PROCESS_RISK),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateRiskColumn("Process Risk (Raw)", FIELD_PROPS.PROCESS_RISK,
            RISK_TYPE_ENUM.PROCESS_RISK, getClassName(FIELD_PROPS.PROCESS_RISK, true),
            process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Process Risk (%)",
        prop: FIELD_PROPS.PROCESS_RISK_PERCENTAGE,
        colspan: PROCESS_CAPABILITY_COL_SPAN,
        section: PROCESS_CAPABILITY_SECTION,
        isRiskScaleField: true,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.PROCESS_RISK_PERCENTAGE),
        value: generateRiskValue.bind(this, FIELD_PROPS.PROCESS_RISK_PERCENTAGE, RISK_TYPE_ENUM.PROCESS_RISK),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateRiskColumn("Process Risk (%)", FIELD_PROPS.PROCESS_RISK_PERCENTAGE,
            RISK_TYPE_ENUM.PROCESS_RISK, getClassName(FIELD_PROPS.PROCESS_RISK_PERCENTAGE),
            process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Process Risk (%)",
        prop: FIELD_PROPS.PROCESS_RISK_PERCENTAGE,
        colspan: PROCESS_CAPABILITY_COL_SPAN,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.PROCESS_RISK_PERCENTAGE, true),
        value: generateRiskValue.bind(this, FIELD_PROPS.PROCESS_RISK_PERCENTAGE, RISK_TYPE_ENUM.PROCESS_RISK),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateRiskColumn("Process Risk (%)", FIELD_PROPS.PROCESS_RISK_PERCENTAGE,
            RISK_TYPE_ENUM.PROCESS_RISK, getClassName(FIELD_PROPS.PROCESS_RISK_PERCENTAGE, true),
            process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Estimated Sample Size",
        prop: FIELD_PROPS.ESTIMATED_SAMPLE_SIZE,
        colspan: PROCESS_CAPABILITY_COL_SPAN,
        section: PROCESS_CAPABILITY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        filterType: FILTER_TYPES.NUMERIC,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Estimated Sample Size",
        prop: FIELD_PROPS.ESTIMATED_SAMPLE_SIZE,
        colspan: PROCESS_CAPABILITY_COL_SPAN,
        filterType: FILTER_TYPES.NUMERIC,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Capability Justification",
        prop: FIELD_PROPS.CAPABILITY_JUSTIFICATION,
        section: PROCESS_CAPABILITY_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        colspan: PROCESS_CAPABILITY_COL_SPAN,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextAreaColumn("Capability Justification", FIELD_PROPS.CAPABILITY_JUSTIFICATION, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Capability Justification",
        columnSelectionTitle: "Capability Justification",
        prop: FIELD_PROPS.CAPABILITY_JUSTIFICATION,
        colspan: PROCESS_CAPABILITY_COL_SPAN,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextAreaColumn("Capability Justification", FIELD_PROPS.CAPABILITY_JUSTIFICATION, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
    ];
  }

  get riskControlSectionFields() {
    return [
      // Risk Control
      {
        title: "Detectability Risk",
        prop: FIELD_PROPS.DETECTABILITY_RISK,
        colspan: RISK_CONTROL_COL_SPAN,
        section: RISK_CONTROL_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        isRiskScoreField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.DETECTABILITY_RISK),
        value: generateRiskValue.bind(this, FIELD_PROPS.DETECTABILITY_RISK, RISK_TYPE_ENUM.DETECTABILITY_RISK),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateRiskColumn("Detectability Risk", FIELD_PROPS.DETECTABILITY_RISK,
            RISK_TYPE_ENUM.DETECTABILITY_RISK, getClassName(FIELD_PROPS.DETECTABILITY_RISK),
            process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Detectability Risk",
        prop: FIELD_PROPS.DETECTABILITY_RISK,
        colspan: RISK_CONTROL_COL_SPAN,
        isRiskScoreField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.DETECTABILITY_RISK, true),
        value: generateRiskValue.bind(this, FIELD_PROPS.DETECTABILITY_RISK, RISK_TYPE_ENUM.DETECTABILITY_RISK),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateRiskColumn("Detectability Risk", FIELD_PROPS.DETECTABILITY_RISK,
            RISK_TYPE_ENUM.DETECTABILITY_RISK, getClassName(FIELD_PROPS.DETECTABILITY_RISK, true),
            process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "RPN (Raw)",
        prop: FIELD_PROPS.RPN,
        colspan: RISK_CONTROL_COL_SPAN,
        section: RISK_CONTROL_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.RPN),
        value: generateRiskValue.bind(this, FIELD_PROPS.RPN, RISK_TYPE_ENUM.RPN),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateRiskColumn("RPN (Raw)", FIELD_PROPS.RPN,
            RISK_TYPE_ENUM.RPN, getClassName(FIELD_PROPS.RPN),
            process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "RPN (Raw)",
        prop: FIELD_PROPS.RPN,
        colspan: RISK_CONTROL_COL_SPAN,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.RPN, true),
        value: generateRiskValue.bind(this, FIELD_PROPS.RPN, RISK_TYPE_ENUM.RPN),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateRiskColumn("RPN (Raw)", FIELD_PROPS.RPN,
            RISK_TYPE_ENUM.RPN, getClassName(FIELD_PROPS.RPN, true),
            process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "RPN (%)",
        prop: FIELD_PROPS.RPN_PERCENTAGE,
        colspan: RISK_CONTROL_COL_SPAN,
        section: RISK_CONTROL_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.RPN_PERCENTAGE),
        value: generateRiskValue.bind(this, FIELD_PROPS.RPN_PERCENTAGE, RISK_TYPE_ENUM.RPN),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateRiskColumn("RPN (%)", FIELD_PROPS.RPN_PERCENTAGE,
            RISK_TYPE_ENUM.RPN, getClassName(FIELD_PROPS.RPN_PERCENTAGE),
            process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "RPN (%)",
        prop: FIELD_PROPS.RPN_PERCENTAGE,
        colspan: RISK_CONTROL_COL_SPAN,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.RPN_PERCENTAGE, true),
        value: generateRiskValue.bind(this, FIELD_PROPS.RPN_PERCENTAGE, RISK_TYPE_ENUM.RPN),
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateRiskColumn("RPN (%)", FIELD_PROPS.RPN_PERCENTAGE,
            RISK_TYPE_ENUM.RPN, getClassName(FIELD_PROPS.RPN_PERCENTAGE, true),
            process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Detectability Justification",
        prop: FIELD_PROPS.DETECTABILITY_JUSTIFICATION,
        colspan: RISK_CONTROL_COL_SPAN,
        section: RISK_CONTROL_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextColumn("Detectability Justification", FIELD_PROPS.DETECTABILITY_JUSTIFICATION, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Detectability Justification",
        prop: FIELD_PROPS.DETECTABILITY_JUSTIFICATION,
        colspan: RISK_CONTROL_COL_SPAN,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextColumn("Detectability Justification", FIELD_PROPS.DETECTABILITY_JUSTIFICATION, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Control Strategy",
        prop: FIELD_PROPS.CONTROL_STRATEGY,
        colspan: RISK_CONTROL_COL_SPAN,
        section: RISK_CONTROL_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
        tableValue: (columnGenerator) => columnGenerator.generateMultiSelectionColumn("Control Strategy", FIELD_PROPS.CONTROL_STRATEGY, true),
      },
      {
        title: "Control Strategy",
        prop: FIELD_PROPS.CONTROL_STRATEGY,
        colspan: RISK_CONTROL_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
        tableValue: (columnGenerator) => columnGenerator.generateMultiSelectionColumn("Control Strategy", FIELD_PROPS.CONTROL_STRATEGY, true, true),
      },
      {
        title: "CCP",
        prop: FIELD_PROPS.CCP,
        colspan: RISK_CONTROL_COL_SPAN,
        section: RISK_CONTROL_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
        filterOptions: () => CommonEditables.BOOLEAN_VALUES,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("CCP", FIELD_PROPS.CCP),
      },
      {
        title: "CCP",
        prop: FIELD_PROPS.CCP,
        colspan: RISK_CONTROL_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
        filterOptions: () => CommonEditables.BOOLEAN_VALUES,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("CCP", FIELD_PROPS.CCP, true),
      },
      {
        title: "Control Strategy Justification",
        prop: FIELD_PROPS.CONTROL_STRATEGY_JUSTIFICATION,
        colspan: RISK_CONTROL_COL_SPAN,
        section: RISK_CONTROL_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextAreaColumn("Control Strategy Justification", FIELD_PROPS.CONTROL_STRATEGY_JUSTIFICATION, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Control Strategy Justification",
        prop: FIELD_PROPS.CONTROL_STRATEGY_JUSTIFICATION,
        colspan: RISK_CONTROL_COL_SPAN,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextAreaColumn("Control Strategy Justification", FIELD_PROPS.CONTROL_STRATEGY_JUSTIFICATION, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Risk Control Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.RISK_CONTROL_LINKS,
        colspan: RISK_CONTROL_COL_SPAN,
        section: RISK_CONTROL_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.RISK_CONTROL_LINKS),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Risk Control Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.RISK_CONTROL_LINKS,
        colspan: RISK_CONTROL_COL_SPAN,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.RISK_CONTROL_LINKS, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
    ];
  }

  get referenceAndStandardsSectionFields() {
    return [
      // References & Standards
      {
        title: "References Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.REFERENCES,
        colspan: REFERENCE_AND_STANDARD_COL_SPAN,
        section: REFERENCES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.REFERENCES),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "References Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.REFERENCES,
        colspan: REFERENCE_AND_STANDARD_COL_SPAN,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.REFERENCES, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
    ];
  }

  get fields() {
    return [
      ...COMMON_VARIABLE_COLUMNS,
      ...this.aboutSectionFields,
      ...this.criticalityAssessmentSectionFields,
      ...this.acceptanceCriteriaSectionFields,
      ...this.processCapabilitySectionFields,
      ...this.riskControlSectionFields,
      ...this.referenceAndStandardsSectionFields,
      ...TECH_TRANSFER_ASSESSMENT_COLUMNS,
    ];
  }
}
