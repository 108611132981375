"use strict";

import * as UIUtils from "./ui_utils";
import React from "react";
import CompanyHeader from "./widgets/headers/company_header";
import TrialBar from "./widgets/bars/trial_bar";
import CookiesBar from "./widgets/bars/cookies_bar";
import TermsPopup from "./terms/terms_popup";
import BaseReactComponent from "./base_react_component";
import TypeaheadObjectCache from "./utils/cache/typeahead_object_cache";
import { DeveloperTools } from "./widgets/developer/developer_tools";
import { RouterContext } from "./utils/router_context";

/**
 * This is the base class that every page should extend from. Child pages should implement renderPage() to render their
 * content.
 */
// i18next-extract-mark-ns-start base_page
export default class BasePage extends BaseReactComponent {
  static contextType = RouterContext;

  constructor(props) {
    super(props);

    this.state.loggedInUser = {};
    this.projectId = UIUtils.getParameterByName("projectId");
    new TypeaheadObjectCache("User").loadOptions(this.handleUserTypeaheadResultsFromServer);


    this.extraPrintItems = {};
    this.setExtraPrintItems([{
      order: Number.MAX_SAFE_INTEGER,
      label: "QbDVision Version",
      data: UIUtils.getSoftwareVersion()
    }]);

    this.tableRefs = [];

    this.minified = (UIUtils.getParameterByName("minified") === "false") ? false : this.props.minified;
  }

  componentDidMount() {
    super.componentDidMount();

    //we need to remove undo full-screen-display styles manually when user switch to a "non" full screen page
    //check BaseFullScreenPage componentDidMount
    $("html").removeAttr("style");
    $("body").removeAttr("style");
    $("#root").removeClass("full-screen-display");

  }

  addTableRef(tableRef) {
    if (tableRef && !this.tableRefs.includes(tableRef)) {
      this.tableRefs.push(tableRef);
    }
  }

  getInfoAboutCurrentProject(projects) {
    const {t} = this.props;
    let info = {};
    const project = (projects || []).find(project => UIUtils.parseInt(project.id) === UIUtils.parseInt(this.projectId));
    if (project) {
      info = {
        breadcrumb: {
          current: UIUtils.getRecordCustomLabelForDisplay(project),
          parent: t ? t("Projects") : "Projects",
          parentLink: "/projects/list.html",
        },
        project
      };
    }

    return info;
  }

  handleProjectTypeaheadResultsFromServer(projects) {
    const info = this.getInfoAboutCurrentProject(projects);
    this.setStateSafely(info);
  }

  handleUserTypeaheadResultsFromServer(users) {
    if (this.projectId && !this.state.project?.name) {
      new TypeaheadObjectCache("Project").loadOptions(projects => {
        const projectInfo = this.getInfoAboutCurrentProject(projects);
        if (UIUtils.isEmpty(this.state.loggedInUser)) {
          const loggedInUser = this.getLoggedInUser(users);
          this.setStateSafely({...projectInfo, loggedInUser}, () => {
            this.forceUpdateSafely();
          });
        }
      });
    } else {
      if (UIUtils.isEmpty(this.state.loggedInUser)) {
        const loggedInUser = this.getLoggedInUser(users);
        this.setStateSafely({loggedInUser}, () => {
          this.forceUpdateSafely();
        });
      }
    }
  }

  loadProject() {
    return new TypeaheadObjectCache("Project").loadOptions().promise().then(projects => {
      const projectInfo = this.getInfoAboutCurrentProject(projects);
      this.setStateSafely({...projectInfo}, () => {
        this.forceUpdate();
      });
    });
  }

  projectInformationIsLoaded() {
    return this.state.project?.name && this.state.breadcrumb;
  }

  getLoggedInUser(users) {
    const loggedInUserId = UIUtils.getUserId();
    return users.find(user => user.id === loggedInUserId);
  }

  renderPage() {
  }

  failCallback(results) {
    UIUtils.defaultFailFunction(results);
  }

  setExtraPrintItems(items) {
    // This is so when we use this as a prop later on, we don't end up changing the in-memory reference to some other component's props.
    this.extraPrintItems = {...this.extraPrintItems};
    for (let item of items) {
      this.extraPrintItems[item.label] = item;
    }
  }

  handleChangeValue(attributeName, attributeValue, callback) {
    // Uncomment for verbose logging
    // console.log("Setting " + attributeName + " to '" + attributeValue + "'");
    let newState = {};
    newState[attributeName] = attributeValue;
    if (callback) {
      this.setStateSafely(newState, callback);
    } else {
      this.setStateSafely(newState);
    }
  }

  handleTermsPopupApproveButtonClick() {
    if (this.termsPopup) {
      $(this.termsPopup).modal("hide");
    }
  }

  isMinified() {
    return this.minified;
  }

  renderCompanyHeader() {
    return <CompanyHeader minified={this.isMinified()}
                          project={this.state.project}
                          extraPrintItems={this.extraPrintItems}
                          title={this.state.title}
                          breadcrumb={this.state.breadcrumb}
    />;
  }

  render() {
    return (
      <div id="bodyDiv">
        <TrialBar minified={this.isMinified()} />
        {this.renderCompanyHeader()}
        {this.renderPage()}
        <CookiesBar />
        <TermsPopup modalRef={termsPopup => this.termsPopup = termsPopup}
                    onApproveButtonCLick={this.handleTermsPopupApproveButtonClick}
        />
        <DeveloperTools />
      </div>
    );
  }
}
// i18next-extract-mark-ns-stop base_page
