"use strict";

import * as styles from "./table_title_bar.module.scss";
import React, { memo, useContext } from "react";
import CommonSecurity from "../../../../../../server/common/generic/common_security";
import { can, generateTooltip } from "../../../../../utils/ui_permissions";
import * as UIUtils from "../../../../../ui_utils";
import { getURLByTypeCodeAndId } from "../../../../../helpers/url_helper";
import { DefaultTitleView } from "./default_title_view";
import { ShareButton } from "./share_button";
import { SELECTED_TABLES } from "../constants";
import { ADD_PROCESS_MODEL_TYPES } from "../../../../../processExplorer/process_explorer_constants";
import { ViewsArea } from "../../../../../processExplorer/widgets/topBar/titleBar/views_area";
import AddButton from "./add_button";
import { RouterContext } from "../../../../../utils/router_context";

export const getAddButtonOptions = table => {
  return {
    [SELECTED_TABLES.CONTROL_METHODS]: [
      {
        key: "controlMethodAdd",
        title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.CONTROL_METHOD),
        disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.CONTROL_METHOD),
      },
    ],
    [SELECTED_TABLES.LIBRARY_MATERIALS]: [
      {
        key: "materialAdd",
        title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.LIBRARY_MATERIAL),
        disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.LIBRARY_MATERIAL),
      },
    ],
    [SELECTED_TABLES.PRODUCT]: [
      {
        value: "Target Product Profile Element",
        key: "addTPP",
        disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.TPP_SECTION),
        title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.TPP_SECTION),
      },
      {
        value: "General Attribute",
        key: "addGeneralAttribute",
        disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.GENERAL_ATTRIBUTE),
        title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.GENERAL_ATTRIBUTE),
      },
      {
        value: "Quality Attribute",
        key: "addQualityAttribute",
        disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.FQA),
        title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.FQA),
      },
      {
        value: "Performance Attribute",
        key: "addPerformanceAttribute",
        disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.FPA),
        title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.FPA),
      },
    ],
    [SELECTED_TABLES.SUPPLIERS]: [
      {
        key: "supplierAdd",
        value: "Supplier",
        title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.SUPPLIER),
        disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.SUPPLIER),
      },
    ],
    [SELECTED_TABLES.PROCESS]: ADD_PROCESS_MODEL_TYPES,
  }[table];
};

const TableTitleBar = (
  {
    t,
    projectId,
    table,
    modelName,
    onExport,
    onAddClick,
    viewsConfig,
    selectedPanel,
    viewsArea,
    titleArea,
    actionsArea,
    moreActions,
    selectedModelType
  }) => {

  const {navigate} = useContext(RouterContext);
  const onAddButtonClick = (e) => {
    UIUtils.ignoreHandler(e);
    if (onAddClick) {
      onAddClick(e);
      return;
    }
    const typeCode = UIUtils.getTypeCodeForModelName(modelName || e.target.innerText);
    const redirectionURL = UIUtils.getSecuredURL(
      getURLByTypeCodeAndId(typeCode, "Add", projectId)
    );

    //window.open(redirectionURL, "_self");
    navigate(redirectionURL);
  };

  return (
    <div className={styles["container"]}>
      {viewsArea ||
        viewsConfig && (
          <div className={styles["views-container"]}>
            <ViewsArea selectedPanel={selectedPanel}
                       viewsConfig={viewsConfig}
            />
          </div>
        )}

      <div className={styles["title-container"]}>
        {titleArea || (
          <DefaultTitleView t={t} modelName={modelName} selectedModelType={selectedModelType} />
        )}
      </div>

      <div className={styles["search-container"]} />

      <div className={styles["actions-container"]}>
        {actionsArea ? (
          actionsArea
        ) : (
          <>
            <AddButton
              t={t}
              table={table}
              options={getAddButtonOptions(table)}
              onClick={onAddButtonClick}
            />
            <ShareButton onExport={onExport} />
            {moreActions}
          </>
        )}
      </div>
    </div>
  );
};

export default memo(TableTitleBar);
