"use strict";

import React from "react";
import BasePage from "../base_page";

/**
 * This class shows the REST API (Open API) Swagger documentation so that clients can integrate other systems
 * programmatically.
 */
export default class RestAPIPage extends BasePage {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();

    // Cleanup the generated REST API docs.
    this.frame.addEventListener("load", () => {
      const iFrameContents = $(this.frame).contents();
      // Wait for the API to load so we can clean it up a bit
      const checkExist = setInterval(function() {
        if ($("a[href$='Redocly/redoc']", iFrameContents).length) {
          clearInterval(checkExist);
          // Add some CSS
          const new_style_element = document.createElement("style");
          new_style_element.textContent = `
            /* Hide the "Powered by Redoc" marketing */
            a[href$="Redocly/redoc"] {
              display: none;
            }
          `;
          iFrameContents[0].head.appendChild(new_style_element);
        }
      }, 100); // check every 100ms

    });
  }

  renderPage() {
    /**
     * We use an IFrame here because this HTML is generated by the ReDocly package (https://github.com/Redocly/redoc).
     * They offer a pro version where you can modify the header (and a lot of other features). It seems easy enough to
     * add our own header. We don't need any of the other features, such has hosting the docs, etc.
     */
    // noinspection HtmlUnknownTarget
    return (
      <iframe src="./redoc-static.html" className="rest-api-iframe" ref={ref => this.frame = ref} />
    );
  }
}

