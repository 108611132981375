"use strict";

import * as UIUtils from "../../ui_utils";

/**
 * This is a proxy class for triggering an Ajax request to the backend.
 */
export class AjaxServiceProxy {

  /**
   * Submits a PUT request to the backend using Ajax
   * @param payload The payload to include in the request
   * @param parameters The Ajax request parameters
   */
  submitPutRequest(payload, parameters) {
    const {urlPrefix, action, idempotent, global, clearUIErrors} = parameters;

    return new Promise((resolve, reject) => {
      const path = `${urlPrefix}/${action}`;
      UIUtils.secureAjaxPUT(path, payload, global, reject, idempotent, clearUIErrors).done(resolve);
    });
  }

  /**
   * Submits a GET request to the backend using Ajax
   * @param parameters {*} The Ajax request parameters
   */
  submitGetRequest(parameters) {
    const {urlPrefix, action, idempotent, global, clearUIErrors, urlParameters} = parameters;

    return new Promise((resolve, reject) => {
      const path = `${urlPrefix}/${action}`;
      UIUtils.secureAjaxGET(path, urlParameters, global, reject, idempotent, clearUIErrors).done(resolve);
    });
  }

  /**
   * Submits a POST request to the backend using Ajax
   * @param payload The payload to include in the request
   * @param parameters The Ajax request parameters
   */
  submitPostRequest(payload, parameters) {
    const {urlPrefix, action, idempotent, global, clearUIErrors} = parameters;

    return new Promise((resolve, reject) => {
      const path = `${urlPrefix}/${action}`;
      UIUtils.secureAjaxPOST(path, payload, global, reject, idempotent, clearUIErrors).done(resolve);
    });
  }
}
