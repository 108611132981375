"use strict";

import * as UIUtils from "../../ui_utils";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import BaseAutoBind from "../../base_auto_bind";
import { TaskExecutor } from "../../../server/framework/task_executor";

const Logger = Log.group(LOG_GROUP.ProcessExplorer, "RecordLoader");

/**
 * This class is responsible for loading records in the background while the user surfs for what they need.
 */
export default class RecordLoader extends BaseAutoBind {

  /**
   * @param {[string]} recordKeysToLoad An array of keys (ex. "UO-13", "PRC-12") to load.
   * @param {int} projectId The project Id.
   * @param {boolean} useWriterDB True if the writer/master DB should be used, false if the reader node can be used.
   * @param [sendingProcessId] {int} The ID of the sending process, if needed.
   */
  constructor(recordKeysToLoad, projectId, useWriterDB = false, sendingProcessId) {
    super();
    // Get the unique keys
    this.recordKeysSet = new Set(recordKeysToLoad);
    this.useWriterDB = useWriterDB;
    this.sendingProcessId = sendingProcessId;
    this.keyToRecordMap = new Map();
    this.projectId = projectId;
    this.timeOfLastMessage = Date.now();
    this._isLoading = false;
    this.loadingStatusHandlers = [];
    this.loadedRecordChunks = 0;
  }

  /**
   * Starts loading the records required.
   *
   * @return {Promise<[{}]>} A promise that when complete, will contain all of the records requested to be loaded.
   */
  startLoading() {
    this.startLoadingTime = Date.now();
    this._isLoading = true;
    this.loadedRecordChunks = 0;

    this.recordLoadingPromise = new Promise((resolve) => {
      /* Chuck the data to be loaded into buckets of 500 records each. For number of records above this size, the lambda
         handler runs into the danger of exceeding the 30 seconds API Gateway limit and failing.
       */
      const requirementIds = [...this.recordKeysSet];
      const recordChunkSize = 500;
      const recordChunkLength = Math.ceil(requirementIds.length / recordChunkSize);
      let chunkCounter = 0;
      const ajaxLoadingTasks = [];
      for (let i = 0, j = requirementIds.length; i < j; i += recordChunkSize) {
        const recordIdsSlice = requirementIds.slice(i, i + recordChunkSize);
        chunkCounter++;

        const chunkFunction = () => new Promise((resolveChunk) => {
          UIUtils.secureAjaxPUT(`editables/multiple/list/${this.projectId}?` +
            "onlyLoadDetails=true" +
            "&includeHistory=true" +
            "&includeClonedFrom=true" +
            "&shouldCompress=true" +
            "&includeFromLibrary=true" +
            "&includeRecordOrder=true" +
            "&returnLatestApprovedOrDraftVersionsOnly=true" +
            (this.useWriterDB ? "&useWriterDB=true" : ""), {
            requirementIds: recordIdsSlice,
            sendingProcessId: this.sendingProcessId,
          }, false).done(results => {
            for (const record of results.instances) {
              this.handleIndividualRecord(record);
            }
            this.handlePartialLoadingCompleted(chunkCounter, recordChunkLength, resolve);
            resolveChunk();
          });
        });

        ajaxLoadingTasks.push(chunkFunction);
      }

      TaskExecutor.parallel(ajaxLoadingTasks, 5);
    });

    return this.recordLoadingPromise;
  }

  handleIndividualRecord(record) {
    // Make sure there's a typeCode for future lookups.
    const typeCode = record.typeCode || UIUtils.getTypeCodeForModelName(record.modelName);
    if (!record.typeCode) {
      record.typeCode = typeCode;
    }
    this.keyToRecordMap.set(typeCode + "-" + record.id, record);
    this.showLoadingStatus();
  }

  handlePartialLoadingCompleted(chunkNumber, recordChunkLength, resolve) {
    this.loadedRecordChunks++;
    Logger.verbose(() => `Loading completed in ${Date.now() - this.startLoadingTime} ms for chunk ${chunkNumber}`);
    if (this.loadedRecordChunks === recordChunkLength) {
      this._isLoading = false;
      resolve(this.keyToRecordMap);
    }
  }

  showLoadingStatus() {
    // Only update the loading status every 500 ms.
    const currentTime = Date.now();
    if ((currentTime - this.timeOfLastMessage) >= 500) {
      const recordsLoadedSoFar = this.keyToRecordMap.size;
      const totalNumberOfRecords = this.recordKeysSet.size;

      // Log it to the console.
      const loadingMessage = `Loading ${recordsLoadedSoFar}/${totalNumberOfRecords} records..`;
      Logger.info(loadingMessage);
      this.timeOfLastMessage = currentTime;

      // Update other interested parties.
      for (const loadingStatusHandler of this.loadingStatusHandlers) {
        loadingStatusHandler(recordsLoadedSoFar, totalNumberOfRecords);
      }
    }
  }

  /**
   * @return {boolean} True if the records are still being loaded, false otherwise.
   */
  isLoading() {
    return this._isLoading;
  }

  /**
   * Get a promise that is complete once all records are loaded.
   * @return {Promise<unknown>} A promise that is complete once all records are loaded.
   */
  getRecordLoadingPromise() {
    return this.recordLoadingPromise;
  }

  /**
   * Find a given record, if it's been loaded yet.
   * @param {string} key
   * @return {{}} The record requested, or undefined if it's not laoded yet.
   */
  findRecordIfLoaded(key) {
    return this.keyToRecordMap.get(key);
  }

  /**
   * Register a handler to receive updates when the number or loaded records has changed.
   * @param {function(int, int)} someFunction A function that takes two parameters: the number of records loaded so far,
   *                                          and the total number of records.
   */
  registerLoadingStatusHandler(someFunction) {
    this.loadingStatusHandlers.push(someFunction);
  }
}
