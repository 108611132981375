"use strict";

import React from "react";
import * as UIUtils from "../../../ui_utils";
import ReactDOMServer from "react-dom/server";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfigurableTablesTooltipBase from "../../../configurableTables/widgets/configurable_tables_tooltip_base";

export default class TechTransferChangeColumnTooltip extends ConfigurableTablesTooltipBase {
  show() {
    $("#techTransferChangeColumnTooltipLink").on("click", this.handleViewDetailedComparison);
  }

  handleViewDetailedComparison(event) {
    UIUtils.ignoreHandler(event);
    $(this.popover).popover("hide");

    const {onViewDetailedComparison, reviewRecord} = this.props;
    onViewDetailedComparison(reviewRecord);
  }

  renderPopupContent() {
    const {diff} = this.props;
    const {diffs} = diff;

    return ReactDOMServer.renderToString(
      <div id="changeColumnTooltipDiv">
        <div className="tech-transfer-diff-column-tooltip-title">
          Changes Summary
        </div>
        <div className="configurable-tables-column-selection-body-container">
          {diffs.map((diff, idx) => {
            return (<div key={idx}
                         className="tech-transfer-diff-column-tooltip-body-section"
            >
              {`${diff.title} (${diff.sectionChangesCount})`}
            </div>);
          })}
          <a id="techTransferChangeColumnTooltipLink">
            <FontAwesomeIcon className="tech-transfer-review-icon m-0"
                             size="sm"
                             icon={faEye}
            /> Detailed Comparison
          </a>
        </div>
      </div>
    );
  }

  renderBody() {
    const {diff} = this.props;
    const {totalChangesCount} = diff;
    return `${totalChangesCount} ${UIUtils.pluralize("change", totalChangesCount)}`;
  }
}
