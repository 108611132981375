"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import BaseReactComponent from "../../base_react_component";

/**
 * This class is for showing a tooltip on a label
 */
export default class LabelTooltip extends BaseReactComponent {
  constructor(props) {
    super(props);

    this._popoverInitialized = false;
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.id !== this.props.id ||
      nextProps.text !== this.props.text ||
      nextProps.tooltipText !== this.props.tooltipText ||
      nextProps.indicatorClassName !== this.props.indicatorClassName ||
      nextProps.className !== this.props.className;
  }

  componentDidMount() {
    super.componentDidMount();
    this.initializeTooltip();
  }

  componentDidUpdate() {
    this.initializeTooltip();
  }

  initializeTooltip() {
    if (this.props.tooltipText || this.props.getTooltipCallback) {
      $(this.popover).popover({
        sanitizeFn: UIUtils.sanitizePopoverData,
      });
      this._popoverInitialized = true;
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this._popoverInitialized) {
      $(this.popover).popover("dispose");
    }
  }

  renderPopupContent() {
    /*
      This gets converted to HTML and passed around, so don't bother putting onClick handlers here.
      Put them in componentDidMount() above instead.
     */
    if (this.props.tooltipText || this.props.getTooltipCallback || this.props.tooltipGuidanceURL) {
      if (this.props.getTooltipCallback) {
        return ReactDOMServer.renderToString(this.props.getTooltipCallback());
      }

      if (this.props.tooltipText) {
        return ReactDOMServer.renderToString(
          <div id={this.props.id + "TooltipDiv"}>
            <div id={this.props.id + "Content"} className="info-tooltip-content">
              {this.props.tooltipText ? this.props.tooltipText : ""}
              {this.props.tooltipGuidanceURL ? (
                <div>
                  <a rel="noopener noreferrer"
                     target="_blank"
                     className="info-tooltip-footer"
                     href={UIUtils.computePdfURL(this.props.tooltipGuidanceURL, this.props.tooltipGuidancePage, this.props.tooltipGuidanceOffset)}
                  >
                    Guidance
                  </a>
                </div>
              ) : ""}
            </div>
          </div>
        );
      }
    }

    return "";
  }

  render() {
    let className = this.props.className ? this.props.className : "";
    let tooltip = this.renderPopupContent();
    let showTooltip = !!tooltip;

    return <label id={this.props.id}
                  htmlFor={this.props.htmlFor}
                  style={this.props.style}
                  className={className}
                  data-toggle="popover"
                  data-html="true"
                  data-container="body"
                  data-boundary="viewport"
                  data-placement={this.props.placement ? this.props.placement : "top"}
                  data-trigger={this.props.htmlFor || this.props.showOnHover ? "hover" : "click"}
                  data-content={tooltip}
                  ref={popover => this.popover = popover}
    >
        <span className={showTooltip ? "col-form-label-tooltip" : ""}>
          {this.props.text}
        </span>
      {this.props.instructions ? this.props.instructions : ""}
      {this.props.moreInfo ? this.props.moreInfo : ""}
      {this.props.indicator}{this.props.htmlFor || this.props.noColon ? "" : ":"}
    </label>;
  }
}
