import {EditorToolsSettings, ProseMirror} from "@progress/kendo-react-editor";
import * as UIUtils from "../../ui_utils";
import {Node, ResolvedPos, Transaction} from "@progress/kendo-editor-common";
import {Converters} from "../../../server/common/generic/common_converters";

const {Plugin, PluginKey} = ProseMirror;

export default function setDataNumberedList() {
  return new Plugin({
    key: new PluginKey("numberedListPlugin"),
    appendTransaction: (transactions, oldState, newState) => {
      let newStateTr = newState.tr;
      if (newState.doc.eq(oldState.doc)) {
        return newStateTr;
      }

      let isDocChanged = false;
      let listItemNodeWithChangedStyling: Node = null;
      let newListItemStyle = "";
      for (const transaction of transactions) {
        if (transaction.docChanged) {
          isDocChanged = true;
          const args = transaction.getMeta("args");
          const anchor = transaction.selection.$anchor;
          // When we change the font size of a text, we also need to manually update the
          // font size of the list item associated with it as well
          if (
            transaction.getMeta("commandName") ===
            EditorToolsSettings.fontSize.commandName
          ) {
            for (let d = anchor.depth; d > 0; d--) {
              const node = anchor.node(d);
              if (node.type.name === "list_item") {
                listItemNodeWithChangedStyling = node;
                newListItemStyle = args ? `${args.style}: ${args.value};` : "";
                break;
              }
            }
          }
          break;
        }
      }
      if (!isDocChanged) {
        return newStateTr;
      }

      // We only need to set the list item data if the changes contain list_item
      if (
        !isListItemChanged(newState.selection.$anchor) &&
        !isListItemChanged(oldState.selection.$anchor)
      ) {
        return newStateTr;
      }

      return setListItemsData(
        newState.doc,
        newStateTr,
        listItemNodeWithChangedStyling,
        newListItemStyle,
      );
    },
  });
}

function isListItemChanged(anchor: ResolvedPos) {
  for (let d = anchor.depth; d > 0; d--) {
    const node = anchor.node(d);
    if (node.type.name === "list_item") {
      return true;
    }
  }
  return false;
}

function setListItemsData(
  parentNode: Node,
  transaction: Transaction,
  listItemNodeWithChangedStyling: Node,
  newListItemStyle: string,
) {
  const olNodeToUUID: Map<Node, string> = new Map();
  const levelToLastListItem: Map<number, string> = new Map();
  const levelToLastLiNode: Map<number, Node> = new Map();
  const levelToLastOlNode: Map<number, Node> = new Map();
  parentNode.descendants((node, pos) => {
    if (node.type.name === "ordered_list") {
      // We need to set uuid for merging list and setting data in preview mode
      const uuid = UIUtils.generateUUID();
      transaction = transaction.setNodeAttribute(pos, "uuid", uuid);
      olNodeToUUID.set(node, uuid);
    }

    if (node.type.name === "list_item") {
      if (
        listItemNodeWithChangedStyling &&
        node.eq(listItemNodeWithChangedStyling)
      ) {
        transaction = transaction.setNodeAttribute(
          pos,
          "style",
          newListItemStyle,
        );
      }
    }
  });

  const visited: Set<string> = new Set();
  parentNode.descendants((node, pos) => {
    if (node.type.name === "ordered_list") {
      // We only need to set the list item data for the first level ol node
      const uuid = olNodeToUUID.get(node);
      if (visited.has(uuid)) {
        return false;
      }

      visited.add(uuid);
      setListItemDataForOlNode(
        node,
        pos,
        1,
        transaction,
        olNodeToUUID,
        levelToLastListItem,
        levelToLastLiNode,
        levelToLastOlNode,
        visited,
      );
    }
  });

  return transaction;
}

function setListItemDataForOlNode(
  node: Node,
  nodePos: number,
  level: number,
  transaction: Transaction,
  olNodeToUUID: Map<Node, string>,
  levelToLastListItem: Map<number, string>,
  levelToLastLiNode: Map<number, Node>,
  levelToLastOlNode: Map<number, Node>,
  visited: Set<string>,
  prefix?: string,
) {
  let numberOfListItems = 0;
  if (
    Converters.toBoolean(node.attrs.continuous) ||
    Converters.toBoolean(node.attrs.prefixnearest)
  ) {
    const lastOlNode = levelToLastOlNode.get(level);
    if (lastOlNode && olNodeToUUID.get(lastOlNode)) {
      // We have the dependon attribute, so we know whether the list is start from the beginning
      // of the repeater widget or not
      transaction = transaction.setNodeAttribute(
        nodePos,
        "dependon",
        olNodeToUUID.get(lastOlNode)
      );
    }

    const lastListItem = levelToLastListItem.get(level);
    if (lastListItem) {
      if (Converters.toBoolean(node.attrs.continuous)) {
        const lastListItemData = parseListItemData(lastListItem);
        numberOfListItems = lastListItemData.order;
        prefix = lastListItemData.prefix ? `${lastListItemData.prefix}.` : "";
      } else {
        prefix = prefix ? `${lastListItem}.${prefix}` : `${lastListItem}.`;
        // If the ol node is prefix nearest item and the last li node at the same level has nested
        // list, we will need to use the last list item of the nested list instead
        const lastLiNode = levelToLastLiNode.get(level);
        if (lastLiNode) {
          lastLiNode.descendants((childNode, childPos, childParent) => {
            if (
              childNode.type.name === "ordered_list" &&
              childParent.eq(lastLiNode)
            ) {
              // We use level + 1 since we want to get the last list item of the next level
              const listItemData = parseListItemData(
                levelToLastListItem.get(level + 1),
              );
              numberOfListItems = listItemData.order;
              return false;
            }
          });
        }
      }
    }
  }
  transaction = transaction.setNodeAttribute(nodePos, "level", level);
  levelToLastOlNode.set(level, node);

  // We traverse all the list item of the ol node to set the data for them
  node.descendants((childNode, childPos, childParentNode) => {
    // We only traverse the direct list item of the current numbered list
    if (childNode.type.name === "list_item" && node.eq(childParentNode)) {
      levelToLastLiNode.set(level, childNode);
      numberOfListItems++;
      const absolutePos = nodePos + childPos + 1;
      const nodeAtPos = transaction?.doc?.nodeAt(absolutePos);
      const dataValue = `${prefix || ""}${numberOfListItems}`;
      // This is just a safe check to make sure the absolute position is a list item before
      // we set it
      if (nodeAtPos?.type?.name === "list_item") {
        levelToLastListItem.set(level, dataValue);
        if (childNode.attrs?.data !== dataValue) {
          transaction = transaction.setNodeAttribute(
            absolutePos,
            "data",
            dataValue,
          );
        }
      }

      // Traverse the nested numbered list of the list item if it has any
      childNode.descendants(
        (nextChildNode, nextChildPos, nextChildParentNode) => {
          if (
            nextChildNode.type.name === "ordered_list" &&
            childNode.eq(nextChildParentNode)
          ) {
            const uuid = olNodeToUUID.get(nextChildNode);
            if (visited.has(uuid)) {
              return false;
            }

            visited.add(uuid);
            // If there is a nested list, we will need to recursively set data for all
            // the list items
            setListItemDataForOlNode(
              nextChildNode,
              absolutePos + nextChildPos + 1,
              level + 1,
              transaction,
              olNodeToUUID,
              levelToLastListItem,
              levelToLastLiNode,
              levelToLastOlNode,
              visited,
              `${dataValue}.`,
            );
          }
        },
      );
    }
  });

  return transaction;
}

export function parseListItemData(dataValue: string): {
  order: number;
  prefix: string;
  level: number;
} {
  const parts = dataValue?.split(".");
  return parts?.length
    ? {
      order: UIUtils.convertToNumber(parts[parts.length - 1]),
      prefix: parts.slice(0, parts.length - 1).join("."),
      level: parts.length,
    }
    : {
      order: UIUtils.convertToNumber(dataValue || "0"),
      prefix: "",
      level: parts?.length,
    };
}
