"use strict";

/*
 * The functions in this file are responsible for ordering and indexing unit operations.
 */

const CommonUtils = require("../generic/common_utils");
const { TYPE_CODE } = require("../generic/common_constants");

function isObjectCacheStillLoading(options) {
  return !options || options.isLoading;
}

module.exports.orderAndIndex = function(instancesList, uoIdAttribute = "id", includeArchived, previousAttributeName, typeCode) {
  if (instancesList && instancesList.length <= 1){
    return instancesList;
  }

  let previousInstanceIdToInstanceMap = new Map();
  let archivedInstances = [];
  let firstInstance;

  if (isObjectCacheStillLoading(instancesList)) {
    return [];
  }

  // Fill in the previousInstanceIdToInstanceMap
  for (let instance of instancesList) {
    if (instance.deletedAt && includeArchived) {
      archivedInstances.push(instance);
    }

    if (instance.deletedAt) {
      continue;
    }

    if (instance[previousAttributeName]) {
      previousInstanceIdToInstanceMap.set(instance[previousAttributeName], instance);
    }

    if (!firstInstance && !instance[previousAttributeName]
    ) {
      firstInstance = instance;
    }

  }

  // Uncomment for verbose logging
  // console.log("Calling order and index with '" + firstInstance.name + "' [" + firstInstance.id + "].  PreviousUOIdToMap = "
  //   + UIUtils.stringify(previousInstanceIdToInstanceMap));

  // Process the items
  let i = 0;
  let orderedInstanceList = [];
  if (instancesList.length > 0) {
    let nextInstance = firstInstance;
    while (nextInstance) {
      nextInstance.isLast = false;
      module.exports.addIndexAndTypeCode(nextInstance, i++, typeCode);

      // Organize the children
      nextInstance.children = nextInstance.ProcessComponents;
      let children = nextInstance.children;
      if (children) {
        if (!includeArchived) {
          children = children.filter(pc => {
            return (pc.currentState !== CommonUtils.VERSION_STATES.ARCHIVED)
              && (pc.currentState !== CommonUtils.VERSION_STATES.ARCHIVED_CASCADED)
              && (pc.currentState !== CommonUtils.VERSION_STATES.PROPOSED_FOR_RESTORE)
              && (pc.currentState !== CommonUtils.VERSION_STATES.PROPOSED_FOR_RESTORE_CASCADED);
          });
        }
        if (children.length > 0) {
          for (let j = 0; j < children.length; j++) {
            let child = children[j];
            module.exports.addIndexAndTypeCode(child, j, "PRC", nextInstance);
          }
          children[children.length - 1].isLast = true;
        }
      }

      // Add it to this list and move on.
      orderedInstanceList.push(nextInstance);
      nextInstance = previousInstanceIdToInstanceMap.get(nextInstance[uoIdAttribute]);
    }

    if (orderedInstanceList.length > 0) {
      orderedInstanceList[orderedInstanceList.length - 1].isLast = true;
    }

    if (includeArchived) {
      orderedInstanceList = orderedInstanceList.concat(archivedInstances.sort((instance1, instance2) => instance1.name > instance2.name ? 1 : instance1.name < instance2.name ? -1 : 0));
    }
  }

  return orderedInstanceList;
};

/**
 * This function orders and indexes unit operations.
 *
 * @param uoList The list of UOs
 * @param uoIdAttribute The attribute to use as the ID
 * @param includeArchived Whether to include archived UOs in alphabetical order at the end
 * @return {[]} A list of UOs that is in order and contains an index property
 */
module.exports.orderAndIndexUnits = function(uoList, uoIdAttribute = "id", includeArchived) {
  return module.exports.orderAndIndex(uoList, uoIdAttribute, includeArchived, "PreviousUnitId", TYPE_CODE.UNIT_OPERATION);
};

/**
 * This function orders and indexes steps.
 *
 * @param stpList The list of UOs
 * @param stpIdAttribute The attribute to use as the ID
 * @param includeArchived Whether to include archived UOs in alphabetical order at the end
 * @return {[]} A list of Steps that is in order and contains an index property
 */
module.exports.orderAndIndexSteps = function(stpList, stpIdAttribute = "id", includeArchived) {
  return module.exports.orderAndIndex(stpList, stpIdAttribute, includeArchived, "PreviousStepId", TYPE_CODE.STEP);
};

/*
 * Figure out the index.
 */
module.exports.addIndexAndTypeCode = function(obj, num, typeCode, parent) {
  obj.index = (parent ? parent.index + "." : "") + (num + 1);
  obj.typeCode = typeCode;
};

