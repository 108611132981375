"use strict";

import React from "react";
import BaseReactComponent from "../../../../base_react_component";
import * as UIUtils from "../../../../ui_utils";
import { getURLByTypeCodeAndId } from "../../../../helpers/url_helper";

/**
 * This implements the attribute information panel on the continuous data import preview screen.
 */
export default class ContinuousAttributeInformationPanel extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  createUnitOperationField() {
    const {attribute} = this.props;
    const unitOperation = attribute && attribute.unitOperation;

    if (unitOperation) {
      const uoKey = unitOperation.split(" - ")[0];
      const uoId = UIUtils.parseKey(uoKey).id;
      return (<div className="continuous-attribute-info-field">
          <div>Unit Operation</div>
          <a id="unitOperationLink"
             href={getURLByTypeCodeAndId("UO", "View", uoId)}
             target="_blank"
             rel="noreferrer noopener"
          >
            {unitOperation}
          </a>
        </div>
      );
    }

    return "";
  }

  createStepField() {
    const {attribute} = this.props;
    const step = attribute && attribute.step;

    if (step && step !== "Global") {
      const stepKey = step.split(" - ")[0];
      const stepId = UIUtils.parseKey(stepKey).id;
      return (<div className="continuous-attribute-info-field">
          <div>Step</div>
          <a id="stepLink"
             href={getURLByTypeCodeAndId("STP", "View", stepId)}
             target="_blank"
             rel="noreferrer noopener"
          >
            {step}
          </a>
        </div>
      );
    }

    return "";
  }

  createAttributeField(property, value) {
    const {attribute} = this.props;
    const attributeId = UIUtils.uncapitalize(UIUtils.convertToId(property));

    return (
      <div className="continuous-attribute-info-field">
        <div><span>{property}</span></div>
        <div><span id={attributeId}>{value || attribute[attributeId]}</span></div>
      </div>
    );
  }

  render() {
    const {attribute} = this.props;
    const measurements = attribute && attribute.measurements || [];

    return (
      <div className="continuous-data-information-panel">
        {this.createUnitOperationField()}
        {this.createStepField()}
        {this.createAttributeField("Acceptance Criteria")}
        {this.createAttributeField("Measurement Units")}
        {this.createAttributeField("No of Samples", measurements.length)}
      </div>
    );
  }
}
