"use strict";

import * as React from "react";
import { useState, useCallback, useEffect } from "react";
import { filterBy } from "@progress/kendo-data-query";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import { faExternalLinkAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as UIUtils from "../../../../ui_utils";
import { getURLByTypeCodeAndId } from "../../../../helpers/url_helper";
import { LIBRARY_MATERIAL_TYPE_CODE } from "../../../library_constants";
import { can, generateTooltip } from "../../../../utils/ui_permissions";
import CommonSecurity from "../../../../../server/common/generic/common_security";
import { RadioButton } from "@progress/kendo-react-inputs";

const COLOR = "#999999";
const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const resetSelectedAttribute = dataItem => Object.assign({selected: false}, dataItem);
const isTableLoaded = dataState => dataState && dataState.length !== 0;

const renderGmpCell = ({dataItem}) => {
  return (
    <td colSpan="1" role="gridcell" aria-colindex="5" aria-selected="false" data-grid-col-index="4">
      <div>
        {dataItem.gmp ? "Yes" : "No"}
      </div>
    </td>
  );
};

/**
 * Create and return the view column cell.
 * @param dataItem
 * @returns {JSX.Element}
 */
const buildViewInLibraryColumn = ({dataItem}) => {

  const {id} = dataItem;
  return (<td>
    <div>
      <a href={getURLByTypeCodeAndId(LIBRARY_MATERIAL_TYPE_CODE, "View", id, true)}
         rel="noreferrer noopener"
         target="_blank"
         className="view-library-material-cell"
      >
        <FontAwesomeIcon icon={faEye} color={COLOR} />
      </a>
    </div>
  </td>);
};

const EmptyMaterialTableStatus = ({t, isEmptySearchResult}) => (<div className="empty-table-state">
  <div className="empty-table-state-message pb-3">
    {(isEmptySearchResult) ? t("Empty Search Result") : t("Select Material Message")}
  </div>
  <button
    id="addMaterialInLibrary"
    className="btn btn-secondary btn-lg mt-2"
    disabled={!can(CommonSecurity.Actions.ADD, CommonSecurity.Types.LIBRARY_MATERIAL)}
    title={generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.LIBRARY_MATERIAL)}
    onClick={() => window.location.href = UIUtils.getSecuredURL(`/library/materials/viewEdit.html?operation=Add`)}
  >
    {t("Add In Library Button")} <FontAwesomeIcon icon={faExternalLinkAlt} />
  </button>
</div>);

const LibraryMaterialsTelerikTable = ({dataState, selectedState, onSelectionChange}) => (
  <div className="library-materials-table">
    <Grid
      className="library-materials-grid"
      data={dataState.map(item => ({
        ...item, [SELECTED_FIELD]: selectedState[idGetter(item)],
      }))}
      dataItemKey={DATA_ITEM_KEY}
      selectedField={SELECTED_FIELD}
      selectable={{
        enabled: true, mode: "single",
      }}
      onSelectionChange={onSelectionChange}
    >
      <Column
        headerCell={() => <div />}
        field={SELECTED_FIELD}
        width="50px"
        cell={({dataItem}) => (
          <td>
            <div>
              <RadioButton
                checked={selectedState[idGetter(dataItem)]}
                onChange={() => onSelectionChange(dataItem)}
              />
            </div>
          </td>
        )}
      />
      <Column field="name" title="Name" width="300px" />
      <Column field="category" title="Category" />
      <Column field="Supplier.name" title="Supplier" />
      <Column field="gmp" title="GMP" cell={renderGmpCell} />
      <Column width="75px" cell={(event) => buildViewInLibraryColumn(event)}
      />
    </Grid>
  </div>);

function normalizeGmpColumns(materials, toNumeric) {
  if (toNumeric) {
    return materials.map(material => material.gmp === "Yes" ? {...material, gmp: 1} : {...material, gmp: 0});
  } else {
    return materials.map(material => material.gmp ? {
      ...material, gmp: "Yes"
    } : {
      ...material, gmp: "No"
    });
  }
}

/**
 * A list table of library materials the user can pick from
 * @param t Translator
 * @param isEmptySearchResult
 * @param materials
 * @param filter
 * @param onSelectRow
 * @param onResultChange
 * @param initialSelection
 * @returns {JSX.Element}
 * @constructor
 */
export function MaterialLibraryTable({
  t,
  isEmptySearchResult,
  materials,
  filter,
  onSelectRow,
  onResultChange,
  initialSelection = {}
}) {

  const [dataState, setDataState] = useState(materials);
  const [selectedState, setSelectedState] = useState(initialSelection);

  // Filter the table data only when the library materials changes or the filter changes.
  useEffect(() => {
    /**
     * Because of a bug in filterBy(), I have to convert the GMP to text to be able to filter its value
     * then return it back to numeric, normalizeGmpColumns() function is doing this job.
     */
    const dataState = filterBy(
      normalizeGmpColumns(materials.map(resetSelectedAttribute), false), filter
    );
    setDataState(
      normalizeGmpColumns(dataState, true)
    );
    onResultChange(dataState.length);
    setSelectedState({});
  }, [materials, filter]);

  /**
   If the user returns from material options step to material selection step, we will show the currently selected material as selected on the table.
   */
  useEffect(() => {
    if (!UIUtils.isEmpty(initialSelection)) {
      setSelectedState(initialSelection);
    }
  }, [initialSelection]);


  const onSelectionChange = useCallback(dataItemOrEvent => {

    const {dataItems, startRowIndex} = dataItemOrEvent;
    let itemId;
    let newSelectedState;
    if (dataItems) {
      const currentDataItem = dataItems.slice(startRowIndex, startRowIndex + 1)[0];
      itemId = idGetter(currentDataItem);
      newSelectedState = {
        [itemId]: true,
      };
    } else {
      itemId = idGetter(dataItemOrEvent);
      newSelectedState = {
        [itemId]: true,
      };
    }

    onSelectRow(
      dataState.find(material => idGetter(material) === parseInt(itemId)),
      newSelectedState
    );
  }, [dataState, selectedState]);


  return !isTableLoaded(dataState) ? (<EmptyMaterialTableStatus t={t} isEmptySearchResult={isEmptySearchResult} />) : (
    <LibraryMaterialsTelerikTable
      t={t}
      dataState={dataState}
      selectedState={selectedState}
      onSelectionChange={onSelectionChange}
    />);
}
