"use strict";

import {
  ABOUT_SECTION, AUDIT_SECTION, QUALIFICATION_SECTION, RISK_CONTROL_SECTION,
} from "../constants/configurable_tables_sections_config";
import { FIELD_PROPS } from "../constants/configurable_tables_field_props_config";
import RecordFields from "../record_fields";
import { FILTER_TYPES } from "../../columnOperations/configurable_tables_column_operations_constants";

/**
 * This class lists all supplier fields to be shown in export, column selection and tables.
 */
export default class SupplierFields extends RecordFields {

  get title() {
    return "Suppliers";
  }

  get fields() {
    return [
      // About
      {
        title: "Name",
        prop: FIELD_PROPS.NAME,
        section: ABOUT_SECTION,
        defaultValue: true,
        tableValue: (columnGenerator) => columnGenerator.generateNameColumn(),
      },
      {
        title: "Address",
        prop: FIELD_PROPS.ADDRESS,
        section: ABOUT_SECTION,
        defaultValue: false,
        tableValue: (columnGenerator) => columnGenerator.generateTextColumn("Address", FIELD_PROPS.ADDRESS),
      },
      {
        title: "Phone",
        prop: FIELD_PROPS.PHONE,
        section: ABOUT_SECTION,
        defaultValue: false,
        tableValue: (columnGenerator) => columnGenerator.generateTextColumn("Phone", FIELD_PROPS.PHONE),
      },
      {
        title: "Website",
        prop: FIELD_PROPS.WEBSITE,
        section: ABOUT_SECTION,
        defaultValue: false,
        tableValue: (columnGenerator) => columnGenerator.generateTextColumn("Website", FIELD_PROPS.WEBSITE),
      },
      {
        title: "Services Or Products",
        prop: FIELD_PROPS.SERVICES_OR_PRODUCTS,
        section: ABOUT_SECTION,
        defaultValue: false,
        tableValue: (columnGenerator) => columnGenerator.generateTextColumn("Services Or Products", FIELD_PROPS.SERVICES_OR_PRODUCTS),
      },
      {
        title: "Supplier Rank",
        prop: FIELD_PROPS.SUPPLIER_RANK,
        section: ABOUT_SECTION,
        defaultValue: false,
        tableValue: (columnGenerator) => columnGenerator.generateTextColumn("Supplier Rank", FIELD_PROPS.SUPPLIER_RANK),
      },
      {
        title: "Current State",
        prop: FIELD_PROPS.CURRENT_STATE,
        section: ABOUT_SECTION,
        defaultValue: false,
        tableValue: (columnGenerator) => columnGenerator.generateTextColumn("Current State", FIELD_PROPS.CURRENT_STATE),
      },
      // Audit
      {
        title: "Audit Method",
        prop: FIELD_PROPS.AUDIT_METHOD,
        section: AUDIT_SECTION,
        defaultValue: false,
        tableValue: (columnGenerator) => columnGenerator.generateTextColumn("Audit Method", FIELD_PROPS.AUDIT_METHOD),
      },
      {
        title: "Date Completed",
        prop: FIELD_PROPS.DATE_COMPLETED,
        section: AUDIT_SECTION,
        defaultValue: false,
        filterType: FILTER_TYPES.DATE,
        tableValue: (columnGenerator) => columnGenerator.generateTextColumn("Date Completed", FIELD_PROPS.DATE_COMPLETED),
      },
      {
        title: "Next Audit",
        prop: FIELD_PROPS.NEXT_AUDIT,
        section: AUDIT_SECTION,
        defaultValue: false,
        filterType: FILTER_TYPES.DATE,
        tableValue: (columnGenerator) => columnGenerator.generateTextColumn("Next Audit", FIELD_PROPS.NEXT_AUDIT),
      },
      // Qualifications
      {
        title: "Qualification Status",
        prop: FIELD_PROPS.QUALIFICATION_STATUS,
        section: QUALIFICATION_SECTION,
        defaultValue: true,
        tableValue: (columnGenerator) => columnGenerator.generateTextColumn("Qualification Status", FIELD_PROPS.QUALIFICATION_STATUS),
      },
      {
        title: "Effective Date",
        prop: FIELD_PROPS.EFFECTIVE_DATE,
        section: QUALIFICATION_SECTION,
        defaultValue: false,
        filterType: FILTER_TYPES.DATE,
      },
      {
        title: "Expiration Date",
        prop: FIELD_PROPS.EXPIRATION_DATE,
        section: QUALIFICATION_SECTION,
        defaultValue: true,
        filterType: FILTER_TYPES.DATE,
      },
      {
        title: "Quality Agreement",
        prop: FIELD_PROPS.QUALITY_AGREEMENT,
        section: QUALIFICATION_SECTION,
        defaultValue: false,
        filterType: FILTER_TYPES.COMBO_BOX,
      },
      {
        title: "Supply Agreement",
        prop: FIELD_PROPS.SUPPLY_AGREEMENT,
        section: QUALIFICATION_SECTION,
        defaultValue: false,
        filterType: FILTER_TYPES.COMBO_BOX,
      },
      // Risk
      {
        title: "Risk Rating",
        prop: FIELD_PROPS.RISK_RATING,
        section: RISK_CONTROL_SECTION,
        defaultValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
      },
      {
        title: "Risk Justification",
        prop: FIELD_PROPS.RISK_JUSTIFICATION,
        section: RISK_CONTROL_SECTION,
        defaultValue: false,
      },
      {
        title: "Risk Mitigation",
        prop: FIELD_PROPS.SUPPLIER_RISK_MITIGATION,
        section: RISK_CONTROL_SECTION,
        defaultValue: false,
      },
      {
        title: "Risk Control",
        prop: FIELD_PROPS.RISK_CONTROL,
        section: RISK_CONTROL_SECTION,
        defaultValue: false,
      },
    ];
  }
}
