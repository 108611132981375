"use strict";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import BaseReactComponent from "../../../base_react_component";
import { hasPermissions } from "../../helpers/import_security";
import { createImportLinkURL } from "../../helpers/import_url_factory";
import * as UIUtils from "../../../ui_utils";
import { IMPORT_TYPE_KEY } from "../../constants/import_constants";

/**
 * This renders a card which displays information for a particular import flow, like the Type of data, Release or
 * Manufacturing, some description, etc... Given the inforamtion on this card, the user can tell which import flow
 * it refers to.
 */
export default class ImportFlowCard extends BaseReactComponent {
  handleImportCardClick(e) {
    const {projectId, processId, batchId, onImportDataTypeSelected, enabled} = this.props;
    let {importConfig} = this.props;

    const hasPermission = hasPermissions(importConfig);

    if (enabled && hasPermission) {
      importConfig = {
        projectId,
        processId,
        batchId,
        allowProcessSelection: importConfig.key === IMPORT_TYPE_KEY.COA,
        ...importConfig
      };

      if (onImportDataTypeSelected) {
        onImportDataTypeSelected(importConfig);
      } else {
        window.location.href = createImportLinkURL(importConfig);
      }
    } else {
      UIUtils.ignoreHandler(e);
      return false;
    }
  }

  render() {
    const {enabled, disabledReason, importConfig, className, id} = this.props;
    const {key, dataCategory, title, instructions, infoDescription, showDataCategory} = importConfig;

    return (
      <div key={key}
           id={id || key}
           className={`row batch-import-button-option-container ${enabled ? "" : "disabled"} ${className || ""}`}
           onClick={this.handleImportCardClick}
      >
        <div className={`col batch-import-button-option ${showDataCategory ? "show-top-border" : ""}`}>
          {showDataCategory && dataCategory ? (
            <div key="dataCategoryHeader"
                 className="batch-import-button-option-category"
            >
              {dataCategory.toUpperCase()}
            </div>
          ) : ""}
          <div key="importTitleHeader"
               className={`batch-import-button-option-title ${showDataCategory ? "" : "no-margin"} ${enabled ? "" : "disabled"}`}
          >
            {title}
          </div>
          <div key="importFlowDescription"
               className="batch-import-button-option-description"
          >
            {instructions}
            {infoDescription && !enabled ?
              <div className="import-project-widget-info">
                <div>
                  <FontAwesomeIcon icon={faInfoCircle} className="table-description-info" />
                </div>
                <div>
                  <span>{disabledReason || infoDescription}</span>
                </div>
              </div>
              : ""}
          </div>
        </div>
      </div>
    );
  }
}
