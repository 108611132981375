"use strict";

import * as pdfjs from "pdfjs-dist/lib/pdf";
import {PDFJS, GlobalWorkerOptions, getDocument} from "pdfjs-dist";
import * as worker from "pdfjs-dist/build/pdf.worker.entry";
import * as viewer from "pdfjs-dist/web/pdf_viewer";

GlobalWorkerOptions.workerSrc = worker;

/**
 * @typedef {typeof import("pdfjs-dist/index.d.ts").PDFSource} PDFSource
 */

/**
 * @typedef {typeof import("pdfjs-dist/index.d.ts").PDFJSStatic} PDFJSStatic
 **/

/**
 * @typedef {typeof import("pdfjs-dist/index.d.ts").TextLayerBuilder} TextLayerBuilder
 **/

/**
 * @typedef {typeof import("pdfjs-dist/index.d.ts").PDFPageViewport} PDFPageViewport
 **/

/**
 * @typedef {typeof import("pdfjs-dist/index.d.ts").PDFPageProxy} PDFPageProxy
 */

/**
 * @typedef {typeof import("pdfjs-dist/index.d.ts").PDFDocumentProxy} PDFDocumentProxy
 */

/**
 * @template T
 * @typedef {typeof import("pdfjs-dist/index.d.ts").PDFPromise<T>} PDFPromise<T>
 */

/**
 * @typedef {typeof import("pdfjs-dist").PDFJS.EventBus} EventBus
 */

/**
 * @callback TextLayerFactory
 * @param {HTMLElement} textLayerDiv
 * @param {number} pageIndex
 * @param {PDFPageViewport} viewport
 */

/**
 * @typedef pdfjsViewerLibExt {typeof viewer}
 * @property {EventBus} EventBus
 * @property {PDFPageProxy} PDFPageProxy
 * @property {PDFDocumentProxy} PDFDocumentProxy
 * @property {PDFPageViewport} PDFPageViewport
 * @property {TextLayerBuilder} TextLayerBuilder
 * @property {TextLayerFactory} TextLayerFactory
 */

/**
 * @typedef {typeof PDFJSStatic|typeof pdfjsLib|typeof pdfJS} pdfJSLibExt
 * @property {typeof pdfjsViewerLibExt} viewer
 * @property {typeof worker} worker
 */

/**
 * @type {typeof pdfJSLibExt}
 */
const pdfJS = {
  ...PDFJS,
  pdfjs,
  viewer,
  worker,
  getDocument,
};

export default pdfJS;