import { addRowBefore, addRowAfter, addColumnBefore, addColumnAfter, deleteRow, deleteColumn, deleteTable, mergeCells, splitCell, selectionCell } from '@progress/kendo-editor-common';
import { createProseMirrorTool } from './proseMirrorTool';
/**
 * @hidden
 */
var deleteColumnCmd = function (state, dispatch) {
    var result = deleteColumn(state, dispatch);
    if (result && !dispatch) {
        var pos = selectionCell(state);
        var row = pos && pos.node(pos.depth);
        if (row && row.content.childCount === 1) {
            return false;
        }
    }
    return result;
};
/**
 * @hidden
 */
var deleteRowCmd = function (state, dispatch) {
    var result = deleteRow(state, dispatch);
    if (result && !dispatch) {
        var pos = selectionCell(state);
        var rowParent = pos && pos.node(pos.depth - 1);
        if (rowParent && rowParent.content.childCount === 1) {
            return false;
        }
    }
    return result;
};
/**
 * @hidden
 */
export var TableEditNS;
(function (TableEditNS) {
    /**
     * @hidden
     */
    TableEditNS.createAddRowBeforeTool = function (settings) {
        return createProseMirrorTool(settings, addRowBefore);
    };
    /**
     * @hidden
     */
    TableEditNS.createAddRowAfterTool = function (settings) {
        return createProseMirrorTool(settings, addRowAfter);
    };
    /**
     * @hidden
     */
    TableEditNS.createAddColumnBeforeTool = function (settings) {
        return createProseMirrorTool(settings, addColumnBefore);
    };
    /**
     * @hidden
     */
    TableEditNS.createAddColumnAfterTool = function (settings) {
        return createProseMirrorTool(settings, addColumnAfter);
    };
    /**
     * @hidden
     */
    TableEditNS.createDeleteRowTool = function (settings) {
        return createProseMirrorTool(settings, deleteRowCmd);
    };
    /**
     * @hidden
     */
    TableEditNS.createDeleteColumnTool = function (settings) {
        return createProseMirrorTool(settings, deleteColumnCmd);
    };
    /**
     * @hidden
     */
    TableEditNS.createDeleteTableTool = function (settings) {
        return createProseMirrorTool(settings, deleteTable);
    };
    /**
     * @hidden
     */
    TableEditNS.createMergeCellsTool = function (settings) {
        return createProseMirrorTool(settings, mergeCells);
    };
    /**
     * @hidden
     */
    TableEditNS.createSplitCellTool = function (settings) {
        return createProseMirrorTool(settings, splitCell);
    };
})(TableEditNS || (TableEditNS = {}));
