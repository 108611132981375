"use strict";

import React from "react";
import QTPP from "../pages/qtpp";
import TPP from "../pages/tpp";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import { PRODUCT_LIST_TABS, PRODUCT_RELATED_TYPES, } from "../product_constants";
import AddButton from "../widgets/add_button";
import BaseListPage from "./base_list_page";

/* This shows the product page that has TPP and QTPP tabs. */

// i18next-extract-mark-ns-start qtpp
class ProductListPage extends BaseListPage {

  constructor(props) {
    super(props);

    const cacheName = "Project";
    let projectCache = new TypeaheadObjectCache(cacheName);
    const projectTypeaheadOptions = projectCache.getOptionsFromCacheIncludingArchived();
    let thisProject = projectTypeaheadOptions.find(option => option.id === props.projectId);
    if (!thisProject) {
      projectCache.invalidateCacheOptionsAsync().then(() => {
        projectCache.loadOptions((options) => {
          thisProject = options.find(option => option.id === props.projectId);
          this.setStateSafely({project: thisProject});
        });
      });
    } else {
      this.setStateSafely({project: thisProject});
    }
  }

  componentDidMount() {
    document.title = "QbDVision Products";

    super.componentDidMount();
  }

  getRelatedTypes() {
    return [...PRODUCT_RELATED_TYPES];
  }

  renderAddRecordButton() {
    const {project} = this.state;
    const {
      projectId,
      selectedProductListTab,
    } = this.props;

    return (
      <AddButton buttonClassName="product-tables-add-button ml-1"
                 project={project}
                 projectId={projectId}
                 selectedProductListTab={selectedProductListTab}
      />
    );
  }

  renderSelectedRecord(commonProps) {
    const {
      selectedProductListTab,
    } = this.props;

    return (
      <div className="row">
        {selectedProductListTab === PRODUCT_LIST_TABS.QTPP ?
          <QTPP {...commonProps} /> :
          <TPP {...commonProps} />}
      </div>
    );
  }
}

export default I18NWrapper.wrap(ProductListPage, "qtpp");
// i18next-extract-mark-ns-stop qtpp
