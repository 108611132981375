import React from "react";
import {createHTMLForTextDiff} from "../../helpers/diff_helper.js";
import BaseReactComponent, {IBaseComponentStateAndProps} from "../../base_react_component";

export interface IProjectAndProcessLabelProps extends IBaseComponentStateAndProps {
  project: any;
  process: any;
  isDiff: boolean;
  id: string;
  oldProject?: any;
  oldProcess?: any;
  ref?: React.RefObject<ProjectAndProcessLabel>;
  showEmptyProject?: boolean;
}

/**
 * Displays the specified project and process
 * @param props
 * @constructor
 */
export class ProjectAndProcessLabel extends BaseReactComponent<IProjectAndProcessLabelProps, any> {
  render() {
    const {
      project,
      process,
      isDiff,
      oldProject,
      oldProcess,
      id,
      showEmptyProject,
    } = this.props;

    let label;

    if (isDiff) {
      let createDiffMethod = createHTMLForTextDiff;
      if ((oldProject?.name || project?.name) && (oldProcess?.name || process?.name)) {
        label = (
          <>
            {createDiffMethod(oldProject?.name, project?.name)},&nbsp;
            {createDiffMethod(oldProcess?.name, process?.name)}
          </>
        );
      } else if (oldProject?.name || project?.name) {
        label = <>{createDiffMethod(oldProject?.name, project?.name)}, No Process</>;
      } else {
        label = createDiffMethod(oldProject?.name, project?.name);
      }
    } else {
      let projectName = project?.name ?? (showEmptyProject ? "No Project" : "");
      let processName = process?.name ?? (projectName ? "No Process" : "");
      label = (
        <span id={id}>
          <span id={`${id}_ProjectName`}>{projectName}</span>{processName ? <>,&nbsp;</> : ""}
          <span id={`${id}_ProcessName`}>{processName}</span>
        </span>
      );
    }

    return <span className={this.isLoading() ? "skeleton" : ""}>{label}</span>;
  }
}
