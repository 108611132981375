import { Keys } from '@progress/kendo-react-common';
import * as idUtils from './itemIdUtils';
import { isItemExpandedAndWithChildren, isEnabledAndAllParentsEnabled } from './itemUtils';
/**
 * @hidden
 */
export default function getItemIdUponKeyboardNavigation(item, itemId, items, keyCode, fieldsSvc) {
    switch (keyCode) {
        case Keys.left:
            return getIdUponLeftKey();
        case Keys.right:
            return getIdUponRightKey();
        case Keys.up:
            return getIdUponUpKey();
        case Keys.down:
            return getIdUponDownKey();
        case Keys.home:
            return idUtils.ZERO_LEVEL_ZERO_NODE_ID;
        case Keys.end:
            return getIdUponEndKey();
        default:
            return itemId;
    }
    function getIdUponLeftKey() {
        return idUtils.isIdZeroLevel(itemId)
            // For expanded disabled items, navigate directly to the parent.
            // Collapse cannot happen because an event is not fired to the client.
            || (fieldsSvc.expanded(item) && isEnabledAndAllParentsEnabled(itemId, items, fieldsSvc)) ?
            itemId : idUtils.getDirectParentId(itemId);
    }
    function getIdUponRightKey() {
        return isItemExpandedAndWithChildren(item, fieldsSvc) ? idUtils.getFirstChildId(itemId) : itemId;
    }
    function getIdUponUpKey() {
        var index = Number(idUtils.getShortId(itemId));
        var parentId = idUtils.getDirectParentId(itemId);
        return index ? getLastNotOpenedChildId(idUtils.createId(index - 1, parentId), items, fieldsSvc) :
            idUtils.isIdZeroLevel(itemId) ? itemId : parentId;
    }
    function getIdUponDownKey() {
        if (isItemExpandedAndWithChildren(item, fieldsSvc)) {
            return idUtils.getFirstChildId(itemId);
        }
        else {
            return getNextItemId(itemId, items, fieldsSvc) || itemId;
        }
    }
    function getIdUponEndKey() {
        var result = (items.length - 1).toString();
        var currentItem = items[items.length - 1];
        var children;
        while (isItemExpandedAndWithChildren(currentItem, fieldsSvc)) {
            children = currentItem[fieldsSvc.getChildrenField()];
            result = idUtils.createId(children.length - 1, result);
            currentItem = children[children.length - 1];
        }
        return result;
    }
}
function getNextItemId(itemId, items, fieldsSvc) {
    var parentId = idUtils.getDirectParentId(itemId);
    var parent = parentId ? idUtils.getItemById(parentId, items, fieldsSvc.getChildrenField()) : undefined;
    var siblings = parent ? parent[fieldsSvc.getChildrenField()] : items;
    var index = Number(idUtils.getShortId(itemId));
    return index < siblings.length - 1 ?
        idUtils.createId(index + 1, parentId) :
        parent ? getNextItemId(parentId, items, fieldsSvc) : undefined;
}
function getLastNotOpenedChildId(itemId, items, fieldsSvc) {
    var item = idUtils.getItemById(itemId, items, fieldsSvc.getChildrenField());
    if (isItemExpandedAndWithChildren(item, fieldsSvc)) {
        return getLastNotOpenedChildId(idUtils.createId(item[fieldsSvc.getChildrenField()].length - 1, itemId), items, fieldsSvc);
    }
    else {
        return itemId;
    }
}
