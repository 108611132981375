"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import { ADD_TYPES } from "../constants/constants";
import { can, generateTooltip } from "../../utils/ui_permissions";
import DropdownButton from "../../widgets/generic/dropdown_button";
import CommonSecurity from "../../../server/common/generic/common_security";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseReactComponent from "../../base_react_component";
import { RouterContext } from "../../utils/router_context";

/**
 *  This shows the split add button for documents.
 */
// i18next-extract-mark-ns-start documents
class AddButton extends BaseReactComponent {
  static contextType = RouterContext;

  constructor(props) {
    super(props);
  }

  handleDocumentAddClick(event) {
    if (event.target.innerText === "Add multiple") {
      $("#documentBulkAddFilesSelector").click();
    } else {
      let url = "/documents/viewEdit.html?operation=Add";
      if (this.props.projectId) {
        url += "&projectId=" + this.props.projectId;
      }

      this.context.navigate(UIUtils.getSecuredURL(url));
    }
  }

  handleOpenFilesSelector() {
    $("#documentBulkAddFilesSelector").prop("value", "");
  }

  render() {
    const {t} = this.props;

    return (
      <Fragment>
        <div className="document-bulk-add-split-button-div">
          <InfoTooltip id="infoDocuments"
                       textDirection="left"
                       verbiage={<div>{t("You can add by dragging and dropping documents to this window.")}</div>}
          />
          <DropdownButton options={ADD_TYPES}
                          removeTitleIcon={true}
                          className="btn-group document-bulk-add-split-button"
                          id="documentAdd"
                          text={t("Add")}
                          title={generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.DOCUMENT)}
                          isDisabled={!can(CommonSecurity.Actions.ADD, CommonSecurity.Types.DOCUMENT)}
                          onOptionsSelect={this.handleDocumentAddClick}
          />
        </div>
        <input id="documentBulkAddFilesSelector"
               type="file"
               accept=".pdf"
               multiple={true}
               className="links-file-input form-control"
               onClick={this.handleOpenFilesSelector}
               onChange={this.props.onFilesSelection}
        />
        <input id="documentBulkAddTestFilesSelector"
               className="document-bulk-add-hidden-file-selector"
               type="file"
               multiple={true}
        />
      </Fragment>

    );
  }
}

export default I18NWrapper.wrap(AddButton, "documents");
// i18next-extract-mark-ns-stop documents

