"use strict";


import BaseReactComponent from "../../../base_react_component";
import React from "react";
import StepWizard from "react-step-wizard";
import LinkTechTransferWizardNavBar from "./link_tech_transfer_wizard_nav_bar";
import { WIZARD_STEPS, WIZARD_STEPS_CONFIG } from "./constants/link_tech_transfer_constants";
import * as UIUtils from "../../../ui_utils";

export default class LinkTechTransferWizard extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.setStateSafely({
      currentWizardStep: WIZARD_STEPS.ProcessSelection,
    });
  }

  componentDidMount() {
    super.componentDidMount();
  }

  handleMoveToStep(step) {
    const newStep = WIZARD_STEPS_CONFIG.find(config => config.step === step);
    const newStepIndex = newStep.stepIndex;
    let currentStepName = UIUtils.stripAllWhitespaces(this.state.currentWizardStep);
    this[currentStepName].deactivate(newStepIndex);
    this.setStateSafely({
      currentWizardStep: newStep.step,
    });
  }

  getWizardSteps() {
    return WIZARD_STEPS_CONFIG.map(wizardStepConfig => {
      let stepName = UIUtils.stripAllWhitespaces(wizardStepConfig.step);

      return React.createElement(wizardStepConfig.type,
        {
          key: stepName,
          ref: (step) => {
            return this[stepName] = step;
          },
          config: wizardStepConfig,
          handleMoveToStep: this.handleMoveToStep,
          parent: this,
          ...this.props,
          ...this.state,
        });
    });
  }

  handleWizardStepChange(changeInfo) {
    let stepConfig = WIZARD_STEPS_CONFIG.find(config => config.stepIndex === changeInfo.activeStep);
    let stepName = UIUtils.stripAllWhitespaces(stepConfig.step);
    this[stepName].activate(changeInfo);
  }

  render() {
    const {currentWizardStep} = this.state;
    return (
      <StepWizard nav={<LinkTechTransferWizardNavBar parent={this}
                                                     currentWizardStep={currentWizardStep}
                                                     onTabNavItemClicked={this.handleMoveToStep}
      />}
                  instance={wizard => this.wizard = wizard}
                  onStepChange={this.handleWizardStepChange}
                  transitions={
                    {
                      enterRight: "",
                      enterLeft: "",
                      exitRight: "",
                      exitLeft: ""
                    }
                  }
                  isLazyMount={true}
      >
        {this.getWizardSteps()}
      </StepWizard>
    );
  }
}