"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
// noinspection NpmUsedModulesInstalled
import * as PropTypes from "prop-types";

import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import BaseReactComponent from "../../base_react_component";

export class AttributeLink extends BaseReactComponent {
  render() {
    const {
      instance,
      baseTypeName,
      renderName,
      className,
      noId,
    } = this.props;

    if (!instance) {
      return "";
    }

    if (!instance.typeCode) {
      instance.typeCode = UIUtils.getTypeCodeForModelName(instance.modelType || baseTypeName);
    }

    const objId = instance.id;
    const objName = typeof renderName === "function" ? renderName(instance) : instance.name;

    return (
      <a className={className}
         href={getURLByTypeCodeAndId(instance.typeCode, "View", objId, false)}
         target="_blank"
         rel="noopener noreferrer"
      >
        {noId ? objName : UIUtils.getRecordCustomLabelForDisplay(instance)}
      </a>
    );
  }
}

AttributeLink.propTypes = {
  instance: PropTypes.any,
  baseTypeName: PropTypes.string,
  renderName: PropTypes.func,
  noId: PropTypes.bool,
  className: PropTypes.string,
};

AttributeLink.defaultProps = {
  instance: null,
  baseTypeName: null,
  renderName: (item => item.name),
  noId: false,
};
