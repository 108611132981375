"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { CompositeRiskTooltip } from "../widgets/composite_risk_tooltip";
import { RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import { getURLByTypeCodeAndId } from "../../../helpers/url_helper";
import { ValueTooltip } from "../widgets/value_tooltip";
import { RiskScore } from "../widgets/risk_score";
import { ValueCell } from "../widgets/value_cell";
import { NoDataCell } from "../widgets/no_data_cell";
import { getStampFromState } from "./risk_tables_helper";
import CommonUtils from "../../../../server/common/generic/common_utils";
import { getFilteredRMPForType } from "../../../../server/common/misc/common_risk_utils";

export function renderProcessRisk(columnAttribute, riskType, rmp, showRawScore, labelAction) {
  return ReactDOMServer.renderToStaticMarkup(
    <RiskScore
      attribute={columnAttribute}
      riskType={riskType}
      rmp={rmp}
      showRawScore={showRawScore}
      labelAction={labelAction}
      tooltipRiskType={RISK_TYPE_ENUM.CAPABILITY_RISK} />
  );
}

export function renderRPN(columnAttribute, riskType, rmp, showRawScore, labelAction) {
  return ReactDOMServer.renderToStaticMarkup(
    <RiskScore
      attribute={columnAttribute}
      riskType={riskType}
      rmp={rmp}
      showRawScore={showRawScore}
      labelAction={labelAction}
      tooltipRiskType={RISK_TYPE_ENUM.DETECTABILITY_RISK} />
  );
}

export function renderRecommendedActions(columnAttribute) {
  return ReactDOMServer.renderToStaticMarkup(
    <ValueCell value={columnAttribute.recommendedActions} />
  );
}

export function renderControlStrategy(columnAttribute) {
  let controlStrategy = columnAttribute.controlStrategy;
  let controlStrategyJustification = columnAttribute.controlStrategyJustification;

  return ReactDOMServer.renderToStaticMarkup(
    <span data-trigger="click hover"
          data-html="true"
          data-placement="right"
          data-container="body"
          data-toggle="popover"
          data-content={renderJustificationTooltip(controlStrategyJustification)}>
      {(controlStrategy ? controlStrategy : "")}
    </span>);
}

export function renderControlMethod(columnAttribute) {
  let controlMethods = columnAttribute.controlMethods;

  if (controlMethods && controlMethods.length > 0) {
    return ReactDOMServer.renderToStaticMarkup(
      <div>
        {controlMethods.map(
          controlMethod => {
            controlMethod.modelName = "ControlMethod";
            return (
              <div key={controlMethod.id}
                   className="risk-tables-multi-link">
                <div key={controlMethod.id + "-1"}>
                     <span data-trigger="hover"
                           data-html="true"
                           data-placement="right"
                           data-container="body"
                           data-toggle="popover"
                           data-content={renderControlMethodTooltip(controlMethod.status)}>
                       <a href={getURLByTypeCodeAndId("CM", "View", controlMethod.id)}
                          rel="noopener noreferrer"
                          target="_blank"
                          className="risk-tables-row-header-cell">
                         {CommonUtils.getRecordCustomLabelForDisplay(controlMethod)}
                       </a>
                     </span>
                </div>
                <div key={controlMethod.id + "-2"}>
                  <span className="risk-tables-column-stamp">{getStampFromState(controlMethod.state)}</span>
                </div>
              </div>
            );
          })}
      </div>
    );
  } else {
    return ReactDOMServer.renderToStaticMarkup(
      <NoDataCell />
    );
  }
}

export function renderScaleDependency(columnAttribute) {
  let scaleDependent = columnAttribute.scaleDependent;
  let scaleJustification = columnAttribute.scaleJustification;

  return ReactDOMServer.renderToStaticMarkup(
    <span data-trigger="click hover"
          data-html="true"
          data-placement="right"
          data-container="body"
          data-toggle="popover"
          data-content={renderJustificationTooltip(scaleJustification)}>{(typeof scaleDependent === "string" ? scaleDependent : "")}</span>
  );
}

export function renderPotentialFailureModes(columnAttribute) {
  let potentialFailureModes = columnAttribute.potentialFailureModes;
  return ReactDOMServer.renderToStaticMarkup(
    <span>{(potentialFailureModes ? potentialFailureModes : "")}</span>
  );
}


// To optimize the performance of large risk tables (See QI-3955)
const criticalityToTooltipCache = new Map();

export function renderCriticalityTooltip(rmp, impact, uncertainty, typeCodeOrModelName = null) {
  let tooltipRMP = getFilteredRMPForType(rmp, typeCodeOrModelName);
  const criticalityKey = impact + "|" + uncertainty + "|" + rmp.id;
  let tooltip;
  if (criticalityToTooltipCache.has(criticalityKey)) {
    tooltip = criticalityToTooltipCache.get(criticalityKey);
  } else {
    tooltip = ReactDOMServer.renderToStaticMarkup(
      <CompositeRiskTooltip
        risks={[
          {type: RISK_TYPE_ENUM.IMPACT, value: impact},
          {type: RISK_TYPE_ENUM.UNCERTAINTY, value: uncertainty},
        ]}
        rmp={tooltipRMP}
      />
    );
    criticalityToTooltipCache.set(criticalityKey, tooltip);
  }

  return tooltip;
}

/**
 * Renders a criticality tooltip that may display multiple risk links information if more than 1 is available.
 * @param rmp {*} The Risk Management Plan being used
 * @param row {*} The data row being rendered
 * @return {string} A string containing the rendered HTML markup
 */
export function renderCompositeCriticalityTooltip(rmp, row) {
  return row.maxRiskLinks && row.maxRiskLinks instanceof Array && row.maxRiskLinks.length > 1
    ? ReactDOMServer.renderToStaticMarkup(
      row.maxRiskLinks.map((link, index) => {
        let linkRMP = getFilteredRMPForType(rmp, link.linkedTypeCode);
        return <div key={link.id} style={index > 0 ? {paddingTop: "0.8em"} : {}}>
          <p>
            <a href={getURLByTypeCodeAndId(link.linkedTypeCode, "View", link.linkedId)}
               rel="noopener noreferrer"
               target="_blank"
               className="risk-tables-row-header-cell tooltip-link"
            >{UIUtils.getRecordCustomLabelForDisplay({typeCode: link.linkedTypeCode, id: link.linkedId, name: link.name})}</a>
          </p>
          <p>
            {<CompositeRiskTooltip
              risks={[
                {type: RISK_TYPE_ENUM.IMPACT, value: link.impact},
                {type: RISK_TYPE_ENUM.UNCERTAINTY, value: link.uncertainty},
              ]}
              rmp={linkRMP}
            />}
          </p>
        </div>;
      })
    ) : renderCriticalityTooltip(rmp, row.impact, row.uncertainty);
}

export function renderCapabilityRiskTooltip(capabilityRisk, rmp) {
  return ReactDOMServer.renderToStaticMarkup(
    <CompositeRiskTooltip
      risks={[
        {type: RISK_TYPE_ENUM.CAPABILITY_RISK, value: capabilityRisk}
      ]}
      rmp={rmp}
    />
  );
}

export function renderDetectabilityRiskTooltip(detectabilityRisk, rmp) {
  return ReactDOMServer.renderToStaticMarkup(
    <CompositeRiskTooltip
      risks={[
        {type: RISK_TYPE_ENUM.DETECTABILITY_RISK, value: detectabilityRisk}
      ]}
      rmp={rmp}
    />
  );
}

export function renderJustificationTooltip(justification) {
  return ReactDOMServer.renderToStaticMarkup(
    <ValueTooltip label="Justification" value={justification} />
  );
}

export function renderControlMethodTooltip(status) {
  return ReactDOMServer.renderToStaticMarkup(
    <ValueTooltip label="Status" value={status} />
  );
}
