"use strict";

import { WebSocketsServiceProxy } from "./serviceProxies/websockets_service_proxy";
import { AjaxServiceProxy } from "./serviceProxies/ajax_service_proxy";

/**
 * Base client service class for submitting a request to the backend. It supports both submitting requests with Ajax
 * and with WebSockets. The consumer of this calls can decide which technology to use transparently of the
 * implementation.
 */
export class ServiceBase {

  constructor(messageHandler = null, statusServiceParam = null) {
    this.twoWayService = new WebSocketsServiceProxy(messageHandler, statusServiceParam);
    this.requestResponseService = new AjaxServiceProxy();
  }

  /**
   * Sends a request to the backend, either using Ajax or Websockets.
   * @param payload The payload to include in the request
   * @param parameters The request parameters. The client can control which technology to use for submitting the
   * request by setting the useTwoWayCommunication in the parameters object. When useTwoWayCommunication is true,
   * then Websockets is being used for opening a connection to the backend, otherwise an Ajax request is submitted.
   * @returns {Promise<ISaveResult|*>}
   */
  async sendToBackend(payload, parameters) {
    let result;
    // Uncomment for verbose logging
    // console.log("Parameters", parameters);
    if (parameters.useTwoWayCommunication) {
      result = await this.twoWayService.connect(payload, parameters);
    } else {
      result = await this.requestResponseService.submitPutRequest(payload, parameters);
    }
    return result;
  }

  /**
   * This disconnects any clients using a two way service to connect to the backend.
   * @param parameters
   */
  disconnect(parameters) {
    this.twoWayService.disconnect(parameters);
  }
}