"use strict";

import React, { Fragment } from "react";
import StaticPanel from "../../editor/quick/static_panel";
import { EDITOR_TYPES } from "../../editor/editor_constants";
import { CLONING_PLUGINS } from "../../widgets/cloning/copy_record_popup_plugins/plugins_config";
import CopyRecordPopup from "../../widgets/cloning/copy_record_popup";
import CopyRecordFromPopup from "../../widgets/cloning/copy_record_from_popup";
import EditablesPageTitleBar from "../../widgets/pageTitleBar/editables_page_title_bar";
import NavBar from "../../widgets/bars/nav_bar";
import Heading from "../../widgets/headers/heading";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import ErrorBar from "../../widgets/bars/error_bar";
import SplashScreen from "../../widgets/generic/splash_screen";
import BaseAutoBind from "../../base_auto_bind";
import ApprovalRequestPopup from "../../editor/approval/approval_request_popup";

export default class ProcessExplorerRenderer extends BaseAutoBind {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.projectId = props.projectId;
  }

  renderStaticPanel(panelInfo = this.parent.state.staticPanelInfo,
                    selectedRecord = this.parent.state.selectedRecord,
                    isFullWidth = false,
                    forcedHeight = null) {

    const {parent} = this;
    let staticPanelConfig = {
      sectionsCollapsed: true,
    };

    const typeCode = panelInfo ? panelInfo.typeCode : undefined;
    const id = panelInfo ? panelInfo.id : undefined;
    const {isProposeForArchiveOrRestore} = parent.state;

    return (
      <StaticPanel typeCode={typeCode}
                   ref={parent.staticPanel}
                   id={id}
                   config={staticPanelConfig}
                   forcedHeight={forcedHeight}
                   editorType={isFullWidth ? EDITOR_TYPES.STATIC_PANEL_FULL_WIDTH : EDITOR_TYPES.STATIC_PANEL}
                   editorOperation={panelInfo ? panelInfo.operation : undefined}
                   emptySelectionMessage="Click on any record to edit it here."
                   data={selectedRecord}
                   isProposeForArchiveOrRestore={isProposeForArchiveOrRestore}
                   onSaveCompleted={parent.staticPanelOperator.handleSaveInStaticPanel}
                   onCancelLoad={parent.staticPanelOperator.handleCancelLoad}
                   onChangeEditorOperation={parent.staticPanelOperator.handleChangeEditorType}
                   onCloseWindow={parent.staticPanelOperator.handleCloseStaticPanel}
                   processExplorerHelper={this.parent.processExplorerHelper}
      />
    );
  }

  renderApprovalRequestPopup() {
    const {parent} = this;
    const {state} = parent;
    return (
      <ApprovalRequestPopup modalRef={approvalPopup => parent.approvalPopup = approvalPopup}
                            action={state.action}
                            message={state.message}
                            warning={state.warning}
                            onHideModal={parent.recordOperationsHandler.handleHideApprovalPopup}
                            onSendApprovalRequest={parent.recordOperationsHandler.handleProposeForArchiveOrRestoreConfirmation}
                            parent={parent}
      />
    );
  }

  renderCopyPopup() {
    const {parent} = this;
    const plugins = [];
    let staticPanelRecord;

    staticPanelRecord = parent.processExplorerHelper.getFullRecordForCopy();

    switch (staticPanelRecord.modelName) {
      case "Process":
        plugins.push(CLONING_PLUGINS.RESET_TECH_TRANSFER);
        break;
      case "UnitOperation":
        plugins.push(CLONING_PLUGINS.COPY_AS_SHARED);
        break;
      case "IQA":
      case "IPA":
        plugins.push(CLONING_PLUGINS.COPY_TO_NEW_UO);
        break;
    }

    return <div>
      <CopyRecordPopup modalRef={copyRecordPopup => parent.copyRecordPopup = copyRecordPopup}
                       onHideModal={parent.copyHandler.handleHideCopyButtonClick}
                       onCopyStarted={parent.copyHandler.handleCopyStarted}
                       onCopyCompletion={parent.copyHandler.handleCopyCompletion}
                       parent={this}
                       customNameValidator={parent.processExplorerHelper.validateDuplicateNames}
                       showAsOfDate={true}
                       objectToCopy={staticPanelRecord}
                       plugins={plugins}
      />
    </div>;
  }

  renderCopyFromPopup(project, process) {
    const {parent} = this;
    return (
      <CopyRecordFromPopup
        modalRef={(copyRecordFromPopup) =>
          (parent.copyRecordFromPopup = copyRecordFromPopup)
        }
        parent={parent}
        toProject={project}
        toProcess={process}
        selectedNode={parent.state.showCopySelectedNode}
        copyFromForTypeCode={parent.state.copyFromForTypeCode}
        onCopyStarted={parent.copyHandler.handleCopyStarted}
        onCopyCompletion={parent.copyHandler.handleCopyFromCompletion}
        onHideModal={parent.copyHandler.handleHideCopyFromButtonClick}
      />
    );
  }

  renderSplashScreen(project) {
    return (
      <Fragment>
        <EditablesPageTitleBar projectId={this.projectId}
                               projectName={project?.name ?? ""}
                               projectDeletedAt={project?.deletedAt ?? null}
                               isDemoProject={project?.isDemo ?? ""}
                               currentState={project?.currentState ?? null}
        />
        <NavBar selected="Process Explorer"
                projectId={this.projectId}
        />
        <div className="container">
          <Heading level={1}
                   className="header-banner"
          >
            Process Explorer
            <InfoTooltip id="infoProcessExplorer"
                         verbiage={<div>
                           Use this to describe your manufacturing process and rate the risks involved in the various
                           activities.
                         </div>}
            />
          </Heading>
        </div>
        <ErrorBar />
        <SplashScreen />
      </Fragment>
    );
  }
}
