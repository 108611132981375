"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseAttribute from "./base_attribute";
import { createHTMLForWholeTextDiff } from "../../helpers/diff_helper";
import LabelTooltip from "../../widgets/tooltips/label_tooltip";

export default class CheckboxAttribute extends BaseAttribute {
  constructor(props) {
    super(props);
    this.state = {};

    // Bind functions to this
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.parent.handleChangeValue(this.props.name, event.target.checked);
  }

  // noinspection JSMethodCanBeStatic
  getInitialValue() {
    return false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      let value = this.getNextValue(nextProps);
      return (this.currentValue !== !!value);
    }
    return true;
  }

  getValue() {
    let value = super.getValue();
    let {checkedByDefault} = this.props;

    return this.isAdd() && checkedByDefault && !this._isMounted ? checkedByDefault : value;
  }

  render() {
    let input;
    let inputId = this.getInputId();
    if (this.isView()) {
      this.currentValue = this.getValue() ? "Yes" : "No";
      if (this.isDiffingVersions()) {
        let hasOldValue = this.hasOldValue(this.props.name);
        let oldValue = this.getOldValue(this.props.name);
        input = createHTMLForWholeTextDiff(hasOldValue ? (oldValue ? "Yes" : "No") : "", this.currentValue);
      } else {
        input = (
          <span id={inputId}>{this.currentValue}</span>
        );
      }
      return (
        <div className={"attribute-container " + this.props.className}>
          <LabelTooltip id={inputId + "Label"}
                        className="col-form-label"
                        tooltipText={this.props.tooltipText}
                        tooltipGuidanceURL={this.props.tooltipGuidanceURL}
                        tooltipGuidancePage={this.props.tooltipGuidancePage}
                        tooltipGuidanceOffset={this.props.tooltipGuidanceOffset}
                        style={this.props.labelStyle ? this.props.labelStyle : {}}
                        text={this.getDisplayName()}
                        indicator={(
                          <span className={this.getRequiredFieldIndicatorClass()}>{!this.isView() && this.isRequiredForSaving() ? " * " : !this.isView() && this.isRequiredForProposing() ? " ** " : ""}</span>
                        )}
          />
          <div className={"view-attribute" + this.getClassForLoading()}
               id={inputId + "Div"}
          >
            {input}
          </div>
        </div>
      );
    } else {
      // We have to special case the checkbox because the label has to wrap around the input
      this.currentValue = this.getValue();
      return (
        <div className={"attribute-container " + this.props.className}>
          <div className="form-group">
            <LabelTooltip id={inputId + "Label"}
                          className={"col-form-label-checkbox" + this.getClassForLoading() + (this.currentValue ? "checked" : "")}
                          tooltipText={this.props.tooltipText}
                          style={this.props.labelStyle ? this.props.labelStyle : {}}
                          htmlFor={inputId}
                          text={(
                            <span>
                              <input type="checkbox"
                                     id={inputId}
                                     checked={this.currentValue}
                                     onChange={this.handleChange}
                                     disabled={this.isDisabled()}
                              />
                              <span className={"control-text-checkbox-edit" + (this.props.tooltipText ? " col-form-label-tooltip" : "")}>
                                {this.getDisplayName()}
                              </span>
                            </span>
                          )}
                          indicator={(
                            <span className={this.getRequiredFieldIndicatorClass()}>{!this.isView() && this.isRequiredForSaving() ? " *" : !this.isView() && this.isRequiredForProposing() ? " **" : ""}</span>
                          )}
            />
            {this.props.tooltipGuidanceURL ? (
              <a target="_blank"
                 rel="noopener noreferrer"
                 id={inputId + "Guidance"}
                 href={UIUtils.computePdfURL(this.props.tooltipGuidanceURL, this.props.tooltipGuidancePage, this.props.tooltipGuidanceOffset)}
              > (Guidance)</a>
            ) : ""}
            <div className="help-block with-errors" />
          </div>
        </div>
      );
    }
  }
}
