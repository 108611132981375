"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import {
  applyStreamFilters,
  getHistoryPointForDate,
  shouldRenderFullColor,
  showByRiskLabel,
  shouldRender, getControlStrategiesToShowForNode
} from "./utilities/risk_map_report_helper";
import { resetGraphToDefaultView } from "./utilities/risk_map_layout_helper";
import { RISK_MAP_COLORS } from "../constants/report_constants";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { calculateTransparentColor } from "../../utils/color_calculator";
import { getRiskScale, getRiskScores } from "../../helpers/risk_helper";
import { RISK_COLORS } from "../../rmps/constants/rmp_constants";
import RiskMapSelectionHandler from "./utilities/risk_map_selection_handler";
import { Network } from "vis-network/peer";
import { DataSet } from "vis-data/peer";
import { createCanvasWithRiskMapPicture } from "./utilities/risk_map_report_canvas_helper";
import CommonRiskUtils from "../../../server/common/misc/common_risk_utils";
import BaseReactComponent from "../../base_react_component";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import RiskMapTooltipBuilder from "./utilities/risk_map_tooltip_builder";

// Bound a custom filter function to the Object type. that can give better workaround to filter an object entries.
Object.filter = (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate));

const Logger = Log.group(LOG_GROUP.Reports, "RiskMap");

let graphData = {
  nodes: null,
  edges: null,
};

const EDGES_COUNT = 500;
const WHITE_BACKGROUND = "#FFFFFF";
const DEFAULT_FONT_COLOR = "#333333";
const DEFAULT_BACKGROUND_COLOR = "#d6d6d6";
const DEFAULT_LABEL_BACKGROUND_COLOR = "#f5f5f5";
const CONTAINER_BOX_FONT_SIZE = 16;
const CONTAINER_BOX_HEADER_HEIGHT = CONTAINER_BOX_FONT_SIZE + 20; // Font size plus 10px above and below
export const GHOST_NODE_OPACITY = 0.2;
export const GHOST_EDGE_OPACITY = 0.05;
export function getGraphData() {
  return graphData;
}

const TYPE_TO_CONTAINER_ID = {
  TPP: -1,
  GeneralAttribute: -2,
  FA: -3,
  MA: -5,
  PROCESS: -6,
  // UO container boxes use the Id of the UO
};

/* This class shows the Risk Map report. */
export class RiskMap extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.resetRiskMapOptions(0);
    this.nodesDataSet = new DataSet([], {queue: true});
    this.edgesDataSet = new DataSet([], {queue: true});
    this.containerBoxHeaders = [];
  }

  static onDoubleClick(event) {
    if (event.nodes.length === 1) {
      // Make sure we're not clicking on the Container Box (ie. Unit Operation), which pretends to be the children so
      // it can be dragged.
      const nodeId = event.nodes[0];
      const canvasPosition = event.pointer.canvas;
      const boundingBox = window.network.getBoundingBox(nodeId);
      if (boundingBox.left <= canvasPosition.x && canvasPosition.x <= boundingBox.right
        && boundingBox.top <= canvasPosition.y && canvasPosition.y <= boundingBox.bottom) {
        let node = window.nodesToObjects[nodeId];
        let url = getURLByTypeCodeAndId(node.type, "View", node.id);
        window.open(url, "_blank");
      }
    }
  }

  onDragStart(event) {
    // Search for a container box that might be clicked on.
    const canvasPosition = event.pointer.canvas;
    const allNodeIdsInContainerBox = this.getAllNodeIdsInContainerBoxAt(canvasPosition);

    if (allNodeIdsInContainerBox) {
      window.network.selectNodes(allNodeIdsInContainerBox);
    }
  }

  getAllNodeIdsInContainerBoxAt(positionParam) {
    const position = {
      x: positionParam.x || positionParam.left,
      y: positionParam.y || positionParam.top,
    };

    let allNodeIdsInContainerBox;
    let allContainerBoxHeaders = this.containerBoxHeaders.filter(
      containerBox =>
        containerBox.top <= position.y && position.y <= containerBox.bottom
        && containerBox.left <= position.x && position.x <= containerBox.right);
    // The last one is the one on top
    let containerBoxHeader = allContainerBoxHeaders[allContainerBoxHeaders.length - 1];

    if (containerBoxHeader) {
      Logger.verbose(() => "Clicked on UO-" + containerBoxHeader.id);

      // Select all the nodes in the container box
      switch (containerBoxHeader.id) {
        case TYPE_TO_CONTAINER_ID.TPP:
          allNodeIdsInContainerBox = Object.keys(window.nodesToObjects).filter(nodeId => window.nodesToObjects[nodeId].type === "TPP");
          break;
        case TYPE_TO_CONTAINER_ID.GeneralAttribute:
          allNodeIdsInContainerBox = Object.keys(window.nodesToObjects).filter(nodeId => window.nodesToObjects[nodeId].type === "GA");
          break;
        case TYPE_TO_CONTAINER_ID.FA:
          allNodeIdsInContainerBox = Object.keys(window.nodesToObjects)
            .filter(nodeId => window.nodesToObjects[nodeId].type === "FQA" || window.nodesToObjects[nodeId].type === "FPA");
          break;
        case TYPE_TO_CONTAINER_ID.MA:
          allNodeIdsInContainerBox = Object.keys(window.nodesToObjects).filter(nodeId =>
            window.nodesToObjects[nodeId].type === "MA"
            && window.nodesToObjects[nodeId].parentType === "MT"
            && !window.nodesToObjects[nodeId].unitOperationId);
          break;
        case TYPE_TO_CONTAINER_ID.PROCESS:
          allNodeIdsInContainerBox = Object.keys(window.nodesToObjects).filter(nodeId =>
            (window.nodesToObjects[nodeId].type === "PP"
              || window.nodesToObjects[nodeId].type === "MA")
            && window.nodesToObjects[nodeId].parentType === "PRC"
            && !window.nodesToObjects[nodeId].unitOperationId);
          break;
        default:
          allNodeIdsInContainerBox = Object.keys(window.nodesToObjects).filter(nodeId => window.nodesToObjects[nodeId].unitOperationId === containerBoxHeader.id);
      }
    }
    return allNodeIdsInContainerBox;
  }

  static getShapeFromEntity(entity) {
    switch (entity.type) {
      case "FQA":
      case "FPA":
        return "dot";
      case "TPP":
      case "GA":
        return "square";
      case "IQA":
      case "IPA":
        return "hexagon";
      case "PP":
        return "triangle";
      case "MA":
        return "star";
    }
  }

  static getSizeFromLinks(entity, filters) {
    const outgoingLinks = entity.type === "FQA" || entity.type === "FPA" ? entity.outgoingLinks
      .filter(link => filters.riskTracedToFilter === "traceToTPPs" ?
        link.type === "TPP" : link.type === "GA").length : entity.outgoingLinks.length;

    return 25 + ((outgoingLinks + entity.incomingLinks.length) * 5);
  }

  /**
   * These are the default options that apply to every node.
   */
  resetRiskMapOptions(edgesCount) {
    this.options = {
      nodes: {
        chosen: {
          label: (values) => {
            // change font color on hover
            if (values.color === DEFAULT_BACKGROUND_COLOR) {
              values.color = DEFAULT_FONT_COLOR;
            }
          }
        },
        shape: "dot",
        scaling: {
          min: 25,
          max: 45
        },
        font: {
          multi: false,
          size: 16,
          face: "Open Sans",
        },
        borderWidth: 2,
      },
      edges: {
        width: 2,
        arrows: {
          to: {
            enabled: true
          }
        },
        color: {
          inherit: false
        }
      },
      layout: {
        randomSeed: 12
      },
      interaction: {
        hover: true,
        multiselect: true,
        tooltipDelay: 300
      },
      physics: {
        enabled: true,
        stabilization: {
          enabled: this.props.graphLayout ? !this.props.graphLayout.isInitialized : true,
          onlyDynamicEdges: this.props.graphLayout ? this.props.graphLayout.isInitialized : false,
          fit: this.props.graphLayout ? !this.props.graphLayout.isInitialized : true,
        }
      }
    };

    if (edgesCount > EDGES_COUNT) {
      this.options.physics = false;
      this.options.layout.improvedLayout = false;
      this.options.edges.smooth = {
        enabled: true,
        type: "discrete",
        roundness: 0
      };
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!nextProps.filters) {
      return false;
    }

    let typeaheadOptionsChanged = (!this.props.selectedTypeaheadOptions && nextProps.selectedTypeaheadOptions) ||
      (this.props.selectedTypeaheadOptions && nextProps.selectedTypeaheadOptions && (nextProps.selectedTypeaheadOptions.length !== this.props.selectedTypeaheadOptions.length));
    this.shouldFocusOnSearchedObjects = this.shouldFocusOnSearchedObjects || typeaheadOptionsChanged;

    let previousHistoryPoint = getHistoryPointForDate(this.props.selectedDate, this.props.mapHistory);
    let newHistoryPoint = getHistoryPointForDate(nextProps.selectedDate, nextProps.mapHistory);

    if (!this.props.graphLayout ||
      (!this.props.filters && nextProps.filters) ||
      (previousHistoryPoint !== newHistoryPoint) ||
      (nextProps.riskType !== this.props.riskType) ||
      (nextProps.RMP !== this.props.RMP) ||
      (JSON.stringify(nextProps.filters) !== JSON.stringify(this.props.filters)) ||
      typeaheadOptionsChanged ||
      nextProps.shouldRenderGraph) {
      return true;
    } else {
      UIUtils.incrementReactComponentDidUpdateCounter();
      return false;
    }
  }

  componentDidUpdate() {
    if (this.props.mapHistory) {
      this.props.onReportRenderingStarted();

      this.updateGraphData(this.props.mapHistory, this.props.selectedDate, this.props.graphLayout,
        this.props.filters, this.props.selectedTypeaheadOptions);
      this.resetRiskMapOptions(graphData.edges.length);

      Logger.verbose(() => graphData.nodes);
      Logger.verbose(() => `Nodes count ${graphData.nodes.length}`);
      Logger.verbose(() => `Edges count ${graphData.edges.length}`);

      if (!window.network) {
        window.network = new Network(this.riskMapDiv, graphData, this.options);
        window.network.on("doubleClick", RiskMap.onDoubleClick);
        window.network.on("beforeDrawing", this.onBeforeGraphDrawing);
        window.network.on("afterDrawing", this.onGraphDrawingAfterMount);
        window.network.on("afterDrawing", this.onGraphDrawingAlways);
        window.network.on("dragStart", this.onDragStart);
        window.network.on("dragEnd", this.onGraphDragEnd);
        window.network.on("zoom", this.onGraphZoom);
        window.network.on("release", this.onGraphRelease);
        window.network.on("animationFinished", this.onAnimationFinished);
        /*
          This is overridden because when dragging starts in vis-network v6.5.0 it decides immediately whether it's moving
          the entire map or dragging a node and there doesn't seem to be any way to change it later.  So we override the
          selection handler to think that when it's over a container box (ie. UO grey box) then it's actually hovering
          over the first node.  This way it's in "dragging nodes" mode when the startDrag event kicks off and we can add
          the rest of the nodes in the UO when the event handler fires.
         */
        RiskMapSelectionHandler.overrideSelectionHandler((pointer) => {
          let allNodeIdsInContainerBox = this.getAllNodeIdsInContainerBoxAt(pointer);
          if (allNodeIdsInContainerBox) {
            Logger.verbose(() => "Getting node at", pointer, " returns:", Log.json(allNodeIdsInContainerBox[0]));
            return RiskMapSelectionHandler.getNodeForId(allNodeIdsInContainerBox[0]);
          } else {
            return undefined;
          }
        });

        window.addEventListener("beforeprint", this.onBeforePrint);
        window.addEventListener("afterprint", this.onAfterPrint);
      } else {
        window.network.on("afterDrawing", this.onGraphDrawingAfterMount);
      }
    }
  }

  componentWillUnmount() {
    if (window.network) {
      window.network.off("doubleClick");
      window.network.off("beforeDrawing");
      window.network.off("dragEnd");
      window.network.off("zoom");
      window.network.off("release");
      window.network.off("afterDrawing");
      window.network.off("animationFinished");
      window.network.destroy();
    }
    super.componentWillUnmount();
  }

  onGraphDragEnd(event) {
    if (event.nodes.length === 1) {
      this.props.onAutoSaveReportLayout(false);
    }
  }

  onGraphZoom() {
    this.props.onAutoSaveReportLayout(false);
  }

  onGraphRelease() {
    this.props.onAutoSaveReportLayout(false);
  }

  onAnimationFinished() {
    this.props.onAutoSaveReportLayout(true);
    UIUtils.incrementReactComponentDidUpdateCounter();
  }

  onBeforeGraphDrawing(ctx) {
    // Uncomment for verbose logging
    // console.time("Drawing UO boxes");
    let idToContainerBox = new Map();

    let allNodeIds = Object.keys(window.nodesToObjects);

    // Figure out where the boxes should go for each item
    for (let nodeId of allNodeIds) {
      let nodeBoundingBox = window.network.getBoundingBox(nodeId);
      const nodeObject = window.nodesToObjects[nodeId];
      Logger.verbose(() => "Unit Operations, Current node is: ", Log.json(nodeObject));
      const containerBoxId = this.getContainerBoxId(nodeObject);

      if (nodeBoundingBox) {
        Logger.verbose(() => containerBoxId === 315 ? `Setting ${containerBoxId} to ${nodeBoundingBox.left}, ${nodeBoundingBox.top}, ${nodeBoundingBox.right}, ${nodeBoundingBox.bottom}` : "");
        if (idToContainerBox.has(containerBoxId)) {
          let containerBox = idToContainerBox.get(containerBoxId);
          containerBox.left = containerBox.left > nodeBoundingBox.left ? nodeBoundingBox.left : containerBox.left;
          containerBox.top = containerBox.top > nodeBoundingBox.top ? nodeBoundingBox.top : containerBox.top;
          containerBox.right = containerBox.right < nodeBoundingBox.right ? nodeBoundingBox.right : containerBox.right;
          containerBox.bottom = containerBox.bottom < nodeBoundingBox.bottom ? nodeBoundingBox.bottom : containerBox.bottom;
          containerBox.renderFullColor = containerBox.renderFullColor || nodeObject.renderFullColor;
        } else {
          idToContainerBox.set(containerBoxId, {
            id: containerBoxId,
            name: this.getContainerBoxName(nodeObject),
            left: nodeBoundingBox.left,
            top: nodeBoundingBox.top,
            right: nodeBoundingBox.right,
            bottom: nodeBoundingBox.bottom,
            renderFullColor: nodeObject.renderFullColor,
          });
        }
      }
    }

    // Figure out container boxes for nodes with steps, and create separate boxes for steps...
    let nodesWithSteps = Object.filter(window.nodesToObjects, ([, node]) => node.unitOperationId && node.stepId);

    let allNodeWithStepIds = Object.keys(nodesWithSteps);
    for (let nodeId of allNodeWithStepIds) {
      let nodeBoundingBox = window.network.getBoundingBox(nodeId);
      const nodeObject = nodesWithSteps[nodeId];
      const containerBoxId = this.getContainerBoxId(nodeObject, false);
      const uoContainerBoxId = nodeObject.unitOperationId;
      const uoContainerBox = idToContainerBox.get(uoContainerBoxId);

      Logger.verbose(() => "Container Box: ", containerBoxId);
      Logger.verbose(() => "Steps, Current node is: ", Log.json(nodeObject));

      if (nodeBoundingBox) {
        Logger.verbose(() => "Dragging node at position:", Log.json(nodeBoundingBox));
        if (idToContainerBox.has(containerBoxId)) {
          let containerBox = idToContainerBox.get(containerBoxId);
          containerBox.left = uoContainerBox.left;
          containerBox.right = uoContainerBox.right;
          containerBox.top = containerBox.top > nodeBoundingBox.top ? nodeBoundingBox.top : containerBox.top;
          containerBox.bottom = containerBox.bottom < nodeBoundingBox.bottom ? nodeBoundingBox.bottom : containerBox.bottom;
          containerBox.boxType = "Step";
          containerBox.renderFullColor = containerBox.renderFullColor || nodeObject.renderFullColor;

        } else {
          idToContainerBox.set(containerBoxId, {
            id: containerBoxId,
            name: this.getContainerBoxName(nodeObject, false),
            left: uoContainerBox.left,
            right: uoContainerBox.right,
            top: nodeBoundingBox.top,
            bottom: nodeBoundingBox.bottom,
            boxType: "Step",
            renderFullColor: nodeObject.renderFullColor,
          });
        }
        // if the first step box overlaps with the UO box, add some margin to the step box.
        if (uoContainerBox.top === idToContainerBox.get(containerBoxId).top) {
          uoContainerBox.top -= CONTAINER_BOX_HEADER_HEIGHT;
        }
      }
    }

    // Draw a box for each UO
    const PADDING = 30;
    const previousIdToContainerBox = new Map(window.containerBoxes ? window.containerBoxes.map(box => [box.id, box]) : undefined);
    this.containerBoxHeaders = [];
    window.containerBoxes = [];
    for (const containerBox of idToContainerBox.values()) {
      let left, top, right, width, height;
      if (containerBox.id < 0 || containerBox.left !== 0.00) {
        left = containerBox.left - PADDING;
        right = containerBox.right;
      } else {
        // When navigating left/right in the timeline, the left node bounding box is zero temporarily, so we provide the
        // previous value until it's updated.
        const prevContainerBox = previousIdToContainerBox.get(containerBox.id);
        left = prevContainerBox ? prevContainerBox.left : 0;
        right = prevContainerBox ? prevContainerBox.right - PADDING : 0;
      }
      top = containerBox.top - PADDING - CONTAINER_BOX_HEADER_HEIGHT;
      width = right - left + PADDING;
      height = containerBox.bottom - top + PADDING;

      Logger.verbose(() => `Drawing a rectangle (${left}, ${top}, ${width}, ${height})`);
      // Draw the outline around the box
      if (containerBox.boxType !== "Step") {
        ctx.strokeStyle = containerBox.renderFullColor ? "#999999" : "#CCCCCC";
        ctx.strokeRect(left, top, width, height);
      }

      // Fill in the background of the header
      if (containerBox.boxType !== "Step") {
        ctx.fillStyle = containerBox.renderFullColor ? "#859099" : "#CCCCCC";
        ctx.fillRect(left, top, width, CONTAINER_BOX_HEADER_HEIGHT);
      } else {
        ctx.fillStyle = "#DBE1E4";
        ctx.fillRect(left, top, width, CONTAINER_BOX_HEADER_HEIGHT);
      }
      // Fill in the background where the attributes go
      ctx.fillStyle = containerBox.renderFullColor ? DEFAULT_LABEL_BACKGROUND_COLOR : WHITE_BACKGROUND;
      ctx.fillRect(left, top + CONTAINER_BOX_HEADER_HEIGHT, width, height - CONTAINER_BOX_HEADER_HEIGHT);

      this.containerBoxHeaders.push({
        id: containerBox.id,
        name: containerBox.name,
        boxType: containerBox.boxType,
        top: top,
        left: left,
        right: left + width,
        width: width,
        bottom: top + CONTAINER_BOX_HEADER_HEIGHT,
      });

      // For test cases to check them
      window.containerBoxes.push({
        id: containerBox.id,
        name: containerBox.name,
        boxType: containerBox.boxType,
        top: top,
        left: left,
        right: left + width,
        bottom: top + height,
      });
    }

    // Uncomment for verbose logging
    // console.timeEnd("Drawing UO boxes");
  }

  getContainerBoxId(nodeObject, isParentLevel = true) {
    switch (nodeObject.type) {
      case "TPP":
        return TYPE_TO_CONTAINER_ID.TPP;
      case "GA":
        return TYPE_TO_CONTAINER_ID.GeneralAttribute;
      case "FQA":
        return TYPE_TO_CONTAINER_ID.FA;
      case "FPA":
        return TYPE_TO_CONTAINER_ID.FA;
      case "MA":
        return nodeObject.unitOperationId
          ? this.getBoxIdFromEitherStepOrUO(nodeObject, isParentLevel)
          : nodeObject.parentType === "MT"
            ? TYPE_TO_CONTAINER_ID.MA
            : TYPE_TO_CONTAINER_ID.PROCESS;
      case "PP":
        return nodeObject.unitOperationId
          ? this.getBoxIdFromEitherStepOrUO(nodeObject, isParentLevel)
          : TYPE_TO_CONTAINER_ID.PROCESS;
      default:
        return this.getBoxIdFromEitherStepOrUO(nodeObject, isParentLevel);
    }
  }

  getBoxIdFromEitherStepOrUO(nodeObject, isParentLevel) {
    return isParentLevel ? nodeObject.unitOperationId : nodeObject.unitOperationId + "-" + nodeObject.stepId;
  }

  getContainerBoxName(nodeObject, isParentLevel = true) {
    switch (nodeObject.type) {
      case "TPP":
        return "Target Product Profiles (TPPs)";
      case "GA":
        return "General Attribute (GAs)";
      case "FQA":
      case "FPA":
        return "Final Attributes";
      case "MA":
        return nodeObject.unitOperationId
          ? this.getBoxNameFromEitherStepOrUO(nodeObject, isParentLevel)
          : nodeObject.parentType === "MT"
            ? "Material Attributes (MAs)"
            : "Process";
      case "PP":
        return nodeObject.unitOperationId
          ? (
            this.getBoxNameFromEitherStepOrUO(nodeObject, isParentLevel)
          ) : "Process";
      default:
        return this.getBoxNameFromEitherStepOrUO(nodeObject, isParentLevel);
    }
  }

  getBoxNameFromEitherStepOrUO(nodeObject, isParentLevel) {
    return isParentLevel ? nodeObject.unitOperationName : nodeObject.stepName;
  }

  /**
   * This is used to draw on top of the graph.
   * @param ctx The context to draw on the canvas
   */
  onGraphDrawingAlways(ctx) {
    for (const containerBoxHeader of this.containerBoxHeaders) {

      ctx.font = CONTAINER_BOX_FONT_SIZE + "px Open Sans";
      ctx.textAlign = "center";

      // Change header text color if the box for a step.
      containerBoxHeader.boxType ? ctx.fillStyle = "#292A2B" : ctx.fillStyle = DEFAULT_LABEL_BACKGROUND_COLOR;

      let containerBoxHeaderText;
      const {id} = containerBoxHeader;
      // Only UOs and Steps will go into the below condition...
      if (id > 0 || (typeof id === "string" && id.includes("-"))) {
        const typeCode = containerBoxHeader.boxType ? "STP" : "UO";
        // Extract the step id from the box id
        const joinCharIndex = typeCode === "STP" ? id.indexOf("-") : -1;
        if (joinCharIndex !== -1) {
          const containerBoxId = id.slice(joinCharIndex + 1);
          containerBoxHeaderText = UIUtils.getRecordLabelForDisplay(typeCode, containerBoxId, containerBoxHeader.name);
        } else {
          containerBoxHeaderText = UIUtils.getRecordLabelForDisplay(typeCode, id, containerBoxHeader.name);
        }

      } else {
        containerBoxHeaderText = containerBoxHeader.name;
      }
      ctx.fillText(containerBoxHeaderText,
        containerBoxHeader.left + (containerBoxHeader.width / 2),
        containerBoxHeader.top + (CONTAINER_BOX_FONT_SIZE / 2 + 3),
        containerBoxHeader.width - 20);

    }
  }

  /**
   * This is used to zoom the graph based on outside parameters (loading a new layout, searching for a node, etc)
   */
  onGraphDrawingAfterMount() {
    window.network.off("afterDrawing", this.onGraphDrawingAfterMount);

    this.options.nodes.physics = false;
    this.options.edges.physics = graphData.edges.length < EDGES_COUNT;
    window.network.setOptions(this.options);

    if (!this.props.graphLayout || !this.props.graphLayout.isInitialized) {
      resetGraphToDefaultView(window.network, graphData.nodes, this.props.filters);
      clearTimeout(this.resetLayoutTimeout);
      this.resetLayoutTimeout = setTimeout(() => {
        window.network.fit({
          animation: {
            duration: 500,
            easingFunction: "easeInOutQuint"
          }
        });
      }, 500);
      this.props.onInitializationChanged(true);
    }

    if (this.props.graphLayout.layout.viewPosition && this.props.graphLayout.layout.scale) {
      window.network.moveTo({
        position: this.props.graphLayout.layout.viewPosition,
        scale: this.props.graphLayout.layout.scale,
        animation: false
      });
    }

    if (this.props.selectedTypeaheadOptions && this.shouldFocusOnSearchedObjects) {
      let typeaheadOptionsNodeIds = this.props.selectedTypeaheadOptions.map(selectedNode => {
        return selectedNode.id;
      }).filter(nodeId => {
        return window.nodesToObjects[nodeId];
      });

      for (let nodeId of Object.keys(window.nodesToObjects).filter(key =>
        window.nodesToObjects[key].renderFullColor
      )) {
        if (!typeaheadOptionsNodeIds.includes(UIUtils.parseInt(nodeId))) {
          typeaheadOptionsNodeIds.push(UIUtils.parseInt(nodeId));
        }
      }

      window.network.fit({
        nodes: typeaheadOptionsNodeIds,
        animation: {
          duration: 1000,
          easingFunction: "easeOutQuad"
        }
      });
      window.network.selectNodes(this.props.selectedTypeaheadOptions.length > 0 ? typeaheadOptionsNodeIds : []);
      this.shouldFocusOnSearchedObjects = false;
    }

    this.props.onReportRenderingCompleted();
    UIUtils.incrementReactComponentDidUpdateCounter();
  }

  /**
   * This is here because of https://cherrycircle.atlassian.net/browse/QI-4087. For now, the Risk Map cannot be printed
   * without this workaround.
   */
  onBeforePrint() {
    const riskMap = $("#riskMap");
    const riskMapPicAsCanvas = $(createCanvasWithRiskMapPicture());
    riskMapPicAsCanvas.attr("id", "riskMapPrintableCanvas");
    riskMapPicAsCanvas.css("width", riskMap.width());
    riskMapPicAsCanvas.css("height", riskMap.height());
    riskMap.prepend(riskMapPicAsCanvas);
    $("#riskMap .vis-network").hide();
  }

  onAfterPrint() {
    $("#riskMapPrintableCanvas").remove();
    $("#riskMap .vis-network").show();
  }

  updateGraphData(mapHistory, selectedDate, graphLayout, filters, selectedTypeaheadOptions) {
    window.nodesToObjects = {};
    window.nodesToArrows = new Map();
    this.objectIdsToNodeIds = {};
    this.objectVersionIdsToNodeIds = {};
    let typeaheadOptions = [];
    let counter = 1;
    let nodes = [];
    let historyPoint;

    if (selectedDate) {
      historyPoint = getHistoryPointForDate(selectedDate, mapHistory);

      if (historyPoint) {
        historyPoint.historySnapshot.forEach(value => {
          value.forceRenderFullColor = false;
          value.upstreamProcessed = false;
          value.downstreamProcessed = false;
          value.windowObject = null;
          value.nodeObject = null;
        });

        for (let value of historyPoint.historySnapshot.values()) {

          if (value.processCapabilities) {
            value.processCapability = value.processCapabilities.find(prc => prc.batchType === filters.batchType);
          }

          let effectiveRMPForModelType = CommonRiskUtils.filterRMPByType(this.props.RMP, value.type);
          this.objectIdsToNodeIds[value.type + "-" + value.id] = {
            id: counter,
            label: value.name
          };
          this.objectVersionIdsToNodeIds[value.type + "-" + value.versionId] = {
            id: counter,
            label: value.name
          };

          let renderFullColor = shouldRenderFullColor(effectiveRMPForModelType, value, filters, selectedTypeaheadOptions, "map", this.props.riskType);
          if (renderFullColor) {
            applyStreamFilters(value, historyPoint.historySnapshot, filters.showUpstream, filters.showDownstream, this.redrawNodeInFullColor.bind(this));
          }

          renderFullColor = renderFullColor || value.forceRenderFullColor;

          if (!value.archived && shouldRender(value, filters)) {

            let size = RiskMap.getSizeFromLinks(value, filters);
            let fontColor = renderFullColor ? DEFAULT_FONT_COLOR
              : calculateTransparentColor(DEFAULT_FONT_COLOR, WHITE_BACKGROUND, GHOST_NODE_OPACITY);

            let fontBackgroundColor = DEFAULT_LABEL_BACKGROUND_COLOR;
            if (value.processCapability && filters.showProcessCapability) {
              fontBackgroundColor = value.processCapability.color;
              if (value.processCapability.label === "Poor") {
                fontColor = DEFAULT_LABEL_BACKGROUND_COLOR;
              }
            }

            let riskScores = getRiskScores(value, this.props.riskType);

            let unitOperation = historyPoint.unitOperationVersionsMap[value.unitOperationId];
            let step = historyPoint.stepVersionsMap[value.stepId];
            const controlStrategies = getControlStrategiesToShowForNode({
              node: value,
              filters,
              nodeHighlightedInRiskMap: renderFullColor,
              selectedTypeaheadOptions,
              isForTooltip: false,
            });

            // This is how the tests figure out what the current values are.
            window.nodesToObjects[counter] = {
              id: value.id,
              versionId: value.versionId,
              name: value.name,
              controlStrategy: controlStrategies.join("|"),
              type: value.type,
              parentType: value.parent ? value.parent.type : null,
              rawRiskScore: riskScores.rawRiskScore,
              normalizedRiskScore: riskScores.normalizedRiskScore,
              size,
              fontColor,
              backgroundColor: fontBackgroundColor,
              renderFullColor: renderFullColor,
              unitOperationId: unitOperation ? unitOperation.unitOperationId : null,
              unitOperationName: unitOperation ? unitOperation.name : null,
              unitOperationOrder: unitOperation ? unitOperation.order : null,

              stepId: step ? step.stepId : null,
              stepName: step ? step.name : null,
              stepOrder: step ? step.order : null,
              previousStepId: step ? step.PreviousStepId : null,

              approved: value.approved,
              archived: value.archived,
              restored: value.restored
            };
            value.windowObject = window.nodesToObjects[counter];

            const tooltipBuilder = new RiskMapTooltipBuilder({
              node: value,
              unitOperation,
              step,
              riskType: this.props.riskType,
              riskScores,
              filters,
              effectiveRMPForModelType,
              nodeHighlightedInRiskMap: renderFullColor,
              selectedTypeaheadOptions,
            });

            /* The vis.js library is failing when trying to use markdown and escaping the markdown characters supported
               by the library. Those characters are '*', '_' and '\'. To work around this issue, we are replacing below
               those 3 characters with visually equivalent ones. I learned that these are called confusables, and I
               managed to find the ones looking closer to the regular characters from this web site:
               https://util.unicode.org/UnicodeJsps/confusables.jsp?a=%5C&r=None

               This has the effect of showing the names of the attributes on the risk very close to how one would type
               them, when using any of the mentioned characters above.
             */
            const confusablesMap = {"*": "∗", "_": "ߺ", "\\": "∖"};
            const nodeLabel = value.name.replace(/[*_\\]/g, m => confusablesMap[m]);

            let node = {
              id: counter,
              borderWidth: 1,
              borderWidthSelected: 1,
              label: `${nodeLabel}\n${controlStrategies.map(cs => `*${cs}*`).join("\n")}`,
              font: {
                multi: "markdown",
                color: fontColor,
                background: fontBackgroundColor
              },
              size: size,
              color: this.getRiskColor(value, renderFullColor),
              shape: RiskMap.getShapeFromEntity(value),
              shapeProperties: {
                borderDashes: value.approved ? false : [size / 5, size / 5]
              },
              type: value.type,
              parentType: value.parent ? value.parent.type : null,
              unitOperationId: unitOperation ? unitOperation.unitOperationId : null,
              stepId: step ? step.stepId : null,
              unitOperationOrder: unitOperation ? unitOperation.order : null,
              stepOrder: step ? step.order : null,
              title: ReactDOMServer.renderToStaticMarkup(tooltipBuilder.getTooltip()),
              physics: false
            };
            value.nodeObject = node;

            if (graphLayout.layout[value.type] && graphLayout.layout[value.type][value.id]) {
              node.x = graphLayout.layout[value.type][value.id].x;
              node.y = graphLayout.layout[value.type][value.id].y;
            }

            nodes.push(node);
          }

          if (shouldRenderFullColor(effectiveRMPForModelType, value, filters, selectedTypeaheadOptions,
            "typeahead", this.props.riskType) && shouldRender(value, filters)) {

            typeaheadOptions.push({
              id: this.objectIdsToNodeIds[value.type + "-" + value.id].id,
              label: value.type + "-" + value.id + " - " + value.name,
              type: value.type,
              objId: value.id
            });
          }

          counter++;
        }
      }
    }

    let edges = [];
    if (historyPoint) {
      for (let value of historyPoint.historySnapshot.values()) {
        if (value.outgoingLinks.length > 0) {
          let sourceNode = this.getSourceNode(value);

          let newEdges = value.outgoingLinks.filter(link => {
            let targetNode = this.getTargetNode(link);
            return sourceNode && targetNode;
          });

          newEdges = newEdges.map(link => {
            let sourceNodeId = sourceNode.id;
            let targetNode = this.getTargetNode(link);
            let targetNodeId = targetNode.id;

            let edgeOpacity = (window.nodesToObjects[sourceNodeId] &&
              window.nodesToObjects[targetNodeId] &&
              window.nodesToObjects[sourceNodeId].renderFullColor &&
              window.nodesToObjects[targetNodeId].renderFullColor) ? 1 : GHOST_EDGE_OPACITY;

            let colorMap = this.getRiskColor(value, true);

            let shouldDisplayEffectLabel = filters?.showEffectLabel && link?.effect && edgeOpacity !== GHOST_EDGE_OPACITY;

            let effectLabelAdditionalProps = (
              shouldDisplayEffectLabel
                ? {
                  label: link.effect,
                  font: {
                    color: colorMap.background,
                    background: "white",
                  },
                }
                : {}
            );

            let arrow = {
              from: sourceNodeId,
              to: targetNodeId,
              color: {
                opacity: edgeOpacity,
                color: colorMap.background,
                highlight: colorMap.highlight.background,
                hover: colorMap.hover.background,
              },
              ...effectLabelAdditionalProps,
            };
            window.nodesToArrows.set(`${sourceNodeId}-${targetNodeId}`, arrow);
            return arrow;
          });

          edges = edges.concat(newEdges);
        }
      }
    }

    this.nodesDataSet.clear();
    this.nodesDataSet.add(nodes);

    this.edgesDataSet.clear();
    this.edgesDataSet.add(edges);

    this.nodesDataSet.flush();
    this.edgesDataSet.flush();

    this.props.onTypeaheadOptionsReady(typeaheadOptions);

    graphData.nodes = this.nodesDataSet;
    graphData.edges = this.edgesDataSet;
  }

  getTargetNode(link) {
    return this.objectIdsToNodeIds[link.type + "-" + link.id];
  }

  getSourceNode(value) {
    return this.objectVersionIdsToNodeIds[value.type + "-" + value.versionId];
  }

  redrawNodeInFullColor(node) {
    if (node.windowObject) {
      node.windowObject.renderFullColor = true;
    }
    if (node.nodeObject) {
      node.nodeObject.color = this.getRiskColor(node, true);
      node.nodeObject.font.color = DEFAULT_FONT_COLOR;
    }
  }

  getRiskColor(entity, renderFullColor) {
    let colorMap;
    if (entity.type !== "TPP" && entity.type !== "GA" && this.props.RMP) {
      let effectiveRMPForModelType = CommonRiskUtils.filterRMPByType(this.props.RMP, entity.type);
      let rowRiskScore = getRiskScores(entity, this.props.riskType).rawRiskScore;
      let riskScale = getRiskScale(this.props.riskType, effectiveRMPForModelType, rowRiskScore, entity, showByRiskLabel(this.props.filters, this.props.riskType));
      let color = riskScale && riskScale.color ? riskScale.color : RISK_COLORS.NONE;

      colorMap = {
        background: RISK_MAP_COLORS[color].background,
        border: RISK_MAP_COLORS[color].border,
        highlight: {
          background: RISK_MAP_COLORS[color].highlight.background,
          border: RISK_MAP_COLORS[color].highlight.border
        },
        hover: {
          background: RISK_MAP_COLORS[color].hover.background,
          border: RISK_MAP_COLORS[color].hover.border
        }
      };
    } else {
      colorMap = {
        background: RISK_MAP_COLORS.tpp.background,
        border: RISK_MAP_COLORS.tpp.border,
        highlight: {
          background: RISK_MAP_COLORS.tpp.highlight.background,
          border: RISK_MAP_COLORS.tpp.highlight.border,
        },
        hover: {
          background: RISK_MAP_COLORS.tpp.hover.background,
          border: RISK_MAP_COLORS.tpp.hover.border,
        }
      };
    }

    if (!renderFullColor) {
      colorMap.background = calculateTransparentColor(colorMap.background, WHITE_BACKGROUND, GHOST_NODE_OPACITY);
      colorMap.border = calculateTransparentColor(colorMap.border, WHITE_BACKGROUND, GHOST_NODE_OPACITY);
    }

    return colorMap;
  }

  render() {
    return (
      <div id="riskMapContainer"
           className="risk-map-report risk-map-report-expanded"
      >
        <div id="riskMap"
             className="risk-map-network"
             ref={riskMapDiv => this.riskMapDiv = riskMapDiv}
        />
      </div>
    );
  }
}
