"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import BaseApprovableEditor from "../editor/base_approvable_editor";
import ComboBoxAttribute from "../editor/attributes/combo_box_attribute";
import TextAreaAttribute from "../editor/attributes/text_area_attribute";
import TextAttribute from "../editor/attributes/text_attribute";
import TypeaheadAttribute from "../editor/attributes/typeahead_attribute";
import Heading from "../widgets/headers/heading";
import { can, generateTooltip } from "../utils/ui_permissions";
import { PROJECT_TYPES } from "../utils/project_helper";
import CommonSecurity from "../../server/common/generic/common_security";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";
import { getURLByTypeCodeAndId, getURLByTypeCodeAndIdAndVersionId } from "../helpers/url_helper";

export default class ProjectSummary extends BaseApprovableEditor {
  constructor(props) {
    super(props, "project");

    this.state = {isLoading: true};

    if (this.isAdd() || this.isEdit()) {
      new TypeaheadObjectCache("Project").invalidateCacheOptions();
    }
  }

  /**
   * Change the state of the component in case isLoading or cacheData properties changes
   * @param props
   * @param state
   * @returns {*&{isLoading}}
   */
  static getDerivedStateFromProps(props, state) {
    if (props.isLoading !== state.isLoading || state.id !== props.cacheData?.id) {
      return {isLoading: props.isLoading, ...props.cachedData};
    }

    return null;
  }

  static handleViewButton() {
    UIUtils.setLoadingDisabled(false);
    UIUtils.showLoadingImage();
    let id = UIUtils.getParameterByName("projectId");
    window.location.href = UIUtils.getSecuredURL("/projects/viewEdit.html?operation=View&id=" + id);
  }

  static handleEditButton() {
    UIUtils.setLoadingDisabled(false);
    UIUtils.showLoadingImage();
    let id = UIUtils.getParameterByName("projectId");
    window.location.href = UIUtils.getSecuredURL("/projects/viewEdit.html?operation=Edit&id=" + id);
  }

  render() {
    const rmpLabel = this.getRmpLabel();
    const rmpURL = this.getRmpUrl();
    const isRMPLabelLoading = this.isLoading() || !rmpLabel || !rmpURL;

    return (
      <div className="section-content">
        <Heading level={2}>
          <div className="row justify-content-between">
            <div className="col-auto">
              Project Summary
            </div>
            <div className="col-auto">
              <div className="btn-group">
                <button id="viewButton"
                        onClick={ProjectSummary.handleViewButton}
                        className={"btn btn-lg btn-primary"}
                >
                  View
                </button>
                {
                  this.state.currentState
                  && !this.state.currentState.startsWith(UIUtils.VERSION_STATES.PROPOSED)
                  && this.state.currentState !== UIUtils.VERSION_STATES.ROUTING
                  && this.state.currentState !== UIUtils.VERSION_STATES.ARCHIVED ? (
                    <button id="editButton"
                            onClick={ProjectSummary.handleEditButton}
                            className={"btn btn-lg btn-secondary"}
                            title={generateTooltip(CommonSecurity.Actions.EDIT, CommonSecurity.Types.PROJECT, this.state)}
                            disabled={!can(CommonSecurity.Actions.EDIT, CommonSecurity.Types.PROJECT, this.state)}
                    >
                      Edit
                    </button>
                  ) : ""
                }

              </div>
            </div>
          </div>
        </Heading>


        <div className="row">
          <TextAttribute name="name"
                         className="col-sm-4"
                         parent={this}
          />
          <ComboBoxAttribute name="type"
                             className="col-sm-4"
                             options={[PROJECT_TYPES.DRUG_SUBSTANCE, PROJECT_TYPES.DRUG_PRODUCT]}
                             default={PROJECT_TYPES.DRUG_PRODUCT}
                             parent={this}
                             isLoading={this.state.isLoading}
                             updatedAt={this.state.updatedAt}
                             parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                             parentId={this.state.id}
          />
          <TypeaheadAttribute name="projectSponsor"
                              className="col-sm-4"
                              typeaheadType="User"
                              parent={this}
                              isLoading={this.state.isLoading}
                              updatedAt={this.state.updatedAt}
                              parentId={this.state.id}
          />
        </div>

        <div className="row">
          <div className="attribute-container col-sm-4">
            <label className="col-form-label base-attribute col-form-label-add-or-edit">
              <span>Risk Management Plan</span>
            </label>
            <div className={"view-attribute " + (isRMPLabelLoading ? "skeleton" : "")}>
              <span>
                <a id="rMPComboBox" href={rmpURL} rel="noreferrer">{rmpLabel}</a>
              </span>
            </div>
          </div>
          <div className="attribute-container col-sm-4">
            <label className="col-form-label base-attribute col-form-label-add-or-edit">
              <span>Product Risk Assessment Type</span>
            </label>
            <div className="view-attribute">
              <span>{this.state.productRiskAssessmentType}</span>
            </div>
          </div>
        </div>

        <div className="row">
          <ComboBoxAttribute name="category"
                             className="col-sm-4"
                             options={["Small Molecule",
                               "Peptide",
                               "Biologic-mAb",
                               "Biologic-Gene",
                               "Biologic-Cell",
                               "Biologic-Tissue",
                               "Biologic-Other"]}
                             parent={this}
                             isLoading={this.state.isLoading}
                             parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                             parentId={this.state.id}
          />
          <TextAttribute name="routeOfAdministration"
                         displayName="Route of Administration"
                         className="col-sm-4"
                         parent={this}
                         tooltipText={(
                           <div>
                             A route of administration in pharmacology and toxicology is the path by which a drug,
                             fluid, poison, or other substance is taken into the body.<br />
                             <br />
                             For a complete list of routes of administration defined by the FDA, please visit&nbsp;
                             <a rel="noopener noreferrer"
                                target="_blank"
                                href="https://www.fda.gov/drugs/data-standards-manual-monographs/route-administration"
                             >
                               this site
                             </a>.
                           </div>
                         )}
                         disabled={this.state.type !== PROJECT_TYPES.DRUG_PRODUCT}
          />
          <TypeaheadAttribute name="projectManager"
                              className="col-sm-4"
                              typeaheadType="User"
                              parent={this}
                              isLoading={this.state.isLoading}
                              updatedAt={this.state.updatedAt}
                              parentId={this.state.id}
          />
        </div>
        <div className="row">
          <TextAreaAttribute name="purposeAndScope"
                             displayName="Purpose and Scope"
                             tooltipText="Describe the overall function and/or breadth of the project"
                             isLoading={this.state.isLoading}
                             className="col-sm-12"
                             parent={this}
          />
        </div>
      </div>
    );
  }

  getRMP() {
    return this.state.RMP;
  }

  getRmpLabel() {
    const RMP = this.getRMP();
    return RMP ? `${RMP.name} (v${RMP.majorVersion}.${RMP.minorVersion})` : "";

  }

  getRmpUrl() {
    const rmp = this.getRMP();
    const isLatestApprovedVersion = rmp?.RMPId === rmp?.LastApprovedVersionId;
    const url = isLatestApprovedVersion ? getURLByTypeCodeAndId("RMP", "View", rmp?.id, true) : getURLByTypeCodeAndIdAndVersionId("RMP", "View", rmp?.RMPId, true, rmp?.id);
    return url ?? "";
  }
}
