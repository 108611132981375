import * as styles from "./direct_scope_record_tree_item.module.scss";

import React from "react";
import {DirectScopeRecord} from "../../../services/direct_scope_service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";

type DirectScopeRecordTreeItemProps = {
  record: DirectScopeRecord;
};

export default function DirectScopeRecordTreeItem(
  props: DirectScopeRecordTreeItemProps,
) {
  const {record} = props;
  return (
    <div className="row m-0 w-100 align-items-center justify-content-between">
      <div className="row m-0 align-items-baseline w-75">
        <div className="mr-1 text-truncate mw-75 direct-scope-tree-item-name">
          {record.name}
        </div>
        <small>
          {record.typeCode}-{record.id}
        </small>
      </div>
      <div>
        <FontAwesomeIcon
          icon={faChevronRight}
          className={`btn btn-sm ${styles["direct-scope-record-tree-item-insert-button"]}`}
        />
      </div>
    </div>
  );
}
