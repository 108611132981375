"use strict";

import * as UIUtils from "../ui_utils";

/**
 * This is a Reports URL builder. You can use this to manipulate URLs for accessing the reports on the client.
 */
export default class ReportURLGenerator {

  /**
   * Use this to create a URL which can be used on the client to access a report
   * @param reportOptions An enumeration value from the REPORT_OPTIONS_ENUM enumeration. The enum value is an object
   * which need to have at least 2 attributes to build the report URL.
   * 1. The base URL of the report (baseURL)
   * 2. An array with the URL parameters (urlParams)
   * As an example look at the ControlChartsContinuousReport enumeration in REPORT_OPTIONS_ENUM
   * @param urlParams An object which contains the values for the URL params required to construct the URL. The URL
   * params required to construct the URL should be defined in the urlParams field of the REPORT_OPTIONS_ENUM
   * enumeration.
   * @returns {*}
   */
  static generateURL(reportOptions, urlParams) {
    return UIUtils.getSecuredURL(`${reportOptions.baseURL}?` +
      reportOptions.urlParams.map(param => {
        const value = urlParams[param];
        return value ? `${param}=${value}` : "";
      }).filter(entry => entry).join("&"));
  }
}
