var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, useRtl, getTabIndex } from '@progress/kendo-react-common';
import { calculateRatio } from '../common/utils';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
/**
 * Represents the [KendoReact ChunkProgressBar component]({% slug overview_chunkprogressbar %}).
 *
 * @example
 * ```jsx
 * const App = () => {
 *   return (
 *       <ChunkProgressBar value={40}/>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var ChunkProgressBar = React.forwardRef(function (props, target) {
    validatePackage(packageMetadata);
    var _a = props.chunkCount, chunkCount = _a === void 0 ? defaultProps.chunkCount : _a, className = props.className, disabled = props.disabled, orientation = props.orientation, _b = props.min, min = _b === void 0 ? defaultProps.min : _b, _c = props.max, max = _c === void 0 ? defaultProps.max : _c, _d = props.reverse, reverse = _d === void 0 ? defaultProps.reverse : _d, style = props.style, tabIndex = props.tabIndex, emptyStyle = props.emptyStyle, emptyClassName = props.emptyClassName, progressStyle = props.progressStyle, progressClassName = props.progressClassName;
    var elementRef = React.useRef(null);
    var focusElement = React.useCallback(function () {
        if (elementRef.current) {
            elementRef.current.focus();
        }
    }, []);
    React.useImperativeHandle(target, function () { return ({
        element: elementRef.current,
        focus: focusElement
    }); });
    var value = (props.value || defaultProps.value);
    var indeterminateProp = props.value === null;
    var dir = useRtl(elementRef, props.dir);
    var isVertical = orientation === 'vertical';
    var wrapperProps = {
        className: classNames('k-widget k-progressbar', {
            'k-progressbar-horizontal': !isVertical,
            'k-progressbar-vertical': isVertical,
            'k-progressbar-reverse': reverse,
            'k-progressbar-indeterminate': indeterminateProp,
            'k-disabled': disabled
        }, className),
        ref: elementRef,
        dir: dir,
        tabIndex: getTabIndex(tabIndex, disabled),
        role: 'progressbar',
        'aria-label': props.ariaLabel,
        'aria-valuemin': min,
        'aria-valuemax': max,
        'aria-valuenow': indeterminateProp ? undefined : value,
        'aria-disabled': disabled,
        style: style
    };
    var Chunks = function (_a) {
        var count = _a.count;
        var chunks = [];
        var chunkSizePercentage = 100 / count + '%';
        var progressRatio = calculateRatio(min, max, value);
        var completedChunksCount = Math.floor(progressRatio * count);
        var completedChunks = Array(count).fill(false);
        var reverseCompletedChunks = isVertical && !reverse || !isVertical && reverse;
        for (var i = 0; i < completedChunksCount; i++) {
            completedChunks[i] = true;
        }
        if (reverseCompletedChunks) {
            completedChunks.reverse();
        }
        for (var i = 0; i < count; ++i) {
            var isComplete = completedChunks[i];
            var classes = isComplete ? progressClassName : emptyClassName;
            var layoutStyles = {
                width: !isVertical ? chunkSizePercentage : undefined,
                height: isVertical ? chunkSizePercentage : undefined
            };
            var styles = isComplete ? progressStyle : emptyStyle;
            var mergedStyles = __assign(__assign({}, layoutStyles), styles);
            chunks.push(React.createElement("li", { key: i, className: classNames('k-item', {
                    'k-first': i === 0,
                    'k-last': i === count - 1,
                    'k-selected': isComplete
                }, classes), style: mergedStyles }));
        }
        return React.createElement(React.Fragment, null, chunks);
    };
    return (React.createElement("div", __assign({}, wrapperProps),
        React.createElement("ul", { className: 'k-reset' },
            React.createElement(Chunks, { count: chunkCount || defaultProps.chunkCount }))));
});
ChunkProgressBar.propTypes = {
    chunkCount: PropTypes.number,
    ariaLabel: PropTypes.string,
    disabled: PropTypes.bool,
    reverse: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    value: PropTypes.number,
    tabIndex: PropTypes.number,
    emptyStyle: PropTypes.object,
    emptyClassName: PropTypes.string,
    progressStyle: PropTypes.object,
    progressClassName: PropTypes.string
};
var defaultProps = {
    chunkCount: 5,
    min: 0,
    max: 100,
    value: 0,
    disabled: false,
    reverse: false
};
ChunkProgressBar.defaultProps = defaultProps;
ChunkProgressBar.displayName = 'KendoChunkProgressBar';
