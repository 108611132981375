"use strict";

/**
 * Use this to change the behavior of a bootstrap drop down component, so that it does not automatically close the
 * drop down panel, when certain items are clicked. You can control which items do not result in the drop down panel
 * being closed by attaching a class to each one of them, the inactive item class.
 * @param parentContainerSelector This is the selector to get the parent div/container of the drop down.
 * @param itemContainerClass This is a CSS class attached to all drop down items.
 * @param inactiveItemClass This is a CSS class attached to inactive drop down items. Those items will not result in
 * the drop down panel being closed when they are clicked.
 * https://stackoverflow.com/questions/28458888/hide-bs-dropdown-event-origin-target#
 */
module.exports.registerInactiveItemsBehavior = function(parentContainerSelector, itemContainerClass, inactiveItemClass) {
  $(parentContainerSelector).on("hide.bs.dropdown", e => {
    if (e && e.clickEvent && e.clickEvent.target) {
      let target = $(e.clickEvent.target);
      if (!target.hasClass(itemContainerClass)) {
        target = target.parents(`.${itemContainerClass}`);
      }

      if (target.length > 0 && target.hasClass(inactiveItemClass)) {
        return false;
      }
    }

    return true;
  });
};