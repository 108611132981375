"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ImplementationNeededError from "../../utils/implementation_needed_error";
import ErrorBar from "../../widgets/bars/error_bar";
import BaseReactComponent from "../../base_react_component";
import { IMPORT_TYPE_KEY } from "../constants/import_constants";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { LIBRARY_MATERIAL_TYPE_CODE } from "../../library/library_constants";

/**
 * This provides base functionality for the Import Wizard steps.
 * Certain properties are not provided directly in the source code but are native supported by the react-step-wizard
 * control, like:
 * - this.props.currentStep
 * - this.props.nextStep()
 * - this.props.previousStep()
 * https://www.npmjs.com/package/react-step-wizard
 */
export default class ImportBaseStep extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: this.props.config.visible
    };
  }

  componentDidUpdate() {
    const {stepError, onWizardStepDataUpdated} = this.props;
    if (stepError) {
      UIUtils.showError(stepError);
      onWizardStepDataUpdated({
        stepError: null
      });
    }
  }

  handleMoveToNextStep() {
    const {wizardStepsConfig, config, totalSteps, handleMoveToStep} = this.props;
    let currentStepIndex = wizardStepsConfig.indexOf(config);
    if (totalSteps > currentStepIndex + 1) {
      let newStepConfig = wizardStepsConfig[currentStepIndex + 1];
      handleMoveToStep(newStepConfig.step);
    }
  }

  handleMoveToPreviousStep() {
    let {wizardStepsConfig, config, handleMoveToStep} = this.props;
    let currentStepIndex = wizardStepsConfig.indexOf(config);
    if (currentStepIndex > 0) {
      let newStepConfig = wizardStepsConfig[currentStepIndex - 1];
      handleMoveToStep(newStepConfig.step);
    }
  }

  isNextStepDisabled() {
    const {wizardStepsConfig, config, totalSteps, hasPermissionToImport} = this.props;
    let currentStepIndex = wizardStepsConfig.indexOf(config);
    if (totalSteps > currentStepIndex + 1) {
      let nextStepConfig = wizardStepsConfig[currentStepIndex + 1];
      return nextStepConfig.disabled || !hasPermissionToImport;
    } else {
      return !hasPermissionToImport;
    }
  }

  activate() {
    UIUtils.clearError();
    this.setStateSafely({
      visible: true
    });
  }

  deactivate(stepIndexToMoveTo) {
    UIUtils.clearError();
    this.props.goToStep(stepIndexToMoveTo);
  }

  handleImportExit() {
    const {projectId, projectType, process, selectedTab, importConfig, selectedDependencyRecord} = this.props;

    let url;
    if (importConfig.key === IMPORT_TYPE_KEY.LIBRARY_COA && selectedDependencyRecord) {
      url = getURLByTypeCodeAndId(LIBRARY_MATERIAL_TYPE_CODE, "View", selectedDependencyRecord.getInternalId(), true);
    } else {
      url = "/import/importDashboard.html?exitImport=true";
      url += projectId ? `&projectId=${projectId}` : "";
      url += projectType ? `&projectType=${projectType}` : "";
      url += process ? `&processId=${process.id}` : "";
      url += selectedTab ? `&selectedTab=${selectedTab}` : "";
    }

    window.location.href = UIUtils.getSecuredURL(url);
  }

  renderImportExitButton() {
    return (
      <button className="btn btn-lg btn-secondary"
              id="exitImportButton"
              onClick={this.handleImportExit}
      >
        Exit Import
      </button>
    );
  }

  renderStep() {
    throw new ImplementationNeededError();
  }

  render() {
    const {currentStep} = this.props;
    const {visible} = this.state;
    return (
      <div className={"row row-white import-wizard-steps-container"
        + (currentStep === 1 ? " first-wizard-step" : "")
        + (!visible ? " hidden" : "")}
      >
        <ErrorBar className="import-error-bar" />
        {this.renderStep()}
      </div>
    );
  }
}
