"use strict";
import React from "react";
import BaseAttribute from "./base_attribute";


/**
 * This class takes care of rendering errors manually.
 *
 * NOTE: If you use this as a base class and you override handleChange(), you need to call this.clearValidationErrors()
 * when it is called (or the value of your attribute is set otherwise) so the error will go away once they type something in.
 */
export default class BaseCustomErrorAttribute extends BaseAttribute {
  constructor(props) {
    super(props);

    this.state.isShowingError = false;
  }

  componentDidMount() {
    super.componentDidMount();

    // Let the parent know we're handling error messages ourselves
    this.props.parent.registerCustomValidatedAttribute(this);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.props.parent.unregisterCustomValidatedAttribute(this);
  }

  handleChange(event) {
    super.handleChange(event);
    this.clearValidationErrors();
    /* We want to trigger the custom attribute validation 500 ms after the attribute's value changes to mimic
       bootstrap validator behavior, where once an invalid value is set on a control the error shows up after a few
       milliseconds.
     */
    setTimeout(this.validate, 500);
  }

  // Override in child controls to implement custom validation.
  validate() {
    if (this.isRequiredForSaving() || (this.isRequiredForProposing() && this.props.parent.isInProposeValidationMode())) {
      if (!this.isValueSet() && !this.isView()) {
        this.setErrorText(this.getDisplayName() + " is required.");
        return false;
      }
    }

    if (this.props.onValidate) {
      const errorText = this.props.onValidate();
      if (errorText) {
        this.setErrorText(errorText);
        return false;
      } else {
        this.clearValidationErrors();
      }
    }

    return true;
  }

  setErrorText(someHTML) {
    const {parent} = this.props;
    const errorDivSelector = "#" + this.getInputId() + "ErrorDiv";
    if (someHTML) {
      $(errorDivSelector).html(DOMPurify.sanitize(someHTML));
      this.setStateSafely({isShowingError: true});
      let numberOfFieldsWithErrors = $(".has-error").length;
      /* Scroll to this item if nothing else has an error. Controls inheriting from the base-json-attribute control
         are a special case, since once a validation error is triggered on them, they will always show the error div
         with all of its contents hidden. The condition below checks if any visible error divs are hidden
         base-jason-attribute control error divs also.
       */
      if ((numberOfFieldsWithErrors === 0
          || $(".base-json-attribute-error-div.has-error.has-danger .hidden").length === numberOfFieldsWithErrors)
        && parent
        && parent.scrollToElement) {
        parent.scrollToElement($(errorDivSelector));
      }
    } else {
      $(errorDivSelector).text("");
      this.setStateSafely({isShowingError: false});
    }
    this.forceUpdate();
  }

  clearValidationErrors() {
    this.setErrorText();
  }

  isValueSet() {
    throw Error("You must override this method.");
  }

  getOuterDivClass() {
    return (this.isView() ? "view-attribute" : "form-group")
      + (this.state.isShowingError ? " custom-has-error" : "");
  }
}
