"use strict";

import React from "react";
import documentEmptyStateArrow from "./images/document-empty-state-arrow.png";
import AddButton from "./widgets/add_button";
import { generateTooltip } from "../utils/ui_permissions";
import CommonSecurity from "../../server/common/generic/common_security";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import BaseReactComponent from "../base_react_component";

/**
 * This class handles the document bulk add empty state when there are no documents.
 */
// i18next-extract-mark-ns-start documents
class DocumentEmptyState extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let {className, t,} = this.props;

    return (
      <div id="documentEmptyStateScreen"
           className={"container shadow document-empty-state " + (className || "")}>
        <img src={documentEmptyStateArrow}
             className={"document-empty-state-arrow" + (this.isLoading() ? " d-none" : "")}
             alt={t("Add Document")} />
        <div className="document-empty-state-header">
          <span className="document-empty-state-title">
            {t("0 Documents")}
          </span>
          <AddButton
            onFilesSelection={this.props.onFilesSelection} projectId={this.props.projectId} />
        </div>
        <div className={"document-empty-state-body" + this.getClassForLoading()}
             title={generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.DOCUMENT)}>
          <div className="document-empty-state-body-main-text">
            {t("Start adding your documents")}
          </div>
          <div className="document-empty-state-body-sub-text">
            {t("Upload by clicking on ‘Add’ button or drag and drop from your local drive")}
          </div>
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(DocumentEmptyState, "documents");
// i18next-extract-mark-ns-stop documents
