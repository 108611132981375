"use strict";

import { StatusDisplayService } from "./status_display_service";

/**
 * A WebSockets status service implementing callbacks for updating the state of the React page establishing
 * the connection.
 */
export class StatusCallbackService extends StatusDisplayService {
  // @formatter:off
  /**
   * @param displayCallback {StatusDisplayCallback} A method that takes a string message to handle displaying because the server has returned a partial status.
   * @param hideCallback {StatusHideCallback} An optional method that should be called to hide whatever is being displayed.
   */
  constructor(displayCallback = () => {}, hideCallback= () => {}) {
  // @formatter:on
    super();

    /**
     * @type {StatusDisplayCallback}
     */
    this.displayCallback = displayCallback;
    /**
     * @type {StatusHideCallback}
     */
    this.hideCallback = hideCallback;
  }

  /**
   * Displays a status message and executes the callback passed in.
   * @param message {string} The status message to be displayed
   * @param details {*} The details for the message
   */
  displayStatus(message, details = null) {
    if (this.displayCallback) {
      return this.displayCallback(message, details);
    }
  }

  /**
   * Hides the status message
   * @param immediately {boolean} Indicates that the status should be hidden even if there's a pending operation
   */
  hideStatus(immediately = false) {
    super.hideStatus(immediately);
    return this.hideCallback(immediately);
  }
}
