"use strict";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import CompanyHeader from "../headers/company_header";
import PropTypes from "prop-types";
import React from "react";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import moment from "moment";
import BaseReactComponent from "../../base_react_component";
import { QBDLink } from "../../utils/withRouter";

const USER_MENU_ITEMS = [
  {
    title: "Home",
    href: "/homePage/dashboard.html",
    id: "home",
    class: "user-settings-nav-menu-item-margin-top",
  },
  {
    title: "Documents",
    href: "/documents/list.html",
    id: "documents",
  },
  {
    title: "Library",
    href: "/library/list.html",
    id: "library",
  },
  {
    title: "Import Data",
    href: "/import/importDashboard.html",
    id: "import",
  },
  {
    title: "Projects",
    href: "/projects/list.html",
    id: "projects",
  },
  {
    title: "Risk Management Plans",
    href: "/rmps/list.html",
    id: "rmps",
  },
  {
    title: "Suppliers",
    href: "/suppliers/list.html",
    id: "suppliers",
  },
  {
    title: "Training",
    href: "/training/list.html",
    id: "training",
  },
  {
    title: "Users",
    href: "/users/list.html",
    id: "users",
  },
  {
    title: "Separator",
  },
  {
    title: "My Profile",
    href: "getMyProfileLink",
    id: "myprofile",
  },
  {
    title: "My Training",
    href: "/training/myTraining.html",
    id: "mytraining",
  },
  {
    title: "Logout",
    id: "logout",
    onClick: (e) => {
      CompanyHeader.handleLogout(e);
    },
  },
  {
    title: "Separator",
  },
  {
    title: "Privacy Policy",
    href: "/terms/privacyPolicy.html",
    target: "_blank",
    id: "privacyPolicy",
  },
  {
    title: (
      <span id="userSettingsCopyright">
        &copy; 2017-{moment().format("YYYY")} <br />
        <a href="https://www.qbdvision.com" target="_blank" rel="noopener noreferrer" className="user-settings-nav-menu-copyright-link">
          QbDVision, Inc.
        </a>
      </span>
    ),
    href: "https://www.qbdvision.com",
    target: "_blank",
    id: "copyright",
    class: "user-settings-nav-menu-item-margin-bottom user-settings-nav-menu-copyright",
  },
];

/**
 * This class is used to show the global user settings menu
 */
class UserSettingsMenu extends BaseReactComponent {

  getMyProfileLink() {
    return this.props.href;
  }

  handleCloseDropDown() {
    $("#userNameDropDown").dropdown("toggle");
  }

  render() {
    const {t, title} = this.props;

    return (
      <div id="userNameDiv">
        <div id="userSettingsNav" className="dropdown user-settings-nav-div">
          <div className="dropdown-menu user-settings-nav-list"
               aria-labelledby="userNameDropDown"
          >
            <form>
              <div>
                <div className="user-settings-username-div">
                  <div className="user-settings-user-icon">
                    <FontAwesomeIcon icon={faUserCircle} />
                  </div>
                  <div className="user-settings-username">
                  <span id="currentUserName"
                        className="user-settings-nav-list-username"
                        title={title}
                  >
                    {title}
                  </span>
                  </div>
                </div>
                {USER_MENU_ITEMS.map((item, index) => {
                  let hideItem = item.hide ? this[item.hide]() : false;
                  let target = item.target ?? "_self";
                  let href = item.href && typeof this[item.href] === "function" ?
                    this[item.href]() : item.href;

                  return (
                    item.title === "Separator" ?
                      <div key={"separator" + index}
                           className="user-settings-nav-menu-separator"
                      /> :
                      hideItem ? "" :
                        (typeof item.title === "string" ?
                            <QBDLink id={item.id}
                                     to={href}
                                     key={index}
                                     target={target}
                                     style={{padding: 0, margin: 0}}
                                     onClick={item.onClick ?? this.handleCloseDropDown}
                            >
                              <div
                                className={"user-settings-nav-menu-item" + (" " + item.class || "")}
                              >
                                {t(item.title)}
                              </div>
                            </QBDLink>
                            :
                            <div key={index}
                                 className={"user-settings-nav-menu-item" + (" " + item.class || "")}
                            >
                              {item.title}
                            </div>
                        )
                  );
                })}
              </div>
              <a className="user-settings-nav-close-icon"
                 href="#"
                 onClick={this.handleCloseDropDown}
                 role="button"
                 id="userNameCloseDropDown"
                 aria-haspopup="true"
                 aria-expanded="false"
              >
                <FontAwesomeIcon icon={faTimes} size="sm" />
              </a>
            </form>
          </div>
          <a className="user-settings-nav-menu-icon"
             href="#"
             onClick={e => e.preventDefault()}
             role="button"
             id="userNameDropDown"
             data-toggle="dropdown"
             aria-haspopup="true"
             aria-expanded="false"
          >
            <FontAwesomeIcon icon={faBars} />
          </a>
        </div>
      </div>
    );
  }
}

UserSettingsMenu.propTypes = {
  title: PropTypes.any,
  href: PropTypes.string,
};

export default I18NWrapper.wrap(UserSettingsMenu, "widgets");
// i18next-extract-mark-ns-stop widgets
