"use strict";

import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GridColumn } from "@progress/kendo-react-grid";
import ConfigurableTablesColumnGenerator
  from "../../configurableTables/tables/columnGenerator/configurable_tables_columns_generator";
import {
  createExpandColumnCell,
  createExpandHeaderCell,
  updateExpandButtonStyle
} from "./configurable_tables_helper";
import { ConfigurableTable } from "./configurable_table";
import {
  ASSESSMENT_MODE_SELECTOR,
  ASSESSMENT_MODE_SELECTOR_ACCEPTANCE_CRITERIA_RANGES,
} from "../columnSelection/constants";

export const ConfigurableMasterDetailsTable = memo((props) => {

  const {
    detailsTable,
    project,
    records,
  } = props;

  const gridRef = useRef(null);
  const [localRecords, setLocalRecords] = useState([]);

  useEffect(() => {
    setLocalRecords(records);
  }, [records]);

  const handleExpandChange = useCallback(({ dataItem, dataIndex }) => {
    dataItem.expanded = !dataItem.expanded;
    dataItem.columnGenerator = new ConfigurableTablesColumnGenerator(props);

    const { id } = dataItem;
    setLocalRecords(prevRecords =>
      prevRecords.map((record) => (record.id === id ? dataItem : record))
    );
    updateExpandButtonStyle(dataItem, dataIndex);
  }, []);

  const assessmentMode = useMemo(() => {
    return (project?.productRiskAssessmentType === "Preliminary Hazards Analysis (PHA)" ||
      localStorage.getItem(ASSESSMENT_MODE_SELECTOR) === ASSESSMENT_MODE_SELECTOR_ACCEPTANCE_CRITERIA_RANGES)
      ? "AcceptanceCriteriaRanges"
      : "riskLinksDetails";
  }, [project]);

  return (
    <ConfigurableTable
      ref={gridRef}
      detail={detailsTable}
      expandField="expanded"
      ColumnGeneratorClass={ConfigurableTablesColumnGenerator}
      {...props}
      records={localRecords}
    >
      <GridColumn field="expanded"
                  headerCell={createExpandHeaderCell}
                  cell={(props) => createExpandColumnCell(props, handleExpandChange, assessmentMode)}
                  width="40px" locked
                  reorderable={false}
                  resizable={false}
                  orderIndex={0}
      />
    </ConfigurableTable>
  );
});
