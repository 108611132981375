"use strict";

import * as UIUtils from "../../../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import OCRImageViewer from "./ocr_image_viewer";
import OCRTextViewer from "./ocr_text_viewer";
import OCRControlBar from "./ocr_control_bar";
import OCRPDFViewer from "./ocr_pdf_viewer";
import * as PdfJsFacade from "./pdfjs_facade";
import OCRResultsColumn from "./ocr_results_column";
import { COA_COLUMN_TAGS, SELECTED_CONTENT } from "../../../constants/import_constants";
import BaseReactComponent from "../../../../base_react_component";

export const WIDGET_VIEW = {
  PNG: {
    title: "PNG",
    visible: true
  },
  PDF: {
    title: "PDF",
    visible: false
  },
  TEXT: {
    title: "TEXT",
    visible: true
  },
};

/**
 * This is responsible for rendering the OCR widget in the AI Import edit screen. This is the widget that shows up
 * in the left side of the Upload and Extract wizard step screen and allows the user to switch from the PNG/PDF view
 * to the extracted text view.
 */
export default class OCRWidget extends BaseReactComponent {
  constructor(props) {
    super(props);

    // References to DOM elements
    this.parentDomContainer = null;

    /* A private variable is used instead of the state for tracking when the user cancelled a pending file upload
       because when an XMLHttpRequest is aborted its ready state is changed to 4 and the readyStateChange event is
       fired before the state has a chance to update, thus there is no other way for us to know if the file was
       actually uploaded or the upload was cancelled.
     */
    const {textractResults} = this.props;

    this.state = {
      selectedView: this.isPDF() ? WIDGET_VIEW.PDF : WIDGET_VIEW.PNG,
      originalDocumentHeight: this.props.originalDocumentHeight,
      originalDocumentWidth: this.props.originalDocumentWidth,
      textractResults: textractResults || null,
      totalPages: this.props.totalPages,
      currentPage: 1,
      dataUrl: this.props.dataUrl,
      paperWidth: this.props.paperWidth,
    };
    this.fileModified = false;
    this.pdfRenderingInProcess = false;
    this.textRenderingInProcess = false;
  }

  get renderingInProcess() {
    let isPDF = this.isPDF();
    return (isPDF && (this.pdfRenderingInProcess || this.textRenderingInProcess))
      || (!isPDF && this.textRenderingInProcess);
  }

  componentDidMount() {
    super.componentDidMount();
    this.updatePanelsVisibility();
  }

  componentDidUpdate() {
    $("[data-toggle='popover']").popover({sanitizeFn: UIUtils.sanitizePopoverData});
    let {fileData} = this.props;

    if (this.fileModified && fileData && fileData.file) {
      this.loadFile(fileData.file);
    }

    if (this.parentDomContainer && this.state.paperWidth !== this.parentDomContainer.clientWidth) {
      this.setStateSafely({
        paperWidth: this.parentDomContainer.clientWidth,
        paperHeight: this.parentDomContainer.clientHeight
      }, () => {
        this.props.onWizardStepDataUpdated({
          paperWidth: this.state.paperWidth,
          paperHeight: this.state.paperHeight
        });
      });
    }

    this.updatePanelsVisibility();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      panelSizes,
      visible,
      fileData,
      textractResults,
      zoomLevel,
      showAdvancedOptions,
    } = this.props;
    const {
      selectedView,
      originalDocumentHeight,
      originalDocumentWidth,
      dataUrl,
      totalPages,
      currentPage,
      paperWidth,
    } = this.state;

    const nextPanelSizes = nextProps.panelSizes;
    const nextVisible = nextProps.visible;
    const nextFileData = nextProps.fileData;
    const nextTextractResults = nextProps.textractResults;
    const nextZoomLevel = nextProps.zoomLevel;
    const nextShowAdvancedOptions = nextProps.showAdvancedOptions;
    const nextDataUrl = nextState.dataUrl;
    const nextSelectedTab = nextState.selectedView;
    const nextOriginalDocumentHeight = nextState.originalDocumentHeight;
    const nextOriginalDocumentWidth = nextState.originalDocumentWidth;
    const nextTotalPages = nextState.totalPages;
    const nextCurrentPage = nextState.currentPage;
    const nextPaperWidth = nextState.paperWidth;

    this.fileModified = fileData.uuid !== nextFileData.uuid;

    return visible !== nextVisible
      || dataUrl !== nextDataUrl
      || (panelSizes && nextPanelSizes && panelSizes[0] !== nextPanelSizes[0])
      || fileData.uuid !== nextFileData.uuid
      || (
        (!textractResults
          && nextTextractResults)
        ||
        (textractResults
          && nextTextractResults
          && (
            (textractResults.blocks
              && nextTextractResults.blocks
              && textractResults.blocks.length !== nextTextractResults.blocks.length)
            || (textractResults.columns
              && nextTextractResults.columns
              && textractResults.columns.length !== nextTextractResults.columns.length)
          )))
      || zoomLevel !== nextZoomLevel
      || showAdvancedOptions !== nextShowAdvancedOptions
      || selectedView !== nextSelectedTab
      || originalDocumentHeight !== nextOriginalDocumentHeight
      || originalDocumentWidth !== nextOriginalDocumentWidth
      || totalPages !== nextTotalPages
      || currentPage !== nextCurrentPage
      || paperWidth !== nextPaperWidth;
  }

  isPDF() {
    const {fileData} = this.props;
    let fileName = fileData && fileData.file ? fileData.file.name : null;
    const fileExtension = fileName ? UIUtils.getFileExtension(fileName) : "";
    return fileExtension === ".pdf";
  }

  /**
   * This updates the visibility status of the pdf and text containers when the user switches back and forth
   * by selecting the "Original File" and "Converted to Text" views of the uploaded file. At this point we do not
   * want to trigger a new render in React as this will redraw all the text divs and spans. For files with more than
   * 1 page, this results in the UI hanging until the rendering is completed, which gives the user a lagging
   * experience.
   */
  updatePanelsVisibility() {
    let imageViewerContainer = $(".import-data-ocr-image-div");
    let pdfViewerContainer = $(".import-data-ocr-pdf-div");
    let textViewerContainer = $(".import-data-ocr-text-div");
    imageViewerContainer.removeClass("import-visible"); // import-visible doesn't actually do anything, it's just for finding the visible container later.
    pdfViewerContainer.removeClass("import-visible");
    textViewerContainer.removeClass("import-visible");
    imageViewerContainer.addClass("d-none"); // This is bootstrap 4's version of `.hidden`.
    pdfViewerContainer.addClass("d-none");
    textViewerContainer.addClass("d-none");

    switch (this.state.selectedView) {
      case WIDGET_VIEW.PDF:
        pdfViewerContainer.addClass("import-visible");
        pdfViewerContainer.removeClass("d-none");
        break;
      case WIDGET_VIEW.TEXT:
        textViewerContainer.addClass("import-visible");
        textViewerContainer.removeClass("d-none");
        break;
      case WIDGET_VIEW.PNG:
        imageViewerContainer.addClass("import-visible");
        imageViewerContainer.removeClass("d-none");
        break;
    }

    this.updateColumnsCheckboxes();
  }

  updateColumnsCheckboxes() {
    // Set the checked status of the checkboxes in the columns
    const {textractResults} = this.props;
    const {selectedView} = this.state;
    const columns = textractResults && textractResults.columns ? textractResults.columns : [];
    for (let column of columns) {
      const {tableId, columnIndex} = column;
      for (let tag of Object.values(COA_COLUMN_TAGS)) {
        let columnId = OCRResultsColumn.getOCRColumnId(selectedView.title, tableId, columnIndex);
        let dropDownOption = $(`#${columnId}_${UIUtils.convertToId(tag)}`);
        dropDownOption.off("click");
        dropDownOption.on("click", this.handleColumnTagChanged.bind(this, tableId, columnIndex, tag));
      }
    }
  }

  handleColumnTagChanged(tableId, columnIndex, tag) {
    let {textractResults} = this.props;
    const columns = textractResults && textractResults.columns ? textractResults.columns : [];
    let allTableColumns = columns.filter(column => column.tableId === tableId);
    let taggedColumn = allTableColumns.find(column => column.columnIndex === columnIndex);

    // There can be at most one Attributes column in each table
    if (tag === COA_COLUMN_TAGS.MATERIAL_ATTRIBUTE) {
      for (let column of allTableColumns) {
        if (column.isAttributesColumn) {
          this.updateColumnTag(column, COA_COLUMN_TAGS.NONE);
        }
      }
    }

    if (taggedColumn) {
      this.updateColumnTag(taggedColumn, tag);

      this.props.onWizardStepDataUpdated({
        textractResults,
        dataMappingRequired: true,
      });
    }
  }

  updateColumnTag(column, newTag) {
    const {tableId, columnIndex} = column;
    column.isResultsColumn = newTag === COA_COLUMN_TAGS.RESULT;
    column.isAttributesColumn = newTag === COA_COLUMN_TAGS.MATERIAL_ATTRIBUTE;

    for (let view of Object.keys(WIDGET_VIEW)) {
      let columnId = OCRResultsColumn.getOCRColumnId(view, tableId, columnIndex);
      let columnDiv = $(`#${columnId}`);
      if (columnDiv && columnDiv[0]) {
        columnDiv.removeClass("attribute");
        columnDiv.removeClass("result");
        columnDiv.removeClass("none");

        switch (newTag) {
          case COA_COLUMN_TAGS.MATERIAL_ATTRIBUTE:
            columnDiv.addClass("attribute");
            break;
          case COA_COLUMN_TAGS.RESULT:
            columnDiv.addClass("result");
            break;
          case COA_COLUMN_TAGS.NONE:
            columnDiv.addClass("none");
            break;
        }
      }
    }
  }

  loadFile(file) {
    const isPDF = this.isPDF();
    UIUtils.showLoadingImage();

    let fileReader = new FileReader();
    fileReader.addEventListener("load", (e) => {
      let dataUrl = e.target.result;
      let selectedView = isPDF ? WIDGET_VIEW.PDF : WIDGET_VIEW.PNG;

      this.setStateSafely({
        selectedView,
        dataUrl,
      });

      if (isPDF) {
        return PdfJsFacade.getNumberOfPages(dataUrl).then(numberOfPages => {
          this.setStateSafely({
            totalPages: numberOfPages,
            currentPage: 1
          }, () => {
            this.props.onWizardStepDataUpdated({
              dataUrl,
              totalPages: this.state.totalPages,
              isPDF,
              selectedView,
            }, () => {
              UIUtils.hideLoadingImage();
            });
          });
        }).catch(error => {
          if (error.name === "InvalidPDFException") {
            UIUtils.showError("PDF file too large or corrupted.");
          }
          UIUtils.hideLoadingImage();
        });
      } else {
        this.setStateSafely({
          totalPages: 1,
          currentPage: 1
        }, () => {
          this.props.onWizardStepDataUpdated({
            dataUrl,
            totalPages: this.state.totalPages,
            isPDF,
            selectedView,
          }, () => {
            UIUtils.hideLoadingImage();
          });
        });
      }
    });

    fileReader.readAsDataURL(file);
  }

  findVisiblePaperDiv(container) {
    let visibleDiv = $(`.${container.className} .import-visible`);
    if (visibleDiv && visibleDiv[0]) {
      return this.state.selectedView === WIDGET_VIEW.PDF
        ? visibleDiv[0].firstChild
        : visibleDiv[0];
    }
    throw new Error("Visible paper not found.");
  }

  scaleView(scaleFactor) {
    const {zoomLevel} = this.props;

    const paper = this.findVisiblePaperDiv(this.parentDomContainer);
    let newScrollTop = paper.scrollTop * scaleFactor;
    let newScrollLeft = paper.scrollLeft * scaleFactor;
    let newZoomLevel = zoomLevel * scaleFactor;
    paper.scrollTop = newScrollTop;
    paper.scrollLeft = newScrollLeft;

    this.pdfRenderingInProcess = true;
    this.textRenderingInProcess = true;

    this.setStateSafely({
      scrollTop: newScrollTop,
      scrollLeft: newScrollLeft
    }, () => {
      this.props.onWizardStepDataUpdated({
        zoomLevel: newZoomLevel,
      });
    });
  }

  restoreScrollPosition() {
    const paper = this.findVisiblePaperDiv(this.parentDomContainer);
    paper.scrollTop = this.state.scrollTop;
    paper.scrollLeft = this.state.scrollLeft;
  }

  findFocusedPage() {
    const paper = this.findVisiblePaperDiv(this.parentDomContainer);
    const pageScrollHeight = paper.scrollHeight / this.state.totalPages;
    let scrolledPage = (paper.scrollTop / pageScrollHeight) + 1;
    return Math.round(scrolledPage);
  }

  handleImageLoaded(e) {
    let {naturalHeight, naturalWidth} = e.target;
    this.setStateSafely({
      originalDocumentHeight: naturalHeight,
      originalDocumentWidth: naturalWidth
    }, () => {
      this.props.onWizardStepDataUpdated({
        originalDocumentHeight: this.state.originalDocumentHeight,
        originalDocumentWidth: this.state.originalDocumentWidth,
      });
    });
  }

  handleViewChanged(view) {
    this.setStateSafely({
      selectedView: view
    }, () => {
      this.props.onWizardStepDataUpdated({
        selectedView: this.state.selectedView,
      }, () => {
        this.restoreScrollPosition();
      });
    });
  }

  handleZoom(isZoomIn) {
    if (!this.renderingInProcess) {
      let scaleFactor = isZoomIn ? 1.1 : 0.9;
      this.scaleView(scaleFactor);
    }
  }

  handleResetZoom() {
    const {zoomLevel} = this.props;
    let scaleFactor = 100 / zoomLevel;

    if (!this.renderingInProcess && scaleFactor !== 1) {
      this.scaleView(scaleFactor);
    }
  }

  handlePageChange(currentPage) {
    this.setStateSafely({currentPage}, () => {
      if (!this.renderingInProcess && UIUtils.isInteger(currentPage)) {
        const {selectedView} = this.state;
        let parentContainer = selectedView === WIDGET_VIEW.PDF ? "#importOCRPDFViewerDiv" : ".import-data-ocr-text-div";
        if ($(parentContainer).length > 0) {
          UIUtils.scrollToField(`[data-page-number=${this.state.currentPage}]`, 0, parentContainer, false);
        }
      }
    });
  }

  handlePDFRenderingCompleted(numberOfPages, originalDocumentSize, callback) {
    // Render the result column divs on the PDF div wrapper
    const {pdfPageWidth, pdfPageHeight} = originalDocumentSize;

    this.setStateSafely({
      totalPages: numberOfPages,
      originalDocumentWidth: originalDocumentSize.width,
      originalDocumentHeight: originalDocumentSize.height,
      pdfPageHeight,
      pdfPageWidth,
    }, () => {
      this.props.onWizardStepDataUpdated({
        pdfPageHeight,
        pdfPageWidth,
        originalDocumentWidth: this.state.originalDocumentWidth,
        originalDocumentHeight: this.state.originalDocumentHeight,
      }, () => {
        const {
          zoomLevel, textractResults, selectedContent, hideNonTaggedColumns, showAdvancedOptions
        } = this.props;
        const {originalDocumentWidth, originalDocumentHeight, pdfPageHeight, paperWidth, paperHeight} = this.state;
        let shouldRenderPDF = this.state.totalPages < this.props.maxPDFPages;

        /* We can only draw the result columns after the pdf has completed rendering. If we try to do this
         in the React render method of the OCRPFDViewer control, then the PDF rendering engine will clean up the
         result column divs, while rendering the PDF. For this reason we use JQuery to append the OCRResultsColumn
         control right after the PDF has completed rendering.
       */
        if (textractResults && textractResults.columns && shouldRenderPDF) {
          $("#importOCRPDFViewerDiv").append(ReactDOMServer.renderToStaticMarkup(
            <OCRResultsColumn zoomLevel={zoomLevel ? zoomLevel : 100}
                              selectedView={WIDGET_VIEW.PDF}
                              pageHeight={pdfPageHeight}
                              isLibraryMaterial={this.props.isLibraryMaterial}
                              selectedContent={selectedContent}
                              hideNonTaggedColumns={hideNonTaggedColumns}
                              textractResults={textractResults}
                              originalDocumentWidth={originalDocumentWidth}
                              originalDocumentHeight={originalDocumentHeight}
                              paperWidth={paperWidth}
                              paperHeight={paperHeight}
                              showAdvancedOptions={showAdvancedOptions}
            />
          ));

          this.updateColumnsCheckboxes();
        }

        if (this.state.scrollTop > 0 || this.state.scrollLeft > 0) {
          this.restoreScrollPosition();
        } else {
          this.handlePageChange(this.state.currentPage);
        }

        if (callback) {
          callback();
        }

        this.pdfRenderingInProcess = false;
      });
    });
  }

  handleTextRenderingCompleted() {
    this.textRenderingInProcess = false;
  }

  handleScrollCompleted(scrollTop, scrollLeft, scrollCompleted) {
    if (scrollCompleted) {
      this.setStateSafely({
        scrollTop,
        scrollLeft
      });
    } else {
      let scrolledPage = this.findFocusedPage(scrollTop);
      if (this.state.currentPage !== scrolledPage) {
        this.setStateSafely({
          currentPage: scrolledPage
        });
      }
    }
  }

  render() {
    const {
      originalDocumentWidth, originalDocumentHeight, dataUrl, totalPages, currentPage, paperWidth, paperHeight,
      selectedView, pdfPageHeight, pdfPageWidth
    } = this.state;

    const {
      visible, panelSizes, textractResults, zoomLevel, selectedContent, hideNonTaggedColumns, maxPDFPages,
      showAdvancedOptions
    } = this.props;

    const isPDF = this.isPDF();

    return (
      <div className={"import-data-panel" + (!visible ? " d-none" : "")}>
        <div className="import-document-div"
             ref={ref => this.parentDomContainer = ref}
        >
          <OCRImageViewer zoomLevel={zoomLevel}
                          dataUrl={dataUrl}
                          textractResults={textractResults}
                          isLibraryMaterial={this.props.isLibraryMaterial}
                          isPDF={isPDF}
                          selectedContent={selectedContent}
                          hideNonTaggedColumns={hideNonTaggedColumns}
                          paperWidth={paperWidth}
                          paperHeight={paperHeight}
                          originalDocumentWidth={originalDocumentWidth}
                          originalDocumentHeight={originalDocumentHeight}
                          showAdvancedOptions={showAdvancedOptions}
                          onImageLoaded={this.handleImageLoaded}
                          onScroll={this.handleScrollCompleted}
          />
          <OCRPDFViewer zoomLevel={zoomLevel}
                        isPDF={isPDF}
                        maxPDFPages={maxPDFPages}
                        textractResults={textractResults}
                        isLibraryMaterial={this.props.isLibraryMaterial}
                        paperWidth={paperWidth}
                        paperHeight={paperHeight}
                        selectedContent={selectedContent}
                        hideNonTaggedColumns={hideNonTaggedColumns}
                        dataUrl={dataUrl}
                        panelSize={panelSizes[0]}
                        showAdvancedOptions={showAdvancedOptions}
                        onPDFRenderingCompleted={this.handlePDFRenderingCompleted}
                        onScroll={this.handleScrollCompleted}
                        parentDOMSelector=".import-document-div"
                        name={"importOCR"}
          />
          <OCRTextViewer totalPages={totalPages}
                         zoomLevel={zoomLevel}
                         paperWidth={paperWidth}
                         paperHeight={paperHeight}
                         isLibraryMaterial={this.props.isLibraryMaterial}
                         pdfPageHeight={pdfPageHeight}
                         pdfPageWidth={pdfPageWidth}
                         selectedContent={selectedContent}
                         hideNonTaggedColumns={hideNonTaggedColumns}
                         isPDF={isPDF}
                         textractResults={textractResults}
                         originalDocumentWidth={originalDocumentWidth}
                         originalDocumentHeight={originalDocumentHeight}
                         showAdvancedOptions={showAdvancedOptions}
                         onTextRenderingCompleted={this.handleTextRenderingCompleted}
                         onScroll={this.handleScrollCompleted}
          />
        </div>
        <OCRControlBar totalPages={totalPages}
                       currentPage={currentPage}
                       isPDF={isPDF}
                       selectedView={selectedView}
                       onViewChange={this.handleViewChanged}
                       onResetZoom={this.handleResetZoom}
                       onZoomIn={this.handleZoom.bind(this, true)}
                       onZoomOut={this.handleZoom.bind(this, false)}
                       onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

OCRWidget.defaultProps = {
  zoomLevel: 100,
  selectedContent: SELECTED_CONTENT.ALL_DATA,
  originalDocumentWidth: 0,
  originalDocumentHeight: 0,
  totalPages: 1,
  paperWidth: null
};
