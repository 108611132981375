"use strict";

import RecordFields from "../record_fields";
import { FILTER_TYPES } from "../../columnOperations/configurable_tables_column_operations_constants";
import {
  COMMON_VARIABLE_ABOUT_COLUMNS,
  SENDING_SITE_COMMON_COLUMNS_PROPS,
} from "../constants/configurable_tables_columns_config";
import { FIELD_PROPS } from "../constants/configurable_tables_field_props_config";
import { SENDING_SITE_LABEL } from "../constants/configurable_tables_columns_config";
import { RISK_CONTROL_SECTION } from "../constants/configurable_tables_sections_config";
import { RISK_CONTROL_COL_SPAN } from "../constants/configurable_tables_col_spans_config";
import CommonEditables from "../../../../server/common/editables/common_editables";

/**
 * This class lists all Material Attribute fields to be shown in export, column selection and tables.
 */
export default class MaterialAttributeFields extends RecordFields {
  get title() {
    return "Material Attributes";
  }

  get aboutSectionFields() {
    return super.filterTechTransferFields(COMMON_VARIABLE_ABOUT_COLUMNS);
  }

  get riskControlSectionFields() {
    let parentFields = super.riskControlSectionFields;

    const controlStrategyIndex = parentFields.findIndex(field => field.prop === FIELD_PROPS.CONTROL_STRATEGY
      && field.originatedFromSite === SENDING_SITE_LABEL);

    parentFields[controlStrategyIndex] = {
      title: "Control Strategy",
      prop: FIELD_PROPS.CONTROL_STRATEGY,
      colspan: RISK_CONTROL_COL_SPAN,
      section: RISK_CONTROL_SECTION,
      defaultValue: true,
      defaultTechTransferValue: false,
      defaultChangeCriteriaValue: true,
      filterType: FILTER_TYPES.COMBO_BOX,
      filterLogic: "contains",
      filterOptions: () => CommonEditables.MATERIAL_ATTRIBUTE_CONTROL_STRATEGY_OPTIONS,
      ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      tableValue: (columnGenerator) => columnGenerator.generateMultiSelectionColumn("Control Strategy", FIELD_PROPS.CONTROL_STRATEGY),
    };

    return parentFields;
  }
}
