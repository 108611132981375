var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { MenuItemInternal } from './MenuItemInternal';
import { getDOMElementId } from './../utils/misc';
/**
 * @hidden
 */
var MenuItemInternalsList = /** @class */ (function (_super) {
    __extends(MenuItemInternalsList, _super);
    function MenuItemInternalsList() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onMouseOver = function (event) {
            _this.props.onMouseOver(_this.props.parentItemId);
            event.stopPropagation();
        };
        _this.onMouseLeave = function (event) {
            _this.props.onMouseLeave(_this.props.parentItemId);
            event.stopPropagation();
        };
        return _this;
    }
    MenuItemInternalsList.prototype.render = function () {
        var parentItemId = this.props.parentItemId;
        return (React.createElement("ul", { className: this.props.className, role: parentItemId !== undefined ? 'menu' : 'menubar', id: parentItemId !== undefined ? getDOMElementId(this.props.menuGuid, parentItemId) : undefined, onMouseOver: parentItemId !== undefined ? this.onMouseOver : undefined, onMouseLeave: parentItemId !== undefined ? this.onMouseLeave : undefined, "aria-orientation": this.props['aria-orientation'] }, this.renderChildItems()));
    };
    MenuItemInternalsList.prototype.renderChildItems = function () {
        var _this = this;
        return this.props.items.length > 0 ? (this.props.items.map(function (item, index) {
            return (React.createElement(MenuItemInternal, { item: item, isMenuVertical: _this.props.isMenuVertical, isDirectionRightToLeft: _this.props.isDirectionRightToLeft, focusedItemId: _this.props.focusedItemId, lastItemIdToBeOpened: _this.props.lastItemIdToBeOpened, tabbableItemId: _this.props.tabbableItemId, itemRender: _this.props.itemRender, linkRender: _this.props.linkRender, menuGuid: _this.props.menuGuid, onMouseOver: _this.props.onMouseOver, onMouseLeave: _this.props.onMouseLeave, onMouseDown: _this.props.onMouseDown, onBlur: _this.props.onBlur, onFocus: _this.props.onFocus, onClick: _this.props.onClick, onOriginalItemNeeded: _this.props.onOriginalItemNeeded, key: index }));
        })) : null;
    };
    return MenuItemInternalsList;
}(React.Component));
export { MenuItemInternalsList };
