var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import { onDownPreventDefault } from './utils';
import { messages } from '../messages';
import { cleanFormatting as cleanFormattingCmd } from '@progress/kendo-editor-common';
import { EditorToolsSettings } from './../config/toolsSettings';
import { classNames } from '@progress/kendo-react-common';
var cleanFormatting = EditorToolsSettings.cleanFormatting;
var command = cleanFormattingCmd();
/**
 * @hidden
 */
export var CleanFormatting = function (props) {
    var view = props.view, render = props.render, _a = props.settings, settings = _a === void 0 ? cleanFormatting : _a, buttonProps = __rest(props, ["view", "render", "settings"]);
    var titleKey = settings.messages.title;
    var disabled = !view || !command(view.state);
    var onClick = React.useCallback(function () {
        if (view) {
            command(view.state, function (tr) {
                tr.setMeta('commandName', settings.commandName);
                view.dispatch(tr);
            });
        }
    }, [view]);
    var button = (React.createElement(Button, __assign({ onClick: disabled ? undefined : onClick, "aria-disabled": disabled ? true : undefined, title: useLocalization().toLanguageString(titleKey, messages[titleKey]) }, onDownPreventDefault, settings.props, buttonProps, { className: classNames(props.className, settings.props.className, { 'k-disabled': disabled }) })));
    return (render ? render.call(undefined, button, { view: view }) : button);
};
