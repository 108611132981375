var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import ListItem from './ListItem';
import { areSame, getItemValue } from './utils';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { messages, nodata } from '../messages';
/**
 * @hidden
 */
var List = /** @class */ (function (_super) {
    __extends(List, _super);
    function List() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    List.prototype.renderItems = function () {
        var _this = this;
        var _a = this.props, textField = _a.textField, valueField = _a.valueField, groupField = _a.groupField, optionsGuid = _a.optionsGuid, _b = _a.skip, skip = _b === void 0 ? 0 : _b, virtual = _a.virtual, focusedIndex = _a.focusedIndex, _c = _a.highlightSelected, highlightSelected = _c === void 0 ? true : _c, value = _a.value, data = _a.data, itemRender = _a.itemRender;
        var isArray = Array.isArray(value);
        return data.map(function (item, index) {
            var realIndex = skip + index;
            var selected = highlightSelected &&
                ((!isArray && areSame(item, value, valueField)) ||
                    (isArray && value.findIndex(function (i) { return areSame(i, item, valueField); }) !== -1));
            var group = undefined;
            var current, previous;
            if (index > 0 && groupField !== undefined) {
                current = getItemValue(item, groupField);
                previous = getItemValue(data[index - 1], groupField);
                if (current && previous && current !== previous) {
                    group = current;
                }
            }
            return (React.createElement(ListItem, { id: "option-".concat(optionsGuid, "-").concat(realIndex), virtual: virtual, dataItem: item, selected: selected, focused: focusedIndex === index, index: realIndex, key: realIndex, onClick: _this.props.onClick, textField: textField, group: group, render: itemRender }));
        });
    };
    List.prototype.renderNoValueElement = function (localizationService) {
        var noDataRender = this.props.noDataRender;
        var noDataElement = (React.createElement("div", { className: 'k-nodata' },
            React.createElement("div", null, localizationService.toLanguageString(nodata, messages[nodata]))));
        return noDataRender ?
            noDataRender.call(undefined, noDataElement) : noDataElement;
    };
    List.prototype.render = function () {
        var localizationService = provideLocalizationService(this);
        var _a = this.props, id = _a.id, show = _a.show, wrapperCssClass = _a.wrapperCssClass, wrapperStyle = _a.wrapperStyle, listStyle = _a.listStyle, listRef = _a.listRef, wrapperRef = _a.wrapperRef, _b = _a.listClassName, listClassName = _b === void 0 ? 'k-list-ul' : _b;
        var items = this.renderItems();
        return (items.length ? (React.createElement("div", { className: wrapperCssClass, style: wrapperStyle, ref: wrapperRef, onMouseDown: this.props.onMouseDown, onBlur: this.props.onBlur, onScroll: this.props.onScroll, unselectable: "on" },
            React.createElement("ul", { id: id, role: "listbox", "aria-hidden": !show ? true : undefined, className: listClassName, ref: listRef, style: listStyle }, items),
            this.props.scroller && React.createElement("div", { className: "k-height-container" }, this.props.scroller))) : this.renderNoValueElement(localizationService));
    };
    return List;
}(React.Component));
export default List;
registerForLocalization(List);
