var _a;
/**
 * @hidden
 */
export var numericIncreaseValue = 'numerictextbox.increment';
/**
 * @hidden
 */
export var numericDecreaseValue = 'numerictextbox.decrement';
/**
 * @hidden
 */
export var sliderIncreaseValue = 'slider.increment';
/**
 * @hidden
 */
export var sliderDecreaseValue = 'slider.decrement';
/**
 * @hidden
 */
export var sliderDragTitle = 'slider.dragTitle';
/**
 * @hidden
 */
export var colorGradientR = 'colorGradient.r';
/**
 * @hidden
 */
export var colorGradientG = 'colorGradient.g';
/**
 * @hidden
 */
export var colorGradientB = 'colorGradient.b';
/**
 * @hidden
 */
export var colorGradientA = 'colorGradient.a';
/**
 * @hidden
 */
export var colorGradientHex = 'colorGradient.hex';
/**
 * @hidden
 */
export var colorGradientContrastRatio = 'colorGradient.contrastRatio';
/**
 * @hidden
 */
export var colorGradientAALevel = 'colorGradient.colorGradientAALevel';
/**
 * @hidden
 */
export var colorGradientAAALevel = 'colorGradient.colorGradientAAALevel';
/**
 * @hidden
 */
export var colorGradientPass = 'colorGradient.colorGradientPass';
/**
 * @hidden
 */
export var colorGradientFail = 'colorGradient.colorGradientFail';
/**
 * @hidden
 */
export var colorGradientHueSliderLabel = 'colorGradient.hueSliderLabel';
/**
 * @hidden
 */
export var colorGradientAlphaSliderLabel = 'colorGradient.alphaSliderLabel';
/**
 * @hidden
 */
export var colorGradientToggleInputsButton = 'colorGradient.toggleInputsButton';
/**
 * @hidden
 */
export var flatColorPickerCancelBtn = 'flatColorPicker.cancelBtn';
/**
 * @hidden
 */
export var flatColorPickerApplyBtn = 'flatColorPicker.applyBtn';
/**
 * @hidden
 */
export var checkboxValidation = 'checkbox.validation';
/**
 * @hidden
 */
export var checkboxOptionalText = 'checkbox.optionalText';
/**
 * @hidden
 */
export var radioButtonValidation = 'radioButton.validation';
/**
 * @hidden
 */
export var switchValidation = 'switch.validation';
/**
 * @hidden
 */
export var colorPickerDropdownButtonAriaLabel = 'colorPicker.dropdownButtonAriaLabel';
/**
 * @hidden
 */
export var ratingAriaLabel = 'rating.ariaLabel';
/**
 * @hidden
 */
export var signatureClear = 'signature.clear';
/**
 * @hidden
 */
export var signatureMaximize = 'signature.maximize';
/**
 * @hidden
 */
export var signatureMinimize = 'signature.minimize';
/**
 * @hidden
 */
export var messages = (_a = {},
    _a[numericIncreaseValue] = 'Increase value',
    _a[numericDecreaseValue] = 'Decrease value',
    _a[sliderIncreaseValue] = 'Increase',
    _a[sliderDecreaseValue] = 'Decrease',
    _a[sliderDragTitle] = 'Drag',
    _a[colorGradientR] = 'r',
    _a[colorGradientG] = 'g',
    _a[colorGradientB] = 'b',
    _a[colorGradientA] = 'a',
    _a[colorGradientHex] = 'hex',
    _a[colorGradientContrastRatio] = 'Contrast ratio',
    _a[colorGradientAALevel] = 'AA',
    _a[colorGradientAAALevel] = 'AAA',
    _a[colorGradientPass] = 'Pass',
    _a[colorGradientFail] = 'Fail',
    _a[colorGradientHueSliderLabel] = 'Hue slider',
    _a[colorGradientAlphaSliderLabel] = 'Alpha slider',
    _a[colorGradientToggleInputsButton] = 'Toggle colorgradient inputs',
    _a[flatColorPickerCancelBtn] = 'Cancel',
    _a[flatColorPickerApplyBtn] = 'Apply',
    _a[checkboxValidation] = 'Please check this box if you want to proceed!',
    _a[checkboxOptionalText] = '(Optional)',
    _a[radioButtonValidation] = 'Please select option if you want to proceed!',
    _a[switchValidation] = 'Please turn on if you want to proceed!',
    _a[colorPickerDropdownButtonAriaLabel] = 'Select',
    _a[ratingAriaLabel] = 'Rating',
    _a[signatureClear] = 'Clear',
    _a[signatureMaximize] = 'Maximize',
    _a[signatureMinimize] = 'Minimize',
    _a);
