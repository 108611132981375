"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ValidationIcon from "../generic/validation_icon";
import BaseReactComponent from "../../base_react_component";

/* This is a tab navigation control that can be used in a single page to organize the content in a tabular format
 * Example usages of this you will find in the RMP and Reports pages */
export default class TabNavBar extends BaseReactComponent {

  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.parentId !== this.props.parentId ||
           nextProps.parentVersionId !== this.props.parentVersionId ||
           nextProps.selected?.title !== this.props.selected?.title ||
           JSON.stringify(nextProps.errors) !== JSON.stringify(this.props.errors) ||
           JSON.stringify(nextProps.tabs) !== JSON.stringify(this.props.tabs);
  }

  handleClickTab(tab, e) {
    e.preventDefault();
    if (this.props.onTabChanged) {
      this.props.onTabChanged(tab);
    }
  }

  render() {
    let {tabs, selected, modelName, errors, navItemClassName, className} = this.props;
    modelName = modelName ? modelName : "";

    const selectedTab = selected;

    return (
      <div className={"tab-nav-bar" + (className ? " " + className : "")}>
        {
          Object.keys(tabs).filter(key => tabs[key].visible !== false).map(key => {
            let tab = tabs[key];
            if (tab.experiment && !UIUtils.isExperimentEnabled(tab.experiment)) {
              return <div key={modelName + tab.title} />;
            } else {
              return (
                <div key={modelName + tab.title}
                     id={tab.id || `${UIUtils.convertToCamelCaseId(modelName + tab.title)}Tab`}
                     className={
                       "tab-nav-bar-item"
                       + (navItemClassName ? ` ${navItemClassName}` : "")
                       + ((selectedTab?.title === tab?.title || selectedTab === tab) ? " tab-nav-bar-item-selected" : "")
                       + (tab.disabled || tab.isTabLoading ? " links-btn-disabled" : "")
                       + (tab.isTabLoading ? " skeleton" : "")
                     }
                     onClick={!tab.disabled && !tab.isTabLoading ? this.handleClickTab.bind(this, tab) : null}
                >
                  <a title={tab.disabled && tab.disabledTooltip ? tab.disabledTooltip : tab.isTabLoading ? "Please wait. Loading..." : ""}
                     disabled={tab.disabled || tab.isTabLoading}
                     data-tab-title={tab.title}
                     data-model-name={modelName}
                  >
                    {tab.title}
                    <span>{tab.info ? ` ${tab.info}` : ""}</span>
                  </a>
                  <ValidationIcon id={`${UIUtils.convertToCamelCaseId(modelName + tab.title)}TabIcon`}
                                  tooltip={errors ? errors[modelName + tab.title + "TabError"] : ""}
                                  visible={errors && !!errors[modelName + tab.title + "TabError"]}
                  />
                </div>
              );
            }
          })
        }
      </div>
    );
  }
}
