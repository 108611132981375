import {useState, useCallback} from "react";

function getInitialCacheValues(initialValues?: Record<string, Array<any>>) {
  const cache = {};
  if (!initialValues) {
    return cache;
  }

  for (const [key, values] of Object.entries(initialValues)) {
    cache[key] = {};
    for (const value of values) {
      cache[key][value.id] = {...value};
    }
  }
  return cache;
}

export default function useDirectScopeRecordsCache(
  initialValues?: Record<string, Array<any>>,
  // eslint-disable-next-line no-unused-vars
): [Record<string, Record<string, any>>, (record: any) => void] {
  const [cache, setCache] = useState(getInitialCacheValues(initialValues));
  const saveCache = useCallback((record: any) => {
    const { modelName, id } = record;
    if (!modelName || !id) {
      return;
    }

    if (!cache[modelName]) {
      cache[modelName] = {};
    }

    cache[modelName][id] = record;
    setCache({ ...cache });
  }, []);

  return [cache, saveCache];
}

