"use strict";

import { WebSocketsMessageHandler } from "../web_sockets_message_handler";

/**
 * A WebSockets message handler for Editables.
 */
export class EditablesMessageHandler extends WebSocketsMessageHandler {
  /**
   * Creates a handler for the onOpen event of the websocket.
   * @param event {Event}
   * @param context {IConnectionContext}
   * @returns {void}
   */
  handleOpen(event, context) {
    const {payload, parameters} = context;
    const {model} = parameters || {};

    context.payload = {
      model,
      ...payload,
    };

    super.handleOpen(event, context);
  }
}
