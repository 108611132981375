"use strict";

import React, { Fragment } from "react";
import TechTransferReviewRow from "../widgets/tech_transfer_review_row";
import TechTransferReviewHeader from "../widgets/tech_transfer_review_header";
import TechTransferReviewBase from "../tech_transfer_review_base";
import * as I18NWrapper from "../../../i18n/i18n_wrapper";

// i18next-extract-mark-ns-start tech_transfer
/**
 *  This class shows the screen when a user is reviewing a tech transfer record.
 */
class TechTransferMaterialReview extends TechTransferReviewBase {
  renderDiff() {
    const {hideUnchangedFields} = this.state;
    const {reviewRecord, fromProcess, toProcess, onViewChangeCriteria,} = this.props;
    const transferredTo = reviewRecord;
    const transferredFrom = reviewRecord.techTransferredFrom || {};
    const {totalChangesCount, changeCriteria,} = reviewRecord;

    const commonProps = {
      hideUnchangedFields,
      transferredFrom,
      transferredTo,
      changeCriteria,
      totalChangesCount,
      onViewChangeCriteria,
    };

    const usePartialDiffCommonProps = {
      usePartialDiff: true,
      ...commonProps,
    };

    return (
      <Fragment>
        <TechTransferReviewHeader name="name"
                                  onHideUnchangedFields={this.handleHideUnchangedFields}
                                  receivingSite={fromProcess}
                                  sendingSite={toProcess}
        />

        <div className="row">
          <div className="col-12 tech-transfer-review-section-title-container">
            ABOUT
          </div>
        </div>
        <TechTransferReviewRow name="name" {...commonProps} />
        <TechTransferReviewRow name="use" {...commonProps} />
        <TechTransferReviewRow name="category" {...commonProps} />
        <TechTransferReviewRow name="unitOperations" {...commonProps} />
        <TechTransferReviewRow name="steps" {...commonProps} />
        <TechTransferReviewRow name="function" {...usePartialDiffCommonProps} />
        <TechTransferReviewRow name="description" {...commonProps} />
        <TechTransferReviewRow name="links"
                               displayName="Source Documents"
                               isDocs={true}
                               {...usePartialDiffCommonProps} />

        <div className="row">
          <div className="col-12 tech-transfer-review-section-title-container">
            Quantity
          </div>
        </div>
        <TechTransferReviewRow name="descriptiveUnitAbsolute"
                               displayName="Descriptive Unit (Absolute)"
                               {...commonProps} />
        <TechTransferReviewRow name="quantityAbsolute"
                               displayName="Quantity (Absolute)"
                               {...commonProps} />
        <TechTransferReviewRow name="quantityRelative"
                               displayName="Quantity (Relative)"
                               {...commonProps} />
        <TechTransferReviewRow name="quantityLinks"
                               displayName="Source Documents"
                               isDocs={true}
                               {...commonProps} />

        <div className="row">
          <div className="col-12 tech-transfer-review-section-title-container">
            Qualification
          </div>
        </div>
        <TechTransferReviewRow name="supplier" {...commonProps} />
        <TechTransferReviewRow name="partNumber"
                               displayName="Supplier Part Number"
                               {...commonProps} />
        <TechTransferReviewRow name="internalPartNumber" {...commonProps} />
        <TechTransferReviewRow name="materialQualified"
                               displayName="Material/Part Qualified"
                               {...commonProps} />
        <TechTransferReviewRow name="effectiveDate" {...commonProps}
                               isDate={true} />
        <TechTransferReviewRow name="expirationDate" {...commonProps}
                               isDate={true} />
        <TechTransferReviewRow name="qualificationLinks"
                               displayName="Source Documents"
                               isDocs={true}
                               {...usePartialDiffCommonProps} />

        <div className="row">
          <div className="col-12 tech-transfer-review-section-title-container">
            Regulatory
          </div>
        </div>
        <TechTransferReviewRow name="regulatoryFiling" {...commonProps} />
        <TechTransferReviewRow name="referenceNumber" {...commonProps} />
        <TechTransferReviewRow name="authorizationLetter" {...usePartialDiffCommonProps}
                               displayName="Letter of Authorization"
        />
        <TechTransferReviewRow name="regulatoryLinks"
                               displayName="Source Documents"
                               isDocs={true}
                               {...usePartialDiffCommonProps} />

        <div className="row">
          <div className="col-12 tech-transfer-review-section-title-container">
            Properties
          </div>
        </div>
        <TechTransferReviewRow name="drugSubstanceType" {...commonProps} />
        <TechTransferReviewRow name="chemicalStructure" {...commonProps} />
        <TechTransferReviewRow name="empiricalFormula" {...commonProps} />
        <TechTransferReviewRow name="molecularWeight" {...commonProps} />
        <TechTransferReviewRow name="chemicalNameCAS"
                               displayName="Chemical Name (CAS)"
                               {...commonProps} />
        <TechTransferReviewRow name="chemicalNameIUPAC"
                               displayName="Chemical Name (IUPAC)"
                               {...commonProps} />
        <TechTransferReviewRow name="otherNames" {...commonProps} />
        <TechTransferReviewRow name="innUsan"
                               displayName="INN/USAN"
                               {...commonProps} />
        <TechTransferReviewRow name="casRegistryNumber"
                               displayName="CAS Registry Number"
                               {...commonProps} />
        <TechTransferReviewRow name="compendialStandard" {...commonProps} />
        <TechTransferReviewRow name="certificateOfAnalysis"
                               displayName="COA/COC"
                               {...commonProps} />
        <TechTransferReviewRow name="propertiesLinks"
                               displayName="Source Documents"
                               isDocs={true}
                               {...usePartialDiffCommonProps} />

        <div className="row">
          <div className="col-12 tech-transfer-review-section-title-container">
            References & Standards
          </div>
        </div>
        <TechTransferReviewRow name="referencesLinks"
                               displayName="Source Documents"
                               isDocs={true}
                               {...usePartialDiffCommonProps} />
      </Fragment>
    );
  }
}

export default I18NWrapper.wrap(TechTransferMaterialReview, ["tech_transfer", "base_page"]);
// i18next-extract-mark-ns-stop tech_transfer
