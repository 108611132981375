"use strict";

import React, { useEffect, useState } from "react";
import * as styles from "./styles/bulk_edit.module.scss";
import { nameToEditableMap } from "../../../../../../helpers/editables_map";

const getValidFieldsForBulkUpdate = (model) => {
  const {attributesMap} = nameToEditableMap.get(model);
  return Array.from(attributesMap.values())
    .filter(att => ["STRING", "TEXT"].includes(att.type))
    .filter(att => !["clonedFromModel", "currentState"].includes(att.name))
    .filter(att => !att.displayName.includes("Links"));
};

export const FieldDropdown = ({modelName, onFieldChange}) => {

  const options = getValidFieldsForBulkUpdate(modelName);
  const [field, setField] = useState();

  useEffect(() => {
    const [defaultOption] = options;
    const {name} = defaultOption;
    setField(name);
    onFieldChange(name);
  }, []);

  const onChange = ({target}) => {
    const field = target.value;
    const {type} = options.find(opt => opt.name === field);
    setField(field);
    onFieldChange(field, type);
  };

  return (
    <label className={styles["drop-down-label"]}>
      Field*
      <select
        id="fieldDropdown"
        className="form-control category-select"
        value={field}
        onChange={onChange}
        title="Field"
      >
        {options.map((opt, idx) => <option key={idx} value={opt.name}>{opt.displayName}</option>)}
      </select>
    </label>
  );
};