"use strict";

import React from "react";
import OCRResultsColumn from "./ocr_results_column";
import { WIDGET_VIEW } from "./ocr_widget";
import { calculateZoomPercentage } from "../../../helpers/import_helper";
import BaseReactComponent from "../../../../base_react_component";

/**
 * This is responsible for rendering the uploaded image in the Upload and Extract wizard step of the AI import workflow.
 */
export default class OCRImageViewer extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  handleScroll() {
    this.scrollDiv && this.props.onScroll(this.scrollDiv.scrollTop, this.scrollDiv.scrollLeft, false);

    clearTimeout(this.isScrolling);
    this.isScrolling = setTimeout(() => {
      this.scrollDiv && this.props.onScroll(this.scrollDiv.scrollTop, this.scrollDiv.scrollLeft, true);
    }, 100);
  }

  render() {
    const {
      zoomLevel, dataUrl, onImageLoaded, isPDF, textractResults, originalDocumentWidth, originalDocumentHeight,
      paperWidth, paperHeight, selectedContent, hideNonTaggedColumns, showAdvancedOptions
    } = this.props;

    const zoomPercent = calculateZoomPercentage(zoomLevel, paperWidth, paperHeight, originalDocumentWidth, originalDocumentHeight);
    const imageDivWidth = originalDocumentWidth * zoomPercent;

    return (
      <div id="importOCRImageViewerDiv"
           ref={scrollDiv => this.scrollDiv = scrollDiv}
           className="import-data-ocr-image-div"
           onScroll={this.handleScroll}
      >
        <div style={{width: imageDivWidth + "px"}}>
          <img src={!isPDF ? dataUrl : ""}
               className="import-data-ocr-image"
               alt="Originally uploaded file"
               onLoad={onImageLoaded}
          />
        </div>
        <OCRResultsColumn zoomLevel={zoomLevel}
                          selectedView={WIDGET_VIEW.PNG}
                          isLibraryMaterial={this.props.isLibraryMaterial}
                          selectedContent={selectedContent}
                          hideNonTaggedColumns={hideNonTaggedColumns}
                          textractResults={textractResults}
                          originalDocumentWidth={originalDocumentWidth}
                          originalDocumentHeight={originalDocumentHeight}
                          showAdvancedOptions={showAdvancedOptions}
                          paperWidth={paperWidth}
                          paperHeight={paperHeight}
        />
      </div>
    );
  }
}
