import * as licensing from '@progress/kendo-licensing';
/**
 * @hidden
 */
export function validatePackage(packageMetadata) {
    if (typeof licensing !== 'undefined') {
        licensing.validatePackage(packageMetadata);
    }
    else {
        var message = "License activation failed for ".concat(packageMetadata.name, "\n");
        message += 'The @progress/kendo-licensing script is not loaded.\n';
        message += "See ".concat(packageMetadata.licensingDocsUrl, " for more information.\n");
        console.warn(message);
    }
}
