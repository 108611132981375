"use strict";

import React, { useRef, useEffect } from "react";

/**
 * A confirmation modal that will show when the project material is created, the idea we will let the
 * user decides if staying on the MTL screen or redirect to the created material.
 * @param message
 * @param onStay
 * @param onGoToRecord
 * @returns {JSX.Element}
 * @constructor
 */
const ConfirmationModal = ({message, onStay, onGoToRecord}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    $(modalRef.current).modal({
      show: true,
      backdrop: "static",
      keyboard: false
    });
    return () => {
      $(modalRef.current).modal("hide");
    };
  }, []);

  const handleStay = () => {
    $(modalRef.current).modal("hide");
    onStay();
  };

  const handleGo = () => {
    $(modalRef.current).modal("hide");
    onGoToRecord();
  };

  return (
    <div id="confirmationModal" className="modal fade" role="dialog" ref={modalRef}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">Material Added to Project</h1>
          </div>
          <div className="modal-body text-left">
            {message}
          </div>
          <div className="modal-footer">
            <button id="stayInLibraryButton" type="button" className="btn btn-secondary" onClick={handleStay}>
              Stay in Library
            </button>
            <button id="goToMaterialButton" type="button" className="btn btn-primary" onClick={handleGo}>
              Go to Project Material
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
