@import "../../../css/_brand_colors.scss";

.configurable-table {

  flex-grow: 1;
  background-color: $brand-white;

  :global(.k-grid) {
    /* Apply card style to the Grid, Taken from Figma */
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid $brand-slight-grey;
    border-radius: 8px;
  }

  /* Styling the grid borders start */
  :global(.k-grid td) {
    border: none !important;
    border-top: 1px solid $brand-slight-grey !important;

    &:global(.k-grid-content-sticky) {
      border-right: solid thin $brand-slight-grey !important;
    }

    &:last-child {
      border-right: none !important;
    }
  }

  :global(.k-grid .k-grid-header th) {
    height: 40px !important;
    border-left: none !important;
    border-right: solid $brand-slight-grey !important;
    background-color: $brand-lightest-grey !important;
    vertical-align: middle;

    &:first-child {
      display: inline-flex;
      align-items: center;
      width: 100%;
      justify-content: end;
      border-right: unset !important;

      :global(.k-checkbox) {
        border-radius: 3px;
        border: 1.3px solid #859399;
        height: 16px;
        width: 16px;

        &:checked {
          background-color: $brand-primary-color;
          border: 1.3px solid $brand-primary-color;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    &:last-child {
      border-right: none !important;
    }
  }

  /* Styling the grid borders end */


  :global(.k-grid-header-sticky) {
    background: unset !important;
  }

  /* Rules for showing sort icon in header cells */

  :global(.k-i-sort-asc-small),
  :global(.k-i-sort-desc-small),
  :global(.sort-icon) {
    margin-right: 5px;
    color: $brand-medium-grey !important;
    display: none;
  }

  :global(.sort-icon) {
    float: right;
    margin-top: unset;
  }

  /* Always show the sort icon if the column is sorted */
  :global(th.k-header.k-sorted .k-link > .sort-icon) {
    display: inline-block;
  }

  /* When the header cell is hovered, show the icon */
  :global(th.k-header:hover .k-link > .sort-icon) {
    display: inline-block;
  }

  /* When the filter menu dropdown is hovered, hide the icon again */
  :global(th.k-header:not(.k-sorted):hover span:hover ~ .k-link > .sort-icon) {
    display: none;
  }

  /* Override grid column menu style */
  :global(.k-grid-header-sticky) {
    background: inherit !important;
  }

  /* Hide scrollbar start */
  /*  :global(.k-grid-header) {
      background: inherit !important;
      border-bottom-width: 0 !important;
      padding-right: unset !important;
    }

    :global(.k-grid-container ::-webkit-scrollbar) {
      width: 0 !important;
      height: 1px !important;
      background-color: transparent !important;
    }

    :global(.k-grid-container ::-webkit-scrollbar-track) {
      background-color: transparent !important;
    }

    :global(.k-grid-container ::-webkit-scrollbar-thumb) {
      background-color: transparent !important;
    }*/

  /* Hide scrollbar end */


  :global(.k-master-row:hover),
  :global(.k-master-row:hover td) {
    background-color: $brand-light-grey !important;
  }

  :global(.k-detail-row .k-master-row:hover),
  :global(.k-detail-row .k-master-row:hover td) {
    background-color: $brand-white !important;
  }

  .select-column {
    :global(.k-checkbox) {
      position: unset;
      vertical-align: middle;
      transform: translateX(15px);
      display: none;
    }
  }

  :global(.k-master-row:hover) {
    .select-column {
      :global(.k-checkbox) {
        display: inline-block;
        border-radius: 3px;
        border: 1.3px solid #859399;
        height: 16px;
        width: 16px;
      }
    }
  }

  :global(.k-master-row.k-selected),
  :global(.k-master-row.k-selected) td {
    background-color: #E8F8FF !important;
  }

  :global(.k-master-row.k-selected) {
    .select-column {
      :global(.k-checkbox) {
        display: inline-block !important;
        height: 16px;
        width: 16px;
        background-color: $brand-primary-color;
        border-radius: 3px;
        border: 1.3px solid $brand-primary-color;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  :global(.k-pager) {
    :global(.k-link) {
      font-size: 14px;
      font-style: normal;
      font-weight: 600 !important;
      color: #859099 !important;
    }

    :global(.k-link:hover) {
      color: #859099 !important;
    }

    :global(.k-pager-numbers .k-link.k-selected) {
      border-color: #C0ECFF;
      color: #1F46A1 !important;
      background-color: #C0ECFF;
    }
  }
}


.configurable-table-inner-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;

  .error-bar {
    width: 98%;
    margin-left: 0;
    margin-right: 0;

    :global(a) {
      float: right;
    }
  }

  &:has(.error-bar:global(.d-block)) {
    margin-top: unset !important;
  }
}
