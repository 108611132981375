import * as React from 'react';
/**
 * @hidden
 */
var onDownPreventDefault = {
    onMouseDown: function (e) { return e.preventDefault(); },
    onPointerDown: function (e) { return e.preventDefault(); }
};
/**
 * @hidden
 */
var userSelectNone = {
    userSelect: 'none'
};
/**
 * @hidden
 */
var formatString = function (input) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    args.forEach(function (value, index) {
        input = input.replace('{' + index + '}', String(value));
    });
    return input;
};
/**
 * @hidden
 */
var itemRender = function (li, itemProps) {
    return itemProps.dataItem.style ? React.cloneElement(li, li.props, (React.createElement("span", { style: itemProps.dataItem.style }, li.props.children))) : li;
};
export { onDownPreventDefault, formatString, itemRender, userSelectNone };
