"use strict";

import React from "react";
import { DEFAULT_ACTIONS } from "../project_material_actions";

/**
 * Helper utility functions for project material wizard flow.
 */
class ProjectMaterialWizardHelper {

  constructor(context) {
    this._context = context;
  }

  onSearchFilterChange({config, wizardStepsConfig, initialState}) {
    const stepIndex = wizardStepsConfig.indexOf(config);
    const {wizardDispatcher} = this._context;
    wizardDispatcher({
      type: DEFAULT_ACTIONS.UpdateStepState,
      wizardStepsConfig: wizardStepsConfig.map((cfg, index) => {
        if (index === stepIndex) {
          return {...cfg, initialState};
        }
        return cfg;
      }),
    });
  }

  isNextStepDisabled({config, totalSteps, wizardStepsConfig}) {
    const nextStepIndex = wizardStepsConfig.indexOf(config) + 1;
    const nextStepConfig = wizardStepsConfig[nextStepIndex];
    return totalSteps >= nextStepIndex ? nextStepConfig.disabled : false;
  }

  onStepDataUpdated(params) {

    const {config, totalSteps, isNextDisabled, state, selectedState, wizardStepsConfig} = params;
    const {wizardDispatcher} = this._context;
    const stepIndex = wizardStepsConfig.indexOf(config);
    const nextStepIndex = stepIndex + 1;

    if (totalSteps >= stepIndex) {

      wizardDispatcher({
        type: DEFAULT_ACTIONS.StepDataUpdated,
        wizardStepsConfig: wizardStepsConfig.map((cfg, index) => {
          if (index === nextStepIndex) {
            return {...cfg, disabled: isNextDisabled, initialState: state};
          }
          return cfg;
        }),
        selectionState: selectedState,
      });

    }
  }

  onNextButtonClick({config, wizardStepsConfig, totalSteps}) {

    const {wizardDispatcher, wizardRef} = this._context;
    const stepIndex = wizardStepsConfig.indexOf(config);
    const nextStepIndex = stepIndex + 1;
    const nextStepConfig = wizardStepsConfig[nextStepIndex];

    if (totalSteps > stepIndex) {
      wizardDispatcher({
        type: DEFAULT_ACTIONS.NextButtonClicked,
        wizardStepsConfig: wizardStepsConfig.map((cfg, index) => {
          switch (index) {
            case stepIndex:
              return {...cfg, visible: false};
            case nextStepIndex:
              return {...cfg, visible: true};
            default:
              return cfg;
          }
        }),
        currentFlowStep: nextStepConfig.step
      });
      wizardRef.current.goToStep(nextStepConfig.wizardStepId);
    }
  }

  onNavTabClick(config, wizardStepsConfig) {

    const {wizardDispatcher, wizardRef} = this._context;
    const stepIndex = wizardStepsConfig.indexOf(config);

    wizardDispatcher({
      type: DEFAULT_ACTIONS.NavTabClicked,
      wizardStepsConfig: wizardStepsConfig.map((cfg, index) => {
        switch (index) {
          case stepIndex:
            return {...cfg, visible: true};
          default:
            return {...cfg, visible: false};
        }
      }),
      currentFlowStep: config.step
    });

    wizardRef.current.goToStep(config.wizardStepId);
  }

  goToPreviousStep({config, wizardStepsConfig}) {

    const {wizardDispatcher, wizardRef} = this._context;
    const stepIndex = wizardStepsConfig.indexOf(config);
    const prevStepIndex = stepIndex - 1;
    const prevStepConfig = wizardStepsConfig[prevStepIndex];

    wizardDispatcher({
      type: DEFAULT_ACTIONS.PreviousStep,
      wizardStepsConfig: wizardStepsConfig.map((cfg, index) => {
        switch (index) {
          case stepIndex:
            return {...cfg, visible: false};
          case prevStepIndex:
            return {...cfg, visible: true};
          default:
            return cfg;
        }
      }),
      currentFlowStep: prevStepConfig.step
    });

    wizardRef.current.goToStep(prevStepConfig.wizardStepId);
  }
}


module.exports = {
  ProjectMaterialWizardHelper
};