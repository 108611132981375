/*
 * GoJS v2.0.12 JavaScript Library for HTML Diagrams
 * Northwoods Software, https://www.nwoods.com/
 * GoJS and Northwoods Software are registered trademarks of Northwoods Software Corporation.
 * Copyright (C) 1998-2019 by Northwoods Software Corporation.  All Rights Reserved.
 * THIS SOFTWARE IS LICENSED.  THE LICENSE AGREEMENT IS AT: https://gojs.net/2.0.12/license.html.
 */
(function() { var t;function aa(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}function ba(a){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];return b?b.call(a):{next:aa(a)}}function ca(a){for(var b,c=[];!(b=a.next()).done;)c.push(b.value);return c}var ea="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},ia;
if("function"==typeof Object.setPrototypeOf)ia=Object.setPrototypeOf;else{var ka;a:{var la={a:!0},ma={};try{ma.__proto__=la;ka=ma.a;break a}catch(a){}ka=!1}ia=ka?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var na=ia;
function oa(a,b){a.prototype=ea(b.prototype);a.prototype.constructor=a;if(na)na(a,b);else for(var c in b)if("prototype"!=c)if(Object.defineProperties){var d=Object.getOwnPropertyDescriptor(b,c);d&&Object.defineProperty(a,c,d)}else a[c]=b[c];a.hA=b.prototype}var pa="undefined"!=typeof window&&window===self?self:"undefined"!=typeof global&&null!=global?global:self,qa="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,c){a!=Array.prototype&&a!=Object.prototype&&(a[b]=c.value)};
function ra(a){if(a){for(var b=pa,c=["Array","prototype","fill"],d=0;d<c.length-1;d++){var e=c[d];e in b||(b[e]={});b=b[e]}c=c[c.length-1];d=b[c];a=a(d);a!=d&&null!=a&&qa(b,c,{writable:!0,value:a})}}ra(function(a){return a?a:function(a,c,d){var b=this.length||0;0>c&&(c=Math.max(0,b+c));if(null==d||d>b)d=b;d=Number(d);0>d&&(d=Math.max(0,b+d));for(c=Number(c||0);c<d;c++)this[c]=a;return this}});var w="object"===typeof self&&self.self===self&&self||"object"===typeof global&&global.global===global&&global||"object"===typeof window&&window.window===window&&window||{};void 0===w.requestAnimationFrame&&(w.requestAnimationFrame=w.setImmediate);function sa(){}function ta(a,b){var c=-1;return function(){var d=this,e=arguments;-1!==c&&w.clearTimeout(c);c=ua(function(){c=-1;a.apply(d,e)},b)}}function ua(a,b){return w.setTimeout(a,b)}function va(a){return w.document.createElement(a)}
function A(a){throw Error(a);}function wa(a,b){a="The object is frozen, so its properties cannot be set: "+a.toString();void 0!==b&&(a+="  to value: "+b);A(a)}function ya(a,b,c,d){c=null===c?"*":"string"===typeof c?c:"function"===typeof c&&"string"===typeof c.className?c.className:"";void 0!==d&&(c+="."+d);A(c+" is not in the range "+b+": "+a)}function za(a){w.console&&w.console.log(a)}
function Aa(){w.console&&w.console.log("Warning: List/Map/Set constructors no longer take an argument that enforces type.Instead they take an optional collection of Values to add to the collection. See 2.0 changelog for details.")}function Ca(a){return"object"===typeof a&&null!==a}function Ea(a){return Array.isArray(a)||w.NodeList&&a instanceof w.NodeList||w.HTMLCollection&&a instanceof w.HTMLCollection}function Ga(a){return Array.prototype.slice.call(a)}
function Ia(a,b,c){Array.isArray(a)?b>=a.length?a.push(c):a.splice(b,0,c):A("Cannot insert an object into an HTMLCollection or NodeList: "+c+" at "+b)}function Ja(a,b){Array.isArray(a)?b>=a.length?a.pop():a.splice(b,1):A("Cannot remove an object from an HTMLCollection or NodeList at "+b)}function Ka(){var a=La.pop();return void 0===a?[]:a}function Oa(a){a.length=0;La.push(a)}
function Pa(a){if("function"===typeof a){if(a.className)return a.className;if(a.name)return a.name;var b=a.toString();b=b.substring(9,b.indexOf("(")).trim();if(""!==b)return a._className=b}else if(Ca(a)&&a.constructor)return Pa(a.constructor);return typeof a}
function Qa(a){var b=a;Ca(a)&&(a.text?b=a.text:a.name?b=a.name:void 0!==a.key?b=a.key:void 0!==a.id?b=a.id:a.constructor===Object&&(a.Text?b=a.Text:a.Name?b=a.Name:void 0!==a.Key?b=a.Key:void 0!==a.Id?b=a.Id:void 0!==a.ID&&(b=a.ID)));return void 0===b?"undefined":null===b?"null":b.toString()}function Sa(a,b){if(a.hasOwnProperty(b))return!0;for(a=Object.getPrototypeOf(a);a&&a!==Function;){if(a.hasOwnProperty(b))return!0;var c=a.Zz;if(c&&c[b])return!0;a=Object.getPrototypeOf(a)}return!1}
function Ta(a,b,c){Object.defineProperty(Ua.prototype,a,{get:b,set:c})}function Va(){var a=Wa;if(0===a.length)for(var b=w.document.getElementsByTagName("canvas"),c=b.length,d=0;d<c;d++){var e=b[d];e.parentElement&&e.parentElement.C&&a.push(e.parentElement.C)}return a}
function Za(a){for(var b=[],c=0;256>c;c++)b["0123456789abcdef".charAt(c>>4)+"0123456789abcdef".charAt(c&15)]=String.fromCharCode(c);a.length%2&&(a="0"+a);c=[];for(var d=0,e=0;e<a.length;e+=2)c[d++]=b[a.substr(e,2)];a=c.join("");a=""===a?"0":a;b=[];for(c=0;256>c;c++)b[c]=c;for(c=d=0;256>c;c++)d=(d+b[c]+119)%256,e=b[c],b[c]=b[d],b[d]=e;d=c=0;for(var f="",g=0;g<a.length;g++)c=(c+1)%256,d=(d+b[c])%256,e=b[c],b[c]=b[d],b[d]=e,f+=String.fromCharCode(a.charCodeAt(g)^b[(b[c]+b[d])%256]);return f}
var bb=void 0!==w.navigator&&0<w.navigator.userAgent.indexOf("MSIE 9.0"),cb=void 0!==w.navigator&&0<w.navigator.userAgent.indexOf("MSIE 10.0"),db=void 0!==w.navigator&&0<w.navigator.userAgent.indexOf("Trident/7"),gb=void 0!==w.navigator&&0<w.navigator.userAgent.indexOf("Edge/"),ib=void 0!==w.navigator&&void 0!==w.navigator.platform&&0<=w.navigator.platform.toUpperCase().indexOf("MAC"),mb=void 0!==w.navigator&&void 0!==w.navigator.platform&&null!==w.navigator.platform.match(/(iPhone|iPod|iPad)/i),
La=[];Object.freeze([]);var Wa=[];sa.className="Util";sa.Dx="32ab5ff3b26f42dc0ed90f224d2913b5";sa.adym="gojs.net";sa.vfo="28e646fdb264";sa.className="Util";function D(a,b,c){nb(this);this.l=a;this.Ua=b;this.u=c}D.prototype.toString=function(){return"EnumValue."+this.Ua};function qb(a,b){return void 0===b||null===b||""===b?null:a[b]}
pa.Object.defineProperties(D.prototype,{classType:{get:function(){return this.l}},name:{get:function(){return this.Ua}},value:{get:function(){return this.u}}});D.className="EnumValue";function sb(){this.kw=[]}sb.prototype.toString=function(){return this.kw.join("")};sb.prototype.add=function(a){""!==a&&this.kw.push(a)};sb.className="StringBuilder";function tb(){}tb.className="PropertyCollection";
function ub(){}ub.prototype.reset=function(){};ub.prototype.next=function(){return!1};ub.prototype.dd=function(){return!1};ub.prototype.first=function(){return null};ub.prototype.any=function(){return!1};ub.prototype.all=function(){return!0};ub.prototype.each=function(){return this};ub.prototype.map=function(){return this};ub.prototype.filter=function(){return this};ub.prototype.xd=function(){};ub.prototype.toString=function(){return"EmptyIterator"};
pa.Object.defineProperties(ub.prototype,{iterator:{get:function(){return this}},count:{get:function(){return 0}}});ub.prototype.first=ub.prototype.first;ub.prototype.hasNext=ub.prototype.dd;ub.prototype.next=ub.prototype.next;ub.prototype.reset=ub.prototype.reset;var xb=null;ub.className="EmptyIterator";xb=new ub;function yb(a){this.key=-1;this.value=a}yb.prototype.reset=function(){this.key=-1};
yb.prototype.next=function(){return-1===this.key?(this.key=0,!0):!1};yb.prototype.dd=function(){return this.next()};yb.prototype.first=function(){this.key=0;return this.value};yb.prototype.any=function(a){this.key=-1;return a(this.value)};yb.prototype.all=function(a){this.key=-1;return a(this.value)};yb.prototype.each=function(a){this.key=-1;a(this.value);return this};yb.prototype.map=function(a){return new yb(a(this.value))};
yb.prototype.filter=function(a){return a(this.value)?new yb(this.value):xb};yb.prototype.xd=function(){this.value=null};yb.prototype.toString=function(){return"SingletonIterator("+this.value+")"};pa.Object.defineProperties(yb.prototype,{iterator:{get:function(){return this}},count:{get:function(){return 1}}});yb.prototype.first=yb.prototype.first;yb.prototype.hasNext=yb.prototype.dd;yb.prototype.next=yb.prototype.next;
yb.prototype.reset=yb.prototype.reset;yb.className="SingletonIterator";function zb(a){this.pb=a;this.Xe=null;a.Ja=null;this.la=a.Ba;this.Sa=-1}zb.prototype.reset=function(){var a=this.pb;a.Ja=null;this.la=a.Ba;this.Sa=-1};zb.prototype.next=function(){var a=this.pb;if(a.Ba!==this.la&&0>this.key)return!1;a=a.j;var b=a.length,c=++this.Sa,d=this.Xe;if(null!==d)for(;c<b;){var e=a[c];if(d(e))return this.key=this.Sa=c,this.value=e,!0;c++}else{if(c<b)return this.key=c,this.value=a[c],!0;this.xd()}return!1};
zb.prototype.dd=function(){return this.next()};zb.prototype.first=function(){var a=this.pb;this.la=a.Ba;this.Sa=0;a=a.j;var b=a.length,c=this.Xe;if(null!==c){for(var d=0;d<b;){var e=a[d];if(c(e))return this.key=this.Sa=d,this.value=e;d++}return null}return 0<b?(a=a[0],this.key=0,this.value=a):null};zb.prototype.any=function(a){var b=this.pb;b.Ja=null;this.Sa=-1;b=b.j;for(var c=b.length,d=this.Xe,e=0;e<c;e++){var f=b[e];if((null===d||d(f))&&a(f))return!0}return!1};
zb.prototype.all=function(a){var b=this.pb;b.Ja=null;this.Sa=-1;b=b.j;for(var c=b.length,d=this.Xe,e=0;e<c;e++){var f=b[e];if((null===d||d(f))&&!a(f))return!1}return!0};zb.prototype.each=function(a){var b=this.pb;b.Ja=null;this.Sa=-1;b=b.j;for(var c=b.length,d=this.Xe,e=0;e<c;e++){var f=b[e];(null===d||d(f))&&a(f)}return this};
zb.prototype.map=function(a){var b=this.pb;b.Ja=null;this.Sa=-1;var c=[];b=b.j;for(var d=b.length,e=this.Xe,f=0;f<d;f++){var g=b[f];(null===e||e(g))&&c.push(a(g))}a=new E;a.j=c;a.kb();return a.iterator};zb.prototype.filter=function(a){var b=this.pb;b.Ja=null;this.Sa=-1;var c=[];b=b.j;for(var d=b.length,e=this.Xe,f=0;f<d;f++){var g=b[f];(null===e||e(g))&&a(g)&&c.push(g)}a=new E;a.j=c;a.kb();return a.iterator};
zb.prototype.xd=function(){this.key=-1;this.value=null;this.la=-1;this.Xe=null;this.pb.Ja=this};zb.prototype.toString=function(){return"ListIterator@"+this.Sa+"/"+this.pb.count};
pa.Object.defineProperties(zb.prototype,{iterator:{get:function(){return this}},predicate:{get:function(){return this.Xe},set:function(a){this.Xe=a}},count:{get:function(){var a=this.Xe;if(null!==a){for(var b=0,c=this.pb.j,d=c.length,e=0;e<d;e++)a(c[e])&&b++;return b}return this.pb.j.length}}});zb.prototype.first=zb.prototype.first;zb.prototype.hasNext=zb.prototype.dd;zb.prototype.next=zb.prototype.next;
zb.prototype.reset=zb.prototype.reset;zb.className="ListIterator";function Ab(a){this.pb=a;a.Sg=null;this.la=a.Ba;this.Sa=a.j.length}Ab.prototype.reset=function(){var a=this.pb;a.Sg=null;this.la=a.Ba;this.Sa=a.j.length};Ab.prototype.next=function(){var a=this.pb;if(a.Ba!==this.la&&0>this.key)return!1;var b=--this.Sa;if(0<=b)return this.key=b,this.value=a.j[b],!0;this.xd();return!1};Ab.prototype.dd=function(){return this.next()};
Ab.prototype.first=function(){var a=this.pb;this.la=a.Ba;var b=a.j;this.Sa=a=b.length-1;return 0<=a?(b=b[a],this.key=a,this.value=b):null};Ab.prototype.any=function(a){var b=this.pb;b.Sg=null;b=b.j;var c=b.length;this.Sa=c;for(--c;0<=c;c--)if(a(b[c]))return!0;return!1};Ab.prototype.all=function(a){var b=this.pb;b.Sg=null;b=b.j;var c=b.length;this.Sa=c;for(--c;0<=c;c--)if(!a(b[c]))return!1;return!0};
Ab.prototype.each=function(a){var b=this.pb;b.Sg=null;b=b.j;var c=b.length;this.Sa=c;for(--c;0<=c;c--)a(b[c]);return this};Ab.prototype.map=function(a){var b=this.pb;b.Sg=null;var c=[];b=b.j;var d=b.length;this.Sa=d;for(--d;0<=d;d--)c.push(a(b[d]));a=new E;a.j=c;a.kb();return a.iterator};Ab.prototype.filter=function(a){var b=this.pb;b.Sg=null;var c=[];b=b.j;var d=b.length;this.Sa=d;for(--d;0<=d;d--){var e=b[d];a(e)&&c.push(e)}a=new E;a.j=c;a.kb();return a.iterator};
Ab.prototype.xd=function(){this.key=-1;this.value=null;this.la=-1;this.pb.Sg=this};Ab.prototype.toString=function(){return"ListIteratorBackwards("+this.Sa+"/"+this.pb.count+")"};pa.Object.defineProperties(Ab.prototype,{iterator:{get:function(){return this}},count:{get:function(){return this.pb.j.length}}});Ab.prototype.first=Ab.prototype.first;Ab.prototype.hasNext=Ab.prototype.dd;Ab.prototype.next=Ab.prototype.next;Ab.prototype.reset=Ab.prototype.reset;
Ab.className="ListIteratorBackwards";function E(a){nb(this);this.v=!1;this.j=[];this.Ba=0;this.Sg=this.Ja=null;void 0!==a&&("function"===typeof a||"string"===typeof a?Aa():this.addAll(a))}t=E.prototype;t.kb=function(){var a=this.Ba;a++;999999999<a&&(a=0);this.Ba=a};t.freeze=function(){this.v=!0;return this};t.ha=function(){this.v=!1;return this};t.toString=function(){return"List()#"+Bb(this)};t.add=function(a){if(null===a)return this;this.v&&wa(this,a);this.j.push(a);this.kb();return this};
t.push=function(a){this.add(a)};t.addAll=function(a){if(null===a)return this;this.v&&wa(this);var b=this.j;if(Ea(a))for(var c=a.length,d=0;d<c;d++)b.push(a[d]);else for(a=a.iterator;a.next();)b.push(a.value);this.kb();return this};t.clear=function(){this.v&&wa(this);this.j.length=0;this.kb()};t.contains=function(a){return null===a?!1:-1!==this.j.indexOf(a)};t.has=function(a){return this.contains(a)};t.indexOf=function(a){return null===a?-1:this.j.indexOf(a)};
t.N=function(a){var b=this.j;(0>a||a>=b.length)&&ya(a,"0 <= i < length",E,"elt:i");return b[a]};t.get=function(a){return this.N(a)};t.gd=function(a,b){var c=this.j;(0>a||a>=c.length)&&ya(a,"0 <= i < length",E,"setElt:i");this.v&&wa(this,a);c[a]=b};t.set=function(a,b){this.gd(a,b)};t.first=function(){var a=this.j;return 0===a.length?null:a[0]};t.Sb=function(){var a=this.j,b=a.length;return 0<b?a[b-1]:null};t.pop=function(){this.v&&wa(this);var a=this.j;return 0<a.length?a.pop():null};
E.prototype.any=function(a){for(var b=this.j,c=b.length,d=0;d<c;d++)if(a(b[d]))return!0;return!1};E.prototype.all=function(a){for(var b=this.j,c=b.length,d=0;d<c;d++)if(!a(b[d]))return!1;return!0};E.prototype.each=function(a){for(var b=this.j,c=b.length,d=0;d<c;d++)a(b[d]);return this};E.prototype.map=function(a){for(var b=new E,c=[],d=this.j,e=d.length,f=0;f<e;f++)c.push(a(d[f]));b.j=c;b.kb();return b};
E.prototype.filter=function(a){for(var b=new E,c=[],d=this.j,e=d.length,f=0;f<e;f++){var g=d[f];a(g)&&c.push(g)}b.j=c;b.kb();return b};t=E.prototype;t.Ib=function(a,b){0>a&&ya(a,">= 0",E,"insertAt:i");this.v&&wa(this,a);var c=this.j;a>=c.length?c.push(b):c.splice(a,0,b);this.kb()};t.remove=function(a){if(null===a)return!1;this.v&&wa(this,a);var b=this.j;a=b.indexOf(a);if(-1===a)return!1;a===b.length-1?b.pop():b.splice(a,1);this.kb();return!0};t.delete=function(a){return this.remove(a)};
t.mb=function(a){var b=this.j;(0>a||a>=b.length)&&ya(a,"0 <= i < length",E,"removeAt:i");this.v&&wa(this,a);a===b.length-1?b.pop():b.splice(a,1);this.kb()};t.removeRange=function(a,b){var c=this.j,d=c.length;if(0>a)a=0;else if(a>=d)return this;if(0>b)return this;b>=d&&(b=d-1);if(a>b)return this;this.v&&wa(this);for(var e=a,f=b+1;f<d;)c[e++]=c[f++];c.length=d-(b-a+1);this.kb();return this};E.prototype.copy=function(){var a=new E,b=this.j;0<b.length&&(a.j=Array.prototype.slice.call(b));return a};
t=E.prototype;t.Na=function(){for(var a=this.j,b=this.count,c=Array(b),d=0;d<b;d++)c[d]=a[d];return c};t.Qv=function(){for(var a=new F,b=this.j,c=this.count,d=0;d<c;d++)a.add(b[d]);return a};t.sort=function(a){this.v&&wa(this);this.j.sort(a);this.kb();return this};
t.Xi=function(a,b,c){var d=this.j,e=d.length;void 0===b&&(b=0);void 0===c&&(c=e);this.v&&wa(this);var f=c-b;if(1>=f)return this;(0>b||b>=e-1)&&ya(b,"0 <= from < length",E,"sortRange:from");if(2===f)return c=d[b],e=d[b+1],0<a(c,e)&&(d[b]=e,d[b+1]=c,this.kb()),this;if(0===b)if(c>=e)d.sort(a);else for(b=d.slice(0,c),b.sort(a),a=0;a<c;a++)d[a]=b[a];else if(c>=e)for(c=d.slice(b),c.sort(a),a=b;a<e;a++)d[a]=c[a-b];else for(e=d.slice(b,c),e.sort(a),a=b;a<c;a++)d[a]=e[a-b];this.kb();return this};
t.reverse=function(){this.v&&wa(this);this.j.reverse();this.kb();return this};
pa.Object.defineProperties(E.prototype,{_dataArray:{get:function(){return this.j}},count:{get:function(){return this.j.length}},size:{get:function(){return this.j.length}},length:{get:function(){return this.j.length}},iterator:{get:function(){if(0>=this.j.length)return xb;var a=this.Ja;return null!==a?(a.reset(),a):new zb(this)}},iteratorBackwards:{
get:function(){if(0>=this.j.length)return xb;var a=this.Sg;return null!==a?(a.reset(),a):new Ab(this)}}});E.prototype.reverse=E.prototype.reverse;E.prototype.sortRange=E.prototype.Xi;E.prototype.sort=E.prototype.sort;E.prototype.toSet=E.prototype.Qv;E.prototype.toArray=E.prototype.Na;E.prototype.removeRange=E.prototype.removeRange;E.prototype.removeAt=E.prototype.mb;E.prototype["delete"]=E.prototype.delete;E.prototype.remove=E.prototype.remove;E.prototype.insertAt=E.prototype.Ib;
E.prototype.pop=E.prototype.pop;E.prototype.last=E.prototype.Sb;E.prototype.first=E.prototype.first;E.prototype.set=E.prototype.set;E.prototype.setElt=E.prototype.gd;E.prototype.get=E.prototype.get;E.prototype.elt=E.prototype.N;E.prototype.indexOf=E.prototype.indexOf;E.prototype.has=E.prototype.has;E.prototype.contains=E.prototype.contains;E.prototype.clear=E.prototype.clear;E.prototype.addAll=E.prototype.addAll;E.prototype.push=E.prototype.push;E.prototype.add=E.prototype.add;E.prototype.thaw=E.prototype.ha;
E.prototype.freeze=E.prototype.freeze;E.className="List";function Cb(a){this.fg=a;a.Ja=null;this.la=a.Ba;this.na=null}Cb.prototype.reset=function(){var a=this.fg;a.Ja=null;this.la=a.Ba;this.na=null};Cb.prototype.next=function(){var a=this.fg;if(a.Ba!==this.la&&null===this.key)return!1;var b=this.na;b=null===b?a.fa:b.oa;if(null!==b)return this.na=b,this.value=b.value,this.key=b.key,!0;this.xd();return!1};Cb.prototype.dd=function(){return this.next()};
Cb.prototype.first=function(){var a=this.fg;this.la=a.Ba;a=a.fa;if(null!==a){this.na=a;var b=a.value;this.key=a.key;return this.value=b}return null};Cb.prototype.any=function(a){var b=this.fg;this.na=b.Ja=null;for(b=b.fa;null!==b;){if(a(b.value))return!0;b=b.oa}return!1};Cb.prototype.all=function(a){var b=this.fg;this.na=b.Ja=null;for(b=b.fa;null!==b;){if(!a(b.value))return!1;b=b.oa}return!0};Cb.prototype.each=function(a){var b=this.fg;this.na=b.Ja=null;for(b=b.fa;null!==b;)a(b.value),b=b.oa;return this};
Cb.prototype.map=function(a){var b=this.fg;b.Ja=null;var c=new E;for(b=b.fa;null!==b;)c.add(a(b.value)),b=b.oa;return c.iterator};Cb.prototype.filter=function(a){var b=this.fg;b.Ja=null;var c=new E;for(b=b.fa;null!==b;){var d=b.value;a(d)&&c.add(d);b=b.oa}return c.iterator};Cb.prototype.xd=function(){this.value=this.key=null;this.la=-1;this.fg.Ja=this};Cb.prototype.toString=function(){return null!==this.na?"SetIterator@"+this.na.value:"SetIterator"};
pa.Object.defineProperties(Cb.prototype,{iterator:{get:function(){return this}},count:{get:function(){return this.fg.Cb}}});Cb.prototype.first=Cb.prototype.first;Cb.prototype.hasNext=Cb.prototype.dd;Cb.prototype.next=Cb.prototype.next;Cb.prototype.reset=Cb.prototype.reset;Cb.className="SetIterator";
function F(a){nb(this);this.v=!1;this.Db={};this.Cb=0;this.Ja=null;this.Ba=0;this.Te=this.fa=null;void 0!==a&&("function"===typeof a||"string"===typeof a?Aa():this.addAll(a))}t=F.prototype;t.kb=function(){var a=this.Ba;a++;999999999<a&&(a=0);this.Ba=a};t.freeze=function(){this.v=!0;return this};t.ha=function(){this.v=!1;return this};t.toString=function(){return"Set()#"+Bb(this)};
t.add=function(a){if(null===a)return this;this.v&&wa(this,a);var b=a;Ca(a)&&(b=Db(a));void 0===this.Db[b]&&(this.Cb++,a=new Fb(a,a),this.Db[b]=a,b=this.Te,null===b?this.fa=a:(a.wl=b,b.oa=a),this.Te=a,this.kb());return this};t.addAll=function(a){if(null===a)return this;this.v&&wa(this);if(Ea(a))for(var b=a.length,c=0;c<b;c++)this.add(a[c]);else for(a=a.iterator;a.next();)this.add(a.value);return this};
t.contains=function(a){if(null===a)return!1;var b=a;return Ca(a)&&(b=Bb(a),void 0===b)?!1:void 0!==this.Db[b]};t.has=function(a){return this.contains(a)};t.qy=function(a){if(null===a)return!0;for(a=a.iterator;a.next();)if(!this.contains(a.value))return!1;return!0};t.sy=function(a){if(null===a)return!0;for(a=a.iterator;a.next();)if(this.contains(a.value))return!0;return!1};t.first=function(){var a=this.fa;return null===a?null:a.value};
F.prototype.any=function(a){for(var b=this.fa;null!==b;){if(a(b.value))return!0;b=b.oa}return!1};F.prototype.all=function(a){for(var b=this.fa;null!==b;){if(!a(b.value))return!1;b=b.oa}return!0};F.prototype.each=function(a){for(var b=this.fa;null!==b;)a(b.value),b=b.oa;return this};F.prototype.map=function(a){for(var b=new F,c=this.fa;null!==c;)b.add(a(c.value)),c=c.oa;return b};F.prototype.filter=function(a){for(var b=new F,c=this.fa;null!==c;){var d=c.value;a(d)&&b.add(d);c=c.oa}return b};t=F.prototype;
t.remove=function(a){if(null===a)return!1;this.v&&wa(this,a);var b=a;if(Ca(a)&&(b=Bb(a),void 0===b))return!1;a=this.Db[b];if(void 0===a)return!1;var c=a.oa,d=a.wl;null!==c&&(c.wl=d);null!==d&&(d.oa=c);this.fa===a&&(this.fa=c);this.Te===a&&(this.Te=d);delete this.Db[b];this.Cb--;this.kb();return!0};t.delete=function(a){return this.remove(a)};
t.oq=function(a){if(null===a)return this;this.v&&wa(this);if(Ea(a))for(var b=a.length,c=0;c<b;c++)this.remove(a[c]);else for(a=a.iterator;a.next();)this.remove(a.value);return this};t.Mz=function(a){if(null===a||0===this.count)return this;this.v&&wa(this);var b=new F;b.addAll(a);a=[];for(var c=this.iterator;c.next();){var d=c.value;b.contains(d)||a.push(d)}this.oq(a);return this};t.clear=function(){this.v&&wa(this);this.Db={};this.Cb=0;null!==this.Ja&&this.Ja.reset();this.Te=this.fa=null;this.kb()};
F.prototype.copy=function(){var a=new F,b=this.Db,c;for(c in b)a.add(b[c].value);return a};F.prototype.Na=function(){var a=Array(this.Cb),b=this.Db,c=0,d;for(d in b)a[c]=b[d].value,c++;return a};F.prototype.Pv=function(){var a=new E,b=this.Db,c;for(c in b)a.add(b[c].value);return a};function nb(a){a.__gohashid=Gb++}function Db(a){var b=a.__gohashid;void 0===b&&(b=Gb++,a.__gohashid=b);return b}function Bb(a){return a.__gohashid}
pa.Object.defineProperties(F.prototype,{count:{get:function(){return this.Cb}},size:{get:function(){return this.Cb}},iterator:{get:function(){if(0>=this.Cb)return xb;var a=this.Ja;return null!==a?(a.reset(),a):new Cb(this)}}});F.prototype.toList=F.prototype.Pv;F.prototype.toArray=F.prototype.Na;F.prototype.clear=F.prototype.clear;F.prototype.retainAll=F.prototype.Mz;F.prototype.removeAll=F.prototype.oq;
F.prototype["delete"]=F.prototype.delete;F.prototype.remove=F.prototype.remove;F.prototype.first=F.prototype.first;F.prototype.containsAny=F.prototype.sy;F.prototype.containsAll=F.prototype.qy;F.prototype.has=F.prototype.has;F.prototype.contains=F.prototype.contains;F.prototype.addAll=F.prototype.addAll;F.prototype.add=F.prototype.add;F.prototype.thaw=F.prototype.ha;F.prototype.freeze=F.prototype.freeze;var Gb=1;F.className="Set";F.uniqueHash=nb;F.hashIdUnique=Db;F.hashId=Bb;
function Hb(a){this.ja=a;this.la=a.Ba;this.na=null}Hb.prototype.reset=function(){this.la=this.ja.Ba;this.na=null};Hb.prototype.next=function(){var a=this.ja;if(a.Ba!==this.la&&null===this.key)return!1;var b=this.na;b=null===b?a.fa:b.oa;if(null!==b)return this.na=b,this.value=this.key=a=b.key,!0;this.xd();return!1};Hb.prototype.dd=function(){return this.next()};Hb.prototype.first=function(){var a=this.ja;this.la=a.Ba;a=a.fa;return null!==a?(this.na=a,this.value=this.key=a=a.key):null};
Hb.prototype.any=function(a){var b=this.ja;this.na=null;for(b=b.fa;null!==b;){if(a(b.key))return!0;b=b.oa}return!1};Hb.prototype.all=function(a){var b=this.ja;this.na=null;for(b=b.fa;null!==b;){if(!a(b.key))return!1;b=b.oa}return!0};Hb.prototype.each=function(a){var b=this.ja;this.na=null;for(b=b.fa;null!==b;)a(b.key),b=b.oa;return this};Hb.prototype.map=function(a){var b=this.ja;this.na=null;var c=new E;for(b=b.fa;null!==b;)c.add(a(b.key)),b=b.oa;return c.iterator};
Hb.prototype.filter=function(a){var b=this.ja;this.na=null;var c=new E;for(b=b.fa;null!==b;){var d=b.key;a(d)&&c.add(d);b=b.oa}return c.iterator};Hb.prototype.xd=function(){this.value=this.key=null;this.la=-1};Hb.prototype.toString=function(){return null!==this.na?"MapKeySetIterator@"+this.na.value:"MapKeySetIterator"};pa.Object.defineProperties(Hb.prototype,{iterator:{get:function(){return this}},count:{get:function(){return this.ja.Cb}}});
Hb.prototype.first=Hb.prototype.first;Hb.prototype.hasNext=Hb.prototype.dd;Hb.prototype.next=Hb.prototype.next;Hb.prototype.reset=Hb.prototype.reset;Hb.className="MapKeySetIterator";function Ib(a){F.call(this);nb(this);this.v=!0;this.ja=a}oa(Ib,F);t=Ib.prototype;t.freeze=function(){return this};t.ha=function(){return this};t.toString=function(){return"MapKeySet("+this.ja.toString()+")"};t.add=function(){A("This Set is read-only: "+this.toString());return this};t.contains=function(a){return this.ja.contains(a)};
t.has=function(a){return this.contains(a)};t.remove=function(){A("This Set is read-only: "+this.toString());return!1};t.delete=function(a){return this.remove(a)};t.clear=function(){A("This Set is read-only: "+this.toString())};t.first=function(){var a=this.ja.fa;return null!==a?a.key:null};Ib.prototype.any=function(a){for(var b=this.ja.fa;null!==b;){if(a(b.key))return!0;b=b.oa}return!1};Ib.prototype.all=function(a){for(var b=this.ja.fa;null!==b;){if(!a(b.key))return!1;b=b.oa}return!0};
Ib.prototype.each=function(a){for(var b=this.ja.fa;null!==b;)a(b.key),b=b.oa;return this};Ib.prototype.map=function(a){for(var b=new F,c=this.ja.fa;null!==c;)b.add(a(c.key)),c=c.oa;return b};Ib.prototype.filter=function(a){for(var b=new F,c=this.ja.fa;null!==c;){var d=c.key;a(d)&&b.add(d);c=c.oa}return b};Ib.prototype.copy=function(){return new Ib(this.ja)};Ib.prototype.Qv=function(){var a=new F,b=this.ja.Db,c;for(c in b)a.add(b[c].key);return a};
Ib.prototype.Na=function(){var a=this.ja.Db,b=Array(this.ja.Cb),c=0,d;for(d in a)b[c]=a[d].key,c++;return b};Ib.prototype.Pv=function(){var a=new E,b=this.ja.Db,c;for(c in b)a.add(b[c].key);return a};pa.Object.defineProperties(Ib.prototype,{count:{get:function(){return this.ja.Cb}},size:{get:function(){return this.ja.Cb}},iterator:{get:function(){return 0>=this.ja.Cb?xb:new Hb(this.ja)}}});
Ib.prototype.toList=Ib.prototype.Pv;Ib.prototype.toArray=Ib.prototype.Na;Ib.prototype.toSet=Ib.prototype.Qv;Ib.prototype.first=Ib.prototype.first;Ib.prototype.clear=Ib.prototype.clear;Ib.prototype["delete"]=Ib.prototype.delete;Ib.prototype.remove=Ib.prototype.remove;Ib.prototype.has=Ib.prototype.has;Ib.prototype.contains=Ib.prototype.contains;Ib.prototype.add=Ib.prototype.add;Ib.prototype.thaw=Ib.prototype.ha;Ib.prototype.freeze=Ib.prototype.freeze;Ib.className="MapKeySet";
function Jb(a){this.ja=a;a.Se=null;this.la=a.Ba;this.na=null}Jb.prototype.reset=function(){var a=this.ja;a.Se=null;this.la=a.Ba;this.na=null};Jb.prototype.next=function(){var a=this.ja;if(a.Ba!==this.la&&null===this.key)return!1;var b=this.na;b=null===b?a.fa:b.oa;if(null!==b)return this.na=b,this.value=b.value,this.key=b.key,!0;this.xd();return!1};Jb.prototype.dd=function(){return this.next()};
Jb.prototype.first=function(){var a=this.ja;this.la=a.Ba;a=a.fa;if(null!==a){this.na=a;var b=a.value;this.key=a.key;return this.value=b}return null};Jb.prototype.any=function(a){var b=this.ja;this.na=b.Se=null;for(b=b.fa;null!==b;){if(a(b.value))return!0;b=b.oa}return!1};Jb.prototype.all=function(a){var b=this.ja;this.na=b.Se=null;for(b=b.fa;null!==b;){if(!a(b.value))return!1;b=b.oa}return!0};Jb.prototype.each=function(a){var b=this.ja;this.na=b.Se=null;for(b=b.fa;null!==b;)a(b.value),b=b.oa;return this};
Jb.prototype.map=function(a){var b=this.ja;this.na=b.Se=null;var c=new E;for(b=b.fa;null!==b;)c.add(a(b.value)),b=b.oa;return c.iterator};Jb.prototype.filter=function(a){var b=this.ja;this.na=b.Se=null;var c=new E;for(b=b.fa;null!==b;){var d=b.value;a(d)&&c.add(d);b=b.oa}return c.iterator};Jb.prototype.xd=function(){this.value=this.key=null;this.la=-1;this.ja.Se=this};Jb.prototype.toString=function(){return null!==this.na?"MapValueSetIterator@"+this.na.value:"MapValueSetIterator"};
pa.Object.defineProperties(Jb.prototype,{iterator:{get:function(){return this}},count:{get:function(){return this.ja.Cb}}});Jb.prototype.first=Jb.prototype.first;Jb.prototype.hasNext=Jb.prototype.dd;Jb.prototype.next=Jb.prototype.next;Jb.prototype.reset=Jb.prototype.reset;Jb.className="MapValueSetIterator";function Fb(a,b){this.key=a;this.value=b;this.wl=this.oa=null}Fb.prototype.toString=function(){return"{"+this.key+":"+this.value+"}"};
Fb.className="KeyValuePair";function Kb(a){this.ja=a;a.Ja=null;this.la=a.Ba;this.na=null}Kb.prototype.reset=function(){var a=this.ja;a.Ja=null;this.la=a.Ba;this.na=null};Kb.prototype.next=function(){var a=this.ja;if(a.Ba!==this.la&&null===this.key)return!1;var b=this.na;b=null===b?a.fa:b.oa;if(null!==b)return this.na=b,this.key=b.key,this.value=b.value,!0;this.xd();return!1};Kb.prototype.dd=function(){return this.next()};
Kb.prototype.first=function(){var a=this.ja;this.la=a.Ba;a=a.fa;return null!==a?(this.na=a,this.key=a.key,this.value=a.value,a):null};Kb.prototype.any=function(a){var b=this.ja;this.na=b.Ja=null;for(b=b.fa;null!==b;){if(a(b))return!0;b=b.oa}return!1};Kb.prototype.all=function(a){var b=this.ja;this.na=b.Ja=null;for(b=b.fa;null!==b;){if(!a(b))return!1;b=b.oa}return!0};Kb.prototype.each=function(a){var b=this.ja;this.na=b.Ja=null;for(b=b.fa;null!==b;)a(b),b=b.oa;return this};
Kb.prototype.map=function(a){var b=this.ja;this.na=b.Ja=null;var c=new E;for(b=b.fa;null!==b;)c.add(a(b)),b=b.oa;return c.iterator};Kb.prototype.filter=function(a){var b=this.ja;this.na=b.Ja=null;var c=new E;for(b=b.fa;null!==b;)a(b)&&c.add(b),b=b.oa;return c.iterator};Kb.prototype.xd=function(){this.value=this.key=null;this.la=-1;this.ja.Ja=this};Kb.prototype.toString=function(){return null!==this.na?"MapIterator@"+this.na:"MapIterator"};
pa.Object.defineProperties(Kb.prototype,{iterator:{get:function(){return this}},count:{get:function(){return this.ja.Cb}}});Kb.prototype.first=Kb.prototype.first;Kb.prototype.hasNext=Kb.prototype.dd;Kb.prototype.next=Kb.prototype.next;Kb.prototype.reset=Kb.prototype.reset;Kb.className="MapIterator";
function Lb(a){nb(this);this.v=!1;this.Db={};this.Cb=0;this.Se=this.Ja=null;this.Ba=0;this.Te=this.fa=null;void 0!==a&&("function"===typeof a||"string"===typeof a?Aa():this.addAll(a))}t=Lb.prototype;t.kb=function(){var a=this.Ba;a++;999999999<a&&(a=0);this.Ba=a};t.freeze=function(){this.v=!0;return this};t.ha=function(){this.v=!1;return this};t.toString=function(){return"Map()#"+Bb(this)};
t.add=function(a,b){this.v&&wa(this,a);var c=a;Ca(a)&&(c=Db(a));var d=this.Db[c];void 0===d?(this.Cb++,a=new Fb(a,b),this.Db[c]=a,c=this.Te,null===c?this.fa=a:(a.wl=c,c.oa=a),this.Te=a,this.kb()):d.value=b;return this};t.set=function(a,b){return this.add(a,b)};t.addAll=function(a){if(null===a)return this;if(Ea(a))for(var b=a.length,c=0;c<b;c++){var d=a[c];this.add(d.key,d.value)}else for(a=a.iterator;a.next();)b=a.value,this.add(b.key,b.value);return this};t.first=function(){return this.fa};
Lb.prototype.any=function(a){for(var b=this.fa;null!==b;){if(a(b))return!0;b=b.oa}return!1};Lb.prototype.all=function(a){for(var b=this.fa;null!==b;){if(!a(b))return!1;b=b.oa}return!0};Lb.prototype.each=function(a){for(var b=this.fa;null!==b;)a(b),b=b.oa;return this};Lb.prototype.map=function(a){for(var b=new Lb,c=this.fa;null!==c;)b.add(c.key,a(c)),c=c.oa;return b};Lb.prototype.filter=function(a){for(var b=new Lb,c=this.fa;null!==c;)a(c)&&b.add(c.key,c.value),c=c.oa;return b};t=Lb.prototype;
t.contains=function(a){var b=a;return Ca(a)&&(b=Bb(a),void 0===b)?!1:void 0!==this.Db[b]};t.has=function(a){return this.contains(a)};t.J=function(a){var b=a;if(Ca(a)&&(b=Bb(a),void 0===b))return null;a=this.Db[b];return void 0===a?null:a.value};t.get=function(a){return this.J(a)};
t.remove=function(a){if(null===a)return!1;this.v&&wa(this,a);var b=a;if(Ca(a)&&(b=Bb(a),void 0===b))return!1;a=this.Db[b];if(void 0===a)return!1;var c=a.oa,d=a.wl;null!==c&&(c.wl=d);null!==d&&(d.oa=c);this.fa===a&&(this.fa=c);this.Te===a&&(this.Te=d);delete this.Db[b];this.Cb--;this.kb();return!0};t.delete=function(a){return this.remove(a)};t.clear=function(){this.v&&wa(this);this.Db={};this.Cb=0;null!==this.Ja&&this.Ja.reset();null!==this.Se&&this.Se.reset();this.Te=this.fa=null;this.kb()};
Lb.prototype.copy=function(){var a=new Lb,b=this.Db,c;for(c in b){var d=b[c];a.add(d.key,d.value)}return a};Lb.prototype.Na=function(){var a=this.Db,b=Array(this.Cb),c=0,d;for(d in a){var e=a[d];b[c]=new Fb(e.key,e.value);c++}return b};Lb.prototype.Yd=function(){return new Ib(this)};
pa.Object.defineProperties(Lb.prototype,{count:{get:function(){return this.Cb}},size:{get:function(){return this.Cb}},iterator:{get:function(){if(0>=this.count)return xb;var a=this.Ja;return null!==a?(a.reset(),a):new Kb(this)}},iteratorKeys:{get:function(){return 0>=this.count?xb:new Hb(this)}},iteratorValues:{get:function(){if(0>=this.count)return xb;
var a=this.Se;return null!==a?(a.reset(),a):new Jb(this)}}});Lb.prototype.toKeySet=Lb.prototype.Yd;Lb.prototype.toArray=Lb.prototype.Na;Lb.prototype.clear=Lb.prototype.clear;Lb.prototype["delete"]=Lb.prototype.delete;Lb.prototype.remove=Lb.prototype.remove;Lb.prototype.get=Lb.prototype.get;Lb.prototype.getValue=Lb.prototype.J;Lb.prototype.has=Lb.prototype.has;Lb.prototype.contains=Lb.prototype.contains;Lb.prototype.first=Lb.prototype.first;Lb.prototype.addAll=Lb.prototype.addAll;
Lb.prototype.set=Lb.prototype.set;Lb.prototype.add=Lb.prototype.add;Lb.prototype.thaw=Lb.prototype.ha;Lb.prototype.freeze=Lb.prototype.freeze;Lb.className="Map";function H(a,b){void 0===a?this.G=this.F=0:"number"===typeof a&&"number"===typeof b?(this.F=a,this.G=b):A("Invalid arguments to Point constructor: "+a+", "+b);this.v=!1}H.prototype.assign=function(a){this.F=a.F;this.G=a.G;return this};H.prototype.h=function(a,b){this.F=a;this.G=b;return this};
H.prototype.ug=function(a,b){this.F=a;this.G=b;return this};H.prototype.set=function(a){this.F=a.F;this.G=a.G;return this};H.prototype.copy=function(){var a=new H;a.F=this.F;a.G=this.G;return a};t=H.prototype;t.ga=function(){this.v=!0;Object.freeze(this);return this};t.I=function(){return Object.isFrozen(this)?this:this.copy().freeze()};t.freeze=function(){this.v=!0;return this};t.ha=function(){Object.isFrozen(this)&&A("cannot thaw constant: "+this);this.v=!1;return this};
function Nb(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var e=0;""===a[b];)b++;(d=a[b++])&&(e=parseFloat(d));return new H(c,e)}return new H}function Ob(a){return a.x.toString()+" "+a.y.toString()}t.toString=function(){return"Point("+this.x+","+this.y+")"};t.A=function(a){return a instanceof H?this.F===a.x&&this.G===a.y:!1};t.Fi=function(a,b){return this.F===a&&this.G===b};t.Qa=function(a){return I.w(this.F,a.x)&&I.w(this.G,a.y)};
t.add=function(a){this.F+=a.x;this.G+=a.y;return this};t.Xd=function(a){this.F-=a.x;this.G-=a.y;return this};t.offset=function(a,b){this.F+=a;this.G+=b;return this};H.prototype.rotate=function(a){if(0===a)return this;var b=this.F,c=this.G;if(0===b&&0===c)return this;360<=a?a-=360:0>a&&(a+=360);if(90===a){a=0;var d=1}else 180===a?(a=-1,d=0):270===a?(a=0,d=-1):(d=a*Math.PI/180,a=Math.cos(d),d=Math.sin(d));this.F=a*b-d*c;this.G=d*b+a*c;return this};t=H.prototype;
t.scale=function(a,b){this.F*=a;this.G*=b;return this};t.Be=function(a){var b=a.x-this.F;a=a.y-this.G;return b*b+a*a};t.cd=function(a,b){a-=this.F;b-=this.G;return a*a+b*b};t.normalize=function(){var a=this.F,b=this.G,c=Math.sqrt(a*a+b*b);0<c&&(this.F=a/c,this.G=b/c);return this};t.Wa=function(a){return Pb(a.x-this.F,a.y-this.G)};t.direction=function(a,b){return Pb(a-this.F,b-this.G)};
function Pb(a,b){if(0===a)return 0<b?90:0>b?270:0;if(0===b)return 0<a?0:180;if(isNaN(a)||isNaN(b))return 0;var c=180*Math.atan(Math.abs(b/a))/Math.PI;0>a?c=0>b?c+180:180-c:0>b&&(c=360-c);return c}t.Fz=function(a,b,c,d){I.Si(a,b,c,d,this.F,this.G,this);return this};t.Gz=function(a,b){I.Si(a.x,a.y,b.x,b.y,this.F,this.G,this);return this};t.Rz=function(a,b,c,d){I.Wp(this.F,this.G,a,b,c,d,this);return this};t.Sz=function(a,b){I.Wp(this.F,this.G,a.x,a.y,b.width,b.height,this);return this};
t.Vi=function(a,b){this.F=a.x+b.x*a.width+b.offsetX;this.G=a.y+b.y*a.height+b.offsetY;return this};t.sk=function(a,b,c,d,e){this.F=a+e.x*c+e.offsetX;this.G=b+e.y*d+e.offsetY;return this};t.transform=function(a){a.ta(this);return this};function Qb(a,b){b.Ud(a);return a}function Rb(a,b,c,d,e,f){var g=e-c,h=f-d,k=g*g+h*h;c-=a;d-=b;var l=-c*g-d*h;if(0>=l||l>=k)return g=e-a,h=f-b,Math.min(c*c+d*d,g*g+h*h);a=g*d-h*c;return a*a/k}function Sb(a,b,c,d){a=c-a;b=d-b;return a*a+b*b}
function Tb(a,b,c,d){a=c-a;b=d-b;if(0===a)return 0<b?90:0>b?270:0;if(0===b)return 0<a?0:180;if(isNaN(a)||isNaN(b))return 0;d=180*Math.atan(Math.abs(b/a))/Math.PI;0>a?d=0>b?d+180:180-d:0>b&&(d=360-d);return d}t.o=function(){return isFinite(this.x)&&isFinite(this.y)};H.alloc=function(){var a=$b.pop();return void 0===a?new H:a};H.allocAt=function(a,b){var c=$b.pop();if(void 0===c)return new H(a,b);c.x=a;c.y=b;return c};H.free=function(a){$b.push(a)};
pa.Object.defineProperties(H.prototype,{x:{get:function(){return this.F},set:function(a){this.F=a}},y:{get:function(){return this.G},set:function(a){this.G=a}}});H.prototype.isReal=H.prototype.o;H.prototype.setSpot=H.prototype.sk;H.prototype.setRectSpot=H.prototype.Vi;H.prototype.snapToGridPoint=H.prototype.Sz;H.prototype.snapToGrid=H.prototype.Rz;H.prototype.projectOntoLineSegmentPoint=H.prototype.Gz;H.prototype.projectOntoLineSegment=H.prototype.Fz;
H.prototype.direction=H.prototype.direction;H.prototype.directionPoint=H.prototype.Wa;H.prototype.normalize=H.prototype.normalize;H.prototype.distanceSquared=H.prototype.cd;H.prototype.distanceSquaredPoint=H.prototype.Be;H.prototype.scale=H.prototype.scale;H.prototype.rotate=H.prototype.rotate;H.prototype.offset=H.prototype.offset;H.prototype.subtract=H.prototype.Xd;H.prototype.add=H.prototype.add;H.prototype.equalsApprox=H.prototype.Qa;H.prototype.equalTo=H.prototype.Fi;H.prototype.equals=H.prototype.A;
H.prototype.set=H.prototype.set;H.prototype.setTo=H.prototype.ug;var ac=null,bc=null,cc=null,dc=null,ec=null,$b=[];H.className="Point";H.parse=Nb;H.stringify=Ob;H.distanceLineSegmentSquared=Rb;H.distanceSquared=Sb;H.direction=Tb;H.Origin=ac=(new H(0,0)).ga();H.InfiniteTopLeft=bc=(new H(-Infinity,-Infinity)).ga();H.InfiniteBottomRight=cc=(new H(Infinity,Infinity)).ga();H.SixPoint=dc=(new H(6,6)).ga();H.NoPoint=ec=(new H(NaN,NaN)).ga();H.parse=Nb;H.stringify=Ob;H.distanceLineSegmentSquared=Rb;
H.distanceSquared=Sb;H.direction=Tb;function L(a,b){void 0===a?this.Z=this.$=0:"number"===typeof a&&(0<=a||isNaN(a))&&"number"===typeof b&&(0<=b||isNaN(b))?(this.$=a,this.Z=b):A("Invalid arguments to Size constructor: "+a+", "+b);this.v=!1}var fc,gc,hc,ic,kc,lc,mc;L.prototype.assign=function(a){this.$=a.$;this.Z=a.Z;return this};L.prototype.h=function(a,b){this.$=a;this.Z=b;return this};L.prototype.ug=function(a,b){this.$=a;this.Z=b;return this};L.prototype.set=function(a){this.$=a.$;this.Z=a.Z;return this};
L.prototype.copy=function(){var a=new L;a.$=this.$;a.Z=this.Z;return a};t=L.prototype;t.ga=function(){this.v=!0;Object.freeze(this);return this};t.I=function(){return Object.isFrozen(this)?this:this.copy().freeze()};t.freeze=function(){this.v=!0;return this};t.ha=function(){Object.isFrozen(this)&&A("cannot thaw constant: "+this);this.v=!1;return this};
function nc(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var e=0;""===a[b];)b++;(d=a[b++])&&(e=parseFloat(d));return new L(c,e)}return new L}function oc(a){return a.width.toString()+" "+a.height.toString()}t.toString=function(){return"Size("+this.width+","+this.height+")"};t.A=function(a){return a instanceof L?this.$===a.width&&this.Z===a.height:!1};t.Fi=function(a,b){return this.$===a&&this.Z===b};
t.Qa=function(a){return I.w(this.$,a.width)&&I.w(this.Z,a.height)};t.o=function(){return isFinite(this.width)&&isFinite(this.height)};L.alloc=function(){var a=pc.pop();return void 0===a?new L:a};L.free=function(a){pc.push(a)};
pa.Object.defineProperties(L.prototype,{width:{get:function(){return this.$},set:function(a){0>a&&ya(a,">= 0",L,"width");this.$=a}},height:{get:function(){return this.Z},set:function(a){0>a&&ya(a,">= 0",L,"height");this.Z=a}}});L.prototype.isReal=L.prototype.o;L.prototype.equalsApprox=L.prototype.Qa;L.prototype.equalTo=L.prototype.Fi;L.prototype.equals=L.prototype.A;L.prototype.set=L.prototype.set;L.prototype.setTo=L.prototype.ug;
var pc=[];L.className="Size";L.parse=nc;L.stringify=oc;L.ZeroSize=fc=(new L(0,0)).ga();L.OneSize=gc=(new L(1,1)).ga();L.SixSize=hc=(new L(6,6)).ga();L.EightSize=ic=(new L(8,8)).ga();L.TenSize=kc=(new L(10,10)).ga();L.InfiniteSize=lc=(new L(Infinity,Infinity)).ga();L.NoSize=mc=(new L(NaN,NaN)).ga();L.parse=nc;L.stringify=oc;
function N(a,b,c,d){void 0===a?this.Z=this.$=this.G=this.F=0:a instanceof H?(c=a.x,a=a.y,b instanceof H?(d=b.x,b=b.y,this.F=Math.min(c,d),this.G=Math.min(a,b),this.$=Math.abs(c-d),this.Z=Math.abs(a-b)):b instanceof L?(this.F=c,this.G=a,this.$=b.$,this.Z=b.Z):A("Incorrect arguments supplied to Rect constructor")):"number"===typeof a&&"number"===typeof b&&"number"===typeof c&&(0<=c||isNaN(c))&&"number"===typeof d&&(0<=d||isNaN(d))?(this.F=a,this.G=b,this.$=c,this.Z=d):A("Invalid arguments to Rect constructor: "+
a+", "+b+", "+c+", "+d);this.v=!1}t=N.prototype;t.assign=function(a){this.F=a.F;this.G=a.G;this.$=a.$;this.Z=a.Z;return this};t.h=function(a,b,c,d){this.F=a;this.G=b;this.$=c;this.Z=d;return this};function sc(a,b,c){a.$=b;a.Z=c}t.ug=function(a,b,c,d){this.F=a;this.G=b;this.$=c;this.Z=d;return this};t.set=function(a){this.F=a.F;this.G=a.G;this.$=a.$;this.Z=a.Z;return this};t.jd=function(a){this.F=a.x;this.G=a.y;return this};t.Pz=function(a){this.$=a.$;this.Z=a.Z;return this};
N.prototype.copy=function(){var a=new N;a.F=this.F;a.G=this.G;a.$=this.$;a.Z=this.Z;return a};t=N.prototype;t.ga=function(){this.v=!0;Object.freeze(this);return this};t.I=function(){return Object.isFrozen(this)?this:this.copy().freeze()};t.freeze=function(){this.v=!0;return this};t.ha=function(){Object.isFrozen(this)&&A("cannot thaw constant: "+this);this.v=!1;return this};
function tc(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));for(var e=0;""===a[b];)b++;(d=a[b++])&&(e=parseFloat(d));for(var f=0;""===a[b];)b++;(d=a[b++])&&(f=parseFloat(d));for(var g=0;""===a[b];)b++;(d=a[b++])&&(g=parseFloat(d));return new N(c,e,f,g)}return new N}function uc(a){return a.x.toString()+" "+a.y.toString()+" "+a.width.toString()+" "+a.height.toString()}
t.toString=function(){return"Rect("+this.x+","+this.y+","+this.width+","+this.height+")"};t.A=function(a){return a instanceof N?this.F===a.x&&this.G===a.y&&this.$===a.width&&this.Z===a.height:!1};t.Fi=function(a,b,c,d){return this.F===a&&this.G===b&&this.$===c&&this.Z===d};t.Qa=function(a){return I.w(this.F,a.x)&&I.w(this.G,a.y)&&I.w(this.$,a.width)&&I.w(this.Z,a.height)};function xc(a,b){return I.ca(a.F,b.x)&&I.ca(a.G,b.y)&&I.ca(a.$,b.width)&&I.ca(a.Z,b.height)}
t.da=function(a){return this.F<=a.x&&this.F+this.$>=a.x&&this.G<=a.y&&this.G+this.Z>=a.y};t.mf=function(a){return this.F<=a.x&&a.x+a.width<=this.F+this.$&&this.G<=a.y&&a.y+a.height<=this.G+this.Z};t.contains=function(a,b,c,d){void 0===c&&(c=0);void 0===d&&(d=0);return this.F<=a&&a+c<=this.F+this.$&&this.G<=b&&b+d<=this.G+this.Z};t.reset=function(){this.Z=this.$=this.G=this.F=0};t.offset=function(a,b){this.F+=a;this.G+=b;return this};t.Uc=function(a,b){return yc(this,b,a,b,a)};
t.Np=function(a){return yc(this,a.top,a.right,a.bottom,a.left)};t.Ov=function(a){return yc(this,-a.top,-a.right,-a.bottom,-a.left)};t.hz=function(a,b,c,d){return yc(this,a,b,c,d)};function yc(a,b,c,d,e){var f=a.$;c+e<=-f?(a.F+=f/2,a.$=0):(a.F-=e,a.$+=c+e);c=a.Z;b+d<=-c?(a.G+=c/2,a.Z=0):(a.G-=b,a.Z+=b+d);return a}t.lz=function(a){return zc(this,a.x,a.y,a.width,a.height)};t.gv=function(a,b,c,d){return zc(this,a,b,c,d)};
function zc(a,b,c,d,e){var f=Math.max(a.F,b),g=Math.max(a.G,c);b=Math.min(a.F+a.$,b+d);c=Math.min(a.G+a.Z,c+e);a.F=f;a.G=g;a.$=Math.max(0,b-f);a.Z=Math.max(0,c-g);return a}t.Ic=function(a){return this.hv(a.x,a.y,a.width,a.height)};t.hv=function(a,b,c,d){var e=this.$,f=this.F;if(Infinity!==e&&Infinity!==c&&(e+=f,c+=a,isNaN(c)||isNaN(e)||f>c||a>e))return!1;a=this.Z;c=this.G;return Infinity!==a&&Infinity!==d&&(a+=c,d+=b,isNaN(d)||isNaN(a)||c>d||b>a)?!1:!0};
function Ac(a,b,c){var d=a.$,e=a.F,f=b.x-c;if(e>b.width+c+c+f||f>d+e)return!1;d=a.Z;a=a.G;e=b.y-c;return a>b.height+c+c+e||e>d+a?!1:!0}t.Ie=function(a){return Bc(this,a.x,a.y,0,0)};t.Jc=function(a){return Bc(this,a.F,a.G,a.$,a.Z)};t.Vv=function(a,b,c,d){void 0===c&&(c=0);void 0===d&&(d=0);return Bc(this,a,b,c,d)};function Bc(a,b,c,d,e){var f=Math.min(a.F,b),g=Math.min(a.G,c);b=Math.max(a.F+a.$,b+d);c=Math.max(a.G+a.Z,c+e);a.F=f;a.G=g;a.$=b-f;a.Z=c-g;return a}
t.sk=function(a,b,c){this.F=a-c.offsetX-c.x*this.$;this.G=b-c.offsetY-c.y*this.Z;return this};function Cc(a,b,c,d,e,f,g,h){void 0===g&&(g=0);void 0===h&&(h=0);return a<=e&&e+g<=a+c&&b<=f&&f+h<=b+d}function Dc(a,b,c,d,e,f,g,h){return a>g+e||e>c+a?!1:b>h+f||f>d+b?!1:!0}t.o=function(){return isFinite(this.x)&&isFinite(this.y)&&isFinite(this.width)&&isFinite(this.height)};t.nz=function(){return 0===this.width&&0===this.height};N.alloc=function(){var a=Fc.pop();return void 0===a?new N:a};
N.allocAt=function(a,b,c,d){var e=Fc.pop();return void 0===e?new N(a,b,c,d):e.h(a,b,c,d)};N.free=function(a){Fc.push(a)};
pa.Object.defineProperties(N.prototype,{x:{get:function(){return this.F},set:function(a){this.F=a}},y:{get:function(){return this.G},set:function(a){this.G=a}},width:{get:function(){return this.$},set:function(a){0>a&&ya(a,">= 0",N,"width");this.$=a}},height:{get:function(){return this.Z},set:function(a){0>a&&ya(a,">= 0",N,"height");this.Z=a}},left:{
get:function(){return this.F},set:function(a){this.F=a}},top:{get:function(){return this.G},set:function(a){this.G=a}},right:{get:function(){return this.F+this.$},set:function(a){this.F+=a-(this.F+this.$)}},bottom:{get:function(){return this.G+this.Z},set:function(a){this.G+=a-(this.G+this.Z)}},position:{get:function(){return new H(this.F,this.G)},set:function(a){this.F=a.x;this.G=
a.y}},size:{get:function(){return new L(this.$,this.Z)},set:function(a){this.$=a.width;this.Z=a.height}},center:{get:function(){return new H(this.F+this.$/2,this.G+this.Z/2)},set:function(a){this.F=a.x-this.$/2;this.G=a.y-this.Z/2}},centerX:{get:function(){return this.F+this.$/2},set:function(a){this.F=a-this.$/2}},centerY:{get:function(){return this.G+this.Z/2},set:function(a){this.G=
a-this.Z/2}}});N.prototype.isEmpty=N.prototype.nz;N.prototype.isReal=N.prototype.o;N.prototype.setSpot=N.prototype.sk;N.prototype.union=N.prototype.Vv;N.prototype.unionRect=N.prototype.Jc;N.prototype.unionPoint=N.prototype.Ie;N.prototype.intersects=N.prototype.hv;N.prototype.intersectsRect=N.prototype.Ic;N.prototype.intersect=N.prototype.gv;N.prototype.intersectRect=N.prototype.lz;N.prototype.grow=N.prototype.hz;N.prototype.subtractMargin=N.prototype.Ov;N.prototype.addMargin=N.prototype.Np;
N.prototype.inflate=N.prototype.Uc;N.prototype.offset=N.prototype.offset;N.prototype.contains=N.prototype.contains;N.prototype.containsRect=N.prototype.mf;N.prototype.containsPoint=N.prototype.da;N.prototype.equalsApprox=N.prototype.Qa;N.prototype.equalTo=N.prototype.Fi;N.prototype.equals=N.prototype.A;N.prototype.setSize=N.prototype.Pz;N.prototype.setPoint=N.prototype.jd;N.prototype.set=N.prototype.set;N.prototype.setTo=N.prototype.ug;var Gc=null,Hc=null,Fc=[];N.className="Rect";N.parse=tc;
N.stringify=uc;N.contains=Cc;N.intersects=Dc;N.ZeroRect=Gc=(new N(0,0,0,0)).ga();N.NoRect=Hc=(new N(NaN,NaN,NaN,NaN)).ga();N.parse=tc;N.stringify=uc;N.contains=Cc;N.intersects=Dc;
function Ic(a,b,c,d){void 0===a?this.ke=this.ae=this.ue=this.we=0:void 0===b?this.left=this.bottom=this.right=this.top=a:void 0===c?(this.top=a,this.right=b,this.bottom=a,this.left=b):void 0!==d?(this.top=a,this.right=b,this.bottom=c,this.left=d):A("Invalid arguments to Margin constructor: "+a+", "+b+", "+c+", "+d);this.v=!1}Ic.prototype.assign=function(a){this.we=a.we;this.ue=a.ue;this.ae=a.ae;this.ke=a.ke;return this};Ic.prototype.ug=function(a,b,c,d){this.we=a;this.ue=b;this.ae=c;this.ke=d;return this};
Ic.prototype.set=function(a){this.we=a.we;this.ue=a.ue;this.ae=a.ae;this.ke=a.ke;return this};Ic.prototype.copy=function(){var a=new Ic;a.we=this.we;a.ue=this.ue;a.ae=this.ae;a.ke=this.ke;return a};t=Ic.prototype;t.ga=function(){this.v=!0;Object.freeze(this);return this};t.I=function(){return Object.isFrozen(this)?this:this.copy().freeze()};t.freeze=function(){this.v=!0;return this};t.ha=function(){Object.isFrozen(this)&&A("cannot thaw constant: "+this);this.v=!1;return this};
function Jc(a){if("string"===typeof a){a=a.split(" ");for(var b=0,c=NaN;""===a[b];)b++;var d=a[b++];d&&(c=parseFloat(d));if(isNaN(c))return new Ic;for(var e=NaN;""===a[b];)b++;(d=a[b++])&&(e=parseFloat(d));if(isNaN(e))return new Ic(c);for(var f=NaN;""===a[b];)b++;(d=a[b++])&&(f=parseFloat(d));if(isNaN(f))return new Ic(c,e);for(var g=NaN;""===a[b];)b++;(d=a[b++])&&(g=parseFloat(d));return isNaN(g)?new Ic(c,e):new Ic(c,e,f,g)}return new Ic}
function Kc(a){return a.top.toString()+" "+a.right.toString()+" "+a.bottom.toString()+" "+a.left.toString()}t.toString=function(){return"Margin("+this.top+","+this.right+","+this.bottom+","+this.left+")"};t.A=function(a){return a instanceof Ic?this.we===a.top&&this.ue===a.right&&this.ae===a.bottom&&this.ke===a.left:!1};t.Fi=function(a,b,c,d){return this.we===a&&this.ue===b&&this.ae===c&&this.ke===d};
t.Qa=function(a){return I.w(this.we,a.top)&&I.w(this.ue,a.right)&&I.w(this.ae,a.bottom)&&I.w(this.ke,a.left)};t.o=function(){return isFinite(this.top)&&isFinite(this.right)&&isFinite(this.bottom)&&isFinite(this.left)};Ic.alloc=function(){var a=Lc.pop();return void 0===a?new Ic:a};Ic.free=function(a){Lc.push(a)};
pa.Object.defineProperties(Ic.prototype,{top:{get:function(){return this.we},set:function(a){this.we=a}},right:{get:function(){return this.ue},set:function(a){this.ue=a}},bottom:{get:function(){return this.ae},set:function(a){this.ae=a}},left:{get:function(){return this.ke},set:function(a){this.ke=a}}});Ic.prototype.isReal=Ic.prototype.o;Ic.prototype.equalsApprox=Ic.prototype.Qa;
Ic.prototype.equalTo=Ic.prototype.Fi;Ic.prototype.equals=Ic.prototype.A;Ic.prototype.set=Ic.prototype.set;Ic.prototype.setTo=Ic.prototype.ug;var Oc=null,Pc=null,Lc=[];Ic.className="Margin";Ic.parse=Jc;Ic.stringify=Kc;Ic.ZeroMargin=Oc=(new Ic(0,0,0,0)).ga();Ic.TwoMargin=Pc=(new Ic(2,2,2,2)).ga();Ic.parse=Jc;Ic.stringify=Kc;function Qc(){this.m11=1;this.m21=this.m12=0;this.m22=1;this.dy=this.dx=0}
Qc.prototype.set=function(a){this.m11=a.m11;this.m12=a.m12;this.m21=a.m21;this.m22=a.m22;this.dx=a.dx;this.dy=a.dy;return this};Qc.prototype.copy=function(){var a=new Qc;a.m11=this.m11;a.m12=this.m12;a.m21=this.m21;a.m22=this.m22;a.dx=this.dx;a.dy=this.dy;return a};t=Qc.prototype;t.toString=function(){return"Transform("+this.m11+","+this.m12+","+this.m21+","+this.m22+","+this.dx+","+this.dy+")"};
t.A=function(a){return a instanceof Qc?this.m11===a.m11&&this.m12===a.m12&&this.m21===a.m21&&this.m22===a.m22&&this.dx===a.dx&&this.dy===a.dy:!1};t.bt=function(){return 0===this.dx&&0===this.dy&&1===this.m11&&0===this.m12&&0===this.m21&&1===this.m22};t.reset=function(){this.m11=1;this.m21=this.m12=0;this.m22=1;this.dy=this.dx=0;return this};
t.multiply=function(a){var b=this.m12*a.m11+this.m22*a.m12,c=this.m11*a.m21+this.m21*a.m22,d=this.m12*a.m21+this.m22*a.m22,e=this.m11*a.dx+this.m21*a.dy+this.dx,f=this.m12*a.dx+this.m22*a.dy+this.dy;this.m11=this.m11*a.m11+this.m21*a.m12;this.m12=b;this.m21=c;this.m22=d;this.dx=e;this.dy=f;return this};
t.nv=function(a){var b=1/(a.m11*a.m22-a.m12*a.m21),c=a.m22*b,d=-a.m12*b,e=-a.m21*b,f=a.m11*b,g=b*(a.m21*a.dy-a.m22*a.dx);a=b*(a.m12*a.dx-a.m11*a.dy);b=this.m11*c+this.m21*d;c=this.m12*c+this.m22*d;d=this.m11*e+this.m21*f;e=this.m12*e+this.m22*f;this.dx=this.m11*g+this.m21*a+this.dx;this.dy=this.m12*g+this.m22*a+this.dy;this.m11=b;this.m12=c;this.m21=d;this.m22=e;return this};
t.at=function(){var a=1/(this.m11*this.m22-this.m12*this.m21),b=-this.m12*a,c=-this.m21*a,d=this.m11*a,e=a*(this.m21*this.dy-this.m22*this.dx),f=a*(this.m12*this.dx-this.m11*this.dy);this.m11=this.m22*a;this.m12=b;this.m21=c;this.m22=d;this.dx=e;this.dy=f;return this};
Qc.prototype.rotate=function(a,b,c){360<=a?a-=360:0>a&&(a+=360);if(0===a)return this;this.translate(b,c);if(90===a){a=0;var d=1}else 180===a?(a=-1,d=0):270===a?(a=0,d=-1):(d=a*Math.PI/180,a=Math.cos(d),d=Math.sin(d));var e=this.m12*a+this.m22*d,f=this.m11*-d+this.m21*a,g=this.m12*-d+this.m22*a;this.m11=this.m11*a+this.m21*d;this.m12=e;this.m21=f;this.m22=g;this.translate(-b,-c);return this};t=Qc.prototype;t.translate=function(a,b){this.dx+=this.m11*a+this.m21*b;this.dy+=this.m12*a+this.m22*b;return this};
t.scale=function(a,b){void 0===b&&(b=a);this.m11*=a;this.m12*=a;this.m21*=b;this.m22*=b;return this};t.ta=function(a){var b=a.x,c=a.y;return a.h(b*this.m11+c*this.m21+this.dx,b*this.m12+c*this.m22+this.dy)};t.Ud=function(a){var b=1/(this.m11*this.m22-this.m12*this.m21),c=a.x,d=a.y;return a.h(c*this.m22*b+d*-this.m21*b+b*(this.m21*this.dy-this.m22*this.dx),c*-this.m12*b+d*this.m11*b+b*(this.m12*this.dx-this.m11*this.dy))};
t.Uv=function(a){var b=a.F,c=a.G,d=b+a.$,e=c+a.Z,f=this.m11,g=this.m12,h=this.m21,k=this.m22,l=this.dx,m=this.dy,n=b*f+c*h+l,p=b*g+c*k+m,q=d*f+c*h+l,r=d*g+c*k+m;c=b*f+e*h+l;b=b*g+e*k+m;f=d*f+e*h+l;d=d*g+e*k+m;e=Math.min(n,q);n=Math.max(n,q);q=Math.min(p,r);p=Math.max(p,r);e=Math.min(e,c);n=Math.max(n,c);q=Math.min(q,b);p=Math.max(p,b);e=Math.min(e,f);n=Math.max(n,f);q=Math.min(q,d);p=Math.max(p,d);a.F=e;a.G=q;a.$=n-e;a.Z=p-q;return a};Qc.alloc=function(){var a=Rc.pop();return void 0===a?new Qc:a};
Qc.free=function(a){Rc.push(a)};Qc.prototype.transformRect=Qc.prototype.Uv;Qc.prototype.invertedTransformPoint=Qc.prototype.Ud;Qc.prototype.transformPoint=Qc.prototype.ta;Qc.prototype.scale=Qc.prototype.scale;Qc.prototype.translate=Qc.prototype.translate;Qc.prototype.rotate=Qc.prototype.rotate;Qc.prototype.invert=Qc.prototype.at;Qc.prototype.multiplyInverted=Qc.prototype.nv;Qc.prototype.multiply=Qc.prototype.multiply;Qc.prototype.reset=Qc.prototype.reset;Qc.prototype.isIdentity=Qc.prototype.bt;
Qc.prototype.equals=Qc.prototype.A;Qc.prototype.set=Qc.prototype.set;var Rc=[];Qc.className="Transform";Qc.xF="54a702f3e53909c447824c6706603faf4c";function O(a,b,c,d){void 0===a?this.Nd=this.Md=this.G=this.F=0:(void 0===b&&(b=0),void 0===c&&(c=0),void 0===d&&(d=0),this.x=a,this.y=b,this.offsetX=c,this.offsetY=d);this.v=!1}var Sc,Tc,Uc,Vc,cd,dd,ed,fd,gd,hd,id,jd,pd,qd,rd,sd,td,ud,vd,wd,zd,Ad,Bd,Cd,Dd,Ed,Fd,Gd,Hd,Id,Jd,Kd,Sd,Td,Ud,Vd;
O.prototype.assign=function(a){this.F=a.F;this.G=a.G;this.Md=a.Md;this.Nd=a.Nd;return this};O.prototype.ug=function(a,b,c,d){this.F=a;this.G=b;this.Md=c;this.Nd=d;return this};O.prototype.set=function(a){this.F=a.F;this.G=a.G;this.Md=a.Md;this.Nd=a.Nd;return this};O.prototype.copy=function(){var a=new O;a.F=this.F;a.G=this.G;a.Md=this.Md;a.Nd=this.Nd;return a};t=O.prototype;t.ga=function(){this.v=!0;Object.freeze(this);return this};t.I=function(){return Object.isFrozen(this)?this:this.copy().freeze()};
t.freeze=function(){this.v=!0;return this};t.ha=function(){Object.isFrozen(this)&&A("cannot thaw constant: "+this);this.v=!1;return this};function Wd(a,b){a.F=NaN;a.G=NaN;a.Md=b;return a}
function Xd(a){if("string"===typeof a){a=a.trim();if("None"===a)return Sc;if("TopLeft"===a)return Tc;if("Top"===a||"TopCenter"===a||"MiddleTop"===a)return Uc;if("TopRight"===a)return Vc;if("Left"===a||"LeftCenter"===a||"MiddleLeft"===a)return cd;if("Center"===a)return dd;if("Right"===a||"RightCenter"===a||"MiddleRight"===a)return ed;if("BottomLeft"===a)return fd;if("Bottom"===a||"BottomCenter"===a||"MiddleBottom"===a)return gd;if("BottomRight"===a)return hd;if("TopSide"===a)return id;if("LeftSide"===
a)return jd;if("RightSide"===a)return pd;if("BottomSide"===a)return qd;if("TopBottomSides"===a)return rd;if("LeftRightSides"===a)return sd;if("TopLeftSides"===a)return td;if("TopRightSides"===a)return ud;if("BottomLeftSides"===a)return vd;if("BottomRightSides"===a)return wd;if("NotTopSide"===a)return zd;if("NotLeftSide"===a)return Ad;if("NotRightSide"===a)return Bd;if("NotBottomSide"===a)return Cd;if("AllSides"===a)return Dd;if("Default"===a)return Ed;a=a.split(" ");for(var b=0,c=0;""===a[b];)b++;
var d=a[b++];void 0!==d&&0<d.length&&(c=parseFloat(d));for(var e=0;""===a[b];)b++;d=a[b++];void 0!==d&&0<d.length&&(e=parseFloat(d));for(var f=0;""===a[b];)b++;d=a[b++];void 0!==d&&0<d.length&&(f=parseFloat(d));for(var g=0;""===a[b];)b++;d=a[b++];void 0!==d&&0<d.length&&(g=parseFloat(d));return new O(c,e,f,g)}return new O}function Yd(a){return a.gb()?a.x.toString()+" "+a.y.toString()+" "+a.offsetX.toString()+" "+a.offsetY.toString():a.toString()}
t.toString=function(){return this.gb()?0===this.Md&&0===this.Nd?"Spot("+this.x+","+this.y+")":"Spot("+this.x+","+this.y+","+this.offsetX+","+this.offsetY+")":this.A(Sc)?"None":this.A(Tc)?"TopLeft":this.A(Uc)?"Top":this.A(Vc)?"TopRight":this.A(cd)?"Left":this.A(dd)?"Center":this.A(ed)?"Right":this.A(fd)?"BottomLeft":this.A(gd)?"Bottom":this.A(hd)?"BottomRight":this.A(id)?"TopSide":this.A(jd)?"LeftSide":this.A(pd)?"RightSide":this.A(qd)?"BottomSide":this.A(rd)?"TopBottomSides":this.A(sd)?"LeftRightSides":
this.A(td)?"TopLeftSides":this.A(ud)?"TopRightSides":this.A(vd)?"BottomLeftSides":this.A(wd)?"BottomRightSides":this.A(zd)?"NotTopSide":this.A(Ad)?"NotLeftSide":this.A(Bd)?"NotRightSide":this.A(Cd)?"NotBottomSide":this.A(Dd)?"AllSides":this.A(Ed)?"Default":"None"};t.A=function(a){return a instanceof O?(this.F===a.x||isNaN(this.F)&&isNaN(a.x))&&(this.G===a.y||isNaN(this.G)&&isNaN(a.y))&&this.Md===a.offsetX&&this.Nd===a.offsetY:!1};
t.rv=function(){return new O(.5-(this.F-.5),.5-(this.G-.5),-this.Md,-this.Nd)};t.qf=function(a){if(!this.rf())return!1;if(!a.rf())if(a.A(Fd))a=jd;else if(a.A(Gd))a=pd;else if(a.A(Hd))a=id;else if(a.A(Id))a=qd;else return!1;a=a.offsetY;return(this.Nd&a)===a};t.gb=function(){return!isNaN(this.x)&&!isNaN(this.y)};t.jc=function(){return isNaN(this.x)||isNaN(this.y)};t.rf=function(){return isNaN(this.x)&&isNaN(this.y)&&1===this.offsetX&&0!==this.offsetY};
t.dt=function(){return isNaN(this.x)&&isNaN(this.y)&&0===this.offsetX&&0===this.offsetY};t.Kb=function(){return isNaN(this.x)&&isNaN(this.y)&&-1===this.offsetX&&0===this.offsetY};O.alloc=function(){var a=Zd.pop();return void 0===a?new O:a};O.free=function(a){Zd.push(a)};
pa.Object.defineProperties(O.prototype,{x:{get:function(){return this.F},set:function(a){this.F=a}},y:{get:function(){return this.G},set:function(a){this.G=a}},offsetX:{get:function(){return this.Md},set:function(a){this.Md=a}},offsetY:{get:function(){return this.Nd},set:function(a){this.Nd=a}}});O.prototype.isDefault=O.prototype.Kb;O.prototype.isNone=O.prototype.dt;
O.prototype.isSide=O.prototype.rf;O.prototype.isNoSpot=O.prototype.jc;O.prototype.isSpot=O.prototype.gb;O.prototype.includesSide=O.prototype.qf;O.prototype.opposite=O.prototype.rv;O.prototype.equals=O.prototype.A;O.prototype.set=O.prototype.set;O.prototype.setTo=O.prototype.ug;var Zd=[];O.className="Spot";O.parse=Xd;O.stringify=Yd;O.None=Sc=Wd(new O(0,0,0,0),0).ga();O.Default=Ed=Wd(new O(0,0,-1,0),-1).ga();O.TopLeft=Tc=(new O(0,0,0,0)).ga();O.TopCenter=Uc=(new O(.5,0,0,0)).ga();
O.TopRight=Vc=(new O(1,0,0,0)).ga();O.LeftCenter=cd=(new O(0,.5,0,0)).ga();O.Center=dd=(new O(.5,.5,0,0)).ga();O.RightCenter=ed=(new O(1,.5,0,0)).ga();O.BottomLeft=fd=(new O(0,1,0,0)).ga();O.BottomCenter=gd=(new O(.5,1,0,0)).ga();O.BottomRight=hd=(new O(1,1,0,0)).ga();O.MiddleTop=Jd=Uc;O.MiddleLeft=Kd=cd;O.MiddleRight=Sd=ed;O.MiddleBottom=Td=gd;O.Top=Hd=Uc;O.Left=Fd=cd;O.Right=Gd=ed;O.Bottom=Id=gd;O.TopSide=id=Wd(new O(0,0,1,1),1).ga();O.LeftSide=jd=Wd(new O(0,0,1,2),1).ga();
O.RightSide=pd=Wd(new O(0,0,1,4),1).ga();O.BottomSide=qd=Wd(new O(0,0,1,8),1).ga();O.TopBottomSides=rd=Wd(new O(0,0,1,9),1).ga();O.LeftRightSides=sd=Wd(new O(0,0,1,6),1).ga();O.TopLeftSides=td=Wd(new O(0,0,1,3),1).ga();O.TopRightSides=ud=Wd(new O(0,0,1,5),1).ga();O.BottomLeftSides=vd=Wd(new O(0,0,1,10),1).ga();O.BottomRightSides=wd=Wd(new O(0,0,1,12),1).ga();O.NotTopSide=zd=Wd(new O(0,0,1,14),1).ga();O.NotLeftSide=Ad=Wd(new O(0,0,1,13),1).ga();O.NotRightSide=Bd=Wd(new O(0,0,1,11),1).ga();
O.NotBottomSide=Cd=Wd(new O(0,0,1,7),1).ga();O.AllSides=Dd=Wd(new O(0,0,1,15),1).ga();Ud=(new O(.156,.156)).ga();Vd=(new O(.844,.844)).ga();O.parse=Xd;O.stringify=Yd;
var I={Xz:"7da71ca0ad381e90",yg:(Math.sqrt(2)-1)/3*4,cw:null,sqrt:function(a){if(0>=a)return 0;var b=I.cw;if(null===b){b=[];for(var c=0;2E3>=c;c++)b[c]=Math.sqrt(c);I.cw=b}return 1>a?(c=1/a,2E3>=c?1/b[c|0]:Math.sqrt(a)):2E3>=a?b[a|0]:Math.sqrt(a)},w:function(a,b){a-=b;return.5>a&&-.5<a},ca:function(a,b){a-=b;return 5E-8>a&&-5E-8<a},Tb:function(a,b,c,d,e,f,g){0>=e&&(e=1E-6);if(a<c){var h=a;var k=c}else h=c,k=a;if(b<d){var l=b;var m=d}else l=d,m=b;if(a===c)return l<=g&&g<=m&&a-e<=f&&f<=a+e;if(b===d)return h<=
f&&f<=k&&b-e<=g&&g<=b+e;k+=e;h-=e;if(h<=f&&f<=k&&(m+=e,l-=e,l<=g&&g<=m))if(k-h>m-l)if(a-c>e||c-a>e){if(f=(d-b)/(c-a)*(f-a)+b,f-e<=g&&g<=f+e)return!0}else return!0;else if(b-d>e||d-b>e){if(g=(c-a)/(d-b)*(g-b)+a,g-e<=f&&f<=g+e)return!0}else return!0;return!1},Js:function(a,b,c,d,e,f,g,h,k,l,m,n){if(I.Tb(a,b,g,h,n,c,d)&&I.Tb(a,b,g,h,n,e,f))return I.Tb(a,b,g,h,n,l,m);var p=(a+c)/2,q=(b+d)/2,r=(c+e)/2,u=(d+f)/2;e=(e+g)/2;f=(f+h)/2;d=(p+r)/2;c=(q+u)/2;r=(r+e)/2;u=(u+f)/2;var x=(d+r)/2,v=(c+u)/2;return I.Js(a,
b,p,q,d,c,x,v,k,l,m,n)||I.Js(x,v,r,u,e,f,g,h,k,l,m,n)},my:function(a,b,c,d,e,f,g,h,k){var l=(c+e)/2,m=(d+f)/2;k.h((((a+c)/2+l)/2+(l+(e+g)/2)/2)/2,(((b+d)/2+m)/2+(m+(f+h)/2)/2)/2);return k},ly:function(a,b,c,d,e,f,g,h){var k=(c+e)/2,l=(d+f)/2;return Tb(((a+c)/2+k)/2,((b+d)/2+l)/2,(k+(e+g)/2)/2,(l+(f+h)/2)/2)},Sl:function(a,b,c,d,e,f,g,h,k,l){if(I.Tb(a,b,g,h,k,c,d)&&I.Tb(a,b,g,h,k,e,f))Bc(l,a,b,0,0),Bc(l,g,h,0,0);else{var m=(a+c)/2,n=(b+d)/2,p=(c+e)/2,q=(d+f)/2;e=(e+g)/2;f=(f+h)/2;d=(m+p)/2;c=(n+q)/
2;p=(p+e)/2;q=(q+f)/2;var r=(d+p)/2,u=(c+q)/2;I.Sl(a,b,m,n,d,c,r,u,k,l);I.Sl(r,u,p,q,e,f,g,h,k,l)}return l},ze:function(a,b,c,d,e,f,g,h,k,l){if(I.Tb(a,b,g,h,k,c,d)&&I.Tb(a,b,g,h,k,e,f))0===l.length&&(l.push(a),l.push(b)),l.push(g),l.push(h);else{var m=(a+c)/2,n=(b+d)/2,p=(c+e)/2,q=(d+f)/2;e=(e+g)/2;f=(f+h)/2;d=(m+p)/2;c=(n+q)/2;p=(p+e)/2;q=(q+f)/2;var r=(d+p)/2,u=(c+q)/2;I.ze(a,b,m,n,d,c,r,u,k,l);I.ze(r,u,p,q,e,f,g,h,k,l)}return l},uv:function(a,b,c,d,e,f,g,h,k,l){if(I.Tb(a,b,e,f,l,c,d))return I.Tb(a,
b,e,f,l,h,k);var m=(a+c)/2,n=(b+d)/2;c=(c+e)/2;d=(d+f)/2;var p=(m+c)/2,q=(n+d)/2;return I.uv(a,b,m,n,p,q,g,h,k,l)||I.uv(p,q,c,d,e,f,g,h,k,l)},fA:function(a,b,c,d,e,f,g){g.h(((a+c)/2+(c+e)/2)/2,((b+d)/2+(d+f)/2)/2);return g},tv:function(a,b,c,d,e,f,g,h){if(I.Tb(a,b,e,f,g,c,d))Bc(h,a,b,0,0),Bc(h,e,f,0,0);else{var k=(a+c)/2,l=(b+d)/2;c=(c+e)/2;d=(d+f)/2;var m=(k+c)/2,n=(l+d)/2;I.tv(a,b,k,l,m,n,g,h);I.tv(m,n,c,d,e,f,g,h)}return h},mq:function(a,b,c,d,e,f,g,h){if(I.Tb(a,b,e,f,g,c,d))0===h.length&&(h.push(a),
h.push(b)),h.push(e),h.push(f);else{var k=(a+c)/2,l=(b+d)/2;c=(c+e)/2;d=(d+f)/2;var m=(k+c)/2,n=(l+d)/2;I.mq(a,b,k,l,m,n,g,h);I.mq(m,n,c,d,e,f,g,h)}return h},Op:function(a,b,c,d,e,f,g,h,k,l,m,n,p,q){if(I.Tb(a,b,g,h,p,c,d)&&I.Tb(a,b,g,h,p,e,f)){var r=(a-g)*(l-n)-(b-h)*(k-m);if(0===r)return!1;p=((a*h-b*g)*(k-m)-(a-g)*(k*n-l*m))/r;r=((a*h-b*g)*(l-n)-(b-h)*(k*n-l*m))/r;if((k>m?k-m:m-k)<(l>n?l-n:n-l)){if(b<h?g=b:(g=h,h=b),r<g||r>h)return!1}else if(a<g?h=a:(h=g,g=a),p<h||p>g)return!1;q.h(p,r);return!0}r=
(a+c)/2;var u=(b+d)/2;c=(c+e)/2;d=(d+f)/2;e=(e+g)/2;f=(f+h)/2;var x=(r+c)/2,v=(u+d)/2;c=(c+e)/2;d=(d+f)/2;var y=(x+c)/2,z=(v+d)/2,B=(m-k)*(m-k)+(n-l)*(n-l),C=!1;I.Op(a,b,r,u,x,v,y,z,k,l,m,n,p,q)&&(a=(q.x-k)*(q.x-k)+(q.y-l)*(q.y-l),a<B&&(B=a,C=!0));a=q.x;b=q.y;I.Op(y,z,c,d,e,f,g,h,k,l,m,n,p,q)&&((q.x-k)*(q.x-k)+(q.y-l)*(q.y-l)<B?C=!0:q.h(a,b));return C},Pp:function(a,b,c,d,e,f,g,h,k,l,m,n,p){var q=0;if(I.Tb(a,b,g,h,p,c,d)&&I.Tb(a,b,g,h,p,e,f)){p=(a-g)*(l-n)-(b-h)*(k-m);if(0===p)return q;var r=((a*
h-b*g)*(k-m)-(a-g)*(k*n-l*m))/p,u=((a*h-b*g)*(l-n)-(b-h)*(k*n-l*m))/p;if(r>=m)return q;if((k>m?k-m:m-k)<(l>n?l-n:n-l)){if(b<h?(a=b,b=h):a=h,u<a||u>b)return q}else if(a<g?(b=a,a=g):b=g,r<b||r>a)return q;0<p?q++:0>p&&q--}else{r=(a+c)/2;u=(b+d)/2;var x=(c+e)/2,v=(d+f)/2;e=(e+g)/2;f=(f+h)/2;d=(r+x)/2;c=(u+v)/2;x=(x+e)/2;v=(v+f)/2;var y=(d+x)/2,z=(c+v)/2;q+=I.Pp(a,b,r,u,d,c,y,z,k,l,m,n,p);q+=I.Pp(y,z,x,v,e,f,g,h,k,l,m,n,p)}return q},Si:function(a,b,c,d,e,f,g){if(I.ca(a,c)){b<d?(c=b,b=d):c=d;if(f<c)return g.h(a,
c),!1;if(f>b)return g.h(a,b),!1;g.h(a,f);return!0}if(I.ca(b,d)){a<c?(d=a,a=c):d=c;if(e<d)return g.h(d,b),!1;if(e>a)return g.h(a,b),!1;g.h(e,b);return!0}e=((a-e)*(a-c)+(b-f)*(b-d))/((c-a)*(c-a)+(d-b)*(d-b));if(-5E-6>e)return g.h(a,b),!1;if(1.000005<e)return g.h(c,d),!1;g.h(a+e*(c-a),b+e*(d-b));return!0},Fe:function(a,b,c,d,e,f,g,h,k){if(I.w(a,c)&&I.w(b,d))return k.h(a,b),!1;if(I.ca(e,g))return I.ca(a,c)?(I.Si(a,b,c,d,e,f,k),!1):I.Si(a,b,c,d,e,(d-b)/(c-a)*(e-a)+b,k);h=(h-f)/(g-e);if(I.ca(a,c)){c=h*
(a-e)+f;b<d?(e=b,b=d):e=d;if(c<e)return k.h(a,e),!1;if(c>b)return k.h(a,b),!1;k.h(a,c);return!0}g=(d-b)/(c-a);if(I.ca(h,g))return I.Si(a,b,c,d,e,f,k),!1;e=(g*a-h*e+f-b)/(g-h);if(I.ca(g,0)){a<c?(d=a,a=c):d=c;if(e<d)return k.h(d,b),!1;if(e>a)return k.h(a,b),!1;k.h(e,b);return!0}return I.Si(a,b,c,d,e,g*(e-a)+b,k)},cA:function(a,b,c,d,e){return I.Fe(c.x,c.y,d.x,d.y,a.x,a.y,b.x,b.y,e)},bA:function(a,b,c,d,e,f,g,h,k,l){function m(c,d){var e=(c-a)*(c-a)+(d-b)*(d-b);e<n&&(n=e,k.h(c,d))}var n=Infinity;m(k.x,
k.y);var p=0,q=0,r=0,u=0;e<g?(p=e,q=g):(p=g,q=e);f<h?(r=e,u=g):(r=g,u=e);p=(q-p)/2+l;l=(u-r)/2+l;e=(e+g)/2;f=(f+h)/2;if(0===p||0===l)return k;if(.5>(c>a?c-a:a-c)){p=1-(c-e)*(c-e)/(p*p);if(0>p)return k;p=Math.sqrt(p);d=-l*p+f;m(c,l*p+f);m(c,d)}else{c=(d-b)/(c-a);d=1/(p*p)+c*c/(l*l);h=2*c*(b-c*a)/(l*l)-2*c*f/(l*l)-2*e/(p*p);p=h*h-4*d*(2*c*a*f/(l*l)-2*b*f/(l*l)+f*f/(l*l)+e*e/(p*p)-1+(b-c*a)*(b-c*a)/(l*l));if(0>p)return k;p=Math.sqrt(p);l=(-h+p)/(2*d);m(l,c*l-c*a+b);p=(-h-p)/(2*d);m(p,c*p-c*a+b)}return k},
Tc:function(a,b,c,d,e,f,g,h,k){var l=1E21,m=a,n=b;if(I.Fe(a,b,a,d,e,f,g,h,k)){var p=(k.x-e)*(k.x-e)+(k.y-f)*(k.y-f);p<l&&(l=p,m=k.x,n=k.y)}I.Fe(c,b,c,d,e,f,g,h,k)&&(p=(k.x-e)*(k.x-e)+(k.y-f)*(k.y-f),p<l&&(l=p,m=k.x,n=k.y));I.Fe(a,b,c,b,e,f,g,h,k)&&(b=(k.x-e)*(k.x-e)+(k.y-f)*(k.y-f),b<l&&(l=b,m=k.x,n=k.y));I.Fe(a,d,c,d,e,f,g,h,k)&&(a=(k.x-e)*(k.x-e)+(k.y-f)*(k.y-f),a<l&&(l=a,m=k.x,n=k.y));k.h(m,n);return 1E21>l},aA:function(a,b,c,d,e,f,g,h,k){c=a-c;g=e-g;0===c||0===g?0===c?(b=(f-h)/g,h=a,e=b*h+(f-
b*e)):(f=(b-d)/c,h=e,e=f*h+(b-f*a)):(d=(b-d)/c,h=(f-h)/g,a=b-d*a,h=(f-h*e-a)/(d-h),e=d*h+a);k.h(h,e);return k},Zs:function(a,b,c){var d=b.x,e=b.y,f=c.x,g=c.y,h=a.left,k=a.right,l=a.top,m=a.bottom;return d===f?(e<g?(f=e,e=g):f=g,h<=d&&d<=k&&f<=m&&e>=l):e===g?(d<f?(g=d,d=f):g=f,l<=e&&e<=m&&g<=k&&d>=h):a.da(b)||a.da(c)||I.Ys(h,l,k,l,d,e,f,g)||I.Ys(k,l,k,m,d,e,f,g)||I.Ys(k,m,h,m,d,e,f,g)||I.Ys(h,m,h,l,d,e,f,g)?!0:!1},Ys:function(a,b,c,d,e,f,g,h){return 0>=I.Ls(a,b,c,d,e,f)*I.Ls(a,b,c,d,g,h)&&0>=I.Ls(e,
f,g,h,a,b)*I.Ls(e,f,g,h,c,d)},Ls:function(a,b,c,d,e,f){c-=a;d-=b;a=e-a;b=f-b;f=a*d-b*c;0===f&&(f=a*c+b*d,0<f&&(f=(a-c)*c+(b-d)*d,0>f&&(f=0)));return 0>f?-1:0<f?1:0},jq:function(a){0>a&&(a+=360);360<=a&&(a-=360);return a},Yw:function(a,b,c,d,e,f){var g=Math.PI;f||(d*=g/180,e*=g/180);var h=d>e?-1:1;f=[];var k=g/2,l=d;d=Math.min(2*g,Math.abs(e-d));if(1E-5>d)return k=l+h*Math.min(d,k),h=a+c*Math.cos(l),l=b+c*Math.sin(l),a+=c*Math.cos(k),b+=c*Math.sin(k),c=(h+a)/2,k=(l+b)/2,f.push([h,l,c,k,c,k,a,b]),f;
for(;1E-5<d;)e=l+h*Math.min(d,k),f.push(I.uy(c,l,e,a,b)),d-=Math.abs(e-l),l=e;return f},uy:function(a,b,c,d,e){var f=(c-b)/2,g=a*Math.cos(f),h=a*Math.sin(f),k=-h,l=g*g+k*k,m=l+g*g+k*h;l=4/3*(Math.sqrt(2*l*m)-m)/(g*h-k*g);h=g-l*k;g=k+l*g;k=-g;l=f+b;f=Math.cos(l);l=Math.sin(l);return[d+a*Math.cos(b),e+a*Math.sin(b),d+h*f-g*l,e+h*l+g*f,d+h*f-k*l,e+h*l+k*f,d+a*Math.cos(c),e+a*Math.sin(c)]},Wp:function(a,b,c,d,e,f,g){c=Math.floor((a-c)/e)*e+c;d=Math.floor((b-d)/f)*f+d;var h=c;c+e-a<e/2&&(h=c+e);a=d;d+
f-b<f/2&&(a=d+f);g.h(h,a);return g},ix:function(a,b){var c=Math.max(a,b);a=Math.min(a,b);var d;do b=c%a,c=d=a,a=b;while(0<b);return d},zy:function(a,b,c,d){var e=0>c,f=0>d;if(a<b){var g=1;var h=0}else g=0,h=1;var k=0===g?a:b;var l=0===g?c:d;if(0===g?e:f)l=-l;g=h;c=0===g?c:d;if(0===g?e:f)c=-c;return I.Ay(k,0===g?a:b,l,c,0,0)},Ay:function(a,b,c,d,e,f){if(0<d)if(0<c){e=a*a;f=b*b;a*=c;var g=b*d,h=-f+g,k=-f+Math.sqrt(a*a+g*g);b=h;for(var l=0;9999999999>l;++l){b=.5*(h+k);if(b===h||b===k)break;var m=a/(b+
e),n=g/(b+f);m=m*m+n*n-1;if(0<m)h=b;else if(0>m)k=b;else break}c=e*c/(b+e)-c;d=f*d/(b+f)-d;c=Math.sqrt(c*c+d*d)}else c=Math.abs(d-b);else d=a*a-b*b,f=a*c,f<d?(d=f/d,f=b*Math.sqrt(Math.abs(1-d*d)),c=a*d-c,c=Math.sqrt(c*c+f*f)):c=Math.abs(c-a);return c},Je:new tb,Bm:new tb};I.za=I.Xz;
function $d(a){nb(this);this.v=!1;void 0===a&&(a=ae);this.va=a;this.Bc=this.mc=this.bd=this.ad=0;this.ij=new E;this.mr=this.ij.Ba;this.Vq=(new N).freeze();this.ra=!0;this.Nm=this.zk=null;this.Om=NaN;this.cf=Tc;this.df=hd;this.cl=this.el=NaN;this.Ef=be}
$d.prototype.copy=function(){var a=new $d;a.va=this.va;a.ad=this.ad;a.bd=this.bd;a.mc=this.mc;a.Bc=this.Bc;for(var b=this.ij.j,c=b.length,d=a.ij,e=0;e<c;e++){var f=b[e].copy();d.add(f)}a.mr=this.mr;a.Vq.assign(this.Vq);a.ra=this.ra;a.zk=this.zk;a.Nm=this.Nm;a.Om=this.Om;a.cf=this.cf.I();a.df=this.df.I();a.el=this.el;a.cl=this.cl;a.Ef=this.Ef;return a};t=$d.prototype;t.ga=function(){this.freeze();Object.freeze(this);return this};
t.freeze=function(){this.v=!0;var a=this.figures;a.freeze();a=a.j;for(var b=a.length,c=0;c<b;c++)a[c].freeze();return this};t.ha=function(){Object.isFrozen(this)&&A("cannot thaw constant: "+this);this.v=!1;var a=this.figures;a.ha();a=a.j;for(var b=a.length,c=0;c<b;c++)a[c].ha();return this};
t.Qa=function(a){if(!(a instanceof $d))return!1;if(this.type!==a.type)return this.type===ce&&a.type===ae?de(this,a):a.type===ce&&this.type===ae?de(a,this):!1;if(this.type===ae){var b=this.figures.j;a=a.figures.j;var c=b.length;if(c!==a.length)return!1;for(var d=0;d<c;d++)if(!b[d].Qa(a[d]))return!1;return!0}return I.w(this.startX,a.startX)&&I.w(this.startY,a.startY)&&I.w(this.endX,a.endX)&&I.w(this.endY,a.endY)};
function de(a,b){return a.type!==ce||b.type!==ae?!1:1===b.figures.count&&(b=b.figures.N(0),1===b.segments.count&&I.w(a.startX,b.startX)&&I.w(a.startY,b.startY)&&(b=b.segments.N(0),b.type===ie&&I.w(a.endX,b.endX)&&I.w(a.endY,b.endY)))?!0:!1}function je(a){return a.toString()}t.fb=function(a){a.classType===$d&&(this.type=a)};
t.toString=function(a){void 0===a&&(a=-1);switch(this.type){case ce:return 0>a?"M"+this.startX.toString()+" "+this.startY.toString()+"L"+this.endX.toString()+" "+this.endY.toString():"M"+this.startX.toFixed(a)+" "+this.startY.toFixed(a)+"L"+this.endX.toFixed(a)+" "+this.endY.toFixed(a);case ke:var b=new N(this.startX,this.startY,0,0);b.Vv(this.endX,this.endY,0,0);return 0>a?"M"+b.x.toString()+" "+b.y.toString()+"H"+b.right.toString()+"V"+b.bottom.toString()+"H"+b.left.toString()+"z":"M"+b.x.toFixed(a)+
" "+b.y.toFixed(a)+"H"+b.right.toFixed(a)+"V"+b.bottom.toFixed(a)+"H"+b.left.toFixed(a)+"z";case le:b=new N(this.startX,this.startY,0,0);b.Vv(this.endX,this.endY,0,0);if(0>a)return a=b.left.toString()+" "+(b.y+b.height/2).toString(),"M"+a+"A"+(b.width/2).toString()+" "+(b.height/2).toString()+" 0 0 1 "+(b.right.toString()+" "+(b.y+b.height/2).toString())+"A"+(b.width/2).toString()+" "+(b.height/2).toString()+" 0 0 1 "+a;var c=b.left.toFixed(a)+" "+(b.y+b.height/2).toFixed(a);return"M"+c+"A"+(b.width/
2).toFixed(a)+" "+(b.height/2).toFixed(a)+" 0 0 1 "+(b.right.toFixed(a)+" "+(b.y+b.height/2).toFixed(a))+"A"+(b.width/2).toFixed(a)+" "+(b.height/2).toFixed(a)+" 0 0 1 "+c;case ae:b="";c=this.figures.j;for(var d=c.length,e=0;e<d;e++){var f=c[e];0<e&&(b+=" x ");f.isFilled&&(b+="F ");b+=f.toString(a)}return b;default:return this.type.toString()}};
function me(a,b){function c(){return u>=B-1?!0:null!==k[u+1].match(/[UuBbMmZzLlHhVvCcSsQqTtAaFfXx]/)}function d(){u++;return k[u]}function e(){var a=new H(parseFloat(d()),parseFloat(d()));x===x.toLowerCase()&&(a.x=z.x+a.x,a.y=z.y+a.y);return a}function f(){return z=e()}function g(){return y=e()}function h(){var a=v.toLowerCase();return"c"!==a&&"s"!==a&&"q"!==a&&"t"!==a?z:new H(2*z.x-y.x,2*z.y-y.y)}void 0===b&&(b=!1);a=a.replace(/,/gm," ");a=a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFfXx])([UuBbMmZzLlHhVvCcSsQqTtAaFfXx])/gm,
"$1 $2");a=a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFfXx])([UuBbMmZzLlHhVvCcSsQqTtAaFfXx])/gm,"$1 $2");a=a.replace(/([UuBbMmZzLlHhVvCcSsQqTtAaFfXx])([^\s])/gm,"$1 $2");a=a.replace(/([^\s])([UuBbMmZzLlHhVvCcSsQqTtAaFfXx])/gm,"$1 $2");a=a.replace(/([0-9])([+\-])/gm,"$1 $2");a=a.replace(/([Aa](\s+[0-9]+){3})\s+([01])\s*([01])/gm,"$1 $3 $4 ");a=a.replace(/[\s\r\t\n]+/gm," ");a=a.replace(/^\s+|\s+$/g,"");var k=a.split(" ");for(a=0;a<k.length;a++){var l=k[a];if(null!==l.match(/(\.[0-9]*)(\.)/gm)){for(var m=
Ka(),n="",p=!1,q=0;q<l.length;q++){var r=l[q];"."!==r||p?"."===r?(m.push(n),n="."):n+=r:(p=!0,n+=r)}m.push(n);k.splice(a,1);for(l=0;l<m.length;l++)k.splice(a+l,0,m[l]);a+=m.length-1;Oa(m)}}var u=-1,x="",v="";m=new H(0,0);var y=new H(0,0),z=new H(0,0),B=k.length;a=ne(null);n=l=!1;p=!0;for(q=null;!(u>=B-1);)if(v=x,x=d(),""!==x)switch(x.toUpperCase()){case "X":p=!0;n=l=!1;break;case "M":q=f();null===a.ec||!0===p?(oe(a,q.x,q.y,l,!n),p=!1):a.moveTo(q.x,q.y);for(m=z;!c();)q=f(),a.lineTo(q.x,q.y);break;
case "L":for(;!c();)q=f(),a.lineTo(q.x,q.y);break;case "H":for(;!c();)z=new H((x===x.toLowerCase()?z.x:0)+parseFloat(d()),z.y),a.lineTo(z.x,z.y);break;case "V":for(;!c();)z=new H(z.x,(x===x.toLowerCase()?z.y:0)+parseFloat(d())),a.lineTo(z.x,z.y);break;case "C":for(;!c();){q=e();r=g();var C=f();pe(a,q.x,q.y,r.x,r.y,C.x,C.y)}break;case "S":for(;!c();)q=h(),r=g(),C=f(),pe(a,q.x,q.y,r.x,r.y,C.x,C.y);break;case "Q":for(;!c();)q=g(),r=f(),te(a,q.x,q.y,r.x,r.y);break;case "T":for(;!c();)y=q=h(),r=f(),te(a,
q.x,q.y,r.x,r.y);break;case "B":for(;!c();){q=parseFloat(d());r=parseFloat(d());C=parseFloat(d());var G=parseFloat(d()),K=parseFloat(d()),M=K,U=!1;c()||(M=parseFloat(d()),c()||(U=0!==parseFloat(d())));x===x.toLowerCase()&&(C+=z.x,G+=z.y);a.arcTo(q,r,C,G,K,M,U)}break;case "A":for(;!c();)q=Math.abs(parseFloat(d())),r=Math.abs(parseFloat(d())),C=parseFloat(d()),G=!!parseFloat(d()),K=!!parseFloat(d()),M=f(),ue(a,q,r,C,G,K,M.x,M.y);break;case "Z":ve(a);z=m;break;case "F":q="";for(r=1;k[u+r];)if(null!==
k[u+r].match(/[Uu]/))r++;else if(null===k[u+r].match(/[UuBbMmZzLlHhVvCcSsQqTtAaFfXx]/))r++;else{q=k[u+r];break}q.match(/[Mm]/)?l=!0:0<a.ec.segments.length&&(a.ec.isFilled=!0);break;case "U":q="";for(r=1;k[u+r];)if(null!==k[u+r].match(/[Ff]/))r++;else if(null===k[u+r].match(/[UuBbMmZzLlHhVvCcSsQqTtAaFfXx]/))r++;else{q=k[u+r];break}q.match(/[Mm]/)?n=!0:a.tq(!1)}m=a.Ts;we=a;if(b)for(b=m.figures.iterator;b.next();)b.value.isFilled=!0;return m}
function xe(a,b){for(var c=a.length,d=H.alloc(),e=0;e<c;e++){var f=a[e];d.x=f[0];d.y=f[1];b.ta(d);f[0]=d.x;f[1]=d.y;d.x=f[2];d.y=f[3];b.ta(d);f[2]=d.x;f[3]=d.y;d.x=f[4];d.y=f[5];b.ta(d);f[4]=d.x;f[5]=d.y;d.x=f[6];d.y=f[7];b.ta(d);f[6]=d.x;f[7]=d.y}H.free(d)}t.lv=function(){if(this.ra||this.mr!==this.figures.Ba)return!0;for(var a=this.figures.j,b=a.length,c=0;c<b;c++)if(a[c].lv())return!0;return!1};
$d.prototype.computeBounds=function(){this.ra=!1;this.Nm=this.zk=null;this.Om=NaN;this.mr=this.figures.Ba;for(var a=this.figures.j,b=a.length,c=0;c<b;c++){var d=a[c];d.ra=!1;var e=d.segments;d.ts=e.Ba;d=e.j;e=d.length;for(var f=0;f<e;f++){var g=d[f];g.ra=!1;g.Ke=null}}a=this.Vq;a.ha();isNaN(this.el)||isNaN(this.cl)?a.h(0,0,0,0):a.h(0,0,this.el,this.cl);ye(this,a,!1);Bc(a,0,0,0,0);a.freeze()};$d.prototype.Xw=function(){var a=new N;ye(this,a,!0);return a};
function ye(a,b,c){switch(a.type){case ce:case ke:case le:c?b.h(a.ad,a.bd,0,0):Bc(b,a.ad,a.bd,0,0);Bc(b,a.mc,a.Bc,0,0);break;case ae:var d=a.figures;a=d.j;d=d.length;for(var e=0;e<d;e++){var f=a[e];c&&0===e?b.h(f.startX,f.startY,0,0):Bc(b,f.startX,f.startY,0,0);for(var g=f.segments.j,h=g.length,k=f.startX,l=f.startY,m=0;m<h;m++){var n=g[m];switch(n.type){case ie:case ze:k=n.endX;l=n.endY;Bc(b,k,l,0,0);break;case Ae:I.Sl(k,l,n.point1X,n.point1Y,n.point2X,n.point2Y,n.endX,n.endY,.5,b);k=n.endX;l=n.endY;
break;case Be:I.tv(k,l,n.point1X,n.point1Y,n.endX,n.endY,.5,b);k=n.endX;l=n.endY;break;case Ce:case De:var p=n.type===Ce?Ee(n,f):Fe(n,f,k,l),q=p.length;if(0===q){k=n.centerX;l=n.centerY;Bc(b,k,l,0,0);break}n=null;for(var r=0;r<q;r++)n=p[r],I.Sl(n[0],n[1],n[2],n[3],n[4],n[5],n[6],n[7],.5,b);null!==n&&(k=n[6],l=n[7]);break;default:A("Unknown Segment type: "+n.type)}}}break;default:A("Unknown Geometry type: "+a.type)}}
$d.prototype.normalize=function(){this.v&&wa(this);var a=this.Xw();this.offset(-a.x,-a.y);return new H(-a.x,-a.y)};$d.prototype.offset=function(a,b){this.v&&wa(this);this.transform(1,0,0,1,a,b);return this};$d.prototype.scale=function(a,b){this.v&&wa(this);this.transform(a,0,0,b,0,0);return this};$d.prototype.rotate=function(a,b,c){this.v&&wa(this);void 0===b&&(b=0);void 0===c&&(c=0);var d=Qc.alloc();d.reset();d.rotate(a,b,c);this.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy);Qc.free(d);return this};
t=$d.prototype;
t.transform=function(a,b,c,d,e,f){switch(this.type){case ce:case ke:case le:var g=this.ad;var h=this.bd;this.ad=g*a+h*c+e;this.bd=g*b+h*d+f;g=this.mc;h=this.Bc;this.mc=g*a+h*c+e;this.Bc=g*b+h*d+f;break;case ae:for(var k=this.figures.j,l=k.length,m=0;m<l;m++){var n=k[m];g=n.startX;h=n.startY;n.startX=g*a+h*c+e;n.startY=g*b+h*d+f;n=n.segments.j;for(var p=n.length,q=0;q<p;q++){var r=n[q];switch(r.type){case ie:case ze:g=r.endX;h=r.endY;r.endX=g*a+h*c+e;r.endY=g*b+h*d+f;break;case Ae:g=r.point1X;h=r.point1Y;
r.point1X=g*a+h*c+e;r.point1Y=g*b+h*d+f;g=r.point2X;h=r.point2Y;r.point2X=g*a+h*c+e;r.point2Y=g*b+h*d+f;g=r.endX;h=r.endY;r.endX=g*a+h*c+e;r.endY=g*b+h*d+f;break;case Be:g=r.point1X;h=r.point1Y;r.point1X=g*a+h*c+e;r.point1Y=g*b+h*d+f;g=r.endX;h=r.endY;r.endX=g*a+h*c+e;r.endY=g*b+h*d+f;break;case Ce:g=r.centerX;h=r.centerY;r.centerX=g*a+h*c+e;r.centerY=g*b+h*d+f;0!==b&&(g=180*Math.atan2(b,a)/Math.PI,0>g&&(g+=360),r.startAngle+=g);0>a&&(r.startAngle=180-r.startAngle,r.sweepAngle=-r.sweepAngle);0>d&&
(r.startAngle=-r.startAngle,r.sweepAngle=-r.sweepAngle);r.radiusX*=Math.sqrt(a*a+c*c);void 0!==r.radiusY&&(r.radiusY*=Math.sqrt(b*b+d*d));break;case De:g=r.endX;h=r.endY;r.endX=g*a+h*c+e;r.endY=g*b+h*d+f;0!==b&&(g=180*Math.atan2(b,a)/Math.PI,0>g&&(g+=360),r.xAxisRotation+=g);0>a&&(r.xAxisRotation=180-r.xAxisRotation,r.isClockwiseArc=!r.isClockwiseArc);0>d&&(r.xAxisRotation=-r.xAxisRotation,r.isClockwiseArc=!r.isClockwiseArc);r.radiusX*=Math.sqrt(a*a+c*c);r.radiusY*=Math.sqrt(b*b+d*d);break;default:A("Unknown Segment type: "+
r.type)}}}}this.ra=!0;return this};
t.da=function(a,b,c,d){var e=a.x;a=a.y;for(var f=this.bounds.x-20,g=0,h,k,l,m,n,p=this.figures.j,q=p.length,r=0;r<q;r++){var u=p[r];if(u.isFilled){if(c&&u.da(e,a,b))return!0;var x=u.segments;h=u.startX;k=u.startY;for(var v=h,y=k,z=x.j,B=0;B<=x.length;B++){var C=void 0;if(B!==x.length){C=z[B];var G=C.type;m=C.endX;n=C.endY}else G=ie,m=v,n=y;switch(G){case ze:v=Ge(e,a,f,a,h,k,v,y);if(isNaN(v))return!0;g+=v;v=m;y=n;break;case ie:h=Ge(e,a,f,a,h,k,m,n);if(isNaN(h))return!0;g+=h;break;case Ae:l=I.Pp(h,
k,C.point1X,C.point1Y,C.point2X,C.point2Y,m,n,f,a,e,a,.5);g+=l;break;case Be:l=I.Pp(h,k,(h+2*C.point1X)/3,(k+2*C.point1Y)/3,(2*C.point1X+m)/3,(2*C.point1Y+n)/3,m,n,f,a,e,a,.5);g+=l;break;case Ce:case De:G=C.type===Ce?Ee(C,u):Fe(C,u,h,k);var K=G.length;if(0===K){h=Ge(e,a,f,a,h,k,C.centerX,C.centerY);if(isNaN(h))return!0;g+=h;break}C=null;for(var M=0;M<K;M++){C=G[M];if(0===M){l=Ge(e,a,f,a,h,k,C[0],C[1]);if(isNaN(l))return!0;g+=l}l=I.Pp(C[0],C[1],C[2],C[3],C[4],C[5],C[6],C[7],f,a,e,a,.5);g+=l}null!==
C&&(m=C[6],n=C[7]);break;default:A("Unknown Segment type: "+C.type)}h=m;k=n}if(0!==g)return!0;g=0}else if(u.da(e,a,d?b:b+2))return!0}return 0!==g};function Ge(a,b,c,d,e,f,g,h){if(I.Tb(e,f,g,h,.05,a,b))return NaN;var k=(a-c)*(f-h);if(0===k)return 0;var l=((a*d-b*c)*(e-g)-(a-c)*(e*h-f*g))/k;b=(a*d-b*c)*(f-h)/k;if(l>=a)return 0;if((e>g?e-g:g-e)<(f>h?f-h:h-f))if(f<h){if(b<f||b>h)return 0}else{if(b<h||b>f)return 0}else if(e<g){if(l<e||l>g)return 0}else if(l<g||l>e)return 0;return 0<k?1:-1}
function He(a,b,c,d){a=a.figures.j;for(var e=a.length,f=0;f<e;f++)if(a[f].da(b,c,d))return!0;return!1}
t.cv=function(a,b){0>a?a=0:1<a&&(a=1);void 0===b&&(b=new H);if(this.type===ce)return b.h(this.startX+a*(this.endX-this.startX),this.startY+a*(this.endY-this.startY)),b;for(var c=this.flattenedSegments,d=this.flattenedLengths,e=c.length,f=this.flattenedTotalLength*a,g=0,h=0;h<e;h++){var k=d[h],l=k.length;for(a=0;a<l;a++){var m=k[a];if(g+m>=f)return d=(f-g)/m,c=c[h],e=c[2*a],h=c[2*a+1],b.h(e+(c[2*a+2]-e)*d,h+(c[2*a+3]-h)*d),b;g+=m}}b.h(NaN,NaN);return b};
t.jx=function(a){if(this.type===ce){var b=this.startX,c=this.startY,d=this.endX,e=this.endY;if(b!==d||c!==e){var f=a.x;a=a.y;if(b===d){if(c<e){var g=c;d=e}else g=e,d=c;return a<=g?g===c?0:1:a>=d?d===c?0:1:Math.abs(a-c)/(d-g)}return c===e?(b<d?g=b:(g=d,d=b),f<=g?g===b?0:1:f>=d?d===b?0:1:Math.abs(f-b)/(d-g)):((f-b)*(f-b)+(a-c)*(a-c))/((d-b)*(d-b)+(e-c)*(e-c))}}else if(this.type===ke){g=this.startX;var h=this.startY,k=this.endX;e=this.endY;if(g!==k||h!==e){b=k-g;c=e-h;f=2*b+2*c;d=a.x;a=a.y;d=Math.min(Math.max(d,
g),k);a=Math.min(Math.max(a,h),e);g=Math.abs(d-g);k=Math.abs(d-k);h=Math.abs(a-h);e=Math.abs(a-e);var l=Math.min(g,k,h,e);if(l===h)return d/f;if(l===k)return(b+a)/f;if(l===e)return(2*b+c-d)/f;if(l===g)return(2*b+2*c-a)/f}}else{b=this.flattenedSegments;c=this.flattenedLengths;f=this.flattenedTotalLength;d=H.alloc();e=Infinity;h=g=0;k=b.length;for(var m=l=0,n=0;n<k;n++)for(var p=b[n],q=c[n],r=p.length,u=0;u<r;u+=2){var x=p[u],v=p[u+1];if(0!==u){I.Si(l,m,x,v,a.x,a.y,d);var y=(d.x-a.x)*(d.x-a.x)+(d.y-
a.y)*(d.y-a.y);y<e&&(e=y,g=h,g+=Math.sqrt((d.x-l)*(d.x-l)+(d.y-m)*(d.y-m)));h+=q[(u-2)/2]}l=x;m=v}H.free(d);a=g/f;return 0>a?0:1<a?1:a}return 0};
function Te(a){if(null===a.zk){var b=a.zk=[],c=a.Nm=[],d=[],e=[];if(a.type===ce)d.push(a.startX),d.push(a.startY),d.push(a.endX),d.push(a.endY),b.push(d),e.push(Math.sqrt((a.startX-a.endX)*(a.startX-a.endX)+(a.startY-a.endY)*(a.startY-a.endY))),c.push(e);else if(a.type===ke)d.push(a.startX),d.push(a.startY),d.push(a.endX),d.push(a.startY),d.push(a.endX),d.push(a.endY),d.push(a.startX),d.push(a.endY),d.push(a.startX),d.push(a.startY),b.push(d),e.push(Math.abs(a.startX-a.endX)),e.push(Math.abs(a.startY-
a.endY)),e.push(Math.abs(a.startX-a.endX)),e.push(Math.abs(a.startY-a.endY)),c.push(e);else if(a.type===le){var f=new Ue;f.startX=a.endX;f.startY=(a.startY+a.endY)/2;var g=new Ve(Ce);g.startAngle=0;g.sweepAngle=360;g.centerX=(a.startX+a.endX)/2;g.centerY=(a.startY+a.endY)/2;g.radiusX=Math.abs(a.startX-a.endX)/2;g.radiusY=Math.abs(a.startY-a.endY)/2;f.add(g);a=Ee(g,f);e=a.length;if(0===e)d.push(g.centerX),d.push(g.centerY);else{g=f.startX;f=f.startY;for(var h=0;h<e;h++){var k=a[h];I.ze(g,f,k[2],k[3],
k[4],k[5],k[6],k[7],.5,d);g=k[6];f=k[7]}}b.push(d);c.push(We(d))}else for(a=a.figures.iterator;a.next();){e=a.value;d=[];d.push(e.startX);d.push(e.startY);g=e.startX;f=e.startY;h=g;k=f;for(var l=e.segments.j,m=l.length,n=0;n<m;n++){var p=l[n];switch(p.type){case ze:4<=d.length&&(b.push(d),c.push(We(d)));d=[];d.push(p.endX);d.push(p.endY);g=p.endX;f=p.endY;h=g;k=f;break;case ie:d.push(p.endX);d.push(p.endY);g=p.endX;f=p.endY;break;case Ae:I.ze(g,f,p.point1X,p.point1Y,p.point2X,p.point2Y,p.endX,p.endY,
.5,d);g=p.endX;f=p.endY;break;case Be:I.mq(g,f,p.point1X,p.point1Y,p.endX,p.endY,.5,d);g=p.endX;f=p.endY;break;case Ce:var q=Ee(p,e),r=q.length;if(0===r){d.push(p.centerX);d.push(p.centerY);g=p.centerX;f=p.centerY;break}for(var u=0;u<r;u++){var x=q[u];I.ze(g,f,x[2],x[3],x[4],x[5],x[6],x[7],.5,d);g=x[6];f=x[7]}break;case De:q=Fe(p,e,g,f);r=q.length;if(0===r){d.push(p.centerX);d.push(p.centerY);g=p.centerX;f=p.centerY;break}for(u=0;u<r;u++)x=q[u],I.ze(g,f,x[2],x[3],x[4],x[5],x[6],x[7],.5,d),g=x[6],
f=x[7];break;default:A("Segment not of valid type: "+p.type)}p.isClosed&&(d.push(h),d.push(k))}4<=d.length&&(b.push(d),c.push(We(d)))}}}function We(a){for(var b=[],c=0,d=0,e=a.length,f=0;f<e;f+=2){var g=a[f],h=a[f+1];0!==f&&b.push(Math.sqrt(Sb(c,d,g,h)));c=g;d=h}return b}t.add=function(a){this.ij.add(a);return this};t.xm=function(a,b,c,d,e,f,g,h){this.v&&wa(this);this.cf=(new O(a,b,e,f)).freeze();this.df=(new O(c,d,g,h)).freeze();return this};
pa.Object.defineProperties($d.prototype,{flattenedSegments:{get:function(){Te(this);return this.zk}},flattenedLengths:{get:function(){Te(this);return this.Nm}},flattenedTotalLength:{get:function(){var a=this.Om;if(isNaN(a)){if(this.type===ce){a=Math.abs(this.endX-this.startX);var b=Math.abs(this.endY-this.startY);a=Math.sqrt(a*a+b*b)}else if(this.type===ke)a=2*Math.abs(this.endX-this.startX)+2*Math.abs(this.endY-
this.startY);else{b=this.flattenedLengths;for(var c=b.length,d=a=0;d<c;d++)for(var e=b[d],f=e.length,g=0;g<f;g++)a+=e[g]}this.Om=a}return a}},type:{get:function(){return this.va},set:function(a){this.va!==a&&(this.v&&wa(this,a),this.va=a,this.ra=!0)}},startX:{get:function(){return this.ad},set:function(a){this.ad!==a&&(this.v&&wa(this,a),this.ad=a,this.ra=!0)}},startY:{get:function(){return this.bd},set:function(a){this.bd!==
a&&(this.v&&wa(this,a),this.bd=a,this.ra=!0)}},endX:{get:function(){return this.mc},set:function(a){this.mc!==a&&(this.v&&wa(this,a),this.mc=a,this.ra=!0)}},endY:{get:function(){return this.Bc},set:function(a){this.Bc!==a&&(this.v&&wa(this,a),this.Bc=a,this.ra=!0)}},figures:{get:function(){return this.ij},set:function(a){this.ij!==a&&(this.v&&wa(this,a),this.ij=a,this.ra=!0)}},spot1:{
get:function(){return this.cf},set:function(a){this.v&&wa(this,a);this.cf=a.I()}},spot2:{get:function(){return this.df},set:function(a){this.v&&wa(this,a);this.df=a.I()}},defaultStretch:{get:function(){return this.Ef},set:function(a){this.v&&wa(this,a);this.Ef=a}},bounds:{get:function(){this.lv()&&this.computeBounds();return this.Vq}}});$d.prototype.setSpots=$d.prototype.xm;$d.prototype.add=$d.prototype.add;
$d.prototype.getFractionForPoint=$d.prototype.jx;$d.prototype.getPointAlongPath=$d.prototype.cv;$d.prototype.transform=$d.prototype.transform;$d.prototype.rotate=$d.prototype.rotate;$d.prototype.scale=$d.prototype.scale;$d.prototype.offset=$d.prototype.offset;$d.prototype.normalize=$d.prototype.normalize;$d.prototype.computeBoundsWithoutOrigin=$d.prototype.Xw;$d.prototype.equalsApprox=$d.prototype.Qa;
var ce=new D($d,"Line",0),ke=new D($d,"Rectangle",1),le=new D($d,"Ellipse",2),ae=new D($d,"Path",3);$d.className="Geometry";$d.stringify=je;$d.fillPath=function(a){a=a.split(/[Xx]/);for(var b=a.length,c="",d=0;d<b;d++){var e=a[d];c=null!==e.match(/[Ff]/)?0===d?c+e:c+("X"+(" "===e[0]?"":" ")+e):c+((0===d?"":"X ")+"F"+(" "===e[0]?"":" ")+e)}return c};$d.parse=me;$d.Line=ce;$d.Rectangle=ke;$d.Ellipse=le;$d.Path=ae;
function Ue(a,b,c,d){nb(this);this.v=!1;void 0===c&&(c=!0);this.Cr=c;void 0===d&&(d=!0);this.Gr=d;void 0!==a?this.ad=a:this.ad=0;void 0!==b?this.bd=b:this.bd=0;this.Bl=new E;this.ts=this.Bl.Ba;this.ra=!0}Ue.prototype.copy=function(){var a=new Ue;a.Cr=this.Cr;a.Gr=this.Gr;a.ad=this.ad;a.bd=this.bd;for(var b=this.Bl.j,c=b.length,d=a.Bl,e=0;e<c;e++){var f=b[e].copy();d.add(f)}a.ts=this.ts;a.ra=this.ra;return a};t=Ue.prototype;
t.Qa=function(a){if(!(a instanceof Ue&&I.w(this.startX,a.startX)&&I.w(this.startY,a.startY)))return!1;var b=this.segments.j;a=a.segments.j;var c=b.length;if(c!==a.length)return!1;for(var d=0;d<c;d++)if(!b[d].Qa(a[d]))return!1;return!0};t.toString=function(a){void 0===a&&(a=-1);var b=0>a?"M"+this.startX.toString()+" "+this.startY.toString():"M"+this.startX.toFixed(a)+" "+this.startY.toFixed(a);for(var c=this.segments.j,d=c.length,e=0;e<d;e++)b+=" "+c[e].toString(a);return b};
t.freeze=function(){this.v=!0;var a=this.segments;a.freeze();var b=a.j;a=a.length;for(var c=0;c<a;c++)b[c].freeze();return this};t.ha=function(){this.v=!1;var a=this.segments;a.ha();a=a.j;for(var b=a.length,c=0;c<b;c++)a[c].ha();return this};t.lv=function(){if(this.ra)return!0;var a=this.segments;if(this.ts!==a.Ba)return!0;a=a.j;for(var b=a.length,c=0;c<b;c++)if(a[c].ra)return!0;return!1};t.add=function(a){this.Bl.add(a);return this};
t.da=function(a,b,c){for(var d=this.startX,e=this.startY,f=d,g=e,h=this.segments.j,k=h.length,l=0;l<k;l++){var m=h[l];switch(m.type){case ze:f=m.endX;g=m.endY;d=m.endX;e=m.endY;break;case ie:if(I.Tb(d,e,m.endX,m.endY,c,a,b))return!0;d=m.endX;e=m.endY;break;case Ae:if(I.Js(d,e,m.point1X,m.point1Y,m.point2X,m.point2Y,m.endX,m.endY,.5,a,b,c))return!0;d=m.endX;e=m.endY;break;case Be:if(I.uv(d,e,m.point1X,m.point1Y,m.endX,m.endY,.5,a,b,c))return!0;d=m.endX;e=m.endY;break;case Ce:case De:var n=m.type===
Ce?Ee(m,this):Fe(m,this,d,e),p=n.length;if(0===p){if(I.Tb(d,e,m.centerX,m.centerY,c,a,b))return!0;d=m.centerX;e=m.centerY;break}for(var q=null,r=0;r<p;r++)if(q=n[r],0===r&&I.Tb(d,e,q[0],q[1],c,a,b)||I.Js(q[0],q[1],q[2],q[3],q[4],q[5],q[6],q[7],.5,a,b,c))return!0;null!==q&&(d=q[6],e=q[7]);break;default:A("Unknown Segment type: "+m.type)}if(m.isClosed&&(d!==f||e!==g)&&I.Tb(d,e,f,g,c,a,b))return!0}return!1};
pa.Object.defineProperties(Ue.prototype,{isFilled:{get:function(){return this.Cr},set:function(a){this.v&&wa(this,a);this.Cr=a}},isShadowed:{get:function(){return this.Gr},set:function(a){this.v&&wa(this,a);this.Gr=a}},startX:{get:function(){return this.ad},set:function(a){this.v&&wa(this,a);this.ad=a;this.ra=!0}},startY:{get:function(){return this.bd},set:function(a){this.v&&wa(this,
a);this.bd=a;this.ra=!0}},segments:{get:function(){return this.Bl},set:function(a){this.v&&wa(this,a);this.Bl=a;this.ra=!0}}});Ue.prototype.add=Ue.prototype.add;Ue.prototype.equalsApprox=Ue.prototype.Qa;Ue.className="PathFigure";
function Ve(a,b,c,d,e,f,g,h){nb(this);this.v=!1;void 0===a&&(a=ie);this.va=a;void 0!==b?this.mc=b:this.mc=0;void 0!==c?this.Bc=c:this.Bc=0;void 0===d&&(d=0);void 0===e&&(e=0);void 0===f&&(f=0);void 0===g&&(g=0);a===De?(a=f%360,0>a&&(a+=360),this.te=a,this.oi=0,this.pi=Math.max(d,0),this.$g=Math.max(e,0),this.il="boolean"===typeof g?!!g:!1,this.Fk=!!h):(this.te=d,this.oi=e,a===Ce&&(f=Math.max(f,0)),this.pi=f,"number"===typeof g?(a===Ce&&(g=Math.max(g,0)),this.$g=g):this.$g=0,this.Fk=this.il=!1);this.nj=
!1;this.ra=!0;this.Ke=null}Ve.prototype.copy=function(){var a=new Ve;a.va=this.va;a.mc=this.mc;a.Bc=this.Bc;a.te=this.te;a.oi=this.oi;a.pi=this.pi;a.$g=this.$g;a.il=this.il;a.Fk=this.Fk;a.nj=this.nj;a.ra=this.ra;return a};t=Ve.prototype;
t.Qa=function(a){if(!(a instanceof Ve)||this.type!==a.type||this.isClosed!==a.isClosed)return!1;switch(this.type){case ze:case ie:return I.w(this.endX,a.endX)&&I.w(this.endY,a.endY);case Ae:return I.w(this.endX,a.endX)&&I.w(this.endY,a.endY)&&I.w(this.point1X,a.point1X)&&I.w(this.point1Y,a.point1Y)&&I.w(this.point2X,a.point2X)&&I.w(this.point2Y,a.point2Y);case Be:return I.w(this.endX,a.endX)&&I.w(this.endY,a.endY)&&I.w(this.point1X,a.point1X)&&I.w(this.point1Y,a.point1Y);case Ce:return I.w(this.startAngle,
a.startAngle)&&I.w(this.sweepAngle,a.sweepAngle)&&I.w(this.centerX,a.centerX)&&I.w(this.centerY,a.centerY)&&I.w(this.radiusX,a.radiusX)&&I.w(this.radiusY,a.radiusY);case De:return this.isClockwiseArc===a.isClockwiseArc&&this.isLargeArc===a.isLargeArc&&I.w(this.xAxisRotation,a.xAxisRotation)&&I.w(this.endX,a.endX)&&I.w(this.endY,a.endY)&&I.w(this.radiusX,a.radiusX)&&I.w(this.radiusY,a.radiusY);default:return!1}};t.fb=function(a){a.classType===Ve&&(this.type=a)};
t.toString=function(a){void 0===a&&(a=-1);switch(this.type){case ze:a=0>a?"M"+this.endX.toString()+" "+this.endY.toString():"M"+this.endX.toFixed(a)+" "+this.endY.toFixed(a);break;case ie:a=0>a?"L"+this.endX.toString()+" "+this.endY.toString():"L"+this.endX.toFixed(a)+" "+this.endY.toFixed(a);break;case Ae:a=0>a?"C"+this.point1X.toString()+" "+this.point1Y.toString()+" "+this.point2X.toString()+" "+this.point2Y.toString()+" "+this.endX.toString()+" "+this.endY.toString():"C"+this.point1X.toFixed(a)+
" "+this.point1Y.toFixed(a)+" "+this.point2X.toFixed(a)+" "+this.point2Y.toFixed(a)+" "+this.endX.toFixed(a)+" "+this.endY.toFixed(a);break;case Be:a=0>a?"Q"+this.point1X.toString()+" "+this.point1Y.toString()+" "+this.endX.toString()+" "+this.endY.toString():"Q"+this.point1X.toFixed(a)+" "+this.point1Y.toFixed(a)+" "+this.endX.toFixed(a)+" "+this.endY.toFixed(a);break;case Ce:a=0>a?"B"+this.startAngle.toString()+" "+this.sweepAngle.toString()+" "+this.centerX.toString()+" "+this.centerY.toString()+
" "+this.radiusX.toString()+" "+this.radiusY.toString():"B"+this.startAngle.toFixed(a)+" "+this.sweepAngle.toFixed(a)+" "+this.centerX.toFixed(a)+" "+this.centerY.toFixed(a)+" "+this.radiusX.toFixed(a)+" "+this.radiusY.toFixed(a);break;case De:a=0>a?"A"+this.radiusX.toString()+" "+this.radiusY.toString()+" "+this.xAxisRotation.toString()+" "+(this.isLargeArc?1:0)+" "+(this.isClockwiseArc?1:0)+" "+this.endX.toString()+" "+this.endY.toString():"A"+this.radiusX.toFixed(a)+" "+this.radiusY.toFixed(a)+
" "+this.xAxisRotation.toFixed(a)+" "+(this.isLargeArc?1:0)+" "+(this.isClockwiseArc?1:0)+" "+this.endX.toFixed(a)+" "+this.endY.toFixed(a);break;default:a=this.type.toString()}return a+(this.nj?"z":"")};t.freeze=function(){this.v=!0;return this};t.ha=function(){this.v=!1;return this};t.close=function(){this.nj=!0;return this};
function Ee(a,b){if(null!==a.Ke&&!1===b.ra)return a.Ke;var c=a.radiusX,d=a.radiusY;void 0===d&&(d=c);if(0===c||0===d)return a.Ke=[],a.Ke;b=a.te;var e=a.oi,f=I.Yw(0,0,c<d?c:d,a.startAngle,a.startAngle+a.sweepAngle,!1);if(c!==d){var g=Qc.alloc();g.reset();c<d?g.scale(1,d/c):g.scale(c/d,1);xe(f,g);Qc.free(g)}c=f.length;for(d=0;d<c;d++)g=f[d],g[0]+=b,g[1]+=e,g[2]+=b,g[3]+=e,g[4]+=b,g[5]+=e,g[6]+=b,g[7]+=e;a.Ke=f;return a.Ke}
function Fe(a,b,c,d){function e(a,b,c,d){return(a*d<b*c?-1:1)*Math.acos((a*c+b*d)/(Math.sqrt(a*a+b*b)*Math.sqrt(c*c+d*d)))}if(null!==a.Ke&&!1===b.ra)return a.Ke;b=a.pi;var f=a.$g;0===b&&(b=1E-4);0===f&&(f=1E-4);var g=Math.PI/180*a.te,h=a.il,k=a.Fk,l=a.mc,m=a.Bc,n=Math.cos(g),p=Math.sin(g),q=n*(c-l)/2+p*(d-m)/2;g=-p*(c-l)/2+n*(d-m)/2;var r=q*q/(b*b)+g*g/(f*f);1<r&&(b*=Math.sqrt(r),f*=Math.sqrt(r));r=(h===k?-1:1)*Math.sqrt((b*b*f*f-b*b*g*g-f*f*q*q)/(b*b*g*g+f*f*q*q));isNaN(r)&&(r=0);h=r*b*g/f;r=r*-f*
q/b;isNaN(h)&&(h=0);isNaN(r)&&(r=0);c=(c+l)/2+n*h-p*r;d=(d+m)/2+p*h+n*r;m=e(1,0,(q-h)/b,(g-r)/f);n=(q-h)/b;l=(g-r)/f;q=(-q-h)/b;h=(-g-r)/f;g=e(n,l,q,h);q=(n*q+l*h)/(Math.sqrt(n*n+l*l)*Math.sqrt(q*q+h*h));-1>=q?g=Math.PI:1<=q&&(g=0);!k&&0<g&&(g-=2*Math.PI);k&&0>g&&(g+=2*Math.PI);k=b>f?1:b/f;q=b>f?f/b:1;b=I.Yw(0,0,b>f?b:f,m,m+g,!0);f=Qc.alloc();f.reset();f.translate(c,d);f.rotate(a.te,0,0);f.scale(k,q);xe(b,f);Qc.free(f);a.Ke=b;return a.Ke}
pa.Object.defineProperties(Ve.prototype,{isClosed:{get:function(){return this.nj},set:function(a){this.nj!==a&&(this.nj=a,this.ra=!0)}},type:{get:function(){return this.va},set:function(a){this.v&&wa(this,a);this.va=a;this.ra=!0}},endX:{get:function(){return this.mc},set:function(a){this.v&&wa(this,a);this.mc=a;this.ra=!0}},endY:{get:function(){return this.Bc},set:function(a){this.v&&
wa(this,a);this.Bc=a;this.ra=!0}},point1X:{get:function(){return this.te},set:function(a){this.v&&wa(this,a);this.te=a;this.ra=!0}},point1Y:{get:function(){return this.oi},set:function(a){this.v&&wa(this,a);this.oi=a;this.ra=!0}},point2X:{get:function(){return this.pi},set:function(a){this.v&&wa(this,a);this.pi=a;this.ra=!0}},point2Y:{get:function(){return this.$g},set:function(a){this.v&&
wa(this,a);this.$g=a;this.ra=!0}},centerX:{get:function(){return this.te},set:function(a){this.v&&wa(this,a);this.te=a;this.ra=!0}},centerY:{get:function(){return this.oi},set:function(a){this.v&&wa(this,a);this.oi=a;this.ra=!0}},radiusX:{get:function(){return this.pi},set:function(a){0>a&&ya(a,">= zero",Ve,"radiusX");this.v&&wa(this,a);this.pi=a;this.ra=!0}},radiusY:{get:function(){return this.$g},
set:function(a){0>a&&ya(a,">= zero",Ve,"radiusY");this.v&&wa(this,a);this.$g=a;this.ra=!0}},startAngle:{get:function(){return this.mc},set:function(a){this.mc!==a&&(this.v&&wa(this,a),a%=360,0>a&&(a+=360),this.mc=a,this.ra=!0)}},sweepAngle:{get:function(){return this.Bc},set:function(a){this.v&&wa(this,a);360<a&&(a=360);-360>a&&(a=-360);this.Bc=a;this.ra=!0}},isClockwiseArc:{get:function(){return this.Fk},set:function(a){this.v&&
wa(this,a);this.Fk=a;this.ra=!0}},isLargeArc:{get:function(){return this.il},set:function(a){this.v&&wa(this,a);this.il=a;this.ra=!0}},xAxisRotation:{get:function(){return this.te},set:function(a){a%=360;0>a&&(a+=360);this.v&&wa(this,a);this.te=a;this.ra=!0}}});Ve.prototype.equalsApprox=Ve.prototype.Qa;
var ze=new D(Ve,"Move",0),ie=new D(Ve,"Line",1),Ae=new D(Ve,"Bezier",2),Be=new D(Ve,"QuadraticBezier",3),Ce=new D(Ve,"Arc",4),De=new D(Ve,"SvgArc",4);Ve.className="PathSegment";Ve.Move=ze;Ve.Line=ie;Ve.Bezier=Ae;Ve.QuadraticBezier=Be;Ve.Arc=Ce;Ve.SvgArc=De;function Xe(){this.C=null;this.Bu=(new H(0,0)).freeze();this.St=(new H(0,0)).freeze();this.Oq=this.Vr=0;this.Pq=1;this.Kr="";this.Es=this.fr=!1;this.er=this.Rq=0;this.Cg=this.tr=this.Er=!1;this.lr=null;this.Cs=0;this.Qd=this.Bs=null}
Xe.prototype.copy=function(){var a=new Xe;return this.clone(a)};Xe.prototype.clone=function(a){a.C=this.C;a.Bu.assign(this.viewPoint);a.St.assign(this.documentPoint);a.Vr=this.Vr;a.Oq=this.Oq;a.Pq=this.Pq;a.Kr=this.Kr;a.fr=this.fr;a.Es=this.Es;a.Rq=this.Rq;a.er=this.er;a.Er=this.Er;a.tr=this.tr;a.Cg=this.Cg;a.lr=this.lr;a.Cs=this.Cs;a.Bs=this.Bs;a.Qd=this.Qd;return a};
Xe.prototype.toString=function(){var a="^";0!==this.modifiers&&(a+="M:"+this.modifiers);0!==this.button&&(a+="B:"+this.button);""!==this.key&&(a+="K:"+this.key);0!==this.clickCount&&(a+="C:"+this.clickCount);0!==this.delta&&(a+="D:"+this.delta);this.handled&&(a+="h");this.bubbles&&(a+="b");null!==this.documentPoint&&(a+="@"+this.documentPoint.toString());return a};Xe.prototype.Zp=function(a,b){var c=this.diagram;if(null===c)return b;Ye(c,this.event,a,b);return b};
Xe.prototype.$y=function(a,b){var c=this.diagram;if(null===c)return b;Ye(c,this.event,a,b);b.assign(c.xt(b));return b};
pa.Object.defineProperties(Xe.prototype,{diagram:{get:function(){return this.C},set:function(a){this.C=a}},viewPoint:{get:function(){return this.Bu},set:function(a){this.Bu.assign(a)}},documentPoint:{get:function(){return this.St},set:function(a){this.St.assign(a)}},modifiers:{get:function(){return this.Vr},set:function(a){this.Vr=a}},button:{get:function(){return this.Oq},
set:function(a){this.Oq=a;if(null===this.event)switch(a){case 0:this.buttons=1;break;case 1:this.buttons=4;break;case 2:this.buttons=2}}},buttons:{get:function(){return this.Pq},set:function(a){this.Pq=a}},key:{get:function(){return this.Kr},set:function(a){this.Kr=a}},down:{get:function(){return this.fr},set:function(a){this.fr=a}},up:{get:function(){return this.Es},set:function(a){this.Es=
a}},clickCount:{get:function(){return this.Rq},set:function(a){this.Rq=a}},delta:{get:function(){return this.er},set:function(a){this.er=a}},isMultiTouch:{get:function(){return this.Er},set:function(a){this.Er=a}},handled:{get:function(){return this.tr},set:function(a){this.tr=a}},bubbles:{get:function(){return this.Cg},set:function(a){this.Cg=a}},event:{
get:function(){return this.lr},set:function(a){this.lr=a}},isTouchEvent:{get:function(){var a=w.TouchEvent,b=this.event;return a&&b instanceof a?!0:(a=w.PointerEvent)&&b instanceof a&&("touch"===b.pointerType||"pen"===b.pointerType)}},timestamp:{get:function(){return this.Cs},set:function(a){this.Cs=a}},targetDiagram:{get:function(){return this.Bs},set:function(a){this.Bs=a}},targetObject:{
get:function(){return this.Qd},set:function(a){this.Qd=a}},control:{get:function(){return 0!==(this.modifiers&1)},set:function(a){this.modifiers=a?this.modifiers|1:this.modifiers&-2}},shift:{get:function(){return 0!==(this.modifiers&4)},set:function(a){this.modifiers=a?this.modifiers|4:this.modifiers&-5}},alt:{get:function(){return 0!==(this.modifiers&2)},set:function(a){this.modifiers=a?this.modifiers|
2:this.modifiers&-3}},meta:{get:function(){return 0!==(this.modifiers&8)},set:function(a){this.modifiers=a?this.modifiers|8:this.modifiers&-9}},left:{get:function(){var a=this.event;return null===a||"mousedown"!==a.type&&"mouseup"!==a.type&&"pointerdown"!==a.type&&"pointerup"!==a.type?0!==(this.buttons&1):0===this.button},set:function(a){this.buttons=a?this.buttons|1:this.buttons&-2}},right:{get:function(){var a=
this.event;return null===a||"mousedown"!==a.type&&"mouseup"!==a.type&&"pointerdown"!==a.type&&"pointerup"!==a.type?0!==(this.buttons&2):2===this.button},set:function(a){this.buttons=a?this.buttons|2:this.buttons&-3}},middle:{get:function(){var a=this.event;return null===a||"mousedown"!==a.type&&"mouseup"!==a.type&&"pointerdown"!==a.type&&"pointerup"!==a.type?0!==(this.buttons&4):1===this.button},set:function(a){this.buttons=a?this.buttons|4:this.buttons&-5}}});
Xe.prototype.getMultiTouchDocumentPoint=Xe.prototype.$y;Xe.prototype.getMultiTouchViewPoint=Xe.prototype.Zp;Xe.className="InputEvent";function Ze(){this.C=null;this.Ua="";this.fs=this.zs=null}Ze.prototype.copy=function(){var a=new Ze;a.C=this.C;a.Ua=this.Ua;a.zs=this.zs;a.fs=this.fs;return a};Ze.prototype.toString=function(){var a="*"+this.name;null!==this.subject&&(a+=":"+this.subject.toString());null!==this.parameter&&(a+="("+this.parameter.toString()+")");return a};
pa.Object.defineProperties(Ze.prototype,{diagram:{get:function(){return this.C},set:function(a){this.C=a}},name:{get:function(){return this.Ua},set:function(a){this.Ua=a}},subject:{get:function(){return this.zs},set:function(a){this.zs=a}},parameter:{get:function(){return this.fs},set:function(a){this.fs=a}}});Ze.className="DiagramEvent";
function $e(){this.Vm=af;this.Fj=this.Ur="";this.Bo=this.Co=this.Ho=this.Io=this.Go=this.C=this.Zb=null}$e.prototype.clear=function(){this.Bo=this.Co=this.Ho=this.Io=this.Go=this.C=this.Zb=null};
$e.prototype.copy=function(){var a=new $e;a.Vm=this.Vm;a.Ur=this.Ur;a.Fj=this.Fj;a.Zb=this.Zb;a.C=this.C;a.Go=this.Go;var b=this.Io;a.Io=Ca(b)&&"function"===typeof b.I?b.I():b;b=this.Ho;a.Ho=Ca(b)&&"function"===typeof b.I?b.I():b;b=this.Co;a.Co=Ca(b)&&"function"===typeof b.I?b.I():b;b=this.Bo;a.Bo=Ca(b)&&"function"===typeof b.I?b.I():b;return a};$e.prototype.fb=function(a){a.classType===$e&&(this.change=a)};
$e.prototype.toString=function(){var a="";a=this.change===bf?a+"* ":this.change===af?a+(null!==this.model?"!m":"!d"):a+((null!==this.model?"!m":"!d")+this.change);this.propertyName&&"string"===typeof this.propertyName&&(a+=" "+this.propertyName);this.modelChange&&this.modelChange!==this.propertyName&&(a+=" "+this.modelChange);a+=": ";this.change===bf?null!==this.oldValue&&(a+=" "+this.oldValue):(null!==this.object&&(a+=Qa(this.object)),null!==this.oldValue&&(a+="  old: "+Qa(this.oldValue)),null!==
this.oldParam&&(a+=" "+this.oldParam),null!==this.newValue&&(a+="  new: "+Qa(this.newValue)),null!==this.newParam&&(a+=" "+this.newParam));return a};$e.prototype.J=function(a){return a?this.oldValue:this.newValue};$e.prototype.bz=function(a){return a?this.oldParam:this.newParam};$e.prototype.canUndo=function(){return null!==this.model||null!==this.diagram?!0:!1};$e.prototype.undo=function(){this.canUndo()&&(null!==this.model?this.model.Tj(this,!0):null!==this.diagram&&this.diagram.Tj(this,!0))};
$e.prototype.canRedo=function(){return null!==this.model||null!==this.diagram?!0:!1};$e.prototype.redo=function(){this.canRedo()&&(null!==this.model?this.model.Tj(this,!1):null!==this.diagram&&this.diagram.Tj(this,!1))};
pa.Object.defineProperties($e.prototype,{model:{get:function(){return this.Zb},set:function(a){this.Zb=a}},diagram:{get:function(){return this.C},set:function(a){this.C=a}},change:{get:function(){return this.Vm},set:function(a){this.Vm=a}},modelChange:{get:function(){return this.Ur},set:function(a){this.Ur=a}},propertyName:{get:function(){return this.Fj},
set:function(a){this.Fj=a}},isTransactionFinished:{get:function(){return this.Vm===bf&&("CommittedTransaction"===this.Fj||"FinishedUndo"===this.Fj||"FinishedRedo"===this.Fj)}},object:{get:function(){return this.Go},set:function(a){this.Go=a}},oldValue:{get:function(){return this.Io},set:function(a){this.Io=a}},oldParam:{get:function(){return this.Ho},set:function(a){this.Ho=a}},
newValue:{get:function(){return this.Co},set:function(a){this.Co=a}},newParam:{get:function(){return this.Bo},set:function(a){this.Bo=a}}});$e.prototype.redo=$e.prototype.redo;$e.prototype.canRedo=$e.prototype.canRedo;$e.prototype.undo=$e.prototype.undo;$e.prototype.canUndo=$e.prototype.canUndo;$e.prototype.getParam=$e.prototype.bz;$e.prototype.getValue=$e.prototype.J;$e.prototype.clear=$e.prototype.clear;
var bf=new D($e,"Transaction",-1),af=new D($e,"Property",0),cf=new D($e,"Insert",1),df=new D($e,"Remove",2);$e.className="ChangedEvent";$e.Transaction=bf;$e.Property=af;$e.Insert=cf;$e.Remove=df;function ef(){this.u=(new E).freeze();this.Ua="";this.l=!1}ef.prototype.toString=function(a){var b="Transaction: "+this.name+" "+this.changes.count.toString()+(this.isComplete?"":", incomplete");if(void 0!==a&&0<a){a=this.changes.count;for(var c=0;c<a;c++){var d=this.changes.N(c);null!==d&&(b+="\n  "+d.toString())}}return b};
ef.prototype.clear=function(){var a=this.changes;a.ha();for(var b=a.count-1;0<=b;b--){var c=a.N(b);null!==c&&c.clear()}a.clear();a.freeze()};ef.prototype.canUndo=function(){return this.isComplete};ef.prototype.undo=function(){if(this.canUndo())for(var a=this.changes.count-1;0<=a;a--){var b=this.changes.N(a);null!==b&&b.undo()}};ef.prototype.canRedo=function(){return this.isComplete};
ef.prototype.redo=function(){if(this.canRedo())for(var a=this.changes.count,b=0;b<a;b++){var c=this.changes.N(b);null!==c&&c.redo()}};pa.Object.defineProperties(ef.prototype,{changes:{get:function(){return this.u}},name:{get:function(){return this.Ua},set:function(a){this.Ua=a}},isComplete:{get:function(){return this.l},set:function(a){this.l=a}}});ef.prototype.redo=ef.prototype.redo;ef.prototype.canRedo=ef.prototype.canRedo;
ef.prototype.undo=ef.prototype.undo;ef.prototype.canUndo=ef.prototype.canUndo;ef.prototype.clear=ef.prototype.clear;ef.className="Transaction";function ff(){this.iu=new F;this.Dc=!1;this.K=(new E).freeze();this.fe=-1;this.u=999;this.he=!1;this.cr=null;this.Ai=0;this.l=!1;this.qe=(new E).freeze();this.sl=new E;this.Zt=!0;this.cu=!1}
ff.prototype.toString=function(a){var b="UndoManager "+this.historyIndex+"<"+this.history.count+"<="+this.maxHistoryLength;b+="[";for(var c=this.nestedTransactionNames.count,d=0;d<c;d++)0<d&&(b+=" "),b+=this.nestedTransactionNames.N(d);b+="]";if(void 0!==a&&0<a)for(c=this.history.count,d=0;d<c;d++)b+="\n "+this.history.N(d).toString(a-1);return b};
ff.prototype.clear=function(){var a=this.history;a.ha();for(var b=a.count-1;0<=b;b--){var c=a.N(b);null!==c&&c.clear()}a.clear();this.fe=-1;a.freeze();this.he=!1;this.cr=null;this.Ai=0;this.qe.ha();this.qe.clear();this.qe.freeze();this.sl.clear()};ff.prototype.copyProperties=function(a){this.isEnabled=a.isEnabled;this.maxHistoryLength=a.maxHistoryLength;this.checksTransactionLevel=a.checksTransactionLevel};t=ff.prototype;t.Qw=function(a){this.iu.add(a)};t.xx=function(a){this.iu.remove(a)};
t.Aa=function(a){void 0===a&&(a="");null===a&&(a="");if(this.isUndoingRedoing)return!1;!0===this.Zt&&(this.Zt=!1,this.Ai++,this.yb("StartingFirstTransaction",a,this.currentTransaction),0<this.Ai&&this.Ai--);this.isEnabled&&(this.qe.ha(),this.qe.add(a),this.qe.freeze(),null===this.currentTransaction?this.sl.add(0):this.sl.add(this.currentTransaction.changes.count));this.Ai++;var b=1===this.transactionLevel;b&&this.yb("StartedTransaction",a,this.currentTransaction);return b};
t.Za=function(a){void 0===a&&(a="");return jf(this,!0,a)};t.vf=function(){return jf(this,!1,"")};
function jf(a,b,c){if(a.isUndoingRedoing)return!1;a.checksTransactionLevel&&1>a.transactionLevel&&za("Ending transaction without having started a transaction: "+c);var d=1===a.transactionLevel;d&&b&&a.yb("CommittingTransaction",c,a.currentTransaction);var e=0;if(0<a.transactionLevel&&(a.Ai--,a.isEnabled)){var f=a.qe.count;0<f&&(""===c&&(c=a.qe.N(0)),a.qe.ha(),a.qe.mb(f-1),a.qe.freeze());f=a.sl.count;0<f&&(e=a.sl.N(f-1),a.sl.mb(f-1))}f=a.currentTransaction;if(d){if(b){a.cu=!1;if(a.isEnabled&&null!==
f){b=f;b.isComplete=!0;b.name=c;d=a.history;d.ha();for(e=d.count-1;e>a.historyIndex;e--)f=d.N(e),null!==f&&f.clear(),d.mb(e),a.cu=!0;e=a.maxHistoryLength;0<=e&&(0===e?d.clear():d.count>=e&&(f=d.N(0),null!==f&&f.clear(),d.mb(0),a.fe--));0!==e&&(d.add(b),a.fe++);d.freeze();f=b}a.yb("CommittedTransaction",c,f)}else{a.he=!0;try{a.isEnabled&&null!==f&&(f.isComplete=!0,f.undo())}finally{a.yb("RolledBackTransaction",c,f),a.he=!1}null!==f&&f.clear()}a.cr=null;return!0}if(a.isEnabled&&!b&&null!==f){a=e;c=
f.changes;for(b=c.count-1;b>=a;b--)d=c.N(b),null!==d&&d.undo(),c.ha(),c.mb(b);c.freeze()}return!1}ff.prototype.canUndo=function(){if(!this.isEnabled||0<this.transactionLevel)return!1;var a=this.transactionToUndo;return null!==a&&a.canUndo()?!0:!1};ff.prototype.undo=function(){if(this.canUndo()){var a=this.transactionToUndo;try{this.he=!0,this.yb("StartingUndo","Undo",a),this.fe--,a.undo()}catch(b){za("undo error: "+b.toString())}finally{this.yb("FinishedUndo","Undo",a),this.he=!1}}};
ff.prototype.canRedo=function(){if(!this.isEnabled||0<this.transactionLevel)return!1;var a=this.transactionToRedo;return null!==a&&a.canRedo()?!0:!1};ff.prototype.redo=function(){if(this.canRedo()){var a=this.transactionToRedo;try{this.he=!0,this.yb("StartingRedo","Redo",a),this.fe++,a.redo()}catch(b){za("redo error: "+b.toString())}finally{this.yb("FinishedRedo","Redo",a),this.he=!1}}};
ff.prototype.yb=function(a,b,c){void 0===c&&(c=null);var d=new $e;d.change=bf;d.propertyName=a;d.object=c;d.oldValue=b;for(a=this.models;a.next();)b=a.value,d.model=b,b.Ks(d)};
ff.prototype.fv=function(a){if(this.isEnabled&&!this.isUndoingRedoing&&!this.skipsEvent(a)){var b=this.currentTransaction;null===b&&(this.cr=b=new ef);var c=a.copy();b=b.changes;b.ha();b.add(c);b.freeze();this.checksTransactionLevel&&0>=this.transactionLevel&&!this.Zt&&(a=a.diagram,null!==a&&!1===a.Qi||za("Change not within a transaction: "+c.toString()))}};
ff.prototype.skipsEvent=function(a){if(null===a||0>a.change.value)return!0;a=a.object;if(void 0!==a.layer){if(a=a.layer,null!==a&&a.isTemporary)return!0}else if(a.isTemporary)return!0;return!1};
pa.Object.defineProperties(ff.prototype,{models:{get:function(){return this.iu.iterator}},isEnabled:{get:function(){return this.Dc},set:function(a){this.Dc=a}},transactionToUndo:{get:function(){return 0<=this.historyIndex&&this.historyIndex<=this.history.count-1?this.history.N(this.historyIndex):null}},transactionToRedo:{get:function(){return this.historyIndex<this.history.count-
1?this.history.N(this.historyIndex+1):null}},isUndoingRedoing:{get:function(){return this.he}},history:{get:function(){return this.K}},maxHistoryLength:{get:function(){return this.u},set:function(a){this.u=a}},historyIndex:{get:function(){return this.fe}},currentTransaction:{get:function(){return this.cr}},transactionLevel:{
get:function(){return this.Ai}},isInTransaction:{get:function(){return 0<this.Ai}},checksTransactionLevel:{get:function(){return this.l},set:function(a){this.l=a}},nestedTransactionNames:{get:function(){return this.qe}}});ff.prototype.handleChanged=ff.prototype.fv;ff.prototype.redo=ff.prototype.redo;ff.prototype.undo=ff.prototype.undo;ff.prototype.canUndo=ff.prototype.canUndo;
ff.prototype.rollbackTransaction=ff.prototype.vf;ff.prototype.commitTransaction=ff.prototype.Za;ff.prototype.startTransaction=ff.prototype.Aa;ff.prototype.removeModel=ff.prototype.xx;ff.prototype.addModel=ff.prototype.Qw;ff.prototype.clear=ff.prototype.clear;ff.className="UndoManager";function kf(){nb(this);this.C=lf;this.Ua="";this.Dc=!0;this.Ub=!1;this.Bq=null;this.Zx=new Xe;this.Hs=-1}kf.prototype.hb=function(a){this.C=a};
kf.prototype.toString=function(){return""!==this.name?this.name+" Tool":Pa(this.constructor)};kf.prototype.updateAdornments=function(){};kf.prototype.canStart=function(){return this.isEnabled};kf.prototype.doStart=function(){};kf.prototype.doActivate=function(){this.isActive=!0};kf.prototype.doDeactivate=function(){this.isActive=!1};kf.prototype.doStop=function(){};kf.prototype.doCancel=function(){this.transactionResult=null;this.stopTool()};
kf.prototype.stopTool=function(){var a=this.diagram;a.currentTool===this&&(a.currentTool=null,a.currentCursor="")};kf.prototype.doMouseDown=function(){!this.isActive&&this.canStart()&&this.doActivate()};kf.prototype.doMouseMove=function(){};kf.prototype.doMouseUp=function(){this.stopTool()};kf.prototype.doMouseWheel=function(){};kf.prototype.canStartMultiTouch=function(){return!0};
kf.prototype.standardPinchZoomStart=function(){var a=this.diagram,b=a.lastInput,c=b.Zp(0,H.allocAt(NaN,NaN)),d=b.Zp(1,H.allocAt(NaN,NaN));if(c.o()&&d.o()&&(this.doCancel(),a.bm("hasGestureZoom"))){a.Jo=a.scale;var e=d.x-c.x,f=d.y-c.y;a.Gw=Math.sqrt(e*e+f*f);b.bubbles=!1}H.free(c);H.free(d)};
kf.prototype.standardPinchZoomMove=function(){var a=this.diagram,b=a.lastInput,c=b.Zp(0,H.allocAt(NaN,NaN)),d=b.Zp(1,H.allocAt(NaN,NaN));if(c.o()&&d.o()&&(this.doCancel(),a.bm("hasGestureZoom"))){var e=d.x-c.x,f=d.y-c.y;f=Math.sqrt(e*e+f*f)/a.Gw;e=new H((Math.min(d.x,c.x)+Math.max(d.x,c.x))/2,(Math.min(d.y,c.y)+Math.max(d.y,c.y))/2);f*=a.Jo;var g=a.commandHandler;if(f!==a.scale&&g.canResetZoom(f)){var h=a.zoomPoint;a.zoomPoint=e;g.resetZoom(f);a.zoomPoint=h}b.bubbles=!1}H.free(c);H.free(d)};
kf.prototype.doKeyDown=function(){"Esc"===this.diagram.lastInput.key&&this.doCancel()};kf.prototype.doKeyUp=function(){};kf.prototype.Aa=function(a){void 0===a&&(a=this.name);this.transactionResult=null;return this.diagram.Aa(a)};kf.prototype.wg=function(){var a=this.diagram;return null===this.transactionResult?a.vf():a.Za(this.transactionResult)};
kf.prototype.standardMouseSelect=function(){var a=this.diagram;if(a.allowSelect){var b=a.lastInput,c=a.$l(b.documentPoint,!1);if(null!==c)if(ib?b.meta:b.control){a.aa("ChangingSelection",a.selection);for(b=c;null!==b&&!b.canSelect();)b=b.containingGroup;null!==b&&(b.isSelected=!b.isSelected);a.aa("ChangedSelection",a.selection)}else if(b.shift){if(!c.isSelected){a.aa("ChangingSelection",a.selection);for(b=c;null!==b&&!b.canSelect();)b=b.containingGroup;null!==b&&(b.isSelected=!0);a.aa("ChangedSelection",
a.selection)}}else{if(!c.isSelected){for(b=c;null!==b&&!b.canSelect();)b=b.containingGroup;null!==b&&a.select(b)}}else!b.left||(ib?b.meta:b.control)||b.shift||a.Qp()}};kf.prototype.standardMouseClick=function(a,b){void 0===a&&(a=null);void 0===b&&(b=function(a){return!a.layer.isTemporary});var c=this.diagram,d=c.lastInput;a=c.Rb(d.documentPoint,a,b);d.targetObject=a;mf(a,d,c);return d.handled};
function mf(a,b,c){b.handled=!1;if(null===a||a.sg()){var d=0;b.left?d=1===b.clickCount?1:2===b.clickCount?2:1:b.right&&1===b.clickCount&&(d=3);var e="ObjectSingleClicked";if(null!==a){switch(d){case 1:e="ObjectSingleClicked";break;case 2:e="ObjectDoubleClicked";break;case 3:e="ObjectContextClicked"}0!==d&&c.aa(e,a)}else{switch(d){case 1:e="BackgroundSingleClicked";break;case 2:e="BackgroundDoubleClicked";break;case 3:e="BackgroundContextClicked"}0!==d&&c.aa(e)}if(null!==a)for(;null!==a;){c=null;switch(d){case 1:c=
a.click;break;case 2:c=a.doubleClick?a.doubleClick:a.click;break;case 3:c=a.contextClick}if(null!==c&&(c(b,a),b.handled))break;a=a.panel}else{a=null;switch(d){case 1:a=c.click;break;case 2:a=c.doubleClick?c.doubleClick:c.click;break;case 3:a=c.contextClick}null!==a&&a(b)}}}
kf.prototype.standardMouseOver=function(){var a=this.diagram,b=a.lastInput;if(!0!==a.animationManager.rb){var c=a.skipsUndoManager;a.skipsUndoManager=!0;var d=a.viewportBounds.da(b.documentPoint)?a.Rb(b.documentPoint,null,null):null;b.targetObject=d;var e=!1;if(d!==a.Kk){var f=a.Kk,g=f;a.Kk=d;this.doCurrentObjectChanged(f,d);for(b.handled=!1;null!==f;){var h=f.mouseLeave;if(null!==h){if(d===f)break;if(null!==d&&d.rg(f))break;h(b,f,d);e=!0;if(b.handled)break}f=f.panel}f=g;for(b.handled=!1;null!==d;){g=
d.mouseEnter;if(null!==g){if(f===d)break;if(null!==f&&f.rg(d))break;g(b,d,f);e=!0;if(b.handled)break}d=d.panel}d=a.Kk}if(null!==d){f=d;for(g="";null!==f;){g=f.cursor;if(""!==g)break;f=f.panel}a.currentCursor=g;b.handled=!1;for(f=d;null!==f;){d=f.mouseOver;if(null!==d&&(d(b,f),e=!0,b.handled))break;f=f.panel}}else a.currentCursor="",d=a.mouseOver,null!==d&&(d(b),e=!0);e&&a.cc();a.skipsUndoManager=c}};kf.prototype.doCurrentObjectChanged=function(){};
kf.prototype.standardMouseWheel=function(){var a=this.diagram,b=a.lastInput,c=b.delta;if(0!==c&&a.documentBounds.o()){var d=a.commandHandler,e=a.toolManager.mouseWheelBehavior;if(null!==d&&(e===nf&&!b.shift||e===of&&b.control)&&(0<c?d.canIncreaseZoom():d.canDecreaseZoom()))e=a.zoomPoint,a.zoomPoint=b.viewPoint,0<c?d.increaseZoom():d.decreaseZoom(),a.zoomPoint=e,b.bubbles=!1;else if(e===nf&&b.shift||e===of&&!b.control){d=a.position.copy();var f=0<c?c:-c,g=b.event,h=g.deltaMode;e=g.deltaX;g=g.deltaY;
if(cb||db||gb)h=1,0<e&&(e=3),0>e&&(e=-3),0<g&&(g=3),0>g&&(g=-3);if(void 0===h||void 0===e||void 0===g||0===e&&0===g||b.shift)!b.shift&&a.allowVerticalScroll?(f=3*f*a.scrollVerticalLineChange,0<c?a.scroll("pixel","up",f):a.scroll("pixel","down",f)):b.shift&&a.allowHorizontalScroll&&(f=3*f*a.scrollHorizontalLineChange,0<c?a.scroll("pixel","left",f):a.scroll("pixel","right",f));else{switch(h){case 0:c="pixel";break;case 1:c="line";break;case 2:c="page";break;default:c="pixel"}0!==e&&a.allowHorizontalScroll&&
(0<e?a.scroll(c,"left",-e):a.scroll(c,"right",e));0!==g&&a.allowVerticalScroll&&(0<g?a.scroll(c,"up",-g):a.scroll(c,"down",g))}a.position.A(d)||(b.bubbles=!1)}}};kf.prototype.standardWaitAfter=function(a,b){void 0===b&&(b=this.diagram.lastInput);this.cancelWaitAfter();var c=this,d=b.clone(this.Zx);this.Hs=ua(function(){c.doWaitAfter(d)},a)};kf.prototype.cancelWaitAfter=function(){-1!==this.Hs&&w.clearTimeout(this.Hs);this.Hs=-1};kf.prototype.doWaitAfter=function(){};
kf.prototype.findToolHandleAt=function(a,b){a=this.diagram.Rb(a,function(a){for(;null!==a&&!(a.panel instanceof pf);)a=a.panel;return a});return null===a?null:a.part.category===b?a:null};kf.prototype.isBeyondDragSize=function(a,b){var c=this.diagram;void 0===a&&(a=c.firstInput.viewPoint);void 0===b&&(b=c.lastInput.viewPoint);var d=c.toolManager.dragSize,e=d.width;d=d.height;c.firstInput.isTouchEvent&&(e+=6,d+=6);return Math.abs(b.x-a.x)>e||Math.abs(b.y-a.y)>d};
pa.Object.defineProperties(kf.prototype,{diagram:{get:function(){return this.C},set:function(a){a instanceof P&&(this.C=a)}},name:{get:function(){return this.Ua},set:function(a){this.Ua=a}},isEnabled:{get:function(){return this.Dc},set:function(a){this.Dc=a}},isActive:{get:function(){return this.Ub},set:function(a){this.Ub=a}},transactionResult:{get:function(){return this.Bq},
set:function(a){this.Bq=a}}});kf.prototype.stopTransaction=kf.prototype.wg;kf.prototype.startTransaction=kf.prototype.Aa;kf.className="Tool";function Ua(){kf.call(this);this.name="ToolManager";this.Kc=new E;this.Vc=new E;this.xg=new E;this.ba=this.La=850;this.u=(new L(2,2)).ga();this.Vb=5E3;this.Ma=of;this.K=qf;this.br=this.l=null;this.Mj=-1}oa(Ua,kf);Ua.prototype.initializeStandardTools=function(){};
Ua.prototype.updateAdornments=function(a){var b=this.currentToolTip;if(b instanceof pf&&this.br===a){var c=b.adornedObject;(null!==a?c.part===a:null===c)?this.showToolTip(b,c):this.hideToolTip()}};
Ua.prototype.doMouseDown=function(){var a=this.diagram,b=a.lastInput;b.isTouchEvent&&this.gestureBehavior===rf&&(b.bubbles=!1);if(b.isMultiTouch){this.cancelWaitAfter();if(this.gestureBehavior===sf){b.bubbles=!0;return}if(this.gestureBehavior===rf)return;if(a.currentTool.canStartMultiTouch()){a.currentTool.standardPinchZoomStart();return}}for(var c=this.mouseDownTools.length,d=0;d<c;d++){var e=this.mouseDownTools.N(d);e.hb(this.diagram);if(e.canStart()){a.doFocus();a.currentTool=e;a.currentTool===
e&&(e.isActive||e.doActivate(),e.doMouseDown());return}}1===a.lastInput.button&&(this.mouseWheelBehavior===of?this.mouseWheelBehavior=nf:this.mouseWheelBehavior===nf&&(this.mouseWheelBehavior=of));this.doActivate();this.standardWaitAfter(this.holdDelay,b)};
Ua.prototype.doMouseMove=function(){var a=this.diagram,b=a.lastInput;if(b.isMultiTouch){if(this.gestureBehavior===sf){b.bubbles=!0;return}if(this.gestureBehavior===rf)return;if(a.currentTool.canStartMultiTouch()){a.currentTool.standardPinchZoomMove();return}}if(this.isActive)for(var c=this.mouseMoveTools.length,d=0;d<c;d++){var e=this.mouseMoveTools.N(d);e.hb(this.diagram);if(e.canStart()){a.doFocus();a.currentTool=e;a.currentTool===e&&(e.isActive||e.doActivate(),e.doMouseMove());return}}tf(this,
a);a=b.event;null===a||"mousemove"!==a.type&&"pointermove"!==a.type&&a.cancelable||(b.bubbles=!0)};function tf(a,b){a.standardMouseOver();a.isBeyondDragSize()&&a.standardWaitAfter(a.isActive?a.holdDelay:a.hoverDelay,b.lastInput)}Ua.prototype.doCurrentObjectChanged=function(a,b){a=this.currentToolTip;null===a||null!==b&&a instanceof pf&&(b===a||b.rg(a))||this.hideToolTip()};
Ua.prototype.doWaitAfter=function(a){var b=this.diagram;b.Da&&(this.doMouseHover(),this.isActive||this.doToolTip(),a.isTouchEvent&&!b.lastInput.handled&&(a=a.copy(),a.button=2,a.buttons=2,b.lastInput=a,b.Ml=!0,b.doMouseUp()))};
Ua.prototype.doMouseHover=function(){var a=this.diagram,b=a.lastInput;null===b.targetObject&&(b.targetObject=a.Rb(b.documentPoint,null,null));var c=b.targetObject;if(null!==c)for(b.handled=!1;null!==c;){a=this.isActive?c.mouseHold:c.mouseHover;if(null!==a&&(a(b,c),b.handled))break;c=c.panel}else c=this.isActive?a.mouseHold:a.mouseHover,null!==c&&c(b)};
Ua.prototype.doToolTip=function(){var a=this.diagram,b=a.lastInput;null===b.targetObject&&(b.targetObject=a.Rb(b.documentPoint,null,null));b=b.targetObject;if(null!==b){if(a=this.currentToolTip,!(a instanceof pf)||b!==a&&!b.rg(a)){for(;null!==b;){a=b.toolTip;if(null!==a){this.showToolTip(a,b);return}b=b.panel}this.hideToolTip()}}else b=a.toolTip,null!==b?this.showToolTip(b,null):this.hideToolTip()};
Ua.prototype.showToolTip=function(a,b){var c=this.diagram;a!==this.currentToolTip&&this.hideToolTip();if(a instanceof pf){a.layerName="Tool";a.selectable=!1;a.scale=1/c.scale;a.category="ToolTip";null!==a.placeholder&&(a.placeholder.scale=c.scale);var d=a.diagram;null!==d&&d!==c&&d.remove(a);c.add(a);null!==b?a.adornedObject=b:a.data=c.model;a.$b();this.positionToolTip(a,b)}else a instanceof uf&&a!==this.currentToolTip&&a.show(b,c,this);this.currentToolTip=a;-1!==this.Mj&&(w.clearTimeout(this.Mj),
this.Mj=-1);a=this.toolTipDuration;if(0<a&&Infinity!==a){var e=this;this.Mj=ua(function(){e.hideToolTip()},a)}};Ua.prototype.positionToolTip=function(a){if(null===a.placeholder){var b=this.diagram,c=b.lastInput.documentPoint.copy(),d=a.measuredBounds,e=b.viewportBounds;b.lastInput.isTouchEvent&&(c.x-=d.width);c.x+d.width>e.right&&(c.x-=d.width+5/b.scale);c.x<e.x&&(c.x=e.x);c.y=c.y+20/b.scale+d.height>e.bottom?c.y-(d.height+5/b.scale):c.y+20/b.scale;c.y<e.y&&(c.y=e.y);a.position=c}};
Ua.prototype.hideToolTip=function(){-1!==this.Mj&&(w.clearTimeout(this.Mj),this.Mj=-1);var a=this.diagram,b=this.currentToolTip;null!==b&&(b instanceof pf?(a.remove(b),null!==this.br&&this.br.uf(b.category),b.data=null,b.adornedObject=null):b instanceof uf&&null!==b.hide&&b.hide(a,this),this.currentToolTip=null)};
Ua.prototype.doMouseUp=function(){this.cancelWaitAfter();var a=this.diagram;if(this.isActive)for(var b=this.mouseUpTools.length,c=0;c<b;c++){var d=this.mouseUpTools.N(c);d.hb(this.diagram);if(d.canStart()){a.doFocus();a.currentTool=d;a.currentTool===d&&(d.isActive||d.doActivate(),d.doMouseUp());return}}a.doFocus();this.doDeactivate()};Ua.prototype.doMouseWheel=function(){this.standardMouseWheel()};Ua.prototype.doKeyDown=function(){var a=this.diagram;null!==a.commandHandler&&a.commandHandler.doKeyDown()};
Ua.prototype.doKeyUp=function(){var a=this.diagram;null!==a.commandHandler&&a.commandHandler.doKeyUp()};Ua.prototype.findTool=function(a){for(var b=this.mouseDownTools.length,c=0;c<b;c++){var d=this.mouseDownTools.N(c);if(d.name===a)return d}b=this.mouseMoveTools.length;for(c=0;c<b;c++)if(d=this.mouseMoveTools.N(c),d.name===a)return d;b=this.mouseUpTools.length;for(c=0;c<b;c++)if(d=this.mouseUpTools.N(c),d.name===a)return d;return null};
Ua.prototype.replaceTool=function(a,b){null!==b&&b.hb(this.diagram);for(var c=this.mouseDownTools.length,d=0;d<c;d++){var e=this.mouseDownTools.N(d);if(e.name===a)return null!==b?this.mouseDownTools.gd(d,b):this.mouseDownTools.mb(d),e}c=this.mouseMoveTools.length;for(d=0;d<c;d++)if(e=this.mouseMoveTools.N(d),e.name===a)return null!==b?this.mouseMoveTools.gd(d,b):this.mouseMoveTools.mb(d),e;c=this.mouseUpTools.length;for(d=0;d<c;d++)if(e=this.mouseUpTools.N(d),e.name===a)return null!==b?this.mouseUpTools.gd(d,
b):this.mouseUpTools.mb(d),e;return null};function vf(a,b,c,d){null!==c&&(c.name=b,c.hb(a.diagram));a.findTool(b)?a.replaceTool(b,c):null!==c&&d.add(c)}
pa.Object.defineProperties(Ua.prototype,{mouseWheelBehavior:{get:function(){return this.Ma},set:function(a){this.Ma=a}},gestureBehavior:{get:function(){return this.K},set:function(a){this.K=a}},currentToolTip:{get:function(){return this.l},set:function(a){this.l=a;this.br=null!==a&&a instanceof pf?a.adornedPart:null}},mouseDownTools:{get:function(){return this.Kc}},mouseMoveTools:{
get:function(){return this.Vc}},mouseUpTools:{get:function(){return this.xg}},hoverDelay:{get:function(){return this.La},set:function(a){this.La=a}},holdDelay:{get:function(){return this.ba},set:function(a){this.ba=a}},dragSize:{get:function(){return this.u},set:function(a){this.u=a.I()}},toolTipDuration:{get:function(){return this.Vb},
set:function(a){this.Vb=a}}});var of=new D(Ua,"WheelScroll",0),nf=new D(Ua,"WheelZoom",1),wf=new D(Ua,"WheelNone",2),qf=new D(Ua,"GestureZoom",3),rf=new D(Ua,"GestureCancel",4),sf=new D(Ua,"GestureNone",5);Ua.className="ToolManager";Ua.WheelScroll=of;Ua.WheelZoom=nf;Ua.WheelNone=wf;Ua.GestureZoom=qf;Ua.GestureCancel=rf;Ua.GestureNone=sf;
function xf(){kf.call(this);this.name="Dragging";this.K=this.Kc=!0;this.u=this.Ma=this.ba=this.bg=null;this.xn=this.Vc=!1;this.Hl=new H(NaN,NaN);this.xs=new H;this.Vb=!0;this.Rk=100;this.Jg=[];this.xg=(new F).freeze();this.La=new yf}oa(xf,kf);
xf.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;if(a.isReadOnly&&!a.allowDragOut||!a.allowMove&&!a.allowCopy&&!a.allowDragOut||!a.allowSelect)return!1;var b=a.lastInput;return!b.left||a.currentTool!==this&&(!this.isBeyondDragSize()||b.isTouchEvent&&b.timestamp-a.firstInput.timestamp<this.Rk)?!1:null!==this.findDraggablePart()};
xf.prototype.findDraggablePart=function(){var a=this.diagram;a=a.$l(a.firstInput.documentPoint,!1);if(null===a)return null;for(;null!==a&&!a.canSelect();)a=a.containingGroup;return null!==a&&(a.canMove()||a.canCopy())?a:null};
xf.prototype.standardMouseSelect=function(){var a=this.diagram;if(a.allowSelect){var b=a.$l(a.firstInput.documentPoint,!1);if(null!==b){for(;null!==b&&!b.canSelect();)b=b.containingGroup;this.currentPart=b;null===this.currentPart||this.currentPart.isSelected||(a.aa("ChangingSelection",a.selection),b=a.lastInput,(ib?b.meta:b.control)||b.shift||Hf(a),this.currentPart.isSelected=!0,a.aa("ChangedSelection",a.selection))}}};
xf.prototype.doActivate=function(){var a=this.diagram;null===this.currentPart&&this.standardMouseSelect();var b=this.currentPart;null!==b&&(b.canMove()||b.canCopy())&&(If=null,this.isActive=!0,this.Hl.set(a.position),Jf(this,a.selection),this.Jg.length=0,this.draggedParts=this.computeEffectiveCollection(a.selection,this.dragOptions),a.Wi=!0,!0===a.De("temporaryPixelRatio")&&30<a.Tw&&Kf(a),Lf(a,this.draggedParts),this.Aa("Drag"),this.startPoint=a.firstInput.documentPoint,a.isMouseCaptured=!0,a.allowDragOut&&
(this.isDragOutStarted=!0,this.xn=!1,If=this,Mf=this.diagram,this.doSimulatedDragOut()))};function Jf(a,b){if(a.dragsLink){var c=a.diagram;c.allowRelink&&(c.model.ek()&&1===b.count&&b.first()instanceof S?(a.draggedLink=b.first(),a.draggedLink.canRelinkFrom()&&a.draggedLink.canRelinkTo()&&a.draggedLink.Uj(),a.bg=c.toolManager.findTool("Relinking"),null===a.bg&&(a.bg=new Nf,a.bg.hb(c))):(a.draggedLink=null,a.bg=null))}}
xf.prototype.computeEffectiveCollection=function(a,b){return this.diagram.commandHandler.computeEffectiveCollection(a,b)};xf.prototype.pd=function(a){return void 0===a?new Of(ac):this.isGridSnapEnabled?new Of(new H(Math.round(a.x),Math.round(a.y))):new Of(a.copy())};
xf.prototype.doDeactivate=function(){this.isActive=!1;var a=this.diagram;a.wf();Pf(this);Qf(a,this.draggedParts);this.draggedParts=this.currentPart=null;this.xn=this.isDragOutStarted=!1;if(0<Rf.count){for(var b=Rf,c=b.length,d=0;d<c;d++){var e=b.N(d);Sf(e);Tf(e);Pf(e);e.diagram.wf()}b.clear()}Sf(this);this.Hl.h(NaN,NaN);If=Mf=null;Tf(this);a.isMouseCaptured=!1;a.currentCursor="";a.Wi=!1;this.wg();Uf(a,!0)};
function Pf(a){var b=a.diagram,c=b.skipsUndoManager;b.skipsUndoManager=!0;Vf(a,b.lastInput,null);b.skipsUndoManager=c;a.Jg.length=0}function Wf(){var a=If;Tf(a);Xf(a);var b=a.diagram;a.Hl.o()&&(b.position=a.Hl);b.wf()}xf.prototype.doCancel=function(){Tf(this);Xf(this);var a=this.diagram;this.Hl.o()&&(a.position=this.Hl);this.stopTool()};xf.prototype.doKeyDown=function(){this.isActive&&("Esc"===this.diagram.lastInput.key?this.doCancel():this.doMouseMove())};
xf.prototype.doKeyUp=function(){this.isActive&&this.doMouseMove()};function Yf(a,b){var c=Infinity,d=Infinity,e=-Infinity,f=-Infinity;for(a=a.iterator;a.next();){var g=a.value;if(g.bc()&&g.isVisible()){var h=g.location;g=h.x;h=h.y;isNaN(g)||isNaN(h)||(g<c&&(c=g),h<d&&(d=h),g>e&&(e=g),h>f&&(f=h))}}Infinity===c?b.h(0,0,0,0):b.h(c,d,e-c,f-d)}
function Zf(a,b){if(null===a.copiedParts){var c=a.diagram;if((!b||!c.isReadOnly&&!c.isModelReadOnly)&&null!==a.draggedParts){var d=c.undoManager;d.isEnabled&&d.isInTransaction?null!==d.currentTransaction&&0<d.currentTransaction.changes.count&&(c.undoManager.vf(),c.Aa("Drag")):Xf(a);c.skipsUndoManager=!b;c.partManager.addsToTemporaryLayer=!b;a.startPoint=c.firstInput.documentPoint;b=a.copiesEffectiveCollection?a.draggedParts.Yd():c.selection;c=c.Zj(b,c,!0);for(b=c.iterator;b.next();)b.value.location=
b.key.location;b=N.alloc();Yf(c,b);N.free(b);b=new Lb;for(d=a.draggedParts.iterator;d.next();){var e=d.key;e.bc()&&e.canCopy()&&(e=c.J(e),null!==e&&(e.$b(),b.add(e,a.pd(e.location))))}for(c=c.iterator;c.next();)d=c.value,d instanceof S&&d.canCopy()&&b.add(d,a.pd());a.copiedParts=b;Jf(a,b.Yd());null!==a.draggedLink&&(c=a.draggedLink,b=c.routeBounds,$f(c,a.startPoint.x-(b.x+b.width/2),a.startPoint.y-(b.y+b.height/2)))}}}
function Tf(a){var b=a.diagram;if(null!==a.copiedParts&&(b.qt(a.copiedParts.Yd(),!1),a.copiedParts=null,null!==a.draggedParts))for(var c=a.draggedParts.iterator;c.next();)c.key instanceof S&&(c.value.point=new H(0,0));b.skipsUndoManager=!1;b.partManager.addsToTemporaryLayer=!1;a.startPoint=b.firstInput.documentPoint}
function Sf(a){if(null!==a.draggedLink){if(a.dragsLink&&null!==a.bg){var b=a.bg;b.diagram.remove(b.temporaryFromNode);b.diagram.remove(b.temporaryToNode)}a.draggedLink=null;a.bg=null}}function ag(a,b,c){var d=a.diagram,e=a.startPoint,f=H.alloc();f.assign(d.lastInput.documentPoint);a.moveParts(b,f.Xd(e),c);H.free(f);!0===d.De("temporaryPixelRatio")&&null===d.jh&&30<d.Tw&&(Kf(d),d.nt())}xf.prototype.moveParts=function(a,b,c){var d=this.diagram;null!==d&&bg(d,a,b,this.dragOptions,c)};
function Xf(a){if(null!==a.draggedParts){for(var b=a.diagram,c=a.draggedParts.iterator;c.next();){var d=c.key;d.bc()&&(d.location=c.value.point)}for(c=a.draggedParts.iterator;c.next();)if(d=c.key,d instanceof S&&d.suspendsRouting){var e=c.value.point;a.draggedParts.add(d,a.pd());$f(d,-e.x,-e.y)}b.fd()}}function dg(a,b){if(null===b)return!0;b=b.part;return null===b||b instanceof pf||b.layer.isTemporary||a.draggedParts&&a.draggedParts.contains(b)||a.copiedParts&&a.copiedParts.contains(b)?!0:!1}
function eg(a,b){var c=a.diagram;a.dragsLink&&(null!==a.draggedLink&&(a.draggedLink.fromNode=null,a.draggedLink.toNode=null),fg(a,!1));var d=gg(c,b,null,function(b){return!dg(a,b)}),e=c.lastInput;e.targetObject=d;var f=c.skipsUndoManager,g=!1;try{c.skipsUndoManager=!0;g=Vf(a,e,d);if(!a.isActive&&null===If)return;if(null===d||c.handlesDragDropForTopLevelParts){var h=c.mouseDragOver;null!==h&&(h(e),g=!0)}if(!a.isActive&&null===If)return;a.doDragOver(b,d);if(!a.isActive&&null===If)return}finally{c.skipsUndoManager=
f,g&&c.fd()}c.isReadOnly||!c.allowMove&&!c.allowCopy||!c.allowHorizontalScroll&&!c.allowVerticalScroll||c.Ns(e.viewPoint)}
function Vf(a,b,c){var d=!1,e=a.Jg.length,f=0<e?a.Jg[0]:null;if(c===f)return!1;b.handled=!1;for(var g=0;g<e;g++){var h=a.Jg[g],k=h.mouseDragLeave;if(null!==k&&(k(b,h,c),d=!0,b.handled))break}a.Jg.length=0;if(!a.isActive&&null===If||null===c)return d;b.handled=!1;for(e=c;null!==e;)a.Jg.push(e),e=hg(e);e=a.Jg.length;for(c=0;c<e&&(g=a.Jg[c],h=g.mouseDragEnter,null===h||(h(b,g,f),d=!0,!b.handled));c++);return d}
function hg(a){var b=a.panel;return null!==b?b:a instanceof T&&!(a instanceof ig)&&(a=a.containingGroup,null!==a&&a.handlesDragDropForMembers)?a:null}function jg(a,b,c){var d=a.bg;if(null===d)return null;var e=a.diagram.og(b,d.portGravity,function(a){return d.findValidLinkablePort(a,c)});a=H.alloc();var f=Infinity,g=null;for(e=e.iterator;e.next();){var h=e.value;if(null!==h.part){var k=h.ma(dd,a);k=b.Be(k);k<f&&(g=h,f=k)}}H.free(a);return g}
function fg(a,b){var c=a.draggedLink;if(null!==c&&!(2>c.pointsCount)){var d=a.diagram;if(!d.isReadOnly){var e=a.bg;if(null!==e){var f=null,g=null;null===c.fromNode&&(f=jg(a,c.i(0),!1),null!==f&&(g=f.part));var h=null,k=null;null===c.toNode&&(h=jg(a,c.i(c.pointsCount-1),!0),null!==h&&(k=h.part));e.isValidLink(g,f,k,h)?b?(c.defaultFromPoint=c.i(0),c.defaultToPoint=c.i(c.pointsCount-1),c.suspendsRouting=!1,c.fromNode=g,null!==f&&(c.fromPortId=f.portId),c.toNode=k,null!==h&&(c.toPortId=h.portId),c.fromPort!==
d.sx&&d.aa("LinkRelinked",c,d.sx),c.toPort!==d.tx&&d.aa("LinkRelinked",c,d.tx)):kg(e,g,f,k,h):kg(e,null,null,null,null)}}}}xf.prototype.doDragOver=function(){};
function lg(a,b){var c=a.diagram;a.dragsLink&&fg(a,!0);Pf(a);var d=gg(c,b,null,function(b){return!dg(a,b)}),e=c.lastInput;e.targetObject=d;if(null!==d){e.handled=!1;for(var f=d;null!==f;){var g=f.mouseDrop;if(null!==g&&(g(e,f),e.handled))break;mg(a,e,f);f=hg(f)}}else f=c.mouseDrop,null!==f&&f(e);if(a.isActive||null!==If){for(e=(a.copiedParts||a.draggedParts).iterator;e.next();)f=e.key,f instanceof V&&f.linksConnected.each(function(a){a.suspendsRouting=!1});a.doDropOnto(b,d);if(a.isActive||null!==
If){b=N.alloc();for(d=c.selection.iterator;d.next();)e=d.value,e instanceof V&&ng(a,c,e,b);N.free(b)}}}function mg(a,b,c){a=a.diagram;c instanceof T&&null===c.containingGroup&&!(c instanceof ig)&&a.handlesDragDropForTopLevelParts&&(c=a.mouseDrop,null!==c&&c(b))}
function ng(a,b,c,d){var e=!1;c.getAvoidableRect(d);b.viewportBounds.mf(d)&&(e=!0);b=b.links;for(a=a.copiedParts||a.draggedParts;b.next();){var f=b.value;if(!e||og(f))a.contains(f)&&a.contains(c)||!f.Vd(c)&&f.isAvoiding&&Ac(f.actualBounds,d,0)&&f.Ra()}}xf.prototype.doDropOnto=function(){};
xf.prototype.doMouseMove=function(){if(this.isActive){var a=this.diagram,b=a.lastInput;this.simulatedMouseMove(b.event,null,b.targetDiagram||null)||null===this.currentPart||null===this.draggedParts||(this.mayCopy()?(a.currentCursor="copy",Zf(this,!1),Lf(a,this.copiedParts),ag(this,this.copiedParts,!1),Qf(a,this.copiedParts)):this.mayMove()?(Tf(this),ag(this,this.draggedParts,!0)):this.mayDragOut()?(a.currentCursor="no-drop",Zf(this,!1),ag(this,this.copiedParts,!1)):Tf(this),eg(this,a.lastInput.documentPoint))}};
xf.prototype.doMouseUp=function(){if(this.isActive){var a=this.diagram,b=a.lastInput;if(!this.simulatedMouseUp(b.event,null,b.documentPoint,b.targetDiagram)){var c=!1;(b=this.mayCopy())&&null!==this.copiedParts?(Tf(this),Zf(this,!0),Lf(a,this.copiedParts),ag(this,this.copiedParts,!1),Qf(a,this.copiedParts),null!==this.copiedParts&&a.Gv(this.copiedParts.Yd())):(c=!0,Tf(this),this.mayMove()&&(ag(this,this.draggedParts,!0),eg(this,a.lastInput.documentPoint)));this.xn=!0;lg(this,a.lastInput.documentPoint);
if(this.isActive){var d=b?this.copiedParts.Yd():this.draggedParts.Yd();this.copiedParts=null;if(c&&null!==this.draggedParts)for(c=this.draggedParts.iterator;c.next();){var e=c.key;e instanceof V&&(e=e.containingGroup,null===e||null===e.placeholder||this.draggedParts.contains(e)||e.placeholder.s())}a.Xa();Qf(a,this.draggedParts);this.transactionResult=b?"Copy":"Move";a.aa(b?"SelectionCopied":"SelectionMoved",d)}this.stopTool()}}};
xf.prototype.simulatedMouseMove=function(a,b,c){if(null===If)return!1;var d=If.diagram;c instanceof P||(c=null);var e=Mf;c!==e&&(null!==e&&e!==d&&(e.wf(),If.isDragOutStarted=!1,e=e.toolManager.findTool("Dragging"),null!==e&&e.doSimulatedDragLeave()),Mf=c,null!==c&&c!==d&&(Wf(),e=c.toolManager.findTool("Dragging"),null!==e&&(Rf.contains(e)||Rf.add(e),e.doSimulatedDragEnter())));if(null===c||c===d||!c.allowDrop||c.isReadOnly||!c.allowInsert)return!1;d=c.toolManager.findTool("Dragging");null!==d&&(null!==
a?(void 0!==a.targetTouches&&(0<a.targetTouches.length?a=a.targetTouches[0]:0<a.changedTouches.length&&(a=a.changedTouches[0])),b=c.getMouse(a)):null===b&&(b=new H),c.lastInput.documentPoint=b,c.lastInput.viewPoint=c.wt(b),c.lastInput.down=!1,c.lastInput.up=!1,d.doSimulatedDragOver());return!0};
xf.prototype.simulatedMouseUp=function(a,b,c,d){if(null===If)return!1;null===d&&(d=b);b=Mf;var e=If.diagram;if(d!==b){var f=b.toolManager.findTool("Dragging");if(null!==b&&b!==e&&null!==f)return b.wf(),If.isDragOutStarted=!1,f.doSimulatedDragLeave(),!1;Mf=d;b=d.toolManager.findTool("Dragging");null!==d&&null!==b&&(Wf(),Rf.contains(b)||Rf.add(b),b.doSimulatedDragEnter())}return null===d?(If.doCancel(),!0):d!==this.diagram?(null!==a?(void 0!==a.targetTouches&&(0<a.targetTouches.length?a=a.targetTouches[0]:
0<a.changedTouches.length&&(a=a.changedTouches[0])),c=d.getMouse(a)):null===c&&(c=new H),d.lastInput.documentPoint=c,d.lastInput.viewPoint=d.wt(c),d.lastInput.down=!1,d.lastInput.up=!0,a=d.toolManager.findTool("Dragging"),null!==a&&a.doSimulatedDrop(),a=If,null!==a&&(d=a.mayCopy(),a.transactionResult=d?"Copy":"Move",a.stopTool()),!0):!1};
xf.prototype.mayCopy=function(){if(!this.isCopyEnabled)return!1;var a=this.diagram;if(a.isReadOnly||a.isModelReadOnly||!a.allowInsert||!a.allowCopy||(ib?!a.lastInput.alt:!a.lastInput.control))return!1;for(a=a.selection.iterator;a.next();){var b=a.value;if(b.bc()&&b.canCopy())return!0}return null!==this.draggedLink&&this.dragsLink&&this.draggedLink.canCopy()?!0:!1};
xf.prototype.mayDragOut=function(){if(!this.isCopyEnabled)return!1;var a=this.diagram;if(!a.allowDragOut||!a.allowCopy||a.allowMove)return!1;for(a=a.selection.iterator;a.next();){var b=a.value;if(b.bc()&&b.canCopy())return!0}return null!==this.draggedLink&&this.dragsLink&&this.draggedLink.canCopy()?!0:!1};
xf.prototype.mayMove=function(){var a=this.diagram;if(a.isReadOnly||!a.allowMove)return!1;for(a=a.selection.iterator;a.next();){var b=a.value;if(b.bc()&&b.canMove())return!0}return null!==this.draggedLink&&this.dragsLink&&this.draggedLink.canMove()?!0:!1};xf.prototype.computeBorder=function(a,b,c){return this.xn||null===this.draggedParts||this.draggedParts.contains(a)?null:c.assign(b)};xf.prototype.Xy=function(){return If};
xf.prototype.mayDragIn=function(){var a=this.diagram;if(!a.allowDrop||a.isReadOnly||a.isModelReadOnly||!a.allowInsert)return!1;var b=If;return null===b||b.diagram.model.dataFormat!==a.model.dataFormat?!1:!0};xf.prototype.doSimulatedDragEnter=function(){if(this.mayDragIn()){var a=this.diagram;a.animationManager.vd();pg(a);a.animationManager.vd();a=If;null!==a&&(a.diagram.currentCursor="copy",a.diagram.Wi=!1)}};xf.prototype.doSimulatedDragLeave=function(){var a=If;null!==a&&a.doSimulatedDragOut();this.doCancel()};
xf.prototype.doSimulatedDragOver=function(){var a=this.diagram,b=If;null!==b&&null!==b.draggedParts&&this.mayDragIn()&&(a.currentCursor="copy",qg(this,b.draggedParts.Yd(),!1,a.firstInput),ag(this,this.copiedParts,!1),eg(this,a.lastInput.documentPoint))};
xf.prototype.doSimulatedDrop=function(){var a=this.diagram,b=If;if(null!==b){var c=b.diagram;b.xn=!0;Tf(this);this.mayDragIn()&&(this.Aa("Drop"),qg(this,b.draggedParts.Yd(),!0,a.lastInput),ag(this,this.copiedParts,!1),null!==this.copiedParts&&a.Gv(this.copiedParts.Yd()),lg(this,a.lastInput.documentPoint),a.Xa(),b=a.selection,null!==this.copiedParts?this.transactionResult="ExternalCopy":b=new F,this.copiedParts=null,a.doFocus(),a.aa("ExternalObjectsDropped",b,c),this.wg())}};
function qg(a,b,c,d){if(null===a.copiedParts){var e=a.diagram;if(!e.isReadOnly&&!e.isModelReadOnly){e.skipsUndoManager=!c;e.partManager.addsToTemporaryLayer=!c;a.startPoint=d.documentPoint;c=e.Zj(b,e,!0);var f=N.alloc();Yf(b,f);d=f.x+f.width/2;e=f.y+f.height/2;N.free(f);f=a.xs;var g=new Lb,h=H.alloc();for(b=b.iterator;b.next();){var k=b.value,l=c.J(k);k.bc()&&k.canCopy()?(k=k.location,h.h(f.x-(d-k.x),f.y-(e-k.y)),l.location=h,l.$b(),g.add(l,a.pd(h))):l instanceof S&&k.canCopy()&&($f(l,f.x-d,f.y-e),
g.add(l,a.pd()))}H.free(h);a.copiedParts=g;Jf(a,g.Yd());null!==a.draggedLink&&(c=a.draggedLink,d=c.routeBounds,$f(c,a.startPoint.x-(d.x+d.width/2),a.startPoint.y-(d.y+d.height/2)))}}}xf.prototype.doSimulatedDragOut=function(){var a=this.diagram;a.Wi=!1;this.mayCopy()||this.mayMove()?a.currentCursor="":a.currentCursor="no-drop"};xf.prototype.computeMove=function(a,b,c,d){c=this.diagram;return null!==c?c.computeMove(a,b,this.dragOptions,d):new H};
pa.Object.defineProperties(xf.prototype,{isCopyEnabled:{get:function(){return this.Kc},set:function(a){this.Kc=a}},copiesEffectiveCollection:{get:function(){return this.K},set:function(a){this.K=a}},dragOptions:{get:function(){return this.La},set:function(a){this.La=a}},isGridSnapEnabled:{get:function(){return this.dragOptions.isGridSnapEnabled},set:function(a){this.dragOptions.isGridSnapEnabled=
a}},isComplexRoutingRealtime:{get:function(){return this.Vb},set:function(a){this.Vb=a}},isGridSnapRealtime:{get:function(){return this.dragOptions.isGridSnapRealtime},set:function(a){this.dragOptions.isGridSnapRealtime=a}},gridSnapCellSize:{get:function(){return this.dragOptions.gridSnapCellSize},set:function(a){null===this.diagram||this.dragOptions.gridSnapCellSize.A(a)||(a=a.I(),this.dragOptions.gridSnapCellSize=
a)}},gridSnapCellSpot:{get:function(){return this.dragOptions.gridSnapCellSpot},set:function(a){this.dragOptions.gridSnapCellSpot.A(a)||(a=a.I(),this.dragOptions.gridSnapCellSpot=a)}},gridSnapOrigin:{get:function(){return this.dragOptions.gridSnapOrigin},set:function(a){this.dragOptions.gridSnapOrigin.A(a)||(a=a.I(),this.dragOptions.gridSnapOrigin=a)}},dragsLink:{get:function(){return this.dragOptions.dragsLink},
set:function(a){this.dragOptions.dragsLink=a}},dragsTree:{get:function(){return this.dragOptions.dragsTree},set:function(a){this.dragOptions.dragsTree=a}},currentPart:{get:function(){return this.ba},set:function(a){this.ba=a}},copiedParts:{get:function(){return this.u},set:function(a){this.u=a}},draggedParts:{get:function(){return this.Ma},set:function(a){this.Ma=a}},draggingParts:{
get:function(){return null!==this.copiedParts?this.copiedParts.Yd():null!==this.draggedParts?this.draggedParts.Yd():this.xg}},draggedLink:{get:function(){return this.diagram.draggedLink},set:function(a){this.diagram.draggedLink=a}},isDragOutStarted:{get:function(){return this.Vc},set:function(a){this.Vc=a}},startPoint:{get:function(){return this.xs},set:function(a){this.xs.A(a)||this.xs.assign(a)}},
delay:{get:function(){return this.Rk},set:function(a){this.Rk=a}}});xf.prototype.getDraggingSource=xf.prototype.Xy;var Rf=null,If=null,Mf=null;xf.className="DraggingTool";Rf=new E;Ta("draggingTool",function(){return this.findTool("Dragging")},function(a){vf(this,"Dragging",a,this.mouseMoveTools)});Ua.prototype.doCancel=function(){null!==If&&If.doCancel();kf.prototype.doCancel.call(this)};
function rg(){kf.call(this);this.xg=100;this.ba=!1;var a=new S,b=new W;b.isPanelMain=!0;b.stroke="blue";a.add(b);b=new W;b.toArrow="Standard";b.fill="blue";b.stroke="blue";a.add(b);a.layerName="Tool";this.Gm=a;a=new V;b=new W;b.portId="";b.figure="Rectangle";b.fill=null;b.stroke="magenta";b.strokeWidth=2;b.desiredSize=gc;a.add(b);a.selectable=!1;a.layerName="Tool";this.Em=a;this.Fm=b;a=new V;b=new W;b.portId="";b.figure="Rectangle";b.fill=null;b.stroke="magenta";b.strokeWidth=2;b.desiredSize=gc;a.add(b);
a.selectable=!1;a.layerName="Tool";this.zq=a;this.Aq=b;this.Vc=this.Kc=this.Ma=this.La=this.Vb=null;this.K=!0;this.Ox=new Lb;this.Cm=this.ji=this.Dm=null}oa(rg,kf);rg.prototype.doStop=function(){this.diagram.wf();this.originalToPort=this.originalToNode=this.originalFromPort=this.originalFromNode=this.originalLink=null;this.validPortsCache.clear();this.targetPort=null};
rg.prototype.copyPortProperties=function(a,b,c,d,e){if(null!==a&&null!==b&&null!==c&&null!==d){var f=b.Ce(),g=L.alloc();g.width=b.naturalBounds.width*f;g.height=b.naturalBounds.height*f;d.desiredSize=g;L.free(g);e?(d.toSpot=b.toSpot,d.toEndSegmentLength=b.toEndSegmentLength):(d.fromSpot=b.fromSpot,d.fromEndSegmentLength=b.fromEndSegmentLength);c.locationSpot=dd;f=H.alloc();c.location=b.ma(dd,f);H.free(f);d.angle=b.Li();null!==this.portTargeted&&this.portTargeted(a,b,c,d,e)}};
rg.prototype.setNoTargetPortProperties=function(a,b,c){null!==b&&(b.desiredSize=gc,b.fromSpot=Sc,b.toSpot=Sc);null!==a&&(a.location=this.diagram.lastInput.documentPoint);null!==this.portTargeted&&this.portTargeted(null,null,a,b,c)};rg.prototype.doMouseDown=function(){this.isActive&&this.doMouseMove()};
rg.prototype.doMouseMove=function(){if(this.isActive){var a=this.diagram;this.targetPort=this.findTargetPort(this.isForwards);if(null!==this.targetPort&&this.targetPort.part instanceof V){var b=this.targetPort.part;this.isForwards?this.copyPortProperties(b,this.targetPort,this.temporaryToNode,this.temporaryToPort,!0):this.copyPortProperties(b,this.targetPort,this.temporaryFromNode,this.temporaryFromPort,!1)}else this.isForwards?this.setNoTargetPortProperties(this.temporaryToNode,this.temporaryToPort,
!0):this.setNoTargetPortProperties(this.temporaryFromNode,this.temporaryFromPort,!1);(a.allowHorizontalScroll||a.allowVerticalScroll)&&a.Ns(a.lastInput.viewPoint)}};rg.prototype.findValidLinkablePort=function(a,b){if(null===a)return null;var c=a.part;if(!(c instanceof V))return null;for(;null!==a;){var d=b?a.toLinkable:a.fromLinkable;if(!0===d&&(null!==a.portId||a instanceof V)&&(b?this.isValidTo(c,a):this.isValidFrom(c,a)))return a;if(!1===d)break;a=a.panel}return null};
rg.prototype.findTargetPort=function(a){var b=this.diagram,c=b.lastInput.documentPoint,d=this.portGravity;0>=d&&(d=.1);var e=this,f=b.og(c,d,function(b){return e.findValidLinkablePort(b,a)},null,!0);d=Infinity;b=null;for(f=f.iterator;f.next();){var g=f.value,h=g.part;if(h instanceof V){var k=g.ma(dd,H.alloc()),l=c.x-k.x,m=c.y-k.y;H.free(k);k=l*l+m*m;k<d&&(l=this.validPortsCache.J(g),null!==l?l&&(b=g,d=k):a&&this.isValidLink(this.originalFromNode,this.originalFromPort,h,g)||!a&&this.isValidLink(h,
g,this.originalToNode,this.originalToPort)?(this.validPortsCache.add(g,!0),b=g,d=k):this.validPortsCache.add(g,!1))}}return null!==b&&(c=b.part,c instanceof V&&(null===c.layer||c.layer.allowLink))?b:null};
rg.prototype.isValidFrom=function(a,b){if(null===a||null===b)return this.isUnconnectedLinkValid;if(this.diagram.currentTool===this&&(null!==a.layer&&!a.layer.allowLink||!0!==b.fromLinkable))return!1;var c=b.fromMaxLinks;if(Infinity>c){if(null!==this.originalLink&&a===this.originalFromNode&&b===this.originalFromPort)return!0;b=b.portId;null===b&&(b="");if(a.Vp(b).count>=c)return!1}return!0};
rg.prototype.isValidTo=function(a,b){if(null===a||null===b)return this.isUnconnectedLinkValid;if(this.diagram.currentTool===this&&(null!==a.layer&&!a.layer.allowLink||!0!==b.toLinkable))return!1;var c=b.toMaxLinks;if(Infinity>c){if(null!==this.originalLink&&a===this.originalToNode&&b===this.originalToPort)return!0;b=b.portId;null===b&&(b="");if(a.sd(b).count>=c)return!1}return!0};
rg.prototype.isInSameNode=function(a,b){if(null===a||null===b)return!1;if(a===b)return!0;a=a.part;b=b.part;return null!==a&&a===b};rg.prototype.isLinked=function(a,b){if(null===a||null===b)return!1;var c=a.part;if(!(c instanceof V))return!1;a=a.portId;null===a&&(a="");var d=b.part;if(!(d instanceof V))return!1;b=b.portId;null===b&&(b="");for(b=d.sd(b);b.next();)if(d=b.value,d.fromNode===c&&d.fromPortId===a)return!0;return!1};
rg.prototype.isValidLink=function(a,b,c,d){if(!this.isValidFrom(a,b)||!this.isValidTo(c,d)||!(null===b||null===d||(b.fromLinkableSelfNode&&d.toLinkableSelfNode||!this.isInSameNode(b,d))&&(b.fromLinkableDuplicates&&d.toLinkableDuplicates||!this.isLinked(b,d)))||null!==this.originalLink&&(null!==a&&this.isLabelDependentOnLink(a,this.originalLink)||null!==c&&this.isLabelDependentOnLink(c,this.originalLink))||null!==a&&null!==c&&(null===a.data&&null!==c.data||null!==a.data&&null===c.data)||!this.isValidCycle(a,
c,this.originalLink))return!1;if(null!==a){var e=a.linkValidation;if(null!==e&&!e(a,b,c,d,this.originalLink))return!1}if(null!==c&&(e=c.linkValidation,null!==e&&!e(a,b,c,d,this.originalLink)))return!1;e=this.linkValidation;return null!==e?e(a,b,c,d,this.originalLink):!0};rg.prototype.isLabelDependentOnLink=function(a,b){if(null===a)return!1;var c=a.labeledLink;if(null===c)return!1;if(c===b)return!0;var d=new F;d.add(a);return sg(this,c,b,d)};
function sg(a,b,c,d){if(b===c)return!0;var e=b.fromNode;if(null!==e&&e.isLinkLabel&&(d.add(e),sg(a,e.labeledLink,c,d)))return!0;b=b.toNode;return null!==b&&b.isLinkLabel&&(d.add(b),sg(a,b.labeledLink,c,d))?!0:!1}
rg.prototype.isValidCycle=function(a,b,c){void 0===c&&(c=null);if(null===a||null===b)return this.isUnconnectedLinkValid;var d=this.diagram.validCycle;if(d!==tg){if(d===ug){d=c||this.temporaryLink;if(null!==d&&!d.isTreeLink)return!0;for(d=b.linksConnected;d.next();){var e=d.value;if(e!==c&&e.isTreeLink&&e.toNode===b)return!1}return!vg(this,a,b,c,!0)}if(d===wg){d=c||this.temporaryLink;if(null!==d&&!d.isTreeLink)return!0;for(d=a.linksConnected;d.next();)if(e=d.value,e!==c&&e.isTreeLink&&e.fromNode===
a)return!1;return!vg(this,a,b,c,!0)}if(d===xg)return a===b?a=!0:(d=new F,d.add(b),a=yg(this,d,a,b,c)),!a;if(d===zg)return!vg(this,a,b,c,!1);if(d===Ag)return a===b?a=!0:(d=new F,d.add(b),a=Hg(this,d,a,b,c)),!a}return!0};function vg(a,b,c,d,e){if(b===c)return!0;if(null===b||null===c)return!1;for(var f=b.linksConnected;f.next();){var g=f.value;if(g!==d&&(!e||g.isTreeLink)&&g.toNode===b&&(g=g.fromNode,g!==b&&vg(a,g,c,d,e)))return!0}return!1}
function yg(a,b,c,d,e){if(c===d)return!0;if(null===c||null===d||b.contains(c))return!1;b.add(c);for(var f=c.linksConnected;f.next();){var g=f.value;if(g!==e&&g.toNode===c&&(g=g.fromNode,g!==c&&yg(a,b,g,d,e)))return!0}return!1}function Hg(a,b,c,d,e){if(c===d)return!0;if(null===c||null===d||b.contains(c))return!1;b.add(c);for(var f=c.linksConnected;f.next();){var g=f.value;if(g!==e){var h=g.fromNode;g=g.toNode;h=h===c?g:h;if(h!==c&&Hg(a,b,h,d,e))return!0}}return!1}
pa.Object.defineProperties(rg.prototype,{portGravity:{get:function(){return this.xg},set:function(a){0<=a&&(this.xg=a)}},isUnconnectedLinkValid:{get:function(){return this.ba},set:function(a){this.ba=a}},temporaryLink:{get:function(){return this.Gm},set:function(a){this.Gm=a}},temporaryFromNode:{get:function(){return this.Em},set:function(a){this.Em=a}},temporaryFromPort:{
get:function(){return this.Fm},set:function(a){this.Fm=a}},temporaryToNode:{get:function(){return this.zq},set:function(a){this.zq=a}},temporaryToPort:{get:function(){return this.Aq},set:function(a){this.Aq=a}},originalLink:{get:function(){return this.Vb},set:function(a){this.Vb=a}},originalFromNode:{get:function(){return this.La},set:function(a){this.La=a}},originalFromPort:{
get:function(){return this.Ma},set:function(a){this.Ma=a}},originalToNode:{get:function(){return this.Kc},set:function(a){this.Kc=a}},originalToPort:{get:function(){return this.Vc},set:function(a){this.Vc=a}},isForwards:{get:function(){return this.K},set:function(a){this.K=a}},validPortsCache:{get:function(){return this.Ox}},targetPort:{
get:function(){return this.Dm},set:function(a){this.Dm=a}},linkValidation:{get:function(){return this.ji},set:function(a){this.ji=a}},portTargeted:{get:function(){return this.Cm},set:function(a){this.Cm=a}}});rg.className="LinkingBaseTool";function Ig(){rg.call(this);this.name="Linking";this.u={};this.l=null;this.L=Jg;this.Dh=null}oa(Ig,rg);
Ig.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;return a.isReadOnly||a.isModelReadOnly||!a.allowLink||!a.model.ct()||!a.lastInput.left||a.currentTool!==this&&!this.isBeyondDragSize()?!1:null!==this.findLinkablePort()};
Ig.prototype.findLinkablePort=function(){var a=this.diagram,b=this.startObject;null===b&&(b=a.Rb(a.firstInput.documentPoint,null,null));if(null===b||!(b.part instanceof V))return null;a=this.direction;if(a===Jg||a===Kg){var c=this.findValidLinkablePort(b,!1);if(null!==c)return this.isForwards=!0,c}if(a===Jg||a===Lg)if(b=this.findValidLinkablePort(b,!0),null!==b)return this.isForwards=!1,b;return null};
Ig.prototype.doActivate=function(){var a=this.diagram,b=this.findLinkablePort();null!==b&&(this.Aa(this.name),a.isMouseCaptured=!0,a.currentCursor="pointer",this.isForwards?(null===this.temporaryToNode||this.temporaryToNode.location.o()||(this.temporaryToNode.location=a.lastInput.documentPoint),this.originalFromPort=b,b=this.originalFromPort.part,b instanceof V&&(this.originalFromNode=b),this.copyPortProperties(this.originalFromNode,this.originalFromPort,this.temporaryFromNode,this.temporaryFromPort,
!1)):(null===this.temporaryFromNode||this.temporaryFromNode.location.o()||(this.temporaryFromNode.location=a.lastInput.documentPoint),this.originalToPort=b,b=this.originalToPort.part,b instanceof V&&(this.originalToNode=b),this.copyPortProperties(this.originalToNode,this.originalToPort,this.temporaryToNode,this.temporaryToPort,!0)),a.add(this.temporaryFromNode),a.add(this.temporaryToNode),null!==this.temporaryLink&&(null!==this.temporaryFromNode&&(this.temporaryLink.fromNode=this.temporaryFromNode),
null!==this.temporaryToNode&&(this.temporaryLink.toNode=this.temporaryToNode),this.temporaryLink.isTreeLink=this.isNewTreeLink(),this.temporaryLink.Ra(),a.add(this.temporaryLink)),this.isActive=!0)};Ig.prototype.doDeactivate=function(){this.isActive=!1;var a=this.diagram;a.remove(this.temporaryLink);a.remove(this.temporaryFromNode);a.remove(this.temporaryToNode);a.isMouseCaptured=!1;a.currentCursor="";this.wg()};Ig.prototype.doStop=function(){rg.prototype.doStop.call(this);this.startObject=null};
Ig.prototype.doMouseUp=function(){if(this.isActive){var a=this.diagram,b=this.transactionResult=null,c=null,d=null,e=null,f=this.targetPort=this.findTargetPort(this.isForwards);if(null!==f){var g=f.part;g instanceof V&&(this.isForwards?(null!==this.originalFromNode&&(b=this.originalFromNode,c=this.originalFromPort),d=g,e=f):(b=g,c=f,null!==this.originalToNode&&(d=this.originalToNode,e=this.originalToPort)))}else this.isForwards?null!==this.originalFromNode&&this.isUnconnectedLinkValid&&(b=this.originalFromNode,
c=this.originalFromPort):null!==this.originalToNode&&this.isUnconnectedLinkValid&&(d=this.originalToNode,e=this.originalToPort);null!==b||null!==d?(g=this.insertLink(b,c,d,e),null!==g?(null===f&&(this.isForwards?g.defaultToPoint=a.lastInput.documentPoint:g.defaultFromPoint=a.lastInput.documentPoint),a.allowSelect&&a.select(g),this.transactionResult=this.name,a.aa("LinkDrawn",g)):(a.model.Lu(),this.doNoLink(b,c,d,e))):this.isForwards?this.doNoLink(this.originalFromNode,this.originalFromPort,null,null):
this.doNoLink(null,null,this.originalToNode,this.originalToPort)}this.stopTool()};Ig.prototype.isNewTreeLink=function(){var a=this.archetypeLinkData;if(null===a)return!0;if(a instanceof S)return a.isTreeLink;var b=this.diagram;if(null===b)return!0;a=b.partManager.getLinkCategoryForData(a);b=b.partManager.findLinkTemplateForCategory(a);return null!==b?b.isTreeLink:!0};Ig.prototype.insertLink=function(a,b,c,d){return this.diagram.partManager.insertLink(a,b,c,d)};Ig.prototype.doNoLink=function(){};
pa.Object.defineProperties(Ig.prototype,{archetypeLinkData:{get:function(){return this.u},set:function(a){this.u=a}},archetypeLabelNodeData:{get:function(){return this.l},set:function(a){this.l=a}},direction:{get:function(){return this.L},set:function(a){this.L=a}},startObject:{get:function(){return this.Dh},set:function(a){this.Dh=a}}});
var Jg=new D(Ig,"Either",0),Kg=new D(Ig,"ForwardsOnly",0),Lg=new D(Ig,"BackwardsOnly",0);Ig.className="LinkingTool";Ig.Either=Jg;Ig.ForwardsOnly=Kg;Ig.BackwardsOnly=Lg;
function Nf(){rg.call(this);this.name="Relinking";var a=new W;a.figure="Diamond";a.desiredSize=ic;a.fill="lightblue";a.stroke="dodgerblue";a.cursor="pointer";a.segmentIndex=0;this.u=a;a=new W;a.figure="Diamond";a.desiredSize=ic;a.fill="lightblue";a.stroke="dodgerblue";a.cursor="pointer";a.segmentIndex=-1;this.Dh=a;this.l=null;this.Fw=new N}oa(Nf,rg);
Nf.prototype.updateAdornments=function(a){if(null!==a&&a instanceof S){var b="RelinkFrom",c=null;if(a.isSelected&&!this.diagram.isReadOnly){var d=a.selectionObject;null!==d&&a.canRelinkFrom()&&a.actualBounds.o()&&a.isVisible()&&d.actualBounds.o()&&d.sf()&&(c=a.bk(b),null===c&&(c=this.makeAdornment(d,!1),a.qh(b,c)))}null===c&&a.uf(b);b="RelinkTo";c=null;a.isSelected&&!this.diagram.isReadOnly&&(d=a.selectionObject,null!==d&&a.canRelinkTo()&&a.actualBounds.o()&&a.isVisible()&&d.actualBounds.o()&&d.sf()&&
(c=a.bk(b),null===c?(c=this.makeAdornment(d,!0),a.qh(b,c)):c.s()));null===c&&a.uf(b)}};Nf.prototype.makeAdornment=function(a,b){var c=new pf;c.type=X.Link;b=b?this.toHandleArchetype:this.fromHandleArchetype;null!==b&&c.add(b.copy());c.adornedObject=a;return c};
Nf.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;if(a.isReadOnly||a.isModelReadOnly||!a.allowRelink||!a.model.ct()||!a.lastInput.left)return!1;var b=this.findToolHandleAt(a.firstInput.documentPoint,"RelinkFrom");null===b&&(b=this.findToolHandleAt(a.firstInput.documentPoint,"RelinkTo"));return null!==b};
Nf.prototype.doActivate=function(){var a=this.diagram;if(null===this.originalLink){var b=this.handle;null===b&&(b=this.findToolHandleAt(a.firstInput.documentPoint,"RelinkFrom"),null===b&&(b=this.findToolHandleAt(a.firstInput.documentPoint,"RelinkTo")));if(null===b)return;var c=b.part;if(!(c instanceof pf&&c.adornedPart instanceof S))return;this.handle=b;this.isForwards=null===c||"RelinkTo"===c.category;this.originalLink=c.adornedPart}this.Aa(this.name);a.isMouseCaptured=!0;a.currentCursor="pointer";
this.originalFromPort=this.originalLink.fromPort;this.originalFromNode=this.originalLink.fromNode;this.originalToPort=this.originalLink.toPort;this.originalToNode=this.originalLink.toNode;this.Fw.set(this.originalLink.actualBounds);null!==this.originalLink&&0<this.originalLink.pointsCount&&(null===this.originalLink.fromNode&&(null!==this.temporaryFromPort&&(this.temporaryFromPort.desiredSize=fc),null!==this.temporaryFromNode&&(this.temporaryFromNode.location=this.originalLink.i(0))),null===this.originalLink.toNode&&
(null!==this.temporaryToPort&&(this.temporaryToPort.desiredSize=fc),null!==this.temporaryToNode&&(this.temporaryToNode.location=this.originalLink.i(this.originalLink.pointsCount-1))));this.copyPortProperties(this.originalFromNode,this.originalFromPort,this.temporaryFromNode,this.temporaryFromPort,!1);this.copyPortProperties(this.originalToNode,this.originalToPort,this.temporaryToNode,this.temporaryToPort,!0);a.add(this.temporaryFromNode);a.add(this.temporaryToNode);null!==this.temporaryLink&&(null!==
this.temporaryFromNode&&(this.temporaryLink.fromNode=this.temporaryFromNode),null!==this.temporaryToNode&&(this.temporaryLink.toNode=this.temporaryToNode),this.copyLinkProperties(this.originalLink,this.temporaryLink),this.temporaryLink.Ra(),a.add(this.temporaryLink));this.isActive=!0};
Nf.prototype.copyLinkProperties=function(a,b){if(null!==a&&null!==b){b.adjusting=a.adjusting;b.corner=a.corner;var c=a.curve;if(c===Mg||c===Ng)c=Og;b.curve=c;b.curviness=a.curviness;b.isTreeLink=a.isTreeLink;b.points=a.points;b.routing=a.routing;b.smoothness=a.smoothness;b.fromSpot=a.fromSpot;b.fromEndSegmentLength=a.fromEndSegmentLength;b.fromShortLength=a.fromShortLength;b.toSpot=a.toSpot;b.toEndSegmentLength=a.toEndSegmentLength;b.toShortLength=a.toShortLength}};
Nf.prototype.doDeactivate=function(){this.isActive=!1;var a=this.diagram;a.remove(this.temporaryLink);a.remove(this.temporaryFromNode);a.remove(this.temporaryToNode);a.isMouseCaptured=!1;a.currentCursor="";this.wg()};Nf.prototype.doStop=function(){rg.prototype.doStop.call(this);this.handle=null};
Nf.prototype.doMouseUp=function(){if(this.isActive){var a=this.diagram;this.transactionResult=null;var b=this.originalFromNode,c=this.originalFromPort,d=this.originalToNode,e=this.originalToPort,f=this.originalLink;this.targetPort=this.findTargetPort(this.isForwards);if(null!==this.targetPort){var g=this.targetPort.part;g instanceof V&&(this.isForwards?(d=g,e=this.targetPort):(b=g,c=this.targetPort))}else this.isUnconnectedLinkValid?this.isForwards?e=d=null:c=b=null:f=null;null!==f?(this.reconnectLink(f,
this.isForwards?d:b,this.isForwards?e:c,this.isForwards),null===this.targetPort&&(this.isForwards?f.defaultToPoint=a.lastInput.documentPoint:f.defaultFromPoint=a.lastInput.documentPoint,f.Ra()),a.allowSelect&&(f.isSelected=!0),this.transactionResult=this.name,a.aa("LinkRelinked",f,this.isForwards?this.originalToPort:this.originalFromPort)):this.doNoRelink(this.originalLink,this.isForwards);this.originalLink.cq(this.Fw)}this.stopTool()};
Nf.prototype.reconnectLink=function(a,b,c,d){c=null!==c&&null!==c.portId?c.portId:"";d?(a.toNode=b,a.toPortId=c):(a.fromNode=b,a.fromPortId=c);return!0};Nf.prototype.doNoRelink=function(){};
function kg(a,b,c,d,e){null!==b?(a.copyPortProperties(b,c,a.temporaryFromNode,a.temporaryFromPort,!1),a.diagram.add(a.temporaryFromNode)):a.diagram.remove(a.temporaryFromNode);null!==d?(a.copyPortProperties(d,e,a.temporaryToNode,a.temporaryToPort,!0),a.diagram.add(a.temporaryToNode)):a.diagram.remove(a.temporaryToNode)}
pa.Object.defineProperties(Nf.prototype,{fromHandleArchetype:{get:function(){return this.u},set:function(a){this.u=a}},toHandleArchetype:{get:function(){return this.Dh},set:function(a){this.Dh=a}},handle:{get:function(){return this.l},set:function(a){if(null!==a&&!(a.part instanceof pf))throw Error("new handle is not in an Adornment: "+a);this.l=a}}});Nf.className="RelinkingTool";
Ta("linkingTool",function(){return this.findTool("Linking")},function(a){vf(this,"Linking",a,this.mouseMoveTools)});Ta("relinkingTool",function(){return this.findTool("Relinking")},function(a){vf(this,"Relinking",a,this.mouseDownTools)});
function Pg(){kf.call(this);this.name="LinkReshaping";var a=new W;a.figure="Rectangle";a.desiredSize=hc;a.fill="lightblue";a.stroke="dodgerblue";this.u=a;a=new W;a.figure="Diamond";a.desiredSize=ic;a.fill="lightblue";a.stroke="dodgerblue";a.cursor="move";this.K=a;this.ba=3;this.Gt=this.l=null;this.ul=new H;this.es=new E}oa(Pg,kf);Pg.prototype.dv=function(a){return a&&a.ks&&0!==a.ks.value?a.ks:Qg};Pg.prototype.wm=function(a,b){a.ks=b};
Pg.prototype.updateAdornments=function(a){if(null!==a&&a instanceof S){var b=null;if(a.isSelected&&!this.diagram.isReadOnly){var c=a.path;null!==c&&a.canReshape()&&a.actualBounds.o()&&a.isVisible()&&c.actualBounds.o()&&c.sf()&&(b=a.bk(this.name),null===b||b.Cw!==a.pointsCount||b.Nw!==a.resegmentable)&&(b=this.makeAdornment(c),null!==b&&(b.Cw=a.pointsCount,b.Nw=a.resegmentable,a.qh(this.name,b)))}null===b&&a.uf(this.name)}};
Pg.prototype.makeAdornment=function(a){var b=a.part,c=b.pointsCount,d=b.isOrthogonal,e=null;if(null!==b.points&&1<c){e=new pf;e.type=X.Link;c=b.firstPickIndex;var f=b.lastPickIndex,g=d?1:0;if(b.resegmentable&&b.computeCurve()!==Rg)for(var h=c+g;h<f-g;h++){var k=this.makeResegmentHandle(a,h);null!==k&&(k.segmentIndex=h,k.segmentFraction=.5,k.fromMaxLinks=999,e.add(k))}for(g=c+1;g<f;g++)if(h=this.makeHandle(a,g),null!==h){h.segmentIndex=g;if(g!==c)if(g===c+1&&d){k=b.i(c);var l=b.i(c+1);I.w(k.x,l.x)&&
I.w(k.y,l.y)&&(l=b.i(c-1));I.w(k.x,l.x)?(this.wm(h,Sg),h.cursor="n-resize"):I.w(k.y,l.y)&&(this.wm(h,Tg),h.cursor="w-resize")}else g===f-1&&d?(k=b.i(f-1),l=b.i(f),I.w(k.x,l.x)&&I.w(k.y,l.y)&&(k=b.i(f+1)),I.w(k.x,l.x)?(this.wm(h,Sg),h.cursor="n-resize"):I.w(k.y,l.y)&&(this.wm(h,Tg),h.cursor="w-resize")):g!==f&&(this.wm(h,Ug),h.cursor="move");e.add(h)}e.adornedObject=a}return e};Pg.prototype.makeHandle=function(){var a=this.handleArchetype;return null===a?null:a.copy()};
Pg.prototype.makeResegmentHandle=function(){var a=this.midHandleArchetype;return null===a?null:a.copy()};Pg.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;return!a.isReadOnly&&a.allowReshape&&a.lastInput.left?null!==this.findToolHandleAt(a.firstInput.documentPoint,this.name):!1};
Pg.prototype.doActivate=function(){var a=this.diagram;null===this.handle&&(this.handle=this.findToolHandleAt(a.firstInput.documentPoint,this.name));if(null!==this.handle){var b=this.handle.part.adornedPart;if(b instanceof S){this.Gt=b;a.isMouseCaptured=!0;this.Aa(this.name);if(b.resegmentable&&999===this.handle.fromMaxLinks){var c=b.points.copy(),d=this.getResegmentingPoint();c.Ib(this.handle.segmentIndex+1,d);b.isOrthogonal&&c.Ib(this.handle.segmentIndex+1,d);b.points=c;b.Jb();b.updateAdornments();
this.handle=this.findToolHandleAt(a.firstInput.documentPoint,this.name);if(null===this.handle){this.doDeactivate();return}}this.ul=b.i(this.handle.segmentIndex);this.es=b.points.copy();this.isActive=!0}}};Pg.prototype.doDeactivate=function(){this.wg();this.Gt=this.handle=null;this.isActive=this.diagram.isMouseCaptured=!1};Pg.prototype.doCancel=function(){var a=this.adornedLink;null!==a&&(a.points=this.es);this.stopTool()};Pg.prototype.getResegmentingPoint=function(){return this.handle.ma(dd)};
Pg.prototype.doMouseMove=function(){var a=this.diagram;this.isActive&&(a=this.computeReshape(a.lastInput.documentPoint),this.reshape(a))};
Pg.prototype.doMouseUp=function(){var a=this.diagram;if(this.isActive){var b=this.computeReshape(a.lastInput.documentPoint);this.reshape(b);b=this.adornedLink;if(null!==b&&b.resegmentable){var c=this.handle.segmentIndex,d=b.i(c-1),e=b.i(c),f=b.i(c+1);if(b.isOrthogonal){if(c>b.firstPickIndex+1&&c<b.lastPickIndex-1){var g=b.i(c-2);if(Math.abs(d.x-e.x)<this.resegmentingDistance&&Math.abs(d.y-e.y)<this.resegmentingDistance&&(Vg(this,g,d,e,f,!0)||Vg(this,g,d,e,f,!1))){var h=b.points.copy();Vg(this,g,d,
e,f,!0)?(h.gd(c-2,new H(g.x,(f.y+g.y)/2)),h.gd(c+1,new H(f.x,(f.y+g.y)/2))):(h.gd(c-2,new H((f.x+g.x)/2,g.y)),h.gd(c+1,new H((f.x+g.x)/2,f.y)));h.mb(c);h.mb(c-1);b.points=h;b.Jb()}else g=b.i(c+2),Math.abs(e.x-f.x)<this.resegmentingDistance&&Math.abs(e.y-f.y)<this.resegmentingDistance&&(Vg(this,d,e,f,g,!0)||Vg(this,d,e,f,g,!1))&&(h=b.points.copy(),Vg(this,d,e,f,g,!0)?(h.gd(c-1,new H(d.x,(d.y+g.y)/2)),h.gd(c+2,new H(g.x,(d.y+g.y)/2))):(h.gd(c-1,new H((d.x+g.x)/2,d.y)),h.gd(c+2,new H((d.x+g.x)/2,g.y))),
h.mb(c+1),h.mb(c),b.points=h,b.Jb())}}else g=H.alloc(),I.Si(d.x,d.y,f.x,f.y,e.x,e.y,g)&&g.Be(e)<this.resegmentingDistance*this.resegmentingDistance&&(d=b.points.copy(),d.mb(c),b.points=d,b.Jb()),H.free(g)}a.Xa();this.transactionResult=this.name;a.aa("LinkReshaped",this.adornedLink,this.es)}this.stopTool()};
function Vg(a,b,c,d,e,f){return f?Math.abs(b.y-c.y)<a.resegmentingDistance&&Math.abs(c.y-d.y)<a.resegmentingDistance&&Math.abs(d.y-e.y)<a.resegmentingDistance:Math.abs(b.x-c.x)<a.resegmentingDistance&&Math.abs(c.x-d.x)<a.resegmentingDistance&&Math.abs(d.x-e.x)<a.resegmentingDistance}
Pg.prototype.reshape=function(a){var b=this.adornedLink;b.Ch();var c=this.handle.segmentIndex,d=this.dv(this.handle);if(b.isOrthogonal)if(c===b.firstPickIndex+1)c=b.firstPickIndex+1,d===Sg?(b.M(c,b.i(c-1).x,a.y),b.M(c+1,b.i(c+2).x,a.y)):d===Tg&&(b.M(c,a.x,b.i(c-1).y),b.M(c+1,a.x,b.i(c+2).y));else if(c===b.lastPickIndex-1)c=b.lastPickIndex-1,d===Sg?(b.M(c-1,b.i(c-2).x,a.y),b.M(c,b.i(c+1).x,a.y)):d===Tg&&(b.M(c-1,a.x,b.i(c-2).y),b.M(c,a.x,b.i(c+1).y));else{d=c;var e=b.i(d),f=b.i(d-1),g=b.i(d+1);I.w(f.x,
e.x)&&I.w(e.y,g.y)?(I.w(f.x,b.i(d-2).x)&&!I.w(f.y,b.i(d-2).y)?(b.m(d,a.x,f.y),c++,d++):b.M(d-1,a.x,f.y),I.w(g.y,b.i(d+2).y)&&!I.w(g.x,b.i(d+2).x)?b.m(d+1,g.x,a.y):b.M(d+1,g.x,a.y)):I.w(f.y,e.y)&&I.w(e.x,g.x)?(I.w(f.y,b.i(d-2).y)&&!I.w(f.x,b.i(d-2).x)?(b.m(d,f.x,a.y),c++,d++):b.M(d-1,f.x,a.y),I.w(g.x,b.i(d+2).x)&&!I.w(g.y,b.i(d+2).y)?b.m(d+1,a.x,g.y):b.M(d+1,a.x,g.y)):I.w(f.x,e.x)&&I.w(e.x,g.x)?(I.w(f.x,b.i(d-2).x)&&!I.w(f.y,b.i(d-2).y)?(b.m(d,a.x,f.y),c++,d++):b.M(d-1,a.x,f.y),I.w(g.x,b.i(d+2).x)&&
!I.w(g.y,b.i(d+2).y)?b.m(d+1,a.x,g.y):b.M(d+1,a.x,g.y)):I.w(f.y,e.y)&&I.w(e.y,g.y)&&(I.w(f.y,b.i(d-2).y)&&!I.w(f.x,b.i(d-2).x)?(b.m(d,f.x,a.y),c++,d++):b.M(d-1,f.x,a.y),I.w(g.y,b.i(d+2).y)&&!I.w(g.x,b.i(d+2).x)?b.m(d+1,g.x,a.y):b.M(d+1,g.x,a.y));b.M(c,a.x,a.y)}else b.M(c,a.x,a.y),d=b.fromNode,e=b.fromPort,null!==d&&(f=d.findVisibleNode(),null!==f&&f!==d&&(d=f,e=d.port)),1===c&&b.computeSpot(!0,e).jc()&&(f=e.ma(dd,H.alloc()),d=b.getLinkPointFromPoint(d,e,f,a,!0,H.alloc()),b.M(0,d.x,d.y),H.free(f),
H.free(d)),d=b.toNode,e=b.toPort,null!==d&&(f=d.findVisibleNode(),null!==f&&f!==d&&(d=f,e=d.port)),c===b.pointsCount-2&&b.computeSpot(!1,e).jc()&&(c=e.ma(dd,H.alloc()),a=b.getLinkPointFromPoint(d,e,c,a,!1,H.alloc()),b.M(b.pointsCount-1,a.x,a.y),H.free(c),H.free(a));b.lf()};Pg.prototype.computeReshape=function(a){var b=this.adornedLink,c=this.handle.segmentIndex;switch(this.dv(this.handle)){case Ug:return a;case Sg:return new H(b.i(c).x,a.y);case Tg:return new H(a.x,b.i(c).y);default:case Qg:return b.i(c)}};
pa.Object.defineProperties(Pg.prototype,{handleArchetype:{get:function(){return this.u},set:function(a){this.u=a}},midHandleArchetype:{get:function(){return this.K},set:function(a){this.K=a}},handle:{get:function(){return this.l},set:function(a){if(null!==a&&!(a.part instanceof pf))throw Error("new handle is not in an Adornment: "+a);this.l=a}},adornedLink:{get:function(){return this.Gt}},
resegmentingDistance:{get:function(){return this.ba},set:function(a){this.ba=a}},originalPoint:{get:function(){return this.ul}},originalPoints:{get:function(){return this.es}}});Pg.prototype.setReshapingBehavior=Pg.prototype.wm;Pg.prototype.getReshapingBehavior=Pg.prototype.dv;var Qg=new D(Pg,"None",0),Tg=new D(Pg,"Horizontal",1),Sg=new D(Pg,"Vertical",2),Ug=new D(Pg,"All",3);Pg.className="LinkReshapingTool";
Pg.None=Qg;Pg.Horizontal=Tg;Pg.Vertical=Sg;Pg.All=Ug;Ta("linkReshapingTool",function(){return this.findTool("LinkReshaping")},function(a){vf(this,"LinkReshaping",a,this.mouseDownTools)});
function Wg(){kf.call(this);this.name="Resizing";this.Qf=(new L(1,1)).freeze();this.Pf=(new L(9999,9999)).freeze();this.Dg=(new L(NaN,NaN)).freeze();this.K=!1;this.$d=null;var a=new W;a.alignmentFocus=dd;a.figure="Rectangle";a.desiredSize=hc;a.fill="lightblue";a.stroke="dodgerblue";a.strokeWidth=1;a.cursor="pointer";this.u=a;this.l=null;this.ul=new H;this.Ew=new L;this.Ko=new H;this.Xt=new L(0,0);this.Wt=new L(Infinity,Infinity);this.Vt=new L(1,1);this.Bw=!0}oa(Wg,kf);
Wg.prototype.updateAdornments=function(a){if(!(null===a||a instanceof S)){if(a.isSelected&&!this.diagram.isReadOnly){var b=a.resizeObject,c=a.bk(this.name);if(null!==b&&a.canResize()&&a.actualBounds.o()&&a.isVisible()&&b.actualBounds.o()&&b.sf()){if(null===c||c.adornedObject!==b)c=this.makeAdornment(b);if(null!==c){b=b.Li();og(a)&&this.updateResizeHandles(c,b);a.qh(this.name,c);return}}}a.uf(this.name)}};
Wg.prototype.makeAdornment=function(a){var b=a.part.resizeAdornmentTemplate;if(null===b){b=new pf;b.type=X.Spot;b.locationSpot=dd;var c=new Xg;c.isPanelMain=!0;b.add(c);b.add(this.makeHandle(a,Tc));b.add(this.makeHandle(a,Vc));b.add(this.makeHandle(a,hd));b.add(this.makeHandle(a,fd));b.add(this.makeHandle(a,Jd));b.add(this.makeHandle(a,Sd));b.add(this.makeHandle(a,Td));b.add(this.makeHandle(a,Kd))}else if(Yg(b),b=b.copy(),null===b)return null;b.adornedObject=a;return b};
Wg.prototype.makeHandle=function(a,b){a=this.handleArchetype;if(null===a)return null;a=a.copy();a.alignment=b;return a};
Wg.prototype.updateResizeHandles=function(a,b){if(null!==a)if(!a.alignment.Kb()&&("pointer"===a.cursor||0<a.cursor.indexOf("resize")))a:{var c=a.alignment;c.jc()&&(c=dd);if(0>=c.x)b=0>=c.y?b+225:1<=c.y?b+135:b+180;else if(1<=c.x)0>=c.y?b+=315:1<=c.y&&(b+=45);else if(0>=c.y)b+=270;else if(1<=c.y)b+=90;else break a;0>b?b+=360:360<=b&&(b-=360);a.cursor=22.5>b?"e-resize":67.5>b?"se-resize":112.5>b?"s-resize":157.5>b?"sw-resize":202.5>b?"w-resize":247.5>b?"nw-resize":292.5>b?"n-resize":337.5>b?"ne-resize":
"e-resize"}else if(a instanceof X)for(a=a.elements;a.next();)this.updateResizeHandles(a.value,b)};Wg.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;return!a.isReadOnly&&a.allowResize&&a.lastInput.left?null!==this.findToolHandleAt(a.firstInput.documentPoint,this.name):!1};
Wg.prototype.doActivate=function(){var a=this.diagram;null===this.handle&&(this.handle=this.findToolHandleAt(a.firstInput.documentPoint,this.name));null!==this.handle&&(this.adornedObject=this.handle.part.adornedObject,null!==this.adornedObject&&(this.ul.set(this.adornedObject.ma(this.handle.alignment.rv())),this.Ko.set(this.adornedObject.part.location),this.Ew.set(this.adornedObject.desiredSize),this.Vt=this.computeCellSize(),this.Xt=this.computeMinSize(),this.Wt=this.computeMaxSize(),a.isMouseCaptured=
!0,this.Bw=a.animationManager.isEnabled,a.animationManager.isEnabled=!1,this.Aa(this.name),this.isActive=!0))};Wg.prototype.doDeactivate=function(){var a=this.diagram;this.wg();this.$d=this.handle=null;this.isActive=a.isMouseCaptured=!1;a.animationManager.isEnabled=this.Bw};Wg.prototype.doCancel=function(){null!==this.adornedObject&&(this.adornedObject.desiredSize=this.originalDesiredSize,this.adornedObject.part.location=this.originalLocation);this.stopTool()};
Wg.prototype.doMouseMove=function(){var a=this.diagram;if(this.isActive){var b=this.Xt,c=this.Wt,d=this.Vt,e=this.adornedObject.Vs(a.lastInput.documentPoint,H.alloc()),f=this.computeReshape();b=this.computeResize(e,this.handle.alignment,b,c,d,f);this.resize(b);a.fd();H.free(e)}};
Wg.prototype.doMouseUp=function(){var a=this.diagram;if(this.isActive){var b=this.Xt,c=this.Wt,d=this.Vt,e=this.adornedObject.Vs(a.lastInput.documentPoint,H.alloc()),f=this.computeReshape();b=this.computeResize(e,this.handle.alignment,b,c,d,f);this.resize(b);H.free(e);a.Xa();this.transactionResult=this.name;a.aa("PartResized",this.adornedObject,this.originalDesiredSize)}this.stopTool()};
Wg.prototype.resize=function(a){var b=this.diagram,c=this.adornedObject,d=c.part;c.desiredSize=a.size;d.$b();a=this.adornedObject.ma(this.handle.alignment.rv());d instanceof ig?(c=new E,c.add(d),b.moveParts(c,this.ul.copy().Xd(a),!0)):d.location=d.location.copy().Xd(a).add(this.ul)};
Wg.prototype.computeResize=function(a,b,c,d,e,f){b.jc()&&(b=dd);var g=this.adornedObject.naturalBounds,h=g.x,k=g.y,l=g.x+g.width,m=g.y+g.height,n=1;if(!f){n=g.width;var p=g.height;0>=n&&(n=1);0>=p&&(p=1);n=p/n}p=H.alloc();I.Wp(a.x,a.y,h,k,e.width,e.height,p);a=g.copy();0>=b.x?0>=b.y?(a.x=Math.max(p.x,l-d.width),a.x=Math.min(a.x,l-c.width),a.width=Math.max(l-a.x,c.width),a.y=Math.max(p.y,m-d.height),a.y=Math.min(a.y,m-c.height),a.height=Math.max(m-a.y,c.height),f||(1<=a.height/a.width?(a.height=Math.max(Math.min(n*
a.width,d.height),c.height),a.width=a.height/n):(a.width=Math.max(Math.min(a.height/n,d.width),c.width),a.height=n*a.width),a.x=l-a.width,a.y=m-a.height)):1<=b.y?(a.x=Math.max(p.x,l-d.width),a.x=Math.min(a.x,l-c.width),a.width=Math.max(l-a.x,c.width),a.height=Math.max(Math.min(p.y-k,d.height),c.height),f||(1<=a.height/a.width?(a.height=Math.max(Math.min(n*a.width,d.height),c.height),a.width=a.height/n):(a.width=Math.max(Math.min(a.height/n,d.width),c.width),a.height=n*a.width),a.x=l-a.width)):(a.x=
Math.max(p.x,l-d.width),a.x=Math.min(a.x,l-c.width),a.width=l-a.x,f||(a.height=Math.max(Math.min(n*a.width,d.height),c.height),a.width=a.height/n,a.y=k+.5*(m-k-a.height))):1<=b.x?0>=b.y?(a.width=Math.max(Math.min(p.x-h,d.width),c.width),a.y=Math.max(p.y,m-d.height),a.y=Math.min(a.y,m-c.height),a.height=Math.max(m-a.y,c.height),f||(1<=a.height/a.width?(a.height=Math.max(Math.min(n*a.width,d.height),c.height),a.width=a.height/n):(a.width=Math.max(Math.min(a.height/n,d.width),c.width),a.height=n*a.width),
a.y=m-a.height)):1<=b.y?(a.width=Math.max(Math.min(p.x-h,d.width),c.width),a.height=Math.max(Math.min(p.y-k,d.height),c.height),f||(1<=a.height/a.width?(a.height=Math.max(Math.min(n*a.width,d.height),c.height),a.width=a.height/n):(a.width=Math.max(Math.min(a.height/n,d.width),c.width),a.height=n*a.width))):(a.width=Math.max(Math.min(p.x-h,d.width),c.width),f||(a.height=Math.max(Math.min(n*a.width,d.height),c.height),a.width=a.height/n,a.y=k+.5*(m-k-a.height))):0>=b.y?(a.y=Math.max(p.y,m-d.height),
a.y=Math.min(a.y,m-c.height),a.height=m-a.y,f||(a.width=Math.max(Math.min(a.height/n,d.width),c.width),a.height=n*a.width,a.x=h+.5*(l-h-a.width))):1<=b.y&&(a.height=Math.max(Math.min(p.y-k,d.height),c.height),f||(a.width=Math.max(Math.min(a.height/n,d.width),c.width),a.height=n*a.width,a.x=h+.5*(l-h-a.width)));H.free(p);return a};Wg.prototype.computeReshape=function(){var a=Zg;this.adornedObject instanceof W&&(a=$g(this.adornedObject));return!(a===ah||this.diagram.lastInput.shift)};
Wg.prototype.computeMinSize=function(){var a=this.adornedObject.minSize.copy(),b=this.minSize;!isNaN(b.width)&&b.width>a.width&&(a.width=b.width);!isNaN(b.height)&&b.height>a.height&&(a.height=b.height);return a};Wg.prototype.computeMaxSize=function(){var a=this.adornedObject.maxSize.copy(),b=this.maxSize;!isNaN(b.width)&&b.width<a.width&&(a.width=b.width);!isNaN(b.height)&&b.height<a.height&&(a.height=b.height);return a};
Wg.prototype.computeCellSize=function(){var a=new L(NaN,NaN),b=this.adornedObject.part;null!==b&&(b=b.resizeCellSize,!isNaN(b.width)&&0<b.width&&(a.width=b.width),!isNaN(b.height)&&0<b.height&&(a.height=b.height));b=this.cellSize;isNaN(a.width)&&!isNaN(b.width)&&0<b.width&&(a.width=b.width);isNaN(a.height)&&!isNaN(b.height)&&0<b.height&&(a.height=b.height);b=this.diagram;(isNaN(a.width)||isNaN(a.height))&&b&&(b=b.grid,null!==b&&b.visible&&this.isGridSnapEnabled&&(b=b.gridCellSize,isNaN(a.width)&&
!isNaN(b.width)&&0<b.width&&(a.width=b.width),isNaN(a.height)&&!isNaN(b.height)&&0<b.height&&(a.height=b.height)));if(isNaN(a.width)||0===a.width||Infinity===a.width)a.width=1;if(isNaN(a.height)||0===a.height||Infinity===a.height)a.height=1;return a};
pa.Object.defineProperties(Wg.prototype,{handleArchetype:{get:function(){return this.u},set:function(a){this.u=a}},handle:{get:function(){return this.l},set:function(a){if(null!==a&&!(a.part instanceof pf))throw Error("new handle is not in an Adornment: "+a);this.l=a}},adornedObject:{get:function(){return this.$d},set:function(a){if(null!==a&&a.part instanceof pf)throw Error("new handle must not be in an Adornment: "+
a);this.$d=a}},minSize:{get:function(){return this.Qf},set:function(a){if(!this.Qf.A(a)){var b=a.width;isNaN(b)&&(b=0);a=a.height;isNaN(a)&&(a=0);this.Qf.h(b,a)}}},maxSize:{get:function(){return this.Pf},set:function(a){if(!this.Pf.A(a)){var b=a.width;isNaN(b)&&(b=Infinity);a=a.height;isNaN(a)&&(a=Infinity);this.Pf.h(b,a)}}},cellSize:{get:function(){return this.Dg},set:function(a){this.Dg.A(a)||this.Dg.assign(a)}},
isGridSnapEnabled:{get:function(){return this.K},set:function(a){this.K=a}},originalDesiredSize:{get:function(){return this.Ew}},originalLocation:{get:function(){return this.Ko}}});Wg.className="ResizingTool";Ta("resizingTool",function(){return this.findTool("Resizing")},function(a){vf(this,"Resizing",a,this.mouseDownTools)});
function bh(){kf.call(this);this.name="Rotating";this.Ma=45;this.La=2;this.Ko=new H;this.$d=null;var a=new W;a.figure="Ellipse";a.desiredSize=ic;a.fill="lightblue";a.stroke="dodgerblue";a.strokeWidth=1;a.cursor="pointer";this.u=a;this.l=null;this.Dw=0;this.pu=new H(NaN,NaN);this.K=0;this.ba=50}oa(bh,kf);
bh.prototype.updateAdornments=function(a){if(null!==a){if(a.Ah()){var b=a.rotateObject;if(b===a||b===a.path||b.isPanelMain)return}if(a.isSelected&&!this.diagram.isReadOnly&&(b=a.rotateObject,null!==b&&a.canRotate()&&a.actualBounds.o()&&a.isVisible()&&b.actualBounds.o()&&b.sf())){var c=a.bk(this.name);if(null===c||c.adornedObject!==b)c=this.makeAdornment(b);if(null!==c){c.angle=b.Li();null===c.placeholder&&(c.location=this.computeAdornmentLocation(b));a.qh(this.name,c);return}}a.uf(this.name)}};
bh.prototype.makeAdornment=function(a){var b=a.part.rotateAdornmentTemplate;if(null===b){b=new pf;b.type=X.Position;b.locationSpot=dd;var c=this.handleArchetype;null!==c&&b.add(c.copy())}else if(Yg(b),b=b.copy(),null===b)return null;b.adornedObject=a;return b};bh.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;return!a.isReadOnly&&a.allowRotate&&a.lastInput.left?null!==this.findToolHandleAt(a.firstInput.documentPoint,this.name):!1};
bh.prototype.doActivate=function(){var a=this.diagram;if(null===this.adornedObject){null===this.handle&&(this.handle=this.findToolHandleAt(a.firstInput.documentPoint,this.name));if(null===this.handle)return;this.adornedObject=this.handle.part.adornedObject}null!==this.adornedObject&&(this.Dw=this.adornedObject.angle,this.pu=this.computeRotationPoint(this.adornedObject),this.Ko=this.adornedObject.part.location.copy(),a.isMouseCaptured=!0,a.delaysLayout=!0,this.Aa(this.name),this.isActive=!0)};
bh.prototype.computeRotationPoint=function(a){var b=a.part,c=b.locationObject;return b.rotationSpot.gb()?a.ma(b.rotationSpot):a===b||a===c?c.ma(b.locationSpot):a.ma(dd)};
bh.prototype.computeAdornmentLocation=function(a){var b=this.rotationPoint;b.o()||(b=this.computeRotationPoint(a));b=a.Vs(b);var c=this.handleAngle;0>c?c+=360:360<=c&&(c-=360);c=Math.round(45*Math.round(c/45));var d=this.handleDistance;0===c?b.x=a.naturalBounds.width+d:45===c?(b.x=a.naturalBounds.width+d,b.y=a.naturalBounds.height+d):90===c?b.y=a.naturalBounds.height+d:135===c?(b.x=-d,b.y=a.naturalBounds.height+d):180===c?b.x=-d:225===c?(b.x=-d,b.y=-d):270===c?b.y=-d:315===c&&(b.x=a.naturalBounds.width+
d,b.y=-d);return a.ma(b)};bh.prototype.doDeactivate=function(){var a=this.diagram;this.wg();this.$d=this.handle=null;this.pu=new H(NaN,NaN);this.isActive=a.isMouseCaptured=!1};bh.prototype.doCancel=function(){this.diagram.delaysLayout=!1;this.rotate(this.originalAngle);this.stopTool()};bh.prototype.doMouseMove=function(){var a=this.diagram;this.isActive&&(a=this.computeRotate(a.lastInput.documentPoint),this.rotate(a))};
bh.prototype.doMouseUp=function(){var a=this.diagram;if(this.isActive){a.delaysLayout=!1;var b=this.computeRotate(a.lastInput.documentPoint);this.rotate(b);a.Xa();this.transactionResult=this.name;a.aa("PartRotated",this.adornedObject,this.originalAngle)}this.stopTool()};bh.prototype.rotate=function(a){var b=this.adornedObject;if(null!==b){b.angle=a;b=b.part;b.$b();var c=b.locationObject,d=b.rotateObject;if(c===d||c.rg(d))c=this.Ko.copy(),b.location=c.Xd(this.rotationPoint).rotate(a-this.originalAngle).add(this.rotationPoint)}};
bh.prototype.computeRotate=function(a){a=this.rotationPoint.Wa(a)-this.handleAngle;var b=this.adornedObject.panel;null!==b&&(a-=b.Li());360<=a?a-=360:0>a&&(a+=360);b=Math.min(Math.abs(this.snapAngleMultiple),180);var c=Math.min(Math.abs(this.snapAngleEpsilon),b/2);!this.diagram.lastInput.shift&&0<b&&0<c&&(a%b<c?a=Math.floor(a/b)*b:a%b>b-c&&(a=(Math.floor(a/b)+1)*b));360<=a?a-=360:0>a&&(a+=360);return a};
pa.Object.defineProperties(bh.prototype,{handleArchetype:{get:function(){return this.u},set:function(a){this.u=a}},handle:{get:function(){return this.l},set:function(a){if(null!==a&&!(a.part instanceof pf))throw Error("new handle is not in an Adornment: "+a);this.l=a}},adornedObject:{get:function(){return this.$d},set:function(a){if(null!==a&&a.part instanceof pf)throw Error("new handle must not be in an Adornment: "+
a);this.$d=a}},snapAngleMultiple:{get:function(){return this.Ma},set:function(a){this.Ma=a}},snapAngleEpsilon:{get:function(){return this.La},set:function(a){this.La=a}},originalAngle:{get:function(){return this.Dw}},rotationPoint:{get:function(){return this.pu}},handleAngle:{get:function(){return this.K},set:function(a){this.K=a}},handleDistance:{
get:function(){return this.ba},set:function(a){this.ba=a}}});bh.className="RotatingTool";Ta("rotatingTool",function(){return this.findTool("Rotating")},function(a){vf(this,"Rotating",a,this.mouseDownTools)});function ch(){kf.call(this);this.name="ClickSelecting"}oa(ch,kf);ch.prototype.canStart=function(){return!this.isEnabled||this.isBeyondDragSize()?!1:!0};
ch.prototype.doMouseUp=function(){this.isActive&&(this.standardMouseSelect(),!this.standardMouseClick()&&this.diagram.lastInput.isTouchEvent&&this.diagram.toolManager.doToolTip());this.stopTool()};ch.className="ClickSelectingTool";function dh(){kf.call(this);this.name="Action";this.wk=null}oa(dh,kf);
dh.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram,b=a.lastInput,c=a.Rb(b.documentPoint,function(a){for(;null!==a.panel&&!a.isActionable;)a=a.panel;return a});if(null!==c){if(!c.isActionable)return!1;this.wk=c;a.Kk=a.Rb(b.documentPoint,null,null);return!0}return!1};dh.prototype.doMouseDown=function(){if(this.isActive){var a=this.diagram.lastInput,b=this.wk;null!==b&&(a.targetObject=b,null!==b.actionDown&&b.actionDown(a,b))}else this.canStart()&&this.doActivate()};
dh.prototype.doMouseMove=function(){if(this.isActive){var a=this.diagram.lastInput,b=this.wk;null!==b&&(a.targetObject=b,null!==b.actionMove&&b.actionMove(a,b))}};dh.prototype.doMouseUp=function(){if(this.isActive){var a=this.diagram.lastInput,b=this.wk;if(null===b)return;a.targetObject=b;null!==b.actionUp&&b.actionUp(a,b);this.standardMouseClick(function(a){for(;null!==a.panel&&(!a.isActionable||a!==b);)a=a.panel;return a},function(a){return a===b})}this.stopTool()};
dh.prototype.doCancel=function(){var a=this.diagram.lastInput,b=this.wk;null!==b&&(a.targetObject=b,null!==b.actionCancel&&b.actionCancel(a,b),this.stopTool())};dh.prototype.doStop=function(){this.wk=null};dh.className="ActionTool";function eh(){kf.call(this);this.name="ClickCreating";this.bj=null;this.u=!0;this.l=!1;this.vw=new H(0,0)}oa(eh,kf);
eh.prototype.canStart=function(){if(!this.isEnabled||null===this.archetypeNodeData)return!1;var a=this.diagram;if(a.isReadOnly||a.isModelReadOnly||!a.allowInsert||!a.lastInput.left||this.isBeyondDragSize())return!1;if(this.isDoubleClick){if(1===a.lastInput.clickCount&&(this.vw=a.lastInput.viewPoint.copy()),2!==a.lastInput.clickCount||this.isBeyondDragSize(this.vw))return!1}else if(1!==a.lastInput.clickCount)return!1;return a.currentTool!==this&&null!==a.$l(a.lastInput.documentPoint,!0)?!1:!0};
eh.prototype.doMouseUp=function(){var a=this.diagram;this.isActive&&this.insertPart(a.lastInput.documentPoint);this.stopTool()};
eh.prototype.insertPart=function(a){var b=this.diagram,c=this.archetypeNodeData;if(null===c)return null;this.Aa(this.name);var d=null;c instanceof T?c.bc()&&(Yg(c),d=c.copy(),null!==d&&b.add(d)):null!==c&&(c=b.model.copyNodeData(c),Ca(c)&&(b.model.jf(c),d=b.vc(c)));null!==d&&(c=H.allocAt(a.x,a.y),this.isGridSnapEnabled&&fh(this.diagram,d,a,c),d.location=c,b.allowSelect&&b.select(d),H.free(c));b.Xa();this.transactionResult=this.name;b.aa("PartCreated",d);this.wg();return d};
pa.Object.defineProperties(eh.prototype,{archetypeNodeData:{get:function(){return this.bj},set:function(a){this.bj=a}},isDoubleClick:{get:function(){return this.u},set:function(a){this.u=a}},isGridSnapEnabled:{get:function(){return this.l},set:function(a){this.l=a}}});eh.className="ClickCreatingTool";
function gh(){kf.call(this);this.name="DragSelecting";this.Rk=175;this.u=!1;var a=new T;a.layerName="Tool";a.selectable=!1;var b=new W;b.name="SHAPE";b.figure="Rectangle";b.fill=null;b.stroke="magenta";a.add(b);this.l=a}oa(gh,kf);
gh.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;if(!a.allowSelect)return!1;var b=a.lastInput;return!b.left||a.currentTool!==this&&(!this.isBeyondDragSize()||b.timestamp-a.firstInput.timestamp<this.delay||null!==a.$l(b.documentPoint,!0))?!1:!0};gh.prototype.doActivate=function(){var a=this.diagram;this.isActive=!0;a.isMouseCaptured=!0;a.skipsUndoManager=!0;a.add(this.box);this.doMouseMove()};
gh.prototype.doDeactivate=function(){var a=this.diagram;a.wf();a.remove(this.box);a.skipsUndoManager=!1;this.isActive=a.isMouseCaptured=!1};gh.prototype.doMouseMove=function(){var a=this.diagram;if(this.isActive&&null!==this.box){var b=this.computeBoxBounds(),c=this.box.$a("SHAPE");null===c&&(c=this.box.zb());var d=L.alloc().h(b.width,b.height);b=H.alloc().h(b.x,b.y);c.desiredSize=d;this.box.position=b;L.free(d);H.free(b);(a.allowHorizontalScroll||a.allowVerticalScroll)&&a.Ns(a.lastInput.viewPoint)}};
gh.prototype.doMouseUp=function(){if(this.isActive){var a=this.diagram;a.remove(this.box);try{a.currentCursor="wait",a.aa("ChangingSelection",a.selection),this.selectInRect(this.computeBoxBounds()),a.aa("ChangedSelection",a.selection)}finally{a.currentCursor=""}}this.stopTool()};gh.prototype.computeBoxBounds=function(){var a=this.diagram;return new N(a.firstInput.documentPoint,a.lastInput.documentPoint)};
gh.prototype.selectInRect=function(a){var b=this.diagram,c=b.lastInput;a=b.hx(a,this.isPartialInclusion);if(ib?c.meta:c.control)if(c.shift)for(a=a.iterator;a.next();)b=a.value,b.isSelected&&(b.isSelected=!1);else for(a=a.iterator;a.next();)b=a.value,b.isSelected=!b.isSelected;else if(c.shift)for(a=a.iterator;a.next();)b=a.value,b.isSelected||(b.isSelected=!0);else{c=new E;for(b=b.selection.iterator;b.next();){var d=b.value;a.contains(d)||c.add(d)}for(b=c.iterator;b.next();)b.value.isSelected=!1;for(a=
a.iterator;a.next();)b=a.value,b.isSelected||(b.isSelected=!0)}};pa.Object.defineProperties(gh.prototype,{delay:{get:function(){return this.Rk},set:function(a){this.Rk=a}},isPartialInclusion:{get:function(){return this.u},set:function(a){this.u=a}},box:{get:function(){return this.l},set:function(a){this.l=a}}});gh.className="DragSelectingTool";
function hh(){kf.call(this);this.name="Panning";this.ku=new H;this.Yx=new H;this.Cg=!1;var a=this;this.Hw=function(){w.document.removeEventListener("scroll",a.Hw,!1);a.stopTool()}}oa(hh,kf);hh.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;return!a.allowHorizontalScroll&&!a.allowVerticalScroll||!a.lastInput.left||a.currentTool!==this&&!this.isBeyondDragSize()?!1:!0};
hh.prototype.doActivate=function(){var a=this.diagram;this.Cg?(a.lastInput.bubbles=!0,w.document.addEventListener("scroll",this.Hw,!1)):(a.currentCursor="move",a.isMouseCaptured=!0,this.ku.assign(a.position));this.isActive=!0};hh.prototype.doDeactivate=function(){var a=this.diagram;a.currentCursor="";this.isActive=a.isMouseCaptured=!1};hh.prototype.doCancel=function(){var a=this.diagram;a.position=this.ku;a.isMouseCaptured=!1;this.stopTool()};hh.prototype.doMouseMove=function(){this.move()};
hh.prototype.doMouseUp=function(){this.move();this.stopTool()};hh.prototype.move=function(){var a=this.diagram;if(this.isActive&&a)if(this.Cg)a.lastInput.bubbles=!0;else{var b=a.position,c=a.firstInput.documentPoint,d=a.lastInput.documentPoint,e=b.x+c.x-d.x;c=b.y+c.y-d.y;a.allowHorizontalScroll||(e=b.x);a.allowVerticalScroll||(c=b.y);a.position=this.Yx.h(e,c)}};
pa.Object.defineProperties(hh.prototype,{bubbles:{get:function(){return this.Cg},set:function(a){this.Cg=a}},originalPosition:{get:function(){return this.ku}}});hh.className="PanningTool";Ta("clickCreatingTool",function(){return this.findTool("ClickCreating")},function(a){vf(this,"ClickCreating",a,this.mouseUpTools)});Ta("clickSelectingTool",function(){return this.findTool("ClickSelecting")},function(a){vf(this,"ClickSelecting",a,this.mouseUpTools)});
Ta("panningTool",function(){return this.findTool("Panning")},function(a){vf(this,"Panning",a,this.mouseMoveTools)});Ta("dragSelectingTool",function(){return this.findTool("DragSelecting")},function(a){vf(this,"DragSelecting",a,this.mouseMoveTools)});Ta("actionTool",function(){return this.findTool("Action")},function(a){vf(this,"Action",a,this.mouseDownTools)});function uf(){this.ba=this.K=this.l=this.u=null}
pa.Object.defineProperties(uf.prototype,{mainElement:{get:function(){return this.K},set:function(a){this.K=a}},show:{get:function(){return this.u},set:function(a){this.u!==a&&(this.u=a)}},hide:{get:function(){return this.l},set:function(a){this.l!==a&&(this.l=a)}},valueFunction:{get:function(){return this.ba},set:function(a){this.ba=a}}});uf.className="HTMLInfo";
function ih(a,b,c){this.text=a;this.Ww=b;this.visible=c}ih.className="ContextMenuButtonInfo";function jh(){kf.call(this);this.name="ContextMenu";this.u=this.Mt=this.l=null;this.Aw=new H;this.Nt=null;var a=this;this.zu=function(){a.stopTool()}}oa(jh,kf);
function kh(a){var b=new uf;b.show=function(a,b,c){c.showDefaultContextMenu()};b.hide=function(a,b){b.hideDefaultContextMenu()};lh=b;a.zu=function(){a.stopTool()};b=va("div");var c=va("div");b.style.cssText="top: 0px;z-index:10002;position: fixed;display: none;text-align: center;left: 25%;width: 50%;background-color: #F5F5F5;padding: 16px;border: 16px solid #444;border-radius: 10px;margin-top: 10px";c.style.cssText="z-index:10001;position: fixed;display: none;top: 0;left: 0;width: 100%;height: 100%;background-color: black;opacity: 0.8;";
var d=va("style");w.document.getElementsByTagName("head")[0].appendChild(d);d.sheet.insertRule(".goCXul { list-style: none; }",0);d.sheet.insertRule(".goCXli {font:700 1.5em Helvetica, Arial, sans-serif;position: relative;min-width: 60px; }",0);d.sheet.insertRule(".goCXa {color: #444;display: inline-block;padding: 4px;text-decoration: none;margin: 2px;border: 1px solid gray;border-radius: 10px; }",0);b.addEventListener("contextmenu",mh,!1);b.addEventListener("selectstart",mh,!1);c.addEventListener("contextmenu",
mh,!1);b.className="goCXforeground";c.className="goCXbackground";w.document.body&&(w.document.body.appendChild(b),w.document.body.appendChild(c));nh=b;oh=c;ph=!0}function mh(a){a.preventDefault();return!1}jh.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;return this.isBeyondDragSize()||!a.lastInput.right?!1:a.lastInput.isTouchEvent&&null!==this.defaultTouchContextMenu||null!==this.findObjectWithContextMenu()?!0:!1};jh.prototype.doStart=function(){this.Aw.set(this.diagram.firstInput.documentPoint)};
jh.prototype.doStop=function(){this.hideContextMenu();this.currentObject=null};jh.prototype.findObjectWithContextMenu=function(a){void 0===a&&(a=null);var b=this.diagram,c=b.lastInput,d=null;a instanceof P||(a instanceof Y?d=a:d=b.Rb(c.documentPoint,null,function(a){return!a.layer.isTemporary}));if(null!==d){for(a=d;null!==a;){if(null!==a.contextMenu)return a;a=a.panel}if(b.lastInput.isTouchEvent&&this.defaultTouchContextMenu)return d.part}else if(null!==b.contextMenu)return b;return null};
jh.prototype.doActivate=function(){};jh.prototype.doMouseDown=function(){kf.prototype.doMouseDown.call(this);if(this.isActive&&this.currentContextMenu instanceof pf){var a=this.diagram.toolManager.findTool("Action");null!==a&&a.canStart()&&(a.doActivate(),a.doMouseDown(),a.doDeactivate())}this.diagram.toolManager.mouseDownTools.contains(this)&&qh(this)};
jh.prototype.doMouseUp=function(){if(this.isActive&&this.currentContextMenu instanceof pf){var a=this.diagram.toolManager.findTool("Action");null!==a&&a.canStart()&&(a.doActivate(),a.doCancel(),a.doDeactivate())}qh(this)};
function qh(a){var b=a.diagram;if(a.isActive){var c=a.currentContextMenu;if(null!==c){if(!(c instanceof uf)){var d=b.Rb(b.lastInput.documentPoint,null,null);null!==d&&d.rg(c)&&a.standardMouseClick(null,null)}a.stopTool();a.canStart()&&(b.currentTool=a,a.doMouseUp())}}else a.canStart()&&(rh(a,!0),a.isActive||a.stopTool())}
function rh(a,b,c){void 0===c&&(c=null);b&&a.standardMouseSelect();if(!a.standardMouseClick())if(a.isActive=!0,b=lh,null===c&&(c=a.findObjectWithContextMenu()),null!==c){var d=c.contextMenu;null!==d?(a.currentObject=c instanceof Y?c:null,a.showContextMenu(d,a.currentObject)):null!==b&&a.showContextMenu(b,a.currentObject)}else null!==b&&a.showContextMenu(b,null)}jh.prototype.doMouseMove=function(){var a=this.diagram.toolManager.findTool("Action");null!==a&&a.doMouseMove();this.isActive&&this.diagram.toolManager.doMouseMove()};
jh.prototype.showContextMenu=function(a,b){var c=this.diagram;a!==this.currentContextMenu&&this.hideContextMenu();if(a instanceof pf){a.layerName="Tool";a.selectable=!1;a.scale=1/c.scale;a.category=this.name;null!==a.placeholder&&(a.placeholder.scale=c.scale);var d=a.diagram;null!==d&&d!==c&&d.remove(a);c.add(a);null!==b?a.adornedObject=b:a.data=c.model;a.$b();this.positionContextMenu(a,b)}else a instanceof uf&&a.show(b,c,this);this.currentContextMenu=a};
jh.prototype.positionContextMenu=function(a){if(null===a.placeholder){var b=this.diagram,c=b.lastInput.documentPoint.copy(),d=a.measuredBounds,e=b.viewportBounds;b.lastInput.isTouchEvent&&(c.x-=d.width);c.x+d.width>e.right&&(c.x-=d.width+5/b.scale);c.x<e.x&&(c.x=e.x);c.y+d.height>e.bottom&&(c.y-=d.height+5/b.scale);c.y<e.y&&(c.y=e.y);a.position=c}};
jh.prototype.hideContextMenu=function(){var a=this.diagram,b=this.currentContextMenu;null!==b&&(b instanceof pf?(a.remove(b),null!==this.Mt&&this.Mt.uf(b.category),b.data=null,b.adornedObject=null):b instanceof uf&&(null!==b.hide?b.hide(a,this):null!==b.mainElement&&(b.mainElement.style.display="none")),this.currentContextMenu=null,this.standardMouseOver())};
function sh(){var a=new E;a.add(new ih("Copy",function(a){a.commandHandler.copySelection()},function(a){return a.commandHandler.canCopySelection()}));a.add(new ih("Cut",function(a){a.commandHandler.cutSelection()},function(a){return a.commandHandler.canCutSelection()}));a.add(new ih("Delete",function(a){a.commandHandler.deleteSelection()},function(a){return a.commandHandler.canDeleteSelection()}));a.add(new ih("Paste",function(a){a.commandHandler.pasteSelection(a.lastInput.documentPoint)},function(a){return a.commandHandler.canPasteSelection()}));
a.add(new ih("Select All",function(a){a.commandHandler.selectAll()},function(a){return a.commandHandler.canSelectAll()}));a.add(new ih("Undo",function(a){a.commandHandler.undo()},function(a){return a.commandHandler.canUndo()}));a.add(new ih("Redo",function(a){a.commandHandler.redo()},function(a){return a.commandHandler.canRedo()}));a.add(new ih("Scroll To Part",function(a){a.commandHandler.scrollToPart()},function(a){return a.commandHandler.canScrollToPart()}));a.add(new ih("Zoom To Fit",function(a){a.commandHandler.zoomToFit()},
function(a){return a.commandHandler.canZoomToFit()}));a.add(new ih("Reset Zoom",function(a){a.commandHandler.resetZoom()},function(a){return a.commandHandler.canResetZoom()}));a.add(new ih("Group Selection",function(a){a.commandHandler.groupSelection()},function(a){return a.commandHandler.canGroupSelection()}));a.add(new ih("Ungroup Selection",function(a){a.commandHandler.ungroupSelection()},function(a){return a.commandHandler.canUngroupSelection()}));a.add(new ih("Edit Text",function(a){a.commandHandler.editTextBlock()},
function(a){return a.commandHandler.canEditTextBlock()}));return a}
jh.prototype.showDefaultContextMenu=function(){var a=this.diagram;null===this.Nt&&(this.Nt=sh());nh.innerHTML="";oh.addEventListener("click",this.zu,!1);var b=this,c=va("ul");c.className="goCXul";nh.appendChild(c);c.innerHTML="";for(var d=this.Nt.iterator;d.next();){var e=d.value,f=e.visible;if("function"===typeof e.Ww&&("function"!==typeof f||f(a))){f=va("li");f.className="goCXli";var g=va("a");g.className="goCXa";g.href="#";g.Qx=e.Ww;g.addEventListener("click",function(c){this.Qx(a);b.stopTool();
c.preventDefault();return!1},!1);g.textContent=e.text;f.appendChild(g);c.appendChild(f)}}nh.style.display="block";oh.style.display="block"};jh.prototype.hideDefaultContextMenu=function(){null!==this.currentContextMenu&&this.currentContextMenu===lh&&(nh.style.display="none",oh.style.display="none",oh.removeEventListener("click",this.zu,!1),this.currentContextMenu=null)};
pa.Object.defineProperties(jh.prototype,{currentContextMenu:{get:function(){return this.l},set:function(a){this.l=a;this.Mt=a instanceof pf?a.adornedPart:null}},defaultTouchContextMenu:{get:function(){!1===ph&&null===lh&&th&&kh(this);return lh},set:function(a){null===a&&(ph=!0);lh=a}},currentObject:{get:function(){return this.u},set:function(a){this.u=a}},mouseDownPoint:{get:function(){return this.Aw}}});
var lh=null,ph=!1,oh=null,nh=null;jh.className="ContextMenuTool";Ta("contextMenuTool",function(){return this.findTool("ContextMenu")},function(a){vf(this,"ContextMenu",a,this.mouseUpTools)});function uh(){kf.call(this);this.name="TextEditing";this.kh=new vh;this.Ma=null;this.La=wh;this.xi=null;this.la=xh;this.K=1;this.ba=!0;this.u=null;this.l=new uf;this.Ot=null;yh(this,this.l)}oa(uh,kf);
function yh(a,b){if(th){var c=va("textarea");a.Ot=c;c.addEventListener("input",function(){if(null!==a.textBlock){var b=a.qx(this.value);this.style.width=20+b.measuredBounds.width*this.Tz+"px";this.rows=b.lineCount}},!1);c.addEventListener("keydown",function(b){if(null!==a.textBlock){var c=b.which;13===c?(!1===a.textBlock.isMultiline&&b.preventDefault(),a.acceptText(zh)):9===c?(a.acceptText(Ah),b.preventDefault()):27===c&&(a.doCancel(),null!==a.diagram&&a.diagram.doFocus())}},!1);c.addEventListener("focus",
function(){if(null!==a.currentTextEditor&&a.state!==xh){var b=a.Ot;a.la===Bh&&(a.la=Ch);"function"===typeof b.select&&a.selectsTextOnActivate&&(b.select(),b.setSelectionRange(0,9999))}},!1);c.addEventListener("blur",function(){if(null!==a.currentTextEditor&&a.state!==xh){var b=a.Ot;"function"===typeof b.focus&&b.focus();"function"===typeof b.select&&a.selectsTextOnActivate&&(b.select(),b.setSelectionRange(0,9999))}},!1);b.valueFunction=function(){return c.value};b.mainElement=c;b.show=function(a,
b,f){if(a instanceof vh&&f instanceof uh)if(f.state===Gh)c.style.border="3px solid red",c.focus();else{var d=a.ma(dd),e=b.position,k=b.scale,l=a.Ce()*k;l<f.minimumEditorScale&&(l=f.minimumEditorScale);var m=a.naturalBounds.width*l+6,n=a.naturalBounds.height*l+2,p=(d.x-e.x)*k;d=(d.y-e.y)*k;c.value=a.text;b.div.style.font=a.font;c.style.position="absolute";c.style.zIndex="100";c.style.font="inherit";c.style.fontSize=100*l+"%";c.style.lineHeight="normal";c.style.width=m+"px";c.style.left=(p-m/2|0)-1+
"px";c.style.top=(d-n/2|0)-1+"px";c.style.textAlign=a.textAlign;c.style.margin="0";c.style.padding="1px";c.style.border="0";c.style.outline="none";c.style.whiteSpace="pre-wrap";c.style.overflow="hidden";c.rows=a.lineCount;c.Tz=l;c.className="goTXarea";b.div.appendChild(c);c.focus();f.selectsTextOnActivate&&(c.select(),c.setSelectionRange(0,9999))}};b.hide=function(a){a.div.removeChild(c)}}}
uh.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;if(null===a||a.isReadOnly||!a.lastInput.left||this.isBeyondDragSize())return!1;var b=a.Rb(a.lastInput.documentPoint);if(!(null!==b&&b instanceof vh&&b.editable&&b.part.canEdit()))return!1;b=b.part;return null===b||this.starting===wh&&!b.isSelected||this.starting===Hh&&2>a.lastInput.clickCount?!1:!0};uh.prototype.doStart=function(){this.isActive||null===this.textBlock||this.doActivate()};
uh.prototype.doActivate=function(){if(!this.isActive){var a=this.diagram;if(null!==a){var b=this.textBlock;null===b&&(b=a.Rb(a.lastInput.documentPoint));if(null!==b&&b instanceof vh&&(this.textBlock=b,null!==b.part)){this.isActive=!0;this.la=Bh;var c=this.defaultTextEditor;null!==b.textEditor&&(c=b.textEditor);this.kh=this.textBlock.copy();var d=new N(this.textBlock.ma(Tc),this.textBlock.ma(hd));a.Fv(d);c.show(b,a,this);this.currentTextEditor=c}}}};uh.prototype.doCancel=function(){this.stopTool()};
uh.prototype.doMouseUp=function(){!this.isActive&&this.canStart()&&this.doActivate()};uh.prototype.doMouseDown=function(){this.isActive&&this.acceptText(Ih)};
uh.prototype.acceptText=function(a){switch(a){case Ih:if(this.la===Jh)this.currentTextEditor instanceof HTMLElement&&this.currentTextEditor.focus();else if(this.la===Bh||this.la===Gh||this.la===Ch)this.la=Kh,Lh(this);break;case Mh:case zh:case Ah:if(zh!==a||!0!==this.textBlock.isMultiline)if(this.la===Bh||this.la===Gh||this.la===Ch)this.la=Kh,Lh(this)}};
function Lh(a){var b=a.textBlock,c=a.diagram,d=a.currentTextEditor;if(null!==b&&null!==d){var e=b.text,f="";null!==d.valueFunction&&(f=d.valueFunction());a.isValidText(b,e,f)?(a.Aa(a.name),a.la=Jh,a.transactionResult=a.name,b.text=f,null!==b.textEdited&&b.textEdited(b,e,f),null!==c&&c.aa("TextEdited",b,e),a.wg(),a.stopTool(),null!==c&&c.doFocus()):(a.la=Gh,null!==b.errorFunction&&b.errorFunction(a,e,f),d.show(b,c,a))}}
uh.prototype.doDeactivate=function(){var a=this.diagram;null!==a&&(this.la=xh,this.textBlock=null,null!==this.currentTextEditor&&this.currentTextEditor.hide(a,this),this.isActive=!1)};uh.prototype.isValidText=function(a,b,c){var d=this.textValidation;if(null!==d&&!d(a,b,c))return!1;d=a.textValidation;return null===d||d(a,b,c)?!0:!1};uh.prototype.qx=function(a){var b=this.kh;b.text=a;b.measure(this.textBlock.jl,Infinity);return b};
pa.Object.defineProperties(uh.prototype,{textBlock:{get:function(){return this.Ma},set:function(a){this.Ma=a}},currentTextEditor:{get:function(){return this.u},set:function(a){this.u=a}},defaultTextEditor:{get:function(){return this.l},set:function(a){this.l=a}},starting:{get:function(){return this.La},set:function(a){this.La=a}},textValidation:{get:function(){return this.xi},
set:function(a){this.xi=a}},minimumEditorScale:{get:function(){return this.K},set:function(a){this.K=a}},selectsTextOnActivate:{get:function(){return this.ba},set:function(a){this.ba=a}},state:{get:function(){return this.la},set:function(a){this.la!==a&&(this.la=a)}}});uh.prototype.measureTemporaryTextBlock=uh.prototype.qx;
var Mh=new D(uh,"LostFocus",0),Ih=new D(uh,"MouseDown",1),Ah=new D(uh,"Tab",2),zh=new D(uh,"Enter",3),Nh=new D(uh,"SingleClick",0),wh=new D(uh,"SingleClickSelected",1),Hh=new D(uh,"DoubleClick",2),xh=new D(uh,"StateNone",0),Bh=new D(uh,"StateActive",1),Ch=new D(uh,"StateEditing",2),Kh=new D(uh,"StateValidating",3),Gh=new D(uh,"StateInvalid",4),Jh=new D(uh,"StateValidated",5);uh.className="TextEditingTool";uh.LostFocus=Mh;uh.MouseDown=Ih;uh.Tab=Ah;uh.Enter=zh;uh.SingleClick=Nh;
uh.SingleClickSelected=wh;uh.DoubleClick=Hh;uh.StateNone=xh;uh.StateActive=Bh;uh.StateEditing=Ch;uh.StateValidating=Kh;uh.StateInvalid=Gh;uh.StateValidated=Jh;Ta("textEditingTool",function(){return this.findTool("TextEditing")},function(a){vf(this,"TextEditing",a,this.mouseUpTools)});
function Oh(){Ph||(Qh(),Ph=!0);this.nw=Rh;this.C=lf;this.qn=this.rn=null;this.aj=this.sn=this.tn=0;this.Bk=this.ni=this.rb=this.Hr=this.Lf=!1;this.Rg=this.Dc=!0;this.Zq=this.Yq=this.mw=null;this.lw=0;this.ar=new Lb;this.Ut=600;this.Sx=new H(0,0);this.jw=this.iw=this.Kw=!1;this.Ag=new F;this.zj=new Lb;this.bs=new F;this.$q=null}Oh.prototype.hb=function(a){this.C=a};function Rh(a,b,c,d){a/=d/2;return 1>a?c/2*a*a+b:-c/2*(--a*(a-2)-1)+b}Oh.prototype.canStart=function(){return!0};t=Oh.prototype;
t.Ti=function(a,b){this.Dc&&(void 0!==b&&(this.$q=b),this.Rg||this.C.Qi)&&(this.Ag.add(a),this.canStart(a)&&(this.Lf&&this.vd(),this.ni=this.rb=!0))};t.Ez=function(a,b){this.Dc&&(void 0!==b&&(this.$q=b),this.Rg||this.C.Qi)&&(this.Ag.add(a),this.canStart(a)&&(this.Lf&&this.vd(),this.rb=!0))};
function Sh(a){if(a.Dc&&(a.Ag.clear(),a.rb))if(!a.Bk)a.rb=!1,a.ni=!1;else if(0===a.aj){var b=+new Date;a.aj=b;w.requestAnimationFrame(function(){if(!1!==a.rb&&!a.Lf&&a.aj===b){var c=a.C;c.De("temporaryPixelRatio")&&Kf(c);Th(c);a.rb=!1;a.ni=!1;c.aa("AnimationStarting");Uh(a,b)}})}}
t.sh=function(a,b,c,d,e,f){if(!(!this.rb||"position"===b&&c.A(d)||a instanceof T&&!a.isAnimated)){var g=this.zj;if(g.contains(a)){var h=g.J(a);g=h.start;var k=h.end;void 0===g[b]&&(g[b]=Vh(c));k[b]=Vh(d)}else h={},k={},h[b]=Vh(c),k[b]=Vh(d),d=h.position,d instanceof H&&!d.o()&&this.Ag.contains("Expand SubGraph")&&d.assign(k.position),h=new Wh(h,k,e),g.add(a,h);e&&0===b.indexOf("position:")&&a instanceof T?h.Uu.location=Vh(a.location):e&&(h.Uu[b]=Vh(c));f&&(h.ot=!0);this.Bk=!0}};
function Vh(a){return a instanceof H?a.copy():a instanceof L?a.copy():a}function Xh(a,b){if(!a.Lf)return!1;a=a.zj.J(b);return null!==a&&a.ot}
function Uh(a,b){function c(){if(!1!==e.Lf&&e.aj===b){var a=+new Date,f=a>r?l:a-q;Yh(e);Zh(e,d,p,g,f,l);e.Yq&&e.Yq();pg(d);$h(e);a>r?ai(e):w.requestAnimationFrame(c)}}var d=a.C;if(null!==d){var e=a,f=a.$q||{},g=f.$z||a.nw,h=f.dA||null,k=f.eA||null,l=f.duration||a.Ut,m=a.Sx;for(f=a.zj.iterator;f.next();){var n=f.value.start.position;n instanceof H&&(n.o()||n.assign(m))}a.mw=g;a.Yq=h;a.Zq=k;a.lw=l;a.ar=a.zj;var p=a.ar;for(f=p.iterator;f.next();)h=f.value.end,h["position:placeholder"]&&(k=f.key.findVisibleNode(),
k instanceof ig&&null!==k.placeholder&&(m=k.placeholder,k=m.ma(Tc),m=m.padding,k.x+=m.left,k.y+=m.top,h["position:placeholder"]=k));a.Lf=!0;Yh(a);Zh(a,d,p,g,0,l);pg(a.C,!0);$h(a);var q=+new Date,r=q+l;e.aj===b&&w.requestAnimationFrame(function(){c()})}}function Yh(a){if(!a.Hr){var b=a.C;a.Kw=b.skipsUndoManager;a.iw=b.skipsModelSourceBindings;a.jw=b.Wi;b.skipsUndoManager=!0;b.skipsModelSourceBindings=!0;b.Wi=!0;a.Hr=!0}}
function $h(a){var b=a.C;b.skipsUndoManager=a.Kw;b.skipsModelSourceBindings=a.iw;b.Wi=a.jw;a.Hr=!1}function Zh(a,b,c,d,e,f){for(c=c.iterator;c.next();){var g=c.key,h=c.value,k=h.start,l=h.end,m=bi;for(n in l)"position"===n&&(l["position:placeholder"]||l["position:node"])||null===m.get(n)||m.get(n)(g,k[n],l[n],d,e,f,h)}d=b.jv;b.jv=!0;var n=a.nw;0!==a.tn&&0!==a.sn&&(c=a.tn,b.Ca=n(e,c,a.sn-c,f));null!==a.rn&&null!==a.qn&&(c=a.rn,a=a.qn,b.sa=new H(n(e,c.x,a.x-c.x,f),n(e,c.y,a.y-c.y,f)));b.jv=d}
t.vd=function(){!0===this.rb&&(this.ni=this.rb=!1,this.aj=0,this.Bk&&this.C.cc());this.Lf&&this.Dc&&ai(this)};
function ai(a){a.Lf=!1;a.Bk=!1;Yh(a);for(var b=a.C,c=a.mw,d=a.lw,e=a.ar,f=a.bs.iterator;f.next();)f.value.s();e=e.iterator;for(f=bi;e.next();){var g=e.key,h=e.value,k=h.start,l=h.end,m=h.Uu,n;for(n in l)if(null!==f.get(n)){var p=n;!h.Pu||"position:node"!==p&&"position:placeholder"!==p||(p="position");f.get(p)(g,k[n],void 0!==m[n]?m[n]:h.Pu?k[n]:l[n],c,d,d)}h.Pu&&void 0!==m.location&&g instanceof T&&(g.location=m.location);h.ot&&g instanceof T&&g.Lb(!1)}for(c=a.C.links;c.next();)d=c.value,null!==d.bh&&
(d.points=d.bh,d.bh=null);b.ft.clear();Uf(b,!1);b.Xa();b.R();b.fd();ci(b);$h(a);a.Zq&&a.Zq();a.aj=0;a.ar.clear();a.Zq=null;a.Yq=null;a.rn=null;a.qn=null;a.tn=0;a.sn=0;a.zj.clear();0<a.bs.count&&a.bs.clear();a.$q=null;b.aa("AnimationFinished");b.cc()}
t.Mp=function(a,b){if(this.ni&&(this.Ag.contains("Expand Tree")||this.Ag.contains("Expand SubGraph"))){var c=b.actualBounds,d=null;b instanceof ig&&(d=b.placeholder);null!==d?(c=d.ma(Tc),d=d.padding,c.x+=d.left,c.y+=d.top,this.sh(a,"position",c,a.position,!1)):this.sh(a,"position",new H(c.x+c.width/2,c.y+c.height/2),a.position,!1);this.sh(a,"scale",.01,a.scale,!1);if(a instanceof ig)for(a=a.memberParts;a.next();)d=a.value,d instanceof V&&this.Mp(d,b)}};
t.Lp=function(a,b){if(a.isVisible()&&this.ni&&(this.Ag.contains("Collapse Tree")||this.Ag.contains("Collapse SubGraph"))){var c=null;b instanceof ig&&(c=b.placeholder);null!==c?this.sh(a,"position:placeholder",a.position,c,!0):this.sh(a,"position:node",a.position,b,!0);this.sh(a,"scale",a.scale,.01,!0);this.rb&&(c=this.zj,c.contains(a)&&(c.J(a).ot=!0));if(a instanceof ig)for(a=a.memberParts;a.next();)c=a.value,c instanceof V&&this.Lp(c,b)}};
function di(a,b,c){a.rb&&!b.A(c)&&(null===a.rn&&b.o()&&null===a.qn&&(a.rn=b.copy()),a.qn=c.copy(),a.Bk=!0)}function ei(a,b,c){a.rb&&a.C.Qi&&(0===a.tn&&0===a.sn&&(a.tn=b),a.sn=c,a.Bk=!0)}
function Qh(){var a=new Lb;a.add("position",function(a,c,d,e,f,g){f!==g?a.vt(e(f,c.x,d.x-c.x,g),e(f,c.y,d.y-c.y,g)):a.position=new H(e(f,c.x,d.x-c.x,g),e(f,c.y,d.y-c.y,g))});a.add("position:placeholder",function(a,c,d,e,f,g){f!==g?a.vt(e(f,c.x,d.x-c.x,g),e(f,c.y,d.y-c.y,g)):a.position=new H(e(f,c.x,d.x-c.x,g),e(f,c.y,d.y-c.y,g))});a.add("position:node",function(a,c,d,e,f,g){var b=a.actualBounds,k=d.actualBounds;d=k.x+k.width/2-b.width/2;b=k.y+k.height/2-b.height/2;f!==g?a.vt(e(f,c.x,d-c.x,g),e(f,
c.y,b-c.y,g)):a.position=new H(e(f,c.x,d-c.x,g),e(f,c.y,b-c.y,g))});a.add("opacity",function(a,c,d,e,f,g){a.opacity=e(f,c,d-c,g)});a.add("scale",function(a,c,d,e,f,g){a.scale=e(f,c,d-c,g)});a.add("angle",function(a,c,d,e,f,g){a.angle=e(f,c,d-c,g)});a.add("visible",function(a,c,d,e,f,g){a.visible=f!==g?c:d});bi=a}
pa.Object.defineProperties(Oh.prototype,{animationReasons:{get:function(){return this.Ag}},isEnabled:{get:function(){return this.Dc},set:function(a){this.Dc=a}},duration:{get:function(){return this.Ut},set:function(a){1>a&&ya(a,">= 1",Oh,"duration");this.Ut=a}},isAnimating:{get:function(){return this.Lf}},isTicking:{get:function(){return this.Hr}},isInitial:{
get:function(){return this.Rg},set:function(a){this.Rg=a}}});Oh.prototype.stopAnimation=Oh.prototype.vd;Oh.prototype.addToAnimation=Oh.prototype.sh;Oh.prototype.prepareAnimation=Oh.prototype.Ez;Oh.prototype.prepareAutomaticAnimation=Oh.prototype.Ti;var bi=null,Ph=!1;Oh.className="AnimationManager";function Wh(a,b,c){this.start=a;this.end=b;this.Uu={};this.Pu=c;this.ot=!1}Wh.className="AnimationStates";
function fi(){nb(this);this.C=null;this.Fa=new E;this.Ua="";this.lb=1;this.u=!1;this.Dj=this.K=this.Oh=this.Nh=this.Mh=this.Lh=this.Jh=this.Kh=this.Ih=this.Qh=this.Hh=this.Ph=this.Gh=this.Fh=!0;this.l=!1;this.Lo=[]}t=fi.prototype;t.hb=function(a){this.C=a};
t.toString=function(a){void 0===a&&(a=0);var b='Layer "'+this.name+'"';if(0>=a)return b;for(var c=0,d=0,e=0,f=0,g=0,h=this.Fa.iterator;h.next();){var k=h.value;k instanceof ig?e++:k instanceof V?d++:k instanceof S?f++:k instanceof pf?g++:c++}h="";0<c&&(h+=c+" Parts ");0<d&&(h+=d+" Nodes ");0<e&&(h+=e+" Groups ");0<f&&(h+=f+" Links ");0<g&&(h+=g+" Adornments ");if(1<a)for(a=this.Fa.iterator;a.next();)c=a.value,h+="\n    "+c.toString(),d=c.data,null!==d&&Bb(d)&&(h+=" #"+Bb(d)),c instanceof V?h+=" "+
Qa(d):c instanceof S&&(h+=" "+Qa(c.fromNode)+" "+Qa(c.toNode));return b+" "+this.Fa.count+": "+h};t.Rb=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);if(!1===this.Dj)return null;var d=!1;null!==this.diagram&&this.diagram.viewportBounds.da(a)&&(d=!0);for(var e=H.alloc(),f=this.Fa.j,g=f.length;g--;){var h=f[g];if((!0!==d||!1!==og(h))&&h.isVisible()&&(e.assign(a),Qb(e,h.rd),h=h.Rb(e,b,c),null!==h&&(null!==b&&(h=b(h)),null!==h&&(null===c||c(h)))))return H.free(e),h}H.free(e);return null};
t.Hi=function(a,b,c,d){void 0===b&&(b=null);void 0===c&&(c=null);d instanceof E||d instanceof F||(d=new F);if(!1===this.Dj)return d;var e=!1;null!==this.diagram&&this.diagram.viewportBounds.da(a)&&(e=!0);for(var f=H.alloc(),g=this.Fa.j,h=g.length;h--;){var k=g[h];if((!0!==e||!1!==og(k))&&k.isVisible()){f.assign(a);Qb(f,k.rd);var l=k;k.Hi(f,b,c,d)&&(null!==b&&(l=b(l)),null===l||null!==c&&!c(l)||d.add(l))}}H.free(f);return d};
t.ng=function(a,b,c,d,e){void 0===b&&(b=null);void 0===c&&(c=null);void 0===d&&(d=!1);e instanceof E||e instanceof F||(e=new F);if(!1===this.Dj)return e;var f=!1;null!==this.diagram&&this.diagram.viewportBounds.mf(a)&&(f=!0);for(var g=this.Fa.j,h=g.length;h--;){var k=g[h];if((!0!==f||!1!==og(k))&&k.isVisible()){var l=k;k.ng(a,b,c,d,e)&&(null!==b&&(l=b(l)),null===l||null!==c&&!c(l)||e.add(l))}}return e};
t.og=function(a,b,c,d,e,f){void 0===c&&(c=null);void 0===d&&(d=null);void 0===e&&(e=!0);if(!1!==e&&!0!==e){if(e instanceof E||e instanceof F)f=e;e=!0}f instanceof E||f instanceof F||(f=new F);if(!1===this.Dj)return f;var g=!1;null!==this.diagram&&this.diagram.viewportBounds.da(a)&&(g=!0);for(var h=H.alloc(),k=H.alloc(),l=this.Fa.j,m=l.length;m--;){var n=l[m];if((!0!==g||!1!==og(n))&&n.isVisible()){h.assign(a);Qb(h,n.rd);k.h(a.x+b,a.y);Qb(k,n.rd);var p=n;n.og(h,k,c,d,e,f)&&(null!==c&&(p=c(p)),null===
p||null!==d&&!d(p)||f.add(p))}}H.free(h);H.free(k);return f};
t.hd=function(a,b){if(this.visible){var c=void 0===b?a.viewportBounds:b;var d=this.Fa.j,e=d.length;a=Ka();b=Ka();for(var f=0;f<e;f++){var g=d[f];g.zw=f;g instanceof S&&!1===g.Lc||g instanceof pf&&null!==g.adornedPart||(Ac(g.actualBounds,c,10)?(g.hd(!0),a.push(g)):(g.hd(!1),null!==g.adornments&&0<g.adornments.count&&b.push(g)))}for(c=0;c<a.length;c++)for(d=a[c],gi(d),d=d.adornments;d.next();)e=d.value,e.measure(Infinity,Infinity),e.arrange(),e.hd(!0);for(c=0;c<b.length;c++)d=b[c],d.updateAdornments(),
hi(d,!0);Oa(a);Oa(b)}};t.hc=function(a,b,c){if(this.visible&&0!==this.lb&&(void 0===c&&(c=!0),c||!this.isTemporary)){c=this.Fa.j;var d=c.length;if(0!==d){1!==this.lb&&(a.globalAlpha=this.lb);var e=this.Lo;e.length=0;for(var f=b.scale,g=0;g<d;g++){var h=c[g];if(og(h)){if(h instanceof S&&(h.isOrthogonal&&e.push(h),!1===h.Lc))continue;var k=h.actualBounds;k.width*f>b.ne||k.height*f>b.ne?h.hc(a,b):ii(a,h)}}a.globalAlpha=1}}};
function ii(a,b){var c=b.actualBounds,d=b.naturalBounds;if(0!==c.width&&0!==c.height&&!isNaN(c.x)&&!isNaN(c.y)&&b.isVisible()){var e=b.transform;null!==b.areaBackground&&(ji(b,a,b.areaBackground,!0,!0,d,c),a.fillRect(c.x,c.y,c.width,c.height));null===b.areaBackground&&null===b.background&&(ji(b,a,"rgba(0,0,0,0.3)",!0,!1,d,c),a.fillRect(c.x,c.y,c.width,c.height));null!==b.background&&(a.transform(e.m11,e.m12,e.m21,e.m22,e.dx,e.dy),ji(b,a,b.background,!0,!1,d,c),a.fillRect(0,0,d.width/2,d.height/2),
e.bt()||(b=1/(e.m11*e.m22-e.m12*e.m21),a.transform(e.m22*b,-e.m12*b,-e.m21*b,e.m11*b,b*(e.m21*e.dy-e.m22*e.dx),b*(e.m12*e.dx-e.m11*e.dy))))}}t.g=function(a,b,c,d,e){var f=this.diagram;null!==f&&f.ab(af,a,this,b,c,d,e)};t.Pi=function(a,b,c){var d=this.Fa;b.gi=this;if(a>=d.count)a=d.count;else if(d.N(a)===b)return-1;d.Ib(a,b);b.aq(c);d=this.diagram;null!==d&&(c?d.R():d.Pi(b));ki(this,a,b);return a};
t.xc=function(a,b,c){if(!c&&b.layer!==this&&null!==b.layer)return b.layer.xc(a,b,c);var d=this.Fa;if(0>a||a>=d.length){if(a=d.indexOf(b),0>a)return-1}else if(d.N(a)!==b&&(a=d.indexOf(b),0>a))return-1;b.bq(c);d.mb(a);d=this.diagram;null!==d&&(c?d.R():d.xc(b));b.gi=null;return a};
function ki(a,b,c){b=li(a,b,c);if(c instanceof ig&&null!==c&&isNaN(c.zOrder)){if(0!==c.memberParts.count){for(var d=-1,e=a.Fa.j,f=e.length,g=0;g<f;g++){var h=e[g];if(h===c&&(b=g,0<=d))break;if(0>d&&h.containingGroup===c&&(d=g,0<=b))break}!(0>d)&&d<b&&(e=a.Fa,e.mb(b),e.Ib(d,c))}c=c.containingGroup;null!==c&&ki(a,-1,c)}}
function li(a,b,c){var d=c.zOrder;if(isNaN(d))return b;a=a.Fa;var e=a.count;if(1>=e)return b;0>b&&(b=a.indexOf(c));if(0>b)return-1;for(var f=b-1,g=NaN;0<=f;){g=a.N(f).zOrder;if(!isNaN(g))break;f--}for(var h=b+1,k=NaN;h<e;){k=a.N(h).zOrder;if(!isNaN(k))break;h++}if(!isNaN(g)&&g>d)for(;;){if(-1===f||g<=d){f++;if(f===b)break;a.mb(b);a.Ib(f,c);return f}for(g=NaN;0<=--f&&(g=a.N(f).zOrder,isNaN(g)););}else if(!isNaN(k)&&k<d)for(;;){if(h===e||k>=d){h--;if(h===b)break;a.mb(b);a.Ib(h,c);return h}for(k=NaN;++h<
e&&(k=a.N(h).zOrder,isNaN(k)););}return b}t.clear=function(){for(var a=this.Fa.Na(),b=a.length,c=0;c<b;c++)a[c].hd(!1),this.xc(-1,a[c],!1);this.Lo.length=0};
pa.Object.defineProperties(fi.prototype,{parts:{get:function(){return this.Fa.iterator}},partsBackwards:{get:function(){return this.Fa.iteratorBackwards}},diagram:{get:function(){return this.C}},name:{get:function(){return this.Ua},set:function(a){var b=this.Ua;if(b!==a){var c=this.diagram;if(null!==c)for(""===b&&A("Cannot rename default Layer to: "+a),c=c.layers;c.next();)c.value.name===
a&&A("Layer.name is already present in this diagram: "+a);this.Ua=a;this.g("name",b,a);for(a=this.Fa.iterator;a.next();)a.value.layerName=this.Ua}}},opacity:{get:function(){return this.lb},set:function(a){var b=this.lb;b!==a&&((0>a||1<a)&&ya(a,"0 <= value <= 1",fi,"opacity"),this.lb=a,this.g("opacity",b,a),a=this.diagram,null!==a&&a.R())}},isTemporary:{get:function(){return this.u},set:function(a){var b=this.u;b!==a&&(this.u=a,this.g("isTemporary",
b,a))}},visible:{get:function(){return this.K},set:function(a){var b=this.K;if(b!==a){this.K=a;this.g("visible",b,a);for(b=this.Fa.iterator;b.next();)b.value.Lb(a);a=this.diagram;null!==a&&a.R()}}},pickable:{get:function(){return this.Dj},set:function(a){var b=this.Dj;b!==a&&(this.Dj=a,this.g("pickable",b,a))}},isBoundsIncluded:{get:function(){return this.l},set:function(a){this.l!==a&&(this.l=a,null!==this.diagram&&
this.diagram.Xa())}},allowCopy:{get:function(){return this.Fh},set:function(a){var b=this.Fh;b!==a&&(this.Fh=a,this.g("allowCopy",b,a))}},allowDelete:{get:function(){return this.Gh},set:function(a){var b=this.Gh;b!==a&&(this.Gh=a,this.g("allowDelete",b,a))}},allowTextEdit:{get:function(){return this.Ph},set:function(a){var b=this.Ph;b!==a&&(this.Ph=a,this.g("allowTextEdit",b,a))}},allowGroup:{
get:function(){return this.Hh},set:function(a){var b=this.Hh;b!==a&&(this.Hh=a,this.g("allowGroup",b,a))}},allowUngroup:{get:function(){return this.Qh},set:function(a){var b=this.Qh;b!==a&&(this.Qh=a,this.g("allowUngroup",b,a))}},allowLink:{get:function(){return this.Ih},set:function(a){var b=this.Ih;b!==a&&(this.Ih=a,this.g("allowLink",b,a))}},allowRelink:{get:function(){return this.Kh},set:function(a){var b=
this.Kh;b!==a&&(this.Kh=a,this.g("allowRelink",b,a))}},allowMove:{get:function(){return this.Jh},set:function(a){var b=this.Jh;b!==a&&(this.Jh=a,this.g("allowMove",b,a))}},allowReshape:{get:function(){return this.Lh},set:function(a){var b=this.Lh;b!==a&&(this.Lh=a,this.g("allowReshape",b,a))}},allowResize:{get:function(){return this.Mh},set:function(a){var b=this.Mh;b!==a&&(this.Mh=a,this.g("allowResize",b,a))}},
allowRotate:{get:function(){return this.Nh},set:function(a){var b=this.Nh;b!==a&&(this.Nh=a,this.g("allowRotate",b,a))}},allowSelect:{get:function(){return this.Oh},set:function(a){var b=this.Oh;b!==a&&(this.Oh=a,this.g("allowSelect",b,a))}}});fi.prototype.findObjectsNear=fi.prototype.og;fi.prototype.findObjectsIn=fi.prototype.ng;fi.prototype.findObjectsAt=fi.prototype.Hi;fi.prototype.findObjectAt=fi.prototype.Rb;fi.className="Layer";
function P(a){function b(){c.removeEventListener(w.document,"DOMContentLoaded",b,!1);c.setRTL()}1<arguments.length&&A("Diagram constructor can only take one optional argument, the DIV HTML element or its id.");mi||(ni(),mi=!0);nb(this);lf=this;Wa=[];this.ob=!0;this.Ak=new Oh;this.Ak.hb(this);this.Gb=17;this.Sn=!1;this.qu="default";this.Ia=null;var c=this;th&&(null!==w.document.body?this.setRTL():c.addEventListener(w.document,"DOMContentLoaded",b,!1));this.Pa=new E;this.wa=this.xa=0;this.Da=null;this.ou=
new Lb;this.cg=this.Yc=null;this.Dv();this.dl=null;this.Cv();this.sa=(new H(NaN,NaN)).freeze();this.dr=this.Ca=1;this.yr=(new H(NaN,NaN)).freeze();this.zr=NaN;this.Tr=1E-4;this.Rr=100;this.tb=new Qc;this.Is=(new H(NaN,NaN)).freeze();this.pr=(new N(NaN,NaN,NaN,NaN)).freeze();this.ti=(new Ic(0,0,0,0)).freeze();this.Ij=oi;this.ss=!1;this.ns=this.js=null;this.cj=pi;this.ej=Ed;this.bi=pi;this.Nn=Ed;this.Ar=this.xr=Tc;this.nc=!0;this.On=!1;this.Dd=new F;this.Xh=new Lb;this.wn=!0;this.Sm=250;this.Ck=-1;
this.Tm=(new Ic(16,16,16,16)).freeze();this.Sk=this.qd=!1;this.Xk=!0;this.Yh=new Xe;this.Yh.diagram=this;this.Ye=new Xe;this.Ye.diagram=this;this.sj=new Xe;this.sj.diagram=this;this.oe=this.Af=null;this.Ml=!1;this.Qt=this.Rt=null;this.Lq=w.PointerEvent&&(cb||db||gb)&&w.navigator&&!1!==w.navigator.msPointerEnabled;qi(this);this.zi=new F;this.Ir=!0;this.Ds=ri;this.Vb=!1;this.Fs=tg;this.Ma=null;si.add("Model",ti);this.ba=this.La=this.Ub=null;this.Xq="";this.pn="auto";this.Rf=this.Wr=this.Tf=this.Uf=
this.Wf=this.Cf=this.Gf=this.Bf=null;this.ur=!1;this.Df=this.hg=this.Vf=this.Sf=null;this.ju=!1;this.mu={};this.vl=[null,null];this.K=null;this.Pt=this.vu=this.Dh=this.eh=!1;this.Vc=!0;this.oj=this.Yb=!1;this.Zb=null;var d=this;this.xg=function(a){var b=d.partManager;if(a.model===b.diagram.model&&b.diagram.ea){b.diagram.ea=!1;try{var c=a.change;""===a.modelChange&&c===af&&b.updateDataBindings(a.object,a.propertyName)}finally{b.diagram.ea=!0}}};this.Cm=function(a){d.partManager.doModelChanged(a)};
this.Mw=!0;this.fe=-2;this.Ej=new Lb;this.lu=new E;this.Mf=!1;this.Gh=this.Fh=this.Dq=this.Dc=!0;this.Eq=!1;this.Kq=this.Iq=this.Oh=this.Nh=this.Mh=this.Lh=this.Jh=this.Kh=this.Ih=this.Hq=this.Qh=this.Hh=this.Ph=this.Fq=!0;this.Qe=this.Kc=!1;this.Jq=this.Gq=this.wr=this.vr=!0;this.rs=this.ps=16;this.su=this.os=!1;this.hp=this.qs=null;this.tu=this.uu=0;this.eb=(new Ic(5)).freeze();this.Bq=(new F).freeze();this.Sr=999999999;this.zq=(new F).freeze();this.ci=this.mj=this.Qg=!0;this.$h=this.Pg=!1;this.gc=
null;this.Bg=!0;this.ge=!1;this.Aq=new F;this.xw=new F;this.Nb=null;this.Jo=1;this.Gw=0;this.xe={scale:1,position:new H,bounds:new N,nx:!1};this.Lw=(new N(NaN,NaN,NaN,NaN)).freeze();this.Gp=(new L(NaN,NaN)).freeze();this.un=(new N(NaN,NaN,NaN,NaN)).freeze();this.Jr=!1;this.hr=null;ui(this);this.Or=this.sr=this.Zr=this.pw=this.ow=this.qw=this.Ug=this.Zh=this.Xf=null;vi(this);this.Eb=null;this.rr=!1;this.Kk=null;this.partManager=new ti;this.toolManager=new Ua;this.toolManager.initializeStandardTools();
this.currentTool=this.defaultTool=this.toolManager;this.gr=null;this.Mk=new yf;this.ds=this.cs=null;this.xp=!1;this.commandHandler=wi();this.model=xi();this.eh=!0;this.layout=new yi;this.eh=!1;this.sw=this.Tt=null;this.Mb=1;this.jh=null;this.ne=1;this.vo=0;this.wo=[0,0,0,0,0];this.xo=0;this.Fd=1;this.kl=0;this.Lr=new H;this.Au=500;this.Mq=new H;this.Re=!1;this.preventDefault=this.nt=this.km=this.lm=this.jm=this.im=this.lk=this.nk=this.mk=this.jk=this.kk=this.Zv=this.Rv=this.Sv=this.Tv=this.si=this.bp=
this.ri=this.ap=null;this.u=!1;this.ai=new zi;this.As=!1;void 0!==a&&Ai(this,a);this.ob=!1}P.prototype.clear=function(){this.model.clear();Bi=null;Ci="";Di(this,!1);this.un=(new N(NaN,NaN,NaN,NaN)).freeze();this.R()};
function Di(a,b){var c=null;null!==a.Eb&&(c=a.Eb.part);a.animationManager.vd();for(var d=[],e=a.Pa.length,f=0;f<e;f++){var g=a.Pa.j[f];if(b)for(var h=g.parts;h.next();){var k=h.value;k!==c&&null===k.data&&d.push(k)}g.clear()}a.partManager.clear();a.Dd.clear();a.Xh.clear();a.zi.clear();a.Qp();a.Ku();a.Kk=null;La=[];null!==c&&(a.add(c),a.partManager.parts.remove(c));if(b)for(b=0;b<d.length;b++)a.add(d[b])}function wi(){return null}
P.prototype.reset=function(){this.ob=!0;this.clear();this.Ak=new Oh;this.Ak.hb(this);this.Pa=new E;this.Dv();this.Cv();this.sa=(new H(NaN,NaN)).freeze();this.Ca=1;this.yr=(new H(NaN,NaN)).freeze();this.zr=NaN;this.Tr=1E-4;this.Rr=100;this.Is=(new H(NaN,NaN)).freeze();this.pr=(new N(NaN,NaN,NaN,NaN)).freeze();this.ti=(new Ic(0,0,0,0)).freeze();this.Ij=oi;this.ss=!1;this.ns=this.js=null;this.cj=pi;this.ej=Ed;this.bi=pi;this.Nn=Ed;this.Ar=this.xr=Tc;this.Sm=250;this.Tm=(new Ic(16,16,16,16)).freeze();
this.Ir=!0;this.Ds=ri;this.Fs=tg;this.pn="auto";this.Rf=this.Wr=this.Tf=this.Uf=this.Wf=this.Cf=this.Gf=this.Bf=null;this.ur=!1;this.Df=this.hg=this.Vf=this.Sf=null;this.Mf=!1;this.Gh=this.Fh=this.Dq=this.Dc=!0;this.Eq=!1;this.Jq=this.Gq=this.wr=this.vr=this.Kq=this.Iq=this.Oh=this.Nh=this.Mh=this.Lh=this.Jh=this.Kh=this.Ih=this.Hq=this.Qh=this.Hh=this.Ph=this.Fq=!0;this.rs=this.ps=16;this.eb=(new Ic(5)).freeze();this.Sr=999999999;this.gc=null;this.Jr=!1;this.Mb=1;this.jh=null;this.vo=0;this.wo=[0,
0,0,0,0];this.xo=0;vi(this);this.Eb=null;this.partManager=new ti;this.toolManager=new Ua;this.toolManager.initializeStandardTools();this.ds=this.cs=this.gr=null;this.xp=!1;this.Mk.reset();this.ou=new Lb;this.ou.clear();this.currentTool=this.defaultTool=this.toolManager;this.commandHandler=wi();this.eh=!0;ui(this);this.layout=new yi;this.eh=!1;this.model=xi();this.model.undoManager=new ff;this.ge=!1;this.Xk=!0;this.ob=this.qd=!1;this.R();this.oe=this.Af=null;qi(this);this.Xq=""};
function vi(a){a.Xf=new Lb;var b=new V,c=new vh;c.bind(new Ei("text","",Qa));b.add(c);a.qw=b;a.Xf.add("",b);b=new V;c=new vh;c.stroke="brown";c.bind(new Ei("text","",Qa));b.add(c);a.Xf.add("Comment",b);b=new V;b.selectable=!1;b.avoidable=!1;c=new W;c.figure="Ellipse";c.fill="black";c.stroke=null;c.desiredSize=(new L(3,3)).ga();b.add(c);a.Xf.add("LinkLabel",b);a.Zh=new Lb;b=new ig;b.selectionObjectName="GROUPPANEL";b.type=X.Vertical;c=new vh;c.font="bold 12pt sans-serif";c.bind(new Ei("text","",Qa));
b.add(c);c=new X(X.Auto);c.name="GROUPPANEL";var d=new W;d.figure="Rectangle";d.fill="rgba(128,128,128,0.2)";d.stroke="black";c.add(d);d=new Xg;d.padding=(new Ic(5,5,5,5)).ga();c.add(d);b.add(c);a.ow=b;a.Zh.add("",b);a.Ug=new Lb;b=new S;c=new W;c.isPanelMain=!0;b.add(c);c=new W;c.toArrow="Standard";c.fill="black";c.stroke=null;c.strokeWidth=0;b.add(c);a.pw=b;a.Ug.add("",b);b=new S;c=new W;c.isPanelMain=!0;c.stroke="brown";b.add(c);a.Ug.add("Comment",b);b=new pf;b.type=X.Auto;c=new W;c.fill=null;c.stroke=
"dodgerblue";c.strokeWidth=3;b.add(c);c=new Xg;c.margin=(new Ic(1.5,1.5,1.5,1.5)).ga();b.add(c);a.Zr=b;a.sr=b;b=new pf;b.type=X.Link;c=new W;c.isPanelMain=!0;c.fill=null;c.stroke="dodgerblue";c.strokeWidth=3;b.add(c);a.Or=b}
P.prototype.setRTL=function(a){a=void 0===a?this.div:a;null===a&&(a=w.document.body);var b=va("div");b.dir="rtl";b.style.cssText="font-size: 14px; width: 1px; height: 1px; position: absolute; top: -1000px; overflow: scroll;";b.textContent="A";a.appendChild(b);var c="reverse";0<b.scrollLeft?c="default":(b.scrollLeft=1,0===b.scrollLeft&&(c="negative"));a.removeChild(b);this.qu=c};
P.prototype.setScrollWidth=function(a){a=void 0===a?this.div:a;null===a&&(a=w.document.body);var b=0;if(th){var c=Ii;b=Ji;null===c&&(c=Ii=va("p"),c.style.width="100%",c.style.height="200px",c.style.boxSizing="content-box",b=Ji=va("div"),b.style.position="absolute",b.style.visibility="hidden",b.style.width="200px",b.style.height="150px",b.style.boxSizing="content-box",b.appendChild(c));b.style.overflow="hidden";a.appendChild(b);var d=c.offsetWidth;b.style.overflow="scroll";c=c.offsetWidth;d===c&&(c=
b.clientWidth);a.removeChild(b);b=d-c;0!==b||mb||(b=11)}this.Gb=b};P.prototype.fb=function(a){a.classType===P&&(this.autoScale=a)};P.prototype.toString=function(a){void 0===a&&(a=0);var b="";this.div&&this.div.id&&(b=this.div.id);b='Diagram "'+b+'"';if(0>=a)return b;for(var c=this.Pa.iterator;c.next();)b+="\n  "+c.value.toString(a-1);return b};P.prototype.addEventListener=function(a,b,c,d){a.addEventListener(b,c,d)};P.prototype.removeEventListener=function(a,b,c,d){a.removeEventListener(b,c,d)};
function Ki(a){var b=a.Da.Ga;b instanceof HTMLCanvasElement&&(a.Lq?(a.addEventListener(b,"pointerdown",a.im,!1),a.addEventListener(b,"pointermove",a.jm,!1),a.addEventListener(b,"pointerup",a.lm,!1),a.addEventListener(b,"pointerout",a.km,!1)):(a.addEventListener(b,"touchstart",a.Tv,!1),a.addEventListener(b,"touchmove",a.Sv,!1),a.addEventListener(b,"touchend",a.Rv,!1),a.addEventListener(b,"mousemove",a.kk,!1),a.addEventListener(b,"mousedown",a.jk,!1),a.addEventListener(b,"mouseup",a.mk,!1),a.addEventListener(b,
"mouseout",a.lk,!1)),a.addEventListener(b,"mouseenter",a.By,!1),a.addEventListener(b,"mouseleave",a.Cy,!1),a.addEventListener(b,"wheel",a.nk,!1),a.addEventListener(b,"keydown",a.qz,!1),a.addEventListener(b,"keyup",a.rz,!1),a.addEventListener(b,"blur",a.ny,!1),a.addEventListener(b,"focus",a.oy,!1),a.addEventListener(b,"selectstart",function(a){a.preventDefault();return!1},!1),a.addEventListener(b,"contextmenu",function(a){a.preventDefault();return!1},!1),a.addEventListener(b,"gesturestart",function(b){a.toolManager.gestureBehavior!==
sf&&(a.toolManager.gestureBehavior===rf?b.preventDefault():a.Re&&a.lastInput.handled||(b.preventDefault(),a.Jo=a.scale,a.currentTool.doCancel()))},!1),a.addEventListener(b,"gesturechange",function(b){if(a.toolManager.gestureBehavior!==sf)if(a.toolManager.gestureBehavior===rf)b.preventDefault();else if(!a.Re||!a.lastInput.handled){b.preventDefault();var c=b.scale;if(null!==a.Jo){var e=a.Da.getBoundingClientRect();b=new H(b.pageX-window.scrollX-a.xa/e.width*e.left,b.pageY-window.scrollY-a.wa/e.height*
e.top);c=a.Jo*c;e=a.commandHandler;if(c!==a.scale&&e.canResetZoom(c)){var f=a.zoomPoint;a.zoomPoint=b;e.resetZoom(c);a.zoomPoint=f}}}},!1),a.addEventListener(w,"resize",a.Zv,!1))}function Kf(a){30<a.vo&&(a.jh=1)}function Uf(a,b){null!==a.jh&&(a.jh=null,b&&a.nt(),a.vo=0,a.wo=[0,0,0,0,0],a.xo=0)}P.prototype.computePixelRatio=function(){return null!==this.jh?this.jh:w.devicePixelRatio||1};P.prototype.doMouseMove=function(){this.currentTool.doMouseMove()};P.prototype.doMouseDown=function(){this.currentTool.doMouseDown()};
P.prototype.doMouseUp=function(){this.currentTool.doMouseUp()};P.prototype.doMouseWheel=function(){this.currentTool.doMouseWheel()};P.prototype.doKeyDown=function(){this.currentTool.doKeyDown()};P.prototype.doKeyUp=function(){this.currentTool.doKeyUp()};P.prototype.doFocus=function(){this.focus()};P.prototype.focus=function(){if(this.Da)if(this.scrollsPageOnFocus)this.Da.focus();else{var a=w.scrollX||w.pageXOffset,b=w.scrollY||w.pageYOffset;this.Da.focus();w.scrollTo(a,b)}};P.prototype.oy=function(){this.C.aa("GainedFocus")};
P.prototype.ny=function(){this.C.aa("LostFocus")};function Th(a){if(null!==a.Da){var b=a.Ia;if(0!==b.clientWidth&&0!==b.clientHeight){a.setScrollWidth();var c=a.$h?a.Gb:0,d=a.Pg?a.Gb:0,e=a.Mb;a.Mb=a.computePixelRatio();a.Mb!==e&&(a.On=!0,a.cc());if(b.clientWidth!==a.xa+c||b.clientHeight!==a.wa+d)a.mj=!0,a.nc=!0,b=a.layout,null!==b&&b.isViewportSized&&a.autoScale===pi&&(a.Sk=!0,b.B()),a.Yb||a.cc()}}}
function ui(a){var b=new fi;b.name="Background";a.Ql(b);b=new fi;b.name="";a.Ql(b);b=new fi;b.name="Foreground";a.Ql(b);b=new fi;b.name="Adornment";b.isTemporary=!0;a.Ql(b);b=new fi;b.name="Tool";b.isTemporary=!0;b.isBoundsIncluded=!0;a.Ql(b);b=new fi;b.name="Grid";b.allowSelect=!1;b.pickable=!1;b.isTemporary=!0;a.Pw(b,a.Zl("Background"))}
function Li(a){a.Eb=new X(X.Grid);a.Eb.name="GRID";var b=new W;b.figure="LineH";b.stroke="lightgray";b.strokeWidth=.5;b.interval=1;a.Eb.add(b);b=new W;b.figure="LineH";b.stroke="gray";b.strokeWidth=.5;b.interval=5;a.Eb.add(b);b=new W;b.figure="LineH";b.stroke="gray";b.strokeWidth=1;b.interval=10;a.Eb.add(b);b=new W;b.figure="LineV";b.stroke="lightgray";b.strokeWidth=.5;b.interval=1;a.Eb.add(b);b=new W;b.figure="LineV";b.stroke="gray";b.strokeWidth=.5;b.interval=5;a.Eb.add(b);b=new W;b.figure="LineV";
b.stroke="gray";b.strokeWidth=1;b.interval=10;a.Eb.add(b);b=new T;b.add(a.Eb);b.layerName="Grid";b.zOrder=0;b.isInDocumentBounds=!1;b.isAnimated=!1;b.pickable=!1;b.locationObjectName="GRID";a.add(b);a.partManager.parts.remove(b);a.Eb.visible=!1}function Mi(){this.C.su?this.C.su=!1:this.C.isEnabled?this.C.Zw(this):Ni(this.C)}function Oi(a){this.C.isEnabled?(this.C.uu=a.target.scrollTop,this.C.tu=a.target.scrollLeft):Ni(this.C)}
P.prototype.Zw=function(a){if(null!==this.Da){this.os=!0;var b=this.documentBounds,c=this.viewportBounds,d=this.ti,e=b.x-d.left,f=b.y-d.top,g=b.width+d.left+d.right,h=b.height+d.top+d.bottom,k=b.right+d.right;d=b.bottom+d.bottom;var l=c.x;b=c.y;var m=c.width,n=c.height,p=c.right,q=c.bottom;c=this.scale;var r=a.scrollLeft;if(this.Sn)switch(this.qu){case "negative":r=r+a.scrollWidth-a.clientWidth;break;case "reverse":r=a.scrollWidth-r-a.clientWidth}var u=r;m<g||n<h?(r=H.allocAt(this.position.x,this.position.y),
this.allowHorizontalScroll&&this.tu!==u&&(r.x=u/c+e,this.tu=u),this.allowVerticalScroll&&this.uu!==a.scrollTop&&(r.y=a.scrollTop/c+f,this.uu=a.scrollTop),this.position=r,H.free(r),this.mj=this.os=!1):(r=H.alloc(),a.Ux&&this.allowHorizontalScroll&&(e<l&&(this.position=r.h(u+e,this.position.y)),k>p&&(this.position=r.h(-(this.qs.scrollWidth-this.xa)+u-this.xa/c+k,this.position.y))),a.Vx&&this.allowVerticalScroll&&(f<b&&(this.position=r.h(this.position.x,a.scrollTop+f)),d>q&&(this.position=r.h(this.position.x,
-(this.qs.scrollHeight-this.wa)+a.scrollTop-this.wa/c+d))),H.free(r),Pi(this),this.mj=this.os=!1,b=this.documentBounds,c=this.viewportBounds,k=b.right,p=c.right,d=b.bottom,q=c.bottom,e=b.x,l=c.x,f=b.y,b=c.y,m>=g&&e>=l&&k<=p&&(this.hp.style.width="1px"),n>=h&&f>=b&&d<=q&&(this.hp.style.height="1px"))}};P.prototype.computeBounds=function(){0<this.Dd.count&&Qi(this);return Ri(this)};
function Ri(a){if(a.fixedBounds.o()){var b=a.fixedBounds.copy();b.Np(a.eb);return b}for(var c=!0,d=a.Pa.j,e=d.length,f=0;f<e;f++){var g=d[f];if(g.visible&&(!g.isTemporary||g.isBoundsIncluded)){g=g.Fa.j;for(var h=g.length,k=0;k<h;k++){var l=g[k];l.isInDocumentBounds&&l.isVisible()&&(l=l.actualBounds,l.o()&&(c?(c=!1,b=l.copy()):b.Jc(l)))}}}c&&(b=new N(0,0,0,0));b.Np(a.eb);return b}
P.prototype.computePartsBounds=function(a,b){void 0===b&&(b=!1);var c=null;if(Ea(a))for(var d=0;d<a.length;d++){var e=a[d];!b&&e instanceof S||(e.$b(),null===c?c=e.actualBounds.copy():c.Jc(e.actualBounds))}else for(a=a.iterator;a.next();)d=a.value,!b&&d instanceof S||(d.$b(),null===c?c=d.actualBounds.copy():c.Jc(d.actualBounds));return null===c?new N(NaN,NaN,0,0):c};
function Si(a,b){if((b||a.ge)&&!a.ob&&null!==a.Da&&!a.animationManager.isAnimating&&a.documentBounds.o()){a.ob=!0;var c=a.cj;b&&a.bi!==pi&&(c=a.bi);var d=c!==pi?Ti(a,c):a.scale;c=a.viewportBounds.copy();var e=a.xa/d,f=a.wa/d,g=null,h=a.animationManager;h.rb&&(g=a.sa.copy());var k=a.ej,l=a.Nn;b&&!k.gb()&&(l.gb()||l.Kb())&&(k=l.Kb()?dd:l);Ui(a,a.documentBounds,e,f,k,b);null!==g&&di(h,g,a.sa);b=a.scale;a.scale=d;a.ob=!1;d=a.viewportBounds;d.Qa(c)||a.lq(c,d,b,!1)}}
function Ti(a,b){var c=a.dr;if(null===a.Da)return c;a.Qg&&Vi(a,a.computeBounds());var d=a.documentBounds;if(!d.o())return c;var e=d.width;d=d.height;var f=a.xa,g=a.wa,h=f/e,k=g/d;return b===Wi?(b=Math.min(k,h),b>c&&(b=c),b<a.minScale&&(b=a.minScale),b>a.maxScale&&(b=a.maxScale),b):b===Xi?(b=k>h?(g-a.Gb)/d:(f-a.Gb)/e,b>c&&(b=c),b<a.minScale&&(b=a.minScale),b>a.maxScale&&(b=a.maxScale),b):a.scale}
P.prototype.zoomToFit=function(){var a=this.Ij;this.Ij=oi;this.scale=Ti(this,Wi);a!==oi&&(Si(this,!1),Ui(this,this.documentBounds,this.xa/this.Ca,this.wa/this.Ca,this.ej,!1));this.Ij=a};t=P.prototype;
t.Yz=function(a,b){void 0===b&&(b=Wi);var c=a.width,d=a.height;if(!(0===c||0===d||isNaN(c)&&isNaN(d))){var e=1;if(b===Wi||b===Xi)if(isNaN(c))e=this.viewportBounds.height*this.scale/d;else if(isNaN(d))e=this.viewportBounds.width*this.scale/c;else{e=this.xa;var f=this.wa;e=b===Xi?f/d>e/c?(f-(this.Pg?this.Gb:0))/d:(e-(this.$h?this.Gb:0))/c:Math.min(f/d,e/c)}this.scale=e;this.position=new H(a.x,a.y)}};
t.iy=function(a,b){this.Qg&&Vi(this,this.computeBounds());var c=this.documentBounds,d=this.viewportBounds;this.position=new H(c.x+(a.x*c.width+a.offsetX)-(b.x*d.width-b.offsetX),c.y+(a.y*c.height+a.offsetY)-(b.y*d.height-b.offsetY))};
function Ui(a,b,c,d,e,f){a.sa.ha();var g=a.sa,h=g.x,k=g.y;if(f||a.scrollMode===oi)e.gb()&&(c>b.width&&(h=b.x+(e.x*b.width+e.offsetX)-(e.x*c-e.offsetX)),d>b.height&&(k=b.y+(e.y*b.height+e.offsetY)-(e.y*d-e.offsetY))),e=a.ti,f=c-b.width,c<b.width+e.left+e.right?(h=Math.min(h+c/2,b.right+Math.max(f,e.right)-c/2),h=Math.max(h,b.left-Math.max(f,e.left)+c/2),h-=c/2):h>b.left?h=b.left:h<b.right-c&&(h=b.right-c),c=d-b.height,d<b.height+e.top+e.bottom?(k=Math.min(k+d/2,b.bottom+Math.max(c,e.bottom)-d/2),k=
Math.max(k,b.top-Math.max(c,e.top)+d/2),k-=d/2):k>b.top?k=b.top:k<b.bottom-d&&(k=b.bottom-d);g.x=isFinite(h)?h:-a.eb.left;g.y=isFinite(k)?k:-a.eb.top;null!==a.positionComputation&&(b=a.positionComputation(a,g),g.x=b.x,g.y=b.y);a.sa.freeze()}t.$l=function(a,b){void 0===b&&(b=!0);if(b){if(a=gg(this,a,function(a){return a.part},function(a){return a.canSelect()}),a instanceof T)return a}else if(a=gg(this,a,function(a){return a.part}),a instanceof T)return a;return null};
t.Rb=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);Qi(this);for(var d=this.Pa.iteratorBackwards;d.next();){var e=d.value;if(e.visible&&(e=e.Rb(a,b,c),null!==e))return e}return null};function gg(a,b,c,d){void 0===c&&(c=null);void 0===d&&(d=null);Qi(a);for(a=a.Pa.iteratorBackwards;a.next();){var e=a.value;if(e.visible&&!e.isTemporary&&(e=e.Rb(b,c,d),null!==e))return e}return null}
t.Ly=function(a,b,c){void 0===b&&(b=!0);return Yi(this,a,function(a){return a.part},b?function(a){return a instanceof T&&a.canSelect()}:null,c)};function Yi(a,b,c,d,e){void 0===c&&(c=null);void 0===d&&(d=null);e instanceof E||e instanceof F||(e=new F);Qi(a);for(a=a.Pa.iteratorBackwards;a.next();){var f=a.value;f.visible&&!f.isTemporary&&f.Hi(b,c,d,e)}return e}
t.Hi=function(a,b,c,d){void 0===b&&(b=null);void 0===c&&(c=null);d instanceof E||d instanceof F||(d=new F);Qi(this);for(var e=this.Pa.iteratorBackwards;e.next();){var f=e.value;f.visible&&f.Hi(a,b,c,d)}return d};t.hx=function(a,b,c,d){void 0===b&&(b=!1);void 0===c&&(c=!0);return Zi(this,a,function(a){return a instanceof T&&(!c||a.canSelect())},b,d)};
t.ng=function(a,b,c,d,e){void 0===b&&(b=null);void 0===c&&(c=null);void 0===d&&(d=!1);e instanceof E||e instanceof F||(e=new F);Qi(this);for(var f=this.Pa.iteratorBackwards;f.next();){var g=f.value;g.visible&&g.ng(a,b,c,d,e)}return e};function Zi(a,b,c,d,e){var f=null;void 0===f&&(f=null);void 0===c&&(c=null);void 0===d&&(d=!1);e instanceof E||e instanceof F||(e=new F);Qi(a);for(a=a.Pa.iteratorBackwards;a.next();){var g=a.value;g.visible&&!g.isTemporary&&g.ng(b,f,c,d,e)}return e}
t.My=function(a,b,c,d,e){void 0===c&&(c=!0);void 0===d&&(d=!0);return $i(this,a,b,function(a){return a instanceof T&&(!d||a.canSelect())},c,e)};t.og=function(a,b,c,d,e,f){void 0===c&&(c=null);void 0===d&&(d=null);void 0===e&&(e=!0);if(!1!==e&&!0!==e){if(e instanceof E||e instanceof F)f=e;e=!0}f instanceof E||f instanceof F||(f=new F);Qi(this);for(var g=this.Pa.iteratorBackwards;g.next();){var h=g.value;h.visible&&h.og(a,b,c,d,e,f)}return f};
function $i(a,b,c,d,e,f){var g=null;void 0===g&&(g=null);void 0===d&&(d=null);void 0===e&&(e=!0);if(!1!==e&&!0!==e){if(e instanceof E||e instanceof F)f=e;e=!0}f instanceof E||f instanceof F||(f=new F);Qi(a);for(a=a.Pa.iteratorBackwards;a.next();){var h=a.value;h.visible&&!h.isTemporary&&h.og(b,c,g,d,e,f)}return f}P.prototype.acceptEvent=function(a){return aj(this,a,a instanceof MouseEvent)};
function aj(a,b,c){var d=a.Ye;a.Ye=a.sj;a.sj=d;d.diagram=a;d.event=b;c?bj(a,b,d):(d.viewPoint=a.Ye.viewPoint,d.documentPoint=a.Ye.documentPoint);a=0;b.ctrlKey&&(a+=1);b.altKey&&(a+=2);b.shiftKey&&(a+=4);b.metaKey&&(a+=8);d.modifiers=a;d.button=b.button;void 0===b.buttons||bb||(d.buttons=b.buttons);ib&&0===b.button&&b.ctrlKey&&(d.button=2);d.down=!1;d.up=!1;d.clickCount=1;d.delta=0;d.handled=!1;d.bubbles=!1;d.timestamp=b.timeStamp;d.isMultiTouch=!1;d.targetDiagram=cj(b);d.targetObject=null;return d}
function cj(a){var b=a.target.C;if(!b){var c=a.path;c||"function"!==typeof a.composedPath||(c=a.composedPath());c&&c[0]&&(b=c[0].C)}return b?b:null}function dj(a,b,c,d){var e=ej(a,b,!0,!1,!0,d);bj(a,c,e);e.targetDiagram=cj(b);e.targetObject=null;d||e.clone(a.Yh);return e}
function fj(a,b,c,d){var e;d=ej(a,b,!1,!1,!1,d);null!==c?((e=w.document.elementFromPoint(c.clientX,c.clientY))&&e.C?(b=c,c=e.C):(b=void 0!==b.targetTouches?b.targetTouches[0]:b,c=a),d.targetDiagram=c,bj(a,b,d)):null!==a.Ye?(d.documentPoint=a.Ye.documentPoint,d.viewPoint=a.Ye.viewPoint,d.targetDiagram=a.Ye.targetDiagram):null!==a.Yh&&(d.documentPoint=a.Yh.documentPoint,d.viewPoint=a.Yh.viewPoint,d.targetDiagram=a.Yh.targetDiagram);d.targetObject=null;return d}
function ej(a,b,c,d,e,f){var g=a.Ye;a.Ye=a.sj;a.sj=g;g.diagram=a;g.clickCount=1;var h=g.delta=0;b.ctrlKey&&(h+=1);b.altKey&&(h+=2);b.shiftKey&&(h+=4);b.metaKey&&(h+=8);g.modifiers=h;g.button=0;g.buttons=1;g.event=b;g.timestamp=b.timeStamp;a.Lq&&b instanceof w.PointerEvent&&"touch"!==b.pointerType&&(g.button=b.button,void 0===b.buttons||bb||(g.buttons=b.buttons),ib&&0===b.button&&b.ctrlKey&&(g.button=2));g.down=c;g.up=d;g.handled=!1;g.bubbles=e;g.isMultiTouch=f;return g}
function gj(a,b){if(a.bubbles)return!0;void 0!==b.stopPropagation&&b.stopPropagation();!1!==b.cancelable&&b.preventDefault();b.cancelBubble=!0;return!1}
P.prototype.qz=function(a){var b=this.C;if(!this.C.isEnabled)return!1;var c=aj(b,a,!1);c.key=String.fromCharCode(a.which);c.down=!0;switch(a.which){case 8:c.key="Backspace";break;case 33:c.key="PageUp";break;case 34:c.key="PageDown";break;case 35:c.key="End";break;case 36:c.key="Home";break;case 37:c.key="Left";break;case 38:c.key="Up";break;case 39:c.key="Right";break;case 40:c.key="Down";break;case 45:c.key="Insert";break;case 46:c.key="Del";break;case 48:c.key="0";break;case 187:case 61:case 107:c.key=
"Add";break;case 189:case 173:case 109:c.key="Subtract";break;case 27:c.key="Esc"}b.doKeyDown();return gj(c,a)};
P.prototype.rz=function(a){var b=this.C;if(!b.isEnabled)return!1;var c=aj(b,a,!1);c.key=String.fromCharCode(a.which);c.up=!0;switch(a.which){case 8:c.key="Backspace";break;case 33:c.key="PageUp";break;case 34:c.key="PageDown";break;case 35:c.key="End";break;case 36:c.key="Home";break;case 37:c.key="Left";break;case 38:c.key="Up";break;case 39:c.key="Right";break;case 40:c.key="Down";break;case 45:c.key="Insert";break;case 46:c.key="Del"}b.doKeyUp();return gj(c,a)};
P.prototype.By=function(a){var b=this.C;if(!b.isEnabled)return!1;var c=aj(b,a,!0);null!==b.mouseEnter&&b.mouseEnter(c);return gj(c,a)};P.prototype.Cy=function(a){var b=this.C;if(!b.isEnabled)return!1;var c=aj(b,a,!0);null!==b.mouseLeave&&b.mouseLeave(c);return gj(c,a)};
P.prototype.getMouse=function(a){var b=this.Da;if(null===b)return new H(0,0);var c=b.getBoundingClientRect();b=a.clientX-this.xa/c.width*c.left;a=a.clientY-this.wa/c.height*c.top;return null!==this.tb?Qb(new H(b,a),this.tb):new H(b,a)};
function bj(a,b,c){var d=a.Da,e=a.xa,f=a.wa,g=0,h=0;null!==d&&(d=d.getBoundingClientRect(),g=b.clientX-e/d.width*d.left,h=b.clientY-f/d.height*d.top);c.viewPoint.h(g,h);null!==a.tb?(b=H.allocAt(g,h),a.tb.Ud(b),c.documentPoint.assign(b),H.free(b)):c.documentPoint.h(g,h)}
function Ye(a,b,c,d){if(void 0!==b.targetTouches){if(2>b.targetTouches.length)return;b=b.targetTouches[c]}else if(null!==a.vl[0])b=a.vl[c];else return;c=a.Da;null!==c&&(c=c.getBoundingClientRect(),d.h(b.clientX-a.xa/c.width*c.left,b.clientY-a.wa/c.height*c.top))}t=P.prototype;t.Xa=function(){this.Qg||(this.Qg=!0,this.cc(!0))};function ci(a){a.Yb||Qi(a);a.Qg&&Vi(a,a.computeBounds())}t.tf=function(){this.ob||this.Yb||(this.R(),hj(this),Pi(this),this.Xa(),this.fd())};t.pz=function(){return this.qd};
t.wy=function(a){void 0===a&&(a=null);var b=this.animationManager,c=b.isEnabled;b.vd();b.isEnabled=!1;pg(this);this.ge=!1;b.isEnabled=c;null!==a&&ua(a,1)};t.cc=function(a){void 0===a&&(a=!1);if(!0!==this.qd&&!(this.ob||!1===a&&this.Yb)){this.qd=!0;var b=this;w.requestAnimationFrame(function(){b.qd&&b.fd()})}};t.fd=function(){if(!this.Xk||this.qd)this.Xk&&(this.Xk=!1),pg(this)};function ij(a,b){a.animationManager.isAnimating||a.ob||!a.mj||Ni(a)||(b&&Qi(a),Si(a,!1))}
function pg(a,b){if(!a.Yb&&(a.qd=!1,null!==a.Ia||a.Gp.o())){a.Yb=!0;var c=a.animationManager,d=a.lu;if(!c.isTicking&&0!==d.length){for(var e=d.j,f=e.length,g=0;g<f;g++){var h=e[g];jj(h,!1);h.s()}d.clear()}d=a.xw;0<d.count&&(d.each(function(a){a.Yv()}),d.clear());e=d=!1;c.isAnimating&&(e=!0,d=a.skipsUndoManager,a.skipsUndoManager=!0);c.rb||Th(a);ij(a,!1);null!==a.Eb&&(a.Eb.visible&&!a.rr&&(kj(a),a.rr=!0),!a.Eb.visible&&a.rr&&(a.rr=!1));Qi(a);f=!1;if(!a.ge||a.Bg)a.ge?lj(a,!a.Sk):(a.Aa("Initial Layout"),
!1===c.isEnabled&&c.vd(),lj(a,!1)),f=!0;a.Sk=!1;Qi(a);a.vu||c.isAnimating||ci(a);ij(a,!0);f&&(a.ge||mj(a),a.aa("LayoutCompleted"));Qi(a);f&&!a.ge&&(a.ge=!0,a.Za("Initial Layout"),a.skipsUndoManager||a.undoManager.clear(),ua(function(){a.isModified=!1},1));a.Ju();Sh(c);b||a.hc(a.Yc);e&&(a.skipsUndoManager=d);a.Yb=!1}}
function mj(a){var b=a.Pa.j;a.hd(b,b.length,a);a.bi!==pi?a.scale=Ti(a,a.bi):a.cj!==pi?a.scale=Ti(a,a.cj):(b=a.initialScale,isFinite(b)&&0<b&&(a.scale=b));b=a.initialPosition;if(b.o())a.position=b;else{b=H.alloc();b.Vi(a.documentBounds,a.initialDocumentSpot);var c=a.viewportBounds;c=N.allocAt(0,0,c.width,c.height);var d=H.alloc();d.Vi(c,a.initialViewportSpot);d.h(b.x-d.x,b.y-d.y);a.position=d;N.free(c);H.free(d);H.free(b);hj(a);ij(a,!0);Si(a,!0)}a.aa("InitialLayoutCompleted");kj(a)}
function Qi(a){if((a.Yb||!a.animationManager.isAnimating)&&0!==a.Dd.count){for(var b=0;23>b;b++){var c=a.Dd.iterator;if(null===c||0===a.Dd.count)break;a.Dd=new F;a.Yv(c,a.Dd)}a.nodes.each(function(a){a instanceof ig&&0!==(a.S&65536)!==!1&&(a.S=a.S^65536)})}}
t.Yv=function(a,b){for(a.reset();a.next();){var c=a.value;!c.bc()||c instanceof ig||(c.Ri()?(c.measure(Infinity,Infinity),c.arrange()):b.add(c))}for(a.reset();a.next();)c=a.value,c instanceof ig&&c.isVisible()&&nj(this,c);for(a.reset();a.next();)c=a.value,c instanceof S&&c.isVisible()&&(c.Ri()?(c.measure(Infinity,Infinity),c.arrange()):b.add(c));for(a.reset();a.next();)c=a.value,c instanceof pf&&c.isVisible()&&(c.Ri()?(c.measure(Infinity,Infinity),c.arrange()):b.add(c))};
function nj(a,b){for(var c=Ka(),d=Ka(),e=b.memberParts;e.next();){var f=e.value;f.isVisible()&&(f instanceof ig?(oj(f)||pj(f)||qj(f))&&nj(a,f):f instanceof S?f.fromNode===b||f.toNode===b?d.push(f):c.push(f):(f.measure(Infinity,Infinity),f.arrange()))}a=c.length;for(e=0;e<a;e++)f=c[e],f.measure(Infinity,Infinity),f.arrange();Oa(c);b.measure(Infinity,Infinity);b.arrange();a=d.length;for(b=0;b<a;b++)c=d[b],c.measure(Infinity,Infinity),c.arrange();Oa(d)}
t.hd=function(a,b,c,d){if(this.ci||this.animationManager.isAnimating)for(var e=0;e<b;e++)a[e].hd(c,d)};
t.hc=function(a,b){void 0===b&&(b=null);if(null!==this.Ia){null===this.Da&&A("No canvas specified");var c=this.animationManager;if(!c.rb){var d=new Date;rj(this);if("0"!==this.Ia.style.opacity){var e=a!==this.Yc,f=this.Pa.j,g=f.length,h=this;this.hd(f,g,h);if(e)a.Rc(!0),Pi(this);else if(!this.nc&&null===b&&!c.isAnimating)return;g=this.sa;var k=this.Ca,l=Math.round(g.x*k)/k,m=Math.round(g.y*k)/k;c=this.tb;c.reset();1!==k&&c.scale(k);0===g.x&&0===g.y||c.translate(-l,-m);k=this.Mb;a.setTransform(1,0,
0,1,0,0);a.scale(k,k);a.clearRect(0,0,this.xa,this.wa);a.setTransform(1,0,0,1,0,0);a.scale(k,k);a.transform(c.m11,c.m12,c.m21,c.m22,c.dx,c.dy);l=null!==b?function(c){var d=b;if(c.visible&&0!==c.lb){var e=c.Fa.j,f=e.length;if(0!==f){1!==c.lb&&(a.globalAlpha=c.lb);c=c.Lo;c.length=0;for(var g=h.scale,k=0;k<f;k++){var l=e[k];if(og(l)&&!d.contains(l)){if(l instanceof S&&(l.isOrthogonal&&c.push(l),!1===l.Lc))continue;var m=l.actualBounds;m.width*g>h.ne||m.height*g>h.ne?l.hc(a,h):ii(a,l)}}a.globalAlpha=
1}}}:function(b){b.hc(a,h)};sj(this,a);g=f.length;for(m=0;m<g;m++)a.setTransform(1,0,0,1,0,0),a.scale(k,k),a.transform(c.m11,c.m12,c.m21,c.m22,c.dx,c.dy),l(f[m]);this.ai&&tj(this.ai,this)&&this.hr();e?(this.Yc.Rc(!0),Pi(this)):this.nc=this.ci=!1;e=+new Date-+d;if(null===this.jh){d=this.wo;d[this.xo]=e;this.xo=(this.xo+1)%d.length;for(f=e=0;f<this.wo.length;f++)e+=this.wo[f];this.vo=e/d.length}}}}};
function uj(a,b,c,d,e,f,g,h,k,l){if(null!==a.Ia){null===a.Da&&A("No canvas specified");void 0===g&&(g=null);void 0===h&&(h=null);void 0===k&&(k=!1);void 0===l&&(l=!1);rj(a);a.Yc.Rc(!0);Pi(a);a.oj=!0;var m=a.Ca;a.Ca=e;var n=a.Pa.j,p=n.length;try{var q=new N(f.x,f.y,d.width/e,d.height/e),r=q.copy();r.Np(c);kj(a,r);Qi(a);a.hd(n,p,a,q);var u=a.Mb;b.setTransform(1,0,0,1,0,0);b.scale(u,u);b.clearRect(0,0,d.width,d.height);null!==h&&""!==h&&(b.fillStyle=h,b.fillRect(0,0,d.width,d.height));var x=Qc.alloc();
x.reset();x.translate(c.left,c.top);x.scale(e);0===f.x&&0===f.y||x.translate(-f.x,-f.y);b.setTransform(x.m11,x.m12,x.m21,x.m22,x.dx,x.dy);Qc.free(x);sj(a,b);if(null!==g){var v=new F,y=g.iterator;for(y.reset();y.next();){var z=y.value;!1===l&&"Grid"===z.layer.name||null===z||v.add(z)}var B=function(c){var d=k;if(c.visible&&0!==c.lb&&(void 0===d&&(d=!0),d||!c.isTemporary)){d=c.Fa.j;var e=d.length;if(0!==e){1!==c.lb&&(b.globalAlpha=c.lb);c=c.Lo;c.length=0;for(var f=a.scale,g=0;g<e;g++){var h=d[g];if(og(h)&&
v.contains(h)){if(h instanceof S&&(h.isOrthogonal&&c.push(h),!1===h.Lc))continue;var l=h.actualBounds;l.width*f>a.ne||l.height*f>a.ne?h.hc(b,a):ii(b,h)}}b.globalAlpha=1}}}}else if(!k&&l){var C=a.grid.part,G=C.layer;B=function(c){c===G?C.hc(b,a):c.hc(b,a,k)}}else B=function(c){c.hc(b,a,k)};for(c=0;c<p;c++)B(n[c]);a.oj=!1;a.ai&&tj(a.ai,a)&&a.hr()}finally{a.Ca=m,a.Yc.Rc(!0),Pi(a),a.hd(n,p,a),kj(a)}}}t.De=function(a){return this.cg[a]};
t.Fx=function(a,b){"minDrawingLength"===a&&(this.ne=b);this.cg[a]=b;this.tf()};t.Dv=function(){this.cg=new tb;this.cg.drawShadows=!0;this.cg.textGreeking=!0;this.cg.viewportOptimizations=mb||cb||db?!1:!0;this.cg.temporaryPixelRatio=!0;this.cg.pictureRatioOptimization=!0;this.ne=this.cg.minDrawingLength=1};function sj(a,b){a=a.cg;null!==a&&(void 0!==a.imageSmoothingEnabled&&b.Ex(!!a.imageSmoothingEnabled),a=a.defaultFont,void 0!==a&&null!==a&&(b.font=a))}t.bm=function(a){return this.dl[a]};
t.Oz=function(a,b){this.dl[a]=b};t.Cv=function(){this.dl=new tb;this.dl.extraTouchArea=10;this.dl.extraTouchThreshold=10;this.dl.hasGestureZoom=!0};t.Mv=function(a){vj(this,a)};
function vj(a,b){var c=a instanceof X,d=a instanceof P,e;for(e in b){""===e&&A("Setting properties requires non-empty property names");var f=a,g=e;if(c||d){var h=e.indexOf(".");if(0<h){var k=e.substring(0,h);if(c)f=a.$a(k);else if(f=a[k],void 0===f||null===f)f=a.toolManager[k];Ca(f)?g=e.substr(h+1):A("Unable to find object named: "+k+" in "+a.toString()+" when trying to set property: "+e)}}if("_"!==g[0]&&!Sa(f,g))if(d&&"ModelChanged"===g){a.Rw(b[g]);continue}else if(d&&"Changed"===g){a.rh(b[g]);continue}else if(d&&
Sa(a.toolManager,g))f=a.toolManager;else if(d&&wj(a,g)){a.Pj(g,b[g]);continue}else if(a instanceof Z&&"Changed"===g){a.rh(b[g]);continue}else A('Trying to set undefined property "'+g+'" on object: '+f.toString());f[g]=b[e];"_"===g[0]&&f instanceof Y&&f.Ow(g)}}t.Ju=function(){if(0===this.undoManager.transactionLevel&&0!==this.Xh.count){for(;0<this.Xh.count;){var a=this.Xh;this.Xh=new Lb;for(a=a.iterator;a.next();){var b=a.key;b.cq(a.value);b.ac()}}this.R()}};
t.R=function(a){void 0===a&&(a=null);if(null===a)this.nc=!0,this.cc();else{var b=this.viewportBounds;null!==a&&a.o()&&b.Ic(a)&&(this.nc=!0,this.cc())}this.aa("InvalidateDraw")};
t.lx=function(a,b){if(!0!==this.nc){this.nc=!0;var c=!0===this.De("temporaryPixelRatio");if(!0===this.De("viewportOptimizations")&&this.scrollMode!==xj&&this.ti.Fi(0,0,0,0)&&b.width===a.width&&b.height===a.height){var d=this.scale,e=Math.max(a.x,b.x),f=Math.max(a.y,b.y);d=N.allocAt(e,f,Math.max(0,Math.min(a.x+a.width,b.x+b.width)-e)*d,Math.max(0,Math.min(a.y+a.height,b.y+b.height)-f)*d);if(!this.As&&0<d.width&&0<d.height){if(!(this.Yb||(this.qd=!1,null===this.Ia||(this.Yb=!0,this.Ju(),this.documentBounds.o()||
Vi(this,this.computeBounds()),e=this.Da,null===e||e instanceof yj)))){var g=this.Mb;f=this.xa*g;var h=this.wa*g,k=this.scale*g,l=Math.round(Math.round(b.x*k)-Math.round(a.x*k));b=Math.round(Math.round(b.y*k)-Math.round(a.y*k));k=this.Tt;a=this.sw;k.width!==f&&(k.width=f);k.height!==h&&(k.height=h);a.clearRect(0,0,f,h);k=190*g;var m=70*g,n=Math.max(l,0),p=Math.max(b,0),q=Math.floor(f-n),r=Math.floor(h-p);a.drawImage(e.Ga,n,p,q,r,0,0,q,r);tj(this.ai,this)&&a.clearRect(0,0,k,m);e=Ka();a=Ka();r=Math.abs(l);
q=Math.abs(b);var u=0===n?0:f-r;n=H.allocAt(u,0);r=H.allocAt(r+u,h);a.push(new N(Math.min(n.x,r.x),Math.min(n.y,r.y),Math.abs(n.x-r.x),Math.abs(n.y-r.y)));var x=this.tb;x.reset();x.scale(g,g);1!==this.Ca&&x.scale(this.Ca);g=this.sa;(0!==g.x||0!==g.y)&&isFinite(g.x)&&isFinite(g.y)&&x.translate(-g.x,-g.y);Qb(n,x);Qb(r,x);e.push(new N(Math.min(n.x,r.x),Math.min(n.y,r.y),Math.abs(n.x-r.x),Math.abs(n.y-r.y)));u=0===p?0:h-q;n.h(0,u);r.h(f,q+u);a.push(new N(Math.min(n.x,r.x),Math.min(n.y,r.y),Math.abs(n.x-
r.x),Math.abs(n.y-r.y)));Qb(n,x);Qb(r,x);e.push(new N(Math.min(n.x,r.x),Math.min(n.y,r.y),Math.abs(n.x-r.x),Math.abs(n.y-r.y)));tj(this.ai,this)&&(f=0<l?0:-l,h=0<b?0:-b,n.h(f,h),r.h(k+f,m+h),a.push(new N(Math.min(n.x,r.x),Math.min(n.y,r.y),Math.abs(n.x-r.x),Math.abs(n.y-r.y))),Qb(n,x),Qb(r,x),e.push(new N(Math.min(n.x,r.x),Math.min(n.y,r.y),Math.abs(n.x-r.x),Math.abs(n.y-r.y))));H.free(n);H.free(r);ij(this,!1);null===this.Ia&&A("No div specified");null===this.Da&&A("No canvas specified");if(!this.animationManager.rb&&
(f=this.Yc,this.nc)){rj(this);h=this.Mb;f.setTransform(1,0,0,1,0,0);f.clearRect(0,0,this.xa*h,this.wa*h);f.drawImage(this.Tt.Ga,0<l?0:Math.round(-l),0<b?0:Math.round(-b));l=this.sa;g=this.Ca;k=Math.round(l.x*g)/g;m=Math.round(l.y*g)/g;b=this.tb;b.reset();1!==g&&b.scale(g);0===l.x&&0===l.y||b.translate(-k,-m);f.save();f.beginPath();l=a.length;for(g=0;g<l;g++)k=a[g],0!==k.width&&0!==k.height&&f.rect(Math.floor(k.x),Math.floor(k.y),Math.ceil(k.width),Math.ceil(k.height));f.clip();f.setTransform(1,0,
0,1,0,0);f.scale(h,h);f.transform(b.m11,b.m12,b.m21,b.m22,b.dx,b.dy);b=this.Pa.j;l=b.length;this.hd(b,l,this);sj(this,f);for(h=0;h<l;h++)if(p=b[h],g=e,p.visible&&0!==p.lb){1!==p.lb&&(f.globalAlpha=p.lb);k=p.Lo;k.length=0;m=this.scale;p=p.Fa.j;n=p.length;q=g.length;for(r=0;r<n;r++)if(x=p[r],og(x)){if(x instanceof S&&(x.isOrthogonal&&k.push(x),!1===x.Lc))continue;u=zj(x,x.actualBounds);a:{var v=2/m;for(var y=4/m,z=0;z<q;z++){var B=g[z];if(0!==B.width&&0!==B.height&&u.hv(B.x-v,B.y-v,B.width+y,B.height+
y)){v=!0;break a}}v=!1}v&&(u.width*m>this.ne||u.height*m>this.ne?x.hc(f,this):ii(f,x))}f.globalAlpha=1}f.restore();f.Rc(!0);this.ai&&tj(this.ai,this)&&this.hr();this.nc=this.ci=!1;this.nt()}Oa(e);Oa(a);this.Yb=!1}}else this.fd();N.free(d);c&&(Kf(this),this.fd(),Uf(this,!0))}else c?(Kf(this),this.fd(),Uf(this,!0)):this.fd()}};function hj(a){!1===a.mj&&(a.mj=!0)}function Pi(a){!1===a.ci&&(a.ci=!0)}function rj(a){!1!==a.On&&(a.On=!1,Aj(a,a.xa,a.wa))}
function Aj(a,b,c){var d=a.Mb;a.Da.resize(b*d,c*d,b,c)&&(a.nc=!0,a.Yc.Rc(!0))}
function Ni(a){var b=a.Da;if(null===b)return!0;var c=a.Ia,d=a.xa,e=a.wa,f=a.Lw.copy();if(!f.o())return!0;var g=!1,h=a.$h?a.Gb:0,k=a.Pg?a.Gb:0,l=c.clientWidth||d+h;c=c.clientHeight||e+k;if(l!==d+h||c!==e+k)a.$h=!1,a.Pg=!1,k=h=0,a.xa=l,a.wa=c,g=a.On=!0;a.mj=!1;var m=a.documentBounds,n=0,p=0,q=0,r=0;l=f.width;c=f.height;var u=a.ti;a.contentAlignment.gb()?(m.width>l&&(n=u.left,p=u.right),m.height>c&&(q=u.top,r=u.bottom)):(n=u.left,p=u.right,q=u.top,r=u.bottom);u=m.width+n+p;var x=m.height+q+r;n=m.x-n;
var v=f.x;p=m.right+p;var y=f.right+h;q=m.y-q;var z=f.y;r=m.bottom+r;var B=f.bottom+k,C="1px",G="1px";m=a.scale;var K=!(u<l+h),M=!(x<c+k);a.scrollMode===oi&&(K||M)&&(K&&a.hasHorizontalScrollbar&&a.allowHorizontalScroll&&(C=1,n+1<v&&(C=Math.max((v-n)*m+a.xa,C)),p>y+1&&(C=Math.max((p-y)*m+a.xa,C)),l+h+1<u&&(C=Math.max((u-l+h)*m+a.xa,C)),C=C.toString()+"px"),M&&a.hasVerticalScrollbar&&a.allowVerticalScroll&&(G=1,q+1<z&&(G=Math.max((z-q)*m+a.wa,G)),r>B+1&&(G=Math.max((r-B)*m+a.wa,G)),c+k+1<x&&(G=Math.max((x-
c+k)*m+a.wa,G)),G=G.toString()+"px"));M="1px"!==C;K="1px"!==G;M&&K||!M&&!K||(K&&(y-=a.Gb),M&&(B-=a.Gb),u<l+h||!a.hasHorizontalScrollbar||!a.allowHorizontalScroll||(h=1,n+1<v&&(h=Math.max((v-n)*m+a.xa,h)),p>y+1&&(h=Math.max((p-y)*m+a.xa,h)),l+1<u&&(h=Math.max((u-l)*m+a.xa,h)),C=h.toString()+"px"),M="1px"!==C,h=a.wa,M!==a.Pg&&(h=M?a.wa-a.Gb:a.wa+a.Gb),x<c+k||!a.hasVerticalScrollbar||!a.allowVerticalScroll||(k=1,q+1<z&&(k=Math.max((z-q)*m+h,k)),r>B+1&&(k=Math.max((r-B)*m+h,k)),c+1<x&&(k=Math.max((x-
c)*m+h,k)),G=k.toString()+"px"),K="1px"!==G);if(a.os&&M===a.Pg&&K===a.$h)return d===a.xa&&e===a.wa||a.fd(),!1;M!==a.Pg&&("1px"===C?a.wa=a.wa+a.Gb:a.wa=Math.max(a.wa-a.Gb,1),g=!0);a.Pg=M;a.hp.style.width=C;K!==a.$h&&("1px"===G?a.xa=a.xa+a.Gb:a.xa=Math.max(a.xa-a.Gb,1),g=!0,a.Sn&&(k=H.alloc(),K?(b.style.left=a.Gb+"px",a.position=k.h(a.sa.x+a.Gb/a.scale,a.sa.y)):(b.style.left="0px",a.position=k.h(a.sa.x-a.Gb/a.scale,a.sa.y)),H.free(k)));a.$h=K;a.hp.style.height=G;a.su=!0;g&&(a.On=!0);b=a.qs;k=b.scrollLeft;
a.hasHorizontalScrollbar&&a.allowHorizontalScroll&&(l+1<u?k=(a.position.x-n)*m:n+1<v?k=b.scrollWidth-b.clientWidth:p>y+1&&(k=a.position.x*m));if(a.Sn)switch(a.qu){case "negative":k=-(b.scrollWidth-k-b.clientWidth);break;case "reverse":k=b.scrollWidth-k-b.clientWidth}b.scrollLeft=k;a.hasVerticalScrollbar&&a.allowVerticalScroll&&(c+1<x?b.scrollTop=(a.position.y-q)*m:q+1<z?b.scrollTop=b.scrollHeight-b.clientHeight:r>B+1&&(b.scrollTop=a.position.y*m));l=a.xa;c=a.wa;b.style.width=l+(a.$h?a.Gb:0)+"px";
b.style.height=c+(a.Pg?a.Gb:0)+"px";return d!==l||e!==c||a.animationManager.rb?(a.lq(f,a.viewportBounds,m,g),!1):!0}
t.add=function(a){var b=a.diagram;if(b!==this&&(null!==b&&A("Cannot add part "+a.toString()+" to "+this.toString()+". It is already a part of "+b.toString()),b=this.Zl(a.layerName),null===b&&(b=this.Zl("")),null===b&&A('Cannot add a Part when unable find a Layer named "'+a.layerName+'" and there is no default Layer'),a.layer!==b)){var c=b.Pi(99999999,a,a.diagram===this);0<=c&&this.ab(cf,"parts",b,null,a,null,c);b.isTemporary||this.Xa();a.B(1);c=a.layerChanged;null!==c&&c(a,null,b)}};
t.Pi=function(a){this.partManager.Pi(a);var b=this;Bj(a,function(a){Cj(b,a)});(a instanceof pf||a instanceof ig&&null!==a.placeholder)&&a.s();null!==a.data&&Bj(a,function(a){Dj(b.partManager,a)});!0!==pj(a)&&!0!==qj(a)||this.Dd.add(a);Ej(a,!0,this);Fj(a)?(a.actualBounds.o()&&this.R(zj(a,a.actualBounds)),this.Xa()):a.isVisible()&&a.actualBounds.o()&&this.R(zj(a,a.actualBounds));this.cc()};
t.xc=function(a){a.Uj();this.partManager.xc(a);var b=this;null!==a.data&&Bj(a,function(a){Gj(b.partManager,a,b)});this.Dd.remove(a);Fj(a)?(a.actualBounds.o()&&this.R(zj(a,a.actualBounds)),this.Xa()):a.isVisible()&&a.actualBounds.o()&&this.R(zj(a,a.actualBounds));this.cc()};t.remove=function(a){Hj(this,a,!0)};
function Hj(a,b,c){var d=b.layer;null!==d&&d.diagram===a&&(b.isSelected=!1,b.isHighlighted=!1,b.B(2),c&&b.$j(),c=d.xc(-1,b,!1),0<=c&&a.ab(df,"parts",d,b,null,c,null),a=b.layerChanged,null!==a&&a(b,d,null))}t.qt=function(a,b){if(Ea(a))for(var c=a.length,d=0;d<c;d++){var e=a[d];b&&!e.canDelete()||this.remove(e)}else for(c=new F,c.addAll(a),a=c.iterator;a.next();)c=a.value,b&&!c.canDelete()||this.remove(c)};t.Zj=function(a,b,c){return this.partManager.Zj(a,b,c)};
P.prototype.moveParts=function(a,b,c,d){void 0===d&&(d=Ij(this));if(null!==this.toolManager){var e=new Lb;if(null!==a)if(Ea(a))for(var f=0;f<a.length;f++)Jj(this,e,a[f],c,d);else for(a=a.iterator;a.next();)Jj(this,e,a.value,c,d);else{for(a=this.parts;a.next();)Jj(this,e,a.value,c,d);for(a=this.nodes;a.next();)Jj(this,e,a.value,c,d);for(a=this.links;a.next();)Jj(this,e,a.value,c,d)}bg(this,e,b,d,c)}};
function Jj(a,b,c,d,e){void 0===e&&(e=Ij(a));if(!b.contains(c)&&(!d||c.canMove()||c.canCopy()))if(c instanceof V){b.add(c,a.pd(e,c,c.location));if(c instanceof ig)for(var f=c.memberParts;f.next();)Jj(a,b,f.value,d,e);for(f=c.linksConnected;f.next();){var g=f.value;if(!b.contains(g)){var h=g.fromNode,k=g.toNode;null!==h&&b.contains(h)&&null!==k&&b.contains(k)&&Jj(a,b,g,d,e)}}if(e.dragsTree)for(c=c.$u();c.next();)Jj(a,b,c.value,d,e)}else if(c instanceof S)for(b.add(c,a.pd(e,c)),c=c.labelNodes;c.next();)Jj(a,
b,c.value,d,e);else c instanceof pf||b.add(c,a.pd(e,c,c.location))}
function bg(a,b,c,d,e){if(null!==b&&0!==b.count){var f=H.alloc(),g=H.alloc();g.assign(c);isNaN(g.x)&&(g.x=0);isNaN(g.y)&&(g.y=0);(c=a.xp)||Lf(a,b);for(var h=Ka(),k=Ka(),l=b.iterator,m=H.alloc();l.next();){var n=l.key,p=l.value;if(n.bc()){var q=Kj(a,n,b);if(null!==q)h.push(new Lj(n,p,q));else if(!e||n.canMove())q=p.point,f.assign(q),a.computeMove(n,f.add(g),d,m),n.location=m,void 0===p.shifted&&(p.shifted=new H),p.shifted.assign(m.Xd(q))}else l.key instanceof S&&k.push(l.na)}H.free(m);e=h.length;for(l=
0;l<e;l++)n=h[l],f.assign(n.info.point),void 0===n.ev.shifted&&(n.ev.shifted=new H),n.node.location=f.add(n.ev.shifted);e=H.alloc();l=H.alloc();n=k.length;for(p=0;p<n;p++){var r=k[p];q=r.key;if(q instanceof S)if(q.suspendsRouting){q.bh=null;m=q.fromNode;var u=q.toNode;if(null!==a.draggedLink&&d.dragsLink)if(u=r.value.point,null===q.dragComputation)b.add(q,a.pd(d,q,g)),$f(q,g.x-u.x,g.y-u.y);else{r=H.allocAt(0,0);(m=q.i(0))&&m.o()&&r.assign(m);var x=m=H.alloc().assign(r).add(g);d.isGridSnapEnabled&&
(d.isGridSnapRealtime||a.lastInput.up)&&(x=H.alloc(),fh(a,q,m,x,d));m.assign(q.dragComputation(q,m,x)).Xd(r);b.add(q,a.pd(d,q,m));$f(q,m.x-u.x,m.y-u.y);H.free(r);H.free(m);x!==m&&H.free(x)}else null!==m&&(e.assign(m.location),x=b.J(m),null!==x&&e.Xd(x.point)),null!==u&&(l.assign(u.location),x=b.J(u),null!==x&&l.Xd(x.point)),null!==m&&null!==u?e.Qa(l)?(m=r.value.point,u=f,u.assign(e),u.Xd(m),b.add(q,a.pd(d,q,e)),$f(q,u.x,u.y)):(q.suspendsRouting=!1,q.Ra()):(r=r.value.point,m=null!==m?e:null!==u?l:
g,b.add(q,a.pd(d,q,m)),$f(q,m.x-r.x,m.y-r.y))}else if(null===q.fromNode||null===q.toNode)m=r.value.point,b.add(q,a.pd(d,q,g)),$f(q,g.x-m.x,g.y-m.y)}H.free(f);H.free(g);H.free(e);H.free(l);Oa(h);Oa(k);c||(Qi(a),Qf(a,b))}}
P.prototype.computeMove=function(a,b,c,d){void 0===d&&(d=new H);d.assign(b);if(null===a)return d;var e=b,f=c.isGridSnapEnabled;f&&(c.isGridSnapRealtime||this.lastInput.up)&&(e=H.alloc(),fh(this,a,b,e,c));c=null!==a.dragComputation?a.dragComputation(a,b,e):e;var g=a.minLocation,h=g.x;isNaN(h)&&(h=f?Math.round(a.location.x):a.location.x);g=g.y;isNaN(g)&&(g=f?Math.round(a.location.y):a.location.y);var k=a.maxLocation,l=k.x;isNaN(l)&&(l=f?Math.round(a.location.x):a.location.x);k=k.y;isNaN(k)&&(k=f?Math.round(a.location.y):
a.location.y);d.h(Math.max(h,Math.min(c.x,l)),Math.max(g,Math.min(c.y,k)));e!==b&&H.free(e);return d};function Ij(a){var b=a.toolManager.findTool("Dragging");return null!==b?b.dragOptions:a.Mk}
function fh(a,b,c,d,e){void 0===e&&(e=Ij(a));d.assign(c);if(null!==b){var f=a.grid;b=e.gridSnapCellSize;a=b.width;b=b.height;var g=e.gridSnapOrigin,h=g.x;g=g.y;e=e.gridSnapCellSpot;if(null!==f){var k=f.gridCellSize;isNaN(a)&&(a=k.width);isNaN(b)&&(b=k.height);f=f.gridOrigin;isNaN(h)&&(h=f.x);isNaN(g)&&(g=f.y)}f=H.allocAt(0,0);f.sk(0,0,a,b,e);I.Wp(c.x,c.y,h+f.x,g+f.y,a,b,d);H.free(f)}}function Lf(a,b){if(null!==b)for(a.xp=!0,a=b.iterator;a.next();)b=a.key,b instanceof S&&(b.suspendsRouting=!0)}
function Qf(a,b){if(null!==b){for(b=b.iterator;b.next();){var c=b.key;c instanceof S&&(c.suspendsRouting=!1,Mj(c)&&c.Ra())}a.xp=!1}}function Kj(a,b,c){b=b.containingGroup;if(null!==b){a=Kj(a,b,c);if(null!==a)return a;a=c.J(b);if(null!==a)return a}return null}t=P.prototype;t.pd=function(a,b,c){if(void 0===c)return new Of(ac);var d=a.isGridSnapEnabled;a.gz||null===b.containingGroup||(d=!1);return d?new Of(new H(Math.round(c.x),Math.round(c.y))):new Of(c.copy())};
function Nj(a,b,c){null!==b.diagram&&b.diagram!==a&&A("Cannot share a Layer with another Diagram: "+b+" of "+b.diagram);null===c?null!==b.diagram&&A("Cannot add an existing Layer to this Diagram again: "+b):(c.diagram!==a&&A("Existing Layer must be in this Diagram: "+c+" not in "+c.diagram),b===c&&A("Cannot move a Layer before or after itself: "+b));if(b.diagram!==a){b=b.name;a=a.Pa;c=a.count;for(var d=0;d<c;d++)a.N(d).name===b&&A("Cannot add Layer with the name '"+b+"'; a Layer with the same name is already present in this Diagram.")}}
t.Ql=function(a){Nj(this,a,null);a.hb(this);var b=this.Pa,c=b.count-1;if(!a.isTemporary)for(;0<=c&&b.N(c).isTemporary;)c--;b.Ib(c+1,a);null!==this.Zb&&this.ab(cf,"layers",this,null,a,null,c+1);this.R();this.Xa()};t.Pw=function(a,b){Nj(this,a,b);a.hb(this);var c=this.Pa,d=c.indexOf(a);0<=d&&(c.remove(a),null!==this.Zb&&this.ab(df,"layers",this,a,null,d,null));var e=c.count,f;for(f=0;f<e;f++)if(c.N(f)===b){c.Ib(f,a);break}null!==this.Zb&&this.ab(cf,"layers",this,null,a,null,f);this.R();0>d&&this.Xa()};
t.ay=function(a,b){Nj(this,a,b);a.hb(this);var c=this.Pa,d=c.indexOf(a);0<=d&&(c.remove(a),null!==this.Zb&&this.ab(df,"layers",this,a,null,d,null));var e=c.count,f;for(f=0;f<e;f++)if(c.N(f)===b){c.Ib(f+1,a);break}null!==this.Zb&&this.ab(cf,"layers",this,null,a,null,f+1);this.R();0>d&&this.Xa()};
t.Iz=function(a){a.diagram!==this&&A("Cannot remove a Layer from another Diagram: "+a+" of "+a.diagram);if(""!==a.name){var b=this.Pa,c=b.indexOf(a);if(b.remove(a)){for(b=a.Fa.copy().iterator;b.next();){var d=b.value,e=d.layerName;e!==a.name?d.layerName=e:d.layerName=""}null!==this.Zb&&this.ab(df,"layers",this,a,null,c,null);this.R();this.Xa()}}};t.Zl=function(a){for(var b=this.layers;b.next();){var c=b.value;if(c.name===a)return c}return null};
t.Rw=function(a){null===this.oe&&(this.oe=new E);this.oe.add(a);this.model.rh(a)};t.Kz=function(a){null!==this.oe&&(this.oe.remove(a),0===this.oe.count&&(this.oe=null));this.model.rk(a)};t.rh=function(a){null===this.Af&&(this.Af=new E);this.Af.add(a)};t.rk=function(a){null!==this.Af&&(this.Af.remove(a),0===this.Af.count&&(this.Af=null))};
t.Ks=function(a){this.skipsUndoManager||this.model.skipsUndoManager||this.model.undoManager.fv(a);a.change!==bf&&(this.isModified=!0);if(null!==this.Af)for(var b=this.Af,c=b.length,d=0;d<c;d++)b.N(d)(a)};t.ab=function(a,b,c,d,e,f,g){void 0===f&&(f=null);void 0===g&&(g=null);var h=new $e;h.diagram=this;h.change=a;h.propertyName=b;h.object=c;h.oldValue=d;h.oldParam=f;h.newValue=e;h.newParam=g;this.Ks(h)};t.g=function(a,b,c,d,e){this.ab(af,a,this,b,c,d,e)};
t.Tj=function(a,b){if(null!==a&&a.diagram===this){var c=this.skipsModelSourceBindings;try{this.skipsModelSourceBindings=!0;var d=a.change;if(d===af){var e=a.object;Oj(e,a.propertyName,a.J(b));if(e instanceof Y){var f=e.part;null!==f&&f.Jb()}this.isModified=!0}else if(d===cf){var g=a.object,h=a.newParam,k=a.newValue;if(g instanceof X)if("number"===typeof h&&k instanceof Y){b?g.xc(h):g.Ib(h,k);var l=g.part;null!==l&&l.Jb()}else{if("number"===typeof h&&k instanceof Pj)if(b)k.isRow?g.Av(h):g.yv(h);else{var m=
k.isRow?g.getRowDefinition(k.index):g.getColumnDefinition(k.index);m.Vl(k)}}else if(g instanceof fi){var n=!0===a.oldParam;"number"===typeof h&&k instanceof T&&(b?(k.isSelected=!1,k.isHighlighted=!1,k.Jb(),g.xc(n?h:-1,k,n)):g.Pi(h,k,n))}else g instanceof P?"number"===typeof h&&k instanceof fi&&(b?this.Pa.mb(h):(k.hb(this),this.Pa.Ib(h,k))):A("unknown ChangedEvent.Insert object: "+a.toString());this.isModified=!0}else if(d===df){var p=a.object,q=a.oldParam,r=a.oldValue;if(p instanceof X)"number"===
typeof q&&r instanceof Y?b?p.Ib(q,r):p.xc(q):"number"===typeof q&&r instanceof Pj&&(b?(m=r.isRow?p.getRowDefinition(r.index):p.getColumnDefinition(r.index),m.Vl(r)):r.isRow?p.Av(q):p.yv(q));else if(p instanceof fi){var u=!0===a.newParam;"number"===typeof q&&r instanceof T&&(b?0>p.Fa.indexOf(r)&&p.Pi(q,r,u):(r.isSelected=!1,r.isHighlighted=!1,r.Jb(),p.xc(u?q:-1,r,u)))}else p instanceof P?"number"===typeof q&&r instanceof fi&&(b?(r.hb(this),this.Pa.Ib(q,r)):this.Pa.mb(q)):A("unknown ChangedEvent.Remove object: "+
a.toString());this.isModified=!0}else d!==bf&&A("unknown ChangedEvent: "+a.toString())}finally{this.skipsModelSourceBindings=c}}};t.Aa=function(a){return this.undoManager.Aa(a)};t.Za=function(a){return this.undoManager.Za(a)};t.vf=function(){return this.undoManager.vf()};
P.prototype.commit=function(a,b){void 0===b&&(b="");var c=this.skipsUndoManager;null===b&&(this.skipsUndoManager=!0,b="");this.undoManager.Aa(b);var d=!1;try{a(this),d=!0}finally{d?this.undoManager.Za(b):this.undoManager.vf(),this.skipsUndoManager=c}};P.prototype.updateAllTargetBindings=function(a){this.partManager.updateAllTargetBindings(a)};t=P.prototype;t.wq=function(){this.partManager.wq()};
function Qj(a,b,c){var d=a.animationManager;if(a.ob||a.Yb)a.Ca=c,ei(d,b,a.Ca);else if(a.ob=!0,null===a.Da)a.Ca=c;else{var e=a.viewportBounds.copy(),f=a.xa,g=a.wa;e.width=a.xa/b;e.height=a.wa/b;var h=a.zoomPoint.x,k=a.zoomPoint.y,l=a.contentAlignment;isNaN(h)&&(l.rf()?l.qf(jd)?h=0:l.qf(pd)&&(h=f-1):h=l.gb()?l.x*(f-1):f/2);isNaN(k)&&(l.rf()?l.qf(id)?k=0:l.qf(qd)&&(k=g-1):k=l.gb()?l.y*(g-1):g/2);null!==a.scaleComputation&&(c=a.scaleComputation(a,c));c<a.minScale&&(c=a.minScale);c>a.maxScale&&(c=a.maxScale);
f=H.allocAt(a.sa.x+h/b-h/c,a.sa.y+k/b-k/c);a.position=f;H.free(f);a.Ca=c;a.lq(e,a.viewportBounds,b,!1);a.ob=!1;Si(a,!1);ei(d,b,a.Ca);a.R();hj(a)}}
t.lq=function(a,b,c,d){if(!a.A(b)){void 0===d&&(d=!1);d||hj(this);Pi(this);var e=this.layout;null===e||!e.isViewportSized||this.autoScale!==pi||d||a.width===b.width&&a.height===b.height||e.B();e=this.currentTool;!0===this.Qe&&e instanceof Ua&&(this.lastInput.documentPoint=this.xt(this.lastInput.viewPoint),tf(e,this));this.ob||this.lx(a,b);kj(this);this.xe.scale=c;this.xe.position.x=a.x;this.xe.position.y=a.y;this.xe.bounds.assign(a);this.xe.nx=d;this.aa("ViewportBoundsChanged",this.xe,a);this.isVirtualized&&
this.links.each(function(a){a.isAvoiding&&a.actualBounds.Ic(b)&&a.Ra()})}};
function kj(a,b){void 0===b&&(b=null);var c=a.Eb;if(null!==c&&c.visible){for(var d=L.alloc(),e=1,f=1,g=c.Y.j,h=g.length,k=0;k<h;k++){var l=g[k],m=l.interval;2>m||(Rj(l.figure)?f=f*m/I.ix(f,m):e=e*m/I.ix(e,m))}g=c.gridCellSize;d.h(f*g.width,e*g.height);if(null!==b)e=b.width,f=b.height,a=b.x,g=b.y;else{b=N.alloc();a=a.viewportBounds;b.h(a.x,a.y,a.width,a.height);if(!b.o()){N.free(b);return}e=b.width;f=b.height;a=b.x;g=b.y;N.free(b)}c.width=e+2*d.width;c.height=f+2*d.height;b=H.alloc();I.Wp(a,g,0,0,
d.width,d.height,b);b.offset(-d.width,-d.height);L.free(d);c.part.location=b;H.free(b)}}t.Qp=function(){var a=0<this.selection.count;a&&this.aa("ChangingSelection",this.selection);Hf(this);a&&this.aa("ChangedSelection",this.selection)};function Hf(a){a=a.selection;if(0<a.count){for(var b=a.Na(),c=b.length,d=0;d<c;d++)b[d].isSelected=!1;a.ha();a.clear();a.freeze()}}
t.select=function(a){null!==a&&a.layer.diagram===this&&(!a.isSelected||1<this.selection.count)&&(this.aa("ChangingSelection",this.selection),Hf(this),a.isSelected=!0,this.aa("ChangedSelection",this.selection))};
t.Gv=function(a){this.aa("ChangingSelection",this.selection);Hf(this);if(Ea(a))for(var b=a.length,c=0;c<b;c++){var d=a[c];d instanceof T||A("Diagram.selectCollection given something that is not a Part: "+d);d.isSelected=!0}else for(a=a.iterator;a.next();)b=a.value,b instanceof T||A("Diagram.selectCollection given something that is not a Part: "+b),b.isSelected=!0;this.aa("ChangedSelection",this.selection)};
t.Ku=function(){var a=this.highlighteds;if(0<a.count){for(var b=a.Na(),c=b.length,d=0;d<c;d++)b[d].isHighlighted=!1;a.ha();a.clear();a.freeze()}};t.iz=function(a){null!==a&&a.layer.diagram===this&&(!a.isHighlighted||1<this.highlighteds.count)&&(this.Ku(),a.isHighlighted=!0)};
t.jz=function(a){a=(new F).addAll(a);for(var b=this.highlighteds.copy().oq(a).iterator;b.next();)b.value.isHighlighted=!1;for(a=a.iterator;a.next();)b=a.value,b instanceof T||A("Diagram.highlightCollection given something that is not a Part: "+b),b.isHighlighted=!0};
t.scroll=function(a,b,c){void 0===c&&(c=1);var d="up"===b||"down"===b,e=0;if("pixel"===a)e=c;else if("line"===a)e=c*(d?this.scrollVerticalLineChange:this.scrollHorizontalLineChange);else if("page"===a)a=d?this.viewportBounds.height:this.viewportBounds.width,a*=this.scale,0!==a&&(e=c*Math.max(a-(d?this.scrollVerticalLineChange:this.scrollHorizontalLineChange),0));else{if("document"===a){e=this.documentBounds;c=this.viewportBounds;d=H.alloc();"up"===b?this.position=d.h(c.x,e.y):"left"===b?this.position=
d.h(e.x,c.y):"down"===b?this.position=d.h(c.x,e.bottom-c.height):"right"===b&&(this.position=d.h(e.right-c.width,c.y));H.free(d);return}A("scrolling unit must be 'pixel', 'line', 'page', or 'document', not: "+a)}e/=this.scale;c=this.position.copy();"up"===b?c.y=this.position.y-e:"down"===b?c.y=this.position.y+e:"left"===b?c.x=this.position.x-e:"right"===b?c.x=this.position.x+e:A("scrolling direction must be 'up', 'down', 'left', or 'right', not: "+b);this.position=c};
t.Fv=function(a){var b=this.viewportBounds;b.mf(a)||(a=a.center,a.x-=b.width/2,a.y-=b.height/2,this.position=a)};t.Iu=function(a){var b=this.viewportBounds;a=a.center;a.x-=b.width/2;a.y-=b.height/2;this.position=a};t.wt=function(a){var b=this.tb;b.reset();1!==this.Ca&&b.scale(this.Ca);var c=this.sa;(0!==c.x||0!==c.y)&&isFinite(c.x)&&isFinite(c.y)&&b.translate(-c.x,-c.y);return a.copy().transform(this.tb)};
t.Vz=function(a){var b=this.tb,c=a.x,d=a.y,e=c+a.width,f=d+a.height,g=b.m11,h=b.m12,k=b.m21,l=b.m22,m=b.dx,n=b.dy,p=c*g+d*k+m;b=c*h+d*l+n;var q=e*g+d*k+m;a=e*h+d*l+n;d=c*g+f*k+m;c=c*h+f*l+n;g=e*g+f*k+m;e=e*h+f*l+n;f=Math.min(p,q);p=Math.max(p,q);q=Math.min(b,a);b=Math.max(b,a);f=Math.min(f,d);p=Math.max(p,d);q=Math.min(q,c);b=Math.max(b,c);f=Math.min(f,g);p=Math.max(p,g);q=Math.min(q,e);b=Math.max(b,e);return new N(f,q,p-f,b-q)};
t.xt=function(a){var b=this.tb;b.reset();1!==this.Ca&&b.scale(this.Ca);var c=this.sa;(0!==c.x||0!==c.y)&&isFinite(c.x)&&isFinite(c.y)&&b.translate(-c.x,-c.y);return Qb(a.copy(),this.tb)};function Sj(a){var b=a.isModified;a.Mw!==b&&(a.Mw=b,a.aa("Modified"))}function Tj(a){a=si.get(a);return null!==a?new a:new ti}
P.prototype.doModelChanged=function(a){if(a.model===this.model){var b=a.change,c=a.propertyName;if(b===bf&&"S"===c[0])if("StartingFirstTransaction"===c){var d=this;a=this.toolManager;a.mouseDownTools.each(function(a){a.hb(d)});a.mouseMoveTools.each(function(a){a.hb(d)});a.mouseUpTools.each(function(a){a.hb(d)});this.Yb||this.ge||(this.Sk=!0,this.Xk&&(this.qd=!0))}else"StartingUndo"===c||"StartingRedo"===c?(a=this.animationManager,a.isAnimating&&!this.skipsUndoManager&&a.vd(),this.aa("ChangingSelection",
this.selection)):"StartedTransaction"===c&&(a=this.animationManager,a.isAnimating&&!this.skipsUndoManager&&a.vd());else if(this.ea){this.ea=!1;try{if(""===a.modelChange&&b===bf){if("FinishedUndo"===c||"FinishedRedo"===c)this.aa("ChangedSelection",this.selection),Qi(this);var e=this.animationManager;"RolledBackTransaction"===c&&e.vd();this.Sk=!0;this.fd();0===this.undoManager.transactionLevel&&Sh(e);"CommittedTransaction"===c&&this.undoManager.cu&&(this.fe=Math.min(this.fe,this.undoManager.historyIndex-
1));var f=a.isTransactionFinished;f&&(Sj(this),this.ft.clear());if(!this.ju&&f){this.ju=!0;var g=this;ua(function(){g.currentTool.standardMouseOver();g.ju=!1},10)}}}finally{this.ea=!0}}}};function Cj(a,b){b=b.Y.j;for(var c=b.length,d=0;d<c;d++)Uj(a,b[d])}
function Uj(a,b){if(b instanceof Yj){var c=b.element;if(null!==c&&c instanceof HTMLImageElement){var d=b.Kg;null!==d&&(d.Zk instanceof Event&&null!==b.Cc&&b.Cc(b,d.Zk),!0===d.Dr&&(null!==b.ef&&b.ef(b,d.ru),null!==b.diagram&&b.diagram.lu.add(b)));c=c.src;d=a.Ej.J(c);if(null===d)d=[],d.push(b),a.Ej.add(c,d);else{for(a=0;a<d.length;a++)if(d[a]===b)return;d.push(b)}}}}
function ck(a,b){if(b instanceof Yj){var c=b.element;if(null!==c&&c instanceof HTMLImageElement){c=c.src;var d=a.Ej.J(c);if(null!==d)for(var e=0;e<d.length;e++)if(d[e]===b){d.splice(e,1);0===d.length&&(a.Ej.remove(c),dk(c));break}}}}P.prototype.ud=function(){this.partManager.ud()};P.prototype.Lp=function(a,b){this.Ak.Lp(a,b)};P.prototype.Mp=function(a,b){this.Ak.Mp(a,b)};P.prototype.findPartForKey=function(a){return this.partManager.findPartForKey(a)};t=P.prototype;t.Hb=function(a){return this.partManager.Hb(a)};
t.vc=function(a){return this.partManager.vc(a)};t.Gi=function(a){return this.partManager.Gi(a)};t.uc=function(a){return this.partManager.uc(a)};t.Rs=function(a){for(var b=[],c=0;c<arguments.length;++c)b[c]=arguments[c];return this.partManager.Rs.apply(this.partManager,b instanceof Array?b:ca(ba(b)))};t.Qs=function(a){for(var b=[],c=0;c<arguments.length;++c)b[c]=arguments[c];return this.partManager.Qs.apply(this.partManager,b instanceof Array?b:ca(ba(b)))};
function Vi(a,b){a.Qg=!1;var c=a.un;c.A(b)||(b=b.I(),a.un=b,Si(a,!1),a.aa("DocumentBoundsChanged",null,c.copy()),hj(a))}t.Qy=function(){for(var a=new F,b=this.nodes;b.next();){var c=b.value;c.isTopLevel&&a.add(c)}for(b=this.links;b.next();)c=b.value,c.isTopLevel&&a.add(c);return a.iterator};t.Py=function(){return this.zi.iterator};t.uz=function(a){Qi(this);a&&ek(this,!0);this.Sk=!0;pg(this)};
function ek(a,b){for(var c=a.zi.iterator;c.next();)fk(a,c.value,b);null!==a.layout&&(b?a.layout.isValidLayout=!1:a.layout.B())}function fk(a,b,c){if(null!==b){for(var d=b.rl.iterator;d.next();)fk(a,d.value,c);null!==b.layout&&(c?b.layout.isValidLayout=!1:b.layout.B())}}
function lj(a,b){if(a.Bg&&!a.Pt){var c=a.ea;a.ea=!0;var d=a.undoManager.transactionLevel,e=a.layout;try{0===d&&a.Aa("Layout");var f=a.animationManager;1>=d&&!f.isAnimating&&!f.rb&&(b||f.Ti("Layout"));a.Bg=!1;for(var g=a.zi.iterator;g.next();)gk(a,g.value,b,d);e.isValidLayout||(!b||e.isRealtime||null===e.isRealtime||0===d?(e.doLayout(a),Qi(a),e.isValidLayout=!0):a.Bg=!0)}finally{0===d&&a.Za("Layout"),a.Bg=!e.isValidLayout,a.ea=c}}}
function gk(a,b,c,d){if(null!==b){for(var e=b.rl.iterator;e.next();)gk(a,e.value,c,d);e=b.layout;null===e||e.isValidLayout||(!c||e.isRealtime||0===d?(b.pk=!b.location.o(),e.doLayout(b),b.B(32),nj(a,b),e.isValidLayout=!0):a.Bg=!0)}}t.Wy=function(){for(var a=new E,b=this.nodes;b.next();){var c=b.value;c.isTopLevel&&null===c.Ji()&&a.add(c)}return a.iterator};
function qi(a){function b(a){var b=a.toLowerCase(),e=new E;c.add(a,e);c.add(b,e);d.add(a,a);d.add(b,a)}var c=new Lb,d=new Lb;b("AnimationStarting");b("AnimationFinished");b("BackgroundSingleClicked");b("BackgroundDoubleClicked");b("BackgroundContextClicked");b("ClipboardChanged");b("ClipboardPasted");b("DocumentBoundsChanged");b("ExternalObjectsDropped");b("GainedFocus");b("InitialLayoutCompleted");b("LayoutCompleted");b("LinkDrawn");b("LinkRelinked");b("LinkReshaped");b("LostFocus");b("Modified");
b("ObjectSingleClicked");b("ObjectDoubleClicked");b("ObjectContextClicked");b("PartCreated");b("PartResized");b("PartRotated");b("SelectionMoved");b("SelectionCopied");b("SelectionDeleting");b("SelectionDeleted");b("SelectionGrouped");b("SelectionUngrouped");b("ChangingSelection");b("ChangedSelection");b("SubGraphCollapsed");b("SubGraphExpanded");b("TextEdited");b("TreeCollapsed");b("TreeExpanded");b("ViewportBoundsChanged");b("InvalidateDraw");a.Rt=c;a.Qt=d}
function wj(a,b){var c=a.Qt.J(b);return null!==c?c:a.Qt.J(b.toLowerCase())}function hk(a,b){var c=a.Rt.J(b);if(null!==c)return c;c=a.Rt.J(b.toLowerCase());if(null!==c)return c;A("Unknown DiagramEvent name: "+b);return null}t.Pj=function(a,b){a=hk(this,a);null!==a&&a.add(b)};t.mm=function(a,b){a=hk(this,a);null!==a&&a.remove(b)};
t.aa=function(a,b,c){var d=hk(this,a),e=new Ze;e.diagram=this;a=wj(this,a);null!==a&&(e.name=a);void 0!==b&&(e.subject=b);void 0!==c&&(e.parameter=c);b=d.length;if(1===b)d.N(0)(e);else if(0!==b)for(d=d.Na(),c=0;c<b;c++)(0,d[c])(e)};function ik(a){if(a.animationManager.isAnimating)return!1;var b=a.currentTool;return b===a.toolManager.findTool("Dragging")?!a.xp||b.isComplexRoutingRealtime:!0}t.gk=function(a,b){void 0===b&&(b=null);return jk(this,!1,null,b).gk(a.x,a.y,a.width,a.height)};
P.prototype.computeOccupiedArea=function(){return this.isVirtualized?this.viewportBounds.copy():this.Qg?Ri(this):this.documentBounds.copy()};
function jk(a,b,c,d){null===a.Nb&&(a.Nb=new kk);if(a.Nb.$s||a.Nb.group!==c||a.Nb.Ix!==d){if(null===c){b=a.computeOccupiedArea();b.Uc(100,100);a.Nb.initialize(b);b=N.alloc();for(var e=a.nodes;e.next();){var f=e.value,g=f.layer;null!==g&&g.visible&&!g.isTemporary&&lk(a,f,d,b)}N.free(b)}else{0<c.memberParts.count&&(b=a.computePartsBounds(c.memberParts,!1),b.Uc(20,20),a.Nb.initialize(b));b=N.alloc();for(e=c.memberParts;e.next();)f=e.value,f instanceof V&&lk(a,f,d,b);N.free(b)}a.Nb.group=c;a.Nb.Ix=d;a.Nb.$s=
!1}else b&&mk(a.Nb);return a.Nb}function lk(a,b,c,d){if(b!==c)if(b.isVisible()&&b.avoidable&&!b.isLinkLabel){var e=b.getAvoidableRect(d),f=a.Nb.Ul;c=a.Nb.Tl;d=e.x+e.width;b=e.y+e.height;for(var g=e.x;g<d;g+=f){for(var h=e.y;h<b;h+=c)nk(a.Nb,g,h);nk(a.Nb,g,b)}for(e=e.y;e<b;e+=c)nk(a.Nb,d,e);nk(a.Nb,d,b)}else if(b instanceof ig)for(b=b.memberParts;b.next();)e=b.value,e instanceof V&&lk(a,e,c,d)}
function ok(a,b){null!==a.Nb&&!a.Nb.$s&&(void 0===b&&(b=null),null===b||b.avoidable&&!b.isLinkLabel)&&(a.Nb.$s=!0)}t=P.prototype;t.Ns=function(a){this.Mq.assign(a);pk(this,this.Mq).Qa(this.position)?this.wf():qk(this)};
function qk(a){-1===a.Ck&&(a.Ck=ua(function(){if(-1!==a.Ck&&(a.wf(),null!==a.lastInput.event)){var b=pk(a,a.Mq);b.Qa(a.position)||(a.position=b,a.lastInput.documentPoint=a.xt(a.Mq),a.doMouseMove(),a.Qg=!0,Vi(a,a.documentBounds.copy().Jc(a.computeBounds())),a.nc=!0,a.fd(),qk(a))}},a.Sm))}t.wf=function(){-1!==this.Ck&&(w.clearTimeout(this.Ck),this.Ck=-1)};
function pk(a,b){var c=a.position,d=a.Tm;if(0>=d.top&&0>=d.left&&0>=d.right&&0>=d.bottom)return c;var e=a.viewportBounds,f=a.scale;e=N.allocAt(0,0,e.width*f,e.height*f);var g=H.allocAt(0,0);if(b.x>=e.x&&b.x<e.x+d.left){var h=Math.max(a.scrollHorizontalLineChange,1);h|=0;g.x-=h;b.x<e.x+d.left/2&&(g.x-=h);b.x<e.x+d.left/4&&(g.x-=4*h)}else b.x<=e.x+e.width&&b.x>e.x+e.width-d.right&&(h=Math.max(a.scrollHorizontalLineChange,1),h|=0,g.x+=h,b.x>e.x+e.width-d.right/2&&(g.x+=h),b.x>e.x+e.width-d.right/4&&
(g.x+=4*h));b.y>=e.y&&b.y<e.y+d.top?(a=Math.max(a.scrollVerticalLineChange,1),a|=0,g.y-=a,b.y<e.y+d.top/2&&(g.y-=a),b.y<e.y+d.top/4&&(g.y-=4*a)):b.y<=e.y+e.height&&b.y>e.y+e.height-d.bottom&&(a=Math.max(a.scrollVerticalLineChange,1),a|=0,g.y+=a,b.y>e.y+e.height-d.bottom/2&&(g.y+=a),b.y>e.y+e.height-d.bottom/4&&(g.y+=4*a));g.Qa(ac)||(c=new H(c.x+g.x/f,c.y+g.y/f));N.free(e);H.free(g);return c}t.ht=function(){return null};t.mv=function(){return null};t.hy=function(a,b){this.ou.add(a,b)};
function rk(a,b,c){function d(){var a=+new Date;f=!0;for(g.reset();g.next();)if(!g.value[0].nl){f=!1;break}f||a-l>k?b(c,e,h):w.requestAnimationFrame(d)}for(var e=c.callback,f=!0,g=a.Ej.iterator;g.next();)if(!g.value[0].nl){f=!1;break}if("function"!==typeof e||f)return b(c,e,a);var h=a,k=c.callbackTimeout||300,l=+new Date;w.requestAnimationFrame(function(){d()});return null}t.wz=function(a){if(!th)return null;void 0===a&&(a=new tb);a.returnType="Image";return this.ox(a)};
t.ox=function(a){void 0===a&&(a=new tb);return rk(this,this.xz,a)};
t.xz=function(a,b,c){var d=sk(c,a,"canvas",null);if(null===d)return null;c=d.W.canvas;var e=null;if(null!==c)switch(e=a.returnType,void 0===e?e="string":e=e.toLowerCase(),e){case "imagedata":e=d.getImageData(0,0,c.width,c.height);break;case "image":d=(a.document||document).createElement("img");d.src=c.toDataURL(a.type,a.details);e=d;break;case "blob":"function"!==typeof b&&A('Error: Diagram.makeImageData called with "returnType: toBlob", but no required "callback" function property defined.');if("function"===
typeof c.toBlob)return c.toBlob(b,a.type,a.details),"toBlob";if("function"===typeof c.msToBlob)return b(c.msToBlob()),"msToBlob";b(null);return null;default:e=c.toDataURL(a.type,a.details)}return"function"===typeof b?(b(e),null):e};
function sk(a,b,c,d){a.animationManager.vd();a.fd();if(null===a.Da)return null;"object"!==typeof b&&A("properties argument must be an Object.");var e=b.size||null,f=b.scale||null;void 0!==b.scale&&isNaN(b.scale)&&(f="NaN");var g=b.maxSize;void 0===b.maxSize&&(g="SVG"===c?new L(Infinity,Infinity):new L(2E3,2E3));var h=b.position||null,k=b.parts||null,l=void 0===b.padding?1:b.padding,m=b.background||null,n=b.omitTemporary;void 0===n&&(n=!0);var p=b.document||document,q=b.elementFinished||null,r=b.showTemporary;
void 0===r&&(r=!n);b=b.showGrid;void 0===b&&(b=r);null!==e&&isNaN(e.width)&&isNaN(e.height)&&(e=null);"number"===typeof l?l=new Ic(l):l instanceof Ic||A("MakeImage padding must be a Margin or a number.");l.left=Math.max(l.left,0);l.right=Math.max(l.right,0);l.top=Math.max(l.top,0);l.bottom=Math.max(l.bottom,0);a.Yc.Rc(!0);n=new tk(null,p);var u=n.context;if(!(e||f||k||h)){n.width=a.xa+Math.ceil(l.left+l.right);n.height=a.wa+Math.ceil(l.top+l.bottom);if("SVG"===c){if(null===d)return null;d.resize(n.width,
n.height,n.width,n.height);d.ownerDocument=p;d.Tp=q;uj(a,d.context,l,new L(n.width,n.height),a.Ca,a.sa,k,m,r,b);return d.context}a.wn=!1;uj(a,u,l,new L(n.width,n.height),a.Ca,a.sa,k,m,r,b);a.wn=!0;return n.context}var x=a.dr,v=a.documentBounds.copy();v.Ov(a.eb);if(r)for(var y=a.Pa.j,z=y.length,B=0;B<z;B++){var C=y[B];if(C.visible&&C.isTemporary){C=C.Fa.j;for(var G=C.length,K=0;K<G;K++){var M=C[K];M.isInDocumentBounds&&M.isVisible()&&(M=M.actualBounds,M.o()&&v.Jc(M))}}}y=new H(v.x,v.y);if(null!==k){z=
!0;B=k.iterator;for(B.reset();B.next();)if(C=B.value,C instanceof T&&(G=C.layer,(null===G||G.visible)&&(null===G||r||!G.isTemporary)&&C.isVisible()&&(C=C.actualBounds,C.o())))if(z){z=!1;var U=C.copy()}else U.Jc(C);z&&(U=new N(0,0,0,0));v.width=U.width;v.height=U.height;y.x=U.x;y.y=U.y}null!==h&&h.o()&&(y=h,f||(f=x));U=h=0;null!==l&&(h=l.left+l.right,U=l.top+l.bottom);z=B=0;null!==e&&(B=e.width,z=e.height,isFinite(B)&&(B=Math.max(0,B-h)),isFinite(z)&&(z=Math.max(0,z-U)));null!==e&&null!==f?("NaN"===
f&&(f=x),e.o()?(e=B,v=z):isNaN(z)?(e=B,v=v.height*f):(e=v.width*f,v=z)):null!==e?e.o()?(f=Math.min(B/v.width,z/v.height),e=B,v=z):isNaN(z)?(f=B/v.width,e=B,v=v.height*f):(f=z/v.height,e=v.width*f,v=z):null!==f?"NaN"===f&&g.o()?(f=Math.min((g.width-h)/v.width,(g.height-U)/v.height),f>x?(f=x,e=v.width,v=v.height):(e=g.width,v=g.height)):(e=v.width*f,v=v.height*f):(f=x,e=v.width,v=v.height);null!==l?(e+=h,v+=U):l=new Ic(0);null!==g&&(x=g.width,g=g.height,isNaN(x)&&(x=2E3),isNaN(g)&&(g=2E3),isFinite(x)&&
(e=Math.min(e,x)),isFinite(g)&&(v=Math.min(v,g)));n.width=Math.ceil(e);n.height=Math.ceil(v);if("SVG"===c){if(null===d)return null;d.resize(n.width,n.height,n.width,n.height);d.ownerDocument=p;d.Tp=q;uj(a,d.context,l,new L(Math.ceil(e),Math.ceil(v)),f,y,k,m,r,b);return d.context}a.wn=!1;uj(a,u,l,new L(Math.ceil(e),Math.ceil(v)),f,y,k,m,r,b);a.wn=!0;return n.context}
pa.Object.defineProperties(P.prototype,{div:{get:function(){return this.Ia},set:function(a){if(this.Ia!==a){Wa=[];var b=this.Ia;null!==b?(b.C=void 0,b.innerHTML="",null!==this.Da&&(b=this.Da.Ga,this.removeEventListener(b,"touchstart",this.Tv,!1),this.removeEventListener(b,"touchmove",this.Sv,!1),this.removeEventListener(b,"touchend",this.Rv,!1),this.Da.$w()),b=this.toolManager,null!==b&&(b.mouseDownTools.each(function(a){a.cancelWaitAfter()}),b.mouseMoveTools.each(function(a){a.cancelWaitAfter()}),
b.mouseUpTools.each(function(a){a.cancelWaitAfter()})),b.cancelWaitAfter(),this.currentTool.doCancel(),this.Yc=this.Da=null,this.removeEventListener(w,"resize",this.Zv,!1),this.removeEventListener(w,"mousemove",this.kk,!0),this.removeEventListener(w,"mousedown",this.jk,!0),this.removeEventListener(w,"mouseup",this.mk,!0),this.removeEventListener(w,"wheel",this.nk,!0),this.removeEventListener(w,"mouseout",this.lk,!0),lf===this&&(lf=null)):this.ge=!1;this.Ia=null;if(null!==a){if(b=a.C)b.div=null;Ai(this,
a);this.tf()}}}},Tw:{get:function(){return this.vo}},jv:{get:function(){return this.ob},set:function(a){this.ob=a}},Qi:{get:function(){return this.ge}},draggedLink:{get:function(){return this.gr},set:function(a){this.gr!==a&&(this.gr=a,null!==a&&(this.cs=a.fromPort,this.ds=a.toPort))}},sx:{get:function(){return this.cs},set:function(a){this.cs=a}},tx:{
get:function(){return this.ds},set:function(a){this.ds=a}},animationManager:{get:function(){return this.Ak}},undoManager:{get:function(){return this.Zb.undoManager}},skipsUndoManager:{get:function(){return this.eh},set:function(a){this.eh=a;this.Zb.skipsUndoManager=a}},delaysLayout:{get:function(){return this.Pt},set:function(a){this.Pt=a}},validCycle:{
get:function(){return this.Fs},set:function(a){var b=this.Fs;b!==a&&(this.Fs=a,this.g("validCycle",b,a))}},layers:{get:function(){return this.Pa.iterator}},isModelReadOnly:{get:function(){var a=this.Zb;return null===a?!1:a.isReadOnly},set:function(a){var b=this.Zb;null!==b&&(b.isReadOnly=a)}},isReadOnly:{get:function(){return this.Mf},set:function(a){var b=this.Mf;b!==a&&(this.Mf=a,this.g("isReadOnly",
b,a))}},isEnabled:{get:function(){return this.Dc},set:function(a){var b=this.Dc;b!==a&&(this.Dc=a,this.g("isEnabled",b,a))}},allowClipboard:{get:function(){return this.Dq},set:function(a){var b=this.Dq;b!==a&&(this.Dq=a,this.g("allowClipboard",b,a))}},allowCopy:{get:function(){return this.Fh},set:function(a){var b=this.Fh;b!==a&&(this.Fh=a,this.g("allowCopy",b,a))}},allowDelete:{
get:function(){return this.Gh},set:function(a){var b=this.Gh;b!==a&&(this.Gh=a,this.g("allowDelete",b,a))}},allowDragOut:{get:function(){return this.Eq},set:function(a){var b=this.Eq;b!==a&&(this.Eq=a,this.g("allowDragOut",b,a))}},allowDrop:{get:function(){return this.Fq},set:function(a){var b=this.Fq;b!==a&&(this.Fq=a,this.g("allowDrop",b,a))}},allowTextEdit:{get:function(){return this.Ph},set:function(a){var b=
this.Ph;b!==a&&(this.Ph=a,this.g("allowTextEdit",b,a))}},allowGroup:{get:function(){return this.Hh},set:function(a){var b=this.Hh;b!==a&&(this.Hh=a,this.g("allowGroup",b,a))}},allowUngroup:{get:function(){return this.Qh},set:function(a){var b=this.Qh;b!==a&&(this.Qh=a,this.g("allowUngroup",b,a))}},allowInsert:{get:function(){return this.Hq},set:function(a){var b=this.Hq;b!==a&&(this.Hq=a,this.g("allowInsert",
b,a))}},allowLink:{get:function(){return this.Ih},set:function(a){var b=this.Ih;b!==a&&(this.Ih=a,this.g("allowLink",b,a))}},allowRelink:{get:function(){return this.Kh},set:function(a){var b=this.Kh;b!==a&&(this.Kh=a,this.g("allowRelink",b,a))}},allowMove:{get:function(){return this.Jh},set:function(a){var b=this.Jh;b!==a&&(this.Jh=a,this.g("allowMove",b,a))}},allowReshape:{get:function(){return this.Lh},
set:function(a){var b=this.Lh;b!==a&&(this.Lh=a,this.g("allowReshape",b,a))}},allowResize:{get:function(){return this.Mh},set:function(a){var b=this.Mh;b!==a&&(this.Mh=a,this.g("allowResize",b,a))}},allowRotate:{get:function(){return this.Nh},set:function(a){var b=this.Nh;b!==a&&(this.Nh=a,this.g("allowRotate",b,a))}},allowSelect:{get:function(){return this.Oh},set:function(a){var b=this.Oh;b!==a&&(this.Oh=a,
this.g("allowSelect",b,a))}},allowUndo:{get:function(){return this.Iq},set:function(a){var b=this.Iq;b!==a&&(this.Iq=a,this.g("allowUndo",b,a))}},allowZoom:{get:function(){return this.Kq},set:function(a){var b=this.Kq;b!==a&&(this.Kq=a,this.g("allowZoom",b,a))}},hasVerticalScrollbar:{get:function(){return this.wr},set:function(a){var b=this.wr;b!==a&&(this.wr=a,hj(this),this.R(),this.g("hasVerticalScrollbar",
b,a),Si(this,!1))}},hasHorizontalScrollbar:{get:function(){return this.vr},set:function(a){var b=this.vr;b!==a&&(this.vr=a,hj(this),this.R(),this.g("hasHorizontalScrollbar",b,a),Si(this,!1))}},allowHorizontalScroll:{get:function(){return this.Gq},set:function(a){var b=this.Gq;b!==a&&(this.Gq=a,this.g("allowHorizontalScroll",b,a),Si(this,!1))}},allowVerticalScroll:{get:function(){return this.Jq},set:function(a){var b=
this.Jq;b!==a&&(this.Jq=a,this.g("allowVerticalScroll",b,a),Si(this,!1))}},scrollHorizontalLineChange:{get:function(){return this.ps},set:function(a){var b=this.ps;b!==a&&(0>a&&ya(a,">= 0",P,"scrollHorizontalLineChange"),this.ps=a,this.g("scrollHorizontalLineChange",b,a))}},scrollVerticalLineChange:{get:function(){return this.rs},set:function(a){var b=this.rs;b!==a&&(0>a&&ya(a,">= 0",P,"scrollVerticalLineChange"),this.rs=a,this.g("scrollVerticalLineChange",
b,a))}},lastInput:{get:function(){return this.sj},set:function(a){this.sj=a}},firstInput:{get:function(){return this.Yh},set:function(a){this.Yh=a}},currentCursor:{get:function(){return this.Xq},set:function(a){""===a&&(a=this.pn);if(this.Xq!==a){var b=this.Da,c=this.Ia;if(null!==b){this.Xq=a;var d=b.style.cursor;b.style.cursor=a;c.style.cursor=a;b.style.cursor===d&&(b.style.cursor="-webkit-"+a,c.style.cursor=
"-webkit-"+a,b.style.cursor===d&&(b.style.cursor="-moz-"+a,c.style.cursor="-moz-"+a,b.style.cursor===d&&(b.style.cursor=a,c.style.cursor=a)))}}}},defaultCursor:{get:function(){return this.pn},set:function(a){""===a&&(a="auto");var b=this.pn;b!==a&&(this.pn=a,this.g("defaultCursor",b,a))}},click:{get:function(){return this.Bf},set:function(a){var b=this.Bf;b!==a&&(this.Bf=a,this.g("click",b,a))}},doubleClick:{
get:function(){return this.Gf},set:function(a){var b=this.Gf;b!==a&&(this.Gf=a,this.g("doubleClick",b,a))}},contextClick:{get:function(){return this.Cf},set:function(a){var b=this.Cf;b!==a&&(this.Cf=a,this.g("contextClick",b,a))}},mouseOver:{get:function(){return this.Wf},set:function(a){var b=this.Wf;b!==a&&(this.Wf=a,this.g("mouseOver",b,a))}},mouseHover:{get:function(){return this.Uf},set:function(a){var b=
this.Uf;b!==a&&(this.Uf=a,this.g("mouseHover",b,a))}},mouseHold:{get:function(){return this.Tf},set:function(a){var b=this.Tf;b!==a&&(this.Tf=a,this.g("mouseHold",b,a))}},mouseDragOver:{get:function(){return this.Wr},set:function(a){var b=this.Wr;b!==a&&(this.Wr=a,this.g("mouseDragOver",b,a))}},mouseDrop:{get:function(){return this.Rf},set:function(a){var b=this.Rf;b!==a&&(this.Rf=a,this.g("mouseDrop",b,a))}},
handlesDragDropForTopLevelParts:{get:function(){return this.ur},set:function(a){var b=this.ur;b!==a&&(this.ur=a,this.g("handlesDragDropForTopLevelParts",b,a))}},mouseEnter:{get:function(){return this.Sf},set:function(a){var b=this.Sf;b!==a&&(this.Sf=a,this.g("mouseEnter",b,a))}},mouseLeave:{get:function(){return this.Vf},set:function(a){var b=this.Vf;b!==a&&(this.Vf=a,this.g("mouseLeave",b,a))}},toolTip:{
get:function(){return this.hg},set:function(a){var b=this.hg;b!==a&&(this.hg=a,this.g("toolTip",b,a))}},contextMenu:{get:function(){return this.Df},set:function(a){var b=this.Df;b!==a&&(this.Df=a,this.g("contextMenu",b,a))}},commandHandler:{get:function(){return this.K},set:function(a){this.K!==a&&(this.K=a,a.hb(this))}},toolManager:{get:function(){return this.Ub},set:function(a){this.Ub!==a&&(this.Ub=
a,a.hb(this))}},defaultTool:{get:function(){return this.La},set:function(a){var b=this.La;b!==a&&(this.La=a,a.hb(this),this.currentTool===b&&(this.currentTool=a))}},currentTool:{get:function(){return this.ba},set:function(a){var b=this.ba;null!==b&&(b.isActive&&b.doDeactivate(),b.cancelWaitAfter(),b.doStop());null===a&&(a=this.defaultTool);null!==a&&(this.ba=a,a.hb(this),a.doStart())}},selection:{get:function(){return this.Bq}},
maxSelectionCount:{get:function(){return this.Sr},set:function(a){var b=this.Sr;if(b!==a)if(0<=a&&!isNaN(a)){if(this.Sr=a,this.g("maxSelectionCount",b,a),!this.undoManager.isUndoingRedoing&&(a=this.selection.count-a,0<a)){this.aa("ChangingSelection",this.selection);b=this.selection.Na();for(var c=0;c<a;c++)b[c].isSelected=!1;this.aa("ChangedSelection",this.selection)}}else ya(a,">= 0",P,"maxSelectionCount")}},nodeSelectionAdornmentTemplate:{
get:function(){return this.Zr},set:function(a){var b=this.Zr;b!==a&&(this.Zr=a,this.g("nodeSelectionAdornmentTemplate",b,a))}},groupSelectionAdornmentTemplate:{get:function(){return this.sr},set:function(a){var b=this.sr;b!==a&&(this.sr=a,this.g("groupSelectionAdornmentTemplate",b,a))}},linkSelectionAdornmentTemplate:{get:function(){return this.Or},set:function(a){var b=this.Or;b!==a&&(this.Or=a,this.g("linkSelectionAdornmentTemplate",b,
a))}},highlighteds:{get:function(){return this.zq}},isModified:{get:function(){var a=this.undoManager;return a.isEnabled?null!==a.currentTransaction?!0:this.u&&this.fe!==a.historyIndex:this.u},set:function(a){if(this.u!==a){this.u=a;var b=this.undoManager;!a&&b.isEnabled&&(this.fe=b.historyIndex);a||Sj(this)}}},model:{get:function(){return this.Zb},set:function(a){var b=this.Zb;if(b!==a){this.currentTool.doCancel();
null!==b&&b.undoManager!==a.undoManager&&b.undoManager.isInTransaction&&A("Do not replace a Diagram.model while a transaction is in progress.");Di(this,!0);this.ge=!1;this.Xk=!0;this.fe=-2;this.qd=!1;var c=this.Yb;this.Yb=!0;this.animationManager.Ti("Model");null!==b&&(null!==this.oe&&this.oe.each(function(a){b.rk(a)}),b.rk(this.Cm));this.Zb=a;this.partManager=Tj(this.Zb.constructor.type);a.rh(this.xg);this.partManager.addAllModeledParts();a.rk(this.xg);a.rh(this.Cm);null!==this.oe&&this.oe.each(function(b){a.rh(b)});
this.Yb=c;this.ob||this.R();null!==b&&a.undoManager.copyProperties(b.undoManager)}}},ea:{get:function(){return this.Vc},set:function(a){this.Vc=a}},ft:{get:function(){return this.Aq}},skipsModelSourceBindings:{get:function(){return this.Dh},set:function(a){this.Dh=a}},Wi:{get:function(){return this.vu},set:function(a){this.vu=a}},nodeTemplate:{get:function(){return this.Xf.J("")},
set:function(a){var b=this.Xf.J("");b!==a&&(this.Xf.add("",a),this.g("nodeTemplate",b,a),this.undoManager.isUndoingRedoing||this.ud())}},nodeTemplateMap:{get:function(){return this.Xf},set:function(a){var b=this.Xf;b!==a&&(this.Xf=a,this.g("nodeTemplateMap",b,a),this.undoManager.isUndoingRedoing||this.ud())}},groupTemplate:{get:function(){return this.Zh.J("")},set:function(a){var b=this.Zh.J("");b!==a&&(this.Zh.add("",a),this.g("groupTemplate",
b,a),this.undoManager.isUndoingRedoing||this.ud())}},groupTemplateMap:{get:function(){return this.Zh},set:function(a){var b=this.Zh;b!==a&&(this.Zh=a,this.g("groupTemplateMap",b,a),this.undoManager.isUndoingRedoing||this.ud())}},linkTemplate:{get:function(){return this.Ug.J("")},set:function(a){var b=this.Ug.J("");b!==a&&(this.Ug.add("",a),this.g("linkTemplate",b,a),this.undoManager.isUndoingRedoing||this.ud())}},linkTemplateMap:{
get:function(){return this.Ug},set:function(a){var b=this.Ug;b!==a&&(this.Ug=a,this.g("linkTemplateMap",b,a),this.undoManager.isUndoingRedoing||this.ud())}},isMouseOverDiagram:{get:function(){return this.Qe},set:function(a){this.Qe=a}},isMouseCaptured:{get:function(){return this.Kc},set:function(a){var b=this.Da;null!==b&&(b=b.Ga,b instanceof SVGElement||(a?(this.lastInput.bubbles=!1,this.Lq?(this.removeEventListener(b,"pointermove",
this.jm,!1),this.removeEventListener(b,"pointerdown",this.im,!1),this.removeEventListener(b,"pointerup",this.lm,!1),this.removeEventListener(b,"pointerout",this.km,!1),this.addEventListener(w,"pointermove",this.jm,!0),this.addEventListener(w,"pointerdown",this.im,!0),this.addEventListener(w,"pointerup",this.lm,!0),this.addEventListener(w,"pointerout",this.km,!0)):(this.removeEventListener(b,"mousemove",this.kk,!1),this.removeEventListener(b,"mousedown",this.jk,!1),this.removeEventListener(b,"mouseup",
this.mk,!1),this.removeEventListener(b,"mouseout",this.lk,!1),this.addEventListener(w,"mousemove",this.kk,!0),this.addEventListener(w,"mousedown",this.jk,!0),this.addEventListener(w,"mouseup",this.mk,!0),this.addEventListener(w,"mouseout",this.lk,!0)),this.removeEventListener(b,"wheel",this.nk,!1),this.addEventListener(w,"wheel",this.nk,!0),this.addEventListener(w,"selectstart",this.preventDefault,!1)):(this.Lq?(this.removeEventListener(w,"pointermove",this.jm,!0),this.removeEventListener(w,"pointerdown",
this.im,!0),this.removeEventListener(w,"pointerup",this.lm,!0),this.removeEventListener(w,"pointerout",this.km,!0),this.addEventListener(b,"pointermove",this.jm,!1),this.addEventListener(b,"pointerdown",this.im,!1),this.addEventListener(b,"pointerup",this.lm,!1),this.addEventListener(b,"pointerout",this.km,!1)):(this.removeEventListener(w,"mousemove",this.kk,!0),this.removeEventListener(w,"mousedown",this.jk,!0),this.removeEventListener(w,"mouseup",this.mk,!0),this.removeEventListener(w,"mouseout",
this.lk,!0),this.addEventListener(b,"mousemove",this.kk,!1),this.addEventListener(b,"mousedown",this.jk,!1),this.addEventListener(b,"mouseup",this.mk,!1),this.addEventListener(b,"mouseout",this.lk,!1)),this.removeEventListener(w,"wheel",this.nk,!0),this.removeEventListener(w,"selectstart",this.preventDefault,!1),this.addEventListener(b,"wheel",this.nk,!1)),this.Kc=a))}},position:{get:function(){return this.sa},set:function(a){var b=H.alloc().assign(this.sa);if(!b.A(a)){var c=
this.viewportBounds.copy();this.sa.assign(a);this.ob||null===this.Da&&!this.Gp.o()||(this.ob=!0,a=this.scale,Ui(this,this.un,this.xa/a,this.wa/a,this.ej,!1),this.ob=!1);di(this.animationManager,b,this.sa);this.ob||this.lq(c,this.viewportBounds,this.Ca,!1)}H.free(b)}},initialPosition:{get:function(){return this.yr},set:function(a){this.yr.A(a)||(this.yr=a.I())}},initialScale:{get:function(){return this.zr},set:function(a){this.zr!==a&&(this.zr=
a)}},grid:{get:function(){null===this.Eb&&Li(this);return this.Eb},set:function(a){var b=this.Eb;if(b!==a){null===b&&(Li(this),b=this.Eb);a.type!==X.Grid&&A("Diagram.grid must be a Panel of type Panel.Grid");var c=b.panel;null!==c&&c.remove(b);this.Eb=a;a.name="GRID";null!==c&&c.add(a);kj(this);this.R();this.g("grid",b,a)}}},viewportBounds:{get:function(){var a=this.Lw,b=this.sa,c=this.Ca;if(null===this.Da)return this.Gp.o()&&a.h(b.x,b.y,
this.xa/c,this.wa/c),a;a.h(b.x,b.y,Math.max(this.xa,0)/c,Math.max(this.wa,0)/c);return a}},viewSize:{get:function(){return this.Gp},set:function(a){var b=this.viewSize;b.A(a)||(this.Gp=a=a.I(),this.xa=a.width,this.wa=a.height,this.Xa(),this.g("viewSize",b,a))}},fixedBounds:{get:function(){return this.pr},set:function(a){var b=this.pr;b.A(a)||(-Infinity!==a.width&&Infinity!==a.height&&-Infinity!==a.height||A("fixedBounds width/height must not be Infinity"),
this.pr=a=a.I(),this.Xa(),this.g("fixedBounds",b,a))}},scrollMargin:{get:function(){return this.ti},set:function(a){"number"===typeof a&&(a=new Ic(a));var b=this.ti;b.A(a)||(this.ti=a=a.I(),this.g("scrollMargin",b,a),this.tf())}},scrollMode:{get:function(){return this.Ij},set:function(a){var b=this.Ij;b!==a&&(this.Ij=a,a===oi&&Si(this,!1),this.g("scrollMode",b,a),this.tf())}},scrollsPageOnFocus:{get:function(){return this.ss},
set:function(a){var b=this.ss;b!==a&&(this.ss=a,this.g("scrollsPageOnFocus",b,a))}},positionComputation:{get:function(){return this.js},set:function(a){var b=this.js;b!==a&&(this.js=a,Si(this,!1),this.g("positionComputation",b,a))}},scaleComputation:{get:function(){return this.ns},set:function(a){var b=this.ns;b!==a&&(this.ns=a,Qj(this,this.scale,this.scale),this.g("scaleComputation",b,a))}},documentBounds:{
get:function(){return this.un}},isVirtualized:{get:function(){return this.Jr},set:function(a){var b=this.Jr;b!==a&&(this.Jr=a,this.g("isVirtualized",b,a))}},scale:{get:function(){return this.Ca},set:function(a){var b=this.Ca;b!==a&&Qj(this,b,a)}},defaultScale:{get:function(){return this.dr},set:function(a){this.dr=a}},autoScale:{get:function(){return this.cj},set:function(a){var b=
this.cj;b!==a&&(this.cj=a,this.g("autoScale",b,a),a!==pi&&Si(this,!1))}},initialAutoScale:{get:function(){return this.bi},set:function(a){var b=this.bi;b!==a&&(this.bi=a,this.g("initialAutoScale",b,a))}},initialViewportSpot:{get:function(){return this.Ar},set:function(a){var b=this.Ar;b!==a&&(a.gb()||A("initialViewportSpot must be a specific Spot: "+a),this.Ar=a,this.g("initialViewportSpot",b,a))}},initialDocumentSpot:{
get:function(){return this.xr},set:function(a){var b=this.xr;b!==a&&(a.gb()||A("initialViewportSpot must be a specific Spot: "+a),this.xr=a,this.g("initialDocumentSpot",b,a))}},minScale:{get:function(){return this.Tr},set:function(a){var b=this.Tr;b!==a&&(0<a?(this.Tr=a,this.g("minScale",b,a),a>this.scale&&(this.scale=a)):ya(a,"> 0",P,"minScale"))}},maxScale:{get:function(){return this.Rr},set:function(a){var b=this.Rr;b!==a&&(0<a?(this.Rr=
a,this.g("maxScale",b,a),a<this.scale&&(this.scale=a)):ya(a,"> 0",P,"maxScale"))}},zoomPoint:{get:function(){return this.Is},set:function(a){this.Is.A(a)||(this.Is=a=a.I())}},contentAlignment:{get:function(){return this.ej},set:function(a){var b=this.ej;b.A(a)||(this.ej=a=a.I(),this.g("contentAlignment",b,a),Si(this,!1))}},initialContentAlignment:{get:function(){return this.Nn},set:function(a){var b=this.Nn;
b.A(a)||(this.Nn=a=a.I(),this.g("initialContentAlignment",b,a))}},padding:{get:function(){return this.eb},set:function(a){"number"===typeof a&&(a=new Ic(a));var b=this.eb;b.A(a)||(this.eb=a=a.I(),this.Xa(),this.g("padding",b,a))}},partManager:{get:function(){return this.Ma},set:function(a){var b=this.Ma;b!==a&&(null!==a.diagram&&A("Cannot share PartManagers between Diagrams: "+a.toString()),null!==b&&b.hb(null),this.Ma=a,a.hb(this))}},nodes:{
get:function(){return this.partManager.nodes.iterator}},links:{get:function(){return this.partManager.links.iterator}},parts:{get:function(){return this.partManager.parts.iterator}},layout:{get:function(){return this.gc},set:function(a){var b=this.gc;b!==a&&(this.gc=a,a.diagram=this,a.group=null,this.Bg=!0,this.g("layout",b,a),this.cc())}},isTreePathToChildren:{get:function(){return this.Ir},
set:function(a){var b=this.Ir;if(b!==a&&(this.Ir=a,this.g("isTreePathToChildren",b,a),!this.undoManager.isUndoingRedoing))for(a=this.nodes;a.next();)uk(a.value)}},treeCollapsePolicy:{get:function(){return this.Ds},set:function(a){var b=this.Ds;b!==a&&(a!==ri&&a!==vk&&a!==wk&&A("Unknown Diagram.treeCollapsePolicy: "+a),this.Ds=a,this.g("treeCollapsePolicy",b,a))}},Ee:{get:function(){return this.Vb},set:function(a){this.Vb=a}},autoScrollInterval:{
get:function(){return this.Sm},set:function(a){var b=this.Sm;b!==a&&(this.Sm=a,this.g("autoScrollInterval",b,a))}},autoScrollRegion:{get:function(){return this.Tm},set:function(a){"number"===typeof a&&(a=new Ic(a));var b=this.Tm;b.A(a)||(this.Tm=a=a.I(),this.Xa(),this.g("autoScrollRegion",b,a))}}});
pa.Object.defineProperties(P,{licenseKey:{get:function(){return xk.Sb()},set:function(a){xk.add(a)}},version:{get:function(){return yk}}});P.prototype.makeImageData=P.prototype.ox;P.prototype.makeImage=P.prototype.wz;P.prototype.addRenderer=P.prototype.hy;P.prototype.makeSVG=P.prototype.mv;P.prototype.makeSvg=P.prototype.ht;P.prototype.stopAutoScroll=P.prototype.wf;P.prototype.doAutoScroll=P.prototype.Ns;P.prototype.isUnoccupied=P.prototype.gk;
P.prototype.raiseDiagramEvent=P.prototype.aa;P.prototype.removeDiagramListener=P.prototype.mm;P.prototype.addDiagramListener=P.prototype.Pj;P.prototype.findTreeRoots=P.prototype.Wy;P.prototype.layoutDiagram=P.prototype.uz;P.prototype.findTopLevelGroups=P.prototype.Py;P.prototype.findTopLevelNodesAndLinks=P.prototype.Qy;P.prototype.findLinksByExample=P.prototype.Qs;P.prototype.findNodesByExample=P.prototype.Rs;P.prototype.findLinkForData=P.prototype.uc;P.prototype.findNodeForData=P.prototype.Gi;
P.prototype.findPartForData=P.prototype.vc;P.prototype.findNodeForKey=P.prototype.Hb;P.prototype.findPartForKey=P.prototype.findPartForKey;P.prototype.rebuildParts=P.prototype.ud;P.prototype.transformViewToDoc=P.prototype.xt;P.prototype.transformRectDocToView=P.prototype.Vz;P.prototype.transformDocToView=P.prototype.wt;P.prototype.centerRect=P.prototype.Iu;P.prototype.scrollToRect=P.prototype.Fv;P.prototype.scroll=P.prototype.scroll;P.prototype.highlightCollection=P.prototype.jz;
P.prototype.highlight=P.prototype.iz;P.prototype.clearHighlighteds=P.prototype.Ku;P.prototype.selectCollection=P.prototype.Gv;P.prototype.select=P.prototype.select;P.prototype.clearSelection=P.prototype.Qp;P.prototype.updateAllRelationshipsFromData=P.prototype.wq;P.prototype.updateAllTargetBindings=P.prototype.updateAllTargetBindings;P.prototype.commit=P.prototype.commit;P.prototype.rollbackTransaction=P.prototype.vf;P.prototype.commitTransaction=P.prototype.Za;P.prototype.startTransaction=P.prototype.Aa;
P.prototype.raiseChanged=P.prototype.g;P.prototype.raiseChangedEvent=P.prototype.ab;P.prototype.removeChangedListener=P.prototype.rk;P.prototype.addChangedListener=P.prototype.rh;P.prototype.removeModelChangedListener=P.prototype.Kz;P.prototype.addModelChangedListener=P.prototype.Rw;P.prototype.findLayer=P.prototype.Zl;P.prototype.removeLayer=P.prototype.Iz;P.prototype.addLayerAfter=P.prototype.ay;P.prototype.addLayerBefore=P.prototype.Pw;P.prototype.addLayer=P.prototype.Ql;
P.prototype.moveParts=P.prototype.moveParts;P.prototype.copyParts=P.prototype.Zj;P.prototype.removeParts=P.prototype.qt;P.prototype.remove=P.prototype.remove;P.prototype.add=P.prototype.add;P.prototype.clearDelayedGeometries=P.prototype.Ju;P.prototype.setProperties=P.prototype.Mv;P.prototype.resetInputOptions=P.prototype.Cv;P.prototype.setInputOption=P.prototype.Oz;P.prototype.getInputOption=P.prototype.bm;P.prototype.resetRenderingHints=P.prototype.Dv;P.prototype.setRenderingHint=P.prototype.Fx;
P.prototype.getRenderingHint=P.prototype.De;P.prototype.maybeUpdate=P.prototype.fd;P.prototype.requestUpdate=P.prototype.cc;P.prototype.delayInitialization=P.prototype.wy;P.prototype.isUpdateRequested=P.prototype.pz;P.prototype.redraw=P.prototype.tf;P.prototype.invalidateDocumentBounds=P.prototype.Xa;P.prototype.findObjectsNear=P.prototype.og;P.prototype.findPartsNear=P.prototype.My;P.prototype.findObjectsIn=P.prototype.ng;P.prototype.findPartsIn=P.prototype.hx;P.prototype.findObjectsAt=P.prototype.Hi;
P.prototype.findPartsAt=P.prototype.Ly;P.prototype.findObjectAt=P.prototype.Rb;P.prototype.findPartAt=P.prototype.$l;P.prototype.alignDocument=P.prototype.iy;P.prototype.zoomToRect=P.prototype.Yz;P.prototype.zoomToFit=P.prototype.zoomToFit;P.prototype.diagramScroll=P.prototype.Zw;P.prototype.focus=P.prototype.focus;P.prototype.reset=P.prototype.reset;P.useDOM=function(a){th=a?void 0!==w.document:!1};P.isUsingDOM=function(){return th};
var lf=null,si=new Lb,Ji=null,Ii=null,th=void 0!==w.document,Bi=null,Ci="",pi=new D(P,"None",0),Wi=new D(P,"Uniform",1),Xi=new D(P,"UniformToFill",2),tg=new D(P,"CycleAll",10),xg=new D(P,"CycleNotDirected",11),zg=new D(P,"CycleNotDirectedFast",12),Ag=new D(P,"CycleNotUndirected",13),ug=new D(P,"CycleDestinationTree",14),wg=new D(P,"CycleSourceTree",15),oi=new D(P,"DocumentScroll",1),xj=new D(P,"InfiniteScroll",2),ri=new D(P,"TreeParentCollapsed",21),vk=new D(P,"AllParentsCollapsed",22),wk=new D(P,
"AnyParentsCollapsed",23),xk=new E,yk="2.0.12",zk=null,mi=!1;
function ni(){if(th){var a=w.document.createElement("canvas"),b=a.getContext("2d"),c=Za("7ca11abfd022028846");b[c]=Za("398c3597c01238");for(var d=["5da73c80a36455d4038e4972187c3cae51fd22",sa.Dx+"4ae6247590da4bb21c324ba3a84e385776",Qc.xF+"fb236cdfda5de14c134ba1a95a2d4c7cc6f93c1387",I.za],e=1;5>e;e++)b[Za("7ca11abfd7330390")](Za(d[e-1]),10,15*e);b[c]=Za("39f046ebb36e4b");for(c=1;5>c;c++)b[Za("7ca11abfd7330390")](Za(d[c-1]),10,15*c);zk=a}}P.className="Diagram";
P.fromDiv=function(a){var b=a;"string"===typeof a&&(b=w.document.getElementById(a));return b instanceof HTMLDivElement&&b.C instanceof P?b.C:null};P.inherit=function(a,b){function c(){}if(Object.getPrototypeOf(a).prototype)throw Error("Used go.Diagram.inherit defining already defined class \n"+a);c.prototype=b.prototype;a.prototype=new c;a.prototype.constructor=a};P.None=pi;P.Uniform=Wi;P.UniformToFill=Xi;P.CycleAll=tg;P.CycleNotDirected=xg;P.CycleNotDirectedFast=zg;P.CycleNotUndirected=Ag;
P.CycleDestinationTree=ug;P.CycleSourceTree=wg;P.DocumentScroll=oi;P.InfiniteScroll=xj;P.TreeParentCollapsed=ri;P.AllParentsCollapsed=vk;P.AnyParentsCollapsed=wk;function zi(){this.Wx=null;this.l="zz@orderNum";"63ad05bbe23a1786468a4c741b6d2"===this._tk?this.Ne=this.l=!0:this.Ne=null}
function tj(a,b){b.Yc.setTransform(b.Mb,0,0,b.Mb,0,0);if(null===a.Ne){b="f";var c=w[Za("76a715b2f73f148a")][Za("72ba13b5")];a.Ne=!0;if(th){var d=P[Za("76a115b6ed251eaf4692")];if(d)for(var e=xk.iterator;e.next();){d=e.value;d=Za(d).split(Za("39e9"));if(6>d.length)break;var f=Za(d[1]).split(".");if("7da71ca0"!==d[4])break;var g=Za(sa[Za("6cae19")]).split(".");if(f[0]>g[0]||f[0]===g[0]&&f[1]>=g[1]){f=c[Za("76ad18b4f73e")];for(g=c[Za("73a612b6fb191d")](Za("35e7"))+2;g<f;g++)b+=c[g];f=b[Za("73a612b6fb191d")](Za(d[2]));
0>f&&Za(d[2])!==Za("7da71ca0ad381e90")&&(f=b[Za("73a612b6fb191d")](Za("76a715b2ef3e149757")));0>f&&(f=b[Za("73a612b6fb191d")](Za("76a715b2ef3e149757")));a.Ne=!(0<=f&&f<b[Za("73a612b6fb191d")](Za("35")));if(!a.Ne)break;f=Za(d[2]);if("#"!==f[0])break;g=w.document.createElement("div");for(var h=d[0].replace(/[A-Za-z]/g,"");4>h.length;)h+="9";h=h.substr(h.length-4);d="";d+=["gsh","gsf"][parseInt(h.substr(0,1),10)%2];d+=["Header","Background","Display","Feedback"][parseInt(h.substr(0,1),10)%4];g[Za("79a417a0f0181a8946")]=
d;if(w.document[Za("78a712aa")]){w.document[Za("78a712aa")][Za("7bb806b6ed32388c4a875b")](g);h=w.getComputedStyle(g).getPropertyValue(Za("78a704b7e62456904c9b12701b6532a8"));w.document[Za("78a712aa")][Za("68ad1bbcf533388c4a875b")](g);if(!h)break;if(-1!==h.indexOf(parseInt(f[1]+f[2],16))&&-1!==h.indexOf(parseInt(f[3]+f[4],16))){a.Ne=!1;break}else if(bb||cb||db||gb)for(d="."+d,f=0;f<document.styleSheets.length;f++){g=document.styleSheets[f].rules||document.styleSheets[f].cssRules;for(var k in g)if(d===
g[k].selectorText){a.Ne=!1;break}}}else a.Ne=null,a.Ne=!1}}else{k=c[Za("76ad18b4f73e")];for(e=c[Za("73a612b6fb191d")](Za("35e7"))+2;e<k;e++)b+=c[e];c=b[Za("73a612b6fb191d")](Za("7da71ca0ad381e90"));a.Ne=!(0<=c&&c<b[Za("73a612b6fb191d")](Za("35")))}}}return 0<a.Ne&&a!==a.Wx?!0:!1}
function Ai(a,b){if(th){void 0!==b&&null!==b||A("Diagram setup requires an argument DIV.");null!==a.Ia&&A("Diagram has already completed setup.");"string"===typeof b?a.Ia=w.document.getElementById(b):b instanceof HTMLDivElement?a.Ia=b:A("No DIV or DIV id supplied: "+b);null===a.Ia&&A("Invalid DIV id; could not get element with id: "+b);void 0!==a.Ia.C&&A("Invalid div id; div already has a Diagram associated with it.");"static"===w.getComputedStyle(a.Ia,null).position&&(a.Ia.style.position="relative");
a.Ia.style["-webkit-tap-highlight-color"]="rgba(255, 255, 255, 0)";a.Ia.style["-ms-touch-action"]="none";a.Ia.innerHTML="";a.Ia.C=a;var c=a.As?new yj(a):new tk(a);void 0!==c.style&&(c.style.position="absolute",c.style.top="0px",c.style.left="0px","rtl"===w.getComputedStyle(a.Ia,null).getPropertyValue("direction")&&(a.Sn=!0),c.style.zIndex="2",c.style.userSelect="none",c.style.webkitUserSelect="none",c.style.MozUserSelect="none");a.Da=c;a.Yc=c.context;b=a.Yc;a.Mb=a.computePixelRatio();a.xa=a.Ia.clientWidth||
1;a.wa=a.Ia.clientHeight||1;Aj(a,a.xa,a.wa);a.hr=b.W[Za("7eba17a4ca3b1a8346")][Za("78a118b7")](b.W,zk,4,4);a.Ia.insertBefore(c.Ga,a.Ia.firstChild);c=new tk(null);c.width=1;c.height=1;a.Tt=c;a.sw=c.context;if(th){c=va("div");var d=va("div");c.style.position="absolute";c.style.overflow="auto";c.style.width=a.xa+"px";c.style.height=a.wa+"px";c.style.zIndex="1";d.style.position="absolute";d.style.width="1px";d.style.height="1px";a.Ia.appendChild(c);c.appendChild(d);c.onscroll=Mi;c.onmousedown=Oi;c.ontouchstart=
Oi;c.C=a;c.Ux=!0;c.Vx=!0;a.qs=c;a.hp=d}a.nt=ta(function(){a.jh=null;a.R()},300);a.Zv=ta(function(){Th(a)},250);a.preventDefault=function(a){a.preventDefault();return!1};a.kk=function(b){if(a.isEnabled){a.Qe=!0;var c=aj(a,b,!0);a.doMouseMove();a.currentTool.isBeyondDragSize()&&(a.Fd=0);gj(c,b)}};a.jk=function(b){if(a.isEnabled)if(a.Qe=!0,a.Re)b.preventDefault();else{var c=aj(a,b,!0);c.down=!0;c.clickCount=b.detail;if(cb||db)b.timeStamp-a.kl<a.Au&&!a.currentTool.isBeyondDragSize()?a.Fd++:a.Fd=1,a.kl=
b.timeStamp,c.clickCount=a.Fd;c.clone(a.firstInput);a.doMouseDown();1===b.button?b.preventDefault():gj(c,b)}};a.mk=function(b){if(a.isEnabled)if(a.Re&&2===b.button)b.preventDefault();else if(a.Re&&0===b.button&&(a.Re=!1),a.Ml)b.preventDefault();else{a.Qe=!0;var c=aj(a,b,!0);c.up=!0;c.clickCount=b.detail;if(cb||db)c.clickCount=a.Fd;c.bubbles=b.bubbles;c.targetDiagram=cj(b);a.doMouseUp();a.wf();gj(c,b)}};a.nk=function(b){if(a.isEnabled){var c=aj(a,b,!0);c.bubbles=!0;var d=0,e=0;c.delta=0;void 0!==b.deltaX?
(0!==b.deltaX&&(d=0<b.deltaX?1:-1),0!==b.deltaY&&(e=0<b.deltaY?1:-1),c.delta=Math.abs(b.deltaX)>Math.abs(b.deltaY)?-d:-e):void 0!==b.wheelDeltaX?(0!==b.wheelDeltaX&&(d=0<b.wheelDeltaX?-1:1),0!==b.wheelDeltaY&&(e=0<b.wheelDeltaY?-1:1),c.delta=Math.abs(b.wheelDeltaX)>Math.abs(b.wheelDeltaY)?-d:-e):void 0!==b.wheelDelta&&0!==b.wheelDelta&&(c.delta=0<b.wheelDelta?1:-1);a.doMouseWheel();gj(c,b)}};a.lk=function(b){a.isEnabled&&(a.Qe=!1,aj(a,b,!0),b=a.currentTool,b.cancelWaitAfter(),b.standardMouseOver())};
a.Tv=function(b){if(a.isEnabled){a.Ml=!1;a.Re=!0;var c=dj(a,b,b.targetTouches[0],1<b.touches.length),d=null;0<b.targetTouches.length?d=b.targetTouches[0]:0<b.changedTouches.length&&(d=b.changedTouches[0]);if(null!==d){var e=d.screenX;d=d.screenY;var k=a.Lr;b.timeStamp-a.kl<a.Au&&!(25<Math.abs(k.x-e)||25<Math.abs(k.y-d))?a.Fd++:a.Fd=1;c.clickCount=a.Fd;a.kl=b.timeStamp;a.Lr.h(e,d)}a.doMouseDown();gj(c,b)}};a.Sv=function(b){if(a.isEnabled){var c=null;0<b.targetTouches.length?c=b.targetTouches[0]:0<
b.changedTouches.length&&(c=b.changedTouches[0]);c=fj(a,b,c,1<b.touches.length);a.doMouseMove();gj(c,b)}};a.Rv=function(b){if(a.isEnabled)if(a.Ml)b.preventDefault();else if(!(1<b.touches.length)){var c=null,d=null;0<b.targetTouches.length?d=b.targetTouches[0]:0<b.changedTouches.length&&(d=b.changedTouches[0]);var e=ej(a,b,!1,!0,!1,!1);null!==d&&(c=w.document.elementFromPoint(d.clientX,d.clientY),null!==c&&c.C instanceof P&&c.C!==a&&bj(c.C,d,e),bj(a,d,e),e.clickCount=a.Fd);null===c?e.targetDiagram=
cj(b):c.C?e.targetDiagram=c.C:e.targetDiagram=null;e.targetObject=null;a.doMouseUp();gj(e,b);a.Re=!1}};a.im=function(b){if(a.isEnabled){a.Qe=!0;var c=a.mu;void 0===c[b.pointerId]&&(c[b.pointerId]=b);c=a.vl;var d=!1;if(null!==c[0]&&c[0].pointerId===b.pointerId)c[0]=b;else if(null!==c[1]&&c[1].pointerId===b.pointerId)c[1]=b,d=!0;else if(null===c[0])c[0]=b;else if(null===c[1])c[1]=b,d=!0;else{b.preventDefault();return}if("touch"===b.pointerType||"pen"===b.pointerType)a.Ml=!1,a.Re=!0;c=dj(a,b,b,d);d=
a.Lr;var e="touch"===b.pointerType||"pen"===b.pointerType?25:10;b.timeStamp-a.kl<a.Au&&!(Math.abs(d.x-b.screenX)>e||Math.abs(d.y-b.screenY)>e)?a.Fd++:a.Fd=1;c.clickCount=a.Fd;a.kl=b.timeStamp;a.Lr.ug(b.screenX,b.screenY);a.doMouseDown();1===b.button?b.preventDefault():gj(c,b)}};a.jm=function(b){if(a.isEnabled){a.Qe=!0;var c=a.vl;if(null!==c[0]&&c[0].pointerId===b.pointerId)c[0]=b;else{if(null!==c[1]&&c[1].pointerId===b.pointerId){c[1]=b;return}if(null===c[0])c[0]=b;else return}c[0].pointerId===b.pointerId&&
(c=fj(a,b,b,null!==c[1]),a.doMouseMove(),gj(c,b))}};a.lm=function(b){if(a.isEnabled){a.Qe=!0;var c="touch"===b.pointerType||"pen"===b.pointerType,d=a.mu;if(c&&a.Ml)delete d[b.pointerId],b.preventDefault();else if(d=a.vl,null!==d[0]&&d[0].pointerId===b.pointerId){d[0]=null;d=ej(a,b,!1,!0,!0,!1);var e=w.document.elementFromPoint(b.clientX,b.clientY);null!==e&&e.C instanceof P&&e.C!==a&&bj(e.C,b,d);bj(a,b,d);d.clickCount=a.Fd;null===e?d.targetDiagram=cj(b):e.C?d.targetDiagram=e.C:d.targetDiagram=null;
d.targetObject=null;a.doMouseUp();gj(d,b);c&&(a.Re=!1)}else null!==d[1]&&d[1].pointerId===b.pointerId&&(d[1]=null)}};a.km=function(b){if(a.isEnabled){a.Qe=!1;var c=a.mu;c[b.pointerId]&&delete c[b.pointerId];c=a.vl;null!==c[0]&&c[0].pointerId===b.pointerId&&(c[0]=null);null!==c[1]&&c[1].pointerId===b.pointerId&&(c[1]=null);"touch"!==b.pointerType&&"pen"!==b.pointerType&&(b=a.currentTool,b.cancelWaitAfter(),b.standardMouseOver())}};b.Rc(!0);Ki(a)}}zi.className="DiagramHelper";
function Of(a){this.l=void 0===a?new H:a;this.u=new H}pa.Object.defineProperties(Of.prototype,{point:{get:function(){return this.l},set:function(a){this.l=a}},shifted:{get:function(){return this.u},set:function(a){this.u=a}}});Of.className="DraggingInfo";function Lj(a,b,c){this.node=a;this.info=b;this.ev=c}Lj.className="DraggingNodeInfoPair";function yf(){this.reset()}
yf.prototype.reset=function(){this.isGridSnapEnabled=!1;this.isGridSnapRealtime=!0;this.gridSnapCellSize=(new L(NaN,NaN)).freeze();this.gridSnapCellSpot=Tc;this.gridSnapOrigin=(new H(NaN,NaN)).freeze();this.gz=this.dragsTree=this.dragsLink=!1};function Ak(a){1<arguments.length&&A("Palette constructor can only take one optional argument, the DIV HTML element or its id.");P.call(this,a);this.allowDragOut=!0;this.allowMove=!1;this.isReadOnly=!0;this.contentAlignment=Uc;this.layout=new Bk}oa(Ak,P);
Ak.className="Palette";
function Ck(a){1<arguments.length&&A("Overview constructor can only take one optional argument, the DIV HTML element or its id.");P.call(this,a);this.animationManager.isEnabled=!1;this.ob=!0;this.Yf=null;this.ir=!0;this.Fx("drawShadows",!1);var b=new T,c=new W;c.stroke="magenta";c.strokeWidth=2;c.fill="transparent";c.name="BOXSHAPE";b.selectable=!0;b.selectionAdorned=!1;b.selectionObjectName="BOXSHAPE";b.locationObjectName="BOXSHAPE";b.resizeObjectName="BOXSHAPE";b.cursor="move";b.add(c);this.l=b;
this.allowDelete=this.allowCopy=!1;this.allowSelect=!0;this.autoScrollRegion=new Ic(0,0,0,0);this.xu=new tk(null);this.Xx=this.xu.context;vf(this.toolManager,"Dragging",new Dk,this.toolManager.mouseMoveTools);var d=this;this.click=function(){var a=d.Yf;if(null!==a){var b=a.viewportBounds,c=d.lastInput.documentPoint;a.position=new H(c.x-b.width/2,c.y-b.height/2)}};this.Gm=function(){d.Xa();Ek(d)};this.Em=function(){null!==d.Yf&&(d.Xa(),d.R())};this.Fm=function(){d.R()};this.Dm=function(){null!==d.Yf&&
Ek(d)};this.autoScale=Wi;this.ob=!1}oa(Ck,P);
function Fk(a){a.ob||a.Yb||!1!==a.qd||(a.qd=!0,w.requestAnimationFrame(function(){if(a.qd&&!a.Yb&&(a.qd=!1,null!==a.Ia)){a.Yb=!0;Qi(a);a.documentBounds.o()||Vi(a,a.computeBounds());null===a.Ia&&A("No div specified");null===a.Da&&A("No canvas specified");gi(a.box);if(a.nc){var b=a.Yf;if(null!==b&&!b.animationManager.isAnimating&&!b.animationManager.rb){b=a.Yc;var c=a.xu;b.setTransform(1,0,0,1,0,0);b.clearRect(0,0,a.Da.width,a.Da.height);b.drawImage(c.Ga,0,0);c=a.tb;c.reset();1!==a.scale&&c.scale(a.scale);
0===a.position.x&&0===a.position.y||c.translate(-a.position.x,-a.position.y);b.scale(a.Mb,a.Mb);b.transform(c.m11,c.m12,c.m21,c.m22,c.dx,c.dy);c=a.Pa.j;for(var d=c.length,e=0;e<d;e++)c[e].hc(b,a);a.ci=!1;a.nc=!1}}a.Yb=!1}}))}Ck.prototype.computePixelRatio=function(){return 1};
Ck.prototype.hc=function(){null===this.Ia&&A("No div specified");null===this.Da&&A("No canvas specified");if(!(this.Da instanceof yj)&&(gi(this.box),this.nc)){var a=this.Yf;if(null!==a&&!a.animationManager.isAnimating){rj(this);var b=a.grid;null===b||!b.visible||isNaN(b.width)||isNaN(b.height)||(b=N.alloc().assign(this.viewportBounds).Jc(a.viewportBounds),kj(a,b),N.free(b),Qi(a));var c=this.Da;b=this.Yc;var d=this.xu,e=this.Xx;d.width=c.width;d.height=c.height;b.Rc(!0);b.setTransform(1,0,0,1,0,0);
b.clearRect(0,0,c.width,c.height);d=this.tb;d.reset();1!==this.scale&&d.scale(this.scale);0===this.position.x&&0===this.position.y||d.translate(-this.position.x,-this.position.y);b.scale(this.Mb,this.Mb);b.transform(d.m11,d.m12,d.m21,d.m22,d.dx,d.dy);d=this.ir;var f=this.viewportBounds;a=a.Pa.j;for(var g=a.length,h=0;h<g;h++){var k=a[h],l=d;if(k.visible&&0!==k.lb&&(void 0===l&&(l=!0),l||!k.isTemporary)){1!==k.lb&&(b.globalAlpha=k.lb);l=this.scale;k=k.Fa.j;for(var m=k.length,n=0;n<m;n++){var p=k[n],
q=p.actualBounds;q.Ic(f)&&(q.width*l>this.ne||q.height*l>this.ne?p.hc(b,this):ii(b,p))}b.globalAlpha=1}}e.drawImage(c.Ga,0,0);c=this.Pa.j;e=c.length;for(d=0;d<e;d++)c[d].hc(b,this);this.nc=this.ci=!1}}};function Ek(a){var b=a.box;if(null!==b){var c=a.Yf;if(null!==c){a.nc=!0;c=c.viewportBounds;var d=b.selectionObject,e=L.alloc();e.h(c.width,c.height);d.desiredSize=e;L.free(e);a=2/a.scale;d instanceof W&&(d.strokeWidth=a);b.location=new H(c.x-a/2,c.y-a/2);b.isSelected=!0}}}
Ck.prototype.computeBounds=function(){var a=this.Yf;if(null===a)return Gc;var b=a.documentBounds.copy();b.Jc(a.viewportBounds);return b};Ck.prototype.lx=function(){!0!==this.nc&&(this.nc=!0,Fk(this))};Ck.prototype.lq=function(a,b,c,d){this.ob||(Pi(this),this.R(),hj(this),this.Xa(),Ek(this),this.xe.scale=c,this.xe.position.x=a.x,this.xe.position.y=a.y,this.xe.bounds.assign(a),this.xe.nx=d,this.aa("ViewportBoundsChanged",this.xe,a))};
pa.Object.defineProperties(Ck.prototype,{observed:{get:function(){return this.Yf},set:function(a){var b=this.Yf;a instanceof Ck&&A("Overview.observed Diagram may not be an Overview itself: "+a);b!==a&&(null!==b&&(this.remove(this.box),b.mm("ViewportBoundsChanged",this.Gm),b.mm("DocumentBoundsChanged",this.Em),b.mm("InvalidateDraw",this.Fm),b.mm("AnimationFinished",this.Dm)),this.Yf=a,null!==a&&(a.Pj("ViewportBoundsChanged",this.Gm),a.Pj("DocumentBoundsChanged",this.Em),
a.Pj("InvalidateDraw",this.Fm),a.Pj("AnimationFinished",this.Dm),this.add(this.box),Ek(this)),this.Xa(),this.g("observed",b,a))}},box:{get:function(){return this.l},set:function(a){var b=this.l;b!==a&&(this.l=a,this.remove(b),this.add(this.l),Ek(this),this.g("box",b,a))}},drawsTemporaryLayers:{get:function(){return this.ir},set:function(a){this.ir!==a&&(this.ir=a,this.tf())}}});Ck.className="Overview";
function Dk(){xf.call(this);this.l=null}oa(Dk,xf);
Dk.prototype.canStart=function(){if(!this.isEnabled)return!1;var a=this.diagram;if(null===a||!a.allowMove||!a.allowSelect)return!1;var b=a.observed;if(null===b)return!1;var c=a.lastInput;if(!c.left||a.currentTool!==this&&(!this.isBeyondDragSize()||c.isTouchEvent&&c.timestamp-a.firstInput.timestamp<this.delay))return!1;null===this.findDraggablePart()&&(c=b.viewportBounds,this.l=new H(c.width/2,c.height/2),a=a.firstInput.documentPoint,b.position=new H(a.x-this.l.x,a.y-this.l.y));return!0};
Dk.prototype.doActivate=function(){this.l=null;xf.prototype.doActivate.call(this)};Dk.prototype.moveParts=function(){var a=this.diagram,b=a.observed;if(null!==b){var c=a.box;if(null!==c){if(null===this.l){var d=a.firstInput.documentPoint;c=c.location;this.l=new H(d.x-c.x,d.y-c.y)}a=a.lastInput.documentPoint;b.position=new H(a.x-this.l.x,a.y-this.l.y)}}};Dk.className="OverviewDraggingTool";
function Gk(){nb(this);this.C=lf;this.Ub=this.K=this.u=!0;this.ba=this.La=this.Vb=this.Ma=!1;this.ki=this.l=null;this.Vc=1.05;this.du=NaN;this.yw=null;this.Du=NaN;this.Cu=Gc;this.dg=null;this.Kc=200}Gk.prototype.toString=function(){return"CommandHandler"};Gk.prototype.hb=function(a){this.C=a};
Gk.prototype.doKeyDown=function(){var a=this.diagram,b=a.lastInput,c=ib?b.meta:b.control,d=b.shift,e=b.alt,f=b.key;!c||"C"!==f&&"Insert"!==f?c&&"X"===f||d&&"Del"===f?this.canCutSelection()&&this.cutSelection():c&&"V"===f||d&&"Insert"===f?this.canPasteSelection()&&this.pasteSelection():c&&"Y"===f||e&&d&&"Backspace"===f?this.canRedo()&&this.redo():c&&"Z"===f||e&&"Backspace"===f?this.canUndo()&&this.undo():"Del"===f||"Backspace"===f?this.canDeleteSelection()&&this.deleteSelection():c&&"A"===f?this.canSelectAll()&&
this.selectAll():"Esc"===f?this.canStopCommand()&&this.stopCommand():"Up"===f?a.allowVerticalScroll&&(c?a.scroll("pixel","up"):a.scroll("line","up")):"Down"===f?a.allowVerticalScroll&&(c?a.scroll("pixel","down"):a.scroll("line","down")):"Left"===f?a.allowHorizontalScroll&&(c?a.scroll("pixel","left"):a.scroll("line","left")):"Right"===f?a.allowHorizontalScroll&&(c?a.scroll("pixel","right"):a.scroll("line","right")):"PageUp"===f?d&&a.allowHorizontalScroll?a.scroll("page","left"):a.allowVerticalScroll&&
a.scroll("page","up"):"PageDown"===f?d&&a.allowHorizontalScroll?a.scroll("page","right"):a.allowVerticalScroll&&a.scroll("page","down"):"Home"===f?c&&a.allowVerticalScroll?a.scroll("document","up"):!c&&a.allowHorizontalScroll&&a.scroll("document","left"):"End"===f?c&&a.allowVerticalScroll?a.scroll("document","down"):!c&&a.allowHorizontalScroll&&a.scroll("document","right"):" "===f?this.canScrollToPart()&&this.scrollToPart():"Subtract"===f?this.canDecreaseZoom()&&this.decreaseZoom():"Add"===f?this.canIncreaseZoom()&&
this.increaseZoom():c&&"0"===f?this.canResetZoom()&&this.resetZoom():d&&"Z"===f?this.canZoomToFit()&&this.zoomToFit():c&&!d&&"G"===f?this.canGroupSelection()&&this.groupSelection():c&&d&&"G"===f?this.canUngroupSelection()&&this.ungroupSelection():b.event&&113===b.event.which?this.canEditTextBlock()&&this.editTextBlock():b.event&&93===b.event.which?this.canShowContextMenu()&&this.showContextMenu():b.bubbles=!0:this.canCopySelection()&&this.copySelection()};
Gk.prototype.doKeyUp=function(){this.diagram.lastInput.bubbles=!0};Gk.prototype.stopCommand=function(){var a=this.diagram,b=a.currentTool;b instanceof Ua&&a.allowSelect&&a.Qp();null!==b&&b.doCancel()};Gk.prototype.canStopCommand=function(){return!0};
Gk.prototype.selectAll=function(){var a=this.diagram;a.R();try{a.currentCursor="wait";a.aa("ChangingSelection",a.selection);for(var b=a.parts;b.next();)b.value.isSelected=!0;for(var c=a.nodes;c.next();)c.value.isSelected=!0;for(var d=a.links;d.next();)d.value.isSelected=!0}finally{a.aa("ChangedSelection",a.selection),a.currentCursor=""}};Gk.prototype.canSelectAll=function(){return this.diagram.allowSelect};
Gk.prototype.deleteSelection=function(){var a=this.diagram;try{a.currentCursor="wait";a.Aa("Delete");a.aa("ChangingSelection",a.selection);a.aa("SelectionDeleting",a.selection);for(var b=new F,c=a.selection.iterator;c.next();)Hk(b,c.value,!0,this.deletesTree?Infinity:0,this.deletesConnectedLinks?null:!1,function(a){return a.canDelete()});a.qt(b,!0);a.aa("SelectionDeleted",b)}finally{a.aa("ChangedSelection",a.selection),a.Za("Delete"),a.currentCursor=""}};
Gk.prototype.canDeleteSelection=function(){var a=this.diagram;return a.isReadOnly||a.isModelReadOnly||!a.allowDelete||0===a.selection.count?!1:!0};Gk.prototype.copySelection=function(){var a=this.diagram,b=new F;for(a=a.selection.iterator;a.next();)Hk(b,a.value,!0,this.copiesTree?Infinity:0,this.copiesConnectedLinks,function(a){return a.canCopy()});this.copyToClipboard(b)};Gk.prototype.canCopySelection=function(){var a=this.diagram;return a.allowCopy&&a.allowClipboard&&0!==a.selection.count?!0:!1};
Gk.prototype.cutSelection=function(){this.copySelection();this.deleteSelection()};Gk.prototype.canCutSelection=function(){var a=this.diagram;return!a.isReadOnly&&!a.isModelReadOnly&&a.allowCopy&&a.allowDelete&&a.allowClipboard&&0!==a.selection.count?!0:!1};
Gk.prototype.copyToClipboard=function(a){var b=this.diagram,c=null;if(null===a)Bi=null,Ci="";else{c=b.model;var d=!1,e=!1,f=null;try{c.fm()&&(d=c.Yj,c.Yj=this.copiesParentKey),c.ek()&&(e=c.Xj,c.Xj=this.copiesGroupKey),f=b.Zj(a,null,!0)}finally{c.fm()&&(c.Yj=d),c.ek()&&(c.Xj=e),c=new E,c.addAll(f),Bi=c,Ci=b.model.dataFormat}}b.aa("ClipboardChanged",c)};
Gk.prototype.pasteFromClipboard=function(){var a=new F,b=Bi;if(null===b)return a;var c=this.diagram;if(Ci!==c.model.dataFormat)return a;var d=c.model,e=!1,f=!1,g=null;try{d.fm()&&(e=d.Yj,d.Yj=this.copiesParentKey),d.ek()&&(f=d.Xj,d.Xj=this.copiesGroupKey),g=c.Zj(b,c,!1)}finally{for(d.fm()&&(d.Yj=e),d.ek()&&(d.Xj=f),b=g.iterator;b.next();)c=b.value,d=b.key,c.location.o()||(d.location.o()?c.location=d.location:!c.position.o()&&d.position.o()&&(c.position=d.position)),a.add(c)}return a};
Gk.prototype.pasteSelection=function(a){void 0===a&&(a=null);var b=this.diagram;try{b.currentCursor="wait";b.Aa("Paste");b.aa("ChangingSelection",b.selection);var c=this.pasteFromClipboard();0<c.count&&Hf(b);for(var d=c.iterator;d.next();)d.value.isSelected=!0;b.aa("ChangedSelection",b.selection);if(null!==a){var e=b.computePartsBounds(b.selection);if(e.o()){var f=this.computeEffectiveCollection(b.selection,b.Mk);bg(b,f,new H(a.x-e.centerX,a.y-e.centerY),b.Mk,!1)}}b.aa("ClipboardPasted",c)}finally{b.Za("Paste"),
b.currentCursor=""}};Gk.prototype.canPasteSelection=function(){var a=this.diagram;return a.isReadOnly||a.isModelReadOnly||!a.allowInsert||!a.allowClipboard||null===Bi||0===Bi.count||Ci!==a.model.dataFormat?!1:!0};Gk.prototype.undo=function(){this.diagram.undoManager.undo()};Gk.prototype.canUndo=function(){var a=this.diagram;return a.isReadOnly||a.isModelReadOnly?!1:a.allowUndo&&a.undoManager.canUndo()};Gk.prototype.redo=function(){this.diagram.undoManager.redo()};
Gk.prototype.canRedo=function(){var a=this.diagram;return a.isReadOnly||a.isModelReadOnly?!1:a.allowUndo&&a.undoManager.canRedo()};Gk.prototype.decreaseZoom=function(a){void 0===a&&(a=1/this.zoomFactor);var b=this.diagram;b.autoScale===pi&&(a=b.scale*a,a<b.minScale||a>b.maxScale||(b.scale=a))};Gk.prototype.canDecreaseZoom=function(a){void 0===a&&(a=1/this.zoomFactor);var b=this.diagram;if(b.autoScale!==pi)return!1;a=b.scale*a;return a<b.minScale||a>b.maxScale?!1:b.allowZoom};
Gk.prototype.increaseZoom=function(a){void 0===a&&(a=this.zoomFactor);var b=this.diagram;b.autoScale===pi&&(a=b.scale*a,a<b.minScale||a>b.maxScale||(b.scale=a))};Gk.prototype.canIncreaseZoom=function(a){void 0===a&&(a=this.zoomFactor);var b=this.diagram;if(b.autoScale!==pi)return!1;a=b.scale*a;return a<b.minScale||a>b.maxScale?!1:b.allowZoom};Gk.prototype.resetZoom=function(a){void 0===a&&(a=this.defaultScale);var b=this.diagram;a<b.minScale||a>b.maxScale||(b.scale=a)};
Gk.prototype.canResetZoom=function(a){void 0===a&&(a=this.defaultScale);var b=this.diagram;return a<b.minScale||a>b.maxScale?!1:b.allowZoom};Gk.prototype.zoomToFit=function(){var a=this.diagram,b=a.scale,c=a.position;b===this.Du&&!isNaN(this.du)&&a.documentBounds.A(this.Cu)?(a.scale=this.du,a.position=this.yw,this.Du=NaN,this.Cu=Gc):(this.du=b,this.yw=c.copy(),a.zoomToFit(),this.Du=a.scale,this.Cu=a.documentBounds.copy())};Gk.prototype.canZoomToFit=function(){return this.diagram.allowZoom};
Gk.prototype.scrollToPart=function(a){void 0===a&&(a=null);var b=this.diagram;if(null===a){try{null!==this.dg&&(this.dg.next()?a=this.dg.value:this.dg=null)}catch(k){this.dg=null}null===a&&(0<b.highlighteds.count?this.dg=b.highlighteds.iterator:0<b.selection.count&&(this.dg=b.selection.iterator),null!==this.dg&&this.dg.next()&&(a=this.dg.value))}if(null!==a){var c=b.animationManager;c.Ti("Scroll To Part");var d=this.scrollToPartPause;if(0<d){var e=Ik(this,a,[a]),f=function(){b.Aa();for(var a=e.pop();0<
e.length&&a instanceof V&&a.isTreeExpanded&&(!(a instanceof ig)||a.isSubGraphExpanded);)a=e.pop();0<e.length?(a instanceof T&&b.Fv(a.actualBounds),a instanceof V&&!a.isTreeExpanded&&(a.isTreeExpanded=!0),a instanceof ig&&!a.isSubGraphExpanded&&(a.isSubGraphExpanded=!0)):(a instanceof T&&b.Iu(a.actualBounds),b.mm("LayoutCompleted",g));b.Za("Scroll To Part")},g=function(){ua(f,(c.isEnabled?c.duration:0)+d)};b.Pj("LayoutCompleted",g);f()}else{var h=b.position.copy();b.Iu(a.actualBounds);h.Qa(b.position)&&
c.vd()}}};function Ik(a,b,c){if(b.isVisible())return c;if(b instanceof pf)Ik(a,b.adornedPart,c);else if(b instanceof S){var d=b.fromNode;null!==d&&Ik(a,d,c);b=b.toNode;null!==b&&Ik(a,b,c)}else b instanceof V&&(d=b.labeledLink,null!==d&&Ik(a,d,c),d=b.pg(),null!==d&&(d.isTreeExpanded||d.wasTreeExpanded||c.push(d),Ik(a,d,c))),b=b.containingGroup,null!==b&&(b.isSubGraphExpanded||b.wasSubGraphExpanded||c.push(b),Ik(a,b,c));return c}
Gk.prototype.canScrollToPart=function(a){void 0===a&&(a=null);if(null!==a&&!(a instanceof T))return!1;a=this.diagram;return 0===a.selection.count&&0===a.highlighteds.count?!1:a.allowHorizontalScroll&&a.allowVerticalScroll};
Gk.prototype.collapseTree=function(a){void 0===a&&(a=null);var b=this.diagram;try{b.Aa("Collapse Tree");b.animationManager.Ti("Collapse Tree");var c=new E;if(null!==a&&a.isTreeExpanded)a.collapseTree(),c.add(a);else if(null===a)for(var d=b.selection.iterator;d.next();){var e=d.value;e instanceof V&&e.isTreeExpanded&&(e.collapseTree(),c.add(e))}b.aa("TreeCollapsed",c)}finally{b.Za("Collapse Tree")}};
Gk.prototype.canCollapseTree=function(a){void 0===a&&(a=null);var b=this.diagram;if(b.isReadOnly)return!1;if(null!==a){if(!(a instanceof V&&a.isTreeExpanded))return!1;if(0<a.Xp().count)return!0}else for(a=b.selection.iterator;a.next();)if(b=a.value,b instanceof V&&b.isTreeExpanded&&0<b.Xp().count)return!0;return!1};
Gk.prototype.expandTree=function(a){void 0===a&&(a=null);var b=this.diagram;try{b.Aa("Expand Tree");b.animationManager.Ti("Expand Tree");var c=new E;if(null!==a&&!a.isTreeExpanded)a.expandTree(),c.add(a);else if(null===a)for(var d=b.selection.iterator;d.next();){var e=d.value;e instanceof V&&!e.isTreeExpanded&&(e.expandTree(),c.add(e))}b.aa("TreeExpanded",c)}finally{b.Za("Expand Tree")}};
Gk.prototype.canExpandTree=function(a){void 0===a&&(a=null);var b=this.diagram;if(b.isReadOnly)return!1;if(null!==a){if(!(a instanceof V)||a.isTreeExpanded)return!1;if(0<a.Xp().count)return!0}else for(a=b.selection.iterator;a.next();)if(b=a.value,b instanceof V&&!b.isTreeExpanded&&0<b.Xp().count)return!0;return!1};
Gk.prototype.groupSelection=function(){var a=this.diagram,b=a.model;if(b.fk()){var c=this.archetypeGroupData;if(null!==c){var d=null;try{a.currentCursor="wait";a.Aa("Group");a.aa("ChangingSelection",a.selection);for(var e=new E,f=a.selection.iterator;f.next();){var g=f.value;g.bc()&&g.canGroup()&&e.add(g)}for(var h=new E,k=e.iterator;k.next();){var l=k.value;f=!1;for(var m=e.iterator;m.next();)if(l.Vd(m.value)){f=!0;break}f||h.add(l)}if(0<h.count){var n=h.first().containingGroup;if(null!==n)for(;null!==
n;){e=!1;for(var p=h.iterator;p.next();)if(!p.value.Vd(n)){e=!0;break}if(e)n=n.containingGroup;else break}if(c instanceof ig)Yg(c),d=c.copy(),null!==d&&a.add(d);else if(b.kv(c)){var q=b.copyNodeData(c);Ca(q)&&(b.jf(q),d=a.Gi(q))}if(null!==d){null!==n&&this.isValidMember(n,d)&&(d.containingGroup=n);for(var r=h.iterator;r.next();){var u=r.value;this.isValidMember(d,u)&&(u.containingGroup=d)}a.select(d)}}a.aa("ChangedSelection",a.selection);a.aa("SelectionGrouped",d)}finally{a.Za("Group"),a.currentCursor=
""}}}};Gk.prototype.canGroupSelection=function(){var a=this.diagram;if(a.isReadOnly||a.isModelReadOnly||!a.allowInsert||!a.allowGroup||!a.model.fk()||null===this.archetypeGroupData)return!1;for(a=a.selection.iterator;a.next();){var b=a.value;if(b.bc()&&b.canGroup())return!0}return!1};
function Jk(a){var b=Ka();for(a=a.iterator;a.next();){var c=a.value;c instanceof S||b.push(c)}a=new F;c=b.length;for(var d=0;d<c;d++){for(var e=b[d],f=!0,g=0;g<c;g++)if(e.Vd(b[g])){f=!1;break}f&&a.add(e)}Oa(b);return a}
Gk.prototype.isValidMember=function(a,b){if(null===b||a===b||b instanceof S)return!1;if(null!==a){if(a===b||a.Vd(b))return!1;var c=a.memberValidation;if(null!==c&&!c(a,b)||null===a.data&&null!==b.data||null!==a.data&&null===b.data)return!1}c=this.memberValidation;return null!==c?c(a,b):!0};
Gk.prototype.ungroupSelection=function(a){void 0===a&&(a=null);var b=this.diagram,c=b.model;if(c.fk())try{b.currentCursor="wait";b.Aa("Ungroup");b.aa("ChangingSelection",b.selection);var d=new E;if(null!==a)d.add(a);else for(var e=b.selection.iterator;e.next();){var f=e.value;f instanceof ig&&f.canUngroup()&&d.add(f)}var g=new E;if(0<d.count){b.Qp();for(var h=d.iterator;h.next();){var k=h.value;k.expandSubGraph();var l=k.containingGroup,m=null!==l&&null!==l.data?c.pa(l.data):void 0;g.addAll(k.memberParts);
for(var n=g.iterator;n.next();){var p=n.value;p.isSelected=!0;if(!(p instanceof S)){var q=p.data;null!==q?c.tt(q,m):p.containingGroup=l}}b.remove(k)}}b.aa("ChangedSelection",b.selection);b.aa("SelectionUngrouped",d,g)}finally{b.Za("Ungroup"),b.currentCursor=""}};
Gk.prototype.canUngroupSelection=function(a){void 0===a&&(a=null);var b=this.diagram;if(b.isReadOnly||b.isModelReadOnly||!b.allowDelete||!b.allowUngroup||!b.model.fk())return!1;if(null!==a){if(!(a instanceof ig))return!1;if(a.canUngroup())return!0}else for(a=b.selection.iterator;a.next();)if(b=a.value,b instanceof ig&&b.canUngroup())return!0;return!1};
Gk.prototype.addTopLevelParts=function(a,b){var c=!0;for(a=Jk(a).iterator;a.next();){var d=a.value;null!==d.containingGroup&&(!b||this.isValidMember(null,d)?d.containingGroup=null:c=!1)}return c};
Gk.prototype.collapseSubGraph=function(a){void 0===a&&(a=null);var b=this.diagram;try{b.Aa("Collapse SubGraph");b.animationManager.Ti("Collapse SubGraph");var c=new E;if(null!==a&&a.isSubGraphExpanded)a.collapseSubGraph(),c.add(a);else if(null===a)for(var d=b.selection.iterator;d.next();){var e=d.value;e instanceof ig&&e.isSubGraphExpanded&&(e.collapseSubGraph(),c.add(e))}b.aa("SubGraphCollapsed",c)}finally{b.Za("Collapse SubGraph")}};
Gk.prototype.canCollapseSubGraph=function(a){void 0===a&&(a=null);var b=this.diagram;if(b.isReadOnly)return!1;if(null!==a)return a instanceof ig&&a.isSubGraphExpanded?!0:!1;for(a=b.selection.iterator;a.next();)if(b=a.value,b instanceof ig&&b.isSubGraphExpanded)return!0;return!1};
Gk.prototype.expandSubGraph=function(a){void 0===a&&(a=null);var b=this.diagram;try{b.Aa("Expand SubGraph");b.animationManager.Ti("Expand SubGraph");var c=new E;if(null!==a&&!a.isSubGraphExpanded)a.expandSubGraph(),c.add(a);else if(null===a)for(var d=b.selection.iterator;d.next();){var e=d.value;e instanceof ig&&!e.isSubGraphExpanded&&(e.expandSubGraph(),c.add(e))}b.aa("SubGraphExpanded",c)}finally{b.Za("Expand SubGraph")}};
Gk.prototype.canExpandSubGraph=function(a){void 0===a&&(a=null);var b=this.diagram;if(b.isReadOnly)return!1;if(null!==a)return a instanceof ig&&!a.isSubGraphExpanded?!0:!1;for(a=b.selection.iterator;a.next();)if(b=a.value,b instanceof ig&&!b.isSubGraphExpanded)return!0;return!1};
Gk.prototype.editTextBlock=function(a){void 0===a&&(a=null);var b=this.diagram,c=b.toolManager.findTool("TextEditing");if(null!==c){if(null===a){a=null;for(var d=b.selection.iterator;d.next();){var e=d.value;if(e.canEdit()){a=e;break}}if(null===a)return;a=a.Yl(function(a){return a instanceof vh&&a.editable})}null!==a&&(b.currentTool=null,c.textBlock=a,b.currentTool=c)}};
Gk.prototype.canEditTextBlock=function(a){void 0===a&&(a=null);var b=this.diagram;if(b.isReadOnly||b.isModelReadOnly||!b.allowTextEdit||null===b.toolManager.findTool("TextEditing"))return!1;if(null!==a){if(!(a instanceof vh))return!1;a=a.part;if(null!==a&&a.canEdit())return!0}else for(b=b.selection.iterator;b.next();)if(a=b.value,a.canEdit()&&(a=a.Yl(function(a){return a instanceof vh&&a.editable}),null!==a))return!0;return!1};
Gk.prototype.showContextMenu=function(a){var b=this.diagram,c=b.toolManager.findTool("ContextMenu");if(null!==c&&(void 0===a&&(a=0<b.selection.count?b.selection.first():b),a=c.findObjectWithContextMenu(a),null!==a)){var d=b.lastInput,e=null;a instanceof Y?e=a.ma(dd):b.isMouseOverDiagram||(e=b.viewportBounds,e=new H(e.x+e.width/2,e.y+e.height/2));null!==e&&(d.viewPoint=b.wt(e),d.documentPoint=e,d.left=!1,d.right=!0,d.up=!0);b.currentTool=c;rh(c,!1,a)}};
Gk.prototype.canShowContextMenu=function(a){var b=this.diagram,c=b.toolManager.findTool("ContextMenu");if(null===c)return!1;void 0===a&&(a=0<b.selection.count?b.selection.first():b);return null===c.findObjectWithContextMenu(a)?!1:!0};
Gk.prototype.computeEffectiveCollection=function(a,b){var c=this.diagram,d=c.toolManager.findTool("Dragging"),e=c.currentTool===d;void 0===b&&(b=e?d.dragOptions:c.Mk);d=new Lb;if(null===a)return d;for(var f=a.iterator;f.next();)Jj(c,d,f.value,e,b);if(null!==c.draggedLink&&b.dragsLink)return d;for(f=a.iterator;f.next();)a=f.value,a instanceof S&&(b=a.fromNode,null===b||d.contains(b)?(b=a.toNode,null===b||d.contains(b)||d.remove(a)):d.remove(a));return d};
pa.Object.defineProperties(Gk.prototype,{diagram:{get:function(){return this.C}},copiesClipboardData:{get:function(){return this.u},set:function(a){this.u=a}},copiesConnectedLinks:{get:function(){return this.K},set:function(a){this.K=a}},deletesConnectedLinks:{get:function(){return this.Ub},set:function(a){this.Ub=a}},copiesTree:{get:function(){return this.Ma},
set:function(a){this.Ma=a}},deletesTree:{get:function(){return this.Vb},set:function(a){this.Vb=a}},copiesParentKey:{get:function(){return this.La},set:function(a){this.La=a}},copiesGroupKey:{get:function(){return this.ba},set:function(a){this.ba=a}},archetypeGroupData:{get:function(){return this.l},set:function(a){this.l=a}},memberValidation:{get:function(){return this.ki},
set:function(a){this.ki=a}},defaultScale:{get:function(){return this.diagram.defaultScale},set:function(a){this.diagram.defaultScale=a}},zoomFactor:{get:function(){return this.Vc},set:function(a){1<a||A("zoomFactor must be larger than 1.0, not: "+a);this.Vc=a}},scrollToPartPause:{get:function(){return this.Kc},set:function(a){this.Kc=a}}});Gk.className="CommandHandler";wi=function(){return new Gk};
function Y(){nb(this);this.H=4225027;this.lb=1;this.ag=null;this.Ua="";this.dc=this.ib=null;this.sa=(new H(NaN,NaN)).freeze();this.Nc=mc;this.Qf=fc;this.Pf=lc;this.tb=new Qc;this.Eh=new Qc;this.Of=new Qc;this.Ca=this.Tk=1;this.zc=0;this.ve=Kk;this.Vg=Oc;this.pc=(new N(NaN,NaN,NaN,NaN)).freeze();this.vb=(new N(NaN,NaN,NaN,NaN)).freeze();this.qc=(new N(0,0,NaN,NaN)).freeze();this.P=this.Qo=this.Ro=null;this.yk=this.wb=Ed;this.cp=0;this.ep=1;this.Eg=0;this.Ym=1;this.tp=null;this.ip=-Infinity;this.yl=
0;this.zl=ac;this.Al=Og;this.hn="";this.bb=this.O=null;this.Ek=-1;this.Cl=this.kd=this.Th=this.Fl=null;this.ks=Zg;this.Cj=null}var be,Zg,ah,Kk,Lk,Mk,Nk,Ok,Pk,Qk;
Y.prototype.cloneProtected=function(a){a.H=this.H|6144;a.lb=this.lb;a.Ua=this.Ua;a.ib=this.ib;a.dc=this.dc;a.sa.assign(this.sa);a.Nc=this.Nc.I();a.Qf=this.Qf.I();a.Pf=this.Pf.I();a.Of=this.Of.copy();a.Ca=this.Ca;a.zc=this.zc;a.ve=this.ve;a.Vg=this.Vg.I();a.pc.assign(this.pc);a.vb.assign(this.vb);a.qc.assign(this.qc);a.Qo=this.Qo;null!==this.P&&(a.P=this.P.copy());a.wb=this.wb.I();a.yk=this.yk.I();a.cp=this.cp;a.ep=this.ep;a.Eg=this.Eg;a.Ym=this.Ym;a.tp=this.tp;a.ip=this.ip;a.yl=this.yl;a.zl=this.zl.I();
a.Al=this.Al;a.hn=this.hn;null!==this.O&&(a.O=this.O.copy());a.bb=this.bb;a.Ek=this.Ek;null!==this.Th&&(a.Th=Ga(this.Th));null!==this.kd&&(a.kd=this.kd.copy());a.Cl=this.Cl};Y.prototype.Ow=function(a){var b=this.Th;if(Ea(b))for(var c=0;c<b.length;c++){if(b[c]===a)return}else this.Th=b=[];b.push(a)};Y.prototype.nf=function(a){a.Ro=null;a.Cj=null;a.s()};
Y.prototype.clone=function(){var a=new this.constructor;this.cloneProtected(a);if(null!==this.Th)for(var b=0;b<this.Th.length;b++){var c=this.Th[b];a[c]=this[c]}return a};Y.prototype.copy=function(){return this.clone()};t=Y.prototype;t.fb=function(a){a.classType===S?0===a.name.indexOf("Orient")?this.segmentOrientation=a:A("Unknown Link enum value for GraphObject.segmentOrientation property: "+a):a.classType===Y&&(this.stretch=a)};t.toString=function(){return Pa(this.constructor)+"#"+Bb(this)};
function Rk(a){null===a.O&&(a.O=new Sk)}t.Hc=function(){if(null===this.P){var a=new Tk;a.Ng=Sc;a.nh=Sc;a.Lg=10;a.lh=10;a.Mg=0;a.mh=0;this.P=a}};t.ab=function(a,b,c,d,e,f,g){var h=this.part;if(null!==h&&(h.qk(a,b,c,d,e,f,g),c===this&&a===af&&Uk(this)&&Vk(this,h,b),this instanceof X&&c===h&&0!==(h.H&16777216)&&null!==h.data))for(a=this.Y.j,c=a.length,d=0;d<c;d++)e=a[d],e instanceof X&&Bj(e,function(a){null!==a.data&&0!==(a.H&16777216)&&a.Ea(b)})};
function Vk(a,b,c){var d=a.Ii();if(null!==d)for(var e=a.bb.iterator;e.next();){var f=e.value,g=null;if(null!==f.sourceName){g=Wk(f,d,a);if(null===g)continue;f.xq(a,g,c,null)}else if(f.isToModel){var h=b.diagram;null===h||h.skipsModelSourceBindings||f.xq(a,h.model.modelData,c,d)}else{h=d.data;if(null===h)continue;var k=b.diagram;null===k||k.skipsModelSourceBindings||f.xq(a,h,c,d)}g===a&&(h=d.Ps(f.Zi),null!==h&&f.Xv(h,g,c))}}t.Ps=function(a){return this.Ek===a?this:null};
t.g=function(a,b,c){this.ab(af,a,this,b,c)};function Xk(a,b,c,d,e){var f=a.pc,g=a.Of;g.reset();Yk(a,g,b,c,d,e);a.Of=g;f.h(b,c,d,e);g.bt()||g.Uv(f)}function Zk(a,b,c,d){if(!1===a.pickable)return!1;d.multiply(a.transform);return c?a.Ic(b,d):a.vh(b,d)}
t.gx=function(a,b,c){if(!1===this.pickable)return!1;var d=this.naturalBounds;b=a.Be(b);return c?Rb(a.x,a.y,0,0,0,d.height)<=b||Rb(a.x,a.y,0,d.height,d.width,d.height)<=b||Rb(a.x,a.y,d.width,d.height,d.width,0)<=b||Rb(a.x,a.y,d.width,0,0,0)<=b:a.cd(0,0)<=b&&a.cd(0,d.height)<=b&&a.cd(d.width,0)<=b&&a.cd(d.width,d.height)<=b};t.Zd=function(){return!0};
t.da=function(a){var b=H.alloc();b.assign(a);this.transform.ta(b);var c=this.actualBounds;if(!c.o())return H.free(b),!1;var d=this.diagram;if(null!==d&&d.Re){var e=d.bm("extraTouchThreshold"),f=d.bm("extraTouchArea"),g=f/2,h=this.naturalBounds;d=this.Ce()*d.scale;var k=1/d;if(h.width*d<e&&h.height*d<e)return a=Cc(c.x-g*k,c.y-g*k,c.width+f*k,c.height+f*k,b.x,b.y),H.free(b),a}e=!1;if(this instanceof pf||this instanceof W?Cc(c.x-5,c.y-5,c.width+10,c.height+10,b.x,b.y):c.da(b))this.kd&&!this.kd.da(b)?
e=!1:null!==this.dc&&c.da(b)?e=!0:null!==this.ib&&this.qc.da(a)?e=!0:e=this.wh(a);H.free(b);return e};t.wh=function(a){var b=this.naturalBounds;return Cc(0,0,b.width,b.height,a.x,a.y)};
t.mf=function(a){if(0===this.angle)return this.actualBounds.mf(a);var b=this.naturalBounds;b=N.allocAt(0,0,b.width,b.height);var c=this.transform,d=!1,e=H.allocAt(a.x,a.y);b.da(c.Ud(e))&&(e.h(a.x,a.bottom),b.da(c.Ud(e))&&(e.h(a.right,a.bottom),b.da(c.Ud(e))&&(e.h(a.right,a.y),b.da(c.Ud(e))&&(d=!0))));H.free(e);N.free(b);return d};
t.vh=function(a,b){if(void 0===b)return a.mf(this.actualBounds);var c=this.naturalBounds,d=!1,e=H.allocAt(0,0);a.da(b.ta(e))&&(e.h(0,c.height),a.da(b.ta(e))&&(e.h(c.width,c.height),a.da(b.ta(e))&&(e.h(c.width,0),a.da(b.ta(e))&&(d=!0))));H.free(e);return d};
t.Ic=function(a,b){if(void 0===b&&(b=this.transform,0===this.angle))return a.Ic(this.actualBounds);var c=this.naturalBounds,d=H.allocAt(0,0),e=H.allocAt(0,c.height),f=H.allocAt(c.width,c.height),g=H.allocAt(c.width,0),h=!1;if(a.da(b.ta(d))||a.da(b.ta(e))||a.da(b.ta(f))||a.da(b.ta(g)))h=!0;else{c=N.allocAt(0,0,c.width,c.height);var k=H.allocAt(a.x,a.y);c.da(b.Ud(k))?h=!0:(k.h(a.x,a.bottom),c.da(b.Ud(k))?h=!0:(k.h(a.right,a.bottom),c.da(b.Ud(k))?h=!0:(k.h(a.right,a.y),c.da(b.Ud(k))&&(h=!0))));H.free(k);
N.free(c);!h&&(I.Zs(a,d,e)||I.Zs(a,e,f)||I.Zs(a,f,g)||I.Zs(a,g,d))&&(h=!0)}H.free(d);H.free(e);H.free(f);H.free(g);return h};t.ma=function(a,b){void 0===b&&(b=new H);if(a instanceof O){var c=this.naturalBounds;b.h(a.x*c.width+a.offsetX,a.y*c.height+a.offsetY)}else b.set(a);this.rd.ta(b);return b};
t.Yp=function(a){void 0===a&&(a=new N);var b=this.naturalBounds,c=this.rd,d=H.allocAt(0,0).transform(c);a.h(d.x,d.y,0,0);d.h(b.width,0).transform(c);Bc(a,d.x,d.y,0,0);d.h(b.width,b.height).transform(c);Bc(a,d.x,d.y,0,0);d.h(0,b.height).transform(c);Bc(a,d.x,d.y,0,0);H.free(d);return a};t.Li=function(){var a=this.rd;1===a.m11&&0===a.m12?a=0:(a=180*Math.atan2(a.m12,a.m11)/Math.PI,0>a&&(a+=360));return a};
t.Ce=function(){if(0!==(this.H&4096)===!1)return this.Tk;var a=this.Ca;return null!==this.panel?a*this.panel.Ce():a};t.Vs=function(a,b){void 0===b&&(b=new H);b.assign(a);this.rd.Ud(b);return b};t.Tc=function(a,b,c){return this.dk(a.x,a.y,b.x,b.y,c)};
t.dk=function(a,b,c,d,e){var f=this.transform,g=1/(f.m11*f.m22-f.m12*f.m21),h=f.m22*g,k=-f.m12*g,l=-f.m21*g,m=f.m11*g,n=g*(f.m21*f.dy-f.m22*f.dx),p=g*(f.m12*f.dx-f.m11*f.dy);if(null!==this.areaBackground)return f=this.actualBounds,I.Tc(f.left,f.top,f.right,f.bottom,a,b,c,d,e);g=a*h+b*l+n;a=a*k+b*m+p;b=c*h+d*l+n;c=c*k+d*m+p;e.h(0,0);d=this.naturalBounds;c=I.Tc(0,0,d.width,d.height,g,a,b,c,e);e.transform(f);return c};
Y.prototype.measure=function(a,b,c,d){if(!1!==oj(this)){var e=this.Vg,f=e.right+e.left;e=e.top+e.bottom;a=Math.max(a-f,0);b=Math.max(b-e,0);c=Math.max((c||0)-f,0);d=Math.max((d||0)-e,0);f=this.angle;e=this.desiredSize;var g=0;this instanceof W&&(g=this.strokeWidth);90===f||270===f?(a=isFinite(e.height)?e.height+g:a,b=isFinite(e.width)?e.width+g:b):(a=isFinite(e.width)?e.width+g:a,b=isFinite(e.height)?e.height+g:b);e=c||0;g=d||0;var h=this instanceof X;switch($k(this,!0)){case Zg:g=e=0;h&&(b=a=Infinity);
break;case be:isFinite(a)&&a>c&&(e=a);isFinite(b)&&b>d&&(g=b);break;case Lk:isFinite(a)&&a>c&&(e=a);g=0;h&&(b=Infinity);break;case Mk:isFinite(b)&&b>d&&(g=b),e=0,h&&(a=Infinity)}h=this.maxSize;var k=this.minSize;e>h.width&&k.width<h.width&&(e=h.width);g>h.height&&k.height<h.height&&(g=h.height);c=Math.max(e/this.scale,k.width);d=Math.max(g/this.scale,k.height);h.width<c&&(c=Math.min(k.width,c));h.height<d&&(d=Math.min(k.height,d));a=Math.min(h.width,a);b=Math.min(h.height,b);a=Math.max(c,a);b=Math.max(d,
b);if(90===f||270===f)f=a,a=b,b=f,f=c,c=d,d=f;this.pc.ha();this.hm(a,b,c,d);this.pc.freeze();this.pc.o()||A("Non-real measuredBounds has been set. Object "+this+", measuredBounds: "+this.pc.toString());jj(this,!1)}};Y.prototype.hm=function(){};Y.prototype.tg=function(){return!1};
Y.prototype.arrange=function(a,b,c,d,e){this.fl();var f=N.alloc();f.assign(this.vb);this.vb.ha();!1===pj(this)?this.vb.h(a,b,c,d):this.uh(a,b,c,d);this.vb.freeze();void 0===e?this.kd=null:this.kd=e;c=!1;if(void 0!==e)c=!0;else if(e=this.panel,null===e||e.type!==X.TableRow&&e.type!==X.TableColumn||(e=e.panel),null!==e&&(e=e.qc,d=this.measuredBounds,null!==this.areaBackground&&(d=this.vb),c=b+d.height,d=a+d.width,c=!(0<=a+.05&&d<=e.width+.05&&0<=b+.05&&c<=e.height+.05),this instanceof vh&&(a=this.naturalBounds,
this.Qr>a.height||this.metrics.maxLineWidth>a.width)))c=!0;this.H=c?this.H|256:this.H&-257;this.vb.o()||A("Non-real actualBounds has been set. Object "+this+", actualBounds: "+this.vb.toString());this.kt(f,this.vb);al(this,!1);N.free(f)};t=Y.prototype;t.uh=function(){};
function bl(a,b,c,d,e){a.vb.h(b,c,d,e);if(!a.desiredSize.o()){var f=a.pc;c=a.Vg;b=c.right+c.left;var g=c.top+c.bottom;c=f.width+b;f=f.height+g;d+=b;e+=g;b=$k(a,!0);c===d&&f===e&&(b=Zg);switch(b){case Zg:if(c>d||f>e)jj(a,!0),a.measure(c>d?d:c,f>e?e:f,0,0);break;case be:jj(a,!0);a.measure(d,e,0,0);break;case Lk:jj(a,!0);a.measure(d,f,0,0);break;case Mk:jj(a,!0),a.measure(c,e,0,0)}}}
t.kt=function(a,b){var c=this.part;null!==c&&null!==c.diagram&&(c.selectionObject!==this&&c.resizeObject!==this&&c.rotateObject!==this||cl(c,!0),this.R(),xc(a,b)||(c.zh(),this.Fo(c)))};t.Fo=function(a){null!==this.portId&&(cl(a,!0),a instanceof V&&dl(a,this))};
t.hc=function(a,b){if(this.visible){var c=this instanceof X&&(this.type===X.TableRow||this.type===X.TableColumn),d=this.vb;if(c||0!==d.width&&0!==d.height&&!isNaN(d.x)&&!isNaN(d.y)){var e=this.opacity;if(0!==e){var f=1;1!==e&&(f=a.globalAlpha,a.globalAlpha=f*e);if(!this.ax(a,b))if(c)el(this,a,b);else{this instanceof S&&this.ik(!1);c=this.transform;var g=this.panel;0!==(this.H&4096)===!0&&fl(this);var h=this.part,k=!1,l=0;if(h&&b.De("drawShadows")&&(k=h.isShadowed)){var m=h.ui;l=Math.max(m.y,m.x)*
b.scale*b.Mb}if(!(m=b.oj)){var n=this.naturalBounds;m=this.Eh;var p=m.m11,q=m.m21,r=m.dx,u=m.m12,x=m.m22,v=m.dy,y,z=y=0;m=y*p+z*q+r;var B=y*u+z*x+v;y=n.width+l;z=0;var C=y*p+z*q+r;y=y*u+z*x+v;m=Math.min(m,C);B=Math.min(B,y);var G=Math.max(m,C)-m;var K=Math.max(B,y)-B;y=n.width+l;z=n.height+l;C=y*p+z*q+r;y=y*u+z*x+v;m=Math.min(m,C);B=Math.min(B,y);G=Math.max(m+G,C)-m;K=Math.max(B+K,y)-B;y=0;z=n.height+l;C=y*p+z*q+r;y=y*u+z*x+v;m=Math.min(m,C);B=Math.min(B,y);G=Math.max(m+G,C)-m;K=Math.max(B+K,y)-B;
l=b.viewportBounds;n=l.x;p=l.y;m=!(m>l.$+n||n>G+m||B>l.Z+p||p>K+B)}if(m){m=0!==(this.H&256);a.clipInsteadOfFill&&(m=!1);this instanceof vh&&(a.font=this.font);if(m){B=g.Zd()?g.naturalBounds:g.actualBounds;null!==this.kd?(n=this.kd,G=n.x,K=n.y,l=n.width,n=n.height):(G=Math.max(d.x,B.x),K=Math.max(d.y,B.y),l=Math.min(d.right,B.right)-G,n=Math.min(d.bottom,B.bottom)-K);if(G>d.width+d.x||d.x>B.width+B.x){1!==e&&(a.globalAlpha=f);return}a.save();a.beginPath();a.rect(G,K,l,n);a.clip()}if(this.tg()){if(!h.isVisible()){1!==
e&&(a.globalAlpha=f);return}k&&(B=h.ui,a.Nv(B.x*b.scale*b.Mb,B.y*b.scale*b.Mb,h.Od),gl(a),a.shadowColor=h.Kj)}!0===this.shadowVisible?gl(a):!1===this.shadowVisible&&hl(a);h=this.naturalBounds;null!==this.dc&&(ji(this,a,this.dc,!0,!0,h,d),this.dc instanceof il&&this.dc.type===jl?(a.beginPath(),a.rect(d.x,d.y,d.width,d.height),a.Td(this.dc)):a.fillRect(d.x,d.y,d.width,d.height));a.transform(c.m11,c.m12,c.m21,c.m22,c.dx,c.dy);k&&(null!==g&&0!==(g.H&512)||null!==g&&(g.type===X.Auto||g.type===X.Spot)&&
g.zb()!==this)&&null===this.shadowVisible&&hl(a);null!==this.ib&&(l=this.naturalBounds,G=B=0,K=l.width,l=l.height,n=0,this instanceof W&&(l=this.qa.bounds,B=l.x,G=l.y,K=l.width,l=l.height,n=this.strokeWidth),ji(this,a,this.ib,!0,!1,h,d),this.ib instanceof il&&this.ib.type===jl?(a.beginPath(),a.rect(B-n/2,G-n/2,K+n,l+n),a.Td(this.ib)):a.fillRect(B-n/2,G-n/2,K+n,l+n));k&&(null!==this.ib||null!==this.dc||null!==g&&0!==(g.H&512)||null!==g&&(g.type===X.Auto||g.type===X.Spot)&&g.zb()!==this)?(kl(this,!0),
null===this.shadowVisible&&hl(a)):kl(this,!1);this.Ei(a,b);k&&0!==(this.H&512)===!0&&gl(a);this.tg()&&k&&hl(a);m?(a.restore(),this instanceof X?a.Rc(!0):a.Rc(!1)):c.bt()||(b=1/(c.m11*c.m22-c.m12*c.m21),a.transform(c.m22*b,-c.m12*b,-c.m21*b,c.m11*b,b*(c.m21*c.dy-c.m22*c.dx),b*(c.m12*c.dx-c.m11*c.dy)))}}1!==e&&(a.globalAlpha=f)}}}};t.ax=function(){return!1};
function el(a,b,c){var d=a.vb,e=a.qc;null!==a.dc&&(ji(a,b,a.dc,!0,!0,e,d),a.dc instanceof il&&a.dc.type===jl?(b.beginPath(),b.rect(d.x,d.y,d.width,d.height),b.Td(a.dc)):b.fillRect(d.x,d.y,d.width,d.height));null!==a.ib&&(ji(a,b,a.ib,!0,!1,e,d),a.ib instanceof il&&a.ib.type===jl?(b.beginPath(),b.rect(d.x,d.y,d.width,d.height),b.Td(a.ib)):b.fillRect(d.x,d.y,d.width,d.height));a.Ei(b,c)}t.Ei=function(){};
function ji(a,b,c,d,e,f,g){if(null!==c){var h=1,k=1;if("string"===typeof c)d?b.fillStyle=c:b.strokeStyle=c;else if(c.type===ll)d?b.fillStyle=c.color:b.strokeStyle=c.color;else{h=f.width;k=f.height;e&&(h=g.width,k=g.height);if((f=b instanceof ml)&&c.ce&&(c.type===nl||c.Hk===h&&c.Kt===k))var l=c.ce;else{var m=0,n=0,p=0,q=0,r=0,u=0;u=r=0;e&&(r=g.x,u=g.y);m=c.start.x*h+c.start.offsetX;n=c.start.y*k+c.start.offsetY;p=c.end.x*h+c.end.offsetX;q=c.end.y*k+c.end.offsetY;m+=r;p+=r;n+=u;q+=u;if(c.type===ol)l=
b.createLinearGradient(m,n,p,q);else if(c.type===jl)u=isNaN(c.endRadius)?Math.max(h,k)/2:c.endRadius,isNaN(c.startRadius)?(r=0,u=Math.max(h,k)/2):r=c.startRadius,l=b.createRadialGradient(m,n,r,p,q,u);else if(c.type===nl)try{l=b.createPattern(c.pattern,"repeat")}catch(v){l=null}if(c.type!==nl&&(e=c.colorStops,null!==e))for(e=e.iterator;e.next();)l.addColorStop(e.key,e.value);if(f&&(c.ce=l,null!==l&&(c.Hk=h,c.Kt=k),null===l&&c.type===nl&&-1!==c.Hk)){c.Hk=-1;var x=a.diagram;null!==x&&-1===c.Hk&&ua(function(){x.tf()},
600)}}d?b.fillStyle=l:b.strokeStyle=l}}}t.rg=function(a){if(a instanceof X)a:{if(this!==a&&null!==a)for(var b=this.panel;null!==b;){if(b===a){a=!0;break a}b=b.panel}a=!1}else a=!1;return a};t.sf=function(){if(!this.visible)return!1;var a=this.panel;return null!==a?a.sf():!0};t.sg=function(){for(var a=this instanceof X?this:this.panel;null!==a&&a.isEnabled;)a=a.panel;return null===a};
function fl(a){if(0!==(a.H&2048)===!0){var b=a.tb;b.reset();if(!a.vb.o()||!a.pc.o()){pl(a,!1);return}b.translate(a.vb.x-a.pc.x,a.vb.y-a.pc.y);if(1!==a.scale||0!==a.angle){var c=a.naturalBounds;Yk(a,b,c.x,c.y,c.width,c.height)}pl(a,!1);ql(a,!0)}0!==(a.H&4096)===!0&&(b=a.panel,null===b?(a.Eh.set(a.tb),a.Tk=a.scale,ql(a,!1)):null!==b.rd&&(c=a.Eh,c.reset(),b.Zd()?c.multiply(b.Eh):null!==b.panel&&c.multiply(b.panel.Eh),c.multiply(a.tb),a.Tk=a.scale*b.Tk,ql(a,!1)))}
function Yk(a,b,c,d,e,f){1!==a.scale&&b.scale(a.scale);if(0!==a.angle){var g=dd;a.tg()&&a.locationSpot.gb()&&(g=a.locationSpot);var h=H.alloc();if(a instanceof T&&a.locationObject!==a)for(c=a.locationObject,d=c.naturalBounds,h.sk(d.x,d.y,d.width,d.height,g),c.Of.ta(h),h.offset(-c.measuredBounds.x,-c.measuredBounds.y),g=c.panel;null!==g&&g!==a;)g.Of.ta(h),h.offset(-g.measuredBounds.x,-g.measuredBounds.y),g=g.panel;else h.sk(c,d,e,f,g);b.rotate(a.angle,h.x,h.y);H.free(h)}}
t.s=function(a){void 0===a&&(a=!1);if(!0!==oj(this)){jj(this,!0);al(this,!0);var b=this.panel;null===b||a||b.s()}};t.dm=function(){!0!==oj(this)&&(jj(this,!0),al(this,!0))};function rl(a){if(!1===pj(a)){var b=a.panel;null!==b?b.s():a.tg()&&(b=a.diagram,null!==b&&(b.Dd.add(a),a instanceof V&&a.ed(),b.cc()));al(a,!0)}}t.fl=function(){0!==(this.H&2048)===!1&&(pl(this,!0),ql(this,!0))};t.iv=function(){ql(this,!0)};t.R=function(){var a=this.part;null!==a&&a.R()};
function $k(a,b){var c=a.stretch,d=a.panel;if(null!==d&&d.type===X.Table)return sl(a,d.getRowDefinition(a.row),d.getColumnDefinition(a.column),b);if(null!==d&&d.type===X.Auto&&d.zb()===a)return tl(a,be,b);if(c===Kk){if(null!==d){if(d.type===X.Spot&&d.zb()===a)return tl(a,be,b);c=d.defaultStretch;return c===Kk?tl(a,Zg,b):tl(a,c,b)}return tl(a,Zg,b)}return tl(a,c,b)}
function sl(a,b,c,d){var e=a.stretch;if(e!==Kk)return tl(a,e,d);var f=e=null;switch(b.stretch){case Mk:f=!0;break;case be:f=!0}switch(c.stretch){case Lk:e=!0;break;case be:e=!0}b=a.panel.defaultStretch;null===e&&(e=b===Lk||b===be);null===f&&(f=b===Mk||b===be);return!0===e&&!0===f?tl(a,be,d):!0===e?tl(a,Lk,d):!0===f?tl(a,Mk,d):tl(a,Zg,d)}
function tl(a,b,c){if(c)return b;if(b===Zg)return Zg;c=a.desiredSize;if(c.o())return Zg;a=a.angle;if(!isNaN(c.width))if(90!==a&&270!==a){if(b===Lk)return Zg;if(b===be)return Mk}else{if(b===Mk)return Zg;if(b===be)return Lk}if(!isNaN(c.height))if(90!==a&&270!==a){if(b===Mk)return Zg;if(b===be)return Lk}else{if(b===Lk)return Zg;if(b===be)return Mk}return b}function kl(a,b){a.H=b?a.H|512:a.H&-513}function Uk(a){return 0!==(a.H&1024)}function ul(a,b){a.H=b?a.H|1024:a.H&-1025}
function pl(a,b){a.H=b?a.H|2048:a.H&-2049}function ql(a,b){a.H=b?a.H|4096:a.H&-4097}function oj(a){return 0!==(a.H&8192)}function jj(a,b){a.H=b?a.H|8192:a.H&-8193}function pj(a){return 0!==(a.H&16384)}function al(a,b){a.H=b?a.H|16384:a.H&-16385}t.Ui=function(a){this.ag=a};t.Lv=function(){};t.Kv=function(a){this.sa=a;rl(this);return!0};t.vt=function(a,b){this.sa.h(a,b);this.fl()};
function vl(a){var b=a.part;if(b instanceof V&&(null!==a.portId||a===b.port)){var c=b.diagram;null===c||c.undoManager.isUndoingRedoing||dl(b,a)}}function wl(a){var b=a.diagram;null===b||b.undoManager.isUndoingRedoing||(a instanceof X?a instanceof V?a.ed():a.ym(a,function(a){vl(a)}):vl(a))}t.bind=function(a){a.Qd=this;var b=this.Ii();null!==b&&xl(b)&&A("Cannot add a Binding to a template that has already been copied: "+a);null===this.bb&&(this.bb=new E);this.bb.add(a)};
t.Ii=function(){for(var a=this instanceof X?this:this.panel;null!==a;){if(null!==a.Rh)return a;a=a.panel}return null};t.Mv=function(a){vj(this,a)};
function yl(a,b){for(var c=1;c<arguments.length;++c);c=arguments;var d=null,e=null;if("function"===typeof a)e=a;else if("string"===typeof a){var f=zl.J(a);"function"===typeof f?(c=Ga(arguments),d=f(c),Ca(d)||A('GraphObject.make invoked object builder "'+a+'", but it did not return an Object')):e=w.go[a]}null===d&&(void 0!==e&&null!==e&&e.constructor||A("GraphObject.make requires a class function or GoJS class name or name of an object builder, not: "+a),d=new e);e=1;if(d instanceof P&&1<c.length){f=
d;var g=c[1];if("string"===typeof g||g instanceof HTMLDivElement)Ai(f,g),e++}for(;e<c.length;e++)f=c[e],void 0===f?A("Undefined value at argument "+e+" for object being constructed by GraphObject.make: "+d):Al(d,f);return d}
function Al(a,b){if("string"===typeof b)if(a instanceof vh)a.text=b;else if(a instanceof W)a.figure=b;else if(a instanceof Yj)a.source=b;else if(a instanceof X)b=Bl.J(b),null!==b&&(a.type=b);else if(a instanceof il){var c=qb(il,b);null!==c?a.type=c:A("Unknown Brush type as an argument to GraphObject.make: "+b)}else a instanceof $d?(b=qb($d,b),null!==b&&(a.type=b)):a instanceof Ve&&(b=qb(Ve,b),null!==b&&(a.type=b));else if(b instanceof Y)a instanceof X||A("A GraphObject can only be added to a Panel, not to: "+
a),a.add(b);else if(b instanceof Pj){var d;b.isRow&&"function"===typeof a.getRowDefinition?d=a.getRowDefinition(b.index):b.isRow||"function"!==typeof a.getColumnDefinition||(d=a.getColumnDefinition(b.index));d instanceof Pj?d.Vl(b):A("A RowColumnDefinition can only be added to an object that implements getRowDefinition/getColumnDefinition, not to: "+a)}else if(b instanceof D)"function"===typeof a.fb&&a.fb(b);else if(b instanceof Cl)a.type=b;else if(b instanceof Ei)a instanceof Y?a.bind(b):a instanceof
Pj?a.bind(b):A("A Binding can only be applied to a GraphObject or RowColumnDefinition, not to: "+a);else if(b instanceof Ue)a instanceof $d?a.figures.add(b):A("A PathFigure can only be added to a Geometry, not to: "+a);else if(b instanceof Ve)a instanceof Ue?a.segments.add(b):A("A PathSegment can only be added to a PathFigure, not to: "+a);else if(b instanceof yi)a instanceof P?a.layout=b:a instanceof ig?a.layout=b:A("A Layout can only be assigned to a Diagram or a Group, not to: "+a);else if(Array.isArray(b))for(c=
0;c<b.length;c++)Al(a,b[c]);else if("object"===typeof b&&null!==b)if(a instanceof il){c=new tb;for(var e in b)d=parseFloat(e),isNaN(d)?c[e]=b[e]:a.addColorStop(d,b[e]);vj(a,c)}else if(a instanceof Pj){void 0!==b.row?(e=b.row,(void 0===e||null===e||Infinity===e||isNaN(e)||0>e)&&A("Must specify non-negative integer row for RowColumnDefinition "+b+", not: "+e),a.isRow=!0,a.index=e):void 0!==b.column&&(e=b.column,(void 0===e||null===e||Infinity===e||isNaN(e)||0>e)&&A("Must specify non-negative integer column for RowColumnDefinition "+
b+", not: "+e),a.isRow=!1,a.index=e);e=new tb;for(c in b)"row"!==c&&"column"!==c&&(e[c]=b[c]);vj(a,e)}else vj(a,b);else A('Unknown initializer "'+b+'" for object being constructed by GraphObject.make: '+a)}function Dl(a,b){zl.add(a,b)}function El(a,b,c){void 0===c&&(c=null);var d=a[1];if("function"===typeof c?c(d):"string"===typeof d)return a.splice(1,1),d;if(void 0===b)throw Error("no "+("function"===typeof c?"satisfactory":"string")+" argument for GraphObject builder "+a[0]);return b}
pa.Object.defineProperties(Y.prototype,{shadowVisible:{get:function(){return this.Cl},set:function(a){var b=this.Cl;b!==a&&(this.Cl=a,this.R(),this.g("shadowVisible",b,a))}},enabledChanged:{get:function(){return null!==this.O?this.O.An:null},set:function(a){Rk(this);var b=this.O.An;b!==a&&(this.O.An=a,this.g("enabledChanged",b,a))}},segmentOrientation:{get:function(){return this.Al},set:function(a){var b=this.Al;
b!==a&&(this.Al=a,this.s(),this.g("segmentOrientation",b,a),a===Og&&(this.angle=0))}},segmentIndex:{get:function(){return this.ip},set:function(a){a=Math.round(a);var b=this.ip;b!==a&&(this.ip=a,this.s(),this.g("segmentIndex",b,a))}},segmentFraction:{get:function(){return this.yl},set:function(a){isNaN(a)?a=0:0>a?a=0:1<a&&(a=1);var b=this.yl;b!==a&&(this.yl=a,this.s(),this.g("segmentFraction",b,a))}},segmentOffset:{
get:function(){return this.zl},set:function(a){var b=this.zl;b.A(a)||(this.zl=a=a.I(),this.s(),this.g("segmentOffset",b,a))}},stretch:{get:function(){return this.ve},set:function(a){var b=this.ve;b!==a&&(this.ve=a,this.s(),this.g("stretch",b,a))}},name:{get:function(){return this.Ua},set:function(a){var b=this.Ua;b!==a&&(this.Ua=a,null!==this.part&&(this.part.xj=null),this.g("name",b,a))}},opacity:{get:function(){return this.lb},
set:function(a){var b=this.lb;b!==a&&((0>a||1<a)&&ya(a,"0 <= value <= 1",Y,"opacity"),this.lb=a,this.g("opacity",b,a),a=this.diagram,b=this.part,null!==a&&null!==b&&a.R(zj(b,b.actualBounds)))}},visible:{get:function(){return 0!==(this.H&1)},set:function(a){var b=0!==(this.H&1);b!==a&&(this.H^=1,this.g("visible",b,a),b=this.panel,null!==b?b.s():this.tg()&&this.Lb(a),this.R(),wl(this))}},pickable:{get:function(){return 0!==(this.H&2)},set:function(a){var b=
0!==(this.H&2);b!==a&&(this.H^=2,this.g("pickable",b,a))}},fromLinkableDuplicates:{get:function(){return 0!==(this.H&4)},set:function(a){var b=0!==(this.H&4);b!==a&&(this.H^=4,this.g("fromLinkableDuplicates",b,a))}},fromLinkableSelfNode:{get:function(){return 0!==(this.H&8)},set:function(a){var b=0!==(this.H&8);b!==a&&(this.H^=8,this.g("fromLinkableSelfNode",b,a))}},toLinkableDuplicates:{get:function(){return 0!==
(this.H&16)},set:function(a){var b=0!==(this.H&16);b!==a&&(this.H^=16,this.g("toLinkableDuplicates",b,a))}},toLinkableSelfNode:{get:function(){return 0!==(this.H&32)},set:function(a){var b=0!==(this.H&32);b!==a&&(this.H^=32,this.g("toLinkableSelfNode",b,a))}},isPanelMain:{get:function(){return 0!==(this.H&64)},set:function(a){var b=0!==(this.H&64);b!==a&&(this.H^=64,this.s(),this.g("isPanelMain",b,a))}},isActionable:{
get:function(){return 0!==(this.H&128)},set:function(a){var b=0!==(this.H&128);b!==a&&(this.H^=128,this.g("isActionable",b,a))}},areaBackground:{get:function(){return this.dc},set:function(a){var b=this.dc;b!==a&&(null!==a&&Fl(a,"GraphObject.areaBackground"),a instanceof il&&a.freeze(),this.dc=a,this.R(),this.g("areaBackground",b,a))}},background:{get:function(){return this.ib},set:function(a){var b=this.ib;b!==a&&(null!==a&&Fl(a,"GraphObject.background"),
a instanceof il&&a.freeze(),this.ib=a,this.R(),this.g("background",b,a))}},part:{get:function(){if(this.tg())return this;if(null!==this.Cj)return this.Cj;var a;for(a=this.panel;a;){if(a instanceof T)return this.Cj=a;a=a.panel}return null}},svg:{get:function(){return this.As},set:function(a){this.As=a}},panel:{get:function(){return this.ag}},layer:{get:function(){var a=this.part;
return null!==a?a.layer:null}},diagram:{get:function(){var a=this.part;return null!==a?a.diagram:null}},position:{get:function(){return this.sa},set:function(a){var b=a.x,c=a.y,d=this.sa,e=d.x,f=d.y;(e===b||isNaN(e)&&isNaN(b))&&(f===c||isNaN(f)&&isNaN(c))?this.Lv():(a=a.I(),this.Kv(a,d)&&this.g("position",d,a))}},actualBounds:{get:function(){return this.vb}},scale:{get:function(){return this.Ca},
set:function(a){var b=this.Ca;b!==a&&(0>=a&&A("GraphObject.scale for "+this+" must be greater than zero, not: "+a),this.Ca=a,this.s(),this.g("scale",b,a))}},angle:{get:function(){return this.zc},set:function(a){var b=this.zc;b!==a&&(a%=360,0>a&&(a+=360),b!==a&&(this.zc=a,wl(this),this.s(),this.g("angle",b,a)))}},desiredSize:{get:function(){return this.Nc},set:function(a){var b=a.width,c=a.height,d=this.Nc,e=d.width,f=d.height;(e===b||isNaN(e)&&
isNaN(b))&&(f===c||isNaN(f)&&isNaN(c))||(this.Nc=a=a.I(),this.s(),this instanceof W&&this.ac(),this.g("desiredSize",d,a),Uk(this)&&(a=this.part,null!==a&&(Vk(this,a,"width"),Vk(this,a,"height"))))}},width:{get:function(){return this.Nc.width},set:function(a){var b=this.Nc.width;b===a||isNaN(b)&&isNaN(a)||(b=this.Nc,this.Nc=a=(new L(a,this.Nc.height)).freeze(),this.s(),this instanceof W&&this.ac(),this.g("desiredSize",b,a),Uk(this)&&(a=this.part,null!==a&&Vk(this,a,"width")))}},
height:{get:function(){return this.Nc.height},set:function(a){var b=this.Nc.height;b===a||isNaN(b)&&isNaN(a)||(b=this.Nc,this.Nc=a=(new L(this.Nc.width,a)).freeze(),this.s(),this instanceof W&&this.ac(),this.g("desiredSize",b,a),Uk(this)&&(a=this.part,null!==a&&Vk(this,a,"height")))}},minSize:{get:function(){return this.Qf},set:function(a){var b=this.Qf;b.A(a)||(a=a.copy(),isNaN(a.width)&&(a.width=0),isNaN(a.height)&&(a.height=0),a.freeze(),
this.Qf=a,this.s(),this.g("minSize",b,a))}},maxSize:{get:function(){return this.Pf},set:function(a){var b=this.Pf;b.A(a)||(a=a.copy(),isNaN(a.width)&&(a.width=Infinity),isNaN(a.height)&&(a.height=Infinity),a.freeze(),this.Pf=a,this.s(),this.g("maxSize",b,a))}},measuredBounds:{get:function(){return this.pc}},naturalBounds:{get:function(){return this.qc}},margin:{get:function(){return this.Vg},
set:function(a){"number"===typeof a&&(a=new Ic(a));var b=this.Vg;b.A(a)||(this.Vg=a=a.I(),this.s(),this.g("margin",b,a))}},transform:{get:function(){0!==(this.H&2048)===!0&&fl(this);return this.tb}},rd:{get:function(){0!==(this.H&4096)===!0&&fl(this);return this.Eh}},alignment:{get:function(){return this.wb},set:function(a){var b=this.wb;b.A(a)||(a.jc()&&!a.Kb()&&A("GraphObject.alignment for "+this+" must be a real Spot or Spot.Default, not: "+
a),this.wb=a=a.I(),rl(this),this.g("alignment",b,a))}},column:{get:function(){return this.Eg},set:function(a){a=Math.round(a);var b=this.Eg;b!==a&&(0>a&&ya(a,">= 0",Y,"column"),this.Eg=a,this.s(),this.g("column",b,a))}},columnSpan:{get:function(){return this.Ym},set:function(a){a=Math.round(a);var b=this.Ym;b!==a&&(1>a&&ya(a,">= 1",Y,"columnSpan"),this.Ym=a,this.s(),this.g("columnSpan",b,a))}},row:{get:function(){return this.cp},
set:function(a){a=Math.round(a);var b=this.cp;b!==a&&(0>a&&ya(a,">= 0",Y,"row"),this.cp=a,this.s(),this.g("row",b,a))}},rowSpan:{get:function(){return this.ep},set:function(a){a=Math.round(a);var b=this.ep;b!==a&&(1>a&&ya(a,">= 1",Y,"rowSpan"),this.ep=a,this.s(),this.g("rowSpan",b,a))}},spanAllocation:{get:function(){return this.tp},set:function(a){var b=this.tp;b!==a&&(this.tp=a,this.s(),this.g("spanAllocation",b,a))}},alignmentFocus:{
get:function(){return this.yk},set:function(a){var b=this.yk;b.A(a)||(this.yk=a=a.I(),this.s(),this.g("alignmentFocus",b,a))}},portId:{get:function(){return this.Qo},set:function(a){var b=this.Qo;if(b!==a){var c=this.part;null===c||c instanceof V||(A("Cannot set portID on a Link: "+a),c=null);null!==b&&null!==c&&Gl(c,this);this.Qo=a;null!==a&&null!==c&&(c.yh=!0,Hl(c,this));this.g("portId",b,a)}}},toSpot:{get:function(){return null!==
this.P?this.P.nh:Sc},set:function(a){this.Hc();var b=this.P.nh;b.A(a)||(a=a.I(),this.P.nh=a,this.g("toSpot",b,a),vl(this))}},toEndSegmentLength:{get:function(){return null!==this.P?this.P.lh:10},set:function(a){this.Hc();var b=this.P.lh;b!==a&&(0>a&&ya(a,">= 0",Y,"toEndSegmentLength"),this.P.lh=a,this.g("toEndSegmentLength",b,a),vl(this))}},toShortLength:{get:function(){return null!==this.P?this.P.mh:0},set:function(a){this.Hc();var b=this.P.mh;
b!==a&&(this.P.mh=a,this.g("toShortLength",b,a),vl(this))}},toLinkable:{get:function(){return null!==this.P?this.P.Bp:null},set:function(a){this.Hc();var b=this.P.Bp;b!==a&&(this.P.Bp=a,this.g("toLinkable",b,a))}},toMaxLinks:{get:function(){return null!==this.P?this.P.Cp:Infinity},set:function(a){this.Hc();var b=this.P.Cp;b!==a&&(0>a&&ya(a,">= 0",Y,"toMaxLinks"),this.P.Cp=a,this.g("toMaxLinks",b,a))}},fromSpot:{
get:function(){return null!==this.P?this.P.Ng:Sc},set:function(a){this.Hc();var b=this.P.Ng;b.A(a)||(a=a.I(),this.P.Ng=a,this.g("fromSpot",b,a),vl(this))}},fromEndSegmentLength:{get:function(){return null!==this.P?this.P.Lg:10},set:function(a){this.Hc();var b=this.P.Lg;b!==a&&(0>a&&ya(a,">= 0",Y,"fromEndSegmentLength"),this.P.Lg=a,this.g("fromEndSegmentLength",b,a),vl(this))}},fromShortLength:{get:function(){return null!==this.P?this.P.Mg:
0},set:function(a){this.Hc();var b=this.P.Mg;b!==a&&(this.P.Mg=a,this.g("fromShortLength",b,a),vl(this))}},fromLinkable:{get:function(){return null!==this.P?this.P.Cn:null},set:function(a){this.Hc();var b=this.P.Cn;b!==a&&(this.P.Cn=a,this.g("fromLinkable",b,a))}},fromMaxLinks:{get:function(){return null!==this.P?this.P.Dn:Infinity},set:function(a){this.Hc();var b=this.P.Dn;b!==a&&(0>a&&ya(a,">= 0",Y,"fromMaxLinks"),this.P.Dn=a,this.g("fromMaxLinks",
b,a))}},cursor:{get:function(){return this.hn},set:function(a){var b=this.hn;b!==a&&(this.hn=a,this.g("cursor",b,a))}},click:{get:function(){return null!==this.O?this.O.Bf:null},set:function(a){Rk(this);var b=this.O.Bf;b!==a&&(this.O.Bf=a,this.g("click",b,a))}},doubleClick:{get:function(){return null!==this.O?this.O.Gf:null},set:function(a){Rk(this);var b=this.O.Gf;b!==a&&(this.O.Gf=a,this.g("doubleClick",b,
a))}},contextClick:{get:function(){return null!==this.O?this.O.Cf:null},set:function(a){Rk(this);var b=this.O.Cf;b!==a&&(this.O.Cf=a,this.g("contextClick",b,a))}},mouseEnter:{get:function(){return null!==this.O?this.O.Sf:null},set:function(a){Rk(this);var b=this.O.Sf;b!==a&&(this.O.Sf=a,this.g("mouseEnter",b,a))}},mouseLeave:{get:function(){return null!==this.O?this.O.Vf:null},set:function(a){Rk(this);var b=
this.O.Vf;b!==a&&(this.O.Vf=a,this.g("mouseLeave",b,a))}},mouseOver:{get:function(){return null!==this.O?this.O.Wf:null},set:function(a){Rk(this);var b=this.O.Wf;b!==a&&(this.O.Wf=a,this.g("mouseOver",b,a))}},mouseHover:{get:function(){return null!==this.O?this.O.Uf:null},set:function(a){Rk(this);var b=this.O.Uf;b!==a&&(this.O.Uf=a,this.g("mouseHover",b,a))}},mouseHold:{get:function(){return null!==this.O?this.O.Tf:
null},set:function(a){Rk(this);var b=this.O.Tf;b!==a&&(this.O.Tf=a,this.g("mouseHold",b,a))}},mouseDragEnter:{get:function(){return null!==this.O?this.O.so:null},set:function(a){Rk(this);var b=this.O.so;b!==a&&(this.O.so=a,this.g("mouseDragEnter",b,a))}},mouseDragLeave:{get:function(){return null!==this.O?this.O.to:null},set:function(a){Rk(this);var b=this.O.to;b!==a&&(this.O.to=a,this.g("mouseDragLeave",b,a))}},mouseDrop:{
get:function(){return null!==this.O?this.O.Rf:null},set:function(a){Rk(this);var b=this.O.Rf;b!==a&&(this.O.Rf=a,this.g("mouseDrop",b,a))}},actionDown:{get:function(){return null!==this.O?this.O.Im:null},set:function(a){Rk(this);var b=this.O.Im;b!==a&&(this.O.Im=a,this.g("actionDown",b,a))}},actionMove:{get:function(){return null!==this.O?this.O.Jm:null},set:function(a){Rk(this);var b=this.O.Jm;b!==a&&(this.O.Jm=a,this.g("actionMove",
b,a))}},actionUp:{get:function(){return null!==this.O?this.O.Km:null},set:function(a){Rk(this);var b=this.O.Km;b!==a&&(this.O.Km=a,this.g("actionUp",b,a))}},actionCancel:{get:function(){return null!==this.O?this.O.Hm:null},set:function(a){Rk(this);var b=this.O.Hm;b!==a&&(this.O.Hm=a,this.g("actionCancel",b,a))}},toolTip:{get:function(){return null!==this.O?this.O.hg:null},set:function(a){Rk(this);var b=this.O.hg;
b!==a&&(this.O.hg=a,this.g("toolTip",b,a))}},contextMenu:{get:function(){return null!==this.O?this.O.Df:null},set:function(a){Rk(this);var b=this.O.Df;b!==a&&(this.O.Df=a,this.g("contextMenu",b,a))}}});Y.prototype.setProperties=Y.prototype.Mv;Y.prototype.findTemplateBinder=Y.prototype.Ii;Y.prototype.bind=Y.prototype.bind;Y.prototype.isEnabledObject=Y.prototype.sg;Y.prototype.isVisibleObject=Y.prototype.sf;Y.prototype.isContainedBy=Y.prototype.rg;
Y.prototype.getNearestIntersectionPoint=Y.prototype.Tc;Y.prototype.getLocalPoint=Y.prototype.Vs;Y.prototype.getDocumentScale=Y.prototype.Ce;Y.prototype.getDocumentAngle=Y.prototype.Li;Y.prototype.getDocumentBounds=Y.prototype.Yp;Y.prototype.getDocumentPoint=Y.prototype.ma;Y.prototype.intersectsRect=Y.prototype.Ic;Y.prototype.containedInRect=Y.prototype.vh;Y.prototype.containsRect=Y.prototype.mf;Y.prototype.containsPoint=Y.prototype.da;Y.prototype.raiseChanged=Y.prototype.g;
Y.prototype.raiseChangedEvent=Y.prototype.ab;Y.prototype.addCopyProperty=Y.prototype.Ow;var zl=null;Y.className="GraphObject";zl=new Lb;
Dl("Button",function(){function a(a,b){return null!==a.diagram.Rb(a.documentPoint,function(a){for(;null!==a.panel&&!a.isActionable;)a=a.panel;return a},function(a){return a===b})}var b=yl(X,X.Auto,{isActionable:!0,enabledChanged:function(a,b){if(a instanceof X){var c=a.$a("ButtonBorder");null!==c&&(c.fill=b?a._buttonFillNormal:a._buttonFillDisabled)}},cursor:"pointer",_buttonFillNormal:"#F5F5F5",_buttonStrokeNormal:"#BDBDBD",_buttonFillOver:"#E0E0E0",_buttonStrokeOver:"#9E9E9E",_buttonFillPressed:"#BDBDBD",
_buttonStrokePressed:"#9E9E9E",_buttonFillDisabled:"#E5E5E5"},yl(W,{name:"ButtonBorder",figure:"RoundedRectangle",spot1:new O(0,0,2.76142374915397,2.761423749153969),spot2:new O(1,1,-2.76142374915397,-2.761423749153969),parameter1:2,parameter2:2,fill:"#F5F5F5",stroke:"#BDBDBD"}));b.mouseEnter=function(a,b){if(b.sg()&&b instanceof X&&(a=b.$a("ButtonBorder"),a instanceof W)){var c=b._buttonFillOver;b._buttonFillNormal=a.fill;a.fill=c;c=b._buttonStrokeOver;b._buttonStrokeNormal=a.stroke;a.stroke=c}};
b.mouseLeave=function(a,b){b.sg()&&b instanceof X&&(a=b.$a("ButtonBorder"),a instanceof W&&(a.fill=b._buttonFillNormal,a.stroke=b._buttonStrokeNormal))};b.actionDown=function(a,b){if(b.sg()&&b instanceof X&&null!==b._buttonFillPressed&&0===a.button){var c=b.$a("ButtonBorder");if(c instanceof W){a=a.diagram;var d=a.skipsUndoManager;a.skipsUndoManager=!0;var g=b._buttonFillPressed;b._buttonFillOver=c.fill;c.fill=g;g=b._buttonStrokePressed;b._buttonStrokeOver=c.stroke;c.stroke=g;a.skipsUndoManager=d}}};
b.actionUp=function(b,d){if(d.sg()&&d instanceof X&&null!==d._buttonFillPressed&&0===b.button){var c=d.$a("ButtonBorder");if(c instanceof W){var f=b.diagram,g=f.skipsUndoManager;f.skipsUndoManager=!0;a(b,d)?(c.fill=d._buttonFillOver,c.stroke=d._buttonStrokeOver):(c.fill=d._buttonFillNormal,c.stroke=d._buttonStrokeNormal);f.skipsUndoManager=g}}};b.actionCancel=function(b,d){if(d.sg()&&d instanceof X&&null!==d._buttonFillPressed){var c=d.$a("ButtonBorder");if(c instanceof W){var f=b.diagram,g=f.skipsUndoManager;
f.skipsUndoManager=!0;a(b,d)?(c.fill=d._buttonFillOver,c.stroke=d._buttonStrokeOver):(c.fill=d._buttonFillNormal,c.stroke=d._buttonStrokeNormal);f.skipsUndoManager=g}}};b.actionMove=function(b,d){if(d.sg()&&d instanceof X&&null!==d._buttonFillPressed){var c=b.diagram;if(0===c.firstInput.button&&(c.currentTool.standardMouseOver(),a(b,d)&&(b=d.$a("ButtonBorder"),b instanceof W))){var f=c.skipsUndoManager;c.skipsUndoManager=!0;var g=d._buttonFillPressed;b.fill!==g&&(b.fill=g);g=d._buttonStrokePressed;
b.stroke!==g&&(b.stroke=g);c.skipsUndoManager=f}}};return b});
Dl("TreeExpanderButton",function(){var a=yl("Button",{_treeExpandedFigure:"MinusLine",_treeCollapsedFigure:"PlusLine"},yl(W,{name:"ButtonIcon",figure:"MinusLine",stroke:"#424242",strokeWidth:2,desiredSize:ic},(new Ei("figure","isTreeExpanded",function(a,c){c=c.panel;return a?c._treeExpandedFigure:c._treeCollapsedFigure})).kq()),{visible:!1},(new Ei("visible","isTreeLeaf",function(a){return!a})).kq());a.click=function(a,c){c=c.part;c instanceof pf&&(c=c.adornedPart);if(c instanceof V){var b=c.diagram;
if(null!==b){b=b.commandHandler;if(c.isTreeExpanded){if(!b.canCollapseTree(c))return}else if(!b.canExpandTree(c))return;a.handled=!0;c.isTreeExpanded?b.collapseTree(c):b.expandTree(c)}}};return a});
Dl("SubGraphExpanderButton",function(){var a=yl("Button",{_subGraphExpandedFigure:"MinusLine",_subGraphCollapsedFigure:"PlusLine"},yl(W,{name:"ButtonIcon",figure:"MinusLine",stroke:"#424242",strokeWidth:2,desiredSize:ic},(new Ei("figure","isSubGraphExpanded",function(a,c){c=c.panel;return a?c._subGraphExpandedFigure:c._subGraphCollapsedFigure})).kq()));a.click=function(a,c){c=c.part;c instanceof pf&&(c=c.adornedPart);if(c instanceof ig){var b=c.diagram;if(null!==b){b=b.commandHandler;if(c.isSubGraphExpanded){if(!b.canCollapseSubGraph(c))return}else if(!b.canExpandSubGraph(c))return;
a.handled=!0;c.isSubGraphExpanded?b.collapseSubGraph(c):b.expandSubGraph(c)}}};return a});Dl("ToolTip",function(){return yl(pf,X.Auto,{isShadowed:!0,shadowColor:"rgba(0, 0, 0, .4)",shadowOffset:new H(0,3),shadowBlur:5},yl(W,{name:"Border",figure:"RoundedRectangle",parameter1:1,parameter2:1,fill:"#F5F5F5",stroke:"#F0F0F0",spot1:new O(0,0,4,6),spot2:new O(1,1,-4,-4)}))});
Dl("ContextMenu",function(){return yl(pf,X.Vertical,{background:"#F5F5F5",isShadowed:!0,shadowColor:"rgba(0, 0, 0, .4)",shadowOffset:new H(0,3),shadowBlur:5},new Ei("background","",function(a){return null!==a.adornedPart&&null!==a.placeholder?null:"#F5F5F5"}))});Dl("ContextMenuButton",function(){var a=yl("Button");a.stretch=Lk;var b=a.$a("ButtonBorder");b instanceof W&&(b.figure="Rectangle",b.strokeWidth=0,b.spot1=new O(0,0,2,3),b.spot2=new O(1,1,-2,-2));return a});
Dl("PanelExpanderButton",function(a){var b=El(a,"COLLAPSIBLE"),c=yl("Button",{_buttonExpandedFigure:"M0 0 M0 6 L4 2 8 6 M8 8",_buttonCollapsedFigure:"M0 0 M0 2 L4 6 8 2 M8 8",_buttonFillNormal:"rgba(0, 0, 0, 0)",_buttonStrokeNormal:null,_buttonFillOver:"rgba(0, 0, 0, .2)",_buttonStrokeOver:null,_buttonFillPressed:"rgba(0, 0, 0, .4)",_buttonStrokePressed:null},yl(W,{name:"ButtonIcon",strokeWidth:2},(new Ei("geometryString","visible",function(a){return a?c._buttonExpandedFigure:c._buttonCollapsedFigure})).kq(b)));
a=c.$a("ButtonBorder");a instanceof W&&(a.stroke=null,a.fill="rgba(0, 0, 0, 0)");c.click=function(a,c){a=c.diagram;if(null!==a&&!a.isReadOnly){var d=c.Ii();null===d&&(d=c.part);null!==d&&(c=d.$a(b),null!==c&&(a.Aa("Collapse/Expand Panel"),c.visible=!c.visible,a.Za("Collapse/Expand Panel")))}};return c});
Dl("CheckBoxButton",function(a){var b=El(a);a=yl("Button",{desiredSize:new L(14,14)},yl(W,{name:"ButtonIcon",geometryString:"M0 0 M0 8.85 L4.9 13.75 16.2 2.45 M16.2 16.2",strokeWidth:2,stretch:be,geometryStretch:ah,visible:!1},""!==b?(new Ei("visible",b)).px():[]));a.click=function(a,d){if(d instanceof X){var c=a.diagram;if(!(null===c||c.isReadOnly||""!==b&&c.model.isReadOnly)){a.handled=!0;var f=d.$a("ButtonIcon");c.Aa("checkbox");f.visible=!f.visible;"function"===typeof d._doClick&&d._doClick(a,
d);c.Za("checkbox")}}};return a});
Dl("CheckBox",function(a){a=El(a);a=yl("CheckBoxButton",a,{name:"Button",isActionable:!1,margin:new Ic(0,1,0,0)});var b=yl(X,"Horizontal",a,{isActionable:!0,cursor:a.cursor,margin:1,_buttonFillNormal:a._buttonFillNormal,_buttonStrokeNormal:a._buttonStrokeNormal,_buttonFillOver:a._buttonFillOver,_buttonStrokeOver:a._buttonStrokeOver,_buttonFillPressed:a._buttonFillPressed,_buttonStrokePressed:a._buttonStrokePressed,_buttonFillDisabled:a._buttonFillDisabled,mouseEnter:a.mouseEnter,mouseLeave:a.mouseLeave,
actionDown:a.actionDown,actionUp:a.actionUp,actionCancel:a.actionCancel,actionMove:a.actionMove,click:a.click,_buttonClick:a.click});a.mouseEnter=null;a.mouseLeave=null;a.actionDown=null;a.actionUp=null;a.actionCancel=null;a.actionMove=null;a.click=null;return b});Y.None=Zg=new D(Y,"None",0);Y.Default=Kk=new D(Y,"Default",0);Y.Vertical=Mk=new D(Y,"Vertical",4);Y.Horizontal=Lk=new D(Y,"Horizontal",5);Y.Fill=be=new D(Y,"Fill",3);Y.Uniform=ah=new D(Y,"Uniform",1);
Y.UniformToFill=Nk=new D(Y,"UniformToFill",2);Y.FlipVertical=Ok=new D(Y,"FlipVertical",1);Y.FlipHorizontal=Pk=new D(Y,"FlipHorizontal",2);Y.FlipBoth=Qk=new D(Y,"FlipBoth",3);Y.make=yl;Y.getBuilders=function(){var a=new Lb,b;for(b in zl)if(b!==b.toLowerCase()){var c=zl.J(b);"function"===typeof c&&a.add(b,c)}a.freeze();return a};Y.defineBuilder=Dl;Y.takeBuilderArgument=El;
function Sk(){this.An=this.Df=this.hg=this.Hm=this.Km=this.Jm=this.Im=this.Rf=this.to=this.so=this.Tf=this.Uf=this.Wf=this.Vf=this.Sf=this.Cf=this.Gf=this.Bf=null}Sk.prototype.copy=function(){var a=new Sk;a.Bf=this.Bf;a.Gf=this.Gf;a.Cf=this.Cf;a.Sf=this.Sf;a.Vf=this.Vf;a.Wf=this.Wf;a.Uf=this.Uf;a.Tf=this.Tf;a.so=this.so;a.to=this.to;a.Rf=this.Rf;a.Im=this.Im;a.Jm=this.Jm;a.Km=this.Km;a.Hm=this.Hm;a.hg=this.hg;a.Df=this.Df;a.An=this.An;return a};Sk.className="GraphObjectEventHandlers";
function Il(){this.Oa=[1,0,0,1,0,0]}Il.prototype.copy=function(){var a=new Il;a.Oa[0]=this.Oa[0];a.Oa[1]=this.Oa[1];a.Oa[2]=this.Oa[2];a.Oa[3]=this.Oa[3];a.Oa[4]=this.Oa[4];a.Oa[5]=this.Oa[5];return a};Il.prototype.translate=function(a,b){this.Oa[4]+=this.Oa[0]*a+this.Oa[2]*b;this.Oa[5]+=this.Oa[1]*a+this.Oa[3]*b};Il.prototype.scale=function(a,b){this.Oa[0]*=a;this.Oa[1]*=a;this.Oa[2]*=b;this.Oa[3]*=b};Il.className="STransform";
function Jl(a){this.type=a;this.r2=this.y2=this.x2=this.r1=this.y1=this.x1=0;this.Vw=[];this.pattern=null}Jl.prototype.addColorStop=function(a,b){this.Vw.push({offset:a,color:b})};Jl.className="SGradient";
function yj(a,b){this.ownerDocument=a=void 0===b?w.document:b;this.Bz="http://www.w3.org/2000/svg";void 0!==a&&(this.Ga=this.ub("svg",{width:"1px",height:"1px",viewBox:"0 0 1 1"}),this.Ga.setAttributeNS("http://www.w3.org/2000/xmlns/","xmlns","http://www.w3.org/2000/svg"),this.Ga.setAttributeNS("http://www.w3.org/2000/xmlns/","xmlns:xlink","http://www.w3.org/1999/xlink"));this.Tp=null;this.context=new Kl(this)}
yj.prototype.resize=function(a,b,c,d){return this.width!==a||this.height!==b?(this.style.width=c+"px",this.style.height=d+"px",this.Ga.setAttributeNS(null,"width",c+"px"),this.Ga.setAttributeNS(null,"height",d+"px"),this.Ga.setAttributeNS(null,"viewBox","0 0 "+c+" "+d),this.context.fu.firstElementChild.setAttributeNS(null,"width",c+"px"),this.context.fu.firstElementChild.setAttributeNS(null,"height",d+"px"),!0):!1};
yj.prototype.ub=function(a,b,c){a=this.ownerDocument.createElementNS(this.Bz,a);if(Ca(b))for(var d in b)a.setAttributeNS("href"===d?"http://www.w3.org/1999/xlink":"",d,b[d]);void 0!==c&&(a.textContent=c);return a};yj.prototype.getBoundingClientRect=function(){return this.Ga.getBoundingClientRect()};yj.prototype.focus=function(){this.Ga.focus()};yj.prototype.$w=function(){this.ownerDocument=null};
pa.Object.defineProperties(yj.prototype,{width:{get:function(){return this.Ga.width.baseVal.value},set:function(a){this.Ga.width=a}},height:{get:function(){return this.Ga.height.baseVal.value},set:function(a){this.Ga.height=a}},style:{get:function(){return this.Ga.style}}});yj.className="SVGSurface";
function Kl(a){this.tk=a;this.svg=a.Ga;this.stack=[];this.wc=[];this.fillStyle="#000000";this.font="10px sans-serif";this.globalAlpha=1;this.lineCap="butt";this.lineDashOffset=0;this.lineJoin="miter";this.lineWidth=1;this.miterLimit=10;this.shadowBlur=0;this.shadowColor="rgba(0, 0, 0, 0)";this.shadowOffsetY=this.shadowOffsetX=0;this.strokeStyle="#000000";this.textAlign="start";this.clipInsteadOfFill=!1;this.Od=this.pp=this.op=0;this.eq=null;this.path=[];this.bu=!1;this.gh=null;this.hh=0;this.Sd=new Il;
Ll(this,1,0,0,1,0,0);var b=Gb++,c=this.ub("clipPath",{id:"mainClip"+b});c.appendChild(this.ub("rect",{x:0,y:0,width:a.width,height:a.height}));this.fu=c;this.tk.Ga.appendChild(c);this.wc[0].setAttributeNS(null,"clip-path","url(#mainClip"+b+")");this.Dz={}}t=Kl.prototype;
t.reset=function(){this.stack=[];this.wc=[];this.fillStyle="#000000";this.font="10px sans-serif";this.globalAlpha=1;this.lineCap="butt";this.lineDashOffset=0;this.lineJoin="miter";this.lineWidth=1;this.miterLimit=10;this.shadowBlur=0;this.shadowColor="rgba(0, 0, 0, 0)";this.shadowOffsetY=this.shadowOffsetX=0;this.strokeStyle="#000000";this.textAlign="start";this.clipInsteadOfFill=!1;this.Od=this.pp=this.op=0;this.eq=null;this.path=[];this.Sd=new Il;Ll(this,1,0,0,1,0,0);var a=Gb++,b=this.ub("clipPath",
{id:"mainClip"+a});b.appendChild(this.ub("rect",{x:0,y:0,width:this.tk.width,height:this.tk.height}));this.fu=b;this.tk.Ga.appendChild(b);this.wc[0].setAttributeNS(null,"clip-path","url(#mainClip"+a+")")};
t.arc=function(a,b,c,d,e,f,g,h){var k=2*Math.PI,l=k-1E-6,m=c*Math.cos(d),n=c*Math.sin(d),p=a+m,q=b+n,r=f?0:1;d=f?d-e:e-d;(1E-6<Math.abs(g-p)||1E-6<Math.abs(h-q))&&this.path.push(["L",p,+q]);0>d&&(d=d%k+k);d>l?(this.path.push(["A",c,c,0,1,r,a-m,b-n]),this.path.push(["A",c,c,0,1,r,p,q])):1E-6<d&&this.path.push(["A",c,c,0,+(d>=Math.PI),r,a+c*Math.cos(e),b+c*Math.sin(e)])};t.beginPath=function(){this.path=[]};t.bezierCurveTo=function(a,b,c,d,e,f){this.path.push(["C",a,b,c,d,e,f])};t.clearRect=function(){};
t.clip=function(){this.addPath("clipPath",this.path,this.Sd);this.addPath("clipPath",this.path,new Il)};t.closePath=function(){this.path.push(["z"])};t.createLinearGradient=function(a,b,c,d){var e=new Jl("linear");e.x1=a;e.y1=b;e.x2=c;e.y2=d;return e};
t.createPattern=function(a){var b="";a instanceof HTMLCanvasElement&&(b=a.toDataURL());a instanceof HTMLImageElement&&(b=a.src);var c=this.Dz;if(c[b])return"url(#"+c[b]+")";var d="PATTERN"+Gb++,e={x:0,y:0,width:a.width,height:a.height,href:b};a=this.ub("pattern",{width:a.width,height:a.height,id:d,patternUnits:"userSpaceOnUse"});a.appendChild(this.ub("image",e));this.svg.appendChild(a);c[b]=d;return"url(#"+d+")"};
t.createRadialGradient=function(a,b,c,d,e,f){var g=new Jl("radial");g.x1=a;g.y1=b;g.r1=c;g.x2=d;g.y2=e;g.r2=f;return g};
t.drawImage=function(a,b,c,d,e,f,g,h,k){var l="";a instanceof HTMLCanvasElement&&(l=a.toDataURL());a instanceof HTMLImageElement&&(l=a.src);var m=a instanceof HTMLImageElement?a.naturalWidth:a.width,n=a instanceof HTMLImageElement?a.naturalHeight:a.height;void 0===d&&(f=b,g=c,h=d=m,k=e=n);d=d||0;e=e||0;f=f||0;g=g||0;h=h||0;k=k||0;l={x:0,y:0,width:m||d,height:n||e,href:l,preserveAspectRatio:"xMidYMid slice"};I.ca(d,h)&&I.ca(e,k)||(l.preserveAspectRatio="none");a="";h/=d;k/=e;if(0!==f||0!==g)a+=" translate("+
f+", "+g+")";if(1!==h||1!==k)a+=" scale("+h+", "+k+")";if(0!==b||0!==c)a+=" translate("+-b+", "+-c+")";if(0!==b||0!==c||d!==m||e!==n)f="CLIP"+Gb++,g=this.ub("clipPath",{id:f}),g.appendChild(this.ub("rect",{x:b,y:c,width:d,height:e})),this.svg.appendChild(g),l["clip-path"]="url(#"+f+")";Ml(this,"image",l,this.Sd,a);this.addElement("image",l)};t.fill=function(){this.addPath("fill",this.path,this.Sd)};t.Td=function(){this.clipInsteadOfFill?this.clip():this.fill()};
t.fillRect=function(a,b,c,d){a=[a,b,c,d];a={x:a[0],y:a[1],width:a[2],height:a[3]};Ml(this,"fill",a,this.Sd);this.addElement("rect",a)};t.fillText=function(a,b,c){a=[a,b,c];b=this.textAlign;"left"===b?b="start":"right"===b?b="end":"center"===b&&(b="middle");b={x:a[1],y:a[2],style:"font: "+this.font,"text-anchor":b};Ml(this,"fill",b,this.Sd);this.addElement("text",b,a[0])};t.lineTo=function(a,b){this.path.push(["L",a,b])};t.moveTo=function(a,b){this.path.push(["M",a,b])};
t.quadraticCurveTo=function(a,b,c,d){this.path.push(["Q",a,b,c,d])};t.rect=function(a,b,c,d){this.path.push(["M",a,b],["L",a+c,b],["L",a+c,b+d],["L",a,b+d],["z"])};
t.restore=function(){this.Sd=this.stack.pop();this.path=this.stack.pop();var a=this.stack.pop();this.fillStyle=a.fillStyle;this.font=a.font;this.globalAlpha=a.globalAlpha;this.lineCap=a.lineCap;this.lineDashOffset=a.lineDashOffset;this.lineJoin=a.lineJoin;this.lineWidth=a.lineWidth;this.miterLimit=a.miterLimit;this.shadowBlur=a.shadowBlur;this.shadowColor=a.shadowColor;this.shadowOffsetX=a.shadowOffsetX;this.shadowOffsetY=a.shadowOffsetY;this.strokeStyle=a.strokeStyle;this.textAlign=a.textAlign};
t.save=function(){this.stack.push({fillStyle:this.fillStyle,font:this.font,globalAlpha:this.globalAlpha,lineCap:this.lineCap,lineDashOffset:this.lineDashOffset,lineJoin:this.lineJoin,lineWidth:this.lineWidth,miterLimit:this.miterLimit,shadowBlur:this.shadowBlur,shadowColor:this.shadowColor,shadowOffsetX:this.shadowOffsetX,shadowOffsetY:this.shadowOffsetY,strokeStyle:this.strokeStyle,textAlign:this.textAlign});for(var a=[],b=0;b<this.path.length;b++)a.push(this.path[b]);this.stack.push(a);this.stack.push(this.Sd.copy())};
t.setTransform=function(a,b,c,d,e,f){1===a&&0===b&&0===c&&1===d&&0===e&&0===f||Ll(this,a,b,c,d,e,f)};t.scale=function(a,b){this.Sd.scale(a,b)};t.translate=function(a,b){this.Sd.translate(a,b)};t.transform=function(){};t.stroke=function(){this.addPath("stroke",this.path,this.Sd)};t.Yi=function(){this.clipInsteadOfFill||this.stroke()};t.ub=function(a,b,c){return this.tk.ub(a,b,c)};
t.addElement=function(a,b,c){a=this.ub(a,b,c);0<this.wc.length?this.wc[this.wc.length-1].appendChild(a):this.svg.appendChild(a);return this.eq=a};
function Ml(a,b,c,d,e){1!==a.globalAlpha&&(c.opacity=a.globalAlpha);"fill"===b?(a.fillStyle instanceof Jl?c.fill=Nl(a,a.fillStyle):(/^rgba\(/.test(a.fillStyle)&&(b=/^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(a.fillStyle),c.fill="rgb("+b[1]+","+b[2]+","+b[3]+")",c["fill-opacity"]=b[4]),c.fill=a.fillStyle),c.stroke="none"):"stroke"===b&&(c.fill="none",a.strokeStyle instanceof Jl?c.stroke=Nl(a,a.strokeStyle):(/^rgba\(/.test(a.strokeStyle)&&(b=/^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(a.strokeStyle),
c.stroke="rgb("+b[1]+","+b[2]+","+b[3]+")",c["stroke-opacity"]=b[4]),c.stroke=a.strokeStyle),c["stroke-width"]=a.lineWidth,c["stroke-linecap"]=a.lineCap,c["stroke-linejoin"]=a.lineJoin,c["stroke-miterlimit"]=a.miterLimit);a=d.Oa;a="matrix("+a[0]+", "+a[1]+", "+a[2]+", "+a[3]+", "+a[4]+", "+a[5]+")";void 0!==e&&(a+=e);c.transform=a}
function Nl(a,b){var c="GRAD"+Gb++;if("linear"===b.type)var d=a.ub("linearGradient",{x1:b.x1,x2:b.x2,y1:b.y1,y2:b.y2,id:c,gradientUnits:"userSpaceOnUse"});else if("radial"===b.type)d=a.ub("radialGradient",{x1:b.x1,x2:b.x2,y1:b.y1,y2:b.y2,r1:b.r1,r2:b.r2,id:c});else throw Error("invalid gradient");var e=b.Vw;b=e.length;for(var f=[],g=0;g<b;g++){var h=e[g],k=h.color;h={offset:h.offset,"stop-color":k};/^rgba\(/.test(k)&&(k=/^\s*rgba\s*\(([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\s*,\s*([^,\s]+)\)\s*$/i.exec(k),
h["stop-color"]="rgb("+k[1]+","+k[2]+","+k[3]+")",h["stop-opacity"]=k[4]);f.push(h)}f.sort(function(a,b){return a.offset>b.offset?1:-1});for(e=0;e<b;e++)d.appendChild(a.ub("stop",f[e]));a.svg.appendChild(d);return"url(#"+c+")"}
t.addPath=function(a,b,c){for(var d=[],e=0;e<b.length;e++){var f=Ga(b[e]),g=[f.shift()];if("A"===g[0])g.push(f.shift()+","+f.shift(),f.shift(),f.shift()+","+f.shift(),f.shift()+","+f.shift());else for(;f.length;)g.push(f.shift()+","+f.shift());d.push(g.join(" "))}b={d:d.join(" ")};"stroke"===a&&this.bu&&(b["stroke-dasharray"]=this.gh.toString(),b["stroke-dashoffset"]=this.hh);Ml(this,a,b,c);"clipPath"===a?(a="CLIP"+Gb++,c=this.ub("clipPath",{id:a}),c.appendChild(this.ub("path",b)),this.svg.appendChild(c),
0<this.wc.length&&this.wc[this.wc.length-1].setAttributeNS(null,"clip-path","url(#"+a+")")):this.addElement("path",b)};function Ll(a,b,c,d,e,f,g){var h=new Il;h.Oa=[b,c,d,e,f,g];b={};Ml(a,"g",b,h);h=a.addElement("g",b);a.wc.push(h)}
t.tq=function(){if(0!==this.shadowOffsetX||0!==this.shadowOffsetY||0!==this.shadowBlur){var a="SHADOW"+Gb++,b=this.addElement("filter",{id:a,x:"-100%",y:"-100%",width:"300%",height:"300%"},null);var c=this.ub("feGaussianBlur",{"in":"SourceAlpha",result:"blur",gA:this.shadowBlur/2});var d=this.ub("feFlood",{"in":"blur",result:"flood","flood-color":this.shadowColor});var e=this.ub("feComposite",{"in":"flood",in2:"blur",operator:"in",result:"comp"});var f=this.ub("feOffset",{"in":"comp",result:"offsetBlur",
dx:this.shadowOffsetX,dy:this.shadowOffsetY});var g=this.ub("feMerge",{});g.appendChild(this.ub("feMergeNode",{"in":"offsetBlur"}));g.appendChild(this.ub("feMergeNode",{"in":"SourceGraphic"}));b.appendChild(c);b.appendChild(d);b.appendChild(e);b.appendChild(f);b.appendChild(g);0<this.wc.length&&this.wc[this.wc.length-1].setAttributeNS(null,"filter","url(#"+a+")")}};t.Nv=function(a,b,c){this.op=a;this.pp=b;this.Od=c};function hl(a){a.shadowOffsetX=0;a.shadowOffsetY=0;a.shadowBlur=0}
function gl(a){a.shadowOffsetX=a.op;a.shadowOffsetY=a.pp;a.shadowBlur=a.Od}t.Os=function(a,b){this.bu=!0;this.gh=a;this.hh=b};t.Ms=function(){this.bu=!1};t.Rc=function(){};t.Ex=function(){};Kl.prototype.rotate=function(){};Kl.prototype.getImageData=function(){return null};Kl.prototype.measureText=function(){return null};Kl.className="SVGContext";
P.prototype.ht=function(a){var b=new yj(this,w.document);void 0===a&&(a=new tb);var c=this;return rk(this,function(a,e){a=sk(c,a,"SVG",b);a=null!==a?a.svg:null;return"function"===typeof e?(e(a),null):a},a)};P.prototype.makeSvg=P.prototype.ht;P.prototype.mv=function(a){return this.ht(a)};P.prototype.makeSVG=P.prototype.mv;
Y.prototype.ax=function(a,b){if(!(a instanceof Kl))return!1;if(!this.visible)return!0;var c=null,d=a.eq;if(this instanceof X&&(this.type===X.TableRow||this.type===X.TableColumn))return el(this,a,b),!0;var e=this.vb;if(0===e.width||0===e.height||isNaN(e.x)||isNaN(e.y))return!0;var f=this.transform,g=this.panel;0!==(this.H&4096)===!0&&fl(this);var h=0!==(this.H&256),k=!1;this instanceof vh&&(a.font=this.font);if(h){k=g.Zd()?g.naturalBounds:g.actualBounds;if(null!==this.kd){var l=this.kd;var m=l.x;var n=
l.y;var p=l.width;l=l.height}else m=Math.max(e.x,k.x),n=Math.max(e.y,k.y),p=Math.min(e.right,k.right)-m,l=Math.min(e.bottom,k.bottom)-n;if(m>e.width+e.x||e.x>k.width+k.x||n>e.height+e.y||e.y>k.height+k.y)return!0;k=!0;Ll(a,1,0,0,1,0,0);a.save();a.beginPath();a.rect(m,n,p,l);a.clip()}if(this.tg()&&!this.isVisible())return!0;a.Sd.Oa=[1,0,0,1,0,0];this instanceof vh&&1<this.lineCount&&Ll(a,1,0,0,1,0,0);m=!1;this.tg()&&this.isShadowed&&b.De("drawShadows")&&(n=this.ui,a.Nv(n.x*b.scale*b.Mb,n.y*b.scale*
b.Mb,this.Od),gl(a),a.shadowColor=this.Kj);n=!1;this.part&&b.De("drawShadows")&&(n=this.part.isShadowed);!0===this.shadowVisible?(gl(a),!1===m&&n&&(Ll(a,1,0,0,1,0,0),a.tq(),m=!0)):!1===this.shadowVisible&&hl(a);p=this.naturalBounds;null!==this.areaBackground&&(ji(this,a,this.areaBackground,!0,!0,p,e),!1===m&&n&&(Ll(a,1,0,0,1,0,0),a.tq(),m=!0),this.areaBackground instanceof il&&this.areaBackground.type===jl?(a.beginPath(),a.rect(e.x,e.y,e.width,e.height),a.Td(this.areaBackground)):a.fillRect(e.x,e.y,
e.width,e.height));this instanceof X?Ll(a,f.m11,f.m12,f.m21,f.m22,f.dx,f.dy):a.Sd.Oa=[f.m11,f.m12,f.m21,f.m22,f.dx,f.dy];if(null!==this.background){!1===m&&n&&(Ll(a,1,0,0,1,0,0),a.tq(),m=!0);var q=this.naturalBounds;l=f=0;var r=q.width;q=q.height;var u=0;this instanceof W&&(q=this.geometry.bounds,f=q.x,l=q.y,r=q.width,q=q.height,u=this.strokeWidth);ji(this,a,this.background,!0,!1,p,e);this.background instanceof il&&this.background.type===jl?(a.beginPath(),a.rect(f-u/2,l-u/2,r+u,q+u),a.Td(this.background)):
a.fillRect(f-u/2,l-u/2,r+u,q+u)}n&&(null!==this.background||null!==this.areaBackground||null!==g&&0!==(g.H&512)||null!==g&&(g.type===X.Auto||g.type===X.Spot)&&g.zb()!==this)?(kl(this,!0),null===this.shadowVisible&&hl(a)):kl(this,!1);this.Ei(a,b);n&&0!==(this.H&512)===!0&&gl(a);this.tg()&&n&&hl(a);h&&(a.restore(),k&&a.wc.pop());this instanceof X&&(c=a.wc.pop());!0===m&&a.wc.pop();this instanceof vh&&1<this.lineCount&&(c=a.wc.pop());null!==a.tk.Tp&&(null===c&&(d===a.eq?(Ll(a,1,0,0,1,0,0),c=a.wc.pop()):
c=a.eq),a.tk.Tp(this,c));this.svg=c;return!0};function tk(a,b){this.ownerDocument=b=void 0===b?w.document:b;this.Tp=null;b=b.createElement("canvas");b.tabIndex=0;this.Ga=b;this.Ga.innerHTML="This text is displayed if your browser does not support the Canvas HTML element.";this.context=new ml(b);b.C=a}tk.prototype.resize=function(a,b,c,d){return this.width!==a||this.height!==b?(this.width=a,this.height=b,this.style.width=c+"px",this.style.height=d+"px",!0):!1};
tk.prototype.toDataURL=function(a,b){return this.Ga.toDataURL(a,b)};tk.prototype.getBoundingClientRect=function(){return this.Ga.getBoundingClientRect()};tk.prototype.focus=function(){this.Ga.focus()};tk.prototype.$w=function(){this.ownerDocument=this.Ga.C=null};
pa.Object.defineProperties(tk.prototype,{width:{get:function(){return this.Ga.width},set:function(a){this.Ga.width=a}},height:{get:function(){return this.Ga.height},set:function(a){this.Ga.height=a}},style:{get:function(){return this.Ga.style}}});tk.className="CanvasSurface";
function ml(a){a.getContext&&a.getContext("2d")||A("Browser does not support HTML Canvas Element");this.W=a.getContext("2d");this.Ht=this.Jt=this.It="";this.Xm=!1;this.Od=this.pp=this.op=0}t=ml.prototype;t.Ex=function(a){this.W.imageSmoothingEnabled=a};t.arc=function(a,b,c,d,e,f){this.W.arc(a,b,c,d,e,f)};t.beginPath=function(){this.W.beginPath()};t.bezierCurveTo=function(a,b,c,d,e,f){this.W.bezierCurveTo(a,b,c,d,e,f)};t.clearRect=function(a,b,c,d){this.W.clearRect(a,b,c,d)};t.clip=function(){this.W.clip()};
t.closePath=function(){this.W.closePath()};t.createLinearGradient=function(a,b,c,d){return this.W.createLinearGradient(a,b,c,d)};t.createPattern=function(a,b){return this.W.createPattern(a,b)};t.createRadialGradient=function(a,b,c,d,e,f){return this.W.createRadialGradient(a,b,c,d,e,f)};t.drawImage=function(a,b,c,d,e,f,g,h,k){void 0===d?this.W.drawImage(a,b,c):this.W.drawImage(a,b,c,d,e,f,g,h,k)};t.fill=function(){this.W.fill()};t.fillRect=function(a,b,c,d){this.W.fillRect(a,b,c,d)};
t.fillText=function(a,b,c){this.W.fillText(a,b,c)};t.getImageData=function(a,b,c,d){return this.W.getImageData(a,b,c,d)};t.lineTo=function(a,b){this.W.lineTo(a,b)};t.measureText=function(a){return this.W.measureText(a)};t.moveTo=function(a,b){this.W.moveTo(a,b)};t.quadraticCurveTo=function(a,b,c,d){this.W.quadraticCurveTo(a,b,c,d)};t.rect=function(a,b,c,d){this.W.rect(a,b,c,d)};t.restore=function(){this.W.restore()};ml.prototype.rotate=function(a){this.W.rotate(a)};t=ml.prototype;t.save=function(){this.W.save()};
t.setTransform=function(a,b,c,d,e,f){this.W.setTransform(a,b,c,d,e,f)};t.scale=function(a,b){this.W.scale(a,b)};t.stroke=function(){this.W.stroke()};t.transform=function(a,b,c,d,e,f){1===a&&0===b&&0===c&&1===d&&0===e&&0===f||this.W.transform(a,b,c,d,e,f)};t.translate=function(a,b){this.W.translate(a,b)};
t.Td=function(a){if(a instanceof il&&a.type===jl){var b=a.Hk;a=a.Kt;a>b?(this.scale(b/a,1),this.translate((a-b)/2,0)):b>a&&(this.scale(1,a/b),this.translate(0,(b-a)/2));this.Xm?this.clip():this.fill();a>b?(this.translate(-(a-b)/2,0),this.scale(1/(b/a),1)):b>a&&(this.translate(0,-(b-a)/2),this.scale(1,1/(a/b)))}else this.Xm?this.clip():this.fill()};t.Yi=function(){this.Xm||this.stroke()};t.Nv=function(a,b,c){this.op=a;this.pp=b;this.Od=c};
t.Os=function(a,b){var c=this.W;void 0!==c.setLineDash&&(c.setLineDash(a),c.lineDashOffset=b)};t.Ms=function(){var a=this.W;void 0!==a.setLineDash&&(a.setLineDash(Ol),a.lineDashOffset=0)};t.Rc=function(a){a&&(this.It="");this.Ht=this.Jt=""};
pa.Object.defineProperties(ml.prototype,{fillStyle:{get:function(){return this.W.fillStyle},set:function(a){this.Ht!==a&&(this.Ht=this.W.fillStyle=a)}},font:{get:function(){return this.W.font},set:function(a){this.It!==a&&(this.It=this.W.font=a)}},globalAlpha:{get:function(){return this.W.globalAlpha},set:function(a){this.W.globalAlpha=a}},lineCap:{get:function(){return this.W.lineCap},
set:function(a){this.W.lineCap=a}},lineDashOffset:{get:function(){return this.W.lineDashOffset},set:function(a){this.W.lineDashOffset=a}},lineJoin:{get:function(){return this.W.lineJoin},set:function(a){this.W.lineJoin=a}},lineWidth:{get:function(){return this.W.lineWidth},set:function(a){this.W.lineWidth=a}},miterLimit:{get:function(){return this.W.miterLimit},set:function(a){this.W.miterLimit=
a}},shadowBlur:{get:function(){return this.W.shadowBlur},set:function(a){this.W.shadowBlur=a}},shadowColor:{get:function(){return this.W.shadowColor},set:function(a){this.W.shadowColor=a}},shadowOffsetX:{get:function(){return this.W.shadowOffsetX},set:function(a){this.W.shadowOffsetX=a}},shadowOffsetY:{get:function(){return this.W.shadowOffsetY},set:function(a){this.W.shadowOffsetY=
a}},strokeStyle:{get:function(){return this.W.strokeStyle},set:function(a){this.Jt!==a&&(this.Jt=this.W.strokeStyle=a)}},textAlign:{get:function(){return this.W.textAlign},set:function(a){this.W.textAlign=a}},imageSmoothingEnabled:{get:function(){return this.W.imageSmoothingEnabled},set:function(a){this.W.imageSmoothingEnabled=a}},clipInsteadOfFill:{get:function(){return this.Xm},
set:function(a){this.Xm=a}}});var Ol=Object.freeze([]);ml.className="CanvasSurfaceContext";function Pl(){this.ba=this.u=this.K=this.l=0}Pl.className="ColorNumbers";function il(a){Ql||(Rl(),Ql=!0);nb(this);this.v=!1;void 0===a?(this.va=ll,this.Gk="black"):"string"===typeof a?(this.va=ll,this.Gk=a):(this.va=a,this.Gk="black");a=this.va;a===ol?(this.Gl=Uc,this.Uk=gd):this.Uk=a===jl?this.Gl=dd:this.Gl=Sc;this.ys=0;this.kr=NaN;this.ce=this.gs=this.be=null;this.Kt=this.Hk=0}
il.prototype.copy=function(){var a=new il;a.va=this.va;a.Gk=this.Gk;a.Gl=this.Gl.I();a.Uk=this.Uk.I();a.ys=this.ys;a.kr=this.kr;null!==this.be&&(a.be=this.be.copy());a.gs=this.gs;return a};t=il.prototype;t.freeze=function(){this.v=!0;null!==this.be&&this.be.freeze();return this};t.ha=function(){Object.isFrozen(this)&&A("cannot thaw constant: "+this);this.v=!1;null!==this.be&&this.be.ha();return this};t.fb=function(a){a.classType===il&&(this.type=a)};
t.toString=function(){var a="Brush(";if(this.type===ll)a+=this.color;else if(a=this.type===ol?a+"Linear ":this.type===jl?a+"Radial ":this.type===nl?a+"Pattern ":a+"(unknown) ",a+=this.start+" "+this.end,null!==this.colorStops)for(var b=this.colorStops.iterator;b.next();)a+=" "+b.key+":"+b.value;return a+")"};
t.addColorStop=function(a,b){this.v&&wa(this);("number"!==typeof a||!isFinite(a)||1<a||0>a)&&ya(a,"0 <= loc <= 1",il,"addColorStop:loc");null===this.be&&(this.be=new Lb);this.be.add(a,b);this.va===ll&&(this.type=ol);this.ce=null;return this};
t.vz=function(a,b){this.v&&wa(this);a=void 0===a||"number"!==typeof a?.2:a;b=void 0===b?Sl:b;if(this.type===ll)Tl(this.color),this.color=Ul(a,b);else if((this.type===ol||this.type===jl)&&null!==this.colorStops)for(var c=this.colorStops.iterator;c.next();)Tl(c.value),this.addColorStop(c.key,Ul(a,b));return this};function Vl(a,b,c){b=void 0===b||"number"!==typeof b?.2:b;c=void 0===c?Sl:c;Tl(a);return Ul(b,c)}
t.vy=function(a,b){this.v&&wa(this);a=void 0===a||"number"!==typeof a?.2:a;b=void 0===b?Sl:b;if(this.type===ll)Tl(this.color),this.color=Ul(-a,b);else if((this.type===ol||this.type===jl)&&null!==this.colorStops)for(var c=this.colorStops.iterator;c.next();)Tl(c.value),this.addColorStop(c.key,Ul(-a,b));return this};function Wl(a,b,c){b=void 0===b||"number"!==typeof b?.2:b;c=void 0===c?Sl:c;Tl(a);return Ul(-b,c)}
function Xl(a,b,c){Tl(a);a=Yl.l;var d=Yl.K,e=Yl.u,f=Yl.ba;Tl(b);void 0===c&&(c=.5);return"rgba("+Math.round((Yl.l-a)*c+a)+", "+Math.round((Yl.K-d)*c+d)+", "+Math.round((Yl.u-e)*c+e)+", "+Math.round((Yl.ba-f)*c+f)+")"}
t.mx=function(){if(this.type===ll)return Zl(this.color);if((this.type===ol||this.type===jl)&&null!==this.colorStops){var a=this.colorStops;if(this.type===jl)return Zl(a.first().value);if(null!==a.get(.5))return Zl(a.get(.5));if(2===a.count)return a=a.Na(),Zl(Xl(a[0].value,a[1].value));for(var b=a.iterator,c=-1,d=-1,e=1,f=1;b.next();){var g=b.key,h=Math.abs(.5-b.key);e>f&&h<e?(c=g,e=h):f>=e&&h<f&&(d=g,f=h)}c>d&&(c=[d,d=c][0]);b=d-c;return Zl(Xl(a.get(c),a.get(d),1-e/b))}return!1};
function Zl(a){if(null===a)return null;if(a instanceof il)return a.mx();Tl(a);return 128>(299*Yl.l+587*Yl.K+114*Yl.u)/1E3}
function Ul(a,b){switch(b){case Sl:var c=100*$l(Yl.l);b=100*$l(Yl.K);var d=100*$l(Yl.u);am.l=.4124564*c+.3575761*b+.1804375*d;am.K=.2126729*c+.7151522*b+.072175*d;am.u=.0193339*c+.119192*b+.9503041*d;am.ba=Yl.ba;c=bm(am.l/cm[0]);b=bm(am.K/cm[1]);d=bm(am.u/cm[2]);dm.l=116*b-16;dm.K=500*(c-b);dm.u=200*(b-d);dm.ba=am.ba;dm.l=Math.min(100,Math.max(0,dm.l+100*a));a=(dm.l+16)/116;c=a-dm.u/200;am.l=cm[0]*em(dm.K/500+a);am.K=cm[1]*(dm.l>fm*gm?Math.pow(a,3):dm.l/fm);am.u=cm[2]*em(c);am.ba=dm.ba;a=-.969266*
am.l+1.8760108*am.K+.041556*am.u;c=.0556434*am.l+-.2040259*am.K+1.0572252*am.u;Yl.l=255*hm((3.2404542*am.l+-1.5371385*am.K+-.4985314*am.u)/100);Yl.K=255*hm(a/100);Yl.u=255*hm(c/100);Yl.ba=am.ba;Yl.l=Math.round(Yl.l);255<Yl.l?Yl.l=255:0>Yl.l&&(Yl.l=0);Yl.K=Math.round(Yl.K);255<Yl.K?Yl.K=255:0>Yl.K&&(Yl.K=0);Yl.u=Math.round(Yl.u);255<Yl.u?Yl.u=255:0>Yl.u&&(Yl.u=0);return"rgba("+Yl.l+", "+Yl.K+", "+Yl.u+", "+Yl.ba+")";case im:b=Yl.l/255;d=Yl.K/255;var e=Yl.u/255,f=Math.max(b,d,e),g=Math.min(b,d,e),h=
f-g;g=(f+g)/2;if(0===h)c=b=0;else{switch(f){case b:c=(d-e)/h%6;break;case d:c=(e-b)/h+2;break;case e:c=(b-d)/h+4}c*=60;0>c&&(c+=360);b=h/(1-Math.abs(2*g-1))}jm.l=Math.round(c);jm.K=Math.round(100*b);jm.u=Math.round(100*g);jm.ba=Yl.ba;jm.u=Math.min(100,Math.max(0,jm.u+100*a));return"hsla("+jm.l+", "+jm.K+"%, "+jm.u+"%, "+jm.ba+")";default:return A("Unknown color space: "+b),"rgba(0, 0, 0, 1)"}}
function Tl(a){Ql||(Rl(),Ql=!0);var b=km;if(null!==b){b.clearRect(0,0,1,1);b.fillStyle="#000000";var c=b.fillStyle;b.fillStyle=a;b.fillStyle!==c?(b.fillRect(0,0,1,1),a=b.getImageData(0,0,1,1).data,Yl.l=a[0],Yl.K=a[1],Yl.u=a[2],Yl.ba=a[3]/255):(b.fillStyle="#FFFFFF",b.fillStyle=a,Yl.l=0,Yl.K=0,Yl.u=0,Yl.ba=1)}}function $l(a){a/=255;return.04045>=a?a/12.92:Math.pow((a+.055)/1.055,2.4)}function hm(a){return.0031308>=a?12.92*a:1.055*Math.pow(a,1/2.4)-.055}
function bm(a){return a>gm?Math.pow(a,1/3):(fm*a+16)/116}function em(a){var b=a*a*a;return b>gm?b:(116*a-16)/fm}function Fl(a,b){"string"!==typeof a&&(a instanceof il||A("Value for "+b+" must be a color string or a Brush, not "+a))}function Rl(){km=th?(new tk(null)).context:null}
pa.Object.defineProperties(il.prototype,{type:{get:function(){return this.va},set:function(a){this.v&&wa(this,a);this.va=a;this.start.jc()&&(a===ol?this.start=Uc:a===jl&&(this.start=dd));this.end.jc()&&(a===ol?this.end=gd:a===jl&&(this.end=dd));this.ce=null}},color:{get:function(){return this.Gk},set:function(a){this.v&&wa(this,a);this.Gk=a;this.ce=null}},start:{get:function(){return this.Gl},set:function(a){this.v&&
wa(this,a);this.Gl=a.I();this.ce=null}},end:{get:function(){return this.Uk},set:function(a){this.v&&wa(this,a);this.Uk=a.I();this.ce=null}},startRadius:{get:function(){return this.ys},set:function(a){this.v&&wa(this,a);0>a&&ya(a,">= zero",il,"startRadius");this.ys=a;this.ce=null}},endRadius:{get:function(){return this.kr},set:function(a){this.v&&wa(this,a);0>a&&ya(a,">= zero",il,"endRadius");this.kr=a;this.ce=
null}},colorStops:{get:function(){return this.be},set:function(a){this.v&&wa(this,a);this.be=a;this.ce=null}},pattern:{get:function(){return this.gs},set:function(a){this.v&&wa(this,a);this.gs=a;this.ce=null}}});il.prototype.isDark=il.prototype.mx;il.prototype.darkenBy=il.prototype.vy;il.prototype.lightenBy=il.prototype.vz;il.prototype.addColorStop=il.prototype.addColorStop;
var gm=216/24389,fm=24389/27,cm=[95.047,100,108.883],km=null,Yl=new Pl,jm=new Pl,am=new Pl,dm=new Pl,Ql=!1;il.className="Brush";var ll;il.Solid=ll=new D(il,"Solid",0);var ol;il.Linear=ol=new D(il,"Linear",1);var jl;il.Radial=jl=new D(il,"Radial",2);var nl;il.Pattern=nl=new D(il,"Pattern",4);var Sl;il.Lab=Sl=new D(il,"Lab",5);var im;il.HSL=im=new D(il,"HSL",6);
il.randomColor=function(a,b){void 0===a&&(a=128);void 0===b&&(b=Math.max(a,255));var c=Math.abs(b-a);b=Math.floor(a+Math.random()*c).toString(16);var d=Math.floor(a+Math.random()*c).toString(16);a=Math.floor(a+Math.random()*c).toString(16);2>b.length&&(b="0"+b);2>d.length&&(d="0"+d);2>a.length&&(a="0"+a);return"#"+b+d+a};
il.isValidColor=function(a){if("black"===a)return!0;if(""===a)return!1;Ql||(Rl(),Ql=!0);var b=km;if(null===b)return!0;b.fillStyle="#000000";var c=b.fillStyle;b.fillStyle=a;if(b.fillStyle!==c)return!0;b.fillStyle="#FFFFFF";c=b.fillStyle;b.fillStyle=a;return b.fillStyle!==c};il.lighten=function(a){return Vl(a)};il.lightenBy=Vl;il.darken=function(a){return Wl(a)};il.darkenBy=Wl;il.mix=Xl;il.isDark=Zl;function Cl(){this.name="Base"}Cl.prototype.measure=function(){};
Cl.prototype.yz=function(a,b,c,d,e){a.measure(b,c,d,e)};Cl.prototype.arrange=function(){};Cl.prototype.ky=function(a,b,c,d,e,f){a.arrange(b,c,d,e,f)};pa.Object.defineProperties(Cl.prototype,{classType:{get:function(){return X}}});Cl.prototype.arrangeElement=Cl.prototype.ky;Cl.prototype.measureElement=Cl.prototype.yz;Cl.className="PanelLayout";function lm(){this.name="Base";this.name="Position"}oa(lm,Cl);
lm.prototype.measure=function(a,b,c,d,e,f,g){var h=d.length;a=mm(a);for(var k=0;k<h;k++){var l=d[k];if(l.visible||l===a){var m=l.margin,n=m.right+m.left;m=m.top+m.bottom;l.measure(b,c,f,g);var p=l.measuredBounds;n=Math.max(p.width+n,0);m=Math.max(p.height+m,0);p=l.position.x;var q=l.position.y;isFinite(p)||(p=0);isFinite(q)||(q=0);l instanceof W&&l.isGeometryPositioned&&(l=l.strokeWidth/2,p-=l,q-=l);Bc(e,p,q,n,m)}}};
lm.prototype.arrange=function(a,b,c){var d=b.length,e=a.padding;a=c.x-e.left;c=c.y-e.top;for(e=0;e<d;e++){var f=b[e],g=f.measuredBounds,h=f.margin,k=f.position.x,l=f.position.y;k=isNaN(k)?-a:k-a;l=isNaN(l)?-c:l-c;if(f instanceof W&&f.isGeometryPositioned){var m=f.strokeWidth/2;k-=m;l-=m}f.visible&&f.arrange(k+h.left,l+h.top,g.width,g.height)}};function nm(){this.name="Base";this.name="Horizontal"}oa(nm,Cl);
nm.prototype.measure=function(a,b,c,d,e,f,g){var h=d.length;b=Ka();f=mm(a);for(var k=0;k<h;k++){var l=d[k];if(l.visible||l===f){var m=$k(l,!1);if(m!==Zg&&m!==Lk)b.push(l);else{l.measure(Infinity,c,0,g);m=l.margin;l=l.measuredBounds;var n=Math.max(l.height+m.top+m.bottom,0);e.width+=Math.max(l.width+m.right+m.left,0);e.height=Math.max(e.height,n)}}}d=b.length;a.desiredSize.height?c=Math.min(a.desiredSize.height,a.maxSize.height):0!==e.height&&(c=Math.min(e.height,a.maxSize.height));for(a=0;a<d;a++)if(k=
b[a],k.visible||k===f)m=k.margin,h=m.right+m.left,m=m.top+m.bottom,k.measure(Infinity,c,0,g),k=k.measuredBounds,m=Math.max(k.height+m,0),e.width+=Math.max(k.width+h,0),e.height=Math.max(e.height,m);Oa(b)};
nm.prototype.arrange=function(a,b,c){for(var d=b.length,e=a.padding,f=e.top,g=a.isOpposite,h=g?c.width:e.left,k=0;k<d;k++){var l=f,m=b[k];if(m.visible){var n=m.measuredBounds,p=m.margin,q=p.top+p.bottom,r=f+e.bottom,u=n.height,x=$k(m,!1);if(isNaN(m.desiredSize.height)&&x===be||x===Mk)u=Math.max(c.height-q-r,0);q=u+q+r;r=m.alignment;r.Kb()&&(r=a.defaultAlignment);r.gb()||(r=dd);g&&(h-=n.width+p.left+p.right);m.arrange(h+r.offsetX+p.left,l+r.offsetY+p.top+(c.height*r.y-q*r.y),n.width,u);g||(h+=n.width+
p.left+p.right)}}};function om(){this.name="Base";this.name="Vertical"}oa(om,Cl);
om.prototype.measure=function(a,b,c,d,e,f){var g=d.length;c=Ka();for(var h=mm(a),k=0;k<g;k++){var l=d[k];if(l.visible||l===h){var m=$k(l,!1);if(m!==Zg&&m!==Mk)c.push(l);else{var n=l.margin;m=n.right+n.left;n=n.top+n.bottom;l.measure(b,Infinity,f,0);l=l.measuredBounds;sc(e,Math.max(e.width,Math.max(l.width+m,0)),e.height+Math.max(l.height+n,0))}}}d=c.length;if(0!==d){a.desiredSize.width?b=Math.min(a.desiredSize.width,a.maxSize.width):0!==e.width&&(b=Math.min(e.width,a.maxSize.width));for(a=0;a<d;a++)if(k=
c[a],k.visible||k===h)l=k.margin,g=l.right+l.left,l=l.top+l.bottom,k.measure(b,Infinity,f,0),k=k.measuredBounds,l=Math.max(k.height+l,0),e.width=Math.max(e.width,Math.max(k.width+g,0)),e.height+=l;Oa(c)}};
om.prototype.arrange=function(a,b,c){for(var d=b.length,e=a.padding,f=e.left,g=a.isOpposite,h=g?c.height:e.top,k=0;k<d;k++){var l=f,m=b[k];if(m.visible){var n=m.measuredBounds,p=m.margin,q=p.left+p.right,r=f+e.right,u=n.width,x=$k(m,!1);if(isNaN(m.desiredSize.width)&&x===be||x===Lk)u=Math.max(c.width-q-r,0);q=u+q+r;r=m.alignment;r.Kb()&&(r=a.defaultAlignment);r.gb()||(r=dd);g&&(h-=n.height+p.bottom+p.top);m.arrange(l+r.offsetX+p.left+(c.width*r.x-q*r.x),h+r.offsetY+p.top,u,n.height);g||(h+=n.height+
p.bottom+p.top)}}};function pm(){this.name="Base";this.name="Spot"}oa(pm,Cl);
pm.prototype.measure=function(a,b,c,d,e,f,g){var h=d.length,k=a.zb(),l=k.margin,m=l.right+l.left,n=l.top+l.bottom;k.measure(b,c,f,g);var p=k.measuredBounds;f=p.width;g=p.height;var q=Math.max(f+m,0);var r=Math.max(g+n,0);for(var u=a.isClipping,x=N.allocAt(-l.left,-l.top,q,r),v=!0,y=mm(a),z=0;z<h;z++){var B=d[z];if(B!==k&&(B.visible||B===y)){l=B.margin;q=l.right+l.left;r=l.top+l.bottom;p=$k(B,!1);switch(p){case be:b=f;c=g;break;case Lk:b=f;break;case Mk:c=g}B.measure(b,c,0,0);p=B.measuredBounds;q=
Math.max(p.width+q,0);r=Math.max(p.height+r,0);var C=B.alignment;C.Kb()&&(C=a.defaultAlignment);C.gb()||(C=dd);var G=B.alignmentFocus;G.Kb()&&(G=dd);var K=null;B instanceof X&&""!==B.zg&&(B.arrange(0,0,p.width,p.height),K=B.$a(B.zg),K===B&&(K=null));if(null!==K){l=K.naturalBounds;p=K.margin;for(l=H.allocAt(G.x*l.width-G.offsetX-p.left,G.y*l.height-G.offsetY-p.top);K!==B;)K.transform.ta(l),K=K.panel;B=C.x*f+C.offsetX-l.x;p=C.y*g+C.offsetY-l.y;H.free(l)}else B=C.x*f+C.offsetX-(G.x*p.width+G.offsetX)-
l.left,p=C.y*g+C.offsetY-(G.y*p.height+G.offsetY)-l.top;v?(v=!1,e.h(B,p,q,r)):Bc(e,B,p,q,r)}}v?e.assign(x):u?e.gv(x.x,x.y,x.width,x.height):Bc(e,x.x,x.y,x.width,x.height);N.free(x);p=k.stretch;p===Kk&&(p=$k(k,!1));switch(p){case Zg:return;case be:if(!isFinite(b)&&!isFinite(c))return;break;case Lk:if(!isFinite(b))return;break;case Mk:if(!isFinite(c))return}p=k.measuredBounds;f=p.width;g=p.height;q=Math.max(f+m,0);r=Math.max(g+n,0);l=k.margin;x=N.allocAt(-l.left,-l.top,q,r);for(b=0;b<h;b++)c=d[b],c===
k||!c.visible&&c!==y||(l=c.margin,q=l.right+l.left,r=l.top+l.bottom,p=c.measuredBounds,q=Math.max(p.width+q,0),r=Math.max(p.height+r,0),m=c.alignment,m.Kb()&&(m=a.defaultAlignment),m.gb()||(m=dd),c=c.alignmentFocus,c.Kb()&&(c=dd),v?(v=!1,e.h(m.x*f+m.offsetX-(c.x*p.width+c.offsetX)-l.left,m.y*g+m.offsetY-(c.y*p.height+c.offsetY)-l.top,q,r)):Bc(e,m.x*f+m.offsetX-(c.x*p.width+c.offsetX)-l.left,m.y*g+m.offsetY-(c.y*p.height+c.offsetY)-l.top,q,r));v?e.assign(x):u?e.gv(x.x,x.y,x.width,x.height):Bc(e,x.x,
x.y,x.width,x.height);N.free(x)};
pm.prototype.arrange=function(a,b,c){var d=b.length,e=a.zb(),f=e.measuredBounds,g=f.width;f=f.height;var h=a.padding,k=h.left;h=h.top;var l=k-c.x,m=h-c.y;e.arrange(l,m,g,f);for(var n=0;n<d;n++){var p=b[n];if(p!==e){var q=p.measuredBounds,r=q.width;q=q.height;m=p.alignment;m.Kb()&&(m=a.defaultAlignment);m.gb()||(m=dd);var u=p.alignmentFocus;u.Kb()&&(u=dd);l=null;p instanceof X&&""!==p.zg&&(l=p.$a(p.zg),l===p&&(l=null));if(null!==l){var x=l.naturalBounds;for(u=H.allocAt(u.x*x.width-u.offsetX,u.y*x.height-
u.offsetY);l!==p;)l.transform.ta(u),l=l.panel;l=m.x*g+m.offsetX-u.x;m=m.y*f+m.offsetY-u.y;H.free(u)}else l=m.x*g+m.offsetX-(u.x*r+u.offsetX),m=m.y*f+m.offsetY-(u.y*q+u.offsetY);l-=c.x;m-=c.y;p.visible&&p.arrange(k+l,h+m,r,q)}}};function qm(){this.name="Base";this.name="Auto"}oa(qm,Cl);
qm.prototype.measure=function(a,b,c,d,e,f,g){var h=d.length,k=a.zb(),l=k.margin,m=b,n=c,p=l.right+l.left,q=l.top+l.bottom;k.measure(b,c,f,g);l=k.measuredBounds;var r=0,u=null;k instanceof W&&(u=k,r=u.strokeWidth*u.scale);var x=Math.max(l.width+p,0);l=Math.max(l.height+q,0);var v=rm(k),y=v.x*x+v.offsetX;v=v.y*l+v.offsetY;var z=sm(k),B=z.x*x+z.offsetX;z=z.y*l+z.offsetY;isFinite(b)&&(m=Math.max(Math.abs(y-B)-r,0));isFinite(c)&&(n=Math.max(Math.abs(v-z)-r,0));r=L.alloc();r.h(0,0);a=mm(a);for(z=0;z<h;z++)v=
d[z],v===k||!v.visible&&v!==a||(l=v.margin,x=l.right+l.left,y=l.top+l.bottom,v.measure(m,n,0,0),l=v.measuredBounds,x=Math.max(l.width+x,0),l=Math.max(l.height+y,0),r.h(Math.max(x,r.width),Math.max(l,r.height)));if(1===h)e.width=x,e.height=l,L.free(r);else{v=rm(k);z=sm(k);h=d=0;z.x!==v.x&&z.y!==v.y&&(d=r.width/Math.abs(z.x-v.x),h=r.height/Math.abs(z.y-v.y));L.free(r);r=0;null!==u&&(r=u.strokeWidth*u.scale,$g(u)===ah&&(d=h=Math.max(d,h)));d+=Math.abs(v.offsetX)+Math.abs(z.offsetX)+r;h+=Math.abs(v.offsetY)+
Math.abs(z.offsetY)+r;u=k.stretch;u===Kk&&(u=$k(k,!1));switch(u){case Zg:g=f=0;break;case be:isFinite(b)&&(d=b);isFinite(c)&&(h=c);break;case Lk:isFinite(b)&&(d=b);g=0;break;case Mk:f=0,isFinite(c)&&(h=c)}k.dm();k.measure(d,h,f,g);e.width=k.measuredBounds.width+p;e.height=k.measuredBounds.height+q}};
qm.prototype.arrange=function(a,b){var c=b.length,d=a.zb(),e=d.measuredBounds,f=N.alloc();f.h(0,0,1,1);var g=d.margin,h=g.left;g=g.top;var k=a.padding,l=k.left;k=k.top;d.arrange(l+h,k+g,e.width,e.height);var m=rm(d),n=sm(d),p=m.y*e.height+m.offsetY,q=n.x*e.width+n.offsetX;n=n.y*e.height+n.offsetY;f.x=m.x*e.width+m.offsetX;f.y=p;Bc(f,q,n,0,0);f.x+=h+l;f.y+=g+k;for(e=0;e<c;e++)h=b[e],h!==d&&(l=h.measuredBounds,g=h.margin,k=Math.max(l.width+g.right+g.left,0),m=Math.max(l.height+g.top+g.bottom,0),p=h.alignment,
p.Kb()&&(p=a.defaultAlignment),p.gb()||(p=dd),k=f.width*p.x+p.offsetX-k*p.x+g.left+f.x,g=f.height*p.y+p.offsetY-m*p.y+g.top+f.y,h.visible&&(Cc(f.x,f.y,f.width,f.height,k,g,l.width,l.height)?h.arrange(k,g,l.width,l.height):h.arrange(k,g,l.width,l.height,new N(f.x,f.y,f.width,f.height))));N.free(f)};function tm(){this.name="Base";this.name="Table"}oa(tm,Cl);
tm.prototype.measure=function(a,b,c,d,e,f,g){for(var h=d.length,k=Ka(),l=Ka(),m=0;m<h;m++){var n=d[m],p=n instanceof X?n:null;if(null===p||p.type!==X.TableRow&&p.type!==X.TableColumn||!n.visible)k.push(n);else{l.push(p);for(var q=p.Y.j,r=q.length,u=0;u<r;u++){var x=q[u];p.type===X.TableRow?x.row=n.row:p.type===X.TableColumn&&(x.column=n.column);k.push(x)}}}h=k.length;0===h&&(a.getRowDefinition(0),a.getColumnDefinition(0));for(var v=[],y=0;y<h;y++){var z=k[y];jj(z,!0);al(z,!0);v[z.row]||(v[z.row]=
[]);v[z.row][z.column]||(v[z.row][z.column]=[]);v[z.row][z.column].push(z)}Oa(k);var B=Ka(),C=Ka(),G=Ka(),K={count:0},M={count:0},U=b,R=c,da=a.sb;h=da.length;for(var ja=0;ja<h;ja++){var J=da[ja];void 0!==J&&(J.actual=0)}da=a.nb;h=da.length;for(var Q=0;Q<h;Q++)J=da[Q],void 0!==J&&(J.actual=0);for(var xa=v.length,jb=0,Ha=0;Ha<xa;Ha++)v[Ha]&&(jb=Math.max(jb,v[Ha].length));var Da=Math.min(a.topIndex,xa-1),Fa=Math.min(a.leftIndex,jb-1),Ra=0;xa=v.length;for(var Ub=mm(a),$a=0;$a<xa;$a++)if(v[$a]){jb=v[$a].length;
for(var Xa=a.getRowDefinition($a),Ma=Xa.actual=0;Ma<jb;Ma++)if(v[$a][Ma]){var ha=a.getColumnDefinition(Ma);void 0===B[Ma]&&(ha.actual=0,B[Ma]=!0);for(var fa=v[$a][Ma],hb=fa.length,kb=0;kb<hb;kb++){var Na=fa[kb];if(Na.visible||Na===Ub){var ee=1<Na.rowSpan||1<Na.columnSpan;ee&&($a<Da||Ma<Fa||C.push(Na));var Ld=Na.margin,vb=Ld.right+Ld.left,zf=Ld.top+Ld.bottom;var Eb=sl(Na,Xa,ha,!1);var qe=Na.desiredSize,Wc=!isNaN(qe.height),Vb=!isNaN(qe.width)&&Wc;ee||Eb===Zg||Vb||$a<Da||Ma<Fa||(void 0!==K[Ma]||Eb!==
be&&Eb!==Lk||(K[Ma]=-1,K.count++),void 0!==M[$a]||Eb!==be&&Eb!==Mk||(M[$a]=-1,M.count++),G.push(Na));Na.measure(Infinity,Infinity,0,0);if(!($a<Da||Ma<Fa)){var Md=Na.measuredBounds,Ec=Math.max(Md.width+vb,0),Ie=Math.max(Md.height+zf,0);if(1===Na.rowSpan&&(Eb===Zg||Eb===Lk)){J=a.getRowDefinition($a);var Je=J.tc();Ra=Math.max(Ie-J.actual,0);Ra+Je>R&&(Ra=Math.max(R-Je,0));var Af=0===J.actual;J.actual=J.actual+Ra;R=Math.max(R-(Ra+(Af?Je:0)),0)}if(1===Na.columnSpan&&(Eb===Zg||Eb===Mk)){J=a.getColumnDefinition(Ma);
var gf=J.tc();Ra=Math.max(Ec-J.actual,0);Ra+gf>U&&(Ra=Math.max(U-gf,0));var Bf=0===J.actual;J.actual=J.actual+Ra;U=Math.max(U-(Ra+(Bf?gf:0)),0)}ee&&Na.dm()}}}}}Oa(B);var Wb=0,Ya=0;h=a.columnCount;for(var ab=0;ab<h;ab++){var Mc=a.nb[ab];void 0!==Mc&&(Wb+=Mc.ka,0!==Mc.ka&&(Wb+=Mc.tc()))}h=a.rowCount;for(var Cf=0;Cf<h;Cf++){var jc=a.sb[Cf];void 0!==jc&&(Ya+=jc.ka,0!==jc.ka&&(Ya+=jc.tc()))}U=Math.max(b-Wb,0);var Nd=R=Math.max(c-Ya,0),qc=U;h=G.length;for(var Od=0;Od<h;Od++){var Xb=G[Od],Dh=a.getRowDefinition(Xb.row),
Eh=a.getColumnDefinition(Xb.column),Ke=Xb.measuredBounds,pb=Xb.margin,Df=pb.right+pb.left,Le=pb.top+pb.bottom;K[Xb.column]=0===Eh.actual&&void 0!==K[Xb.column]?Math.max(Ke.width+Df,K[Xb.column]):null;M[Xb.row]=0===Dh.actual&&void 0!==M[Xb.row]?Math.max(Ke.height+Le,M[Xb.row]):null}var Yb=0,Me=0,Nc;for(Nc in M)"count"!==Nc&&(Yb+=M[Nc]);for(Nc in K)"count"!==Nc&&(Me+=K[Nc]);for(var eb=L.alloc(),Ne=0;Ne<h;Ne++){var rb=G[Ne];if(rb.visible||rb===Ub){var rc=a.getRowDefinition(rb.row),lb=a.getColumnDefinition(rb.column),
Xc=0;isFinite(lb.width)?Xc=lb.width:(isFinite(U)&&null!==K[rb.column]?0===Me?Xc=lb.actual+U:Xc=K[rb.column]/Me*qc:null!==K[rb.column]?Xc=U:Xc=lb.actual||U,Xc=Math.max(0,Xc-lb.tc()));var Yc=0;isFinite(rc.height)?Yc=rc.height:(isFinite(R)&&null!==M[rb.row]?0===Yb?Yc=rc.actual+R:Yc=M[rb.row]/Yb*Nd:null!==M[rb.row]?Yc=R:Yc=rc.actual||R,Yc=Math.max(0,Yc-rc.tc()));eb.h(Math.max(lb.minimum,Math.min(Xc,lb.maximum)),Math.max(rc.minimum,Math.min(Yc,rc.maximum)));Eb=sl(rb,rc,lb,!1);switch(Eb){case Lk:eb.height=
Math.max(eb.height,rc.actual+R);break;case Mk:eb.width=Math.max(eb.width,lb.actual+U)}var xd=rb.margin,Bg=xd.right+xd.left,Ef=xd.top+xd.bottom;rb.dm();rb.measure(eb.width,eb.height,lb.minimum,rc.minimum);var Ff=rb.measuredBounds,hf=Math.max(Ff.width+Bg,0),kd=Math.max(Ff.height+Ef,0);isFinite(U)&&(hf=Math.min(hf,eb.width));isFinite(R)&&(kd=Math.min(kd,eb.height));var Pd=0;Pd=rc.actual;rc.actual=Math.max(rc.actual,kd);Ra=rc.actual-Pd;R=Math.max(R-Ra,0);Pd=lb.actual;lb.actual=Math.max(lb.actual,hf);
Ra=lb.actual-Pd;U=Math.max(U-Ra,0)}}Oa(G);var ld=L.alloc(),md=Ka(),ob=Ka();h=C.length;if(0!==h)for(var fb=0;fb<xa;fb++)if(v[fb]){jb=v[fb].length;var nd=a.getRowDefinition(fb);md[fb]=nd.actual;for(var Zc=0;Zc<jb;Zc++)if(v[fb][Zc]){var od=a.getColumnDefinition(Zc);ob[Zc]=od.actual}}for(var vc=0;vc<h;vc++){var Ba=C[vc];if(Ba.visible||Ba===Ub){var $c=a.getRowDefinition(Ba.row),Zb=a.getColumnDefinition(Ba.column);eb.h(Math.max(Zb.minimum,Math.min(b,Zb.maximum)),Math.max($c.minimum,Math.min(c,$c.maximum)));
Eb=sl(Ba,$c,Zb,!1);switch(Eb){case be:0!==ob[Zb.index]&&(eb.width=Math.min(eb.width,ob[Zb.index]));0!==md[$c.index]&&(eb.height=Math.min(eb.height,md[$c.index]));break;case Lk:0!==ob[Zb.index]&&(eb.width=Math.min(eb.width,ob[Zb.index]));break;case Mk:0!==md[$c.index]&&(eb.height=Math.min(eb.height,md[$c.index]))}isFinite(Zb.width)&&(eb.width=Zb.width);isFinite($c.height)&&(eb.height=$c.height);ld.h(0,0);for(var fe=1;fe<Ba.rowSpan&&!(Ba.row+fe>=a.rowCount);fe++)J=a.getRowDefinition(Ba.row+fe),Ra=0,
Ra=Eb===be||Eb===Mk?Math.max(J.minimum,0===md[Ba.row+fe]?J.maximum:Math.min(md[Ba.row+fe],J.maximum)):Math.max(J.minimum,isNaN(J.Pc)?J.maximum:Math.min(J.Pc,J.maximum)),ld.height+=Ra;for(var Oe=1;Oe<Ba.columnSpan&&!(Ba.column+Oe>=a.columnCount);Oe++)J=a.getColumnDefinition(Ba.column+Oe),Ra=0,Ra=Eb===be||Eb===Lk?Math.max(J.minimum,0===ob[Ba.column+Oe]?J.maximum:Math.min(ob[Ba.column+Oe],J.maximum)):Math.max(J.minimum,isNaN(J.Pc)?J.maximum:Math.min(J.Pc,J.maximum)),ld.width+=Ra;eb.width+=ld.width;eb.height+=
ld.height;var ge=Ba.margin,wc=ge.right+ge.left,re=ge.top+ge.bottom;Ba.measure(eb.width,eb.height,f,g);for(var ad=Ba.measuredBounds,wb=Math.max(ad.width+wc,0),Qd=Math.max(ad.height+re,0),Mb=0,se=0;se<Ba.rowSpan&&!(Ba.row+se>=a.rowCount);se++)J=a.getRowDefinition(Ba.row+se),Mb+=J.total||0;if(Mb<Qd){var yd=Qd-Mb,he=Qd-Mb;if(null!==Ba.spanAllocation)for(var Cg=Ba.spanAllocation,bd=0;bd<Ba.rowSpan&&!(0>=yd)&&!(Ba.row+bd>=a.rowCount);bd++){J=a.getRowDefinition(Ba.row+bd);var Pe=J.ka||0,Dg=Cg(Ba,J,he);J.actual=
Math.min(J.maximum,Pe+Dg);J.ka!==Pe&&(yd-=J.ka-Pe)}for(;0<yd;){var Gf=J.ka||0;isNaN(J.height)&&J.maximum>Gf&&(J.actual=Math.min(J.maximum,Gf+yd),J.ka!==Gf&&(yd-=J.ka-Gf));if(0===J.index)break;J=a.getRowDefinition(J.index-1)}}for(var Qe=0,Eg=0;Eg<Ba.columnSpan&&!(Ba.column+Eg>=a.columnCount);Eg++)J=a.getColumnDefinition(Ba.column+Eg),Qe+=J.total||0;if(Qe<wb){var cg=wb-Qe,Fi=wb-Qe;if(null!==Ba.spanAllocation)for(var Re=Ba.spanAllocation,Se=0;Se<Ba.columnSpan&&!(0>=cg)&&!(Ba.column+Se>=a.columnCount);Se++){J=
a.getColumnDefinition(Ba.column+Se);var Fh=J.ka||0,mt=Re(Ba,J,Fi);J.actual=Math.min(J.maximum,Fh+mt);J.ka!==Fh&&(cg-=J.ka-Fh)}for(;0<cg;){var Vj=J.ka||0;isNaN(J.width)&&J.maximum>Vj&&(J.actual=Math.min(J.maximum,Vj+cg),J.ka!==Vj&&(cg-=J.ka-Vj));if(0===J.index)break;J=a.getColumnDefinition(J.index-1)}}}}Oa(C);L.free(ld);L.free(eb);void 0!==md&&Oa(md);void 0!==ob&&Oa(ob);var Fg=0,Gg=0,Wj=a.desiredSize,Oq=a.maxSize;Eb=$k(a,!0);var Gi=Ya=Wb=0,Hi=0;h=a.columnCount;for(var Xj=0;Xj<h;Xj++)void 0!==a.nb[Xj]&&
(J=a.getColumnDefinition(Xj),isFinite(J.width)?(Gi+=J.width,Gi+=J.tc()):um(J)===vm?(Gi+=J.ka,Gi+=J.tc()):0!==J.ka&&(Wb+=J.ka,Wb+=J.tc()));isFinite(Wj.width)?Fg=Math.min(Wj.width,Oq.width):Fg=Eb!==Zg&&isFinite(b)?b:Wb;Fg=Math.max(Fg,a.minSize.width);Fg=Math.max(Fg-Gi,0);for(var nt=0===Wb?1:Math.max(Fg/Wb,1),Zj=0;Zj<h;Zj++)void 0!==a.nb[Zj]&&(J=a.getColumnDefinition(Zj),isFinite(J.width)||um(J)===vm||(J.actual=J.ka*nt),J.position=e.width,0!==J.ka&&(e.width+=J.ka,e.width+=J.tc()));h=a.rowCount;for(var ak=
0;ak<h;ak++)void 0!==a.sb[ak]&&(J=a.getRowDefinition(ak),isFinite(J.height)?(Hi+=J.height,Hi+=J.tc()):um(J)===vm?(Hi+=J.ka,Hi+=J.tc()):0!==J.ka&&(Ya+=J.ka,0!==J.ka&&(Ya+=J.tc())));isFinite(Wj.height)?Gg=Math.min(Wj.height,Oq.height):Gg=Eb!==Zg&&isFinite(c)?c:Ya;Gg=Math.max(Gg,a.minSize.height);Gg=Math.max(Gg-Hi,0);for(var ot=0===Ya?1:Math.max(Gg/Ya,1),bk=0;bk<h;bk++)void 0!==a.sb[bk]&&(J=a.getRowDefinition(bk),isFinite(J.height)||um(J)===vm||(J.actual=J.ka*ot),J.position=e.height,0!==J.ka&&(e.height+=
J.ka,0!==J.ka&&(e.height+=J.tc())));h=l.length;for(var kn=0;kn<h;kn++){var Rd=l[kn],ln=0,mn=0;Rd.type===X.TableRow?(ln=e.width,J=a.getRowDefinition(Rd.row),mn=J.actual):(J=a.getColumnDefinition(Rd.column),ln=J.actual,mn=e.height);Rd.measuredBounds.h(0,0,ln,mn);jj(Rd,!1);v[Rd.row]||(v[Rd.row]=[]);v[Rd.row][Rd.column]||(v[Rd.row][Rd.column]=[]);v[Rd.row][Rd.column].push(Rd)}Oa(l);a.fp=v};
tm.prototype.arrange=function(a,b,c){var d=b.length,e=a.padding,f=e.left;e=e.top;for(var g=a.fp,h,k,l=g.length,m=0,n=0;n<l;n++)g[n]&&(m=Math.max(m,g[n].length));for(n=Math.min(a.topIndex,l-1);n!==l&&(void 0===a.sb[n]||0===a.sb[n].ka);)n++;n=Math.min(n,l-1);n=-a.sb[n].position;for(h=Math.min(a.leftIndex,m-1);h!==m&&(void 0===a.nb[h]||0===a.nb[h].ka);)h++;h=Math.min(h,m-1);for(var p=-a.nb[h].position,q=L.alloc(),r=0;r<l;r++)if(g[r]){m=g[r].length;var u=a.getRowDefinition(r);k=u.position+n+e;0!==u.ka&&
(k+=u.Nu());for(var x=0;x<m;x++)if(g[r][x]){var v=a.getColumnDefinition(x);h=v.position+p+f;0!==v.ka&&(h+=v.Nu());for(var y=g[r][x],z=y.length,B=0;B<z;B++){var C=y[B],G=C.measuredBounds,K=C instanceof X?C:null;if(null===K||K.type!==X.TableRow&&K.type!==X.TableColumn){q.h(0,0);for(var M=1;M<C.rowSpan&&!(r+M>=a.rowCount);M++)K=a.getRowDefinition(r+M),q.height+=K.total;for(M=1;M<C.columnSpan&&!(x+M>=a.columnCount);M++)K=a.getColumnDefinition(x+M),q.width+=K.total;var U=v.ka+q.width,R=u.ka+q.height;M=
h;K=k;var da=U,ja=R,J=h,Q=k,xa=U,jb=R;h+U>c.width&&(xa=Math.max(c.width-h,0));k+R>c.height&&(jb=Math.max(c.height-k,0));var Ha=C.alignment;if(Ha.Kb()){Ha=a.defaultAlignment;Ha.gb()||(Ha=dd);var Da=Ha.x;var Fa=Ha.y;var Ra=Ha.offsetX;Ha=Ha.offsetY;var Ub=v.alignment,$a=u.alignment;Ub.gb()&&(Da=Ub.x,Ra=Ub.offsetX);$a.gb()&&(Fa=$a.y,Ha=$a.offsetY)}else Da=Ha.x,Fa=Ha.y,Ra=Ha.offsetX,Ha=Ha.offsetY;if(isNaN(Da)||isNaN(Fa))Fa=Da=.5,Ha=Ra=0;Ub=G.width;$a=G.height;var Xa=C.margin,Ma=Xa.left+Xa.right,ha=Xa.top+
Xa.bottom,fa=sl(C,u,v,!1);!isNaN(C.desiredSize.width)||fa!==be&&fa!==Lk||(Ub=Math.max(U-Ma,0));!isNaN(C.desiredSize.height)||fa!==be&&fa!==Mk||($a=Math.max(R-ha,0));U=C.maxSize;R=C.minSize;Ub=Math.min(U.width,Ub);$a=Math.min(U.height,$a);Ub=Math.max(R.width,Ub);$a=Math.max(R.height,$a);U=$a+ha;M+=da*Da-(Ub+Ma)*Da+Ra+Xa.left;K+=ja*Fa-U*Fa+Ha+Xa.top;C.visible&&(Cc(J,Q,xa,jb,M,K,G.width,G.height)?C.arrange(M,K,Ub,$a):C.arrange(M,K,Ub,$a,new N(J,Q,xa,jb)))}else C.fl(),C.actualBounds.ha(),da=C.actualBounds,
M=N.allocAt(da.x,da.y,da.width,da.height),da.x=K.type===X.TableRow?f:h,da.y=K.type===X.TableColumn?e:k,da.width=G.width,da.height=G.height,C.actualBounds.freeze(),al(C,!1),xc(M,da)||(G=C.part,null!==G&&(G.zh(),C.Fo(G))),N.free(M)}}}L.free(q);for(a=0;a<d;a++)c=b[a],f=c instanceof X?c:null,null===f||f.type!==X.TableRow&&f.type!==X.TableColumn||(f=c.actualBounds,c.naturalBounds.ha(),c.naturalBounds.h(0,0,f.width,f.height),c.naturalBounds.freeze())};
function wm(){this.name="Base";this.name="TableRow"}oa(wm,Cl);wm.prototype.measure=function(){};wm.prototype.arrange=function(){};function xm(){this.name="Base";this.name="TableColumn"}oa(xm,Cl);xm.prototype.measure=function(){};xm.prototype.arrange=function(){};function ym(){this.name="Base";this.name="Viewbox"}oa(ym,Cl);
ym.prototype.measure=function(a,b,c,d,e,f,g){1<d.length&&A("Viewbox Panel cannot contain more than one GraphObject.");d=d[0];d.Ca=1;d.dm();d.measure(Infinity,Infinity,f,g);var h=d.measuredBounds,k=d.margin,l=k.right+k.left;k=k.top+k.bottom;if(isFinite(b)||isFinite(c)){var m=d.scale,n=h.width;h=h.height;var p=Math.max(b-l,0),q=Math.max(c-k,0),r=1;a.viewboxStretch===ah?0!==n&&0!==h&&(r=Math.min(p/n,q/h)):0!==n&&0!==h&&(r=Math.max(p/n,q/h));0===r&&(r=1E-4);d.Ca*=r;m!==d.scale&&(jj(d,!0),d.measure(Infinity,
Infinity,f,g))}h=d.measuredBounds;e.width=isFinite(b)?b:Math.max(h.width+l,0);e.height=isFinite(c)?c:Math.max(h.height+k,0)};ym.prototype.arrange=function(a,b,c){b=b[0];var d=b.measuredBounds,e=b.margin,f=Math.max(d.width+(e.right+e.left),0);e=Math.max(d.height+(e.top+e.bottom),0);var g=b.alignment;g.Kb()&&(g=a.defaultAlignment);g.gb()||(g=dd);b.arrange(c.width*g.x-f*g.x+g.offsetX,c.height*g.y-e*g.y+g.offsetY,d.width,d.height)};function zm(){this.name="Base";this.name="Grid"}oa(zm,Cl);
zm.prototype.measure=function(){};zm.prototype.arrange=function(){};function Am(){this.name="Base";this.name="Link"}oa(Am,Cl);
Am.prototype.measure=function(a,b,c,d,e){c=d.length;if(a instanceof pf||a instanceof S){var f=null,g=null,h=null;a instanceof S&&(g=f=a);a instanceof pf&&(h=a,f=h.adornedPart);if(f instanceof S){var k=f;if(0===c)sc(a.naturalBounds,0,0),a.measuredBounds.h(0,0,0,0);else{var l=a instanceof pf?null:f.path,m=f.routeBounds;b=a.ig;b.h(0,0,m.width,m.height);var n=k.points;f=f.pointsCount;null!==h?h.ik(!1):null!==g&&g.ik(!1);var p=m.width,q=m.height;a.location.h(m.x,m.y);a.l.length=0;null!==l&&(Bm(a,p,q,l),
h=l.measuredBounds,b.Jc(h),a.l.push(h));h=Qc.alloc();for(var r=H.alloc(),u=H.alloc(),x=0;x<c;x++){var v=d[x];if(v!==l)if(v.isPanelMain&&v instanceof W){Bm(a,p,q,v);var y=v.measuredBounds;b.Jc(y);a.l.push(y)}else if(2>f)v.measure(Infinity,Infinity,0,0),y=v.measuredBounds,b.Jc(y),a.l.push(y);else{var z=v.segmentIndex;y=v.segmentFraction;var B=v.alignmentFocus;B.jc()&&(B=dd);var C=v.segmentOrientation,G=v.segmentOffset;if(z<-f||z>=f){y=k.midPoint;var K=k.midAngle;if(C!==Og){var M=k.computeAngle(v,C,
K);v.zc=M}M=y.x-m.x;var U=y.y-m.y}else{M=0;if(0<=z){U=n.N(z);var R=z<f-1?n.N(z+1):U}else M=f+z,U=n.N(M),R=0<M?n.N(M-1):U;if(U.Qa(R)){0<=z?(K=0<z?n.N(z-1):U,M=z<f-2?n.N(z+2):R):(K=M<f-1?n.N(M+1):U,M=1<M?n.N(M-2):R);var da=K.Be(U),ja=R.Be(M);K=da>ja+10?0<=z?K.Wa(U):U.Wa(K):ja>da+10?0<=z?R.Wa(M):M.Wa(R):0<=z?K.Wa(M):M.Wa(K)}else K=0<=z?U.Wa(R):R.Wa(U);C!==Og&&(M=k.computeAngle(v,C,K),v.zc=M);M=U.x+(R.x-U.x)*y-m.x;U=U.y+(R.y-U.y)*y-m.y}v.measure(Infinity,Infinity,0,0);y=v.measuredBounds;R=v.naturalBounds;
var J=0;v instanceof W&&(J=v.strokeWidth);da=R.width+J;ja=R.height+J;h.reset();h.translate(-y.x,-y.y);h.scale(v.scale,v.scale);h.rotate(C===Og?v.angle:K,da/2,ja/2);C!==Cm&&C!==Dm||h.rotate(90,da/2,ja/2);C!==Em&&C!==Fm||h.rotate(-90,da/2,ja/2);C===Gm&&(45<K&&135>K||225<K&&315>K)&&h.rotate(-K,da/2,ja/2);R=new N(0,0,da,ja);r.Vi(R,B);h.ta(r);B=-r.x+J/2*v.scale;v=-r.y+J/2*v.scale;u.assign(G);J=isNaN(G.x);var Q=isNaN(G.y);if(J||Q){da=da/2+3;ja=ja/2+3;var xa=45<=K&&135>=K,jb=225<=K&&315>=K;C===Og&&(xa||
jb)?(u.x=Q?da:G.y,u.y=J?ja:G.x,xa?0<=z||!J||(u.y=-ja):jb&&(0<=z&&J&&(u.y=-ja),Q&&(u.x=-da))):(J&&(u.x=0<=z?da:-da),Q&&(u.y=-ja),u.rotate(K))}else u.rotate(K);M+=u.x;U+=u.y;R.set(y);R.h(M+B,U+v,y.width,y.height);a.l.push(R);b.Jc(R)}}if(null!==g)for(d=g.labelNodes;d.next();)d.value.measure(Infinity,Infinity);a.ig=b;a=a.location;a.h(a.x+b.x,a.y+b.y);sc(e,b.width||0,b.height||0);Qc.free(h);H.free(r);H.free(u)}}}};
Am.prototype.arrange=function(a,b){var c=b.length;if(a instanceof pf||a instanceof S){var d=null,e=null,f=null;a instanceof S&&(e=d=a);a instanceof pf&&(f=a,d=f.adornedPart);var g=a instanceof pf?null:d.path;if(0!==a.l.length){var h=a.l,k=0;if(null!==g&&k<a.l.length){var l=h[k];k++;g.arrange(l.x-a.ig.x,l.y-a.ig.y,l.width,l.height)}for(l=0;l<c;l++){var m=b[l];if(m!==g&&k<a.l.length){var n=h[k];k++;m.arrange(n.x-a.ig.x,n.y-a.ig.y,n.width,n.height)}}}b=d.points;c=b.count;if(2<=c&&a instanceof S)for(d=
a.labelNodes;d.next();){n=a;g=d.value;h=g.segmentIndex;var p=g.segmentFraction;l=g.alignmentFocus;var q=g.segmentOrientation;k=g.segmentOffset;if(h<-c||h>=c){var r=n.midPoint;m=n.midAngle;q!==Og&&(n=n.computeAngle(g,q,m),g.angle=n);n=r.x;var u=r.y}else{var x=0;0<=h?(u=b.j[h],r=h<c-1?b.j[h+1]:u):(x=c+h,u=b.j[x],r=0<x?b.j[x-1]:u);if(u.Qa(r)){0<=h?(m=0<h?b.j[h-1]:u,x=h<c-2?b.j[h+2]:r):(m=x<c-1?b.j[x+1]:u,x=1<x?b.j[x-2]:r);var v=m.Be(u),y=r.Be(x);m=v>y+10?0<=h?m.Wa(u):u.Wa(m):y>v+10?0<=h?r.Wa(x):x.Wa(r):
0<=h?m.Wa(x):x.Wa(m)}else m=0<=h?u.Wa(r):r.Wa(u);q!==Og&&(n=n.computeAngle(g,q,m),g.angle=n);n=u.x+(r.x-u.x)*p;u=u.y+(r.y-u.y)*p}l.dt()?g.location=new H(n,u):(l.jc()&&(l=dd),r=Qc.alloc(),r.reset(),r.scale(g.scale,g.scale),r.rotate(g.angle,0,0),p=g.naturalBounds,p=N.allocAt(0,0,p.width,p.height),q=H.alloc(),q.Vi(p,l),r.ta(q),l=-q.x,x=-q.y,k=k.copy(),isNaN(k.x)&&(0<=h?k.x=q.x+3:k.x=-(q.x+3)),isNaN(k.y)&&(k.y=-(q.y+3)),k.rotate(m),n+=k.x,u+=k.y,r.Uv(p),l+=p.x,x+=p.y,h=H.allocAt(n+l,u+x),g.move(h),H.free(h),
H.free(q),N.free(p),Qc.free(r))}null!==f?f.ik(!1):null!==e&&e.ik(!1)}};function Bm(a,b,c,d){if(!1!==oj(d)){var e=d.strokeWidth;0===e&&a instanceof pf&&a.type===X.Link&&a.adornedObject instanceof W&&(e=a.adornedObject.strokeWidth);e*=d.Ca;a instanceof S&&null!==a.qa?(a=a.qa.bounds,Xk(d,a.x-e/2,a.y-e/2,a.width+e,a.height+e)):a instanceof pf&&null!==a.adornedPart.qa?(a=a.adornedPart.qa.bounds,Xk(d,a.x-e/2,a.y-e/2,a.width+e,a.height+e)):Xk(d,-(e/2),-(e/2),b+e,c+e);jj(d,!1)}}
function Hm(){this.name="Base";this.name="Graduated"}oa(Hm,Cl);
Hm.prototype.measure=function(a,b,c,d,e,f,g){var h=a.zb();a.jj=[];var k=h.margin,l=k.right+k.left,m=k.top+k.bottom;h.measure(b,c,f,g);var n=h.measuredBounds,p=new N(-k.left,-k.top,Math.max(n.width+l,0),Math.max(n.height+m,0));a.jj.push(p);e.assign(p);for(var q=h.geometry,r=h.strokeWidth,u=q.flattenedSegments,x=q.flattenedLengths,v=q.flattenedTotalLength,y=u.length,z=0,B=0,C=Ka(),G=0;G<y;G++){var K=u[G],M=[];B=z=0;for(var U=K.length,R=0;R<U;R+=2){var da=K[R],ja=K[R+1];if(0!==R){var J=180*Math.atan2(ja-
B,da-z)/Math.PI;0>J&&(J+=360);M.push(J)}z=da;B=ja}C.push(M)}if(null===a.Og){for(var Q=[],xa=a.Y.j,jb=xa.length,Ha=0;Ha<jb;Ha++){var Da=xa[Ha],Fa=[];Q.push(Fa);if(Da.visible)for(var Ra=Da.interval,Ub=0;Ub<jb;Ub++){var $a=xa[Ub];if($a.visible&&Da!==$a&&!(Da instanceof W&&!($a instanceof W)||Da instanceof vh&&!($a instanceof vh))){var Xa=$a.interval;Xa>Ra&&Fa.push(Xa)}}}a.Og=Q}var Ma=a.Og;var ha=a.Y.j,fa=ha.length,hb=0,kb=0,Na=v;a.lj=[];for(var ee,Ld=0;Ld<fa;Ld++){var vb=ha[Ld];ee=[];if(vb.visible&&
vb!==h){var zf=vb.interval,Eb=a.graduatedTickUnit;if(!(2>Eb*zf*v/a.graduatedRange)){var qe=x[0][0],Wc=0,Vb=0;kb=v*vb.graduatedStart-1E-4;Na=v*vb.graduatedEnd+1E-4;var Md=Eb*zf,Ec=a.graduatedTickBase;if(Ec<a.graduatedMin){var Ie=(a.graduatedMin-Ec)/Md;Ie=0===Ie%1?Ie:Math.floor(Ie+1);Ec+=Ie*Md}else Ec>a.graduatedMin+Md&&(Ec-=Math.floor((Ec-a.graduatedMin)/Md)*Md);for(var Je=Ma[Ld];Ec<=a.graduatedMax;){a:{for(var Af=Je.length,gf=0;gf<Af;gf++)if(I.ca((Ec-a.graduatedTickBase)%(Je[gf]*a.graduatedTickUnit),
0)){var Bf=!1;break a}Bf=!0}if(Bf&&(null===vb.graduatedSkip||!vb.graduatedSkip(Ec))&&(hb=(Ec-a.graduatedMin)*v/a.graduatedRange,hb>v&&(hb=v),kb<=hb&&hb<=Na)){for(var Wb=C[Wc][Vb],Ya=x[Wc][Vb];Wc<x.length;){for(;hb>qe&&Vb<x[Wc].length-1;)Vb++,Wb=C[Wc][Vb],Ya=x[Wc][Vb],qe+=Ya;if(hb<=qe)break;Wc++;Vb=0;Wb=C[Wc][Vb];Ya=x[Wc][Vb];qe+=Ya}var ab=u[Wc],Mc=ab[2*Vb],Cf=ab[2*Vb+1],jc=(hb-(qe-Ya))/Ya,Nd=new H(Mc+(ab[2*Vb+2]-Mc)*jc+r/2-q.bounds.x,Cf+(ab[2*Vb+3]-Cf)*jc+r/2-q.bounds.y);Nd.scale(h.scale,h.scale);
var qc=Wb,Od=C[Wc];1E-4>jc?0<Vb?qc=Od[Vb-1]:I.ca(ab[0],ab[ab.length-2])&&I.ca(ab[1],ab[ab.length-1])&&(qc=Od[Od.length-1]):.9999<jc&&(Vb+1<Od.length?qc=Od[Vb+1]:I.ca(ab[0],ab[ab.length-2])&&I.ca(ab[1],ab[ab.length-1])&&(qc=Od[0]));Wb!==qc&&(180<Math.abs(Wb-qc)&&(Wb<qc?Wb+=360:qc+=360),Wb=(Wb+qc)/2%360);if(vb instanceof vh){var Xb="";null!==vb.graduatedFunction?(Xb=vb.graduatedFunction(Ec),Xb=null!==Xb&&void 0!==Xb?Xb.toString():""):Xb=(+Ec.toFixed(2)).toString();""!==Xb&&ee.push([Nd,Wb,Xb])}else ee.push([Nd,
Wb])}Ec+=Md}}}a.lj.push(ee)}Oa(C);for(var Dh=a.lj,Eh=d.length,Ke=0;Ke<Eh;Ke++){var pb=d[Ke],Df=Dh[Ke];if(pb.visible&&pb!==h&&0!==Df.length){if(pb instanceof W){var Le=a,Yb=e,Me=pb.alignmentFocus;Me.jc()&&(Me=Uc);var Nc=pb.angle;pb.zc=0;pb.measure(Infinity,Infinity,0,0);pb.zc=Nc;var eb=pb.measuredBounds,Ne=eb.width,rb=eb.height,rc=N.allocAt(0,0,Ne,rb),lb=H.alloc();lb.Vi(rc,Me);N.free(rc);for(var Xc=-lb.x,Yc=-lb.y,xd=new N,Bg=Df.length,Ef=0;Ef<Bg;Ef++)for(var Ff=Df[Ef],hf=Ff[0].x,kd=Ff[0].y,Pd=Ff[1],
ld=0;4>ld;ld++){switch(ld){case 0:lb.h(Xc,Yc);break;case 1:lb.h(Xc+Ne,Yc);break;case 2:lb.h(Xc,Yc+rb);break;case 3:lb.h(Xc+Ne,Yc+rb)}lb.rotate(Pd+pb.angle);lb.offset(hf,kd);0===Ef&&0===ld?xd.h(lb.x,lb.y,0,0):xd.Ie(lb);lb.offset(-hf,-kd);lb.rotate(-Pd-pb.angle)}H.free(lb);Le.jj.push(xd);Bc(Yb,xd.x,xd.y,xd.width,xd.height)}else if(pb instanceof vh){var md=a,ob=e;null===md.kh&&(md.kh=new vh);var fb=md.kh;Im(fb,pb);var nd=pb.alignmentFocus;nd.jc()&&(nd=Uc);for(var Zc=pb.segmentOrientation,od=pb.segmentOffset,
vc=null,Ba=0,$c=0,Zb=0,fe=0,Oe=Df.length,ge=0;ge<Oe;ge++){var wc=Df[ge];Ba=wc[0].x;$c=wc[0].y;Zb=wc[1];Zc!==Og&&(fe=S.computeAngle(Zc,Zb),fb.zc=fe);fb.text=wc[2];fb.measure(Infinity,Infinity,0,0);var re=fb.measuredBounds,ad=fb.naturalBounds,wb=ad.width,Qd=ad.height,Mb=Qc.alloc();Mb.reset();Mb.translate(-re.x,-re.y);Mb.scale(fb.scale,fb.scale);Mb.rotate(Zc===Og?fb.angle:Zb,wb/2,Qd/2);Zc!==Cm&&Zc!==Dm||Mb.rotate(90,wb/2,Qd/2);Zc!==Em&&Zc!==Fm||Mb.rotate(-90,wb/2,Qd/2);Zc===Gm&&(45<Zb&&135>Zb||225<Zb&&
315>Zb)&&Mb.rotate(-Zb,wb/2,Qd/2);var se=N.allocAt(0,0,wb,Qd),yd=H.alloc();yd.Vi(se,nd);Mb.ta(yd);var he=-yd.x,Cg=-yd.y,bd=H.alloc();bd.assign(od);isNaN(bd.x)&&(bd.x=wb/2+3);isNaN(bd.y)&&(bd.y=-(Qd/2+3));bd.rotate(Zb);Ba+=bd.x+he;$c+=bd.y+Cg;var Pe=new N(Ba,$c,re.width,re.height),Dg=new N(re.x,re.y,re.width,re.height),Gf=new N(ad.x,ad.y,ad.width,ad.height),Qe=new Jm;Qe.Vl(fb.metrics);wc.push(fe);wc.push(fb.lineCount);wc.push(Qe);wc.push(Pe);wc.push(Dg);wc.push(Gf);0===ge?vc=Pe.copy():vc.Jc(Pe);H.free(bd);
H.free(yd);N.free(se);Qc.free(Mb)}md.jj.push(vc);Bc(ob,vc.x,vc.y,vc.width,vc.height)}jj(pb,!1)}}};Hm.prototype.arrange=function(a,b,c){if(null!==a.jj){var d=a.zb(),e=a.lj,f=a.jj,g=0,h=f[g];g++;d.arrange(h.x-c.x,h.y-c.y,h.width,h.height);for(var k=b.length,l=0;l<k;l++){var m=b[l];h=e[l];m.visible&&m!==d&&0!==h.length&&(h=f[g],g++,m.arrange(h.x-c.x,h.y-c.y,h.width,h.height))}a.jj=null}};
function X(a){Y.call(this);this.va=void 0===a?X.Position:a;null===this.va&&A("Panel type not specified or PanelLayout not loaded: "+a);this.Y=new E;this.eb=Oc;this.va===X.Grid&&(this.isAtomic=!0);this.mn=Ed;this.Ef=Kk;this.va===X.Table&&Km(this);this.Hp=ah;this.Kn=kc;this.Ln=ac;this.Hn=0;this.Gn=100;this.Jn=10;this.In=0;this.Rh=this.jb=this.Og=this.jj=this.lj=null;this.Yn=NaN;this.ie=this.fi=null;this.gl="category";this.Ed=null;this.ig=new N(NaN,NaN,NaN,NaN);this.kh=this.fp=this.vi=null;this.zg=""}
oa(X,Y);function Km(a){a.fj=Oc;a.Ig=1;a.Wh=null;a.Vh=null;a.Hg=1;a.Gg=null;a.Uh=null;a.sb=[];a.nb=[];a.Hj=Lm;a.dj=Lm;a.yi=0;a.hi=0}
X.prototype.cloneProtected=function(a){Y.prototype.cloneProtected.call(this,a);a.va=this.va;a.eb=this.eb.I();a.mn=this.mn.I();a.Ef=this.Ef;if(a.va===X.Table){a.fj=this.fj.I();a.Ig=this.Ig;a.Wh=this.Wh;a.Vh=this.Vh;a.Hg=this.Hg;a.Gg=this.Gg;a.Uh=this.Uh;var b=[];if(0<this.sb.length)for(var c=this.sb,d=c.length,e=0;e<d;e++)if(void 0!==c[e]){var f=c[e].copy();f.Ui(a);b[e]=f}a.sb=b;b=[];if(0<this.nb.length)for(c=this.nb,d=c.length,e=0;e<d;e++)void 0!==c[e]&&(f=c[e].copy(),f.Ui(a),b[e]=f);a.nb=b;a.Hj=
this.Hj;a.dj=this.dj;a.yi=this.yi;a.hi=this.hi}a.Hp=this.Hp;a.Kn=this.Kn.I();a.Ln=this.Ln.I();a.Hn=this.Hn;a.Gn=this.Gn;a.Jn=this.Jn;a.In=this.In;a.lj=this.lj;a.Og=this.Og;a.jb=this.jb;a.Rh=this.Rh;a.Yn=this.Yn;a.fi=this.fi;a.ie=this.ie;a.gl=this.gl;a.ig.assign(this.ig);a.zg=this.zg;null!==this.fp&&(a.fp=this.fp)};X.prototype.nf=function(a){Y.prototype.nf.call(this,a);a.Y=this.Y;for(var b=a.Y.j,c=b.length,d=0;d<c;d++)b[d].ag=a;a.vi=null};
X.prototype.copy=function(){var a=Y.prototype.copy.call(this);if(null!==a){for(var b=this.Y.j,c=b.length,d=0;d<c;d++){var e=b[d].copy();e.Ui(a);e.Cj=null;var f=a.Y,g=f.count;f.Ib(g,e);f=a.part;if(null!==f){f.xj=null;null!==e.portId&&f instanceof V&&(f.yh=!0);var h=a.diagram;null!==h&&h.undoManager.isUndoingRedoing||f.ab(cf,"elements",a,null,e,null,g)}}return a}return null};t=X.prototype;t.toString=function(){return"Panel("+this.type+")#"+Bb(this)};
t.Fo=function(a){Y.prototype.Fo.call(this,a);for(var b=this.Y.j,c=b.length,d=0;d<c;d++)b[d].Fo(a)};
t.Ei=function(a,b){if(this.va===X.Grid){b=this.Ce()*b.scale;0>=b&&(b=1);var c=this.gridCellSize,d=c.width;c=c.height;var e=this.naturalBounds,f=this.actualBounds,g=e.width,h=e.height,k=Math.ceil(g/d),l=Math.ceil(h/c),m=this.gridOrigin;a.save();a.beginPath();a.rect(0,0,g,h);a.clip();for(var n=[],p=this.Y.j,q=p.length,r=0;r<q;r++){var u=p[r],x=[];n.push(x);if(u.visible){u=Rj(u.figure);for(var v=r+1;v<q;v++){var y=p[v];y.visible&&Rj(y.figure)===u&&(y=y.interval,2<=y&&x.push(y))}}}p=this.Y.j;q=p.length;
for(r=0;r<q;r++){var z=p[r];if(z.visible&&(x=z.interval,!(2>d*x*b))){u=z.opacity;v=1;if(1!==u){if(0===u)continue;v=a.globalAlpha;a.globalAlpha=v*u}y=n[r];var B=!1,C=z.strokeDashArray;null!==C&&(B=!0,a.Os(C,z.strokeDashOffset));if("LineV"===z.figure&&null!==z.stroke){a.lineWidth=z.strokeWidth;ji(this,a,z.stroke,!1,!1,e,f);a.beginPath();for(C=z=Math.floor(-m.x/d);C<=z+k;C++){var G=C*d+m.x;0<=G&&G<=g&&Mm(C,x,y)&&(a.moveTo(G,0),a.lineTo(G,h))}a.stroke()}else if("LineH"===z.figure&&null!==z.stroke){a.lineWidth=
z.strokeWidth;ji(this,a,z.stroke,!1,!1,e,f);a.beginPath();for(C=z=Math.floor(-m.y/c);C<=z+l;C++)G=C*c+m.y,0<=G&&G<=h&&Mm(C,x,y)&&(a.moveTo(0,G),a.lineTo(g,G));a.stroke()}else if("BarV"===z.figure&&null!==z.fill)for(ji(this,a,z.fill,!0,!1,e,f),z=z.width,isNaN(z)&&(z=d),G=C=Math.floor(-m.x/d);G<=C+k;G++){var K=G*d+m.x;0<=K&&K<=g&&Mm(G,x,y)&&a.fillRect(K,0,z,h)}else if("BarH"===z.figure&&null!==z.fill)for(ji(this,a,z.fill,!0,!1,e,f),z=z.height,isNaN(z)&&(z=c),G=C=Math.floor(-m.y/c);G<=C+l;G++)K=G*c+
m.y,0<=K&&K<=h&&Mm(G,x,y)&&a.fillRect(0,K,g,z);B&&a.Ms();1!==u&&(a.globalAlpha=v)}}a.restore();a.Rc(!1)}else if(this.va===X.Graduated){d=b.oj;b.oj=!0;e=this.naturalBounds;c=e.width;e=e.height;a.save();a.beginPath();a.rect(-1,-1,c+1,e+1);a.clip();c=this.zb();c.hc(a,b);e=this.Ce()*b.scale;0>=e&&(e=1);f=c.actualBounds;g=this.Y.j;h=this.lj;k=g.length;for(l=0;l<k;l++)if(p=g[l],m=h[l],n=m.length,p.visible&&p!==c&&0!==m.length)if(p instanceof W){if(!(2>this.graduatedTickUnit*p.interval*e))for(q=p.measuredBounds,
r=p.strokeWidth*p.scale,x=p.alignmentFocus,x.jc()&&(x=Uc),u=0;u<n;u++)v=m[u][0],y=m[u][1],B=x,z=p.tb,z.reset(),z.translate(v.x+f.x,v.y+f.y),z.rotate(y+p.angle,0,0),z.translate(-q.width*B.x+B.offsetX+r/2,-q.height*B.y+B.offsetY+r/2),z.scale(p.scale,p.scale),pl(p,!1),p.Eh.set(p.tb),p.Tk=p.scale,ql(p,!1),p.hc(a,b),p.tb.reset()}else if(p instanceof vh)for(null===this.kh&&(this.kh=new vh),q=this.kh,Im(q,p),p=0;p<n;p++)u=m[p],3<u.length&&(r=u[6],q.Ob=u[2],q.zc=u[3],q.oc=u[4],q.nd=u[5],q.qc=u[8],q.arrange(r.x,
r.y,r.width,r.height),r=u[6],q.arrange(r.x,r.y,r.width,r.height),x=u[7],u=u[8],v=q.tb,v.reset(),v.translate(r.x+f.x,r.y+f.y),v.translate(-x.x,-x.y),Yk(q,v,u.x,u.y,u.width,u.height),pl(q,!1),q.Eh.set(q.tb),q.Tk=q.scale,ql(q,!1),q.hc(a,b));b.oj=d;a.restore();a.Rc(!0)}else{this.va===X.Table&&(a.lineCap="butt",Nm(this,a,!0,this.sb,!0),Nm(this,a,!1,this.nb,!0),Om(this,a,!0,this.sb),Om(this,a,!1,this.nb),Nm(this,a,!0,this.sb,!1),Nm(this,a,!1,this.nb,!1));(d=this.isClipping)&&a.save();c=this.zb();e=this.Y.j;
f=e.length;for(g=0;g<f;g++)h=e[g],d&&h===c&&(a.clipInsteadOfFill=!0),h.hc(a,b),d&&h===c&&(a.clipInsteadOfFill=!1);d&&(a.restore(),a.Rc(!0))}};
function Om(a,b,c,d){for(var e=d.length,f=a.actualBounds,g=a.naturalBounds,h=!0,k=0;k<e;k++){var l=d[k];if(void 0!==l)if(h)h=!1;else if(0!==l.actual){if(c){if(l.position>f.height)continue}else if(l.position>f.width)continue;var m=l.separatorStrokeWidth;isNaN(m)&&(m=c?a.Ig:a.Hg);var n=l.separatorStroke;null===n&&(n=c?a.Wh:a.Gg);if(0!==m&&null!==n){ji(a,b,n,!1,!1,g,f);n=!1;var p=l.separatorDashArray;null===p&&(p=c?a.Vh:a.Uh);null!==p&&(n=!0,b.Os(p,0));b.beginPath();p=l.position+m;c?p>f.height&&(m-=
p-f.height):p>f.width&&(m-=p-f.width);l=l.position+m/2;b.lineWidth=m;m=a.eb;c?(l+=m.top,p=f.width-m.right,b.moveTo(m.left,l),b.lineTo(p,l)):(l+=m.left,p=f.height-m.bottom,b.moveTo(l,m.top),b.lineTo(l,p));b.stroke();n&&b.Ms()}}}}
function Nm(a,b,c,d,e){for(var f=d.length,g=a.actualBounds,h=a.naturalBounds,k=0;k<f;k++){var l=d[k];if(void 0!==l&&null!==l.background&&l.coversSeparators!==e&&0!==l.actual){var m=c?g.height:g.width;if(!(l.position>m)){var n=l.tc(),p=l.separatorStrokeWidth;isNaN(p)&&(p=c?a.Ig:a.Hg);var q=l.separatorStroke;null===q&&(q=c?a.Wh:a.Gg);null===q&&(p=0);n-=p;p=l.position+p;n+=l.actual;p+n>m&&(n=m-p);0>=n||(m=a.eb,ji(a,b,l.background,!0,!1,h,g),c?b.fillRect(m.left,p+m.top,g.width-(m.left+m.right),n):b.fillRect(p+
m.left,m.top,n,g.height-(m.top+m.bottom)))}}}}function Mm(a,b,c){if(0!==a%b)return!1;b=c.length;for(var d=0;d<b;d++)if(0===a%c[d])return!1;return!0}function Rj(a){return"LineV"===a||"BarV"===a}
t.dk=function(a,b,c,d,e){var f=this.Zd(),g=this.transform,h=1/(g.m11*g.m22-g.m12*g.m21),k=g.m22*h,l=-g.m12*h,m=-g.m21*h,n=g.m11*h,p=h*(g.m21*g.dy-g.m22*g.dx),q=h*(g.m12*g.dx-g.m11*g.dy);if(null!==this.areaBackground)return g=this.actualBounds,I.Tc(g.left,g.top,g.right,g.bottom,a,b,c,d,e);if(null!==this.background)return f=a*k+b*m+p,h=a*l+b*n+q,a=c*k+d*m+p,k=c*l+d*n+q,e.h(0,0),c=this.naturalBounds,f=I.Tc(0,0,c.width,c.height,f,h,a,k,e),e.transform(g),f;f||(k=1,m=l=0,n=1,q=p=0);h=a*k+b*m+p;a=a*l+b*
n+q;k=c*k+d*m+p;c=c*l+d*n+q;e.h(k,c);d=(k-h)*(k-h)+(c-a)*(c-a);l=!1;n=this.Y.j;q=n.length;m=H.alloc();p=null;b=Infinity;var r=null;this.isClipping&&(r=H.alloc(),p=this.zb(),(l=p.dk(h,a,k,c,r))&&(b=(h-r.x)*(h-r.x)+(a-r.y)*(a-r.y)));for(var u=0;u<q;u++){var x=n[u];x.visible&&x!==p&&x.dk(h,a,k,c,m)&&(l=!0,x=(h-m.x)*(h-m.x)+(a-m.y)*(a-m.y),x<d&&(d=x,e.set(m)))}this.isClipping&&(b>d&&e.set(r),H.free(r));H.free(m);f&&e.transform(g);return l};
t.s=function(a){Y.prototype.s.call(this,a);a=null;if(this.va===X.Auto||this.va===X.Link)a=this.zb();for(var b=this.Y.j,c=b.length,d=0;d<c;d++){var e=b[d];(e===a||e.isPanelMain)&&e.s(!0);if(!e.desiredSize.o()){var f=$k(e,!1);(e instanceof Xg||e instanceof X||e instanceof vh||f!==Zg)&&e.s(!0)}}};t.dm=function(){if(!1===oj(this)){jj(this,!0);al(this,!0);for(var a=this.Y.j,b=a.length,c=0;c<b;c++)a[c].dm()}};
t.fl=function(){if(0!==(this.H&2048)===!1){pl(this,!0);ql(this,!0);for(var a=this.Y.j,b=a.length,c=0;c<b;c++)a[c].iv()}};t.iv=function(){ql(this,!0);for(var a=this.Y.j,b=a.length,c=0;c<b;c++)a[c].iv()};
t.hm=function(a,b,c,d){var e=this.ig;e.h(0,0,0,0);var f=this.desiredSize,g=this.minSize;void 0===c&&(c=g.width,d=g.height);c=Math.max(c,g.width);d=Math.max(d,g.height);var h=this.maxSize;isNaN(f.width)||(a=Math.min(f.width,h.width));isNaN(f.height)||(b=Math.min(f.height,h.height));a=Math.max(c,a);b=Math.max(d,b);var k=this.eb;a=Math.max(a-k.left-k.right,0);b=Math.max(b-k.top-k.bottom,0);var l=this.Y.j;0!==l.length&&this.va.measure(this,a,b,l,e,c,d);a=e.width+k.left+k.right;k=e.height+k.top+k.bottom;
isFinite(f.width)&&(a=f.width);isFinite(f.height)&&(k=f.height);a=Math.min(h.width,a);k=Math.min(h.height,k);a=Math.max(g.width,a);k=Math.max(g.height,k);a=Math.max(c,a);k=Math.max(d,k);sc(e,a,k);sc(this.naturalBounds,a,k);Xk(this,0,0,a,k)};t.zb=function(){if(null===this.vi){var a=this.Y.j,b=a.length;if(0===b)return null;for(var c=0;c<b;c++){var d=a[c];if(!0===d.isPanelMain)return this.vi=d}this.vi=a[0]}return this.vi};function mm(a){return null!==a.part?a.part.locationObject:null}
t.uh=function(a,b,c,d){var e=this.Y.j;this.actualBounds.h(a,b,c,d);if(0!==e.length){if(!this.desiredSize.o()){a=$k(this,!0);var f=this.measuredBounds;b=f.width;f=f.height;var g=this.Vg,h=g.left+g.right;g=g.top+g.bottom;b===c&&f===d&&(a=Zg);switch(a){case Zg:if(b>c||f>d)this.s(),this.measure(b>c?c:b,f>d?d:f,0,0);break;case be:this.s(!0);this.measure(c+h,d+g,0,0);break;case Lk:this.s(!0);this.measure(c+h,f+g,0,0);break;case Mk:this.s(!0),this.measure(b+h,d+g,0,0)}}this.va.arrange(this,e,this.ig)}};
t.wh=function(a){var b=this.naturalBounds,c=mm(this);if(Cc(0,0,b.width,b.height,a.x,a.y)){b=this.Y.j;for(var d=b.length,e=H.allocAt(0,0);d--;){var f=b[d];if(f.visible||f===c)if(Qb(e.set(a),f.transform),f.da(e))return H.free(e),!0}H.free(e);return null===this.ib&&null===this.dc?!1:!0}return!1};t.Ps=function(a){if(this.Ek===a)return this;for(var b=this.Y.j,c=b.length,d=0;d<c;d++){var e=b[d].Ps(a);if(null!==e)return e}return null};
t.ym=function(a,b){b(this,a);if(a instanceof X){a=a.Y.j;for(var c=a.length,d=0;d<c;d++)this.ym(a[d],b)}};function Bj(a,b){Pm(a,a,b)}function Pm(a,b,c){c(b);b=b.Y.j;for(var d=b.length,e=0;e<d;e++){var f=b[e];f instanceof X&&Pm(a,f,c)}}function Qm(a,b){Rm(a,a,b)}function Rm(a,b,c){c(b);if(b instanceof X){b=b.Y.j;for(var d=b.length,e=0;e<d;e++)Rm(a,b[e],c)}}t.Yl=function(a){return Sm(this,this,a)};
function Sm(a,b,c){if(c(b))return b;if(b instanceof X){b=b.Y.j;for(var d=b.length,e=0;e<d;e++){var f=Sm(a,b[e],c);if(null!==f)return f}}return null}t.$a=function(a){if(this.name===a)return this;var b=this.Y.j,c=b.length;null===this.fi&&null===this.ie||(c=Tm(this));for(var d=0;d<c;d++){var e=b[d];if(e instanceof X){var f=e.$a(a);if(null!==f)return f}if(e.name===a)return e}return null};
function Um(a){a=a.Y.j;for(var b=a.length,c=0,d=0;d<b;d++){var e=a[d];if(e instanceof X)c=Math.max(c,Um(e));else if(e instanceof W){a:{switch(e.Vk){case "None":case "Square":case "Ellipse":case "Circle":case "LineH":case "LineV":case "FramedRectangle":case "RoundedRectangle":case "Line1":case "Line2":case "Border":case "Cube1":case "Cube2":case "Junction":case "Cylinder1":case "Cylinder2":case "Cylinder3":case "Cylinder4":case "PlusLine":case "XLine":case "ThinCross":case "ThickCross":e=0;break a}e=
e.ih/2*e.Lj*e.Ce()}c=Math.max(c,e)}}return c}t.Zd=function(){return!(this.type===X.TableRow||this.type===X.TableColumn)};
t.Rb=function(a,b,c){if(!1===this.pickable)return null;void 0===b&&(b=null);void 0===c&&(c=null);if(pj(this))return null;var d=this.naturalBounds,e=1/this.Ce(),f=this.Zd(),g=f?a:Qb(H.allocAt(a.x,a.y),this.transform),h=this.diagram,k=10,l=5;null!==h&&(k=h.bm("extraTouchArea"),l=k/2);if(Cc(-(l*e),-(l*e),d.width+k*e,d.height+k*e,g.x,g.y)){if(!this.isAtomic){e=this.Y.j;var m=e.length;h=H.alloc();l=(k=this.isClipping)?this.zb():null;if(k&&(l.Zd()?Qb(h.set(a),l.transform):h.set(a),!l.da(h)))return H.free(h),
f||H.free(g),null;for(var n=mm(this);m--;){var p=e[m];if(p.visible||p===n)if(p.Zd()?Qb(h.set(a),p.transform):h.set(a),!k||p!==l){var q=null;p instanceof X?q=p.Rb(h,b,c):!0===p.pickable&&p.da(h)&&(q=p);if(null!==q&&(null!==b&&(q=b(q)),null!==q&&(null===c||c(q))))return H.free(h),f||H.free(g),q}}H.free(h)}if(null===this.background&&null===this.areaBackground)return f||H.free(g),null;a=Cc(0,0,d.width,d.height,g.x,g.y)?this:null;f||H.free(g);return a}f||H.free(g);return null};
t.Hi=function(a,b,c,d){if(!1===this.pickable)return!1;void 0===b&&(b=null);void 0===c&&(c=null);var e=this.naturalBounds,f=this.Zd(),g=f?a:Qb(H.allocAt(a.x,a.y),this.transform);e=Cc(0,0,e.width,e.height,g.x,g.y);if(this.type===X.TableRow||this.type===X.TableColumn||e){if(!this.isAtomic){for(var h=this.Y.j,k=h.length,l=H.alloc(),m=mm(this);k--;){var n=h[k];if(n.visible||n===m){n.Zd()?Qb(l.set(a),n.transform):l.set(a);var p=n;n=n instanceof X?n:null;(null!==n?n.Hi(l,b,c,d):p.da(l))&&!1!==p.pickable&&
(null!==b&&(p=b(p)),null===p||null!==c&&!c(p)||d.add(p))}}H.free(l)}f||H.free(g);return e&&(null!==this.background||null!==this.areaBackground)}f||H.free(g);return!1};
t.ng=function(a,b,c,d,e,f){if(!1===this.pickable)return!1;void 0===b&&(b=null);void 0===c&&(c=null);var g=f;void 0===f&&(g=Qc.alloc(),g.reset());g.multiply(this.transform);if(this.vh(a,g))return Vm(this,b,c,e),void 0===f&&Qc.free(g),!0;if(this.Ic(a,g)){if(!this.isAtomic)for(var h=mm(this),k=this.Y.j,l=k.length;l--;){var m=k[l];if(m.visible||m===h){var n=m.actualBounds,p=this.naturalBounds;if(!(n.x>p.width||n.y>p.height||0>n.x+n.width||0>n.y+n.height)){n=m;m=m instanceof X?m:null;p=Qc.alloc();p.set(g);
if(null!==m?m.ng(a,b,c,d,e,p):Zk(n,a,d,p))null!==b&&(n=b(n)),null===n||null!==c&&!c(n)||e.add(n);Qc.free(p)}}}void 0===f&&Qc.free(g);return d}void 0===f&&Qc.free(g);return!1};function Vm(a,b,c,d){for(var e=a.Y.j,f=e.length;f--;){var g=e[f];if(g.visible){var h=g.actualBounds,k=a.naturalBounds;h.x>k.width||h.y>k.height||0>h.x+h.width||0>h.y+h.height||(g instanceof X&&Vm(g,b,c,d),null!==b&&(g=b(g)),null===g||null!==c&&!c(g)||d.add(g))}}}
t.og=function(a,b,c,d,e,f){if(!1===this.pickable)return!1;void 0===c&&(c=null);void 0===d&&(d=null);var g=this.naturalBounds,h=this.Zd(),k=h?a:Qb(H.allocAt(a.x,a.y),this.transform),l=h?b:Qb(H.allocAt(b.x,b.y),this.transform),m=k.Be(l),n=0<k.x&&k.x<g.width&&0<k.y&&k.y<g.height||Rb(k.x,k.y,0,0,0,g.height)<=m||Rb(k.x,k.y,0,g.height,g.width,g.height)<=m||Rb(k.x,k.y,g.width,g.height,g.width,0)<=m||Rb(k.x,k.y,g.width,0,0,0)<=m;g=k.cd(0,0)<=m&&k.cd(0,g.height)<=m&&k.cd(g.width,0)<=m&&k.cd(g.width,g.height)<=
m;h||(H.free(k),H.free(l));if(n){if(!this.isAtomic){k=H.alloc();l=H.alloc();m=mm(this);for(var p=this.Y.j,q=p.length;q--;){var r=p[q];if(r.visible||r===m){var u=r.actualBounds,x=this.naturalBounds;if(!h||!(u.x>x.width||u.y>x.height||0>u.x+u.width||0>u.y+u.height))if(r.Zd()?(u=r.transform,Qb(k.set(a),u),Qb(l.set(b),u)):(k.set(a),l.set(b)),u=r,r=r instanceof X?r:null,null!==r?r.og(k,l,c,d,e,f):u.gx(k,l,e))null!==c&&(u=c(u)),null===u||null!==d&&!d(u)||f.add(u)}}H.free(k);H.free(l)}return e?n:g}return!1};
function rm(a){var b=null;a instanceof W&&(b=a.spot1,b===Ed&&(b=null),a=a.geometry,null!==a&&null===b&&(b=a.spot1));null===b&&(b=Tc);return b}function sm(a){var b=null;a instanceof W&&(b=a.spot2,b===Ed&&(b=null),a=a.geometry,null!==a&&null===b&&(b=a.spot2));null===b&&(b=hd);return b}t.add=function(a){this.Ib(this.Y.count,a)};t.N=function(a){return this.Y.N(a)};
t.Ib=function(a,b){b instanceof T&&A("Cannot add a Part to a Panel: "+b+"; use a Panel instead");if(this===b||this.rg(b))this===b&&A("Cannot make a Panel contain itself: "+this.toString()),A("Cannot make a Panel indirectly contain itself: "+this.toString()+" already contains "+b.toString());var c=b.panel;null!==c&&c!==this&&A("Cannot add a GraphObject that already belongs to another Panel to this Panel: "+b.toString()+", already contained by "+c.toString()+", cannot be shared by this Panel: "+this.toString());
this.va!==X.Grid||b instanceof W||A("Can only add Shapes to a Grid Panel, not: "+b);this.va!==X.Graduated||b instanceof W||b instanceof vh||A("Can only add Shapes or TextBlocks to a Graduated Panel, not: "+b);b.Ui(this);b.Cj=null;if(null!==this.itemArray){var d=b.data;null!==d&&"object"===typeof d&&(null===this.Ed&&(this.Ed=new Lb),this.Ed.add(d,b))}var e=this.Y;d=-1;if(c===this){for(var f=-1,g=this.Y.j,h=g.length,k=0;k<h;k++)if(g[k]===b){f=k;break}if(-1!==f){if(f===a||f+1>=e.count&&a>=e.count)return;
e.mb(f);d=f}else A("element "+b.toString()+" has panel "+c.toString()+" but is not contained by it.")}if(0>a||a>e.count)a=e.count;e.Ib(a,b);if(0===a||b.isPanelMain)this.vi=null;oj(this)||this.s();b.s(!1);null!==b.portId?this.yh=!0:b instanceof X&&!0===b.yh&&(this.yh=!0);this.Og=null;c=this.part;null!==c&&(c.xj=null,c.Wg=NaN,this.yh&&c instanceof V&&(c.yh=!0),c.yh&&c instanceof V&&(c.rc=null),e=this.diagram,null!==e&&e.undoManager.isUndoingRedoing||(-1!==d&&c.ab(df,"elements",this,b,null,d,null),c.ab(cf,
"elements",this,null,b,null,a),this.sg()||Wm(this,b,!1)))};function Xm(a,b){a.H=b?a.H|16777216:a.H&-16777217}t.remove=function(a){for(var b=this.Y.j,c=b.length,d=-1,e=0;e<c;e++)if(b[e]===a){d=e;break}-1!==d&&this.xc(d,!0)};t.mb=function(a){0<=a&&this.xc(a,!0)};
t.xc=function(a,b){var c=this.Y,d=c.N(a);d.Cj=null;d.Ui(null);if(null!==this.Ed){var e=d.data;"object"===typeof e&&this.Ed.remove(e)}c.mb(a);jj(this,!1);this.s();this.vi===d&&(this.vi=null);this.Og=null;var f=this.part;null!==f&&(f.xj=null,f.Wg=NaN,f.Jb(),f instanceof V&&(d instanceof X?d.ym(d,function(a,c){Gl(f,c,b)}):Gl(f,d,b)),c=this.diagram,null!==c&&c.undoManager.isUndoingRedoing||f.ab(df,"elements",this,d,null,a,null))};
X.prototype.getRowDefinition=function(a){0>a&&ya(a,">= 0",X,"getRowDefinition:idx");a=Math.round(a);var b=this.sb;if(void 0===b)return null;if(void 0===b[a]){var c=new Pj;c.Ui(this);c.isRow=!0;c.index=a;b[a]=c}return b[a]};X.prototype.Av=function(a){0>a&&ya(a,">= 0",X,"removeRowDefinition:idx");a=Math.round(a);var b=this.sb;void 0!==b&&(this.ab(df,"coldefs",this,b[a],null,a,null),b[a]&&delete b[a],this.s())};
X.prototype.getColumnDefinition=function(a){0>a&&ya(a,">= 0",X,"getColumnDefinition:idx");a=Math.round(a);var b=this.nb;if(void 0===b)return null;if(void 0===b[a]){var c=new Pj;c.Ui(this);c.isRow=!1;c.index=a;b[a]=c}return b[a]};t=X.prototype;t.yv=function(a){0>a&&ya(a,">= 0",X,"removeColumnDefinition:idx");a=Math.round(a);var b=this.nb;void 0!==b&&(this.ab(df,"coldefs",this,b[a],null,a,null),b[a]&&delete b[a],this.s())};
t.Ny=function(a){if(0>a||this.type!==X.Table)return-1;for(var b=0,c=this.sb,d=c.length,e=this.yi;e<d;e++){var f=c[e];if(void 0!==f&&(b+=f.total,a<b))break}return e};t.Fy=function(a){if(0>a||this.type!==X.Table)return-1;for(var b=0,c=this.nb,d=c.length,e=this.hi;e<d;e++){var f=c[e];if(void 0!==f&&(b+=f.total,a<b))break}return e};
t.ez=function(a,b){void 0===b&&(b=new H(NaN,NaN));if(this.type!==X.Graduated)return b.h(NaN,NaN),b;a=Math.min(Math.max(a,this.graduatedMin),this.graduatedMax);var c=this.zb();c.geometry.cv((a-this.graduatedMin)/this.graduatedRange,b);return c.transform.ta(b)};t.fz=function(a){if(this.type!==X.Graduated)return NaN;var b=this.zb();b.transform.Ud(a);return b.geometry.jx(a)*this.graduatedRange+this.graduatedMin};function xl(a){a=a.Rh;return null!==a&&a.v}
function Yg(a){var b=a.Rh;if(null===b)null!==a.data&&A("Template cannot have .data be non-null: "+a),a.Rh=b=new E;else if(b.v)return;var c=new E;Xm(a,!1);a.ym(a,function(a,d){var e=d.bb;if(null!==e)for(ul(d,!1),e=e.iterator;e.next();){var f=e.value;f.mode===Ym&&ul(d,!0);var g=f.sourceName;null!==g&&("/"===g&&Xm(a,!0),g=Wk(f,a,d),null!==g&&(c.add(g),null===g.Fl&&(g.Fl=new E),g.Fl.add(f)));b.add(f)}if(d instanceof X&&d.type===X.Table){if(0<d.sb.length)for(a=d.sb,e=a.length,f=0;f<e;f++)if(g=a[f],void 0!==
g&&null!==g.bb)for(var h=g.bb.iterator;h.next();){var k=h.value;k.Qd=g;k.yp=2;k.Nl=g.index;b.add(k)}if(0<d.nb.length)for(d=d.nb,a=d.length,e=0;e<a;e++)if(f=d[e],void 0!==f&&null!==f.bb)for(g=f.bb.iterator;g.next();)h=g.value,h.Qd=f,h.yp=1,h.Nl=f.index,b.add(h)}});for(var d=c.iterator;d.next();){var e=d.value;if(null!==e.Fl){ul(e,!0);for(var f=e.Fl.iterator;f.next();){var g=f.value;null===e.bb&&(e.bb=new E);e.bb.add(g)}}e.Fl=null}for(d=b.iterator;d.next();)if(e=d.value,f=e.Qd,null!==f){e.Qd=null;var h=
e.targetProperty,k=h.indexOf(".");0<k&&f instanceof X&&(g=h.substring(0,k),h=h.substr(k+1),k=f.$a(g),null!==k?(f=k,e.targetProperty=h):za('Warning: unable to find GraphObject named "'+g+'" for Binding: '+e.toString()));f instanceof Pj?(g=Bb(f.panel),e.Zi=void 0===g?-1:g,f.panel.Ek=e.Zi):f instanceof Y?(g=Bb(f),e.Zi=void 0===g?-1:g,f.Ek=e.Zi):A("Unknown type of binding target: "+f)}b.freeze();a instanceof T&&a.bc()&&a.$b()}
t.ty=function(){var a=this.copy();Qm(a,function(a){a instanceof X&&(a.Rh=null,a.jb=null);var b=a.bb;null!==b&&(a.bb=null,b.each(function(b){a.bind(b.copy())}))});return a};
t.Ea=function(a){var b=this.Rh;if(null!==b)for(void 0===a&&(a=""),b=b.iterator;b.next();){var c=b.value,d=c.sourceProperty;if(""===a||""===d||d===a)if(d=c.targetProperty,null!==c.converter||""!==d){d=this.data;var e=c.sourceName;if(null!==e)d=""===e?this:"/"===e?this:"."===e?this:".."===e?this:this.$a(e);else{var f=this.diagram;null!==f&&c.isToModel&&(d=f.model.modelData)}if(null!==d){f=this;var g=c.Zi;if(-1!==g){if(f=this.Ps(g),null===f)continue}else null!==c.Qd&&(f=c.Qd);"/"===e?d=f.part:"."===
e?d=f:".."===e&&(d=f.panel);e=c.yp;if(0!==e){if(!(f instanceof X))continue;1===e?f=f.getColumnDefinition(c.Nl):2===e&&(f=f.getRowDefinition(c.Nl))}void 0!==f&&c.Xv(f,d)}}}};function Zm(a,b){a=a.Y.j;for(var c=a.length,d=b.length,e=0,f=null;e<c&&!(f=a[e],f instanceof X&&null!==f.data);)e++,f=a[e];if(c-e!==d)return!0;if(null===f)return 0<d;for(var g=0;e<c&&g<d;){f=a[e];if(!(f instanceof X)||f.data!==b[g])return!0;e++;g++}return!1}
function Tm(a){if(a.type===X.Spot||a.type===X.Auto)return Math.min(a.Y.length,1);if(a.type===X.Link){a=a.Y;for(var b=a.length,c=0;c<b;c++){var d=a.N(c);if(!(d instanceof W&&d.isPanelMain))break}return c}return a.type===X.Table&&0<a.Y.length&&(a=a.Y.N(0),a.isPanelMain&&a instanceof X&&(a.type===X.TableRow||a.type===X.TableColumn))?1:0}t.mt=function(){for(var a=Tm(this);this.Y.length>a;)this.xc(this.Y.length-1,!1);a=this.itemArray;if(null!==a)for(var b=a.length,c=0;c<b;c++)$m(this,a[c],c)};
t.ex=function(a){return void 0===a||null===a||null===this.Ed?null:this.Ed.J(a)};
function $m(a,b,c){if(!(void 0===b||null===b||0>c)){var d=an(a,b),e=a.itemTemplateMap,f=null;null!==e&&(f=e.J(d));null===f&&(bn||(bn=!0,za('No item template Panel found for category "'+d+'" on '+a),za("  Using default item template."),d=new X,e=new vh,e.bind(new Ei("text","",Qa)),d.add(e),cn=d),f=cn);d=f;null!==d&&(Yg(d),d=d.copy(),0!==(d.H&16777216)&&(e=a.Ii(),null!==e&&Xm(e,!0)),"object"===typeof b&&(null===a.Ed&&(a.Ed=new Lb),a.Ed.add(b,d)),e=c+Tm(a),a.Ib(e,d),d.jb=b,dn(a,e,c),d.jb=null,d.data=
b)}}function dn(a,b,c){for(a=a.Y;b<a.length;){var d=a.N(b);if(d instanceof X){var e=b,f=c;d.type===X.TableRow?d.row=e:d.type===X.TableColumn&&(d.column=e);d.itemIndex=f}b++;c++}}function an(a,b){if(null===b)return"";a=a.gl;if("function"===typeof a)a=a(b);else if("string"===typeof a&&"object"===typeof b){if(""===a)return"";a=en(b,a)}else return"";if(void 0===a)return"";if("string"===typeof a)return a;A("Panel.getCategoryForItemData found a non-string category for "+b+": "+a);return""}
function Wm(a,b,c){var d=b.enabledChanged;null!==d&&d(b,c);if(b instanceof X){b=b.Y.j;d=b.length;for(var e=0;e<d;e++){var f=b[e];c&&f instanceof X&&!f.isEnabled||Wm(a,f,c)}}}function fn(a,b){Bl.add(a,b)}
pa.Object.defineProperties(X.prototype,{type:{get:function(){return this.va},set:function(a){var b=this.va;b!==a&&(this.va=a,this.va===X.Grid?this.isAtomic=!0:this.va===X.Table&&Km(this),this.s(),this.g("type",b,a))}},elements:{get:function(){return this.Y.iterator}},naturalBounds:{get:function(){return this.qc}},padding:{get:function(){return this.eb},set:function(a){"number"===
typeof a?(0>a&&ya(a,">= 0",X,"padding"),a=new Ic(a)):(0>a.left&&ya(a.left,">= 0",X,"padding:value.left"),0>a.right&&ya(a.right,">= 0",X,"padding:value.right"),0>a.top&&ya(a.top,">= 0",X,"padding:value.top"),0>a.bottom&&ya(a.bottom,">= 0",X,"padding:value.bottom"));var b=this.eb;b.A(a)||(this.eb=a=a.I(),this.s(),this.g("padding",b,a))}},defaultAlignment:{get:function(){return this.mn},set:function(a){var b=this.mn;b.A(a)||(this.mn=a=a.I(),this.s(),this.g("defaultAlignment",
b,a))}},defaultStretch:{get:function(){return this.Ef},set:function(a){var b=this.Ef;b!==a&&(this.Ef=a,this.s(),this.g("defaultStretch",b,a))}},defaultSeparatorPadding:{get:function(){return void 0===this.fj?Oc:this.fj},set:function(a){if(void 0!==this.fj){"number"===typeof a&&(a=new Ic(a));var b=this.fj;b.A(a)||(this.fj=a=a.I(),this.s(),this.g("defaultSeparatorPadding",b,a))}}},defaultRowSeparatorStroke:{get:function(){return void 0===
this.Wh?null:this.Wh},set:function(a){var b=this.Wh;b!==a&&(null===a||"string"===typeof a||a instanceof il)&&(a instanceof il&&a.freeze(),this.Wh=a,this.R(),this.g("defaultRowSeparatorStroke",b,a))}},defaultRowSeparatorStrokeWidth:{get:function(){return void 0===this.Ig?1:this.Ig},set:function(a){if(void 0!==this.Ig){var b=this.Ig;b!==a&&isFinite(a)&&0<=a&&(this.Ig=a,this.s(),this.g("defaultRowSeparatorStrokeWidth",b,a))}}},defaultRowSeparatorDashArray:{
get:function(){return void 0===this.Vh?null:this.Vh},set:function(a){if(void 0!==this.Vh){var b=this.Vh;if(b!==a){if(null!==a){for(var c=a.length,d=0,e=0;e<c;e++){var f=a[e];"number"===typeof f&&0<=f&&isFinite(f)||A("defaultRowSeparatorDashArray value "+f+" at index "+e+" must be a positive number or zero.");d+=f}if(0===d){if(null===b)return;a=null}}this.Vh=a;this.R();this.g("defaultRowSeparatorDashArray",b,a)}}}},defaultColumnSeparatorStroke:{get:function(){return void 0===
this.Gg?null:this.Gg},set:function(a){if(void 0!==this.Gg){var b=this.Gg;b!==a&&(null===a||"string"===typeof a||a instanceof il)&&(a instanceof il&&a.freeze(),this.Gg=a,this.R(),this.g("defaultColumnSeparatorStroke",b,a))}}},defaultColumnSeparatorStrokeWidth:{get:function(){return void 0===this.Hg?1:this.Hg},set:function(a){if(void 0!==this.Hg){var b=this.Hg;b!==a&&isFinite(a)&&0<=a&&(this.Hg=a,this.s(),this.g("defaultColumnSeparatorStrokeWidth",b,a))}}},defaultColumnSeparatorDashArray:{
get:function(){return void 0===this.Uh?null:this.Uh},set:function(a){if(void 0!==this.Uh){var b=this.Uh;if(b!==a){if(null!==a){for(var c=a.length,d=0,e=0;e<c;e++){var f=a[e];"number"===typeof f&&0<=f&&isFinite(f)||A("defaultColumnSeparatorDashArray value "+f+" at index "+e+" must be a positive number or zero.");d+=f}if(0===d){if(null===b)return;a=null}}this.Uh=a;this.R();this.g("defaultColumnSeparatorDashArray",b,a)}}}},viewboxStretch:{get:function(){return this.Hp},
set:function(a){var b=this.Hp;b!==a&&(this.Hp=a,this.s(),this.g("viewboxStretch",b,a))}},gridCellSize:{get:function(){return this.Kn},set:function(a){var b=this.Kn;if(!b.A(a)){a.o()&&0!==a.width&&0!==a.height||A("Invalid Panel.gridCellSize: "+a);this.Kn=a.I();var c=this.diagram;null!==c&&this===c.grid&&kj(c);this.R();this.g("gridCellSize",b,a)}}},gridOrigin:{get:function(){return this.Ln},set:function(a){var b=this.Ln;if(!b.A(a)){a.o()||
A("Invalid Panel.gridOrigin: "+a);this.Ln=a.I();var c=this.diagram;null!==c&&this===c.grid&&kj(c);this.R();this.g("gridOrigin",b,a)}}},graduatedMin:{get:function(){return this.Hn},set:function(a){var b=this.Hn;b!==a&&(this.Hn=a,this.s(),this.g("graduatedMin",b,a),Uk(this)&&(a=this.part,null!==a&&Vk(this,a,"graduatedRange")))}},graduatedMax:{get:function(){return this.Gn},set:function(a){var b=this.Gn;b!==a&&(this.Gn=a,this.s(),this.g("graduatedMax",
b,a),Uk(this)&&(a=this.part,null!==a&&Vk(this,a,"graduatedRange")))}},graduatedRange:{get:function(){return this.graduatedMax-this.graduatedMin}},graduatedTickUnit:{get:function(){return this.Jn},set:function(a){var b=this.Jn;b!==a&&0<a&&(this.Jn=a,this.s(),this.g("graduatedTickUnit",b,a))}},graduatedTickBase:{get:function(){return this.In},set:function(a){var b=this.In;b!==a&&(this.In=a,this.s(),this.g("graduatedTickBase",
b,a))}},yh:{get:function(){return 0!==(this.H&8388608)},set:function(a){0!==(this.H&8388608)!==a&&(this.H^=8388608)}},rowCount:{get:function(){return void 0===this.sb?0:this.sb.length}},columnCount:{get:function(){return void 0===this.nb?0:this.nb.length}},rowSizing:{get:function(){return void 0===this.Hj?Lm:this.Hj},set:function(a){if(void 0!==this.Hj){var b=this.Hj;b!==a&&(this.Hj=
a,this.s(),this.g("rowSizing",b,a))}}},columnSizing:{get:function(){return void 0===this.dj?Lm:this.dj},set:function(a){if(void 0!==this.dj){var b=this.dj;b!==a&&(this.dj=a,this.s(),this.g("columnSizing",b,a))}}},topIndex:{get:function(){return void 0===this.yi?0:this.yi},set:function(a){if(void 0!==this.yi){var b=this.yi;b!==a&&((!isFinite(a)||0>a)&&A("Panel.topIndex must be greater than zero and a real number, not: "+a),this.yi=a,this.s(),
this.g("topIndex",b,a))}}},leftIndex:{get:function(){return void 0===this.hi?0:this.hi},set:function(a){if(void 0!==this.hi){var b=this.hi;b!==a&&((!isFinite(a)||0>a)&&A("Panel.leftIndex must be greater than zero and a real number, not: "+a),this.hi=a,this.s(),this.g("leftIndex",b,a))}}},data:{get:function(){return this.jb},set:function(a){var b=this.jb;if(b!==a){var c=this instanceof T&&!(this instanceof pf);Yg(this);this.jb=a;var d=this.diagram;
null!==d&&(c?(c=d.partManager,this instanceof S?(null!==b&&c.Fg.remove(b),null!==a&&c.Fg.add(a,this)):this instanceof T&&(null!==b&&c.Me.remove(b),null!==a&&c.Me.add(a,this))):(c=this.panel,null!==c&&null!==c.Ed&&(null!==b&&c.Ed.remove(b),null!==a&&c.Ed.add(a,this))));this.g("data",b,a);null!==d&&d.undoManager.isUndoingRedoing||null!==a&&this.Ea()}}},itemIndex:{get:function(){return this.Yn},set:function(a){var b=this.Yn;b!==a&&(this.Yn=a,this.g("itemIndex",b,a))}},itemArray:{
get:function(){return this.fi},set:function(a){var b=this.fi;if(b!==a||null!==a&&Zm(this,a)){var c=this.diagram;b!==a&&(null!==c&&null!==b&&Gj(c.partManager,this,c),this.fi=a,null!==c&&null!==a&&Dj(c.partManager,this));this.g("itemArray",b,a);null!==c&&c.undoManager.isUndoingRedoing||this.mt()}}},itemTemplate:{get:function(){return null===this.ie?null:this.ie.J("")},set:function(a){if(null===this.ie){if(null===a)return;this.ie=new Lb}var b=this.ie.J("");
b!==a&&((a instanceof T||a.isPanelMain)&&A("Panel.itemTemplate must not be a Part or be Panel.isPanelMain: "+a),this.ie.add("",a),this.g("itemTemplate",b,a),a=this.diagram,null!==a&&a.undoManager.isUndoingRedoing||this.mt())}},itemTemplateMap:{get:function(){return this.ie},set:function(a){var b=this.ie;if(b!==a){for(var c=a.iterator;c.next(););this.ie=a;this.g("itemTemplateMap",b,a);a=this.diagram;null!==a&&a.undoManager.isUndoingRedoing||this.mt()}}},itemCategoryProperty:{
get:function(){return this.gl},set:function(a){var b=this.gl;b!==a&&(this.gl=a,this.g("itemCategoryProperty",b,a))}},isAtomic:{get:function(){return 0!==(this.H&1048576)},set:function(a){var b=0!==(this.H&1048576);b!==a&&(this.H^=1048576,this.g("isAtomic",b,a))}},isClipping:{get:function(){return 0!==(this.H&2097152)},set:function(a){var b=0!==(this.H&2097152);b!==a&&(this.H^=2097152,this.s(),this.g("isClipping",b,a))}},isOpposite:{
get:function(){return 0!==(this.H&33554432)},set:function(a){var b=0!==(this.H&33554432);b!==a&&(this.H^=33554432,this.s(),this.g("isOpposite",b,a))}},isEnabled:{get:function(){return 0!==(this.H&4194304)},set:function(a){var b=0!==(this.H&4194304);if(b!==a){var c=null===this.panel||this.panel.sg();this.H^=4194304;this.g("isEnabled",b,a);b=this.diagram;null!==b&&b.undoManager.isUndoingRedoing||c&&Wm(this,this,a)}}},alignmentFocusName:{
get:function(){return this.zg},set:function(a){var b=this.zg;b!==a&&(this.zg=a,this.s(),this.g("alignmentFocusName",b,a))}}});
pa.Object.defineProperties(X,{Position:{get:function(){return Bl.J("Position")}},Horizontal:{get:function(){return Bl.J("Horizontal")}},Vertical:{get:function(){return Bl.J("Vertical")}},Spot:{get:function(){return Bl.J("Spot")}},Auto:{get:function(){return Bl.J("Auto")}},Table:{get:function(){return Bl.J("Table")}},Viewbox:{
get:function(){return Bl.J("Viewbox")}},TableRow:{get:function(){return Bl.J("TableRow")}},TableColumn:{get:function(){return Bl.J("TableColumn")}},Link:{get:function(){return Bl.J("Link")}},Grid:{get:function(){return Bl.J("Grid")}},Graduated:{get:function(){return Bl.J("Graduated")}}});X.prototype.findItemPanelForData=X.prototype.ex;
X.prototype.rebuildItemElements=X.prototype.mt;X.prototype.updateTargetBindings=X.prototype.Ea;X.prototype.copyTemplate=X.prototype.ty;X.prototype.graduatedValueForPoint=X.prototype.fz;X.prototype.graduatedPointForValue=X.prototype.ez;X.prototype.findColumnForLocalX=X.prototype.Fy;X.prototype.findRowForLocalY=X.prototype.Ny;X.prototype.removeColumnDefinition=X.prototype.yv;X.prototype.removeRowDefinition=X.prototype.Av;X.prototype.removeAt=X.prototype.mb;X.prototype.remove=X.prototype.remove;
X.prototype.insertAt=X.prototype.Ib;X.prototype.elt=X.prototype.N;X.prototype.add=X.prototype.add;X.prototype.findObject=X.prototype.$a;X.prototype.findInVisualTree=X.prototype.Yl;X.prototype.walkVisualTreeFrom=X.prototype.ym;X.prototype.findMainElement=X.prototype.zb;var bn=!1,cn=null,Bl=new Lb;X.className="Panel";X.definePanelLayout=fn;fn("Position",new lm);fn("Vertical",new om);fn("Auto",new qm);fn("Link",new Am);fn("Grid",new zm);
function Pj(){nb(this);this.ag=null;this.Fr=!0;this.Sa=0;this.Pc=NaN;this.Yg=0;this.Xg=Infinity;this.wb=Ed;this.sa=this.ka=0;this.bb=null;this.qp=gn;this.ve=Kk;this.mp=this.eg=null;this.np=NaN;this.ib=this.Jj=null;this.gn=!1}
Pj.prototype.copy=function(){var a=new Pj;a.Fr=this.Fr;a.Sa=this.Sa;a.Pc=this.Pc;a.Yg=this.Yg;a.Xg=this.Xg;a.wb=this.wb;a.ka=this.ka;a.sa=this.sa;a.ve=this.ve;a.qp=this.qp;null===this.eg?a.eg=null:a.eg=this.eg.I();a.mp=this.mp;a.np=this.np;a.Jj=null;null!==this.Jj&&(a.separatorDashArray=Ga(this.separatorDashArray));a.ib=this.ib;a.gn=this.gn;a.bb=this.bb;return a};t=Pj.prototype;
t.Vl=function(a){a.isRow?this.height=a.height:this.width=a.width;this.minimum=a.minimum;this.maximum=a.maximum;this.alignment=a.alignment;this.stretch=a.stretch;this.sizing=a.sizing;this.eg=null===a.separatorPadding?null:a.separatorPadding.I();this.separatorStroke=a.separatorStroke;this.separatorStrokeWidth=a.separatorStrokeWidth;this.Jj=null;a.separatorDashArray&&(this.Jj=Ga(a.separatorDashArray));this.background=a.background;this.coversSeparators=a.coversSeparators;this.bb=a.bb};
t.fb=function(a){a.classType===Pj&&(this.sizing=a)};t.toString=function(){return"RowColumnDefinition "+(this.isRow?"(Row ":"(Column ")+this.index+") #"+Bb(this)};t.Ui=function(a){this.ag=a};
t.Nu=function(){var a=0,b=0,c=this.ag,d=this.isRow;if(null!==c&&c.type===X.Table)for(var e=d?c.sb.length:c.nb.length,f=0;f<e;f++){var g=d?c.sb[f]:c.nb[f];if(void 0!==g){b=g.index;break}}this.index!==b&&(b=this.separatorStroke,null===b&&null!==c&&(b=this.isRow?c.defaultRowSeparatorStroke:c.defaultColumnSeparatorStroke),null!==b&&(a=this.separatorStrokeWidth,isNaN(a)&&(null!==c?a=this.isRow?c.defaultRowSeparatorStrokeWidth:c.defaultColumnSeparatorStrokeWidth:a=0)));b=this.eg;if(null===b)if(null!==c)b=
c.defaultSeparatorPadding;else return a;return a+(this.isRow?b.top:b.left)};
t.tc=function(){var a=0,b=this.ag,c=0,d=this.isRow;if(null!==b&&b.type===X.Table)for(var e=d?b.sb.length:b.nb.length,f=0;f<e;f++){var g=d?b.sb[f]:b.nb[f];if(void 0!==g){c=g.index;break}}this.index!==c&&(c=this.separatorStroke,null===c&&null!==b&&(c=d?b.defaultRowSeparatorStroke:b.defaultColumnSeparatorStroke),null!==c&&(a=this.separatorStrokeWidth,isNaN(a)&&(null!==b?a=d?b.defaultRowSeparatorStrokeWidth:b.defaultColumnSeparatorStrokeWidth:a=0)));d=this.eg;if(null===d)if(null!==b)d=b.defaultSeparatorPadding;
else return a;return a+(this.isRow?d.top+d.bottom:d.left+d.right)};t.yb=function(a,b,c){var d=this.ag;if(null!==d&&(d.ab(af,a,this,b,c,void 0,void 0),null!==this.bb&&(b=d.diagram,null!==b&&!b.skipsModelSourceBindings&&(d=d.Ii(),null!==d&&(b=d.data,null!==b)))))for(c=this.bb.iterator;c.next();)c.value.xq(this,b,a,d)};function um(a){if(a.sizing===gn){var b=a.ag;return a.isRow?b.rowSizing:b.columnSizing}return a.sizing}
t.bind=function(a){a.Qd=this;var b=this.panel;if(null!==b){var c=b.Ii();null!==c&&xl(c)&&A("Cannot add a Binding to a RowColumnDefinition that is already frozen: "+a+" on "+b)}null===this.bb&&(this.bb=new E);this.bb.add(a)};
pa.Object.defineProperties(Pj.prototype,{panel:{get:function(){return this.ag}},isRow:{get:function(){return this.Fr},set:function(a){this.Fr=a}},index:{get:function(){return this.Sa},set:function(a){this.Sa=a}},height:{get:function(){return this.Pc},set:function(a){var b=this.Pc;b!==a&&(0>a&&ya(a,">= 0",Pj,"height"),this.Pc=a,this.actual=this.ka,null!==this.panel&&this.panel.s(),
this.yb("height",b,a))}},width:{get:function(){return this.Pc},set:function(a){var b=this.Pc;b!==a&&(0>a&&ya(a,">= 0",Pj,"width"),this.Pc=a,this.actual=this.ka,null!==this.panel&&this.panel.s(),this.yb("width",b,a))}},minimum:{get:function(){return this.Yg},set:function(a){var b=this.Yg;b!==a&&((0>a||!isFinite(a))&&ya(a,">= 0",Pj,"minimum"),this.Yg=a,this.actual=this.ka,null!==this.panel&&this.panel.s(),this.yb("minimum",b,a))}},maximum:{
get:function(){return this.Xg},set:function(a){var b=this.Xg;b!==a&&(0>a&&ya(a,">= 0",Pj,"maximum"),this.Xg=a,this.actual=this.ka,null!==this.panel&&this.panel.s(),this.yb("maximum",b,a))}},alignment:{get:function(){return this.wb},set:function(a){var b=this.wb;b.A(a)||(this.wb=a.I(),null!==this.panel&&this.panel.s(),this.yb("alignment",b,a))}},stretch:{get:function(){return this.ve},set:function(a){var b=this.ve;b!==a&&(this.ve=
a,null!==this.panel&&this.panel.s(),this.yb("stretch",b,a))}},separatorPadding:{get:function(){return this.eg},set:function(a){"number"===typeof a&&(a=new Ic(a));var b=this.eg;null!==a&&null!==b&&b.A(a)||(null!==a&&(a=a.I()),this.eg=a,null!==this.panel&&this.panel.s(),this.yb("separatorPadding",b,a))}},separatorStroke:{get:function(){return this.mp},set:function(a){var b=this.mp;b!==a&&(null!==a&&Fl(a,"RowColumnDefinition.separatorStroke"),
a instanceof il&&a.freeze(),this.mp=a,null!==this.panel&&this.panel.s(),this.yb("separatorStroke",b,a))}},separatorStrokeWidth:{get:function(){return this.np},set:function(a){var b=this.np;b!==a&&(this.np=a,null!==this.panel&&this.panel.s(),this.yb("separatorStrokeWidth",b,a))}},separatorDashArray:{get:function(){return this.Jj},set:function(a){var b=this.Jj;if(b!==a){if(null!==a){for(var c=a.length,d=0,e=0;e<c;e++){var f=a[e];"number"===
typeof f&&0<=f&&isFinite(f)||A("separatorDashArray value "+f+" at index "+e+" must be a positive number or zero.");d+=f}if(0===d){if(null===b)return;a=null}}this.Jj=a;null!==this.panel&&this.panel.R();this.yb("separatorDashArray",b,a)}}},background:{get:function(){return this.ib},set:function(a){var b=this.ib;b!==a&&(null!==a&&Fl(a,"RowColumnDefinition.background"),a instanceof il&&a.freeze(),this.ib=a,null!==this.panel&&this.panel.R(),this.yb("background",b,a))}},coversSeparators:{
get:function(){return this.gn},set:function(a){var b=this.gn;b!==a&&(this.gn=a,null!==this.panel&&this.panel.R(),this.yb("coversSeparators",b,a))}},sizing:{get:function(){return this.qp},set:function(a){var b=this.qp;b!==a&&(this.qp=a,null!==this.panel&&this.panel.s(),this.yb("sizing",b,a))}},actual:{get:function(){return this.ka},set:function(a){this.ka=isNaN(this.Pc)?Math.max(Math.min(this.Xg,a),this.Yg):Math.max(Math.min(this.Xg,
this.Pc),this.Yg)}},total:{get:function(){return this.ka+this.tc()},set:function(a){this.ka=isNaN(this.Pc)?Math.max(Math.min(this.Xg,a),this.Yg):Math.max(Math.min(this.Xg,this.Pc),this.Yg);this.ka=Math.max(0,this.ka-this.tc())}},position:{get:function(){return this.sa},set:function(a){this.sa=a}}});Pj.prototype.bind=Pj.prototype.bind;Pj.prototype.computeEffectiveSpacing=Pj.prototype.tc;Pj.prototype.computeEffectiveSpacingTop=Pj.prototype.Nu;
var gn=new D(Pj,"Default",0),vm=new D(Pj,"None",1),Lm=new D(Pj,"ProportionalExtra",2);Pj.className="RowColumnDefinition";Pj.Default=gn;Pj.None=vm;Pj.ProportionalExtra=Lm;function W(){Y.call(this);this.Pd=this.qa=null;this.Vk="None";this.Fn=Kk;this.Gc=this.Wk="black";this.ih=1;this.Il="butt";this.Jl="miter";this.Lj=10;this.gh=null;this.hh=0;this.df=this.cf=Ed;this.No=this.Mo=NaN;this.Qn=!1;this.Po=null;this.Yk=this.Pl="None";this.Cd=1;this.Bd=0;this.zd=1;this.Ad=null}oa(W,Y);
W.prototype.cloneProtected=function(a){Y.prototype.cloneProtected.call(this,a);a.qa=this.qa;a.Vk=this.Vk;a.Fn=this.Fn;a.Pd=this.Pd;a.Wk=this.Wk;a.Gc=this.Gc;a.ih=this.ih;a.Il=this.Il;a.Jl=this.Jl;a.Lj=this.Lj;null!==this.gh&&(a.gh=Ga(this.gh));a.hh=this.hh;a.cf=this.cf.I();a.df=this.df.I();a.Mo=this.Mo;a.No=this.No;a.Qn=this.Qn;a.Po=this.Po;a.Pl=this.Pl;a.Yk=this.Yk;a.Cd=this.Cd;a.Bd=this.Bd;a.zd=this.zd;a.Ad=this.Ad};t=W.prototype;
t.fb=function(a){a===Zg||a===ah||a===Nk||a===Kk?this.geometryStretch=a:Y.prototype.fb.call(this,a)};t.toString=function(){return"Shape("+("None"!==this.figure?this.figure:"None"!==this.toArrow?this.toArrow:this.fromArrow)+")#"+Bb(this)};
function hn(a,b,c,d){var e=c.length;if(!(4>e)){var f=d.measuredBounds,g=Math.max(1,f.width);f=f.height;for(var h=c[0],k=c[1],l,m,n,p,q,r,u=0,x=Ka(),v=2;v<e;v+=2)l=c[v],m=c[v+1],n=l-h,h=m-k,0===n&&(n=.001),p=h/n,q=Math.atan2(h,n),r=Math.sqrt(n*n+h*h),x.push([n,q,p,r]),u+=r,h=l,k=m;h=c[0];k=c[1];n=d.measuredBounds.width;d instanceof W&&(n-=d.strokeWidth);1>n&&(n=1);e=c=n;l=g/2;m=0===l?!1:!0;v=0;r=x[v];n=r[0];q=r[1];p=r[2];r=r[3];for(var y=0;.1<=u;){0===y&&(m?(e=c,e-=l,u-=l,m=!1):e=c,0===e&&(e=1));if(e>
u){Oa(x);return}e>r?(y=e-r,e=r):y=0;var z=Math.sqrt(e*e/(1+p*p));0>n&&(z=-z);h+=z;k+=p*z;a.translate(h,k);a.rotate(q);a.translate(-(g/2),-(f/2));0===y&&d.Ei(a,b);a.translate(g/2,f/2);a.rotate(-q);a.translate(-h,-k);u-=e;r-=e;if(0!==y){v++;if(v===x.length){Oa(x);return}r=x[v];n=r[0];q=r[1];p=r[2];r=r[3];e=y}}Oa(x)}}
t.Ei=function(a,b){var c=this.Gc,d=this.Wk;if(null!==c||null!==d){var e=this.actualBounds,f=this.naturalBounds;null!==d&&ji(this,a,d,!0,!1,f,e);null!==c&&ji(this,a,c,!1,!1,f,e);e=this.part;f=this.ih;0===f&&null!==e&&(f=e instanceof pf&&e.type===X.Link&&"Selection"===e.category&&e.adornedObject instanceof W&&e.adornedPart.zb()===e.adornedObject?e.adornedObject.strokeWidth:0);a.lineWidth=f;a.lineJoin=this.Jl;a.lineCap=this.Il;a.miterLimit=this.Lj;var g=!1;e&&b.De("drawShadows")&&(g=e.isShadowed);var h=
!0;null!==c&&null===d&&(h=!1);e=!1;var k=this.strokeDashArray;null!==k&&(e=!0,a.Os(k,this.hh));var l=this.qa;if(null!==l){if(l.type===ce)a.beginPath(),a.moveTo(l.startX,l.startY),a.lineTo(l.endX,l.endY),null!==d&&a.Td(d),0!==f&&null!==c&&a.Yi();else if(l.type===ke){var m=l.startX,n=l.startY,p=l.endX,q=l.endY;k=Math.min(m,p);l=Math.min(n,q);m=Math.abs(p-m);n=Math.abs(q-n);a.beginPath();a.rect(k,l,m,n);null!==d&&a.Td(d);if(null!==c){p=d=c=0;h&&g&&(c=a.shadowOffsetX,d=a.shadowOffsetY,p=a.shadowBlur,
a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0);if(0!==f){if(0===m||0===n)a.beginPath(),a.rect(k,l,Math.max(m,.1),Math.max(n,.1));a.Yi()}h&&g&&(a.shadowOffsetX=c,a.shadowOffsetY=d,a.shadowBlur=p)}}else if(l.type===le)n=l.startX,k=l.startY,p=l.endX,q=l.endY,l=Math.abs(p-n)/2,m=Math.abs(q-k)/2,n=Math.min(n,p)+l,k=Math.min(k,q)+m,a.beginPath(),a.moveTo(n,k-m),a.bezierCurveTo(n+I.yg*l,k-m,n+l,k-I.yg*m,n+l,k),a.bezierCurveTo(n+l,k+I.yg*m,n+I.yg*l,k+m,n,k+m),a.bezierCurveTo(n-I.yg*l,k+m,n-l,k+I.yg*
m,n-l,k),a.bezierCurveTo(n-l,k-I.yg*m,n-I.yg*l,k-m,n,k-m),a.closePath(),null!==d&&a.Td(d),0!==f&&null!==c&&(h&&g?(f=a.shadowOffsetX,g=a.shadowOffsetY,c=a.shadowBlur,a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0,a.Yi(),a.shadowOffsetX=f,a.shadowOffsetY=g,a.shadowBlur=c):a.Yi());else if(l.type===ae)for(k=l.figures,l=k.length,m=0;m<l;m++){n=k.j[m];a.beginPath();a.moveTo(n.startX,n.startY);p=n.segments.j;q=p.length;for(var r=null,u=0;u<q;u++){var x=p[u];switch(x.type){case ze:a.moveTo(x.endX,x.endY);
break;case ie:a.lineTo(x.endX,x.endY);break;case Ae:a.bezierCurveTo(x.point1X,x.point1Y,x.point2X,x.point2Y,x.endX,x.endY);break;case Be:a.quadraticCurveTo(x.point1X,x.point1Y,x.endX,x.endY);break;case Ce:if(x.radiusX===x.radiusY){var v=Math.PI/180;a.arc(x.point1X,x.point1Y,x.radiusX,x.startAngle*v,(x.startAngle+x.sweepAngle)*v,0>x.sweepAngle,null!==r?r.endX:n.startX,null!==r?r.endY:n.startY)}else if(r=Ee(x,n),v=r.length,0===v)a.lineTo(x.centerX,x.centerY);else for(var y=0;y<v;y++){var z=r[y];0===
y&&a.lineTo(z[0],z[1]);a.bezierCurveTo(z[2],z[3],z[4],z[5],z[6],z[7])}break;case De:y=v=0;if(null!==r&&r.type===Ce){r=Ee(r,n);z=r.length;if(0===z){a.lineTo(x.centerX,x.centerY);break}r=r[z-1]||null;null!==r&&(v=r[6],y=r[7])}else v=null!==r?r.endX:n.startX,y=null!==r?r.endY:n.startY;r=Fe(x,n,v,y);v=r.length;if(0===v){a.lineTo(x.centerX,x.centerY);break}for(y=0;y<v;y++)z=r[y],a.bezierCurveTo(z[2],z[3],z[4],z[5],z[6],z[7]);break;default:A("Segment not of valid type: "+x.type)}x.isClosed&&a.closePath();
r=x}g?(u=q=p=0,n.isShadowed?(!0===n.isFilled&&null!==d?(a.Td(d),h=!0):h=!1,0!==f&&null!==c&&(h&&(p=a.shadowOffsetX,q=a.shadowOffsetY,u=a.shadowBlur,a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0),a.Yi(),h&&(a.shadowOffsetX=p,a.shadowOffsetY=q,a.shadowBlur=u))):(h&&(p=a.shadowOffsetX,q=a.shadowOffsetY,u=a.shadowBlur,a.shadowOffsetX=0,a.shadowOffsetY=0,a.shadowBlur=0),!0===n.isFilled&&null!==d&&a.Td(d),0!==f&&null!==c&&a.Yi(),h&&(a.shadowOffsetX=p,a.shadowOffsetY=q,a.shadowBlur=u))):(!0===n.isFilled&&
null!==d&&a.Td(d),0!==f&&null!==c&&a.Yi())}e&&a.Ms();if(null!==this.pathPattern){e=this.pathPattern;e.measure(Infinity,Infinity);f=e.measuredBounds;e.arrange(0,0,f.width,f.height);g=this.geometry;a.save();a.beginPath();f=Ka();if(g.type===ce)f.push(g.startX),f.push(g.startY),f.push(g.endX),f.push(g.endY),hn(a,b,f,e);else if(g.type===ae)for(g=g.figures.iterator;g.next();){c=g.value;f.length=0;f.push(c.startX);f.push(c.startY);d=c.startX;h=c.startY;k=d;l=h;m=c.segments.j;n=m.length;for(p=0;p<n;p++){q=
m[p];switch(q.type){case ze:hn(a,b,f,e);f.length=0;f.push(q.endX);f.push(q.endY);d=q.endX;h=q.endY;k=d;l=h;break;case ie:f.push(q.endX);f.push(q.endY);d=q.endX;h=q.endY;break;case Ae:I.ze(d,h,q.point1X,q.point1Y,q.point2X,q.point2Y,q.endX,q.endY,.5,f);d=q.endX;h=q.endY;break;case Be:I.mq(d,h,q.point1X,q.point1Y,q.endX,q.endY,.5,f);d=q.endX;h=q.endY;break;case Ce:u=Ee(q,c);x=u.length;if(0===x){f.push(q.centerX);f.push(q.centerY);d=q.centerX;h=q.centerY;break}for(r=0;r<x;r++)v=u[r],I.ze(d,h,v[2],v[3],
v[4],v[5],v[6],v[7],.5,f),d=v[6],h=v[7];break;case De:u=Fe(q,c,d,h);x=u.length;if(0===x){f.push(q.centerX);f.push(q.centerY);d=q.centerX;h=q.centerY;break}for(r=0;r<x;r++)v=u[r],I.ze(d,h,v[2],v[3],v[4],v[5],v[6],v[7],.5,f),d=v[6],h=v[7];break;default:A("Segment not of valid type: "+q.type)}q.isClosed&&(f.push(k),f.push(l),hn(a,b,f,e))}hn(a,b,f,e)}else if(g.type===ke)f.push(g.startX),f.push(g.startY),f.push(g.endX),f.push(g.startY),f.push(g.endX),f.push(g.endY),f.push(g.startX),f.push(g.endY),f.push(g.startX),
f.push(g.startY),hn(a,b,f,e);else if(g.type===le){h=new Ue;h.startX=g.endX;h.startY=(g.startY+g.endY)/2;d=new Ve(Ce);d.startAngle=0;d.sweepAngle=360;d.centerX=(g.startX+g.endX)/2;d.centerY=(g.startY+g.endY)/2;d.radiusX=Math.abs(g.startX-g.endX)/2;d.radiusY=Math.abs(g.startY-g.endY)/2;h.add(d);g=Ee(d,h);c=g.length;if(0===c)f.push(d.centerX),f.push(d.centerY);else for(d=h.startX,h=h.startY,k=0;k<c;k++)l=g[k],I.ze(d,h,l[2],l[3],l[4],l[5],l[6],l[7],.5,f),d=l[6],h=l[7];hn(a,b,f,e)}Oa(f);a.restore();a.Rc(!1)}}}};
t.ma=function(a,b){void 0===b&&(b=new H);if(a instanceof O){a.jc()&&A("getDocumentPoint Spot must be a real, specific Spot, not: "+a.toString());var c=this.naturalBounds,d=this.strokeWidth;b.h(a.x*(c.width+d)-d/2+c.x+a.offsetX,a.y*(c.height+d)-d/2+c.y+a.offsetY)}else b.set(a);this.rd.ta(b);return b};
t.Yp=function(a){void 0===a&&(a=new N);var b=this.naturalBounds,c=this.rd;b=N.allocAt(b.x,b.y,b.width,b.height);var d=this.strokeWidth;b.Uc(d/2,d/2);d=H.allocAt(b.x,b.y).transform(c);a.h(d.x,d.y,0,0);d.h(b.right,b.y).transform(c);Bc(a,d.x,d.y,0,0);d.h(b.right,b.bottom).transform(c);Bc(a,d.x,d.y,0,0);d.h(b.x,b.bottom).transform(c);Bc(a,d.x,d.y,0,0);N.free(b);H.free(d);return a};
t.wh=function(a,b){var c=this.geometry;if(null===c||null===this.fill&&null===this.stroke)return!1;var d=c.bounds,e=this.strokeWidth/2;c.type!==ce||b||(e+=2);var f=N.alloc();f.assign(d);f.Uc(e+2,e+2);if(!f.da(a))return N.free(f),!1;d=e+1E-4;if(c.type===ce){if(null===this.stroke)return!1;d=(c.endX-c.startX)*(a.x-c.startX)+(c.endY-c.startY)*(a.y-c.startY);if(0>(c.startX-c.endX)*(a.x-c.endX)+(c.startY-c.endY)*(a.y-c.endY)||0>d)return!1;N.free(f);return I.Tb(c.startX,c.startY,c.endX,c.endY,e,a.x,a.y)}if(c.type===
ke){b=c.startX;var g=c.startY,h=c.endX;c=c.endY;f.x=Math.min(b,h);f.y=Math.min(g,c);f.width=Math.abs(h-b);f.height=Math.abs(c-g);if(null===this.fill){f.Uc(-d,-d);if(f.da(a))return N.free(f),!1;f.Uc(d,d)}null!==this.stroke&&f.Uc(e,e);a=f.da(a);N.free(f);return a}if(c.type===le){g=c.startX;e=c.startY;h=c.endX;var k=c.endY;c=Math.min(g,h);b=Math.min(e,k);g=Math.abs(h-g)/2;e=Math.abs(k-e)/2;c=a.x-(c+g);b=a.y-(b+e);if(null===this.fill){g-=d;e-=d;if(0>=g||0>=e||1>=c*c/(g*g)+b*b/(e*e))return N.free(f),!1;
g+=d;e+=d}null!==this.stroke&&(g+=d,e+=d);N.free(f);return 0>=g||0>=e?!1:1>=c*c/(g*g)+b*b/(e*e)}if(c.type===ae)return N.free(f),null===this.fill?He(c,a.x,a.y,e):c.da(a,e,1<this.strokeWidth,b);A("Unknown Geometry type: "+c.type);return!1};
t.hm=function(a,b,c,d){var e=this.desiredSize,f=this.ih;a=Math.max(a,0);b=Math.max(b,0);if(null!==this.Pd)var g=this.geometry.bounds;else{var h=this.figure,k=jn[h];if(void 0===k){var l=I.Je[h];"string"===typeof l&&(l=I.Je[l]);"function"===typeof l?(k=l(null,100,100),jn[h]=k):A("Unsupported Figure: "+h)}g=k.bounds}h=g.width;k=g.height;l=g.width;var m=g.height;switch($k(this,!0)){case Zg:d=c=0;break;case be:l=Math.max(a-f,0);m=Math.max(b-f,0);break;case Lk:l=Math.max(a-f,0);d=0;break;case Mk:c=0,m=
Math.max(b-f,0)}isFinite(e.width)&&(l=e.width);isFinite(e.height)&&(m=e.height);e=this.maxSize;g=this.minSize;c=Math.max(c-f,g.width);d=Math.max(d-f,g.height);l=Math.min(e.width,l);m=Math.min(e.height,m);l=isFinite(l)?Math.max(c,l):Math.max(h,c);m=isFinite(m)?Math.max(d,m):Math.max(k,d);c=$g(this);switch(c){case Zg:break;case be:h=l;k=m;break;case ah:c=Math.min(l/h,m/k);isFinite(c)||(c=1);h*=c;k*=c;break;default:A(c+" is not a valid geometryStretch.")}null!==this.Pd?(h=Math.max(h,.01),k=Math.max(k,
.01),g=null!==this.Pd?this.Pd:this.qa,e=h,d=k,c=g.copy(),g=g.bounds,e/=g.width,d/=g.height,isFinite(e)||(e=1),isFinite(d)||(d=1),1===e&&1===d||c.scale(e,d),this.qa=c):null!==this.qa&&I.ca(this.qa.el,a-f)&&I.ca(this.qa.cl,b-f)||(this.qa=W.makeGeometry(this,h,k));g=this.qa.bounds;Infinity===a||Infinity===b?Xk(this,g.x-f/2,g.y-f/2,0===a&&0===h?0:g.width+f,0===b&&0===k?0:g.height+f):Xk(this,-(f/2),-(f/2),l+f,m+f)};
function $g(a){var b=a.geometryStretch;return null!==a.Pd?b===Kk?be:b:b===Kk?jn[a.figure].defaultStretch:b}t.uh=function(a,b,c,d){bl(this,a,b,c,d)};t.Tc=function(a,b,c){return this.dk(a.x,a.y,b.x,b.y,c)};
t.dk=function(a,b,c,d,e){var f=this.transform,g=1/(f.m11*f.m22-f.m12*f.m21),h=f.m22*g,k=-f.m12*g,l=-f.m21*g,m=f.m11*g,n=g*(f.m21*f.dy-f.m22*f.dx),p=g*(f.m12*f.dx-f.m11*f.dy);f=a*h+b*l+n;g=a*k+b*m+p;h=c*h+d*l+n;k=c*k+d*m+p;n=this.ih/2;l=this.qa;null===l&&(this.measure(Infinity,Infinity),l=this.qa);p=l.bounds;m=!1;if(l.type===ce)if(1.5>=this.strokeWidth)m=I.Fe(l.startX,l.startY,l.endX,l.endY,f,g,h,k,e);else{l.startX===l.endX?(d=n,m=0):(b=(l.endY-l.startY)/(l.endX-l.startX),m=n/Math.sqrt(1+b*b),d=m*
b);b=Ka();a=new H;I.Fe(l.startX+d,l.startY+m,l.endX+d,l.endY+m,f,g,h,k,a)&&b.push(a);a=new H;I.Fe(l.startX-d,l.startY-m,l.endX-d,l.endY-m,f,g,h,k,a)&&b.push(a);a=new H;I.Fe(l.startX+d,l.startY+m,l.startX-d,l.startY-m,f,g,h,k,a)&&b.push(a);a=new H;I.Fe(l.endX+d,l.endY+m,l.endX-d,l.endY-m,f,g,h,k,a)&&b.push(a);h=b.length;if(0===h)return Oa(b),!1;m=!0;k=Infinity;for(d=0;d<h;d++)a=b[d],c=(a.x-f)*(a.x-f)+(a.y-g)*(a.y-g),c<k&&(k=c,e.x=a.x,e.y=a.y);Oa(b)}else if(l.type===ke)m=I.Tc(p.x-n,p.y-n,p.x+p.width+
n,p.y+p.height+n,f,g,h,k,e);else if(l.type===le){b=N.allocAt(p.x,p.y,p.width,p.height).Uc(n,n);a:if(0===b.width)m=I.Fe(b.x,b.y,b.x,b.y+b.height,f,g,h,k,e);else if(0===b.height)m=I.Fe(b.x,b.y,b.x+b.width,b.y,f,g,h,k,e);else{a=b.width/2;l=b.height/2;d=b.x+a;m=b.y+l;c=9999;f!==h&&(c=(g-k)/(f-h));if(9999>Math.abs(c)){k=g-m-c*(f-d);if(0>a*a*c*c+l*l-k*k){e.x=NaN;e.y=NaN;m=!1;break a}n=Math.sqrt(a*a*c*c+l*l-k*k);h=(-(a*a*c*k)+a*l*n)/(l*l+a*a*c*c)+d;a=(-(a*a*c*k)-a*l*n)/(l*l+a*a*c*c)+d;l=c*(h-d)+k+m;k=c*
(a-d)+k+m;Math.abs((f-h)*(f-h))+Math.abs((g-l)*(g-l))<Math.abs((f-a)*(f-a))+Math.abs((g-k)*(g-k))?(e.x=h,e.y=l):(e.x=a,e.y=k)}else{h=l*l;k=f-d;h-=h/(a*a)*k*k;if(0>h){e.x=NaN;e.y=NaN;m=!1;break a}k=Math.sqrt(h);h=m+k;k=m-k;Math.abs(h-g)<Math.abs(k-g)?(e.x=f,e.y=h):(e.x=f,e.y=k)}m=!0}N.free(b)}else if(l.type===ae){p=H.alloc();var q=h-f;var r=k-g;var u=q*q+r*r;e.x=h;e.y=k;for(var x=0;x<l.figures.count;x++){var v=l.figures.j[x],y=v.segments;q=v.startX;r=v.startY;for(var z=q,B=r,C=0;C<y.count;C++){var G=
y.j[C],K=G.type;var M=G.endX;var U=G.endY;var R=!1;switch(K){case ze:z=M;B=U;break;case ie:R=nn(q,r,M,U,f,g,h,k,p);break;case Ae:R=I.Op(q,r,G.point1X,G.point1Y,G.point2X,G.point2Y,M,U,f,g,h,k,.6,p);break;case Be:R=I.Op(q,r,(q+2*G.point1X)/3,(r+2*G.point1Y)/3,(2*G.point1X+M)/3,(2*G.point1X+M)/3,M,U,f,g,h,k,.6,p);break;case Ce:case De:K=G.type===Ce?Ee(G,v):Fe(G,v,q,r);var da=K.length;if(0===da){R=nn(q,r,G.centerX,G.centerY,f,g,h,k,p);break}U=null;for(M=0;M<da;M++){U=K[M];if(0===M&&nn(q,r,U[0],U[1],
f,g,h,k,p)){var ja=on(f,g,p,u,e);ja<u&&(u=ja,m=!0)}I.Op(U[0],U[1],U[2],U[3],U[4],U[5],U[6],U[7],f,g,h,k,.6,p)&&(ja=on(f,g,p,u,e),ja<u&&(u=ja,m=!0))}M=U[6];U=U[7];break;default:A("Unknown Segment type: "+K)}q=M;r=U;R&&(R=on(f,g,p,u,e),R<u&&(u=R,m=!0));G.isClosed&&(M=z,U=B,nn(q,r,M,U,f,g,h,k,p)&&(G=on(f,g,p,u,e),G<u&&(u=G,m=!0)))}}f=c-a;g=d-b;h=Math.sqrt(f*f+g*g);0!==h&&(f/=h,g/=h);e.x-=f*n;e.y-=g*n;H.free(p)}else A("Unknown Geometry type: "+l.type);if(!m)return!1;this.transform.ta(e);return!0};
function on(a,b,c,d,e){a=c.x-a;b=c.y-b;b=a*a+b*b;return b<d?(e.x=c.x,e.y=c.y,b):d}function nn(a,b,c,d,e,f,g,h,k){var l=!1,m=(e-g)*(b-d)-(f-h)*(a-c);if(0===m)return!1;k.x=((e*h-f*g)*(a-c)-(e-g)*(a*d-b*c))/m;k.y=((e*h-f*g)*(b-d)-(f-h)*(a*d-b*c))/m;(a>c?a-c:c-a)<(b>d?b-d:d-b)?(a=b<d?b:d,b=b<d?d:b,(k.y>a||I.ca(k.y,a))&&(k.y<b||I.ca(k.y,b))&&(l=!0)):(b=a<c?a:c,a=a<c?c:a,(k.x>b||I.ca(k.x,b))&&(k.x<a||I.ca(k.x,a))&&(l=!0));return l}
t.vh=function(a,b){if(void 0===b)return a.mf(this.actualBounds);var c=this.qa;null===c&&(this.measure(Infinity,Infinity),c=this.qa);c=c.bounds;var d=this.strokeWidth/2,e=!1,f=H.alloc();f.h(c.x-d,c.y-d);a.da(b.ta(f))&&(f.h(c.x-d,c.bottom+d),a.da(b.ta(f))&&(f.h(c.right+d,c.bottom+d),a.da(b.ta(f))&&(f.h(c.right+d,c.y-d),a.da(b.ta(f))&&(e=!0))));H.free(f);return e};
t.Ic=function(a,b){if(this.vh(a,b)||void 0===b&&(b=this.transform,a.mf(this.actualBounds)))return!0;var c=Qc.alloc();c.set(b);c.at();var d=a.left,e=a.right,f=a.top;a=a.bottom;var g=H.alloc();g.h(d,f);c.ta(g);if(this.wh(g,!0))return H.free(g),!0;g.h(e,f);c.ta(g);if(this.wh(g,!0))return H.free(g),!0;g.h(d,a);c.ta(g);if(this.wh(g,!0))return H.free(g),!0;g.h(e,a);c.ta(g);if(this.wh(g,!0))return H.free(g),!0;var h=H.alloc(),k=H.alloc();c.set(b);c.nv(this.transform);c.at();h.x=e;h.y=f;h.transform(c);g.x=
d;g.y=f;g.transform(c);b=!1;pn(this,g,h,k)?b=!0:(g.x=e,g.y=a,g.transform(c),pn(this,g,h,k)?b=!0:(h.x=d,h.y=a,h.transform(c),pn(this,g,h,k)?b=!0:(g.x=d,g.y=f,g.transform(c),pn(this,g,h,k)&&(b=!0))));H.free(g);Qc.free(c);H.free(h);H.free(k);return b};function pn(a,b,c,d){if(!a.Tc(b,c,d))return!1;a=b.x;b=b.y;var e=c.x,f=c.y;c=d.x;d=d.y;if(a===e)return b<f?(a=b,b=f):a=f,d>=a&&d<=b;a<e?(d=a,a=e):d=e;return c>=d&&c<=a}
t.gx=function(a,b,c){function d(a,b){for(var c=a.length,d=0;d<c;d+=2)if(b.cd(a[d],a[d+1])>e)return!0;return!1}if(c&&null!==this.fill&&this.wh(a,!0))return!0;var e=a.Be(b),f=e;1.5<this.strokeWidth&&(e=this.strokeWidth/2+Math.sqrt(e),e*=e);b=this.qa;if(null===b&&(this.measure(Infinity,Infinity),b=this.qa,null===b))return!1;if(!c){var g=b.bounds,h=g.x,k=g.y,l=g.x+g.width;g=g.y+g.height;if(Sb(a.x,a.y,h,k)<=e&&Sb(a.x,a.y,l,k)<=e&&Sb(a.x,a.y,h,g)<=e&&Sb(a.x,a.y,l,g)<=e)return!0}h=b.startX;k=b.startY;l=
b.endX;g=b.endY;if(b.type===ce){if(c=(h-l)*(a.x-l)+(k-g)*(a.y-g),Rb(a.x,a.y,h,k,l,g)<=(0<=(l-h)*(a.x-h)+(g-k)*(a.y-k)&&0<=c?e:f))return!0}else{if(b.type===ke)return b=!1,c&&(b=Rb(a.x,a.y,h,k,h,g)<=e||Rb(a.x,a.y,h,k,l,k)<=e||Rb(a.x,a.y,l,k,l,g)<=e||Rb(a.x,a.y,h,g,l,g)<=e),b;if(b.type===le){b=a.x-(h+l)/2;f=a.y-(k+g)/2;var m=Math.abs(l-h)/2,n=Math.abs(g-k)/2;if(0===m||0===n)return Rb(a.x,a.y,h,k,l,g)<=e?!0:!1;if(c){if(a=I.zy(m,n,b,f),a*a<=e)return!0}else return Sb(b,f,-m,0)>=e||Sb(b,f,0,-n)>=e||Sb(b,
f,0,n)>=e||Sb(b,f,m,0)>=e?!1:!0}else if(b.type===ae){l=b.bounds;f=l.x;h=l.y;k=l.x+l.width;l=l.y+l.height;if(a.x>k&&a.x<f&&a.y>l&&a.y<h&&Rb(a.x,a.y,f,h,f,l)>e&&Rb(a.x,a.y,f,h,k,h)>e&&Rb(a.x,a.y,k,l,f,l)>e&&Rb(a.x,a.y,k,l,k,h)>e)return!1;f=Math.sqrt(e);if(c){if(null===this.fill?He(b,a.x,a.y,f):b.da(a,f,!0))return!0}else{c=b.figures;for(b=0;b<c.count;b++){f=c.j[b];g=f.startX;m=f.startY;if(a.cd(g,m)>e)return!1;h=f.segments.j;k=h.length;for(l=0;l<k;l++)switch(n=h[l],n.type){case ze:case ie:g=n.endX;m=
n.endY;if(a.cd(g,m)>e)return!1;break;case Ae:var p=Ka();I.ze(g,m,n.point1X,n.point1Y,n.point2X,n.point2Y,n.endX,n.endY,.8,p);g=d(p,a);Oa(p);if(g)return!1;g=n.endX;m=n.endY;if(a.cd(g,m)>e)return!1;break;case Be:p=Ka();I.mq(g,m,n.point1X,n.point1Y,n.endX,n.endY,.8,p);g=d(p,a);Oa(p);if(g)return!1;g=n.endX;m=n.endY;if(a.cd(g,m)>e)return!1;break;case Ce:case De:p=n.type===Ce?Ee(n,f):Fe(n,f,g,m);var q=p.length;if(0===q){g=n.centerX;m=n.centerY;if(a.cd(g,m)>e)return!1;break}n=null;for(var r=Ka(),u=0;u<q;u++)if(n=
p[u],r.length=0,I.ze(n[0],n[1],n[2],n[3],n[4],n[5],n[6],n[7],.8,r),d(r,a))return Oa(r),!1;Oa(r);null!==n&&(g=n[6],m=n[7]);break;default:A("Unknown Segment type: "+n.type)}}return!0}}}return!1};t.ac=function(){this.qa=null};function qn(a){var b=a.diagram;null!==b&&b.undoManager.isUndoingRedoing||(a.segmentOrientation=rn,"None"!==a.Pl?(a.segmentIndex=-1,a.alignmentFocus=Sd):"None"!==a.Yk&&(a.segmentIndex=0,a.alignmentFocus=new O(1-Sd.x,Sd.y)))}
W.makeGeometry=function(a,b,c){if("None"!==a.toArrow)var d=sn[a.toArrow];else"None"!==a.fromArrow?d=sn[a.fromArrow]:(d=I.Je[a.figure],"string"===typeof d&&(d=I.Je[d]),void 0===d&&A("Unknown Shape.figure: "+a.figure),d=d(a,b,c),d.el=b,d.cl=c);if(null===d){var e=I.Je.Rectangle;"function"===typeof e&&(d=e(a,b,c))}return d};
function tn(a){var b=sn[a];if(void 0===b){var c=a.toLowerCase();if("none"===c)return"None";b=sn[c];if(void 0===b){var d=null,e;for(e in I.Bm)if(e.toLowerCase()===c){d=e;break}if(null!==d)return a=me(I.Bm[d],!1),sn[d]=a,c!==d&&(sn[c]=d),d}}return"string"===typeof b?b:b instanceof $d?a:null}
pa.Object.defineProperties(W.prototype,{geometry:{get:function(){return null!==this.qa?this.qa:this.Pd},set:function(a){var b=this.qa;if(b!==a){null!==a?this.Pd=this.qa=a.freeze():this.Pd=this.qa=null;var c=this.part;null!==c&&(c.Wg=NaN);this.s();this.g("geometry",b,a);Uk(this)&&(a=this.part,null!==a&&Vk(this,a,"geometryString"))}}},geometryString:{get:function(){return null===this.geometry?"":this.geometry.toString()},set:function(a){a=
me(a);var b=a.normalize();this.geometry=a;this.position=a=H.allocAt(-b.x,-b.y);H.free(a)}},isGeometryPositioned:{get:function(){return this.Qn},set:function(a){var b=this.Qn;b!==a&&(this.Qn=a,this.s(),this.g("isGeometryPositioned",b,a))}},fill:{get:function(){return this.Wk},set:function(a){var b=this.Wk;b!==a&&(null!==a&&Fl(a,"Shape.fill"),a instanceof il&&a.freeze(),this.Wk=a,this.R(),this.g("fill",b,a))}},stroke:{
get:function(){return this.Gc},set:function(a){var b=this.Gc;b!==a&&(null!==a&&Fl(a,"Shape.stroke"),a instanceof il&&a.freeze(),this.Gc=a,this.R(),this.g("stroke",b,a))}},strokeWidth:{get:function(){return this.ih},set:function(a){var b=this.ih;if(b!==a)if(0<=a){this.ih=a;this.s();var c=this.part;null!==c&&(c.Wg=NaN);this.g("strokeWidth",b,a)}else ya(a,"value >= 0",W,"strokeWidth:value")}},strokeCap:{get:function(){return this.Il},set:function(a){var b=
this.Il;b!==a&&("string"!==typeof a||"butt"!==a&&"round"!==a&&"square"!==a?ya(a,'"butt", "round", or "square"',W,"strokeCap"):(this.Il=a,this.R(),this.g("strokeCap",b,a)))}},strokeJoin:{get:function(){return this.Jl},set:function(a){var b=this.Jl;b!==a&&("string"!==typeof a||"miter"!==a&&"bevel"!==a&&"round"!==a?ya(a,'"miter", "bevel", or "round"',W,"strokeJoin"):(this.Jl=a,this.R(),this.g("strokeJoin",b,a)))}},strokeMiterLimit:{get:function(){return this.Lj},
set:function(a){var b=this.Lj;if(b!==a&&1<=a){this.Lj=a;this.R();var c=this.part;null!==c&&(c.Wg=NaN);this.g("strokeMiterLimit",b,a)}}},strokeDashArray:{get:function(){return this.gh},set:function(a){var b=this.gh;if(b!==a){if(null!==a){for(var c=a.length,d=0,e=0;e<c;e++){var f=a[e];0<=f&&isFinite(f)||A("strokeDashArray:value "+f+" at index "+e+" must be a positive number or zero.");d+=f}if(0===d){if(null===b)return;a=null}}this.gh=a;this.R();this.g("strokeDashArray",
b,a)}}},strokeDashOffset:{get:function(){return this.hh},set:function(a){var b=this.hh;b!==a&&0<=a&&(this.hh=a,this.R(),this.g("strokeDashOffset",b,a))}},figure:{get:function(){return this.Vk},set:function(a){var b=this.Vk;if(b!==a){var c=I.Je[a];"function"===typeof c?c=a:(c=I.Je[a.toLowerCase()])||A("Unknown Shape.figure: "+a);b!==c&&(a=this.part,null!==a&&(a.Wg=NaN),this.Vk=c,this.Pd=null,this.ac(),this.s(),this.g("figure",b,c))}}},toArrow:{
get:function(){return this.Pl},set:function(a){var b=this.Pl;!0===a?a="Standard":!1===a&&(a="");if(b!==a){var c=tn(a);null===c?A("Unknown Shape.toArrow: "+a):b!==c&&(this.Pl=c,this.Pd=null,this.ac(),this.s(),qn(this),this.g("toArrow",b,c))}}},fromArrow:{get:function(){return this.Yk},set:function(a){var b=this.Yk;!0===a?a="Standard":!1===a&&(a="");if(b!==a){var c=tn(a);null===c?A("Unknown Shape.fromArrow: "+a):b!==c&&(this.Yk=c,this.Pd=null,this.ac(),this.s(),
qn(this),this.g("fromArrow",b,c))}}},spot1:{get:function(){return this.cf},set:function(a){var b=this.cf;b.A(a)||(this.cf=a=a.I(),this.s(),this.g("spot1",b,a))}},spot2:{get:function(){return this.df},set:function(a){var b=this.df;b.A(a)||(this.df=a=a.I(),this.s(),this.g("spot2",b,a))}},parameter1:{get:function(){return this.Mo},set:function(a){var b=this.Mo;b!==a&&(this.Mo=a,this.ac(),this.s(),this.g("parameter1",
b,a))}},parameter2:{get:function(){return this.No},set:function(a){var b=this.No;b!==a&&(this.No=a,this.ac(),this.s(),this.g("parameter2",b,a))}},naturalBounds:{get:function(){if(null!==this.qa)return this.qc.assign(this.qa.bounds),this.qc;var a=this.desiredSize;return new N(0,0,a.width,a.height)}},pathPattern:{get:function(){return this.Po},set:function(a){var b=this.Po;b!==a&&(this.Po=a,this.R(),this.g("pathPattern",
b,a))}},geometryStretch:{get:function(){return this.Fn},set:function(a){var b=this.Fn;b!==a&&(this.Fn=a,this.g("geometryStretch",b,a))}},interval:{get:function(){return this.Cd},set:function(a){var b=this.Cd;a=Math.floor(a);if(b!==a&&0<=a){this.Cd=a;var c=this.diagram;null!==c&&this.panel===c.grid&&kj(c);this.s();c=this.panel;null!==c&&(c.Og=null);this.g("interval",b,a)}}},graduatedStart:{get:function(){return this.Bd},
set:function(a){var b=this.Bd;b!==a&&(0>a?a=0:1<a&&(a=1),this.Bd=a,this.s(),this.g("graduatedStart",b,a))}},graduatedEnd:{get:function(){return this.zd},set:function(a){var b=this.zd;b!==a&&(0>a?a=0:1<a&&(a=1),this.zd=a,this.s(),this.g("graduatedEnd",b,a))}},graduatedSkip:{get:function(){return this.Ad},set:function(a){var b=this.Ad;b!==a&&(this.Ad=a,this.s(),this.g("graduatedSkip",b,a))}}});W.prototype.intersectsRect=W.prototype.Ic;
W.prototype.containedInRect=W.prototype.vh;W.prototype.getNearestIntersectionPoint=W.prototype.Tc;W.prototype.getDocumentBounds=W.prototype.Yp;W.prototype.getDocumentPoint=W.prototype.ma;var sn=new tb,jn=new tb;W.className="Shape";W.getFigureGenerators=function(){var a=new Lb,b;for(b in I.Je)b!==b.toLowerCase()&&a.add(b,I.Je[b]);a.freeze();return a};W.defineFigureGenerator=function(a,b){var c=a.toLowerCase(),d=I.Je;d[a]=b;d[c]=a};
W.getArrowheadGeometries=function(){var a=new Lb;for(d in I.Bm)if(void 0===sn[d]){var b=me(I.Bm[d],!1);sn[d]=b;b=d.toLowerCase();b!==d&&(sn[b]=d)}for(var c in sn)if(c!==c.toLowerCase()){var d=sn[c];d instanceof $d&&a.add(c,d)}a.freeze();return a};W.defineArrowheadGeometry=function(a,b){var c=null;"string"===typeof b?c=me(b,!1):c=b;b=a.toLowerCase();"none"!==b&&a!==b||A("Shape.defineArrowheadGeometry name must not be empty or None or all-lower-case: "+a);var d=sn;d[a]=c;d[b]=a};
function vh(){Y.call(this);un||(vn(),un=!0);this.Ob="";this.Gc="black";this.ee="13px sans-serif";this.wi="start";this.yd=Zg;this.Bi=Hd;this.pj=!0;this.di=this.ei=!1;this.Zf=wn;this.kg=xn;this.Qr=this.oc=0;this.gu=this.hu=null;this.nd=new Jm;this.zn=!1;this.Cc=this.Wm=this.zp=this.xi=this.Ap=null;this.bf=this.af=0;this.me=Infinity;this.jl=0;this.Cd=1;this.Bd=0;this.zd=1;this.Ad=this.kj=null}oa(vh,Y);
vh.prototype.cloneProtected=function(a){Y.prototype.cloneProtected.call(this,a);a.Ob=this.Ob;a.Gc=this.Gc;a.ee=this.ee;a.wi=this.wi;a.yd=this.yd;a.Bi=this.Bi;a.pj=this.pj;a.ei=this.ei;a.di=this.di;a.Zf=this.Zf;a.kg=this.kg;a.oc=this.oc;a.Qr=this.Qr;a.hu=this.hu;a.gu=this.gu;a.nd.Vl(this.nd);a.zn=this.zn;a.Ap=this.Ap;a.xi=this.xi;a.zp=this.zp;a.Wm=this.Wm;a.Cc=this.Cc;a.af=this.af;a.bf=this.bf;a.me=this.me;a.jl=this.jl;a.Cd=this.Cd;a.Bd=this.Bd;a.zd=this.zd;a.kj=this.kj;a.Ad=this.Ad};
function Im(a,b){a.H=b.H|6144;a.lb=b.opacity;a.ib=b.background;a.dc=b.areaBackground;a.Nc=b.desiredSize.I();a.Qf=b.minSize.I();a.Pf=b.maxSize.I();a.Of=b.Of.copy();a.Ca=b.scale;a.zc=b.angle;a.ve=b.stretch;a.Vg=b.margin.I();a.wb=b.alignment.I();a.yk=b.alignmentFocus.I();a.yl=b.segmentFraction;a.zl=b.segmentOffset.I();a.Al=b.segmentOrientation;null!==b.kd&&(a.kd=b.kd.copy());a.Cl=b.shadowVisible;b instanceof vh&&(a.Ob=b.Ob,a.Gc=b.Gc,a.ee=b.ee,a.wi=b.wi,a.yd=b.yd,a.Bi=b.Bi,a.pj=b.pj,a.ei=b.ei,a.di=b.di,
a.Zf=b.Zf,a.kg=b.kg,a.nd.If=null,a.af=b.af,a.bf=b.bf,a.me=b.me,a.jl=b.jl,a.Cd=b.Cd,a.Bd=b.Bd,a.zd=b.zd,a.kj=b.kj,a.Ad=b.Ad)}t=vh.prototype;t.fb=function(a){a.classType===vh?this.wrap=a:Y.prototype.fb.call(this,a)};t.toString=function(){return 22<this.Ob.length?'TextBlock("'+this.Ob.substring(0,20)+'"...)':'TextBlock("'+this.Ob+'")'};t.s=function(){Y.prototype.s.call(this);this.gu=this.hu=null};
t.Ei=function(a,b){if(null!==this.Gc&&0!==this.Ob.length&&null!==this.ee){var c=this.naturalBounds,d=this.actualBounds,e=c.width,f=c.height,g=yn(this),h=a.textAlign=this.wi,k=b.Sn;"start"===h?h=k?"right":"left":"end"===h&&(h=k?"left":"right");k=this.ei;var l=this.di;ji(this,a,this.Gc,!0,!1,c,d);(k||l)&&ji(this,a,this.Gc,!1,!1,c,d);d=0;c=!1;var m=H.allocAt(0,0);this.rd.ta(m);var n=H.allocAt(0,g);this.rd.ta(n);var p=m.Be(n);H.free(m);H.free(n);m=b.scale;8>p*m*m&&(c=!0);b.Yc!==a&&(c=!1);!1===b.De("textGreeking")&&
(c=!1);b=this.af;p=this.bf;switch(this.flip){case Pk:a.translate(e,0);a.scale(-1,1);break;case Ok:a.translate(0,f);a.scale(1,-1);break;case Qk:a.translate(e,f),a.scale(-1,-1)}m=this.oc;n=(b+g+p)*m;f>n&&(d=this.Bi,d=d.y*f-d.y*n+d.offsetY);n=this.nd;for(var q=0;q<m;q++){var r=n.Wc[q];r>e&&(r=e);d+=b;var u=n.Ac[q],x=a,v=d,y=h,z=0;if(c)"left"===y?z=0:"right"===y?z=e-r:"center"===y&&(z=(e-r)/2),x.fillRect(0+z,v+.25*g,r,1);else{"left"===y?z=0:"right"===y?z=e:"center"===y&&(z=e/2);var B=null!==zn?zn(this,
g):.75*g;x.fillText(u,0+z,v+B);u=g/20|0;0===u&&(u=1);"right"===y?z-=r:"center"===y&&(z-=r/2);k&&(y=null!==An?An(this,g):.8*g,x.beginPath(),x.lineWidth=u,x.moveTo(0+z,v+y),x.lineTo(0+z+r,v+y),x.stroke());l&&(x.beginPath(),x.lineWidth=u,v=v+g-g/2.2|0,0!==u%2&&(v+=.5),x.moveTo(0+z,v),x.lineTo(0+z+r,v),x.stroke())}d+=g+p}switch(this.flip){case Pk:a.scale(-1,1);a.translate(-e,0);break;case Ok:a.scale(1,-1);a.translate(0,-f);break;case Qk:a.scale(-1,-1),a.translate(-e,-f)}}};
t.hm=function(a,b,c,d){this.jl=a;var e=this.ee;null!==Bn&&Cn!==e&&(Cn=Bn.font=e);e=this.nd;e.reset();var f;if(isNaN(this.desiredSize.width)){var g=this.Ob.replace(/\r\n/g,"\n").replace(/\r/g,"\n");if(0===g.length)g=0;else if(this.isMultiline){for(var h=f=0,k=!1;!k;){var l=g.indexOf("\n",h);-1===l&&(l=g.length,k=!0);f=Math.max(f,Dn(g.substr(h,l-h).trim()));h=l+1}g=f}else f=g.indexOf("\n",0),0<=f&&(g=g.substr(0,f)),g=Dn(g);g=Math.min(g,a/this.scale);g=Math.max(8,g)}else g=this.desiredSize.width;null!==
this.panel&&(g=Math.min(g,this.panel.maxSize.width));f=En(this,g,e);isNaN(this.desiredSize.height)?f=Math.min(f,b/this.scale):f=this.desiredSize.height;h=f;if(0!==e.Ec&&1!==e.Ac.length&&this.Zf===Fn&&(b=this.ee,b=this.Zf===Fn?Gn(b):0,k=this.af+this.bf,k=Math.max(0,yn(this)+k),h=Math.min(this.maxLines-1,Math.max(Math.floor(h/k+.01)-1,0)),!(h+1>=e.Ac.length))){k=e.Ac[h];for(b=Math.max(1,a-b);Dn(k)>b&&1<k.length;)k=k.substr(0,k.length-1);k+=Hn;b=Dn(k);e.Ac[h]=k;e.Ac=e.Ac.slice(0,h+1);e.Wc[h]=b;e.Wc=
e.Wc.slice(0,h+1);e.jg=e.Ac.length;e.Ec=Math.max(e.Ec,b);this.oc=e.jg}if(this.wrap===In||isNaN(this.desiredSize.width))g=isNaN(a)?e.Ec:Math.min(a,e.Ec),isNaN(this.desiredSize.width)&&(g=Math.max(8,g));g=Math.max(c,g);f=Math.max(d,f);sc(this.qc,g,f);Xk(this,0,0,g,f)};t.uh=function(a,b,c,d){bl(this,a,b,c,d)};
function Jn(a,b,c,d,e){b=b.trim();var f=0;var g=a.ee;var h=a.af+a.bf;h=Math.max(0,yn(a)+h);var k=a.Zf===Fn?Gn(g):0;if(a.oc>=a.me)null!==e&&e.h(0,h);else{var l=b;if(a.kg===Kn)if(c.jg=1,g=Dn(b),0===k||g<=d)c.Ec=Math.max(c.Ec,g),c.Wc.push(c.Ec),c.Ac.push(b),null!==e&&e.h(g,h);else{f=Ln(a,l);l=l.substr(f.length);b=Ln(a,l);for(g=Dn(f+b);0<b.length&&g<=d;)f+=b,l=l.substr(b.length),b=Ln(a,l),g=Dn((f+b).trim());f+=b.trim();for(d=Math.max(1,d-k);Dn(f)>d&&1<f.length;)f=f.substr(0,f.length-1);f+=Hn;b=Dn(f);
c.Wc.push(b);c.Ec=b;c.Ac.push(f);null!==e&&e.h(b,h)}else{k=0;0===l.length&&(k=1,c.Wc.push(0),c.Ac.push(l));for(;0<l.length;){var m=Ln(a,l);for(l=l.substr(m.length);Dn(m)>d;){var n=1;g=Dn(m.substr(0,n));for(b=0;g<=d;)n++,b=g,g=Dn(m.substr(0,n));1===n?(c.Wc[a.oc+k]=g,f=Math.max(f,g)):(c.Wc[a.oc+k]=b,f=Math.max(f,b));n--;1>n&&(n=1);c.Ac[a.oc+k]=m.substr(0,n);k++;m=m.substr(n);if(a.oc+k>a.me)break}b=Ln(a,l);for(g=Dn(m+b);0<b.length&&g<=d;)m+=b,l=l.substr(b.length),b=Ln(a,l),g=Dn((m+b).trim());m=m.trim();
if(""!==m&&("\u00ad"===m[m.length-1]&&(m=m.substring(0,m.length-1)+"\u2010"),0===b.length?(c.Wc.push(g),f=Math.max(f,g)):(b=Dn(m),c.Wc.push(b),f=Math.max(f,b)),c.Ac.push(m),k++,a.oc+k>a.me))break}c.jg=Math.min(a.me,k);c.Ec=Math.max(c.Ec,f);null!==e&&e.h(c.Ec,h*c.jg)}}}function Ln(a,b){if(a.kg===Mn)return b.substr(0,1);a=b.length;for(var c=0,d=Nn;c<a&&!d.test(b.charAt(c));)c++;for(;c<a&&d.test(b.charAt(c));)c++;return c>=a?b:b.substr(0,c)}
function Dn(a){return null===Bn?8*a.length:Bn.measureText(a).width}function yn(a){if(null!==a.nd.If)return a.nd.If;var b=a.ee;if(null===Bn){var c=16;return a.nd.If=c}void 0!==On[b]&&5E3>Pn?c=On[b]:(c=1.3*Bn.measureText("M").width,On[b]=c,Pn++);return a.nd.If=c}function Gn(a){if(null===Bn)return 6;if(void 0!==Qn[a]&&5E3>Rn)var b=Qn[a];else b=Bn.measureText(Hn).width,Qn[a]=b,Rn++;return b}
function En(a,b,c){var d=a.Ob.replace(/\r\n/g,"\n").replace(/\r/g,"\n"),e=a.af+a.bf;e=Math.max(0,yn(a)+e);if(0===d.length)return c.Ec=0,a.oc=1,e;if(!a.isMultiline){var f=d.indexOf("\n",0);0<=f&&(d=d.substr(0,f))}f=0;for(var g=a.oc=0,h,k=!1;!k;){h=d.indexOf("\n",g);-1===h&&(h=d.length,k=!0);if(g<=h){g=d.substr(g,h-g);if(a.kg!==Kn){c.jg=0;var l=L.alloc();Jn(a,g,c,b,l);f+=l.height;L.free(l);a.oc+=c.jg}else Jn(a,g,c,b,null),f+=e,a.oc++;a.oc===a.me&&(k=!0)}g=h+1}return a.Qr=f}
function vn(){Nn=/[ \u200b\u00ad]/;On=new tb;Qn=new tb;Bn=th?(new tk(null)).context:null}
pa.Object.defineProperties(vh.prototype,{font:{get:function(){return this.ee},set:function(a){var b=this.ee;b!==a&&(this.ee=a,this.nd.If=null,this.s(),this.g("font",b,a))}},text:{get:function(){return this.Ob},set:function(a){var b=this.Ob;null!==a&&void 0!==a?a=a.toString():a="";b!==a&&(this.Ob=a,this.s(),this.g("text",b,a))}},textAlign:{get:function(){return this.wi},set:function(a){var b=this.wi;b===a||"start"!==
a&&"end"!==a&&"left"!==a&&"right"!==a&&"center"!==a||(this.wi=a,this.R(),this.g("textAlign",b,a))}},flip:{get:function(){return this.yd},set:function(a){var b=this.yd;b!==a&&(this.yd=a,this.R(),this.g("flip",b,a))}},verticalAlignment:{get:function(){return this.Bi},set:function(a){var b=this.Bi;b.A(a)||(this.Bi=a=a.I(),rl(this),this.g("verticalAlignment",b,a))}},naturalBounds:{get:function(){if(!this.qc.o()){var a=
L.alloc();Jn(this,this.Ob,this.nd,999999,a);var b=a.width;L.free(a);a=En(this,b,this.nd);var c=this.desiredSize;isNaN(c.width)||(b=c.width);isNaN(c.height)||(a=c.height);sc(this.qc,b,a)}return this.qc}},isMultiline:{get:function(){return this.pj},set:function(a){var b=this.pj;b!==a&&(this.pj=a,this.s(),this.g("isMultiline",b,a))}},isUnderline:{get:function(){return this.ei},set:function(a){var b=this.ei;b!==a&&(this.ei=a,this.R(),this.g("isUnderline",
b,a))}},isStrikethrough:{get:function(){return this.di},set:function(a){var b=this.di;b!==a&&(this.di=a,this.R(),this.g("isStrikethrough",b,a))}},wrap:{get:function(){return this.kg},set:function(a){var b=this.kg;b!==a&&(this.kg=a,this.s(),this.g("wrap",b,a))}},overflow:{get:function(){return this.Zf},set:function(a){var b=this.Zf;b!==a&&(this.Zf=a,this.s(),this.g("overflow",b,a))}},stroke:{
get:function(){return this.Gc},set:function(a){var b=this.Gc;b!==a&&(null!==a&&Fl(a,"TextBlock.stroke"),a instanceof il&&a.freeze(),this.Gc=a,this.R(),this.g("stroke",b,a))}},lineCount:{get:function(){return this.oc}},editable:{get:function(){return this.zn},set:function(a){var b=this.zn;b!==a&&(this.zn=a,this.g("editable",b,a))}},textEditor:{get:function(){return this.Ap},set:function(a){var b=this.Ap;b!==a&&
(this.Ap=a,this.g("textEditor",b,a))}},errorFunction:{get:function(){return this.Cc},set:function(a){var b=this.Cc;b!==a&&(this.Cc=a,this.g("errorFunction",b,a))}},interval:{get:function(){return this.Cd},set:function(a){var b=this.Cd;a=Math.floor(a);if(b!==a&&0<=a){this.Cd=a;this.s();var c=this.panel;null!==c&&(c.Og=null);this.g("interval",b,a)}}},graduatedStart:{get:function(){return this.Bd},set:function(a){var b=
this.Bd;b!==a&&(0>a?a=0:1<a&&(a=1),this.Bd=a,this.s(),this.g("graduatedStart",b,a))}},graduatedEnd:{get:function(){return this.zd},set:function(a){var b=this.zd;b!==a&&(0>a?a=0:1<a&&(a=1),this.zd=a,this.s(),this.g("graduatedEnd",b,a))}},graduatedFunction:{get:function(){return this.kj},set:function(a){var b=this.kj;b!==a&&(this.kj=a,this.s(),this.g("graduatedFunction",b,a))}},graduatedSkip:{get:function(){return this.Ad},
set:function(a){var b=this.Ad;b!==a&&(this.Ad=a,this.s(),this.g("graduatedSkip",b,a))}},textValidation:{get:function(){return this.xi},set:function(a){var b=this.xi;b!==a&&(this.xi=a,this.g("textValidation",b,a))}},textEdited:{get:function(){return this.zp},set:function(a){var b=this.zp;b!==a&&(this.zp=a,this.g("textEdited",b,a))}},spacingAbove:{get:function(){return this.af},set:function(a){var b=this.af;b!==
a&&(this.af=a,this.g("spacingAbove",b,a))}},spacingBelow:{get:function(){return this.bf},set:function(a){var b=this.bf;b!==a&&(this.bf=a,this.g("spacingBelow",b,a))}},maxLines:{get:function(){return this.me},set:function(a){var b=this.me;b!==a&&(a=Math.floor(a),0>=a&&ya(a,"> 0",vh,"maxLines"),this.me=a,this.g("maxLines",b,a),this.s())}},metrics:{get:function(){return this.nd}},choices:{
get:function(){return this.Wm},set:function(a){var b=this.Wm;b!==a&&(this.Wm=a,this.g("choices",b,a))}}});var zn=null,An=null,Kn=new D(vh,"None",0),In=new D(vh,"WrapFit",1),xn=new D(vh,"WrapDesiredSize",2),Mn=new D(vh,"WrapBreakAll",3),wn=new D(vh,"OverflowClip",0),Fn=new D(vh,"OverflowEllipsis",1),Nn=null,On=null,Pn=0,Qn=null,Rn=0,Hn="...",Cn="",Bn=null,un=!1;vh.className="TextBlock";vh.getEllipsis=function(){return Hn};vh.setEllipsis=function(a){Hn=a;Qn=new tb;Rn=0};vh.getBaseline=function(){return zn};
vh.setBaseline=function(a){zn=a;a=Va();for(var b=a.length,c=0;c<b;c++)a[c].tf()};vh.getUnderline=function(){return An};vh.setUnderline=function(a){An=a;a=Va();for(var b=a.length,c=0;c<b;c++)a[c].tf()};vh.isValidFont=function(a){un||(vn(),un=!0);if(null===Bn)return!0;var b=Bn.font;if(a===b||"10px sans-serif"===a)return!0;Bn.font="10px sans-serif";Bn.font=a;var c=Bn.font;if("10px sans-serif"!==c)return Bn.font=b,!0;Bn.font="19px serif";var d=Bn.font;Bn.font=a;c=Bn.font;Bn.font=b;return c!==d};
vh.None=Kn;vh.WrapFit=In;vh.WrapDesiredSize=xn;vh.WrapBreakAll=Mn;vh.OverflowClip=wn;vh.OverflowEllipsis=Fn;function Jm(){this.Ec=this.jg=0;this.Wc=[];this.Ac=[];this.If=null}Jm.prototype.reset=function(){this.Ec=this.jg=0;this.If=null;this.Wc=[];this.Ac=[]};Jm.prototype.Vl=function(a){this.jg=a.jg;this.If=a.If;this.Ec=a.Ec;this.Wc=Ga(a.Wc);this.Ac=Ga(a.Ac)};
pa.Object.defineProperties(Jm.prototype,{arrSize:{get:function(){return this.Wc}},arrText:{get:function(){return this.Ac}},maxLineWidth:{get:function(){return this.Ec}},fontHeight:{get:function(){return this.If}}});Jm.className="TextBlockMetrics";
function Yj(){Y.call(this);this.Kg=null;this.sp="";this.fh=Hc;this.al=be;this.ef=this.Cc=null;this.$k=dd;this.yd=Zg;this.Ll=null;this.au=!1;this.nr=!0;this.nl=!1;this.Dl=null}oa(Yj,Y);Yj.prototype.cloneProtected=function(a){Y.prototype.cloneProtected.call(this,a);a.element=this.Kg;a.sp=this.sp;a.fh=this.fh.I();a.al=this.al;a.yd=this.yd;a.Cc=this.Cc;a.ef=this.ef;a.$k=this.$k.I();a.nr=this.nr;a.Dl=this.Dl};t=Yj.prototype;
t.fb=function(a){a===Zg||a===ah||a===Nk?this.imageStretch=a:Y.prototype.fb.call(this,a)};t.toString=function(){return"Picture("+this.source+")#"+Bb(this)};function dk(a){void 0===a&&(a="");""!==a?Sn[a]&&(delete Sn[a],Tn--):(Sn=new tb,Tn=0)}function Un(a,b){a.Dr=!0;a.Zk=!1;for(var c,d=Va(),e=d.length,f=0;f<e;f++){var g=d[f],h=g.Ej.J(a.src);if(null!==h)for(var k=h.length,l=0;l<k;l++)c=h[l],g.lu.add(c),g.cc(),void 0===a.ru&&(a.ru=b,null!==c.ef&&c.ef(c,b))}}
function Vn(a,b){a.Zk=b;for(var c,d=Va(),e=d.length,f=0;f<e;f++)if(c=d[f].Ej.J(a.src),null!==c){for(var g=c.length,h=Ka(),k=0;k<g;k++)h.push(c[k]);for(k=0;k<g;k++)c=h[k],null!==c.Cc&&c.Cc(c,b);Oa(h)}}t.Hz=function(){if(""!==this.source){dk(this.source);var a=this.source;this.source="";this.source=a}};t.tf=function(){this.R()};
t.Ei=function(a,b){var c=this.Kg;if(null!==c){var d=c.src;null!==d&&""!==d||A('Element has no source ("src") attribute: '+c);if(!(c.Zk instanceof Event)){d=this.naturalBounds;var e=0,f=0,g=this.au,h=g?+c.width:c.naturalWidth;g=g?+c.height:c.naturalHeight;void 0===h&&c.videoWidth&&(h=c.videoWidth);void 0===g&&c.videoHeight&&(g=c.videoHeight);h=h||d.width;g=g||d.height;if(0!==h&&0!==g){var k=h,l=g;this.sourceRect.o()&&(e=this.fh.x,f=this.fh.y,h=this.fh.width,g=this.fh.height);var m=h,n=g,p=this.al,
q=this.$k;switch(p){case Zg:if(this.sourceRect.o())break;m>=d.width&&(e=e+q.offsetX+(m*q.x-d.width*q.x));n>=d.height&&(f=f+q.offsetY+(n*q.y-d.height*q.y));h=Math.min(d.width,m);g=Math.min(d.height,n);break;case be:m=d.width;n=d.height;break;case ah:case Nk:p===ah?(p=Math.min(d.height/n,d.width/m),m*=p,n*=p):p===Nk&&(p=Math.max(d.height/n,d.width/m),m*=p,n*=p,m>=d.width&&(e=(e+q.offsetX+(m*q.x-d.width*q.x)/m)*h),n>=d.height&&(f=(f+q.offsetY+(n*q.y-d.height*q.y)/n)*g),h*=1/(m/d.width),g*=1/(n/d.height),
m=d.width,n=d.height)}p=this.Ce()*b.scale;var r=h*g/(m*p*n*p),u=c.__goCache;p=null;var x=Wn;if(c.Dr&&void 0!==u&&r>x*x)for(null===u.Di&&(Xn(u,4,k,l,c),Xn(u,16,k,l,c)),k=u.Di,l=k.length,p=k[0],x=0;x<l;x++)if(k[x].ratio*k[x].ratio<r)p=k[x];else break;if(!b.wn){if(null===this.Ll)if(null===this.Kg)this.Ll=!1;else{k=(new tk(null)).context;k.drawImage(this.Kg,0,0);try{k.getImageData(0,0,1,1).data[3]&&(this.Ll=!1),this.Ll=!1}catch(v){this.Ll=!0}}if(this.Ll)return}k=0;m<d.width&&(k=q.offsetX+(d.width*q.x-
m*q.x));l=0;n<d.height&&(l=q.offsetY+(d.height*q.y-n*q.y));switch(this.flip){case Pk:a.translate(Math.min(d.width,m),0);a.scale(-1,1);break;case Ok:a.translate(0,Math.min(d.height,n));a.scale(1,-1);break;case Qk:a.translate(Math.min(d.width,m),Math.min(d.height,n)),a.scale(-1,-1)}if(b.De("pictureRatioOptimization")&&!b.oj&&void 0!==u&&null!==p&&1!==p.ratio){a.save();b=p.ratio;try{a.drawImage(p.source,e/b,f/b,Math.min(p.source.width,h/b),Math.min(p.source.height,g/b),k,l,Math.min(d.width,m),Math.min(d.height,
n))}catch(v){this.nr=!1}a.restore()}else try{a.drawImage(c,e,f,h,g,k,l,Math.min(d.width,m),Math.min(d.height,n))}catch(v){this.nr=!1}switch(this.flip){case Pk:a.scale(-1,1);a.translate(-Math.min(d.width,m),0);break;case Ok:a.scale(1,-1);a.translate(0,-Math.min(d.height,n));break;case Qk:a.scale(-1,-1),a.translate(-Math.min(d.width,m),-Math.min(d.height,n))}}}}};
t.hm=function(a,b,c,d){var e=this.desiredSize,f=$k(this,!0),g=this.Kg,h=this.au;if(h||!this.nl&&g&&g.complete)this.nl=!0;null===g&&(isFinite(e.width)||(a=0),isFinite(e.height)||(b=0));isFinite(e.width)||f===be||f===Lk?(isFinite(a)||(a=this.sourceRect.o()?this.sourceRect.width:h?+g.width:g.naturalWidth),c=0):null!==g&&!1!==this.nl&&(a=this.sourceRect.o()?this.sourceRect.width:h?+g.width:g.naturalWidth);isFinite(e.height)||f===be||f===Mk?(isFinite(b)||(b=this.sourceRect.o()?this.sourceRect.height:h?
+g.height:g.naturalHeight),d=0):null!==g&&!1!==this.nl&&(b=this.sourceRect.o()?this.sourceRect.height:h?+g.height:g.naturalHeight);isFinite(e.width)&&(a=e.width);isFinite(e.height)&&(b=e.height);e=this.maxSize;f=this.minSize;c=Math.max(c,f.width);d=Math.max(d,f.height);a=Math.min(e.width,a);b=Math.min(e.height,b);a=Math.max(c,a);b=Math.max(d,b);null===g||g.complete||(isFinite(a)||(a=0),isFinite(b)||(b=0));sc(this.qc,a,b);Xk(this,0,0,a,b)};t.uh=function(a,b,c,d){bl(this,a,b,c,d)};
pa.Object.defineProperties(Yj.prototype,{element:{get:function(){return this.Kg},set:function(a){var b=this.Kg;if(b!==a){null===a||a instanceof HTMLImageElement||a instanceof HTMLVideoElement||a instanceof HTMLCanvasElement||A("Picture.element must be an instance of Image, Canvas, or Video, not: "+a);this.au=a instanceof HTMLCanvasElement;this.Kg=a;if(null!==a)if(a instanceof HTMLCanvasElement||!0===a.complete)a.Zk instanceof Event&&null!==this.Cc&&this.Cc(this,a.Zk),
!0===a.Dr&&null!==this.ef&&this.ef(this,a.ru),a.Dr=!0,this.desiredSize.o()||(jj(this,!1),this.s());else{var c=this;a.ww||(a.addEventListener("load",function(b){Un(a,b);c.desiredSize.o()||(jj(c,!1),c.s())}),a.addEventListener("error",function(b){Vn(a,b)}),a.ww=!0)}this.g("element",b,a);this.R()}}},source:{get:function(){return this.sp},set:function(a){var b=this.sp;if(b!==a){this.sp=a;var c=Sn,d=this.diagram,e=null;if(void 0!==c[a])e=c[a];else{30<Tn&&(dk(),c=Sn);e=va("img");
var f=this;e.addEventListener("load",function(a){Un(e,a);f.desiredSize.o()||(jj(f,!1),f.s())});e.addEventListener("error",function(a){Vn(e,a)});e.ww=!0;var g=this.Dl;null!==g&&(e.crossOrigin=g(this));e.src=a;c[a]=e;Tn++}null!==d&&ck(d,this);this.element=e;null!==d&&Uj(d,this);void 0===e.__goCache&&(e.__goCache=new Yn);this.s();this.R();this.g("source",b,a)}}},sourceCrossOrigin:{get:function(){return this.Dl},set:function(a){if(this.Dl!==a&&(this.Dl=a,null!==this.element)){var b=
this.element.src;null===a&&"string"===typeof b?this.element.crossOrigin=null:null!==a&&(this.element.crossOrigin=a(this));this.element.src=b}}},sourceRect:{get:function(){return this.fh},set:function(a){var b=this.fh;b.A(a)||(this.fh=a=a.I(),this.R(),this.g("sourceRect",b,a))}},imageStretch:{get:function(){return this.al},set:function(a){var b=this.al;b!==a&&(this.al=a,this.R(),this.g("imageStretch",b,a))}},flip:{
get:function(){return this.yd},set:function(a){var b=this.yd;b!==a&&(this.yd=a,this.R(),this.g("flip",b,a))}},imageAlignment:{get:function(){return this.$k},set:function(a){var b=this.$k;b.A(a)||(this.$k=a=a.I(),this.s(),this.g("imageAlignment",b,a))}},errorFunction:{get:function(){return this.Cc},set:function(a){var b=this.Cc;b!==a&&(this.Cc=a,this.g("errorFunction",b,a))}},successFunction:{get:function(){return this.ef},
set:function(a){var b=this.ef;b!==a&&(this.ef=a,this.g("successFunction",b,a))}},naturalBounds:{get:function(){return this.qc}}});Yj.prototype.redraw=Yj.prototype.tf;Yj.prototype.reloadSource=Yj.prototype.Hz;var Sn=null,Tn=0,Wn=4;Yj.className="Picture";Sn=new tb;Yj.clearCache=dk;function Yn(){this.Di=null}
function Xn(a,b,c,d,e){null===a.Di&&(a.Di=[]);var f=new tk(null),g=f.context,h=1/b;f.width=c/b;f.height=d/b;b=new Zn(f.Ga,b);c=1;0<a.Di.length&&(c=a.Di[a.Di.length-1],e=c.source,c=c.ratio);g.setTransform(h*c,0,0,h*c,0,0);g.drawImage(e,0,0);a.Di.push(b)}Yn.className="PictureCacheArray";function Zn(a,b){this.source=a;this.ratio=b}Zn.className="PictureCacheInstance";function $n(){this.Ts=new $d;this.ec=null}t=$n.prototype;
t.reset=function(a){null!==a?(a.ha(),this.Ts=a,a.figures.clear()):this.Ts=new $d;this.ec=null};function oe(a,b,c,d,e){a.ec=new Ue;a.ec.startX=b;a.ec.startY=c;a.ec.isFilled=d;a.Ts.figures.add(a.ec);void 0!==e&&(a.ec.isShadowed=e)}function ve(a){var b=a.ec.segments.length;0<b&&a.ec.segments.N(b-1).close()}t.tq=function(a){this.ec.isShadowed=a};t.moveTo=function(a,b,c){void 0===c&&(c=!1);var d=new Ve(ze);d.endX=a;d.endY=b;c&&d.close();this.ec.segments.add(d)};
t.lineTo=function(a,b,c){void 0===c&&(c=!1);var d=new Ve(ie);d.endX=a;d.endY=b;c&&d.close();this.ec.segments.add(d)};function pe(a,b,c,d,e,f,g){var h;void 0===h&&(h=!1);var k=new Ve(Ae);k.point1X=b;k.point1Y=c;k.point2X=d;k.point2Y=e;k.endX=f;k.endY=g;h&&k.close();a.ec.segments.add(k)}function te(a,b,c,d,e){var f;void 0===f&&(f=!1);var g=new Ve(Be);g.point1X=b;g.point1Y=c;g.endX=d;g.endY=e;f&&g.close();a.ec.segments.add(g)}
t.arcTo=function(a,b,c,d,e,f,g){void 0===f&&(f=0);void 0===g&&(g=!1);var h=new Ve(Ce);h.startAngle=a;h.sweepAngle=b;h.centerX=c;h.centerY=d;h.radiusX=e;h.radiusY=0!==f?f:e;g&&h.close();this.ec.segments.add(h)};function ue(a,b,c,d,e,f,g,h){var k;void 0===k&&(k=!1);b=new Ve(De,g,h,b,c,d,e,f);k&&b.close();a.ec.segments.add(b)}function ne(a){var b=we;if(null!==b)return we=null,b.reset(a),b;b=new $n;b.reset(a);return b}var we=null;$n.className="StreamGeometryContext";
function ao(a,b){var c=a.toLowerCase(),d=I.Je;d[a]=b;d[c]=a}ao("Rectangle",function(a,b,c){a=new $d(ke);a.startX=0;a.startY=0;a.endX=b;a.endY=c;return a});ao("Square",function(a,b,c){a=new $d(ke);a.startX=0;a.startY=0;a.endX=b;a.endY=c;a.defaultStretch=ah;return a});
ao("RoundedRectangle",function(a,b,c){var d=a?a.parameter1:NaN;if(isNaN(d)||0>=d)d=5;d=Math.min(d,b/3);d=Math.min(d,c/3);a=d*I.yg;b=(new $d).add((new Ue(d,0,!0)).add(new Ve(ie,b-d,0)).add(new Ve(Ae,b,d,b-a,0,b,a)).add(new Ve(ie,b,c-d)).add(new Ve(Ae,b-d,c,b,c-a,b-a,c)).add(new Ve(ie,d,c)).add(new Ve(Ae,0,c-d,a,c,0,c-a)).add(new Ve(ie,0,d)).add((new Ve(Ae,d,0,0,a,a,0)).close()));1<a&&(b.spot1=new O(0,0,a,a),b.spot2=new O(1,1,-a,-a));return b});ao("Border","RoundedRectangle");
ao("Ellipse",function(a,b,c){a=new $d(le);a.startX=0;a.startY=0;a.endX=b;a.endY=c;a.spot1=Ud;a.spot2=Vd;return a});ao("Circle",function(a,b,c){a=new $d(le);a.startX=0;a.startY=0;a.endX=b;a.endY=c;a.spot1=Ud;a.spot2=Vd;a.defaultStretch=ah;return a});ao("TriangleRight",function(a,b,c){return(new $d).add((new Ue(0,0)).add(new Ve(ie,b,.5*c)).add((new Ve(ie,0,c)).close())).xm(0,.25,.5,.75)});
ao("TriangleDown",function(a,b,c){return(new $d).add((new Ue(0,0)).add(new Ve(ie,b,0)).add((new Ve(ie,.5*b,c)).close())).xm(.25,0,.75,.5)});ao("TriangleLeft",function(a,b,c){return(new $d).add((new Ue(b,c)).add(new Ve(ie,0,.5*c)).add((new Ve(ie,b,0)).close())).xm(.5,.25,1,.75)});ao("TriangleUp",function(a,b,c){return(new $d).add((new Ue(b,c)).add(new Ve(ie,0,c)).add((new Ve(ie,.5*b,0)).close())).xm(.25,.5,.75,1)});ao("Triangle","TriangleUp");
ao("Diamond",function(a,b,c){return(new $d).add((new Ue(.5*b,0)).add(new Ve(ie,0,.5*c)).add(new Ve(ie,.5*b,c)).add((new Ve(ie,b,.5*c)).close())).xm(.25,.25,.75,.75)});ao("LineH",function(a,b,c){a=new $d(ce);a.startX=0;a.startY=c/2;a.endX=b;a.endY=c/2;return a});ao("LineV",function(a,b,c){a=new $d(ce);a.startX=b/2;a.startY=0;a.endX=b/2;a.endY=c;return a});ao("None","Rectangle");ao("BarH","Rectangle");ao("BarV","Rectangle");ao("MinusLine","LineH");
ao("PlusLine",function(a,b,c){return(new $d).add((new Ue(0,c/2,!1)).add(new Ve(ie,b,c/2)).add(new Ve(ze,b/2,0)).add(new Ve(ie,b/2,c)))});ao("XLine",function(a,b,c){return(new $d).add((new Ue(0,c,!1)).add(new Ve(ie,b,0)).add(new Ve(ze,0,0)).add(new Ve(ie,b,c)))});
I.Bm={"":"",Standard:"F1 m 0,0 l 8,4 -8,4 2,-4 z",Backward:"F1 m 8,0 l -2,4 2,4 -8,-4 z",Triangle:"F1 m 0,0 l 8,4.62 -8,4.62 z",BackwardTriangle:"F1 m 8,4 l 0,4 -8,-4 8,-4 0,4 z",Boomerang:"F1 m 0,0 l 8,4 -8,4 4,-4 -4,-4 z",BackwardBoomerang:"F1 m 8,0 l -8,4 8,4 -4,-4 4,-4 z",SidewaysV:"m 0,0 l 8,4 -8,4 0,-1 6,-3 -6,-3 0,-1 z",BackwardV:"m 8,0 l -8,4 8,4 0,-1 -6,-3 6,-3 0,-1 z",OpenTriangle:"m 0,0 l 8,4 -8,4",BackwardOpenTriangle:"m 8,0 l -8,4 8,4",OpenTriangleLine:"m 0,0 l 8,4 -8,4 m 8.5,0 l 0,-8",
BackwardOpenTriangleLine:"m 8,0 l  -8,4 8,4 m -8.5,0 l 0,-8",OpenTriangleTop:"m 0,0 l 8,4 m 0,4",BackwardOpenTriangleTop:"m 8,0 l -8,4 m 0,4",OpenTriangleBottom:"m 0,8 l 8,-4",BackwardOpenTriangleBottom:"m 0,4 l 8,4",HalfTriangleTop:"F1 m 0,0 l 0,4 8,0 z m 0,8",BackwardHalfTriangleTop:"F1 m 8,0 l 0,4 -8,0 z m 0,8",HalfTriangleBottom:"F1 m 0,4 l 0,4 8,-4 z",BackwardHalfTriangleBottom:"F1 m 8,4 l 0,4 -8,-4 z",ForwardSemiCircle:"m 4,0 b 270 180 0 4 4",BackwardSemiCircle:"m 4,8 b 90 180 0 -4 4",Feather:"m 0,0 l 3,4 -3,4",
BackwardFeather:"m 3,0 l -3,4 3,4",DoubleFeathers:"m 0,0 l 3,4 -3,4 m 3,-8 l 3,4 -3,4",BackwardDoubleFeathers:"m 3,0 l -3,4 3,4 m 3,-8 l -3,4 3,4",TripleFeathers:"m 0,0 l 3,4 -3,4 m 3,-8 l 3,4 -3,4 m 3,-8 l 3,4 -3,4",BackwardTripleFeathers:"m 3,0 l -3,4 3,4 m 3,-8 l -3,4 3,4 m 3,-8 l -3,4 3,4",ForwardSlash:"m 0,8 l 5,-8",BackSlash:"m 0,0 l 5,8",DoubleForwardSlash:"m 0,8 l 4,-8 m -2,8 l 4,-8",DoubleBackSlash:"m 0,0 l 4,8 m -2,-8 l 4,8",TripleForwardSlash:"m 0,8 l 4,-8 m -2,8 l 4,-8 m -2,8 l 4,-8",
TripleBackSlash:"m 0,0 l 4,8 m -2,-8 l 4,8 m -2,-8 l 4,8",Fork:"m 0,4 l 8,0 m -8,0 l 8,-4 m -8,4 l 8,4",BackwardFork:"m 8,4 l -8,0 m 8,0 l -8,-4 m 8,4 l -8,4",LineFork:"m 0,0 l 0,8 m 0,-4 l 8,0 m -8,0 l 8,-4 m -8,4 l 8,4",BackwardLineFork:"m 8,4 l -8,0 m 8,0 l -8,-4 m 8,4 l -8,4 m 8,-8 l 0,8",CircleFork:"F1 m 6,4 b 0 360 -3 0 3 z m 0,0 l 6,0 m -6,0 l 6,-4 m -6,4 l 6,4",BackwardCircleFork:"F1 m 0,4 l 6,0 m -6,-4 l 6,4 m -6,4 l 6,-4 m 6,0 b 0 360 -3 0 3",CircleLineFork:"F1 m 6,4 b 0 360 -3 0 3 z m 1,-4 l 0,8 m 0,-4 l 6,0 m -6,0 l 6,-4 m -6,4 l 6,4",
BackwardCircleLineFork:"F1 m 0,4 l 6,0 m -6,-4 l 6,4 m -6,4 l 6,-4 m 0,-4 l 0,8 m 7,-4 b 0 360 -3 0 3",Circle:"F1 m 8,4 b 0 360 -4 0 4 z",Block:"F1 m 0,0 l 0,8 8,0 0,-8 z",StretchedDiamond:"F1 m 0,3 l 5,-3 5,3 -5,3 -5,-3 z",Diamond:"F1 m 0,4 l 4,-4 4,4 -4,4 -4,-4 z",Chevron:"F1 m 0,0 l 5,0 3,4 -3,4 -5,0 3,-4 -3,-4 z",StretchedChevron:"F1 m 0,0 l 8,0 3,4 -3,4 -8,0 3,-4 -3,-4 z",NormalArrow:"F1 m 0,2 l 4,0 0,-2 4,4 -4,4 0,-2 -4,0 z",X:"m 0,0 l 8,8 m 0,-8 l -8,8",TailedNormalArrow:"F1 m 0,0 l 2,0 1,2 3,0 0,-2 2,4 -2,4 0,-2 -3,0 -1,2 -2,0 1,-4 -1,-4 z",
DoubleTriangle:"F1 m 0,0 l 4,4 -4,4 0,-8 z  m 4,0 l 4,4 -4,4 0,-8 z",BigEndArrow:"F1 m 0,0 l 5,2 0,-2 3,4 -3,4 0,-2 -5,2 0,-8 z",ConcaveTailArrow:"F1 m 0,2 h 4 v -2 l 4,4 -4,4 v -2 h -4 l 2,-2 -2,-2 z",RoundedTriangle:"F1 m 0,1 a 1,1 0 0 1 1,-1 l 7,3 a 0.5,1 0 0 1 0,2 l -7,3 a 1,1 0 0 1 -1,-1 l 0,-6 z",SimpleArrow:"F1 m 1,2 l -1,-2 2,0 1,2 -1,2 -2,0 1,-2 5,0 0,-2 2,2 -2,2 0,-2 z",AccelerationArrow:"F1 m 0,0 l 0,8 0.2,0 0,-8 -0.2,0 z m 2,0 l 0,8 1,0 0,-8 -1,0 z m 3,0 l 2,0 2,4 -2,4 -2,0 0,-8 z",BoxArrow:"F1 m 0,0 l 4,0 0,2 2,0 0,-2 2,4 -2,4 0,-2 -2,0 0,2 -4,0 0,-8 z",
TriangleLine:"F1 m 8,4 l -8,-4 0,8 8,-4 z m 0.5,4 l 0,-8",CircleEndedArrow:"F1 m 10,4 l -2,-3 0,2 -2,0 0,2 2,0 0,2 2,-3 z m -4,0 b 0 360 -3 0 3 z",DynamicWidthArrow:"F1 m 0,3 l 2,0 2,-1 2,-2 2,4 -2,4 -2,-2 -2,-1 -2,0 0,-2 z",EquilibriumArrow:"m 0,3 l 8,0 -3,-3 m 3,5 l -8,0 3,3",FastForward:"F1 m 0,0 l 3.5,4 0,-4 3.5,4 0,-4 1,0 0,8 -1,0 0,-4 -3.5,4 0,-4 -3.5,4 0,-8 z",Kite:"F1 m 0,4 l 2,-4 6,4 -6,4 -2,-4 z",HalfArrowTop:"F1 m 0,0 l 4,4 4,0 -8,-4 z m 0,8",HalfArrowBottom:"F1 m 0,8 l 4,-4 4,0 -8,4 z",
OpposingDirectionDoubleArrow:"F1 m 0,4 l 2,-4 0,2 4,0 0,-2 2,4 -2,4 0,-2 -4,0 0,2 -2,-4 z",PartialDoubleTriangle:"F1 m 0,0 4,3 0,-3 4,4 -4,4 0,-3 -4,3 0,-8 z",LineCircle:"F1 m 0,0 l 0,8 m 7 -4 b 0 360 -3 0 3 z",DoubleLineCircle:"F1 m 0,0 l 0,8 m 2,-8 l 0,8 m 7 -4 b 0 360 -3 0 3 z",TripleLineCircle:"F1 m 0,0 l 0,8 m 2,-8 l 0,8 m 2,-8 l 0,8 m 7 -4 b 0 360 -3 0 3 z",CircleLine:"F1 m 6 4 b 0 360 -3 0 3 z m 1,-4 l 0,8",DiamondCircle:"F1 m 8,4 l -4,4 -4,-4 4,-4 4,4 m 8,0 b 0 360 -4 0 4 z",PlusCircle:"F1 m 8,4 b 0 360 -4 0 4 l -8 0 z m -4 -4 l 0 8",
OpenRightTriangleTop:"m 8,0 l 0,4 -8,0 m 0,4",OpenRightTriangleBottom:"m 8,8 l 0,-4 -8,0",Line:"m 0,0 l 0,8",DoubleLine:"m 0,0 l 0,8 m 2,0 l 0,-8",TripleLine:"m 0,0 l 0,8 m 2,0 l 0,-8 m 2,0 l 0,8",PentagonArrow:"F1 m 8,4 l -4,-4 -4,0 0,8 4,0 4,-4 z"};
function T(a){X.call(this,a);this.D=2408959;this.Tg=this.zf="";this.Yo=this.Vo=this.jp=this.$n=null;this.lp="";this.xf=this.Mn=this.kp=this.dh=null;this.Xo="";this.Wo=mc;this.Ob=this.Zo="";this.gi=this.Zm=this.Sh=null;this.le=(new H(NaN,NaN)).freeze();this.ho="";this.We=null;this.io=Tc;this.$o=Ed;this.qo=bc;this.jo=cc;this.vn=null;this.ao=127;this.ui=dc;this.Kj="gray";this.Od=4;this.zw=-1;this.Kp=NaN;this.Rx=new N;this.xj=null;this.Wg=NaN}oa(T,X);
T.prototype.cloneProtected=function(a){X.prototype.cloneProtected.call(this,a);a.D=this.D&-4097|49152;a.zf=this.zf;a.Tg=this.Tg;a.$n=this.$n;a.jp=this.jp;a.Vo=this.Vo;a.Yo=this.Yo;a.lp=this.lp;a.kp=this.kp;a.Mn=this.Mn;a.xf=null;a.Xo=this.Xo;a.Wo=this.Wo.I();a.Zo=this.Zo;a.$o=this.$o.I();a.Ob=this.Ob;a.Zm=this.Zm;a.le.assign(this.le);a.ho=this.ho;a.io=this.io.I();a.qo=this.qo.I();a.jo=this.jo.I();a.vn=this.vn;a.ao=this.ao;a.ui=this.ui.I();a.Kj=this.Kj;a.Od=this.Od;a.Kp=this.Kp};
T.prototype.nf=function(a){X.prototype.nf.call(this,a);a.zh();a.dh=null;a.We=null;a.xj=null};T.prototype.toString=function(){var a=Pa(this.constructor)+"#"+Bb(this);null!==this.data&&(a+="("+Qa(this.data)+")");return a};T.prototype.qk=function(a,b,c,d,e,f,g){var h=this.diagram;null!==h&&(a===cf&&"elements"===b?e instanceof X?Bj(e,function(a){Dj(h.partManager,a);Cj(h,a)}):Uj(h,e):a===df&&"elements"===b&&(e instanceof X?Bj(e,function(a){Gj(h.partManager,a,h)}):ck(h,e)),h.ab(a,b,c,d,e,f,g))};
T.prototype.Ea=function(a){X.prototype.Ea.call(this,a);if(null!==this.data){a=this.Y.j;for(var b=a.length,c=0;c<b;c++){var d=a[c];d instanceof X&&Bj(d,function(a){null!==a.data&&a.Ea()})}}};T.prototype.updateRelationshipsFromData=function(){null!==this.data&&this.diagram.partManager.updateRelationshipsFromData(this)};T.prototype.bk=function(a){var b=this.xf;return null===b?null:b.J(a)};
T.prototype.qh=function(a,b){if(null!==b){var c=null,d=this.xf;null!==d&&(c=d.J(a));if(c!==b){if(null!==c){var e=c.diagram;null!==e&&e.remove(c)}null===d&&(this.xf=d=new Lb);b.zf!==a&&(b.category=a);d.add(a,b);a=this.diagram;null!==a&&(a.add(b),a=b.adornedObject,null!==a&&(a=a.Ii(),null!==a&&(b.data=a.data)))}}};T.prototype.uf=function(a){var b=this.xf;if(null!==b){var c=b.J(a);if(null!==c){var d=c.diagram;null!==d&&d.remove(c)}b.remove(a);0===b.count&&(this.xf=null)}};
T.prototype.Uj=function(){var a=this.xf;if(null!==a){var b=Ka();for(a=a.iterator;a.next();)b.push(a.key);a=b.length;for(var c=0;c<a;c++)this.uf(b[c]);Oa(b)}};
T.prototype.updateAdornments=function(){var a=this.diagram;if(null!==a){for(var b=this.adornments;b.next();){var c=b.value;c.s();c.placeholder&&c.placeholder.s()}a:{if(this.isSelected&&this.selectionAdorned&&(b=this.selectionObject,null!==b&&this.actualBounds.o()&&this.isVisible()&&b.sf()&&b.actualBounds.o())){c=this.bk("Selection");if(null===c){c=this.selectionAdornmentTemplate;null===c&&(c=this.Ah()?a.linkSelectionAdornmentTemplate:this instanceof ig?a.groupSelectionAdornmentTemplate:a.nodeSelectionAdornmentTemplate);
if(!(c instanceof pf))break a;Yg(c);c=c.copy();null!==c&&(this.Ah()&&this.selectionObject===this.path&&(c.type=X.Link),c.adornedObject=b)}if(null!==c){if(null!==c.placeholder){var d=b.Ce(),e=0;b instanceof W&&(e=b.strokeWidth);var f=L.alloc();f.h((b.naturalBounds.width+e)*d,(b.naturalBounds.height+e)*d);L.free(f)}c.type===X.Link?c.s():(b=H.alloc(),H.free(b));this.qh("Selection",c);break a}}this.uf("Selection")}bo(this,a);for(b=this.adornments;b.next();)b.value.Ea()}};
T.prototype.Jb=function(){var a=this.diagram;null!==a&&(Pi(a),0!==(this.D&16384)!==!0&&(hi(this,!0),a.cc()))};function gi(a){0!==(a.D&16384)!==!1&&(a.updateAdornments(),hi(a,!1))}function bo(a,b){b.toolManager.mouseDownTools.each(function(b){b.isEnabled&&b.updateAdornments(a)});b.toolManager.updateAdornments(a)}function co(a){if(!1===qj(a)){eo(a,!0);a.fl();var b=a.diagram;null!==b&&(b.Dd.add(a),b.cc())}}
function fo(a){a.D|=2097152;if(!1!==qj(a)){var b=a.position,c=a.location;c.o()&&b.o()||go(a,b,c);c=a.vb;var d=N.alloc().assign(c);c.ha();c.x=b.x;c.y=b.y;c.freeze();a.kt(d,c);N.free(d);eo(a,!1)}}T.prototype.move=function(a,b){!0===b?this.location=a:this.position=a};T.prototype.moveTo=function(a,b,c){a=H.allocAt(a,b);this.move(a,c);H.free(a)};
T.prototype.isVisible=function(){if(!this.visible)return!1;var a=this.layer;if(null!==a&&!a.visible)return!1;a=this.diagram;if(null!==a&&Xh(a.animationManager,this))return!0;a=this.containingGroup;return null===a||a.isSubGraphExpanded&&a.isVisible()?!0:!1};t=T.prototype;t.Lb=function(a){var b=this.diagram;a?(this.B(4),this.Jb(),null!==b&&b.Dd.add(this)):(this.B(8),this.Uj());this.zh();null!==b&&(b.Xa(),b.R())};
t.$a=function(a){if(this.name===a)return this;var b=this.xj;null===b&&(this.xj=b=new Lb);if(null!==b.J(a))return b.J(a);var c=X.prototype.$a.call(this,a);if(null!==c)return b.set(a,c),c;b.set(a,null);return null};t.pf=function(a,b,c){void 0===c&&(c=new H);b=b.jc()?dd:b;var d=a.naturalBounds;c.h(d.width*b.x+b.offsetX,d.height*b.y+b.offsetY);if(null===a||a===this)return c;a.transform.ta(c);for(a=a.panel;null!==a&&a!==this;)a.transform.ta(c),a=a.panel;this.Of.ta(c);c.offset(-this.pc.x,-this.pc.y);return c};
t.Yp=function(a){void 0===a&&(a=new N);return a.assign(this.actualBounds)};t.$b=function(){!0===oj(this)&&(this instanceof ig&&this.memberParts.each(function(a){a.$b()}),this.measure(Infinity,Infinity));this.arrange()};
function zj(a,b){var c=a.Rx;isNaN(a.Wg)&&(a.Wg=Um(a));var d=a.Wg;var e=2*d;if(!a.isShadowed)return c.h(b.x-1-d,b.y-1-d,b.width+2+e,b.height+2+e),c;d=b.x;e=b.y;var f=b.width;b=b.height;var g=a.shadowBlur;a=a.shadowOffset;f+=g;b+=g;d-=g/2;e-=g/2;0<a.x?f+=a.x:(d+=a.x,f-=a.x);0<a.y?b+=a.y:(e+=a.y,b-=a.y);c.h(d-1,e-1,f+2,b+2);return c}
T.prototype.arrange=function(){if(!1===pj(this))fo(this);else{var a=this.vb,b=N.alloc();b.assign(a);a.ha();var c=og(this);this.uh(0,0,this.pc.width,this.pc.height);var d=this.position;go(this,d,this.location);a.x=d.x;a.y=d.y;a.freeze();this.kt(b,a);al(this,!1);b.A(a)?this.hd(c):!this.bc()||I.w(b.width,a.width)&&I.w(b.height,a.height)||0<=this.zw&&this.B(16);N.free(b);eo(this,!1)}};t=T.prototype;
t.kt=function(a,b){var c=this.diagram;if(null!==c){var d=!1;if(!1===c.Qg&&a.o()){var e=N.alloc();e.assign(c.documentBounds);e.Ov(c.padding);a.x>e.x&&a.y>e.y&&a.right<e.right&&a.bottom<e.bottom&&b.x>e.x&&b.y>e.y&&b.right<e.right&&b.bottom<e.bottom&&(d=!0);N.free(e)}0!==(this.D&65536)!==!0&&a.A(b)||Ej(this,d,c);c.R();xc(a,b)||(this instanceof V&&!c.undoManager.isUndoingRedoing&&this.ed(),this.zh())}};
t.Kv=function(a,b){if(this.Ah()||!a.o())return!1;var c=this.diagram;if(null!==c&&(ho(this,c,a,b),!0===c.undoManager.isUndoingRedoing))return!0;this.sa=a;this.D&=-2097153;c=this.le;c.o()&&(this.le=new H(c.x+(a.x-b.x),c.y+(a.y-b.y)),this.g("location",c,this.le));!1===qj(this)&&!1===pj(this)&&(co(this),fo(this));return!0};function ho(a,b,c,d){null===b||a instanceof pf||(b=b.animationManager,b.ni&&b.sh(a,"position",d.copy(),c.copy(),!1))}
t.vt=function(a,b){var c=this.le,d=this.sa;qj(this)||pj(this)?c.h(NaN,NaN):c.h(c.x+a-d.x,c.y+b-d.y);d.h(a,b);co(this)};t.Lv=function(){this.D&=-2097153;co(this)};
function go(a,b,c){var d=H.alloc(),e=a.locationSpot,f=a.locationObject;e.jc()&&A("determineOffset: Part's locationSpot must be real: "+e.toString());var g=f.naturalBounds,h=f instanceof W?f.strokeWidth:0;d.sk(0,0,g.width+h,g.height+h,e);if(f!==a)for(d.offset(-h/2,-h/2),f.transform.ta(d),e=f.panel;null!==e&&e!==a;)e.transform.ta(d),e=e.panel;a.Of.ta(d);d.offset(-a.pc.x,-a.pc.y);e=a.diagram;f=c.o();g=b.o();f&&g?0!==(a.D&2097152)?io(a,b,c,e,d):jo(a,b,c,e,d):f?io(a,b,c,e,d):g&&jo(a,b,c,e,d);a.D|=2097152;
H.free(d);a.fl()}function io(a,b,c,d,e){var f=b.x,g=b.y;b.h(c.x-e.x,c.y-e.y);null!==d&&(c=d.animationManager,(e=c.isAnimating)||!c.ni||a instanceof pf||c.sh(a,"position",new H(f,g),b,!1),e||b.x===f&&b.y===g||(c=d.skipsUndoManager,d.skipsUndoManager=!0,a.g("position",new H(f,g),b),d.skipsUndoManager=c))}function jo(a,b,c,d,e){var f=c.copy();c.h(b.x+e.x,b.y+e.y);c.A(f)||null===d||(b=d.skipsUndoManager,d.skipsUndoManager=!0,a.g("location",f,c),d.skipsUndoManager=b)}
function Ej(a,b,c){cl(a,!1);a instanceof V&&ok(c,a);a.layer.isTemporary||b||c.Xa();b=a.vb;var d=c.viewportBounds;d.o()?og(a)?(Ac(b,d,10)||a.hd(!1),a.updateAdornments()):b.Ic(d)?(a.hd(!0),a.updateAdornments()):a.Jb():c.ci=!0}t.Ri=function(){return!0};t.bc=function(){return!0};t.Ah=function(){return!1};t.tg=function(){return!0};
function ko(a,b,c,d){b.constructor===a.constructor||lo||(lo=!0,za('Should not change the class of the Part when changing category from "'+c+'" to "'+d+'"'),za("  Old class: "+Pa(a.constructor)+", new class: "+Pa(b.constructor)+", part: "+a.toString()));a.Uj();var e=a.data;c=a.layerName;var f=a.isSelected,g=a.isHighlighted,h=!0,k=!0,l=!1;a instanceof V&&(h=a.isTreeLeaf,k=a.isTreeExpanded,l=a.wasTreeExpanded);b.nf(a);b.cloneProtected(a);a.zf=d;a.s();a.R();b=a.diagram;d=!0;null!==b&&(d=b.skipsUndoManager,
b.skipsUndoManager=!0);a.jb=e;a.D=f?a.D|4096:a.D&-4097;a.D=g?a.D|524288:a.D&-524289;a instanceof V&&(a.S=h?a.S|4:a.S&-5,a.S=k?a.S|1:a.S&-2,a.S=l?a.S|2:a.S&-3);null!==e&&a.Ea();e=a.layerName;e!==c&&(a.Tg=c,a.layerName=e);null!==b&&(b.skipsUndoManager=d);a.bc()&&a.B(64)}T.prototype.canCopy=function(){if(!this.copyable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowCopy)return!1;a=a.diagram;return null===a?!0:a.allowCopy?!0:!1};
T.prototype.canDelete=function(){if(!this.deletable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowDelete)return!1;a=a.diagram;return null===a?!0:a.allowDelete?!0:!1};T.prototype.canEdit=function(){if(!this.textEditable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowTextEdit)return!1;a=a.diagram;return null===a?!0:a.allowTextEdit?!0:!1};
T.prototype.canGroup=function(){if(!this.groupable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowGroup)return!1;a=a.diagram;return null===a?!0:a.allowGroup?!0:!1};T.prototype.canMove=function(){if(!this.movable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowMove)return!1;a=a.diagram;return null===a?!0:a.allowMove?!0:!1};
T.prototype.canReshape=function(){if(!this.reshapable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowReshape)return!1;a=a.diagram;return null===a?!0:a.allowReshape?!0:!1};T.prototype.canResize=function(){if(!this.resizable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowResize)return!1;a=a.diagram;return null===a?!0:a.allowResize?!0:!1};
T.prototype.canRotate=function(){if(!this.rotatable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowRotate)return!1;a=a.diagram;return null===a?!0:a.allowRotate?!0:!1};T.prototype.canSelect=function(){if(!this.selectable)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowSelect)return!1;a=a.diagram;return null===a?!0:a.allowSelect?!0:!1};function hi(a,b){a.D=b?a.D|16384:a.D&-16385}function qj(a){return 0!==(a.D&32768)}function eo(a,b){a.D=b?a.D|32768:a.D&-32769}
function cl(a,b){a.D=b?a.D|65536:a.D&-65537}function og(a){return 0!==(a.D&131072)}t=T.prototype;t.hd=function(a){this.D=a?this.D|131072:this.D&-131073};function mo(a,b){a.D=b?a.D|1048576:a.D&-1048577}t.zh=function(){var a=this.containingGroup;null!==a&&(a.s(),null!==a.placeholder&&a.placeholder.s(),a.ed())};t.R=function(){var a=this.diagram;null!==a&&!pj(this)&&!qj(this)&&this.isVisible()&&this.vb.o()&&a.R(zj(this,this.vb))};
t.s=function(){X.prototype.s.call(this);var a=this.diagram;null!==a&&(a.Dd.add(this),this instanceof V&&null!==this.labeledLink&&rl(this.labeledLink),a.cc(!0))};t.aq=function(a){a||(a=this.Sh,null!==a&&no(a,this))};t.bq=function(a){a||(a=this.Sh,null!==a&&oo(a,this))};t.$j=function(){var a=this.data;if(null!==a){var b=this.diagram;null!==b&&(b=b.model,null!==b&&b.om(a))}};t.Oy=function(){return po(this,this)};
function po(a,b){var c=b.containingGroup;return null!==c?1+po(a,c):b instanceof V&&(b=b.labeledLink,null!==b)?po(a,b):0}t.Ry=function(){return qo(this,this)};function qo(a,b){var c=b.containingGroup;return null!==c||b instanceof V&&(c=b.labeledLink,null!==c)?qo(a,c):b}t.Vd=function(a){return a instanceof ig?ro(this,this,a):!1};function ro(a,b,c){if(b===c||null===c)return!1;var d=b.containingGroup;return null===d||d!==c&&!ro(a,d,c)?b instanceof V&&(b=b.labeledLink,null!==b)?ro(a,b,c):!1:!0}
t.bx=function(a){if(null===a)return null;if(this===a)return this.containingGroup;for(var b=this;null!==b;){b instanceof ig&&mo(b,!0);if(b instanceof V){var c=b.labeledLink;null!==c&&(b=c)}b=b.containingGroup}c=null;for(b=a;null!==b;){if(0!==(b.D&1048576)){c=b;break}b instanceof V&&(a=b.labeledLink,null!==a&&(b=a));b=b.containingGroup}for(b=this;null!==b;)b instanceof ig&&mo(b,!1),b instanceof V&&(a=b.labeledLink,null!==a&&(b=a)),b=b.containingGroup;return c};
T.prototype.canLayout=function(){if(!this.isLayoutPositioned||!this.isVisible())return!1;var a=this.layer;return null!==a&&a.isTemporary||this instanceof V&&this.isLinkLabel?!1:!0};
T.prototype.B=function(a){void 0===a&&(a=16777215);if(this.isLayoutPositioned&&0!==(a&this.layoutConditions)){var b=this.layer;null!==b&&b.isTemporary||this instanceof V&&this.isLinkLabel?b=!1:(b=this.diagram,b=null!==b&&b.undoManager.isUndoingRedoing?!1:!0)}else b=!1;if(b)if(b=this.Sh,null!==b){var c=b.layout;null!==c?c.B():b.B(a)}else a=this.diagram,null!==a&&(a=a.layout,null!==a&&a.B())};function Fj(a){if(!a.isVisible())return!1;a=a.layer;return null!==a&&a.isTemporary?!1:!0}
function Hk(a,b,c,d,e,f){void 0===f&&(f=null);if(!(a.contains(b)||null!==f&&!f(b)||b instanceof pf))if(a.add(b),b instanceof V){if(c&&b instanceof ig)for(var g=b.memberParts;g.next();)Hk(a,g.value,c,d,e,f);if(!1!==e)for(g=b.linksConnected;g.next();){var h=g.value;if(!a.contains(h)){var k=h.fromNode,l=h.toNode;k=null===k||a.contains(k);l=null===l||a.contains(l);(e?k&&l:k||l)&&Hk(a,h,c,d,e,f)}}if(1<d)for(b=b.$u();b.next();)Hk(a,b.value,c,d-1,e,f)}else if(b instanceof S)for(b=b.labelNodes;b.next();)Hk(a,
b.value,c,d,e,f)}
pa.Object.defineProperties(T.prototype,{key:{get:function(){var a=this.diagram;if(null!==a)return a.model.pa(this.data)}},adornments:{get:function(){return null===this.xf?xb:this.xf.iteratorValues}},layer:{get:function(){return this.gi}},diagram:{get:function(){var a=this.gi;return null!==a?a.diagram:null}},layerName:{get:function(){return this.Tg},set:function(a){var b=
this.Tg;if(b!==a){var c=this.diagram;if(null===c||null!==c.Zl(a)&&!c.partManager.addsToTemporaryLayer)if(this.Tg=a,null!==c&&c.Xa(),this.g("layerName",b,a),b=this.layer,null!==b&&b.name!==a&&(c=b.diagram,null!==c&&(a=c.Zl(a),null!==a&&a!==b))){var d=b.xc(-1,this,!0);0<=d&&c.ab(df,"parts",b,this,null,d,!0);d=a.Pi(99999999,this,!0);b.visible!==a.visible&&this.Lb(a.visible);0<=d&&c.ab(cf,"parts",a,null,this,!0,d);d=this.layerChanged;if(null!==d){var e=c.ea;c.ea=!0;d(this,b,a);c.ea=e}}}}},layerChanged:{
get:function(){return this.$n},set:function(a){var b=this.$n;b!==a&&(this.$n=a,this.g("layerChanged",b,a))}},zOrder:{get:function(){return this.Kp},set:function(a){var b=this.Kp;if(b!==a){this.Kp=a;var c=this.layer;null!==c&&ki(c,-1,this);this.g("zOrder",b,a);a=this.diagram;null!==a&&a.R()}}},locationObject:{get:function(){if(null===this.We){var a=this.locationObjectName;""!==a?(a=this.$a(a),null!==a?this.We=a:this.We=this):
this instanceof pf?this.type!==X.Link&&null!==this.placeholder?this.We=this.placeholder:this.We=this:this.We=this}return this.We.visible?this.We:this}},minLocation:{get:function(){return this.qo},set:function(a){var b=this.qo;b.A(a)||(this.qo=a=a.I(),this.g("minLocation",b,a))}},maxLocation:{get:function(){return this.jo},set:function(a){var b=this.jo;b.A(a)||(this.jo=a=a.I(),this.g("maxLocation",b,a))}},locationObjectName:{
get:function(){return this.ho},set:function(a){var b=this.ho;b!==a&&(this.ho=a,this.We=null,this.s(),this.g("locationObjectName",b,a))}},locationSpot:{get:function(){return this.io},set:function(a){var b=this.io;b.A(a)||(this.io=a=a.I(),this.s(),this.g("locationSpot",b,a))}},location:{get:function(){return this.le},set:function(a){var b=a.x,c=a.y,d=this.le,e=d.x,f=d.y;(e===b||isNaN(e)&&isNaN(b))&&(f===c||isNaN(f)&&isNaN(c))||
(a=a.I(),b=a,this.Ah()?b=!1:(this.le=b,this.D|=2097152,!1===pj(this)&&(co(this),c=this.sa,c.o()&&(e=c.copy(),c.h(c.x+(b.x-d.x),c.y+(b.y-d.y)),ho(this,this.diagram,c,e),this.g("position",e,c))),b=!0),b&&this.g("location",d,a))}},category:{get:function(){return this.zf},set:function(a){var b=this.zf;if(b!==a){var c=this.diagram,d=this.data,e=null;if(null!==c&&null!==d&&!(this instanceof pf)){var f=c.model.undoManager;f.isEnabled&&!f.isUndoingRedoing&&(e=this.clone(),e.Y.addAll(this.Y))}this.zf=
a;this.g("category",b,a);null===c||null===d||this instanceof pf?this instanceof pf&&(e=this.adornedPart,null!==e&&(a=e.xf,null!==a&&a.remove(b),e.qh(this.category,this))):(f=c.model,f.undoManager.isUndoingRedoing||(this.Ah()?(c.partManager.setLinkCategoryForData(d,a),c=c.partManager.findLinkTemplateForCategory(a),null!==c&&(Yg(c),c=c.copy(),null!==c&&ko(this,c,b,a))):(null!==f&&f.sq(d,a),c=so(c.partManager,d,a),null!==c&&(Yg(c),c=c.copy(),null===c||c instanceof S||(d=this.location.copy(),ko(this,
c,b,a),this.location.o()||(this.location=d)))),null!==e&&(b=this.clone(),b.Y.addAll(this.Y),this.g("self",e,b))))}}},self:{get:function(){return this},set:function(a){ko(this,a,this.category,a.category)}},copyable:{get:function(){return 0!==(this.D&1)},set:function(a){var b=0!==(this.D&1);b!==a&&(this.D^=1,this.g("copyable",b,a))}},deletable:{get:function(){return 0!==(this.D&2)},set:function(a){var b=0!==(this.D&
2);b!==a&&(this.D^=2,this.g("deletable",b,a))}},textEditable:{get:function(){return 0!==(this.D&4)},set:function(a){var b=0!==(this.D&4);b!==a&&(this.D^=4,this.g("textEditable",b,a),this.Jb())}},groupable:{get:function(){return 0!==(this.D&8)},set:function(a){var b=0!==(this.D&8);b!==a&&(this.D^=8,this.g("groupable",b,a))}},movable:{get:function(){return 0!==(this.D&16)},set:function(a){var b=0!==(this.D&16);
b!==a&&(this.D^=16,this.g("movable",b,a))}},selectionAdorned:{get:function(){return 0!==(this.D&32)},set:function(a){var b=0!==(this.D&32);b!==a&&(this.D^=32,this.g("selectionAdorned",b,a),this.Jb())}},isInDocumentBounds:{get:function(){return 0!==(this.D&64)},set:function(a){var b=0!==(this.D&64);if(b!==a){this.D^=64;var c=this.diagram;null!==c&&c.Xa();this.g("isInDocumentBounds",b,a)}}},isLayoutPositioned:{
get:function(){return 0!==(this.D&128)},set:function(a){var b=0!==(this.D&128);b!==a&&(this.D^=128,this.g("isLayoutPositioned",b,a),this.B(a?4:8))}},selectable:{get:function(){return 0!==(this.D&256)},set:function(a){var b=0!==(this.D&256);b!==a&&(this.D^=256,this.g("selectable",b,a),this.Jb())}},reshapable:{get:function(){return 0!==(this.D&512)},set:function(a){var b=0!==(this.D&512);b!==a&&(this.D^=512,this.g("reshapable",b,a),this.Jb())}},
resizable:{get:function(){return 0!==(this.D&1024)},set:function(a){var b=0!==(this.D&1024);b!==a&&(this.D^=1024,this.g("resizable",b,a),this.Jb())}},rotatable:{get:function(){return 0!==(this.D&2048)},set:function(a){var b=0!==(this.D&2048);b!==a&&(this.D^=2048,this.g("rotatable",b,a),this.Jb())}},isSelected:{get:function(){return 0!==(this.D&4096)},set:function(a){var b=0!==(this.D&4096);if(b!==a){var c=this.diagram;
if(!a||this.canSelect()&&!(null!==c&&c.selection.count>=c.maxSelectionCount)){this.D^=4096;var d=!1;if(null!==c){d=c.skipsUndoManager;c.skipsUndoManager=!0;var e=c.selection;e.ha();a?e.add(this):e.remove(this);e.freeze()}this.g("isSelected",b,a);this.Jb();a=this.selectionChanged;null!==a&&a(this);null!==c&&(c.cc(),c.skipsUndoManager=d)}}}},isHighlighted:{get:function(){return 0!==(this.D&524288)},set:function(a){var b=0!==(this.D&524288);if(b!==a){this.D^=524288;var c=
this.diagram;null!==c&&(c=c.highlighteds,c.ha(),a?c.add(this):c.remove(this),c.freeze());this.g("isHighlighted",b,a);this.R();a=this.highlightedChanged;null!==a&&a(this)}}},isShadowed:{get:function(){return 0!==(this.D&8192)},set:function(a){var b=0!==(this.D&8192);b!==a&&(this.D^=8192,this.g("isShadowed",b,a),this.R())}},isAnimated:{get:function(){return 0!==(this.D&262144)},set:function(a){var b=0!==(this.D&262144);b!==a&&(this.D^=262144,
this.g("isAnimated",b,a))}},highlightedChanged:{get:function(){return this.Mn},set:function(a){var b=this.Mn;b!==a&&(this.Mn=a,this.g("highlightedChanged",b,a))}},selectionObjectName:{get:function(){return this.lp},set:function(a){var b=this.lp;b!==a&&(this.lp=a,this.dh=null,this.g("selectionObjectName",b,a))}},selectionAdornmentTemplate:{get:function(){return this.jp},set:function(a){var b=this.jp;b!==a&&(this.jp=
a,this.g("selectionAdornmentTemplate",b,a))}},selectionObject:{get:function(){if(null===this.dh){var a=this.selectionObjectName;null!==a&&""!==a?(a=this.$a(a),null!==a?this.dh=a:this.dh=this):this instanceof S?(a=this.path,null!==a?this.dh=a:this.dh=this):this.dh=this}return this.dh}},selectionChanged:{get:function(){return this.kp},set:function(a){var b=this.kp;b!==a&&(this.kp=a,this.g("selectionChanged",b,a))}},resizeAdornmentTemplate:{
get:function(){return this.Vo},set:function(a){var b=this.Vo;b!==a&&(this.Vo=a,this.g("resizeAdornmentTemplate",b,a))}},resizeObjectName:{get:function(){return this.Xo},set:function(a){var b=this.Xo;b!==a&&(this.Xo=a,this.g("resizeObjectName",b,a))}},resizeObject:{get:function(){var a=this.resizeObjectName;return""!==a&&(a=this.$a(a),null!==a)?a:this}},resizeCellSize:{get:function(){return this.Wo},
set:function(a){var b=this.Wo;b.A(a)||(this.Wo=a=a.I(),this.g("resizeCellSize",b,a))}},rotateAdornmentTemplate:{get:function(){return this.Yo},set:function(a){var b=this.Yo;b!==a&&(this.Yo=a,this.g("rotateAdornmentTemplate",b,a))}},rotateObjectName:{get:function(){return this.Zo},set:function(a){var b=this.Zo;b!==a&&(this.Zo=a,this.g("rotateObjectName",b,a))}},rotateObject:{get:function(){var a=this.rotateObjectName;
return""!==a&&(a=this.$a(a),null!==a)?a:this}},rotationSpot:{get:function(){return this.$o},set:function(a){var b=this.$o;b.A(a)||(this.$o=a=a.I(),this.g("rotationSpot",b,a))}},text:{get:function(){return this.Ob},set:function(a){var b=this.Ob;b!==a&&(this.Ob=a,this.g("text",b,a))}},containingGroup:{get:function(){return this.Sh},set:function(a){if(this.bc()){var b=this.Sh;if(b!==a){null===a||this!==a&&!a.Vd(this)||
(this===a&&A("Cannot make a Group a member of itself: "+this.toString()),A("Cannot make a Group indirectly contain itself: "+this.toString()+" already contains "+a.toString()));this.B(2);var c=this.diagram;null!==b?oo(b,this):this instanceof ig&&null!==c&&c.zi.remove(this);this.Sh=a;null!==a?no(a,this):this instanceof ig&&null!==c&&c.zi.add(this);this.B(1);if(null!==c){var d=this.data,e=c.model;if(null!==d&&e.fk()){var f=e.pa(null!==a?a.data:null);e.tt(d,f)}}d=this.containingGroupChanged;null!==d&&
(e=!0,null!==c&&(e=c.ea,c.ea=!0),d(this,b,a),null!==c&&(c.ea=e));if(this instanceof ig)for(c=new F,Hk(c,this,!0,0,!0),c=c.iterator;c.next();)if(d=c.value,d instanceof V)for(d=d.linksConnected;d.next();)to(d.value);if(this instanceof V){for(c=this.linksConnected;c.next();)to(c.value);c=this.labeledLink;null!==c&&to(c)}this.g("containingGroup",b,a);null!==a&&(b=a.layer,null!==b&&ki(b,-1,a))}}else A("cannot set the Part.containingGroup of a Link or Adornment")}},containingGroupChanged:{
get:function(){return this.Zm},set:function(a){var b=this.Zm;b!==a&&(this.Zm=a,this.g("containingGroupChanged",b,a))}},isTopLevel:{get:function(){return null!==this.containingGroup||this instanceof V&&null!==this.labeledLink?!1:!0}},layoutConditions:{get:function(){return this.ao},set:function(a){var b=this.ao;b!==a&&(this.ao=a,this.g("layoutConditions",b,a))}},dragComputation:{get:function(){return this.vn},
set:function(a){var b=this.vn;b!==a&&(this.vn=a,this.g("dragComputation",b,a))}},shadowOffset:{get:function(){return this.ui},set:function(a){var b=this.ui;b.A(a)||(this.ui=a=a.I(),this.R(),this.g("shadowOffset",b,a))}},shadowColor:{get:function(){return this.Kj},set:function(a){var b=this.Kj;b!==a&&(this.Kj=a,this.R(),this.g("shadowColor",b,a))}},shadowBlur:{get:function(){return this.Od},set:function(a){var b=
this.Od;b!==a&&(this.Od=a,this.R(),this.g("shadowBlur",b,a))}}});T.prototype.invalidateLayout=T.prototype.B;T.prototype.findCommonContainingGroup=T.prototype.bx;T.prototype.isMemberOf=T.prototype.Vd;T.prototype.findTopLevelPart=T.prototype.Ry;T.prototype.findSubGraphLevel=T.prototype.Oy;T.prototype.ensureBounds=T.prototype.$b;T.prototype.getDocumentBounds=T.prototype.Yp;T.prototype.getRelativePoint=T.prototype.pf;T.prototype.findObject=T.prototype.$a;T.prototype.moveTo=T.prototype.moveTo;
T.prototype.invalidateAdornments=T.prototype.Jb;T.prototype.clearAdornments=T.prototype.Uj;T.prototype.removeAdornment=T.prototype.uf;T.prototype.addAdornment=T.prototype.qh;T.prototype.findAdornment=T.prototype.bk;T.prototype.updateTargetBindings=T.prototype.Ea;var lo=!1;T.className="Part";T.LayoutNone=0;T.LayoutAdded=1;T.LayoutRemoved=2;T.LayoutShown=4;T.LayoutHidden=8;T.LayoutNodeSized=16;T.LayoutGroupLayout=32;T.LayoutNodeReplaced=64;T.LayoutStandard=127;T.LayoutAll=16777215;
function pf(a){T.call(this,a);this.D&=-257;this.Tg="Adornment";this.$d=null;this.Cw=0;this.Nw=!1;this.l=[];this.Va=null}oa(pf,T);pf.prototype.toString=function(){var a=this.adornedPart;return"Adornment("+this.category+")"+(null!==a?a.toString():"")};pf.prototype.updateRelationshipsFromData=function(){};
pf.prototype.ik=function(a){var b=this.adornedObject.part;if(b instanceof S&&this.adornedObject instanceof W){var c=b.path;b.ik(a);a=c.geometry;b=this.Y.j;c=b.length;for(var d=0;d<c;d++){var e=b[d];e.isPanelMain&&e instanceof W&&(e.qa=a)}}};pf.prototype.Ri=function(){var a=this.$d;if(null===a)return!0;a=a.part;return null===a||!pj(a)};pf.prototype.bc=function(){return!1};
pf.prototype.qk=function(a,b,c,d,e,f,g){if(a===cf&&"elements"===b)if(e instanceof Xg)null===this.Va&&(this.Va=e);else{if(e instanceof X){var h=e.Yl(function(a){return a instanceof Xg});h instanceof Xg&&null===this.Va&&(this.Va=h)}}else a===df&&"elements"===b&&null!==this.Va&&(d===this.Va?this.Va=null:d instanceof X&&this.Va.rg(d)&&(this.Va=null));T.prototype.qk.call(this,a,b,c,d,e,f,g)};pf.prototype.updateAdornments=function(){};pf.prototype.$j=function(){};
pa.Object.defineProperties(pf.prototype,{placeholder:{get:function(){return this.Va}},adornedObject:{get:function(){return this.$d},set:function(a){var b=this.adornedPart,c=null;null!==a&&(c=a.part);null===b||null!==a&&b===c||b.uf(this.category);this.$d=a;null!==c&&c.qh(this.category,this)}},adornedPart:{get:function(){var a=this.$d;return null!==a?a.part:null}},containingGroup:{
get:function(){return null}}});pf.className="Adornment";function V(a){T.call(this,a);this.S=13;this.Ya=new E;this.Ep=this.hl=this.ji=this.co=this.bo=null;this.Dk=Pc;this.rc=this.Le=null;this.So=uo;this.oh=!1}oa(V,T);V.prototype.cloneProtected=function(a){T.prototype.cloneProtected.call(this,a);a.S=this.S;a.S=this.S&-17;a.bo=this.bo;a.co=this.co;a.ji=this.ji;a.Ep=this.Ep;a.Dk=this.Dk.I();a.So=this.So};t=V.prototype;t.nf=function(a){T.prototype.nf.call(this,a);a.ed();a.Le=this.Le;a.rc=null};
function vo(a,b){null!==b&&(null===a.Le&&(a.Le=new F),a.Le.add(b))}function wo(a,b,c,d){if(null===b||null===a.Le)return null;for(var e=a.Le.iterator;e.next();){var f=e.value;if(f.it===a&&f.ov===b&&f.ux===c&&f.vx===d||f.it===b&&f.ov===a&&f.ux===d&&f.vx===c)return f}return null}t.mz=function(a,b,c){if(void 0===b||null===b)b="";if(void 0===c||null===c)c="";a=wo(this,a,b,c);null!==a&&a.cm()};
t.qk=function(a,b,c,d,e,f,g){a===cf&&"elements"===b?this.rc=null:a===df&&"elements"===b&&(this.rc=null);T.prototype.qk.call(this,a,b,c,d,e,f,g)};t.ed=function(a){void 0===a&&(a=null);for(var b=this.linksConnected;b.next();){var c=b.value;null!==a&&a.contains(c)||(xo(this,c.fromPort),xo(this,c.toPort),c.Ra())}};function dl(a,b){for(var c=a.linksConnected;c.next();){var d=c.value;if(d.fromPort===b||d.toPort===b)xo(a,d.fromPort),xo(a,d.toPort),d.Ra()}}
function xo(a,b){null!==b&&(b=b.Ro,null!==b&&b.cm(),a=a.containingGroup,null===a||a.isSubGraphExpanded||xo(a,a.port))}t.Ri=function(){return!0};V.prototype.getAvoidableRect=function(a){a.set(this.actualBounds);a.Np(this.Dk);return a};V.prototype.findVisibleNode=function(){for(var a=this;null!==a&&!a.isVisible();)a=a.containingGroup;return a};
V.prototype.isVisible=function(){if(!T.prototype.isVisible.call(this))return!1;var a=!0,b=ri,c=this.diagram;if(null!==c){if(Xh(c.animationManager,this))return!0;a=c.isTreePathToChildren;b=c.treeCollapsePolicy}if(b===ri){if(a=this.pg(),null!==a&&!a.isTreeExpanded)return!1}else if(b===vk){if(a=a?this.Yu():this.Zu(),0<a.count&&a.all(function(a){return!a.isTreeExpanded}))return!1}else if(b===wk&&(a=a?this.Yu():this.Zu(),0<a.count&&a.any(function(a){return!a.isTreeExpanded})))return!1;a=this.labeledLink;
return null!==a?a.isVisible():!0};t=V.prototype;t.Lb=function(a){T.prototype.Lb.call(this,a);for(var b=this.linksConnected;b.next();)b.value.Lb(a)};t.Wu=function(a){void 0===a&&(a=null);if(null===a)return this.Ya.iterator;var b=new zb(this.Ya),c=this;b.predicate=function(b){return b.fromNode===c&&b.fromPortId===a||b.toNode===c&&b.toPortId===a};return b};
t.Vp=function(a){void 0===a&&(a=null);var b=new zb(this.Ya),c=this;b.predicate=function(b){return b.fromNode!==c?!1:null===a?!0:b.fromPortId===a};return b};t.sd=function(a){void 0===a&&(a=null);var b=new zb(this.Ya),c=this;b.predicate=function(b){return b.toNode!==c?!1:null===a?!0:b.toPortId===a};return b};
t.Xu=function(a){void 0===a&&(a=null);for(var b=null,c=null,d=this.Ya.iterator;d.next();){var e=d.value;if(e.fromNode===this){if(null===a||e.fromPortId===a)e=e.toNode,null!==b?b.add(e):null!==c&&c!==e?(b=new F,b.add(c),b.add(e)):c=e}else e.toNode!==this||null!==a&&e.toPortId!==a||(e=e.fromNode,null!==b?b.add(e):null!==c&&c!==e?(b=new F,b.add(c),b.add(e)):c=e)}return null!==b?b.iterator:null!==c?new yb(c):xb};
t.Zu=function(a){void 0===a&&(a=null);for(var b=null,c=null,d=this.Ya.iterator;d.next();){var e=d.value;e.fromNode!==this||null!==a&&e.fromPortId!==a||(e=e.toNode,null!==b?b.add(e):null!==c&&c!==e?(b=new F,b.add(c),b.add(e)):c=e)}return null!==b?b.iterator:null!==c?new yb(c):xb};
t.Yu=function(a){void 0===a&&(a=null);for(var b=null,c=null,d=this.Ya.iterator;d.next();){var e=d.value;e.toNode!==this||null!==a&&e.toPortId!==a||(e=e.fromNode,null!==b?b.add(e):null!==c&&c!==e?(b=new F,b.add(c),b.add(e)):c=e)}return null!==b?b.iterator:null!==c?new yb(c):xb};
t.Jy=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);var d=new zb(this.Ya),e=this;d.predicate=function(d){return(d.fromNode!==e||d.toNode!==a||null!==b&&d.fromPortId!==b||null!==c&&d.toPortId!==c)&&(d.fromNode!==a||d.toNode!==e||null!==c&&d.fromPortId!==c||null!==b&&d.toPortId!==b)?!1:!0};return d};
t.Ky=function(a,b,c){void 0===b&&(b=null);void 0===c&&(c=null);var d=new zb(this.Ya),e=this;d.predicate=function(d){return d.fromNode!==e||d.toNode!==a||null!==b&&d.fromPortId!==b||null!==c&&d.toPortId!==c?!1:!0};return d};
function yo(a,b,c){xo(a,c);var d=a.Ya.contains(b);d||a.Ya.add(b);if(!d||b.fromNode===b.toNode){var e=a.linkConnected;if(null!==e){var f=!0,g=a.diagram;null!==g&&(f=g.ea,g.ea=!0);e(a,b,c);null!==g&&(g.ea=f)}}!d&&b.isTreeLink&&(c=b.fromNode,b=b.toNode,null!==c&&null!==b&&c!==b&&(d=!0,a=a.diagram,null!==a&&(d=a.isTreePathToChildren),e=d?b:c,f=d?c:b,e.oh||(e.oh=f),!f.isTreeLeaf||null!==a&&a.undoManager.isUndoingRedoing||(d?c===f&&(f.isTreeLeaf=!1):b===f&&(f.isTreeLeaf=!1))))}
function zo(a,b,c){xo(a,c);var d=a.Ya.remove(b),e=null;if(d||b.toNode===b.fromNode){var f=a.linkDisconnected;e=a.diagram;if(null!==f){var g=!0;null!==e&&(g=e.ea,e.ea=!0);f(a,b,c);null!==e&&(e.ea=g)}}d&&b.isTreeLink&&(c=!0,null!==e&&(c=e.isTreePathToChildren),a=c?b.toNode:b.fromNode,b=c?b.fromNode:b.toNode,null!==a&&(a.oh=!1),null===b||b.isTreeLeaf||(0===b.Ya.count?(b.oh=null,null!==e&&e.undoManager.isUndoingRedoing||(b.isTreeLeaf=!0)):uk(b)))}
function uk(a){a.oh=!1;if(0!==a.Ya.count){var b=!0,c=a.diagram;if(null===c||!c.undoManager.isUndoingRedoing){null!==c&&(b=c.isTreePathToChildren);for(c=a.Ya.iterator;c.next();){var d=c.value;if(d.isTreeLink)if(b){if(d.fromNode===a){a.isTreeLeaf=!1;return}}else if(d.toNode===a){a.isTreeLeaf=!1;return}}a.isTreeLeaf=!0}}}V.prototype.updateRelationshipsFromData=function(){var a=this.diagram;null!==a&&a.partManager.updateRelationshipsFromData(this)};t=V.prototype;
t.aq=function(a){T.prototype.aq.call(this,a);a||(uk(this),a=this.hl,null!==a&&Ao(a,this))};t.bq=function(a){T.prototype.bq.call(this,a);a||(a=this.hl,null!==a&&null!==a.$c&&(a.$c.remove(this),a.s()))};
t.$j=function(){if(0<this.Ya.count){var a=this.diagram;if(null!==a)for(var b=null!==a.commandHandler?a.commandHandler.deletesConnectedLinks:!0,c=this.Ya.copy().iterator;c.next();){var d=c.value;b?a.remove(d):(d.fromNode===this&&(d.fromNode=null),d.toNode===this&&(d.toNode=null))}}this.labeledLink=null;T.prototype.$j.call(this)};t.Ss=function(a){if(null===this.rc){if(""===a&&!1===this.yh)return this;Bo(this)}var b=this.rc.J(a);return null!==b||""!==a&&(b=this.rc.J(""),null!==b)?b:this};
function Bo(a){null===a.rc?a.rc=new Lb:a.rc.clear();a.ym(a,function(a,c){Hl(a,c)});0===a.rc.count&&a.rc.add("",a)}function Hl(a,b){var c=b.portId;null!==c&&null!==a.rc&&a.rc.add(c,b)}function Gl(a,b,c){var d=b.portId;if(null!==d&&(null!==a.rc&&a.rc.remove(d),b=a.diagram,null!==b&&c)){c=null;for(a=a.Wu(d);a.next();)d=a.value,null===c&&(c=Ka()),c.push(d);if(null!==c){for(a=0;a<c.length;a++)b.remove(c[a]);Oa(c)}}}
t.oz=function(a){if(null===a||a===this)return!1;var b=!0,c=this.diagram;null!==c&&(b=c.isTreePathToChildren);c=this;if(b)for(;c!==a;){b=null;for(var d=c.Ya.iterator;d.next();){var e=d.value;if(e.isTreeLink&&(b=e.fromNode,b!==c&&b!==this))break}if(b===this||null===b||b===c)return!1;c=b}else for(;c!==a;){b=null;for(d=c.Ya.iterator;d.next()&&(e=d.value,!e.isTreeLink||(b=e.toNode,b===c||b===this)););if(b===this||null===b||b===c)return!1;c=b}return!0};
t.Vy=function(){var a=!0,b=this.diagram;null!==b&&(a=b.isTreePathToChildren);b=this;if(a)for(;;){a=null;for(var c=b.Ya.iterator;c.next();){var d=c.value;if(d.isTreeLink&&(a=d.fromNode,a!==b&&a!==this))break}if(a===this)return this;if(null===a||a===b)return b;b=a}else for(;;){a=null;for(c=b.Ya.iterator;c.next()&&(d=c.value,!d.isTreeLink||(a=d.toNode,a===b||a===this)););if(a===this)return this;if(null===a||a===b)return b;b=a}};
t.Gy=function(a){if(null===a)return null;if(this===a)return this;for(var b=this;null!==b;)mo(b,!0),b=b.pg();var c=null;for(b=a;null!==b;){if(0!==(b.D&1048576)){c=b;break}b=b.pg()}for(b=this;null!==b;)mo(b,!1),b=b.pg();return c};t.Ji=function(){var a=!0,b=this.diagram;null!==b&&(a=b.isTreePathToChildren);b=this.Ya.iterator;if(a)for(;b.next();){if(a=b.value,a.isTreeLink&&a.fromNode!==this)return a}else for(;b.next();)if(a=b.value,a.isTreeLink&&a.toNode!==this)return a;return null};
t.pg=function(){var a=this.oh;if(null===a)return null;if(a instanceof V)return a;var b=!0;a=this.diagram;null!==a&&(b=a.isTreePathToChildren);a=this.Ya.iterator;if(b)for(;a.next();){if(b=a.value,b.isTreeLink&&(b=b.fromNode,b!==this))return this.oh=b}else for(;a.next();)if(b=a.value,b.isTreeLink&&(b=b.toNode,b!==this))return this.oh=b;return this.oh=null};t.Ty=function(){function a(b,d){if(null!==b){d.add(b);var c=b.Ji();null!==c&&(d.add(c),a(b.pg(),d))}}var b=new F;a(this,b);return b};
t.Sy=function(){return Co(this,this)};function Co(a,b){b=b.pg();return null===b?0:1+Co(a,b)}t.Xp=function(){var a=!0,b=this.diagram;null!==b&&(a=b.isTreePathToChildren);b=new zb(this.Ya);var c=this;b.predicate=a?function(a){return a.isTreeLink&&a.fromNode===c?!0:!1}:function(a){return a.isTreeLink&&a.toNode===c?!0:!1};return b};
t.$u=function(){var a=!0,b=this.diagram;null!==b&&(a=b.isTreePathToChildren);var c=b=null,d=this.Ya.iterator;if(a)for(;d.next();)a=d.value,a.isTreeLink&&a.fromNode===this&&(a=a.toNode,null!==b?b.add(a):null!==c&&c!==a?(b=new E,b.add(c),b.add(a)):c=a);else for(;d.next();)a=d.value,a.isTreeLink&&a.toNode===this&&(a=a.fromNode,null!==b?b.add(a):null!==c&&c!==a?(b=new E,b.add(c),b.add(a)):c=a);return null!==b?b.iterator:null!==c?new yb(c):xb};
t.Uy=function(a){void 0===a&&(a=Infinity);var b=new F;Hk(b,this,!1,a,!0);return b};V.prototype.collapseTree=function(a){void 0===a&&(a=1);1>a&&(a=1);var b=this.diagram;if(null!==b&&!b.Ee){b.Ee=!0;var c=new F;c.add(this);Do(this,c,b.isTreePathToChildren,a,b,this,b.treeCollapsePolicy===ri);b.Ee=!1}};function Do(a,b,c,d,e,f,g){if(1<d)for(var h=c?a.Vp():a.sd();h.next();){var k=h.value;k.isTreeLink&&(k=k.Ws(a),null===k||k===a||b.contains(k)||(b.add(k),Do(k,b,c,d-1,e,f,g)))}else Eo(a,b,c,e,f,g)}
function Eo(a,b,c,d,e,f){for(var g=e===a?!0:a.isTreeExpanded,h=c?a.Vp():a.sd();h.next();){var k=h.value;if(k.isTreeLink&&(k=k.Ws(a),null!==k&&k!==a)){var l=b.contains(k);l||b.add(k);g&&(f&&d.Lp(k,e),k.zh(),k.Lb(!1));k.isTreeExpanded&&(k.wasTreeExpanded=k.isTreeExpanded,l||Eo(k,b,c,d,e,f))}}a.isTreeExpanded=!1}
V.prototype.expandTree=function(a){void 0===a&&(a=2);2>a&&(a=2);var b=this.diagram;if(null!==b&&!b.Ee){b.Ee=!0;var c=new F;c.add(this);Fo(this,c,b.isTreePathToChildren,a,b,this,b.treeCollapsePolicy===ri);b.Ee=!1}};
function Fo(a,b,c,d,e,f,g){for(var h=f===a?!1:a.isTreeExpanded,k=c?a.Vp():a.sd();k.next();){var l=k.value;l.isTreeLink&&(h||l.Lc||l.Ra(),l=l.Ws(a),null!==l&&l!==a&&!b.contains(l)&&(b.add(l),h||(l.Lb(!0),l.zh(),g&&e.Mp(l,f)),2<d||l.wasTreeExpanded))&&(l.wasTreeExpanded=!1,Fo(l,b,c,d-1,e,f,g))}a.isTreeExpanded=!0}
pa.Object.defineProperties(V.prototype,{portSpreading:{get:function(){return this.So},set:function(a){var b=this.So;b!==a&&(this.So=a,this.g("portSpreading",b,a),a=this.diagram,null!==a&&a.undoManager.isUndoingRedoing||this.ed())}},avoidable:{get:function(){return 0!==(this.S&8)},set:function(a){var b=0!==(this.S&8);if(b!==a){this.S^=8;var c=this.diagram;null!==c&&ok(c,this);this.g("avoidable",b,a)}}},avoidableMargin:{
get:function(){return this.Dk},set:function(a){"number"===typeof a&&(a=new Ic(a));var b=this.Dk;if(!b.A(a)){this.Dk=a=a.I();var c=this.diagram;null!==c&&ok(c,this);this.g("avoidableMargin",b,a)}}},linksConnected:{get:function(){return this.Ya.iterator}},linkConnected:{get:function(){return this.bo},set:function(a){var b=this.bo;b!==a&&(this.bo=a,this.g("linkConnected",b,a))}},linkDisconnected:{get:function(){return this.co},
set:function(a){var b=this.co;b!==a&&(this.co=a,this.g("linkDisconnected",b,a))}},linkValidation:{get:function(){return this.ji},set:function(a){var b=this.ji;b!==a&&(this.ji=a,this.g("linkValidation",b,a))}},isLinkLabel:{get:function(){return null!==this.hl}},labeledLink:{get:function(){return this.hl},set:function(a){var b=this.hl;if(b!==a){var c=this.diagram,d=this.data;if(null!==b){null!==b.$c&&(b.$c.remove(this),
b.s());if(null!==c&&null!==d&&!c.undoManager.isUndoingRedoing){var e=b.data,f=c.model;if(null!==e&&f.em()){var g=f.pa(d);void 0!==g&&f.wx(e,g)}}this.containingGroup=null}this.hl=a;null!==a&&(Ao(a,this),null===c||null===d||c.undoManager.isUndoingRedoing||(e=a.data,c=c.model,null!==e&&c.em()&&(d=c.pa(d),void 0!==d&&c.Fu(e,d))),this.containingGroup=a.containingGroup);rl(this);this.g("labeledLink",b,a)}}},port:{get:function(){return this.Ss("")}},ports:{
get:function(){null===this.rc&&Bo(this);return this.rc.iteratorValues}},isTreeExpanded:{get:function(){return 0!==(this.S&1)},set:function(a){var b=0!==(this.S&1);if(b!==a){this.S^=1;var c=this.diagram;this.g("isTreeExpanded",b,a);b=this.treeExpandedChanged;if(null!==b){var d=!0;null!==c&&(d=c.ea,c.ea=!0);b(this);null!==c&&(c.ea=d)}null!==c&&c.undoManager.isUndoingRedoing?this.Lb(a):a?this.expandTree():this.collapseTree()}}},wasTreeExpanded:{
get:function(){return 0!==(this.S&2)},set:function(a){var b=0!==(this.S&2);b!==a&&(this.S^=2,this.g("wasTreeExpanded",b,a))}},treeExpandedChanged:{get:function(){return this.Ep},set:function(a){var b=this.Ep;b!==a&&(this.Ep=a,this.g("treeExpandedChanged",b,a))}},isTreeLeaf:{get:function(){return 0!==(this.S&4)},set:function(a){var b=0!==(this.S&4);b!==a&&(this.S^=4,this.g("isTreeLeaf",b,a))}}});V.prototype.expandTree=V.prototype.expandTree;
V.prototype.collapseTree=V.prototype.collapseTree;V.prototype.findTreeParts=V.prototype.Uy;V.prototype.findTreeChildrenNodes=V.prototype.$u;V.prototype.findTreeChildrenLinks=V.prototype.Xp;V.prototype.findTreeLevel=V.prototype.Sy;V.prototype.findTreeParentChain=V.prototype.Ty;V.prototype.findTreeParentNode=V.prototype.pg;V.prototype.findTreeParentLink=V.prototype.Ji;V.prototype.findCommonTreeParent=V.prototype.Gy;V.prototype.findTreeRoot=V.prototype.Vy;V.prototype.isInTreeOf=V.prototype.oz;
V.prototype.findPort=V.prototype.Ss;V.prototype.findLinksTo=V.prototype.Ky;V.prototype.findLinksBetween=V.prototype.Jy;V.prototype.findNodesInto=V.prototype.Yu;V.prototype.findNodesOutOf=V.prototype.Zu;V.prototype.findNodesConnected=V.prototype.Xu;V.prototype.findLinksInto=V.prototype.sd;V.prototype.findLinksOutOf=V.prototype.Vp;V.prototype.findLinksConnected=V.prototype.Wu;V.prototype.invalidateConnectedLinks=V.prototype.ed;V.prototype.invalidateLinkBundle=V.prototype.mz;
var Go=new D(V,"SpreadingNone",10),uo=new D(V,"SpreadingEvenly",11),Ho=new D(V,"SpreadingPacked",12);V.className="Node";V.SpreadingNone=Go;V.SpreadingEvenly=uo;V.SpreadingPacked=Ho;function ig(a){V.call(this,a);this.S|=4608;this.no=new F;this.rl=new F;this.Va=this.wp=this.ki=this.oo=this.mo=null;this.gc=new yi;this.gc.group=this}oa(ig,V);
ig.prototype.cloneProtected=function(a){V.prototype.cloneProtected.call(this,a);this.S=this.S&-32769;a.mo=this.mo;a.oo=this.oo;a.ki=this.ki;a.wp=this.wp;var b=a.Yl(function(a){return a instanceof Xg});b instanceof Xg?a.Va=b:a.Va=null;null!==this.gc?(a.gc=this.gc.copy(),a.gc.group=a):(null!==a.gc&&(a.gc.group=null),a.gc=null)};t=ig.prototype;
t.nf=function(a){V.prototype.nf.call(this,a);var b=a.ck();for(a=a.memberParts;a.next();){var c=a.value;c.s();c.B(8);c.Uj();if(c instanceof V)c.ed(b);else if(c instanceof S)for(c=c.labelNodes;c.next();)c.value.ed(b)}};
t.qk=function(a,b,c,d,e,f,g){if(a===cf&&"elements"===b)if(e instanceof Xg)null===this.Va?this.Va=e:this.Va!==e&&A("Cannot insert a second Placeholder into the visual tree of a Group.");else{if(e instanceof X){var h=e.Yl(function(a){return a instanceof Xg});h instanceof Xg&&(null===this.Va?this.Va=h:this.Va!==h&&A("Cannot insert a second Placeholder into the visual tree of a Group."))}}else a===df&&"elements"===b&&null!==this.Va&&(d===this.Va?this.Va=null:d instanceof X&&this.Va.rg(d)&&(this.Va=null));
V.prototype.qk.call(this,a,b,c,d,e,f,g)};t.uh=function(a,b,c,d){this.We=this.Va;V.prototype.uh.call(this,a,b,c,d)};t.Ri=function(){if(!V.prototype.Ri.call(this))return!1;for(var a=this.memberParts;a.next();){var b=a.value;if(b instanceof V){if(b.isVisible()&&pj(b))return!1}else if(b instanceof S&&b.isVisible()&&pj(b)&&b.fromNode!==this&&b.toNode!==this)return!1}return!0};
function no(a,b){if(a.no.add(b)){b instanceof ig&&a.rl.add(b);var c=a.memberAdded;if(null!==c){var d=!0,e=a.diagram;null!==e&&(d=e.ea,e.ea=!0);c(a,b);null!==e&&(e.ea=d)}a.isVisible()&&a.isSubGraphExpanded||b.Lb(!1)}b instanceof S&&!a.computesBoundsIncludingLinks||(b=a.Va,null===b&&(b=a),b.s())}
function oo(a,b){if(a.no.remove(b)){b instanceof ig&&a.rl.remove(b);var c=a.memberRemoved;if(null!==c){var d=!0,e=a.diagram;null!==e&&(d=e.ea,e.ea=!0);c(a,b);null!==e&&(e.ea=d)}a.isVisible()&&a.isSubGraphExpanded||b.Lb(!0)}b instanceof S&&!a.computesBoundsIncludingLinks||(b=a.Va,null===b&&(b=a),b.s())}t.$j=function(){if(0<this.no.count){var a=this.diagram;if(null!==a)for(var b=this.no.copy().iterator;b.next();)a.remove(b.value)}V.prototype.$j.call(this)};
ig.prototype.canAddMembers=function(a){var b=this.diagram;if(null===b)return!1;b=b.commandHandler;for(a=Jk(a).iterator;a.next();)if(!b.isValidMember(this,a.value))return!1;return!0};ig.prototype.addMembers=function(a,b){var c=this.diagram;if(null===c)return!1;c=c.commandHandler;var d=!0;for(a=Jk(a).iterator;a.next();){var e=a.value;!b||c.isValidMember(this,e)?e.containingGroup=this:d=!1}return d};
ig.prototype.canUngroup=function(){if(!this.ungroupable)return!1;var a=this.layer;if(null!==a&&!a.allowUngroup)return!1;a=a.diagram;return null===a||a.allowUngroup?!0:!1};t=ig.prototype;
t.ed=function(a){void 0===a&&(a=null);var b=0!==(this.S&65536);V.prototype.ed.call(this,a);if(!b)for(0!==(this.S&65536)!==!0&&(this.S=this.S^65536),b=this.Vu();b.next();){var c=b.value;if(null===a||!a.contains(c)){var d=c.fromNode;null!==d&&d!==this&&d.Vd(this)&&!d.isVisible()?(xo(d,c.fromPort),xo(d,c.toPort),c.Ra()):(d=c.toNode,null!==d&&d!==this&&d.Vd(this)&&!d.isVisible()&&(xo(d,c.fromPort),xo(d,c.toPort),c.Ra()))}}};
t.Vu=function(){var a=this.ck();a.add(this);for(var b=new F,c=a.iterator;c.next();){var d=c.value;if(d instanceof V)for(d=d.linksConnected;d.next();){var e=d.value;a.contains(e)||b.add(e)}}return b.iterator};t.Iy=function(){var a=this.ck();a.add(this);for(var b=new F,c=a.iterator;c.next();){var d=c.value;if(d instanceof V)for(d=d.linksConnected;d.next();){var e=d.value,f=e.fromNode;a.contains(f)&&f!==this||b.add(f);e=e.toNode;a.contains(e)&&e!==this||b.add(e)}}return b.iterator};
t.Hy=function(){function a(b,d){null!==b&&(d.add(b),a(b.containingGroup,d))}var b=new F;a(this,b);return b};t.ck=function(){var a=new F;Hk(a,this,!0,0,!0);a.remove(this);return a};t.Lb=function(a){V.prototype.Lb.call(this,a);for(var b=this.memberParts;b.next();)b.value.Lb(a)};ig.prototype.collapseSubGraph=function(){var a=this.diagram;if(null!==a&&!a.Ee){a.Ee=!0;var b=this.ck();Io(this,b,a,this);a.Ee=!1}};
function Io(a,b,c,d){for(var e=a.memberParts;e.next();){var f=e.value;f.Lb(!1);f instanceof ig&&f.isSubGraphExpanded&&(f.wasSubGraphExpanded=f.isSubGraphExpanded,Io(f,b,c,d));if(f instanceof V)f.ed(b),c.Lp(f,d);else if(f instanceof S)for(f=f.labelNodes;f.next();)f.value.ed(b)}a.isSubGraphExpanded=!1}ig.prototype.expandSubGraph=function(){var a=this.diagram;if(null!==a&&!a.Ee){a.Ee=!0;var b=this.ck();Jo(this,b,a,this);a.Ee=!1}};
function Jo(a,b,c,d){for(var e=a.memberParts;e.next();){var f=e.value;f.Lb(!0);f instanceof ig&&f.wasSubGraphExpanded&&(f.wasSubGraphExpanded=!1,Jo(f,b,c,d));if(f instanceof V)f.ed(b),c.Mp(f,d);else if(f instanceof S)for(f=f.labelNodes;f.next();)f.value.ed(b)}a.isSubGraphExpanded=!0}
ig.prototype.move=function(a,b){void 0===b&&(b=!1);var c=b?this.location:this.position,d=c.x;isNaN(d)&&(d=0);c=c.y;isNaN(c)&&(c=0);d=a.x-d;c=a.y-c;var e=H.allocAt(d,c);V.prototype.move.call(this,a,b);a=new F;for(b=this.ck().iterator;b.next();){var f=b.value;f instanceof S&&(f.suspendsRouting&&a.add(f),f.Lc||f.fromNode!==this&&f.toNode!==this)&&(f.suspendsRouting=!0)}for(b.reset();b.next();)if(f=b.value,!(f.Ah()||f instanceof V&&f.isLinkLabel)){var g=f.position,h=f.location;g.o()?(e.x=g.x+d,e.y=g.y+
c,f.position=e):h.o()&&(e.x=h.x+d,e.y=h.y+c,f.location=e)}for(b.reset();b.next();)if(f=b.value,f instanceof S&&(f.suspendsRouting=a.contains(f),f.Lc||f.fromNode!==this&&f.toNode!==this))g=f.position,e.x=g.x+d,e.y=g.y+c,e.o()?f.move(e):f.Ra(),Mj(f)&&f.Ra();H.free(e)};
pa.Object.defineProperties(ig.prototype,{placeholder:{get:function(){return this.Va}},computesBoundsAfterDrag:{get:function(){return 0!==(this.S&2048)},set:function(a){var b=0!==(this.S&2048);b!==a&&(this.S^=2048,this.g("computesBoundsAfterDrag",b,a))}},computesBoundsIncludingLinks:{get:function(){return 0!==(this.S&4096)},set:function(a){var b=0!==(this.S&4096);b!==a&&(this.S^=4096,this.g("computesBoundsIncludingLinks",
b,a))}},computesBoundsIncludingLocation:{get:function(){return 0!==(this.S&8192)},set:function(a){var b=0!==(this.S&8192);b!==a&&(this.S^=8192,this.g("computesBoundsIncludingLocation",b,a))}},handlesDragDropForMembers:{get:function(){return 0!==(this.S&16384)},set:function(a){var b=0!==(this.S&16384);b!==a&&(this.S^=16384,this.g("handlesDragDropForMembers",b,a))}},memberParts:{get:function(){return this.no.iterator}},
layout:{get:function(){return this.gc},set:function(a){var b=this.gc;if(b!==a){null!==b&&(b.diagram=null,b.group=null);this.gc=a;var c=this.diagram;null!==a&&(a.diagram=c,a.group=this);null!==c&&(c.Bg=!0);this.g("layout",b,a);null!==c&&c.cc()}}},memberAdded:{get:function(){return this.mo},set:function(a){var b=this.mo;b!==a&&(this.mo=a,this.g("memberAdded",b,a))}},memberRemoved:{get:function(){return this.oo},
set:function(a){var b=this.oo;b!==a&&(this.oo=a,this.g("memberRemoved",b,a))}},memberValidation:{get:function(){return this.ki},set:function(a){var b=this.ki;b!==a&&(this.ki=a,this.g("memberValidation",b,a))}},ungroupable:{get:function(){return 0!==(this.S&256)},set:function(a){var b=0!==(this.S&256);b!==a&&(this.S^=256,this.g("ungroupable",b,a))}},isSubGraphExpanded:{get:function(){return 0!==(this.S&512)},
set:function(a){var b=0!==(this.S&512);if(b!==a){this.S^=512;var c=this.diagram;this.g("isSubGraphExpanded",b,a);b=this.subGraphExpandedChanged;if(null!==b){var d=!0;null!==c&&(d=c.ea,c.ea=!0);b(this);null!==c&&(c.ea=d)}null!==c&&c.undoManager.isUndoingRedoing?(null!==this.Va&&this.Va.s(),this.memberParts.each(function(a){a.updateAdornments()})):a?this.expandSubGraph():this.collapseSubGraph()}}},wasSubGraphExpanded:{get:function(){return 0!==(this.S&1024)},set:function(a){var b=
0!==(this.S&1024);b!==a&&(this.S^=1024,this.g("wasSubGraphExpanded",b,a))}},subGraphExpandedChanged:{get:function(){return this.wp},set:function(a){var b=this.wp;b!==a&&(this.wp=a,this.g("subGraphExpandedChanged",b,a))}},pk:{get:function(){return 0!==(this.S&32768)},set:function(a){0!==(this.S&32768)!==a&&(this.S^=32768)}}});ig.prototype.expandSubGraph=ig.prototype.expandSubGraph;ig.prototype.collapseSubGraph=ig.prototype.collapseSubGraph;
ig.prototype.findSubGraphParts=ig.prototype.ck;ig.prototype.findContainingGroupChain=ig.prototype.Hy;ig.prototype.findExternalNodesConnected=ig.prototype.Iy;ig.prototype.findExternalLinksConnected=ig.prototype.Vu;ig.className="Group";function Xg(){Y.call(this);this.eb=Oc;this.gp=new N(NaN,NaN,NaN,NaN)}oa(Xg,Y);Xg.prototype.cloneProtected=function(a){Y.prototype.cloneProtected.call(this,a);a.eb=this.eb.I();a.gp=this.gp.copy()};
Xg.prototype.wh=function(a){if(null===this.background&&null===this.areaBackground)return!1;var b=this.naturalBounds;return Cc(0,0,b.width,b.height,a.x,a.y)};
Xg.prototype.hm=function(){var a=this.part;null!==a&&(a instanceof ig||a instanceof pf)||A("Placeholder is not inside a Group or Adornment.");if(a instanceof ig){var b=this.computeBorder(this.gp),c=this.minSize,d=this.qc;sc(d,(isFinite(c.width)?Math.max(c.width,b.width):b.width)||0,(isFinite(c.height)?Math.max(c.height,b.height):b.height)||0);Xk(this,0,0,d.width,d.height);c=a.memberParts;for(d=!1;c.next();)if(c.value.isVisible()){d=!0;break}c=a.diagram;(d=!d||null===c)||(d=c.animationManager,d.Lf?
(d=d.zj.J(a),d=null!==d&&(d.start.position||d.start.location)):d=!1);d||isNaN(b.x)||isNaN(b.y)?null!==c&&c.animationManager.isAnimating&&c.animationManager.bs.add(this):(c=H.alloc(),c.Vi(b,a.locationSpot),c.A(a.location)||(a.location=new H(c.x,c.y)),H.free(c))}else{b=this.qc;c=this.eb;d=c.left+c.right;var e=c.top+c.bottom,f=a.adornedObject;a.angle=f.Li();var g=0;f instanceof W&&(g=f.strokeWidth);var h=f.Ce(),k=f.naturalBounds,l=(k.width+g)*h;g=(k.height+g)*h;a.type!==X.Link&&(f=f.ma("Selection"===
a.category?Tc:a.locationSpot,H.alloc()),a.location=f,H.free(f));isNaN(l)||isNaN(g)?(a=a.adornedObject,l=a.ma(Tc,H.alloc()),f=N.allocAt(l.x,l.y,0,0),f.Ie(a.ma(hd,l)),f.Ie(a.ma(Vc,l)),f.Ie(a.ma(fd,l)),sc(b,f.width+d||0,f.height+e||0),Xk(this,-c.left,-c.top,b.width,b.height),H.free(l),N.free(f)):(sc(b,l+d||0,g+e||0),Xk(this,-c.left,-c.top,b.width,b.height))}};Xg.prototype.uh=function(a,b,c,d){this.actualBounds.h(a,b,c,d)};
Xg.prototype.computeBorder=function(a){var b=this.part,c=b.diagram;if(null!==c&&b instanceof ig&&!b.layer.isTemporary&&b.computesBoundsAfterDrag&&this.gp.o()){var d=c.toolManager.findTool("Dragging");if(d===c.currentTool&&(c=d.computeBorder(b,this.gp,a),null!==c))return c}c=N.alloc();d=this.computeMemberBounds(c);var e=this.eb;b instanceof ig&&!b.isSubGraphExpanded?a.h(d.x-e.left,d.y-e.top,0,0):a.h(d.x-e.left,d.y-e.top,Math.max(d.width+e.left+e.right,0),Math.max(d.height+e.top+e.bottom,0));N.free(c);
b instanceof ig&&b.computesBoundsIncludingLocation&&b.location.o()&&a.Ie(b.location);return a};
Xg.prototype.computeMemberBounds=function(a){if(!(this.part instanceof ig))return a.h(0,0,0,0),a;for(var b=this.part,c=Infinity,d=Infinity,e=-Infinity,f=-Infinity,g=b.memberParts;g.next();){var h=g.value;if(h.isVisible()){if(h instanceof S){if(!b.computesBoundsIncludingLinks)continue;if(oj(h))continue;if(h.fromNode===b||h.toNode===b)continue}h=h.actualBounds;h.left<c&&(c=h.left);h.top<d&&(d=h.top);h.right>e&&(e=h.right);h.bottom>f&&(f=h.bottom)}}isFinite(c)&&isFinite(d)?a.h(c,d,e-c,f-d):(b=b.location,
a.h(b.x,b.y,0,0));return a};pa.Object.defineProperties(Xg.prototype,{padding:{get:function(){return this.eb},set:function(a){"number"===typeof a&&(a=new Ic(a));var b=this.eb;b.A(a)||(this.eb=a=a.I(),this.g("padding",b,a))}}});Xg.className="Placeholder";
function S(){T.call(this,X.Link);this.Ta=8;this.Oe=null;this.Pe="";this.ff=this.En=null;this.gf="";this.Dp=null;this.Lm=Og;this.fn=0;this.jn=Og;this.kn=NaN;this.Gj=Ko;this.rp=.5;this.$c=null;this.xb=(new E).freeze();this.bh=this.Zg=null;this.xl=new N;this.qa=new $d;this.Pn=!0;this.K=this.u=this.yf=this.Hf=null;this.l=[];this.yu=new H;this.qr=this.Jw=this.Iw=null;this.$t=NaN;this.P=null}oa(S,T);
S.prototype.cloneProtected=function(a){T.prototype.cloneProtected.call(this,a);a.Ta=this.Ta&-113;a.Pe=this.Pe;a.En=this.En;a.gf=this.gf;a.Dp=this.Dp;a.Lm=this.Lm;a.fn=this.fn;a.jn=this.jn;a.kn=this.kn;a.Gj=this.Gj;a.rp=this.rp;null!==this.P&&(a.P=this.P.copy())};t=S.prototype;t.nf=function(a){T.prototype.nf.call(this,a);this.Pe=a.Pe;this.gf=a.gf;a.Zg=null;a.Ra();a.yf=this.yf;var b=a.fromPort;null!==b&&xo(a.fromNode,b);b=a.toPort;null!==b&&xo(a.toNode,b)};
t.fb=function(a){a.classType===S?2===(a.value&2)?this.routing=a:a===Rg||a===Ng||a===Mg?this.curve=a:a===Lo||a===Mo||a===No?this.adjusting=a:a!==Ko&&a!==Og&&A("Unknown Link enum value for a Link property: "+a):T.prototype.fb.call(this,a)};t.Hc=function(){null===this.P&&(this.P=new Tk)};t.Ri=function(){var a=this.fromNode;if(null!==a){var b=a.findVisibleNode();null!==b&&(a=b);if(pj(a)||qj(a))return!1}a=this.toNode;return null!==a&&(b=a.findVisibleNode(),null!==b&&(a=b),pj(a)||qj(a))?!1:!0};t.Kv=function(){return!1};
t.Lv=function(){};t.bc=function(){return!1};S.prototype.computeAngle=function(a,b,c){return S.computeAngle(b,c)};S.computeAngle=function(a,b){switch(a){default:case Og:a=0;break;case rn:a=b;break;case Cm:a=b+90;break;case Em:a=b-90;break;case Oo:a=b+180;break;case Po:a=I.jq(b);90<a&&270>a&&(a-=180);break;case Dm:a=I.jq(b+90);90<a&&270>a&&(a-=180);break;case Fm:a=I.jq(b-90);90<a&&270>a&&(a-=180);break;case Gm:a=I.jq(b);if(45<a&&135>a||225<a&&315>a)return 0;90<a&&270>a&&(a-=180)}return I.jq(a)};
function to(a){var b=a.fromNode,c=a.toNode,d=null;null!==b?d=null!==c?b.bx(c):b.containingGroup:null!==c?d=c.containingGroup:d=null;b=d;c=a.Sh;if(c!==b){null!==c&&oo(c,a);a.Sh=b;null!==b&&no(b,a);var e=a.containingGroupChanged;if(null!==e){var f=!0,g=a.diagram;null!==g&&(f=g.ea,g.ea=!0);e(a,c,b);null!==g&&(g.ea=f)}!a.Lc||a.Iw!==c&&a.Jw!==c||a.Ra()}if(a.isLabeledLink)for(a=a.labelNodes;a.next();)a.value.containingGroup=d}t=S.prototype;
t.zh=function(){var a=this.containingGroup;null!==a&&this.fromNode!==a&&this.toNode!==a&&a.computesBoundsIncludingLinks&&T.prototype.zh.call(this)};t.Ws=function(a){var b=this.fromNode;return a===b?this.toNode:b};t.az=function(a){var b=this.fromPort;return a===b?this.toPort:b};function Ao(a,b){null===a.$c&&(a.$c=new F);a.$c.add(b);a.s()}
t.aq=function(a){T.prototype.aq.call(this,a);Qo(this)&&this.cq(this.actualBounds);if(!a){a=this.Oe;var b=null;null!==a&&(b=this.fromPort,yo(a,this,b));var c=this.ff;if(null!==c){var d=this.toPort;c===a&&d===b||yo(c,this,d)}Ro(this)}};t.bq=function(a){T.prototype.bq.call(this,a);Qo(this)&&this.cq(this.actualBounds);if(!a){a=this.Oe;var b=null;null!==a&&(b=this.fromPort,zo(a,this,b));var c=this.ff;if(null!==c){var d=this.toPort;c===a&&d===b||zo(c,this,d)}So(this)}};
t.$j=function(){this.Lc=!0;if(null!==this.$c){var a=this.diagram;if(null!==a)for(var b=this.$c.copy().iterator;b.next();)a.remove(b.value)}null!==this.data&&(a=this.diagram,null!==a&&a.partManager.removeDataForLink(this))};S.prototype.updateRelationshipsFromData=function(){if(null!==this.data){var a=this.diagram;null!==a&&a.partManager.updateRelationshipsFromData(this)}};
S.prototype.move=function(a,b){var c=b?this.location:this.position,d=c.x;isNaN(d)&&(d=0);var e=c.y;isNaN(e)&&(e=0);d=a.x-d;e=a.y-e;!0===b?T.prototype.move.call(this,a,!1):(a=H.allocAt(c.x+d,c.y+e),T.prototype.move.call(this,a,!1),H.free(a));$f(this,d,e);for(a=this.labelNodes;a.next();)b=a.value,c=b.position,b.moveTo(c.x+d,c.y+e)};
S.prototype.canRelinkFrom=function(){if(!this.relinkableFrom)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowRelink)return!1;a=a.diagram;return null===a||a.allowRelink?!0:!1};S.prototype.canRelinkTo=function(){if(!this.relinkableTo)return!1;var a=this.layer;if(null===a)return!0;if(!a.allowRelink)return!1;a=a.diagram;return null===a||a.allowRelink?!0:!1};
S.prototype.computeMidPoint=function(a){var b=this.pointsCount;if(0===b)return a.assign(ec),a;if(1===b)return a.assign(this.i(0)),a;if(2===b){var c=this.i(0),d=this.i(1);a.h((c.x+d.x)/2,(c.y+d.y)/2);return a}if(this.isOrthogonal&&(15<=this.computeCorner()||this.computeCurve()===Rg))return this.qa.cv(.5,a),a.add(this.i(0)),c=this.qa.figures.first(),a.offset(-c.startX,-c.startY),a;if(this.computeCurve()===Rg){if(3===b)return this.i(1);d=(b-1)/3|0;c=3*(d/2|0);if(1===d%2){d=this.i(c);var e=this.i(c+1),
f=this.i(c+2);c=this.i(c+3);I.my(d.x,d.y,e.x,e.y,f.x,f.y,c.x,c.y,a)}else a.assign(this.i(c));return a}var g=this.flattenedLengths;c=this.flattenedTotalLength;for(e=f=d=0;d<c/2&&f<b;){e=g[f];if(d+e>c/2)break;d+=e;f++}b=this.i(f);f=this.i(f+1);1>Math.abs(b.x-f.x)?b.y>f.y?a.h(b.x,b.y-(c/2-d)):a.h(b.x,b.y+(c/2-d)):1>Math.abs(b.y-f.y)?b.x>f.x?a.h(b.x-(c/2-d),b.y):a.h(b.x+(c/2-d),b.y):(c=(c/2-d)/e,a.h(b.x+c*(f.x-b.x),b.y+c*(f.y-b.y)));return a};
S.prototype.computeMidAngle=function(){var a=this.pointsCount;if(2>a)return NaN;if(2===a)return this.i(0).Wa(this.i(1));if(this.isOrthogonal&&(15<=this.computeCorner()||this.computeCurve()===Rg)){a:{a=this.qa;var b=.5;0>b?b=0:1<b&&(b=1);if(a.type===ce)a=180*Math.atan2(a.endY-a.startY,a.endX-a.startX)/Math.PI;else{var c=a.flattenedSegments,d=a.flattenedLengths,e=c.length;b=a.flattenedTotalLength*b;for(var f=0,g=0;g<e;g++){var h=d[g],k=h.length;for(a=0;a<k;a++){var l=h[a];if(f+l>=b){b=c[g];c=b[2*a];
d=b[2*a+1];e=b[2*a+2];a=b[2*a+3];a=1>Math.abs(e-c)&&1>Math.abs(a-d)?0:1>Math.abs(e-c)?0<=a-d?90:270:1>Math.abs(a-d)?0<=e-c?0:180:180*Math.atan2(a-d,e-c)/Math.PI;break a}f+=l}}a=NaN}}return a}if(this.computeCurve()===Rg&&4<=a){d=(a-1)/3|0;c=3*(d/2|0);if(1===d%2)return c=Math.floor(c),a=this.i(c),d=this.i(c+1),e=this.i(c+2),c=this.i(c+3),I.ly(a.x,a.y,d.x,d.y,e.x,e.y,c.x,c.y);if(0<c&&c+1<a)return this.i(c-1).Wa(this.i(c+1))}d=this.flattenedLengths;e=this.flattenedTotalLength;for(c=b=0;b<e/2&&c<a;){f=
d[c];if(b+f>e/2)break;b+=f;c++}d=this.i(c);e=this.i(c+1);if(1>Math.abs(d.x-e.x)&&1>Math.abs(d.y-e.y)){if(0<c&&c+2<a)return this.i(c-1).Wa(this.i(c+2))}else{if(1>Math.abs(d.x-e.x))return d.y>e.y?270:90;if(1>Math.abs(d.y-e.y))return d.x>e.x?180:0}return d.Wa(e)};t=S.prototype;t.i=function(a){return this.xb.j[a]};t.jd=function(a,b){this.xb.gd(a,b)};t.M=function(a,b,c){this.xb.gd(a,new H(b,c))};t.kz=function(a,b){this.xb.Ib(a,b)};t.m=function(a,b,c){this.xb.Ib(a,new H(b,c))};t.ye=function(a){this.xb.add(a)};
t.kf=function(a,b){this.xb.add(new H(a,b))};t.zv=function(a){this.xb.mb(a)};t.Vj=function(){this.xb.clear()};function $f(a,b,c){if(0!==b||0!==c){for(var d=a.Lc,e=new E,f=a.xb.iterator;f.next();){var g=f.value;e.add((new H(g.x+b,g.y+c)).freeze())}e.freeze();f=a.xb;a.xb=e;isNaN(b)||isNaN(c)||a.diagram.animationManager.rb?a.s():(a.le.h(a.le.x+b,a.le.y+c),a.sa.h(a.sa.x+b,a.sa.y+c),rl(a));d&&To(a);b=a.diagram;null!==b&&b.animationManager.rb&&(a.bh=e);a.g("points",f,e)}}
t.Ch=function(){null===this.Zg&&(this.Zg=this.xb,this.xb=this.xb.copy())};
t.lf=function(){if(null!==this.Zg){for(var a=this.Zg,b=this.xb,c=Infinity,d=Infinity,e=a.j,f=e.length,g=0;g<f;g++){var h=e[g];c=Math.min(h.x,c);d=Math.min(h.y,d)}h=g=Infinity;for(var k=b.j,l=k.length,m=0;m<l;m++){var n=k[m];g=Math.min(n.x,g);h=Math.min(n.y,h);n.freeze()}b.freeze();if(l===f)for(f=0;f<l;f++){if(m=e[f],n=k[f],m.x-c!==n.x-g||m.y-d!==n.y-h){this.s();this.ac();break}}else this.s(),this.ac();this.Zg=null;c=this.diagram;null!==c&&c.animationManager.rb&&(this.bh=b);To(this);this.g("points",
a,b)}};t.yx=function(){null!==this.Zg&&(this.xb=this.Zg,this.Zg=null)};function To(a){0===a.xb.count?a.Lc=!1:(a.Lc=!0,a.qr=null,a.$t=NaN,a.defaultFromPoint=a.i(0),a.defaultToPoint=a.i(a.pointsCount-1),Uo(a,!1))}
t.Ra=function(){if(!this.suspendsRouting){var a=this.diagram;if(a){if(a.ft.contains(this)||a.undoManager.isUndoingRedoing)return;a=a.animationManager;if(a.isTicking&&!a.isAnimating)return;null!==this.bh&&!a.isTicking&&a.isAnimating&&(this.bh=null)}a=this.path;null!==a&&(this.Lc=!1,this.s(),a.s())}};t.$i=function(){if(!this.Lc&&!this.Ou){var a=!0;try{this.Ou=!0,this.Ch(),a=this.computePoints()}finally{this.Ou=!1,a?this.lf():this.yx()}}};
S.prototype.computePoints=function(){var a=this.diagram;if(null===a)return!1;var b=this.fromNode,c=null;null===b?(a.ri||(a.ap=new W,a.ap.desiredSize=fc,a.ap.strokeWidth=0,a.ri=new V,a.ri.add(a.ap),a.ri.$b()),this.defaultFromPoint&&(a.ri.position=a.ri.location=this.defaultFromPoint,a.ri.$b(),b=a.ri,c=a.ap)):c=this.fromPort;if(null!==c&&!b.isVisible()){var d=b.findVisibleNode();null!==d&&d!==b?(b=d,c=d.port):b=d}this.Iw=b;if(null===b||!b.location.o())return!1;for(;!(null===c||c.actualBounds.o()&&c.sf());)c=
c.panel;if(null===c)return!1;var e=this.toNode,f=null;null===e?(a.si||(a.bp=new W,a.bp.desiredSize=fc,a.bp.strokeWidth=0,a.si=new V,a.si.add(a.bp),a.si.$b()),this.defaultToPoint&&(a.si.position=a.si.location=this.defaultToPoint,a.si.$b(),e=a.si,f=a.bp)):f=this.toPort;null===f||e.isVisible()||(a=e.findVisibleNode(),null!==a&&a!==e?(e=a,f=a.port):e=a);this.Jw=e;if(null===e||!e.location.o())return!1;for(;!(null===f||f.actualBounds.o()&&f.sf());)f=f.panel;if(null===f)return!1;var g=this.pointsCount;d=
this.computeSpot(!0,c);a=this.computeSpot(!1,f);var h=Vo(d),k=Vo(a),l=c===f&&null!==c,m=this.isOrthogonal,n=this.curve===Rg;this.Hf=l&&!m?n=!0:!1;var p=this.adjusting===Og||l;if(!m&&!l&&h&&k){if(h=!1,!p&&3<=g&&(p=this.getLinkPoint(b,c,d,!0,!1,e,f),k=this.getLinkPoint(e,f,a,!1,!1,b,c),h=this.adjustPoints(0,p,g-1,k))&&(p=this.getLinkPoint(b,c,d,!0,!1,e,f),k=this.getLinkPoint(e,f,a,!1,!1,b,c),this.adjustPoints(0,p,g-1,k)),!h)if(this.Vj(),n){g=this.getLinkPoint(b,c,d,!0,!1,e,f);p=this.getLinkPoint(e,
f,a,!1,!1,b,c);h=p.x-g.x;k=p.y-g.y;l=this.computeCurviness();n=m=0;var q=g.x+h/3,r=g.y+k/3,u=q,x=r;I.w(k,0)?x=0<h?x-l:x+l:(m=-h/k,n=Math.sqrt(l*l/(m*m+1)),0>l&&(n=-n),u=(0>k?-1:1)*n+q,x=m*(u-q)+r);q=g.x+2*h/3;r=g.y+2*k/3;var v=q,y=r;I.w(k,0)?y=0<h?y-l:y+l:(v=(0>k?-1:1)*n+q,y=m*(v-q)+r);this.Vj();this.ye(g);this.kf(u,x);this.kf(v,y);this.ye(p);this.jd(0,this.getLinkPoint(b,c,d,!0,!1,e,f));this.jd(3,this.getLinkPoint(e,f,a,!1,!1,b,c))}else d=this.getLinkPoint(b,c,d,!0,!1,e,f),a=this.getLinkPoint(e,
f,a,!1,!1,b,c),this.hasCurviness()?(p=a.x-d.x,e=a.y-d.y,f=this.computeCurviness(),b=d.x+p/2,c=d.y+e/2,g=b,h=c,I.w(e,0)?h=0<p?h-f:h+f:(p=-p/e,g=Math.sqrt(f*f/(p*p+1)),0>f&&(g=-g),g=(0>e?-1:1)*g+b,h=p*(g-b)+c),this.ye(d),this.kf(g,h)):this.ye(d),this.ye(a)}else{n=this.isAvoiding;p&&(m&&n||l)&&this.Vj();var z=l?this.computeCurviness():0;n=this.getLinkPoint(b,c,d,!0,m,e,f);q=u=r=0;if(m||!h||l)x=this.computeEndSegmentLength(b,c,d,!0),q=this.getLinkDirection(b,c,n,d,!0,m,e,f),l&&(h||d.A(a)||!m&&1===d.x+
a.x&&1===d.y+a.y)&&(q-=m?90:30,0>z&&(q-=180)),0>q?q+=360:360<=q&&(q-=360),l&&(x+=Math.abs(z)*(m?1:2)),0===q?r=x:90===q?u=x:180===q?r=-x:270===q?u=-x:(r=x*Math.cos(q*Math.PI/180),u=x*Math.sin(q*Math.PI/180)),d.jc()&&l&&(x=c.ma(dd,H.alloc()),v=H.allocAt(x.x+1E3*r,x.y+1E3*u),this.getLinkPointFromPoint(b,c,x,v,!0,n),H.free(x),H.free(v));x=this.getLinkPoint(e,f,a,!1,m,b,c);var B=y=v=0;if(m||!k||l){var C=this.computeEndSegmentLength(e,f,a,!1);B=this.getLinkDirection(e,f,x,a,!1,m,b,c);l&&(k||d.A(a)||!m&&
1===d.x+a.x&&1===d.y+a.y)&&(B+=m?0:30,0>z&&(B+=180));0>B?B+=360:360<=B&&(B-=360);l&&(C+=Math.abs(z)*(m?1:2));0===B?v=C:90===B?y=C:180===B?v=-C:270===B?y=-C:(v=C*Math.cos(B*Math.PI/180),y=C*Math.sin(B*Math.PI/180));a.jc()&&l&&(a=f.ma(dd,H.alloc()),d=H.allocAt(a.x+1E3*v,a.y+1E3*y),this.getLinkPointFromPoint(e,f,a,d,!1,x),H.free(a),H.free(d))}a=n;if(m||!h||l)a=new H(n.x+r,n.y+u);d=x;if(m||!k||l)d=new H(x.x+v,x.y+y);!p&&!m&&h&&3<g&&this.adjustPoints(0,n,g-2,d)?this.jd(g-1,x):!p&&!m&&k&&3<g&&this.adjustPoints(1,
a,g-1,x)?this.jd(0,n):!p&&(m?6<=g:4<g)&&this.adjustPoints(1,a,g-2,d)?(this.jd(0,n),this.jd(g-1,x)):(this.Vj(),this.ye(n),(m||!h||l)&&this.ye(a),m&&this.addOrthoPoints(a,q,d,B,b,e),(m||!k||l)&&this.ye(d),this.ye(x))}return!0};function Wo(a,b){Math.abs(b.x-a.x)>Math.abs(b.y-a.y)?(b.x>=a.x?b.x=a.x+9E9:b.x=a.x-9E9,b.y=a.y):(b.y>=a.y?b.y=a.y+9E9:b.y=a.y-9E9,b.x=a.x);return b}
S.prototype.getLinkPointFromPoint=function(a,b,c,d,e,f){void 0===f&&(f=new H);if(null===a||null===b)return f.assign(c),f;a.isVisible()||(e=a.findVisibleNode(),null!==e&&e!==a&&(b=e.port));a=null;e=b.panel;null===e||e.Zd()||(e=e.panel);if(null===e){e=d.x;d=d.y;var g=c.x;c=c.y}else{a=e.rd;e=1/(a.m11*a.m22-a.m12*a.m21);g=a.m22*e;var h=-a.m12*e,k=-a.m21*e,l=a.m11*e,m=e*(a.m21*a.dy-a.m22*a.dx),n=e*(a.m12*a.dx-a.m11*a.dy);e=d.x*g+d.y*k+m;d=d.x*h+d.y*l+n;g=c.x*g+c.y*k+m;c=c.x*h+c.y*l+n}b.dk(e,d,g,c,f);null!==
a&&f.transform(a);return f};function Xo(a,b){var c=b.Ro;null===c&&(c=new Yo,c.port=b,c.node=b.part,b.Ro=c);return Zo(c,a)}
S.prototype.getLinkPoint=function(a,b,c,d,e,f,g,h){void 0===h&&(h=new H);if(c.gb()&&!Vo(c))return b.ma(c,h),h;if(c.rf()){var k=Xo(this,b);if(null!==k){h.assign(k.gq);if(e&&this.routing===$o){var l=Xo(this,g);if(null!==l&&k.Wl<l.Wl){k=H.alloc();l=H.alloc();var m=new N(b.ma(Tc,k),b.ma(hd,l)),n=this.computeSpot(!d,g);a=this.getLinkPoint(f,g,n,!d,e,a,b,l);(c.qf(jd)||c.qf(pd))&&a.y>=m.y&&a.y<=m.y+m.height?h.y=a.y:(c.qf(id)||c.qf(qd))&&a.x>=m.x&&a.x<=m.x+m.width&&(h.x=a.x);H.free(k);H.free(l)}}return h}}c=
b.ma(.5===c.x&&.5===c.y?c:dd,H.alloc());this.pointsCount>(e?6:2)?(g=d?this.i(1):this.i(this.pointsCount-2),e&&(g=Wo(c,g.copy()))):(k=this.computeSpot(!d,g),f=H.alloc(),g=g.ma(.5===k.x&&.5===k.y?k:dd,f),e&&(g=Wo(c,g)),H.free(f));this.getLinkPointFromPoint(a,b,c,g,d,h);H.free(c);return h};
S.prototype.getLinkDirection=function(a,b,c,d,e,f,g,h){a:if(d.gb())var k=d.x>d.y?d.x>1-d.y?0:d.x<1-d.y?270:315:d.x<d.y?d.x>1-d.y?90:d.x<1-d.y?180:135:.5>d.x?225:.5<d.x?45:0;else{if(d.rf()&&(k=Xo(this,b),null!==k))switch(k.yc){case 1:k=270;break a;case 2:k=180;break a;default:case 4:k=0;break a;case 8:k=90;break a}k=b.ma(dd,H.alloc());this.pointsCount>(f?6:2)?(h=e?this.i(1):this.i(this.pointsCount-2),h=f?Wo(k,h.copy()):c):(c=H.alloc(),h=h.ma(dd,c),H.free(c));c=Math.abs(h.x-k.x)>Math.abs(h.y-k.y)?h.x>=
k.x?0:180:h.y>=k.y?90:270;H.free(k);k=c}d.jc()&&g.Vd(a)&&(k+=180,360<=k&&(k-=360));if(Vo(d))return k;a=b.Li();if(0===a)return k;45<=a&&135>a?k+=90:135<=a&&225>a?k+=180:225<=a&&315>a&&(k+=270);360<=k&&(k-=360);return k};S.prototype.computeEndSegmentLength=function(a,b,c,d){if(null!==b&&c.rf()&&(a=Xo(this,b),null!==a))return a.Tu;a=d?this.fromEndSegmentLength:this.toEndSegmentLength;null!==b&&isNaN(a)&&(a=d?b.fromEndSegmentLength:b.toEndSegmentLength);isNaN(a)&&(a=10);return a};
S.prototype.computeSpot=function(a,b){void 0===b&&(b=null);a?(a=b?b:this.fromPort,null===a?a=dd:(b=this.fromSpot,b.Kb()&&(b=a.fromSpot),a=b===Ed?Sc:b)):(a=b?b:this.toPort,null===a?a=dd:(b=this.toSpot,b.Kb()&&(b=a.toSpot),a=b===Ed?Sc:b));return a};function Vo(a){return a===Sc||.5===a.x&&.5===a.y}S.prototype.computeOtherPoint=function(a,b){a=b.ma(dd);b=b.Ro;b=null!==b?Zo(b,this):null;null!==b&&(a=b.gq);return a};
S.prototype.computeShortLength=function(a){if(a){a=this.fromShortLength;if(isNaN(a)){var b=this.fromPort;null!==b&&(a=b.fromShortLength)}return isNaN(a)?0:a}a=this.toShortLength;isNaN(a)&&(b=this.toPort,null!==b&&(a=b.toShortLength));return isNaN(a)?0:a};
S.prototype.ng=function(a,b,c,d,e,f){if(!1===this.pickable)return!1;void 0===b&&(b=null);void 0===c&&(c=null);var g=f;void 0===f&&(g=Qc.alloc(),g.reset());g.multiply(this.transform);if(this.vh(a,g))return Vm(this,b,c,e),void 0===f&&Qc.free(g),!0;if(this.Ic(a,g)){var h=!1;if(!this.isAtomic)for(var k=this.Y.j,l=k.length;l--;){var m=k[l];if(m.visible||m===this.locationObject){var n=m.actualBounds,p=this.naturalBounds;if(!(n.x>p.width||n.y>p.height||0>n.x+n.width||0>n.y+n.height)){n=Qc.alloc();n.set(g);
if(m instanceof X)h=m.ng(a,b,c,d,e,n);else if(this.path===m){if(m instanceof W)if(h=a,p=d,!1===m.pickable)h=!1;else if(n.multiply(m.transform),p)b:{var q=h,r=n;if(m.vh(q,r))h=!0;else{if(void 0===r&&(r=m.transform,q.mf(m.actualBounds))){h=!0;break b}h=q.left;p=q.right;var u=q.top;q=q.bottom;var x=H.alloc(),v=H.alloc(),y=H.alloc(),z=Qc.alloc();z.set(r);z.nv(m.transform);z.at();v.x=p;v.y=u;v.transform(z);x.x=h;x.y=u;x.transform(z);r=!1;pn(m,x,v,y)?r=!0:(x.x=p,x.y=q,x.transform(z),pn(m,x,v,y)?r=!0:(v.x=
h,v.y=q,v.transform(z),pn(m,x,v,y)?r=!0:(x.x=h,x.y=u,x.transform(z),pn(m,x,v,y)&&(r=!0))));Qc.free(z);H.free(x);H.free(v);H.free(y);h=r}}else h=m.vh(h,n)}else h=Zk(m,a,d,n);h&&(p=m,null!==b&&(p=b(m)),p&&(null===c||c(p))&&e.add(p));Qc.free(n)}}}void 0===f&&Qc.free(g);return h||null!==this.background||null!==this.areaBackground}void 0===f&&Qc.free(g);return!1};
S.prototype.computeCurve=function(){if(null===this.Hf){var a=this.fromPort,b=this.isOrthogonal;this.Hf=null!==a&&a===this.toPort&&!b}return this.Hf?Rg:this.curve};S.prototype.computeCorner=function(){if(this.curve===Rg)return 0;var a=this.corner;if(isNaN(a)||0>a)a=10;return a};
S.prototype.findMidLabel=function(){for(var a=this.path,b=this.Y.j,c=b.length,d=0;d<c;d++){var e=b[d];if(e!==a&&!e.isPanelMain&&(-Infinity===e.segmentIndex||isNaN(e.segmentIndex)))return e}for(a=this.labelNodes;a.next();)if(b=a.value,-Infinity===b.segmentIndex||isNaN(b.segmentIndex))return b;return null};
S.prototype.computeSpacing=function(){if(!this.isVisible())return 0;var a=Math.max(14,this.computeThickness());var b=this.fromPort,c=this.toPort;if(null!==b&&null!==c){var d=this.findMidLabel();if(null!==d){var e=d.naturalBounds,f=d.margin,g=isNaN(e.width)?30:e.width*d.scale+f.left+f.right;e=isNaN(e.height)?14:e.height*d.scale+f.top+f.bottom;d=d.segmentOrientation;d===rn||d===Po||d===Oo?a=Math.max(a,e):d===Em||d===Fm||d===Cm||d===Dm?a=Math.max(a,g):(b=b.ma(dd).Wa(c.ma(dd))/180*Math.PI,a=Math.max(a,
Math.abs(Math.sin(b)*g)+Math.abs(Math.cos(b)*e)+1));this.curve===Rg&&(a*=1.333)}}return a};S.prototype.arrangeBundledLinks=function(a,b){if(b)for(b=0;b<a.length;b++){var c=a[b];c.adjusting===Og&&c.Ra()}};
S.prototype.computeCurviness=function(){var a=this.curviness;if(isNaN(a)){a=16;var b=this.yf;if(null!==b){for(var c=Ka(),d=0,e=b.links,f=0;f<e.length;f++){var g=e[f].computeSpacing();c.push(g);d+=g}d=-d/2;for(f=0;f<e.length;f++){if(e[f]===this){a=d+c[f]/2;break}d+=c[f]}b.it===this.fromNode&&(a=-a);Oa(c)}}return a};S.prototype.computeThickness=function(){if(!this.isVisible())return 0;var a=this.path;return null!==a?Math.max(a.strokeWidth,1):1};
S.prototype.hasCurviness=function(){return!isNaN(this.curviness)||null!==this.yf};
S.prototype.adjustPoints=function(a,b,c,d){var e=this.adjusting;if(this.isOrthogonal){if(e===Mo)return!1;e===No&&(e=Lo)}switch(e){case Mo:var f=this.i(a),g=this.i(c);if(!f.Qa(b)||!g.Qa(d)){e=f.x;f=f.y;var h=g.x-e,k=g.y-f,l=Math.sqrt(h*h+k*k);if(!I.ca(l,0)){if(I.ca(h,0))var m=0>k?-Math.PI/2:Math.PI/2;else m=Math.atan(k/Math.abs(h)),0>h&&(m=Math.PI-m);g=b.x;var n=b.y;h=d.x-g;var p=d.y-n;k=Math.sqrt(h*h+p*p);I.ca(h,0)?p=0>p?-Math.PI/2:Math.PI/2:(p=Math.atan(p/Math.abs(h)),0>h&&(p=Math.PI-p));l=k/l;m=
p-m;this.jd(a,b);for(a+=1;a<c;a++)b=this.i(a),h=b.x-e,k=b.y-f,b=Math.sqrt(h*h+k*k),I.ca(b,0)||(I.ca(h,0)?k=0>k?-Math.PI/2:Math.PI/2:(k=Math.atan(k/Math.abs(h)),0>h&&(k=Math.PI-k)),h=k+m,b*=l,this.M(a,g+b*Math.cos(h),n+b*Math.sin(h)));this.jd(c,d)}}return!0;case No:f=this.i(a);n=this.i(c);if(!f.Qa(b)||!n.Qa(d)){e=f.x;f=f.y;g=n.x;n=n.y;l=(g-e)*(g-e)+(n-f)*(n-f);h=b.x;m=b.y;k=d.x;p=d.y;var q=1;if(0!==k-h){var r=(p-m)/(k-h);q=Math.sqrt(1+1/(r*r))}else r=9E9;this.jd(a,b);for(a+=1;a<c;a++){b=this.i(a);
var u=b.x,x=b.y,v=.5;0!==l&&(v=((e-u)*(e-g)+(f-x)*(f-n))/l);var y=e+v*(g-e),z=f+v*(n-f);b=Math.sqrt((u-y)*(u-y)+(x-z)*(x-z));x<r*(u-y)+z&&(b=-b);0<r&&(b=-b);u=h+v*(k-h);v=m+v*(p-m);0!==r?(b=u+b/q,this.M(a,b,v-(b-u)/r)):this.M(a,u,v+b)}this.jd(c,d)}return!0;case Lo:a:{if(this.isOrthogonal&&(e=this.i(a),f=this.i(a+1),g=this.i(a+2),h=f.x,m=f.y,n=h,l=m,I.w(e.y,f.y)?I.w(f.x,g.x)?m=b.y:I.w(f.y,g.y)&&(h=b.x):I.w(e.x,f.x)&&(I.w(f.y,g.y)?h=b.x:I.w(f.x,g.x)&&(m=b.y)),this.M(a+1,h,m),e=this.i(c),f=this.i(c-
1),g=this.i(c-2),h=f.x,m=f.y,k=h,p=m,I.w(e.y,f.y)?I.w(f.x,g.x)?m=d.y:I.w(f.y,g.y)&&(h=d.x):I.w(e.x,f.x)&&(I.w(f.y,g.y)?h=d.x:I.w(f.x,g.x)&&(m=d.y)),this.M(c-1,h,m),Mj(this))){this.M(a+1,n,l);this.M(c-1,k,p);c=!1;break a}this.jd(a,b);this.jd(c,d);c=!0}return c;default:return!1}};
S.prototype.addOrthoPoints=function(a,b,c,d,e,f){b=-45<=b&&45>b?0:45<=b&&135>b?90:135<=b&&225>b?180:270;d=-45<=d&&45>d?0:45<=d&&135>d?90:135<=d&&225>d?180:270;var g=e.actualBounds.copy(),h=f.actualBounds.copy();if(g.o()&&h.o()){g.Uc(8,8);h.Uc(8,8);g.Ie(a);h.Ie(c);if(0===b)if(c.x>a.x||270===d&&c.y<a.y&&h.right>a.x||90===d&&c.y>a.y&&h.right>a.x){var k=new H(c.x,a.y);var l=new H(c.x,(a.y+c.y)/2);180===d?(k.x=this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!1),l.x=k.x,l.y=c.y):270===d&&c.y<a.y||90===d&&
c.y>a.y?(k.x=a.x<h.left?this.computeMidOrthoPosition(a.x,a.y,h.left,c.y,!1):a.x<h.right&&(270===d&&a.y<h.top||90===d&&a.y>h.bottom)?this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!1):h.right,l.x=k.x,l.y=c.y):0===d&&a.x<h.left&&a.y>h.top&&a.y<h.bottom&&(k.x=a.x,k.y=a.y<c.y?Math.min(c.y,h.top):Math.max(c.y,h.bottom),l.y=k.y)}else{k=new H(a.x,c.y);l=new H((a.x+c.x)/2,c.y);if(180===d||90===d&&c.y<g.top||270===d&&c.y>g.bottom)180===d&&(h.da(a)||g.da(c))?k.y=this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!0):
c.y<a.y&&(180===d||90===d)?k.y=this.computeMidOrthoPosition(a.x,g.top,c.x,Math.max(c.y,h.bottom),!0):c.y>a.y&&(180===d||270===d)&&(k.y=this.computeMidOrthoPosition(a.x,g.bottom,c.x,Math.min(c.y,h.top),!0)),l.x=c.x,l.y=k.y;if(k.y>g.top&&k.y<g.bottom)if(c.x>=g.left&&c.x<=a.x||a.x<=h.right&&a.x>=c.x){if(90===d||270===d)k=new H(Math.max((a.x+c.x)/2,a.x),a.y),l=new H(k.x,c.y)}else k.y=270===d||(0===d||180===d)&&c.y<a.y?Math.min(c.y,0===d?g.top:Math.min(g.top,h.top)):Math.max(c.y,0===d?g.bottom:Math.max(g.bottom,
h.bottom)),l.x=c.x,l.y=k.y}else if(180===b)if(c.x<a.x||270===d&&c.y<a.y&&h.left<a.x||90===d&&c.y>a.y&&h.left<a.x)k=new H(c.x,a.y),l=new H(c.x,(a.y+c.y)/2),0===d?(k.x=this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!1),l.x=k.x,l.y=c.y):270===d&&c.y<a.y||90===d&&c.y>a.y?(k.x=a.x>h.right?this.computeMidOrthoPosition(a.x,a.y,h.right,c.y,!1):a.x>h.left&&(270===d&&a.y<h.top||90===d&&a.y>h.bottom)?this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!1):h.left,l.x=k.x,l.y=c.y):180===d&&a.x>h.right&&a.y>h.top&&a.y<
h.bottom&&(k.x=a.x,k.y=a.y<c.y?Math.min(c.y,h.top):Math.max(c.y,h.bottom),l.y=k.y);else{k=new H(a.x,c.y);l=new H((a.x+c.x)/2,c.y);if(0===d||90===d&&c.y<g.top||270===d&&c.y>g.bottom)0===d&&(h.da(a)||g.da(c))?k.y=this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!0):c.y<a.y&&(0===d||90===d)?k.y=this.computeMidOrthoPosition(a.x,g.top,c.x,Math.max(c.y,h.bottom),!0):c.y>a.y&&(0===d||270===d)&&(k.y=this.computeMidOrthoPosition(a.x,g.bottom,c.x,Math.min(c.y,h.top),!0)),l.x=c.x,l.y=k.y;if(k.y>g.top&&k.y<g.bottom)if(c.x<=
g.right&&c.x>=a.x||a.x>=h.left&&a.x<=c.x){if(90===d||270===d)k=new H(Math.min((a.x+c.x)/2,a.x),a.y),l=new H(k.x,c.y)}else k.y=270===d||(0===d||180===d)&&c.y<a.y?Math.min(c.y,180===d?g.top:Math.min(g.top,h.top)):Math.max(c.y,180===d?g.bottom:Math.max(g.bottom,h.bottom)),l.x=c.x,l.y=k.y}else if(90===b)if(c.y>a.y||180===d&&c.x<a.x&&h.bottom>a.y||0===d&&c.x>a.x&&h.bottom>a.y)k=new H(a.x,c.y),l=new H((a.x+c.x)/2,c.y),270===d?(k.y=this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!0),l.x=c.x,l.y=k.y):180===
d&&c.x<a.x||0===d&&c.x>a.x?(k.y=a.y<h.top?this.computeMidOrthoPosition(a.x,a.y,c.x,h.top,!0):a.y<h.bottom&&(180===d&&a.x<h.left||0===d&&a.x>h.right)?this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!0):h.bottom,l.x=c.x,l.y=k.y):90===d&&a.y<h.top&&a.x>h.left&&a.x<h.right&&(k.x=a.x<c.x?Math.min(c.x,h.left):Math.max(c.x,h.right),k.y=a.y,l.x=k.x);else{k=new H(c.x,a.y);l=new H(c.x,(a.y+c.y)/2);if(270===d||0===d&&c.x<g.left||180===d&&c.x>g.right)270===d&&(h.da(a)||g.da(c))?k.x=this.computeMidOrthoPosition(a.x,
a.y,c.x,c.y,!1):c.x<a.x&&(270===d||0===d)?k.x=this.computeMidOrthoPosition(g.left,a.y,Math.max(c.x,h.right),c.y,!1):c.x>a.x&&(270===d||180===d)&&(k.x=this.computeMidOrthoPosition(g.right,a.y,Math.min(c.x,h.left),c.y,!1)),l.x=k.x,l.y=c.y;if(k.x>g.left&&k.x<g.right)if(c.y>=g.top&&c.y<=a.y||a.y<=h.bottom&&a.y>=c.y){if(0===d||180===d)k=new H(a.x,Math.max((a.y+c.y)/2,a.y)),l=new H(c.x,k.y)}else k.x=180===d||(90===d||270===d)&&c.x<a.x?Math.min(c.x,90===d?g.left:Math.min(g.left,h.left)):Math.max(c.x,90===
d?g.right:Math.max(g.right,h.right)),l.x=k.x,l.y=c.y}else if(c.y<a.y||180===d&&c.x<a.x&&h.top<a.y||0===d&&c.x>a.x&&h.top<a.y)k=new H(a.x,c.y),l=new H((a.x+c.x)/2,c.y),90===d?(k.y=this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!0),l.x=c.x,l.y=k.y):180===d&&c.x<a.x||0===d&&c.x>=a.x?(k.y=a.y>h.bottom?this.computeMidOrthoPosition(a.x,a.y,c.x,h.bottom,!0):a.y>h.top&&(180===d&&a.x<h.left||0===d&&a.x>h.right)?this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!0):h.top,l.x=c.x,l.y=k.y):270===d&&a.y>h.bottom&&a.x>
h.left&&a.x<h.right&&(k.x=a.x<c.x?Math.min(c.x,h.left):Math.max(c.x,h.right),k.y=a.y,l.x=k.x);else{k=new H(c.x,a.y);l=new H(c.x,(a.y+c.y)/2);if(90===d||0===d&&c.x<g.left||180===d&&c.x>g.right)90===d&&(h.da(a)||g.da(c))?k.x=this.computeMidOrthoPosition(a.x,a.y,c.x,c.y,!1):c.x<a.x&&(90===d||0===d)?k.x=this.computeMidOrthoPosition(g.left,a.y,Math.max(c.x,h.right),c.y,!1):c.x>a.x&&(90===d||180===d)&&(k.x=this.computeMidOrthoPosition(g.right,a.y,Math.min(c.x,h.left),c.y,!1)),l.x=k.x,l.y=c.y;if(k.x>g.left&&
k.x<g.right)if(c.y<=g.bottom&&c.y>=a.y||a.y>=h.top&&a.y<=c.y){if(0===d||180===d)k=new H(a.x,Math.min((a.y+c.y)/2,a.y)),l=new H(c.x,k.y)}else k.x=180===d||(90===d||270===d)&&c.x<a.x?Math.min(c.x,270===d?g.left:Math.min(g.left,h.left)):Math.max(c.x,270===d?g.right:Math.max(g.right,h.right)),l.x=k.x,l.y=c.y}var m=k,n=l,p=c;if(this.isAvoiding){var q=this.diagram;if(null===q||!ik(q)||g.da(p)&&!f.Vd(e)||h.da(a)&&!e.Vd(f)||e===f||this.layer.isTemporary)b=!1;else{var r=jk(q,!0,this.containingGroup,null);
if(r.gk(Math.min(a.x,m.x),Math.min(a.y,m.y),Math.abs(a.x-m.x),Math.abs(a.y-m.y))&&r.gk(Math.min(m.x,n.x),Math.min(m.y,n.y),Math.abs(m.x-n.x),Math.abs(m.y-n.y))&&r.gk(Math.min(n.x,p.x),Math.min(n.y,p.y),Math.abs(n.x-p.x),Math.abs(n.y-p.y)))b=!1;else{e=a;f=p;var u=c=null;if(q.isVirtualized){q=r.bounds.copy();q.Uc(-r.Ul,-r.Tl);var x=H.alloc();ap(r,a.x,a.y)||(I.Tc(q.x,q.y,q.x+q.width,q.y+q.height,a.x,a.y,m.x,m.y,x)?(c=a=x.copy(),b=x.Wa(m)):I.Tc(q.x,q.y,q.x+q.width,q.y+q.height,m.x,m.y,n.x,n.y,x)?(c=a=
x.copy(),b=x.Wa(n)):I.Tc(q.x,q.y,q.x+q.width,q.y+q.height,n.x,n.y,p.x,p.y,x)&&(c=a=x.copy(),b=x.Wa(p)));ap(r,p.x,p.y)||(I.Tc(q.x,q.y,q.x+q.width,q.y+q.height,p.x,p.y,n.x,n.y,x)?(u=p=x.copy(),d=n.Wa(x)):I.Tc(q.x,q.y,q.x+q.width,q.y+q.height,n.x,n.y,m.x,m.y,x)?(u=p=x.copy(),d=m.Wa(x)):I.Tc(q.x,q.y,q.x+q.width,q.y+q.height,m.x,m.y,a.x,a.y,x)&&(u=p=x.copy(),d=a.Wa(x)));H.free(x)}g=g.copy().Jc(h);h=r.Qz;g.Uc(r.Ul*h,r.Tl*h);bp(r,a,b,p,d,g);h=cp(r,p.x,p.y);!r.abort&&h>=dp&&(mk(r),h=r.sz,g.Uc(r.Ul*h,r.Tl*
h),bp(r,a,b,p,d,g),h=cp(r,p.x,p.y));!r.abort&&h>=dp&&r.Wz&&(mk(r),bp(r,a,b,p,d,r.bounds),h=cp(r,p.x,p.y));if(!r.abort&&h<dp&&cp(r,p.x,p.y)!==ep){fp(this,r,p.x,p.y,d,!0);g=this.i(2);if(4>this.pointsCount)0===b||180===b?(g.x=a.x,g.y=p.y):(g.x=p.x,g.y=a.y),this.M(2,g.x,g.y),this.m(3,g.x,g.y);else if(p=this.i(3),0===b||180===b)I.w(g.x,p.x)?(g=0===b?Math.max(g.x,a.x):Math.min(g.x,a.x),this.M(2,g,a.y),this.M(3,g,p.y)):I.w(g.y,p.y)?(Math.abs(a.y-g.y)<=r.Tl/2&&(this.M(2,g.x,a.y),this.M(3,p.x,a.y)),this.m(2,
g.x,a.y)):this.M(2,a.x,g.y);else if(90===b||270===b)I.w(g.y,p.y)?(g=90===b?Math.max(g.y,a.y):Math.min(g.y,a.y),this.M(2,a.x,g),this.M(3,p.x,g)):I.w(g.x,p.x)?(Math.abs(a.x-g.x)<=r.Ul/2&&(this.M(2,a.x,g.y),this.M(3,a.x,p.y)),this.m(2,a.x,g.y)):this.M(2,g.x,a.y);null!==c&&(a=this.i(1),p=this.i(2),a.x!==p.x&&a.y!==p.y?0===b||180===b?this.m(2,a.x,p.y):this.m(2,p.x,a.y):0===b||180===b?this.m(2,e.x,c.y):this.m(2,c.x,e.y));null!==u&&(0===d||180===d?this.kf(f.x,u.y):this.kf(u.x,f.y));b=!0}else b=!1}}}else b=
!1;b||(this.ye(k),this.ye(l))}};S.prototype.computeMidOrthoPosition=function(a,b,c,d,e){var f=0;this.hasCurviness()&&(f=this.computeCurviness());return e?(b+d)/2+f:(a+c)/2+f};function Mj(a){if(null===a.diagram||!a.isAvoiding||!ik(a.diagram))return!1;var b=a.points.j,c=b.length;if(4>c)return!1;a=jk(a.diagram,!0,a.containingGroup,null);for(var d=1;d<c-2;d++){var e=b[d],f=b[d+1];if(!a.gk(Math.min(e.x,f.x),Math.min(e.y,f.y),Math.abs(e.x-f.x),Math.abs(e.y-f.y)))return!0}return!1}
function fp(a,b,c,d,e,f){var g=b.Ul,h=b.Tl,k=cp(b,c,d),l=c,m=d;for(0===e?l+=g:90===e?m+=h:180===e?l-=g:m-=h;k>gp&&cp(b,l,m)===k-1;)c=l,d=m,0===e?l+=g:90===e?m+=h:180===e?l-=g:m-=h,--k;if(f){if(k>gp)if(180===e||0===e)c=Math.floor(c/g)*g+g/2;else if(90===e||270===e)d=Math.floor(d/h)*h+h/2}else c=Math.floor(c/g)*g+g/2,d=Math.floor(d/h)*h+h/2;k>gp&&(f=e,l=c,m=d,0===e?(f=90,m+=h):90===e?(f=180,l-=g):180===e?(f=270,m-=h):270===e&&(f=0,l+=g),cp(b,l,m)===k-1?fp(a,b,l,m,f,!1):(l=c,m=d,0===e?(f=270,m-=h):90===
e?(f=0,l+=g):180===e?(f=90,m+=h):270===e&&(f=180,l-=g),cp(b,l,m)===k-1&&fp(a,b,l,m,f,!1)));a.kf(c,d)}S.prototype.Ey=function(a){var b=a.x;a=a.y;for(var c=this.i(0),d=this.i(1),e=Rb(b,a,c.x,c.y,d.x,d.y),f=0,g=1;g<this.pointsCount-1;g++){c=this.i(g+1);var h=Rb(b,a,d.x,d.y,c.x,c.y);d=c;h<e&&(f=g,e=h)}return f};S.prototype.ac=function(){this.Pn=!0};
S.prototype.ik=function(a){if(!a){if(!1===this.Lc)return;a=this.zb();if(!this.Pn&&(null===a||null!==a.geometry))return}this.qa=this.makeGeometry();a=this.path;if(null!==a){a.qa=this.qa;for(var b=this.Y.j,c=b.length,d=0;d<c;d++){var e=b[d];e!==a&&e.isPanelMain&&e instanceof W&&(e.qa=this.qa)}}};
S.prototype.makeGeometry=function(){var a=this.qa,b=this.pointsCount;if(2>b)return a.type=ce,this.Pn=!1,a;var c=!1,d=this.diagram;null!==d&&Qo(this)&&d.Xh.contains(this)&&(0!==this.xl.width||0!==this.xl.height)&&(c=!0);var e=this.i(0).copy(),f=e.copy();d=this.xb.j;var g=this.computeCurve();if(g===Rg&&3<=b&&!I.ca(this.smoothness,0))if(3===b){var h=this.i(1);d=Math.min(e.x,h.x);var k=Math.min(e.y,h.y);h=this.i(2);d=Math.min(d,h.x);k=Math.min(k,h.y)}else{if(this.isOrthogonal)for(k=0;k<b;k++)h=d[k],f.x=
Math.min(h.x,f.x),f.y=Math.min(h.y,f.y);else for(d=3;d<b;d+=3)d+3>=b&&(d=b-1),k=this.i(d),f.x=Math.min(k.x,f.x),f.y=Math.min(k.y,f.y);d=f.x;k=f.y}else{for(k=0;k<b;k++)h=d[k],f.x=Math.min(h.x,f.x),f.y=Math.min(h.y,f.y);d=f.x;k=f.y}d-=this.yu.x;k-=this.yu.y;e.x-=d;e.y-=k;if(2!==b||Qo(this)){a.type=ae;h=ne(a);0!==this.computeShortLength(!0)&&(e=hp(this,e,!0,f));oe(h,e.x,e.y,!1);if(g===Rg&&3<=b&&!I.ca(this.smoothness,0))if(3===b)c=this.i(1),b=c.x-d,c=c.y-k,e=this.i(2).copy(),e.x-=d,e.y-=k,0!==this.computeShortLength(!1)&&
(e=hp(this,e,!1,f)),pe(h,b,c,b,c,e.x,e.y);else if(this.isOrthogonal){f=new H(d,k);e=this.i(1).copy();g=new H(d,k);b=new H(d,k);c=this.i(0);for(var l,m=this.smoothness/3,n=1;n<this.pointsCount-1;n++){l=this.i(n);var p=c,q=l,r=this.i(ip(this,l,n,!1));if(!I.ca(p.x,q.x)||!I.ca(q.x,r.x))if(!I.ca(p.y,q.y)||!I.ca(q.y,r.y)){var u=m;isNaN(u)&&(u=this.smoothness/3);var x=p.x;p=p.y;var v=q.x;q=q.y;var y=r.x;r=r.y;var z=u*jp(x,p,v,q);u*=jp(v,q,y,r);I.ca(p,q)&&I.ca(v,y)&&(v>x?r>q?(g.x=v-z,g.y=q-z,b.x=v+u,b.y=
q+u):(g.x=v-z,g.y=q+z,b.x=v+u,b.y=q-u):r>q?(g.x=v+z,g.y=q-z,b.x=v-u,b.y=q+u):(g.x=v+z,g.y=q+z,b.x=v-u,b.y=q-u));I.ca(x,v)&&I.ca(q,r)&&(q>p?(y>v?(g.x=v-z,g.y=q-z,b.x=v+u):(g.x=v+z,g.y=q-z,b.x=v-u),b.y=q+u):(y>v?(g.x=v-z,g.y=q+z,b.x=v+u):(g.x=v+z,g.y=q+z,b.x=v-u),b.y=q-u));if(I.ca(x,v)&&I.ca(v,y)||I.ca(p,q)&&I.ca(q,r))x=.5*(x+y),p=.5*(p+r),g.x=x,g.y=p,b.x=x,b.y=p;1===n?(e.x=.5*(c.x+l.x),e.y=.5*(c.y+l.y)):2===n&&I.ca(c.x,this.i(0).x)&&I.ca(c.y,this.i(0).y)&&(e.x=.5*(c.x+l.x),e.y=.5*(c.y+l.y));pe(h,e.x-
d,e.y-k,g.x-d,g.y-k,l.x-d,l.y-k);f.set(g);e.set(b);c=l}}f=c.x;c=c.y;e=this.i(this.pointsCount-1);0!==this.computeShortLength(!1)&&(e=hp(this,e.copy(),!1,ac));f=.5*(f+e.x);c=.5*(c+e.y);pe(h,b.x-d,b.y-k,f-d,c-k,e.x-d,e.y-k)}else for(c=3;c<b;c+=3)f=this.i(c-2),c+3>=b&&(c=b-1),e=this.i(c-1),g=this.i(c),c===b-1&&0!==this.computeShortLength(!1)&&(g=hp(this,g.copy(),!1,ac)),pe(h,f.x-d,f.y-k,e.x-d,e.y-k,g.x-d,g.y-k);else{f=H.alloc();f.assign(this.i(0));g=1;for(e=0;g<b;){g=ip(this,f,g,1<g);m=this.i(g);if(g>=
b-1){if(!f.A(m))0!==this.computeShortLength(!1)&&(m=hp(this,m.copy(),!1,ac)),kp(this,h,-d,-k,f,m,c);else if(0===e)for(g=1;g<b;)m=this.i(g++),kp(this,h,-d,-k,f,m,c),f.assign(m);break}e=ip(this,m,g+1,g<b-3);g=-d;l=-k;n=this.i(e);x=c;I.w(f.y,m.y)&&I.w(m.x,n.x)?(p=this.computeCorner(),p=Math.min(p,Math.abs(m.x-f.x)/2),p=u=Math.min(p,Math.abs(n.y-m.y)/2),I.w(p,0)?(kp(this,h,g,l,f,m,x),f.assign(m)):(v=m.x,q=m.y,y=v,r=q,m.x>f.x?v=m.x-p:v=m.x+p,n.y>m.y?r=m.y+u:r=m.y-u,kp(this,h,g,l,f,new H(v,q),x),te(h,m.x+
g,m.y+l,y+g,r+l),f.h(y,r))):I.w(f.x,m.x)&&I.w(m.y,n.y)?(p=this.computeCorner(),p=Math.min(p,Math.abs(m.y-f.y)/2),p=u=Math.min(p,Math.abs(n.x-m.x)/2),I.w(u,0)?(kp(this,h,g,l,f,m,x),f.assign(m)):(v=m.x,q=m.y,y=v,r=q,m.y>f.y?q=m.y-p:q=m.y+p,n.x>m.x?y=m.x+u:y=m.x-u,kp(this,h,g,l,f,new H(v,q),x),te(h,m.x+g,m.y+l,y+g,r+l),f.h(y,r))):(kp(this,h,g,l,f,m,x),f.assign(m));g=e}H.free(f)}we=h}else h=this.i(1).copy(),h.x-=d,h.y-=k,0!==this.computeShortLength(!0)&&(e=hp(this,e,!0,f)),0!==this.computeShortLength(!1)&&
(h=hp(this,h,!1,f)),a.type=ce,a.startX=e.x,a.startY=e.y,a.endX=h.x,a.endY=h.y;this.Pn=!1;return a};function jp(a,b,c,d){a=c-a;if(isNaN(a)||Infinity===a||-Infinity===a)return NaN;0>a&&(a=-a);b=d-b;if(isNaN(b)||Infinity===b||-Infinity===b)return NaN;0>b&&(b=-b);return I.ca(a,0)?b:I.ca(b,0)?a:Math.sqrt(a*a+b*b)}
function hp(a,b,c,d){var e=a.pointsCount;if(2>e)return b;if(c){var f=a.i(1);c=f.x-d.x;f=f.y-d.y;d=jp(b.x,b.y,c,f);if(0===d)return b;e=2===e?.5*d:d;a=a.computeShortLength(!0);a>e&&(a=e);e=a*(f-b.y)/d;b.x+=a*(c-b.x)/d;b.y+=e}else{f=a.i(e-2);c=f.x-d.x;f=f.y-d.y;d=jp(b.x,b.y,c,f);if(0===d)return b;e=2===e?.5*d:d;a=a.computeShortLength(!1);a>e&&(a=e);e=a*(b.y-f)/d;b.x-=a*(b.x-c)/d;b.y-=e}return b}
function ip(a,b,c,d){for(var e=a.pointsCount,f=b;I.ca(b.x,f.x)&&I.ca(b.y,f.y);){if(c>=e)return e-1;f=a.i(c++)}if(!I.ca(b.x,f.x)&&!I.ca(b.y,f.y))return c-1;for(var g=f;I.ca(b.x,f.x)&&I.ca(f.x,g.x)&&(!d||(b.y>=f.y?f.y>=g.y:f.y<=g.y))||I.ca(b.y,f.y)&&I.ca(f.y,g.y)&&(!d||(b.x>=f.x?f.x>=g.x:f.x<=g.x));){if(c>=e)return e-1;g=a.i(c++)}return c-2}
function kp(a,b,c,d,e,f,g){if(!g&&Qo(a)){g=[];var h=0;a.isVisible()&&(h=lp(a,e,f,g));if(0<h)if(I.w(e.y,f.y))if(e.x<f.x)for(var k=0;k<h;){var l=Math.max(e.x,Math.min(g[k++]-5,f.x-10));b.lineTo(l+c,f.y+d);var m=l+c;for(var n=Math.min(l+10,f.x);k<h;)if(l=g[k],l<n+10)k++,n=Math.min(l+5,f.x);else break;l=f.y-10+d;n+=c;var p=f.y+d;a.curve===Ng?oe(b,n,p,!1):pe(b,m,l,n,l,n,p)}else for(--h;0<=h;){k=Math.min(e.x,Math.max(g[h--]+5,f.x+10));b.lineTo(k+c,f.y+d);m=k+c;for(l=Math.max(k-10,f.x);0<=h;)if(k=g[h],k>
l-10)h--,l=Math.max(k-5,f.x);else break;k=f.y-10+d;l+=c;n=f.y+d;a.curve===Ng?oe(b,l,n,!1):pe(b,m,k,l,k,l,n)}else if(I.w(e.x,f.x))if(e.y<f.y)for(k=0;k<h;){l=Math.max(e.y,Math.min(g[k++]-5,f.y-10));b.lineTo(f.x+c,l+d);m=l+d;for(l=Math.min(l+10,f.y);k<h;)if(n=g[k],n<l+10)k++,l=Math.min(n+5,f.y);else break;n=f.x-10+c;p=f.x+c;l+=d;a.curve===Ng?oe(b,p,l,!1):pe(b,n,m,n,l,p,l)}else for(--h;0<=h;){k=Math.min(e.y,Math.max(g[h--]+5,f.y+10));b.lineTo(f.x+c,k+d);m=k+d;for(k=Math.max(k-10,f.y);0<=h;)if(l=g[h],
l>k-10)h--,k=Math.max(l-5,f.y);else break;l=f.x-10+c;n=f.x+c;k+=d;a.curve===Ng?oe(b,n,k,!1):pe(b,l,m,l,k,n,k)}}b.lineTo(f.x+c,f.y+d)}
function lp(a,b,c,d){var e=a.diagram;if(null===e||b.A(c))return 0;for(e=e.layers;e.next();){var f=e.value;if(null!==f&&f.visible){f=f.Fa.j;for(var g=f.length,h=0;h<g;h++){var k=f[h];if(k instanceof S){if(k===a)return 0<d.length&&d.sort(function(a,b){return a-b}),d.length;if(k.isVisible()&&Qo(k)){var l=k.routeBounds;l.o()&&a.routeBounds.Ic(l)&&!a.usesSamePort(k)&&(l=k.path,null!==l&&l.sf()&&mp(b,c,d,k))}}}}}0<d.length&&d.sort(function(a,b){return a-b});return d.length}
function mp(a,b,c,d){for(var e=I.w(a.y,b.y),f=d.pointsCount,g=d.i(0),h=H.alloc(),k=1;k<f;k++){var l=d.i(k);if(k<f-1){var m=d.i(k+1);if(g.y===l.y&&l.y===m.y){if(l.x>g.x&&m.x>=l.x||l.x<g.x&&m.x<=l.x)continue}else if(g.x===l.x&&l.x===m.x&&(l.y>g.y&&m.y>=l.y||l.y<g.y&&m.y<=l.y))continue}a:{m=a.x;var n=a.y,p=b.x,q=b.y,r=g.x;g=g.y;var u=l.x,x=l.y;if(!I.w(m,p)){if(I.w(n,q)&&I.w(r,u)&&Math.min(m,p)<r&&Math.max(m,p)>r&&Math.min(g,x)<n&&Math.max(g,x)>n&&!I.w(g,x)){h.x=r;h.y=n;m=!0;break a}}else if(!I.w(n,q)&&
I.w(g,x)&&Math.min(n,q)<g&&Math.max(n,q)>g&&Math.min(r,u)<m&&Math.max(r,u)>m&&!I.w(r,u)){h.x=m;h.y=g;m=!0;break a}h.x=0;h.y=0;m=!1}m&&(e?c.push(h.x):c.push(h.y));g=l}H.free(h)}function Qo(a){a=a.curve;return a===Mg||a===Ng}function Uo(a,b){if(b||Qo(a))b=a.diagram,null===b||b.Wi||b.Xh.contains(a)||0===a.xl.width&&0===a.xl.height||b.Xh.add(a,a.xl)}
S.prototype.cq=function(a){var b=this.layer;if(null!==b&&b.visible&&!b.isTemporary){var c=b.diagram;if(null!==c&&!c.animationManager.isAnimating){var d=!1;for(c=c.layers;c.next();){var e=c.value;if(e.visible)if(e===b){d=!0;var f=!1;e=e.Fa.j;for(var g=e.length,h=0;h<g;h++){var k=e[h];k instanceof S&&(k===this?f=!0:f&&np(this,k,a))}}else if(d)for(f=e.Fa.j,e=f.length,g=0;g<e;g++)h=f[g],h instanceof S&&np(this,h,a)}}}};
function np(a,b,c){if(null!==b&&null!==b.qa&&Qo(b)){var d=b.routeBounds;d.o()&&(a.routeBounds.Ic(d)||c.Ic(d))&&(a.usesSamePort(b)||b.ac())}}S.prototype.usesSamePort=function(a){var b=this.pointsCount,c=a.pointsCount;if(0<b&&0<c){var d=this.i(0),e=a.i(0);if(d.Qa(e))return!0;b=this.i(b-1);a=a.i(c-1);if(b.Qa(a)||d.Qa(a)||b.Qa(e))return!0}else if(this.fromNode===a.fromNode||this.toNode===a.toNode||this.fromNode===a.toNode||this.toNode===a.fromNode)return!0;return!1};
S.prototype.isVisible=function(){if(!T.prototype.isVisible.call(this))return!1;var a=this.containingGroup,b=!0,c=this.diagram;null!==c&&(b=c.isTreePathToChildren);c=this.fromNode;if(null!==c){if(this.isTreeLink&&b&&!c.isTreeExpanded)return!1;if(c===a)return!0;for(var d=c;null!==d;){if(d.labeledLink===this)return!0;d=d.containingGroup}c=c.findVisibleNode();if(null===c||c===a)return!1}c=this.toNode;if(null!==c){if(this.isTreeLink&&!b&&!c.isTreeExpanded)return!1;if(c===a)return!0;for(b=c;null!==b;){if(b.labeledLink===
this)return!0;b=b.containingGroup}b=c.findVisibleNode();if(null===b||b===a)return!1}return!0};S.prototype.Lb=function(a){T.prototype.Lb.call(this,a);null!==this.yf&&this.yf.cm();if(null!==this.$c)for(var b=this.$c.iterator;b.next();)b.value.Lb(a)};
function Ro(a){var b=a.Oe;if(null!==b){var c=a.ff;if(null!==c){for(var d=a.Pe,e=a.gf,f=a=null,g=b.Ya.j,h=g.length,k=0;k<h;k++){var l=g[k];if(l.Oe===b&&l.Pe===d&&l.ff===c&&l.gf===e||l.Oe===c&&l.Pe===e&&l.ff===b&&l.gf===d)null===f?f=l:(null===a&&(a=[],a.push(f)),a.push(l))}if(null!==a){f=wo(b,c,d,e);null===f&&(f=new op(b,d,c,e),vo(b,f),vo(c,f));f.links=a;for(b=0;b<a.length;b++)a[b].yf=f;f.cm()}}}}function So(a){var b=a.yf;null!==b&&(a.yf=null,a=b.links.indexOf(a),0<=a&&(Ja(b.links,a),b.cm()))}
S.prototype.Ah=function(){return!0};
pa.Object.defineProperties(S.prototype,{fromNode:{get:function(){return this.Oe},set:function(a){var b=this.Oe;if(b!==a){var c=this.fromPort;null!==b&&(this.ff!==b&&zo(b,this,c),So(this),this.B(2));this.Oe=a;null!==a&&this.Lb(a.isVisible());this.Hf=null;this.Ra();var d=this.diagram;null!==d&&d.partManager.setFromNodeForLink(this,a,b);var e=this.fromPort,f=this.fromPortChanged;if(null!==f){var g=!0;null!==d&&(g=d.ea,d.ea=!0);f(this,c,e);null!==d&&(d.ea=g)}null!==a&&(this.ff!==
a&&yo(a,this,e),Ro(this),this.B(1));this.g("fromNode",b,a);to(this)}}},fromPortId:{get:function(){return this.Pe},set:function(a){var b=this.Pe;if(b!==a){var c=this.fromPort;null!==c&&xo(this.fromNode,c);So(this);this.Pe=a;var d=this.fromPort;null!==d&&xo(this.fromNode,d);var e=this.diagram;if(null!==e){var f=this.data,g=e.model;null!==f&&g.em()&&g.Cx(f,a)}c!==d&&(this.Hf=null,this.Ra(),f=this.fromPortChanged,null!==f&&(g=!0,null!==e&&(g=e.ea,e.ea=!0),f(this,c,d),null!==
e&&(e.ea=g)));Ro(this);this.g("fromPortId",b,a)}}},fromPort:{get:function(){var a=this.Oe;return null===a?null:a.Ss(this.Pe)}},fromPortChanged:{get:function(){return this.En},set:function(a){var b=this.En;b!==a&&(this.En=a,this.g("fromPortChanged",b,a))}},toNode:{get:function(){return this.ff},set:function(a){var b=this.ff;if(b!==a){var c=this.toPort;null!==b&&(this.Oe!==b&&zo(b,this,c),So(this),this.B(2));this.ff=
a;null!==a&&this.Lb(a.isVisible());this.Hf=null;this.Ra();var d=this.diagram;null!==d&&d.partManager.setToNodeForLink(this,a,b);var e=this.toPort,f=this.toPortChanged;if(null!==f){var g=!0;null!==d&&(g=d.ea,d.ea=!0);f(this,c,e);null!==d&&(d.ea=g)}null!==a&&(this.Oe!==a&&yo(a,this,e),Ro(this),this.B(1));this.g("toNode",b,a);to(this)}}},toPortId:{get:function(){return this.gf},set:function(a){var b=this.gf;if(b!==a){var c=this.toPort;null!==c&&xo(this.toNode,c);So(this);
this.gf=a;var d=this.toPort;null!==d&&xo(this.toNode,d);var e=this.diagram;if(null!==e){var f=this.data,g=e.model;null!==f&&g.em()&&g.Hx(f,a)}c!==d&&(this.Hf=null,this.Ra(),f=this.toPortChanged,null!==f&&(g=!0,null!==e&&(g=e.ea,e.ea=!0),f(this,c,d),null!==e&&(e.ea=g)));Ro(this);this.g("toPortId",b,a)}}},toPort:{get:function(){var a=this.ff;return null===a?null:a.Ss(this.gf)}},toPortChanged:{get:function(){return this.Dp},set:function(a){var b=
this.Dp;b!==a&&(this.Dp=a,this.g("toPortChanged",b,a))}},fromSpot:{get:function(){return null!==this.P?this.P.Ng:Ed},set:function(a){this.Hc();var b=this.P.Ng;b.A(a)||(a=a.I(),this.P.Ng=a,this.g("fromSpot",b,a),this.Ra())}},fromEndSegmentLength:{get:function(){return null!==this.P?this.P.Lg:NaN},set:function(a){this.Hc();var b=this.P.Lg;b!==a&&(0>a&&ya(a,">= 0",S,"fromEndSegmentLength"),this.P.Lg=a,this.g("fromEndSegmentLength",b,a),this.Ra())}},
fromShortLength:{get:function(){return null!==this.P?this.P.Mg:NaN},set:function(a){this.Hc();var b=this.P.Mg;b!==a&&(this.P.Mg=a,this.g("fromShortLength",b,a),this.Ra(),this.ac())}},toSpot:{get:function(){return null!==this.P?this.P.nh:Ed},set:function(a){this.Hc();var b=this.P.nh;b.A(a)||(a=a.I(),this.P.nh=a,this.g("toSpot",b,a),this.Ra())}},toEndSegmentLength:{get:function(){return null!==this.P?this.P.lh:
NaN},set:function(a){this.Hc();var b=this.P.lh;b!==a&&(0>a&&ya(a,">= 0",S,"toEndSegmentLength"),this.P.lh=a,this.g("toEndSegmentLength",b,a),this.Ra())}},toShortLength:{get:function(){return null!==this.P?this.P.mh:NaN},set:function(a){this.Hc();var b=this.P.mh;b!==a&&(this.P.mh=a,this.g("toShortLength",b,a),this.Ra(),this.ac())}},isLabeledLink:{get:function(){return null===this.$c?!1:0<this.$c.count}},labelNodes:{
get:function(){return null===this.$c?xb:this.$c.iterator}},relinkableFrom:{get:function(){return 0!==(this.Ta&1)},set:function(a){var b=0!==(this.Ta&1);b!==a&&(this.Ta^=1,this.g("relinkableFrom",b,a),this.Jb())}},relinkableTo:{get:function(){return 0!==(this.Ta&2)},set:function(a){var b=0!==(this.Ta&2);b!==a&&(this.Ta^=2,this.g("relinkableTo",b,a),this.Jb())}},resegmentable:{get:function(){return 0!==(this.Ta&
4)},set:function(a){var b=0!==(this.Ta&4);b!==a&&(this.Ta^=4,this.g("resegmentable",b,a),this.Jb())}},isTreeLink:{get:function(){return 0!==(this.Ta&8)},set:function(a){var b=0!==(this.Ta&8);b!==a&&(this.Ta^=8,this.g("isTreeLink",b,a),null!==this.fromNode&&uk(this.fromNode),null!==this.toNode&&uk(this.toNode))}},path:{get:function(){var a=this.zb();return a instanceof W?a:null}},routeBounds:{get:function(){this.$i();
var a=this.xl,b=Infinity,c=Infinity,d=this.pointsCount;if(0===d)a.h(NaN,NaN,0,0);else{if(1===d)d=this.i(0),b=Math.min(d.x,b),c=Math.min(d.y,c),a.h(d.x,d.y,0,0);else if(2===d){d=this.i(0);var e=this.i(1);b=Math.min(d.x,e.x);c=Math.min(d.y,e.y);a.h(d.x,d.y,0,0);a.Ie(e)}else if(this.computeCurve()===Rg&&3<=d&&!this.isOrthogonal)if(e=this.i(0),b=e.x,c=e.y,a.h(b,c,0,0),3===d){d=this.i(1);b=Math.min(d.x,b);c=Math.min(d.y,c);var f=this.i(2);b=Math.min(f.x,b);c=Math.min(f.y,c);I.Sl(e.x,e.y,d.x,d.y,d.x,d.y,
f.x,f.y,.5,a)}else for(f=3;f<d;f+=3){var g=this.i(f-2);f+3>=d&&(f=d-1);var h=this.i(f-1),k=this.i(f);I.Sl(e.x,e.y,g.x,g.y,h.x,h.y,k.x,k.y,.5,a);b=Math.min(k.x,b);c=Math.min(k.y,c);e=k}else for(e=this.i(0),f=this.i(1),b=Math.min(e.x,f.x),c=Math.min(e.y,f.y),a.h(e.x,e.y,0,0),a.Ie(f),e=2;e<d;e++)f=this.i(e),b=Math.min(f.x,b),c=Math.min(f.y,c),a.Ie(f);this.yu.h(b-a.x,c-a.y)}return a}},midPoint:{get:function(){this.$i();return this.computeMidPoint(new H)}},midAngle:{
get:function(){this.$i();return this.computeMidAngle()}},flattenedLengths:{get:function(){if(null===this.qr){this.Lc||To(this);for(var a=this.qr=[],b=this.pointsCount,c=0;c<b-1;c++){var d=this.i(c);var e=this.i(c+1);I.ca(d.x,e.x)?(d=e.y-d.y,0>d&&(d=-d)):I.ca(d.y,e.y)?(d=e.x-d.x,0>d&&(d=-d)):d=Math.sqrt(d.Be(e));a.push(d)}}return this.qr}},flattenedTotalLength:{get:function(){var a=this.$t;if(isNaN(a)){for(var b=this.flattenedLengths,
c=b.length,d=a=0;d<c;d++)a+=b[d];this.$t=a}return a}},points:{get:function(){return this.xb},set:function(a){var b=this.xb;if(b!==a){var c=null;if(Array.isArray(a)){var d=0===a.length%2;if(d)for(var e=0;e<a.length;e++)if("number"!==typeof a[e]||isNaN(a[e])){d=!1;break}if(d)for(c=new E,d=0;d<a.length/2;d++)e=(new H(a[2*d],a[2*d+1])).freeze(),c.add(e);else{d=!0;for(e=0;e<a.length;e++){var f=a[e];if(!Ca(f)||"number"!==typeof f.x||isNaN(f.x)||"number"!==typeof f.y||isNaN(f.y)){d=
!1;break}}if(d)for(c=new E,d=0;d<a.length;d++)e=a[d],c.add((new H(e.x,e.y)).freeze())}}else if(a instanceof E)for(c=a.copy(),a=c.iterator;a.next();)a.value.freeze();else A("Link.points value is not an instance of List or Array: "+a);c.freeze();this.xb=c;this.ac();this.s();To(this);a=this.diagram;null!==a&&(a.Qi||a.undoManager.isUndoingRedoing||a.ft.add(this),a.animationManager.rb&&(this.bh=c));this.g("points",b,c)}}},pointsCount:{get:function(){return this.xb.count}},
Lc:{get:function(){return 0!==(this.Ta&16)},set:function(a){0!==(this.Ta&16)!==a&&(this.Ta^=16)}},suspendsRouting:{get:function(){return 0!==(this.Ta&32)},set:function(a){0!==(this.Ta&32)!==a&&(this.Ta^=32)}},Ou:{get:function(){return 0!==(this.Ta&64)},set:function(a){0!==(this.Ta&64)!==a&&(this.Ta^=64)}},defaultFromPoint:{get:function(){return this.u},set:function(a){this.u=a.copy()}},
defaultToPoint:{get:function(){return this.K},set:function(a){this.K=a.copy()}},isOrthogonal:{get:function(){return 2===(this.Gj.value&2)}},isAvoiding:{get:function(){return 4===(this.Gj.value&4)}},geometry:{get:function(){this.Pn&&(this.$i(),this.qa=this.makeGeometry());return this.qa}},firstPickIndex:{get:function(){return 2>=this.pointsCount?0:this.isOrthogonal||
!Vo(this.computeSpot(!0))?1:0}},lastPickIndex:{get:function(){var a=this.pointsCount;return 0===a?0:2>=a?a-1:this.isOrthogonal||!Vo(this.computeSpot(!1))?a-2:a-1}},adjusting:{get:function(){return this.Lm},set:function(a){var b=this.Lm;b!==a&&(this.Lm=a,this.g("adjusting",b,a))}},corner:{get:function(){return this.fn},set:function(a){var b=this.fn;b!==a&&(this.fn=a,this.ac(),this.g("corner",b,a))}},curve:{
get:function(){return this.jn},set:function(a){var b=this.jn;b!==a&&(this.jn=a,this.Ra(),this.ac(),Uo(this,b===Ng||b===Mg||a===Ng||a===Mg),this.g("curve",b,a))}},curviness:{get:function(){return this.kn},set:function(a){var b=this.kn;b!==a&&(this.kn=a,this.Ra(),this.ac(),this.g("curviness",b,a))}},routing:{get:function(){return this.Gj},set:function(a){var b=this.Gj;b!==a&&(this.Gj=a,this.Hf=null,this.Ra(),Uo(this,2===(b.value&
2)||2===(a.value&2)),this.g("routing",b,a))}},smoothness:{get:function(){return this.rp},set:function(a){var b=this.rp;b!==a&&(this.rp=a,this.ac(),this.g("smoothness",b,a))}},key:{get:function(){var a=this.diagram;if(null!==a&&a.model.em())return a.model.ic(this.data)}}});S.prototype.invalidateOtherJumpOvers=S.prototype.cq;S.prototype.findClosestSegment=S.prototype.Ey;S.prototype.updateRoute=S.prototype.$i;S.prototype.invalidateRoute=S.prototype.Ra;
S.prototype.rollbackRoute=S.prototype.yx;S.prototype.commitRoute=S.prototype.lf;S.prototype.startRoute=S.prototype.Ch;S.prototype.clearPoints=S.prototype.Vj;S.prototype.removePoint=S.prototype.zv;S.prototype.addPointAt=S.prototype.kf;S.prototype.addPoint=S.prototype.ye;S.prototype.insertPointAt=S.prototype.m;S.prototype.insertPoint=S.prototype.kz;S.prototype.setPointAt=S.prototype.M;S.prototype.setPoint=S.prototype.jd;S.prototype.getPoint=S.prototype.i;S.prototype.getOtherPort=S.prototype.az;
S.prototype.getOtherNode=S.prototype.Ws;
var Ko=new D(S,"Normal",1),pp=new D(S,"Orthogonal",2),qp=new D(S,"AvoidsNodes",6),$o=new D(S,"AvoidsNodesStraight",7),Og=new D(S,"None",0),Rg=new D(S,"Bezier",9),Ng=new D(S,"JumpGap",10),Mg=new D(S,"JumpOver",11),Lo=new D(S,"End",17),Mo=new D(S,"Scale",18),No=new D(S,"Stretch",19),rn=new D(S,"OrientAlong",21),Cm=new D(S,"OrientPlus90",22),Em=new D(S,"OrientMinus90",23),Oo=new D(S,"OrientOpposite",24),Po=new D(S,"OrientUpright",25),Dm=new D(S,"OrientPlus90Upright",26),Fm=new D(S,"OrientMinus90Upright",
27),Gm=new D(S,"OrientUpright45",28);S.className="Link";S.Normal=Ko;S.Orthogonal=pp;S.AvoidsNodes=qp;S.AvoidsNodesStraight=$o;S.None=Og;S.Bezier=Rg;S.JumpGap=Ng;S.JumpOver=Mg;S.End=Lo;S.Scale=Mo;S.Stretch=No;S.OrientAlong=rn;S.OrientPlus90=Cm;S.OrientMinus90=Em;S.OrientOpposite=Oo;S.OrientUpright=Po;S.OrientPlus90Upright=Dm;S.OrientMinus90Upright=Fm;S.OrientUpright45=Gm;function op(a,b,c,d){nb(this);this.he=this.Br=!1;this.it=a;this.ux=b;this.ov=c;this.vx=d;this.links=[]}
op.prototype.cm=function(){if(!this.Br){var a=this.links;0<a.length&&(a=a[0].diagram,null!==a&&(a.xw.add(this),this.he=a.undoManager.isUndoingRedoing))}this.Br=!0};op.prototype.Yv=function(){if(this.Br){this.Br=!1;var a=this.links;if(0<a.length){var b=a[0],c=b.diagram;c=null===c||c.Qi&&!this.he;this.he=!1;b.arrangeBundledLinks(a,c);1===a.length&&(b.yf=null,a.length=0)}0===a.length&&(a=this.it,null!==this&&null!==a.Le&&a.Le.remove(this),a=this.ov,null!==this&&null!==a.Le&&a.Le.remove(this))}};
op.className="LinkBundle";function kk(){nb(this);this.Ix=this.group=null;this.$s=!0;this.abort=!1;this.Kd=this.Jd=1;this.lo=this.ko=-1;this.lc=this.kc=8;this.Bb=[[]];this.Oj=this.Nj=0;this.Wz=!1;this.Qz=22;this.sz=111}
kk.prototype.initialize=function(a){if(!(0>=a.width||0>=a.height)){var b=a.y,c=a.x+a.width,d=a.y+a.height;this.Jd=Math.floor((a.x-this.kc)/this.kc)*this.kc;this.Kd=Math.floor((b-this.lc)/this.lc)*this.lc;this.ko=Math.ceil((c+2*this.kc)/this.kc)*this.kc;this.lo=Math.ceil((d+2*this.lc)/this.lc)*this.lc;a=1+(Math.ceil((this.ko-this.Jd)/this.kc)|0);b=1+(Math.ceil((this.lo-this.Kd)/this.lc)|0);if(null===this.Bb||this.Nj<a-1||this.Oj<b-1){c=[];for(d=0;d<=a;d++)c[d]=[];this.Bb=c;this.Nj=a-1;this.Oj=b-1}a=
rp;if(null!==this.Bb)for(b=0;b<=this.Nj;b++)for(c=0;c<=this.Oj;c++)this.Bb[b][c]=a}};function ap(a,b,c){return a.Jd<=b&&b<=a.ko&&a.Kd<=c&&c<=a.lo}function cp(a,b,c){if(!ap(a,b,c))return rp;b-=a.Jd;b/=a.kc;c-=a.Kd;c/=a.lc;return a.Bb[b|0][c|0]}function nk(a,b,c){ap(a,b,c)&&(b-=a.Jd,b/=a.kc,c-=a.Kd,c/=a.lc,a.Bb[b|0][c|0]=ep)}function mk(a){if(null!==a.Bb)for(var b=0;b<=a.Nj;b++)for(var c=0;c<=a.Oj;c++)a.Bb[b][c]>=gp&&(a.Bb[b][c]=rp)}
kk.prototype.gk=function(a,b,c,d){if(a>this.ko||a+c<this.Jd||b>this.lo||b+d<this.Kd)return!0;a=(a-this.Jd)/this.kc|0;b=(b-this.Kd)/this.lc|0;c=Math.max(0,c)/this.kc+1|0;var e=Math.max(0,d)/this.lc+1|0;0>a&&(c+=a,a=0);0>b&&(e+=b,b=0);if(0>c||0>e)return!0;d=Math.min(a+c-1,this.Nj)|0;for(c=Math.min(b+e-1,this.Oj)|0;a<=d;a++)for(e=b;e<=c;e++)if(this.Bb[a][e]===ep)return!1;return!0};
function sp(a,b,c,d,e,f,g,h,k){if(!(b<f||b>g||c<h||c>k)){var l=b|0;var m=c|0;var n=a.Bb[l][m];if(n>=gp&&n<dp)for(e?m+=d:l+=d,n+=1;f<=l&&l<=g&&h<=m&&m<=k&&!(n>=a.Bb[l][m]);)a.Bb[l][m]=n,n+=1,e?m+=d:l+=d;l=e?m:l;if(e)if(0<d)for(c+=d;c<l;c+=d)sp(a,b,c,1,!e,f,g,h,k),sp(a,b,c,-1,!e,f,g,h,k);else for(c+=d;c>l;c+=d)sp(a,b,c,1,!e,f,g,h,k),sp(a,b,c,-1,!e,f,g,h,k);else if(0<d)for(b+=d;b<l;b+=d)sp(a,b,c,1,!e,f,g,h,k),sp(a,b,c,-1,!e,f,g,h,k);else for(b+=d;b>l;b+=d)sp(a,b,c,1,!e,f,g,h,k),sp(a,b,c,-1,!e,f,g,h,
k)}}function tp(a,b,c,d,e,f,g,h,k){b|=0;c|=0;var l=ep,m=gp;for(a.Bb[b][c]=m;l===ep&&b>f&&b<g&&c>h&&c<k;)m+=1,a.Bb[b][c]=m,e?c+=d:b+=d,l=a.Bb[b][c]}function up(a,b,c,d,e,f,g,h,k){b|=0;c|=0;var l=ep,m=dp;for(a.Bb[b][c]=m;l===ep&&b>f&&b<g&&c>h&&c<k;)a.Bb[b][c]=m,e?c+=d:b+=d,l=a.Bb[b][c]}
function bp(a,b,c,d,e,f){if(null!==a.Bb){a.abort=!1;var g=b.x,h=b.y;if(ap(a,g,h)&&(g-=a.Jd,g/=a.kc,h-=a.Kd,h/=a.lc,b=d.x,d=d.y,ap(a,b,d)))if(b-=a.Jd,b/=a.kc,d-=a.Kd,d/=a.lc,1>=Math.abs(g-b)&&1>=Math.abs(h-d))a.abort=!0;else{var k=f.x,l=f.y,m=f.x+f.width,n=f.y+f.height;k-=a.Jd;k/=a.kc;l-=a.Kd;l/=a.lc;m-=a.Jd;m/=a.kc;n-=a.Kd;n/=a.lc;f=Math.max(0,Math.min(a.Nj,k|0));m=Math.min(a.Nj,Math.max(0,m|0));l=Math.max(0,Math.min(a.Oj,l|0));n=Math.min(a.Oj,Math.max(0,n|0));g|=0;h|=0;b|=0;d|=0;k=0===c||90===c?
1:-1;c=90===c||270===c;a.Bb[g][h]===ep?(tp(a,g,h,k,c,f,m,l,n),tp(a,g,h,1,!c,f,m,l,n),tp(a,g,h,-1,!c,f,m,l,n)):tp(a,g,h,k,c,g,h,g,h);a.Bb[b][d]===ep?(up(a,b,d,0===e||90===e?1:-1,90===e||270===e,f,m,l,n),up(a,b,d,1,!(90===e||270===e),f,m,l,n),up(a,b,d,-1,!(90===e||270===e),f,m,l,n)):up(a,b,d,k,c,b,d,b,d);a.abort||(sp(a,g,h,1,!1,f,m,l,n),sp(a,g,h,-1,!1,f,m,l,n),sp(a,g,h,1,!0,f,m,l,n),sp(a,g,h,-1,!0,f,m,l,n))}}}
pa.Object.defineProperties(kk.prototype,{bounds:{get:function(){return new N(this.Jd,this.Kd,this.ko-this.Jd,this.lo-this.Kd)}},Ul:{get:function(){return this.kc},set:function(a){0<a&&a!==this.kc&&(this.kc=a,this.initialize(this.bounds))}},Tl:{get:function(){return this.lc},set:function(a){0<a&&a!==this.lc&&(this.lc=a,this.initialize(this.bounds))}}});var ep=0,gp=1,dp=999999,rp=dp+1;kk.className="PositionArray";
function Yo(){nb(this);this.port=this.node=null;this.Wd=[];this.iq=!1}Yo.prototype.toString=function(){for(var a=this.Wd,b=this.node.toString()+" "+a.length.toString()+":",c=0;c<a.length;c++){var d=a[c];null!==d&&(b+="\n  "+d.toString())}return b};
function vp(a,b,c,d){b=b.offsetY;switch(b){case 8:return 90;case 2:return 180;case 1:return 270;case 4:return 0}switch(b){case 9:return 180<c?270:90;case 6:return 90<c&&270>=c?180:0}a=180*Math.atan2(a.height,a.width)/Math.PI;switch(b){case 3:return c>a&&c<=180+a?180:270;case 5:return c>180-a&&c<=360-a?270:0;case 12:return c>a&&c<=180+a?90:0;case 10:return c>180-a&&c<=360-a?180:90;case 7:return 90<c&&c<=180+a?180:c>180+a&&c<=360-a?270:0;case 13:return 180<c&&c<=360-a?270:c>a&&180>=c?90:0;case 14:return c>
a&&c<=180-a?90:c>180-a&&270>=c?180:0;case 11:return c>180-a&&c<=180+a?180:c>180+a?270:90}d&&15!==b&&(c-=15,0>c&&(c+=360));return c>a&&c<180-a?90:c>=180-a&&c<=180+a?180:c>180+a&&c<360-a?270:0}Yo.prototype.cm=function(){this.Wd.length=0};
function Zo(a,b){var c=a.Wd;if(0===c.length){a:if(!a.iq){c=a.iq;a.iq=!0;var d=null,e=a.node;e=e instanceof ig?e:null;if(null===e||e.isSubGraphExpanded)var f=a.node.Wu(a.port.portId);else{if(!e.actualBounds.o()){a.iq=c;break a}d=e;f=d.Vu()}var g=a.Wd.length=0,h=a.port.ma(Tc,H.alloc()),k=a.port.ma(hd,H.alloc());e=N.allocAt(h.x,h.y,0,0);e.Ie(k);H.free(h);H.free(k);h=H.allocAt(e.x+e.width/2,e.y+e.height/2);k=a.port.Li();for(f=f.iterator;f.next();){var l=f.value;if(l.isVisible()&&l.fromPort!==l.toPort){var m=
l.fromPort===a.port||null!==l.fromNode&&l.fromNode.Vd(d),n=l.computeSpot(m,a.port);if(n.rf()&&(m=m?l.toPort:l.fromPort,null!==m)){var p=m.part;if(null!==p){var q=p.findVisibleNode();null!==q&&q!==p&&(p=q,m=p.port);m=l.computeOtherPoint(p,m);p=h.Wa(m);p-=k;0>p&&(p+=360);n=vp(e,n,p,l.isOrthogonal);0===n?(n=4,180<p&&(p-=360)):n=90===n?8:180===n?2:1;q=a.Wd[g];void 0===q?(q=new wp(l,p,n),a.Wd[g]=q):(q.link=l,q.angle=p,q.yc=n);q.sv.set(m);g++}}}}H.free(h);a.Wd.sort(Yo.prototype.l);k=a.Wd.length;d=-1;for(g=
h=0;g<k;g++)f=a.Wd[g],void 0!==f&&(f.yc!==d&&(d=f.yc,h=0),f.$p=h,h++);d=-1;h=0;for(g=k-1;0<=g;g--)k=a.Wd[g],void 0!==k&&(k.yc!==d&&(d=k.yc,h=k.$p+1),k.Wl=h);g=a.Wd;n=a.port;d=a.node.portSpreading;h=H.alloc();k=H.alloc();f=H.alloc();l=H.alloc();n.ma(Tc,h);n.ma(Vc,k);n.ma(hd,f);n.ma(fd,l);q=p=m=n=0;if(d===Ho)for(var r=0;r<g.length;r++){var u=g[r];if(null!==u){var x=u.link.computeThickness();switch(u.yc){case 8:p+=x;break;case 2:q+=x;break;case 1:n+=x;break;default:case 4:m+=x}}}var v=r=0,y=1,z=u=0;
for(x=0;x<g.length;x++){var B=g[x];if(null!==B){if(r!==B.yc){r=B.yc;switch(r){case 8:var C=f;v=l;break;case 2:C=l;v=h;break;case 1:C=h;v=k;break;default:case 4:C=k,v=f}u=v.x-C.x;z=v.y-C.y;switch(r){case 8:p>Math.abs(u)?(y=Math.abs(u)/p,p=Math.abs(u)):y=1;break;case 2:q>Math.abs(z)?(y=Math.abs(z)/q,q=Math.abs(z)):y=1;break;case 1:n>Math.abs(u)?(y=Math.abs(u)/n,n=Math.abs(u)):y=1;break;default:case 4:m>Math.abs(z)?(y=Math.abs(z)/m,m=Math.abs(z)):y=1}v=0}var G=B.gq;if(d===Ho){B=B.link.computeThickness();
B*=y;G.set(C);switch(r){case 8:G.x=C.x+u/2+p/2-v-B/2;break;case 2:G.y=C.y+z/2+q/2-v-B/2;break;case 1:G.x=C.x+u/2-n/2+v+B/2;break;default:case 4:G.y=C.y+z/2-m/2+v+B/2}v+=B}else{var K=.5;d===uo&&(K=(B.$p+1)/(B.Wl+1));G.x=C.x+u*K;G.y=C.y+z*K}}}H.free(h);H.free(k);H.free(f);H.free(l);C=a.Wd;for(g=0;g<C.length;g++)d=C[g],null!==d&&(d.Tu=a.computeEndSegmentLength(d));a.iq=c;N.free(e)}c=a.Wd}for(a=0;a<c.length;a++)if(e=c[a],null!==e&&e.link===b)return e;return null}
Yo.prototype.l=function(a,b){return a===b?0:null===a?-1:null===b?1:a.yc<b.yc?-1:a.yc>b.yc?1:a.angle<b.angle?-1:a.angle>b.angle?1:0};Yo.prototype.computeEndSegmentLength=function(a){var b=a.link,c=b.computeEndSegmentLength(this.node,this.port,Sc,b.fromPort===this.port),d=a.$p;if(0>d)return c;var e=a.Wl;if(1>=e||!b.isOrthogonal)return c;b=a.sv;var f=a.gq;if(2===a.yc||8===a.yc)d=e-1-d;return((a=2===a.yc||4===a.yc)?b.y<f.y:b.x<f.x)?c+8*d:(a?b.y===f.y:b.x===f.x)?c:c+8*(e-1-d)};Yo.className="Knot";
function wp(a,b,c){this.link=a;this.angle=b;this.yc=c;this.sv=new H;this.Wl=this.$p=0;this.gq=new H;this.Tu=0}wp.prototype.toString=function(){return this.link.toString()+" "+this.angle.toString()+" "+this.yc.toString()+":"+this.$p.toString()+"/"+this.Wl.toString()+" "+this.gq.toString()+" "+this.Tu.toString()+" "+this.sv.toString()};wp.className="LinkInfo";function Tk(){this.nh=this.Ng=Ed;this.mh=this.Mg=this.lh=this.Lg=NaN;this.Bp=this.Cn=null;this.Cp=this.Dn=Infinity}
Tk.prototype.copy=function(){var a=new Tk;a.Ng=this.Ng.I();a.nh=this.nh.I();a.Lg=this.Lg;a.lh=this.lh;a.Mg=this.Mg;a.mh=this.mh;a.Cn=this.Cn;a.Bp=this.Bp;a.Dn=this.Dn;a.Cp=this.Cp;return a};Tk.className="LinkSettings";function yi(){nb(this);this.K=this.C=null;this.Rg=this.Rn=!0;this.Xn=!1;this.Pm=(new H(0,0)).freeze();this.Un=!0;this.Tn=null;this.rw="";this.u=null;this.Wn=!1;this.l=null}
yi.prototype.cloneProtected=function(a){a.Rn=this.Rn;a.Rg=this.Rg;a.Xn=this.Xn;a.Pm.assign(this.Pm);a.Un=this.Un;a.Tn=this.Tn;a.rw=this.rw;a.Wn=!0};yi.prototype.copy=function(){var a=new this.constructor;this.cloneProtected(a);return a};yi.prototype.fb=function(){};yi.prototype.toString=function(){var a=Pa(this.constructor);a+="(";null!==this.group&&(a+=" in "+this.group);null!==this.diagram&&(a+=" for "+this.diagram);return a+")"};
yi.prototype.B=function(){if(this.isValidLayout){var a=this.diagram;if(null!==a&&!a.undoManager.isUndoingRedoing){var b=a.animationManager;!b.isTicking&&(b.isAnimating&&b.vd(),this.isOngoing&&a.Qi||this.isInitial&&!a.Qi)&&(this.isValidLayout=!1,a.cc())}}};yi.prototype.createNetwork=function(){return new xp(this)};yi.prototype.makeNetwork=function(a){var b=this.createNetwork();a instanceof P?(b.lg(a.nodes,!0),b.lg(a.links,!0)):a instanceof ig?b.lg(a.memberParts):b.lg(a.iterator);return b};
yi.prototype.updateParts=function(){var a=this.diagram;if(null===a&&null!==this.network)for(var b=this.network.vertexes.iterator;b.next();){var c=b.value.node;if(null!==c&&(a=c.diagram,null!==a))break}this.isValidLayout=!0;try{null!==a&&a.Aa("Layout"),this.commitLayout()}finally{null!==a&&a.Za("Layout")}};yi.prototype.commitLayout=function(){if(null!==this.network){for(var a=this.network.vertexes.iterator;a.next();)a.value.commit();if(this.isRouting)for(a=this.network.edges.iterator;a.next();)a.value.commit()}};
yi.prototype.doLayout=function(a){var b=new F;a instanceof P?(yp(this,b,a.nodes,!0,this.pk,!0,!1,!0),yp(this,b,a.parts,!0,this.pk,!0,!1,!0)):a instanceof ig?yp(this,b,a.memberParts,!1,this.pk,!0,!1,!0):b.addAll(a.iterator);var c=b.count;if(0<c){a=this.diagram;null!==a&&a.Aa("Layout");c=Math.ceil(Math.sqrt(c));this.arrangementOrigin=this.initialOrigin(this.arrangementOrigin);var d=this.arrangementOrigin.x,e=d,f=this.arrangementOrigin.y,g=0,h=0;for(b=b.iterator;b.next();){var k=b.value;zp(k);var l=
k.measuredBounds,m=l.width;l=l.height;k.moveTo(e,f);k instanceof ig&&(k.pk=!1);e+=Math.max(m,50)+20;h=Math.max(h,Math.max(l,50));g>=c-1?(g=0,e=d,f+=h+20,h=0):g++}null!==a&&a.Za("Layout")}this.isValidLayout=!0};yi.prototype.pk=function(a){return!a.location.o()||a instanceof ig&&a.pk?!0:!1};
function yp(a,b,c,d,e,f,g,h){for(c=c.iterator;c.next();){var k=c.value;d&&!k.isTopLevel||null!==e&&!e(k)||!k.canLayout()||(f&&k instanceof V?k.isLinkLabel||(k instanceof ig?null===k.layout?yp(a,b,k.memberParts,!1,e,f,g,h):(zp(k),b.add(k)):(zp(k),b.add(k))):g&&k instanceof S?b.add(k):!h||!k.bc()||k instanceof V||(zp(k),b.add(k)))}}function zp(a){var b=a.actualBounds;(0===b.width||0===b.height||isNaN(b.width)||isNaN(b.height))&&a.$b()}
yi.prototype.Ni=function(a,b){var c=this.boundsComputation;if(null!==c)return b||(b=new N),c(a,this,b);if(!b)return a.actualBounds;b.set(a.actualBounds);return b};yi.prototype.Uw=function(a){var b=new F;a instanceof P?(yp(this,b,a.nodes,!0,null,!0,!0,!0),yp(this,b,a.links,!0,null,!0,!0,!0),yp(this,b,a.parts,!0,null,!0,!0,!0)):a instanceof ig?yp(this,b,a.memberParts,!1,null,!0,!0,!0):yp(this,b,a.iterator,!1,null,!0,!0,!0);return b};
yi.prototype.initialOrigin=function(a){var b=this.group;if(null!==b){var c=b.position.copy();(isNaN(c.x)||isNaN(c.y))&&c.set(a);b=b.placeholder;null!==b&&(c=b.ma(Tc),(isNaN(c.x)||isNaN(c.y))&&c.set(a),a=b.padding,c.x+=a.left,c.y+=a.top);return c}return a};
pa.Object.defineProperties(yi.prototype,{diagram:{get:function(){return this.C},set:function(a){this.C=a}},group:{get:function(){return this.K},set:function(a){this.K!==a&&(this.K=a,null!==a&&(this.C=a.diagram))}},isOngoing:{get:function(){return this.Rn},set:function(a){this.Rn!==a&&(this.Rn=a)}},isInitial:{get:function(){return this.Rg},set:function(a){this.Rg=a;a||(this.Wn=!0)}},
isViewportSized:{get:function(){return this.Xn},set:function(a){this.Xn!==a&&(this.Xn=a)&&this.B()}},isRouting:{get:function(){return this.Un},set:function(a){this.Un!==a&&(this.Un=a)}},isRealtime:{get:function(){return this.Tn},set:function(a){this.Tn!==a&&(this.Tn=a)}},isValidLayout:{get:function(){return this.Wn},set:function(a){this.Wn!==a&&(this.Wn=a,a||(a=this.diagram,null!==
a&&(a.Bg=!0)))}},network:{get:function(){return this.l},set:function(a){this.l!==a&&(this.l=a,null!==a&&(a.layout=this))}},boundsComputation:{get:function(){return this.u},set:function(a){this.u!==a&&(this.u=a,this.B())}},arrangementOrigin:{get:function(){return this.Pm},set:function(a){this.Pm.A(a)||(this.Pm.assign(a),this.B())}}});yi.prototype.collectParts=yi.prototype.Uw;yi.prototype.getLayoutBounds=yi.prototype.Ni;
yi.prototype.invalidateLayout=yi.prototype.B;yi.className="Layout";function xp(a){nb(this);this.gc=a;this.hf=new F;this.de=new F;this.jt=new Lb;this.et=new Lb}xp.prototype.clear=function(){if(this.hf)for(var a=this.hf.iterator;a.next();)a.value.clear();if(this.de)for(a=this.de.iterator;a.next();)a.value.clear();this.hf=new F;this.de=new F;this.jt=new Lb;this.et=new Lb};
xp.prototype.toString=function(a){void 0===a&&(a=0);var b="LayoutNetwork"+(null!==this.layout?"("+this.layout.toString()+")":"");if(0>=a)return b;b+=" vertexes: "+this.hf.count+" edges: "+this.de.count;if(1<a){for(var c=this.hf.iterator;c.next();)b+="\n    "+c.value.toString(a-1);for(c=this.de.iterator;c.next();)b+="\n    "+c.value.toString(a-1)}return b};xp.prototype.createVertex=function(){return new Ap(this)};xp.prototype.createEdge=function(){return new Bp(this)};
xp.prototype.lg=function(a,b,c){if(null!==a){void 0===b&&(b=!1);void 0===c&&(c=null);null===c&&(c=function(a){if(a instanceof V)return!a.isLinkLabel;if(a instanceof S){var b=a.fromNode;if(null===b||b.isLinkLabel)return!1;a=a.toNode;return null===a||a.isLinkLabel?!1:!0}return!1});for(a=a.iterator;a.next();){var d=a.value;if(d instanceof V&&(!b||d.isTopLevel)&&d.canLayout()&&c(d))if(d instanceof ig&&null===d.layout)this.lg(d.memberParts,!1);else if(null===this.Ki(d)){var e=this.createVertex();e.node=
d;this.th(e)}}for(a.reset();a.next();)if(d=a.value,d instanceof S&&(!b||d.isTopLevel)&&d.canLayout()&&c(d)&&null===this.Up(d)){var f=d.fromNode;e=d.toNode;null!==f&&null!==e&&f!==e&&(f=this.findGroupVertex(f),e=this.findGroupVertex(e),null!==f&&null!==e&&this.hk(f,e,d))}}};xp.prototype.findGroupVertex=function(a){if(null===a)return null;var b=a.findVisibleNode();if(null===b)return null;a=this.Ki(b);if(null!==a)return a;for(b=b.containingGroup;null!==b;){a=this.Ki(b);if(null!==a)return a;b=b.containingGroup}return null};
t=xp.prototype;t.th=function(a){if(null!==a){this.hf.add(a);var b=a.node;null!==b&&this.jt.add(b,a);a.network=this}};t.Rl=function(a){if(null===a)return null;var b=this.Ki(a);null===b&&(b=this.createVertex(),b.node=a,this.th(b));return b};t.Su=function(a){if(null!==a&&Cp(this,a)){for(var b=a.vg,c=b.count-1;0<=c;c--){var d=b.N(c);this.ak(d)}b=a.mg;for(a=b.count-1;0<=a;a--)c=b.N(a),this.ak(c)}};function Cp(a,b){if(null===b)return!1;var c=a.hf.remove(b);c&&a.jt.remove(b.node);return c}
t.yy=function(a){null!==a&&(a=this.Ki(a),null!==a&&this.Su(a))};t.Ki=function(a){return null===a?null:this.jt.J(a)};t.Qj=function(a){if(null!==a){this.de.add(a);var b=a.link;null!==b&&null===this.Up(b)&&this.et.add(b,a);b=a.toVertex;null!==b&&b.Gu(a);b=a.fromVertex;null!==b&&b.Eu(a);a.network=this}};
t.by=function(a){if(null===a)return null;var b=a.fromNode,c=a.toNode,d=this.Up(a);null===d?(d=this.createEdge(),d.link=a,null!==b&&(d.fromVertex=this.Rl(b)),null!==c&&(d.toVertex=this.Rl(c)),this.Qj(d)):(null!==b?d.fromVertex=this.Rl(b):d.fromVertex=null,null!==c?d.toVertex=this.Rl(c):d.toVertex=null);return d};t.ak=function(a){if(null!==a){var b=a.toVertex;null!==b&&b.Ru(a);b=a.fromVertex;null!==b&&b.Qu(a);Dp(this,a)}};function Dp(a,b){null!==b&&a.de.remove(b)&&a.et.remove(b.link)}
t.xy=function(a){null!==a&&(a=this.Up(a),null!==a&&this.ak(a))};t.Up=function(a){return null===a?null:this.et.J(a)};t.hk=function(a,b,c){if(null===a||null===b)return null;if(a.network===this&&b.network===this){var d=this.createEdge();d.link=c;d.fromVertex=a;d.toVertex=b;this.Qj(d);return d}return null};t.sm=function(a){if(null!==a){var b=a.fromVertex,c=a.toVertex;null!==b&&null!==c&&(b.Qu(a),c.Ru(a),a.sm(),b.Gu(a),c.Eu(a))}};
t.Sp=function(){for(var a=Ka(),b=this.de.iterator;b.next();){var c=b.value;c.fromVertex===c.toVertex&&a.push(c)}b=a.length;for(c=0;c<b;c++)this.ak(a[c]);Oa(a)};xp.prototype.deleteArtificialVertexes=function(){for(var a=Ka(),b=this.hf.iterator;b.next();){var c=b.value;null===c.node&&a.push(c)}c=a.length;for(b=0;b<c;b++)this.Su(a[b]);b=Ka();for(c=this.de.iterator;c.next();){var d=c.value;null===d.link&&b.push(d)}c=b.length;for(d=0;d<c;d++)this.ak(b[d]);Oa(a);Oa(b)};
function Ep(a){for(var b=Ka(),c=a.de.iterator;c.next();){var d=c.value;null!==d.fromVertex&&null!==d.toVertex||b.push(d)}c=b.length;for(d=0;d<c;d++)a.ak(b[d]);Oa(b)}
xp.prototype.Jx=function(){this.deleteArtificialVertexes();Ep(this);this.Sp();for(var a=new E,b=!0;b;){b=!1;for(var c=this.hf.iterator;c.next();){var d=c.value;if(0<d.vg.count||0<d.mg.count){b=this.layout.createNetwork();a.add(b);Fp(this,b,d);b=!0;break}}}a.sort(function(a,b){return null===a||null===b||a===b?0:b.vertexes.count-a.vertexes.count});return a};
function Fp(a,b,c){if(null!==c&&c.network!==b){Cp(a,c);b.th(c);for(var d=c.sourceEdges;d.next();){var e=d.value;e.network!==b&&(Dp(a,e),b.Qj(e),Fp(a,b,e.fromVertex))}for(d=c.destinationEdges;d.next();)c=d.value,c.network!==b&&(Dp(a,c),b.Qj(c),Fp(a,b,c.toVertex))}}xp.prototype.Dy=function(){for(var a=new F,b=this.hf.iterator;b.next();)a.add(b.value.node);for(b=this.de.iterator;b.next();)a.add(b.value.link);return a};
pa.Object.defineProperties(xp.prototype,{layout:{get:function(){return this.gc},set:function(a){null!==a&&(this.gc=a)}},vertexes:{get:function(){return this.hf}},edges:{get:function(){return this.de}}});xp.prototype.findAllParts=xp.prototype.Dy;xp.prototype.splitIntoSubNetworks=xp.prototype.Jx;xp.prototype.deleteSelfEdges=xp.prototype.Sp;xp.prototype.reverseEdge=xp.prototype.sm;xp.prototype.linkVertexes=xp.prototype.hk;
xp.prototype.findEdge=xp.prototype.Up;xp.prototype.deleteLink=xp.prototype.xy;xp.prototype.deleteEdge=xp.prototype.ak;xp.prototype.addLink=xp.prototype.by;xp.prototype.addEdge=xp.prototype.Qj;xp.prototype.findVertex=xp.prototype.Ki;xp.prototype.deleteNode=xp.prototype.yy;xp.prototype.deleteVertex=xp.prototype.Su;xp.prototype.addNode=xp.prototype.Rl;xp.prototype.addVertex=xp.prototype.th;xp.prototype.addParts=xp.prototype.lg;xp.className="LayoutNetwork";
function Ap(a){nb(this);this.Vc=a;this.l=(new N(0,0,10,10)).freeze();this.u=(new H(5,5)).freeze();this.li=this.jb=null;this.vg=new E;this.mg=new E}Ap.prototype.clear=function(){this.li=this.jb=null;this.vg=new E;this.mg=new E};
Ap.prototype.toString=function(a){void 0===a&&(a=0);var b="LayoutVertex#"+Bb(this);if(0<a&&(b+=null!==this.node?"("+this.node.toString()+")":"",1<a)){a="";for(var c=!0,d=this.vg.iterator;d.next();){var e=d.value;c?c=!1:a+=",";a+=e.toString(0)}e="";c=!0;for(d=this.mg.iterator;d.next();){var f=d.value;c?c=!1:e+=",";e+=f.toString(0)}b+=" sources: "+a+" destinations: "+e}return b};
Ap.prototype.commit=function(){var a=this.jb;if(null!==a){var b=this.bounds,c=a.bounds;Ca(c)?(c.x=b.x,c.y=b.y,c.width=b.width,c.height=b.height):a.bounds=b.copy()}else if(a=this.node,null!==a){b=this.bounds;if(!(a instanceof ig)){c=N.alloc();var d=this.network.layout.Ni(a,c),e=a.locationObject.ma(dd);if(d.o()&&e.o()){a.moveTo(b.x+this.focusX-(e.x-d.x),b.y+this.focusY-(e.y-d.y));N.free(c);return}N.free(c)}a.moveTo(b.x,b.y)}};Ap.prototype.Gu=function(a){null!==a&&(this.vg.contains(a)||this.vg.add(a))};
Ap.prototype.Ru=function(a){null!==a&&this.vg.remove(a)};Ap.prototype.Eu=function(a){null!==a&&(this.mg.contains(a)||this.mg.add(a))};Ap.prototype.Qu=function(a){null!==a&&this.mg.remove(a)};function Gp(a,b){a=a.li;b=b.li;return a?b?(a=a.text,b=b.text,a<b?-1:a>b?1:0):1:null!==b?-1:0}
pa.Object.defineProperties(Ap.prototype,{sourceEdgesArrayAccess:{get:function(){return this.vg._dataArray}},destinationEdgesArrayAccess:{get:function(){return this.mg._dataArray}},data:{get:function(){return this.jb},set:function(a){this.jb=a;if(null!==a){var b=a.bounds;a=b.x;var c=b.y,d=b.width;b=b.height;this.u.h(d/2,b/2);this.l.h(a,c,d,b)}}},node:{get:function(){return this.li},
set:function(a){if(this.li!==a){this.li=a;a.$b();var b=this.network.layout,c=N.alloc(),d=b.Ni(a,c);b=d.x;var e=d.y,f=d.width;d=d.height;isNaN(b)&&(b=0);isNaN(e)&&(e=0);this.l.h(b,e,f,d);N.free(c);if(!(a instanceof ig)&&(a=a.locationObject.ma(dd),a.o())){this.u.h(a.x-b,a.y-e);return}this.u.h(f/2,d/2)}}},bounds:{get:function(){return this.l},set:function(a){this.l.A(a)||this.l.assign(a)}},focus:{get:function(){return this.u},set:function(a){this.u.A(a)||
this.u.assign(a)}},centerX:{get:function(){return this.l.x+this.u.x},set:function(a){var b=this.l;b.x+this.u.x!==a&&(b.ha(),b.x=a-this.u.x,b.freeze())}},centerY:{get:function(){return this.l.y+this.u.y},set:function(a){var b=this.l;b.y+this.u.y!==a&&(b.ha(),b.y=a-this.u.y,b.freeze())}},focusX:{get:function(){return this.u.x},set:function(a){var b=this.u;b.x!==a&&(b.ha(),b.x=a,b.freeze())}},focusY:{
get:function(){return this.u.y},set:function(a){var b=this.u;b.y!==a&&(b.ha(),b.y=a,b.freeze())}},x:{get:function(){return this.l.x},set:function(a){var b=this.l;b.x!==a&&(b.ha(),b.x=a,b.freeze())}},y:{get:function(){return this.l.y},set:function(a){var b=this.l;b.y!==a&&(b.ha(),b.y=a,b.freeze())}},width:{get:function(){return this.l.width},set:function(a){var b=this.l;b.width!==a&&(b.ha(),b.width=
a,b.freeze())}},height:{get:function(){return this.l.height},set:function(a){var b=this.l;b.height!==a&&(b.ha(),b.height=a,b.freeze())}},network:{get:function(){return this.Vc},set:function(a){this.Vc=a}},sourceVertexes:{get:function(){for(var a=new F,b=this.sourceEdges;b.next();)a.add(b.value.fromVertex);return a.iterator}},destinationVertexes:{get:function(){for(var a=new F,b=
this.destinationEdges;b.next();)a.add(b.value.toVertex);return a.iterator}},vertexes:{get:function(){for(var a=new F,b=this.sourceEdges;b.next();)a.add(b.value.fromVertex);for(b=this.destinationEdges;b.next();)a.add(b.value.toVertex);return a.iterator}},sourceEdges:{get:function(){return this.vg.iterator}},destinationEdges:{get:function(){return this.mg.iterator}},edges:{get:function(){for(var a=
new E,b=this.sourceEdges;b.next();)a.add(b.value);for(b=this.destinationEdges;b.next();)a.add(b.value);return a.iterator}},edgesCount:{get:function(){return this.vg.count+this.mg.count}}});Ap.prototype.deleteDestinationEdge=Ap.prototype.Qu;Ap.prototype.addDestinationEdge=Ap.prototype.Eu;Ap.prototype.deleteSourceEdge=Ap.prototype.Ru;Ap.prototype.addSourceEdge=Ap.prototype.Gu;Ap.className="LayoutVertex";Ap.standardComparer=Gp;
Ap.smartComparer=function(a,b){if(null!==a){if(null!==b){a=a.li;var c=b.li;if(null!==a){if(null!==c){b=a.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/);a=c.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/);for(c=0;c<b.length;c++)if(""!==a[c]&&void 0!==a[c]){var d=parseFloat(b[c]),e=parseFloat(a[c]);if(isNaN(d))if(isNaN(e)){if(0!==b[c].localeCompare(a[c]))return b[c].localeCompare(a[c])}else return 1;else{if(isNaN(e))return-1;if(0!==d-e)return d-
e}}else if(""!==b[c])return 1;return""!==a[c]&&void 0!==a[c]?-1:0}return 1}return null!==c?-1:0}return 1}return null!==b?-1:0};function Bp(a){nb(this);this.Vb=a;this.gg=this.Jf=this.ml=this.jb=null}Bp.prototype.clear=function(){this.gg=this.Jf=this.ml=this.jb=null};Bp.prototype.toString=function(a){void 0===a&&(a=0);var b="LayoutEdge#"+Bb(this);0<a&&(b+=null!==this.ml?"("+this.ml.toString()+")":"",1<a&&(b+=" "+(this.Jf?this.Jf.toString():"null")+" --\x3e "+(this.gg?this.gg.toString():"null")));return b};
Bp.prototype.sm=function(){var a=this.Jf;this.Jf=this.gg;this.gg=a};Bp.prototype.commit=function(){};Bp.prototype.kx=function(a){return this.gg===a?this.Jf:this.Jf===a?this.gg:null};
pa.Object.defineProperties(Bp.prototype,{network:{get:function(){return this.Vb},set:function(a){this.Vb=a}},data:{get:function(){return this.jb},set:function(a){this.jb!==a&&(this.jb=a)}},link:{get:function(){return this.ml},set:function(a){this.ml!==a&&(this.ml=a)}},fromVertex:{get:function(){return this.Jf},set:function(a){this.Jf!==a&&(this.Jf=a)}},toVertex:{
get:function(){return this.gg},set:function(a){this.gg!==a&&(this.gg=a)}}});Bp.prototype.getOtherVertex=Bp.prototype.kx;Bp.className="LayoutEdge";function Bk(){yi.call(this);this.isViewportSized=!0;this.Ip=this.Jp=NaN;this.Dg=(new L(NaN,NaN)).freeze();this.$e=(new L(10,10)).freeze();this.wb=Hp;this.Ab=Ip;this.Qc=Jp;this.Mc=Kp}oa(Bk,yi);
Bk.prototype.cloneProtected=function(a){yi.prototype.cloneProtected.call(this,a);a.Jp=this.Jp;a.Ip=this.Ip;a.Dg.assign(this.Dg);a.$e.assign(this.$e);a.wb=this.wb;a.Ab=this.Ab;a.Qc=this.Qc;a.Mc=this.Mc};Bk.prototype.fb=function(a){a.classType===Bk?a===Jp||a===Lp||a===Mp||a===Np?this.sorting=a:a===Ip||a===Op?this.arrangement=a:a===Hp||a===Pp?this.alignment=a:A("Unknown enum value: "+a):yi.prototype.fb.call(this,a)};
Bk.prototype.doLayout=function(a){this.arrangementOrigin=this.initialOrigin(this.arrangementOrigin);var b=this.Uw(a);a=this.diagram;for(var c=b.copy().iterator;c.next();){var d=c.value;if(!d.Ah()||null===d.fromNode&&null===d.toNode){if(d.$b(),d instanceof ig)for(d=d.memberParts;d.next();)b.remove(d.value)}else b.remove(d)}var e=b.Na();if(0!==e.length){switch(this.sorting){case Np:e.reverse();break;case Jp:e.sort(this.comparer);break;case Lp:e.sort(this.comparer),e.reverse()}var f=this.wrappingColumn;
isNaN(f)&&(f=0);var g=this.wrappingWidth;isNaN(g)&&null!==a?(b=a.padding,g=Math.max(a.viewportBounds.width-b.left-b.right,0)):g=Math.max(this.wrappingWidth,0);0>=f&&0>=g&&(f=1);b=this.spacing.width;isFinite(b)||(b=0);c=this.spacing.height;isFinite(c)||(c=0);null!==a&&a.Aa("Layout");d=[];switch(this.alignment){case Pp:var h=b,k=c,l=N.alloc(),m=Math.max(this.cellSize.width,1);if(!isFinite(m))for(var n=m=0;n<e.length;n++){var p=this.Ni(e[n],l);m=Math.max(m,p.width)}m=Math.max(m+h,1);n=Math.max(this.cellSize.height,
1);if(!isFinite(n))for(p=n=0;p<e.length;p++){var q=this.Ni(e[p],l);n=Math.max(n,q.height)}n=Math.max(n+k,1);p=this.arrangement;for(var r=q=this.arrangementOrigin.x,u=this.arrangementOrigin.y,x=0,v=0,y=0;y<e.length;y++){var z=e[y],B=this.Ni(z,l),C=Math.ceil((B.width+h)/m)*m,G=Math.ceil((B.height+k)/n)*n;switch(p){case Op:var K=Math.abs(r-B.width);break;default:K=r+B.width}if(0<f&&x>f-1||0<g&&0<x&&K-q>g)d.push(new N(0,u,g+h,v)),x=0,r=q,u+=v,v=0;v=Math.max(v,G);switch(p){case Op:B=-B.width;break;default:B=
0}z.moveTo(r+B,u);switch(p){case Op:r-=C;break;default:r+=C}x++}d.push(new N(0,u,g+h,v));N.free(l);break;case Hp:k=g;m=f;n=b;p=c;g=N.alloc();q=Math.max(this.cellSize.width,1);f=u=l=0;h=H.alloc();for(r=0;r<e.length;r++)v=e[r],x=this.Ni(v,g),v=v.pf(v.locationObject,v.locationSpot,h),l=Math.max(l,v.x),u=Math.max(u,x.width-v.x),f=Math.max(f,v.y);r=this.arrangement;switch(r){case Op:l+=n;break;default:u+=n}q=isFinite(q)?Math.max(q+n,1):Math.max(l+u,1);var M=v=this.arrangementOrigin.x;y=this.arrangementOrigin.y;
u=0;k>=l&&(k-=l);l=z=0;C=Math.max(this.cellSize.height,1);B=f=0;G=!0;x=H.alloc();for(K=0;K<e.length;K++){var U=e[K],R=this.Ni(U,g),da=U.pf(U.locationObject,U.locationSpot,h);if(0<u)switch(r){case Op:M=(M-v-(R.width-da.x))/q;M=I.ca(Math.round(M),M)?Math.round(M):Math.floor(M);M=M*q+v;break;default:M=(M-v+da.x)/q,M=I.ca(Math.round(M),M)?Math.round(M):Math.ceil(M),M=M*q+v}else switch(r){case Op:z=M+da.x+R.width;break;default:z=M-da.x}switch(r){case Op:var ja=-(M+da.x)+z;break;default:ja=M+R.width-da.x-
z}if(0<m&&u>m-1||0<k&&0<u&&ja>k){d.push(new N(0,G?y-f:y,k+n,B+f+p));for(M=0;M<u&&K!==u;M++){ja=e[K-u+M];var J=ja.pf(ja.locationObject,ja.locationSpot,x);ja.moveTo(ja.position.x,ja.position.y+f-J.y)}B+=p;y=G?y+B:y+(B+f);u=B=f=0;M=v;G=!1}M===v&&(l=r===Op?Math.max(l,R.width-da.x):Math.min(l,-da.x));f=Math.max(f,da.y);B=Math.max(B,R.height-da.y);isFinite(C)&&(B=Math.max(B,Math.max(R.height,C)-da.y));G?U.moveTo(M-da.x,y-da.y):U.moveTo(M-da.x,y);switch(r){case Op:M-=da.x+n;break;default:M+=R.width-da.x+
n}u++}d.push(new N(0,y,k+n,(G?B:B+f)+p));if(e.length!==u)for(k=0;k<u;k++)m=e[e.length-u+k],n=m.pf(m.locationObject,m.locationSpot,h),m.moveTo(m.position.x,m.position.y+f-n.y);H.free(h);H.free(x);if(r===Op)for(e=0;e<d.length;e++)f=d[e],f.width+=l,f.x-=l;else for(e=0;e<d.length;e++)f=d[e],f.x>l&&(f.width+=f.x-l,f.x=l);N.free(g)}for(h=f=g=e=0;h<d.length;h++)k=d[h],e=Math.min(e,k.x),g=Math.min(g,k.y),f=Math.max(f,k.x+k.width);this.arrangement===Op?this.commitLayers(d,new H(e+b/2-(f+e),g-c/2)):this.commitLayers(d,
new H(e-b/2,g-c/2));null!==a&&a.Za("Layout");this.isValidLayout=!0}};Bk.prototype.commitLayers=function(){};function Kp(a,b){a=a.text;b=b.text;return a<b?-1:a>b?1:0}
pa.Object.defineProperties(Bk.prototype,{wrappingWidth:{get:function(){return this.Jp},set:function(a){this.Jp!==a&&(0<a||isNaN(a))&&(this.Jp=a,this.isViewportSized=isNaN(a),this.B())}},wrappingColumn:{get:function(){return this.Ip},set:function(a){this.Ip!==a&&(0<a||isNaN(a))&&(this.Ip=a,this.B())}},cellSize:{get:function(){return this.Dg},set:function(a){this.Dg.A(a)||(this.Dg.assign(a),this.B())}},spacing:{
get:function(){return this.$e},set:function(a){this.$e.A(a)||(this.$e.assign(a),this.B())}},alignment:{get:function(){return this.wb},set:function(a){this.wb===a||a!==Hp&&a!==Pp||(this.wb=a,this.B())}},arrangement:{get:function(){return this.Ab},set:function(a){this.Ab===a||a!==Ip&&a!==Op||(this.Ab=a,this.B())}},sorting:{get:function(){return this.Qc},set:function(a){this.Qc===a||a!==Mp&&a!==Np&&
a!==Jp&&a!==Lp||(this.Qc=a,this.B())}},comparer:{get:function(){return this.Mc},set:function(a){this.Mc!==a&&(this.Mc=a,this.B())}}});var Pp=new D(Bk,"Position",0),Hp=new D(Bk,"Location",1),Ip=new D(Bk,"LeftToRight",2),Op=new D(Bk,"RightToLeft",3),Mp=new D(Bk,"Forward",4),Np=new D(Bk,"Reverse",5),Jp=new D(Bk,"Ascending",6),Lp=new D(Bk,"Descending",7);Bk.className="GridLayout";Bk.standardComparer=Kp;
Bk.smartComparer=function(a,b){if(null!==a){if(null!==b){a=a.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/);b=b.text.toLocaleLowerCase().split(/([+\-]?[\.]?\d+(?:\.\d*)?(?:e[+\-]?\d+)?)/);for(var c=0;c<a.length;c++)if(""!==b[c]&&void 0!==b[c]){var d=parseFloat(a[c]),e=parseFloat(b[c]);if(isNaN(d))if(isNaN(e)){if(0!==a[c].localeCompare(b[c]))return a[c].localeCompare(b[c])}else return 1;else{if(isNaN(e))return-1;if(0!==d-e)return d-e}}else if(""!==a[c])return 1;return""!==
b[c]&&void 0!==b[c]?-1:0}return 1}return null!==b?-1:0};Bk.Position=Pp;Bk.Location=Hp;Bk.LeftToRight=Ip;Bk.RightToLeft=Op;Bk.Forward=Mp;Bk.Reverse=Np;Bk.Ascending=Jp;Bk.Descending=Lp;function ti(){this.Eo=new F;this.fo=new F;this.Fa=new F;this.Me=new Lb;this.Fg=new Lb;this.qj=new Lb;this.C=null;this.Cq=!1}t=ti.prototype;t.clear=function(){this.Eo.clear();this.fo.clear();this.Fa.clear();this.Me.clear();this.Fg.clear();this.qj.clear()};t.hb=function(a){this.C=a};
t.Pi=function(a){if(a instanceof V){if(this.Eo.add(a),a instanceof ig){var b=a.containingGroup;null===b?this.C.zi.add(a):b.rl.add(a);b=a.layout;null!==b&&(b.diagram=this.C)}}else a instanceof S?this.fo.add(a):a instanceof pf||this.Fa.add(a);b=a.data;null===b||a instanceof pf||(a instanceof S?this.Fg.add(b,a):this.Me.add(b,a))};
t.xc=function(a){a.Uj();if(a instanceof V){if(this.Eo.remove(a),a instanceof ig){var b=a.containingGroup;null===b?this.C.zi.remove(a):b.rl.remove(a);b=a.layout;null!==b&&(b.diagram=null)}}else a instanceof S?this.fo.remove(a):a instanceof pf||this.Fa.remove(a);b=a.data;null===b||a instanceof pf||(a instanceof S?this.Fg.remove(b):this.Me.remove(b))};
t.ud=function(){for(var a=this.C.nodeTemplateMap.iterator;a.next();){var b=a.value,c=a.key;(!b.bc()||b instanceof ig)&&A('Invalid node template in Diagram.nodeTemplateMap: template for "'+c+'" must be a Node or a simple Part, not a Group or Link: '+b)}for(a=this.C.groupTemplateMap.iterator;a.next();)b=a.value,c=a.key,b instanceof ig||A('Invalid group template in Diagram.groupTemplateMap: template for "'+c+'" must be a Group, not a normal Node or Link: '+b);for(a=this.C.linkTemplateMap.iterator;a.next();)b=
a.value,c=a.key,b instanceof S||A('Invalid link template in Diagram.linkTemplateMap: template for "'+c+'" must be a Link, not a normal Node or simple Part: '+b);a=Ka();for(b=this.C.selection.iterator;b.next();)(c=b.value.data)&&a.push(c);b=Ka();for(c=this.C.highlighteds.iterator;c.next();){var d=c.value.data;d&&b.push(d)}c=Ka();for(d=this.nodes.iterator;d.next();){var e=d.value;null!==e.data&&(c.push(e.data),c.push(e.location))}for(d=this.links.iterator;d.next();)e=d.value,null!==e.data&&(c.push(e.data),
c.push(e.location));for(d=this.parts.iterator;d.next();)e=d.value,null!==e.data&&(c.push(e.data),c.push(e.location));this.removeAllModeledParts();this.addAllModeledParts();for(d=0;d<a.length;d++)e=this.vc(a[d]),null!==e&&(e.isSelected=!0);for(d=0;d<b.length;d++)e=this.vc(b[d]),null!==e&&(e.isHighlighted=!0);for(d=0;d<c.length;d+=2)e=this.vc(c[d]),null!==e&&(e.location=c[d+1]);Oa(a);Oa(b);Oa(c)};ti.prototype.addAllModeledParts=function(){this.addModeledParts(this.diagram.model.nodeDataArray)};
ti.prototype.addModeledParts=function(a,b){var c=this,d=this.diagram.model;a.forEach(function(a){d.Pb(a)&&Qp(c,a,!1)});a.forEach(function(a){d.Pb(a)&&c.resolveReferencesForData(a)});!1!==b&&ek(this.diagram,!1)};
function Qp(a,b,c){if(void 0!==b&&null!==b&&!a.diagram.undoManager.isUndoingRedoing&&!a.Me.contains(b)){void 0===c&&(c=!0);a:{if(void 0!==b&&null!==b&&!a.C.undoManager.isUndoingRedoing&&!a.Me.contains(b)){var d=a.Us(b);var e=so(a,b,d);if(null!==e&&(Yg(e),e=e.copy(),null!==e)){var f=a.diagram.skipsModelSourceBindings;a.diagram.skipsModelSourceBindings=!0;e.zf=d;e.jb=b;a.Cq&&(e.Tg="Tool");a.diagram.add(e);e.jb=null;e.data=b;a.diagram.skipsModelSourceBindings=f;d=e;break a}}d=null}null!==d&&c&&a.resolveReferencesForData(b)}}
ti.prototype.insertLink=function(){return null};ti.prototype.resolveReferencesForData=function(){};ti.prototype.Us=function(a){return this.C.model.Us(a)};
function so(a,b,c){a=a.C;var d=a.model;d.fk()&&d.kv(b)?(b=a.groupTemplateMap.J(c),null===b&&(b=a.groupTemplateMap.J(""),null===b&&(Rp||(Rp=!0,za('No Group template found for category "'+c+'"'),za("  Using default group template")),b=a.ow))):(b=a.nodeTemplateMap.J(c),null===b&&(b=a.nodeTemplateMap.J(""),null===b&&(Sp||(Sp=!0,za('No Node template found for category "'+c+'"'),za("  Using default node template")),b=a.qw)));return b}ti.prototype.getLinkCategoryForData=function(){return""};
ti.prototype.setLinkCategoryForData=function(){};ti.prototype.setFromNodeForLink=function(){};ti.prototype.setToNodeForLink=function(){};ti.prototype.findLinkTemplateForCategory=function(a){var b=this.C.linkTemplateMap.J(a);null===b&&(b=this.C.linkTemplateMap.J(""),null===b&&(Tp||(Tp=!0,za('No Link template found for category "'+a+'"'),za("  Using default link template")),b=this.C.pw));return b};ti.prototype.removeAllModeledParts=function(){this.pt(this.diagram.model.nodeDataArray)};
ti.prototype.pt=function(a){var b=this;a.forEach(function(a){b.pq(a)})};ti.prototype.pq=function(a){a=this.vc(a);null!==a&&(Hj(this.diagram,a,!1),this.unresolveReferencesForPart(a))};ti.prototype.unresolveReferencesForPart=function(){};ti.prototype.removeDataForLink=function(){};ti.prototype.findPartForKey=function(a){if(null===a||void 0===a)return null;a=this.C.model.Qb(a);return null!==a?this.Me.J(a):null};t=ti.prototype;
t.Hb=function(a){if(null===a||void 0===a)return null;a=this.C.model.Qb(a);if(null===a)return null;a=this.Me.J(a);return a instanceof V?a:null};t.vc=function(a){if(null===a)return null;var b=this.Me.J(a);return null!==b?b:b=this.Fg.J(a)};t.Gi=function(a){if(null===a)return null;a=this.Me.J(a);return a instanceof V?a:null};t.uc=function(a){return null===a?null:this.Fg.J(a)};
t.Rs=function(a){for(var b=0;b<arguments.length;++b);b=new F;for(var c=this.Eo.iterator;c.next();){var d=c.value,e=d.data;if(null!==e)for(var f=0;f<arguments.length;f++){var g=arguments[f];if(Ca(g)&&Up(this,e,g)){b.add(d);break}}}return b.iterator};t.Qs=function(a){for(var b=0;b<arguments.length;++b);b=new F;for(var c=this.fo.iterator;c.next();){var d=c.value,e=d.data;if(null!==e)for(var f=0;f<arguments.length;f++){var g=arguments[f];if(Ca(g)&&Up(this,e,g)){b.add(d);break}}}return b.iterator};
function Up(a,b,c){for(var d in c){var e=b[d],f=c[d];if(Ea(f)){if(!Ea(e)||e.length<f.length)return!1;for(var g=0;g<e.length;g++){var h=f[g];if(void 0!==h&&!Vp(a,e[g],h))return!1}}else if(!Vp(a,e,f))return!1}return!0}function Vp(a,b,c){if("function"===typeof c){if(!c(b))return!1}else if(c instanceof RegExp){if(!b||!c.test(b.toString()))return!1}else if(Ca(b)&&Ca(c)){if(!Up(a,b,c))return!1}else if(b!==c)return!1;return!0}
ti.prototype.doModelChanged=function(a){if(this.C){var b=this.C;if(a.model===b.model){var c=a.change;b.doModelChanged(a);if(b.ea){b.ea=!1;try{var d=a.modelChange;if(""!==d)if(c===af){if("nodeCategory"===d){var e=this.vc(a.object),f=a.newValue;null!==e&&"string"===typeof f&&(e.category=f)}else"nodeDataArray"===d&&(this.pt(a.oldValue),this.addModeledParts(a.newValue));b.isModified=!0}else if(c===cf){var g=a.newValue;"nodeDataArray"===d&&Ca(g)&&Qp(this,g);b.isModified=!0}else if(c===df){var h=a.oldValue;
"nodeDataArray"===d&&Ca(h)&&this.pq(h);b.isModified=!0}else c===bf&&("SourceChanged"===d?null!==a.object?this.updateDataBindings(a.object,a.propertyName):(this.wq(),this.updateAllTargetBindings()):"ModelDisplaced"===d&&this.ud());else if(c===af){var k=a.propertyName,l=a.object;if(l===b.model){if("nodeKeyProperty"===k||"nodeCategoryProperty"===k)b.undoManager.isUndoingRedoing||this.ud()}else this.updateDataBindings(l,k);b.isModified=!0}else if(c===cf||c===df){var m=a.change===cf,n=m?a.newParam:a.oldParam,
p=m?a.newValue:a.oldValue,q=this.qj.J(a.object);if(Array.isArray(q))for(a=0;a<q.length;a++){var r=q[a];if(m)$m(r,p,n);else if(!(0>n)){var u=n+Tm(r);r.xc(u,!0);dn(r,u,n)}}b.isModified=!0}}finally{b.ea=!0}}}}};ti.prototype.updateAllTargetBindings=function(a){void 0===a&&(a="");for(var b=this.parts.iterator;b.next();)b.value.Ea(a);for(b=this.nodes.iterator;b.next();)b.value.Ea(a);for(b=this.links.iterator;b.next();)b.value.Ea(a)};
ti.prototype.wq=function(){for(var a=this.C.model,b=new F,c=a.nodeDataArray,d=0;d<c.length;d++)b.add(c[d]);var e=[];this.nodes.each(function(a){null===a.data||b.contains(a.data)||e.push(a.data)});this.parts.each(function(a){null===a.data||b.contains(a.data)||e.push(a.data)});e.forEach(function(b){Wp(a,b,!1)});for(d=0;d<c.length;d++){var f=c[d];null===this.vc(f)&&Xp(a,f,!1)}this.refreshDataBoundLinks();for(c=this.parts.iterator;c.next();)c.value.updateRelationshipsFromData();for(c=this.nodes.iterator;c.next();)c.value.updateRelationshipsFromData();
for(c=this.links.iterator;c.next();)c.value.updateRelationshipsFromData()};ti.prototype.refreshDataBoundLinks=function(){};ti.prototype.updateRelationshipsFromData=function(){};
ti.prototype.updateDataBindings=function(a,b){if("string"===typeof b){var c=this.vc(a);if(null!==c)c.Ea(b);else{c=null;for(var d=this.qj.iterator;d.next();){for(var e=d.value,f=0;f<e.length;f++){var g=e[f].ex(a);null!==g&&(null===c&&(c=Ka()),c.push(g))}if(null!==c)break}if(null!==c){for(d=0;d<c.length;d++)c[d].Ea(b);Oa(c)}}a===this.diagram.model.modelData&&this.updateAllTargetBindings(b)}};
function Dj(a,b){var c=b.fi;if(Ea(c)){var d=a.qj.J(c);if(null===d)d=[],d.push(b),a.qj.add(c,d);else{for(a=0;a<d.length;a++)if(d[a]===b)return;d.push(b)}}}function Gj(a,b,c){Bj(b,function(a){a=a.Y.j;for(var b=a.length,d=0;d<b;d++)ck(c,a[d])});var d=b.fi;if(Ea(d)){var e=a.qj.J(d);if(null!==e)for(var f=0;f<e.length;f++)if(e[f]===b){e.splice(f,1);0===e.length&&a.qj.remove(d);break}}}
ti.prototype.Zj=function(a,b,c){var d=new Lb;if(Ea(a))for(var e=0;e<a.length;e++)Yp(this,a[e],b,d,c);else for(a=a.iterator;a.next();)Yp(this,a.value,b,d,c);if(null!==b){c=b.model;a=b.toolManager.findTool("Dragging");a=null!==a?a.dragOptions.dragsLink:b.Mk.dragsLink;e=new F;for(var f=new Lb,g=d.iterator;g.next();){var h=g.value;if(h instanceof S)a||null!==h.fromNode&&null!==h.toNode||e.add(h);else if(h instanceof V&&null!==h.data&&c.fm()){var k=h;h=g.key;var l=h.pg();null!==l&&(l=d.J(l),null!==l?(c.He(k.data,
c.pa(l.data)),k=b.uc(k.data),h=h.Ji(),null!==h&&null!==k&&f.add(h,k)):c.He(k.data,void 0))}}0<e.count&&b.qt(e,!1);if(0<f.count)for(c=f.iterator;c.next();)d.add(c.key,c.value)}if(null!==b&&null!==this.C&&(b=b.model,c=b.afterCopyFunction,null!==c)){var m=new Lb;d.each(function(a){null!==a.key.data&&m.add(a.key.data,a.value.data)});c(m,b,this.C.model)}for(b=d.iterator;b.next();)b.value.Ea();return d};
function Yp(a,b,c,d,e){if(null===b||e&&!b.canCopy())return null;if(d.contains(b))return d.J(b);var f=a.copyPartData(b,c);if(!(f instanceof T))return null;f.isSelected=!1;f.isHighlighted=!1;d.add(b,f);if(b instanceof V){for(var g=b.linksConnected;g.next();){var h=g.value;if(h.fromNode===b){var k=d.J(h);null!==k&&(k.fromNode=f)}h.toNode===b&&(h=d.J(h),null!==h&&(h.toNode=f))}if(b instanceof ig&&f instanceof ig)for(b=b.memberParts;b.next();)g=Yp(a,b.value,c,d,e),g instanceof S||null===g||(g.containingGroup=
f)}else if(b instanceof S&&f instanceof S)for(g=b.fromNode,null!==g&&(g=d.J(g),null!==g&&(f.fromNode=g)),g=b.toNode,null!==g&&(g=d.J(g),null!==g&&(f.toNode=g)),b=b.labelNodes;b.next();)g=Yp(a,b.value,c,d,e),null!==g&&g instanceof V&&(g.labeledLink=f);return f}
ti.prototype.copyPartData=function(a,b){var c=null,d=a.data;if(null!==d&&null!==b){var e=b.model;a instanceof S||(d=e.copyNodeData(d),Ca(d)&&(e.jf(d),c=b.vc(d)))}else Yg(a),c=a.copy(),null!==c&&(e=this.C,null!==b?b.add(c):null!==d&&null!==e&&null!==e.commandHandler&&e.commandHandler.copiesClipboardData&&(b=e.model,e=null,c instanceof S||(e=b.copyNodeData(d)),Ca(e)&&(c.data=e)));return c};
pa.Object.defineProperties(ti.prototype,{nodes:{get:function(){return this.Eo}},links:{get:function(){return this.fo}},parts:{get:function(){return this.Fa}},diagram:{get:function(){return this.C}},addsToTemporaryLayer:{get:function(){return this.Cq},set:function(a){this.Cq=a}}});ti.prototype.updateAllRelationshipsFromData=ti.prototype.wq;
ti.prototype.findLinksByExample=ti.prototype.Qs;ti.prototype.findNodesByExample=ti.prototype.Rs;ti.prototype.findLinkForData=ti.prototype.uc;ti.prototype.findNodeForData=ti.prototype.Gi;ti.prototype.findPartForData=ti.prototype.vc;ti.prototype.findNodeForKey=ti.prototype.Hb;ti.prototype.removeModeledPart=ti.prototype.pq;ti.prototype.removeModeledParts=ti.prototype.pt;ti.prototype.rebuildParts=ti.prototype.ud;var Sp=!1,Rp=!1,Tp=!1;ti.className="PartManager";
function Zp(a){ti.apply(this,arguments)}oa(Zp,ti);Zp.prototype.addAllModeledParts=function(){var a=this.diagram.model;this.addModeledParts(a.nodeDataArray);$p(this,a.linkDataArray)};Zp.prototype.addModeledParts=function(a){ti.prototype.addModeledParts.call(this,a,!1);for(a=this.links.iterator;a.next();)to(a.value);ek(this.diagram,!1)};function $p(a,b){b.forEach(function(b){aq(a,b)});ek(a.diagram,!1)}
function aq(a,b){if(void 0!==b&&null!==b&&!a.diagram.undoManager.isUndoingRedoing&&!a.Fg.contains(b)){var c=a.getLinkCategoryForData(b),d=a.findLinkTemplateForCategory(c);if(null!==d){Yg(d);var e=d.copy();if(null!==e){d=a.diagram.skipsModelSourceBindings;a.diagram.skipsModelSourceBindings=!0;e.zf=c;e.jb=b;c=a.diagram.model;var f=bq(c,b,!0);""!==f&&(e.fromPortId=f);f=cq(c,b,!0);void 0!==f&&(f=a.Hb(f),f instanceof V&&(e.fromNode=f));f=bq(c,b,!1);""!==f&&(e.toPortId=f);f=cq(c,b,!1);void 0!==f&&(f=a.Hb(f),
f instanceof V&&(e.toNode=f));c=c.qg(b);Array.isArray(c)&&c.forEach(function(b){b=a.Hb(b);null!==b&&(b.labeledLink=e)});a.Cq&&(e.Tg="Tool");a.diagram.add(e);e.jb=null;e.data=b;a.diagram.skipsModelSourceBindings=d}}}}Zp.prototype.removeAllModeledParts=function(){var a=this.diagram.model;dq(this,a.linkDataArray);this.pt(a.nodeDataArray)};function dq(a,b){b.forEach(function(b){a.pq(b)})}Zp.prototype.getLinkCategoryForData=function(a){return this.diagram.model.av(a)};
Zp.prototype.setLinkCategoryForData=function(a,b){return this.diagram.model.st(a,b)};Zp.prototype.setFromNodeForLink=function(a,b){var c=this.diagram.model;c.Bx(a.data,c.pa(null!==b?b.data:null))};Zp.prototype.setToNodeForLink=function(a,b){var c=this.diagram.model;c.Gx(a.data,c.pa(null!==b?b.data:null))};Zp.prototype.removeDataForLink=function(a){this.diagram.model.nm(a.data)};
Zp.prototype.findPartForKey=function(a){var b=ti.prototype.findPartForKey.call(this,a);return null===b&&(a=this.diagram.model.xh(a),null!==a)?this.Fg.J(a):b};
Zp.prototype.doModelChanged=function(a){var b=this;ti.prototype.doModelChanged.call(this,a);if(this.diagram){var c=this.diagram;if(a.model===c.model){var d=a.change;if(c.ea){c.ea=!1;try{var e=a.modelChange;if(""!==e)if(d===af){if("linkFromKey"===e){var f=this.uc(a.object);if(null!==f){var g=this.Hb(a.newValue);f.fromNode=g}}else if("linkToKey"===e){var h=this.uc(a.object);if(null!==h){var k=this.Hb(a.newValue);h.toNode=k}}else if("linkFromPortId"===e){var l=this.uc(a.object);if(null!==l){var m=a.newValue;
"string"===typeof m&&(l.fromPortId=m)}}else if("linkToPortId"===e){var n=this.uc(a.object);if(null!==n){var p=a.newValue;"string"===typeof p&&(n.toPortId=p)}}else if("nodeGroupKey"===e){var q=this.vc(a.object);if(null!==q){var r=a.newValue;if(void 0!==r){var u=this.Hb(r);u instanceof ig?q.containingGroup=u:q.containingGroup=null}else q.containingGroup=null}}else if("linkLabelKeys"===e){var x=this.uc(a.object);if(null!==x){var v=a.oldValue,y=a.newValue;Array.isArray(v)&&v.forEach(function(a){a=b.Hb(a);
null!==a&&(a.labeledLink=null)});Array.isArray(y)&&y.forEach(function(a){a=b.Hb(a);null!==a&&(a.labeledLink=x)})}}else if("linkCategory"===e){var z=this.uc(a.object),B=a.newValue;null!==z&&"string"===typeof B&&(z.category=B)}else"linkDataArray"===e&&(dq(this,a.oldValue),$p(this,a.newValue));c.isModified=!0}else if(d===cf){var C=a.newValue;if("linkDataArray"===e&&"object"===typeof C&&null!==C)aq(this,C);else if("linkLabelKeys"===e&&eq(C)){var G=this.uc(a.object),K=this.Hb(C);null!==G&&null!==K&&(K.labeledLink=
G)}c.isModified=!0}else{if(d===df){var M=a.oldValue;if("linkDataArray"===e&&"object"===typeof M&&null!==M)this.pq(M);else if("linkLabelKeys"===e&&eq(M)){var U=this.Hb(M);null!==U&&(U.labeledLink=null)}c.isModified=!0}}else if(d===af){var R=a.propertyName,da=a.object;if(da===c.model){if("linkFromKeyProperty"===R||"linkToKeyProperty"===R||"linkFromPortIdProperty"===R||"linkToPortIdProperty"===R||"linkLabelKeysProperty"===R||"nodeIsGroupProperty"===R||"nodeGroupKeyProperty"===R||"linkCategoryProperty"===
R)c.undoManager.isUndoingRedoing||this.ud()}else this.updateDataBindings(da,R);c.isModified=!0}}finally{c.ea=!0}}}}};Zp.prototype.refreshDataBoundLinks=function(){var a=this,b=this.diagram.model,c=new F,d=b.linkDataArray;d.forEach(function(a){c.add(a)});var e=[];this.links.each(function(a){null===a.data||c.contains(a.data)||e.push(a.data)});e.forEach(function(a){fq(b,a,!1)});d.forEach(function(c){null===a.uc(c)&&gq(b,c,!1)})};
Zp.prototype.updateRelationshipsFromData=function(a){var b=a.data;if(null!==b){var c=a.diagram;if(null!==c){var d=c.model;if(a instanceof S){var e=cq(d,b,!0);e=c.Hb(e);a.fromNode=e;e=cq(d,b,!1);e=c.Hb(e);a.toNode=e;b=d.qg(b);if(0<b.length||0<a.labelNodes.count){if(1===b.length&&1===a.labelNodes.count){e=b[0];var f=a.labelNodes.first();if(d.pa(f.data)===e)return}e=(new F).addAll(b);var g=new F;a.labelNodes.each(function(a){null!==a.data&&(a=d.pa(a.data),void 0!==a&&g.add(a))});b=g.copy();b.oq(e);e=
e.copy();e.oq(g);if(0<b.count||0<e.count)b.each(function(b){b=c.Hb(b);null!==b&&b.labeledLink===a&&(b.labeledLink=null)}),e.each(function(b){b=c.Hb(b);null!==b&&b.labeledLink!==a&&(b.labeledLink=a)})}}else!(a instanceof pf)&&(b=d.Mi(b),b=c.findPartForKey(b),null===b||b instanceof ig)&&(a.containingGroup=b)}}};
Zp.prototype.resolveReferencesForData=function(a){var b=this.diagram.model,c=b.pa(a);if(void 0!==c){var d=hq(b,c),e=this.vc(a);if(null!==d&&null!==e){d=d.iterator;for(var f={};d.next();){var g=d.value;b.Pb(g)?e instanceof ig&&b.Mi(g)===c&&(g=this.vc(g),null!==g&&(g.containingGroup=e)):(f.link=this.uc(g),null!==f.link&&e instanceof V&&(cq(b,g,!0)===c&&(f.link.fromNode=e),cq(b,g,!1)===c&&(f.link.toNode=e),g=b.qg(g),Array.isArray(g)&&g.some(function(a){return function(b){return b===c?(e.labeledLink=
a.link,!0):!1}}(f))));f={link:f.link}}iq(b,c)}a=b.Mi(a);void 0!==a&&(a=this.Hb(a),a instanceof ig&&(e.containingGroup=a))}};Zp.prototype.unresolveReferencesForPart=function(a){var b=this.diagram.model;if(a instanceof V){var c=b.pa(a.data);if(void 0!==c){for(var d=a.linksConnected;d.next();)jq(b,c,d.value.data);a.isLinkLabel&&(d=a.labeledLink,null!==d&&jq(b,c,d.data));if(a instanceof ig)for(a=a.memberParts;a.next();)d=a.value.data,b.Pb(d)&&jq(b,c,d)}}};
Zp.prototype.copyPartData=function(a,b){var c=ti.prototype.copyPartData.call(this,a,b);if(a instanceof S)if(a=a.data,null!==a&&null!==b){var d=b.model;a=d.Rp(a);"object"===typeof a&&null!==a&&(d.Ci(a),c=b.uc(a))}else null!==c&&(b=this.diagram,null!==a&&null!==b&&null!==b.commandHandler&&b.commandHandler.copiesClipboardData&&(b=b.model.Rp(a),"object"===typeof b&&null!==b&&(c.data=b)));return c};
Zp.prototype.insertLink=function(a,b,c,d){var e=this.diagram,f=e.model,g=e.toolManager.findTool("Linking"),h="";null!==a&&(null===b&&(b=a),h=b.portId,null===h&&(h=""));b="";null!==c&&(null===d&&(d=c),b=d.portId,null===b&&(b=""));d=g.archetypeLinkData;if(d instanceof S){if(Yg(d),f=d.copy(),null!==f)return f.fromNode=a,f.fromPortId=h,f.toNode=c,f.toPortId=b,e.add(f),a=g.archetypeLabelNodeData,a instanceof V&&(Yg(a),a=a.copy(),null!==a&&(a.labeledLink=f,e.add(a))),f}else if(null!==d&&(d=f.Rp(d),"object"===
typeof d&&null!==d))return null!==a&&kq(f,d,f.pa(a.data),!0),lq(f,d,h,!0),null!==c&&kq(f,d,f.pa(c.data),!1),lq(f,d,b,!1),f.Ci(d),a=g.archetypeLabelNodeData,null===a||a instanceof V||(a=f.copyNodeData(a),"object"===typeof a&&null!==a&&(f.jf(a),a=f.pa(a),void 0!==a&&f.Fu(d,a))),e.uc(d);return null};Zp.prototype.findPartForKey=Zp.prototype.findPartForKey;Zp.prototype.removeAllModeledParts=Zp.prototype.removeAllModeledParts;Zp.prototype.addModeledParts=Zp.prototype.addModeledParts;
Zp.prototype.addAllModeledParts=Zp.prototype.addAllModeledParts;Zp.className="GraphLinksPartManager";function mq(){ti.apply(this,arguments);this.ah=null}oa(mq,ti);
function nq(a,b,c){if(null!==b&&null!==c){var d=a.diagram.toolManager.findTool("Linking"),e=b,f=c;if(a.diagram.isTreePathToChildren)for(b=f.linksConnected;b.next();){if(b.value.toNode===f)return}else for(e=c,f=b,b=e.linksConnected;b.next();)if(b.value.fromNode===e)return;if(null===d||!vg(d,e,f,null,!0))if(d=a.getLinkCategoryForData(c.data),b=a.findLinkTemplateForCategory(d),null!==b&&(Yg(b),b=b.copy(),null!==b)){var g=a.diagram.skipsModelSourceBindings;a.diagram.skipsModelSourceBindings=!0;b.zf=d;
b.jb=c.data;b.fromNode=e;b.toNode=f;a.diagram.add(b);b.jb=null;b.data=c.data;a.diagram.skipsModelSourceBindings=g}}}mq.prototype.getLinkCategoryForData=function(a){return this.diagram.model.bv(a)};mq.prototype.setLinkCategoryForData=function(a,b){this.diagram.model.Iv(a,b)};
mq.prototype.setFromNodeForLink=function(a,b,c){var d=this.diagram.model;void 0===c&&(c=null);b=null!==b?b.data:null;if(this.diagram.isTreePathToChildren)d.He(a.data,d.pa(b));else{var e=this.ah;this.ah=a;null!==c&&d.He(c.data,void 0);d.He(b,d.pa(null!==a.toNode?a.toNode.data:null));this.ah=e}};
mq.prototype.setToNodeForLink=function(a,b,c){var d=this.diagram.model;void 0===c&&(c=null);b=null!==b?b.data:null;if(this.diagram.isTreePathToChildren){var e=this.ah;this.ah=a;null!==c&&d.He(c.data,void 0);d.He(b,d.pa(null!==a.fromNode?a.fromNode.data:null));this.ah=e}else d.He(a.data,d.pa(b))};mq.prototype.removeDataForLink=function(a){this.diagram.model.He(a.data,void 0)};
mq.prototype.doModelChanged=function(a){ti.prototype.doModelChanged.call(this,a);if(this.diagram){var b=this.diagram;if(a.model===b.model){var c=a.change;if(b.ea){b.ea=!1;try{var d=a.modelChange;if(""!==d){if(c===af){if("nodeParentKey"===d){var e=a.object,f=this.Hb(a.newValue),g=this.Gi(e);if(null!==this.ah)null!==f&&(this.ah.data=e,this.ah.category=this.getLinkCategoryForData(e));else if(null!==g){var h=g.Ji();null!==h?null===f?b.remove(h):b.isTreePathToChildren?h.fromNode=f:h.toNode=f:nq(this,f,
g)}}else if("parentLinkCategory"===d){var k=this.Gi(a.object),l=a.newValue;if(null!==k&&"string"===typeof l){var m=k.Ji();null!==m&&(m.category=l)}}b.isModified=!0}}else if(c===af){var n=a.propertyName,p=a.object;p===b.model?"nodeParentKeyProperty"===n&&(b.undoManager.isUndoingRedoing||this.ud()):this.updateDataBindings(p,n);b.isModified=!0}}finally{b.ea=!0}}}}};
mq.prototype.updateRelationshipsFromData=function(a){var b=a.data;if(null!==b){var c=a.diagram;if(null!==c){var d=c.model;a instanceof V&&(b=d.Oi(b),b=c.Hb(b),d=a.pg(),b!==d&&(d=a.Ji(),null!==b?null!==d?c.isTreePathToChildren?d.fromNode=b:d.toNode=b:nq(this,b,a):null!==d&&Hj(c,d,!1)))}}};mq.prototype.updateDataBindings=function(a,b){ti.prototype.updateDataBindings.call(this,a,b);"string"===typeof b&&null!==this.vc(a)&&(a=this.uc(a),null!==a&&a.Ea(b))};
mq.prototype.resolveReferencesForData=function(a){var b=this.diagram.model,c=b.pa(a);if(void 0!==c){var d=hq(b,c),e=this.vc(a);if(null!==d&&null!==e){for(d=d.iterator;d.next();){var f=d.value;b.Pb(f)&&e instanceof V&&b.Oi(f)===c&&nq(this,e,this.Gi(f))}iq(b,c)}a=b.Oi(a);void 0!==a&&e instanceof V&&(a=this.Hb(a),nq(this,a,e))}};
mq.prototype.unresolveReferencesForPart=function(a){var b=this.diagram.model;if(a instanceof V){var c=b.pa(a.data),d=this.uc(a.data);if(null!==d){d.isSelected=!1;d.isHighlighted=!1;var e=d.layer;if(null!==e){var f=e.xc(-1,d,!1);0<=f&&this.diagram.ab(df,"parts",e,d,null,f,null);f=d.layerChanged;null!==f&&f(d,e,null)}}d=this.diagram.isTreePathToChildren;for(a=a.linksConnected;a.next();)e=a.value,e=(d?e.toNode:e.fromNode).data,b.Pb(e)&&jq(b,c,e)}};
mq.prototype.insertLink=function(a,b,c){b=this.diagram.model;var d=a,e=c;this.diagram.isTreePathToChildren||(d=c,e=a);return null!==d&&null!==e?(b.He(e.data,b.pa(d.data)),e.Ji()):null};mq.className="TreePartManager";
function Z(a){this.Dt=',\n  "insertedNodeKeys": ';this.ew=',\n  "modifiedNodeData": ';this.Ft=',\n  "removedNodeKeys": ';nb(this);this.ln=this.Ua="";this.Mf=!1;this.l={};this.Fc=[];this.cb=new Lb;this.mi="key";this.Jk=this.ol=null;this.an=this.bn=!1;this.dn=!0;this.Mm=null;this.yj="category";this.Ff=new Lb;this.eu=new E;this.eh=!1;this.u=null;this.undoManager=new ff;void 0!==a&&(this.nodeDataArray=a)}
Z.prototype.cloneProtected=function(a){a.Ua=this.Ua;a.ln=this.ln;a.Mf=this.Mf;a.mi=this.mi;a.ol=this.ol;a.Jk=this.Jk;a.bn=this.bn;a.an=this.an;a.dn=this.dn;a.Mm=this.Mm;a.yj=this.yj};Z.prototype.copy=function(){var a=new this.constructor;this.cloneProtected(a);return a};t=Z.prototype;t.clear=function(){this.Fc=[];this.cb.clear();this.Ff.clear();this.undoManager.clear()};
t.toString=function(a){void 0===a&&(a=0);if(1<a)return this.uq();var b=(""!==this.name?this.name:"")+" Model";if(0<a){b+="\n node data:";a=this.nodeDataArray;for(var c=a.length,d=0;d<c;d++){var e=a[d];b+=" "+this.pa(e)+":"+Qa(e)}}return b};
t.uk=function(){var a="";""!==this.name&&(a+=',\n  "name": '+this.quote(this.name));""!==this.dataFormat&&(a+=',\n  "dataFormat": '+this.quote(this.dataFormat));this.isReadOnly&&(a+=',\n  "isReadOnly": '+this.isReadOnly);"key"!==this.nodeKeyProperty&&"string"===typeof this.nodeKeyProperty&&(a+=',\n  "nodeKeyProperty": '+this.quote(this.nodeKeyProperty));this.copiesArrays&&(a+=',\n  "copiesArrays": true');this.copiesArrayObjects&&(a+=',\n  "copiesArrayObjects": true');this.copiesKey||(a+=',\n  "copiesKey": false');
"category"!==this.nodeCategoryProperty&&"string"===typeof this.nodeCategoryProperty&&(a+=',\n  "nodeCategoryProperty": '+this.quote(this.nodeCategoryProperty));return a};
t.nq=function(a){a.name&&(this.name=a.name);a.dataFormat&&(this.dataFormat=a.dataFormat);a.isReadOnly&&(this.isReadOnly=!0);a.nodeKeyProperty&&(this.nodeKeyProperty=a.nodeKeyProperty);a.copiesArrays&&(this.copiesArrays=!0);a.copiesArrayObjects&&(this.copiesArrayObjects=!0);!1===a.copiesKey&&(this.copiesKey=!1);a.nodeCategoryProperty&&(this.nodeCategoryProperty=a.nodeCategoryProperty)};function oq(a){return',\n  "modelData": '+pq(a,a.modelData)}
function qq(a,b){b=b.modelData;Ca(b)&&(a.pm(b),a.modelData=b)}t.$v=function(){var a=this.modelData,b=!1,c;for(c in a)if(!rq(c,a[c])){b=!0;break}a="";b&&(a=oq(this));return a+',\n  "nodeDataArray": '+sq(this,this.nodeDataArray,!0)};t.wv=function(a){qq(this,a);a=a.nodeDataArray;Ea(a)&&(this.pm(a),this.nodeDataArray=a)};
function tq(a,b,c,d){if(b===c)return!0;if(typeof b!==typeof c||"function"===typeof b||"function"===typeof c)return!1;if(Array.isArray(b)&&Array.isArray(c)){if(d.J(b)===c)return!0;d.add(b,c);if(b.length!==c.length)return!1;for(var e=0;e<b.length;e++)if(!tq(a,b[e],c[e],d))return!1;return!0}if(Ca(b)&&Ca(c)){if(d.J(b)===c)return!0;d.add(b,c);for(var f in b){var g=b[f];if(!rq(f,g)){var h=c[f];if(void 0===h||!tq(a,g,h,d))return!1}}for(e in c)if(f=c[e],!rq(e,f)&&(g=b[e],void 0===g||!tq(a,g,f,d)))return!1;
return!0}return!1}function uq(a,b,c){a[c]!==b[c]&&A("Model.computeJsonDifference: Model."+c+' is not the same in both models: "'+a[c]+'" and "'+b[c]+'"')}
t.yq=function(a){uq(this,a,"nodeKeyProperty");for(var b=new F,c=new F,d=(new F).addAll(this.cb.iteratorKeys),e=new Lb,f=a.nodeDataArray,g=f.length,h=0;h<g;h++){var k=f[h],l=a.pa(k);if(void 0!==l){d.remove(l);var m=this.Qb(l);null===m?(b.add(l),c.add(k)):tq(this,m,k,e)||c.add(k)}else this.gt(k),l=this.pa(k),b.add(l),c.add(k)}f="";tq(this,this.modelData,a.modelData,e)||(f+=oq(this));0<b.count&&(f+=this.Dt+sq(this,b.Na(),!0));0<c.count&&(f+=this.ew+sq(this,c.Na(),!0));0<d.count&&(f+=this.Ft+sq(this,
d.Na(),!0));return f};t.py=function(a,b){void 0===b&&(b=vq(this,this));return'{ "class": '+this.quote(b)+', "incremental": 1'+this.uk()+this.yq(a)+"}"};
t.yt=function(a,b){var c=this,d=!1,e=new F,f=new F,g=new F;a.changes.each(function(a){a.model===c&&("nodeDataArray"===a.modelChange?a.change===cf?e.add(a.newValue):a.change===df&&g.add(a.oldValue):c.Pb(a.object)?f.add(a.object):c.modelData===a.object&&a.change===af&&(d=!0))});var h=new F;e.each(function(a){h.add(c.pa(a));b||f.add(a)});var k=new F;g.each(function(a){k.add(c.pa(a));b&&f.add(a)});a="";d&&(a+=oq(this));0<h.count&&(a+=(b?this.Ft:this.Dt)+sq(this,h.Na(),!0));0<f.count&&(a+=this.ew+sq(this,
f.Na(),!0));0<k.count&&(a+=(b?this.Dt:this.Ft)+sq(this,k.Na(),!0));return a};
t.vv=function(a){qq(this,a);var b=a.insertedNodeKeys,c=a.modifiedNodeData,d=new Lb;if(Array.isArray(c))for(var e=0;e<c.length;e++){var f=c[e],g=this.pa(f);void 0!==g&&null!==g&&d.set(g,f)}if(Array.isArray(b))for(e=b.length,f=0;f<e;f++){g=b[f];var h=this.Qb(g);null===h&&(h=(h=d.get(g))?h:this.copyNodeData({}),this.vm(h,g),this.jf(h))}if(Array.isArray(c))for(b=c.length,d=0;d<b;d++)if(e=c[d],f=this.pa(e),f=this.Qb(f),null!==f)for(var k in e)"__gohashid"===k||k===this.nodeKeyProperty||this.ek()&&k===
this.nodeIsGroupProperty||this.setDataProperty(f,k,e[k]);a=a.removedNodeKeys;if(Array.isArray(a))for(c=a.length,k=0;k<c;k++)b=this.Qb(a[k]),null!==b&&this.om(b)};
t.Kx=function(a,b){a.change!==bf&&A("Model.toIncrementalJson argument is not a Transaction ChangedEvent:"+a.toString());var c=a.object;if(!(a.isTransactionFinished&&c instanceof ef))return'{ "incremental": 0 }';void 0===b&&(b=vq(this,this));return"2"===b?'{ "incremental": 2'+this.yt(c,"FinishedUndo"===a.propertyName)+"}":'{ "class": '+this.quote(b)+', "incremental": 1'+this.uk()+this.yt(c,"FinishedUndo"===a.propertyName)+"}"};t.Uz=function(a,b){return this.Kx(a,b)};
t.uq=function(a){void 0===a&&(a=vq(this,this));return'{ "class": '+this.quote(a)+this.uk()+this.$v()+"}"};t.toJSON=function(a){return this.uq(a)};t.Sw=function(a){var b=null;if("string"===typeof a)try{b=w.JSON.parse(a)}catch(d){}else"object"===typeof a?b=a:A("Unable to modify a Model from: "+a);var c=b.incremental;"number"!==typeof c&&A("Unable to apply non-incremental changes to Model: "+a);0!==c&&(this.Aa("applyIncrementalJson"),this.vv(b),this.Za("applyIncrementalJson"))};t.jy=function(a){return this.Sw(a)};
Z.constructGraphLinksModel=function(){return new Z};t=Z.prototype;
t.pm=function(a){if(Ea(a))for(var b=a.length,c=0;c<b;c++){var d=a[c];if(Ca(d)){var e=c;d=this.pm(d);Array.isArray(a)?a[e]=d:A("Cannot replace an object in an HTMLCollection or NodeList at "+e)}}else if(Ca(a)){for(c in a)if(e=a[c],Ca(e)&&(e=this.pm(e),a[c]=e,"points"===c&&Array.isArray(e))){d=0===e.length%2;for(var f=0;f<e.length;f++)if("number"!==typeof e[f]){d=!1;break}if(d){d=new E;for(f=0;f<e.length/2;f++)d.add(new H(e[2*f],e[2*f+1]));d.freeze();a[c]=d}}if("object"===typeof a){c=a;e=a["class"];
if("NaN"===e)c=NaN;else if("Date"===e)c=new Date(a.value);else if("go.Point"===e)c=new H(wq(a.x),wq(a.y));else if("go.Size"===e)c=new L(wq(a.width),wq(a.height));else if("go.Rect"===e)c=new N(wq(a.x),wq(a.y),wq(a.width),wq(a.height));else if("go.Margin"===e)c=new Ic(wq(a.top),wq(a.right),wq(a.bottom),wq(a.left));else if("go.Spot"===e)"string"===typeof a["enum"]?c=Xd(a["enum"]):c=new O(wq(a.x),wq(a.y),wq(a.offsetX),wq(a.offsetY));else if("go.Brush"===e){if(c=new il,c.type=qb(il,a.type),"string"===
typeof a.color&&(c.color=a.color),a.start instanceof O&&(c.start=a.start),a.end instanceof O&&(c.end=a.end),"number"===typeof a.startRadius&&(c.startRadius=wq(a.startRadius)),"number"===typeof a.endRadius&&(c.endRadius=wq(a.endRadius)),a=a.colorStops,Ca(a))for(b in a)c.addColorStop(parseFloat(b),a[b])}else"go.Geometry"===e?(b=null,"string"===typeof a.path?b=me(a.path):b=new $d,b.type=qb($d,a.type),"number"===typeof a.startX&&(b.startX=wq(a.startX)),"number"===typeof a.startY&&(b.startY=wq(a.startY)),
"number"===typeof a.endX&&(b.endX=wq(a.endX)),"number"===typeof a.endY&&(b.endY=wq(a.endY)),a.spot1 instanceof O&&(b.spot1=a.spot1),a.spot2 instanceof O&&(b.spot2=a.spot2),c=b):"go.EnumValue"===e&&(b=a.classType,0===b.indexOf("go.")&&(b=b.substr(3)),c=qb(xq[b]?xq[b]:null,a.name));a=c}}return a};
t.quote=function(a){for(var b="",c=a.length,d=0;d<c;d++){var e=a[d];if('"'===e||"\\"===e)b+="\\"+e;else if("\b"===e)b+="\\b";else if("\f"===e)b+="\\f";else if("\n"===e)b+="\\n";else if("\r"===e)b+="\\r";else if("\t"===e)b+="\\t";else{var f=a.charCodeAt(d);b=16>f?b+("\\u000"+a.charCodeAt(d).toString(16)):32>f?b+("\\u00"+a.charCodeAt(d).toString(16)):8232===f?b+"\\u2028":8233===f?b+"\\u2029":b+e}}return'"'+b+'"'};
t.zm=function(a){return void 0===a?"undefined":null===a?"null":!0===a?"true":!1===a?"false":"string"===typeof a?this.quote(a):"number"===typeof a?Infinity===a?"9e9999":-Infinity===a?"-9e9999":isNaN(a)?'{"class":"NaN"}':a.toString():a instanceof Date?'{"class":"Date", "value":"'+a.toJSON()+'"}':a instanceof Number?this.zm(a.valueOf()):Ea(a)?sq(this,a):Ca(a)?pq(this,a):"function"===typeof a?"null":a.toString()};
function sq(a,b,c){void 0===c&&(c=!1);var d=b.length;if(0>=d)return"[]";var e=new sb;e.add("[ ");c&&1<d&&e.add("\n");for(var f=0;f<d;f++){var g=b[f];void 0!==g&&(0<f&&(e.add(","),c&&e.add("\n")),e.add(a.zm(g)))}c&&1<d&&e.add("\n");e.add(" ]");return e.toString()}function rq(a,b){return void 0===b||"__gohashid"===a||"_"===a[0]||"function"===typeof b?!0:!1}function yq(a){return isNaN(a)?"NaN":Infinity===a?"9e9999":-Infinity===a?"-9e9999":a}
function pq(a,b){var c=b;if(c instanceof H)b={"class":"go.Point",x:yq(c.x),y:yq(c.y)};else if(c instanceof L)b={"class":"go.Size",width:yq(c.width),height:yq(c.height)};else if(c instanceof N)b={"class":"go.Rect",x:yq(c.x),y:yq(c.y),width:yq(c.width),height:yq(c.height)};else if(c instanceof Ic)b={"class":"go.Margin",top:yq(c.top),right:yq(c.right),bottom:yq(c.bottom),left:yq(c.left)};else if(c instanceof O)c.gb()?b={"class":"go.Spot",x:yq(c.x),y:yq(c.y),offsetX:yq(c.offsetX),offsetY:yq(c.offsetY)}:
b={"class":"go.Spot","enum":c.toString()};else if(c instanceof il){b={"class":"go.Brush",type:c.type.name};if(c.type===ll)b.color=c.color;else if(c.type===ol||c.type===jl)b.start=c.start,b.end=c.end,c.type===jl&&(0!==c.startRadius&&(b.startRadius=yq(c.startRadius)),isNaN(c.endRadius)||(b.endRadius=yq(c.endRadius)));if(null!==c.colorStops){var d={};for(c=c.colorStops.iterator;c.next();)d[c.key]=c.value;b.colorStops=d}}else c instanceof $d?(b={"class":"go.Geometry",type:c.type.name},0!==c.startX&&(b.startX=
yq(c.startX)),0!==c.startY&&(b.startY=yq(c.startY)),0!==c.endX&&(b.endX=yq(c.endX)),0!==c.endY&&(b.endY=yq(c.endY)),c.spot1.A(Tc)||(b.spot1=c.spot1),c.spot2.A(hd)||(b.spot2=c.spot2),c.type===ae&&(b.path=je(c))):c instanceof D&&(b={"class":"go.EnumValue",classType:vq(a,c.classType),name:c.name});d="{";c=!0;for(var e in b){var f=en(b,e);if(!rq(e,f))if(c?c=!1:d+=", ",d+='"'+e+'":',"points"===e&&f instanceof E){var g="[";for(f=f.iterator;f.next();){var h=f.value;1<g.length&&(g+=",");g+=a.zm(h.x);g+=",";
g+=a.zm(h.y)}g+="]";d+=g}else d+=a.zm(f)}return d+"}"}function wq(a){return"number"===typeof a?a:"NaN"===a?NaN:"9e9999"===a?Infinity:"-9e9999"===a?-Infinity:parseFloat(a)}t.rh=function(a){this.eu.add(a)};t.rk=function(a){this.eu.remove(a)};t.Ks=function(a){this.skipsUndoManager||this.undoManager.fv(a);for(var b=this.eu,c=b.length,d=0;d<c;d++)b.N(d)(a)};t.ab=function(a,b,c,d,e,f,g){zq(this,"",a,b,c,d,e,f,g)};t.g=function(a,b,c,d,e){zq(this,"",af,a,this,b,c,d,e)};
t.lt=function(a,b,c,d,e,f){zq(this,"",af,b,a,c,d,e,f)};function zq(a,b,c,d,e,f,g,h,k){void 0===h&&(h=null);void 0===k&&(k=null);var l=new $e;l.model=a;l.change=c;l.modelChange=b;l.propertyName=d;l.object=e;l.oldValue=f;l.oldParam=h;l.newValue=g;l.newParam=k;a.Ks(l)}
t.Tj=function(a,b){if(null!==a&&a.model===this)if(a.change===af)Oj(a.object,a.propertyName,a.J(b));else if(a.change===cf){var c=a.newParam;if("nodeDataArray"===a.modelChange){if(a=a.newValue,Ca(a)&&"number"===typeof c){var d=this.pa(a);b?(this.Fc[c]===a&&Ja(this.Fc,c),void 0!==d&&this.cb.remove(d)):(this.Fc[c]!==a&&Ia(this.Fc,c,a),void 0!==d&&this.cb.add(d,a))}}else""===a.modelChange?((d=a.object)&&!Ea(d)&&a.propertyName&&(d=en(a.object,a.propertyName)),Ea(d)&&"number"===typeof c&&(a=a.newValue,b?
Ja(d,c):Ia(d,c,a))):A("unknown ChangedEvent.Insert modelChange: "+a.toString())}else a.change===df?(c=a.oldParam,"nodeDataArray"===a.modelChange?(a=a.oldValue,Ca(a)&&"number"===typeof c&&(d=this.pa(a),b?(this.Fc[c]!==a&&Ia(this.Fc,c,a),void 0!==d&&this.cb.add(d,a)):(this.Fc[c]===a&&Ja(this.Fc,c),void 0!==d&&this.cb.remove(d)))):""===a.modelChange?((d=a.object)&&!Ea(d)&&a.propertyName&&(d=en(a.object,a.propertyName)),Ea(d)&&"number"===typeof c&&(a=a.oldValue,b?Ia(d,c,a):Ja(d,c))):A("unknown ChangedEvent.Remove modelChange: "+
a.toString())):a.change!==bf&&A("unknown ChangedEvent: "+a.toString())};t.Aa=function(a){return this.undoManager.Aa(a)};t.Za=function(a){return this.undoManager.Za(a)};t.vf=function(){return this.undoManager.vf()};Z.prototype.commit=function(a,b){void 0===b&&(b="");var c=this.skipsUndoManager;null===b&&(this.skipsUndoManager=!0,b="");this.undoManager.Aa(b);var d=!1;try{a(this),d=!0}finally{d?this.undoManager.Za(b):this.undoManager.vf(),this.skipsUndoManager=c}};t=Z.prototype;
t.Ea=function(a,b){void 0===b&&(b="");zq(this,"SourceChanged",bf,b,a,null,null)};t.pa=function(a){if(null!==a){var b=this.mi;if(""!==b&&(b=en(a,b),void 0!==b)){if(eq(b))return b;A("Key value for node data "+a+" is not a number or a string: "+b)}}};
t.vm=function(a,b){if(null!==a){var c=this.mi;if(""!==c)if(this.Pb(a)){var d=en(a,c);d!==b&&null===this.Qb(b)&&(Oj(a,c,b),void 0!==d&&this.cb.remove(d),this.cb.add(b,a),zq(this,"nodeKey",af,c,a,d,b),"string"===typeof c&&this.Ea(a,c),this.qq(d,b))}else Oj(a,c,b)}};function eq(a){return"number"===typeof a||"string"===typeof a}t.Pb=function(a){var b=this.pa(a);return void 0===b?!1:this.cb.J(b)===a};
t.Qb=function(a){null===a&&A("Model.findNodeDataForKey:key must not be null");return void 0!==a&&eq(a)?this.cb.J(a):null};t.gt=function(a){if(null!==a){var b=this.mi;if(""!==b){var c=this.pa(a);if(void 0===c||this.cb.contains(c)){var d=this.ol;if(null!==d&&(c=d(this,a),void 0!==c&&null!==c&&!this.cb.contains(c))){Oj(a,b,c);return}if("string"===typeof c){for(d=2;this.cb.contains(c+d);)d++;Oj(a,b,c+d)}else if(void 0===c||"number"===typeof c){for(c=-this.cb.count-1;this.cb.contains(c);)c--;Oj(a,b,c)}}}}};
t.jf=function(a){null!==a&&(Db(a),this.Pb(a)||Xp(this,a,!0))};function Xp(a,b,c){var d=a.pa(b);if(void 0===d||a.cb.J(d)!==b)a.gt(b),d=a.pa(b),void 0===d?A("Model.makeNodeDataKeyUnique failed on "+b+".  Data not added to Model."):(a.cb.add(d,b),d=null,c&&(d=a.Fc.length,Ia(a.Fc,d,b)),zq(a,"nodeDataArray",cf,"nodeDataArray",a,null,b,null,d),a.rm(b),a.qm(b))}t.gy=function(a){if(Ea(a))for(var b=a.length,c=0;c<b;c++)this.jf(a[c]);else for(a=a.iterator;a.next();)this.jf(a.value)};
t.om=function(a){null!==a&&Wp(this,a,!0)};function Wp(a,b,c){var d=a.pa(b);void 0!==d&&a.cb.remove(d);d=null;if(c){a:if(c=a.Fc,Array.isArray(c))d=c.indexOf(b);else{d=c.length;for(var e=0;e<d;e++)if(c[e]===b){d=e;break a}d=-1}if(0>d)return;Ja(a.Fc,d)}zq(a,"nodeDataArray",df,"nodeDataArray",a,b,null,d,null);a.vq(b)}t.Lz=function(a){if(Ea(a))for(var b=a.length,c=0;c<b;c++)this.om(a[c]);else for(a=a.iterator;a.next();)this.om(a.value)};
t.Az=function(a){if(Ea(a)){for(var b=new F(this.cb.iteratorKeys),c=new F,d=a.length,e=0;e<d;e++){var f=a[e],g=this.pa(f);if(void 0!==g){c.add(g);var h=this.Qb(g);null!==h?this.Sj(h,f):(h={},this.vm(h,g),this.Sj(h,f),this.jf(h))}else this.jf(f),c.add(this.pa(f))}for(a=b.iterator;a.next();)b=a.value,c.contains(b)||(b=this.Qb(b))&&this.om(b)}};t.qq=function(a,b){void 0!==b&&(a=hq(this,a),a instanceof F&&this.Ff.add(b,a))};t.Wv=function(){};t.rm=function(){};t.qm=function(){};t.vq=function(){};
function jq(a,b,c){if(void 0!==b){var d=a.Ff.J(b);null===d&&(d=new F,a.Ff.add(b,d));d.add(c)}}function iq(a,b,c){if(void 0!==b){var d=a.Ff.J(b);d instanceof F&&(void 0===c||null===c?a.Ff.remove(b):(d.remove(c),0===d.count&&a.Ff.remove(b)))}}function hq(a,b){if(void 0===b)return null;a=a.Ff.J(b);return a instanceof F?a:null}t.Lu=function(a){void 0===a?this.Ff.clear():this.Ff.remove(a)};
Z.prototype.copyNodeData=function(a){if(null===a)return null;var b=this.Jk;a=null!==b?b(a,this):Aq(this,a,!0);Ca(a)&&nb(a);return a};
function Aq(a,b,c){if(a.copiesArrays&&Array.isArray(b)){var d=[];for(c=0;c<b.length;c++){var e=Aq(a,b[c],a.copiesArrayObjects);d.push(e)}nb(d);return d}if(c&&Ca(b)){c=(c=b.constructor)?new c:{};e=a.copiesKey||"string"!==typeof a.nodeKeyProperty?null:a.nodeKeyProperty;for(d in b)if("__gohashid"===d)c.__gohashid=void 0;else if(d===e)c[e]=void 0;else{var f=en(b,d),g=vq(a,f);"GraphObject"===g||"Diagram"===g||"Layer"===g||"RowColumnDefinition"===g||"AnimationManager"===g||"Tool"===g||"CommandHandler"===
g||"Layout"===g||"InputEvent"===g||"DiagramEvent"===g||f instanceof Z||f instanceof ff||f instanceof ef||f instanceof $e?Oj(c,d,f):(f=Aq(a,f,!1),Oj(c,d,f))}nb(c);return c}return b instanceof H?b.copy():b instanceof L?b.copy():b instanceof N?b.copy():b instanceof O?b.copy():b instanceof Ic?b.copy():b}
Z.prototype.setDataProperty=function(a,b,c){if(this.Pb(a))if(b===this.nodeKeyProperty)this.vm(a,c);else{if(b===this.nodeCategoryProperty){this.sq(a,c);return}}else!Bq&&a instanceof Y&&(Bq=!0,za('Model.setDataProperty is modifying a GraphObject, "'+a.toString()+'"'),za("  Is that really your intent?"));var d=en(a,b);d!==c&&(Oj(a,b,c),this.lt(a,b,d,c))};t=Z.prototype;t.set=function(a,b,c){this.setDataProperty(a,b,c)};
t.Sj=function(a,b){if(b){var c=this.Pb(a),d;for(d in b)"__gohashid"===d||c&&d===this.nodeKeyProperty||this.setDataProperty(a,d,b[d])}};t.$x=function(a,b){this.Xs(a,-1,b)};t.Xs=function(a,b,c){0>b&&(b=a.length);Ia(a,b,c);zq(this,"",cf,"",a,null,c,null,b)};t.xv=function(a,b){void 0===b&&(b=-1);a===this.Fc&&A("Model.removeArrayItem should not be called on the Model.nodeDataArray");-1===b&&(b=a.length-1);var c=a[b];Ja(a,b);zq(this,"",df,"",a,c,null,b,null)};
t.Us=function(a){if(null===a)return"";var b=this.yj;if(""===b)return"";b=en(a,b);if(void 0===b)return"";if("string"===typeof b)return b;A("getCategoryForNodeData found a non-string category for "+a+": "+b);return""};t.sq=function(a,b){if(null!==a){var c=this.yj;if(""!==c)if(this.Pb(a)){var d=en(a,c);void 0===d&&(d="");d!==b&&(Oj(a,c,b),zq(this,"nodeCategory",af,c,a,d,b))}else Oj(a,c,b)}};t.fm=function(){return!1};t.ek=function(){return!1};t.em=function(){return!1};t.ct=function(){return!1};t.fk=function(){return!1};
function xi(){return new Z}function vq(a,b){if("function"===typeof b){if(b.className)return b.className;if(b.name)return b.name}else if("object"===typeof b&&null!==b&&b.constructor)return vq(a,b.constructor);return typeof b}function en(a,b){if(!a||!b)return null;try{if("function"===typeof b)var c=b(a);else"function"===typeof a.getAttribute?(c=a.getAttribute(b),null===c&&(c=void 0)):c=a[b]}catch(d){}return c}
function Oj(a,b,c){if(a&&b)try{"function"===typeof b?b(a,c):"function"===typeof a.setAttribute?a.setAttribute(b,c):a[b]=c}catch(d){}}
pa.Object.defineProperties(Z.prototype,{name:{get:function(){return this.Ua},set:function(a){var b=this.Ua;b!==a&&(this.Ua=a,this.g("name",b,a))}},dataFormat:{get:function(){return this.ln},set:function(a){var b=this.ln;b!==a&&(this.ln=a,this.g("dataFormat",b,a))}},isReadOnly:{get:function(){return this.Mf},set:function(a){var b=this.Mf;b!==a&&(this.Mf=a,this.g("isReadOnly",b,a))}},modelData:{
get:function(){return this.l},set:function(a){var b=this.l;b!==a&&(this.l=a,this.g("modelData",b,a),this.Ea(a))}},undoManager:{get:function(){return this.u},set:function(a){var b=this.u;b!==a&&(null!==b&&b.xx(this),this.u=a,null!==a&&a.Qw(this))}},skipsUndoManager:{get:function(){return this.eh},set:function(a){this.eh=a}},nodeKeyProperty:{get:function(){return this.mi},set:function(a){var b=this.mi;
b!==a&&(""===a&&A("Model.nodeKeyProperty may not be the empty string"),0<this.cb.count&&A("Cannot set Model.nodeKeyProperty when there is existing node data"),this.mi=a,this.g("nodeKeyProperty",b,a))}},makeUniqueKeyFunction:{get:function(){return this.ol},set:function(a){var b=this.ol;b!==a&&(this.ol=a,this.g("makeUniqueKeyFunction",b,a))}},nodeDataArray:{get:function(){return this.Fc},set:function(a){var b=this.Fc;if(b!==a){this.cb.clear();
this.Wv();for(var c=a.length,d=0;d<c;d++){var e=a[d];if(!Ca(e)){A("Model.nodeDataArray must only contain Objects, not: "+e);return}Db(e)}this.Fc=a;d=new E;for(e=0;e<c;e++){var f=a[e],g=this.pa(f);void 0===g?d.add(f):null!==this.cb.J(g)?d.add(f):this.cb.add(g,f)}for(d=d.iterator;d.next();)e=d.value,this.gt(e),f=this.pa(e),void 0!==f&&this.cb.add(f,e);zq(this,"nodeDataArray",af,"nodeDataArray",this,b,a);for(b=0;b<c;b++)d=a[b],this.rm(d),this.qm(d);this.Lu();Array.isArray(a)||(this.isReadOnly=!0)}}},
copyNodeDataFunction:{get:function(){return this.Jk},set:function(a){var b=this.Jk;b!==a&&(this.Jk=a,this.g("copyNodeDataFunction",b,a))}},copiesArrays:{get:function(){return this.bn},set:function(a){var b=this.bn;b!==a&&(this.bn=a,this.g("copiesArrays",b,a))}},copiesArrayObjects:{get:function(){return this.an},set:function(a){var b=this.an;b!==a&&(this.an=a,this.g("copiesArrayObjects",b,a))}},copiesKey:{
get:function(){return this.dn},set:function(a){var b=this.dn;b!==a&&(this.dn=a,this.g("copiesKey",b,a))}},afterCopyFunction:{get:function(){return this.Mm},set:function(a){var b=this.Mm;b!==a&&(this.Mm=a,this.g("afterCopyFunction",b,a))}},nodeCategoryProperty:{get:function(){return this.yj},set:function(a){var b=this.yj;b!==a&&(this.yj=a,this.g("nodeCategoryProperty",b,a))}}});
pa.Object.defineProperties(Z,{type:{get:function(){return"Model"}}});Z.prototype.setCategoryForNodeData=Z.prototype.sq;Z.prototype.getCategoryForNodeData=Z.prototype.Us;Z.prototype.removeArrayItem=Z.prototype.xv;Z.prototype.insertArrayItem=Z.prototype.Xs;Z.prototype.addArrayItem=Z.prototype.$x;Z.prototype.assignAllDataProperties=Z.prototype.Sj;Z.prototype.set=Z.prototype.set;Z.prototype.clearUnresolvedReferences=Z.prototype.Lu;Z.prototype.mergeNodeDataArray=Z.prototype.Az;
Z.prototype.removeNodeDataCollection=Z.prototype.Lz;Z.prototype.removeNodeData=Z.prototype.om;Z.prototype.addNodeDataCollection=Z.prototype.gy;Z.prototype.addNodeData=Z.prototype.jf;Z.prototype.makeNodeDataKeyUnique=Z.prototype.gt;Z.prototype.findNodeDataForKey=Z.prototype.Qb;Z.prototype.containsNodeData=Z.prototype.Pb;Z.prototype.setKeyForNodeData=Z.prototype.vm;Z.prototype.getKeyForNodeData=Z.prototype.pa;Z.prototype.updateTargetBindings=Z.prototype.Ea;Z.prototype.commit=Z.prototype.commit;
Z.prototype.rollbackTransaction=Z.prototype.vf;Z.prototype.commitTransaction=Z.prototype.Za;Z.prototype.startTransaction=Z.prototype.Aa;Z.prototype.raiseDataChanged=Z.prototype.lt;Z.prototype.raiseChanged=Z.prototype.g;Z.prototype.raiseChangedEvent=Z.prototype.ab;Z.prototype.removeChangedListener=Z.prototype.rk;Z.prototype.addChangedListener=Z.prototype.rh;Z.prototype.writeJsonValue=Z.prototype.zm;Z.prototype.replaceJsonObjects=Z.prototype.pm;Z.prototype.applyIncrementalJSON=Z.prototype.jy;
Z.prototype.applyIncrementalJson=Z.prototype.Sw;Z.prototype.toJSON=Z.prototype.toJSON;Z.prototype.toJson=Z.prototype.uq;Z.prototype.toIncrementalJSON=Z.prototype.Uz;Z.prototype.toIncrementalJson=Z.prototype.Kx;Z.prototype.computeJsonDifference=Z.prototype.py;Z.prototype.clear=Z.prototype.clear;var Bq=!1,xq={};Z.className="Model";
Z.fromJSON=Z.fromJson=function(a,b){void 0===b&&(b=null);var c=null;if("string"===typeof a)try{c=w.JSON.parse(a)}catch(f){}else"object"===typeof a?c=a:A("Unable to construct a Model from: "+a);if(null===b){a=null;var d=c["class"];if("string"===typeof d)try{var e=null;0===d.indexOf("go.")?(d=d.substr(3),e=xq[d]?xq[d]:null):(e=xq[d]?xq[d]:null,void 0===e&&(e=w[d]));"function"===typeof e&&(a=new e)}catch(f){}null===a||a instanceof Z?b=a:A("Unable to construct a Model of declared class: "+c["class"])}null===
b&&(b=Z.constructGraphLinksModel());b.nq(c);b.wv(c);return b};Z.safePropertyValue=en;Z.safePropertySet=Oj;xq.Brush=il;xq.ChangedEvent=$e;xq.Geometry=$d;xq.GraphObject=Y;xq.Margin=Ic;xq.Panel=X;xq.Point=H;xq.Rect=N;xq.Size=L;xq.Spot=O;xq.Transaction=ef;xq.UndoManager=ff;function Ei(a,b,c){nb(this);this.v=!1;void 0===a&&(a="");void 0===b&&(b=a);void 0===c&&(c=null);this.l=-1;this.Qd=null;this.Ol=a;this.Nl=this.yp=0;this.ws=null;this.Vn=!1;this.El=b;this.$m=c;this.ro=Cq;this.Um=null;this.Yt=new F}
Ei.prototype.copy=function(){var a=new Ei;a.Ol=this.Ol;a.yp=this.yp;a.Nl=this.Nl;a.ws=this.ws;a.Vn=this.Vn;a.El=this.El;a.$m=this.$m;a.ro=this.ro;a.Um=this.Um;return a};t=Ei.prototype;t.fb=function(a){a.classType===Ei&&(this.mode=a)};t.toString=function(){return"Binding("+this.targetProperty+":"+this.sourceProperty+(-1!==this.Zi?" "+this.Zi:"")+" "+this.mode.name+")"};t.freeze=function(){this.v=!0;return this};t.ha=function(){this.v=!1;return this};
t.px=function(a){void 0===a&&(a=null);this.mode=Ym;this.backConverter=a;return this};t.kq=function(a){void 0===a&&(a="");this.sourceName=a;this.isToModel=!1;return this};t.Cz=function(){this.sourceName=null;this.isToModel=!0;return this};function Wk(a,b,c){a=a.sourceName;return null===a||""===a?b:"/"===a?c.part:"."===a?c:".."===a?c.panel:b.$a(a)}
t.Xv=function(a,b,c){var d=this.El;if(void 0===c||""===d||d===c){c=this.Ol;var e=this.$m;if(null===e&&""===c)za("Binding error: target property is the empty string: "+this.toString());else{var f=b;""!==d&&(f=en(b,d));if(void 0!==f)if(null===e)""!==c&&Oj(a,c,f);else try{if(""!==c){var g=e(f,a);Oj(a,c,g)}else e(f,a)}catch(h){}}}};
t.xq=function(a,b,c,d){if(this.ro===Ym){var e=this.Ol;if(void 0===c||e===c){c=this.El;var f=this.Um,g=a;""!==e&&(g=en(a,e));if(void 0!==g&&!this.Yt.contains(a))try{this.Yt.add(a);var h=null!==d?d.diagram:null,k=null!==h?h.model:null;if(null===f)if(""!==c)null!==k?k.setDataProperty(b,c,g):Oj(b,c,g);else{if(null!==k&&null!==d&&0<=d.itemIndex&&null!==d.panel&&Array.isArray(d.panel.itemArray)){var l=d.itemIndex,m=d.panel.itemArray;k.xv(m,l);k.Xs(m,l,g)}}else try{if(""!==c){var n=f(g,b,k);null!==k?k.setDataProperty(b,
c,n):Oj(b,c,n)}else{var p=f(g,b,k);if(void 0!==p&&null!==k&&null!==d&&0<=d.itemIndex&&null!==d.panel&&Array.isArray(d.panel.itemArray)){var q=d.itemIndex,r=d.panel.itemArray;k.xv(r,q);k.Xs(r,q,p)}}}catch(u){}}finally{this.Yt.remove(a)}}}};
pa.Object.defineProperties(Ei.prototype,{Zi:{get:function(){return this.l},set:function(a){this.v&&wa(this);this.l=a}},targetProperty:{get:function(){return this.Ol},set:function(a){this.v&&wa(this);this.Ol=a}},sourceName:{get:function(){return this.ws},set:function(a){this.v&&wa(this);this.ws=a;null!==a&&(this.Vn=!1)}},isToModel:{get:function(){return this.Vn},set:function(a){this.v&&
wa(this);this.Vn=a}},sourceProperty:{get:function(){return this.El},set:function(a){this.v&&wa(this);this.El=a}},converter:{get:function(){return this.$m},set:function(a){this.v&&wa(this);this.$m=a}},backConverter:{get:function(){return this.Um},set:function(a){this.v&&wa(this);this.Um=a}},mode:{get:function(){return this.ro},set:function(a){this.v&&wa(this);this.ro=a}}});
Ei.prototype.updateSource=Ei.prototype.xq;Ei.prototype.updateTarget=Ei.prototype.Xv;Ei.prototype.ofModel=Ei.prototype.Cz;Ei.prototype.ofObject=Ei.prototype.kq;Ei.prototype.makeTwoWay=Ei.prototype.px;var Cq=new D(Ei,"OneWay",1),Ym=new D(Ei,"TwoWay",2);Ei.className="Binding";Ei.parseEnum=function(a,b){return function(c){c=qb(a,c);return null===c?b:c}};Ei.toString=Qa;Ei.OneWay=Cq;Ei.TwoWay=Ym;
function Dq(a,b){Z.call(this);this.Ct=',\n  "insertedLinkKeys": ';this.dw=',\n  "modifiedLinkData": ';this.Et=',\n  "removedLinkKeys": ';this.Oc=[];this.Nf=new F;this.qb=new Lb;this.ii="";this.bj=this.Ik=this.pl=null;this.Ue="from";this.Ve="to";this.vj=this.uj="";this.tj="category";this.Hd="";this.tl="isGroup";this.re="group";this.cn=!1;void 0!==a&&(this.nodeDataArray=a);void 0!==b&&(this.linkDataArray=b)}oa(Dq,Z);Dq.constructGraphLinksModel=Z.constructGraphLinksModel;
Dq.prototype.cloneProtected=function(a){Z.prototype.cloneProtected.call(this,a);a.ii=this.ii;a.pl=this.pl;a.Ik=this.Ik;a.Ue=this.Ue;a.Ve=this.Ve;a.uj=this.uj;a.vj=this.vj;a.tj=this.tj;a.Hd=this.Hd;a.tl=this.tl;a.re=this.re;a.cn=this.cn};t=Dq.prototype;t.clear=function(){Z.prototype.clear.call(this);this.Oc=[];this.qb.clear();this.Nf.clear()};
t.toString=function(a){void 0===a&&(a=0);if(2<=a)return this.uq();var b=(""!==this.name?this.name:"")+" GraphLinksModel";if(0<a){b+="\n node data:";a=this.nodeDataArray;var c=a.length,d;for(d=0;d<c;d++){var e=a[d];b+=" "+this.pa(e)+":"+Qa(e)}b+="\n link data:";a=this.linkDataArray;c=a.length;for(d=0;d<c;d++)e=a[d],b+=" "+cq(this,e,!0)+"--\x3e"+cq(this,e,!1)}return b};
t.uk=function(){var a=Z.prototype.uk.call(this),b="";"category"!==this.linkCategoryProperty&&"string"===typeof this.linkCategoryProperty&&(b+=',\n  "linkCategoryProperty": '+this.quote(this.linkCategoryProperty));""!==this.linkKeyProperty&&"string"===typeof this.linkKeyProperty&&(b+=',\n  "linkKeyProperty": '+this.quote(this.linkKeyProperty));"from"!==this.linkFromKeyProperty&&"string"===typeof this.linkFromKeyProperty&&(b+=',\n  "linkFromKeyProperty": '+this.quote(this.linkFromKeyProperty));"to"!==
this.linkToKeyProperty&&"string"===typeof this.linkToKeyProperty&&(b+=',\n  "linkToKeyProperty": '+this.quote(this.linkToKeyProperty));""!==this.linkFromPortIdProperty&&"string"===typeof this.linkFromPortIdProperty&&(b+=',\n  "linkFromPortIdProperty": '+this.quote(this.linkFromPortIdProperty));""!==this.linkToPortIdProperty&&"string"===typeof this.linkToPortIdProperty&&(b+=',\n  "linkToPortIdProperty": '+this.quote(this.linkToPortIdProperty));""!==this.linkLabelKeysProperty&&"string"===typeof this.linkLabelKeysProperty&&
(b+=',\n  "linkLabelKeysProperty": '+this.quote(this.linkLabelKeysProperty));"isGroup"!==this.nodeIsGroupProperty&&"string"===typeof this.nodeIsGroupProperty&&(b+=',\n  "nodeIsGroupProperty": '+this.quote(this.nodeIsGroupProperty));"group"!==this.nodeGroupKeyProperty&&"string"===typeof this.nodeGroupKeyProperty&&(b+=',\n  "nodeGroupKeyProperty": '+this.quote(this.nodeGroupKeyProperty));return a+b};
t.nq=function(a){Z.prototype.nq.call(this,a);a.linkKeyProperty&&(this.linkKeyProperty=a.linkKeyProperty);a.linkFromKeyProperty&&(this.linkFromKeyProperty=a.linkFromKeyProperty);a.linkToKeyProperty&&(this.linkToKeyProperty=a.linkToKeyProperty);a.linkFromPortIdProperty&&(this.linkFromPortIdProperty=a.linkFromPortIdProperty);a.linkToPortIdProperty&&(this.linkToPortIdProperty=a.linkToPortIdProperty);a.linkCategoryProperty&&(this.linkCategoryProperty=a.linkCategoryProperty);a.linkLabelKeysProperty&&(this.linkLabelKeysProperty=
a.linkLabelKeysProperty);a.nodeIsGroupProperty&&(this.nodeIsGroupProperty=a.nodeIsGroupProperty);a.nodeGroupKeyProperty&&(this.nodeGroupKeyProperty=a.nodeGroupKeyProperty)};t.$v=function(){var a=Z.prototype.$v.call(this),b=',\n  "linkDataArray": '+sq(this,this.linkDataArray,!0);return a+b};t.wv=function(a){Z.prototype.wv.call(this,a);a=a.linkDataArray;Array.isArray(a)&&(this.pm(a),this.linkDataArray=a)};
t.yq=function(a){if(!(a instanceof Dq))return A("Model.computeJsonDifference: newmodel must be a GraphLinksModel"),"";var b=Z.prototype.yq.call(this,a);uq(this,a,"linkKeyProperty");uq(this,a,"linkFromKeyProperty");uq(this,a,"linkToKeyProperty");uq(this,a,"linkLabelKeysProperty");uq(this,a,"nodeIsGroupProperty");uq(this,a,"nodeGroupKeyProperty");for(var c=new F,d=new F,e=(new F).addAll(this.qb.iteratorKeys),f=new Lb,g=a.linkDataArray,h=g.length,k=0;k<h;k++){var l=g[k],m=a.ic(l);if(void 0!==m){e.remove(m);
var n=this.xh(m);null===n?(c.add(m),d.add(l)):tq(this,n,l,f)||d.add(l)}else this.hq(l),m=this.ic(l),c.add(m),d.add(l)}a=b;0<c.count&&(a+=this.Ct+sq(this,c.Na(),!0));0<d.count&&(a+=this.dw+sq(this,d.Na(),!0));0<e.count&&(a+=this.Et+sq(this,e.Na(),!0));return a};
t.yt=function(a,b){var c=Z.prototype.yt.call(this,a,b),d=this,e=new F,f=new F,g=new F;a.changes.each(function(a){a.model===d&&("linkDataArray"===a.modelChange?a.change===cf?e.add(a.newValue):a.change===df&&g.add(a.oldValue):d.Ae(a.object)&&f.add(a.object))});var h=new F;e.each(function(a){h.add(d.ic(a));b||f.add(a)});var k=new F;g.each(function(a){k.add(d.ic(a));b&&f.add(a)});a=c;0<h.count&&(a+=(b?this.Et:this.Ct)+sq(this,h.Na(),!0));0<f.count&&(a+=this.dw+sq(this,f.Na(),!0));0<k.count&&(a+=(b?this.Ct:
this.Et)+sq(this,k.Na(),!0));return a};
t.vv=function(a){Z.prototype.vv.call(this,a);var b=a.insertedLinkKeys;if(Array.isArray(b))for(var c=b.length,d=0;d<c;d++){var e=b[d],f=this.xh(e);null===f&&(f=this.Rp({}),this.ut(f,e),this.Ci(f))}b=a.modifiedLinkData;if(Array.isArray(b))for(c=b.length,d=0;d<c;d++)if(e=b[d],f=this.ic(e),f=this.xh(f),null!==f)for(var g in e)"__gohashid"!==g&&g!==this.linkKeyProperty&&this.setDataProperty(f,g,e[g]);a=a.removedLinkKeys;if(Array.isArray(a))for(g=a.length,b=0;b<g;b++)c=this.xh(a[b]),null!==c&&this.nm(c)};
t.Tj=function(a,b){if(a.change===cf){var c=a.newParam;if("linkDataArray"===a.modelChange){a=a.newValue;if(Ca(a)&&"number"===typeof c){var d=this.ic(a);b?(this.Nf.remove(a),this.Oc[c]===a&&this.Oc.splice(c,1),void 0!==d&&this.qb.remove(d)):(this.Nf.add(a),this.Oc[c]!==a&&this.Oc.splice(c,0,a),void 0!==d&&this.qb.add(d,a))}return}if("linkLabelKeys"===a.modelChange){d=this.qg(a.object);Array.isArray(d)&&"number"===typeof c&&(b?(c=d.indexOf(a.newValue),0<=c&&d.splice(c,1)):0>d.indexOf(a.newValue)&&d.splice(c,
0,a.newValue));return}}else if(a.change===df){c=a.oldParam;if("linkDataArray"===a.modelChange){a=a.oldValue;Ca(a)&&"number"===typeof c&&(d=this.ic(a),b?(this.Nf.add(a),this.Oc[c]!==a&&this.Oc.splice(c,0,a),void 0!==d&&this.qb.add(d,a)):(this.Nf.remove(a),this.Oc[c]===a&&this.Oc.splice(c,1),void 0!==d&&this.qb.remove(d)));return}if("linkLabelKeys"===a.modelChange){d=this.qg(a.object);Array.isArray(d)&&"number"===typeof c&&(b?0>d.indexOf(a.newValue)&&d.splice(c,0,a.newValue):(c=d.indexOf(a.newValue),
0<=c&&d.splice(c,1)));return}}Z.prototype.Tj.call(this,a,b)};t.gm=function(a){if(void 0!==a){var b=this.bj;if(null!==b){var c=this.Qb(a);null===c&&(c=this.copyNodeData(b),Oj(c,this.nodeKeyProperty,a),this.jf(c))}return a}};t.Yy=function(a){return cq(this,a,!0)};t.Bx=function(a,b){kq(this,a,b,!0)};t.cz=function(a){return cq(this,a,!1)};t.Gx=function(a,b){kq(this,a,b,!1)};
function cq(a,b,c){if(null!==b&&(a=c?a.Ue:a.Ve,""!==a&&(a=en(b,a),void 0!==a))){if(eq(a))return a;A((c?"FromKey":"ToKey")+" value for link data "+b+" is not a number or a string: "+a)}}function kq(a,b,c,d){null===c&&(c=void 0);if(null!==b){var e=d?a.Ue:a.Ve;if(""!==e)if(c=a.gm(c),a.Ae(b)){var f=en(b,e);f!==c&&(iq(a,f,b),Oj(b,e,c),null===a.Qb(c)&&jq(a,c,b),zq(a,d?"linkFromKey":"linkToKey",af,e,b,f,c),"string"===typeof e&&a.Ea(b,e))}else Oj(b,e,c)}}t.Zy=function(a){return bq(this,a,!0)};
t.Cx=function(a,b){lq(this,a,b,!0)};t.dz=function(a){return bq(this,a,!1)};t.Hx=function(a,b){lq(this,a,b,!1)};function bq(a,b,c){if(null===b)return"";a=c?a.uj:a.vj;if(""===a)return"";b=en(b,a);return void 0===b?"":b}function lq(a,b,c,d){if(null!==b){var e=d?a.uj:a.vj;if(""!==e)if(a.Ae(b)){var f=en(b,e);void 0===f&&(f="");f!==c&&(Oj(b,e,c),zq(a,d?"linkFromPortId":"linkToPortId",af,e,b,f,c),"string"===typeof e&&a.Ea(b,e))}else Oj(b,e,c)}}
t.qg=function(a){if(null===a)return Eq;var b=this.Hd;if(""===b)return Eq;a=en(a,b);return void 0===a?Eq:a};t.Hv=function(a,b){if(null!==a){var c=this.Hd;if(""!==c)if(this.Ae(a)){var d=en(a,c);void 0===d&&(d=Eq);if(d!==b){if(Array.isArray(d))for(var e=d.length,f=0;f<e;f++)iq(this,d[f],a);Oj(a,c,b);e=b.length;for(f=0;f<e;f++){var g=b[f];null===this.Qb(g)&&jq(this,g,a)}zq(this,"linkLabelKeys",af,c,a,d,b);"string"===typeof c&&this.Ea(a,c)}}else Oj(a,c,b)}};
t.Fu=function(a,b){if(null!==b&&void 0!==b&&null!==a){var c=this.Hd;if(""!==c){var d=en(a,c);if(void 0===d)c=[],c.push(b),this.Hv(a,c);else if(Array.isArray(d)){var e=d.indexOf(b);0<=e||(e=d.length,d.push(b),this.Ae(a)&&(null===this.Qb(b)&&jq(this,b,a),zq(this,"linkLabelKeys",cf,c,a,null,b,null,e)))}else A(c+" property is not an Array; cannot addLabelKeyForLinkData: "+a)}}};
t.wx=function(a,b){if(null!==b&&void 0!==b&&null!==a){var c=this.Hd;if(""!==c){var d=en(a,c);if(Array.isArray(d)){var e=d.indexOf(b);0>e||(d.splice(e,1),this.Ae(a)&&(iq(this,b,a),zq(this,"linkLabelKeys",df,c,a,b,null,e,null)))}else void 0!==d&&A(c+" property is not an Array; cannot removeLabelKeyforLinkData: "+a)}}};t.ic=function(a){if(null!==a){var b=this.ii;if(""!==b&&(b=en(a,b),void 0!==b)){if(eq(b))return b;A("Key value for link data "+a+" is not a number or a string: "+b)}}};
t.ut=function(a,b){if(null!==a){var c=this.ii;if(""!==c)if(this.Ae(a)){var d=en(a,c);d!==b&&null===this.xh(b)&&(Oj(a,c,b),void 0!==d&&this.qb.remove(d),this.qb.add(b,a),zq(this,"linkKey",af,c,a,d,b),"string"===typeof c&&this.Ea(a,c))}else Oj(a,c,b)}};t.xh=function(a){null===a&&A("GraphLinksModel.findLinkDataForKey:key must not be null");return void 0!==a&&eq(a)?this.qb.J(a):null};
t.hq=function(a){if(null!==a){var b=this.ii;if(""!==b){var c=this.ic(a);if(void 0===c||this.qb.contains(c)){var d=this.pl;if(null!==d&&(c=d(this,a),void 0!==c&&null!==c&&!this.qb.contains(c))){Oj(a,b,c);return}if("string"===typeof c){for(d=2;this.qb.contains(c+d);)d++;Oj(a,b,c+d)}else if(void 0===c||"number"===typeof c){for(c=-this.qb.count-1;this.qb.contains(c);)c--;Oj(a,b,c)}}}}};t.Ae=function(a){return null===a?!1:this.Nf.contains(a)};t.Ci=function(a){null!==a&&(Db(a),this.Ae(a)||gq(this,a,!0))};
function gq(a,b,c){if(""!==a.linkKeyProperty){var d=a.ic(b);if(void 0!==d&&a.qb.J(d)===b)return;a.hq(b);d=a.ic(b);if(void 0===d){A("GraphLinksModel.makeLinkDataKeyUnique failed on "+b+". Data not added to model.");return}a.qb.add(d,b)}a.Nf.add(b);d=null;c&&(d=a.Oc.length,a.Oc.splice(d,0,b));zq(a,"linkDataArray",cf,"linkDataArray",a,null,b,null,d);Fq(a,b)}t.ey=function(a){if(Array.isArray(a))for(var b=a.length,c=0;c<b;c++)this.Ci(a[c]);else for(a=a.iterator;a.next();)this.Ci(a.value)};
t.nm=function(a){null!==a&&fq(this,a,!0)};function fq(a,b,c){a.Nf.remove(b);var d=a.ic(b);void 0!==d&&a.qb.remove(d);d=null;if(c){d=a.Oc.indexOf(b);if(0>d)return;a.Oc.splice(d,1)}zq(a,"linkDataArray",df,"linkDataArray",a,b,null,d,null);c=cq(a,b,!0);iq(a,c,b);c=cq(a,b,!1);iq(a,c,b);d=a.qg(b);if(Array.isArray(d))for(var e=d.length,f=0;f<e;f++)c=d[f],iq(a,c,b)}t.Jz=function(a){if(Array.isArray(a))for(var b=a.length,c=0;c<b;c++)this.nm(a[c]);else for(a=a.iterator;a.next();)this.nm(a.value)};
t.zz=function(a){if(Ea(a)){for(var b=new F(this.qb.iteratorKeys),c=new F,d=a.length,e=0;e<d;e++){var f=a[e],g=this.ic(f);if(void 0!==g){c.add(g);var h=this.xh(g);null!==h?this.Sj(h,f):(h={},this.ut(h,g),this.Sj(h,f),this.Ci(h))}else this.Ci(f),c.add(this.ic(f))}for(a=b.iterator;a.next();)b=a.value,c.contains(b)||(b=this.xh(b))&&this.nm(b)}};
function Fq(a,b){var c=cq(a,b,!0);c=a.gm(c);null===a.Qb(c)&&jq(a,c,b);c=cq(a,b,!1);c=a.gm(c);null===a.Qb(c)&&jq(a,c,b);var d=a.qg(b);if(Array.isArray(d))for(var e=d.length,f=0;f<e;f++)c=d[f],null===a.Qb(c)&&jq(a,c,b)}t.Rp=function(a){if(null===a)return null;var b=this.Ik;a=null!==b?b(a,this):Aq(this,a,!0);Ca(a)&&(nb(a),""!==this.Ue&&Oj(a,this.Ue,void 0),""!==this.Ve&&Oj(a,this.Ve,void 0),""!==this.Hd&&Oj(a,this.Hd,[]));return a};
t.kv=function(a){if(null===a)return!1;var b=this.tl;return""===b?!1:en(a,b)?!0:!1};t.Mi=function(a){if(null!==a){var b=this.re;if(""!==b&&(b=en(a,b),void 0!==b)){if(eq(b))return b;A("GroupKey value for node data "+a+" is not a number or a string: "+b)}}};
t.tt=function(a,b){null===b&&(b=void 0);if(null!==a){var c=this.re;if(""!==c)if(this.Pb(a)){var d=en(a,c);d!==b&&(iq(this,d,a),Oj(a,c,b),null===this.Qb(b)&&jq(this,b,a),zq(this,"nodeGroupKey",af,c,a,d,b),"string"===typeof c&&this.Ea(a,c))}else Oj(a,c,b)}};Dq.prototype.copyNodeData=function(a){if(null===a)return null;a=Z.prototype.copyNodeData.call(this,a);this.Xj||""===this.re||void 0===en(a,this.re)||Oj(a,this.re,void 0);return a};
Dq.prototype.setDataProperty=function(a,b,c){if(this.Pb(a))if(b===this.nodeKeyProperty)this.vm(a,c);else{if(b===this.nodeCategoryProperty){this.sq(a,c);return}if(b===this.nodeGroupKeyProperty){this.tt(a,c);return}}else if(this.Ae(a)){if(b===this.linkFromKeyProperty){kq(this,a,c,!0);return}if(b===this.linkToKeyProperty){kq(this,a,c,!1);return}if(b===this.linkFromPortIdProperty){lq(this,a,c,!0);return}if(b===this.linkToPortIdProperty){lq(this,a,c,!1);return}if(b===this.linkKeyProperty){this.ut(a,c);
return}if(b===this.linkCategoryProperty){this.st(a,c);return}if(b===this.linkLabelKeysProperty){this.Hv(a,c);return}}var d=en(a,b);d!==c&&(Oj(a,b,c),this.lt(a,b,d,c))};t=Dq.prototype;t.Sj=function(a,b){if(b){var c=this.Pb(a),d=this.Ae(a),e;for(e in b)"__gohashid"===e||c&&e===this.nodeKeyProperty||d&&e===this.linkKeyProperty||this.setDataProperty(a,e,b[e])}};
t.qq=function(a,b){Z.prototype.qq.call(this,a,b);for(var c=this.cb.iterator;c.next();)this.Bv(c.value,a,b);for(c=this.Nf.iterator;c.next();){var d=c.value,e=a,f=b;if(cq(this,d,!0)===e){var g=this.Ue;Oj(d,g,f);zq(this,"linkFromKey",af,g,d,e,f);"string"===typeof g&&this.Ea(d,g)}cq(this,d,!1)===e&&(g=this.Ve,Oj(d,g,f),zq(this,"linkToKey",af,g,d,e,f),"string"===typeof g&&this.Ea(d,g));g=this.qg(d);if(Array.isArray(g))for(var h=g.length,k=this.Hd,l=0;l<h;l++)g[l]===e&&(g[l]=f,zq(this,"linkLabelKeys",cf,
k,d,e,f,l,l))}};t.Bv=function(a,b,c){if(this.Mi(a)===b){var d=this.re;Oj(a,d,c);zq(this,"nodeGroupKey",af,d,a,b,c);"string"===typeof d&&this.Ea(a,d)}};t.Wv=function(){Z.prototype.Wv.call(this);for(var a=this.linkDataArray,b=a.length,c=0;c<b;c++)Fq(this,a[c])};
t.rm=function(a){Z.prototype.rm.call(this,a);a=this.pa(a);var b=hq(this,a);if(null!==b){var c=Ka();for(b=b.iterator;b.next();){var d=b.value;if(this.Pb(d)){if(this.Mi(d)===a){var e=this.re;zq(this,"nodeGroupKey",af,e,d,a,a);"string"===typeof e&&this.Ea(d,e);c.push(d)}}else if(cq(this,d,!0)===a&&(e=this.Ue,zq(this,"linkFromKey",af,e,d,a,a),"string"===typeof e&&this.Ea(d,e),c.push(d)),cq(this,d,!1)===a&&(e=this.Ve,zq(this,"linkToKey",af,e,d,a,a),"string"===typeof e&&this.Ea(d,e),c.push(d)),e=this.qg(d),
Array.isArray(e))for(var f=e.length,g=this.Hd,h=0;h<f;h++)e[h]===a&&(zq(this,"linkLabelKeys",cf,g,d,a,a,h,h),c.push(d))}for(b=0;b<c.length;b++)iq(this,a,c[b]);Oa(c)}};t.qm=function(a){Z.prototype.qm.call(this,a);var b=this.Mi(a);null===this.Qb(b)&&jq(this,b,a)};t.vq=function(a){Z.prototype.vq.call(this,a);var b=this.Mi(a);iq(this,b,a)};
t.av=function(a){if(null===a)return"";var b=this.tj;if(""===b)return"";b=en(a,b);if(void 0===b)return"";if("string"===typeof b)return b;A("getCategoryForLinkData found a non-string category for "+a+": "+b);return""};Dq.prototype.getLinkCategoryForData=function(a){return this.av(a)};Dq.prototype.st=function(a,b){if(null!==a){var c=this.tj;if(""!==c)if(this.Ae(a)){var d=en(a,c);void 0===d&&(d="");d!==b&&(Oj(a,c,b),zq(this,"linkCategory",af,c,a,d,b),"string"===typeof c&&this.Ea(a,c))}else Oj(a,c,b)}};
Dq.prototype.setLinkCategoryForData=function(a,b){this.st(a,b)};Dq.prototype.ek=function(){return!0};Dq.prototype.em=function(){return!0};Dq.prototype.ct=function(){return!0};Dq.prototype.fk=function(){return!0};
pa.Object.defineProperties(Dq.prototype,{archetypeNodeData:{get:function(){return this.bj},set:function(a){var b=this.bj;b!==a&&(this.bj=a,this.g("archetypeNodeData",b,a))}},linkFromKeyProperty:{get:function(){return this.Ue},set:function(a){var b=this.Ue;b!==a&&(this.Ue=a,this.g("linkFromKeyProperty",b,a))}},linkToKeyProperty:{get:function(){return this.Ve},set:function(a){var b=this.Ve;b!==a&&(this.Ve=a,this.g("linkToKeyProperty",
b,a))}},linkFromPortIdProperty:{get:function(){return this.uj},set:function(a){var b=this.uj;b!==a&&(this.uj=a,this.g("linkFromPortIdProperty",b,a))}},linkToPortIdProperty:{get:function(){return this.vj},set:function(a){var b=this.vj;b!==a&&(this.vj=a,this.g("linkToPortIdProperty",b,a))}},linkLabelKeysProperty:{get:function(){return this.Hd},set:function(a){var b=this.Hd;b!==a&&(this.Hd=a,this.g("linkLabelKeysProperty",
b,a))}},linkDataArray:{get:function(){return this.Oc},set:function(a){var b=this.Oc;if(b!==a){this.qb.clear();for(var c=a.length,d=0;d<c;d++){var e=a[d];if(!Ca(e)){A("GraphLinksModel.linkDataArray must only contain Objects, not: "+e);return}Db(e)}this.Oc=a;if(""!==this.linkKeyProperty){d=new E;for(e=0;e<c;e++){var f=a[e],g=this.ic(f);void 0===g?d.add(f):null!==this.qb.J(g)?d.add(f):this.qb.add(g,f)}for(d=d.iterator;d.next();)e=d.value,this.hq(e),f=this.ic(e),void 0!==
f&&this.qb.add(f,e)}d=new F;for(e=0;e<c;e++)d.add(a[e]);this.Nf=d;zq(this,"linkDataArray",af,"linkDataArray",this,b,a);for(b=0;b<c;b++)Fq(this,a[b])}}},linkKeyProperty:{get:function(){return this.ii},set:function(a){var b=this.ii;if(b!==a){this.ii=a;this.qb.clear();for(var c=this.linkDataArray.length,d=0;d<c;d++){var e=this.linkDataArray[d],f=this.ic(e);void 0===f&&(this.hq(e),f=this.ic(e));void 0!==f&&this.qb.add(f,e)}this.g("linkKeyProperty",b,a)}}},makeUniqueLinkKeyFunction:{
get:function(){return this.pl},set:function(a){var b=this.pl;b!==a&&(this.pl=a,this.g("makeUniqueLinkKeyFunction",b,a))}},copyLinkDataFunction:{get:function(){return this.Ik},set:function(a){var b=this.Ik;b!==a&&(this.Ik=a,this.g("copyLinkDataFunction",b,a))}},nodeIsGroupProperty:{get:function(){return this.tl},set:function(a){var b=this.tl;b!==a&&(this.tl=a,this.g("nodeIsGroupProperty",b,a))}},nodeGroupKeyProperty:{
get:function(){return this.re},set:function(a){var b=this.re;b!==a&&(this.re=a,this.g("nodeGroupKeyProperty",b,a))}},Xj:{get:function(){return this.cn},set:function(a){this.cn!==a&&(this.cn=a)}},linkCategoryProperty:{get:function(){return this.tj},set:function(a){var b=this.tj;b!==a&&(this.tj=a,this.g("linkCategoryProperty",b,a))}}});pa.Object.defineProperties(Dq,{type:{get:function(){return"GraphLinksModel"}}});
Dq.prototype.setCategoryForLinkData=Dq.prototype.st;Dq.prototype.getCategoryForLinkData=Dq.prototype.av;Dq.prototype.assignAllDataProperties=Dq.prototype.Sj;Dq.prototype.setGroupKeyForNodeData=Dq.prototype.tt;Dq.prototype.getGroupKeyForNodeData=Dq.prototype.Mi;Dq.prototype.isGroupForNodeData=Dq.prototype.kv;Dq.prototype.copyLinkData=Dq.prototype.Rp;Dq.prototype.mergeLinkDataArray=Dq.prototype.zz;Dq.prototype.removeLinkDataCollection=Dq.prototype.Jz;Dq.prototype.removeLinkData=Dq.prototype.nm;
Dq.prototype.addLinkDataCollection=Dq.prototype.ey;Dq.prototype.addLinkData=Dq.prototype.Ci;Dq.prototype.containsLinkData=Dq.prototype.Ae;Dq.prototype.makeLinkDataKeyUnique=Dq.prototype.hq;Dq.prototype.findLinkDataForKey=Dq.prototype.xh;Dq.prototype.setKeyForLinkData=Dq.prototype.ut;Dq.prototype.getKeyForLinkData=Dq.prototype.ic;Dq.prototype.removeLabelKeyForLinkData=Dq.prototype.wx;Dq.prototype.addLabelKeyForLinkData=Dq.prototype.Fu;Dq.prototype.setLabelKeysForLinkData=Dq.prototype.Hv;
Dq.prototype.getLabelKeysForLinkData=Dq.prototype.qg;Dq.prototype.setToPortIdForLinkData=Dq.prototype.Hx;Dq.prototype.getToPortIdForLinkData=Dq.prototype.dz;Dq.prototype.setFromPortIdForLinkData=Dq.prototype.Cx;Dq.prototype.getFromPortIdForLinkData=Dq.prototype.Zy;Dq.prototype.setToKeyForLinkData=Dq.prototype.Gx;Dq.prototype.getToKeyForLinkData=Dq.prototype.cz;Dq.prototype.setFromKeyForLinkData=Dq.prototype.Bx;Dq.prototype.getFromKeyForLinkData=Dq.prototype.Yy;Dq.prototype.clear=Dq.prototype.clear;
var Eq=Object.freeze([]);Dq.className="GraphLinksModel";xq.GraphLinksModel=Dq;Z.constructGraphLinksModel=Z.constructGraphLinksModel=function(){return new Dq};Z.initDiagramModel=xi=function(){return new Dq};function Gq(a){Z.call(this);this.se="parent";this.en=!1;this.Bj="parentLinkCategory";void 0!==a&&(this.nodeDataArray=a)}oa(Gq,Z);Gq.constructGraphLinksModel=Z.constructGraphLinksModel;
Gq.prototype.cloneProtected=function(a){Z.prototype.cloneProtected.call(this,a);a.se=this.se;a.en=this.en;a.Bj=this.Bj};t=Gq.prototype;t.toString=function(a){void 0===a&&(a=0);if(2<=a)return this.uq();var b=(""!==this.name?this.name:"")+" TreeModel";if(0<a){b+="\n node data:";a=this.nodeDataArray;for(var c=a.length,d=0;d<c;d++){var e=a[d];b+=" "+this.pa(e)+":"+Qa(e)}}return b};
t.uk=function(){var a=Z.prototype.uk.call(this),b="";"parent"!==this.nodeParentKeyProperty&&"string"===typeof this.nodeParentKeyProperty&&(b+=',\n  "nodeParentKeyProperty": '+this.quote(this.nodeParentKeyProperty));return a+b};t.nq=function(a){Z.prototype.nq.call(this,a);a.nodeParentKeyProperty&&(this.nodeParentKeyProperty=a.nodeParentKeyProperty)};t.yq=function(a){uq(this,a,"nodeParentKeyProperty");return Z.prototype.yq.call(this,a)};t.gm=function(a){return a};
t.Oi=function(a){if(null!==a){var b=this.se;if(""!==b&&(b=en(a,b),void 0!==b)){if(eq(b))return b;A("ParentKey value for node data "+a+" is not a number or a string: "+b)}}};t.He=function(a,b){null===b&&(b=void 0);if(null!==a){var c=this.se;if(""!==c)if(b=this.gm(b),this.Pb(a)){var d=en(a,c);d!==b&&(iq(this,d,a),Oj(a,c,b),null===this.Qb(b)&&jq(this,b,a),zq(this,"nodeParentKey",af,c,a,d,b),"string"===typeof c&&this.Ea(a,c))}else Oj(a,c,b)}};
t.bv=function(a){if(null===a)return"";var b=this.Bj;if(""===b)return"";b=en(a,b);if(void 0===b)return"";if("string"===typeof b)return b;A("getParentLinkCategoryForNodeData found a non-string category for "+a+": "+b);return""};Gq.prototype.getLinkCategoryForData=function(a){return this.bv(a)};
Gq.prototype.Iv=function(a,b){if(null!==a){var c=this.Bj;if(""!==c)if(this.Pb(a)){var d=en(a,c);void 0===d&&(d="");d!==b&&(Oj(a,c,b),zq(this,"parentLinkCategory",af,c,a,d,b),"string"===typeof c&&this.Ea(a,c))}else Oj(a,c,b)}};Gq.prototype.setLinkCategoryForData=function(a,b){this.Iv(a,b)};Gq.prototype.copyNodeData=function(a){if(null===a)return null;a=Z.prototype.copyNodeData.call(this,a);this.Yj||""===this.se||void 0===en(a,this.se)||Oj(a,this.se,void 0);return a};
Gq.prototype.setDataProperty=function(a,b,c){if(this.Pb(a))if(b===this.nodeKeyProperty)this.vm(a,c);else{if(b===this.nodeCategoryProperty){this.sq(a,c);return}if(b===this.nodeParentKeyProperty){this.He(a,c);return}}var d=en(a,b);d!==c&&(Oj(a,b,c),this.lt(a,b,d,c))};t=Gq.prototype;t.qq=function(a,b){Z.prototype.qq.call(this,a,b);for(var c=this.cb.iterator;c.next();)this.Bv(c.value,a,b)};
t.Bv=function(a,b,c){if(this.Oi(a)===b){var d=this.se;Oj(a,d,c);zq(this,"nodeParentKey",af,d,a,b,c);"string"===typeof d&&this.Ea(a,d)}};t.rm=function(a){Z.prototype.rm.call(this,a);a=this.pa(a);var b=hq(this,a);if(null!==b){var c=Ka();for(b=b.iterator;b.next();){var d=b.value;if(this.Pb(d)&&this.Oi(d)===a){var e=this.se;zq(this,"nodeParentKey",af,e,d,a,a);"string"===typeof e&&this.Ea(d,e);c.push(d)}}for(b=0;b<c.length;b++)iq(this,a,c[b]);Oa(c)}};
t.qm=function(a){Z.prototype.qm.call(this,a);var b=this.Oi(a);b=this.gm(b);null===this.Qb(b)&&jq(this,b,a)};t.vq=function(a){Z.prototype.vq.call(this,a);var b=this.Oi(a);iq(this,b,a)};t.fm=function(){return!0};t.ct=function(){return!0};
pa.Object.defineProperties(Gq.prototype,{nodeParentKeyProperty:{get:function(){return this.se},set:function(a){var b=this.se;b!==a&&(this.se=a,this.g("nodeParentKeyProperty",b,a))}},Yj:{get:function(){return this.en},set:function(a){this.en!==a&&(this.en=a)}},parentLinkCategoryProperty:{get:function(){return this.Bj},set:function(a){var b=this.Bj;b!==a&&(this.Bj=a,this.g("parentLinkCategoryProperty",b,a))}},
linkCategoryProperty:{get:function(){return this.parentLinkCategoryProperty},set:function(a){this.parentLinkCategoryProperty=a}}});pa.Object.defineProperties(Gq,{type:{get:function(){return"TreeModel"}}});Gq.prototype.setParentLinkCategoryForNodeData=Gq.prototype.Iv;Gq.prototype.getParentLinkCategoryForNodeData=Gq.prototype.bv;Gq.prototype.setParentKeyForNodeData=Gq.prototype.He;Gq.prototype.getParentKeyForNodeData=Gq.prototype.Oi;
Gq.className="TreeModel";xq.TreeModel=Gq;function Hq(){yi.call(this);this.uw=this.yn=this.Xb=0;this.jr=360;this.tw=Iq;this.hj=0;this.fw=new H;this.Wq=this.Rd=0;this.Gs=new Jq;this.Lt=this.Aj=0;this.Tx=600;this.To=NaN;this.Rm=1;this.vp=0;this.Kl=360;this.Ab=Iq;this.L=Kq;this.Qc=Lq;this.Mc=Gp;this.$e=6;this.Do=Mq}oa(Hq,yi);
Hq.prototype.cloneProtected=function(a){yi.prototype.cloneProtected.call(this,a);a.To=this.To;a.Rm=this.Rm;a.vp=this.vp;a.Kl=this.Kl;a.Ab=this.Ab;a.L=this.L;a.Qc=this.Qc;a.Mc=this.Mc;a.$e=this.$e;a.Do=this.Do};
Hq.prototype.fb=function(a){if(a.classType===Hq)if(a===Nq||a===Pq||a===Qq||a===Rq||a===Lq)this.sorting=a;else if(a===Sq||a===Tq||a===Kq||a===Uq)this.direction=a;else if(a===Vq||a===Wq||a===Iq||a===Xq)this.arrangement=a;else{if(a===Yq||a===Mq)this.nodeDiameterFormula=a}else yi.prototype.fb.call(this,a)};Hq.prototype.createNetwork=function(){return new Zq(this)};
Hq.prototype.doLayout=function(a){null===this.network&&(this.network=this.makeNetwork(a));this.arrangementOrigin=this.initialOrigin(this.arrangementOrigin);a=this.network.vertexes;if(1>=a.count)1===a.count&&(a=a.first(),a.centerX=0,a.centerY=0);else{var b=new E;b.addAll(a.iterator);a=new E;var c=new E;var d=this.sort(b);var e,f,g=this.Wq;var h=this.arrangement;var k=this.nodeDiameterFormula;var l=this.radius;if(!isFinite(l)||0>=l)l=NaN;var m=this.aspectRatio;if(!isFinite(m)||0>=m)m=1;var n=this.startAngle;
isFinite(n)||(n=0);var p=this.sweepAngle;if(!isFinite(p)||360<p||1>p)p=360;b=this.spacing;isFinite(b)||(b=NaN);h===Xq&&k===Yq?h=Iq:h===Xq&&k!==Yq&&(h=this.arrangement);if((this.direction===Sq||this.direction===Tq)&&this.sorting!==Lq){for(k=0;!(k>=d.length);k+=2){a.add(d.N(k));if(k+1>=d.length)break;c.add(d.N(k+1))}this.direction===Sq?(this.arrangement===Xq&&a.reverse(),d=new E,d.addAll(a),d.addAll(c)):(this.arrangement===Xq&&c.reverse(),d=new E,d.addAll(c),d.addAll(a))}k=d.length;for(var q=f=e=0;q<
d.length;q++){var r=n+p*f*(this.direction===Kq?1:-1)/k,u=d.N(q).diameter;isNaN(u)&&(u=$q(d.N(q),r));360>p&&(0===q||q===d.length-1)&&(u/=2);e+=u;f++}if(isNaN(l)||h===Xq){isNaN(b)&&(b=6);if(h!==Iq&&h!==Xq){f=-Infinity;for(g=0;g<k;g++)q=d.N(g),e=d.N(g===k-1?0:g+1),isNaN(q.diameter)&&$q(q,0),isNaN(e.diameter)&&$q(e,0),f=Math.max(f,(q.diameter+e.diameter)/2);g=f+b;h===Vq?l=(f+b)/(2*Math.PI/k):l=ar(this,g*(360<=p?k:k-1),m,n*Math.PI/180,p*Math.PI/180)}else l=ar(this,e+(360<=p?k:k-1)*(h!==Xq?b:1.6*b),m,n*
Math.PI/180,p*Math.PI/180);f=l*m}else if(f=l*m,q=br(this,l,f,n*Math.PI/180,p*Math.PI/180),isNaN(b)){if(h===Iq||h===Xq)b=(q-e)/(360<=p?k:k-1)}else if(h===Iq||h===Xq)q=(q-e)/(360<=p?k:k-1),q<b?(l=ar(this,e+b*(360<=p?k:k-1),m,n*Math.PI/180,p*Math.PI/180),f=l*m):b=q;else{g=-Infinity;for(e=0;e<k;e++)r=d.N(e),u=d.N(e===k-1?0:e+1),isNaN(r.diameter)&&$q(r,0),isNaN(u.diameter)&&$q(u,0),g=Math.max(g,(r.diameter+u.diameter)/2);g+=b;e=ar(this,g*(360<=p?k:k-1),m,n*Math.PI/180,p*Math.PI/180);e>l?(l=e,f=l*m):g=
q/(360<=p?k:k-1)}this.tw=h;this.Xb=l;this.yn=m;this.uw=n;this.jr=p;this.hj=b;this.Rd=f;this.Wq=g;b=d;d=this.tw;h=this.Xb;l=this.uw;m=this.jr;n=this.hj;p=this.Rd;k=this.Wq;if(this.direction!==Sq&&this.direction!==Tq||d!==Xq)if(this.direction===Sq||this.direction===Tq){g=0;switch(d){case Wq:g=180*cr(this,h,p,l,k)/Math.PI;break;case Iq:k=b=0;g=a.first();null!==g&&(b=$q(g,Math.PI/2));g=c.first();null!==g&&(k=$q(g,Math.PI/2));g=180*cr(this,h,p,l,n+(b+k)/2)/Math.PI;break;case Vq:g=m/b.length}if(this.direction===
Sq){switch(d){case Wq:dr(this,a,l,Uq);break;case Iq:er(this,a,l,Uq);break;case Vq:fr(this,a,m/2,l,Uq)}switch(d){case Wq:dr(this,c,l+g,Kq);break;case Iq:er(this,c,l+g,Kq);break;case Vq:fr(this,c,m/2,l+g,Kq)}}else{switch(d){case Wq:dr(this,c,l,Uq);break;case Iq:er(this,c,l,Uq);break;case Vq:fr(this,c,m/2,l,Uq)}switch(d){case Wq:dr(this,a,l+g,Kq);break;case Iq:er(this,a,l+g,Kq);break;case Vq:fr(this,a,m/2,l+g,Kq)}}}else switch(d){case Wq:dr(this,b,l,this.direction);break;case Iq:er(this,b,l,this.direction);
break;case Vq:fr(this,b,m,l,this.direction);break;case Xq:gr(this,b,m,l,this.direction)}else gr(this,b,m,l-m/2,Kq)}this.updateParts();this.network=null;this.isValidLayout=!0};function fr(a,b,c,d,e){var f=a.jr,g=a.Xb;a=a.Rd;d=d*Math.PI/180;c=c*Math.PI/180;for(var h=b.length,k=0;k<h;k++){var l=d+(e===Kq?k*c/(360<=f?h:h-1):-(k*c)/h),m=b.N(k),n=g*Math.tan(l)/a;n=Math.sqrt((g*g+a*a*n*n)/(1+n*n));m.centerX=n*Math.cos(l);m.centerY=n*Math.sin(l);m.actualAngle=180*l/Math.PI}}
function er(a,b,c,d){var e=a.Xb,f=a.Rd,g=a.hj;c=c*Math.PI/180;for(var h=b.length,k=0;k<h;k++){var l=b.N(k),m=b.N(k===h-1?0:k+1),n=f*Math.sin(c);l.centerX=e*Math.cos(c);l.centerY=n;l.actualAngle=180*c/Math.PI;isNaN(l.diameter)&&$q(l,0);isNaN(m.diameter)&&$q(m,0);l=cr(a,e,f,d===Kq?c:-c,(l.diameter+m.diameter)/2+g);c+=d===Kq?l:-l}}
function dr(a,b,c,d){var e=a.Xb,f=a.Rd,g=a.Wq;c=c*Math.PI/180;for(var h=b.length,k=0;k<h;k++){var l=b.N(k);l.centerX=e*Math.cos(c);l.centerY=f*Math.sin(c);l.actualAngle=180*c/Math.PI;l=cr(a,e,f,d===Kq?c:-c,g);c+=d===Kq?l:-l}}function gr(a,b,c,d,e){var f=a.jr;a.Aj=0;a.Gs=new Jq;if(360>c){for(f=d+(e===Kq?f:-f);0>f;)f+=360;f%=360;180<f&&(f-=360);f*=Math.PI/180;a.Lt=f;hr(a,b,c,d,e)}else ir(a,b,c,d,e);a.Gs.commit(b)}
function ir(a,b,c,d,e){var f=a.Xb,g=a.hj,h=a.yn,k=f*Math.cos(d*Math.PI/180),l=a.Rd*Math.sin(d*Math.PI/180),m=b.Na();if(3===m.length)m[0].centerX=f,m[0].centerY=0,m[1].centerX=m[0].centerX-m[0].width/2-m[1].width/2-g,m[1].y=m[0].y,m[2].centerX=(m[0].centerX+m[1].centerX)/2,m[2].y=m[0].y-m[2].height-g;else if(4===m.length)m[0].centerX=f,m[0].centerY=0,m[2].centerX=-m[0].centerX,m[2].centerY=m[0].centerY,m[1].centerX=0,m[1].y=Math.min(m[0].y,m[2].y)-m[1].height-g,m[3].centerX=0,m[3].y=Math.max(m[0].y+
m[0].height+g,m[2].y+m[2].height+g);else{f=H.alloc();for(var n=0;n<m.length;n++){m[n].centerX=k;m[n].centerY=l;if(n>=m.length-1)break;jr(a,k,l,m,n,e,f)||kr(a,k,l,m,n,e,f);k=f.x;l=f.y}H.free(f);a.Aj++;if(!(23<a.Aj)){k=m[0].centerX;l=m[0].centerY;f=m[m.length-1].centerX;n=m[m.length-1].centerY;var p=Math.abs(k-f)-((m[0].width+m[m.length-1].width)/2+g),q=Math.abs(l-n)-((m[0].height+m[m.length-1].height)/2+g);g=0;1>Math.abs(q)?Math.abs(k-f)<(m[0].width+m[m.length-1].width)/2&&(g=0):g=0<q?q:1>Math.abs(p)?
0:p;k=Math.abs(f)>Math.abs(n)?0<f!==l>n:0<n!==k<f;if(k=e===Kq?k:!k)g=-Math.abs(g),g=Math.min(g,-m[m.length-1].width),g=Math.min(g,-m[m.length-1].height);a.Gs.compare(g,m);1<Math.abs(g)&&(a.Xb=8>a.Aj?a.Xb-g/(2*Math.PI):5>m.length&&10<g?a.Xb/2:a.Xb-(0<g?1.7:-2.3),a.Rd=a.Xb*h,ir(a,b,c,d,e))}}}
function hr(a,b,c,d,e){for(var f=a.Xb,g=a.Rd,h=a.yn,k=f*Math.cos(d*Math.PI/180),l=g*Math.sin(d*Math.PI/180),m=H.alloc(),n=b.Na(),p=0;p<n.length;p++){n[p].centerX=k;n[p].centerY=l;if(p>=n.length-1)break;jr(a,k,l,n,p,e,m)||kr(a,k,l,n,p,e,m);k=m.x;l=m.y}H.free(m);a.Aj++;if(!(23<a.Aj)){k=Math.atan2(l,k);k=e===Kq?a.Lt-k:k-a.Lt;k=Math.abs(k)<Math.abs(k-2*Math.PI)?k:k-2*Math.PI;f=k*(f+g)/2;g=a.Gs;if(Math.abs(f)<Math.abs(g.am))for(g.am=f,g.vk=[],g.Am=[],k=0;k<n.length;k++)g.vk[k]=n[k].bounds.x,g.Am[k]=n[k].bounds.y;
1<Math.abs(f)&&(a.Xb=8>a.Aj?a.Xb-f/(2*Math.PI):a.Xb-(0<f?1.7:-2.3),a.Rd=a.Xb*h,hr(a,b,c,d,e))}}function jr(a,b,c,d,e,f,g){var h=a.Xb,k=a.Rd,l=0;a=(d[e].width+d[e+1].width)/2+a.hj;var m=!1;if(0<=c!==(f===Kq)){if(f=b+a,f>h){f=b-a;if(f<-h)return g.x=f,g.y=l,!1;m=!0}}else if(f=b-a,f<-h){f=b+a;if(f>h)return g.x=f,g.y=l,!1;m=!0}l=Math.sqrt(1-Math.min(1,f*f/(h*h)))*k;0>c!==m&&(l=-l);if(Math.abs(c-l)>(d[e].height+d[e+1].height)/2)return g.x=f,g.y=l,!1;g.x=f;g.y=l;return!0}
function kr(a,b,c,d,e,f,g){var h=a.Xb,k=a.Rd,l=0;a=(d[e].height+d[e+1].height)/2+a.hj;d=!1;if(0<=b!==(f===Kq)){if(f=c-a,f<-k){f=c+a;if(f>k){g.x=l;g.y=f;return}d=!0}}else if(f=c+a,f>k){f=c-a;if(f<-k){g.x=l;g.y=f;return}d=!0}l=Math.sqrt(1-Math.min(1,f*f/(k*k)))*h;0>b!==d&&(l=-l);g.x=l;g.y=f}Hq.prototype.commitLayout=function(){this.commitNodes();this.isRouting&&this.commitLinks()};
Hq.prototype.commitNodes=function(){var a=null!==this.group&&null!==this.group.placeholder&&this.group.isSubGraphExpanded,b=a?this.group.location.copy():null,c=this.actualCenter;a?c=new H(0,0):(c.x=this.arrangementOrigin.x+this.Xb,c.y=this.arrangementOrigin.y+this.Rd);for(var d=this.network.vertexes.iterator;d.next();){var e=d.value;e.x+=c.x;e.y+=c.y;e.commit()}a&&(this.group.$b(),a=this.group.position.copy(),c=this.group.location.copy(),b=b.Xd(c.Xd(a)),this.group.move(b),this.fw=b.Xd(a))};
Hq.prototype.commitLinks=function(){for(var a=this.network.edges.iterator;a.next();)a.value.commit()};function br(a,b,c,d,e){var f=a.Tx;if(.001>Math.abs(a.yn-1))return void 0!==d&&void 0!==e?e*b:2*Math.PI*b;a=b>c?Math.sqrt(b*b-c*c)/b:Math.sqrt(c*c-b*b)/c;var g=0;var h=void 0!==d&&void 0!==e?e/(f+1):Math.PI/(2*(f+1));for(var k=0,l=0;l<=f;l++){void 0!==d&&void 0!==e?k=d+l*e/f:k=l*Math.PI/(2*f);var m=Math.sin(k);g+=Math.sqrt(1-a*a*m*m)*h}return void 0!==d&&void 0!==e?(b>c?b:c)*g:4*(b>c?b:c)*g}
function ar(a,b,c,d,e){return b/(void 0!==d&&void 0!==e?br(a,1,c,d,e):br(a,1,c))}function cr(a,b,c,d,e){if(.001>Math.abs(a.yn-1))return e/b;var f=b>c?Math.sqrt(b*b-c*c)/b:Math.sqrt(c*c-b*b)/c,g=0;a=2*Math.PI/(700*a.network.vertexes.count);b>c&&(d+=Math.PI/2);for(var h=0;;h++){var k=Math.sin(d+h*a);g+=(b>c?b:c)*Math.sqrt(1-f*f*k*k)*a;if(g>=e)return h*a}}
Hq.prototype.sort=function(a){switch(this.sorting){case Qq:break;case Rq:a.reverse();break;case Nq:a.sort(this.comparer);break;case Pq:a.sort(this.comparer);a.reverse();break;case Lq:for(var b=[],c=0;c<a.length;c++)b.push(0);c=new E;for(var d=0;d<a.length;d++){var e=-1,f=-1;if(0===d)for(var g=0;g<a.length;g++){var h=a.N(g).edgesCount;h>e&&(e=h,f=g)}else for(g=0;g<a.length;g++)h=b[g],h>e&&(e=h,f=g);c.add(a.N(f));b[f]=-1;f=a.N(f);for(g=f.sourceEdges;g.next();)e=a.indexOf(g.value.fromVertex),0>e||0<=
b[e]&&b[e]++;for(f=f.destinationEdges;f.next();)e=a.indexOf(f.value.toVertex),0>e||0<=b[e]&&b[e]++}a=[];for(b=0;b<c.length;b++){e=c.N(b);a[b]=[];for(f=e.destinationEdges;f.next();)d=c.indexOf(f.value.toVertex),d!==b&&0>a[b].indexOf(d)&&a[b].push(d);for(e=e.sourceEdges;e.next();)d=c.indexOf(e.value.fromVertex),d!==b&&0>a[b].indexOf(d)&&a[b].push(d)}f=[];for(b=0;b<a.length;b++)f[b]=0;b=[];g=[];h=[];e=[];d=new E;for(var k=0,l=0;l<a.length;l++){var m=a[l].length;if(1===m)e.push(l);else if(0===m)d.add(c.N(l));
else{if(0===k)b.push(l);else{for(var n=m=Infinity,p=-1,q=[],r=0;r<b.length;r++)0>a[b[r]].indexOf(b[r===b.length-1?0:r+1])&&q.push(r===b.length-1?0:r+1);if(0===q.length)for(r=0;r<b.length;r++)q.push(r);for(r=0;r<q.length;r++){for(var u=q[r],x=a[l],v=0,y=0;y<g.length;y++){var z=f[g[y]],B=f[h[y]];if(z<B){var C=z;z=B}else C=B;if(C<u&&u<=z)for(B=0;B<x.length;B++){var G=x[B];0>b.indexOf(G)||C<f[G]&&f[G]<z||C===f[G]||z===f[G]||v++}else for(B=0;B<x.length;B++)G=x[B],0>b.indexOf(G)||C<f[G]&&f[G]<z&&C!==f[G]&&
z!==f[G]&&v++}x=v;for(y=v=0;y<a[l].length;y++)C=b.indexOf(a[l][y]),0<=C&&(C=Math.abs(u-(C>=u?C+1:C)),v+=C<b.length+1-C?C:b.length+1-C);for(y=0;y<g.length;y++)C=f[g[y]],z=f[h[y]],C>=u&&C++,z>=u&&z++,C>z&&(B=z,z=C,C=B),z-C<(b.length+2)/2===(C<u&&u<=z)&&v++;if(x<m||x===m&&v<n)m=x,n=v,p=u}b.splice(p,0,l);for(m=0;m<b.length;m++)f[b[m]]=m;for(m=0;m<a[l].length;m++)n=a[l][m],0<=b.indexOf(n)&&(g.push(l),h.push(n))}k++}}for(g=b.length;;){f=!0;for(h=0;h<e.length;h++)if(k=e[h],l=a[k][0],m=b.indexOf(l),0<=m){for(p=
n=0;p<a[l].length;p++)q=b.indexOf(a[l][p]),0>q||q===m||(r=q>m?q-m:m-q,n+=q<m!==r>g-r?1:-1);b.splice(0>n?m:m+1,0,k);e.splice(h,1);h--}else f=!1;if(f)break;else b.push(e[0]),e.splice(0,1)}for(a=0;a<b.length;a++)d.add(c.N(b[a]));return d;default:A("Invalid sorting type.")}return a};
pa.Object.defineProperties(Hq.prototype,{radius:{get:function(){return this.To},set:function(a){this.To!==a&&(0<a||isNaN(a))&&(this.To=a,this.B())}},aspectRatio:{get:function(){return this.Rm},set:function(a){this.Rm!==a&&0<a&&(this.Rm=a,this.B())}},startAngle:{get:function(){return this.vp},set:function(a){this.vp!==a&&(this.vp=a,this.B())}},sweepAngle:{get:function(){return this.Kl},
set:function(a){this.Kl!==a&&(0<a&&360>=a?this.Kl=a:this.Kl=360,this.B())}},arrangement:{get:function(){return this.Ab},set:function(a){this.Ab===a||a!==Xq&&a!==Iq&&a!==Wq&&a!==Vq||(this.Ab=a,this.B())}},direction:{get:function(){return this.L},set:function(a){this.L===a||a!==Kq&&a!==Uq&&a!==Sq&&a!==Tq||(this.L=a,this.B())}},sorting:{get:function(){return this.Qc},set:function(a){this.Qc===a||a!==Qq&&a!==Rq&&
a!==Nq&&!Pq&&a!==Lq||(this.Qc=a,this.B())}},comparer:{get:function(){return this.Mc},set:function(a){this.Mc!==a&&(this.Mc=a,this.B())}},spacing:{get:function(){return this.$e},set:function(a){this.$e!==a&&(this.$e=a,this.B())}},nodeDiameterFormula:{get:function(){return this.Do},set:function(a){this.Do===a||a!==Mq&&a!==Yq||(this.Do=a,this.B())}},actualXRadius:{get:function(){return this.Xb}},
actualYRadius:{get:function(){return this.Rd}},actualSpacing:{get:function(){return this.hj}},actualCenter:{get:function(){return this.fw}}});
var Iq=new D(Hq,"ConstantSpacing",0),Wq=new D(Hq,"ConstantDistance",1),Vq=new D(Hq,"ConstantAngle",2),Xq=new D(Hq,"Packed",3),Kq=new D(Hq,"Clockwise",4),Uq=new D(Hq,"Counterclockwise",5),Sq=new D(Hq,"BidirectionalLeft",6),Tq=new D(Hq,"BidirectionalRight",7),Qq=new D(Hq,"Forwards",8),Rq=new D(Hq,"Reverse",9),Nq=new D(Hq,"Ascending",10),Pq=new D(Hq,"Descending",11),Lq=new D(Hq,"Optimized",12),Mq=new D(Hq,"Pythagorean",13),Yq=new D(Hq,"Circular",14);Hq.className="CircularLayout";Hq.ConstantSpacing=Iq;
Hq.ConstantDistance=Wq;Hq.ConstantAngle=Vq;Hq.Packed=Xq;Hq.Clockwise=Kq;Hq.Counterclockwise=Uq;Hq.BidirectionalLeft=Sq;Hq.BidirectionalRight=Tq;Hq.Forwards=Qq;Hq.Reverse=Rq;Hq.Ascending=Nq;Hq.Descending=Pq;Hq.Optimized=Lq;Hq.Pythagorean=Mq;Hq.Circular=Yq;function Jq(){this.am=-Infinity;this.Am=this.vk=null}
Jq.prototype.compare=function(a,b){if(0<a&&0>this.am||Math.abs(a)<Math.abs(this.am)&&!(0>a&&0<this.am))for(this.am=a,this.vk=[],this.Am=[],a=0;a<b.length;a++)this.vk[a]=b[a].bounds.x,this.Am[a]=b[a].bounds.y};Jq.prototype.commit=function(a){if(null!==this.vk&&null!==this.Am)for(var b=0;b<this.vk.length;b++){var c=a.N(b);c.x=this.vk[b];c.y=this.Am[b]}};Jq.className="VertexArrangement";function Zq(a){xp.call(this,a)}oa(Zq,xp);Zq.prototype.createVertex=function(){return new lr(this)};
Zq.prototype.createEdge=function(){return new mr(this)};Zq.className="CircularNetwork";function lr(a){Ap.call(this,a);this.K=this.gj=NaN}oa(lr,Ap);
function $q(a,b){var c=a.network;if(null===c)return NaN;c=c.layout;if(null===c)return NaN;if(c.arrangement===Xq)if(c.nodeDiameterFormula===Yq)a.gj=Math.max(a.width,a.height);else{c=Math.abs(Math.sin(b));b=Math.abs(Math.cos(b));if(0===c)return a.width;if(0===b)return a.height;a.gj=Math.min(a.height/c,a.width/b)}else a.gj=c.nodeDiameterFormula===Yq?Math.max(a.width,a.height):Math.sqrt(a.width*a.width+a.height*a.height);return a.gj}
pa.Object.defineProperties(lr.prototype,{diameter:{get:function(){return this.gj},set:function(a){this.gj!==a&&(this.gj=a)}},actualAngle:{get:function(){return this.K},set:function(a){this.K!==a&&(this.K=a)}}});lr.className="CircularVertex";function mr(a){Bp.call(this,a)}oa(mr,Bp);mr.className="CircularEdge";
function nr(){yi.call(this);this.ph=null;this.Zn=0;this.wd=(new L(100,100)).freeze();this.Qm=!1;this.Ze=!0;this.Xc=!1;this.ql=100;this.Bn=1;this.Kf=1E3;this.uo=10;this.Uo=Math;this.Qk=.05;this.Pk=50;this.Nk=150;this.Ok=0;this.on=10;this.nn=5}oa(nr,yi);
nr.prototype.cloneProtected=function(a){yi.prototype.cloneProtected.call(this,a);a.wd.assign(this.wd);a.Qm=this.Qm;a.Ze=this.Ze;a.Xc=this.Xc;a.ql=this.ql;a.Bn=this.Bn;a.Kf=this.Kf;a.uo=this.uo;a.Uo=this.Uo;a.Qk=this.Qk;a.Pk=this.Pk;a.Nk=this.Nk;a.Ok=this.Ok;a.on=this.on;a.nn=this.nn};nr.prototype.createNetwork=function(){return new or(this)};
nr.prototype.doLayout=function(a){null===this.network&&(this.network=this.makeNetwork(a));a=this.maxIterations;if(0<this.network.vertexes.count){this.network.Sp();for(var b=this.network.vertexes.iterator;b.next();){var c=b.value;c.charge=this.electricalCharge(c);c.mass=this.gravitationalMass(c)}for(b=this.network.edges.iterator;b.next();)c=b.value,c.stiffness=this.springStiffness(c),c.length=this.springLength(c);this.Hu();this.Zn=0;if(this.needsClusterLayout()){b=this.network;for(c=b.Jx().iterator;c.next();){this.network=
c.value;for(var d=this.network.vertexes.iterator;d.next();){var e=d.value;e.td=e.vertexes.count;e.Bh=1;e.Wj=null;e.Ge=null}pr(this,0,a)}this.network=b;c.reset();d=this.arrangementSpacing;for(var f=c.count,g=!0,h=e=0,k=Ka(),l=0;l<f+b.vertexes.count+2;l++)k[l]=null;f=0;c.reset();for(var m=N.alloc();c.next();)if(l=c.value,this.computeBounds(l,m),g)g=!1,e=m.x+m.width/2,h=m.y+m.height/2,k[0]=new H(m.x+m.width+d.width,m.y),k[1]=new H(m.x,m.y+m.height+d.height),f=2;else{var n=qr(k,f,e,h,m.width,m.height,
d),p=k[n],q=new H(p.x+m.width+d.width,p.y),r=new H(p.x,p.y+m.height+d.height);n+1<f&&k.splice(n+1,0,null);k[n]=q;k[n+1]=r;f++;n=p.x-m.x;p=p.y-m.y;for(l=l.vertexes.iterator;l.next();)q=l.value,q.centerX+=n,q.centerY+=p}N.free(m);for(l=b.vertexes.iterator;l.next();)g=l.value,q=g.bounds,2>f?(e=q.x+q.width/2,h=q.y+q.height/2,k[0]=new H(q.x+q.width+d.width,q.y),k[1]=new H(q.x,q.y+q.height+d.height),f=2):(m=qr(k,f,e,h,q.width,q.height,d),p=k[m],n=new H(p.x+q.width+d.width,p.y),q=new H(p.x,p.y+q.height+
d.height),m+1<f&&k.splice(m+1,0,null),k[m]=n,k[m+1]=q,f++,g.centerX=p.x+g.width/2,g.centerY=p.y+g.height/2);Oa(k);for(c.reset();c.next();){d=c.value;for(e=d.vertexes.iterator;e.next();)b.th(e.value);for(d=d.edges.iterator;d.next();)b.Qj(d.value)}}rr(this,a);this.updateParts()}this.ql=a;this.network=null;this.isValidLayout=!0};
nr.prototype.needsClusterLayout=function(){if(3>this.network.vertexes.count)return!1;for(var a=0,b=0,c=this.network.vertexes.first().bounds,d=this.network.vertexes.iterator;d.next();){if(d.value.bounds.Ic(c)&&(a++,2<a))return!0;if(10<b)break;b++}return!1};nr.prototype.computeBounds=function(a,b){var c=!0;for(a=a.vertexes.iterator;a.next();){var d=a.value;c?(c=!1,b.set(d.bounds)):b.Jc(d.bounds)}return b};
function pr(a,b,c){if(sr(a,b)){var d=a.Kf;a.Kf*=1+1/(b+1);var e=tr(a,b),f=Math.max(0,Math.max(Math.min(a.network.vertexes.count,c*(b+1)/11),10));a.maxIterations+=f;pr(a,b+1,c);rr(a,f);ur(a,e);b=e.vertexes.Na();b.sort(function(a,b){return null===a||null===b||a===b?0:b.td-a.td});for(c=0;c<b.length;c++)vr(a,b[c]);a.Kf=d}}
function sr(a,b){if(10<b||3>a.network.vertexes.count)return!1;a.ph=a.network.vertexes.Na();a=a.ph;a.sort(function(a,b){return null===a||null===b||a===b?0:b.td-a.td});for(b=a.length-1;0<=b&&1>=a[b].td;)b--;return 1<a.length-b}
function tr(a,b){for(var c=a.network,d=new or(a),e=0;e<a.ph.length;e++){var f=a.ph[e];if(1<f.td){d.th(f);var g=new wr;g.At=f.td;g.Bt=f.width;g.zt=f.height;g.aw=f.focus.x;g.bw=f.focus.y;null===f.Ge&&(f.Ge=new E);f.Ge.add(g);f.Ev=f.Ge.count-1}else break}for(f=c.edges.iterator;f.next();){var h=f.value;e=h.fromVertex;g=h.toVertex;e.network===d&&g.network===d?d.Qj(h):e.network===d?(h=e.Wj,null===h&&(h=new E,e.Wj=h),h.add(g),e.td--,e.Bh+=g.Bh):g.network===d&&(h=g.Wj,null===h&&(h=new E,g.Wj=h),h.add(e),
g.td--,g.Bh+=e.Bh)}for(e=d.edges.iterator;e.next();)f=e.value,f.length*=Math.max(1,I.sqrt((f.fromVertex.Bh+f.toVertex.Bh)/(4*b+1)));for(b=d.vertexes.iterator;b.next();){e=b.value;var k=e.Wj;if(null!==k&&0<k.count&&(g=e.Ge.N(e.Ge.count-1).At-e.td,!(0>=g))){for(var l=h=0,m=k.count-g;m<k.count;m++){var n=k.N(m),p=null;for(f=n.edges.iterator;f.next();){var q=f.value;if(q.kx(n)===e){p=q;break}}null!==p&&(l+=p.length,h+=n.width*n.height)}f=e.centerX;k=e.centerY;m=e.width;n=e.height;p=e.focus;q=m*n;1>q&&
(q=1);h=I.sqrt((h+q+l*l*4/(g*g))/q);g=(h-1)*m/2;h=(h-1)*n/2;e.bounds=new N(f-p.x-g,k-p.y-h,m+2*g,n+2*h);e.focus=new H(p.x+g,p.y+h)}}a.network=d;return c}function ur(a,b){for(var c=a.network.vertexes.iterator;c.next();){var d=c.value;d.network=b;if(null!==d.Ge){var e=d.Ge.N(d.Ev);d.td=e.At;var f=e.aw,g=e.bw;d.bounds=new N(d.centerX-f,d.centerY-g,e.Bt,e.zt);d.focus=new H(f,g);d.Ev--}}for(c=a.network.edges.iterator;c.next();)c.value.network=b;a.network=b}
function vr(a,b){var c=b.Wj;if(null!==c&&0!==c.count){var d=b.centerX,e=b.centerY,f=b.width,g=b.height;null!==b.Ge&&0<b.Ge.count&&(g=b.Ge.N(0),f=g.Bt,g=g.zt);f=I.sqrt(f*f+g*g)/2;for(var h=!1,k=g=0,l=0,m=b.vertexes.iterator;m.next();){var n=m.value;1>=n.td?k++:(h=!0,l++,g+=Math.atan2(b.centerY-n.centerY,b.centerX-n.centerX))}if(0!==k)for(0<l&&(g/=l),l=b=0,b=h?2*Math.PI/(k+1):2*Math.PI/k,0===k%2&&(l=b/2),1<c.count&&c.sort(function(a,b){return null===a||null===b||a===b?0:b.width*b.height-a.width*a.height}),
h=0===k%2?0:1,c=c.iterator;c.next();)if(k=c.value,!(1<k.td||a.isFixed(k))){m=null;for(n=k.edges.iterator;n.next();){m=n.value;break}n=k.width;var p=k.height;n=I.sqrt(n*n+p*p)/2;m=f+m.length+n;n=g+(b*(h/2>>1)+l)*(0===h%2?1:-1);k.centerX=d+m*Math.cos(n);k.centerY=e+m*Math.sin(n);h++}}}
function qr(a,b,c,d,e,f,g){var h=9E19,k=-1,l=0;a:for(;l<b;l++){var m=a[l],n=m.x-c,p=m.y-d;n=n*n+p*p;if(n<h){for(p=l-1;0<=p;p--)if(a[p].y>m.y&&a[p].x-m.x<e+g.width)continue a;for(p=l+1;p<b;p++)if(a[p].x>m.x&&a[p].y-m.y<f+g.height)continue a;k=l;h=n}}return k}nr.prototype.Hu=function(){if(this.comments)for(var a=this.network.vertexes.iterator;a.next();)this.addComments(a.value)};
nr.prototype.addComments=function(a){var b=a.node;if(null!==b)for(b=b.Xu();b.next();){var c=b.value;if("Comment"===c.category&&c.isVisible()){var d=this.network.Ki(c);null===d&&(d=this.network.Rl(c));d.charge=this.defaultCommentElectricalCharge;c=null;for(var e=d.destinationEdges;e.next();){var f=e.value;if(f.toVertex===a){c=f;break}}if(null===c)for(e=d.sourceEdges;e.next();)if(f=e.value,f.fromVertex===a){c=f;break}null===c&&(c=this.network.hk(a,d,null));c.length=this.defaultCommentSpringLength}}};
function xr(a,b){var c=a.bounds,d=c.x;a=c.y;var e=c.width;c=c.height;var f=b.bounds,g=f.x;b=f.y;var h=f.width;f=f.height;return d+e<g?a>b+f?(c=d+e-g,a=a-b-f,I.sqrt(c*c+a*a)):a+c<b?(d=d+e-g,a=a+c-b,I.sqrt(d*d+a*a)):g-(d+e):d>g+h?a>b+f?(c=d-g-h,a=a-b-f,I.sqrt(c*c+a*a)):a+c<b?(d=d-g-h,a=a+c-b,I.sqrt(d*d+a*a)):d-(g+h):a>b+f?a-(b+f):a+c<b?b-(a+c):.1}function rr(a,b){a.ph=null;for(b=a.Zn+b;a.Zn<b&&(a.Zn++,yr(a)););a.ph=null}
function yr(a){null===a.ph&&(a.ph=a.network.vertexes.Na());var b=a.ph;if(0>=b.length)return!1;var c=b[0];c.forceX=0;c.forceY=0;for(var d=c.centerX,e=d,f=c=c.centerY,g=1;g<b.length;g++){var h=b[g];h.forceX=0;h.forceY=0;var k=h.centerX;h=h.centerY;d=Math.min(d,k);e=Math.max(e,k);c=Math.min(c,h);f=Math.max(f,h)}(e=e-d>f-c)?b.sort(function(a,b){return null===a||null===b||a===b?0:a.centerX-b.centerX}):b.sort(function(a,b){return null===a||null===b||a===b?0:a.centerY-b.centerY});c=a.Kf;var l=d=h=0;for(f=
0;f<b.length;f++){g=b[f];d=g.bounds;h=g.focus;k=d.x+h.x;var m=d.y+h.y;d=g.charge*a.electricalFieldX(k,m);l=g.charge*a.electricalFieldY(k,m);d+=g.mass*a.gravitationalFieldX(k,m);l+=g.mass*a.gravitationalFieldY(k,m);g.forceX+=d;g.forceY+=l;for(var n=f+1;n<b.length;n++){var p=b[n];if(p!==g){d=p.bounds;h=p.focus;l=d.x+h.x;var q=d.y+h.y;if(k-l>c||l-k>c){if(e)break}else if(m-q>c||q-m>c){if(!e)break}else{var r=xr(g,p);1>r?(d=a.randomNumberGenerator,null===d&&(a.randomNumberGenerator=d=new zr),r=d.random(),
h=d.random(),k>l?(d=Math.abs(p.bounds.right-g.bounds.x),d=(1+d)*r):k<l?(d=Math.abs(p.bounds.x-g.bounds.right),d=-(1+d)*r):(d=Math.max(p.width,g.width),d=(1+d)*r-d/2),m>q?(l=Math.abs(p.bounds.bottom-g.bounds.y),l=(1+l)*h):k<l?(l=Math.abs(p.bounds.y-g.bounds.bottom),l=-(1+l)*h):(l=Math.max(p.height,g.height),l=(1+l)*h-l/2)):(h=-(g.charge*p.charge)/(r*r),d=(l-k)/r*h,l=(q-m)/r*h);g.forceX+=d;g.forceY+=l;p.forceX-=d;p.forceY-=l}}}}for(e=a.network.edges.iterator;e.next();)h=e.value,c=h.fromVertex,f=h.toVertex,
g=c.bounds,k=c.focus,d=g.x+k.x,g=g.y+k.y,m=f.bounds,n=f.focus,k=m.x+n.x,m=m.y+n.y,n=xr(c,f),1>n?(n=a.randomNumberGenerator,null===n&&(a.randomNumberGenerator=n=new zr),h=n.random(),n=n.random(),d=(d>k?1:-1)*(1+(f.width>c.width?f.width:c.width))*h,l=(g>m?1:-1)*(1+(f.height>c.height?f.height:c.height))*n):(h=h.stiffness*(n-h.length),d=(k-d)/n*h,l=(m-g)/n*h),c.forceX+=d,c.forceY+=l,f.forceX-=d,f.forceY-=l;d=0;e=a.moveLimit;for(c=0;c<b.length;c++)f=b[c],a.isFixed(f)?a.moveFixedVertex(f):(g=f.forceX,k=
f.forceY,g<-e?g=-e:g>e&&(g=e),k<-e?k=-e:k>e&&(k=e),f.centerX+=g,f.centerY+=k,d=Math.max(d,g*g+k*k));return d>a.epsilonDistance*a.epsilonDistance}nr.prototype.moveFixedVertex=function(){};nr.prototype.commitLayout=function(){this.Jv();this.commitNodes();this.isRouting&&this.commitLinks()};nr.prototype.Jv=function(){if(this.setsPortSpots)for(var a=this.network.edges.iterator;a.next();){var b=a.value.link;null!==b&&(b.fromSpot=Ed,b.toSpot=Ed)}};
nr.prototype.commitNodes=function(){var a=0,b=0;if(this.arrangesToOrigin){var c=N.alloc();this.computeBounds(this.network,c);b=this.arrangementOrigin;a=b.x-c.x;b=b.y-c.y;N.free(c)}c=N.alloc();for(var d=this.network.vertexes.iterator;d.next();){var e=d.value;if(0!==a||0!==b)c.assign(e.bounds),c.x+=a,c.y+=b,e.bounds=c;e.commit()}N.free(c)};nr.prototype.commitLinks=function(){for(var a=this.network.edges.iterator;a.next();)a.value.commit()};
nr.prototype.springStiffness=function(a){a=a.stiffness;return isNaN(a)?this.Qk:a};nr.prototype.springLength=function(a){a=a.length;return isNaN(a)?this.Pk:a};nr.prototype.electricalCharge=function(a){a=a.charge;return isNaN(a)?this.Nk:a};nr.prototype.electricalFieldX=function(){return 0};nr.prototype.electricalFieldY=function(){return 0};nr.prototype.gravitationalMass=function(a){a=a.mass;return isNaN(a)?this.Ok:a};nr.prototype.gravitationalFieldX=function(){return 0};
nr.prototype.gravitationalFieldY=function(){return 0};nr.prototype.isFixed=function(a){return a.isFixed};
pa.Object.defineProperties(nr.prototype,{currentIteration:{get:function(){return this.Zn}},arrangementSpacing:{get:function(){return this.wd},set:function(a){this.wd.A(a)||(this.wd.assign(a),this.B())}},arrangesToOrigin:{get:function(){return this.Qm},set:function(a){this.Qm!==a&&(this.Qm=a,this.B())}},setsPortSpots:{get:function(){return this.Ze},set:function(a){this.Ze!==a&&(this.Ze=
a,this.B())}},comments:{get:function(){return this.Xc},set:function(a){this.Xc!==a&&(this.Xc=a,this.B())}},maxIterations:{get:function(){return this.ql},set:function(a){this.ql!==a&&0<=a&&(this.ql=a,this.B())}},epsilonDistance:{get:function(){return this.Bn},set:function(a){this.Bn!==a&&0<a&&(this.Bn=a,this.B())}},infinityDistance:{get:function(){return this.Kf},set:function(a){this.Kf!==
a&&1<a&&(this.Kf=a,this.B())}},moveLimit:{get:function(){return this.uo},set:function(a){this.uo!==a&&1<a&&(this.uo=a,this.B())}},randomNumberGenerator:{get:function(){return this.Uo},set:function(a){this.Uo!==a&&(null!==a&&"function"!==typeof a.random&&A('ForceDirectedLayout.randomNumberGenerator must have a "random()" function on it: '+a),this.Uo=a)}},defaultSpringStiffness:{get:function(){return this.Qk},
set:function(a){this.Qk!==a&&(this.Qk=a,this.B())}},defaultSpringLength:{get:function(){return this.Pk},set:function(a){this.Pk!==a&&(this.Pk=a,this.B())}},defaultElectricalCharge:{get:function(){return this.Nk},set:function(a){this.Nk!==a&&(this.Nk=a,this.B())}},defaultGravitationalMass:{get:function(){return this.Ok},set:function(a){this.Ok!==a&&(this.Ok=a,this.B())}},defaultCommentSpringLength:{
get:function(){return this.on},set:function(a){this.on!==a&&(this.on=a,this.B())}},defaultCommentElectricalCharge:{get:function(){return this.nn},set:function(a){this.nn!==a&&(this.nn=a,this.B())}}});nr.className="ForceDirectedLayout";function wr(){this.bw=this.aw=this.zt=this.Bt=this.At=0}wr.className="ForceDirectedSubnet";function or(a){xp.call(this,a)}oa(or,xp);or.prototype.createVertex=function(){return new Ar(this)};or.prototype.createEdge=function(){return new Br(this)};
or.className="ForceDirectedNetwork";function Ar(a){Ap.call(this,a);this.Ma=!1;this.Ub=this.K=NaN;this.Bh=this.td=this.La=this.ba=0;this.Ge=this.Wj=null;this.Ev=0}oa(Ar,Ap);
pa.Object.defineProperties(Ar.prototype,{isFixed:{get:function(){return this.Ma},set:function(a){this.Ma!==a&&(this.Ma=a)}},charge:{get:function(){return this.K},set:function(a){this.K!==a&&(this.K=a)}},mass:{get:function(){return this.Ub},set:function(a){this.Ub!==a&&(this.Ub=a)}},forceX:{get:function(){return this.ba},set:function(a){this.ba!==a&&(this.ba=a)}},forceY:{
get:function(){return this.La},set:function(a){this.La!==a&&(this.La=a)}}});Ar.className="ForceDirectedVertex";function Br(a){Bp.call(this,a);this.l=this.u=NaN}oa(Br,Bp);pa.Object.defineProperties(Br.prototype,{stiffness:{get:function(){return this.u},set:function(a){this.u!==a&&(this.u=a)}},length:{get:function(){return this.l},set:function(a){this.l!==a&&(this.l=a)}}});Br.className="ForceDirectedEdge";
function zr(){var a=0;void 0===a&&(a=42);this.seed=a;this.Lx=48271;this.Nx=2147483647;this.Q=44488.07041494893;this.Px=3399;this.Mx=1/2147483647;this.random()}zr.prototype.random=function(){var a=this.seed%this.Q*this.Lx-this.seed/this.Q*this.Px;0<a?this.seed=a:this.seed=a+this.Nx;return this.seed*this.Mx};zr.className="RandomNumberGenerator";
function Cr(){yi.call(this);this.Wb=this.je=25;this.L=0;this.Lk=Dr;this.ll=Er;this.bl=Fr;this.rj=4;this.xk=Gr;this.$f=7;this.Ze=!0;this.eo=4;this.Ha=this.Pr=this.ya=-1;this.od=this.po=0;this.Ka=this.ld=this.md=this.Gd=this.fc=null;this.zo=0;this.yo=this.wj=null;this.Ld=0;this.Ao=null;this.hw=new H;this.pe=[];this.pe.length=100}oa(Cr,yi);
Cr.prototype.cloneProtected=function(a){yi.prototype.cloneProtected.call(this,a);a.je=this.je;a.Wb=this.Wb;a.L=this.L;a.Lk=this.Lk;a.ll=this.ll;a.bl=this.bl;a.rj=this.rj;a.xk=this.xk;a.$f=this.$f;a.Ze=this.Ze;a.eo=this.eo};
Cr.prototype.fb=function(a){a.classType===Cr?0===a.name.indexOf("Aggressive")?this.aggressiveOption=a:0===a.name.indexOf("Cycle")?this.cycleRemoveOption=a:0===a.name.indexOf("Init")?this.initializeOption=a:0===a.name.indexOf("Layer")?this.layeringOption=a:A("Unknown enum value: "+a):yi.prototype.fb.call(this,a)};Cr.prototype.createNetwork=function(){return new Hr(this)};
Cr.prototype.doLayout=function(a){null===this.network&&(this.network=this.makeNetwork(a));this.arrangementOrigin=this.initialOrigin(this.arrangementOrigin);this.Pr=-1;this.od=this.po=0;this.Ao=this.yo=this.wj=null;for(a=0;a<this.pe.length;a++)this.pe[a]=null;if(0<this.network.vertexes.count){this.network.Sp();this.cycleRemoveOption!==Ir&&this.removeCycles();for(a=this.network.vertexes.iterator;a.next();)a.value.layer=-1;this.ya=-1;this.assignLayers();for(a.reset();a.next();)this.ya=Math.max(this.ya,
a.value.layer);this.cycleRemoveOption===Ir&&this.removeCycles();a=this.network;for(var b=[],c=a.edges.iterator;c.next();){var d=c.value;d.valid=!1;b.push(d)}for(c=0;c<b.length;c++){d=b[c];var e=d.fromVertex,f=d.toVertex;if(!d.valid&&(null!==e.node&&null!==f.node||e.layer!==f.layer)){var g=0,h=0,k=0,l=0;if(null!==d.link){h=d.link;if(null===h)continue;var m=e.node;g=f.node;if(null===m||null===g)continue;var n=h.fromNode;k=h.toNode;var p=h.fromPort;h=h.toPort;if(d.rev){l=n;var q=p;n=k;p=h;k=l;h=q}var r=
e.focus;l=f.focus;var u=d.rev?f.bounds:e.bounds;q=H.alloc();m!==n?u.o()&&n.isVisible()?n.actualBounds.o()?(n.pf(p,dd,q),q.x+=n.actualBounds.x-u.x,q.y+=n.actualBounds.y-u.y):(n.pf(p,dd,q),q.o()||q.assign(r)):q.assign(r):u.o()?(n.pf(p,dd,q),q.o()||q.assign(r)):q.assign(r);n=d.rev?e.bounds:f.bounds;m=H.alloc();g!==k?n.o()&&k.isVisible()?k.actualBounds.o()?(k.pf(h,dd,m),m.x+=k.actualBounds.x-n.x,m.y+=k.actualBounds.y-n.y):(k.pf(h,dd,m),m.o()||m.assign(l)):m.assign(l):n.o()?(k.pf(h,dd,m),m.o()||m.assign(l)):
m.assign(l);90===this.L||270===this.L?(g=Math.round((q.x-r.x)/this.Wb),k=q.x,h=Math.round((m.x-l.x)/this.Wb),l=m.x):(g=Math.round((q.y-r.y)/this.Wb),k=q.y,h=Math.round((m.y-l.y)/this.Wb),l=m.y);H.free(q);H.free(m);d.portFromColOffset=g;d.portFromPos=k;d.portToColOffset=h;d.portToPos=l}else d.portFromColOffset=0,d.portFromPos=0,d.portToColOffset=0,d.portToPos=0;q=e.layer;m=f.layer;n=0;u=d.link;if(null!==u){var x=u.fromPort,v=u.toPort;if(null!==x&&null!==v){var y=u.fromNode;p=u.toNode;if(null!==y&&
null!==p){var z=Jr(this,!0),B=Jr(this,!1),C=this.setsPortSpots?z:u.computeSpot(!0,x);r=this.setsPortSpots?B:u.computeSpot(!1,v);var G=u.isOrthogonal;C.rf()&&C.qf(B)&&r.rf()&&r.qf(z)?n=0:(z=u.getLinkPoint(y,x,C,!0,G,p,v,H.alloc()),B=u.getLinkDirection(y,x,z,C,!0,G,p,v),H.free(z),C.dt()||B!==Kr(this,d,!0)?this.setsPortSpots&&null!==y&&1===y.ports.count&&d.rev&&(n+=1):n+=1,C=u.getLinkPoint(p,v,r,!1,G,y,x,H.alloc()),u=u.getLinkDirection(p,v,C,r,!1,G,y,x),H.free(C),r.dt()||u!==Kr(this,d,!1)?this.setsPortSpots&&
null!==p&&1===p.ports.count&&d.rev&&(n+=2):n+=2)}}}p=n;n=1===p||3===p?!0:!1;if(p=2===p||3===p?!0:!1)r=a.createVertex(),r.node=null,r.Rj=1,r.layer=q,r.near=e,a.th(r),e=a.hk(e,r,d.link),e.valid=!1,e.rev=d.rev,e.portFromColOffset=g,e.portToColOffset=0,e.portFromPos=k,e.portToPos=0,e=r;u=1;n&&u--;if(q-m>u&&0<q){d.valid=!1;r=a.createVertex();r.node=null;r.Rj=2;r.layer=q-1;a.th(r);e=a.hk(e,r,d.link);e.valid=!0;e.rev=d.rev;e.portFromColOffset=p?0:g;e.portToColOffset=0;e.portFromPos=p?0:k;e.portToPos=0;e=
r;for(q--;q-m>u&&0<q;)r=a.createVertex(),r.node=null,r.Rj=3,r.layer=q-1,a.th(r),e=a.hk(e,r,d.link),e.valid=!0,e.rev=d.rev,e.portFromColOffset=0,e.portToColOffset=0,e.portFromPos=0,e.portToPos=0,e=r,q--;e=a.hk(r,f,d.link);e.valid=!n;n&&(r.near=f);e.rev=d.rev;e.portFromColOffset=0;e.portToColOffset=h;e.portFromPos=0;e.portToPos=l}else d.valid=!0}}a=this.fc=[];for(b=0;b<=this.ya;b++)a[b]=0;for(b=this.network.vertexes.iterator;b.next();)b.value.index=-1;this.initializeIndices();this.Pr=-1;for(c=this.od=
this.po=0;c<=this.ya;c++)a[c]>a[this.od]&&(this.Pr=a[c]-1,this.od=c),a[c]<a[this.po]&&(this.po=c);this.Ao=[];for(c=0;c<a.length;c++)this.Ao[c]=[];for(b.reset();b.next();)a=b.value,this.Ao[a.layer][a.index]=a;this.Ha=-1;for(a=0;a<=this.ya;a++){b=Lr(this,a);c=0;d=this.fc[a];for(f=0;f<d;f++)e=b[f],c+=this.nodeMinColumnSpace(e,!0),e.column=c,c+=1,c+=this.nodeMinColumnSpace(e,!1);this.Ha=Math.max(this.Ha,c-1);Mr(this,a,b)}this.reduceCrossings();this.straightenAndPack();this.updateParts()}this.network=
null;this.isValidLayout=!0};Cr.prototype.linkMinLength=function(){return 1};function Nr(a){var b=a.fromVertex.node;a=a.toVertex.node;return null===b&&null===a?8:null===b||null===a?4:1}Cr.prototype.nodeMinLayerSpace=function(a,b){return null===a.node?0:90===this.L||270===this.L?b?a.focus.y+10:a.bounds.height-a.focus.y+10:b?a.focus.x+10:a.bounds.width-a.focus.x+10};
Cr.prototype.nodeMinColumnSpace=function(a,b){if(null===a.node)return 0;var c=b?a.qv:a.pv;if(null!==c)return c;c=this.L;return 90===c||270===c?b?a.qv=a.focus.x/this.Wb+1|0:a.pv=(a.bounds.width-a.focus.x)/this.Wb+1|0:b?a.qv=a.focus.y/this.Wb+1|0:a.pv=(a.bounds.height-a.focus.y)/this.Wb+1|0};function Or(a){null===a.wj&&(a.wj=[]);for(var b=0,c=a.network.vertexes.iterator;c.next();){var d=c.value;a.wj[b]=d.layer;b++;a.wj[b]=d.column;b++;a.wj[b]=d.index;b++}return a.wj}
function Pr(a,b){var c=0;for(a=a.network.vertexes.iterator;a.next();){var d=a.value;d.layer=b[c];c++;d.column=b[c];c++;d.index=b[c];c++}}
function Qr(a,b,c){var d=Lr(a,b),e=a.fc[b];if(null===a.yo||a.yo.length<e*e)a.yo=[];for(var f=a.yo,g=0;g<e;g++){var h=0,k=d[g],l=k.near;if(null!==l&&l.layer===k.layer)if(k=l.index,k>g)for(var m=g+1;m<k;m++){var n=d[m];n.near===l&&n.Rj===l.Rj||h++}else for(m=g-1;m>k;m--)n=d[m],n.near===l&&n.Rj===l.Rj||h++;var p;if(0<=c)for(k=d[g].sourceEdgesArrayAccess,l=0;l<k.length;l++){var q=k[l];if(q.valid&&q.fromVertex.layer!==b)for(n=q.fromVertex.index,m=q.portToPos,q=q.portFromPos,p=l+1;p<k.length;p++){var r=
k[p];if(r.valid&&r.fromVertex.layer!==b){var u=r.fromVertex.index;var x=r.portToPos;r=r.portFromPos;m<x&&(n>u||n===u&&q>r)&&h++;x<m&&(u>n||u===n&&r>q)&&h++}}}if(0>=c)for(k=d[g].destinationEdgesArrayAccess,l=0;l<k.length;l++)if(q=k[l],q.valid&&q.toVertex.layer!==b)for(n=q.toVertex.index,m=q.portToPos,q=q.portFromPos,p=l+1;p<k.length;p++)r=k[p],r.valid&&r.toVertex.layer!==b&&(u=r.toVertex.index,x=r.portToPos,r=r.portFromPos,q<r&&(n>u||n===u&&m>x)&&h++,r<q&&(u>n||u===n&&x>m)&&h++);f[g*e+g]=h;for(k=g+
1;k<e;k++){var v=0,y=0;if(0<=c){h=d[g].sourceEdgesArrayAccess;var z=d[k].sourceEdgesArrayAccess;for(l=0;l<h.length;l++)if(q=h[l],q.valid&&q.fromVertex.layer!==b)for(n=q.fromVertex.index,q=q.portFromPos,p=0;p<z.length;p++)r=z[p],r.valid&&r.fromVertex.layer!==b&&(u=r.fromVertex.index,r=r.portFromPos,(n<u||n===u&&q<r)&&y++,(u<n||u===n&&r<q)&&v++)}if(0>=c)for(h=d[g].destinationEdgesArrayAccess,z=d[k].destinationEdgesArrayAccess,l=0;l<h.length;l++)if(q=h[l],q.valid&&q.toVertex.layer!==b)for(n=q.toVertex.index,
m=q.portToPos,p=0;p<z.length;p++)r=z[p],r.valid&&r.toVertex.layer!==b&&(u=r.toVertex.index,x=r.portToPos,(n<u||n===u&&m<x)&&y++,(u<n||u===n&&x<m)&&v++);f[g*e+k]=v;f[k*e+g]=y}}Mr(a,b,d);return f}Cr.prototype.countCrossings=function(){for(var a=0,b=0;b<=this.ya;b++)for(var c=Qr(this,b,1),d=this.fc[b],e=0;e<d;e++)for(var f=e;f<d;f++)a+=c[e*d+f];return a};
function Rr(a){for(var b=0,c=0;c<=a.ya;c++){for(var d=a,e=c,f=Lr(d,e),g=d.fc[e],h=0,k=0;k<g;k++){var l=f[k].destinationEdgesArrayAccess;if(null!==l)for(var m=0;m<l.length;m++){var n=l[m];if(n.valid&&n.toVertex.layer!==e){var p=n.fromVertex.column+n.portFromColOffset;var q=n.toVertex.column+n.portToColOffset;h+=(Math.abs(p-q)+1)*Nr(n)}}}Mr(d,e,f);b+=h}return b}
Cr.prototype.normalize=function(){var a=Infinity;this.Ha=-1;for(var b=this.network.vertexes.iterator;b.next();){var c=b.value;a=Math.min(a,c.column-this.nodeMinColumnSpace(c,!0));this.Ha=Math.max(this.Ha,c.column+this.nodeMinColumnSpace(c,!1))}for(b.reset();b.next();)b.value.column-=a;this.Ha-=a};
function Sr(a,b,c){for(var d=Lr(a,b),e=a.fc[b],f=[],g=0;g<e;g++){var h=d[g],k=null;0>=c&&(k=h.sourceEdgesArrayAccess);var l=null;0<=c&&(l=h.destinationEdgesArrayAccess);var m=0,n=0,p=h.near;null!==p&&p.layer===h.layer&&(m+=p.column-1,n++);if(null!==k)for(p=0;p<k.length;p++){h=k[p];var q=h.fromVertex;h.valid&&!h.rev&&q.layer!==b&&(m+=q.column,n++)}if(null!==l)for(k=0;k<l.length;k++)h=l[k],p=h.toVertex,h.valid&&!h.rev&&p.layer!==b&&(m+=p.column,n++);f[g]=0===n?-1:m/n}Mr(a,b,d);return f}
function Tr(a,b,c){for(var d=Lr(a,b),e=a.fc[b],f=[],g=0;g<e;g++){var h=d[g],k=null;0>=c&&(k=h.sourceEdgesArrayAccess);var l=null;0<=c&&(l=h.destinationEdgesArrayAccess);var m=0,n=[],p=h.near;null!==p&&p.layer===h.layer&&(n[m]=p.column-1,m++);h=void 0;if(null!==k)for(p=0;p<k.length;p++){h=k[p];var q=h.fromVertex;h.valid&&!h.rev&&q.layer!==b&&(n[m]=q.column+h.portFromColOffset,m++)}if(null!==l)for(k=0;k<l.length;k++)h=l[k],p=h.toVertex,h.valid&&!h.rev&&p.layer!==b&&(n[m]=p.column+h.portToColOffset,
m++);0===m?f[g]=-1:(n.sort(function(a,b){return a-b}),l=m>>1,f[g]=0!==(m&1)?n[l]:n[l-1]+n[l]>>1)}Mr(a,b,d);return f}function Ur(a,b,c,d,e,f){if(b.component===d){b.component=c;if(e)for(var g=b.destinationEdges;g.next();){var h=g.value;var k=h.toVertex;var l=b.layer-k.layer;h=a.linkMinLength(h);l===h&&Ur(a,k,c,d,e,f)}if(f)for(g=b.sourceEdges;g.next();)h=g.value,k=h.fromVertex,l=k.layer-b.layer,h=a.linkMinLength(h),l===h&&Ur(a,k,c,d,e,f)}}
function Vr(a,b,c,d,e,f){if(b.component===d){b.component=c;if(e)for(var g=b.destinationEdges;g.next();)Vr(a,g.value.toVertex,c,d,e,f);if(f)for(b=b.sourceEdges;b.next();)Vr(a,b.value.fromVertex,c,d,e,f)}}
Cr.prototype.removeCycles=function(){for(var a=this.network.edges.iterator;a.next();)a.value.rev=!1;switch(this.Lk){default:case Wr:a=this.network;var b=0,c=a.vertexes.count-1,d=[];d.length=c+1;for(var e=a.vertexes.iterator;e.next();)e.value.valid=!0;for(;null!==Xr(a);){for(e=Yr(a);null!==e;)d[c]=e,c--,e.valid=!1,e=Yr(a);for(e=Zr(a);null!==e;)d[b]=e,b++,e.valid=!1,e=Zr(a);e=null;for(var f=0,g=this.network.vertexes.iterator;g.next();){var h=g.value;if(h.valid){for(var k=0,l=h.destinationEdges;l.next();)l.value.toVertex.valid&&
k++;l=0;for(var m=h.sourceEdges;m.next();)m.value.fromVertex.valid&&l++;if(null===e||f<k-l)e=h,f=k-l}}null!==e&&(d[b]=e,b++,e.valid=!1)}for(b=0;b<a.vertexes.count;b++)d[b].index=b;for(d=a.edges.iterator;d.next();)b=d.value,b.fromVertex.index>b.toVertex.index&&(a.sm(b),b.rev=!0);break;case Dr:for(d=this.network.vertexes.iterator;d.next();)a=d.value,a.Xl=-1,a.finish=-1;for(a=this.network.edges.iterator;a.next();)a.value.forest=!1;this.zo=0;for(d.reset();d.next();)b=d.value,0===b.sourceEdges.count&&
$r(this,b);for(d.reset();d.next();)b=d.value,-1===b.Xl&&$r(this,b);for(a.reset();a.next();)d=a.value,d.forest||(b=d.fromVertex,c=b.finish,e=d.toVertex,f=e.finish,e.Xl<b.Xl&&c<f&&(this.network.sm(d),d.rev=!0));break;case Ir:a=this.network;b=a.vertexes.iterator;for(d=Infinity;b.next();)d=Math.min(d,b.value.layer);if(Infinity>d){if(0>d)for(b.reset();b.next();)b.value.layer-=d;d=[];for(b.reset();b.next();)c=b.value,e=d[c.layer],void 0===e?d[c.layer]=[c]:e.push(c);for(c=b=0;c<d.length;c++)if(e=d[c],!e||
0===e.length)b++;else if(0<c)for(f=0;f<e.length;f++)e[f].layer-=b;for(d=a.edges.iterator;d.next();)b=d.value,b.fromVertex.layer<b.toVertex.layer&&(a.sm(b),b.rev=!0)}}};function Xr(a){for(a=a.vertexes.iterator;a.next();){var b=a.value;if(b.valid)return b}return null}function Yr(a){for(a=a.vertexes.iterator;a.next();){var b=a.value;if(b.valid){for(var c=!0,d=b.destinationEdges;d.next();)if(d.value.toVertex.valid){c=!1;break}if(c)return b}}return null}
function Zr(a){for(a=a.vertexes.iterator;a.next();){var b=a.value;if(b.valid){for(var c=!0,d=b.sourceEdges;d.next();)if(d.value.fromVertex.valid){c=!1;break}if(c)return b}}return null}function $r(a,b){b.Xl=a.zo;a.zo++;for(var c=b.destinationEdges;c.next();){var d=c.value,e=d.toVertex;-1===e.Xl&&(d.forest=!0,$r(a,e))}b.finish=a.zo;a.zo++}
Cr.prototype.assignLayers=function(){switch(this.ll){case as:bs(this);break;case cs:for(var a,b=this.network.vertexes.iterator;b.next();)a=ds(this,b.value),this.ya=Math.max(a,this.ya);for(b.reset();b.next();)a=b.value,a.layer=this.ya-a.layer;break;default:case Er:bs(this);for(b=this.network.vertexes.iterator;b.next();)b.value.valid=!1;for(b.reset();b.next();)a=b.value,0===a.sourceEdges.count&&es(this,a);a=Infinity;for(b.reset();b.next();)a=Math.min(a,b.value.layer);this.ya=-1;for(b.reset();b.next();){var c=
b.value;c.layer-=a;this.ya=Math.max(this.ya,c.layer)}}};function bs(a){for(var b=a.network.vertexes.iterator;b.next();){var c=fs(a,b.value);a.ya=Math.max(c,a.ya)}}function fs(a,b){var c=0;if(-1===b.layer){for(var d=b.destinationEdges;d.next();){var e=d.value,f=e.toVertex;e=a.linkMinLength(e);c=Math.max(c,fs(a,f)+e)}b.layer=c}else c=b.layer;return c}
function ds(a,b){var c=0;if(-1===b.layer){for(var d=b.sourceEdges;d.next();){var e=d.value,f=e.fromVertex;e=a.linkMinLength(e);c=Math.max(c,ds(a,f)+e)}b.layer=c}else c=b.layer;return c}
function es(a,b){if(!b.valid){b.valid=!0;for(var c=b.destinationEdges;c.next();)es(a,c.value.toVertex);for(c=a.network.vertexes.iterator;c.next();)c.value.component=-1;for(var d=b.sourceEdgesArrayAccess,e=d.length,f=0;f<e;f++){var g=d[f],h=g.fromVertex,k=g.toVertex;g=a.linkMinLength(g);h.layer-k.layer>g&&Ur(a,h,0,-1,!0,!1)}for(Ur(a,b,1,-1,!0,!0);0!==b.component;){f=0;d=Infinity;h=0;k=null;for(g=a.network.vertexes.iterator;g.next();){var l=g.value;if(1===l.component){var m=0,n=!1,p=l.sourceEdgesArrayAccess;
e=p.length;for(var q=0;q<e;q++){var r=p[q],u=r.fromVertex;m+=1;1!==u.component&&(f+=1,u=u.layer-l.layer,r=a.linkMinLength(r),d=Math.min(d,u-r))}p=l.destinationEdgesArrayAccess;e=p.length;for(q=0;q<e;q++)r=p[q].toVertex,--m,1!==r.component?--f:n=!0;(null===k||m<h)&&!n&&(k=l,h=m)}}if(0<f){for(c.reset();c.next();)e=c.value,1===e.component&&(e.layer+=d);b.component=0}else k.component=0}for(c=a.network.vertexes.iterator;c.next();)c.value.component=-1;for(Ur(a,b,1,-1,!0,!1);0!==b.component;){d=0;e=Infinity;
f=0;h=null;for(k=a.network.vertexes.iterator;k.next();)if(g=k.value,1===g.component){l=0;m=!1;p=g.sourceEdgesArrayAccess;n=p.length;for(q=0;q<n;q++)r=p[q].fromVertex,l+=1,1!==r.component?d+=1:m=!0;p=g.destinationEdgesArrayAccess;n=p.length;for(q=0;q<n;q++)r=p[q],u=r.toVertex,--l,1!==u.component&&(--d,u=g.layer-u.layer,r=a.linkMinLength(r),e=Math.min(e,u-r));(null===h||l>f)&&!m&&(h=g,f=l)}if(0>d){for(c.reset();c.next();)d=c.value,1===d.component&&(d.layer-=e);b.component=0}else h.component=0}}}
function Kr(a,b,c){return 90===a.L?c&&!b.rev||!c&&b.rev?270:90:180===a.L?c&&!b.rev||!c&&b.rev?0:180:270===a.L?c&&!b.rev||!c&&b.rev?90:270:c&&!b.rev||!c&&b.rev?180:0}
Cr.prototype.initializeIndices=function(){switch(this.bl){default:case gs:for(var a=this.network.vertexes.iterator;a.next();){var b=a.value,c=b.layer;b.index=this.fc[c];this.fc[c]++}break;case Fr:a=this.network.vertexes.iterator;for(b=this.ya;0<=b;b--)for(a.reset();a.next();)c=a.value,c.layer===b&&-1===c.index&&hs(this,c);break;case is:for(a=this.network.vertexes.iterator,b=0;b<=this.ya;b++)for(a.reset();a.next();)c=a.value,c.layer===b&&-1===c.index&&js(this,c)}};
function hs(a,b){var c=b.layer;b.index=a.fc[c];a.fc[c]++;b=b.destinationEdgesArrayAccess;for(c=!0;c;){c=!1;for(var d=0;d<b.length-1;d++){var e=b[d],f=b[d+1];e.portFromColOffset>f.portFromColOffset&&(c=!0,b[d]=f,b[d+1]=e)}}for(c=0;c<b.length;c++)d=b[c],d.valid&&(d=d.toVertex,-1===d.index&&hs(a,d))}
function js(a,b){var c=b.layer;b.index=a.fc[c];a.fc[c]++;b=b.sourceEdgesArrayAccess;for(var d=!0;d;)for(d=!1,c=0;c<b.length-1;c++){var e=b[c],f=b[c+1];e.portToColOffset>f.portToColOffset&&(d=!0,b[c]=f,b[c+1]=e)}for(c=0;c<b.length;c++)d=b[c],d.valid&&(d=d.fromVertex,-1===d.index&&js(a,d))}
Cr.prototype.reduceCrossings=function(){var a=this.countCrossings(),b=Or(this),c,d;for(c=0;c<this.rj;c++){for(d=0;d<=this.ya;d++)ks(this,d,1),ls(this,d,1);var e=this.countCrossings();e<a&&(a=e,b=Or(this));for(d=this.ya;0<=d;d--)ks(this,d,-1),ls(this,d,-1);e=this.countCrossings();e<a&&(a=e,b=Or(this))}Pr(this,b);for(c=0;c<this.rj;c++){for(d=0;d<=this.ya;d++)ks(this,d,0),ls(this,d,0);e=this.countCrossings();e<a&&(a=e,b=Or(this));for(d=this.ya;0<=d;d--)ks(this,d,0),ls(this,d,0);e=this.countCrossings();
e<a&&(a=e,b=Or(this))}Pr(this,b);var f,g,h;switch(this.xk){case ms:break;case ns:for(h=a+1;(d=this.countCrossings())<h;)for(h=d,c=this.ya;0<=c;c--)for(g=0;g<=c;g++){for(f=!0;f;)for(f=!1,d=c;d>=g;d--)f=ls(this,d,-1)||f;e=this.countCrossings();e>=a?Pr(this,b):(a=e,b=Or(this));for(f=!0;f;)for(f=!1,d=c;d>=g;d--)f=ls(this,d,1)||f;e=this.countCrossings();e>=a?Pr(this,b):(a=e,b=Or(this));for(f=!0;f;)for(f=!1,d=g;d<=c;d++)f=ls(this,d,1)||f;e>=a?Pr(this,b):(a=e,b=Or(this));for(f=!0;f;)for(f=!1,d=g;d<=c;d++)f=
ls(this,d,-1)||f;e>=a?Pr(this,b):(a=e,b=Or(this));for(f=!0;f;)for(f=!1,d=c;d>=g;d--)f=ls(this,d,0)||f;e>=a?Pr(this,b):(a=e,b=Or(this));for(f=!0;f;)for(f=!1,d=g;d<=c;d++)f=ls(this,d,0)||f;e>=a?Pr(this,b):(a=e,b=Or(this))}break;default:case Gr:for(c=this.ya,g=0,h=a+1;(d=this.countCrossings())<h;){h=d;for(f=!0;f;)for(f=!1,d=c;d>=g;d--)f=ls(this,d,-1)||f;e=this.countCrossings();e>=a?Pr(this,b):(a=e,b=Or(this));for(f=!0;f;)for(f=!1,d=c;d>=g;d--)f=ls(this,d,1)||f;e=this.countCrossings();e>=a?Pr(this,b):
(a=e,b=Or(this));for(f=!0;f;)for(f=!1,d=g;d<=c;d++)f=ls(this,d,1)||f;e>=a?Pr(this,b):(a=e,b=Or(this));for(f=!0;f;)for(f=!1,d=g;d<=c;d++)f=ls(this,d,-1)||f;e>=a?Pr(this,b):(a=e,b=Or(this));for(f=!0;f;)for(f=!1,d=c;d>=g;d--)f=ls(this,d,0)||f;e>=a?Pr(this,b):(a=e,b=Or(this));for(f=!0;f;)for(f=!1,d=g;d<=c;d++)f=ls(this,d,0)||f;e>=a?Pr(this,b):(a=e,b=Or(this))}}Pr(this,b)};
function ks(a,b,c){var d=Lr(a,b),e=a.fc[b],f=Tr(a,b,c),g=Sr(a,b,c);for(c=0;c<e;c++)-1===g[c]&&(g[c]=d[c].column),-1===f[c]&&(f[c]=d[c].column);for(var h=!0,k;h;)for(h=!1,c=0;c<e-1;c++)if(f[c+1]<f[c]||f[c+1]===f[c]&&g[c+1]<g[c])h=!0,k=f[c],f[c]=f[c+1],f[c+1]=k,k=g[c],g[c]=g[c+1],g[c+1]=k,k=d[c],d[c]=d[c+1],d[c+1]=k;for(c=f=0;c<e;c++)k=d[c],k.index=c,f+=a.nodeMinColumnSpace(k,!0),k.column=f,f+=1,f+=a.nodeMinColumnSpace(k,!1);Mr(a,b,d)}
function ls(a,b,c){var d=Lr(a,b),e=a.fc[b];c=Qr(a,b,c);var f;var g=[];for(f=0;f<e;f++)g[f]=-1;var h=[];for(f=0;f<e;f++)h[f]=-1;for(var k=!1,l=!0;l;)for(l=!1,f=0;f<e-1;f++){var m=c[d[f].index*e+d[f+1].index],n=c[d[f+1].index*e+d[f].index],p=0,q=0,r=d[f].column,u=d[f+1].column,x=a.nodeMinColumnSpace(d[f],!0),v=a.nodeMinColumnSpace(d[f],!1),y=a.nodeMinColumnSpace(d[f+1],!0),z=a.nodeMinColumnSpace(d[f+1],!1);x=r-x+y;v=u-v+z;var B=d[f].sourceEdges.iterator;for(B.reset();B.next();)if(y=B.value,z=y.fromVertex,
y.valid&&z.layer===b){for(y=0;d[y]!==z;)y++;y<f&&(p+=2*(f-y),q+=2*(f+1-y));y===f+1&&(p+=1);y>f+1&&(p+=4*(y-f),q+=4*(y-(f+1)))}B=d[f].destinationEdges.iterator;for(B.reset();B.next();)if(y=B.value,z=y.toVertex,y.valid&&z.layer===b){for(y=0;d[y]!==z;)y++;y===f+1&&(q+=1)}B=d[f+1].sourceEdges.iterator;for(B.reset();B.next();)if(y=B.value,z=y.fromVertex,y.valid&&z.layer===b){for(y=0;d[y]!==z;)y++;y<f&&(p+=2*(f+1-y),q+=2*(f-y));y===f&&(q+=1);y>f+1&&(p+=4*(y-(f+1)),q+=4*(y-f))}B=d[f+1].destinationEdges.iterator;
for(B.reset();B.next();)if(y=B.value,z=y.toVertex,y.valid&&z.layer===b){for(y=0;d[y]!==z;)y++;y===f&&(p+=1)}y=z=0;B=g[d[f].index];var C=h[d[f].index],G=g[d[f+1].index],K=h[d[f+1].index];-1!==B&&(z+=Math.abs(B-r),y+=Math.abs(B-v));-1!==C&&(z+=Math.abs(C-r),y+=Math.abs(C-v));-1!==G&&(z+=Math.abs(G-u),y+=Math.abs(G-x));-1!==K&&(z+=Math.abs(K-u),y+=Math.abs(K-x));if(q<p-.5||q===p&&n<m-.5||q===p&&n===m&&y<z-.5)l=k=!0,d[f].column=v,d[f+1].column=x,m=d[f],d[f]=d[f+1],d[f+1]=m}for(f=0;f<e;f++)d[f].index=
f;Mr(a,b,d);return k}
Cr.prototype.straightenAndPack=function(){var a=0!==(this.$f&1);var b=7===this.$f;1E3<this.network.edges.count&&!b&&(a=!1);if(a){var c=[];for(b=0;b<=this.ya;b++)c[b]=0;for(var d,e=this.network.vertexes.iterator;e.next();){var f=e.value;b=f.layer;d=f.column;f=this.nodeMinColumnSpace(f,!1);c[b]=Math.max(c[b],d+f)}for(e.reset();e.next();)f=e.value,b=f.layer,d=f.column,f.column=(8*(this.Ha-c[b])>>1)+8*d;this.Ha*=8}if(0!==(this.$f&2))for(c=!0;c;){c=!1;for(b=this.od+1;b<=this.ya;b++)c=os(this,b,1)||c;for(b=
this.od-1;0<=b;b--)c=os(this,b,-1)||c;c=os(this,this.od,0)||c}if(0!==(this.$f&4)){for(b=this.od+1;b<=this.ya;b++)ps(this,b,1);for(b=this.od-1;0<=b;b--)ps(this,b,-1);ps(this,this.od,0)}a&&(qs(this,-1),qs(this,1));if(0!==(this.$f&2))for(c=!0;c;){c=!1;c=os(this,this.od,0)||c;for(b=this.od+1;b<=this.ya;b++)c=os(this,b,0)||c;for(b=this.od-1;0<=b;b--)c=os(this,b,0)||c}};function os(a,b,c){for(var d=!1;rs(a,b,c);)d=!0;return d}
function rs(a,b,c){var d,e=Lr(a,b),f=a.fc[b],g=Sr(a,b,-1);if(0<c)for(d=0;d<f;d++)g[d]=-1;var h=Sr(a,b,1);if(0>c)for(d=0;d<f;d++)h[d]=-1;for(var k=!1,l=!0;l;)for(l=!1,d=0;d<f;d++){var m=e[d].column,n=a.nodeMinColumnSpace(e[d],!0),p=a.nodeMinColumnSpace(e[d],!1),q=0;0>d-1||m-e[d-1].column-1>n+a.nodeMinColumnSpace(e[d-1],!1)?q=m-1:q=m;n=d+1>=f||e[d+1].column-m-1>p+a.nodeMinColumnSpace(e[d+1],!0)?m+1:m;var r=p=0,u=0;if(0>=c)for(var x=e[d].sourceEdges.iterator;x.next();){var v=x.value;var y=v.fromVertex;
if(v.valid&&y.layer!==b){var z=Nr(v);var B=v.portFromColOffset;v=v.portToColOffset;y=y.column;p+=(Math.abs(m+v-(y+B))+1)*z;r+=(Math.abs(q+v-(y+B))+1)*z;u+=(Math.abs(n+v-(y+B))+1)*z}}if(0<=c)for(x=e[d].destinationEdges.iterator;x.next();)v=x.value,y=v.toVertex,v.valid&&y.layer!==b&&(z=Nr(v),B=v.portFromColOffset,v=v.portToColOffset,y=y.column,p+=(Math.abs(m+B-(y+v))+1)*z,r+=(Math.abs(q+B-(y+v))+1)*z,u+=(Math.abs(n+B-(y+v))+1)*z);v=B=z=0;x=g[e[d].index];y=h[e[d].index];-1!==x&&(z+=Math.abs(x-m),B+=
Math.abs(x-q),v+=Math.abs(x-n));-1!==y&&(z+=Math.abs(y-m),B+=Math.abs(y-q),v+=Math.abs(y-n));if(r<p||r===p&&B<z)l=k=!0,e[d].column=q;else if(u<p||u===p&&v<z)l=k=!0,e[d].column=n}Mr(a,b,e);a.normalize();return k}
function ps(a,b,c){var d=Lr(a,b),e=a.fc[b],f=Tr(a,b,c),g=[];for(c=0;c<e;c++)g[c]=f[c];for(f=!0;f;)for(f=!1,c=0;c<e;c++){var h=d[c].column,k=a.nodeMinColumnSpace(d[c],!0),l=a.nodeMinColumnSpace(d[c],!1),m=0;if(-1===g[c])if(0===c&&c===e-1)m=h;else if(0===c){var n=d[c+1].column;n-h===l+a.nodeMinColumnSpace(d[c+1],!0)?m=h-1:m=h}else c===e-1?(n=d[c-1].column,m=h-n===k+a.nodeMinColumnSpace(d[c-1],!1)?h+1:h):(n=d[c-1].column,k=n+a.nodeMinColumnSpace(d[c-1],!1)+k+1,n=d[c+1].column,l=n-a.nodeMinColumnSpace(d[c+
1],!0)-l-1,m=(k+l)/2|0);else 0===c&&c===e-1?m=g[c]:0===c?(n=d[c+1].column,l=n-a.nodeMinColumnSpace(d[c+1],!0)-l-1,m=Math.min(g[c],l)):c===e-1?(n=d[c-1].column,k=n+a.nodeMinColumnSpace(d[c-1],!1)+k+1,m=Math.max(g[c],k)):(n=d[c-1].column,k=n+a.nodeMinColumnSpace(d[c-1],!1)+k+1,n=d[c+1].column,l=n-a.nodeMinColumnSpace(d[c+1],!0)-l-1,k<g[c]&&g[c]<l?m=g[c]:k>=g[c]?m=k:l<=g[c]&&(m=l));m!==h&&(f=!0,d[c].column=m)}Mr(a,b,d);a.normalize()}
function ss(a,b){for(var c=!0,d=a.network.vertexes.iterator;d.next();){var e=d.value,f=a.nodeMinColumnSpace(e,!0),g=a.nodeMinColumnSpace(e,!1);if(e.column-f<=b&&e.column+g>=b){c=!1;break}}a=!1;if(c)for(d.reset();d.next();)c=d.value,c.column>b&&(--c.column,a=!0);return a}
function ts(a,b){var c=b+1;var d,e=[],f=[];for(d=0;d<=a.ya;d++)e[d]=!1,f[d]=!1;for(var g=a.network.vertexes.iterator;g.next();){d=g.value;var h=d.column-a.nodeMinColumnSpace(d,!0),k=d.column+a.nodeMinColumnSpace(d,!1);h<=b&&k>=b&&(e[d.layer]=!0);h<=c&&k>=c&&(f[d.layer]=!0)}h=!0;c=!1;for(d=0;d<=a.ya;d++)h=h&&!(e[d]&&f[d]);if(h)for(g.reset();g.next();)a=g.value,a.column>b&&(--a.column,c=!0);return c}
function qs(a,b){for(var c=0;c<=a.Ha;c++)for(;ss(a,c););a.normalize();for(c=0;c<a.Ha;c++)for(;ts(a,c););a.normalize();var d;if(0<b)for(c=0;c<=a.Ha;c++){var e=Or(a);var f=Rr(a);for(d=f+1;f<d;){d=f;us(a,c,1);var g=Rr(a);g>f?Pr(a,e):g<f&&(f=g,e=Or(a))}}if(0>b)for(c=a.Ha;0<=c;c--)for(e=Or(a),f=Rr(a),d=f+1;f<d;)d=f,us(a,c,-1),g=Rr(a),g>f?Pr(a,e):g<f&&(f=g,e=Or(a));a.normalize()}
function us(a,b,c){a.Ld=0;for(var d=a.network.vertexes.iterator;d.next();)d.value.component=-1;if(0<c)for(d.reset();d.next();){var e=d.value;e.column-a.nodeMinColumnSpace(e,!0)<=b&&(e.component=a.Ld)}if(0>c)for(d.reset();d.next();)e=d.value,e.column+a.nodeMinColumnSpace(e,!1)>=b&&(e.component=a.Ld);a.Ld++;for(d.reset();d.next();)b=d.value,-1===b.component&&(Vr(a,b,a.Ld,-1,!0,!0),a.Ld++);var f;b=[];for(f=0;f<a.Ld*a.Ld;f++)b[f]=!1;e=[];for(f=0;f<(a.ya+1)*(a.Ha+1);f++)e[f]=-1;for(d.reset();d.next();){f=
d.value;for(var g=f.layer,h=Math.max(0,f.column-a.nodeMinColumnSpace(f,!0)),k=Math.min(a.Ha,f.column+a.nodeMinColumnSpace(f,!1));h<=k;h++)e[g*(a.Ha+1)+h]=f.component}for(f=0;f<=a.ya;f++){if(0<c)for(g=0;g<a.Ha;g++)-1!==e[f*(a.Ha+1)+g]&&-1!==e[f*(a.Ha+1)+g+1]&&e[f*(a.Ha+1)+g]!==e[f*(a.Ha+1)+g+1]&&(b[e[f*(a.Ha+1)+g]*a.Ld+e[f*(a.Ha+1)+g+1]]=!0);if(0>c)for(g=a.Ha;0<g;g--)-1!==e[f*(a.Ha+1)+g]&&-1!==e[f*(a.Ha+1)+g-1]&&e[f*(a.Ha+1)+g]!==e[f*(a.Ha+1)+g-1]&&(b[e[f*(a.Ha+1)+g]*a.Ld+e[f*(a.Ha+1)+g-1]]=!0)}e=
[];for(f=0;f<a.Ld;f++)e[f]=!0;g=[];for(g.push(0);0!==g.length;)if(k=g[g.length-1],g.pop(),e[k])for(e[k]=!1,f=0;f<a.Ld;f++)b[k*a.Ld+f]&&g.splice(0,0,f);if(0<c)for(d.reset();d.next();)a=d.value,e[a.component]&&--a.column;if(0>c)for(d.reset();d.next();)c=d.value,e[c.component]&&(c.column+=1)}
Cr.prototype.commitLayout=function(){if(this.setsPortSpots)for(var a=Jr(this,!0),b=Jr(this,!1),c=this.network.edges.iterator;c.next();){var d=c.value.link;null!==d&&(d.fromSpot=a,d.toSpot=b)}this.commitNodes();this.Mu();this.isRouting&&this.commitLinks()};function Jr(a,b){return 270===a.L?b?Jd:Td:90===a.L?b?Td:Jd:180===a.L?b?Kd:Sd:b?Sd:Kd}
Cr.prototype.commitNodes=function(){this.Gd=[];this.md=[];this.ld=[];this.Ka=[];for(var a=0;a<=this.ya;a++)this.Gd[a]=0,this.md[a]=0,this.ld[a]=0,this.Ka[a]=0;for(a=this.network.vertexes.iterator;a.next();){var b=a.value,c=b.layer;this.Gd[c]=Math.max(this.Gd[c],this.nodeMinLayerSpace(b,!0));this.md[c]=Math.max(this.md[c],this.nodeMinLayerSpace(b,!1))}b=0;c=this.je;for(var d=0;d<=this.ya;d++){var e=c;0>=this.Gd[d]+this.md[d]&&(e=0);0<d&&(b+=e/2);90===this.L||0===this.L?(b+=this.md[d],this.ld[d]=b,
b+=this.Gd[d]):(b+=this.Gd[d],this.ld[d]=b,b+=this.md[d]);d<this.ya&&(b+=e/2);this.Ka[d]=b}c=b;b=this.arrangementOrigin;for(d=0;d<=this.ya;d++)270===this.L?this.ld[d]=b.y+this.ld[d]:90===this.L?(this.ld[d]=b.y+c-this.ld[d],this.Ka[d]=c-this.Ka[d]):180===this.L?this.ld[d]=b.x+this.ld[d]:(this.ld[d]=b.x+c-this.ld[d],this.Ka[d]=c-this.Ka[d]);a.reset();for(c=d=Infinity;a.next();){e=a.value;var f=e.layer,g=e.column|0;if(270===this.L||90===this.L){var h=b.x+this.Wb*g;f=this.ld[f]}else h=this.ld[f],f=b.y+
this.Wb*g;e.centerX=h;e.centerY=f;d=Math.min(e.x,d);c=Math.min(e.y,c)}d=b.x-d;b=b.y-c;this.hw=new H(d,b);for(a.reset();a.next();)c=a.value,c.x+=d,c.y+=b,c.commit()};
Cr.prototype.Mu=function(){for(var a=0,b=this.je,c=0;c<=this.ya;c++)a+=this.Gd[c],a+=this.md[c];a+=this.ya*b;b=[];c=this.Wb*this.Ha;for(var d=this.maxLayer;0<=d;d--)270===this.L?0===d?b.push(new N(0,0,c,Math.abs(this.Ka[0]))):b.push(new N(0,this.Ka[d-1],c,Math.abs(this.Ka[d-1]-this.Ka[d]))):90===this.L?0===d?b.push(new N(0,this.Ka[0],c,Math.abs(this.Ka[0]-a))):b.push(new N(0,this.Ka[d],c,Math.abs(this.Ka[d-1]-this.Ka[d]))):180===this.L?0===d?b.push(new N(0,0,Math.abs(this.Ka[0]),c)):b.push(new N(this.Ka[d-
1],0,Math.abs(this.Ka[d-1]-this.Ka[d]),c)):0===d?b.push(new N(this.Ka[0],0,Math.abs(this.Ka[0]-a),c)):b.push(new N(this.Ka[d],0,Math.abs(this.Ka[d-1]-this.Ka[d]),c));this.commitLayers(b,this.hw)};Cr.prototype.commitLayers=function(){};
Cr.prototype.commitLinks=function(){for(var a=this.network.edges.iterator,b;a.next();)b=a.value.link,null!==b&&(b.Ch(),b.Vj(),b.lf());for(a.reset();a.next();)b=a.value.link,null!==b&&b.$i();for(a.reset();a.next();){var c=a.value;b=c.link;if(null!==b){b.Ch();var d=b,e=d.fromNode,f=d.toNode,g=d.fromPort,h=d.toPort;if(null!==e){var k=e.findVisibleNode();null!==k&&k!==e&&(e=k,g=k.port)}if(null!==f){var l=f.findVisibleNode();null!==l&&l!==f&&(f=l,h=l.port)}var m=b.computeSpot(!0,g),n=b.computeSpot(!1,
h),p=c.fromVertex,q=c.toVertex;if(c.valid){if(b.curve===Rg&&4===b.pointsCount)if(p.column===q.column){var r=b.getLinkPoint(e,g,m,!0,!1,f,h),u=b.getLinkPoint(f,h,n,!1,!1,e,g);r.o()||r.set(e.actualBounds.center);u.o()||u.set(f.actualBounds.center);b.Vj();b.kf(r.x,r.y);b.kf((2*r.x+u.x)/3,(2*r.y+u.y)/3);b.kf((r.x+2*u.x)/3,(r.y+2*u.y)/3);b.kf(u.x,u.y)}else{var x=!1,v=!1;null!==g&&m===Sc&&(x=!0);null!==h&&n===Sc&&(v=!0);if(x||v){var y=b.i(0).x,z=b.i(0).y,B=b.i(3).x,C=b.i(3).y;if(x){if(90===this.L||270===
this.L){var G=y;var K=(z+C)/2}else G=(y+B)/2,K=z;b.M(1,G,K);var M=b.getLinkPoint(e,g,m,!0,!1,f,h);M.o()||M.set(e.actualBounds.center);b.M(0,M.x,M.y)}if(v){if(90===this.L||270===this.L){var U=B;var R=(z+C)/2}else U=(y+B)/2,R=C;b.M(2,U,R);var da=b.getLinkPoint(f,h,n,!1,!1,e,g);da.o()||da.set(f.actualBounds.center);b.M(3,da.x,da.y)}}}b.lf()}else if(p.layer===q.layer)b.lf();else{var ja=!1,J=!1,Q=b.firstPickIndex+1;if(b.isOrthogonal){J=!0;var xa=b.pointsCount;4<xa&&b.points.removeRange(2,xa-3)}else if(b.curve===
Rg)ja=!0,xa=b.pointsCount,4<xa&&b.points.removeRange(2,xa-3),4===xa&&(Q=2);else{xa=b.pointsCount;var jb=m===Sc,Ha=n===Sc;2<xa&&jb&&Ha?b.points.removeRange(1,xa-2):3<xa&&jb&&!Ha?b.points.removeRange(1,xa-3):3<xa&&!jb&&Ha?b.points.removeRange(2,xa-2):4<xa&&!jb&&!Ha&&b.points.removeRange(2,xa-3)}var Da;if(c.rev){for(var Fa;null!==q&&p!==q;){var Ra=Da=null;for(var Ub=q.sourceEdges.iterator;Ub.next();){var $a=Ub.value;if($a.link===c.link&&(Da=$a.fromVertex,Ra=$a.toVertex,null===Da.node))break}if(Da!==
p){var Xa=b.i(Q-1).x;var Ma=b.i(Q-1).y;var ha=Da.centerX;var fa=Da.centerY;if(J)if(180===this.L||0===this.L)if(Q===b.firstPickIndex+1)b.m(Q++,Xa,Ma),b.m(Q++,Xa,fa);else{if((null!==Ra?Ra.centerY:Ma)!==fa){var hb=this.Ka[Da.layer-1];b.m(Q++,hb,Ma);b.m(Q++,hb,fa)}}else Q===b.firstPickIndex+1?(b.m(Q++,Xa,Ma),b.m(Q++,ha,Ma)):(null!==Ra?Ra.centerX:Xa)!==ha&&(hb=this.Ka[Da.layer-1],b.m(Q++,Xa,hb),b.m(Q++,ha,hb));else if(Q===b.firstPickIndex+1){var kb=Math.max(10,this.Gd[q.layer]);var Na=Math.max(10,this.md[q.layer]);
if(ja)180===this.L?ha<=q.bounds.x?(Fa=q.bounds.x,b.m(Q++,Fa-kb,fa),b.m(Q++,Fa,fa),b.m(Q++,Fa+Na,fa)):(b.m(Q++,ha-kb,fa),b.m(Q++,ha,fa),b.m(Q++,ha+Na,fa)):90===this.L?fa>=q.bounds.bottom?(Fa=q.bounds.y+q.bounds.height,b.m(Q++,ha,Fa+Na),b.m(Q++,ha,Fa),b.m(Q++,ha,Fa-kb)):(b.m(Q++,ha,fa+Na),b.m(Q++,ha,fa),b.m(Q++,ha,fa-kb)):270===this.L?fa<=q.bounds.y?(Fa=q.bounds.y,b.m(Q++,ha,Fa-kb),b.m(Q++,ha,Fa),b.m(Q++,ha,Fa+Na)):(b.m(Q++,ha,fa-kb),b.m(Q++,ha,fa),b.m(Q++,ha,fa+Na)):0===this.L&&(ha>=q.bounds.right?
(Fa=q.bounds.x+q.bounds.width,b.m(Q++,Fa+Na,fa),b.m(Q++,Fa,fa),b.m(Q++,Fa-kb,fa)):(b.m(Q++,ha+Na,fa),b.m(Q++,ha,fa),b.m(Q++,ha-kb,fa)));else{b.m(Q++,Xa,Ma);var ee=0;if(180===this.L||0===this.L){if(180===this.L?ha>=q.bounds.right:ha<=q.bounds.x)ee=(0===this.L?-kb:Na)/2;b.m(Q++,Xa+ee,fa)}else{if(270===this.L?fa>=q.bounds.bottom:fa<=q.bounds.y)ee=(90===this.L?-kb:Na)/2;b.m(Q++,ha,Ma+ee)}b.m(Q++,ha,fa)}}else kb=Math.max(10,this.Gd[Da.layer]),Na=Math.max(10,this.md[Da.layer]),180===this.L?(ja&&b.m(Q++,
ha-kb,fa),b.m(Q++,ha,fa),ja&&b.m(Q++,ha+Na,fa)):90===this.L?(ja&&b.m(Q++,ha,fa+Na),b.m(Q++,ha,fa),ja&&b.m(Q++,ha,fa-kb)):270===this.L?(ja&&b.m(Q++,ha,fa-kb),b.m(Q++,ha,fa),ja&&b.m(Q++,ha,fa+Na)):(ja&&b.m(Q++,ha+Na,fa),b.m(Q++,ha,fa),ja&&b.m(Q++,ha-kb,fa))}q=Da}if(null===h||m!==Sc||J)if(Xa=b.i(Q-1).x,Ma=b.i(Q-1).y,ha=b.i(Q).x,fa=b.i(Q).y,J){var Ld=this.md[p.layer];if(180===this.L||0===this.L){var vb=Ma;vb>=p.bounds.y&&vb<=p.bounds.bottom&&(180===this.L?ha>=p.bounds.x:ha<=p.bounds.right)&&(Fa=p.centerX+
(180===this.L?-Ld:Ld),vb<p.bounds.y+p.bounds.height/2?vb=p.bounds.y-this.Wb/2:vb=p.bounds.bottom+this.Wb/2,b.m(Q++,Fa,Ma),b.m(Q++,Fa,vb));b.m(Q++,ha,vb)}else vb=Xa,vb>=p.bounds.x&&vb<=p.bounds.right&&(270===this.L?fa>=p.bounds.y:fa<=p.bounds.bottom)&&(Fa=p.centerY+(270===this.L?-Ld:Ld),vb<p.bounds.x+p.bounds.width/2?vb=p.bounds.x-this.Wb/2:vb=p.bounds.right+this.Wb/2,b.m(Q++,Xa,Fa),b.m(Q++,vb,Fa)),b.m(Q++,vb,fa);b.m(Q++,ha,fa)}else if(ja)kb=Math.max(10,this.Gd[p.layer]),Na=Math.max(10,this.md[p.layer]),
180===this.L&&ha>=p.bounds.x?(Fa=p.bounds.x+p.bounds.width,b.M(Q-2,Fa,Ma),b.M(Q-1,Fa+Na,Ma)):90===this.L&&fa<=p.bounds.bottom?(Fa=p.bounds.y,b.M(Q-2,Xa,Fa),b.M(Q-1,Xa,Fa-kb)):270===this.L&&fa>=p.bounds.y?(Fa=p.bounds.y+p.bounds.height,b.M(Q-2,Xa,Fa),b.M(Q-1,Xa,Fa+Na)):0===this.L&&ha<=p.bounds.right&&(Fa=p.bounds.x,b.M(Q-2,Fa,Ma),b.M(Q-1,Fa-kb,Ma));else{kb=Math.max(10,this.Gd[p.layer]);Na=Math.max(10,this.md[p.layer]);var zf=0;if(180===this.L||0===this.L){if(180===this.L?ha<=p.bounds.x:ha>=p.bounds.right)zf=
(0===this.L?Na:-kb)/2;b.m(Q++,ha+zf,Ma)}else{if(270===this.L?fa<=p.bounds.y:fa>=p.bounds.bottom)zf=(90===this.L?Na:-kb)/2;b.m(Q++,Xa,fa+zf)}b.m(Q++,ha,fa)}}else{for(;null!==p&&p!==q;){Ra=Da=null;for(var Eb=p.destinationEdges.iterator;Eb.next();){var qe=Eb.value;if(qe.link===c.link&&(Da=qe.toVertex,Ra=qe.fromVertex,null!==Ra.node&&(Ra=null),null===Da.node))break}Da!==q&&(Xa=b.i(Q-1).x,Ma=b.i(Q-1).y,ha=Da.centerX,fa=Da.centerY,J?180===this.L||0===this.L?(null!==Ra?Ra.centerY:Ma)!==fa&&(hb=this.Ka[Da.layer],
Q===b.firstPickIndex+1&&(hb=0===this.L?Math.max(hb,Xa):Math.min(hb,Xa)),b.m(Q++,hb,Ma),b.m(Q++,hb,fa)):(null!==Ra?Ra.centerX:Xa)!==ha&&(hb=this.Ka[Da.layer],Q===b.firstPickIndex+1&&(hb=90===this.L?Math.max(hb,Ma):Math.min(hb,Ma)),b.m(Q++,Xa,hb),b.m(Q++,ha,hb)):(kb=Math.max(10,this.Gd[Da.layer]),Na=Math.max(10,this.md[Da.layer]),180===this.L?(b.m(Q++,ha+Na,fa),ja&&b.m(Q++,ha,fa),b.m(Q++,ha-kb,fa)):90===this.L?(b.m(Q++,ha,fa-kb),ja&&b.m(Q++,ha,fa),b.m(Q++,ha,fa+Na)):270===this.L?(b.m(Q++,ha,fa+Na),
ja&&b.m(Q++,ha,fa),b.m(Q++,ha,fa-kb)):(b.m(Q++,ha-kb,fa),ja&&b.m(Q++,ha,fa),b.m(Q++,ha+Na,fa))));p=Da}J&&(Xa=b.i(Q-1).x,Ma=b.i(Q-1).y,ha=b.i(Q).x,fa=b.i(Q).y,180===this.L||0===this.L?Ma!==fa&&(hb=0===this.L?Math.min(Math.max((ha+Xa)/2,this.Ka[q.layer]),ha):Math.max(Math.min((ha+Xa)/2,this.Ka[q.layer]),ha),b.m(Q++,hb,Ma),b.m(Q++,hb,fa)):Xa!==ha&&(hb=90===this.L?Math.min(Math.max((fa+Ma)/2,this.Ka[q.layer]),fa):Math.max(Math.min((fa+Ma)/2,this.Ka[q.layer]),fa),b.m(Q++,Xa,hb),b.m(Q++,ha,hb)))}if(null!==
d&&ja){if(null!==g){if(m===Sc){var Wc=b.i(0),Vb=b.i(2);Wc.A(Vb)||b.M(1,(Wc.x+Vb.x)/2,(Wc.y+Vb.y)/2)}var Md=b.getLinkPoint(e,g,Sc,!0,!1,f,h);Md.o()||Md.set(e.actualBounds.center);b.M(0,Md.x,Md.y)}if(null!==h){if(n===Sc){var Ec=b.i(b.pointsCount-1),Ie=b.i(b.pointsCount-3);Ec.A(Ie)||b.M(b.pointsCount-2,(Ec.x+Ie.x)/2,(Ec.y+Ie.y)/2)}var Je=b.getLinkPoint(f,h,Sc,!1,!1,e,g);Je.o()||Je.set(f.actualBounds.center);b.M(b.pointsCount-1,Je.x,Je.y)}}b.lf();c.commit()}}}for(var Af=new E,gf=this.network.edges.iterator;gf.next();){var Bf=
gf.value.link;null!==Bf&&Bf.isOrthogonal&&!Af.contains(Bf)&&Af.add(Bf)}if(0<Af.count)if(90===this.L||270===this.L){for(var Wb=0,Ya=[],ab,Mc,Cf=Af.iterator;Cf.next();){var jc=Cf.value;if(null!==jc&&jc.isOrthogonal)for(var Nd=2;Nd<jc.pointsCount-3;Nd++)if(ab=jc.i(Nd),Mc=jc.i(Nd+1),this.w(ab.y,Mc.y)&&!this.w(ab.x,Mc.x)){var qc=new vs;qc.layer=Math.floor(ab.y/2);var Od=jc.i(0),Xb=jc.i(jc.pointsCount-1);qc.first=Od.x*Od.x+Od.y;qc.Sb=Xb.x*Xb.x+Xb.y;qc.Sc=Math.min(ab.x,Mc.x);qc.sc=Math.max(ab.x,Mc.x);qc.index=
Nd;qc.link=jc;if(Nd+2<jc.pointsCount){var Dh=jc.i(Nd-1),Eh=jc.i(Nd+2),Ke=0;Dh.y<ab.y?Ke=Eh.y<ab.y?3:ab.x<Mc.x?2:1:Dh.y>ab.y&&(Ke=Eh.y>ab.y?0:Mc.x<ab.x?2:1);qc.l=Ke}Ya.push(qc)}}if(1<Ya.length){Ya.sort(this.Ax);for(var pb=0;pb<Ya.length;){for(var Df=Ya[pb].layer,Le=pb+1;Le<Ya.length&&Ya[Le].layer===Df;)Le++;if(1<Le-pb)for(var Yb=pb;Yb<Le;){for(var Me=Ya[Yb].sc,Nc=pb+1;Nc<Le&&Ya[Nc].Sc<Me;)Me=Math.max(Me,Ya[Nc].sc),Nc++;var eb=Nc-Yb;if(1<eb){this.Xi(Ya,this.rt,Yb,Yb+eb);for(var Ne=1,rb=Ya[Yb].Sb,rc=
Yb;rc<Nc;rc++){var lb=Ya[rc];lb.Sb!==rb&&(Ne++,rb=lb.Sb)}this.Xi(Ya,this.zx,Yb,Yb+eb);var Xc=1;rb=Ya[Yb].first;for(var Yc=Yb;Yc<Nc;Yc++){var xd=Ya[Yc];xd.first!==rb&&(Xc++,rb=xd.first)}var Bg=!0,Ef=Xc;Ne<Xc?(Bg=!1,Ef=Ne,rb=Ya[Yb].Sb,this.Xi(Ya,this.rt,Yb,Yb+eb)):rb=Ya[Yb].first;for(var Ff=0,hf=Yb;hf<Nc;hf++){var kd=Ya[hf];(Bg?kd.first:kd.Sb)!==rb&&(Ff++,rb=Bg?kd.first:kd.Sb);var Pd=kd.link;ab=Pd.i(kd.index);Mc=Pd.i(kd.index+1);var ld=this.linkSpacing*(Ff-(Ef-1)/2);Wb++;Pd.Ch();Pd.M(kd.index,ab.x,
ab.y+ld);Pd.M(kd.index+1,Mc.x,Mc.y+ld);Pd.lf()}}Yb=Nc}pb=Le}}}else{for(var md=0,ob=[],fb,nd,Zc=Af.iterator;Zc.next();){var od=Zc.value;if(null!==od&&od.isOrthogonal)for(var vc=2;vc<od.pointsCount-3;vc++)if(fb=od.i(vc),nd=od.i(vc+1),this.w(fb.x,nd.x)&&!this.w(fb.y,nd.y)){var Ba=new vs;Ba.layer=Math.floor(fb.x/2);var $c=od.i(0),Zb=od.i(od.pointsCount-1);Ba.first=$c.x+$c.y*$c.y;Ba.Sb=Zb.x+Zb.y*Zb.y;Ba.Sc=Math.min(fb.y,nd.y);Ba.sc=Math.max(fb.y,nd.y);Ba.index=vc;Ba.link=od;if(vc+2<od.pointsCount){var fe=
od.i(vc-1),Oe=od.i(vc+2),ge=0;fe.x<fb.x?ge=Oe.x<fb.x?3:fb.y<nd.y?2:1:fe.x>fb.x&&(ge=Oe.x>fb.x?0:nd.y<fb.y?2:1);Ba.l=ge}ob.push(Ba)}}if(1<ob.length){ob.sort(this.Ax);for(var wc=0;wc<ob.length;){for(var re=ob[wc].layer,ad=wc+1;ad<ob.length&&ob[ad].layer===re;)ad++;if(1<ad-wc)for(var wb=wc;wb<ad;){for(var Qd=ob[wb].sc,Mb=wc+1;Mb<ad&&ob[Mb].Sc<Qd;)Qd=Math.max(Qd,ob[Mb].sc),Mb++;var se=Mb-wb;if(1<se){this.Xi(ob,this.rt,wb,wb+se);for(var yd=1,he=ob[wb].Sb,Cg=wb;Cg<Mb;Cg++){var bd=ob[Cg];bd.Sb!==he&&(yd++,
he=bd.Sb)}this.Xi(ob,this.zx,wb,wb+se);var Pe=1;he=ob[wb].first;for(var Dg=wb;Dg<Mb;Dg++){var Gf=ob[Dg];Gf.first!==he&&(Pe++,he=Gf.first)}var Qe=!0,Eg=Pe;yd<Pe?(Qe=!1,Eg=yd,he=ob[wb].Sb,this.Xi(ob,this.rt,wb,wb+se)):he=ob[wb].first;for(var cg=0,Fi=wb;Fi<Mb;Fi++){var Re=ob[Fi];(Qe?Re.first:Re.Sb)!==he&&(cg++,he=Qe?Re.first:Re.Sb);var Se=Re.link;fb=Se.i(Re.index);nd=Se.i(Re.index+1);var Fh=this.linkSpacing*(cg-(Eg-1)/2);md++;Se.Ch();Se.M(Re.index,fb.x+Fh,fb.y);Se.M(Re.index+1,nd.x+Fh,nd.y);Se.lf()}}wb=
Mb}wc=ad}}}};t=Cr.prototype;t.Ax=function(a,b){return a instanceof vs&&b instanceof vs&&a!==b?a.layer<b.layer?-1:a.layer>b.layer?1:a.Sc<b.Sc?-1:a.Sc>b.Sc?1:a.sc<b.sc?-1:a.sc>b.sc?1:0:0};t.zx=function(a,b){return a instanceof vs&&b instanceof vs&&a!==b?a.first<b.first?-1:a.first>b.first||a.l<b.l?1:a.l>b.l||a.Sc<b.Sc?-1:a.Sc>b.Sc?1:a.sc<b.sc?-1:a.sc>b.sc?1:0:0};
t.rt=function(a,b){return a instanceof vs&&b instanceof vs&&a!==b?a.Sb<b.Sb?-1:a.Sb>b.Sb||a.l<b.l?1:a.l>b.l||a.Sc<b.Sc?-1:a.Sc>b.Sc?1:a.sc<b.sc?-1:a.sc>b.sc?1:0:0};t.w=function(a,b){a-=b;return-1<a&&1>a};
t.Xi=function(a,b,c,d){var e=a.length,f=d-c;if(!(1>=f))if((0>c||c>=e-1)&&A("not in range 0 <= from < length: "+c),2===f)d=a[c],e=a[c+1],0<b(d,e)&&(a[c]=e,a[c+1]=d);else if(0===c)if(d>=e)a.sort(b);else for(c=a.slice(0,d),c.sort(b),b=0;b<d;b++)a[b]=c[b];else if(d>=e)for(d=a.slice(c),d.sort(b),b=c;b<e;b++)a[b]=d[b-c];else for(e=a.slice(c,d),e.sort(b),b=c;b<d;b++)a[b]=e[b-c]};
function Lr(a,b){var c=a.fc[b];if(c>=a.pe.length){var d=[];for(var e=0;e<a.pe.length;e++)d[e]=a.pe[e];a.pe=d}void 0===a.pe[c]||null===a.pe[c]?d=[]:(d=a.pe[c],a.pe[c]=null);a=a.Ao[b];for(b=0;b<a.length;b++)c=a[b],d[c.index]=c;return d}function Mr(a,b,c){a.pe[a.fc[b]]=c}
pa.Object.defineProperties(Cr.prototype,{layerSpacing:{get:function(){return this.je},set:function(a){this.je!==a&&0<=a&&(this.je=a,this.B())}},columnSpacing:{get:function(){return this.Wb},set:function(a){this.Wb!==a&&0<a&&(this.Wb=a,this.B())}},direction:{get:function(){return this.L},set:function(a){this.L!==a&&(0===a||90===a||180===a||270===a?(this.L=a,this.B()):A("LayeredDigraphLayout.direction must be 0, 90, 180, or 270"))}},
cycleRemoveOption:{get:function(){return this.Lk},set:function(a){this.Lk===a||a!==Wr&&a!==Dr&&a!==Ir||(this.Lk=a,this.B())}},layeringOption:{get:function(){return this.ll},set:function(a){this.ll===a||a!==Er&&a!==as&&a!==cs||(this.ll=a,this.B())}},initializeOption:{get:function(){return this.bl},set:function(a){this.bl===a||a!==Fr&&a!==is&&a!==gs||(this.bl=a,this.B())}},iterations:{
get:function(){return this.rj},set:function(a){this.rj!==a&&0<=a&&(this.rj=a,this.B())}},aggressiveOption:{get:function(){return this.xk},set:function(a){this.xk===a||a!==ms&&a!==Gr&&a!==ns||(this.xk=a,this.B())}},packOption:{get:function(){return this.$f},set:function(a){this.$f!==a&&0<=a&&8>a&&(this.$f=a,this.B())}},setsPortSpots:{get:function(){return this.Ze},set:function(a){this.Ze!==a&&(this.Ze=a,this.B())}},
linkSpacing:{get:function(){return this.eo},set:function(a){this.eo!==a&&0<=a&&(this.eo=a,this.B())}},maxLayer:{get:function(){return this.ya}},maxIndex:{get:function(){return this.Pr}},maxColumn:{get:function(){return this.Ha}},minIndexLayer:{get:function(){return this.po}},maxIndexLayer:{get:function(){return this.od}}});
var Dr=new D(Cr,"CycleDepthFirst",0),Wr=new D(Cr,"CycleGreedy",1),Ir=new D(Cr,"CycleFromLayers",2),Er=new D(Cr,"LayerOptimalLinkLength",0),as=new D(Cr,"LayerLongestPathSink",1),cs=new D(Cr,"LayerLongestPathSource",2),Fr=new D(Cr,"InitDepthFirstOut",0),is=new D(Cr,"InitDepthFirstIn",1),gs=new D(Cr,"InitNaive",2),ms=new D(Cr,"AggressiveNone",0),Gr=new D(Cr,"AggressiveLess",1),ns=new D(Cr,"AggressiveMore",2);Cr.className="LayeredDigraphLayout";Cr.CycleDepthFirst=Dr;Cr.CycleGreedy=Wr;
Cr.CycleFromLayers=Ir;Cr.LayerOptimalLinkLength=Er;Cr.LayerLongestPathSink=as;Cr.LayerLongestPathSource=cs;Cr.InitDepthFirstOut=Fr;Cr.InitDepthFirstIn=is;Cr.InitNaive=gs;Cr.AggressiveNone=ms;Cr.AggressiveLess=Gr;Cr.AggressiveMore=ns;Cr.PackNone=0;Cr.PackExpand=1;Cr.PackStraighten=2;Cr.PackMedian=4;Cr.PackAll=7;function vs(){this.index=this.sc=this.Sc=this.Sb=this.first=this.layer=0;this.link=null;this.l=0}vs.className="SegInfo";function Hr(a){xp.call(this,a)}oa(Hr,xp);Hr.prototype.createVertex=function(){return new ws(this)};
Hr.prototype.createEdge=function(){return new xs(this)};Hr.className="LayeredDigraphNetwork";function ws(a){Ap.call(this,a);this.Sa=this.Eg=this.gi=-1;this.K=NaN;this.ba=null;this.valid=!1;this.finish=this.Xl=NaN;this.Rj=0;this.pv=this.qv=null}oa(ws,Ap);
pa.Object.defineProperties(ws.prototype,{layer:{get:function(){return this.gi},set:function(a){this.gi!==a&&(this.gi=a)}},column:{get:function(){return this.Eg},set:function(a){this.Eg!==a&&(this.Eg=a)}},index:{get:function(){return this.Sa},set:function(a){this.Sa!==a&&(this.Sa=a)}},component:{get:function(){return this.K},set:function(a){this.K!==a&&(this.K=a)}},near:{
get:function(){return this.ba},set:function(a){this.ba!==a&&(this.ba=a)}}});ws.className="LayeredDigraphVertex";function xs(a){Bp.call(this,a);this.l=this.Ma=this.Ub=!1;this.La=this.K=NaN;this.ba=this.u=0}oa(xs,Bp);
pa.Object.defineProperties(xs.prototype,{valid:{get:function(){return this.Ub},set:function(a){this.Ub!==a&&(this.Ub=a)}},rev:{get:function(){return this.Ma},set:function(a){this.Ma!==a&&(this.Ma=a)}},forest:{get:function(){return this.l},set:function(a){this.l!==a&&(this.l=a)}},portFromPos:{get:function(){return this.K},set:function(a){this.K!==a&&(this.K=a)}},portToPos:{
get:function(){return this.La},set:function(a){this.La!==a&&(this.La=a)}},portFromColOffset:{get:function(){return this.u},set:function(a){this.u!==a&&(this.u=a)}},portToColOffset:{get:function(){return this.ba},set:function(a){this.ba!==a&&(this.ba=a)}}});xs.className="LayeredDigraphEdge";
function ys(){yi.call(this);this.Fb=new F;this.Oo=zs;this.Zc=As;this.Fp=Bs;this.Nr=Cs;this.gw=[];this.Xc=!0;this.Ab=Ds;this.wd=(new L(10,10)).freeze();var a=new Es(this);this.U=new Fs(a);this.V=new Fs(a);this.wu=[]}oa(ys,yi);ys.prototype.cloneProtected=function(a){yi.prototype.cloneProtected.call(this,a);a.Oo=this.Oo;a.Fp=this.Fp;a.Nr=this.Nr;a.Xc=this.Xc;a.Ab=this.Ab;a.wd.assign(this.wd);a.U.copyInheritedPropertiesFrom(this.U);a.V.copyInheritedPropertiesFrom(this.V)};
ys.prototype.fb=function(a){a.classType===ys?0===a.name.indexOf("Alignment")?this.alignment=a:0===a.name.indexOf("Arrangement")?this.arrangement=a:0===a.name.indexOf("Compaction")?this.compaction=a:0===a.name.indexOf("Path")?this.path=a:0===a.name.indexOf("Sorting")?this.sorting=a:0===a.name.indexOf("Style")?this.treeStyle=a:A("Unknown enum value: "+a):yi.prototype.fb.call(this,a)};ys.prototype.createNetwork=function(){return new Es(this)};
ys.prototype.makeNetwork=function(a){function b(a){if(a instanceof V)return!a.isLinkLabel&&"Comment"!==a.category;if(a instanceof S){var b=a.fromNode;if(null===b||b.isLinkLabel||"Comment"===b.category)return!1;a=a.toNode;return null===a||a.isLinkLabel||"Comment"===a.category?!1:!0}return!1}var c=this.createNetwork();a instanceof P?(c.lg(a.nodes,!0,b),c.lg(a.links,!0,b)):a instanceof ig?c.lg(a.memberParts,!1,b):c.lg(a.iterator,!1,b);return c};
ys.prototype.doLayout=function(a){null===this.network&&(this.network=this.makeNetwork(a));this.arrangement!==Gs&&(this.arrangementOrigin=this.initialOrigin(this.arrangementOrigin));var b=this.diagram;null===b&&a instanceof P&&(b=a);this.path===zs&&null!==b?this.Zc=b.isTreePathToChildren?As:Hs:this.Zc=this.path===zs?As:this.path;if(0<this.network.vertexes.count){this.network.Sp();for(a=this.network.vertexes.iterator;a.next();)b=a.value,b.initialized=!1,b.level=0,b.parent=null,b.children=[];if(0<this.Fb.count){a=
new F;for(b=this.Fb.iterator;b.next();){var c=b.value;c instanceof V?(c=this.network.Ki(c),null!==c&&a.add(c)):c instanceof Fs&&a.add(c)}this.Fb=a}0===this.Fb.count&&this.findRoots();for(a=this.Fb.copy().iterator;a.next();)b=a.value,b.initialized||(b.initialized=!0,Is(this,b));b=this.network.vertexes;for(a=null;a=Js(b),0<a.count;)b=Ks(this,a),null!==b&&this.Fb.add(b),b.initialized=!0,Is(this,b),b=a;for(a=this.Fb.iterator;a.next();)b=a.value,b instanceof Fs&&Ls(this,b);for(a=this.Fb.iterator;a.next();)b=
a.value,b instanceof Fs&&Ms(this,b);for(a=this.Fb.iterator;a.next();)b=a.value,b instanceof Fs&&Ns(this,b);this.Hu();if(this.layerStyle===Os){a=[];for(b=this.network.vertexes.iterator;b.next();){c=b.value;var d=c.parent;null===d&&(d=c);d=0===d.angle||180===d.angle;var e=a[c.level];void 0===e&&(e=0);a[c.level]=Math.max(e,d?c.width:c.height)}for(b=0;b<a.length;b++)void 0===a[b]&&(a[b]=0);this.gw=a;for(b=this.network.vertexes.iterator;b.next();)c=b.value,d=c.parent,null===d&&(d=c),0===d.angle||180===
d.angle?(180===d.angle&&(c.focusX+=a[c.level]-c.width),c.width=a[c.level]):(270===d.angle&&(c.focusY+=a[c.level]-c.height),c.height=a[c.level])}else if(this.layerStyle===Ps)for(a=this.network.vertexes.iterator;a.next();){b=a.value;c=0===b.angle||180===b.angle;d=-1;for(e=0;e<b.children.length;e++){var f=b.children[e];d=Math.max(d,c?f.width:f.height)}if(0<=d)for(e=0;e<b.children.length;e++)f=b.children[e],c?(180===b.angle&&(f.focusX+=d-f.width),f.width=d):(270===b.angle&&(f.focusY+=d-f.height),f.height=
d)}for(a=this.Fb.iterator;a.next();)b=a.value,b instanceof Fs&&this.layoutTree(b);this.arrangeTrees();this.updateParts()}this.network=null;this.Fb=new F;this.isValidLayout=!0};function Js(a){var b=new F;for(a=a.iterator;a.next();){var c=a.value;c.initialized||b.add(c)}return b}
ys.prototype.findRoots=function(){for(var a=this.network.vertexes,b=a.iterator;b.next();){var c=b.value;switch(this.Zc){case As:0===c.sourceEdges.count&&this.Fb.add(c);break;case Hs:0===c.destinationEdges.count&&this.Fb.add(c);break;default:A("Unhandled path value "+this.Zc.toString())}}0===this.Fb.count&&(a=Ks(this,a),null!==a&&this.Fb.add(a))};
function Ks(a,b){var c=999999,d=null;for(b=b.iterator;b.next();){var e=b.value;switch(a.Zc){case As:e.sourceEdges.count<c&&(c=e.sourceEdges.count,d=e);break;case Hs:e.destinationEdges.count<c&&(c=e.destinationEdges.count,d=e);break;default:A("Unhandled path value "+a.Zc.toString())}}return d}
function Is(a,b){if(null!==b){switch(a.Zc){case As:if(0<b.destinationEdges.count){for(var c=new E,d=b.destinationVertexes;d.next();){var e=d.value;Qs(a,b,e)&&c.add(e)}0<c.count&&(b.children=c.Na())}break;case Hs:if(0<b.sourceEdges.count){c=new E;for(d=b.sourceVertexes;d.next();)e=d.value,Qs(a,b,e)&&c.add(e);0<c.count&&(b.children=c.Na())}break;default:A("Unhandled path value"+a.Zc.toString())}c=b.children;d=c.length;for(e=0;e<d;e++){var f=c[e];f.initialized=!0;f.level=b.level+1;f.parent=b;a.Fb.remove(f)}for(b=
0;b<d;b++)Is(a,c[b])}}function Qs(a,b,c){if(c.initialized){if(null===b)var d=!1;else{for(d=b.parent;null!==d&&d!==c;)d=d.parent;d=d===c}if(d||c.level>b.level)return!1;a.removeChild(c.parent,c)}return!0}ys.prototype.removeChild=function(a,b){if(null!==a&&null!==b){for(var c=a.children,d=0,e=0;e<c.length;e++)c[e]===b&&d++;if(0<d){d=Array(c.length-d);for(var f=e=0;f<c.length;f++)c[f]!==b&&(d[e++]=c[f]);a.children=d}}};
function Ls(a,b){if(null!==b){a.initializeTreeVertexValues(b);b.alignment===Rs&&a.sortTreeVertexChildren(b);for(var c=0,d=b.childrenCount,e=0,f=b.children,g=f.length,h=0;h<g;h++){var k=f[h];Ls(a,k);c+=k.descendantCount+1;d=Math.max(d,k.maxChildrenCount);e=Math.max(e,k.maxGenerationCount)}b.descendantCount=c;b.maxChildrenCount=d;b.maxGenerationCount=0<d?e+1:0}}
function Ss(a,b){switch(a.Fp){default:case Bs:return null!==b.parent?b.parent:a.U;case Ts:return null===b.parent?a.U:null===b.parent.parent?a.V:b.parent;case Us:return null!==b.parent?null!==b.parent.parent?b.parent.parent:a.V:a.U;case Vs:var c=!0;if(0===b.childrenCount)c=!1;else for(var d=b.children,e=d.length,f=0;f<e;f++)if(0<d[f].childrenCount){c=!1;break}return c&&null!==b.parent?a.V:null!==b.parent?b.parent:a.U}}
ys.prototype.initializeTreeVertexValues=function(a){a.copyInheritedPropertiesFrom(Ss(this,a));if(null!==a.parent&&a.parent.alignment===Rs){for(var b=a.angle,c=a.parent.children,d=0;d<c.length&&a!==c[d];)d++;0===d%2?d!==c.length-1&&(b=90===b?180:180===b?270:270===b?180:270):b=90===b?0:180===b?90:270===b?0:90;a.angle=b}a.initialized=!0};function Ms(a,b){if(null!==b){a.assignTreeVertexValues(b);b=b.children;for(var c=b.length,d=0;d<c;d++)Ms(a,b[d])}}ys.prototype.assignTreeVertexValues=function(){};
function Ns(a,b){if(null!==b){b.alignment!==Rs&&a.sortTreeVertexChildren(b);b=b.children;for(var c=b.length,d=0;d<c;d++)Ns(a,b[d])}}ys.prototype.sortTreeVertexChildren=function(a){switch(a.sorting){case Ws:break;case Xs:a.children.reverse();break;case Ys:a.children.sort(a.comparer);break;case Zs:a.children.sort(a.comparer);a.children.reverse();break;default:A("Unhandled sorting value "+a.sorting.toString())}};ys.prototype.Hu=function(){if(this.comments)for(var a=this.network.vertexes.iterator;a.next();)this.addComments(a.value)};
ys.prototype.addComments=function(a){var b=a.angle,c=a.parent,d=0;var e=!1;null!==c&&(d=c.angle,e=c.alignment,e=$s(e));b=90===b||270===b;d=90===d||270===d;c=0===a.childrenCount;var f=0,g=0,h=0,k=a.commentSpacing;if(null!==a.node)for(var l=a.node.Xu();l.next();){var m=l.value;"Comment"===m.category&&m.canLayout()&&(null===a.comments&&(a.comments=[]),a.comments.push(m),m.$b(),m=m.measuredBounds,b&&!c||!e&&!d&&c||e&&d&&c?(f=Math.max(f,m.width),g+=m.height+Math.abs(h)):(f+=m.width+Math.abs(h),g=Math.max(g,
m.height)),h=k)}null!==a.comments&&(b&&!c||!e&&!d&&c||e&&d&&c?(f+=Math.abs(a.commentMargin),g=Math.max(0,g-a.height)):(g+=Math.abs(a.commentMargin),f=Math.max(0,f-a.width)),e=N.allocAt(0,0,a.bounds.width+f,a.bounds.height+g),a.bounds=e,N.free(e))};function $s(a){return a===at||a===Rs||a===bt||a===ct}function dt(a){return a===at||a===Rs}
function et(a){var b=a.parent;if(null!==b){var c=b.alignment;if($s(c)){if(dt(c)){b=b.children;for(c=0;c<b.length&&a!==b[c];)c++;return 0===c%2}if(c===bt)return!0}}return!1}
ys.prototype.layoutComments=function(a){if(null!==a.comments){var b=a.node.measuredBounds,c=a.parent,d=a.angle,e=0;var f=!1;null!==c&&(e=c.angle,f=c.alignment,f=$s(f));d=90===d||270===d;c=90===e||270===e;for(var g=0===a.childrenCount,h=et(a),k=0,l=a.comments,m=l.length,n=H.alloc(),p=0;p<m;p++){var q=l[p],r=q.measuredBounds;if(d&&!g||!f&&!c&&g||f&&c&&g){if(135<e&&!f||c&&h)if(0<=a.commentMargin)for(n.h(a.bounds.x-a.commentMargin-r.width,a.bounds.y+k),q.move(n),q=q.sd();q.next();){var u=q.value;u.fromSpot=
Kd;u.toSpot=Sd}else for(n.h(a.bounds.x+2*a.focus.x-a.commentMargin,a.bounds.y+k),q.move(n),q=q.sd();q.next();)u=q.value,u.fromSpot=Sd,u.toSpot=Kd;else if(0<=a.commentMargin)for(n.h(a.bounds.x+2*a.focus.x+a.commentMargin,a.bounds.y+k),q.move(n),q=q.sd();q.next();)u=q.value,u.fromSpot=Sd,u.toSpot=Kd;else for(n.h(a.bounds.x+a.commentMargin-r.width,a.bounds.y+k),q.move(n),q=q.sd();q.next();)u=q.value,u.fromSpot=Kd,u.toSpot=Sd;k=0<=a.commentSpacing?k+(r.height+a.commentSpacing):k+(a.commentSpacing-r.height)}else{if(135<
e&&!f||!c&&h)if(0<=a.commentMargin)for(n.h(a.bounds.x+k,a.bounds.y-a.commentMargin-r.height),q.move(n),q=q.sd();q.next();)u=q.value,u.fromSpot=Jd,u.toSpot=Td;else for(n.h(a.bounds.x+k,a.bounds.y+2*a.focus.y-a.commentMargin),q.move(n),q=q.sd();q.next();)u=q.value,u.fromSpot=Td,u.toSpot=Jd;else if(0<=a.commentMargin)for(n.h(a.bounds.x+k,a.bounds.y+2*a.focus.y+a.commentMargin),q.move(n),q=q.sd();q.next();)u=q.value,u.fromSpot=Td,u.toSpot=Jd;else for(n.h(a.bounds.x+k,a.bounds.y+a.commentMargin-r.height),
q.move(n),q=q.sd();q.next();)u=q.value,u.fromSpot=Jd,u.toSpot=Td;k=0<=a.commentSpacing?k+(r.width+a.commentSpacing):k+(a.commentSpacing-r.width)}}H.free(n);b=k-a.commentSpacing-(d?b.height:b.width);if(this.Zc===As)for(a=a.destinationEdges;a.next();)e=a.value.link,null===e||e.isAvoiding||(e.fromEndSegmentLength=0<b?b:NaN);else for(a=a.sourceEdges;a.next();)e=a.value.link,null===e||e.isAvoiding||(e.toEndSegmentLength=0<b?b:NaN)}};
ys.prototype.layoutTree=function(a){if(null!==a){for(var b=a.children,c=b.length,d=0;d<c;d++)this.layoutTree(b[d]);switch(a.compaction){case ft:gt(this,a);break;case ht:if(a.alignment===Rs)gt(this,a);else if(0===a.childrenCount)d=a.parent,c=!1,b=0,null!==d&&(b=d.angle,c=d.alignment,c=$s(c)),d=et(a),a.T.h(0,0),a.ua.h(a.width,a.height),null===a.parent||null===a.comments||(180!==b&&270!==b||c)&&!d?a.ia.h(0,0):180===b&&!c||(90===b||270===b)&&d?a.ia.h(a.width-2*a.focus.x,0):a.ia.h(0,a.height-2*a.focus.y),
a.fq=null,a.rq=null;else{var e=it(a);b=90===e||270===e;var f=0,g=a.children,h=g.length;for(c=0;c<h;c++)d=g[c],f=Math.max(f,b?d.ua.width:d.ua.height);var k=a.alignment,l=k===jt,m=$s(k),n=Math.max(0,a.breadthLimit);c=kt(a);var p=a.nodeSpacing,q=lt(a),r=a.rowSpacing,u=0;if(k===pt||l||a.tm||a.um&&1===a.maxGenerationCount)u=Math.max(0,a.rowIndent);d=a.width;var x=a.height,v=0,y=0,z=0,B=null,C=null,G=0,K=0,M=0,U=0,R=0,da=0,ja=0,J=0;m&&!dt(k)&&135<e&&g.reverse();if(dt(k))if(1<h)for(var Q=0;Q<h;Q++)0===Q%
2&&Q!==h-1&&(J=Math.max(J,b?g[Q].ua.width:g[Q].ua.height));else 1===h&&(J=b?g[0].ua.width:g[0].ua.height);if(m){switch(k){case at:y=135>e?qt(a,g,J,v,y):rt(a,g,J,v,y);J=y.x;v=y.width;y=y.height;break;case bt:for(B=0;B<h;B++)C=g[B],n=C.ua,z=0===da?0:r,b?(C.T.h(f-n.width,U+z),v=Math.max(v,n.width),y=Math.max(y,U+z+n.height),U+=z+n.height):(C.T.h(M+z,f-n.height),v=Math.max(v,M+z+n.width),y=Math.max(y,n.height),M+=z+n.width),da++;break;case ct:for(B=0;B<h;B++)C=g[B],f=C.ua,n=0===da?0:r,b?(C.T.h(p/2+a.focus.x,
U+n),v=Math.max(v,f.width),y=Math.max(y,U+n+f.height),U+=n+f.height):(C.T.h(M+n,p/2+a.focus.y),v=Math.max(v,M+n+f.width),y=Math.max(y,f.height),M+=n+f.width),da++}B=st(this,2);C=st(this,2);b?(B[0].h(0,0),B[1].h(0,y),C[0].h(v,0)):(B[0].h(0,0),B[1].h(v,0),C[0].h(0,y));C[1].h(v,y)}else for(Q=0;Q<h;Q++){var xa=g[Q],jb=xa.ua;if(b){0<n&&0<da&&M+p+jb.width>n&&(M<f&&tt(a,k,f-M,0,ja,Q-1),R++,da=0,ja=Q,z=y,M=0,U=135<e?-y-r:y+r);ut(this,xa,0,U);var Ha=0;if(0===da){if(B=xa.fq,C=xa.rq,G=jb.width,K=jb.height,null===
B||null===C||e!==it(xa))B=st(this,2),C=st(this,2),B[0].h(0,0),B[1].h(0,K),C[0].h(G,0),C[1].h(G,K)}else{var Da=Ka();K=vt(this,a,xa,B,C,G,K,Da);Ha=K.x;B=Da[0];C=Da[1];G=K.width;K=K.height;Oa(Da);M<jb.width&&0>Ha&&(wt(a,-Ha,0,ja,Q-1),xt(B,-Ha,0),xt(C,-Ha,0),Ha=0)}xa.T.h(Ha,U);v=Math.max(v,G);y=Math.max(y,z+(0===R?0:r)+jb.height);M=G}else{0<n&&0<da&&U+p+jb.height>n&&(U<f&&tt(a,k,0,f-U,ja,Q-1),R++,da=0,ja=Q,z=v,U=0,M=135<e?-v-r:v+r);ut(this,xa,M,0);Ha=0;if(0===da){if(B=xa.fq,C=xa.rq,G=jb.width,K=jb.height,
null===B||null===C||e!==it(xa))B=st(this,2),C=st(this,2),B[0].h(0,0),B[1].h(G,0),C[0].h(0,K),C[1].h(G,K)}else Da=Ka(),K=vt(this,a,xa,B,C,G,K,Da),Ha=K.x,B=Da[0],C=Da[1],G=K.width,K=K.height,Oa(Da),U<jb.height&&0>Ha&&(wt(a,0,-Ha,ja,Q-1),xt(B,0,-Ha),xt(C,0,-Ha),Ha=0);xa.T.h(M,Ha);y=Math.max(y,K);v=Math.max(v,z+(0===R?0:r)+jb.width);U=K}da++}0<R&&(b?(y+=Math.max(0,c),M<v&&tt(a,k,v-M,0,ja,h-1),0<u&&(l||wt(a,u,0,0,h-1),v+=u)):(v+=Math.max(0,c),U<y&&tt(a,k,0,y-U,ja,h-1),0<u&&(l||wt(a,0,u,0,h-1),y+=u)));
u=l=0;switch(k){case yt:b?l+=v/2-a.focus.x-q/2:u+=y/2-a.focus.y-q/2;break;case zt:0<R?b?l+=v/2-a.focus.x-q/2:u+=y/2-a.focus.y-q/2:b?(J=g[0].T.x+g[0].ia.x,l+=J+(g[h-1].T.x+g[h-1].ia.x+2*g[h-1].focus.x-J)/2-a.focus.x-q/2):(J=g[0].T.y+g[0].ia.y,u+=J+(g[h-1].T.y+g[h-1].ia.y+2*g[h-1].focus.y-J)/2-a.focus.y-q/2);break;case pt:b?(l-=q,v+=q):(u-=q,y+=q);break;case jt:b?(l+=v-a.width+q,v+=q):(u+=y-a.height+q,y+=q);break;case at:b?1<h?l+=J+p/2-a.focus.x:l+=g[0].focus.x-a.focus.x+g[0].ia.x:1<h?u+=J+p/2-a.focus.y:
u+=g[0].focus.y-a.focus.y+g[0].ia.y;break;case bt:b?l+=v+p/2-a.focus.x:u+=y+p/2-a.focus.y;break;case ct:break;default:A("Unhandled alignment value "+k.toString())}for(q=0;q<h;q++)J=g[q],b?J.T.h(J.T.x+J.ia.x-l,J.T.y+(135<e?(m?-y:-J.ua.height)+J.ia.y-c:x+c+J.ia.y)):J.T.h(J.T.x+(135<e?(m?-v:-J.ua.width)+J.ia.x-c:d+c+J.ia.x),J.T.y+J.ia.y-u);h=g=0;m?b?(v=At(a,v,l),0>l&&(l=0),135<e&&(u+=y+c),y+=x+c,k===ct&&(g+=p/2+a.focus.x),h+=x+c):(135<e&&(l+=v+c),v+=d+c,y=Bt(a,y,u),0>u&&(u=0),k===ct&&(h+=p/2+a.focus.y),
g+=d+c):b?(null===a.comments?d>v&&(v=Ct(k,d-v,0),g=v.x,h=v.y,v=d,l=0):v=At(a,v,l),0>l&&(g-=l,l=0),135<e&&(u+=y+c),y=Math.max(Math.max(y,x),y+x+c),h+=x+c):(135<e&&(l+=v+c),v=Math.max(Math.max(v,d),v+d+c),null===a.comments?x>y&&(y=Ct(k,0,x-y),g=y.x,h=y.y,y=x,u=0):y=Bt(a,y,u),0>u&&(h-=u,u=0),g+=d+c);if(0<R)e=st(this,4),R=st(this,4),b?(e[2].h(0,x+c),e[3].h(e[2].x,y),R[2].h(v,e[2].y),R[3].h(R[2].x,e[3].y)):(e[2].h(d+c,0),e[3].h(v,e[2].y),R[2].h(e[2].x,y),R[3].h(e[3].x,R[2].y));else{e=st(this,B.length+
2);R=st(this,C.length+2);for(k=0;k<B.length;k++)m=B[k],e[k+2].h(m.x+g,m.y+h);for(k=0;k<C.length;k++)m=C[k],R[k+2].h(m.x+g,m.y+h)}b?(e[0].h(l,0),e[1].h(e[0].x,x),e[2].y<e[1].y&&(e[2].x>e[0].x?e[2].assign(e[1]):e[1].assign(e[2])),e[3].y<e[2].y&&(e[3].x>e[0].x?e[3].assign(e[2]):e[2].assign(e[3])),R[0].h(l+d,0),R[1].h(R[0].x,x),R[2].y<R[1].y&&(R[2].x<R[0].x?R[2].assign(R[1]):R[1].assign(R[2])),R[3].y<R[2].y&&(R[3].x<R[0].x?R[3].assign(R[2]):R[2].assign(R[3])),e[2].y-=c/2,R[2].y-=c/2):(e[0].h(0,u),e[1].h(d,
e[0].y),e[2].x<e[1].x&&(e[2].y>e[0].y?e[2].assign(e[1]):e[1].assign(e[2])),e[3].x<e[2].x&&(e[3].y>e[0].y?e[3].assign(e[2]):e[2].assign(e[3])),R[0].h(0,u+x),R[1].h(d,R[0].y),R[2].x<R[1].x&&(R[2].y<R[0].y?R[2].assign(R[1]):R[1].assign(R[2])),R[3].x<R[2].x&&(R[3].y<R[0].y?R[3].assign(R[2]):R[2].assign(R[3])),e[2].x-=c/2,R[2].x-=c/2);Dt(this,B);Dt(this,C);a.fq=e;a.rq=R;a.ia.h(l,u);a.ua.h(v,y)}break;default:A("Unhandled compaction value "+a.compaction.toString())}}};
function gt(a,b){if(0===b.childrenCount){var c=!1,d=0;null!==b.parent&&(d=b.parent.angle,c=b.parent.alignment,c=$s(c));var e=et(b);b.T.h(0,0);b.ua.h(b.width,b.height);null===b.parent||null===b.comments||(180!==d&&270!==d||c)&&!e?b.ia.h(0,0):180===d&&!c||(90===d||270===d)&&e?b.ia.h(b.width-2*b.focus.x,0):b.ia.h(0,b.height-2*b.focus.y)}else{d=it(b);c=90===d||270===d;var f=0;e=b.children;for(var g=e.length,h=0;h<g;h++){var k=e[h];f=Math.max(f,c?k.ua.width:k.ua.height)}var l=b.alignment,m=l===pt,n=l===
jt;h=$s(l);var p=Math.max(0,b.breadthLimit);k=kt(b);var q=b.nodeSpacing,r=lt(b),u=m||n?0:r/2,x=b.rowSpacing,v=0;if(m||n||b.tm||b.um&&1===b.maxGenerationCount)v=Math.max(0,b.rowIndent);m=b.width;var y=b.height,z=0,B=0,C=0,G=0,K=0,M=0,U=0,R=0,da=0;h&&!dt(l)&&135<d&&e.reverse();if(dt(l))if(1<g)for(var ja=0;ja<g;ja++){var J=e[ja],Q=J.ua;0===ja%2&&ja!==g-1&&(da=Math.max(da,(c?Q.width:Q.height)+Et(J)-q))}else 1===g&&(da=c?e[0].ua.width:e[0].ua.height);if(h)switch(l){case at:case Rs:B=135>d?qt(b,e,da,z,
B):rt(b,e,da,z,B);da=B.x;z=B.width;B=B.height;break;case bt:for(a=0;a<g;a++)p=e[a],u=p.ua,C=0===U?0:x,c?(p.T.h(f-u.width,K+C),z=Math.max(z,u.width),B=Math.max(B,K+C+u.height),K+=C+u.height):(p.T.h(G+C,f-u.height),z=Math.max(z,G+C+u.width),B=Math.max(B,u.height),G+=C+u.width),U++;break;case ct:for(f=0;f<g;f++)a=e[f],p=a.ua,u=0===U?0:x,c?(a.T.h(q/2+b.focus.x,K+u),z=Math.max(z,p.width),B=Math.max(B,K+u+p.height),K+=u+p.height):(a.T.h(G+u,q/2+b.focus.y),z=Math.max(z,G+u+p.width),B=Math.max(B,p.height),
G+=u+p.width),U++}else for(ja=0;ja<g;ja++){J=e[ja];Q=J.ua;if(c){0<p&&0<U&&G+q+Q.width>p&&(G<f&&tt(b,l,f-G,0,R,ja-1),M++,U=0,R=ja,C=B,G=0,K=135<d?-B-x:B+x);var xa=0===U?u:q;ut(a,J,0,K);J.T.h(G+xa,K);z=Math.max(z,G+xa+Q.width);B=Math.max(B,C+(0===M?0:x)+Q.height);G+=xa+Q.width}else 0<p&&0<U&&K+q+Q.height>p&&(K<f&&tt(b,l,0,f-K,R,ja-1),M++,U=0,R=ja,C=z,K=0,G=135<d?-z-x:z+x),xa=0===U?u:q,ut(a,J,G,0),J.T.h(G,K+xa),B=Math.max(B,K+xa+Q.height),z=Math.max(z,C+(0===M?0:x)+Q.width),K+=xa+Q.height;U++}0<M&&(c?
(B+=Math.max(0,k),G<z&&tt(b,l,z-G,0,R,g-1),0<v&&(n||wt(b,v,0,0,g-1),z+=v)):(z+=Math.max(0,k),K<B&&tt(b,l,0,B-K,R,g-1),0<v&&(n||wt(b,0,v,0,g-1),B+=v)));v=n=0;switch(l){case yt:c?n+=z/2-b.focus.x-r/2:v+=B/2-b.focus.y-r/2;break;case zt:0<M?c?n+=z/2-b.focus.x-r/2:v+=B/2-b.focus.y-r/2:c?(l=e[0].T.x+e[0].ia.x,n+=l+(e[g-1].T.x+e[g-1].ia.x+2*e[g-1].focus.x-l)/2-b.focus.x-r/2):(l=e[0].T.y+e[0].ia.y,v+=l+(e[g-1].T.y+e[g-1].ia.y+2*e[g-1].focus.y-l)/2-b.focus.y-r/2);break;case pt:c?(n-=r,z+=r):(v-=r,B+=r);break;
case jt:c?(n+=z-b.width+r,z+=r):(v+=B-b.height+r,B+=r);break;case at:case Rs:c?1<g?n+=da+q/2-b.focus.x:n+=e[0].focus.x-b.focus.x+e[0].ia.x:1<g?v+=da+q/2-b.focus.y:v+=e[0].focus.y-b.focus.y+e[0].ia.y;break;case bt:c?n+=z+q/2-b.focus.x:v+=B+q/2-b.focus.y;break;case ct:break;default:A("Unhandled alignment value "+l.toString())}for(r=0;r<g;r++)l=e[r],c?l.T.h(l.T.x+l.ia.x-n,l.T.y+(135<d?(h?-B:-l.ua.height)+l.ia.y-k:y+k+l.ia.y)):l.T.h(l.T.x+(135<d?(h?-z:-l.ua.width)+l.ia.x-k:m+k+l.ia.x),l.T.y+l.ia.y-v);
c?(z=At(b,z,n),0>n&&(n=0),135<d&&(v+=B+k),B+=y+k):(135<d&&(n+=z+k),z+=m+k,B=Bt(b,B,v),0>v&&(v=0));b.ia.h(n,v);b.ua.h(z,B)}}
function qt(a,b,c,d,e){var f=b.length;if(0===f)return new N(c,0,d,e);if(1===f)return a=b[0],d=a.ua.width,e=a.ua.height,new N(c,0,d,e);for(var g=a.nodeSpacing,h=a.rowSpacing,k=90===it(a),l=0,m=0,n=0,p=0;p<f;p++)if(!(0!==p%2||1<f&&p===f-1)){var q=b[p],r=q.ua,u=0===l?0:h;if(k){var x=Et(q)-g;q.T.h(c-(r.width+x),n+u);d=Math.max(d,r.width+x);e=Math.max(e,n+u+r.height);n+=u+r.height}else x=Et(q)-g,q.T.h(m+u,c-(r.height+x)),e=Math.max(e,r.height+x),d=Math.max(d,m+u+r.width),m+=u+r.width;l++}l=0;q=m;p=n;k?
(m=c+g,n=0):(m=0,n=c+g);for(r=0;r<f;r++)if(0!==r%2){u=b[r];x=u.ua;var v=0===l?0:h;if(k){var y=Et(u)-g;u.T.h(m+y,n+v);d=Math.max(d,m+x.width+y);e=Math.max(e,n+v+x.height);n+=v+x.height}else y=Et(u)-g,u.T.h(m+v,n+y),d=Math.max(d,m+v+x.width),e=Math.max(e,n+x.height+y),m+=v+x.width;l++}1<f&&1===f%2&&(b=b[f-1],f=b.ua,h=null===b.parent?0:b.parent.rowSpacing,k?(b.T.h(c+g/2-b.focus.x-b.ia.x,e+h),k=c+g/2-b.focus.x-b.ia.x,d=Math.max(d,k+f.width),0>k&&(d-=k),e=Math.max(e,Math.max(p,n)+h+f.height),0>b.T.x&&
(c=Ft(a,b.T.x,!1,c,g))):(b.T.h(d+h,c+g/2-b.focus.y-b.ia.y),d=Math.max(d,Math.max(q,m)+h+f.width),n=c+g/2-b.focus.y-b.ia.y,e=Math.max(e,n+f.height),0>n&&(e-=n),0>b.T.y&&(c=Ft(a,b.T.y,!0,c,g))));return new N(c,0,d,e)}
function rt(a,b,c,d,e){var f=b.length;if(0===f)return new N(c,0,d,e);if(1===f)return b=b[0],d=b.ua.width,e=b.ua.height,new N(c,0,d,e);for(var g=a.nodeSpacing,h=a.rowSpacing,k=270===it(a),l=0,m=0,n=0,p=0;p<f;p++)if(!(0!==p%2||1<f&&p===f-1)){var q=b[p],r=q.ua,u=0===l?0:h;if(k){var x=Et(q)-g;n-=u+r.height;q.T.h(c-(r.width+x),n);d=Math.max(d,r.width+x);e=Math.max(e,Math.abs(n))}else x=Et(q)-g,m-=u+r.width,q.T.h(m,c-(r.height+x)),e=Math.max(e,r.height+x),d=Math.max(d,Math.abs(m));l++}l=0;q=m;p=n;k?(m=
c+g,n=0):(m=0,n=c+g);for(r=0;r<f;r++)if(0!==r%2){u=b[r];x=u.ua;var v=0===l?0:h;if(k){var y=Et(u)-g;n-=v+x.height;u.T.h(m+y,n);d=Math.max(d,m+x.width+y);e=Math.max(e,Math.abs(n))}else y=Et(u)-g,m-=v+x.width,u.T.h(m,n+y),e=Math.max(e,n+x.height+y),d=Math.max(d,Math.abs(m));l++}1<f&&1===f%2&&(h=b[f-1],l=h.ua,r=null===h.parent?0:h.parent.rowSpacing,k?(h.T.h(c+g/2-h.focus.x-h.ia.x,-e-l.height-r),m=c+g/2-h.focus.x-h.ia.x,d=Math.max(d,m+l.width),0>m&&(d-=m),e=Math.max(e,Math.abs(Math.min(p,n))+r+l.height),
0>h.T.x&&(c=Ft(a,h.T.x,!1,c,g))):(h.T.h(-d-l.width-r,c+g/2-h.focus.y-h.ia.y),d=Math.max(d,Math.abs(Math.min(q,m))+r+l.width),n=c+g/2-h.focus.y-h.ia.y,e=Math.max(e,n+l.height),0>n&&(e-=n),0>h.T.y&&(c=Ft(a,h.T.y,!0,c,g))));for(a=0;a<f;a++)g=b[a],k?g.T.h(g.T.x,g.T.y+e):g.T.h(g.T.x+d,g.T.y);return new N(c,0,d,e)}function Et(a){return null===a.parent?0:a.parent.nodeSpacing}
function Ft(a,b,c,d,e){a=a.children;for(var f=a.length,g=0;g<f;g++)c?a[g].T.h(a[g].T.x,a[g].T.y-b):a[g].T.h(a[g].T.x-b,a[g].T.y);b=a[f-1];return Math.max(d,c?b.ia.y+b.focus.y-e/2:b.ia.x+b.focus.x-e/2)}
function At(a,b,c){switch(a.alignment){case zt:case yt:return c+a.width>b&&(b=c+a.width),0>c&&(b-=c),b;case pt:return a.width>b?a.width:b;case jt:return 2*a.focus.x>b?a.width:b+a.width-2*a.focus.x;case at:case Rs:return Math.max(a.width,Math.max(b,c+a.width)-Math.min(0,c));case bt:return a.width-a.focus.x+a.nodeSpacing/2+b;case ct:return Math.max(a.width,a.focus.x+a.nodeSpacing/2+b);default:return b}}
function Bt(a,b,c){switch(a.alignment){case zt:case yt:return c+a.height>b&&(b=c+a.height),0>c&&(b-=c),b;case pt:return a.height>b?a.height:b;case jt:return 2*a.focus.y>b?a.height:b+a.height-2*a.focus.y;case at:case Rs:return Math.max(a.height,Math.max(b,c+a.height)-Math.min(0,c));case bt:return a.height-a.focus.y+a.nodeSpacing/2+b;case ct:return Math.max(a.height,a.focus.y+a.nodeSpacing/2+b);default:return b}}
function Ct(a,b,c){switch(a){case yt:b/=2;c/=2;break;case zt:b/=2;c/=2;break;case pt:c=b=0;break;case jt:break;default:A("Unhandled alignment value "+a.toString())}return new H(b,c)}function tt(a,b,c,d,e,f){b=Ct(b,c,d);wt(a,b.x,b.y,e,f)}function wt(a,b,c,d,e){if(0!==b||0!==c)for(a=a.children;d<=e;d++){var f=a[d].T;f.x+=b;f.y+=c}}
function ut(a,b,c,d){var e=b.parent;switch(a.Zc){case As:for(a=b.sourceEdges;a.next();)b=a.value,b.fromVertex===e&&b.relativePoint.h(c,d);break;case Hs:for(a=b.destinationEdges;a.next();)b=a.value,b.toVertex===e&&b.relativePoint.h(c,d);break;default:A("Unhandled path value "+a.Zc.toString())}}function xt(a,b,c){for(var d=0;d<a.length;d++){var e=a[d];e.x+=b;e.y+=c}}
function vt(a,b,c,d,e,f,g,h){var k=it(b),l=90===k||270===k,m=b.nodeSpacing;b=d;var n=e;d=f;var p=g,q=c.fq,r=c.rq;g=c.ua;var u=l?Math.max(p,g.height):Math.max(d,g.width);if(null===q||k!==it(c))q=st(a,2),r=st(a,2),l?(q[0].h(0,0),q[1].h(0,g.height),r[0].h(g.width,0),r[1].h(r[0].x,q[1].y)):(q[0].h(0,0),q[1].h(g.width,0),r[0].h(0,g.height),r[1].h(q[1].x,r[0].y));if(l){p=9999999;if(!(null===n||2>n.length||null===q||2>q.length))for(e=c=0;c<n.length&&e<q.length;){f=n[c];var x=q[e];k=x.x;l=x.y;k+=d;var v=
f;c+1<n.length&&(v=n[c+1]);var y=x;x=y.x;y=y.y;e+1<q.length&&(y=q[e+1],x=y.x,y=y.y,x+=d);var z=p;f.y===l?z=k-f.x:f.y>l&&f.y<y?z=k+(f.y-l)/(y-l)*(x-k)-f.x:l>f.y&&l<v.y&&(z=k-(f.x+(l-f.y)/(v.y-f.y)*(v.x-f.x)));z<p&&(p=z);v.y<=f.y?c++:y<=l?e++:(v.y<=y&&c++,y<=v.y&&e++)}p=d-p;p+=m;c=q;e=p;if(null===b||2>b.length||null===c||2>c.length)d=null;else{m=st(a,b.length+c.length);for(d=f=k=0;f<c.length&&c[f].y<b[0].y;)l=c[f++],m[d++].h(l.x+e,l.y);for(;k<b.length;)l=b[k++],m[d++].h(l.x,l.y);for(k=b[b.length-1].y;f<
c.length&&c[f].y<=k;)f++;for(;f<c.length&&c[f].y>k;)l=c[f++],m[d++].h(l.x+e,l.y);c=st(a,d);for(k=0;k<d;k++)c[k].assign(m[k]);Dt(a,m);d=c}f=r;k=p;if(null===n||2>n.length||null===f||2>f.length)e=null;else{m=st(a,n.length+f.length);for(e=l=c=0;c<n.length&&n[c].y<f[0].y;)v=n[c++],m[e++].h(v.x,v.y);for(;l<f.length;)v=f[l++],m[e++].h(v.x+k,v.y);for(f=f[f.length-1].y;c<n.length&&n[c].y<=f;)c++;for(;c<n.length&&n[c].y>f;)k=n[c++],m[e++].h(k.x,k.y);f=st(a,e);for(c=0;c<e;c++)f[c].assign(m[c]);Dt(a,m);e=f}f=
Math.max(0,p)+g.width;g=u;Dt(a,b);Dt(a,q);Dt(a,n);Dt(a,r);h[0]=d;h[1]=e;return new N(p,0,f,g)}d=9999999;if(!(null===n||2>n.length||null===q||2>q.length))for(e=c=0;c<n.length&&e<q.length;)f=n[c],x=q[e],k=x.x,l=x.y,l+=p,v=f,c+1<n.length&&(v=n[c+1]),y=x,x=y.x,y=y.y,e+1<q.length&&(y=q[e+1],x=y.x,y=y.y,y+=p),z=d,f.x===k?z=l-f.y:f.x>k&&f.x<x?z=l+(f.x-k)/(x-k)*(y-l)-f.y:k>f.x&&k<v.x&&(z=l-(f.y+(k-f.x)/(v.x-f.x)*(v.y-f.y))),z<d&&(d=z),v.x<=f.x?c++:x<=k?e++:(v.x<=x&&c++,x<=v.x&&e++);p-=d;p+=m;c=q;e=p;if(null===
b||2>b.length||null===c||2>c.length)d=null;else{m=st(a,b.length+c.length);for(d=f=k=0;f<c.length&&c[f].x<b[0].x;)l=c[f++],m[d++].h(l.x,l.y+e);for(;k<b.length;)l=b[k++],m[d++].h(l.x,l.y);for(k=b[b.length-1].x;f<c.length&&c[f].x<=k;)f++;for(;f<c.length&&c[f].x>k;)l=c[f++],m[d++].h(l.x,l.y+e);c=st(a,d);for(k=0;k<d;k++)c[k].assign(m[k]);Dt(a,m);d=c}f=r;k=p;if(null===n||2>n.length||null===f||2>f.length)e=null;else{m=st(a,n.length+f.length);for(e=l=c=0;c<n.length&&n[c].x<f[0].x;)v=n[c++],m[e++].h(v.x,v.y);
for(;l<f.length;)v=f[l++],m[e++].h(v.x,v.y+k);for(f=f[f.length-1].x;c<n.length&&n[c].x<=f;)c++;for(;c<n.length&&n[c].x>f;)k=n[c++],m[e++].h(k.x,k.y);f=st(a,e);for(c=0;c<e;c++)f[c].assign(m[c]);Dt(a,m);e=f}f=u;g=Math.max(0,p)+g.height;Dt(a,b);Dt(a,q);Dt(a,n);Dt(a,r);h[0]=d;h[1]=e;return new N(p,0,f,g)}function st(a,b){a=a.wu[b];if(void 0!==a&&(a=a.pop(),void 0!==a))return a;a=[];for(var c=0;c<b;c++)a[c]=new H;return a}
function Dt(a,b){var c=b.length,d=a.wu[c];void 0===d&&(d=[],a.wu[c]=d);d.push(b)}
ys.prototype.arrangeTrees=function(){if(this.Ab===Gs)for(var a=this.Fb.iterator;a.next();){var b=a.value;if(b instanceof Fs){var c=b.node;if(null!==c){var d=c.position;c=d.x;d=d.y;isFinite(c)||(c=0);isFinite(d)||(d=0);Gt(this,b,c,d)}}}else{a=[];for(b=this.Fb.iterator;b.next();)c=b.value,c instanceof Fs&&a.push(c);switch(this.sorting){case Ws:break;case Xs:a.reverse();break;case Ys:a.sort(this.comparer);break;case Zs:a.sort(this.comparer);a.reverse();break;default:A("Unhandled sorting value "+this.sorting.toString())}c=
this.arrangementOrigin;b=c.x;c=c.y;for(d=0;d<a.length;d++){var e=a[d];Gt(this,e,b+e.ia.x,c+e.ia.y);switch(this.Ab){case Ds:c+=e.ua.height+this.wd.height;break;case Ht:b+=e.ua.width+this.wd.width;break;default:A("Unhandled arrangement value "+this.Ab.toString())}}}};function Gt(a,b,c,d){if(null!==b){b.x=c;b.y=d;b=b.children;for(var e=b.length,f=0;f<e;f++){var g=b[f];Gt(a,g,c+g.T.x,d+g.T.y)}}}ys.prototype.commitLayout=function(){this.Jv();this.commitNodes();this.Mu();this.isRouting&&this.commitLinks()};
ys.prototype.commitNodes=function(){for(var a=this.network.vertexes.iterator;a.next();)a.value.commit();for(a.reset();a.next();)this.layoutComments(a.value)};
ys.prototype.Mu=function(){if(this.layerStyle===Os){for(var a=this.gw,b=[],c=null,d=this.network.vertexes.iterator;d.next();){var e=d.value;null===c?c=e.bounds.copy():c.Jc(e.bounds);var f=b[e.level];void 0===f?f=kt(e):f=Math.max(f,kt(e));b[e.level]=f}for(d=0;d<b.length;d++)void 0===b[d]&&(b[d]=0);90===this.angle||270===this.angle?(c.Uc(this.nodeSpacing/2,this.layerSpacing),d=new H(-this.nodeSpacing/2,-this.layerSpacing/2)):(c.Uc(this.layerSpacing,this.nodeSpacing/2),d=new H(-this.layerSpacing/2,-this.nodeSpacing/
2));e=[];c=90===this.angle||270===this.angle?c.width:c.height;f=0;if(180===this.angle||270===this.angle)for(var g=0;g<a.length;g++)f+=a[g]+b[g];for(g=0;g<a.length;g++){var h=a[g]+b[g];270===this.angle?(f-=h,e.push(new N(0,f,c,h))):90===this.angle?(e.push(new N(0,f,c,h)),f+=h):180===this.angle?(f-=h,e.push(new N(f,0,h,c))):(e.push(new N(f,0,h,c)),f+=h)}this.commitLayers(e,d)}};ys.prototype.commitLayers=function(){};ys.prototype.commitLinks=function(){for(var a=this.network.edges.iterator;a.next();)a.value.commit()};
ys.prototype.Jv=function(){for(var a=this.Fb.iterator;a.next();){var b=a.value;b instanceof Fs&&It(this,b)}};function It(a,b){if(null!==b){a.setPortSpots(b);b=b.children;for(var c=b.length,d=0;d<c;d++)It(a,b[d])}}
ys.prototype.setPortSpots=function(a){var b=a.alignment;if($s(b)){var c=this.Zc===As,d=it(a);switch(d){case 0:var e=Sd;break;case 90:e=Td;break;case 180:e=Kd;break;default:e=Jd}var f=a.children,g=f.length;switch(b){case at:case Rs:for(b=0;b<g;b++){var h=f[b];h=(c?h.sourceEdges:h.destinationEdges).first();if(null!==h&&(h=h.link,null!==h)){var k=90===d||270===d?Kd:Jd;if(1===g||b===g-1&&1===g%2)switch(d){case 0:k=Kd;break;case 90:k=Jd;break;case 180:k=Sd;break;default:k=Td}else 0===b%2&&(k=90===d||270===
d?Sd:Td);c?(a.setsPortSpot&&(h.fromSpot=e),a.setsChildPortSpot&&(h.toSpot=k)):(a.setsPortSpot&&(h.fromSpot=k),a.setsChildPortSpot&&(h.toSpot=e))}}break;case bt:d=90===d||270===d?Sd:Td;for(f=c?a.destinationEdges:a.sourceEdges;f.next();)g=f.value.link,null!==g&&(c?(a.setsPortSpot&&(g.fromSpot=e),a.setsChildPortSpot&&(g.toSpot=d)):(a.setsPortSpot&&(g.fromSpot=d),a.setsChildPortSpot&&(g.toSpot=e)));break;case ct:for(d=90===d||270===d?Kd:Jd,f=c?a.destinationEdges:a.sourceEdges;f.next();)g=f.value.link,
null!==g&&(c?(a.setsPortSpot&&(g.fromSpot=e),a.setsChildPortSpot&&(g.toSpot=d)):(a.setsPortSpot&&(g.fromSpot=d),a.setsChildPortSpot&&(g.toSpot=e)))}}else if(c=it(a),this.Zc===As)for(e=a.destinationEdges;e.next();){if(d=e.value.link,null!==d){if(a.setsPortSpot)if(a.portSpot.Kb())switch(c){case 0:d.fromSpot=Sd;break;case 90:d.fromSpot=Td;break;case 180:d.fromSpot=Kd;break;default:d.fromSpot=Jd}else d.fromSpot=a.portSpot;if(a.setsChildPortSpot)if(a.childPortSpot.Kb())switch(c){case 0:d.toSpot=Kd;break;
case 90:d.toSpot=Jd;break;case 180:d.toSpot=Sd;break;default:d.toSpot=Td}else d.toSpot=a.childPortSpot}}else for(e=a.sourceEdges;e.next();)if(d=e.value.link,null!==d){if(a.setsPortSpot)if(a.portSpot.Kb())switch(c){case 0:d.toSpot=Sd;break;case 90:d.toSpot=Td;break;case 180:d.toSpot=Kd;break;default:d.toSpot=Jd}else d.toSpot=a.portSpot;if(a.setsChildPortSpot)if(a.childPortSpot.Kb())switch(c){case 0:d.fromSpot=Kd;break;case 90:d.fromSpot=Jd;break;case 180:d.fromSpot=Sd;break;default:d.fromSpot=Td}else d.fromSpot=
a.childPortSpot}};function it(a){a=a.angle;return 45>=a?0:135>=a?90:225>=a?180:315>=a?270:0}function kt(a){var b=it(a);b=90===b||270===b;var c=a.layerSpacing;if(0<a.layerSpacingParentOverlap){var d=Math.min(1,a.layerSpacingParentOverlap);c-=b?a.height*d:a.width*d}c<(b?-a.height:-a.width)&&(c=b?-a.height:-a.width);return c}function lt(a){var b=it(a),c=a.nodeIndent;if(0<a.nodeIndentPastParent){var d=Math.min(1,a.nodeIndentPastParent);c+=90===b||270===b?a.width*d:a.height*d}return c=Math.max(0,c)}
pa.Object.defineProperties(ys.prototype,{roots:{get:function(){return this.Fb},set:function(a){this.Fb!==a&&(this.Fb=a,this.B())}},path:{get:function(){return this.Oo},set:function(a){this.Oo!==a&&(this.Oo=a,this.B())}},treeStyle:{get:function(){return this.Fp},set:function(a){this.Ab===a||a!==Bs&&a!==Us&&a!==Vs&&a!==Ts||(this.Fp=a,this.B())}},layerStyle:{get:function(){return this.Nr},
set:function(a){this.Ab===a||a!==Cs&&a!==Ps&&a!==Os||(this.Nr=a,this.B())}},comments:{get:function(){return this.Xc},set:function(a){this.Xc!==a&&(this.Xc=a,this.B())}},arrangement:{get:function(){return this.Ab},set:function(a){this.Ab===a||a!==Ds&&a!==Ht&&a!==Gs||(this.Ab=a,this.B())}},arrangementSpacing:{get:function(){return this.wd},set:function(a){this.wd.A(a)||(this.wd.assign(a),this.B())}},rootDefaults:{
get:function(){return this.U},set:function(a){this.U!==a&&(this.U=a,this.B())}},alternateDefaults:{get:function(){return this.V},set:function(a){this.V!==a&&(this.V=a,this.B())}},sorting:{get:function(){return this.U.sorting},set:function(a){this.U.sorting===a||a!==Ws&&a!==Xs&&a!==Ys&&!Zs||(this.U.sorting=a,this.B())}},comparer:{get:function(){return this.U.comparer},set:function(a){this.U.comparer!==
a&&(this.U.comparer=a,this.B())}},angle:{get:function(){return this.U.angle},set:function(a){this.U.angle!==a&&(0===a||90===a||180===a||270===a?(this.U.angle=a,this.B()):A("TreeLayout.angle must be 0, 90, 180, or 270"))}},alignment:{get:function(){return this.U.alignment},set:function(a){this.U.alignment!==a&&(this.U.alignment=a,this.B())}},nodeIndent:{get:function(){return this.U.nodeIndent},set:function(a){this.U.nodeIndent!==
a&&0<=a&&(this.U.nodeIndent=a,this.B())}},nodeIndentPastParent:{get:function(){return this.U.nodeIndentPastParent},set:function(a){this.U.nodeIndentPastParent!==a&&0<=a&&1>=a&&(this.U.nodeIndentPastParent=a,this.B())}},nodeSpacing:{get:function(){return this.U.nodeSpacing},set:function(a){this.U.nodeSpacing!==a&&(this.U.nodeSpacing=a,this.B())}},layerSpacing:{get:function(){return this.U.layerSpacing},set:function(a){this.U.layerSpacing!==
a&&(this.U.layerSpacing=a,this.B())}},layerSpacingParentOverlap:{get:function(){return this.U.layerSpacingParentOverlap},set:function(a){this.U.layerSpacingParentOverlap!==a&&0<=a&&1>=a&&(this.U.layerSpacingParentOverlap=a,this.B())}},compaction:{get:function(){return this.U.compaction},set:function(a){this.U.compaction===a||a!==ft&&a!==ht||(this.U.compaction=a,this.B())}},breadthLimit:{get:function(){return this.U.breadthLimit},
set:function(a){this.U.breadthLimit!==a&&0<=a&&(this.U.breadthLimit=a,this.B())}},rowSpacing:{get:function(){return this.U.rowSpacing},set:function(a){this.U.rowSpacing!==a&&(this.U.rowSpacing=a,this.B())}},rowIndent:{get:function(){return this.U.rowIndent},set:function(a){this.U.rowIndent!==a&&0<=a&&(this.U.rowIndent=a,this.B())}},commentSpacing:{get:function(){return this.U.commentSpacing},set:function(a){this.U.commentSpacing!==
a&&(this.U.commentSpacing=a,this.B())}},commentMargin:{get:function(){return this.U.commentMargin},set:function(a){this.U.commentMargin!==a&&(this.U.commentMargin=a,this.B())}},setsPortSpot:{get:function(){return this.U.setsPortSpot},set:function(a){this.U.setsPortSpot!==a&&(this.U.setsPortSpot=a,this.B())}},portSpot:{get:function(){return this.U.portSpot},set:function(a){this.U.portSpot.A(a)||(this.U.portSpot=
a,this.B())}},setsChildPortSpot:{get:function(){return this.U.setsChildPortSpot},set:function(a){this.U.setsChildPortSpot!==a&&(this.U.setsChildPortSpot=a,this.B())}},childPortSpot:{get:function(){return this.U.childPortSpot},set:function(a){this.U.childPortSpot.A(a)||(this.U.childPortSpot=a,this.B())}},alternateSorting:{get:function(){return this.V.sorting},set:function(a){this.V.sorting===a||a!==Ws&&a!==Xs&&
a!==Ys&&!Zs||(this.V.sorting=a,this.B())}},alternateComparer:{get:function(){return this.V.comparer},set:function(a){this.V.comparer!==a&&(this.V.comparer=a,this.B())}},alternateAngle:{get:function(){return this.V.angle},set:function(a){this.V.angle===a||0!==a&&90!==a&&180!==a&&270!==a||(this.V.angle=a,this.B())}},alternateAlignment:{get:function(){return this.V.alignment},set:function(a){this.V.alignment!==
a&&(this.V.alignment=a,this.B())}},alternateNodeIndent:{get:function(){return this.V.nodeIndent},set:function(a){this.V.nodeIndent!==a&&0<=a&&(this.V.nodeIndent=a,this.B())}},alternateNodeIndentPastParent:{get:function(){return this.V.nodeIndentPastParent},set:function(a){this.V.nodeIndentPastParent!==a&&0<=a&&1>=a&&(this.V.nodeIndentPastParent=a,this.B())}},alternateNodeSpacing:{get:function(){return this.V.nodeSpacing},
set:function(a){this.V.nodeSpacing!==a&&(this.V.nodeSpacing=a,this.B())}},alternateLayerSpacing:{get:function(){return this.V.layerSpacing},set:function(a){this.V.layerSpacing!==a&&(this.V.layerSpacing=a,this.B())}},alternateLayerSpacingParentOverlap:{get:function(){return this.V.layerSpacingParentOverlap},set:function(a){this.V.layerSpacingParentOverlap!==a&&0<=a&&1>=a&&(this.V.layerSpacingParentOverlap=a,this.B())}},alternateCompaction:{
get:function(){return this.V.compaction},set:function(a){this.V.compaction===a||a!==ft&&a!==ht||(this.V.compaction=a,this.B())}},alternateBreadthLimit:{get:function(){return this.V.breadthLimit},set:function(a){this.V.breadthLimit!==a&&0<=a&&(this.V.breadthLimit=a,this.B())}},alternateRowSpacing:{get:function(){return this.V.rowSpacing},set:function(a){this.V.rowSpacing!==a&&(this.V.rowSpacing=a,this.B())}},alternateRowIndent:{
get:function(){return this.V.rowIndent},set:function(a){this.V.rowIndent!==a&&0<=a&&(this.V.rowIndent=a,this.B())}},alternateCommentSpacing:{get:function(){return this.V.commentSpacing},set:function(a){this.V.commentSpacing!==a&&(this.V.commentSpacing=a,this.B())}},alternateCommentMargin:{get:function(){return this.V.commentMargin},set:function(a){this.V.commentMargin!==a&&(this.V.commentMargin=a,this.B())}},alternateSetsPortSpot:{
get:function(){return this.V.setsPortSpot},set:function(a){this.V.setsPortSpot!==a&&(this.V.setsPortSpot=a,this.B())}},alternatePortSpot:{get:function(){return this.V.portSpot},set:function(a){this.V.portSpot.A(a)||(this.V.portSpot=a,this.B())}},alternateSetsChildPortSpot:{get:function(){return this.V.setsChildPortSpot},set:function(a){this.V.setsChildPortSpot!==a&&(this.V.setsChildPortSpot=a,this.B())}},alternateChildPortSpot:{
get:function(){return this.V.childPortSpot},set:function(a){this.V.childPortSpot.A(a)||(this.V.childPortSpot=a,this.B())}}});
var zs=new D(ys,"PathDefault",-1),As=new D(ys,"PathDestination",0),Hs=new D(ys,"PathSource",1),Ws=new D(ys,"SortingForwards",10),Xs=new D(ys,"SortingReverse",11),Ys=new D(ys,"SortingAscending",12),Zs=new D(ys,"SortingDescending",13),yt=new D(ys,"AlignmentCenterSubtrees",20),zt=new D(ys,"AlignmentCenterChildren",21),pt=new D(ys,"AlignmentStart",22),jt=new D(ys,"AlignmentEnd",23),at=new D(ys,"AlignmentBus",24),Rs=new D(ys,"AlignmentBusBranching",25),bt=new D(ys,"AlignmentTopLeftBus",26),ct=new D(ys,
"AlignmentBottomRightBus",27),ft=new D(ys,"CompactionNone",30),ht=new D(ys,"CompactionBlock",31),Bs=new D(ys,"StyleLayered",40),Vs=new D(ys,"StyleLastParents",41),Us=new D(ys,"StyleAlternating",42),Ts=new D(ys,"StyleRootOnly",43),Ds=new D(ys,"ArrangementVertical",50),Ht=new D(ys,"ArrangementHorizontal",51),Gs=new D(ys,"ArrangementFixedRoots",52),Cs=new D(ys,"LayerIndividual",60),Ps=new D(ys,"LayerSiblings",61),Os=new D(ys,"LayerUniform",62);ys.className="TreeLayout";ys.PathDefault=zs;
ys.PathDestination=As;ys.PathSource=Hs;ys.SortingForwards=Ws;ys.SortingReverse=Xs;ys.SortingAscending=Ys;ys.SortingDescending=Zs;ys.AlignmentCenterSubtrees=yt;ys.AlignmentCenterChildren=zt;ys.AlignmentStart=pt;ys.AlignmentEnd=jt;ys.AlignmentBus=at;ys.AlignmentBusBranching=Rs;ys.AlignmentTopLeftBus=bt;ys.AlignmentBottomRightBus=ct;ys.CompactionNone=ft;ys.CompactionBlock=ht;ys.StyleLayered=Bs;ys.StyleLastParents=Vs;ys.StyleAlternating=Us;ys.StyleRootOnly=Ts;ys.ArrangementVertical=Ds;
ys.ArrangementHorizontal=Ht;ys.ArrangementFixedRoots=Gs;ys.LayerIndividual=Cs;ys.LayerSiblings=Ps;ys.LayerUniform=Os;function Es(a){xp.call(this,a)}oa(Es,xp);Es.prototype.createVertex=function(){return new Fs(this)};Es.prototype.createEdge=function(){return new Jt(this)};Es.className="TreeNetwork";
function Fs(a){Ap.call(this,a);this.La=!1;this.Kc=null;this.K=[];this.Vb=this.Ub=this.ba=this.Ma=0;this.Xc=null;this.T=new H(0,0);this.ua=new L(0,0);this.ia=new H(0,0);this.um=this.tm=this.Nz=!1;this.rq=this.fq=null;this.Qc=Ws;this.Mc=Gp;this.zc=0;this.wb=zt;this.Yr=this.Xr=0;this.$r=20;this.je=50;this.Mr=0;this.Uq=ht;this.Nq=0;this.ms=25;this.Tq=this.ls=10;this.Sq=20;this.vs=!0;this.hs=Ed;this.us=!0;this.Qq=Ed}oa(Fs,Ap);
Fs.prototype.copyInheritedPropertiesFrom=function(a){null!==a&&(this.Qc=a.sorting,this.Mc=a.comparer,this.zc=a.angle,this.wb=a.alignment,this.Xr=a.nodeIndent,this.Yr=a.nodeIndentPastParent,this.$r=a.nodeSpacing,this.je=a.layerSpacing,this.Mr=a.layerSpacingParentOverlap,this.Uq=a.compaction,this.Nq=a.breadthLimit,this.ms=a.rowSpacing,this.ls=a.rowIndent,this.Tq=a.commentSpacing,this.Sq=a.commentMargin,this.vs=a.setsPortSpot,this.hs=a.portSpot,this.us=a.setsChildPortSpot,this.Qq=a.childPortSpot)};
pa.Object.defineProperties(Fs.prototype,{initialized:{get:function(){return this.La},set:function(a){this.La!==a&&(this.La=a)}},parent:{get:function(){return this.Kc},set:function(a){this.Kc!==a&&(this.Kc=a)}},children:{get:function(){return this.K},set:function(a){if(this.K!==a){if(null!==a)for(var b=a.length,c=0;c<b;c++);this.K=a}}},level:{get:function(){return this.Ma},set:function(a){this.Ma!==
a&&(this.Ma=a)}},descendantCount:{get:function(){return this.ba},set:function(a){this.ba!==a&&(this.ba=a)}},maxChildrenCount:{get:function(){return this.Ub},set:function(a){this.Ub!==a&&(this.Ub=a)}},maxGenerationCount:{get:function(){return this.Vb},set:function(a){this.Vb!==a&&(this.Vb=a)}},comments:{get:function(){return this.Xc},set:function(a){if(this.Xc!==a){if(null!==a)for(var b=
a.length,c=0;c<b;c++);this.Xc=a}}},sorting:{get:function(){return this.Qc},set:function(a){this.Qc!==a&&(this.Qc=a)}},comparer:{get:function(){return this.Mc},set:function(a){this.Mc!==a&&(this.Mc=a)}},angle:{get:function(){return this.zc},set:function(a){this.zc!==a&&(this.zc=a)}},alignment:{get:function(){return this.wb},set:function(a){this.wb!==a&&(this.wb=a)}},nodeIndent:{
get:function(){return this.Xr},set:function(a){this.Xr!==a&&(this.Xr=a)}},nodeIndentPastParent:{get:function(){return this.Yr},set:function(a){this.Yr!==a&&(this.Yr=a)}},nodeSpacing:{get:function(){return this.$r},set:function(a){this.$r!==a&&(this.$r=a)}},layerSpacing:{get:function(){return this.je},set:function(a){this.je!==a&&(this.je=a)}},layerSpacingParentOverlap:{
get:function(){return this.Mr},set:function(a){this.Mr!==a&&(this.Mr=a)}},compaction:{get:function(){return this.Uq},set:function(a){this.Uq!==a&&(this.Uq=a)}},breadthLimit:{get:function(){return this.Nq},set:function(a){this.Nq!==a&&(this.Nq=a)}},rowSpacing:{get:function(){return this.ms},set:function(a){this.ms!==a&&(this.ms=a)}},rowIndent:{get:function(){return this.ls},set:function(a){this.ls!==
a&&(this.ls=a)}},commentSpacing:{get:function(){return this.Tq},set:function(a){this.Tq!==a&&(this.Tq=a)}},commentMargin:{get:function(){return this.Sq},set:function(a){this.Sq!==a&&(this.Sq=a)}},setsPortSpot:{get:function(){return this.vs},set:function(a){this.vs!==a&&(this.vs=a)}},portSpot:{get:function(){return this.hs},set:function(a){this.hs.A(a)||(this.hs=a)}},setsChildPortSpot:{
get:function(){return this.us},set:function(a){this.us!==a&&(this.us=a)}},childPortSpot:{get:function(){return this.Qq},set:function(a){this.Qq.A(a)||(this.Qq=a)}},childrenCount:{get:function(){return this.children.length}},relativePosition:{get:function(){return this.T},set:function(a){this.T.set(a)}},subtreeSize:{get:function(){return this.ua},set:function(a){this.ua.set(a)}},
subtreeOffset:{get:function(){return this.ia},set:function(a){this.ia.set(a)}}});Fs.className="TreeVertex";function Jt(a){Bp.call(this,a);this.nu=new H(0,0)}oa(Jt,Bp);
Jt.prototype.commit=function(){var a=this.link;if(null!==a&&!a.isAvoiding){var b=this.network.layout,c=null,d=null;switch(b.Zc){case As:c=this.fromVertex;d=this.toVertex;break;case Hs:c=this.toVertex;d=this.fromVertex;break;default:A("Unhandled path value "+b.Zc.toString())}if(null!==c&&null!==d)if(b=this.nu,0!==b.x||0!==b.y||c.Nz){d=c.bounds;var e=it(c),f=kt(c),g=c.rowSpacing;a.$i();var h=a.curve===Rg,k=a.isOrthogonal,l;a.Ch();if(k||h){for(l=2;4<a.pointsCount;)a.zv(2);var m=a.i(1);var n=a.i(2)}else{for(l=
1;3<a.pointsCount;)a.zv(1);m=a.i(0);n=a.i(a.pointsCount-1)}var p=a.i(a.pointsCount-1);0===e?(c.alignment===jt?(e=d.bottom+b.y,0===b.y&&m.y>p.y+c.rowIndent&&(e=Math.min(e,Math.max(m.y,e-lt(c))))):c.alignment===pt?(e=d.top+b.y,0===b.y&&m.y<p.y-c.rowIndent&&(e=Math.max(e,Math.min(m.y,e+lt(c))))):e=c.tm||c.um&&1===c.maxGenerationCount?d.top-c.ia.y+b.y:d.y+d.height/2+b.y,h?(a.m(l,m.x,e),l++,a.m(l,d.right+f,e),l++,a.m(l,d.right+f+(b.x-g)/3,e),l++,a.m(l,d.right+f+2*(b.x-g)/3,e),l++,a.m(l,d.right+f+(b.x-
g),e),l++,a.m(l,n.x,e)):(k&&(a.m(l,d.right+f/2,m.y),l++),a.m(l,d.right+f/2,e),l++,a.m(l,d.right+f+b.x-(k?g/2:g),e),l++,k&&a.m(l,a.i(l-1).x,n.y))):90===e?(c.alignment===jt?(e=d.right+b.x,0===b.x&&m.x>p.x+c.rowIndent&&(e=Math.min(e,Math.max(m.x,e-lt(c))))):c.alignment===pt?(e=d.left+b.x,0===b.x&&m.x<p.x-c.rowIndent&&(e=Math.max(e,Math.min(m.x,e+lt(c))))):e=c.tm||c.um&&1===c.maxGenerationCount?d.left-c.ia.x+b.x:d.x+d.width/2+b.x,h?(a.m(l,e,m.y),l++,a.m(l,e,d.bottom+f),l++,a.m(l,e,d.bottom+f+(b.y-g)/
3),l++,a.m(l,e,d.bottom+f+2*(b.y-g)/3),l++,a.m(l,e,d.bottom+f+(b.y-g)),l++,a.m(l,e,n.y)):(k&&(a.m(l,m.x,d.bottom+f/2),l++),a.m(l,e,d.bottom+f/2),l++,a.m(l,e,d.bottom+f+b.y-(k?g/2:g)),l++,k&&a.m(l,n.x,a.i(l-1).y))):180===e?(c.alignment===jt?(e=d.bottom+b.y,0===b.y&&m.y>p.y+c.rowIndent&&(e=Math.min(e,Math.max(m.y,e-lt(c))))):c.alignment===pt?(e=d.top+b.y,0===b.y&&m.y<p.y-c.rowIndent&&(e=Math.max(e,Math.min(m.y,e+lt(c))))):e=c.tm||c.um&&1===c.maxGenerationCount?d.top-c.ia.y+b.y:d.y+d.height/2+b.y,h?
(a.m(l,m.x,e),l++,a.m(l,d.left-f,e),l++,a.m(l,d.left-f+(b.x+g)/3,e),l++,a.m(l,d.left-f+2*(b.x+g)/3,e),l++,a.m(l,d.left-f+(b.x+g),e),l++,a.m(l,n.x,e)):(k&&(a.m(l,d.left-f/2,m.y),l++),a.m(l,d.left-f/2,e),l++,a.m(l,d.left-f+b.x+(k?g/2:g),e),l++,k&&a.m(l,a.i(l-1).x,n.y))):270===e?(c.alignment===jt?(e=d.right+b.x,0===b.x&&m.x>p.x+c.rowIndent&&(e=Math.min(e,Math.max(m.x,e-lt(c))))):c.alignment===pt?(e=d.left+b.x,0===b.x&&m.x<p.x-c.rowIndent&&(e=Math.max(e,Math.min(m.x,e+lt(c))))):e=c.tm||c.um&&1===c.maxGenerationCount?
d.left-c.ia.x+b.x:d.x+d.width/2+b.x,h?(a.m(l,e,m.y),l++,a.m(l,e,d.top-f),l++,a.m(l,e,d.top-f+(b.y+g)/3),l++,a.m(l,e,d.top-f+2*(b.y+g)/3),l++,a.m(l,e,d.top-f+(b.y+g)),l++,a.m(l,e,n.y)):(k&&(a.m(l,m.x,d.top-f/2),l++),a.m(l,e,d.top-f/2),l++,a.m(l,e,d.top-f+b.y+(k?g/2:g)),l++,k&&a.m(l,n.x,a.i(l-1).y))):A("Invalid angle "+e);a.lf()}else a=this.link,f=it(c),f!==it(d)&&(g=kt(c),h=c.bounds,c=d.bounds,0===f&&c.left-h.right<g+1||90===f&&c.top-h.bottom<g+1||180===f&&h.left-c.right<g+1||270===f&&h.top-c.bottom<
g+1||(a.$i(),c=a.curve===Rg,b=a.isOrthogonal,d=$s(this.fromVertex.alignment),a.Ch(),0===f?(f=h.right+g/2,c?4===a.pointsCount&&(c=a.i(3).y,a.M(1,f-20,a.i(1).y),a.m(2,f-20,c),a.m(3,f,c),a.m(4,f+20,c),a.M(5,a.i(5).x,c)):b?d?a.M(3,a.i(2).x,a.i(4).y):6===a.pointsCount&&(a.M(2,f,a.i(2).y),a.M(3,f,a.i(3).y)):4===a.pointsCount?a.m(2,f,a.i(2).y):3===a.pointsCount?a.M(1,f,a.i(2).y):2===a.pointsCount&&a.m(1,f,a.i(1).y)):90===f?(f=h.bottom+g/2,c?4===a.pointsCount&&(c=a.i(3).x,a.M(1,a.i(1).x,f-20),a.m(2,c,f-20),
a.m(3,c,f),a.m(4,c,f+20),a.M(5,c,a.i(5).y)):b?d?a.M(3,a.i(2).x,a.i(4).y):6===a.pointsCount&&(a.M(2,a.i(2).x,f),a.M(3,a.i(3).x,f)):4===a.pointsCount?a.m(2,a.i(2).x,f):3===a.pointsCount?a.M(1,a.i(2).x,f):2===a.pointsCount&&a.m(1,a.i(1).x,f)):180===f?(f=h.left-g/2,c?4===a.pointsCount&&(c=a.i(3).y,a.M(1,f+20,a.i(1).y),a.m(2,f+20,c),a.m(3,f,c),a.m(4,f-20,c),a.M(5,a.i(5).x,c)):b?d?a.M(3,a.i(2).x,a.i(4).y):6===a.pointsCount&&(a.M(2,f,a.i(2).y),a.M(3,f,a.i(3).y)):4===a.pointsCount?a.m(2,f,a.i(2).y):3===a.pointsCount?
a.M(1,f,a.i(2).y):2===a.pointsCount&&a.m(1,f,a.i(1).y)):270===f&&(f=h.top-g/2,c?4===a.pointsCount&&(c=a.i(3).x,a.M(1,a.i(1).x,f+20),a.m(2,c,f+20),a.m(3,c,f),a.m(4,c,f-20),a.M(5,c,a.i(5).y)):b?d?a.M(3,a.i(2).x,a.i(4).y):6===a.pointsCount&&(a.M(2,a.i(2).x,f),a.M(3,a.i(3).x,f)):4===a.pointsCount?a.m(2,a.i(2).x,f):3===a.pointsCount?a.M(1,a.i(2).x,f):2===a.pointsCount&&a.m(1,a.i(1).x,f)),a.lf()))}};
pa.Object.defineProperties(Jt.prototype,{relativePoint:{get:function(){return this.nu},set:function(a){this.nu.set(a)}}});Jt.className="TreeEdge";
Ua.prototype.initializeStandardTools=function(){vf(this,"Action",new dh,this.mouseDownTools);vf(this,"Relinking",new Nf,this.mouseDownTools);vf(this,"LinkReshaping",new Pg,this.mouseDownTools);vf(this,"Rotating",new bh,this.mouseDownTools);vf(this,"Resizing",new Wg,this.mouseDownTools);vf(this,"Linking",new Ig,this.mouseMoveTools);vf(this,"Dragging",new xf,this.mouseMoveTools);vf(this,"DragSelecting",new gh,this.mouseMoveTools);vf(this,"Panning",new hh,this.mouseMoveTools);vf(this,"ContextMenu",new jh,
this.mouseUpTools);vf(this,"TextEditing",new uh,this.mouseUpTools);vf(this,"ClickCreating",new eh,this.mouseUpTools);vf(this,"ClickSelecting",new ch,this.mouseUpTools)};fn("Horizontal",new nm);fn("Spot",new pm);fn("Table",new tm);fn("Viewbox",new ym);fn("TableRow",new wm);fn("TableColumn",new xm);fn("Graduated",new Hm);si.add(Dq.type,Zp);si.add(Gq.type,mq);
var Kt=w.go,Lt={get licenseKey(){return P.licenseKey},set licenseKey(a){P.licenseKey=a},get version(){return P.version},Group:ig,EnumValue:D,List:E,Set:F,Map:Lb,Point:H,Size:L,Rect:N,Margin:Ic,Spot:O,Geometry:$d,PathFigure:Ue,PathSegment:Ve,InputEvent:Xe,DiagramEvent:Ze,ChangedEvent:$e,Model:Z,GraphLinksModel:Dq,TreeModel:Gq,Binding:Ei,Transaction:ef,UndoManager:ff,CommandHandler:Gk,Tool:kf,DraggingTool:xf,DraggingInfo:Of,LinkingBaseTool:rg,LinkingTool:Ig,RelinkingTool:Nf,LinkReshapingTool:Pg,ResizingTool:Wg,
RotatingTool:bh,ClickSelectingTool:ch,ActionTool:dh,ClickCreatingTool:eh,HTMLInfo:uf,ContextMenuTool:jh,DragSelectingTool:gh,PanningTool:hh,TextEditingTool:uh,ToolManager:Ua,AnimationManager:Oh,Layer:fi,Diagram:P,Palette:Ak,Overview:Ck,Brush:il,GraphObject:Y,Panel:X,RowColumnDefinition:Pj,Shape:W,TextBlock:vh,TextBlockMetrics:Jm,Picture:Yj,Part:T,Adornment:pf,Node:V,Link:S,Placeholder:Xg,Layout:yi,LayoutNetwork:xp,LayoutVertex:Ap,LayoutEdge:Bp,GridLayout:Bk,PanelLayout:Cl,CircularLayout:Hq,CircularNetwork:Zq,
CircularVertex:lr,CircularEdge:mr,ForceDirectedLayout:nr,ForceDirectedNetwork:or,ForceDirectedVertex:Ar,ForceDirectedEdge:Br,LayeredDigraphLayout:Cr,LayeredDigraphNetwork:Hr,LayeredDigraphVertex:ws,LayeredDigraphEdge:xs,TreeLayout:ys,TreeNetwork:Es,TreeVertex:Fs,TreeEdge:Jt};Kt&&Kt.version!==Lt.version&&A("WARNING: a `go` object on the root object is already defined with a version mismatch."+Kt.version+". Replaced with version: "+Lt.version);w.go=Lt;
("undefined"===typeof w||"undefined"===typeof w.module||"object"!==typeof w.module.exports)&&w.define&&"function"===typeof w.define&&w.define.amd&&w.define(Lt); 'undefined'!==typeof module&&'object'===typeof module.exports&&(module.exports='undefined'!==typeof global?global.go:self.go); })();
