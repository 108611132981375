var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, getTabIndex } from '@progress/kendo-react-common';
import { DrawerContext } from './context/DrawerContext';
/**
 * Represents the [KendoReact Drawer component]({% slug overview_drawer %}).
 *
 * @example
 * ```jsx
 *    const App = () => {
 *      const [stateVisible, setStateVisible] = React.useState(true);
 *      const handleClick = () => { setStateVisible(prevState => !prevState); };
 *
 *      return (
 *        <Drawer expanded={stateVisible} mode={'push'}>
 *            <DrawerNavigation>
 *                <DrawerItem text="Inbox" icon="k-i-inbox" />
 *                <DrawerItem separator={true} />
 *                <DrawerItem text="Notifications" icon="k-i-bell" disabled={true}/>
 *                <DrawerItem text="Calendar" icon="k-i-calendar"/>
 *                <DrawerItem separator={true} />
 *                <DrawerItem text="Attachments" icon="k-i-hyperlink-email" selected={true}/>
 *                <DrawerItem text="Favourites" icon="k-i-star-outline"/>
 *            </DrawerNavigation>
 *            <DrawerContent><button className="k-button" onClick={handleClick}>Toggle the drawer state</button></DrawerContent>
 *        </Drawer>
 *      );
 *    };
 *
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var DrawerItem = React.forwardRef(function (props, target) {
    var className = props.className, style = props.style, text = props.text, icon = props.icon, separator = props.separator, disabled = props.disabled, selected = props.selected, onSelect = props.onSelect, index = props.index, tabIndex = props.tabIndex, children = props.children, others = __rest(props, ["className", "style", "text", "icon", "separator", "disabled", "selected", "onSelect", "index", "tabIndex", "children"]);
    var _a = React.useContext(DrawerContext), expanded = _a.expanded, mini = _a.mini, item = _a.item;
    var elementRef = React.useRef(null);
    var focusElement = React.useCallback(function () {
        if (elementRef.current) {
            elementRef.current.focus();
        }
    }, []);
    React.useImperativeHandle(target, function () { return ({
        element: elementRef.current,
        focus: focusElement,
        props: props
    }); });
    var itemClassNames = classNames('k-drawer-item', {
        'k-selected': selected,
        'k-disabled': disabled
    }, className);
    var handleClick = React.useCallback(function (event) {
        if (onSelect && !disabled) {
            var handle = {
                element: elementRef.current,
                focus: focusElement,
                props: props
            };
            onSelect(handle, index, event);
        }
    }, [onSelect, index, disabled]);
    var drawerItemContent = (React.createElement(React.Fragment, null,
        icon && React.createElement("span", { className: 'k-icon ' + icon }),
        !expanded && mini ? null : React.createElement("span", { className: 'k-item-text' }, text)));
    return (separator
        ? React.createElement("li", { className: 'k-drawer-item k-drawer-separator', role: "presentation" })
        : (React.createElement("li", __assign({ ref: elementRef, className: itemClassNames, style: style, role: "option", "aria-selected": selected, "aria-disabled": disabled, onClick: handleClick, tabIndex: getTabIndex(tabIndex, disabled) }, others), !item ? drawerItemContent : children)));
});
DrawerItem.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    text: PropTypes.string,
    icon: PropTypes.string,
    separator: PropTypes.bool,
    selected: PropTypes.bool,
    disabled: PropTypes.bool,
    targetItem: PropTypes.any
};
DrawerItem.displayName = 'KendoDrawerItem';
