"use strict";

import React, { Fragment } from "react";
import { Radio, RadioGroup } from "react-radio-group";
import { RISK_LABEL_ACTION } from "../../../reports/constants/report_constants";
import BaseReactComponent from "../../../base_react_component";

/**
 * This class is for producing a button on a table
 */
export default class RiskTablesFilter extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      showRawScore,
      labelAction,
      onRiskValueTypeChange,
      onRiskLabelTypeChange,
      showCriticalOnlyFilter,
      OnShowCriticalOnlyChange,
      showCriticalOnlyChecked,
      reportSettings,
      id
    } = this.props;

    let showEffectLabel = reportSettings?.isPivot;
    return (
      <div className="col-sm-12 rmp-risk-table-options-container">
        <label className="col-form-label rmp-risk-table-show-label">Show: </label>
        <RadioGroup name={`${id}ScoresRadioGroup`}
                    selectedValue={showRawScore ? "showRawScore" : "showNormalizedScore"}
                    onChange={onRiskValueTypeChange}
        >
          <label id={`${id}RawValuesRadioButton`}
                 className="col-form-label"
          >
            <Radio id={`${id}RawValuesRadioButtonLabel`}
                   value="showRawScore"
            />Raw Values
          </label>
          <label id={`${id}PercentageValuesRadioButton`}
                 className="col-form-label"
          >
            <Radio id={`${id}PercentageValuesRadioButtonLabel`}
                   value="showNormalizedScore"
            />% Values
          </label>
        </RadioGroup>
        <div className={"rmp-risk-tables-radio-group-separator"} />
        <RadioGroup name={`${id}LabelsRadioGroup`}
                    selectedValue={labelAction}
                    onChange={onRiskLabelTypeChange}
        >
          <label id={`${id}RiskLabelRadioButton`}
                 className="col-form-label"
          >
            <Radio id={`${id}RiskLabelRadioButtonLabel`}
                   value={RISK_LABEL_ACTION.SHOW_RISK_LABEL}
            />Risk Label
          </label>
          <label id={`${id}ScoreLabelRadioButton`}
                 className="col-form-label"
          >
            <Radio id={`${id}ScoreLabelRadioButtonLabel`}
                   value={RISK_LABEL_ACTION.SHOW_SCORE_LABEL}
            />Score Label
          </label>
          {showEffectLabel ? (
            <label id={`${id}EffectOnAttributeRadioButton`}
                   className="col-form-label"
            >
              <Radio id={`${id}EffectOnAttributeRadioButtonLabel`}
                     value={RISK_LABEL_ACTION.SHOW_EFFECT_LABEL}
              />Effect on Attribute
            </label>
          ) : ""}
        </RadioGroup>
        {showCriticalOnlyFilter ?
          <Fragment>
            <div className={"rmp-risk-tables-radio-group-separator"} />
            <input type="checkbox"
                   id={`${id}CriticalOnlyInput`}
                   aria-label="Show Critical Only"
                   title="If enabled, all non-critical items will be filtered out"
                   onChange={OnShowCriticalOnlyChange}
                   checked={showCriticalOnlyChecked}
            />
            <label className="col-form-label rmp-risk-table-show-label"
                   id={`${id}CriticalOnlyInputLabel`}
                   htmlFor={`${id}CriticalOnlyInput`}
            >
              Critical/Key Only
            </label>
          </Fragment> : ""}
      </div>
    );
  }
}
