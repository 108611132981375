"use strict";

import { BulkOperationMessageHandler } from "./bulk_operation_message_handler";
import {Log, LOG_GROUP} from "../../../server/common/logger/common_log";
const Logger = Log.group(LOG_GROUP.Editables, "BulkDocumentAddMessageHandler");

/**
 * This class is used to handle websockets messages from the back end
 */
export class BulkDocumentAddMessageHandler extends BulkOperationMessageHandler {

  getDisplayMessage(message, parameters) {
    Logger.verbose(() => "Getting message for:", message);

    let actualMessage = (typeof (message) === "object") ? message.status : message;
    let finalMessage = "";

    switch (actualMessage) {
      case "progress":
        if (message.status && message.data === "emails") {
          finalMessage = "Sending emails";
        }
        finalMessage = message.data;
        break;
      case "done":
        finalMessage = "Completed";
        break;
    }

    this.statusService.displayStatus(finalMessage);
  }
}
