import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, useDir } from '@progress/kendo-react-common';
/**
 * Represents the RatingItem component.
 */
export var RatingItem = React.forwardRef(function (props, ref) {
    var target = React.useRef(null);
    var element = React.useRef(null);
    var dir = useDir(element, props.dir);
    React.useImperativeHandle(target, function () { return ({
        element: element.current,
        props: props,
        value: props.value
    }); });
    React.useImperativeHandle(ref, function () { return target.current; });
    var handleClick = React.useCallback(function (event) {
        if (props.onClick) {
            props.onClick.call(undefined, {
                value: props.value,
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [props.onClick, props.value]);
    var handleKeyDown = React.useCallback(function (event) {
        if (props.onKeyDown) {
            props.onKeyDown.call(undefined, {
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [props.onKeyDown]);
    var handleFocus = React.useCallback(function (event) {
        if (props.onFocus) {
            props.onFocus.call(undefined, {
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [props.onFocus]);
    var handleBlur = React.useCallback(function (event) {
        if (props.onBlur) {
            props.onBlur.call(undefined, {
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [props.onBlur]);
    var handleMouseMove = React.useCallback(function (event) {
        if (props.onMouseMove) {
            props.onMouseMove.call(undefined, {
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [props.onMouseMove]);
    var handleMouseEnter = React.useCallback(function (event) {
        if (props.onMouseEnter) {
            props.onMouseEnter.call(undefined, {
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [props.onMouseEnter]);
    var handleMouseLeave = React.useCallback(function (event) {
        if (props.onMouseLeave) {
            props.onMouseLeave.call(undefined, {
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [props.onMouseLeave]);
    return (React.createElement("span", { id: props.id, ref: element, dir: dir, "data-half": props.half, tabIndex: props.tabIndex, title: props.title, style: props.style, className: classNames('k-rating-item', {
            'k-rtl': dir === 'rtl',
            'k-selected': props.selected,
            'k-hover': props.hovered
        }, props.className), onClick: handleClick, onKeyDown: handleKeyDown, onFocus: handleFocus, onBlur: handleBlur, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, onMouseMove: handleMouseMove }, props.children));
});
var propTypes = {
    id: PropTypes.string,
    icon: PropTypes.any,
    dir: PropTypes.oneOf(['ltr', 'rtl']),
    title: PropTypes.string,
    value: PropTypes.number,
    half: PropTypes.bool,
    selected: PropTypes.bool,
    hovered: PropTypes.bool
};
var defaultProps = {
    dir: 'ltr'
};
RatingItem.displayName = 'KendoReactRatingItem';
// TODO: delete casting when @types/react is updated!
RatingItem.propTypes = propTypes;
RatingItem.defaultProps = defaultProps;
