import React, { Fragment } from "react";
import BaseAttribute from "../base_attribute";
import { getCSSClassForLegend } from "../../../reports/risk_map_reports/utilities/risk_map_report_helper";
import { createHTMLForWholeTextDiff } from "../../../helpers/diff_helper";
import { CRITICALITY_RULES } from "../../../../server/common/misc/common_risk_utils";

export class RiskLabelAttribute extends BaseAttribute {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
    if (!shouldUpdate) {
      shouldUpdate = JSON.stringify(this.props.riskTable) !== JSON.stringify(nextProps.riskTable);
    }

    return shouldUpdate;
  }

  getInitialValue() {
    return "";
  }

  getOldValue() {
    const riskTable = this.props.riskTable;
    if (!riskTable) {
      return null;
    }

    return {
      color: riskTable.oldRiskColor,
      label: riskTable.oldRiskLabel
    };
  }

  getValue() {
    const riskTable = this.props.riskTable;
    if (!riskTable) {
      return null;
    }

    return {
      color: riskTable.riskColor,
      label: riskTable.riskLabel
    };
  }

  shouldRenderSubscriptLabel() {
    const riskTable = this.props.riskTable;
    return !!riskTable?.riskRule && riskTable.riskRule !== CRITICALITY_RULES.MAXIMUM;
  }

  renderInput() {
    const riskTable = this.props.riskTable;
    if (!riskTable) {
      return <Fragment />;
    }

    let input = "Input type not defined: " + this.props.type;
    let inputId = this.getInputId();
    this.currentValue = this.getValue() ? this.getValue() : "";

    if (this.isDiffingVersions()) {
      let oldValue = this.getOldValue();
      input = createHTMLForWholeTextDiff(oldValue?.label, this.currentValue?.label);
    } else {
      input = (<div id={inputId}>
        { <div className={`list-table-risk-label list-table-risk-label-title-bar ${getCSSClassForLegend(this.currentValue.color)}`} /> }
        {this.currentValue.label}
      </div>);
    }

    return input;
  }
}