var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, Keys } from '@progress/kendo-react-common';
import { toolbarButtons } from './../util';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
/**
 * Represents the [KendoReact Toolbar component]({% slug overview_toolbar %}).
 *
 * @example
 * ```jsx
 * class App extends React.Component {
 *    render() {
 *       return (
 *          <Toolbar>
 *              <ToolbarItem>
 *                  <ButtonGroup>
 *                      <Button togglable={true} icon="bold" />
 *                      <Button togglable={true} icon="italic" />
 *                      <Button togglable={true} icon="underline" />
 *                  </ButtonGroup>
 *              </ToolbarItem>
 *              <ToolbarItem>
 *                  <ButtonGroup>
 *                      <Button icon="hyperlink">Insert Link</Button>
 *                      <Button icon="image">Insert Image</Button>
 *                      <Button icon="table">Insert Table</Button>
 *                  </ButtonGroup>
 *              </ToolbarItem>
 *          </Toolbar>
 *       );
 *    }
 * }
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var Toolbar = /** @class */ (function (_super) {
    __extends(Toolbar, _super);
    function Toolbar(props) {
        var _this = _super.call(this, props) || this;
        _this._element = null;
        _this.offsetHeight = 0;
        _this.offsetWidth = 0;
        _this.buttons = [];
        _this.focusedSelector = _this.selectors.map(function (s) { return s + ':focus'; }).join(',');
        _this.setTabIndex = function (focusedIndex) {
            var _a = _this.props.tabIndex, tabIndex = _a === void 0 ? Toolbar.defaultProps.tabIndex : _a;
            _this.buttons.forEach(function (button, index) {
                button.tabIndex = (index === focusedIndex) ? tabIndex : -1;
            });
        };
        _this.onKeyDown = function (event) {
            var target = event.target;
            var arrowKey = event.keyCode === Keys.left || event.keyCode === Keys.right;
            if (!arrowKey || event.defaultPrevented || _this.buttons.findIndex(function (b) { return b === target; }) === -1) {
                return;
            }
            var focusedIndex = _this.focusedIndex;
            if (event.keyCode === Keys.left) {
                _this.focusButton(focusedIndex, focusedIndex - 1);
            }
            else {
                _this.focusButton(focusedIndex, focusedIndex + 1);
            }
        };
        _this.onWindowResize = function (event) {
            var element = _this.element;
            if (!element) {
                return;
            }
            var offsetWidth = element.offsetWidth;
            var offsetHeight = element.offsetHeight;
            if (_this.offsetWidth !== offsetWidth || _this.offsetHeight !== offsetHeight) {
                _this.offsetWidth = offsetWidth;
                _this.offsetHeight = offsetHeight;
                var newSizes = { offsetWidth: _this.offsetWidth, offsetHeight: _this.offsetHeight };
                if (_this.props.onResize) {
                    _this.props.onResize.call(undefined, __assign(__assign({ target: _this }, newSizes), { nativeEvent: event }));
                }
            }
        };
        validatePackage(packageMetadata);
        return _this;
    }
    Object.defineProperty(Toolbar.prototype, "selectors", {
        get: function () {
            return this.props.buttons || toolbarButtons;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Toolbar.prototype, "focusedIndex", {
        get: function () {
            var focused = this.element && this.element.querySelector(this.focusedSelector);
            return Math.max(0, this.buttons.findIndex(function (e) { return e === focused; }));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Toolbar.prototype, "element", {
        /**
         * Returns the HTML element of the Toolbar component.
         */
        get: function () {
            return this._element;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    Toolbar.prototype.componentDidMount = function () {
        window.addEventListener('resize', this.onWindowResize);
        var element = this.element;
        if (element) {
            this.offsetWidth = element.offsetWidth;
            this.offsetHeight = element.offsetHeight;
            if (this.props.keyboardNavigation !== false) {
                this.buttons = Array.from(element.querySelectorAll(this.selectors.join(',')));
                this.setTabIndex(0);
            }
        }
    };
    /**
     * @hidden
     */
    Toolbar.prototype.componentDidUpdate = function () {
        var element = this.element;
        if (!element || this.props.keyboardNavigation === false) {
            return;
        }
        this.buttons = Array.from(element.querySelectorAll(this.selectors.join(',')));
        this.setTabIndex(this.focusedIndex);
    };
    /**
     * @hidden
     */
    Toolbar.prototype.componentWillUnmount = function () {
        window.removeEventListener('resize', this.onWindowResize);
        this.buttons.length = 0;
    };
    /**
     * @hidden
     */
    Toolbar.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { id: this.props.id, className: classNames('k-widget k-toolbar', this.props.className), style: this.props.style, role: this.props.role !== undefined ? (this.props.role || undefined) : 'toolbar', dir: this.props.dir, ref: function (element) { return _this._element = element; }, onKeyDown: this.props.keyboardNavigation !== false ? this.onKeyDown : undefined }, this.props.children));
    };
    Toolbar.prototype.focusButton = function (prevIndex, index) {
        var _a = this.props.tabIndex, tabIndex = _a === void 0 ? Toolbar.defaultProps.tabIndex : _a;
        var button = this.buttons[index];
        if (button) {
            button.tabIndex = tabIndex;
            button.focus();
            var prevButton = this.buttons[prevIndex];
            if (prevButton) {
                prevButton.tabIndex = -1;
            }
        }
    };
    /**
     * @hidden
     */
    Toolbar.propTypes = {
        tabIndex: PropTypes.number,
        dir: PropTypes.string,
        keyboardNavigation: PropTypes.bool,
        style: PropTypes.object,
        className: PropTypes.string,
        role: PropTypes.string,
        onResize: PropTypes.func,
        buttons: PropTypes.arrayOf(PropTypes.string)
    };
    /**
     * @hidden
     */
    Toolbar.defaultProps = {
        tabIndex: 0
    };
    return Toolbar;
}(React.Component));
export { Toolbar };
