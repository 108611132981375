import { BaseParser } from "../base_parser";
import { ElementParserFactory } from "./element_parser_factory";
import { merge } from "../../index";
import { WidgetParserFactory } from "../widget_parser";

/**
 * Unlike the QbDFieldParser, this parser will parse all QbD fields in
 * the widgets recursively
 */
export class ElementParser extends BaseParser {
  getParentDataOfElementWithDifferentModel(data, modelName) {
    if (!data) {
      return null;
    }

    if (data.modelName === modelName) {
      return data;
    }

    return this.getParentDataOfElementWithDifferentModel(
      data.parentData,
      modelName,
    );
  }

  parseElementWithDifferentModel(
    parentModelName,
    modelName,
    columnName,
    element,
    newElement,
  ) {
    const parentModelNameParts = parentModelName.split(".");
    parentModelName =
      parentModelNameParts.length > 1
        ? parentModelNameParts[1]
        : parentModelName;
    const repeaterField = this.addModelAndColumnToField(
      this.fields,
      parentModelName,
    );
    this.addModelAndColumnToField(repeaterField, modelName, columnName);

    if (this.data && modelName in this.data) {
      if (this.data[modelName] && this.data[modelName][columnName]) {
        return this.data[modelName][columnName];
      }

      const parentData = this.getParentDataOfElementWithDifferentModel(
        this.data?.parentData,
        modelName,
      );
      if (parentData && parentData[columnName]) {
        return parentData[columnName];
      }

      return "";
    } else {
      const parentData = this.getParentDataOfElementWithDifferentModel(
        this.data?.parentData,
        modelName,
      );
      if (parentData && parentData[columnName]) {
        return parentData[columnName];
      }
    }

    newElement.className = "alert alert-danger";
    return element.innerText;
  }

  parseChildElement(element, parentModelName) {
    if (!element.tagName) {
      return [element.textContent];
    }

    // We don't need to parse the direct scope widget and qbd field
    if (
      element.className?.includes("qbd-output-direct-scope-widget") ||
      element.className?.includes("direct-scope-widget-error") ||
      element.className?.includes("qbd-field-error")
    ) {
      return [element];
    }

    const newElement = document.createElement(element.tagName);
    const kind = element.getAttribute("kind");
    if (kind) {
      const parentField = this.addModelAndColumnToField(
        this.fields,
        parentModelName,
      );

      const parser = WidgetParserFactory.getParser(
        this.globalNode,
        element,
        element,
        this.data,
        this.fileType,
        this.rmp,
        this.project,
        this.process,
        this.parseOnly,
      );
      const newElements = parser.parse();
      merge(parentField, parser.fields);
      newElement.append(...newElements);
      return [newElement];
    }

    for (const attribute of element.getAttributeNames()) {
      // We don't want to keep class since we don't want to keep
      // the css styling in PDF preview
      if (attribute === "class") {
        continue;
      }
      newElement.setAttribute(attribute, element.getAttribute(attribute));
    }

    for (const childNode of element.childNodes) {
      newElement.append(...this.parseChildElement(childNode, parentModelName));
    }

    if (element.className && element.className.includes("qbd-output")) {
      const {modelName, columnName} = this.getNodeInfo(element);
      const parser = ElementParserFactory.getParser(
        this.globalNode,
        this.node,
        element,
        this.data,
        this.fileType,
        this.rmp,
        this.project,
        this.process,
        this.parseOnly,
      );

      if (parser) {
        const newElements = parser.parse();
        merge(this.fields, parser.fields);
        return newElements;
      }

      let textContent = this.data ? this.data[columnName] : "";
      if (modelName === parentModelName) {
        this.addModelAndColumnToField(this.fields, modelName, columnName);
      } else if (parentModelName) {
        textContent = this.parseElementWithDifferentModel(
          parentModelName,
          modelName,
          columnName,
          element,
          newElement,
        );
        if (newElement.className.includes("alert alert-danger")) {
          return [this.createElementFromText(this.createErrorElement(element))];
        }
      }

      textContent = formatText(textContent);
      newElement.innerText = textContent || "";
    }

    return [newElement];
  }

  parse() {
    const parentModelName = this.parentNode.getAttribute
      ? this.parentNode.getAttribute("model")
      : null;
    return this.parseChildElement(this.node, parentModelName);
  }
}

export function formatText(textContent) {
  try {
    const item = JSON.parse(textContent);
    if (Array.isArray(item)) {
      return item.sort().join(", ");
    }

    const content = textContent?.toString();
    if (content === "true") {
      return "Yes";
    }

    if (content === "false") {
      return "No";
    }

    return content;
  } catch (e) {
    return textContent?.toString();
  }
}
