"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import BaseReactComponent from "../../base_react_component";

/* This shows the comments and approvers of a given request. */
export default class ApprovalRequestTooltip extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  /**
   * @param {string} date A date coming from the DB
   * @return {string} A date that can be shown to a user
   */
  static formatDate(date) {
    return moment(date, UIUtils.DATE_FORMAT_FOR_STORAGE).format(UIUtils.DATE_FORMAT_FOR_DISPLAY);
  }

  componentDidMount() {
    super.componentDidMount();
    $(this.popover).popover({sanitizeFn: UIUtils.sanitizePopoverData});
  }

  render() {
    let version = this.props.version;
    let versionTransition = this.props.versionTransition;
    let userIdToApprovalResponse = this.props.userIdToApprovalResponse;
    let approvalRequest = versionTransition.approvalRequest;
    let approveByDate = approvalRequest.approveByDate ? ApprovalRequestTooltip.formatDate(approvalRequest.approveByDate) : "";
    let requestPopupContent = (
      <div>
        <div className="history-item-popup-title">Approvers</div>
        <div className="history-item-popup-approvers">
          {approvalRequest.approvalReqToUsers.map(
            approverRequested => (
              <div className="history-item-popup-value history-item-popup-approver" key={approverRequested.userId}>
                {approverRequested.user?.firstName} {approverRequested.user?.lastName} {" "}
                (<i>{userIdToApprovalResponse[approverRequested.userId] ?
                (userIdToApprovalResponse[approverRequested.userId].approve ?
                  "Approved" : "Rejected") : "Pending"}</i>)
              </div>
            )
          )
          }
        </div>
        {approveByDate ? (
          <div>
            <div className="history-item-popup-title">Approve By:</div>
            <div className="history-item-popup-value history-item-popup-approve-by-date">
              {approveByDate}
            </div>
          </div>
        ) : ""}
        <div className="history-item-popup-title">Description</div>
        <div className="history-item-popup-value history-item-popup-comment">
          {approvalRequest.comment}
        </div>
        {approvalRequest.withdrawn ? (
          <div>
            <div className="history-item-popup-title">Withdrawal Comment</div>
            <div className="history-item-popup-value history-item-popup-withdrawal-comment">
              {approvalRequest.withdrawalComment}
            </div>
          </div>
        ) : ""}
      </div>
    );

    return (
      <a data-toggle="popover" data-html="true" data-container="body"
         data-content={ReactDOMServer.renderToStaticMarkup(requestPopupContent)}
         id={(this.props.isVersionTransitionTooltip ? "historyItemTransitionPopup" : "historyItemPopup") +
           version.majorVersion + "-" + version.minorVersion + "-" + this.props.index}
         data-placement="top" ref={popover => this.popover = popover}
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </a>
    );
  }

}
