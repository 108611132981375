"use strict";

import {
  generateAcceptanceCriteriaOrSupplierValue,
  generateAcceptanceCriteriaValue,
  generateAssessmentStatus,
  generateChangeValue, generateControlValue,
  generateDocumentsValue, generateLinkedAssetsValue, generateOutcomeValue, generateRiskValue, generateVersionValue
} from "../../export/configurable_tables_export_value_generator";
import { RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import { getClassName } from "../../../techTransfer/tables/tech_transfer_table_helper";
import { ABOUT_SECTION, LEADING_SECTION, TECH_TRANSFER_SECTION } from "./configurable_tables_sections_config";
import { FIELD_PROPS } from "./configurable_tables_field_props_config";
import { FILTER_TYPES } from "../../columnOperations/configurable_tables_column_operations_constants";
import {
  ABOUT_COL_SPAN,
  ACCEPTANCE_CRITERIA_SUMMARY_COL_SPAN,
  SUPPLIER_COL_SPAN,
  TECH_TRANSFER_COL_SPAN
} from "./configurable_tables_col_spans_config";
import { sortByParent } from "../../columnOperations/configurable_tables_column_sorting";


export const SENDING_SITE_LABEL = "sending";
export const RECEIVING_SITE_LABEL = "receiving";
export const COMMON_SITE_LABEL = "common";
export const HIDDEN = "hidden";

/**
 * Configuration in this file is used for the column selection widget and decide
 * how columns are exported to the excel sheet. There are multiple configuration
 * for each field that is used to decide where the column will show and how the
 * value/title will be evaluated. Possible props for configuration are:
 * 1- title: Title can be either a function or a string. If a function, depending on
 * the siteForValueEvaluation prop either to or from site will be sent as an argument
 * to this function.
 * 2- prop: The prop name along with the originatedFromSite are used to set the id of
 * each column and checkbox for the column selection widget and export.
 * 3- siteForValueEvaluation: Depending on this the arguments sent to the value
 * function will change. So in case this is SENDING_SITE_LABEL, only the fromSite
 * will be sent as an argument to the value function. In case of RECEIVING_SITE_LABEL,
 * the fromSite only will be sent to the value function and in case of COMMON_SITE_LABEL,
 * both the fromSite and toSite will both be sent to the value function for the value to be
 * evaluated.
 * 4- value: The value can be a string, function, or by default it will get the record[prop].
 * 5- showColumnSelectionOn: This will decide on which side of the column selection widget the
 * checkbox will appear. The checkbox can appear on sending site only or receiving site or both.
 * In case of a field that has a sending/receiving value (ex. control) we will set this value
 * to HIDDEN so it wont show up in the UI however, it will be used in the export and datatables.net
 * to show the column.
 * 6- colspan: This will decide the column grouping of the fields in case there are any
 * (ex. comment, impact, detectability, etc. will show under Tech Transfer Assessment group)
 * 7- section: This will show a header above all columns have the same section (ex. Criticality Assessment)
 * 8- defaultTechTransferValue: The default value for the checkbox of that field.
 * 9- columnSelectionTitle: This will override the title in the column selection widget.
 * 10- defaultChangeCriteriaValue: The default value for the change criteria selection.
 * 11- tableValue: The render of the column in the table (ex. risk columns with label, color, etc.).
 * 12- filterLabel: For something like Impact it needs some processing before passing it to filtration and this
 * post-processed value is put in a new prop with the name of the filterLabel.
 * 13- defaultValue: If it should show in the column selection by default or not
 */

export const SENDING_SITE_COMMON_COLUMNS_PROPS = {
  originatedFromSite: SENDING_SITE_LABEL,
  showColumnSelectionOn: COMMON_SITE_LABEL,
  siteForValueEvaluation: SENDING_SITE_LABEL,
};

export const RECEIVING_SITE_COMMON_COLUMNS_PROPS = {
  originatedFromSite: RECEIVING_SITE_LABEL,
  showColumnSelectionOn: HIDDEN,
  siteForValueEvaluation: RECEIVING_SITE_LABEL,
  defaultValue: false,
  defaultTechTransferValue: false,
};

export const RECEIVING_TECH_TRANSFER_COLUMNS_PROPS = {
  colspan: TECH_TRANSFER_COL_SPAN,
  section: TECH_TRANSFER_SECTION,
  originatedFromSite: RECEIVING_SITE_LABEL,
  showColumnSelectionOn: RECEIVING_SITE_LABEL,
  siteForValueEvaluation: RECEIVING_SITE_LABEL,
  defaultTechTransferValue: true,
};

export const TECH_TRANSFER_ASSESSMENT_COLUMNS = [
  {
    title: "Change",
    prop: FIELD_PROPS.CHANGE,
    section: TECH_TRANSFER_SECTION,
    originatedFromSite: RECEIVING_SITE_LABEL,
    showColumnSelectionOn: RECEIVING_SITE_LABEL,
    siteForValueEvaluation: COMMON_SITE_LABEL,
    defaultTechTransferValue: true,
    value: generateChangeValue,
    tableValue: (columnGenerator, process, enableColumnOperations) =>
      columnGenerator.generateChangeColumn(columnGenerator.props.onReviewRecord, enableColumnOperations),
  },
  {
    title: "Assessment Status",
    prop: FIELD_PROPS.ASSESSMENT_STATUS,
    filterType: FILTER_TYPES.COMBO_BOX,
    value: generateAssessmentStatus,
    ...RECEIVING_TECH_TRANSFER_COLUMNS_PROPS,
  },
  {
    title: "Comment",
    prop: FIELD_PROPS.COMMENT,
    tableValue: (columnGenerator, process, enableColumnOperations) =>
      columnGenerator.generateTextColumn("Comment", FIELD_PROPS.COMMENT,
        process, true, true, enableColumnOperations),
    ...RECEIVING_TECH_TRANSFER_COLUMNS_PROPS,
  },
  {
    title: "Document References",
    prop: FIELD_PROPS.TECH_TRANSFER_REFERENCE,
    value: generateDocumentsValue,
    tableValue: (columnGenerator, process) =>
      columnGenerator.generateDocumentsColumn("Document References", process,
        FIELD_PROPS.REFERENCES, true, true),
    ...RECEIVING_TECH_TRANSFER_COLUMNS_PROPS,
  },
  {
    title: "Impact/Severity Description",
    prop: FIELD_PROPS.IMPACT_DESCRIPTION,
    tableValue: (columnGenerator, process, enableColumnOperations) =>
      columnGenerator.generateTextAreaColumn("Impact/Severity Description",
        FIELD_PROPS.IMPACT_DESCRIPTION, process, true, true, enableColumnOperations),
    ...RECEIVING_TECH_TRANSFER_COLUMNS_PROPS,
  },
  {
    title: "Impact/Severity",
    prop: FIELD_PROPS.TECH_TRANSFER_IMPACT,
    isRiskScoreField: true,
    filterLogic: "equals",
    filterType: FILTER_TYPES.COMBO_BOX,
    filterOptions: (records, columnOperationsAdapter) =>
      columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.TECH_TRANSFER_IMPACT, true),
    value: generateRiskValue.bind(this, FIELD_PROPS.TECH_TRANSFER_IMPACT, RISK_TYPE_ENUM.IMPACT),
    tableValue: (columnGenerator, process, enableColumnOperations) =>
      columnGenerator.generateRiskColumn("Impact/Severity", FIELD_PROPS.TECH_TRANSFER_IMPACT,
        RISK_TYPE_ENUM.IMPACT,
        getClassName(FIELD_PROPS.TECH_TRANSFER_IMPACT, true),
        null, true, enableColumnOperations),
    ...RECEIVING_TECH_TRANSFER_COLUMNS_PROPS,
  },
  {
    title: "Uncertainty Justification",
    prop: FIELD_PROPS.UNCERTAINTY_JUSTIFICATION,
    tableValue: (columnGenerator, process, enableColumnOperations) => columnGenerator.generateTextAreaColumn(
      "Uncertainty Justification", FIELD_PROPS.UNCERTAINTY_JUSTIFICATION, process, true, true, enableColumnOperations
    ),
    ...RECEIVING_TECH_TRANSFER_COLUMNS_PROPS,
  },
  {
    title: "Uncertainty",
    prop: FIELD_PROPS.TECH_TRANSFER_UNCERTAINTY,
    isRiskScoreField: true,
    filterLogic: "equals",
    filterType: FILTER_TYPES.COMBO_BOX,
    filterOptions: (records, columnOperationsAdapter) =>
      columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.TECH_TRANSFER_UNCERTAINTY, true),
    value: generateRiskValue.bind(this, FIELD_PROPS.TECH_TRANSFER_UNCERTAINTY, RISK_TYPE_ENUM.UNCERTAINTY),
    tableValue: (columnGenerator, process, enableColumnOperations) =>
      columnGenerator.generateRiskColumn("Uncertainty", FIELD_PROPS.TECH_TRANSFER_UNCERTAINTY,
        RISK_TYPE_ENUM.UNCERTAINTY, getClassName(FIELD_PROPS.TECH_TRANSFER_UNCERTAINTY, true),
        null, true, enableColumnOperations),
    ...RECEIVING_TECH_TRANSFER_COLUMNS_PROPS,
  },
  {
    title: "Detectability / Current Control Strategy",
    prop: FIELD_PROPS.TECH_TRANSFER_CONTROL_STRATEGY,
    tableValue: (columnGenerator, process, enableColumnOperations) =>
      columnGenerator.generateTextColumn("Detectability / Current Control Strategy",
        FIELD_PROPS.TECH_TRANSFER_CONTROL_STRATEGY, process, true, true, enableColumnOperations),
    ...RECEIVING_TECH_TRANSFER_COLUMNS_PROPS,
  },
  {
    title: "Detectability",
    prop: FIELD_PROPS.TECH_TRANSFER_DETECTABILITY,
    isRiskScoreField: true,
    filterLogic: "equals",
    filterType: FILTER_TYPES.COMBO_BOX,
    filterOptions: (records, columnOperationsAdapter) =>
      columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.TECH_TRANSFER_DETECTABILITY, true),
    value: generateRiskValue.bind(this, FIELD_PROPS.TECH_TRANSFER_DETECTABILITY, RISK_TYPE_ENUM.DETECTABILITY_RISK),
    tableValue: (columnGenerator, process, enableColumnOperations) =>
      columnGenerator.generateRiskColumn("Detectability", FIELD_PROPS.TECH_TRANSFER_DETECTABILITY,
        RISK_TYPE_ENUM.DETECTABILITY_RISK, getClassName(FIELD_PROPS.TECH_TRANSFER_DETECTABILITY, true),
        null, true, enableColumnOperations),
    ...RECEIVING_TECH_TRANSFER_COLUMNS_PROPS,
  },
  {
    title: "Outcome",
    prop: FIELD_PROPS.OUTCOME,
    isRiskScaleField: true,
    filterLogic: "equals",
    filterType: FILTER_TYPES.COMBO_BOX,
    filterOptions: (records, columnOperationsAdapter) =>
      columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.OUTCOME, true),
    value: generateOutcomeValue,
    tableValue: (columnGenerator, process, enableColumnOperations) =>
      columnGenerator.generateOutcomeColumn(enableColumnOperations),
    ...RECEIVING_TECH_TRANSFER_COLUMNS_PROPS,
  },
  {
    title: "Risk Mitigation Strategy and Limitations",
    prop: FIELD_PROPS.RISK_MITIGATION,
    tableValue: (columnGenerator, process, enableColumnOperations) =>
      columnGenerator.generateTextColumn("Risk Mitigation Strategy and Limitations",
        FIELD_PROPS.RISK_MITIGATION, process, true, true, enableColumnOperations),
    ...RECEIVING_TECH_TRANSFER_COLUMNS_PROPS,
  },
  {
    title: "Recommended Actions",
    prop: FIELD_PROPS.TECH_TRANSFER_RECOMMENDED_ACTIONS,
    tableValue: (columnGenerator, process, enableColumnOperations) =>
      columnGenerator.generateTextColumn("Recommended Actions",
        FIELD_PROPS.TECH_TRANSFER_RECOMMENDED_ACTIONS, process, true, true, enableColumnOperations),
    ...RECEIVING_TECH_TRANSFER_COLUMNS_PROPS,
  }
];

export const COMMON_VARIABLE_ABOUT_COLUMNS = [
  {
    title: "Name",
    prop: FIELD_PROPS.NAME,
    colspan: ABOUT_COL_SPAN,
    section: ABOUT_SECTION,
    defaultValue: true,
    defaultTechTransferValue: false,
    defaultChangeCriteriaValue: true,
    tableValue: (columnGenerator, process) => columnGenerator.generateNameColumn("Name", FIELD_PROPS.NAME, process),
    ...SENDING_SITE_COMMON_COLUMNS_PROPS,
  },
  {
    title: "Name",
    prop: FIELD_PROPS.NAME,
    colspan: ABOUT_COL_SPAN,
    tableValue: (columnGenerator, process) => columnGenerator.generateNameColumn("Name", FIELD_PROPS.NAME, process, true),
    ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
  },
  {
    title: "Version",
    prop: FIELD_PROPS.VERSION,
    colspan: ABOUT_COL_SPAN,
    section: ABOUT_SECTION,
    defaultValue: true,
    defaultTechTransferValue: false,
    defaultChangeCriteriaValue: true,
    value: generateVersionValue,
    tableValue: (columnGenerator) => columnGenerator.generateVersionColumn("Version"),
    ...SENDING_SITE_COMMON_COLUMNS_PROPS,
  },
  {
    title: "Version",
    prop: FIELD_PROPS.VERSION,
    colspan: ABOUT_COL_SPAN,
    section: ABOUT_SECTION,
    value: generateVersionValue,
    tableValue: (columnGenerator) => columnGenerator.generateVersionColumn("Version", null, true),
    ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
  },
  {
    title: "Material/Process Component",
    prop: FIELD_PROPS.PARENT,
    colspan: ABOUT_COL_SPAN,
    section: ABOUT_SECTION,
    defaultValue: true,
    showColumnSelectionOn: HIDDEN,
    compare: sortByParent,
    tableValue: (columnGenerator) =>
      columnGenerator.generateParentColumnForMaterialProcessComponent(),
  },
  {
    title: "Unit Operation",
    prop: FIELD_PROPS.UNIT_OPERATION,
    colspan: ABOUT_COL_SPAN,
    section: ABOUT_SECTION,
    defaultValue: false,
    defaultTechTransferValue: false,
    defaultChangeCriteriaValue: true,
    sortByOrder: true,
    filterType: FILTER_TYPES.LOOKUP,
    value: (record) => record[FIELD_PROPS.UNIT_OPERATION_NAME],
    tableValue: (columnGenerator, process) =>
      columnGenerator.generateParentColumn("Unit Operation", FIELD_PROPS.UNIT_OPERATION, process),
    ...SENDING_SITE_COMMON_COLUMNS_PROPS,
  },
  {
    title: "Unit Operation",
    prop: FIELD_PROPS.UNIT_OPERATION,
    colspan: ABOUT_COL_SPAN,
    sortByOrder: true,
    filterType: FILTER_TYPES.LOOKUP,
    value: (record) => record[FIELD_PROPS.UNIT_OPERATION_NAME],
    tableValue: (columnGenerator, process) =>
      columnGenerator.generateParentColumn("Unit Operation", FIELD_PROPS.UNIT_OPERATION, process, true),
    ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
  },
  {
    title: "Step",
    prop: FIELD_PROPS.STEP,
    colspan: ABOUT_COL_SPAN,
    section: ABOUT_SECTION,
    defaultValue: false,
    defaultTechTransferValue: false,
    defaultChangeCriteriaValue: true,
    sortByOrder: true,
    filterType: FILTER_TYPES.LOOKUP,
    value: (record) => record[FIELD_PROPS.STEP_NAME],
    tableValue: (columnGenerator, process) =>
      columnGenerator.generateParentColumn("Step", FIELD_PROPS.STEP, process),
    ...SENDING_SITE_COMMON_COLUMNS_PROPS,
  },
  {
    title: "Step",
    prop: FIELD_PROPS.STEP,
    colspan: ABOUT_COL_SPAN,
    sortByOrder: true,
    filterType: FILTER_TYPES.LOOKUP,
    value: (record) => record[FIELD_PROPS.STEP_NAME],
    tableValue: (columnGenerator, process) =>
      columnGenerator.generateParentColumn("Step", FIELD_PROPS.STEP, process, true),
    ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
  },
  {
    title: "Type",
    prop: FIELD_PROPS.TYPE,
    colspan: ABOUT_COL_SPAN,
    section: ABOUT_SECTION,
    defaultValue: true,
    defaultTechTransferValue: false,
    defaultChangeCriteriaValue: false,
    filterType: FILTER_TYPES.COMBO_BOX,
    ...SENDING_SITE_COMMON_COLUMNS_PROPS,
  },
  {
    title: "Type",
    prop: FIELD_PROPS.TYPE,
    colspan: ABOUT_COL_SPAN,
    filterType: FILTER_TYPES.COMBO_BOX,
    ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
  },
  {
    title: "Description",
    prop: FIELD_PROPS.DESCRIPTION,
    colspan: ABOUT_COL_SPAN,
    section: ABOUT_SECTION,
    defaultValue: false,
    defaultTechTransferValue: false,
    defaultChangeCriteriaValue: true,
    tableValue: (columnGenerator, process) => columnGenerator.generateTextAreaColumn("Description", FIELD_PROPS.DESCRIPTION, process),
    ...SENDING_SITE_COMMON_COLUMNS_PROPS,
  },
  {
    title: "Description",
    colspan: ABOUT_COL_SPAN,
    prop: FIELD_PROPS.DESCRIPTION,
    tableValue: (columnGenerator, process) => columnGenerator.generateTextAreaColumn("Description", FIELD_PROPS.DESCRIPTION, process, true),
    ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
  },
];

export const COMMON_VARIABLE_COLUMNS = [
  {
    title: "Linked Assets",
    prop: FIELD_PROPS.LINKED_ASSETS,
    section: LEADING_SECTION,
    originatedFromSite: RECEIVING_SITE_LABEL,
    showColumnSelectionOn: RECEIVING_SITE_LABEL,
    siteForValueEvaluation: COMMON_SITE_LABEL,
    defaultTechTransferValue: true,
    isCompositeField: true,
    value: generateLinkedAssetsValue,
    tableValue: (columnGenerator) => columnGenerator.generateLinkedAssetsColumn(),
  },
  {
    title: "Control",
    prop: FIELD_PROPS.CONTROL,
    section: LEADING_SECTION,
    defaultTechTransferValue: true,
    isCompositeField: true,
    value: generateControlValue,
    tableValue: (columnGenerator, process) => columnGenerator.generateControlColumn(process),
    ...SENDING_SITE_COMMON_COLUMNS_PROPS,
  },
  {
    title: "Control",
    prop: FIELD_PROPS.CONTROL,
    value: generateControlValue,
    tableValue: (columnGenerator, process) => columnGenerator.generateControlColumn(process, true),
    ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
  },
  {
    title: "Acceptance Criteria",
    prop: FIELD_PROPS.ACCEPTANCE_CRITERIA,
    colspan: ACCEPTANCE_CRITERIA_SUMMARY_COL_SPAN,
    section: LEADING_SECTION,
    defaultTechTransferValue: true,
    isCompositeField: true,
    value: generateAcceptanceCriteriaValue,
    tableValue: (columnGenerator, process) => columnGenerator.generateAcceptanceCriteriaColumn(process),
    ...SENDING_SITE_COMMON_COLUMNS_PROPS,
  },
  {
    title: "Acceptance Criteria",
    prop: FIELD_PROPS.ACCEPTANCE_CRITERIA,
    colspan: ACCEPTANCE_CRITERIA_SUMMARY_COL_SPAN,
    originatedFromSite: RECEIVING_SITE_LABEL,
    showColumnSelectionOn: HIDDEN,
    siteForValueEvaluation: RECEIVING_SITE_LABEL,
    defaultTechTransferValue: true,
    isCompositeField: true,
    value: generateAcceptanceCriteriaValue,
    tableValue: (columnGenerator, process) => columnGenerator.generateAcceptanceCriteriaColumn(process, true),
  }
];

export const COMMON_VARIABLE_PARENT_COLUMNS = [
  {
    title: "Supplier",
    prop: FIELD_PROPS.SUPPLIER_OR_ACCEPTANCE_CRITERIA,
    colspan: SUPPLIER_COL_SPAN,
    section: LEADING_SECTION,
    defaultTechTransferValue: true,
    isCompositeField: true,
    value: generateAcceptanceCriteriaOrSupplierValue,
    tableValue: (columnGenerator, process) => columnGenerator.generateSupplierAndMaterialSummaryColumn(process),
    ...SENDING_SITE_COMMON_COLUMNS_PROPS,
  },
  {
    title: "Supplier",
    prop: FIELD_PROPS.SUPPLIER_OR_ACCEPTANCE_CRITERIA,
    colspan: SUPPLIER_COL_SPAN,
    originatedFromSite: RECEIVING_SITE_LABEL,
    showColumnSelectionOn: HIDDEN,
    siteForValueEvaluation: RECEIVING_SITE_LABEL,
    defaultTechTransferValue: true,
    isCompositeField: true,
    value: generateAcceptanceCriteriaOrSupplierValue,
    tableValue: (columnGenerator, process) => columnGenerator.generateSupplierAndMaterialSummaryColumn(process, true),
  }
];

export const FUNCTION_COLUMN = [
  {
    title: "Function",
    prop: FIELD_PROPS.FUNCTION,
    section: ABOUT_SECTION,
    defaultTechTransferValue: true,
    defaultChangeCriteriaValue: true,
    ...SENDING_SITE_COMMON_COLUMNS_PROPS,
  },
  {
    title: "Function",
    prop: FIELD_PROPS.FUNCTION,
    ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
  }
];
