"use strict";

const mathjs = require("mathjs");
const SCIENTIFIC_NUMBER_REGEX = /\d+\.?\d*e[\+\-]*\d+/i;
const LEADING_ZEROS_REGEX = /(^0+)/g;
const SIGNS_PERIOD_REGEX = /([+-.])/g;
const FIND_FRONT_ZEROS_SIGN_DOT_EXP = /^[\D0]+|\.$/g;

/**
 * This function gets min number of decimal places in group of numbers
 * ex. 1.214, 1.14, 1.1 -> max digits numbers is 3 for 1.214
 * @param measurements Values to get max decimal places for
 */
module.exports.getNumberOfMostDecimalPlaces = function(measurements) {
  const DECIMAL_PLACES_REGEX = /(?:\.)(\d*)/g;
  return mathjs.max(measurements.map(m => {
    m = String(m).trim();

    let match = new RegExp(DECIMAL_PLACES_REGEX).exec(m);
    return match ? match[1].length : 0;
  }));
};

/**
 * This function gets min number of decimal places in group of numbers
 * ex. 1.214, 1.14, 1.1 -> min digits numbers is 1 for 1.1
 * @param measurements Values to get max decimal places for
 */
module.exports.getNumberOfLeastDecimalPlaces = function(measurements) {
  const DECIMAL_PLACES_REGEX = /(?:\.)(\d*)/g;
  return mathjs.min(measurements.map(m => {
    m = String(m).trim();

    let match = new RegExp(DECIMAL_PLACES_REGEX).exec(m);
    return match ? match[1].length : 0;
  }));
};

/**
 * This function calculates average for given set of measurements
 * @param measurements Values to get average for
 * @param returnReportedRoundedNumber return reported rounded number instead of saved format
 * @return {string} Returns average for list of numbers
 */
module.exports.getCorrectedAverage = function(measurements, returnReportedRoundedNumber = false) {
  let average = mathjs.mean(measurements);
  let numberOfLeastSignificantFigures = exports.getNumberOfLeastSignificantFigures(measurements);
  let result = exports.getFDACorrectedNumberForSave(average, numberOfLeastSignificantFigures);
  result = returnReportedRoundedNumber ? exports.getFDAReportedRoundedNumber(result) : result;
  return result.toString();
};

/**
 * This function calculates max for given set of measurements
 * @param measurements Values to get max for
 * @param returnReportedRoundedNumber return reported rounded number instead of saved format
 * @return {string} Returns max for list of numbers
 */
module.exports.getCorrectedMax = function(measurements) {
  let max = mathjs.max(measurements);
  const containsString = measurements.find(measurement => typeof measurement === "string" || measurement instanceof String);
  if(!containsString) {
    return max;
  }

  return measurements.find(measurement => measurement == max);
};

/**
 * This function calculates min for given set of measurements
 * @param measurements Values to get min for
 * @param returnReportedRoundedNumber return reported rounded number instead of saved format
 * @return {string} Returns min for list of numbers
 */
module.exports.getCorrectedMin = function(measurements) {
  let min = mathjs.min(measurements);
  const containsString = measurements.find(measurement => typeof measurement === "string" || measurement instanceof String);
  if(!containsString) {
    return min;
  }

  return measurements.find(measurement => measurement == min);
};

/**
 * This function calculates standard division for given set of measurements
 * @param measurements Values to get Std for
 * @param returnReportedRoundedNumber return reported rounded number instead of saved format
 * @return {string} Returns Std for list of numbers
 */
module.exports.getCorrectedStd = function(measurements, returnReportedRoundedNumber = false) {
  let std = mathjs.std(measurements);
  let numberOfLeastSignificantFigures = exports.getNumberOfLeastSignificantFigures(measurements);
  let result = exports.getFDACorrectedNumberForSave(std, numberOfLeastSignificantFigures);
  result = returnReportedRoundedNumber ? exports.getFDAReportedRoundedNumber(result) : result;
  return result.toString();
};

/**
 * This function calculates range for given min and max
 * @param min
 * @param max
 * @return {number} Returns Std for list of numbers
 */
module.exports.getCorrectedRange = function(min, max) {
  let range = (max - min);
  let numberOfLeastDecimalPlaces = exports.getNumberOfLeastDecimalPlaces([min, max]);
  return parseFloat(range.toFixed(numberOfLeastDecimalPlaces));
};

/**
 * Reference: https://www.fda.gov/media/73535/download - Section 4.3.2.1 -  Definitions and Rules for Significant Figures
 * This function returns the significant digits of a number according to FDA guidelines.
 * @param number input number to get significant figures for
 * @return number throw typeError if invalid input (ex. 742400g, -Infinity, NaN, .. etc), otherwise will return a positive number.
 */
module.exports.getNumberOfSignificantFigures = function(number) {
  if (!isFinite(Number(number))) {
    throw new TypeError(`Number (${number}) has an invalid format.`);
  }

  number = String(number).trim();
  number = exports.scientificToDecimal(number);
  number = number.replace(SIGNS_PERIOD_REGEX, "").replace(LEADING_ZEROS_REGEX, "");

  // Significant figures can't be less than 1. 0 is considered to have 1 significant figure.
  return number.length > 0 ? number.length : 1;
};

/**
 * Reference: https://www.fda.gov/media/73535/download - Section 4.3.2 -  Significant Figures
 * This function return the number of signification digits for the least precise number in a group of numbers.
 * ex. If we have numbers like 12, 12.1, 12.23 then this function will return 2 as least significant number is 12.
 * @param {*} numbers to get number of least significant number for.
 */
module.exports.getNumberOfLeastSignificantFigures = function(numbers) {
  if (numbers.length > 0) {
    return mathjs.min(numbers.map(n => exports.getNumberOfSignificantFigures(n)));
  } else {
    return 3;
  }
};

/**
 * This function returns the length of the max number text length
 * @param numbers to get max number length for and they should be > 1
 * @returns {number} max number text length
 */
module.exports.getMaxTextLengthOfNumbers = function(...numbers) {
  numbers = numbers ? numbers.filter(number => number) : [];

  for (let number of numbers) {
    if (!isFinite(Number(number))) {
      throw new TypeError(`Number (${number}) has an invalid format.`);
    } else if (Number(number) < 1) {
      throw new TypeError(`This function is used for numbers > 1 only.`);
    }
  }

  return mathjs.max(numbers.map(number => mathjs.abs(number).toString().length));
};

/**
 * This function return the number of signification digits for the most precise number in a group of numbers.
 * ex. If we have numbers like 12, 12.1, 12.22 then this function will return 4 as most significant number is 12.22.
 * @param {*} numbers to get number of most significant number for.
 */
module.exports.getNumberOfMostSignifcantFigures = function(numbers) {
  if (numbers.length > 0) {
    return mathjs.max(numbers.map(n => exports.getNumberOfSignificantFigures(n)));
  } else {
    return 3;
  }
};

/**
 * Reference: https://www.fda.gov/media/73535/download - Section 4.3.1 - Rounding of Reported Data
 * In FDA, we should retain one extra digit for calculated numbers. For example if calculated number is
 * 12.657662 and least significant figures of measurements used to calculate that number
 * is 4 which makes the number to save 12.6576, we would need to keep one extra digit,
 * which makes the number we save is 12.65766
 * @param number input number to get FDA corrected format for save
 * @param significantFiguresToReport how many significant figures returned result should retain
 * @return number with correct digit numbers
 */
module.exports.getFDACorrectedNumberForSave = function(number, significantFiguresToReport) {
  if (!isFinite(Number(number))) {
    throw new TypeError(`Number (${number}) has an invalid format.`);
  } else if (mathjs.isZero(number)) {
    return "0";
  }

  // Converting input to string and removing any white spaces
  number = String(number).trim();

  // Converting scientific input to regular number
  number = exports.scientificToDecimal(number);

  // Check if the input number has +/- signs
  let numberHasSign = number.startsWith("-") || number.startsWith("+");
  let sign = numberHasSign ? number[0] : "";

  // Removing the sign to be able to work with the actual number
  number = numberHasSign ? number.replace(sign, "") : number;

  // This regex finds leading zeros before the period
  let match = number.match(FIND_FRONT_ZEROS_SIGN_DOT_EXP);

  if (match && match[0].includes("0.")) {

    /**
     * This scenario handles input numbers start with leading 0 or more before the period/dot
     * like 00. 000. 0.000123. Steps to process such number are:
     * Example to work the following steps through (00.0215) and significant digits to report are 1
     * 1- Remove the period/dot along with any leading zeros as they are not part of the
     * significant digits. We will remove 00.0
     * 2- Get part of the number after the period/dot to process within given significant digits.
     * Number will be 215
     * 3- Getting the number from step 2 to the reported significant digits which are 1 and we keep one more digit
     * so we need to keep 2 significant digits from 215 which will be 21
     * 4- Reconstruct the number by adding the initial removed part which is 00.021
     * 5- Expected result is 00.021
     */
    let correctedNumber = number.replace(".", "").replace(/^0+/, "");
    correctedNumber = correctedNumber.substring(0, significantFiguresToReport + 1);
    correctedNumber = exports.zeroPadding(correctedNumber, significantFiguresToReport + 1, false);

    number = `${match[0]}${correctedNumber}`;
  } else if (number.includes(".")) {
    /**
     * This scenario handles any other decimal numbers. Numbers like 125.0215 12.36 12.2140
     * Steps to process such number are:
     * Example to work the following steps through (125.0215) and significant digits to report are 4
     * 1- Remove the period/dot along with any leading zeros as they are not part of the
     * significant digits. Number will be 1250215
     * 2- Getting the number from step 1 to the reported significant digits which are 4 and we keep one more digit
     * so we need to keep 5 significant digits from 1250215 which will be 12502
     * 3- Reconstruct the number by adding the period/dot in the correct place.
     * 4- Expected result is 125.02
     */

    number = number.replace(/^0+/, "");

    /**
     * Getting index of the period/dot as we may need to remove it and put
     * it back while processing the number.
     */
    let periodIndex = number.indexOf(".");

    /**
     * If the number of significant figures to report is less than number of significant figures of the number before the period
     * then reporting the rounded number will be different. ex. 21320 has 5 significant figures and we need to report just 2 then
     * result should be 21300 as we report one more significant digit.
     */
    let numberBeforePeriod = number.substring(0, periodIndex);
    let numberSignificantFigures = exports.getNumberOfSignificantFigures(numberBeforePeriod);
    if (significantFiguresToReport < numberSignificantFigures) {
      number = Number(number).toPrecision(significantFiguresToReport + 1);
      number = exports.scientificToDecimal(number);
    } else {
      number = number.replace(".", "");

      if (number.length < significantFiguresToReport + 1) {
        number = exports.zeroPadding(number, significantFiguresToReport + 1, false);
      } else {
        number = number.substring(0, significantFiguresToReport + 1);
      }
    }

    // Sometimes the number will end to be something like 125. so we check if the final char is . and remove it
    let isThereTextAfterDigit = !!number.slice(periodIndex);
    number = `${number.slice(0, periodIndex)}${isThereTextAfterDigit ? `.${number.slice(periodIndex)}` : ""}`;
  } else {
    /**
     * This scenario handles any other integers like 1 22 1235 02145 029
     * Steps to process such number are:
     * Example to work the following steps through (002915) and significant digits to report are 2
     * 1- Remove any leading zeros as they are not part of the significant digits
     * 2- Getting the number from step 1 to the reported significant digits which are 2 and we keep one more digit
     * so we need to keep 3 significant digits from 2915 which will be 291
     * 3- Reconstruct the number by adding the leading zeros if any.
     * 4- Expected result is 00291
     * NB: This is not a realistic example as user asked to report significant digits less than the least which
     * won't happen in any of our situations but this function follows exactly the documentations.
     */
    number = Number(number).toPrecision(significantFiguresToReport + 1);
    number = exports.scientificToDecimal(number);
  }

  // Reconstruct the number with the sign if exist
  return numberHasSign ? `${sign}${number}` : number;
};

/**
 * This function is used to get the right number to show in UI according to FDA guidelines.
 * Reference: https://www.fda.gov/media/73535/download - Section 4.3.1 - Rounding of Reported Data
 * If number is 12.98732 and as last digit < 5 then we drop that digit and report 12.9873
 * If number is 12.98736 and as last digit > 5 then we increase the digit before last digit with 1 and report 12.9874
 * If number is 12.98735 and as last digit = 5 and digit before 5 is odd then we increase that digit by 1 and report 12.9874
 * If number is 12.98725 and as last digit = 5 and digit before 5 is even then we drop last digit and report 12.9872
 * @param number input number to get FDA corrected format for display
 * This can be used is something like chart reports where we calculate average and std on the fly and not from a saved value in the database.
 * @return string with correct digit numbers
 */
module.exports.getFDAReportedRoundedNumber = function(number) {

  // Converting input to string and removing any white spaces
  number = String(number).trim();

  // If number is a single digit +/- integer we return it as-is.
  let singleDigitIntegers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "-1", "-2", "-3", "-4", "-5", "-6", "-7", "-8", "-9"];

  if (!isFinite(Number(number))) {
    throw new TypeError(`Number (${number}) has an invalid format.`);
  } else if (mathjs.isZero(number)) {
    return "0";
  } else if (singleDigitIntegers.includes(number)) {
    return number;
  }

  // Converting scientific input to regular number
  number = exports.scientificToDecimal(number);

  // Check if the input number has +/- signs
  let numberHasSign = number.startsWith("-") || number.startsWith("+");
  let sign = numberHasSign ? number[0] : "";

  // Removing the sign to be able to work with the actual number
  number = numberHasSign ? number.replace(sign, "") : number;

  // Number of significant figures for the input number
  let numberOfSignificantFigures = exports.getNumberOfSignificantFigures(number);

  /**
   * This is the significant digits to report in the displayed number. In case we have
   * value of 1.005 that has 4 significant digits and according to FDA guidelines last digit will be dropped
   * and number will be 1.00 then we need to report it as 1.000 to maintain the number of significant
   * digits to be reported.
   */
  let significantFiguresToReport = numberOfSignificantFigures - 1;

  /**
   * Guidelines are more into decimal numbers. In case of integers we just return the number as is for display.
   */
  if (number.includes(".")) {
    // Getting last digit of the number as that will be used for rest of the processing.
    let lastDigit = number.substring(number.length - 1, number.length);

    /**
     * Getting index of the period/dot as we may need to remove it and put
     * it back while processing the number.
     */
    let periodIndex = number.indexOf(".");

    /**
     * Getting the length of the digits after the period/dot. This will be used
     * to round the number into the right precision.
     */
    let fractionLength = number.substring(number.indexOf(".") + 1, number.length).length;

    if (lastDigit === ".") {
      /**
       * This scenario just handles edge case where a number is passed like 125. and
       * in that case this is considered as an integer and we just need to remove the
       * unneeded unused period/dot and report the actual integer number.
       */
      number = number.substring(0, number.length - 1);
    } else if (Number(lastDigit) > 5) {
      /**
       * In this scenario FDA says the following: If the extra digit is greater than 5, drop it and increase the previous digit by one.
       * For example if number is 1.256 then resulted number should be 1.26. But we need to maintain the significant digits to report
       * as well. So in case of a number like 1.996 resulted number should be 2.00 not just 2.
       */

      // Digit before the number > 5 to see if it's a period/dot or a 9 or something else
      let digitBeforeLastDigit = number.substring(number.length - 2, number.length - 1);
      if (digitBeforeLastDigit === ".") {
        // Scenario 1: If the number has one digit after the period/dot then according to FDA we should increase it by 1
        let numberBeforePeriod = number.substring(0, periodIndex);
        number = Number(numberBeforePeriod) + 1;
      } else {
        /**
         * Scenario 2: There are more than 1 digit after the period/dot. This has 3 scenarios
         * 1- The digit equals 9 then we use the toFixed() and make sure reported significant digits are correct.
         * 2- The digit is less than 9 then we just increase it by 1.
         */
        if (digitBeforeLastDigit === "9") {
          number = parseFloat(number).toFixed(fractionLength - 2);
          number = exports.zeroPadding(number, significantFiguresToReport);
        } else {
          number = `${number.substring(0, number.length - 2)}${Number(digitBeforeLastDigit) + 1}`;
        }
      }
    } else if (Number(lastDigit) < 5) {
      /**
       * In this scenario FDA says the following: If the extra digit is less than 5, drop the digit.
       * There are 2 cases we handle here
       * 1- If the number has one digit after the period/dot like 1.3 so according to FDA final result should be 1. So we do
       * that condition {fractionLength <= 1 ? number.length - 2} so we bypass both the digit and the period/dot
       * 2- If number has more than 1 digit after the period/dot like 1.2353 so according to FDA final result should be 1.235
       */
      number = number.substring(0, fractionLength <= 1 ? number.length - 2 : number.length - 1);
    } else if (Number(lastDigit) === 5) {
      /**
       * In this scenario FDA says the following: If the extra digit is five, then increase the previous digit by one if it is odd;
       * otherwise do not change the previous digit.
       */

      // Digit before the 5 to test if odd, even or if 5 is the only digit and we need to increase the number before the period or trim the 5
      let digitBeforeLastDigit = number.substring(number.length - 2, number.length - 1);
      if (digitBeforeLastDigit === ".") {
        /**
         * Scenario 1: If the number has one digit after the period/dot which is the 5 123.5 then according to FDA we should increase 123
         * by 1 as 3 is odd. Resulted number should be 124. We check the digitBeforeLastDigit to be able to increase the number before the period by 1 if odd
         * or leave it if even.
         */
        let numberBeforePeriod = number.substring(0, periodIndex);
        if (exports.isOdd(numberBeforePeriod)) {
          number = Number(numberBeforePeriod) + 1;
        } else {
          number = Number(numberBeforePeriod);
        }
      } else {
        /**
         * Scenario 2: There are more than 1 digit after the period/dot. This has 3 scenarios
         * 1- The digit is odd and equals 9 then we use the toFixed() and make sure reported significant digits are correct. ex. 123.2395 will be 123.240
         * 2- The digit is odd but less than 9 then we just increase it by 1. ex. 123.2355 will be 123.236
         * 3- The digit before the last 5 is even then we drop the 5 and report rest of the number. ex. 123.2345 will be 123.234
         */
        if (exports.isOdd(digitBeforeLastDigit)) {
          if (digitBeforeLastDigit === "9") {
            number = parseFloat(number).toFixed(fractionLength - 2);
            number = exports.zeroPadding(number, significantFiguresToReport);
          } else {
            number = `${number.substring(0, number.length - 2)}${Number(digitBeforeLastDigit) + 1}`;
          }
        } else {
          number = number.substring(0, number.length - 1);
        }
      }
    }
  } else {
    // In case of integers, number is rounded to the .
    number = Number(number).toPrecision(significantFiguresToReport);
    number = exports.scientificToDecimal(number);
  }

  // Reconstruct the number with the sign if exist
  return numberHasSign ? `${sign}${number}` : number.toString();
};

/**
 * This function right pads the given number with zeros
 * @param number to be padded
 * @param size of the final number
 * @param addPeriod option to remove the period
 * @returns {*} number after padding
 */
module.exports.zeroPadding = function(number, size, addPeriod = true) {
  number = !number.includes(".") && exports.getNumberOfSignificantFigures(number) < size && addPeriod ? `${number}.` : number;
  while (exports.getNumberOfSignificantFigures(number) < size) {
    number = number + "0";
  }
  return number;
};

/**
 * This function checks if a given number is even or odd.
 * @param number to check its type
 * @returns {boolean} either number is odd or not.
 */
module.exports.isOdd = function(number) {
  return !!(Number(number) % 2);
};

/**
 * This function is used to return back number fixed to certain number of digits.
 * It turns the string output of toFixed to a number to be used in places like chart reports.
 * @param number to report toFixed for.
 * @param digits number of digits to report input number fixed to.
 * @returns {number}
 */
module.exports.getToFixed = function(number, digits) {
  return Number(parseFloat(number).toFixed(digits));
};

/**
 * This function converts scientific numbers to decimal number
 * Reference: https://gist.github.com/jiggzson/b5f489af9ad931e3d186
 * @param number scientific number
 * @returns {*}
 */
module.exports.scientificToDecimal = function(number) {
  let numberHasSign = number.startsWith("-") || number.startsWith("+");
  let sign = numberHasSign ? number[0] : "";
  number = numberHasSign ? number.replace(sign, "") : number;

  //if the number is in scientific notation remove it
  if (SCIENTIFIC_NUMBER_REGEX.test(number)) {
    let zero = "0";
    let parts = String(number).toLowerCase().split("e"); //split into coeff and exponent
    let e = parts.pop();//store the exponential part
    let l = Math.abs(e); //get the number of zeros
    let sign = e / l;
    let coeff_array = parts[0].split(".");

    if (sign === -1) {
      coeff_array[0] = Math.abs(coeff_array[0]);
      number = zero + "." + new Array(l).join(zero) + coeff_array.join("");
    } else {
      let dec = coeff_array[1];
      if (dec) l = l - dec.length;
      number = coeff_array.join("") + new Array(l + 1).join(zero);
    }
  }

  return `${sign}${number}`;
};
