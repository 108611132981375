"use strict";

import React from "react";
import BaseFinalAttributeListTable from "./base_final_attribute_list";
import * as I18NWrapper from "../../i18n/i18n_wrapper";

/* This shows the table of FPAs when viewing the QTPP. */

// i18next-extract-mark-ns-start qtpp
class FPAList extends BaseFinalAttributeListTable {
  constructor(props) {
    super(props, "FPA", true, false);
  }
}
export default I18NWrapper.wrap(FPAList, ["qtpp", "base_page"]);
// i18next-extract-mark-ns-stop qtpp
