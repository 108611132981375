"use strict";

import MultipleTypeaheadObjectCache from "../../utils/cache/multiple_typeahead_object_cache";
import {
  ALL_TYPES_FOR_TYPEAHEADS, EMPTY_STATE,
  PROCESS_CONSTANTS, PROCESS_LEVEL_ID,
  PROJECT_TO_PROCESS_COMPARISONS
} from "../tech_transfer_constants";
import { getEffectiveRMPForDate, loadRMP } from "../../helpers/risk_helper";
import * as UIUtils from "../../ui_utils";
import { TECH_TRANSFER_MODELS_CONFIG } from "../tech_transfer_config";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import ConfigurableTablesColumnSelectionHandler
  from "../../configurableTables/columnSelection/configurable_tables_column_selection_handler";
import BaseReactComponent from "../../base_react_component";
import { pushHistoryURLWithParameterChanges } from "../../configurableTables/tables/configurable_tables_helper";
import { SENDING_SITE_LABEL } from "../../configurableTables/fieldsConfig/constants/configurable_tables_columns_config";
import {
  COLUMN_SELECTION_ONLY_SECTIONS
} from "../../configurableTables/fieldsConfig/constants/configurable_tables_sections_config";

const Logger = Log.group(LOG_GROUP.ProcessExplorer, "TechTransfer");

export default class TechTransferCommonHandler extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.parent = props.parent;
    this.projectId = props.projectId;
    this.columnSelectionHandler = new ConfigurableTablesColumnSelectionHandler(props, true,
      "defaultTechTransferValue");
  }

  async handleReceivedDataFromServer(loadFromProcessTypeaheadPromise, loadToProcessTypeaheadPromise, techTransferResults) {
    await Promise.all([loadFromProcessTypeaheadPromise, loadToProcessTypeaheadPromise]);

    Logger.info(() => "Received results:", Log.object(techTransferResults));

    const {fromProcessId, toProcessId} = this.parent.state;
    const processes = new TypeaheadObjectCache("Process", this.projectId).getOptionsFromCache();
    const toProcess = processes.find(process => process.id === toProcessId);
    const fromProcess = processes.find(process => process.id === fromProcessId);
    let changeCriteria = techTransferResults.changeCriteria;
    changeCriteria = this.checkAndSetDefaultValuesForChangeCriteria(changeCriteria);

    if (toProcess && toProcess.SendingId && toProcess.SendingId !== fromProcessId) {
      const correctFromProcess = processes.find(process => process.id === toProcess.SendingId);
      UIUtils.showError(`You have chosen a process, ${UIUtils.getRecordCustomLabelForDisplay(toProcess)}, that is not receiving from ${UIUtils.getRecordCustomLabelForDisplay(fromProcess)}. Choose to receive from ${UIUtils.getRecordCustomLabelForDisplay(correctFromProcess)} instead.`);
      this.parent.setStateSafely(EMPTY_STATE);
    } else {
      this.parent.setStateSafely(
        {
          results: techTransferResults,
          toProcess,
          fromProcess,
          changeCriteria: changeCriteria,
          ...this.getAdaptedTechTransferInfo(techTransferResults, null, changeCriteria),
        }, this.columnSelectionHandler.handleResetColumnVisibility);
    }
  }

  checkAndSetDefaultValuesForChangeCriteria(changeCriteria) {
    const finalChangeCriteria = [];

    for (let modelName in TECH_TRANSFER_MODELS_CONFIG) {
      const techTransferConfig = TECH_TRANSFER_MODELS_CONFIG[modelName];
      const existingChangeCriteria = changeCriteria && changeCriteria.find(changeCriteriaItem => changeCriteriaItem.modelName === UIUtils.getTypeCodeForModelName(techTransferConfig.modelName));
      if (existingChangeCriteria) {
        finalChangeCriteria.push(existingChangeCriteria);
        continue;
      }

      const fieldsConfig = techTransferConfig.fieldsConfig;
      let fields = fieldsConfig.fields.filter(field =>
        field.originatedFromSite === SENDING_SITE_LABEL &&
        !COLUMN_SELECTION_ONLY_SECTIONS.includes(field.section)
      );

      finalChangeCriteria.push({
        modelName: UIUtils.getTypeCodeForModelName(techTransferConfig.modelName),
        config: fields.filter(field => field.defaultChangeCriteriaValue).map(field => field.prop)
      });
    }

    return finalChangeCriteria;
  }

  createFilterByUOFunction(unitOperationId) {
    return row => {
      const techTransferredFrom = row.techTransferredFrom;
      if (techTransferredFrom) {
        if (Array.isArray(techTransferredFrom.UnitOperations)) {
          return techTransferredFrom.UnitOperations.find(uo => uo.id === unitOperationId) || techTransferredFrom.UnitOperations.length === 0;
        } else if (techTransferredFrom.unitOperationId) {
          return techTransferredFrom.unitOperationId === unitOperationId || techTransferredFrom.unitOperationId === PROCESS_LEVEL_ID;
        }
      }
    };
  }

  handleFilterFieldChange(fieldName, event) {
    const selectedId = UIUtils.parseInt(event.target ? event.target.value : event);
    if (selectedId !== this.parent.state[fieldName]) {
      this.parent.setStateSafely({[fieldName]: selectedId});
    }
  }

  initializeAndLoadData(fromProcessId, toProcessId, unitOperationId, selectedModelType) {
    const loadFromProcessTypeaheadPromise = new MultipleTypeaheadObjectCache(ALL_TYPES_FOR_TYPEAHEADS, this.projectId, fromProcessId)
      .loadOptions(this.handleTypeaheadResults).promise();
    const loadToProcessTypeaheadPromise = new MultipleTypeaheadObjectCache(ALL_TYPES_FOR_TYPEAHEADS, this.projectId, toProcessId)
      .loadOptions(this.handleTypeaheadResults).promise();

    this.parent.setStateSafely({
      fromProcessId,
      toProcessId,
      unitOperationId,
      selectedModelType,
    }, () => {
      this.rememberProcessComparisons();
      this.loadTechTransferData(loadFromProcessTypeaheadPromise, loadToProcessTypeaheadPromise);
    });
  }

  handleTypeaheadResults(typeaheadOptions, typeCode) {
    if (typeCode === "PR") {
      this.parent.setStateSafely({
        processesSelection: typeaheadOptions,
      });
    } else {
      this.parent.setStateSafely({
        typeaheadOptions: [...this.parent.state.typeaheadOptions, ...typeaheadOptions],
      });
    }
  }

  loadTechTransferData(loadFromProcessTypeaheadPromise = Promise.resolve(), loadToProcessTypeaheadPromise = Promise.resolve()) {
    const {fromProcessId, toProcessId} = this.parent.state;
    if (fromProcessId && toProcessId && fromProcessId !== toProcessId) {
      loadRMP((rmp, project) => {
        let effectiveTechTransferRMP;
        if (rmp) {
          effectiveTechTransferRMP = getEffectiveRMPForDate(project, rmp.approvedVersionsWithDetails, this.parent.state.reportDate, "TT");
        }

        this.parent.setStateSafely(
          {
            effectiveRMP: rmp,
            effectiveTechTransferRMP,
          }, () => {
            let params = {
              processIds: this.parent.state.fromProcessId !== this.parent.state.toProcessId ?
                [fromProcessId, toProcessId].join(",") :
                toProcessId,
              shouldPreferApproved: false,
              latestVersion: true,
            };

            UIUtils.secureAjaxGET(`techTransfer/${this.projectId}?${UIUtils.objectToURLParameters(params)}`)
              .done(this.handleReceivedDataFromServer.bind(this, loadFromProcessTypeaheadPromise, loadToProcessTypeaheadPromise));
          });
      });
    }
  }

  getAdaptedTechTransferInfo(techTransferResults, selectedModelType, changeCriteria) {
    let {
      fromProcessId,
      toProcessId,
      typeaheadOptions,
      unitOperationId,
      effectiveRMP,
      effectiveTechTransferRMP,
      results
    } = this.parent.state;

    selectedModelType = selectedModelType || this.parent.state.selectedModelType;
    changeCriteria = changeCriteria || this.parent.state.changeCriteria;
    techTransferResults = techTransferResults || results;

    const techTransferResultsAdapter = TECH_TRANSFER_MODELS_CONFIG[selectedModelType]
      .adapter(
        {
          techTransferResults,
          fromProcessId,
          toProcessId,
          typeaheadOptions,
          effectiveRMP,
          effectiveTechTransferRMP,
          selectedModelType,
          changeCriteria,
        }
      );

    const originalTechTransferRows = techTransferResultsAdapter.getRows();

    // Find the unit operation Id and filter the results by it, if it exists.
    const orderedUOList = techTransferResultsAdapter.orderedUOList;
    if (!unitOperationId && orderedUOList.length > 0) {
      unitOperationId = orderedUOList[0].id;
    }

    let techTransferRows = unitOperationId === "All" ? originalTechTransferRows : [];
    if (unitOperationId && unitOperationId !== "All") {
      techTransferRows = originalTechTransferRows.filter(this.createFilterByUOFunction(unitOperationId));
    }

    return {
      processes: techTransferResults.processes,
      orderedUOList,
      unitOperationId,
      originalTechTransferRows,
      techTransferRows,
    };
  }

  rememberProcessComparisons() {
    let projectToProcessComparisons = localStorage[PROJECT_TO_PROCESS_COMPARISONS];
    if (projectToProcessComparisons) {
      projectToProcessComparisons = JSON.parse(projectToProcessComparisons);
    } else {
      projectToProcessComparisons = {};
    }

    const {fromProcessId, toProcessId} = this.parent.state;
    if (fromProcessId && toProcessId && fromProcessId !== toProcessId) {
      projectToProcessComparisons[this.projectId] = {
        [PROCESS_CONSTANTS.FROM]: fromProcessId,
        [PROCESS_CONSTANTS.TO]: this.parent.state.toProcessId,
      };

      localStorage[PROJECT_TO_PROCESS_COMPARISONS] = JSON.stringify(projectToProcessComparisons);
      pushHistoryURLWithParameterChanges({
        fromProcessId: this.parent.state.fromProcessId,
        toProcessId: this.parent.state.toProcessId,
      });
    }
  }

}
