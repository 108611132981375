"use strict";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import CommonEditablesFormatter from "../../../../../server/common/editables/common_editables_formatter";

export const SpecificationCell = ({dataItem, icon}) => {
  const dataValue = dataItem["name"] === null ? "" : dataItem["name"];
  const dataValue2 = CommonEditablesFormatter.getAttributeMeasurementInfoTechTransferFormat(dataItem);
  return (
    <td>
      <div className="specification-cell-outer-div">
        <span className="specification-cell-value">{dataValue}</span>
        <span className="specification-cell-secondary">{dataValue2}</span>
        <FontAwesomeIcon icon={icon} />
      </div>
    </td>
  );
};