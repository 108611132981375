"use strict";

import React, { Fragment } from "react";
import BaseReactComponent from "../../base_react_component";
import LabelTooltip from "./label_tooltip";

/* This shows any large text with a see/read more link */

export default class ShowMoreTooltip extends BaseReactComponent {
  render() {
    const {text, id} = this.props;
    const words = text ? text.split(" ") : [];
    const textToShow = words.length > 30 ?
      <Fragment>
        {words.splice(0, 30).join(" ")}
        <br />
        <LabelTooltip id={id}
                      noColon={true}
                      tooltipText={text}
                      moreInfo={<a onClick={() => {}}>Show more</a>}
        />
      </Fragment> :
      text;

    return (
      <div id={id}>
        {textToShow}
      </div>
    );
  }
}
