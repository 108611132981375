/**
 * @hidden
 */
export var EMPTY_ID = '';
/**
 * @hidden
 */
export var ZERO_LEVEL_ZERO_ITEM_ID = '0';
/**
 * @hidden
 */
export var SEPARATOR = '_';
/**
 * @hidden
 */
export function getItemById(itemId, items) {
    if (isIdZeroLevel(itemId)) {
        return items[Number(itemId)];
    }
    else {
        var rootParentItem = items[Number(getRootParentId(itemId))];
        return rootParentItem.items ?
            getItemById(getIdWithoutRootParentId(itemId), rootParentItem.items) : undefined;
    }
}
/**
 * @hidden
 */
export function getRootParentId(itemId) {
    return isIdEmptyOrZeroLevel(itemId) ? itemId : itemId.split(SEPARATOR)[0];
}
/**
 * @hidden
 */
export function getIdWithoutRootParentId(itemId) {
    if (isIdEmptyOrZeroLevel(itemId)) {
        return itemId;
    }
    else {
        var firstSeparatorIndex = itemId.indexOf(SEPARATOR);
        return itemId.substring(firstSeparatorIndex + 1);
    }
}
/**
 * @hidden
 */
export function getFirstChildId(itemId) {
    return createId('0', itemId);
}
/**
 * @hidden
 */
export function shouldOpenItem(itemId, lastItemIdToBeOpened) {
    if (lastItemIdToBeOpened.indexOf(itemId) === 0) {
        return lastItemIdToBeOpened.length === itemId.length ||
            lastItemIdToBeOpened.charAt(itemId.length) === SEPARATOR;
    }
    else {
        return false;
    }
}
/**
 * @hidden
 */
export function createId(childId, parentId) {
    return parentId ? parentId + SEPARATOR + childId : childId;
}
/**
 * @hidden
 */
export function getDirectParentId(itemId) {
    var lastSeparatorIndex = itemId.lastIndexOf(SEPARATOR);
    return lastSeparatorIndex < 0 ? EMPTY_ID : itemId.substring(0, lastSeparatorIndex);
}
/**
 * @hidden
 */
export function isIdEmptyOrZeroLevel(itemId) {
    return itemId === EMPTY_ID || itemId.indexOf(SEPARATOR) < 0;
}
/**
 * @hidden
 */
export function isIdZeroLevel(itemId) {
    return itemId !== EMPTY_ID && itemId.indexOf(SEPARATOR) < 0;
}
/**
 * @hidden
 */
export function isIdFirstLevel(itemId) {
    return getSeparatorOccurances(itemId) === 1;
}
/**
 * @hidden
 */
export function isFirstItemFromSiblings(itemId) {
    return getShortId(itemId) === ZERO_LEVEL_ZERO_ITEM_ID;
}
/**
 * @hidden
 */
export function getShortId(itemId) {
    var lastSeparatorIndex = itemId.lastIndexOf(SEPARATOR);
    return lastSeparatorIndex < 0 ? itemId : itemId.substring(lastSeparatorIndex + 1);
}
/**
 * @hidden
 */
export function getDirectSiblingIdForLevelZero(next, shortItemId, siblingsCount) {
    if (!isIdZeroLevel(shortItemId)) {
        return shortItemId;
    }
    return next ?
        Number(shortItemId) < siblingsCount - 1 ? (Number(shortItemId) + 1).toString() : '0' :
        Number(shortItemId) > 0 ? (Number(shortItemId) - 1).toString() : (siblingsCount - 1).toString();
}
function getSeparatorOccurances(itemId) {
    return itemId.split(SEPARATOR).length - 1;
}
