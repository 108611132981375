"use strict";

import React from "react";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import Section from "../../editor/widgets/section";
import TechTransferTypeaheadAttribute from "./tech_transfer_typeahead_attribute";
import RelatedDnDRecordsPanel from "../../widgets/relatedRecords/dnd/related_dnd_records_panel";
import * as UIUtils from "../../ui_utils";
import { CommonUtils } from "../../../server/common/generic/common_utils";
import { ProcessParameterIndexer } from "../indexers/process_parameter_indexer";
import BaseAttributeParent from "./base_attribute_parent";
import MemoryCache from "../../utils/cache/memory_cache";
import { RelatedPanelNotice } from "../../editor/widgets/related_panel_notice";

/**
 * This contains common methods needed by operation records (Steps/UOs).
 *  @abstract
 */
// i18next-extract-mark-ns-start process_explorer
export default class BaseOperationsEditor extends BaseAttributeParent {
  constructor(props, baseTypeName, capitalizedBaseTypeName, displayName) {
    super(props, baseTypeName, capitalizedBaseTypeName, displayName);

    this.setStateSafely({
      isSavingReorderInProgress: false, // Not necessary, but just so a future reader knows this state is being tracked in this class.
    });
  }

  /**
   * This triggers every time a typeahead is loaded. Once all typeaheads are loaded, then we search
   *      and save in the state all child attributes of the selected parent record. This should happen only
   *      once though, when the last typeahead is loaded.
   */
  handleAllTypeaheadsLoaded() {
    let childAttributes = this.getAllAttributesFromTypeaheads().filter(this.filterChildAttribute);
    this.updateChildAttributes(childAttributes);
  }

  /**
   * This is a filter function that determines if a child attribute is a child of the currently selected record.
   * @param childAttribute
   * @returns {boolean}
   */
  filterChildAttribute(childAttribute) {
    return childAttribute[this.getForeignKeyId()] === this.getAttributeParentIdValue();
  }

  /**
   * Updates the page state with the child attributes and other related information.
   * @param childAttributes
   */
  updateChildAttributes(childAttributes) {
    this.setStateSafely({
      childAttributes,
    });
  }

  getURLToLoadData(shouldShowApproved) {
    return `${super.getURLToLoadData(shouldShowApproved)}&includeRecordOrder=true`;
  }

  getForeignKeyId() {
    return this.capitalizedBaseTypeName + "Id";
  }

  handleClearTechTransferInfoConfirmation() {
    $(this.clearTechTransferInfoConfirmationPopup).modal("hide");
    this.setStateSafely({
      TransferredFromId: "None",
      techTransferInformationCleared: true,
      dataModified: true,
    });
  }

  hasRelatedItemsTab() {
    return true;
  }

  handleSaveReorder(records) {
    this.setStateSafely({processParameters: records});
    const recordIdOrder = records.map(record => record.id);
    recordIdOrder[this.getForeignKeyId()] = this.getAttributeParentIdValue();

    const recordOrder = CommonUtils.deepClone(this.state.RecordOrder) || {[this.getForeignKeyId()]: this.getAttributeParentIdValue()};
    recordOrder.processParameterOrder = JSON.stringify(recordIdOrder);
    recordOrder.projectIdNotRequired = true;

    this.setStateSafely({
      RecordOrder: recordOrder,
      isSavingReorderInProgress: true,
    });

    UIUtils.secureAjaxPUT("entities/recordOrder/addOrEdit", recordOrder).done((newOrUpdatedRecordOrder) => {
      this.setStateSafely({
        RecordOrder: {
          ...recordOrder,
          id: newOrUpdatedRecordOrder.id
        },
        isSavingReorderInProgress: false,
      });

      // Tell listeners, like the Process Explorer Page, to update if need be.
      if (this.props.eventListeners && this.props.eventListeners.onSaveCompleted) {
        this.props.eventListeners.onSaveCompleted(this.state);
      }
    });
  }

  renderSidePanelWidget() {
    const {t} = this.props;
    const {processParameters, isSavingReorderInProgress} = this.state;
    const recordOrder = ProcessParameterIndexer.getProcessParameterOrder(this.state);
    const records = (processParameters || []).filter(pp => pp);
    if (processParameters) {
      return (
        <>
          <RelatedPanelNotice isApproved={this.isApproved()} />
          <RelatedDnDRecordsPanel id={`${this.baseTypeName}ProcessParametersRelatedRecords`}
                                  header={t("Process Parameters")}
                                  modelName="ProcessParameter"
                                  projectId={this.getProjectId()}
                                  processId={this.getProcessId()}
                                  parent={this}
                                  parentId={this.state.id}
                                  records={[...records]}
                                  recordOrder={recordOrder}
                                  onSaveReorder={this.handleSaveReorder}
                                  isSavingReorderInProgress={isSavingReorderInProgress}
                                  isLoading={this.isLoading()}
          />
        </>
      );
    }
  }

  /**
   * @return {any|*[]} the order of the process parameters for the side panel.
   */
  getProcessParameterRecordOrder() {
    const {RecordOrder} = this.state;
    let recordOrder = RecordOrder?.processParameterOrder || [];
    return typeof recordOrder === "string" ? JSON.parse(recordOrder) : recordOrder;
  }

  renderTechTransferAssessmentSection() {
    let {
      ProjectId,
      ProcessId,
      showClearTechTransferInfoConfirmation,
    } = this.state;

    this.memoryCacheForProcessInTechTransfer = MemoryCache.getNamedInstance("base_operations_editor");
    const cacheKey = `process_${ProcessId}`;
    let process = this.memoryCacheForProcessInTechTransfer.get(cacheKey);
    if (!process) {
      process = new TypeaheadObjectCache("Process", ProjectId).getOptionsFromCache().find(process => process.id === ProcessId);
      if (process) {
        this.memoryCacheForProcessInTechTransfer.set(cacheKey, process);
      }
    }

    return process && process.SendingId ? (
      <Section id="techTransfer"
               parent={this}
               header={<span>Tech Transfer Assessment</span>}
               headerLink={this.renderClearTechTransferInfoButton()}
      >
        {showClearTechTransferInfoConfirmation ?
          this.renderClearTechTransferInfoConfirmationPopup()
          : ""}
        <div className="row">
          <TechTransferTypeaheadAttribute name="transferredFrom"
                                          default={this.getTransferredFromId()}
                                          typeaheadType={this.baseTypeName}
                                          className="col-sm-6"
                                          getProcessId={() => process ? process.SendingId : null}
                                          parent={this}
          />
        </div>
      </Section>) : "";
  }
}
