"use strict";

import React, { Fragment } from "react";
import * as UIUtils from "../../ui_utils";
import { measurePerformanceEnd, measurePerformanceStart } from "../../ui_utils";
import BaseQuickEditor from "../../editor/base_quick_editor";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import {
  getRiskFieldOptions,
  getRiskFieldTooltip,
  getRiskScale,
  getRiskScaleTooltip,
} from "../../helpers/risk_helper";
import { RISK_TYPE_ENUM } from "../../helpers/constants/constants";
import Section from "../../editor/widgets/section";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import TechTransferComboBoxAttribute from "./tech_transfer_combo_box_attribute";
import TextAreaAttribute from "../../editor/attributes/text_area_attribute";
import CalculatedRiskAttribute from "../../editor/attributes/calculated_risk_attribute";
import LabelTooltip from "../../widgets/tooltips/label_tooltip";
import CommonRiskUtils from "../../../server/common/misc/common_risk_utils";
import { TECH_TRANSFER_ASSESSMENT_STATUSES, TOOLTIP_HEADERS } from "../../techTransfer/tech_transfer_constants";
import ConfirmationPopup from "../../widgets/generic/confirmation_popup";
import TechTransferTypeaheadAttribute from "./tech_transfer_typeahead_attribute";
import { VARIABLE_PARENTS } from "../../configurableTables/tables/configurable_tables_constants";
import { TYPE_CODE } from "../process_explorer_constants";
import { valueExists } from "../../../server/common/generic/common_utils";
import DataLoader from "../recordLoader/data_loader";
import RecordLoader from "../recordLoader/record_loader";
import StaticPanelHelper from "../utils/static_panel_helper";
import MemoryCache from "../../utils/cache/memory_cache";

/**
 * This contains common methods needed by records that have fields to track Tech Transfer (and hence may or may not
 * contain a TT section).
 *
 *  @abstract
 */
// i18next-extract-mark-ns-start process_explorer
export default class BaseTechTransferAttribute extends BaseQuickEditor {

  constructor(props, baseTypeName, capitalizedBaseTypeName, displayName) {
    super(props, baseTypeName, capitalizedBaseTypeName, displayName);
    this.processExplorerHelper = props.processExplorerHelper;
    const initialState = {sendingTypeaheadOptions: []};
    if (this.processExplorerHelper) {
      initialState.processExplorerHelperIsLoaded = true;
    }

    this.setStateSafely(initialState);
  }

  loadTypeAheadFilterRequirements() {
    if (this.state.isLoadingProcessExplorerData ||
      this.state.processExplorerHelperIsLoaded ||
      !this.state.ProjectId ||
      !this.state.ProcessId) {
      return;
    }

    this.setStateSafely({
      processId: this.state.ProcessId,
      isLoadingProcessExplorerData: true
    });

    const parent = this;
    const commonProps = {parent, projectId: this.state.ProjectId};
    this.dataReceiver = {
      handleReceivedDataFromServer: (useWriterDB, loadTypeaheadPromise, results) => {
        const process = results.processes[0];

        let allRecords = StaticPanelHelper.getRecordsFromProcessExplorerResult(results, process.id);
        parent.recordLoader = new RecordLoader(allRecords, this.state.ProjectId, useWriterDB, process.SendingId);
        parent.recordLoader.startLoading().then((result) => {
          parent.setStateSafely({
            isLoadingProcessExplorerData: false,
            isLoading: false,
            processExplorerHelperIsLoaded: true,
            keyToFullRecordMap: result
          });
        });
      }
    };

    this.processExplorerHelper = {
      findStaticPanelRecord: (typeCode, id) => {
        let record;
        if (parent.recordLoader.isLoading()) {
          record = parent.recordLoader.findRecordIfLoaded(typeCode + "-" + id);
        } else {
          record = parent.state.keyToFullRecordMap.get(typeCode + "-" + id);
        }
        return record;
      }
    };

    const dataLoader = new DataLoader(commonProps);
    dataLoader.loadProcessExplorerData(true, Promise.resolve(true));
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
  }

  /**
   * The default is the id of the cloned from instance unless the user
   * has overwritten this by selecting different option from the TransferredFrom typeahead.
   */
  getTransferredFromId() {
    const {TransferredFromId, clonedFrom, id} = this.state;
    return TransferredFromId ? TransferredFromId === id ? null : TransferredFromId || (clonedFrom ? clonedFrom[`${this.capitalizedBaseTypeName}Id`] : undefined) : null;
  }

  /**
   * This function Fetched the id of the sending record given the receiving record
   * @param {array} parents The receiving records.
   * @returns {int} the id of the sending record
   */
  checkForSendingRecord(parents) {
    for (const parent of parents) {
      const record = this.processExplorerHelper.findStaticPanelRecord(parent.typeCode, parent.id);
      if (record) {
        if (record.clonedFrom) {
          return record.clonedFrom.id;
        } else if (record.TransferredFromId) {
          return record.TransferredFromId;
        }
      }
    }
  }

  /**
   * This function filters the rows that should appear in the transferred from typeahead.
   * @param {object} row The potential sending record that should be checked.
   * @returns {boolean} whether the record should be an option in the typeahead or not
   */
  filterTransferredFromTypeahead(row) {
    let {
      sendingUnitOperationId,
      availableSendingRecordIds,
      sendingParentId,
      ProcessComponentId,
      MaterialId,
      UnitOperationId,
      typeCode,
      UnitOperations,
      Steps,
    } = this.state;

    // Check if the record in edit is a Process Component (PRC) or Material (MT)
    if (VARIABLE_PARENTS.includes(typeCode)) {
      // Check if availableSendingRecordIds array is defined
      if (availableSendingRecordIds) {
        // Include the row in the typeahead options if its ID is included in availableSendingRecordIds
        return availableSendingRecordIds.includes(row.id);
      } else {
        // Check if my record is under a Step
        if (Steps && Steps.length > 0) {
          // Include the row in the typeahead options if any of the record steps transfer from one of the row steps
          return row.Steps.includes(this.checkForSendingRecord(Steps));
        } else if (row.Steps && row.Steps.length === 0) {
          // Check if my record is under a UnitOperation
          if (UnitOperations && UnitOperations.length > 0) {
            // Include the row in the typeahead options if any of the record unit operations transfer from one of the row UnitOperations
            return row.UnitOperations.includes(this.checkForSendingRecord(UnitOperations));
          } else if (row.UnitOperations && row.UnitOperations.length === 0) {
            // Include the row in the typeahead options since both records are global
            return true;
          }
        }
      }
    } else {
      // Check if sendingUnitOperationId is not defined but the record UnitOperationId is defined
      if (!sendingUnitOperationId && UnitOperationId) {
        // Fetch the sendingUnitOperationId of the record's unit operation
        const sendingRecordId = this.checkForSendingRecord([{typeCode: TYPE_CODE.UNIT_OPERATION, id: UnitOperationId}]);
        if (row.UnitOperationId === sendingRecordId) {
          sendingUnitOperationId = row.UnitOperationId;
        }
      }
      // Check if record is linked to a PRC
      if (ProcessComponentId) {
        // Check if sendingParentId is not defined, which is the sending record for the linked PRC
        if (!sendingParentId) {
          // Fetch the sendingParentId for the Process Component (PRC)
          sendingParentId = this.checkForSendingRecord([{
            typeCode: TYPE_CODE.PROCESS_COMPONENT,
            id: ProcessComponentId
          }]);
        }
        // Include the record in the typeahead options if:
        //  - the row's ProcessComponentId matches the sendingParentId, or
        //  - the row's UnitOperationId matches sendingUnitOperationId and both MaterialId and ProcessComponentId are not defined for the row
        return row.ProcessComponentId === sendingParentId ||
          (row.UnitOperationId === sendingUnitOperationId &&
            !row.MaterialId && !row.ProcessComponentId);
      } else if (MaterialId) {   // Check if MaterialId is defined
        // Check if sendingParentId is not defined, which is the sending record for the linked MT
        if (!sendingParentId) {
          // Fetch the sendingParentId for the Material (MT)
          sendingParentId = this.checkForSendingRecord([{typeCode: TYPE_CODE.MATERIAL, id: MaterialId}]);
        }
        // Include the record in the typeahead options if:
        //  - the row's MaterialId matches the sendingParentId, or
        //  - the row's UnitOperationId matches sendingUnitOperationId and both MaterialId and ProcessComponentId are not defined for the row
        return row.MaterialId === sendingParentId ||
          (row.UnitOperationId === sendingUnitOperationId &&
            !row.MaterialId && !row.ProcessComponentId);
      } else if (UnitOperationId) {  // Check if record's UnitOperationId is defined
        // Include the record in the typeahead options if:
        //  - the row's UnitOperationId matches sendingUnitOperationId and both MaterialId and ProcessComponentId are not defined for the row
        return row.UnitOperationId === sendingUnitOperationId &&
          !row.MaterialId && !row.ProcessComponentId;
      }
    }
  }

  renderClearTechTransferInfoConfirmationPopup() {
    return (
      <ConfirmationPopup
        modalRef={clearTechTransferInfoConfirmationPopup => this.clearTechTransferInfoConfirmationPopup = clearTechTransferInfoConfirmationPopup}
        headerText={"Reset Transfer Assessment?"}
        message="This action will reset all values from the Transfer Assessment section."
        yesButtonText="Reset Values"
        noButtonText="Cancel"
        useYesNoOptions={true}
        showCancelButton={true}
        onOkButtonClick={this.handleClearTechTransferInfoConfirmation}
        onHideConfirmationPopup={this.handleHideClearTechTransferInfoConfirmation}
      />
    );
  }

  handleHideClearTechTransferInfoConfirmation() {
    this.setStateSafely({
      showClearTechTransferInfoConfirmation: false,
    });
  }

  handleClearTechTransferInfoConfirmation() {
    const effectiveRMP = this.getEffectiveRMPByModelName("TT");
    const {
      maxImpact,
      maxUncertainty,
      maxDetectabilityRisk,
      minImpact,
    } = effectiveRMP.boundaries;
    const isNotAssessedAllowed = CommonRiskUtils.scoreIsNotAssessed(minImpact);

    $(this.clearTechTransferInfoConfirmationPopup).modal("hide");
    this.setStateSafely({
      techTransferAssessmentStatus: "Not started",
      TransferredFromId: "None",
      techTransferInformationCleared: true,
      techTransferComment: "",
      techTransferImpact: isNotAssessedAllowed ? 0 : maxImpact,
      techTransferImpactDescription: "",
      techTransferUncertainty: isNotAssessedAllowed ? 0 : maxUncertainty,
      techTransferUncertaintyJustification: "",
      techTransferDetectability: isNotAssessedAllowed ? 0 : maxDetectabilityRisk,
      techTransferControlStrategy: "",
      techTransferRecommendedActions: "",
      techTransferRiskMitigationStrategy: "",
      techTransferLinks: "[]",
      showClearTechTransferInfoConfirmation: false,
      dataModified: true,
    });
  }

  handleChangeValue(attributeName, attributeValue, callback, attributeType) {
    if (attributeName === "TransferredFromId") {
      this.setStateSafely({techTransferInformationCleared: false}, () => {
        super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
      });
    } else {
      super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
    }
  }

  renderClearTechTransferInfoButton() {
    return this.isEdit() ? (
      <Fragment>
        <a target="_blank"
           id="clearButton"
           onClick={() => {
             this.setStateSafely({
               showClearTechTransferInfoConfirmation: true,
             });
           }}
           rel="noopener noreferrer"
        >Clear</a>
      </Fragment>
    ) : null;
  }

  getProcess() {
    const memoryCache = MemoryCache.getNamedInstance("base_tech_transfer_attribute");
    const cacheKey = this.state.ProjectId + "_" + this.state.ProcessId;
    let process = memoryCache.get(cacheKey);
    if (!process) {
      process = new TypeaheadObjectCache("Process", this.state.ProjectId).getOptionsFromCache().find(process => process.id === this.state.ProcessId);
      memoryCache.set(cacheKey, process);
    }

    return process;
  }

  renderTechTransferAssessmentSection() {
    const interactionName = "renderTechTransferAssessmentSection";
    measurePerformanceStart(interactionName);

    let {
      techTransferImpact,
      techTransferUncertainty,
      techTransferDetectability,
      showClearTechTransferInfoConfirmation,
    } = this.state;

    const process = this.getProcess();
    const effectiveRMP = this.getEffectiveRMPByModelName("TT");
    const olderRMP = this.getOlderRMP("TT");

    // The tests need to see the loading spinner for the TT section. Otherwise, nothing will be loading while the process
    // typeahead is loading and they'll go ahead and fail some validation section because there's no TT section.
    const shouldShowSection = (this.getTransferredFromId() || (process && process.SendingId))
      && CommonRiskUtils.TECH_TRANSFER_RISK_MODELS.includes(UIUtils.getTypeCodeForModelName(this.baseTypeName));

    if (this.isEdit() && shouldShowSection && !this.state.processExplorerHelperIsLoaded) {
      this.loadTypeAheadFilterRequirements();
      return (<div className="skeleton">Loading...</div>);
    }

    const defaultTechTransferImpact = CommonRiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.IMPACT, effectiveRMP);
    const defaultTechTransferUncertainty = CommonRiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.UNCERTAINTY, effectiveRMP);
    const defaultTechTransferDetectability = CommonRiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.DETECTABILITY_RISK, effectiveRMP);

    const defaultOlderTechTransferImpact = CommonRiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.IMPACT, olderRMP ?? effectiveRMP);
    const defaultOlderTechTransferUncertainty = CommonRiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.UNCERTAINTY, olderRMP ?? effectiveRMP);
    const defaultOlderTechTransferDetectability = CommonRiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.DETECTABILITY_RISK, olderRMP ?? effectiveRMP);

    const shouldDisplayTechTransferImpact = techTransferImpact !== null;
    const shouldDisplayTechTransferUncertainty = techTransferUncertainty !== null;
    const shouldDisplayTechTransferDetectability = techTransferDetectability !== null;

    techTransferImpact = techTransferImpact || defaultTechTransferImpact;
    techTransferUncertainty = techTransferUncertainty || defaultTechTransferUncertainty;
    techTransferDetectability = techTransferDetectability || defaultTechTransferDetectability;

    const rpn = techTransferImpact * techTransferUncertainty * techTransferDetectability;
    const outcomeRiskScale = getRiskScale(RISK_TYPE_ENUM.RPN, effectiveRMP, rpn, this);
    const outcome = outcomeRiskScale ? outcomeRiskScale.riskLabel : "";


    const section = shouldShowSection ? (
      <Section id="techTransfer"
               parent={this}
               showDocLinks={true}
               header={<span>
                           Tech Transfer Assessment
                           <InfoTooltip id="infoTechTransferAssessment"
                                        verbiage={
                                          <div>
                                            Use this to rate the risk of transferring a process from one environment to
                                            another.
                                          </div>
                                        }
                           />
                 </span>}
               headerLink={this.renderClearTechTransferInfoButton()}
      >
        {showClearTechTransferInfoConfirmation ?
          this.renderClearTechTransferInfoConfirmationPopup()
          : ""}
        <div className="row">
          <TechTransferTypeaheadAttribute name="transferredFrom"
                                          default={this.getTransferredFromId()}
                                          typeaheadType={this.capitalizedBaseTypeName}
                                          className="col-sm-6"
                                          filter={this.filterTransferredFromTypeahead}
                                          getProcessId={() => process ? process.SendingId : null}
                                          parent={this}
          />
          <TechTransferComboBoxAttribute name="techTransferAssessmentStatus"
                                         displayName="Assessment Status"
                                         options={TECH_TRANSFER_ASSESSMENT_STATUSES}
                                         default="Not started"
                                         olderDefault="Not started"
                                         parent={this}
          />
        </div>
        <div className="row">
          <TechTransferComboBoxAttribute name="techTransferImpact"
                                         displayName="Impact/Severity"
                                         className="col-sm-6"
                                         options={getRiskFieldOptions(effectiveRMP, "RMPToImpactLinkedVersions")}
                                         olderOptions={olderRMP && getRiskFieldOptions(olderRMP, "RMPToImpactLinkedVersions")}
                                         default={defaultTechTransferImpact}
                                         olderDefault={defaultOlderTechTransferImpact}
                                         getTooltipCallback={getRiskFieldTooltip.bind(this, RISK_TYPE_ENUM.IMPACT, effectiveRMP, true)}
                                         shouldDisplayValue={shouldDisplayTechTransferImpact}
                                         isLoading={!effectiveRMP}
                                         parent={this}
          />
        </div>
        <div className="row">
          <TextAreaAttribute name="techTransferImpactDescription"
                             displayName="Impact/Severity Description"
                             className="col-sm-12"
                             tooltipText={TOOLTIP_HEADERS.IMPACT_SEVERITY_DESCRIPTION}
                             parent={this}
          />
        </div>
        <div className="row">
          <TechTransferComboBoxAttribute name="techTransferUncertainty"
                                         displayName="Uncertainty/Likelihood"
                                         className="col-sm-6"
                                         disabled={!(effectiveRMP && effectiveRMP.useUncertainty)}
                                         options={getRiskFieldOptions(effectiveRMP, "RMPToUncertaintyLinkedVersions")}
                                         olderOptions={olderRMP && getRiskFieldOptions(olderRMP, "RMPToUncertaintyLinkedVersions")}
                                         default={defaultTechTransferUncertainty}
                                         olderDefault={defaultOlderTechTransferUncertainty}
                                         getTooltipCallback={getRiskFieldTooltip.bind(this, RISK_TYPE_ENUM.UNCERTAINTY, effectiveRMP, true)}
                                         shouldDisplayValue={shouldDisplayTechTransferUncertainty}
                                         isLoading={!effectiveRMP}
                                         parent={this}
          />
        </div>
        <div className="row">
          <TextAreaAttribute name="techTransferUncertaintyJustification"
                             displayName="Uncertainty Justification"
                             className="col-sm-12"
                             tooltipText={TOOLTIP_HEADERS.UNCERTAINTY_JUSTIFICATION}
                             isLoading={!effectiveRMP}
                             parent={this}
          />
        </div>
        <div className="row">
          <TechTransferComboBoxAttribute name="techTransferDetectability"
                                         displayName="Detectability Risk"
                                         className="col-sm-6"
                                         disabled={!(effectiveRMP && effectiveRMP.useDetectability)}
                                         options={getRiskFieldOptions(effectiveRMP, "RMPToDetectabilityRiskLinkedVersions")}
                                         olderOptions={olderRMP && getRiskFieldOptions(olderRMP, "RMPToDetectabilityRiskLinkedVersions")}
                                         default={defaultTechTransferDetectability}
                                         olderDefault={defaultOlderTechTransferDetectability}
                                         getTooltipCallback={getRiskFieldTooltip.bind(this, RISK_TYPE_ENUM.DETECTABILITY_RISK, effectiveRMP, true)}
                                         shouldDisplayValue={shouldDisplayTechTransferDetectability}
                                         isLoading={!effectiveRMP}
                                         parent={this}
          />
        </div>
        <div className="row">
          <TextAreaAttribute name="techTransferControlStrategy"
                             displayName="Detectability / Current Control Strategy"
                             className="col-sm-12"
                             tooltipText={TOOLTIP_HEADERS.DETECTABILITY_CURRENT_CONTROL_STRATEGY}
                             parent={this}
          />
        </div>
        <div className="row">
          <CalculatedRiskAttribute name="techTransferRPN"
                                   className="col-sm-4"
                                   displayName="RPN (Raw)"
                                   riskFactor1={((instance) => {
                                     return this.getTechTransferDetectabilityForCalculatedAttribute(instance, techTransferDetectability, defaultOlderTechTransferDetectability);
                                   }).bind(this)}
                                   riskFactor2={((instance) => {
                                     return this.getTechTransferCriticalityForCalculatedAttribute(instance, techTransferImpact, techTransferUncertainty, defaultOlderTechTransferImpact, defaultOlderTechTransferUncertainty);
                                   }).bind(this)}
                                   riskType={RISK_TYPE_ENUM.RPN}
                                   isTechTransfer={true}
                                   RMP={effectiveRMP}
                                   olderRMP={olderRMP}
                                   getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.RPN, effectiveRMP, this.state, false, false, false, true)}
                                   valueType="raw"
                                   isLoading={!effectiveRMP}
                                   parent={this}
                                   olderVersion={this.getOlderVersion()}
          />
          <CalculatedRiskAttribute name="techTransferRPNPercentage"
                                   className="col-sm-4"
                                   displayName="RPN (%)"
                                   riskFactor1={((instance) => {
                                     return this.getTechTransferDetectabilityForCalculatedAttribute(instance, techTransferDetectability, defaultOlderTechTransferDetectability);
                                   }).bind(this)}
                                   riskFactor2={((instance) => {
                                     return this.getTechTransferCriticalityForCalculatedAttribute(instance, techTransferImpact, techTransferUncertainty, defaultOlderTechTransferImpact, defaultOlderTechTransferUncertainty);
                                   }).bind(this)}
                                   riskType={RISK_TYPE_ENUM.RPN}
                                   isTechTransfer={true}
                                   RMP={effectiveRMP}
                                   olderRMP={olderRMP}
                                   getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.RPN, effectiveRMP, this.state, true, false, false, true)}
                                   valueType="percentage"
                                   isLoading={!effectiveRMP}
                                   parent={this}
                                   olderVersion={this.getOlderVersion()}
          />
          <div className="col-4">
            <LabelTooltip className="col-form-label base-attribute"
                          id="techTransferOutcomeInputLabel"
                          text="Outcome"
                          tooltipText={TOOLTIP_HEADERS.OUTCOME}
            />
            <div className={"view-attribute" + (!effectiveRMP ? " skeleton" : "")}>
              {outcome}
            </div>
          </div>
        </div>
        <div className="row">
          <TextAreaAttribute name="techTransferRiskMitigationStrategy"
                             displayName="Risk Mitigation Strategy and Limitations"
                             className="col-sm-12"
                             tooltipText={TOOLTIP_HEADERS.RISK_MITIGATION_STRATEGY_AND_LIMITATIONS}
                             parent={this}
          />
        </div>
        <div className="row">
          <TextAreaAttribute name="techTransferRecommendedActions"
                             displayName="Recommended Actions"
                             className="col-sm-12"
                             tooltipText={TOOLTIP_HEADERS.RECOMMENDED_ACTIONS}
                             parent={this}
          />
        </div>
        <div className="row">
          <TextAreaAttribute name="techTransferComment"
                             displayName="Comment or Description of Change"
                             className="col-sm-12"
                             parent={this}
          />
        </div>
      </Section>
    ) : "";

    measurePerformanceEnd(interactionName);
    return section;
  }

  getTechTransferDetectabilityForCalculatedAttribute(instance, techTransferDetectability, defaultOlderTechTransferDetectability) {
    const olderVersion = this.getOlderVersion(this.state);
    if (olderVersion && olderVersion?.versionId === instance.versionId) {
      return valueExists(olderVersion.techTransferDetectability) ? olderVersion.techTransferDetectability : defaultOlderTechTransferDetectability;
    }

    return techTransferDetectability;
  }

  getTechTransferCriticalityForCalculatedAttribute(instance, techTransferImpact, techTransferUncertainty, defaultOlderTechTransferImpact, defaultOlderTechTransferUncertainty) {
    const olderVersion = this.getOlderVersion(this.state);
    if (olderVersion && olderVersion?.versionId === instance.versionId) {
      const impact = valueExists(olderVersion.techTransferImpact) ? olderVersion.techTransferImpact : defaultOlderTechTransferImpact;
      const uncertainty = valueExists(olderVersion.techTransferUncertainty) ? olderVersion.techTransferUncertainty : defaultOlderTechTransferUncertainty;

      return impact * uncertainty;
    }

    return techTransferImpact * techTransferUncertainty;
  }
}
