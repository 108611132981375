"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseReactComponent from "../../base_react_component";

/**
 * This class is responsible for showing CCS splash screen
 */
export default class SplashScreen extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="splashScreen" className="container shadow graceful-error-message">
        <div className="row row-white">
          <div className="col-sm-6 splash-screen-left-panel">
            <div className="splash-left-panel-main-text">
              <span>Do more,<br />save time</span>
            </div>
            <div className="splash-left-panel-secondary-text">
              <span>Spend less time on manual tasks and report writing and more time improving the
                quality of your product, process and ultimately, patient profile.
                Contact us today to upgrade your license. </span>
            </div>
            <div>
              <button className="btn btn-lg btn-primary splash-screen-button"
                      onClick={() => {
                        window.open(UIUtils.getSecuredURL("https://www.qbdvision.com/contact-us"), "_blank");
                      }}
                      id="contactSalesButton"
              >
                Contact Sales
              </button>
            </div>
          </div>
          <div className="col-sm-6 splash-screen-right-panel">
            <div className="splash-right-panel-main-text">
              <span>Collaborate in one place</span>
            </div>
            <div className="splash-screen-users-icon">
              <FontAwesomeIcon icon={faUsers} />
            </div>
            <div className="splash-right-panel-secondary-text">
              <ul>
                <li>Link patient, product and process requirements</li>
                <li>Integrate risk assessment & process capability analysis</li>
                <li>Visualize your process control strategy</li>
                <li>Simplify tech transfer during lifecycle</li>
                <li>Conduct risk-based validation</li>
                <li>Execute post-approval change management</li>
                <li>And, so much more! See today at&nbsp;
                  <a rel="noopener noreferrer"
                     target="_blank"
                     href="https://qbdvision.com"
                  >
                    qbdvision.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
