"use strict";

import React from "react";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import Section from "../../editor/widgets/section";
import TextAttribute from "../../editor/attributes/text_attribute";
import ComboBoxAttribute from "../../editor/attributes/combo_box_attribute";
import TextAreaAttribute from "../../editor/attributes/text_area_attribute";
import NumberAttribute from "../../editor/attributes/number_attribute";
import AggregateCriticalityAttribute from "../../editor/attributes/riskTable/aggregate_criticality_attribute";
import { getCalculationFormulaTooltip, getRiskLabelTooltip, getRiskScaleTooltip } from "../../helpers/risk_helper";
import { RISK_TYPE_ENUM } from "../../helpers/constants/constants";
import { EDITOR_TYPES } from "../../editor/editor_constants";
import TypeaheadAttribute from "../../editor/attributes/typeahead_attribute";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseAssetAttributePage from "../../editor/base_asset_attribute_page";
import MultiDocumentsRiskLinksAttribute from "../../editor/attributes/multi_documents_risk_links_attribute";
import RiskSupportingDocumentsAttribute from "../../editor/widgets/risk_supporting_documents_attribute";
import { measurePerformanceEnd, measurePerformanceStart } from "../../ui_utils";
import { RiskLabelAttribute } from "../../editor/attributes/riskTable/risk_label_attribute";
import RiskUtils from "../../../server/common/misc/common_risk_utils";

/**
 * This is the class that renders the Add/Edit/View Process Parameter editor in the process explorer.
 */
// i18next-extract-mark-ns-start process_explorer
class ProcessParameter extends BaseAssetAttributePage {
  constructor(props) {
    super(props, "processParameter", "ProcessParameter", "Process Parameter (PP)");

    // Bind stuff
    this.getHTMLURLPrefix = ProcessParameter.getHTMLURLPrefix;
  }

  componentDidMount() {
    document.title = "QbDVision Process Parameter (PP)";

    super.componentDidMount();
  }

  static getHTMLURLPrefix() {
    return "/processExplorer/processParameters";
  }

  getInfoTooltip() {
    return (
      <InfoTooltip id="infoPP"
                   verbiage={
                     <div>
                       Input operating parameters for the unit operation that may affect the quality attributes of the outputs of the unit operation.
                     </div>
                   }
      />
    );
  }

  filterSteps(option) {
    return option.UnitOperationId === this.state.UnitOperationId;
  }

  renderGeneralSection() {
    const interactionName = "renderGeneralSection";
    measurePerformanceStart(interactionName);
    const section = <Section parent={this}
                             header={this.getGeneralHeader()}
                             collapsible={false}
    >
      <div className="row">
        <TextAttribute name="name"
                       parent={this}
        />
        <TypeaheadAttribute name="process"
                            default={this.getProcessId()}
                            typeaheadType="Process"
                            target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                            instructions={`This can only be modified on add.`}
                            disabled={!this.isAdd()}
                            parent={this}
                            isLoading={this.state.isLoading}
                            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                            parentId={this.state.id}
                            projectId={this.getProjectId()}
                            processId={this.getProcessId()}
        />
      </div>
      <div className="row">
        <TypeaheadAttribute name="unitOperation"
                            typeaheadType="UnitOperation"
                            target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                            onValidate={this.handleUOVerification}
                            parent={this}
                            isLoading={this.state.isLoading}
                            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                            parentId={this.state.id}
                            projectId={this.getProjectId()}
                            processId={this.getProcessId()}
        />
        {this.renderParentAttribute()}
      </div>
      <div className="row">
        <TypeaheadAttribute name="step"
                            typeaheadType="Step"
                            target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                            onValidate={this.handleStepVerification}
                            filter={this.filterSteps}
                            parent={this}
                            isLoading={this.state.isLoading}
                            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                            parentId={this.state.id}
                            projectId={this.getProjectId()}
                            processId={this.getProcessId()}
                            relatedRecordId={this.state.UnitOperationId}
        />
        <ComboBoxAttribute name="type"
                           options={["",
                             "Quantity",
                             "Design",
                             "Operating",
                             "Control",
                             "Other"]}
                           tooltipText={(<div>
                             Allows for grouping of parameters by type.
                             <ul>
                               <li>A <b>design parameter</b> is a process parameter that affects the operating variables of a process (i.e., number of impellers affects volumetric flow rate). Design parameters are often fixed in a process (i.e., impeller type, sparger design)
                               </li>
                               <li> An <b>operating parameter</b> is a process parameter that is determined by one or multiple design parameters and can affect a variable that needs to be controlled in order to ensure that the defined performance and/or quality attributes are met (e.g., volumetric flow rate affects shear stress).
                               </li>
                               <li> A <b>control parameter</b> is a process parameter that can directly impact a performance or quality attribute (e.g., shear stress affects cell viability).
                               </li>
                             </ul>
                           </div>)}
                           parent={this}
                           isLoading={this.state.isLoading}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           parentId={this.state.id}
        />
      </div>
      <div className="row">
        <TextAreaAttribute name="description"
                           className="col-sm-12"
                           parent={this}
        />
      </div>
    </Section>;

    measurePerformanceEnd(interactionName);

    return section;
  }

  renderAcceptanceCriteriaSectionExtension() {
    const interactionName = "renderAcceptanceCriteriaSectionExtension";
    measurePerformanceStart(interactionName);

    const section = <div className="row">
      <NumberAttribute name="lowerOperatingLimit"
                       displayName="LOL"
                       tooltipText="LOL - Lower operating limit of the parameter as recommended by the manufacturer of the component"
                       parent={this}
                       max={this.state.upperOperatingLimit}
      />
      <NumberAttribute name="upperOperatingLimit"
                       displayName="UOL"
                       tooltipText="UOL - Upper operating limit of the parameter as recommended by the manufacturer of the component"
                       parent={this}
                       min={this.state.lowerOperatingLimit}
      />
    </div>;

    measurePerformanceEnd(interactionName);
    return section;
  }


  renderRiskLinksAttribute() {
    const interactionName = "renderRiskLinksAttribute";
    measurePerformanceStart(interactionName);

    const section = (
      <div className="row">
        <MultiDocumentsRiskLinksAttribute name="risk"
                                          linkToObjectDisplayName="Intermediate or Final Attributes"
                                          linkToObject={["IPA", "IQA", "FPA", "FQA"]}
                                          linkObject={["ProcessParameterToIPA", "ProcessParameterToIQA", "ProcessParameterToFPA", "ProcessParameterToFQA"]}
                                          optionsFilter={this.filterRiskLinkTypeaheadOptions}
                                          displayName="Risk"
                                          className="col-sm-12"
                                          ref={(riskTable) => this.setRiskTableToState(riskTable)}
                                          modelName={this.baseTypeName}
                                          RMP={this.state.RMP}
                                          projectWithAllVersions={this.state.ProjectWithAllVersions}
                                          parent={this}
                                          triggerChildUpdate={this.state.triggerChildUpdate}
                                          isLoading={!this.state.RMP || !this.state.ProjectWithAllVersions || this.isLoading() || this.isLoadingRiskLinks()}
                                          onValidate={this.handleRiskVerification}
                                          onDelete={this.handleDelete}
                                          onSave={this.handleRiskLinkSave}
                                          risksFromAllRecords={this.state.risksFromAllRecords}

        />
      </div>
    );

    measurePerformanceEnd(interactionName);

    return section;
  }

  renderCriticalityAssessmentSection(effectiveRMP, olderRMP, riskTableInstance) {
    const interactionName = "renderCriticalityAssessmentSection-2";
    const isLoading = !effectiveRMP;
    measurePerformanceStart(interactionName);

    const section = <Section id="criticalityAssessment"
                             parent={this}
                             header={this.renderCriticalitySectionHeader()}
    >
      {this.renderPotentialFailureModes()}
      {this.renderRiskLinksAttribute()}
      <div className="row">
        <AggregateCriticalityAttribute name="maxCriticality"
                                       displayName="Criticality (Raw)"
                                       className="col-sm-3"
                                       riskInfo={this.state.riskInfo}
                                       olderRiskInfo={this.getOlderVersion()?.riskInfo}
                                       RMP={effectiveRMP}
                                       olderRMP={olderRMP}
                                       getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, this.state, false, false, false)}
                                       getSubscriptTooltipCallback={getCalculationFormulaTooltip.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, this.state, true, false, "Winner")}
                                       subscriptText="How is this calculated?"
                                       valueType="raw"
                                       parent={this}
                                       isLoading={isLoading}
        />
        <AggregateCriticalityAttribute name="maxCriticalityPercentage"
                                       displayName="Criticality (%)"
                                       className="col-sm-3"
                                       riskInfo={this.state.riskInfo}
                                       olderRiskInfo={this.getOlderVersion()?.riskInfo}
                                       RMP={effectiveRMP}
                                       olderRMP={olderRMP}
                                       getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, this.state, true, false, false)}
                                       getSubscriptTooltipCallback={getCalculationFormulaTooltip.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, this.state, false, true, "Winner")}
                                       subscriptText="How is this calculated?"
                                       valueType="percentage"
                                       parent={this}
                                       isLoading={isLoading}
        />

        <RiskLabelAttribute
          name="riskLabel"
          className="col-sm-3"
          riskTable={riskTableInstance}
          subscriptText="Overridden"
          parent={this}
          getSubscriptTooltipCallback={getRiskLabelTooltip.bind(this, this.state.riskInfo)}
          getTooltipCallback={RiskUtils.getRisklabelAttributeTooltip.bind(this)}
          visible={this.isView()}
          isLoading={isLoading}
        />

        <ComboBoxAttribute name="scaleDependent"
                           options={["Yes",
                             "No",
                             "Unknown"]}
                           default="Unknown"
                           tooltipText="Determination as to whether the process parameter target and range vary with the scale of production"
                           parent={this}
                           isLoading={this.state.isLoading}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           parentId={this.state.id}
        />
        <TextAreaAttribute name="scaleJustification"
                           className="col-sm-12"
                           tooltipText="Justification for scale dependence derived from current product and process understanding."
                           parent={this}
        />
        <TextAreaAttribute name="recommendedActions"
                           className="col-sm-12"
                           tooltipText="Identify recommended actions to further evaluate attribute or parameter and reduce uncertainty on how this impacts downstream requirements. These actions may be additional studies to evaluate attribute or parameter alone or in conjunction with other attributes or parameters (e.g. design of experiments (DOE) studies)."
                           parent={this}
        />
      </div>
      <div className="row">
        <RiskSupportingDocumentsAttribute name="riskLinks"
                                          displayName="Source Documents"
                                          getRiskLinksOptions={this.getRiskLinksTypeaheadOptions}
                                          className="col-sm-12"
                                          onDocumentDelete={this.handleRiskDocumentDelete}
                                          onDocumentSave={this.handleRiskDocumentSave}
                                          isLoading={this.isLoadingRiskLinks()}
                                          triggerChildUpdate={this.state.triggerChildUpdate}
                                          parent={this}
        />
      </div>
      {this.renderDeleteRiskConfirmation()}
      {this.renderEditRiskConfirmation()}
    </Section>;

    measurePerformanceEnd(interactionName);

    return section;
  }
}

export default I18NWrapper.wrap(ProcessParameter, ["process_explorer", "editor"]);
// i18next-extract-mark-ns-stop process_explorer
