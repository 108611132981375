@import "../../../../css/_brand_colors.scss";

.tooltip-container {
  font-weight: normal;
  font-family: Open Sans, sans-serif;

  > :global(.tooltip-inner) {
    padding: 0;
    border-radius: 5px;
    overflow-y: hidden;
    max-width: 300px;
    background-color: $brand-white;
    color: $brand-black;
    box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px 0 rgba(0, 0, 0, 0.20);
    text-align: left;
  }

  > :global(.arrow::before) {
    border-bottom-color: $brand-white;
    border-width: 7px;
  }

  :global(.related-panel-content) {
    border: unset;
    border-radius: 5px;
    max-height: 50vh;
    overflow-y: auto;
  }
}