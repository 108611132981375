/* stylelint-disable-next-line import-notation */
@import "../../../../../../../css/brand_colors";

.side-panel {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.column-selection-header {
  padding: 19px 24px;
  background: white;
  border-bottom: $hover-color solid thin;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 100;

  :global(a) {
    font-size: 14px;
  }
}

.column-selection-footer {
  display: flex;
  justify-content: flex-end;
  background: $brand-light-grey;
  border-top: solid thin $brand-mild-grey;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  bottom: 0;
  position: fixed;
  width: 100%;
}