"use strict";

import * as styles from "../style/pills_cell.module.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import * as commonStyles from "../style/common_cell.module.scss";
import React, { useState } from "react";

const renderOverlay = (content) => content.map((item, idx) => (
  <div key={idx} className={styles["overlay-content"]}>
    {"• " + item}
  </div>
));

export const Pill = (
  {
    width,
    content,
    overlayContent,
    overlayCssClass = styles["tooltip-container"],
    renderOverlayContent = renderOverlay
  }) => {

  const [
    showTooltip,
    setShowTooltip
  ] = useState(false);

  const cssClass = overlayContent ? `${styles["pill-item"]} ${styles["hidden-pills"]}` : `${styles["pill-item"]}`;

  return overlayContent ? (
    <OverlayTrigger
      show={showTooltip}
      placement="bottom-start"
      trigger={["hover", "focus"]}
      delay={{hide: 300}}
      overlay={
        <Tooltip id={`tooltip`}
                 className={`${commonStyles["field-tooltip"]} ${overlayCssClass}`}
                 onMouseEnter={() => setShowTooltip(true)}
                 onMouseLeave={() => setShowTooltip(false)}
        >
          {renderOverlayContent(overlayContent)}
        </Tooltip>
      }
    >
      <span className={cssClass}
            style={{width}}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
      >
        {content}
      </span>
    </OverlayTrigger>
  ) : (
    <span className={cssClass} style={{width}}>
      {content}
    </span>
  );
};