"use strict";

import React, { Fragment } from "react";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { loadRMP } from "../../helpers/risk_helper";
import * as UIUtils from "../../ui_utils";
import { PRODUCT_MODELS_CONFIG } from "../tables/product_tables_config";
import ConfigurableTablesExportColumnGenerator
  from "../../configurableTables/export/configurable_tables_export_column_generator";
import ConfigurableTablesColumnSelectionHandler
  from "../../configurableTables/columnSelection/configurable_tables_column_selection_handler";
import ConfigurableTablesColumnOperationsHandler
  from "../../configurableTables/columnOperations/configurable_tables_column_operations_handler";
import ConfigurableTablesColumnOperationsAdapter
  from "../../configurableTables/columnOperations/configurable_tables_column_operations_adapter";
import * as styles from "../../configurableTables/tables/styles/configurable_table.module.scss";
import ConfigurableTablePage from "../../configurableTables/tables/configurable_table_page";
import {
  filterRiskRankingFields
} from "../../configurableTables/columnSelection/configurable_tables_column_selection_helper";
import { TableToolBar } from "../../configurableTables/tables/widgets/topBar/toolBar/table_tool_bar";
import { TableStateProvider } from "../../configurableTables/tables/table_state_provider";
import { WithNotification } from "../../configurableTables/tables/widgets/notification/with_notification";
import ErrorBar from "../../widgets/bars/error_bar";
import { TableSettingsProvider } from "../../configurableTables/tables/table_settings_provider";

// i18next-extract-mark-ns-start qtpp
class ProductTablePage extends ConfigurableTablePage {
  constructor(props) {
    super(props);
    this.projectId = props.projectId;

    this.columnOperationsAdapter = new ConfigurableTablesColumnOperationsAdapter();
    this.columnOperationsHandler = new ConfigurableTablesColumnOperationsHandler({
      parent: this,
      columnOperationsAdapter: this.columnOperationsAdapter,
    });

    this.columnSelectionHandler = new ConfigurableTablesColumnSelectionHandler({
      parent: this,
      modelsConfig: PRODUCT_MODELS_CONFIG,
      columnOperationsAdapter: this.columnOperationsAdapter,
      exportColumnGenerator: ConfigurableTablesExportColumnGenerator,
    }, false, "defaultValue");

    props.onUpdateTableDependencies({
      columnSelectionHandler: this.columnSelectionHandler,
      columnOperationsHandler: this.columnOperationsHandler,
      columnOperationsAdapter: this.columnOperationsAdapter,
    });

    UIUtils.setLoadingDisabled(false);
    UIUtils.showLoadingImage();
    loadRMP((effectiveRMP) => this.rmpLoaded(effectiveRMP, props.selectedModelType));
  }

  rmpLoaded(effectiveRMP, selectedModelType) {
    let stateObject = {
      effectiveRMP,
      selectedModelType,
    };
    this.columnOperationsHandler.handleDefaultColumnOperationsFromURL(stateObject);
    this.setStateSafely(stateObject, this.loadTableData);
  }

  loadTableData() {
    UIUtils.secureAjaxGET(`configurableTables/${this.projectId}?source=product&latestVersion=true`)
      .done(this.handleReceivedDataFromServer);
  }

  handleReceivedDataFromServer(records) {
    const project = records.project;
    const adaptedRecords = this.getAdaptedInfo(records, project);
    const {onVisibleColumnsChange} = this.props;
    this.setStateSafely({
      records,
      project,
      adaptedRecords,
    }, () => {
      this.columnSelectionHandler.handleResetColumnVisibility(onVisibleColumnsChange);
      UIUtils.hideLoadingImage();
    });
  }

  getAdaptedInfo(records, project) {
    const {effectiveRMP} = this.state;
    const {selectedModelType, onTableDataUpdated} = this.props;
    const productResultsAdapter = PRODUCT_MODELS_CONFIG[selectedModelType]
      .adapter(
        {
          results: UIUtils.deepClone(records),
          effectiveRMP,
          selectedModelType,
        }
      );
    const adaptedData = productResultsAdapter.getRows();
    onTableDataUpdated(adaptedData, project);
    return adaptedData;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedModelType,
      onVisibleColumnsChange,
      onTableStateChange
    } = this.props;
    const oldSelectedModelType = prevProps.selectedModelType;
    const {records, project, tableState} = this.state;

    if (records && selectedModelType !== oldSelectedModelType) {
      const adaptedRecords = this.getAdaptedInfo(records, project);
      this.setStateSafely(
        {
          adaptedRecords,
          selectedModelType,
          securityTypeName: this.getSecurityTypeName(selectedModelType),
        }, () =>
          this.columnSelectionHandler.handleResetColumnVisibility(onVisibleColumnsChange));
    }

    if (JSON.stringify(tableState) !== JSON.stringify(prevState.tableState)) {
      onTableStateChange(tableState);
    }
  }

  render() {
    const {t, selectedModelType, onVisibleColumnsChange} = this.props;
    const {
      adaptedRecords,
      effectiveRMP,
      project,
      visibleTableColumns,
      tableState,
    } = this.state;

    const commonProps = {
      effectiveRMP,
      projectId: this.projectId,
      project,
      parent: this,
    };

    const {
      handleColumnOperationsChange,
    } = this.columnOperationsHandler;

    const {customFilters} = tableState || {};

    const config = PRODUCT_MODELS_CONFIG[selectedModelType];
    const {fieldsConfig, customFiltersHandler, modelName, table} = config || {};
    const sort = this.columnOperationsAdapter.getDefaultSorting(tableState);
    const productTable = config ?
      table({
        id: "productTable",
        records: adaptedRecords || [],
        selectedModelType,
        fieldsConfig,
        tableState: {
          ...tableState,
          sort,
        },
        t,
        customFilters,
        customFiltersHandler,
        handleArchiveButton: this.handleArchiveButton,
        handleRestoreButton: this.handleRestoreButton,
        onEmptyTableAddClicked: this.handleEmptyTableAddButton,
        onImportButtonClick: this.handleImport,
        visibleTableColumns: filterRiskRankingFields(visibleTableColumns, project) || [],
        columnOperationsAdapter: this.columnOperationsAdapter,
        onColumnOperationsChange: handleColumnOperationsChange,
        ...commonProps,
      }, project?.productRiskAssessmentType === "Risk Ranking") : "";

    return (
      <TableStateProvider>
        <Fragment>
          <TableToolBar modelName={modelName}
                        selectedModelType={selectedModelType}
                        visibleTableColumns={filterRiskRankingFields(visibleTableColumns, project) || []}
                        tableState={tableState}
                        operationsAdapter={this.columnOperationsAdapter}
                        operationsHandler={this.columnOperationsHandler}
                        onResetToDefaults={this.columnSelectionHandler?.handleResetToDefaults}
                        onVisibleTableColumnsChanged={(map) =>
                          this.columnSelectionHandler?.handleVisibleTableColumnsChanged(map, onVisibleColumnsChange)}
                        enableAssessmentOptions={project?.productRiskAssessmentType === "Risk Ranking"}
                        showAssessmentOptions={true}
                        customFilters={customFilters}
                        customFiltersHandler={customFiltersHandler}
                        records={adaptedRecords}
                        afterBulkEdit={this.handleBulkEdit}
          />
          <div className={styles["configurable-table"]}>
            <div id="productContainer"
                 className={styles["configurable-table-inner-container"]}
            >
              <ErrorBar className={`mt-2 ${styles["error-bar"]}`} />
              <TableSettingsProvider visibleTableColumns={visibleTableColumns}>
                {productTable}
              </TableSettingsProvider>
            </div>
          </div>
          {this.renderArchivePopup()}
        </Fragment>
      </TableStateProvider>
    );
  }
}

export default I18NWrapper.wrap(WithNotification(ProductTablePage), "qtpp");
// i18next-extract-mark-ns-stop qtpp
