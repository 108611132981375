"use strict";

import React, { memo, useMemo, useState } from "react";
import { process } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { renderRow } from "./configurable_tables_helper";
import { useColumnGenerator } from "./hooks/use_column_generator";
import { getter } from "@progress/kendo-react-common";

import * as styles from "./styles/configurable_table.module.scss";
import { useTableState } from "./table_state_provider";
import { useTableSettings } from "./table_settings_provider";
import { NoRecordsContainer } from "./widgets/blankState/no_records_container";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const getDataSource = (props, data, selectedState, skip, take) => {
  const {customFilters, customFiltersHandler, columnOperationsAdapter} = props;
  const filterOptions = columnOperationsAdapter.adaptFilterNames(props);
  filterOptions.skip = skip;
  filterOptions.take = take;
  const projectedData = customFiltersHandler
    .process(customFilters, data)
    .map(row => (
      {...row, [SELECTED_FIELD]: selectedState[idGetter(row)]}
    ));
  return process(projectedData, filterOptions);
};

function getPagingOptions({data, total}) {
  const pageableOption = {
    info: true,
    pageSizes: [50, 100, 250]
  };
  return {total, data, pageableOption};
}

export const ConfigurableTable = memo((props) => {

  const {
    id,
    tableState,
    records,
    children,
    selectedModelType,
    onEmptyTableAddClicked,
    onImportButtonClick,
    onColumnOperationsChange,
  } = props;

  const {width, height} = useTableSettings();

  const {
    selectedState,
    adjustSelectedRows,
  } = useTableState();

  const {filter, sort} = tableState;
  const [
    {skip, take},
    setPagerState
  ] = useState({skip: 0, take: 50});

  const onPageChange = ({page}) => {
    const {skip, take} = page;
    setPagerState({skip, take});
  };

  const onChange = (event) => {
    onRowClick(event);
  };

  const onRowClick = ({dataItem}) => {
    const id = idGetter(dataItem);
    if (selectedState[id]) {
      delete selectedState[id];
      adjustSelectedRows({...selectedState});
    } else {
      adjustSelectedRows({...selectedState, [id]: true});
    }
  };

  const onHeaderChange = ({syntheticEvent}) => {
    const {checked} = syntheticEvent.target;
    const newSelectedState = {};

    if (checked) {
      for (const item of dataSource.data) {
        newSelectedState[idGetter(item)] = true;
      }
    }

    adjustSelectedRows(checked ? newSelectedState : {});
  };

  const dataSource = useMemo(() => {
    return getDataSource(props, records, selectedState, skip, take);
  }, [records, selectedState, skip, take, sort, filter]);

  const {total, data, pageableOption} = useMemo(() => {
    return getPagingOptions(dataSource);
  }, [dataSource, skip, take]);


  const {
    onColumnResize,
    onColumnReorder,
    columns,
  } = useColumnGenerator(props);

  return (
    <Grid
      id={id || "configurableTable"}
      data={data}
      onDataStateChange={onColumnOperationsChange}
      rowHeight={40}
      rowRender={renderRow}
      onColumnResize={onColumnResize}
      onColumnReorder={onColumnReorder}
      scrollable={"scrollable"}
      onRowClick={onRowClick}
      dataItemKey={DATA_ITEM_KEY}
      selectedField={SELECTED_FIELD}
      onSelectionChange={onChange}
      onHeaderSelectionChange={onHeaderChange}
      selectable={{
        enabled: !!data.length,
        cell: false,
        mode: "multiple",
      }}
      resizable={true}
      reorderable={true}
      pageable={pageableOption}
      total={total}
      skip={skip}
      take={take}
      pageSize={take}
      onPageChange={onPageChange}
      sortable={true}
      style={{
        width,
        height,
        maxHeight: height
      }}
      {...tableState}
      {...props}
      headerCellRender={(props) =>
        props?.map((element, index) => {
          if (index === 0 && element.props.checked && !data.length) {
            const newProps = {...element.props, checked: false};
            return React.cloneElement(element, newProps);
          }
          return element;
        })}
    >
      <GridNoRecords>
        <NoRecordsContainer height={height}
                            filter={filter}
                            selectedModelType={selectedModelType}
                            onEmptyTableAddClicked={onEmptyTableAddClicked}
                            onImportButtonClick={onImportButtonClick}
        />
      </GridNoRecords>
      <GridColumn className={styles["select-column"]}
                  field={SELECTED_FIELD}
                  width="50px"
                  headerSelectionValue={
                    data.findIndex((item) => !selectedState[idGetter(item)]) === -1
                  }
                  resizable={false}
                  reorderable={false}
                  orderIndex={0}
      />
      {children}
      {columns}
    </Grid>
  );
});