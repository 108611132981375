var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { classNames, Draggable, Icon, Keys } from '@progress/kendo-react-common';
/**
 * @hidden
 */
var SplitterBar = /** @class */ (function (_super) {
    __extends(SplitterBar, _super);
    function SplitterBar(props) {
        var _this = _super.call(this, props) || this;
        _this.draggable = null;
        _this.onDrag = function (data, isFirst, isLast) {
            var event = data.event;
            var _a = _this.props, onDrag = _a.onDrag, index = _a.index;
            var element = _this.draggable && _this.draggable.element;
            if (element && !_this.isStatic && _this.isDraggable) {
                onDrag(event, element, index, isFirst, isLast);
            }
        };
        _this.onFocus = function () {
            _this.setState({
                focused: true
            });
        };
        _this.onBlur = function () {
            _this.setState({
                focused: false
            });
        };
        _this.onToggle = function (event) {
            var _a = _this.props, onToggle = _a.onToggle, index = _a.index, prev = _a.prev, next = _a.next;
            if (prev.collapsible || next.collapsible) {
                onToggle(prev.collapsible ? index : index + 1, event);
            }
        };
        _this.onPrevToggle = function (event) {
            var _a = _this.props, onToggle = _a.onToggle, index = _a.index, prev = _a.prev;
            if (prev.collapsible) {
                onToggle(index, event);
            }
        };
        _this.onNextToggle = function (event) {
            var _a = _this.props, onToggle = _a.onToggle, index = _a.index, next = _a.next;
            if (next.collapsible) {
                onToggle(index + 1, event);
            }
        };
        _this.onKeyDown = function (event) {
            var keyCode = event && event.keyCode;
            var isHorizontal = _this.isHorizontal;
            var index = _this.props.index;
            var element = _this.draggable && _this.draggable.element;
            var onKeyboardResize = function (el, idx, offset) {
                event.preventDefault();
                _this.props.onKeyboardResize(el, idx, offset, event);
            };
            if (!element) {
                return;
            }
            if (keyCode === Keys.enter) {
                event.preventDefault();
                _this.onToggle(event);
            }
            else if (_this.isDraggable) {
                if (isHorizontal && keyCode === Keys.left) {
                    onKeyboardResize(element, index, -10);
                }
                else if (isHorizontal && keyCode === Keys.right) {
                    onKeyboardResize(element, index, 10);
                }
                else if (!isHorizontal && keyCode === Keys.up) {
                    onKeyboardResize(element, index, -10);
                }
                else if (!isHorizontal && keyCode === Keys.down) {
                    onKeyboardResize(element, index, 10);
                }
            }
        };
        _this.state = {
            focused: false
        };
        return _this;
    }
    Object.defineProperty(SplitterBar.prototype, "isStatic", {
        get: function () {
            var _a = this.props, prev = _a.prev, next = _a.next;
            var betweenResizablePanes = prev.resizable && next.resizable;
            var nearCollapsiblePane = prev.collapsible || next.collapsible;
            return !betweenResizablePanes && !nearCollapsiblePane;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SplitterBar.prototype, "isDraggable", {
        get: function () {
            var _a = this.props, prev = _a.prev, next = _a.next;
            var betweenResizablePanes = prev.resizable && next.resizable;
            var nearCollapsedPane = prev.collapsed || next.collapsed;
            return !!betweenResizablePanes && !nearCollapsedPane;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SplitterBar.prototype, "isHorizontal", {
        get: function () {
            return this.props.orientation === 'horizontal';
        },
        enumerable: false,
        configurable: true
    });
    SplitterBar.prototype.render = function () {
        var _a;
        var _this = this;
        var isDraggable = this.isDraggable;
        var isStatic = this.isStatic;
        var isHorizontal = this.isHorizontal;
        var barClasses = classNames('k-splitbar', (_a = {},
            _a['k-focus'] = this.state.focused,
            _a['k-splitbar-horizontal'] = isHorizontal,
            _a['k-splitbar-vertical'] = !isHorizontal,
            _a['k-splitbar-draggable-horizontal'] = isHorizontal && isDraggable,
            _a['k-splitbar-draggable-vertical'] = !isHorizontal && isDraggable,
            _a['k-splitbar-static-horizontal'] = isHorizontal && isStatic,
            _a['k-splitbar-static-vertical'] = !isHorizontal && isStatic,
            _a));
        return (React.createElement(Draggable, { onPress: function (event) { return _this.onDrag(event, true, false); }, onDrag: function (event) { return _this.onDrag(event, false, false); }, onRelease: function (event) { return _this.onDrag(event, false, true); }, ref: function (component) { _this.draggable = component; } },
            React.createElement("div", { tabIndex: isStatic ? -1 : 0, role: 'separator', className: barClasses, style: { touchAction: 'none' }, onFocus: this.onFocus, onBlur: this.onBlur, onDoubleClick: this.onToggle, onKeyDown: this.onKeyDown },
                this.props.prev.collapsible && React.createElement("div", { className: 'k-collapse-prev', onClick: this.onPrevToggle },
                    React.createElement(Icon, { name: this.props.prev.collapsible
                            ? isHorizontal
                                ? this.props.prev.collapsed
                                    ? 'caret-alt-right'
                                    : 'caret-alt-left'
                                : this.props.prev.collapsed
                                    ? 'caret-alt-down'
                                    : 'caret-alt-up'
                            : undefined })),
                React.createElement("div", { className: 'k-resize-handle' }),
                this.props.next.collapsible && React.createElement("div", { className: 'k-collapse-next', onClick: this.onNextToggle },
                    React.createElement(Icon, { name: this.props.next.collapsible
                            ? isHorizontal
                                ? this.props.next.collapsed
                                    ? 'caret-alt-left'
                                    : 'caret-alt-right'
                                : this.props.next.collapsed
                                    ? 'caret-alt-up'
                                    : 'caret-alt-down'
                            : undefined })))));
    };
    return SplitterBar;
}(React.Component));
export { SplitterBar };
