"use strict";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import * as styles from "./styles/views_area.module.scss";

export const ViewsArea = (
  {
    selectedPanel,
    viewsConfig,
  }) => {

  return (
    <div className={styles["container"]}>
      {viewsConfig.map((view, idx) => {
        const {id, title, onClick, icon, panel} = view;
        return (
          <div className={"process-bar-panel-option" + (selectedPanel === panel ? " process-bar-panel-option-selected" : "")}
               key={idx}
               id={id}
               onClick={onClick}
               title={title}
          >
            <FontAwesomeIcon icon={icon} className="process-bar-panel-option-icon" />
          </div>
        );
      })}
    </div>
  );
};