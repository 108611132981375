"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import DocumentBulkAddTable from "./tables/document_bulk_add_table";
import DocumentBulkAddResultsTable from "./tables/document_bulk_results_table";
import { FILE_STATUS } from "../../helpers/document_transfer_helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ErrorBar from "../../widgets/bars/error_bar";
import { EditablesService } from "../../services/editables/editables_service";
import {
  BulkOperationsStatusDisplayService
} from "../../services/bulkOperations/bulk_operations_status_display_service";
import { BulkDocumentAddMessageHandler } from "../../services/bulkOperations/bulk_document_add_message_handler";
import { BULK_ADD_STATUS } from "../../dashboard/constants/bulk_operations_constants";
import { can, generateTooltip } from "../../utils/ui_permissions";
import CollapsePanel from "../../widgets/generic/collapse_panel";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import CommonSecurity from "../../../server/common/generic/common_security";
import BaseReactComponent from "../../base_react_component";
import * as ProcessCache from "../../processExplorer/process/process_cache";
import { RouterContext } from "../../utils/router_context";

const Logger = Log.group(LOG_GROUP.Editables, "DocumentBulkAddPopUp");

/**
 * This is the document bulk add popup.
 */
export default class DocumentBulkAddPopUp extends BaseReactComponent {
  static contextType = RouterContext;

  constructor(props) {
    super(props);

    this.state = {
      uploadedFilesCount: 0,
      documents: [],
      processingBulkAdd: false,
      processedRows: 0,
      processedRowsData: [],
      failedRows: 0,
      failedRowsData: [],
      bulkAddStatus: BULK_ADD_STATUS.SAVING,
      showWarningsPanel: false,
      warningsBody: [],
      numberOfWarnings: 0,
    };

    this._isMounted = false;
    this.statusService = new BulkOperationsStatusDisplayService(this.displayStatus, this.hideStatus);
    this.messageHandler = new BulkDocumentAddMessageHandler(this.handleWebSocketConnectionMessage, this.statusService);

    this.ref = React.createRef();
  }

  /**
   * Displays the status message.
   * @param message {string}
   */
  displayStatus(message) {
    if (message) {
      Logger.verbose(() => "Setting bulkAddStatus to:", message);
      this.setStateSafely({
        bulkAddStatus: message,
      });
    }
  }

  /**
   * Hides the status message.
   */
  hideStatus() {
    // Do nothing!
  }

  // noinspection JSMethodCanBeStatic
  handleWebSocketConnectionMessage(message) {
    Logger.verbose(() => "Received message:", message);

    let processedRowsData = this.state.processedRowsData;
    let failedRowsData = this.state.failedRowsData;

    if (message.status === "success" &&
      message.data && message.data.uuid) {
      processedRowsData.push(message.data);
    } else if (message.status === "failure") {
      failedRowsData.push(message.data);
    }

    this.setStateSafely({
      processedRowsData,
      failedRowsData,
      processedRows: this.state.processedRowsData.length,
      failedRows: this.state.failedRowsData.length,
    });
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.ref.current) {
      let documentBulkAddPopup = $(this.ref.current);
      documentBulkAddPopup.modal({keyboard: false, show: true, backdrop: "static"});
      // this will be called automatically when the modal is hidden.
      documentBulkAddPopup.on("hidden.bs.modal", this.props.onHideBulkAddPopup);

      setImmediate(() => {
        $("[data-toggle='validator']").validator("update");
      });
    }
    this._isMounted = true;
  }

  isMounted() {
    return this._isMounted;
  }

  /**
   * This function handle saving of bulk added files.
   */
  handleSaveAndComplete() {
    if (this.isDataValid()) {

      UIUtils.clearError("#documentBulkAddError");

      const projectId = this.props.projectId;
      let processId = null;

      if (projectId) {
        processId = ProcessCache.getProcessIdUsedRecently(projectId);
      }

      this.setStateSafely({
        processingBulkAdd: true,
        showWarningsPanel: false,
      }, () => {

        this.context.preventNavigation();

        const editablesService = new EditablesService(this.messageHandler, this.statusService);
        let instances = this.state.documents.map(document => {
          let majorVersion = UIUtils.parseInt(document.version);

          return {
            name: document.name,
            category: document.category,
            customID: document.customID,
            type: document.type,
            ProjectId: projectId,
            ProcessId: processId,
            author: document.author,
            majorVersion: majorVersion >= 1 ? majorVersion : 0,
            minorVersion: majorVersion >= 1 ? 0 : 1,
            fileName: document.fileName,
            effectiveDate: document.effectiveDate,
            uuid: document.uuid,
            controlled: true,
            uploadDocumentLinks: JSON.stringify(
              [{
                linkType: document.linkType,
                S3TmpKey: document.S3TmpKey,
                S3TmpVersion: document.S3TmpVersion,
                link: document.link,
                linkVersion: document.linkVersion,
                fileName: document.fileName,
                progress: document.progress,
                fileStatus: document.fileStatus,
                size: document.size,
                lastModified: document.lastModified
              }]
            ),
          };
        });

        let arrayBlocks = UIUtils.spliceArray(instances, 100);
        const promises = [];
        for (const blocks of arrayBlocks) {
          let payload = {
            instances: blocks,
          };

          promises.push(editablesService
            .bulkAdd(payload, {model: "document"})
            .catch(UIUtils.defaultFailFunction));
        }

        Promise.all(promises).then(() => {
          Logger.info(() => "All editable service promises are complete.");
          this.context.clearPreventNavigation();
          Logger.verbose(() => "Setting bulkAddStatus to:", BULK_ADD_STATUS.COMPLETED);
          this.setStateSafely({bulkAddStatus: BULK_ADD_STATUS.COMPLETED});
        });
      });
    }
  }

  isDataValid() {
    $("[data-toggle='validator']").validator("validate");
    return $("#documentBulkAddForm")[0].checkValidity();
  }

  handleChildrenChange(record) {
    let {documents, isChildrenValid} = record;
    let uploadedFilesCount = documents
      .filter(document => document.fileStatus === FILE_STATUS.UPLOADED).length;

    this.setStateSafely({
      documents,
      uploadedFilesCount,
      isChildrenValid,
    });
  }

  handleDone() {
    this.hideModal();
    this.props.onBulkAddComplete();
  }

  hideModal() {
    if (this.ref.current) {
      $(this.ref.current).modal("hide");
    }
  }

  handleShowWarnings(warningsBody, numberOfWarnings) {
    this.setStateSafely({
      warningsBody,
      numberOfWarnings,
      showWarningsPanel: true,
    });
  }

  handleShowError(someMessage) {
    UIUtils.showError(someMessage, null, "#documentBulkAddError");
  }

  render() {
    const {files, existingCustomIds} = this.props;
    const {
      uploadedFilesCount, documents, bulkAddStatus,
      processingBulkAdd, processedRows, failedRows,
      processedRowsData, showWarningsPanel, warningsBody,
      numberOfWarnings, isChildrenValid,
    } = this.state;

    const isBulkProcessingComplete = bulkAddStatus === BULK_ADD_STATUS.COMPLETED;
    const canBulkAdd = can(CommonSecurity.Actions.BULK_ADD, CommonSecurity.Types.DOCUMENT);
    const filesUploadInProgress = documents.find(file => file.fileStatus !== FILE_STATUS.UPLOADED);
    const isSaveEnabled = documents.length > 0 && !filesUploadInProgress && canBulkAdd && isChildrenValid;
    const documentsLength = documents.length || files.length;

    let disableBulkAddTitle =
      !canBulkAdd ? generateTooltip(CommonSecurity.Actions.BULK_ADD, CommonSecurity.Types.DOCUMENT) :
        filesUploadInProgress ? "All documents must complete uploading before you can proceed." : "";

    return (
      <div id="documentBulkAddModal" className="modal fade" ref={this.ref}>
        <div className="modal-dialog modal-xl">
          <div id="documentBulkAddContainer" className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title document-bulk-add-title">
                Adding {documentsLength} documents
              </h1>
              {processingBulkAdd ? "" :
                <div id="bulkAddMinimize"
                     className="document-bulk-add-title-minimize-icon"
                     onClick={this.hideModal}
                     data-dismiss="modal"
                >
                  <FontAwesomeIcon icon={faTimes} size="lg" />
                </div>}
            </div>
            <div id="documentBulkAddBody" className="modal-body document-bulk-add-modal-no-padding-bottom">
              <div className="modal-container popup-container document-bulk-add-modal-no-padding-bottom">
                <ErrorBar className="document-bulk-add-error-alert"
                          id="documentBulkAddError"
                />
                <CollapsePanel className={"collapse-card " + (showWarningsPanel ? "" : "d-none")}
                               warningBodyClass="document-bulk-add-warning-body"
                               isWarning={true}
                               collapsedByDefault={true}
                               numOfWarnings={numberOfWarnings}
                               body={<div>{warningsBody}</div>}
                />
                {processingBulkAdd ? (
                  <div id="documentBulkAddResultsInfoDiv"
                       className={"col-sm-12 alert " + (isBulkProcessingComplete ? "alert-success" : "alert-info")}
                  >
                    {isBulkProcessingComplete ? `${processedRows} of ${processedRows + failedRows} records processed successfully.` : bulkAddStatus}
                  </div>
                ) : ""}
                {processingBulkAdd ?
                  <div className="document-bulk-add-progress-bar-div">
                    <div className="progress bulk-proposal-progress-bar">
                      <div className="progress-bar bg-success"
                           role="progressbar"
                           style={{width: `${files.length > 0 ? (isBulkProcessingComplete ? 100 : 90) * processedRows / documentsLength + "%" : "0%"}`}}
                           aria-valuenow="0"
                           aria-valuemin="0"
                           aria-valuemax="100"
                      />
                      <div className="progress-bar bg-danger"
                           role="progressbar"
                           style={{width: `${files.length > 0 ? (isBulkProcessingComplete ? 100 : 90) * failedRows / documentsLength + "%" : "0%"}`}}
                           aria-valuenow="0"
                           aria-valuemin="0"
                           aria-valuemax="100"
                      />
                    </div>
                    <div id="documentBulkAddSaveProgressbarTitle">
                      <span>{"Saved " + (processedRows + failedRows) + "/" + documentsLength}</span>
                    </div>
                  </div> :
                  <div className="document-bulk-add-progress-bar-div">
                    <div className="progress document-bulk-add-progress-bar">
                      <div id="documentBulkAddProgressbar"
                           className="progress-bar"
                           role="progressbar"
                           style={{width: `${files.length > 0 ? 100 * uploadedFilesCount / documentsLength + "%" : "0%"}`}}
                           aria-valuenow="0"
                           aria-valuemin="0"
                           aria-valuemax="100"
                      />
                    </div>
                    <div id="documentBulkAddProgressbarTitle">
                      <span>{"Uploaded " + uploadedFilesCount + "/" + documentsLength}</span>
                    </div>
                  </div>}
                <div>
                  <form id="documentBulkAddForm"
                        autoComplete="off"
                        className={"document-bulk-add-form " +
                          (processingBulkAdd ? "document-bulk-add-results-form" : "")}
                        data-toggle="validator"
                        role="form"
                  >
                    {processingBulkAdd ?
                      <DocumentBulkAddResultsTable
                        documents={documents}
                        paging={false}
                        datatablesDomOptions={"<t>"}
                        isBulkAddCompleted={isBulkProcessingComplete}
                        processedRowsData={processedRowsData}
                      /> :
                      <DocumentBulkAddTable
                        paging={false}
                        datatablesDomOptions={"<t>"}
                        onShowWarnings={this.handleShowWarnings}
                        onShowError={this.handleShowError}
                        existingCustomIds={existingCustomIds}
                        onChildrenChange={this.handleChildrenChange}
                        parent={this}
                        files={files}
                      />}
                  </form>
                </div>
              </div>
            </div>
            <div id="documentBulkAddFooter" className="modal-footer document-bulk-add-footer">
              {processingBulkAdd ? "" :
                <div className="document-bulk-add-modal-footer-text">
                  Documents will be added to QbDVision only after you select save and complete on this screen
                </div>}
              <div className="btn-group">
                {processingBulkAdd ?
                  <button id="doneButton"
                          type="button"
                          disabled={!isBulkProcessingComplete}
                          title={isBulkProcessingComplete ? "" : "All documents must complete saving before you can proceed."}
                          onClick={this.handleDone}
                          className="btn btn-primary"
                  >
                    Done
                  </button> :
                  <Fragment>
                    <button id="saveAndCompleteButton"
                            type="button"
                            disabled={!isSaveEnabled}
                            title={disableBulkAddTitle}
                            onClick={this.handleSaveAndComplete}
                            className="btn btn-primary"
                    >
                      Save and Complete
                    </button>
                    <button id="cancelBulkAddButton"
                            type="submit"
                            onClick={this.hideModal}
                            data-dismiss="modal"
                            className="btn btn-secondary"
                    >
                      Cancel
                    </button>
                  </Fragment>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}