"use strict";
import { RiskTablesTransformStrategy } from "./risk_tables_transform_strategy";
import { RISK_TABLE_REPORT_TO_RMP_ENUM } from "../../../../constants/report_constants";

export class PPToXQARiskTableTransformStrategy extends RiskTablesTransformStrategy {

  get parentReportType() {
    return "XQAs_to_PPs";
  }

  get modelType() {
    return RISK_TABLE_REPORT_TO_RMP_ENUM[this.parentReportType];
  }
}
