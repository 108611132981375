"use strict";

import * as UIUtils from "../../ui_utils";
import moment from "moment-timezone";
import { ExtraPrintItem } from "./extra_print_item";
import PropTypes from "prop-types";
import React from "react";

export function HeaderPrintInfo(props) {
  return <div id="printInfo">
    <div className="print-row">
      <div className="print-label">Exported By:</div>
      <div className="print-data">{props.userName}</div>
    </div>
    <div className="print-row">
      <div className="print-label">Export Date:</div>
      <div className="print-data">
        {UIUtils.getDateForDisplayToUser(moment())}
      </div>
    </div>
    <div className="print-row">
      <div className="print-label">Company:</div>
      <div className="print-data">{UIUtils.getCompany()}</div>
    </div>
    {props.extraPrintItems
      ? Object.values(props.extraPrintItems).sort((a, b) => a.order - b.order).map(
      extraPrintItem => (
        <ExtraPrintItem key={extraPrintItem.label} extraPrintItem={extraPrintItem} />
      )
    ) : ""}
  </div>;
}

HeaderPrintInfo.propTypes = {
  userName: PropTypes.any,
  extraPrintItems: PropTypes.any,
};
