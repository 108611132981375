"use strict";

import ConfigurableTablesAdapter from "./configurable_tables_adapter";
import { formatValue } from "../fieldsConfig/fields_formatter";
import * as CommonEditablesFormatter from "../../../server/common/editables/common_editables_formatter";
import { orderAndIndexUnits } from "../../processExplorer/indexers/uo_indexer";
import moment from "moment/moment";

/**
 * This class adapts/transforms the tech transfer data from server for both PRC and MT.
 */
export default class ConfigurableTablesVariableParentAdapter extends ConfigurableTablesAdapter {
  
  isValidDate(date) {
    return moment(date).isValid() ? date : null;
  }

  getRows() {
    const rows = [];

    const records = Object.values(this.activeMap);
    super.adaptRows(records);

    for (const record of records) {

      record.unitOperations = record.UnitOperations
        ? orderAndIndexUnits(record.UnitOperations, "id", false).map(uo => uo.name).join(", ")
        : "";
      record.steps = record.Steps ? record.Steps.map(step => step.name).join(", ") : "";
      record.supplier = record.Supplier ? record.Supplier.name : "";

      record.certificateOfAnalysis = formatValue(record.certificateOfAnalysis);
      record.drugProductContact = formatValue(record.drugProductContact);
      record.compendialStandard = CommonEditablesFormatter.formatMultiSelectValueForDisplay(record.compendialStandard);

      record.effectiveDate = this.isValidDate(record.effectiveDate) ? new Date(record.effectiveDate) : null;
      record.expirationDate = this.isValidDate(record.expirationDate) ? new Date(record.expirationDate) : null;
      record.isGroupRow = true;
      record.gmp = formatValue(!!record.gmp);

      record.links = record.links || [];
      record.componentRiskLinks = record.componentRiskLinks || [];
      record.componentQualificationLinks = record.componentQualificationLinks || [];
      record.unitQualificationLinks = record.unitQualificationLinks || [];
      record.formulationQuantityLinks = record.formulationQuantityLinks || [];
      record.qualificationLinks = record.qualificationLinks || [];
      record.regulatoryLinks = record.regulatoryLinks || [];
      record.propertiesLinks = record.propertiesLinks || [];

      rows.push(record);
    }

    return this.wrapResults(rows);
  }
}
