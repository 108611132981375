import * as React from 'react';
/**
 * @hidden
 */
var defaultContext = {
    linear: false,
    mode: 'steps',
    value: 0
};
/** @hidden */
export var StepperContext = React.createContext(defaultContext);
