"use strict";

export const FILTER_TYPES = {
  COMBO_BOX: "comboBox",
  NUMERIC: "numeric",
  DATE: "date",
  TEXT: "text",
  LOOKUP: "lookup",
  BOOLEAN: "boolean",
  LINKS: "links"
};

export const DEFAULT_SORTING = [
  {
    "field": "name",
    "dir": "asc"
  }
];

export const DATE_NULL_FILTER_DESCRIPTOR = {
  value: new Date(0),
  operator: "gt"
};
