/**
 * @hidden
 */
export var SIZE_CLASSES = {
    'default': '',
    'xsmall': 'k-icon-xs',
    'small': 'k-icon-sm',
    'medium': 'k-icon-md',
    'large': 'k-icon-lg',
    'xlarge': 'k-icon-xl'
};
