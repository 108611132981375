import { merge } from "../index";
import { ElementParser } from "./elementParser/element_parser";
import { TableParser } from "./table_parser";
import { RepeaterParser } from "./repeater_parser";
import { ReferenceListParser } from "./reference_list_parser";
import { ReferenceBlockParser } from "./reference_block_parser";
import { TableOfContentsParser } from "./table_of_contents_parser";
import { HeaderFooterParser } from "./header_footer_parser";
import { BaseWidgetParser } from "./base_widget_parser";
import { WIDGET_KIND } from "../../components/sideMenu/widget/widget_constants";

export class WidgetParserFactory {
  static getParser(
    globalNode,
    parentNode,
    node,
    data,
    fileType,
    rmp,
    project,
    process,
    parseOnly
  ) {
    const kindToParser = {
      [WIDGET_KIND.Repeater]: RepeaterParser,
      [WIDGET_KIND.Table]: TableParser,
      [WIDGET_KIND.ReferenceList]: ReferenceListParser,
      [WIDGET_KIND.ReferenceBlock]: ReferenceBlockParser,
      [WIDGET_KIND.TableOfContents]: TableOfContentsParser,
      [WIDGET_KIND.Header]: HeaderFooterParser,
      [WIDGET_KIND.Footer]: HeaderFooterParser,
    };
    const kind = node.getAttribute("kind");
    const Parser = kindToParser[kind] || ElementParser;
    return new Parser(
      globalNode,
      parentNode,
      node,
      data,
      fileType,
      rmp,
      project,
      process,
      parseOnly
    );
  }
}

/**
 * The parser that has responsibility to parse all the widgets that are
 * not inside any widget
 */
export class WidgetParser extends BaseWidgetParser {
  get selector() {
    return ".widget";
  }

  get fieldKey() {
    return "widgets";
  }

  getElements() {
    // We only get the widgets are in the body and ignore those nested widgets.
    // They will be parsed by other parsers
    return Array.from(this.node.querySelectorAll(this.selector)).filter(
      (element) => {
        // If a widget is in a list item, we need to check whether it stays inside
        // another widget or not. If it is, we can ignore it.
        if (element?.parentElement.tagName === "LI") {
          return !this.isWidgetItemNested(element.parentElement, element.parentElement.tagName);
        }

        // If a widget element is in a TD, we need to check whether the table contains
        // that TD element stays inside another widget or not. If it is, we can ignore
        // it.
        if (element?.parentElement.tagName === "TD") {
          return !this.isWidgetItemNested(element.parentElement, element.parentElement.tagName);
        }

        return element?.parentElement?.tagName === "BODY";
      }
    );
  }

  isWidgetItemNested(element, tagName) {
    if (element?.getAttribute("kind")) {
      return true;
    }

    if (element?.tagName === "BODY") {
      return false;
    }

    if (tagName === "LI" && element?.parentElement?.tagName === "LI") {
      return this.isWidgetItemNested(element.parentElement, tagName);
    }

    if (tagName === "TD" && element) {
      return this.isWidgetItemNested(element.parentElement, tagName);
    }

    return false;
  }

  parse() {
    const WIDGET_LEVEL_1 = [WIDGET_KIND.ReferenceBlock];
    const WIDGET_LEVEL_2 = [WIDGET_KIND.TableOfContents];

    const allNewElements = [];
    for (const widgetElement of this.getElements()) {
      if (
        [...WIDGET_LEVEL_1, ...WIDGET_LEVEL_2].includes(
          widgetElement.getAttribute("kind")
        )
      ) {
        continue;
      }

      const newElements = [];
      const parser = WidgetParserFactory.getParser(
        this.globalNode,
        this.node,
        widgetElement,
        this.data[this.fieldKey],
        this.fileType,
        this.rmp,
        this.project,
        this.process,
        this.parseOnly
      );
      newElements.push(...parser.parse());
      merge(this.fields, parser.fields);

      if (newElements.length) {
        allNewElements.push(...newElements);
        widgetElement.after(...newElements);
      }

      widgetElement.remove();
    }

    for (const widgetElement of this.getElements()) {
      if (WIDGET_LEVEL_2.includes(widgetElement.getAttribute("kind"))) {
        continue;
      }

      const parser = WidgetParserFactory.getParser(
        this.globalNode,
        this.node,
        widgetElement,
        this.data,
        this.fileType,
        this.rmp,
        this.project,
        this.process,
        this.parseOnly
      );
      widgetElement.after(...parser.parse());
      widgetElement.remove();
    }

    for (const widgetElement of this.getElements()) {
      const parser = WidgetParserFactory.getParser(
        this.globalNode,
        this.node,
        widgetElement,
        this.data,
        this.fileType,
        this.rmp,
        this.project,
        this.process,
        this.parseOnly
      );
      widgetElement.after(...parser.parse());
      widgetElement.remove();
    }

    return allNewElements;
  }
}
