"use strict";

/**
 * This implements the batches and CoAs tables in the data tab.
 */

import React from "react";
import ReactDOM from "react-dom";
import GenericEditableListTable from "../widgets/tables/generic_editable_list_table";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import { CommonSorting } from "../../server/common/generic/common_sorting";
import * as UIUtils from "../ui_utils";
import { getURLByKey } from "../helpers/url_helper";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import ImportFlowOptionsPanel from "../import/widgets/general/import_flow_options_panel";
import { DATA_CATEGORIES, DATA_TYPES, IMPORT_TYPES } from "../import/constants/import_constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TABLE_COLUMN_INDEX } from "../helpers/constants/constants";
import GroupableTablePlugin from "../widgets/tables/tablePlugins/groupable_table_plugin";
import { transformMultiValueForDisplay } from "../../server/common/editables/common_editables";
import { COLUMN_FORMATTERS } from "../widgets/tables/column_formatters";

const GROUP_NAME_COLUMN_INDEX = 1;
const GROUP_NAME_COLUMN_SPAN = 9;

// i18next-extract-mark-ns-start batches
class BatchesListTable extends GenericEditableListTable {
  constructor(props) {
    super(props);

    if (props.useGrouping) {
      this.groupableTablePlugin = new GroupableTablePlugin("typeCode", "materialId", GROUP_NAME_COLUMN_INDEX, GROUP_NAME_COLUMN_SPAN);
    }
    this.columns = this.generateColumns(props);
    this.filteredRowsNum = this.getInstances().length;
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.props.useGrouping) {
      this.groupableTablePlugin.registerTable(this.tableRef);
    }

    $(document).on("click", ".batch-data-import-button-div .dropdown-menu", e => {
      e.stopPropagation();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);

    const table = $(this.tableRef).DataTable();
    const dataRows = table.rows().data();
    this.filteredRowsNum = dataRows.filter(row => !row.isGroupRow).length;
  }

  getFilteredRowsNum() {
    return this.filteredRowsNum;
  }

  generateColumns(props) {
    const {t} = this.props;
    const columnDateFormatter = COLUMN_FORMATTERS.DATE(UIUtils.DATE_FORMAT_FOR_DISPLAY);

    let columns = [];
    if(this.props.dataType === "Drug Substance/Product") {
      columns = [{
        title: t("Type"),
        data: item => transformMultiValueForDisplay(item.batchType),
      }];
    }

    columns = [
      ...columns,
      {
        title: t("Manufacture Date"),
        data: item => item.manufactureDate || "",
        ...columnDateFormatter,
      },
      {
        title: t("Records"),
        data: item => typeof item.totalRecords !== "undefined" ? item.totalRecords : "",
      },
      {
        title: t("Status"),
        data: result => result.currentState || "",
      },
      {
        title: t("Site"),
        data: item => item.site || "",
      },
      {
        title: t("Scale"),
        data: item => item.scale || "",
      },
    ];

    columns = [
      ...(this.groupableTablePlugin && props.useGrouping ? [this.groupableTablePlugin.generateFixedSortingColumn()] : []),
      ...(this.groupableTablePlugin && props.useGrouping ? [this.groupableTablePlugin.generateExpandIndicatorColumn()] : []),
      this.generateIDColumn(),
      ...(columns.map(column => {
        return {
          ...column,
          data: this.createMappedCell(column.data),
          createdCell: column.createdCell,
        };
      })),
      this.generateManageColumn(),
    ];

    return columns;
  }

  getOrderByColumnIndex() {
    return TABLE_COLUMN_INDEX.ID;
  }

  initComplete() {
    this.tableInitCompleted = true;
    this.renderShowArchived();
  }

  getTableInitializationOptions() {
    let options = super.getTableInitializationOptions();

    if (this.props.useGrouping) {
      options.rowCallback = this.groupableTablePlugin.renderRow;
    }

    return options;
  }

  prepareRecordsForDisplay(records = []) {
    const {useGrouping, materials} = this.props;

    if (useGrouping) {
      const updateRecordInfo = record => {
        record.typeCode = "BAT";
        record.material = materials.find(material => material.id === record.materialId);
        record.material.typeCode = "MT";
        record.sortingKey = `${record.material.name}-${record.material.id}-rec`;
        record.fullName = UIUtils.getRecordCustomIdForDisplay(record.material);
        return record;
      };

      records = records.map(updateRecordInfo);
      this.groupBatchRecords(records);
    }

    return records;
  }

  /**
   * This groups the CoA records based on their Material.
   * @param records
   */
  groupBatchRecords(records) {
    let {materials} = this.props;
    if (materials) {
      records = records.sort(CommonSorting.sortAttributesByParentAndTypeCodeAndSomeField(materials, "material", "customID"));
    }

    for (let i = 0; i < records.length; i++) {
      const batch = records[i];
      let previousBatch = records[i - 1];
      const {materialId, isGroupRow, material} = batch;
      const sortingKey = `${material.name}-${materialId}`;

      if (isGroupRow || (previousBatch && previousBatch.isGroupRow)) {
        continue;
      }

      if (materialId && (!previousBatch || previousBatch.materialId !== materialId)) {
        const totalRecordsInGroup = records.filter(record => !record.isGroupRow && record.materialId === materialId).length;
        records.splice(i, 0,
          this.groupableTablePlugin.createGroupRow(true, true, null, materialId,
            this.getGroupTitle(batch.material, totalRecordsInGroup), sortingKey));
        previousBatch = records[i];
      }
    }

    return records;
  }

  /**
   * This gets the title for a material group by row.
   * @param material The Material the list of CoAs is being grouped by.
   * @param totalRecordsInGroup The total number of CoAs for the material.
   * @returns {string}
   */
  getGroupTitle(material, totalRecordsInGroup) {
    return `${UIUtils.getRecordCustomLabelForDisplay(material)} (${totalRecordsInGroup})`;
  }

  generateIDColumn(action = "View") {
    let idColumn = super.generateIDColumn(action);
    idColumn.title = "Batch ID";
    return idColumn;
  }

  getRecordId(result, type) {
    if (result.isGroupRow) {
      return result.fullName;
    }

    return super.getRecordId(result, type);
  }

  createIdCell(td, cellData, rowData) {
    if (rowData.isGroupRow) {
      const url = getURLByKey(rowData.fullName, "View");

      ReactDOM.render(
        <div>
          <a href={url}
             rel="noopener noreferrer"
             target="_blank"
          >
            {rowData.fullName}
          </a>
        </div>, td);
    } else {
      super.createIdCell(td, cellData, rowData);
    }
  }

  getImportTypeConfigs() {
    const {dataType} = this.props;
    if (dataType === DATA_TYPES.MATERIAL) {
      return IMPORT_TYPES.PROCESS_DATA.filter(config => config.dataType === dataType);
    } else {
      return IMPORT_TYPES.PROCESS_DATA.filter(config => config.dataType === dataType
        && (config.dataCategory === DATA_CATEGORIES.MANUFACTURING || config.dataCategory === DATA_CATEGORIES.RELEASE));
    }
  }

  getManageColumnControls(optionsMenuBuilder, rowData, row) {
    const {projectId, showImportButton, importDependenciesStatus} = this.props;
    let controls = super.getManageColumnControls(optionsMenuBuilder, rowData, row);

    if (showImportButton) {
      controls.unshift(
        <div key="importDataButton"
             className="btn-group batch-data-import-button-div"
        >
          <button id={`importDataButton_${row}`}
                  className="btn batch-data-import-list-button"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-boundary="viewport"
          >
            <FontAwesomeIcon icon={faFileExport} />
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <ImportFlowOptionsPanel id={`importData_options_panel_${row}`}
                                    projectId={projectId}
                                    processId={rowData.processId}
                                    batchId={rowData.id}
                                    importConfigs={this.getImportTypeConfigs()}
                                    importDependenciesStatus={importDependenciesStatus}
            />
          </div>
        </div>
      );
    }

    return controls;
  }

  getShowArchivedCheckboxId() {
    let id = super.getShowArchivedCheckboxId();

    return `${this.props.id}_${id}`;
  }
}

export default I18NWrapper.wrap(BatchesListTable, ["batches", "documents"]);

// i18next-extract-mark-ns-stop batches
