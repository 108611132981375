"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import BasePage from "../base_page";
import CommonEditablesPageTitleBar from "../widgets/pageTitleBar/common_editables_page_title_bar";
import SupplierTablePage from "./supplier_table_page";
import TableTitleBar from "../configurableTables/tables/widgets/topBar/titleBar/table_title_bar";
import { SELECTED_TABLES } from "../configurableTables/tables/widgets/topBar/constants";
import { NotificationProvider } from "../configurableTables/tables/widgets/notification/notification_provider";
import InfoNotification from "../configurableTables/tables/widgets/notification/types/info_notification";
import { MoreActionsLink } from "../processExplorer/widgets/topBar/titleBar/more_actions_link";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import { process } from "@progress/kendo-data-query";
import { filterFields } from "../configurableTables/columnSelection/configurable_tables_column_selection_helper";
import { SupplierConfigurableTablesExport } from "./tables/supplier_configurable_table_export";
import { SUPPLIER_CONFIG } from "./tables/supplier_table_config";
// i18next-extract-mark-ns-start suppliers

/* This class shows the list of suppliers. */
class SupplierListPage extends BasePage {
  constructor(props) {
    super(props);

    this.selectedModelType = SELECTED_TABLES.SUPPLIERS;
    const { t } = props;

    this.setStateSafely({
      breadcrumb: {
        current: t("Suppliers"),
      },
    });
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = t("QbDVision Suppliers");

    super.componentDidMount();
  }

  static handleAdd() {
    window.location.href = UIUtils.getSecuredURL("viewEdit.html?operation=Add");
  }

  handleVisibleColumnsChange(visibleTableColumns) {
    this.setStateSafely({visibleTableColumns});
  }

  handleTableStateChange(tableState) {
    this.setStateSafely({tableState});
  }
  handleUpdateTableDependencies(dependencies) {
    this.setStateSafely(dependencies);
  }
  handleShowArchive(showArchived) {
    this.setStateSafely({showArchived});
    UIUtils.pushHistoryURLWithNewParameter({}, "showArchived", showArchived);
  }
  handleTableDataUpdated(tableData) {
    this.setStateSafely({tableData});
  }

  handleExport() {
    const {
      columnOperationsAdapter,
      tableData,
      showArchived,
      visibleTableColumns,
    } = this.state;

    const records = tableData ? process(tableData.slice(0),
      columnOperationsAdapter.adaptFilterNames(this.state)).data : [];


    new SupplierConfigurableTablesExport({
      selectedModelType: this.selectedModelType,
      records,
      modelsConfig: SUPPLIER_CONFIG,
      feature: "Suppliers",
      showArchived,
      visibleTableColumns: filterFields(visibleTableColumns || []),
    }).exportReport();
  }

  renderPage() {
    const { t } = this.props;

    return (
      <>
        <CommonEditablesPageTitleBar name={t("Suppliers")} hasNoLinksInTitleBar={true} />
        <NotificationProvider>
          <TableTitleBar t={t}
                         table={this.selectedModelType}
                         modelName={"Supplier"}
                         selectedModelType={this.selectedModelType}
                         onExport={this.handleExport}
                         moreActions={
                           <MoreActionsLink id={"ApprovedSuppliersListReportName"} label={"Approved Suppliers List"} onClick={() => window.open(
                             UIUtils.getSecuredURL(
                             `/reports/cannedReports/base.html?reportType=ApprovedSuppliersList`
                             ), "_blank")}
                           />
                        }
          />
          <SupplierTablePage
            onTableStateChange={this.handleTableStateChange}
            onUpdateTableDependencies={this.handleUpdateTableDependencies}
            onVisibleColumnsChange={this.handleVisibleColumnsChange}
            onTableDataUpdated={this.handleTableDataUpdated}
            selectedModelType={this.selectedModelType}
            handleShowArchive={this.handleShowArchive}
            modelName={this.selectedModelType}
            showArchived={this.state.showArchived}
          />

          <InfoNotification />
        </NotificationProvider>
      </>
    );
  }
}

export default I18NWrapper.wrap(SupplierListPage, "suppliers");
// i18next-extract-mark-ns-stop suppliers
