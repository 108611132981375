"use strict";

import BasePage from "../../../base_page";
import * as UIUtils from "../../../ui_utils";
import React, { Fragment } from "react";
import * as FailHandlers from "../../../utils/fail_handlers";
import CommonEditablesPageTitleBar from "../../../widgets/pageTitleBar/common_editables_page_title_bar";
import { LibraryHelper } from "../../helpers/library_helper";
import { getURLByKey } from "../../../helpers/url_helper";
import { faLink, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SpecificationTable from "./specification_table";
import { MODEL_DECLARATIONS } from "../../../../server/common/generic/common_models";


export default class CompareProjectMaterialAttributePage extends BasePage {
  constructor(props) {
    super(props);

    const initialState = {
      MaterialId: UIUtils.getParameterByName("materialId"),
      ProjectId: UIUtils.getParameterByName("projectId"),
      ProcessId: UIUtils.getParameterByName("processId"),
      isLoading: true
    };

    this.setStateSafely({...initialState});
  }

  componentDidMount() {
    super.componentDidMount();

    document.title = "Compare Material Attributes with Library Specifications";
    this.loadData();
  }

  loadData() {
    this.setStateSafely({isLoading: true});

    const urlParameters = {
      includeHistory: true,
      loadFullHistory: true,
      includeChildRecords: true,
    };

    const materialId = UIUtils.getParameterByName("materialId");
    UIUtils.secureAjaxGET(
      `editables/Material/${materialId}`,
      urlParameters,
      true,
      FailHandlers.defaultStopLoadingFailFunction.bind(this)
    ).then(results => this.handleResultsReceivedFromServer(results));
  }

  handleResultsReceivedFromServer(result) {
    const material = result;

    if (!LibraryHelper.recordHasLibraryAttached(material)) {
      throw new Error("Material is not linked to library");
    }

    const fields = ["lowerLimit", "upperLimit", "target", "measure", "measurementUnits"];
    const materialAttributes = material.childInstances.map(materialAttribute => {
      const record = {
        id: materialAttribute.id,
        name: materialAttribute.name,
        model: "MaterialAttribute",
        linkedToSpecificationVersionId: materialAttribute.linkedToSpecificationVersionId
      };

      const acceptanceCriteria = materialAttribute.Requirement?.AcceptanceCriteriaRanges?.find(range => range.isDefault);
      if (acceptanceCriteria) {
        fields.forEach(field => {
          record[field] = acceptanceCriteria[field];
        });
      }

      record.measure = materialAttribute.measure;

      return record;
    });

    const specifications = material.fromLibrary.specifications.map(specification => {
      const record = {
        id: specification.recordId,
        versionId: specification.id,
        name: specification.name,
      };

      fields.forEach(field => {
        record[field] = specification[field];
      });

      return record;
    });

    const maSpecificationMappingTmp = materialAttributes.map(materialAttribute => {
      const specification = specifications.find(specification => specification.versionId === materialAttribute.linkedToSpecificationVersionId);
      const emptyFields = fields.filter(field => !LibraryHelper.recordFieldValueExists(materialAttribute, field) &&
        (specification && !LibraryHelper.recordFieldValueExists(specification, field) || !specification));
      return {
        materialAttribute,
        specification,
        emptyFields
      };
    });

    const maSpecificationMapping = maSpecificationMappingTmp.filter(mapping => mapping.specification);
    const unmappedMaterialAttributes = maSpecificationMappingTmp.filter(mapping => !mapping.specification).map((mapping) => {
      return {
        ...mapping.materialAttribute,
        analyticsUrl: UIUtils.getSecuredURL(`/reports/chartReports.html?projectId=${this.state.ProjectId}&processId=${this.state.ProcessId}&reportType=ControlChartsContinuousReport&modelLabel=${MODEL_DECLARATIONS.MA.typeCode}-${mapping.materialAttribute.id}`)
      };
    });
    const unmappedSpecifications = specifications.filter(specification => !materialAttributes.find(materialAttribute => materialAttribute.linkedToSpecificationVersionId === specification.versionId))
      .map(specification => {
        return {
          ...specification,
          analyticsUrl: UIUtils.getSecuredURL(`/reports/chartReports.html?reportType=LibraryControlChartsContinuousReport&parentId=${material.LibraryMaterialId}&modelLabel=${MODEL_DECLARATIONS.SPECIFICATION.typeCode}-${specification.id}`)
        };
      });

    this.setStateSafely({
      material,
      library: {
        ...material.fromLibrary,
        id: material.fromLibrary.LibraryMaterialId,
        versionId: material.fromLibrary.id
      },
      maSpecificationMapping,
      unmappedMaterialAttributes,
      unmappedSpecifications,
      isLoading: false
    });
  }

  renderPage() {
    const {isLoading, library, material, unmappedMaterialAttributes, unmappedSpecifications} = this.state;

    if (isLoading) {
      return (<Fragment></Fragment>);
    }

    const libraryKey = this.getRecordFullKey(library);
    const materialKey = this.getRecordFullKey(material, "Material");

    const title = "Compare Material Attributes with Library Specifications";
    return (
      <Fragment>
        <CommonEditablesPageTitleBar name={title} />
        <div className="container-spacer" />
        <div className="container compare-specification-ma-container  min-vh-100">
          <div className="row-white">
            <div className="col-12">
              {this.renderHeaderRow()}
              {this.renderCompareRows()}
            </div>
          </div>
          {unmappedSpecifications.length > 0 &&
            <div className="row-white">
              <div className="col-12">
                <SpecificationTable
                  data={unmappedSpecifications}
                  tableId={"otherLibrarySpecifications"}
                  title={"Other Library Specifications"}
                  parentName={library.name}
                  parentKey={libraryKey}
                  parentLink={getURLByKey(libraryKey, "View", false)}
                />
              </div>
            </div>}
          {unmappedMaterialAttributes.length > 0 &&
            <div className="row-white">
              <div className="col-12">
                <SpecificationTable
                  data={unmappedMaterialAttributes}
                  tableId={"otherMaterialAttributes"}
                  title={"Other Material Attributes"}
                  parentName={material.name}
                  parentKey={materialKey}
                  firstColumnLabel={"Material Attribute"}
                  parentLink={getURLByKey(materialKey, "View", false)}
                />
              </div>
            </div>}
        </div>
      </Fragment>
    );
  }

  renderCompareRows() {
    const {maSpecificationMapping} = this.state;

    return maSpecificationMapping.map(mapping => this.renderCompareRow(mapping.specification, mapping.materialAttribute, mapping.emptyFields));
  }

  renderCompareRow(specification, materialAttribute, emptyFields = []) {
    const differences = LibraryHelper.getLibrarySpecificationRecordDifferences(materialAttribute, specification);
    const materialAttributeKey = this.getRecordFullKey(materialAttribute, "MaterialAttribute");

    return (
      <div key={specification.id + "-" + materialAttribute.id} data-test={specification.name.trim()} className={"row compare-row " + (differences.length > 0 ? "warning" : "")}>
        <div className="col-6 left col library-specification">
          <h3 className="specification-name">{specification.name.trim()}</h3>
          {this.renderAttributes(specification, [], emptyFields)}
        </div>
        <div className="col-6 right col material-attribute">
          <h3 className="material-name">
            <a href={getURLByKey(materialAttributeKey, "View", false)}
               target="_blank"
               rel="noreferrer noopener"
            >
              {materialAttribute.name}
            </a>
            <span className="key">{materialAttributeKey}</span>
          </h3>
          {this.renderAttributes(materialAttribute, differences, emptyFields)}
          <FontAwesomeIcon className="icon-link" title={"Linked to Library Specification"} icon={faLink} />
          {differences.length > 0 ? (
            <FontAwesomeIcon className="icon-warning tab-nav-bar-warning-icon-yellow" icon={faTriangleExclamation} />) : ""}
        </div>
      </div>);
  }

  renderAttributes(record, differences = [], emptyFields = []) {
    const attributes = this.getAttributes(record);
    return attributes.filter(attribute => !emptyFields.includes(attribute))
      .map(attribute => this.renderAttribute(attribute, record[attribute], differences.find(difference => difference === attribute) !== undefined));
  }

  getAttributes(record) {
    let attributes = [];

    switch (record.measure) {
      case "Range":
        attributes = ["lowerLimit", "target", "upperLimit"];
        break;
      case "Lower Limit (NLT)":
        attributes = ["lowerLimit", "target"];
        break;
      case "Upper Limit (NMT)":
        attributes = ["target", "upperLimit"];
        break;
      case "Defects (Pass/Fail)":
        attributes = ["target", "upperLimit"];
        break;
      case "Conforms (Pass/Fail)":
        attributes = ["target"];
        break;
      default:
        throw new Error("Not implemented for " + record.measure);
    }
    attributes.push("measurementUnits");
    attributes.unshift("measure");

    return attributes;
  }

  renderAttribute(attributeKey, attributeValue, markAsDifferent = false) {
    return (
      <div key={attributeKey} data-test={attributeKey} className="row attribute-row">
        <div className="col-4 attribute-name">{UIUtils.convertCamelCaseToSpacedOutWords(attributeKey)}</div>
        <div className={"col-8 attribute-value"}>
          <span className={(markAsDifferent ? "warning" : "")}>{attributeValue}</span>
        </div>
      </div>
    );
  }

  renderHeaderRow() {
    const {material, library} = this.state;
    const libraryKey = this.getRecordFullKey(library);
    const materialKey = this.getRecordFullKey(material, "Material");

    return (
      <Fragment>
        <div className="row header-row">
          <div className="col-6 left">
            <h2>Library Specifications</h2>
            <a className="library-name header-link" href={getURLByKey(libraryKey, "View", false)}
               target="_blank"
               rel="noreferrer noopener"
            >
              {library.name}
            </a>
            <span className="key">{libraryKey}</span>
          </div>
          <div className="col-6 right">
            <h2>Material Attributes</h2>
            <a className="material-name header-link" href={getURLByKey(materialKey, "View", false)}
               target="_blank"
               rel="noreferrer noopener"
            >
              {material.name}
            </a>
            <span className="key">{materialKey}</span>
          </div>
        </div>
      </Fragment>
    );
  }

  getRecordFullKey(record, modelName = null) {
    return `${UIUtils.getTypeCodeForModelName(modelName ?? record.model)}-${record.id}`;
  }
}
