"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import moment from "moment";
import { generateTooltip } from "../../utils/ui_permissions";
import { getImportConfigFromKey, IMPORT_TYPE_KEY } from "../../import/constants/import_constants";
import { createImportLinkURL } from "../../import/helpers/import_url_factory";
import CommonURLs from "../../../server/common/generic/common_urls";
import CommonSecurity from "../../../server/common/generic/common_security";
import BaseRelatedRecordsTableWithProcess from "../relatedRecords/base_related_records_table_with_process";

/**
 * This is responsible for rendering the coa import records for an attribute in the view attribute page.
 */
export default class COAImportsTable extends BaseRelatedRecordsTableWithProcess {
  constructor(props) {
    super(props);

    this.handleImportButtonClick = this.handleImportButtonClick.bind(this);
  }

  /**
   * WARNING: shouldComponentUpdate is not being called when the component is viewed in process explorer.
   * I suspect we have a context defined that sends the upstream parameters
   * https://stackoverflow.com/questions/58132922/shouldcomponentupdate-is-not-being-called
   * @param nextProps
   * @param nextState
   * @returns {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
    if (!shouldUpdate) {
      shouldUpdate = nextProps.hasMaterialAttributes !== this.props.hasMaterialAttributes;
    }

    return shouldUpdate;
  }

  getTableInitializationOptions() {
    return {
      dom: "<t>",
      data: this.props.records,
      columns: this.getColumns(),
      bSort: false,
      scrollY: "250px",
      scrollCollapse: true,
      paging: false,
      stateSave: true,
    };
  }

  prepareRecordsForDisplay(records) {
    return records.sort((a, b) => {
      let aCreatedAt = moment(a.createdAt);
      let bCreatedAt = moment(b.createdAt);
      return aCreatedAt > bCreatedAt ? -1 : aCreatedAt === bCreatedAt ? 0 : 1;
    });
  }

  getColumns() {
    return [
      {
        data: (result, type) => {
          if (type === "display") {
            return ReactDOMServer.renderToStaticMarkup(
              <div>
                <div>
                  <span>Batch Id </span>
                  <a href={CommonURLs.getImportResultsReviewURL(result.ProjectId, result.modelToImport,
                    result.dependency, result.dataSectionName, result.id)}
                     rel="noopener noreferrer"
                     target="_blank"
                  >
                    {result.customID}
                  </a>
                </div>
                <div>
                  {`Imported on ${moment(result.createdAt).format(UIUtils.DATE_FORMAT_FOR_DISPLAY)}`}
                </div>
              </div>
            );
          } else {
            return result.id;
          }
        }
      },
    ];
  }

  handleImportButtonClick() {
    if (this.state.canEdit) {
      let thisProject = this.getProject();
      const importConfig = getImportConfigFromKey(IMPORT_TYPE_KEY.COA);

      window.location = createImportLinkURL({
        ...importConfig,
        projectId: thisProject.id,
        processId: this.props.processId,
        projectType: thisProject.type,
        dependency: this.props.parentModelName,
        selectionTableModelTypes: `["${this.props.parentModelName}"]`,
        selectedDependencyRecordId: this.props.parentId
      });
    }
  }

  render() {
    const {hasMaterialAttributes} = this.props;
    let thisProject = this.getProject();
    let thisProcess = this.getProcess();

    return (
      <div className="coa-imports-container row-white">
        <div className="coa-records-title">
          <div>
            {this.props.header}
          </div>
          <div>
            {!(thisProject && thisProject.deletedAt)
            && !(thisProcess && thisProcess.deletedAt)
            && hasMaterialAttributes ? (
              <button id={"importCOAs"}
                      title={generateTooltip(CommonSecurity.Actions.EDIT, this.modelFullName)}
                      className="btn btn-sm btn-secondary coa-add-button"
                      onClick={this.handleImportButtonClick}
                      disabled={!this.state.canEdit}
              >
                Import
              </button>
            ) : ""}
          </div>
        </div>
        <div>
          <table ref={ref => this.tableRef = ref}
                 className="table table-hover coa-records-table" id={`${this.props.id}Table`}
                 style={{width: "100%"}}
          />
        </div>
      </div>
    );
  }
}
