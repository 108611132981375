"use strict";
const CustomConditionalVerification = require("./custom_conditional_verification");

function isMissing(attribute, isCollection) {
    return attribute === undefined || attribute === null || (isCollection ? attribute.length === 0 : attribute.toString().trim() === "");
}

let fieldsConfiguration = {};
let validate = {};


  validate["AcceptanceCriteriaRange"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["AcceptanceCriteriaRangeLinkedVersion"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["AttachmentAudit"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["Batch"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.customID))
    {
        requiredFields.push("Custom I D");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(instance.approvalInfo && isMissing(instance.scale))
    {
        requiredFields.push("Scale");
    }

    if(instance.approvalInfo && isMissing(instance.manufactureDate))
    {
        requiredFields.push("Manufacture Date");
    }

    if(instance.approvalInfo && isMissing(instance.releaseDate))
    {
        requiredFields.push("Release Date");
    }

    if(instance.approvalInfo && isMissing(instance.expirationDate))
    {
        requiredFields.push("Expiration Date");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["BatchApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["BatchApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["BatchApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["BatchApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["BatchAttribute"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["BatchAttributeData"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["BatchHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["BatchVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.customID))
    {
        requiredFields.push("Custom I D");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(instance.approvalInfo && isMissing(instance.scale))
    {
        requiredFields.push("Scale");
    }

    if(instance.approvalInfo && isMissing(instance.manufactureDate))
    {
        requiredFields.push("Manufacture Date");
    }

    if(instance.approvalInfo && isMissing(instance.releaseDate))
    {
        requiredFields.push("Release Date");
    }

    if(instance.approvalInfo && isMissing(instance.expirationDate))
    {
        requiredFields.push("Expiration Date");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["BatchVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ControlMethod"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ControlMethodApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ControlMethodApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ControlMethodApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ControlMethodApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ControlMethodVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ControlMethodVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["Curriculum"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(instance.approvalInfo && isMissing(instance.Documents, true) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Documents")) {
          requiredFields.push("Documents");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["CurriculumApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["CurriculumApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["CurriculumApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["CurriculumApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["CurriculumAssignment"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["CurriculumAssignmentLinkedVersion"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["CurriculumVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(instance.approvalInfo && isMissing(instance.Documents, true) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Documents")) {
          requiredFields.push("Documents");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["CurriculumVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["Document"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && CustomConditionalVerification.isFileNotAttachedToDocument(instance))
    {
        requiredFields.push("Upload Document Links");
    }

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["DocumentApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["DocumentApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["DocumentApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["DocumentApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["DocumentContent"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["DocumentContentApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["DocumentContentApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["DocumentContentApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["DocumentContentApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["DocumentContentVersion"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["DocumentContentVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["DocumentVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && CustomConditionalVerification.isFileNotAttachedToDocument(instance))
    {
        requiredFields.push("Upload Document Links");
    }

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["DocumentVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FPA"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.scope))
    {
        requiredFields.push("Scope");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(isMissing(instance.category))
    {
        requiredFields.push("Category");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }

    if(isMissing(instance.measure))
    {
        requiredFields.push("Measure");
    }

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityRisk))
    {
        requiredFields.push("Capability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityJustification))
    {
        requiredFields.push("Capability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityRisk))
    {
        requiredFields.push("Detectability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityJustification))
    {
        requiredFields.push("Detectability Justification");
    }

    if(instance.approvalInfo && CustomConditionalVerification.hasNoCriticalityJustification(instance))
    {
        requiredFields.push("Criticality Justification");
    }

    if(instance.approvalInfo && isMissing(instance.controlStrategy))
    {
        requiredFields.push("Control Strategy");
    }

    if(instance.approvalInfo && isMissing(instance.FPAToGeneralAttributeRisks, true) && CustomConditionalVerification.isFPANotAttachedToGeneralAttribute(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Risk link(s) to General Attributes")) {
          requiredFields.push("Risk link(s) to General Attributes");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FPAApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FPAApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FPAApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FPAApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FPAToGeneralAttributeRisk"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FPAToGeneralAttributeRiskLinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FPAVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.scope))
    {
        requiredFields.push("Scope");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(isMissing(instance.category))
    {
        requiredFields.push("Category");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }

    if(isMissing(instance.measure))
    {
        requiredFields.push("Measure");
    }

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityRisk))
    {
        requiredFields.push("Capability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityJustification))
    {
        requiredFields.push("Capability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityRisk))
    {
        requiredFields.push("Detectability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityJustification))
    {
        requiredFields.push("Detectability Justification");
    }

    if(instance.approvalInfo && CustomConditionalVerification.hasNoCriticalityJustification(instance))
    {
        requiredFields.push("Criticality Justification");
    }

    if(instance.approvalInfo && isMissing(instance.controlStrategy))
    {
        requiredFields.push("Control Strategy");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FPAVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FQA"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.scope))
    {
        requiredFields.push("Scope");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(isMissing(instance.category))
    {
        requiredFields.push("Category");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }

    if(isMissing(instance.measure))
    {
        requiredFields.push("Measure");
    }

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityRisk))
    {
        requiredFields.push("Capability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityJustification))
    {
        requiredFields.push("Capability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityRisk))
    {
        requiredFields.push("Detectability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityJustification))
    {
        requiredFields.push("Detectability Justification");
    }

    if(instance.approvalInfo && CustomConditionalVerification.hasNoCriticalityJustification(instance))
    {
        requiredFields.push("Criticality Justification");
    }

    if(instance.approvalInfo && isMissing(instance.controlStrategy))
    {
        requiredFields.push("Control Strategy");
    }

    if(instance.approvalInfo && isMissing(instance.FQAToGeneralAttributeRisks, true) && CustomConditionalVerification.isFQANotAttachedToGeneralAttribute(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Risk link(s) to General Attributes")) {
          requiredFields.push("Risk link(s) to General Attributes");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FQAApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FQAApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FQAApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FQAApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FQAToGeneralAttributeRisk"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FQAToGeneralAttributeRiskLinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FQAVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.scope))
    {
        requiredFields.push("Scope");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(isMissing(instance.category))
    {
        requiredFields.push("Category");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }

    if(isMissing(instance.measure))
    {
        requiredFields.push("Measure");
    }

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityRisk))
    {
        requiredFields.push("Capability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityJustification))
    {
        requiredFields.push("Capability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityRisk))
    {
        requiredFields.push("Detectability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityJustification))
    {
        requiredFields.push("Detectability Justification");
    }

    if(instance.approvalInfo && CustomConditionalVerification.hasNoCriticalityJustification(instance))
    {
        requiredFields.push("Criticality Justification");
    }

    if(instance.approvalInfo && isMissing(instance.controlStrategy))
    {
        requiredFields.push("Control Strategy");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["FQAVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["GeneralAttribute"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["GeneralAttributeApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["GeneralAttributeApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["GeneralAttributeApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["GeneralAttributeApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["GeneralAttributeVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["GeneralAttributeVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["Import"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IPA"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }

    if(isMissing(instance.measure))
    {
        requiredFields.push("Measure");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityRisk))
    {
        requiredFields.push("Capability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityJustification))
    {
        requiredFields.push("Capability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityRisk))
    {
        requiredFields.push("Detectability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityJustification))
    {
        requiredFields.push("Detectability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.controlStrategy))
    {
        requiredFields.push("Control Strategy");
    }

    if(instance.approvalInfo && isMissing(instance.IPAToIPAs, true) && CustomConditionalVerification.hasNoIPAToIQAAndFinalAttributes(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Risk link(s) to Intermediate / Final Attributes")) {
          requiredFields.push("Risk link(s) to Intermediate / Final Attributes");
      }
    }
    if(instance.approvalInfo && isMissing(instance.IPAToIQAs, true) && CustomConditionalVerification.hasNoIPAToIPAAndFinalAttributes(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Risk link(s) to Intermediate / Final Attributes")) {
          requiredFields.push("Risk link(s) to Intermediate / Final Attributes");
      }
    }
    if(isMissing(instance.ProcessId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Process")) {
          requiredFields.push("Process");
      }
    }
    if(isMissing(instance.UnitOperationId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Unit Operation")) {
          requiredFields.push("Unit Operation");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IPAApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IPAApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IPAApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IPAApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IPAToFPA"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IPAToFPALinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IPAToFQA"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IPAToFQALinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IPAToIPA"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IPAToIPALinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IPAToIQA"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IPAToIQALinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IPAVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }

    if(isMissing(instance.measure))
    {
        requiredFields.push("Measure");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityRisk))
    {
        requiredFields.push("Capability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityJustification))
    {
        requiredFields.push("Capability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityRisk))
    {
        requiredFields.push("Detectability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityJustification))
    {
        requiredFields.push("Detectability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.controlStrategy))
    {
        requiredFields.push("Control Strategy");
    }

    if(isMissing(instance.ProcessId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Process")) {
          requiredFields.push("Process");
      }
    }
    if(isMissing(instance.UnitOperationId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Unit Operation")) {
          requiredFields.push("Unit Operation");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IPAVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IQA"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }

    if(isMissing(instance.measure))
    {
        requiredFields.push("Measure");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityRisk))
    {
        requiredFields.push("Capability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityJustification))
    {
        requiredFields.push("Capability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityRisk))
    {
        requiredFields.push("Detectability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityJustification))
    {
        requiredFields.push("Detectability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.controlStrategy))
    {
        requiredFields.push("Control Strategy");
    }

    if(instance.approvalInfo && isMissing(instance.IQAToIPAs, true) && CustomConditionalVerification.hasNoIQAToIQAAndFinalAttributes(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Risk link(s) to Intermediate / Final Attributes")) {
          requiredFields.push("Risk link(s) to Intermediate / Final Attributes");
      }
    }
    if(instance.approvalInfo && isMissing(instance.IQAToIQAs, true) && CustomConditionalVerification.hasNoIQAToIPAAndFinalAttributes(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Risk link(s) to Intermediate / Final Attributes")) {
          requiredFields.push("Risk link(s) to Intermediate / Final Attributes");
      }
    }
    if(isMissing(instance.ProcessId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Process")) {
          requiredFields.push("Process");
      }
    }
    if(isMissing(instance.UnitOperationId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Unit Operation")) {
          requiredFields.push("Unit Operation");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IQAApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IQAApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IQAApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IQAApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IQAToFPA"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IQAToFPALinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IQAToFQA"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IQAToFQALinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IQAToIPA"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IQAToIPALinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IQAToIQA"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IQAToIQALinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IQAVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }

    if(isMissing(instance.measure))
    {
        requiredFields.push("Measure");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityRisk))
    {
        requiredFields.push("Capability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityJustification))
    {
        requiredFields.push("Capability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityRisk))
    {
        requiredFields.push("Detectability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityJustification))
    {
        requiredFields.push("Detectability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.controlStrategy))
    {
        requiredFields.push("Control Strategy");
    }

    if(isMissing(instance.ProcessId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Process")) {
          requiredFields.push("Process");
      }
    }
    if(isMissing(instance.UnitOperationId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Unit Operation")) {
          requiredFields.push("Unit Operation");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["IQAVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ITP"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.CurriculumAssignments, true) && CustomConditionalVerification.isDocumentNotAttachedToITP(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Curricula")) {
          requiredFields.push("Curricula");
      }
    }
    if(instance.approvalInfo && isMissing(instance.Documents, true) && CustomConditionalVerification.isCurriculumNotAttachedToITP(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Documents")) {
          requiredFields.push("Documents");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ITPApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ITPApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ITPApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ITPApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ITPVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.Documents, true) && CustomConditionalVerification.isCurriculumNotAttachedToITP(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Documents")) {
          requiredFields.push("Documents");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ITPVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["LibraryMaterial"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.category))
    {
        requiredFields.push("Category");
    }

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(instance.approvalInfo && isMissing(instance.materialQualified))
    {
        requiredFields.push("Material Qualified");
    }

    if(instance.approvalInfo && CustomConditionalVerification.materialHasNoEffectiveDate(instance))
    {
        requiredFields.push("Effective Date");
    }

    if(instance.approvalInfo && isMissing(instance.Specifications, true) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Specifications")) {
          requiredFields.push("Specifications");
      }
    }
    if(instance.approvalInfo && isMissing(instance.SupplierId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Supplier")) {
          requiredFields.push("Supplier");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["LibraryMaterialApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["LibraryMaterialApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["LibraryMaterialApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["LibraryMaterialApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["LibraryMaterialVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.category))
    {
        requiredFields.push("Category");
    }

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(instance.approvalInfo && isMissing(instance.materialQualified))
    {
        requiredFields.push("Material Qualified");
    }

    if(instance.approvalInfo && CustomConditionalVerification.materialHasNoEffectiveDate(instance))
    {
        requiredFields.push("Effective Date");
    }

    if(instance.approvalInfo && isMissing(instance.SupplierId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Supplier")) {
          requiredFields.push("Supplier");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["LibraryMaterialVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["Material"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.category))
    {
        requiredFields.push("Category");
    }

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.use))
    {
        requiredFields.push("Use");
    }

    if(instance.approvalInfo && isMissing(instance.materialQualified))
    {
        requiredFields.push("Material Qualified");
    }

    if(instance.approvalInfo && CustomConditionalVerification.materialHasNoEffectiveDate(instance))
    {
        requiredFields.push("Effective Date");
    }

    if(isMissing(instance.ProcessId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Process")) {
          requiredFields.push("Process");
      }
    }
    if(instance.approvalInfo && isMissing(instance.SupplierId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Supplier")) {
          requiredFields.push("Supplier");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialAttribute"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }

    if(isMissing(instance.measure))
    {
        requiredFields.push("Measure");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityRisk))
    {
        requiredFields.push("Capability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityJustification))
    {
        requiredFields.push("Capability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityRisk))
    {
        requiredFields.push("Detectability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityJustification))
    {
        requiredFields.push("Detectability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.controlStrategy))
    {
        requiredFields.push("Control Strategy");
    }

    if(instance.approvalInfo && isMissing(instance.MaterialAttributeToFPAs, true) && CustomConditionalVerification.isMAUnattachedToFQAAndIAs(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Risk link(s) to Intermediate / Final Attributes")) {
          requiredFields.push("Risk link(s) to Intermediate / Final Attributes");
      }
    }
    if(instance.approvalInfo && isMissing(instance.MaterialAttributeToFQAs, true) && CustomConditionalVerification.isMAUnattachedToFPAAndIAs(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Risk link(s) to Intermediate / Final Attributes")) {
          requiredFields.push("Risk link(s) to Intermediate / Final Attributes");
      }
    }
    if(instance.approvalInfo && isMissing(instance.MaterialAttributeToIPAs, true) && CustomConditionalVerification.isMAUnattachedToIQAAndFAs(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Risk link(s) to Intermediate / Final Attributes")) {
          requiredFields.push("Risk link(s) to Intermediate / Final Attributes");
      }
    }
    if(instance.approvalInfo && isMissing(instance.MaterialAttributeToIQAs, true) && CustomConditionalVerification.isMAUnattachedToIPAAndFAs(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Risk link(s) to Intermediate / Final Attributes")) {
          requiredFields.push("Risk link(s) to Intermediate / Final Attributes");
      }
    }
    if(isMissing(instance.ProcessId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Process")) {
          requiredFields.push("Process");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialAttributeApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialAttributeApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialAttributeApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialAttributeApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialAttributeToFPA"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialAttributeToFPALinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialAttributeToFQA"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialAttributeToFQALinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialAttributeToIPA"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialAttributeToIPALinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialAttributeToIQA"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialAttributeToIQALinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialAttributeVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }

    if(isMissing(instance.measure))
    {
        requiredFields.push("Measure");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityRisk))
    {
        requiredFields.push("Capability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityJustification))
    {
        requiredFields.push("Capability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityRisk))
    {
        requiredFields.push("Detectability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityJustification))
    {
        requiredFields.push("Detectability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.controlStrategy))
    {
        requiredFields.push("Control Strategy");
    }

    if(isMissing(instance.ProcessId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Process")) {
          requiredFields.push("Process");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialAttributeVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.category))
    {
        requiredFields.push("Category");
    }

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.use))
    {
        requiredFields.push("Use");
    }

    if(instance.approvalInfo && isMissing(instance.materialQualified))
    {
        requiredFields.push("Material Qualified");
    }

    if(instance.approvalInfo && CustomConditionalVerification.materialHasNoEffectiveDate(instance))
    {
        requiredFields.push("Effective Date");
    }

    if(isMissing(instance.ProcessId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Process")) {
          requiredFields.push("Process");
      }
    }
    if(instance.approvalInfo && isMissing(instance.SupplierId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Supplier")) {
          requiredFields.push("Supplier");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["MaterialVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["Process"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessComponent"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(instance.approvalInfo && isMissing(instance.function))
    {
        requiredFields.push("Function");
    }

    if(isMissing(instance.ProcessId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Process")) {
          requiredFields.push("Process");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessComponentApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessComponentApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessComponentApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessComponentApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessComponentVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(instance.approvalInfo && isMissing(instance.function))
    {
        requiredFields.push("Function");
    }

    if(isMissing(instance.ProcessId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Process")) {
          requiredFields.push("Process");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessComponentVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessParameter"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }

    if(isMissing(instance.measure))
    {
        requiredFields.push("Measure");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityRisk))
    {
        requiredFields.push("Capability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityJustification))
    {
        requiredFields.push("Capability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityRisk))
    {
        requiredFields.push("Detectability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityJustification))
    {
        requiredFields.push("Detectability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.controlStrategy))
    {
        requiredFields.push("Control Strategy");
    }

    if(isMissing(instance.ProcessId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Process")) {
          requiredFields.push("Process");
      }
    }
    if(instance.approvalInfo && isMissing(instance.ProcessParameterToFPAs, true) && CustomConditionalVerification.isPPUnattachedToFQAAndIAs(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Risk link(s) to Intermediate / Final Attributes")) {
          requiredFields.push("Risk link(s) to Intermediate / Final Attributes");
      }
    }
    if(instance.approvalInfo && isMissing(instance.ProcessParameterToFQAs, true) && CustomConditionalVerification.isPPUnattachedToFPAAndIAs(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Risk link(s) to Intermediate / Final Attributes")) {
          requiredFields.push("Risk link(s) to Intermediate / Final Attributes");
      }
    }
    if(instance.approvalInfo && isMissing(instance.ProcessParameterToIPAs, true) && CustomConditionalVerification.isPPUnattachedToIQAAndFAs(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Risk link(s) to Intermediate / Final Attributes")) {
          requiredFields.push("Risk link(s) to Intermediate / Final Attributes");
      }
    }
    if(instance.approvalInfo && isMissing(instance.ProcessParameterToIQAs, true) && CustomConditionalVerification.isPPUnattachedToIPAAndFAs(instance))
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Risk link(s) to Intermediate / Final Attributes")) {
          requiredFields.push("Risk link(s) to Intermediate / Final Attributes");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessParameterApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessParameterApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessParameterApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessParameterApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessParameterToFPA"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessParameterToFPALinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessParameterToFQA"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessParameterToFQALinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessParameterToIPA"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessParameterToIPALinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessParameterToIQA"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessParameterToIQALinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(instance.approvalInfo && isMissing(instance.impact))
    {
        requiredFields.push("Impact");
    }

    if(instance.approvalInfo && isMissing(instance.uncertainty))
    {
        requiredFields.push("Uncertainty");
    }

    if(instance.approvalInfo && isMissing(instance.justification))
    {
        requiredFields.push("Justification");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessParameterVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }

    if(isMissing(instance.measure))
    {
        requiredFields.push("Measure");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityRisk))
    {
        requiredFields.push("Capability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.capabilityJustification))
    {
        requiredFields.push("Capability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityRisk))
    {
        requiredFields.push("Detectability Risk");
    }

    if(instance.approvalInfo && isMissing(instance.detectabilityJustification))
    {
        requiredFields.push("Detectability Justification");
    }

    if(instance.approvalInfo && isMissing(instance.controlStrategy))
    {
        requiredFields.push("Control Strategy");
    }

    if(isMissing(instance.ProcessId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Process")) {
          requiredFields.push("Process");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessParameterVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProcessVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["Project"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.customProjectId))
    {
        requiredFields.push("Custom Project");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(isMissing(instance.category))
    {
        requiredFields.push("Category");
    }

    if(instance.approvalInfo && isMissing(instance.purposeAndScope))
    {
        requiredFields.push("Purpose And Scope");
    }

    if(instance.approvalInfo && isMissing(instance.qualityByDesignPhase))
    {
        requiredFields.push("Quality By Design Phase");
    }

    if(instance.approvalInfo && isMissing(instance.cmcPhase))
    {
        requiredFields.push("Cmc Phase");
    }

    if(instance.approvalInfo && CustomConditionalVerification.requiresValidationPhase(instance))
    {
        requiredFields.push("Validation Phase");
    }

    if(instance.approvalInfo && isMissing(instance.projectManagerId))
    {
        requiredFields.push("Project Manager");
    }

    if(instance.approvalInfo && isMissing(instance.projectSponsorId))
    {
        requiredFields.push("Project Sponsor");
    }

    if(isMissing(instance.RMPId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Risk Management Plan")) {
          requiredFields.push("Risk Management Plan");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProjectApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProjectApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProjectApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProjectApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProjectVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.customProjectId))
    {
        requiredFields.push("Custom Project");
    }

    if(isMissing(instance.type))
    {
        requiredFields.push("Type");
    }

    if(isMissing(instance.category))
    {
        requiredFields.push("Category");
    }

    if(instance.approvalInfo && isMissing(instance.purposeAndScope))
    {
        requiredFields.push("Purpose And Scope");
    }

    if(instance.approvalInfo && isMissing(instance.qualityByDesignPhase))
    {
        requiredFields.push("Quality By Design Phase");
    }

    if(instance.approvalInfo && isMissing(instance.cmcPhase))
    {
        requiredFields.push("Cmc Phase");
    }

    if(instance.approvalInfo && CustomConditionalVerification.requiresValidationPhase(instance))
    {
        requiredFields.push("Validation Phase");
    }

    if(instance.approvalInfo && isMissing(instance.projectManagerId))
    {
        requiredFields.push("Project Manager");
    }

    if(instance.approvalInfo && isMissing(instance.projectSponsorId))
    {
        requiredFields.push("Project Sponsor");
    }

    if(isMissing(instance.RMPId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Risk Management Plan")) {
          requiredFields.push("Risk Management Plan");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ProjectVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RecordOrder"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ReportAutoSavedLayout"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["ReportLayout"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["Requirement"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RequirementVersion"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMP"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.minRiskScore))
    {
        requiredFields.push("Min Risk Score");
    }

    if(isMissing(instance.maxRiskScore))
    {
        requiredFields.push("Max Risk Score");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPToCapabilityRisk"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.riskScore))
    {
        requiredFields.push("Risk Score");
    }

    if(isMissing(instance.scoreLabel))
    {
        requiredFields.push("Score Label");
    }

    if(isMissing(instance.color))
    {
        requiredFields.push("Color");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPToCapabilityRiskLinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.riskScore))
    {
        requiredFields.push("Risk Score");
    }

    if(isMissing(instance.scoreLabel))
    {
        requiredFields.push("Score Label");
    }

    if(isMissing(instance.color))
    {
        requiredFields.push("Color");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPToCriticalityScale"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.from))
    {
        requiredFields.push("From");
    }

    if(isMissing(instance.to))
    {
        requiredFields.push("To");
    }

    if(isMissing(instance.scoreLabel))
    {
        requiredFields.push("Score Label");
    }

    if(instance.approvalInfo && isMissing(instance.riskLabel))
    {
        requiredFields.push("Risk Label");
    }

    if(isMissing(instance.color))
    {
        requiredFields.push("Color");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPToCriticalityScaleLinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.from))
    {
        requiredFields.push("From");
    }

    if(isMissing(instance.to))
    {
        requiredFields.push("To");
    }

    if(isMissing(instance.scoreLabel))
    {
        requiredFields.push("Score Label");
    }

    if(instance.approvalInfo && isMissing(instance.riskLabel))
    {
        requiredFields.push("Risk Label");
    }

    if(isMissing(instance.color))
    {
        requiredFields.push("Color");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPToDetectabilityRisk"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.riskScore))
    {
        requiredFields.push("Risk Score");
    }

    if(isMissing(instance.scoreLabel))
    {
        requiredFields.push("Score Label");
    }

    if(isMissing(instance.color))
    {
        requiredFields.push("Color");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPToDetectabilityRiskLinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.riskScore))
    {
        requiredFields.push("Risk Score");
    }

    if(isMissing(instance.scoreLabel))
    {
        requiredFields.push("Score Label");
    }

    if(isMissing(instance.color))
    {
        requiredFields.push("Color");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPToImpact"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.riskScore))
    {
        requiredFields.push("Risk Score");
    }

    if(isMissing(instance.scoreLabel))
    {
        requiredFields.push("Score Label");
    }

    if(isMissing(instance.color))
    {
        requiredFields.push("Color");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPToImpactLinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.riskScore))
    {
        requiredFields.push("Risk Score");
    }

    if(isMissing(instance.scoreLabel))
    {
        requiredFields.push("Score Label");
    }

    if(isMissing(instance.color))
    {
        requiredFields.push("Color");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPToProcessRiskScale"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.from))
    {
        requiredFields.push("From");
    }

    if(isMissing(instance.to))
    {
        requiredFields.push("To");
    }

    if(isMissing(instance.scoreLabel))
    {
        requiredFields.push("Score Label");
    }

    if(instance.approvalInfo && isMissing(instance.riskLabel))
    {
        requiredFields.push("Risk Label");
    }

    if(isMissing(instance.color))
    {
        requiredFields.push("Color");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPToProcessRiskScaleLinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.from))
    {
        requiredFields.push("From");
    }

    if(isMissing(instance.to))
    {
        requiredFields.push("To");
    }

    if(isMissing(instance.scoreLabel))
    {
        requiredFields.push("Score Label");
    }

    if(instance.approvalInfo && isMissing(instance.riskLabel))
    {
        requiredFields.push("Risk Label");
    }

    if(isMissing(instance.color))
    {
        requiredFields.push("Color");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPToRPNScale"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.from))
    {
        requiredFields.push("From");
    }

    if(isMissing(instance.to))
    {
        requiredFields.push("To");
    }

    if(isMissing(instance.scoreLabel))
    {
        requiredFields.push("Score Label");
    }

    if(instance.approvalInfo && isMissing(instance.riskLabel))
    {
        requiredFields.push("Risk Label");
    }

    if(isMissing(instance.color))
    {
        requiredFields.push("Color");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPToRPNScaleLinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.from))
    {
        requiredFields.push("From");
    }

    if(isMissing(instance.to))
    {
        requiredFields.push("To");
    }

    if(isMissing(instance.scoreLabel))
    {
        requiredFields.push("Score Label");
    }

    if(instance.approvalInfo && isMissing(instance.riskLabel))
    {
        requiredFields.push("Risk Label");
    }

    if(isMissing(instance.color))
    {
        requiredFields.push("Color");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPToUncertainty"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.riskScore))
    {
        requiredFields.push("Risk Score");
    }

    if(isMissing(instance.scoreLabel))
    {
        requiredFields.push("Score Label");
    }

    if(isMissing(instance.color))
    {
        requiredFields.push("Color");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPToUncertaintyLinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.riskScore))
    {
        requiredFields.push("Risk Score");
    }

    if(isMissing(instance.scoreLabel))
    {
        requiredFields.push("Score Label");
    }

    if(isMissing(instance.color))
    {
        requiredFields.push("Color");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(isMissing(instance.minRiskScore))
    {
        requiredFields.push("Min Risk Score");
    }

    if(isMissing(instance.maxRiskScore))
    {
        requiredFields.push("Max Risk Score");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["RMPVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["Specification"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.measure))
    {
        requiredFields.push("Measure");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["SpecificationLinkedVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.measure))
    {
        requiredFields.push("Measure");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["Step"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }

    if(isMissing(instance.ProcessId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Process")) {
          requiredFields.push("Process");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["StepApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["StepApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["StepApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["StepApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["StepVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }

    if(isMissing(instance.ProcessId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Process")) {
          requiredFields.push("Process");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["StepVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["Supplier"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(instance.approvalInfo && isMissing(instance.servicesOrProducts))
    {
        requiredFields.push("Services Or Products");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["SupplierApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["SupplierApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["SupplierApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["SupplierApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["SupplierVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(instance.approvalInfo && isMissing(instance.servicesOrProducts))
    {
        requiredFields.push("Services Or Products");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["SupplierVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["TechTransferChangeCriteria"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["TechTransferIgnoredChanges"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["TPPSection"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["TPPSectionApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["TPPSectionApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["TPPSectionApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["TPPSectionApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["TPPSectionVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["TPPSectionVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["TrainingOnboarding"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["TrainingRecord"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.retrainingCount))
    {
        requiredFields.push("Retraining Count");
    }


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["UnitOperation"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }

    if(instance.approvalInfo && isMissing(instance.input))
    {
        requiredFields.push("Input");
    }

    if(instance.approvalInfo && isMissing(instance.output))
    {
        requiredFields.push("Output");
    }

    if(isMissing(instance.ProcessId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Process")) {
          requiredFields.push("Process");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["UnitOperationApprovalReqToUser"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["UnitOperationApprovalRequest"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["UnitOperationApprovalResponse"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["UnitOperationApprovalResponseHistory"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["UnitOperationVersion"] = function(instance) {
    let requiredFields = [];

    if(isMissing(instance.name))
    {
        requiredFields.push("Name");
    }

    if(instance.approvalInfo && isMissing(instance.description))
    {
        requiredFields.push("Description");
    }

    if(instance.approvalInfo && isMissing(instance.input))
    {
        requiredFields.push("Input");
    }

    if(instance.approvalInfo && isMissing(instance.output))
    {
        requiredFields.push("Output");
    }

    if(isMissing(instance.ProcessId, false) )
    {
      //
      // avoids repeated messages due to the fact that the
      // messages for FQA and IQA links on MAs and PPs appear on the
      // same control even though in the database, those are different tables
      //
      if (!requiredFields.includes("Process")) {
          requiredFields.push("Process");
      }
    }

      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["UnitOperationVersionTransition"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["UserActivity"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["UserToProject"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}


  validate["UserToProjectLinkedVersion"] = function(instance) {
    let requiredFields = [];


      if(requiredFields.length > 0) {
        let errorMessage = "The following fields are required for " + (instance.approvalInfo ? "proposal" : "saving") + " but currently missing:\n"
        errorMessage = errorMessage + " - " + requiredFields.join("\n - ");
        throw new Error(errorMessage);
      }
}



    fieldsConfiguration["AcceptanceCriteriaRange"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["AcceptanceCriteriaRangeLinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["AttachmentAudit"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["Batch"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["Batch"].requiredFields.customid = true;
            fieldsConfiguration["Batch"].requiredFields.type = true;
            fieldsConfiguration["Batch"].requiredForProposalFields.scale = true;
            fieldsConfiguration["Batch"].requiredForProposalFields.manufacturedate = true;
            fieldsConfiguration["Batch"].requiredForProposalFields.releasedate = true;
            fieldsConfiguration["Batch"].requiredForProposalFields.expirationdate = true;
    fieldsConfiguration["BatchApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["BatchApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["BatchApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["BatchApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["BatchAttribute"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["BatchAttributeData"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["BatchHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["BatchVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["BatchVersion"].requiredFields.customid = true;
            fieldsConfiguration["BatchVersion"].requiredFields.type = true;
            fieldsConfiguration["BatchVersion"].requiredForProposalFields.scale = true;
            fieldsConfiguration["BatchVersion"].requiredForProposalFields.manufacturedate = true;
            fieldsConfiguration["BatchVersion"].requiredForProposalFields.releasedate = true;
            fieldsConfiguration["BatchVersion"].requiredForProposalFields.expirationdate = true;
    fieldsConfiguration["BatchVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ControlMethod"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ControlMethod"].requiredFields.name = true;
            fieldsConfiguration["ControlMethod"].requiredFields.type = true;
            fieldsConfiguration["ControlMethod"].requiredForProposalFields.description = true;
    fieldsConfiguration["ControlMethodApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ControlMethodApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ControlMethodApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ControlMethodApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ControlMethodVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ControlMethodVersion"].requiredFields.name = true;
            fieldsConfiguration["ControlMethodVersion"].requiredFields.type = true;
            fieldsConfiguration["ControlMethodVersion"].requiredForProposalFields.description = true;
    fieldsConfiguration["ControlMethodVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["Curriculum"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["Curriculum"].requiredFields.name = true;
            fieldsConfiguration["Curriculum"].requiredForProposalFields.documents = true;
    fieldsConfiguration["CurriculumApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["CurriculumApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["CurriculumApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["CurriculumApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["CurriculumAssignment"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["CurriculumAssignmentLinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["CurriculumVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["CurriculumVersion"].requiredFields.name = true;
            fieldsConfiguration["CurriculumVersion"].requiredForProposalFields.documents = true;
    fieldsConfiguration["CurriculumVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["Document"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["Document"].requiredForProposalFields.uploaddocumentlinks = CustomConditionalVerification.isFileNotAttachedToDocument;
            fieldsConfiguration["Document"].requiredFields.name = true;
    fieldsConfiguration["DocumentApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["DocumentApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["DocumentApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["DocumentApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["DocumentContent"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["DocumentContentApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["DocumentContentApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["DocumentContentApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["DocumentContentApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["DocumentContentVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["DocumentContentVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["DocumentVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["DocumentVersion"].requiredForProposalFields.uploaddocumentlinks = CustomConditionalVerification.isFileNotAttachedToDocument;
            fieldsConfiguration["DocumentVersion"].requiredFields.name = true;
    fieldsConfiguration["DocumentVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["FPA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["FPA"].requiredFields.name = true;
            fieldsConfiguration["FPA"].requiredFields.scope = true;
            fieldsConfiguration["FPA"].requiredFields.type = true;
            fieldsConfiguration["FPA"].requiredFields.category = true;
            fieldsConfiguration["FPA"].requiredForProposalFields.description = true;
            fieldsConfiguration["FPA"].requiredFields.measure = true;
            fieldsConfiguration["FPA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["FPA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["FPA"].requiredForProposalFields.capabilityrisk = true;
            fieldsConfiguration["FPA"].requiredForProposalFields.capabilityjustification = true;
            fieldsConfiguration["FPA"].requiredForProposalFields.detectabilityrisk = true;
            fieldsConfiguration["FPA"].requiredForProposalFields.detectabilityjustification = true;
            fieldsConfiguration["FPA"].requiredForProposalFields.criticalityjustification = CustomConditionalVerification.hasNoCriticalityJustification;
            fieldsConfiguration["FPA"].requiredForProposalFields.controlstrategy = true;
            fieldsConfiguration["FPA"].requiredForProposalFields.fpatogeneralattributerisks = CustomConditionalVerification.isFPANotAttachedToGeneralAttribute;
    fieldsConfiguration["FPAApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["FPAApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["FPAApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["FPAApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["FPAToGeneralAttributeRisk"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["FPAToGeneralAttributeRisk"].requiredForProposalFields.impact = true;
            fieldsConfiguration["FPAToGeneralAttributeRisk"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["FPAToGeneralAttributeRisk"].requiredForProposalFields.justification = true;
    fieldsConfiguration["FPAToGeneralAttributeRiskLinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["FPAToGeneralAttributeRiskLinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["FPAToGeneralAttributeRiskLinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["FPAToGeneralAttributeRiskLinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["FPAVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["FPAVersion"].requiredFields.name = true;
            fieldsConfiguration["FPAVersion"].requiredFields.scope = true;
            fieldsConfiguration["FPAVersion"].requiredFields.type = true;
            fieldsConfiguration["FPAVersion"].requiredFields.category = true;
            fieldsConfiguration["FPAVersion"].requiredForProposalFields.description = true;
            fieldsConfiguration["FPAVersion"].requiredFields.measure = true;
            fieldsConfiguration["FPAVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["FPAVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["FPAVersion"].requiredForProposalFields.capabilityrisk = true;
            fieldsConfiguration["FPAVersion"].requiredForProposalFields.capabilityjustification = true;
            fieldsConfiguration["FPAVersion"].requiredForProposalFields.detectabilityrisk = true;
            fieldsConfiguration["FPAVersion"].requiredForProposalFields.detectabilityjustification = true;
            fieldsConfiguration["FPAVersion"].requiredForProposalFields.criticalityjustification = CustomConditionalVerification.hasNoCriticalityJustification;
            fieldsConfiguration["FPAVersion"].requiredForProposalFields.controlstrategy = true;
    fieldsConfiguration["FPAVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["FQA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["FQA"].requiredFields.name = true;
            fieldsConfiguration["FQA"].requiredFields.scope = true;
            fieldsConfiguration["FQA"].requiredFields.type = true;
            fieldsConfiguration["FQA"].requiredFields.category = true;
            fieldsConfiguration["FQA"].requiredForProposalFields.description = true;
            fieldsConfiguration["FQA"].requiredFields.measure = true;
            fieldsConfiguration["FQA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["FQA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["FQA"].requiredForProposalFields.capabilityrisk = true;
            fieldsConfiguration["FQA"].requiredForProposalFields.capabilityjustification = true;
            fieldsConfiguration["FQA"].requiredForProposalFields.detectabilityrisk = true;
            fieldsConfiguration["FQA"].requiredForProposalFields.detectabilityjustification = true;
            fieldsConfiguration["FQA"].requiredForProposalFields.criticalityjustification = CustomConditionalVerification.hasNoCriticalityJustification;
            fieldsConfiguration["FQA"].requiredForProposalFields.controlstrategy = true;
            fieldsConfiguration["FQA"].requiredForProposalFields.fqatogeneralattributerisks = CustomConditionalVerification.isFQANotAttachedToGeneralAttribute;
    fieldsConfiguration["FQAApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["FQAApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["FQAApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["FQAApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["FQAToGeneralAttributeRisk"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["FQAToGeneralAttributeRisk"].requiredForProposalFields.impact = true;
            fieldsConfiguration["FQAToGeneralAttributeRisk"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["FQAToGeneralAttributeRisk"].requiredForProposalFields.justification = true;
    fieldsConfiguration["FQAToGeneralAttributeRiskLinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["FQAToGeneralAttributeRiskLinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["FQAToGeneralAttributeRiskLinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["FQAToGeneralAttributeRiskLinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["FQAVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["FQAVersion"].requiredFields.name = true;
            fieldsConfiguration["FQAVersion"].requiredFields.scope = true;
            fieldsConfiguration["FQAVersion"].requiredFields.type = true;
            fieldsConfiguration["FQAVersion"].requiredFields.category = true;
            fieldsConfiguration["FQAVersion"].requiredForProposalFields.description = true;
            fieldsConfiguration["FQAVersion"].requiredFields.measure = true;
            fieldsConfiguration["FQAVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["FQAVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["FQAVersion"].requiredForProposalFields.capabilityrisk = true;
            fieldsConfiguration["FQAVersion"].requiredForProposalFields.capabilityjustification = true;
            fieldsConfiguration["FQAVersion"].requiredForProposalFields.detectabilityrisk = true;
            fieldsConfiguration["FQAVersion"].requiredForProposalFields.detectabilityjustification = true;
            fieldsConfiguration["FQAVersion"].requiredForProposalFields.criticalityjustification = CustomConditionalVerification.hasNoCriticalityJustification;
            fieldsConfiguration["FQAVersion"].requiredForProposalFields.controlstrategy = true;
    fieldsConfiguration["FQAVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["GeneralAttribute"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["GeneralAttribute"].requiredFields.name = true;
    fieldsConfiguration["GeneralAttributeApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["GeneralAttributeApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["GeneralAttributeApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["GeneralAttributeApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["GeneralAttributeVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["GeneralAttributeVersion"].requiredFields.name = true;
    fieldsConfiguration["GeneralAttributeVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["Import"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["IPA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IPA"].requiredFields.name = true;
            fieldsConfiguration["IPA"].requiredFields.type = true;
            fieldsConfiguration["IPA"].requiredForProposalFields.description = true;
            fieldsConfiguration["IPA"].requiredFields.measure = true;
            fieldsConfiguration["IPA"].requiredForProposalFields.capabilityrisk = true;
            fieldsConfiguration["IPA"].requiredForProposalFields.capabilityjustification = true;
            fieldsConfiguration["IPA"].requiredForProposalFields.detectabilityrisk = true;
            fieldsConfiguration["IPA"].requiredForProposalFields.detectabilityjustification = true;
            fieldsConfiguration["IPA"].requiredForProposalFields.controlstrategy = true;
            fieldsConfiguration["IPA"].requiredForProposalFields.ipatoipas = CustomConditionalVerification.hasNoIPAToIQAAndFinalAttributes;
            fieldsConfiguration["IPA"].requiredForProposalFields.ipatoiqas = CustomConditionalVerification.hasNoIPAToIPAAndFinalAttributes;
            fieldsConfiguration["IPA"].requiredFields.processid = true;
            fieldsConfiguration["IPA"].requiredFields.unitoperationid = true;
    fieldsConfiguration["IPAApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["IPAApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["IPAApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["IPAApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["IPAToFPA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IPAToFPA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["IPAToFPA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["IPAToFPA"].requiredForProposalFields.justification = true;
    fieldsConfiguration["IPAToFPALinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IPAToFPALinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["IPAToFPALinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["IPAToFPALinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["IPAToFQA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IPAToFQA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["IPAToFQA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["IPAToFQA"].requiredForProposalFields.justification = true;
    fieldsConfiguration["IPAToFQALinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IPAToFQALinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["IPAToFQALinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["IPAToFQALinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["IPAToIPA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IPAToIPA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["IPAToIPA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["IPAToIPA"].requiredForProposalFields.justification = true;
    fieldsConfiguration["IPAToIPALinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IPAToIPALinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["IPAToIPALinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["IPAToIPALinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["IPAToIQA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IPAToIQA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["IPAToIQA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["IPAToIQA"].requiredForProposalFields.justification = true;
    fieldsConfiguration["IPAToIQALinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IPAToIQALinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["IPAToIQALinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["IPAToIQALinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["IPAVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IPAVersion"].requiredFields.name = true;
            fieldsConfiguration["IPAVersion"].requiredFields.type = true;
            fieldsConfiguration["IPAVersion"].requiredForProposalFields.description = true;
            fieldsConfiguration["IPAVersion"].requiredFields.measure = true;
            fieldsConfiguration["IPAVersion"].requiredForProposalFields.capabilityrisk = true;
            fieldsConfiguration["IPAVersion"].requiredForProposalFields.capabilityjustification = true;
            fieldsConfiguration["IPAVersion"].requiredForProposalFields.detectabilityrisk = true;
            fieldsConfiguration["IPAVersion"].requiredForProposalFields.detectabilityjustification = true;
            fieldsConfiguration["IPAVersion"].requiredForProposalFields.controlstrategy = true;
            fieldsConfiguration["IPAVersion"].requiredFields.processid = true;
            fieldsConfiguration["IPAVersion"].requiredFields.unitoperationid = true;
    fieldsConfiguration["IPAVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["IQA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IQA"].requiredFields.name = true;
            fieldsConfiguration["IQA"].requiredFields.type = true;
            fieldsConfiguration["IQA"].requiredForProposalFields.description = true;
            fieldsConfiguration["IQA"].requiredFields.measure = true;
            fieldsConfiguration["IQA"].requiredForProposalFields.capabilityrisk = true;
            fieldsConfiguration["IQA"].requiredForProposalFields.capabilityjustification = true;
            fieldsConfiguration["IQA"].requiredForProposalFields.detectabilityrisk = true;
            fieldsConfiguration["IQA"].requiredForProposalFields.detectabilityjustification = true;
            fieldsConfiguration["IQA"].requiredForProposalFields.controlstrategy = true;
            fieldsConfiguration["IQA"].requiredForProposalFields.iqatoipas = CustomConditionalVerification.hasNoIQAToIQAAndFinalAttributes;
            fieldsConfiguration["IQA"].requiredForProposalFields.iqatoiqas = CustomConditionalVerification.hasNoIQAToIPAAndFinalAttributes;
            fieldsConfiguration["IQA"].requiredFields.processid = true;
            fieldsConfiguration["IQA"].requiredFields.unitoperationid = true;
    fieldsConfiguration["IQAApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["IQAApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["IQAApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["IQAApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["IQAToFPA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IQAToFPA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["IQAToFPA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["IQAToFPA"].requiredForProposalFields.justification = true;
    fieldsConfiguration["IQAToFPALinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IQAToFPALinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["IQAToFPALinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["IQAToFPALinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["IQAToFQA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IQAToFQA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["IQAToFQA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["IQAToFQA"].requiredForProposalFields.justification = true;
    fieldsConfiguration["IQAToFQALinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IQAToFQALinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["IQAToFQALinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["IQAToFQALinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["IQAToIPA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IQAToIPA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["IQAToIPA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["IQAToIPA"].requiredForProposalFields.justification = true;
    fieldsConfiguration["IQAToIPALinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IQAToIPALinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["IQAToIPALinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["IQAToIPALinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["IQAToIQA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IQAToIQA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["IQAToIQA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["IQAToIQA"].requiredForProposalFields.justification = true;
    fieldsConfiguration["IQAToIQALinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IQAToIQALinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["IQAToIQALinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["IQAToIQALinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["IQAVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["IQAVersion"].requiredFields.name = true;
            fieldsConfiguration["IQAVersion"].requiredFields.type = true;
            fieldsConfiguration["IQAVersion"].requiredForProposalFields.description = true;
            fieldsConfiguration["IQAVersion"].requiredFields.measure = true;
            fieldsConfiguration["IQAVersion"].requiredForProposalFields.capabilityrisk = true;
            fieldsConfiguration["IQAVersion"].requiredForProposalFields.capabilityjustification = true;
            fieldsConfiguration["IQAVersion"].requiredForProposalFields.detectabilityrisk = true;
            fieldsConfiguration["IQAVersion"].requiredForProposalFields.detectabilityjustification = true;
            fieldsConfiguration["IQAVersion"].requiredForProposalFields.controlstrategy = true;
            fieldsConfiguration["IQAVersion"].requiredFields.processid = true;
            fieldsConfiguration["IQAVersion"].requiredFields.unitoperationid = true;
    fieldsConfiguration["IQAVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ITP"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ITP"].requiredForProposalFields.curriculumassignments = CustomConditionalVerification.isDocumentNotAttachedToITP;
            fieldsConfiguration["ITP"].requiredForProposalFields.documents = CustomConditionalVerification.isCurriculumNotAttachedToITP;
    fieldsConfiguration["ITPApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ITPApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ITPApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ITPApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ITPVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ITPVersion"].requiredForProposalFields.documents = CustomConditionalVerification.isCurriculumNotAttachedToITP;
    fieldsConfiguration["ITPVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["LibraryMaterial"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["LibraryMaterial"].requiredFields.category = true;
            fieldsConfiguration["LibraryMaterial"].requiredFields.name = true;
            fieldsConfiguration["LibraryMaterial"].requiredForProposalFields.materialqualified = true;
            fieldsConfiguration["LibraryMaterial"].requiredForProposalFields.effectivedate = CustomConditionalVerification.materialHasNoEffectiveDate;
            fieldsConfiguration["LibraryMaterial"].requiredForProposalFields.specifications = true;
            fieldsConfiguration["LibraryMaterial"].requiredForProposalFields.supplierid = true;
    fieldsConfiguration["LibraryMaterialApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["LibraryMaterialApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["LibraryMaterialApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["LibraryMaterialApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["LibraryMaterialVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["LibraryMaterialVersion"].requiredFields.category = true;
            fieldsConfiguration["LibraryMaterialVersion"].requiredFields.name = true;
            fieldsConfiguration["LibraryMaterialVersion"].requiredForProposalFields.materialqualified = true;
            fieldsConfiguration["LibraryMaterialVersion"].requiredForProposalFields.effectivedate = CustomConditionalVerification.materialHasNoEffectiveDate;
            fieldsConfiguration["LibraryMaterialVersion"].requiredForProposalFields.supplierid = true;
    fieldsConfiguration["LibraryMaterialVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["Material"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["Material"].requiredFields.category = true;
            fieldsConfiguration["Material"].requiredFields.name = true;
            fieldsConfiguration["Material"].requiredFields.use = true;
            fieldsConfiguration["Material"].requiredForProposalFields.materialqualified = true;
            fieldsConfiguration["Material"].requiredForProposalFields.effectivedate = CustomConditionalVerification.materialHasNoEffectiveDate;
            fieldsConfiguration["Material"].requiredFields.processid = true;
            fieldsConfiguration["Material"].requiredForProposalFields.supplierid = true;
    fieldsConfiguration["MaterialApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["MaterialApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["MaterialApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["MaterialApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["MaterialAttribute"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["MaterialAttribute"].requiredFields.name = true;
            fieldsConfiguration["MaterialAttribute"].requiredFields.type = true;
            fieldsConfiguration["MaterialAttribute"].requiredForProposalFields.description = true;
            fieldsConfiguration["MaterialAttribute"].requiredFields.measure = true;
            fieldsConfiguration["MaterialAttribute"].requiredForProposalFields.capabilityrisk = true;
            fieldsConfiguration["MaterialAttribute"].requiredForProposalFields.capabilityjustification = true;
            fieldsConfiguration["MaterialAttribute"].requiredForProposalFields.detectabilityrisk = true;
            fieldsConfiguration["MaterialAttribute"].requiredForProposalFields.detectabilityjustification = true;
            fieldsConfiguration["MaterialAttribute"].requiredForProposalFields.controlstrategy = true;
            fieldsConfiguration["MaterialAttribute"].requiredForProposalFields.materialattributetofpas = CustomConditionalVerification.isMAUnattachedToFQAAndIAs;
            fieldsConfiguration["MaterialAttribute"].requiredForProposalFields.materialattributetofqas = CustomConditionalVerification.isMAUnattachedToFPAAndIAs;
            fieldsConfiguration["MaterialAttribute"].requiredForProposalFields.materialattributetoipas = CustomConditionalVerification.isMAUnattachedToIQAAndFAs;
            fieldsConfiguration["MaterialAttribute"].requiredForProposalFields.materialattributetoiqas = CustomConditionalVerification.isMAUnattachedToIPAAndFAs;
            fieldsConfiguration["MaterialAttribute"].requiredFields.processid = true;
    fieldsConfiguration["MaterialAttributeApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["MaterialAttributeApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["MaterialAttributeApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["MaterialAttributeApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["MaterialAttributeToFPA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["MaterialAttributeToFPA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["MaterialAttributeToFPA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["MaterialAttributeToFPA"].requiredForProposalFields.justification = true;
    fieldsConfiguration["MaterialAttributeToFPALinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["MaterialAttributeToFPALinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["MaterialAttributeToFPALinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["MaterialAttributeToFPALinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["MaterialAttributeToFQA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["MaterialAttributeToFQA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["MaterialAttributeToFQA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["MaterialAttributeToFQA"].requiredForProposalFields.justification = true;
    fieldsConfiguration["MaterialAttributeToFQALinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["MaterialAttributeToFQALinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["MaterialAttributeToFQALinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["MaterialAttributeToFQALinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["MaterialAttributeToIPA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["MaterialAttributeToIPA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["MaterialAttributeToIPA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["MaterialAttributeToIPA"].requiredForProposalFields.justification = true;
    fieldsConfiguration["MaterialAttributeToIPALinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["MaterialAttributeToIPALinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["MaterialAttributeToIPALinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["MaterialAttributeToIPALinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["MaterialAttributeToIQA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["MaterialAttributeToIQA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["MaterialAttributeToIQA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["MaterialAttributeToIQA"].requiredForProposalFields.justification = true;
    fieldsConfiguration["MaterialAttributeToIQALinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["MaterialAttributeToIQALinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["MaterialAttributeToIQALinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["MaterialAttributeToIQALinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["MaterialAttributeVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["MaterialAttributeVersion"].requiredFields.name = true;
            fieldsConfiguration["MaterialAttributeVersion"].requiredFields.type = true;
            fieldsConfiguration["MaterialAttributeVersion"].requiredForProposalFields.description = true;
            fieldsConfiguration["MaterialAttributeVersion"].requiredFields.measure = true;
            fieldsConfiguration["MaterialAttributeVersion"].requiredForProposalFields.capabilityrisk = true;
            fieldsConfiguration["MaterialAttributeVersion"].requiredForProposalFields.capabilityjustification = true;
            fieldsConfiguration["MaterialAttributeVersion"].requiredForProposalFields.detectabilityrisk = true;
            fieldsConfiguration["MaterialAttributeVersion"].requiredForProposalFields.detectabilityjustification = true;
            fieldsConfiguration["MaterialAttributeVersion"].requiredForProposalFields.controlstrategy = true;
            fieldsConfiguration["MaterialAttributeVersion"].requiredFields.processid = true;
    fieldsConfiguration["MaterialAttributeVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["MaterialVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["MaterialVersion"].requiredFields.category = true;
            fieldsConfiguration["MaterialVersion"].requiredFields.name = true;
            fieldsConfiguration["MaterialVersion"].requiredFields.use = true;
            fieldsConfiguration["MaterialVersion"].requiredForProposalFields.materialqualified = true;
            fieldsConfiguration["MaterialVersion"].requiredForProposalFields.effectivedate = CustomConditionalVerification.materialHasNoEffectiveDate;
            fieldsConfiguration["MaterialVersion"].requiredFields.processid = true;
            fieldsConfiguration["MaterialVersion"].requiredForProposalFields.supplierid = true;
    fieldsConfiguration["MaterialVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["Process"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["Process"].requiredFields.name = true;
            fieldsConfiguration["Process"].requiredForProposalFields.description = true;
    fieldsConfiguration["ProcessApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProcessApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProcessApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProcessApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProcessComponent"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ProcessComponent"].requiredFields.name = true;
            fieldsConfiguration["ProcessComponent"].requiredFields.type = true;
            fieldsConfiguration["ProcessComponent"].requiredForProposalFields.function = true;
            fieldsConfiguration["ProcessComponent"].requiredFields.processid = true;
    fieldsConfiguration["ProcessComponentApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProcessComponentApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProcessComponentApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProcessComponentApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProcessComponentVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ProcessComponentVersion"].requiredFields.name = true;
            fieldsConfiguration["ProcessComponentVersion"].requiredFields.type = true;
            fieldsConfiguration["ProcessComponentVersion"].requiredForProposalFields.function = true;
            fieldsConfiguration["ProcessComponentVersion"].requiredFields.processid = true;
    fieldsConfiguration["ProcessComponentVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProcessParameter"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ProcessParameter"].requiredFields.name = true;
            fieldsConfiguration["ProcessParameter"].requiredForProposalFields.description = true;
            fieldsConfiguration["ProcessParameter"].requiredFields.measure = true;
            fieldsConfiguration["ProcessParameter"].requiredForProposalFields.capabilityrisk = true;
            fieldsConfiguration["ProcessParameter"].requiredForProposalFields.capabilityjustification = true;
            fieldsConfiguration["ProcessParameter"].requiredForProposalFields.detectabilityrisk = true;
            fieldsConfiguration["ProcessParameter"].requiredForProposalFields.detectabilityjustification = true;
            fieldsConfiguration["ProcessParameter"].requiredForProposalFields.controlstrategy = true;
            fieldsConfiguration["ProcessParameter"].requiredFields.processid = true;
            fieldsConfiguration["ProcessParameter"].requiredForProposalFields.processparametertofpas = CustomConditionalVerification.isPPUnattachedToFQAAndIAs;
            fieldsConfiguration["ProcessParameter"].requiredForProposalFields.processparametertofqas = CustomConditionalVerification.isPPUnattachedToFPAAndIAs;
            fieldsConfiguration["ProcessParameter"].requiredForProposalFields.processparametertoipas = CustomConditionalVerification.isPPUnattachedToIQAAndFAs;
            fieldsConfiguration["ProcessParameter"].requiredForProposalFields.processparametertoiqas = CustomConditionalVerification.isPPUnattachedToIPAAndFAs;
    fieldsConfiguration["ProcessParameterApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProcessParameterApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProcessParameterApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProcessParameterApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProcessParameterToFPA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ProcessParameterToFPA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["ProcessParameterToFPA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["ProcessParameterToFPA"].requiredForProposalFields.justification = true;
    fieldsConfiguration["ProcessParameterToFPALinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ProcessParameterToFPALinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["ProcessParameterToFPALinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["ProcessParameterToFPALinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["ProcessParameterToFQA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ProcessParameterToFQA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["ProcessParameterToFQA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["ProcessParameterToFQA"].requiredForProposalFields.justification = true;
    fieldsConfiguration["ProcessParameterToFQALinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ProcessParameterToFQALinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["ProcessParameterToFQALinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["ProcessParameterToFQALinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["ProcessParameterToIPA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ProcessParameterToIPA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["ProcessParameterToIPA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["ProcessParameterToIPA"].requiredForProposalFields.justification = true;
    fieldsConfiguration["ProcessParameterToIPALinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ProcessParameterToIPALinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["ProcessParameterToIPALinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["ProcessParameterToIPALinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["ProcessParameterToIQA"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ProcessParameterToIQA"].requiredForProposalFields.impact = true;
            fieldsConfiguration["ProcessParameterToIQA"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["ProcessParameterToIQA"].requiredForProposalFields.justification = true;
    fieldsConfiguration["ProcessParameterToIQALinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ProcessParameterToIQALinkedVersion"].requiredForProposalFields.impact = true;
            fieldsConfiguration["ProcessParameterToIQALinkedVersion"].requiredForProposalFields.uncertainty = true;
            fieldsConfiguration["ProcessParameterToIQALinkedVersion"].requiredForProposalFields.justification = true;
    fieldsConfiguration["ProcessParameterVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ProcessParameterVersion"].requiredFields.name = true;
            fieldsConfiguration["ProcessParameterVersion"].requiredForProposalFields.description = true;
            fieldsConfiguration["ProcessParameterVersion"].requiredFields.measure = true;
            fieldsConfiguration["ProcessParameterVersion"].requiredForProposalFields.capabilityrisk = true;
            fieldsConfiguration["ProcessParameterVersion"].requiredForProposalFields.capabilityjustification = true;
            fieldsConfiguration["ProcessParameterVersion"].requiredForProposalFields.detectabilityrisk = true;
            fieldsConfiguration["ProcessParameterVersion"].requiredForProposalFields.detectabilityjustification = true;
            fieldsConfiguration["ProcessParameterVersion"].requiredForProposalFields.controlstrategy = true;
            fieldsConfiguration["ProcessParameterVersion"].requiredFields.processid = true;
    fieldsConfiguration["ProcessParameterVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProcessVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ProcessVersion"].requiredFields.name = true;
            fieldsConfiguration["ProcessVersion"].requiredForProposalFields.description = true;
    fieldsConfiguration["ProcessVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["Project"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["Project"].requiredFields.name = true;
            fieldsConfiguration["Project"].requiredFields.customprojectid = true;
            fieldsConfiguration["Project"].requiredFields.type = true;
            fieldsConfiguration["Project"].requiredFields.category = true;
            fieldsConfiguration["Project"].requiredForProposalFields.purposeandscope = true;
            fieldsConfiguration["Project"].requiredForProposalFields.qualitybydesignphase = true;
            fieldsConfiguration["Project"].requiredForProposalFields.cmcphase = true;
            fieldsConfiguration["Project"].requiredForProposalFields.validationphase = CustomConditionalVerification.requiresValidationPhase;
            fieldsConfiguration["Project"].requiredForProposalFields.projectmanagerid = true;
            fieldsConfiguration["Project"].requiredForProposalFields.projectsponsorid = true;
            fieldsConfiguration["Project"].requiredFields.rmpid = true;
    fieldsConfiguration["ProjectApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProjectApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProjectApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProjectApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ProjectVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["ProjectVersion"].requiredFields.name = true;
            fieldsConfiguration["ProjectVersion"].requiredFields.customprojectid = true;
            fieldsConfiguration["ProjectVersion"].requiredFields.type = true;
            fieldsConfiguration["ProjectVersion"].requiredFields.category = true;
            fieldsConfiguration["ProjectVersion"].requiredForProposalFields.purposeandscope = true;
            fieldsConfiguration["ProjectVersion"].requiredForProposalFields.qualitybydesignphase = true;
            fieldsConfiguration["ProjectVersion"].requiredForProposalFields.cmcphase = true;
            fieldsConfiguration["ProjectVersion"].requiredForProposalFields.validationphase = CustomConditionalVerification.requiresValidationPhase;
            fieldsConfiguration["ProjectVersion"].requiredForProposalFields.projectmanagerid = true;
            fieldsConfiguration["ProjectVersion"].requiredForProposalFields.projectsponsorid = true;
            fieldsConfiguration["ProjectVersion"].requiredFields.rmpid = true;
    fieldsConfiguration["ProjectVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["RecordOrder"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ReportAutoSavedLayout"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["ReportLayout"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["Requirement"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["RequirementVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["RMP"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["RMP"].requiredFields.name = true;
            fieldsConfiguration["RMP"].requiredFields.minriskscore = true;
            fieldsConfiguration["RMP"].requiredFields.maxriskscore = true;
    fieldsConfiguration["RMPApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["RMPApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["RMPApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["RMPApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["RMPToCapabilityRisk"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["RMPToCapabilityRisk"].requiredFields.riskscore = true;
            fieldsConfiguration["RMPToCapabilityRisk"].requiredFields.scorelabel = true;
            fieldsConfiguration["RMPToCapabilityRisk"].requiredFields.color = true;
    fieldsConfiguration["RMPToCapabilityRiskLinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["RMPToCapabilityRiskLinkedVersion"].requiredFields.riskscore = true;
            fieldsConfiguration["RMPToCapabilityRiskLinkedVersion"].requiredFields.scorelabel = true;
            fieldsConfiguration["RMPToCapabilityRiskLinkedVersion"].requiredFields.color = true;
    fieldsConfiguration["RMPToCriticalityScale"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["RMPToCriticalityScale"].requiredFields.from = true;
            fieldsConfiguration["RMPToCriticalityScale"].requiredFields.to = true;
            fieldsConfiguration["RMPToCriticalityScale"].requiredFields.scorelabel = true;
            fieldsConfiguration["RMPToCriticalityScale"].requiredForProposalFields.risklabel = true;
            fieldsConfiguration["RMPToCriticalityScale"].requiredFields.color = true;
    fieldsConfiguration["RMPToCriticalityScaleLinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["RMPToCriticalityScaleLinkedVersion"].requiredFields.from = true;
            fieldsConfiguration["RMPToCriticalityScaleLinkedVersion"].requiredFields.to = true;
            fieldsConfiguration["RMPToCriticalityScaleLinkedVersion"].requiredFields.scorelabel = true;
            fieldsConfiguration["RMPToCriticalityScaleLinkedVersion"].requiredForProposalFields.risklabel = true;
            fieldsConfiguration["RMPToCriticalityScaleLinkedVersion"].requiredFields.color = true;
    fieldsConfiguration["RMPToDetectabilityRisk"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["RMPToDetectabilityRisk"].requiredFields.riskscore = true;
            fieldsConfiguration["RMPToDetectabilityRisk"].requiredFields.scorelabel = true;
            fieldsConfiguration["RMPToDetectabilityRisk"].requiredFields.color = true;
    fieldsConfiguration["RMPToDetectabilityRiskLinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["RMPToDetectabilityRiskLinkedVersion"].requiredFields.riskscore = true;
            fieldsConfiguration["RMPToDetectabilityRiskLinkedVersion"].requiredFields.scorelabel = true;
            fieldsConfiguration["RMPToDetectabilityRiskLinkedVersion"].requiredFields.color = true;
    fieldsConfiguration["RMPToImpact"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["RMPToImpact"].requiredFields.riskscore = true;
            fieldsConfiguration["RMPToImpact"].requiredFields.scorelabel = true;
            fieldsConfiguration["RMPToImpact"].requiredFields.color = true;
    fieldsConfiguration["RMPToImpactLinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["RMPToImpactLinkedVersion"].requiredFields.riskscore = true;
            fieldsConfiguration["RMPToImpactLinkedVersion"].requiredFields.scorelabel = true;
            fieldsConfiguration["RMPToImpactLinkedVersion"].requiredFields.color = true;
    fieldsConfiguration["RMPToProcessRiskScale"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["RMPToProcessRiskScale"].requiredFields.from = true;
            fieldsConfiguration["RMPToProcessRiskScale"].requiredFields.to = true;
            fieldsConfiguration["RMPToProcessRiskScale"].requiredFields.scorelabel = true;
            fieldsConfiguration["RMPToProcessRiskScale"].requiredForProposalFields.risklabel = true;
            fieldsConfiguration["RMPToProcessRiskScale"].requiredFields.color = true;
    fieldsConfiguration["RMPToProcessRiskScaleLinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["RMPToProcessRiskScaleLinkedVersion"].requiredFields.from = true;
            fieldsConfiguration["RMPToProcessRiskScaleLinkedVersion"].requiredFields.to = true;
            fieldsConfiguration["RMPToProcessRiskScaleLinkedVersion"].requiredFields.scorelabel = true;
            fieldsConfiguration["RMPToProcessRiskScaleLinkedVersion"].requiredForProposalFields.risklabel = true;
            fieldsConfiguration["RMPToProcessRiskScaleLinkedVersion"].requiredFields.color = true;
    fieldsConfiguration["RMPToRPNScale"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["RMPToRPNScale"].requiredFields.from = true;
            fieldsConfiguration["RMPToRPNScale"].requiredFields.to = true;
            fieldsConfiguration["RMPToRPNScale"].requiredFields.scorelabel = true;
            fieldsConfiguration["RMPToRPNScale"].requiredForProposalFields.risklabel = true;
            fieldsConfiguration["RMPToRPNScale"].requiredFields.color = true;
    fieldsConfiguration["RMPToRPNScaleLinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["RMPToRPNScaleLinkedVersion"].requiredFields.from = true;
            fieldsConfiguration["RMPToRPNScaleLinkedVersion"].requiredFields.to = true;
            fieldsConfiguration["RMPToRPNScaleLinkedVersion"].requiredFields.scorelabel = true;
            fieldsConfiguration["RMPToRPNScaleLinkedVersion"].requiredForProposalFields.risklabel = true;
            fieldsConfiguration["RMPToRPNScaleLinkedVersion"].requiredFields.color = true;
    fieldsConfiguration["RMPToUncertainty"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["RMPToUncertainty"].requiredFields.riskscore = true;
            fieldsConfiguration["RMPToUncertainty"].requiredFields.scorelabel = true;
            fieldsConfiguration["RMPToUncertainty"].requiredFields.color = true;
    fieldsConfiguration["RMPToUncertaintyLinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["RMPToUncertaintyLinkedVersion"].requiredFields.riskscore = true;
            fieldsConfiguration["RMPToUncertaintyLinkedVersion"].requiredFields.scorelabel = true;
            fieldsConfiguration["RMPToUncertaintyLinkedVersion"].requiredFields.color = true;
    fieldsConfiguration["RMPVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["RMPVersion"].requiredFields.name = true;
            fieldsConfiguration["RMPVersion"].requiredFields.minriskscore = true;
            fieldsConfiguration["RMPVersion"].requiredFields.maxriskscore = true;
    fieldsConfiguration["RMPVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["Specification"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["Specification"].requiredFields.measure = true;
    fieldsConfiguration["SpecificationLinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["SpecificationLinkedVersion"].requiredFields.measure = true;
    fieldsConfiguration["Step"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["Step"].requiredFields.name = true;
            fieldsConfiguration["Step"].requiredForProposalFields.description = true;
            fieldsConfiguration["Step"].requiredFields.processid = true;
    fieldsConfiguration["StepApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["StepApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["StepApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["StepApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["StepVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["StepVersion"].requiredFields.name = true;
            fieldsConfiguration["StepVersion"].requiredForProposalFields.description = true;
            fieldsConfiguration["StepVersion"].requiredFields.processid = true;
    fieldsConfiguration["StepVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["Supplier"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["Supplier"].requiredFields.name = true;
            fieldsConfiguration["Supplier"].requiredForProposalFields.servicesorproducts = true;
    fieldsConfiguration["SupplierApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["SupplierApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["SupplierApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["SupplierApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["SupplierVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["SupplierVersion"].requiredFields.name = true;
            fieldsConfiguration["SupplierVersion"].requiredForProposalFields.servicesorproducts = true;
    fieldsConfiguration["SupplierVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["TechTransferChangeCriteria"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["TechTransferIgnoredChanges"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["TPPSection"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["TPPSection"].requiredFields.name = true;
    fieldsConfiguration["TPPSectionApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["TPPSectionApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["TPPSectionApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["TPPSectionApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["TPPSectionVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["TPPSectionVersion"].requiredFields.name = true;
    fieldsConfiguration["TPPSectionVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["TrainingOnboarding"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["TrainingRecord"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["TrainingRecord"].requiredFields.retrainingcount = true;
    fieldsConfiguration["UnitOperation"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["UnitOperation"].requiredFields.name = true;
            fieldsConfiguration["UnitOperation"].requiredForProposalFields.description = true;
            fieldsConfiguration["UnitOperation"].requiredForProposalFields.input = true;
            fieldsConfiguration["UnitOperation"].requiredForProposalFields.output = true;
            fieldsConfiguration["UnitOperation"].requiredFields.processid = true;
    fieldsConfiguration["UnitOperationApprovalReqToUser"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["UnitOperationApprovalRequest"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["UnitOperationApprovalResponse"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["UnitOperationApprovalResponseHistory"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["UnitOperationVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

            fieldsConfiguration["UnitOperationVersion"].requiredFields.name = true;
            fieldsConfiguration["UnitOperationVersion"].requiredForProposalFields.description = true;
            fieldsConfiguration["UnitOperationVersion"].requiredForProposalFields.input = true;
            fieldsConfiguration["UnitOperationVersion"].requiredForProposalFields.output = true;
            fieldsConfiguration["UnitOperationVersion"].requiredFields.processid = true;
    fieldsConfiguration["UnitOperationVersionTransition"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["UserActivity"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["UserToProject"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };

    fieldsConfiguration["UserToProjectLinkedVersion"] = {
        requiredFields: {},
        requiredForProposalFields: {},
    };



function isRequiredForSaving(instance, className, attributeName) {
  if (fieldsConfiguration[className]) {
    let fieldValidator = fieldsConfiguration[className].requiredFields[attributeName];
    return ((typeof fieldValidator === "boolean" && fieldValidator) || ((typeof fieldValidator === "function") && fieldValidator(instance)));
  }
}

function isRequiredForProposing(instance, className, attributeName) {
  if (fieldsConfiguration[className]) {
    let fieldValidator = fieldsConfiguration[className].requiredForProposalFields[attributeName];
    return ((typeof fieldValidator === "boolean" && fieldValidator) || ((typeof fieldValidator === "function") && fieldValidator(instance)));
  }
}

module.exports = {
    validate : validate,
    isRequiredForSaving    : isRequiredForSaving,
    isRequiredForProposing : isRequiredForProposing
};
