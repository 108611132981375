var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Keys, classNames, guid, useDir, getTabIndex, dispatchEvent, createPropsContext, usePropsContext, kendoThemeMaps } from '@progress/kendo-react-common';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
import { useLocalization } from '@progress/kendo-react-intl';
import { messages, checkboxValidation, checkboxOptionalText } from './../messages';
/**
 * Represents the PropsContext of the `Checkbox` component.
 * Used for global configuration of all `Checkbox` instances.
 *
 * For more information, refer to the [Inputs Props Context]({% slug props-context_inputs %}) article.
 */
export var CheckboxPropsContext = createPropsContext();
/**
 * Represents the [KendoReact Checkbox component]({% slug overview_checkbox %}).
 *
 * @example
 * ```jsx
 * class App extends React.Component {
 *     render() {
 *         return <Checkbox />;
 *     }
 * }
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var Checkbox = React.forwardRef(function (directProps, target) {
    var _a;
    validatePackage(packageMetadata);
    var props = usePropsContext(CheckboxPropsContext, directProps);
    var _b = props, ariaDescribedBy = _b.ariaDescribedBy, ariaLabelledBy = _b.ariaLabelledBy, checked = _b.checked, className = _b.className, children = _b.children, defaultChecked = _b.defaultChecked, disabled = _b.disabled, defaultValue = _b.defaultValue, id = _b.id, size = _b.size, rounded = _b.rounded, label = _b.label, labelPlacement = _b.labelPlacement, name = _b.name, labelOptional = _b.labelOptional, onChange = _b.onChange, onFocus = _b.onFocus, onBlur = _b.onBlur, tabIndex = _b.tabIndex, value = _b.value, required = _b.required, valid = _b.valid, validationMessage = _b.validationMessage, validityStyles = _b.validityStyles, 
    // Removed to support direct use in Form Field component
    visited = _b.visited, touched = _b.touched, modified = _b.modified, others = __rest(_b, ["ariaDescribedBy", "ariaLabelledBy", "checked", "className", "children", "defaultChecked", "disabled", "defaultValue", "id", "size", "rounded", "label", "labelPlacement", "name", "labelOptional", "onChange", "onFocus", "onBlur", "tabIndex", "value", "required", "valid", "validationMessage", "validityStyles", "visited", "touched", "modified"]);
    var elementRef = React.useRef(null);
    var focusElement = React.useCallback(function () {
        if (elementRef.current) {
            elementRef.current.focus();
        }
    }, []);
    var getImperativeHandle = React.useCallback(function () { return ({
        element: elementRef.current,
        focus: focusElement,
        get name() { return elementRef.current && elementRef.current.name; }
    }); }, [focusElement]);
    React.useImperativeHandle(target, getImperativeHandle);
    var _c = React.useState(defaultChecked), stateChecked = _c[0], setStateChecked = _c[1];
    var _d = React.useState(defaultValue), stateValue = _d[0], setStateValue = _d[1];
    var valueIsBoolean = (typeof value === 'boolean' || value === null);
    var isCheckedControlled = checked !== undefined;
    var isValueControlled = valueIsBoolean;
    var currentValue = isValueControlled ? value : stateValue;
    var currentChecked = isCheckedControlled ? checked : isValueControlled ? undefined : stateChecked;
    var useValueAsChecked = currentChecked === undefined && currentValue;
    var checkedProp = useValueAsChecked ? currentValue : currentChecked;
    var valueProp = (useValueAsChecked || isValueControlled) ? (value === null ? value : undefined) : value || currentValue;
    var indeterminateProp = checkedProp === null || valueProp === null;
    var calculatedId = React.useMemo(function () { return guid(); }, []);
    var localizationService = useLocalization();
    var localizeMessage = function (message) {
        return localizationService.toLanguageString(message, messages[message]);
    };
    var defaultValidationMessage = localizeMessage(checkboxValidation);
    var optionalMessage = localizeMessage(checkboxOptionalText);
    var isValid = valid !== undefined ?
        valid :
        !required ?
            true :
            currentChecked ? true : false;
    React.useEffect(function () {
        if (elementRef.current && elementRef.current.setCustomValidity) {
            elementRef.current.setCustomValidity(isValid
                ? ''
                : (validationMessage || defaultValidationMessage));
        }
    }, [isValid, validationMessage, defaultValidationMessage]);
    var setValue = React.useCallback(function (e, val) {
        if (!isValueControlled && !disabled) {
            setStateValue(val);
            if (!isCheckedControlled && !disabled) {
                setStateChecked(val);
            }
        }
        if (onChange && !disabled) {
            dispatchEvent(onChange, e, __assign(__assign({}, getImperativeHandle()), { value: val }), { value: val });
        }
    }, [isCheckedControlled, disabled, onChange, isValueControlled, getImperativeHandle]);
    var handleChange = React.useCallback(function (e) {
        var newValue = e.target.checked;
        setValue(e, newValue);
    }, [setValue, value]);
    var handleKeyDown = React.useCallback(function (e) {
        if (disabled) {
            return;
        }
        var keyCode = e.keyCode;
        var currentVal = e.currentTarget.checked;
        if (keyCode === Keys.space) {
            e.preventDefault();
            e.stopPropagation();
            setValue(e, !currentVal);
        }
    }, [disabled, setValue]);
    var handleKeyUp = React.useCallback(function (e) {
        if (disabled) {
            return;
        }
        var keyCode = e.keyCode;
        if (keyCode === Keys.space) {
            e.preventDefault();
        }
    }, [disabled]);
    var handleFocus = React.useCallback(function (e) {
        if (onFocus && !disabled) {
            dispatchEvent(onFocus, e, getImperativeHandle(), undefined);
        }
    }, [onFocus, disabled, getImperativeHandle]);
    var handleBlur = React.useCallback(function (e) {
        if (onBlur && !disabled) {
            dispatchEvent(onBlur, e, getImperativeHandle(), undefined);
        }
    }, [onBlur, disabled, getImperativeHandle]);
    var dir = useDir(elementRef, props.dir);
    var checkboxClasses = classNames({ 'k-disabled': disabled }, className);
    var inputProps = __assign(__assign({ type: 'checkbox', className: classNames('k-checkbox', (_a = {},
            _a["k-checkbox-".concat(kendoThemeMaps.sizeMap[size] || size)] = size,
            _a["k-rounded-".concat(kendoThemeMaps.roundedMap[rounded] || rounded)] = rounded,
            _a['k-indeterminate'] = indeterminateProp,
            _a['k-disabled'] = disabled,
            _a['k-invalid k-invalid'] = !(isValid || validityStyles !== undefined || validityStyles === true),
            _a)), ref: elementRef, name: name, id: id || calculatedId, 'aria-labelledby': ariaLabelledBy, 'aria-describedby': ariaDescribedBy, checked: Boolean(checkedProp), disabled: disabled, tabIndex: getTabIndex(tabIndex, disabled), role: 'checkbox', required: required !== undefined ? required : false, 'aria-checked': currentChecked || checkedProp ?
            true :
            indeterminateProp ?
                'mixed' :
                false, 'aria-disabled': disabled || undefined }, others), { onChange: handleChange, onKeyDown: handleKeyDown, onKeyUp: handleKeyUp, onFocus: handleFocus, onBlur: handleBlur });
    var checkboxInput = (React.createElement(React.Fragment, null, 
    // removing value prop is required due to bug in react where
    // value set to undefined override default submit value
    valueProp === undefined ? (React.createElement("input", __assign({}, inputProps))) : (React.createElement("input", __assign({}, inputProps, { value: valueIsBoolean ? undefined : valueProp === null ? '' : valueProp })))));
    var checkboxLabel = (React.createElement(React.Fragment, null, label !== undefined ? (React.createElement("label", { className: 'k-checkbox-label', htmlFor: id || calculatedId, style: { userSelect: 'none' } },
        label,
        labelOptional && React.createElement("span", { className: "k-label-optional" }, optionalMessage))) : null));
    return (React.createElement(React.Fragment, null, (labelPlacement === 'before') ? (React.createElement("span", { className: checkboxClasses, dir: 'rtl' },
        checkboxInput,
        checkboxLabel,
        children)) : (React.createElement("span", { className: checkboxClasses, dir: dir },
        checkboxInput,
        checkboxLabel,
        children))));
});
Checkbox.propTypes = {
    checked: PropTypes.bool,
    className: PropTypes.string,
    defaultChecked: PropTypes.bool,
    defaultValue: PropTypes.any,
    dir: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    size: PropTypes.oneOf([null, 'small', 'medium', 'large']),
    rounded: PropTypes.oneOf([null, 'small', 'medium', 'large']),
    ariaLabelledBy: PropTypes.string,
    ariaDescribedBy: PropTypes.string,
    label: PropTypes.any,
    labelPlacement: PropTypes.string,
    labelOptional: PropTypes.bool,
    name: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.any,
    validationMessage: PropTypes.string,
    required: PropTypes.bool,
    validate: PropTypes.bool,
    valid: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
};
var defaultProps = {
    size: 'medium',
    rounded: 'medium'
};
Checkbox.defaultProps = defaultProps;
Checkbox.displayName = 'KendoCheckbox';
