import * as React from 'react';
/** @hidden */
export var useAnimation = function (config, deps) {
    var offset = React.useRef(0);
    var mount = React.useRef(false);
    var animationFrame = React.useRef();
    var animate = function (transition) {
        var duration = transition.duration;
        var start;
        var progress;
        var skip = offset.current && 1 - offset.current;
        if (transition.onStart) {
            transition.onStart();
        }
        var frame = function (timestamp) {
            if (!start) {
                start = timestamp;
            }
            progress = timestamp - start + 1;
            var rate = progress / duration + skip;
            if (rate <= 1) {
                if (transition.onUpdate) {
                    transition.onUpdate(rate);
                }
                animationFrame.current = window.requestAnimationFrame(frame);
                offset.current = rate;
            }
            else {
                if (transition.onEnd) {
                    transition.onEnd(1);
                }
                offset.current = 0;
            }
        };
        animationFrame.current = window.requestAnimationFrame(frame);
    };
    React.useEffect(function () {
        animate(config);
        return function () {
            if (animationFrame.current) {
                window.cancelAnimationFrame(animationFrame.current);
            }
        };
    }, deps);
    React.useEffect(function () {
        mount.current = true;
    }, []);
};
