import * as UIUtils from "../../ui_utils";
import { getRiskLabel, getRiskScale } from "../../helpers/risk_helper";
import { RISK_TYPE_ENUM } from "../../helpers/constants/constants";
import { getChangeInfo } from "../tech_transfer_column_value_generator";
import { generateRecordDiff } from "../diff/tech_transfer_diff_generator";
import { PROCESS_LEVEL_ID } from "../tech_transfer_constants";
import ConfigurableTablesAdapterHelper from "../../configurableTables/adapters/configurable_tables_adapter_helper";
import RiskUtils from "../../../server/common/misc/common_risk_utils";

const CommonUtils = require("../../../server/common/generic/common_utils");

export default class TechTransferAdapterHelper extends ConfigurableTablesAdapterHelper {
  constructor(props) {
    super(props);
    this.effectiveTechTransferRMP = props.effectiveTechTransferRMP;
  }

  attachTransformationObjects(record) {
    const transferredFromId = record.techTransferredFromId !== record.id ?
      (record.techTransferredFromId || record.transferredFromId) : null;

    if (transferredFromId) {
      record.techTransferredFrom = this.activeMap[transferredFromId];
    }
  }

  getRiskLinksDocuments(riskLinks, documentsNotAttachedToRisks) {
    let links = Array.from(riskLinks.map(riskLink => riskLink.links).reduce(
      (previousValue, currentValue) => {
        currentValue.forEach(link => {
          previousValue.set(link.uuid, link);
        });
        return previousValue;
      },
      new Map(),
    ).values());

    links = links.concat(documentsNotAttachedToRisks);

    return links.sort(CommonUtils.sortBy("index"));
  }


  getRiskLinksSummary(riskLinks) {
    let links = UIUtils.deepClone(riskLinks);
    return links.map(link => {
      let message = `${link.riskLinkWithoutId}`;
      if (link.effect && link.effect.length > 0) {
        message += ` Effect on Attribute: ${link.effect}`;
      }

      message += ` Impact: ${link.impact}`;
      message += ` Uncertainty: ${link.uncertainty}`;
      message += link.justification ? ` Justification: ${link.justification}` : "";
      return message;
    });
  }

  addCommonFilterProps(record) {
    const {
      techTransferImpact,
      techTransferUncertainty,
      techTransferDetectability,
    } = record;

    if (techTransferImpact && techTransferUncertainty && techTransferDetectability) {
      record.rpnRiskValue = techTransferImpact * techTransferUncertainty * techTransferDetectability;
      record.rpnRiskScale = getRiskScale(RISK_TYPE_ENUM.RPN, this.effectiveTechTransferRMP, record.rpnRiskValue, record, true);
      record.outcomeRiskLabel = record.rpnRiskScale ? record.rpnRiskScale.riskLabel : RiskUtils.NOT_ASSESSED_LABEL;
    }

    const commonProps = [record, false, true, true, true];
    record.techTransferImpactLabel = getRiskLabel(RISK_TYPE_ENUM.IMPACT, this.effectiveTechTransferRMP, techTransferImpact, ...commonProps);
    record.techTransferUncertaintyLabel = getRiskLabel(RISK_TYPE_ENUM.UNCERTAINTY, this.effectiveTechTransferRMP, techTransferUncertainty, ...commonProps);
    record.techTransferDetectabilityLabel = getRiskLabel(RISK_TYPE_ENUM.DETECTABILITY_RISK, this.effectiveTechTransferRMP, techTransferDetectability, ...commonProps);

    record.capabilityRiskLabel = getRiskLabel(RISK_TYPE_ENUM.CAPABILITY_RISK, record.effectiveModelRMP, record.capabilityRisk, ...commonProps);
    record.detectabilityRiskLabel = getRiskLabel(RISK_TYPE_ENUM.DETECTABILITY_RISK, record.effectiveModelRMP, record.detectabilityRisk, ...commonProps);

    const commonPropsFrom = [record.techTransferredFrom, false, true, true, true];
    record.techTransferredFrom.capabilityRiskLabel = getRiskLabel(RISK_TYPE_ENUM.CAPABILITY_RISK, record.effectiveModelRMP, record.techTransferredFrom.capabilityRisk, ...commonPropsFrom);
    record.techTransferredFrom.detectabilityRiskLabel = getRiskLabel(RISK_TYPE_ENUM.DETECTABILITY_RISK, record.effectiveModelRMP, record.techTransferredFrom.detectabilityRisk, ...commonPropsFrom);

    record.changeInfo = getChangeInfo(record);

    const {areChangesIgnored, recordChanged} = record.changeInfo;
    record.change = recordChanged ? areChangesIgnored ? "No" : "Yes" : "No";
  }

  /**
   * @param record The sending  site record
   */
  getReceivingUnitOperationObject(record) {
    let receivingUnitOperation;

    if (record.unitOperationId) {
      receivingUnitOperation = Object.values(this.results.uoMap).find(uo =>
        record.unitOperationId && (uo.techTransferredFromId === record.unitOperationId ||
        uo.transferredFromId === record.unitOperationId)) || {};
    } else if (record.UnitOperations) {
      const unitOperationIds = record.UnitOperations.map(uo => uo.id);
      receivingUnitOperation = Object.values(this.results.uoMap)
        .filter(uo => unitOperationIds.includes(uo.techTransferredFromId || uo.transferredFromId));
    }

    let unitOperationObject;
    if (Array.isArray(receivingUnitOperation)) {
      unitOperationObject = {
        UnitOperations: receivingUnitOperation,
      };
    } else {
      unitOperationObject = {
        unitOperationId: receivingUnitOperation ? receivingUnitOperation.id :
          PROCESS_LEVEL_ID,
      };
    }

    return unitOperationObject;
  }

  /**
   * @param record The receiving site record
   */
  getSendingUnitOperationObject(record) {
    let sendingUnitOperation;
    if (record.unitOperationId) {
      sendingUnitOperation = Object.values(this.results.uoMap).find(uo => uo.id === record.unitOperationId);
    } else if (record.UnitOperations) {
      const unitOperationIds = record.UnitOperations.map(uo => uo.id);
      sendingUnitOperation = Object.values(this.results.uoMap)
        .filter(uo => unitOperationIds.includes(uo.id));
      const sendingUnitOperationIds = sendingUnitOperation.map(uo => uo.techTransferredFromId || uo.transferredFromId);
      sendingUnitOperation = Object.values(this.results.uoMap)
        .filter(uo => sendingUnitOperationIds.includes(uo.id));
    }

    let unitOperationObject;
    if (Array.isArray(sendingUnitOperation)) {
      unitOperationObject = {
        UnitOperations: sendingUnitOperation,
      };
    } else {
      unitOperationObject = {
        unitOperationId: sendingUnitOperation ?
          (
            sendingUnitOperation.techTransferredFromId ||
            sendingUnitOperation.transferredFromId
          ) :
          PROCESS_LEVEL_ID,
      };
    }

    return unitOperationObject;
  }

  getUnderlyingChangesCount(records, parentId, parentProp) {
    let underlyingDiffsCount = 0;
    for (const record of records.filter(record => record[parentProp] === parentId)) {
      const diff = generateRecordDiff(record.pluralizedModelName, record.techTransferredFrom, record, record.changeCriteria);
      underlyingDiffsCount += diff.totalChangesCount > 0 ? 1 : 0;
    }
    return underlyingDiffsCount;
  }

  getRiskLabel(object, prop, riskType, rmp, getForRiskLabel = false) {
    const riskValue = riskType === RISK_TYPE_ENUM.RPN ?
      object.techTransferImpact * object.techTransferUncertainty * object.techTransferDetectability :
      object[prop];
    const riskScale = getRiskScale(riskType, rmp, riskValue, object, getForRiskLabel);
    return getForRiskLabel ? riskScale.riskLabel : riskScale.scoreLabel;
  }
}

