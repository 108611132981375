import { BaseParser } from "./base_parser";
import * as UIUtils from "../../../ui_utils";
import { FileType } from "../../common/constants";

/**
 * Parser will be used to parse table of contents
 */
export class TableOfContentsParser extends BaseParser {
  get selector() {
    return "h1, h2, h3, h4, h5, h6";
  }

  getElements() {
    return Array.from(this.globalNode.querySelectorAll(this.selector));
  }

  parse() {
    const newElements = [];

    if (this.fileType === FileType.DOC) {
      const htmlText = `
        <br>
        <span style="mso-element:field-begin"></span>TOC
        <span style="mso-element:field-separator"></span>
        <b>Table of Contents - Right-Click->Update Field to Generate</b>
        <span style="mso-element:field-end"></span>
      `;
      const tocElements = this.createElementFromText(htmlText, true);
      newElements.push(...tocElements);
      return newElements;
    }

    const titleHtmlText = `<h1 class="table-of-content-title text-center">Table of Contents</h1>`;
    const titleElement = this.createElementFromText(titleHtmlText);
    newElements.push(titleElement);

    const elements = this.getElements();
    for (const element of elements) {
      if (element.innerHTML && !element.innerHTML.includes("<img") && element.parentNode && element.parentNode.nodeName !== "TD") {
        const id = UIUtils.generateUUID();
        element.id = id;
        const newElementHtmlText = `
        <div class="table-of-content-item p-${element.tagName.toLowerCase()}">
          <p>
            <a class="table-of-content-link" href="#${id}">${element.innerText}</a>
          </p> 
          <p class="table-of-content-page-number"></p>
        </div>
      `;
        const newElement = this.createElementFromText(newElementHtmlText);
        newElements.push(newElement);

        // We create this temporary link element here for heading so the TableOfContentsSetter
        // can locate it and set the page number in the table of contents
        const tempLinkHtmlText = `<a class="inactive-link" href="**${id}**">&nbsp;</a>`;
        const tempLinkElement = this.createElementFromText(tempLinkHtmlText);
        element.appendChild(tempLinkElement);
      }
    }
    return newElements;
  }
}
