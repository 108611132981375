"use strict";

import { Ensure } from "../../../../server/common/generic/common_ensure";
import { Log, LOG_GROUP } from "../../../../server/common/logger/common_log";
// eslint-disable-next-line no-unused-vars
const Logger = Log.group(LOG_GROUP.Documents, "BasePDFRenderer");

/**
 * Defines the base functionality required for a class that will render PDF documents, pages or elements.
 *  @abstract
 */
export class BasePDFRenderer {
  /**
   * @param viewerRoot {React.RefObject<HTMLElement>} The root container that hosts the PDF viewer.
   * This is the container that gets resized when the window is resized.
   */
  constructor(viewerRoot) {
    Ensure.that({viewerRoot}).isNotFalsy();

    /**
     * The root container that hosts the PDF viewer.
     * @type {React.RefObject<HTMLElement>}
     * @protected
     */
    this.viewerRoot = viewerRoot;

    this.postRenderQueue = [];
    this.renderingInProgress = false;
    this.shouldCancelRender = false;
  }


  /**
   * Identifies whether or not the specified document or page needs to be rendered.
   * @param target {IPDFPage|ICachedPDFDocument} The page or document that needs to be tested whether it should be rendered.
   * @param context {IPDFRenderContext} The object with information about the current rendering operation.
   * @returns {boolean} A boolean specifying whether or not the rendering should proceed.
   * @abstract
   */
  shouldRender(target, context) {
    Ensure.notImplemented("shouldRender", {target, context});
    return false;
  }

  /**
   * Renders the specified PDF document or page
   * @param target {IPDFPage|ICachedPDFDocument} The document or page that needs to be tested whether it should be rendered.
   * @param context {IPDFRenderContext} The object with information about the current rendering operation.
   * @returns {IPDFPage|IPDFRenderDocument} The rendering result object.
   */
  async render(target, context) {
    Logger.verbose("render", Log.object(target));
    this.renderingInProcess = true;
    let result = target;
    try {
      // Not using target.rendered because that means that the target was rendered at some point
      // in the past (even in previous iterations).
      // Skipped means that for this iteration it didn't render.
      result.skipped = !this.shouldRender(result, context);
      if (!result.skipped) {
        result.renderObject = await this.renderCore(result, context);
      }
    } finally {
      result = await this.endRender(result, context);
    }
    return result;
  }

  /**
   * Renders the specified PDF page
   * @param target {IPDFPage|ICachedPDFDocument} The document or page that needs to be rendered.
   * @param context {IPDFRenderContext} The object with information about the current rendering operation.
   * @returns {IPDFPage|IPDFRenderDocument} The rendering result object.
   * @abstract
   */
  async renderCore(target, context) {
    Ensure.notImplemented("render", {target, context});
    return target;
  }

  /**
   * Performs cleanup logic after rendering.
   * @param target {IPDFPage|ICachedPDFDocument} The or document page that needs to be tested whether it should be rendered.
   * @param context {IPDFRenderContext} The object with information about the current rendering operation.
   */
  async endRender(target, context) {
    Ensure.virtual("endRender", {target, context});
    return Promise.resolve(target);
  }

  /**
   * Attempts to retrieve the nearest parent container that is visible.
   * @param renderObject
   * @returns {React.RefObject<HTMLElement>}
   */
  getVisibleContainer(renderObject) {
    let containerRef = renderObject.containerRef || this.viewerRoot;

    if (containerRef && containerRef.current) {
      const $current = $(containerRef.current);
      let isVisible = $current.is(":visible");

      if (!isVisible) {
        containerRef = {current: $current.parent(":visible")[0]};
      }
    }
    return containerRef;
  }
}
