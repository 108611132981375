"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import ApprovalRequestPopup from "./approval_request_popup";
import ApprovalResponsePopup from "./approval_response_popup";
import WithdrawPopup from "./withdraw_popup";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { can, generateTooltip } from "../../utils/ui_permissions";
import { getLatestApprovalRequestVersionTransition } from "../../helpers/approval_helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import BaseReactComponent from "../../base_react_component";
import { EDITOR_OPERATIONS, EDITOR_TYPES } from "../editor_constants";
import toggleHistoryIcon from "./icons/toggle-history.png";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import CommonSecurity from "../../../server/common/generic/common_security";
import CommonConstants from "../../../server/common/generic/common_constants";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import RecordOperationsHandler from "../../processExplorer/handlers/record_operations_handler";
import MemoryCache from "../../utils/cache/memory_cache";
import TypeaheadObjectCacheFactory from "../../utils/cache/typeahead_object_cache_factory";
import BaseObjectCache from "../../utils/cache/base_object_cache";
import { RouterContext } from "../../utils/router_context";

const Logger = Log.group(LOG_GROUP.Editables, "ApprovalContainer");

// i18next-extract-mark-ns-start editor
const CommonUtils = require("../../../server/common/generic/common_utils");

const EDITABLE_STATES = [
  CommonUtils.VERSION_STATES.DRAFT,
  CommonUtils.VERSION_STATES.WITHDRAWN,
  CommonUtils.VERSION_STATES.WITHDRAWN_CASCADED,
  CommonUtils.VERSION_STATES.REJECTED,
  CommonUtils.VERSION_STATES.REJECTED_CASCADED,
  CommonUtils.VERSION_STATES.APPROVED,
  CommonUtils.VERSION_STATES.RESTORED,
  CommonUtils.VERSION_STATES.RESTORED_CASCADED,
];

/**
 * This shows the current state of approval and the full size buttons for editing, approving, etc.
 */
export class ApprovalContainer extends BaseReactComponent {
  static contextType = RouterContext;

  constructor(props) {
    super(props);

    Logger.debug(() => this.getLogHeader(props) + "constructor :: checkRenderOptions");
    const {parent} = this.props;
    this.setStateSafely({
      showApprovalRequestPopup: false,
      showApprovalResponsePopup: false,
      showWithdrawPopup: false,
      // The buttons depend on whether the project/process is archived, so we have to load them once we can.
      isTypeaheadLoaded: !this.isInProjectContext(props),
      renderOptions: this.getCurrentRenderOptions(props)
    });

    this.securityTypeName = parent.securityTypeName;

    this.props.parent.addOnDataReceivedListener(() => {
      Logger.debug(() => this.getLogHeader() + "data received from parent");
      const newRenderOptions = this.getCurrentRenderOptions(this.props);
      this.setStateSafely(newRenderOptions);
    });
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.isInProjectContext()) {
      Logger.debug(() => this.getLogHeader() + "componentDidMount :: attemptToLoadTypeahead");
      this.attemptToLoadTypeahead();
    }
  }

  isInProjectContext(props = this.props) {
    const parentState = props.parent?.state;
    const typeCode = parentState?.typeCode;
    let modelName = parentState?.modelType;
    if (typeCode && !modelName) {
      modelName = UIUtils.getModelNameForTypeCode(typeCode);
    }

    if (!modelName) {
      Logger.error(this.getLogHeader(props) + "cannot detect typeCode from parent");
      return true;
    }

    const modelDeclaration = CommonUtils.modelFinder.findFromModelName(modelName);
    if (!modelDeclaration) {
      Logger.error(this.getLogHeader(props) + "cannot detect model declaration for model " + modelName);
    }

    if (modelDeclaration.isInProjectOrGlobalContext) {
      return props.isInProjectContext;
    }

    return modelDeclaration.isInProjectContext;
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = this.propsArrayAreDifferent(nextProps, "additionalButtons") ||
      this.propsArrayAreDifferent(nextProps, "additionalPopups") ||
      this.propsAreDifferent(nextProps, "dataModified", true) ||
      this.propsArrayAreDifferent(nextProps, "versions") ||
      this.propsArrayAreDifferent(nextProps, "currentDiffingVersion") ||
      this.propsAreDifferent(nextProps, "objectCurrentState") ||
      this.propsAreDifferent(nextProps, "parentId") ||
      this.propsAreDifferent(nextProps, "projectId") ||
      this.propsAreDifferent(nextProps, "processId") ||
      this.propsAreDifferent(nextProps, "versionId") ||
      this.propsAreDifferent(nextProps, "isInProjectContext", true) ||
      this.stateIsDifferent(nextState, "isTypeaheadLoaded", true) ||
      this.stateIsDifferent(nextState, "showApprovalRequestPopup", true) ||
      this.stateIsDifferent(nextState, "showApprovalResponsePopup", true) ||
      this.stateIsDifferent(nextState, "showWithdrawPopup", true) ||
      this.propsAreDifferent(nextProps, "isApproved", true) ||
      this.propsAreDifferent(nextProps, "isLoading", true) ||
      this.stateArrayAreDifferent(nextState, "renderOptions");

    if (!shouldUpdate && (!nextProps.versionId || nextProps.versionId === -1) && nextProps.versions?.length > 0) {
      Logger.debug(() => this.getLogHeader() + `shouldComponentUpdate :: YES - Forced because versionId is not specified`, Log.object({...nextProps}));
      shouldUpdate = true;
    }


    const propsDiff = UIUtils.objectDeepDiffMapper.getDifferences(nextProps, this.props, ["parent"]);
    const stateDiff = UIUtils.objectDeepDiffMapper.getDifferences(nextState, this.state);
    Logger.debug(() => this.getLogHeader() + `shouldComponentUpdate :: ${shouldUpdate ? "YES" : "NO"}`, Log.object({
      propsDiff,
      stateDiff,
      nextProps: {...nextProps},
      nextState: {...nextState},
      props: {...this.props},
      state: {...this.state}
    }));

    return shouldUpdate;
  }

  componentDidUpdate(previousProps, previousState) {
    if (!previousProps.projectId && this.props.projectId) {
      Logger.debug(() => this.getLogHeader() + `componentDidUpdate :: Load typeaheads because project has changed. Old value: ${previousProps.projectId}. New value: ${this.props.projectId}`);
      this.attemptToLoadTypeahead();
      return;
    }

    if (previousProps.isInProjectContext !== this.props.isInProjectContext) {
      if (this.props.isInProjectContext) {
        Logger.debug(() => this.getLogHeader() + `componentDidUpdate :: Load typeaheads because parent is isInProjectContext`);
        this.setStateSafely({
          isTypeaheadLoaded: false
        }, () => {
          this.attemptToLoadTypeahead();
        });
      } else {
        Logger.debug(() => this.getLogHeader() + `componentDidUpdate :: Cancel loading typeaheads as parent is not in isInProjectContext`);
        this.setStateSafely({
          isTypeaheadLoaded: true
        });
      }

      return;
    }

    const renderOptions = this.getCurrentRenderOptions(this.props);
    if (JSON.stringify(renderOptions) !== JSON.stringify(previousState.renderOptions)) {
      const renderOptionsDiff = UIUtils.objectDeepDiffMapper.getDifferences(renderOptions, previousState.renderOptions);
      Logger.debug(() => this.getLogHeader() + `componentDidUpdate :: Render options has changed`, Log.object({
        diff: renderOptionsDiff,
        renderOptions
      }));
      this.setStateSafely({
        renderOptions
      });
    } else {
      Logger.debug(() => this.getLogHeader() + `componentDidUpdate :: Same renderOptions`, Log.object({...renderOptions}));
    }
  }

  propsAreDifferent(nextProps, name, isBoolean = false) {
    if (isBoolean) {
      return this.valuesAreDifferent(!!nextProps[name], !!this.props[name], name);
    } else {
      let nextValue = nextProps[name];
      let value = this.props[name];
      if (name === "versionId") {
        nextValue = nextValue === -1 ? null : nextValue;
        value = value === -1 ? null : nextValue;
      }

      return this.valuesAreDifferent(nextValue, value, name);
    }
  }

  propsArrayAreDifferent(props, name) {
    return this.objectsAreDifferent(props, this.props, name);
  }

  stateArrayAreDifferent(state, name) {
    return this.objectsAreDifferent(state, this.state, name);
  }

  stateIsDifferent(nextState, name, isBoolean = false) {
    if (isBoolean) {
      return this.valuesAreDifferent(!!nextState[name], !!this.state[name], name);
    } else {
      return this.valuesAreDifferent(nextState[name], this.state[name], name);
    }
  }

  valuesAreDifferent(value1, value2, field) {
    const isDifferent = value1 !== value2;

    if (value1 && !Number.isNaN(value1)) {
      value1 = value1.toString();
    }

    if (value2 && !Number.isNaN(value2)) {
      value2 = value2.toString();
    }

    if (isDifferent) {
      Logger.debug(() => this.getLogHeader() + `shouldComponentUpdate :: Field ${field} different (Old value: ${value2}, New value: ${value1}) `);
    }

    return isDifferent;
  }

  objectsAreDifferent(obj1, obj2, field) {
    if (JSON.stringify(obj1[field]) !== JSON.stringify(obj2[field])) {
      const diff = UIUtils.objectDeepDiffMapper.getDifferences(obj1[field], obj2[field]);
      Logger.debug(() => this.getLogHeader() + `shouldComponentUpdate:: Field ${field} is different`, Log.object({
        diff,
        old: {...obj1[field]},
        new: {...obj2[field]}
      }));
      return true;
    }

    return false;
  }

  async attemptToLoadTypeahead() {
    if (this.state.isTypeaheadLoaded) {
      Logger.debug(() => this.getLogHeader() + "attemptToLoadTypeahead :: Typeahead already loaded");
      return;
    }

    const parentIsProject = this.props.parent.baseTypeName === "project";
    if (parentIsProject && this.props.parent.isAdd()) {
      Logger.debug(() => this.getLogHeader() + "attemptToLoadTypeahead :: No neeed to load project information during Create operation");
      this.setStateSafely({
        isTypeaheadLoaded: true
      });
      return;
    }

    const typesToCache = [];
    if (this.getProjectId() && !parentIsProject) {
      typesToCache.push("Project");
    }

    if (this.props.parent.baseTypeName !== "process") {
      typesToCache.push("Process");
    }

    if (typesToCache.length === 0) {
      Logger.debug(() => this.getLogHeader() + "attemptToLoadTypeahead :: no typeaheads selected to load");
      // this method will be retried when parent will cause the component to render using ProjectId or ProcessId parameters
      return;
    }

    Logger.debug(() => this.getLogHeader() + "attemptToLoadTypeahead :: preparing typeaheads for " + typesToCache);

    // we try to download all typeaheads due to concurency issues
    const remainingTypesToCache = typesToCache
      .map(typeCode => ({
        typeCode,
        isLoaded: false
      }));

    if (remainingTypesToCache.length > 0) {
      await this.loadTypeaheadOptionsFromServer(remainingTypesToCache);
    } else {
      this.checkForTypeaheadsAlreadyAvailableInMemoryCache(remainingTypesToCache);
    }
  }

  async loadTypeaheadOptionsFromServer(remainingTypesToCache) {
    for (let cache of remainingTypesToCache) {
      await this.loadTypeaheadOptionsFromServerForTypeCode(cache.typeCode, [...remainingTypesToCache]);
    }
  }

  async loadTypeaheadOptionsFromServerForTypeCode(typeCode, remainingTypesToCache) {
    const memoryCache = this.getInMemoryCache();
    const cacheKey = this.getMemoryCacheKeyForTypeahead(typeCode);
    if (!memoryCache.get(cacheKey)) {
      Logger.debug(() => this.getLogHeader() + `loadTypeaheadOptionsFromServerForTypeCode :: typeahead loading ${typeCode}`);
      // we are not using the MultipleTypeahead as it does not support archived records
      const typeaheadObjectCache = TypeaheadObjectCacheFactory.createTypeaheadObjectCacheIfPossible(
        typeCode, this.getProjectId, this.getProcessId);
      if (!typeaheadObjectCache) {
        Logger.error(this.getLogHeader() + `loadTypeaheadOptionsFromServerForTypeCode :: typeahead loading ${typeCode} failure - Cannot build cache for project ${this.getProjectId()} and processid ${this.getProcessId()}`);
        return;
      }

      const cachedResults = typeaheadObjectCache.getOptionsFromCacheIncludingArchived();
      if (!BaseObjectCache.isObjectCacheStillLoading(cachedResults)) {
        Logger.debug(() => this.getLogHeader() + `loadTypeaheadOptionsFromServerForTypeCode :: typeahead already loaded ${typeCode}`, Log.object(cachedResults));
        memoryCache.set(cacheKey, cachedResults);
        this.checkForTypeaheadsAlreadyAvailableInMemoryCache(remainingTypesToCache);
      } else {
        // we are using the async option of loadOptions because when using callbacks on a fast loading environment is causes the callback to be missed
        const loadResult = await typeaheadObjectCache.loadOptions().promise();
        if (loadResult) {
          const results = typeaheadObjectCache.getOptionsFromCacheIncludingArchived();
          memoryCache.set(cacheKey, results);
          Logger.debug(() => this.getLogHeader() + `loadTypeaheadOptionsFromServerForTypeCode :: typeahead loaded ${typeCode}`, Log.object(results));
          this.checkForTypeaheadsAlreadyAvailableInMemoryCache(remainingTypesToCache);
        }
      }
    } else {
      Logger.debug(() => this.getLogHeader() + `typeahead already loaded ${typeCode}`);
      this.checkForTypeaheadsAlreadyAvailableInMemoryCache(remainingTypesToCache);
    }
  }

  getProcessId() {
    return this.props.processId;
  }

  getProjectId() {
    return this.props.projectId;
  }

  checkForTypeaheadsAlreadyAvailableInMemoryCache(typesToCache) {
    const memoryCache = this.getInMemoryCache();
    for (let cache of typesToCache) {
      const typeaheadOptionsFromCache = memoryCache.get(this.getMemoryCacheKeyForTypeahead(cache.typeCode));
      if (typeaheadOptionsFromCache) {
        cache.isLoaded = true;
      }
    }

    const loadedCaches = typesToCache.filter(cache => cache.isLoaded).map(cache => cache.typeCode);
    const allCaches = typesToCache.map(cache => cache.typeCode);
    Logger.debug(() => this.getLogHeader() + `checkForTypeaheadsAlreadyAvailableInMemoryCache - Caches loaded ${loadedCaches.join(", ")} from ${allCaches.join(", ")}`);
    if (allCaches.length === loadedCaches.length) {
      this.setStateSafely({
        isTypeaheadLoaded: true,
      });
    }
  }

  getInMemoryCache() {
    if (!this.inMemoryCache) {
      Logger.debug(() => this.getLogHeader() + "getInMemoryCache :: create new cache instance");
      this.inMemoryCache = MemoryCache.getNamedInstance("approvalContainerFor" + (this.props?.location ?? "UNKNOWN"));
    }

    return this.inMemoryCache;
  }

  getMemoryCacheKeyForTypeahead(typeCode) {
    return typeCode;
  }

  async handleProposeButton(event) {
    event.persist();

    const proposeHandler = () => {
      if (this.props.parent.baseTypeName === "unitOperation" || this.props.parent.baseTypeName === "step") {
        const instance = this.props.parent.getInstance();
        const commonProps = {parent: this, projectId: this.getProjectId()};

        const recordOperationsHandler = new RecordOperationsHandler(commonProps);

        recordOperationsHandler.handleBulkPropose(`${instance.typeCode}-${instance.id}`, false);
      } else {
        this.eventForm = event.target.form;
        this.props.parent.updateControls(true, CommonUtils.FORM_VALIDATION_MODE.PROPOSE, () => {
          this.props.parent.handleChangeValue("formValidationMode", CommonUtils.FORM_VALIDATION_MODE.PROPOSE, async() => {
            //Check custom validated controls and trigger validation messages
            let customControlsValid = await this.props.parent.areCustomControlsValid(CommonUtils.FORM_VALIDATION_MODE.PROPOSE);
            this.props.parent.updateControls(false, null, () => {
              if (this.eventForm.checkValidity() === true) {
                if (customControlsValid) {
                  this.setStateSafely({
                    showApprovalRequestPopup: true,
                    approvalInfo: null
                  });
                  this.props.parent.setPopupOpened(true);
                }
              }

              if (this.props.onAfterProposeButtonClick) {
                this.props.onAfterProposeButtonClick();
              }
            });
          });
        });
      }
    };

    if (this.props.onBeforeProposeButtonClick) {
      this.props.onBeforeProposeButtonClick(proposeHandler);
    } else {
      proposeHandler();
    }
  }

  handleSaveButton(event) {
    event.persist();

    const saveHandler = () => {
      this.props.parent.updateControls(true, CommonUtils.FORM_VALIDATION_MODE.SAVE, () => {
        this.props.parent.handleApprovalClearValue(() => {
          this.props.parent.handleSave(event);
          if (this.props.onAfterSaveButtonClick) {
            this.props.onAfterSaveButtonClick();
          }
        });
      });
    };

    if (this.props.onBeforeSaveButtonClick) {
      this.props.onBeforeSaveButtonClick(saveHandler);
    } else {
      saveHandler();
    }
  }

  handleApproveRejectButton(e) {
    UIUtils.ignoreHandler(e);

    if (this.props.objectCurrentState &&
      (this.props.objectCurrentState === CommonUtils.VERSION_STATES.PROPOSED_FOR_ARCHIVE ||
        this.props.objectCurrentState === CommonUtils.VERSION_STATES.PROPOSED_FOR_RESTORE)) {
      let url = this.getURLToLoadChildObjects();
      UIUtils.setLoadingDisabled(false);
      UIUtils.secureAjaxGET(url).done(this.handleReceivedChildObjectsFromServer);
    } else {
      this.setStateSafely({
        showApprovalResponsePopup: true,
        approvalInfo: null,
        warning: null
      });
      this.props.parent.setPopupOpened(true);
    }
  }

  handleReceivedChildObjectsFromServer(results) {
    const warning = results.length > 0
      ? (
        <div>
          <div>
            Approving/Rejecting will also apply to the following child objects:
          </div>
          <br />
          {results.map(result => {
            return (
              <div key={result.typeCode + "-" + result.id}>
                <a href={getURLByTypeCodeAndId(result.typeCode, "View", result.id)}
                   rel="noopener noreferrer"
                   target="_blank"
                ><b>{UIUtils.getRecordCustomLabelForDisplay(result)}</b></a>
              </div>
            );
          })}
        </div>
      ) : null;

    this.setStateSafely({
      showApprovalResponsePopup: true,
      approvalInfo: null,
      warning,
    });
    this.props.parent.setPopupOpened(true);
    UIUtils.setLoadingDisabled(true);
  }

  getURLToLoadChildObjects() {
    let id = this.props.parent.state.id;
    const action = this.props.objectCurrentState === CommonUtils.VERSION_STATES.PROPOSED_FOR_ARCHIVE ?
      CommonConstants.ACTIONS.ARCHIVE : CommonConstants.ACTIONS.RESTORE;
    return `editables/${this.props.parent.capitalizedBaseTypeName}/${id}?includeHistory=false&approved=false&childrenOnly=${action}&includeFromLibrary=true`;
  }

  handleWithdrawButton(e) {
    UIUtils.ignoreHandler(e);

    this.setStateSafely({
      showWithdrawPopup: true
    });
    this.props.parent.setPopupOpened(true);
  }

  handleViewDraftButton(e) {
    UIUtils.ignoreHandler(e);
    this.openPage(EDITOR_OPERATIONS.VIEW, false);
  }

  handleViewApprovedButton(e) {
    UIUtils.ignoreHandler(e);

    this.openPage(EDITOR_OPERATIONS.VIEW, true);
  }

  handleEditButton(e) {
    UIUtils.ignoreHandler(e);

    // Check to make sure there's no pending proposal first.
    let approvalRequestTransition = this.getApprovalRequestTransition();
    let latestVersion = this.props.versions ? this.props.versions[0] : {};
    if ((latestVersion.currentState === CommonUtils.VERSION_STATES.PROPOSED //Respond with an error when there is a pending proposal and none of the approvers has rejected.
        || latestVersion.currentState === CommonUtils.VERSION_STATES.ROUTING)
      && approvalRequestTransition.approvalResponses //If any of the approvers has rejected allow for the edit to continue. Else show an error.
      && approvalRequestTransition.approvalResponses.find(response => !response.approve) === undefined) {
      UIUtils.showError("The current proposal needs to be withdrawn, rejected or unanimously approved to edit.");
    } else {
      // The approval process can leave this on.
      this.context.clearPreventNavigation();
      this.openPage(EDITOR_OPERATIONS.EDIT);
    }
  }

  openPage(operation, approved = null) {
    /**
     * If you got here looking for some insanely huge comment related to reloading data for the
     * latest draft when clicking to edit the latest draft from an approved version in a static panel, you may want
     * to click the icon besides the openPage to see the overrides of this method.
     * The comment you're looking for is in {@link StaticApprovalContainer}.
     */
    UIUtils.setLoadingDisabled(false);
    UIUtils.showLoadingImage();
    let id = UIUtils.getParameterByName("id");
    let url = "viewEdit.html?operation=" + operation + "&id=" + id;
    if (approved !== null) {
      url += "&approved=" + approved;
    }
    if (this.props.projectId) {
      url += "&projectId=" + this.props.projectId;
    }
    const securedURL = UIUtils.getSecuredURL(url);
    Logger.info(() => "Going to:", securedURL); // To help diagnose flaky tests where the spinner just spins but doesn't go to the next page.
    window.location.href = securedURL;
  }

  handleCancel(e) {
    return this.props.parent.handleCancel(e);
  }

  handleCancelApprovalRequest() {
    this.props.parent.handleChangeValue("formValidationMode", null);
  }

  handleHideModal() {
    this.setStateSafely({
      showApprovalRequestPopup: false,
      showApprovalResponsePopup: false,
      showWithdrawPopup: false
    });
  }

  async handleSendApprovalRequest(event, callback) {
    // Close the dialog only when no callback is specified, otherwise let the callback handle showing an error or closing the dialog itself.
    if (!callback && this.approvalRequestPopup) {
      callback = () => $(this.approvalRequestPopup).modal("hide");
    }
    return await this.props.parent.handleSendApprovalRequest(event, callback);
  }

  async handleSendApproval(approve) {
    // Only turn it back on if it was off originally.
    const originalLoadingDisabled = UIUtils.getLoadingDisabled();
    UIUtils.setLoadingDisabled(false);
    try {
      return await this.props.parent.handleSendApproval(approve);
    } catch (e) {
      return UIUtils.defaultFailFunction(e);
    } finally {
      if (this.approvalResponsePopup) {
        $(this.approvalResponsePopup).modal("hide");
      }
      UIUtils.setLoadingDisabled(originalLoadingDisabled);
    }
  }

  handleSendWithdraw() {
    this.props.parent.handleSendWithdraw();
    if (this.withdrawPopup) {
      $(this.withdrawPopup).modal("hide");
      this.props.parent.setPopupOpened(false);
    }
  }

  getApprovalRequestTransition() {
    let approvalRequestTransition = null;

    if (this.props.versions) {
      let latestVersion = this.props.versions[0];
      let versionTransitions = latestVersion.versionTransitions;
      let latestVersionTransition = versionTransitions[0];
      let previousVersionTransition = versionTransitions[1];
      if (latestVersionTransition.transition !== UIUtils.VERSION_STATES.REJECTED &&
        latestVersionTransition.transition !== UIUtils.VERSION_STATES.ROUTING) {
        approvalRequestTransition = latestVersionTransition;
      } else {
        approvalRequestTransition = previousVersionTransition;
      }
    }

    return approvalRequestTransition;
  }

  handleToggleHistory() {
    this.props.parent.setHistoryShowing(!this.props.parent.state.isHistoryShowing);
  }

  isLoading() {
    return this.props.isLoading
      // If these are still loading, the project/process might be archived and we don't know it.
      || !this.state.isTypeaheadLoaded;
  }

  isSaveDisabled() {
    const isSaveDisabled = this.props.parent?.state?.isSaveDisabled;
    if (isSaveDisabled) {
      return isSaveDisabled;
    } else {
      return false;
    }
  }

  getDefaultButtons(options) {
    let buttons = [];
    const {
      location,
      parent,
      onCheckPermissions,
      dataModified,
      t,
    } = this.props;

    const securityTypeName = this.securityTypeName;
    const parentValue = parent.state;
    const shouldDisablePropose = parent.baseTypeName === "unitOperation" || parent.baseTypeName === "step";
    const parentBelongsToArchivedProject = !options.isParentProject && options.isProjectArchived;
    const parentBelongsToArchivedProcess = !options.isParentProcess && !options.isParentProject && options.isProcessArchived;

    if (options.isView) {
      if (options.isModifiable) {
        if (options.isDraft) {
          const proposeButton = {
            id: "proposeButton" + location,
            onClick: this.handleProposeButton,
            isPrimary: true,
            tooltip: options.isProjectArchived
              ? t("You cannot modify a requirement in the system that belongs to an archived project.")
              : options.isProcessArchived ? t("You cannot modify a requirement in the system that belongs to an archived process.")
                : (
                  !options.canUserPropose
                    ? t("Only the current draft can be proposed")
                    : generateTooltip(CommonSecurity.Actions.PROPOSE, securityTypeName, parentValue)
                ),
            text: t("Propose"),
            className: this.getClassForLoading(),
            disabled: options.isProjectArchived
              || (!options.isParentProject && options.isProcessArchived)
              || !options.canUserPropose
              || !can(CommonSecurity.Actions.PROPOSE, securityTypeName, parentValue, onCheckPermissions)
              || this.isLoading(),
          };
          buttons.push(proposeButton);
        } else {
          const approveRejectButton = {
            id: "approveRejectButton",
            onClick: this.handleApproveRejectButton,
            isPrimary: options.canUserApprove,
            tooltip: options.isProjectArchived
              ? t("You cannot modify a requirement in the system that belongs to an archived project.")
              : options.isProcessArchived && parent.displayName !== "Document" ? t("You cannot modify a requirement in the system that belongs to an archived process.")
                : (
                  options.currentState.endsWith(" (Cascaded)")
                    ? t("You cannot approve a cascaded record. Approve or reject the higher level record instead.")
                    : (
                      !options.canUserApprove
                        ? t("You were not listed as an approver.")
                        : generateTooltip(CommonSecurity.Actions.APPROVE, securityTypeName, parentValue)
                    )
                ),
            text: t("Approve / Reject"),
            className: this.getClassForLoading(),
            disabled: parentBelongsToArchivedProject
              || (parentBelongsToArchivedProcess && parent.displayName !== "Document" && options.currentState !== CommonUtils.VERSION_STATES.PROPOSED_FOR_RESTORE)
              || !options.canUserApprove
              || !can(CommonSecurity.Actions.APPROVE, securityTypeName, parentValue, onCheckPermissions)
              || this.isLoading(),
          };
          buttons.push(approveRejectButton);

          if (options.canWithdraw) {
            const withdrawButton = {
              id: "withdrawButton",
              onClick: this.handleWithdrawButton,
              isPrimary: !options.canUserApprove,
              className: this.getClassForLoading(),
              disabled: parentBelongsToArchivedProject
                || parentBelongsToArchivedProject
                || !can(CommonSecurity.Actions.WITHDRAW, securityTypeName, parentValue, onCheckPermissions)
                || this.isLoading(),
              text: t("Withdraw"),
            };
            buttons.push(withdrawButton);
          }
        }
      }

      if (options.isApproved || !options.hasApprovedVersion) {
        const viewDraftButton = {
          id: "viewDraftButton",
          onClick: this.handleViewDraftButton,
          isPrimary: options.isApproved,
          text: t("View Last Draft"),
        };
        buttons.push(viewDraftButton);
      } else if (!options.isArchived) {
        const viewApprovedButton = {
          id: "viewApprovedButton",
          onClick: this.handleViewApprovedButton,
          isPrimary: (options.latestVersion && options.latestVersion.minorVersion === 0),
          text: t("View Last Approved"),
        };
        buttons.push(viewApprovedButton);
      }

      if (!options.isArchived) {
        const canUserEdit = can(CommonSecurity.Actions.EDIT, this.securityTypeName, parentValue, onCheckPermissions);
        const isLoading = this.isLoading();
        const editButton = {
          id: "editButton",
          onClick: this.handleEditButton,
          isPrimary: false,
          text: t("Edit Last Draft"),
          tooltip: options.isProjectArchived
            ? t("You cannot modify a requirement in the system that belongs to an archived project.")
            : options.isProcessArchived && parent.displayName !== "Document" ? t("You cannot modify a requirement in the system that belongs to an archived process.")
              : (
                options.editNotAllowedReason
                  ? options.editNotAllowedReason
                  : generateTooltip(CommonSecurity.Actions.EDIT, this.securityTypeName, parentValue)
              ),
          className: this.getClassForLoading(),
          disabled: options.isProjectArchived
            || (!options.isParentProject && options.isProcessArchived && parent.displayName !== "Document")
            || !options.canUserEdit
            || !canUserEdit
            || isLoading,
        };
        buttons.push(editButton);
      }
    } else {
      const saveButton = {
        id: "saveButton" + location,
        isPrimary: true,
        onClick: this.handleSaveButton,
        className: this.getClassForLoading(),
        disabled: !dataModified || this.isLoading() || this.isSaveDisabled(),
        text: t("Save Draft"),
      };
      const proposeButton = {
        id: "proposeButton" + location,
        isPrimary: false,
        onClick: this.handleProposeButton,
        className: this.getClassForLoading(),
        disabled: this.isLoading() || shouldDisablePropose,
        tooltip: shouldDisablePropose ? "Unit operations and Steps cannot be proposed in edit mode" : "",
        text: t("Propose"),
      };
      const cancelButton = {
        id: "cancelButton" + location,
        isPrimary: false,
        onClick: this.handleCancel,
        text: t("Cancel"),
      };

      buttons.push(saveButton, proposeButton, cancelButton);
    }
    return buttons;
  }

  getButtonsToDisplay(options) {
    const buttons = [
      ...this.getAdditionalButtons(options),
      ...this.getDefaultButtons(options),
    ].map((item, index) => {
      // We return a copy of the original button so if we have any caching we don't change
      // the originals.
      return {
        ...item,
        // If no order is specified, uses a factor based on the original array index
        // so, item with index 0 will be 100, item with index 1 will be 200, and so on.
        // This way, we can insert items in the middle of the default buttons if we need.
        order: item.order || ((index + 1) * 100),
      };
    });

    buttons.sort(CommonUtils.sortBy("order"));

    // Now, we will ensure just the first button that is marked as primary is actually
    // shown as primary.

    let primaryChosen = false;

    for (let button of buttons) {
      button.isPrimary = button.isPrimary && !primaryChosen;
      primaryChosen = primaryChosen || button.isPrimary;
    }

    if (!primaryChosen) {
      Logger.warn("No primary button found for approval container.");
    }

    return buttons;
  }

  getAdditionalButtons() {
    const {additionalButtons} = this.props;
    return additionalButtons || [];
  }

  getCurrentRenderOptions(props) {
    const {
      location,
      parent,
      versions,
      t,
    } = props;

    let parentProject = null;
    let parentProcess = null;
    if (this.isInProjectContext()) {
      const memoryCache = this.getInMemoryCache();
      const projects = memoryCache.get(this.getMemoryCacheKeyForTypeahead("Project"));
      if (!projects) {
        Logger.debug(() => `${this.getLogHeader()} getCurrentRenderOptions :: Project typeahead not already loaded, ${Log.object(UIUtils.deepClone(memoryCache))}`);
      }

      parentProject = projects?.find(project => project.id === this.getProjectId());
      if (!parentProject) {
        Logger.debug(() => this.getLogHeader() + "getCurrentRenderOptions :: parent project not found for projectId", this.getProjectId());
      }

      const processes = memoryCache.get(this.getMemoryCacheKeyForTypeahead("Process"));
      if (!processes) {
        Logger.debug(() => `${this.getLogHeader()} getCurrentRenderOptions :: process typeahead not already loaded ${Log.object(UIUtils.deepClone(memoryCache))}`);
      }

      parentProcess = processes?.find(process => process.id === this.getProcessId());
      if (!parentProcess) {
        Logger.debug(() => this.getLogHeader() + "getCurrentRenderOptions :: parent process not found for process", this.getProcessId());
      }
    }

    let result = {
      status: "",
      location,
      isInProjectContext: this.isInProjectContext(),
      canUserApprove: false,
      canUserPropose: false,
      canUserEdit: parent.isEditAllowed(),
      latestVersion: null,
      currentState: parent.getCurrentState(),
      editNotAllowedReason: "",
      isModifiable: false,
      canWithdraw: false,
      isArchived: parent.isArchived(),
      isApproved: parent.isApproved(),
      isDraft: false,
      isRestored: parent.isRestored(),
      isHistoryShowing: false,
      hasApprovedVersion: parent.hasApprovedVersion(),
      isView: parent.isView(),
      isAdd: parent.isAdd(),
      isUserOriginator: false,
      allUsersHaveResponded: false,
      isProjectArchived: (parentProject && !!parentProject.deletedAt),
      isProcessArchived: parentProject && parentProcess && !!parentProcess.deletedAt,
      isParentProject: parent.isProjectEditor(),
      isParentProcess: parent.isProcessEditor(),
      currentDiffingVersion: this.props.currentDiffingVersion,
      currentViewingVersionId: parent.getCurrentViewingVersionId(),
    };

    if (result.isView) {
      const isViewingLatest = !result.currentViewingVersionId || !!(versions && versions[0].id === result.currentViewingVersionId);
      const parentValue = parent.state;

      let currentState = result.isArchived ? CommonUtils.VERSION_STATES.ARCHIVED
        : result.isRestored ? CommonUtils.VERSION_STATES.RESTORED
          : result.isApproved ? CommonUtils.VERSION_STATES.APPROVED : CommonUtils.VERSION_STATES.DRAFT;

      if (!result.canUserEdit) {
        result.editNotAllowedReason = parent.getEditButtonDisabledTooltip();
      }

      if (result.currentDiffingVersion.majorVersion >= 0) {
        // The "v" is so the version looks like v0.1 on the screen.
        result.status += "v" + result.currentDiffingVersion.majorVersion + "." + result.currentDiffingVersion.minorVersion;
      } else if (result.currentViewingVersionId && versions && !isViewingLatest) {
        result.status += "v" + parentValue.majorVersion + "." + parentValue.minorVersion;
      } else {
        result.status += "Latest";
      }

      result.canUserApprove = false;
      result.canUserPropose = false;
      result.isUserOriginator = false;
      result.allUsersHaveResponded = false;

      let approvalRequest = null;
      let approvalResponses = null;
      let latestVersion;

      const editNotAllowedReasonLabel = t("This record is not in a state that can be edited.");
      if (versions) {
        latestVersion = versions[0];
        let versionTransitions = latestVersion.versionTransitions;
        let approvalRequestTransition = getLatestApprovalRequestVersionTransition(versionTransitions);
        approvalRequest = approvalRequestTransition.approvalRequest;
        approvalResponses = approvalRequestTransition.approvalResponses;

        // Can this version be approved?
        if (result.currentViewingVersionId === latestVersion.id && approvalRequest) {
          let myselfInApproversList = approvalRequest.approvalReqToUsers.filter(
            reqToUser => UIUtils.isCurrentUser(reqToUser?.user?.cognitoUUID));

          result.allUsersHaveResponded = approvalResponses && (approvalRequest.approvalReqToUsers.length === approvalResponses.length);
          result.canUserApprove = (myselfInApproversList.length === 1);
          result.isUserOriginator = UIUtils.isCurrentUser(approvalRequest.createdByUser?.cognitoUUID);
          currentState = CommonUtils.VERSION_STATES.PROPOSED;
        }

        if (isViewingLatest) {
          currentState = latestVersion.currentState;
        } else {
          let currentVersion = versions.find(version => version.id === result.currentViewingVersionId);
          if (currentVersion) {
            currentState = currentVersion.currentState;
          } else if (versions.length > 0) {
            currentState = versions[0].currentState;
          } else {
            currentState = "Draft";
          }
        }

        result.canUserPropose = (isViewingLatest && currentState === "Draft");
        if (result.canUserEdit) {
          if (!isViewingLatest) {
            result.canUserEdit = false;
            result.editNotAllowedReason = t("View the latest version to edit.");
          } else if (!EDITABLE_STATES.includes(currentState)) {
            result.canUserEdit = false;
            result.editNotAllowedReason = editNotAllowedReasonLabel;
          }
        }

        result.approvalRequest = approvalRequest;
        result.approvalRequestTransition = approvalRequestTransition;
        result.versionTransitions = versionTransitions;
        result.latestVersion = latestVersion;
        result.currentUserCognitoUUID = UIUtils.getCognitoUUID();
      } else {
        if (result.canUserEdit && !EDITABLE_STATES.includes(result.currentState)) {
          result.canUserEdit = false;
          result.editNotAllowedReason = editNotAllowedReasonLabel;
        }
      }

      result.latestVersion = latestVersion;
      result.status += " (" + currentState + ")";

      /*Do not display any of the Propose/Withdraw/Approve buttons when the current state of the selected version is "Approved" or "Obsolete" or "Withdrawn"
        Also, do not display any of the above buttons when the current state of the selected version is "Rejected" and all users have responded or the current user is the one who has proposed.
        or the parent project is archived*/
      result.isModifiable = !(
        currentState === CommonUtils.VERSION_STATES.APPROVED
        || currentState === CommonUtils.VERSION_STATES.ARCHIVED
        || currentState === CommonUtils.VERSION_STATES.ARCHIVED_CASCADED
        || currentState === CommonUtils.VERSION_STATES.RESTORED
        || currentState === CommonUtils.VERSION_STATES.RESTORED_CASCADED
        || currentState === CommonUtils.VERSION_STATES.OBSOLETE
        || currentState === CommonUtils.VERSION_STATES.WITHDRAWN
        || currentState === CommonUtils.VERSION_STATES.WITHDRAWN_CASCADED
        || currentState === CommonUtils.VERSION_STATES.REJECTED_CASCADED
        || (currentState === CommonUtils.VERSION_STATES.REJECTED && (result.allUsersHaveResponded || result.isUserOriginator))
      );

      result.canWithdraw = currentState && (currentState.startsWith("Proposed") || currentState === CommonUtils.VERSION_STATES.ROUTING) && result.isUserOriginator;
      result.isDraft = currentState === CommonUtils.VERSION_STATES.DRAFT;

      result.isHistoryShowing = parentValue.isHistoryShowing;
    } else {
      result.status = "Draft";
      result.isApproved = false;
      result.isDraft = true;
      result.isArchived = false;
    }

    if (result.currentDiffingVersion?.isLoadingVersionDiff) {
      result.status = "";
    }

    Logger.debug(() => `${this.getLogHeader()}getCurrentRenderOptions result ${Log.object({
      result,
      versions: UIUtils.deepClone(versions),
      project: UIUtils.deepClone(parentProject),
      process: UIUtils.deepClone(parentProcess)
    })}`);

    return result;
  }

  shouldShowHistoryToggle() {
    const {parent} = this.props;

    let isApproved = parent.isApproved();
    const editorType = parent.getEditorType();

    return !(editorType === EDITOR_TYPES.FULL_SCREEN && isApproved);
  }

  getDefaultPopups(options) {
    const {
      currentDiffingVersion,
    } = options;

    const {
      parent,
      versions,
      onCheckPermissions,
    } = this.props;

    const {
      warning,
      showApprovalRequestPopup,
      showApprovalResponsePopup,
      showWithdrawPopup,
    } = this.state;

    const result = [];

    const approvalContainer = this;

    if (showApprovalRequestPopup) {
      result.push(
        {
          key: "ApprovalRequest",
          Popup: ApprovalRequestPopup,
          props: {
            parent,
            modalRef: approvalRequestPopup => approvalContainer.approvalRequestPopup = approvalRequestPopup,
            onHideModal: approvalContainer.handleHideModal,
            onSendApprovalRequest: approvalContainer.handleSendApprovalRequest,
            onCancelApprovalRequest: approvalContainer.handleCancelApprovalRequest,
            setPopupOpened: parent.setPopupOpened,
            onCheckPermissions,
          }
        }
      );
    }

    if (showApprovalResponsePopup) {
      result.push(
        {
          key: "ApprovalResponse",
          Popup: ApprovalResponsePopup,
          props: {
            parent,
            modalRef: approvalResponsePopup => approvalContainer.approvalResponsePopup = approvalResponsePopup,
            onSendApproval: approvalContainer.handleSendApproval,
            onHideModal: approvalContainer.handleHideModal,
            currentDiffingVersion,
            warning,
            versions,
            getApprovalValue: parent.getApprovalValue,
            setPopupOpened: parent.setPopupOpened,
            onApprovalChangeValue: parent.handleApprovalChangeValue,
            onApprovalChangeValues: parent.handleApprovalChangeValues,
            onApprovalClearValue: parent.handleApprovalClearValue,
            baseTypeName: parent.baseTypeName,
            instance: parent.getInstance(),
          }
        },
      );
    }

    if (showWithdrawPopup) {
      result.push(
        {
          key: "WithdrawPopup",
          Popup: WithdrawPopup,
          props: {
            parent,
            modalRef: withdrawPopup => approvalContainer.withdrawPopup = withdrawPopup,
            onWithdraw: approvalContainer.handleSendWithdraw,
            onHideModal: approvalContainer.handleHideModal,
          }
        },
      );
    }

    return result;
  }

  /**
   * Renders the history button to be displayed in this approval container
   * (or an empty string if it should not be rendered).
   * @param options
   * @return {JSX.Element|string}
   */
  renderHistoryButton(options) {
    const {
      location,
      t,
    } = this.props;

    const showToggleHistory = this.shouldShowHistoryToggle();

    let {
      isHistoryShowing,
      isView,
    } = options;

    return (isView && location === "Top" && showToggleHistory)
      ? (<div className={`col-auto ${isHistoryShowing ? "col-xl-4" : ""} ml-auto approval-history-toggle-group`}>
        <button id="toggleHistoryButton"
                onClick={this.handleToggleHistory}
                className={"btn approval-toggle-history-button"}
                title={t("Toggle History And Related Items")}
        >
          <img src={toggleHistoryIcon}
               width={20} height={23}
               alt={t("Toggle the History And Related Items")}
               className="approval-toggle-history-button-icon"
          />
          <FontAwesomeIcon icon={isHistoryShowing ? faChevronDown : faChevronLeft} />
        </button>
      </div>)
      : "";
  }

  getAdditionalPopups() {
    return this.props.additionalPopups || [];
  }

  getPopupsToRender(options) {
    return [
      ...this.getAdditionalPopups(options),
      ...this.getDefaultPopups(options)
    ];
  }

  /**
   * Renders the popup windows to be displayed from this approval container
   * @return {JSX.Element}
   */
  renderPopups(options) {
    const popups = this.getPopupsToRender(options);
    return (
      <Fragment>
        {popups.map(({key, Popup, props}) => <Popup key={key} {...props} />)}
      </Fragment>
    );
  }

  renderActionButtons(options) {
    const {
      isView,
      location,
    } = options;

    const isToggleHistoryButtonVisible = this.shouldShowHistoryToggle();
    let buttons = this.getButtonsToDisplay(options);

    let buttonGroupClassName = `${
      isView
        ? `col-auto ${isToggleHistoryButtonVisible ? "col-xl" : "col-xl-10"}`
        : "col"
    } btn-group approval-btn-group approval-btn-group-${location.toLowerCase()}`;

    return (
      <div className={buttonGroupClassName}>
        {buttons.map(btn => {
          return (
            <button
              key={btn.id}
              id={btn.id}
              onClick={btn.onClick}
              className={"btn btn-lg " + (btn.isPrimary ? "btn-primary" : "btn-secondary") + (btn.className ? " " + btn.className : "")}
              title={btn.tooltip}
              disabled={btn.disabled}
            >
              {btn.text}
            </button>
          );
        })}
      </div>
    );
  }

  /**
   * Renders the React component
   * @return {JSX.Element}
   */
  render() {
    let {
      location,
      className,
    } = this.props;

    const options = this.state.renderOptions;
    let {
      currentState,
      status,
      isView,
    } = options;

    Logger.debug(() => `${this.getLogHeader()} render using renderOptions`, Log.object({...options}));

    let buttonGroup;

    let toggleHistoryButton = this.renderHistoryButton(options);
    buttonGroup = this.renderActionButtons(options);

    return (
      <div className={"row approval-container" +
        (" " + (className || "")) +
        (location === "Popup" ? " approval-container-popup" : "")}
      >
        {location === "Top" ? (
          <div
            className={"col-auto approval-status approval-status-"
              + ((options.isApproved
                || currentState === CommonUtils.VERSION_STATES.APPROVED
                || currentState === CommonUtils.VERSION_STATES.ARCHIVED
                || currentState === CommonUtils.VERSION_STATES.RESTORED) ? "approved" : "unapproved")}
          >
            {status}
          </div>
        ) : ""}
        {location === "Top" && !isView ? (
          <div className="col-sm-3 col-md-5 approval-legend">
            <div className="row">
              <div className="col-md-5">
                * required for Save
              </div>
              <div className="col-md-6">
                ** required for Propose
              </div>
            </div>
          </div>
        ) : ""}
        {buttonGroup}
        {toggleHistoryButton}
        {this.renderPopups(options)}
      </div>
    );
  }

  getLogHeader(props = this.props) {
    return `${props?.location ?? "UNKNOWN"} :: `;
  }
}

ApprovalContainer.defaultProps = {
  additionalButtons: [],
  additionalPopups: [],
};

export default I18NWrapper.wrap(ApprovalContainer, "editor");
// i18next-extract-mark-ns-stop editor
