"use strict";

import TableTitleBar from "../configurableTables/tables/widgets/topBar/titleBar/table_title_bar";
import { TitleArea } from "./widgets/topBar/titleBar/title_area";
import { PROCESS_BAR_PANELS } from "./process_explorer_constants";
import { TreeActionsArea } from "./widgets/topBar/titleBar/tree_actions_area";
import { AboutActionsArea } from "./widgets/topBar/titleBar/about_actions_area";
import { MoreActionsLink } from "./widgets/topBar/titleBar/more_actions_link";
import * as UIUtils from "../ui_utils";
import React from "react";

export const ProcessExplorerMoreActions = ({projectId}) => <>
  <MoreActionsLink id="processBarRiskMapLink" label={"Risk"} onClick={() => window.open(
    UIUtils.getSecuredURL(
      `/reports/riskMap.html?projectId=${projectId}&reportType=CompositeRiskMapReport`
    ), "_blank")}
  />
  <MoreActionsLink id="processBarFlowMapLink" label={"Flow"} onClick={() => window.open(
    UIUtils.getSecuredURL(
      `/reports/processMapReports.html?projectId=${projectId}&reportType=ProcessFlowMapReport`
    ), "_blank")}
  />
</>;

export const ProcessExplorerTitleBar = (
  {
    t,
    aboutPanelRecord,
    keyToFullRecordMap,
    loading,
    nonArchivedCount,
    onAddClick,
    onExport,
    onModelTypeChange,
    onUnitOperationChange,
    processId,
    processes,
    projectId,
    recordOperationsHandler,
    selectedModelType,
    selectedPanel,
    showArchived,
    viewsConfig
  }) => {
  return (
    <TableTitleBar t={t}
                   projectId={projectId}
                   table={"Process"}
                   selectedModelType={selectedModelType}
                   onAddClick={onAddClick}
                   onExport={onExport}
                   selectedPanel={selectedPanel}
                   viewsConfig={viewsConfig}
                   titleArea={
                     <TitleArea
                       selectedModelType={selectedModelType}
                       selectedPanel={selectedPanel}
                       processes={processes}
                       projectId={projectId}
                       processId={processId}
                       onModelTypeChange={onModelTypeChange}
                       onUnitOperationChange={onUnitOperationChange}
                       isLoading={loading}
                     />
                   }
                   actionsArea={
                     selectedPanel === PROCESS_BAR_PANELS.TREE
                       ? <TreeActionsArea t={t}
                                          projectId={projectId}
                                          showArchived={showArchived}
                                          recordCount={keyToFullRecordMap.size}
                                          nonArchivedCount={nonArchivedCount}
                                          onShowArchivedChange={recordOperationsHandler.handleShowArchived}
                                          loading={loading}
                       />
                       : selectedPanel === PROCESS_BAR_PANELS.ABOUT && aboutPanelRecord
                         ? <AboutActionsArea aboutPanelRecord={aboutPanelRecord} />
                         : null
                   }
                   moreActions={<ProcessExplorerMoreActions projectId={projectId} />}
    />
  );
};
