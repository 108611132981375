"use strict";

import { RequirementElementDataTransform } from "./requirement_element_data_transform";
import * as UIUtils from "../../../ui_utils";
import * as CommonEditablesFormatter from "../../../../server/common/editables/common_editables_formatter";

/**
 * Formats the material data appropriately to be fed into Stimulsoft reports.
 */
export class IndividualMaterialDataTransform extends RequirementElementDataTransform {

  get type() {
    return "individualMaterial";
  }

  shouldRun() {
    return true;
  }

  transform(input, options) {
    const result = super.transform(input, options);
    const data = result.instances;
    data.compendialStandard = UIUtils.secureString(
      CommonEditablesFormatter.formatMultiSelectValueForDisplay(data.compendialStandard)
    );
    if (data.lastVersion) {
      data.lastVersion.compendialStandard = UIUtils.secureString(
        CommonEditablesFormatter.formatMultiSelectValueForDisplay(data.lastVersion.compendialStandard)
      );
    }
    result.instances = data;
    return result;
  }
}
