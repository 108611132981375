var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { classNames } from '@progress/kendo-react-common';
import { GRID_ROW_INDEX_ATTRIBUTE } from '../constants';
/**
 * The component for each of the items in the `data` property which the Grid renders.
 */
var GridRow = /** @class */ (function (_super) {
    __extends(GridRow, _super);
    function GridRow() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @hidden
     */
    GridRow.prototype.render = function () {
        var _a;
        var className = classNames({
            'k-grouping-row': this.props.rowType === 'groupHeader',
            'k-group-footer': this.props.rowType === 'groupFooter',
            'k-master-row': this.props.rowType !== 'groupHeader' &&
                this.props.rowType !== 'groupFooter',
            'k-alt': this.props.rowType !== 'groupHeader' &&
                this.props.rowType !== 'groupFooter' &&
                this.props.isAltRow,
            'k-selected': this.props.isSelected,
            'k-grid-edit-row': this.props.isInEdit
        });
        var defaultRendering = (React.createElement("tr", __assign({ id: this.props.id, onClick: this.props.onClick, onDoubleClick: this.props.onDoubleClick, className: className, style: {
                height: this.props.rowHeight ? this.props.rowHeight + 'px' : '',
                visibility: this.props.isHidden ? 'hidden' : ''
            }, role: "row", "aria-rowindex": this.props.ariaRowIndex }, (_a = {}, _a[GRID_ROW_INDEX_ATTRIBUTE] = this.props.rowType === 'data' ? this.props.dataIndex : undefined, _a)), this.props.children));
        return this.props.render ?
            this.props.render.call(undefined, defaultRendering, this.props) :
            defaultRendering;
    };
    return GridRow;
}(React.Component));
export { GridRow };
