/**
 * @hidden
 */
export var focusFirstFocusableChild = function (target) {
    if (target) {
        if ((target instanceof HTMLInputElement || target.tabIndex !== -1) && target.focus) {
            target.focus();
            return;
        }
        var element = target.querySelector('input, [tabindex]:not([tabindex="-1"])');
        if (element && element.focus) {
            element.focus();
        }
    }
};
