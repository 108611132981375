"use strict";

import React from "react";
import { LoadingIndicator } from "../../generic/loading_indicator";

/**
 *
 * @enum {IPDFPageViewerElement}
 */
export const PDF_VIEWER_STRUCTURE = {
  DOCUMENT_ROOT: {
    name: "document",
    selector: ".document-preview-container",
  },
  DOCUMENT_PREVIEW_AREA: {
    name: "container",
    selector: ".document-preview-area",
    get parent() {
      return PDF_VIEWER_STRUCTURE.DOCUMENT_ROOT;
    },
  },
  PAGE_WRAPPER: {
    name: "pageWrapper",
    selector(page) {
      return `.page[data-page-number=${page.number}]`;
    },
    component: "div",
    props: {
      className: "page document-preview-page"
    },
    get parent() {
      return PDF_VIEWER_STRUCTURE.DOCUMENT_PREVIEW_AREA;
    }
  },
  LOADING_INDICATOR: {
    name: "loadingIndicator",
    selector: ".loading-indicator",
    component: LoadingIndicator,
    get parent() {
      return PDF_VIEWER_STRUCTURE.PAGE_WRAPPER;
    },
  },
  CANVAS_WRAPPER: {
    name: "canvasWrapper",
    selector: ".canvasWrapper",
    component: "div",
    props: {
      className: "canvasWrapper",
    },
    get parent() {
      return PDF_VIEWER_STRUCTURE.PAGE_WRAPPER;
    }
  },
  CANVAS: {
    name: "canvas",
    selector: "canvas",
    component: "canvas",
    props: {
      className: "document-preview-canvas"
    },
    get parent() {
      return PDF_VIEWER_STRUCTURE.CANVAS_WRAPPER;
    },
  },
  TEXT_LAYER: {
    name: "textWrapper",
    selector: ".textLayer",
    component: "div",
    props: {
      className: "textLayer"
    },
    get parent() {
      return PDF_VIEWER_STRUCTURE.PAGE_WRAPPER;
    },
  }
};