"use strict";

import * as UIUtils from "../../../../ui_utils";
import React from "react";
import DatePicker from "../../../../widgets/date_picker";
import TableEditorBase from "./table_editor_base";

/**
 *  This shows the date picker used in the review table.
 */
export default class TableDatePicker extends TableEditorBase {
  constructor(props) {
    super(props);

    this.state = {
      value: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  /**
   * The bulk add table doesn't render at all so I need a date picker
   * control that manages its own state and can put the changed date in
   * the textbox then inform the parent with the change thus first setting
   * the state then calling the onDateChange to notify the parent to check
   * the textbox value and update its records.
   */
  handleChange(value) {
    if (value && value instanceof Date) {
      this.setStateSafely({date: value}, () => {
        this.props.onDateChage();
      });
    }
  }

  renderInput() {

    return (
      <DatePicker id={this.props.id}
                  className="form-control document-bulk-add-validation-editor-input"
                  selected={UIUtils.convertMomentToDate(this.state.date)}
                  onChange={this.handleChange}
      />
    );
  }
}
