"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import { createHTMLForLinksDiff } from "../../helpers/diff_helper";
import { Typeahead } from "react-bootstrap-typeahead";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import BaseCustomErrorAttribute from "./base_custom_error_attribute";
import TypeaheadObjectCacheFactory from "../../utils/cache/typeahead_object_cache_factory";
import MemoryCache from "../../utils/cache/memory_cache";
import { measurePerformanceEnd, measurePerformanceStart } from "../../ui_utils";

/**
 * This class can be used to view/edit many to many linked objects.
 *
 * Set the name to the model name of the linked object.
 */
export default class LinksAttribute extends BaseCustomErrorAttribute {
  constructor(props) {
    super(props);
    this.state.index = -1;
    this.pluralName = UIUtils.pluralize(this.props.name);
    this.filter = this.props.filter;

    // this is needed when taking the current value from parent
    // the parent loads slower than the typeahead
    if (this.props.parent && Object.prototype.hasOwnProperty.call(this.props.parent, "addOnDataReceivedListener")) {
      this.props.parent.addOnDataReceivedListener(() => {
        this.loadTypeaheadOptions();
      });
    }
  }

  componentDidMount() {
    super.componentDidMount();

    if (!this.state.isTypeaheadLoaded) {
      this.loadTypeaheadOptions();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
    if (!shouldUpdate) {
      shouldUpdate = nextProps.rows !== this.props.rows ||
        nextProps.projectId !== this.props.projectId ||
        nextProps.processId !== this.props.processId ||
        JSON.stringify(nextProps.relatedRecord) !== JSON.stringify(this.props.relatedRecord) ||
        nextState.isTypeaheadLoaded !== this.state.isTypeaheadLoaded;
    }

    return shouldUpdate;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.projectId !== this.props.projectId ||
      prevProps.processId !== this.props.processId) {
      this.loadTypeaheadOptions();
    }

    super.componentDidUpdate(prevProps);
  }

  loadTypeaheadOptions() {
    this.setStateSafely(({
      isTypeaheadLoaded: false,
      typeaheadOptions: null
    }), () => this.loadTypeaheadOptionsFromServer());
  }

  loadTypeaheadOptionsFromServer() {
    const memoryCache = this.getMemoryCache();

    let typeaheadOptions = memoryCache.get(this.getMemoryCacheKeyForTypeahead(this.getTypeCode()));
    if (!typeaheadOptions) {
      const interactionName = `LinksAttribute :: ${this.getMemoryCacheKeyForTypeahead()} :: Load options from cache/sessionStorage for Model: ${this.props.name}. ProjectId: ${this.getProjectId()}. ProcessId: ${this.getProcessId()}`;
      measurePerformanceStart(interactionName);

      const typeaheadObjectCache = TypeaheadObjectCacheFactory.createTypeaheadObjectCacheIfPossible(
        this.props.name, this.getProjectId, this.getProcessId);
      if (typeaheadObjectCache) {
        typeaheadObjectCache.loadOptions(() => {
          const typeaheadOptions = this.isDiffOrView() ? typeaheadObjectCache.getOptionsFromCacheIncludingArchived() : typeaheadObjectCache.getOptionsFromCache();
          this.setStateSafely({
            isTypeaheadLoaded: true,
            typeaheadOptions
          });

          this.displayDebugInformation(interactionName);
        });
      }
    } else {
      this.setStateSafely({
        isTypeaheadLoaded: true,
        typeaheadOptions
      });
    }
  }

  displayDebugInformation(interactionName) {
    measurePerformanceEnd(interactionName);
  }

  getTypeCode() {
    const modelName = this.props.name;
    return UIUtils.getTypeCodeForModelName(modelName);
  }

  // noinspection JSMethodCanBeStatic
  getInitialValue() {
    return [];
  }

  getAttributeName() {
    return this.pluralName;
  }

  getValue() {
    let value = this.props.parent.state[this.pluralName];
    return value ? value : this.getInitialValue();
  }

  handleChange(event) {
    this.props.parent.handleChangeValue(this.pluralName, event);
    this.clearValidationErrors();
  }

  getInputId() {
    return this.props.name;
  }

  isValueSet() {
    return !!this.getValue();
  }

  isLoading() {
    return !this.state.isTypeaheadLoaded || this.props.isLoading;
  }

  getMemoryCacheKeyForTypeahead(typeCode) {
    return `typeahead_${typeCode}_${this.getProjectId()}_${this.getProcessId()}_${this.isDiffOrView() ? "diff" : ""}`;
  }

  getMemoryCache() {
    return MemoryCache.getNamedInstance(`links_attribute_${this.getProjectId()}_${this.getProcessId()}_${this.isDiffOrView() ? "diffOrView" : ""}`);
  }

  getProjectId() {
    if (this.props.projectId) {
      return this.props.projectId;
    }

    const parent = this.props.parent;
    if (!parent) {
      return;
    }

    if (!Object.prototype.hasOwnProperty.call(parent, "getProjectId")) {
      return;
    }

    parent.getProjectId();
  }

  getProcessId() {
    if (this.props.processId) {
      return this.props.processId;
    }

    const parent = this.props.parent;
    if (!parent) {
      return;
    }

    if (!Object.prototype.hasOwnProperty.call(parent, "getProcessId")) {
      return;
    }

    parent.getProjectId();
  }

  isDiffOrView() {
    return false;
  }

  renderInput() {
    let input;
    let {filter} = this.props;
    let inputId = this.getInputId();

    if (!this.state.isTypeaheadLoaded) {
      return <Fragment />;
    }

    let typeaheadOptions = this.state.typeaheadOptions;
    let value = this.getValue() ? this.getValue() : [];
    if (!this.isView() && typeaheadOptions) {
      value = typeaheadOptions.filter(option => {
        return value.find(selectedOption => option?.id === selectedOption?.id);
      });
    }

    // apply options filter function if provided...
    typeaheadOptions = filter ? typeaheadOptions.filter(filter) : typeaheadOptions;

    // Uncomment for verbose logging
    // console.log("Render: Generating " + inputId + " with links: " + UIUtils.stringify(links));
    if (this.isView()) {
      let typeCode = UIUtils.getTypeCodeForModelName(this.props.name);
      if (this.isDiffingVersions()) {
        if (this.props.readOnly) {
          input = null;
        } else {
          let oldLinks = this.getOldValue(this.pluralName);
          oldLinks = (oldLinks ? oldLinks : []);
          input = createHTMLForLinksDiff(inputId, oldLinks, value, typeCode);
        }
      } else {
        input = value.map(
          link => (
            <div key={link.id}
                 id={inputId + link.id + "Div"}
                 className="links-view-container"
            >
              <a href={getURLByTypeCodeAndId(typeCode, "View", link.id)}>
                {link.label}
              </a>
            </div>
          )
        );
      }
    } else if (!this.props.readOnly) {
      input = (
        <Typeahead multiple
                   options={typeaheadOptions}
                   id={inputId}
                   inputProps={{id: inputId + "Input", autoComplete: "off"}}
                   onChange={this.handleChange}
                   selected={value}
                   disabled={this.isDisabled()}
                   ref={typeahead => this.typeahead = typeahead}
        />
      );
    } else {
      input = null;
    }

    return input;
  }
}
