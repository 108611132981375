"use strict";

import * as UIUtils from "../../../../../ui_utils";
import { EMPTY_UNIT_OPERATION } from "./specification_transform_strategy";
import { orderAndIndexUnits } from "../../../../../processExplorer/indexers/uo_indexer";
import { VariableSpecificationTransformStrategy } from "./variable_specification_transform_strategy";

export class RawMaterialSpecificationTransformStrategy extends VariableSpecificationTransformStrategy {
  constructor() {
    super("materialId", "materials");
  }

  fillGeneralInformation(record, requirement) {
    super.fillGeneralInformation(record, requirement);

    if (requirement.modelType === "MT") {
      record.id = Number(requirement.MaterialId);
      record.unitOperationId = requirement.UnitOperations && requirement.UnitOperations.length > 0 ?
        requirement.UnitOperations.map(uo => uo.id) : -1;
      record.name = UIUtils.secureString(requirement.name);
    }
  }

  fillParentInformation(record, requirement) {
    if (requirement.Material) {
      record.materialId = Number(requirement.Material.id);
      record.material = UIUtils.secureString(requirement.Material.name);
    }
  }

  /**
   * This function make data ready in Stimulsoft structure where all data is put
   * in a list of attributes with UOs, MTs and MAs/PPs. Also this takes care of MTs shared
   * between multiple UOs.
   */
  wrapUp(result) {
    const instances = result.instances;

    if (instances) {
      const attributes = [];
      const knownUOs = new Set();
      const unitOperations = [];
      const variablesToProcess = this.mergeEntities(instances);
      let flattenedMaterials = this.flattenParents(instances.materials);

      for (let material of flattenedMaterials) {
        const context = {parent: material, knownUOs, instances, unitOperations};
        const variables = variablesToProcess
          .filter(attr => {
            return attr.materialId === material.id &&
              attr.unitOperationId === material.unitOperationId;
          });

        if (variables.length === 0) {
          // creates empty attribute to force the display of the material in the report
          const attribute = this.createAttribute();
          attribute.materialId = material.id;
          attribute.material = material.name;
          attribute.unitOperationId = material.unitOperationId;
          this.setUnitOperation(attribute, context);
          attributes.push(attribute);
        } else {
          for (const attribute of variables) {
            if (material && material.unitOperationId) {
              this.setUnitOperation(attribute, context);
              attributes.push(attribute);
            }
          }
        }
      }

      attributes.sort(this.sortAttributes);

      instances.uos = orderAndIndexUnits(instances.uos, "id", false);
      instances.uos.push(EMPTY_UNIT_OPERATION);

      instances.uos = instances.uos.filter(uo => knownUOs.has(uo.id));
      instances.uos = instances.uos.map(uo => {
        uo.typeCode = uo.modelType;
        return {
          ...uo,
          label: uo.id === -1 ? uo.name : UIUtils.getRecordCustomLabelForDisplay(uo),
        };
      });

      instances.attributes = attributes;

      // ensures an empty summary will appear
      instances.isEmptySummary = (
        (!instances.attributes || instances.attributes.length === 0)
        || (!instances.uos || instances.uos.length === 0)
      );
    } else {
      result.instances = {
        isEmptySummary: true,
      };
    }

    return result;
  }
}
