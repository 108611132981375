"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseAttribute from "./base_attribute";
import { createHTMLForTextDiff } from "../../helpers/diff_helper";
import DatePicker from "../../widgets/date_picker";
import moment from "moment";
import { isPropertyDefined } from "../../../server/common/generic/common_utils";

export const SET_EXPLICITLY = "SetExplicitly";
export default class DateAttribute extends BaseAttribute {
  constructor(props) {
    super(props);

    this.isDataErrorSet = false;
  }

  // noinspection JSMethodCanBeStatic
  getInitialValue() {
    return "";
  }

  handleChange(event) {
    // Event is a JS Date
    let dateString = event ? moment(event).format(UIUtils.DATE_FORMAT_FOR_STORAGE) : event;
    this.validateDate(dateString);
    this.props.parent.handleChangeValue(this.props.name, dateString);
    this.props.parent.handleChangeValue(this.props.name + SET_EXPLICITLY, !!dateString);
  }

  handleChangeRaw(event) {
    if (event) {
      this.validateDate(event.target.value);
    }
  }

  validateDate(date) {
    const inputId = this.getInputId();
    const inputDate = $(`#${inputId}`);

    if (!inputDate ||
        !inputDate[0] ||
        !isPropertyDefined(inputDate[0], "setCustomValidity")) {
      return;
    }

    if (this.props.validateDate && typeof this.props.validateDate === "function") {
      const error = this.props.validateDate(date, this.isRequired());
      if (error) {
        inputDate.attr("data-error", error);
        inputDate[0].setCustomValidity(error);
      } else {
        inputDate.attr("data-error", "");
        inputDate[0].setCustomValidity("");
      }
      inputDate.trigger("input");
    }
  }

  setDate(someDate) {
    this.props.parent.handleChangeValue(this.props.name, someDate ? someDate.format(UIUtils.DATE_FORMAT_FOR_STORAGE) : "");
  }

  //We are setting the error explicitly for the date time picker control so that it matches the one
  //all controls inheriting from the BaseAttribute component. react-datepicker does not propagate data-error to the input control.
  componentDidMount() {
    super.componentDidMount();
    this.attemptToSetDataErrorMessage();
  }

  attemptToSetDataErrorMessage() {
    if (!this.isDataErrorSet) {
      let inputId = this.getInputId();
      const input = $("#" + inputId);
      if (input.length > 0) {
        let dataErrorMsg = this.getCapitalizedName() + " is required.";
        input.attr("data-error", dataErrorMsg);
        this.isDataErrorSet = true;
      }
    }
  }

  componentDidUpdate() {
    if (this.limitsChanged) {
      this.validateDate(this.getValue() ? this.getValue() : "");
    }

    this.attemptToSetDataErrorMessage();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      const {minDate, maxDate} = this.props;
      const nextMinDate = nextProps.minDate;
      const nextMaxDate = nextProps.maxDate;

      let value = this.getNextValue(nextProps);
      this.currentValue = this.currentValue ? moment(this.currentValue).format(UIUtils.DATE_FORMAT_FOR_STORAGE) : "";
      this.limitsChanged = this.compareLimits(minDate, nextMinDate) || this.compareLimits(maxDate, nextMaxDate);
      const disabledChanged = this.props.disabled !== nextProps.disabled;
      return this.currentValue !== value || this.limitsChanged || disabledChanged;
    }
    return true;
  }

  /**
   * Returns true if the old and new limits are different, false otherwise
   * @param oldLimit The old limit. This is a moment object
   * @param newLimit The new limit. This is a moment object
   * @returns {*|boolean}
   */
  compareLimits(oldLimit, newLimit) {
    return !!(!oldLimit && newLimit
      || oldLimit && !newLimit
      || oldLimit && newLimit && !oldLimit.isSame(newLimit));
  }

  renderInput() {
    let input;
    let inputId = this.getInputId();
    this.currentValue = this.getValue() ? this.getValue() : "";
    // Uncomment for verbose logging
    // console.log("Render: Generating " + this.getInputId() + " with this.currentValue: " + this.currentValue);
    if (this.isView()) {
      this.currentValue = this.currentValue ? moment(this.currentValue, UIUtils.DATE_FORMAT_FOR_STORAGE).format(UIUtils.DATE_FORMAT_FOR_DISPLAY) : null;
      if (this.isDiffingVersions()) {
        let oldValue = this.getOldValue(this.props.name) ?
          moment(this.getOldValue(this.props.name), UIUtils.DATE_FORMAT_FOR_STORAGE).format(UIUtils.DATE_FORMAT_FOR_DISPLAY) : null;
        // Uncomment for verbose logging
        // console.log("Diffing " + oldValue + " against " + this.currentValue);

        input = createHTMLForTextDiff(oldValue, this.currentValue);
      } else {
        this.currentValue = this.currentValue ? this.currentValue : "";
        input = (<span id={inputId}>{this.currentValue}</span>);
      }
    } else {
      this.currentValue = this.currentValue ? moment(this.currentValue, UIUtils.DATE_FORMAT_FOR_STORAGE).toDate() : null;
      input = (
        <DatePicker id={inputId}
                    required={this.isRequired()}
                    selected={UIUtils.convertMomentToDate(this.currentValue)}
                    dateFormat={UIUtils.DATE_FORMAT_FOR_DISPLAY_DATEPICKER}
                    disabled={this.isDisabled()}
                    minDate={UIUtils.convertMomentToDate(this.props.minDate)}
                    maxDate={UIUtils.convertMomentToDate(this.props.maxDate)}
                    ref={datePicker => this.datePicker = datePicker}
                    onChange={this.handleChange}
                    onChangeRaw={this.handleChangeRaw}
        />
      );
    }

    return input;
  }
}
