"use strict";

/**
 * @typedef ProcessExplorerResults
 * @property {Object<number, Object>} uoMap  A map of Unit Operation (UO) Ids to UOs.
 * @property {Object<number, Object>} iqaMap  A map of Intermediate Quality Attribute (IQA) Ids to IQAs.
 * @property {Object<number, Object>} ipaMap  A map of Performance Quality Attribute (IPA) Ids to IQAs.
 * @property {Object<number, Object>} maMap  A map of Material Attribute (MA) Ids to MAs.
 * @property {Object<number, Object>} ppMap  A map of Process Parameter (PP) Ids to PPs.
 * @property {Object<number, Object>} mtMap  A map of Material (MT) Ids to MTs.
 * @property {Object<number, Object>} prcMap  A map of Process Component (PRC) Ids to PRCs.
 * @property {Object<number, Object>} stpMap  A map of Step (STP) Ids to STPs.
 */

import { TYPE_CODE } from "../process_explorer_constants";

/**
 * This class adapts/transforms the process explorer results into various maps of unit operations (UOs) to types.
 */
export class UOToRecordResultsAdapter {

  /**
   * @param processExplorerResults {ProcessExplorerResults} The results from the process explorer handler
   */
  constructor(processExplorerResults) {
    this.results = processExplorerResults;

    // Precalculate maps of unit operations to various types
    this.uoToMAMap = this.createUOToRecordMap(processExplorerResults.maMap);
    this.uoToPPMap = this.createUOToRecordMap(processExplorerResults.ppMap);
    this.uoToMTMap = this.createUOToRecordMap(processExplorerResults.mtMap);
    this.uoToPRCMap = this.createUOToRecordMap(processExplorerResults.prcMap);
    this.uoToIQAMap = this.createUOToRecordMap(processExplorerResults.iqaMap);
    this.uoToIPAMap = this.createUOToRecordMap(processExplorerResults.ipaMap);
    this.uoToSTPMap = this.createUOToRecordMap(processExplorerResults.stpMap);

    // Precalculate maps of steps to various types
    this.stpToMAMap = this.createStepToRecordMap(processExplorerResults.maMap);
    this.stpToPPMap = this.createStepToRecordMap(processExplorerResults.ppMap);
    this.stpToMTMap = this.createStepToRecordMap(processExplorerResults.mtMap);
    this.stpToMTMap = this.createStepToAssetMap(processExplorerResults.mtMap, processExplorerResults.maMap, processExplorerResults.ppMap, "MaterialId");
    this.stpToPRCMap = this.createStepToAssetMap(processExplorerResults.prcMap, processExplorerResults.maMap, processExplorerResults.ppMap, "ProcessComponentId");
    this.stpToIQAMap = this.createStepToRecordMap(processExplorerResults.iqaMap);
    this.stpToIPAMap = this.createStepToRecordMap(processExplorerResults.ipaMap);
  }

  createUOToRecordMap(recordMap) {
    const uoToIdToRecordMap = new Map();
    for (const record of Object.values(recordMap)) {
      // Get the UOs

      let unitOperations;
      if (record.UnitOperation && !record.Step && (!record.Steps || record.Steps.length === 0)) {
        unitOperations = [record.UnitOperation];
      } else if (record.UnitOperations && !record.Step) {
        if (record.Steps && record.Steps.length > 0) {
          const results = this.results;
          // Remove the UOs where this record is in a step in that UO.
          const uosToRemoveSet = new Set(record.Steps.map(step => {
            const stepFromResult = results.stpMap[step.id];
            if (!stepFromResult) {
              console.warn(`Step ${step.id} is not defined`, stepFromResult, results.stpMap, record.Steps);
            }
            return stepFromResult?.UnitOperation?.id;
          }).filter(id => !!id));
          unitOperations = record.UnitOperations.filter(uo => !uosToRemoveSet.has(uo.id));
        } else {
          unitOperations = record.UnitOperations;
        }
      }

      // Catch the steps and assign them to a UnitOperation
      if (TYPE_CODE.STEP === record.typeCode && record.UnitOperation) {
        unitOperations = [record.UnitOperation];
      }

      if (!unitOperations || unitOperations.length === 0) {
        this.addToUOToIDToRecordMap(uoToIdToRecordMap, null, record);
      } else {
        for (const unitOperation of unitOperations) {
          this.addToUOToIDToRecordMap(uoToIdToRecordMap, unitOperation.id, record);
        }
      }
    }
    return uoToIdToRecordMap;
  }

  createStepToRecordMap(recordMap) {
    const stepToIdToRecordMap = new Map();
    for (const record of Object.values(recordMap)) {

      // Get the Steps
      const steps = (record.Step ? [record.Step] : record.Steps) || [];
      for (const step of steps) {
        this.addToTypeToIDToRecordMap(stepToIdToRecordMap, step.id, record);
      }
    }
    return stepToIdToRecordMap;
  }

  createStepToAssetMap(assetMap, maMap, ppMap, assetParentId) {
    const mas = Object.values(maMap) || [];
    const pps = Object.values(ppMap) || [];
    const allAttributes = mas.concat(pps);
    const stepToIdToAssetMap = new Map();

    for (let asset of Object.values(assetMap)) {

      // Add the asset anywhere they exist
      let steps = asset.Step ? [asset.Step] : (asset.Steps || []);
      if (!steps || steps.length === 0) {
        this.addToTypeToIDToRecordMap(stepToIdToAssetMap, null, asset);
      } else {
        for (const step of steps) {
          this.addToTypeToIDToRecordMap(stepToIdToAssetMap, step.id, asset);
        }
      }

      // Check the MAs && PPs, if we find one with an asset referenced but not directly assigned to the step, mark it as ghosted.
      const stepIdSet = new Set(steps.map(step => step.id));
      steps = allAttributes.filter(att => att[assetParentId] === asset.id && att.Step && !stepIdSet.has(att.Step.id)).map(att => att.Step);
      if (steps && steps.length > 0) {
        asset = {...asset, isGhosted: true};
        for (const step of steps) {
          this.addToTypeToIDToRecordMap(stepToIdToAssetMap, step.id, asset);
        }
      }
    }
    return stepToIdToAssetMap;
  }

  addToUOToIDToRecordMap(uoToIdToRecordMap, unitOperationId, record) {
    if (!uoToIdToRecordMap.has(unitOperationId)) {
      uoToIdToRecordMap.set(unitOperationId, new Map());
    }
    const idToRecordMap = uoToIdToRecordMap.get(unitOperationId);
    idToRecordMap.set(record.id, record);
  }

  createTypeToRecordMap(type, recordMap) {
    const typeToIdToRecordMap = new Map();
    for (const record of Object.values(recordMap)) {
      // Get the Types
      const types = record[type] ? [record[type]] : record[type + "s"];

      if (!types || types.length === 0) {
        this.addToTypeToIDToRecordMap(typeToIdToRecordMap, null, record);
      } else {
        for (const type of types) {
          this.addToTypeToIDToRecordMap(typeToIdToRecordMap, type.id, record);
        }
      }
    }
    return typeToIdToRecordMap;
  }

  addToTypeToIDToRecordMap(typeToIdToRecordMap, typeId, record) {
    if (!typeToIdToRecordMap.has(typeId)) {
      typeToIdToRecordMap.set(typeId, new Map());
    }
    const idToRecordMap = typeToIdToRecordMap.get(typeId);
    idToRecordMap.set(record.id, record);
  }

}
