"use strict";

import React, { Fragment } from "react";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import { CONTROL_METHODS_CONFIG } from "./tables/control_method_tables_config";
import * as UIUtils from "../ui_utils";
import * as styles from "../configurableTables/tables/styles/configurable_table.module.scss";
import ConfigurableTablesColumnOperationsAdapter
  from "../configurableTables/columnOperations/configurable_tables_column_operations_adapter";
import ControlMethodColumnOperationHandler from "./tables/control_method_column_operation_handler";
import ConfigurableTablesColumnSelectionHandler
  from "../configurableTables/columnSelection/configurable_tables_column_selection_handler";
import ConfigurableTablesExportColumnGenerator
  from "../configurableTables/export/configurable_tables_export_column_generator";
import ConfigurableTablePage from "../configurableTables/tables/configurable_table_page";
import { TableToolBar } from "../configurableTables/tables/widgets/topBar/toolBar/table_tool_bar";
import { ShowArchiveToggle } from "../configurableTables/widgets/show_archive_toggle";
import { TableStateProvider } from "../configurableTables/tables/table_state_provider";
import ErrorBar from "../widgets/bars/error_bar";
import { TableSettingsProvider } from "../configurableTables/tables/table_settings_provider";

class ControlMethodsTablePage extends ConfigurableTablePage {
  constructor(props) {
    super(props);

    this.resetState(props);

    this.columnOperationsAdapter = new ConfigurableTablesColumnOperationsAdapter();
    this.columnOperationsHandler = new ControlMethodColumnOperationHandler({
      parent: this,
      columnOperationsAdapter: this.columnOperationsAdapter,
    });

    this.columnSelectionHandler = new ConfigurableTablesColumnSelectionHandler({
      parent: this,
      modelsConfig: CONTROL_METHODS_CONFIG,
      columnOperationsAdapter: this.columnOperationsAdapter,
      exportColumnGenerator: ConfigurableTablesExportColumnGenerator,
    }, false, "defaultValue");

    props.onUpdateTableDependencies({
      columnSelectionHandler: this.columnSelectionHandler,
      columnOperationsHandler: this.columnOperationsHandler,
      columnOperationsAdapter: this.columnOperationsAdapter,
    });
  }

  componentDidMount() {
    super.componentDidMount();

    this.loadTableData();
  }

  filterAdaptedRows(rows) {
    const {showArchived} = this.props;
    return rows.filter(controlMethod => showArchived || controlMethod.deletedAt === null);
  }

  handleReceivedDataFromServer(records) {
    const {onVisibleColumnsChange} = this.props;
    const adaptedRecords = this.getAdaptedInfo(records);

    UIUtils.setHideLoadingOnAjaxStop(true);

    this.setStateSafely({
      records,
      adaptedRecords,
      filteredRows: this.filterAdaptedRows(adaptedRecords),
    }, () => {
      this.columnSelectionHandler.handleResetColumnVisibility(onVisibleColumnsChange);
      UIUtils.hideLoadingImage();
    });
  }

  loadTableData() {
    UIUtils.setLoadingDisabled(false);
    UIUtils.setHideLoadingOnAjaxStop(false);

    const {projectId} = this.props;
    this.columnOperationsHandler
      .handleDefaultColumnOperationsFromURL((state) => this.setStateSafely({...state}));
    UIUtils.secureAjaxGET(`configurableTables/${projectId}?source=ControlMethod&latestVersion=true`)
      .done(this.handleReceivedDataFromServer);
  }

  getAdaptedInfo(records) {
    const {selectedModelType, onTableDataUpdated} = this.props;
    const resultAdapter = CONTROL_METHODS_CONFIG["Control Methods"]
      .adapter(
        {
          results: UIUtils.deepClone(records),
          selectedModelType,
        }
      );

    const adaptedData = resultAdapter.getRows();
    onTableDataUpdated(adaptedData);
    return adaptedData;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      showArchived,
      onTableStateChange,
      selectedModelType,
      onVisibleColumnsChange
    } = this.props;
    const {
      records,
      tableState
    } = this.state;
    const oldShowArchived = prevProps.showArchived;

    if (records
      && ((JSON.stringify(records) !== JSON.stringify(prevState.records)) || (oldShowArchived !== showArchived))) {
      const adaptedRecords = this.getAdaptedInfo(records);
      this.setStateSafely(
        {
          adaptedRecords,
          selectedModelType,
          filteredRows: this.filterAdaptedRows(adaptedRecords),
          securityTypeName: this.getSecurityTypeName(selectedModelType),
        }, () =>
          this.columnSelectionHandler.handleResetColumnVisibility(onVisibleColumnsChange));
    }

    if (JSON.stringify(tableState) !== JSON.stringify(prevState.tableState)) {
      onTableStateChange(tableState);
    }
  }

  render() {

    const {
      t,
      selectedModelType,
      handleShowArchive,
      onVisibleColumnsChange
    } = this.props;

    const {
      filteredRows,
      tableState,
      visibleTableColumns,
    } = this.state;

    const {
      handleColumnOperationsChange,
    } = this.columnOperationsHandler;

    const {customFilters} = tableState || {};

    const config = CONTROL_METHODS_CONFIG[selectedModelType];
    const {fieldsConfig, customFiltersHandler, modelName, table} = config || {};
    const sort = this.columnOperationsAdapter.getDefaultSorting(tableState);
    const controlMethodTable = config ?
      table({
        id: "controlMethodsTable",
        records: filteredRows || [],
        selectedModelType,
        modelName,
        fieldsConfig,
        tableState: {
          ...tableState,
          sort,
        },
        t,
        customFilters,
        customFiltersHandler,
        onEmptyTableAddClicked: this.handleEmptyTableAddButton,
        onImportButtonClick: this.handleImport,
        handleArchiveButton: this.handleArchiveButton,
        handleRestoreButton: this.handleRestoreButton,
        visibleTableColumns: visibleTableColumns || [],
        columnOperationsAdapter: this.columnOperationsAdapter,
        onColumnOperationsChange: handleColumnOperationsChange
      }) : "";

    return (
      <TableStateProvider>
        <Fragment>
          <TableToolBar modelName={modelName}
                        selectedModelType={selectedModelType}
                        showAssessmentOptions={false}
                        visibleTableColumns={visibleTableColumns}
                        tableState={tableState}
                        operationsAdapter={this.columnOperationsAdapter}
                        operationsHandler={this.columnOperationsHandler}
                        onResetToDefaults={this.columnSelectionHandler?.handleResetToDefaults}
                        onVisibleTableColumnsChanged={(map) =>
                          this.columnSelectionHandler?.handleVisibleTableColumnsChanged(map, onVisibleColumnsChange)}
                        customFilters={customFilters}
                        customFiltersHandler={customFiltersHandler}
                        records={filteredRows}
                        quickFiltersZone={
                          <ShowArchiveToggle t={t}
                                             type={selectedModelType}
                                             onShowArchiveChange={handleShowArchive}
                          />
                        }
                        afterBulkEdit={this.handleBulkEdit}
          />
          <div className={styles["configurable-table"]}>
            <div id="controlMethodContainer" className={styles["configurable-table-inner-container"]}>
              <ErrorBar className={`mt-2 ${styles["error-bar"]}`} />
              <TableSettingsProvider visibleTableColumns={visibleTableColumns}>
                {controlMethodTable}
              </TableSettingsProvider>
            </div>
          </div>
          {this.renderArchivePopup()}
        </Fragment>
      </TableStateProvider>
    );
  }
}

export default I18NWrapper.wrap(ControlMethodsTablePage, "controlMethod");


