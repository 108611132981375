"use strict";

/**
 * This file is responsible for handling import related URLs.
 */

import * as UIUtils from "../../ui_utils";

/**
 * This creates a link into the first import wizard step for all possible import flows.
 * @param config The configuration for a particular import flow as defined in the import constants.
 * @returns {string}
 */
module.exports.createImportLinkURL = function(config) {
  const {
    key,
    projectId,
    processId,
    projectType,
    batchId,
    allowProcessSelection,
    selectedTab,
    selectedDependencyRecordId
  } = config;

  let url = `/import/importFile.html?key=${key}`;
  url += projectId ? `&projectId=${projectId}` : "";
  url += processId ? `&processId=${processId}` : "";
  url += projectType ? `&projectType=${projectType}` : "";
  url += batchId ? `&batchId=${batchId}` : "";
  url += allowProcessSelection ? `&allowProcessSelection=true` : "";
  url += selectedTab ? `&selectedTab=${selectedTab}` : "";
  url += selectedDependencyRecordId ? `&selectedDependencyRecordId=${selectedDependencyRecordId}` : "";

  return UIUtils.getSecuredURL(url);
};