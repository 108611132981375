"use strict";

import * as UIUtils from "../../ui_utils";
import ReactDOMServer from "react-dom/server";
import React from "react";
import ImplementationNeededError from "../../utils/implementation_needed_error";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import MultipleTypeaheadObjectCache from "../../utils/cache/multiple_typeahead_object_cache";
import BaseTechTransferAttribute from "./base_tech_transfer_attribute";
import MemoryCache from "../../utils/cache/memory_cache";

/**
 * This contains common methods needed by Process Components and Materials.
 *
 *  @abstract
 */
// i18next-extract-mark-ns-start process_explorer
export default class BaseAttributeParent extends BaseTechTransferAttribute {
  constructor(props, baseTypeName, capitalizedBaseTypeName, displayName) {
    super(props, baseTypeName, capitalizedBaseTypeName, displayName);

    this.setStateSafely({
      canAddMoreThanOneUO: true,
      childAttributes: [],
      originalUOs: this.state.UnitOperations || [],
      originalSTPs: this.state.Steps || [],
      loadedTypeaheadCodes: [],
    });

    this.typeaheadsToLoad = ["Supplier", ...this.getAttributeNames()];

    if (this.getProjectId()) {
      this.loadTypeaheads();
    } else {
      this.addOnDataReceivedListener(() => {
        this.loadTypeaheads();
      }, this);
    }
  }

  getTypesToCache() {
    return ["Project", "Process", "UnitOperation", "Step", "MaterialAttribute", "ProcessParameter", ...super.getTypesToCache()];
  }

  handleChangeValue(attributeName, attributeValue, callback, attributeType) {
    super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
    if (attributeName === "ProcessId" && attributeValue) {
      const cache = new TypeaheadObjectCache("UnitOperation", this.getProjectId(), attributeValue);
      cache.loadOptions(() => this.initializeValue("ProcessId", attributeValue));
      if (UIUtils.parseInt(attributeValue) !== UIUtils.parseInt(this.state.ProcessId)) {
        // Clear out the UnitOperations/Steps if the Process changed.
        this.initializeValue("UnitOperations", []);
        this.initializeValue("Steps", []);
      }
    }
  }

  isDetailedRiskLinks() {
    return false;
  }

  /**
   * @return an array of Attribute names for which this class is the parent (ie. ProcessComponent, MaterialAttribute).
   * @abstract
   */
  getAttributeNames() {
    throw new ImplementationNeededError();
  }

  /**
   * @return a string that can be used on an attribute to find the parent (ie. "MaterialId", or "ProcessComponentId").
   * @abstract
   */
  getAttributeParentId() {
    throw new ImplementationNeededError();
  }

  loadTypeaheads() {
    if (!this.memoryCacheForLoadTypeaheads) {
      this.memoryCacheForLoadTypeaheads = MemoryCache.getNamedInstance(`base_attribute_parent_loadTypeaheads_${this.typeaheadsToLoad.join("_")}_${this.getProjectId()}_${this.getProcessId()}`);
    }

    const remainingTypesToCache = this.typeaheadsToLoad.filter(modelName => !this.memoryCacheForLoadTypeaheads.get(this.getInMemoryCacheKey(UIUtils.getTypeCodeForModelName(modelName))));
    if (remainingTypesToCache.length > 0) {
      new MultipleTypeaheadObjectCache(
        remainingTypesToCache,
        this.getProjectId(),
        this.getProcessId()).loadOptions((results, typeCode) => this.handleTypeaheadLoaded(null, results, typeCode, remainingTypesToCache.map(modelName => UIUtils.getTypeCodeForModelName(modelName))));
    } else {
      this.handleAllTypeaheadsLoaded();
    }
  }

  /**
   * This triggers every time a typeahead is loaded. Once all typeaheads are loaded, then we search
   *      and save in the state all child attributes of the selected parent record. This should happen only
   *      once though, when the last typeahead is loaded.
   * @param ignore
   * @param typeCode The typeCode of the typeahead being loaded.
   */
  handleTypeaheadLoaded(ignore, results, typeCode, remainingTypesToCache) {
    const {loadedTypeaheadCodes} = this.state;

    if (this.memoryCacheForLoadTypeaheads && typeCode && results) {
      this.memoryCacheForLoadTypeaheads.set(this.getInMemoryCacheKey(typeCode), results);
    }

    if (!loadedTypeaheadCodes.includes(typeCode)) {
      loadedTypeaheadCodes.push(typeCode);

      this.setStateSafely({loadedTypeaheadCodes}, () => {
        if (loadedTypeaheadCodes.length === remainingTypesToCache?.length) {
          this.handleAllTypeaheadsLoaded();
        }
      });
    }
  }

  /**
   * Once all typeaheads are loaded this finds all child attributes of the selected parent record.
   */
  handleAllTypeaheadsLoaded() {
    if (this.state.UnitOperations) {
      // Find all child attributes
      let childAttributes = this.getAllAttributesFromTypeaheads().filter(this.filterChildAttribute);
      this.updateChildAttributes(childAttributes);
    }
  }

  /**
   * Retrieves all attributes from the typeaheads cache.
   * @returns {*[]}
   */
  getAllAttributesFromTypeaheads() {
    // Find all child attributes
    let attributeNames = this.getAttributeNames();
    let childAttributes = [];
    const memoryCache = MemoryCache.getNamedInstance(`base_attribute_parent_${this.getProjectId()}_${this.getProcessId()}`);
    for (const attributeName of attributeNames) {
      const cacheKey = "typeahead_" + attributeName;
      let children = memoryCache.get(cacheKey);
      if (!children) {
        children = new TypeaheadObjectCache(attributeName, this.getProjectId(), this.getProcessId()).getOptionsFromCache();
        memoryCache.set(cacheKey, children);
      }

      childAttributes = childAttributes.concat(children);
    }

    return childAttributes;
  }

  getAttributeParentIdValue() {
    return this.state.id || this.id;
  }
  /**
   * This is a filter function that determines if a child attribute is a child of the currently selected record.
   * @param childAttribute
   * @returns {boolean}
   */
  filterChildAttribute(childAttribute) {
    return childAttribute[this.getAttributeParentId()] === this.getAttributeParentIdValue();
  }

  /**
   * Updates the page state with the child attributes and other related information.
   * @param childAttributes
   */
  updateChildAttributes(childAttributes) {
    // Set state to guide UO movement
    if (this.state.UnitOperations.length === 0) {
      this.setStateSafely({canAddMoreThanOneUO: childAttributes.length === 0});
    } else {
      this.setStateSafely({childAttributes});
    }
  }

  handleStepVerification() {
    let verificationErrors = [];
    if (this.state.childAttributes && this.state.childAttributes.length > 0) {
      const {childAttributes, Steps, UnitOperations} = this.state;

      // Create a unique list of steps & top level UOs that this asset is in.
      const stepIdSet = new Set(Steps.map(stp => stp.id));
      const uoIdSet = new Set(UnitOperations.map(uo => uo.id));
      const uoIdsNotAtTopLevel = new Set(Steps.map(stp => stp.UnitOperationId));
      const topLevelUOIdSet = new Set((UnitOperations || []).filter(uo => !uoIdsNotAtTopLevel.has(uo.id)).map(uo => uo.id));

      // Ensure all children are either in the stepIdSet or topLevelUOIdSet.
      const attributesAbanondedAtUO = [];
      const attributesAbanondedInAStep = [];
      for (const childAttribute of childAttributes) {
        if (!stepIdSet.has(childAttribute.StepId) && !topLevelUOIdSet.has(childAttribute.UnitOperationId)) {
          if (childAttribute.StepId) {
            attributesAbanondedInAStep.push(childAttribute);
          } else if (uoIdSet.has(childAttribute.UnitOperationId)) {
            attributesAbanondedAtUO.push(childAttribute);
          } // Else if the UO isn't on this record at all anymore, the correct error should be shown on the UO field, not this step one.
        }
      }

      // Compose the errors
      if (attributesAbanondedAtUO.length > 0) {
        const message = "The following attributes must be assigned to a step first";
        verificationErrors = verificationErrors.concat(this.renderStepValidationError(message, attributesAbanondedAtUO));
      }
      if (attributesAbanondedInAStep.length > 0) {
        let message = "The following attributes would be abandoned in their current step";
        verificationErrors = verificationErrors.concat(this.renderStepValidationError(message, attributesAbanondedInAStep));
      }
    }

    return verificationErrors.length > 0 ? verificationErrors.join("") : null;
  }

  renderStepValidationError(message, childAttributes) {
    return ReactDOMServer.renderToStaticMarkup(
      <div key={message}>
        {this.props.t(message)}
        <ul>
          {childAttributes.map(childAttribute => {
            const locations = [];
            if (childAttribute.UnitOperationId) {
              const cache = new TypeaheadObjectCache("UnitOperation", this.getProjectId(), this.getProcessId()).getOptionsFromCache();
              const uo = cache.find(uo => uo.id === childAttribute.UnitOperationId);
              locations.push(uo);
            }
            if (childAttribute.StepId) {
              const cache = new TypeaheadObjectCache("Step", this.getProjectId(), this.getProcessId()).getOptionsFromCache();
              const step = cache.find(step => step.id === childAttribute.StepId);
              locations.push(step);
            }
            let locationString;
            if (locations.length > 0) {
              locationString = locations.map(loc => UIUtils.getRecordCustomLabelForDisplay(loc)).join(", ");
            }
            return <li key={childAttribute.typeCode + "-" + childAttribute.id}>
              {UIUtils.getRecordCustomLabelForDisplay(childAttribute)} {locationString ? `in ${locationString}` : ""}
            </li>;
          })}
        </ul>
      </div>
    );
  }

  handleUOVerification() {
    let verificationErrors = [];

    verificationErrors = this.verifyNotAddingTooManyUOs(verificationErrors);

    verificationErrors = this.verifyChildAttributesOnParentUOChange(verificationErrors);

    verificationErrors = this.verifyNotLosingSteps(verificationErrors);

    return verificationErrors.length > 0 ? verificationErrors.join("") : null;
  }

  verifyNotAddingTooManyUOs(verificationErrors) {
    const uos = this.state.UnitOperations;
    if (!this.state.canAddMoreThanOneUO && uos.length > 1) {
      // If we try to be fancy about the "Process Parameters or Material Attributes" stuff then they won't end up in the translation file.
      verificationErrors.push(this.props.t("Only 1 Unit Operation can be added when there are Process Parameters or Material Attributes without a Unit Operation set."));
    }

    return verificationErrors;
  }

  /**
   * Creates and returns a Map of Unit Operation Ids to Child attribute records.
   * @returns {Map<any, any>}
   */
  getUOIdToChildAttributesMap() {
    const {childAttributes} = this.state;
    const uoToChildAttributesMap = new Map();

    for (const childAttribute of childAttributes) {
      const childUOIds = (childAttribute.UnitOperationId && [childAttribute.UnitOperationId])
        || childAttribute.UnitOperations
        || [];

      for (let childUOId of childUOIds) {
        const childAttributesForUO = uoToChildAttributesMap.get(childUOId) || [];
        childAttributesForUO.push(childAttribute);
        uoToChildAttributesMap.set(childUOId, childAttributesForUO);
      }
    }

    return uoToChildAttributesMap;
  }

  /**
   * Creates and returns a map of child attribute records to Unit Operation Ids.
   * @returns {Map<any, any>}
   */
  getChildAttributesToUOIdsMap() {
    const {childAttributes} = this.state;
    const childAttributesToUOsMap = new Map();

    for (const childAttribute of childAttributes) {
      const childUOIds = (childAttribute.UnitOperationId && [childAttribute.UnitOperationId])
        || childAttribute.UnitOperations
        || [];

      childAttributesToUOsMap.set(childAttribute, childUOIds);
    }

    return childAttributesToUOsMap;
  }

  /**
   * Gets the currently selected Unit Operation Ids for the record being edited
   * @returns {Set<*>}
   */
  getCurrentUOIds() {
    const {UnitOperations} = this.state;
    return new Set((UnitOperations || []).map(uo => uo.id));
  }

  /**
   * Finds and returns any Unit Operations that were removed from the record while being edited.
   * @returns {*}
   */
  getRemovedUOs() {
    const {originalUOs} = this.state;

    const currentUOIdSet = this.getCurrentUOIds();
    return originalUOs.filter(uo => !currentUOIdSet.has(uo.id));
  }

  /**
   * This verifies that after changing the UO on some parent element, i.e. a Step, a PRC or a MT, all child attributes
   * of the parent element are still valid. A child element could become invalid in the following cases:
   *
   * 1. The parent element is a PRC or MT and at least one UO is removed from the list of UOs the parent element is
   * associated with. If the parent element has any PP or MA which is no longer associated with the remaining UOs
   * of the parent element, then this PP or MA is not valid anymore.
   * 2. The parent element is a Step and the UO on the Step is modified. At the same time at least one PRC or MT under
   * the parent step is no longer associated with the new UO defined at the step level. This PRC or MT would become
   * invalid.
   * 3. The parent element is a Step and the UO on the Step is modified. At the same time at least one IQA, IPA, PP or
   * MA associated with the step. Any of those records would become invalid.
   * @param verificationErrors
   * @returns {*}
   */
  verifyChildAttributesOnParentUOChange(verificationErrors) {
    const {childAttributes} = this.state;

    if (childAttributes && childAttributes.length > 0) {
      // Create a map of UO and Step Ids to Child attributes in them.
      const uoIdToChildAttributesMap = this.getUOIdToChildAttributesMap();
      // And a map of child attributes to unit operation ids.
      const childAttributeToUOIdsMap = this.getChildAttributesToUOIdsMap();
      // Figure out which UOs were removed
      const uosRemoved = this.getRemovedUOs();
      // Figure out which UOs are associated with the parent element after the change
      const currentUOIdSet = this.getCurrentUOIds();
      const uosWithDanglingChildren = [];
      const removedUOIdsToAffectedChildrenMap = new Map();

      /* Create a Map with the UOs that were removed from the parent record and the children that would end up
         being invalid if the change were to be applied.
       */
      for (let removedUO of uosRemoved) {
        const childAttributes = uoIdToChildAttributesMap.get(removedUO.id) || [];
        /* Any child is affected if none of the unit operations defined at the child attribute is part of the
           UOs of the parent element. This covers for both cases of PRCs/MTs which can be associated with more than one
           UO but also for IQAs, IPAs, PPs and MAs which are only associated with one UO.
         */
        const affectedChildren = childAttributes.filter(childAttribute => {
          const attributeUOIds = childAttributeToUOIdsMap.get(childAttribute);
          return attributeUOIds.filter(uoId => currentUOIdSet.has(uoId)).length === 0;
        });

        /* If at least one child attribute is affected by removing a UO then keep track of the UO and the affected
           children.
         */
        if (affectedChildren.length > 0) {
          uosWithDanglingChildren.push(removedUO);
          removedUOIdsToAffectedChildrenMap.set(removedUO.id, affectedChildren);
        }
      }

      // Return an error where the removed UOs and the affected children due to each removal.
      verificationErrors = verificationErrors.concat(uosWithDanglingChildren.map(uo => {
        const affectedChildren = removedUOIdsToAffectedChildrenMap.get(uo.id);
        return ReactDOMServer.renderToStaticMarkup(
          <div key={uo.id}>
            {this.props.t("UO-{{uoId}} {{uoName}} cannot be removed because the following attributes need to be moved first:", {
              uoId: uo.id,
              uoName: uo.name
            })}
            <ul>
              {affectedChildren.map(childAttribute =>
                <li key={childAttribute.typeCode + "-" + childAttribute.id}>
                  {UIUtils.getRecordCustomLabelForDisplay(childAttribute)}
                </li>
              )}
            </ul>
          </div>
        );
      }));
    }

    return verificationErrors;
  }

  verifyNotLosingSteps(verificationErrors) {
    const {Steps, UnitOperations} = this.state;
    const uoCache = new TypeaheadObjectCache("UnitOperation", this.getProjectId(), this.getProcessId()).getOptionsFromCache();
    const stepCache = new TypeaheadObjectCache("Step", this.getProjectId(), this.getProcessId()).getOptionsFromCache();
    const uoIdSet = new Set((UnitOperations || []).map(uo => uo.id));

    const uoStepPairs = [];
    if (Steps && Steps.length > 0) {
      for (const stepFromState of Steps) {
        const step = stepCache.find(step => step.id === stepFromState.id);
        if (step && !uoIdSet.has(step.UnitOperationId)) {
          const uo = uoCache.find(uo => uo.id === step.UnitOperationId);
          uoStepPairs.push({uo, step});
        }
      }
    }
    if (uoStepPairs.length > 0) {
      verificationErrors.push(ReactDOMServer.renderToStaticMarkup(
        <div key="stepsLost">
          {this.props.t("The following Unit Operations are required because there are steps assigned:")}
          <ul>
            {uoStepPairs.map(uoStepPair => {
              const {uo, step} = uoStepPair;
              return <li key={step.typeCode + "-" + step.id}>
                {UIUtils.getRecordCustomLabelForDisplay(uo)} because it is needed by {UIUtils.getRecordCustomLabelForDisplay(step)}
              </li>;
            })}
          </ul>
        </div>
      ));
    }

    return verificationErrors;
  }

  showSupplierQualificationError(qualificationField, newQualificationStatus, supplierId) {
    return !this.isView()
      && this.isSupplierNotQualified(supplierId)
      && (newQualificationStatus || this.state[qualificationField]) === "Yes";
  }

  isSupplierNotQualified(supplierId) {
    supplierId = supplierId ? supplierId : this.state.SupplierId;
    if (supplierId) {
      let supplierOptions = new TypeaheadObjectCache("Supplier", this.getProjectId()).getOptionsFromCache();
      let supplier = supplierOptions.find(supplier => supplier.id === supplierId);
      return !supplier || (supplier.qualificationStatus !== "Qualified" && supplier.qualificationStatus !== "Re-qualified");
    }
    return true;
  }

  shouldCollapseAllSections() {
    return false;
  }
}
// i18next-extract-mark-ns-stop process_explorer

