"use strict";

import React, { Fragment } from "react";

import BaseReactComponent from "../../../../base_react_component";
import ContinuousDataBatchPanel from "./continuous_data_batch_panel";
import DataReviewHeaderPanel from "./continuous_data_review_panel_header";
import ContinuousDataAttributesTable from "./continuous_data_attributes_table";
import ContinuousDataMeasurementsTable from "./continuous_data_measurements_table";
import ContinuousAttributeInformationPanel from "./continuous_attribute_information_panel";

/**
 * This implements the batch panel showing batch information while reviewing continuous data in a process data import flow.
 */
export default class ContinuousDataReviewPanel extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state.selectedAttribute = null;
  }

  handleSelectedAttributeChanged(attribute) {
    this.setStateSafely({
      selectedAttribute: attribute,
    });
  }

  render() {
    const {attributes, batchInfo} = this.props;
    const {selectedAttribute} = this.state;
    const measurements = selectedAttribute && selectedAttribute.measurements || [];
    const totalAttributesWithMeasurementsOFL = attributes.filter(attribute => attribute.totalMeasurementsOFL > 0).length;
    const totalMeasurementsOFL = attributes.reduce((total, attribute) => {
      total += attribute.totalMeasurementsOFL;
      return total;
    }, 0);

    return (
      <Fragment>
        <div className="row continuous-data-review-batch-panel">
          <ContinuousDataBatchPanel batchInfo={batchInfo} />
        </div>
        <div className="row continuous-data-review-header-panel">
          <DataReviewHeaderPanel totalAttributes={attributes.length}
                                 totalAttributesWithMeasurementsOutOfLimits={totalAttributesWithMeasurementsOFL}
                                 totalMeasurementsOutOfLimits={totalMeasurementsOFL}
          />
        </div>
        <div className="row continuous-data-review-details-panel">
          <div className="col-12 col-md-6 col-lg-3 continuous-data-attributes-table-col">
            <ContinuousDataAttributesTable id="continuousDataAttributes"
                                           records={attributes}
                                           onSelectedAttributeChanged={this.handleSelectedAttributeChanged}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <ContinuousDataMeasurementsTable records={measurements}
                                             attribute={selectedAttribute}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <ContinuousAttributeInformationPanel attribute={selectedAttribute || {}} />
          </div>
        </div>
      </Fragment>);
  }
}

