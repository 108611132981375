"use strict";

import React, { createContext, useContext, useState } from "react";
import { NOTIFICATION } from "../topBar/constants";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({children}) => {
  const [notification, setNotification] = useState(null);

  /**
   * @param message The notification message.
   * @param duration number of seconds to hide the message after.
   */
  const showNotification = ({message, duration, details, type = NOTIFICATION.INFO}) => {
    setNotification({message, details, type});
    if (duration) {
      setTimeout(() => hideNotification(), duration * 1000);
    }
  };

  const hideNotification = () => {
    setNotification(null);
  };

  return (
    <NotificationContext.Provider value={{notification, showNotification, hideNotification}}>
      {children}
    </NotificationContext.Provider>
  );
};
