import { Node, Fragment } from 'prosemirror-model';
import { TextSelection, AllSelection, NodeSelection } from 'prosemirror-state';
import { parseContent } from './source';
export var changeStylesString = function (styleText, newStyle) {
    var styleToChange = newStyle.style;
    var regExp = newStyle.value;
    var newValue = newStyle.newValue;
    if (!styleText) {
        return { changed: false, style: null };
    }
    var styles = styleText.split(/\s*;\s*/).filter(function (s) { return Boolean(s); });
    var filtered = styles.filter(function (s) {
        var nameValue = s.split(/\s*:\s*/);
        return !(nameValue[0].toLowerCase() === styleToChange && regExp.test(nameValue[1]));
    });
    if (newValue) {
        filtered.push(styleToChange + ": " + newValue);
    }
    return {
        style: filtered.join('; ') + (filtered.length ? ';' : ''),
        changed: Boolean(newValue) || filtered.length !== styles.length
    };
};
/**
 * Determines if a given node type can be inserted at the current cursor position.
 */
export var canInsert = function (state, nodeType) {
    var $from = state.selection.$from;
    for (var d = $from.depth; d >= 0; d--) {
        var index = $from.index(d);
        if ($from.node(d).canReplaceWith(index, index, nodeType)) {
            return true;
        }
    }
    return false;
};
var getTypeName = function (n) {
    return n instanceof Node ? n.type.name : n.name;
};
var findNthParentNode = function (predicate, depth) {
    if (depth === void 0) { depth = 1; }
    return function (selection) {
        var $from = selection.$from;
        for (var i = $from.depth; i > 0; i--) {
            var node = $from.node(i);
            if (predicate(node)) {
                depth = depth - 1;
                if (depth === 0) {
                    return { depth: i, node: node };
                }
            }
        }
    };
};
export var findNthParentNodeOfType = function (nodeType, depth) {
    if (depth === void 0) { depth = 1; }
    return function (selection) {
        return findNthParentNode(function (node) { return getTypeName(node) === getTypeName(nodeType); }, depth)(selection);
    };
};
/**
 * Inserts the given node at the place of current selection.
 */
export var insertNode = function (node, scrollIntoView) { return function (state, dispatch) {
    var tr = state.tr.replaceSelectionWith(node);
    if (scrollIntoView) {
        tr.scrollIntoView();
    }
    dispatch(tr);
}; };
export var hasSameMarkup = function (dom1, dom2, schema, parseOptions) {
    var fragment1 = Fragment.from(parseContent(dom1, schema, parseOptions));
    var fragment2 = Fragment.from(parseContent(dom2, schema, parseOptions));
    return fragment1.eq(fragment2);
};
export var getSelectionText = function (state) {
    var sel = state.selection;
    if (sel instanceof TextSelection || sel instanceof AllSelection) {
        var fragment = sel.content().content;
        return fragment.textBetween(0, fragment.size);
    }
    return '';
};
export var getNodeFromSelection = function (state) {
    if (state.selection instanceof NodeSelection) {
        return state.selection.node;
    }
};
/**
 * Returns the text from the selection if only text is selected on a single line.
 * If selection contains leaf nodes (br, image) between text elements or
 * text from multiple block nodes, the function will return empty string.
 *
 * Useful for values of the inputs of Link and Find&Replace dialogs where the inputs value has been retrieved from the selection and
 * should be single line text only.
 */
export var selectedLineTextOnly = function (state) {
    var result = '', hasLeafs = false;
    var selection = state.selection, doc = state.doc;
    var $from = selection.$from, $to = selection.$to, from = selection.from, to = selection.to;
    if ($from.sameParent($to)) {
        doc.nodesBetween(from, to, function (node) {
            hasLeafs = hasLeafs || (node.isLeaf && !node.isText);
        });
        if (!hasLeafs) {
            result = getSelectionText(state);
        }
    }
    return result;
};
/**
 * Used by ViewHtml/ViewSource dialogs for making the HTML more readable.
 */
export var indentHtml = function (content) {
    return content.replace(/<\/(p|li|ul|ol|h[1-6]|table|tr|td|th)>/ig, '</$1>\n')
        .replace(/<(ul|ol)([^>]*)><li/ig, '<$1$2>\n<li')
        .replace(/<br \/>/ig, '<br />\n')
        .replace(/\n$/, '');
};
export var shallowEqual = function (object1, object2) {
    var keys1 = Object.keys(object1);
    var keys2 = Object.keys(object2);
    return keys1.length === keys2.length &&
        keys1.every(function (k) { return object1[k] === object2[k]; });
};
var applyToWordDefault = { before: /[^ !,?.\[\]{}()]+$/i, after: /^[^ !,?.\[\]{}()]+/i };
/**
 * if options.applyToWord is set, expands the selection to the word where the cursor is and
 * returns modified state and dispatch.
 */
export var expandSelection = function (state, dispatch, options) {
    if (!options.applyToWord || !state.selection.empty) {
        return { state: state, dispatch: dispatch };
    }
    var applyToWordOptions = options.applyToWord === true ? applyToWordDefault : options.applyToWord;
    var initialPosition = null;
    var tr = state.tr;
    var selection = state.selection;
    var before = selection.$head.nodeBefore;
    var after = selection.$head.nodeAfter;
    if (before && before.type.name === 'text' && before.text && after && after.type.name === 'text' && after.text) {
        var children_1 = [];
        selection.$head.parent.descendants(function (node, pos) {
            children_1.push({ node: node, pos: pos });
            return false;
        });
        var cursor_1 = selection.$head.parentOffset;
        var nodeIndex = children_1.findIndex(function (_a) {
            var node = _a.node, pos = _a.pos;
            return pos <= cursor_1 && pos + node.nodeSize >= cursor_1;
        });
        var text = children_1[nodeIndex].node.text;
        var skip = false;
        for (var i = nodeIndex - 1; i >= 0; i--) {
            var element = children_1[i];
            if (!skip && element && element.node.type.name === 'text') {
                text = element.node.text + text;
            }
            else {
                skip = true;
                cursor_1 -= element.node.nodeSize;
            }
        }
        for (var i = nodeIndex + 1; i < children_1.length; i++) {
            var element = children_1[i];
            if (element && element.node.type.name === 'text') {
                text = text + element.node.text;
            }
            else {
                break;
            }
        }
        var textBefore = text.substring(0, cursor_1);
        var textAfter = text.substring(cursor_1);
        var matchBefore = applyToWordOptions.before.exec(textBefore);
        var matchAfter = applyToWordOptions.after.exec(textAfter);
        if (matchBefore && matchAfter) {
            var extendLeft = matchBefore[0].length;
            var extendRight = matchAfter[0].length;
            var pos = initialPosition = selection.from;
            tr.setSelection(TextSelection.create(state.doc, pos - extendLeft, pos + extendRight));
            var cmdState = {
                tr: tr,
                selection: tr.selection,
                doc: tr.doc,
                storedMarks: null,
                schema: tr.doc.type.schema
            };
            var cmdDispatch = function (tran) {
                tran.setSelection(TextSelection.create(tran.doc, initialPosition));
                dispatch(tran);
            };
            return { state: cmdState, dispatch: cmdDispatch };
        }
    }
    return { state: state, dispatch: dispatch };
};
/**
 * if options.applyToWord is set, expands the selection to the word where the cursor is and
 * use the modified state for the passed command.
 * Designed to work with toggleInlineFormat, applyInlineStyle and applyLink functions.
 *
 * Example:
 * const applyToWord: boolean|{before: RegExp, after: RegExp} = true;
 * // or applyToWord = { before: /[^ !,?.\[\]{}()]+$/i, after: /^[^ !,?.\[\]{}()]+/i }};
 *
 * const command = expandToWordWrap(toggleInlineFormat, {...bold, applyToWord );
 * command(view.state, view.dispatch);
 */
export var expandToWordWrap = function (command, options) {
    return function (state, dispatch, _view) {
        var _a = expandSelection(state, dispatch, options), cmdState = _a.state, cmdDispatch = _a.dispatch;
        return command(options)(cmdState, cmdDispatch);
    };
};
