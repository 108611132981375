"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import ErrorBar from "../widgets/bars/error_bar";
import moment from "moment/moment";
import DropdownButton from "../widgets/generic/dropdown_button";
import { loadRMP } from "../helpers/risk_helper";
import { MODEL_TYPES_ENUM } from "./constants/report_constants";
import Typeahead from "../widgets/typeahead";
import DatePicker from "../widgets/date_picker";
import { renderTypeaheadItems } from "./chart_reports/chart_report_helper";
import * as CannedReportHelper from "./canned_reports/canned_report_helper";
import BaseReactComponent from "../base_react_component";
import * as ProcessCache from "../processExplorer/process/process_cache";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { ServiceBase } from "../services/service_base";

/* This class is the base class for both canned and chart reports */
export default class DataReportBase extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.report = null;

    let currentDate = UIUtils.getEndOfDayForDate(moment()).toDate();
    this.setStateSafely({
      reportResults: null,
      isPrintDisabled: true,
      isExportDisabled: true,
      reportDate: currentDate,
      editableName: "",
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    let dependencyTypeaheadChanged = (!this.props.dependencyTypeahead && nextProps.dependencyTypeahead) ||
      (JSON.stringify(this.props.dependencyTypeahead) !== JSON.stringify(nextProps.dependencyTypeahead));
    let typeaheadOptionsChanged = (!this.props.typeaheadOptions && nextProps.typeaheadOptions) ||
      (JSON.stringify(this.props.typeaheadOptions) !== JSON.stringify(nextProps.typeaheadOptions));
    let selectedTypeaheadOptionChanged = (!this.props.selectedTypeaheadOption && nextProps.selectedTypeaheadOption) ||
      (JSON.stringify(this.props.selectedTypeaheadOption) !== JSON.stringify(nextProps.selectedTypeaheadOption));

    return !!this.state.reportResults ||
      this.props.reportOptions !== nextProps.reportOptions ||
      this.props.reportType !== nextProps.reportType ||
      this.props.projectId !== nextProps.projectId ||
      this.props.title !== nextProps.title ||
      this.state.clearCharts !== nextState.clearCharts ||
      JSON.stringify(this.state.filterOptions) !==
      JSON.stringify(nextProps.filterOptions) ||
      (this.state.charts && nextState.charts &&
        JSON.stringify([...this.state.charts.keys()]) !==
        JSON.stringify([...nextState.charts.keys()])) ||
      dependencyTypeaheadChanged ||
      typeaheadOptionsChanged ||
      selectedTypeaheadOptionChanged;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.processId !== this.props.processId && this.typeahead) {
      this.typeahead.getInstance().clear();

      this.setStateSafely({
        reportResults: null,
        selectedTypeaheadOption: null,
        isPrintDisabled: true,
        isExportDisabled: true,
      });
    }

    // Once the typeahead options are loaded, look to see if there's a model Label in the URL that should be loaded.
    if ((!prevProps.typeaheadOptions && this.props.typeaheadOptions)
      || (JSON.stringify(prevProps.typeaheadOptions) !== JSON.stringify(this.props.typeaheadOptions))) {
      this.onTypeaheadChange([this.getSelectedTypeaheadOption()]);
    }
  }

  onTypeaheadChange(options, callback) {
    let value = options.length === 0 ? null : options[0];
    this.setStateSafely(
      {
        selectedTypeaheadOption: value,
        reportResults: null,
      }, () => {
        this.loadData();
        if (typeof callback === "function") {
          callback();
        }
      }
    );
  }

  loadData() {
    const {
      projectId,
      versionId,
      reportOptions,
    } = this.props;

    const {selectedTypeaheadOption} = this.state;
    if (selectedTypeaheadOption) {
      UIUtils.setHideLoadingOnAjaxStop(false);
      UIUtils.showLoadingImage("Retrieving Data");

      this.modelType = selectedTypeaheadOption.typeCode;
      const processId = this.props.processId || ProcessCache.getProcessIdUsedRecently(this.props.projectId);

      UIUtils.pushHistoryURLWithParameterChanges(this.state, {
        modelLabel: selectedTypeaheadOption.typeCode ? selectedTypeaheadOption.typeCode + "-" + selectedTypeaheadOption.id
          : selectedTypeaheadOption.id,
        selectedIds: this.props.selectedSubgroupIds ? JSON.stringify(this.props.selectedSubgroupIds) : undefined,
      });

      const requestPayload = {
        ...this.getCustomPayloadData(),
        date: reportOptions.needsDateInput ? CannedReportHelper.getUTCDateFromDate(this.state.reportDate, UIUtils.DATE_FORMAT_FOR_STORAGE) : null,
        editableId: selectedTypeaheadOption.id,
        editableName: reportOptions.multiModelReport ? MODEL_TYPES_ENUM[selectedTypeaheadOption.typeCode] : undefined,
        projectId: this.isCompanyLevelReport() ? null : projectId,
        processId,
        versionId: versionId && !this.autoLoadFinished ? versionId : null,
        selectedIds: this.props.selectedSubgroupIds ? JSON.stringify(this.props.selectedSubgroupIds) : null,
      };

      // company level reports (such as suppliers or admin reports) don't have a project, so they don't have RMPs
      if (this.isCompanyLevelReport()) {
        this.getDataFromServer(requestPayload);
      } else {
        loadRMP(this.getDataFromServer.bind(this, requestPayload));
      }
    }
  }

  isCompanyLevelReport() {
    return this.props.projectId === null;
  }

  getReportCategoryType() {
    throw Error("You must override this method.");
  }

  getDataFromServer(requestPayload, cachedRMP, project) {
    if (this.isReportLoading) {
      return;
    }

    this.isReportLoading = true;

    UIUtils.clearError();

    requestPayload.report = this.props.reportType;
    let parameters = {
      action: "reports",
      useTwoWayCommunication: true,
    };

    const service = new ServiceBase();
    service.sendToBackend(requestPayload, parameters).then((response) => {
      this.handleReceivedDataFromServer(cachedRMP, project, response);
      this.isReportLoading = false;
    }).catch(result => {
      this.isReportLoading = false;
      UIUtils.defaultFailFunction(result, null, null, UIUtils.PRIMARY_ALERT_DIV);
    });
  }

  getCustomPayloadData() {
    return {};
  }

  // eslint-disable-next-line no-unused-vars
  handleDateChange(event) {
    if (this.props.reportOptions.needsDateInput) {
      throw Error("You must override onDateChange on the derived class.");
    }
  }

  // eslint-disable-next-line no-unused-vars
  handleReceivedDataFromServer(cachedRMP, project, results) {
    throw Error("You must override handleReceivedDataFromServer on the derived class.");
  }

  handlePrint() {
    throw Error("You must override handlePrint on the derived class.");
  }

  // eslint-disable-next-line no-unused-vars
  exportAs(event) {
    throw Error("You must override exportAs on the derived class.");
  }

  /**
   * @returns {jsx.Element|String}
   */
  renderInput() {
    throw Error("You must override renderInput on the derived class.");
  }

  renderFilters() {
    // Override this function if your report has filters other than defaults
  }

  showTypeahead() {
    // Some reports rely on having the data that should appear in the typeahead loaded
    // (even though it won't appear in the page)
    // So, to avoid breaking other functionality, the hideTypeahead option can be used to
    // load the data while not displaying the typeahead
    return !!(
      this.props.reportOptions
      && this.props.reportOptions.needsTypeaheadInput
      && !this.props.reportOptions.hideTypeahead
    );
  }

  showDatePicker() {
    return !!(this.props.reportOptions
      && this.props.reportOptions.needsDateInput
      && !this.props.reportOptions.hideDatePicker);
  }

  getFiltersClass() {
    const {reportOptions} = this.props;
    const {
      useShorterDatePicker, useShorterTypeahead,
      useVeryShortDatePicker, useVeryShortTypeahead
    } = reportOptions;

    const useShorterDefaultFilter = !!(reportOptions && (useShorterDatePicker || useShorterTypeahead));
    const useVeryShortDefaultFilter = !!(reportOptions && (useVeryShortDatePicker || useVeryShortTypeahead));

    if (useVeryShortDefaultFilter) {
      return "col-2";
    } else if (useShorterDefaultFilter) {
      return "col-2 col-xl-3";
    } else {
      return "col-7 col-lg-8 col-xl-9";
    }
  }

  getBorderClass() {
    const showBorder = !!(this.props.reportOptions && this.props.reportOptions.showBorder);
    return showBorder ? " data-report-border" : "";
  }

  getPaddingClass() {
    const removePaddingAtBottom = !!(this.props.reportOptions && this.props.reportOptions.removePaddingAtBottom);
    return removePaddingAtBottom ? " data-report-no-padding-bottom" : "";
  }

  isFullScreen() {
    return !!(this.props.reportOptions && this.props.reportOptions.fullScreen);
  }

  filterDataAfterReceivedFromServer() {
    // Override this function if your report has default filtration
  }

  isDatePickerDisabled(selectedTypeaheadOption) {
    return this.showTypeahead() && !(selectedTypeaheadOption && selectedTypeaheadOption.label);
  }

  getSelectedTypeaheadOption() {
    return this.state.selectedTypeaheadOption || this.props.selectedTypeaheadOption || null;
  }

  filterOptions(options) {
    return options;
  }

  render() {
    const {
      reportOptions,
      typeaheadOptions,
    } = this.props;

    const {
      reportDate,
      isPrintDisabled,
      isExportDisabled,
    } = this.state;

    const selectedTypeaheadOption = this.getSelectedTypeaheadOption();

    return (
      <div className={"row-white row shadow canned-report-container" +
        (this.isFullScreen() ? " canned-report-container-full-screen" : "")}
      >
        <div className={"col-12 " + this.getBorderClass() + this.getPaddingClass()}>
          <div className={"row p-3"}>
            <div className={this.getFiltersClass()}>
              {this.showTypeahead() ?
                <div className={this.showDatePicker() ?
                  " col-6" : ""}
                >
                  <label id="dataReportTypeaheadLabel"
                         htmlFor="dataReportTypeahead"
                         className="col-form-label base-attribute"
                  >
                    {reportOptions.elementLabel}:
                  </label>
                  <div id="dataReportTypeaheadDiv">
                    <Typeahead id="dataReportTypeahead"
                               inputProps={{id: "dataReportTypeaheadInput", autoComplete: "off"}}
                               renderMenuItemChildren={renderTypeaheadItems.bind(this)}
                               options={this.filterOptions(typeaheadOptions)}
                               Multiple={false}
                               selectHintOnEnter={true}
                               maxResults={reportOptions.maxResults}
                               selected={selectedTypeaheadOption && selectedTypeaheadOption.label ? [selectedTypeaheadOption.label] : null}
                               ref={typeahead => this.typeahead = typeahead}
                               onChange={this.onTypeaheadChange}
                    />
                  </div>
                </div> : ""
              }
              {this.showDatePicker() ?
                <div className={this.showTypeahead() ? " col-6" : ""}>
                  <label id="dataReportDatePickerLabel"
                         htmlFor="dataReportDatePicker"
                         className="col-form-label base-attribute pt-0"
                  >
                    Date:
                  </label>
                  <div id="dataReportDatePickerDiv">
                    <DatePicker id="dataReportDatePicker"
                                disabled={this.isDatePickerDisabled(selectedTypeaheadOption)}
                                selected={reportDate ? moment(reportDate).toDate() : null}
                                maxDate={new Date()}
                                ref={datePicker => this.datePicker = datePicker}
                                onChange={this.handleDateChange}
                    />
                  </div>
                </div> : ""
              }
            </div>
            {this.renderFilters()}
            {reportOptions.printable || reportOptions.exportable ? (
              <div className={`col col-lg-4 col-xl-3 data-report-btn-group`}>
                <div className="row justify-content-end no-gutters">
                  {reportOptions.printable ?
                    <div className="col-auto">
                      <button className="btn btn-lg btn-primary btn-group data-report-print-button"
                              disabled={isPrintDisabled}
                              id="dataReportPrintButton"
                              onClick={this.handlePrint}
                      >
                        <FontAwesomeIcon icon={faPrint} /> Print
                      </button>
                    </div>
                    : ""}
                  {reportOptions.exportable ?
                    <div className="col-auto">
                      <DropdownButton className="btn-group data-report-split-button"
                                      options={reportOptions.exportAs}
                                      text="Export As"
                                      id={this.getReportCategoryType() + "Export"}
                                      isDisabled={isExportDisabled}
                                      onOptionsSelect={this.exportAs}
                      />
                    </div>
                    : ""}
                </div>
              </div>
            ) : ""}
          </div>
          <ErrorBar className="mx-3" />
        </div>
        {this.renderInput()}
      </div>
    );
  }
}
