"use strict";

import { findBestMatch } from "string-similarity";
import { TYPE_CODE } from "../../../../server/common/generic/common_constants";
import CommonUtils from "../../../../server/common/generic/common_utils";
import CommonLibraryMaterials from "../../../../server/common/editables/common_library_material";
import * as CommonLibraryMaterial from "../../../../server/common/editables/common_library_material";

/**
 * Utility functions to use in sync Material flow.
 */
export class SyncUtils {

  static getChangedAttributes(material, libraryMaterial) {
    return CommonLibraryMaterials.getChangedAttributes(material, libraryMaterial);
  }

  static addMatchingMaterialAttribute(specifications, mAttributes) {

    const names = mAttributes.map(ma => ma.name);
    return specifications.map(spec => {

      /**
       * Find the matching Material Attribute and get the highest match by sorting desc the algorithm result.
       */
      if (!CommonUtils.isEmptyArray(names)) {

        const { ratings } = findBestMatch(spec.name, names);
        ratings.sort((a, b) => {
          return b.rating - a.rating;
        });

        const [attribute] = ratings;
        return {
          ...spec,
          materialAttribute: mAttributes.find(ma => (ma.name === attribute.target && attribute.rating > 0.6)),
        };
      }

      return spec;
    });
  }

  static getMaterialAttributes(material, addDropDownDefaultItems = false) {
    let materialAttributes = material?.childInstances.filter(child => child.typeCode === TYPE_CODE.MATERIAL_ATTRIBUTE);
    if (addDropDownDefaultItems) {
      materialAttributes.push({
        id: CommonLibraryMaterial.CREATE_ID,
        name: "Create new Material Attribute",
        description: "This will be linked to the Library\nspecifications",
      });
      materialAttributes.push({
        id: CommonLibraryMaterial.IGNORE_ID,
        name: "Ignore for now",
        description: "You can create a Material Attribute from\nLibrary anytime later",
      });
    }
    return materialAttributes;
  }
}
