"use strict";

import * as UIUtils from "../../../../ui_utils";
import { BaseTrainingDataTransform } from "./base_training_data_transform";

/**
 * This class is responsible for converting the data returned from the server into JSON format Stimulsoft needs
 * to create the Individual Training Report.
 */
export class IndividualTrainingReportTransform extends BaseTrainingDataTransform {

  get type() {
    return "individualTrainingReport";
  }

  shouldRun() {
    return true;
  }

  transform(input, options) {

    let status = options.filterOptions.status.toLowerCase();
    status = status === "all" ? "assigned" : status;

    const data = input.instances.reportData;

    let {instances, users} = data;
    let results = [];

    const documentTypes = Array.from(new Set(instances.map(instance => instance.documentType).values()));
    const documentsPerType = [];

    instances = instances.map(instance => {
      return {
        ...instance,
        assignmentDate: UIUtils.getDateForDisplayToUser(instance.assignmentDate, UIUtils.DATE_FORMAT_FOR_DISPLAY),
        completionDate: UIUtils.getDateForDisplayToUser(instance.completionDate, UIUtils.DATE_FORMAT_FOR_DISPLAY),
        effectiveDate: UIUtils.getDateForDisplayToUser(instance.effectiveDate, UIUtils.DATE_FORMAT_FOR_DISPLAY),
        specifier: this.getRetrainingSpecifier(instance),
        id: instance.documentId,
      };
    });

    for (let documentType of documentTypes) {
      documentsPerType.push({
        type: documentType,
        instances: instances.filter(instance => instance.documentType === documentType)
      });
    }

    for (let user of users) {

      let userFilter = (instance) => instance.userId === user.id;

      let userData = {
        ...user,
        userName: `${user.firstName} ${user.lastName}`,
        instances: documentsPerType.filter(documentPerType => {
          return documentPerType.instances.find(userFilter);
        }).map(documentPerType => {
          let instancesByType = documentPerType.instances.filter(userFilter) || [];
          let instance = {
            type: documentPerType.type
          };

          let documentsPerUser = instancesByType.length > 0 ?
            documentPerType.instances.filter(userFilter) : [];
          instance.documentsPerUser = documentsPerUser.map(document => {
            document.modelName = "Document";
            document.customId = UIUtils.getRecordCustomIdForDisplay(document);
            document.completionDate = this.getTrainingStatus(document, documentsPerUser);
            document.incomplete = this.getIncompleteStatus(document, documentsPerUser);

            return document;
          }).sort(
            UIUtils.sortBy(
              {name: "customId",},
              {
                name: "documentVersion",
                primer: UIUtils.parseInt,
                reverse: true
              },
            ));
          return instance;
        }).sort(
          UIUtils.sortBy({
            name: "type"
          }))
      };

      userData.isEmptySummary = userData.instances.length === 0;
      results.push(userData);
    }

    results = results.sort(
      UIUtils.sortBy({
        name: "userName",
      }));

    return {
      instances: results,
      isEmptySummary: results.length === 0,
      status,
    };
  }
}
