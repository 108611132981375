"use strict";

import React, { Fragment } from "react";
import BaseReactComponent from "../../../base_react_component";
import { ListOption } from "../../../editor/widgets/list_option";

/**
 * This renders the process selection drop down rendered in various places in the import flow.
 */
export default class ImportProcessSelect extends BaseReactComponent {
  render() {
    const {processes, process, onProcessChange, showRequiredMark} = this.props;

    return (
      <Fragment>
        <div>
          <label id="processSelectionDropDownLabel"
                 htmlFor="processSelectionDropDown"
          >
                  <span className="col-form-label">Process
                    {showRequiredMark ? (
                      <span className={process ? "base-attribute-value-specified" : "base-attribute-value-missing"}>
                        {" *"}
                      </span>
                    ) : ""}
                  </span>
          </label>
        </div>
        <div>
          <select className="form-control"
                  id="processSelectionDropDown"
                  value={process && process.name}
                  onChange={onProcessChange}
          >
            {(processes || []).map(process => <ListOption item={process.name} key={process.id} />)}
          </select>
        </div>
      </Fragment>
    );
  }
}