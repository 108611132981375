"use strict";

import React from "react";
import BaseReactComponent from "../../base_react_component";

export default class PCMDashboardInfoBox extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id={`${this.props.id}Div`} className="pcm-dashboard-info-box">
        <div id={`${this.props.id}Title`} className="col-form-label pcm-dashboard-index-chart-label">
          <label className="pcm-dashboard-info-box-title"> {this.props.title} </label>
        </div>
        <div id={`${this.props.id}Value`} className="col-form-label pcm-dashboard-index-chart-label">
          <label className="pcm-dashboard-info-box-value"> {this.props.value} </label>
        </div>
      </div>
    );
  }
}
