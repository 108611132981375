"use strict";

import React, { memo, useEffect } from "react";
import { SidePanelProvider } from "./widgets/side_panel_provider";
import { SidePanelWidget } from "./widgets/side_panel_widget";
import { TABLE_MODE } from "../../../configurable_tables_helper";
import { ViewTableToolBar } from "./view/view_table_tool_bar";
import { EditTableToolBar } from "./edit/edit_table_tool_bar";
import { useTableState } from "../../../table_state_provider";


export const TableToolBar = memo((props) => {

  const {
    selectedModelType
  } = props;

  const {
    selectedCount,
    clearSelectedRows
  } = useTableState();

  useEffect(() => {
    clearSelectedRows();
  }, [selectedModelType]);

  const tableMode = selectedCount ? TABLE_MODE.EDIT : TABLE_MODE.VIEW;

  return (
    <SidePanelProvider>

      {tableMode === TABLE_MODE.VIEW && <ViewTableToolBar {...props} />}
      {tableMode === TABLE_MODE.EDIT && <EditTableToolBar {...props} />}

      <SidePanelWidget  {...props} />
    </SidePanelProvider>
  );
});