"use strict";

import { RequirementSummaryDataTransform } from "./requirement_summary_data_transform";
import { MaxCriticalityInfo } from "../../canned_reports/widgets/max_crtiticality_info";
import ReactDOMServer from "react-dom/server";
import React from "react";

export class BillOfProcessComponentsDataTransform extends RequirementSummaryDataTransform {

  get type() {
    return "billOfProcessComponents";
  }

  getSupportedRecordTypes() {
    return ["processcomponents", "mas", "pps"];
  }

  getRecordInstance(requirement) {
    return {...requirement};
  }

  sortRecords(records) {
    const result = [...records];
    result.sort((a, b) => a.ProcessComponentId - b.ProcessComponentId);
    return result;
  }

  getModelNameForView(recordType) {
    return recordType;
  }

  formatCriticality(requirement) {
    return requirement.criticality
      ? requirement.criticalityRiskLabel
      : "";
  }

  /**
   * Retrieves the accepted record types for this report
   */
  getFilteredRecordTypes() {
    return ["processcomponents"];
  }

  wrapUp(result, options) {
    const wrappedData = super.wrapUp(result, options);
    const instances = wrappedData.instances;
    // noinspection SpellCheckingInspection
    const {mas, pps, processcomponents} = instances;

    // combines MAs and PPs into a single array
    const allChildren = [
      ...(mas || []),
      ...(pps || []),
    ];

    for(let prc of processcomponents) {
      const children = allChildren.filter(item => item.processComponentId === prc.ProcessComponentId);
      const criticalityComponent = <MaxCriticalityInfo children={children} />;

      prc.criticality = ReactDOMServer.renderToStaticMarkup(criticalityComponent);
    }

    instances.isEmptySummary = processcomponents && processcomponents.length === 0;
    return wrappedData;
  }
}
