import { wrapIn } from 'prosemirror-commands';
export var blockquote = function (state, dispatch) {
    return wrapIn(state.schema.nodes.blockquote)(state, dispatch);
};
export var liftBlockquote = function (state, dispatch) {
    var _a = state.selection, $from = _a.$from, $to = _a.$to;
    var nodeType = state.schema.nodes.blockquote;
    var doc = state.doc;
    var target = -1;
    var range = $from.blockRange($to);
    if (range) {
        doc.nodesBetween(range.start, range.end, function (node, pos, _parent, _index) {
            if (node.type === nodeType) {
                target = pos;
            }
        });
    }
    var result = target !== -1;
    if (dispatch && result) {
        dispatch(state.tr.lift(range, doc.resolve(target).depth));
    }
    return result;
};
