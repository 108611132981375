var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { chainCommands, exitCode, splitListItem, undo, redo, undoInputRule, goToNextCell, toggleInlineFormat } from '@progress/kendo-editor-common';
import { EditorToolsSettings } from './toolsSettings';
var bold = EditorToolsSettings.bold, underline = EditorToolsSettings.underline, italic = EditorToolsSettings.italic;
var mac = typeof navigator !== 'undefined' ? /Mac/.test(navigator.platform) : false;
/**
 * @hidden
 */
export var getShortcuts = function (settings) {
    var insertBr = chainCommands(exitCode, function (state, dispatch) {
        var hardBreak = settings && settings.types && settings.types.hardBreak || 'hard_break';
        var br = state.schema.nodes[hardBreak];
        if (dispatch) {
            dispatch(state.tr.replaceSelectionWith(br.create()).scrollIntoView());
        }
        return true;
    });
    var listItem = settings && settings.types && settings.types.listItem || 'list_item';
    var toolsSettings = settings && settings.toolsSettings || {};
    var shortcutsSettings = __assign(__assign(__assign(__assign({ 'Mod-b': function (state, dispatch) { return toggleInlineFormat(toolsSettings.bold || bold)(state, dispatch); }, 'Mod-i': function (state, dispatch) { return toggleInlineFormat(toolsSettings.italic || italic)(state, dispatch); }, 'Mod-u': function (state, dispatch) { return toggleInlineFormat(toolsSettings.underline || underline)(state, dispatch); }, 'Mod-z': undo, 'Shift-Mod-z': redo }, __assign({}, !mac ? { 'Mod-y': redo } : {})), { 'Backspace': undoInputRule, 'Mod-Enter': insertBr, 'Shift-Enter': insertBr }), __assign({}, mac ? { 'Ctrl-Enter': insertBr } : {})), { 'Enter': function (state, dispatch) {
            return splitListItem(state.schema.nodes[listItem])(state, dispatch);
        }, 'Tab': goToNextCell(1), 'Shift-Tab': goToNextCell(-1) });
    return shortcutsSettings;
};
