"use strict";

import React from "react";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { ADD_QUALITY_ATTRIBUTES_TYPES, ADD_TPPs_TYPES, PRODUCT_LIST_TABS } from "../product_constants";
import DropdownButton from "../../widgets/generic/dropdown_button";
import * as UIUtils from "../../ui_utils";
import BaseReactComponent from "../../base_react_component";

// i18next-extract-mark-ns-start widgets
class AddButton extends BaseReactComponent {

  handleAddClick(event) {
    const {t, projectId} = this.props;

    if (event.target.textContent === t("General Attribute")) {
      window.location.href = UIUtils.getSecuredURL(`/product/qtpp/generalAttributes/viewEdit.html?operation=Add&projectId=${projectId}`);
    } else if (event.target.textContent === t("Quality Attribute")) {
      window.location.href = UIUtils.getSecuredURL(`/product/qtpp/fqas/viewEdit.html?operation=Add&projectId=${projectId}`);
    } else if (event.target.textContent === t("Performance Attribute")) {
      window.location.href = UIUtils.getSecuredURL(`/product/qtpp/fpas/viewEdit.html?operation=Add&projectId=${projectId}`);
    } else if (event.target.textContent === t("Target Product Profile Element")) {
      window.location.href = UIUtils.getSecuredURL(`/product/tpp/viewEdit.html?operation=Add&projectId=${projectId}`);
    }
  }

  getOptions() {
    switch (this.props.selectedProductListTab) {
      case PRODUCT_LIST_TABS.QTPP:
        return ADD_QUALITY_ATTRIBUTES_TYPES;
      case PRODUCT_LIST_TABS.TPP:
        return ADD_TPPs_TYPES;
      case "All":
        return ADD_TPPs_TYPES.concat(ADD_QUALITY_ATTRIBUTES_TYPES);
    }
  }

  render() {
    const {t, project, buttonClassName, isSecondaryButton} = this.props;
    return (
      <DropdownButton options={this.getOptions()}
                      removeTitleIcon={true}
                      isSecondaryButton={isSecondaryButton}
                      buttonClassName={buttonClassName}
                      className={"btn-group" + (project ? "" : " skeleton")}
                      id="productElementsAdd"
                      text={t("Add")}
                      isDisabled={project && project.deletedAt}
                      onOptionsSelect={this.handleAddClick}
                      size="small"
      />
    );
  }
}

export default I18NWrapper.wrap(AddButton, "widgets");
// i18next-extract-mark-ns-stop widgets
