import { BaseParser } from "./base_parser";
import { FIELD_KEY } from "../../common/editables_map";
import { GLOBAL_MODELS } from "../../common/constants";
import * as DocumentLinkParser from "./elementParser/document_link_parser";
import * as UIUtils from "../../../ui_utils";

/**
 * Parser that parses all QbD fields that are not in a widget
 */
export class QbDFieldParser extends BaseParser {
  get selector() {
    return ".qbd-output";
  }

  get fieldKey() {
    return "qbdFields";
  }

  getElements() {
    return Array.from(this.node.querySelectorAll(this.selector)).filter(
      (element) => {
        const [modelName] = element.innerText.split(".");
        // If the element is inside a widget that has the same model, we should not parse it
        // here. We will leave it for the widget parser
        if (GLOBAL_MODELS.includes(modelName)) {
          const parentWidgetNode = this.findParentWidgetNode(element, modelName);
          if (parentWidgetNode) {
            return false;
          }
        }

        return (
          GLOBAL_MODELS.includes(modelName) ||
          !element.className.includes("widget")
        );
      },
    );
  }

  parse() {
    const ignoredColumns = ["lastVersion"];
    const qbdOutputElements = this.getElements();
    for (const qbdOutputElement of qbdOutputElements) {
      const {parentModelName, modelName, columnName} =
        this.getNodeInfo(qbdOutputElement);
      if (
        !modelName ||
        !columnName ||
        DocumentLinkParser.isLinkAttribute(modelName)
      ) {
        continue;
      }

      // We only add the Qbd field to our fields when it has an id in the state
      // For example, if there is a Project.name and the data contains ProjectId,
      // we will add that to the fields. We need to have the id so the backend
      // can generate a query then find it
      let modelIdKey = `${modelName}Id`;
      if (parentModelName) {
        modelIdKey = `${parentModelName}Id`;
      }

      if (this.data[modelIdKey] && !ignoredColumns.includes(columnName)) {
        let modelField = this.fields;
        if (parentModelName) {
          modelField = this.addModelAndColumnToField(
            this.fields,
            parentModelName,
          );
          modelField.id = this.data[`${modelIdKey}`];
        }
        modelField = this.addModelAndColumnToField(
          modelField,
          modelName,
          columnName,
        );

        if (!parentModelName) {
          modelField.id = this.data[`${modelIdKey}`];
        }
      }

      const fieldData = this.data && this.data[this.fieldKey];
      if (
        parentModelName &&
        fieldData &&
        fieldData[parentModelName] &&
        fieldData[parentModelName][modelName]
      ) {
        qbdOutputElement.outerHTML = UIUtils.secureString(
          fieldData[parentModelName][modelName][columnName],
        );
      } else if (modelName === FIELD_KEY.DocBuilderFields) {
        qbdOutputElement.outerHTML =
          this.getValueForDocBuilderField(columnName);
      } else if (!fieldData || !fieldData[modelName]) {
        qbdOutputElement.outerHTML = this.createErrorElement(qbdOutputElement, "qbd-field-error");
      } else if (columnName === "lastVersion") {
        // A temporary solution, we will make our query generator can get all the versions of a
        // record later
        const record = fieldData[modelName];
        const versions = record[`${modelName}Versions`];
        const lastVersion = (versions || []).find(
          (version) => version.id === record.LastVersionId,
        );
        if (lastVersion) {
          qbdOutputElement.outerHTML = `v${lastVersion.majorVersion}.${lastVersion.minorVersion}`;
        }
      } else {
        qbdOutputElement.outerHTML = UIUtils.secureString(
          fieldData[modelName][columnName],
        );
      }
    }

    return qbdOutputElements;
  }
}
