"use strict";

/*
This configuration file contains the main rules for each model type. That includes
- Table
- Data Adapter
- Naming and Security
 */

import React from "react";
import CommonSecurity from "../../../server/common/generic/common_security";
import { ConfigurableTable } from "../../configurableTables/tables/configurable_table";
import ConfigurableTablesAttributeAdapter
  from "../../configurableTables/adapters/configurable_tables_attribute_adapter";
import ConfigurableTablesAdapter from "../../configurableTables/adapters/configurable_tables_adapter";
import { filterRiskRankingFields } from "../../configurableTables/columnSelection/configurable_tables_column_selection_helper";
import {
  ConfigurableMasterDetailsTable
} from "../../configurableTables/tables/configurable_master_details_table";
import TargetProductProfileFields from "../../configurableTables/fieldsConfig/generic/target_product_profile_fields";
import GeneralAttributeFields from "../../configurableTables/fieldsConfig/generic/general_attribute_fields";
import FinalAttributeFields from "../../configurableTables/fieldsConfig/attributes/final_attribute_fields";
import DetailsTableContainer from "../../configurableTables/tables/details_table_container";
import ConfigurableTablesCustomFiltersHandler
  from "../../configurableTables/columnOperations/configurable_tables_custom_filters_handler";

export const PRODUCT_MODELS_CONFIG = {
  ["Quality Attributes"]: {
    table: (props) =>
      <ConfigurableMasterDetailsTable {...{
        ...props,
        modelName: "Final Attribute",
        detailsTable: DetailsTableContainer,
        filterFieldsFunction: filterRiskRankingFields,
      }} />,
    adapter: (params) => new ConfigurableTablesAttributeAdapter(params, "fqaMap", "FQAs"),
    customFiltersHandler: new ConfigurableTablesCustomFiltersHandler(),
    fieldsConfig: new FinalAttributeFields(),
    modelName: "FQA",
    securityModelType: CommonSecurity.Types.FQA,
  },
  ["Performance Attributes"]: {
    table: (props) =>
      <ConfigurableMasterDetailsTable {...{
        ...props,
        modelName: "Final Attribute",
        detailsTable: DetailsTableContainer,
        filterFieldsFunction: filterRiskRankingFields
      }} />,
    adapter: (params) => new ConfigurableTablesAttributeAdapter(params, "fpaMap", "FPAs"),
    customFiltersHandler: new ConfigurableTablesCustomFiltersHandler(),
    fieldsConfig: new FinalAttributeFields("Final Attributes", "FPAs"),
    modelName: "FPA",
    securityModelType: CommonSecurity.Types.FPA,
  },
  ["Target Product Profile"]: {
    table: (props) => <ConfigurableTable {...props} />,
    adapter: (params) => new ConfigurableTablesAdapter(params, "tppMap", "TPPs"),
    customFiltersHandler: new ConfigurableTablesCustomFiltersHandler(),
    fieldsConfig: new TargetProductProfileFields(),
    modelName: "TPPSection",
    securityModelType: CommonSecurity.Types.TPP_SECTION
  },
  ["General Attributes"]: {
    table: (props) => <ConfigurableTable {...props} />,
    adapter: (params) => new ConfigurableTablesAdapter(params, "gaMap", "GeneralAttributes"),
    customFiltersHandler: new ConfigurableTablesCustomFiltersHandler(),
    fieldsConfig: new GeneralAttributeFields(),
    modelName: "GeneralAttribute",
    securityModelType: CommonSecurity.Types.GENERAL_ATTRIBUTE
  },
};
