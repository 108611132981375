"use strict";

import * as UIUtils from "../../../ui_utils";
import { BaseDataTransform } from "../base_data_transform";
import {
  convertLinkedArrayToString,
  fillInRiskLinks,
  formatJustification,
  getRiskLabel,
  getRiskScaleColor,
  getRiskScaleLabel,
  processEmptyFields
} from "../../canned_reports/canned_report_helper";
import {
  getFilteredRMPForType,
  hasNotAssessedRiskScale
} from "../../../helpers/risk_helper";
import { RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import { REPORT_DRAFT_ENUM } from "../../constants/report_constants";
import * as CommonEditablesFormatter from "../../../../server/common/editables/common_editables_formatter";
import { RecordsIndexerBuilder } from "../../../processExplorer/indexers/records_indexer";

const moment = require("moment");
const impactColumnLabel = "Impact/ Severity";
const criticalityColumnLabel = "Criticality (Raw)";
const PROCESS_PARAMETER_TYPES_ENUM = {
  ACTION: "action",
  REFERENCE: "reference"
};

export class FMEAUnitOperationDataTransform extends BaseDataTransform {

  get type() {
    return "fmeaUnitOperation";
  }

  // eslint-disable-next-line no-unused-vars
  shouldRun(options) {
    return true;
  }

  transform(input, options) {
    const result = input;
    let {reviewDate, referenceDate} = options.filterOptions;
    let data = result.instances.requirement;
    let typeaheadOptions = options.dependenciesTypeaheadOptions;
    let rows = [];
    const ppParents = [{id: null, name: "", function: "None"},
      ...data.materials.map(mt => {
        return {typeCode: "MT", ...mt};
      }),
      ...data.processComponents.map(prc => {
        return {typeCode: "PRC", ...prc};
      })];
    const recordsIndexer = new RecordsIndexerBuilder(data.unitOperations)
      .setModelName("ProcessParameters")
      .setStpMap(data.unitOperations.reduce((acc, uo) => uo.Steps ? acc.concat(uo.Steps) : acc, []))
      .build();

    // Create a map of process parameters and their corresponding versions.
    const processParameterToVersionsMap = new Map();
    for (let processParameterVersion of data.processParameterVersions) {
      const {processParameterId} = processParameterVersion;
      if (!processParameterToVersionsMap.has(processParameterId)) {
        processParameterToVersionsMap.set(processParameterId, [processParameterVersion]);
      } else {
        processParameterToVersionsMap.get(processParameterId).push(processParameterVersion);
      }
    }

    for (let [ignore, value] of processParameterToVersionsMap) {
      const processParameterVersions = value;
      processParameterVersions.sort(
        UIUtils.sortBy({
          name: "id",
          primer: UIUtils.parseInt,
          reverse: true
        }));

      let referencePPVersion = processParameterVersions.find(pp => {
        pp.createdAt = this.formatDate(pp.createdAt);
        return pp.createdAt.isSameOrBefore(this.formatDate(referenceDate));
      });

      let actionPPVersion = processParameterVersions.find(pp => {
        pp.createdAt = this.formatDate(pp.createdAt);
        return pp.createdAt.isSameOrBefore(this.formatDate(reviewDate));
      });

      if (!referencePPVersion || !actionPPVersion) {
        continue;
      }

      const ppParent = ppParents.find(parent => {
        return (parent.id === referencePPVersion.processComponentId && parent.typeCode === "PRC")
          || (parent.id === referencePPVersion.materialId && parent.typeCode === "MT");
      });

      // Filling in data for report
      const row = {
        id: referencePPVersion.processParameterId,
        name: referencePPVersion.name,
        processComponentId: ppParent?.id || "",
        processComponentLabel: ppParent?.id ? UIUtils.getRecordLabelForDisplay(ppParent.typeCode, ppParent.id, ppParent.name) : "",
        processComponentFunction: ppParent?.function || "",
        processParameterLabel: UIUtils.getRecordLabelForDisplay("PP", referencePPVersion.processParameterId, referencePPVersion.name),
        potentialFailureModes: referencePPVersion.potentialFailureModes,
        draftMarker: referencePPVersion.majorVersion > 0 && referencePPVersion.minorVersion === 0 ? "" : REPORT_DRAFT_ENUM.DraftMarker,
        referenceCapabilityJustification: referencePPVersion.capabilityJustification,
        referenceDetectabilityJustification: referencePPVersion.detectabilityJustification,
        referenceRecommendedActions: referencePPVersion.recommendedActions,
        ...this.fillInRiskInformation(PROCESS_PARAMETER_TYPES_ENUM.REFERENCE, referencePPVersion, options, typeaheadOptions),
        actionControlStrategy: UIUtils.secureString(
          CommonEditablesFormatter.formatMultiSelectValueForDisplay(actionPPVersion.controlStrategy)
        ),
        actionControlMethods: convertLinkedArrayToString(actionPPVersion.ControlMethods, "CM", " | "),
        actionRecommendedActions: actionPPVersion.recommendedActions,
        ...this.fillInRiskInformation(PROCESS_PARAMETER_TYPES_ENUM.ACTION, actionPPVersion, options)
      };

      if (data.unitOperations) {
        let uo = data.unitOperations.find(uo => uo.id === referencePPVersion.unitOperationId);
        row.unitOperationId = uo.id;
        row.unitOperation = UIUtils.getRecordLabelForDisplay("UO", uo.id, uo.name);
        row.unitOperationLabel = `${uo.name} - Global`;

        if (referencePPVersion.stepId) {
          row.stepId = referencePPVersion.stepId;
          row.step = `${referencePPVersion.Step.name}`;
          row.stepLabel = `${uo.name} - ${referencePPVersion.Step.name}`;
        }
      }

      processEmptyFields(row);

      // Function shouldn't be shown at all in case it doesn't have value
      row.processComponentFunction = (ppParent?.function && ppParent?.function !== "None") ? ppParent.function : "";
      rows.push(row);
    }

    referenceDate = moment(referenceDate).format(UIUtils.DATE_FORMAT_FOR_DISPLAY);
    reviewDate = moment(reviewDate).format(UIUtils.DATE_FORMAT_FOR_DISPLAY);

    // Restructuring object based on UO
    const orderedRows = recordsIndexer.fillInSortByOrderProperty([...rows]);
    let restructuredObjects = [];
    let processedUnitOperations = [];

    for (let {unitOperationLabel, stepLabel} of orderedRows.map(record => {
      return {unitOperationLabel: record.unitOperationLabel, stepLabel: record.stepLabel};
    })) {
      let records = [];
      let label;
      if (stepLabel && !processedUnitOperations.includes(stepLabel)) {
        label = stepLabel;
        records = orderedRows.filter(record => record.stepLabel === stepLabel);
      } else if (!processedUnitOperations.includes(unitOperationLabel)) {
        label = unitOperationLabel;
        records = orderedRows.filter(record => record.unitOperationLabel === unitOperationLabel && !record.stepLabel);
      }

      if (records.length > 0) {
        restructuredObjects.push({
          records,
          unitOperationLabel: label,
        });
        processedUnitOperations.push(label);
      }
    }
    restructuredObjects = restructuredObjects.filter(object => object.records.length > 0);

    return {
      ...result,
      instances: restructuredObjects,
      unitOperation: options.selectedTypeaheadLabel,
      referenceDate,
      reviewDate,
      draftMessage: REPORT_DRAFT_ENUM.DraftMessage.replace("{informationDate}", referenceDate),
      isEmptySummary: orderedRows.length === 0
    };
  }

  fillInRiskInformation(processParameterType, processParameter, options, typeaheadOptions) {
    if (!processParameter.riskInfo) {
      throw new Error("Missing RiskInfo from Process Parameter");
    }

    let result = {};
    let effectiveRMP = getFilteredRMPForType(options.rmpVersions.find(rmpVersion => rmpVersion.id === processParameter.effectiveRMPVersionId), "PP");
    const hasNotAssessed = hasNotAssessedRiskScale(RISK_TYPE_ENUM.CRITICALITY, effectiveRMP);
    const defaultLabel = "None";

    result[`${processParameterType}ImpactColumnLabel`] = impactColumnLabel;

    let impact = processParameter.riskInfo[RISK_TYPE_ENUM.IMPACT].value;
    let riskScale = processParameter.riskInfo[RISK_TYPE_ENUM.IMPACT].scale;
    result[`${processParameterType}ImpactLabel`] = this.formatEmptyRiskScore(impact, riskScale, hasNotAssessed ? riskScale.riskLabel : defaultLabel);
    result[`${processParameterType}ImpactColor`] = getRiskScaleColor(riskScale);

    const capability = processParameter.riskInfo[RISK_TYPE_ENUM.CAPABILITY_RISK].value;
    riskScale = processParameter.riskInfo[RISK_TYPE_ENUM.CAPABILITY_RISK].scale;
    result[`${processParameterType}CapabilityRiskLabel`] = this.formatEmptyRiskScore(capability, riskScale, hasNotAssessed ? riskScale.scoreLabel : defaultLabel);
    result[`${processParameterType}CapabilityColor`] = getRiskScaleColor(riskScale);

    const detectability = processParameter.riskInfo[RISK_TYPE_ENUM.DETECTABILITY_RISK].value;
    riskScale = processParameter.riskInfo[RISK_TYPE_ENUM.DETECTABILITY_RISK].scale;
    result[`${processParameterType}DetectabilityRiskLabel`] = this.formatEmptyRiskScore(detectability, riskScale, hasNotAssessed ? riskScale.scoreLabel : defaultLabel);
    result[`${processParameterType}DetectabilityColor`] = getRiskScaleColor(riskScale);

    let rpn = processParameter.riskInfo[RISK_TYPE_ENUM.RPN].value;
    riskScale = processParameter.riskInfo[RISK_TYPE_ENUM.RPN].scale;
    result[`${processParameterType}RPN`] = rpn;
    result[`${processParameterType}RPNLabel`] = this.formatEmptyRiskScore(rpn, riskScale, hasNotAssessed ? riskScale.riskLabel : defaultLabel);
    result[`${processParameterType}RPNColor`] = getRiskScaleColor(riskScale);

    let rawCriticalityRiskScore = processParameter.riskInfo[RISK_TYPE_ENUM.CRITICALITY].value;
    riskScale = processParameter.riskInfo[RISK_TYPE_ENUM.CRITICALITY].scale;
    result[`${processParameterType}CriticalityLabel`] = this.formatEmptyRiskScore(rawCriticalityRiskScore, riskScale, hasNotAssessed ? riskScale.riskLabel : defaultLabel);
    result[`${processParameterType}CriticalityColor`] = getRiskScaleColor(riskScale);

    if (processParameterType === PROCESS_PARAMETER_TYPES_ENUM.REFERENCE) {
      let riskLinks = fillInRiskLinks(processParameter, typeaheadOptions).map(link => {
        return {
          ...link,
          justification: link.justification || "None"
        };
      }) || [];
      result[`${processParameterType}ImpactJustification`] = formatJustification(riskLinks.filter(link => link.impact === impact));

      let riskScale = processParameter.riskInfo[RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel;
      result.riskLabel = getRiskLabel(riskScale);
      result.riskLabelColor = getRiskScaleColor(riskScale);
      result.riskLinksSummary = riskLinks.map(link => `• ${link.riskLinkTo}`).join("\n");
    }

    if (effectiveRMP.useUncertainty) {
      result[`${processParameterType}ImpactColumnLabel`] = criticalityColumnLabel;
      result[`${processParameterType}ImpactLabel`] = result[`${processParameterType}CriticalityLabel`];
      result[`${processParameterType}ImpactColor`] = result[`${processParameterType}CriticalityColor`];
    }

    return result;
  }

  // noinspection JSMethodCanBeStatic
  formatEmptyRiskScore(riskValue, riskScale, defaultLabel = "None") {
    return riskValue ? `${riskValue} - ${getRiskScaleLabel(riskScale)}` : defaultLabel;
  }

  // noinspection JSMethodCanBeStatic
  formatDate(date) {
    return moment(moment(date).format(UIUtils.DATE_FORMAT_FOR_STORAGE));
  }

  // noinspection JSMethodCanBeStatic
  getDistinctValues(array) {
    let ids = [];
    let outputArray = [];
    for (let record of array) {
      if (!ids.includes(record.processParameterId)) {
        outputArray.push(record);
        ids.push(record.processParameterId);
      }
    }
    return outputArray;
  }
}
