"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import RiskMapShape from "../shapes/risk_map_shape";
import { shouldRenderFullColor, shouldRender, showByRiskLabel } from "../utilities/risk_map_report_helper";
import loadingImg from "../../../images/loading.gif";
import { getRiskScale, getRiskScores } from "../../../helpers/risk_helper";
import { RISK_COLORS } from "../../../rmps/constants/rmp_constants";
import BaseReactComponent from "../../../base_react_component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackward,
  faClock, faExpandArrowsAlt,
  faFastBackward, faFastForward,
  faForward,
  faMinus,
  faPlus,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { Timeline } from "vis-timeline/peer";
import { DataSet } from "vis-data/peer";
import { Log, LOG_GROUP } from "../../../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Reports, "RiskMapTimeline");

const TIMELINE_BAR_ID = "risk-map-timeline-select-time-bar";

/**
 * The maximum number of items that can appear on the timeline. The timeline just can't handle huge amounts of data,
 * especially when using the filters.
 *
 * @type {number}
 */
const MAX_TIMELINE_ITEMS = 200;

export default class RiskMapTimeline extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.setStateSafely({
      showLoading: true,
      warningMessage: null,
      overrideShowAll: false,
    });

    this.rendered = false;
    this.reRender = false;

    window.itemsToObjects = {};

    this.objectIdsToItemIds = {};
    this.timelineData = new DataSet({queue: true});
    this.previousSelectedItem = null;

    this.setTimeLineOptions();
  }

  static scaleToFitTimeline() {
    window.timeline.fit({
      animation: {
        duration: 500,
        easingFunction: "easeInOutQuint"
      }
    });
  }

  showHideTimeline(forceTimelineCollapse = false) {
    let riskMapTimelinePanel = $("#riskMapTimelinePanel");
    if (forceTimelineCollapse) {
      riskMapTimelinePanel.collapse("hide");
    }

    let panelIsClosing = RiskMapTimeline.isTimelinePanelExpanded();
    if (panelIsClosing) {
      riskMapTimelinePanel.resizable("disable");
      $("#riskMapTimelineContainer").height("initial");
    } else {
      if (!panelIsClosing) {
        riskMapTimelinePanel.resizable("enable");
      }
    }
  }

  static isTimelinePanelExpanded() {
    let riskMapTimelinePanel = $("#riskMapTimelinePanel");
    return (riskMapTimelinePanel[0].clientHeight > 0);
  }

  static onTimelineResize(event, ui) {
    let riskMapTimelineShowButtonDiv = $("#riskMapTimelineShowButtonDiv")[0];
    let riskMapGraphZoomInButtonDiv = $("#riskMapGraphZoomInButtonDiv")[0];
    let riskMapGraphZoomOutButtonDiv = $("#riskMapGraphZoomOutButtonDiv")[0];
    let riskMapTimelineScaleToFitButtonDiv = $("#riskMapTimelineScaleToFitButtonDiv")[0];
    let riskMapTimelineWarningDiv = $("#riskMapTimelineWarningDiv")[0];
    let riskMapTimelineFirstButtonDiv = $("#riskMapTimelineFirstButtonDiv")[0];
    let riskMapTimelinePreviousButtonDiv = $("#riskMapTimelinePreviousButtonDiv")[0];
    let riskMapTimelineNextButtonDiv = $("#riskMapTimelineNextButtonDiv")[0];
    let riskMapTimelineLastButtonDiv = $("#riskMapTimelineLastButtonDiv")[0];
    riskMapTimelineShowButtonDiv.style.bottom = ui.size.height + "px";
    riskMapTimelineWarningDiv.style.bottom = ui.size.height + "px";
    riskMapTimelineFirstButtonDiv.style.bottom = ui.size.height + "px";
    riskMapTimelinePreviousButtonDiv.style.bottom = ui.size.height + "px";
    riskMapTimelineNextButtonDiv.style.bottom = ui.size.height + "px";
    riskMapTimelineLastButtonDiv.style.bottom = ui.size.height + "px";
    riskMapGraphZoomInButtonDiv.style.bottom = ui.size.height + 44 + "px";
    riskMapGraphZoomOutButtonDiv.style.bottom = ui.size.height + "px";
    riskMapTimelineScaleToFitButtonDiv.style.bottom = ui.size.height + "px";
  }

  static getItemDimensionsFromItemType(type) {
    let dimensions = {
      itemHeight: 45,
      itemWidth: 45,
      shapeHeight: 35,
      shapeWidth: 35
    };

    switch (type) {
      case "PP":
        dimensions.shapeHeight = 30;
        dimensions.shapeWidth = 38;
        break;
    }

    return dimensions;
  }

  setTimeLineOptions() {
    this.options = {
      width: "100%",
      height: "100%",
      stack: false,
      showTooltips: true,
      tooltip: {
        overflowMethod: "flip",
        followMouse: false
      },
      xss: {
        disabled: true,
      },
      showCurrentTime: false,
      onInitialDrawComplete: () => {
        //Add the project time bar title
        if ($("#timelineProjectCustomTimeDiv").length === 0) {
          $(".risk-map-timeline-custom-time-project").prepend(
            ReactDOMServer.renderToString(
              <div id="timelineProjectCustomTimeDiv"
                   className="risk-map-timeline-custom-time-project-legend"
              >
                {this.props.mapHistory.project.name}
              </div>
            )
          );
        }

        //Add the RMP Version time bar titles
        for (let rmpApprovedVersion of this.props.rmpVersions) {
          if ($(`#timelineRMP${rmpApprovedVersion.id}`).length === 0) {
            $(`.risk-map-timeline-rmp-custom-time.rmp${rmpApprovedVersion.id}`).prepend(
              ReactDOMServer.renderToString(
                <div id={`timelineRMP${rmpApprovedVersion.id}`}
                     className="risk-map-timeline-custom-time-rmp-legend"
                >
                  {`${rmpApprovedVersion.name} (V${rmpApprovedVersion.majorVersion}.${rmpApprovedVersion.minorVersion})`}
                </div>
              )
            );
          }
        }
      }
    };
  }

  componentDidMount() {
    super.componentDidMount();
    let riskMapTimelinePanel = $("#riskMapTimelinePanel");
    riskMapTimelinePanel.resizable({
      alsoResize: ".risk-map-timeline-container",
      handles: "n",
    });
    riskMapTimelinePanel.resizable("disable");
    riskMapTimelinePanel.on("resize", this.handleResize);

    riskMapTimelinePanel.on("shown.bs.collapse", () => {
      this.props.riskMapFilter.resizePanel();
      this.props.riskMapLegend.resizePanel();
    });
    riskMapTimelinePanel.on("hidden.bs.collapse", () => {
      this.props.riskMapFilter.resizePanel();
      this.props.riskMapLegend.resizePanel();
    });
  }

  handleResize(event, ui) {
    RiskMapTimeline.onTimelineResize(event, ui);
    this.props.riskMapFilter.resizePanel();
    this.props.riskMapLegend.resizePanel();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!nextProps.filters) {
      return false;
    }

    if (!this.props.filters || !nextProps.filters) {
      return false;
    }

    let typeaheadOptionsChanged = (!this.props.selectedTypeaheadOptions && nextProps.selectedTypeaheadOptions) ||
      (this.props.selectedTypeaheadOptions && nextProps.selectedTypeaheadOptions && (nextProps.selectedTypeaheadOptions.length !== this.props.selectedTypeaheadOptions.length));

    this.reRender = !this.rendered
      || !this.props.graphLayout
      || (!this.props.filters && nextProps.filters)
      || (nextProps.riskType !== this.props.riskType)
      || typeaheadOptionsChanged
      || (JSON.stringify(this.prepareFiltersForTimeline(nextProps.filters)) !== JSON.stringify(this.prepareFiltersForTimeline(this.props.filters)))
      || (typeof nextProps.scaleNetworkToFit === "boolean" && nextProps.scaleNetworkToFit)
      || this.state.warningMessage !== nextState.warningMessage
      || this.state.overrideShowAll !== nextState.overrideShowAll
      || (this.state.showLoading && !nextState.showLoading);

    return this.reRender;
  }

  /**
   * We don't want certain filters to update the timeline, so we are removing these from the filters object
   * right before determining whether we should re-render the control
   * @param filters The filters object from which certain properties will be removed.
   */
  prepareFiltersForTimeline(filters) {
    let filtersClone = JSON.parse(JSON.stringify(filters));
    delete filtersClone.linksFilter;
    return filtersClone;
  }

  componentDidUpdate(prevProps) {
    if (this.props.filters.riskTracedToFilter !== prevProps.filters.riskTracedToFilter) {
      this.previousSelectedItem = null;
      this.objectIdsToItemIds = {};
    }

    if (this.props.mapHistory && this.reRender) {
      this.setTimelineData(this.props.mapHistory.historyPoints, this.props.filters, this.props.selectedTypeaheadOptions);
      if (this.timelineData.length === 0) {
        this.options.end = new Date();
      }

      if (!window.timeline) {
        window.timeline = new Timeline(this.riskMapTimelineDiv, this.timelineData, this.options);
        window.timeline.on("select", this.handleHistoryItemSelected);
        window.timeline.on("mouseMove", this.handleHistoryMouseMove);
        window.timeline.on("mouseOver", this.handleHistoryMouseOver);
        window.timeline.on("changed", this.handleHistoryChanged);
        window.timeline.on("timechange", this.handleTimeChange);
        window.timeline.on("doubleClick", this.handleTimelineDoubleClick);
        this.addCustomTimeBars(window.timeline);
      }

      let selectedItemKey = this.getSelectedItemKey(this.state.selectedDate);
      this.updateSelectedItem(selectedItemKey, false, true, true);
      this.rendered = true;
      UIUtils.incrementReactComponentDidUpdateCounter();
    }

    if (typeof this.props.scaleNetworkToFit === "boolean" && this.props.scaleNetworkToFit) {
      if (RiskMapTimeline.isTimelinePanelExpanded()) {
        this.showHideTimeline(true);
      }
    }
  }

  async handleShowAll(event) {
    const eventTarget = event.target;
    UIUtils.ignoreHandler(event);
    await UIUtils.showLoadingCursor(eventTarget);
    this.setStateSafely({overrideShowAll: true, warningMessage: null}, () => {
      UIUtils.hideLoadingCursor(eventTarget);
    });
  }

  handleHistoryChanged() {
    if (this.state.showLoading) {
      this.setStateSafely({showLoading: false});
    }
    window.timeline.off("changed", this.handleHistoryChanged);
    this.selectTimelineDate("last", true);
  }

  handleHistoryMouseMove() {
    $("#riskMapTimeline .vis-tooltip").css("visibility", "hidden");
  }

  handleHistoryMouseOver(event) {
    $("#riskMapTimeline .vis-tooltip").css("visibility", "hidden");
    clearTimeout(this.showTimelineTooltipTimeout);
    if (event.item !== null) {
      this.showTimelineTooltipTimeout = setTimeout(() => {
        $("#riskMapTimeline .vis-tooltip").css("visibility", "visible");
      }, 500);
    }
  }

  componentWillUnmount() {
    if (window.timeline) {
      window.timeline.off("select");
      window.timeline.off("mouseMove");
      window.timeline.off("mouseOver");
      window.timeline.off("changed");
      window.timeline.off("timechange");
      window.timeline.off("doubleClick");
      window.timeline.destroy();
    }
    super.componentWillUnmount();
  }

  handleHistoryItemSelected(event) {
    if (event.items && this.props.onRiskMapDateChanged && this.props.mapHistory) {
      let itemId = event.items[0];
      if (itemId) {
        this.updateSelectedItem(itemId, true, true, true);
        this.setStateSafely({
          selectedDate: window.itemsToObjects[itemId].date
        }, () => {
          let itemKeys = Object.keys(window.itemsToObjects);
          let lastDateSelected = itemKeys.indexOf(itemId.toString()) === (itemKeys.length - 1);
          this.props.onRiskMapDateChanged(this.state.selectedDate, lastDateSelected);
        });
      }
    }
  }

  updateSelectedItem(selectedItemId, updateTimeBar, focusOnTimeLineSelection, useAnimation) {
    if (selectedItemId && this.previousSelectedItem && this.previousSelectedItem === window.itemsToObjects[selectedItemId]) {
      return;
    }

    if (this.previousSelectedItem) {
      let previousSelectedItemDimensions = RiskMapTimeline.getItemDimensionsFromItemType(this.previousSelectedItem.type);
      let timelineItemId = this.objectIdsToItemIds[this.previousSelectedItem.type + "-" + this.previousSelectedItem.versionTransitionId];
      if (window.itemsToObjects[timelineItemId]) {
        this.updateContent(timelineItemId, ReactDOMServer.renderToStaticMarkup(
          <RiskMapShape type={this.previousSelectedItem.type}
                        RMP={this.previousSelectedItem.rmpForModelType}
                        currentState={this.previousSelectedItem.currentState}
                        itemHeight={previousSelectedItemDimensions.itemHeight}
                        itemWidth={previousSelectedItemDimensions.itemWidth}
                        shapeWidth={previousSelectedItemDimensions.shapeWidth}
                        shapeHeight={previousSelectedItemDimensions.shapeHeight}
                        selected={false}
                        color={this.previousSelectedItem.color}
                        renderFullColor={this.previousSelectedItem.renderFullColor}
                        legend={this.previousSelectedItem.name}
          />));
      }
    }

    if (selectedItemId) {
      let selectedItem = window.itemsToObjects[selectedItemId];
      let itemDimensions = RiskMapTimeline.getItemDimensionsFromItemType(selectedItem?.type);

      this.updateContent(selectedItemId, ReactDOMServer.renderToStaticMarkup(
        <RiskMapShape type={selectedItem?.type}
                      RMP={selectedItem?.rmpForModelType}
                      currentState={selectedItem?.currentState}
                      itemHeight={itemDimensions?.itemHeight}
                      itemWidth={itemDimensions?.itemWidth}
                      shapeWidth={itemDimensions?.shapeWidth}
                      shapeHeight={itemDimensions?.shapeHeight}
                      selected={true}
                      color={selectedItem?.color}
                      renderFullColor={selectedItem?.renderFullColor}
                      legend={selectedItem?.name}
        />));

      this.previousSelectedItem = selectedItem;

      if (updateTimeBar) {
        window.timeline.setCustomTime(selectedItem.date, TIMELINE_BAR_ID);
      }

      if (focusOnTimeLineSelection) {
        //Disable the double click event (QI-2384)
        window.timeline.off("doubleClick");
        let animation = useAnimation ? {
          duration: 500,
          easingFunction: "easeInOutQuint"
        } : false;
        window.timeline.setSelection(selectedItemId, {
          focus: false,
          animation,
        });
        window.timeline.focus(selectedItemId, {
          zoom: false,
          animation,
        });

        //Enable again the double click event when the animation has finished
        setTimeout(() => {
          window.timeline.on("doubleClick", this.handleTimelineDoubleClick);
        }, 700);
      } else {
        window.timeline.setSelection(selectedItemId, {animation: false});
      }
    }

    window.timeline.redraw();
  }

  updateContent(id, content) {
    const itemToUpdate = this.timelineData.get(UIUtils.parseInt(id));
    itemToUpdate.content = content;
    this.timelineData.update(itemToUpdate);
    this.timelineData.flush();
  }

  /**
   * Computes all of the points that should exist on the timeline.
   *
   * @param historyPoints {[]} An array of objects that represent a change to a single record
   * @param filters {{}} An object that contains the filter values from the filter panel
   * @param selectedTypeaheadOptions {[]} An array of objects that have been selected in the search box
   */
  setTimelineData(historyPoints, filters, selectedTypeaheadOptions) {
    window.itemsToObjects = {};

    // Find the history points
    let historyPointsToDraw = historyPoints.filter(historyPoint => {
      return historyPoint.affectedRiskTypes.includes(this.props.riskType) && shouldRender(historyPoint, filters);
    });
    if (historyPointsToDraw.length > MAX_TIMELINE_ITEMS && !this.state.overrideShowAll) {
      Logger.warn(() => `Showing a maximum of ${MAX_TIMELINE_ITEMS} of ${historyPointsToDraw.length} total items on the timeline`);
      historyPointsToDraw = historyPointsToDraw.slice(historyPointsToDraw.length - MAX_TIMELINE_ITEMS, historyPointsToDraw.length);
      this.setStateSafely({warningMessage: `Only the most recent ${MAX_TIMELINE_ITEMS} changes are shown on the timeline.`});
    }

    for (let historyPoint of historyPointsToDraw) {
      let historyItemSnapshot = historyPoint.historySnapshot.get(historyPoint.type + "-" + historyPoint.id);

      let renderFullColor = shouldRenderFullColor(historyItemSnapshot.rmpForModelType, historyItemSnapshot, filters, selectedTypeaheadOptions, "timeline", this.props.riskType);
      let itemDimensions = RiskMapTimeline.getItemDimensionsFromItemType(historyPoint.type);
      let riskScores = getRiskScores(historyItemSnapshot, this.props.riskType);
      let riskScale = historyPoint.type !== "TPP" && historyPoint.type !== "GA" ? getRiskScale(this.props.riskType, historyItemSnapshot.rmpForModelType, riskScores.rawRiskScore, historyItemSnapshot, showByRiskLabel(filters)) : null;

      if ((historyItemSnapshot.type !== "TPP" && historyItemSnapshot.type !== "GA") ||
        historyItemSnapshot.majorVersion !== 0 || historyItemSnapshot.minorVersion !== 1) {

        const oldItem = this.timelineData.get(historyPoint.uniqueId);
        const color = riskScale ? riskScale.color : RISK_COLORS.NONE;
        if (!oldItem || oldItem.color !== color || oldItem.renderFullColor !== renderFullColor || oldItem.title !== historyPoint.changes) {
          this.timelineData.update({
            id: historyPoint.uniqueId,
            content: ReactDOMServer.renderToStaticMarkup(
              <RiskMapShape type={historyPoint.type}
                            RMP={historyPoint.rmpForModelType}
                            currentState={historyPoint.currentState}
                            itemHeight={itemDimensions.itemHeight}
                            itemWidth={itemDimensions.itemWidth}
                            shapeWidth={itemDimensions.shapeWidth}
                            shapeHeight={itemDimensions.shapeHeight}
                            selected={false}
                            color={color}
                            renderFullColor={renderFullColor}
                            legend={historyPoint.name}
              />),
            start: historyPoint.date,
            title: historyPoint.changes,
            className: "risk-map-timeline-item-container",
            rmpForModelType: historyItemSnapshot.rmpForModelType,
            renderFullColor,
            color,
          });
        }

        window.itemsToObjects[historyPoint.uniqueId] = {
          type: historyPoint.type,
          rawRiskScore: riskScores.rawRiskScore,
          normalizedRiskScore: riskScores.normalizedRiskScore,
          date: historyPoint.date,
          name: historyPoint.name,
          color: color,
          id: historyPoint.id,
          versionId: historyPoint.versionId,
          versionTransitionId: historyPoint.versionTransitionId,
          changes: historyPoint.changes,
          currentState: historyPoint.currentState,
          approved: historyPoint.approved,
          archived: historyPoint.archived,
          restored: historyPoint.restored,
          renderFullColor: renderFullColor,
          rmpForModelType: historyPoint.rmpForModelType
        };
        this.objectIdsToItemIds[historyPoint.type + "-" + historyPoint.versionTransitionId] = historyPoint.uniqueId;
      }
    }

    // Send all of the changes to the timeline, if it's created
    this.timelineData.flush();
  }

  zoomInGraph() {
    window.network.moveTo({
      scale: window.network.getScale() + window.network.getScale() * 0.3,
      animation: {
        duration: 500,
        easingFunction: "easeOutQuint"
      }
    });

    this.props.onAutoSaveReportLayout(false);
  }

  zoomOutGraph() {
    window.network.moveTo({
      scale: window.network.getScale() - window.network.getScale() * 0.3,
      animation: {
        duration: 500,
        easingFunction: "easeOutQuint"
      }
    });

    this.props.onAutoSaveReportLayout(false);
  }

  setTimelineDate(date, focusOnTimeLineSelection, useAnimation) {
    let itemKeys = Object.keys(window.itemsToObjects);
    let selectedItemKey = this.getSelectedItemKey(date);
    let selectedKeyIndex = itemKeys.indexOf(selectedItemKey);
    let isLastItemSelected = (selectedKeyIndex === itemKeys.length - 1);
    this.setStateSafely({
      selectedDate: date
    }, () => {
      this.updateSelectedItem(selectedItemKey, false, focusOnTimeLineSelection, useAnimation);
      this.props.onRiskMapDateChanged(date, isLastItemSelected);
    });
  }

  selectTimelineDate(action, useAnimation) {
    let itemKeys = Object.keys(window.itemsToObjects);
    if (itemKeys.length > 0) {
      let selectedItemId = this.getSelectedItemKey(this.state.selectedDate);
      let selectedKeyIndex = itemKeys.indexOf(selectedItemId ? selectedItemId : itemKeys[itemKeys.length - 1]);
      let selectedItemKey = itemKeys[selectedKeyIndex];
      let selectedItem = window.itemsToObjects[selectedItemKey];
      let lastDateSelected = (selectedKeyIndex === itemKeys.length - 1);

      switch (action) {
        case "first":
          selectedItemKey = itemKeys[0];
          selectedItem = window.itemsToObjects[itemKeys[0]];
          lastDateSelected = (itemKeys.length === 1);
          break;
        case "last":
          selectedItemKey = itemKeys[itemKeys.length - 1];
          selectedItem = window.itemsToObjects[itemKeys[itemKeys.length - 1]];
          lastDateSelected = true;
          break;
        case "previous":
          if (selectedKeyIndex > 0) {
            selectedItemKey = itemKeys[selectedKeyIndex - 1];
            selectedItem = window.itemsToObjects[itemKeys[selectedKeyIndex - 1]];
            lastDateSelected = false;
          }
          break;
        case "next":
          if (selectedKeyIndex < itemKeys.length - 1) {
            selectedItemKey = itemKeys[selectedKeyIndex + 1];
            selectedItem = window.itemsToObjects[itemKeys[selectedKeyIndex + 1]];
            lastDateSelected = ((selectedKeyIndex + 1 === itemKeys.length - 1));
            break;
          }
      }

      this.updateSelectedItem(selectedItemKey, true, true, useAnimation);
      this.setStateSafely({
        selectedDate: selectedItem.date
      }, () => {
        this.props.onRiskMapDateChanged(this.state.selectedDate, lastDateSelected);
      });
    }
  }

  getSelectedItemKey(selectedDate) {
    let selectedItemKey;
    if (selectedDate) {
      let filteredByDateItemKeys = Object.keys(window.itemsToObjects).filter(key => {
        return window.itemsToObjects[key].date.getTime() <= selectedDate.getTime();
      });
      selectedItemKey = filteredByDateItemKeys.length > 0 ? filteredByDateItemKeys[filteredByDateItemKeys.length - 1] : null;
    }

    return selectedItemKey;
  }

  addCustomTimeBars(timeline) {
    // Add the regular timeline to select the date the Risk Map report is being viewed at
    timeline.addCustomTime(this.state.selectedDate, TIMELINE_BAR_ID);
    // Add a timeline bar for the date the project was created
    timeline.addCustomTime(this.props.mapHistory.project.createdAt, "risk-map-timeline-custom-time-project");
    // Add one timeline bar for each approved RMP version
    for (let rmpApprovedVersion of this.props.rmpVersions) {
      timeline.addCustomTime(rmpApprovedVersion.createdAt, `risk-map-timeline-rmp-custom-time rmp${rmpApprovedVersion.id}`);
    }
  }

  handleTimeChange(event) {
    this.setTimelineDate(event.time, false, false);
  }

  handleTimelineDoubleClick(event) {
    this.setTimelineDate(event.time, false, false);
    window.timeline.setCustomTime(event.time, TIMELINE_BAR_ID);
  }

  render() {
    return (
      <div id="riskMapTimelineContainer" className="risk-map-timeline-container">
        <div className="risk-map-timeline-show-button-div generic-map-button"
             id="riskMapTimelineShowButtonDiv"
        >
          <button id="showHistoryTimelineButton"
                  type="button"
                  className="btn btn-primary"
                  aria-label="Show/Hide timeline"
                  aria-expanded="false"
                  aria-controls="riskMapTimelinePanel"
                  data-toggle="collapse"
                  data-target="#riskMapTimelinePanel"
                  onClick={this.showHideTimeline}
          >
            <FontAwesomeIcon icon={faClock} />
          </button>
        </div>
        <div className="risk-map-graph-zoom-in-button-div generic-map-button"
             id="riskMapGraphZoomInButtonDiv"
        >
          <button id="riskMapGraphZoomInButton"
                  type="button"
                  className="btn btn-secondary btn-sm"
                  aria-label="Zoom into timeline"
                  onClick={this.zoomInGraph}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
        <div className="risk-map-graph-zoom-out-button-div generic-map-button"
             id="riskMapGraphZoomOutButtonDiv"
        >
          <button id="riskMapGraphZoomOutButton"
                  type="button"
                  className="btn btn-secondary btn-sm"
                  aria-label="Zoom out of timeline"
                  onClick={this.zoomOutGraph}
          >
            <FontAwesomeIcon icon={faMinus} />
          </button>
        </div>
        <div id="riskMapTimelinePanel"
             className="risk-map-timeline-panel risk-map-opacity-transition collapse"
        >
          <div className={"risk-map-timeline-warning-div alert alert-warning" + (this.state.warningMessage ? "" : " d-none")}
               id="riskMapTimelineWarningDiv"
          >
            {this.state.warningMessage}
            {this.state.warningMessage ?
              <a onClick={this.handleShowAll}
                 className="risk-map-timeline-warning-show-all"
              >Show all</a>
              : ""}
          </div>
          <div id="riskMapTimelineCloseButtonDiv"
               className="risk-map-timeline-close-button-div"
          >
            <button id="closeHistoryTimelineButton"
                    type="button"
                    className="btn btn-secondary btn-sm btn-close"
                    aria-label="Hide timeline"
                    aria-expanded="false"
                    aria-controls="riskMapTimelinePanel"
                    data-toggle="collapse"
                    data-target="#riskMapTimelinePanel"
                    onClick={this.showHideTimeline}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div id="riskMapTimelineContent"
               className="risk-map-timeline-content"
          >
            <div className="risk-map-timeline">

              <div className="risk-map-timeline-first-button-div generic-map-button"
                   id="riskMapTimelineFirstButtonDiv"
              >
                <button id="riskMapTimelineFirstButton"
                        type="button"
                        className="btn btn-secondary btn-sm"
                        aria-label="Select the beginning of time"
                        onClick={this.selectTimelineDate.bind(this, "first", true)}
                >
                  <FontAwesomeIcon icon={faFastBackward} />
                </button>
              </div>
              <div className="risk-map-timeline-previous-button-div generic-map-button"
                   id="riskMapTimelinePreviousButtonDiv"
              >
                <button id="riskMapTimelinePreviousButton"
                        type="button"
                        className="btn btn-secondary btn-sm"
                        aria-label="Select the previous date"
                        onClick={this.selectTimelineDate.bind(this, "previous", true)}
                >
                  <FontAwesomeIcon icon={faBackward} />
                </button>
              </div>
              <div className="risk-map-timeline-next-button-div generic-map-button"
                   id="riskMapTimelineNextButtonDiv"
              >
                <button id="riskMapTimelineNextButton"
                        type="button"
                        className="btn btn-secondary btn-sm"
                        aria-label="Move to the next date"
                        onClick={this.selectTimelineDate.bind(this, "next", true)}
                >
                  <FontAwesomeIcon icon={faForward} />
                </button>
              </div>
              <div className="risk-map-timeline-last-button-div generic-map-button"
                   id="riskMapTimelineLastButtonDiv"
              >
                <button id="riskMapTimelineLastButton"
                        type="button"
                        className="btn btn-secondary btn-sm"
                        aria-label="Move to the end of time"
                        onClick={this.selectTimelineDate.bind(this, "last", true)}
                >
                  <FontAwesomeIcon icon={faFastForward} />
                </button>
              </div>
              <div className="risk-map-timeline-scale-to-fit-button-div generic-map-button"
                   id="riskMapTimelineScaleToFitButtonDiv"
              >
                <button id="riskMapTimelineFitButton"
                        type="button"
                        className="btn btn-secondary btn-sm"
                        aria-label="Scale to fit timeline"
                        onClick={RiskMapTimeline.scaleToFitTimeline}
                >
                  <FontAwesomeIcon icon={faExpandArrowsAlt} />
                </button>
              </div>
              {this.state.showLoading ? (
                <div className="risk-map-timeline-loading-panel">
                  <img height={128} width={128}
                       className="risk-map-timeline-loading-img"
                       src={loadingImg}
                       alt="Loading..."
                  />
                </div>
              ) : ""}
              <div id="riskMapTimeline"
                   className="risk-map-timeline"
                   ref={riskMapTimelineDiv => this.riskMapTimelineDiv = riskMapTimelineDiv}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
