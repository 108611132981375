"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import TextAttribute from "../../editor/attributes/text_attribute";
import ComboBoxAttribute from "../../editor/attributes/combo_box_attribute";
import TextAreaAttribute from "../../editor/attributes/text_area_attribute";
import { EDITOR_TYPES } from "../../editor/editor_constants";
import TypeaheadAttribute from "../../editor/attributes/typeahead_attribute";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseAssetAttributePage from "../../editor/base_asset_attribute_page";
import Section from "../../editor/widgets/section";
import * as CommonEditables from "../../../server/common/editables/common_editables";
import MultiDocumentsRiskLinksAttribute from "../../editor/attributes/multi_documents_risk_links_attribute";
import { LibrarySpecificationStatusBar } from "../../library/widgets/library_specification_status_bar";
import * as FailHandlers from "../../utils/fail_handlers";

/**
 * This is the class that renders the Add/Edit/View Material Attribute editor in the process explorer.
 */
// i18next-extract-mark-ns-start process_explorer
class MaterialAttribute extends BaseAssetAttributePage {
  constructor(props) {
    super(props, "materialAttribute", "MaterialAttribute", "Material Attribute (MA)");

    // Bind stuff
    this.getHTMLURLPrefix = MaterialAttribute.getHTMLURLPrefix;
    this.checkLibraryMaterial();
  }

  componentDidMount() {
    document.title = "QbDVision Material Attribute (MA)";

    super.componentDidMount();
  }

  checkLibraryMaterial() {
    this.setStateSafely({
      hasLibraryMaterial: true
    });

    UIUtils.secureAjaxGET(
      `materialLibrary/hasData`,
      {},
      true,
      FailHandlers.defaultStopLoadingFailFunction.bind(this)
    ).then(results => {
      this.setStateSafely(results);
    });
  }

  static getHTMLURLPrefix() {
    return "/processExplorer/materialAttributes";
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
  }

  renderLibrarySection() {
    if (this.isAdd()) {
      return (<Fragment />);
    }

    return (<LibrarySpecificationStatusBar record={this.state}
                                           isDiffingVersions={this.isDiffingVersions()}
                                           olderVersion={this.getOlderVersion()}
    />);
  }

  getInfoTooltip() {
    return (
      <InfoTooltip id="infoPP"
                   verbiage={
                     this.isParentAMaterial() ? (
                       <div>
                         A Material Attribute (MA) is a measurable characteristic (ex. pH, Alkalinity, % of weight to volume
                         (% w/v)) of a Material.
                       </div>
                     ) : (
                       <div>
                         A Material Attribute (MA) is a measurable characteristic (ex. Reactive / Non-reactive) of a Process
                         Component.
                       </div>
                     )
                   }
      />
    );
  }

  renderRiskLinksAttribute() {
    return (
      <div className="row">
        <MultiDocumentsRiskLinksAttribute name="risk"
                                          key={`${this.getParentModelName()}-RiskLinks`}
                                          linkToObjectDisplayName="Intermediate or Final Attributes"
                                          linkToObject={["IPA", "IQA", "FPA", "FQA"]}
                                          linkObject={["MaterialAttributeToIPA", "MaterialAttributeToIQA", "MaterialAttributeToFPA", "MaterialAttributeToFQA"]}
                                          displayName="Risk"
                                          optionsFilter={this.filterRiskLinkTypeaheadOptions}
                                          className="col-sm-12"
                                          ref={(riskTable) => this.setRiskTableToState(riskTable)}
                                          isLoading={!this.state.RMP || !this.state.ProjectWithAllVersions || this.isLoading() || this.isLoadingRiskLinks()}
                                          modelName={this.baseTypeName}
                                          RMP={this.state.RMP}
                                          projectWithAllVersions={this.state.ProjectWithAllVersions}
                                          parent={this}
                                          triggerChildUpdate={this.state.triggerChildUpdate}
                                          onForceValidation={this.triggerFormValidation}
                                          onValidate={this.handleRiskVerification}
                                          onDelete={this.handleDelete}
                                          onSave={this.handleRiskLinkSave}
                                          risksFromAllRecords={this.state.risksFromAllRecords}
        />
      </div>
    );
  }

  filterSteps(option) {
    return option.UnitOperationId === this.state.UnitOperationId;
  }

  renderControlStrategyField() {
    return (
      <TypeaheadAttribute name="controlStrategy"
                          options={CommonEditables.MATERIAL_ATTRIBUTE_CONTROL_STRATEGY_OPTIONS}
                          parent={this}
                          isLoading={this.state.isLoading}
                          parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                          parentId={this.state.id}
                          multiple={true}
                          tooltipText={(<div>
                            <li>
                              <b>Characterization</b> - Variable requires additional characterization to determine interactions, design space, optimal target and range, etc.
                            </li>
                            <li>
                              <b>Monitoring (COA)</b> - Attribute values are tracked from the COAs received for each lot.
                            </li>
                            <li>
                              <b>Monitoring (Test Upon Receipt)</b> - Attribute values are determined from testing of material after receipt from supplier.
                            </li>
                            <li>
                              <b>Other</b>
                            </li>
                            <li>
                              <b>Not Required</b> - No control strategy is required for this variable or attribute.
                            </li>
                          </div>)}
      />
    );
  }

  renderGeneralSection() {
    return <Section parent={this}
                    header={this.getGeneralHeader()}
                    collapsible={false}
    >
      <div className="row">
        <TextAttribute name="name"
                       parent={this}
        />
        <TypeaheadAttribute name="process"
                            default={this.getProcessId()}
                            typeaheadType="Process"
                            target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                            instructions={`This can only be modified on add.`}
                            disabled={!this.isAdd()}
                            parent={this}
                            isLoading={this.state.isLoading}
                            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                            parentId={this.state.id}
                            projectId={this.getProjectId()}
                            processId={this.getProcessId()}
        />
      </div>
      <div className="row">
        <TypeaheadAttribute name="unitOperation"
                            typeaheadType="UnitOperation"
                            target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                            onValidate={this.handleUOVerification}
                            parent={this}
                            isLoading={this.state.isLoading}
                            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                            parentId={this.state.id}
                            projectId={this.getProjectId()}
                            processId={this.getProcessId()}
        />
        {this.renderParentAttribute()}
      </div>
      <div className="row">
        <TypeaheadAttribute name="step"
                            typeaheadType="Step"
                            target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                            onValidate={this.handleStepVerification}
                            filter={this.filterSteps}
                            parent={this}
                            isLoading={this.state.isLoading}
                            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                            parentId={this.state.id}
                            projectId={this.getProjectId()}
                            processId={this.getProcessId()}
                            relatedRecordId={this.state.UnitOperationId}
        />
        <ComboBoxAttribute name="type"
                           options={["Physical",
                             "Chemical",
                             "Biological",
                             "Microbiological"]}
                           default="Chemical"
                           parent={this}
                           isLoading={this.state.isLoading}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           parentId={this.state.id}
        />
      </div>
      <div className="row">
        <TextAreaAttribute name="description"
                           className="col-sm-12"
                           parent={this}
        />
      </div>
    </Section>;
  }
}

export default I18NWrapper.wrap(MaterialAttribute, ["process_explorer", "editor"]);
// i18next-extract-mark-ns-stop process_explorer
