"use strict";

import React from "react";
import BaseReactComponent from "../../../base_react_component";
import { GridColumn } from "@progress/kendo-react-grid";
import {
  EXTRA_LARGE_COLUMN,
  TEXT_COLUMN,
} from "../configurable_tables_constants";
import * as UIUtils from "../../../ui_utils";
import { FILTER_TYPES } from "../../columnOperations/configurable_tables_column_operations_constants";
import { FIELD_PROPS } from "../../fieldsConfig/constants/configurable_tables_field_props_config";
import { HeaderCell } from "../cells/header_cell";
import { LinksCell } from "../cells/links_cell";
import { CommonUtils } from "../../../../server/common/generic/common_utils";

export default class ConfigurableTablesColumnGeneratorBase extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.props = props;

    const {columnOperationsAdapter} = this.props;
    this.getColumnOperationsProps = columnOperationsAdapter.getColumnOperationsProps;
    this.adaptRiskFilterName = columnOperationsAdapter.adaptRiskFilterName;
  }

  generateColumn(field, prop, filterType = FILTER_TYPES.TEXT, enableColumnOperations = true) {
    const {title} = field;
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  title={title}
                  key={prop}
                  field={prop}
                  headerCell={(props) => this.columnHeaderCell(props, prop === FIELD_PROPS.UNIT_OPERATION || prop === FIELD_PROPS.STEP)}
                  headerClassName={`column-header ${UIUtils.convertToCamelCaseId(prop)}`}
                  cell={(props) => this.textCreatedCell(props, title, prop, filterType)}
                  {...this.getColumnOperationsProps(enableColumnOperations, filterType, this.props, prop)}
      />
    );
  }

  generateColumns(fields, enableColumnOperations = true) {
    const filteredProps = fields.map(field => field.prop);
    const {visibleTableColumns} = this.props;
    const visibleColumns = visibleTableColumns
      .filter(column => column.checked && filteredProps.includes(column.prop));
    const generatedColumns = [];

    for (const field of visibleColumns || []) {
      const {prop, tableValue, filterType} = field;
      const generatedColumn = tableValue ? tableValue(this, enableColumnOperations) :
        this.generateColumn(field, prop, filterType, enableColumnOperations);
      generatedColumns.push({
        ...generatedColumn,
        props: {
          ...generatedColumn.props,
          // colspan: field.colspan,
        }
      });
    }
    return this.generateColSpans(generatedColumns);
  }

  generateColSpans(columns) {
    const processColumns = [];
    const processedColSpans = [];

    for (let i = 0; i < columns.length; i++) {
      let column = columns[i];
      const colspan = column.props.colspan;

      if (processedColSpans.includes(colspan)) {
        continue;
      }

      if (colspan) {
        column = (
          <GridColumn key={UIUtils.convertToCamelCaseId(colspan)}
                      headerClassName="text-center column-header"
                      title={colspan}
          >
            {columns.filter(visibleColumn => visibleColumn.props.colspan === colspan)}
          </GridColumn>
        );
        processedColSpans.push(colspan);
      }
      processColumns.push(column);
    }
    return processColumns;
  }

  generateParentColumn(title, prop) {
    const className = "column-header";
    const filterType = FILTER_TYPES.LOOKUP;
    const isSortingByOrder = true;

    return (
      <GridColumn width={TEXT_COLUMN}
                  key={prop}
                  title={title}
                  field={prop}
                  headerCell={(props) => this.columnHeaderCell(props, isSortingByOrder)}
                  headerClassName={`${className} ${prop}`}
                  cell={(event) => this.createParentCell(event.dataItem, prop)}
                  {...this.getColumnOperationsProps(true, filterType)}
      />
    );
  }

  createParentCell(dataItem, prop) {
    const parents = dataItem[`sorted${CommonUtils.capitalize(CommonUtils.pluralize(prop))}`] || [];
    return (
      <LinksCell links={parents} />
    );
  }

  columnHeaderCell(props, isSortingByOrder = false) {
    return (
      <HeaderCell {...props} isSortingByOrder={isSortingByOrder} />
    );
  }

}
