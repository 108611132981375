"use strict";

import * as UIUtils from "../ui_utils";
import React, { Fragment } from "react";
import ImportPageTitleBar from "../widgets/pageTitleBar/import_page_title_bar";
import BasePage from "../base_page";
import ImportResultsPanel from "./widgets/general/import_results_panel";
import * as ImportHelper from "./helpers/import_helper";
import ImportWizard from "./widgets/general/import_wizard";
import ErrorBar from "../widgets/bars/error_bar";
import { getProjectFromCache } from "../utils/project_helper";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";
import { getImportConfigFromKey, IMPORT_TYPE_KEY } from "./constants/import_constants";
import * as ProcessCache from "../processExplorer/process/process_cache";
import { IMPORT_ACTION } from "../../server/common/editables/common_batches";
import CommonUtils from "../../server/common/generic/common_utils";
import { getURLByTypeCodeAndId } from "../helpers/url_helper";
import { LIBRARY_MATERIAL_TYPE_CODE } from "../library/library_constants";


export default class ImportFilePage extends BasePage {
  constructor(props) {
    super(props);

    let importKey = UIUtils.getParameterByName("key");
    const importConfig = importKey ? CommonUtils.deepClone(getImportConfigFromKey(importKey)) : {};

    importConfig.projectId = UIUtils.getParameterByName("projectId");
    importConfig.processIdFromURLParam = UIUtils.getParameterByName("processId");
    importConfig.batchIdFromURLParam = UIUtils.getParameterByName("batchId");
    importConfig.projectType = UIUtils.getParameterByName("projectType");
    importConfig.allowProcessSelection = UIUtils.getParameterByName("allowProcessSelection") === "true";
    importConfig.selectedTab = UIUtils.getParameterByName("selectedTab");
    importConfig.modelName = UIUtils.stripAllWhitespaces(importConfig.modelName);
    importConfig.modelNameForDisplay = ImportHelper.getModelNameForDisplay(importConfig.modelName);
    importConfig.modelNameForSecurity = importConfig.dataSectionName === "Process Capability"
      ? "Batch"
      : UIUtils.convertCamelCaseToSpacedOutWords(importConfig.modelName);

    this.state = {
      importConfig,
      isResultScreen: false,
      dependencyRecords: [{id: "", name: ""}],
      importResults: {
        importedRecords: [],
      },
      fileData: {fileName: ""},
    };

    if (importConfig.projectId) {
      this.handleSelectedProcessChanged();
    } else {
      importConfig.allowProcessSelection = false;
      importConfig.processIdFromURLParam = null;
      this.loadDepenciesFromServer();
    }

    if (importKey === IMPORT_TYPE_KEY.LIBRARY_COA) {
      this.setStateSafely({
        breadcrumb: {
          current: "Import CoA",
          parent: "Library",
          parentLink: "/library/list.html",
        },
      });
    } else {
      this.setStateSafely({
        breadcrumb: {
          current: `${UIUtils.convertCamelCaseToSpacedOutWords(this.state.importConfig.modelName)}  Data`,
          parent: "Import",
          parentLink: "/import/importDashboard.html",
        },
      });
    }
  }

  componentDidMount() {
    document.title = "QbDVision Import";

    super.componentDidMount();
  }

  handleSelectedProcessChanged(process = null) {
    const {importConfig} = this.state;
    const {
      dependency, dataSectionName, projectId, processIdFromURLParam, selectionTableModelTypes, importContinuousData,
      isPaperImport,
    } = importConfig;

    const defaultProcessId = (process && process.id)
      || (processIdFromURLParam && UIUtils.parseInt(processIdFromURLParam))
      || (projectId && ProcessCache.getProcessIdUsedRecently(projectId));

    new TypeaheadObjectCache("Project").loadOptions(this.forceUpdateSafely);
    if (projectId) {
      new TypeaheadObjectCache("Process", projectId).loadOptions(processes => {
        let process = processes.find(process => process.id === defaultProcessId) || processes[0];

        this.setStateSafely({processes, process}, () => {
          if (dependency && dataSectionName) {
            UIUtils.secureAjaxGET(`import/get/getProcessCapabilityDataAttributes`, {
              projectId,
              processId: this.state.process.id,
              modelsToLoad: typeof selectionTableModelTypes === "string"
                ? selectionTableModelTypes
                : JSON.stringify(selectionTableModelTypes),
              importContinuousData,
              isPaperImport,
            }, true).done(this.handleDependencyReceivedFromServer);
          }
        });
      });
    }
  }

  loadDepenciesFromServer() {
    const {importConfig} = this.state;
    const {
      dependency, dataSectionName, selectionTableModelTypes, importContinuousData, isPaperImport,
    } = importConfig;

    if (!dependency || !dataSectionName) {
      return;
    }

    UIUtils.secureAjaxGET(`import/get/getProcessCapabilityDataAttributes`, {
      modelsToLoad: typeof selectionTableModelTypes === "string"
        ? selectionTableModelTypes
        : JSON.stringify(selectionTableModelTypes),
      importContinuousData,
      isPaperImport,
    }, true).done(this.handleDependencyReceivedFromServer);
  }

  handleDependencyReceivedFromServer(result) {
    const {project, process} = result;
    result = result.instances.map(record => {
      return {
        ...record,
        id: `${record.typeCode}-${record.id}`,
      };
    });

    let selectedDependencyRecordId = UIUtils.getParameterByName("selectedDependencyRecordId");
    let selectedDependencyRecord = null;
    if (selectedDependencyRecordId) {
      selectedDependencyRecord = result.find(record => UIUtils.parseKey(record.id).id === UIUtils.parseInt(selectedDependencyRecordId));

      if (selectedDependencyRecord) {
        selectedDependencyRecord.getInternalId = () => UIUtils.parseInt(selectedDependencyRecord.id.replace(selectedDependencyRecord.typeCode + "-", ""));
      }

      if (this.state.importConfig.key === IMPORT_TYPE_KEY.LIBRARY_COA && selectedDependencyRecord) {
        this.setStateSafely({
          breadcrumb: {
            current: "Import CoA",
            parent: selectedDependencyRecord.name,
            parentLink: getURLByTypeCodeAndId(LIBRARY_MATERIAL_TYPE_CODE, "View", selectedDependencyRecord.getInternalId(), true),
          },
        });
      }
    }

    this.setStateSafely({
      dependencyRecords: result,
      selectedDependencyRecord,
      project,
      process,
    });
  }

  handleImportCompleted(fileData, importResults) {
    this.setStateSafely({
      fileData,
      importResults,
      isResultScreen: true,
    });
  }

  renderPage() {
    const {importConfig} = this.state;
    let {
      projectId, projectType, processIdFromURLParam, dataSectionName, modelNameForDisplay, selectedTab, modelName,
      dependency, importContinuousData, isPaperImport,
    } = importConfig;

    let {
      isResultScreen,
      importResults,
      batches,
      fileData,
      dependencyRecords,
      selectedDependencyRecord,
      project,
      process,
      processes,
    } = this.state;

    let thisProject = getProjectFromCache(projectId);
    const projectLabel = thisProject ? UIUtils.getRecordLabelForDisplay("PRJ", thisProject.id, thisProject.name) : "";

    let headerTitle = `Import new
    ${dataSectionName ? `${UIUtils.uncapitalizeAllText(dataSectionName)} data` : modelNameForDisplay}
    ${dependency ? ` for ${UIUtils.uncapitalizeAllText(CommonUtils.convertCamelCaseToSpacedOutWords(dependency))}` : ""} 
    ${projectLabel ? `to ${projectLabel}` : ""}`;

    if (importConfig.key === IMPORT_TYPE_KEY.LIBRARY_COA && selectedDependencyRecord) {
      headerTitle = "Import Certificate of Analysis for " + selectedDependencyRecord.name;
    }

    return (
      <div>
        <ImportPageTitleBar name={headerTitle}
                            importDataRecords={!!projectId}
                            projectId={projectId}
                            processId={processIdFromURLParam}
                            projectType={projectType}
                            importConfig={importConfig}
                            selectedDependencyRecord={selectedDependencyRecord}
                            selectedTab={selectedTab}
        />
        <div className="container-spacer" />
        <div className="container import-wizard-div">
          {!isResultScreen ?
            <ImportWizard importConfig={importConfig}
                          importType={IMPORT_ACTION.APPEND}
                          project={project}
                          processes={processes}
                          process={process}
                          dependencyRecords={dependencyRecords}
                          selectedDependencyRecord={selectedDependencyRecord}
                          onImportCompleted={this.handleImportCompleted}
                          onSelectedProcessChanged={this.handleSelectedProcessChanged}
            />
            : (
              <Fragment>
                <div className="import-results-div">
                  <ErrorBar className="alert alert-danger d-none import-error-bar" />
                  <ImportResultsPanel dataSectionName={dataSectionName}
                                      projectId={projectId}
                                      processId={processIdFromURLParam}
                                      modelName={modelName}
                                      modelNameForDisplay={modelNameForDisplay}
                                      dependency={dependency}
                                      selectedDependencyRecord={selectedDependencyRecord}
                                      isPaperImport={isPaperImport}
                                      importResults={importResults}
                                      batches={batches}
                                      importContinuousData={importContinuousData}
                                      showImportCompleteLabel={true}
                                      fileData={fileData}
                  />
                </div>
              </Fragment>
            )
          }
        </div>
      </div>
    );
  }
}
