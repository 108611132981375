"use strict";

import RiskUtils from "../../../../server/common/misc/common_risk_utils";
import { getRiskScale, getTableCellClassFromRiskScore } from "../../../helpers/risk_helper";
import React, { memo } from "react";
import * as styles from "./style/risk_cell.module.scss";
import { RiskIcon } from "./widgets/risk_icon";
import { getColorCode } from "./cells_helper";

export const RiskCell = memo(({riskType, prop, dataItem, className, adaptRiskFilterName}) => {
  const {typeCode, effectiveModelRMP} = dataItem;

  const riskValue = effectiveModelRMP && !isNaN(parseFloat(dataItem[prop]))
    ? RiskUtils.parseRiskValue(parseFloat(dataItem[prop]), riskType, effectiveModelRMP)
    : null;

  if (riskValue) {
    const rawRiskValue = dataItem[adaptRiskFilterName(prop)]
      ? RiskUtils.parseRiskValue(parseFloat(dataItem[adaptRiskFilterName(prop)]), riskType, effectiveModelRMP)
      : null;

    const riskColorClass = rawRiskValue
      ? getTableCellClassFromRiskScore(riskType, effectiveModelRMP, rawRiskValue, dataItem, true)
      : null;

    const riskScale = rawRiskValue
      ? getRiskScale(riskType, effectiveModelRMP, rawRiskValue, dataItem, true)
      : null;

    const [color, backgroundColor] = getColorCode(riskColorClass);

    return (
      <td>
        <div className={styles["risk-pill-container"]}
             style={{backgroundColor}}
        >
          <RiskIcon typeCode={typeCode}
                    width={12}
                    height={12}
                    color={color}
          />
          <span className={styles["label"]}>
            {`${riskValue} · ${prop.includes("Perc") ? "%" : ""}` || ""}
          </span>
          <span className={styles["label"]}>
            {riskScale ? riskScale.riskLabel || riskScale.scoreLabel : ""}
          </span>
        </div>
      </td>
    );
  }

  return (
    <td className={`${className}`}>
      <span
        className={`${styles["risk-pill-container"]} ${styles["label"]} ${styles["not-assessed-label"]}`}
      >
        {RiskUtils.NOT_ASSESSED_LABEL}
      </span>
    </td>
  );
});