import { FIELD_KEY } from "../../../common/editables_map";
import { DocBuilderFieldsParser } from "./doc_builder_fields_parser";
import { SupplierParser } from "./supplier_parser";
import { ControlMethodParser } from "./control_method_parser";
import { CALCULATED_RISK_ATTRIBUTES } from "../../../../../server/common/generic/common_constants";
import { DocumentLinkParser, isLinkAttribute } from "./document_link_parser";
import { CalculatedRiskAttributesParser } from "./calculated_risk_attributes_parser";
import { CriticalityParser } from "./criticality_parser";
import { AcceptanceCriteriaRangeParser } from "./acceptance_criteria_range_parser";

export class ElementParserFactory {
  static getParser(
    globalNode,
    parentNode,
    node,
    data,
    fileType,
    rmp,
    project,
    parseOnly
  ) {
    const MODEL_TO_PARSER = {
      [FIELD_KEY.DocBuilderFields]: DocBuilderFieldsParser,
      "Criticality": CriticalityParser,
      "AcceptanceCriteriaRange": AcceptanceCriteriaRangeParser,
    };

    const COLUMN_TO_PARSER = {
      supplier: SupplierParser,
      controlMethod: ControlMethodParser,
    };

    const parts = node.innerText.split(".");
    let parentModelName, modelName, columnName;
    if (parts.length >= 3) {
      [parentModelName, modelName, columnName] = parts;
    } else {
      [modelName, columnName] = parts;
    }
    let Parser =
      COLUMN_TO_PARSER[columnName] || MODEL_TO_PARSER[modelName] || null;
    if (isLinkAttribute(modelName)) {
      Parser = DocumentLinkParser;
    } else if (!parentModelName && CALCULATED_RISK_ATTRIBUTES.includes(columnName)) {
      Parser = CalculatedRiskAttributesParser;
    }

    if (!Parser) {
      return null;
    }

    return new Parser(
      globalNode,
      parentNode,
      node,
      data,
      fileType,
      rmp,
      project,
      null,
      parseOnly
    );
  }
}
