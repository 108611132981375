var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { guid } from '@progress/kendo-react-common';
import { GridColumnMenuWrapper } from '../columnMenu/GridColumnMenuWrapper';
/**
 * @hidden
 */
var GridHeaderSelectionCell = /** @class */ (function (_super) {
    __extends(GridHeaderSelectionCell, _super);
    function GridHeaderSelectionCell() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._inputId = guid();
        return _this;
    }
    GridHeaderSelectionCell.prototype.render = function () {
        var _this = this;
        var columnMenuWrapperProps = this.props.columnMenuWrapperProps;
        var defaultRendering = [
            (React.createElement("input", { key: 0, checked: this.props.selectionValue, id: this._inputId, type: "checkbox", className: "k-checkbox k-checkbox-md k-rounded-md", onChange: function (e) {
                    return _this.props.selectionChange({
                        field: _this.props.field,
                        syntheticEvent: e
                    });
                } })),
            (React.createElement("label", { key: 1, className: "k-checkbox-label", htmlFor: this._inputId })),
            columnMenuWrapperProps.columnMenu && React.createElement(GridColumnMenuWrapper, __assign({}, columnMenuWrapperProps))
        ];
        return this.props.render ?
            this.props.render.call(undefined, defaultRendering, this.props) :
            defaultRendering;
    };
    return GridHeaderSelectionCell;
}(React.Component));
export { GridHeaderSelectionCell };
