var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { getter, classNames, dispatchEvent } from '@progress/kendo-react-common';
import { InternalTile } from './InternalTile';
/**
 * @hidden
 */
var AUTO_FLOW_CLASSES = {
    'column': 'k-grid-flow-col',
    'row': 'k-grid-flow-row',
    'column dense': 'k-grid-flow-col-dense',
    'row dense': 'k-grid-flow-row-dense',
    'unset': 'k-grid-flow-unset'
};
/**
 * Represents the [KendoReact TileLayout component]({% slug overview_tilelayout %}).
 */
var TileLayout = /** @class */ (function (_super) {
    __extends(TileLayout, _super);
    function TileLayout() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * @hidden
         */
        _this._element = null;
        /**
         * @hidden
         */
        _this.state = { positions: (_this.props.items || []).map(function (p, i) { return (Object.assign({ order: i, rowSpan: 1, colSpan: 1 }, p.defaultPosition)); }) };
        /**
         * @hidden
         */
        _this.focus = function () {
            if (_this._element) {
                _this._element.focus();
            }
        };
        /**
         * @hidden
         */
        _this.update = function (index, dOrder, dCol, dRowSpan, dColSpan) {
            if (dRowSpan === void 0) { dRowSpan = 0; }
            if (dColSpan === void 0) { dColSpan = 0; }
            if (dOrder === 0 && dCol === 0 && !dColSpan && !dRowSpan) {
                return;
            }
            var shouldUpdate = false;
            var positions = _this.state.positions.map(function (p) { return Object.assign({}, p); });
            // reordering:
            var current = positions[index];
            var other = positions.find(function (item) { return (item.order === current.order + dOrder); });
            if (other && other !== current) {
                current.order += dOrder;
                other.order += -dOrder;
                shouldUpdate = true;
            }
            var proposedCol = current.col + dCol;
            if (dCol !== 0 && proposedCol >= 1 && proposedCol + current.colSpan <= (_this.props.columns || 3) + 1) {
                current.col = proposedCol;
                shouldUpdate = true;
            }
            // resizing:
            var proposedColSpan = current.colSpan + dColSpan;
            if (dColSpan && proposedColSpan >= 1 && proposedColSpan + current.col <= (_this.props.columns || 3) + 1) {
                current.colSpan = proposedColSpan;
                shouldUpdate = true;
            }
            var proposedRowSpan = current.rowSpan + dRowSpan;
            if (dRowSpan && proposedRowSpan >= 1) {
                current.rowSpan = proposedRowSpan;
                shouldUpdate = true;
            }
            if (shouldUpdate) {
                _this.setState({ positions: positions });
                dispatchEvent(_this.props.onReposition, {}, _this, { value: positions });
            }
        };
        return _this;
    }
    Object.defineProperty(TileLayout.prototype, "element", {
        /**
         * Gets the HTML element of the TileLayout component.
         */
        get: function () {
            return this._element;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    TileLayout.getDerivedStateFromProps = function (props, state) {
        if (props.positions) {
            // The component is in controlled mode.
            return {
                positions: props.positions.map(function (p, i) { return (Object.assign({ order: i, rowSpan: 1, colSpan: 1 }, p)); })
            };
        }
        if (props.items && (!state.positions || (props.items.length !== state.positions.length))) {
            // The component is in uncontrolled mode.
            return {
                positions: props.items.map(function (p, i) { return (Object.assign({ order: i, rowSpan: 1, colSpan: 1 }, p.defaultPosition)); })
            };
        }
        return null;
    };
    TileLayout.prototype.render = function () {
        var _this = this;
        var _a = this.props, className = _a.className, _b = _a.columns, columns = _b === void 0 ? 3 : _b, _c = _a.columnWidth, columnWidth = _c === void 0 ? '1fr' : _c, gap = _a.gap, _d = _a.rowHeight, rowHeight = _d === void 0 ? '1fr' : _d, style = _a.style, _e = _a.autoFlow, autoFlow = _e === void 0 ? 'column' : _e, _f = _a.items, items = _f === void 0 ? [] : _f;
        var gapValue = gap
            ? "".concat(typeof gap.rows === 'number' ? gap.rows + 'px' : gap.rows) + ' ' + "".concat(typeof gap.columns === 'number' ? gap.columns + 'px' : gap.columns)
            : 16;
        var tileLayoutStyles = __assign({ gridTemplateColumns: "repeat(".concat(columns, ", minmax(0px, ").concat(typeof columnWidth === 'number' ? columnWidth + 'px' : columnWidth, "))"), gridAutoRows: "minmax(0px, ".concat(typeof rowHeight === 'number' ? rowHeight + 'px' : rowHeight, ")"), gap: gapValue, padding: gapValue }, style);
        return (React.createElement("div", { ref: function (el) { _this._element = el; }, dir: this.props.dir, className: classNames('k-tilelayout', AUTO_FLOW_CLASSES[autoFlow], className), style: tileLayoutStyles, id: this.props.id, children: items.map(function (tile, index) {
                return (React.createElement(InternalTile, { key: _this.props.dataItemKey ? getter(_this.props.dataItemKey)(tile) : index, update: _this.update, defaultPosition: _this.state.positions[index], index: index, resizable: tile.resizable, reorderable: tile.reorderable, style: tile.style, className: tile.className, hintClassName: tile.hintClassName, hintStyle: tile.hintStyle, ignoreDrag: _this.props.ignoreDrag }, tile.item ? tile.item : (React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "k-tilelayout-item-header k-card-header" }, React.isValidElement(tile.header) ? tile.header : React.createElement("h5", { className: 'k-card-title' }, tile.header)),
                    React.createElement("div", { className: 'k-tilelayout-item-body k-card-body' }, tile.body)))));
            }) }));
    };
    /**
     * @hidden
     */
    TileLayout.propTypes = {
        id: PropTypes.string,
        style: PropTypes.object,
        className: PropTypes.string,
        dir: PropTypes.string,
        gap: PropTypes.object,
        columns: PropTypes.number,
        columnWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        dataItemKey: PropTypes.string,
        items: PropTypes.array,
        positions: PropTypes.array,
        autoFlow: PropTypes.oneOf(['column', 'row', 'column dense', 'row dense', 'unset']),
        onReposition: PropTypes.func,
        ignoreDrag: PropTypes.func
    };
    /**
     * @hidden
     */
    TileLayout.displayName = 'KendoTileLayout';
    return TileLayout;
}(React.Component));
export { TileLayout };
