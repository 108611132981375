"use strict";

import {
  generateDocumentsValue,
  generateRiskValue,
  generateVersionValue,
} from "../../export/configurable_tables_export_value_generator";
import { RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import CommonEditables from "../../../../server/common/editables/common_editables";
import { FIELD_PROPS } from "../constants/configurable_tables_field_props_config";
import {
  ABOUT_SECTION, ACCEPTANCE_CRITERIA_SECTION,
  CRITICALITY_ASSESSMENT_SECTION, RISK_CONTROL_SECTION,
} from "../constants/configurable_tables_sections_config";
import { FILTER_TYPES } from "../../columnOperations/configurable_tables_column_operations_constants";
import {
  ABOUT_COL_SPAN, ACCEPTANCE_CRITERIA_COL_SPAN,
  CRITICALITY_ASSESSMENT_COL_SPAN,
  RISK_CONTROL_COL_SPAN
} from "../constants/configurable_tables_col_spans_config";
import RecordFields from "../record_fields";
import {
  HIDDEN,
  SENDING_SITE_LABEL
} from "../constants/configurable_tables_columns_config";
import * as UIUtils from "../../../ui_utils";
import { EXPERIMENTS } from "../../../../server/common/generic/common_utils";

/**
 * This class lists all Final Attributes fields to be shown in export, column selection and tables.
 */
export default class FinalAttributeFields extends RecordFields {

  get title() {
    return "Final Attributes";
  }

  get riskControlSectionFields() {

    const parentFields = super.riskControlSectionFields;
    const controlStrategyIndex = parentFields.findIndex(field => field.prop === FIELD_PROPS.CONTROL_STRATEGY
      && field.originatedFromSite === SENDING_SITE_LABEL);

    parentFields[controlStrategyIndex] = {
      title: "Control Strategy",
      prop: FIELD_PROPS.CONTROL_STRATEGY,
      colspan: RISK_CONTROL_COL_SPAN,
      section: RISK_CONTROL_SECTION,
      defaultValue: true,
      defaultTechTransferValue: false,
      defaultChangeCriteriaValue: true,
      filterType: FILTER_TYPES.COMBO_BOX,
      filterLogic: "contains",
      filterOptions: () => CommonEditables.FINAL_ATTRIBUTE_CONTROL_STRATEGY_OPTIONS,
      tableValue: (columnGenerator) => columnGenerator.generateMultiSelectionColumn("Control Strategy", FIELD_PROPS.CONTROL_STRATEGY),
    };

    const ccpIndex = parentFields.findIndex(field => field.prop === FIELD_PROPS.CCP
      && field.originatedFromSite === SENDING_SITE_LABEL);

    parentFields[ccpIndex] = {
      title: "CCP",
      prop: FIELD_PROPS.CCP,
      section: RISK_CONTROL_SECTION,
      defaultValue: false,
      defaultChangeCriteriaValue: true,
      filterType: FILTER_TYPES.COMBO_BOX,
      filterOptions: () => CommonEditables.BOOLEAN_VALUES,
      tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("CCP", FIELD_PROPS.CCP)
    };

    return parentFields;
  }

  get acceptanceCriteriaSectionFields() {

    if (this.modelType === "FPAs") {
      return super.acceptanceCriteriaSectionFields;
    }

    const parentFields = super.acceptanceCriteriaSectionFields;
    const targetJustificationIndex = parentFields.findIndex(field => field.prop === FIELD_PROPS.TARGET_JUSTIFICATION);

    parentFields.splice(targetJustificationIndex, 0,
      {
        title: "Stability Indicating",
        prop: FIELD_PROPS.STABILITY_INDICATING,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        section: ACCEPTANCE_CRITERIA_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: () => CommonEditables.BOOLEAN_VALUES,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("Stability Indicating", FIELD_PROPS.STABILITY_INDICATING)
      });


    const samplingPlanIndex = parentFields.findIndex(field => field.prop === FIELD_PROPS.SAMPLING_PLAN
      && field.originatedFromSite === SENDING_SITE_LABEL);

    parentFields[samplingPlanIndex] = {
      title: "Sampling Plan",
      prop: FIELD_PROPS.SAMPLING_PLAN,
      section: ACCEPTANCE_CRITERIA_SECTION,
      defaultValue: false,
      defaultTechTransferValue: false,
      defaultChangeCriteriaValue: true,
      filterType: FILTER_TYPES.COMBO_BOX,
      filterOptions: () => CommonEditables.BOOLEAN_VALUES,
      tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("Sampling Plan", FIELD_PROPS.SAMPLING_PLAN)
    };

    return parentFields;
  }

  get fields() {
    const conditionalFields = [];
    if (this.modelType !== "FPAs" && UIUtils.isExperimentEnabled(EXPERIMENTS.ObligatoryCQA)) {
      conditionalFields.push({
        title: "Obligatory CQA",
        prop: FIELD_PROPS.OBLIGATORY_CQA,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
      });
    }

    return ([
      // About
      {
        title: "Name",
        prop: FIELD_PROPS.NAME,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        tableValue: (columnGenerator) => columnGenerator.generateNameColumn(),
      },
      {
        title: "Version",
        prop: FIELD_PROPS.VERSION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        showColumnSelectionOn: HIDDEN,
        value: generateVersionValue,
        tableValue: (columnGenerator) => columnGenerator.generateVersionColumn("Version"),
      },
      {
        title: "Type",
        prop: FIELD_PROPS.TYPE,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        filterType: FILTER_TYPES.COMBO_BOX,
      },
      {
        title: "Category",
        prop: FIELD_PROPS.CATEGORY,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        filterLogic: "contains",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: () => CommonEditables.QUALITY_ATTRIBUTES_CATEGORIES,
        tableValue: (columnGenerator) => columnGenerator.generateMultiSelectionColumn("Category", FIELD_PROPS.CATEGORY, true),
      },
      {
        title: "Scope",
        prop: FIELD_PROPS.SCOPE,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        filterLogic: "contains",
        filterOptions: () => CommonEditables.FINAL_ATTRIBUTE_SCOPE,
        tableValue: (columnGenerator) => columnGenerator.generateMultiSelectionColumn("Scope", FIELD_PROPS.SCOPE, true),
      },
      {
        title: "Description",
        prop: FIELD_PROPS.DESCRIPTION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        tableValue: (columnGenerator) =>
          columnGenerator.generateTextAreaColumn("Description", FIELD_PROPS.DESCRIPTION),
      },
      {
        title: "TPP Sections",
        prop: FIELD_PROPS.TPP_SECTIONS,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        filterLogic: "contains",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnAdapter) => columnAdapter.getObjectListFilterOptions(records, FIELD_PROPS.TPP_SECTIONS),
        tableValue: (columnGenerator) =>
          columnGenerator.generateObjectListColumn(null, FIELD_PROPS.TPP_SECTIONS, "TPP", "TPP Sections"),
      },
      {
        title: "General Attributes",
        prop: FIELD_PROPS.GENERAL_ATTRIBUTES,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        filterLogic: "contains",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnAdapter) => columnAdapter.getObjectListFilterOptions(records, FIELD_PROPS.GENERAL_ATTRIBUTES),
        tableValue: (columnGenerator) =>
          columnGenerator.generateObjectListColumn(null, FIELD_PROPS.GENERAL_ATTRIBUTES, "GA", "General Attributes"),
      },
      // Criticality Assessment
      {
        title: "Impact",
        prop: FIELD_PROPS.IMPACT,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        isRiskScoreField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.IMPACT),
        value: generateRiskValue.bind(this, FIELD_PROPS.IMPACT, RISK_TYPE_ENUM.IMPACT),
        tableValue: (columnGenerator) =>
          columnGenerator.generateRiskColumn("Impact", FIELD_PROPS.IMPACT, RISK_TYPE_ENUM.IMPACT),
      },
      {
        title: "Uncertainty",
        prop: FIELD_PROPS.UNCERTAINTY,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        isRiskScoreField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.UNCERTAINTY),
        value: generateRiskValue.bind(this, FIELD_PROPS.UNCERTAINTY, RISK_TYPE_ENUM.UNCERTAINTY),
        tableValue: (columnGenerator) =>
          columnGenerator.generateRiskColumn("Uncertainty", FIELD_PROPS.UNCERTAINTY, RISK_TYPE_ENUM.UNCERTAINTY),
      },
      {
        title: "Criticality (Raw)",
        prop: FIELD_PROPS.CRITICALITY,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: true,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.CRITICALITY),
        value: generateRiskValue.bind(this, FIELD_PROPS.CRITICALITY, RISK_TYPE_ENUM.CRITICALITY),
        tableValue: (columnGenerator) =>
          columnGenerator.generateRiskColumn("Criticality (Raw)", FIELD_PROPS.CRITICALITY, RISK_TYPE_ENUM.CRITICALITY),
      },
      {
        title: "Criticality (%)",
        prop: FIELD_PROPS.CRITICALITY_PERCENTAGE,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.CRITICALITY_PERCENTAGE),
        value: generateRiskValue.bind(this, FIELD_PROPS.CRITICALITY_PERCENTAGE, RISK_TYPE_ENUM.CRITICALITY),
        tableValue: (columnGenerator) =>
          columnGenerator.generateRiskColumn("Criticality (%)", FIELD_PROPS.CRITICALITY_PERCENTAGE, RISK_TYPE_ENUM.CRITICALITY),
      },
      {
        title: "Criticality (Raw)",
        prop: FIELD_PROPS.MAX_CRITICALITY,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.MAX_CRITICALITY),
        value: generateRiskValue.bind(this, FIELD_PROPS.MAX_CRITICALITY, RISK_TYPE_ENUM.CRITICALITY),
        tableValue: (columnGenerator) =>
          columnGenerator.generateRiskColumn("Criticality (Raw)", FIELD_PROPS.MAX_CRITICALITY, RISK_TYPE_ENUM.CRITICALITY),
      },
      {
        title: "Criticality (%)",
        prop: FIELD_PROPS.MAX_CRITICALITY_PERCENTAGE,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.MAX_CRITICALITY_PERCENTAGE),
        value: generateRiskValue.bind(this, FIELD_PROPS.MAX_CRITICALITY_PERCENTAGE, RISK_TYPE_ENUM.CRITICALITY),
        tableValue: (columnGenerator) =>
          columnGenerator.generateRiskColumn("Criticality (%)", FIELD_PROPS.MAX_CRITICALITY_PERCENTAGE, RISK_TYPE_ENUM.CRITICALITY),
      },
      {
        title: "Criticality Justification",
        prop: FIELD_PROPS.CRITICALITY_JUSTIFICATION,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: true,
        tableValue: (columnGenerator) => columnGenerator.generateTextAreaColumn("Criticality Justification", FIELD_PROPS.CRITICALITY_JUSTIFICATION),
      },
      {
        title: "Recommended Actions",
        prop: FIELD_PROPS.RECOMMENDED_ACTIONS,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        tableValue: (columnGenerator) => columnGenerator.generateTextAreaColumn("Recommended Actions", FIELD_PROPS.RECOMMENDED_ACTIONS),
      },
      {
        title: "Source Documents",
        prop: FIELD_PROPS.CRITICALITY_ASSESSMENT_LINKS,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        filterType: FILTER_TYPES.LINKS,
        value: generateDocumentsValue,
        tableValue: (columnGenerator) => columnGenerator.generateDocumentsColumn("Source Documents", null, FIELD_PROPS.CRITICALITY_ASSESSMENT_LINKS),
      },
      ...this.acceptanceCriteriaSectionFields,
      ...this.processCapabilitySectionFields,
      ...this.riskControlSectionFields,
      ...this.referenceAndStandardsSectionFields,
    ]).concat(conditionalFields);
  }
}
