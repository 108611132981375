"use strict";

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BaseReactComponent from "../../base_react_component";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import * as UIUtils from "../../ui_utils";
import { QBDLink } from "../../utils/withRouter";

/** This header appears in the top level bar to indicate the user's current location on the sitemap */
// i18next-extract-mark-ns-start widgets
class HeaderBreadcrumb extends BaseReactComponent {

  render() {
    const {t} = this.props;
    let {parent, current, parentLink, paths} = this.props.breadcrumb ?? "";

    // translate typecode if there's a project or doc
    let currentSliced = current && current.split(" - ");
    if (currentSliced?.length > 1) {
      const key = currentSliced[0];
      const keyInfo = UIUtils.parseKey(key);
      const typeCode = t(keyInfo.typeCode);
      if (keyInfo?.id) {
        currentSliced[0] = `${typeCode}-${keyInfo.id}`;
      } else {
        currentSliced[0] = typeCode;
      }

      current = currentSliced.join(" - ");
    }

    if (paths) {
      return (
        <div className={"header-breadcrumb"}>
          {paths.map((path) => (
            <Fragment key={path.parentLink}>
              <a
                className={"header-breadcrumb-parent"}
                id={"breadcrumbParent"}
                href={path.parentLink}
              >
                {path.parent}
              </a>
              {" / "}
            </Fragment>
          ))}
          <span
            className={"header-breadcrumb-current"}
            id={"breadcrumbCurrent"}
          >
            {current}
          </span>
        </div>
      );
    }

    return <div className={"header-breadcrumb"}>
      <QBDLink className={"header-breadcrumb-parent"} id={"breadcrumbParent"} to={parentLink}>{parent}</QBDLink>
      {parent && current ? " / " : ""}
      <span className={"header-breadcrumb-current"} id={"breadcrumbCurrent"}>{current}</span>
    </div>;
  }
}

HeaderBreadcrumb.propTypes = {
  breadcrumb: PropTypes.object,
};

export default I18NWrapper.wrap(HeaderBreadcrumb, "widgets");
// i18next-extract-mark-ns-stop widgets
