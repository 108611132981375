"use strict";

import React from "react";
import * as UIUtils from "../../../ui_utils";
import { CREATE_MATERIAL_STEPS, COMMON_MATERIAL_STEPS, SYNC_MATERIAL_STEPS } from "../../library_constants";
import { ProjectMaterialOptionsStep } from "./project_material_options_step";
import { ProjectMaterialSelectionStep } from "./project_material_selection_step";
import { SyncMaterialOptionsStep } from "./sync_material_options_step";
import { MATERIAL_ACTIONS } from "../../helpers/library_helper";

/**
 * The required configuration to create material selection step
 * @type {{initialState: {searchFilter: {logic: string, filters: [{field: string, value: string, operator: string},{field: string, value: string, operator: string},{field: string, value: string, operator: string}]}}, visible: boolean, step: string, disabled: boolean, wizardStepId: number, navTabId: string, type: JSX.Element}}
 */
export const MATERIAL_SELECTION_STEP_CONFIG = {
  type: ProjectMaterialSelectionStep,
  navTabId: "selectMaterialNavItem",
  step: COMMON_MATERIAL_STEPS.MaterialLibrarySelection,
  initialState: {
    searchFilter: {
      logic: "or",
      filters: [
        {
          field: "name",
          operator: "contains",
          value: "*",
        },
        {
          field: "category",
          operator: "contains",
          value: "*",
        },
        {
          field: "Supplier.name",
          operator: "contains",
          value: "*",
        },
        {
          field: "gmp",
          operator: "contains",
          value: "*",
        },
      ],
    },
  },
  disabled: false,
  visible: true,
  wizardStepId: 1,
};

/**
 * the required config to create material options step.
 * @type {{initialState: {}, visible: boolean, step: string, disabled: boolean, wizardStepId: number, navTabId: string, type: JSX.Element}}
 */
export const MATERIAL_OPTIONS_STEP_CONFIG = {
  type: ProjectMaterialOptionsStep,
  navTabId: "createPreferencesNavItem",
  step: CREATE_MATERIAL_STEPS.CreateMaterialPreferences,
  initialState: {},
  disabled: true,
  visible: false,
  wizardStepId: 2,
};


/**
 * the required config to create material options step.
 * @type {{initialState: {}, visible: boolean, step: string, disabled: boolean, wizardStepId: number, navTabId: string, type: JSX.Element}}
 */
export const SYNC_OPTIONS_STEP_CONFIG = {
  type: SyncMaterialOptionsStep,
  navTabId: "createPreferencesNavItem",
  step: SYNC_MATERIAL_STEPS.SyncMaterialOptions,
  initialState: {},
  disabled: true,
  visible: false,
  wizardStepId: 2,
  actionButton: MATERIAL_ACTIONS.SYNC,
};

/**
 * the required config to create material options step.
 * @type {{initialState: {}, visible: boolean, step: string, disabled: boolean, wizardStepId: number, navTabId: string, type: JSX.Element}}
 */
export const SWAP_OPTIONS_STEP_CONFIG = {
  ...SYNC_OPTIONS_STEP_CONFIG,
  actionButton: MATERIAL_ACTIONS.SWAP,
};


/**
 * Create and return the actual step react elements.
 * @return {[React.Component]}
 */
export function getWizardSteps(initialState) {
  const {wizardStepsConfig} = initialState;
  return wizardStepsConfig.map(wizardStepConfig => {
    let stepName = UIUtils.stripAllWhitespaces(wizardStepConfig.step);
    return React.createElement(wizardStepConfig.type,
      {
        key: stepName,
        wizardStepsConfig,
        config: wizardStepConfig,
        ...initialState
      });
  });
}