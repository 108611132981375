var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EMPTY_ID, isIdZeroLevel, createId, getRootParentId, getIdWithoutRootParentId, getAllShortIds } from './itemIdUtils';
import SortedPublicItemIds from './SortedPublicItemIds';
import { getNestedValue } from './misc';
/**
 * @hidden
 */
export function hasChildren(item, childrenField) {
    var children = getNestedValue(childrenField, item);
    return Boolean(children && children.length);
}
/**
 * @hidden
 */
export function isItemExpandedAndWithChildren(item, fieldsSvc) {
    return fieldsSvc.expanded(item) && hasChildren(item, fieldsSvc.getChildrenField());
}
/**
 * @hidden
 */
export function resolveItemId(publicId, idField, items, childrenField) {
    var resolvedIds = resolveItemsIds([publicId], idField, items, childrenField);
    return resolvedIds.length ? resolvedIds[0] : undefined;
}
/**
 * @hidden
 */
export function resolveItemsIds(publicIds, idField, items, childrenField) {
    var result = [];
    var sortedIds = new SortedPublicItemIds();
    sortedIds.init(publicIds);
    resolveItemsIdsHelper(sortedIds, idField, items, EMPTY_ID, result, childrenField);
    return result;
}
/**
 * @hidden
 */
export function updateItem(items, itemId, update, cloneField, childrenField) {
    var _a;
    var itemIndex = Number(getRootParentId(itemId));
    if (itemIndex >= items.length) {
        return items;
    }
    var result = items;
    var item = items[itemIndex];
    // Clone the item before updating it.
    if (!item[cloneField]) {
        result = items.slice();
        item = result[itemIndex] = Object.assign({}, item, (_a = {}, _a[cloneField] = true, _a));
    }
    // Directly update the item.
    if (isIdZeroLevel(itemId)) {
        update(item);
    }
    else if (item[childrenField]) {
        item[childrenField] = updateItem(item[childrenField], getIdWithoutRootParentId(itemId), update, cloneField, childrenField);
    }
    return result;
}
/**
 * @hidden
 */
export function isEnabledAndAllParentsEnabled(itemId, items, fieldsSvc) {
    var ids = getAllShortIds(itemId);
    var currentItems = items;
    for (var i = 0; i < ids.length; i++) {
        var currentItem = currentItems[Number(ids[i])];
        if (fieldsSvc.disabled(currentItem)) {
            return false;
        }
        else {
            currentItems = currentItem[fieldsSvc.getChildrenField()];
        }
    }
    return true;
}
/**
 * @hidden
 */
export function getAllDirectIndirectChildrenIds(item, itemId, childrenField, idField) {
    return idField ? getChildrenPublicIds(item) : getChildrenHierarchicalIndices(item, itemId);
    function getChildrenHierarchicalIndices(startItem, startItemId) {
        var result = [];
        var children = startItem[childrenField] || [];
        for (var i = 0; i < children.length; i++) {
            var id = createId(i, startItemId);
            result.push(id);
            result = result.concat(getChildrenHierarchicalIndices(children[i], id));
        }
        return result;
    }
    function getChildrenPublicIds(startItem) {
        var result = [];
        var children = startItem[childrenField] || [];
        for (var i = 0; i < children.length; i++) {
            result.push(getNestedValue(idField, children[i]));
            result = result.concat(getChildrenPublicIds(children[i]));
        }
        return result;
    }
}
/**
 * @hidden
 */
export function areAllDirectChildrenChecked(item, itemId, idField, childrenField, check) {
    var children = item[childrenField] || [];
    return children.every(function (child, index) {
        return check.indexOf(idField ? getNestedValue(idField, child) : createId(index, itemId)) > -1;
    });
}
/**
 * @hidden
 */
export function getAllParents(itemId, childrenField, items) {
    var result = [];
    var ids = getAllShortIds(itemId);
    var currentItems = items;
    for (var i = 0; i < ids.length - 1; i++) {
        if (!currentItems) {
            break;
        }
        var currentItem = currentItems[Number(ids[i])];
        result.push(currentItem);
        currentItems = currentItem[childrenField];
    }
    return result;
}
/**
 * @hidden
 */
export function removeItem(itemId, childrenField, items) {
    var result = items.slice();
    if (isIdZeroLevel(itemId)) {
        result.splice(Number(itemId), 1);
    }
    else {
        var rootParentIndex = Number(getRootParentId(itemId));
        var rootParent = result[rootParentIndex] = __assign({}, result[rootParentIndex]);
        rootParent[childrenField] = removeItem(getIdWithoutRootParentId(itemId), childrenField, rootParent[childrenField]);
    }
    return result;
}
/**
 * @hidden
 */
export function addItem(item, operation, childrenField, targetItemId, items) {
    var result = items.slice();
    if (isIdZeroLevel(targetItemId)) {
        if (operation === 'child') {
            var targetItem = result[Number(targetItemId)] = __assign({}, result[Number(targetItemId)]);
            if (targetItem[childrenField]) {
                targetItem[childrenField] = targetItem[childrenField].slice();
                targetItem[childrenField].push(item);
            }
            else {
                targetItem[childrenField] = [item];
            }
        }
        else {
            result.splice(Number(targetItemId) + (operation === 'after' ? 1 : 0), 0, item);
        }
    }
    else {
        var rootParentIndex = Number(getRootParentId(targetItemId));
        var rootParent = result[rootParentIndex] = __assign({}, result[rootParentIndex]);
        rootParent[childrenField] = addItem(item, operation, childrenField, getIdWithoutRootParentId(targetItemId), rootParent[childrenField]);
    }
    return result;
}
function resolveItemsIdsHelper(publicItemIds, idField, items, parentItemId, resolvedIds, childrenField) {
    for (var i = 0; i < items.length; i++) {
        var item = items[i];
        var id = createId(i, parentItemId);
        if (publicItemIds.hasId(getNestedValue(idField, item))) {
            resolvedIds.push(id);
        }
        if (hasChildren(item, childrenField)) {
            resolveItemsIdsHelper(publicItemIds, idField, getNestedValue(childrenField, item), id, resolvedIds, childrenField);
        }
    }
}
