"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseReactComponent from "../../base_react_component";

// i18next-extract-mark-ns-start widgets
/**
 * This class show a multi option button (ex. Reports, Document Bulk Add)
 */
class DropdownButton extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let {
      options, id, onOptionsSelect,
      className, isDisabled, titleIcon,
      text, removeTitleIcon, t,
      isSecondaryButton,
      buttonClassName,
      title,
    } = this.props;

    const buttonPriorityClass = isSecondaryButton ? "btn-secondary" : "btn-primary";
    options = (options || []).map((option, index) => {
      let input = (
        <Fragment>
          {option.icon ?
            <FontAwesomeIcon className="split-button-option-icon"
                             icon={option.icon}
            /> : ""}
          {t(option.value)}
        </Fragment>
      );

      const eventHandler = (event) => {
        UIUtils.ignoreHandler(event);
        onOptionsSelect(event);
      };

      return (
        <div key={index}
             className="dropdown-item"
        >
          {option.disabled ?
            <div title={option.title}
                 id={id + "_" + option.key}
                 className="split-button-disabled full-width"
                 onClick={option.disabled ? null : eventHandler}
            >
              {input}
            </div> :
            <a name={option.key}
               className="d-block"
               id={id + "_" + option.key}
               onClick={option.disabled ? null : eventHandler}
            >
              {input}
            </a>}
        </div>);
    });

    return (
      <div className={className + " split-button"}>
        <button disabled={isDisabled}
                id={id + "DropdownButton"}
                data-toggle="dropdown"
                title={title}
                aria-haspopup="true" aria-expanded="false"
                type="button"
                className={`btn btn-split ${buttonPriorityClass} ${buttonClassName} dropdown-toggle`}
        >
          {removeTitleIcon ? "" :
            <FontAwesomeIcon className="split-button-title-icon"
                             icon={titleIcon || faFileDownload}
            />}
          <span className="mr-1">{text}</span>
        </button>
        <div className="dropdown-menu dropdown-menu-right"
             id="dropdown-menu">
          {options}
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(DropdownButton, "widgets");
// i18next-extract-mark-ns-stop widgets
