"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import BaseAttribute from "./base_attribute";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { faInfoCircle, faPen, faCheck, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import LabelTooltip from "../../widgets/tooltips/label_tooltip";
import Typeahead from "../../widgets/typeahead";
import { createRowDataDiffForJSONAttribute } from "../../helpers/diff_helper";
import DatePicker from "../../widgets/date_picker";
import moment from "moment";
import { FILE_STATUS } from "../../helpers/document_transfer_helper";
import FieldTooltip from "../../widgets/tooltips/field_tooltip";
import { ATTRIBUTE_TYPE } from "./constants/attribute_type";
import CommonUtils from "../../../server/common/generic/common_utils";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import { ActionButton } from "../../widgets/generic/action_button";
import { FIELD_REQUIRED_FOR, WidgetField } from "../widgets/widget_field";
import { Ensure } from "../../../server/common/generic/common_ensure";

const Logger = Log.group(LOG_GROUP.JSONAttribute, "BaseJsonAttribute");

export const CUSTOM_ERROR_TYPE = {
  FOR_SAVE: "For save",
  FOR_PROPOSE: "For propose"
};

/**
 * @enum {string}
 */
export const TABLE_ITEM_MODE = {
  ADD: "Add",
  EDIT: "Edit",
  VIEW: "View",
};

/**
 * @enum {string}
 */
export const TABLE_MODE = {
  EDITOR_OPEN: "EditorOpen",
  EDITOR_CLOSED: "EditorClosed",
};

/**
 * This is a table widget that can be used to store in a single TEXT database field a json structure holding
 * multiple rows of a given set of fields. The table widget provides an inline editor that can be used for editing
 * each row individually.
 * The fields are provided to the widget in the form of a json structure (see for example doc_links_attribute_constants.js).
 * Each field is a set of parameters that controls the behavior of the field in all of the widget states (add, view, edit, diff).
 *
 * The supported widget field parameters in the widgets json structure are:
 *
 * 1.  fieldName: The name of attribute that will be used for storing the field value. Needs to be a proper attribute id.
 * 2.  displayName: The name that will be used as a caption for the field in the table header.
 * 3.  tooltipText: The tooltip text that will be shown when clicking on the field's column header.
 * 4.  diffMethod: Can be any value from the TEXT_DIFF_METHOD_ENUM (whole & differential). This controls how the diff for the particular filed will be
 *     rendered when viewing a version of an object the widget belongs to.
 * 5.  requiredFor: Can be (none, proposal, save). Makes the field mandatory when saving/proposing and throws an error if no value is specified.
 * 6.  forceUpdate: If set to true, forces the widget's parent state to be updated (i.e the page holding the widget) when the inline editor is saved.
 * 7.  defaultValue: A default value for the field for newly added rows. This can be a simple string, a json structure or a method defined on the control itself.
 *     Child controls can provide the method for getting the default value for a given field. The control first checks if the provided string is a method
 *     and then if not, it uses that string as the default value. If the provided value is a json object, then that object is cloned and assigned to the field.
 * 8.  placeholder: The text which will show up on the fields input control in the widget's row inline editor.
 * 9.  belongsToMasterRow: True if the field will be rendered as part of the master row, false if it should be rendered on the child row.
 * 10. order: The order the field should show up. The order is not reset for child rows. It is an incremental id, starting from 1.
 * 11. inputType: The input type that will be used for user input for the particular field in the rows inline editor.
 *     Allowed values are: text, textarea, number, select, typeahead, link, linkedTypeahead, linkIcon, checkbox and date.
 *     The link and linkedTypeahead are supported in the child base classes, base_links_attachments_attribute.jsx & base_linked_entities_attribute.jsx
 *     The link input type allows for a link to be specified or an attachment to be uploaded and the linkedTypeahead allows a linked
 *     entity to be selected.
 * 12. multiSelect: Applicable for typeahead input fields. Allows multi selection on the typeahead.
 * 13. getOptions: Applicable for typeahead and select input fields. This is the name of a method defined on a child class which will be executed
 *     in order to provide the options for the typeahead or the select input controls
 * 14. width: Optional. Can be specified to control the field's column width. For the master row, this should be a numerical value representing pixels.
 *     For the child rows, this can be either a numerical value or a percentage value in the form of "x%", representing the column width
 *     in the child row as a percentage. When numerical values are specified, then all controls will adjust their width proportionally to
 *     the numerical values and they will all fit into a single child row. When percentages are used then it is up to the developer to
 *     decide in how many child rows the fields should be split. Once a row is populated with fields and before the total width exceeds 100%,
 *     the next fields will be split to a new row. This offers great flexibility on how to organize the controls into multiple child rows within
 *     then child section of the data table. If both numerical and percentage values are specified for the child section, then an error will be
 *     raised.
 * 15. rows: Applicable for the textarea input type. Controls the number of rows the textarea control will use in the row inline editor.
 * 16. disabled: Applicable for all input fields during data input. When set to true the field it corresponds to will not accept any input from the user.
 *     disabled can also be a function name, in which case the return value of that function will determine if the field will be disabled or not.
 * 17. allowDecimalNumbers: This will make the number field accept decimal numbers with any step value and not only integers.
 * 18. orderable: This is true by default but when set to false will make this column unsortable
 * 19. singleLineOptions: Applicable for the typeahead input type. This will make it so that each typeahead option is
 *     rendered on its own line.
 *
 * It is worth noticing that the parameters of the widget fields can be extended in child classes to server specific needs.
 * See as an example the customValidator parameter in the rmp_risk_scale_attribute_constants.js which defines a function that is used
 * to customize the validation logic of the respective field.
 */
export default class BaseJsonAttribute extends BaseAttribute {
  constructor(props, widgetFields) {
    super(props);

    if (!widgetFields && !this.props.widgetFields) {
      throw new Error("WIDGET_FIELDS need to be specified for a links attribute.");
    }

    /* We keep references to these here because myRef.DataTable() does not like to be initialized on a table with
       a different number of columns in each row.  Basically, DataTables doesn't support colspan, and this is the
       workaround.  This workaround is especially important for the QuickPanel functionality where the tables are
       regularly mounted and unmounted.

       See more here: https://stackoverflow.com/a/46584232/491553
    */
    this.refTable = React.createRef();
    this.refDataTable = null;

    /**
     * @type {WidgetField[]}
     */
    this.widgetFields = ((this.props.widgetFields ? this.props.widgetFields : widgetFields) || []).map(
      field => new WidgetField(field, this.name)
    );

    this.editedRows = [];
    this.customError = {
      error: "",
      includesErrorForSave: false,
      includesErrorForPropose: false
    };

    this.typeaheadField = this.widgetFields.find(field => {
      return field.inputType === "typeahead";
    });

    this.columnDefs = [];
    this.columns = [];

    this.errorMessages = {
      editedRowPending: "At least one row is in edit state. Please save or cancel all rows in edit state."
    };

    this._forceFullRedraw = false;

    this.rowReferences = new Map();
  }

  /**
   * Returns an array of field types supported by this base class in the WIDGET_FIELDS JSON object
   */
  static getSupportedFieldTypes() {
    return ["text", "textarea", "number", "select", "typeahead", "date", "linkIcon", "checkbox", "nonEditable", "customInput"];
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
    if (!shouldUpdate) {
      shouldUpdate = (nextProps.hideTableCaption !== this.props.hideTableCaption)
        || (nextProps.tableDescription !== this.props.tableDescription)
        || (nextState.date !== this.state.date);
    }

    return shouldUpdate;
  }

  componentDidMount() {
    super.componentDidMount();
    this.initialiseDataTable();
    const rows = this.preProcessDataForTable();
    if (rows.length > 0) {
      this.reloadDataTable(rows);
    }

    this.props.parent.registerCustomValidatedAttribute(this);
  }

  /**
   * @inheritDoc
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    UIUtils.Ensure.virtual("componentDidUpdate", {prevProps, prevState, snapshot});

    if (this._forceFullRedraw) {
      this.initialiseDataTable();
      this.reloadDataTable(this.preProcessDataForTable());
      this._forceFullRedraw = false;
    } else {
      this.reloadDataTable(this.preProcessDataForTable());
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    $(this.refTable.current).empty();

    if (this.refDataTable) {
      this.refDataTable.destroy(true);
    }

    this.props.parent.unregisterCustomValidatedAttribute(this);
  }

  /**
   * This generates a new UUID and assigns that to a row, if the row does not already have one.
   * @param row The row to generate a new UUID for
   * @returns {*}
   */
  generateUUID(row) {
    return row.uuid ? row.uuid : UIUtils.generateUUID();
  }

  // noinspection JSMethodCanBeStatic
  getInitialValue() {
    return "[]";
  }

  /**
   * Initializes the datatables table
   */
  initialiseDataTable() {
    this.initializeColumns();
    this.initialiseColumnDefs();

    /*At this point you might be confused around why we use this expression for the order attribute of datatables.
      When we are in edit mode and add a new row, we want by default the new rows to be added to the bottom of the table.
      For this reason, the table is originally sorted by the row index of each row which increments every time a row
      id added. The last column of the table holds the row index. However, this behavior changes when the user explicitly
      decides to sort the table with another column.
     */
    const data = this.getValueAsObject();
    this.refDataTable = $(this.refTable.current).DataTable({
      columnDefs: this.columnDefs,
      columns: this.columns,
      data,
      deferRender: true,
      destroy: true,
      dom: "<t>",
      order: !this.isView() ? [[this.columnDefs.length - 1, "asc"]] : [],
      paging: false,
      stateSave: true,
    });

    if (this.refDataTable) {
      Logger.info("Creating child rows");
      this.createChildRows(this.refDataTable);
    } else {
      Logger.warn("DataTable constructor returned a falsy value: ", Log.object(this.refDataTable),
        "\n - Data:", Log.object(data),
        "\n - ColumnDefs:", Log.object(this.columnDefs),
        "\n - Columns:", Log.object(this.columns),
        "\n - Table: ", Log.object(this.refTable.current),
      );
    }
  }

  /**
   * Initializes the datatables columns.
   * Overwrite getFieldDisplayName, getFieldTooltipText and getColumn to customize the table columns rendering
   * in child classes.
   */
  initializeColumns() {
    this.columns = this.hasChildRows() ? [
      {
        className: "links-details-row-control",
        orderable: false,
        width: 1,
        data: () => {
          return ReactDOMServer.renderToStaticMarkup(
            <FontAwesomeIcon icon={faMinusSquare}
                             size="3x"
                             className="table-details-control"
                             aria-label="Hide details"
            />
          );
        },
      }] : [];

    this.columns.push(...this.widgetFields.filter(field => {
      return field.belongsToMasterRow && !field.hidden;
    }).sort((a, b) => {
      return a.order - b.order;
    }).map(field => {
      return {
        title: ReactDOMServer.renderToStaticMarkup(
          <LabelTooltip text={this.getFieldDisplayName(field)}
                        className="links-details-field-label"
                        noColon={true}
                        getTooltipCallback={this.getFieldTooltipText.bind(this, field)}
          />),
        width: field.width ? field.width : 500,
        data: (result, type) => {
          return this.getColumn(field, result, type);
        }
      };
    }));

    if (!this.isView()) {
      this.columns.push(this.getManageColumn());
    }
  }

  getManageColumn() {
    return {
      title: "Manage",
      width: 1,
      class: "links-manage-column-header",
      data: result => result.index,
    };
  }

  /**
   * Overwrite this in child classes to customize the rendering of a particular field in the table column
   * @param field The field to display a title for
   */
  getFieldDisplayName(field) {
    return field.displayName;
  }

  /**
   * Overwrite this in child classes to customize the rendering of the tooltip text of a particular field in the table column
   * @param field The field to customize the column tooltip text for
   * @returns {string|*}
   */
  getFieldTooltipText(field) {
    return field.tooltipText;
  }

  /**
   * Overwrite this in child classes to customize the rendering of the instructions of a particular field in the table rows
   * @param field field The field to customize the column tooltip text for
   * @param rowData The raw data that contain the actual link
   * @returns {string| *}
   */
  getFieldInstructions(field, rowData) {
    if (typeof this[field.instructions] === "function") {
      return this[field.instructions](rowData, this.props.parent);
    } else {
      return field.instructions;
    }
  }

  getFieldInstructionsInput(field, rowData) {
    let fieldInstructions = this.getFieldInstructions(field, rowData);
    return fieldInstructions ?
      <FieldTooltip
        id={CommonUtils.capitalize(field.fieldName) + "_" + rowData.index + "_"}
        className="links-instructions"
        text={fieldInstructions}
      /> : "";
  }

  /**
   * Overwrite this in child classes to customize the min value of a numeric field
   * @param field field The field to customize the column tooltip text for
   * @returns {number| *}
   */
  getFieldMinValue(field) {
    if (typeof this[field.min] === "function") {
      return this[field.min]();
    } else {
      return field.min ? field.min : Number.MIN_SAFE_INTEGER;
    }
  }

  /**
   * Overwrite this in child classes to customize the rendering of a particular field in a table column
   * This method returns the DOM object used to populate the data property of the datatables columns element.
   * @param field {WidgetField} The field to render a column for
   * @param result The datatables row data
   * @param type The datatables display mode flag
   * @returns {*}
   */
  getColumn(field, result, type) {
    if (!BaseJsonAttribute.getSupportedFieldTypes().includes(field.inputType)) {
      return null;
    }

    Logger.verbose("Retrieving column: ", Log.object(field), Log.object(result), Log.object(type));

    let fieldValue = field.getValue(result);
    if (!fieldValue) {
      Logger.verbose("Field value is falsy: ", Log.object(fieldValue));
      return null;
    }

    if (field.inputType === "typeahead") {
      if (!Array.isArray(fieldValue)) {
        fieldValue = [fieldValue];
      }
      if (this.isDiffingVersions()) {
        Logger.verbose("Diffing versions: ", Log.object(fieldValue));
        return fieldValue;
      } else if (field.singleLineOptions) {
        Logger.verbose("Single line Typeahead: ", Log.object(fieldValue));
        return ReactDOMServer.renderToStaticMarkup(
          fieldValue
            .map(option => <div key={option.id || option}>{this.getOptionSafely(field, option)}</div>)
        );
      } else {
        Logger.verbose("Regular Typeahead: ", Log.object(fieldValue));
        return ReactDOMServer.renderToStaticMarkup(
          <span>{fieldValue
            .map(option => this.getOptionSafely(field, option))
            .join(", ")}</span>
        );
      }
    } else {
      Logger.verbose("Not a typeahead: ", Log.object(fieldValue));
      return ReactDOMServer.renderToStaticMarkup(
        <span>{fieldValue}</span>
      );
    }
  }

  /**
   * This is a helper method to handle an option that's not found, so it doesn't crash the whole page.  It shouldn't
   * happen that the option isn't there, but it has in the past and this is more defensive.
   */
  getOptionSafely(field, option, returnLabel = true) {
    Logger.verbose("Getting options: ", Log.object(field), Log.object(option), Log.object(returnLabel));
    const fieldOptions = this[field.getOptions](field);
    let optionFound = fieldOptions.find(opt => opt.id === option || opt.label === option);

    if (!optionFound) {
      Logger.verbose(`Missing option ${option} not found in ${JSON.stringify(fieldOptions)}.  Making it work anyway.`, Log.stackTrace());
      optionFound = {id: option, label: option};
    }

    if (returnLabel) {
      return optionFound.label;
    } else {
      return optionFound;
    }
  }

  /**
   * Initializes the datatables cells based on the provided WIDGET_FIELDS JSON object
   * Overwrite getColumnDef, getFieldInput in child classes to customize the rendering of a particular cell
   */
  initialiseColumnDefs() {
    this.columnDefs = [];
    let hasChildRows = this.hasChildRows();

    if (this.refTable.current) {
      // noinspection JSUnusedGlobalSymbols
      if (hasChildRows) {
        this.columnDefs.push(
          {
            targets: 0,
            // This is separate from the columns above because the onClick events won't be included using ReactDOMServer
            createdCell: (td, cellData, rowData, row) => {
              ReactDOM.render((
                <FontAwesomeIcon id={this.props.name + "_ExpandCollapseButton_" + rowData.index}
                                 icon={faMinusSquare}
                                 size="3x"
                                 className="table-details-control"
                                 aria-label="Show details"
                                 onClick={this.handleExpandCollapseDetails.bind(this, row, td)}
                />), td);
            },
          });
      }

      this.targetCounter = hasChildRows ? 1 : 0;
      const masterRowFields = this.widgetFields.filter(field => {
        return field.belongsToMasterRow && !field.hidden;
      }).sort((a, b) => {
        return a.order - b.order;
      });
      Logger.verbose("Master Widget Fields: ", Log.object(masterRowFields));
      const masterRowColumnDefs = masterRowFields.map(field => {
        return {
          targets: this.targetCounter++,
          orderable: field.orderable !== false,
          createdCell: (td, cellData, rowData) => {
            Logger.verbose("Creating cell: ", Log.object(td), Log.object(cellData), Log.object(rowData));
            ReactDOM.render((
              this.getColumnDef(field, rowData)
            ), td);
          },
        };
      });
      Logger.verbose("Master Column Defs: ", Log.object(masterRowFields));
      this.columnDefs.push(...masterRowColumnDefs);

      if (!this.isView()) {
        this.columnDefs.push(
          {
            targets: this.targetCounter++,
            orderable: false,
            createdCell: (td, cellData, rowData) => {
              ReactDOM.render(
                (
                  !this.isRowInEditMode(rowData.uuid) ?
                    (<div className="links-manage links-manage-column">
                      {this.enableRecordEditing() ?
                        <button id={this.props.name + "_EditButton_" + rowData.index}
                                type="button"
                                className="btn btn-primary btn-links"
                                aria-label="Edit"
                                disabled={this.isEditButtonDisabled(rowData)}
                                title={this.isEditButtonDisabled(rowData) ? this.getEditButtonDisabledReason(rowData) : null}
                                onClick={this.handleEdit.bind(this, rowData, null, undefined)}
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </button> : ""}
                      <button id={this.props.name + "_TrashButton_" + rowData.index}
                              type="button"
                              className="btn btn-secondary btn-links"
                              disabled={this.isDeleteButtonDisabled(rowData)}
                              title={this.isDeleteButtonDisabled(rowData) ? this.getDeleteButtonDisabledReason(rowData) : null}
                              aria-label="Remove"
                              onClick={this.handleDelete.bind(this, rowData)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                    </div>)
                    :
                    (<div className="links-manage links-manage-column">
                      <button id={this.props.name + "_SaveButton_" + rowData.index}
                              type="button"
                              className="btn btn-primary btn-links"
                              aria-label="Save"
                              disabled={this.getEditedRowByUUID(rowData.uuid).fileStatus === FILE_STATUS.UPLOADING}
                              onClick={() => this.handleSave(rowData, null, this.setValue)}
                      >
                        <FontAwesomeIcon icon={faCheck} />
                      </button>
                      <button id={this.props.name + "_CancelButton_" + rowData.index}
                              type="button"
                              className="btn btn-secondary btn-links"
                              aria-label="Cancel"
                              disabled={this.getEditedRowByUUID(rowData.uuid).fileStatus === FILE_STATUS.UPLOADING}
                              onClick={this.handleCancel.bind(this, rowData)}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>)
                ), td);
            },
          }
        );
      }
    }
  }

  /**
   * Overwrite this in child classes to customize the rendering of a particular cell.
   * This method will cover the field types returned by BaseJsonAttribute.getSupportedFieldTypes()
   * Overwrite getFieldInput to further customize the input controls used in table cell inline editors
   * @param field The field to customize the column cell for
   * @param rowData The row data object
   */
  getColumnDef(field, rowData) {
    Logger.verbose("Entering BaseJsonAttribute.getColumnDef", Log.object(field), Log.object(rowData));

    if (!BaseJsonAttribute.getSupportedFieldTypes().includes(field.inputType) || field.hidden) {
      Logger.verbose(`Field input not supported ${Log.symbol(field.inputType)}. Skipping...`);
      return null;
    }
    const capitalizedFieldName = CommonUtils.capitalize(field.fieldName);
    const id = this.props.name + "_" + capitalizedFieldName + "_" + rowData.index;
    let value = field.getValue(rowData);

    if (this.isRowInEditMode(rowData.uuid)) {
      return (
        <div id={this.props.name + "_" + capitalizedFieldName + "FormGroup_" + rowData.index}
             className="form-group"
        >
          <div className="links-manage">
            {this.getFieldInput(field, rowData)}
          </div>
          <div className="help-block with-errors"
               id={id + "ErrorDiv"}
          />
        </div>
      );
    } else {
      if (field.inputType === "typeahead") {
        let typeaheadField = field.getFormattedValue(this, rowData);
        if (!typeaheadField) {
          return null;
        }
        if (this.isDiffingVersions()) {
          return (
            <div id={id} className="links-diff">
              {typeaheadField}
            </div>
          );
        } else {
          if (!Array.isArray(typeaheadField)) {
            typeaheadField = [typeaheadField];
          }
          if (field.singleLineOptions) {
            return (
              <div className="links-manage">
                {
                  <div id={id}>
                    {typeaheadField
                      .map(option => <div key={option.id || option}>{this.getOptionSafely(field, option)}</div>)}
                  </div>}
              </div>
            );
          } else {
            return (
              <div className="links-manage">
                {
                  <span id={id}>
                      {typeaheadField
                        .map(option => this.getOptionSafely(field, option))
                        .join(", ")}</span>}
              </div>
            );
          }
        }
      } else if (field.inputType === "linkIcon") {
        return (
          <div className="links-manage">
            {this.getLinkIconForDisplay(field, rowData)}
          </div>
        );
      } else {
        let formattedValue = field.getFormattedValue(this, rowData);
        if (field.inputType === "customInput") {
          formattedValue = this.getFieldValueForDisplay(field, rowData);
        }

        if (!this.isDiffingVersions()) {
          if (field.inputType === "date" && formattedValue) {
            formattedValue = moment(value, UIUtils.DATE_FORMAT_FOR_STORAGE).format(UIUtils.DATE_FORMAT_FOR_DISPLAY);
          } else if (field.inputType === "checkbox") {
            formattedValue = (
              <div className="json-attribute-checkbox">
                <input type="checkbox" disabled={true} checked={formattedValue} />
              </div>
            );
          }
        }

        return (
          <div className="links-manage">
              <span id={id}
                    className={"links-" + field.inputType}
              >
                {formattedValue}
              </span>
            {this.getFieldInstructionsInput(field, rowData)}
          </div>
        );
      }
    }
  }

  /**
   * Overwrite this in child classes to customize the cell input control properties used for rendering a table cell
   * @param field The field to customize the input control for
   * @param rowData The row data is used for assigning a value to the input control from session
   */
  getFieldInput(field, rowData) {
    if (!BaseJsonAttribute.getSupportedFieldTypes().includes(field.inputType)) {
      Logger.verbose(`Field input not supported ${Log.symbol(field.inputType)}. Skipping...`);
      return null;
    }

    let input;
    const {index, uuid} = rowData;
    let onChangeHandler = field.forceUpdate
      ? this.handleChangeFieldAndUpdateParent
      : this.handleChangeField;
    const capitalizedFieldName = CommonUtils.capitalize(field.fieldName);
    const id = this.props.name + "_" + capitalizedFieldName + "_" + index;
    let minValue;
    let selectedDate;
    const editedRow = this.getEditedRowByUUID(uuid);
    let editedRowValue = field.getFormattedValue(this, editedRow);
    const additionalProps = field.getAdditionalProps(this, editedRow);
    switch (field.inputType) {
      case "text":
        input = (
          <input type="text"
                 id={id}
                 className="form-control"
                 data-required-error={field.displayName + " is required."}
                 {...this.getRequiredAttribute(field)}
                 defaultValue={editedRowValue}
                 placeholder={field.placeholder}
                 maxLength={field.maxLength ? field.maxLength : undefined}
                 disabled={this.isFieldDisabled(field, rowData)}
                 onChange={onChangeHandler.bind(this, rowData, field)}
                 {...additionalProps}
          />
        );
        break;
      case "nonEditable":
        input = (
          <span id={id} {...additionalProps}>
            {editedRowValue}
          </span>
        );
        break;
      case "checkbox":
        input = (
          <div id={id + "Div"} className="json-attribute-checkbox">
            <input type="checkbox"
                   id={id}
                   disabled={this.isFieldDisabled(field, rowData)}
                   defaultChecked={editedRowValue}
                   onChange={onChangeHandler.bind(this, rowData, field)}
                   {...additionalProps}
            />
          </div>
        );
        break;
      case "textarea":
        input = (
          <textarea id={id}
                    className="form-control doc-links-textarea"
                    data-required-error={field.displayName + " is required."}
                    {...this.getRequiredAttribute(field)}
                    rows={field.rows}
                    maxLength={field.maxLength ? field.maxLength : undefined}
                    defaultValue={editedRowValue}
                    disabled={this.isFieldDisabled(field, rowData)}
                    onChange={onChangeHandler.bind(this, rowData, field)}
                    {...additionalProps}
          />
        );
        break;
      case "number":
        minValue = this.getFieldMinValue(field);
        input = (
          <input type="number"
                 id={id}
                 step={field.step ? field.step : field.allowDecimalNumbers ? "any" : 1}
                 className="form-control"
            /*Begin data-verification props: this can be overridden by "getAdditionalProps" */
                 min={minValue}
                 max={field.max ? field.max : Number.MAX_SAFE_INTEGER}
                 data-required-error={field.displayName + " is required."}
                 {...(typeof additionalProps.min === "undefined" ? {"data-min-error": (field.displayName + " should be >= " + minValue)} : {})}
                 {...(typeof additionalProps.max === "undefined" ? {"data-max-error": (field.displayName + " should be <= " + field.max)} : {})}
                 {...this.getRequiredAttribute(field)}
            /*End of data-verification props */
                 defaultValue={editedRowValue}
                 placeholder={field.placeholder}
                 disabled={this.isFieldDisabled(field, rowData)}
                 onChange={onChangeHandler.bind(this, rowData, field)}
                 {...additionalProps}
          />
        );
        break;
      case "select":
        Logger.verbose("select: getOptions: ", field.getOptions, this[field.getOptions], this);
        input = (
          <select id={id}
                  className="form-control"
                  defaultValue={editedRowValue}
                  disabled={this.isFieldDisabled(field, rowData)}
                  onChange={onChangeHandler.bind(this, rowData, field)}
                  {...additionalProps}
          >
            {this[field.getOptions](field, rowData.uuid)}
          </select>
        );
        break;
      case "typeahead":
        Logger.verbose("typeahead: getOptions: ", field.getOptions, this[field.getOptions], this);
        if (!Array.isArray(editedRowValue)) {
          editedRowValue = [editedRowValue];
        }

        input = (
          <div id={id + "Div"}>
            <Typeahead id={id}
                       inputProps={{id: id + "Input", autoComplete: "off", maxLength: field.maxLength ?? undefined}}
                       options={this[field.getOptions](field, rowData)}
                       multiple={!!field.multiSelect}
                       disabled={this.isFieldDisabled(field, rowData)}
                       selected={field.autoComplete ? editedRowValue : editedRowValue.map(option => this.getOptionSafely(field, option, false))}
                       onChange={onChangeHandler.bind(this, rowData, field)}
                       allowNew={!!field.autoComplete}
                       async={!!field.async}
                       newSelectionPrefix={field.newSelectionPrefix}
                       {...(field.autoComplete ? {
                         onBlur: (e) => this.handleTypeaheadValueChanged(e, field, rowData),
                       } : {})}
                       ref={control => this.setCellRef(rowData, field, control)}
                       {...additionalProps}
            />
          </div>
        );
        break;
      case "date":
        selectedDate = editedRowValue;
        input = (
          <DatePicker id={id}
                      selected={selectedDate ? moment(selectedDate, UIUtils.DATE_FORMAT_FOR_STORAGE).toDate() : null}
                      onSelect={date => this.setStateSafely(() => ({date}))}
                      {...this.getRequiredAttribute(field)}
                      onChange={onChangeHandler.bind(this, rowData, field)}
                      {...additionalProps}
          />
        );
        break;
      case "customInput":
        if (!Array.isArray(editedRowValue)) {
          editedRowValue = [editedRowValue];
        }
        input = this[field.renderCustomInput](field, editedRowValue, rowData);
        break;
      case "linkIcon":
        return this.getLinkIconForDisplay(field, editedRow);
      default:
        return null;
    }

    let fieldInstructions = this.getFieldInstructions(field, rowData);
    return fieldInstructions ?
      <Fragment>
        <div className="attribute-with-instructions">
          {input}
        </div>
        <FieldTooltip
          id={capitalizedFieldName + "_" + rowData.index + "_"}
          className="links-instructions"
          text={fieldInstructions}
        />
      </Fragment> : input;
  }

  handleTypeaheadValueChanged(event, field, rowData) {
    Ensure.virtual("handleTypeaheadValueChanged", {event, field, rowData});
    return true;
  }

  // noinspection JSMethodCanBeStatic
  /**
   * Given a field this function will return back wither the field is disabled or not during data input
   * @param field {WidgetField} The field to check if it should be disabled or not
   * @param rowData The row data object
   * @returns {boolean|*} True if the provided fields should be disabled during data input, false otherwise
   */
  isFieldDisabled(field, rowData) {
    return field.isDisabled(this, rowData);
  }

  // noinspection JSMethodCanBeStatic
  /**
   * Handler for the click event on fontawesome link icon field types
   * @param link If a link is specified, then the user will be redirected to that link
   */
  handleLinkIconClick(link) {
    if (link) {
      window.open(link, "_blank");
    }
  }

  // noinspection JSMethodCanBeStatic
  /**
   * Used in getFieldInput for rendering the required attribute to input controls, whenever they are required for save.
   * @param field The field to decide if the required attribute will be used or not
   */
  getRequiredAttribute(field) {
    let requireAttribute = {};

    if (field.requiredFor === FIELD_REQUIRED_FOR.save) {
      requireAttribute.required = true;
    } else {
      requireAttribute = null;
    }

    return requireAttribute;
  }

  /**
   * Overwrite this method in child classes to control how a table is reloaded provided a collection of rows
   * @param rowData
   */
  reloadDataTable(rowData) {
    const table = this.refDataTable;
    if (this.refTable.current && table) {
      table.clear();
      this.refDataTable.rows.add(rowData);
      /* This is required for recalculating the columns widths
         https://datatables.net/reference/api/columns.adjust()
       */
      try {
        table.draw();
        // uncomment this when debugging
        // console.warn(">> [BaseJsonAttribute] Adjusting data table widths: ", this.refTable.current, table);
        table.columns.adjust().draw();
      } catch (error) {
        // if an error happens while adjusting column widths, we can ignore it
        Logger.error(`Error adjusting column widths: ${error.message}`, Log.error(error), Log.object(this.refTable.current), Log.object(table));
      }

      this.createChildRows(this.refDataTable);
      $("[data-toggle='popover']").popover({sanitizeFn: UIUtils.sanitizePopoverData});
    } else {
      Logger.warn("Data table is not available:",
        "\n - HTML Table:", Log.object(this.refTable),
        "\n - DataTables object: ", Log.object(this.refDataTable),
        "\n - Data:", Log.object(rowData),
      );
    }
  }

  /**
   * This method will return an object from the underlying JSON string assigned to the object attribute this
   * control will be used for. You can overwrite this in child classes to convert into an object whatever different
   * structure is used to persist the data into the database.
   * @returns {any}
   */
  getValueAsObject() {
    return this.prepareRawData(JSON.parse(this.getValue()));
  }

  // noinspection JSMethodCanBeStatic
  /**
   * This method intercepts the raw data coming from DB and fixes row index inconsistencies that might be caused
   * from features outside of the UI. It will reorganize the indexes of the rows as they are retrieved from the database
   * prioritizing first the rows with index 0. It will also fill in the uuid for any records that are missing it.
   * https://cherrycircle.atlassian.net/browse/QI-2159
   */
  prepareRawData(rows) {
    rows = rows.sort(CommonUtils.sortBy("index"));

    let index = 0;
    for (let row of rows) {
      row.index = index;
      index++;
      row.uuid = this.generateUUID(row);
    }

    return rows;
  }

  /**
   * Updates the state of the parent object with the value that needs to be passed to the backend for persisting it into the database.
   * You can overwrite this in child classes to support different structures other than JSON for more complex scenarios.
   * @param rows
   */
  setValue(rows) {
    this.props.parent.handleChangeValue(this.props.name, JSON.stringify(rows), null, ATTRIBUTE_TYPE.JSON_ATTRIBUTE);
  }

  /**
   * Adds a new row to the rows collection managed by this attribute and assigns the default value for each field
   * as specified in the WIDGET_FIELDS.
   * @param editedRow Optional row provided by a child class, where it is already initialized..
   */
  handleAddToList(editedRow) {
    let rows = this.getValueAsObject();

    editedRow = editedRow ? editedRow : {};
    editedRow.index = rows.length;
    editedRow.uuid = this.generateUUID(editedRow);

    for (let field of this.widgetFields) {
      if (field.defaultValue) {
        field.setValue(editedRow, this.getFieldDefaultValue(field, editedRow));
      }
    }

    editedRow.mode = TABLE_ITEM_MODE.ADD;
    this.editedRows.push(editedRow);
    let clonedRow = UIUtils.deepClone(editedRow);
    rows.push(clonedRow);
    this.setValue(rows);

    this.clearErrorText(true);

    this.notifyModeChanged();
  }

  /**
   * Returns a fields default value as specified in the widget fields. Takes special care of objects specified in the widgets json file
   * returning a clone of them
   * @param field The field to return the default value from
   * @param editedRow The new inline editor row for which the default value will be returned for
   */
  getFieldDefaultValue(field, editedRow) {
    return field.getDefaultValue(this, editedRow);
  }

  /**
   * Turns on the inline editor for the provided row.
   * @param rowData The row data to populate the inline editor controls with
   * @param editedRow Optional the edited row, if this method is called from a child class where the edited row is already initialized.
   * @param forceUpdate Set to true to clear any control errors and force a react update
   */
  handleEdit(rowData, editedRow, forceUpdate = true) {
    let row = this.getValueAsObject().find(row => row.uuid === rowData.uuid);
    editedRow = editedRow ? editedRow : {};
    editedRow.index = row.index;
    if (row.uuid) {
      editedRow.uuid = row.uuid;
    }
    if (row.id) {
      editedRow.id = row.id;
    }

    for (let field of this.widgetFields) {
      field.setValue(editedRow, field.getValue(row));
    }

    editedRow.mode = TABLE_ITEM_MODE.EDIT;
    this.editedRows.push(editedRow);
    this.clearErrorText(forceUpdate);

    this.notifyModeChanged();
  }


  /**
   * Generic handler to update the edited row field with a user provided value
   * @param rowData The row to update the edited row for
   * @param field The field to update with the provided data
   * @param e The event object holding the user provided data
   */
  handleChangeField(rowData, field, e) {
    $("[data-toggle='validator']").validator("update");
    const id = this.props.name + "_" + CommonUtils.capitalize(field.fieldName) + "_" + rowData.index;

    if (field.customValidator) {
      $("#" + id)[0].setCustomValidity("");
    }

    let uuid = rowData.uuid;

    const editedRowValue = this.getEditedRowByUUID(uuid);
    let value = "";
    let shouldForceUpdate = false;

    if (field.inputType === "typeahead") {
      value = e.map(option => option.id);
    } else if (field.inputType === "date") {
      if (e && typeof e.isValid === "function" && e.isValid()) {
        value = e.format(UIUtils.DATE_FORMAT_FOR_STORAGE);
      } else if (!e || e.target.value === "") {
        value = "";
        shouldForceUpdate = true;
      }
    } else if (field.inputType === "checkbox") {
      value = e ? e.target.checked : null;
    } else {
      value = e.target ? e.target.value : e;
    }
    field.setValue(editedRowValue, value);

    let propsToEdit = field.getAdditionalProps(this, editedRowValue) || {};
    for (let [prop, value] of Object.entries(propsToEdit)) {
      event.target.setAttribute(prop, value);
    }

    if (field.onUpdate && typeof this[field.onUpdate] === "function") {
      this[field.onUpdate](value, field, e, rowData);
    }

    if (shouldForceUpdate) {
      this.forceUpdateSafely();
    }
  }

  /**
   * Same as handleChangeField but also forces the parent to redraw
   * @param rowData
   * @param field
   * @param e
   */
  handleChangeFieldAndUpdateParent(rowData, field, e) {
    this.handleChangeField(rowData, field, e);
    this.props.parent.forceUpdate();
  }

  /**
   * Removes the specified row from the collection of rows this control manages and if the parent object is saved,
   * from the database as well
   * @param rowData
   */
  handleDelete(rowData) {
    let rows = this.getValueAsObject();
    let rowIndexToDelete = rows.findIndex(row => row.uuid === rowData.uuid);
    rows.splice(rowIndexToDelete, 1);

    // Reset the indexes
    for (let i = 0; i < rows.length; i++) {
      let editedRow = this.getEditedRowByUUID(rows[i].uuid);
      if (editedRow) {
        editedRow.index = i;
      }
      rows[i].index = i;
    }

    this.setValue(rows);
    this.clearErrorText(true);
  }

  /**
   * Updates the rows collection with the updated edited row data
   * @param rowData The datatables row to update with the edited data
   * @param rows The rows object passed in from the child object updated with the child object edited row fields and values
   * @param setValueCallback
   * @param errorReport {Map<string, string>} A map containing the errors in this operation
   * @returns {boolean} A boolean indicating whether the validation inside this method was successful or not.
   */
  handleSave(rowData, rows, setValueCallback, errorReport = new Map()) {
    rows = rows ?? [];

    $("[data-toggle='validator']").validator("update");
    let editedRow = this.getEditedRowByUUID(rowData.uuid);

    if (this.isEditingRecordValid(editedRow, rows, errorReport)) {
      rows = rows && rows.length !== 0 ? rows : this.getValueAsObject();
      let row = rows.find(row => row.uuid === editedRow.uuid);

      for (let field of this.widgetFields) {
        field.setValue(row, field.getValue(editedRow));
      }
      row.mode = TABLE_ITEM_MODE.VIEW;

      this.editedRows.splice(this.editedRows.indexOf(editedRow), 1);
      this.clearErrorText(true);

      if (setValueCallback) {
        setValueCallback(rows);
      }
      this.notifyModeChanged();
      return true;
    }

    this.showRecordValidationErrors(editedRow, errorReport);

    return false;
  }

  notifyModeChanged() {
    const {onModeChanged} = this.props;

    if (typeof onModeChanged === "function") {
      if (this.editedRows?.length > 0) {
        onModeChanged(TABLE_MODE.EDITOR_OPEN);
      } else {
        onModeChanged(TABLE_MODE.EDITOR_CLOSED);
      }
    }
  }

  /**
   * Cancels pending changes in an edited row and turns off the row inline editor
   * @param rowData The row for which any pending changes should be discarded
   */
  handleCancel(rowData) {
    let editedRow = this.getEditedRowByUUID(rowData.uuid);

    if (editedRow.mode === TABLE_ITEM_MODE.ADD) {
      let rows = this.getValueAsObject();
      let rowIndexToRemove = rows.findIndex(row => row.uuid === editedRow.uuid);

      rows.splice(rowIndexToRemove, 1);
      this.editedRows.splice(this.editedRows.indexOf(editedRow), 1);

      // Reset the indexes
      for (let i = 0; i < rows.length; i++) {
        editedRow = this.getEditedRowByUUID(rows[i].uuid);
        if (editedRow) {
          editedRow.index = i;
        }
        rows[i].index = i;
      }

      this.setValue(rows);
    } else {
      this.editedRows.splice(this.editedRows.indexOf(editedRow), 1);
    }

    this.clearErrorText(true);

    this.notifyModeChanged();
  }

  /**
   * Returns true if the widget has child rows which need to be rendered, false otherwise
   */
  hasChildRows() {
    return this.widgetFields.find(field => {
      return !field.belongsToMasterRow;
    });
  }

  /**
   * Add the extra columns that couldn't fit as a child row.
   */
  createChildRows(table) {
    if (this.hasChildRows()) {
      table.rows().every(rowIdx => {
        let row = table.row(rowIdx);
        this.createChildRow(row);
      });
    }
  }

  /**
   * Creates a new child row given the provided data row
   * @param row The data row for which the child row will be rendered
   */
  createChildRow(row) {
    // Get the TR to render into
    let rowContainer = $("<div></div>");
    let childRow = row.child(rowContainer);
    let rowData = row.data();

    if (rowData.hideDetails) {
      childRow.hide();
    } else {
      childRow.show();

      let tr = row.child()[0];

      // Call the children to render their specific child row / columns
      this.renderChildRow(rowData, tr);
    }
  }

  /**
   * Renders a single child row provided the row data and the tr element which will host the row
   * @param rowData The row data used to render the child row
   * @param tr The tr element within the child row will be rendered
   */
  renderChildRow(rowData, tr) {
    let fieldWidthInfo = {};
    let fieldsInRows = [[]];
    let firstRowFieldsNum = this.widgetFields.filter(field => field.belongsToMasterRow && !field.hidden).length;
    let fieldsInChildRow = this.widgetFields
      .filter(field => !field.belongsToMasterRow && !field.hidden)
      .sort((a, b) => a.order - b.order);
    let fieldWidthType;

    for (const {width} of fieldsInChildRow) {
      let type;
      if (width && typeof width === "string" && width.includes("%")) {
        type = "percentage";
      } else if (width) {
        type = "number";
      }

      if (type && fieldWidthType && fieldWidthType !== type) {
        throw new Error("You cannot specify both numerical and percentage types in the field types of the base_json_attribute.jsx");
      }
      fieldWidthType = type;
    }

    let totalFieldsWidth = fieldsInChildRow.reduce((totalWidth, field) => {
      return totalWidth + (field.width && fieldWidthType === "number" ? field.width : 100);
    }, 0);

    let rowIndex = 0;
    let totalFieldsPercentage = 0;

    for (const field of fieldsInChildRow) {
      const {fieldName, width} = field;

      if (fieldWidthType === "percentage") {
        fieldWidthInfo[fieldName] = width ? UIUtils.parseInt(width.replace("%", "")) : 20;
        totalFieldsPercentage += fieldWidthInfo[fieldName];
        if (totalFieldsPercentage > 100) {
          rowIndex++;
          totalFieldsPercentage = 0;
          fieldsInRows.push([]);
        }
        fieldsInRows[rowIndex].push(field);
      } else {
        fieldWidthInfo[fieldName] = width ? Math.floor(100 * width / totalFieldsWidth) : Math.floor(100 / fieldsInChildRow.length);
        fieldsInRows[rowIndex].push(field);
      }
    }

    rowIndex = 0;

    ReactDOM.render(
      <td colSpan={firstRowFieldsNum + 1}
          className="links-details-row"
      >
        {
          fieldsInRows.map(fieldsInRow => {
            return (
              <div key={rowIndex++}
                   className={"row links-details-fields-row-padding" + (rowIndex - 1 < fieldsInRows.length - 1 ? " links-details-fields-row" : "")}
              >
                {
                  fieldsInRow.map(field => {
                    return (
                      <div key={field.fieldName}
                           style={{width: fieldWidthInfo[field.fieldName] + "%"}}
                           className={"col-xs-" + Math.round(12 / fieldsInChildRow.length) + " links-details-field"}
                      >
                        {this.getChildRowFieldInput(field, rowData)}
                      </div>
                    );
                  })
                }
              </div>
            );
          })
        }
      </td>
      , tr);
  }

  getChildRowFieldTooltip(field, rowData) {
    return (
      <LabelTooltip text={field.displayName + ":"}
                    className="col-form-label links-details-field-label"
                    noColon={true}
                    instructions={this.getFieldInstructionsInput(field, rowData)}
                    getTooltipCallback={this.getFieldTooltipText.bind(this, field)}
      />
    );
  }

  // eslint-disable-next-line no-unused-vars
  getChildRowClass(uuid) {
    return "";
  }

  /**
   * Returns the child row input field used DOM element. Overwrite this in the child classes to
   * customize the child row input and view controls
   * @param field The field for which the input control will be returned
   * @param rowData The rowData for which the input control will be used for
   */
  getChildRowFieldInput(field, rowData) {
    if (!BaseJsonAttribute.getSupportedFieldTypes().includes(field.inputType)) {
      return null;
    }

    const {index} = rowData;
    let uuid = rowData.uuid;
    return (
      <div className={this.getChildRowClass(uuid)}>
        {this.getChildRowFieldTooltip(field, rowData)}
        {this.isRowInEditMode(uuid)
          ? (
            <div id={this.props.name + "_" + CommonUtils.capitalize(field.fieldName) + "FormGroup_" + index}
                 className="form-group"
            >
              <div>
                {this.getFieldInput(field, rowData)}
              </div>
              <div className="help-block with-errors"
                   id={this.props.name + "_" + CommonUtils.capitalize(field.fieldName) + "_" + index + "ErrorDiv"}
              />
            </div>
          )
          : field.inputType === "linkIcon"
            ? (
              <div className="links-manage">
                {this.getFieldValueForDisplay(field, rowData)}
              </div>)
            : (
              <div className="links-manage">
                <span id={this.props.name + "_" + CommonUtils.capitalize(field.fieldName) + "_" + index}
                      className={"links-" + field.inputType}
                >
                  {this.getFieldValueForDisplay(field, rowData)}
                </span>
              </div>
            )
        }
      </div>
    );
  }

  /**
   * Returns the field value as it should be displayed on the UI when not in edit mode
   * @param field The field to format and return the value for
   * @param rowData The rowData for which the field value will be formatted
   */
  getFieldValueForDisplay(field, rowData) {
    let rowValue = field.getValue(rowData);

    if (!this.isDiffingVersions() && field.inputType === "date" && rowValue) {
      return moment(rowValue, UIUtils.DATE_FORMAT_FOR_STORAGE).format(UIUtils.DATE_FORMAT_FOR_DISPLAY);
    } else if (!this.isDiffingVersions() && field.inputType === "linkIcon" && rowValue) {
      return this.getLinkIconForDisplay(field, rowData);
    } else {
      return rowValue;
    }
  }

  /**
   * Returns the react element that renders a link with an icon for a linkIcon type of field
   * @param field The widget field
   * @param rowData The raw data that contain the actual link
   */
  getLinkIconForDisplay(field, rowData) {
    let link = field.getValue(rowData);

    return link ? <a id={this.props.name + "_" + CommonUtils.capitalize(field.fieldName) + "_" + rowData.index}
                     onClick={this.handleLinkIconClick.bind(this, link)}
                     disabled={false}
                     {...field.getAdditionalProps(this, rowData)}
    >
      <FontAwesomeIcon icon={field.icon} />
    </a> : "";
  }

  /**
   * Prepares the table data for display when we are viewing the attribute in version diff mode and
   * just returns the underlying data when viewing in edit or view mode
   * @param additionalFields fields that is in the object passed but may not be part of original WIDGETS_FIELDS
   */
  preProcessDataForTable(additionalFields = []) {
    additionalFields = this.widgetFields.concat(additionalFields);
    if (this.isDiffingVersions()) {
      let oldDocLinksJSON = this.getOldValue(this.props.name);
      let oldDocLinks = JSON.parse(!oldDocLinksJSON ? "[]" : oldDocLinksJSON);
      let newDocLinks = this.getValueAsObject();
      let diffedRowData;

      //Properly format the date fields before diffing
      for (let dateField of additionalFields.filter(field => field.inputType === "date")) {
        for (let oldLink of oldDocLinks.filter(link => link[dateField.fieldName])) {
          oldLink[dateField.fieldName] = moment(oldLink[dateField.fieldName], UIUtils.DATE_FORMAT_FOR_STORAGE).format(UIUtils.DATE_FORMAT_FOR_DISPLAY);
        }
        for (let newLink of newDocLinks.filter(link => link[dateField.fieldName])) {
          newLink[dateField.fieldName] = moment(newLink[dateField.fieldName], UIUtils.DATE_FORMAT_FOR_STORAGE).format(UIUtils.DATE_FORMAT_FOR_DISPLAY);
        }
      }

      //Properly format the checkbox fields before diffing
      for (let checkboxField of additionalFields.filter(field => field.inputType === "checkbox")) {
        for (let oldLink of oldDocLinks.filter(link => link[checkboxField.fieldName])) {
          oldLink[checkboxField.fieldName] = oldLink[checkboxField.fieldName] ? "Yes" : "No";
        }
        for (let newLink of newDocLinks.filter(link => link[checkboxField.fieldName])) {
          newLink[checkboxField.fieldName] = newLink[checkboxField.fieldName] ? "Yes" : "No";
        }
      }

      //The linkIcons are not diffed, so we just prepare the HTML element we need to render for them here
      for (let linkIcon of additionalFields.filter(field => field.inputType === "linkIcon")) {
        for (let oldLink of oldDocLinks.filter(link => link[linkIcon.fieldName])) {
          oldLink[linkIcon.fieldName] = this.getLinkIconForDisplay(linkIcon, oldLink);
        }
        for (let newLink of newDocLinks.filter(link => link[linkIcon.fieldName])) {
          newLink[linkIcon.fieldName] = this.getLinkIconForDisplay(linkIcon, newLink);
        }
      }

      let attributeOptions = this.typeaheadField ? this[this.typeaheadField.getOptions](this.typeaheadField.field) : null;

      diffedRowData = createRowDataDiffForJSONAttribute(this.props.name, oldDocLinks, newDocLinks, additionalFields,
        attributeOptions, this.handleFileDownload);
      return diffedRowData;
    } else {
      return this.getValueAsObject();
    }
  }

  /**
   * Validates the record being edited before saving it. Every field provided in the WIDGET_FIELDS that has the validation
   * mode set to save is used for the end result. The edited record is marked as invalid when at least one of the edited row
   * fields are marked as invalid.
   * @param editedRow {*} The row data of the row being edited
   * @param rows {[]} The array with all the rows in the control
   * @param errorReport {Map<string, *>} An object containing information about the errors that occurred
   * @return {boolean} true if the record is valid, false otherwise.
   */
  isEditingRecordValid(editedRow, rows, errorReport) {
    rows = rows ?? [];
    let index = editedRow.index;
    let isValid = (
      !errorReport
      || errorReport.size === 0
      || !([...errorReport.values()]).some(value => value.length > 0)
    );

    if (index >= 0) {
      for (let field of this.widgetFields) {
        isValid = this.isEditingRowFieldValid(editedRow, field, rows, errorReport) && isValid;
      }
    }

    return isValid;
  }

  /**
   * Validates the input field before saving for the row being edited and return true if it is valid, false otherwise.
   * Overwrite this method in child classes to customize the default validation behavior of any input field.
   * @param editedRow {*} The rows data of the row being edited
   * @param field {*} The field to validate
   * @param rows {[]} The array with all the rows in the control
   * @param errorReport {Map<string, *>} A map containing the error information
   * @return {boolean} true if the field is valid, false otherwise.
   */
  isEditingRowFieldValid(editedRow, field, rows, errorReport) {
    rows = rows ?? [];
    let isValid = true;
    let fieldInput = $("#" + this.props.name + "_" + CommonUtils.capitalize(field.fieldName) + "_" + editedRow.index);
    let value = null;

    if (field.customValidator && fieldInput[0]) {
      fieldInput[0].setCustomValidity("");
    }
    if (field.inputType === "typeahead") {
      isValid = this.isTypeaheadRequired(isValid, editedRow, field, rows, errorReport);
    } else {
      let idInput = fieldInput.trigger("input")[0];
      if (idInput) {
        isValid = (!idInput.validity || (isValid && idInput.validity.valid));
        Logger.verbose(Log.object(field.getFieldPath()), "IS VALID after checking input validity", Log.object(isValid), Log.object(field.customValidator));
      } else {
        value = field.getValue(editedRow);
        isValid = isValid && !((field.requiredFor === FIELD_REQUIRED_FOR.save) && (!value || value === ""));
        Logger.verbose(Log.object(field.getFieldPath()), "IS VALID after checking required", Log.object(isValid));
      }
    }

    isValid = this.isEntryUnique(isValid, editedRow, field, rows, errorReport);

    Logger.verbose(Log.object(field.getFieldPath()), "IS VALID after isEntryUnique", Log.object(isValid), Log.object(field.customValidator));

    if (field.customValidator) {
      isValid = this[field.customValidator](field, editedRow) && isValid;
    }

    Logger.verbose(Log.object(field.getFieldPath()), "IS VALID (final)", Log.object(isValid), Log.object(field.customValidator));

    return isValid;
  }

  /**
   * Validates whether the current row field is unique (if applicable)
   * @param isValid {boolean} The current validation result (before this step)
   * @param editedRow {*} The currently edited row
   * @param field {*} The field being edited
   * @param rows {*[]} All rows in the control
   * @param errorReport {Map<string, *>} An object containing information about the errors that occurred
   * @return {boolean}
   */
  isEntryUnique(isValid, editedRow, field, rows, errorReport) {
    rows = rows || [];

    let value = this.getFieldValueInRow(field, editedRow);

    if (field.isUnique && value) {
      const existing = rows.find(item => {
        const fieldValueInRow = this.getFieldValueInRow(field, item);
        Logger.verbose(() => "Comparing items: ",
          "\n Current Row:", Log.json(fieldValueInRow),
          "\n Edited Row:", Log.json(value),
        );
        return fieldValueInRow === value && item.uuid !== editedRow.uuid;
      });
      isValid = isValid && !existing;

      if (!isValid) {
        let errors = errorReport.get("unique") || [];
        errors.push({field, editedRow, existingRow: existing, allRows: rows});
        errorReport.set("unique", errors);
      }
      Logger.verbose(
        "Unique entry validation result: ",
        Log.json(isValid),
        "\nValue:",
        Log.object(value),
        "\nEditedRow: ",
        Log.object(editedRow),
        "\nExisting:",
        Log.object(existing),
        "\nField: ",
        Log.object(field),
        "\nRows:",
        Log.object(rows),
        "\nErrorReport:",
        Log.object(errorReport),
      );
    }
    return !!isValid;
  }

  isTypeaheadRequired(isValid, editedRow, field, rows, errorReport) {
    let value = this.getFieldValueInRow(field, editedRow);
    let errors = errorReport.get("required") || [];
    if (field.requiredFor === FIELD_REQUIRED_FOR.save) {
      let isRequiredValid = Array.isArray(value)
        ? (value?.length > 0)
        : (value?.toString()?.trim()?.length > 0);

      if (!isRequiredValid) {
        errors.push({field, editedRow, allRows: rows});
        isValid = false;
        errorReport.set("required", errors);
      }
    }

    Logger.verbose(Log.object(field.getFieldPath()), "IS REQUIRED VALID: ", Log.object(isValid), Log.object(field), Log.object(editedRow), Log.object(errors));
    return isValid;
  }

  /**
   * Gets the value for the specified field within the specified row
   * @param row {*} The row to get the value from
   * @param field {*} The field to get the value from
   * @return {*} The value of the field
   */
  getFieldValueInRow(field, row) {
    return (row && field.getValue(row)) || null;
  }

  /**
   * Verifies if the given field is missing while it is being required for proposal. This is used in validate method to
   * show an error when at least one or more fields are required before being able to propose an object for approval.
   * @param row The data row which is being validated for proposal
   * @param field The field which is being validated for proposal
   */
  isFieldMissingForProposal(row, field) {
    let fieldValue = field.getValue(row);

    if (fieldValue === 0) {
      return false;
      // do not allow 0
      // return true;
    }

    return (
      !fieldValue
      || fieldValue === ""
      || (fieldValue instanceof Array && fieldValue.length === 0)
    );
  }

  /**
   * Shows the validation errors in a particular row before the row is saved.
   * @param editedRow {*} The row to show an error for
   * @param errorReport {Map<string, *>} The error messages to display
   */
  showRecordValidationErrors(editedRow, errorReport) {
    let index = editedRow.index;

    let errorFormats = new Map([
      ["unique", (fieldName) => `${fieldName} must be unique.`],
      ["required", (fieldName) => `${fieldName} is required.`],
    ]);

    if (index >= 0) {
      let errors = [];

      for (let [errorType, formatter] of errorFormats.entries()) {
        const errorsOfType = errorReport.get(errorType) || [];
        for (let error of errorsOfType) {
          error.errorType = errorType;
          error.formatter = formatter;
          errors.push(error);
        }
      }

      if (errors.length > 0) {
        for (let error of errors) {
          const field = error.field;
          const errorDiv = $("#" + this.props.name + "_" + CommonUtils.capitalize(field.fieldName || "LinkTo") + "_" + index + "ErrorDiv");
          const formatter = error.formatter;

          if (errorDiv && errorDiv.length && typeof (formatter) === "function") {
            const message = error.message || formatter(this.getFieldDisplayName(field));
            errorDiv.toggleClass("has-error", true);
            errorDiv.toggleClass("has-danger", true);
            errorDiv.text(message);
            Logger.debug(() => ">> +BASE ERROR", message, `#${this.props.name}_LinkToFormGroup_${index}`, `#${this.props.name}_LinkTo_${index}ErrorDiv`, errorDiv.text());
          } else {
            Logger.error("A verification error happened, but formatter or output div has not been found: ", Log.object(errorDiv), Log.object(error));
          }
        }
      } else {
        for (let field of this.widgetFields) {
          const fieldSelector = "#" + this.props.name + "_" + CommonUtils.capitalize(field.fieldName || "LinkTo") + "_" + index;
          // Uncomment for verbose logging
          // console.log("Highlighting " + fieldSelector + " for field: ", field);
          $(fieldSelector).trigger("input");
        }
      }
    }
  }

  /**
   * Given a provided row id it returns back the edited row data from the edited rows collection
   * @param id The row unique id for which the edited row is requested
   */
  getEditedRowByUUID(id) {
    return this.editedRows.find(row => row.uuid === id);
  }

  /**
   * Given a provided row id, it returns true if the row is in edit mode or false otherwise
   * @param id The row unique index to query for
   */
  isRowInEditMode(id) {
    return !!this.editedRows.find(row => row.uuid === id);
  }

  /**
   * Updates the UI with a custom error
   * @param error The error to show on the UI
   * @param errorType The error type based on if it was raised due to a save or propose action.
   */
  setError(error, errorType = CUSTOM_ERROR_TYPE.FOR_SAVE) {
    this.customError.error = error;
    this.customError.includesErrorForPropose = this.customError.includesErrorForPropose
      || errorType === CUSTOM_ERROR_TYPE.FOR_PROPOSE;
    this.customError.includesErrorForSave = this.customError.includesErrorForSave
      || errorType === CUSTOM_ERROR_TYPE.FOR_SAVE;
  }

  /**
   * Returns back a DOM object to hold the custom error, which it can be from a simple string to an array of strings.
   */
  getError() {
    return (!this.customError.error || typeof this.customError.error === "string") ?
      this.customError.error : this.customError.error.join("\n");
  }

  /**
   * @returns {*} true if the control has at least one row in edit mode.
   */
  hasRowInEditMode() {
    return (this.editedRows || []).length > 0;
  }

  /**
   * Clears any previous errors still showing up on the UI
   * @param forceUpdate
   */
  clearErrorText(forceUpdate) {
    Logger.verbose(() => ">>> CLEAR ERROR TEXT", forceUpdate);
    this.customError = {
      error: null,
      includesErrorForSave: false,
      includesErrorForPropose: false
    };

    if (forceUpdate && this._isMounted) {
      this.forceUpdate();
    }
  }

  /**
   * Override this function in child classed to enable/disable the ability of a record to be edited
   * @returns {boolean}
   */
  enableRecordEditing() {
    return true;
  }

  /**
   * Takes care of expanding or collapsing a child row given the provided row index.
   * @param rowIndex The index to expand or collapse a row for
   * @param td The table cell within the expand or collapse button will be rendered
   */
  handleExpandCollapseDetails(rowIndex, td) {
    let row = this.refDataTable.row(rowIndex);

    if (row.child.isShown()) {
      ReactDOM.render(
        (<FontAwesomeIcon id={this.props.name + "_ExpandCollapseButton_" + rowIndex}
                          icon={faPlusSquare}
                          size="3x"
                          className="table-details-control"
                          aria-label="Show details"
                          onClick={this.handleExpandCollapseDetails.bind(this, rowIndex, td)}
          />
        ), td);
      row.child.hide();
    } else {
      ReactDOM.render(
        (<FontAwesomeIcon id={this.props.name + "_ExpandCollapseButton_" + rowIndex}
                          icon={faMinusSquare}
                          size="3x"
                          className="table-details-control"
                          aria-label="Show details"
                          onClick={this.handleExpandCollapseDetails.bind(this, rowIndex, td)}
          />
        ), td);
      row.child.show();
    }
  }

  /**
   * Validates the data this specific attribute holds against proposing. For each field in each row that is not
   * good for proposing, it renders an error on the UI.
   * @param formValidationMode Checks if validation is running against save or propose
   * @returns {boolean} True if the save/propose can happen. False otherwise.
   */
  validate(formValidationMode) {
    const rows = this.getValueAsObject();
    let isValid = this.validateEditedRows();

    if (isValid && this.props.parent.isInProposeValidationMode()) {
      let missingFieldsForProposal = [];
      let errors = [];

      for (let i = 0; i < rows.length; i++) {
        const rowData = rows[i];
        for (let field of this.widgetFields.filter(field => {
          return field.requiredFor === FIELD_REQUIRED_FOR.proposal && !field.isDisabled(this, rowData);
        })) {
          if (this.isFieldMissingForProposal(rowData, field) && !missingFieldsForProposal.includes(field)) {
            missingFieldsForProposal.push(field);
          }
        }
      }

      // Order the fields with errors based on the fields order significance number
      // And push the field display name into the errors array.
      missingFieldsForProposal.sort((a, b) => {
        return a.order - b.order;
      }).map(field => {
        errors.push("* " + field.displayName);
      });

      if (errors.length > 0) {
        errors.unshift("The following fields are missing from one or more rows above:");
        this.setError(errors, CUSTOM_ERROR_TYPE.FOR_PROPOSE);
        this.forceUpdateSafely();
        isValid = false;
      }

      if (this.isRequiredForProposing() && !this.isView() && rows.length === 0) {
        errors.push(`At least one ${this.props.displayName} link is required`);
        this.setError(errors, CUSTOM_ERROR_TYPE.FOR_PROPOSE);
        this.forceUpdateSafely();
        isValid = false;
      }
    }

    if (isValid && this.props.onValidate) {
      const functionMethod = this.props.onValidate((errors) => {
        if (errors && errors.length > 0) {
          this.setError(errors);
          this.forceUpdateSafely();
          isValid = false;
        }
      }, formValidationMode, rows);

      if (UIUtils.isPromise(functionMethod)) {
        return functionMethod.then(errors => {
          if (errors && errors.length > 0) {
            this.setError(errors);
            this.forceUpdateSafely();
            return false;
          } else {
            this.clearErrorText(true);
            return true;
          }
        });
      }
    }

    if (isValid) {
      this.clearErrorText(true);
    }

    return isValid;
  }

  validateEditedRows() {
    if (this.editedRows.length > 0) {
      this.setError(this.errorMessages.editedRowPending);
      this.forceUpdateSafely();
      return false;
    }
    return true;
  }

  getInputId() {
    return this.props.name;
  }

  clearValidationErrors(forceUpdate = true) {
    this.clearErrorText(false);

    if (!this.isView() && this._isMounted && forceUpdate) {
      this.forceUpdate();
    }
  }

  /**
   * Returns true or false, depending on if the controls add button should be disabled or not during rendering.
   */
  isAddButtonDisabled() {
    return this.isDisabled();
  }

  /**
   * Returns the add button disabled reason which shows up as a tooltip for the add button when it is disabled.
   */
  getAddButtonDisabledReason() {
    return "No more rows can be added.";
  }

  /**
   * @param rowData {*} The data for the row being rendered.
   * @returns {boolean} true or false, depending on if the delete row button should be disabled or not*
   */
  isDeleteButtonDisabled(rowData) {
    Ensure.virtual("isDeleteButtonDisabled", {rowData});
    return false;
  }

  /**
   * Returns the delete button disabled reason which shows up as a tooltip for the add button when it is disabled.
   * @param rowData {*} The data for the row being rendered.
   */
  getDeleteButtonDisabledReason(rowData) {
    Ensure.virtual("getDeleteButtonDisabledReason", {rowData});
    return "This row cannot be deleted.";
  }

  isEditButtonDisabled(rowData) {
    Ensure.virtual("isEditButtonDisabled", {rowData});
    return false;
  }

  getEditButtonDisabledReason(rowData) {
    Ensure.virtual("getEditButtonDisabledReason", {rowData});
    return "This row cannot be changed.";
  }

  getAdditiveSkeletonClass() {
    return "skeleton-table";
  }

  render() {
    let input = this.renderInput();
    let inputId = this.getInputId();
    let visible = input && (typeof this.props.visible === "boolean" ? this.props.visible : true);
    let isProposeValidationModeOn = this.props.parent.isInProposeValidationMode();
    let showError = (this.customError.error && this.customError.includesErrorForSave)
      || (isProposeValidationModeOn && this.customError.includesErrorForPropose);

    return (
      visible ?
        (<div className={"attribute-container " + this.props.className}>
          {this.props.hideTableCaption ? "" : this.renderLabel(inputId)}
          <div id={inputId + "Div"}
               className={"base-json-attribute " + inputId + " " + (this.isView() ? "view-attribute" : "form-group")}
          >
            {input}
          </div>
          <div id={inputId + "ErrorDiv"}
               className={"form-group" + (this.isView() ? " base-json-attribute-view-error-div" : " base-json-attribute-error-div")}
          >
            {showError ? //This is a trick to trigger a validation error using bootstrap validator. This input is never visible on the UI.
              <input type="text"
                     id={inputId + "HiddenInput"}
                     required={true}
                     className="base-json-attribute-error-input"
                     data-error={this.getError()}
                     data-validate="true"
              />
              : ""}
            <div className={showError ? "help-block with-errors" : "d-none"}
                 id={inputId + "CustomErrorDiv"}
            />
          </div>
        </div>) :
        null
    );
  }

  renderAddButton(inputId) {
    const {addButtonText} = this.props;

    const id = inputId + "AddToListButton";
    const disabled = this.isAddButtonDisabled();
    const disabledReason = this.getAddButtonDisabledReason();
    const onClick = () => this.handleAddToList(null);
    const displayName = this.getDisplayName();

    return <ActionButton
      id={id}
      label={addButtonText}
      disabled={disabled}
      disabledReason={disabledReason}
      onClick={onClick}
      attributeName={displayName}
    />;
  }


  renderInput() {
    let inputId = this.getInputId();
    return (
      <div id="baseLinksAttributesTable">
        {this.props.tableDescription ? (
          <div className="table-description">
            <div>
              <FontAwesomeIcon icon={faInfoCircle} className="table-description-info" />
            </div>
            <div>
              <span>{this.props.tableDescription}</span>
            </div>
          </div>
        ) : ""}
        <table ref={this.refTable}
               className={"table table-bordered table-hover doc-links-table" + this.getClassForLoading()}
               id={inputId + "Table"}
               style={{
                 width: "100%"
               }}
        >
        </table>
        {this.isView() ? "" : this.renderAddButton(inputId)}
      </div>
    );
  }

  setCellRef(rowData, field, control) {
    let cellRef = this.getCellRef(rowData, field);
    if (cellRef) {
      cellRef.control = control;
    }
    return cellRef;
  }

  getCellRef(rowData, field) {
    if (!rowData || !rowData.uuid) {
      return null;
    }
    let rowRef = this.rowReferences.get(rowData.uuid);
    if (!rowRef) {
      rowRef = new Map();
      this.rowReferences.set(rowData.uuid, rowRef);
    }

    let cellRef = rowRef.get(field.getFieldPath());
    if (!cellRef) {
      cellRef = {};
      rowRef.set(field.getFieldPath(), cellRef);
    }
    return cellRef;
  }
}

BaseJsonAttribute.defaultProps = {
  addButtonText: "Add",
  onModeChanged: () => {
  },
};
