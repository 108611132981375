"use strict";

/*This is a tooltip class to render a nice informative message for the user when one or more users need to be
  selected for a particular operation but they are not available in the list. The Tooltip will show a message to the
  user and list the system administrators to be contacted if additional access is required.*/

import * as UIUtils from "../../ui_utils";
import React from "react";
import { getURLByKey } from "../../helpers/url_helper";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseReactComponent from "../../base_react_component";

// i18next-extract-mark-ns-start editor
class ProjectAdminsTooltip extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {users, projectId, projectName, message, t} = this.props;

    return users ? (<div className="col-sm-12"
                         id="projectAdminsTooltip"
    >
      <p>{message}</p>
      <p>
        <div>{t("Your current Administrators are:")}</div>
        <ul>
          {
            users.filter(user => user.isAdmin).map(user =>
              <li key={`$admin-div-${user.id}`}>
                <a href={"mailto:" + user.email} rel="noopener noreferrer" target="_blank">{(user.fullName ? user.fullName : user.firstName + " " + user.lastName) + " <" + user.email + ">"}</a>
              </li>
            )
          }
        </ul>
      </p>
      {projectId ? (
        <p>
          <p> Please include the project link found below.</p>
          <p>
            <div> Project: {
              <a href={getURLByKey("PRJ-" + projectId, "View")} rel="noopener noreferrer" target="_blank" id="projectAdminsTooltipProjectLink">
                {UIUtils.getRecordLabelForDisplay("PRJ", projectId, projectName)}
              </a>}
            </div>
          </p>
        </p>
      ) : ""}
    </div>) : "";
  }
}

export default I18NWrapper.wrap(ProjectAdminsTooltip, "editor");
// i18next-extract-mark-ns-stop editor
