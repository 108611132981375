"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ErrorBar from "../../widgets/bars/error_bar";
import BasePage from "../../base_page";
import UserListTable from "./tables/user_list_table";
import CompanyListTable from "./tables/company_list_table";
import TabNavBar from "../../widgets/bars/tab_nav_bar";
import TrialBar from "../../widgets/bars/trial_bar";
import CompanyHeader from "../../widgets/headers/company_header";
import CookiesBar from "../../widgets/bars/cookies_bar";
import TermsPopup from "../../terms/terms_popup";


const ADMIN_TABS_ENUM = {
  USERS: {title: "Users"},
  COMPANIES: {title: "Companies"},
};

export default class AdminPanelListPage extends BasePage {
  constructor(props) {
    super(props);

    this.renderPage = this.renderPage.bind(this);
    this.state = {
      selectedTab: null,
      tabs: ADMIN_TABS_ENUM,
      users: [],
      selectedCompanyUsers: [],
      companies: [],
      selectedCompany: null,
    };

    // Bind stuff
    this.handleTabChanged = this.handleTabChanged.bind(this);
    this.handleCompanySelected = this.handleCompanySelected.bind(this);
    this.handleClickGoBack = this.handleClickGoBack.bind(this);
    this.handleClickCompanyOnUser = this.handleClickCompanyOnUser.bind(this);

    this.setStateSafely({
      breadcrumb: {
        parent: "Admin Dashboard",
        parentLink: "/admin/internal/listOfUsers.html?activeTab=Companies",
      }
    });
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = "Admin Dashboard";
    UIUtils.secureAjaxGET("admin/getAdminResource").done(
      ({users, companies, usersExtraInfo}) => {
        const result = this.joinResult(users, usersExtraInfo, companies);
        this.setStateSafely({
          companies: result.companies,
          users: result.users,
          selectedTab: UIUtils.getParameterByName("activeTab") === "Companies" ? ADMIN_TABS_ENUM.COMPANIES : ADMIN_TABS_ENUM.USERS,
        });
      }
    );
  }

  joinResult(users, usersExtraInfo, companies) {
    const newUsers = users.map(user => {
      const extraInfo = usersExtraInfo.find(x => x.userId === user.id);
      if (extraInfo) {
        user = {...user, ...extraInfo};
      }
      return user;
    });

    const newCompanies = companies.map(company => {
      let totalLogins = 0;
      let totalProjects = 0;
      let totalActiveUsers = 0;
      for (const user of newUsers) {
        if (user.Company && user.Company.id === company.id) {
          totalLogins += user.TotalLogins ? user.TotalLogins : 0;
          totalProjects += user.TotalProjects ? user.TotalProjects : 0;
          totalActiveUsers += 1;
        }
      }
      return {...company, totalLogins, totalProjects, totalActiveUsers};
    });

    return {users: newUsers, companies: newCompanies};
  }

  handleTabChanged(tab) {
    this.setStateSafely({selectedTab: tab}, () => {
      const {pathname} = window.location;
      const url = tab.title === ADMIN_TABS_ENUM.COMPANIES.title ? `${pathname}?activeTab=Companies` : pathname;
      history.replaceState(this.state, "", url);
    });
  }

  handleCompanySelected(company) {
    const {id, name} = company;
    const users = this.state.users.filter(user => user && user.Company && user.Company.id === id);
    const newTab = {title: `Users in ${name}`};

    this.setStateSafely({
      selectedCompanyUsers: users,
      tabs: {...ADMIN_TABS_ENUM, newTab},
    }, () => {
      this.handleTabChanged(newTab);
    });
  }

  handleClickGoBack() {
    this.setStateSafely({
      selectedCompanyUsers: [],
      tabs: ADMIN_TABS_ENUM,
      selectedTab: ADMIN_TABS_ENUM.COMPANIES
    });
  }

  handleClickCompanyOnUser(company) {
    this.setStateSafely({selectedCompany: company}, () => {
      this.handleTabChanged(ADMIN_TABS_ENUM.COMPANIES);
    });
  }

  renderTable() {
    const {selectedCompany, companies, users, selectedCompanyUsers, selectedTab} = this.state;

    if (selectedTab?.title === ADMIN_TABS_ENUM.COMPANIES.title) {
      return (
        <CompanyListTable
          selectedCompany={selectedCompany}
          companies={companies}
          onSelectCompany={this.handleCompanySelected}
          resetSelectedCompany={() => this.setStateSafely({selectedCompany: null})}
        />
      );
    }

    if (selectedTab?.title === ADMIN_TABS_ENUM.USERS.title) {
      return <UserListTable users={users} onClickCompany={this.handleClickCompanyOnUser} />;
    }

    return (
      <>
        <a onClick={this.handleClickGoBack}> &larr; Go back</a>
        <UserListTable
          id={UIUtils.stripAllWhitespaces(selectedTab?.title)}
          users={selectedCompanyUsers}
          onClickCompany={this.handleClickCompanyOnUser}
        />
      </>
    );
  }

  renderPage() {
    const {selectedTab, tabs} = this.state;

    return (
      <div id="adminInternal">
        <div className="container-spacer" />
        <div className="container-fluid">
          <ErrorBar />
          <div className="row row-white shadow overflow-auto">
            <TabNavBar
              selected={selectedTab}
              onTabChanged={this.handleTabChanged}
              tabs={UIUtils.deepClone(tabs)}
              parent={this}
            />
            <div className="row row-white">{this.renderTable()}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="bodyDiv">
        <TrialBar minified />
        <CompanyHeader
          minified
          title="Admin Dashboard"
          breadcrumb={this.state.breadcrumb}
        />
        {this.renderPage()}
        <CookiesBar />
        <TermsPopup modalRef={termsPopup => this.termsPopup = termsPopup}
                    onApproveButtonCLick={this.handleTermsPopupApproveButtonClick}
        />
      </div>
    );
  }
}
