"use strict";

import React from "react";
import { ProjectMaterialOptionsCard } from "./project_material_options_card";
import { ProjectMaterialOptions } from "./project_material_options";

/**
 *
 * @param showInCard
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function ProjectMaterialOptionsContainer({showInCard, ...props}) {
  return (
    <div className="col">
      {showInCard ?
        <ProjectMaterialOptionsCard>
          <ProjectMaterialOptions {...props} />
        </ProjectMaterialOptionsCard>
        :
        <ProjectMaterialOptions {...props} />
      }
    </div>
  );
}