"use strict";

import * as UIUtils from "../../../ui_utils";
import {
  getRawRiskScore,
  getRiskLinks,
  getRiskScale,
  hasNotAssessedRiskScale
} from "../../../helpers/risk_helper";
import {
  NEEDS_ANALYSIS_TERM,
  REPORT_OPTIONS_ENUM,
  RISK_LABEL_ACTION,
  RISK_TABLE_REPORTS_ENUM
} from "../../constants/report_constants";
import { EMPTY_STRING, RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import { isCritical } from "../../../rmps/rmp_helper";
import { getRowSortPriorityForModelType, getSortPriority } from "./risk_tables_sorter";
import RiskUtils from "../../../../server/common/misc/common_risk_utils";

export function calculateSum(columnAttribute) {
  if (!columnAttribute.riskInfo) {
    throw new Error("Missing risk info");
  }

  let sum = columnAttribute.riskLinks.reduce((sum, value) => {
    return sum + getRawRiskScore(RISK_TYPE_ENUM.CRITICALITY, null, value, value.detailedRiskLinks);
  }, 0);
  return sum > 0 ? sum : `${RiskUtils.NOT_ASSESSED_SCALE} (${RiskUtils.NOT_ASSESSED_LABEL})`;
}

export function destroyDataTables(remove) {
  let tables = $("table.risk-table");
  for (let i = 0; i < tables.length; i++) {
    const table = tables[i];
    if ($.fn.DataTable.isDataTable(table)) {
      $(table).DataTable().destroy(remove);
      $(table).empty();
    }
  }
}

/**
 * Identifies whether a data row has risk links
 * @param row {*} The data item to be checked
 * @return {boolean}
 */
export function doesItemHaveRiskLinks(row) {
  return !!(row.riskLinks && row.riskLinks instanceof Array &&
    row.riskLinks.filter(link => link.IQAId || link.FQAId || link.IPAId || link.FPAId || link.GeneralAttributeId).length > 0);
}


/**
 * Sorts the data to display, so that the IQAs appear before FQAs each group sorted by ID
 * @param link1 {*} The first link to be evaluated
 * @param link2 {*} The second link to be evaluated
 * @return {number} A negative number if lesser than. A positive number of greater than. Zero if equal.
 */
export function sortRiskLinks(link1, link2) {
  return link1.linkedTypeCode > link2.linkedTypeCode ? -1 : (
    link1.linkedTypeCode < link2.linkedTypeCode ? 1 : (
      link1.linkedId - link2.linkedId
    )
  );
}

/**
 * Returns the formatted key for a risk link entry
 * @param link
 * @return {string}
 */
export function getRiskLinkKey(link) {
  return `${link.linkedTypeCode}-${link.linkedId}`;
}

/**
 * Returns the text to be displayed as the justification
 * @param item {*} The object containing the justification
 * @return {string} A string with the text to be displayed (or "None" if empty)
 */
export function getJustification(item) {
  return item.justification && item.justification.toString().trim() !== "" ? item.justification : "None";
}

/**
 * Determines whether this report type is a pivot table
 * @param reportType {string} Either a value or a key from the {RISK_TABLE_REPORTS_ENUM} enumeration.
 * @returns {boolean} A boolean indicating whether or not the specified report is a pivot table.
 */
export function isPivot(reportType) {
  let options = getRiskTableOptions(reportType);
  return options.isPivot;
}

/**
 * Determines whether this report type allows selecting a Unit Operation.
 * @param reportType {string} Either a value or a key from the {RISK_TABLE_REPORTS_ENUM} enumeration.
 * @returns {boolean} A boolean indicating whether or not the specified report should allow selecting a unit operation.
 */
export function allowSelectUO(reportType) {
  let options = getRiskTableOptions(reportType);
  return options.allowSelectUO;
}

/**
 * Retrieves the options for a risk table sub-report
 * @param reportType {string} Either a value or a key from the {RISK_TABLE_REPORTS_ENUM} enumeration.
 * @returns {*} An object with the options for a risk table sub-report.
 */
export function getRiskTableOptions(reportType) {
  // The reportType could be either the value or the key of the enum, so we handle this here:
  let [typeKey, ignored] = Object.entries(RISK_TABLE_REPORTS_ENUM)
    .find(([key, value]) => value === reportType || key === reportType);

  return REPORT_OPTIONS_ENUM.RiskTablesDataReport.editableModelNames[typeKey];
}

export function getStampFromState(state) {
  return state !== UIUtils.VERSION_STATES.APPROVED ? "[NOT APPROVED]" : "";
}

/*
 * WARNING: The following two methods are tricky.
 *
 * The map that comes from the parameter is used before these methods are called to ensure no duplicate cols are added.
 * However, not all rows have all columns defined (this is a pivot table), so, it ends up with its columns added in
 * an order that is not the same we expect in the UI.
 *
 * In order to avoid reinventing the wheel when resorting, we use `Array.sort` here.
 * To be able to do so, we need to put the items into an array and sort them.
 *
 * However, the code that uses the result of this still expects a map, and then enumerates its items to display
 * the columns. The columns appear in the UI according to the order from that enumeration (`for...of`).
 *
 * So we need to ensure the resulting map is sorted.
 *
 * In order to do so, we have to re-add them into a map in the sorted order, so when it gets enumerated in the UI,
 * the columns will appear in the correct order
 *
 * This pivot tables code was really complex. So, in order to do the smallest intervention possible we do this
 * unmap/sort/remap logic here.
 */

/**
 * Extracts the items from a map, sorts them, and return them in a map that contains the items added in order, according
 * to the correct sorting logic.
 * @param columnsMap {Map<Number, any>} The map with the columns to be sorted
 * @param reportSettings configs for the report
 * @return {Map<any, any>} An Map containing the columns in the correctly sorted order.

 * @param unitOperationId
 * @param columnsMap {Map<Number, any>} The map with the columns to be sorted
 * @param globalColumnsLocationMap a map with all columns mapped to thier location
 * @param reportSettings configs for the report
 * @return {Map<any, any>} An Map containing the columns in the correctly sorted order.
 */
export function getSortedMap(unitOperationId, orderedSteps, columnsMap, globalColumnsLocationMap, reportSettings) {
  let sortedLocationColumns = sortedColumnGroups(unitOperationId, orderedSteps, columnsMap, globalColumnsLocationMap);
  const sortedColumns = new Map();

  for (const [, value] of sortedLocationColumns.entries()) {
    let filteredColumns = new Map([...columnsMap.entries()].filter(column => value.has(column[0])));
    const columns = getSortedColumnsFromMap(filteredColumns, reportSettings);

    for (let {key, value} of columns) {
      sortedColumns.set(key, value);
    }
  }
  return sortedColumns;
}

/**
 * Since we are showing pivot table columns grouped by steps now. we need to ensure the column groups are sorted with the step, and each column inside the column group having the original alphabetical sorting as well.
 * @param unitOperationId
 * @param columnsMap
 * @param columnsLocationMap
 */
export function sortedColumnGroups(unitOperationId, orderedSteps, columnsMap, columnsLocationMap) {
  const idSeparator = "-";
  const locationIds = [];

  if (orderedSteps.length > 0) {
    orderedSteps
      .map(step => `${getLocationId(unitOperationId, step.id)}`)
      .forEach(locationId => locationIds.push(locationId));
  }

  let sortedLocationColumns = new Map([...columnsLocationMap.entries()]
    .filter((entry) => entry[0].startsWith(unitOperationId))
    .filter((entry) => entry[0].indexOf(idSeparator) !== -1)
    .sort((entry1, entry2) => {
      return locationIds.indexOf(entry1[0]) - locationIds.indexOf(entry2[0]);
    }));

  // Columns with no steps.
  let uoColumns = columnsLocationMap.get(`${unitOperationId}`) ?? null;

  if (uoColumns) {
    sortedLocationColumns.set(unitOperationId, uoColumns);
  }

  return sortedLocationColumns;
}

/**
 * Extracts the items from a map and puts them into a sorted array
 * @param columnsMap {(() => IterableIterator<unknown>) | ((key: unknown, value: unknown) => ObjectConstructor) | ((key: unknown) => boolean) | string | ((key: unknown) => unknown) | (() => IterableIterator<[unknown, unknown]>) | number | (() => void) | ((callbackfn: (value: unknown, key: unknown, map: Map<unknown, unknown>) => void, thisArg?: any) => void)} The map with the columns to be sorted
 * @param reportOptions The settings for this report
 * @return *{[]} An array containing the columns in the correctly sorted order.
 */
export function getSortedColumnsFromMap(columnsMap, reportOptions) {
  let columns = [];
  for (let [key, value] of columnsMap.entries()) {
    columns.push({key, value});
  }

  columns.sort((i1, i2) => {
    const key1 = UIUtils.parseKey(i1.key);
    const key2 = UIUtils.parseKey(i2.key);

    let sortResult = getSortPriority(reportOptions, key1.typeCode) - getSortPriority(reportOptions, key2.typeCode);

    if (sortResult === 0) {
      const textA = i1.value.toUpperCase();
      const textB = i2.value.toUpperCase();
      sortResult = (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }

    if (sortResult === 0) {
      sortResult = key1.id - key2.id;
    }

    return sortResult;
  });
  return columns;
}

export function getPrefixForSorting(result, props, sortingProp = "targetModel") {
  const {reportSettings} = props;
  const index = getRowSortPriorityForModelType(reportSettings, result[`${sortingProp}Type`]);
  // in order to make datatables sort properly, we sort the index and the name a 0-padded string
  return index >= 0
    ? index.toString().padStart(6, "0") + result[sortingProp]
    : result[sortingProp];
}

export function getRiskScoreWithLabel(record, riskType, rmp, showRawScore,
                                      showRiskLabel, includeLabel = true, defaultValue = RiskUtils.NOT_ASSESSED_LABEL) {

  // Keeps backwards compatibility with the many calls of this method that are passing a boolean
  if (typeof(showRiskLabel) === "string") {
    return getRiskScoreOrEffectWithLabel(record, riskType, rmp, showRawScore, showRiskLabel, includeLabel, defaultValue);
  }

  return getRiskScoreWithLabelCore(riskType, record, rmp, showRiskLabel, showRawScore, includeLabel, defaultValue);
}

function getRiskScoreWithLabelCore(riskType, record, rmp, showRiskLabel, showRawScore, includeLabel, defaultValue) {
  if (!record.riskInfo || !record.riskInfo[riskType]) {
    throw Error("Missing RiskInfo");
  }

  let rawRiskScore = record.riskInfo[riskType].value;
  let normalisedRiskScore = record.riskInfo[riskType].normalizedValue;

  let riskScale = showRiskLabel && riskType === RISK_TYPE_ENUM.CRITICALITY ? record.riskInfo[riskType].scaleForRiskLabel : record.riskInfo[riskType].scale;
  let riskLinks = getRiskLinks(record);
  let hasNotAssessed = hasNotAssessedRiskScale(riskType, rmp);
  const hasValidValue = hasNotAssessed && (riskLinks.length > 0 || record.detailedRiskLinks === false) || record.obligatoryCQA;
  if (hasNotAssessed && !record.obligatoryCQA && (defaultValue === NEEDS_ANALYSIS_TERM || defaultValue === RiskUtils.NOT_ASSESSED_LABEL || defaultValue === "")) {
    defaultValue = `${RiskUtils.NOT_ASSESSED_SCALE} (${RiskUtils.NOT_ASSESSED_LABEL})`;
  }

  if (riskScale) {
    let riskScoreValue = (rawRiskScore || hasValidValue) ? (showRawScore ? `${rawRiskScore}` : `${normalisedRiskScore}%`) : "";
    return (riskScoreValue || hasValidValue) ? includeLabel ?
      `${riskScoreValue} (${showRiskLabel ? riskScale.riskLabel : riskScale.scoreLabel})` :
      riskScoreValue : defaultValue;
  } else {
    return defaultValue;
  }
}

export function getShowRiskLabelFromLabelAction(labelAction, options = {isAggregation: false}) {
  return labelAction === RISK_LABEL_ACTION.SHOW_RISK_LABEL || (labelAction === RISK_LABEL_ACTION.SHOW_EFFECT_LABEL && options?.isAggregation);
}

export function getRiskScoreOrEffectWithLabel(record, riskType, rmp, showRawScore, labelAction, includeLabel = true, defaultValue = RiskUtils.NOT_ASSESSED_LABEL) {
  let result = "";

  const recordHasValues = Object.keys(record).filter(key => record[key] !== undefined).length > 0;
  if (!recordHasValues) {
    return result;
  }

  let showRiskLabel = getShowRiskLabelFromLabelAction(labelAction);
  result = getRiskScoreWithLabelCore(riskType, record, rmp, showRiskLabel, showRawScore, includeLabel, defaultValue);

  // When we're showing the effect label and the risk is set for this cell, we show a hyphen instead of empty to express
  // that the relationship exists, but there's no effect set.
  if (result && labelAction === RISK_LABEL_ACTION.SHOW_EFFECT_LABEL) {
    result = record?.effect ?? "";

    if (String(result).trim().length === 0) {
      result = "-";
    }
  }
  return result;
}

export function getRiskTableId(unitOperationId, includeHash = false) {
  let tableId = (unitOperationId !== -1 ? "riskTable_" + unitOperationId : "riskTable");
  return includeHash ? `#${tableId}` : tableId;
}

export function getCriticalOnlyData(tableData, criticalOnly, rmp) {
  return criticalOnly ? tableData.filter(record => {
    // we compute the criticality on a single risk link (not on entire record)
    let hasRiskInfo = record.linkRiskInfo &&  record.linkRiskInfo[RISK_TYPE_ENUM.CRITICALITY];
    if (hasRiskInfo) {
      record.isCritical = record.linkRiskInfo[RISK_TYPE_ENUM.CRITICALITY].isCritical;
    }
    else {
      let rawCriticality = getRawRiskScore(RISK_TYPE_ENUM.CRITICALITY, rmp, record, record.detailedRiskLinks);
      let criticalityScale = getRiskScale(RISK_TYPE_ENUM.CRITICALITY, rmp, rawCriticality, record, true);
      if (criticalityScale) {
        record.isCritical = isCritical(criticalityScale);
      }
    }

    return record.isCritical;
  }) : tableData;
}

/**
 * Builds an object location.
 * @param unitOperationId
 * @param stepId
 * @returns {string}
 */
export function getLocationId(unitOperationId, stepId) {
  return `${unitOperationId}${stepId ? "-" + stepId : EMPTY_STRING}`;
}

export function getQuickPanelLinkId(typeCode, id) {
  return `${typeCode}-${id}`.toLowerCase();
}

export function getReportSettings(reportKey) {
  return REPORT_OPTIONS_ENUM.RiskTablesDataReport.editableModelNames[reportKey];
}
