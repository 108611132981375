"use strict";

/**
 * This file exports constants used mostly by React components in the data tab.
 * @type {{COA: string, BATCH: string}}
 */

const BATCH_RECORD_TYPE_ENUM = {
  BATCH: "Batch",
  COA: "CoA",
  LIBRARY_COA: "Library CoA",
};

const BATCH_DATA_TYPE_ENUM = {
  RELEASE_DATA: "Release Data",
  MANUFACTURING_DATA: "Manufacturing Data",
};

const BATCH_DATES_ENUM = {
  START_DATE: "Start Date",
  MANUFACTURE_DATE: "Manufacture Date",
  RELEASE_DATE: "Release Date",
  EXPIRATION_DATE: "Expiration Date",
};

const BATCH_PURPOSE_OPTIONS = [
  "Characterization",
  "Clinical (Phase 1)",
  "Clinical (Phase 2)",
  "Clinical (Phase 3)",
  "Formulation",
  "Preclinical",
  "Process Development (Analytical)",
  "Process Development (Early Phase)",
  "Process Development (Late Phase)",
  "Reference Standard",
  "Research & Development",
  "Stability",
  "Other"
];

module.exports = {
  BATCH_RECORD_TYPE_ENUM,
  BATCH_DATES_ENUM,
  BATCH_DATA_TYPE_ENUM,
  BATCH_PURPOSE_OPTIONS
};