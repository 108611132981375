"use strict";

/**
 * All excel columns we expect to see in an import sheet.
 * @param numOfCells The number of cells to retrieve.
 * @returns {[]}
 */
module.exports.getExcelHeaders = function(numOfCells) {
  let headers = [];
  for (let i = 0; i < numOfCells; i++) {
    headers.push(exports.getExcelColumnAtIndex(i));
  }

  return headers;
};

/**
 * This gets the next Excel Header cell given a starting cell and a number of columns to skip.
 * @param startingCell The excel starting cell column, i.e "A" or "AB", etc...
 * @param numberOfColumnsToSkip The number of columns to skip. Use a positive integer to pick some excel column on the
 * right or a negative integer to pick some column on the left.
 */
module.exports.getNextExcelHeaderCell = function(startingCell, numberOfColumnsToSkip) {
  let columnIndex = exports.getIndexFromExcelColumn(startingCell) + numberOfColumnsToSkip;
  return exports.getExcelColumnAtIndex(columnIndex);
};

/**
 * Gets the excel column header that is located at the given index. The index is a base 10 integer number and it is zero based.
 * Given for example index 0, this returns column "A". With index 1 it returns "B"and so on...
 * @param index The numberical index to get the excel column header at.
 * @returns {string}
 */
module.exports.getExcelColumnAtIndex = function(index) {
  let base = 26;
  let column = "";
  while (index / base >= 1) {
    column += String.fromCharCode(65 + index % base);
    index = (index - index % base) / base - 1;
  }
  column += String.fromCharCode(65 + index % base);

  return column.split("").reverse().join("");
};

/**
 * This gets back the base 10 integer number representing the index (location) of a given excel header in a spreadsheet.
 * The index is 0 based, so given column "A", this return back 0, column "B", 1 and so on...
 * @param column The excel header column to get the index position in the spreadsheet back.
 * @returns {number}
 */
module.exports.getIndexFromExcelColumn = function(column) {
  let base = 26;
  let index = 0;
  for (let i = 1; i <= column.length; i++) {
    let charValue = column.charCodeAt(i - 1) - 64;
    index += charValue * Math.pow(base, column.length - i);
  }

  return index - 1;
};
