"use strict";

import React from "react";
import * as UIUtils from "../../../ui_utils";
import BaseReactComponent from "../../../base_react_component";
import { createHTMLForTextDiff, createHTMLForWholeTextDiff } from "../../../helpers/diff_helper";
import TechTransferViewChangeCriteriaTooltip from "./tech_transfer_view_change_criteria_tooltip";
import { formatValue } from "../../../configurableTables/fieldsConfig/fields_formatter";
import { getPropertyWithFormattedValue } from "../../diff/tech_transfer_diff_generator";
import { isPropertyDefined } from "../../../../server/common/generic/common_utils";

/**
 * This class is responsible for rendering the diff row of the review screen.
 */
export default class TechTransferReviewRow extends BaseReactComponent {
  render() {
    const {
      name,
      prop,
      displayName,
      transferredFrom,
      transferredTo,
      usePartialDiff,
      hideUnchangedFields,
      totalChangesCount,
      changeCriteria,
      onViewChangeCriteria,
      isDocs,
      isDate,
    } = this.props;

    const {config} = changeCriteria || {};

    const propertyInObject = getPropertyWithFormattedValue(transferredFrom, name);
    const transferredFromValue = this.formatValue(transferredFrom[propertyInObject], transferredFrom, name, isDocs, isDate);
    const transferredToValue = this.formatValue(transferredTo[propertyInObject], transferredTo, name, isDocs, isDate);

    const diffFunction = usePartialDiff ? createHTMLForTextDiff : createHTMLForWholeTextDiff;
    const diff = diffFunction(isDocs && transferredFromValue === "None" && transferredToValue !== "None" ?
      "" : transferredFromValue, transferredToValue, true);

    // There is a diff and it's within the change criteria
    const showRedDot = config && totalChangesCount > 0 && transferredFromValue !== transferredToValue && config.includes(prop || name);

    // There is a diff and it's not within the change criteria
    const showGreyDot = config && transferredFromValue !== transferredToValue && !config.includes(prop || name);
    const title = displayName || UIUtils.convertCamelCaseToSpacedOutWords(name);

    return (hideUnchangedFields &&
      ((transferredFromValue === transferredToValue) ||
        (!transferredFromValue && !transferredToValue))) ? "" :
      (
        <div className="row">
          <div className="col-4 tech-transfer-review-row-container">
            <span className={`${showRedDot ? "red-dot mr-1" : showGreyDot ? "grey-dot mr-1" : ""}`} />
            {showRedDot || showGreyDot ?
              <TechTransferViewChangeCriteriaTooltip title={title}
                                                     changeWithinCriteria={showRedDot}
                                                     onViewChangeCriteria={onViewChangeCriteria}
              /> :
              <span>{title}</span>}
          </div>
          <div className="col-4 tech-transfer-review-row-container">
            {createHTMLForTextDiff(transferredFromValue, transferredFromValue)}
          </div>
          <div className="col-4 tech-transfer-review-row-container">
            {diff}
          </div>
        </div>
      );
  }

  formatValue(value, record, prop, isDocs = false, isDate = false) {
    if (!isDocs && !isDate) {
      const defaultFallbackCallback = () => "0 " + record[prop + "Label"];
      const fieldsWithFallbackValues = {
        maxCriticality: () => defaultFallbackCallback(),
        capabilityRisk: () => defaultFallbackCallback(),
        processRisk: () => defaultFallbackCallback(),
        processRiskPercWithoutLabel:  () => record["processRiskRiskLabel"],
        detectabilityRisk: () => defaultFallbackCallback(),
        RPN: () => defaultFallbackCallback(),
        RPNPercWithoutLabel:  () => record["RPNRiskLabel"],
      };

      if ((value === 0 || (value !== null && value !== undefined && isNaN(value) && value.toString().indexOf("%") === -1) || value === "0%") && isPropertyDefined(fieldsWithFallbackValues, prop)) {
        value = fieldsWithFallbackValues[prop]();
      }
    }

    return formatValue(value, record, prop, isDocs, isDate);
  }
}
