"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseAttribute from "./base_attribute";
import { createHTMLForTextDiff } from "../../helpers/diff_helper";

export default class NumberAttribute extends BaseAttribute {
  constructor(props) {
    super(props);

    // Bind functions to this
    this.renderInput = this.renderInput.bind(this);
  }

  // noinspection JSMethodCanBeStatic
  getInitialValue() {
    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
    if (!shouldUpdate) {
      let value = this.getNextValue(nextProps);
      shouldUpdate = (this.currentValue !== value) ||
        (nextProps.type !== this.props.type) ||
        (nextProps.max !== this.props.max) ||
        (nextProps.min !== this.props.min);
    }
    return shouldUpdate;
  }

  renderInput() {
    let input = "Input type not defined: " + this.props.type;
    let inputId = this.getInputId();
    this.currentValue = this.getValue() ? this.getValue() : "";
    // Uncomment for verbose logging
    // console.log("Render: Generating " + this.getInputId() + " with value: " + value);
    if (this.isView()) {
      if (this.isDiffingVersions()) {
        let oldValue = this.getOldValue(this.props.name);
        input = createHTMLForTextDiff(oldValue, this.currentValue);
      } else {
        input = (<span id={inputId}>{this.currentValue}</span>);
      }
    } else {
      let dataErrorMsg = this.getCapitalizedName() + " is required.";
      input = (
        <input type="number"
               max={this.props.max}
               min={this.props.min}
               step="any"
               className="form-control"
               id={inputId}
               data-validate="true"
               data-required-error={dataErrorMsg}
               required={this.isRequired()}
               value={UIUtils.isNumber(this.currentValue) ? this.currentValue : ""}
               disabled={this.isDisabled()}
               onChange={this.handleChange} />

      );
    }

    return input;
  }
}
