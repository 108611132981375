var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { guid, classNames, dispatchEvent, getTabIndex, createPropsContext, usePropsContext, kendoThemeMaps } from '@progress/kendo-react-common';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
/**
 * Represents the PropsContext of the `RadioButton` component.
 * Used for global configuration of all `RadioButton` instances.
 *
 * For more information, refer to the [Inputs Props Context]({% slug props-context_inputs %}) article.
 */
export var RadioButtonPropsContext = createPropsContext();
/**
 * Represents the [KendoReact RadioButton component]({% slug overview_radiobutton %}).
 *
 * Accepts properties of type [RadioButtonProps]({% slug api_inputs_radiobuttonprops %}).
 * Obtaining the `ref` returns an object of type [RadioButtonHandle]({% slug api_inputs_radiobuttonhandle %}).
 */
export var RadioButton = React.forwardRef(function (directProps, target) {
    var _a;
    validatePackage(packageMetadata);
    var props = usePropsContext(RadioButtonPropsContext, directProps);
    var ariaDescribedBy = props.ariaDescribedBy, checked = props.checked, children = props.children, className = props.className, disabled = props.disabled, id = props.id, size = props.size, label = props.label, labelPlacement = props.labelPlacement, name = props.name, style = props.style, tabIndex = props.tabIndex, value = props.value, valid = props.valid, onChange = props.onChange, onFocus = props.onFocus, onBlur = props.onBlur, others = __rest(props, ["ariaDescribedBy", "checked", "children", "className", "disabled", "id", "size", "label", "labelPlacement", "name", "style", "tabIndex", "value", "valid", "onChange", "onFocus", "onBlur"]);
    var elementRef = React.useRef(null);
    var focusElement = React.useCallback(function () {
        if (elementRef.current) {
            elementRef.current.focus();
        }
    }, []);
    var getImperativeHandle = React.useCallback(function () { return ({
        element: elementRef.current,
        focus: focusElement
    }); }, [focusElement]);
    React.useImperativeHandle(target, getImperativeHandle);
    var calculatedId = React.useMemo(function () { return guid(); }, []);
    var handleChange = React.useCallback(function (event) {
        dispatchEvent(onChange, event, getImperativeHandle(), { value: value });
    }, [onChange, value, disabled]);
    var handleFocus = React.useCallback(function (event) {
        if (onFocus && !disabled) {
            dispatchEvent(onFocus, event, getImperativeHandle(), undefined);
        }
    }, [onFocus, disabled]);
    var handleBlur = React.useCallback(function (event) {
        if (onBlur && !disabled) {
            dispatchEvent(onBlur, event, getImperativeHandle(), undefined);
        }
    }, [onBlur, disabled]);
    var inputProps = __assign({ type: 'radio', id: id || calculatedId, name: name, className: classNames('k-radio', (_a = {},
            _a["k-radio-".concat(kendoThemeMaps.sizeMap[size] || size)] = size,
            _a['k-invalid'] = valid === false,
            _a), className), ref: elementRef, disabled: disabled, tabIndex: getTabIndex(tabIndex, disabled), checked: checked, style: style, 'aria-describedby': ariaDescribedBy, value: value, onChange: handleChange, onFocus: handleFocus, onBlur: handleBlur }, others);
    var radioButtonLabel = (React.createElement(React.Fragment, null, label !== undefined ? (React.createElement("label", { className: 'k-radio-label', htmlFor: id || calculatedId, style: { userSelect: 'none' }, "aria-label": label }, label)) : null));
    var radio = React.createElement("input", __assign({}, inputProps));
    return (labelPlacement === 'before'
        ? (React.createElement(React.Fragment, null,
            radioButtonLabel,
            radio,
            children)) : (React.createElement(React.Fragment, null,
        radio,
        radioButtonLabel,
        children)));
});
RadioButton.propTypes = {
    ariaDescribedBy: PropTypes.string,
    checked: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    size: PropTypes.oneOf([null, 'small', 'medium', 'large']),
    label: PropTypes.string,
    labelPlacement: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.object,
    tabIndex: PropTypes.number,
    value: PropTypes.any,
    valid: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
};
var defaultProps = {
    size: 'medium'
};
RadioButton.displayName = 'KendoRadioButton';
RadioButton.defaultProps = defaultProps;
