"use strict";

import React from "react";
import { WIDGET_FIELDS } from "../constants/rmp_risk_scale_attribute_constants";
import { RISK_ATTRIBUTES_TABS } from "../constants/rmp_constants";
import RMPRiskScaleAttribute, { RMP_RISK_ATTRIBUTE_TYPE } from "./rmp_risk_scale_attribute";

/**
 * This is class is responsible for handling Risk Management Plan Process Risk records.
 */
export default class RMPProcessRisksAttribute extends RMPRiskScaleAttribute {
  constructor(props) {
    super(props, WIDGET_FIELDS);

    this.errorMessages.editedRowPending = "At least one process risk row is in edit state. Please save or cancel all process risk rows first.";
    this.type = RMP_RISK_ATTRIBUTE_TYPE.PROCESS_RISK;
    this.tabContainerType = RISK_ATTRIBUTES_TABS.PROCESS_RISK_SCALE;
    this.sourceParameter1 = "Capability Risk";
    this.sourceParameter2 = "Criticality";
    this.minSourceParameter1 = "minCapabilityRisk";
    this.maxSourceParameter1 = "maxCapabilityRisk";
    this.minSourceParameter2 = "minCriticality";
    this.maxSourceParameter2 = "maxCriticality";
  }
}
