import * as React from 'react';
/**
 * @hidden
 */
export function useDir(elementRef, initialDir, args) {
    // Dir prop is read only on initial rendering due to specifics of getComputedStyles - see below
    var _a = React.useState(initialDir), dir = _a[0], setDir = _a[1];
    React.useEffect(function () {
        if (!dir && window && elementRef.current) {
            // Note: getComputedStyle forces reflow
            var rtlCandidate = window.getComputedStyle(elementRef.current).direction;
            if (rtlCandidate) {
                // rerender is needed as DOM is read after first render
                setDir(rtlCandidate);
            }
        }
    }, args);
    return dir;
}
