"use strict";

import * as UIUtils from "../../ui_utils";
import BaseAttribute from "./base_attribute";
import React from "react";
import LabelTooltip from "../../widgets/tooltips/label_tooltip";
import { createHTMLForArrayDiff } from "../../helpers/diff_helper";
// noinspection NpmUsedModulesInstalled
import * as PropTypes from "prop-types";

// i18next-extract-mark-ns-start widgets
export class CheckboxListAttribute extends BaseAttribute {
  constructor(props) {
    super(props);

    this.formatDiffValue = this.formatDiffValue.bind(this);
  }

  getInitialValue() {
    return "[]";
  }

  handleChange(event, option) {
    let value = this.getParsedValue();
    if (event.target.checked) {
      if (!value.includes(option.value)) {
        value.push(option.value);
      }
    } else {
      value = value.filter(item => item !== option.value);
    }

    // Uncomment for verbose logging
    // console.log("Changing " + this.props.name + " to ", value);
    this.props.parent.handleChangeValue(this.getAttributeName(), JSON.stringify(value));
  }

  getOldValue(attributeName) {
    let oldValue = super.getOldValue(attributeName);

    if (oldValue && typeof (oldValue) === "string") {
      oldValue = JSON.parse(oldValue) || [];
    }
    return oldValue;
  }

  renderInput() {
    if (this.isView()) {
      if (this.isDiffingVersions()) {
        return this.renderDiff();
      } else {
        return this.renderView();
      }
    } else {
      return this.renderAddOrEdit();
    }
  }

  renderView() {
    const {
      options,
      t,
    } = this.props;

    const inputId = this.getInputId();
    const selectedOptions = options.filter(option => this.getParsedValue() && this.getParsedValue().includes(option.value));

    if (!selectedOptions || selectedOptions.length === 0) {
      return t("None");
    }

    return (
      <div>
        {selectedOptions.map(option => {
          const baseId = `${inputId}_${option.value}`;

          return (
            <div key={option.key}>
              <LabelTooltip
                id={`${baseId}Label`}
                tooltipText={this.getTooltipText(option)}
                style={this.props.labelStyle ? this.props.labelStyle : {}}
                text={this.getOptionDisplayName(option)}
                noColon={true}
              />
            </div>
          );
        })}
      </div>
    );
  }

  renderDiff() {
    const value = this.getParsedValue() || [];
    const oldValue = this.getOldValue(this.getAttributeName());

    const diff = createHTMLForArrayDiff(oldValue, value, this.formatDiffValue);

    return (
      <div>
        {
          diff.map((item, index) => {
            return (
              <div key={`${this.getInputId()}_diff_${index}`}>{item}</div>
            );
          })
        }
      </div>
    );
  }

  renderAddOrEdit() {
    const {
      options,
      labelStyle,
    } = this.props;

    return (
      <div>
        <div className={"form-group"}>
          {options.map(option => {
            const baseId = this.getBaseId(option);

            const checkboxId = `${baseId}CheckBox`;
            return (
              <div className={"col-sm-4"} key={option.key}>
                <LabelTooltip
                  id={`${baseId}Label`}
                  className={"col-form-label"}
                  tooltipText={this.getTooltipText(option)}
                  style={labelStyle ? labelStyle : {}}
                  htmlFor={checkboxId}
                  text={(
                    <span>
                    <input
                      type={"checkbox"}
                      id={checkboxId}
                      checked={this.getOptionValue(option)}
                      disabled={this.isOptionDisabled(option)}
                      onChange={(event) => this.handleChange(event, option)}
                    />
                    <span className={`control-text-checkbox-edit ${this.getTooltipText(option) ? " col-form-label-tooltip" : ""}`}>
                      {this.getOptionDisplayName(option)}
                    </span>
                  </span>
                  )}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  getTooltipText(option) {
    return typeof this.props.formatTooltip === "function" ? this.props.formatTooltip(option) : "";
  }

  getOptionValue(option) {
    const value = this.getParsedValue();
    return !!value.includes(option.value);
  }

  isOptionDisabled(option) {
    return false;
  }

  getOptionDisplayName(option) {
    return option.displayName || t(option.value);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      let value = this.getNextValue(nextProps);
      return (JSON.stringify(this.getParsedValue()) !== JSON.stringify(value));
    }
    return true;
  }

  getParsedValue() {
    const baseValue = super.getValue();
    const value = typeof (baseValue) === "string" ? JSON.parse(baseValue) : baseValue;
    return Array.isArray(value) ? value : [];
  }

  getValue() {
    const baseValue = super.getValue();
    return baseValue || "[]";
  }

  formatDiffValue(value, className) {
    const {options} = this.props;
    const option = options.find(option => option.value === value);

    if (option) {
      const baseId = this.getBaseId(option);
      return (
        <LabelTooltip
          id={`${baseId}Label`}
          className={className}
          tooltipText={this.getTooltipText(option)}
          style={this.props.labelStyle ? this.props.labelStyle : {}}
          text={this.getOptionDisplayName(option)}
          noColon={true}
        />
      );
    } else {
      return value;
    }
  }

  getBaseId(option) {
    const inputId = this.getInputId();
    return `${inputId}_${UIUtils.convertToId(option.value)}`;
  }
}

CheckboxListAttribute.propTypes = {
  t: PropTypes.func,
  formatTooltip: PropTypes.func,
};

CheckboxListAttribute.defaultProps = {
  t: value => value,
  formatTooltip: () => undefined,
};
// i18next-extract-mark-ns-stop widgets
