"use strict";

/**
 * This defines the widget fields configuration for the experimental record supporting documents widget.
 * @type {IWidgetField[]}
 */

import { TEXT_DIFF_METHOD_ENUM } from "../../../helpers/constants/constants";
import { FIELD_INPUT_TYPE, FIELD_REQUIRED_FOR } from "../../widgets/widget_field";


export const WIDGET_FIELDS = [
  {
    isUnique: true,
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    requiredFor: FIELD_REQUIRED_FOR.save,
    forceUpdate: false,
    belongsToMasterRow: true,
    order: 1,
    inputType: FIELD_INPUT_TYPE.linkedTypeahead,
    width: 250,
    ignoredOnModels: [],
    orderable: false,
  },
  {
    displayName: "Effect on Attribute",
    fieldName: "effect",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    requiredFor: FIELD_REQUIRED_FOR.none,
    forceUpdate: false,
    belongsToMasterRow: true,
    order: 2,
    inputType: FIELD_INPUT_TYPE.typeahead,
    autoComplete: true,
    newSelectionPrefix: "Add new effect: ",
    getOptions: "getEffectOptions",
    multiSelect: false,
    useDisplayName: true,
    width: 90,
    tooltipText: "The effect field captures the nature of the relationship between the attribute or parameter and the downstream value. This field helps better explain what is happening in this particular interaction. For example, if the downstream value is impurities, the effect label would clarify if they 'added' or 'removed' impurities by this relationship.",
    ignoredOnModels: ["fqa", "fpa"],
    disabled: false,
    maxLength: 255,
    orderable: false
  },
  {
    fieldName: "impact",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    displayName: "Impact/Severity",
    requiredFor: "proposal",
    forceUpdate: true,
    defaultValue: "getDefaultImpactOption",
    placeholder: "",
    belongsToMasterRow: true,
    order: 3,
    inputType: FIELD_INPUT_TYPE.select,
    getOptions: "getImpactOptions",
    width: 90,
    ignoredOnModels: [],
    orderable: false,
  },
  {
    fieldName: "uncertainty",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    displayName: "Uncertainty/Likelihood",
    requiredFor: "proposal",
    forceUpdate: true,
    defaultValue: "getDefaultUncertaintyOption",
    placeholder: "",
    belongsToMasterRow: true,
    disabled: "useUncertainty",
    order: 4,
    inputType: FIELD_INPUT_TYPE.select,
    getOptions: "getUncertaintyOptions",
    width: 90,
    ignoredOnModels: [],
    orderable: false,
  },
  {
    fieldName: "criticality",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    displayName: "Criticality",
    requiredFor: FIELD_REQUIRED_FOR.none,
    forceUpdate: false,
    belongsToMasterRow: true,
    order: 5,
    inputType: FIELD_INPUT_TYPE.criticality,
    width: 1,
    ignoredOnModels: [],
    orderable: false,
  },
  {
    fieldName: "justification",
    diffMethod: TEXT_DIFF_METHOD_ENUM.differential,
    displayName: "Justification",
    tooltipText: "Impact and uncertainty values should be justified by literature, data, or other science-based rationales.",
    requiredFor: "proposal",
    forceUpdate: false,
    defaultValue: "",
    placeholder: "",
    belongsToMasterRow: false,
    order: 6,
    rows: 4,
    ignoredOnModels: [],
    inputType: FIELD_INPUT_TYPE.textarea,
    orderable: false,
  }]
;

