"use strict";

// eslint-disable-next-line no-unused-vars
import * as stimulsoftReports from "./stimulsoftBinaries/stimulsoft.reports.pack";
// eslint-disable-next-line no-unused-vars
import * as stimulsoftViewer from "./stimulsoftBinaries/stimulsoft.viewer.pack";
// eslint-disable-next-line no-unused-vars
import * as stimulsoftCSS from "./stimulsoftBinaries/stimulsoft.viewer.office2013.whiteblue.css";

import * as UIUtils from "../ui_utils";
import React from "react";
import BasePage from "../base_page";
import { getReportOptions, registerStimulsoftLicense } from "./canned_reports/canned_report_helper";
import { REPORT_OPTIONS_ENUM, REPORT_TITLES_ENUM, REPORT_TYPES_ENUM } from "./constants/report_constants";
import CannedReport from "./canned_reports/canned_report";
import RequirementSummaryCannedReport from "./canned_reports/canned_report_requirement_summary";
import SpecificationCannedReport from "./canned_reports/canned_report_specification";
import FMEACannedReport from "./canned_reports/canned_report_fmea";
import QTPPFullCannedReport from "./canned_reports/canned_report_qtpp_full";
import IndividualTrainingReport from "./canned_reports/training/canned_report_individual_training_report";
import DocumentTrainingReport from "./canned_reports/training/canned_report_document_training_report";
import TrainingMatrixReport from "./canned_reports/training/canned_report_training_matrix_report";
import Heading from "../widgets/headers/heading";
import { orderAndIndexUnits } from "../processExplorer/indexers/uo_indexer";
import * as ProcessCache from "../processExplorer/process/process_cache";
import CompanyHeader from "../widgets/headers/company_header";
import FullObjectCache from "../utils/cache/full_object_cache";
import { loadRMP } from "../helpers/risk_helper";


/* This class shows both canned and chart reports */
export default class DataReportPage extends BasePage {
  constructor(props) {
    super(props);

    this.projectId = UIUtils.getParameterByName("projectId") || null;
    const processId = UIUtils.getParameterByName("processId")
      || ProcessCache.getProcessIdUsedRecently(this.projectId);
    this.reportType = UIUtils.getParameterByName("reportType");
    this.modelLabel = UIUtils.getParameterByName("modelLabel");
    const versionId = UIUtils.getParameterByName("versionId");
    const parentId = UIUtils.getParameterByName("parentId");
    this.reportOptions = getReportOptions(this.reportType);

    if (this.reportType !== REPORT_TYPES_ENUM.ControlChartsContinuousReport &&
      this.reportType !== REPORT_TYPES_ENUM.LibraryControlChartsContinuousReport &&
      this.reportType !== REPORT_TYPES_ENUM.LibraryControlChartsDefectivesReport &&
      this.reportType !== REPORT_TYPES_ENUM.ControlChartsDefectivesReport) {
      registerStimulsoftLicense();
    }

    this.loadTypeaheadCache(processId);

    this.state = {
      title: REPORT_TITLES_ENUM[this.reportType],
      project: null,
      typeaheadOptions: [],
      dependenciesTypeahead: [],
      selectedTypeaheadOption: {},
      versionId,
      parentId,
      processId
    };

    if (this.projectId) {
      new FullObjectCache("Project", this.projectId).loadOptions(this.handleObjectCacheResultsFromServer, {includeAllVersions: true});
    } else {
      this.handleObjectCacheResultsFromServer(null);
    }

    this.setStateSafely({
      breadcrumb: {
        current: this.state.title,
        parent: "Training",
        parentLink: "/training/list.html",
      }
    });
  }

  getTypeaheadLoadParameters() {
    return REPORT_OPTIONS_ENUM[this.reportType].typeaheadLoadConfig;
  }

  loadTypeaheadCache(processId) {
    if (this.reportOptions.needsTypeaheadInput) {
      UIUtils.secureAjaxGET(`editables/multiple/findForTypeahead/${this.projectId || ""}`, {
        reportType: this.reportType,
        models: JSON.stringify(this.reportOptions.dependencies ?
          this.reportOptions.dependencies.concat(this.reportOptions.typeAheadModels) : this.reportOptions.typeAheadModels),
        processId: processId || ProcessCache.getProcessIdUsedRecently(this.projectId) || undefined,
        ...this.getTypeaheadLoadParameters(),
      }, true).done(this.handleTypeaheadResultsFromServer);
    }
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = "QbDVision " + this.state.title;
  }

  handleTypeaheadResultsFromServer(results) {
    if (!results.instances) {
      throw new Error("No instance found! Is this the right project?");
    }

    let instances = results.instances.filter(instance => !instance.deletedAt);
    let {typeAheadModels, dependencies} = this.reportOptions;

    const typeAheadTypeCodesSet = new Set(typeAheadModels.map(model => UIUtils.getTypeCodeForModelName(model)));
    let typeaheadOptions = typeAheadModels ?
      instances.filter(instance => typeAheadTypeCodesSet.has(instance.typeCode)) : [];

    if (typeAheadModels && typeAheadModels.length === 1 &&
      typeAheadModels[0] === "unitOperation") {
      typeaheadOptions = orderAndIndexUnits(typeaheadOptions);
    }

    typeaheadOptions = this.addExtraTypeAheadOptions(typeaheadOptions);

    let dependenciesTypeahead = dependencies ?
      instances.filter(instance =>
        dependencies.map(model =>
          UIUtils.getTypeCodeForModelName(model)).includes(instance.typeCode)) : [];

    let selectedTypeaheadOption = typeaheadOptions.find(option => `${option.typeCode}-${option.id}` === this.modelLabel);

    if (REPORT_OPTIONS_ENUM[this.reportType].shouldLoadRMP) {
      loadRMP((rmp, project) => this.filterTypeAheadOptions(project, rmp, typeaheadOptions, dependenciesTypeahead, selectedTypeaheadOption));
    } else {
      this.setStateSafely({typeaheadOptions, dependenciesTypeahead, selectedTypeaheadOption});
    }
  }

  addExtraTypeAheadOptions(typeaheadOptions) {
    if (this.reportType === REPORT_TYPES_ENUM.FMEAUnitOperation) {
      typeaheadOptions.unshift({
        label: "All Unit Operations",
        id: "All"
      });
    }
    return typeaheadOptions;
  }

  filterTypeAheadOptions(project, rmp, typeaheadOptions, dependenciesTypeahead, selectedTypeaheadOption) {
    const filterTypeaheadOptionsFunction = REPORT_OPTIONS_ENUM[this.reportType]?.filterTypeaheadOptionsFunction;
    if (filterTypeaheadOptionsFunction) {
      typeaheadOptions = filterTypeaheadOptionsFunction(project, rmp, typeaheadOptions);
    }
    this.setStateSafely({rmp, typeaheadOptions, dependenciesTypeahead, selectedTypeaheadOption});
  }

  handleObjectCacheResultsFromServer(results) {
    this.setStateSafely({project: results});
  }

  handleProcessChange(processId) {
    this.loadTypeaheadCache(processId);
    this.modelLabel = null;
    this.setStateSafely({
      processId,
      selectedTypeaheadOption: null,
    });
  }

  renderReport() {
    let commonProps = this.getCommonPropsForRender();

    if (this.reportType === REPORT_TYPES_ENUM.RequirementsSummaryReport) {
      return <RequirementSummaryCannedReport {...commonProps} />;
    } else if (this.reportType === REPORT_TYPES_ENUM.SpecificationReport) {
      return <SpecificationCannedReport {...commonProps} />;
    } else if (this.reportType === REPORT_TYPES_ENUM.QTPPFullReport) {
      return <QTPPFullCannedReport {...commonProps} />;
    } else if (this.reportType === REPORT_TYPES_ENUM.IndividualTrainingReport) {
      return <IndividualTrainingReport {...commonProps} />;
    } else if (this.reportType === REPORT_TYPES_ENUM.DocumentTrainingReport) {
      return <DocumentTrainingReport {...commonProps} />;
    } else if (this.reportType === REPORT_TYPES_ENUM.TrainingMatrixReport) {
      return <TrainingMatrixReport {...commonProps} />;
    } else if (this.reportType === REPORT_TYPES_ENUM.FMEAUnitOperation) {
      return <FMEACannedReport {...commonProps}
                               dependencyTypeahead={this.state.dependenciesTypeahead}
      />;
    } else {
      return <CannedReport {...commonProps}
                           dependencyTypeahead={this.state.dependenciesTypeahead}
                           versionId={this.state.versionId}
      />;
    }
  }

  getCommonPropsForRender() {
    return {
      reportOptions: this.reportOptions,
      reportType: this.reportType,
      projectId: this.projectId,
      processId: this.state.processId,
      typeaheadOptions: this.state.typeaheadOptions,
      selectedTypeaheadOption: this.state.selectedTypeaheadOption,
      title: this.state.title
    };
  }

  renderCompanyHeader() {
    return <CompanyHeader minified={this.isMinified()}
                          breadcrumb={this.state.breadcrumb}
                          project={this.state.project}
                          extraPrintItems={this.extraPrintItems}
                          renderProcessSelector={this.reportOptions.renderProcessSelector}
                          onProcessChange={this.handleProcessChange}
                          processId={this.state.processId}
                          title={this.state.title}
    />;
  }

  renderPage() {
    return (
      <div className={this.reportOptions.fullScreen ? "" : "container"}>
        <Heading level={1}
                 className={"header-banner" +
                   (this.reportOptions.fullScreen ? " canned-report-container-full-screen-header" : "")}
        >
          {this.state.title}
        </Heading>
        {this.renderReport()}
      </div>
    );
  }
}

DataReportPage.defaultProps = {
  minified: true,
};
