"use strict";

/*
This file contains all logic needed for each column exported to the excel sheet.
 */

import * as UIUtils from "../../ui_utils";
import { getAttributeOperationInfo } from "../../techTransfer/tech_transfer_column_value_generator";
import { getRiskScale } from "../../helpers/risk_helper";
import { generateRecordDiff } from "../../techTransfer/diff/tech_transfer_diff_generator";
import { RISK_TYPE_ENUM } from "../../helpers/constants/constants";
import { convertLinksToJson, getRiskScaleColor } from "../../reports/canned_reports/canned_report_helper";
import { NOT_STARTED, VARIABLE_PARENTS } from "../tables/configurable_tables_constants";
import * as CommonEditablesFormatter from "../../../server/common/editables/common_editables_formatter";
import { getIsSiteMissing } from "../../techTransfer/tech_transfer_utils";
import RiskUtils from "../../../server/common/misc/common_risk_utils";

export function formatDocuments(record, propName) {
  const commonProps = [null, null, null, false,];
  const documentLinks = convertLinksToJson(record[propName], ...commonProps);
  return documentLinks.map(link => {
    const blocks = [link.name, link.fileName || link.link, link.appliesTo, link.description !== "None" ? link.description : false].filter(block => block);
    return blocks.join(", ");
  });
}

export function generateChangeValue(transferredFrom = {}, transferredTo = {}) {
  if (transferredTo.id && transferredFrom.id) {
    const diff = generateRecordDiff(transferredFrom.pluralizedModelName, transferredFrom, transferredTo, transferredTo.changeCriteria);
    const recordChanged = diff && diff.totalChangesCount > 0;
    return recordChanged ? "Yes" : "No";
  }

  return getIsSiteMissing(transferredTo, transferredFrom) ? "Yes" : "No";
}

export function generateDocumentsValue(record, prop) {
  const documentsSummary = formatDocuments(record, prop);
  return documentsSummary && documentsSummary.length > 0 ? documentsSummary.join("\r\n\r\n") : "None";
}

export function generateAssessmentStatus(record) {
  return record.techTransferAssessmentStatus || NOT_STARTED;
}

export function generateLinkedAssetsValue(transferredFrom = {}, transferredTo = {}) {
  const transferredFromParent = transferredFrom.source;
  const transferredToParent = transferredTo.source;
  let linkedAssets = "";

  if (transferredFromParent) {
    linkedAssets += transferredToParent ? "• " : "";
    linkedAssets += UIUtils.getRecordLabelForDisplay(transferredFromParent.typeCode, transferredFromParent.id, transferredFromParent.name);
  }

  if (transferredToParent) {
    linkedAssets += transferredFromParent ? "\r\n• " : "";
    linkedAssets += UIUtils.getRecordLabelForDisplay(transferredToParent.typeCode, transferredToParent.id, transferredToParent.name);
  }

  return linkedAssets;
}

export function showBullet(list) {
  return Array.isArray(list) && list.length > 1 ? "• " : "";
}

export function generateRiskLinksValue(record) {
  const riskLinks = record.riskLinks || [];
  const riskLinkOptions = riskLinks.map((link) => {
    const riskLink = link.riskLink;
    if (riskLink && riskLink.id) {
      return `${showBullet(riskLinks)} ${UIUtils.getRecordLabelForDisplay(riskLink.typeCode, riskLink.id, riskLink.name)}`;
    }
  });
  return riskLinkOptions.join("\r\n");
}

export function generateControlValue(record) {
  const controlMethods = record.ControlMethods || [];

  let result = "";
  result += record.controlStrategy || "";
  result += record.controlStrategy && controlMethods.length > 0 ? "\r\n" : "";

  const controlMethodOptions = controlMethods.map((controlMethod) =>
    `${showBullet(controlMethods)} ${UIUtils.getRecordLabelForDisplay("CM", controlMethod.id, controlMethod.name)}`
  );

  result += controlMethodOptions.join("\r\n");
  return result;
}

export function generateOutcomeValue(record) {
  const {effectiveTechTransferRMP} = record;
  if (effectiveTechTransferRMP) {
    const riskValue = record.techTransferImpact * record.techTransferUncertainty * record.techTransferDetectability;
    const riskScale = getRiskScale(RISK_TYPE_ENUM.RPN, effectiveTechTransferRMP, riskValue, record, true);
    const backgroundColor = getRiskScaleColor(riskScale);
    const riskLabel = riskScale ? riskScale.riskLabel : "";

    let value = riskValue || "";
    value += riskLabel ? "\r\n" : "";
    value += riskLabel;
    return {
      value,
      backgroundColor,
    };
  }
}

export function generateAcceptanceCriteriaOrSupplierValue(record = {}) {
  let value = "";
  if (record.typeCode === "MT" &&
    ["Drug Substance (API)", "Excipient"].includes(record.category)) {
    value += record.empiricalFormula || "";
    value += record.molecularWeight ? "\r\n" : "";
    value += record.molecularWeight || "";
    const supplier = record.Supplier || {};
    value += supplier.id ? "\r\n" : "";
    value += supplier.id ? UIUtils.getRecordLabelForDisplay("SUP", supplier.id, supplier.name) : "";
    value += record.partNumber ? "\r\n" : "";
    value += record.partNumber || "";
    value += record.internalPartNumber ? "\r\n" : "";
    value += record.internalPartNumber || "";
    value += record.compendialStandard ? "\r\n" : "";
    value += record.compendialStandard || "";
  } else if (VARIABLE_PARENTS.includes(record.typeCode)) {
    const supplier = record.Supplier || {};
    value = supplier.id ? UIUtils.getRecordLabelForDisplay("SUP", supplier.id, supplier.name) : "";
    value += record.partNumber ? "\r\n" : "";
    value += record.partNumber || "";
  } else {
    const operationsInfo = getAttributeOperationInfo(record);
    value = CommonEditablesFormatter.getAttributeMeasurementInfoTechTransferFormat(record);
    value += operationsInfo ? "\r\n" : "";
    value += operationsInfo || "";
  }
  value += record.description ? "\r\n" : "";
  value += record.description ? "\r\n" : "";
  value += record.description || "";
  return value;
}

export function generateAcceptanceCriteriaValue(record) {
  let value;
  value = CommonEditablesFormatter.getAttributeMeasurementInfoTechTransferFormat(record);
  if (record.typeCode === "PP") {
    const operationsInfo = getAttributeOperationInfo(record);
    value += operationsInfo ? "\r\n" : "";
    value += operationsInfo || "";
  }
  value += record.description ? "\r\n" : "";
  value += record.description ? "\r\n" : "";
  value += record.description || "";
  return value;
}

export function generateRiskValue(prop, riskType, record) {
  const isTechTransferRiskProp = prop.startsWith("techTransfer");
  if (record) {
    const rmp = isTechTransferRiskProp ?
      record.effectiveTechTransferRMP : record.effectiveModelRMP;

    let riskValue = RiskUtils.parseRiskValue(parseFloat(record[prop]), riskType, rmp);
    if (rmp && !isNaN(riskValue)) {
      let rawRiskValue = RiskUtils.parseRiskValue(parseFloat(record[prop.replace("Perc", "")]), riskType, rmp);

      const riskScale = getRiskScale(riskType, rmp, rawRiskValue, record, true);
      const backgroundColor = getRiskScaleColor(riskScale);
      const scoreLabel = riskScale ? (riskScale.riskLabel || riskScale.scoreLabel) : "";

      let value = `${riskValue}${prop.includes("Perc") ? "%" : ""}` || "";
      value += scoreLabel ? "\r\n" : "";
      value += scoreLabel;

      // 0 (Not Assessed) is not rendered by Stimulsoft, so we convert it only to Not Assessed
      if (RiskUtils.scoreIsNotAssessed(riskValue)) {
        value = scoreLabel;
      }

      return {
        value,
        backgroundColor,
        riskValue
      };
    }
  }
}

export function generateVersionValue(record) {
  if (record.LastVersion) {
    return record.LastVersion.majorVersion + "." + record.LastVersion.minorVersion;
  } else {
    return "";
  }
}
