var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { isCompositeFilterDescriptor } from '@progress/kendo-data-query';
import { Button, Toolbar, ToolbarItem, ButtonGroup } from '@progress/kendo-react-buttons';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { Expression } from './Expression';
import { stringOperator } from './operators';
import { messages, filterAndLogic, filterOrLogic, filterAddExpression, filterAddGroup, filterClose, filterGroupAriaLabel } from '../messages';
/**
 * @hidden
 */
var Group = /** @class */ (function (_super) {
    __extends(Group, _super);
    function Group() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onGroupRemove = function (event) {
            var ev = {
                filter: _this.props.filter,
                target: _this,
                syntheticEvent: event,
                nativeEvent: event.nativeEvent
            };
            _this.props.onRemove.call(undefined, ev);
        };
        _this.onAddExpression = function (event) {
            var prevFilter = _this.props.filter;
            var firstField = _this.props.fields[0];
            var newExpression = { field: firstField.name, operator: firstField.operators[0].operator };
            newExpression.value = stringOperator(newExpression.operator) ? '' : null;
            var ev = {
                nextFilter: __assign(__assign({}, prevFilter), { filters: __spreadArray(__spreadArray([], prevFilter.filters, true), [newExpression], false) }),
                prevFilter: prevFilter,
                target: _this,
                syntheticEvent: event,
                nativeEvent: event.nativeEvent
            };
            _this.props.onChange.call(undefined, ev);
        };
        _this.onAddGroup = function (event) {
            var prevFilter = _this.props.filter;
            var ev = {
                nextFilter: __assign(__assign({}, prevFilter), { filters: __spreadArray(__spreadArray([], prevFilter.filters, true), [__assign({}, _this.props.defaultGroupFilter)], false) }),
                prevFilter: prevFilter,
                target: _this,
                syntheticEvent: event,
                nativeEvent: event.nativeEvent
            };
            _this.props.onChange.call(undefined, ev);
        };
        _this.onLogicAnd = function (event) { return _this.changeLogic('and', event); };
        _this.onLogicOr = function (event) { return _this.changeLogic('or', event); };
        _this.changeLogic = function (logic, event) {
            var prevFilter = _this.props.filter;
            if (prevFilter.logic !== logic) {
                var ev = {
                    nextFilter: __assign(__assign({}, prevFilter), { logic: logic }),
                    prevFilter: prevFilter,
                    target: _this,
                    syntheticEvent: event,
                    nativeEvent: event.nativeEvent
                };
                _this.props.onChange.call(undefined, ev);
            }
        };
        _this.replaceFilter = function (prevFilter, nextFilter) {
            var filter = _this.props.filter;
            var filters = filter.filters.map(function (f) { return f === prevFilter ? nextFilter : f; });
            return __assign(__assign({}, filter), { filters: filters });
        };
        _this.onChange = function (event) {
            var nextFilter = _this.replaceFilter(event.prevFilter, event.nextFilter);
            var changeEvent = {
                nextFilter: nextFilter,
                prevFilter: _this.props.filter,
                syntheticEvent: event.syntheticEvent,
                nativeEvent: event.nativeEvent,
                target: _this
            };
            _this.props.onChange.call(undefined, changeEvent);
        };
        _this.onRemove = function (event) {
            var prevFilter = _this.props.filter;
            var filters = prevFilter.filters.filter(function (f) { return f !== event.filter; });
            var changeEvent = {
                nextFilter: __assign(__assign({}, prevFilter), { filters: filters }),
                prevFilter: prevFilter,
                syntheticEvent: event.syntheticEvent,
                nativeEvent: event.nativeEvent,
                target: _this
            };
            _this.props.onChange.call(undefined, changeEvent);
        };
        return _this;
    }
    Group.prototype.render = function () {
        var _this = this;
        var locService = provideLocalizationService(this);
        var _a = this.props, fields = _a.fields, filter = _a.filter, _b = _a.ariaLabel, ariaLabel = _b === void 0 ? locService.toLanguageString(filterGroupAriaLabel, messages[filterGroupAriaLabel]) : _b;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "k-filter-toolbar", role: "toolbar", "aria-label": ariaLabel },
                React.createElement(Toolbar, { keyboardNavigation: false, role: "" },
                    React.createElement(ToolbarItem, null,
                        React.createElement(ButtonGroup, null,
                            React.createElement(Button, { togglable: true, onClick: this.onLogicAnd, selected: filter.logic === 'and', type: "button" }, locService.toLanguageString(filterAndLogic, messages[filterAndLogic])),
                            React.createElement(Button, { togglable: true, onClick: this.onLogicOr, selected: filter.logic === 'or', type: "button" }, locService.toLanguageString(filterOrLogic, messages[filterOrLogic])))),
                    React.createElement(ToolbarItem, null,
                        React.createElement(Button, { title: locService.toLanguageString(filterAddExpression, messages[filterAddExpression]), icon: "filter-add-expression", type: "button", onClick: this.onAddExpression })),
                    React.createElement(ToolbarItem, null,
                        React.createElement(Button, { title: locService.toLanguageString(filterAddGroup, messages[filterAddGroup]), icon: "filter-add-group", type: "button", onClick: this.onAddGroup })),
                    React.createElement(ToolbarItem, null,
                        React.createElement(Button, { title: locService.toLanguageString(filterClose, messages[filterClose]), icon: "close", fillMode: "flat", type: "button", onClick: this.onGroupRemove })))),
            filter.filters.length > 0 && (React.createElement("ul", { className: "k-filter-lines" }, filter.filters.map(function (f, idx) {
                return (React.createElement("li", { key: idx, className: "k-filter-item" }, isCompositeFilterDescriptor(f) ?
                    React.createElement(Group, { filter: f, fields: fields, onChange: _this.onChange, onRemove: _this.onRemove, defaultGroupFilter: _this.props.defaultGroupFilter, ariaLabel: _this.props.ariaLabel, ariaLabelExpression: _this.props.ariaLabelExpression })
                    :
                        React.createElement(Expression, { filter: f, fields: fields, ariaLabel: _this.props.ariaLabelExpression, onChange: _this.onChange, onRemove: _this.onRemove })));
            })))));
    };
    Group.propTypes = {
        filter: PropTypes.object.isRequired,
        fields: PropTypes.array.isRequired,
        ariaLabel: PropTypes.string,
        ariaLabelExpression: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        defaultGroupFilter: PropTypes.object.isRequired
    };
    return Group;
}(React.Component));
export { Group };
registerForLocalization(Group);
