"use strict";

import * as UIUtils from "../ui_utils";

export function isUnitsFieldDisabled(measure) {
  return measure === UIUtils.MEASUREMENT_TYPES.DEFECTS;
}

export const DEFECTIVE_MEASUREMENT_UNIT = "% Defective";

export function getMeasureDefaultUnit(measure) {
  return measure === UIUtils.MEASUREMENT_TYPES.DEFECTS ? DEFECTIVE_MEASUREMENT_UNIT : "";
}

/**
 * This function calculates min sample size when measure is Defacts (Pass/Fail) using gamma = 0.9
 * Reference: http://www.statit.com/support/quality_practice_tips/sample_size_considerations_for_p.shtml
 * @param target attribute target value
 */
export function getMinSampleSize(target) {
  let gamma = 0.9;
  return Math.round(-1 * (Math.log(1 - gamma) / (target / 100)));
}
