import * as React from 'react';
import * as PropTypes from 'prop-types';
import { validatePackage } from '@progress/kendo-licensing';
import { classNames, getTabIndex, useDir, dispatchEvent } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
export var BreadcrumbLink = React.forwardRef(function (props, ref) {
    validatePackage(packageMetadata);
    var target = React.useRef(null);
    var linkRef = React.useRef(null);
    var focus = React.useCallback(function () {
        if (linkRef.current) {
            linkRef.current.focus();
        }
    }, [linkRef]);
    React.useImperativeHandle(target, function () { return ({
        element: linkRef.current,
        focus: focus,
        props: props
    }); });
    React.useImperativeHandle(ref, function () { return target.current; });
    var handleItemSelect = React.useCallback(function (event) {
        if (props.id) {
            dispatchEvent(props.onItemSelect, event, event.target, { id: props.id });
        }
    }, [props.onItemSelect]);
    var handleKeyDown = React.useCallback(function (event) {
        if (props.id) {
            dispatchEvent(props.onKeyDown, event, event.target, { id: props.id });
        }
    }, [props.onKeyDown]);
    return (React.createElement("span", { "aria-current": props.ariaCurrent ? props.ariaCurrent : props.isLast, id: props.id, ref: linkRef, style: props.style, dir: useDir(linkRef, props.dir), tabIndex: getTabIndex(props.tabIndex, props.disabled), className: classNames('k-cursor-pointer k-flex-none k-breadcrumb-link k-breadcrumb-icontext-link', {
            'k-disabled': props.disabled
        }), onClick: handleItemSelect, onKeyDown: handleKeyDown },
        props.iconClass
            ?
                React.createElement("span", { className: classNames('k-icon', props.iconClass) })
            : props.icon ? props.icon : '',
        props.text));
});
var propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    tabIndex: PropTypes.number,
    style: PropTypes.object,
    dir: PropTypes.string,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    icon: PropTypes.node,
    iconClass: PropTypes.string,
    onClick: PropTypes.func,
    ariaCurrent: PropTypes.bool
};
BreadcrumbLink.displayName = 'KendoReactBreadcrumbLink';
// TODO: delete casting when @types/react is updated!
BreadcrumbLink.propTypes = propTypes;
