import {DiagramResultsAdapter} from "../../processExplorer/adapters/diagram_results_adapter";
import * as UIUtils from "../../ui_utils";

export type ProcessExplorerData = {
  links: Array<{ from: string; to: string; key: string; isVisible: boolean }>,
  nodes: Array<{
    category: string;
    fullName: string;
    headerTypeCode: string;
    headerParentKey: string;
    key: string;
    order: number;
    staticPanelKey: string;
    stepId: number;
    unitOperationId: number;
  }>
}

export async function load(projectId: number, processId: number): Promise<ProcessExplorerData> {
  const params = {shouldCompress: true, useWriterDB: false, processId};
  const processExplorerResults = await UIUtils.secureAjaxGET(
    `processExplorer/${projectId}?${UIUtils.objectToURLParameters(
      params
    )}`,
    undefined,
    false
  );
  const diagramResultsAdapter = new DiagramResultsAdapter(
    processExplorerResults,
    false,
    false,
    () => {
    }
  );
  return diagramResultsAdapter.getNodesAndLinks();
}
