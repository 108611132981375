import { __rest } from "tslib";
import { TextSelection } from 'prosemirror-state';
export var findAt = function (doc, searchOptions, start, end, exit) {
    var result = [], exec, text, from, to, childText, nextSibling;
    var nodes = [];
    var matchCase = searchOptions.matchCase, matchWord = searchOptions.matchWord, useRegExp = searchOptions.useRegExp;
    var searchText = searchOptions.text;
    if (useRegExp && (/^\\$/.test(searchText) || /[^\\]\\$/.test(searchText))) {
        // lookbehind doesn't work in Edge -> /((?<!\\)\\)$/.test(searchText)
        searchText = searchText.substring(0, searchText.length - 1);
    }
    if (!searchText) {
        return result;
    }
    var flags = matchCase ? 'g' : 'gi';
    var regExp = useRegExp ? new RegExp(searchText, flags) : createRegExp(searchText, flags);
    doc.nodesBetween(start, end, function (node, pos, _parent) {
        if (exit(result)) {
            return false;
        }
        if (node.inlineContent && pos + node.content.size >= start) {
            nodes.length = 0;
            node.nodesBetween(0, node.content.size, function (child, childPos, parent, i) {
                if (exit(result)) {
                    return false;
                }
                var posToDoc = 1 + pos + childPos;
                if (child.isText && posToDoc + child.nodeSize >= start) {
                    childText = child.text || '';
                    nextSibling = parent.childCount - 1 >= i + 1 && parent.child(i + 1);
                    nodes.push(start <= posToDoc ?
                        { text: childText, start: posToDoc } :
                        { text: childText.slice(start - posToDoc), start: start });
                    if (nextSibling && nextSibling.isText) {
                        return;
                    }
                    text = nodes.map(function (t) { return t.text; }).join('');
                    exec = regExp.exec(text);
                    while (exec !== null) {
                        from = nodes[0].start + exec.index;
                        to = from + exec[0].length;
                        if (start <= from && end >= to && shouldMatchWord(exec, matchWord)) {
                            result.push(TextSelection.create(doc, from, to));
                        }
                        if (exit(result)) {
                            break;
                        }
                        exec = regExp.exec(text);
                    }
                }
                else {
                    nodes.length = 0;
                }
            });
        }
    });
    return result;
};
export var find = function (state, searchOptions) {
    var backward = searchOptions.backward, matchCyclic = searchOptions.matchCyclic, options = __rest(searchOptions, ["backward", "matchCyclic"]);
    var exit = backward ? function () { return false; } : function (r) { return r.length > 0; };
    var doc = state.doc, selection = state.selection;
    var result = [];
    if (backward) {
        result = findAt(doc, options, 0, selection.from, exit);
        if (!result.length && matchCyclic) {
            result = findAt(doc, options, selection.from, doc.content.size, exit);
        }
    }
    else {
        result = findAt(doc, options, selection.to, doc.content.size, exit);
        if (!result.length && matchCyclic) {
            result = findAt(doc, options, 0, selection.to, exit);
        }
    }
    return result.length ? result[result.length - 1] : null;
};
export var findAll = function (doc, searchOptions) {
    return findAt(doc, searchOptions, 0, doc.content.size, function () { return false; });
};
export var replace = function (selection, text, transaction) {
    var from = selection.from, to = selection.to;
    transaction.insertText(text, from, to)
        .setSelection(TextSelection.create(transaction.doc, from, from + text.length));
    return transaction;
};
export var replaceAll = function (state, replaceText, searchOptions) {
    var result = findAll(state.doc, searchOptions);
    if (result.length === 0) {
        return null;
    }
    var transaction = state.tr;
    for (var i = result.length - 1; i >= 0; i--) {
        transaction.insertText(replaceText, result[i].from, result[i].to);
    }
    return transaction;
};
var notLetter = /^[\s0-9~`!@#$%\^&*\(\)_\-=+\\|\[\]{};:'"\?/.,<>]?$/;
var shouldMatchWord = function (exec, matchWord) {
    if (!matchWord) {
        return true;
    }
    else if (matchWord === true) {
        var text = exec.input;
        var charBefore = text.charAt(exec.index - 1);
        var charAfter = text.charAt(exec.index + exec[0].length);
        return notLetter.test(charBefore) && notLetter.test(charAfter);
    }
    else {
        return matchWord(exec);
    }
};
var createRegExp = function (inputString, flags) {
    var escaped = inputString
        .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
        .replace(/-/g, '\\x2d')
        .replace(/\s/g, '\\s');
    return new RegExp(escaped, flags);
};
