/* stylelint-disable-next-line import-notation */
@import "../../../css/brand_colors";

.side-menu-container {
  background-color: $brand-white;
}

.side-menu-header-sticky {
  top: 0;
  position: sticky;
  z-index: 999;
  background-color: $brand-white;
}

.side-menu-tab-navbar {
  padding: 1rem 0 0.3rem 1rem;
}

.side-menu-separator {
  border-bottom: solid thin $brand-mild-grey;
}

.side-menu-separator-header {
  margin-top: -1rem;
}
