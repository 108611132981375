var _a;
/**
 * @hidden
 */
export var dialogsWindowMaximizeButton = 'dialogs.windowMaximizeButton';
/**
 * @hidden
 */
export var dialogsWindowMinimizeButton = 'dialogs.windowMinimizeButton';
/**
 * @hidden
 */
export var dialogsWindowRestoreButton = 'dialogs.windowRestoreButton';
/**
 * @hidden
 */
export var dialogsWindowCloseButton = 'dialogs.windowCloseButton';
/**
 * @hidden
 */
export var messages = (_a = {},
    _a[dialogsWindowMaximizeButton] = 'maximize',
    _a[dialogsWindowMinimizeButton] = 'minimize',
    _a[dialogsWindowRestoreButton] = 'restore',
    _a[dialogsWindowCloseButton] = 'close',
    _a);
