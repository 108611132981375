"use strict";

import { SYNC_MATERIAL_ACTIONS } from "./sync_material_actions";


export const syncDefaultState = {
  keepSynced: true,
  specifications: [],
};

/**
 * The reducer for sync flow
 * @param state
 * @param action
 * @returns The latest state after mutations.
 */
export const syncReducer = (state, action) => {
  switch (action.type) {
    case SYNC_MATERIAL_ACTIONS.EnableCreateButton:
      return {
        ...state,
      };
    case SYNC_MATERIAL_ACTIONS.UpdateField:
      return {
        ...state,
        [action.field]: action.value,
      };
    case SYNC_MATERIAL_ACTIONS.UpdateSpecifications:
      return {
        ...state,
        specifications: action.specifications,
      };
    default:
      return state;
  }
};
