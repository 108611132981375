"use strict";

import React from "react";

const AcceptanceCriteriaRangesCell = ({ dataItem, field }) => {
  const shouldShowDefaultBadge = field === "label" && dataItem.isDefault;

  return (
    <td>
      <p className="m-0">{dataItem[field]}</p>
      {shouldShowDefaultBadge && <span className="badge b-1 p-1 alert-info">Primary reporting criteria</span>}
    </td>);
};

export default AcceptanceCriteriaRangesCell;
