"use strict";

import * as UIUtils from "../../../ui_utils";
import { BaseDataTransform } from "../base_data_transform";
import {
  checkEmptySummaryData,
  convertLinksToJson,
  convertLinksToOneLine,
  getRawRiskScoreText,
  getRiskScaleColor,
  getRiskScaleLabel,
  isDraft,
  processDraftRegularities,
  processEmptyFields,
  sortAttributes,
} from "../../canned_reports/canned_report_helper";
import { REPORT_DRAFT_ENUM } from "../../constants/report_constants";
import { RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import { parseCriticalityInformationFromRawData } from "../../risk_tables/utils/risk_tables_parser";
import { formatCriticalityJustificationForExport } from "../../risk_tables/utils/risk_tables_formatter";
import * as CommonEditablesFormatter from "../../../../server/common/editables/common_editables_formatter";
import RiskUtils from "../../../../server/common/misc/common_risk_utils";

export class QTPPSummaryDataTransform extends BaseDataTransform {

  get type() {
    return "qtppSummary";
  }

  shouldRun() {
    return true;
  }

  getSupportedRecordTypes() {
    return ["fqas", "fpas"];
  }

  transform(input, options) {
    const result = input;
    const data = result.instances;

    data.fqas = this.processAttributes(data, "fqas", "FQA", options);
    data.fpas = this.processAttributes(data, "fpas", "FPA", options);

    if (data.generalattributes) {
      for (const generalAttribute of data.generalattributes) {
        generalAttribute.name = UIUtils.secureString(generalAttribute.name);
        generalAttribute.draftMarker = isDraft(generalAttribute) ? REPORT_DRAFT_ENUM.DraftMarker : "";
        generalAttribute.links = convertLinksToJson(generalAttribute.links, generalAttribute.id);
        generalAttribute.linkSummary = convertLinksToOneLine(generalAttribute.links, {allowHTML: true});
        processEmptyFields(generalAttribute);
      }
    }

    processDraftRegularities(data);
    checkEmptySummaryData(data, data.fqas, "isFQASummaryEmpty");
    checkEmptySummaryData(data, data.fpas, "isFPASummaryEmpty");
    checkEmptySummaryData(data, data.generalattributes, "isGeneralAttributesSummaryEmpty");

    result.instances = data;
    return result;
  }

  processAttributes(data, recordsPropName, modelName, options) {
    let records = data[recordsPropName];

    if (records) {
      // sorts the Attributes array by criticality (descending)
      records.sort(sortAttributes);
      records = records.map(record => {
        return {
          ...record,
          effectiveRMP: options.rmpVersions.find(rmpVersion => rmpVersion.id === record.effectiveRMPVersionId),
          CriticalityJustification: record.detailedRiskLinks ? null : "",
          riskLinks: record[`${modelName}ToGeneralAttributeRiskLinkedVersions`].map(link => {
            const generalAttribute = data.generalattributes
              .find(generalAttributeItem => generalAttributeItem.GeneralAttributeId === link.GeneralAttributeId);

            return {
              ...link,
              linkedTypeCode: "GA",
              linkedId: link.GeneralAttributeId,
              name: generalAttribute.name,
              currentState: generalAttribute.currentState,
            };
          }),
        };
      });

      records = parseCriticalityInformationFromRawData(records);

      for (const record of records) {
        record.name = UIUtils.secureString(record.name);
        record.controlMethods = record.ControlMethods.join("\r\n");
        record.draftMarker = isDraft(record) ? REPORT_DRAFT_ENUM.DraftMarker : "";
        record.category = UIUtils.secureString(
          CommonEditablesFormatter.formatMultiSelectValueForDisplay(record.category)
        );
        record.controlStrategy = UIUtils.secureString(
          CommonEditablesFormatter.formatMultiSelectValueForDisplay(record.controlStrategy)
        );

        let effectiveRMP = options.rmpVersions.find(rmpVersion => rmpVersion.id === record.effectiveRMPVersionId);
        let criticality = RiskUtils.parseRiskValue(record.detailedRiskLinks ? getRawRiskScoreText(record.riskInfo[RISK_TYPE_ENUM.CRITICALITY].value, record) : record.criticality, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP);

        let riskScale = record.riskInfo[RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel;
        let criticalityScaleLabel = getRiskScaleLabel(riskScale);
        record.criticality = criticalityScaleLabel ? `${criticality} (${getRiskScaleLabel(riskScale)} - ${riskScale.riskLabel})` : "";
        record.criticalityColor = getRiskScaleColor(riskScale);
        record.justification = record.detailedRiskLinks ? formatCriticalityJustificationForExport(record.CriticalityJustification) : record.justification;
        processEmptyFields(record);
        options.showDraftAlerts = options.showDraftAlerts ? options.showDraftAlerts : isDraft(record);
      }
    }

    return records;
  }
}
