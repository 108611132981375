"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BasePage from "../../base_page";
import PageFrameLayout from "../../widgets/layout/page_frame_layout";
import { FilteredList } from "../../widgets/tables/filtered_list";
import { TRAINING_TABS, TRAINING_TABS_ENUM } from "../../helpers/constants/constants";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { Trans } from "react-i18next";
import DropdownButton from "../../widgets/generic/dropdown_button";
import { faUser, faFile, faTable, faFileContract } from "@fortawesome/free-solid-svg-icons";
import { TrainingService } from "../services/training_service";
import { EditablesService } from "../../services/editables/editables_service";
import { COLUMN_FORMATTERS } from "../../widgets/tables/column_formatters";

const REPORT_TYPES = [
  {
    key: "individualTrainingReport",
    value: "Individual Training Report",
    icon: faUser,
  },
  {
    key: "documentTrainingReport",
    value: "Document Training Report",
    icon: faFile,
  },
  {
    key: "trainingMatrixReport",
    value: "Training Matrix Report",
    icon: faTable,
  },
];

/* This shows the list of individual training plans / curricula. */

// i18next-extract-mark-ns-start documents
class TrainingListPage extends BasePage {
  constructor(props) {
    super(props);

    const {t} = props;

    this.getTrainingStatus = this.getTrainingStatus.bind(this);
    this.handleOpenReports = this.handleOpenReports.bind(this);
    this.handleCheckPermissions = this.handleCheckPermissions.bind(this);
    this.handleCurriculaLoaded = this.handleCurriculaLoaded.bind(this);
    this.handleDataPreProcess = this.handleDataPreProcess.bind(this);

    this.filters = [
      {
        key: "NoTraining",
        selected: false,
        order: 2,
        filter: (item) => {
          const trainingStatus = this.getTrainingStatus(item);
          const enabled = item?.user.enabled ?? true;
          return enabled && trainingStatus === "Not Assigned";
        },
        map: item => item,
        name: t("No training assigned"),
        disableShowArchived: true,
        linkToLastDraft: true,
      },
    ];

    //

    this.trainingService = new TrainingService();
    UIUtils.setHideLoadingOnAjaxStop(false);

    this.setStateSafely({
      breadcrumb: {
        current: t("Training"),
      },
    });
  }

  componentDidMount() {
    const {t} = this.props;
    document.title = t("QbDVision Training");

    super.componentDidMount();
  }

  handleDataPreProcess(data) {
    const service = new EditablesService();
    return service.get({
      urlPrefix: "editables/Curriculum",
      action: "list",
      urlParameters: {approved: true, shouldCompress: true}
    })
      .then(curricula => this.handleCurriculaLoaded(curricula, data.instances));
  }

  handleCurriculaLoaded(curricula, itps) {
    const resultInstances = [];

    if (curricula && curricula.instances) {
      const instances = curricula.instances.map(item => ({
        ...item,
        departments: item.departments ? JSON.parse(item.departments) : [],
      }));

      for (let itp of itps) {
        if (itp.user) {
          const departmentCurricula = instances.filter(curr => curr.departments.includes(itp.user.department));
          itp.departmentCurricula = departmentCurricula || [];
          // ensures we only return users that exist (avoids showing stuff for trash data in Rockets R US)
          resultInstances.push(itp);
        } else {
          UIUtils.sendErrorTelemetry(new Error(`User (${itp.userId}) linked to ITP (${itp.id}) not found`), itp);
        }
      }
    }
    return {instances: resultInstances};
  }

  handleOpenReports(event) {
    let reportType = UIUtils.stripAllWhitespaces(event.target.text);
    window.open(UIUtils.getSecuredURL(`/reports/cannedReports/base.html?minified=false&reportType=${reportType}`), "_blank");
  }

  getTrainingStatus(item) {
    const {t} = this.props;

    const hasDocuments = item.Documents && item.Documents.length > 0;
    const hasCurricula = (item.Curricula && item.Curricula.length > 0);
    const hasCurriculaAssignment = item.CurriculumAssignments && item.CurriculumAssignments.length > 0;
    const isApproved = item.LastApprovedVersionId > 0;

    return (
      ((hasDocuments || hasCurricula || hasCurriculaAssignment) && isApproved)
    )
      ? t("Assigned")
      : t("Not Assigned");
  }

  /**
   * Figure out if the current user can do said action with the given type and instance.
   * @param params.permissions {[]} The permissions for the user
   * @param params.action {*} An action from CommonSecurity.Actions
   * @param params.type {*} An type from CommonSecurity.Types
   * @param params.instance {*} An instance of data.  While this isn't used yet, pass it in if you can so that supporting instance level security will be easier later on.
   * @param params.defaultVerificationResult {boolean} The result of the default permissions verification
   * @param [params.user] {*} The user being checked
   */
  handleCheckPermissions(params) {
    const user = {
      id: UIUtils.getUserId(),
      isTrainingCoordinator: this.trainingService.isCurrentUserATrainingCoordinator(),
    };
    return this.trainingService.handleCheckPermissions({...params, user});
  }

  renderPage() {
    const {t} = this.props;
    return (
      <PageFrameLayout title="Training" tabs={TRAINING_TABS} selectedTab={TRAINING_TABS_ENUM.Plans}>
        <FilteredList
          parent={this}
          topLevel
          hideRemoveButton
          hideShowArchivedButton
          handleArchivedOnClient={true}
          filters={this.filters}
          editableName="ITP"
          editableDisplayName={t("Individual Training Plan")}
          onCheckPermissions={this.handleCheckPermissions}
          onDataPreProcess={this.handleDataPreProcess}
          archivedItemsFilter={(item) => item.user && item.user.enabled}
          onGenerateColumns={() => {
            return [
              {
                title: t("Department"),
                width: 50,
                data: item => item.user && item.user.department || "",
              },
              {
                ...COLUMN_FORMATTERS.CURRENT_STATE,
                title: t("Status"),
                data: item => item.user && item.user.enabled ? t(item.currentState) : t("Disabled"),
              },
              {
                title: t("Training Status"),
                width: 200,
                data: item => (this.getTrainingStatus(item)),
              },
            ];
          }}
          onRenderHeader={(props, state) => {
            const {filteredItems, isLoading} = state;
            const itemCount = filteredItems && filteredItems.length > 0 ? filteredItems.length : 0;

            return (
              <div className="col-sm-12">
                <h2 className="header-inline">
                  <Trans t={t} count={itemCount}>
                    <span className={"header-item-count" + (isLoading ? " skeleton" : "")}>{{count: itemCount}}</span><span className="header-item-type"> Individual Training Plan</span>
                  </Trans>
                </h2>
                <DropdownButton className="btn-group training-reports-button"
                                options={REPORT_TYPES}
                                text="Reports"
                                id={"trainingReports"}
                                isDisabled={false}
                                titleIcon={faFileContract}
                                onOptionsSelect={this.handleOpenReports}
                />
              </div>
            );
          }}
        />
      </PageFrameLayout>
    );
  }
}

export default I18NWrapper.wrap(TrainingListPage, "documents");
// i18next-extract-mark-ns-stop documents
