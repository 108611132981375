"use strict";

import React from "react";
import * as styles from "./no_records_container.module.scss";
import { faFileImport, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as UIUtils from "../../../../ui_utils";

function formatModelNameForDisplay(filter, selectedModelType) {
  if (filter?.filters?.length) {
    return "No records match your filter criteria. Please update your filters.";
  } else if (selectedModelType === "Target Product Profile") {
    return `No ${selectedModelType} Sections have been added yet. Start by adding or importing your records.`;
  }
  return `No ${UIUtils.pluralize(selectedModelType)} have been added yet. Start by adding or importing your records.`;
}

function isDisabled(selectedModelType) {
  return selectedModelType === "General Attributes";
}

export const NoRecordsContainer = (
  {
    height,
    filter,
    selectedModelType,
    onImportButtonClick,
    onEmptyTableAddClicked
  }) => {

  return (
    <div className={styles["container"]}
         style={{
           height: `${height - 120}px`,
           width: `${window.innerWidth * 0.96}px`
         }}
    >
      <div className={styles["blank-message"]}>
        <span>{formatModelNameForDisplay(filter, selectedModelType)}</span>
      </div>
      <div className={styles["buttons-container"]}>
        <button id="blankTableAddBtn"
                className={`btn btn-lg btn-primary ${styles["action-btn"]}`}
                onClick={onEmptyTableAddClicked}
        >
          <FontAwesomeIcon className={styles["action-btn-icon"]} icon={faPlus} />Add
        </button>
        <button id="blankTableImportBtn"
                className={`btn btn-lg btn-primary ${styles["action-btn"]}`}
                onClick={onImportButtonClick}
                disabled={isDisabled(selectedModelType)}
        >
          <FontAwesomeIcon className={styles["action-btn-icon"]} icon={faFileImport} />
          Import Excel Sheet
        </button>
      </div>
    </div>
  );
};