var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * @hidden
 */
export function tableColumnsVirtualization(args) {
    var enabled = args.enabled, _a = args.columns, columns = _a === void 0 ? [] : _a, tableViewPortWidth = args.tableViewPortWidth, scrollLeft = args.scrollLeft;
    var colSpans = columns.map(function () { return 1; });
    var hiddenColumns = columns.map(function () { return false; });
    if (!enabled) {
        return { colSpans: colSpans, hiddenColumns: hiddenColumns };
    }
    var widths = columns.map(function (c) { return parseFloat((c.width || '').toString()) || 10; });
    var widthOfHiddenColumns = 0;
    for (var i = 0; i < columns.length; i++) {
        if (columns[i].locked) {
            continue;
        }
        var considerNext = (i < widths.length - 1) ? widths[i + 1] : 0;
        if (widthOfHiddenColumns + widths[i] + considerNext < scrollLeft) {
            hiddenColumns[i] = true;
            widthOfHiddenColumns += widths[i];
        }
        else {
            break;
        }
    }
    var totalWidth = widths.reduce(function (acc, current) { return acc + current; }, 0);
    widthOfHiddenColumns = 0;
    for (var i = columns.length - 1; i >= 0; i--) {
        if (columns[i].locked) {
            continue;
        }
        if (widthOfHiddenColumns + 2 * widths[i] < totalWidth - tableViewPortWidth - scrollLeft) {
            hiddenColumns[i] = true;
            widthOfHiddenColumns += widths[i];
        }
        else {
            break;
        }
    }
    var hiddenCols = __spreadArray([], hiddenColumns, true);
    var hidden = function (value) { return value; };
    var lastVisible = hiddenCols.lastIndexOf(false);
    var anyHidden = hiddenCols.some(hidden);
    var allHidden = hiddenCols.every(hidden);
    var hiddenSeqLength;
    var updateIndex;
    while (anyHidden && hiddenCols.length && (lastVisible !== -1 || allHidden)) {
        if (lastVisible < hiddenCols.length - 1) {
            hiddenSeqLength = allHidden ? hiddenCols.length : hiddenCols.length - lastVisible - 1;
            updateIndex = hiddenCols.length - hiddenSeqLength;
            if (updateIndex === 0) {
                updateIndex = hiddenSeqLength - 1;
            }
            hiddenColumns[updateIndex] = false;
            colSpans[updateIndex] = hiddenSeqLength;
            hiddenCols.splice(lastVisible + 1, hiddenSeqLength);
        }
        while (hiddenCols.length && !hiddenCols[hiddenCols.length - 1]) {
            hiddenCols.pop();
        }
        lastVisible = hiddenCols.lastIndexOf(false);
        anyHidden = hiddenCols.some(hidden);
        allHidden = hiddenCols.every(hidden);
    }
    return { colSpans: colSpans, hiddenColumns: hiddenColumns };
}
