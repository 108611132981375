import {downloadFile} from "../index";
import {FormatBlockFontSize, FormatBlockType} from "../../common/constants";
import {parseListItemData} from "../../plugins/set_data_numbered_list";

/**
 * The exporter util to export a Microsoft Word file
 */
export class WordExporter {
  export(htmlContent: string, fileName: string, isLandscape: boolean) {
    this.download(this.createDOCContent(htmlContent, isLandscape), fileName);
  }

  private download(text, filename) {
    const dataUri = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(
      text
    )}`;
    downloadFile(dataUri, filename);
  }

  private createDOCContent(htmlContent: string, isLandscape: boolean) {
    const node = new DOMParser().parseFromString(htmlContent, "text/html");
    this.setColumnWidthForTables(node);
    this.setImagesWidthAndHeight(node);
    this.setClassForSpecialBlocks(node);
    this.setColor(node);
    const orientation = isLandscape ? "landscape" : "portrait";
    this.setBlankLine(node);
    this.setListItems(node);

    return `<!DOCTYPE html>
      <html
        xmlns:o='urn:schemas-microsoft-com:office:office'
        xmlns:w='urn:schemas-microsoft-com:office:word'
        xmlns='http://www.w3.org/TR/REC-html40'
      >
        <head>
          <meta charset='utf-8'>
          <title></title>
          <style>
            @page WordSection {
              mso-page-orientation: ${orientation};
              size: ${orientation};
              mso-header-margin:0.5in;
              mso-footer-margin:0.5in;
              mso-header: h1;
              mso-footer: f1;
            }

            div.word-content {
              page: WordSection;
            }
            
            table {
              display: table;
              box-sizing: border-box;
              border-spacing: 2px;
              border-color: grey;
              margin: 0;
              border-collapse: collapse;
              table-layout: fixed;
              width: 100%;
              overflow: hidden;
              white-space: pre-wrap;
            }

            td {
              display: table-cell;
              min-width: 1em;
              border: 1px solid #ddd;
              padding: 3px 5px;
              vertical-align: top;
              box-sizing: border-box;
              word-wrap: break-word;
            }
            
            #h1 td, #f1 td {
              border: none;
            }
            
            h1 {
              font-size: ${FormatBlockFontSize[FormatBlockType.Heading1]};
            }

            h2 {
              font-size: ${FormatBlockFontSize[FormatBlockType.Heading2]};
            }

            h3 {
              font-size: ${FormatBlockFontSize[FormatBlockType.Heading3]};
              font-weight: bold;
            }

            h4 {
              font-size: ${FormatBlockFontSize[FormatBlockType.Heading4]};
              font-weight: bold;
            }

            p {
              font-size: ${FormatBlockFontSize[FormatBlockType.NormalText]};
            }
            
            .title {
              font-size: ${FormatBlockFontSize[FormatBlockType.Title]};
            }
  
            .caption {
              font-size: ${FormatBlockFontSize[FormatBlockType.Caption]};
            }
          </style>
        </head>
        <body>
          <div class="word-content">
            ${this.getHeaderAndFooter(node)}
            ${node.body.outerHTML}
          </div>
        </body>
      </html>
    `;
  }

  /**
   * Get the header and footer content for Word exporting
   *
   * @param node
   * @private
   */
  private getHeaderAndFooter(node: Document) {
    const header = node.querySelector(".header-container");
    const footer = node.querySelector(".footer-container");
    if (!header) {
      return "";
    }

    header.remove();
    footer.remove();

    return `
      <table style='margin-left:50in;'>
        <tr style='height:1pt; mso-height-rule:exactly'>
          <td>
            <div style='mso-element:header' id=h1>
              ${header.innerHTML}
            </div>
            &nbsp;
          </td>
   
          <td>
            <div style='mso-element:footer' id=f1>
              ${footer.innerHTML}
            </div>
            &nbsp;
          </td>
        </tr>
      </table>
    `;
  }

  /**
   * Setting width for all the td elements when the table was resized. When we resize
   * a table, it only sets the width in col of colgroup element. It is enough when
   * rendering on browser, but Microsoft Word requires all td elements have width
   *
   * @param node
   * @private
   */
  private setColumnWidthForTables(node: Document) {
    const tables = Array.from(node.querySelectorAll("table"));
    for (const table of tables) {
      const colgroup = table.querySelector("colgroup");
      if (!colgroup) {
        continue;
      }

      const cols = Array.from(colgroup.querySelectorAll("col"));
      if (!cols || !cols.length) {
        continue;
      }

      const tableRows = Array.from(table.querySelectorAll("tr"));
      for (const tableRow of tableRows) {
        const tableColumns = tableRow.querySelectorAll("td");
        tableColumns.forEach((tableColumn, index) => {
          if (cols.length > index) {
            tableColumn.style.width = cols[index].style.width;
          }
        });
      }
    }
  }

  /**
   * If the image width is above 700px, it will be cut-off when exporting to Word, so we
   * need to resize it before exporting
   *
   * @param node
   * @private
   */
  private setImagesWidthAndHeight(node: Document) {
    const imageElements = Array.from(
      document.querySelectorAll<HTMLImageElement>(".document-editor-main img"),
    );
    const titleToImageElementMap = new Map();
    for (const imageElement of imageElements) {
      if (imageElement.getAttribute("filedata")) {
        titleToImageElementMap.set(
          imageElement.getAttribute("filedata"),
          imageElement,
        );
      } else if (imageElement.title) {
        titleToImageElementMap.set(imageElement.title, imageElement);
      } else if (imageElement.src) {
        titleToImageElementMap.set(imageElement.src, imageElement);
      }
    }

    const images = Array.from(node.querySelectorAll("img"));
    for (const image of images) {
      const imageElement = titleToImageElementMap.get(
        image.getAttribute("filedata") || image.title || image.src,
      );
      if (imageElement) {
        image.width = Math.min(imageElement.width, 700);
        image.height =
          imageElement.width > 700
            ? Math.floor(700 * (imageElement.height / imageElement.width))
            : imageElement.height;
      }
    }
  }

  /**
   * Telerik doesn't provide Title and Caption for block format, so we are hacking by using block-type
   * in the style. We need to find all the elements with block-type and set className for it, so it has
   * correct font-size when exporting to Word
   *
   * @param node
   * @private
   */
  private setClassForSpecialBlocks(node: Document) {
    const specialTextBlocks = Array.from(
      node.querySelectorAll<HTMLElement>("[style*='block-type']")
    );
    for (const textBlock of specialTextBlocks) {
      const elementStyle = {};
      textBlock
        .getAttribute("style")
        .split(";")
        .forEach((item) => {
          const parts = item.split(":");
          if (parts.length === 2) {
            elementStyle[parts[0].trim()] = parts[1].trim();
          }
        });
      if (elementStyle["block-type"]) {
        textBlock.className = elementStyle["block-type"];
      }
    }
  }

  /**
   * We need to apply some Microsoft Word style field, so it will show the correct color
   * when exporting to Word
   *
   * @param node
   * @private
   */
  private setColor(node: Document) {
    const elements = Array.from<HTMLElement>(
      node.querySelectorAll("[style*='color'],[style*='background-color']")
    );
    for (const element of elements) {
      let newStyle = element.getAttribute("style");
      if (element.style.backgroundColor) {
        newStyle = `${newStyle}mso-highlight: ${element.style.backgroundColor};background: ${element.style.backgroundColor}`;
      }

      if (element.style.color) {
        newStyle = `${newStyle}mso-style-textfill-fill-color: ${element.style.color};`;
      }

      element.setAttribute("style", newStyle);
    }
  }

  /**
   * If it is a blank line, we need to manually set it to &nbsp, so it will show up
   * correctly. We will set non-breaking space if the paragraph:
   * 1. Has empty text
   * 2. The parent element is not LI, so we don't have unnecessary space in the list
   * 3. Not contain an img child element
   *
   * @param node
   * @private
   */
  private setBlankLine(node: Document) {
    Array.from<HTMLElement>(node.querySelectorAll("p"))
      .filter(
        (element) =>
          !element.innerText &&
          element.parentElement?.tagName !== "LI" &&
          !element.querySelector("img"),
      )
      .forEach((element) => (element.outerHTML = "&nbsp"));
  }

  /**
   * We need to remove all the ol and li elements since it won't work with our custom numbered
   * list. We will append all of its children to the DOM manually and set the ordered number for
   * them
   *
   * @param node
   * @private
   */
  private setListItems(node: Document) {
    const olElements = Array.from<HTMLOListElement>(
      node.querySelectorAll("ol"),
    );
    for (const olElement of olElements) {
      if (olElement.getAttribute("level") !== "1") {
        continue;
      }

      let previousElement: HTMLElement = olElement;
      const liElements = Array.from(olElement.querySelectorAll("li"));
      for (const liElement of liElements) {
        let isFirstChild = true;
        for (const childElement of Array.from(liElement.children)) {
          if (childElement.tagName === "OL") {
            break;
          }

          let childHTMLElement = childElement as HTMLElement;
          // We need to prepend the ordered number in the first child, so it can show our custom
          // ordered number
          if (isFirstChild && childHTMLElement.style) {
            isFirstChild = false;
            const dataStr = liElement.getAttribute("data");
            const data = parseListItemData(dataStr);
            const orderedNumberElement = document.createElement("span");
            orderedNumberElement.innerText = `${dataStr}. `;
            childHTMLElement.style.marginLeft = `${
              0.25 + 0.3 * (data.level - 1)
            }in`;
            childHTMLElement.style.textIndent = `-${
              0.25 + 0.05 * (data.level - 1)
            }in`;
            childHTMLElement.prepend(orderedNumberElement);
          }

          previousElement.after(childHTMLElement);
          previousElement = childHTMLElement;
        }
      }

      olElement.remove();
    }
  }
}
