"use strict";
// The functions in this file are responsible for figuring out which process is the one the user used recently.

import * as UIUtils from "../../ui_utils";

const PROCESS_ID_TOKEN = "PROCESS_ID_TOKEN-";

/**
 * Receives an array with the raw results from the typeahead cache for a process
 * and returns a
 * @param processes {Process[]} An array of process objects
 * @return {(Process | {isDefault: true}[])}
 */
export function prepareProcessesArray(processes) {
  return processes
    .sort(UIUtils.sortBy("id"))
    .map(
      (process, index) => {
        return {
          ...process,
          // the process with the smallest ID is assumed to be the default one
          isDefault: index === 0,
        };
      });
}

/**
 * Finds the ID of the default process given an array of processes and saves it to this cache.
 * @param projectId {number} The ID of the project to set the default process id.
 * @param processes {Process[]} An array of process objects
 * @returns {number}
 */
export function retrieveDefaultProcessIdAndSaveToCache(projectId, processes) {
  projectId = ensureProjectId(projectId, true);
  let result = prepareProcessesArray(processes);
  const process = result ? result.find(proc => proc.isDefault) : undefined;
  const processId = result ? process.id : undefined;
  if (processId) {
    setProcessIdUsedRecently(projectId, processId);
  }
  return processId;
}

/**
 * Find the process Id that the user used most recently.
 *
 * @param projectId
 * @param processes {Process[]} If specified and if no current process ID is found on the cache,
 * attempts to retrieve the default process ID from the specified array
 * (useful when processing typeahead results).
 */
export function getProcessIdUsedRecently(projectId, processes= []) {
  projectId = ensureProjectId(projectId, false);
  let processId = undefined;

  if (projectId) {
    const queryStringProcess = UIUtils.getParameterByName("processId");
    processId = queryStringProcess || localStorage.getItem(getProcessIdLocalStorageToken(projectId));

    processId = processId ? UIUtils.parseInt(processId) : undefined;
  }

  if (!processId && processes && processes.length > 0) {
    processId = retrieveDefaultProcessIdAndSaveToCache(projectId, processes);
  }
  return processId;
}

function getProcessIdLocalStorageToken(projectId) {
  return PROCESS_ID_TOKEN + projectId;
}

function ensureProjectId(projectId, throwErrorIfNotFound) {
  if (!projectId) {
    projectId = UIUtils.parseInt(UIUtils.getParameterByName("projectId"));
  }

  if (throwErrorIfNotFound && !projectId) {
    throw new Error("No project Id found.");
  }

  return projectId;
}

/**
 * Use this to let other pages know which process to use.
 *
 * @param projectId The project Id this process is in.
 * @param processId The process Id to cache.
 */
export function setProcessIdUsedRecently(projectId, processId) {
  projectId = ensureProjectId(projectId, true);
  return localStorage.setItem(getProcessIdLocalStorageToken(projectId), processId);
}
