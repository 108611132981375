"use strict";

import React, { Component } from "react";
import { CriticalityJustification } from "./criticality_justification";
import { NoDataCell } from "./no_data_cell";

/**
 * Renders a complex criticality justification (that supports multiple attributes) in the standard way for a risk table report.
 */
export class ComplexCriticalityJustification extends Component {
  render() {
    const {justification} = this.props;

    if (justification && Array.isArray(justification) && justification.length > 0) {
      return (
        <div>
          {justification.map(item => (
            <CriticalityJustification
              key={item.attributeKey}
              item={item}
            />
          ))}
        </div>
      );
    } else {
      return (
        <NoDataCell />
      );
    }
  }
}
