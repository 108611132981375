"use strict";

import React, { memo } from "react";
import * as style from "./style/text_cell.module.scss";
import * as commonStyle from "./style/common_cell.module.scss";
import { FILTER_TYPES } from "../../columnOperations/configurable_tables_column_operations_constants";
import moment from "moment-timezone";
import * as UIUtils from "../../../ui_utils";

function format(value, filterType) {
  if (filterType === FILTER_TYPES.DATE && value) {
    return moment(value).format(UIUtils.DATE_FORMAT_FOR_TABLE_DISPLAY);
  }
  return value;
}

export const TextCell = memo(({dataItem, className, prop, filterType}) => {
  const text = dataItem[prop];
  return (
    <td className={className}>
      <div className={`${commonStyle["cell-container"]} ${style["text-cell-container"]}`}>
        {format(text, filterType)}
      </div>
    </td>);
});
