"use strict";

import * as UIUtils from "../../../ui_utils";
import React, { Fragment } from "react";
import RMPRiskComparisonTab from "./rmp_risk_comparison_tab";
import { ListOption } from "../../../editor/widgets/list_option";
import BaseCopyRecordPlugin from "./base_copy_record_plugin";
import * as I18NWrapper from "../../../i18n/i18n_wrapper";
import * as RiskUtils from "../../../../server/common/misc/common_risk_utils";
import TypeaheadObjectCache from "../../../utils/cache/typeahead_object_cache";

// i18next-extract-mark-ns-start widgets
class RMPChangePlugin extends BaseCopyRecordPlugin {
  constructor(props) {
    super(props);

    this.setStateSafely({
      rmps: [],
      isLoading: true,
    });

    /**
     * The cache is invalidated every time the copy project dialog shows up, since the RMPs might have
     * been loaded in some other screen without the linked records (impact, uncertainty, etc...).
     */
    const cache = new TypeaheadObjectCache("RMP");
    cache.invalidateCacheOptionsAsync().then(() => {
      cache.loadOptions(this.handleReceiveRMPs, {
        latestApproved: true,
        includeLinks: true
      });
    });
  }

  handleReceiveRMPs(RMPs) {
    this.setStateSafely({
      rmps: RMPs,
      isLoading: false,
    });
  }

  parseRMPDataForCombobox(data) {
    data = data || [];

    // Uncomment for verbose logging
    // console.log("Received RMP", data, this.state);
    return data.filter(rmp => rmp.id && rmp.id !== this.props.objectToCopy.RMPId)
      .map(rmp => {
        return {key: rmp.id, value: rmp.label};
      });
  }

  handleRMPSelectionChange(event) {
    this.handleChangeValue(event.target.value);
  }

  renderRiskComparisonTabs(sourceRMP, newRMP) {
    if (sourceRMP.configureByType || newRMP.configureByType) {
      return (<Fragment>
        <div className="nav nav-tabs">
          {
            UIUtils.RISK_MODELS.filter(riskModel => !riskModel.isRiskLink).map(riskModel => {
              let {model} = riskModel;
              return <a data-toggle="tab"
                        key={model}
                        className={"nav-link" + (model === "FQA" ? " active" : "")}
                        id={`${model}RiskComparisonTab`}
                        href={`#${model}`}
                        aria-controls={model}
              >
                {model}
              </a>;
            })
          }
        </div>
        <div className="tab-content">
          {
            UIUtils.RISK_MODELS.filter(riskModel => !riskModel.isRiskLink).map(riskModel => {
              let {model} = riskModel;
              return (<div key={model} id={model}
                           className={"rmp-requirement-risk-tab tab-pane fade" + (model === "FQA" ? " show active" : "")}
                           role="tabpanel"
                           aria-labelledby={`${model}RiskComparisonTab`}
              >
                <RMPRiskComparisonTab
                  sourceRMP={RiskUtils.getRMPForRiskComparisonMatrix(sourceRMP, model)}
                  newRMP={RiskUtils.getRMPForRiskComparisonMatrix(newRMP, model)}
                  tab={model}
                />
              </div>);
            })
          }
        </div>
      </Fragment>);
    } else {
      return (<RMPRiskComparisonTab
        sourceRMP={sourceRMP}
        newRMP={newRMP}
      />);
    }
  }

  render() {
    const {t} = this.props;
    let sourceRMPId = this.props.objectToCopy.RMPId;
    let showRMPRiskDiff = sourceRMPId && this.state.newRMPId;
    let sourceRMP = this.state.rmps && this.state.rmps.find(rmp => rmp.id === UIUtils.parseInt(sourceRMPId));
    let newRMP = this.state.rmps && this.state.rmps.find(rmp => rmp.id === UIUtils.parseInt(this.state.newRMPId));
    let options = this.parseRMPDataForCombobox(this.state.rmps);

    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="form-group">
              <label htmlFor="newRMPInput" className="col-form-label">
                {t("Choose a new RMP:")}
              </label>
              <div className={this.getClassForLoading()}>
                <select className="form-control"
                        id="newRMPSchemaComboBox"
                        value={this.state.newRMPId}
                        onChange={this.handleRMPSelectionChange}
                >
                  <ListOption item="" key="" />
                  {options ? options.map(option => {
                    return (
                      <ListOption item={option} key={option.key} />
                    );
                  }) : ""}
                </select>
              </div>
              <div className="help-block with-errors" />
            </div>
          </div>
          <div className="row">
            <div id="rmpConversionMatrixWidget"
                 className="col-sm-12 rmp-comparison-widget"
            >
              <div className="row">
                <div className="col-sm-12 col-form-label rmp-comparison-widget-title">
                  {t("Choosing a different RMP will cause all records in the cloned project to update to:")}
                </div>
              </div>
              {showRMPRiskDiff ? (
                this.renderRiskComparisonTabs(sourceRMP, newRMP)
              ) : ""}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(RMPChangePlugin, "widgets");
// i18next-extract-mark-ns-stop widgets
