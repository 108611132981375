"use strict";

import React from "react";
import { ListOption } from "../../../../../editor/widgets/list_option";
import BaseEditor from "./base_editor";

/**
 * This class is responsible for rendering the combobox editors in the AI Import edit screen.
 */
export default class ComboBoxEditor extends BaseEditor {
  constructor(props) {
    super(props);

    // Bind stuff
    this.handleChange = this.handleChange.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (!super.shouldComponentUpdate(nextProps, nextState, nextContext)) {
      const {value, options} = this.props;
      const nextValue = nextProps.value;
      const nextOptions = nextProps.options;

      return value !== nextValue
        || options.length !== nextOptions.length;
    }

    return true;
  }

  handleChange(event) {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
  }

  getTextForMarkup() {
    if (typeof this.props.confidence !== "undefined") {
      return this.props.value;
    } else {
      return super.getTextForMarkup();
    }
  }

  getEditorHighlightsClass() {
    return "select";
  }

  renderInput() {
    const {id} = this.props;

    return (
      <div className="import-data-editor-input-div">
        <select key="importDataEditorInput"
                id={id}
                value={this.props.value}
                disabled={this.props.disabled}
                className={"form-control import-paper-editor" + this.getEditorBorderClass()}
                onChange={this.handleChange}>
          {this.props.options
            ? this.props.options.map(option => {
              return (
                <ListOption item={option} key={option} />
              );
            }) :
            ""}
        </select>
      </div>
    );
  }
}
