/**
 * @hidden
 */
var DirectionHolder = /** @class */ (function () {
    function DirectionHolder() {
        // The phase changes in the following sequence:
        // NotInitialized -> Initialized -> NewValueReceived.
        this.phase = 'Initialized';
    }
    DirectionHolder.prototype.getIsDirectionRightToLeft = function () {
        return this.isDirectionRightToLeft;
    };
    DirectionHolder.prototype.setIsDirectionRightToLeft = function (value) {
        this.phase = this.phase === 'NotInitialized' ? 'Initialized' : 'NewValueReceived';
        this.previousIsDirectionRightToLeft = this.isDirectionRightToLeft;
        this.isDirectionRightToLeft = value;
    };
    DirectionHolder.prototype.hasDirectionChanged = function () {
        return this.phase === 'NewValueReceived' ?
            this.previousIsDirectionRightToLeft !== this.isDirectionRightToLeft : false;
    };
    return DirectionHolder;
}());
export { DirectionHolder };
