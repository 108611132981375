"use strict";

import React from "react";
import { downloadFile } from "../../import/helpers/import_helper";
import { process } from "@progress/kendo-data-query";
import { Grid, GridNoRecords } from "@progress/kendo-react-grid";
import BaseReactComponent from "../../base_react_component";
import TechTransferColumnGenerator from "./columnGenerator/tech_transfer_column_generator";

/**
 * This table class works as the base for all tables used in the tech transfer report.
 */
export default class TechTransferTable extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.ColumnGenerator = null;
  }

  get columnGenerator() {
    return new TechTransferColumnGenerator(this.props);
  }

  getColumns() {
    let {onReviewRecord} = this.props;
    this.ColumnGenerator = this.columnGenerator;

    const {
      generateAttributeColumn,
      generateRecordRelatedColumns,
    } = this.ColumnGenerator;

    return [
      generateAttributeColumn(this.props.modelName, onReviewRecord),
      ...generateRecordRelatedColumns(),
    ];
  }

  handleFileDownload(fileData, e) {
    downloadFile(e, fileData);
  }

  render() {
    const {
      tableState,
      records,
      onColumnOperationsChange,
      columnOperationsAdapter,
    } = this.props;

    return (
      <Grid
        data={process(records.slice(0),
          columnOperationsAdapter.adaptFilterNames(this.props))}
        onDataStateChange={onColumnOperationsChange}
        sortable={true}
        style={{height: "100%"}}
        {...tableState}
      >
        {this.getColumns()}
        <GridNoRecords>No records available.</GridNoRecords>
      </Grid>
    );
  }
}
