"use strict";

import * as UIUtils from "../../ui_utils";
import { RISK_MAP_COLORS } from "../../reports/constants/report_constants";
import { RISK_MAP_REPORT_FILTER_TYPE_ENUM } from "../constants/constants";
import CanvasDrawerBase from "./canvas_drawer_base";
import { RISK_COLORS } from "../../rmps/constants/rmp_constants";

export default class RiskMapReportCanvasDrawer extends CanvasDrawerBase {
  static drawFiltersArray(filterType, fontFamily, fontSize, fontColor, label, filters, margins, filtersSpace, isByTypeRMP) {
    let canvas = RiskMapReportCanvasDrawer.getEmptyCanvas();
    canvas.width = 0;
    canvas.height = 0;

    if (filters.length > 0) {
      let ctx = canvas.getContext("2d");
      let x = margins.left;
      let y = margins.top;
      let labelFont = "900 " + fontSize + " " + fontFamily;
      let filtersFont = "400 " + fontSize + " " + fontFamily;
      let filtersHeights = {};
      let legendShapeSize = 10;
      let legendShapeMargin = 5;
      let modelNameOffset = (isByTypeRMP ? 15 : 0);

      let fontOptions = {
        fontFamily: fontFamily,
        fontSize: fontSize,
        fontWeight: "400"
      };

      ctx.font = labelFont;
      let labelWidth = ctx.measureText(label).width;
      let labelHeight = UIUtils.getTextSize(fontOptions, label + filters[0].label ? filters[0].label : filters[0]);
      ctx.font = filtersFont;
      let maxFiltersWidth = 0;
      let filtersHeight = 0;
      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        let filterLabel = filter.label ? filter.label : filter;
        let filterWidth = ctx.measureText(filterLabel).width;
        maxFiltersWidth = (maxFiltersWidth > filterWidth) ? maxFiltersWidth : filterWidth;
        fontOptions.fontSize = "400";
        filtersHeights[filterLabel] = UIUtils.getTextSize(fontOptions, filterLabel);
        filtersHeight += filtersHeights[filterLabel].ascent + filtersSpace;
      }

      if (isByTypeRMP) {
        for (let typeCode of UIUtils.RISK_MODELS
          .filter(riskModel => !riskModel.isRiskLink)
          .map(riskModel => riskModel.model)) {
          let requirementTextSize = UIUtils.getTextSize(fontOptions, typeCode);
          filtersHeight += requirementTextSize.ascent + filtersSpace;
        }
      }

      //Set the width of the canvas including any specified margins
      canvas.width = labelWidth + maxFiltersWidth + margins.left + margins.right +
        ((filterType !== RISK_MAP_REPORT_FILTER_TYPE_ENUM.SpecificElements) ? legendShapeSize + legendShapeMargin : 0) +
        modelNameOffset;
      canvas.height = ((labelHeight.height > filtersHeight) ? labelHeight.height : filtersHeight) + margins.top + margins.bottom;

      //Fill the background with white color
      ctx.fillStyle = "#FFFFFF";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      //Render the Filters label
      ctx.fillStyle = fontColor ? fontColor : "#666666";
      ctx.font = labelFont;
      y = margins.top + labelHeight.ascent;
      ctx.fillText(label, x, y);
      x = x + labelWidth;
      ctx.font = filtersFont;

      let drownModelsForLegend = [];
      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        let filterLabel = filter.label ? filter.label : filter;
        if (filterType === RISK_MAP_REPORT_FILTER_TYPE_ENUM.Element) {
          ctx.fillStyle = "#666666";
          this.fillInShapeByModelType(filterLabel, legendShapeSize, ctx, x, y);
        } else if (filterType === RISK_MAP_REPORT_FILTER_TYPE_ENUM.Risk) {
          if (filter.modelName && !drownModelsForLegend.includes(filter.modelName)) {
            ctx.fillText(filter.modelName, x + legendShapeSize + legendShapeMargin, y);
            this.fillInShapeByModelType(filter.modelName, legendShapeSize, ctx, x, y);
            y = y + filtersHeights[filterLabel].ascent + filtersSpace;
          }
          filter.color = filter.color ? filter.color : RISK_COLORS.NONE;
          ctx.fillStyle = RISK_MAP_COLORS[filter.color].background;
          ctx.strokeStyle = RISK_MAP_COLORS[filter.color].border;
          ctx.fillRect(x + modelNameOffset, y - legendShapeSize, legendShapeSize, legendShapeSize);
          ctx.strokeRect(x + modelNameOffset, y - legendShapeSize, legendShapeSize, legendShapeSize);
          drownModelsForLegend.push(filter.modelName);
        }

        if (this.filterHasShape(filterType)) {
          x = x + legendShapeSize + legendShapeMargin;
        }

        ctx.fillStyle = fontColor ? fontColor : "#666666";
        ctx.fillText(filterLabel, x + modelNameOffset, y);

        if (this.filterHasShape(filterType)) {
          x = x - legendShapeSize - legendShapeMargin;
        }
        y = y + filtersHeights[filterLabel].ascent + filtersSpace;
      }
    }

    return canvas;
  }

  static filterHasShape(filterType) {
    return filterType === RISK_MAP_REPORT_FILTER_TYPE_ENUM.Element
      || filterType === RISK_MAP_REPORT_FILTER_TYPE_ENUM.Risk;
  }

  static fillInShapeByModelType(modelType, legendShapeSize, ctx, x, y) {
    switch (modelType) {
      case "Target Product Profiles":
      case "General Attributes":
        ctx.fillRect(x, y - legendShapeSize, legendShapeSize, legendShapeSize);
        ctx.strokeRect(x, y - legendShapeSize, legendShapeSize, legendShapeSize);
        break;
      case "Final Quality Attributes":
      case "Final Performance Attributes":
      case "FQA":
      case "FPA":
        this.drawCircle(ctx, x, y, legendShapeSize);
        break;
      case "Intermediate Quality Attributes":
      case "Intermediate Performance Attributes":
      case "IQA":
      case "IPA":
        this.drawHexagon(ctx, x + legendShapeSize / 2, y - legendShapeSize / 2, legendShapeSize);
        break;
      case "Process Parameters":
      case "PP":
        this.drawTriangle(ctx, x, y, legendShapeSize);
        break;
      case "Material Attributes":
      case "MA":
        this.drawStar(ctx, x + legendShapeSize / 2, y - legendShapeSize / 2, 5, legendShapeSize / 1.5, legendShapeSize / 4);
        break;
    }
  }
}
