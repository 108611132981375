import * as React from 'react';
import { dispatchDragAndDrop, autoScroll as autoScrollElement, getScrollableParent } from '@progress/kendo-draggable-common';
import { noop } from '../noop';
import { useInheritedState } from '../hooks/useInheritedState';
import { DragContext, DropContext, DragsContext, DropsContext } from '../drag-n-drop/context';
var IGNORE_MOUSE_TIMEOUT = 2000;
/**
 * Represents the KendoReact `useDraggable` hook.
 * Use it to attach `drag` events to a native HTML DOM elements, or custom React Components.
 *
 * For more information, refer to the [KendoReact Draggable]({% slug draggable_drag-and-drop %}) article.
 *
 * @param ref - The `ref` of the HTML Element or React Component which will enable the `draggable` functionality.
 * @param callbacks - A collection of callbacks, called by the `useDraggable` hook when a specific action occurs.
 * @param options - Additional configuration of the `useDraggable` hook.
 */
export function useDraggable(ref, callbacks, options) {
    if (options === void 0) { options = {}; }
    var _a = callbacks.onPress, onPress = _a === void 0 ? noop : _a, _b = callbacks.onRelease, onRelease = _b === void 0 ? noop : _b, _c = callbacks.onDragStart, onDragStart = _c === void 0 ? noop : _c, _d = callbacks.onDrag, onDrag = _d === void 0 ? noop : _d, _e = callbacks.onDragEnd, onDragEnd = _e === void 0 ? noop : _e;
    var _f = options.hint, hint = _f === void 0 ? null : _f, _g = options.mouseOnly, mouseOnly = _g === void 0 ? false : _g, _h = options.autoScroll, autoScroll = _h === void 0 ? true : _h, _j = options.scrollContainer, scrollContainer = _j === void 0 ? null : _j;
    var _k = React.useState(false), pressed = _k[0], setPressed = _k[1];
    var _l = React.useState(false), scrolling = _l[0], setScrolling = _l[1];
    var drop = useInheritedState(DropContext)[0];
    var _m = useInheritedState(DragContext), drag = _m[0], setDrag = _m[1];
    var drops = React.useContext(DropsContext)[0];
    var _o = React.useContext(DragsContext), drags = _o[0], registerDrag = _o[1], deregisterDrag = _o[2];
    var velocity = React.useRef({ x: 0, y: 0 });
    var scrollInterval = React.useRef();
    var ignoreMouse = React.useRef(false);
    var restoreMouseTimeout = React.useRef();
    var scrollable = React.useRef(null);
    var unmount = React.useRef(false);
    var offset = React.useRef({ x: 0, y: 0 });
    var pageOffset = React.useRef({ x: 0, y: 0 });
    var clientOffset = React.useRef({ x: 0, y: 0 });
    var initialClientOffset = React.useRef({ x: 0, y: 0 });
    var scrollOffset = React.useRef({ x: 0, y: 0 });
    var initialScrollOffset = React.useRef({ x: 0, y: 0 });
    var supportPointerEvent = Boolean((typeof window !== 'undefined') && window.PointerEvent);
    var pointers = !mouseOnly && supportPointerEvent;
    var getElement = React.useCallback(function () { return ref.current && ref.current.element
        ? ref.current.element
        : ref.current; }, [ref]);
    var getHintElement = React.useCallback(function () { return hint && hint.current && hint.current.element
        ? hint.current.element
        : hint
            ? hint.current
            : null; }, [hint]);
    var getScrollContainer = React.useCallback(function () { return scrollContainer && scrollContainer.current && scrollContainer.current.element
        ? scrollContainer.current.element
        : scrollContainer
            ? scrollContainer.current
            : null; }, [scrollContainer]);
    var getAutoScrollContainer = React.useCallback(function () { return typeof autoScroll === 'object' && autoScroll.boundaryElementRef
        && autoScroll.boundaryElementRef.current && autoScroll.boundaryElementRef.current.element
        ? autoScroll.boundaryElementRef.current.element
        : typeof autoScroll === 'object' && autoScroll.boundaryElementRef && autoScroll.boundaryElementRef.current
            ? autoScroll.boundaryElementRef.current
            : null; }, [autoScroll]);
    var target = React.useRef(null);
    React.useImperativeHandle(target, function () { return ({
        element: getElement(),
        hint: getHintElement(),
        onPress: handlePress,
        onDragStart: handleDragStart,
        onDrag: handleDrag,
        onDragEnd: handleDragEnd,
        onRelease: handleRelease,
        data: ref.current
    }); });
    var getDocument = React.useCallback(function () {
        var element = getElement();
        return element ? element.ownerDocument || document : document;
    }, [getElement]);
    var getWindow = React.useCallback(function () {
        var document = getDocument();
        return document
            ? document.defaultView || window
            : window;
    }, [getDocument]);
    var getState = React.useCallback(function () { return ({
        drag: drag ? drag.current : null,
        drop: drop ? drop.current : null,
        drags: drags.map(function (d) { return d.current; }),
        drops: drops.map(function (d) { return d.current; }),
        pressed: pressed,
        ignoreMouse: ignoreMouse.current,
        scrollOffset: scrollOffset.current,
        offset: offset.current,
        pageOffset: pageOffset.current,
        initialScrollOffset: initialScrollOffset.current,
        clientOffset: clientOffset.current,
        initialClientOffset: initialClientOffset.current,
        velocity: velocity.current,
        autoScroll: Boolean(typeof autoScroll === 'object' ? autoScroll.enabled !== false : autoScroll),
        scrollableParent: getAutoScrollContainer(),
        autoScrollDirection: typeof autoScroll === 'object' ? autoScroll.direction : { horizontal: true, vertical: true },
        isScrolling: scrolling
    }); }, [drag, drop, drags, drops, pressed, autoScroll, getAutoScrollContainer, scrolling]);
    var handlePressedChange = React.useCallback(function (value) {
        setPressed(value);
    }, []);
    var handleScrollingChange = React.useCallback(function (value) {
        setScrolling(value);
    }, []);
    var handleVelocityChange = React.useCallback(function (value) {
        velocity.current = value;
    }, []);
    var handleOffsetChange = React.useCallback(function (value) {
        offset.current = value;
    }, []);
    var handleClientOffsetChange = React.useCallback(function (value) {
        clientOffset.current = value;
    }, []);
    var handlePageOffsetChange = React.useCallback(function (value) {
        pageOffset.current = value;
    }, []);
    var handleInitialClientOffsetChange = React.useCallback(function (value) {
        initialClientOffset.current = value;
    }, []);
    var handleScrollOffsetChange = React.useCallback(function (value) {
        scrollOffset.current = value;
    }, []);
    var handleInitialScrollOffsetChange = React.useCallback(function (value) {
        initialScrollOffset.current = value;
    }, []);
    // Drag Events
    var handlePress = React.useCallback(function (event) {
        onPress(event);
    }, [onPress]);
    var handleRelease = React.useCallback(function (event) {
        onRelease(event);
    }, [onRelease]);
    var handleDragStart = React.useCallback(function (event) {
        setDrag(target, { target: ref.current, event: event });
        onDragStart(event);
    }, [setDrag, ref, onDragStart]);
    var handleDrag = React.useCallback(function (event) {
        onDrag(event);
    }, [onDrag]);
    var handleDragEnd = React.useCallback(function (event) {
        if (unmount.current) {
            return;
        }
        setDrag(null, { target: ref.current, event: event });
        onDragEnd(event);
    }, [onDragEnd, setDrag, ref]);
    var dispatchDragEvent = React.useCallback(function (event) {
        dispatchDragAndDrop(getState(), { event: event, payload: target.current }, {
            onVelocityChange: handleVelocityChange,
            onOffsetChange: handleOffsetChange,
            onClientOffsetChange: handleClientOffsetChange,
            onPageOffsetChange: handlePageOffsetChange,
            onInitialClientOffsetChange: handleInitialClientOffsetChange,
            onScrollOffsetChange: handleScrollOffsetChange,
            onInitialScrollOffsetChange: handleInitialScrollOffsetChange,
            onIsPressedChange: handlePressedChange,
            onIsScrollingChange: handleScrollingChange
        });
    }, [
        getState,
        handleVelocityChange,
        handleOffsetChange,
        handlePageOffsetChange,
        handleClientOffsetChange,
        handleInitialClientOffsetChange,
        handleInitialScrollOffsetChange,
        handlePressedChange,
        handleScrollOffsetChange,
        handleScrollingChange
    ]);
    // Pointer Events
    var handlePointerDown = React.useCallback(function (event) {
        dispatchDragEvent(event);
    }, [dispatchDragEvent]);
    var handlePointerMove = React.useCallback(function (event) {
        dispatchDragEvent(event);
    }, [dispatchDragEvent]);
    var handlePointerCancel = React.useCallback(function (event) {
        dispatchDragEvent(event);
    }, [dispatchDragEvent]);
    var handlePointerUp = React.useCallback(function (event) {
        dispatchDragEvent(event);
    }, [dispatchDragEvent]);
    // Mouse Events
    var handleMouseDown = React.useCallback(function (event) {
        dispatchDragEvent(event);
    }, [dispatchDragEvent]);
    var handleMouseMove = React.useCallback(function (event) {
        dispatchDragEvent(event);
    }, [dispatchDragEvent]);
    var handleMouseUp = React.useCallback(function (event) {
        dispatchDragEvent(event);
    }, [dispatchDragEvent]);
    var handleContextMenu = React.useCallback(function (event) {
        event.preventDefault();
        dispatchDragEvent(event);
    }, [dispatchDragEvent]);
    // Touch Events
    var handleTouchStart = React.useCallback(function (event) {
        event.preventDefault();
        dispatchDragEvent(event);
    }, [dispatchDragEvent]);
    var handleTouchMove = React.useCallback(function (event) {
        event.preventDefault();
        dispatchDragEvent(event);
    }, [dispatchDragEvent]);
    var handleTouchEnd = React.useCallback(function (event) {
        if (event.touches.length === 0 && event.changedTouches.length === 1) {
            var currentWindow = getWindow();
            ignoreMouse.current = true;
            restoreMouseTimeout.current = currentWindow.setTimeout(function () { ignoreMouse.current = false; }, IGNORE_MOUSE_TIMEOUT);
        }
        dispatchDragEvent(event);
    }, [dispatchDragEvent, getWindow]);
    var handleScroll = React.useCallback(function (event) {
        dispatchDragEvent(event);
    }, [dispatchDragEvent]);
    // Misc
    var prerequisites = React.useCallback(function () {
        var element = getElement();
        if (element) {
            var initialTouchAction_1 = element.style.touchAction;
            element.style.touchAction = 'none';
            return function () {
                element.style.touchAction = initialTouchAction_1;
            };
        }
    }, [getElement]);
    var register = React.useCallback(function () {
        registerDrag(target);
        return function () {
            deregisterDrag(target);
        };
    }, [deregisterDrag, registerDrag]);
    var init = function () {
        var window = getWindow();
        var element = getElement();
        var document = getDocument();
        if (pointers) {
            if (element) {
                scrollable.current = getScrollableParent(element);
                if (scrollable.current) {
                    scrollable.current.addEventListener('scroll', handleScroll, { passive: true });
                }
                element.addEventListener('pointerdown', handlePointerDown, { passive: true });
            }
            if (pressed) {
                document.addEventListener('pointermove', handlePointerMove);
                document.addEventListener('pointerup', handlePointerUp, true);
                document.addEventListener('contextmenu', handleContextMenu);
                document.addEventListener('pointercancel', handlePointerCancel, { passive: true });
            }
        }
        else {
            // Hacky-hacky iOS Safari
            window.addEventListener('touchmove', noop, { capture: false, passive: false });
            if (element) {
                element.addEventListener('mousedown', handleMouseDown, { passive: true });
                if (!mouseOnly) {
                    element.addEventListener('touchstart', handleTouchStart, { passive: true });
                    if (pressed) {
                        element.addEventListener('touchmove', handleTouchMove, { passive: true });
                        element.addEventListener('touchend', handleTouchEnd, { passive: true });
                    }
                }
            }
            if (pressed) {
                document.addEventListener('mousemove', handleMouseMove, { passive: true });
                document.addEventListener('mouseup', handleMouseUp, { passive: true });
            }
        }
        return function () {
            if (scrollable.current) {
                scrollable.current.removeEventListener('scroll', handleScroll);
            }
            if (element) {
                element.removeEventListener('pointerdown', handlePointerDown);
                element.removeEventListener('mousedown', handleMouseDown);
                element.removeEventListener('touchstart', handleTouchStart);
                element.removeEventListener('touchmove', handleTouchMove);
                element.removeEventListener('touchend', handleTouchEnd);
            }
            document.removeEventListener('pointermove', handlePointerMove);
            document.removeEventListener('pointerup', handlePointerUp, true);
            document.removeEventListener('contextmenu', handleContextMenu);
            document.removeEventListener('pointercancel', handlePointerCancel);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchmove', noop);
            window.clearTimeout(restoreMouseTimeout.current);
        };
    };
    React.useEffect(function () {
        var window = getWindow();
        if (scrolling) {
            var scrollableParent_1 = getScrollContainer()
                || getScrollableParent(document.elementFromPoint(clientOffset.current.x, clientOffset.current.y));
            window.clearInterval(scrollInterval.current);
            scrollInterval.current = window.setInterval(function () {
                autoScrollElement(scrollableParent_1, { x: velocity.current.x, y: velocity.current.y });
            }, 50);
        }
        return function () {
            window.clearInterval(scrollInterval.current);
        };
    }, [getElement, getScrollContainer, getWindow, scrolling]);
    React.useEffect(prerequisites, [prerequisites]);
    React.useEffect(init, [
        pressed,
        getWindow,
        getElement,
        getDocument,
        mouseOnly,
        pointers,
        handleContextMenu,
        handleMouseDown,
        handleMouseMove,
        handleMouseUp,
        handlePointerCancel,
        handlePointerDown,
        handlePointerMove,
        handlePointerUp,
        handleTouchEnd,
        handleTouchMove,
        handleTouchStart,
        handleScroll
    ]);
    React.useEffect(function () {
        return function () {
            unmount.current = true;
        };
    }, []);
    React.useLayoutEffect(register, [register]);
}
