"use strict";

import * as UIUtils from "../../ui_utils";
import { getNextExcelHeaderCell } from "../../../server/common/excel/common_excel_utills";
import { acceptanceCriteriaToString } from "../../../server/common/editables/common_editables_formatter";
import { transformMultiValueForDisplay } from "../../../server/common/editables/common_editables";

/**
 * Prefills an excel import template with data from the DB, to assist in user data input for measurements.
 * @param workbook The excel workbook to prefill.
 * @param modelName The model name of the imported entity.
 * @param data Contains information about the Project, Process and Attributes the user is importing Process Data for.
 * @param parentRecords The parent records of the records for which Process data is being imported.
 * @param templateIsForContinuousData
 * @returns {*}
 */
module.exports.prefillImportTemplateWithData = async function(workbook, modelName, data, parentRecords, templateIsForContinuousData) {
  const sheet = workbook.sheet(0);
  if (modelName === "MultipleAttributes" && templateIsForContinuousData) {
    prefillBatchTemplateForContinuousData(sheet, data);
  } else if (modelName === "Attribute") {
    prefillBatchesTemplateForSingleAttribute(sheet, data.attributes);
  } else if (modelName === "UnitOperation" && !templateIsForContinuousData) {
    await prefillBatchForUOsAttributesTemplate(sheet, modelName, data);
  } else if (modelName !== "Attribute" && !templateIsForContinuousData) {
    prefillBatchGenericTemplate(sheet, modelName, data, parentRecords);
  }

  return workbook;
};

/**
 * Prefills an import template with single batch - multi attribute information for allowing the user to enter continuous
 * Process Data across multiple attributes for a single batch.
 * @param sheet The sheet to prefill the data in.
 * @param data Contains information about the Project, Process and Attributes the user is importing Process Data for.
 */
function prefillBatchTemplateForContinuousData(sheet, data) {
  const {attributes, project, process, batch} = data;
  const attributesInfoStartIndex = 11;
  const CommonInfoStartIndex = 3;
  let startColumn = "A";
  let endColumn;
  for (let record of attributes.filter(record => record)) {
    //Quick column accessor methods
    let columnA = index => `${startColumn}${index || ""}`;
    let columnB = index => `${getNextExcelHeaderCell(startColumn, 1)}${index || ""}`;
    let columnC = index => `${getNextExcelHeaderCell(startColumn, 2)}${index || ""}`;
    let mergeColumnsAndSetValue = (columnA, columnB, value) => {
      let range = sheet.range(`${columnA}:${columnB}`);
      range.merged(true);
      range.value(value);
      return range;
    };
    const recordAcceptanceCriteria = acceptanceCriteriaToString(record);
    const borderStyle = {style: "thick", color: "000000"};

    // Fill in Project and Process related information
    sheet.cell(`E${CommonInfoStartIndex}`).value(UIUtils.getRecordLabelForDisplay("PRJ", project.id, project.name));
    sheet.cell(`E${CommonInfoStartIndex + 1}`).value(UIUtils.getRecordLabelForDisplay("PR", process.id, process.name));
    if (batch && batch.id !== -1) {
      sheet.cell(`E${CommonInfoStartIndex + 2}`).value(batch.customID);
      sheet.cell(`E${CommonInfoStartIndex + 3}`).value(batch.site);
      sheet.cell(`E${CommonInfoStartIndex + 4}`).value(batch.scale);
      sheet.cell(`E${CommonInfoStartIndex + 5}`).value(transformMultiValueForDisplay(batch.batchType));
    }

    // Fill in the background color
    sheet.range(`${columnA(attributesInfoStartIndex)}:${columnC(attributesInfoStartIndex + 5)}`).style("fill", "f3f3f3");
    sheet.range(`${columnA(attributesInfoStartIndex)}:${columnC(attributesInfoStartIndex + 5)}`).style("border", false);
    sheet.range(`${columnA(attributesInfoStartIndex)}:${columnC(attributesInfoStartIndex + 5)}`).style("verticalAlignment", "bottom");
    sheet.range(`${columnA(attributesInfoStartIndex)}:${columnC(attributesInfoStartIndex + 5)}`).style("horizontalAlignment", "center");
    sheet.range(`${columnA(attributesInfoStartIndex)}:${columnC(attributesInfoStartIndex + 5)}`).style("bold", "true");

    // Fill in the attribute labels
    mergeColumnsAndSetValue(columnA(attributesInfoStartIndex), columnB(attributesInfoStartIndex), "Requirement")
      .style("topBorder", borderStyle)
      .style("horizontalAlignment", "left");
    mergeColumnsAndSetValue(columnA(attributesInfoStartIndex + 1), columnB(attributesInfoStartIndex + 1), "Unit Operation")
      .style("horizontalAlignment", "left");
    mergeColumnsAndSetValue(columnA(attributesInfoStartIndex + 2), columnB(attributesInfoStartIndex + 2), "Step")
      .style("horizontalAlignment", "left");
    mergeColumnsAndSetValue(columnA(attributesInfoStartIndex + 3), columnB(attributesInfoStartIndex + 3), "Measure")
      .style("horizontalAlignment", "left");
    mergeColumnsAndSetValue(columnA(attributesInfoStartIndex + 4), columnB(attributesInfoStartIndex + 4), "Acceptance Criteria")
      .style("horizontalAlignment", "left");
    mergeColumnsAndSetValue(columnA(attributesInfoStartIndex + 5), columnB(attributesInfoStartIndex + 5), "Measurement Units")
      .style("horizontalAlignment", "left");
    mergeColumnsAndSetValue(columnA(attributesInfoStartIndex + 6), columnB(attributesInfoStartIndex + 6), "# of Samples")
      .style("horizontalAlignment", "left");

    // Fill in the attribute values
    sheet.cell(`${columnC(attributesInfoStartIndex)}`).value(`${record.label}`)
      .style("topBorder", borderStyle)
      .style("horizontalAlignment", "right");
    sheet.cell(`${columnC(attributesInfoStartIndex + 1)}`).value(`${record.unitOperation || ""}`)
      .style("horizontalAlignment", "right");
    sheet.cell(`${columnC(attributesInfoStartIndex + 2)}`).value(`${record.step || ""}`)
      .style("horizontalAlignment", "right");
    sheet.cell(`${columnC(attributesInfoStartIndex + 3)}`).value(record.measure)
      .style("horizontalAlignment", "right");
    sheet.cell(`${columnC(attributesInfoStartIndex + 4)}`).value(recordAcceptanceCriteria)
      .style("horizontalAlignment", "right");
    sheet.cell(`${columnC(attributesInfoStartIndex + 5)}`).value(record.measurementUnits)
      .style("horizontalAlignment", "right");
    sheet.cell(`${columnC(attributesInfoStartIndex + 6)}`)
      .formula(`=COUNTA(INDIRECT("${columnC()}${attributesInfoStartIndex + 9}:${columnC()}"&ROWS(${columnC()}:${columnC()})))`)
      .style("horizontalAlignment", "right");

    // Fill in the Optional - Required labels
    sheet.cell(`${columnA(attributesInfoStartIndex + 7)}`).value("Optional");
    sheet.cell(`${columnB(attributesInfoStartIndex + 7)}`).value("Optional");
    sheet.cell(`${columnC(attributesInfoStartIndex + 7)}`).value("Required");
    sheet.range(`${columnA(attributesInfoStartIndex + 7)}:${columnC(attributesInfoStartIndex + 7)}`).style("fill", "d8d8d8");
    sheet.range(`${columnA(attributesInfoStartIndex + 7)}:${columnC(attributesInfoStartIndex + 7)}`).style("italic", "true");
    sheet.range(`${columnA(attributesInfoStartIndex + 7)}:${columnC(attributesInfoStartIndex + 7)}`).style("horizontalAlignment", "left");

    // Set the left & right borders to separate the attributes in the header.
    sheet.range(`${columnA()}${attributesInfoStartIndex}:${columnA()}${attributesInfoStartIndex + 8}`).style("leftBorder", borderStyle);
    sheet.range(`${columnC()}${attributesInfoStartIndex}:${columnC()}${attributesInfoStartIndex + 8}`).style("rightBorder", borderStyle);

    // Fill in the Labels for the user entered values, i.e Timestamp - Label - Measurement
    sheet.cell(`${columnA(attributesInfoStartIndex + 8)}`).value("Timestamp");
    sheet.cell(`${columnB(attributesInfoStartIndex + 8)}`).value("Label");
    sheet.cell(`${columnC(attributesInfoStartIndex + 8)}`).value("Measurement");
    sheet.range(`${columnA(attributesInfoStartIndex + 8)}:${columnC(attributesInfoStartIndex + 8)}`).style("fill", "cfe2f3");
    sheet.range(`${columnA(attributesInfoStartIndex + 8)}:${columnC(attributesInfoStartIndex + 8)}`).style("bold", "true");
    sheet.range(`${columnA(attributesInfoStartIndex + 8)}:${columnC(attributesInfoStartIndex + 8)}`).style("horizontalAlignment", "center");

    endColumn = startColumn;
    startColumn = getNextExcelHeaderCell(startColumn, 3);
  }

  // The last column in the sheet is 2 columns after the last requirement label.
  endColumn = getNextExcelHeaderCell(endColumn, 2);

  // Update the fill color for the first row and the ones holding batch information to expand till the last requirement
  sheet.range(`A1:${endColumn}1`).style("fill", "f2f2f2");
  sheet.range(`A2:${endColumn}${attributesInfoStartIndex - 1}`).style("fill", "d8d8d8");
  sheet.range(`A2:${endColumn}${attributesInfoStartIndex - 1}`).style("border", false);

  // Update special cells with their background colors
  // 1. The date cells should have a white background
  sheet.range(`B6:B7`).style("fill", "ffffff");

  // 2. The project & batch meta data cells should have a blue color
  sheet.range(`D3:D8`).style("fill", "cfe2f3");
}

/**
 * Prefills an import template with multi batch - single attribute information for allowing the user to enter Process
 * Data across multiple batches for a single attribute.
 * @param sheet The sheet to prefill.
 * @param records The records for which Process Data is being imported.
 */
function prefillBatchesTemplateForSingleAttribute(sheet, records) {
  sheet.cell("D3").value(records[0].key);
  sheet.cell("D4").value(records[0].name);
  sheet.cell("D5").value(records[0].measure);
  sheet.cell("D7").value("Multiple Batches");
}

async function prefillBatchForUOsAttributesTemplate(sheet, modelName, data) {
  prefillProcessAndProjectInfo(sheet, data);

  let startIndex = 13;
  const borderStyle = {style: "thick", color: "d8d8d8"};
  for (let attribute of data.attributes) {
    const acceptanceCriteria = acceptanceCriteriaToString(attribute);
    sheet.cell(`A${startIndex}`).value(attribute.unitOperation);
    sheet.cell(`B${startIndex}`).value(`${attribute?.step ? attribute.step : "Global"}`);
    sheet.cell(`C${startIndex}`).value(attribute.key);
    sheet.cell(`D${startIndex}`).value(attribute.name);
    sheet.cell(`E${startIndex}`).value(`${attribute.measure}${attribute.measurementUnits ? " (" + attribute.measurementUnits + ")" : ""}`);
    sheet.cell(`F${startIndex}`).value(acceptanceCriteria);

    // Update the auto filled in attribute information cells background with the right color
    // 1. The date cells should have a white background
    sheet.range(`A${startIndex}:E${startIndex}`).style("fill", "d8d8d8");
    sheet.range(`F${startIndex}:AT${startIndex}`).style("border", borderStyle);

    startIndex++;
  }
}

/**
 * Prefills an import template with single batch - multi attribute information for allowing the user to enter Process
 * Data across multiple attributes for a single batch.
 */
function prefillBatchGenericTemplate(sheet, modelName, data, parentRecords) {
  const {attributes} = data;
  let parentRecord = parentRecords ? parentRecords[0] : null;

  prefillProcessAndProjectInfo(sheet, data);

  let scope = "";
  switch (modelName) {
    case "FQA":
      scope = "Multiple FQAs";
      break;
    case "UnitOperation":
    case "Material":
      scope = parentRecord ? parentRecord.label : "";
      break;
  }
  sheet.cell("D9").value(scope);

  let startIndex = 14;
  const borderStyle = {style: "thick", color: "d8d8d8"};

  for (let attribute of attributes) {
    const acceptanceCriteria = acceptanceCriteriaToString(attribute);

    sheet.cell(`A${startIndex}`).value(attribute.key);
    sheet.cell(`B${startIndex}`).value(attribute.name);
    sheet.cell(`C${startIndex}`).value(`${attribute.measure}${attribute.measurementUnits ? " (" + attribute.measurementUnits + ")" : ""}`);
    sheet.cell(`D${startIndex}`).value(acceptanceCriteria);

    // Update the auto-filled in attribute information cells background with the right color
    // 1. The date cells should have a white background
    sheet.range(`A${startIndex}:E${startIndex}`).style("fill", "d8d8d8");
    sheet.range(`F${startIndex}:AS${startIndex}`).style("border", borderStyle);

    startIndex++;
  }
}

/**
 * Fills in the top level section of the generic and UOs import templates with the project, process and batch
 * information.
 */
function prefillProcessAndProjectInfo(sheet, {project, process, batch}) {
  // Fill in Project and Process related information
  sheet.cell(`D3`).value(UIUtils.getRecordLabelForDisplay("PRJ", project.id, project.name));
  sheet.cell(`D4`).value(UIUtils.getRecordLabelForDisplay("PR", process.id, process.name));
  if (batch && batch.id !== -1) {
    sheet.cell(`D5`).value(batch.customID);
    sheet.cell(`D6`).value(batch.site);
    sheet.cell(`D7`).value(batch.scale);
    sheet.cell(`D8`).value(transformMultiValueForDisplay(batch.batchType));
  }
}