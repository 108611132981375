"use strict";

import * as UIUtils from "../ui_utils";
import React, { Fragment } from "react";
import Section from "../editor/widgets/section";
import ComboBoxAttribute from "../editor/attributes/combo_box_attribute";
import TextAreaAttribute from "../editor/attributes/text_area_attribute";
import TextAttribute from "../editor/attributes/text_attribute";
import TypeaheadAttribute from "../editor/attributes/typeahead_attribute";
import EditablesPageTitleBar from "../widgets/pageTitleBar/editables_page_title_bar";
import { getURLByKey, getURLByTypeCodeAndId, getURLByTypeCodeAndIdAndVersionId } from "../helpers/url_helper";
import LabelTooltip from "../widgets/tooltips/label_tooltip";
import InfoTooltip from "../widgets/tooltips/info_tooltip";
import ProjectAdminsTooltip from "../editor/widgets/project_admins_tooltip";
import { EDITOR_TYPES } from "../editor/editor_constants";
import BaseQuickEditor from "../editor/base_quick_editor";
import { PROJECT_TYPES } from "../utils/project_helper";
import ReferenceDocuments from "@cherrycircle/fda-docs";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";
import FullObjectCache from "../utils/cache/full_object_cache";
import RadioAttribute from "../editor/attributes/radio_attribute";
import * as CommonEditables from "../../server/common/editables/common_editables";
import * as FailHandlers from "../utils/fail_handlers";
import * as orangeBookJSON from "./orange_book.json";
import moment from "moment/moment";
import { can } from "../utils/ui_permissions";
import CommonSecurity from "../../server/common/generic/common_security";
import { NewRmpWarning } from "./components/new_rmp_warning";


const DEFAULT_RMP_VALUE = "Default 4x4 RMP";

/**
 * This class is responsible for viewing and editing meta-data about a project.
 */
class Project extends BaseQuickEditor {
  constructor(props) {
    super(props, "project");

    let invalidateCache = Promise.resolve();
    if (this.isAdd() || this.isEdit()) {
      invalidateCache = new TypeaheadObjectCache("RMP").invalidateCacheOptionsAsync();
    }
    if (this.isAdd()) {
      new TypeaheadObjectCache("User").loadOptions(this.handleUserTypeaheadResultsFromServer);
    }

    if (this.isAdd()) {
      this.setStateSafely({
        breadcrumb: {
          current: "New Project",
          parent: "Projects",
          parentLink: "/projects/list.html",
        },
      });
    }

    invalidateCache.then(() => {
      new TypeaheadObjectCache("RMP").loadOptions(this.handleReceiveRMPs, {latestApproved: true});
    });

    new TypeaheadObjectCache("Project").loadOptions(this.handleTypeaheadResultsFromServer);
    this.loadAllRMPs();

    this.orangeBook = orangeBookJSON.entries;
    this.forceUpdateSafely();
  }

  componentDidMount() {
    document.title = "QbDVision Project";

    super.componentDidMount();
  }

  beforeDataSavedToServer(callback) {
    new TypeaheadObjectCache("Project").invalidateCacheOptions();
    super.beforeDataSavedToServer(callback);
  }

  handleTypeaheadResultsFromServer() {
    this.forceUpdateSafely();
  }

  handleUserTypeaheadResultsFromServer(results) {
    if (!this.state.users) {
      this.setStateSafely({
        users: results,
      });
    }
  }

  handleSendApproval(approve) {
    return super.handleSendApproval(approve).then((approvalCompletedSuccessfully) => {
      if (approvalCompletedSuccessfully) {
        const cache = new FullObjectCache("Project", this.getProjectId());
        cache.invalidateCacheOptionsAsync().then(() => {
          cache.loadOptions(() => {
            this.forceUpdateSafely();
          });
        });
      }
    });
  }

  handleSaveResults(result) {
    const typeaheadObjectCache = new TypeaheadObjectCache("User");
    typeaheadObjectCache.invalidateCacheOptionsAsync().then(() => {
      typeaheadObjectCache.loadOptions(() => {
        super.handleSaveResults(result);
      });
    });
  }

  renderPageTitleBar() {
    return this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? (
      <EditablesPageTitleBar projectId={this.getProjectId()}
                             projectName={this.getProjectName()}
                             projectDeletedAt={this.getProjectDeletedAt()}
                             isDemoProject={this.isDemoProject()}
                             currentState={this.getCurrentState()}
                             isProjectsPage={true}
      />
    ) : "";
  }

  getTabName() {
    return "Dashboard";
  }

  /**
   * RMPs used in typeahead
   * @param data
   */
  handleReceiveRMPs(data) {
    data = data || [];
    const riskManagementPlans = data.map(rmp => {
      return {key: rmp.id, value: `${rmp.name} (v${rmp.majorVersion}.${rmp.minorVersion})`};
    });
    const defaultRMP = riskManagementPlans.find(rmp => rmp.value.includes(DEFAULT_RMP_VALUE));

    this.setStateSafely({
      riskManagementPlans,
      defaultRMP: defaultRMP && defaultRMP.key,
    }, this.loadAllRMPs);
  }

  handleReceiveOrangeBook(data) {
    if (typeof data === "string") {
      data = JSON.parse(data);
    }

    this.orangeBook = data.entries;
    this.forceUpdateSafely();
  }

  /***
   * Load all RMPs including version information
   */
  loadAllRMPs() {
    new Promise((resolve) => {
      const urlParameters = {
        showRemoved: false,
        includeHistory: true,
        loadFullHistory: true,
        shouldCompress: true,
        includeAllApprovedVersions: true,
      };

      UIUtils.secureAjaxGET(
        `editables/RMP/list`,
        urlParameters,
        true,
        FailHandlers.defaultStopLoadingFailFunction.bind(this),
      ).done((results) => {
        return resolve(results);
      });
    }).then(this.handleReceiveAllRmpsReceiveDataFromServer);
  }

  handleReceiveAllRmpsReceiveDataFromServer(results) {
    let instances = results.instances;

    if (this.state.riskManagementPlans) {
      this.state.riskManagementPlans = this.state.riskManagementPlans.map(riskPlan => {
        const rmp = instances.find(instance => riskPlan.key === instance.id);
        if (rmp) {
          riskPlan.value = `${rmp.name} (v${rmp.majorVersion}.${rmp.minorVersion})`;
        }

        return riskPlan;
      });
    }

    this.setStateSafely({
      rmps: instances,
      rmpChanged: this.isView() && UIUtils.getParameterByName("rmpChanged"),
    }, () => {
      this.computeEffectiveRmp();
    });
  }

  shouldShowNav() {
    return this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? !this.isAdd() : false;
  }

  getTeamMembers() {
    let teamMembers = [];
    if (this.state.projectSponsorId) {
      teamMembers.push(UIUtils.parseInt(this.state.projectSponsorId));
    }
    if (this.state.projectManagerId) {
      teamMembers.push(UIUtils.parseInt(this.state.projectManagerId));
    }
    if (this.state.cmcLeadId) {
      teamMembers.push(UIUtils.parseInt(this.state.cmcLeadId));
    }
    if (this.state.manufacturingLeadId) {
      teamMembers.push(UIUtils.parseInt(this.state.manufacturingLeadId));
    }
    if (this.state.deviceLeadId) {
      teamMembers.push(UIUtils.parseInt(this.state.deviceLeadId));
    }
    if (this.state.qcLeadId) {
      teamMembers.push(UIUtils.parseInt(this.state.qcLeadId));
    }
    if (this.state.qaLeadId) {
      teamMembers.push(UIUtils.parseInt(this.state.qaLeadId));
    }
    if (this.state.regulatoryLeadId) {
      teamMembers.push(UIUtils.parseInt(this.state.regulatoryLeadId));
    }

    return teamMembers;
  }

  rmpIsInUse() {
    return this.state.fqaVersionsCount > 0 ||
      this.state.fpaVersionsCount > 0 ||
      this.state.iqaToFqaVersionsCount > 0 ||
      this.state.iqaToFpaVersionsCount > 0 ||
      this.state.iqaToIqaVersionsCount > 0 ||
      this.state.iqaToIpaVersionsCount > 0 ||
      this.state.ipaToFqaVersionsCount > 0 ||
      this.state.ipaToFpaVersionsCount > 0 ||
      this.state.ipaToIqaVersionsCount > 0 ||
      this.state.ipaToIpaVersionsCount > 0 ||
      this.state.processParameterTofqaVersionsCount > 0 ||
      this.state.processParameterTofpaVersionsCount > 0 ||
      this.state.processParameterToIqaVersionsCount > 0 ||
      this.state.processParameterToIpaVersionsCount > 0 ||
      this.state.materialAttributeToFqaVersionsCount > 0 ||
      this.state.materialAttributeToFpaVersionsCount > 0 ||
      this.state.materialAttributeToIqaVersionsCount > 0 ||
      this.state.materialAttributeToIpaVersionsCount > 0 ||
      this.state.techTransferIsInUse;
  }

  canChangeRMP() {
    return this.isEdit() && this.rmpIsInUse();
  }

  canViewRMP() {
    return this.isView();
  }

  renderRMPChangeSuccessBar() {
    if (!this.state.rmpChanged) {
      return <Fragment />;
    }

    const selectedRmp = this.state.rmps?.find(rmp => rmp.id === UIUtils.parseInt(this.state.rmpChanged));

    if (!selectedRmp) {
      return <Fragment />;
    }

    const label = `${selectedRmp.name} (v${selectedRmp.majorVersion}.${selectedRmp.minorVersion})`;
    const url = getURLByTypeCodeAndId("RMP", "View", selectedRmp.id, true);

    return (
      <div id="rmpChangedSuccessMessage" className="row">
        <div className="col-sm-12 alert alert-success">
          <span>RMP was successfully changed to <a href={url} target="_blank" rel="noreferrer">{label}</a>.</span>
        </div>
      </div>
    );
  }

  getEffectiveRMPVersion(projectVersion) {
    let effectiveRMP = null;
    if (!this.state.rmps) {
      return effectiveRMP;
    }

    const allRmpVersions = this.state.rmps.reduce((arr, curr) => arr.concat(curr.approvedVersionsWithDetails), []);
    if (projectVersion.rmpVersionId) {
      effectiveRMP = allRmpVersions.find(version => version.id === projectVersion.rmpVersionId);
    } else {
      const projectVersionDate = moment(projectVersion.createdAt).utc();
      const rmpVersions = allRmpVersions.filter(rmpVersion => rmpVersion.RMPId === projectVersion.RMPId);
      const pastRMPs = rmpVersions.filter((version) => {
        let versionCreatedAtDate = moment(version.createdAt).utc();
        return versionCreatedAtDate.isSameOrBefore(moment(projectVersionDate).utc());
      });

      if (pastRMPs.length > 0) {
        effectiveRMP = pastRMPs.sort((a, b) => {
          return b.id - a.id;
        })[0];
      } else {
        effectiveRMP = rmpVersions.sort((a, b) => {
          return a.id - b.id;
        })[0];
      }
    }

    return effectiveRMP;
  }

  getRmpVersionLabel(rmpVersion) {
    if (!rmpVersion) {
      return "";
    }

    return `${rmpVersion.name} (v${rmpVersion.majorVersion}.${rmpVersion.minorVersion})`;
  }

  computeEffectiveRmp() {
    if (this.isDiffingVersions() && this.state.rmps) {
      const effectiveRmpVersion = this.getEffectiveRMPVersion(this.state);

      const olderVersion = this.getOlderVersion();
      let olderRmpVersion;
      if (olderVersion) {
        olderRmpVersion = this.getEffectiveRMPVersion(olderVersion);
      }

      this.setStateSafely({
        effectiveRmpVersionInfo: this.getRmpVersionLabel(effectiveRmpVersion),
        olderVersion: {
          ...this.state.olderVersion,
          effectiveRmpVersionInfo: this.getRmpVersionLabel(olderRmpVersion),
        }
      });
    }
  }

  handleReceiveRevisionDataFromServer(versionMap) {
    super.handleReceiveRevisionDataFromServer(versionMap, () => {
      this.computeEffectiveRmp();
    });
  }

  renderAttributes() {
    let teamMembersFilterFunction = this.isAdd() ? option => option.isAdmin : this.isEdit() && this.state.users ? option => {
      return this.state.users.filter(user => user.isGrantedAccess || user.isAdmin).map(user => user.id).includes(option.id);
    } : null;
    let users = this.state.users ? this.state.users : new TypeaheadObjectCache("User").getOptionsFromCacheIncludingArchived();
    const rmpInfo = this.getCurrentRmpInfo();
    const isLoadingRmpsForDiff = !this.state.rmps || this.state.isLoading;
    const isLoadingRmps = !this.state.riskManagementPlans || isLoadingRmpsForDiff;
    const rmpLoadingClass = this.isLoading() || isLoadingRmps || isLoadingRmpsForDiff || !this.getCurrentRmpInfo() ? "skeleton" : "";

    return (
      <div>
        {this.renderRMPChangeSuccessBar()}
        {this.renderWarningBar()}
        <Section parent={this}
                 header={this.getGeneralHeader()}
                 collapsible={false}
                 showDocLinks={true}
        >
          <div className="row">
            <TextAttribute name="name"
                           parentId={this.state.id}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                           parent={this}
            />
            <TextAttribute name="customProjectId"
                           displayName="Project ID"
                           tooltipText="This field can be used to enter the project code assigned to this drug development program which is often a three letter code for your company and a three digit number (eg. XXX-001)"
                           parentId={this.state.id}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                           parent={this}
            />
          </div>
          <div className="row">
            <ComboBoxAttribute name="type"
                               options={[PROJECT_TYPES.DRUG_SUBSTANCE,
                                 PROJECT_TYPES.DRUG_PRODUCT,
                                 PROJECT_TYPES.DP_AND_DS,
                                 PROJECT_TYPES.RAW_MATERIAL]}
                               default={PROJECT_TYPES.DRUG_PRODUCT}
                               disabled={this.state.materialVersionsCount > 0 || this.state.fqaVersionsCount > 0}
                               instructions={"This field will be disabled once a Final Quality Attribute (FQA) is added to the project."}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentId={this.state.id}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               showMajorVersionsOnly={this.state.showMajorVersionsOnly}
            />
            <ComboBoxAttribute name="category"
                               options={["Small Molecule",
                                 "Peptide",
                                 "Biologic-mAb",
                                 "Biologic-Gene",
                                 "Biologic-Cell",
                                 "Biologic-Tissue",
                                 "Biologic-Other"]}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                               parentId={this.state.id}
            />
          </div>
          <div className="row">
            {(((this.canChangeRMP() || this.canViewRMP()) && !this.isDiffingVersions()) ? <div className="col-sm-6">
                  <label id="rMPComboBoxLabel" className="col-form-label base-attribute col-form-label-add-or-edit">
                    <span>Risk Management Plan</span>
                    <span className="base-attribute-value-specified">*</span>
                  </label>
                  <div className={"view-attribute " + rmpLoadingClass}>
                    <span><a id="rMPComboBox" href={rmpInfo?.url} rel="noreferrer">{rmpInfo?.label}</a></span>
                    {(this.canChangeRMP() ?
                      <span> - <a id="changeRmpLink" href={`/projects/changeRMP.html?projectId=${this.getProjectId()}`}>Change RMP</a></span> : "")}
                  </div>
                </div> :
                (
                  this.isDiffingVersions() ?
                    <ComboBoxAttribute name="rMP"
                                       attributeName="effectiveRmpVersionInfo"
                                       displayName="Risk Management Plan"
                                       default={this.state.effectiveRmpVersionInfo}
                                       options={this.state.effectiveRmpVersionInfo ? [this.state.effectiveRmpVersionInfo] : []}
                                       isLoading={isLoadingRmpsForDiff}
                                       className="col-sm-6"
                                       olderVersion={this.getOlderVersion()}
                                       parent={this}
                                       parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                       showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                                       parentId={this.state.id}
                    />
                    :
                    <ComboBoxAttribute name="rMP"
                                       attributeName="RMPId"
                                       displayName="Risk Management Plan"
                                       default={this.state.defaultRMP}
                                       options={this.state.riskManagementPlans || []}
                                       isLoading={isLoadingRmps}
                                       className="col-sm-6"
                                       disabled={this.rmpIsInUse()}
                                       instructions={
                                         <div>
                                           <p>
                                             This field will be disabled once a risk assessment has been done on a Final Attribute (FQA/FPA), Intermediate Attribute (IQA/IPA), Material Attribute (MA) or Process Parameter (PP).
                                           </p>
                                           <p>
                                             The list of available RMPs is limited to those that are both approved and without a pending draft.
                                           </p>
                                         </div>
                                       }
                                       parent={this}
                                       parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                       showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                                       parentId={this.state.id}
                    />)
            )}

            <RadioAttribute name="productRiskAssessmentType"
                            attributeName="productRiskAssessmentType"
                            displayName="Product Risk Assessment Type"
                            default={"Risk Ranking"}
                            disabled={this.state.fqaVersionsCount > 0 || this.state.fpaVersionsCount > 0}
                            options={CommonEditables.PRODUCT_RISK_ASSESSMENT_TYPES}
                            instructions={
                              <div>
                                <p>
                                  This field will be disabled once Final Quality Attribute (FQA) added to the project
                                </p>
                              </div>
                            }
                            tooltipText={(<div>
                              <b>Preliminary Hazards Analysis (PHA)</b><br />
                              Preliminary Hazards Analysis ranks the criticality of quality attributes using a PHA risk assessment approach
                              based on severity and likelihood (i.e., occurrence or probability of impacting safety and efficacy).<br />
                              <br />
                              <b>Risk Ranking</b><br />
                              Risk ranking of complex systems usually involves the evaluation of multiple factors for each risk.
                              The approach taken with Risk Ranking involves breaking down the risk into the multiple components required to capture the
                              appropriate risk factors (i.e., the potential impact to safety and efficacy and the uncertainty around the information
                              used to assess the potential impact) and developing a scoring matrix for each factor. The individual scores for each factor
                              are then multiplied together to give a single risk score.<br />
                              <br />
                            </div>)}
                            className="col-sm-6"
                            parent={this}
            />
          </div>
        </Section>
        <Section id="purpose"
                 header="Purpose"
                 parent={this}
                 showDocLinks={true}
        >
          <div className="row">
            <TextAreaAttribute name="purposeAndScope"
                               displayName="Purpose and Scope"
                               tooltipText="Describe the overall function and/or breadth of the project"
                               className="col-sm-12"
                               parent={this}
                               parentId={this.state.id}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               showMajorVersionsOnly={this.state.showMajorVersionsOnly}
            />
            <TextAreaAttribute name="objectives"
                               tooltipText="Describe more specific goals and deliverables of the project"
                               className="col-sm-12"
                               parent={this}
                               parentId={this.state.id}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               showMajorVersionsOnly={this.state.showMajorVersionsOnly}
            />
          </div>
        </Section>
        <Section id="development"
                 header="Development"
                 parent={this}
        >
          <div className="row">
            <ComboBoxAttribute name="qualityByDesignPhase"
                               displayName="Quality-By-Design Phase"
                               className="col-sm-6"
                               tooltipText={<div>
                                 <b>Product Understanding</b> - Initiated development of Target Product Profile (TPP)
                                 and Quality Target Product Profile (QTPP) along with identification of critical
                                 quality attributes (CQAs).<br />
                                 <br />
                                 <b>Process Understanding</b> - Describe Manufacturing Process; Identify CMAs and CPPs;
                                 Identify Intermediate CQAs (ICQAs); Establish functional relationships that link
                                 CMA/CPP to ICQAs; Establish functional relationships that link ICQAs to CQAs<br />
                                 <br />
                                 <b>Process Development</b> - Design and complete studies intended to evaluate material
                                 attributes and process parameters to identify key interactions and establish operating
                                 ranges.<br />
                                 <br />
                                 <b>Process Control</b> - Develop appropriate Control Strategy based on risk assessment
                                 and risk mitigation studies in the Process Development phase to demonstrate robust,
                                 well-controlled manufacturing process.<br />

                               </div>}
                               options={[
                                 "Product Understanding",
                                 "Process Understanding",
                                 "Process Development",
                                 "Process Control"]}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                               parentId={this.state.id}
            />
            <ComboBoxAttribute name="cmcPhase"
                               displayName="CMC Phase"
                               className="col-sm-6"
                               tooltipText="Describes the current scale of manufacturing activities"
                               options={[
                                 "Design",
                                 "Optimization",
                                 "Pilot",
                                 "Registration",
                                 "Validation"]}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                               parentId={this.state.id}
            />
            <ComboBoxAttribute name="validationPhase"
                               className="col-sm-6"
                               default="Not Applicable"
                               options={[
                                 "Process Design",
                                 "Process Qualification",
                                 "Continued Process Verification",
                                 "Not Applicable"]}
                               instructions={`To edit this field, the "CMC Phase" field above must be set to "Validation"`}
                               disabled={this.state.cmcPhase !== "Validation"}
                               tooltipText={<div>
                                 <b>Stage 1 – Process Design:</b> The commercial manufacturing process is defined during
                                 this stage based on knowledge gained through development and scale-up
                                 activities. <br />
                                 <br />
                                 <b>Stage 2 – Process Qualification:</b> During this stage, the process design is
                                 evaluated to determine if the process is capable of reproducible commercial
                                 manufacturing. <br />
                                 <br />
                                 <b>Stage 3 – Continued Process Verification:</b> Ongoing assurance is gained during
                                 routine production that the process remains in a state of control.
                               </div>}
                               tooltipGuidanceURL="https://www.fda.gov/media/71021/download"
                               tooltipGuidancePage={7}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                               parentId={this.state.id}
            />
          </div>
        </Section>
        <Section id="regulatory"
                 header="Regulatory"
                 parent={this}
        >
          <div className="row">
            <TextAttribute name="dosageForm"
                           className="col-sm-6"
                           tooltipText="A dosage form is the physical form in which a drug is produced and dispensed, such as a tablet, a capsule, or an injectable."
                           tooltipGuidanceURL="https://www.fda.gov/industry/structured-product-labeling-resources/dosage-forms"
                           parent={this}
                           parentId={this.state.id}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           showMajorVersionsOnly={this.state.showMajorVersionsOnly}
            />
            <TextAttribute name="routeOfAdministration"
                           displayName="Route of Administration"
                           className="col-sm-6"
                           tooltipText="Routes of administration are generally classified by the location at which the substance is applied. Common examples include oral and intravenous administration. Routes can also be classified based on where the target of action is. Action may be topical (local), enteral (system-wide effect, but delivered through the gastrointestinal tract), or parenteral (systemic action, but delivered by routes other than the GI tract)."
                           tooltipGuidanceURL="https://www.fda.gov/drugs/data-standards-manual-monographs/route-administration"
                           parent={this}
                           parentId={this.state.id}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           showMajorVersionsOnly={this.state.showMajorVersionsOnly}
            />
            <ComboBoxAttribute name="regulatoryPath"
                               className="col-sm-6"
                               default="Unknown"
                               options={["505(b)(1) NDA",
                                 "505(b)(2) NDA",
                                 "505(j) ANDA",
                                 "351(a) BLA",
                                 "351(k) BLA",
                                 "Unknown"]}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                               parentId={this.state.id}
            />
            <ComboBoxAttribute name="regulatoryPhase"
                               className="col-sm-6"
                               default="Pre-IND"
                               options={[
                                 "Pre-IND",
                                 "Phase 1",
                                 "Phase 2",
                                 "Phase 2a",
                                 "Phase 2b",
                                 "Phase 3",
                                 "Pre-Submission",
                                 "Post-Submission",
                                 "Post Approval",
                                 "Not Applicable"]}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                               parentId={this.state.id}
            />
            <TypeaheadAttribute name="referenceListedDrug"
                                className="col-sm-6"
                                options={this.orangeBook}
                                tooltipText="A Reference Listed Drug (RLD) is an approved drug product to which new generic versions are compared to show that they are bioequivalent. A drug company seeking approval to market a generic equivalent must refer to the Reference Listed Drug in its Abbreviated New Drug Application (ANDA) or 505(b)(2) applications. For more information, please see this FDA guidance."
                                tooltipGuidanceURL={ReferenceDocuments.ANDA_Submissions_Guidance}
                                instructions={`To edit this field, the "Regulatory Path" field above must be set to anything but "505(b)(1) NDA" or "351(a) BLA"`}
                                disabled={
                                  !this.state.regulatoryPath
                                  || this.state.regulatoryPath === "505(b)(1) NDA"
                                  || this.state.regulatoryPath === "351(a) BLA"
                                }
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                                parentId={this.state.id}
            />
          </div>
        </Section>
        <Section id="team"
                 header={!this.isView() ? (
                   <span>Team
                     <InfoTooltip id="infoTeamAccess"
                                  verbiage={
                                    <ProjectAdminsTooltip users={users}
                                                          useSuspense={false}
                                                          projectId={this.getProjectId()}
                                                          projectName={this.getProjectName()}
                                                          message="Team members need access to this project first.
                                                           If you don’t see the appropriate person, please contact your
                                                           Administrator to request that your team member be given access
                                                           to this project."
                                    />}
                     />
                   </span>
                 ) : "Team"}
                 parent={this}
        >
          <div className="row">
            <TypeaheadAttribute name="projectSponsor"
                                typeaheadType="User"
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                                parentId={this.state.id}
                                filter={teamMembersFilterFunction}
            />
            <TypeaheadAttribute name="projectManager"
                                typeaheadType="User"
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                                parentId={this.state.id}
                                filter={teamMembersFilterFunction}
            />
          </div>
          <div className="row">
            <TypeaheadAttribute name="cmcLead"
                                displayName="CMC Lead"
                                typeaheadType="User"
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                                parentId={this.state.id}
                                filter={teamMembersFilterFunction}
            />
            <TypeaheadAttribute name="manufacturingLead"
                                typeaheadType="User"
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                                parentId={this.state.id}
                                filter={teamMembersFilterFunction}
            />
          </div>
          <div className="row">
            <TypeaheadAttribute name="deviceLead"
                                displayName="Device/Engineering Lead"
                                typeaheadType="User"
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                                parentId={this.state.id}
                                filter={teamMembersFilterFunction}
            />
            <TypeaheadAttribute name="qcLead"
                                displayName="QC Lead"
                                typeaheadType="User"
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                                parentId={this.state.id}
                                filter={teamMembersFilterFunction}
            />
          </div>
          <div className="row">
            <TypeaheadAttribute name="qaLead"
                                displayName="QA Lead"
                                typeaheadType="User"
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                                parentId={this.state.id}
                                filter={teamMembersFilterFunction}
            />
            <TypeaheadAttribute name="regulatoryLead"
                                typeaheadType="User"
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                showMajorVersionsOnly={this.state.showMajorVersionsOnly}
                                parentId={this.state.id}
                                filter={teamMembersFilterFunction}
            />
          </div>
          {
            !this.isDiffingVersions() ? (
              <div className="row">
                <div className="attribute-container col-sm-12">
                  <LabelTooltip id={"accessToProjectLabel"}
                                tooltipText="The listed users below have access to the project besides the designated project team members."
                                text="Additional users with access"
                                className={"col-form-label base-attribute" + (this.isView() ? "" : " col-form-label-add-or-edit")}
                                parent={this}
                  />
                  <div id="projectAdditionalUsersWithAccess">
                    <ul>
                      {
                        this.state.users ? this.state.users
                          .filter(user => !this.getTeamMembers().includes(user.id) && (user.isGrantedAccess || user.isAdmin))
                          .sort((u1, u2) => `${u1.firstName} ${u1.lastName}` < `${u2.firstName} ${u2.lastName}` ? -1 : 1)
                          .map(user => {
                            return (
                              <li key={"user_" + user.id}>
                                <a href={getURLByKey("USR-" + user.id, "View")} rel="noopener noreferrer" target="_blank">
                                  {user.fullName ? user.fullName : user.firstName + " " + user.lastName}
                                </a>
                                {user.isAdmin ?
                                  <sup className="admin-privileges-superscript"> Access from Administrator privileges.</sup> : ""}
                              </li>
                            );
                          }) : ""
                      }
                    </ul>
                  </div>
                </div>
              </div>
            ) : ""
          }
        </Section>
      </div>
    );
  }

  getCurrentRmpInfo() {
    if (!this.state.RMPId || !this.state.rmps) {
      return;
    }

    const rmp = this.state.rmps.find(rmp => rmp.id === this.state.RMPId);
    if (!rmp) {
      return;
    }

    const version = rmp.approvedVersionsWithDetails.find(rmpVersion => rmpVersion.id === (this.state.rmpVersionId ?? rmp.LastApprovedVersionId));
    if (!version) {
      return;
    }

    const label = `${version.name} (v${version.majorVersion}.${version.minorVersion})`;
    const isLatestApprovedVersion = rmp.id === version.id;
    const url = isLatestApprovedVersion ? getURLByTypeCodeAndId("RMP", "View", rmp.id, true) : getURLByTypeCodeAndIdAndVersionId("RMP", "View", rmp.id, true, version.id);

    return {label, url};
  }

  renderWarningBar() {
    const hasEditPermission = can(CommonSecurity.Actions.EDIT, CommonSecurity.Types.PROJECT, this.state);
    if (!hasEditPermission || (this.isEdit() && !this.rmpIsInUse())) {
      return <Fragment />;
    }

    const rmp = this.state.rmps?.find(rmp => rmp.id === this.state.RMPId);
    if (!rmp) {
      return <Fragment />;
    }

    const newRmpVersionAvailable = rmp?.LastApprovedVersionId && this.state.rmpVersionId && rmp?.LastApprovedVersionId > this.state.rmpVersionId;
    if (!newRmpVersionAvailable) {
      return <Fragment />;
    }

    const rmpUrl = getURLByTypeCodeAndId("RMP", "View", rmp.id, true);
    const changeRmpUrl = `/projects/changeRMP.html?projectId=${this.state.id}`;

    return (<NewRmpWarning rmpUrl={rmpUrl} changeRmpUrl={changeRmpUrl} />);
  }
}

export default Project;
