"use strict";

import { isSendingSiteField } from "../tech_transfer_column_value_generator";
import {
  RECEIVING_SITE_LABEL,
  SENDING_SITE_LABEL
} from "../../configurableTables/fieldsConfig/constants/configurable_tables_columns_config";
import ConfigurableTablesColumnOperationsAdapter
  from "../../configurableTables/columnOperations/configurable_tables_column_operations_adapter";
import { DEFAULT_TECH_TRANSFER_SORTING } from "../tech_transfer_constants";

export default class TechTransferColumnOperationsAdapter extends ConfigurableTablesColumnOperationsAdapter {
  cleanProp(prop) {
    return prop
      .replace("techTransferredFrom.", "")
      .replace("_sending", "")
      .replace("_receiving", "");
  }

  getFilterOptions(records, prop) {
    const isSendingField = isSendingSiteField(prop);
    const options = records
      .map(record => {
        const formattedProp = this.cleanProp(prop);
        const value = isSendingField ? record.techTransferredFrom[formattedProp] : record[formattedProp];
        if (value) {
          return isSendingField ? {techTransferredFrom: {[formattedProp]: value}} : {[formattedProp]: value};
        }
      });
    return options.filter(option => isSendingField ? option?.techTransferredFrom : option);
  }

  getFieldInformation(visibleTableColumns, prop) {
    const isSendingSite = isSendingSiteField(prop);
    prop = isSendingSite ? prop.replace("techTransferredFrom.", "") : prop;
    return visibleTableColumns
      .find(column => column.prop === prop && (isSendingSite ?
        column.originatedFromSite === SENDING_SITE_LABEL :
        column.originatedFromSite === RECEIVING_SITE_LABEL));
  }

  getDefaultSorting(tableState) {
    const {sort} = tableState || {};
    return sort && sort.length > 0 ? sort : DEFAULT_TECH_TRANSFER_SORTING;
  }

  getRiskFilterOptions(records, prop, isReceivingSite = false) {
    let formattedProp = this.adaptRiskFilterName(prop);

    records.sort((a, b) => {
      a = isReceivingSite ? a : a.techTransferredFrom;
      b = isReceivingSite ? b : b.techTransferredFrom;
      return a[formattedProp] - b[formattedProp];
    });

    // Filtering empty, null and undefined values
    const options = records
      .map(record => {
        const value = isReceivingSite ?
          (record[`${formattedProp}RiskLabel`] ||
          record[`${formattedProp}Label`]) :
          (record.techTransferredFrom[`${formattedProp}RiskLabel`] ||
          record.techTransferredFrom[`${formattedProp}Label`]);
        if (value) {
          return isReceivingSite ? {[prop]: value} : {techTransferredFrom: {[prop]: value}};
        }
      });

    return options.filter(option => isReceivingSite ? option : option?.techTransferredFrom);
  }

  getFieldFilterOptions(field, prop, records) {
    return field.filterOptions(records, this);
  }

  fixFieldName(field) {
    const name = super.fixFieldName(field);
    return isSendingSiteField(field) ? `techTransferredFrom.${name}` : name;
  }
}
