import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from "prosemirror-view";
export function placeholder(message) {
    var decAttrs = { class: 'k-placeholder', 'data-placeholder': message };
    return new Plugin({
        key: new PluginKey('placeholder'),
        props: {
            decorations: function (state) {
                var doc = state.doc;
                var firstChild = doc.content.firstChild;
                var empty = doc.childCount === 0 ||
                    (doc.childCount === 1 && firstChild.inlineContent && firstChild.childCount === 0);
                if (!empty) {
                    return DecorationSet.empty;
                }
                var decorations = [];
                doc.descendants(function (node, pos) {
                    decorations.push(Decoration.node(pos, pos + node.nodeSize, decAttrs));
                });
                return DecorationSet.create(doc, decorations);
            }
        }
    });
}
