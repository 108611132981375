var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, Keys, useDir, useDraggable, getTabIndex, createPropsContext, usePropsContext } from '@progress/kendo-react-common';
import { messages, sliderDragTitle } from './../messages';
import { useLocalization } from '@progress/kendo-react-intl';
import { rangeReducer, RANGE_ACTION } from './range-raducer';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
/**
 * @hidden
 */
var useRange = function (defaultValue, args, callback) {
    var _a = React.useState(defaultValue), state = _a[0], setState = _a[1];
    var handleDispatchAction = React.useCallback(function (action) {
        var newState = rangeReducer(args.state || state, __assign(__assign({}, action), args));
        if (callback) {
            callback(newState, action.event);
        }
        setState(newState);
    }, [args, callback]);
    return [state, handleDispatchAction];
};
/**
 * Represents the PropsContext of the `RangeSlider` component.
 * Used for global configuration of all `RangeSlider` instances.
 *
 * For more information, refer to the [Inputs Props Context]({% slug props-context_inputs %}) article.
 */
export var RangeSliderPropsContext = createPropsContext();
/**
 * Represents the [KendoReact RangeSlider component]({% slug overview_rangeslider %}).
 *
 * Accepts properties of type [RangeSliderProps]({% slug api_inputs_rangesliderprops %}).
 * Obtaining the `ref` returns an object of type [RangeSliderHandle]({% slug api_inputs_rangesliderhandle %}).
 */
export var RangeSlider = React.forwardRef(function (directProps, ref) {
    var _a, _b;
    validatePackage(packageMetadata);
    var props = usePropsContext(RangeSliderPropsContext, directProps);
    var target = React.useRef(null);
    var sliderRef = React.useRef(null);
    var sliderSelectionRef = React.useRef(null);
    var startHandle = React.useRef(null);
    var endHandle = React.useRef(null);
    var focus = React.useCallback(function () {
        if (startHandle.current) {
            startHandle.current.focus();
        }
    }, [startHandle]);
    React.useImperativeHandle(target, function () { return ({
        element: sliderRef.current,
        focus: focus,
        props: props
    }); });
    React.useImperativeHandle(ref, function () { return target.current; });
    var min = React.useMemo(function () { return props.min; }, [props.min]);
    var max = React.useMemo(function () { return props.max; }, [props.max]);
    var step = React.useMemo(function () { return props.step !== undefined ? props.step : defaultProps.step; }, [props.step, defaultProps.step]);
    var dir = useDir(sliderRef, props.dir);
    var handleChange = function (newValue, event) {
        if (props.onChange && target.current) {
            props.onChange.call(undefined, {
                value: newValue,
                target: target.current,
                syntheticEvent: event
            });
        }
    };
    var _c = React.useState(''), currentDrag = _c[0], setCurrentDrag = _c[1];
    var _d = useRange(props.defaultValue || defaultProps.defaultValue, {
        min: min,
        max: max,
        step: step,
        state: props.value
    }, handleChange), stateValue = _d[0], dispatchStateValue = _d[1];
    var value = React.useMemo(function () { return props.value || stateValue; }, [props.value, stateValue]);
    var sliderTrackRef = React.useRef(null);
    var sliderTrackWrapRef = React.useRef(null);
    var percentStart = React.useMemo(function () { return ((value.start - min) / (max - min)) * 100; }, [value.start, min, max]);
    var percentEnd = React.useMemo(function () { return ((value.end - min) / (max - min)) * 100; }, [value.end, min, max]);
    var sliderItemsStyle = React.useMemo(function () { return props.vertical ? { paddingTop: 0, height: '100%' } : {}; }, [props.vertical]);
    var trackStyles = React.useMemo(function () { return props.vertical
        ? { marginTop: '0.5rem', marginBottom: '0.5rem' }
        : { marginLeft: '0.5rem', marginRight: '0.5rem' }; }, [props.vertical]);
    var localization = useLocalization();
    var calcNewDistance = React.useCallback(function (event) {
        if (!sliderTrackWrapRef.current) {
            return;
        }
        var computed = sliderTrackWrapRef.current.getBoundingClientRect();
        var distance = props.vertical
            ? (computed.bottom - event.clientY)
            : dir === 'rtl'
                ? (computed.right - event.clientX)
                : (event.clientX - computed.left);
        var size = props.vertical ? computed.height : computed.width;
        var percentage = (distance / size);
        var payload = (min + percentage * (max - min));
        return payload;
    }, [
        sliderTrackWrapRef,
        props.vertical,
        dir,
        min,
        max,
        stateValue.start,
        stateValue.end,
        props.value && props.value.start,
        props.value && props.value.end
    ]);
    var calcKey = React.useCallback(function (payload) {
        if (payload <= value.start) {
            return 'start';
        }
        if (payload >= value.end) {
            return 'end';
        }
        return (2 * payload < value.end + value.start) ? 'start' : 'end';
    }, [
        stateValue.start, stateValue.end,
        props.value && props.value.start,
        props.value && props.value.end
    ]);
    var handleStartKeyDown = React.useCallback(function (event) {
        switch (event.keyCode) {
            case Keys.right:
                event.preventDefault();
                dispatchStateValue({ type: dir === 'rtl' ? RANGE_ACTION.decrease : RANGE_ACTION.increase, key: 'start', event: event });
                break;
            case Keys.up:
                event.preventDefault();
                dispatchStateValue({ type: RANGE_ACTION.increase, key: 'start', event: event });
                break;
            case Keys.left:
                event.preventDefault();
                dispatchStateValue({ type: dir === 'rtl' ? RANGE_ACTION.increase : RANGE_ACTION.decrease, key: 'start', event: event });
                break;
            case Keys.down:
                event.preventDefault();
                dispatchStateValue({ type: RANGE_ACTION.decrease, key: 'start', event: event });
                break;
            case Keys.home:
                event.preventDefault();
                dispatchStateValue({ type: RANGE_ACTION.min, key: 'start', event: event });
                break;
            case Keys.end:
                event.preventDefault();
                dispatchStateValue({ type: RANGE_ACTION.max, key: 'start', event: event });
                break;
            default:
                break;
        }
    }, [dispatchStateValue]);
    var handleEndKeyDown = React.useCallback(function (event) {
        switch (event.keyCode) {
            case Keys.right:
                event.preventDefault();
                dispatchStateValue({ type: dir === 'rtl' ? RANGE_ACTION.decrease : RANGE_ACTION.increase, key: 'end', event: event });
                break;
            case Keys.up:
                event.preventDefault();
                dispatchStateValue({ type: RANGE_ACTION.increase, key: 'end', event: event });
                break;
            case Keys.left:
                event.preventDefault();
                dispatchStateValue({ type: dir === 'rtl' ? RANGE_ACTION.increase : RANGE_ACTION.decrease, key: 'end', event: event });
                break;
            case Keys.down:
                event.preventDefault();
                dispatchStateValue({ type: RANGE_ACTION.decrease, key: 'end', event: event });
                break;
            case Keys.home:
                event.preventDefault();
                dispatchStateValue({ type: RANGE_ACTION.min, key: 'end', event: event });
                break;
            case Keys.end:
                event.preventDefault();
                dispatchStateValue({ type: RANGE_ACTION.max, key: 'end', event: event });
                break;
            default:
                break;
        }
    }, [dispatchStateValue, dir]);
    var handleTrackPress = React.useCallback(function (event) {
        var payload = calcNewDistance(event);
        var key = calcKey(payload);
        setCurrentDrag(key);
        key === 'end' ? endHandle.current.focus() : startHandle.current.focus();
        var action = (key === 'end' ? RANGE_ACTION.end : RANGE_ACTION.start);
        dispatchStateValue({ type: action, payload: payload, event: event });
    }, [props.vertical, min, max, dispatchStateValue]);
    var handleTrackDrag = function (event) {
        var payload = calcNewDistance(event);
        var action = currentDrag === 'end' ? RANGE_ACTION.end : RANGE_ACTION.start;
        dispatchStateValue({ type: action, payload: payload, event: event });
    };
    var handleTrackRelease = function (event) {
        var payload = calcNewDistance(event);
        var action = currentDrag === 'end' ? RANGE_ACTION.end : RANGE_ACTION.start;
        dispatchStateValue({ type: action, payload: payload, event: event });
        setCurrentDrag('');
    };
    useDraggable(sliderTrackWrapRef, { onPress: handleTrackPress, onDrag: handleTrackDrag, onRelease: handleTrackRelease });
    return (React.createElement("div", { id: props.id, style: props.style, ref: sliderRef, dir: dir, className: classNames('k-widget k-slider', {
            'k-rtl': dir === 'rtl',
            'k-disabled': props.disabled,
            'k-slider-vertical': props.vertical,
            'k-slider-horizontal': !props.vertical
        }, props.className) },
        React.createElement("div", { ref: sliderTrackWrapRef, className: "k-slider-track-wrap", style: __assign({ flexGrow: 1, position: 'relative', touchAction: 'none' }, trackStyles) },
            props.children && (React.createElement("ul", { className: "k-reset k-slider-items", style: __assign({}, sliderItemsStyle) }, React.Children.map(props.children, function (child) {
                return child && React.cloneElement(child, {
                    position: 100 * (child.props.position - props.min) /
                        (props.max - props.min),
                    vertical: props.vertical
                }, child.props.children);
            }))),
            React.createElement("div", { ref: sliderTrackRef, className: "k-slider-track", style: props.vertical
                    ? { bottom: 0, height: '100%' }
                    : (_a = {}, _a[dir === 'rtl' ? 'right' : 'left'] = 0, _a.width = '100%', _a) },
                (percentStart !== null && percentEnd !== null) && React.createElement("div", { "data-selection": true, ref: sliderSelectionRef, title: "".concat(value.start, " - ").concat(value.end), className: "k-slider-selection", style: props.vertical
                        ? { height: (percentEnd - percentStart) + '%', bottom: percentStart + '%' }
                        : (_b = {},
                            _b[dir === 'rtl' ? 'right' : 'left'] = percentStart + '%',
                            _b.width = (percentEnd - percentStart) + '%',
                            _b) }),
                React.createElement("span", { ref: startHandle, role: "slider", tabIndex: getTabIndex(props.startTabIndex, props.disabled, undefined), "aria-valuemin": min, "aria-valuemax": Math.max(max, value.end), "aria-valuenow": value.start, "aria-disabled": props.disabled ? 'true' : undefined, "aria-valuetext": "".concat(value.start, " - ").concat(value.end), className: "k-draghandle", title: localization.toLanguageString(sliderDragTitle, messages[sliderDragTitle]), style: props.vertical
                        ? { bottom: 'calc(' + percentStart + '%)', zIndex: 1 }
                        : dir === 'rtl' ? { right: 'calc(' + percentStart + '% - 13px)', zIndex: 1 }
                            : { left: 'calc(' + percentStart + '%)', zIndex: 1 }, onKeyDown: handleStartKeyDown }),
                React.createElement("span", { ref: endHandle, role: "slider", tabIndex: getTabIndex(props.endTabIndex, props.disabled, undefined), "aria-valuemin": Math.min(min, value.start), "aria-valuemax": max, "aria-valuenow": value.end, "aria-disabled": props.disabled ? 'true' : undefined, "aria-valuetext": "".concat(value.start, " - ").concat(value.end), className: "k-draghandle", title: localization.toLanguageString(sliderDragTitle, messages[sliderDragTitle]), style: props.vertical
                        ? { bottom: 'calc(' + percentEnd + '%)', zIndex: 1 }
                        : dir === 'rtl' ? { right: 'calc(' + percentEnd + '% - 13px)', zIndex: 1 }
                            : { left: 'calc(' + percentEnd + '%)', zIndex: 1 }, onKeyDown: handleEndKeyDown })))));
});
var propTypes = {
    value: function (props, propName, componentName) {
        if (props.value) {
            var start = props.value.start;
            var end = props.value.end;
            var min = props.min;
            var max = props.max;
            if (start > end || start > max || start < min || end > max || end < min || end < start) {
                return new Error("Invalid prop + ".concat(propName, " supplied to ").concat(componentName, ".\n                    The { start, end } value must be between the min & max value and { start, end } must be start < end.\n                    "));
            }
        }
        return null;
    },
    defaultValue: function (props, propName, componentName) {
        if (props.defaultValue) {
            var start = props.defaultValue.start;
            var end = props.defaultValue.end;
            var min = props.min;
            var max = props.max;
            if (start > end || start > max || start < min || end > max || end < min || end < start) {
                return new Error("Invalid prop + ".concat(propName, " supplied to ").concat(componentName, ".\n                    The { start, end } value must be between the min & max value and { start, end } must be start < end.\n                    "));
            }
        }
        return null;
    },
    onChange: PropTypes.func,
    step: PropTypes.number,
    min: function (props, propName, componentName) {
        var prop = props[propName];
        var min = props.min;
        var max = props.max;
        if (min === undefined) {
            return new Error("Invalid prop + ".concat(propName, " supplied to ").concat(componentName, ".\n                ").concat(propName, " value can not be undefined.\n                "));
        }
        if (prop && min >= max) {
            return new Error("Invalid prop + ".concat(propName, " supplied to ").concat(componentName, ".\n                ").concat(propName, " value can not be equal to or bigger than the max value.\n                "));
        }
        return null;
    },
    max: function (props, propName, componentName) {
        var prop = props[propName];
        var min = props.min;
        var max = props.max;
        if (max === undefined) {
            return new Error("Invalid prop + ".concat(propName, " supplied to ").concat(componentName, ".\n                ").concat(propName, " value can not be undefined.\n                "));
        }
        if (prop && max <= min) {
            return new Error("Invalid prop + ".concat(propName, " supplied to ").concat(componentName, ".\n                ").concat(propName, " value can not be equal to or smaller than the min value.\n                "));
        }
        return null;
    },
    vertical: PropTypes.bool,
    disabled: PropTypes.bool,
    dir: PropTypes.oneOf(['ltr', 'rtl'])
};
var defaultProps = {
    step: 1,
    defaultValue: {
        start: 0,
        end: 0
    },
    vertical: false,
    disabled: false
};
RangeSlider.displayName = 'KendoReactRangeSlider';
// TODO: delete casting when @types/react is updated!
RangeSlider.propTypes = propTypes;
RangeSlider.defaultProps = defaultProps;
