"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import * as ImportHelper from "../../helpers/import_helper";
import FieldTooltip from "../../../widgets/tooltips/field_tooltip";
import BaseReactComponent from "../../../base_react_component";

export default class ImportRecordInstructions extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let modelName = UIUtils.stripAllWhitespaces(this.props.modelName);
    const {hasProjectTypeDependedTemplates, className, showInstructions, projectType, dependency} = this.props;
    const disableLinks = hasProjectTypeDependedTemplates && !projectType;
    const idPrefix = `download${modelName}${dependency ? UIUtils.stripAllWhitespaces(dependency) : ""}`;

    return (
      <div className={"col-12 " + (className ? className : "")}>
        <div className="row align-content-between">
          {showInstructions ?
            <div className="col-12">
              <div className="row import-record-widget-instructions">
                <div className="col-12">
                  Instructions:
                </div>
                <div className="col-12 import-record-widget-instructions-description">
                  {this.props.instructions}
                </div>
              </div>
            </div>
            : ""}
          {this.props.showDownloads ?
            <div className="col-12">
              <div className="row import-record-widget-download-links">
                <div className="col-12 import-record-widget-download-link">
                  &gt;
                  {disableLinks ? (
                    <div className="import-record-widget-disabled-link">
                      <span>Download a sample data file</span>
                      <FieldTooltip id={`${idPrefix}SampleFile`}
                                    text="Please select a project first."
                      />
                    </div>
                  ) : (
                    <a id={`${idPrefix}SampleFile`}
                       rel="noopener noreferrer"
                       href={ImportHelper.getSampleFileLocation(this.props)}
                    >Download a sample data file</a>
                  )}
                </div>
                <div className="col-12 import-record-widget-download-link">
                  &gt;
                  {disableLinks ? (
                    <div className="import-record-widget-disabled-link">
                      <span>Download a template for the import</span>
                      <FieldTooltip id={`${idPrefix}TemplateFile`}
                                    text="Please select a project first."
                      />
                    </div>
                  ) : (
                    <a id={`${idPrefix}TemplateFile`}
                       rel="noopener noreferrer"
                       href={ImportHelper.getTemplateFileLocation(this.props)}
                    >Download a template for the import</a>
                  )}
                </div>
              </div>
            </div> : ""}
          {this.props.showNewProjectLink ?
            <div className="col-12">
              <div className="row import-record-widget-links">
                <div className="col-12">
                  &gt; <a target="_blank"
                          rel="noopener noreferrer"
                          href={"/projects/viewEdit.html?operation=Add"}
                >Add new project</a>
                </div>
              </div>
            </div> : ""}
        </div>
      </div>
    );
  }

}
