"use strict";

import * as UIUtils from "../../ui_utils";
import { USER_TABS, USER_TABS_ENUM } from "../../helpers/constants/constants";
import React, { Fragment } from "react";
import CommonEditablesPageTitleBar from "../../widgets/pageTitleBar/common_editables_page_title_bar";
import TopNavBar from "../../widgets/bars/top_nav_bar";
import CommonUsers from "../../../server/common/editables/common_users";
import CommonSecurity from "../../../server/common/generic/common_security";

// noinspection JSFileReferences
import permissions from "../../../server/users/security/permissions.json";

import ComboBoxAttribute from "../../editor/attributes/combo_box_attribute";
import { can, generateTooltip } from "../../utils/ui_permissions";
import CheckboxAttribute from "../../editor/attributes/checkbox_attribute";

const TYPES_TO_IGNORE_FOR_DEPARTMENT_TOOLTIP = [
  "ITP",
  "Curriculum",
  "Document Content",
];

/**
 * This class handles rendering large UI controls of user.jsx
 */
export default class UserRecordRenderer {

  constructor(props) {
    this._isAdd = props.isAdd;
    this._parent = props.parent;
    this._userConditionChecker = props.userConditionChecker;
  }

  get userConditionChecker() {
    return this._userConditionChecker;
  }

  get parent() {
    return this._parent;
  }

  get isAdd() {
    return this._isAdd || false;
  }

  get state() {
    return this.parent.state || {};
  }

  get isInternalUserEditingOtherCompaniesUsers() {
    return this.userConditionChecker.isInternalUserEditingOtherCompaniesUsers();
  }

  renderPageTitleBar() {
    let userState = "";
    let currentStateTooltip = "";

    const {enabled, expired, firstName, lastName, id, status, isAPIKey} = this.state;

    if (!this.isAdd) {
      if (enabled === false) {
        userState = "DISABLED";
        currentStateTooltip = "The user account has been disabled in the 'Security' section below.";
      } else if (expired) {
        userState = "EXPIRED";
        currentStateTooltip = "The user account has expired and the user cannot use any more the temporary password to confirm the account.";
      } else if (status && status !== "CONFIRMED") {
        userState = "UNCONFIRMED";
        currentStateTooltip = "The user has not yet logged in for the first time to confirm their account.";
      }
    }

    let tabItems = this.isInternalUserEditingOtherCompaniesUsers ? [USER_TABS[0]] : USER_TABS;

    return (
      <Fragment>
        <CommonEditablesPageTitleBar
          name="Users"
          recordName={firstName || lastName ? (firstName ?? "") + " " + (lastName ?? "") : "New " + this._parent.getCapitalizedRecordTypeName()}
          backLink={this._parent.getBreadcrumbParentLink()}
          currentState={userState}
          currentStateTooltip={currentStateTooltip}
        />
        {isAPIKey ? "" : (
          <TopNavBar
            items={tabItems}
            selected={USER_TABS_ENUM.Details}
            urlParams={id ? {id} : {}}
          />
        )}
      </Fragment>
    );
  }

  getDepartmentTooltip() {
    const {companyLicense, license} = this.state;
    let userLicense = this.isAdd ? CommonUsers.getUserLicense(companyLicense) : license;
    userLicense = userLicense || CommonSecurity.UserLicenseTypes.ESSENTIAL;

    const licenseTypes = permissions.License[userLicense].Statements[0].Types;
    const departments = Object.keys(permissions.Department);
    const departmentTypesMap = new Map();

    for (let department of departments) {
      const departmentTypes = permissions.Department[department].Statements
        .reduce((types, statement) => {
          // The Type "Attribute" is used to give permission into the single-attribute import
          // so it only appears if the experiment is enabled
          return types.concat(statement.Types.filter(type => type !== "Attribute"
            // Remove all types restricted by user's license type
            && licenseTypes.includes(type)
            && !TYPES_TO_IGNORE_FOR_DEPARTMENT_TOOLTIP.includes(type)
            && (type !== "Library Material" || type === "Library Material")
          ));
        }, []);
      departmentTypesMap.set(department, departmentTypes.map(type => {
        return (
          <div key={type} className="user-tooltip-type">{type}</div>
        );
      }));
    }

    return (
      <div className="user-tooltip-dept-container">
        {
          departments.map(department => {
            return (
              <div key={department} className="user-tooltip-dept">
                <b>{department}</b>{departmentTypesMap.get(department).length > 0 ?
                ` users with ${userLicense === CommonSecurity.UserLicenseTypes.PROFESSIONAL ? "a" : "an"} 
                ${userLicense.toLowerCase()}
                license can only modify:` :
                " users cannot modify any objects."}<br />
                {departmentTypesMap.get(department)}
              </div>
            );
          })
        }
      </div>
    );
  }

  getLanguageOptions() {
    return Object.keys(UIUtils.LANGUAGE_OPTIONS).map(key => {
      return {key, value: UIUtils.LANGUAGE_OPTIONS[key]};
    });
  }

  renderLanguagesField() {
    return (<ComboBoxAttribute
      name="language"
      className="col-sm-4"
      options={this.getLanguageOptions()}
      parent={this.parent}
      isLoading={this.state.isLoading}
      parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
      parentId={this.state.id}
    />);
  }

  getRoleDropdownTooltip() {
    return this.userConditionChecker.isRoleDropdownEnabled() ?
      "" : generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.USER);
  }

  renderRoleField() {
    return (<ComboBoxAttribute name="role"
                               className="col-sm-6"
                               options={Object.values(CommonSecurity.Roles)}
                               parent={this.parent}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
                               title={this.getRoleDropdownTooltip()}
                               disabled={!this.userConditionChecker.isRoleDropdownEnabled()}
                               tooltipText={
                                 <div>
                                   <p>
                                     <b>Administrator</b> can perform all actions in all product areas.
                                   </p>
                                   <p>
                                     <b>Super User</b> can perform most actions in specific product areas, depending on their assigned department. The following restrictions apply to this role:<br />
                                     <ul>
                                       <li>Super Users cannot approve proposals to restore records</li>
                                       <li>Super Users cannot manage User profiles of other users</li>
                                     </ul>
                                   </p>
                                   <p><b>Standard User</b> can perform some actions in specific product areas, depending on their assigned department. The following restrictions apply to this role:<br />
                                     <ul>
                                       <li>Standard Users cannot approve proposals to archive or restore records</li>
                                       <li>Standard Users cannot propose records to be restored</li>
                                       <li>Standard Users cannot add or edit a Library Material record</li>
                                     </ul>
                                   </p>
                                   <p><b>Reviewer/Approver</b> can only perform approval-related actions in specific product areas, depending on their assigned department.
                                     The following restrictions apply to this role: <br />
                                     <ul>
                                       <li>Reviewers/Approvers cannot propose records to be archived or restored</li>
                                       <li>Reviewers/Approvers cannot add or edit records</li>
                                     </ul>
                                   </p>
                                   <p><b>Viewers</b> can view information in all product areas. This role cannot perform any other action within the platform, except edit their own User profile.
                                   </p>
                                 </div>
                               }
    />);
  }

  renderEnabledField() {
    const {isAPIKey} = this.state;
    return (<CheckboxAttribute
      name="enabled"
      className="col-sm-6"
      displayName={(isAPIKey ? "API Key" : "User") + " Enabled"}
      parent={this.parent}
      disabled={!this.userConditionChecker.isEnabledFieldEnabled()}
      tooltipText={isAPIKey ? (
        <div>
          Unchecking this checkbox will stop the API key from being able to make any new calls to either read/write data.
        </div>
      ) : (
        <div>
          Unchecking this checkbox will:
          <ul>
            <li>Stop the user from being able to login again (but will not log them out
              immediately)
            </li>
            <li>Return a license back that can be used to create another user</li>
            <li>Stop this user from being an option in data input fields or when proposing for
              approval.
            </li>
          </ul>
        </div>
      )
      }
    />);
  }

  renderResetMfaButton() {
    if (this.isAdd || !this.userConditionChecker.isMfaResetEnabled() || this.state.mfaPreference === "NOMFA" || this.state.isLoading) {
      return false;
    }
    return (
      <button
        onClick={() => {
          this.parent.setStateSafely({shouldShowResetMfaPopup: true});
        }}
        id="resetMfaButton"
        title={"If your company enforces MFA, the user will be prompted to setup MFA again next time they log in."}
        className={"btn btn-lg btn-secondary"}
      >
        Reset MFA
      </button>
    );
  }
  renderResetButton() {
    const {expired, status, identityProvider, cognitoUUID} = this.state;

    if (this.isAdd) {
      return false;
    } else if ((expired || (status && status !== "CONFIRMED")) &&
      (!identityProvider || identityProvider === "default")) {
      return (
        <button id="resetUserButton" onClick={this.parent.handleUserResetPassword}
                className={"btn btn-lg btn-secondary"}
                title={expired ? "Reset the user account and expiration period and re-send the invitation email to the user." :
                  "Generate a new temporary password and re-send the invitation email to the user."}
                disabled={!can(CommonSecurity.Actions.EDIT, CommonSecurity.Types.USER, this.state)}
        >
          {expired ? "Reset User" : "Resend Invitation"}
        </button>
      );
    } else if (identityProvider && identityProvider !== "default" && UIUtils.isCurrentUser(cognitoUUID)) {
      return (
        <button id="resetUserButton" onClick={this.parent.handleUserResetSigningPin}
                className={"btn btn-lg btn-default"}
                title={"Initiate signing pin reset workflow."}
                disabled={!can(CommonSecurity.Actions.EDIT, CommonSecurity.Types.USER, this.state)}
        >
          Reset Signing Pin
        </button>
      );
    }
  }

  createEditButton() {
    return (<div className="btn-group save-cancel-top">
      <button id="editButton" onClick={this.parent.handleEdit}
              className={"btn btn-lg btn-primary"}
              title={generateTooltip(CommonSecurity.Actions.EDIT, CommonSecurity.Types.USER, this.state)}
              disabled={!can(CommonSecurity.Actions.EDIT, CommonSecurity.Types.USER, this.state)}
      >
        Edit
      </button>
      {this.renderResetButton()}
      {this.renderResetMfaButton()}
    </div>);
  }

  createSaveCancelButtons(isTop) {
    return (
      <div className={"btn-group save-cancel-" + (isTop ? "top" : "bottom")}>
        {this.renderResetButton()}
        <button id={"saveButton" + (isTop ? "Top" : "Bottom")}
                className="btn btn-lg btn-primary"
                onClick={this.parent.handleSave}
                disabled={!this.state.dataModified}
        >
          Save
        </button>
        <button id={"cancelButton" + (isTop ? "Top" : "Bottom")}
                className="btn btn-lg btn-secondary"
                onClick={this.parent.handleCancel}
        >
          Cancel
        </button>
      </div>
    );
  }
}
