"use strict";

import React from "react";
import * as I18NWrapper from "../../../i18n/i18n_wrapper";
import * as UIUtils from "../../../ui_utils";
import BasePage from "../../../base_page";
import {
  MATERIAL_SELECTION_STEP_CONFIG, SWAP_OPTIONS_STEP_CONFIG
} from "../steps/config";
import {COMMON_MATERIAL_STEPS} from "../../library_constants";
import {
  ProjectMaterialWizardController
} from "../createProjectMaterial/fromProject/project_material_wizard_container";
import {ProjectMaterialWizardHelper} from "../createProjectMaterial/fromProject/project_material_wizard_helper";
import * as FailHandlers from "../../../utils/fail_handlers";
import {MATERIAL_ACTIONS} from "../../helpers/library_helper";


class SwapProjectMaterialPage extends BasePage {

  constructor(props) {
    super(props);

    const initialState = {
      currentFlowStep: COMMON_MATERIAL_STEPS.MaterialLibrarySelection,
      wizardStepsConfig: [MATERIAL_SELECTION_STEP_CONFIG, SWAP_OPTIONS_STEP_CONFIG],
      libraryMaterials: [],
      selectionState: {},
      action: MATERIAL_ACTIONS.SWAP,
      ProjectId: UIUtils.getParameterByName("projectId"),
      ProcessId: UIUtils.getParameterByName("processId"),
    };

    this.setStateSafely({initialState});
  }

  componentDidMount() {
    super.componentDidMount();

    const {t} = this.props;
    document.title = t("Swap Material Page Title");

    this.loadData();
  }

  loadData() {

    this.setStateSafely({isLoading: true});
    const urlParameters = {
      includeHistory: true,
      loadFullHistory: true,
      includeChildRecords: true,
    };

    const materialId = UIUtils.getParameterByName("materialId");
    UIUtils.secureAjaxGET(
      `editables/Material/${materialId}`,
      urlParameters,
      true,
      FailHandlers.defaultStopLoadingFailFunction.bind(this)
    ).then(results => this.handleResultsReceivedFromServer(results));
  }

  handleResultsReceivedFromServer(result) {
    const {initialState} = this.state;
    this.setStateSafely({
      initialState: {
        ...initialState,
        material: result,
        reload: true
      }
    });
  }

  renderPage() {
    const {t} = this.props;
    const {initialState: {material}} = this.state;

    const props = {
      t,
      WizardHelper: ProjectMaterialWizardHelper,
      initialState: this.state.initialState,
    };

    const title = "Swap Library Material for " + material?.name;
    return material && (
      <ProjectMaterialWizardController title={title} {...props} />
    );
  }
}

export default I18NWrapper.wrap(SwapProjectMaterialPage, "library");
