var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Keys, dispatchEvent, guid, kendoThemeMaps, classNames } from '@progress/kendo-react-common';
import nav from './utils/getItemIdUponKeyboardNavigation';
import { resolveItemId, isEnabledAndAllParentsEnabled, hasChildren } from './utils/itemUtils';
import { TreeViewItem } from './TreeViewItem';
import { ZERO_LEVEL_ZERO_NODE_ID, getItemById } from './utils/itemIdUtils';
import FieldsService from './utils/FieldsService';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from './package-metadata';
import { CHECK_FIELD, CHECK_INDETERMINATE_FIELD, CHILDREN_FIELD, DISABLED_FIELD, EXPAND_FIELD, HAS_CHILDREN_FIELD, SELECT_FIELD, TEXT_FIELD } from './utils/consts';
var sizeMap = kendoThemeMaps.sizeMap;
/**
 * Represents the [KendoReact TreeView component]({% slug overview_treeview %}).
 *
 * @example
 * ```jsx
 * const data = [{
 *     text: 'Furniture', expanded: true, items: [
 *         { text: 'Tables & Chairs' }, { text: 'Sofas' }, { text: 'Occasional Furniture' }]
 * }, {
 *     text: 'Decor', expanded: true, items: [
 *         { text: 'Bed Linen' }, { text: 'Curtains & Blinds' }, { text: 'Carpets' }]
 * }];
 * class App extends React.Component {
 *     render() {
 *         return <TreeView data={data} />;
 *     }
 * }
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var TreeView = /** @class */ (function (_super) {
    __extends(TreeView, _super);
    function TreeView(props) {
        var _this = _super.call(this, props) || this;
        /**
         * @hidden
         */
        _this.state = { focusedItemId: undefined, focusedItemPublicId: undefined, tabbableItemId: ZERO_LEVEL_ZERO_NODE_ID };
        _this.fieldsSvc = null;
        // When there is a change in state.focusedItemId, calling focus() on the dom element of the focused item
        // is most often not needed, because the browser focuses the element on its own.
        // https://github.com/telerik/kendo-react/issues/190
        _this.allowExplicitFocus = false;
        _this.treeGuid = guid();
        _this._element = null;
        _this.onFocusDomElNeeded = function (domItem) {
            if (_this.allowExplicitFocus) {
                _this.focusDomItem(domItem);
            }
        };
        _this.onCheckChange = function (event, item, itemId) {
            _this.setFocus(itemId);
            _this.dispatchCheckChange(event, item, itemId);
        };
        _this.onExpandChange = function (event, item, itemId) {
            _this.setFocus(itemId);
            _this.dispatchExpandChange(event, item, itemId);
        };
        _this.onPress = function (_event, item, itemId) {
            if (_this.props.onItemDragStart) {
                _this.props.onItemDragStart.call(undefined, { target: _this, item: item, itemHierarchicalIndex: itemId });
            }
        };
        _this.onDrag = function (event, item, itemId) {
            var pageX = event.pageX, pageY = event.pageY, clientX = event.clientX, clientY = event.clientY;
            if (_this.props.onItemDragOver) {
                _this.props.onItemDragOver.call(undefined, {
                    target: _this,
                    item: item,
                    itemHierarchicalIndex: itemId,
                    pageX: pageX,
                    pageY: pageY,
                    clientX: clientX,
                    clientY: clientY
                });
            }
        };
        _this.onRelease = function (event, item, itemId) {
            var pageX = event.pageX, pageY = event.pageY, clientX = event.clientX, clientY = event.clientY;
            if (_this.props.onItemDragEnd) {
                _this.props.onItemDragEnd.call(undefined, {
                    target: _this,
                    item: item,
                    itemHierarchicalIndex: itemId,
                    pageX: pageX,
                    pageY: pageY,
                    clientX: clientX,
                    clientY: clientY
                });
            }
        };
        _this.onItemClick = function (event, item, itemId) {
            _this.setFocus(itemId);
            _this.dispatchItemClick(event, item, itemId);
        };
        _this.onFocus = function () {
            clearTimeout(_this.blurRequest);
            if (_this.state.focusedItemId === undefined && _this.data.length) {
                _this.setFocus(_this.state.tabbableItemId);
            }
        };
        _this.onBlur = function () {
            clearTimeout(_this.blurRequest);
            _this.blurRequest = window.setTimeout(function () { return _this.setFocus(undefined); }, 0);
        };
        _this.onKeyDown = function (event) {
            var focusedItem = _this.getFocusedItem();
            if (focusedItem && _this.fieldsSvc) {
                var newItemId = nav(focusedItem, _this.state.focusedItemId, _this.data, event.keyCode, _this.fieldsSvc);
                if (newItemId !== _this.state.focusedItemId) {
                    // This disables the auto-scroll. The scroll is achieved via item focusing.
                    event.preventDefault();
                    _this.allowExplicitFocus = true;
                    _this.setFocus(newItemId);
                }
                _this.dispatchEventsOnKeyDown(event, focusedItem);
            }
        };
        validatePackage(packageMetadata);
        return _this;
    }
    Object.defineProperty(TreeView.prototype, "element", {
        /**
         * @hidden
         */
        get: function () {
            return this._element;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    TreeView.prototype.render = function () {
        var _a;
        var _this = this;
        this.fieldsSvc = new FieldsService(this.props);
        var _b = this.props, size = _b.size, className = _b.className;
        return (React.createElement("div", { className: classNames('k-treeview', (_a = {},
                _a["k-treeview-".concat(sizeMap[size] || size)] = size,
                _a), className), onKeyDown: this.onKeyDown, onFocus: this.onFocus, onBlur: this.onBlur, role: "tree", "aria-multiselectable": this.ariaMultiSelectable ? true : undefined, "aria-label": this.props['aria-label'], "aria-labelledby": this.props['aria-labelledby'], ref: function (element) { return _this._element = element; }, tabIndex: this.props.tabIndex },
            React.createElement("ul", { className: "k-treeview-lines k-treeview-group", role: "group" }, this.data.map(function (item, index) {
                return (React.createElement(TreeViewItem, { item: item, position: index === 0 ? 'top' : index === _this.data.length - 1 ? 'bot' : 'mid', itemId: index.toString(), treeGuid: _this.treeGuid, animate: _this.props.animate, focusedItemId: _this.state.focusedItemId, tabbableItemId: _this.state.tabbableItemId, fieldsService: _this.fieldsSvc, itemUI: _this.props.item, checkboxes: _this.props.checkboxes, ariaMultiSelectable: _this.ariaMultiSelectable, onItemClick: _this.onItemClick, onFocusDomElNeeded: _this.onFocusDomElNeeded, draggable: _this.props.draggable, onPress: _this.onPress, onDrag: _this.onDrag, onRelease: _this.onRelease, expandIcons: _this.props.expandIcons, onExpandChange: _this.onExpandChange, onCheckChange: _this.onCheckChange, key: index, size: size }));
            }))));
    };
    /**
     * @hidden
     */
    TreeView.prototype.componentDidUpdate = function () {
        this.allowExplicitFocus = false;
        this.refocusDueToFocusIdField();
    };
    TreeView.prototype.dispatchEventsOnKeyDown = function (event, focusedItem) {
        var _this = this;
        if (this.fieldsSvc === null) {
            return;
        }
        // isEnabled is the most expensive op and that is why is left last.
        var isEnabled = function () {
            return _this.fieldsSvc && isEnabledAndAllParentsEnabled(_this.state.focusedItemId, _this.data, _this.fieldsSvc);
        };
        if (event.keyCode === Keys.left && this.fieldsSvc.expanded(focusedItem) && isEnabled()) {
            this.dispatchExpandChange(event, focusedItem, this.state.focusedItemId);
        }
        else if (event.keyCode === Keys.right && !this.fieldsSvc.expanded(focusedItem) &&
            (this.fieldsSvc.hasChildren(focusedItem) || hasChildren(focusedItem, this.props.childrenField)) && isEnabled()) {
            this.dispatchExpandChange(event, focusedItem, this.state.focusedItemId);
        }
        else if (event.keyCode === Keys.enter && isEnabled()) {
            this.dispatchItemClick(event, focusedItem, this.state.focusedItemId);
        }
        else if (event.keyCode === Keys.space && isEnabled()) {
            // W/o preventDefault() long TreeViews gets incorrectly scrolled.
            event.preventDefault();
            this.dispatchCheckChange(event, focusedItem, this.state.focusedItemId);
        }
    };
    TreeView.prototype.setFocus = function (focusedItemId) {
        if (focusedItemId && this.fieldsSvc) {
            if (this.fieldsSvc.focusIdField) {
                var item = this.getItemById(focusedItemId);
                this.setState({ focusedItemId: focusedItemId, focusedItemPublicId: this.fieldsSvc.focusId(item) });
            }
            else {
                this.setState({ focusedItemId: focusedItemId });
            }
        }
        else {
            this.setState(function (state) {
                return {
                    focusedItemId: undefined,
                    focusedItemPublicId: undefined,
                    tabbableItemId: state.focusedItemId
                };
            });
        }
    };
    TreeView.prototype.getFocusedItem = function () {
        return this.state.focusedItemId ? this.getItemById(this.state.focusedItemId) : undefined;
    };
    TreeView.prototype.getItemById = function (itemId) {
        return getItemById(itemId, this.data, this.props.childrenField || CHILDREN_FIELD);
    };
    TreeView.prototype.dispatchCheckChange = function (dispatchedEvent, item, itemId) {
        dispatchEvent(this.props.onCheckChange, dispatchedEvent, this, { item: item, itemHierarchicalIndex: itemId });
    };
    TreeView.prototype.dispatchExpandChange = function (dispatchedEvent, item, itemId) {
        dispatchEvent(this.props.onExpandChange, dispatchedEvent, this, { item: item, itemHierarchicalIndex: itemId });
    };
    TreeView.prototype.dispatchItemClick = function (dispatchedEvent, item, itemId) {
        dispatchEvent(this.props.onItemClick, dispatchedEvent, this, { item: item, itemHierarchicalIndex: itemId });
    };
    TreeView.prototype.refocusDueToFocusIdField = function () {
        if (this.fieldsSvc && this.fieldsSvc.focusIdField) {
            var focusedItemPublicId = this.state.focusedItemPublicId;
            if (focusedItemPublicId) {
                var focusedItemId = this.props.getFocusHierarchicalIndex ?
                    this.props.getFocusHierarchicalIndex(focusedItemPublicId) :
                    resolveItemId(focusedItemPublicId, this.fieldsSvc.focusIdField, this.data, this.props.childrenField);
                if (focusedItemId !== this.state.focusedItemId) {
                    this.allowExplicitFocus = true;
                    this.setState({ focusedItemId: focusedItemId });
                }
            }
        }
    };
    Object.defineProperty(TreeView.prototype, "ariaMultiSelectable", {
        get: function () {
            return this.props['aria-multiselectable'] === true || this.props['aria-multiselectable'] === 'true';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TreeView.prototype, "data", {
        get: function () {
            return this.props.data || [];
        },
        enumerable: false,
        configurable: true
    });
    TreeView.prototype.focusDomItem = function (domItem) {
        domItem.focus();
    };
    Object.defineProperty(TreeView.prototype, "guid", {
        /**
         * Returns the `guid` which is associated with the TreeView.
         */
        get: function () {
            return this.treeGuid;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    TreeView.propTypes = {
        data: PropTypes.arrayOf(PropTypes.any),
        animate: PropTypes.bool,
        tabIndex: PropTypes.number,
        focusIdField: PropTypes.string,
        getHierarchicalIndexById: PropTypes.func,
        onExpandChange: PropTypes.func,
        onItemClick: PropTypes.func,
        expandField: PropTypes.string,
        selectField: PropTypes.string,
        childrenField: PropTypes.string,
        hasChildrenField: PropTypes.string,
        textField: PropTypes.string,
        disableField: PropTypes.string,
        item: PropTypes.any,
        'aria-multiselectable': function (props, propName, componentName) {
            if (props[propName] !== undefined
                && props[propName] !== true
                && props[propName] !== false
                && props[propName] !== 'true'
                && props[propName] !== 'false') {
                return new Error('Invalid prop `' + propName + '` supplied to' +
                    ' `' + componentName + '`. Validation failed.');
            }
            return null;
        },
        'aria-label': PropTypes.string,
        'aria-labelledby': PropTypes.string,
        size: PropTypes.oneOf([null, 'small', 'medium', 'large'])
    };
    /**
     * @hidden
     */
    TreeView.defaultProps = {
        animate: true,
        expandField: EXPAND_FIELD,
        selectField: SELECT_FIELD,
        hasChildrenField: HAS_CHILDREN_FIELD,
        childrenField: CHILDREN_FIELD,
        textField: TEXT_FIELD,
        disableField: DISABLED_FIELD,
        checkField: CHECK_FIELD,
        checkIndeterminateField: CHECK_INDETERMINATE_FIELD,
        size: 'medium'
    };
    return TreeView;
}(React.Component));
export { TreeView };
