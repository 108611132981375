"use strict";

import React, { Fragment, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import ChatPopup from "../../chat/chat_popup";
import * as UIUtils from "../../ui_utils";
import { EXPERIMENTS } from "../../helpers/constants/constants";
import mentaIcon from "../../images/icons/menta.png";
import * as chatStyles from "../../chat/chat.module.scss";

export function Feedback() {
  const [showModal, setShowModal] = useState(false);
  const modalRef = useRef(null);

  function handleOpenModal() {
    setShowModal(true);
  }

  const showChat = UIUtils.isExperimentEnabled(EXPERIMENTS.Menta);
  return (
    <Fragment>
      <div className="company-header-feedback">
        {showChat ? (
          <a onClick={handleOpenModal} id="mentaButton">
            <img src={mentaIcon}
                 alt="Go to Process Flow Map"
                 className={chatStyles["menta-icon"]}
            />
          </a>
        ) : (
          <a href="https://support.qbdvision.com" target="_blank" id="footerFeedback" rel="noreferrer">
            <FontAwesomeIcon icon={faComment} />
          </a>
        )}
      </div>
      {showModal ? (
        <ChatPopup
          modalRef={modalRefParam => modalRef.current = modalRefParam}
          onHideModal={() => {
            $(modalRef.current).modal("hide");
            setShowModal(false);
          }}
        />
      ) : null}
    </Fragment>
  );
}
