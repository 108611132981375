"use strict";

import React from "react";
import { WIZARD_STEPS_CONFIG } from "./constants/link_tech_transfer_constants";
import BaseReactComponent from "../../../base_react_component";

export default class LinkTechTransferWizardNavBar extends BaseReactComponent {
  constructor(props) {
    super(props);

  }

  render() {
    let index = 1;
    return (
      <div className="row import-nav-tabs">
        {
          WIZARD_STEPS_CONFIG.map(wizardStepConfig => {
            return (
              <div key={wizardStepConfig.navTabId}
                   role="presentation"
                   className={"col-auto import-nav-tab align-items-center" + (this.props.currentWizardStep === wizardStepConfig.step ? " active" : "")}
              >
                <a onClick={() => {
                  if (!wizardStepConfig.disabled) {
                    this.props.onTabNavItemClicked(wizardStepConfig.step);
                  }
                }}
                   id={wizardStepConfig.navTabId}
                   className={this.props.currentWizardStep !== wizardStepConfig.step ? "import-nav-inactive" : ""}
                >
                  {`${index++}- ${wizardStepConfig.step}`}
                </a>
              </div>);
          })
        }
      </div>
    );
  }
}

