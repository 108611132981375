"use strict";

import React, { Fragment } from "react";
import BaseReactComponent from "../../base_react_component";
import { getRiskFieldOptions, getRiskScale } from "../../helpers/risk_helper";
import { ListOption } from "../../editor/widgets/list_option";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import { RISK_TYPE_ENUM } from "../../helpers/constants/constants";

import ErrorBar from "../../widgets/bars/error_bar";
import { TECH_TRANSFER_ASSESSMENT_STATUSES } from "../tech_transfer_constants";
import { NOT_STARTED } from "../../configurableTables/tables/configurable_tables_constants";
import RiskUtils from "../../../server/common/misc/common_risk_utils";

const CommonEditables = require("../../../server/common/editables/common_editables");

const TEXT_AREA_ROWS = "3";

/**
 * This class is responsible for rendering of the tech transfer editor section in the review screen
 */
export default class TechTransferReviewEditor extends BaseReactComponent {
  handleChangeValue(event) {
    let {id, value} = event.target;
    id = id.replace("Input", "").replace("ComboBox", "");
    this.setStateSafely({[id]: value, sourceScreen: CommonEditables.TECH_TRANSFER_REVIEW_SCREEN}, () => {
      this.props.onTechTransferInfoUpdated(this.state);
    });
  }

  getValue(name) {
    const {record} = this.props;
    return (this.state[name] || this.state[name] === "") ? this.state[name] : record[name];
  }

  render() {
    const {record, effectiveRMP} = this.props;
    const impactOptions = getRiskFieldOptions(effectiveRMP, "RMPToImpactLinkedVersions");
    const uncertaintyOptions = getRiskFieldOptions(effectiveRMP, "RMPToUncertaintyLinkedVersions");
    const detectabilityOptions = getRiskFieldOptions(effectiveRMP, "RMPToDetectabilityRiskLinkedVersions");
    const defaultImpact = RiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.IMPACT, effectiveRMP);
    const defaultUncertainty = RiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.UNCERTAINTY, effectiveRMP);
    const defaultDetectabilityRisk = RiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.DETECTABILITY_RISK, effectiveRMP);

    const rpn = (this.getValue("techTransferImpact") || defaultImpact) *
      (this.getValue("techTransferUncertainty") || defaultUncertainty) *
      (this.getValue("techTransferDetectability") || defaultDetectabilityRisk);
    const outcomeRiskScale = getRiskScale(RISK_TYPE_ENUM.RPN, effectiveRMP, rpn, record);
    const outcome = outcomeRiskScale ? outcomeRiskScale.riskLabel : "";

    return (
      <Fragment>
        <span>
           <h2 className="tech-transfer-review-screen-editor-title mt-0">
             Transfer Risk
           </h2>
           <InfoTooltip id="infoTechTransferAssessment"
                        verbiage={
                          <div>
                            Use this to rate the risk of transferring a process from one environment to another.
                          </div>
                        }
           />
        </span>
        <ErrorBar className="m-0" />

        <label className="col-form-label tech-transfer-review-screen-editor-label">
          <span>Assessment Status:</span>
        </label>
        <select id="techTransferAssessmentStatusComboBox"
                className="form-control"
                onChange={this.handleChangeValue}
                defaultValue={NOT_STARTED}
                value={this.getValue("techTransferAssessmentStatus")}
        >
          {TECH_TRANSFER_ASSESSMENT_STATUSES
            .map((optionValue, idx) => {
              return <ListOption item={optionValue} key={idx} />;
            })}
        </select>

        <label className="col-form-label tech-transfer-review-screen-editor-label">
          <span>Impact/Severity:</span>
        </label>
        <select id="techTransferImpactComboBox"
                className="form-control"
                onChange={this.handleChangeValue}
                defaultValue={defaultImpact}
                value={this.getValue("techTransferImpact")}
        >
          {impactOptions.map((optionValue, idx) => {
            return <ListOption item={optionValue} key={idx} />;
          })}
        </select>

        <label className="col-form-label tech-transfer-review-screen-editor-label">
          <span>Impact/Severity Description:</span>
        </label>
        <textarea id="techTransferImpactDescriptionInput"
                  rows={TEXT_AREA_ROWS}
                  className="form-control"
                  onChange={this.handleChangeValue}
                  value={this.getValue("techTransferImpactDescription")}
        />

        <label className="col-form-label tech-transfer-review-screen-editor-label">
          <span>Uncertainty:</span>
        </label>
        <select id="techTransferUncertaintyComboBox"
                className="form-control"
                onChange={this.handleChangeValue}
                defaultValue={defaultUncertainty}
                value={this.getValue("techTransferUncertainty")}
        >
          {uncertaintyOptions.map((optionValue, idx) => {
            return <ListOption item={optionValue} key={idx} />;
          })}
        </select>

        <label className="col-form-label tech-transfer-review-screen-editor-label">
          <span>Uncertainty Justification:</span>
        </label>
        <textarea id="techTransferUncertaintyJustificationInput"
                  rows={TEXT_AREA_ROWS}
                  className="form-control"
                  onChange={this.handleChangeValue}
                  value={this.getValue("techTransferUncertaintyJustification")}
        />

        <label className="col-form-label tech-transfer-review-screen-editor-label">
          <span>Detectability:</span>
        </label>
        <select id="techTransferDetectabilityComboBox"
                className="form-control"
                onChange={this.handleChangeValue}
                defaultValue={defaultDetectabilityRisk}
                value={this.getValue("techTransferDetectability")}
        >
          {detectabilityOptions.map((optionValue, idx) => {
            return <ListOption item={optionValue} key={idx} />;
          })}
        </select>

        <label className="col-form-label tech-transfer-review-screen-editor-label">
          <span>Detectability / Current Control Strategy:</span>
        </label>
        <textarea id="techTransferControlStrategyInput"
                  rows={TEXT_AREA_ROWS}
                  className="form-control"
                  onChange={this.handleChangeValue}
                  value={this.getValue("techTransferControlStrategy")}
        />

        <div className="row">
          <div className="col-6">
            <div>
              <label className="col-form-label tech-transfer-review-screen-editor-label">
                <span>RPN:</span>
              </label>
            </div>
            <div>
              <label className="col-form-label tech-transfer-review-screen-editor-label">
                <span>{rpn}</span>
              </label>
            </div>
          </div>
          <div className="col-6">
            <div>
              <label className="col-form-label tech-transfer-review-screen-editor-label">
                <span>Outcome:</span>
              </label>
            </div>
            <div>
              <label className="col-form-label tech-transfer-review-screen-editor-label">
                <span>{outcome}</span>
              </label>
            </div>
          </div>
        </div>

        <label className="col-form-label tech-transfer-review-screen-editor-label">
          <span>Risk Mitigation Strategy and Limitations:</span>
        </label>
        <textarea id="techTransferRiskMitigationStrategyInput"
                  rows={TEXT_AREA_ROWS}
                  className="form-control"
                  onChange={this.handleChangeValue}
                  value={this.getValue("techTransferRiskMitigationStrategy")}
        />

        <label className="col-form-label tech-transfer-review-screen-editor-label">
          <span>Recommended Actions:</span>
        </label>
        <textarea id="techTransferRecommendedActionsInput"
                  rows={TEXT_AREA_ROWS}
                  className="form-control"
                  onChange={this.handleChangeValue}
                  value={this.getValue("techTransferRecommendedActions")}
        />

        <label className="col-form-label tech-transfer-review-screen-editor-label">
          <span>Comment or Description of Change:</span>
        </label>
        <textarea id="techTransferCommentInput"
                  rows={TEXT_AREA_ROWS}
                  className="form-control mb-2"
                  onChange={this.handleChangeValue}
                  value={this.getValue("techTransferComment")}
        />
      </Fragment>
    );
  }
}
