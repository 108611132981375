"use strict";

import React from "react";
import BaseTable from "../tables/base_table";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import * as UIUtils from "../../ui_utils";
import MemoryCache from "../../utils/cache/memory_cache";

/**
 * This is a base class for implementing a component based on datatables.
 */
export default class BaseRelatedRecordsTable extends BaseTable {
  constructor(props) {
    super(props);

    this.state = {
      processesLoaded: false
    };

    this.attemptToLoadTypeahead();
    this.props.parent?.addOnDataReceivedListener(() => {
      this.attemptToLoadTypeahead();
    });

  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.id !== this.props.id ||
           nextProps.isLoading !== this.props.isLoading ||
           nextProps.parentId !== this.props.parentId ||
           nextState.processesLoaded !== this.state.processesLoaded;
  }

  /**
   * Implement this in derived classed to add custom functionality when a row is clicked
   * @param e
   * @param row
   */
  handleRowClick(e, row) {
    UIUtils.ignoreHandler(e);
    const rowToClick = row.node();

    if (rowToClick) {
      rowToClick.getElementsByTagName("a")[0].click();
    }
  }

  componentDidUpdate() {
    this.reloadDataTable();

    const tBody = $(`#${this.props.id}Table tbody`);
    let table = this.table;
    let handleRowClick = this.handleRowClick;

    tBody.off("click", "tr");

    if (!this.props.ignoreRowClick) {
      tBody.on("click", "tr", this.getRowOnClickFunction(table, handleRowClick));
    }
  }

  getMemoryCacheInstance() {
    return MemoryCache.getNamedInstance("base_related_records_table");
  }

  attemptToLoadTypeahead() {
    if (!this.props.projectId || !this.props.processId || this.state.processesLoaded) {
      return;
    }

    const memoryCache = this.getMemoryCacheInstance();
    if (memoryCache.get(this.getProcessCacheKey())) {
      return;
    }

    const processCache = new TypeaheadObjectCache("Process", this.props.projectId);
    processCache.loadOptions().promise().then(() => {
      this.setStateSafely({
        processId: this.props.processId,
        processesLoaded: true
      });
    });
  }

  getProcessCacheKey() {
    return "process_" + this.props.processId;
  }

  getProcessFromCache() {
    const processId = this.props.processId || this.state.processId;
    let thisProcess = null;
    if (!processId) {
      return;
    }

    const memoryCache = this.getMemoryCacheInstance();
    thisProcess = memoryCache.get(this.getProcessCacheKey());
    if (thisProcess) {
      return thisProcess;
    }

    const processes = new TypeaheadObjectCache("Process", this.props.projectId).getOptionsFromCacheIncludingArchived();
    thisProcess = processes.find(process => process.id === processId);
    if (thisProcess) {
      memoryCache.set(this.getProcessCacheKey(), thisProcess);
      return thisProcess;
    }

    return;
  }

  /**
   * Overwrite this in derived classes to provide custom functionality on the datatables row click event.
   * @param table
   * @param handleClick
   * @returns {function(): void}
   */
  getRowOnClickFunction(table, handleClick) {
    return function(e) {
      let row = table.row(this);
      handleClick(e, row);
    };
  }

  initializeDataTables() {
    this.table = $(this.tableRef).DataTable({
      ...this.getTableInitializationOptions(),
      createdRow: (row, data) => this.createTableRow(row, data)
    });

    setTimeout(() => {
      this.reloadDataTable();
    }, 200);
  }

  createTableRow(row) {
    if (!this.props.ignoreRowClick) {
      $(row).addClass("base-table-row");
      $(row).on("click", "a", function(event) {
        // This prevents the event from propagating to the row and triggering multiple click events.
        UIUtils.ignoreHandler(event);
        window.open(event.target.href, event.target.target);
      });
    }
  }
}
