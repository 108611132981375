"use strict";

import { CriticalityJustificationEntry } from "../types/criticality_justification_entry";
import { getMaxCriticality, getRawRiskScore } from "../../../helpers/risk_helper";
import { RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import { doesItemHaveRiskLinks, getRiskLinkKey, sortRiskLinks } from "./risk_tables_helper";

/**
 * Processes and converts the criticality information into objects ready to be displayed in the risk tables.
 * @param rows {*[]} The raw data objects to be processed.
 * @return {*[]} An array of data objects containing the criticality information ready to be displayed
 */
export function parseCriticalityInformationFromRawData(rows) {
  return rows.map(row => {
    if (typeof (row.CriticalityJustification) === "string") {
      row.CriticalityJustification = parseJustificationString(row.CriticalityJustification);
    } else if (doesItemHaveRiskLinks(row)) {
      const riskLinkWinners = row.riskInfo.Criticality.scaleForRiskLabel.riskLinkWinners.map(riskLinkWinner => riskLinkWinner.typeCode + "-" + riskLinkWinner.impact + "-" + riskLinkWinner.uncertainty);
      const maxRiskLinks = row.riskLinks.filter(
        link => {
          return riskLinkWinners.includes(link.linkedTypeCode + "-" + link.impact + "-" + link.uncertainty);
        }
      );

      maxRiskLinks.sort(sortRiskLinks.bind(this));

      row.CriticalityJustification = maxRiskLinks.map(
        link => new CriticalityJustificationEntry({
          attributeKey: getRiskLinkKey(link),
          attributeName: link.name,
          justification: link.justification,
          showAttributeInfo: maxRiskLinks.length > 1,
          state: link.currentState,
        })
      );

      row.impact = maxRiskLinks[0].impact;
      row.uncertainty = maxRiskLinks[0].uncertainty;
      row.maxRiskLinks = maxRiskLinks;
    } else {
      row.CriticalityJustification = null;
      row.impact = 0;
      row.uncertainty = 0;
    }
    return row;
  });
}

export function parseControlMethodsInRawData(rows) {
  return rows.map(row => {
    row.controlMethods = parseControlMethodString(row.controlMethods);
    return row;
  });
}

/**
 *
 * @param justificationString
 * @returns {?*}
 */
export function parseJustificationString(justificationString) {
  if (justificationString) {
    let justifications = justificationString.split("|\u2063|");
    return justifications.length === 0
      ? null
      : justifications.map(justificationString => {
        const justificationsArray = justificationString.split("\u2063");
        return new CriticalityJustificationEntry({
          justification: justificationsArray[0],
          attributeKey: justificationsArray[1],
          attributeName: justificationsArray[2],
          state: justificationsArray[3],
          showAttributeInfo: true,
        });
      }).sort((item1, item2) => item1.attributeKey < item2.attributeKey ? -1 : (item1.attributeKey > item2.attributeKey ? 1 : 0));
  } else {
    return null;
  }
}

export function parseControlMethodString(controlMethodsString) {
  if (controlMethodsString) {
    let controlMethods = controlMethodsString.split("|");
    return controlMethods.map(controlMethodString => {
      const controlMethodArray = controlMethodString.split("\u2063");
      return {
        id: controlMethodArray[0],
        name: controlMethodArray[1],
        status: controlMethodArray[2],
        state: controlMethodArray[3],
      };
    });
  } else {
    return null;
  }
}

export function parseTPPsString(TPPsString) {
  if (TPPsString) {
    return TPPsString.split("|").map(tppString => {
      const tppArray = tppString.split("\u2063");
      return {
        id: tppArray[0],
        name: tppArray[1],
        state: tppArray[2],
      };
    });
  } else {
    return null;
  }
}