"use strict";

import React, { useEffect, useState } from "react";
import { SELECT_ALL_INDEX } from "../../../helpers/library_helper";

function getCreateAllSpecsLabel(length) {
  if (length >= 1) {
    return length + ` Specifications`;
  } else if (length === 1) {
    return length + ` Specification`;
  } else {
    return `No Specifications`;
  }
}

export function MaterialSpecifications({specifications, onSelect, allChecked}) {

  const {length} = specifications;
  const [checkAll, setCheckAll] = useState(allChecked);

  useEffect(() => {
    setCheckAll(allChecked);
  }, [allChecked]);

  const onSelectAllChange = () => {
    const toggle = !checkAll;
    setCheckAll(toggle);
    onSelect({position: SELECT_ALL_INDEX, toggle});
  };

  const onSelectChange = (position) => {
    onSelect({position});
    setCheckAll(false);
  };

  return (
    <div className="row">
      <div className="col">
        <p className="specification-description">You can automatically create Material Attributes from Library Specifications.</p>
        <div className="form-group">
          <div className="form-check mb-2 pb-1 border-bottom">
            <input
              type="checkbox"
              id="selectAllInput"
              name="select-all"
              className="form-check-input"
              checked={checkAll}
              onChange={() => onSelectAllChange()}
            />
            <label className="form-check-label" htmlFor="selectAllInput">{getCreateAllSpecsLabel(length)}</label>
          </div>
          {specifications.map((item, index) => {
            const {name, checked} = item;
            return (
              <div className="form-check" key={index}>
                <input
                  type="checkbox"
                  id={`spec-checkbox-${index}`}
                  name={name}
                  className="form-check-input"
                  value={name}
                  checked={checked}
                  onChange={() => onSelectChange(index)}
                />
                <label className="form-check-label" htmlFor={`spec-checkbox-${index}`}>{name}</label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
