"use strict";

import * as UIUtils from "../../ui_utils";

export const ALIGNMENT = {
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  CENTER: "CENTER",
  TOP: "TOP",
  BOTTOM: "BOTTOM"
};

export default class CanvasDrawerBase {
  static getEmptyCanvas(width, height) {
    let canvas = document.createElement("canvas");
    canvas.width = width ? width : 0;
    canvas.height = height ? height : 0;
    return canvas;
  }

  static drawLogo(fontFamily, fontSize, logoMargin, logoImage) {
    let canvas = CanvasDrawerBase.getEmptyCanvas();
    let ctx = canvas.getContext("2d");
    let x = logoMargin;
    let y = logoMargin;


    //Calculate the width and height of the rendered text
    ctx.font = "900 " + fontSize + " " + fontFamily;
    let textWidth = ctx.measureText("QbDVision").width;
    let textHeight = UIUtils.getTextSize({
      fontFamily: fontFamily,
      fontSize: fontSize,
      fontWeight: "900"
    }, "QbDVision®");

    //Set the width of the canvas including any specified margins
    canvas.width = logoImage.width + textWidth + (2 * logoMargin);
    canvas.height = (logoImage.height > textHeight.height ? logoImage.height : textHeight.height) + (2 * logoMargin);

    //Fill the background with white color
    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    //Draw the QbDVision Logo
    ctx.drawImage(logoImage, x, y);
    return canvas;
  }

  static drawText(text, fontOptions, margins, backgroundColor = "#FFFFFF", canvasWidth, canvasHeight, centerOptions) {
    const {fontFamily, fontSize, fontColor, fontWeight, fontStyle} = fontOptions;

    let canvas = CanvasDrawerBase.getEmptyCanvas();
    let ctx = canvas.getContext("2d");
    let x, y;
    let font = `${fontStyle ? fontStyle + " " : ""}${fontWeight} ${fontSize} ${fontFamily}`;

    // https://bugzilla.mozilla.org/show_bug.cgi?id=1126391
    let italicsCompensation = fontOptions.fontStyle && fontOptions.fontStyle === "italic" ? 2 : 0;
    ctx.font = font;
    let textWidth = ctx.measureText(text).width + italicsCompensation;
    let textHeight = UIUtils.getTextSize(fontOptions, text);

    //Set the width of the canvas including any specified margins
    canvas.width = canvasWidth ? canvasWidth : (textWidth + margins.left + margins.right);
    canvas.height = canvasHeight ? canvasHeight : (textHeight.height + margins.top + margins.bottom);

    x = centerOptions && centerOptions.horizontal ? (canvas.width / 2) : margins.left;
    y = centerOptions && centerOptions.vertical ? (canvas.height / 2 + textHeight.ascent / 2) : (margins.top + textHeight.ascent);

    //Fill the background with white color
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    //Render the text
    ctx.font = font;
    ctx.fillStyle = fontColor;
    ctx.textAlign = centerOptions ? "center" : "start";
    ctx.fillText(text, x, y);

    return canvas;
  }

  static drawLegend(legend, legendValue, fontOptions, margins, backgroundColor = "#FFFFFF", canvasWidth = null, canvasHeight = null) {
    const {fontFamily, fontSize, fontColor} = fontOptions;
    let canvas = CanvasDrawerBase.getEmptyCanvas();
    let ctx = canvas.getContext("2d");
    let x = margins.left;
    let y;
    let legendFont = "900 " + fontSize + " " + fontFamily;
    let legendValueFont = "400 " + fontSize + " " + fontFamily;

    //Calculate the width and height of the rendered text
    ctx.font = legendFont;
    let legendWidth = ctx.measureText(legend).width;
    ctx.font = legendValueFont;
    let legendValueWidth = ctx.measureText(legendValue).width;
    let textWidth = legendWidth + legendValueWidth;
    let textHeight = UIUtils.getTextSize(fontOptions, legend + legendValue);

    //Set the width of the canvas including any specified margins
    canvas.width = canvasWidth ? canvasWidth : textWidth + margins.left + margins.right;
    canvas.height = canvasHeight ? canvasHeight : textHeight.height + margins.top + margins.bottom;

    //Fill the background with white color
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    //Render the text
    y = margins.top + textHeight.ascent;
    ctx.fillStyle = "#666666";
    ctx.font = legendFont;
    ctx.fillText(legend, x, y);
    x = x + legendWidth;

    if (legendValue) {
      ctx.fillStyle = fontColor ? fontColor : "#666666";
      ctx.font = legendValueFont;
      ctx.fillText(legendValue, x, y);
    }

    return canvas;
  }

  static mergeCanvasToBottom(canvas1, canvas2, canvasSpacing = 0, canvasAlignment = ALIGNMENT.LEFT) {
    let canvas = CanvasDrawerBase.getEmptyCanvas();
    let ctx = canvas.getContext("2d");

    canvas.width = Math.max(canvas1.width, canvas2.width);
    canvas.height = canvas1.height + canvas2.height + canvasSpacing;
    //Fill the background with white color
    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    let x = 0;
    if (canvas1.height > 0) {
      x = CanvasDrawerBase.getVerticalAlignmentPoint(canvas, canvas1, canvasAlignment);
      ctx.drawImage(canvas1, x, 0);
    }

    if (canvas2.height > 0) {
      x = CanvasDrawerBase.getVerticalAlignmentPoint(canvas, canvas2, canvasAlignment);
      ctx.drawImage(canvas2, x, canvasSpacing + canvas1.height);
    }

    return canvas;
  }

  static mergeCanvasToBottomAndScale(canvas1, canvas2, canvasSpacing) {
    let spacing = canvasSpacing ? canvasSpacing : 0;
    let canvas = CanvasDrawerBase.getEmptyCanvas();
    let ctx = canvas.getContext("2d");
    let ratio = canvas2.width / canvas1.width;

    canvas.width = Math.max(canvas1.width, canvas2.width);
    if (ratio > 1) {
      canvas.height = (canvas1.height * ratio) + canvas2.height + spacing;
    } else {
      canvas.height = canvas1.height + (canvas2.height / ratio) + spacing;
    }

    //Fill the background with white color
    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    if (ratio > 1) {
      ctx.drawImage(canvas1, 0, 0, canvas2.width, canvas1.height * ratio);
    } else {
      ctx.drawImage(canvas1, 0, 0);
    }

    if (ratio > 1) {
      ctx.drawImage(canvas2, 0, spacing + canvas1.height * ratio);
    } else {
      ctx.drawImage(canvas2, 0, spacing + canvas1.height, canvas1.width, canvas2.height / ratio);
    }

    return canvas;
  }

  static mergeCanvasToRight(canvas1, canvas2, canvasSpacing, canvasAlignment) {
    let spacing = canvasSpacing ? canvasSpacing : 0;
    let canvas = CanvasDrawerBase.getEmptyCanvas();
    let ctx = canvas.getContext("2d");

    canvas.height = Math.max(canvas1.height, canvas2.height);
    canvas.width = canvas1.width + canvas2.width + spacing;
    //Fill the background with white color
    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    let y = 0;
    if (canvas1.height > 0) {
      y = CanvasDrawerBase.getHorizontalAlignmentPoint(canvas, canvas1, canvasAlignment);
      ctx.drawImage(canvas1, 0, y);
    }

    y = 0;
    if (canvas2.height > 0) {
      y = CanvasDrawerBase.getHorizontalAlignmentPoint(canvas, canvas2, canvasAlignment);
      ctx.drawImage(canvas2, spacing + canvas1.width, y);
    }

    return canvas;
  }

  static mergeCanvasOver(canvas1, canvas2, canvas1OverlayAlignment, canvas2OverlayAlignment) {
    let canvas = CanvasDrawerBase.getEmptyCanvas();
    let ctx = canvas.getContext("2d");
    canvas.height = Math.max(canvas1.height, canvas2.height);
    canvas.width = Math.max(canvas1.width, canvas2.width);

    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    let x = 0;
    let y = 0;

    if (canvas1.height > 0) {
      if (canvas1OverlayAlignment) {
        x = CanvasDrawerBase.getVerticalAlignmentPoint(canvas, canvas1, canvas2OverlayAlignment.horizontal);
        y = CanvasDrawerBase.getHorizontalAlignmentPoint(canvas, canvas1, canvas2OverlayAlignment.vertical);
      }

      ctx.drawImage(canvas1, x, y);
    }

    x = 0;
    y = 0;
    if (canvas2.height > 0) {
      if (canvas2OverlayAlignment) {
        x = CanvasDrawerBase.getVerticalAlignmentPoint(canvas, canvas2, canvas2OverlayAlignment.horizontal);
        y = CanvasDrawerBase.getHorizontalAlignmentPoint(canvas, canvas2, canvas2OverlayAlignment.vertical);
      }

      ctx.drawImage(canvas2, x, y);
    }

    return canvas;
  }

  static getVerticalAlignmentPoint(canvas1, canvas2, alighment) {
    let x = 0;
    if (alighment === ALIGNMENT.RIGHT) {
      x = canvas1.width - canvas2.width;
    } else if (alighment === ALIGNMENT.CENTER) {
      x = (canvas1.width - canvas2.width) / 2;
    }

    return x;
  }

  static getHorizontalAlignmentPoint(canvas1, canvas2, alighment) {
    let y = 0;
    if (alighment === ALIGNMENT.CENTER) {
      y = (canvas1.height - canvas2.height) / 2;
    } else if (alighment === ALIGNMENT.BOTTOM) {
      y = canvas1.height - canvas2.height;
    }

    return y;
  }

  static applyMarginsToCanvas(sourceCanvas, margins) {
    let canvas = CanvasDrawerBase.getEmptyCanvas();
    let ctx = canvas.getContext("2d");
    canvas.height = sourceCanvas.height + margins.top + margins.bottom;
    canvas.width = sourceCanvas.width + margins.left + margins.right;

    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    if (sourceCanvas.height > 0) {
      ctx.drawImage(sourceCanvas, margins.left, margins.top, sourceCanvas.width, sourceCanvas.height);
    }

    return canvas;
  }

  static drawCircle(ctx, x, y, size) {
    ctx.beginPath();
    ctx.arc(x + size / 2, y - size / 2, size / 2, 0, 2 * Math.PI);
    ctx.fill();
    ctx.stroke();
  }

  static drawTriangle(ctx, x, y, size) {
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + size, y);
    ctx.lineTo(x + size / 2, y - size);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  }

  static drawHexagon(ctx, x, y, size) {
    let numberOfSides = 6;
    size = size / 2;

    ctx.beginPath();
    ctx.moveTo(x + size * Math.cos(0), y);
    for (let i = 1; i <= numberOfSides; i += 1) {
      ctx.lineTo(x + size * Math.cos(i * 2 * Math.PI / numberOfSides), y + size * Math.sin(i * 2 * Math.PI / numberOfSides));
    }

    ctx.fill();
    ctx.stroke();
  }

  static drawStar(ctx, x, y, spikes, outerRadius, innerRadius) {
    let rot = Math.PI / 2 * 3;
    let step = Math.PI / spikes;
    let cx = x;
    let cy = y;

    ctx.beginPath();
    ctx.moveTo(cx, cy - outerRadius);
    for (let i = 0; i < spikes; i++) {
      x = cx + Math.cos(rot) * outerRadius;
      y = cy + Math.sin(rot) * outerRadius;
      ctx.lineTo(x, y);
      rot += step;

      x = cx + Math.cos(rot) * innerRadius;
      y = cy + Math.sin(rot) * innerRadius;
      ctx.lineTo(x, y);
      rot += step;
    }
    ctx.lineTo(cx, cy - outerRadius);
    ctx.closePath();
    ctx.stroke();
    ctx.fill();
  }

  static resetMargins() {
    return {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    };
  }

  static includeAttributeInformation() {
    return false;
  }
}
