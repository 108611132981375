"use strict";

import { FIELD_PROPS } from "../fieldsConfig/constants/configurable_tables_field_props_config";
import { SENDING_SITE_LABEL } from "../fieldsConfig/constants/configurable_tables_columns_config";

export function filterFields(fields, isTechTransfer = false) {
  return isTechTransfer ? fields : fields.filter(field =>
    !field.isCompositeField && (!field.siteForValueEvaluation ||
    field.siteForValueEvaluation === SENDING_SITE_LABEL));
}

export function filterRiskRankingFields(fields, project) {
  const originalFields = filterFields(fields || []);
  if (!project || !fields) {
    return fields;
  }

  const isRiskRanking = project?.productRiskAssessmentType === "Risk Ranking";

  let filteredFields = [];
  if (isRiskRanking) {
    filteredFields = [
      FIELD_PROPS.IMPACT,
      FIELD_PROPS.UNCERTAINTY,
      FIELD_PROPS.GENERAL_ATTRIBUTES,
      FIELD_PROPS.CRITICALITY,
      FIELD_PROPS.CRITICALITY_JUSTIFICATION,
      FIELD_PROPS.CRITICALITY_PERCENTAGE
    ];
  } else {
    filteredFields = [
      FIELD_PROPS.MAX_CRITICALITY,
      FIELD_PROPS.MAX_CRITICALITY_PERCENTAGE,
      FIELD_PROPS.TOTAL_CRITICALITY,
    ];
  }

  const fieldsToReturn = originalFields.filter(field => !filteredFields.includes(field.prop));
  return fieldsToReturn;
}

export function getColumnProp(column, isTechTransfer) {
  return `${column.id}${isTechTransfer ? `_${column.originatedFromSite}` : ""}`;
}
