"use strict";

import { Button } from "@qbdvision-inc/component-library";
import React from "react";
import * as UiUtils from "../../../../../../../ui_utils";
import { useSidePanel } from "../../widgets/side_panel_provider";
import { SIDE_PANEL_ACTIONS } from "../../../constants";


export const BulkEditButton = () => {

  const {toggle} = useSidePanel();

  const onClick = (e) => {
    UiUtils.ignoreHandler(e);
    toggle(SIDE_PANEL_ACTIONS.BULK_EDIT);
  };

  return (
    <Button
      id="bulkEdit"
      label={"Edit"}
      size={"small"}
      type="secondary"
      onClick={onClick}
    />
  );
};