var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { isEqual, cloneDate } from '@progress/kendo-date-math';
import { Button } from '@progress/kendo-react-buttons';
import { registerForIntl, provideIntlService, registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { KendoDate } from './models';
import { guid, AsyncFocusBlur, noop, classNames, createPropsContext, withPropsContext, kendoThemeMaps } from '@progress/kendo-react-common';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
import { approximateStringMatching, defaultFormat, defaultFormatPlaceholder, isInRange, wrapperClasses } from './utils';
import { MAX_DATE, MIN_DATE } from './../utils';
import { messages, increaseValue, decreaseValue } from './../messages';
import { isInTimeRange } from '../timepicker/utils';
import { MIN_TIME, MAX_TIME } from '../defaults';
var VALIDATION_MESSAGE = 'Please enter a valid value!';
// tslint:enable:max-line-length
/** @hidden */
var DateInputWithoutContext = /** @class */ (function (_super) {
    __extends(DateInputWithoutContext, _super);
    function DateInputWithoutContext(props) {
        var _this = _super.call(this, props) || this;
        _this.kendoDate = null;
        _this.currentFormat = '';
        _this.paste = false;
        _this._element = null;
        _this._wrapper = null;
        _this._inputId = guid();
        _this._lastSelectedSymbol = '';
        /**
         * @hidden
         */
        _this.focus = function () {
            if (_this._element) {
                _this._element.focus();
            }
        };
        /**
         * @hidden
         */
        _this.setValidity = function () {
            if (_this.element && _this.element.setCustomValidity) {
                _this.element.setCustomValidity(_this.validity.valid
                    ? ''
                    : _this.props.validationMessage || DateInputWithoutContext.defaultProps.validationMessage);
            }
        };
        _this.updateOnPaste = function (event) {
            if (!_this.element || !_this.kendoDate) {
                return;
            }
            var value = provideIntlService(_this).parseDate(_this.element.value, _this.props.format) || _this.value;
            var oldValue = _this.value;
            _this.kendoDate.setValue(value);
            _this.triggerChange(event, oldValue);
        };
        /* Handlers */
        _this.spinnersMouseDown = function (event) {
            /* do not steal focus from input when changing value with spinners */
            event.preventDefault();
            /* manually focus the input in case the user clicks the spinners first */
            if (_this.element && document.activeElement !== _this.element) {
                _this.element.focus({ preventScroll: true });
            }
        };
        _this.handlePaste = function () {
            _this.paste = true;
        };
        _this.elementChange = function (event) {
            if (!_this.element || !_this.kendoDate) {
                return;
            }
            if (_this.paste) {
                _this.updateOnPaste(event);
                _this.paste = false;
                return;
            }
            var _a = _this.kendoDate.getTextAndFormat(), text = _a.text, currentFormat = _a.format;
            _this.currentFormat = currentFormat;
            var dateBeforeChange = _this.value;
            var diff = approximateStringMatching(text, _this.currentFormat, _this.element.value, _this.selection.start);
            var navigationOnly = (diff.length === 1 && diff[0][1] === '_');
            if (!navigationOnly) {
                for (var i = 0; i < diff.length; i++) {
                    _this.kendoDate.parsePart(diff[i][0], diff[i][1]);
                }
            }
            if (diff.length && diff[0][0] !== '_') {
                _this.setSelection(_this.selectionBySymbol(diff[0][0]));
            }
            if (navigationOnly) {
                _this.switchDateSegment(1);
            }
            _this.triggerChange(event, dateBeforeChange);
        };
        _this.elementClick = function (_) {
            _this.setSelection(_this.selectionByIndex(_this.selection.start));
        };
        _this.nativeWheel = function (event) {
            if (document.activeElement === _this.element) {
                event.preventDefault();
            }
        };
        _this.wheel = function (event) {
            if (document.activeElement !== _this.element) {
                return;
            }
            if (event.nativeEvent.deltaY < 0) {
                _this.increasePart(event);
            }
            if (event.nativeEvent.deltaY > 0) {
                _this.decreasePart(event);
            }
        };
        _this.increasePart = function (event) {
            _this.modifyDateSegmentValue(1, event);
        };
        _this.decreasePart = function (event) {
            _this.modifyDateSegmentValue(-1, event);
        };
        _this.elementKeyDown = function (event) {
            if (event.altKey) {
                return;
            }
            switch (event.keyCode) {
                case 37:
                    /*
                        * Key: `Left Arrow`
                        * Action: Switches to previous logical* segment.
                        * (*) https://www.w3.org/International/articles/inline-bidi-markup/uba-basics
                        */
                    _this.switchDateSegment(-1);
                    break;
                case (38):
                    /*
                        * Key: `Up Arrow`
                        * Action: Increases the currently selected segment value.
                        */
                    _this.modifyDateSegmentValue(1, event);
                    break;
                case 39:
                    /*
                        * Key: `Right Arrow`
                        * Action: Switches to the next logical segment.
                        */
                    _this.switchDateSegment(1);
                    break;
                case 40:
                    /*
                        * Key: `Down Arrow`
                        * Action: Decreases the currently selected segment value.
                        */
                    _this.modifyDateSegmentValue(-1, event);
                    break;
                default:
                    /*
                        * Key: any
                        * Action: Does not prevent the default behavior.
                        */
                    return;
            }
            event.preventDefault();
        };
        _this.elementOnFocus = function (_) {
            if (_this.wrapper) {
                _this.wrapper.classList.add('k-focus');
            }
            _this.setState({ focused: true });
        };
        _this.elementOnBlur = function (_) {
            if (_this.wrapper) {
                _this.wrapper.classList.remove('k-focus');
            }
            _this.setState({ focused: false });
        };
        validatePackage(packageMetadata);
        _this.state = {
            focused: false
        };
        return _this;
    }
    /**
     * @hidden
     */
    DateInputWithoutContext.prototype.componentDidMount = function () {
        this.setValidity();
        if (this.wrapper) {
            this.wrapper.addEventListener('wheel', this.nativeWheel, { passive: false });
        }
    };
    /**
     * @hidden
     */
    DateInputWithoutContext.prototype.componentDidUpdate = function (_, prevState) {
        if (this._lastSelectedSymbol && prevState.focused === this.state.focused) {
            this.setSelection(this.selectionBySymbol(this._lastSelectedSymbol));
        }
        else if (this.props.placeholder !== undefined && this.selection.start === this.selection.end) {
            this.setSelection({ start: 0, end: this.currentFormat.length });
        }
        this.setValidity();
    };
    /**
     * @hidden
     */
    DateInputWithoutContext.prototype.componentWillUnmount = function () {
        if (this.wrapper) {
            this.wrapper.removeEventListener('wheel', this.nativeWheel);
        }
    };
    /**
     * @hidden
     */
    DateInputWithoutContext.prototype.render = function () {
        var _this = this;
        var _a = this.props, _b = _a.size, size = _b === void 0 ? DateInputWithoutContext.defaultProps.size : _b, _c = _a.fillMode, fillMode = _c === void 0 ? DateInputWithoutContext.defaultProps.fillMode : _c, _d = _a.rounded, rounded = _d === void 0 ? DateInputWithoutContext.defaultProps.rounded : _d;
        if (this.props._ref) {
            this.props._ref.current = this;
        }
        var localizationService = provideLocalizationService(this);
        var props = __assign(__assign({}, DateInputWithoutContext.defaultProps), this.props);
        var name = props.name, label = props.label, id = props.id;
        var currentText = this.text;
        var text = currentText === this.props.placeholder ? '' : currentText;
        var placeholder = currentText === this.props.placeholder ? currentText : undefined;
        var inputId = id || this._inputId;
        var isValid = !this.validityStyles || this.validity.valid;
        var wrapperClassesInstance = __spreadArray([], wrapperClasses, true);
        if (this.props.className) {
            wrapperClassesInstance.push(this.props.className);
        }
        var dateinput = (React.createElement(AsyncFocusBlur, { onFocus: this.elementOnFocus, onBlur: this.elementOnBlur }, function (_a) {
            var _b;
            var onFocus = _a.onFocus, onBlur = _a.onBlur;
            return (React.createElement("span", { ref: function (span) { _this._wrapper = span; }, style: !label
                    ? { width: _this.props.width }
                    : undefined, dir: _this.props.dir, className: classNames('k-dateinput', 'k-input', (_b = {},
                    _b["k-input-".concat(kendoThemeMaps.sizeMap[size] || size)] = size,
                    _b["k-input-".concat(fillMode)] = fillMode,
                    _b["k-rounded-".concat(kendoThemeMaps.roundedMap[rounded] || rounded)] = rounded,
                    _b['k-invalid'] = !isValid,
                    _b['k-required'] = _this.required,
                    _b['k-disabled'] = _this.props.disabled,
                    _b), _this.props.className), onFocus: onFocus, onBlur: onBlur },
                React.createElement("input", { role: _this.props.ariaRole || 'textbox', tabIndex: _this.props.tabIndex, disabled: _this.props.disabled, title: _this.props.title !== undefined ? _this.props.title : currentText, type: "text", spellCheck: false, autoComplete: "off", autoCorrect: "off", className: "k-input-inner", id: inputId, "aria-label": _this.props.ariaLabel, "aria-labelledby": _this.props.ariaLabelledBy, "aria-describedby": _this.props.ariaDescribedBy, "aria-haspopup": _this.props.ariaHasPopup, "aria-disabled": _this.props.disabled, "aria-expanded": _this.props.ariaExpanded, "aria-controls": _this.props.ariaControls, onWheel: _this.wheel, onClick: _this.elementClick, onInput: _this.elementChange, onPaste: _this.handlePaste, onKeyDown: _this.elementKeyDown, onChange: noop, value: text, placeholder: placeholder, name: name, ref: function (input) { return _this._element = input; } }),
                _this.props.children,
                _this.props.spinners && (React.createElement("span", { className: "k-input-spinner k-spin-button", onMouseDown: _this.spinnersMouseDown },
                    React.createElement(Button, { tabIndex: -1, type: "button", rounded: null, className: "k-spinner-increase", icon: 'caret-alt-up', "aria-label": localizationService.toLanguageString(increaseValue, messages[increaseValue]), title: localizationService.toLanguageString(increaseValue, messages[increaseValue]), onClick: _this.increasePart }),
                    React.createElement(Button, { tabIndex: -1, type: "button", rounded: null, className: "k-spinner-decrease", icon: 'caret-alt-down', "aria-label": localizationService.toLanguageString(decreaseValue, messages[decreaseValue]), title: localizationService.toLanguageString(decreaseValue, messages[decreaseValue]), onClick: _this.decreasePart })))));
        }));
        return label
            ? (React.createElement(FloatingLabel, { label: label, editorId: inputId, editorValue: currentText, editorValid: isValid, editorDisabled: this.props.disabled, children: dateinput, style: { width: this.props.width } }))
            : dateinput;
    };
    Object.defineProperty(DateInputWithoutContext.prototype, "value", {
        /* Public Getters */
        /**
         * Gets the value of the DateInput.
         */
        get: function () {
            if (this.valueDuringOnChange !== undefined) {
                return this.valueDuringOnChange;
            }
            return this.kendoDate && this.kendoDate.getDateObject();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateInputWithoutContext.prototype, "name", {
        /**
         * Gets the `name` property of the DateInput.
         */
        get: function () {
            return this.props.name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateInputWithoutContext.prototype, "min", {
        get: function () {
            return this.props.min !== undefined
                ? this.props.min
                : DateInputWithoutContext.defaultProps.min;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateInputWithoutContext.prototype, "max", {
        get: function () {
            return this.props.max !== undefined
                ? this.props.max
                : DateInputWithoutContext.defaultProps.max;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateInputWithoutContext.prototype, "text", {
        /**
         * @hidden
         */
        get: function () {
            var props = __assign(__assign({}, DateInput.defaultProps), this.props);
            var _a = props.formatPlaceholder, formatPlaceholder = _a === void 0 ? DateInputWithoutContext.defaultProps.formatPlaceholder : _a, _b = props.format, format = _b === void 0 ? DateInputWithoutContext.defaultProps.format : _b, value = props.value, defaultValue = props.defaultValue;
            if (this.kendoDate === null) {
                this.kendoDate = new KendoDate(this.intl.bind(this), formatPlaceholder, format);
                this.kendoDate.setValue(value || defaultValue || null);
            }
            else {
                this.kendoDate.format = format;
                this.kendoDate.formatPlaceholder = formatPlaceholder;
            }
            if (value !== undefined && this.value !== value) {
                this.kendoDate.setValue(value);
            }
            var _c = this.kendoDate.getTextAndFormat(), currentText = _c.text, currentFormat = _c.format;
            this.currentFormat = currentFormat;
            return props.placeholder !== null && props.placeholder !== undefined
                && !this.state.focused
                && !this.kendoDate.hasValue()
                ? props.placeholder
                : currentText;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateInputWithoutContext.prototype, "validity", {
        /**
         * Represents the validity state into which the DateInput is set.
         */
        get: function () {
            var inRange = isInRange(this.value, this.min, this.max)
                && isInTimeRange(this.value, this.props.minTime, this.props.maxTime);
            var customError = this.props.validationMessage !== undefined;
            var isValid = (!this.required || this.value !== null) && inRange;
            var valid = this.props.valid !== undefined ? this.props.valid : isValid;
            return {
                customError: customError,
                rangeOverflow: (this.value && this.max.getTime() < this.value.getTime()) || false,
                rangeUnderflow: (this.value && this.value.getTime() < this.min.getTime()) || false,
                valid: valid,
                valueMissing: this.value === null
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateInputWithoutContext.prototype, "element", {
        /**
         * Gets the element of the DateInput.
         *
         * @return - An `HTMLInputElement`.
         *
         * @example
         * ```jsx
         * class App extends React.Component {
         *     constructor(props) {
         *         super(props);
         *     }
         *     element = null;
         *     render() {
         *         return (
         *             <div>
         *                 <DateInput
         *                     ref={(dateInput) =>
         *                         this.element = dateInput ? dateInput.element : null}
         *                 />
         *                 <button onClick={() => console.log(this.element)}>console.log the element</button>
         *             </div>
         *         );
         *     }
         * }
         *
         * ReactDOM.render(
         *     <App />,
         *     document.getElementsByTagName('my-app')[0]
         * );
         * ```
         */
        get: function () {
            return this._element;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateInputWithoutContext.prototype, "validityStyles", {
        /**
         * @hidden
         */
        get: function () {
            return this.props.validityStyles !== undefined
                ? this.props.validityStyles
                : DateInputWithoutContext.defaultProps.validityStyles;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateInputWithoutContext.prototype, "required", {
        /**
         * @hidden
         */
        get: function () {
            return this.props.required !== undefined
                ? this.props.required
                : DateInputWithoutContext.defaultProps.required;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateInputWithoutContext.prototype, "wrapper", {
        /**
         * @hidden
         */
        get: function () {
            return this._wrapper;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    DateInputWithoutContext.prototype.intl = function () {
        return provideIntlService(this);
    };
    Object.defineProperty(DateInputWithoutContext.prototype, "selection", {
        /*  end handlers */
        get: function () {
            var returnValue = { start: 0, end: 0 };
            if (this.element !== null && this.element.selectionStart !== undefined) {
                returnValue = { start: this.element.selectionStart, end: this.element.selectionEnd };
            }
            return returnValue;
        },
        enumerable: false,
        configurable: true
    });
    DateInputWithoutContext.prototype.setSelection = function (selection) {
        var _this = this;
        this._lastSelectedSymbol = this.currentFormat[selection.start];
        window.requestAnimationFrame(function () {
            if (_this.element && document.activeElement === _this.element) {
                _this.element.setSelectionRange(selection.start, selection.end);
            }
        });
    };
    DateInputWithoutContext.prototype.triggerChange = function (event, oldValue) {
        this.valueDuringOnChange = this.value;
        this.forceUpdate();
        if (this.props.onChange
            && !isEqual(oldValue, this.value)) {
            // isEqual works with null
            this.props.onChange.call(undefined, {
                syntheticEvent: event,
                nativeEvent: event.nativeEvent,
                value: this.value,
                target: this
                // inRange: this.props.min && this.props.max ? isInRange(value, this.props.min, this.props.max) : true
            });
        }
        this.valueDuringOnChange = undefined;
    };
    DateInputWithoutContext.prototype.selectionBySymbol = function (symbol) {
        var start = -1;
        var end = 0;
        for (var i = 0; i < this.currentFormat.length; i++) {
            if (this.currentFormat[i] === symbol) {
                end = i + 1;
                if (start === -1) {
                    start = i;
                }
            }
        }
        if (start < 0) {
            start = 0;
        }
        return { start: start, end: end };
    };
    DateInputWithoutContext.prototype.selectionByIndex = function (index) {
        // console.log(42, index);
        var selection = { start: index, end: index };
        for (var i = index, j = index - 1; i < this.currentFormat.length || j >= 0; i++, j--) {
            if (i < this.currentFormat.length && this.currentFormat[i] !== '_') {
                selection = this.selectionBySymbol(this.currentFormat[i]);
                break;
            }
            if (j >= 0 && this.currentFormat[j] !== '_') {
                selection = this.selectionBySymbol(this.currentFormat[j]);
                break;
            }
        }
        return selection;
    };
    DateInputWithoutContext.prototype.switchDateSegment = function (offset) {
        var _a = this.selection, selectionStart = _a.start, selectionEnd = _a.end;
        if (selectionStart < selectionEnd &&
            this.currentFormat[selectionStart] !== this.currentFormat[selectionEnd - 1]) {
            this.setSelection(this.selectionByIndex(offset > 0 ? selectionStart : selectionEnd - 1));
            return;
        }
        var previousFormatSymbol = this.currentFormat[selectionStart];
        var a = selectionStart + offset;
        while (a > 0 && a < this.currentFormat.length) {
            if (this.currentFormat[a] !== previousFormatSymbol &&
                this.currentFormat[a] !== '_') {
                break;
            }
            a += offset;
        }
        if (this.currentFormat[a] === '_') {
            // no known symbol is found
            return;
        }
        var b = a;
        while (b >= 0 && b < this.currentFormat.length) {
            if (this.currentFormat[b] !== this.currentFormat[a]) {
                break;
            }
            b += offset;
        }
        if (a > b && (b + 1 !== selectionStart || a + 1 !== selectionEnd)) {
            this.setSelection({ start: b + 1, end: a + 1 });
        }
        else if (a < b && (a !== selectionStart || b !== selectionEnd)) {
            this.setSelection({ start: a, end: b });
        }
    };
    DateInputWithoutContext.prototype.modifyDateSegmentValue = function (offset, event) {
        if (!this.kendoDate) {
            return;
        }
        var oldValue = this.value;
        var symbol = this.currentFormat[this.selection.start];
        var currentStepSymbol = this.kendoDate.symbolMap(symbol);
        var step = ((this.props.steps || {})[currentStepSymbol] || 1) * offset;
        this.kendoDate.modifyPart(symbol, step);
        this.triggerChange(event, oldValue);
    };
    /**
     * @hidden
     */
    DateInputWithoutContext.displayName = 'DateInput';
    /**
     * @hidden
     */
    DateInputWithoutContext.propTypes = {
        value: PropTypes.instanceOf(Date),
        format: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                skeleton: PropTypes.string,
                pattern: PropTypes.string,
                date: PropTypes.oneOf(['short', 'medium', 'long', 'full']),
                time: PropTypes.oneOf(['short', 'medium', 'long', 'full']),
                datetime: PropTypes.oneOf(['short', 'medium', 'long', 'full']),
                era: PropTypes.oneOf(['narrow', 'short', 'long']),
                year: PropTypes.oneOf(['numeric', '2-digit']),
                month: PropTypes.oneOf(['numeric', '2-digit', 'narrow', 'short', 'long']),
                day: PropTypes.oneOf(['numeric', '2-digit']),
                weekday: PropTypes.oneOf(['narrow', 'short', 'long']),
                hour: PropTypes.oneOf(['numeric', '2-digit']),
                hour12: PropTypes.bool,
                minute: PropTypes.oneOf(['numeric', '2-digit']),
                second: PropTypes.oneOf(['numeric', '2-digit']),
                timeZoneName: PropTypes.oneOf(['short', 'long'])
            })
        ]),
        formatPlaceholder: PropTypes.oneOfType([
            PropTypes.oneOf(['wide', 'narrow', 'short', 'formatPattern']),
            PropTypes.shape({
                year: PropTypes.string,
                month: PropTypes.string,
                day: PropTypes.string,
                hour: PropTypes.string,
                minute: PropTypes.string,
                second: PropTypes.string
            })
        ]),
        width: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        tabIndex: PropTypes.number,
        title: PropTypes.string,
        steps: PropTypes.shape({
            year: PropTypes.number,
            month: PropTypes.number,
            day: PropTypes.number,
            hour: PropTypes.number,
            minute: PropTypes.number,
            second: PropTypes.number
        }),
        min: PropTypes.instanceOf(Date),
        max: PropTypes.instanceOf(Date),
        disabled: PropTypes.bool,
        spinners: PropTypes.bool,
        name: PropTypes.string,
        dir: PropTypes.string,
        label: PropTypes.string,
        id: PropTypes.string,
        ariaLabelledBy: PropTypes.string,
        ariaDescribedBy: PropTypes.string,
        ariaLabel: PropTypes.string,
        ariaRole: PropTypes.string,
        ariaExpanded: PropTypes.oneOfType([
            PropTypes.bool
        ]),
        onChange: PropTypes.func,
        validationMessage: PropTypes.string,
        required: PropTypes.bool,
        validate: PropTypes.bool,
        valid: PropTypes.bool,
        size: PropTypes.oneOf([null, 'small', 'medium', 'large']),
        rounded: PropTypes.oneOf([null, 'small', 'medium', 'large', 'full']),
        fillMode: PropTypes.oneOf([null, 'solid', 'flat', 'outline'])
    };
    /**
     * @hidden
     */
    DateInputWithoutContext.defaultProps = {
        format: defaultFormat,
        size: 'medium',
        rounded: 'medium',
        fillMode: 'solid',
        formatPlaceholder: defaultFormatPlaceholder,
        // defaultValue: null as Date | null,
        spinners: false,
        disabled: false,
        max: cloneDate(MAX_DATE),
        min: cloneDate(MIN_DATE),
        minTime: cloneDate(MIN_TIME),
        maxTime: cloneDate(MAX_TIME),
        required: false,
        validityStyles: true,
        validationMessage: VALIDATION_MESSAGE,
        placeholder: null
        // the rest of the properties are undefined by default
    };
    return DateInputWithoutContext;
}(React.Component));
export { DateInputWithoutContext };
/**
 * Represents the PropsContext of the `DateInput` component.
 * Used for global configuration of all `DateInput` instances.
 *
 * For more information, refer to the [DateInputs Props Context]({% slug props-context_dateinputs %}) article.
 */
export var DateInputPropsContext = createPropsContext();
;
/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the component the same as the type */
/**
 * Represents the KendoReact DateInput Component.
 *
 * Accepts properties of type [DateInputProps]({% slug api_dateinputs_dateinputprops %}).
 * Obtaining the `ref` returns an object of type [DateInputHandle]({% slug api_dateinputs_dateinputhandle %}).
 */
export var DateInput = withPropsContext(DateInputPropsContext, DateInputWithoutContext);
DateInput.displayName = 'KendoReactDateInput';
registerForIntl(DateInputWithoutContext);
registerForLocalization(DateInputWithoutContext);
