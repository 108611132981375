"use strict";

import { ServiceBase } from "../../../../../../services/service_base";

export class ConfigurableTableService extends ServiceBase {
  processBulkEdit(payload) {
    const parameters = {
      action: "bulkEdit",
      useTwoWayCommunication: true,
    };
    return this.sendToBackend(payload, parameters);
  }

}