"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import ImportDataManipulationTable from "../widgets/dataImportWizardStep/import_data_manipulation_table";
import ImportBaseStep from "./import_base_step";
import { IMPORT_STEPS, SELECTED_CONTENT } from "../constants/import_constants";
import * as ImportHelper from "../helpers/import_helper";
import ETLData from "../widgets/smartUpload/extractAndEditWizardStep/etl_data";
import ContinuousDataReviewPanel from "../widgets/dataImportWizardStep/continuousData/continuous_data_review_panel";
import { StatusDisplayService } from "../../services/status_display_service";
import { ImportMessageHandler } from "../../services/import/import_message_handler";
import { ImportService } from "../../services/import/import_service";

/**
 * This implements the Import wizard data import step
 */
export default class ImportDataStep extends ImportBaseStep {
  constructor(props) {
    super(props);

    this.setStateSafely({
      objectsToImport: [],
      importIsRunning: false,
    });

    this.statusService = new StatusDisplayService();
    this.messageService = new ImportMessageHandler(this.statusService);
    this.importService = new ImportService(this.messageService, this.statusService);
  }

  handleMoveToNextStep() {
    const {wizardStepsConfig, config, totalSteps} = this.props;
    const {importIsRunning} = this.state;

    let currentStepIndex = wizardStepsConfig.indexOf(config);

    // This is for making sure the user can't click the button multiple times
    if (!importIsRunning && totalSteps === currentStepIndex + 1) {
      this.setStateSafely({
        importIsRunning: true
      }, async() => {
        try {
          await this.importAsDraft();
        } finally {
          this.setStateSafely({
            importIsRunning: false
          });
        }
      });
    }
  }

  activate(changeInfo) {
    const {isPaperImport, hasPermissionToImport, onWizardStepDataUpdated, importContinuousData, records} = this.props;

    if (hasPermissionToImport) {
      if (isPaperImport) {
        this.activatePaperImport(changeInfo);
      } else {
        onWizardStepDataUpdated({currentImportStep: IMPORT_STEPS.DataImport}, () => {
          super.activate(changeInfo);

          if (importContinuousData) {
            this.setStateSafely({
              objectsToImport: records
            });
          }
        });
      }
    }
  }

  activatePaperImport(changeInfo) {
    const {
      fileData, projectId, projectType, dependency, dataSectionName, modelName, onWizardStepDataUpdated
    } = this.props;
    const {batchData, dataAttributes, userUpdates} = this.props.etlData;
    let newETLData = new ETLData({batchData, dataAttributes, userUpdates});

    UIUtils.secureAjaxPUT("import/validate/validateETLData", {
      fileData: JSON.stringify(fileData),
      projectId,
      projectType,
      dependency,
      model: modelName,
      dataSectionName,
      etlData: JSON.stringify(newETLData)
    }, true, this.defaultFailFunction.bind(this, changeInfo)).done(sheetData => {
      let validations = sheetData.validations;
      let records = sheetData.objects;

      let validationHasErrors = validations.reduce((hasError, validation) => {
        return hasError || validation.errors.length > 0;
      }, false);

      // Update the attribute errors
      for (let validation of validations) {
        if (validation.name.match(/(\w+-\d+) - (.*)/)) {
          newETLData.setAttributeError(validation.name, validation);
        }
      }

      // Update the batch properties errors
      const batchPropertiesWithVerificationErrors = ["batchId", "startDate", "manufactureDate", "releaseDate",
        "expirationDate"];
      for (let property of batchPropertiesWithVerificationErrors) {
        let validationError = validations.find(validation => validation.name === property);
        newETLData.addBatchInfoError(validationError, property);
      }

      /*Remove from the validation errors the ones already set on the etlData object as those are already
        shown through of the error icons on the data fields
       */
      validations = validations.filter(validation => !validation.name.match(/(\w+-\d+) - (.*)/)
        && validation.name !== "batchId"
        && validation.name !== "startDate"
        && validation.name !== "manufactureDate"
        && validation.name !== "releaseDate"
        && validation.name !== "expirationDate");

      const etlDataHasErrors = newETLData.hasErrors();
      let stepError = "";

      if (!validationHasErrors && !etlDataHasErrors) {
        UIUtils.clearError();
        super.activate(changeInfo);
      } else if (validationHasErrors || etlDataHasErrors) {
        let errors = validations.reduce((errors, validation) => {
          errors = errors.concat(validation.errors);
          return errors;
        }, []);

        if (errors.length > 0) {
          stepError = errors.join("\n");
        } else {
          stepError = "Please fix all the errors to proceed.";
        }

        this.deactivate(changeInfo.previousStep);
      }

      onWizardStepDataUpdated({
        recordsCount: sheetData.recordsCount,
        validations,
        records,
        etlData: newETLData,
        currentImportStep: validationHasErrors || etlDataHasErrors ? IMPORT_STEPS.DataExtract : IMPORT_STEPS.DataImport,
        stepError
      }, () => {
        this.setStateSafely({
          objectsToImport: this.props.records
        });
      });
    });
  }

  defaultFailFunction(changeInfo, result, textStatus, errorThrown, alertDiv) {
    // So the alert shows up.
    this.deactivate(changeInfo.previousStep);
    UIUtils.defaultFailFunction(result, textStatus, errorThrown, alertDiv);
  }

  handleObjectsSelectionForImport(selectedRecords) {
    const {objectsToImport} = this.state;

    if (selectedRecords.length !== objectsToImport.length) {
      this.setStateSafely({
        objectsToImport: selectedRecords
      });
    }
  }

  async importAsDraft() {
    const {
      modelName, records, fileData, supportFileData, projectId, dependency, dataSectionName, isPaperImport,
      modelNameForSecurity, onImportCompleted, selectedDependencyRecord, etlData, originalETLData, selectedContent,
      process, selectedBatch, importType, batches
    } = this.props;
    const {objectsToImport} = this.state;

    if (objectsToImport.length > 0) {
      let modelToImport;
      if (modelName === "Attribute") {
        let key = UIUtils.parseKey(objectsToImport[0].attributeID);
        modelToImport = UIUtils.convertToId(UIUtils.getModelNameForTypeCode(key.typeCode));
      } else {
        modelToImport = UIUtils.capitalize(modelName);
      }
      let smartImportUserStatistics = {};
      if (isPaperImport) {
        smartImportUserStatistics = etlData.getAggregateUsageStatistics(originalETLData.batchData);
        smartImportUserStatistics.usesResultsColumns = selectedContent === SELECTED_CONTENT.RESULTS_COLUMN;

        for (let objectToImport of objectsToImport) {
          objectToImport.supplierId = etlData.batchData?.batchInfo?.supplierId;
        }
      }

      let payload = {
        modelName: modelToImport,
        objectsToImport: JSON.stringify(objectsToImport),
        allRecords: JSON.stringify(records),
        fileData: JSON.stringify(this.getFileDataForSave(fileData)),
        supportFileData: JSON.stringify(this.getFileDataForSave(supportFileData)),
        smartImportUserStatistics,
        projectId,
        processId: process && process.id,
        batchId: selectedBatch && selectedBatch.customID,
        importType,
        dependency,
        dataSectionName,
        isPaperImport,
        selectedDependencyRecord: selectedDependencyRecord
          ? typeof selectedDependencyRecord === "string"
            ? selectedDependencyRecord
            : selectedDependencyRecord.label
          : null,
      };

      try {
        const result = await this.importService.saveRecords(payload);

        // Uncomment for verbose logging
        console.log("Result of import: ", result);
        result.importedRecords = ImportHelper.fixDatesForDisplay(modelNameForSecurity, result.importedRecords);
        result.batches = batches;
        onImportCompleted(fileData, result);
      } catch (e) {
        UIUtils.defaultFailFunction(e);
      }
    } else {
      UIUtils.showError("You need to select at least one record to import.");
    }
  }

  getFileDataForSave(fileData) {
    return [{
      fileName: fileData.fileName,
      name: fileData.fileName,
      uuid: fileData.uuid,
      description: "",
      index: 0,
      appliesTo: [],
      S3TmpVersion: fileData.S3TmpVersion,
      S3TmpKey: fileData.S3TmpKey,
      link: fileData.link,
      linkVersion: fileData.linkVersion,
      linkType: fileData.linkType
    }];
  }

  renderStep() {
    let {
      records, batches, modelName, isPaperImport, importContinuousData
    } = this.props;
    let nextStepDisabled = this.isNextStepDisabled();
    const batch = batches && batches[0] || {};

    return (
      <Fragment>
        <div className="col-12">
          <div className="row import-wizard-step-inner-container">
            <div className="col-12">
              <div className="import-data-review-table-div">
                {importContinuousData ? (
                  <ContinuousDataReviewPanel attributes={records}
                                             batchInfo={batch}
                  />
                ) : (
                  <ImportDataManipulationTable
                    key="dataReviewTable"
                    id="importDataReview"
                    records={records}
                    enableSingleSelection={false}
                    modelName={modelName}
                    onObjectsSelection={this.handleObjectsSelectionForImport}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 import-screen-footer">
          <button disabled={nextStepDisabled}
                  className="btn btn-lg btn-primary import-footer-btn import-footer-btn-fixed"
                  id="importAsDraftButton"
                  onClick={this.handleMoveToNextStep}
          >
            {modelName === "User" ? "Import" : "Import as draft"}
          </button>
          <button disabled={false}
                  className="btn btn-lg btn-primary import-footer-btn import-footer-btn-fixed"
                  id="importDataStepPreviousButton"
                  onClick={this.handleMoveToPreviousStep}
          >
            {isPaperImport ? "< Back" : "< Review Data"}
          </button>
          {this.renderImportExitButton()}
        </div>
      </Fragment>
    );
  }
}
