"use strict";

import React from "react";
// noinspection NpmUsedModulesInstalled
import * as PropTypes from "prop-types";

import CommonEditablesPageTitleBar from "../pageTitleBar/common_editables_page_title_bar";
import ErrorBar from "../bars/error_bar";
import TopNavBar from "../bars/top_nav_bar";
import BaseReactComponent from "../../base_react_component";


export default class PageFrameLayout extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      title,
      tabs,
      selectedTab,
      showLinksInTitleBar,
      recordName,
      urlParams,
      renderButton,
      children,
    } = this.props;

    return (
      <div>
        <CommonEditablesPageTitleBar
          name={title}
          hasNoLinksInTitleBar={!showLinksInTitleBar}
          recordName={recordName}
          renderButton={renderButton}
        />
        <TopNavBar
          items={tabs}
          selected={selectedTab}
          urlParams={urlParams}
        />
        <div className="container pb-3">
          <ErrorBar className="page-frame-layout-error-bar" />
          {children}
        </div>
      </div>
    );
  }
}

PageFrameLayout.propTypes = {
  title: PropTypes.string,
  editableName: PropTypes.string,
  tabs: PropTypes.array,
  selectedTab: PropTypes.any,
  showLinksInTitleBar: PropTypes.bool,
  recordName: PropTypes.string,
  urlParams: PropTypes.any,
  renderButton: PropTypes.func,
  children: PropTypes.any,
};

PageFrameLayout.defaultProps = {
  editableName: "",
  tabs: [],
  selectedTab: null,
  showLinksInTitleBar: false,
  recordName: "",
  urlParams: {},
  renderButton: () => "",
};
