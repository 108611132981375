var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { keys, messages } from './../messages';
import { bold as boldSettings, italic as italicSettings, underline as underlineSettings, strikethrough as strikethroughSettings, subscript as subscriptSettings, superscript as superscriptSettings, link as linkSettings, alignLeftRules, alignRightRules, alignCenterRules, alignJustifyRules, alignRemoveRules, indentRules, outdentRules } from '@progress/kendo-editor-common';
/**
 * @hidden
 */
export var listsTypes = __assign({}, indentRules.listsTypes);
/**
 * Represents a wrapping namespace for the tool settings of the Editor.
 */
export var EditorToolsSettings;
(function (EditorToolsSettings) {
    /**
     * @hidden
     */
    var buttonCommonProps = {
        type: 'button'
    };
    /**
     * The object of the Bold tool settings.
     */
    EditorToolsSettings.bold = __assign(__assign({}, boldSettings), { props: __assign({ icon: 'bold' }, buttonCommonProps), messages: {
            title: keys.bold
        }, commandName: 'Bold' });
    /**
     * The object of the Italic tool settings.
     */
    EditorToolsSettings.italic = __assign(__assign({}, italicSettings), { props: __assign({ icon: 'italic' }, buttonCommonProps), messages: {
            title: keys.italic
        }, commandName: 'Italic' });
    /**
     * The object of the Underline tool settings.
     */
    EditorToolsSettings.underline = __assign(__assign({}, underlineSettings), { props: __assign({ icon: 'underline' }, buttonCommonProps), messages: {
            title: keys.underline
        }, commandName: 'Underline' });
    /**
     * The object of the Strikethrough tool settings.
     */
    EditorToolsSettings.strikethrough = __assign(__assign({}, strikethroughSettings), { props: __assign({ icon: 'strikethrough' }, buttonCommonProps), messages: {
            title: keys.strikethrough
        }, commandName: 'Strikethrough' });
    /**
     * The object of the Subscript tool settings.
     */
    EditorToolsSettings.subscript = __assign(__assign({}, subscriptSettings), { props: __assign({ icon: 'subscript' }, buttonCommonProps), messages: {
            title: keys.subscript
        }, commandName: 'Subscript' });
    /**
     * The object of the Superscript tool settings.
     */
    EditorToolsSettings.superscript = __assign(__assign({}, superscriptSettings), { props: __assign({ icon: 'superscript' }, buttonCommonProps), messages: {
            title: keys.superscript
        }, commandName: 'Superscript' });
    /**
     * The object of the Link tool settings.
     */
    EditorToolsSettings.link = __assign(__assign({}, linkSettings), { props: __assign({ icon: 'link-horizontal' }, buttonCommonProps), messages: {
            insertHyperlink: keys.insertHyperlink,
            insertHyperlinkDialogTitle: keys.insertHyperlinkDialogTitle,
            insertHyperlinkAddress: keys.insertHyperlinkAddress,
            insertHyperlinkTitle: keys.insertHyperlinkTitle,
            insertHyperlinkNewWindow: keys.insertHyperlinkNewWindow,
            insertHyperlinkCancel: keys.insertHyperlinkCancel,
            insertHyperlinkInsert: keys.insertHyperlinkInsert
        }, commandName: 'Link' });
    /**
     * The object of the Link tool settings.
     */
    EditorToolsSettings.insertFile = __assign(__assign({}, linkSettings), { props: __assign({ icon: 'file-add' }, buttonCommonProps), messages: {
            insertHyperlink: keys.insertFile,
            insertHyperlinkDialogTitle: keys.insertFileDialogTitle,
            insertHyperlinkAddress: keys.insertFileAddress,
            insertHyperlinkTitle: keys.insertFileTitle,
            insertHyperlinkNewWindow: '',
            insertHyperlinkCancel: keys.insertFileCancel,
            insertHyperlinkInsert: keys.insertFileInsert
        }, commandName: 'InsertFile', linkTarget: false });
    /**
     * The object of the InsertTable tool settings.
     */
    EditorToolsSettings.insertTable = {
        props: __assign({ icon: 'table-insert' }, buttonCommonProps),
        messages: {
            createTable: keys.createTable,
            createTableHint: keys.createTableHint
        },
        commandName: 'InsertTable'
    };
    /**
     * The object of the AddRowBefore tool settings.
     */
    EditorToolsSettings.addRowBefore = {
        props: __assign({ icon: 'table-row-insert-above' }, buttonCommonProps),
        messages: {
            title: keys.addRowBefore
        },
        commandName: 'AddRowBefore'
    };
    /**
     * The object of the AddRowAfter tool settings.
     */
    EditorToolsSettings.addRowAfter = {
        props: __assign({ icon: 'table-row-insert-below' }, buttonCommonProps),
        messages: {
            title: keys.addRowAfter
        },
        commandName: 'AddRowAfter'
    };
    /**
     * The object of the AddColumnBefore tool settings.
     */
    EditorToolsSettings.addColumnBefore = {
        props: __assign({ icon: 'table-column-insert-left' }, buttonCommonProps),
        messages: {
            title: keys.addColumnBefore
        },
        commandName: 'AddColumnBefore'
    };
    /**
     * The object of the AddColumnAfter tool settings.
     */
    EditorToolsSettings.addColumnAfter = {
        props: __assign({ icon: 'table-column-insert-right' }, buttonCommonProps),
        messages: {
            title: keys.addColumnAfter
        },
        commandName: 'AddColumnAfter'
    };
    /**
     * The object of the DeleteRow tool settings.
     */
    EditorToolsSettings.deleteRow = {
        props: __assign({ icon: 'table-row-delete' }, buttonCommonProps),
        messages: {
            title: keys.deleteRow
        },
        commandName: 'DeleteRow'
    };
    /**
     * The object of the DeleteColumn tool settings.
     */
    EditorToolsSettings.deleteColumn = {
        props: __assign({ icon: 'table-column-delete' }, buttonCommonProps),
        messages: {
            title: keys.deleteColumn
        },
        commandName: 'DeleteColumn'
    };
    /**
     * The object of the DeleteTable tool settings.
     */
    EditorToolsSettings.deleteTable = {
        props: __assign({ icon: 'table-delete' }, buttonCommonProps),
        messages: {
            title: keys.deleteTable
        },
        commandName: 'DeleteTable'
    };
    /**
     * The object of the MergeCells tool settings.
     */
    EditorToolsSettings.mergeCells = {
        props: __assign({ icon: 'cells-merge' }, buttonCommonProps),
        messages: {
            title: keys.mergeCells
        },
        commandName: 'MergeCells'
    };
    /**
     * The object of the SplitCell tool settings.
     */
    EditorToolsSettings.splitCell = {
        props: __assign({ icon: 'cell-split-horizontally' }, buttonCommonProps),
        messages: {
            title: keys.splitCell
        },
        commandName: 'SplitCell'
    };
    /**
     * The object of the Print tool settings.
     */
    EditorToolsSettings.print = {
        props: __assign({ icon: 'print' }, buttonCommonProps),
        messages: {
            title: keys.print
        }
    };
    /**
     * The object of the Print tool settings.
     */
    EditorToolsSettings.selectAll = {
        props: __assign({ icon: 'select-all' }, buttonCommonProps),
        messages: {
            title: keys.selectAll
        }
    };
    /**
     * The object of the Print tool settings.
     */
    EditorToolsSettings.pdf = {
        props: __assign({ icon: 'pdf' }, buttonCommonProps),
        messages: {
            title: keys.pdf
        }
    };
    /**
     * The PDF export options.
     *
     * const savePdfOptions = {
     *   fileName: 'editor.pdf',
     *   paperSize: 'A4',
     *   margin: '1cm'
     * };
     */
    EditorToolsSettings.savePdfOptions = {
        fileName: 'editor.pdf',
        paperSize: 'A4',
        margin: '1cm'
    };
    /**
     * The object of the CleanFormatting tool settings.
     */
    EditorToolsSettings.cleanFormatting = {
        props: __assign({ icon: 'clear-css' }, buttonCommonProps),
        messages: {
            title: keys.cleanFormatting
        },
        commandName: 'CleanFormatting'
    };
    /**
     * The object of the InsertImage tool settings.
     */
    EditorToolsSettings.image = {
        node: 'image',
        props: __assign({ icon: 'image' }, buttonCommonProps),
        messages: {
            insertImage: keys.insertImage,
            insertImageDialogTitle: keys.insertImageDialogTitle,
            insertImageAddress: keys.insertImageAddress,
            insertImageTitle: keys.insertImageTitle,
            insertImageAltText: keys.insertImageAltText,
            insertImageWidth: keys.insertImageWidth,
            insertImageHeight: keys.insertImageHeight,
            insertImageCancel: keys.insertImageCancel,
            insertImageInsert: keys.insertImageInsert
        },
        commandName: 'InsertImage'
    };
    /**
     * The object of the ViewHtml tool settings.
     */
    EditorToolsSettings.viewHtml = {
        props: __assign({ icon: 'html' }, buttonCommonProps),
        messages: {
            viewHtml: keys.viewHtml,
            viewHtmlDialogTitle: keys.viewHtmlDialogTitle,
            viewHtmlCancel: keys.viewHtmlCancel,
            viewHtmlUpdate: keys.viewHtmlUpdate
        },
        commandName: 'SetContent'
    };
    /**
     * The object of the Find and Replace tool settings.
     */
    EditorToolsSettings.findAndReplace = {
        props: __assign({ icon: 'find' }, buttonCommonProps),
        messages: {
            findReplaceToolTitle: keys.findReplaceToolTitle,
            findReplaceDialogTitle: keys.findReplaceDialogTitle,
            findReplaceTabFind: keys.findReplaceTabFind,
            findReplaceTabReplace: keys.findReplaceTabReplace,
            findReplaceFindWhat: keys.findReplaceFindWhat,
            findReplaceReplaceWith: keys.findReplaceReplaceWith,
            findReplaceReplace: keys.findReplaceReplace,
            findReplaceReplaceAll: keys.findReplaceReplaceAll,
            findReplaceMatchCase: keys.findReplaceMatchCase,
            findReplaceMatchWord: keys.findReplaceMatchWord,
            findReplaceMatchCyclic: keys.findReplaceMatchCyclic,
            findReplaceUseRegExp: keys.findReplaceUseRegExp,
            findReplacePrevMatch: keys.findReplacePrevMatch,
            findReplaceNextMatch: keys.findReplaceNextMatch,
            findReplaceMatches: keys.findReplaceMatches
        }
    };
    /**
     * The object of the Unlink tool settings.
     */
    EditorToolsSettings.unlink = __assign(__assign({}, linkSettings), { props: __assign({ icon: 'unlink-horizontal' }, buttonCommonProps), messages: {
            title: keys.unlink
        }, commandName: 'Unlink' });
    /**
     * The object of the Undo tool settings.
     */
    EditorToolsSettings.undo = {
        props: __assign({ icon: 'undo' }, buttonCommonProps),
        messages: {
            title: keys.undo
        },
        commandName: 'Undo'
    };
    /**
     * The object of the Redo tool settings.
     */
    EditorToolsSettings.redo = {
        props: __assign({ icon: 'redo' }, buttonCommonProps),
        messages: {
            title: keys.redo
        },
        commandName: 'Redo'
    };
    /**
     * The object of the FontSize tool settings.
     */
    EditorToolsSettings.fontSize = {
        style: 'font-size',
        defaultItem: { text: messages[keys.fontSize], value: '', localizationKey: keys.fontSize },
        items: [
            { text: '1 (8pt)', value: 'xx-small' },
            { text: '2 (10pt)', value: 'x-small' },
            { text: '3 (12pt)', value: 'small' },
            { text: '4 (14pt)', value: 'medium' },
            { text: '5 (18pt)', value: 'large' },
            { text: '6 (24pt)', value: 'x-large' },
            { text: '7 (36pt)', value: 'xx-large' }
        ],
        commandName: 'FontSize'
    };
    /**
     * The object of the FontName tool settings.
     */
    EditorToolsSettings.fontName = {
        style: 'font-family',
        defaultItem: { text: messages[keys.fontName], value: '', localizationKey: keys.fontName },
        items: [
            { text: 'Arial', value: 'Arial, Helvetica, sans-serif',
                style: { fontFamily: 'Arial, Helvetica, sans-serif' } },
            { text: 'Courier New', value: '\'Courier New\', Courier, monospace',
                style: { fontFamily: '\'Courier New\', Courier, monospace' } },
            { text: 'Georgia', value: 'Georgia, serif',
                style: { fontFamily: 'Georgia, serif' } },
            { text: 'Impact', value: 'Impact, Charcoal, sans-serif',
                style: { fontFamily: 'Impact, Charcoal, sans-serif' } },
            { text: 'Lucida Console', value: '\'Lucida Console\', Monaco, monospace',
                style: { fontFamily: '\'Lucida Console\', Monaco, monospace' } },
            { text: 'Tahoma', value: 'Tahoma, Geneva, sans-serif',
                style: { fontFamily: 'Tahoma, Geneva, sans-serif' } },
            { text: 'Times New Roman', value: '\'Times New Roman\', Times,serif',
                style: { fontFamily: '\'Times New Roman\', Times,serif' } },
            { text: 'Trebuchet MS', value: '\'Trebuchet MS\', Helvetica, sans-serif',
                style: { fontFamily: '\'Trebuchet MS\', Helvetica, sans-serif' } },
            { text: 'Verdana', value: 'Verdana, Geneva, sans-serif',
                style: { fontFamily: 'Verdana, Geneva, sans-serif' } }
        ],
        commandName: 'FontName'
    };
    /**
     * The object of the FormatBlock tool settings.
     */
    EditorToolsSettings.formatBlock = {
        defaultItem: { text: messages[keys.format], value: '', localizationKey: keys.format },
        items: [
            { text: 'Paragraph', value: 'p', style: { display: 'block', marginLeft: 0 } },
            { text: 'Heading 1', value: 'h1', style: { display: 'block', fontSize: '2em', marginLeft: 0, fontWeight: 'bold' } },
            { text: 'Heading 2', value: 'h2', style: { display: 'block', fontSize: '1.5em', marginLeft: 0, fontWeight: 'bold' } },
            { text: 'Heading 3', value: 'h3', style: { display: 'block', fontSize: '1.17em', marginLeft: 0, fontWeight: 'bold' } },
            { text: 'Heading 4', value: 'h4', style: { display: 'block', fontSize: '1em', marginLeft: 0, fontWeight: 'bold' } },
            { text: 'Heading 5', value: 'h5', style: { display: 'block', fontSize: '0.83em', marginLeft: 0, fontWeight: 'bold' } },
            { text: 'Heading 6', value: 'h6', style: { display: 'block', fontSize: '0.67em', marginLeft: 0, fontWeight: 'bold' } }
        ],
        commandName: 'FormatBlock'
    };
    /**
     * The object of the ForeColor tool settings.
     */
    EditorToolsSettings.foreColor = {
        style: 'color',
        colorPickerProps: {
            icon: 'foreground-color',
            view: 'palette',
            title: keys.foregroundColor
        },
        commandName: 'ForeColor'
    };
    /**
     * The object of the BackColor tool settings.
     */
    EditorToolsSettings.backColor = {
        style: 'background-color',
        colorPickerProps: {
            icon: 'background',
            view: 'palette',
            title: keys.backgroundColor
        },
        commandName: 'BackColor'
    };
    /**
     * The object of the AlignLeft tool settings.
     */
    EditorToolsSettings.alignLeft = {
        actions: __spreadArray([], alignLeftRules, true),
        props: __assign({ icon: 'align-left' }, buttonCommonProps),
        messages: {
            title: keys.alignLeft
        },
        commandName: 'AlignLeft'
    };
    /**
     * The object of the AlignRight tool settings.
     */
    EditorToolsSettings.alignRight = {
        actions: __spreadArray([], alignRightRules, true),
        props: __assign({ icon: 'align-right' }, buttonCommonProps),
        messages: {
            title: keys.alignRight
        },
        commandName: 'AlignRight'
    };
    /**
     * The object of the AlignCenter tool settings.
     */
    EditorToolsSettings.alignCenter = {
        actions: __spreadArray([], alignCenterRules, true),
        props: __assign({ icon: 'align-center' }, buttonCommonProps),
        messages: {
            title: keys.alignCenter
        },
        commandName: 'AlignCenter'
    };
    /**
     * The object of the AlignJustify tool settings.
     */
    EditorToolsSettings.alignJustify = {
        actions: __spreadArray([], alignJustifyRules, true),
        props: __assign({ icon: 'align-justify' }, buttonCommonProps),
        messages: {
            title: keys.alignJustify
        },
        commandName: 'AlignJustify'
    };
    /**
     * The object of the AlignRemove tool settings.
     */
    EditorToolsSettings.alignRemove = {
        actions: __spreadArray([], alignRemoveRules, true),
        props: __assign({ icon: 'align-remove' }, buttonCommonProps),
        messages: {},
        commandName: 'AlignRemove'
    };
    /**
     * The object of the Indent tool settings.
     */
    EditorToolsSettings.indent = {
        actions: indentRules.nodes,
        listsTypes: listsTypes,
        props: __assign({ icon: 'indent-increase' }, buttonCommonProps),
        messages: {
            title: keys.indent
        },
        commandName: 'Indent'
    };
    /**
     * The object of the Outdent tool settings.
     */
    EditorToolsSettings.outdent = {
        actions: outdentRules.nodes,
        listsTypes: listsTypes,
        props: __assign({ icon: 'indent-decrease' }, buttonCommonProps),
        messages: {
            title: keys.outdent
        },
        commandName: 'Outdent'
    };
    /**
     * The object of the OrderedList tool settings.
     */
    EditorToolsSettings.orderedList = {
        listType: listsTypes.orderedList,
        props: __assign({ icon: 'list-ordered' }, buttonCommonProps),
        messages: {
            title: keys.orderedList
        },
        commandName: 'OrderedList',
        types: __assign({}, listsTypes)
    };
    /**
     * The object of the UnorderedList tool settings.
     */
    EditorToolsSettings.bulletList = {
        listType: listsTypes.bulletList,
        props: __assign({ icon: 'list-unordered' }, buttonCommonProps),
        messages: {
            title: keys.bulletList
        },
        commandName: 'UnorderedList',
        types: __assign({}, listsTypes)
    };
})(EditorToolsSettings || (EditorToolsSettings = {}));
