"use strict";

import React from "react";
import { PDF_VIEWER_STRUCTURE } from "./pdf_viewer_structure";
import { BasePDFObjectBuilder } from "./base_pdf_object_builder";
import { PDFTextLayerBuilder } from "./pdf_text_layer_builder";

import { Log, LOG_GROUP } from "../../../../server/common/logger/common_log";
import { Ensure } from "../../../../server/common/generic/common_ensure";
import pdfJS from "./pdf_js_bridge";

// eslint-disable-next-line no-unused-vars
const Logger = Log.group(LOG_GROUP.Documents, "PDFPageBuilder");

/**
 * @typedef IPDFPageViewerElement
 * @property {string} name
 * @property {string|function(IPDFPage): string} selector
 * @property {React.Component|string} [component]
 * @property {*} [props]
 * @property {IPDFPageViewerElement} [parent]
 */


/**
 * @typedef IPDFPage
 * @property {number} number
 * @property {boolean} shouldRender
 * @property {boolean} rendered
 * @property {boolean} loading
 * @property {boolean} skipped
 * @property {boolean} renderTextLayer
 * @property {JQuery} pageWrapper
 * @property {JQuery} container
 * @property {JQuery} canvasWrapper
 * @property {JQuery} canvas
 * @property {JQuery} textWrapper
 * @property {JQuery} loadingIndicator
 * @property {pdfJS.viewer.PDFPageViewport} scaledViewport
 * @property {pdfJS.viewer.PDFPageView} view
 */

/**
 * @typedef IPDFPageViewerElementOptions
 * @property {IPDFPageViewerElement} [element] The declaration of the {@link IPDFPageViewerElement}
 * to be configured.
 *
 * This is generally a value from {@link PDF_VIEWER_STRUCTURE} enumeration, or a custom value if you
 * want to override the default behavior.
 * @property {*} [props]
 */

/**
 * @type {IPDFPageViewerElementOptions}
 */
const DEFAULT_ELEMENT_OPTIONS = {element : undefined, props : {}};

/**
 * Creates the necessary objects and prepares the DOM to be able to render a PDF page.
 * @extends {BasePDFObjectBuilder<IPDFPage, HTMLElement>}
 **/
export class PDFPageBuilder extends BasePDFObjectBuilder {
  /**
   * @param container {React.RefObject<HTMLElement>} The container in which the page will be rendered into
   * @param pdfPage {ICachedPDFPage} The pdfJS object that represents the PDF page.
   * @param previousState {IPDFPage} The previous value for the current page (if any)
   */
  constructor(container, pdfPage, previousState= pdfPage.renderObject || {}) {
    super(container, previousState);

    Ensure.that({previousState, pdfPage}).areNotFalsy();

    /**
     * The PDF page being rendered.
     * @type {PDFPageProxy}
     */
    this.pdfPage = pdfPage;

    /**
     * @type {PDFPageViewport}
     */
    this.defaultViewport = pdfPage.getViewport({scale: 1.0});

    /**
     * @type {PDFPageViewport}
     */
    this.scaledViewport = this.defaultViewport;

    /**
     * Indicates whether or not the PDF viewer is currently visible
     * @type {boolean}
     * @protected
     */
    this.isVisible = true;

    this.zoomLevel = 100;

    this.scale = 1;

    this.pageWidth = 1024;

    /**
     * Toggles the rendering of the PDF text layer
     * @type {boolean}
     */
    this.renderTextLayer = true;
  }

  /**
   * Toggles whether or not the PDF viewer is currently visible
   * @param value {boolean}
   */
  setVisibility(value) {
    this.isVisible = value;
    return this;
  }

  configurePageView() {
    let page = this.instance;
    let pdfPage = this.pdfPage;

    let scaledViewport = this.scaledViewport;
    let scale = scaledViewport.scale;

    /**
     * @type {HTMLElement}
     */
    const domContainer = this.containerRef.current;
    const container = $(domContainer);

    const shouldRender = this.isVisible && (
      !page.view
      || !page.rendered
      || scale !== page.scale
      || domContainer.clientWidth !== page.containerWidth
      || domContainer.clientHeight !== page.containerHeight
      || this.isVisible !== page.isVisible
    );

    Logger.verbose("Should Render Page: ", Log.object(shouldRender));

    if (!page.view) {
      /*
       PdfJs creates a div and adds it to the parent container when the constructor is
       called (instead of when we render the page),
       and we don't want that because we want to reuse the existing page divs to improve
       rendering performance.

       So, we pass a temporary container during the constructor call.

       If it's the first time, we add the page div it created to the document.
       If we already have a div, we replace it with the existing element
      */
      const tempContainer = document.createElement("div");
      const pdfTextLayerBuilder = new PDFTextLayerBuilder(tempContainer);

      page.view = new pdfJS.viewer.PDFPageView({
        container: tempContainer,
        id: pdfPage.pageNumber,
        scale,
        defaultViewport: this.defaultViewport,
        renderer: this.renderer,
        eventBus: pdfTextLayerBuilder.getEventBus(),
        textLayerFactory: pdfTextLayerBuilder.build(),
      });

      if (!page.pageWrapper) {
        container.append($(tempContainer).first());
        page.pageWrapper = container.find(`.page[data-page-number=${pdfPage.pageNumber}]`);
      }
    }

    this.ensureChildExists(PDF_VIEWER_STRUCTURE.PAGE_WRAPPER)
      .executeForChild(PDF_VIEWER_STRUCTURE.PAGE_WRAPPER, pageWrapper => {
        page.view.div = pageWrapper[0];
      });


    if (shouldRender) {
      page.view.setPdfPage(pdfPage);
      page.view.viewport = scaledViewport;
    }

    page.shouldRender = shouldRender;
    page.container = container;
    page.number = pdfPage.pageNumber;
    page.scaledViewport = this.scaledViewport;
    page.containerWidth = domContainer.offsetWidth;
    page.containerHeight = domContainer.offsetHeight;
    return this;
  }

  /**
   * Configures the {@link PDF_VIEWER_STRUCTURE.PAGE_WRAPPER} element in the viewer.
   * @param [options] {!IPDFPageViewerElementOptions} Specifies additional options if you want to override
   * the default behavior.
   *
   * You can find the defaults in the {@link PDF_VIEWER_STRUCTURE} enumeration.
   * @returns {this}
   */
  configurePageWrapper(options = DEFAULT_ELEMENT_OPTIONS) {
    const {element, props} = options;

    let page = this.instance;

    const child = this.combineElementWithDefaults(PDF_VIEWER_STRUCTURE.PAGE_WRAPPER, element, props);

    return this.registerChildElement(child)
      .ensureChildExists(child)
      .fitChildToScale(child)
      .executeForChild(child, item => {
        item.addClass("document-preview-page");
        item.attr("data-page-number", page.number);
      });
  }

  /**
   * Configures the {@link PDF_VIEWER_STRUCTURE.LOADING_INDICATOR} element in the viewer.
   * @param [options] {!IPDFPageViewerElementOptions} Specifies additional options if you want to override
   * the default behavior.
   *
   * You can find the defaults in the {@link PDF_VIEWER_STRUCTURE} enumeration.
   * @returns {this}
   */
  configureLoadingIndicator(options = DEFAULT_ELEMENT_OPTIONS) {
    const {element, props} = options;
    const child = this.combineElementWithDefaults(PDF_VIEWER_STRUCTURE.LOADING_INDICATOR, element, props);

    return this.registerChildElement(child)
      .ensureChildExists(child)
      .executeForChild(child, item => item.removeClass("d-none"))
      .fitChildToScale(child);
  }

  /**
   * Configures the {@link PDF_VIEWER_STRUCTURE.CANVAS_WRAPPER} element in the viewer.
   * @param [options] {!IPDFPageViewerElementOptions} Specifies additional options if you want to override
   * the default behavior.
   *
   * You can find the defaults in the {@link PDF_VIEWER_STRUCTURE} enumeration.
   * @returns {this}
   */
  configureCanvasWrapper(options = DEFAULT_ELEMENT_OPTIONS) {
    const {element, props} = options;
    const child = this.combineElementWithDefaults(PDF_VIEWER_STRUCTURE.CANVAS_WRAPPER, element, props);
    return this.registerChildElement(child)
      .ensureChildExists(child)
      .fitChildToScale(child);
  }

  /**
   * Configures the {@link PDF_VIEWER_STRUCTURE.CANVAS_WRAPPER} element in the viewer.
   * @param [options] {!IPDFPageViewerElementOptions} Specifies additional options if you want to override
   * the default behavior.
   *
   * You can find the defaults in the {@link PDF_VIEWER_STRUCTURE} enumeration.
   * @returns {this}
   */
  configureCanvas(options = DEFAULT_ELEMENT_OPTIONS) {
    const {element, props} = options;
    const child = this.combineElementWithDefaults(PDF_VIEWER_STRUCTURE.CANVAS_WRAPPER, element, props);
    return this.registerChildElement(child)
      .ensureChildExists(child)
      .fitChildToScale(child);
  }

  /**
   * Configures the {@link PDF_VIEWER_STRUCTURE.LOADING_INDICATOR} element in the viewer.
   * @param [options] {!IPDFPageViewerElementOptions} Specifies additional options if you want to override
   * the default behavior.
   *
   * You can find the defaults in the {@link PDF_VIEWER_STRUCTURE} enumeration.

   * @returns {this} This instance of the builder, so you can chain calls to it.
   */
  configureTextLayer(options = DEFAULT_ELEMENT_OPTIONS) {
    const {element, props} = options;
    const child = this.combineElementWithDefaults(PDF_VIEWER_STRUCTURE.TEXT_LAYER, element, props);
    return this.registerChildElement(child)
      .ensureChildExists(child)
      .fitChildToScale(child);
  }

  setZoomLevel(zoomLevel) {
    this.zoomLevel = zoomLevel;
    return this;
  }

  rescale() {
    let scale = this.getRecalculatedScale();
    this.setScale(scale);
    return this;
  }

  getRecalculatedScale() {
    return this.zoomLevel * this.pageWidth / this.defaultViewport.width / 100;
  }

  setPageWidth(width) {
    this.pageWidth = width;
    return this;
  }

  setScale(scale) {
    this.scale = scale;
    this.scaledViewport = this.pdfPage.getViewport({scale});
    return this;
  }

  /**
   * Enables or disables the rendering PDF text layer (a layer with selectable text).
   * @param value {boolean}
   * @returns {this}
   */
  setRenderTextLayer(value) {
    this.renderTextLayer = value;
    return this;
  }

  /**
   * Ensures that specified page element is scaled to the viewport size.
   * @param element {!IPDFPageViewerElement}
   * @returns {this}
   */
  fitChildToScale(element) {
    Ensure.that({element}).isNotFalsy();

    let page = this.instance;
    let child = page[element.name];

    if (!child) {
      throw new TypeError(`Unable to find a child element for this builder with the name "${element.name}"`);
    }
    Logger.verbose("Fitting to scale: ", Log.object(this.scale), Log.object(element.selector));
    child.width(this.scaledViewport.width);
    child.height(this.scaledViewport.height);
    return this;
  }

  /**
   * @inheritDoc
   * @returns {IPDFPage|R}
   */
  build() {
    let renderObject = super.build();
    renderObject.isVisible = this.isVisible;
    renderObject.defaultViewport = this.defaultViewport;
    renderObject.scaledViewport = this.scaledViewport;
    renderObject.scale = this.scale;
    renderObject.renderTextLayer = this.renderTextLayer;
    return renderObject;
  }
}
