/**
 * @hidden
 */
export var TIME_PART = {
    dayperiod: 'dayperiod',
    hour: 'hour',
    millisecond: 'millisecond',
    minute: 'minute',
    second: 'second'
};
