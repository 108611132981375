var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { SplitterPane } from './SplitterPane';
import { SplitterBar } from './SplitterBar';
import { classNames } from '@progress/kendo-react-common';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
/**
 * @hidden
 */
var PANE_DEFAULTS = {
    collapsible: false,
    collapsed: false,
    resizable: true,
    scrollable: true
};
/**
 * @hidden
 */
var DOUBLE_CLICK_WAIT_TIME = 150;
/**
 * Represents the [KendoReact Splitter component]({% slug overview_splitter %}).
 *
 * @example
 * ```jsx
 * class App extends React.Component {
 *    render() {
 *        return (
 *            <div>
 *                <Splitter
 *                    style={{height: 400}}
 *                    orientation={'vertical'}
 *                >
 *                    <div>Pane 1</div>
 *                    <div>Pane 2</div>
 *                    <div>Pane 3</div>
 *                </Splitter>
 *            </div>
 *        );
 *    }
 * }
 *
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var Splitter = /** @class */ (function (_super) {
    __extends(Splitter, _super);
    /**
     * @hidden
     */
    function Splitter(props) {
        var _this = _super.call(this, props) || this;
        _this._container = null;
        _this.validatePanes = function (panesOptions) {
            var withoutSize = panesOptions.filter(function (pane) { return pane.size === undefined; });
            if (!withoutSize.length) {
                throw new Error('The Splitter should have at least one pane without a set size.');
            }
        };
        _this.mapPaneOptions = function (panes, panesContent) {
            var orientation = _this.orientation;
            var _a = _this.state, dragIndex = _a.dragIndex, isDragging = _a.isDragging;
            var paneOptions = [];
            for (var index = 0; index < panesContent.length; index++) {
                var containsSplitter = false;
                var paneContent = panesContent[index];
                if (React.isValidElement(paneContent)) {
                    containsSplitter = paneContent.type.displayName === 'Splitter';
                }
                var overlay = false;
                if (isDragging && dragIndex !== undefined) {
                    overlay = dragIndex === index || dragIndex + 1 === index;
                }
                paneOptions.push(__assign(__assign(__assign({}, PANE_DEFAULTS), { orientation: orientation, containsSplitter: containsSplitter, overlay: overlay }), (panes || [])[index]));
            }
            return paneOptions;
        };
        _this.mapSplitterPanes = function (panes, panesContent) {
            return panes.map(function (pane, index) {
                var splitterBar;
                var paneKey = index * 2;
                var barKey = paneKey + 1;
                if (index + 1 < panes.length) {
                    var next = panes[index + 1];
                    splitterBar = (React.createElement(SplitterBar, { key: barKey, index: index, orientation: pane.orientation, prev: pane, next: next, onDrag: _this.onBarDragResize, onToggle: _this.onBarToggle, onKeyboardResize: _this.onBarKeyboardResize }));
                }
                var splitterPane = (React.createElement(SplitterPane, __assign({ key: paneKey }, pane), panesContent[index]));
                return [splitterPane, splitterBar];
            });
        };
        _this.onBarToggle = function (index, event) {
            var panesOptions = _this.panesOptions(_this.panesContent);
            var updatedState = panesOptions.map(function (pane, idx) {
                var paneProps = _this.getPaneProps(pane);
                if (idx === index) {
                    return __assign(__assign({}, paneProps), { collapsed: !pane.collapsed });
                }
                else {
                    return __assign({}, paneProps);
                }
            });
            if (_this.props.onChange) {
                _this.props.onChange({
                    newState: updatedState,
                    isLast: true,
                    nativeEvent: event
                });
            }
        };
        _this.onBarDragResize = function (event, barElement, index, isFirst, isLast) {
            var time = new Date().getTime();
            var pageX = event.pageX, pageY = event.pageY;
            var _a = _this.surroudingPanes(barElement), prevElement = _a.prevElement, nextElement = _a.nextElement;
            if (!prevElement || !nextElement) {
                return;
            }
            if (isFirst) {
                _this.setState({
                    isDragging: true,
                    dragIndex: index,
                    startTime: time,
                    originalX: pageX,
                    originalY: pageY,
                    originalPrevSize: _this.elementSize(prevElement),
                    originalNextSize: _this.elementSize(nextElement)
                });
                return;
            }
            var _b = _this.state, originalPrevSize = _b.originalPrevSize, originalNextSize = _b.originalNextSize, startTime = _b.startTime, originalX = _b.originalX, originalY = _b.originalY;
            if (!isFirst && time - startTime < DOUBLE_CLICK_WAIT_TIME) {
                if (isLast) {
                    _this.resetDragState();
                }
                return;
            }
            var delta;
            if (_this.orientation === 'vertical') {
                delta = pageY - originalY;
            }
            else if (_this.isRtl) {
                delta = originalX - pageX;
            }
            else {
                delta = pageX - originalX;
            }
            _this.resize(index, index + 1, originalPrevSize, originalNextSize, delta, isLast, event);
            if (isLast) {
                _this.resetDragState();
            }
        };
        _this.onBarKeyboardResize = function (barElement, index, delta, event) {
            var _a = _this.surroudingPanes(barElement), prevElement = _a.prevElement, nextElement = _a.nextElement;
            var originalPrevSize = _this.elementSize(prevElement);
            var originalNextSize = _this.elementSize(nextElement);
            _this.resize(index, index + 1, originalPrevSize, originalNextSize, delta, true, event);
        };
        _this.containerSize = function () {
            if (!_this._container) {
                return 0;
            }
            return _this.elementSize(_this._container, true);
        };
        _this.panesOptions = function (panesContent) {
            return _this.mapPaneOptions(_this.panes, panesContent);
        };
        _this.elementSize = function (el, isContainer) {
            var sizeType = isContainer ? 'client' : 'offset';
            if (_this.orientation === 'vertical') {
                return el["".concat(sizeType, "Height")];
            }
            else {
                return el["".concat(sizeType, "Width")];
            }
        };
        _this.clamp = function (min, max, v) { return Math.min(max, Math.max(min, v)); };
        _this.fixedSize = function (size) { return size && size.length > 0; };
        validatePackage(packageMetadata);
        _this.state = {
            isDragging: false,
            dragIndex: undefined,
            startTime: 0,
            originalX: 0,
            originalY: 0,
            originalPrevSize: 0,
            originalNextSize: 0,
            panes: props.defaultPanes || []
        };
        return _this;
    }
    Object.defineProperty(Splitter.prototype, "isControlledState", {
        get: function () {
            return this.props.panes !== undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Splitter.prototype, "panes", {
        get: function () {
            return this.panesDuringOnChange !== undefined ?
                this.panesDuringOnChange :
                (this.isControlledState ? this.props.panes : this.state.panes);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Splitter.prototype, "orientation", {
        get: function () {
            return this.props.orientation || 'horizontal';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Splitter.prototype, "isRtl", {
        get: function () {
            return this._container && (getComputedStyle(this._container).direction === 'rtl') || false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Splitter.prototype, "panesContent", {
        get: function () {
            return React.Children.toArray(this.props.children).filter(function (c) { return c; });
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    Splitter.prototype.render = function () {
        var _this = this;
        var panesContent = this.panesContent;
        var panesOptions = this.panesOptions(panesContent);
        var className = classNames('k-widget', 'k-splitter', 'k-splitter-flex', "k-splitter-".concat(this.orientation), this.props.className);
        this.validatePanes(panesOptions);
        return (React.createElement("div", { style: this.props.style, ref: function (ref) { return _this._container = ref; }, className: className }, this.mapSplitterPanes(panesOptions, panesContent)));
    };
    Splitter.prototype.surroudingPanes = function (barElement) {
        return {
            prevElement: barElement.previousElementSibling,
            nextElement: barElement.nextElementSibling
        };
    };
    Splitter.prototype.isPercent = function (size) {
        return /%$/.test(size);
    };
    Splitter.prototype.toPixels = function (size, splitterSize) {
        var result = parseInt(size, 10);
        if (this.isPercent(size)) {
            result = (splitterSize * result / 100);
        }
        return result;
    };
    Splitter.prototype.resetDragState = function () {
        this.setState({
            isDragging: false,
            dragIndex: undefined,
            startTime: 0,
            originalX: 0,
            originalY: 0,
            originalPrevSize: 0,
            originalNextSize: 0
        });
    };
    Splitter.prototype.resize = function (prevIndex, nextIndex, originalPrevSize, originalNextSize, delta, isLast, event) {
        var _this = this;
        var panesOptions = this.panesOptions(this.panesContent);
        var prevOptions = panesOptions[prevIndex];
        var nextOptions = panesOptions[nextIndex];
        var total = originalPrevSize + originalNextSize;
        var splitterSize = this.containerSize();
        var px = function (s) { return _this.toPixels(s, splitterSize); };
        var prevDragState = {
            index: prevIndex,
            initialSize: originalPrevSize,
            min: px(prevOptions.min) || total - px(nextOptions.max) || 0,
            max: px(prevOptions.max) || total - px(nextOptions.min) || total
        };
        var nextDragState = {
            index: nextIndex,
            initialSize: originalNextSize,
            min: px(nextOptions.min) || total - px(prevOptions.max) || 0,
            max: px(nextOptions.max) || total - px(prevOptions.min) || total
        };
        var resize = function (paneState, change) {
            var pane = panesOptions[paneState.index];
            var newSize = _this.clamp(paneState.min, paneState.max, paneState.initialSize + change);
            if (_this.isPercent(pane.size || '')) {
                return 100 * newSize / splitterSize + '%';
            }
            else {
                return newSize + 'px';
            }
        };
        var prevSize;
        var nextSize;
        if (this.fixedSize(prevOptions.size) && this.fixedSize(nextOptions.size)) {
            prevSize = resize(prevDragState, delta);
            nextSize = resize(nextDragState, -delta);
        }
        else if (nextOptions.collapsible || this.fixedSize(nextOptions.size)) {
            nextSize = resize(nextDragState, -delta);
        }
        else {
            prevSize = resize(prevDragState, delta);
        }
        var updatedState = panesOptions.map(function (pane, idx) {
            var paneProps = _this.getPaneProps(pane);
            if (idx === prevIndex) {
                return __assign(__assign({}, paneProps), { size: prevSize });
            }
            else if (idx === nextIndex) {
                return __assign(__assign({}, paneProps), { size: nextSize });
            }
            else {
                return __assign({}, paneProps);
            }
        });
        this.panesDuringOnChange = updatedState;
        if (!this.isControlledState) {
            this.setState({
                panes: updatedState
            });
        }
        if (this.props.onChange) {
            this.props.onChange({
                newState: updatedState,
                isLast: isLast,
                nativeEvent: event
            });
        }
        this.panesDuringOnChange = undefined;
    };
    Splitter.prototype.getPaneProps = function (pane) {
        var orientation = pane.orientation, overlay = pane.overlay, containsSplitter = pane.containsSplitter, others = __rest(pane, ["orientation", "overlay", "containsSplitter"]);
        return others;
    };
    /**
     * @hidden
     */
    Splitter.displayName = 'Splitter';
    return Splitter;
}(React.Component));
export { Splitter };
