"use strict";
// Disable the unused vars errors because either the classes are needed for the generated output or the classes are used in the JSX below.
/* eslint-disable no-unused-vars */
import * as UIUtils from "../../../ui_utils";
import React from "react";
import ReactDOM from "react-dom";
import RiskMapReportLayoutsListTable from "../widgets/risk_map_report_layouts_list_table";
import BaseReactComponent from "../../../base_react_component";

/* eslint-enable no-unused-vars */

export default class RiskMapLayoutLoadPopup extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.projectId = UIUtils.getParameterByName("projectId");

    this.state = {
      errorText: ""
    };
  }

  componentDidUpdate() {
    $("#riskMapReportLoadLayoutForm").validator("update");
  }

  componentDidMount() {
    super.componentDidMount();
    $(ReactDOM.findDOMNode(this)).modal("show");
    $(ReactDOM.findDOMNode(this)).on("hidden.bs.modal", this.props.onHideModal);
    $("#riskMapReportLoadLayoutForm").validator("update");
  }

  handleLayoutSelection(layoutId) {
    this.setStateSafely({
      selectedLayoutId: layoutId
    });
  }

  handleLayoutDelete(layout) {
    this.props.onDeleteLayout(layout);
  }

  handleLoadLayout(eventIgnore) {
    let selectedLayout = this.props.layouts && this.state.selectedLayoutId ?
      this.props.layouts.find(layout => {
        return layout.id === this.state.selectedLayoutId;
      }) : null;
    this.props.onLoadLayout(selectedLayout);
  }

  render() {
    return (
      <div className="modal fade"
           ref={this.props.modalRef}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Load Layout</h1>
              <button type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-container risk-map-popup-layout-container">
                <div className="row">
                  {<div className="col-sm-12"
                        id="riskMapReportLayoutNameDiv"
                  >
                    <RiskMapReportLayoutsListTable layouts={typeof this.props.layouts !== "undefined" ? this.props.layouts : []}
                                                   onLayoutSelection={this.handleLayoutSelection}
                                                   onLayoutDelete={this.handleLayoutDelete}
                    />
                  </div>}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="btn-group">
                <button id="LoadLayoutButton"
                        type="button"
                        className="btn btn-primary"
                        disabled={!this.state.selectedLayoutId}
                        onClick={this.handleLoadLayout}
                >
                  Load
                </button>
                <button id="cancelLoadLayoutButton"
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
