import TypeaheadObjectCache from "./cache/typeahead_object_cache";
import { getLatestApprovalRequestVersionTransition } from "../helpers/approval_helper";
import { CommonUtils } from "../../server/common/generic/common_utils";
import * as UIUtils from "../ui_utils";

/**
 * Get render options for various scenarios like whether we should enable or disable a button
 *
 * @param options
 * @return {{isView: *, canWithdraw: boolean, isArchived: *, isProjectArchived: boolean, isRestored: (boolean|*), isDraft: boolean, currentViewingVersionId: (Object.versionId|null), isModifiable: boolean, currentDiffingVersion, isApproved: *, hasApprovedVersion: *, isProcessArchived: boolean, isUserOriginator: boolean, allUsersHaveResponded: boolean, isParentProject: boolean, editNotAllowedReason: string, isParentProcess: boolean, canUserApprove: boolean, latestVersion: null, isHistoryShowing: boolean, location, canUserEdit: *, currentState: *, canUserPropose: boolean, status: string}}
 */
export function getRenderOptions(options) {
  const EDITABLE_STATES = [
    CommonUtils.VERSION_STATES.DRAFT,
    CommonUtils.VERSION_STATES.WITHDRAWN,
    CommonUtils.VERSION_STATES.WITHDRAWN_CASCADED,
    CommonUtils.VERSION_STATES.REJECTED,
    CommonUtils.VERSION_STATES.REJECTED_CASCADED,
    CommonUtils.VERSION_STATES.APPROVED,
    CommonUtils.VERSION_STATES.RESTORED,
    CommonUtils.VERSION_STATES.RESTORED_CASCADED,
  ];
  const {
    location,
    versions,
    projectId,
    processId,
    canUserEdit,
    currentState,
    isArchived,
    isApproved,
    isRestored,
    hasApprovedVersion,
    isView,
    isParentProject,
    isParentProcess,
    currentDiffingVersion,
    currentViewingVersionId,
    majorVersion,
    minorVersion,
    isHistoryShowing,
    editNotAllowedReason,
  } = options;
  let {t} = options;
  if (!t) {
    t = (text) => text;
  }

  const projects = new TypeaheadObjectCache(
    "Project"
  ).getOptionsFromCacheIncludingArchived();
  const parentProject =
    projects.length > 0 &&
    projects.find((project) => project.id === projectId);
  const processes = new TypeaheadObjectCache(
    "Process",
    parentProject && parentProject.id
  ).getOptionsFromCacheIncludingArchived();
  const parentProcess =
    processes.length > 0 &&
    processes.find((process) => process.id === processId);

  let result = {
    status: "",
    location,
    canUserEdit,
    currentState,
    isArchived,
    isApproved,
    isRestored,
    hasApprovedVersion,
    isView,
    isParentProject,
    isParentProcess,
    currentDiffingVersion,
    currentViewingVersionId,
    canUserApprove: false,
    canUserPropose: false,
    latestVersion: null,
    editNotAllowedReason: "",
    isModifiable: false,
    canWithdraw: false,
    isDraft: false,
    isHistoryShowing: false,
    isUserOriginator: false,
    allUsersHaveResponded: false,
    isProjectArchived: parentProject && !!parentProject.deletedAt,
    isProcessArchived:
      parentProject && parentProcess && !!parentProcess.deletedAt,
  };

  if (result.isView) {
    const isViewingLatest =
      !result.currentViewingVersionId ||
      !!(versions && versions[0].id === result.currentViewingVersionId);

    let currentState = result.isArchived
      ? CommonUtils.VERSION_STATES.ARCHIVED
      : result.isRestored
        ? CommonUtils.VERSION_STATES.RESTORED
        : result.isApproved
          ? CommonUtils.VERSION_STATES.APPROVED
          : CommonUtils.VERSION_STATES.DRAFT;

    if (!result.canUserEdit) {
      result.editNotAllowedReason = editNotAllowedReason;
    }

    if (result.currentDiffingVersion?.majorVersion >= 0) {
      // The "v" is so the version looks like v0.1 on the screen.
      result.status +=
        "v" +
        result.currentDiffingVersion.majorVersion +
        "." +
        result.currentDiffingVersion.minorVersion;
    } else if (result.currentViewingVersionId && versions && !isViewingLatest) {
      result.status +=
        "v" + majorVersion + "." + minorVersion;
    } else {
      result.status += "Latest";
    }

    result.canUserApprove = false;
    result.canUserPropose = false;
    result.isUserOriginator = false;
    result.allUsersHaveResponded = false;

    let approvalRequest = null;
    let approvalResponses = null;
    let latestVersion;

    const editNotAllowedReasonLabel = t(
      "This record is not in a state that can be edited."
    );
    if (versions) {
      latestVersion = versions[0];
      let versionTransitions = latestVersion.versionTransitions;
      let approvalRequestTransition =
        getLatestApprovalRequestVersionTransition(versionTransitions);
      approvalRequest = approvalRequestTransition.approvalRequest;
      approvalResponses = approvalRequestTransition.approvalResponses;

      // Can this version be approved?
      if (
        result.currentViewingVersionId === latestVersion.id &&
        approvalRequest
      ) {
        let myselfInApproversList = approvalRequest.approvalReqToUsers.filter(
          (reqToUser) => UIUtils.isCurrentUser(reqToUser?.user?.cognitoUUID)
        );

        result.allUsersHaveResponded =
          approvalResponses &&
          approvalRequest.approvalReqToUsers.length ===
          approvalResponses.length;
        result.canUserApprove = myselfInApproversList.length === 1;
        result.isUserOriginator = UIUtils.isCurrentUser(
          approvalRequest.createdByUser.cognitoUUID
        );
        currentState = CommonUtils.VERSION_STATES.PROPOSED;
      }

      if (isViewingLatest) {
        currentState = latestVersion.currentState;
      } else {
        let currentVersion = versions.find(
          (version) => version.id === result.currentViewingVersionId
        );
        if (currentVersion) {
          currentState = currentVersion.currentState;
        } else if (versions.length > 0) {
          currentState = versions[0].currentState;
        } else {
          currentState = "Draft";
        }
      }

      result.canUserPropose = isViewingLatest && currentState === "Draft";
      if (result.canUserEdit) {
        if (!isViewingLatest) {
          result.canUserEdit = false;
          result.editNotAllowedReason = t("View the latest version to edit.");
        } else if (!EDITABLE_STATES.includes(currentState)) {
          result.canUserEdit = false;
          result.editNotAllowedReason = editNotAllowedReasonLabel;
        }
      }
    } else {
      if (
        result.canUserEdit &&
        !EDITABLE_STATES.includes(result.currentState)
      ) {
        result.canUserEdit = false;
        result.editNotAllowedReason = editNotAllowedReasonLabel;
      }
    }

    result.latestVersion = latestVersion;
    result.status += " (" + currentState + ")";

    /** Do not display any of the Propose/Withdraw/Approve buttons when the current state of the selected
     * version is "Approved" or "Obsolete" or "Withdrawn" Also, do not display any of the above buttons
     * when the current state of the selected version is "Rejected" and all users have responded or the
     * current user is the one who has proposed or the parent project is archived
     */
    result.isModifiable = !(
      currentState === CommonUtils.VERSION_STATES.APPROVED ||
      currentState === CommonUtils.VERSION_STATES.ARCHIVED ||
      currentState === CommonUtils.VERSION_STATES.ARCHIVED_CASCADED ||
      currentState === CommonUtils.VERSION_STATES.RESTORED ||
      currentState === CommonUtils.VERSION_STATES.RESTORED_CASCADED ||
      currentState === CommonUtils.VERSION_STATES.OBSOLETE ||
      currentState === CommonUtils.VERSION_STATES.WITHDRAWN ||
      currentState === CommonUtils.VERSION_STATES.WITHDRAWN_CASCADED ||
      currentState === CommonUtils.VERSION_STATES.REJECTED_CASCADED ||
      (currentState === CommonUtils.VERSION_STATES.REJECTED &&
        (result.allUsersHaveResponded || result.isUserOriginator))
    );

    result.canWithdraw =
      currentState &&
      (currentState.startsWith("Proposed") ||
        currentState === CommonUtils.VERSION_STATES.ROUTING) &&
      result.isUserOriginator;
    result.isDraft = currentState === CommonUtils.VERSION_STATES.DRAFT;

    result.isHistoryShowing = isHistoryShowing;
  } else {
    result.status = "Draft";
    result.isApproved = false;
    result.isDraft = true;
    result.isArchived = false;
  }

  return result;
}
