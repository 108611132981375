import { BaseWidgetParser } from "./base_widget_parser";
import { WidgetParserFactory } from "./widget_parser";
import { merge, setElementAttributes } from "../index";

/**
 * Parser will be used to parse table widget
 */
export class TableParser extends BaseWidgetParser {
  get selector() {
    // A widget table can also have a nested table. We only want to select all
    // the table rows of the non-nested table
    return ":scope > tbody > tr";
  }

  getElements() {
    const table = this.node.querySelector("table");
    if (!table) {
      return [];
    }
    return Array.from(table.querySelectorAll(this.selector));
  }

  parseTableCells(tableCells, parentModelName, record, newTableRow) {
    for (const tableCell of tableCells) {
      const newElements = [];
      for (const childElement of tableCell.children) {
        const parser = WidgetParserFactory.getParser(
          this.globalNode,
          this.node,
          childElement,
          record,
          this.fileType,
          this.rmp,
          this.project,
          this.process,
          this.parseOnly
        );
        const newChildElements = parser.parse();
        if (newChildElements.length) {
          newElements.push(...newChildElements);
        }

        if (this.parseOnly) {
          let modelFields = this.fields;
          const kind = childElement.getAttribute("kind");
          if (kind) {
            modelFields = this.addModelAndColumnToField(
              modelFields,
              parentModelName
            );
          }
          merge(modelFields, parser.fields);
        }
      }

      if (record && newTableRow) {
        const newTableCell = setElementAttributes(document.createElement("td"), tableCell);
        newTableCell.append(...newElements);
        newTableRow.append(newTableCell);
      }
    }
  }

  createTable() {
    const table = this.node.querySelector("table");
    const colGroup = table.querySelector("colgroup");
    const newTable = document.createElement("table");
    if (colGroup) {
      newTable.append(colGroup.cloneNode(true));
    }
    return setElementAttributes(newTable, table);
  }

  parse() {
    const tableRows = this.getElements();

    if (this.parseOnly) {
      this.addAttributesInFiltersToFields();

      for (const tableRow of tableRows) {
        const tableCells = tableRow.querySelectorAll(":scope > td");
        this.parseTableCells(tableCells, this.parentModelName);
      }
    } else {
      const records = this.getRecords(this.parentModelName, this.subModelName);
      if (!tableRows.length) {
        return [];
      }

      const newTable = this.createTable();
      const newTableBody = document.createElement("tbody");
      newTable.append(newTableBody);

      // Count number of rows that has static data (non smart content data) and assume these are table headers
      let numberOfHeaderRows = 0;
      for (const tableRow of tableRows) {
        let hasSmartContent = false;
        const tableCellsOfCurrentRow = Array.from(tableRow.querySelectorAll(":scope > td"));
        for (const tableCell of tableCellsOfCurrentRow) {
          const qbdOutput = tableCell.querySelector(".qbd-output");
          if (qbdOutput) {
            hasSmartContent = true;
            break;
          }
        }
        if (!hasSmartContent) {
          numberOfHeaderRows++;
        } else {
          break;
        }
      }

      for (let headerRow = 0; headerRow < numberOfHeaderRows; headerRow++) {
        const newHeader = tableRows[headerRow].cloneNode(true);
        newTableBody.append(newHeader);
      }

      for (const record of records) {
        for (const tableRow of tableRows.slice(numberOfHeaderRows)) {
          const newTableRow = document.createElement("tr");
          const tableCells = tableRow.querySelectorAll(":scope > td");
          this.parseTableCells(tableCells, this.parentModelName, record, newTableRow);
          newTableBody.append(newTableRow);
        }
      }

      return [newTable];
    }

    return [];
  }
}
