"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import Section from "../../editor/widgets/section";
import TextAttribute from "../../editor/attributes/text_attribute";
import TextAreaAttribute from "../../editor/attributes/text_area_attribute";
import TypeaheadAttribute from "../../editor/attributes/typeahead_attribute";
import ComboBoxAttribute from "../../editor/attributes/combo_box_attribute";
import ReferenceDocuments from "@cherrycircle/fda-docs";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import { EDITOR_TYPES } from "../../editor/editor_constants";
import BaseOperationsEditor from "../attributes/base_operations_editor";

/**
 * This is the class that renders the Add/Edit unit operation editor in the process explorer.
 */
// i18next-extract-mark-ns-start process_explorer
class UnitOperation extends BaseOperationsEditor {
  constructor(props) {
    super(props, "unitOperation", "UnitOperation", "Unit Operation (UO)");

    // Bind stuff
    this.getHTMLURLPrefix = UnitOperation.getHTMLURLPrefix;

    const processId = UIUtils.parseInt(UIUtils.getParameterByName("processId"));
    this.setStateSafely({processId});
  }

  componentDidMount() {
    document.title = "QbDVision Unit Operations";

    super.componentDidMount();
  }
  static getHTMLURLPrefix() {
    return "/processExplorer/unitOperations";
  }

  getTypesToCache() {
    return ["Project", "Process", "UnitOperation", "Supplier", "ProcessParameter", ...super.getTypesToCache()];
  }

  getAttributeNames() {
    return ["ProcessParameter"];
  }

  updateChildAttributes(childAttributes) {
    this.setStateSafely({
      processParameters: childAttributes.filter(childAttribute => childAttribute.typeCode === "PP" && !childAttribute.StepId)
    });
  }

  getAttributeParentId() {
    return "UnitOperationId";
  }

  initializeValue(attributeName, attributeValue) {
    // Uncomment for verbose logging
    // console.log("Setting previous unit: ", attributeName, attributeValue);

    // Initialize the previous unit with the latest one
    if (attributeName === "PreviousUnitId" && this.isAdd()) {
      let previousUnitId = UIUtils.getParameterByName("prevUOId");
      if (previousUnitId) {
        super.initializeValue(attributeName, UIUtils.parseInt(previousUnitId));
      } else {
        super.initializeValue(attributeName, attributeValue);
      }
    } else {
      super.initializeValue(attributeName, attributeValue);
    }
  }

  getInfoTooltip() {
    return (
      <InfoTooltip id="infoUO"
                   fdaGuidanceURL={ReferenceDocuments.Q11_GUIDELINE}
                   fdaGuidancePage={11}
                   fdaGuidanceOffset={190}
                   verbiage={
                     <div>
                       A design space might be determined per unit operation (e.g. reaction, crystallization,
                       distillation, purification), or a combination of selected unit operations. The unit operations
                       included in such a design space should generally be selected based on their impact on CQAs and do
                       not necessarily need to be sequential. The linkages between process steps should be evaluated so
                       that, for example, the cumulative generation and removal of impurities is controlled. A design
                       space that spans multiple unit operations can provide more operational flexibility.
                     </div>
                   }
      />
    );
  }

  getTabName() {
    return "Process Explorer";
  }

  beforeDataSavedToServer(callback) {
    if (this.state.PreviousUnitId === "Start") {
      this.setStateSafely({PreviousUnitId: null}, () => super.beforeDataSavedToServer(callback));
    } else {
      super.beforeDataSavedToServer(callback);
    }
  }

  handleSaveResults(result) {
    if (this.getEditorType() !== EDITOR_TYPES.STATIC_PANEL) { // The main panel will take care of reloading typeaheads.
      const cache = new TypeaheadObjectCache("UnitOperation", this.getProjectId(), this.getProcessId());
      cache.invalidateCacheOptionsAsync().then(() => {
        cache.loadOptions(() => {
          super.handleSaveResults(result);
        }, {useWriterDB: true});
      });
    } else {
      super.handleSaveResults(result);
    }
  }

  handleSendApproval(approve) {
    return super.handleSendApproval(approve).then((approvalCompletedSuccessfully) => {
      if (approvalCompletedSuccessfully) {
        const cache = new TypeaheadObjectCache("UnitOperation", this.getProjectId(), this.getProcessId());
        cache.invalidateCacheOptionsAsync().then(() => {
          cache.loadOptions(() => {
            this.forceUpdateSafely();
          });
        });
      }
    });
  }

  shouldCollapseAllSections() {
    return false;
  }

  filterOutSelf(option) {
    return option.id !== this.getAttributeParentIdValue();
  }

  renderAttributes() {
    return (
      <div>
        <Section parent={this}
                 header={this.getGeneralHeader()}
                 collapsible={false}
                 showDocLinks={true}
        >
          <div className="row">
            <TextAttribute name="name"
                           parent={this}
            />
            <TypeaheadAttribute name="process"
                                default={this.getProcessId()}
                                typeaheadType="Process"
                                target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                                instructions={`This can only be modified on add.`}
                                disabled={!this.isAdd()}
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
                                projectId={this.getProjectId()}
                                processId={this.getProcessId()}
            />
            <TextAreaAttribute name="description"
                               className="col-sm-12"
                               parent={this}
            />
          </div>
          <div className="row">
            <TypeaheadAttribute name="previousUnit"
                                typeaheadType="UnitOperation"
                                options={["Start"]}
                                emptyOption={"Start"}
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
                                filter={this.filterOutSelf}
                                projectId={this.getProjectId()}
                                processId={this.getProcessId()}
            />
            <TypeaheadAttribute name="supplier"
                                typeaheadType="Supplier"
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
            />
          </div>
          <div className="row">
            <TextAttribute name="input"
                           tooltipText="Enter the input(s) to the Unit Operation (e.g. could be raw materials or output of a previous unit operation)"
                           parent={this}
            />
            <TextAttribute name="output"
                           tooltipText="Enter the output(s) of the Unit Operation (e.g. could be a bulk intermediate solution)"
                           parent={this}
            />
          </div>
          <div className="row">
            <ComboBoxAttribute name="risk"
                               options={["Very High", "High", "Medium", "Low", "Unknown"]}
                               tooltipText="Composite risk for entire unit operation"
                               default="Unknown"
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
          </div>
        </Section>
        {this.renderTechTransferAssessmentSection()}
        {this.renderTagsSection()}
      </div>
    );
  }
}

export default I18NWrapper.wrap(UnitOperation, ["process_explorer", "editor"]);
// i18next-extract-mark-ns-stop process_explorer
