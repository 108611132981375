var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, Icon } from '@progress/kendo-react-common';
import { Reveal } from '@progress/kendo-react-animation';
/**
 * @hidden
 */
function iconElement(_a) {
    var imageUrl = _a.imageUrl, icon = _a.icon, iconClass = _a.iconClass;
    if (imageUrl) {
        return (React.createElement("img", { role: "presentation", className: 'k-panelbar-item-icon k-image', src: imageUrl }));
    }
    else if (icon) {
        var iconClasses = classNames('k-panelbar-item-icon', 'k-icon', 'k-i-' + icon);
        return (React.createElement("span", { role: "presentation", className: iconClasses }));
    }
    else if (iconClass) {
        return (React.createElement("span", { role: "presentation", className: 'k-panelbar-item-icon ' + iconClass }));
    }
    return null;
}
var PanelBarItem = /** @class */ (function (_super) {
    __extends(PanelBarItem, _super);
    function PanelBarItem(props) {
        var _this = _super.call(this, props) || this;
        _this.handleItemClick = function () {
            var _a = _this.props, onSelect = _a.onSelect, disabled = _a.disabled, id = _a.id;
            if (onSelect && !disabled) {
                onSelect.call(undefined, {
                    uniquePrivateKey: _this.props.uniquePrivateKey,
                    id: id,
                    target: _this
                });
            }
        };
        _this.childFactory = function (child) {
            if (_this.props.keepItemsMounted) {
                return React.cloneElement(child, __assign(__assign({}, child.props), { in: _this.props.expanded }));
            }
            return child;
        };
        _this.state = {
            show: props.expanded || false
        };
        return _this;
    }
    /**
     * @hidden
     */
    PanelBarItem.prototype.render = function () {
        var _this = this;
        var _a = this.props, id = _a.id, children = _a.children, title = _a.title, uniquePrivateKey = _a.uniquePrivateKey, disabled = _a.disabled, selected = _a.selected, focused = _a.focused, expanded = _a.expanded, className = _a.className, level = _a.level, headerClassName = _a.headerClassName, animation = _a.animation, keepItemsMounted = _a.keepItemsMounted;
        var panelBarItemAriaAttributes = {
            'role': 'treeitem',
            'aria-disabled': disabled,
            'aria-hidden': !disabled && !expanded,
            'aria-selected': !disabled && selected,
            'aria-expanded': !disabled && expanded && !!children
        };
        var panelBarItemClassName = classNames('k-panelbar-item', {
            'k-panelbar-header': level === 0,
            'k-expanded': expanded && !!children,
            'k-disabled': disabled
        }, "k-level-".concat(level), className);
        var panelBarLinkClassName = classNames('k-link', {
            'k-selected': !disabled && selected,
            'k-focus': !disabled && focused
        }, headerClassName);
        var animationStyle = { display: 'block' };
        var icon = iconElement(this.props);
        var arrow = (!disabled && children)
            ? (React.createElement(Icon, { name: expanded ? 'chevron-up' : 'chevron-down', className: classNames('k-panelbar-toggle', expanded
                    ? 'k-panelbar-collapse'
                    : 'k-panelbar-expand') }))
            : null;
        var opened = (!disabled && expanded);
        var content = opened || keepItemsMounted
            ? (React.createElement("ul", { role: "group", className: "k-panelbar-group k-panel k-group", style: { display: keepItemsMounted
                        ? (this.state.show ? 'block' : 'none')
                        : 'block' } }, children))
            : null;
        var child = ((animation !== undefined ? animation : true) && !disabled && children)
            ? (React.createElement(Reveal, { transitionEnterDuration: 200, transitionExitDuration: 200, key: uniquePrivateKey + '_animation', style: animationStyle, children: content, childFactory: keepItemsMounted && this.childFactory, unmountOnExit: !keepItemsMounted, onBeforeEnter: function () { return keepItemsMounted && _this.setState({ show: true }); }, onAfterExited: function () { return keepItemsMounted && _this.setState({ show: false }); } }))
            : content;
        return (React.createElement("li", __assign({ id: id, className: panelBarItemClassName }, panelBarItemAriaAttributes),
            React.createElement("span", { className: panelBarLinkClassName, onClick: this.handleItemClick },
                icon,
                React.createElement("span", { className: 'k-panelbar-item-text' }, title),
                arrow),
            child));
    };
    /**
     * @hidden
     */
    PanelBarItem.propTypes = {
        animation: PropTypes.bool,
        children: PropTypes.any,
        className: PropTypes.string,
        expanded: PropTypes.bool,
        disabled: PropTypes.bool,
        onSelect: PropTypes.func,
        selected: PropTypes.bool,
        level: PropTypes.number,
        title: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element
        ]),
        id: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ]),
        focused: PropTypes.bool,
        keepItemsMounted: PropTypes.bool
    };
    /**
     * @hidden
     */
    PanelBarItem.defaultProps = {
        title: 'Untitled'
    };
    return PanelBarItem;
}(React.PureComponent));
export { PanelBarItem };
