"use strict";

import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import { RiskTooltip } from "./risk_tooltip";
import { getRiskScoreWithLabel } from "../utils/risk_tables_helper";
import RiskUtils from "../../../../server/common/misc/common_risk_utils";

export class RiskScore extends Component {
  render() {
    let {
      attribute,
      riskType,
      rmp,
      tooltipRiskType,
      showRawScore, labelAction
    } = this.props;

    if (!tooltipRiskType) {
      tooltipRiskType = riskType;
    }

    const tooltipRiskValue = attribute.riskInfo[tooltipRiskType].value;
    const tooltip = (<RiskTooltip type={tooltipRiskType} value={tooltipRiskValue} rmp={rmp} />);
    return (
      <span
        data-trigger="click hover"
        data-html="true"
        data-placement="right"
        data-container="body"
        data-toggle="popover"
        data-content={ReactDOMServer.renderToStaticMarkup(tooltip)}
      >
        {getRiskScoreWithLabel(attribute, riskType, rmp, showRawScore, labelAction, RiskUtils.NOT_ASSESSED_LABEL)}
      </span>
    );
  }
}
