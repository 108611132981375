import React from "react";
import * as UIUtils from "../../ui_utils";
import * as I18NWrapper from "../../i18n/i18n_wrapper";

import { DocumentsInTrainingTable } from "./documents_in_training_table";
import GroupableTablePlugin from "../../widgets/tables/tablePlugins/groupable_table_plugin";
import { TRAINING_EDITOR_TYPE } from "../editors/training_editor_attribute";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import { TrainingService } from "../services/training_service";

const Logger = Log.group(LOG_GROUP.Training, "DocumentsInTrainingTable");

const GROUP_NAME_COLUMN_INDEX = 1;
const GROUP_NAME_COLUMN_SPAN = 5;

// i18next-extract-mark-ns-start training
/**
 * This is responsible for rendering a list of curricula with each of their documents
 * and their status, with buttons to allow the documents to be retrained.
 */
class CurriculaInTrainingTable extends DocumentsInTrainingTable {

  constructor(props) {
    super(props);

    this.trainingService = new TrainingService();
    let groupColSpan = props.allowRetraining ? GROUP_NAME_COLUMN_SPAN : GROUP_NAME_COLUMN_SPAN - 1;
    this.groupableTablePlugin = new GroupableTablePlugin("curriculumTypeCode", "curriculumId", GROUP_NAME_COLUMN_INDEX, groupColSpan);
    this.isTableInitialized = false;
  }

  componentDidMount() {
    super.componentDidMount();
    this.groupableTablePlugin.registerTable(this.tableRef);
  }

  expandOrCollapseAllRows(expanded) {
    this.groupableTablePlugin.expandCollapseAllRows(expanded);
  }

  handleAfterReloadDataTable(table) {
    const {collapsed} = this.props;
    const expandAllRows = !collapsed;

    super.handleAfterReloadDataTable(table);

    this.expandOrCollapseAllRows(expandAllRows);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      super.shouldComponentUpdate(nextProps, nextState, nextContext)
      || this.detectChangeInArray(this.props.assignedCurricula, nextProps.assignedCurricula)
      || this.detectChangeInArray(this.props.allCurricula, nextProps.allCurricula)
      || this.props.collapsed !== nextProps.collapsed
    );
  }

  getColumns() {
    const columns = super.getColumns();
    columns.unshift(this.groupableTablePlugin.generateExpandIndicatorColumn());
    return columns;
  }

  getTableInitializationOptions() {
    return {
      dom: "<t>",
      data: this.prepareRecordsForDisplay(this.props.records),
      columns: this.getColumns(),
      rowCallback: (row, data) => {
        this.groupableTablePlugin.renderRow(row, data);

        if (data.typeCode === "DOC") {
          $(row).addClass("training-editor-read-only");
        }
      },
      paging: false,
      ordering: false,
      stateSave: false,
      autoWidth: false,
    };
  }

  sortItemsToDisplay(items) {
    let sortedItems = super.sortItemsToDisplay(items);
    this.groupDocumentsByCurricula(sortedItems);
    return sortedItems;
  }

  createItemsToDisplayContext() {
    const {allCurricula} = this.props;
    const curriculaToMap = allCurricula.filter(this.trainingService.isApprovedVersion);
    const curriculumMap = this.getEntriesMappedByField(curriculaToMap);
    return {
      ...super.createItemsToDisplayContext(),
      curriculumMap,
    };
  }

  applyRecordFilter(records) {
    const {type} = this.props;
    let result = super.applyRecordFilter(records);

    if (result && type === TRAINING_EDITOR_TYPE.DEPARTMENT_CURRICULUM) {
      // For department curricula, since we don't actually edit it here and we only
      // display what's in the training records, we can only show whatever has a valid training record.
      result = result.filter(rec => !!rec.trainingRecordId);
    }
    return result;
  }

  addExtraInformationToItemToDisplay(itemToDisplay, currentGroup, trainingRecord = undefined) {
    const {curriculum} = currentGroup;
    const item = super.addExtraInformationToItemToDisplay(itemToDisplay, currentGroup, trainingRecord);
    return Object.assign(itemToDisplay, {
      curriculumId: curriculum ? curriculum.id : null,
      curriculumName: curriculum ? curriculum.name : null,
      curriculum: curriculum || null,
      sortExpressionForView: `${curriculum.id.toString().padStart(6, "0")}-${item.sortExpressionForView}`,
    });
  }

  getTrainingRecordsToProcess(records, currentGroup) {
    const {type} = this.props;
    const {curriculum} = currentGroup;
    Logger.verbose(`>> Records to process (before): ${type.typeName}`, Log.object(records));

    const result = records.filter(rec => {
      if (type === TRAINING_EDITOR_TYPE.CURRICULUM) {
        return rec.curricula.includes(curriculum.id);
      } else if (type === TRAINING_EDITOR_TYPE.DEPARTMENT_CURRICULUM) {
        return rec.departmentCurricula.includes(curriculum.id);
      } else {
        throw new Error("This component must be used with Curricula and Department Curricula only.");
      }
    });

    Logger.verbose(`>> Records to process (after): ${type.typeName}`, Log.object(result));
    return result;
  }

  getGroupsOfItemsToDisplay(context) {
    let {assignedCurricula} = this.props;
    const {curriculumMap} = context;

    Logger.debug("Retrieving assigned curricula: ", Log.object(assignedCurricula), Log.object(curriculumMap));

    assignedCurricula = (assignedCurricula || []);
    let assignedCurriculaMap = new Map();

    for (let assignedCurriculum of assignedCurricula) {
      let curriculum;
      curriculum = curriculumMap.get(assignedCurriculum.CurriculumId);
      if (curriculum) {
        assignedCurriculaMap.set(curriculum.id, curriculum);
      } else {
        UIUtils.sendErrorTelemetry(new Error("Curriculum not found for assignment:"), assignedCurriculum);
      }
    }

    const uniqueAssignedCurricula = [...assignedCurriculaMap.values()];

    const result = uniqueAssignedCurricula.map(curriculum => {
      const assignedDocuments = curriculum.Documents || [];
      const assignedDocumentsMap = new Map(assignedDocuments.map(doc => ([doc.id, doc])));

      return {
        curriculum,
        assignedDocuments,
        assignedDocumentsMap,
        context,
      };
    });

    Logger.debug("Assigned curricula: ", Log.object(assignedCurriculaMap));
    return result;
  }

  groupDocumentsByCurricula(rows) {
    const {collapsed} = this.props;

    for (let index = 0; index < rows.length; index++) {
      const row = rows[index];
      let previousRow = rows[index - 1];

      const {curriculumId, isGroupRow} = row;

      if (isGroupRow || (previousRow && previousRow.isGroupRow)) {
        continue;
      }

      if (curriculumId && (!previousRow || previousRow.curriculumId !== curriculumId)) {
        const groupRow = this.groupableTablePlugin.createGroupRow(
          true,
          true,
          "CUR",
          curriculumId,
          UIUtils.getRecordCustomLabelForDisplay(row.curriculum),
        );
        groupRow.isExpanded = !collapsed;
        groupRow.typeCode = "CUR";
        groupRow.id = curriculumId;
        groupRow.linkToVersion = false;
        rows.splice(index, 0, {...row, ...groupRow});
      }
    }
  }

  getAttributeCellData(result) {
    return result.fullName || super.getAttributeCellData(result);
  }
}

CurriculaInTrainingTable.defaultProps = {
  ...(DocumentsInTrainingTable.defaultProps),
};

export default I18NWrapper.wrap(CurriculaInTrainingTable, ["training", "base_page"]);
// i18next-extract-mark-ns-stop training
