"use strict";

import React from "react";
import FilterDropdown from "../../../../widgets/dropdowns/filter_dropdown";
import * as styles from "../../../../configurableTables/tables/widgets/topBar/titleBar/table_title_bar.module.scss";
import { ProcessExplorerMoreActions } from "../../../process_explorer_title_bar";

export const TreeActionsArea = (
  {
    t,
    showArchived,
    projectId,
    onShowArchivedChange,
    recordCount,
    nonArchivedCount,
    loading
  }) => {

  return (
    <div className={styles["actions-container"]}>
      <div className="align-self-center show-archived-filter">
        <FilterDropdown title={showArchived ? t("{{ recordCount }} all, including archived", {recordCount}) : t("{{ nonArchivedCount }} all", {nonArchivedCount})}
                        showArchived={showArchived}
                        onShowArchivedChange={onShowArchivedChange}
                        isLoading={loading}
        />
      </div>
      <ProcessExplorerMoreActions projectId={projectId} />
    </div>
  );
};