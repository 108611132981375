"use strict";

import React, {} from "react";
import LabelTooltip from "./label_tooltip";


/**
 * This component is for showing a tooltip on a Link
 * @param {object} props
 * @param {string} props.id ID given to the tooltip
 * @param {string} props.link Link to redirect to upon clicking the link
 * @param {string} props.text The text that will be displayed on the screen
 * @param {string} props.tooltipText Text that appears in the popover
 */
const LinkTooltip = (props: { id: string; tooltipText: string; link: string; text: string; }) => {

  return <LabelTooltip id={props.id}
                       className="font-weight-bold line-height-32 mb-0"
                       showOnHover={true}
                       noColon={true}
                       tooltipText={props.tooltipText}
                       moreInfo={<a href={props.link}>{props.text}</a>}
  />;
};

export default LinkTooltip;