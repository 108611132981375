"use strict";

import ConfigurableTablesExport from "../../configurableTables/export/configurable_tables_export";
import TechTransferExportColumnGenerator from "./tech_transfer_export_column_generator";
import * as tech_transfer_tables_export from "./files/tech_transfer_tables_report.mrt";

export default class TechTransferExport extends ConfigurableTablesExport {
  constructor(props) {
    super(props);
    this.fromProcess = props.fromProcess;
    this.toProcess = props.toProcess;
  }

  get columnGenerator() {
    return TechTransferExportColumnGenerator;
  }

  get subTitle() {
    return "Sending - Receiving";
  }

  get exportTemplateName() {
    return tech_transfer_tables_export;
  }

  getProcessedRecords(record, visibleColumns) {
    const {
      selectedModelType,
      fromProcess,
      toProcess,
    } = this;

    const exportColumnGenerator = new this.columnGenerator({
      fromProcess,
      toProcess,
      selectedModelType,
      record,
    });

    const instances = [];
    const variable = exportColumnGenerator.getVariableColumnLabel();
    for (const column of visibleColumns) {
      exportColumnGenerator.column = column;
      const value = exportColumnGenerator.getColumnValue();
      instances.push({
        columnName: exportColumnGenerator.getColumnTitle(),
        variable,
        ...value,
        backgroundColor: this.getBackgroundColor(record, value),
      });
    }
    return instances;
  }

  getBackgroundColor(record, value) {
    const {backgroundColor} = value;
    const {isGroupRow} = record;
    return backgroundColor || (isGroupRow ? "tile" : "grey");
  }
}
