"use strict";

/*
This configuration file contains the main rules for each model type. That includes
- Table
- Review Split Screen
- Diff Configuration
- Data Adapter
- Naming and Security
 */

import React from "react";
import CommonSecurity from "../../server/common/generic/common_security";

import TechTransferMaterialAttributeReview from "./review/records/tech_transfer_material_attribute_review";
import TechTransferProcessParameterReview from "./review/records/tech_transfer_process_parameter_review";
import TechTransferProcessComponentReview from "./review/records/tech_transfer_process_component_review";
import TechTransferMaterialReview from "./review/records/tech_transfer_material_review";
import TechTransferIntermediateAttributeReview from "./review/records/tech_transfer_intermediate_attribute_review";

import ProcessParameterFields
  from "../configurableTables/fieldsConfig/variables/process_parameter_fields";
import MaterialAttributeFields
  from "../configurableTables/fieldsConfig/variables/material_attribute_fields";
import ProcessComponentFields
  from "../configurableTables/fieldsConfig/assets/process_component_fields";
import MaterialFields
  from "../configurableTables/fieldsConfig/assets/material_fields";

import TechTransferVariableAdapter from "./adapters/tech_transfer_variable_adapter";
import TechTransferVariableParentAdapter from "./adapters/tech_transfer_variable_parent_adapter";
import TechTransferTable from "./tables/tech_transfer_table";
import TechTransferVariableParentTable from "./tables/tech_transfer_variable_parent_table";
import IntermediateAttributeFields from "../configurableTables/fieldsConfig/variables/intermediate_attribute_fields";

export const TECH_TRANSFER_MODELS_CONFIG = {
  ["Process Parameters"]: {
    table: (props) => <TechTransferTable {...props} modelName={"Process Parameter"} />,
    review: (props) => <TechTransferProcessParameterReview {...props} />,
    adapter: (params) => new TechTransferVariableAdapter(params,
      "ppMap", "Process Parameters"),
    fieldsConfig: new ProcessParameterFields(null, null, true),
    modelName: "ProcessParameter",
    securityModelType: CommonSecurity.Types.PROCESS_PARAMETER,
  },
  ["Material Attributes"]: {
    table: (props) => <TechTransferTable {...props} modelName={"Material Attribute"} />,
    review: (props) => <TechTransferMaterialAttributeReview {...props} />,
    adapter: (params) => new TechTransferVariableAdapter(params,
      "maMap", "Material Attributes"),
    fieldsConfig: new MaterialAttributeFields(null, null, true),
    modelName: "MaterialAttribute",
    securityModelType: CommonSecurity.Types.MATERIAL_ATTRIBUTE,
  },
  ["Process Components"]: {
    table: (props) => <TechTransferVariableParentTable {...props} modelName={"Process Component"} />,
    review: (props) => <TechTransferProcessComponentReview {...props} />,
    adapter: (params) => new TechTransferVariableParentAdapter(params,
      "prcMap", "Process Components", "ProcessComponentId"),
    fieldsConfig: new ProcessComponentFields(null, null, true),
    modelName: "ProcessComponent",
    securityModelType: CommonSecurity.Types.PROCESS_COMPONENT,
  },
  ["Materials"]: {
    table: (props) => <TechTransferVariableParentTable {...props} modelName={"Material"} />,
    review: (props) => <TechTransferMaterialReview {...props} />,
    adapter: (params) => new TechTransferVariableParentAdapter(params,
      "mtMap", "Materials", "MaterialId"),
    fieldsConfig: new MaterialFields(null, null, true),
    modelName: "Materials",
    securityModelType: CommonSecurity.Types.MATERIAL,
  },
  ["IQAs"]: {
    table: (props) => <TechTransferTable {...props} modelName={"IQA"} />,
    review: (props) => <TechTransferIntermediateAttributeReview {...props} />,
    adapter: (params) => new TechTransferVariableAdapter(params,
      "iqaMap", "IQAs"),
    fieldsConfig: new IntermediateAttributeFields("IQAs", null, true),
    modelName: "IQA",
    securityModelType: CommonSecurity.Types.IQA,
  },
  ["IPAs"]: {
    table: (props) => <TechTransferTable {...props} modelName={"IPA"} />,
    review: (props) => <TechTransferIntermediateAttributeReview {...props} />,
    adapter: (params) => new TechTransferVariableAdapter(params,
      "ipaMap", "IPAs"),
    fieldsConfig: new IntermediateAttributeFields("IPAs", null, true),
    modelName: "IPA",
    securityModelType: CommonSecurity.Types.IPA,
  },
};
