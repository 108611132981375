"use strict";

import React from "react";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { FROM_LIBRARY_STATUS, LIBRARY_MATERIAL_TYPE_CODE } from "../library_constants";
import { createHTMLForTextDiff } from "../../helpers/diff_helper";
import Section from "../../editor/widgets/section";
import { ACTION_TO_ICON_ENUM, EMPTY_STRING } from "../../helpers/constants/constants";
import LibraryOptionButton from "./library_option_button";
import { LibraryHelper, MATERIAL_ACTIONS } from "../helpers/library_helper";
import { VERSION_STATES } from "../../../server/common/generic/common_utils";
import LibraryStatusTooltip from "./library_status_tooltip";
import ReactDOMServer from "react-dom/server";
import * as UIUtils from "../../ui_utils";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Build the action button definitions based on the Project Material status..
 * @param material
 */
function buildOptions(material) {
  const options = [];
  const {fromLibraryStatus} = material;

  function addOption(title, subTitle, action, id, onClick) {
    options.push({
      title,
      subTitle,
      action,
      id,
      onClick,
    });
  }

  if (fromLibraryStatus === FROM_LIBRARY_STATUS.SYNCED || fromLibraryStatus === FROM_LIBRARY_STATUS.LINKED) {
    addOption(
      MATERIAL_ACTIONS.SWAP,
      (
        <span>
          Change the Library Material that this record <br /> is synced or linked to.
        </span>
      ),
      ACTION_TO_ICON_ENUM.SWAP_MATERIAL,
      "swapButton",
      () => LibraryHelper.onSwapWithLibraryClick(material)
    );

    addOption(null, null, ACTION_TO_ICON_ENUM.SEPARATOR);

    if (fromLibraryStatus === FROM_LIBRARY_STATUS.SYNCED) {
      addOption(
        MATERIAL_ACTIONS.UNSYNC,
        (
          <span>
            This record will no longer show changes made to the Library Material, <br /> but will stay linked for reference. Material Attributes will <br /> stay linked to Specifications.
          </span>
        ),
        ACTION_TO_ICON_ENUM.UNSYNC_MATERIAL,
        "unsyncButton",
        () => LibraryHelper.onUnsyncWithLibraryClick(material.id, material.ProjectId, material.ProcessId)
      );
      addOption(null, null, ACTION_TO_ICON_ENUM.SEPARATOR);
    }

    addOption(
      MATERIAL_ACTIONS.UNLINK,
      (
        <span>
          This record will no longer be connected to the Library. <br /> Material Attributes will be unlinked from Specifications.
        </span>
      ),
      ACTION_TO_ICON_ENUM.UNLINK_MATERIAL,
      "unlinkButton",
      () => LibraryHelper.onUnlinkClick(material)
    );
    addOption(null, null, ACTION_TO_ICON_ENUM.SEPARATOR);
  }

  if (fromLibraryStatus === FROM_LIBRARY_STATUS.LINKED) {
    addOption(
      MATERIAL_ACTIONS.SYNC,
      (
        <span>
          A synced Material will automatically update to <br /> show changes made in the Library.
        </span>
      ),
      ACTION_TO_ICON_ENUM.SYNC_MATERIAL,
      "syncButton",
      () => LibraryHelper.onSyncWithLibraryClick(material.id, material.ProjectId, material.ProcessId)
    );
  }

  return options;
}

function generateTooltipText(status) {
  let tooltipText;
  switch (status) {
    case FROM_LIBRARY_STATUS.SYNCED:
      tooltipText = "This Material is synced to the Library and will automatically update to show changes made in the Library.";
      break;
    case FROM_LIBRARY_STATUS.LINKED:
      tooltipText = "This Material is linked to the Library and will not automatically update to show changes made in the Library. Refer to the Library to view updated information.";
      break;
  }
  return tooltipText;
}

function getStatusText(status) {
  if (!status || status === FROM_LIBRARY_STATUS.PROJECT_ONLY) {
    return EMPTY_STRING;
  }
  return status;
}

function renderDisabledLink(anchorText, records) {

  const tooltipWarning = ReactDOMServer.renderToString(
    <div className={"p-2"}>
      <div>
        Materials shared with other Unit Operation(s) / Step(s) cannot be synced/linked to the Library.
        Only one of the following items may remain. All others must be removed from the record to use a Material from the Library:
      </div>
      <br />
      <ul>
        {records.map((record, index) => {
          return <li key={index}>
            {UIUtils.getRecordCustomLabelForDisplay(record)}
          </li>;
        })}
      </ul>
    </div>
  );

  return (
    <span id={"useFromLibraryTooltip"}
          data-toggle="popover"
          data-html="true"
          data-container="body"
          data-placement={"auto"}
          data-trigger="click hover"
          data-template={ReactDOMServer.renderToString(
            <div className="popover" role="tooltip">
              <div className="arrow" />
              <h3 className="popover-header" />
              <div className="popover-body p-0" />
            </div>
          )}
          data-content={tooltipWarning}
          data-boundary="viewport"
    >
      <a id="useMaterialLink" className={"disabled-link"}>{anchorText}</a>
      <FontAwesomeIcon icon={faInfoCircle} />
    </span>
  );
}

/**
 * Display the link information (Synced|Linked|Project Only) with the library material (Status, name, etc...)
 * @param fromLibraryStatus
 * @returns {JSX.Element || string}
 * @constructor
 */
export const LibraryStatusBar = ({record, getOlderVersion, isDiffingVersions}) => {

  const {
    fromLibrary,
    fromLibraryStatus,
    isLoading,
    currentState,
    UnitOperations,
    Steps,
    isUseFromLibraryDisabled,
  } = record;

  const onSyncWithLibraryClick = () => {
    LibraryHelper.onSyncWithLibraryClick(record.id, record.ProjectId, record.ProcessId);
  };

  const renderLink = (anchorText) => {
    return (
      <a
        id="useMaterialLink"
        title="You can link this material to library for easy management"
        rel="noreferrer noopener"
        onClick={onSyncWithLibraryClick}
      >
        {anchorText}
      </a>
    );
  };

  if (
    !isDiffingVersions() &&
    (!fromLibraryStatus || fromLibraryStatus === FROM_LIBRARY_STATUS.PROJECT_ONLY)
  ) {
    const anchorText = "Use material from library";
    return (
      <div className="library-status-bar-outer-div">
        <Section>
          <div className={`row library-status-bar ${isLoading ? "skeleton" : ""}`}>
            <div className={`mr-auto ${isUseFromLibraryDisabled ? "disabled" : ""}`}>
            <span className="font-weight-bold">
              {isUseFromLibraryDisabled ? renderDisabledLink(anchorText, [...UnitOperations, ...Steps]) : renderLink(anchorText)}
            </span>
            </div>
          </div>
        </Section>
      </div>
    );
  }

  const {name, instanceId, majorVersion, minorVersion} = fromLibrary ?? {};

  let nameElement = null;
  let minorVersionElement = null;
  let majorVersionElement = null;
  let fromLibraryStatusElement = null;

  if (isDiffingVersions()) {
    if (!fromLibraryStatus || fromLibraryStatus === FROM_LIBRARY_STATUS.PROJECT_ONLY) {
      return EMPTY_STRING;
    }
    const olderVersion = getOlderVersion();
    fromLibraryStatusElement = createHTMLForTextDiff(getStatusText(olderVersion?.fromLibraryStatus), getStatusText(fromLibraryStatus));
    nameElement = createHTMLForTextDiff(olderVersion?.fromLibrary?.name, name);
    minorVersionElement = createHTMLForTextDiff(olderVersion?.fromLibrary?.minorVersion, minorVersion);
    majorVersionElement = createHTMLForTextDiff(olderVersion?.fromLibrary?.majorVersion, majorVersion);
  } else {
    nameElement = <span>{name}</span>;
    fromLibraryStatusElement = <span>{fromLibraryStatus}</span>;
    minorVersionElement = <span>{minorVersion}</span>;
    majorVersionElement = <span>{majorVersion}</span>;
  }

  const allowedStatuses = [
    VERSION_STATES.APPROVED,
    VERSION_STATES.DRAFT,
    VERSION_STATES.WITHDRAWN,
    VERSION_STATES.WITHDRAWN_CASCADED,
    VERSION_STATES.REJECTED,
    VERSION_STATES.REJECTED_CASCADED,
    VERSION_STATES.RESTORED,
    VERSION_STATES.RESTORED_CASCADED,
  ];

  return (
    <div className="library-status-bar-outer-div">
      <Section>
        <div className={"row library-status-bar" + (isLoading ? " skeleton" : "")}>
        <span className="font-weight-bold">
          <LibraryStatusTooltip
            id="libraryStatusInfo"
            verbiage={
              <div>
                {generateTooltipText(fromLibraryStatus)}
              </div>
            }
            textDirection="right"
            status={fromLibraryStatusElement}
          />
        </span>
          <span className="ml-1">with library</span>
          <span className="ml-1 font-weight-bold">
          <a
            id="libraryName"
            href={getURLByTypeCodeAndId(
              LIBRARY_MATERIAL_TYPE_CODE,
              "View",
              instanceId,
              true,
            )}
            rel="noreferrer noopener"
            target="_blank"
            className="view-library-material-cell"
          >
            {nameElement} v{majorVersionElement}.{minorVersionElement}
          </a>
        </span>
          {allowedStatuses.includes(currentState) && !isDiffingVersions() ? (
            <LibraryOptionButton
              key="libraryOptionsButton"
              id="libraryOptionsButton"
              options={buildOptions(record)}
            />
          ) : (
            EMPTY_STRING
          )}
        </div>
      </Section>
    </div>
  );
};