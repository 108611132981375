var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { DrawerContext } from './context/DrawerContext';
import { DrawerItem } from './DrawerItem';
import { classNames } from '@progress/kendo-react-common';
/**
 * @hidden
 */
var DEFAULT_EXPANDED_WIDTH = 240;
/**
 * @hidden
 */
var DEFAULT_MINI_WIDTH = 50;
/**
 * @hidden
 */
var DEFAULT_ANIMATION = { type: 'slide', duration: 200 };
/**
 * @hidden
 */
var NO_ANIMATION = { type: 'slide', duration: 0 };
/**
 * Represents the [KendoReact Drawer component]({% slug overview_drawer %}).
 *
 * @example
 * ```jsx
 *   const App = () => {
 *       const [expanded, setExpanded] = React.useState(true);
 *       const handleClick = () => setExpanded(prevState => !prevState);
 *
 *       return (
 *           <Drawer expanded={expanded} position='start' mode='push'>
 *               <DrawerNavigation>
 *                   <ul className="k-drawer-items">
 *                       <li className="k-drawer-item k-selected">
 *                           <span className="k-item-text">Home</span>
 *                       </li>
 *                       <li className="k-drawer-item">
 *                           <span className="k-item-text">Products</span>
 *                       </li>
 *                       <li className="k-drawer-item">
 *                           <span className="k-item-text">About</span>
 *                       </li>
 *                   </ul>
 *               </DrawerNavigation>
 *               <DrawerContent>
 *                   <button className="k-button" onClick={handleClick}>Toggle the drawer state</button>
 *               </DrawerContent>
 *           </Drawer>
 *       );
 *   };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var DrawerNavigation = React.forwardRef(function (props, target) {
    var children = props.children, className = props.className, style = props.style;
    var _a = React.useContext(DrawerContext), animation = _a.animation, expanded = _a.expanded, mode = _a.mode, position = _a.position, onOverlayClick = _a.onOverlayClick, mini = _a.mini, dir = _a.dir, width = _a.width, miniWidth = _a.miniWidth, items = _a.items, item = _a.item, onSelect = _a.onSelect;
    var elementRef = React.useRef(null);
    var focusElement = React.useCallback(function () {
        if (elementRef.current) {
            elementRef.current.focus();
        }
    }, []);
    var customSettings = typeof animation !== 'boolean' ? animation : animation === false ? NO_ANIMATION : DEFAULT_ANIMATION;
    var navWidth = width ? width : DEFAULT_EXPANDED_WIDTH;
    var navMiniWidth = miniWidth ? miniWidth : DEFAULT_MINI_WIDTH;
    React.useImperativeHandle(target, function () { return ({
        element: elementRef.current,
        focus: focusElement
    }); });
    var navigationClassNames = React.useMemo(function () { return 'k-widget k-drawer ' + classNames({
        'k-drawer-start': position === 'start',
        'k-drawer-end': position === 'end'
    }, className); }, [position]);
    var expandPush = {
        opacity: 1,
        flexBasis: navWidth,
        WebkitTransition: 'all ' + (customSettings && customSettings.duration) + 'ms',
        transition: 'all ' + (customSettings && customSettings.duration) + 'ms'
    };
    var expandOverlay = {
        opacity: 1,
        transform: 'translateX(0px)',
        WebkitTransition: 'all ' + (customSettings && customSettings.duration) + 'ms',
        transition: 'all ' + (customSettings && customSettings.duration) + 'ms'
    };
    var collapsePush = {
        opacity: !mini ? 0 : 1,
        flexBasis: !mini ? 0 : navMiniWidth,
        WebkitTransition: 'all ' + (customSettings && customSettings.duration) + 'ms',
        transition: 'all ' + (customSettings && customSettings.duration) + 'ms'
    };
    var collapseOverlay = {
        opacity: 0,
        transform: 'translateX(-100%)',
        WebkitTransition: 'all ' + (customSettings && customSettings.duration) + 'ms',
        transition: 'all ' + (customSettings && customSettings.duration) + 'ms'
    };
    var collapseOverlayRtl = {
        opacity: 0,
        transform: 'translateX(100%)',
        WebkitTransition: 'all ' + (customSettings && customSettings.duration) + 'ms',
        transition: 'all ' + (customSettings && customSettings.duration) + 'ms'
    };
    var collapseOverlayMini = {
        transform: 'translateX(0%)',
        WebkitTransitionDuration: (customSettings && customSettings.duration) + 'ms',
        transitionDuration: (customSettings && customSettings.duration) + 'ms'
    };
    var drawerAnimation = expanded ?
        mode === 'push' ? expandPush : expandOverlay :
        mode === 'push' ? collapsePush :
            (dir === 'ltr' && position === 'start') || (dir === 'rtl' && position === 'end') ?
                mini ? collapseOverlayMini : collapseOverlay :
                mini ? collapseOverlayMini : collapseOverlayRtl;
    var drawerItems = (items && (React.createElement("ul", { className: 'k-drawer-items', role: "listbox", "aria-label": "drawer-list", "aria-expanded": expanded }, items
        .map(function (element, index) {
        var drawerItemProps = __assign(__assign({ index: index }, element), { onSelect: onSelect });
        var ItemComp = item || DrawerItem;
        return (React.createElement(ItemComp, __assign({ key: index }, drawerItemProps)));
    }))));
    var drawerNavigation = (React.createElement("div", { style: customSettings ? __assign(__assign({}, drawerAnimation), style) : style, className: navigationClassNames, ref: elementRef },
        React.createElement("div", { className: 'k-drawer-wrapper', style: !expanded && mini && mode === 'overlay' ? { width: navMiniWidth } : { width: navWidth } }, drawerItems || children)));
    return (mode === 'overlay' ?
        (React.createElement(React.Fragment, null,
            expanded && React.createElement("div", { className: "k-overlay", onClick: onOverlayClick }),
            drawerNavigation))
        : drawerNavigation);
});
DrawerNavigation.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.object,
    item: PropTypes.any,
    tabIndex: PropTypes.number
};
DrawerNavigation.displayName = 'KendoDrawerNavigation';
