"use strict";

import { TEXT_DIFF_METHOD_ENUM } from "../../helpers/constants/constants";
import { FIELD_INPUT_TYPE, FIELD_REQUIRED_FOR } from "./widget_field";

/**
 * This defines the widget fields configuration for the editable record supporting documents widget.
 * @type {IWidgetField[]}
 */
export const WIDGET_FIELDS = [
  {
    fieldName: "link",
    displayName: "Source Documents",
    requiredFor: FIELD_REQUIRED_FOR.save,
    forceUpdate: false,
    defaultValue: "",
    belongsToMasterRow: true,
    order: 1,
    orderable: false,
    inputType: FIELD_INPUT_TYPE.link,
  },
  {
    fieldName: "name",
    diffMethod: TEXT_DIFF_METHOD_ENUM.differential,
    displayName: "Name",
    requiredFor: FIELD_REQUIRED_FOR.save,
    forceUpdate: false,
    defaultValue: "",
    placeholder: "",
    belongsToMasterRow: true,
    order: 2,
    inputType: FIELD_INPUT_TYPE.text,
  },
  {
    fieldName: "appliesTo",
    diffMethod: TEXT_DIFF_METHOD_ENUM.differential,
    displayName: "Applies To",
    requiredFor: FIELD_REQUIRED_FOR.none,
    forceUpdate: false,
    defaultValue: [],
    placeholder: "",
    belongsToMasterRow: true,
    order: 3,
    orderable: false,
    multiSelect: true,
    getOptions: "getAppliesToOptions",
    inputType: FIELD_INPUT_TYPE.typeahead,
    singleLineOptions: true,
  },
  {
    fieldName: "description",
    diffMethod: TEXT_DIFF_METHOD_ENUM.differential,
    displayName: "Description",
    requiredFor: FIELD_REQUIRED_FOR.none,
    forceUpdate: false,
    defaultValue: "",
    placeholder: "",
    belongsToMasterRow: false,
    order: 4,
    rows: 4,
    inputType: FIELD_INPUT_TYPE.textarea,
  }
];