/**
 * @hidden
 *
 * Render the svg <path> element.
 *
 * @param points (array) Represents the points coordinates as an array of tuples.
 * @param command (function) The command that is used (bezierCommand, lineCommand).
 *      @param point (array) [x,y] Represents the current point coordinates.
 *      @param i (integer) Represents the index of 'point' in the array 'a'.
 *      @param a (array) Represents the complete array of points coordinates.
 *      @output (string) a svg path command.
 * @output (string) a Svg <path> element
 */
export var svgPath = function (points, command) {
    if (points.length === 0) {
        return '';
    }
    // build the d attributes by looping over the points
    var d = points.reduce(function (acc, point, i, a) {
        return i === 0 ?
            // if first point
            "M ".concat(point[0], ",").concat(point[1]) :
            // else
            "".concat(acc, " ").concat(command(point, i, a));
    }, '');
    return "<path d=\"".concat(d, "\" fill=\"none\" stroke=\"white\" stroke-width=\"1\"/>");
};
/**
 * @hidden
 *
 * Returns the properties of a line.
 *
 * @param pointA (array) [x,y] Represents the start point coordinates.
 * @param pointB (array) [x,y] Represents the end point coordinates.
 * @output (object) { length: (integer), angle: (integer) }
 */
export var line = function (pointA, pointB) {
    var lengthX = pointB[0] - pointA[0];
    var lengthY = pointB[1] - pointA[1];
    return {
        length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
        angle: Math.atan2(lengthY, lengthX)
    };
};
/**
 * @hidden
 *
 * Create a function to calculate the position of the control point.
 *
 * @param lineCalc (function) Represents the line function.
 *      @param pointA (array) [x,y] Represents the start point coordinates.
 *      @param pointB (array) [x,y] Represents the end point coordinates.
 *      @output (object) { length: (integer), angle: (integer) }
 * @output (function) closure.
 *      @param current (array) [x, y] Represents the current point coordinates.
 *      @param previous (array) [x, y] Represents the previous point coordinates.
 *      @param next (array) [x, y] ]Represents the next point coordinates.
 *      @param reverse (boolean, optional) Sets the direction.
 *      @output (array) [x, y] coordinates of a control point.
 */
export var controlPoint = function (lineCalc) {
    return function (current, previous, next, reverse) {
        // when 'current' is the first or last point of the array
        // 'previous' and 'next' are undefined 
        // replace with 'current'
        var p = previous || current;
        var n = next || current;
        var smooth = 0.1;
        // properties of the line between previous and next 
        var l = lineCalc(p, n);
        // If is end-control-point, add PI to the angle to go backward
        var angle = l.angle + (reverse ? Math.PI : 0);
        var length = l.length * smooth;
        // The control point position is relative to the current point
        var x = current[0] + Math.cos(angle) * length;
        var y = current[1] + Math.sin(angle) * length;
        return [x, y];
    };
};
/**
 * @hidden
 *
 * Create a function to calculate a bezier curve command.
 *
 * @param controlPointCalc (function) Represents the controlPoint function.
 *      @param current (array) [x, y] Represents the current point coordinates.
 *      @param previous (array) [x, y] Represents the previous point coordinates.
 *      @param next (array) [x, y] ]Represents the next point coordinates.
 *      @param reverse (boolean, optional) Sets the direction.
 *      @output (array) [x, y] coordinates of a control point.
 * @output (function) closure.
 *      @param point (array) [x,y] Represents the current point coordinates.
 *      @param i (integer) Represents the index of 'point' in the array 'a'.
 *      @param a (array) Represents the complete array of points coordinates.
 *      @output (string) 'C x2,y2 x1,y1 x,y' Cubic bezier command.
 */
export var bezierCommand = function (controlPointCalc) { return function (point, i, a) {
    // start control point
    var _a = controlPointCalc(a[i - 1], a[i - 2], point), cpsX = _a[0], cpsY = _a[1];
    // end control point
    var _b = controlPointCalc(point, a[i - 1], a[i + 1], true), cpeX = _b[0], cpeY = _b[1];
    return "C ".concat(cpsX, ",").concat(cpsY, " ").concat(cpeX, ",").concat(cpeY, " ").concat(point[0], ",").concat(point[1]);
}; };
