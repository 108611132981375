"use strict";

import { useLayoutEffect, useState } from "react";
import { debounce } from "lodash";
import { addWindowListener, removeWindowListener } from "../configurable_tables_helper";

/**
 * Custom Hook to calculate the Height of a container based on its position from the view port height.
 * @param ref
 * @param offset
 * @param debounceTime
 * @returns {height: number}
 */
function useAdjustHeight(ref, offset = 10, debounceTime = 150) {

  const [
    height,
    setHeight
  ] = useState(null);

  useLayoutEffect(() => {
    function adjustHeight() {
      if (ref.current) {
        const {top} = ref.current.getBoundingClientRect();
        setHeight(window.innerHeight - top - offset);
      }
    }

    /*
     * Be careful whenever you change something in below line, the adjustHeight has to be debounced to let the table gets the correct height.
     */
    const debouncedAdjustHeight = debounce(() => adjustHeight(), debounceTime);
    debouncedAdjustHeight();

    addWindowListener("resize", debouncedAdjustHeight);
    return () => removeWindowListener("resize", debouncedAdjustHeight);
  }, [ref, offset, debounceTime]);

  return height;
}

export default useAdjustHeight;
