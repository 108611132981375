var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { PanelBarItem } from './PanelBarItem';
import { renderChildren, getInitialState, flatVisibleChildren, flatChildren, isArrayEqual, getFirstId } from './util';
import { classNames, Keys } from '@progress/kendo-react-common';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
import { NavigationAction } from './interfaces/NavigationAction';
var PanelBar = /** @class */ (function (_super) {
    __extends(PanelBar, _super);
    function PanelBar(props) {
        var _this = _super.call(this, props) || this;
        _this._element = null;
        /**
         * @hidden
         */
        _this.handleSelect = function (event) {
            _this.onSelect(event);
            _this.onFocus(event);
        };
        /**
         * @hidden
         */
        _this.onSelect = function (event) {
            var flattenChildren = flatChildren(React.Children.toArray(_this.children));
            var selectedChild;
            var newExpanded;
            flattenChildren.forEach(function (child) {
                if (child.props.uniquePrivateKey === (event.uniquePrivateKey || _this.state.focused)) {
                    selectedChild = child;
                }
            });
            switch (_this.expandMode) {
                case 'single':
                    newExpanded = __spreadArray(__spreadArray([], selectedChild.props.parentUniquePrivateKey, true), [selectedChild.props.uniquePrivateKey], false);
                    if (isArrayEqual(_this.expandedItems, newExpanded)) {
                        if (selectedChild.props.parentUniquePrivateKey) {
                            newExpanded = __spreadArray([], selectedChild.props.parentUniquePrivateKey, true);
                        }
                        else {
                            newExpanded = [];
                        }
                    }
                    break;
                case 'multiple':
                    newExpanded = _this.expandedItems.slice();
                    var index = newExpanded.indexOf(selectedChild.props.uniquePrivateKey);
                    index === -1 ? newExpanded.push(selectedChild.props.uniquePrivateKey) : newExpanded.splice(index, 1);
                    break;
                default:
                    newExpanded = _this.expandedItems.slice();
                    break;
            }
            _this.setState({ selected: selectedChild.props.uniquePrivateKey, expanded: newExpanded });
            if (_this.props.onSelect) {
                _this.props.onSelect.call(undefined, {
                    target: selectedChild,
                    expandedItems: newExpanded
                });
            }
        };
        /**
         * @hidden
         */
        _this.onFocus = function (event, step) {
            if (step === void 0) { step = 0; }
            var flattenChildren = flatVisibleChildren(React.Children.toArray(_this.children));
            var focusedChild;
            flattenChildren.forEach(function (child, ixd) {
                if (child.props.uniquePrivateKey === (event.uniquePrivateKey || _this.state.focused)) {
                    var index = ixd + step < 0
                        ? 0
                        : ixd + step > flattenChildren.length
                            ? flattenChildren.length - 1
                            : ixd + step;
                    focusedChild = flattenChildren[index];
                }
            });
            if (focusedChild) {
                _this.activeDescendant = focusedChild.props.id;
                _this.setState({ focused: focusedChild.props.uniquePrivateKey });
            }
        };
        /**
         * @hidden
         */
        _this.onNavigate = function (event, action) {
            var step;
            switch (action) {
                case NavigationAction.Previous:
                    step = -1;
                    _this.onFocus(event, step);
                    break;
                case NavigationAction.Next:
                    step = 1;
                    _this.onFocus(event, step);
                    break;
                case NavigationAction.Toggle:
                    _this.onSelect(event);
                    break;
                default:
            }
        };
        _this.handleWrapperFocus = function () {
            clearTimeout(_this.nextTickId);
            if (!_this.state.wrapperFocused) {
                _this.setState({ wrapperFocused: true });
            }
        };
        _this.handleWrapperBlur = function () {
            _this.nextTick(function () {
                _this.setState({ wrapperFocused: false });
            });
        };
        _this.handleKeyDown = function (event) {
            var rtl = _this._element && (getComputedStyle(_this._element).direction === 'rtl') || false;
            if (event.target === event.currentTarget) {
                var keyCode = event.keyCode;
                var action = void 0;
                switch (keyCode) {
                    case Keys.left:
                        action = !rtl ? NavigationAction.Previous : NavigationAction.Next;
                        break;
                    case Keys.up:
                        action = NavigationAction.Previous;
                        break;
                    case Keys.right:
                        action = !rtl ? NavigationAction.Next : NavigationAction.Previous;
                        break;
                    case Keys.down:
                        action = NavigationAction.Next;
                        break;
                    case Keys.space:
                    case Keys.enter:
                        action = NavigationAction.Toggle;
                        break;
                    default:
                        action = null;
                        break;
                }
                if (action !== null) {
                    event.preventDefault();
                    _this.onNavigate(event, action);
                }
            }
        };
        validatePackage(packageMetadata);
        var initialState = getInitialState(props, _this.expandMode);
        if (!initialState.focused) {
            initialState.focused = getFirstId(props);
        }
        _this.state = initialState;
        return _this;
    }
    Object.defineProperty(PanelBar.prototype, "expandMode", {
        get: function () {
            return this.props.expandMode || 'multiple';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PanelBar.prototype, "selectedItem", {
        get: function () {
            var _a = this.props.selected, selected = _a === void 0 ? this.state.selected : _a;
            return selected;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PanelBar.prototype, "expandedItems", {
        get: function () {
            var expanded = this.props.isControlled ? this.props.expanded || [] : this.state.expanded;
            return expanded;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PanelBar.prototype, "children", {
        get: function () {
            var state = __assign(__assign({}, this.state), { selected: this.selectedItem });
            var renderState = {
                animation: this.props.animation,
                keepItemsMounted: this.props.keepItemsMounted,
                state: state,
                expanded: this.expandedItems,
                handleSelect: this.handleSelect,
                children: this.props.children
            };
            return renderChildren(renderState);
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    PanelBar.prototype.render = function () {
        var _this = this;
        var ariaProps = {
            'aria-activedescendant': this.activeDescendant
        };
        var className = classNames('k-panelbar', this.props.className);
        return (React.createElement("ul", __assign({ ref: function (div) { _this._element = div; }, dir: this.props.dir, role: 'tree', tabIndex: 0, onKeyDown: this.handleKeyDown, onFocus: this.handleWrapperFocus, onBlur: this.handleWrapperBlur, className: className, style: this.props.style }, ariaProps), this.children));
    };
    PanelBar.prototype.nextTick = function (f) {
        this.nextTickId = window.setTimeout(function () { return f(); });
    };
    /**
     * @hidden
     */
    PanelBar.propTypes = {
        animation: PropTypes.bool,
        children: function (props, propName) {
            var prop = props[propName];
            if (prop) {
                if (Array.isArray(prop)) {
                    for (var _i = 0, prop_1 = prop; _i < prop_1.length; _i++) {
                        var value = prop_1[_i];
                        if (!value.type || value.type !== PanelBarItem) {
                            return new Error('PanelBar children should be either PanelBarItem or Array of PanelBarItem.');
                        }
                    }
                }
                else {
                    if (prop.type !== PanelBarItem) {
                        return new Error('PanelBar child should be either PanelBarItem or Array of PanelBarItem.');
                    }
                }
                return null;
            }
        },
        dir: PropTypes.string,
        selected: PropTypes.string,
        expanded: PropTypes.arrayOf(PropTypes.string),
        focused: PropTypes.string,
        expandMode: PropTypes.oneOf(['single', 'multiple']),
        className: PropTypes.string,
        keepItemsMounted: PropTypes.bool,
        onSelect: PropTypes.func,
        style: PropTypes.object
    };
    /**
     * @hidden
     */
    PanelBar.defaultProps = {
        expandMode: 'multiple',
        animation: true,
        keepItemsMounted: false
    };
    return PanelBar;
}(React.Component));
export { PanelBar };
