"use strict";
import { FIELD_INPUT_TYPE, FIELD_REQUIRED_FOR } from "../../editor/widgets/widget_field";
import { TEXT_DIFF_METHOD_ENUM } from "../../helpers/constants/constants";

/**
 * @type {IWidgetField[]}
 */
export const WIDGET_FIELDS = [
  {
    fieldName: "system",
    displayName: "System",
    order: 1,
    forceUpdate: false,
    belongsToMasterRow: true,
    defaultValue: [],
    requiredFor: FIELD_REQUIRED_FOR.save,
    diffMethod: TEXT_DIFF_METHOD_ENUM.differential,
    placeholder: "",
    inputType: FIELD_INPUT_TYPE.typeahead,
    multiSelect: false,
    getOptions: "getIntegrationSystems",
    width: "15%"
  },
  {
    fieldName: "name",
    displayName: "Name",
    forceUpdate: false,
    belongsToMasterRow: true,
    requiredFor: FIELD_REQUIRED_FOR.save,
    order: 2,
    inputType: FIELD_INPUT_TYPE.text,
  },
  {
    fieldName: "appliesTo",
    displayName: "Applies To",
    forceUpdate: false,
    belongsToMasterRow: true,
    requiredFor: FIELD_REQUIRED_FOR.save,
    diffMethod: TEXT_DIFF_METHOD_ENUM.differential,
    defaultValue: [],
    inputType: FIELD_INPUT_TYPE.customInput,
    multiSelect: false,
    order: 3,
    getOptions: "getAppliesToFields",
    renderCustomInput: "renderCustomInputForAppliesTo",
    width: "50%"
  },
  {
    fieldName: "description",
    displayName: "Description",
    forceUpdate: false,
    belongsToMasterRow: false,
    order: 4,
    inputType: FIELD_INPUT_TYPE.textarea,
  },
  {
    fieldName: "appliesToHtmlContent",
    belongsToMasterRow: true,
    defaultValue: "",
    order: 5,
    inputType: FIELD_INPUT_TYPE.text,
    hidden: true
  },
  {
    fieldName: "appliesToFields",
    belongsToMasterRow: true,
    defaultValue: "",
    order: 6,
    inputType: FIELD_INPUT_TYPE.text,
    hidden: true
  },

];

