"use strict";

import React, { Fragment } from "react";
import BasePage from "../base_page";
import * as UIUtils from "../ui_utils";
import ControlMethodsTablePage from "./control_methods_table_page";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import EditablesPageTitleBar from "../widgets/pageTitleBar/editables_page_title_bar";
import NavBar from "../widgets/bars/nav_bar";
import { CONTROL_METHODS_CONFIG } from "./tables/control_method_tables_config";
import { getProjectFromCache } from "../utils/project_helper";
import { ControlMethodConfigurableTablesExport } from "./tables/control_method_configurable_table_export";
import { filterFields } from "../configurableTables/columnSelection/configurable_tables_column_selection_helper";
import { process } from "@progress/kendo-data-query";
import { canViewPage } from "../utils/ui_permissions";
import CommonSecurity from "../../server/common/generic/common_security";
import SplashScreen from "../widgets/generic/splash_screen";
import TableTitleBar from "../configurableTables/tables/widgets/topBar/titleBar/table_title_bar";
import { NotificationProvider } from "../configurableTables/tables/widgets/notification/notification_provider";
import InfoNotification from "../configurableTables/tables/widgets/notification/types/info_notification";


class ControlMethodsPage extends BasePage {
  constructor(props) {
    super(props);
    this.projectId = UIUtils.parseInt(UIUtils.getParameterByName("projectId"));

    const selectedModelType = "Control Methods";
    const {modelName} = CONTROL_METHODS_CONFIG[selectedModelType];
    this.setStateSafely({
      breadcrumb: {
        current: selectedModelType,
      },
      modelName,
      selectedModelType
    });
  }

  componentDidMount() {
    document.title = "QbDVision Control Methods";

    super.componentDidMount();
  }

  handleVisibleColumnsChange(visibleTableColumns) {
    this.setStateSafely({visibleTableColumns});
  }

  handleTableStateChange(tableState) {
    this.setStateSafely({tableState});
  }

  handleExport() {
    const {
      tableData,
      columnOperationsAdapter,
      showArchived,
      visibleTableColumns,
      selectedModelType
    } = this.state;

    const thisProject = getProjectFromCache(this.projectId);
    const records = tableData ? process(tableData.slice(0),
      columnOperationsAdapter.adaptFilterNames(this.state)).data : [];


    new ControlMethodConfigurableTablesExport({
      selectedModelType,
      records,
      modelsConfig: CONTROL_METHODS_CONFIG,
      feature: "Control Method",
      projectName: thisProject.name,
      showArchived,
      visibleTableColumns: filterFields(visibleTableColumns || []),
    }).exportReport();
  }

  handleUpdateTableDependencies(dependencies) {
    this.setStateSafely(dependencies);
  }

  handleShowArchive(showArchived) {
    this.setStateSafely({showArchived});
    UIUtils.pushHistoryURLWithNewParameter({}, "showArchived", showArchived);
  }

  handleTableDataUpdated(tableData) {
    this.setStateSafely({tableData});
  }

  renderPage() {
    const {
      modelName,
      selectedModelType,
      showArchived,
    } = this.state;

    const {t} = this.props;
    const projectId = this.projectId;

    return (
      <Fragment>
        <EditablesPageTitleBar projectId={projectId} />
        <NavBar selected={selectedModelType}
                projectId={projectId}
        />
        {
          canViewPage(CommonSecurity.Types.CONTROL_METHOD) ?
            <>
              <NotificationProvider>
                <TableTitleBar t={t}
                               projectId={projectId}
                               modelName={modelName}
                               table={selectedModelType}
                               selectedModelType={selectedModelType}
                               onExport={this.handleExport}
                />
                <ControlMethodsTablePage projectId={projectId}
                                         modelName={modelName}
                                         showArchived={showArchived}
                                         onTableStateChange={this.handleTableStateChange}
                                         onUpdateTableDependencies={this.handleUpdateTableDependencies}
                                         onVisibleColumnsChange={this.handleVisibleColumnsChange}
                                         onTableDataUpdated={this.handleTableDataUpdated}
                                         selectedModelType={selectedModelType}
                                         handleShowArchive={this.handleShowArchive}
                />
                <InfoNotification />
              </NotificationProvider>
            </> : <SplashScreen />
        }
      </Fragment>
    );
  }
}

export default I18NWrapper.wrap(ControlMethodsPage, "controlMethod");
