"use strict";

import React from "react";
import { ConfigurableTable } from "../../configurableTables/tables/configurable_table";
import { MaterialLibraryColumnGenerator } from "./material_library_column_generator";

export const MaterialLibraryTable = (props) => {
  return (
    <ConfigurableTable
      id={"libraryMaterialTable"}
      ColumnGeneratorClass={MaterialLibraryColumnGenerator}
      {...props}
    />
  );
};