"use strict";

import React, { Fragment } from "react";
import ImportDataStep from "./import_data_step";
import ContinuousDataReviewPanel from "../widgets/dataImportWizardStep/continuousData/continuous_data_review_panel";

/**
 * This implements the Import wizard data review & import step for continuous data.
 */
export default class ImportContinuousDataStep extends ImportDataStep {
  constructor(props) {
    super(props);
  }

  renderStep() {
    let {records, batches} = this.props;
    const batch = batches && batches[0] || {};
    let nextStepDisabled = this.isNextStepDisabled();

    return (
      <Fragment>
        <div className="col-12">
          <ContinuousDataReviewPanel attributes={records}
                                     batchInfo={batch}
          />
        </div>
        <div className="col-sm-12 import-screen-footer">
          <button disabled={nextStepDisabled}
                  className="btn btn-lg btn-primary import-footer-btn import-footer-btn-fixed"
                  id="importAsDraftButton"
                  onClick={this.handleMoveToNextStep}
          >
            {"Import as draft"}
          </button>
          <button disabled={false}
                  className="btn btn-lg btn-primary import-footer-btn import-footer-btn-fixed"
                  id="importDataStepPreviousButton"
                  onClick={this.handleMoveToPreviousStep}
          >
            {"< Review Data"}
          </button>
          {this.renderImportExitButton()}
        </div>
      </Fragment>
    );
  }
}
