"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import BaseCopyRecordPlugin from "./base_copy_record_plugin";
import TypeaheadObjectCache from "../../../utils/cache/typeahead_object_cache";

/**
 * A drop down control that can be used as a plugin for the copy record popup. The user can use this
 * to select additional options that affect the cloning operation
 */
export default class CopyDropDownPlugin extends BaseCopyRecordPlugin {
  constructor(props) {
    super(props);

    const {dataCollectionAttribute, modelToLoad} = this.props.configProps;

    this.setStateSafely({
      [dataCollectionAttribute]: [],
      isLoading: true,
    });

    /**
     * The cache is invalidated every time the copy project dialog shows up, since the RMPs might have
     * been loaded in some other screen without the linked records (impact, uncertainty, etc...).
     */
    const cache = new TypeaheadObjectCache(modelToLoad, this.getProjectId(), this.getProcessId());
    cache.invalidateCacheOptionsAsync().then(() => {
      cache.loadOptions(this.handleReceiveDropDownData);
    });
  }

  handleReceiveDropDownData(data) {
    const {dataCollectionAttribute, attributeToUpdate} = this.props.configProps;

    this.setStateSafely({
      [dataCollectionAttribute]: data,
      isLoading: false,
    }, () => {
      let defaultValue = this.props.objectToCopy[attributeToUpdate]
        || (data && data.length > 0 ? data[0].id : null);

      this.handleChangeValue(defaultValue);
    });
  }

  handleChangeDropDownValue(event) {
    this.handleChangeValue(event.target.value);
  }

  render() {
    const {
      id,
      label,
      dataCollectionAttribute,
      attributeToUpdate,
      enabledStatusControlAttribute,
      visibilityStatusControlAttribute,
      indentationControlAttribute,
      modelToLoad
    } = this.props.configProps;

    let isDisabled = Object.prototype.hasOwnProperty.call(this.props.parent.state, enabledStatusControlAttribute)
      && !this.props.parent.state[enabledStatusControlAttribute];

    let isVisible = !Object.prototype.hasOwnProperty.call(this.props.parent.state, visibilityStatusControlAttribute)
      || !!this.props.parent.state[visibilityStatusControlAttribute];

    let shouldBeIndented = Object.prototype.hasOwnProperty.call(this.props.parent.state, indentationControlAttribute)
      && !!this.props.parent.state[indentationControlAttribute];

    return (
      isVisible ? (
        <div key={`${id}Div`} className="row">
          <div className={"form-group" + (shouldBeIndented ? " copy-record-popup-indented-plugin col-sm-12" : "")}>
          <span className="col-form-label">
            {label}
            </span>
            <div className={this.getClassForLoading()}>
              <select className="form-control"
                      id={id}
                      value={this.state[attributeToUpdate]}
                      disabled={isDisabled}
                      onChange={this.handleChangeDropDownValue}
              >
                {this.state[dataCollectionAttribute].map(optionValue => {
                  return (
                    <option value={optionValue.id} key={optionValue.id}>
                      {UIUtils.getRecordLabelForDisplay(UIUtils.getTypeCodeForModelName(modelToLoad), optionValue.id, optionValue.name)}
                    </option>
                  );
                })}
              </select>
            </div>
            <div id={`${id}ErrorDiv`} className="help-block with-errors" />
          </div>
        </div>
      ) : ""
    );
  }
}
