"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import CannedReport from "./canned_report";
import * as CannedReportHelper from "./canned_report_helper";
import { FILE_TYPES_ENUM } from "../constants/report_constants";
import * as ProcessCache from "../../processExplorer/process/process_cache";

/* This class shows QTPP Full canned report */
export default class QTPPFullCannedReport extends CannedReport {
  constructor(props) {
    super(props);
    window.onProcessFlowMapLoaded = this.handleProcessFlowMapLoaded;

    let processId = UIUtils.getParameterByName("processId");
    processId = processId ? UIUtils.parseInt(processId) : ProcessCache.getProcessIdUsedRecently(this.projectId);
    this.loadProcessFlowMap(processId);

    UIUtils.setHideLoadingOnAjaxStop(false);
    UIUtils.showLoadingImage("Loading Report Dependencies");
  }

  loadProcessFlowMap(processId) {
    const processParam = processId ? "&processId=" + processId : "";
    $(document.body).append("<iframe id='processFlowMapIFrame' class='qtpp-full-report-iframe'></iframe>");
    const iframe = document.getElementById("processFlowMapIFrame");
    if (iframe) {
      iframe.src = `/reports/processMapReports.html?projectId=${this.props.projectId}${processParam}&reportType=ProcessFlowMapReport`;
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.processId !== this.props.processId) {
      this.setStateSafely({reportImage: null, reportResults: null}, () => {
        $("#dataReportViewer").hide();
        this.loadProcessFlowMap(this.props.processId);
        this.loadHistoricalReportData();
      });
    } else if (this.state.reportImage && this.state.reportResults) {
      $("#dataReportViewer").show();
      this.clearReport();
      this.renderReport();
    }
  }

  handleProcessFlowMapLoaded(reportImage) {
    this.setStateSafely({reportImage});
    if (this.state.reportResults) {
      this.renderReport();
    }
  }

  exportAs(event) {
    let editableName = this.getEditableName();
    let fileType = CannedReportHelper.getFileType(event.target.name);
    const {reportResults} = this.state;

    let report = new window.Stimulsoft.Report.StiReport();
    report.loadFile(CannedReportHelper.getReportTemplateFullPath(this.props.reportType, editableName));

    let chartImage = window.Stimulsoft.Base.Drawing.StiImageConverter.stringToImage(this.state.reportImage.data);
    let chartImageVar = report.dictionary.variables.getByName("processFlowMapImage");
    chartImageVar.valueObject = chartImage;

    // If process flow map height > 550 then it won't be shown in word export as it will be hidden between pages
    reportResults.topLevelInformation.showLargeImageWarning =
      (fileType === FILE_TYPES_ENUM.WORD || fileType === FILE_TYPES_ENUM.PDF) && this.state.reportImage.height > 550;

    /*
    If one of the risk tables have sources > 5 then risk tables wont be shown in word export because
    it will break between pages as word doesn't support wide tables.
     */
    let sourcesCount = [];
    sourcesCount.push(this.getMaxSourcesNumber(reportResults.riskTablesma_prc_to_xqa));
    sourcesCount.push(this.getMaxSourcesNumber(reportResults.riskTablespp_to_xqa));
    sourcesCount.sort((a, b) => {
      return b - a;
    });

    reportResults.topLevelInformation.showLargeRiskTablesWarning =
      (fileType === FILE_TYPES_ENUM.WORD || fileType === FILE_TYPES_ENUM.PDF) && sourcesCount[0] > 5;

    let dataSet = new window.Stimulsoft.System.Data.DataSet("DataSet");
    dataSet.readJson(JSON.stringify(reportResults));
    report.dictionary.databases.clear();
    report.regData(dataSet.dataSetName, "", dataSet);

    CannedReportHelper.exportAs(fileType, "." + event.target.name, report, this.props.reportType, editableName);
  }

  getMaxSourcesNumber(riskTable) {
    if (riskTable.instances && riskTable.instances.length > 0) {
      let sourcesCount = riskTable.instances.map(instance => instance.sources.length);
      sourcesCount.sort((a, b) => {
        return b - a;
      });
      return sourcesCount[0];
    }
    return 1;
  }

  renderReport() {
    let editableName = this.getEditableName();
    let report = new window.Stimulsoft.Report.StiReport();
    report.loadFile(CannedReportHelper.getReportTemplateFullPath(this.props.reportType, editableName));

    let chartImage = window.Stimulsoft.Base.Drawing.StiImageConverter.stringToImage(this.state.reportImage.data);
    let chartImageVar = report.dictionary.variables.getByName("processFlowMapImage");
    chartImageVar.valueObject = chartImage;

    let dataSet = new window.Stimulsoft.System.Data.DataSet("DataSet");
    this.state.reportResults.topLevelInformation.showLargeImageWarning = false;
    this.state.reportResults.topLevelInformation.showLargeRiskTablesWarning = false;
    dataSet.readJson(JSON.stringify(this.state.reportResults));
    report.dictionary.databases.clear();
    report.regData(dataSet.dataSetName, "", dataSet);

    report.renderAsync(() => {
      let viewer = new window.Stimulsoft.Viewer.StiViewer(CannedReportHelper.getViewerOptions(this.props.reportType, false),
        "StiViewer", false);
      viewer.report = report;
      viewer.renderHtml("dataReportViewer");

      this.report = report;

      this.waitForHTMLRenderAndReplaceURLs();
    });
  }
}
