"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import CompanyLoginHeader from "../../widgets/headers/company_login_header";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { Button } from "@qbdvision-inc/component-library";
import CommonUtils from "../../../server/common/generic/common_utils";
import BaseReactComponent from "../../base_react_component";

// i18next-extract-mark-ns-start users
class UserForgotPassword extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {username: ""};

    this.handleChange = this.handleChange.bind(this);
    this.handleForgotPassword = this.handleForgotPassword.bind(this);
  }

  componentDidMount() {
    const {t} = this.props;
    document.title = t("QbDVision Manage Account");

    super.componentDidMount();
  }

  handleForgotPassword(event) {
    event.preventDefault();

    //data-match does not work with bootstrap validator
    //https://github.com/1000hz/bootstrap-validator/issues/449
    //https://github.com/1000hz/bootstrap-validator/issues/450
    $("[data-toggle='validator']").validator("update");
    let inputs = $(".form-control");
    let formIsValid = true;
    $.each(inputs, (key, val) => {
      formIsValid = formIsValid && !($(val).closest(".form-group").is(".has-error"));
    });

    if (event.target.checkValidity() && formIsValid) {
      UIUtils.showLoadingImage();
      $.ajax({
        url: UIUtils.getURL(`users/userAPI?activity=resetPassword&username=${encodeURIComponent(this.state.username)}`),
        type: "GET",
        global: false
      }).done((result) => {
        window.location.href = UIUtils.getSecuredURL(`./resetPassword.html?username=${encodeURIComponent(result.username)}&email=${encodeURIComponent(result.email)}`);
      });
    }
  }

  handleChange(event) {
    this.setStateSafely({username: event.target.value});
  }

  render() {
    const {t} = this.props;

    const showCreateCompanyButton = !CommonUtils.isCommercialEnvironment();

    return (
      <div className="container-fluid">
        <CompanyLoginHeader firstHeader={t("Let's get you back on your feet.")}
                            paragraph={<div className="text-center">
                              <p>{t("We will email you a verification code to complete your password reset.")}<br />
                                {t("If the verification code doesn't arrive in a few minutes, please check your spam folder.")}
                              </p>
                            </div>}
        />
        <br />
        <div className="center-single-column-grid">
          <div className="alert alert-danger d-none" role="alert" id="alertDiv" />
          <div className="row">
            <div className="col-sm-12">
              <form data-toggle="validator" role="form" onSubmit={this.handleForgotPassword}>
                <div className="form-group">
                  <input type="text" className="form-control" placeholder={t("Email / Username")} name="username" id="usernameInput"
                         data-required-error={t("An email or username is required")} required={true} onChange={this.handleChange}
                  />
                  <div className="help-block with-errors" />
                </div>
                <br />
                <div className="form-group">
                  <Button id="sendVerificationButton" isSubmit label={t("Send")} isFullWidth />
                </div>
                <br />
                <div className="form-group">
                  <p className="text-center loginPageLinks-text">
                    <a href="../index.html">{t("Login")}</a>
                    {showCreateCompanyButton ?
                      <Fragment><span style={{color: "black"}}> | </span><a id="createCompanyButton" href="./createCompany.html">{t("Create Company")}</a></Fragment>
                      : ""}
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(UserForgotPassword, "users");
// i18next-extract-mark-ns-stop users
