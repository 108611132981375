"use strict";

import * as styles from "../style/links_cell.module.scss";
import React from "react";

export const Link = ({id, onClick, href, text, className, type, ...props}) => {

  const attributeLookup = {
    File: {
      id,
      onClick,
    },
    Link: {
      href,
      rel: "noopener noreferrer",
      target: "_blank",
    },
  };

  const attributes = attributeLookup[type] || attributeLookup.Link;

  return (
    <div className={`${styles["link"]} ${className}`}>
      <a
        {...attributes}
        {...props}
      >
        {text}
      </a>
    </div>
  );
};