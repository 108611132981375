"use strict";

import ConfigurableTablesAdapter from "./configurable_tables_adapter";

/**
 * This class adapts/transforms the tech transfer data from server for unit operations.
 */
export default class ConfigurableTablesUnitOperationAdapter extends ConfigurableTablesAdapter {
  constructor(params) {
    super(params, "uoMap", "UnitOperations");
  }

  getRows() {
    const rows = [];
    const uos = Object.values(this.activeMap);
    super.adaptRows(uos);

    for (const record of uos) {
      const previousUO = record.PreviousUnitId ?
        uos.find(uo => uo.id === record.PreviousUnitId) : null;
      record.previousUnit = previousUO ? previousUO.name : "Start";
      record.supplier = record.Supplier ? record.Supplier.name : "";
      rows.push(record);
    }

    return this.sanitizeEmptyValues(rows);
  }
}
