"use strict";

import * as UIUtils from "../../../ui_utils";
import {BaseDataTransform} from "../base_data_transform";
import {
  convertLinksToJson,
  getApprovedProposedVersions,
  processEmptyFields
} from "../../canned_reports/canned_report_helper";
import {LINK_TYPE_ENUM} from "../../constants/report_constants";

export class TPPElementDataTransform extends BaseDataTransform {

  get type() {
    return "tppElement";
  }

  shouldRun(options) {
    return true;
  }

  transform(input, options) {
    const result = input;
    const data = result.instances.requirement;

    data.name = UIUtils.secureString(data.name);
    if (data.lastVersion) {
      data.lastVersion.name = UIUtils.secureString(data.lastVersion.name);
      data.lastVersion.links = convertLinksToJson(data.lastVersion.links, null, LINK_TYPE_ENUM.ProcessCapability);
      processEmptyFields(data.lastVersion);
    }
    data.approvedVersions = getApprovedProposedVersions(data.TPPSectionVersions, options.modelType);
    options.showDraftAlerts = data.approvedVersions.length === 0;

    result.instances = data;
    return result;
  }
}

