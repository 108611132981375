import {BaseParser} from "../base_parser";
import {FileType} from "../../../common/constants";

export class DocBuilderFieldsParser extends BaseParser {
  parse() {
    const newElement = document.createElement(this.node.tagName);
    const {columnName} = this.getNodeInfo();
    const textContent = this.getValueForDocBuilderField(columnName);
    if (
      (this.fileType === FileType.DOC &&
        (columnName === "pageNumber" || columnName === "totalPages" || columnName === "pageNumberExcludingCoverPage")) ||
      (columnName !== "pageNumber" && columnName !== "totalPages" && columnName !== "pageNumberExcludingCoverPage")
    ) {
      return [textContent];
    }

    newElement.className = "doc-builder-field";
    newElement.setAttribute("value", columnName);
    newElement.innerText = textContent;
    return [newElement];
  }
}
