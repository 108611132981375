"use strict";

import React from "react";
import { EDITOR_OPERATIONS } from "../editor_constants";
import { ApprovalContainer } from "./approval_container";
import ImplementationNeededError from "../../utils/implementation_needed_error";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Editables, "StaticApprovalContainer");

// i18next-extract-mark-ns-start editor
/**
 * This shows the current state of approval and the full size buttons for editing, approving, etc.
 */
class StaticApprovalContainer extends ApprovalContainer {
  constructor(props) {
    super(props);
  }

  openPage(operation, approved = null) {
    Logger.debug(() => `openPage ${Log.object({
      operation,
      approved
    })}`);

    /**
     * This is the place where you will find an insanely huge comment explaining how we reload data for the latest
     * draft when you click "Edit Last Draft" from a static panel.
     *
     * If this comment doesn't make sense right now, just move on. It will make sense once you start navigating
     * through the related code.
     */
    const {eventListeners, onLoadNewVersion} = this.props;

    switch (operation) {
      case EDITOR_OPERATIONS.VIEW:
        if (!onLoadNewVersion || typeof onLoadNewVersion !== "function") {
          throw new Error("No valid handler specified in 'onLoadNewVersion' property.");
        }
        onLoadNewVersion(approved);
        break;
      case EDITOR_OPERATIONS.EDIT:
        if (!eventListeners || typeof eventListeners.onSwitchViewMode !== "function") {
          throw new Error("No event listener specified for 'onSwitchViewMode'");
        }
        if (this.props.objectCurrentState === "Approved") {
          onLoadNewVersion(false, (data) => {
            /**
             * This is a crazy workaround to ensure that we will always reload the data when clicking
             * "Edit Last Draft" from an approved version in a static panel.
             *
             * Since our UI code is very complex, with different levels of inheritance and indirection, here I am
             * making a roadmap to help the next poor soul to get to this code.
             *
             * This comment was written on Nov 2, 2021 by Leonardo Pires, so if things change in other places, I can't
             * do much to help here. The good side is that each link here can be Ctrl+clicked and navigated to.
             *
             * So, we first send an async callback into the {@link onLoadNewVersion} event.
             * This event comes from the {@link StaticApprovalContainer.props.onLoadNewVersion}, and is currently set
             * in {@link BaseApprovableEditorPage.renderInternalLayout}.
             *
             * That event, as of the time this code was written was pointing to {@link BaseApprovableEditor.loadNewVersion}.
             *
             * That method then reloads the latest draft data from the server (because we're passing `false`
             * to the onLoadNewVersion call below). And, after it's loaded, it invokes
             * {@link BaseApprovableEditor.handleReceiveRevisionDataFromServer}.
             *
             * From there, it will run its logic and in the end (after all states are set), it will invoke and await for
             * our callback here.
             *
             * From our callback, we then enter another ride on even handlers. First we go to
             * {@link eventListeners.onSwitchViewMode}, which as of today will be set in
             * {@link BaseQuickPanel.renderPanelContent}, pointing to {@link BaseQuickPanel.handleSwitchViewMode}.
             *
             * From there, we our journey proceeds to {@link BaseQuickPanel.props.onChangeEditorOperation}.
             *
             * That one can be set in multiple places, whenever it's set. You can Ctrl+click above and check where it's
             * called.
             *
             * The usage that matters most to us in  {@link ProcessExplorerPage.renderStaticPanel}, which then
             * points to {@link ProcessExplorerPage.handleChangeEditorType}.
             *
             * In that method, we then set the {@link ProcessExplorerPage.state.selectedRecord} with the value of
             * the {@link data} parameter below and, finally, that method will receive the "resolve" parameter of
             * the promise below and call it, fulfilling our promise.
             */
            return new Promise(resolve => eventListeners.onSwitchViewMode(null, operation, data, resolve));
          });
        } else {
          eventListeners.onSwitchViewMode(operation);
        }
        break;
      default:
        throw new ImplementationNeededError();
    }
  }

  handleCancel() {
    const {eventListeners} = this.props;
    this.context.clearPreventNavigation();
    eventListeners.onSwitchViewMode && eventListeners.onSwitchViewMode(EDITOR_OPERATIONS.VIEW);
  }
}

export default I18NWrapper.wrap(StaticApprovalContainer, "editor");
// i18next-extract-mark-ns-stop editor
