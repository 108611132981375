import * as React from "react";
import {Button} from "@progress/kendo-react-buttons";
import qbdLogoIcon from "../../images/documentEditor/document-editor-qbdvision-logo-small.svg";
import {ToolProps} from "../common/types";

/**
 * The editor tool to toggle the smart content and widget menu tool
 */
type ToggleSideMenuToolProps = {
  onToggleMenu: () => void;
};

export default function ToggleSideMenuTool(
  props: ToolProps & ToggleSideMenuToolProps,
): React.ReactElement {
  const {onToggleMenu} = props;
  return (
    <Button
      id="toggleSmartContentAndWidgetMenu"
      onClick={onToggleMenu}
      imageUrl={qbdLogoIcon}
      title="Toggle Menu"
    />
  );
}
