var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { toggleList, listStyle } from '@progress/kendo-editor-common';
import { SplitButton } from '@progress/kendo-react-buttons';
import { listsTypes } from './../config/toolsSettings';
import { useLocalization } from '@progress/kendo-react-intl';
import { messages } from '../messages';
var parentNode = function (state, nodeType) {
    var _a = state.selection, from = _a.from, to = _a.to;
    var result = null;
    state.doc.nodesBetween(from, to, function (node) {
        result = result || (node.type === nodeType ? node : null);
        return !result;
    });
    return result;
};
/**
 * @hidden
 */
export var ListTool = function (props) {
    var isActive = false;
    var view = props.view;
    var types = listsTypes;
    var listType = props.listType;
    var localization = useLocalization();
    var items = __spreadArray([], (props.items || []), true);
    var ol, ul;
    if (view) {
        var state = view.state;
        var nodes = state && state.schema.nodes;
        ol = nodes && parentNode(state, nodes[types.orderedList]);
        ul = nodes && parentNode(state, nodes[types.bulletList]);
        isActive = Boolean(listType === types.orderedList ? (ol && !ul) : (!ol && ul));
    }
    var onButtonClick = function (_event) {
        if (view) {
            toggleList(view.state, view.dispatch, view, __assign(__assign({ listType: listType }, types), { listAttrs: {} }), 'InsertList');
            view.focus();
        }
    };
    var onItemClick = function (event) {
        if (view) {
            var listAttrs = event.item.style ? { style: 'list-style-type: ' + event.item.style + ';' } : {};
            var currentList = ol || ul;
            if (currentList && listStyle(currentList.attrs) !== event.item.style) {
                var pos = view.state.selection.$head.posAtIndex(0, -2) - 1;
                view.dispatch(view.state.tr.setNodeMarkup(pos, view.state.schema.nodes[listType], listAttrs));
            }
            else {
                toggleList(view.state, view.dispatch, view, __assign(__assign({ listType: listType }, types), { listAttrs: listAttrs }), 'InsertList');
            }
            view.focus();
        }
    };
    if (isActive && (ol || ul)) {
        var listStyleType_1 = listStyle((ol || ul).attrs);
        items = items.map(function (item, index) { return (__assign(__assign({}, item), { selected: listStyleType_1 ? item.style === listStyleType_1 : index === 0 })); });
    }
    return (React.createElement("span", { onMouseDown: function (e) { e.preventDefault(); } },
        React.createElement(SplitButton, { textField: "text", items: items, icon: props.icon, onButtonClick: onButtonClick, onItemClick: onItemClick, buttonClass: isActive ? 'k-selected' : undefined, title: props.title !== undefined ? props.title : localization.toLanguageString(props.titleKey, messages[props.titleKey]) })));
};
