import BaseReactComponent from "../base_react_component";
import React from "react";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";
import { Trans } from "react-i18next";
import InfoTooltip from "../widgets/tooltips/info_tooltip";
import AddButton from "./widgets/add_button";
import DragAndDropPanel from "./widgets/drag_and_drop_panel";
import DocumentListTable from "./document_list_table";
import DocumentEmptyState from "./document_empty_state";
import DocumentBulkAddPopup from "./bulkAdd/document_bulk_add_popup";
import { FilteredList } from "../widgets/tables/filtered_list";
import * as I18NWrapper from "../i18n/i18n_wrapper";

// i18next-extract-mark-ns-start documents
export class DocumentListGeneric extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      ...this.getInitialState()
    };

    this.bulkAddPopup = React.createRef();

    // This is accessed only from tests
    window.handleFilesSelection = this.handleFilesSelection;
  }

  getInitialState() {
    return {
      isLoading: true,
      reloadData: false,
      documents: [],
      files: [],
      showDocumentBulkAddPopup: false
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.filters = this.props.filters ?? [];

    this.setStateSafely(this.getInitialState(), () => {
      const cacheInstance = new TypeaheadObjectCache("Document", this.props.projectId, null, { invalidate: true, allProjects: this.props.projectId ? false : true });
      cacheInstance.loadOptions(this.handleTypeaheadResultsFromServer);
    });
  }

  handleTypeaheadResultsFromServer(documents) {
    this.setStateSafely({documents},
      /**
       * This is important to keep the isLoading separate from setting the table results. Once the results (table rows)
       * are set, the table will reload/redraw. Tests will immediately start scanning the table for results once the
       * isLoading indicator goes away and then the redraw will cause webdriver.io to fail with errors like:
       *
       *     Error: stale element reference: element is not attached to the page document
       */
      () => {
        this.setStateSafely({isLoading: false});
      });
  }

  renderSubHeader(props, state) {
    const {t} = this.props;
    const {filteredItems} = state;
    const itemCount = filteredItems && filteredItems.length > 0 ? filteredItems.length : 0;

    return (
      <div className="col-sm-12">
        <h2 className="header-inline">
          <Trans t={t} count={itemCount}>
            <span className={"header-item-count" + this.getClassForLoading()}>{{count: itemCount}}</span><span className="header-item-type"> Document</span>
          </Trans>
          <InfoTooltip
            id="infoDocuLib"
            verbiage={
              <div>
                {this.props.subHeaderTooltip}
              </div>
            }
          />
        </h2>
        <AddButton
          onFilesSelection={this.handleFilesSelection}
          projectId={this.props.projectId}
        />
      </div>
    );
  }

  handleHideBulkAddPopup() {
    // No need to hide the popup here, as this event will only be called after
    // the popup is already hidden.
    // Actually, if you call .modal("hide"), you will get an exception.
    this.setStateSafely({
      showDocumentBulkAddPopup: false,
    });
  }

  handleFilesSelection(e, files) {
    if (e) {
      let input = $("#" + e.target.id)[0];
      if (input.files) {
        files = Array.from(input.files);
      }
    }

    this.setStateSafely({
      files,
      reloadData: false,
      showDocumentBulkAddPopup: true,
    });
  }

  /**
   * Once bulk add process is completed, the documents list
   * page will auto update by setting the reloadData to true
   * and retrieving the documents from the backend to the
   * cache so it triggers the list not the empty state if
   * this is the first time the user adding document to the system.
   */
  handleBulkAddComplete() {
    const cache = new TypeaheadObjectCache("Document", this.props.projectId);
    let cachePromise = Promise.resolve(true);
    if (this.state.documents) {
      cachePromise = cache.invalidateCacheOptionsAsync();
    }

    cachePromise.then(() => {
      cache.loadOptions((documents) => {
        this.setStateSafely({documents, reloadData: true});
      });
    });
  }

  render() {
    let {showFilterBar} = this.props;

    if (showFilterBar !== false) {
      showFilterBar = true;
    }

    const {
      documents, reloadData,
      showDocumentBulkAddPopup, files,
    } = this.state;

    const showFilteredList = (this.isLoading() && documents.length === 0) || documents.length > 0;

    return (
      <DragAndDropPanel
        onFilesSelection={this.handleFilesSelection}
      >
        { showFilteredList ?
          <FilteredList
            topLevel
            handleArchivedOnClient
            showIdInSearchBox
            allowRecordCopy
            parent={this}
            projectId={this.props.projectId}
            filters={this.filters}
            editableName={"Document"}
            showFilterBar={showFilterBar}
            filterSelector={item => [item.name, item.customID]}
            listTableComponent={DocumentListTable}
            reloadData={reloadData}
            pageLength={100}
            onRenderHeader={this.renderSubHeader}
            quickPanelConfig={{
              originalWidth: 720,
            }}
          /> :
          <DocumentEmptyState
            onFilesSelection={this.handleFilesSelection}
            projectId={this.props.projectId}
            isLoading={this.isLoading()}
          />}
        {showDocumentBulkAddPopup ?
          <DocumentBulkAddPopup
            files={files}
            ref={this.bulkAddPopup}
            projectId={this.props.projectId}
            existingCustomIds={documents
              .filter(document => document.customID)
              .map(document => document.customID)}
            onBulkAddComplete={this.handleBulkAddComplete}
            onHideBulkAddPopup={this.handleHideBulkAddPopup}
          /> : ""}
      </DragAndDropPanel>
    );
  }
}

export default I18NWrapper.wrap(DocumentListGeneric, "documents");
// i18next-extract-mark-ns-stop documents
