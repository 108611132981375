"use strict";

import React from "react";
import BaseLinkedEntitiesAttribute from "./base_linked_entities_attribute";
import { TEXT_DIFF_METHOD_ENUM } from "../../helpers/constants/constants";
import * as UIUtils from "../../ui_utils";
import { FIELD_INPUT_TYPE, FIELD_REQUIRED_FOR } from "../widgets/widget_field";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import { getMeasureDefaultUnit } from "../../helpers/attribute_helper";
import { getAcceptanceCriteriaValidationProps } from "../../../server/common/editables/common_editables";

const Logger = Log.group(LOG_GROUP.JSONAttribute, "MultipleAcceptanceCriteriaAttribute");

/**
 * @type {IWidgetField[]}
 */
export const MAC_WIDGET_FIELDS = (
  [
    {
      fieldName: "group",
      displayName: "Group",
      diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
      requiredFor: FIELD_REQUIRED_FOR.save,
      forceUpdate: false,
      defaultValue: "",
      placeholder: "",
      belongsToMasterRow: true,
      order: 1,
      inputType: FIELD_INPUT_TYPE.typeahead,
      getOptions: "getGroupOptions",
      autoComplete: true,
      multiSelect: false,
      newSelectionPrefix: "Add new group: ",
      useDisplayName: true,
      width: 50,
      disabled: false,
      maxLength: 255,
    },
    {
      displayName: "Label",
      fieldName: "label",
      diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
      requiredFor: FIELD_REQUIRED_FOR.save,
      forceUpdate: false,
      belongsToMasterRow: true,
      order: 2,
      defaultValue: "",
      inputType: FIELD_INPUT_TYPE.typeahead,
      autoComplete: true,
      newSelectionPrefix: "Add new label: ",
      getOptions: "getLabelOptions",
      multiSelect: false,
      useDisplayName: true,
      width: 50,
      disabled: false,
      maxLength: 255,
    },
    {
      fieldName: "lowerLimit",
      diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
      displayName: "LSL",
      requiredFor: FIELD_REQUIRED_FOR.none,
      forceUpdate: true,
      belongsToMasterRow: true,
      inputType: FIELD_INPUT_TYPE.number,
      allowDecimalNumbers: true,
      order: 3,
      width: 1,
      disabled: "isMeasureDisabled",
      additionalPropsGetter: "getAdditionalPropsForField",
      maxLength: 255,
    },
    {
      fieldName: "target",
      diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
      displayName: "Target",
      requiredFor: FIELD_REQUIRED_FOR.none,
      forceUpdate: false,
      belongsToMasterRow: true,
      order: 4,
      inputType: FIELD_INPUT_TYPE.text,
      width: 1,
      disabled: "isMeasureDisabled",
      formatValue: "formatTargetValue",
      additionalPropsGetter: "getAdditionalPropsForField",
      maxLength: 255,
    },
    {
      fieldName: "upperLimit",
      diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
      displayName: "USL",
      requiredFor: FIELD_REQUIRED_FOR.none,
      forceUpdate: false,
      belongsToMasterRow: true,
      order: 5,
      inputType: FIELD_INPUT_TYPE.number,
      allowDecimalNumbers: true,
      width: 1,
      disabled: "isMeasureDisabled",
      additionalPropsGetter: "getAdditionalPropsForField",
      maxLength: 255,
    },
    {
      fieldName: "measurementUnits",
      diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
      displayName: "Units",
      requiredFor: FIELD_REQUIRED_FOR.none,
      forceUpdate: false,
      belongsToMasterRow: true,
      order: 6,
      inputType: FIELD_INPUT_TYPE.text,
      width: 1,
      disabled: "isMeasureDisabled",
      maxLength: 255,
    },
    {
      fieldName: "isDefault",
      orderable: false,
      diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
      displayName: "Status",
      forceUpdate: false,
      belongsToMasterRow: true,
      order: 7,
      inputType: FIELD_INPUT_TYPE.nonEditable,
      width: 100,
      disabled: true,
      formatValue: "formatIsDefault",
    },
    {
      fieldName: "targetJustification",
      diffMethod: TEXT_DIFF_METHOD_ENUM.differential,
      displayName: "Justification",
      requiredFor: FIELD_REQUIRED_FOR.none,
      forceUpdate: false,
      defaultValue: "",
      placeholder: "",
      belongsToMasterRow: false,
      order: 7,
      rows: 4,
      inputType: FIELD_INPUT_TYPE.textarea,
    },
  ]
);

export class MultipleAcceptanceCriteriaAttribute extends BaseLinkedEntitiesAttribute {

  constructor(props) {
    super(props, MAC_WIDGET_FIELDS);
    this.handleAddToList = this.handleAddToList.bind(this);
    this.editedRows = props.editedRows && Array.isArray(props.editedRows) ? props.editedRows : [];
  }

  /**
   * Adds a new row to the rows collection managed by this attribute and assigns the default value for each field
   * as specified in the WIDGET_FIELDS.
   * @param editedRow Optional row provided by a child class, where it is already initialized..
   */
  handleAddToList(editedRow) {
    editedRow = editedRow ?? {
      typeCode: "ACR",
      uuid: UIUtils.generateUUID(),
      label: "",
      group: "",
      lowerLimit: null,
      upperLimit: null,
      target: null,
      measurementUnits: null,
      justification: "",
      isDefault: false,
    };

    super.handleAddToList(editedRow);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const primaryReportingCriteriaChanged = nextProps.primaryReportingCriteria !== this.props.primaryReportingCriteria;
    if (primaryReportingCriteriaChanged) {
      this._forceFullRedraw = true;
    }

    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
    shouldUpdate |= nextProps.measure !== this.props.measure;
    shouldUpdate |= primaryReportingCriteriaChanged;
    return shouldUpdate;
  }

  /**
   * Returns the typeahead option if it exists from the selected id on the underlying row
   * @param row The row given which the typeahead option is searched
   * @returns {null}
   */
  getLinkSelectedOption(row) {
    return (
      super.getLinkSelectedOption(row)
      || this.getItemsInList().find(item => (item.uuid === row.uuid))
      || null
    );
  }

  getItemsInList() {
    const {parent} = this.props;
    return parent.state.AcceptanceCriteriaRanges || [];
  }

  getLabelOptions(field, rowData) {
    const allRanges = (this.props.allRanges || []).concat(this.getItemsInList());
    let availableRangeLabels = new Set(allRanges.map(range => range.label));
    const options = [...availableRangeLabels].sort();
    const currentItemValue = this.getTypeaheadInput(rowData, field)?.value;

    Logger.verbose("Label options: ", Log.object(options),
      "\n - Value: ", Log.object(currentItemValue));

    if (currentItemValue && !availableRangeLabels.has(currentItemValue)) {
      options.push(currentItemValue);
    }

    return options.filter(item => item).map(item => ({label: item, id: item}));
  }

  getGroupOptions(field, rowData) {
    const allRanges = (this.props.allRanges || []).concat(this.getItemsInList());
    let availableRangeGroups = new Set(allRanges.map(range => range.group));
    const options = [...availableRangeGroups].sort();
    const currentItemValue = this.getTypeaheadInput(rowData, field)?.value[0];

    Logger.verbose("Group options: ", Log.object(options),
      "\n - Value: ", Log.object(currentItemValue));

    if (currentItemValue && !availableRangeGroups.has(currentItemValue)) {
      options.push(currentItemValue);
    }

    return options.filter(item => item).map(item => ({label: item, id: item}));
  }

  handleTypeaheadValueChanged(event, field, rowData) {
    let input = this.getTypeaheadInput(rowData, field);
    if (input && field?.autoComplete === true && this.isRowInEditMode(rowData.uuid)) {
      const value = input.value;
      Logger.verbose("Label value: ", Log.object(value));
      let editedRow = this.getEditedRowByUUID(rowData.uuid);
      field.setValue(editedRow, value);
    }
  }

  isMeasureDisabled(rowData, field) {
    const {measure} = this.props;

    let disabledFields = [];
    switch (measure) {
      case UIUtils.MEASUREMENT_TYPES.LOWER_LIMIT:
        disabledFields = ["upperLimit"];
        break;
      case UIUtils.MEASUREMENT_TYPES.UPPER_LIMIT:
        disabledFields = ["lowerLimit"];
        break;
      case UIUtils.MEASUREMENT_TYPES.DEFECTS:
        disabledFields = ["lowerLimit", "measurementUnits"];
        break;
      case UIUtils.MEASUREMENT_TYPES.CONFORMS:
        disabledFields = ["upperLimit", "lowerLimit"];
        break;
    }

    if (rowData?.uuid && field.getFieldPath() === "measurementUnits") {
      if (measure === UIUtils.MEASUREMENT_TYPES.DEFECTS) {
        field.setValue(this.getEditedRowByUUID(rowData.uuid), getMeasureDefaultUnit(measure));
      } else {
        if (field.getValue() === getMeasureDefaultUnit(UIUtils.MEASUREMENT_TYPES.DEFECTS)) {
          field.setValue(this.getEditedRowByUUID(rowData.uuid), getMeasureDefaultUnit(measure));
        }
      }
    }
    return disabledFields.includes(field.getFieldPath());
  }

  /**
   * Formats the display of the "IsDefault" column
   * @param field {WidgetField}
   * @param rowData {*}
   * @return {JSX.Element|string}
   */
  formatIsDefault(field, rowData) {
    const {primaryReportingCriteria} = this.props;
    let values;

    if (this.isDiffingVersions() && Array.isArray(rowData.isDefault)) {
      values = rowData.isDefault.map(item => {
        // The diff helper will return a react element with the text "true" inside
        // We then
        let result;
        Logger.warn(">> Formatting value: ", Log.object(field), Log.object(rowData));
        result = {
          index: rowData.index,
          key: `${rowData.uuid}_${item.key}`,
          className: item.props.className ?? "diff-equal",
          value: item.props.children === "true",
        };
        return result;
      });
    } else {
      values = [
        {
          ...rowData,
          index: rowData.index,
          key: `${rowData.uuid}_${rowData.key}`,
          className: this.isDiffingVersions() ? (rowData.className ?? "diff-equal") : "badge b-1 p-1 alert-info",
          value: !!rowData.isDefault || (rowData.uuid === primaryReportingCriteria),
        },
      ];
    }

    return values ? values.map(item => this.renderPrimaryReportingCriteriaMarker(item)) : "";
  }

  renderPrimaryReportingCriteriaMarker(rowData) {
    Logger.debug("Formatting value: ", Log.object(rowData));
    const className = rowData.className;
    const content = rowData.value ? "Primary reporting criteria" : "";
    return (
      <span
        key={rowData.key}
        className={className}
      >{content}</span>
    );
  }

  /**
   * Formats the value of the target field.
   * This field must be a number for all measurement types except for {@link UIUtils.MEASUREMENT_TYPES.CONFORMS}.
   * @param field
   * @param rowData
   * @return {string}
   */
  formatTargetValue(field, rowData) {
    const {measure} = this.props;

    let value = field.getValue(rowData);

    if (measure !== UIUtils.MEASUREMENT_TYPES.CONFORMS) {
      value = UIUtils.isNumber(value) ? Number(value) : "";
    }
    return value;
  }

  /**
   * Returns an object with the validation-related props for a given acceptance criteria field.
   * @param field {WidgetField} The field that is being validated.
   * @param rowData {*} The row data that contains the information about that acceptance criteria range.
   */
  getAdditionalPropsForField(field, rowData) {
    const fieldName = field.getFieldPath();
    const {measure} = this.props;

    return getAcceptanceCriteriaValidationProps(measure, fieldName, rowData);
  }

  /**
   * Indicates whether the delete button should be disabled for the specified row.
   * @param rowData {*}
   * @return {boolean}
   */
  isDeleteButtonDisabled(rowData) {
    return !!(rowData?.isDefault);
  }

  /**
   * Retrieves the reason why the delete button is disabled.
   * @param rowData {*}
   * @return {string}
   */
  getDeleteButtonDisabledReason(rowData) {
    return (rowData?.isDefault) ? "You cannot remove the Primary Reporting Criteria." : "";
  }

  preProcessDataForTable(additionalFields = []) {
    return super.preProcessDataForTable(additionalFields);
  }


  /**
   *
   * @param editedRow {*}
   * @param field {WidgetField}
   * @param rows {[]}
   * @param errorReport {*}
   * @return {boolean}
   */
  isEditingRowFieldValid(editedRow, field, rows, errorReport) {
    let isValid = true;
    let existingRow = rows.find(otherRow => otherRow.uuid !== editedRow.uuid && otherRow.label === editedRow.label && otherRow.group === editedRow.group);

    if (existingRow && field.fieldName === "label") {
      let errors = errorReport.get("unique") || [];
      errors.push({
        field,
        editedRow,
        allRows: rows,
        existingRow,
        message: "The combination of Group and Label values must be unique."
      });
      errorReport.set("unique", errors);
    }

    if (field.inputType === "typeahead" && field.autoComplete) {
      const value = field.getValue(editedRow);
      const isRequiredValid = field.requiredFor === FIELD_REQUIRED_FOR.save && (value && value.trim());
      if (!isRequiredValid) {
        let errors = errorReport.get("required") || [];
        errors.push({field, editedRow, allRows: rows});
        errorReport.set("required", errors);
      }
      // We don't want to short circuit here, so we get all verifications
      isValid = isRequiredValid && isValid;
    } else {
      // We don't want to short circuit here, so we get all verifications
      isValid = super.isEditingRowFieldValid(editedRow, field, rows, errorReport) && isValid;
    }
    return isValid;
  }
}
