"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import { getURLByTypeCodeAndId, getURLByTypeCodeAndIdAndVersionId } from "../../helpers/url_helper";
import Heading from "../../widgets/headers/heading";

import * as CommonRiskTable from "./utils/risk_tables_helper";

import QuickPanel from "../../editor/quick/quick_panel";
import { AUTO_VALIDATION_MODE_ENUM, SAVE_BEHAVIOR_ENUM } from "../../editor/base_approvable_editor";
import * as StickyAdapter from "../../utils/sticky_adapter";
import Cookies from "js-cookie";
import { getTypeCodeForModelName } from "../../../server/common/generic/common_utils";
import { QUICK_PANEL_BUTTONS } from "../../widgets/quickPanel/quick_panel_buttons";
import { EDITOR_OPERATIONS, EDITOR_TYPES } from "../../editor/editor_constants";
import BaseReactComponent from "../../base_react_component";
import { ALL_UOS_OPTION } from "./risk_tables_report_page";
import DeleteRiskLinksConfirmationPopup from "../../widgets/risk/delete_risk_links_confirmation_popup";
import { EMPTY_STRING } from "../../helpers/constants/constants";
import EditRiskLinksConfirmationPopup from "../../widgets/risk/edit_risk_links_confirmation_popup";

const QUICK_PANEL_CONFIG = {
  showHistory: false,
  saveBehavior: SAVE_BEHAVIOR_ENUM.SAVE_AND_RELOAD,
  quickPanelButtons: [QUICK_PANEL_BUTTONS.SAVE, QUICK_PANEL_BUTTONS.RESET],
  sectionsCollapsed: true,
  autoValidationMode: AUTO_VALIDATION_MODE_ENUM.NONE
};

/**
 *  This shows the tables in all of the "Risk Tables" reports.
 */
export class RiskTables extends BaseReactComponent {
  constructor(props) {
    super(props);

    //bind stuff
    window.handleQuickEditRecord = this.handleQuickEditRecord;

    this.quickPanel = React.createRef();
    this.state = {
      quickPanelInfo:
        {
          typeCode: null,
          id: null
        },
      quickPanelOperation: EDITOR_OPERATIONS.EDIT,
      previousData: JSON.stringify(props.data)
    };

  }

  shouldComponentUpdate(nextProps, nextState) {
    const {show} = this.state;
    const nextShow = nextState.show;
    return this.props.selectedUOId !== nextProps.selectedUOId
      || this.props.showRawScore !== nextProps.showRawScore
      || this.props.labelAction !== nextProps.labelAction
      || JSON.stringify(this.state.quickPanelInfo) !== JSON.stringify(nextState.quickPanelInfo)
      || JSON.stringify(this.props.data) !== JSON.stringify(nextProps.data)
      || show !== nextShow;
  }

  componentDidUpdate(prevProps) {
    const {reportSettings, data, selectedUOId} = this.props;

    // Reset the quick panel if the data has changed.
    const hasDataChanged = JSON.stringify(this.props.data) !== JSON.stringify(prevProps.data);
    if (hasDataChanged) {
      this.setStateSafely({
        quickPanelInfo: {typeCode: null, id: null}
      });
    }

    // Recreate tables.
    if (hasDataChanged
      || this.props.selectedUOId !== prevProps.selectedUOId
      || this.props.showRawScore !== prevProps.showRawScore
      || this.props.labelAction !== prevProps.labelAction
    ) {
      CommonRiskTable.destroyDataTables(false);
      if (data && data.length > 0) {
        if (!this.props.reportSettings.allowSelectUO) {
          let tableData = data[0];
          this.initializeTableForData(tableData, reportSettings);
        } else if (selectedUOId === ALL_UOS_OPTION.id) {
          for (let i = 0; i < data.length; i++) {
            this.initializeTableForData(data[i], reportSettings);
          }
        } else if (selectedUOId) {
          let tableData = data.find(tableData => tableData.unitOperationId === selectedUOId);
          if (tableData) {
            this.initializeTableForData(tableData, reportSettings);
          }
        }
      }
    }

    // Initialize the new tooltips.
    setImmediate(() => {
      $("[data-toggle='popover']").popover({sanitizeFn: UIUtils.sanitizePopoverData});
    });
  }

  handleQuickEditRecord(typeCode, id, versionId) {
    const quickPanel = this.quickPanel.current;
    if (quickPanel && quickPanel.isVisible()) {
      this.setStateSafely({
        quickPanelInfo: {typeCode, id}
      });
      quickPanel.expandToOpen();
    } else {
      window.open(getURLByTypeCodeAndIdAndVersionId(typeCode, "View", id, false, versionId), "_blank");
    }

    const quickPanelLinkId = CommonRiskTable.getQuickPanelLinkId(typeCode, id);
    Cookies.set("RISK_TABLES_SCROLL_TO_ID", quickPanelLinkId);
  }

  /**
   * This is called when the user has chosen to cancel the loading of a new record in the quick panel.
   */
  handleCancelQuickEditLoad(oldTypeCode, oldId) {
    this.setStateSafely({
      quickPanelInfo: {typeCode: oldTypeCode, id: oldId}
    });
  }

  handleChangeEditorOperation(newEditorOperation, data, callback) {
    this.setStateSafely({quickPanelOperation: newEditorOperation}, () => UIUtils.invokeCallback(callback, data));
  }

  getTableRef(unitOperationId) {
    return this.refs[unitOperationId !== -1 ? "table_" + unitOperationId : "table"];
  }

  initializeTableForData(tableData, reportSettings) {

    const {isPivot, DataTableAdapterClass} = reportSettings;
    const {unitOperationId} = tableData;

    const {reportKey} = this.props;
    const tableRef = this.getTableRef(unitOperationId);

    const adapterArgs = {
      reportKey,
      tableRef,
      tableData,
      props: this.props
    };

    const tableAdapter = new DataTableAdapterClass(adapterArgs);

    if (isPivot) {

      // Prepare the footer rows in advance so that datatables can take care of the footer during initialization
      // https://datatables.net/forums/discussion/27829/add-table-footer-with-javascript-only
      tableAdapter.createReportFooter();

      this.initializeRiskMapTable(tableData, tableAdapter);

      // Prepare the grouping header rows after initialization so data tables won't consider them and won't have confused with the actuall table header that it should consider for any datatables.net operations, like sorting, etc...
      tableAdapter.createReportHeader();

    } else {
      this.initializeRiskMapTable(tableData, tableAdapter);
    }
  }

  renderRiskLinkConfirmationDialog() {
    const {show, isDeleteConfirmation, message, documents, onHideModal, onChangeRiskLinks} = this.state;
    return show ?
      (
        isDeleteConfirmation
          ? <DeleteRiskLinksConfirmationPopup
            modalRef={riskConfirmationPopup => this.deleteRiskConfirmationPopup = riskConfirmationPopup}
            action={"Remove Risk"}
            message={message}
            documents={documents}
            onHideModal={onHideModal}
            onDeleteRiskLinksAction={onChangeRiskLinks}
            parent={this}
          />
          : <EditRiskLinksConfirmationPopup
            modalRef={riskConfirmationPopup => this.editRiskConfirmationPopup = riskConfirmationPopup}
            action={"Change Risk"}
            message={message}
            documents={documents}
            onHideModal={onHideModal}
            onEditRiskLinksAction={onChangeRiskLinks}
            parent={this}
          />
      ) : EMPTY_STRING;
  }

  onRiskLinkConfirmationDialogRender(show, isDeleteConfirmation, message, documents, onHideModal, onChangeRiskLinks) {
    this.setStateSafely({
      show, isDeleteConfirmation, message, documents, onHideModal, onChangeRiskLinks
    });
    if (isDeleteConfirmation && this.deleteRiskConfirmationPopup && !show) {
      $(this.deleteRiskConfirmationPopup).modal("hide");
    } else if (!isDeleteConfirmation && this.editRiskConfirmationPopup && !show) {
      $(this.editRiskConfirmationPopup).modal("hide");
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    CommonRiskTable.destroyDataTables(true);
  }

  initializeRiskMapTable(tableData, tableAdapter) {

    const columns = tableAdapter.getColumnsForReport();
    const columnDefs = tableAdapter.getColumnDefinitionsForReport();

    const {unitOperationId, data} = tableData;
    const tableId = CommonRiskTable.getRiskTableId(unitOperationId, true);

    // Make sure that every column that specifically doesn't mention that it allows HTML has a renderer that blocks XSS
    for (const column of columns) {
      if (!column.containsHTML) {
        column.render = $.fn.dataTable.render.text();
      }
    }

    $(tableId).DataTable({
      dom: "tr",
      data,
      paging: false,
      columns,
      columnDefs,
      stateSave: true,
      headerCallback: (thead) => {
        tableAdapter.handleCreatedHeaderRowCallback(thead);
      },
      createdRow: (row) => {
        // Make the first cell in every row sticky if the user scrolls left/right
        const firstCell = $(row).find("td")[0];
        StickyAdapter.makeItSticky(firstCell, window, StickyAdapter.STICK_TO.LEFT);
        $(firstCell).addClass("risk-tables-report-table-firstCell");
      },
      footerCallback: () => {
        tableAdapter.handleCreatedFooterRowCallback(tableId);
      },
    });

    // Mark the columns that requires default sorting...
    tableAdapter.markColumnsRequiresSorting(tableId);
  }

  render() {
    let {data, report, reportDate, quickPanelKeyToInstanceMap, selectedUOId, reportSettings} = this.props;
    let {quickPanelInfo, quickPanelOperation} = this.state;
    let hasData = data && data.length > 0;
    if (reportSettings.allowSelectUO && hasData && selectedUOId && selectedUOId !== ALL_UOS_OPTION.id) {
      // Make sure there's data for the selected UO.
      hasData = !!data.find(tableData => tableData.unitOperationId === selectedUOId);
    }
    let hasUnitOperationIds = hasData && (data[0].unitOperationId !== -1);

    return <div className="col-sm-12">
      {this.renderRiskLinkConfirmationDialog()}
      {hasData ? (
        <div id="riskTables">
          {data.map(tableData => {
              let {unitOperation, unitOperationId, unitOperationState} = tableData;
              if (!reportSettings.allowSelectUO || selectedUOId === ALL_UOS_OPTION.id || selectedUOId === unitOperationId) {
                return (
                  <div className="row print-avoid-page-break"
                       key={report + "_" + unitOperationId}
                  >
                    {hasUnitOperationIds ? (
                      <Heading level={2} className="risk-tables-uo-header">
                        <a href={getURLByTypeCodeAndId(getTypeCodeForModelName("unitoperation"), "View", unitOperationId)}
                           rel="noopener noreferrer"
                           target="_blank"
                        >
                          {getTypeCodeForModelName("unitoperation") + "-" + unitOperationId + " - " + unitOperation}
                        </a>
                        <span className="risk-tables-uo-stamp">{CommonRiskTable.getStampFromState(unitOperationState)}</span>
                      </Heading>
                    ) : ""}
                    <div className="form-group">
                      <table ref={hasUnitOperationIds ? "table_" + unitOperationId : "table"}
                             className="table table-bordered table-hover risk-table"
                             id={hasUnitOperationIds ? "riskTable_" + unitOperationId : "riskTable"}
                             style={{width: "100%"}}
                      />
                    </div>
                  </div>);
              } else {
                return null;
              }
            }
          )}
        </div>
      ) : (
        <h2 id="emptyTableHeader">{"No data exists as of " + UIUtils.getDateForDisplayToUser(reportDate)}</h2>
      )}
      <QuickPanel typeCode={quickPanelInfo.typeCode}
                  ref={this.quickPanel}
                  id={quickPanelInfo.id}
                  config={QUICK_PANEL_CONFIG}
                  editorType={EDITOR_TYPES.QUICK_PANEL}
                  editorOperation={quickPanelOperation}
                  asOfDate={reportDate}
                  data={quickPanelKeyToInstanceMap.get(
                    quickPanelInfo.typeCode + "-" + quickPanelInfo.id)}
                  onCancelLoad={this.handleCancelQuickEditLoad}
                  onChangeEditorOperation={this.handleChangeEditorOperation}
                  onRiskLinkConfirmationDialogRender={this.onRiskLinkConfirmationDialogRender}
      />
    </div>;
  }
}
