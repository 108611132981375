"use strict";

import React from "react";
import { WIDGET_FIELDS } from "./risk_supporting_documents_attribute_constants";
import BaseLinksAttachmentsAttribute from "../attributes/base_links_attachments_attribute";
import LabelTooltip from "../../widgets/tooltips/label_tooltip";
import CommonUtils from "../../../server/common/generic/common_utils";

/**
 * This class is responsible for handling the supporting documents for a batch record.
 */
export default class RiskSupportingDocumentsAttribute extends BaseLinksAttachmentsAttribute {

  constructor(props) {
    super(props, WIDGET_FIELDS);
    this.initializeStateObservers();
  }

  initializeStateObservers() {
    this.props.parent.addOnRiskLinksDeletedHandler(() => {
      this.forceUpdateSafely();
    });
    this.props.parent.addOnRiskLinksEditedHandler(() => {
      this.forceUpdateSafely();
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      return (this.props.triggerChildUpdate !== nextProps.triggerChildUpdate);
    }
    return true;
  }

  getAppliesToOptions() {
    return this.props.getRiskLinksOptions();
  }

  handleDelete(rowData) {
    super.handleDelete(rowData);
    this.props.onDocumentDelete(rowData);
  }

  handleSave(rowData, rows, setValueCallback, errorReport = new Map()) {
    super.handleSave(rowData, rows, setValueCallback, errorReport);
    this.props.onDocumentSave(rowData);
  }

  getManageColumn() {
    return {
      title: "",
      width: 1,
      class: "invisible-links-manage-column-header",
      data: result => result.index,
    };
  }

  getChildRowFieldTooltip(field, rowData) {
    return (
      <LabelTooltip text={this.isRowInEditMode(rowData.uuid) ? field.displayName : ""}
                    className="col-form-label links-details-field-label"
                    noColon={true}
                    instructions={this.getFieldInstructionsInput(field, rowData)}
                    getTooltipCallback={this.getFieldTooltipText.bind(this, field)}
      />
    );
  }

  /**
   * Overwrite this in child classes to customize the rendering of a particular cell.
   * This method will cover the field types returned by BaseJsonAttribute.getSupportedFieldTypes()
   * Overwrite getFieldInput to further customize the input controls used in table cell inline editors
   * @param field The field to customize the column cell for
   * @param rowData The row data object
   */
  getColumnDef(field, rowData) {
    let isLoading = this.props.parent.isLoadingRiskLinks();
    let columnDef = super.getColumnDef(field, rowData);
    const id = this.props.name + "_" + CommonUtils.capitalize(field.fieldName) + "_" + rowData.index;
    if (!this.isRowInEditMode(rowData.uuid) && field.fieldName === "appliesTo") {
      let value = field.getValue(rowData);
      return <div className={isLoading ? "links-manage skeleton" : "links-manage"}>
        {
          field.singleLineOptions ? (
            <div id={id} className="d-flex flex-column chips-list">
              {value
                .map(option =>
                  <div key={option.id || option} className="chip-item mr-auto rounded">{this.getOptionSafely(field, option)}</div>)}
            </div>
          ) : (
            <span id={id}>
              {value.map(option => this.getOptionSafely(field, option)).join(", ")}
            </span>
          )}
      </div>;
    }
    return columnDef;
  }
}
