"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import BasePage from "../base_page";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import { defaultListingMap, mapLatestVersionForFilter } from "../utils/filter_helper";
import DocumentListGeneric from "./document_list_generic";
import PageFrameLayout from "../widgets/layout/page_frame_layout";

/* This shows the list of documents. */
// i18next-extract-mark-ns-start documents
class DocumentListPage extends BasePage {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    UIUtils.setLoadingDisabled(true);
    const {t} = this.props;
    document.title = t("QbDVision Documents");

    this.setStateSafely({
      breadcrumb: {
        current: t("Documents"),
      },
    });

    super.componentDidMount();
  }

  renderPage() {
    const {t} = this.props;
    const filters = [
      {
        key: "Approved",
        selected: (items) => items.length,
        order: -1,
        filter: (item) => item.isApprovedVersion && !item.deletedAt,
        map: defaultListingMap,
        name: t("Approved"),
        disableShowArchived: true,
        linkToLastDraft: false,
      },
      {
        key: "WaitingForMyApproval",
        selected: false,
        order: 1,
        filter: (item) => {
          const lastVersion = item.LastVersion;
          const currentState = lastVersion && lastVersion.currentState;

          return currentState
            && (currentState.startsWith("Proposed") || currentState.startsWith("Routing"))
            && item.hasApprovalRequestsToMe;
        },
        map: mapLatestVersionForFilter,
        name: t("Waiting for my approval"),
        disableShowArchived: true,
        linkToLastDraft: true,
      },
      {
        key: "WaitingForOthers",
        selected: false,
        order: 2,
        filter: (item) => {
          const lastVersion = item.LastVersion;
          const currentState = lastVersion && lastVersion.currentState;

          return currentState
            && (currentState.startsWith("Proposed") || currentState.startsWith("Routing"))
            && item.hasApprovalRequestsToOthers;
        },
        map: mapLatestVersionForFilter,
        name: t("Waiting for others"),
        disableShowArchived: true,
        linkToLastDraft: true,
      },
    ];

    return (
      <PageFrameLayout title={t("Documents")}>
        <DocumentListGeneric filters={filters} t={t} subHeaderTooltip={t("A library of all documents at the corporate or quality system level.")} />
      </PageFrameLayout>
    );
  }
}

export default I18NWrapper.wrap(DocumentListPage, "documents");
// i18next-extract-mark-ns-stop documents
