"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import CompanyLoginHeader from "../../widgets/headers/company_login_header";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { RetryPleaseError, RetryWrapper } from "../../helpers/retry_wrapper";
import * as CognitoHelper from "../../helpers/cognito_helper";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import BaseReactComponent from "../../base_react_component";
import { Auth } from "aws-amplify";

const Logger = Log.group(LOG_GROUP.Users, "UserSignup");

// i18next-extract-mark-ns-start users
class UserSignupVerification extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {verificationCode: ""};
    UIUtils.setSignUpEmail(decodeURIComponent(UIUtils.getParameterByName("email")));
  }

  componentDidMount() {
    const {t} = this.props;
    document.title = t("Join QbDVision");

    super.componentDidMount();
  }

  handleChange(event) {
    this.setStateSafely({[event.target.name]: event.target.value});
  }

  handleVerifySignup(event) {
    event.preventDefault();
    const {t} = this.props;
    const username = decodeURIComponent(UIUtils.getParameterByName("username"));
    CognitoHelper.configureUserPool();

    // Send to Cognito
    UIUtils.showLoadingImage();

    // Uncomment for verbose logging
    // console.log("Confirming " + username + " with " + this.state.verificationCode);

    // noinspection JSIgnoredPromiseFromCall
    new RetryWrapper(
      async() => {
        try {
          await Auth.confirmSignUp(username, this.state.verificationCode);
          UIUtils.showSuccess(t("Confirmation successful!"));
          window.location.href = UIUtils.getSecuredURL("../index.html");
        } catch (err) {
          if (CognitoHelper.isCognitoErrorRetryable(err)) {
            Logger.info(() => "Retrying because of " + UIUtils.stringify(err));
            throw new RetryPleaseError();
          } else {
            UIUtils.hideLoadingImage();
            UIUtils.showError(err.message);
            throw err;
          }
        }
      },
      (ignored, waitInMS) => UIUtils.showError(t("Cannot confirm registration. Retrying in {{ retryWait }} seconds...", {retryWait: waitInMS / 1000}))
    ).retryFunction();
  }

  async resendVerificationCode(event) {
    event.preventDefault();
    const {t} = this.props;
    const username = decodeURIComponent(UIUtils.getParameterByName("username"));
    CognitoHelper.configureUserPool();

    // Uncomment for verbose logging
    // console.log("Resending verification code for " + username);

    UIUtils.showLoadingImage();
    // noinspection JSIgnoredPromiseFromCall
    await new RetryWrapper(
      async() => {
        try {
          await Auth.resendSignUp(username);
          const successMessage = t("A new verification code was resent to {{ email }}", {email: UIUtils.getSignUpEmail()});
          UIUtils.showSuccess(successMessage);
        } catch (error) {
          if (CognitoHelper.isCognitoErrorRetryable(error)) {
            Logger.info(() => "Retrying because of " + UIUtils.stringify(error));
            throw new RetryPleaseError();
          } else {
            UIUtils.showError(error.message);
          }
        } finally {
          UIUtils.hideLoadingImage();
        }
      },
      (ignored, waitInMS) => UIUtils.showError(t("Cannot resend confirmation code. Retrying in {{ retryWait }} seconds...", {retryWait: waitInMS / 1000}))
    ).retryFunction();
  }

  render() {
    const {t} = this.props;

    return (
      <div className="container-fluid">
        <CompanyLoginHeader firstHeader={t("Almost there")}
                            paragraph={<div className="text-center">
                              <p>{t(`Please check your email ({{ email }}) for the verification code.`, {email: UIUtils.getSignUpEmail()})}<br />
                                {t("If the verification code doesn't arrive in a few minutes, please check your spam folder.")}
                              </p>
                            </div>}
        />
        <br />
        <div className="center-single-column-grid">
          <div className="alert alert-danger d-none" role="alert" id="alertDiv" />
          <div className="row">
            <div className="col-sm-12">
              <form data-toggle="validator" role="form" id="verifyForm" onSubmit={this.handleVerifySignup}>
                <div className="form-group">
                  <label htmlFor="verificationInput" className="col-form-label">
                    {t("Verification Code")}
                  </label>
                  <input type="text" className="form-control" id="verificationInput" name="verificationCode"
                         pattern="^[0-9]{1,}$" data-minlength="5"
                         data-error={t("Verification code should be 5 or more numbers")} required={true}
                         onChange={this.handleChange}
                  />

                  <div className="help-block with-errors" />
                </div>
                <br />
                <div className="form-group">
                  <input type="submit" className="btn btn-block btn-lg btn-primary" id="submitButton"
                         value={t("Submit")}
                  />
                </div>
                <br />
                <div className="form-group">
                  <p className="text-center loginPageLinks-text">
                    <a href="javascript:void(0);"
                       id="resendVerificationCodeLink"
                       onClick={this.resendVerificationCode}
                    >{t("Resend Verification Code")}
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(UserSignupVerification, "users");
// i18next-extract-mark-ns-stop users
