"use strict";

import { DataAdapter, UNIT_OPERATION_DATA_MODEL } from "./data_adapter";
import * as UIUtils from "../../../../ui_utils";
import * as RiskTablesParser from "../../utils/risk_tables_parser";
import { formatLocationForExport } from "../../utils/risk_tables_formatter";
import * as CommonEditablesFormatter from "../../../../../server/common/editables/common_editables_formatter";

/**
 * This is the parent data adapter for list tables.
 * It will contain any common functionality for preparing and transforming from backend model structure
 * to the table view model structure.
 */
export class ListDataAdapter extends DataAdapter {

  constructor({reportData, steps, unitOperations}) {
    super(reportData, steps, unitOperations);
  }

  convertToRiskTableDataModel(showCriticalOnly, rmp, typeaheads) {
    const {materials, processComponents} = typeaheads;

    if (this.reportData.length > 0) {
      let criticalSortedReportData = super.convertToRiskTableDataModel(showCriticalOnly, rmp);
      criticalSortedReportData.forEach(instance => {
        instance.Location = formatLocationForExport(instance, this.unitOperations, this.steps);
        instance.effectiveRMP = rmp;
        instance.controlStrategy = UIUtils.secureString(
          CommonEditablesFormatter.formatControlStrategyForDisplay(instance.controlStrategy)
        );

        const parent = instance.parentModelType === "Material" ?
          materials.find(material => material.id === instance.parentId)
          : processComponents.find(processComponent => processComponent.id === instance.parentId);

        if (parent) {
          instance.sourceParentModelType = parent.typeCode;
          instance.sourceParentModelId = parent.id;
          instance.sourceParentModel = parent.name;
        }
      });

      let criticalSortedReportObjectsClone = UIUtils.deepClone(criticalSortedReportData);
      let objectsWithControlMethodAsString = RiskTablesParser.parseControlMethodsInRawData(criticalSortedReportObjectsClone);
      let objectsWithCriticalityInformation = RiskTablesParser.parseCriticalityInformationFromRawData(objectsWithControlMethodAsString);

      let tableDataModel = {...UNIT_OPERATION_DATA_MODEL};
      tableDataModel.data = objectsWithCriticalityInformation;

      return {
        criticalSortedReportData,
        tableDataModel: [tableDataModel]
      };
    } else {
      return [];
    }

  }

}
