"use strict";

import React from "react";
import * as UIUtils from "../../ui_utils";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faExpandArrowsAlt, faSearchMinus, faSearchPlus, faEdit} from "@fortawesome/free-solid-svg-icons";
import BaseReactComponent from "../../base_react_component";

/* We want to allow the user to type in a page number without loading the respective PDF page on every key press
   This flag is controlling the time in ms a page render will kick off, after each key press. When keys are typed in
   faster than this timeout, then the page render does not kick, until the user stops typing. This provides a
   non-interrupting typing experience for the user.
 */
const KEY_PRESS_TIMEOUT = 300;

/**
 * @enum {number}
 */
export const ZOOM = {
  IN: +1,
  OUT: -1,
  RESET: 0,
};

/**
 * This is responsible for rendering the zoom & pagination controls on the PDF Viewer
 * of the AI import workflow.
 */
export default class PDFControlBar extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.notifyPageChangeTimeout = null;

    this.setStateSafely({
      currentPage: props.currentPage,
    });
  }

  // noinspection JSCheckFunctionSignatures
  componentDidUpdate(prevProps) {
    if (prevProps.currentPage !== this.props.currentPage) {
      this.setStateSafely({currentPage: this.props.currentPage});
    }
  }

  handlePageChange(e) {
    const {
      onPageChange,
      currentPage,
      totalPages,
    } = this.props;

    this.setStateSafely({currentPage: e.target.value});

    if (onPageChange) {
      e.preventDefault();

      clearTimeout(this.notifyPageChangeTimeout);
      if (UIUtils.isInteger(e.target.value)) {
        const pageNumber = UIUtils.parseInt(e.target.value);
        if (pageNumber !== currentPage && pageNumber >= 1 && pageNumber <= totalPages) {
          this.notifyPageChangeTimeout = setTimeout(
            () => onPageChange(pageNumber),
            KEY_PRESS_TIMEOUT,
          );
        }
      }
    }
  }

  handleDownload() {
    const {onDownload, downloadLink} = this.props;
    if (onDownload && downloadLink) {
      onDownload(downloadLink);
    }
  }

  render() {
    const {
      totalPages,
      onZoomChanged,
      downloadLink,
      externalUrl
    } = this.props;

    const {currentPage} = this.state;

    let fileName = "";
    let extension = ".";

    if (downloadLink && downloadLink.fileName) {
      const fileNameParts = downloadLink.fileName.split(".");

      fileName = fileNameParts.slice(0, fileNameParts.length - 1).join(".");
      extension += fileNameParts[fileNameParts.length - 1];
    }

    return (
      <div className="document-preview-control-bar btn-toolbar align-content-stretch flex-nowrap flex-row" role="toolbar" aria-label="PDF navigation controls">
        <div className="document-preview-download d-flex align-items-stretch">
          {
            downloadLink
              ? (
                <a
                  className="download-link"
                  data-link-selector="filename-link"
                  onClick={() => this.handleDownload(downloadLink)}
                >
                  <div className="file-name">{fileName}</div>
                  <div className="file-extension">{extension}</div>
                  <div className="download-icon"><FontAwesomeIcon icon={faDownload} /></div>
                </a>
              )
              : externalUrl ? (
                <a
                  className="external-link"
                  data-link-selector="filename-link"
                  href={externalUrl}
                  target={"_blank"}
                  rel={"noreferrer"}
                >
                  <div className="file-name">Custom QbDVision Document</div>
                  <div className="file-extension"></div>
                  <div className="edit-icon"><FontAwesomeIcon icon={faEdit} /></div>
                </a>
              ) : null
          }
        </div>
        <div className="toolbar-divider" />
        <div className="document-preview-pager d-flex align-items-center">
          <div className="btn-group mx-auto" role="group" aria-label="Change page">
            <div className="input-group input-group-sm">
              <input id="pdfPageInput"
                     type="number"
                     min={1}
                     max={totalPages}
                     size={totalPages ? Math.ceil(totalPages / 10) : 3}
                     className="form-control input-sm current-page-number"
                     value={currentPage}
                     onChange={this.handlePageChange}
              />
              <div className="input-group-append">
                <div className="input-group-text">/ <span className="page-count">{totalPages}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="toolbar-divider" />
        <div className="document-preview-zoom d-flex align-items-end">
          <div className="btn-group ml-auto" role="group" aria-label="Zoom controls">
            <button id="zoomInButton"
                    onClick={(e) => onZoomChanged(ZOOM.IN, e)}
                    className="btn btn-links btn-default"
                    title="Zoom in"
            >
              <FontAwesomeIcon icon={faSearchPlus} />
            </button>
            <button id="zoomOutButton"
                    onClick={(e) => onZoomChanged(ZOOM.OUT, e)}
                    className="btn btn-links btn-default"
                    title="Zoom out"
            >
              <FontAwesomeIcon icon={faSearchMinus} />
            </button>
            <button id="resetButton"
                    onClick={(e) => onZoomChanged(ZOOM.RESET, e)}
                    className="btn btn-links btn-default"
                    title="Reset back to full size"
            >
              <FontAwesomeIcon icon={faExpandArrowsAlt} />
            </button>
          </div>
        </div>
      </div>
    );
  }
}
