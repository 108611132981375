"use strict";

import { BasePDFRenderer } from "./base_pdf_renderer";
import { Log, LOG_GROUP } from "../../../../server/common/logger/common_log";
import { Ensure } from "../../../../server/common/generic/common_ensure";
import * as UIUtils from "../../../ui_utils";

// eslint-disable-next-line no-unused-vars
const Logger = Log.group(LOG_GROUP.Documents, "PDFPageRenderer");

/**
 * @typedef IPDFRenderContext
 * @property currentPage {number} The number of the page being displayed in the viewer.
 * @property pageCount {number} The total number of pages in the document.
 */

export class PDFPageRenderer extends BasePDFRenderer {
  /**
   * @param viewerRoot {React.RefObject<HTMLElement>} The root container that hosts the PDF viewer.
   * This is the container that gets resized when the window is resized.
   */
  constructor(viewerRoot) {
    super(viewerRoot);
    this.isReady = false;
  }

  /**
   * Identifies whether or not the specified page needs to be rendered.
   * @param page {IPDFPage|ICachedPDFDocument} The page that needs to be tested whether it should be rendered.
   * @param context {IPDFRenderContext} The object with information about the current rendering operation.
   */
  shouldRender(page, context) {
    Ensure.that({page}).isNotFalsy();
    let result = false;

    let {
      currentPage,
      pageCount,
    } = context;

    currentPage = UIUtils.isInteger(currentPage) ? UIUtils.parseInt(currentPage) : 1;

    let rootPanel = this.viewerRoot.current;
    if (!rootPanel) {
      throw new TypeError("There is no root viewer panel associated to this render. Please make sure all react " +
        "references are being created correctly and that the page is rendered already");
    }

    let extraPagesToRender = Math.ceil(rootPanel.clientHeight / page.scaledViewport.height) + 2;
    let initialPage = currentPage - extraPagesToRender;
    // if the initial page is rendered, we can increase the number of extra pages to render
    let finalPage = currentPage + (extraPagesToRender * (this.isReady ? 3 : 1));

    if (initialPage <= 0) {
      finalPage = Math.min(finalPage + Math.abs(0 - initialPage), context.pageCount);
      initialPage = 1;
    }
    if (finalPage >= pageCount) {
      initialPage = Math.max(initialPage - Math.abs(finalPage - pageCount), 1);
      finalPage = pageCount;
    }
    if ((page.number >= initialPage) && (page.number <= finalPage)) {
      result = page.shouldRender || !page.rendered;
    }

    if (result) {
      Logger.verbose(() => `Rendering PDF Page: ${page.number} (${initialPage} > ${currentPage} > ${finalPage} > ${pageCount})`);
    }
    return result;
  }

  /**
   * Renders the specified PDF page
   * @param page {IPDFPage|ICachedPDFDocument} The page that needs to be tested whether it should be rendered.
   * @param context {IPDFRenderContext} The object with information about the current rendering operation.
   */
  async renderCore(page, context) {
    Ensure.that({page}).isNotFalsy();
    Ensure.that({
      "page.canvasWrapper": page.canvasWrapper,
      "page.view": page.view,
    }).areNotFalsy();
    Ensure.virtual("render", {page, context});
    page.canvasWrapper.empty();
    await page.view.paintOnCanvas(page.canvasWrapper[0]);

    if (page.renderTextLayer) {
      await this.renderTextLayer(page);
    }
    page.cancelled = false;
    return page;
  }

  /**
   * Renders the PDF text layer
   * @param page {!IPDFPage} The page that has the text to be rendered.
   * @returns {Promise<void>}
   */
  async renderTextLayer(page) {
    if (page.textWrapper) {
      page.textWrapper.empty();
    }

    // borrowed from the internals of pdfJS
    let textLayer = page.view.textLayerFactory.createTextLayerBuilder(
      page.textWrapper[0],
      page.view.id - 1,
      page.view.viewport,
      true,
    );
    page.view.textLayer = textLayer;

    if (textLayer) {
      const readableStream = page.view.pdfPage.streamTextContent({
        normalizeWhitespace: true
      });
      textLayer.setTextContentStream(readableStream);
      await textLayer.render();
    }
  }
}
