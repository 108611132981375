"use strict";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import * as PropTypes from "prop-types";
import BaseReactComponent from "../../base_react_component";

/**
 * Renders a button that performs some action, such as the Add button for a table such as the risk links table.
 */
export class ActionButton extends BaseReactComponent {
  render() {
    const {disabled, onClick, id, label, disabledReason} = this.props;

    return <div className={`links-btn-div attribute-action-button ${this.getClassForLoading(true)}`}>
      <button id={id}
              type="button"
              className="btn btn-primary btn-links"
              aria-label={label}
              disabled={disabled}
              title={disabled ? disabledReason : null}
              onClick={onClick}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
      <label
        htmlFor={id}
        className="links-btn-label-right"
      >{label}</label>
    </div>;
  }
}

ActionButton.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  onClick: PropTypes.any,
  attributeName: PropTypes.any
};