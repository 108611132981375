"use strict";

import { FIELD_PROPS } from "../constants/configurable_tables_field_props_config";
import {
  COMMON_VARIABLE_ABOUT_COLUMNS,
  COMMON_VARIABLE_COLUMNS,
  RECEIVING_SITE_COMMON_COLUMNS_PROPS,
  SENDING_SITE_COMMON_COLUMNS_PROPS,
  TECH_TRANSFER_ASSESSMENT_COLUMNS,
} from "../constants/configurable_tables_columns_config";
import {
  ACCEPTANCE_CRITERIA_SECTION,
  CRITICALITY_ASSESSMENT_SECTION
} from "../constants/configurable_tables_sections_config";
import {
  ACCEPTANCE_CRITERIA_COL_SPAN,
  CRITICALITY_ASSESSMENT_COL_SPAN
} from "../constants/configurable_tables_col_spans_config";
import { FILTER_TYPES } from "../../columnOperations/configurable_tables_column_operations_constants";
import RecordFields from "../record_fields";

/**
 * This class lists all Process Parameter fields to be shown in export, column selection and tables.
 */
export default class ProcessParameterFields extends RecordFields {
  get title() {
    return "Process Parameters";
  }

  get aboutSectionFields() {
    return super.filterTechTransferFields(COMMON_VARIABLE_ABOUT_COLUMNS);
  }

  get criticalityAssessmentSectionFields() {
    const fields = [...super.criticalityAssessmentSectionFields];
    const newFields = [
      {
        title: "Scale Dependent",
        prop: FIELD_PROPS.SCALE_DEPENDENT,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("Scale Dependent", FIELD_PROPS.SCALE_DEPENDENT),
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Scale Dependent",
        prop: FIELD_PROPS.SCALE_DEPENDENT,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("Scale Dependent", FIELD_PROPS.SCALE_DEPENDENT),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Scale Justification",
        prop: FIELD_PROPS.SCALE_JUSTIFICATION,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateTextColumn("Scale Justification", FIELD_PROPS.SCALE_JUSTIFICATION, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Scale Justification",
        prop: FIELD_PROPS.SCALE_JUSTIFICATION,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateTextColumn("Scale Justification", FIELD_PROPS.SCALE_JUSTIFICATION, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
    ];

    fields.splice(10, 0, ...newFields);
    return fields;
  }

  get acceptanceCriteriaSectionFields() {
    const fields = [...super.acceptanceCriteriaSectionFields];
    const newFields = [
      {
        title: "Lower Operating Limit",
        prop: FIELD_PROPS.LOWER_OPERATING_LIMIT,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        section: ACCEPTANCE_CRITERIA_SECTION,
        defaultTechTransferValue: false,
        defaultValue: false,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextColumn("Lower Operating Limit", FIELD_PROPS.LOWER_OPERATING_LIMIT, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Lower Operating Limit",
        prop: FIELD_PROPS.LOWER_OPERATING_LIMIT,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextColumn("Lower Operating Limit", FIELD_PROPS.LOWER_OPERATING_LIMIT, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Upper Operating Limit",
        prop: FIELD_PROPS.UPPER_OPERATING_LIMIT,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        section: ACCEPTANCE_CRITERIA_SECTION,
        defaultTechTransferValue: false,
        defaultValue: false,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextColumn("Upper Operating Limit", FIELD_PROPS.UPPER_OPERATING_LIMIT, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Upper Operating Limit",
        prop: FIELD_PROPS.UPPER_OPERATING_LIMIT,
        colspan: ACCEPTANCE_CRITERIA_COL_SPAN,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextColumn("Upper Operating Limit", FIELD_PROPS.UPPER_OPERATING_LIMIT, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      }];

    fields.splice(18, 0, ...newFields);
    return fields;
  }

  get fields() {
    return [
      ...COMMON_VARIABLE_COLUMNS,
      ...this.aboutSectionFields,
      ...this.criticalityAssessmentSectionFields,
      ...this.acceptanceCriteriaSectionFields,
      ...this.processCapabilitySectionFields,
      ...this.riskControlSectionFields,
      ...this.referenceAndStandardsSectionFields,
      ...TECH_TRANSFER_ASSESSMENT_COLUMNS,
    ];
  }
}
