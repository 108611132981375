"use strict";

import { FIELD_INPUT_TYPE } from "./edit_risk_links_confirmation_constants";

/**
 * @type {IWidgetField[]}
 */
export const WIDGET_FIELDS = [
  {
    fieldName: "link",
    displayName: "Supporting Document",
    order: 1,
    defaultValue: "",
    tooltipText: "",
    textDecoration: false,
    dynamicToolTip: false,
    inputType: FIELD_INPUT_TYPE.link
  },
  {
    fieldName: "appliesTo",
    displayName: "Applies To Risks",
    order: 2,
    defaultValue: "",
    tooltipText: "",
    textDecoration: true,
    dynamicToolTip: true,
    inputType: FIELD_INPUT_TYPE.text,
  },
  {
    fieldName: "keep",
    displayName: "Keep",
    defaultValue: false,
    order: 3,
    tooltipText: "",
    disabled: false,
    textDecoration: false,
    dynamicToolTip: false,
    inputType: FIELD_INPUT_TYPE.radio,
  },
  {
    fieldName: "remove",
    displayName: "Remove",
    defaultValue: false,
    order: 4,
    tooltipText: "",
    disabled: false,
    textDecoration: false,
    dynamicToolTip: false,
    inputType: FIELD_INPUT_TYPE.radio,
  },
];

export const DELETE_ROW_OPTIONS = {
  keep: true,
  remove: false
};