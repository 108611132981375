var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { classNames, getScrollbarWidth } from '@progress/kendo-react-common';
import { isRtl, tableKeyboardNavigationHeaderAttributes } from '@progress/kendo-react-data-tools';
/**
 * @hidden
 */
var Header = /** @class */ (function (_super) {
    __extends(Header, _super);
    function Header() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.headerWrap = null;
        _this.table = null;
        _this.syncScroll = false;
        _this.scrollbarWidth = getScrollbarWidth();
        _this.onScroll = function (_event) {
            if (_this.syncScroll) {
                _this.syncScroll = false;
                return;
            }
            if (!_this.headerWrap) {
                return;
            }
            var scrollLeft = _this.headerWrap.scrollLeft;
            var scrollableDataElement = _this.props.scrollableDataElement();
            if (scrollableDataElement && scrollableDataElement.scrollLeft !== scrollLeft) {
                scrollableDataElement.scrollLeft = scrollLeft;
            }
        };
        return _this;
    }
    Object.defineProperty(Header.prototype, "element", {
        get: function () {
            return this.props.elemRef.current;
        },
        enumerable: false,
        configurable: true
    });
    Header.prototype.componentDidMount = function () {
        var rtl = isRtl(this.element);
        if (this.props.columnResize) {
            this.props.columnResize.setIsRtl(rtl);
        }
        this.forceUpdate();
    };
    /**
     *
     * @param scrollLeft - Scrolls to the left (in pixels).
     */
    Header.prototype.setScrollLeft = function (scrollLeft) {
        if (this.headerWrap && this.headerWrap.scrollLeft !== scrollLeft) {
            this.syncScroll = true;
            this.headerWrap.scrollLeft = scrollLeft;
        }
    };
    /**
     *
     * @param width - Scrolls the width (in pixels).
     */
    Header.prototype.setWidth = function (width) {
        if (this.table) {
            this.table.style.width = width + 'px';
        }
    };
    Header.prototype.render = function () {
        var _this = this;
        if (!this.props.staticHeaders) {
            return (React.createElement("thead", __assign({ role: "presentation", className: classNames('k-grid-header', { 'k-grid-draggable-header': this.props.draggable }) }, tableKeyboardNavigationHeaderAttributes),
                this.props.headerRow,
                this.props.filterRow));
        }
        return (React.createElement("div", { ref: this.props.elemRef, className: classNames('k-grid-header', { 'k-grid-draggable-header': this.props.draggable }), role: "presentation" },
            React.createElement("div", { ref: function (div) { _this.headerWrap = div; }, className: "k-grid-header-wrap", style: this.scrollbarWidth ? {} : { borderWidth: 0 }, onScroll: this.onScroll, role: "presentation" },
                React.createElement("table", { ref: function (table) { _this.table = table; }, role: "presentation" },
                    React.createElement("colgroup", { ref: function (e) { _this.props.columnResize.colGroupHeader = e; } }, this.props.cols),
                    React.createElement("thead", __assign({ role: "presentation" }, tableKeyboardNavigationHeaderAttributes),
                        this.props.headerRow,
                        this.props.filterRow)))));
    };
    return Header;
}(React.Component));
export { Header };
