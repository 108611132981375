"use strict";

import React from "react";
import Cookies from "js-cookie";
import * as AjaxWrapper from "../../utils/ajax_wrapper";
import BaseReactComponent from "../../base_react_component";

/**
 *  This shows the "Cookies bar" at the bottom of the screen
 */
export default class CookiesBar extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate() {
    return false;
  }

  handleClose() {
    Cookies.set("COOKIES_ACCEPTED", true);
    AjaxWrapper.secureAjaxPUT(`users/addOrEdit?updateCookies=true`, {}, false);
    this.forceUpdate();
  }

  render() {
    if (Cookies.get("COOKIES_ACCEPTED") !== "true") {
      return (
        <div className="cookies-bar">
          <div className="container trial-container">
            <div className="row">
              <div className="col-sm-12">
                <div className="cookies-bar-notification">
                  We use cookies to provide the services and features offered on our website, and to improve our user experience. <a rel="noopener noreferrer" target="_blank" href="/terms/cookiePolicy.html">Learn more.</a>
                </div>
                <button className="btn btn-primary btn-float-right cookies-bar-got-it-button" id="gotItButton" onClick={this.handleClose}>
                  Got it
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
