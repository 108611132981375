import { Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from "prosemirror-view";
import { styleValue } from './../mark';
export var caretColorKey = new PluginKey('caret-color');
export function caretColor() {
    return new Plugin({
        key: caretColorKey,
        props: {
            decorations: function (state) {
                var doc = state.doc, selection = state.selection, storedMarks = state.storedMarks;
                if (!selection.empty || !storedMarks) {
                    return DecorationSet.empty;
                }
                var color = styleValue((storedMarks || []).find(function (m) { return m.type.name === 'style'; }), { name: 'color', value: /^.+$/ });
                if (!color) {
                    return DecorationSet.empty;
                }
                var parentNode = selection.$anchor.parent;
                var decorations = [];
                doc.descendants(function (node, pos) {
                    if (node.eq(parentNode)) {
                        decorations.push(Decoration.node(pos, pos + node.nodeSize, {
                            style: 'caret-color: ' + color
                        }));
                    }
                });
                return DecorationSet.create(doc, decorations);
            }
        }
    });
}
