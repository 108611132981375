// offsetWidth() is slowly transition to reporting double instead of integet in all browsers
// during this process (which is a bit back-and-forth) we need to make sure we are working across all browsers and versions
var OFFSET_ROUND = 1;
/**
 * @hidden
 */
var ColumnResize = /** @class */ (function () {
    function ColumnResize(triggerResize) {
        var _this = this;
        // Typings. `col` and `colgroup` do not differ, both represnt a `HTMLTableColElement`.
        // Implemented as `any`.
        this.columns = [];
        /**
         * The settings for resizing the Grid.
         */
        this.resizable = false;
        this.isRtl = false;
        this.setIsRtl = function (isRtl) {
            _this.isRtl = isRtl;
        };
        this.dragHandler = function (event, column, dragCue, end) {
            var e = event.originalEvent;
            if (!end) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
            }
            var tdElement = dragCue.parentElement;
            if (!tdElement || !tdElement.parentElement) {
                return;
            }
            var oldWidth = tdElement.clientWidth;
            var newWidth = oldWidth;
            if (_this.isRtl) {
                newWidth += (dragCue.getBoundingClientRect().right - (dragCue.offsetWidth / 2)) - event.clientX;
            }
            else {
                newWidth += event.clientX - dragCue.getBoundingClientRect().left - (dragCue.offsetWidth / 2);
            }
            if (!end && Math.abs(newWidth - oldWidth) < 1) {
                return;
            }
            _this.fixateInitialWidths(tdElement.parentElement.clientWidth);
            _this.setWidths(column, Math.floor(newWidth) / oldWidth);
            var index = _this.columns.filter(function (c) { return !c.children.length; }).findIndex(function (c) { return c.id === column.id; });
            _this.onResize(index, oldWidth, newWidth, e, end, column.id);
        };
        this.dblClickHandler = function (event, columnIds) {
            var columns = _this.columns.filter(function (c) { return columnIds.indexOf(c.id) > -1; });
            if (!_this.colGroupMain || !columns.length) {
                return;
            }
            var leafsForAdjust = {};
            var stack = columns;
            while (stack.length > 0) {
                var currentCol = stack.pop();
                if (!currentCol) {
                    break;
                }
                if (currentCol.children.length) {
                    stack.push.apply(stack, currentCol.children);
                }
                else {
                    leafsForAdjust[currentCol.id] = currentCol;
                }
            }
            var leafs = _this.columns.filter(function (c) { return !c.children.length; });
            var indexesForAdjust = [];
            leafs.forEach(function (currentColumn, index) {
                if (leafsForAdjust[currentColumn.id]) {
                    indexesForAdjust.push(index);
                }
            });
            var tables = [_this.colGroupMain.parentElement];
            var colgroups = [_this.colGroupMain];
            if (_this.colGroupHeader) {
                tables.push(_this.colGroupHeader.parentElement);
                colgroups.push(_this.colGroupHeader);
            }
            if (_this.colGroupFooter) {
                tables.push(_this.colGroupFooter.parentElement);
                colgroups.push(_this.colGroupFooter);
            }
            tables.forEach(function (table) { return table.classList.add('k-autofitting'); });
            var maxWidths = [];
            colgroups.forEach(function (colgroup) {
                indexesForAdjust.forEach(function (columnIndex) {
                    if (colgroup.children[columnIndex]) {
                        colgroup.children[columnIndex].width = '';
                        maxWidths[columnIndex] = Math.max(maxWidths[columnIndex] || 0, colgroup.children[columnIndex].offsetWidth + OFFSET_ROUND);
                    }
                });
            });
            colgroups.forEach(function (colgroup) {
                indexesForAdjust.forEach(function (columnIndex) {
                    if (colgroup.children[columnIndex]) {
                        colgroup.children[columnIndex].width = maxWidths[columnIndex] + 'px';
                        leafs[columnIndex].width = maxWidths[columnIndex];
                    }
                });
            });
            tables.forEach(function (table) { return table.classList.remove('k-autofitting'); });
            _this.onResize(indexesForAdjust[0], 0, 0, event, true, columnIds[0]);
        };
        this.updateColElements = function (affectedColumns) {
            var leafColumns = _this.columns.filter(function (c) { return !c.children.length; });
            var difference = 1e-10;
            var _loop_1 = function (i) {
                var currentColumn = affectedColumns[i];
                var colIndex = leafColumns.findIndex(function (c) { return c.id === currentColumn.id; });
                var currentColumnFloatWidth = parseFloat((currentColumn.width || 0).toString());
                var currentColumnFloorWidth = Math.floor(currentColumnFloatWidth);
                difference += currentColumnFloatWidth - currentColumnFloorWidth;
                var currentWidth = currentColumnFloorWidth + Math.floor(difference);
                difference -= Math.floor(difference);
                if (_this.colGroupMain && _this.colGroupMain.children[colIndex]) {
                    _this.colGroupMain.children[colIndex].width = currentWidth + 'px';
                }
                if (_this.colGroupHeader && _this.colGroupHeader.children[colIndex]) {
                    // static headers
                    _this.colGroupHeader.children[colIndex].width = currentWidth + 'px';
                }
                if (_this.colGroupFooter && _this.colGroupFooter.children[colIndex]) {
                    // static footers
                    _this.colGroupFooter.children[colIndex].width = currentWidth + 'px';
                }
            };
            for (var i = 0; i < affectedColumns.length; i++) {
                _loop_1(i);
            }
        };
        this.onResize = triggerResize;
    }
    ColumnResize.prototype.fixateInitialWidths = function (width) {
        var columns = this.columns.filter(function (c) { return !c.children.length; });
        var remainingCount = 0;
        var cols = this.colGroupMain ? this.colGroupMain.children : [];
        for (var i = 0; i < cols.length; i++) {
            if (cols[i].width) {
                width -= parseFloat(cols[i].width);
            }
            else {
                remainingCount++;
            }
        }
        if (remainingCount === 0) {
            return;
        }
        var perCol = Math.floor(width / remainingCount);
        for (var i = 0; i < cols.length; i++) {
            var col = cols[i];
            if (!col.width) {
                col.width = perCol;
                columns[i].width = perCol.toString();
                if (this.colGroupHeader) {
                    this.colGroupHeader.children[i].width = perCol;
                }
                if (this.colGroupFooter) {
                    this.colGroupFooter.children[i].width = perCol;
                }
            }
        }
    };
    ColumnResize.prototype.setWidths = function (column, coef) {
        var indexInOriginal = this.columns.findIndex(function (c) { return c.id === column.id; });
        var toAdjust = [];
        var more = column.children.length;
        for (var i = indexInOriginal + 1; more > 0 && i < this.columns.length; i++, more--) {
            var cc = this.columns[i];
            if (!cc.children.length) {
                toAdjust.push(cc);
            }
            else {
                more += cc.children.length;
            }
        }
        if (toAdjust.length === 0) {
            toAdjust.push(column);
        }
        toAdjust.forEach(function (colToAdjust) {
            var targetWidth = colToAdjust.width ? parseFloat(colToAdjust.width.toString()) * coef : 0;
            var min = colToAdjust.minResizableWidth === undefined ? 10 : colToAdjust.minResizableWidth;
            if (targetWidth < min) {
                targetWidth = min;
            }
            colToAdjust.width = targetWidth;
        });
        this.updateColElements(toAdjust);
        return toAdjust;
    };
    return ColumnResize;
}());
export { ColumnResize };
