"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import * as StickyAdapter from "../../../utils/sticky_adapter";
import ImplementationNeededError from "../../../utils/implementation_needed_error";
import BaseReactComponent from "../../../base_react_component";

/**
 * This is a base class for document bulk review and results tables.
 */
export default class DocumentBulkAddTableBase extends BaseReactComponent {

  constructor(props, id) {
    super(props, id);

    this.id = id;
    this.UUIDToRowIndex = new Map();
  }

  generateColumns() {
    throw new ImplementationNeededError("generateColumns should be implemented in derived class of DocumentBulkAddTableBase.");
  }

  componentDidMount() {
    super.componentDidMount();

    this.initializeTable(this.props.documents);
    $("[data-toggle='popover']").popover({sanitizeFn: UIUtils.sanitizePopoverData});
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    const table = $(this.tableRef).DataTable();
    if (table) {
      table.destroy(true);
    }
  }

  initializeTable(documents) {
    const columns = this.generateColumns();

    if (this.table) {
      $(this.tableRef).DataTable().destroy();
    }

    this.table = $(this.tableRef)
      .DataTable({
        dom: this.props.datatablesDomOptions,
        iDisplayLength: this.props.pageLength ? this.props.pageLength : 10,
        paging: Object.prototype.hasOwnProperty.call(this.props, "paging") ? this.props.paging : true,
        lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
        data: documents,
        ordering: false,
        columns: columns,
        autoWidth: false,
        stateSave: false,
        headerCallback: (thead) => {
          // Make the header sticky if the user scrolls
          for (const th of $(thead).find("th")) {
            StickyAdapter.makeItSticky(th, "#documentBulkAddForm");
            $(th).addClass("risk-tables-report-table-th");
          }
        },
      });


    let records = this.table.rows().data().toArray();
    for (let i = 0; i < records.length; i++) {
      this.UUIDToRowIndex.set(records[i].uuid, i);
    }
  }

  render() {
    return (
      <div>
        <table ref={ref => this.tableRef = ref}
               className="table table-bordered table-hover results-table"
               id={this.id} style={{width: "100%"}}
        />
      </div>
    );
  }
}
