"use strict";
// The functions in this file are responsible for making a React Component stick to the top of the screen when it gets
// scrolled past the top.

import ReactDOM from "react-dom";

const STICK_TO = {
  TOP: "top",
  LEFT: "left",
};
module.exports.STICK_TO = STICK_TO;

/**
 * Make a react component stick to the screen (like the title bar when it floats up).
 *
 * @param thisReactComponent The react component to make sticky
 * @param parentScrollNode The parent node to stick to.
 * @param stickTo {STICK_TO} Stick to either the top or left side of the screen
 */
module.exports.makeItSticky = function(thisReactComponent, parentScrollNode = window, stickTo = STICK_TO.TOP) {
  const thisNode = thisReactComponent instanceof jQuery ? thisReactComponent : $(ReactDOM.findDOMNode(thisReactComponent));
  let position = getPosition(parentScrollNode, thisNode);

  $(window).resize(() => {
    position = getPosition(parentScrollNode, thisNode);
  });

  // Uncomment for verbose logging
  // console.log("Initial position: " + JSON.stringify(position));

  const scrollContainer = $(parentScrollNode);
  const applyStickyClass = () => {
    // Recalculate, since the size of things can bounce around a bit while loading.
    if (!thisNode.hasClass("stick-to-top")) {
      position = getPosition(parentScrollNode, thisNode);
    }

    const distanceFromTop = scrollContainer.scrollTop();
    const distanceFromLeft = scrollContainer.scrollLeft();
    switch (stickTo) {
      case STICK_TO.TOP:
        // Uncomment for verbose logging
        // console.log(`ScrollTop: ${distanceFromTop}, PositionTop: ${position.top}`);

        if (distanceFromTop > position.top) {
          thisNode.addClass("stick-to-top");
        } else {
          thisNode.removeClass("stick-to-top");
        }
        break;
      case STICK_TO.LEFT:
        if (distanceFromLeft > position.left) {
          thisNode.addClass("stick-to-left");
        } else {
          thisNode.removeClass("stick-to-left");
        }
        break;
    }
  };
  scrollContainer.scroll(applyStickyClass);
  applyStickyClass();
};

function getPosition(parentScrollNode, thisNode) {
  return thisNode.position();
}

