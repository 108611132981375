"use strict";

import React from "react";
import * as I18NWrapper from "../../i18n/i18n_wrapper";

import { UPDATE_ROW_OPTIONS, WIDGET_FIELDS } from "./edit_risk_links_confirmation_constants";
import { BaseRiskLinksConfirmationPopup } from "./base_risk_links_confirmation_popup";


class EditRiskLinksConfirmationPopup extends BaseRiskLinksConfirmationPopup {
  constructor(props) {
    super(props, WIDGET_FIELDS);
  }

  handleRowOptionChange(e) {

    super.handleRowOptionChange(e, UPDATE_ROW_OPTIONS);

  }

  // eslint-disable-next-line no-unused-vars
  handleConfirmAction(event) {
    const {documents} = this.state;
    this.props.onEditRiskLinksAction(documents);
  }

}

export default I18NWrapper.wrap(EditRiskLinksConfirmationPopup, "editor");
// i18next-extract-mark-ns-stop editor
