"use strict";

import React from "react";
import BasePopup from "../../editor/approval/base_popup";
import * as UIUtils from "../../ui_utils";
import * as styles from "./popup.module.scss";

export default class Popup extends BasePopup {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      id,
      title,
      children,
      primaryButtonText,
      onPrimaryButtonClick,
      hasPrimaryButton,
    } = this.props;
    return (
      <div className="modal fade" ref={this.setModalRef} id={id}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">{title}</h1>
              <button
                type="button"
                className="close"
                onClick={this.handleCancel}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-container">{children}</div>
            </div>
            <div className={`modal-footer ${styles["modal-footer"]}`}>
              <div className="modal-container">
                <div className="btn-group">
                  {hasPrimaryButton && (
                    <button
                      id={`${UIUtils.convertToCamelCaseId(
                        primaryButtonText,
                      )}ButtonOnPopup`}
                      type="button"
                      className="btn btn-primary"
                      onClick={onPrimaryButtonClick}
                    >
                      {primaryButtonText}
                    </button>
                  )}
                  <button
                    id="cancelButtonOnPopup"
                    type="button"
                    className="btn btn-secondary"
                    onClick={this.handleCancel}
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Popup.defaultProps = {
  hasPrimaryButton: true
};
