"use strict";

import React, { Fragment } from "react";
import ImportDataReviewWidget from "../widgets/dataReviewWizardStep/import_data_review_widget";
import { generateTooltip } from "../../utils/ui_permissions";
import ImportBaseStep from "./import_base_step";
import { IMPORT_STEPS } from "../constants/import_constants";
import { StatusDisplayService } from "../../services/status_display_service";
import { ImportMessageHandler } from "../../services/import/import_message_handler";
import { ImportService } from "../../services/import/import_service";
import * as UIUtils from "../../ui_utils";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Import, "ImportDataReviewStep");

/**
 * This implements the Import wizard data review step
 */
export default class ImportDataReviewStep extends ImportBaseStep {
  constructor(props) {
    super(props);

    this.statusService = new StatusDisplayService();
    this.messageService = new ImportMessageHandler(this.statusService);
    this.importService = new ImportService(this.messageService, this.statusService);
  }

  activate(changeInfo) {
    const {
      hasPermissionToImport, fileData, projectId, projectType, dependency, importContinuousData,
      dataSectionName, onWizardStepDataUpdated, dataReviewRequired, modelName, process, selectedBatch,
      importType
    } = this.props;

    if (hasPermissionToImport) {
      if (hasPermissionToImport) {
        super.activate(changeInfo);

        onWizardStepDataUpdated({
          currentImportStep: IMPORT_STEPS.DataReview,
          stepsDisabledStatus: {
            [IMPORT_STEPS.DataReview]: false,
          },
        }, async() => {

          if (dataReviewRequired) {
            const payload = {
              action: "validate",
              fileData: JSON.stringify(fileData),
              projectId,
              projectType,
              processId: process && process.id,
              batchId: selectedBatch && selectedBatch.customID,
              dependency,
              model: modelName,
              dataSectionName,
              importContinuousData,
              importType,
            };

            try {
              const sheetData = await this.importService.validate(payload);
              Logger.info(() => "Received sheet data.");
              let validations = sheetData.validations;
              let records = sheetData.objects;
              let batches = sheetData.batches;
              Logger.info(
                () =>
                  `Received ${validations?.length} validations, ${records?.length} records and ${batches?.length} batches. Validations: `,
                Log.json(validations));

              let validationHasErrors = false;
              for (let i = 0; i < validations.length; i++) {
                validationHasErrors = validationHasErrors || validations[i].errors.length > 0;
                let progress = (100 * ((i + 1) / validations.length)).toFixed(0).toString();
                let uploadProgressBar = $("#importDataReviewProgressBar");
                if (uploadProgressBar && uploadProgressBar[0]) {
                  uploadProgressBar.css("width", progress + "%");
                  uploadProgressBar.css("color", progress < 20 ? "#BBB" : "#fff");
                  uploadProgressBar.html(`${progress}%`);
                  uploadProgressBar.attr("aria-valuenow", progress);
                }
              }

              onWizardStepDataUpdated({
                recordsCount: sheetData.recordsCount,
                validations,
                dataReviewRequired: false,
                records,
                batches,
                stepsDisabledStatus: {
                  [IMPORT_STEPS.DataImport]: validationHasErrors,
                },
              });
            } catch (e) {
              UIUtils.defaultFailFunction(e);
            }
          }
        });
      } else {
        onWizardStepDataUpdated({
          stepsDisabledStatus: {
            [IMPORT_STEPS.DataReview]: true,
          },
        });
      }
    }
  }

  renderStep() {
    let {
      modelNameForSecurity,
      dataSectionName,
      dataReviewRequired,
      recordsCount,
      validations,
      isPaperImport,
      securityAction
    } = this.props;
    let nextStepDisabled = this.isNextStepDisabled();

    return (
      <Fragment>
        <div className="col-12">
          <div className="row import-wizard-step-inner-container">
            <div className="col-12">
              <div className="import-data-review-div">
                <span className="import-data-review-title">Getting Data...</span>
                <ImportDataReviewWidget
                  title={modelNameForSecurity}
                  dataSectionName={dataSectionName}
                  dataReviewRequired={dataReviewRequired}
                  recordsCount={recordsCount}
                  validations={validations}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 import-screen-footer">
          <button
            disabled={nextStepDisabled}
            title={generateTooltip(securityAction, modelNameForSecurity, null, "")}
            className="btn btn-lg btn-primary import-footer-btn"
            id="nextButton"
            onClick={this.handleMoveToNextStep}
          >
            Next &gt;
          </button>
          <button className="btn btn-lg btn-primary import-footer-btn"
                  id="importDataReviewStepPreviousButton"
                  onClick={this.handleMoveToPreviousStep}
          >
            {isPaperImport ? "< Extract Data" : "< Upload File"}
          </button>
          {this.renderImportExitButton()}
        </div>
      </Fragment>
    );
  }
}
