"use strict";

import { faFileExcel, faFileImage, faFilePdf, faFileWord } from "@fortawesome/free-regular-svg-icons";
import { RISK_COLORS } from "../../rmps/constants/rmp_constants";
import * as ChartConfigs from "../chart_reports/chart_config_builder";
import * as ChartReportHelper from "../chart_reports/chart_report_helper";
import { TPPElementDataTransform } from "../data_transform/canned_reports/tpp_element_data_transform";
import { RequirementElementDataTransform } from "../data_transform/canned_reports/requirement_element_data_transform";
import { RequirementSummaryDataTransform } from "../data_transform/canned_reports/requirement_summary_data_transform";
import {
  IndividualTrainingReportTransform,
} from "../data_transform/canned_reports/training/individual_training_report_transform";
import {
  DocumentTrainingReportTransform,
} from "../data_transform/canned_reports/training/document_training_report_transform";
import {
  TrainingMatrixReportTransform,
} from "../data_transform/canned_reports/training/training_matrix_report_transform";
import { QTPPSummaryDataTransform } from "../data_transform/canned_reports/qtpp_summary_data_transform";
import { FQARiskRankingDataTransform } from "../data_transform/canned_reports/fqa_risk_ranking_data_transform";
import { TPPSummaryDataTransform } from "../data_transform/canned_reports/tpp_summary_data_transform";
import {
  GeneralAttributesSummaryDataTransform,
} from "../data_transform/canned_reports/general_attributes_summary_data_transform";
import { SpecificationDataTransform } from "../data_transform/canned_reports/specification_data_transform";
import { RiskTablesDataTransform } from "../data_transform/canned_reports/risk_tables_data_transform";
import { cannedReportTransform, cannedReportWithMultipleTransforms } from "../data_transform/transform_config_helpers";
import {
  UnitOperationSummaryDataTransform,
} from "../data_transform/canned_reports/unit_operation_summary_data_transform";
import { BillOfMaterialsDataTransform } from "../data_transform/canned_reports/bill_of_materials_data_transform";
import {
  BillOfProcessComponentsDataTransform,
} from "../data_transform/canned_reports/bill_of_process_components_data_transform";
import { SupplierDataTransform } from "../data_transform/canned_reports/supplier_data_transform";
import { RMPDataTransform } from "../data_transform/canned_reports/rmp_data_transform";
import { FMEAUnitOperationDataTransform } from "../data_transform/canned_reports/fmea_unit_operation_data_transform";
import { IndividualMaterialDataTransform } from "../data_transform/canned_reports/individual_material_data_transform";
import { CQAControlStrategyTransform } from "../data_transform/canned_reports/cqa_control_strategy_transform";
import { UOSTPControlStrategyTransform } from "../data_transform/canned_reports/uo_stp_control_strategy_transform";
import { CQAReportUtils } from "../canned_reports/utils/cqa_report_utils";
import { InfluenceMapDataTransform } from "../data_transform/canned_reports/influence_map_data_transform";

export const NEEDS_ANALYSIS_TERM = "Needs Analysis";
export const ROW_RISK_TABLES_SORT_PRIORITY = ["FQA", "FPA", "IQA", "IPA", "MA", "PP"];
export const TEMPLATES_PATH = "/reports/templates";
/**
 * @enum {string}
 */
export const RISK_LABEL_ACTION = {
  SHOW_RISK_LABEL: "showRiskLabel",
  SHOW_EFFECT_LABEL: "showEffectLabel",
  SHOW_SCORE_LABEL: "showScoreLabel",
};

export const LINK_TYPE_ENUM = {
  ProcessCapability: "ProcessCapability",
};

export const FILE_TYPES_ENUM = {
  PDF: "pdf",
  WORD: "docx",
  EXCEL: "xlsx",
  CSV: "csv",
  PNG: "png",
};

export const RISK_TABLE_REPORTS_ENUM = {
  QTPP_QUALITY: "QTPP (Quality)",
  QTPP_PERFORMANCE: "QTPP (Performance)",
  MAs: "Material Attributes",
  PPs: "Process Parameters",
  XQAs_to_MAs: "Material Attributes - Intermediate & Final Attributes",
  XQAs_to_PPs: "Process Parameters - Intermediate & Final Attributes",
  XQAs_to_IQAs: "Intermediates - Intermediate & Final Attributes",
};

export const RISK_TABLE_REPORT_TO_RMP_ENUM = {
  QTPP_QUALITY: "FQA",
  QTPP_PERFORMANCE: "FPA",
  MAs: "MA",
  PPs: "PP",
  XQAs_to_PPs: "PP",
  XQAs_to_MAs: "MA",
  XQAs_to_IQAs: "IQA",
};

export const REPORT_TITLES_ENUM = {
  TPPSectionReport: "TPP Section Report",
  QTPPSummaryReport: "QTPP Summary Report",
  RequirementsSummaryReport: "Requirements Summary Report",
  RequirementElementReport: "Requirement Report",
  CQAControlStrategyReport: "CQA Control Strategy Report",
  UnitOperationControlStrategyReport: "Unit Operation Control Strategy Report",
  RequirementHistoricalSummaryReport: "Requirement Historical Summary Report",
  ProductQualityAttributesReport: "Product Quality Attributes Report",
  ProductReleaseSpecificationReport: "Product Release Specification Report",
  ProductStabilityTestingPanelReport: "Product Stability Testing Panel Report",
  TPPSummaryReport: "TPP Summary Report",
  TPPFullReport: "TPP Full Report",
  QTPPFullReport: "QTPP Full Report",
  CompositeRiskMapReport: "Risk Map (All)",
  RiskMapReport: "Risk Map",
  RiskTablesReport: "Risk Tables",
  RiskManagementPlan: "Risk Management Plan",
  LibraryControlChartsContinuousReport: "Control Charts (Continuous)",
  LibraryControlChartsDefectivesReport: "Control Charts (Defectives)",
  ControlChartsContinuousReport: "Control Charts (Continuous)",
  ControlChartsDefectivesReport: "Control Charts (Defectives)",
  LibraryProcessCapabilityDashboard: "Process Analytics Dashboard",
  ProcessCapabilityDashboard: "Process Analytics Dashboard",
  ProcessFlowMapReport: "Process Flow Map",
  SpecificationReport: "Specification Report",
  IndividualMaterialReport: "Individual Material Report",
  IndividualProcessComponentReport: "Individual Process Component Report",
  InfluenceMapReport: "Influence Map Report",
  UnitOperationsSummary: "Unit Operations Summary",
  BillOfRawMaterials: "Bill of Raw Materials",
  BillOfProcessComponents: "Bill of Process Components",
  ApprovedSuppliersList: "Approved Suppliers List",
  FMEAUnitOperation: "Failure Modes and Effects Analysis (FMEA) - Unit Operation",
  FQARiskRankingReport: "FQA Risk Ranking Report",
  IndividualTrainingReport: "Individual Training Report",
  DocumentTrainingReport: "Document Training Report",
  TrainingMatrixReport: "Training Matrix Report",
};

export const REPORT_TYPES_ENUM = {
  TPPSectionReport: "TPPSectionReport",
  TPPFullReport: "TPPFullReport",
  QTPPFullReport: "QTPPFullReport",
  TPPSummaryReport: "TPPSummaryReport",
  QTPPSummaryReport: "QTPPSummaryReport",
  RequirementsSummaryReport: "RequirementsSummaryReport",
  RequirementElementReport: "RequirementElementReport",
  CQAControlStrategyReport: "CQAControlStrategyReport",
  UnitOperationControlStrategyReport: "UnitOperationControlStrategyReport",
  RequirementHistoricalSummaryReport: "RequirementHistoricalSummaryReport",
  ProductQualityAttributesReport: "ProductQualityAttributesReport",
  ProductReleaseSpecificationReport: "ProductReleaseSpecificationReport",
  ProductStabilityTestingPanelReport: "ProductStabilityTestingPanelReport",
  CompositeRiskMapReport: "CompositeRiskMapReport",
  RiskMapReport: "RiskMapReport",
  RiskTablesReport: "RiskTablesReport",
  RiskManagementPlan: "RiskManagementPlan",
  ControlChartsContinuousReport: "ControlChartsContinuousReport",
  LibraryControlChartsContinuousReport: "LibraryControlChartsContinuousReport",
  LibraryControlChartsDefectivesReport: "LibraryControlChartsDefectivesReport",
  ControlChartsDefectivesReport: "ControlChartsDefectivesReport",
  LibraryProcessCapabilityDashboard: "LibraryProcessCapabilityDashboard",
  ProcessCapabilityDashboard: "ProcessCapabilityDashboard",
  ProcessFlowMapReport: "ProcessFlowMapReport",
  SpecificationReport: "SpecificationReport",
  IndividualMaterialReport: "IndividualMaterialReport",
  IndividualProcessComponentReport: "IndividualProcessComponentReport",
  InfluenceMapReport: "InfluenceMapReport",
  UnitOperationsSummary: "UnitOperationsSummary",
  BillOfRawMaterials: "BillOfRawMaterials",
  BillOfProcessComponents: "BillOfProcessComponents",
  ApprovedSuppliersList: "ApprovedSuppliersList",
  FMEAUnitOperation: "FMEAUnitOperation",
  FQARiskRankingReport: "FQARiskRankingReport",
  IndividualTrainingReport: "IndividualTrainingReport",
  DocumentTrainingReport: "DocumentTrainingReport",
  TechTransferReport: "TechTransferReport",
  ConfigurableTables: "ConfigurableTables",
  TrainingMatrixReport: "TrainingMatrixReport",
};

export const REPORT_TABS_ENUM = {
  ALL_REPORTS: {
    title: "All",
  },
  RECENTLY_USED: {
    title: "Recent",
  },
  PRODUCT_REPORTS: {
    title: "Product",
  },
  PROCESS_REPORTS: {
    title: "Process",
  },
  FACILITIES_MATERIALS_REPORTS: {
    title: "Facilities/Materials",
  },
};

const infoBoxBackgroundColor = "#daeff7";
const indexChartBackgroundColor = "#effafe";
export const REPORT_THUMBNAILS_ENUM = {
  TPP_FULL_REPORT: {
    type: REPORT_TYPES_ENUM.TPPFullReport,
    title: REPORT_TITLES_ENUM.TPPFullReport,
    baseURL: "reports/cannedReports/base.html",
    description: "Detailed Target Product Profile report as of a specific date including all sections of the TPP with full details and a Table of Contents.",
    thumbnailURL: "/images/reports/TPPFullReport.png",
    tab: REPORT_TABS_ENUM.PRODUCT_REPORTS,
    order: 1,
  },
  TPP_SECTION_REPORT: {
    type: REPORT_TYPES_ENUM.TPPSectionReport,
    title: REPORT_TITLES_ENUM.TPPSectionReport,
    baseURL: "reports/cannedReports/base.html",
    description: "Detailed Target Product Profile report for a selected section of the TPP. ",
    thumbnailURL: "/images/reports/TPPSectionReport.png",
    tab: REPORT_TABS_ENUM.PRODUCT_REPORTS,
    order: 2,
  },
  TPP_SUMMARY_REPORT: {
    type: REPORT_TYPES_ENUM.TPPSummaryReport,
    title: REPORT_TITLES_ENUM.TPPSummaryReport,
    baseURL: "reports/cannedReports/base.html",
    description: "Summary Target Product Profile report in table format as of a specific date with the TPP sections listed in rows and information for each TPP section summarized in columns.",
    thumbnailURL: "/images/reports/TPPSummaryReport.png",
    tab: REPORT_TABS_ENUM.PRODUCT_REPORTS,
    order: 3,
  },
  QTPP_SUMMARY_REPORT: {
    type: REPORT_TYPES_ENUM.QTPPSummaryReport,
    title: REPORT_TITLES_ENUM.QTPPSummaryReport,
    baseURL: "reports/cannedReports/base.html",
    description: "Summary Quality Target Product Profile report in table format as of a specific date with each Final Quality Attribute listed in rows and key information for each FQA summarized in columns.",
    thumbnailURL: "/images/reports/QTPPSummary.png",
    tab: REPORT_TABS_ENUM.PRODUCT_REPORTS,
    order: 4,
  },
  QTPP_FULL_REPORT: {
    type: REPORT_TYPES_ENUM.QTPPFullReport,
    title: REPORT_TITLES_ENUM.QTPPFullReport,
    baseURL: "reports/cannedReports/base.html",
    description: "Detailed Quality Target Product Profile report as of a specific date including all sections of the TPP with full details and a Table of Contents.",
    thumbnailURL: "/images/reports/QTPPFullReport.png",
    tab: REPORT_TABS_ENUM.PRODUCT_REPORTS,
    order: 5,
  },
  REQUIREMENT_HISTORICAL_SUMMARY_REPORT: {
    type: REPORT_TYPES_ENUM.RequirementHistoricalSummaryReport,
    title: REPORT_TITLES_ENUM.RequirementHistoricalSummaryReport,
    baseURL: "reports/cannedReports/base.html",
    description: "Detailed information about a selected requirement (Quality Attributes, Material Attributes, Process Parameters) with changes summarized historically by version.",
    thumbnailURL: "/images/reports/RequirementHistoricalSummaryReport.png",
    tab: REPORT_TABS_ENUM.PRODUCT_REPORTS,
    order: 6,
  },
  REQUIREMENT_REPORT: {
    type: REPORT_TYPES_ENUM.RequirementElementReport,
    title: REPORT_TITLES_ENUM.RequirementElementReport,
    baseURL: "reports/cannedReports/base.html",
    description: "Detailed information about a selected requirement (Quality Attributes, Material Attributes, Process Parameters)",
    thumbnailURL: "/images/reports/RequirementReport.png",
    tab: REPORT_TABS_ENUM.PRODUCT_REPORTS,
    order: 7,
  },
  REQUIREMENTS_SUMMARY_REPORT: {
    type: REPORT_TYPES_ENUM.RequirementsSummaryReport,
    title: REPORT_TITLES_ENUM.RequirementsSummaryReport,
    baseURL: "reports/cannedReports/base.html",
    description: "Summary Requirements report in table format as of a specific date with each Requirement listed in rows and key information for each summarized in columns.",
    thumbnailURL: "/images/reports/RequirementsSummary.png",
    tab: REPORT_TABS_ENUM.PRODUCT_REPORTS,
    order: 8,
  },
  PRODUCT_QUALITY_ATTRIBUTES_REPORT: {
    type: REPORT_TYPES_ENUM.ProductQualityAttributesReport,
    title: REPORT_TITLES_ENUM.ProductQualityAttributesReport,
    baseURL: "product/list.html",
    fixedURLParams: "selectedProductListTab=QTPP&selectedProductViewTab=TABLES&selectedModelType=Quality Attributes",
    description: "The Product Quality Attribute report provides a listing of all Final Quality Attributes for this project. It includes relevant information such as attribute risk, acceptance criteria with justification and associated control methods, and overall control strategy with justification. The report is provided as an interactive, customizable, and exportable table of information for each quality attribute.",
    thumbnailURL: "/images/reports/ProductQualityAttributes.png",
    tab: REPORT_TABS_ENUM.PRODUCT_REPORTS,
    order: 9,
  },
  PRODUCT_RELEASE_SPECIFICATION_REPORT: {
    type: REPORT_TYPES_ENUM.ProductReleaseSpecificationReport,
    title: REPORT_TITLES_ENUM.ProductReleaseSpecificationReport,
    baseURL: "product/list.html",
    fixedURLParams: "selectedProductListTab=QTPP&selectedProductViewTab=TABLES&selectedModelType=Quality Attributes&filter={\"filters\":[{\"filters\":[{\"field\":\"controlStrategy\",\"operator\":\"eq\",\"value\":\"Release Testing\"}],\"logic\":\"or\"}],\"logic\":\"and\"}",
    description: "The Product Release Specification report provides a listing of all Final Quality Attributes for this project tagged for Release Testing as part of the Control Strategy. The report is provided as an interactive, customizable, and exportable table of information for each quality attribute.",
    thumbnailURL: "/images/reports/ProductQualityAttributes.png",
    tab: REPORT_TABS_ENUM.PRODUCT_REPORTS,
    order: 10,
  },
  PRODUCT_STABILITY_TESTING_PANEL_REPORT: {
    type: REPORT_TYPES_ENUM.ProductStabilityTestingPanelReport,
    title: REPORT_TITLES_ENUM.ProductStabilityTestingPanelReport,
    baseURL: "product/list.html",
    fixedURLParams: "selectedProductListTab=QTPP&selectedProductViewTab=TABLES&selectedModelType=Quality Attributes&filter={\"filters\":[{\"filters\":[{\"field\":\"stabilityIndicating\",\"operator\":\"eq\",\"value\":\"Yes\"}],\"logic\":\"or\"}],\"logic\":\"and\"}&visibleColumns=[\"stabilityIndicating\"]",
    description: "The Product Stability Testing Panel report provides a listing of all Final Quality Attributes for this project tagged as Stability Indicating. The report is provided as an interactive, customizable, and exportable table of information for each quality attribute.",
    thumbnailURL: "/images/reports/ProductQualityAttributes.png",
    tab: REPORT_TABS_ENUM.PRODUCT_REPORTS,
    order: 11,
  },

  RISK_TABLES_REPORT: {
    type: REPORT_TYPES_ENUM.RiskTablesReport,
    title: REPORT_TITLES_ENUM.RiskTablesReport,
    baseURL: "reports/riskTables.html",
    description: "Risk information displayed as color-coded tables with inputs related to outputs by unit operation.",
    thumbnailURL: "/images/reports/RiskTablesReport.png",
    tab: REPORT_TABS_ENUM.PRODUCT_REPORTS,
    order: 13,
  },
  FQARiskRankingReport: {
    type: REPORT_TYPES_ENUM.FQARiskRankingReport,
    title: REPORT_TITLES_ENUM.FQARiskRankingReport,
    baseURL: "reports/cannedReports/base.html",
    description: "Matrix comparing each Final Quality Attribute to their General Attribute risk assessment.",
    thumbnailURL: "/images/reports/FQARiskRankingReport.png",
    hideReport: (project) => {
      return project ? project.productRiskAssessmentType !== "Risk Ranking" : true;
    },
    order: 14,
    tab: REPORT_TABS_ENUM.PRODUCT_REPORTS,
  },
  CQAControlStrategyReport: {
    type: REPORT_TYPES_ENUM.CQAControlStrategyReport,
    title: REPORT_TITLES_ENUM.CQAControlStrategyReport,
    baseURL: "reports/cannedReports/base.html",
    description: "The CQA Control Strategy Report provides a detailed breakdown of the product and process controls in place for a selected Final Quality Attribute.",
    thumbnailURL: "/images/reports/CQAReport.png",
    tab: REPORT_TABS_ENUM.PRODUCT_REPORTS,
    order: 15,
  },
  UnitOperationControlStrategyReport: {
    type: REPORT_TYPES_ENUM.UnitOperationControlStrategyReport,
    title: REPORT_TITLES_ENUM.UnitOperationControlStrategyReport,
    baseURL: "reports/cannedReports/base.html",
    description: "The Unit Operation Control Strategy Report provides a detailed breakdown of critical process-related controls for a selected Unit Operation.",
    thumbnailURL: "/images/reports/UOSTPControlStrategy.png",
    tab: REPORT_TABS_ENUM.PRODUCT_REPORTS,
    order: 16,
  },
  RISK_MAP_REPORT: {
    type: REPORT_TYPES_ENUM.CompositeRiskMapReport,
    title: REPORT_TITLES_ENUM.RiskMapReport,
    baseURL: "reports/riskMap.html",
    parentPageURL: "riskMap.html",
    description: "Graphical map, color-coded by risk showing links from inputs to outputs for the entire manufacturing process. ",
    thumbnailURL: "/images/reports/RiskMapReport.png",
    tab: REPORT_TABS_ENUM.PROCESS_REPORTS,
    order: 20,
  },
  PROCESS_FLOW_MAP_REPORT: {
    type: REPORT_TYPES_ENUM.ProcessFlowMapReport,
    title: REPORT_TITLES_ENUM.ProcessFlowMapReport,
    baseURL: "reports/processMapReports.html",
    parentPageURL: "processMapReports.html",
    description: "A Process Flow Map showing process parameters, material attributes, IQAs and FQAs per unit operation.",
    thumbnailURL: "/images/reports/ProcessFlowMap.png",
    tab: REPORT_TABS_ENUM.PROCESS_REPORTS,
    order: 21,
  },
  RISK_TABLES_REPORT2: {
    type: REPORT_TYPES_ENUM.RiskTablesReport,
    title: REPORT_TITLES_ENUM.RiskTablesReport,
    baseURL: "reports/riskTables.html",
    description: "Risk information displayed as color-coded tables with inputs related to outputs by unit operation.",
    thumbnailURL: "/images/reports/RiskTablesReport.png",
    tab: REPORT_TABS_ENUM.PROCESS_REPORTS,
    order: 22,
  },
  FMEA_UNIT_OPERATION: {
    type: REPORT_TYPES_ENUM.FMEAUnitOperation,
    title: REPORT_TITLES_ENUM.FMEAUnitOperation,
    baseURL: "reports/cannedReports/base.html",
    description: "Tabular report detailing the failure modes and effects for Process Parameters in a Process Components. The report is per Unit Operation.",
    thumbnailURL: "/images/reports/FMEAReport.png",
    order: 23,
    tab: REPORT_TABS_ENUM.PROCESS_REPORTS,
  },
  PROCESS_CAPABILITY_DASHBOARD: {
    type: REPORT_TYPES_ENUM.ProcessCapabilityDashboard,
    title: REPORT_TITLES_ENUM.ProcessCapabilityDashboard,
    baseURL: "reports/processCapabilityDashboard.html",
    description: "Dashboard showing quality insights from process analytics data for a selected requirement (Final Quality Attribute, Quality Attributes, Material Attributes, Process Parameters).",
    thumbnailURL: "/images/reports/ProcessCapabilityDashboard.png",
    tab: REPORT_TABS_ENUM.PROCESS_REPORTS,
    order: 24,
  },
  CONTROL_CHARTS_CONTINUOUS_REPORT: {
    type: REPORT_TYPES_ENUM.ControlChartsContinuousReport,
    title: REPORT_TITLES_ENUM.ControlChartsContinuousReport,
    baseURL: "reports/chartReports.html",
    description: "Visual report showing graphs of process analytics data for a selected requirement (Final Quality Attribute, Quality Attributes, Material Attributes, Process Parameters).",
    thumbnailURL: "/images/reports/ProcessCapabilityReport.png",
    tab: REPORT_TABS_ENUM.PROCESS_REPORTS,
    order: 25,
  },
  CONTROL_CHARTS_DEFECTS_REPORT: {
    type: REPORT_TYPES_ENUM.ControlChartsDefectivesReport,
    title: REPORT_TITLES_ENUM.ControlChartsDefectivesReport,
    baseURL: "reports/chartReports.html",
    description: "Visual report showing 4 graphs of process analytics (Defects) data for a selected requirement (Final Quality Attribute, Quality Attributes, Material Attributes, Process Parameters).",
    thumbnailURL: "/images/reports/ProcessCapabilityReport.png",
    tab: REPORT_TABS_ENUM.PROCESS_REPORTS,
    order: 26,
  },
  INFLUENCE_MAP_REPORT: {
    type: REPORT_TYPES_ENUM.InfluenceMapReport,
    title: REPORT_TITLES_ENUM.InfluenceMapReport,
    baseURL: "reports/cannedReports/base.html",
    description: "A list of all individual training documents.",
    thumbnailURL: "/images/reports/DocumentTrainingReport.png",
    tab: REPORT_TABS_ENUM.PRODUCT_REPORTS,
    order: 27,
    hideReport: () => {
      return true;
    },
  },
  SPECIFICATION_REPORT: {
    type: REPORT_TYPES_ENUM.SpecificationReport,
    title: REPORT_TITLES_ENUM.SpecificationReport,
    baseURL: "reports/cannedReports/base.html",
    description: "Tabular report detailing specification information for Components, Intermediates, Products, and Raw Materials.",
    thumbnailURL: "/images/reports/SpecificationReport.png",
    tab: REPORT_TABS_ENUM.FACILITIES_MATERIALS_REPORTS,
    order: 40,
  },
  INDIVIDUAL_MATERIAL_REPORT: {
    type: REPORT_TYPES_ENUM.IndividualMaterialReport,
    title: REPORT_TITLES_ENUM.IndividualMaterialReport,
    baseURL: "reports/cannedReports/base.html",
    description: "Detailed report of a selected Material.",
    thumbnailURL: "/images/reports/IndividualMaterialReport.png",
    tab: REPORT_TABS_ENUM.FACILITIES_MATERIALS_REPORTS,
    order: 41,
  },
  INDIVIDUAL_PROCESS_COMPONENT_REPORT: {
    type: REPORT_TYPES_ENUM.IndividualProcessComponentReport,
    title: REPORT_TITLES_ENUM.IndividualProcessComponentReport,
    baseURL: "reports/cannedReports/base.html",
    description: "Detailed report of a Process Component and its related attributes.",
    thumbnailURL: "/images/reports/IndividualProcessComponentReport.png",
    tab: REPORT_TABS_ENUM.FACILITIES_MATERIALS_REPORTS,
    order: 42,
  },
  UNIT_OPERATION_SUMMARY: {
    type: REPORT_TYPES_ENUM.UnitOperationsSummary,
    title: REPORT_TITLES_ENUM.UnitOperationsSummary,
    baseURL: "reports/cannedReports/base.html",
    description: "Provides a summary list of Unit Operations with Input, Outputs, Suppliers, and Risk.",
    thumbnailURL: "/images/reports/UnitOperationsSummary.png",
    tab: REPORT_TABS_ENUM.FACILITIES_MATERIALS_REPORTS,
    order: 43,
  },
  BILL_OF_RAW_MATERIALS: {
    type: REPORT_TYPES_ENUM.BillOfRawMaterials,
    title: REPORT_TITLES_ENUM.BillOfRawMaterials,
    baseURL: "reports/cannedReports/base.html",
    description: "Detailed list of all Materials required to manufacture the project.",
    thumbnailURL: "/images/reports/BillOfRawMaterials.png",
    tab: REPORT_TABS_ENUM.FACILITIES_MATERIALS_REPORTS,
    order: 44,
  },
  BILL_OF_PROCESS_COMPONENTS: {
    type: REPORT_TYPES_ENUM.BillOfProcessComponents,
    title: REPORT_TITLES_ENUM.BillOfProcessComponents,
    baseURL: "reports/cannedReports/base.html",
    description: "Detailed list of process components to manufacture the project.",
    thumbnailURL: "/images/reports/BillOfProcessComponents.png",
    tab: REPORT_TABS_ENUM.FACILITIES_MATERIALS_REPORTS,
    order: 45,
  },
  APPROVED_SUPPLIERS_LIST: {
    type: REPORT_TYPES_ENUM.ApprovedSuppliersList,
    title: REPORT_TITLES_ENUM.ApprovedSuppliersList,
    baseURL: "reports/cannedReports/base.html",
    description: "A list of all active suppliers with at least one approved version.",
    thumbnailURL: "/images/reports/SupplierListApproved.png",
    order: 60,
    companyLevel: true,
  },
  RISK_MANAGEMENT_PLAN_REPORT: {
    type: REPORT_TYPES_ENUM.RiskManagementPlan,
    title: REPORT_TITLES_ENUM.RiskManagementPlan,
    baseURL: "reports/cannedReports/base.html",
    description: "Risk information displayed as color-coded tables with inputs related to outputs by unit operation.",
    thumbnailURL: "/images/reports/RiskManagementPlanReport.png",
    order: 61,
    companyLevel: true,
  },
  INDIVIDUAL_TRAINING_REPORT: {
    type: REPORT_TYPES_ENUM.IndividualTrainingReport,
    title: REPORT_TITLES_ENUM.IndividualTrainingReport,
    baseURL: "reports/cannedReports/base.html?minified=false",
    description: "A list of all individual training records.",
    thumbnailURL: "/images/reports/IndividualTrainingReport.png",
    order: 62,
    companyLevel: true,
  },
  DOCUMENT_TRAINING_REPORT: {
    type: REPORT_TYPES_ENUM.DocumentTrainingReport,
    title: REPORT_TITLES_ENUM.DocumentTrainingReport,
    baseURL: "reports/cannedReports/base.html?minified=false",
    description: "A list of all individual training documents.",
    thumbnailURL: "/images/reports/DocumentTrainingReport.png",
    order: 63,
    companyLevel: true,
  },
};

const exportAs = [
  {key: "pdf", value: " PDF Document (.pdf)", icon: faFilePdf},
  {key: "docx", value: " Microsoft Word (.docx)", icon: faFileWord},
  {key: "xlsx", value: " Microsoft Excel (.xlsx)", icon: faFileExcel},
];

const exportAsNoWord = [
  {key: "pdf", value: " PDF Document (.pdf)", icon: faFilePdf},
  {key: "xlsx", value: " Microsoft Excel (.xlsx)", icon: faFileExcel},
];

const exportAsNoExcel = [
  {key: "pdf", value: " PDF Document (.pdf)", icon: faFilePdf},
  {key: "docx", value: " Microsoft Word (.docx)", icon: faFileWord},
];

const exportProcessCapabilityReportsAs = [
  {key: "pdf", value: " PDF Document (.pdf)", icon: faFilePdf},
  {key: "png", value: " PNG Image (.png)", icon: faFileImage},
];

export const MODEL_TYPES_ENUM = {
  RMP: "RMP",
  FQA: "FQA",
  IQA: "IQA",
  FPA: "FPA",
  IPA: "IPA",
  MA: "MaterialAttribute",
  MT: "Material",
  PRC: "ProcessComponent",
  UO: "UnitOperation",
  STP: "Step",
  PP: "ProcessParameter",
  MTLS: "Specification",
};

export const REPORT_DRAFT_ENUM = {
  DraftMarker: "*",
  DraftMessage: "No approved version as of {informationDate}, Target information displayed is a DRAFT version.",
};

const REPORTS_TABLE_ADAPTERS = {
  PivotTableAdapter: require("../risk_tables/adapters/table/pivot_table_adapter").PivotTableAdapter,
  ListTableAdapter: require("../risk_tables/adapters/table/list_table_adapter").ListTableAdapter,
};

const REPORTS_DATA_ADAPTERS = {
  PivotDataAdapter: require("../risk_tables/adapters/data/pivot_data_adapter").PivotDataAdapter,
  ListDataAdapter: require("../risk_tables/adapters/data/list_data_adapter").ListDataAdapter,
};

export const REPORT_OPTIONS_ENUM = {
  RiskTablesDataReport: {
    isSummaryReport: true,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    defaultSubReport: "Product",
    subReportsInputLabel: "Risk Tables",
    renderProcessSelector: true,
    dependencies: [],
    isRiskTablesReport: true,
    editableModelNames: {
      QTPP_QUALITY: {
        templateName: "riskTables/risk_tables_Product.mrt",
        fullElementName: RISK_TABLE_REPORTS_ENUM.QTPP_QUALITY,
        shortElementName: "QTPP (Quality)",
        rowModelName: "FQA",
        header: "QTPP (Quality) Risk Table",
        isPivot: false,
        allowSelectUO: false,
        includeLocationColumn: false,
        modelName: "fqa",
        DataTableAdapterClass: REPORTS_TABLE_ADAPTERS.ListTableAdapter,
        DataAdapterClass: REPORTS_DATA_ADAPTERS.ListDataAdapter,
      },
      QTPP_PERFORMANCE: {
        templateName: "riskTables/risk_tables_Product.mrt",
        fullElementName: RISK_TABLE_REPORTS_ENUM.QTPP_PERFORMANCE,
        shortElementName: "QTPP (Performance)",
        rowModelName: "FPA",
        header: "QTPP (Performance) Risk Table",
        isPivot: false,
        allowSelectUO: false,
        includeLocationColumn: false,
        modelName: "fpa",
        DataTableAdapterClass: REPORTS_TABLE_ADAPTERS.ListTableAdapter,
        DataAdapterClass: REPORTS_DATA_ADAPTERS.ListDataAdapter,
      },
      MAs: {
        templateName: "riskTables/risk_tables_MA_PP.mrt",
        fullElementName: RISK_TABLE_REPORTS_ENUM.MAs,
        shortElementName: "MA",
        rowModelName: "MA",
        header: "Material Attributes Risk Table",
        isPivot: false,
        allowSelectUO: false,
        includeLocationColumn: true,
        includeParentInfo: true,
        showParentInRows: true,
        modelName: "MaterialAttribute",
        DataTableAdapterClass: REPORTS_TABLE_ADAPTERS.ListTableAdapter,
        DataAdapterClass: REPORTS_DATA_ADAPTERS.ListDataAdapter,
      },
      PPs: {
        templateName: "riskTables/risk_tables_MA_PP.mrt",
        fullElementName: RISK_TABLE_REPORTS_ENUM.PPs,
        shortElementName: "PP",
        rowModelName: "PP",
        header: "Process Parameters Risk Table",
        isPivot: false,
        allowSelectUO: false,
        includeLocationColumn: true,
        includeParentInfo: true,
        showParentInRows: true,
        modelName: "ProcessParameter",
        DataTableAdapterClass: REPORTS_TABLE_ADAPTERS.ListTableAdapter,
        DataAdapterClass: REPORTS_DATA_ADAPTERS.ListDataAdapter,
      },
      XQAs_to_MAs: {
        templateName: "riskTables/risk_tables_IQA.mrt",
        fullElementName: RISK_TABLE_REPORTS_ENUM.XQAs_to_MAs,
        shortElementName: "IQA/FQA-MA",
        columnModelName: "MA",
        rowModelName: "Intermediate or Final Attributes",
        parentModelName: "UO",
        header: "Material Attributes - Intermediate & Final Attributes Risk Table",
        rowReferenceLink: "product/qtpp/fqas",
        columnReferenceLink: "processExplorer/materialAttributes",
        parentReferenceLink: "processExplorer/unitOperations",
        rowSortPriority: ROW_RISK_TABLES_SORT_PRIORITY,
        includeParentInfo: true,
        isPivot: true,
        allowSelectUO: true,
        showParentInRows: false,
        showParentInCols: true,
        DataTableAdapterClass: REPORTS_TABLE_ADAPTERS.PivotTableAdapter,
        DataAdapterClass: REPORTS_DATA_ADAPTERS.PivotDataAdapter,
      },
      XQAs_to_PPs: {
        templateName: "riskTables/risk_tables_PP.mrt",
        fullElementName: RISK_TABLE_REPORTS_ENUM.XQAs_to_PPs,
        shortElementName: "IQA/FQA-PP",
        columnModelName: "PP",
        rowModelName: "Intermediate or Final Attributes",
        parentModelName: "PRC",
        header: "Process Parameters - Intermediate & Final Attributes Risk Table",
        rowReferenceLink: "processExplorer/iqas",
        columnReferenceLink: "processExplorer/processParameters",
        parentReferenceLink: "processExplorer/unitOperations",
        rowSortPriority: ROW_RISK_TABLES_SORT_PRIORITY,
        includeParentInfo: true,
        isPivot: true,
        allowSelectUO: true,
        showParentInRows: false,
        showParentInCols: true,
        DataTableAdapterClass: REPORTS_TABLE_ADAPTERS.PivotTableAdapter,
        DataAdapterClass: REPORTS_DATA_ADAPTERS.PivotDataAdapter,
      },
      XQAs_to_IQAs: {
        templateName: "riskTables/risk_tables_IQA.mrt",
        fullElementName: RISK_TABLE_REPORTS_ENUM.XQAs_to_IQAs,
        shortElementName: "FQA-IQA",
        columnModelName: "IQA",
        rowModelName: "Intermediate or Final Attributes",
        parentModelName: "UO",
        header: "Intermediates - Intermediate & Final Attributes Risk Table",
        rowReferenceLink: "product/qtpp/fqas",
        columnReferenceLink: "processExplorer/iqas",
        parentReferenceLink: "processExplorer/unitOperations",
        includeParentInfo: true,
        rowSortPriority: ROW_RISK_TABLES_SORT_PRIORITY,
        isPivot: true,
        allowSelectUO: true,
        showParentInRows: false,
        showParentInCols: false,
        DataTableAdapterClass: REPORTS_TABLE_ADAPTERS.PivotTableAdapter,
        DataAdapterClass: REPORTS_DATA_ADAPTERS.PivotDataAdapter,
      },
    },
    runAtStart: true,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  TPPSectionReport: {
    isSummaryReport: false,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "TPP Section",
    editableName: "TPPSection",
    typeAheadModels: ["TPPSection"],
    dependencies: [],
    dataTransform: cannedReportTransform([
      {type: TPPElementDataTransform},
    ]),
    editableModelNames: {
      TPPSection: {
        templateName: "requirementElement/TPP_element.mrt",
        fullElementName: "TARGET PRODUCT PROFILE",
        shortElementName: "TPP",
        editableName: "TPP",
        header: "Target Product Profile",
        referenceLink: "tpp",
      },
    },
    needsTypeaheadInput: true,
    needsDateInput: false,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  TPPFullReport: {
    isSummaryReport: true,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "Date",
    editableName: "TPPSection",
    dependencies: [],
    dataTransform: cannedReportTransform([
      {type: TPPSummaryDataTransform},
    ]),
    editableModelNames: {
      TPPSection: {
        templateName: "full/TPP_full.mrt",
        fullElementName: "TARGET PRODUCT PROFILE",
        shortElementName: "TPP",
        editableName: "TPP",
        header: "Target Product Profile",
        referenceLink: "tpp",
      },
    },
    runAtStart: true,
    needsDateInput: true,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  QTPPFullReport: {
    isSummaryReport: true,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "Date",
    editableName: "FQA",
    renderProcessSelector: true,
    rowSortPriority: ROW_RISK_TABLES_SORT_PRIORITY,
    dependencies: [],
    dataTransform: cannedReportWithMultipleTransforms([
      {type: GeneralAttributesSummaryDataTransform},
      {type: QTPPSummaryDataTransform},
      {type: BillOfMaterialsDataTransform},
      {type: SpecificationDataTransform, strategy: "RawMaterial"},
      {type: SpecificationDataTransform, strategy: "Components"},
      {type: RiskTablesDataTransform, strategy: "ma_prc_to_xqa"},
      {type: RiskTablesDataTransform, strategy: "pp_to_xqa"},
      {type: UnitOperationSummaryDataTransform},
    ]),
    editableModelNames: {
      FQA: {
        templateName: "full/QTPP_full.mrt",
        fullElementName: "Quality TARGET PRODUCT PROFILE",
        shortElementName: "FQA",
        editableName: "FQA",
        header: "Quality Target Product Profile",
        referenceLink: "fqa",
      },
    },
    runAtStart: true,
    needsDateInput: true,
    hideDatePicker: true,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  QTPPSummaryReport: {
    isSummaryReport: true,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "Date",
    editableName: "FQA",
    dependencies: [],
    dataTransform: cannedReportTransform([
      {type: QTPPSummaryDataTransform},
    ]),
    editableModelNames: {
      FQA: {
        templateName: "summary/QTPP_summary.mrt",
        fullElementName: "FINAL QUALITY ATTRIBUTE ELEMENT",
        shortElementName: "FQA",
        editableName: "FQA",
        header: "Final Quality Attribute",
        referenceLink: "product/qtpp/fqas",
      },
    },
    runAtStart: true,
    needsDateInput: true,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  FQARiskRankingReport: {
    isSummaryReport: true,
    exportAs: exportAsNoWord,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "Date",
    editableName: "FQA",
    dependencies: [],
    showDraftAlerts: true,
    fullScreen: true,
    dataTransform: cannedReportTransform([
      {type: FQARiskRankingDataTransform},
    ]),
    editableModelNames: {
      FQA: {
        templateName: "summary/FQA_risk_ranking_report.mrt",
        fullElementName: "FINAL QUALITY ATTRIBUTE ELEMENT",
        shortElementName: "FQA",
        editableName: "FQA",
        header: "Final Quality Attribute",
        referenceLink: "product/qtpp/fqas",
      },
    },
    runAtStart: true,
    needsDateInput: true,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  RequirementsSummaryReport: {
    isSummaryReport: true,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "Date",
    editableName: "Requirement",
    dependencies: [],
    dataTransform: cannedReportTransform([
      {type: RequirementSummaryDataTransform},
    ]),
    defaultFilter: ["fqas", "iqas", "fpas", "ipas"],
    typeAheadModels: ["IQA", "FQA", "IPA", "FPA", "GeneralAttribute"],
    renderProcessSelector: true,
    needsTypeaheadInput: true,
    editableModelNames: {
      Requirement: {
        templateName: "summary/requirements_summary.mrt",
        fullElementName: "Requirement",
        shortElementName: "Requirement",
        editableName: "Requirement",
        header: "Requirement",
        referenceLink: "",
      },
    },
    runAtStart: true,
    needsDateInput: true,
    useShorterDatePicker: true,
    includeRisksWithoutJustification: true,
    removePaddingAtBottom: true,
    hideTypeahead: true,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  SpecificationReport: {
    isSummaryReport: true,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "Date",
    editableName: "Product",
    dependencies: [],
    defaultSubReport: "Product",
    subReportsInputLabel: "Specification Reports",
    needsTypeaheadInput: false,
    dataTransform: cannedReportTransform([
      {type: SpecificationDataTransform},
    ]),
    renderProcessSelector: true,
    editableModelNames: {
      Product: {
        templateName: "specification/product_specification.mrt",
        fullElementName: "FQA",
        shortElementName: "FQA",
        editableName: "Product",
        header: "Product",
        referenceLink: "",
      },
      Process: {
        templateName: "specification/process_specification.mrt",
        fullElementName: "Process Component",
        shortElementName: "PRC",
        modelName: "ProcessComponent",
        editableName: "Process",
        header: "Process",
        referenceLink: "",
      },
      Intermediates: {
        templateName: "specification/intermediates_specification.mrt",
        fullElementName: "Unit Operation",
        shortElementName: "UO",
        editableName: "Intermediate",
        header: "Intermediates",
        referenceLink: "",
      },
      RawMaterial: {
        templateName: "specification/raw_materials_specification.mrt",
        fullElementName: "Material",
        shortElementName: "MT",
        editableName: "Raw Material",
        header: "Raw Materials",
        referenceLink: "",
      },
      Components: {
        templateName: "specification/components_specification.mrt",
        fullElementName: "Process Component",
        shortElementName: "PRC",
        modelName: "ProcessComponent",
        editableName: "Component",
        header: "Components",
        referenceLink: "",
      },
    },
    runAtStart: true,
    needsDateInput: true,
    useShorterDatePicker: true,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  TPPSummaryReport: {
    isSummaryReport: true,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "Date",
    editableName: "TPPSection",
    dependencies: [],
    dataTransform: cannedReportTransform([
      {type: TPPSummaryDataTransform},
    ]),
    editableModelNames: {
      TPPSection: {
        templateName: "summary/TPP_summary.mrt",
        fullElementName: "TARGET PRODUCT PROFILE",
        shortElementName: "TPP",
        editableName: "TPP",
        header: "Target Product Profile",
        referenceLink: "tpp",
      },
    },
    runAtStart: true,
    needsDateInput: true,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  RequirementElementReport: {
    isSummaryReport: false,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    referenceLink: "fqa",
    elementLabel: "Requirement",
    multiModelReport: true,
    typeAheadModels: ["FQA", "IQA", "FPA", "IPA", "MaterialAttribute", "ProcessParameter"],
    renderProcessSelector: true,
    dependencies: ["material", "processComponent", "unitOperation", "generalAttribute"],
    dataTransform: cannedReportTransform([
      {type: RequirementElementDataTransform},
    ]),
    editableModelNames: {
      FQA: {
        templateName: "requirementElement/FA_element.mrt",
        fullElementName: "FINAL QUALITY ATTRIBUTE",
        shortElementName: "FQA",
        editableName: "FQA",
        header: "Final Quality Attribute",
        referenceLink: "product/qtpp/fqas",
      },
      IQA: {
        templateName: "requirementElement/IA_element.mrt",
        fullElementName: "INTERMEDIATE QUALITY ATTRIBUTE",
        shortElementName: "IQA",
        editableName: "IQA",
        header: "Intermediate Quality Attribute",
        dependencyName: "Unit Operation",
        dependencyShortName: "UO",
        referenceLink: "processExplorer/iqas",
      },
      FPA: {
        templateName: "requirementElement/FA_element.mrt",
        fullElementName: "FINAL PERFORMANCE ATTRIBUTE",
        shortElementName: "FPA",
        editableName: "FPA",
        header: "Final Performance Attribute",
        referenceLink: "product/qtpp/fpas",
      },
      IPA: {
        templateName: "requirementElement/IA_element.mrt",
        fullElementName: "INTERMEDIATE PERFORMANCE ATTRIBUTE",
        shortElementName: "IPA",
        editableName: "IPA",
        header: "Intermediate Performance Attribute",
        dependencyName: "Unit Operation",
        dependencyShortName: "UO",
        referenceLink: "processExplorer/ipas",
      },
      MA: {
        templateName: "requirementElement/requirement_element.mrt",
        fullElementName: "MATERIAL ATTRIBUTE",
        shortElementName: "MA",
        editableName: "MaterialAttribute",
        header: "Material Attribute",
        dependencyName: "Material",
        dependencyShortName: ["MT", "PRC"],
        referenceLink: "processExplorer/materialAttributes",
      },
      PP: {
        templateName: "requirementElement/requirement_element.mrt",
        fullElementName: "PROCESS PARAMETER",
        shortElementName: "PP",
        editableName: "ProcessParameter",
        header: "Process Parameter",
        dependencyName: "Process Component",
        dependencyShortName: ["MT", "PRC"],
        referenceLink: "processExplorer/processParameters",
      },
    },
    needsTypeaheadInput: true,
    maxResults: 100,
    needsDateInput: false,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  CQAControlStrategyReport: {
    isSummaryReport: false,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    referenceLink: "fqa",
    elementLabel: "Critical Quality Attributes",
    multiModelReport: true,
    typeAheadModels: ["FQA"],
    renderProcessSelector: true,
    dependencies: ["generalAttribute"],
    dataTransform: cannedReportTransform([
      {type: CQAControlStrategyTransform},
    ]),
    editableModelNames: {
      FQA: {
        templateName: "requirementElement/CQA_element.mrt",
        fullElementName: "Control Strategy Report",
        shortElementName: "FQA",
        editableName: "FQA",
        header: "Critical Quality Attribute",
        referenceLink: "product/qtpp/fqas",
      },
    },
    needsTypeaheadInput: true,
    maxResults: 50,
    needsDateInput: false,
    typeaheadLoadConfig: {
      includeLinks: true,
      includeChildren: true,
      includeDates: true,
    },
    filterTypeaheadOptionsFunction: (project, rmp, typeaheadOptions) => {
      return CQAReportUtils.filterTypeAheadOptions(project, rmp, typeaheadOptions);
    },
    shouldLoadRMP: true,
  },
  UnitOperationControlStrategyReport: {
    isSummaryReport: true,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "Unit Operation",
    multiModelReport: true,
    typeAheadModels: ["unitOperation"],
    renderProcessSelector: true,
    dependencies: [],
    dataTransform: cannedReportTransform([
      {type: UOSTPControlStrategyTransform},
    ]),
    editableModelNames: {
      UO: {
        templateName: "requirementElement/uo_stp_element.mrt",
        fullElementName: "Control Strategy Report",
        shortElementName: "UO",
        editableName: "UnitOperation",
        header: "Unit Operation Control Strategy Report",
        referenceLink: "processExplorer/unitOperations",
      },
    },
    needsTypeaheadInput: true,
    maxResults: 50,
    needsDateInput: false,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  IndividualMaterialReport: {
    isSummaryReport: false,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    referenceLink: "mt",
    elementLabel: "Material",
    multiModelReport: true,
    typeAheadModels: ["Material"],
    renderProcessSelector: true,
    dependencies: ["unitOperation", "supplier"],
    dataTransform: cannedReportTransform([
      {type: IndividualMaterialDataTransform},
    ]),
    editableModelNames: {
      MT: {
        templateName: "requirementElement/MT_element.mrt",
        fullElementName: "MATERIAL",
        shortElementName: "MT",
        editableName: "Material",
        header: "Material",
        dependencyName: "Unit Operation",
        dependencyShortName: "UO",
        referenceLink: "processExplorer/materials",
      },
    },
    needsTypeaheadInput: true,
    maxResults: 100,
    needsDateInput: false,
    includeAllApprovedVersions: true,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  IndividualProcessComponentReport: {
    isSummaryReport: false,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    referenceLink: "prc",
    elementLabel: "Process Component",
    multiModelReport: true,
    typeAheadModels: ["ProcessComponent"],
    renderProcessSelector: true,
    dependencies: ["unitOperation", "supplier"],
    dataTransform: cannedReportTransform([
      {type: RequirementElementDataTransform},
    ]),
    editableModelNames: {
      PRC: {
        templateName: "requirementElement/PRC_element.mrt",
        fullElementName: "PROCESS COMPONENT",
        shortElementName: "PRC",
        editableName: "ProcessComponent",
        header: "Process Component",
        dependencyName: "Unit Operation",
        dependencyShortName: "UO",
        referenceLink: "processExplorer/processComponents",
      },
    },
    needsTypeaheadInput: true,
    maxResults: 100,
    needsDateInput: false,
    includeAllApprovedVersions: true,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  UnitOperationsSummary: {
    isSummaryReport: true,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "Date",
    editableName: "UnitOperations",
    renderProcessSelector: true,
    dependencies: [],
    needsTypeaheadInput: false,
    dataTransform: cannedReportTransform([
      {type: UnitOperationSummaryDataTransform},
    ]),
    editableModelNames: {
      UnitOperations: {
        templateName: "techTransfer/list_of_unit_operations.mrt",
        fullElementName: "Unit Operation",
        shortElementName: "UO",
        editableName: "UnitOperation",
        header: "Unit Operations",
        referenceLink: "",
      },
    },
    runAtStart: true,
    needsDateInput: true,
    useShorterDatePicker: false,
    useLargerExportButtonArea: false,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  BillOfRawMaterials: {
    isSummaryReport: true,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "Date",
    editableName: "Materials",
    renderProcessSelector: true,
    dependencies: [],
    needsTypeaheadInput: false,
    includeRisksWithoutJustification: true,
    dataTransform: cannedReportTransform([
      {type: BillOfMaterialsDataTransform},
    ]),
    editableModelNames: {
      Materials: {
        templateName: "techTransfer/bill_of_raw_materials.mrt",
        fullElementName: "Material",
        shortElementName: "MT",
        editableName: "Material",
        header: "Raw Materials",
        referenceLink: "",
      },
    },
    runAtStart: true,
    needsDateInput: true,
    useShorterDatePicker: false,
    useLargerExportButtonArea: false,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  BillOfProcessComponents: {
    isSummaryReport: true,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "Date",
    editableName: "ProcessComponents",
    renderProcessSelector: true,
    dependencies: [],
    needsTypeaheadInput: false,
    dataTransform: cannedReportTransform([
      {type: BillOfProcessComponentsDataTransform},
    ]),
    editableModelNames: {
      ProcessComponents: {
        templateName: "techTransfer/bill_of_process_components.mrt",
        fullElementName: "ProcessComponent",
        shortElementName: "PRC",
        editableName: "ProcessComponent",
        header: "Process Components",
        referenceLink: "",
      },
    },
    runAtStart: true,
    needsDateInput: true,
    useShorterDatePicker: false,
    useLargerExportButtonArea: false,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  RequirementHistoricalSummaryReport: {
    isSummaryReport: false,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    referenceLink: "fqa",
    elementLabel: "Requirement",
    multiModelReport: true,
    typeAheadModels: ["FQA", "IQA", "FPA", "IPA", "MaterialAttribute", "ProcessParameter"],
    renderProcessSelector: true,
    dependencies: ["material", "processComponent", "unitOperation", "generalAttribute"],
    dataTransform: cannedReportTransform([
      {type: RequirementElementDataTransform},
    ]),
    editableModelNames: {
      FQA: {
        templateName: "requirementHistorical/FA_historical.mrt",
        fullElementName: "FINAL QUALITY ATTRIBUTE",
        shortElementName: "FQA",
        editableName: "FQA",
        header: "Final Quality Attribute",
        referenceLink: "product/qtpp/fqas",
      },
      IQA: {
        templateName: "requirementHistorical/IA_historical.mrt",
        fullElementName: "INTERMEDIATE QUALITY ATTRIBUTE",
        shortElementName: "IQA",
        editableName: "IQA",
        header: "Intermediate Quality Attribute",
        dependencyName: "Unit Operation",
        dependencyShortName: "UO",
        referenceLink: "processExplorer/iqas",
      },
      FPA: {
        templateName: "requirementHistorical/FA_historical.mrt",
        fullElementName: "FINAL PERFORMANCE ATTRIBUTE",
        shortElementName: "FPA",
        editableName: "FPA",
        header: "Final Performance Attribute",
        referenceLink: "product/qtpp/fpas",
      },
      IPA: {
        templateName: "requirementHistorical/IA_historical.mrt",
        fullElementName: "INTERMEDIATE PERFORMANCE ATTRIBUTE",
        shortElementName: "IPA",
        editableName: "IPA",
        header: "Intermediate Performance Attribute",
        dependencyName: "Unit Operation",
        dependencyShortName: "UO",
        referenceLink: "processExplorer/ipas",
      },
      MA: {
        templateName: "requirementHistorical/requirement_historical.mrt",
        fullElementName: "MATERIAL ATTRIBUTE ",
        shortElementName: "MA",
        editableName: "MaterialAttribute",
        header: "Material Attribute",
        dependencyName: "Material",
        dependencyShortName: ["MT", "PRC"],
        referenceLink: "processExplorer/materialAttributes",
      },
      PP: {
        templateName: "requirementHistorical/requirement_historical.mrt",
        fullElementName: "PROCESS PARAMETER",
        shortElementName: "PP",
        editableName: "ProcessParameter",
        header: "Process Parameter",
        dependencyName: "Process Component",
        dependencyShortName: ["MT", "PRC"],
        referenceLink: "processExplorer/processParameters",
      },
    },
    needsTypeaheadInput: true,
    maxResults: 100,
    needsDateInput: false,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  ApprovedSuppliersList: {
    isSummaryReport: true,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "Date",
    editableName: "Suppliers",
    dependencies: [],
    needsTypeaheadInput: false,
    dataTransform: cannedReportTransform([
      {type: SupplierDataTransform},
    ]),
    editableModelNames: {
      Suppliers: {
        templateName: "supplier/approved_suppliers_list.mrt",
        fullElementName: "Suppliers",
        shortElementName: "SUP",
        editableName: "Supplier",
        header: "Suppliers",
        referenceLink: "",
      },
    },
    runAtStart: true,
    needsDateInput: true,
    useShorterDatePicker: false,
    useLargerExportButtonArea: false,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  RiskManagementPlan: {
    isSummaryReport: false,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: false,
    elementLabel: "RMPs",
    editableName: "RMP",
    needsTypeaheadInput: true,
    typeAheadModels: ["RMP"],
    multiModelReport: true,
    enableZoom: false,
    dataTransform: cannedReportTransform([
      {type: RMPDataTransform},
    ]),
    editableModelNames: {
      RMP: {
        templateName: "summary/risk_management_plan.mrt",
        fullElementName: "Risk Management Plans",
        shortElementName: "RMP",
        editableName: "RMP",
        header: "Risk Management Plans",
        referenceLink: "",
      },
    },
    runAtStart: false,
    needsDateInput: true,
    useShorterDatePicker: false,
    useLargerExportButtonArea: false,
    hideDatePicker: true,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  FMEAUnitOperation: {
    isSummaryReport: true,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "Unit Operation",
    editableName: "unitOperation",
    needsTypeaheadInput: true,
    typeAheadModels: ["unitOperation"],
    renderProcessSelector: true,
    dependencies: ["FQA", "IQA", "FPA", "IPA"],
    multiModelReport: true,
    fullScreen: true,
    dataTransform: cannedReportTransform([
      {type: FMEAUnitOperationDataTransform},
    ]),
    editableModelNames: {
      unitOperation: {
        templateName: "summary/FMEA_unit_operation.mrt",
        fullElementName: "Unit Operation",
        shortElementName: "UO",
        editableName: "unitOperation",
        header: "Failure Mode and Effects Analysis Report",
        referenceLink: "",
      },
    },
    runAtStart: false,
    needsDateInput: false,
    useShorterTypeahead: true,
    useLargerExportButtonArea: false,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  RiskManagementPlanReport: {
    isSummaryReport: false,
    exportAs: exportAsNoExcel,
    printable: true,
    exportable: true,
    renderOnEmptyData: false,
    elementLabel: "RMPs",
    editableName: "RMP",
    needsTypeaheadInput: true,
    typeAheadModels: ["RMP"],
    multiModelReport: true,
    dataTransform: cannedReportTransform([
      {type: RMPDataTransform},
    ]),
    editableModelNames: {
      RMP: {
        templateName: "summary/risk_management_plan.mrt",
        fullElementName: "Risk Management Plans",
        shortElementName: "RMP",
        editableName: "RMP",
        header: "Risk Management Plans",
        referenceLink: "",
      },
    },
    runAtStart: false,
    needsDateInput: true,
    useShorterDatePicker: false,
    useLargerExportButtonArea: false,
    hideDatePicker: true,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  IndividualTrainingReport: {
    isSummaryReport: true,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "Users",
    editableName: "User",
    dependencies: [],
    dataTransform: cannedReportTransform([
      {type: IndividualTrainingReportTransform},
    ]),
    typeAheadModels: ["User"],
    needsTypeaheadInput: false,
    editableModelNames: {
      User: {
        templateName: "training/individual_training_report.mrt",
        fullElementName: "User",
        shortElementName: "User",
        editableName: "User",
        header: "User",
        referenceLink: "",
      },
    },
    skipInitialRendering: true,
    runAtStart: true,
    needsDateInput: true,
    useVeryShortDatePicker: true,
    useLargerExportButtonArea: false,
    hideTypeahead: true,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  InfluenceMapReport: {
    isSummaryReport: false,
    exportAs: exportAsNoWord,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    renderProcessSelector: true,
    elementLabel: "Influence Map",
    editableName: "InfluenceMap",
    dependencies: [],
    fullScreen: false,
    dataTransform: cannedReportTransform([
      {type: InfluenceMapDataTransform},
    ]),
    needsTypeaheadInput: false,
    editableModelNames: {
      InfluenceMap: {
        templateName: "influenceMap/influence_map_report.mrt",
        fullElementName: "Influence Map Report",
        shortElementName: "Influence Map",
        editableName: "InfluenceMap",
        header: "Influence Map Report",
        referenceLink: "",
      },
    },
    skipInitialRendering: false,
    runAtStart: true,
    needsDateInput: false,
    useShorterDatePicker: false,
    useLargerExportButtonArea: false,
    hideTypeahead: true,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  DocumentTrainingReport: {
    isSummaryReport: true,
    exportAs: exportAs,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "Documents",
    editableName: "Document",
    dependencies: [],
    dataTransform: cannedReportTransform([
      {type: DocumentTrainingReportTransform},
    ]),
    typeAheadModels: ["Document"],
    needsTypeaheadInput: false,
    editableModelNames: {
      Document: {
        templateName: "training/document_training_report.mrt",
        fullElementName: "Document",
        shortElementName: "Document",
        editableName: "Document",
        header: "Document",
        referenceLink: "",
      },
    },
    skipInitialRendering: true,
    runAtStart: true,
    needsDateInput: true,
    useVeryShortDatePicker: true,
    useLargerExportButtonArea: false,
    hideTypeahead: true,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  TrainingMatrixReport: {
    isSummaryReport: true,
    exportAs: exportAsNoWord,
    printable: true,
    exportable: true,
    renderOnEmptyData: true,
    elementLabel: "Documents",
    editableName: "Document",
    dependencies: [],
    fullScreen: true,
    dataTransform: cannedReportTransform([
      {type: TrainingMatrixReportTransform},
    ]),
    typeAheadModels: ["Document"],
    needsTypeaheadInput: false,
    editableModelNames: {
      Document: {
        templateName: "training/training_matrix_report.mrt",
        fullElementName: "Document",
        shortElementName: "Document",
        editableName: "Document",
        header: "Document",
        referenceLink: "",
      },
    },
    skipInitialRendering: true,
    runAtStart: true,
    needsDateInput: true,
    useShorterDatePicker: true,
    useLargerExportButtonArea: false,
    hideTypeahead: true,
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  LibraryProcessCapabilityDashboard: {
    printable: false,
    exportable: false,
    exportAs: exportProcessCapabilityReportsAs,
    elementLabel: "Requirement",
    multiModelReport: true,
    typeAheadModels: ["Specification"],
    renderProcessSelector: false,
    dependencies: ["libraryMaterial"],
    needsTypeaheadInput: true,
    templateName: "processCapability/PCM_dashboard.mrt",
    indices: [
      {
        name: "cpChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "cpChart",
        indexName: "cp",
        displayName: "Cp",
        backgroundColor: indexChartBackgroundColor,
      },
      {
        name: "cplChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "cplChart",
        indexName: "cpl",
        displayName: "Cpl",
        backgroundColor: indexChartBackgroundColor,
      },
      {
        name: "cpuChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "cpuChart",
        indexName: "cpu",
        displayName: "Cpu",
        backgroundColor: indexChartBackgroundColor,
      },
      {
        name: "cpkChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "cpkChart",
        indexName: "cpk",
        displayName: "Cpk",
        backgroundColor: indexChartBackgroundColor,
      },
      {
        name: "cpmChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "cpmChart",
        indexName: "cpm",
        displayName: "Cpm",
        backgroundColor: indexChartBackgroundColor,
      },
      {
        name: "ppChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "ppChart",
        indexName: "pp",
        displayName: "Pp",
        backgroundColor: indexChartBackgroundColor,
      },
      {
        name: "pplChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "pplChart",
        indexName: "ppl",
        displayName: "Ppl",
        backgroundColor: indexChartBackgroundColor,
      },
      {
        name: "ppuChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "ppuChart",
        indexName: "ppu",
        displayName: "Ppu",
        backgroundColor: indexChartBackgroundColor,
      },
      {
        name: "ppkChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "ppkChart",
        indexName: "ppk",
        displayName: "Ppk",
        backgroundColor: indexChartBackgroundColor,
      },
    ],
    infoBoxes: [
      {
        title: "LSL",
        name: "lsl",
        backgroundColor: infoBoxBackgroundColor,
      },
      {
        title: "Target",
        name: "target",
        backgroundColor: infoBoxBackgroundColor,
      },
      {
        title: "USL",
        name: "usl",
        backgroundColor: infoBoxBackgroundColor,
      },
    ],
    charts: [
      {
        enableExport: false,
        showTitle: false,
        height: 250,
        cleanChartUI: false,
        renderIn: "lastSubgroupsChart",
        title: "Last Subgroups Chart",
        loader: ChartConfigs.generateScatteredChart,
        showBorders: true,
        backgroundColor: "#f3f7f8",
        fixToMostDecimalPlaces: false,
        showLSL: true,
        showUSL: true,
      },
      {
        enableExport: false,
        showTitle: false,
        height: 250,
        cleanChartUI: false,
        renderIn: "xbarChart",
        title: "X-bar Chart",
        yAxisLabel: "Average",
        loader: ChartConfigs.generateControlChart,
        showBorders: true,
        backgroundColor: "#f5f5f5",
        isLastChart: true,
      },
      {
        enableExport: false,
        showTitle: false,
        height: 250,
        cleanChartUI: false,
        renderIn: "xChart",
        title: "X Chart",
        yAxisLabel: "Individual Value",
        loader: ChartConfigs.generateControlChart,
        showBorders: true,
        backgroundColor: "#f3f7f8",
      },
      {
        enableExport: false,
        showTitle: false,
        height: 250,
        cleanChartUI: false,
        renderIn: "mrChart",
        title: "MR Chart",
        yAxisLabel: "Moving Range",
        loader: ChartConfigs.generateControlChart,
        showBorders: true,
        backgroundColor: "#f5f5f5",
        isLastChart: true,
      },
      {
        enableExport: false,
        showTitle: false,
        height: 250,
        cleanChartUI: false,
        renderIn: "pChart",
        title: "P Chart",
        yAxisLabel: "Proportion",
        loader: ChartConfigs.generateControlChart,
        showBorders: true,
        backgroundColor: "#f3f7f8",
      },
      {
        enableExport: false,
        showTitle: false,
        height: 250,
        cleanChartUI: false,
        renderIn: "cumulativeDefectiveChart",
        title: "Cumulative %Defective Chart",
        yAxisLabel: "%Defective",
        loader: ChartConfigs.generateControlChart,
        isCenterLineDashed: true,
        showBorders: true,
        backgroundColor: "#f3f7f8",
      },
    ],
    editableModelNames: {
      MTLS: {
        shortElementName: "MTLS",
      },
    },
    maxResults: 100,
    needsDateInput: false,
    baseURL: "/reports/processCapabilityDashboard.html",
    urlParams: ["reportType", "modelLabel"],
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  ProcessCapabilityDashboard: {
    printable: false,
    exportable: false,
    exportAs: exportProcessCapabilityReportsAs,
    elementLabel: "Requirement",
    multiModelReport: true,
    typeAheadModels: ["FQA", "FPA", "IPA", "IQA", "MaterialAttribute", "ProcessParameter"],
    renderProcessSelector: true,
    dependencies: ["material", "processComponent", "unitOperation"],
    needsTypeaheadInput: true,
    templateName: "processCapability/PCM_dashboard.mrt",
    indices: [
      {
        name: "cpChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "cpChart",
        indexName: "cp",
        displayName: "Cp",
        backgroundColor: indexChartBackgroundColor,
      },
      {
        name: "cplChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "cplChart",
        indexName: "cpl",
        displayName: "Cpl",
        backgroundColor: indexChartBackgroundColor,
      },
      {
        name: "cpuChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "cpuChart",
        indexName: "cpu",
        displayName: "Cpu",
        backgroundColor: indexChartBackgroundColor,
      },
      {
        name: "cpkChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "cpkChart",
        indexName: "cpk",
        displayName: "Cpk",
        backgroundColor: indexChartBackgroundColor,
      },
      {
        name: "cpmChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "cpmChart",
        indexName: "cpm",
        displayName: "Cpm",
        backgroundColor: indexChartBackgroundColor,
      },
      {
        name: "ppChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "ppChart",
        indexName: "pp",
        displayName: "Pp",
        backgroundColor: indexChartBackgroundColor,
      },
      {
        name: "pplChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "pplChart",
        indexName: "ppl",
        displayName: "Ppl",
        backgroundColor: indexChartBackgroundColor,
      },
      {
        name: "ppuChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "ppuChart",
        indexName: "ppu",
        displayName: "Ppu",
        backgroundColor: indexChartBackgroundColor,
      },
      {
        name: "ppkChart",
        loader: ChartConfigs.generateCapabilityIndexChart,
        renderIn: "ppkChart",
        indexName: "ppk",
        displayName: "Ppk",
        backgroundColor: indexChartBackgroundColor,
      },
    ],
    infoBoxes: [
      {
        title: "LSL",
        name: "lsl",
        backgroundColor: infoBoxBackgroundColor,
      },
      {
        title: "Target",
        name: "target",
        backgroundColor: infoBoxBackgroundColor,
      },
      {
        title: "USL",
        name: "usl",
        backgroundColor: infoBoxBackgroundColor,
      },
    ],
    charts: [
      {
        enableExport: false,
        showTitle: false,
        height: 250,
        cleanChartUI: false,
        renderIn: "lastSubgroupsChart",
        title: "Last Subgroups Chart",
        loader: ChartConfigs.generateScatteredChart,
        showBorders: true,
        backgroundColor: "#f3f7f8",
        fixToMostDecimalPlaces: false,
        showLSL: true,
        showUSL: true,
      },
      {
        enableExport: false,
        showTitle: false,
        height: 250,
        cleanChartUI: false,
        renderIn: "xbarChart",
        title: "X-bar Chart",
        yAxisLabel: "Average",
        loader: ChartConfigs.generateControlChart,
        showBorders: true,
        backgroundColor: "#f5f5f5",
        isLastChart: true,
      },
      {
        enableExport: false,
        showTitle: false,
        height: 250,
        cleanChartUI: false,
        renderIn: "xChart",
        title: "X Chart",
        yAxisLabel: "Individual Value",
        loader: ChartConfigs.generateControlChart,
        showBorders: true,
        backgroundColor: "#f3f7f8",
      },
      {
        enableExport: false,
        showTitle: false,
        height: 250,
        cleanChartUI: false,
        renderIn: "mrChart",
        title: "MR Chart",
        yAxisLabel: "Moving Range",
        loader: ChartConfigs.generateControlChart,
        showBorders: true,
        backgroundColor: "#f5f5f5",
        isLastChart: true,
      },
      {
        enableExport: false,
        showTitle: false,
        height: 250,
        cleanChartUI: false,
        renderIn: "pChart",
        title: "P Chart",
        yAxisLabel: "Proportion",
        loader: ChartConfigs.generateControlChart,
        showBorders: true,
        backgroundColor: "#f3f7f8",
      },
      {
        enableExport: false,
        showTitle: false,
        height: 250,
        cleanChartUI: false,
        renderIn: "cumulativeDefectiveChart",
        title: "Cumulative %Defective Chart",
        yAxisLabel: "%Defective",
        loader: ChartConfigs.generateControlChart,
        isCenterLineDashed: true,
        showBorders: true,
        backgroundColor: "#f3f7f8",
      },
    ],
    editableModelNames: {
      FQA: {
        shortElementName: "FQA",
      },
      FPA: {
        shortElementName: "FPA",
      },
      IPA: {
        shortElementName: "IPA",
      },
      IQA: {
        shortElementName: "IQA",
      },
      MA: {
        shortElementName: "MA",
      },
      PP: {
        shortElementName: "PP",
      },
    },
    maxResults: 100,
    needsDateInput: false,
    baseURL: "/reports/processCapabilityDashboard.html",
    urlParams: ["reportType", "projectId", "processId", "modelLabel"],
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  LibraryControlChartsContinuousReport: {
    exportAs: exportProcessCapabilityReportsAs,
    printable: false,
    exportable: false,
    elementLabel: "Requirement",
    multiModelReport: true,
    typeAheadModels: ["Specification"],
    renderProcessSelector: false,
    dependencies: ["libraryMaterial"],
    needsTypeaheadInput: true,
    showBorder: true,
    charts: [
      {
        name: "XChart",
        loader: ChartConfigs.generateControlChart,
        renderIn: "xChart",
        title: "X Chart",
        yAxisLabel: "Individual Value",
      },
      {
        name: "MRChart",
        loader: ChartConfigs.generateControlChart,
        renderIn: "mrChart",
        title: "MR Chart",
        yAxisLabel: "Moving Range",
      },
      {
        name: "XbarChart",
        loader: ChartConfigs.generateControlChart,
        renderIn: "xbarChart",
        title: "Xbar Chart",
        yAxisLabel: "Average",
      },
      {
        name: "RChart",
        loader: ChartConfigs.generateControlChart,
        renderIn: "rChart",
        title: "R Chart",
        yAxisLabel: "Range",
      },
      {
        name: "SChart",
        loader: ChartConfigs.generateControlChart,
        renderIn: "sChart",
        title: "S Chart",
        yAxisLabel: "Standard Deviation",
      },
      {
        name: "LastSubgroupsChart",
        loader: ChartConfigs.generateScatteredChart,
        renderIn: "lastSubgroupsChart",
        title: "Last {COUNT} Subgroups",
        fixToMostDecimalPlaces: false,
        showLSL: true,
        showUSL: true,
      },
      {
        name: "CapabilityHistogramChart",
        loader: ChartConfigs.generateHistogramChart,
        renderIn: "capabilityHistogramChart",
        title: "Capability Histogram",
      },
      {
        name: "NormalProbabilityPlotChart",
        loader: ChartConfigs.generateNormalPropPlotChart,
        renderIn: "normalProbabilityPlotChart",
        title: "Normal Probability Plot",
      },
      {
        name: "CapabilityPlotChart",
        loader: ChartConfigs.generateCapabilityPlotChart,
        renderIn: "capabilityPlotChart",
        title: "Capability Plot",
        renderOverallIndices: ChartReportHelper.renderOverallIndices,
        renderWithinIndices: ChartReportHelper.renderWithinIndices,
      },
    ],
    editableModelNames: {
      MTLS: {
        shortElementName: "MTLS",
      },
    },
    maxResults: 100,
    needsDateInput: false,
    baseURL: "/reports/chartReports.html",
    urlParams: ["reportType", "modelLabel"],
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  ControlChartsContinuousReport: {
    exportAs: exportProcessCapabilityReportsAs,
    printable: false,
    exportable: false,
    elementLabel: "Requirement",
    multiModelReport: true,
    typeAheadModels: ["FQA", "FPA", "IQA", "IPA", "MaterialAttribute", "ProcessParameter"],
    renderProcessSelector: true,
    dependencies: ["material", "processComponent", "unitOperation"],
    needsTypeaheadInput: true,
    showBorder: true,
    charts: [
      {
        name: "XChart",
        loader: ChartConfigs.generateControlChart,
        renderIn: "xChart",
        title: "X Chart",
        yAxisLabel: "Individual Value",
      },
      {
        name: "MRChart",
        loader: ChartConfigs.generateControlChart,
        renderIn: "mrChart",
        title: "MR Chart",
        yAxisLabel: "Moving Range",
      },
      {
        name: "XbarChart",
        loader: ChartConfigs.generateControlChart,
        renderIn: "xbarChart",
        title: "Xbar Chart",
        yAxisLabel: "Average",
      },
      {
        name: "RChart",
        loader: ChartConfigs.generateControlChart,
        renderIn: "rChart",
        title: "R Chart",
        yAxisLabel: "Range",
      },
      {
        name: "SChart",
        loader: ChartConfigs.generateControlChart,
        renderIn: "sChart",
        title: "S Chart",
        yAxisLabel: "Standard Deviation",
      },
      {
        name: "LastSubgroupsChart",
        loader: ChartConfigs.generateScatteredChart,
        renderIn: "lastSubgroupsChart",
        title: "Last {COUNT} Subgroups",
        fixToMostDecimalPlaces: false,
        showLSL: true,
        showUSL: true,
      },
      {
        name: "CapabilityHistogramChart",
        loader: ChartConfigs.generateHistogramChart,
        renderIn: "capabilityHistogramChart",
        title: "Capability Histogram",
      },
      {
        name: "NormalProbabilityPlotChart",
        loader: ChartConfigs.generateNormalPropPlotChart,
        renderIn: "normalProbabilityPlotChart",
        title: "Normal Probability Plot",
      },
      {
        name: "CapabilityPlotChart",
        loader: ChartConfigs.generateCapabilityPlotChart,
        renderIn: "capabilityPlotChart",
        title: "Capability Plot",
        renderOverallIndices: ChartReportHelper.renderOverallIndices,
        renderWithinIndices: ChartReportHelper.renderWithinIndices,
      },
    ],
    editableModelNames: {
      FQA: {
        shortElementName: "FQA",
      },
      FPA: {
        shortElementName: "FPA",
      },
      IPA: {
        shortElementName: "IPA",
      },
      IQA: {
        shortElementName: "IQA",
      },
      MA: {
        shortElementName: "MA",
      },
      PP: {
        shortElementName: "PP",
      },
    },
    maxResults: 100,
    needsDateInput: false,
    baseURL: "/reports/chartReports.html",
    urlParams: ["reportType", "projectId", "modelLabel"],
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  LibraryControlChartsDefectivesReport: {
    exportAs: exportProcessCapabilityReportsAs,
    printable: false,
    exportable: false,
    elementLabel: "Requirement",
    multiModelReport: true,
    typeAheadModels: ["Specification"],
    renderProcessSelector: false,
    dependencies: ["libraryMaterial"],
    needsTypeaheadInput: true,
    showBorder: true,
    charts: [
      {
        name: "PChart",
        loader: ChartConfigs.generateControlChart,
        renderIn: "pChart",
        title: "P Chart",
        yAxisLabel: "Proportion",
      },
      {
        name: "RateOfDefectivesChart",
        loader: ChartConfigs.generateScatteredChart,
        renderIn: "rateOfDefectivesChart",
        title: "Rate of Defectives",
        yAxisLabel: "%Defective",
        xAxisLabel: "Sample Size",
        showLSL: false,
        showUSL: false,
        fixToMostDecimalPlaces: false,
      },
      {
        name: "CumulativeDefectiveChart",
        loader: ChartConfigs.generateControlChart,
        renderIn: "cumulativeDefectiveChart",
        title: "Cumulative %Defective Chart",
        yAxisLabel: "%Defective",
        isCenterLineDashed: true,
      },
      {
        name: "DistributionOfDefectiveChart",
        loader: ChartConfigs.generateHistogramChart,
        renderIn: "distributionOfDefectiveChart",
        title: "Distribution of %Defective",
        yAxisLabel: "Frequency",
        xAxisLabel: "%Defective",
      },
    ],
    editableModelNames: {
      MTLS: {
        shortElementName: "MTLS",
      },
    },
    maxResults: 100,
    needsDateInput: false,
    baseURL: "/reports/chartReports.html",
    urlParams: ["reportType", "modelLabel"],
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  ControlChartsDefectivesReport: {
    exportAs: exportProcessCapabilityReportsAs,
    printable: false,
    exportable: false,
    elementLabel: "Requirement",
    multiModelReport: true,
    typeAheadModels: ["FQA", "FPA", "IQA", "IPA", "MaterialAttribute", "ProcessParameter"],
    renderProcessSelector: true,
    dependencies: ["material", "processComponent", "unitOperation"],
    needsTypeaheadInput: true,
    showBorder: true,
    charts: [
      {
        name: "PChart",
        loader: ChartConfigs.generateControlChart,
        renderIn: "pChart",
        title: "P Chart",
        yAxisLabel: "Proportion",
      },
      {
        name: "RateOfDefectivesChart",
        loader: ChartConfigs.generateScatteredChart,
        renderIn: "rateOfDefectivesChart",
        title: "Rate of Defectives",
        yAxisLabel: "%Defective",
        xAxisLabel: "Sample Size",
        showLSL: false,
        showUSL: false,
        fixToMostDecimalPlaces: false,
      },
      {
        name: "CumulativeDefectiveChart",
        loader: ChartConfigs.generateControlChart,
        renderIn: "cumulativeDefectiveChart",
        title: "Cumulative %Defective Chart",
        yAxisLabel: "%Defective",
        isCenterLineDashed: true,
      },
      {
        name: "DistributionOfDefectiveChart",
        loader: ChartConfigs.generateHistogramChart,
        renderIn: "distributionOfDefectiveChart",
        title: "Distribution of %Defective",
        yAxisLabel: "Frequency",
        xAxisLabel: "%Defective",
      },
    ],
    editableModelNames: {
      FQA: {
        shortElementName: "FQA",
      },
      FPA: {
        shortElementName: "FPA",
      },
      IPA: {
        shortElementName: "IPA",
      },
      IQA: {
        shortElementName: "IQA",
      },
      MA: {
        shortElementName: "MA",
      },
      PP: {
        shortElementName: "PP",
      },
    },
    maxResults: 100,
    needsDateInput: false,
    baseURL: "/reports/chartReports.html",
    urlParams: ["reportType", "projectId", "modelLabel"],
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
  ProcessFlowMapReport: {
    PoolGroupCategory: "Pool",
    SwimlanesGroupCategory: "SwimlaneTemplate",
    SwimlaneHeaderCategory: "SwimlaneHeaderTemplate",
    TileCategory: "TileTemplate",
    typeaheadLoadConfig: {},
    shouldLoadRMP: false,
  },
};

export const RISK_MAP_COLORS = {
  tpp: {
    background: "#808080",
    border: "#333333",
    highlight: {
      background: "#515050",
      border: "#000000",
    },
    hover: {
      background: "#999999",
      border: "#666666",
    },
  },
  [RISK_COLORS.NONE]: {
    background: "#b0aeae",
    border: "#737272",
    highlight: {
      background: "#6d6c6c",
      border: "#262525",
    },
    hover: {
      background: "#d4d2d2",
      border: "#b7b6b6",
    },
  },
  [RISK_COLORS.GREEN]: {
    background: "#26ad92",
    border: "#006551",
    highlight: {
      background: "#147f6a",
      border: "#000000",
    },
    hover: {
      background: "#45c4aa",
      border: "#006551",
    },
  },
  [RISK_COLORS.BLUE]: {
    background: "#3366ff",
    border: "#002db3",
    highlight: {
      background: "#002699",
      border: "#002db3",
    },
    hover: {
      background: "#7093ff",
      border: "#006551",
    },
  },
  [RISK_COLORS.YELLOW]: {
    background: "#f8ea0e",
    border: "#adb311",
    highlight: {
      background: "#ded543",
      border: "#999117",
    },
    hover: {
      background: "#f0f80e",
      border: "#adb311",
    },
  },
  [RISK_COLORS.ORANGE]: {
    background: "#ff9900",
    border: "#993300",
    highlight: {
      background: "#ff6600",
      border: "#000000",
    },
    hover: {
      background: "#fbaf3c",
      border: "#993300",
    },
  },
  [RISK_COLORS.RED]: {
    background: "#cc3300",
    border: "#330000",
    highlight: {
      background: "#9e2a03",
      border: "#000000",
    },
    hover: {
      background: "#ff3300",
      border: "#330000",
    },
  },
  [RISK_COLORS.GREY]: {
    background: "#808080",
    border: "#333333",
    highlight: {
      background: "#515050",
      border: "#000000",
    },
    hover: {
      background: "#999999",
      border: "#666666",
    },
  },
};

