var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames } from '@progress/kendo-react-common';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { labelsOptional, messages } from './messages';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from './package-metadata';
/**
 * Represents the KendoReact FloatingLabel component.
 *
 * @example
 * ```jsx
 * const sizes = ["X-Small", "Small", "Medium", "Large", "X-Large", "2X-Large"];
 * const App = () => {
 *     const [ddlState, setDdlState] = React.useState();
 *     const editorId = 'ddl-sizes';
 *     return (
 *         <FloatingLabel label={'Shirt Size:'} editorId={editorId} editorValue={ddlState}>
 *             <DropDownList
 *                 id={editorId}
 *                 value={ddlState}
 *                 data={sizes}
 *                 onChange={(e) => setDdlState(e.target.value)}
 *             />
 *         </FloatingLabel>
 *     );
 * };
 *
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var FloatingLabel = /** @class */ (function (_super) {
    __extends(FloatingLabel, _super);
    function FloatingLabel(props) {
        var _this = _super.call(this, props) || this;
        /**
         * @hidden
         */
        _this.state = {
            focused: false
        };
        /**
         * @hidden
         */
        _this.handleFocus = function (_) {
            _this.setState({ focused: true });
        };
        /**
         * @hidden
         */
        _this.handleBlur = function (_) {
            _this.setState({ focused: false });
        };
        validatePackage(packageMetadata);
        return _this;
    }
    /**
     * @hidden
     */
    FloatingLabel.prototype.render = function () {
        var _a = this.props, label = _a.label, editorId = _a.editorId, className = _a.className, editorValue = _a.editorValue, editorPlaceholder = _a.editorPlaceholder, editorValid = _a.editorValid, editorDisabled = _a.editorDisabled, style = _a.style, id = _a.id, optional = _a.optional;
        var localizationService = provideLocalizationService(this);
        var localizedOptional = optional ? localizationService
            .toLanguageString(labelsOptional, messages[labelsOptional]) : '';
        var optionalElement = localizedOptional && (React.createElement("span", { className: 'k-label-optional' }, localizedOptional));
        var spanClassNames = classNames({
            'k-floating-label-container': true,
            'k-focus': this.state.focused,
            'k-empty': !editorPlaceholder && !editorValue && (editorValue !== 0),
            'k-text-disabled': editorDisabled,
            'k-rtl': this.props.dir === 'rtl'
        }, className);
        var labelClassNames = classNames({
            'k-label': true,
            'k-text-error': editorValid === false,
            'k-text-disabled': editorDisabled
        });
        return (React.createElement("span", { id: this.props.id, className: spanClassNames, onFocus: this.handleFocus, onBlur: this.handleBlur, style: style, dir: this.props.dir },
            this.props.children,
            label
                ? editorId
                    ? React.createElement("label", { id: id, htmlFor: editorId, className: labelClassNames },
                        label,
                        optionalElement)
                    : React.createElement("span", { id: id, className: labelClassNames },
                        label,
                        optionalElement)
                : null));
    };
    /**
     * @hidden
     */
    FloatingLabel.propTypes = {
        label: PropTypes.string,
        editorId: PropTypes.string,
        editorValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
        editorPlaceholder: PropTypes.string,
        editorValid: PropTypes.bool,
        editorDisabled: PropTypes.bool,
        id: PropTypes.string,
        style: PropTypes.object,
        className: PropTypes.string,
        optional: PropTypes.bool
    };
    return FloatingLabel;
}(React.Component));
export { FloatingLabel };
registerForLocalization(FloatingLabel);
