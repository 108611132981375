"use strict";

import { WebSocketsMessageHandler } from "../web_sockets_message_handler";

/**
 * A WebSockets message handler for Chatting.
 */
export class ChatMessageHandler extends WebSocketsMessageHandler {
  constructor(statusService) {
    super(statusService);
  }

  onCustomMessage(message) {
    if (message.status === "partialMessage") {
      this.statusService.displayStatus(message);
    }
  }

  getDisplayMessage(message) {
    // Block everything except partial messages, so that default statuses like "Authenticating" and "Connecting..."
    // don't get into the chat.
    if (message?.data?.status === "partialMessage") {
      return message.data;
    }
    return "";
  }
}
