"use strict";

import React, { Fragment } from "react";
import BasePage from "../base_page";
import * as UIUtils from "../ui_utils";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import NavBar from "../widgets/bars/nav_bar";
import EditablesPageTitleBar from "../widgets/pageTitleBar/editables_page_title_bar";
import { PRODUCT_VIEW_TABS } from "./product_constants";
import ProductListPage from "./parentPages/product_list_page";
import ProductTopBar from "./topBar/product_top_bar";
import ProductTablePage from "./parentPages/product_table_page";
import ConfigurableTablesExport from "../configurableTables/export/configurable_tables_export";
import {
  filterRiskRankingFields
} from "../configurableTables/columnSelection/configurable_tables_column_selection_helper";
import { process } from "@progress/kendo-data-query";
import {
  DEFAULT_SORTING
} from "../configurableTables/columnOperations/configurable_tables_column_operations_constants";
import { PRODUCT_MODELS_CONFIG } from "./tables/product_tables_config";
import { NotificationProvider } from "../configurableTables/tables/widgets/notification/notification_provider";

// i18next-extract-mark-ns-start qtpp
class Product extends BasePage {
  constructor(props) {
    super(props);

  }

  componentDidMount() {
    this.projectId = UIUtils.parseInt(this.context.getParameterByName("projectId"));

    const {t} = this.props;
    document.title = t("QbDVision Product");

    super.componentDidMount();
  }

  handleTypeaheadResultsFromServer() {
    this.forceUpdateSafely();
  }

  handleProductTabChange(propName, tab) {
    UIUtils.pushHistoryURLWithNewParameter(tab.code, propName, tab.code);
    this.setStateSafely({[propName]: tab});
  }

  handleUpdateRecordCount(recordsCount) {
    this.setStateSafely({recordsCount});
  }

  handleShowArchivedChange(showArchived) {
    this.setStateSafely({showArchived});
  }

  handleModelTypeChange(selectedModelType) {
    const {
      columnOperationsHandler,
    } = this.state;
    columnOperationsHandler?.handleClearAllFilters(true);
    UIUtils.pushHistoryURLWithNewParameter({}, "selectedModelType", selectedModelType);
    const {modelName} = PRODUCT_MODELS_CONFIG[selectedModelType];
    this.setStateSafely({
      modelName: modelName,
      selectedModelType
    });
  }

  handleTableDataUpdated(tableData, project) {
    this.setStateSafely({tableData, project});
  }

  handleUpdateTableDependencies(dependencies) {
    this.setStateSafely(dependencies);
  }

  handleExport() {
    const {
      selectedModelType,
      tableData,
      project,
      visibleTableColumns,
      columnOperationsAdapter,
    } = this.state;

    const records = process(tableData.slice(0),
      columnOperationsAdapter.adaptFilterNames(this.state));

    for (const record of records.data) {
      if (record.details) {
        const sortedDetails = process(record.details.slice(0), {sort: DEFAULT_SORTING});
        record.details = sortedDetails.data;
      }
    }

    new ConfigurableTablesExport({
      records: records.data,
      selectedModelType,
      modelsConfig: PRODUCT_MODELS_CONFIG,
      feature: "Product",
      projectName: project.name,
      visibleTableColumns: filterRiskRankingFields(visibleTableColumns, project),
    }).exportReport();
  }

  handleVisibleColumnsChange(visibleTableColumns) {
    this.setStateSafely({visibleTableColumns});
  }

  handleTableStateChange(tableState) {
    this.setStateSafely({tableState});
  }

  renderPage() {
    const {
      recordsCount,
      selectedProductViewTab,
      selectedProductListTab,
      showArchived,
      selectedModelType,
      project,
      modelName,
      visibleTableColumns,
      columnSelectionHandler,
    } = this.state;
    const {projectId} = this;

    return (
      <Fragment>
        <EditablesPageTitleBar projectId={this.projectId}
                               projectName={project?.name ?? ""}
                               projectDeletedAt={project?.deletedAt ?? null}
                               isDemoProject={project?.isDemo ?? ""}
                               currentState={project?.currentState ?? null}
        />
        <NavBar selected="Product" projectId={projectId} />
        <ProductTopBar projectId={projectId}
                       project={project}
                       selectedModelType={selectedModelType || "Quality Attributes"}
                       recordsCount={recordsCount}
                       visibleTableColumns={visibleTableColumns}
                       onProductTabChange={this.handleProductTabChange}
                       onShowArchivedChange={this.handleShowArchivedChange}
                       onModelTypeChange={this.handleModelTypeChange}
                       onResetToDefaults={columnSelectionHandler?.handleResetToDefaults}
                       onVisibleTableColumnsChanged={(map) =>
                         columnSelectionHandler?.handleVisibleTableColumnsChanged(map, this.handleVisibleColumnsChange)}
                       onExport={this.handleExport}
        />
        {selectedProductViewTab?.code === PRODUCT_VIEW_TABS.LISTS.code ?
          <ProductListPage projectId={projectId}
                           project={project}
                           selectedProductListTab={selectedProductListTab}
                           onUpdateRecordCount={this.handleUpdateRecordCount}
                           showArchived={showArchived}
          /> : selectedProductViewTab?.code === PRODUCT_VIEW_TABS.TABLES.code ?
            <NotificationProvider>
              <ProductTablePage projectId={projectId}
                                modelName={modelName}
                                selectedModelType={selectedModelType || "Quality Attributes"}
                                project={project}
                                onTableStateChange={this.handleTableStateChange}
                                onUpdateTableDependencies={this.handleUpdateTableDependencies}
                                onVisibleColumnsChange={this.handleVisibleColumnsChange}
                                onTableDataUpdated={this.handleTableDataUpdated}
              />
            </NotificationProvider> : <Fragment><p>NONE {selectedProductViewTab?.code}</p></Fragment>}
      </Fragment>);
  }
}

export default I18NWrapper.wrap(Product, "product");
// i18next-extract-mark-ns-stop qtpp
