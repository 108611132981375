import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EMPTY_STRING } from "../../../helpers/constants/constants";
import CommonEditablesFormatter from "../../../../server/common/editables/common_editables_formatter";
import { getURLByTypeCodeAndId } from "../../../helpers/url_helper";

export const SpecificationCell = ({onChange, dataItem, data, readonly, field = EMPTY_STRING}) => {
  const defaultItem = {
    name: "Select a specification to link",
  };

  const itemRender = (li, itemProps) => {
    const itemChildren =
      <span className="dropdown-item">
        <span className="d-block">
          {itemProps.dataItem.name}
        </span>
        <span className={"action-dropdown-item"}>
          {CommonEditablesFormatter.getAttributeMeasurementInfoTechTransferFormat(itemProps.dataItem)}
        </span>
      </span>;

    return React.cloneElement(li, li.props, itemChildren);
  };

  const valueRender = (element, elementValue) => {
    const children = [];
    /**
     * Render default item
     */
    if (elementValue.name === defaultItem.name) {
      children.push(
        <span
          key={1}
          className={"default-dropdown-item"}
        >
        {defaultItem.name}
      </span>);

      return React.cloneElement(element, {...element.props}, children);
    }

    if (elementValue.name !== defaultItem.name) {
      children.push(
        <span
          key={1}
          className={"default-dropdown-item"}
        >
        {elementValue.name}
      </span>);

      return React.cloneElement(element, {...element.props}, children);
    }
  };

  const handleChange = (e) => {
    if (onChange) {
      onChange({
        dataIndex: 0,
        dataItem: dataItem,
        field: field,
        syntheticEvent: e.syntheticEvent,
        value: e.value,
      });
    }
  };

  const dataValue = dataItem[field] === null ? "" : dataItem[field];

  if (readonly) {
    return (
      <td>
        <a target="_blank" rel="noreferrer" href={getURLByTypeCodeAndId("MTL", "View", dataValue.LibraryMaterialId, false)}>
          {dataValue.name}
        </a>
        <span className="material-attribute-cell-secondary">
            {CommonEditablesFormatter.getAttributeMeasurementInfoTechTransferFormat(dataValue)}
        </span>
      </td>
    );
  }

  return (
    <td>
      <DropDownList
        style={{
          width: "100%",
        }}
        data={data}
        itemRender={itemRender}
        valueRender={valueRender}
        textField="name"
        value={data.find((c) => c.id === dataValue?.id)}
        defaultItem={defaultItem}
        onChange={handleChange}
      />
    </td>
  );
};