"use strict";

import React, { Fragment } from "react";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import * as UIUtils from "../ui_utils";
import { LIBRARY_MODELS_CONFIG } from "./tables/library_tables_config";
import ErrorBar from "../widgets/bars/error_bar";
import ConfigurableTablesColumnOperationsAdapter
  from "../configurableTables/columnOperations/configurable_tables_column_operations_adapter";
import ConfigurableTablesExportColumnGenerator
  from "../configurableTables/export/configurable_tables_export_column_generator";
import MaterialLibraryTablesColumnSelectionHandler from "./tables/material_library_tables_column_selection_handler";
import { getURLByTypeCodeAndId } from "../helpers/url_helper";
import { FROM_LIBRARY_STATUS } from "./library_constants";
import { TYPE_CODE } from "../../server/common/generic/common_constants";
import MaterialLibraryColumnOperationHandler from "./tables/material_library_column_operations_handler";
import * as styles from "../configurableTables/tables/styles/configurable_table.module.scss";
import ConfigurableTablePage from "../configurableTables/tables/configurable_table_page";
import { TableToolBar } from "../configurableTables/tables/widgets/topBar/toolBar/table_tool_bar";
import * as CommonEditables from "../../server/common/editables/common_editables";
import CategoryTableFilter from "./widgets/category_table_filter";
import { ShowArchiveToggle } from "../configurableTables/widgets/show_archive_toggle";
import { TableStateProvider } from "../configurableTables/tables/table_state_provider";
import { TableSettingsProvider } from "../configurableTables/tables/table_settings_provider";

/**
 * The dynamic table page for library materials.
 */
class LibraryTablePage extends ConfigurableTablePage {

  constructor(props) {
    super(props);

    this.resetState(props);

    this.columnOperationsAdapter = new ConfigurableTablesColumnOperationsAdapter();
    this.columnOperationsHandler = new MaterialLibraryColumnOperationHandler({
      parent: this,
      columnOperationsAdapter: this.columnOperationsAdapter,
    });

    this.columnSelectionHandler = new MaterialLibraryTablesColumnSelectionHandler({
      parent: this,
      modelsConfig: LIBRARY_MODELS_CONFIG,
      columnOperationsAdapter: this.columnOperationsAdapter,
      exportColumnGenerator: ConfigurableTablesExportColumnGenerator,
    }, false, "defaultValue");

    props.onUpdateTableDependencies({
      columnSelectionHandler: this.columnSelectionHandler,
      columnOperationsHandler: this.columnOperationsHandler,
      columnOperationsAdapter: this.columnOperationsAdapter,
    });
  }

  componentDidMount() {
    super.componentDidMount();

    this.resetState(this.props);
    this.loadTableData();
  }

  loadTableData() {
    UIUtils.setLoadingDisabled(false);
    this.columnOperationsHandler
      .handleDefaultColumnOperationsFromURL((state) => this.setStateSafely({...state}));
    UIUtils.secureAjaxGET(`configurableTables?source=systemLibrary&latestApprovedIfAvailable=true&latestVersion=true`)
      .done(this.handleReceivedDataFromServer);
  }

  adaptInstance(instance) {
    const {gmp} = instance;
    instance.gmp = gmp === "Yes" ? 1 : 0;
  }

  getAdaptedInfo(records) {
    const {selectedModelType, filter, onTableDataUpdated} = this.props;
    const libraryResultAdapter = LIBRARY_MODELS_CONFIG[selectedModelType]
      .adapter(
        {
          results: UIUtils.deepClone(records),
          selectedModelType,
          filter,
        }
      );
    const adaptedData = libraryResultAdapter.getRows();
    onTableDataUpdated(adaptedData);
    return adaptedData;
  }

  filterAdaptedRows(rows) {
    const {filter, showArchived} = this.props;
    return rows
      .filter(material => material.category === filter)
      .filter(material => showArchived || material.deletedAt === null);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      filter,
      selectedModelType,
      onVisibleColumnsChange,
      showArchived,
      onTableStateChange
    } = this.props;
    const oldSelectedModelType = prevProps.selectedModelType;
    const oldShowArchived = prevProps.showArchived;
    const {records, tableState} = this.state;

    if (
      records
      && (
        (JSON.stringify(records) !== JSON.stringify(prevState.records))
        || (selectedModelType !== oldSelectedModelType)
        || (JSON.stringify(prevProps.filter) !== JSON.stringify(filter))
        || (oldShowArchived !== showArchived)
      )
    ) {
      const adaptedRecords = this.getAdaptedInfo(records);
      this.setStateSafely(
        {
          adaptedRecords,
          filteredRows: this.filterAdaptedRows(adaptedRecords),
          selectedModelType,
        }, () =>
          this.columnSelectionHandler.handleResetColumnVisibility(onVisibleColumnsChange));
    }

    if (JSON.stringify(tableState) !== JSON.stringify(prevState.tableState)) {
      onTableStateChange(tableState);
    }
  }

  createModalNotifications(record) {

    super.createModalNotifications(record);

    const {t} = this.props;
    const LIBRARY_STATUS = [FROM_LIBRARY_STATUS.SYNCED, FROM_LIBRARY_STATUS.LINKED];

    const materials = record.Materials
      .filter(material => LIBRARY_STATUS.includes(material.fromLibraryStatus))
      .map(material => {
        return {...material, typeCode: TYPE_CODE.MATERIAL};
      });

    this.setStateSafely({
      warning:
        (materials.length &&
          <>
            <div id="archiveWarning">
              {t("Archiving will unsync/unlink the following project materials:")}
            </div>
            <br />
            {materials.map((material, idx) =>
              <div key={idx}>
                <a href={getURLByTypeCodeAndId(material.typeCode, "View", material.id)}
                   rel="noopener noreferrer"
                   target="_blank"
                ><b>{UIUtils.getRecordCustomLabelForDisplay(material)}</b></a>
              </div>
            )}
          </>
        )
    });
  }

  render() {
    const {
      t,
      onShowArchiveChange,
      selectedModelType,
      onCategoryChange,
      onVisibleColumnsChange
    } = this.props;
    const {
      filteredRows,
      visibleTableColumns,
      tableState,
    } = this.state;

    const {
      handleColumnOperationsChange,
    } = this.columnOperationsHandler;
    const {customFilters} = tableState || {};

    const config = LIBRARY_MODELS_CONFIG[selectedModelType];
    const {fieldsConfig, customFiltersHandler, modelName, table} = config || {};
    const sort = this.columnOperationsAdapter.getDefaultSorting(tableState);
    const libraryTable = config ?
      table({
        id: "libraryTable",
        records: filteredRows || [],
        selectedModelType,
        modelName,
        fieldsConfig,
        tableState: {
          ...tableState,
          sort,
        },
        t,
        customFilters,
        customFiltersHandler,
        onEmptyTableAddClicked: this.handleEmptyTableAddButton,
        onImportButtonClick: this.handleImport,
        handleArchiveButton: this.handleArchiveButton,
        handleRestoreButton: this.handleRestoreButton,
        visibleTableColumns: visibleTableColumns || [],
        columnOperationsAdapter: this.columnOperationsAdapter,
        onColumnOperationsChange: handleColumnOperationsChange
      }) : "";


    return (
      <TableStateProvider>
        <Fragment>
          <TableToolBar modelName={modelName}
                        selectedModelType={selectedModelType}
                        showAssessmentOptions={false}
                        tableState={tableState}
                        operationsAdapter={this.columnOperationsAdapter}
                        operationsHandler={this.columnOperationsHandler}
                        visibleTableColumns={visibleTableColumns}
                        onResetToDefaults={this.columnSelectionHandler?.handleResetToDefaults}
                        onVisibleTableColumnsChanged={(map) =>
                          this.columnSelectionHandler?.handleVisibleTableColumnsChanged(map, onVisibleColumnsChange)}
                        customFilters={customFilters}
                        customFiltersHandler={customFiltersHandler}
                        records={filteredRows}
                        quickFiltersZone={
                          <>
                            <CategoryTableFilter
                              categories={CommonEditables.MATERIAL_CATEGORIES}
                              onCategoryChange={onCategoryChange}
                            />
                            <ShowArchiveToggle t={t}
                                               type={selectedModelType}
                                               onShowArchiveChange={onShowArchiveChange}
                            />
                          </>
                        }
                        afterBulkEdit={this.handleBulkEdit}

          />
          <div className={styles["configurable-table"]}>
            <div id="libraryContainer" className={styles["configurable-table-inner-container"]}>
              <ErrorBar className={`mt-2 ${styles["error-bar"]}`} />
              <TableSettingsProvider visibleTableColumns={visibleTableColumns}>
                {libraryTable}
              </TableSettingsProvider>
            </div>
          </div>
          {this.renderArchivePopup()}
        </Fragment>
      </TableStateProvider>
    );
  }

}


export default I18NWrapper.wrap(LibraryTablePage, "libraryMaterial");
