"use strict";

import * as UIUtils from "../../../ui_utils";
import React, { Fragment } from "react";
import BaseReactComponent from "../../../base_react_component";

export class MaxCriticalityInfo extends BaseReactComponent {
  render() {
    const {children} = this.props;
    children.sort((i1, i2) => i2.criticalityScore - i1.criticalityScore);

    const firstChildWithMaxCriticality = children[0];
    if (firstChildWithMaxCriticality) {
      const allChildrenWithMaxCriticality = children.filter(
        child => child.criticalityScore === firstChildWithMaxCriticality.criticalityScore,
      );

      const bullet = allChildrenWithMaxCriticality.length > 1 ? "• " : "";

      return (
        <>
          {firstChildWithMaxCriticality.criticality}
          {allChildrenWithMaxCriticality ? (
            <p>
              {allChildrenWithMaxCriticality.map(child => (
                <Fragment key={child.id}>
                  {bullet}{UIUtils.getRecordLabelForDisplay(child.modelType, child.id, child.name)}<br />
                </Fragment>
              ))}
            </p>
          ) : ""}
        </>
      );
    } else {
      return "None";
    }
  }
}
