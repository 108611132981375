"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseReactComponent from "../../base_react_component";

export default class TechTransferTableHandler  extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.parent = props.parent;
  }

  handleReviewRecord(record) {
    UIUtils.ignoreHandler(event);

    UIUtils.clearError();
    UIUtils.clearError("#techTransferReviewError");

    this.parent.setStateSafely({
      shouldShowRightPanel: true,
      reviewRecord: record,
    });
  }
}
