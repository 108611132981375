"use strict";

import ReportCanvasDrawer from "./report_canvas_drawer";

export default class PCMDashboardCanvasDrawer extends ReportCanvasDrawer {
  static drawInfoBox(title, value, fontFamily, fontSize, valueFontWeight, margins, backgroundColor) {
    let x = margins.left;
    let y = margins.top;

    let canvas = PCMDashboardCanvasDrawer.getEmptyCanvas(200 + x, 110 + y);
    let ctx = canvas.getContext("2d");
    let defaultFontColor = "black";

    ctx.fillStyle = backgroundColor;
    ctx.fillRect(x, y, canvas.width, canvas.height);

    ctx.font = `${fontSize} ${fontFamily}`;
    ctx.fillStyle = defaultFontColor;
    ctx.textAlign = "center";
    ctx.fillText(title, 100 + x, 45 + y);

    ctx.font = `${valueFontWeight} ${fontSize} ${fontFamily}`;
    ctx.fillStyle = defaultFontColor;
    ctx.textAlign = "center";
    ctx.fillText(value, 100 + x, 75 + y);

    return canvas;
  }

  static drawIndexChart(title, value, fontFamily, fontSize, valueFontWeight, chartImage, margins, backgroundColor) {
    let x = margins.left;
    let y = margins.top;

    let canvas = PCMDashboardCanvasDrawer.getEmptyCanvas(200 + x, 180 + y);
    let ctx = canvas.getContext("2d");
    let defaultFontColor = "black";

    ctx.fillStyle = backgroundColor;
    ctx.fillRect(x, y, canvas.width, canvas.height);

    ctx.font = `${fontSize} ${fontFamily}`;
    ctx.fillStyle = defaultFontColor;
    ctx.textAlign = "center";
    ctx.fillText(title, 100 + x, 40 + y);

    if (value) {
      ctx.font = `${valueFontWeight} ${fontSize} ${fontFamily}`;
      ctx.fillStyle = defaultFontColor;
      ctx.textAlign = "center";
      ctx.fillText(value, 100 + x, 65 + y);
    }

    if (chartImage) {
      if (title === "Capability Plot") {
        ctx.drawImage(chartImage, x, 70 + y, 200, 120);
      } else {
        ctx.drawImage(chartImage, 10 + x, 80 + y, 180, 70);

        ctx.font = `normal 10px ${fontFamily}`;
        ctx.fillText("0", 23 + x, 137 + y);
        ctx.fillText("2", 175 + x, 137 + y);
      }
    }
    return canvas;
  }

  static drawControlChart(title, fontFamily, fontSize, backgroundColor, valueFontWeight, chartImage, margins) {
    let x = margins.left;
    let y = margins.top;

    let canvas = PCMDashboardCanvasDrawer.getEmptyCanvas(650 + x, 335 + y);
    let ctx = canvas.getContext("2d");
    let defaultFontColor = "black";

    ctx.fillStyle = backgroundColor;
    ctx.fillRect(x, y, canvas.width, canvas.height);

    ctx.font = `${fontSize} ${fontFamily}`;
    ctx.fillStyle = defaultFontColor;
    ctx.fillText(title, 50 + x, 35 + y);

    if (chartImage) {
      ctx.drawImage(chartImage, 50 + x, 50 + y, 550, 250);
    }
    return canvas;
  }

  static includeAttributeInformation() {
    return true;
  }
}
