"use strict";

import * as UIUtils from "../../ui_utils";
import * as go from "gojs";
import canvasDrawer from "../../helpers/canvas/report_canvas_drawer";
import canvasDownloader from "../../helpers/canvas/canvas_downloader";
import { ALIGNMENT } from "../../helpers/canvas/canvas_drawer_base";

/**
 * This will filter the nodes of a GoJS group and return back a GoJS list with the visible nodes only
 * @param group The GoJS group
 * @returns {List<any>}
 */
export function getVisibleNodesInGroup(group) {
  let visibleParts = new go.List();
  group.memberParts.each(part => {
    if (part.visible) {
      visibleParts.add(part);
    }
  });

  return visibleParts;
}

/**
 * Exports a report as a PNG image, with nice details at the top and bottom to describe the user, etc.
 * @param reportImage The image of what you want on the report.
 * @param exportInfo The information for the headers.
 * @param filename {string} the name of the png file to send to the user.
 */
export function exportReportAsPng(reportImage, exportInfo, filename) {
  let logoImage = new Image(266, 32);

  logoImage.onload = () => {
    const {reportTitle, projectName, processLabel} = exportInfo;
    let finalCanvas;
    let margins;

    let reportMargins = {
      left: 30,
      top: 15,
      right: 30,
      bottom: 30,
    };

    //Render a copy of the Process Map Report
    let graphCanvas = document.createElement("canvas");
    let graphCtx = graphCanvas.getContext("2d");
    graphCanvas.width = reportImage.width;
    graphCanvas.height = reportImage.height;
    graphCtx.fillStyle = "#FFFFFF";
    graphCtx.fillRect(0, 0, reportImage.width, reportImage.height);
    graphCtx.drawImage(reportImage, 0, 0);

    let reportWidth = graphCanvas.width;

    graphCanvas = canvasDrawer.applyMarginsToCanvas(graphCanvas, reportMargins);

    let reportHeaderCanvas = canvasDrawer.drawReportHeader({
      reportTitle: reportTitle,
      projectName,
      processLabel,
      exportDate: UIUtils.getDateForDisplayToUser(new Date()),
      reportWidth: reportWidth
    });

    margins = canvasDrawer.resetMargins();
    margins.top = 30;
    margins.bottom = 15;
    reportHeaderCanvas = canvasDrawer.applyMarginsToCanvas(reportHeaderCanvas, margins);

    // Draw the report header separator line
    let ctx = reportHeaderCanvas.getContext("2d");
    ctx.fillStyle = "#1FBCFF";
    ctx.fillRect(0, reportHeaderCanvas.height - 2, reportHeaderCanvas.width, 2);

    finalCanvas = canvasDrawer.mergeCanvasToBottom(reportHeaderCanvas, graphCanvas, 15, ALIGNMENT.CENTER);

    let reportFooterCanvas = canvasDrawer.drawReportFooter({
      logoImage: logoImage,
      reportWidth: graphCanvas.width,
      reportMargins: reportMargins
    });

    margins = canvasDrawer.resetMargins();
    margins.top = 15;
    margins.bottom = 15;
    reportFooterCanvas = canvasDrawer.applyMarginsToCanvas(reportFooterCanvas, margins);
    finalCanvas = canvasDrawer.mergeCanvasToBottom(finalCanvas, reportFooterCanvas, 0, ALIGNMENT.CENTER);

    //Export to png
    finalCanvas.isDrawingMode = false;
    canvasDownloader.downloadCanvas(finalCanvas, filename);
  };

  logoImage.src = "/images/logo-full-32.png";
}
