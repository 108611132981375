"use strict";

import * as UIUtils from "../../../../ui_utils";
import { VERSION_REGEX, FIELD_NAMES } from "../constants/constants";
import moment from "moment/moment";
import React from "react";
import { DocumentBulkAddTableFetcher } from "./document_bulk_add_table_fetcher";

/**
 * This class works as a validator for bulk add tables.
 */
export class DocumentBulkAddTableFieldValidator {

  constructor() {

    this._validationsSet = new Set();
    this.dataFetcher = new DocumentBulkAddTableFetcher();

    this.validateVersion = this.validateVersion.bind(this);
    this.validateDocumentName = this.validateDocumentName.bind(this);
    this.validateEffectiveDate = this.validateEffectiveDate.bind(this);
    this.validateCustomIDs = this.validateCustomIDs.bind(this);

    this._fieldToValidatorMap = [FIELD_NAMES.VERSION, FIELD_NAMES.DOCUMENT_NAME, FIELD_NAMES.EFFECTIVE_DATE];
  }

  validateVersion() {

    this.fieldName = FIELD_NAMES.VERSION;
    if (this.version.match(VERSION_REGEX)) {
      this.removeError();
    } else {
      this.showError();
    }
  }

  validateDocumentName() {

    this.fieldName = FIELD_NAMES.DOCUMENT_NAME;
    if (!this.documentName) {
      this.showError();
    } else {
      this.removeError();
    }
  }

  validateEffectiveDate() {

    this.fieldName = FIELD_NAMES.EFFECTIVE_DATE;
    if (!UIUtils.isInteger(this.version) &&
      moment().diff(this.effectiveDate, "days") > 0) {
      this.showError();
    } else {
      this.removeError();
    }
  }

  /**
   * If there are already existing documents with custom IDs user try to re-bulk add,
   * user would get a warning with all these existing custom IDs.
   * @param documents List of documents to validate against.
   * @param existingCustomIds Existing custom ID of documents saved on the system.
   * @param callback the function to be called after customID validation.
   */
  validateCustomIDs(documents, existingCustomIds, callback) {

    let inUseCustomIDs = documents
      .filter(document => existingCustomIds.includes(document.customID));

    if (inUseCustomIDs.length > 0) {
      let warningsBody = (
        <div>
          <span>The following Custom IDs are already in use:</span>
          <ul>
            {inUseCustomIDs
              .map((document, index) => {
                return (<li key={index}>
                  {`${document.customID} - ${document.name}`}
                </li>);
              })
            }
          </ul>
        </div>
      );

      callback(warningsBody, inUseCustomIDs.length);
    }
  }

  isTableValid() {
    return this._validationsSet.size === 0;
  }

  addToValidations() {
    this._validationsSet.add(this.fieldId);
  }

  removeFromValidations() {
    this._validationsSet.delete(this.fieldId);
  }

  removeRowValidations(rowIndex) {
    for (let field of this._fieldToValidatorMap) {
      let fieldId = `#${field}_${rowIndex}`;

      if (this._validationsSet.has(fieldId)) {
        this._validationsSet.delete(fieldId);
      }
    }
  }

  get version() {
    return this.dataFetcher.getValue(FIELD_NAMES.VERSION);
  }

  get documentName() {
    return this.dataFetcher.getValue(FIELD_NAMES.DOCUMENT_NAME);
  }

  get effectiveDate() {
    return this.dataFetcher.effectiveDate;
  }

  set rowIndex(rowIndex) {
    this._rowIndex = rowIndex;
    this.dataFetcher.rowIndex = rowIndex;
  }

  set fieldName(fieldName) {
    this._fieldName = fieldName;
  }

  get rowIndex() {
    return this._rowIndex;
  }

  get fieldId() {
    return `#${this._fieldName}_${this._rowIndex}`;
  }

  get fieldName() {
    return this._fieldName;
  }

  get validationIconId() {
    return `${this.fieldId}_icon`;
  }

  showError() {
    this.addToValidations();
    $(this.validationIconId).removeClass("document-bulk-add-validation-icon-hidden");
  }

  removeError() {
    this.removeFromValidations();
    $(this.validationIconId).addClass("document-bulk-add-validation-icon-hidden");
  }
}
