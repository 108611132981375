"use strict";

import * as UIUtils from "../../../ui_utils";
import { RequirementSummaryDataTransform } from "./requirement_summary_data_transform";
import { orderAndIndexUnits } from "../../../processExplorer/indexers/uo_indexer";

export class UnitOperationSummaryDataTransform extends RequirementSummaryDataTransform {

  get type() {
    return "unitOperationSummary";
  }

  getSupportedRecordTypes() {
    return ["uos"];
  }

  fillControlInformation(record, requirement, options) {
    // Do nothing, as unit operations have no control methods
  }

  getModelNameForView(recordType) {
    return "uos";
  }

  fillRiskLinks(record, requirement, options) {
    // Do nothing as UO doesn't have risk links
  }

  fillAcceptanceCriteria(record, requirement, options) {
    // do nothing, as UO doesn't have an acceptance criteria
  }

  filterResultsByCriticality(records, options) {
    return records;
  }

  fillGeneralInformation(record, requirement, options) {
    super.fillGeneralInformation(record, requirement, options);

    record.Supplier = this.sanitizeSupplier(requirement.Supplier, options);

    record.supplierId = requirement.supplierId;
    record.UnitOperationId = requirement.UnitOperationId;
    record.PreviousUnitId = requirement.previousUnitId || requirement.PreviousUnitId || "";
    record.currentState = requirement.currentState;

    record.input = UIUtils.secureString(requirement.input);
    record.output = UIUtils.secureString(requirement.output);
    record.risk = UIUtils.secureString(requirement.risk);
    record.description = UIUtils.secureString(requirement.description);
  }

  sanitizeSupplier(editable) {
    const result = editable ? {
      id: editable.id,
      name: UIUtils.secureString(editable.name),
      modelType: "SUP",
      qualificationStatus: UIUtils.secureString(editable.qualificationStatus),
    } : null;

    // uncomment for verbose logging
    // console.log("Linked supplier: ", result);

    return result;
  }


  /**
   * Performs a final formatting pass on the results
   * @param result {*} The result object created by previous steps
   * @param options {*} An object containing the report options
   * @returns {*} The result object to be passed to Stimulsoft
   * @protected
   */
  wrapUp(result, options) {
    const {instances} = result;
    const uos = instances.uos.map(uo => {
      let previousUnitOperation = instances.uos.find(record => record.UnitOperationId === uo.PreviousUnitId);
      let supplier = uo.Supplier !== "None" ? uo.Supplier : null;
      return {
        ...uo,
        previousUnitOperationLabel: previousUnitOperation ? UIUtils.getRecordLabelForDisplay("UO", uo.PreviousUnitId, previousUnitOperation.name) : "None",
        supplierLabel: supplier ? `${UIUtils.getRecordLabelForDisplay("SUP", supplier.id, supplier.name)} (${supplier.qualificationStatus})` : "None"
      };
    });

    instances.uos = orderAndIndexUnits(uos, "UnitOperationId");
    return result;
  }
}
