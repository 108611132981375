"use strict";


import { LibraryHelper } from "../helpers/library_helper";

export const WizardExitStrategies = {
  Sync: (state) => {
    const {material} = state;
    LibraryHelper.redirectToMaterial(material);
  },
  Swap: (state) => {
    const {material} = state;
    LibraryHelper.redirectToMaterial(material);
  },
  Create: () => {
    LibraryHelper.redirectToProcessExplorer();
  },
  Unlink: (material) => {
    LibraryHelper.redirectToMaterial(material);
  },
  Unsync: (material) => {
    LibraryHelper.redirectToMaterial(material);
  }
};