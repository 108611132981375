"use strict";

import BaseReactComponent from "../../base_react_component";

const React = require("react");
const ReactDOM = require("react-dom");
import * as I18NWrapper from "../../i18n/i18n_wrapper";

// i18next-extract-mark-ns-start users
class UserDeletePopup extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {errorText: ""};
  }

  componentDidMount() {
    super.componentDidMount();
    $(ReactDOM.findDOMNode(this)).modal("show");
    $(ReactDOM.findDOMNode(this)).on("hidden.bs.modal", this.props.onHideModal);
  }

  render() {
    const {t, isAPIKey} = this.props;

    return (
      <div className="modal fade" id="verifyDeleteModal" role="dialog" ref={this.props.modalRef}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="deleteTitle">{t("Verify Delete")}</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h1>{t("Are you sure?")}</h1>
              <br />
              <p className="alert alert-info">
                {isAPIKey ? t("You will never be able to recover this API Key's information: ")
                  : t("You will never be able to recover this user's information: ")}
                <span style={{fontWeight: "bold"}} id="verifyNameSpan">
                    {this.props.fullName}
                  </span>
              </p>
              <p style={{margin: "10px"}}>
                {isAPIKey ? t("Note: You can disable this API Key from logging in by editing it and unchecking \"Enabled\"")
                  : t("Note: You can disable this user from logging in by editing them and unchecking \"Enabled\"")}
              </p>
              <br />

              <div className="btn-group" style={{display: "inline-block"}}>
                <button className="btn btn-lg btn-danger" id="deleteUserButton" onClick={this.props.onNukeEm}>
                  {t("Delete")}
                </button>
                <button className="btn btn-lg btn-secondary" id="cancelUserDeleteButton" data-dismiss="modal">{t("Cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(UserDeletePopup, "users");
// i18next-extract-mark-ns-stop users
