import { BaseParser } from "../base_parser";
import * as UIUtils from "../../../../ui_utils";

export class SupplierParser extends BaseParser {
  parse() {
    const newElement = document.createElement(this.node.tagName);
    const {modelName} = this.getNodeInfo();
    const modelField = this.addModelAndColumnToField(this.fields, modelName);
    this.addModelAndColumnToField(modelField, "Supplier", "name");
    newElement.innerText = getSupplierValue(this.data);
    return [newElement];
  }
}

export function getSupplierValue(record) {
  return record?.Supplier?.id
    ? UIUtils.getRecordLabelForDisplay(
      UIUtils.getTypeCodeForModelName("Supplier"),
      record.Supplier.id,
      record.Supplier.name
    )
    : "";
}
