"use strict";

import * as UIUtils from "../ui_utils";
import React, { Fragment } from "react";
import moment from "moment";
import CommonEditablesPageTitleBar from "../widgets/pageTitleBar/common_editables_page_title_bar";
import Section from "../editor/widgets/section";
import ComboBoxAttribute from "../editor/attributes/combo_box_attribute";
import TextAreaAttribute from "../editor/attributes/text_area_attribute";
import TextAttribute from "../editor/attributes/text_attribute";
import DateAttribute, { SET_EXPLICITLY } from "../editor/attributes/date_attribute";
import BaseQuickEditor from "../editor/base_quick_editor";
import { EDITOR_TYPES } from "../editor/editor_constants";


/**
 * This is used to CRUD suppliers.
 */
export default class Supplier extends BaseQuickEditor {
  constructor(props) {
    super(props, "supplier");

    // Bind stuff
    this.renderAttributes = this.renderAttributes.bind(this);

    if (this.isAdd()) {
      this.setStateSafely({
        breadcrumb: {
          current: "New Supplier",
          parent: "Suppliers",
          parentLink: "/suppliers/list.html",
        },
        auditMethod: "Not Applicable"
      });
    }
  }

  componentWillUnmount() {
    document.title = "QbDVision Supplier";

    super.componentWillUnmount();
  }

  shouldShowNav() {
    return false;
  }

  renderPageTitleBar() {
    return this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ?
      <CommonEditablesPageTitleBar recordName={this.state.name}
                                   recordId={this.state.id}
                                   name="Suppliers"
                                   typeCode={UIUtils.getTypeCodeForModelName(this.baseTypeName)}
                                   currentState={this.getCurrentState()}
      /> : false;
  }

  getTabName() {
    return "Suppliers";
  }

  onDataReceivedFromServer() {
    super.onDataReceivedFromServer();
    if (this.state.expirationDate) {
      this.handleChangeValue("expirationDate" + SET_EXPLICITLY, true);
    }

    let supplier = {typeCode: "SUP", id: this.state.id, name: this.state.name};
    this.setStateSafely({
      breadcrumb: {
        current: UIUtils.getRecordCustomLabelForDisplay(supplier),
        parent: "Suppliers",
        parentLink: "/suppliers/list.html",
      }
    });
  }

  handleChangeValue(attributeName, attributeValue, callback, attributeType) {
    super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
    if (attributeName === "effectiveDate") {
      if (!this.state["expirationDate" + SET_EXPLICITLY]) {
        let attributeDate = moment(attributeValue, UIUtils.DATE_FORMAT_FOR_STORAGE);
        this.expirationDateRef.setDate(attributeDate.add(1, "year"));
      }
    }
  }

  componentDidMount() {
    super.componentDidMount();
  }

  renderAttributes() {
    return <Fragment>
      <Section parent={this}
               header={this.getGeneralHeader()}
               collapsible={false}
      >
        <div className="row">
          <TextAttribute name="name"
                         className="col-sm-12"
                         parent={this}
          />
          <TextAreaAttribute name="address"
                             className="col-sm-12"
                             parent={this}
          />
          <TextAttribute name="phone"
                         parent={this}
          />
          <TextAttribute name="website"
                         parent={this}
          />
          <TextAreaAttribute name="servicesOrProducts"
                             className="col-sm-12"
                             parent={this}
          />
          <ComboBoxAttribute name="supplierRank"
                             options={["Primary", "Secondary"]}
                             default="Primary"
                             tooltipText="Supply disruptions can have catastrophic consequences on a drug sponsor, costing the company precious time, money, and customers. Having a secondary supplier in place is one of the most crucial steps a pharma company can take to protect its delivery schedule and ensure a continuous supply of product."
                             parent={this}
                             isLoading={this.state.isLoading}
                             parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                             parentId={this.state.id}
          />
          <ComboBoxAttribute name="classification"
                             options={["None","GxP", "Non-GxP"]}
                             default="None"
                             tooltipText="Indicates if a supplier is classified for GxP usage (e.g., GMP, GLP, GCP)."
                             parent={this}
                             isLoading={this.state.isLoading}
                             parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                             parentId={this.state.id}
          />
        </div>
      </Section>
      <Section id="audit"
               parent={this}
               showDocLinks={true}
               header="Audit"
      >
        <div className="row">
          <ComboBoxAttribute name="auditMethod"
                             options={["Questionnaire", "Desktop/Paper", "Onsite", "Virtual/Remote", "Not Applicable"]}
                             default="Not Applicable"
                             parent={this}
                             isLoading={this.state.isLoading}
                             parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                             parentId={this.state.id}
          />
        </div>
        <div className="row">
          <DateAttribute name="dateCompleted"
                         instructions={`To edit this field, the "Audit Method" field above must be set to anything but "Not Applicable"`}
                         disabled={this.state.auditMethod === "Not Applicable"}
                         parent={this}
          />
          <DateAttribute name="nextAudit"
                         instructions={`To edit this field, the "Audit Method" field above must be set to anything but "Not Applicable"`}
                         disabled={this.state.auditMethod === "Not Applicable"}
                         parent={this}
          />
        </div>
        <div className="row">
          <TextAreaAttribute name="additionalAuditComments"
                             className="col-sm-12"
                             displayName="Additional Comments"
                             parent={this}
          />
        </div>
      </Section>
      <Section id="qualification"
               parent={this}
               showDocLinks={true}
               header="Qualification"
      >
        <div className="row">
          <ComboBoxAttribute name="qualificationStatus"
                             options={["",
                               "Not Qualified",
                               "Conditional",
                               "Qualified",
                               "Disqualified",
                               "Re-qualified",
                               "Pending"
                             ]}
                             default=""
                             tooltipText="Supplier qualification should be in accordance with your corporate SOPs."
                             parent={this}
                             isLoading={this.state.isLoading}
                             parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                             parentId={this.state.id}
          />
        </div>
        <div className="row">
          <DateAttribute name="effectiveDate"
                         tooltipText="The date the supplier qualification goes into effect."
                         disabled={this.state.qualificationStatus !== "Qualified" && this.state.qualificationStatus !== "Re-qualified"}
                         instructions={`To edit this field, the "Qualification Status" field above must be set to either "Qualified" or "Re-qualified"`}
                         parent={this}
          />
          <DateAttribute name="expirationDate"
                         ref={dateAttribute => this.expirationDateRef = dateAttribute}
                         tooltipText="The date the supplier qualification expires."
                         disabled={this.state.qualificationStatus !== "Qualified" && this.state.qualificationStatus !== "Re-qualified"}
                         instructions={`To edit this field, the "Qualification Status" field above must be set to either "Qualified" or "Re-qualified"`}
                         parent={this}
          />
          <ComboBoxAttribute name="qualityAgreement"
                             options={["Yes", "No", "Not Applicable"]}
                             default="No"
                             tooltipText="A quality agreement is a comprehensive written agreement between parties involved in the contract manufacturing of drugs that defines and establishes each party’s manufacturing activities in terms of how each will comply with CGMP."
                             parent={this}
                             isLoading={this.state.isLoading}
                             parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                             parentId={this.state.id}
          />
          <ComboBoxAttribute name="supplyAgreement"
                             options={["Yes", "No", "Not Applicable"]}
                             default="No"
                             parent={this}
                             isLoading={this.state.isLoading}
                             parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                             parentId={this.state.id}
          />
        </div>
        <div className="row">
          <TextAreaAttribute name="additionalQualificationComments"
                             className="col-sm-12"
                             displayName="Additional Comments"
                             parent={this}
          />
        </div>
      </Section>
      <Section id="risk"
               parent={this}
               showDocLinks={true}
               header="Risk"
      >
        <div className="row">
          <ComboBoxAttribute name="riskRating"
                             options={["High", "Medium", "Low", "Not Assessed"]}
                             default="Not Assessed"
                             tooltipText={<div>
                               <b>High</b> - Supplier custom material, component or service. No alternative suppliers
                               available or extremely difficult to qualify; <br />
                               <b>Medium</b> - Common material, component, or service. Alternative suppliers
                               available; <br />
                               <b>Low</b> - Well defined material, component, or service. Safety stock feasible. Many
                               alternative suppliers available.
                             </div>}
                             parent={this}
                             isLoading={this.state.isLoading}
                             parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                             parentId={this.state.id}
          />
          <TextAreaAttribute name="riskJustification"
                             className="col-sm-12"
                             parent={this}
          />
          <TextAreaAttribute name="riskMitigation"
                             className="col-sm-12"
                             parent={this}
          />
          <TextAreaAttribute name="riskControl"
                             className="col-sm-12"
                             parent={this}
          />
        </div>
      </Section>
      <Section id="contacts"
               parent={this}
               header="Contacts"
      >
        <div className="row">
          <TextAttribute name="primaryContactName"
                         parent={this}
          />
          <TextAttribute name="primaryContactPhone"
                         parent={this}
          />
          <TextAttribute name="primaryContactEmail"
                         parent={this}
          />
          <TextAttribute name="primaryContactTitle"
                         parent={this}
          />
        </div>
        <div className="row">
          <TextAttribute name="regulatoryContactName"
                         parent={this}
          />
          <TextAttribute name="regulatoryContactPhone"
                         parent={this}
          />
          <TextAttribute name="regulatoryContactEmail"
                         parent={this}
          />
          <TextAttribute name="regulatoryContactTitle"
                         parent={this}
          />
        </div>
        <div className="row">
          <TextAttribute name="qualityContactName"
                         parent={this}
          />
          <TextAttribute name="qualityContactPhone"
                         parent={this}
          />
          <TextAttribute name="qualityContactEmail"
                         parent={this}
          />
          <TextAttribute name="qualityContactTitle"
                         parent={this}
          />
        </div>
        <div className="row">
          <TextAttribute name="otherContactName"
                         parent={this}
          />
          <TextAttribute name="otherContactPhone"
                         parent={this}
          />
          <TextAttribute name="otherContactEmail"
                         parent={this}
          />
          <TextAttribute name="otherContactTitle"
                         parent={this}
          />
        </div>
      </Section>
    </Fragment>;
  }
}
