var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Toolbar, ToolbarItem } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { unaryOperator, stringOperator } from './operators';
import { messages, filterClose, filterExpressionAriaLabel, filterExpressionDropdownAriaLabel, filterExpressionOperatorDropdownAriaLabel } from '../messages';
var setInitialValue = function (filter) {
    if (filter.value === undefined && !unaryOperator(filter.operator)) {
        filter.value = stringOperator(filter.operator) ? '' : null;
    }
};
/**
 * @hidden
 */
var Expression = /** @class */ (function (_super) {
    __extends(Expression, _super);
    function Expression() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onFieldChange = function (event) {
            var nextFieldName = event.value.name;
            var nextField = _this.props.fields.find(function (f) { return f.name === nextFieldName; });
            var prevFilter = _this.props.filter;
            var operator = (nextField && !nextField.operators.some(function (o) { return o.operator === prevFilter.operator; })) ?
                nextField.operators[0].operator : prevFilter.operator;
            var nextFilter = {
                field: nextFieldName,
                operator: operator
            };
            setInitialValue(nextFilter);
            _this.triggerOnFilterChange(prevFilter, nextFilter, event);
        };
        _this.onOperatorChange = function (event) {
            var operator = event.value.operator;
            var prevFilter = _this.props.filter;
            var nextFilter;
            if (unaryOperator(operator)) {
                var value = prevFilter.value, prevFilterNoValue = __rest(prevFilter, ["value"]);
                nextFilter = __assign(__assign({}, prevFilterNoValue), { operator: operator });
            }
            else {
                nextFilter = __assign(__assign({}, prevFilter), { operator: operator });
            }
            setInitialValue(nextFilter);
            _this.triggerOnFilterChange(prevFilter, nextFilter, event);
        };
        _this.onInputChange = function (event) {
            var prevFilter = _this.props.filter;
            var nextFilter = event.nextFilter;
            if (unaryOperator(nextFilter.operator)) {
                var field = _this.props.fields.find(function (f) { return f.name === nextFilter.field; });
                nextFilter = __assign(__assign({}, nextFilter), { operator: (field && field.operators[0].operator) || nextFilter.operator });
            }
            _this.triggerOnFilterChange(prevFilter, nextFilter, event);
        };
        _this.triggerOnFilterChange = function (prevFilter, nextFilter, event) {
            var changeEvent = {
                prevFilter: prevFilter,
                nextFilter: nextFilter,
                syntheticEvent: event.syntheticEvent,
                nativeEvent: event.nativeEvent,
                target: _this
            };
            _this.props.onChange.call(undefined, changeEvent);
        };
        _this.onFilterRemove = function (event) {
            var removeEvent = {
                filter: _this.props.filter,
                syntheticEvent: event.syntheticEvent,
                nativeEvent: event.nativeEvent,
                target: _this
            };
            _this.props.onRemove.call(undefined, removeEvent);
        };
        return _this;
    }
    Expression.prototype.render = function () {
        var locService = provideLocalizationService(this);
        var _a = this.props, fields = _a.fields, filter = _a.filter, _b = _a.ariaLabel, ariaLabel = _b === void 0 ? locService.toLanguageString(filterExpressionAriaLabel, messages[filterExpressionAriaLabel]) : _b;
        var field = fields.find(function (f) { return f.name === filter.field; });
        var operators = ((field && field.operators) || [])
            .map(function (o) { return (__assign(__assign({}, o), { text: locService.toLanguageString(o.text, messages[o.text] || o.text) })); });
        return (React.createElement("div", { className: "k-filter-toolbar", role: "group", "aria-label": ariaLabel },
            React.createElement(Toolbar, { keyboardNavigation: false, role: "" },
                React.createElement(ToolbarItem, { className: "k-filter-field" },
                    React.createElement(DropDownList, { className: "k-filter-dropdown", data: fields, textField: "label", value: fields.find(function (f) { return f.name === filter.field; }), onChange: this.onFieldChange, ariaLabel: locService.toLanguageString(filterExpressionDropdownAriaLabel, messages[filterExpressionDropdownAriaLabel]) })),
                React.createElement(ToolbarItem, { className: "k-filter-operator" },
                    React.createElement(DropDownList, { data: operators, textField: "text", value: operators.find(function (o) { return o.operator === filter.operator; }), onChange: this.onOperatorChange, ariaLabel: locService.toLanguageString(filterExpressionOperatorDropdownAriaLabel, messages[filterExpressionOperatorDropdownAriaLabel]) })),
                React.createElement(ToolbarItem, { className: "k-filter-value" }, field && React.createElement(field.filter, { filter: filter, onFilterChange: this.onInputChange })),
                React.createElement(ToolbarItem, null,
                    React.createElement(Button, { title: locService.toLanguageString(filterClose, messages[filterClose]), icon: "close", fillMode: "flat", type: "button", onClick: this.onFilterRemove })))));
    };
    Expression.propTypes = {
        filter: PropTypes.object.isRequired,
        fields: PropTypes.array.isRequired,
        ariaLabel: PropTypes.string,
        onChange: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired
    };
    return Expression;
}(React.Component));
export { Expression };
registerForLocalization(Expression);
