/* stylelint-disable-next-line import-notation */
@import "../../../../../css/brand_colors";

.container {
  display: flex;
  height: 67px;
  min-height: 67px;
  z-index: 200;
  background: $brand-white;
  gap: 16px;
  margin: 0 2%;
  border-bottom: 1px solid $brand-mild-grey;
}

.inner-container {
  display: flex;
  gap: 16px;
  width: 100%;
  margin: 10px 2%;
  padding: 15px 0;
  border-bottom: 1px solid $brand-mild-grey;
}

.actions-container {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  float: right;
  flex-direction: row-reverse;
}

.search-container {
  display: inline-flex;
  float: right;
}

.title-container {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  margin-right: auto;

  .default-title {
    color: $brand-black;
    font-family: Roboto, sans-serif;
    font-size: 1.2857rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.views-container {
  display: inline-flex;
  float: left;
  align-items: center;
}
