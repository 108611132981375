"use strict";

import { Button } from "@qbdvision-inc/component-library";
import React from "react";
import { useTableState } from "../../../../../table_state_provider";
import * as UIUtils from "../../../../../../../ui_utils";


export const BulkProposeButton = ({modelName}) => {

  const {
    selectedIds,
  } = useTableState();

  const onClick = () => {
    const selectedRecords = [];
    const projectId = UIUtils.getParameterByName("projectId") || null;
    for (let key of selectedIds) {
      selectedRecords.push(`${UIUtils.getTypeCodeForModelName(modelName)}-${key}`);
    }

    sessionStorage["bulkProposalRequirementIds"] = JSON.stringify(selectedRecords);

    let url  = "/projects/bulkProposal.html";
    url += projectId ? `?projectId=${projectId}` : "?isTopLevelRecord=true";
    window.location.href = UIUtils.getSecuredURL(url);
  };

  return (
    <Button
      id="bulkPropose"
      label={"Propose"}
      size={"small"}
      type="secondary"
      onClick={onClick}
    />
  );
};