import moment from "moment";
import * as UIUtils from "../../ui_utils";
import { generateDocumentsValue, showBullet } from "../export/configurable_tables_export_value_generator";

const JOIN_SEPARATOR = "\r\n";

/*
Functions on this file are used to format each of the diff
rows values (ex. Checkbox should show as Yes/No instead of true/false).
 */

export function formatValue(value, record, prop, isDocs = false, isDate = false) {
  if (isDocs) {
    return generateDocumentsValue(record, prop);
  } else if (isDate) {
    return formatDate(value);
  } else if (typeof value === "boolean") {
    return value ? "Yes" : "No";
  } else if (Array.isArray(value)) {
    return formatArray(value);
  } else if (typeof value === "string" && value[0] === "[" && value[value.length - 1] === "]") {
    try {
      return formatArray(JSON.parse(value));
    } catch (e) {
      // Is just a string, no need to format it.
      return value;
    }
  }
  return !value ? null : value;
}

export function formatFloat(value, placeHolder = "NA") {
  return (value === placeHolder || !value) ? "" : parseFloat(value);
}

export function formatJson(record, prop) {
  return record[prop] !== "[]" && record[prop]?.trim() ? JSON.parse(record[prop]) : null;
}

function formatArray(array) {
  array = array.sort().map(value => (showBullet(array)) + value);
  return array.join(JOIN_SEPARATOR);
}

export function formatDate(date, format, returnTodayDate = false) {
  format = format || UIUtils.DATE_FORMAT_FOR_DISPLAY;
  return date ? moment(date).format(format) :
    returnTodayDate ? moment().format(format) : date;
}
