"use strict";

import React from "react";
import { BaseAggregateRiskScaleAttribute } from "./base_aggregate_risk_scale_attribute";
import { RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import RiskUtils from "../../../../server/common/misc/common_risk_utils";
export default class AggregateCriticalityAttribute extends BaseAggregateRiskScaleAttribute {
  constructor(props) {
    super(props);

    this.riskType = RISK_TYPE_ENUM.CRITICALITY;
  }

  getValue() {
    let value = null;

    if (this.props.ogligatoryCQA) {
      return null;
    }


    let rawValue = 0;
    let normalizedValue = 0;

    if (this.props.riskInfo?.Criticality) {
      rawValue = this.props.riskInfo.Criticality.value;
      normalizedValue = this.props.riskInfo.Criticality.normalizedValue;
    }

    if (this.props.valueType === "raw") {
      value = rawValue;
    } else {
      value = rawValue ? `${normalizedValue}%` : null;
    }

    if (RiskUtils.riskValueIsNotAssessed(normalizedValue, this.riskType, this.props.RMP)) {
      value = RiskUtils.getRiskLabel(this.riskType, this.props.RMP, normalizedValue, this.props.parent.state, false, true, true);
    }

    return value;
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
    if (!shouldUpdate) {
      let value = this.getNextValue(nextProps);
      shouldUpdate = (this.currentValue !== value) ||
        (nextProps.aggregation !== this.props.aggregation) ||
        (nextProps.riskType !== this.props.riskType) ||
        (nextProps.valueType !== this.props.valueType) ||
        (nextProps.RMP?.id !== this.props.RMP?.id);
    }

    return shouldUpdate;
  }

  getOldValue() {
    let oldValue = null;

    if (this.props.ogligatoryCQA || !this.props.olderRiskInfo?.Criticality) {
      return null;
    }

    const olderRMP = this.props.olderRMP ?? this.props.RMP;

    let oldRawValue = 0;
    let oldNormalizedValue = 0;

    if (this.props.olderRiskInfo?.Criticality) {
      oldRawValue = this.props.olderRiskInfo.Criticality.value;
      oldNormalizedValue = this.props.olderRiskInfo.Criticality.normalizedValue;
    }

    if (this.props.valueType === "raw") {
      oldValue = oldRawValue;
    } else {
      oldValue = oldRawValue ? `${oldNormalizedValue}%` : null;
    }


    if (RiskUtils.riskValueIsNotAssessed(oldNormalizedValue, this.riskType, olderRMP)) {
      oldValue = RiskUtils.getRiskLabel(this.riskType, olderRMP, oldNormalizedValue, this.props.parent.getOlderVersion(), false, true, true);
    }

    return oldValue;
  }
}
