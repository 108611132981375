"use strict";

import React, { memo } from "react";
import { useSidePanel } from "../widgets/side_panel_provider";
import * as styles from "../view/columnSelection/column_selection.module.scss";

const BulkEditFooter = ({disableSubmit, onBulkEdit}) => {

  const {toggle} = useSidePanel();

  return (
    <div className={styles["column-selection-footer"]}>
      <div className="btn-group">
        <button id="saveBulkEdit"
                type="button"
                className="btn btn-primary"
                onClick={onBulkEdit}
                disabled={disableSubmit}
        >
          Save
        </button>
        <button id="cancelBulkEdit"
                type="button"
                className="btn btn-secondary"
                onClick={toggle}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};


export default memo(BulkEditFooter);