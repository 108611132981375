var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Draggable } from '@progress/kendo-react-common';
/**
 * @hidden
 */
var Handle = function (props) { return (React.createElement(Draggable, { onPress: props.onPress, onDrag: function (data) { props.onResize(data.event, false, props.d); }, onRelease: function (data) { props.onResize(data.event, true, props.d); } },
    React.createElement("div", { className: 'k-resize-handle k-cursor-' + props.d + '-resize', style: __assign({ bottom: 0, right: 0 }, props.style) }))); };
/**
 * @hidden
 */
var ResizeHandlers = /** @class */ (function (_super) {
    __extends(ResizeHandlers, _super);
    function ResizeHandlers() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleResize = function (event, isEnd, direction) {
            event.originalEvent.preventDefault();
            _this.props.onResize(event, { end: isEnd, direction: direction });
        };
        return _this;
    }
    ResizeHandlers.prototype.render = function () {
        var _a = this.props, resizable = _a.resizable, onPress = _a.onPress, rtl = _a.rtl;
        if (!resizable) {
            return null;
        }
        return (React.createElement(React.Fragment, null,
            resizable !== 'vertical' && (React.createElement(Handle, { onPress: onPress, onResize: this.handleResize, d: "ew", style: rtl ? { top: 0, width: 9, left: 0, right: '' } : { top: 0, width: 9, right: 0, left: '' } })),
            resizable !== 'horizontal' && (React.createElement(Handle, { onPress: onPress, onResize: this.handleResize, d: "ns", style: { left: 0, height: 9 } })),
            (resizable === true) && (React.createElement(Handle, { onPress: onPress, onResize: this.handleResize, d: rtl ? 'nesw' : 'nwse', style: rtl ? { width: 9, height: 9, right: '', left: 0 } : { width: 9, height: 9, right: 0, left: '' } }))));
    };
    return ResizeHandlers;
}(React.Component));
export { ResizeHandlers };
