"use strict";

import React, { Fragment } from "react";
import ReactDOMServer from "react-dom/server";
import ConfigurableTablesColumnSelectionBase
  from "../../configurableTables/columnSelection/configurable_tables_column_selection_base";
import { SECTIONS } from "../../configurableTables/fieldsConfig/constants/configurable_tables_sections_config";
import {
  COMMON_SITE_LABEL,
  HIDDEN,
  RECEIVING_SITE_LABEL, SENDING_SITE_LABEL
} from "../../configurableTables/fieldsConfig/constants/configurable_tables_columns_config";

export default class TechTransferColumnSelection extends ConfigurableTablesColumnSelectionBase {

  get isTechTransfer() {
    return true;
  }

  get defaultValueProp() {
    return "defaultTechTransferValue";
  }

  renderPopupContent() {
    const {selectedModelType, visibleTableColumns} = this.props;
    return ReactDOMServer.renderToString(
      <div id="columnSelectionTooltipDiv"
           className="tech-transfer-column-selection-tooltip-body"
      >
        {this.renderHeader()}
        <div className="configurable-tables-column-selection-body-container">
          <div className="column-selection-entry">
            <div className="col-8" />
            <div className="col-2 font-weight-bold">Sending</div>
            <div className="col-2 font-weight-bold">Receiving</div>
          </div>
          <div className="column-selection-entry">
            <div className="col-8">
              {selectedModelType} (P1 compared to P2)
            </div>
            <div className="col-2 column-selection-checkbox">
              <input type="checkbox"
                     checked={true}
                     disabled={true}
              />
            </div>
            <div className="col-2 column-selection-checkbox">
              <input type="checkbox"
                     checked={true}
                     disabled={true}
              />
            </div>
          </div>
          {selectedModelType ? SECTIONS[selectedModelType].map((section, index) => {
            const {name, title} = section;
            return (
              <Fragment key={index}>
                {title ?
                  <div className="column-selection-entry-group">
                    {title}
                  </div> : ""}
                {(visibleTableColumns || [])
                  .filter(column => column.section === name && column.showColumnSelectionOn !== HIDDEN)
                  .map((column, index) => {
                    const {id, title, checked, showColumnSelectionOn, columnSelectionTitle, prop} = column;
                    const receivingCol = visibleTableColumns.find(col => col.prop === prop && col.originatedFromSite === RECEIVING_SITE_LABEL);
                    return (
                      <div className="column-selection-entry"
                           key={index}
                      >
                        <div className="col-8">
                          {columnSelectionTitle || title}
                        </div>
                        {[COMMON_SITE_LABEL, SENDING_SITE_LABEL].includes(showColumnSelectionOn) ?
                          <div className="col-2 column-selection-checkbox">
                            <input type="checkbox"
                                   id={`${id}_${SENDING_SITE_LABEL}`}
                                   checked={checked}
                            />
                          </div> : <div className="col-2" />}
                        {[COMMON_SITE_LABEL, RECEIVING_SITE_LABEL].includes(showColumnSelectionOn) ?
                          <div className="col-2 column-selection-checkbox">
                            <input type="checkbox"
                                   id={`${id}_${RECEIVING_SITE_LABEL}`}
                                   checked={receivingCol ? receivingCol.checked : false}
                            />
                          </div> : <div className="col-2" />}
                      </div>
                    );
                  })}
              </Fragment>
            );
          }) : ""}
        </div>
        {this.renderFooter()}
      </div>
    );
  }
}
