"use strict";

import React, {useEffect} from "react";
import * as styles from "./document_editor_toolbar.module.scss";
import {EditorState, FileType} from "./common/constants";
import {getFieldValuesFromRecords, getMaxDate,} from "./utils";
import * as UIUtils from "../ui_utils";
import {getRenderOptions} from "../utils/render_options";
import {CommonUtils} from "../../server/common/generic/common_utils";
import {DocumentRecord} from "../common/models/document";
import {faFilePdf, faFileWord} from "@fortawesome/free-regular-svg-icons";
import DropdownButton from "../widgets/generic/dropdown_button";
import {SmartContent} from "./common/types";
import {can} from "../utils/ui_permissions";
import CommonSecurity from "../../server/common/generic/common_security";

type DocumentEditorToolbarProps = {
  editorState: EditorState;
  editorContent: string;
  smartContent: SmartContent;
  documentRecord: DocumentRecord;
  areAllImagesLoaded: boolean;
  // eslint-disable-next-line no-unused-vars
  onExportToFile: (fileType: FileType) => void;
  onCancelButtonClick: () => void;
  onChangeEditorState: () => void;
}

export default function DocumentEditorToolbar(props: DocumentEditorToolbarProps) {
  const {
    editorState,
    editorContent,
    smartContent,
    documentRecord,
    areAllImagesLoaded,
    onExportToFile,
    onCancelButtonClick,
    onChangeEditorState
  } = props;

  useEffect(() => {
    // A hacking solution to move the Telerik editor toolbar out and put in the top
    // of the page to match with our design. Then we will change the visibility of
    // .k-toolbar to hidden in the css.
    const toolbar = document.querySelector("#toolbar");
    const telerikToolbar = document.querySelector(".k-toolbar");
    if (editorState === EditorState.EDIT && telerikToolbar && toolbar) {
      toolbar.innerHTML = "";
      toolbar.append(telerikToolbar);
    }
  }, [editorState]);

  const renderUpdatedDate = () => {
    // We don't render the updated date if there is no smart content
    const qbdOutput = new DOMParser()
      .parseFromString(editorContent, "text/html")
      .querySelector(".qbd-output");
    if (!qbdOutput || UIUtils.isEmpty(smartContent)) {
      return null;
    }

    const updatedAtOfRecords = getFieldValuesFromRecords(
      smartContent,
      "updatedAt"
    );
    const lastUpdatedAt = getMaxDate(updatedAtOfRecords);
    return (
      <div id="record-last-updated-timestamp" className="ml-4">
        Data from the latest draft of each record as of{" "}
        {lastUpdatedAt.format(UIUtils.LONG_DATE_FORMAT_FOR_DISPLAY)}
      </div>
    );
  };

  const getLatestDocumentRecordVersion = () => {
    if (documentRecord.DocumentVersions && documentRecord.DocumentVersions.length > 0) {
      return documentRecord.DocumentVersions[0];
    }

    return null;
  };

  const getDisplayOptions = () => {
    const latestDocumentRecord = getLatestDocumentRecordVersion();
    return getRenderOptions({
      projectId: documentRecord?.ProjectId,
      processId: documentRecord?.ProcessId,
      canUserEdit: true,
      currentState: documentRecord?.currentState,
      isArchived: documentRecord?.deletedAt,
      isApproved:
        documentRecord?.approved === true ||
        documentRecord?.approved === "true",
      isRestored:
        documentRecord?.currentState ===
        CommonUtils.VERSION_STATES.RESTORED,
      hasApprovedVersion: false,
      isView: editorState === EditorState.VIEW,
      isParentProject: false,
      isParentProcess: false,
      currentDiffingVersion: null,
      currentViewingVersionId: null,
      majorVersion: latestDocumentRecord?.majorVersion,
      minorVersion: latestDocumentRecord?.minorVersion,
      isHistoryShowing: false,
    });
  };

  const shouldDisableEditOrSaveButton = () => {
    // We should disable the Save button if we haven't loaded all the images yet.
    // It will prevent user from saving when the image skeleton is still showing
    if (editorState === EditorState.EDIT) {
      return !areAllImagesLoaded;
    }

    const options = getDisplayOptions();
    const canUserEdit = can(CommonSecurity.Actions.EDIT, CommonSecurity.Types.DOCUMENT_CONTENT);

    return canUserEdit ? (
      documentRecord?.currentState === "Proposed" ||
      options.isProjectArchived ||
      (!options.isParentProject && options.isProcessArchived) ||
      !options.canUserEdit
    ) : true;
  };

  const getEditButtonTitle = () => {
    if (!areAllImagesLoaded) {
      return "Images are loading...";
    }

    const options = getDisplayOptions();
    if (
      options.canUserEdit &&
      documentRecord?.currentState !== "Proposed"
    ) {
      return "";
    }
    return `The Document cannot be modified in ${documentRecord?.currentState?.toLowerCase()} state`;
  };

  const renderExportButtons = () => {
    const options = [
      {key: FileType.PDF, value: " PDF Document (.pdf)", icon: faFilePdf},
      {key: FileType.DOC, value: " Microsoft Word (.doc)", icon: faFileWord},
    ];

    return (
      <DropdownButton
        id="docBuilderExport"
        className="btn-group data-report-split-button"
        options={options}
        text="Export"
        onOptionsSelect={(event) => onExportToFile(event.target.name)}
        isSecondaryButton
      />
    );
  };

  return (
    <div className="col-12">
      <div className="row align-items-center">
        {editorState === EditorState.EDIT && (
          <div id="toolbar" className="col-10"></div>
        )}
        {editorState === EditorState.VIEW && renderUpdatedDate()}
        <div className={`col ${styles["document-editor-button-group"]}`}>
          <div className="row justify-content-end no-gutters">
            <button
              id={
                editorState === EditorState.VIEW
                  ? "editButton"
                  : "saveButtonBottom"
              }
              onClick={onChangeEditorState}
              className="btn btn-lg btn-primary"
              disabled={shouldDisableEditOrSaveButton()}
              title={getEditButtonTitle()}
            >
              {editorState === EditorState.VIEW ? "Edit" : "Save"}
            </button>
            {editorState === EditorState.VIEW && renderExportButtons()}
            {editorState === EditorState.EDIT && (
              <button
                id="cancelButtonBottom"
                onClick={onCancelButtonClick}
                className="btn btn-lg btn-secondary"
              >
                Cancel
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
