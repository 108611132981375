"use strict";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { BaseOptionsMenu } from "../base_options_menu";

/**
 * This class is for producing the meatball button that provide View, Edit, etc options on most tables.
 */
export default class TableOptionsButton extends BaseOptionsMenu {
  constructor(props) {
    super(props);
  }

  render() {
    const {id, alignRight, iconOrientation, className} = this.props;

    return (
      <div className="dropdown">
        <button id={id}
                className={`btn btn-secondary table-options-button ${className}`}
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={"true"}
        >
          <FontAwesomeIcon icon={iconOrientation === "horizontal" ? faEllipsisH : faEllipsisV} />
        </button>
        <ul id={`optionsUl_${id}`} className={`dropdown-menu table-row-options-button${alignRight ? " dropdown-menu-right" : ""}`}>
          {this.renderItems()}
        </ul>
      </div>
    );
  }

}
