"use strict";

import * as UIUtils from "../../../ui_utils";
import moment from "moment-timezone";
import canvasDrawer from "../../../helpers/canvas/risk_map_report_canvas_drawer";
import canvasDownloader from "../../../helpers/canvas/canvas_downloader";
import { RISK_MAP_REPORT_FILTER_TYPE_ENUM } from "../../../helpers/constants/constants";
import { getApprovedStateWarning } from "./risk_map_report_helper";

export function exportReportLayout(rmp, title, projectId, projectName, processId, processName, date, lastApprovedDate, appliedFilters, allItemsInApprovedState, riskType, softwareVersion) {
  let logoImage = new Image(266, 32);
  // We shouldn't be able to export a Risk Map Report in the future!
  date = date.getTime() > new Date() ? new Date() : date;

  logoImage.onload = () => {
    let fontFamily = "Roboto, Open Sans";
    let logoMargin = 5;
    let xLegendMargin = 10;
    let yLegendMargin = 5;
    let filtersIndentation = 10;
    let filtersSpace = 5;
    let legend;
    let legendValue;
    let finalCanvas;

    let margins = {
      left: xLegendMargin,
      top: yLegendMargin,
      right: xLegendMargin,
      bottom: yLegendMargin
    };

    let reportHeaderFontOptions = {
      fontFamily: fontFamily,
      fontSize: "24px"
    };

    let reportSubHeaderFontOptions = {
      fontFamily: fontFamily,
      fontSize: "16px"
    };

    let reportExportedByFontOptions = {
      fontFamily: fontFamily,
      fontSize: "16px"
    };

    // Render a copy of the Risk Map Report
    let graphCanvas = createCanvasWithRiskMapPicture();

    // Draw the QbDVision logo
    let logoCanvas = canvasDrawer.drawLogo(fontFamily, "30px", logoMargin, logoImage);

    // Draw the report title
    legend = title;
    legendValue = "";
    finalCanvas = canvasDrawer.mergeCanvasToBottom(logoCanvas, canvasDrawer.drawLegend(legend, legendValue, reportHeaderFontOptions, margins));

    // Draw the Project info
    legend = UIUtils.getRecordLabelForDisplay("PRJ", projectId, projectName);
    legendValue = "";
    finalCanvas = canvasDrawer.mergeCanvasToBottom(finalCanvas, canvasDrawer.drawLegend(legend, legendValue, reportHeaderFontOptions, margins));

    // Draw the Process info
    legend = "Process: ";
    legendValue = UIUtils.getRecordLabelForDisplay("PR", processId, processName);
    let projectInfoCanvas = canvasDrawer.drawLegend(legend, legendValue, reportSubHeaderFontOptions, margins);

    // Draw the Report date
    legend = "Showing map snapshot as of: ";
    legendValue = UIUtils.getDateForDisplayToUser(date);
    projectInfoCanvas = canvasDrawer.mergeCanvasToBottom(projectInfoCanvas, canvasDrawer.drawLegend(legend, legendValue, reportSubHeaderFontOptions, margins));

    // Draw the last approved date
    legend = "Last Approved Status: ";
    legendValue = (lastApprovedDate ? moment(lastApprovedDate).tz(moment.tz.guess()).format(UIUtils.LONG_DATE_FORMAT_FOR_DISPLAY) : " - ");
    projectInfoCanvas = canvasDrawer.mergeCanvasToBottom(projectInfoCanvas, canvasDrawer.drawLegend(legend, legendValue, reportSubHeaderFontOptions, margins));

    // Draw the risk type
    legend = "Color showing: ";
    legendValue = riskType;
    projectInfoCanvas = canvasDrawer.mergeCanvasToBottom(projectInfoCanvas, canvasDrawer.drawLegend(legend, legendValue, reportSubHeaderFontOptions, margins));

    // Draw the effective RMP
    legend = "Effective RMP: ";
    legendValue = `${rmp.name} V${rmp.majorVersion}.${rmp.minorVersion}`;
    projectInfoCanvas = canvasDrawer.mergeCanvasToBottom(projectInfoCanvas, canvasDrawer.drawLegend(legend, legendValue, reportSubHeaderFontOptions, margins));

    // Draw the Report Filters
    let filtersCanvas1 = null;
    let filtersCanvas2 = null;
    let filtersCanvas3 = null;
    legend = "Report Filters:";
    legendValue = "";
    margins.top = yLegendMargin;
    margins.bottom = 0;
    let reportFiltersHeaderCanvas = canvasDrawer.drawLegend(legend, legendValue, reportSubHeaderFontOptions, margins);
    margins.top = yLegendMargin;

    margins.left = xLegendMargin + filtersIndentation;
    margins.bottom = yLegendMargin / 2;
    margins.top = yLegendMargin / 2;
    if (appliedFilters.links) {
      legend = "Links: ";
      legendValue = appliedFilters.links;
      filtersCanvas1 = canvasDrawer.mergeCanvasToBottom(reportFiltersHeaderCanvas, canvasDrawer.drawLegend(legend, legendValue, reportSubHeaderFontOptions, margins));
    }

    // Render the Elements filter
    filtersCanvas1 = canvasDrawer.mergeCanvasToBottom(
      filtersCanvas1 ? filtersCanvas1 : reportFiltersHeaderCanvas,
      canvasDrawer.drawFiltersArray(RISK_MAP_REPORT_FILTER_TYPE_ENUM.Element, fontFamily, "16px", null, "Elements: ", appliedFilters.elements, margins, filtersSpace));

    // Render the Unit Operations filter
    filtersCanvas1 = canvasDrawer.mergeCanvasToBottom(
      filtersCanvas1 ? filtersCanvas1 : reportFiltersHeaderCanvas,
      canvasDrawer.drawFiltersArray(RISK_MAP_REPORT_FILTER_TYPE_ENUM.UnitOperations, fontFamily, "16px", null, "Unit Operations: ", appliedFilters.unitOperations, margins, filtersSpace),
    );

    // Render the Materials filter
    filtersCanvas1 = canvasDrawer.mergeCanvasToBottom(
      filtersCanvas1 ? filtersCanvas1 : reportFiltersHeaderCanvas,
      canvasDrawer.drawFiltersArray(RISK_MAP_REPORT_FILTER_TYPE_ENUM.Materials, fontFamily, "16px", null, "Materials: ", appliedFilters.mtCategories, margins, filtersSpace),
    );

    margins.top = reportFiltersHeaderCanvas.height + (yLegendMargin / 2);
    filtersCanvas2 = canvasDrawer.drawFiltersArray(RISK_MAP_REPORT_FILTER_TYPE_ENUM.Risk, fontFamily, "16px", null, "Risk: ", appliedFilters.risk, margins, filtersSpace, rmp.configureByType);
    filtersCanvas1 = canvasDrawer.mergeCanvasToRight(filtersCanvas1, filtersCanvas2);

    if (appliedFilters.onlySearched.length > 0) {
      margins.top = reportFiltersHeaderCanvas.height + (yLegendMargin / 2);
      filtersCanvas3 = canvasDrawer.drawFiltersArray(RISK_MAP_REPORT_FILTER_TYPE_ENUM.SpecificElements, fontFamily, "16px", null, "Specific Elements: ", appliedFilters.onlySearched, margins, filtersSpace);
      filtersCanvas1 = canvasDrawer.mergeCanvasToRight(filtersCanvas1, filtersCanvas3);
    }

    projectInfoCanvas = canvasDrawer.mergeCanvasToRight(projectInfoCanvas, filtersCanvas1);
    finalCanvas = canvasDrawer.mergeCanvasToBottom(finalCanvas, projectInfoCanvas);
    finalCanvas = canvasDrawer.mergeCanvasToBottomAndScale(finalCanvas, graphCanvas);

    // Draw the Exported By legend
    margins = {
      left: xLegendMargin,
      top: yLegendMargin,
      right: xLegendMargin,
      bottom: yLegendMargin
    };
    legend = "Exported by: ";
    legendValue = UIUtils.getUserFullName();
    let footerCanvas = canvasDrawer.drawLegend(legend, legendValue, reportExportedByFontOptions, margins);

    // Draw the Exported Date legend
    legend = "Export Date: ";
    legendValue = moment.tz(moment.tz.guess()).format(UIUtils.LONG_DATE_FORMAT_FOR_DISPLAY);
    footerCanvas = canvasDrawer.mergeCanvasToRight(footerCanvas, canvasDrawer.drawLegend(legend, legendValue, reportExportedByFontOptions, margins));

    // Draw the Note legend
    legend = "Note: ";
    legendValue = getApprovedStateWarning(allItemsInApprovedState);
    footerCanvas = canvasDrawer.mergeCanvasToRight(footerCanvas, canvasDrawer.drawLegend(legend, legendValue, reportExportedByFontOptions, margins));

    // Draw the qbdVision software version
    legend = "This report was printed with QbDVision Version: ";
    legendValue = softwareVersion;
    footerCanvas = canvasDrawer.mergeCanvasToBottom(footerCanvas, canvasDrawer.drawLegend(legend, legendValue, reportExportedByFontOptions, margins, "#f2f2f2"));

    finalCanvas = canvasDrawer.mergeCanvasToBottomAndScale(finalCanvas, footerCanvas);

    // Export to png
    finalCanvas.isDrawingMode = false;
    canvasDownloader.downloadCanvas(finalCanvas, "risk_map.png");
  };

  logoImage.src = "/images/logo-full-32.png";
}

export function createCanvasWithRiskMapPicture() {
  let canvasElement = $("canvas")[0];
  let graphCanvas = document.createElement("canvas");
  let graphCtx = graphCanvas.getContext("2d");
  graphCanvas.width = canvasElement.width;
  graphCanvas.height = canvasElement.height;

  // Ensure that the image is fully loaded before drawing
  canvasElement.onload = function() {
    graphCtx.fillStyle = "#FFFFFF";
    graphCtx.fillRect(0, 0, canvasElement.width, canvasElement.height);
    graphCtx.drawImage(canvasElement, 0, 0);
  };

  // Set the source of canvasElement if it relies on an image
  // canvasElement.src = "path_to_image.png";

  return graphCanvas;
}


