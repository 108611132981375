"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import { BaseAggregateRiskScaleAttribute } from "./base_aggregate_risk_scale_attribute";
import { RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import RiskUtils from "../../../../server/common/misc/common_risk_utils";
import { getNormalizedRiskScore, hasNotAssessedRiskScale } from "../../../helpers/risk_helper";

export default class AggregateProcessRiskAttribute extends BaseAggregateRiskScaleAttribute {
  constructor(props) {
    super(props);

    this.riskType = RISK_TYPE_ENUM.PROCESS_RISK;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      return nextProps.capabilityRisk !== this.props.capabilityRisk;
    }

    return true;
  }

  getValue() {
    let value = null;

    const hasNotAssessedScale = hasNotAssessedRiskScale(this.riskType, this.props.RMP);
    if (this.props.riskInfo && (this.props.capabilityRisk || hasNotAssessedScale)) {
      let rawValue = this.props.riskInfo.Criticality.value * UIUtils.parseInt(this.props.capabilityRisk);
      let normalizedValue = getNormalizedRiskScore(this.riskType, this.props.RMP, rawValue);

      if (RiskUtils.riskValueIsNotAssessed(normalizedValue, this.riskType, this.props.RMP)) {
        return RiskUtils.getRiskLabel(this.riskType, this.props.RMP, normalizedValue, this.props.parent.state, false, true, true);
      }

      switch (this.props.valueType) {
        case "raw":
          value = rawValue;
          break;
        case "percentage":
          value = rawValue ? `${normalizedValue}%` : null;
          break;
      }
    }

    return value;
  }

  getOldValue() {
    let oldValue = null;
    let olderVersion = this.props.parent.getOlderVersion();
    const olderRMP = this.props.olderRMP ?? this.props.RMP;

    if (this.props.olderRiskInfo && olderVersion) {
      const oldMaxCriticality = this.props.olderRiskInfo.Criticality.value;
      let oldRawValue = null;
      if (oldMaxCriticality !== null) {
        oldRawValue = oldMaxCriticality * UIUtils.parseInt(olderVersion.capabilityRisk);
      }

      let oldNormalizedValue = getNormalizedRiskScore(this.riskType, olderRMP, oldRawValue);

      if (RiskUtils.riskValueIsNotAssessed(oldNormalizedValue, this.riskType, olderRMP)) {
        return RiskUtils.getRiskLabel(this.riskType, olderRMP, oldNormalizedValue, olderVersion, false, true, true);
      }

      switch (this.props.valueType) {
        case "raw":
          oldValue = oldRawValue;
          break;
        case "percentage":
          oldValue = oldRawValue ? `${oldNormalizedValue}%` : null;
          break;
      }
    }

    return oldValue;
  }
}
