var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { TabStripNavigationItem } from './TabStripNavigationItem';
import { classNames } from '@progress/kendo-react-common';
/**
 * @hidden
 */
var times = function (count) { return Array.apply(null, Array(count)); };
var TabStripNavigation = /** @class */ (function (_super) {
    __extends(TabStripNavigation, _super);
    function TabStripNavigation() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @hidden
     */
    TabStripNavigation.prototype.render = function () {
        var _a = this.props, selected = _a.selected, tabPosition = _a.tabPosition, children = _a.children, onSelect = _a.onSelect, onKeyDown = _a.onKeyDown;
        var tabsCount = React.Children.count(children);
        var childElements = React.Children.toArray(children);
        var tabs;
        if (children) {
            tabs = times(tabsCount)
                .map(function (_, index, array) {
                var tabProps = {
                    active: selected === index,
                    disabled: childElements[index].props.disabled,
                    index: index,
                    title: childElements[index].props.title,
                    first: index === 0,
                    last: index === array.length - 1,
                    onSelect: onSelect
                };
                return (React.createElement(TabStripNavigationItem, __assign({ key: index }, tabProps)));
            });
        }
        var wrapperClasses = classNames('k-tabstrip-items-wrapper', {
            'k-hstack': tabPosition === 'top' || tabPosition === 'bottom',
            'k-vstack': tabPosition === 'left' || tabPosition === 'right'
        });
        var navClasses = classNames('k-tabstrip-items', 'k-reset');
        return (React.createElement("div", { className: wrapperClasses },
            React.createElement("ul", { className: navClasses, role: 'tablist', tabIndex: this.props.tabIndex, onKeyDown: onKeyDown }, tabs)));
    };
    /**
     * @hidden
     */
    TabStripNavigation.propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element)
        ]),
        onSelect: PropTypes.func,
        onKeyDown: PropTypes.func,
        selected: PropTypes.number,
        tabIndex: PropTypes.number
    };
    return TabStripNavigation;
}(React.Component));
export { TabStripNavigation };
