@import "../../../../css/_brand_colors.scss";

.cell-container:empty {
  &::before {
    content: "\2014"; /* unicode for em dash */
    color: $brand-mild-grey;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}

.field-tooltip {
  opacity: 1 !important;
  width: 276px;
}

.column-operation-icon {
  padding: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  color: $brand-medium-grey;

  &:not(.operation-menu-active):hover {
    cursor: pointer;
    color: $brand-medium-grey;
    border-radius: 4px;
    border: 1px solid var(--fill-colours-c-4-stroke-dark, #C0C8CC);
  }
}

.operation-menu-active {
  color: $brand-primary-color !important;
  border-radius: 4px;
  border: 1px solid var(--fill-colours-c-4-stroke-dark, $brand-primary-color)
}

.header-cell {
  :global(.k-link) {
    display: flex;
    align-items: center;

    .header-title {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}