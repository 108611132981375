"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
// noinspection NpmUsedModulesInstalled
import * as PropTypes from "prop-types";
import { ApprovalResponsePopup } from "../../editor/approval/approval_response_popup";
import { AttributeLink } from "../../widgets/generic/attribute_link";
import { createApprovalInfo } from "../../helpers/approval_helper";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { Trans } from "react-i18next";
import BaseReactComponent from "../../base_react_component";
import {
  getApprovalValue,
  handleApprovalChangeValue,
  handleApprovalChangeValues
} from "../../editor/approval/approval_utils";

// i18next-extract-mark-ns-start widgets
export class MarkAsTrainedPopup extends BaseReactComponent {

  constructor(props) {
    super(props);

    this.approvalResponsePopup = null;

    this.handleApprovalChangeValue = handleApprovalChangeValue.bind(this);
    this.handleApprovalChangeValues = handleApprovalChangeValues.bind(this);
    this.getApprovalValue = getApprovalValue.bind(this);

    this.setStateSafely({
      approvalInfo: createApprovalInfo(),
    });
  }

  handlePopupVisibilityChange(visible) {
    if (this.props.onPopupVisibilityChange) {
      this.props.onPopupVisibilityChange(visible);
    }
  }

  handleSendApproval(approve) {
    const {approvalInfo} = this.state;
    const {instance} = this.props;

    let promise = Promise.resolve();
    if (typeof this.props.onSendApproval === "function") {
      promise = promise.then(() => this.props.onSendApproval(instance, approve, approvalInfo));
    }
    return promise
      .catch(UIUtils.defaultFailFunction)
      .finally(() => {
        if (this.approvalResponsePopup) {
          $(this.approvalResponsePopup).modal("hide");
        }
      });
  }

  render() {
    const {instance, t} = this.props;

    return (
      <>
        <ApprovalResponsePopup
          {...this.props}
          modalRef={approvalResponsePopup => {
            return this.approvalResponsePopup = approvalResponsePopup;
          }}
          showRejectButton={false}
          showCommentBox={false}
          onApprovalChangeValue={this.handleApprovalChangeValue}
          onApprovalChangeValues={this.handleApprovalChangeValues}
          onSendApproval={this.handleSendApproval}
          onHideModal={() => this.handlePopupVisibilityChange(false)}
          title={t("Training Signoff")}
          disclaimer={t("I understand that by providing the password below, I am electronically signing indicating that I have read, understood, and trained to all the information in this electronic record.")}
          approveButtonText={t("Submit")}
        >
          <Trans t={t}>
            You are signing off <AttributeLink instance={instance} baseTypeName={"Document"} />:
          </Trans>
        </ApprovalResponsePopup>
      </>
    );
  }
}

MarkAsTrainedPopup.propTypes = {
  ...ApprovalResponsePopup.propTypes,
  onPopupVisibilityChange: PropTypes.func,
  approvalInfo: PropTypes.any,
  onSendApproval: PropTypes.func,
  t: PropTypes.func,
};

MarkAsTrainedPopup.defaultProps = {
  ...ApprovalResponsePopup.defaultProps,
  onSendApproval: () => {
    throw new Error("NotImplemented: onSendApproval");
  },
  t: value => value,
};

// i18next-extract-mark-ns-start widgets
export default I18NWrapper.wrap(MarkAsTrainedPopup, ["documents", "widgets"]);
