"use strict";

import ConfigurableTablesColumnSelectionHandler
  from "../../configurableTables/columnSelection/configurable_tables_column_selection_handler";
import { FIELD_PROPS } from "../../configurableTables/fieldsConfig/constants/configurable_tables_field_props_config";

export default class MaterialLibraryTablesColumnSelectionHandler extends ConfigurableTablesColumnSelectionHandler {

  mapModelTypeColumnRecord(record) {
    if (record.prop === FIELD_PROPS.CATEGORY) {
      return {
        ...record,
        checked: false,
      };
    }
    return record;
  }
}