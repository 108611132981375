"use strict";

import * as UIUtils from "../../ui_utils";
import CanvasDrawerBase, {ALIGNMENT} from "./canvas_drawer_base";


const DEFAULT_HEADER_OPTIONS = {
  fontFamily: "Roboto, Open Sans",
  companyFontSize: "20px",
  headerTextFontSize: "12px",
  fontColor: "black",
  backgroundColor: "#FFFFFF",
  margins: CanvasDrawerBase.resetMargins()
};

const DEFAULT_FOOTER_OPTIONS = {
  fontFamily: "Roboto, Open Sans",
  fontColor: "black",
  logoFontSize: "20px",
  footerTextFontSize: "10px",
  reportMargins: CanvasDrawerBase.resetMargins()
};

export default class ReportCanvasDrawer extends CanvasDrawerBase {

  /**
   * Draws a header that can be used for reports exported as PNGs
   * @param headerOptions A configuration object holding header related information
   */
  static drawReportHeader(headerOptions = {}) {
    const options = {
      ...DEFAULT_HEADER_OPTIONS,
      ...headerOptions
    };

    const {
      reportTitle,
      projectName,
      processLabel,
      exportDate,
      fontFamily,
      companyFontSize,
      headerTextFontSize,
      fontColor,
      backgroundColor,
      margins,
      reportWidth,
      currentState,
      latestVersion,
      modelLabel
    } = options;

    let companyName = UIUtils.getCompany();
    let currentUser = UIUtils.getUserFullName();

    let companyFontOptions = {
      fontFamily: fontFamily,
      fontSize: companyFontSize,
      fontColor: fontColor,
      fontWeight: "bold"
    };

    let headerTextFontOptions = {
      fontFamily: fontFamily,
      fontSize: headerTextFontSize,
      fontColor: fontColor,
      fontWeight: "normal",
      fontStyle: "italic"
    };

    //Draw the company name
    let finalCanvas = this.drawText(companyName, companyFontOptions, margins, backgroundColor);
    let companyNameWidth = finalCanvas.width;

    //Draw the project & process name
    let leftHeaderCanvas = this.drawText(projectName, headerTextFontOptions, margins);
    leftHeaderCanvas = this.mergeCanvasToBottom(leftHeaderCanvas, this.drawText(`Process: ${processLabel}`, headerTextFontOptions, margins));

    //Draw the export date or attribute information
    if (this.includeAttributeInformation()) {
      leftHeaderCanvas = this.mergeCanvasToBottom(leftHeaderCanvas, this.drawText(modelLabel, headerTextFontOptions, margins));
      leftHeaderCanvas = this.mergeCanvasToBottom(leftHeaderCanvas, this.drawText(`Current State: ${currentState} ${latestVersion || ""}`, headerTextFontOptions, margins));
    } else {
      leftHeaderCanvas = this.mergeCanvasToBottom(leftHeaderCanvas, this.drawText(`Export Date: ${exportDate}`, headerTextFontOptions, margins));
    }

    leftHeaderCanvas = this.applyMarginsToCanvas(leftHeaderCanvas, {
      left: 0,
      top: 0,
      right: 30,
      bottom: 0
    });

    //Draw the vertical separator in the report header
    let ctx = leftHeaderCanvas.getContext("2d");
    ctx.fillStyle = "#d3d3d3";
    ctx.fillRect(leftHeaderCanvas.width - 1, 0, 1, leftHeaderCanvas.height);

    //Draw the report title
    let rightHeaderCanvas = this.drawText(reportTitle, headerTextFontOptions, margins); //"Process Capability Dashboard"

    //Draw the exported by
    rightHeaderCanvas = this.mergeCanvasToBottom(rightHeaderCanvas, this.drawText(`Exported By: ${currentUser}`, headerTextFontOptions, margins));

    // Draw the Export Date under Exported By in case attribute information should be included
    if (this.includeAttributeInformation()) {
      rightHeaderCanvas = this.mergeCanvasToBottom(rightHeaderCanvas, this.drawText(`Export Date: ${exportDate}`, headerTextFontOptions, margins));
    }

    rightHeaderCanvas = this.applyMarginsToCanvas(rightHeaderCanvas, {
      left: 30,
      top: 0,
      right: 0,
      bottom: 0
    });

    //Now merge the right part of the report header together
    leftHeaderCanvas = this.mergeCanvasToRight(leftHeaderCanvas, rightHeaderCanvas);

    let headerSpacing = reportWidth - companyNameWidth - leftHeaderCanvas.width + margins.left;

    //And finally the Company name with the right part of the canvas
    finalCanvas = this.mergeCanvasToRight(finalCanvas, leftHeaderCanvas, headerSpacing, ALIGNMENT.BOTTOM);

    return finalCanvas;
  }

  /**
   * Draws a footer that can be used for reports exported as PNGs
   * @param footerOptions A configuration object holding footer related information
   */
  static drawReportFooter(footerOptions) {
    const options = {
      ...DEFAULT_FOOTER_OPTIONS,
      ...footerOptions
    };

    const {
      logoImage,
      fontFamily,
      fontColor,
      logoFontSize,
      footerTextFontSize,
      reportWidth,
      reportMargins
    } = options;

    let footerTextFontOptions = {
      fontFamily: fontFamily,
      fontSize: footerTextFontSize,
      fontColor: fontColor
    };

    let margins = {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0
    };

    let centerOptions = {
      vertical: ALIGNMENT.CENTER,
      horizontal: ALIGNMENT.CENTER
    };

    let logoCanvasCenterOptions = {
      vertical: ALIGNMENT.CENTER,
      horizontal: ALIGNMENT.LEFT
    };

    // Draw software license
    let softwareLicenseText = `This report was generated with QbDVision Version: ${UIUtils.getSoftwareVersion()}`;
    let finalCanvas = this.drawText(softwareLicenseText, footerTextFontOptions, margins,
      "#f5f5f5", reportWidth, 25, centerOptions);

    // Draw the QbDVision logo
    let logoCanvas = this.drawLogo(fontFamily, logoFontSize, 0, logoImage);

    // Draw CONFIDENTIAL INFORMATION
    let confidentialInformationCanvas = this.drawText("CONFIDENTIAL INFORMATION", footerTextFontOptions, margins,
      "white", finalCanvas.width - reportMargins.left - reportMargins.right, 50, centerOptions);

    logoCanvas = this.mergeCanvasOver(confidentialInformationCanvas, logoCanvas, centerOptions, logoCanvasCenterOptions);
    finalCanvas = this.mergeCanvasToBottom(finalCanvas, logoCanvas, 10, ALIGNMENT.CENTER);

    return finalCanvas;
  }
}
