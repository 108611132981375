"use strict";

/* Use this file to add any functionality common across all editables models and specific to the editables
fields.
 */

const CommonUtils = require("../generic/common_utils");
const CommonConstants = require("../generic/common_constants");

module.exports.TECH_TRANSFER_REVIEW_SCREEN = "TechTransferReviewEditor";

module.exports.INITIAL_GENERAL_ATTRIBUTES = [
  "Dosage Form",
  "Dosage Strength",
  "Efficacy",
  "Route of Administration",
  "Safety",
  "Stability",
];

module.exports.PRODUCT_RISK_ASSESSMENT_TYPES = [
  "Preliminary Hazards Analysis (PHA)",
  "Risk Ranking"
];

module.exports.CONTROL_METHOD_CATEGORIES = [
  "Analytical Method",
  "Monitoring",
  "PAT",
  "SOP/Training",
  "Facility Class",
  "Other"
];

module.exports.CONTROL_METHOD_TYPES = [
  "Chemical",
  "Biological",
  "Microbiological",
  "Physico-Chemical",
  "Not Applicable"
];

module.exports.CONTROL_METHOD_SUPPLIER_STATUS = [
  "Pending",
  "Developed",
  "Qualified/Verified",
  "Validated",
  "Transferred"
];

module.exports.INITIAL_TPP_SECTIONS = [
  "Indications and Usage",
  "Dosage and Administration",
  "Dosage Forms and Strengths",
  "Contraindications",
  "Warnings and Precautions",
  "Adverse Reactions",
  "Drug Interactions",
  "Use in Specific Populations",
  "Drug Abuse and Dependence",
  "Overdosage",
  "Description",
  "Clinical Pharmacology",
  "Nonclinical Toxicology",
  "Clinical Studies",
  "References",
  "How Supplied/Storage and Handling",
  "Patient Counseling Information"
];

module.exports.MATERIAL_DRUG_SUBSTANCE_TYPE = [
  "Not Applicable",
  "Synthetic",
  "Semi-Synthetic",
  "Biological"
];

module.exports.MATERIAL_REGULATORY_FILING = [
  "Type I DMF",
  "Type II DMF",
  "Type III DMF",
  "MAF",
  "BMF",
  "Not Applicable"
];

module.exports.MATERIAL_CATEGORIES = [
  "Biologically-derived (animal/human origin)",
  "Biologically-derived (non-animal/non-human origin)",
  "Buffer",
  "Buffer Component",
  "Cell Bank",
  "Container Closure",
  "Device",
  "Drug Substance (API)",
  "Excipient",
  "Feed",
  "Feed Component",
  "Liquids Chemicals Gases",
  "Master Cell Bank",
  "Media",
  "Media Component",
  "Other Material",
  "Packaging/Labeling",
  "Plasmid",
  "Source Raw Material",
  "Tissue",
  "Working Cell Bank"
];

module.exports.QUALITY_ATTRIBUTES_CATEGORIES = [
  "Biological Activity",
  "Biological Activity - Potency",
  "Container Closure",
  "Device",
  "Drug Product",
  "Drug Substance",
  "General",
  "Human Factors",
  "Identity",
  "Immunochemical",
  "Other Quality",
  "Packaging",
  "Performance",
  "Physicochemical",
  "Physicochemical - Composition",
  "Physicochemical - Quantity",
  "Physicochemical - Strength",
  "Physicochemical - Structure",
  "Purity",
  "Purity - Adventitious Agents",
  "Purity - Contaminants",
  "Purity - Process Impurities",
  "Purity - Product Impurities",
  "Purity - Product Variants",
  "Safety"
];

module.exports.FINAL_ATTRIBUTE_CONTROL_STRATEGY_ENUM = {
  RELEASE_TESTING: "Release Testing",
  MONITORING_DEFINED_LIMITS: "Monitoring (Defined Limits)",
  MONITORING_REPORT_RESULTS: "Monitoring (Report Results)",
  CHARACTERIZATION: "Characterization",
  IN_PROCESS_TESTING: "In-Process Testing",
  IN_PROCESS_CONTROL: "In-Process Control",
  NOT_REQUIRED: "Not Required",
};

module.exports.FINAL_ATTRIBUTE_CONTROL_STRATEGY_OPTIONS = Object.values(exports.FINAL_ATTRIBUTE_CONTROL_STRATEGY_ENUM);

module.exports.INTERMEDIATE_ATTRIBUTE_CONTROL_STRATEGY_OPTIONS = ["Characterization",
  "In-Process Monitoring (Defined Limits)",
  "In-Process Monitoring (Report Results)",
  "In-Process Control",
  "Test Upon Completion - IPT",
  "Test Upon Completion - IPC",
  "Other",
  "Not Required"];

module.exports.MATERIAL_ATTRIBUTE_CONTROL_STRATEGY_OPTIONS = ["Characterization",
  "Monitoring (COA)",
  "Monitoring (Test on Receipt)",
  "Other",
  "Not Required"];

module.exports.MATERIAL_FORM_OPTIONS = ["Solid",
  "Liquid",
  "Gas"];

module.exports.CONTROL_STRATEGY_DESCRIPTION = {
  "Release Testing": "Test on attribute is conducted at the end of the manufacturing process and must meet the specification as a condition of release.",
  "Monitoring (Defined Limits)": "Parameter has defined limits and is monitored via testing at the end of the manufacturing process.",
  "Monitoring (Report Results)": "Parameter does not have defined limits but is monitored via testing at the end of the manufacturing process to determine the range of values.",
  "Characterization": "Variable requires additional characterization to determine interactions, design space, optimal target and range, etc.",
  "In-Process Testing": "Test on attribute is conducted prior to the end of the manufacturing process and must meet the specification as a condition of release.",
  "In-Process Monitoring (Defined Limits)": "Variable is monitored in some way to ensure it remains within the limits defined",
  "In-Process Monitoring (Report Results)": "Variable is monitored in some way to understand how it varies.",
  "In-Process Control": "Variable or attribute that has an impact on a downstream IQA or FQA.",
  "Test Upon Completion - IPT": "Attribute is tested at the completion of the unit operation because it is an In-Process Test for a downstream variable like an FQA",
  "Test Upon Completion - IPC": "Attribute is tested at the completion of the unit operation because it is an In-Process Control for a downstream variable like an FQA",
  "Monitoring (COA)": "Attribute values are tracked from the COAs received for each lot",
  "Monitoring (Test on Receipt)": "Attribute values are determined from testing of material after receipt from supplier",
  "Other": "",
  "Not Required": "No control strategy is required for this variable or attribute."
};

module.exports.DRUG_PRODUCT_QUALITY_ATTRIBUTES_CATEGORIES = [
  "General",
  "Drug Substance",
  "Drug Product",
  "Container Closure",
  "Device"
];

module.exports.FINAL_ATTRIBUTE_SCOPE = [
  "Drug Substance",
  "Drug Product",
];

module.exports.COMPENDIAL_STANDARD = [
  "USP",
  "NF",
  "BP",
  "EP",
  "JP",
  "CP",
  "Other",
];

module.exports.BOOLEAN_VALUES = [
  "Yes",
  "No",
];

/**
 * Returns true if the lower limit for the acceptance criteria of an attribute is disabled, given it's measure.
 * @param measure The measure value of the attribute.
 * @returns {boolean|*}
 */
module.exports.isLowerLimitDisabled = function(measure) {
  return measure === CommonUtils.MEASUREMENT_TYPES.UPPER_LIMIT || exports.isQualitativeMeasure(measure);
};

/**
 * Returns true if the upper limit for the acceptance criteria of an attribute is disabled, given it's measure.
 * @param measure The measure value of the attribute.
 * @returns {boolean}
 */
module.exports.isUpperLimitDisabled = function(measure) {
  return measure === CommonUtils.MEASUREMENT_TYPES.LOWER_LIMIT || measure === CommonUtils.MEASUREMENT_TYPES.CONFORMS;
};

/**
 * Returns true if the measure is any of the Qualitative measure types.
 * @param measure The measure value of the attribute.
 * @returns {boolean}
 */
module.exports.isQualitativeMeasure = function(measure) {
  return measure === CommonUtils.MEASUREMENT_TYPES.DEFECTS || measure === CommonUtils.MEASUREMENT_TYPES.CONFORMS;
};

/**
 * Returns true if the measure is any of the Quantitative measure types.
 * @param measure The measure value of the attribute.
 * @returns {boolean}
 */
module.exports.isQuantitativeMeasure = function(measure) {
  return measure === CommonUtils.MEASUREMENT_TYPES.UPPER_LIMIT
    || measure === CommonUtils.MEASUREMENT_TYPES.LOWER_LIMIT
    || measure === CommonUtils.MEASUREMENT_TYPES.RANGE;
};

/**
 * Returns true if the measure if of Conforms type.
 * @param measure The measure value of the attribute.
 * @returns {boolean}
 */
module.exports.isConformsMeasure = function(measure) {
  return measure === CommonUtils.MEASUREMENT_TYPES.CONFORMS;
};

/**
 * Returns an object with the validation-related props for a given acceptance criteria field.
 * @param measure {CommonUtils.MEASUREMENT_TYPES|string} The measurement type used to retrieve the validation for.
 * @param fieldName {string} The name of the field.
 * @param rangeData {*} The state or row data that contains the information about that acceptance criteria range.
 */
module.exports.getAcceptanceCriteriaValidationProps = function(measure, fieldName, rangeData) {
  // Ensures that we have either a number or null
  const upperLimit = CommonUtils.isNumber(rangeData.upperLimit) ? Number(rangeData.upperLimit) : null;
  const lowerLimit = CommonUtils.isNumber(rangeData.lowerLimit) ? Number(rangeData.lowerLimit) : null;
  const target = CommonUtils.isNumber(rangeData.target) ? Number(rangeData.target) : null;

  let props = {};

  // Ensures we can handle qualitative measures differently.
  const isQualitative = exports.isQualitativeMeasure(measure);

  switch (fieldName) {
    case "lowerLimit":
      /*
       * Lower Limit:
       *
       * Quantitative:
       *  - Must be a number
       *  - Can be negative (e.g.: -30ºC)
       *  - Must be lesser than or equal to the target
       *
       * Qualitative:
       *  - Must be a number
       *  - Must be positive or zero
       *  - Must be lesser than or equal to 100% or the target (whichever is lesser)
       */
      props = {
        type: "number",
        min: isQualitative ? 0 : Number.MIN_SAFE_INTEGER,
        max: isQualitative
          ? (
            target === 0 || target
              ? Math.min(target || 0, 100)
              : 100
          )
          : (
            upperLimit === 0 || upperLimit
              ? Math.min(upperLimit, (target || Number.MAX_SAFE_INTEGER))
              : Number.MAX_SAFE_INTEGER
          ),
        step: "any",
      };
      break;
    case "upperLimit":
      /*
       * Upper Limit:
       *
       * Quantitative:
       *  - Must be a number
       *  - Can be negative (e.g.: -30ºC)
       *  - Must be greater than or equal to the target
       *
       * Qualitative:
       *  - Must be a number
       *  - Must be positive
       *  - Must be greater than or equal to 1% or the target (whichever is greater)
       */
      props = {
        type: "number",
        min: (
          isQualitative
            ? (
              (target === 0 || target)
                ? Math.max(target || Number.MIN_SAFE_INTEGER, 1)
                : 1
            )
            : (
              (lowerLimit === 0 || lowerLimit)
                ? Math.max(lowerLimit, (target || Number.MIN_SAFE_INTEGER))
                : ((target === 0 || target) ? target : null)
            )
        ),
        max: isQualitative ? 100 : null,
        step: "any",
      };
      break;
    case "target":
      /*
       * Target:
       *
       * Quantitative:
       *  - Must be a number
       *  - Can be negative (e.g.: -30ºC)
       *  - Must be greater than or equal to the lower limit
       *  - Must be lesser than or equal to the upper limit
       *
       * Qualitative:
       *  - Must be a number or a text (text is only supported for Conforms)
       *  - In case it is a number (Defects):
       *    -- Must be positive or zero
       *    -- Must be greater than or equal to 0% or the lower limit (whichever is greater)
       *    -- Must be lesser than or equal to 100% or the upper limit (whichever is lesser)
       */
      if (measure === CommonUtils.MEASUREMENT_TYPES.CONFORMS) {
        props = {
          type: "text",
        };
      } else {
        props = {
          type: "number",
          min: isQualitative ? 0 : lowerLimit,
          max: (
            isQualitative
              ? ((upperLimit === 0 || upperLimit) ? Math.min(upperLimit, 100) : 100)
              : ((upperLimit === 0 || upperLimit) ? upperLimit : null)
          ),
          step: "any",
        };
      }
      break;
  }

  if (props.min === null) {
    delete props.min;
  }
  if (props.max === null) {
    delete props.max;
  }
  return props;
};

module.exports.maxDescriptionLengthForName = 25;

/**
 * This is used to truncate the description field of a record so that it can be displayed in columns. Mainly used
 * by batches, where we need to display the description of a batch record in the name column, across the software.
 * See (QI-4097).
 * @param description The description text.
 * @returns {string}
 */
module.exports.trimDescriptionForDisplay = function(description) {
  return description
    ? `${description.slice(0, exports.maxDescriptionLengthForName)}${description.length > exports.maxDescriptionLengthForName ? "..." : ""}`
    : "";
};

/**
 * Determines whether a record is in a state that allows it to be self-approved.
 * @param instance {*} The object to be checked if it can be approved.
 * @param action {"approve"|"archive"|"restore"} The action that we are checking if can be approved.
 * @param modelName {string} If set, uses that model instead of trying to retrieve it from the instance.
 * @return {boolean} The result of the verification.
 */
module.exports.canBeSelfApproved = function(instance, action, modelName) {
  // If some of the required information is not present, we assume it cannot be self-approved.
  if (!modelName) {
    console.warn("Model name specified. Assuming the type cannot be self approved.", modelName);
    return false;
  }

  const isProposeForArchive = action === CommonConstants.ACTIONS.ARCHIVE;
  const modelDeclaration = CommonUtils.modelFinder.findFromModelName(modelName);

  if (!modelDeclaration && !instance) {
    console.warn("Instance not specified, or model name is invalid. Assuming the type cannot be self approved.", modelName, instance);
    return false;
  }

  return (
    (
      // We can self-approve the archival of an instance that has no approved version
      instance && isProposeForArchive && (
        // Verifies version records to check whether their version is approved or not.
        instance.majorVersion === 0
        // if majorVersion is not set or is null (this is likely not a version record),
        // it will try to get the LastApprovedVersionId if available
        || (
          (typeof instance.majorVersion === "undefined" || instance.majorVersion === null)
          && !instance.LastApprovedVersionId && !instance.lastApprovedVersionId
        )
      )
    )
    // If both fails, it will try to check in the model declaration if the model can be self-approved
    // (like the Individual Training Plans, for example).
    || (modelDeclaration && modelDeclaration.selfApprovable)
  );
};

/**
 * Get comman separated values from an array of strings.
 *
 * @param {string} val
 * @returns {string} Comma separated values of strings
 */
module.exports.transformMultiValueForDisplay = function(val) {
  if (!val) {
    return "";
  }

  if (val.startsWith("[")) {
    return JSON.parse(val).join(", ");
  }

  return val;
};

module.exports.APPLIES_TO_ID_TO_DISPLAY_NAME = {
  "name": "Name",
  "category": "Category",
  "gmp": "gmp",
  "description": "Description",
  "function": "Function",
  "use": "Use",
  "process": "Process",
  "unitOperations": "Unit Operations",
  "steps": "Steps",
  "descriptiveUnitAbsolute": "Descriptive Unit (Absolute)",
  "quantityAbsolute": "Quantity (Absolute)",
  "quantityRelative": "Quantity (Relative)",
  "supplier": "Supplier",
  "materialQualified": "Material/Part Qualified",
  "partNumber": "Supplier Part Number",
  "internalPartNumber": "Internal Part Number",
  "effectiveDate": "Effective Date",
  "expirationDate": "Expiration Date",
  "regulatoryFiling": "Regulatory Filing",
  "referenceNumber": "Reference Number",
  "authorizationLetter": "Letter of Authorization",
  "drugSubstanceType": "Drug Substance Type",
  "chemicalStructure": "Chemical Structure",
  "empiricalFormula": "Empirical Formula",
  "molecularWeight": "Molecular Weight",
  "chemicalNameCAS": "Chemical Name (CAS)",
  "chemicalNameIUPAC": "Chemical Name (IUPAC)",
  "otherNames": "Other Names",
  "innUsan": "INN/USAN",
  "casRegistryNumber": "CAS Registry Number",
  "compendialStandard": "Compendial Standard",
  "certificateOfAnalysis": "COA/COC",
  "references": "References",
  "standards": "Standards",
  "guidances": "Guidances",
  "acceptanceTesting": "Acceptance Testing",
  "qualificationStatus": "Qualification Status",
  "unitId": "Unit ID",
  "calibration": "Calibration",
  "drugProductContact": "Drug Product Contact",
  "contactRisk": "Contact Risk",
  "contactRiskJustification": "Contact Risk Justification",
  "cleaningValidation": "Cleaning Validation",
  "sterilizationValidation": "Sterilization Validation",
  "detectabilityRisk": "Detectability Risk",
  "rPN": "RPN (Raw)",
  "rPNPercentage": "RPN (%)",
  "detectabilityJustification": "Detectability Justification",
  "controlStrategy": "Control Strategy",
  "ccp": "CCP",
  "controlStrategyJustification": "Control Strategy Justification",
  "dataSpace": "Data Space",
  "measure": "Measure",
  "ControlMethod": "Control Methods",
  "samplingPlan": "Sampling Plan",
  "lowerOperatingLimit": "LOL",
  "upperOperatingLimit": "UOL",
  "lowerLimit": "LSL",
  "target": "Target",
  "upperLimit": "USL",
  "measurementUnits": "Measurement Units",
  "targetJustification": "Justification",
  "Default criteria": "Default criteria",
};
