"use strict";

import { ServiceBase } from "../service_base";

/**
 * @typedef IImportServiceParams {Object}
 * @property {boolean} [useTwoWayCommunication]
 * @property {boolean} [idempotent]
 * @property {boolean} [clearUIErrors]
 * @property {boolean} [global]
 * @property {Map<string, string>} [statusMap]
 * @property {boolean} [autoClose]
 */

/**
 * Service layer for Import functionality. Use this to execute import operations on the backend.
 */
export class BulkUploadService extends ServiceBase {
  /**
   * Bulk upload files
   * @param payload The payload for the request
   */
  bulkUpload(payload) {
    let parameters = {
      global: false,
      action: "bulkPreSignedURLs",
      useTwoWayCommunication: true,
    };

    return this.sendToBackend(payload, parameters);
  }
}
