"use strict";

import React, { memo } from "react";
import { BooleanIcon } from "./widgets/boolean_icon";
import * as styles from "./style/boolean_cell.module.scss";
import * as commonStyles from "./style/common_cell.module.scss";

export const BooleanCell = memo(({value, className}) => {
  return (
    <td className={className}>
      <div className={`${commonStyles["cell-container"]} ${styles["boolean-cell-container"]}`}>
        <BooleanIcon value={value} />
        <span>{value}</span>
      </div>
    </td>
  );
});