"use strict";

import { DataAdapter } from "./data_adapter";
import * as RiskTablesParser from "../../utils/risk_tables_parser";
import * as CommonRiskTable from "../../utils/risk_tables_helper";
import { getMaxCriticality, getRawRiskScore } from "../../../../helpers/risk_helper";
import { RISK_TYPE_ENUM } from "../../../../helpers/constants/constants";
import { getReportSettings } from "../../utils/risk_tables_helper";
import { orderAndIndexSteps } from "../../../../processExplorer/indexers/uo_indexer";
import * as UIUtils from "../../../../ui_utils";
import * as CommonEditablesFormatter from "../../../../../server/common/editables/common_editables_formatter";

/**
 * This is the parent data adapter for pivot tables.
 * It will contain any common functionality for preparing and transforming from backend model structure
 * to the table view model structure.
 */
export class PivotDataAdapter extends DataAdapter {

  constructor({reportKey, steps, unitOperations, reportData}) {
    super(reportData, steps, unitOperations);
    this.reportKey = reportKey;
  }

  convertToRiskTableDataModel(showCriticalOnly, rmp) {
    let criticalSortedReportData = super.convertToRiskTableDataModel(showCriticalOnly, rmp);
    let tableDataModel = this._toPivotTableDataModelFromRawData(criticalSortedReportData, rmp);
    return {tableDataModel, criticalSortedReportData};
  }


  /**
   * This is the main function to convert to pivot table object model.
   * @param originalData
   * @returns {[]}
   * @private
   */
  _toPivotTableDataModelFromRawData(originalData, rmp) {
    let uoIdToTableMap = new Map();
    let uoIdToColumnsMap = new Map();
    let locationToColumnsMap = new Map();
    let data = [];

    for (let i = 0; i < originalData.length; i++) {
      const row = originalData[i];
      let newRow = {};
      let rowId = row["targetModelId"];
      let columnId = row["sourceModelType"] + "-" + row["sourceModelId"];
      let {stepId} = row;
      let unitOperationId = row.groupByUOId ? row.groupByUOId : -1;
      let unitOperationState = row.groupByUOCurrentState ? row.groupByUOCurrentState : null;
      let unitOperation = row.groupByUO ? row.groupByUO : null;
      let unitOperationPreviousUnitId = row.groupByUOPreviousUnitId ? row.groupByUOPreviousUnitId : null;
      let rowKey = row.targetModelType ? `${row.targetModelType}-${rowId}` : "";
      let pivotTable;
      let unitOperationDynamicColumns;
      let locationId = CommonRiskTable.getLocationId(unitOperationId, stepId);

      if (!uoIdToTableMap.has(unitOperationId)) {
        uoIdToTableMap.set(unitOperationId, {
          unitOperationId: unitOperationId,
          unitOperation: unitOperation,
          unitOperationState: unitOperationState,
          unitOperationPreviousUnitId: unitOperationPreviousUnitId,
          columnAttributes: {},
          table: new Map(),
        });
      }

      if (!uoIdToColumnsMap.has(unitOperationId)) {
        uoIdToColumnsMap.set(unitOperationId, new Map());
      }

      if (!locationToColumnsMap.has(locationId)) {
        locationToColumnsMap.set(locationId, new Set().add(columnId));
      } else {
        locationToColumnsMap.get(locationId).add(columnId);
      }

      pivotTable = uoIdToTableMap.get(unitOperationId);
      unitOperationDynamicColumns = uoIdToColumnsMap.get(unitOperationId);

      if (!unitOperationDynamicColumns.has(columnId)) {
        unitOperationDynamicColumns.set(columnId, row["sourceModel"]);
      }

      if (!pivotTable.columnAttributes[columnId]) {
        pivotTable.columnAttributes[columnId] = {
          headerText: row["sourceModel"],
          modelType: row.sourceModelType,
          sourceModelId: row["sourceModelId"],
          sourceModelType: row["sourceModelType"],
          sourceRiskInfo : row["sourceRiskInfo"],
          recommendedActions: row.sourceModelRecommendedActions,
          controlStrategy: UIUtils.secureString(CommonEditablesFormatter.formatControlStrategyForDisplay(row.sourceModelControlStrategy)),
          controlStrategyJustification: row.sourceModelControlJustification,
          controlMethods: RiskTablesParser.parseControlMethodString(row.sourceModelControlMethods),
          potentialFailureModes: row.sourceModelPotentialFailureModes,
          scaleDependent: row.sourceModelScaleDependent,
          scaleJustification: row.sourceModelScaleJustification,
          columnState: row.sourceModelCurrentState,
          isLastVersion: row.isSourceModelLastVersion,
          versionId: row.sourceModelVersionId,
          majorVersion: row.sourceModelMajorVersion,
          minorVersion: row.sourceModelMinorVersion,
          unitOperationId: row.sourceUOId ? row.sourceUOId : null,
          unitOperation: row.sourceUO ? row.sourceUO : null,
          unitOperationState: row.sourceUOCurrentState ? row.sourceUOCurrentState : null,
          stepId: row.stepId ? row.stepId : null,
          step: row.stepName ? row.stepName : null,
          riskLinks: [],
          capabilityRisk: row.sourceModelCapabilityRisk,
          detectabilityRisk: row.sourceModelDetectabilityRisk,
          sourceParentModel: row.sourceParentModel,
          sourceParentModelId: row.sourceParentModelId,
          sourceParentModelType: row.sourceParentModelType,
          riskInfo: row.sourceRiskInfo,
        };
      }
      const columnAttribute = pivotTable.columnAttributes[columnId];

      if (pivotTable.table.has(rowKey)) {
        newRow = pivotTable.table.get(rowKey);
      } else {
        newRow["targetModel"] = row["targetModel"];
        newRow["targetModelId"] = rowId;
        newRow["targetRiskInfo"] = row["targetRiskInfo"];
      }

      if (!newRow.targetModelId) {
        continue;
      }

      let riskLink = {
        impact: row.impact,
        uncertainty: row.uncertainty,
      };
      columnAttribute.riskLinks.push(riskLink);

      newRow["impact_" + columnId] = row.impact;
      newRow["uncertainty_" + columnId] = row.uncertainty;
      newRow["justification_" + columnId] = row.justification;
      newRow["effect_" + columnId] = row.effect;
      newRow["linkRiskInfo_" + columnId] = row.linkRiskInfo;

      newRow[columnId] = getRawRiskScore(RISK_TYPE_ENUM.CRITICALITY, rmp, riskLink, riskLink.detailedRiskLinks);
      newRow["targetModelCurrentState"] = row.targetModelCurrentState;
      newRow.unitOperationId = row.groupByUOId ? row.groupByUOId : null;
      newRow.unitOperation = row.groupByUO ? row.groupByUO : null;
      newRow.targetVersionId = row.targetModelVersionId;
      newRow.targetMajorVersion = row.targetModelMajorVersion;
      newRow.targetMinorVersion = row.targetModelMinorVersion;
      newRow.isTargetLastVersion = row.isTargetModelLastVersion;
      newRow.targetModelType = row.targetModelType;

      newRow.targetRiskInfo = row.targetRiskInfo;
      newRow.sourceParentModelType = row.sourceParentModelType;
      newRow.sourceParentModelId = row.sourceParentModelId;
      newRow.sourceParentModel = row.sourceParentModel;
      newRow.sourceRiskInfo = row.sourceRiskInfo;

      newRow.targetParentModelType = row.targetParentModelType;
      newRow.targetParentModelId = row.targetParentModelId;
      newRow.targetParentModel = row.targetParentModel;

      newRow.riskInfo = row.sourceRiskInfo;

      pivotTable.table.set(rowKey, newRow);
    }

    // Find the list of unit operations
    let uoIdToIndexMap = new Map(this.unitOperations.map((uo, uoIndex) => [uo.id, uoIndex]));

    // Sort the tables by UO order
    let sortedUOIdToTableMap = new Map([...uoIdToTableMap.entries()].sort((a, b) => {
      return uoIdToIndexMap.get(a[0]) - uoIdToIndexMap.get(b[0]);
    }));

    for (const [uoId, table] of sortedUOIdToTableMap) {
      let uoOrderedSteps = this._getOrderedSteps(uoId);
      const sortedColumns = CommonRiskTable.getSortedMap(uoId, uoOrderedSteps, uoIdToColumnsMap.get(uoId), locationToColumnsMap, getReportSettings(this.reportKey));
      const columnAttributes = {};

      for (let [name, columnAttribute] of Object.entries(table.columnAttributes)) {
        columnAttribute.maxRiskLinks = columnAttribute.riskLinks.filter(
          link => getRawRiskScore(RISK_TYPE_ENUM.CRITICALITY, rmp, link, link.detailedRiskLinks) === getMaxCriticality(columnAttribute.riskLinks, rmp)
        );

        columnAttributes[name] = columnAttribute;
      }

      let pivotTableData = {
        unitOperationId: table.unitOperationId,
        unitOperation: table.unitOperation,
        unitOperationState: table.unitOperationState,
        uoIdToColumnsMap: sortedColumns,
        data: [],
        columnAttributes,
      };

      for (let value of table.table.values()) {
        pivotTableData.data.push(value);
      }

      data.push(pivotTableData);
    }

    return data;
  }

  _getOrderedSteps(unitOperationId) {
    return orderAndIndexSteps(
      this.steps.filter(step => step.UnitOperationId === unitOperationId)
    );
  }
}
