var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Input } from '@progress/kendo-react-inputs';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { messages, filterTextFilterAriaLabel } from '../../messages';
/**
 * The TextFilter component used for editing text value of FilterDescriptor object.
 */
var TextFilter = /** @class */ (function (_super) {
    __extends(TextFilter, _super);
    function TextFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onChange = function (event) {
            _this.props.onFilterChange.call(undefined, {
                nextFilter: __assign(__assign({}, _this.props.filter), { value: event.value })
            });
        };
        return _this;
    }
    /**
     * @hidden
     */
    TextFilter.prototype.render = function () {
        var locService = provideLocalizationService(this);
        var _a = this.props.ariaLabel, ariaLabel = _a === void 0 ? locService.toLanguageString(filterTextFilterAriaLabel, messages[filterTextFilterAriaLabel]) : _a;
        return (React.createElement(Input, { value: this.props.filter.value || '', onChange: this.onChange, ariaLabel: ariaLabel }));
    };
    /**
     * @hidden
     */
    TextFilter.propTypes = {
        filter: PropTypes.object.isRequired,
        onFilterChange: PropTypes.func.isRequired,
        ariaLabel: PropTypes.string
    };
    return TextFilter;
}(React.Component));
export { TextFilter };
registerForLocalization(TextFilter);
