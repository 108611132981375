"use strict";

import * as UIUtils from "../ui_utils";
import React, { Fragment } from "react";
import BasePage from "../base_page";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import LibraryTablePage from "./library_table_page";
import CommonEditablesPageTitleBar from "../widgets/pageTitleBar/common_editables_page_title_bar";
import { process } from "@progress/kendo-data-query";
import { LIBRARY_MODELS_CONFIG } from "./tables/library_tables_config";
import { filterFields } from "../configurableTables/columnSelection/configurable_tables_column_selection_helper";
import { Converters } from "../../server/common/generic/common_converters";
import { MaterialLibraryConfigurableTablesExport } from "./export/material_library_configurable_tables_export";
import TableTitleBar from "../configurableTables/tables/widgets/topBar/titleBar/table_title_bar";
import { NotificationProvider } from "../configurableTables/tables/widgets/notification/notification_provider";
import InfoNotification from "../configurableTables/tables/widgets/notification/types/info_notification";
import { Button } from "@qbdvision-inc/component-library";


export const DEFAULT_CATEGORY = "Buffer";

/**
 * The Material Library List page.
 */
class LibraryPage extends BasePage {
  constructor(props) {
    super(props);

    const {t} = this.props;

    const selectedModelType = "Library Materials";
    const {modelName} = LIBRARY_MODELS_CONFIG[selectedModelType];
    this.setStateSafely({
      breadcrumb: {
        current: t("Library"),
      },
      showArchived: Converters.toBoolean(UIUtils.getParameterByName("showArchived") || "false"),
      modelName,
      selectedModelType
    });
  }

  componentDidMount() {
    const {t} = this.props;
    document.title = t("QbDVision Library");

    super.componentDidMount();
  }

  handleVisibleColumnsChange(visibleTableColumns) {
    this.setStateSafely({visibleTableColumns});
  }

  handleExport() {
    const {
      filter,
      selectedModelType,
      tableData,
      showArchived,
      visibleTableColumns,
      columnOperationsAdapter,
    } = this.state;

    let records = process(tableData.slice(0),
      columnOperationsAdapter.adaptFilterNames(this.state));

    /**
     * Filter Library Materials by category.
     */
    records = records.data.filter(record => record.category === filter);

    new MaterialLibraryConfigurableTablesExport({
      records,
      selectedModelType,
      modelsConfig: LIBRARY_MODELS_CONFIG,
      feature: "Library",
      projectName: "NA",
      showArchived,
      visibleTableColumns: filterFields(visibleTableColumns || []),
    }).exportReport();
  }

  handleTableStateChange(tableState) {
    this.setStateSafely({tableState});
  }

  handleUpdateTableDependencies(dependencies) {
    this.setStateSafely(dependencies);
  }

  handleTableDataUpdated(tableData) {
    this.setStateSafely({tableData});
  }

  resetTableFilters(filter = DEFAULT_CATEGORY) {
    this.handleFilterChange(filter);
  }

  handleFilterChange(filter) {
    this.setStateSafely({filter});
    UIUtils.pushHistoryURLWithNewParameter({}, "category", filter);
  }

  handleShowArchive(showArchived) {
    this.setStateSafely({showArchived});
    UIUtils.pushHistoryURLWithNewParameter({}, "showArchived", showArchived);
  }

  handleImport() {
    const url = "/import/importFile.html?key=LIBRARY_MATERIAL";
    window.location.href = UIUtils.getSecuredURL(url);
  }

  renderPage() {

    const {
      filter,
      modelName,
      showArchived,
      selectedModelType,
    } = this.state;

    const {t} = this.props;

    return (
      <Fragment>
        <CommonEditablesPageTitleBar name={t("Library")} hasNoLinksInTitleBar={true} />
        <NotificationProvider>
          <TableTitleBar t={t}
                         modelName={modelName}
                         selectedModelType={selectedModelType}
                         table={selectedModelType}
                         onExport={this.handleExport}
                         moreActions={
                           <Button id="importButton"
                                   label={t("Import")}
                                   size={"small"}
                                   type="secondary"
                                   onClick={this.handleImport}
                           />
                         }
          />
          <LibraryTablePage
            filter={filter}
            modelName={modelName}
            selectedModelType={selectedModelType}
            showArchived={showArchived}
            onShowArchiveChange={this.handleShowArchive}
            onCategoryChange={this.resetTableFilters}
            resetTableFilters={this.resetTableFilters}
            onTableStateChange={this.handleTableStateChange}
            onUpdateTableDependencies={this.handleUpdateTableDependencies}
            onVisibleColumnsChange={this.handleVisibleColumnsChange}
            onTableDataUpdated={this.handleTableDataUpdated}
          />
          <InfoNotification />
        </NotificationProvider>
      </Fragment>
    );
  }
}


export default I18NWrapper.wrap(LibraryPage, "library");
