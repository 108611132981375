import { getNestedValue } from './misc';
/**
 * @hidden
 */
var FieldsService = /** @class */ (function () {
    function FieldsService(treeViewProps) {
        this.expandField = treeViewProps.expandField;
        this.selectField = treeViewProps.selectField;
        this.hasChildrenField = treeViewProps.hasChildrenField;
        this.childrenField = treeViewProps.childrenField;
        this.textField = treeViewProps.textField;
        this.disableField = treeViewProps.disableField;
        this.checkField = treeViewProps.checkField;
        this.checkIndeterminateField = treeViewProps.checkIndeterminateField;
        this.focusIdField = treeViewProps.focusIdField;
    }
    FieldsService.prototype.expanded = function (item) {
        return getNestedValue(this.expandField, item);
    };
    FieldsService.prototype.selected = function (item) {
        return getNestedValue(this.selectField, item);
    };
    FieldsService.prototype.text = function (item) {
        return getNestedValue(this.textField, item);
    };
    FieldsService.prototype.disabled = function (item) {
        return getNestedValue(this.disableField, item);
    };
    FieldsService.prototype.hasChildren = function (item) {
        return getNestedValue(this.hasChildrenField, item);
    };
    FieldsService.prototype.children = function (item) {
        var children = getNestedValue(this.childrenField, item);
        return children ? children : [];
    };
    FieldsService.prototype.checked = function (item) {
        return getNestedValue(this.checkField, item);
    };
    FieldsService.prototype.checkIndeterminate = function (item) {
        return getNestedValue(this.checkIndeterminateField, item);
    };
    FieldsService.prototype.focusId = function (item) {
        return this.focusIdField && getNestedValue(this.focusIdField, item);
    };
    FieldsService.prototype.getChildrenField = function () {
        return this.childrenField;
    };
    return FieldsService;
}());
export default FieldsService;
