/**
 * @hidden
 */
export function getAnchorAlign(isDirectionRightToLeft) {
    var align = { horizontal: 'left', vertical: 'bottom' };
    if (isDirectionRightToLeft) {
        align.horizontal = 'right';
    }
    return align;
}
/**
 * @hidden
 */
export function getPopupAlign(isDirectionRightToLeft) {
    var align = { horizontal: 'left', vertical: 'top' };
    if (isDirectionRightToLeft) {
        align.horizontal = 'right';
    }
    return align;
}
