"use strict";

import TechTransferAdapterBase from "./tech_transfer_adapter_base";
import { getModelChangeCriteria, getPluralizedModelName } from "../tech_transfer_column_value_generator";
import * as CommonEditablesFormatter from "../../../server/common/editables/common_editables_formatter";
import CommonRiskUtils from "../../../server/common/misc/common_risk_utils";
import * as UIUtils from "../../ui_utils";

/**
 * This class adapts/transforms the tech transfer data from server for both PP and MA.
 */
export default class TechTransferVariableAdapter extends TechTransferAdapterBase {
  getRows() {
    const techTransferRows = [];
    const {
      attachTransformationObjects,
      addCommonFilterProps,
      getRiskLinksSummary,
      getRiskLinksDocuments,
      formatRecordData,
      addAcceptanceCriteriaInfo,
    } = this.techTransferAdapterHelper;

    for (const record of Object.values(this.activeMap).map(val => UIUtils.deepClone(val))) {

      attachTransformationObjects(record);
      addAcceptanceCriteriaInfo(record);

      let documents = [];
      formatRecordData(record, this.typeaheadOptions, this.effectiveRMP, documents);

      record.effectiveTechTransferRMP = this.effectiveTechTransferRMP;
      record.changeCriteria = getModelChangeCriteria(this.changeCriteria, this.modelName);

      // here we have a problem, initially riskLinks are www/document links and after it is changed to risk links
      record.riskLinksSummary = getRiskLinksSummary(record.riskLinks);
      record.criticalityAssessmentLinks = getRiskLinksDocuments(record.riskLinks, documents);
      record.controlStrategy = CommonEditablesFormatter.formatControlStrategyForDisplay(record.controlStrategy, false);
      techTransferRows.push(record);
    }

    const wrappedResults = this.wrapResults(techTransferRows);
    const results = [];
    for (const record of wrappedResults) {
      addCommonFilterProps(record);
      results.push(record);
    }

    return results;
  }

  wrapResults(records) {
    const recordsToSort = [...records];

    let linkedRecords = records.filter(record => record.techTransferredFrom);
    let linkedRecordsIds = linkedRecords.map(record => record.techTransferredFromId || record.transferredFromId);
    const {fromProcessId, toProcessId} = this.params;
    const {
      getReceivingUnitOperationObject,
      getSendingUnitOperationObject,
    } = this.techTransferAdapterHelper;

    const getUnlinkedSendingRecords = (linkedRecordsIds) => Object.values(this.activeMap).filter(record =>
      record.processId === fromProcessId &&
      !linkedRecordsIds.includes(record.id));

    const getUnlinkedReceivingRecords = () => Object.values(this.activeMap).filter(record =>
      record.processId === toProcessId &&
      (!record.techTransferredFromId || record.techTransferredFromId === record.id) &&
      (!record.transferredFromId || record.transferredFromId === record.id),
    );

    for (const record of getUnlinkedSendingRecords(linkedRecordsIds)) {
      if (!recordsToSort.find(x => x.id === record.id)) {
        recordsToSort.push(record);
      }
    }

    for (const record of getUnlinkedReceivingRecords()) {
      if (!recordsToSort.find(x => x.id === record.id)) {
        recordsToSort.push(record);
      }
    }

    const sortedRecords = super.wrapResults(recordsToSort);

    linkedRecords = sortedRecords.filter(record => record.techTransferredFrom);
    linkedRecordsIds = linkedRecords.map(record => record.techTransferredFromId || record.transferredFromId);


    const unlinkedSendingRecords = getUnlinkedSendingRecords(linkedRecordsIds).map(record => {
      const receivingProcessComponent = Object.values(this.results.prcMap).find(prc =>
        record.ProcessComponentId && (prc.techTransferredFromId === record.ProcessComponentId
          || prc.transferredFromId === record.ProcessComponentId)) || {};

      const receivingMaterial = Object.values(this.results.mtMap).find(mt =>
        record.MaterialId && (mt.techTransferredFromId === record.MaterialId
          || mt.transferredFromId === record.MaterialId)) || {};

      let sortedRecord = sortedRecords.find(x => x.id === record.id);
      if (!sortedRecord) {
        sortedRecord = record;
      }

      if (sortedRecord) {
        sortedRecord.unitOperations = sortedRecord.unitOperation;
        sortedRecord.steps = sortedRecord.step;
      }

      return {
        ...getReceivingUnitOperationObject(sortedRecord),
        ProcessComponentId: receivingProcessComponent.id,
        MaterialId: receivingMaterial.id,
        isGroupRow: false,
        typeCode: sortedRecord.typeCode,
        techTransferredFrom: sortedRecord,
        pluralizedModelName: getPluralizedModelName(sortedRecord),
        effectiveModelRMP: CommonRiskUtils.filterRMPByType(this.effectiveRMP, sortedRecord.typeCode),
      };
    });

    const unlinkedReceivingRecords = getUnlinkedReceivingRecords().map(record => {
      const receivingProcessComponent = Object.values(this.results.prcMap).find(prc => prc.id === record.ProcessComponentId) || {};
      const receivingMaterial = Object.values(this.results.mtMap).find(mt => mt.id === record.MaterialId) || {};

      let sortedRecord = sortedRecords.find(x => x.id === record.id);
      if (!sortedRecord) {
        sortedRecord = record;
      }

      return {
        ...sortedRecord,
        techTransferredFrom: {
          ...getSendingUnitOperationObject(sortedRecord),
          unitOperation: sortedRecord.unitOperation,
          unitOperations: sortedRecord.unitOperation,
          step: sortedRecord.step,
          steps: sortedRecord.steps,
          ProcessComponentId: receivingProcessComponent.techTransferredFromId || receivingProcessComponent.transferredFromId,
          MaterialId: receivingMaterial.techTransferredFromId || receivingMaterial.transferredFromId,
        },
      };
    });

    return [
      ...linkedRecords,
      ...unlinkedSendingRecords,
      ...unlinkedReceivingRecords,
    ];
  }
}
