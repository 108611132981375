"use strict";

import React, { Fragment } from "react";
import BaseRequirementAttributePage from "../../editor/base_requirement_attribute_page";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import Section from "../../editor/widgets/section";
import AggregateCriticalityAttribute from "../../editor/attributes/riskTable/aggregate_criticality_attribute";
import {
  getCalculationFormulaTooltip,
  getRiskFieldOptions,
  getRiskFieldTooltip,
  getRiskLabelTooltip,
  getRiskScaleTooltip
} from "../../helpers/risk_helper";
import { RISK_TYPE_ENUM } from "../../helpers/constants/constants";
import TextAreaAttribute from "../../editor/attributes/text_area_attribute";
import ComboBoxAttribute from "../../editor/attributes/combo_box_attribute";
import CalculatedRiskAttribute from "../../editor/attributes/calculated_risk_attribute";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import TypeaheadAttribute from "../../editor/attributes/typeahead_attribute";
import LinksAttribute from "../../editor/attributes/links_attribute";
import * as CommonEditables from "../../../server/common/editables/common_editables";
import TabNavBar from "../../widgets/bars/tab_nav_bar";
import HistoryContainer from "../../editor/history/history_container";
import RelatedRecordsPanel from "../../widgets/relatedRecords/related_records_panel";
import * as UIUtils from "../../ui_utils";
import MultiDocumentsRiskLinksAttribute from "../../editor/attributes/multi_documents_risk_links_attribute";
import RiskSupportingDocumentsAttribute from "../../editor/widgets/risk_supporting_documents_attribute";
import RiskUtils, { CRITICALITY_RULES } from "../../../server/common/misc/common_risk_utils";
import CheckboxAttribute from "../../editor/attributes/checkbox_attribute";
import { EXPERIMENTS } from "../../../server/common/generic/common_utils";
import DebouncedTextAttribute from "../../editor/attributes/debounced_text_attribute";
import { getProjectFromCache } from "../../utils/project_helper";
import { RiskLabelAttribute } from "../../editor/attributes/riskTable/risk_label_attribute";
import { RiskScoreAttribute } from "../../editor/attributes/risk_score_attribute";
import { RelatedPanelNotice } from "../../editor/widgets/related_panel_notice";

/**
 * This is the base class for final attributes (ex. FQA, FPA)
 */
export default class BaseFinalAttribute extends BaseRequirementAttributePage {
  constructor(props, baseTypeName, capitalizedBaseTypeName, displayName) {
    super(props, baseTypeName, capitalizedBaseTypeName, displayName);
  }

  componentDidMount() {
    super.componentDidMount();

    if (!this.projectId) {
      return;
    }

    new TypeaheadObjectCache("Project").loadOptions().promise().then((projects) => {
      const project = projects.find(option => option.id === this.projectId);
      if (!project) {
        throw new Error("Project " + this.projectId + " cannot be identified");
      }

      if (project.productRiskAssessmentType === "Risk Ranking") {
        // These need to be initialized properly or else they will fail validation later.
        this.setStateSafely({impact: 0, uncertainty: 0, detailedRiskLinks: true});
      } else {
        this.setStateSafely({detailedRiskLinks: false});
      }
    });
  }

  getTypesToCache() {
    return ["TPPSection", ...super.getTypesToCache()];
  }

  hasRelatedItemsTab() {
    return true;
  }

  renderControlStrategyField() {
    return (
      <TypeaheadAttribute name="controlStrategy"
                          options={CommonEditables.FINAL_ATTRIBUTE_CONTROL_STRATEGY_OPTIONS}
                          parent={this}
                          isLoading={this.state.isLoading}
                          parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                          parentId={this.state.id}
                          multiple={true}
                          tooltipText={(<div>
                            {CommonEditables.FINAL_ATTRIBUTE_CONTROL_STRATEGY_OPTIONS.map(option => {
                              return (
                                <li key={option}>
                                  <b>{option}</b> - {CommonEditables.CONTROL_STRATEGY_DESCRIPTION[option]}
                                </li>
                              );
                            })}
                          </div>)}
      />
    );
  }

  preprocessReceivedData(result) {
    if (result && this.isDetailedRiskLinks()) {
      result.riskLinks = this.preprocessResult(result);
    }
    return super.preprocessReceivedData(result);
  }

  handleChangeValue(fieldName, value, callback, attributeType) {
    super.handleChangeValue(fieldName, value, callback, attributeType);
    if (fieldName === "riskLinks" && this.isDetailedRiskLinks()) {
      super.handleRiskLinksChangeValue(value);
    }
  }

  renderCriticalityAssessmentSection() {
    return this.isDetailedRiskLinks() ?
      this.renderCriticalityRiskLinksSection() :
      this.renderCompositeCriticalityAssessmentSection();
  }

  getProjectIdFromResult(result) {
    const {projectId, ProjectId} = result;
    return this.getProjectId() || projectId || ProjectId || this.projectId;
  }

  fillRiskDocumentsAttributeDetails(callback) {
    if (this.isDetailedRiskLinks()) {
      const riskLinks = this.preprocessResult(this.state);
      this.setStateSafely({riskLinks}, () => {
        if (callback && typeof callback === "function") {
          callback();
        }
      });
    } else {
      if (callback && typeof callback === "function") {
        callback();
      }
    }
  }

  renderRiskLinksAttribute() {
    return <div className="row">
      <MultiDocumentsRiskLinksAttribute name="risk"
                                        linkToObject={["GeneralAttribute"]}
                                        linkToObjectId={["GeneralAttributeId"]}
                                        linkObject={[`${this.capitalizedBaseTypeName}ToGeneralAttributeRisk`]}
                                        optionsFilter={this.filterRiskLinkTypeaheadOptions}
                                        displayName="Risk"
                                        className="col-sm-12"
                                        isLoading={!this.state.RMP || !this.state.ProjectWithAllVersions || this.isLoading() || this.isLoadingRiskLinks()}
                                        modelName={this.baseTypeName}
                                        RMP={this.state.RMP}
                                        projectWithAllVersions={this.state.ProjectWithAllVersions}
                                        parent={this}
                                        ref={(riskTable) => this.setRiskTableToState(riskTable)}
                                        onValidate={this.handleRiskVerification}
                                        onDelete={this.handleDelete}
                                        triggerChildUpdate={this.state.triggerChildUpdate}
                                        onSave={this.handleRiskLinkSave}
                                        risksFromAllRecords={this.state.risksFromAllRecords}

      />
    </div>;
  }


  // eslint-disable-next-line no-unused-vars
  getRiskLinkAttributeName(typeCode) {
    return `${this.capitalizedBaseTypeName}ToGeneralAttributeRisks`;
  }

  // eslint-disable-next-line no-unused-vars
  getRiskLinkVersionAttributeName(typeCode) {
    return `${this.capitalizedBaseTypeName}ToGeneralAttributeRiskLinkedVersions`;
  }

  cleanRecordBeforeSentToServer(record) {

    const newRecord = UIUtils.deepClone(record);

    if (this.isDetailedRiskLinks()) {
      super.cleanRiskLinksBeforeSentToServer(newRecord);
      newRecord.impact = 0;
      newRecord.uncertainty = 0;
      newRecord.criticalityJustification = "";
    } else {
      newRecord[this.getRiskLinkAttributeName()] = [];
      newRecord[this.getRiskLinkVersionAttributeName()] = [];
    }

    newRecord.detailedRiskLinks = this.isDetailedRiskLinks();
    return super.cleanRecordBeforeSentToServer(newRecord);
  }

  isDetailedRiskLinks() {
    if (this.isAdd()) {
      const thisProject = getProjectFromCache(this.projectId);
      const hasRiskLinks = thisProject ? thisProject.productRiskAssessmentType === "Risk Ranking" : false;
      return hasRiskLinks;
    } else {
      return this.state.project ? this.state.project.productRiskAssessmentType === "Risk Ranking" : false;
    }
  }

  getRiskLinkedObjects() {
    return ["GA"];
  }

  renderObligatoryCQA() {
    if (this.state.modelType !== "FQA" || !UIUtils.isExperimentEnabled(EXPERIMENTS.ObligatoryCQA)) {
      return <Fragment />;
    }

    return (
      <div className="row">
        <CheckboxAttribute name="obligatoryCQA"
                           displayName="Obligatory CQA"
                           tooltipText="Details about obligatory CQA."
                           parent={this}
        />
      </div>
    );
  }

  renderCriticalityRiskLinksSection() {
    let effectiveRMP = this.getEffectiveRMPByModelName(this.capitalizedBaseTypeName);
    let olderRMP = this.getOlderRMP(this.capitalizedBaseTypeName);
    return <Section id="criticalityAssessment"
                    parent={this}
                    header={this.renderCriticalitySectionHeader()}
    >
      {this.renderObligatoryCQA()}
      {!this.state.obligatoryCQA ? <Fragment>{this.renderRiskLinksAttribute()}</Fragment> : <Fragment />}
      <div className="row">
        <AggregateCriticalityAttribute name="maxCriticality"
                                       displayName="Criticality (Raw)"
                                       className="col-sm-3"
                                       riskInfo={this.state.riskInfo}
                                       olderRiskInfo={this.getOlderVersion()?.riskInfo}
                                       RMP={effectiveRMP}
                                       olderRMP={olderRMP}
                                       getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, this.state, false, false, false)}
                                       getSubscriptTooltipCallback={getCalculationFormulaTooltip.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, this.state, true, false, "Winner", this.isDetailedRiskLinks())}
                                       subscriptText="How is this calculated?"
                                       valueType="raw"
                                       isLoading={!effectiveRMP}
                                       visible={!this.state.obligatoryCQA}
                                       parent={this}
        />
        <AggregateCriticalityAttribute name="maxCriticalityPercentage"
                                       displayName="Criticality (%)"
                                       className="col-sm-3"
                                       riskInfo={this.state.riskInfo}
                                       olderRiskInfo={this.getOlderVersion()?.riskInfo}
                                       RMP={effectiveRMP}
                                       olderRMP={olderRMP}
                                       getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, this.state, true, false, false)}
                                       getSubscriptTooltipCallback={getCalculationFormulaTooltip.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, this.state, false, true, "Winner", this.isDetailedRiskLinks())}
                                       subscriptText="How is this calculated?"
                                       valueType="percentage"
                                       isLoading={!effectiveRMP}
                                       visible={!this.state.obligatoryCQA}
                                       parent={this}
        />

        <RiskLabelAttribute
          name="riskLabel"
          className="col-sm-3"
          riskTable={this.riskTable?.getInstance()}
          subscriptText="Overridden"
          parent={this}
          getSubscriptTooltipCallback={getRiskLabelTooltip.bind(this, this.state.riskInfo)}
          getTooltipCallback={RiskUtils.getRisklabelAttributeTooltip.bind(this)}
          visible={this.isView()}
        />

        <TextAreaAttribute name="recommendedActions"
                           className="col-sm-12"
                           tooltipText="Identify recommended actions to further evaluate attribute or parameter and reduce uncertainty on how this impacts downstream requirements.
                            These actions may be additional studies to evaluate attribute or parameter alone or in conjunction with other attributes or parameters
                            (e.g. design of experiments (DOE) studies)."
                           parent={this}
        />
      </div>
      <div className="row">
        <RiskSupportingDocumentsAttribute name="riskLinks"
                                          displayName="Source Documents"
                                          getRiskLinksOptions={this.getRiskLinksTypeaheadOptions}
                                          className="col-sm-12"
                                          isLoading={this.isLoading() || this.isLoadingRiskLinks()}
                                          triggerChildUpdate={this.state.triggerChildUpdate}
                                          parent={this}
                                          onDocumentDelete={this.handleRiskDocumentDelete}
                                          onDocumentSave={this.handleRiskDocumentSave}
        />
      </div>
      {this.renderDeleteRiskConfirmation()}
      {this.renderEditRiskConfirmation()}
    </Section>;
  }

  renderSidePanel(versions) {

    const {
      selectedTab, sidePanelTabs, clonedFrom,
      showMajorVersionsOnly, childInstances,
    } = this.state;

    const {t} = this.props;

    return (
      <Fragment>
        <TabNavBar selected={selectedTab}
                   className="related-tab-nav-margin"
                   onTabChanged={this.handleTabChanged}
                   tabs={UIUtils.deepClone(sidePanelTabs)}
                   parent={this}
                   parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                   parentId={this.state.id}
        />
        {selectedTab?.title === sidePanelTabs.HISTORY?.title ? (
          <HistoryContainer parent={this}
                            isLoading={this.isLoading()}
                            versions={versions}
                            showWithRelatedRecords={true}
                            baseTypeName={this.baseTypeName}
                            clonedFrom={clonedFrom}
                            showMajorVersionsOnly={showMajorVersionsOnly}
          />
        ) : (
          <div>
            <RelatedPanelNotice isApproved={this.isApproved()} />
            <RelatedRecordsPanel id={`${this.baseTypeName}IqaRelatedRecords`}
                                 header={t("Intermediate Quality Attributes")}
                                 modelName="IQA"
                                 projectId={this.getProjectId()}
                                 parent={this}
                                 parentId={this.state.id}
                                 isLoading={this.state.isLoading}
                                 records={childInstances
                                   .filter(record => record.modelName === "IQA") || []}
            />
            <RelatedRecordsPanel id={`${this.baseTypeName}IpaRelatedRecords`}
                                 header={t("Intermediate Performance Attributes")}
                                 modelName="IPA"
                                 projectId={this.getProjectId()}
                                 parent={this}
                                 parentId={this.state.id}
                                 isLoading={this.state.isLoading}
                                 records={childInstances
                                   .filter(record => record.modelName === "IPA") || []}
            />
            <RelatedRecordsPanel id={`${this.baseTypeName}ProcessParametersRelatedRecords`}
                                 header={t("Process Parameters")}
                                 modelName="ProcessParameter"
                                 projectId={this.getProjectId()}
                                 parent={this}
                                 parentId={this.state.id}
                                 isLoading={this.state.isLoading}
                                 records={childInstances
                                   .filter(record => record.modelName === "PP") || []}
            />
            <RelatedRecordsPanel id={`${this.baseTypeName}MaterialAttributesRelatedRecords`}
                                 header={t("Material Attributes")}
                                 modelName="MaterialAttribute"
                                 projectId={this.getProjectId()}
                                 parent={this}
                                 parentId={this.state.id}
                                 isLoading={this.state.isLoading}
                                 records={childInstances
                                   .filter(record => record.modelName === "MA") || []}
            />
            {this.renderSidePanelWidget()}
          </div>
        )}
      </Fragment>
    );
  }

  renderCompositeCriticalityAssessmentSection() {
    let effectiveRMP = this.getEffectiveRMPByModelName(this.capitalizedBaseTypeName);
    let olderRMP = this.getOlderRMP(this.capitalizedBaseTypeName);

    return <Section id="risk"
                    parent={this}
                    showDocLinks={true}
                    header={this.renderCriticalitySectionHeader()}
    >
      <div className="row">
        {this.isView() && !this.isDiffingVersions() ?
          <RiskScoreAttribute
            name="impact"
            displayName="Impact/Severity"
            className="col-sm-6"
            getTooltipCallback={getRiskFieldTooltip.bind(this, RISK_TYPE_ENUM.IMPACT, effectiveRMP)}
            scale={effectiveRMP && effectiveRMP["RMPToImpactLinkedVersions"].find(scale => scale.riskScore === this.state.impact)}
            isLoading={!effectiveRMP}
            parent={this}
            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
            parentId={this.state.id}
          />
          : <ComboBoxAttribute name="impact"
                               displayName="Impact/Severity"
                               className="col-sm-6"
                               isNumber={true}
                               options={effectiveRMP && getRiskFieldOptions(effectiveRMP, "RMPToImpactLinkedVersions")}
                               olderOptions={olderRMP && getRiskFieldOptions(olderRMP, "RMPToImpactLinkedVersions")}
                               default={RiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.IMPACT, effectiveRMP)}
                               getTooltipCallback={getRiskFieldTooltip.bind(this, RISK_TYPE_ENUM.IMPACT, effectiveRMP)}
                               isLoading={!effectiveRMP}
                               parent={this}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
          />
        }

        {this.isView() && !this.isDiffingVersions() ?
          <RiskScoreAttribute
            name="uncertainty"
            displayName="Uncertainty/Likelihood"
            className="col-sm-6"
            getTooltipCallback={getRiskFieldTooltip.bind(this, RISK_TYPE_ENUM.UNCERTAINTY, effectiveRMP)}
            scale={effectiveRMP && effectiveRMP["RMPToUncertaintyLinkedVersions"].find(scale => scale.riskScore === this.state.uncertainty)}
            isLoading={!effectiveRMP}
            parent={this}
            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
            parentId={this.state.id}
          /> :
          <ComboBoxAttribute name="uncertainty"
                             displayName="Uncertainty/Likelihood"
                             className="col-sm-6"
                             isNumber={true}
                             disabled={!(effectiveRMP && effectiveRMP.useUncertainty)}
                             options={effectiveRMP && getRiskFieldOptions(effectiveRMP, "RMPToUncertaintyLinkedVersions")}
                             olderOptions={olderRMP && getRiskFieldOptions(olderRMP, "RMPToUncertaintyLinkedVersions")}
                             default={RiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.UNCERTAINTY, effectiveRMP)}
                             getTooltipCallback={getRiskFieldTooltip.bind(this, RISK_TYPE_ENUM.UNCERTAINTY, effectiveRMP)}
                             isLoading={!effectiveRMP}
                             parent={this}
                             parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                             parentId={this.state.id}
          />
        }
      </div>
      <div className="row">
        <CalculatedRiskAttribute name="criticality"
                                 displayName="Criticality (Raw)"
                                 className="col-sm-4"
                                 riskFactor1="impact"
                                 riskFactor2="uncertainty"
                                 riskType={RISK_TYPE_ENUM.CRITICALITY}
                                 RMP={effectiveRMP}
                                 olderRMP={olderRMP}
                                 getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, this.state, false)}
                                 getSubscriptTooltipCallback={getCalculationFormulaTooltip.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, this.state, true, false)}
                                 subscriptText="How is this calculated?"
                                 valueType="raw"
                                 isLoading={!effectiveRMP}
                                 parent={this}
                                 olderVersion={this.getOlderVersion()}
        />
        <CalculatedRiskAttribute name="criticalityPercentage"
                                 displayName="Criticality (%)"
                                 className="col-sm-4"
                                 riskFactor1="impact"
                                 riskFactor2="uncertainty"
                                 riskType={RISK_TYPE_ENUM.CRITICALITY}
                                 RMP={effectiveRMP}
                                 olderRMP={olderRMP}
                                 getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, this.state, true)}
                                 getSubscriptTooltipCallback={getCalculationFormulaTooltip.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, this.state, false, true)}
                                 subscriptText="How is this calculated?"
                                 valueType="percentage"
                                 isLoading={!effectiveRMP}
                                 parent={this}
                                 olderVersion={this.getOlderVersion()}
        />
        <RiskLabelAttribute
          name="riskLabel"
          className="col-sm-4"
          riskTable={this.getRiskTableForRecordWithoutDetails()}
          subscriptText="Overridden"
          parent={this}
          getSubscriptTooltipCallback={RiskUtils.getCriticalityOverrideTooltip.bind(this, this.getRiskRuleForRecordWithoutDetails(), this.getRiskLabelForRecordWithoutDetails())}
          getTooltipCallback={RiskUtils.getRisklabelAttributeTooltip.bind(this)}
          visible={this.isView()}
        />
      </div>
      <div className="row">
        <TextAreaAttribute name="criticalityJustification"
                           displayName="Justification"
                           className="col-sm-12"
                           tooltipText="Impact and uncertainty values should be justified by literature, data, or other science-based rationales."
                           parent={this}
        />
        <TextAreaAttribute name="recommendedActions"
                           className="col-sm-12"
                           tooltipText="Identify recommended actions to further evaluate attribute or parameter and reduce uncertainty on how this impacts downstream requirements. These actions may be additional studies to evaluate attribute or parameter alone or in conjunction with other attributes or parameters (e.g. design of experiments (DOE) studies)."
                           parent={this}
        />
      </div>
    </Section>;
  }

  renderCriticalitySectionHeader() {
    return <div className="include-risk-links-section-header">
      <span>Criticality Assessment</span>
      <InfoTooltip id="infoCriticalityAssessment"
                   fdaGuidanceURL="https://www.fda.gov/media/167721/download"
                   fdaGuidancePage={10}
                   fdaGuidanceOffset={610}
                   verbiage={<div>
                     A systematic process of organizing information to support
                     a risk decision to be made within a risk management
                     process. It consists of the identification of hazards
                     and the analysis and evaluation of risks associated with
                     exposure to those hazards.
                   </div>}
      /></div>;
  }

  renderGeneralSection() {
    return <Section parent={this}
                    header={this.getGeneralHeader()}
                    collapsible={false}
    >
      <div className="row">
        <DebouncedTextAttribute name="name"
                                parent={this}
        />
        <ComboBoxAttribute name="type"
                           options={["Biological", "Chemical", "Microbiological", "Physical"]}
                           default="Physical"
                           parent={this}
                           isLoading={this.state.isLoading}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           parentId={this.state.id}
        />
      </div>
      <div>
        <div className="row">
          {this.renderScope()}
          <TypeaheadAttribute name="category"
                              options={CommonEditables.QUALITY_ATTRIBUTES_CATEGORIES}
                              className="col-sm-6"
                              parent={this}
                              isLoading={this.state.isLoading}
                              parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                              parentId={this.state.id}
                              multiple={true}
          />
        </div>
        <div className="row">
          <TextAreaAttribute name="description"
                             className="col-sm-12"
                             parent={this}
          />
          <LinksAttribute name="TPPSection"
                          displayName="Trace to TPP"
                          className="col-sm-12"
                          parent={this}
                          parentId={this.state.id}
                          isLoading={this.state.isLoading}
                          projectId={this.getProjectId()}
          />
          {this.isDetailedRiskLinks() ?
            "" :
            <LinksAttribute name="GeneralAttribute"
                            displayName="Trace to General Attribute"
                            className="col-sm-12"
                            parent={this}
                            parentId={this.state.id}
                            isLoading={this.state.isLoading}
                            projectId={this.getProjectId()}
            />}
        </div>
      </div>
    </Section>;
  }

  renderScope() {
    return (
      <TypeaheadAttribute name="scope"
                          options={CommonEditables.FINAL_ATTRIBUTE_SCOPE}
                          parent={this}
                          isLoading={this.state.isLoading}
                          parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                          parentId={this.state.id}
                          multiple={true}
      />
    );
  }


  getTabName() {
    return "QTPP";
  }

  getURLToLoadData() {
    return `${super.getURLToLoadData()}&includeRelatedRecords=true`;
  }

  getRiskRuleForRecordWithoutDetails() {
    return this.state.riskInfo &&
      this.state.riskInfo[RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel?.rule;
  }

  getRiskLabelForRecordWithoutDetails() {
    return this.state.riskInfo &&
      this.state.riskInfo[RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel?.riskLabel;
  }

  getRiskTableForRecordWithoutDetails() {
    const {riskLabel, riskColor, riskLinkWinners, riskRule} = this.getRiskLabelAndColor(this.state.riskInfo);
    const {
      riskLabel: olderRiskLabel,
      riskColor: olderRiskColor
    } = this.state.olderVersion ? this.getRiskLabelAndColor(this.state.olderVersion.riskInfo) : {};

    return {
      riskLinkWinners,
      riskLabel: riskLabel,
      riskColor: riskColor,
      riskRule: riskRule,
      oldRiskLabel: olderRiskLabel,
      oldRiskColor: olderRiskColor
    };
  }

  getRiskLabelAndColor(riskInfo) {
    let riskLabel = "";
    let riskColor = "";
    let riskRule = CRITICALITY_RULES.MAXIMUM;
    if (riskInfo) {
      const riskScale = riskInfo[RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel;
      if (riskScale) {
        riskLabel = riskScale.riskLabel;
        riskColor = riskScale.color;
        riskRule = riskScale.rule;
      }
    }

    return {riskLabel, riskColor, riskRule};
  }
}
