"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import BaseReactComponent from "../../base_react_component";

/**
 * This class is for showing a tooltip at the side of a text field or drop down.
 */
export default class FieldTooltip extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    $(this.popover).popover({
      sanitizeFn: UIUtils.sanitizePopoverData,
    });
  }

  renderPopupContent() {
    /*
      This gets converted to HTML and passed around, so don't bother putting onClick handlers here.
      Put them in componentDidMount() above instead.
     */
    return ReactDOMServer.renderToString(
      <div id={this.props.id + "TooltipDiv"}>
        <div id={this.props.id + "Content"} className="info-tooltip-content">
          {this.props.text}
        </div>
      </div>
    );
  }

  render() {
    return (
      <span id={this.props.id + "TooltipIcon"}
            className="field-tooltip-icon"
            data-toggle="popover"
            data-html="true"
            data-container="body"
            data-content={this.renderPopupContent()}
            data-boundary="viewport"
            data-placement="top"
            ref={popover => this.popover = popover}
      >
        <FontAwesomeIcon icon={faInfoCircle} className={this.props.className} />
      </span>
    );
  }
}
