"use strict";

import React from "react";

export const HeaderCell = (props) => {
  const {leftBorder, rightBorder, title} = props;
  const leftBorderClass = (leftBorder === "none") ? "clear-left-border" : "";
  const rightBorderClass = (rightBorder === "none") ? "clear-right-border" : "";
  return (
    <div className={`${leftBorderClass} ${rightBorderClass}`}>
      <span>{title}</span>
    </div>
  );
};