"use strict";

import { generateDocumentsValue, generateVersionValue } from "../../export/configurable_tables_export_value_generator";
import {
  ABOUT_SECTION,
  METHOD_DEVELOPMENT_SECTION,
  REFERENCES_SECTION,
} from "../constants/configurable_tables_sections_config";
import { HIDDEN } from "../constants/configurable_tables_columns_config";
import { FIELD_PROPS } from "../constants/configurable_tables_field_props_config";
import RecordFields from "../record_fields";
import { EXTRA_LARGE_COLUMN, LARGE_COLUMN, TEXT_COLUMN } from "../../tables/configurable_tables_constants";
import { FILTER_TYPES } from "../../columnOperations/configurable_tables_column_operations_constants";
import {
  ABOUT_COL_SPAN,
  METHOD_DEVELOPMENT_COL_SPAN,
  REFERENCE_AND_STANDARD_COL_SPAN
} from "../constants/configurable_tables_col_spans_config";
import CommonEditables from "../../../../server/common/editables/common_editables";

/**
 * This class lists all Library Material fields to be shown in export, column selection and tables.
 */
export default class ControlMethodFields extends RecordFields {

  get title() {
    return "Control Methods";
  }

  get fields() {
    return [
      // About Section
      {
        title: "Name",
        prop: FIELD_PROPS.NAME,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        showColumnSelectionOn: HIDDEN,
        tableValue: (columnGenerator) => columnGenerator.generateNameColumn("Name", FIELD_PROPS.NAME),
      },
      {
        title: "Version",
        prop: FIELD_PROPS.VERSION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        showColumnSelectionOn: HIDDEN,
        value: generateVersionValue,
        tableValue: (columnGenerator) => columnGenerator.generateVersionColumn("Version"),
      },
      {
        title: "Type",
        prop: FIELD_PROPS.TYPE,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        showColumnSelectionOn: HIDDEN,
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: () => CommonEditables.CONTROL_METHOD_TYPES,
        tableValue: (columnGenerator) => columnGenerator.generateTextColumn("Type", FIELD_PROPS.TYPE, true, LARGE_COLUMN, FILTER_TYPES.COMBO_BOX),
      },
      {
        title: "Category",
        prop: FIELD_PROPS.CATEGORY,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        showColumnSelectionOn: HIDDEN,
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: () => CommonEditables.CONTROL_METHOD_CATEGORIES,
        tableValue: (columnGenerator) => columnGenerator.generateTextColumn("Category", FIELD_PROPS.CATEGORY, true, LARGE_COLUMN, FILTER_TYPES.COMBO_BOX),
      },
      {
        title: "Compendial Standard",
        prop: FIELD_PROPS.COMPENDIAL_STANDARD,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        showColumnSelectionOn: HIDDEN,
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: () => CommonEditables.COMPENDIAL_STANDARD,
        tableValue: (columnGenerator) => columnGenerator.generateMultiSelectionColumn("Compendial Standard", FIELD_PROPS.COMPENDIAL_STANDARD, false, EXTRA_LARGE_COLUMN),
      },
      {
        title: "Description",
        prop: FIELD_PROPS.DESCRIPTION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        showColumnSelectionOn: HIDDEN,
        tableValue: (columnGenerator) => columnGenerator.generateTextAreaColumn("Description", FIELD_PROPS.DESCRIPTION),
      },
      {
        title: "Equipment",
        prop: FIELD_PROPS.EQUIPMENT,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        showColumnSelectionOn: HIDDEN,
        tableValue: (columnGenerator) => columnGenerator.generateTextAreaColumn("Equipment", FIELD_PROPS.EQUIPMENT),
      },
      {
        title: "Control Method Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.CONTROL_METHOD_LINKS,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) => columnGenerator.generateDocumentsColumn("Source Documents", process, FIELD_PROPS.CONTROL_METHOD_LINKS, true),
      },
      // Method Development
      {
        title: "Supplier",
        prop: FIELD_PROPS.SUPPLIER,
        colspan: METHOD_DEVELOPMENT_COL_SPAN,
        section: METHOD_DEVELOPMENT_SECTION,
        defaultValue: false,
        tableValue: (columnGenerator) => columnGenerator.generateTextColumn("Supplier", FIELD_PROPS.SUPPLIER, true, TEXT_COLUMN, FILTER_TYPES.COMBO_BOX),
      },
      {
        title: "Status",
        prop: FIELD_PROPS.STATUS,
        colspan: METHOD_DEVELOPMENT_COL_SPAN,
        section: METHOD_DEVELOPMENT_SECTION,
        defaultValue: false,
        showColumnSelectionOn: HIDDEN,
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: () => CommonEditables.CONTROL_METHOD_SUPPLIER_STATUS,
        tableValue: (columnGenerator) => columnGenerator.generateTextColumn("Status", FIELD_PROPS.STATUS, true, LARGE_COLUMN, FILTER_TYPES.COMBO_BOX),
      },
      {
        title: "Stability Indicating",
        prop: FIELD_PROPS.STABILITY_INDICATING,
        colspan: METHOD_DEVELOPMENT_COL_SPAN,
        section: METHOD_DEVELOPMENT_SECTION,
        defaultValue: true,
        showColumnSelectionOn: HIDDEN,
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: () => CommonEditables.BOOLEAN_VALUES,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("Stability Indicating", FIELD_PROPS.STABILITY_INDICATING)
      },
      {
        title: "Method Development Source Documents",
        columnSelectionTitle: "Source Documents",
        colspan: METHOD_DEVELOPMENT_COL_SPAN,
        section: METHOD_DEVELOPMENT_SECTION,
        prop: FIELD_PROPS.DEVELOPMENT_LINKS,
        defaultValue: false,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) => columnGenerator.generateDocumentsColumn("Source Documents", process, FIELD_PROPS.DEVELOPMENT_LINKS, true),
      },
      // References & Standards
      {
        title: "References & Standards",
        columnSelectionTitle: "Source Documents",
        section: REFERENCES_SECTION,
        prop: FIELD_PROPS.REFERENCES,
        colspan: REFERENCE_AND_STANDARD_COL_SPAN,
        defaultValue: false,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) => columnGenerator.generateDocumentsColumn("Source Documents", process, FIELD_PROPS.REFERENCES, true),
      },
    ];
  }
}
