import { Editor } from './Editor';
import { EditorTools } from './tools/main';
import { EditorToolsSettings } from './config/toolsSettings';
import { EditorUtils } from './utils';
import { EditorDialogs } from './dialogs/main';
import { 
// prosemirror-state
Selection, SelectionRange, TextSelection, NodeSelection, AllSelection, EditorState, Plugin, PluginKey, Transaction, 
// prosemirror-view
Decoration, DecorationSet, EditorView, 
// prosemirror-model
Node, ResolvedPos, NodeRange, Fragment, Slice, ReplaceError, Mark, Schema, NodeType, MarkType, ContentMatch, DOMParser, DOMSerializer, 
// prosemirror-transform
Transform, Step, StepResult, joinPoint, canJoin, canSplit, insertPoint, dropPoint, liftTarget, findWrapping, StepMap, MapResult, Mapping, AddMarkStep, RemoveMarkStep, ReplaceStep, ReplaceAroundStep, replaceStep, 
// prosemirror-commands
deleteSelection, joinBackward, selectNodeBackward, joinForward, selectNodeForward, joinUp, joinDown, lift, newlineInCode, exitCode, createParagraphNear, liftEmptyBlock, splitBlock, splitBlockKeepMarks, selectParentNode, selectAll, wrapIn, setBlockType, toggleMark, autoJoin, chainCommands, pcBaseKeymap, macBaseKeymap, baseKeymap, 
// prosemirror-history
history, undo, redo, undoDepth, redoDepth, 
// prosemirror-inputrules
InputRule, inputRules, undoInputRule, emDash, ellipsis, openDoubleQuote, closeDoubleQuote, openSingleQuote, closeSingleQuote, smartQuotes, wrappingInputRule, textblockTypeInputRule, 
// prosemirror-keymap
keymap, keydownHandler, 
// prosemirror-schema-list
orderedList, bulletList, listItem, addListNodes, wrapInList, splitListItem, liftListItem, sinkListItem, 
// prosemirror-dropcursor
dropCursor, 
// prosemirror-gapcursor
gapCursor, 
// prosemirror-tables
tableEditing, fixTables, fixTablesKey, cellAround, isInTable, selectionCell, moveCellForward, inSameTable, findCell, colCount, nextCell, removeColSpan, addColSpan, columnIsHeader, tableNodes, tableNodeTypes, CellSelection, TableMap, tableEditingKey, columnResizing, columnResizingPluginKey, updateColumnsOnResize, selectedRect, addColumn, addColumnBefore, addColumnAfter, deleteColumn, rowIsHeader, addRow, addRowBefore, addRowAfter, deleteRow, mergeCells, splitCell, splitCellWithType, setCellAttr, toggleHeader, toggleHeaderRow, toggleHeaderColumn, toggleHeaderCell, goToNextCell, deleteTable } from '@progress/kendo-editor-common';
/**
 * An object containing the content of ProseMirror packages used at the Editor component.
 *
 * "prosemirror-state",
 * "prosemirror-view",
 * "prosemirror-model",
 * "prosemirror-transform",
 * "prosemirror-commands",
 * "prosemirror-keymap",
 * "prosemirror-tables",
 * "prosemirror-schema-list",
 * "prosemirror-history",
 * "prosemirror-inputrules",
 * "prosemirror-dropcursor",
 * "prosemirror-gapcursor"
 */
export var ProseMirror = {
    // prosemirror-state
    Selection: Selection,
    SelectionRange: SelectionRange,
    TextSelection: TextSelection,
    NodeSelection: NodeSelection,
    AllSelection: AllSelection,
    EditorState: EditorState,
    Plugin: Plugin,
    PluginKey: PluginKey,
    Transaction: Transaction,
    // prosemirror-view
    Decoration: Decoration,
    DecorationSet: DecorationSet,
    EditorView: EditorView,
    // prosemirror-model
    Node: Node,
    ResolvedPos: ResolvedPos,
    NodeRange: NodeRange,
    Fragment: Fragment,
    Slice: Slice,
    ReplaceError: ReplaceError,
    Mark: Mark,
    Schema: Schema,
    NodeType: NodeType,
    MarkType: MarkType,
    ContentMatch: ContentMatch,
    DOMParser: DOMParser,
    DOMSerializer: DOMSerializer,
    // prosemirror-transform
    Transform: Transform,
    Step: Step,
    StepResult: StepResult,
    joinPoint: joinPoint,
    canJoin: canJoin,
    canSplit: canSplit,
    insertPoint: insertPoint,
    dropPoint: dropPoint,
    liftTarget: liftTarget,
    findWrapping: findWrapping,
    StepMap: StepMap,
    MapResult: MapResult,
    Mapping: Mapping,
    AddMarkStep: AddMarkStep,
    RemoveMarkStep: RemoveMarkStep,
    ReplaceStep: ReplaceStep,
    ReplaceAroundStep: ReplaceAroundStep,
    replaceStep: replaceStep,
    // prosemirror-commands
    deleteSelection: deleteSelection,
    joinBackward: joinBackward,
    selectNodeBackward: selectNodeBackward,
    joinForward: joinForward,
    selectNodeForward: selectNodeForward,
    joinUp: joinUp,
    joinDown: joinDown,
    lift: lift,
    newlineInCode: newlineInCode,
    exitCode: exitCode,
    createParagraphNear: createParagraphNear,
    liftEmptyBlock: liftEmptyBlock,
    splitBlock: splitBlock,
    splitBlockKeepMarks: splitBlockKeepMarks,
    selectParentNode: selectParentNode,
    selectAll: selectAll,
    wrapIn: wrapIn,
    setBlockType: setBlockType,
    toggleMark: toggleMark,
    autoJoin: autoJoin,
    chainCommands: chainCommands,
    pcBaseKeymap: pcBaseKeymap,
    macBaseKeymap: macBaseKeymap,
    baseKeymap: baseKeymap,
    // prosemirror-history
    history: history,
    undo: undo,
    redo: redo,
    undoDepth: undoDepth,
    redoDepth: redoDepth,
    // prosemirror-inputrules
    InputRule: InputRule,
    inputRules: inputRules,
    undoInputRule: undoInputRule,
    emDash: emDash,
    ellipsis: ellipsis,
    openDoubleQuote: openDoubleQuote,
    closeDoubleQuote: closeDoubleQuote,
    openSingleQuote: openSingleQuote,
    closeSingleQuote: closeSingleQuote,
    smartQuotes: smartQuotes,
    wrappingInputRule: wrappingInputRule,
    textblockTypeInputRule: textblockTypeInputRule,
    // prosemirror-keymap
    keymap: keymap,
    keydownHandler: keydownHandler,
    // prosemirror-schema-list
    orderedList: orderedList,
    bulletList: bulletList,
    listItem: listItem,
    addListNodes: addListNodes,
    wrapInList: wrapInList,
    splitListItem: splitListItem,
    liftListItem: liftListItem,
    sinkListItem: sinkListItem,
    // prosemirror-dropcursor
    dropCursor: dropCursor,
    // prosemirror-gapcursor
    gapCursor: gapCursor,
    // prosemirror-tables
    tableEditing: tableEditing,
    fixTables: fixTables,
    fixTablesKey: fixTablesKey,
    cellAround: cellAround,
    isInTable: isInTable,
    selectionCell: selectionCell,
    moveCellForward: moveCellForward,
    inSameTable: inSameTable,
    findCell: findCell,
    colCount: colCount,
    nextCell: nextCell,
    removeColSpan: removeColSpan,
    addColSpan: addColSpan,
    columnIsHeader: columnIsHeader,
    tableNodes: tableNodes,
    tableNodeTypes: tableNodeTypes,
    CellSelection: CellSelection,
    TableMap: TableMap,
    tableEditingKey: tableEditingKey,
    columnResizing: columnResizing,
    columnResizingPluginKey: columnResizingPluginKey,
    updateColumnsOnResize: updateColumnsOnResize,
    selectedRect: selectedRect,
    addColumn: addColumn,
    addColumnBefore: addColumnBefore,
    addColumnAfter: addColumnAfter,
    deleteColumn: deleteColumn,
    rowIsHeader: rowIsHeader,
    addRow: addRow,
    addRowBefore: addRowBefore,
    addRowAfter: addRowAfter,
    deleteRow: deleteRow,
    mergeCells: mergeCells,
    splitCell: splitCell,
    splitCellWithType: splitCellWithType,
    setCellAttr: setCellAttr,
    toggleHeader: toggleHeader,
    toggleHeaderRow: toggleHeaderRow,
    toggleHeaderColumn: toggleHeaderColumn,
    toggleHeaderCell: toggleHeaderCell,
    goToNextCell: goToNextCell,
    deleteTable: deleteTable
};
export { Editor, EditorTools, EditorToolsSettings, EditorUtils, EditorDialogs };
