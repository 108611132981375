var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { TabStripNavigation } from './TabStripNavigation';
import { TabStripContent } from './TabStripContent';
import { Keys, classNames, validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
// tslint:enable:max-line-length
var TabStrip = /** @class */ (function (_super) {
    __extends(TabStrip, _super);
    function TabStrip(props) {
        var _a;
        var _this = _super.call(this, props) || this;
        _this._element = null;
        _this.keyBinding = (_a = {},
            _a[Keys.left] = function () { return _this.prevNavigatableTab(); },
            _a[Keys.right] = function () { return _this.nextNavigatableTab(); },
            _a[Keys.down] = function () { return _this.nextNavigatableTab(); },
            _a[Keys.up] = function () { return _this.prevNavigatableTab(); },
            _a[Keys.home] = function () { return 0; },
            _a[Keys.end] = function () { return (React.Children.count(_this.props.children) - 1); },
            _a);
        /**
         * @hidden
         */
        _this.onSelect = function (index) {
            if (_this.props.selected !== index) {
                if (_this.props.onSelect) {
                    _this.props.onSelect({
                        selected: index
                    });
                }
            }
        };
        /**
         * @hidden
         */
        _this.onKeyDown = function (event) {
            var handler;
            switch (event.keyCode) {
                case Keys.left:
                    handler = _this.keyBinding[_this.invertKeys(Keys.left, Keys.right)];
                    break;
                case Keys.right:
                    handler = _this.keyBinding[_this.invertKeys(Keys.right, Keys.left)];
                    break;
                case Keys.up:
                    handler = _this.keyBinding[Keys.up];
                    break;
                case Keys.down:
                    handler = _this.keyBinding[Keys.down];
                    break;
                case Keys.home:
                    handler = _this.keyBinding[Keys.home];
                    break;
                case Keys.end:
                    handler = _this.keyBinding[Keys.end];
                    break;
                default:
                    break;
            }
            if (handler) {
                event.preventDefault();
                _this.onSelect(handler());
            }
        };
        _this.renderContent = function (tabProps) {
            var selected = tabProps.selected, children = tabProps.children, tabContentStyle = tabProps.tabContentStyle;
            var childrenCount = React.Children.count(children);
            if (selected < childrenCount && selected > -1) {
                return (React.createElement(TabStripContent, __assign({ index: selected }, tabProps, { style: tabContentStyle })));
            }
            return null;
        };
        _this.firstNavigatableTab = function () {
            var children = _this.children();
            var length = React.Children.count(children);
            if (children) {
                for (var i = 0; i < length; i++) {
                    if (!children[i].props.disabled) {
                        return i;
                    }
                }
            }
        };
        _this.lastNavigatableTab = function () {
            var children = _this.children();
            var length = React.Children.count(children);
            if (children) {
                for (var i = length - 1; i > 0; i--) {
                    if (!children[i].props.disabled) {
                        return i;
                    }
                }
            }
        };
        _this.prevNavigatableTab = function () {
            var children = _this.children();
            var selected = _this.props.selected;
            var index = selected ? selected - 1 : -1;
            if (index < 0) {
                return _this.lastNavigatableTab();
            }
            if (children) {
                for (var i = index; i > -1; i--) {
                    if (!children[i].props.disabled) {
                        return i;
                    }
                    if (i === 0) {
                        return _this.lastNavigatableTab();
                    }
                }
            }
        };
        _this.nextNavigatableTab = function () {
            var children = _this.children();
            var selected = _this.props.selected;
            var index = selected ? selected + 1 : 1;
            var childrenCount = React.Children.count(children);
            if (index >= childrenCount) {
                return _this.firstNavigatableTab();
            }
            if (children) {
                for (var i = index; i < childrenCount; i++) {
                    if (!children[i].props.disabled) {
                        return i;
                    }
                    if (i + 1 === childrenCount) {
                        return _this.firstNavigatableTab();
                    }
                }
            }
        };
        validatePackage(packageMetadata);
        return _this;
    }
    /**
     * @hidden
     */
    TabStrip.prototype.render = function () {
        var _this = this;
        var tabProps = __assign(__assign({}, this.props), { children: this.children(), onKeyDown: this.onKeyDown, onSelect: this.onSelect });
        var tabPosition = tabProps.tabPosition, _a = tabProps.tabIndex, tabIndex = _a === void 0 ? 0 : _a;
        var bottom = tabPosition === 'bottom';
        var componentClasses = classNames('k-tabstrip', {
            'k-tabstrip-left': tabPosition === 'left',
            'k-tabstrip-right': tabPosition === 'right',
            'k-tabstrip-bottom': tabPosition === 'bottom',
            'k-tabstrip-top': tabPosition === 'top'
        }, this.props.className);
        return (React.createElement("div", { id: this.props.id, ref: function (div) { return _this._element = div; }, dir: this.props.dir, className: componentClasses, style: this.props.style },
            !bottom && React.createElement(TabStripNavigation, __assign({}, tabProps, { tabIndex: tabIndex })),
            this.renderContent(tabProps),
            bottom && React.createElement(TabStripNavigation, __assign({}, tabProps, { tabIndex: tabIndex }))));
    };
    TabStrip.prototype.invertKeys = function (original, inverted) {
        var rtl = this._element && (getComputedStyle(this._element).direction === 'rtl') || false;
        return rtl ? inverted : original;
    };
    TabStrip.prototype.children = function () {
        return React.Children.toArray(this.props.children).filter(function (c) { return c; });
    };
    /**
     * @hidden
     */
    TabStrip.propTypes = {
        animation: PropTypes.bool,
        children: PropTypes.node,
        onSelect: PropTypes.func,
        selected: PropTypes.number,
        style: PropTypes.object,
        tabContentStyle: PropTypes.object,
        tabPosition: PropTypes.string,
        tabIndex: PropTypes.number,
        className: PropTypes.string,
        dir: PropTypes.string
    };
    /**
     * @hidden
     */
    TabStrip.defaultProps = {
        animation: true,
        tabPosition: 'top',
        keepTabsMounted: false
    };
    return TabStrip;
}(React.Component));
export { TabStrip };
