import * as React from 'react';
import * as PropTypes from 'prop-types';
import { validatePackage } from '@progress/kendo-licensing';
import { Icon } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
/**
 * Represents the BreadcrumbDelimiter component.
 */
export var BreadcrumbDelimiter = React.forwardRef(function (props, ref) {
    validatePackage(packageMetadata);
    var target = React.useRef(null);
    var breadcrumbDelimiterRef = React.useRef(null);
    React.useImperativeHandle(target, function () {
        var _a;
        return ({
            element: ((_a = breadcrumbDelimiterRef.current) === null || _a === void 0 ? void 0 : _a.element) || null,
            props: props
        });
    });
    React.useImperativeHandle(ref, function () { return target.current; });
    return (React.createElement(Icon, { ref: breadcrumbDelimiterRef, id: props.id, tabIndex: props.tabIndex, style: props.style, name: "chevron-right", className: "k-breadcrumb-delimiter-icon" }));
});
var propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    tabIndex: PropTypes.number
};
BreadcrumbDelimiter.displayName = 'KendoReactBreadcrumbDelimiter';
BreadcrumbDelimiter.propTypes = propTypes;
