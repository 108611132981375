"use strict";

import { faPlus, faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { can, generateTooltip } from "../../utils/ui_permissions";
import React from "react";
import CommonSecurity from "../../../server/common/generic/common_security";

export const VERSION_REGEX = "^(\\s*|0|[0-9]\\d*|0\\.1)$";

/**
 * @enum {IEditorTabInfo}
 */
export const DOCUMENT_EDITOR_TABS = {
  DOCUMENT: {
    key: "Document",
    title: "Final Document",
    fullWidth: true,
    fullHeight: true,
  },
  SOURCE_DOC: {
    key: "SourceDoc",
    title: "Source Doc",
    fullWidth: true,
    fullHeight: true,
  },
  ABOUT: {
    key: "About",
    title: "About",
    fullWidth: false,
    fullHeight: false,
  },
};

export const QUICKPANEL_WARNING_MESSAGES = {
  DRAFT_WITH_PDF: "This PDF may differ from QbDVision Doc. There might be changes made to the QbDVision Doc after generating this PDF.",
  PROPOSED: "This PDF is proposed for approval, we will add signatures once it’s approved.",
  DRAFT_WITH_APPROVED: "A draft is made after this PDF was approved, that may have some latest changes.",
};

export const FIELD_NAMES = {
  VERSION: "version",
  CUSTOM_ID: "customID",
  DOCUMENT_NAME: "documentName",
  EFFECTIVE_DATE: "effectiveDate",
};

export const ADD_TYPES = [
  {
    value: "Add",
    icon: faPlus,
    key: "add",
    disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.DOCUMENT),
    title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.DOCUMENT),
  },
  {
    value: "Add multiple",
    icon: faLayerGroup,
    key: "bulkAdd",
    disabled: !can(CommonSecurity.Actions.BULK_ADD, CommonSecurity.Types.DOCUMENT),
    title: generateTooltip(CommonSecurity.Actions.BULK_ADD, CommonSecurity.Types.DOCUMENT),
  },
];

export const TABLE_FIELDS = {
  VERSION: {
    title: "Version",
    tooltip: "Set the first major version of the document in the system (e.g., enter v5.0 as 5). To upload the document as a draft, leave this number as blank, 0, or 0.1."
  },
  CUSTOM_ID: {
    title: "Custom ID",
    tooltip: "Use this field to provide additional identifying information about the document (e.g. type and protocol number - PRO-0001)"
  },
  DOCUMENT_NAME: {
    title: "Document Name",
    tooltip: "The name of the document as it will appear throughout the system"
  },
};
