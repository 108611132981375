var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, Keys, guid, dispatchEvent } from '@progress/kendo-react-common';
import { getDirectParentId, isIdEmptyOrZeroLevel, EMPTY_ID, ZERO_LEVEL_ZERO_ITEM_ID, getRootParentId, getItemById } from '../utils/itemsIdsUtils';
import { prepareInputItemsForInternalWork } from '../utils/prepareInputItemsForInternalWork';
import { getNewItemIdUponKeyboardNavigation } from '../utils/getNewItemIdUponKeyboardNavigation';
import { getHoverOpenDelay, getHoverCloseDelay } from '../utils/hoverDelay';
import { MenuItemInternalsList } from './MenuItemInternalsList';
import { DirectionHolder } from '../utils/DirectionHolder';
import { MouseOverHandler } from '../utils/MouseOverHandler';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../../package-metadata';
var initialItemsIds = {
    focusedItemId: EMPTY_ID,
    hoveredItemId: EMPTY_ID,
    tabbableItemId: ZERO_LEVEL_ZERO_ITEM_ID
};
/**
 * Represents the [KendoReact Menu component]({% slug overview_menu %}).
 *
 * @example
 * ```jsx
 * class App extends React.Component {
 *    render() {
 *        return (
 *            <Menu>
 *                <MenuItem text="Item1" />
 *                <MenuItem text="Item2">
 *                    <MenuItem text="Item2.1" />
 *                    <MenuItem text="Item2.2" />
 *                </MenuItem>
 *                <MenuItem text="Item3" />
 *            </Menu>
 *        );
 *    }
 * }
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var Menu = /** @class */ (function (_super) {
    __extends(Menu, _super);
    function Menu(props) {
        var _this = _super.call(this, props) || this;
        _this.guid = guid();
        _this.directionHolder = new DirectionHolder();
        _this.inputItems = [];
        _this.items = [];
        /**
         * Resets the selection and opening of Menu items.
         */
        _this.reset = function () {
            _this.clearItemHoverAndLeaveRequestsIfApplicable();
            _this.setState(initialItemsIds);
        };
        _this.onKeyDown = function (event) {
            // The focusedItemId may be empty when contentRender is used.
            // For example, content with input.
            if (_this.state.focusedItemId !== EMPTY_ID) {
                var currentItem = getItemById(_this.state.focusedItemId, _this.items);
                var newItemId = getNewItemIdUponKeyboardNavigation(_this.items, currentItem.id, event.keyCode, event.key, _this.props.vertical, _this.directionHolder.getIsDirectionRightToLeft());
                if (currentItem.id !== newItemId) {
                    event.preventDefault();
                    _this.setFocusedItemId(newItemId);
                }
                if ((event.keyCode === Keys.enter || event.keyCode === Keys.space) && !currentItem.disabled) {
                    _this.mouseOverHandler.handleItemSelectedViaKeyboard();
                    _this.dispatchSelectEventIfWired(event, currentItem.id);
                    if (!event.isDefaultPrevented() && currentItem.items.length === 0 && currentItem.url) {
                        window.location.assign(currentItem.url);
                    }
                }
            }
        };
        _this.onItemMouseOver = function (itemId) {
            if (_this.mouseOverHandler.IsMouseOverEnabled) {
                // The `over` event can get fired even without actually leaving the item.
                // For example, move the mouse little by little over the item content.
                _this.clearItemHoverAndLeaveRequestsIfApplicable();
                _this.itemHoverRequest = window.setTimeout(function () {
                    _this.setHoveredItemId(itemId);
                    _this.itemHoverRequest = null;
                }, getHoverOpenDelay(_this.props));
            }
        };
        _this.onItemMouseLeave = function (itemId) {
            if (_this.mouseOverHandler.IsMouseOverEnabled && _this.isItemWithDefaultClose(itemId)) {
                // Both the `leave` and `hover` requests are cleared
                // to be defensive and consistent with the `over` handler.
                _this.clearItemHoverAndLeaveRequestsIfApplicable();
                _this.itemLeaveRequest = window.setTimeout(function () {
                    _this.setHoveredItemId(EMPTY_ID);
                    _this.itemLeaveRequest = null;
                }, getHoverCloseDelay(_this.props));
            }
        };
        _this.onItemMouseDown = function () {
            _this.mouseOverHandler.handleItemMouseDown();
        };
        _this.onItemFocus = function (itemId) {
            _this.setFocusedItemId(itemId);
            _this.mouseOverHandler.handleItemFocus();
        };
        _this.onItemClick = function (event, itemId) {
            var item = getItemById(itemId, _this.items);
            if (!item.disabled) {
                _this.setFocusedItemId(itemId);
                _this.mouseOverHandler.handleItemClick(itemId, _this.isItemWithDefaultClose(itemId));
                _this.dispatchSelectEventIfWired(event, itemId);
                if (!event.isDefaultPrevented() && item.url) {
                    window.location.assign(item.url);
                }
            }
        };
        _this.onItemBlur = function (itemId) {
            if (_this.isItemWithDefaultClose(itemId)) {
                _this.setFocusedItemId(EMPTY_ID);
            }
        };
        _this.getInputItem = function (itemId) {
            return getItemById(itemId, _this.inputItems);
        };
        validatePackage(packageMetadata);
        _this.mouseOverHandler = new MouseOverHandler(_this.props.openOnClick, _this.reset, _this.onItemMouseOver);
        _this.state = Object.assign({}, initialItemsIds, { isFirstRender: true });
        return _this;
    }
    /**
     * @hidden
     */
    Menu.prototype.render = function () {
        var _this = this;
        this.prepareItems();
        if (!this.state.isFirstRender) {
            this.directionHolder.setIsDirectionRightToLeft(this.checkIsDirectionRightToLeft());
        }
        var lastItemIdToBeOpened = this.state.hoveredItemId ?
            this.state.hoveredItemId :
            this.state.focusedItemId ? getDirectParentId(this.state.focusedItemId) : EMPTY_ID;
        return (React.createElement("div", { onKeyDown: this.onKeyDown, style: this.props.style, className: this.getMenuWrapperClassName(), ref: function (el) { return _this.menuWrapperEl = el; } },
            React.createElement(MenuItemInternalsList, { className: this.getMenuClassName(), "aria-orientation": this.props.vertical ? 'vertical' : undefined, items: this.items, isMenuVertical: this.props.vertical, isDirectionRightToLeft: this.directionHolder.getIsDirectionRightToLeft(), focusedItemId: this.state.focusedItemId, lastItemIdToBeOpened: lastItemIdToBeOpened, tabbableItemId: this.state.tabbableItemId, itemRender: this.props.itemRender, linkRender: this.props.linkRender, menuGuid: this.guid, onMouseLeave: this.onItemMouseLeave, onMouseOver: this.onItemMouseOver, onMouseDown: this.onItemMouseDown, onFocus: this.onItemFocus, onClick: this.onItemClick, onBlur: this.onItemBlur, onOriginalItemNeeded: this.getInputItem })));
    };
    /**
     * @hidden
     */
    Menu.prototype.componentDidMount = function () {
        this.setState({ isFirstRender: false });
    };
    /**
     * @hidden
     */
    Menu.prototype.componentDidUpdate = function (prevProps) {
        // Reset the Menu upon big UI changes
        // to avoid misleading the user and to
        // keep the component consistent.
        if (Boolean(prevProps.vertical) !== Boolean(this.props.vertical) ||
            this.directionHolder.hasDirectionChanged()) {
            this.reset();
        }
        this.mouseOverHandler.OpenOnClick = this.props.openOnClick;
    };
    /**
     * @hidden
     */
    Menu.prototype.componentWillUnmount = function () {
        this.clearItemHoverAndLeaveRequestsIfApplicable();
    };
    Menu.prototype.setFocusedItemId = function (focusedItemId) {
        this.setState(function (prevState) {
            var tabbableItemId = focusedItemId === EMPTY_ID ?
                prevState.tabbableItemId : getRootParentId(focusedItemId);
            var hoveredItemId = focusedItemId === EMPTY_ID ||
                (isIdEmptyOrZeroLevel(prevState.hoveredItemId) && isIdEmptyOrZeroLevel(focusedItemId)) ?
                prevState.hoveredItemId : EMPTY_ID;
            return { hoveredItemId: hoveredItemId, focusedItemId: focusedItemId, tabbableItemId: tabbableItemId };
        });
    };
    Menu.prototype.setHoveredItemId = function (hoveredItemId) {
        this.setState(function (prevState) {
            if (isIdEmptyOrZeroLevel(hoveredItemId) && isIdEmptyOrZeroLevel(prevState.focusedItemId)) {
                return {
                    hoveredItemId: hoveredItemId,
                    focusedItemId: prevState.focusedItemId,
                    tabbableItemId: prevState.tabbableItemId
                };
            }
            else {
                return { hoveredItemId: hoveredItemId, focusedItemId: EMPTY_ID, tabbableItemId: ZERO_LEVEL_ZERO_ITEM_ID };
            }
        });
    };
    Menu.prototype.getMenuWrapperClassName = function () {
        return classNames({
            'k-rtl': this.directionHolder.getIsDirectionRightToLeft()
        }, this.props.className);
    };
    Menu.prototype.getMenuClassName = function () {
        return classNames('k-widget', 'k-reset', 'k-header', 'k-menu', { 'k-menu-horizontal': !this.props.vertical }, { 'k-menu-vertical': this.props.vertical });
    };
    Menu.prototype.clearItemHoverAndLeaveRequestsIfApplicable = function () {
        if (this.itemHoverRequest) {
            clearTimeout(this.itemHoverRequest);
            this.itemHoverRequest = null;
        }
        if (this.itemLeaveRequest) {
            clearTimeout(this.itemLeaveRequest);
            this.itemLeaveRequest = null;
        }
    };
    Menu.prototype.isItemWithDefaultClose = function (itemId) {
        return !this.props.customCloseItemIds || this.props.customCloseItemIds.indexOf(itemId) === -1;
    };
    Menu.prototype.checkIsDirectionRightToLeft = function () {
        return this.props.dir !== undefined ? this.props.dir === 'rtl' :
            this.menuWrapperEl && getComputedStyle(this.menuWrapperEl).direction === 'rtl';
    };
    Menu.prototype.prepareItems = function () {
        var _a = prepareInputItemsForInternalWork(this.props.items, this.props.children), items = _a.items, inputItems = _a.inputItems;
        this.items = items;
        this.inputItems = inputItems;
    };
    Menu.prototype.dispatchSelectEventIfWired = function (event, itemId) {
        dispatchEvent(this.props.onSelect, event, this, { item: this.getInputItem(itemId), itemId: itemId });
    };
    /**
     * @hidden
     */
    Menu.propTypes = {
        vertical: PropTypes.bool,
        items: PropTypes.arrayOf(PropTypes.object),
        style: PropTypes.object,
        dir: PropTypes.string,
        hoverOpenDelay: PropTypes.number,
        hoverCloseDelay: PropTypes.number,
        openOnClick: PropTypes.bool,
        itemRender: PropTypes.any,
        linkRender: PropTypes.any,
        customCloseItemIds: PropTypes.arrayOf(PropTypes.string),
        onSelect: PropTypes.func
    };
    /**
     * @hidden
     */
    Menu.defaultProps = { vertical: false };
    return Menu;
}(React.Component));
export { Menu };
