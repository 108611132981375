import { CalendarViewEnum } from '../models/CalendarViewEnum';
import { domContainerFactory as containerFactory } from '../../utils';
import { canUseDOM } from '@progress/kendo-react-common';
/**
 * @hidden
 */
var DOMService = /** @class */ (function () {
    function DOMService() {
        // heights
        this.calendarHeight = 0;
        this.headerHeight = 0;
        this.monthViewHeight = 0;
        this.yearViewHeight = 0;
        this.decadeViewHeight = 0;
        this.centuryViewHeight = 0;
        this.navigationItemHeight = 0;
        this.scrollableContentHeight = 0;
        this.scrollableYearContentHeight = 0;
        // widths
        this.calendarWidth = 0;
        this.monthViewWidth = 0;
        this.yearViewWidth = 0;
        this.decadeViewWidth = 0;
        this.centuryViewWidth = 0;
        this.scrollableContentWidth = 0;
        this.didCalculate = false;
        this.hostContainer = null;
    }
    DOMService.prototype.ensureHeights = function () {
        if (this.calendarHeight !== undefined) {
            return;
        }
        this.calculateHeights();
    };
    DOMService.prototype.calculateHeights = function (container) {
        var _this = this;
        if (!canUseDOM) {
            return;
        }
        var div = containerFactory('div');
        var ul = containerFactory('ul');
        var li = containerFactory('li');
        var td = containerFactory('td');
        var th = containerFactory('th');
        var tr = containerFactory('tr');
        var tbody = containerFactory('tbody');
        var thead = containerFactory('thead');
        var table = containerFactory('table');
        var monthHeader = function () { return (div("\n            <span class=\"k-button k-button-md k-button-rectangle k-button-solid k-button-solid-base k-button-flat k-calendar-title\">\n                <span className=\"k-button-text\">March 2017</span>\n            </span>\n            <span class=\"k-spacer\"></span>\n            <span class=\"k-calendar-nav k-hstack\"><span class=\"k-calendar-nav-today\">TODAY</span></span>\n        ", 'k-calendar-header k-hstack')); };
        var monthWeekHeader = function () { return (table([
            thead([
                tr([th('MO', 'k-calendar-th')], 'k-calendar-tr')
            ], 'k-calendar-thead')
        ], 'k-calendar-table k-calendar-weekdays')); };
        var repeat = function (count, mapper) { return new Array(count).fill('1').map(mapper); };
        var content = function (rows, cells) {
            if (cells === void 0) { cells = 1; }
            return (table([
                tbody([tr([td('1', 'k-calendar-td k-alt')])].concat(repeat(rows, function () { return tr(repeat(cells, function (c) { return td("<span class=\"k-link\">".concat(c, "</span>"), 'k-calendar-td'); }), 'k-calendar-tr'); })), 'k-calendar-tbody')
            ], 'k-calendar-table'));
        };
        var scrollable = function (children) { return div(children, 'k-content k-calendar-content k-scrollable'); };
        var view = function (contentElement, className, renderWeekHeader) { return (div(renderWeekHeader
            ? [
                monthHeader(),
                monthWeekHeader(),
                scrollable([contentElement, contentElement])
            ]
            : [
                monthHeader(),
                scrollable([contentElement, contentElement])
            ], className, { left: '-10000px', position: 'absolute' })); };
        var navigationList = (function () {
            var navElement;
            return function () {
                if (!canUseDOM) {
                    return null;
                }
                if (!navElement) {
                    navElement = div([scrollable([ul([li('<span>FEB</span>')])])], 'k-calendar-navigation', { left: '0px', position: 'absolute' });
                }
                return navElement;
            };
        })();
        var viewFactory = function (_a, className, renderWeekHeader) {
            var cells = _a.cells, rows = _a.rows;
            var viewElement;
            return function () {
                if (!canUseDOM) {
                    return null;
                }
                if (!viewElement) {
                    viewElement = view(content(rows, cells), className, renderWeekHeader);
                }
                return viewElement;
            };
        };
        var getScrollable = function (element) { return element.querySelector('.k-scrollable'); };
        var horizontal = function (element) {
            var scrollableElement = getScrollable(element);
            scrollableElement.className = "".concat(scrollableElement.className, " k-scrollable-horizontal");
            return element;
        };
        var monthView = viewFactory({ cells: 7, rows: 6 }, 'k-calendar-view k-calendar-monthview k-vstack', true);
        var yearView = viewFactory({ cells: 5, rows: 3 }, 'k-calendar-view k-calendar-yearview', false);
        var decadeView = viewFactory({ cells: 5, rows: 2 }, 'k-calendar-view k-calendar-decadeview', false);
        var horzMonthView = function () { return horizontal(monthView()); };
        var horzYearView = function () { return horizontal(yearView()); };
        var horzDecadeView = function () { return horizontal(decadeView()); };
        var height = function (element) { return (parseFloat(window.getComputedStyle(element).height) || element.offsetHeight); };
        var width = function (element) {
            var styles = window.getComputedStyle(element);
            var computed = parseFloat(styles.width)
                + parseFloat(styles.paddingLeft)
                + parseFloat(styles.paddingRight);
            return computed || element.offsetWidth;
        };
        var getBody = function (element) { return element.querySelector('tbody'); };
        this.didCalculate = true;
        if (container) {
            this.hostContainer = container;
        }
        this.batch(monthView(), function (contentElement) {
            var viewElement = getBody(contentElement);
            _this.calendarHeight = height(contentElement);
            _this.monthViewHeight = height(viewElement);
            _this.headerHeight = height(viewElement.children[0]);
            _this.scrollableContentHeight = height(getScrollable(contentElement));
        });
        this.batch(horzMonthView(), function (contentElement) {
            var viewElement = getBody(contentElement);
            _this.calendarWidth = width(contentElement);
            _this.monthViewWidth = width(viewElement);
            _this.scrollableContentWidth = width(getScrollable(contentElement));
        });
        this.batch(yearView(), function (contentElement) {
            _this.yearViewHeight = height(getBody(contentElement));
            _this.scrollableYearContentHeight = height(getScrollable(contentElement));
        });
        this.batch(horzYearView(), function (contentElement) {
            _this.yearViewWidth = width(getBody(contentElement));
        });
        this.batch(decadeView(), function (contentElement) {
            _this.decadeViewHeight = height(getBody(contentElement));
            _this.centuryViewHeight = _this.decadeViewHeight;
        });
        this.batch(horzDecadeView(), function (contentElement) {
            _this.decadeViewWidth = width(getBody(contentElement));
            _this.centuryViewWidth = _this.decadeViewWidth;
        });
        this.batch(navigationList(), function (contentElement) {
            _this.navigationItemHeight = height(contentElement.querySelector('li'));
        });
    };
    DOMService.prototype.viewHeight = function (viewType) {
        return this.viewDimension(viewType, 'height');
    };
    DOMService.prototype.viewWidth = function (viewType) {
        return this.viewDimension(viewType, 'width');
    };
    DOMService.prototype.viewDimension = function (viewType, dimension) {
        var viewProp = dimension === 'height' ? 'ViewHeight' : 'ViewWidth';
        switch (viewType) {
            case CalendarViewEnum.month:
                return this["month".concat(viewProp)];
            case CalendarViewEnum.year:
                return this["year".concat(viewProp)];
            case CalendarViewEnum.decade:
                return this["decade".concat(viewProp)];
            case CalendarViewEnum.century:
                return this["century".concat(viewProp)];
            default:
                return 1;
        }
    };
    DOMService.prototype.batch = function (contentElement, action) {
        var hostContainer = this.hostContainer || document.body;
        var appendedContent = hostContainer.appendChild(contentElement);
        action(appendedContent);
        hostContainer.removeChild(appendedContent);
    };
    return DOMService;
}());
export { DOMService };
