"use strict";

import React, { Fragment } from "react";
import TrainingReportBase from "./canned_report_training_base";
import * as CannedReportTrainingFilter from "./canned_report_training_filter";
import { TRAINING_STATUS } from "../../data_transform/canned_reports/training/constants/constants";
import * as CommonTraining from "../../../../server/common/editables/common_training";
import * as UIUtils from "../../../ui_utils";

/* This class shows Individual Training canned report */
export default class IndividualTrainingCannedReport extends TrainingReportBase {

  constructor(props) {
    super(props);
    this.user = UIUtils.getParameterByName("user");
    if (this.user) {
      this.state.filterOptions.status = "Overdue";
      this.state.filterOptions.filteredUsers = [Number(this.user)];
    }
  }
  get defaultFilterValues() {
    return {
      reportDate: this.state.reportDate,
      status: TRAINING_STATUS.COMPLETED,
      version: "latest"
    };
  }

  get typeaheadFilterInfo() {
    return {
      options: this.typeaheadOptions,
      label: "Users",
      statePropName: "filteredUsers",
      user: Number(this.user),
    };
  }

  get typeaheadOptions() {
    let data = this.results.instances.reportData;
    let {users} = data;

    return users.map(user => {
      return {
        label: `${user.firstName} ${user.lastName}`,
        id: user.id
      };
    });
  }

  renderFilters() {
    if (this.results) {
      return <Fragment>
        <div className="col-sm-3">
          {this.renderTypeaheadFilter()}
        </div>
        <div className="col-sm-2">
          {this.renderStatusFilter()}
        </div>
        <div className="col-sm-2">
          {this.renderVersionFilter()}
        </div>
      </Fragment>;
    }
    return null;
  }

  filterResults() {
    let filterOptions = this.state.filterOptions;
    let {version, filteredUsers, status} = filterOptions;
    let filterUsers = false;

    let data = this.results.instances.reportData;
    let {instances, users} = data;

    // Filter by users
    if (filteredUsers && filteredUsers.length > 0) {
      instances = instances.filter(instance => filteredUsers.includes(instance.userId));
      filterUsers = true;
    }

    // Filter results by latest version
    if (version === "latest") {
      instances = CommonTraining.getDocumentsLatestVersions(instances);
    }

    // Filter by status
    instances = CannedReportTrainingFilter.filterByStatus(instances, status);
    filterUsers = filterUsers || status === TRAINING_STATUS.COMPLETED || status === TRAINING_STATUS.OVERDUE;

    // Only show for users within filtered instances
    if (filterUsers) {
      if (filteredUsers && filteredUsers.length > 0) {
        users = users.filter(user => filteredUsers.includes(user.id));
      } else {
        let uniqueUserIds = new Set(instances.map(instance => instance.userId));
        users = users.filter(user => uniqueUserIds.has(user.id));
      }
    }

    let results = {
      instances: {
        reportData: {
          instances,
          users,
        }
      }
    };

    this.wrapUp(results);
  }
}
