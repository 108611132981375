"use strict";

import React from "react";
import LabelTooltip from "../../widgets/tooltips/label_tooltip";
import BasePopup from "../../editor/approval/base_popup";
import ReactDOMServer from "react-dom/server";
import ReactDOM from "react-dom";
import { FIELD_INPUT_TYPE } from "./edit_risk_links_confirmation_constants";
import * as UIUtils from "../../ui_utils";
import InfoTooltip from "../tooltips/info_tooltip";
import * as DocumentTransferHelper from "../../helpers/document_transfer_helper";

export class BaseRiskLinksConfirmationPopup extends BasePopup {

  constructor(props, widgetFields) {
    super(props);
    this.widgetFields = widgetFields;

    this.getColumn = this.getColumn.bind(this);
    this.getColumnDef = this.getColumnDef.bind(this);
    this.initializeColumns = this.initializeColumns.bind(this);
    this.reloadDataTable = this.reloadDataTable.bind(this);
    this.initializeColumnsDefs = this.initializeColumnsDefs.bind(this);
    this.initializeDataTable = this.initializeDataTable.bind(this);

    const {documents} = this.props;
    this.setStateSafely({
      documents
    });
  }

  componentDidMount() {
    super.componentDidMount();
    this.initializeDataTable();
  }

  componentDidUpdate() {
    this.reloadDataTable();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    if (this.table) {
      $(this.tableRef).DataTable().destroy();
    }
  }

  reloadDataTable() {
    if (this.table) {
      const table = $(this.tableRef).DataTable();
      table.clear();
      table.rows.add(this.state.documents);
      table.draw();
    }
  }

  initializeDataTable() {

    if (this.table) {
      $(this.tableRef).DataTable().destroy();
    }

    this.initializeColumns();
    this.initializeColumnsDefs();

    this.table = $(this.tableRef).DataTable({
      dom: "tr",
      data: this.state.documents,
      paging: false,
      deferRender: true,
      columns: this.columns,
      columnDefs: this.columnDefs,
    });
  }

  getColumn(field, result) {
    if (!result) {
      return null;
    }
    return ReactDOMServer.renderToStaticMarkup(
      <span>{this.getFieldValue(field.fieldName, result)}</span>
    );
  }

  getFieldValue(fieldName, row) {
    if (fieldName === "link") {
      return row["fileName"] || row[fieldName];
    }
    return row[fieldName];
  }

  isFieldDisabled(field, rowData) {
    const {fieldName} = field;
    const camelCaseFieldName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
    return rowData[`disable${camelCaseFieldName}`] || field.disabled || false;
  }

  getColumnDef(field, row) {
    const {fieldName} = field;
    const id = fieldName + "_" + row.index;
    let value = this.getFieldValue(fieldName, row);
    if (field.inputType === FIELD_INPUT_TYPE.radio) {
      return (
        <label id={`${fieldName + "Label_" + field.order}`}
               className="radio-button-container"
        >
          <input type="radio" value={value}
                 id={`${fieldName + "Label_" + field.order}`}
                 name={row.uuid}
                 checked={value === true ? "checked" : ""}
                 disabled={this.isFieldDisabled(field, row)}
                 onChange={this.handleRowOptionChange}
          />
          {value}
        </label>

      );
    } else {
      return (
        <div className="links-manage">
              <span id={id}>
                {
                  field.inputType === FIELD_INPUT_TYPE.link
                    ? (
                      row.linkType !== "Attachment" ? (
                        <a href={UIUtils.cleanUpURL(row.link)}
                           rel="noopener noreferrer"
                           target="_blank"
                           className={"text-decoration-none pr-3"}
                        >
                          {value}
                        </a>) : (
                        <a href="javascript:void(0);"
                           rel="noopener noreferrer"
                           onClick={this.handleDownload.bind(this, row)}
                           className={"text-decoration-none pr-3"}
                        >
                          {value}
                        </a>
                      )
                    ) : (
                      <span>{value}</span>
                    )
                }
              </span>
          {field.dynamicToolTip
            ?
            <InfoTooltip id={`${fieldName + "Label_" + field.order}`}
                         textDirection="bottom"
                         verbiage={
                           (<div className={"m-1"}>
                             {
                               row["tooltipLinks"].length > 1
                                 ? <span className={"d-block mb-2"}>Risks</span>
                                 : <span className={"d-block mb-2"}>Risk</span>
                             }
                             {
                               row["tooltipLinks"].map((tooltipLink, idx) =>
                                 <div key={idx}>
                                   <a href={tooltipLink.href} className={"text-decoration-none pr-3"}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                   >{tooltipLink.link}
                                   </a>
                                 </div>
                               )
                             }
                           </div>)
                         }
            />
            : ""}
        </div>
      );
    }
  }

  initializeColumns() {
    this.columns = [];
    this.columns.push(...this.widgetFields
      .sort((a, b) => {
        return a.order - b.order;
      }).map(field => {
        return {
          title: ReactDOMServer.renderToStaticMarkup(
            <LabelTooltip text={field.displayName}
                          className="links-details-field-label"
                          noColon={true}
                          getTooltipCallback={() => field.tooltipText}
            />),
          width: field.width ? field.width : 200,
          data: (result, type) => {
            return this.getColumn(field, result, type);
          }
        };
      }));
  }

  initializeColumnsDefs() {

    this.columnDefs = [];
    this.targetCounter = 0;

    this.columnDefs.push(...this.widgetFields
      .sort(
        (a, b) => {
          return a.order - b.order;
        }).map(
        field => {
          return {
            targets: this.targetCounter++,
            createdCell: (td, cellData, rowData) => {
              ReactDOM.render((
                this.getColumnDef(field, rowData)
              ), td);
            },
          };
        })
    );

  }

  getOptionFromId(id) {
    return id.substring(0, id.indexOf("Label_"));
  }

  handleRowOptionChange(e, options) {
    const {documents} = this.state;
    const {id, name, checked} = e.target;
    const option = this.getOptionFromId(id);

    // Make sure default options have been reset to false.
    for (const key of Object.keys(options)) {
      options[key] = false;
    }

    // Reset all options to false except the checked option on the screen
    let modifiedDocuments = documents
      .map(doc => {
        if (doc.uuid === name) {
          return {
            ...doc,
            ...options,
            [option]: checked,
          };
        }
        return doc;
      });

    this.setStateSafely({
      documents: [...modifiedDocuments]
    });
  }

  handleConfirmAction(event) {
    UIUtils.Ensure.notImplemented("handleConfirmAction", {event});
  }

  render() {
    const {t, action} = this.props;

    return (
      <div className="modal fade risk-link-popup"
           ref={this.setModalRef}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">
                {action}
              </h1>
              <button type="button"
                      className="close"
                      onClick={this.handleCancel}
                      aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-container">
                <div className={"container"}>
                  {this.props.message ? (
                    <div className={"mt-0"}>
                      <h2 id="riskActionConfirmationMessage" className={"mt-0"}>
                        {this.props.message}
                      </h2>
                    </div>
                  ) : ""}
                  <div className={"mt-4"}>
                    <p>
                      Take an action on the supporting documents associated with this risk:
                    </p>
                  </div>
                  <table ref={ref => this.tableRef = ref}
                         className="table table-bordered table-hover doc-links-table"
                         id="riskActionConfirmationTable"
                         style={{width: "100%"}}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="modal-container">
                <div className="btn-group">
                  <button id="confirmButton"
                          type="button"
                          className="btn btn-primary"
                          onClick={this.handleConfirmAction}
                  >
                    Confirm
                  </button>
                  <button id="cancelButton"
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={this.handleCancel}
                  >
                    {t("Cancel")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleDownload(event) {
    DocumentTransferHelper.handleFileDownload(event);
  }
}