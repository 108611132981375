"use strict";

import * as UIUtils from "../../../../ui_utils";
import React, { Fragment } from "react";
import { calculateZoomPercentage } from "../../../helpers/import_helper";
import { COA_COLUMN_TAGS, SELECTED_CONTENT } from "../../../constants/import_constants";
import BaseReactComponent from "../../../../base_react_component";

/**
 * This is responsible for rendering result column divs in the text and document views.
 */
export default class OCRResultsColumn extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  static getOCRColumnId(view, tableId, columnIndex) {
    return `${view}_${tableId}_${columnIndex}`;
  }

  render() {
    const {
      zoomLevel, originalDocumentWidth, originalDocumentHeight, paperWidth, paperHeight, pageHeight,
      selectedContent, selectedView, textractResults, hideNonTaggedColumns,
      showAdvancedOptions
    } = this.props;
    let columns = textractResults && textractResults.columns ? textractResults.columns : [];
    if (hideNonTaggedColumns) {
      columns = columns.filter(column => column.isAttributesColumn || column.isResultsColumn);
    }

    /* Adjust the final applied zoom level for the rendered text based on the image scaling factor which is automatically
      applied to the image due to stretching
    */
    let zoomPercent = calculateZoomPercentage(zoomLevel, paperWidth, paperHeight, originalDocumentWidth, originalDocumentHeight);
    const tableIdToTableNum = new Map();
    let tableCounter = 1;
    for (let column of columns) {
      if (!tableIdToTableNum.has(column.tableId)) {
        tableIdToTableNum.set(column.tableId, tableCounter);
        tableCounter++;
      }
    }

    return (
      <Fragment>
        {
          columns.map(column => {
            const top = (pageHeight || 1) * (column.page - 1) + column.top * originalDocumentHeight * zoomPercent;
            const left = column.left * originalDocumentWidth * zoomPercent;
            const width = column.width * originalDocumentWidth * zoomPercent;
            const height = column.height * originalDocumentHeight * zoomPercent;
            const {tableId, columnIndex} = column;
            let columnsHidden = selectedContent === SELECTED_CONTENT.ALL_DATA;
            let columnCSSClass = column.isAttributesColumn ? "attribute"
              : column.isResultsColumn ? "result" : "none";
            let columnId = OCRResultsColumn.getOCRColumnId(selectedView.title, tableId, columnIndex);
            console.log(`TableID: ${column.tableId}, column-top: ${column.left}, column-left: ${column.left}`);
            let alignRight = (left + width) > 165;

            return (
              <div className={"import-data-ocr-text-table import-result-column "
                + columnCSSClass
                + (columnsHidden ? " hidden" : "")
                + ` table_${tableIdToTableNum.get(column.tableId)}`}
                   key={columnId}
                   id={columnId}
                   style={{
                     left: left + "px",
                     top: top + "px",
                     height: height + "px",
                     width: width + "px"
                   }}
              >
                <div className="btn-group"
                     style={{
                       top: 0 + "px",
                       left: width - 26 + "px"
                     }}
                >
                  <button id={`${columnId}_button`}
                          type="button"
                          className={"btn btn-secondary btn-sm dropdown-toggle smart-import-column-button" + (!showAdvancedOptions ? " hidden" : "")}
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                  >
                  </button>
                  <ul className={"dropdown-menu" + (alignRight ? " dropdown-menu-right" : "")}>
                    {Object.values(COA_COLUMN_TAGS).map(tag => {
                      let optionId = `${columnId}_${UIUtils.convertToId(tag)}`;
                      return <li key={optionId}
                                 className="dropdown-item"
                                 id={optionId}
                      >
                        <a href="#">{this.getTagLabel(tag)}</a>
                      </li>;
                    })}
                  </ul>
                </div>
              </div>
            );
          })
        }
      </Fragment>
    );
  }

  getTagLabel(tag) {
    return this.props.isLibraryMaterial && tag === COA_COLUMN_TAGS.MATERIAL_ATTRIBUTE ? "Material Specifications" : tag;
  }
}
