/**
 * @hidden
 */
export var ColumnDefaultProps = {
    filterable: true,
    editable: true,
    sortable: true,
    resizable: true,
    reorderable: true,
    groupable: true
};
