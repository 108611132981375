"use strict";

import * as UIUtils from "../ui_utils";
import BaseRequirementAttributePage from "./base_requirement_attribute_page";
import ComboBoxAttribute from "./attributes/combo_box_attribute";
import AggregateProcessRiskAttribute from "./attributes/riskTable/aggregate_process_risk_attribute";
import TextAreaAttribute from "./attributes/text_area_attribute";
import {
  getCalculationFormulaTooltip,
  getRiskFieldOptions,
  getRiskFieldTooltip, getRiskLabelTooltip,
  getRiskScaleTooltip,
} from "../helpers/risk_helper";
import InfoTooltip from "../widgets/tooltips/info_tooltip";
import { RISK_TYPE_ENUM } from "../helpers/constants/constants";
import CheckboxAttribute from "./attributes/checkbox_attribute";
import React from "react";
import AggregateRPNAttribute from "./attributes/riskTable/aggregate_rpn_attribute";
import Section from "./widgets/section";
import AggregateCriticalityAttribute from "./attributes/riskTable/aggregate_criticality_attribute";
import ReferenceDocuments from "@cherrycircle/fda-docs";
import { orderAndIndexSteps, orderAndIndexUnits } from "../processExplorer/indexers/uo_indexer";
import RiskSupportingDocumentsAttribute from "./widgets/risk_supporting_documents_attribute";
import * as CommonEditables from "../../server/common/editables/common_editables";
import TypeaheadAttribute from "./attributes/typeahead_attribute";
import RiskUtils from "../../server/common/misc/common_risk_utils";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";
import { measurePerformanceEnd, measurePerformanceStart } from "../ui_utils";
import { RiskLabelAttribute } from "./attributes/riskTable/risk_label_attribute";

/**
 * This is the base react class for attributes like Material Attributes (MAs), IQAs and PPs.
 */
// i18next-extract-mark-ns-start process_explorer
export default class BaseAttributePage extends BaseRequirementAttributePage {
  constructor(props, baseTypeName, capitalizedBaseTypeName, displayName) {
    super(props, baseTypeName, capitalizedBaseTypeName, displayName);
  }

  getTypesToCache() {
    // required for Risk Criticality computation in Typeahead and table
    const types = [
      "UnitOperation",
      "Step",
      "Material", "ProcessComponent",
      "RMP",
      "MaterialAttribute", "ProcessParameter",
      "IQA", "IPA", "FQA", "FPA",
    ];

    return [...new Set([...types, ...super.getTypesToCache()])];
  }

  fillRiskDocumentsAttributeDetails(callback) {
    const riskLinks = this.preprocessResult(this.state);
    this.setStateSafely({
      riskLinks,
    }, () => {
      if (callback && typeof callback === "function") {
        callback();
      }
    });
  }

  preprocessReceivedData(result) {
    if (result) {
      result.riskLinks = this.preprocessResult(result);
    }
    return super.preprocessReceivedData(result);
  }

  handleChangeValue(fieldName, value, callback, attributeType) {
    super.handleChangeValue(fieldName, value, callback, attributeType);
    if (fieldName === "riskLinks") {
      super.handleRiskLinksChangeValue(value);
    }
  }

  cleanRecordBeforeSentToServer(record) {
    super.cleanRiskLinksBeforeSentToServer(record);
    return super.cleanRecordBeforeSentToServer(record);
  }

  handleTypeaheadResultsFromServer(callback, results, typeCode, remainingTypesToCache) {
    super.handleTypeaheadResultsFromServer(callback, results, typeCode, remainingTypesToCache);
    if (typeCode === "UO") {
      this.handleUOTypeaheadResultsFromServer(() => {
        this.handleChangeParent(true);
      }, results);
    } else if (typeCode === "STP") {
      this.setStateSafely({
        _steps: results,
      });
    } else {
      this.handleChangeParent(false);
    }
  }

  handleUOTypeaheadResultsFromServer(callback, result) {
    let orderedUOList = orderAndIndexUnits(result);
    this.setStateSafely({
      _orderedUOList: orderedUOList,
      _thisUOIndex: orderedUOList.findIndex(uo => uo.id === this.state.UnitOperationId),
    }, callback);
  }

  getTabName() {
    return "Process Explorer";
  }

  resetState(newStateData, callback) {
    super.resetState(newStateData, () => {
      this.loadMultipleTypeaheads(callback);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    if (this.state.UnitOperationId !== prevState.UnitOperationId) {
      this.handleChangeParent(this.state.UnitOperationId !== prevState.UnitOperationId);
    }
  }

  /**
   * This is called when the UO changes.
   */
  handleChangeParent(ignored) {
    const newState = {};

    // Set the new list of UOs for choosing risk link IQAs
    if (this.state.UnitOperationId && this.state._orderedUOList) {
      newState._thisUOIndex = this.state._orderedUOList.findIndex(uo => uo.id === this.state.UnitOperationId);
    } else {
      newState._thisUOIndex = -1;
    }

    this.setStateSafely(newState);
  }

  /**
   * This is called on Save/Propose to verify the risk links are valid for the chosen UO.
   *
   * @return {*} The error to be displayed.
   */
  handleRiskVerification(callback, formValidationMode, rows) {

    let {StepId, UnitOperationId} = this.state;
    let orderedSteps = this.getOrderedSteps(UnitOperationId);
    let stepIndex = this.findStepById(StepId, orderedSteps);

    const intermediateAttributeModelTypes = ["IQA", "IPA"];

    const promises = intermediateAttributeModelTypes.map(typeCode => new TypeaheadObjectCache(typeCode, this.getProjectId(), this.getProcessId()).loadOptions().promise());
    return Promise.all(promises).then(responses => {
      const allErrors = responses.map((intermediateAttributes) => {
        const errors = [];
        const isThisAnInterMediateAttribute = intermediateAttributeModelTypes.includes(this.capitalizedBaseTypeName);
        const isThisAttributeForStep = !!StepId;

        for (const row of rows) {
          for (const attributeType of intermediateAttributeModelTypes) {
            const targetAttributeId = this.getTargetObjectIdFieldName(attributeType, row);
            const sourceAttributeIsFinalAttribute = !!row["FQAId"] || !!row["FPAId"];

            if (targetAttributeId && !sourceAttributeIsFinalAttribute) {
              const intermediateAttribute = intermediateAttributes.find(
                attribute => attribute.id === targetAttributeId && attribute.typeCode === attributeType,
              );

              if (intermediateAttribute) {
                const optionUOIndex = this.findUnitOperationById(intermediateAttribute.UnitOperationId);
                let optionOrderedSteps = this.getOrderedSteps(intermediateAttribute.UnitOperationId);
                const optionStepIndex = this.findStepById(intermediateAttribute.StepId, optionOrderedSteps);
                let uoIndex = this.state._thisUOIndex;
                if (!uoIndex) {
                  uoIndex = this.state._orderedUOList?.findIndex(uo => uo.id === this.state.UnitOperationId);
                }

                const isOptionUpstreamOrSameUO = optionUOIndex <= uoIndex;
                const isOptionNotAtTheSameUO = uoIndex >= 0 && optionUOIndex !== uoIndex;
                const isOptionUpstreamStepsOrSameStep = optionUOIndex === uoIndex && optionStepIndex <= stepIndex && optionStepIndex !== -1;
                const isOptionUpstreamSteps = optionUOIndex === uoIndex && optionStepIndex < stepIndex && optionStepIndex !== -1;

                if ((!isThisAttributeForStep && isThisAnInterMediateAttribute && isOptionUpstreamOrSameUO)
                  || (!isThisAttributeForStep && !isThisAnInterMediateAttribute && isOptionNotAtTheSameUO)
                  || (isThisAttributeForStep && isThisAnInterMediateAttribute && isOptionUpstreamStepsOrSameStep)
                  || (isThisAttributeForStep && !isThisAnInterMediateAttribute && isOptionUpstreamSteps)) {
                  errors.push("* " + UIUtils.getRecordLabelForDisplay(attributeType, intermediateAttribute.id, intermediateAttribute.name));
                }
              }
            }
          }
        }

        if (errors.length > 0) {
          if (isThisAnInterMediateAttribute) {
            if (isThisAttributeForStep) {
              errors.unshift(this.props.t(`The following risks would be in the same Step or upstream Steps and therefore invalid:`));
            } else {
              errors.unshift(this.props.t("The following risks would be in the same Unit Operation or upstream and therefore invalid:"));
            }
          } else {
            if (isThisAttributeForStep) {
              errors.unshift(this.props.t(`The following risks would be in the upstream Steps and therefore invalid:`));
            } else {
              errors.unshift(this.props.t("The following risks would not be in the same Unit Operation and therefore invalid:"));
            }
          }
        }

        return errors;
      }).flat();

      callback(allErrors);
      return allErrors;
    });
  }

  getTargetObjectIdFieldName(attributeType, row) {
    return (attributeType === this.capitalizedBaseTypeName)
      ? row[`Target${attributeType}Id`]
      : row[`${attributeType}Id`];
  }

  filterRiskLinkTypeaheadOptions(option) {
    let {StepId, UnitOperationId} = this.state;
    let orderedSteps = this.getOrderedSteps(UnitOperationId);
    let stepIndex = this.findStepById(StepId, orderedSteps);

    let isValid = false;
    if (option.typeCode === "IQA" || option.typeCode === "IPA") {
      let optionUOIndex = this.findUnitOperationById(option.UnitOperationId);
      let optionUOOrderedSteps = this.getOrderedSteps(option.UnitOperationId);
      let optionStepIndex = this.findStepById(option.StepId, optionUOOrderedSteps);
      let uoIndex = this.state._thisUOIndex;
      if (!uoIndex) {
        uoIndex = this.state._orderedUOList?.findIndex(uo => uo.id === this.state.UnitOperationId);
      }

      if (this.capitalizedBaseTypeName === "IQA" || this.capitalizedBaseTypeName === "IPA") {
        isValid = (stepIndex >= 0 && (optionUOIndex === uoIndex && (optionStepIndex > stepIndex || optionStepIndex === -1)) /* Downstream steps OR current UO */ ||
            (optionUOIndex > uoIndex)) /* Downstream UOs from any step */ ||
          stepIndex === -1 && (optionUOIndex > uoIndex && optionStepIndex === -1);
      } else if (uoIndex >= 0) {
        isValid = (optionUOIndex === uoIndex && optionStepIndex === -1 && stepIndex === -1) /* UO Level options with no Step, if attribute is UO Level... */ ||
          (optionUOIndex === uoIndex && optionStepIndex >= stepIndex && stepIndex !== -1) /* Options from downstream or same step for Step Level attributes */ ||
          (optionUOIndex === uoIndex && optionStepIndex === -1); /* UO Level options with no steps */
      } else {
        // This is a process level attribute.
        isValid = true;
      }
    } else {
      isValid = true;
    }

    if (isValid) {
      const riskInfo = this.getRiskAndParentsInfo(option, option.typeCode, "id");
      if (riskInfo) {
        option.riskCriticalityLabel = riskInfo.riskCriticalityLabel;
        option.riskCriticalityStyle = riskInfo.riskCriticalityStyle;
        option.parents = riskInfo.parents;
      }
    }

    return isValid;
  }

  findUnitOperationById(unitOperationId) {
    return unitOperationId && this.state._orderedUOList ? this.state._orderedUOList.findIndex(uo => uo.id === unitOperationId) : -1;
  }

  findStepById(stepId, orderedSteps) {
    return stepId && orderedSteps ? orderedSteps.findIndex(stp => stp.id === stepId) : -1;
  }

  getOrderedSteps(unitOperationId) {
    const {_steps} = this.state;
    let uoSteps = (_steps || []).filter(step => step.UnitOperationId === unitOperationId);
    return orderAndIndexSteps(uoSteps, "id", false);
  }

  getKeysToKeepWhenResettingState() {
    return super.getKeysToKeepWhenResettingState().add("_orderedUOList", "_thisUOIndex");
  }

  renderCriticalitySectionHeader() {
    return <span>
                   Criticality Assessment
                   <InfoTooltip id="infoCriticalityAssessment"
                                fdaGuidanceURL="https://www.fda.gov/media/167721/download"
                                verbiage={<div>
                                  Criticality Assessment is a valuable science-based process used in quality risk management
                                  (see ICH Q9) that can aid in identifying which material attributes and process
                                  parameters potentially have an effect on product CQAs. Criticality Assessment is typically
                                  performed early in the pharmaceutical development process and is repeated as
                                  more information becomes available and greater knowledge is obtained.
                                </div>}
                   />
                 </span>;
  }

  renderCriticalityAssessmentSection(effectiveRMP, olderRMP, riskTableInstance) {
    const interactionName = "renderCriticalityAssessmentSection-1";
    const isLoading = !effectiveRMP;
    measurePerformanceStart(interactionName);

    const section = <Section id="criticalityAssessment"
                             parent={this}
                             header={this.renderCriticalitySectionHeader()}
    >
      {this.renderPotentialFailureModes()}
      {this.renderRiskLinksAttribute()}
      <div className="row">
        <AggregateCriticalityAttribute name="maxCriticality"
                                       displayName="Criticality (Raw)"
                                       className="col-sm-3"
                                       riskInfo={this.state.riskInfo}
                                       olderRiskInfo={this.getOlderVersion()?.riskInfo}
                                       RMP={effectiveRMP}
                                       olderRMP={olderRMP}
                                       getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, this.state, false, false, false)}
                                       getSubscriptTooltipCallback={getCalculationFormulaTooltip.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, this.state, true, false, "Winner")}
                                       subscriptText="How is this calculated?"
                                       valueType="raw"
                                       parent={this}
                                       isLoading={isLoading}
        />
        <AggregateCriticalityAttribute name="maxCriticalityPercentage"
                                       displayName="Criticality (%)"
                                       className="col-sm-3"
                                       riskInfo={this.state.riskInfo}
                                       olderRiskInfo={this.getOlderVersion()?.riskInfo}
                                       RMP={effectiveRMP}
                                       olderRMP={olderRMP}
                                       getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, this.state, true, false, false)}
                                       getSubscriptTooltipCallback={getCalculationFormulaTooltip.bind(this, RISK_TYPE_ENUM.CRITICALITY, effectiveRMP, this.state, false, true, "Winner")}
                                       subscriptText="How is this calculated?"
                                       valueType="percentage"
                                       parent={this}
                                       isLoading={isLoading}
        />

        <RiskLabelAttribute
          name="riskLabel"
          className="col-sm-3"
          riskTable={riskTableInstance}
          subscriptText="Overridden"
          parent={this}
          getSubscriptTooltipCallback={getRiskLabelTooltip.bind(this, this.state.riskInfo)}
          getTooltipCallback={RiskUtils.getRisklabelAttributeTooltip.bind(this)}
          visible={this.isView()}
          isLoading={isLoading}
        />

        <TextAreaAttribute name="recommendedActions"
                           className="col-sm-12"
                           tooltipText="Identify recommended actions to further evaluate attribute or parameter and reduce uncertainty on how this impacts downstream requirements.
                            These actions may be additional studies to evaluate attribute or parameter alone or in conjunction with other attributes or parameters
                            (e.g. design of experiments (DOE) studies)."
                           parent={this}
        />
      </div>
      <div className="row">
        <RiskSupportingDocumentsAttribute name="riskLinks"
                                          displayName="Source Documents"
                                          getRiskLinksOptions={this.getRiskLinksTypeaheadOptions}
                                          className="col-sm-12"
                                          onDocumentDelete={this.handleRiskDocumentDelete}
                                          onDocumentSave={this.handleRiskDocumentSave}
                                          isLoading={this.isLoadingRiskLinks()}
                                          triggerChildUpdate={this.state.triggerChildUpdate}
                                          parent={this}
        />
      </div>
      {this.renderDeleteRiskConfirmation()}
      {this.renderEditRiskConfirmation()}
    </Section>;

    measurePerformanceEnd(interactionName);
    return section;
  }

  renderPotentialFailureModes() {
    return "";
  }

  renderProcessCapabilitySection(effectiveRMP, olderRMP, riskTableInstance) {
    const isLoading = !effectiveRMP;
    const interactionName = "renderProcessCapabilitySection-1";
    measurePerformanceStart(interactionName);

    const section = <Section id="processCapability"
                             parent={this}
                             header={<span>Process Capability <InfoTooltip id="infoProcessCapability"
                                                                           verbiage={<div>
                                                                             Process capability is determined by comparing the output of a process to the acceptance criteria
                                                                           </div>}
                             /></span>}
                             headerLink={this.renderViewAnalytics()}
    >
      <div className="row">
        <ComboBoxAttribute name="capabilityRisk"
                           displayName="Capability Risk/Occurrence"
                           className="col-sm-4"
                           isNumber={true}
                           options={getRiskFieldOptions(effectiveRMP, "RMPToCapabilityRiskLinkedVersions")}
                           olderOptions={olderRMP && getRiskFieldOptions(olderRMP, "RMPToCapabilityRiskLinkedVersions")}
                           default={RiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.CAPABILITY_RISK, effectiveRMP)}
                           getTooltipCallback={getRiskFieldTooltip.bind(this, RISK_TYPE_ENUM.CAPABILITY_RISK, effectiveRMP)}
                           isLoading={isLoading}
                           parent={this}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           parentId={this.state.id}
        />
        <AggregateProcessRiskAttribute name="processRisk"
                                       displayName="Process Risk (Raw)"
                                       className="col-sm-4"
                                       riskInfo={this.state.riskInfo}
                                       olderRiskInfo={this.getOlderVersion()?.riskInfo}
                                       capabilityRisk={this.state.capabilityRisk}
                                       RMP={effectiveRMP}
                                       olderRMP={olderRMP}
                                       getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.PROCESS_RISK, effectiveRMP, this.state, false, false, false)}
                                       getSubscriptTooltipCallback={getCalculationFormulaTooltip.bind(this, RISK_TYPE_ENUM.PROCESS_RISK, effectiveRMP, this.state, true, false, "Max")}
                                       subscriptText="How is this calculated?"
                                       valueType="raw"
                                       isLoading={isLoading}
                                       parent={this}
        />
        <AggregateProcessRiskAttribute name="processRiskPercentage"
                                       displayName="Process Risk (%)"
                                       className="col-sm-4"
                                       riskInfo={this.state.riskInfo}
                                       olderRiskInfo={this.getOlderVersion()?.riskInfo}
                                       capabilityRisk={this.state.capabilityRisk}
                                       RMP={effectiveRMP}
                                       olderRMP={olderRMP}
                                       getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.PROCESS_RISK, effectiveRMP, this.state, true, false, false)}
                                       getSubscriptTooltipCallback={getCalculationFormulaTooltip.bind(this, RISK_TYPE_ENUM.PROCESS_RISK, effectiveRMP, this.state, false, true, "Max")}
                                       subscriptText="How is this calculated?"
                                       valueType="percentage"
                                       isLoading={isLoading}
                                       parent={this}
        />
      </div>
      {this.renderProcessCapabilitySectionExtension()}
    </Section>;

    measurePerformanceEnd(interactionName);

    return section;
  }

  renderRiskControlSection(effectiveRMP, olderRMP, riskTableInstance) {
    const interactionName = "renderRiskControlSection-1";
    const isLoading = !effectiveRMP;
    measurePerformanceStart(interactionName);

    const section = <Section id="riskControl"
                             parent={this}
                             showDocLinks={true}
                             header={<span>
                   Risk Control
                   <InfoTooltip id="infoRiskControl"
                                fdaGuidanceURL="https://www.fda.gov/media/167721/download"
                                fdaGuidancePage={11}
                                fdaGuidanceOffset={650}
                                verbiage={
                                  <div>
                                    Risk control includes decision making to reduce and/or accept risks. The purpose of
                                    risk control is to reduce the risk to an acceptable level. The amount of effort used
                                    for risk control should be proportional to the significance of the risk. Decision
                                    makers might use different processes, including benefit-cost analysis, for
                                    understanding the optimal level of risk control.
                                  </div>}
                   />
                 </span>}
    >
      <div className="row">
        <ComboBoxAttribute name="detectabilityRisk"
                           className="col-sm-4"
                           isNumber={true}
                           disabled={!(effectiveRMP && effectiveRMP.useDetectability)}
                           options={getRiskFieldOptions(effectiveRMP, "RMPToDetectabilityRiskLinkedVersions")}
                           olderOptions={olderRMP && getRiskFieldOptions(olderRMP, "RMPToDetectabilityRiskLinkedVersions")}
                           default={RiskUtils.getDefaultRiskValue(RISK_TYPE_ENUM.DETECTABILITY_RISK, effectiveRMP)}
                           getTooltipCallback={getRiskFieldTooltip.bind(this, RISK_TYPE_ENUM.DETECTABILITY_RISK, effectiveRMP)}
                           isLoading={isLoading}
                           parent={this}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           parentId={this.state.id}
        />
        <AggregateRPNAttribute name="rPN"
                               displayName="RPN (Raw)"
                               className="col-sm-4"
                               riskInfo={this.state.riskInfo}
                               olderRiskInfo={this.getOlderVersion()?.riskInfo}
                               capabilityRisk={this.state.capabilityRisk}
                               detectabilityRisk={this.state.detectabilityRisk}
                               RMP={effectiveRMP}
                               olderRMP={olderRMP}
                               getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.RPN, effectiveRMP, this.state, false, false, false)}
                               getSubscriptTooltipCallback={getCalculationFormulaTooltip.bind(this, RISK_TYPE_ENUM.RPN, effectiveRMP, this.state, true, false, "Max")}
                               subscriptText="How is this calculated?"
                               valueType="raw"
                               isLoading={isLoading}
                               parent={this}
        />
        <AggregateRPNAttribute name="rPNPercentage"
                               displayName="RPN (%)"
                               className="col-sm-4"
                               riskInfo={this.state.riskInfo}
                               olderRiskInfo={this.getOlderVersion()?.riskInfo}
                               capabilityRisk={this.state.capabilityRisk}
                               detectabilityRisk={this.state.detectabilityRisk}
                               RMP={effectiveRMP}
                               olderRMP={olderRMP}
                               getTooltipCallback={getRiskScaleTooltip.bind(this, RISK_TYPE_ENUM.RPN, effectiveRMP, this.state, true, false, false)}
                               getSubscriptTooltipCallback={getCalculationFormulaTooltip.bind(this, RISK_TYPE_ENUM.RPN, effectiveRMP, this.state, false, true, "Max")}
                               subscriptText="How is this calculated?"
                               valueType="percentage"
                               isLoading={isLoading}
                               parent={this}
        />
        <TextAreaAttribute name="detectabilityJustification"
                           displayName="Detectability Justification"
                           className="col-sm-12"
                           tooltipText="Detectability Risk values should be justified by literature, data, or other science-based rationales."
                           parent={this}
        />
      </div>
      <div className="row">
        {this.renderControlStrategyField()}
      </div>
      <div className="row">
        <CheckboxAttribute name="ccp"
                           displayName="CCP"
                           tooltipText="Critical control point (CCP) is a step at which control can be applied and is essential to prevent or eliminate a pharmaceutical quality hazard or reduce it to an acceptable level. Checking this box indicates that this attribute or parameter is a critical control point in the manufacturing process."
                           tooltipGuidanceURL="https://www.gmp-compliance.org/files/guidemgr/WHO_TRS_908_Annex7.pdf"
                           parent={this}
        />
        <TextAreaAttribute name="controlStrategyJustification"
                           displayName="Control Strategy Justification"
                           className="col-sm-12"
                           tooltipText="Justification for frequency and methodology of testing derived from current product and process understanding."
                           parent={this}
        />
      </div>
    </Section>;

    measurePerformanceEnd(interactionName);

    return section;
  }

  renderControlStrategyField() {
    return (
      <TypeaheadAttribute name="controlStrategy"
                          options={CommonEditables.INTERMEDIATE_ATTRIBUTE_CONTROL_STRATEGY_OPTIONS}
                          parent={this}
                          isLoading={this.state.isLoading}
                          parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                          parentId={this.state.id}
                          multiple={true}
                          tooltipText={(<div>
                            <li>
                              <b>Characterization</b> - Variable requires additional characterization to determine interactions, design space, optimal target and range, etc.
                            </li>
                            <li>
                              <b>In-Process Monitoring (Defined Limits)</b> - Variable is monitored in some way to ensure it remains within the limits defined.
                            </li>
                            <li>
                              <b>In-Process Monitoring (Report Results)</b> - Variable is monitored in some way to understand how it varies.
                            </li>
                            <li>
                              <b>In-Process Control</b> - Variable or attribute that has an impact on a downstream IQA or FQA.
                            </li>
                            <li>
                              <b>Test Upon Completion - IPT</b> - Attribute is tested at the completion of the unit operation because it is an In-Process Test for a downstream variable like an FQA.
                            </li>
                            <li>
                              <b>Test Upon Completion - IPC</b> - Attribute is tested at the completion of the unit operation because it is an In-Process Control for a downstream variable like an FQA.
                            </li>
                            <li>
                              <b>Other</b>
                            </li>
                            <li>
                              <b>Not Required</b> - No control strategy is required for this variable or attribute.
                            </li>
                          </div>)}
      />
    );
  }
}
