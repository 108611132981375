"use strict";

import React, { Fragment } from "react";
import BaseReactComponent from "../../../base_react_component";
import { getURLByTypeCodeAndId } from "../../../helpers/url_helper";

/**
 * This renders a label and link of a batch record which the user can click and go to the batch record directly.
 */
export default class ImportBatchInfo extends BaseReactComponent {
  render() {
    const {selectedBatch} = this.props;

    return (
      <Fragment>
        <div>
          Batch:
        </div>
        <div>
          <a id="batchSelectionLink"
             target="_blank"
             rel="noreferrer noopener"
             href={getURLByTypeCodeAndId("BAT", "View", selectedBatch && selectedBatch.id)}
          >
            {selectedBatch && selectedBatch.customID}
          </a>
        </div>
      </Fragment>
    );
  }
}