var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Reveal } from '@progress/kendo-react-animation';
import { useLocalization } from '@progress/kendo-react-intl';
import { messages, columnMenuFilterClearButton as clear, columnMenuFilterSubmitButton as submit } from '../messages';
import { Button } from '@progress/kendo-react-buttons';
export var ColumnMenuForm = function (props) {
    return (React.createElement("div", { className: "k-columnmenu-item-content" },
        React.createElement(Reveal, { style: { position: 'relative', display: 'block' } }, props.show && (React.createElement("div", { className: "kendo-grid-filter-menu-container" },
            React.createElement("form", { className: "k-filter-menu k-group k-reset k-state-border-up", onSubmit: props.onSubmit, onReset: props.onReset },
                React.createElement("div", { className: "k-filter-menu-container" },
                    props.children,
                    React.createElement("div", { className: "k-actions k-hstack k-justify-content-stretch" }, props.actions))))))));
};
/**
 * @hidden
 */
export var ColumnMenuFilterForm = function (props) {
    var localization = useLocalization();
    return (React.createElement(ColumnMenuForm, __assign({}, props, { actions: (React.createElement(React.Fragment, null,
            React.createElement(Button, { type: "reset" }, localization.toLanguageString(clear, messages[clear])),
            React.createElement(Button, { themeColor: 'primary' }, localization.toLanguageString(submit, messages[submit])))) })));
};
