import React, {useRef, useState} from "react";
import * as UIUtils from "../../ui_utils";
import {DOCUMENT_RECORD_SESSION_STORAGE_KEY} from "../../documentEditor/common/constants";
import DocumentAttachmentViewAttribute from "../../editor/attributes/document_attachment_view_attribute";
import {faUpload} from "@fortawesome/free-solid-svg-icons";
import {DocumentRecord} from "../../common/models/document";
import Popup from "../../widgets/generic/popup";

export default function QbDDocumentAttachmentViewAttribute(props: {
  documentRecord: DocumentRecord;
  isView: boolean;
  olderVersion: any;
  isDiffingVersions: boolean;
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onSelectNewTemplate: (event: React.MouseEvent) => void;
}) {
  // eslint-disable-next-line no-unused-vars
  let popupRef = useRef();
  const [isShowingPopup, setIsShowingPopup] = useState(false);
  const {documentRecord, isView, olderVersion, isDiffingVersions, isLoading, onSelectNewTemplate} =
    props;
  const strBuilder = [];
  // When the document record has id, it means it was already created. We only need to include the id
  // in the url, then document_editor_page can find the document record by id and pass it down the
  // document_editor component
  // When the document record has not created yet, we need to stringify it and set it in local storage
  if (documentRecord.id) {
    strBuilder.push(`documentId=${documentRecord.id}`);
  } else {
    localStorage.setItem(
      DOCUMENT_RECORD_SESSION_STORAGE_KEY,
      JSON.stringify({
        ...documentRecord,
        currentSmartContent: "",
      })
    );
    strBuilder.push(
      `documentRecordLocalStorageKey=${DOCUMENT_RECORD_SESSION_STORAGE_KEY}`
    );
  }
  const url = UIUtils.getSecuredURL(
    `${
      UIUtils.FRONT_END_URL
    }/documentEditor/documentEditor.html?${strBuilder.join("&")}`
  );
  const lastModified = documentRecord.documentContent?.updatedAt;
  const state = {
    id: documentRecord.id,
    documentContent: [
      {
        fileName: "Custom QbDVision Document Template",
        updatedAt: lastModified ?? 1666179755433,
      },
    ],
  };

  const onReplaceButtonClick = () => {
    setIsShowingPopup(true);
  };
  const options = [
    {
      action: {
        title: "Replace document",
        icon: faUpload,
      },
      title: "Replace title",
      id: "documentContentReplaceButton",
      onClick: onReplaceButtonClick,
    },
  ];

  const setPopupOpened = (showDialog: boolean) => {
    setIsShowingPopup(showDialog);
    if (!showDialog && popupRef) {
      // @ts-ignore
      $(popupRef).modal("hide");
    }
  };

  const handlePrimaryButtonClick = (event: React.MouseEvent) => {
    setPopupOpened(false);
    onSelectNewTemplate(event);
  };

  return (
    <>
      <DocumentAttachmentViewAttribute
        isQbDSrcDoc={true}
        name="documentContent"
        docAttachmentControlName="documentContent"
        instance={state}
        isView={isView}
        navLink={url}
        openOnSamePage={true}
        docDateKey="updatedAt"
        isDataModified={documentRecord.dataModified}
        olderVersion={olderVersion}
        isDiffingVersions={isDiffingVersions}
        isLoading={isLoading}
        actionButtonOptions={options}
      />
      {isShowingPopup && (
        <Popup
          id="replaceQbDVisionDocWarningPopup"
          modalRef={(modalRef) => (popupRef = modalRef)}
          title="Replace QbDVision Doc"
          primaryButtonText="Continue"
          setPopupOpened={setPopupOpened}
          onPrimaryButtonClick={handlePrimaryButtonClick}
        >
          <p id="popup-info-message">The original document cannot be recovered once replaced. Are you sure you want to continue?</p>
        </Popup>
      )}
    </>
  );
}
