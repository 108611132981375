"use strict";

import React from "react";
import * as I18NWrapper from "../../../../i18n/i18n_wrapper";
import BasePopup from "../../../../editor/approval/base_popup";
import Heading from "../../../../widgets/headers/heading";
import * as UIUtils from "../../../../ui_utils";
import { MaterialSpecifications } from "../components/project_material_specifications";
import ErrorBar from "../../../../widgets/bars/error_bar";
import { LibraryHelper } from "../../../helpers/library_helper";
import { ProjectMaterialOptionsContainer } from "../components/project_material_options_container";

/**
 * the modal to show when the user clicks on add to project from library starting from library screen.
 */
class CreateProjectMaterialPopup extends BasePopup {

  constructor(props) {
    super(props);
    this.initialize();
  }

  initialize() {
    let instance = UIUtils.deepClone(this.props.instance);
    let specs = instance.Specifications.map(spec => {
      return {
        ...spec,
        checked: false,
      };
    });
    instance = {...instance, Specifications: [...specs]};
    this.setStateSafely({
      instance,
      keepSynced: true
    });
  }

  handleOnSelect({position, toggle}) {
    const {instance} = this.state;
    const newState = LibraryHelper.onSelectSpecificationClick(instance, position, toggle);
    const allChecked =  newState.Specifications.every(spec => spec.checked === true);
    this.setStateSafely({instance: {...newState}, allChecked});
  }

  filterSteps(option) {
    return option.UnitOperationId === this.state.UnitOperationId;
  }

  renderPopupContents() {

    const state = this.state;
    const {instance, allChecked} = state;
    const specifications = instance.Specifications;

    return (
      <div className="container">
        <ErrorBar id="operationAlertDiv" />
        <div className="row">
          <div className="mb-3 project-material-modal-head col">
            <h4 className="font-weight-bold">{instance.name} <span>MTL-{instance.id}</span></h4>
          </div>
        </div>
        <div className="row">
          <ProjectMaterialOptionsContainer
            filterSteps={this.filterSteps}
            handleChangeValue={this.handleChangeValue}
            {...state}
            showInCard={true}
          />
        </div>
        <div className="row">
          <div className="col">
            <div className="section specification-section">
              <Heading className="specification-section-heading" level={2}>Material Attributes</Heading>
              <div className="section-content specification-section-content mt-4">
                <MaterialSpecifications specifications={specifications}
                                        onSelect={this.handleOnSelect}
                                        allChecked={allChecked}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleChangeValue(attributeName, attributeValue) {
    let newState = {};
    newState[attributeName] = attributeValue;
    this.setStateSafely(newState);
  }

  getProcessId() {
    return this.state.ProcessId;
  }

  getProjectId() {
    return this.state.ProjectId;
  }

  getUnitOperationId() {
    return this.state.UnitOperationId;
  }

  getStepId() {
    return this.state.StepId;
  }

  isCreateDisabled() {
    return !this.getProjectId() || !this.getProcessId() || !this.getUnitOperationId();
  }

  async handleCreateProjectMaterialClick() {
    const {state} = this;
    const {onCreateCompleted} = this.props;
    const callback = () => LibraryHelper.preprocessRequestCallback(state);
    const onCreateCompletedCallback = (result) => onCreateCompleted(result);
    await LibraryHelper.onCreateProjectMaterialClick(callback, onCreateCompletedCallback);
  }


  render() {
    const {t, title} = this.props;
    return (
      <div className="modal fade project-material-modal"
           ref={this.setModalRef}
      >
        <div className="modal-dialog modal-dialog-scrollable modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">{title}</h1>
              <button type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body project-material-modal-body">
              <div className="modal-container">
                {this.renderPopupContents()}
              </div>
            </div>
            <div className="modal-footer">
              <div className="modal-container">
                <button
                  id="addToProjectButton"
                  type="button"
                  className="btn btn-primary"
                  disabled={this.isCreateDisabled()}
                  onClick={this.handleCreateProjectMaterialClick}
                >
                  {t("Add To Project")}
                </button>
                <button
                  id="cancelCopyButton"
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  {t("Cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default I18NWrapper.wrap(CreateProjectMaterialPopup, "widgets");
// i18next-extract-mark-ns-stop widgets