"use strict";

import React from "react";
import MultipleTypeaheadObjectCache from "../../utils/cache/multiple_typeahead_object_cache";
import { ALL_TYPES_FOR_TYPEAHEADS, PROCESS_BAR_PANELS } from "../process_explorer_constants";
import { DiagramResultsAdapter } from "../adapters/diagram_results_adapter";
import * as FailHandlers from "../../utils/fail_handlers";
import * as UIUtils from "../../ui_utils";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import CommonConstants from "../../../server/common/generic/common_constants";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import BaseAutoBind from "../../base_auto_bind";
import { CommonAggregateError } from "../../../server/common/generic/common_aggregate_error";

const Logger = Log.group(LOG_GROUP.ProcessExplorer, "ProcessExplorerPage");

export default class DataReceiver extends BaseAutoBind {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.projectId = props.projectId;
  }

  async handleReceivedTypeaheadResults(results, typeCode) {
    const {parent} = this;
    const {showArchived} = parent.state;

    if (typeCode === "PRJ") {
      // Reload the project cache if we can't find the current project Id.
      const project = results.find(project => project.id === this.projectId);
      if (!project) {
        const typeaheadObjectCache = new TypeaheadObjectCache("Project");
        await typeaheadObjectCache.invalidateCacheOptionsAsync();

        const results = await typeaheadObjectCache.loadOptions().promise();
        const project = results.find(project => project.id === this.projectId);
        if (project) {
          await this.handleReceivedTypeaheadResults(results, "PRJ");
        } else {
          const error = new Error(`Access denied.`);
          error.statusCode = 403;
          UIUtils.defaultFailFunction(error);
        }
      } else {
        parent.setStateSafely({project});
      }
    } else if (typeCode === "PR") {
      parent.setStateSafely({processes: results});
      // Filter only non archived processes to be visible if showArchived option is disabled
      let processes = showArchived ? results : results.filter(process => process.deletedAt === null);
      parent.setStateSafely({visibleProcesses: processes});
    } else if (results?.length > 0) {
      parent.setStateSafely({
        typeaheadOptions: [...parent.state.typeaheadOptions, ...results],
      });
    }
  }

  handleReceivedDataFromServer(useWriterDB, loadTypeaheadPromise, processExplorerResults) {
    const {parent} = this;
    try {
      Logger.verbose(() => "Received processExplorerResults:", Log.object(processExplorerResults));

      // Load the typeaheads if we didn't know the processId before
      const processId = processExplorerResults
        && processExplorerResults.processes
        && processExplorerResults.processes[0]
        && processExplorerResults.processes[0].id;
      if (!parent.state.processId) {
        loadTypeaheadPromise = loadTypeaheadPromise.then(() =>
          new MultipleTypeaheadObjectCache(ALL_TYPES_FOR_TYPEAHEADS, this.projectId, processId)
            .loadOptions(parent.dataReceiver.handleReceivedTypeaheadResults).promise());
      }

      const diagramResultsAdapter = new DiagramResultsAdapter(processExplorerResults,
        parent.isProjectArchived(), parent.state.showArchived, parent.props.t);
      const {nodes, links} = diagramResultsAdapter.getNodesAndLinks();
      processExplorerResults.orderedUOList = diagramResultsAdapter.getOrderedUOList();

      parent.staticPanelOperator.loadStaticPanelData(useWriterDB, processExplorerResults, loadTypeaheadPromise);
      parent.setStateSafely({
        processId,
        nodes,
        links,
        processExplorerResults,
        isLoading: false,
      });
    } catch (e) {
      const wrappedEx = new CommonAggregateError(e);
      FailHandlers.defaultStopLoadingFailFunction.bind(parent)(wrappedEx);
      throw wrappedEx;
    }
  }

  handleReceiveStaticPanelDataFromServer(keyToFullRecordMap, loadTypeaheadPromise = Promise.resolve()) {
    try {
      const {parent} = this;
      Logger.verbose(() => "Static Panel data loaded:", Log.json(keyToFullRecordMap));

      const allRecords = Array.from(keyToFullRecordMap.values());
      for (const staticPanelInstance of allRecords) {
        staticPanelInstance.isHistoryPartial = true; // This tells the editor to load the history if the user tries to diff versions.

        this.setDefaultAcceptanceCriteria(staticPanelInstance);
        parent.processExplorerHelper.setChildInstances(staticPanelInstance, allRecords);
      }

      // Count non archived instances
      let nonArchivedCount = parent.processExplorerHelper.countRecordsExcludeNonArchived(allRecords);

      parent.setStateSafely({keyToFullRecordMap, nonArchivedCount}, () => {
        loadTypeaheadPromise.then(() => {
          if (parent.state.selectedPanel === PROCESS_BAR_PANELS.ABOUT) {
            parent.staticPanelOperator.handleChangePanel(parent.state.selectedPanel); // Show the about panel data now that we have it.
          }

          Logger.verbose(() => "All loading is complete.");
        });
      });
    } catch (e) {
      UIUtils.defaultFailFunction(e);
      throw e;
    }
  }

  setDefaultAcceptanceCriteria(staticPanelInstance) {
    if (staticPanelInstance?.Requirement || staticPanelInstance?.RequirementVersion) {
      let ranges = (
        staticPanelInstance?.RequirementVersion?.AcceptanceCriteriaRangeLinkedVersions
          ? staticPanelInstance?.RequirementVersion?.AcceptanceCriteriaRangeLinkedVersions
          : staticPanelInstance?.Requirement?.AcceptanceCriteriaRanges
      ) ?? [];
      let primaryReportingCriteria = ranges.find(range => range.isDefault);

      if (primaryReportingCriteria) {
        staticPanelInstance.primaryReportingCriteria = primaryReportingCriteria.uuid;
      }
    }
  }

  handleReceivedChildObjectsFromServer(action, parentObjectDetails, results) {
    const {parent} = this;
    const isArchive = action === CommonConstants.ACTIONS.ARCHIVE;
    const isRestore = action === CommonConstants.ACTIONS.RESTORE;

    const {typeCode} = parentObjectDetails;
    const canContainSharedObjects = typeCode === "UO" || typeCode === "STP";

    parent.setStateSafely({
      showApprovalPopup: true,
      approvalInfo: null,
      warning: results.length > 0 ?
        (<div>
          <div>
            {isArchive ? "Archiving will also remove" : "Restoring will also restore"} the following child objects:
          </div>
          <br />
          {results.map(result => {
            return (
              <div key={result.typeCode + "-" + result.id}>
                <a href={getURLByTypeCodeAndId(result.typeCode, "View", result.id)}
                   target="_blank"
                   rel="noopener noreferrer"
                >
                  <b>{UIUtils.getRecordCustomLabelForDisplay(result)}</b>
                </a>
              </div>
            );
          })}
          {isRestore && canContainSharedObjects ? "Note: Shared Materials and Process Components must be manually relinked after the restore is approved." : ""}
        </div>) : null
    });
  }
}
