import { colgroupAttr, dataResizeDirTable, resizableAttr, resizableWrap, resizeHandle } from '../../config/constants';
import { parseStrColgroup } from '../../config/schema';
import { handles } from './../resize-utils';
var TableView = /** @class */ (function () {
    function TableView(node, view) {
        this.node = node;
        this.view = view;
        this.dom = document.createElement('div');
        this.dom.className = resizableWrap;
        this.table = this.dom.appendChild(document.createElement('table'));
        if (node.attrs[colgroupAttr]) {
            this.renderColgroup(node.attrs[colgroupAttr]);
        }
        var tBody = this.table.appendChild(document.createElement('tbody'));
        this.setAttributes(this.table, node.attrs);
        this.resizeHandles = handles.map(function (dir) {
            var handle = document.createElement('span');
            handle.className = resizeHandle + ' ' + dir;
            handle.setAttribute(dataResizeDirTable, dir);
            return handle;
        });
        this.contentDOM = tBody;
    }
    TableView.prototype.update = function (node) {
        var _this = this;
        if (node.type !== this.node.type) {
            return false;
        }
        var prev = this.node;
        this.node = node;
        if (node.attrs[resizableAttr]) {
            this.resizeHandles.forEach(function (handle) {
                _this.dom.appendChild(handle);
            });
        }
        else {
            Array.from(this.dom.children)
                .filter(function (e) { return e.classList.contains(resizeHandle); })
                .forEach(function (e) { return e.remove(); });
        }
        this.setAttributes(this.table, node.attrs);
        if (prev.attrs[colgroupAttr] !== node.attrs[colgroupAttr]) {
            this.renderColgroup(node.attrs[colgroupAttr]);
        }
        return true;
    };
    TableView.prototype.ignoreMutation = function (record) {
        var result = record.type === 'attributes' &&
            (record.target === this.table ||
                record.target.firstChild === this.table ||
                (this.colgroup && this.colgroup.contains(record.target)));
        return result;
    };
    TableView.prototype.destroy = function () {
        this.node = undefined;
        this.view = undefined;
        this.table = undefined;
        this.colgroup = undefined;
        this.resizeHandles = undefined;
    };
    TableView.prototype.renderColgroup = function (colgroupStr) {
        if (this.table && this.table.firstChild && this.table.firstChild.nodeName === 'COLGROUP') {
            this.table.removeChild(this.table.firstChild);
        }
        if (colgroupStr) {
            this.colgroup = parseStrColgroup(colgroupStr);
            this.table.insertBefore(this.colgroup, this.table.firstChild);
        }
    };
    TableView.prototype.setAttributes = function (table, attrs) {
        var skip = [colgroupAttr, resizableAttr];
        for (var attrName in attrs) {
            if (attrName && skip.indexOf(attrName) === -1) {
                var current = table.getAttribute(attrName);
                var next = attrs[attrName];
                if (next && next !== current) {
                    table.setAttribute(attrName, next);
                }
                else if (!next) {
                    table.removeAttribute(attrName);
                }
            }
        }
    };
    return TableView;
}());
export { TableView };
