"use strict";

import * as UIUtils from "../../../../../ui_utils";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import ValidationIcon from "../../../../../widgets/generic/validation_icon";
import ImplementationNeededError from "../../../../../utils/implementation_needed_error";
import { getTextractConfidenceLevel } from "../../../../helpers/import_helper";
import BaseReactComponent from "../../../../../base_react_component";

/**
 * This is the base class for editors that appear in the AI Import edit screen.
 */
export default class BaseEditor extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {supportsDelete, disabled, validationStatus, confidence, id} = this.props;
    const {mouseOver} = this.state;
    const nextId = nextProps.id;
    const nextSupportsDelete = nextProps.supportsDelete;
    const nextDisabled = nextProps.disabled;
    const nextValidationStatus = nextProps.validationStatus;
    const nextConfidence = nextProps.confidence;
    const nextMouseOver = nextState.mouseOver;

    return id !== nextId
      || supportsDelete !== nextSupportsDelete
      || mouseOver !== nextMouseOver
      || disabled !== nextDisabled
      || confidence !== nextConfidence
      || !validationStatus && nextValidationStatus
      || (validationStatus && validationStatus.error) !== (nextValidationStatus && nextValidationStatus.error);
  }

  handleMouseOver() {
    this.setStateSafely({
      mouseOver: true
    });
  }

  handleMouseLeave() {
    this.setStateSafely({
      mouseOver: false
    });
  }

  renderInput() {
    throw new ImplementationNeededError("renderInput should be implemented in derived class of BaseEditor.");
  }

  renderDeleteButton() {
    return this.state.mouseOver && this.props.supportsDelete ? (
      <div key="measurementDeleteButtonDiv"
           id={this.props.id + "_DeleteButton"}
           className="close-button"
           title="Delete measurement"
           onClick={this.props.onDeleteButtonClicked}
      >
        <span><FontAwesomeIcon icon={faTimesCircle} /></span>
      </div>
    ) : "";
  }

  getEditorBorderClass() {
    return this.showWarning() ? " warning" : this.showError() ? " error" : "";
  }

  getValidationTooltip() {
    let {validationStatus} = this.props;
    return validationStatus && validationStatus.error;
  }

  showError() {
    let {validationStatus} = this.props;
    return this.getValidationTooltip() && validationStatus.isError;
  }

  showWarning() {
    return this.getValidationTooltip() && !this.showError();
  }

  renderValidationIcon() {
    let validationTooltip = this.getValidationTooltip();

    return validationTooltip ? (
      <div key="measurementValidationDiv"
           className="error-icon"
      >
        <ValidationIcon key={this.props.id + "Validation"}
                        id={this.props.id + "Validation"}
                        trigger="click"
                        hideBullets={true}
                        isWarning={this.showWarning()}
                        tooltip={validationTooltip}
                        visible={true}
        />
      </div>
    ) : "";
  }

  /**
   * Overwrite this in child classes to return the text as displayed to the user on the input control. This text is
   * added to the input control background div with transparent color, so that the div is sized appropriately. The
   * background of the highlight div is colored appropriately giving the illusion of actual highlighted text in an
   * input control.
   * @returns {string}
   */
  getTextForMarkup() {
    return "";
  }

  /**
   * Overwrite this in child classed to return the input control type ("select", "input", etc..) of the control that
   * accepts the user input. This is used in CSS to properly position the input background div that renders the
   * text highlight effect below the user input. Based on the control type, different positioning is used in CSS to
   * achieve a nice result.
   * @returns {string}
   */
  getEditorHighlightsClass() {
    return "";
  }

  render() {
    return (
      <div className="base-import-editor-div"
           onMouseOver={this.handleMouseOver}
           onMouseLeave={this.handleMouseLeave}
      >
        {this.renderInput()}
        {UIUtils.isNumber(this.props.confidence) ? (
          <div className={"highlights " + this.getEditorHighlightsClass()}>
            <mark id={this.props.id + "_Mark"}
                  className={getTextractConfidenceLevel(this.props.confidence).color}
            >{this.getTextForMarkup()}</mark>
          </div>
        ) : ""}
        {this.props.disabled ? "" : this.renderDeleteButton()}
        {this.props.disabled ? "" : this.renderValidationIcon()}
      </div>
    );
  }
}
