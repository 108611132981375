"use strict";

import * as URLHelper from "../helpers/url_helper";
import * as UIUtils from "../ui_utils";
import { measurePerformanceEnd, measurePerformanceStart } from "../ui_utils";
import React, { Fragment } from "react";
import * as DocumentTransferHelper from "../helpers/document_transfer_helper";
import InfoTooltip from "../widgets/tooltips/info_tooltip";
import Section from "../editor/widgets/section";
import ComboBoxAttribute from "../editor/attributes/combo_box_attribute";
import TextAreaAttribute from "../editor/attributes/text_area_attribute";
import TextAttribute from "../editor/attributes/text_attribute";
import CheckboxAttribute from "../editor/attributes/checkbox_attribute";
import DocumentAttachmentEditAttribute from "../editor/attributes/document_attachment_edit_attribute";
import DocumentAttachmentViewAttribute from "../editor/attributes/document_attachment_view_attribute";
import CommonEditablesPageTitleBar from "../widgets/pageTitleBar/common_editables_page_title_bar";
import { EDITOR_TYPES, RECORD_OPERATIONS } from "../editor/editor_constants";
import BaseQuickEditor from "../editor/base_quick_editor";
import { QUICK_PANEL_BUTTONS } from "../widgets/quickPanel/quick_panel_buttons";
import { TrainingService } from "../training/services/training_service";
import CommonDocuments from "../../server/common/editables/common_documents";
import TypeaheadAttribute from "../editor/attributes/typeahead_attribute";
import DateAttribute from "../editor/attributes/date_attribute";
import moment from "moment";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";
import DocumentHeaderLink from "./components/document_header_link";
import { DOCUMENT_EDITOR_TABS, QUICKPANEL_WARNING_MESSAGES } from "./constants/constants";
import PDFViewer from "../widgets/pdf/pdf_viewer";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";
import { EXPERIMENTS } from "../helpers/constants/constants";
import MarkAsTrainedPopup from "../training/popups/mark_as_trained_popup";
import ApprovalResponsePopup from "../editor/approval/approval_response_popup";
import * as ProcessCache from "../processExplorer/process/process_cache";
import { getProjectFromCache } from "../utils/project_helper";
import SeeMoreButton from "../widgets/generic/see_more_button";
import * as docStyles from "./document.module.scss";
import DocumentEditor from "../documentEditor/document_editor";
import DocumentContentChangesNotification from "./components/document_content_changes_notification";
import { createSmartContentHash, isSmartContentChanged } from "../documentEditor/utils";
import EditorParser from "../documentEditor/utils/parser/parser";
import DocumentProjectAndProcessAttribute from "../editor/attributes/document_project_and_process_attribute";
import QbDDocumentAttachmentViewAttribute from "./components/qbd_document_attachment_view_attribute";


const Logger = Log.group(LOG_GROUP.Documents, "Document");

const USE_DATA_URL = false;

export default class Document extends BaseQuickEditor {
  constructor(props) {
    super(props, "document", "Document", "Document");

    if (this.isRecordCached()) {
      this.refreshPreview(props.cachedData);
    }

    this.documentEditorRef = React.createRef();
    this.documentHeaderLinkRef = React.createRef();

    this.trainingService = new TrainingService();
    this.documentCache = new TypeaheadObjectCache("Document");

    this.projectId = UIUtils.parseInt(UIUtils.getParameterByName("projectId"));
    const isInProjectContext = this.projectId > 0;
    this.createPDF = false;

    const initialState = {
      isAboutExpanded: false,
      isQbdSrcDoc: false,
      currentSmartContent: null,
      smartContentLoaded: false,
      areDocBuilderImagesLoaded: false,
      deferRenderSmartContent: true,
      isInProjectContext
    };

    if (!isInProjectContext) {
      initialState.breadcrumb = {
        current: this.isAdd() ? "New Document" : "",
        parent: "Documents",
        parentLink: "/documents/list.html",
      };
    }

    if (this.projectId) {
      initialState.project = getProjectFromCache(this.projectId);
    }

    if (props.editorType === EDITOR_TYPES.QUICK_PANEL) {
      this.projectId = null;
    }

    if (this.projectId && this.isAdd()) {
      initialState.ProjectId = this.projectId;

      const processId = UIUtils.getParameterByName("processId") || ProcessCache.getProcessIdUsedRecently(this.projectId);
      if (processId) {
        initialState.ProcessId = processId;
      }
    }

    if (props.editorType !== EDITOR_TYPES.QUICK_PANEL) {
      this.setStateSafely(initialState);
    }
  }

  loadDefaultState() {
    const additionalState = {
      isAboutExpanded: false,
      isQbdSrcDoc: false
    };
    Logger.verbose("loadDefaultState", Log.object(additionalState));
    super.loadDefaultState(additionalState);
  }

  handleQuickPanelActionClick(action) {
    if (action?.id === QUICK_PANEL_BUTTONS.DOWNLOAD.id) {
      if (this.documentAttachment) {
        this.handleDocumentDownload(this.documentAttachment.getFileData());
      }
    }
  }

  onDataReceivedFromServer() {
    super.onDataReceivedFromServer();

    const documentVersions = [this.getCurrentViewingVersionId()];
    UIUtils.setHideLoadingOnAjaxStop(true);
    this.trainingService.loadTrainingInfo(UIUtils.getUserId(), documentVersions)
      .then(({trainingRecords}) => {
        const recordForDocument = trainingRecords && trainingRecords[0];

        if (recordForDocument) {
          this.setStateSafely({trainingStatus: recordForDocument.action});
        }
      });

    // breadcrumb logic
    let document;

    if (this.state?.customID) {
      document = `${this.state.customID} - ${this.state.name}`;
    } else {
      document = UIUtils.getRecordCustomLabelForDisplay({
        typeCode: "DOC",
        id: this.state.id,
        name: this.state.name,
      });
    }

    const isInProjectContext = this.state.ProjectId > 0;
    if (!isInProjectContext) {
      this.setStateSafely({
        isInProjectContext,
        breadcrumb: {
          current: document,
          parent: "Documents",
          parentLink: "/documents/list.html",
        },
      });
    } else {
      this.setStateSafely({
        isInProjectContext: true
      });
    }
  }

  displaySignOffSuccessMessage(instance) {
    const documentLabel = UIUtils.getRecordCustomLabelForDisplay({
      ...instance,
      typeCode: "DOC",
    });

    UIUtils.showSuccess(`Document "${documentLabel}" successfully signed off as trained.`);
  }

  toggleMarkAsTrainedPopup(visible) {
    return this.setStateSafely({isMarkAsTrainedPopupOpen: visible});
  }

  async handleTrainingSignOff(instance, approve, approvalInfo) {
    approvalInfo = approvalInfo || {};

    let payload = {
      versionId: instance.LastApprovedVersionId,
      comment: approvalInfo.comment,
      email: approvalInfo.email,
      password: approvalInfo.password,
      signingPin: approvalInfo.signingPin,
      baseId: instance.id,
    };

    try {
      let signOffResult = await this.trainingService.signOff(approve, payload, {useTwoWayCommunication: false});

      if (approve) {
        ApprovalResponsePopup.setApprovalSucceededInThisSession();
      }

      instance.trainingStatus = "Trained";

      this.displaySignOffSuccessMessage(instance);

      return signOffResult;
    } catch (results) {
      return this.failCallback(results);
    }
  }

  getAdditionalPopups() {
    const result = super.getAdditionalPopups();
    const documentPage = this;

    if (this.state.isMarkAsTrainedPopupOpen) {
      result.push(
        {
          key: "MarkAsTrained",
          Popup: MarkAsTrainedPopup,
          props: {
            baseTypeName: "Training Record",
            instance: documentPage.state,
            onPopupVisibilityChange: documentPage.toggleMarkAsTrainedPopup,
            onSendApproval: documentPage.handleTrainingSignOff,
          },
        },
      );
    }
    return result;
  }

  getAdditionalButtons() {
    const versionId = this.state.versionId || this.state.LastApprovedVersionId;
    let buttons = super.getAdditionalButtons();
    if (buttons && this.isView() && this.getCurrentState() === UIUtils.VERSION_STATES.APPROVED) {
      const status = this.trainingService.getTrainingSignOffStatus(UIUtils.getUserId(), this.state, versionId);

      if (status.visible) {
        buttons.push({
          id: "markAsTrainedButton",
          onClick: () => this.toggleMarkAsTrainedPopup(true),
          disabled: status.disabled,
          text: "Mark as trained",
          tooltip: status.tooltipText,
          isPrimary: true,
        });
      }
    }
    return buttons;
  }

  handleDocumentDownload(fileData) {
    const clonedFileData = {...fileData};
    /* We saved smartContent in fileData to check whether the underlying data has changed or not
       We need to remove the smartContent before downloading since it will make the getPreSignedURL
       way too long and that will cause an error. Although we addressed this at some point by saving
       a hash of the data in a new field called smartContentHash, there could be some records in the system
       with the smartContent still attached. Until those are cleaned through some migration, we should keep
       this code in for safety, just in case some client already started using the document builder before
       releasing it out of experimental.
     */
    if (clonedFileData.smartContent) {
      delete clonedFileData.smartContent;
    }

    const versionId = this.state.versionId || this.state.LastApprovedVersionId;
    return this.trainingService.downloadDocument(clonedFileData, versionId).then(() => {
      if (this.getEditorType() === EDITOR_TYPES.TRAINING_PANEL) {
        this.initialize();
      } else {
        const downloadedVersions = [...(this.state.downloadedVersions || []), versionId];
        this.setStateSafely({downloadedVersions: downloadedVersions});
      }
    });
  }

  shouldShowNav() {
    return (this.projectId && this.projectId > 0);
  }

  renderPageTitleBar() {
    const backLink = this.projectId ? "/documents/documentsListInProject.html?projectId=" + this.projectId : null;
    return this.getEditorType() === EDITOR_TYPES.FULL_SCREEN
      ? (
        <CommonEditablesPageTitleBar
          recordName={this.state.name}
          recordId={this.state.id}
          record={this.state}
          backLink={backLink}
          name="Documents"
          typeCode={UIUtils.getTypeCodeForModelName(this.baseTypeName)}
          currentState={this.getCurrentState()}
        />
      ) : "";
  }

  getTabName() {
    return "Documents";
  }

  disableSaveButton(on) {
    if (on === true) {
      this.setStateSafely({isSaveDisabled: true});
    } else {
      this.setStateSafely({isSaveDisabled: false});
    }
  }

  handleChangeValue(attributeName, attributeValue, callback, attributeType) {
    this.disableSaveButton(false);
    if (attributeName === "ProjectId") {
      this.setStateSafely({ProcessId: -1}, () => {
        super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
      });
    } else {
      super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
    }
  }

  beforeDataSavedToServer(callback) {
    const changedState = {};
    if (this.state.ProjectId === -1) {
      changedState.ProjectId = null;
    }

    if (this.state.ProcessId === -1) {
      changedState.ProcessId = null;
    }

    this.setStateSafely(changedState, () => {
      if (callback) {
        callback();
      }
    });
  }

  handleSaveResults(result) {
    const {projectId} = this.state;
    const projectIdInURL = UIUtils.getParameterByName("projectId");

    // If the project changed, we redirect on save
    this.redirectOnSave = (String(projectId) !== String(projectIdInURL));

    this.documentCache.invalidateCacheOptionsAsync().then(() => {
      this.documentCache.loadOptions(() => {
        super.handleSaveResults(result);
        this.refreshPreview(result, true);
        this.disableSaveButton(false);
      });
    });
  }

  getRedirectURLParams() {
    const {projectId} = this.state;
    return projectId ? `&projectId=${projectId}` : "";
  }

  handleSave(event, callback) {
    UIUtils.setLoadingDisabled(false);
    UIUtils.setHideLoadingOnAjaxStop(false);
    this.disableSaveButton(true);
    this.documentCache.loadOptions(() => {
      super.handleSave(event, callback);
    });
  }

  async saveToDB() {
    if (this.createPDF) {
      this.createPDF = false;
      const {documentContent, currentSmartContent} = this.state;

      if (
        this.documentEditorRef &&
        this.documentEditorRef.current
      ) {
        const fileData = this.getFileData(false);
        const fields = EditorParser.getFields(documentContent.content, {
          ProjectId: this.state.ProjectId,
          ProcessId: this.state.ProcessId,
          DocumentId: this.state.id,
        });
        const smartContentHash = createSmartContentHash(documentContent.content, fields, currentSmartContent);
        if (
          fileData.documentContentVersionId !== documentContent.LastVersionId ||
          isSmartContentChanged(
            fileData.smartContentHash,
            smartContentHash,
          )
        ) {
          this.documentEditorRef.current.uploadFile(this.state, (newFileData) => {
            this.setStateSafely({
              ...this.state,
              uploadDocumentLinks: JSON.stringify([
                {...newFileData, smartContentHash},
              ]),
              deferRenderSmartContent: true,
            }, () => {
              super.saveToDB();
            });
          });
        } else {
          await super.saveToDB();
        }
      } else {
        UIUtils.hideLoadingImage();
      }
    } else {
      await super.saveToDB();
    }
  }

  scrollToFirstValidationError() {
    this.disableSaveButton(false);
    super.scrollToFirstValidationError();
  }

  refreshPreview(result, isFromSave = false) {
    Logger.debug(() => "DATA RECEIVED", Log.object(result));
    if (result && result.uploadDocumentLinks) {
      let value = result.uploadDocumentLinks;
      const fileData = (value && typeof value === "string")
        ? JSON.parse(value)
        : (value ? value : JSON.parse(DocumentAttachmentEditAttribute.getInitialValue()));
      if (fileData && fileData[0]) {
        this.runPromise(this.setFileData(fileData[0], isFromSave));
      }
    }
  }

  validateClientInstance() {
    const existingDocs = this.documentCache.getOptionsFromCache();
    const result = this.verifyThatCustomIdIsUnique(existingDocs, this.state);
    if (result.status === "error") {
      if (this.state?.isSaveDisabled) {
        this.disableSaveButton(false);
      }
      const error = new Error("Custom ID is not unique");
      error.reactComponent = result.message;
      throw error;
    } else if (result.status === "warning") {
      UIUtils.showWarning(result.message);
    }
    super.validateClientInstance();
  }

  handleDocumentPreviewLoading(status) {
    const {onLoading} = this.props;
    if (this.getSelectedEditorTab().key === DOCUMENT_EDITOR_TABS.DOCUMENT.key) {
      if (onLoading) {
        onLoading(status);
      }
    }
  }

  handleDocumentPreviewReady(status) {
    const {versionId} = this.state;
    const {onReady} = this.props;

    if (this.getSelectedEditorTab().key === DOCUMENT_EDITOR_TABS.DOCUMENT.key) {
      Logger.debug("Marking document version as viewed: ", Log.symbol(versionId), Log.object(status));

      this.trainingService.addDownloadedDocumentVersion(versionId);
      this.initialize();
    }
    if (onReady) {
      onReady(status);
    }
  }

  handleDocumentPreviewError(error) {
    const {onError} = this.props;
    if (onError) {
      onError(error);
    }
  }

  handleSendApprovalRequest(event, callback) {
    const {
      isQbdSrcDoc,
      documentContent,
    } = this.state;
    // We only need to upload the file when:
    // 1. The document is QbD Document
    // 2. There was a change in document content since we exported the last file
    //    or the smart content changed
    if (isQbdSrcDoc && documentContent) {
      UIUtils.setHideLoadingOnAjaxStop(false);
      UIUtils.setLoadingDisabled(false);
      UIUtils.showLoadingImage();
      this.completeDocumentProposal = true;
      setTimeout(() => {
        this.setStateSafely({
          deferRenderSmartContent: false,
        });
      }, 200);

      return;
    }

    super.handleSendApprovalRequest(event, callback);
  }

  handleCompleteProposal() {
    const {smartContentLoaded, areDocBuilderImagesLoaded} = this.state;
    // If the Doc Builder has not finished loading smart content and images, we won't send approval
    // request. We only need to set the completeDocumentProposal to true. After the Doc Builder finishes
    // loading them, it will setStateSafely for them and the componentDidUpdate will be triggered. Then it will
    // call this method again to upload file normally.
    if (!smartContentLoaded || !areDocBuilderImagesLoaded) {
      const LOADING_MESSAGE = "Preparing to generate PDF. Please hold on, this may take a few minutes.";
      UIUtils.showLoadingImage(LOADING_MESSAGE);
      this.completeDocumentProposal = true;
      return;
    }

    this.createPDF = true;
    this.completeDocumentProposal = false;
    super.handleSendApprovalRequest(null, (result) => {
      $(".modal.fade").modal("hide");
      this.setPopupOpened(false);
      if (result.error) {
        this.failCallback(result.error);
      }
    });
  }

  /**
   * Verifies that the Custom ID of the document being saved is unique and displays a warning or error message if needed.
   * @param existingDocs {Document[]} The documents that already exist in the system.
   * @param docToSave {Document} The document we're trying to save
   */
  verifyThatCustomIdIsUnique(existingDocs, docToSave) {
    let message;
    let status = "success";

    if (docToSave.customID) {
      const docsWithTheSameCustomID = existingDocs.filter(
        existingDoc => existingDoc.customID === docToSave.customID && existingDoc.id !== docToSave.id,
      );

      if (docsWithTheSameCustomID.length > 0) {
        let repeatedDocs = docsWithTheSameCustomID.map(existingDoc => {
          const link = URLHelper.getURLByTypeCodeAndId("DOC", existingDoc.id);
          const description = UIUtils.getRecordCustomLabelForDisplay(existingDoc);
          return <li key={link}><a href={link}>{description}</a></li>;
        });

        // TODO: this entire page has no translation, and converting it is not part of the ticket. However, this is something we need to check in the future.
        let messageHeader =
          <span>The Custom ID <strong>&quot;{docToSave.customID}&quot;</strong> is already in use by the following {UIUtils.pluralize("document", docsWithTheSameCustomID.length)}</span>;

        message = <div>{messageHeader}: <ul>{repeatedDocs}</ul></div>;

        // We tolerate duplicate IDs for existing documents that have a previous version that uses
        // the custom ID we're trying to set.
        if (this.isEdit()) {
          /**
           * @type {IDocumentVersion[]}
           */
          const oldVersions = docToSave.DocumentVersions;

          // If some older version of the document had the repeated custom ID,
          // we will raise a warning instead of an error.
          if (oldVersions.some(oldVersion => oldVersion.customID === docToSave.customID)) {
            status = "warning";
          } else {
            status = "error";
          }
        } else {
          status = "error";
        }
      }
    }

    return {
      status,
      message,
    };
  }

  renderAttributes() {
    const interactionName = "Document :: renderAttributes";
    measurePerformanceStart(interactionName);

    let selectedEditorTab = this.getSelectedEditorTab();
    const {isQbdSrcDoc} = this.state;

    Logger.verbose(() => "Selected Tab: ", Log.object(selectedEditorTab), Log.object(selectedEditorTab.key === DOCUMENT_EDITOR_TABS.DOCUMENT.key));

    const section = (
      <Fragment>
        {isQbdSrcDoc && this.renderQbdDocumentPreviewTab()}
        {this.renderDocumentPreviewTab()}
        {this.renderAboutTab()}
        {this.renderDocumentEditor()}
      </Fragment>
    );

    measurePerformanceEnd(interactionName);

    return section;
  }

  renderDocumentEditor() {
    const documentRecord = this.state;
    if (!documentRecord.isQbdSrcDoc || !documentRecord.DocumentContentId) {
      return null;
    }

    return (
      <DocumentEditor
        offScreen
        deferRender={documentRecord.deferRenderSmartContent}
        ref={this.documentEditorRef}
        onLoadSmartContent={(smartContent) => this.handleSmartContentLoaded(smartContent)}
        onLoadSmartContentFail={() => this.setStateSafely({smartContentLoaded: true})}
        onFinishedDownloadingImages={() => this.setStateSafely({areDocBuilderImagesLoaded: true})}
        documentRecord={documentRecord}
      />
    );
  }

  handleSmartContentLoaded(smartContent) {
    this.setStateSafely({
      smartContentLoaded: true,
      currentSmartContent: smartContent,
    });
  }

  renderQbdDocumentPreviewTab() {
    if (!this.isQuickPanel() || !this.shouldShowDocumentPreview()) {
      return "";
    }

    const documentRecord = this.state;
    const {selectedEditorTab} = this.state;
    const qbdDocumentTabVisible = selectedEditorTab?.key === DOCUMENT_EDITOR_TABS.SOURCE_DOC.key;

    return (
      <DocumentEditor
        previewOnly
        isVisible={qbdDocumentTabVisible}
        ref={this.documentEditorRef}
        onLoadSmartContent={(smartContent) => this.handleSmartContentLoaded(smartContent)}
        onFinishedDownloadingImages={() => this.setStateSafely({areDocBuilderImagesLoaded: true})}
        documentRecord={documentRecord}
      />
    );
  }

  renderDocumentPreviewTab() {
    if (!this.shouldShowDocumentPreview()) {
      return "";
    }

    const {
      panelSize,
      showingState,
      containerRef,
      onLoaded,
    } = this.props;

    const {
      currentState,
      documentSource,
      DocumentVersions,
      fileData,
      id,
      isQbdSrcDoc,
    } = this.state;

    const strBuilder = [`documentId=${id}`];

    if (fileData?.smartContent) {
      delete fileData.smartContent;
    }

    const url = UIUtils.getSecuredURL(`${UIUtils.FRONT_END_URL}/documentEditor/documentEditor.html?${strBuilder.join("&")}`);
    const fileExists = !!(fileData?.fileName);

    const blankStateMessage = isQbdSrcDoc ? "There is no PDF yet. \n A PDF will be created from your QbDVision doc and sent to approvers for review." : "No PDF uploaded yet.";
    const documentTabVisible = this.getSelectedEditorTab().key === DOCUMENT_EDITOR_TABS.DOCUMENT.key;

    const quickPanelWarning = () => {
      let warningMessage;
      let shouldShowDocEditorLink = true;
      if (currentState === "Approved") {
        warningMessage = DocumentVersions[0]?.currentState === "Draft" ? QUICKPANEL_WARNING_MESSAGES.DRAFT_WITH_APPROVED : "";
      } else if (currentState === "Proposed") {
        warningMessage = QUICKPANEL_WARNING_MESSAGES.PROPOSED;
      } else {
        warningMessage = QUICKPANEL_WARNING_MESSAGES.DRAFT_WITH_PDF;
        shouldShowDocEditorLink = false;
      }

      return (
        warningMessage &&
        <div className="document-quickpanel-warning quick-panel-record-info-box alert alert-warning">
          <div className={"qp-warning-message"}>
            {warningMessage}
          </div>
          {shouldShowDocEditorLink && <div className="qp-doc-editor-link link">
            <a href={url} target={"_blank"} rel={"noreferrer"} className="edit-with-qbd-link">View QbDVision Doc</a>
          </div>}
        </div>
      );
    };

    return (
      <>
        {fileExists ?
          <>
            {isQbdSrcDoc && documentTabVisible && quickPanelWarning()}
            <PDFViewer
              downloadLink={fileData}
              documentSource={documentSource}
              name={"documentPreview"}
              panelSize={panelSize}
              showingState={showingState}
              isVisible={documentTabVisible}
              onLoading={this.handleDocumentPreviewLoading}
              onLoaded={onLoaded}
              onReady={this.handleDocumentPreviewReady}
              onDownload={this.handleDocumentDownload}
              onValidationError={this.handleDocumentPreviewError}
              elementToFill={containerRef}
            />
          </> : (documentTabVisible && (
            <div className={docStyles["document-quick-panel-blank-state"]}>{blankStateMessage}</div>))
        }
      </>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const interactionName = "Document :: componentDidUpdate";
    measurePerformanceStart(interactionName);
    super.componentDidUpdate(prevProps, prevState, snapshot);

    const wasDiff = this.isDiffingVersions(prevState);
    const oldData = this.getFileData(wasDiff, prevState);

    const isDiff = this.isDiffingVersions();
    const newData = this.getFileData(isDiff);

    if (newData !== oldData) {
      this.runPromise(this.setFileData(newData));
    }

    if (this.completeDocumentProposal) {
      this.handleCompleteProposal();
    }

    measurePerformanceEnd(interactionName);
  }

  renderCreateCustomDocument(inputId, isQbdSrcDoc) {
    return (
      <DocumentHeaderLink
        ref={this.documentHeaderLinkRef}
        projectId={this.state.ProjectId}
        documentFileData={this.getFileData(false)}
        isQbdSrcDoc={isQbdSrcDoc}
        inputId={inputId}
        onSwitchDocumentSource={(value) =>
          this.handleChangeValue("isQbdSrcDoc", value)
        }
        onFileRemove={() =>
          this.handleChangeValue(
            "uploadDocumentLinks",
            DocumentAttachmentEditAttribute.getInitialValue(),
          )
        }
        onCreateDocument={(newDocumentContent) => {
          this.handleChangeValue("DocumentContentId", newDocumentContent.id);
          this.handleChangeValue("documentContentVersionId", newDocumentContent.lastVersionId);
          this.setStateSafely({documentContent: newDocumentContent});
        }}
      />
    );
  }

  renderQbDVisionDocContentChangesNotification() {
    const fileData = this.getFileData(false);
    const {currentSmartContent} = this.state;
    return (
      <DocumentContentChangesNotification
        documentRecord={this.state}
        fileData={fileData}
        currentSmartContent={currentSmartContent}
      />
    );
  }

  renderExternalDocumentAttributes(isView) {
    return isView ?
      (<DocumentAttachmentViewAttribute
        ref={component => this.setDocumentAttachment(component)}
        name="uploadDocumentLinks"
        docAttachmentControlName="uploadDocumentLinks"
        instance={this.state}
        isView={true}
        onDownload={this.handleDocumentDownload}
        olderVersion={this.getOlderVersion()}
        isDiffingVersions={this.isDiffingVersions()}
        isLoading={this.isLoading()}
      />)
      :
      (<DocumentAttachmentEditAttribute
        ref={component => this.setDocumentAttachment(component)}
        name="uploadDocumentLinks"
        parent={this}
        onDownload={this.handleDocumentDownload}
      />);
  }

  renderQbDSrcDocAttribute() {
    return (
      <QbDDocumentAttachmentViewAttribute
        documentRecord={this.state}
        isView={this.isView()}
        olderVersion={this.getOlderVersion()}
        isDiffingVersions={this.isDiffingVersions()}
        isLoading={this.isLoading()}
        onSelectNewTemplate={() => this.documentHeaderLinkRef?.current?.showSelectTemplatePopup()}
      />
    );
  }

  renderAboutTab() {
    let selectedEditorTab = this.getSelectedEditorTab();
    if (selectedEditorTab.key !== DOCUMENT_EDITOR_TABS.ABOUT.key) {
      return "";
    }

    return (
      <div>
        {this.renderAboutSection()}
        {this.renderOriginalDocumentSection()}
        {this.renderRegulatorySection()}
        {this.renderReferencesSection()}
      </div>
    );
  }

  renderAboutSection() {
    const controlledCheckboxAttribute = (<CheckboxAttribute name="controlled"
                                                            checkedByDefault={true}
                                                            tooltipText={"This box should be checked if this document needs to be controlled per company SOP."}
                                                            parent={this}
                                                            classname="col-sm-12"
    />);

    const customIdAttribute = (<TextAttribute name="customID"
                                              tooltipText="Use this field to provide additional identifying
                                              information about the document (e.g. batch number or protocol number)"
                                              displayName="Custom ID"
                                              className="col-sm-3"
                                              parent={this}
    />);

    const moreInfoButton = (<SeeMoreButton className={docStyles["more-info-button"]}
                                           id={"documentMoreInfoButton"}
                                           isExpanded={this.state.isAboutExpanded}
                                           expansionToggle={() => this.setStateSafely({isAboutExpanded: !this.state.isAboutExpanded})}
    />);

    return (<Section parent={this}
                     header={"About"}
                     collapsible={false}
    >
      <div className="row">
        <TextAttribute name="name" parent={this}
                       className="col-sm-6"
        />
        {customIdAttribute}
      </div>
      <div className="row">
        {this.renderProjectAndProcess()}
      </div>
      <div className="row">
        <ComboBoxAttribute name="type"
                           options={CommonDocuments.DOCUMENT_TYPES}
                           className="col-sm-6"
                           parent={this}
                           isLoading={this.state.isLoading}
                           parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                           parentId={this.state.id}
        />
      </div>
      <div className="row">{moreInfoButton}</div>
      {this.state.isAboutExpanded &&
        <div>
          <div className="row">{controlledCheckboxAttribute}</div>
          <div className="row">
            <ComboBoxAttribute name="category"
                               options={CommonDocuments.Document_CATEGORIES}
                               className="col-sm-6"
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
            <DateAttribute name="effectiveDate"
                           className="col-sm-3"
                           minDate={moment()}
                           parent={this}
            />
            <TextAttribute name="site"
                           className="col-sm-6"
                           parent={this}
            />
            <TextAreaAttribute name="purpose"
                               className="col-sm-12"
                               parent={this}
            />
            <TextAreaAttribute name="scope"
                               className="col-sm-12"
                               parent={this}
            />
          </div>
          <div className="row">
            <TextAttribute name="author"
                           displayName="Author(s)"
                           className="col-sm-6"
                           parent={this}
            />
            <TextAttribute name="sme"
                           displayName="SME(s)"
                           className="col-sm-6"
                           parent={this}
            />
          </div>
        </div>}
    </Section>);
  }

  renderReferencesSection() {
    return (<Section id="references"
                     parent={this}
                     showDocLinks={true}
                     tooltipText="Provide links or attachments that will be used as training materials for this document"
                     addOptions={[
                       {
                         id: "references",
                         label: "References",
                       },
                       {
                         id: "standards",
                         label: "Standards",
                       },
                       {
                         id: "guidances",
                         label: "Guidances",
                       }]}
                     header={<span>References and Standards <InfoTooltip id="infoReferences"
                                                                         verbiage={<div>
                                                                           Use this section to link or upload any relevant
                                                                           references, standards, or guidelines supporting
                                                                           the document or its related parts.
                                                                         </div>}
                     /></span>}
    >
    </Section>);
  }

  renderRegulatorySection() {
    return (<Section id="regulatory"
                     parent={this}
                     showDocLinks={false}
                     header={<span>Regulatory
                   <InfoTooltip id="infoRegulatory"
                                verbiage={
                                  <div>
                                    Use this section to tag the document with the associated
                                    section of the following regulatory documents as appropriate.
                                    These tags can be later used to list the stored documents
                                    by section for the appropriate regulatory submission.
                                  </div>}
                   />
                 </span>}
    >
      <div className="row">
        <TypeaheadAttribute name="regulatoryDocument"
                            options={[
                              "None",
                              "Pre-IND",
                              "IND",
                              "ANDA",
                              "SNDA",
                              "NDA",
                              "BLA - 351(a)",
                              "BLA - 351(k)",
                              "Drug Master File (DMF)",
                              "Design History File (DHF)",
                              "Device Master Record (DMR)",
                              "Pre-Market Approval (PMA)",
                              "510(k) DeNovo",
                              "510(k)",
                            ]}
                            className="col-sm-6"
                            parent={this}
                            isLoading={this.state.isLoading}
                            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                            parentId={this.state.id}
                            multiple={true}
        />
        <TypeaheadAttribute name="ectdSection"
                            tooltipText="eCTD - Electronic Common Technical Document"
                            tooltipGuidanceURL="https://www.fda.gov/media/76444/download"
                            displayName="eCTD Section"
                            options={[
                              "M1 - ADMINISTRATIVE INFORMATION",
                              "1.1 Forms",
                              "1.2 Cover Letters",
                              "1.3 Administrative Information",
                              "1.4 References",
                              "1.5 Application Status",
                              "1.6 Meetings",
                              "1.7 Fast Track",
                              "1.8 Special Protocol Assessment Request",
                              "1.9 Pediatric Administrative Information",
                              "1.10 Dispute Resolution",
                              "1.11 Information Amendment",
                              "1.12 Other Correspondence",
                              "1.13 Annual Report",
                              "1.14 Labeling",
                              "1.15 Promotional Material",
                              "1.16 Risk Management Plan",
                              "1.17 Postmarketing Studies",
                              "1.18 Proprietary Names",
                              "1.19 Pre-EUA and EUA",
                              "1.20 General Investigational Plan for initial IND",
                              "M2 - SUMMARIES",
                              "2.2 Introduction to summary",
                              "2.3 Quality overall summary",
                              "2.4 Nonclinical overview",
                              "2.5 Clinical overview",
                              "2.6 Nonclinical written and tabulated summaries",
                              "2.7 Clinical summary",
                              "M3 - QUALITY",
                              "3.2 Body of Data",
                              "3.2.S Drug Substance",
                              "3.2.S.1 General information",
                              "3.2.S.2 Manufacture",
                              "3.2.S.3 Characterization",
                              "3.2.S.4 Control of drug substance",
                              "3.2.S.5 Reference standards or materials",
                              "3.2.S.6 Container closure system",
                              "3.2.S.7 Stability",
                              "3.2.P Drug Product",
                              "3.2.P.1 Description and composition",
                              "3.2.P.2 Pharmaceutical development",
                              "3.2.P.3 Manufacture",
                              "3.2.P.4 Control of excipients",
                              "3.2.P.5 Control of drug product",
                              "3.2.P.6 Reference standards or materials",
                              "3.2.P.7 Container closure system",
                              "3.2.P.8 Stability",
                              "3.2.A Appendices",
                              "3.2.R Regional information",
                              "3.3 Literature References",
                              "M4 - NONCLINCAL STUDY REPORTS",
                              "4.2 Study reports",
                              "4.2.1 Pharmacology",
                              "4.2.1.1 Primary pharmacodynamics",
                              "4.2.1.2 Secondary pharmacodynamics",
                              "4.2.1.3 Safety Pharmacology",
                              "4.2.1.4 Pharmacodynamic Drug Interactions",
                              "4.2.2 Pharmacokinetics",
                              "4.2.2.1 Analytical Methods and Validation",
                              "4.2.2.2 Absorption",
                              "4.2.2.3 Distribution",
                              "4.2.2.4 Metabolism",
                              "4.2.2.5 Excretion",
                              "4.2.2.6 Pharmacokinetic drug interactions",
                              "4.2.2.7 Other pharmacokinetic studies",
                              "4.2.3 Toxicology",
                              "4.2.3.1 Single dose toxicity",
                              "4.2.3.2 Repeat dose toxicity",
                              "4.2.3.3 Genotoxicity",
                              "4.2.3.4 Carcinogenicity",
                              "4.2.3.5 Reproductive and developmental toxicity",
                              "4.2.3.6 Local tolerance",
                              "4.2.3.7 Other toxicity studies",
                              "4.3 Literature References",
                            ]}
                            className="col-sm-6"
                            parent={this}
                            isLoading={this.state.isLoading}
                            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                            parentId={this.state.id}
                            multiple={true}
        />
      </div>
      <div className="row">
        <TypeaheadAttribute name="dhfSection"
                            tooltipText="DHF - Design History File - § 820.3(e) Design history file (DHF) means a compilation of records which describes the
                                design history of a finished device. § 820.30(j) Design history file. Each manufacturer shall establish and maintain a DHF for each type of device.
                                The DHF shall contain or reference the records necessary to demonstrate that the design was developed in accordance with the approved design plan and the requirements of this part."
                            tooltipGuidanceURL="https://www.fda.gov/media/89241/download"
                            displayName="DHF Section"
                            options={[
                              "1. Design Planning",
                              "2. Design Inputs",
                              "3. Design Development",
                              "4. Design Outputs",
                              "5. Design Verification",
                              "6. Design Validation",
                              "7. Design Reviews",
                              "8. Design Changes",
                              "9. Design Transfer",
                            ]}
                            className="col-sm-6"
                            parent={this}
                            isLoading={this.state.isLoading}
                            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                            parentId={this.state.id}
                            multiple={true}
        />
        <TypeaheadAttribute name="dmrSection"
                            tooltipText="DMR - Device Master Record - Title 21 CFR 820.3(j) states a “Device master record
                                means a compilation of records containing the procedures and specifications for a
                                finished device.” A Device Master Record (DMR) is a comprehensive record of all of
                                the procedures and instructions required to manufacture each type of glove. A DMR
                                contains or refers to the location of documents for manufacturing and processing
                                activities, such as procurement, processing, labeling, test and inspection, and packaging.
                                The DMR also contains information on the design, formulation, specifications, complete
                                manufacturing procedures, quality assurance requirements, acceptance criteria, packaging,
                                and labeling of a finished glove."
                            tooltipGuidanceURL="https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfcfr/CFRSearch.cfm?fr=820.181"
                            displayName="DMR Section"
                            options={[
                              "1. Device Specifications",
                              "2. Production Process",
                              "3. Quality Assurance",
                              "4. Packaging & Labeling",
                              "5. Installation & Maintenance",
                            ]}
                            className="col-sm-6"
                            parent={this}
                            isLoading={this.state.isLoading}
                            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                            parentId={this.state.id}
                            multiple={true}
        />
      </div>
      <div className="row">
        <TypeaheadAttribute name="k510Section"
                            displayName="510(k) Section"
                            options={[
                              "1. User Fee Cover Sheet (Form 3601)",
                              "2. Premarket Review Cover Sheet (Form 3514)",
                              "3. 510(k) Cover Letter",
                              "4. Indications for Use (FDA Form 3881)",
                              "5. 510(k) Summary Statement",
                              "6. Truthful and Accuracy Statement",
                              "7. Class III Summary and Certification",
                              "8. Financial Certification or Disclosure Statement",
                              "9. Declarations of Conformity and Summary Reports",
                              "10. Device Description",
                              "11. Executive Summary/Predicate Comparison",
                              "12. Substantial Equivalence Discussion",
                              "13. Proposed Labeling",
                              "14. Sterilization and Shelf Life",
                              "15. Biocompatibility",
                              "16. Software",
                              "17. Electromagnetic Compatibility and Electrical Safety",
                              "18. Performance Testing – Bench",
                              "19. Performance Testing – Animal",
                              "20. Performance Testing – Clinical",
                              "21. Miscellaneous",
                            ]}
                            className="col-sm-6"
                            parent={this}
                            isLoading={this.state.isLoading}
                            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                            parentId={this.state.id}
                            multiple={true}
        />
        <TypeaheadAttribute name="pmaSection"
                            displayName="PMA Section"
                            tooltipText="Map document to Pre-market approval application."
                            tooltipGuidanceURL="https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfcfr/CFRSearch.cfm?FR=814.20"
                            options={[
                              "1. General Information",
                              "2. Table of Contents",
                              "3. Summary",
                              "3.1 Indications For Use",
                              "3.2 Device Description",
                              "3.3 Alternative Practices and Procedures",
                              "3.4 Marketing History",
                              "3.5 Summary of Studies",
                              "3.5.1 Summary of Nonclinical Studies",
                              "3.5.2 Summary of Clinical Studies",
                              "3.6 Conclusions of Studies",
                              "4. Complete Description",
                              "4.1 Device (including pictures)",
                              "4.2 Functional components",
                              "4.3 Properties of the device",
                              "4.4 Principles of operation",
                              "4.5 Manufacturing Methods",
                              "5. Reference to performance or voluntary standards",
                              "5.1 Performance Standard",
                              "5.2 Voluntary Standard",
                              "6. Technical Data",
                              "6.1 Nonclinical laboratory studies",
                              "6.2 Clinical results",
                              "6.3 IRB Compliance",
                              "6.4 Part 812 Compliance",
                              "6.5 Clinical studies outside US",
                              "7. Single Study Justification",
                              "8. Bibliography",
                              "8.1 Published reports (not submitted)",
                              "8.2 All other information relative safety and effectiveness",
                              "8.3 All reports or information available upon FDA request",
                              "9. Device/Components Samples",
                              "10. Proposed Labeling",
                              "11. Environmental assessment",
                              "12. Financial certification or disclosure statement",
                              "13. Use in pediatric patients",
                              "13.1 Description of pediatric subpopulations",
                              "13.2 Number of affected pediatric patients",
                              "14. Other Information",
                            ]}
                            className="col-sm-6"
                            parent={this}
                            isLoading={this.state.isLoading}
                            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                            parentId={this.state.id}
                            multiple={true}
        />
      </div>
    </Section>);
  }

  renderOriginalDocumentSection() {
    const {isQbdSrcDoc} = this.state;
    return (<Section id="originalDocument"
                     parent={this}
                     showDocLinks={!isQbdSrcDoc}
                     hideAppliesToColumn={true}
                     headerLink={!this.isView() ? this.renderCreateCustomDocument("originalDocument", isQbdSrcDoc) : null}
                     header={<span>Document
                   <InfoTooltip id="infoOriginalDocument"
                                verbiage={
                                  <div>
                                    Use this section to link or upload the source files
                                    for the Document PDF. This section can help teams
                                    by providing a centralized location for those files.
                                  </div>}
                   />
                 </span>}
    >
      {this.renderQbDVisionDocContentChangesNotification()}
      <div className="form-group">
        {this.renderExternalDocumentAttributes(this.isView())}
      </div>
      {isQbdSrcDoc && this.renderQbDSrcDocAttribute()}
    </Section>);
  }

  renderProjectAndProcess() {
    return (
      <Fragment>
        <DocumentProjectAndProcessAttribute
          id={"ProjectAndProcess"}
          name={"Project"}
          parent={this}
          projectId={this.state.ProjectId}
          processId={this.state.ProcessId}
          isLoading={this.state.isLoading}
          parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
          parentId={this.state.id}
          onOK={this.handleDocumentAndProcessOk}
        />
      </Fragment>
    );
  }

  handleDocumentAndProcessOk({project, process}) {
    this.setStateSafely({
      // for some reason there are two fields... one uppercase and one lowercase
      ProjectId: project?.id ?? null,
      projectId: project?.id ?? null,
      ProcessId: process?.id ?? null,
      project: project ?? null,
      process: process ?? null,
    });
  }

  getDocumentProject() {
    const project = this.state.project;
    if (project) {
      project.typeCode = "PRJ";
    }
    return project;
  }

  getFileData(getOlderVersion, state = this.state) {
    let docInfo = getOlderVersion ? this.getOlderVersion(state) : state;

    let currentValue = null;
    if (docInfo) {
      currentValue = docInfo.uploadDocumentLinks;
    }

    if (currentValue && typeof currentValue === "string") {
      currentValue = JSON.parse(currentValue);
    }

    return currentValue ? currentValue[0] : null;
  }

  setDocumentAttachment(component) {
    this.documentAttachment = component;
    const fileData = this.getFileData();

    if (fileData) {
      this.runPromise(this.setFileData(fileData));
    }
  }

  async setFileData(fileData, isFromSave = false) {
    Logger.verbose("SetFileData", Log.object(fileData && fileData.fileName));
    const {onLoading, onLoaded, onError} = this.props;
    const {fileData: currentFileData} = this.state;
    const isFullScreen = this.getEditorType() === EDITOR_TYPES.FULL_SCREEN;

    const shouldDownload = (
      this.shouldShowDocumentPreview()
      && (this.hasEditorTab(DOCUMENT_EDITOR_TABS.DOCUMENT) || isFromSave)
      && fileData
      && fileData.fileName
      && (
        !currentFileData
        || currentFileData.fileName !== fileData.fileName
        || currentFileData.S3TmpVersion !== fileData.S3TmpVersion
        || currentFileData.linkVersion !== fileData.linkVersion
      )
    );

    if (shouldDownload) {
      Logger.info("Document preview file changed", Log.object(fileData && fileData.fileName));
      onLoading();

      try {
        await this.setStateSafelyAsync({fileData});
        let documentSource = await this.downloadDocumentForPreview(fileData);
        await this.setStateSafelyAsync({documentSource});
        let isDocumentPreviewLoading = (
          !isFullScreen
          && (this.getSelectedEditorTab().key === DOCUMENT_EDITOR_TABS.DOCUMENT.key)
        );

        if (!isDocumentPreviewLoading) {
          onLoaded();
        }
      } catch (error) {
        return onError(error);
      }
    }
  }

  downloadDocumentForPreview(fileData) {
    return USE_DATA_URL
      ? DocumentTransferHelper.downloadAsDataUrl(fileData)
      : DocumentTransferHelper.downloadAsBuffer(fileData);
  }

  getEditorTabs(...extraTabs) {
    let tabs = [];
    let disabledOptions = {};
    const {isQbdSrcDoc, LastApprovedVersionId} = this.state;
    const showDocumentPreview = this.shouldShowDocumentPreview();
    const currentState = this.getCurrentState();
    const tooltip = "Open the PDF to view the information proposed for approval. To edit the document, create a new draft of the QbDVision Doc in Doc Builder.";

    if (LastApprovedVersionId || currentState === "Proposed") {
      disabledOptions = {disabled: true, disabledTooltip: tooltip};
    }

    const qbdDocTab = {...DOCUMENT_EDITOR_TABS.SOURCE_DOC, ...disabledOptions};

    if (showDocumentPreview) {
      tabs.push(DOCUMENT_EDITOR_TABS.DOCUMENT, ...(isQbdSrcDoc ? [qbdDocTab] : []), DOCUMENT_EDITOR_TABS.ABOUT);
    } else {
      tabs.push(DOCUMENT_EDITOR_TABS.ABOUT);
    }
    return super.getEditorTabs(...tabs, ...extraTabs);
  }

  shouldShowDocumentPreview() {
    let {operation} = this.props;
    const isFullScreen = this.getEditorType() === EDITOR_TYPES.FULL_SCREEN;

    const showPreviewInBulkOperations = UIUtils.isExperimentEnabled(EXPERIMENTS.BulkDocumentPreview);
    const isBulkOperation = (
      operation === RECORD_OPERATIONS.BULK_APPROVE
      || operation === RECORD_OPERATIONS.BULK_PROPOSE
    );
    const isView = this.isView();
    return (
      isView &&
      !isFullScreen &&
      (!isBulkOperation || showPreviewInBulkOperations)
    );
  }

  handleFormInvalid() {
    super.handleFormInvalid();
    UIUtils.setHideLoadingOnAjaxStop(true);
    UIUtils.hideLoadingImage();
  }

  cleanStateForComponentUpdate(state) {
    const cleanedState = {...state};
    delete cleanedState.documentContent;
    delete cleanedState.DocumentVersions;
    delete cleanedState.currentSmartContent;
    return cleanedState;
  }
}

Document.defaultProps = {
  onLoading: (status) => {
    UIUtils.showLoadingImage(status ? status.message : "");
  },
  onReady: () => {
    UIUtils.hideLoadingImage();
  },
  onLoaded: () => {
    UIUtils.hideLoadingImage();
  },
  onError: (error, details) => {
    UIUtils.defaultFailFunction(error, details);
  },
};
