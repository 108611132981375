"use strict";

import React, {useEffect, useState} from "react";
import WidgetItem, {WidgetData} from "./widget_item";
import {EditorUtils} from "@progress/kendo-react-editor";
import EditorParser from "../../../utils/parser/parser";
import {EditorView, NodeType, Node} from "@progress/kendo-editor-common";
import {WIDGET_ATTRIBUTES, WIDGET_KIND} from "./widget_constants";
import {Project} from "../../../../common/models/project";
import {
  getClosestWidget,
  isInWidget,
  WIDGET_NODE,
} from "../../../common/editorSchemas/widget_node";
import WidgetFieldsTree from "./widget_fields_tree";
import {NON_EDITABLE_QBD_FIELD_NODE} from "../../../common/editorSchemas/qbd_field_node";

const WIDGET_TOOLTIP = {
  [WIDGET_KIND.Repeater]: () => (
    <div>
      <div>
        Repeater widgets create templates for enumerating lists of information.
        When populated with smart content fields, the Repeater will
        automatically iterate and format the data according to the outlined
        structure.
      </div>
      <div />
      <div>
        Every Repeater requires a model to define the scope of the data. The
        model selected must match the record name that the smart content fields
        are sourced from in order for the data to render.
      </div>
    </div>
  ),
  [WIDGET_KIND.Table]: () => (
    <div>
      <div>
        Table widgets create templates for enumerating lists of information in a
        table format. When populated with smart content fields, the Table widget
        will automatically iterate and format the data according to the outlined
        structure.
      </div>
      <div />
      <div>
        Every Table widget requires a model to define the scope of the data. The
        model selected must match the record name that the smart content fields
        are sourced from in order for the data to render. Filters based on the
        model or submodel selected can be used to define criteria for the data
        that will populate the table.
      </div>
    </div>
  ),
  [WIDGET_KIND.ReferenceList]: () => (
    <div>
      Reference List widgets are used to add references to a Reference Block
      widget. The information in each Reference List widget is numbered and
      listed in the associated Reference Block in the order in which the
      widgets appear in the document. When populated, the Reference List
      information will be abbreviated with the number that it refers to in the
      Reference Block widget.
    </div>
  ),
  [WIDGET_KIND.ReferenceBlock]: () => (
    <div>
      The Reference Block widget creates a reference section based on the
      information added to each Reference List widget. The Reference Block
      automatically numbers this information based on the order that the
      Reference List widgets appear in the document. Multiple Reference Blocks
      can be added to the same document.
    </div>
  ),
  [WIDGET_KIND.TableOfContents]: () => (
    <div>
      The Table of Contents widget creates a numbered list of sections that are
      in the document. When populated, the Table of Contents will be
      automatically formatted based on section headers and linked to each
      section.
    </div>
  ),
};

type WidgetProps = {
  editorView: EditorView;
  project: Project;
};

export default function Widget(props: WidgetProps) {
  const {editorView, project} = props;
  const [selectedWidget, setSelectedWidget] = useState<string>();
  const [widgetData, setWidgetData] = useState<WidgetData>(null);

  useEffect(() => {
    const widget = getClosestWidget(editorView);
    // If we are in a widget, we will show the widget tab settings
    // Else, we will show the list of widgets
    if (
      widget &&
      (widget.attrs?.kind === WIDGET_KIND.Table ||
        widget.attrs?.kind === WIDGET_KIND.Repeater)
    ) {
      const widgetModelParts = widget.attrs.model.split(".");
      setSelectedWidget(widget.attrs.kind);
      setWidgetData({
        model: widgetModelParts[0],
        attribute: widgetModelParts.length === 2 ? widgetModelParts[1] : null,
        filters: widget.attrs.filters ? JSON.parse(widget.attrs.filters) : null,
      });
    } else {
      setSelectedWidget(null);
      setWidgetData(null);
    }
  }, [editorView?.state?.selection?.$anchor?.pos]);

  const getWidgets = () => {
    const widgets = [WIDGET_KIND.Repeater, WIDGET_KIND.Table];

    // The "Reference List" widget cannot be in another Reference List
    if (!isInWidget(editorView, WIDGET_KIND.ReferenceList)) {
      widgets.push(WIDGET_KIND.ReferenceList);
    }

    // The "Reference Block" cannot be in any widgets.
    if (!isInWidget(editorView)) {
      widgets.push(WIDGET_KIND.ReferenceBlock);

      // We allow to have only 1 TOC
      const editorContent = EditorUtils.getHtml(editorView.state);
      const tableOfContentWidgets = EditorParser.getWidgetByKind(
        editorContent,
        WIDGET_KIND.TableOfContents,
      );
      if (!tableOfContentWidgets.length) {
        widgets.push(WIDGET_KIND.TableOfContents);
      }
    }

    return widgets;
  };

  const getAttributes = (data: WidgetData) => {
    const attrs = {
      class: "widget",
      kind: selectedWidget,
    };
    for (const attribute of WIDGET_ATTRIBUTES) {
      if (!data[attribute.key]) {
        continue;
      }

      if (
        Array.isArray(data[attribute.key]) &&
        data[attribute.key].length === 0
      ) {
        continue;
      }

      attrs[attribute.key] = Array.isArray(data[attribute.key])
        ? JSON.stringify(data[attribute.key])
        : data[attribute.key];
    }
    return attrs;
  };

  const addSpan = (data: WidgetData) => {
    const {schema} = editorView.state;
    const attrs = getAttributes(data);
    const widgetNodeType = schema.nodes[WIDGET_NODE.name];
    let widgetNode: Node;
    if (selectedWidget === WIDGET_KIND.Table) {
      const nodes = schema.nodes as {
        table: NodeType;
        table_row: NodeType;
        table_cell: NodeType;
      };
      const rows = 1;
      const columns = 2;
      const table = EditorUtils.createTable(nodes, rows, columns);
      widgetNode = widgetNodeType.createAndFill(attrs, table);
    } else {
      widgetNode = widgetNodeType.createAndFill(attrs);
    }

    EditorUtils.insertNode(editorView, widgetNode);
    editorView.focus();
  };

  const handleAddWidget = (data: WidgetData) => {
    if (data.attribute) {
      addSpan({...data, model: `${data.model}.${data.attribute}`});
    } else {
      addSpan(data);
    }

    if (
      selectedWidget === WIDGET_KIND.Repeater ||
      selectedWidget === WIDGET_KIND.Table
    ) {
      setWidgetData(data);
    }
  };

  const renderWidgets = () => {
    const widgets = getWidgets();
    return widgets.map((widget) => {
      return (
        <WidgetItem
          key={widget}
          name={widget}
          selected={selectedWidget === widget}
          editorView={editorView}
          project={project}
          tooltipContent={WIDGET_TOOLTIP[widget]}
          enabledModels={[
            "Project",
            "Process",
            "UnitOperation",
            "Step",
            "IQA",
            "IPA",
            "ProcessComponent",
            "ProcessParameter",
            "Material",
            "MaterialAttribute",
            "AcceptanceCriteriaRange",
            "ReferencesLinks",
            "FQA",
            "FPA",
            "ControlMethod",
            "TPPSection",
            "GeneralAttribute",
            "Supplier",
            "Document"
          ]}
          onSelectWidget={(widgetName) => setSelectedWidget(widgetName)}
          onAddWidget={handleAddWidget}
        />
      );
    });
  };

  const handleAddField = (field: string) => {
    const {schema, selection} = editorView.state;
    let className = "qbd-output";

    // If the added node is in a widget, we append widget in the class
    // to differentiate with the global one. It will be useful for parsing
    // @ts-ignore
    for (const path of selection.$anchor.path) {
      if (
        path.attrs &&
        path.attrs.class &&
        path.attrs.class.includes("widget")
      ) {
        className = `${className} ${className}-widget`;
        break;
      }
    }

    const nodeType = schema.nodes[NON_EDITABLE_QBD_FIELD_NODE.name];
    const node = nodeType.createAndFill(
      {class: className},
      schema.text(field),
    );
    EditorUtils.insertNode(editorView, node);
    editorView.focus();
  };

  const renderWidgetFields = () => {
    const {model, attribute, filters} = widgetData;
    return (
      <WidgetFieldsTree
        modelName={model}
        subModelName={attribute}
        widgetType={selectedWidget}
        filters={filters}
        project={project}
        onBackClick={() => setWidgetData(null)}
        onAddField={handleAddField}
      />
    );
  };

  return (
    <div className={widgetData ? "" : "px-3 widget-selection-list"}>
      {widgetData ? renderWidgetFields() : renderWidgets()}
    </div>
  );
}
