var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames } from '@progress/kendo-react-common';
import { cardType } from './interfaces/Enums';
import { cardOrientation } from './interfaces/Enums';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
export var Card = React.forwardRef(function (props, ref) {
    var _a;
    validatePackage(packageMetadata);
    var children = props.children, dir = props.dir, style = props.style, className = props.className, type = props.type, orientation = props.orientation, customProps = __rest(props, ["children", "dir", "style", "className", "type", "orientation"]);
    var target = React.useRef(null);
    var chipRef = React.useRef(null);
    React.useImperativeHandle(target, function () { return ({
        element: chipRef.current,
        props: props
    }); });
    React.useImperativeHandle(ref, function () { return target.current; });
    return (React.createElement("div", __assign({ dir: dir, style: style, ref: chipRef, className: classNames('k-card', className, (_a = {}, _a["k-card-".concat(type)] = type !== cardType.DEFAULT, _a), orientation !== cardOrientation.HORIZONTAL ? 'k-card-vertical' : 'k-card-horizontal') }, customProps), children));
});
Card.displayName = 'KendoReactCard';
Card.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    dir: PropTypes.string,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    style: PropTypes.object,
    type: PropTypes.oneOf(['default', 'primary', 'info', 'success', 'warning', 'error'])
};
Card.defaultProps = {
    type: cardType.DEFAULT,
    orientation: cardOrientation.VERTICAL
};
