var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Icon } from '@progress/kendo-react-common';
import { getChildrenPosition } from '../utils/misc';
/**
 * @hidden
 */
export var downArrowName = 'caret-alt-down';
/**
 * @hidden
 */
export var rightArrowName = 'caret-alt-right';
/**
 * @hidden
 */
export var leftArrowName = 'caret-alt-left';
var MenuItemArrow = /** @class */ (function (_super) {
    __extends(MenuItemArrow, _super);
    function MenuItemArrow() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * @hidden
     */
    MenuItemArrow.prototype.render = function () {
        return React.createElement(Icon, { className: 'k-menu-expand-arrow', name: this.getIconName() });
    };
    MenuItemArrow.prototype.getIconName = function () {
        var childrenPosition = getChildrenPosition(this.props.itemId, this.props.verticalMenu === true, this.props.dir === 'rtl');
        switch (childrenPosition) {
            case 'downward':
                return downArrowName;
            case 'rightward':
                return rightArrowName;
            case 'leftward':
                return leftArrowName;
            default:
                return undefined;
        }
    };
    /**
     * @hidden
     */
    MenuItemArrow.propTypes = {
        itemId: PropTypes.string,
        dir: PropTypes.string,
        verticalMenu: PropTypes.bool
    };
    return MenuItemArrow;
}(React.Component));
export { MenuItemArrow };
