"use strict";

import React from "react";

export const VERSION_REGEX = "^(\\s*|0|[0-9]\\d*|0\\.1)$";

export const FIELD_NAMES = {
  VERSION: "version",
  CUSTOM_ID: "customID",
  DOCUMENT_NAME: "documentName",
  EFFECTIVE_DATE: "effectiveDate",
  TYPE: "documentType",
  CATEGORY: "documentCategory",
  FILE_NAME: "fileName",
};

export const TABLE_FIELDS = {
  VERSION: {
    title: "Version",
    tooltip: "Set the first major version of the document in the system (e.g., enter v5.0 as 5). To upload the document as a draft, leave this number as blank, 0, or 0.1."
  },
  CUSTOM_ID: {
    title: "Custom ID",
    tooltip: "Use this field to provide additional identifying information about the document (e.g. type and protocol number - PRO-0001)"
  },
  DOCUMENT_NAME: {
    title: "Document Name",
    tooltip: "The name of the document as it will appear throughout the system"
  },
};
