"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import ImportRecordInstructions from "./import_record_instructions";
import { generateTooltip } from "../../../utils/ui_permissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { createImportLinkURL } from "../../helpers/import_url_factory";
import { hasSufficientDependencies, hasPermissions } from "../../helpers/import_security";
import BaseReactComponent from "../../../base_react_component";

export default class ImportProjectRecordWidget extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  handleImportNewRecord() {
    window.location.href = createImportLinkURL(this.props.widgetParams);
  }


  render() {
    const {widgetParams} = this.props;
    const {
      supportedFileTypes,
      isOptional,
      dataSectionName,
      modelName,
      dependency,
      infoDescription,
      importContinuousData,
      securityAction,
      instructions,
      hideTitle,
      hideBorder,
      importButtonLabel,
      showDownloads,
      projectType,
      isPaperImport,
      title,
      dependenciesStatus,
      explicitDisable,
      processId,
      hasProjectTypeDependedTemplates,
      notIncludedProjectType,
    } = widgetParams;
    const enableImport = hasSufficientDependencies(dependenciesStatus, widgetParams, processId);
    const hasPermission = hasPermissions(widgetParams);

    const infoDescriptionId = `Import${UIUtils.convertToId(modelName)}${dependency ? `for${UIUtils.convertToId(dependency)}` : ""}InfoDescription`;
    const buttonId = `${dataSectionName
      ? `${dataSectionName} ${importContinuousData ? "Continuous" : ""}Data`
      : UIUtils.pluralize(modelName)} ${dependency ? ` for ${dependency}` : ""}`;


    return (
      <tr className={"import-project-record-widgets-row" + (hideBorder ? " no-borders" : "")}>
        <td className="import-project-record-widgets-cell">
          <div className="import-project-widget-title">
            {!hideTitle ? title : ""}
          </div>
          {isOptional ?
            <div className="import-project-widget-subscript">
              Optional
            </div>
            : ""}
        </td>
        <td className="import-project-record-widgets-cell">
          {supportedFileTypes}
        </td>
        <td className="import-project-record-widgets-cell">
          <ImportRecordInstructions
            showInstructions={false}
            showDownloads={showDownloads}
            modelName={modelName}
            dependency={dependency}
            projectType={projectType}
            hasProjectTypeDependedTemplates={hasProjectTypeDependedTemplates}
            notIncludedProjectType={notIncludedProjectType}
            className="import-project-widget-instructions-panel"
          />
          {instructions}
          {infoDescription && !enableImport && !explicitDisable ?
            <div className="import-project-widget-info">
              <div>
                <FontAwesomeIcon icon={faInfoCircle} className="table-description-info" />
              </div>
              <div>
                <span id={infoDescriptionId}>
                  {infoDescription}
                </span>
              </div>
            </div>
            : ""}
        </td>
        <td className="import-project-record-widgets-cell">
          <button id={`${UIUtils.convertToCamelCaseId((isPaperImport ? "COAImport" : "Import") + buttonId)}Btn`}
                  className="btn btn-lg btn-primary import-project-widget-btn"
                  onClick={this.handleImportNewRecord}
                  disabled={!enableImport || !hasPermission}
                  title={generateTooltip(securityAction, modelName, null, "Import")}
          >
            {importButtonLabel ? importButtonLabel : "Import"}
          </button>
        </td>
      </tr>
    );
  }
}
