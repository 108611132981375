"use strict";

import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import * as UIUtils from "../../../ui_utils";

/**
 * This function is used as a mini-class that is an item that is sortable in the RelatedDNDRecordsPanel.
 * @return {JSX.Element}
 */
export function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef}
         style={style}
         className="related-dnd-records-sortable row"
         id={UIUtils.convertToCamelCaseId(props.name)}
         {...attributes}
         {...listeners}>
      <div className="col px-0">{props.label}</div>
      <div className="col-auto px-0 text-right">
        <FontAwesomeIcon icon={faGripVertical} />
      </div>
    </div>
  );
}
