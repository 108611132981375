var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from './../main';
import { classNames, guid, kendoThemeMaps, Keys } from '@progress/kendo-react-common';
import { dispatchEvent } from '@progress/kendo-react-common';
import { ButtonItem } from './ButtonItem';
import { SplitButtonItem } from './SplitButtonItem';
import { navigate as navigation } from './utils/navigation';
import { Popup } from '@progress/kendo-react-popup';
import { getAnchorAlign, getPopupAlign } from './utils/popup';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
/**
 * Represents the [KendoReact SplitButton component]({% slug overview_splitbutton %}).
 *
 * @example
 * ```jsx
 * class App extends React.Component {
 *    render() {
 *        return (
 *            <SplitButton text="Act">
 *                <SplitButtonItem text="Item1" />
 *                <SplitButtonItem text="Item2" />
 *                <SplitButtonItem text="Item3" />
 *            </SplitButton>
 *        );
 *    }
 * }
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var SplitButton = /** @class */ (function (_super) {
    __extends(SplitButton, _super);
    function SplitButton(props) {
        var _this = _super.call(this, props) || this;
        /**
         * @hidden
         */
        _this.state = {
            focused: false,
            focusedIndex: -1,
            opened: false
        };
        _this.wrapper = null;
        _this.mainButton = null;
        _this.list = null;
        _this.guid = guid();
        _this.skipFocus = false;
        _this.buttonsData = [];
        _this.onKeyDown = function (event) {
            var focusedIndex = _this.state.focusedIndex;
            if (event.altKey) {
                if (!_this.opened && event.keyCode === Keys.down) {
                    _this.dispatchPopupEvent(event, true);
                    _this.setState({ focusedIndex: 0, opened: true });
                }
                else if (_this.opened && event.keyCode === Keys.up) {
                    _this.dispatchPopupEvent(event, false);
                    _this.setState({ focusedIndex: -1, opened: false });
                }
                return;
            }
            var newState = undefined;
            if (event.keyCode === Keys.enter || event.keyCode === Keys.space) {
                // Prevent default because otherwise when an item is selected
                // click on the default button gets emitted which opens the popup again.
                event.preventDefault();
                _this.dispatchClickEvent(event, focusedIndex);
                if (focusedIndex >= 0) {
                    newState = {
                        focusedIndex: _this.opened ? -1 : 0,
                        opened: !_this.opened
                    };
                    _this.dispatchPopupEvent(event, newState.opened);
                }
            }
            else if (_this.opened && event.keyCode === Keys.esc) {
                newState = {
                    focusedIndex: -1,
                    opened: false
                };
                _this.dispatchPopupEvent(event, newState.opened);
            }
            if (_this.opened) {
                var newFocused = navigation(focusedIndex, event.keyCode, event.altKey, _this.buttonsData.length);
                if (newFocused !== focusedIndex) {
                    newState = newState || {};
                    newState.focusedIndex = newFocused;
                }
                var arrowKey = event.keyCode === Keys.up || event.keyCode === Keys.down ||
                    event.keyCode === Keys.left || event.keyCode === Keys.right;
                if (!event.altKey && (arrowKey || event.keyCode === Keys.home || event.keyCode === Keys.end)) {
                    // Needed to notify the parent listeners that event is handled.
                    event.preventDefault();
                }
            }
            if (newState) {
                _this.setState(newState);
            }
        };
        _this.switchFocus = function (focusFn) {
            _this.skipFocus = true;
            focusFn();
            window.setTimeout(function () { return _this.skipFocus = false; }, 0);
        };
        _this.onFocus = function (event) {
            if (_this.skipFocus) {
                return;
            }
            dispatchEvent(_this.props.onFocus, event, _this, undefined);
            _this.setState({ focused: true });
        };
        _this.onItemClick = function (event, clickedItemIndex) {
            var opened = _this.opened;
            if (opened) {
                _this.setState({ focusedIndex: 0, opened: false });
            }
            _this.dispatchClickEvent(event, clickedItemIndex);
            if (opened) {
                _this.dispatchPopupEvent(event, false);
            }
        };
        _this.onBlur = function (event) {
            if (_this.skipFocus) {
                return;
            }
            _this.setState({
                focused: false,
                focusedIndex: -1,
                opened: false
            });
            dispatchEvent(_this.props.onBlur, event, _this, undefined);
            if (_this.opened) {
                _this.dispatchPopupEvent(event, false);
            }
        };
        _this.onPopupClose = function () {
            if (_this.state.focused) {
                _this.switchFocus(function () {
                    if (_this.element) {
                        _this.element.removeAttribute('tabindex');
                        _this.element.focus({ preventScroll: true });
                    }
                });
            }
        };
        _this.listRef = function (list) {
            _this.list = list;
            if (list && _this.state.focused) {
                _this.switchFocus(function () {
                    list.focus({ preventScroll: true });
                    if (_this.element) {
                        _this.element.tabIndex = -1;
                    }
                });
            }
        };
        _this.onSplitPartClick = function (event) {
            if (_this.buttonsData.length) {
                var toOpen = !_this.opened;
                _this.dispatchPopupEvent(event, toOpen);
                _this.setState({
                    focusedIndex: toOpen ? 0 : -1,
                    opened: toOpen,
                    focused: true
                });
            }
        };
        _this.onDownSplitPart = function (event) {
            event.preventDefault();
            if (_this.element && document.activeElement !== _this.element && document.activeElement !== _this.list) {
                _this.element.focus();
            }
        };
        _this.onItemDown = function (event) {
            if (document.activeElement === _this.list) {
                event.preventDefault();
            }
        };
        _this.dispatchPopupEvent = function (dispatchedEvent, open) {
            dispatchEvent(open ? _this.props.onOpen : _this.props.onClose, dispatchedEvent, _this, undefined);
        };
        validatePackage(packageMetadata);
        return _this;
    }
    Object.defineProperty(SplitButton.prototype, "opened", {
        get: function () {
            return this.props.opened === undefined ? this.state.opened : this.props.opened;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    SplitButton.prototype.render = function () {
        var _this = this;
        this.buttonsData = this.props.items ||
            React.Children.toArray(this.props.children)
                .filter(function (child) { return child && child.type === SplitButtonItem; })
                .map(function (child) { return child.props; });
        var rtl = this.isRtl();
        var dir = rtl ? 'rtl' : undefined;
        var _a = this.props, id = _a.id, style = _a.style, tabIndex = _a.tabIndex, disabled = _a.disabled;
        return (React.createElement("div", { id: id, style: style, className: classNames('k-split-button', 'k-button-group', {
                'k-focus': this.state.focused
            }, "k-rounded-".concat(kendoThemeMaps.roundedMap[this.props.rounded || 'medium']), this.props.className), onKeyDown: this.onKeyDown, onFocus: this.onFocus, onBlur: this.onBlur, dir: dir, ref: function (el) { return _this.wrapper = el; } },
            React.createElement(Button, { ref: function (el) { return _this.mainButton = el && el.element; }, type: "button", size: this.props.size, rounded: this.props.rounded, fillMode: this.props.fillMode, themeColor: this.props.themeColor, onClick: function (event) { return _this.onItemClick(event, -1); }, disabled: disabled || undefined, tabIndex: tabIndex, accessKey: this.props.accessKey, className: this.props.buttonClass, icon: this.props.icon, iconClass: this.props.iconClass, imageUrl: this.props.imageUrl, dir: dir, "aria-disabled": disabled, "aria-haspopup": true, "aria-expanded": this.opened || undefined, "aria-label": this.props.ariaLabel, "aria-controls": this.opened ? this.guid : undefined, id: 'button-' + this.guid, title: this.props.title }, this.props.text),
            React.createElement(Button, { type: "button", size: this.props.size, rounded: this.props.rounded, fillMode: this.props.fillMode, themeColor: this.props.themeColor, icon: "caret-alt-down", className: "k-split-button-arrow", disabled: disabled || undefined, tabIndex: -1, onClick: this.onSplitPartClick, onMouseDown: this.onDownSplitPart, onPointerDown: this.onDownSplitPart, dir: dir, "aria-label": "menu toggling button" }),
            this.renderPopup(rtl)));
    };
    /**
     * @hidden
     */
    SplitButton.prototype.componentDidMount = function () {
        // If this.props.opened is true during the initial render, the popup is not aligned.
        if ((this.props.dir === undefined && this.isRtl()) || this.opened) {
            this.forceUpdate();
        }
    };
    Object.defineProperty(SplitButton.prototype, "element", {
        /**
         * The DOM element of main button.
         */
        get: function () {
            return this.mainButton;
        },
        enumerable: false,
        configurable: true
    });
    SplitButton.prototype.dispatchClickEvent = function (dispatchedEvent, clickedItemIndex) {
        if (!this.isItemDisabled(clickedItemIndex)) {
            if (clickedItemIndex === -1) {
                dispatchEvent(this.props.onButtonClick, dispatchedEvent, this, undefined);
            }
            else {
                dispatchEvent(this.props.onItemClick, dispatchedEvent, this, {
                    item: this.buttonsData[clickedItemIndex],
                    itemIndex: clickedItemIndex
                });
            }
        }
    };
    SplitButton.prototype.renderPopup = function (rtl) {
        var _a;
        var _b = this.props.popupSettings, popupSettings = _b === void 0 ? {} : _b;
        var focusedIndex = this.state.focusedIndex;
        return (React.createElement(Popup, { anchor: this.wrapper, show: this.opened, animate: popupSettings.animate, popupClass: classNames('k-menu-popup', popupSettings.popupClass), anchorAlign: popupSettings.anchorAlign || getAnchorAlign(rtl), popupAlign: popupSettings.popupAlign || getPopupAlign(rtl), style: rtl ? { direction: 'rtl' } : undefined, onClose: this.onPopupClose },
            React.createElement("ul", { role: "menu", id: this.guid, "aria-labelledby": 'button-' + this.guid, tabIndex: -1, ref: this.listRef, "aria-activedescendant": focusedIndex >= 0 ? "".concat(this.guid, "-").concat(focusedIndex) : undefined, className: classNames('k-group k-menu-group k-reset', (_a = {},
                    _a["k-menu-group-".concat(kendoThemeMaps.sizeMap[this.props.size] || this.props.size)] = this.props.size,
                    _a)) }, this.renderChildItems())));
    };
    SplitButton.prototype.renderChildItems = function () {
        var _this = this;
        var _a = this.props, item = _a.item, itemRender = _a.itemRender, textField = _a.textField;
        return this.buttonsData.length > 0 ? (this.buttonsData.map(function (dataItem, index) {
            return (React.createElement(ButtonItem, { className: "k-menu-item", dataItem: dataItem, textField: textField, focused: _this.state.focusedIndex === index, onClick: _this.onItemClick, onDown: _this.onItemDown, render: itemRender, item: item, key: index, index: index, id: "".concat(_this.guid, "-").concat(index) }));
        })) : null;
    };
    SplitButton.prototype.isItemDisabled = function (index) {
        return this.buttonsData[index] ? this.buttonsData[index].disabled : this.props.disabled;
    };
    SplitButton.prototype.isRtl = function () {
        return this.props.dir !== undefined ? this.props.dir === 'rtl' :
            !!this.wrapper && getComputedStyle(this.wrapper).direction === 'rtl';
    };
    /**
     * @hidden
     */
    SplitButton.propTypes = {
        accessKey: PropTypes.string,
        ariaLabel: PropTypes.string,
        title: PropTypes.string,
        onButtonClick: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onItemClick: PropTypes.func,
        onOpen: PropTypes.func,
        onClose: PropTypes.func,
        text: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.any),
        textField: PropTypes.string,
        tabIndex: PropTypes.number,
        disabled: PropTypes.bool,
        icon: PropTypes.string,
        iconClass: PropTypes.string,
        imageUrl: PropTypes.string,
        popupSettings: PropTypes.object,
        itemRender: PropTypes.any,
        item: PropTypes.func,
        className: PropTypes.string,
        buttonClass: PropTypes.string,
        dir: PropTypes.string
    };
    /**
     * @hidden
     */
    SplitButton.defaultProps = {
        size: 'medium',
        rounded: 'medium',
        shape: null,
        fillMode: 'solid',
        themeColor: 'base'
    };
    return SplitButton;
}(React.Component));
export { SplitButton };
