"use strict";

import * as UIUtils from "../../../ui_utils";
import React, { Fragment } from "react";
import * as DocumentTransferHelper from "../../../helpers/document_transfer_helper";
import { FILE_STATUS } from "../../../helpers/document_transfer_helper";
import ValidationIcon from "../../../widgets/generic/validation_icon";
import { INVALID_FILE_EXTENSION_ERROR } from "../../../editor/attributes/constants/attributes_tooltips";
import BaseReactComponent from "../../../base_react_component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPaperclip } from "@fortawesome/free-solid-svg-icons";

export default class ImportFileUploader extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state.showProgressBar = false;
  }

  cancelUpload() {
    this.setStateSafely({
      showProgressBar: false,
    }, () => {
      this.props.onUploadCancel({
        linkType: "",
        fileName: "",
        S3TmpKey: "",
        S3TmpVersion: "",
        fileStatus: FILE_STATUS.NOT_SPECIFIED,
      });
      if (this.props.fileData && this.props.fileData.xhr) {
        this.props.fileData.xhr.abort();
      }
    });
  }

  handleFileSelection() {
    $(`#${this.props.id}Input`).trigger("click");
  }

  handleUploadCancel() {
    this.cancelUpload();
  }

  async handleFileUpload(e) {
    e.persist();
    let {fileData} = this.props;
    fileData.uuid = UIUtils.generateUUID();
    let input = $("#" + e.target.id)[0];

    if (input.files) {
      fileData.file = input.files[0];
      fileData.fileStatus = FILE_STATUS.NEW_FILE_SELECTED;
      await this.props.onNewFileSelection(fileData);

      this.setStateSafely({
        showProgressBar: true,
      }, () => {
        DocumentTransferHelper.handleUpload(e, fileData,
          `#${this.props.id}ProgressBar`,
          UIUtils.clearError,
          UIUtils.showError,
          this.forceUpdate.bind(this),
          this.props.onUpdateFileData,
          this.validateFileData);
      });
    }
  }

  validateFileData(fileData) {
    if (!UIUtils.isFileNameValidForUpload(fileData.fileName)) {
      this.setStateSafely({
        error: INVALID_FILE_EXTENSION_ERROR
      });
      return false;
    } else {
      this.setStateSafely({
        error: null
      });
    }

    return true;
  }

  render() {
    let inputId = this.props.id;
    const fileStatus = this.props.fileData.fileStatus || FILE_STATUS.NOT_SPECIFIED;

    return (
      <div className={"col-12" + (this.props.className ? " " + this.props.className : "")}>
        <div className="row">
          <label className="col-12 col-form-label import-file-selector">
            {this.props.label}
          </label>
          <ValidationIcon
            id={`${inputId}ErrorIcon`}
            tooltip={this.state.error}
            trigger="click"
            visible={!!this.state.error}
          />
          <div className="col-8 input-group">
            <input type="file"
                   accept={this.props.allowedFiles ? this.props.allowedFiles : ""}
                   style={{display: "none"}}
                   id={`${inputId}Input`}
                   onChange={this.handleFileUpload}
            />
            <input type="text" className="form-control" value={this.props.fileData.fileName} readOnly={true} />
            <span className="input-group-btn">
            <button id={inputId + (fileStatus !== FILE_STATUS.NOT_SPECIFIED ? "CancelButton" : "Button")}
                    className="btn btn-sm btn-secondary import-file-attach-button"
                    type="button"
                    onClick={fileStatus !== FILE_STATUS.NOT_SPECIFIED ? this.handleUploadCancel : this.handleFileSelection}
            >
              {fileStatus !== FILE_STATUS.NOT_SPECIFIED ?
                <FontAwesomeIcon icon={faTimes} />
                :
                <FontAwesomeIcon icon={faPaperclip} />
              }
            </button>
          </span>
          </div>
          {this.state.showProgressBar ?
            <Fragment>
              {/* Force the progress bar to a new line */}
              <div className="w-100" />
              <div className="progress import-file-selector-progress col-3">
                <div id={`${inputId}ProgressBar`}
                     className="progress-bar"
                     role="progressbar"
                     style={this.props.fileData.fileStatus === FILE_STATUS.UPLOADED ? {width: 100 + "%"} : {}}
                     aria-valuenow={this.props.fileData.fileStatus === FILE_STATUS.UPLOADED ? 100 : 0}
                     aria-valuemin="0"
                     aria-valuemax="100"
                >
                  {this.props.fileData.fileStatus === FILE_STATUS.UPLOADED ? "100%" : ""}
                </div>
              </div>
            </Fragment> : ""}
        </div>
      </div>
    );
  }
}
