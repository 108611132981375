"use strict";

import * as UIUtils from "../../ui_utils";
import * as FailHandlers from "../../utils/fail_handlers";
import BaseAutoBind from "../../base_auto_bind";

/**
 * This class is responsible for loading records in the background while the user surfs for what they need.
 */
export default class DataLoader extends BaseAutoBind {

  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.projectId = props.projectId;
  }

  loadProcessExplorerData(useWriterDB, loadTypeaheadPromise, transformResultsCallback = null) {
    const {parent} = this;
    parent.setStateSafely({isLoading: true});

    let params = {shouldCompress: true};
    if (useWriterDB) {
      params.useWriterDB = true;
    }

    if (parent.state.processId) {
      params.processId = parent.state.processId;
    }

    return UIUtils.secureAjaxGET("processExplorer/" + this.projectId + (Object.keys(params).length > 0 ? "?" + UIUtils.objectToURLParameters(params) : ""),
      undefined,
      true,
      FailHandlers.defaultStopLoadingFailFunction.bind(parent))
      .then((results) => {
        if (transformResultsCallback) {
          transformResultsCallback(results);
        }

        parent.dataReceiver.handleReceivedDataFromServer(useWriterDB, loadTypeaheadPromise, results);
      });
  }
}
