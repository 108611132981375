var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { onDownPreventDefault } from './utils';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { messages } from './../messages';
import { classNames } from '@progress/kendo-react-common';
/**
 * @hidden
 */
export var createProseMirrorTool = function (settings, command) {
    var Tool = /** @class */ (function (_super) {
        __extends(ProseMirrorTool, _super);
        function ProseMirrorTool() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        ProseMirrorTool.prototype.render = function () {
            var _a = this.props, view = _a.view, render = _a.render, buttonProps = __rest(_a, ["view", "render"]);
            var localization = provideLocalizationService(this);
            var titleKey = settings.messages.title;
            var state = view && view.state;
            var disabled = state ? !command(state) : false;
            var button = (React.createElement(Button, __assign({ onClick: disabled ? undefined : function () { return view &&
                    command(view.state, function (tr) { return view.dispatch(tr.setMeta('commandName', settings.commandName)); }); }, "aria-disabled": disabled ? true : undefined }, onDownPreventDefault, { title: localization.toLanguageString(titleKey, messages[titleKey]) }, settings.props, buttonProps, { className: classNames(buttonProps.className, settings.props.className, { 'k-disabled': disabled }) })));
            return render ? render.call(undefined, button, { view: view }) : button;
        };
        return ProseMirrorTool;
    }(React.Component));
    registerForLocalization(Tool);
    return Tool;
};
