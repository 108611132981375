var maxHeightIE = 1533915;
/**
 * @hidden
 */
var VirtualScroll = /** @class */ (function () {
    function VirtualScroll() {
        var _this = this;
        this.container = null;
        this.scrollElement = null;
        this.list = null;
        this.containerHeight = 0;
        this.skip = 0;
        this.total = 0;
        this.enabled = false;
        this.pageSize = 0;
        this.itemHeight = 0;
        this.PageChange = null;
        this.prevScrollPos = 0;
        this.listTranslate = 0;
        this.scrollSyncing = false;
        this.scrollerRef = function (element) {
            var vs = _this;
            vs.container = element;
            if (element) {
                element.setAttribute('unselectable', 'on');
                window.setTimeout(vs.calcScrollElementHeight.bind(vs), 0);
            }
        };
        this.calcScrollElementHeight = function () {
            _this.scrollSyncing = true;
            var heightChanged = false;
            _this.itemHeight = _this.list ? _this.list.children[0].offsetHeight : _this.itemHeight;
            _this.containerHeight = Math.min(maxHeightIE, _this.itemHeight * _this.total);
            var newHeight = _this.containerHeight;
            if (_this.scrollElement) {
                heightChanged = _this.scrollElement.style.height !== newHeight + 'px';
                if (heightChanged) {
                    _this.scrollElement.style.height = newHeight + 'px';
                }
            }
            _this.scrollSyncing = false;
            return heightChanged;
        };
        this.scrollHandler = this.scrollHandler.bind(this);
    }
    Object.defineProperty(VirtualScroll.prototype, "translate", {
        get: function () {
            return this.listTranslate;
        },
        enumerable: false,
        configurable: true
    });
    VirtualScroll.prototype.changePage = function (skip, e) {
        var newSkip = Math.min(Math.max(0, skip), this.total - this.pageSize);
        if (newSkip !== this.skip && this.PageChange) {
            this.PageChange({ skip: newSkip, take: this.pageSize }, e);
        }
    };
    VirtualScroll.prototype.translateTo = function (dY) {
        this.listTranslate = dY;
        if (this.list) {
            this.list.style.transform = 'translateY(' + dY + 'px)';
        }
    };
    VirtualScroll.prototype.reset = function () {
        if (this.container) {
            this.calcScrollElementHeight();
            this.container.scrollTop = 0;
            this.translateTo(0);
        }
    };
    VirtualScroll.prototype.scrollToEnd = function () {
        if (this.container && this.list) {
            this.calcScrollElementHeight();
            this.container.scrollTop = this.container.scrollHeight - this.container.offsetHeight;
            this.translateTo(this.container.scrollHeight); //  - this.list.offsetHeight
        }
    };
    VirtualScroll.prototype.localScrollUp = function (e) {
        var height = this.itemHeight;
        var scrollTop = this.container.scrollTop;
        var targetTranslate = this.listTranslate;
        var items;
        var additionalOnTop = scrollTop - targetTranslate;
        if (additionalOnTop > height) {
            return;
        }
        for (items = 0; items < this.skip; items++) {
            if (targetTranslate + height + additionalOnTop <= scrollTop) {
                break;
            }
            targetTranslate -= height;
        }
        targetTranslate = this.validateTranslate(targetTranslate);
        if (this.skip - items <= 0 && targetTranslate >= scrollTop) {
            this.translateTo(0);
            this.changePage(0, e);
            this.container.scrollTop = 0;
            return;
        }
        if (targetTranslate !== this.listTranslate) {
            this.translateTo(targetTranslate);
            this.changePage(this.skip - items, e);
        }
    };
    VirtualScroll.prototype.localScrollDown = function (e) {
        var height = this.itemHeight;
        var scrollTop = this.container.scrollTop;
        var targetTranslate = this.listTranslate;
        var itemsLenght = this.list.children.length;
        var items;
        for (items = 0; items < itemsLenght; items++) {
            if (targetTranslate + height >= scrollTop) {
                break;
            }
            targetTranslate += height;
        }
        targetTranslate = this.validateTranslate(targetTranslate);
        if (items >= itemsLenght && this.skip + items >= this.total) {
            this.translateTo(targetTranslate);
            this.changePage(this.total - 1, e);
        }
        else if (targetTranslate !== this.listTranslate) {
            this.translateTo(targetTranslate);
            this.changePage(this.skip + items, e);
        }
    };
    VirtualScroll.prototype.scrollNonStrict = function (e) {
        var floatItemIndex = this.total * this.prevScrollPos / (this.containerHeight);
        var itemIndex = Math.min(Math.floor(floatItemIndex), this.total - 1);
        var targetTranslate = this.containerHeight * floatItemIndex / this.total;
        targetTranslate = this.validateTranslate(targetTranslate);
        this.translateTo(targetTranslate);
        this.changePage(itemIndex, e);
    };
    VirtualScroll.prototype.scrollHandler = function (e) {
        var scrollTop = this.container ? this.container.scrollTop : 0;
        var prev = this.prevScrollPos;
        this.prevScrollPos = scrollTop;
        if (!this.enabled || !this.list || !this.container || this.scrollSyncing) {
            return;
        }
        if (scrollTop - prev <= 0 && scrollTop > this.listTranslate - this.list.scrollHeight / 10) {
            this.localScrollUp(e);
        }
        else if (scrollTop - prev > 0 && scrollTop < this.listTranslate + this.list.scrollHeight * 2 / 3) {
            this.localScrollDown(e);
        }
        else {
            this.scrollNonStrict(e);
        }
    };
    VirtualScroll.prototype.validateTranslate = function (translate) {
        translate = Math.max(0, translate);
        // translate = Math.min(this.containerHeight - this.list!.offsetHeight, translate);
        translate = Math.min(this.containerHeight, translate);
        return translate;
    };
    return VirtualScroll;
}());
export default VirtualScroll;
