import BasePage from "../base_page";
import * as UIUtils from "../ui_utils";
import React from "react";
import EditablesPageTitleBar from "../widgets/pageTitleBar/editables_page_title_bar";
import NavBar from "../widgets/bars/nav_bar";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import DocumentListGeneric from "./document_list_generic";

class DocumentsListInProjectPage extends BasePage {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {t} = this.props;
    document.title = t("QbDVision Documents");

    this.projectId = UIUtils.parseInt(this.context.getParameterByName("projectId"));

    super.componentDidMount();
  }

  renderPage() {
    const {t} = this.props;

    return (
      <div>
        <EditablesPageTitleBar projectId={this.projectId} />
        <NavBar selected="Documents" projectId={this.projectId} />
        <div className="container">
          <DocumentListGeneric t={t} showFilterBar={false} projectId={this.projectId} subHeaderTooltip={t("A library of all documents at project level.")} />
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(DocumentsListInProjectPage, "documents");
// i18next-extract-mark-ns-stop controlMethod
