"use strict";

import * as go from "gojs";
import { TYPE_HEADER } from "../adapters/diagram_results_adapter";
import { TYPE_CODE } from "../process_explorer_constants";

const HEADER_TYPE_CODES_WITH_LINKS = new Set([
  TYPE_CODE.UNIT_OPERATION,
  TYPE_CODE.MATERIAL,
  TYPE_CODE.PROCESS_COMPONENT,
]);
// i18next-extract-mark-ns-start process_explorer
/**
 * This class creates a custom GoJS tree layout for the process explorer, so that steps can be below UOs instead of the
 * right of them.
 *
 * The idea for how to do this was borrowed heavily from: https://gojs.net/latest/samples/orgChartAssistants.html
 */
export default class ProcessExplorerTreeLayout extends go.TreeLayout {
  /**
   * This method is inherited from go.TreeLayout. We use it to mark the UO and steps edges so they can be drawn
   * differently in the `assignTreeVertexValues` method below.
   *
   * @param coll A Diagram or a Group or a collection of Parts.
   * @return {LayoutNetwork} The network to render.
   */
  makeNetwork(coll) {
    let network = super.makeNetwork(coll);
    // copy the collection of TreeVertexes, because we will modify the network
    let vertexSet = new go.Set(/*go.TreeVertex*/);
    vertexSet.addAll(network.vertexes);
    for (let it = vertexSet.iterator; it.next();) {
      let parent = it.value;
      // if a vertex is a UO
      if (this._getCategory(parent) === TYPE_HEADER && HEADER_TYPE_CODES_WITH_LINKS.has(this._getHeaderTypeCode(parent))) {
        parent._isHeaderWithLinks = true;
      } else if (this._getCategory(parent) === TYPE_CODE.UNIT_OPERATION) {
        // remember the Steps edges and the other child edges
        let stepsEdges = new go.Set(/*go.TreeEdge*/);
        let childEdges = new go.Set(/*go.TreeEdge*/);
        let edgeIterator = parent.destinationEdges;
        while (edgeIterator.next()) {
          let e = edgeIterator.value;
          if (this._getCategory(e.toVertex) === TYPE_CODE.STEP) {
            stepsEdges.add(e);
          } else {
            childEdges.add(e);
          }
        }

        if (stepsEdges.count > 0) {
          parent._isUO = true;

          const [nonStepVertex1, nonStepVertex2] = this.reAddEdgesToParent(network, childEdges, parent, true);
          nonStepVertex1._forNonSteps = true;
          nonStepVertex2._forNonSteps = true;
          const [stepsVertex1] = this.reAddEdgesToParent(network, stepsEdges, parent, false);
          stepsVertex1._forSteps = true;
        }
      }
    }
    return network;
  }

  /**
   * GoJS likes to follow a pattern for all children in a tree, in terms of spacing, where lines come out (ports), etc.
   * This works great except Steps don't follow the pattern that everything else follows (they're tucked under the UO,
   * without a * header). So to create 2 different patterns, we remove the edges from the parent, add a new substitute
   * node on top of the parent for each different pattern and then re-add the edges to the nodes according to which
   * pattern we want them to follow.
   *
   * Confused about vertexes/nodes & edges? https://en.wikipedia.org/wiki/Vertex_(graph_theory)
   *
   * @param network The network the new vertex will be added to
   * @param edges The edges to re-add
   * @param parent The parent to link the next vertex with
   * @param shouldAddTwice True if two substitute vertexes should be added, to keep the alternating style working. False otherwise.
   * @return {*} The new vertex created
   */
  reAddEdgesToParent(network, edges, parent, shouldAddTwice) {
    // Remove steps edges from parent.
    this._removeEdgesFromParent(edges, parent);

    // Re-add them now, but to a new Vertex
    let substituteVertex = network.createVertex();
    let substituteVertexExtra = null;
    network.addVertex(substituteVertex);
    network.linkVertexes(parent, substituteVertex, null);

    if (shouldAddTwice) {
      // Create 2 substitute vertexes to be the new parent of all steps (to not mess with the alternating stuff)
      substituteVertex.layerSpacingParentOverlap = 1;
      substituteVertexExtra = substituteVertex;
      substituteVertex = network.createVertex();
      network.addVertex(substituteVertex);
      network.linkVertexes(substituteVertexExtra, substituteVertex, null);
    }
    // Re-parent steps children to the new substitute vertex
    const edgeIterator = edges.iterator;
    while (edgeIterator.next()) {
      let edge = edgeIterator.value;
      edge.fromVertex = substituteVertex;
      substituteVertex.addDestinationEdge(edge);
    }

    // finally can add substitute vertex as the final odd child,
    // to be positioned at the end of the PARENT's immediate subtree.
    return [substituteVertex, substituteVertexExtra];
  }

  assignTreeVertexValues(v) {
    if (v._isHeaderWithLinks) {
      // Give this header more spacing because it has lines under it.
      v.layerSpacing = 30;
    } else if (v._isUO) {
      v.layerSpacing = 0;
      v.layerSpacingParentOverlap = 1.0;
    } else if (v._forSteps) {
      const parent = v.parent.parent;
      // this is the substitute parent for the steps
      v.width = parent.width;
      v.height = this._getCategory(parent) === "UO" ? parent.height : 0;
      v.portSpot = this.diagram.layout.alternatePortSpot;
      v.layerSpacing = 30;
      v.layerSpacingParentOverlap = 1.0;
    } else if (v._forNonSteps) {
      const parent = v.parent.parent;
      v.width = parent.width;
      v.height = this._getCategory(parent) === "UO" ? parent.height : 0;
      v.nodeIndent = 0;
    }
  }

  _removeEdgesFromParent(edges, parent) {
    let eit = edges.iterator;
    while (eit.next()) {
      parent.deleteDestinationEdge(eit.value);
    }
  }

  /**
   * Finds the category (i.e. "PR", "UO", "MT", etc.) that was set on the node in DiagramResultsAdapter.
   * @param someVertex The vertex to analyze
   * @return {string} The category set on the vertex, or null if it wasn't set or this isn't the right type of object.
   */
  _getCategory(someVertex) {
    const data = this._getData(someVertex);
    return data && data.category;
  }

  /**
   * A helper function to pulls out the data set on a node in DiagramResultsAdapter.
   * @param someVertex The vertex to analyze
   * @return {*} The object containing the data set.
   */
  _getData(someVertex) {
    return someVertex && someVertex.node && someVertex.node.data;
  }

  /**
   * Returns the full name (i.e. "Materials" on a header, or "UO-23 Mixing" on a record) that was set on the node in
   * DiagramResultsAdapter.
   * @param someVertex The vertex to analyze
   * @return {string} The fullName set on the vertex, or null if it wasn't set or this isn't the right type of object.
   */
  _getFullName(someVertex) {
    const data = this._getData(someVertex);
    return data && data.fullName;
  }

  /**
   * Returns the Key (i.e. "PR-1-UOs" on a header, or "UO-23" on a record) that was set on the node in
   * DiagramResultsAdapter.
   * @param someVertex The vertex to analyze
   * @return {string} The key set on the vertex, or null if it wasn't set or this isn't the right type of object.
   */
  _getKey(someVertex) {
    const data = this._getData(someVertex);
    return data && data.key;
  }

  /**
   * Returns the Header Type Code (e.x. "UO", "MT", "PRC") that was set on the node in
   * DiagramResultsAdapter.
   * @param someVertex The vertex to analyze
   * @return {string} The header type code set on the vertex, or null if it wasn't set or this isn't the right type of object.
   */
  _getHeaderTypeCode(someVertex) {
    const data = this._getData(someVertex);
    return data && data.headerTypeCode;
  }
}

// i18next-extract-mark-ns-stop process_explorer
