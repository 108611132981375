"use strict";

import React from "react";
import BaseLinkedEntitiesAttribute from "../../../editor/attributes/base_linked_entities_attribute";
import { TEXT_DIFF_METHOD_ENUM } from "../../../helpers/constants/constants";
import * as UIUtils from "../../../ui_utils";
import { FIELD_INPUT_TYPE, FIELD_REQUIRED_FOR } from "../../../editor/widgets/widget_field";
import { getAcceptanceCriteriaValidationProps } from "../../../../server/common/editables/common_editables";
import { DEFECTIVE_MEASUREMENT_UNIT, getMeasureDefaultUnit } from "../../../helpers/attribute_helper";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";

/**
 * @type {IWidgetField[]}
 */
export const MTL_SPECS_WIDGET_FIELDS = (
  [
    {
      displayName: "Specification",
      fieldName: "name",
      diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
      requiredFor: FIELD_REQUIRED_FOR.save,
      forceUpdate: false,
      belongsToMasterRow: true,
      order: 1,
      defaultValue: "",
      inputType: FIELD_INPUT_TYPE.text,
      newSelectionPrefix: "Add new specification: ",
      width: 50,
      maxLength: 255,
    },
    {
      fieldName: "measure",
      displayName: "Measure",
      diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
      requiredFor: FIELD_REQUIRED_FOR.save,
      forceUpdate: false,
      defaultValue: "Range",
      placeholder: "",
      belongsToMasterRow: true,
      order: 2,
      inputType: FIELD_INPUT_TYPE.select,
      getOptions: "getMeasureOptions",
      width: 50,
      maxLength: 255,
    },
    {
      fieldName: "lowerLimit",
      diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
      displayName: "LSL",
      requiredFor: FIELD_REQUIRED_FOR.none,
      forceUpdate: false,
      belongsToMasterRow: true,
      inputType: FIELD_INPUT_TYPE.number,
      allowDecimalNumbers: true,
      order: 3,
      width: 1,
      additionalPropsGetter: "getAdditionalPropsForField",
      disabled: "isWidgetFieldDisabled",
      maxLength: 255,
    },
    {
      fieldName: "target",
      diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
      displayName: "Target",
      requiredFor: FIELD_REQUIRED_FOR.none,
      forceUpdate: false,
      belongsToMasterRow: true,
      order: 4,
      inputType: FIELD_INPUT_TYPE.text,
      width: 1,
      additionalPropsGetter: "getAdditionalPropsForField",
      disabled: "isWidgetFieldDisabled",
      maxLength: 255,
    },
    {
      fieldName: "upperLimit",
      diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
      displayName: "USL",
      requiredFor: FIELD_REQUIRED_FOR.none,
      forceUpdate: false,
      belongsToMasterRow: true,
      order: 5,
      inputType: FIELD_INPUT_TYPE.number,
      allowDecimalNumbers: true,
      width: 1,
      additionalPropsGetter: "getAdditionalPropsForField",
      disabled: "isWidgetFieldDisabled",
      maxLength: 255,
    },
    {
      fieldName: "measurementUnits",
      diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
      displayName: "Units",
      requiredFor: FIELD_REQUIRED_FOR.none,
      forceUpdate: false,
      belongsToMasterRow: true,
      order: 6,
      inputType: FIELD_INPUT_TYPE.text,
      width: 1,
      formatValue: "formatMeasurementUnitsValue",
      disabled: "isWidgetFieldDisabled",
      maxLength: 255,
    },
    {
      fieldName: "reportLink",
      diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
      displayName: "Analytics",
      requiredFor: FIELD_REQUIRED_FOR.none,
      icon: faChartLine,
      order: 7,
      inputType: FIELD_INPUT_TYPE.linkIcon,
      width: 1,
    },
  ]
);

export class MaterialSpecificationsAttribute extends BaseLinkedEntitiesAttribute {

  constructor(props) {
    super(props, MTL_SPECS_WIDGET_FIELDS.filter(field => (!props.isViewMode && field.fieldName !== "reportLink") || props.isViewMode));
    this.handleAddToList = this.handleAddToList.bind(this);
    this.editedRows = props.editedRows && Array.isArray(props.editedRows) ? props.editedRows : [];
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      return (
        this.editedRows.length > 0
        || this.propsAreDifferent(nextProps, "specifications")
      );
    }
    return true;
  }

  getMeasureOptions() {
    return Object.values(UIUtils.MEASUREMENT_TYPES).map(measurementType => {
      // noinspection JSValidateTypes
      return <option key={measurementType}
                     value={measurementType}
      >
        {measurementType}
      </option>;
    });
  }

  handleChangeField(rowData, field, e) {
    super.handleChangeField(rowData, field, e);
    if (field.getFieldPath() === "measure" && this._isMounted) {
      this.forceUpdate();
    }
  }

  isWidgetFieldDisabled(rowData, field) {

    let editedRow = this.getEditedRowByUUID(rowData.uuid);
    const {measure} = editedRow;
    const previousMeasure = rowData.measure;

    let disabledFields = [];
    switch (measure) {
      case UIUtils.MEASUREMENT_TYPES.LOWER_LIMIT:
        disabledFields = ["upperLimit"];
        break;
      case UIUtils.MEASUREMENT_TYPES.UPPER_LIMIT:
        disabledFields = ["lowerLimit"];
        break;
      case UIUtils.MEASUREMENT_TYPES.DEFECTS:
        disabledFields = ["lowerLimit", "measurementUnits"];
        break;
      case UIUtils.MEASUREMENT_TYPES.CONFORMS:
        disabledFields = ["upperLimit", "lowerLimit"];
        break;
    }

    if (editedRow?.uuid && measure === UIUtils.MEASUREMENT_TYPES.RANGE && previousMeasure !== measure) {
      if (field.getFieldPath() === "lowerLimit"
        || field.getFieldPath() === "target"
        || field.getFieldPath() === "upperLimit"
        || field.getFieldPath() === "measurementUnits") {
        field.setValue(editedRow, "");
      }
    } else if (editedRow?.uuid && measure === UIUtils.MEASUREMENT_TYPES.DEFECTS && previousMeasure !== measure) {
      if (field.getFieldPath() === "lowerLimit"
        || field.getFieldPath() === "target"
        || field.getFieldPath() === "upperLimit") {
        field.setValue(editedRow, "");
      } else if (field.getFieldPath() === "measurementUnits") {
        field.setValue(editedRow, getMeasureDefaultUnit(measure));
      }
    }

    return disabledFields.includes(field.getFieldPath());
  }

  formatMeasurementUnitsValue(field, rowData) {

    let editedRow = this.getEditedRowByUUID(rowData.uuid);
    let value = field.getValue(rowData);

    if (editedRow) {
      const {measure, measurementUnits} = editedRow;
      if (field.getFieldPath() === "measurementUnits") {
        if (measure === UIUtils.MEASUREMENT_TYPES.DEFECTS) {
          let unit = getMeasureDefaultUnit(measure);
          value = unit;
          field.setValue(editedRow, unit);
        } else if (measurementUnits === DEFECTIVE_MEASUREMENT_UNIT) {
          value = "";
          field.setValue(editedRow, "");
        }
      }
      return value;
    } else {
      return field.getValue(rowData);
    }

  }

  /**
   * Adds a new row to the rows collection managed by this attribute and assigns the default value for each field
   * as specified in the WIDGET_FIELDS.
   * @param editedRow Optional row provided by a child class, where it is already initialized..
   */
  handleAddToList(editedRow) {
    editedRow = editedRow ?? {
      uuid: UIUtils.generateUUID(),
      measure: "Range",
      specification: "",
      lowerLimit: null,
      upperLimit: null,
      target: null,
      measurementUnits: null,
    };

    super.handleAddToList(editedRow);
  }

  /**
   * Returns an object with the validation-related props for a given material specification field.
   * @param field {WidgetField} The field that is being validated.
   * @param rowData {*} The row data that contains the information about that material specification range.
   */
  getAdditionalPropsForField(field, rowData) {
    const fieldName = field.getFieldPath();
    let editedRow = this.getEditedRowByUUID(rowData.uuid);
    return getAcceptanceCriteriaValidationProps(editedRow.measure, fieldName, rowData);
  }

}
