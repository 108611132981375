"use strict";

import React from "react";
import { WIDGET_FIELDS } from "../constants/rmp_criticality_scale_attribute_constants";
import RMPRiskScaleAttribute, { RMP_RISK_ATTRIBUTE_TYPE } from "./rmp_risk_scale_attribute";
import { RISK_ATTRIBUTES_TABS } from "../constants/rmp_constants";

/**
 * This is class is responsible for handling Risk Management Plan Criticality records.
 */
export default class RMPCriticalitiesAttribute extends RMPRiskScaleAttribute {
  constructor(props) {
    super(props, [...WIDGET_FIELDS]);

    this.errorMessages.editedRowPending = "At least one criticality row is in edit state. Please save or cancel all criticality rows first.";
    this.type = RMP_RISK_ATTRIBUTE_TYPE.CRITICALITY;
    this.tabContainerType = RISK_ATTRIBUTES_TABS.CRITICALITY_SCALE;
    this.sourceParameter1 = "Impact";
    this.sourceParameter2 = "Uncertainty";
    this.minSourceParameter1 = "minImpact";
    this.maxSourceParameter1 = "maxImpact";
    this.minSourceParameter2 = "minUncertainty";
    this.maxSourceParameter2 = "maxUncertainty";

    this.adjustFieldDisplayName(props);
  }

  adjustFieldDisplayName(props) {
    if (props.configureByType) {
      if (props.modelName === "PA") {
        this.widgetFields.find(field => field.fieldName === "critical").displayName = props.rmp.useRulesBasedCriticality ? "Key" : "Critical";
      }

      if (props.modelName === "PP" || props.modelName === "MA") {
        this.widgetFields.find(field => field.fieldName === "critical").displayName = props.rmp.useRulesBasedCriticality ? "Critical / Key" : "Critical";
      }
    }
  }

  validate(formValidationMode) {
    if (this.props.useRulesBasedCriticality && !this.props.keyLabel) {
      this.setError(`Key Label needs to be specified.`);
      this.forceUpdateSafely();
      return false;
    }

    return super.validate(formValidationMode);
  }

  reloadDataTable(rowData) {
    if (this.refTable) {
      const table = $(this.refTable.current).DataTable();
      this.adjustFieldDisplayName(this.props);
      const field = this.widgetFields.find(field => field.fieldName === "critical");
      $(table.column(3).header()).html(field.displayName);
    }

    super.reloadDataTable(rowData);
  }

}
