"use strict";

import { exportAs, getFileType } from "../../reports/canned_reports/canned_report_helper";
import { REPORT_TYPES_ENUM } from "../../reports/constants/report_constants";
import moment from "moment";
import * as UIUtils from "../../ui_utils";
import ConfigurableTablesExportColumnGenerator from "./configurable_tables_export_column_generator";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import { FIELD_PROPS, SECTION_LABELS } from "../fieldsConfig/constants/configurable_tables_field_props_config";
import { EXPORT_WORD_CHARS_LIMIT } from "../tables/configurable_tables_constants";
import { generateDocumentsValue, generateRiskValue } from "./configurable_tables_export_value_generator";
import { RISK_TYPE_ENUM } from "../../helpers/constants/constants";
import {
  applyColumnOrder,
  getColumnPreferences,
  getPreferencesStorageKey,
} from "../tables/hooks/tables_column_preferences_helper";
import * as configurable_tables_export from "./files/configurable_tables_report.mrt";
import { CommonUtils } from "../../../server/common/generic/common_utils";


const Logger = Log.group(LOG_GROUP.ConfigurableTables, "ConfigurableTablesExport");

export default class ConfigurableTablesExport {
  constructor(props) {
    this.projectName = props.projectName;
    this.selectedModelType = props.selectedModelType;
    this.records = props.records;
    this.modelsConfig = props.modelsConfig;
    this.visibleTableColumns = props.visibleTableColumns;
    this.unitOperationName = props.unitOperationName;
    this.feature = props.feature;
  }

  get subTitle() {
    return null;
  }

  get columnGenerator() {
    return ConfigurableTablesExportColumnGenerator;
  }

  exportReport() {
    const {
      records,
      selectedModelType,
      visibleTableColumns,
    } = this;

    UIUtils.showLoadingImage("Exporting Report");

    const config = this.modelsConfig[selectedModelType].fieldsConfig;
    const visibleColumns = visibleTableColumns?.filter(column => column.checked) ?? config.fields;
    const {title,} = config;
    const exportOutput = {
      title,
      subTitle: this.subTitle,
      instances: [],
      riskLinks: [],
      documents: [],
      acceptanceCriterias: []
    };
    this.preprocessRecordsForExport(records, exportOutput, visibleColumns);
    this.exportToExcel(exportOutput);
  }

  preprocessRecordsForExport(records, exportOutput, visibleColumns) {
    for (const record of records) {
      exportOutput.instances = exportOutput.instances.concat(this.getProcessedRecords(record, visibleColumns));
      if (record.details) {
        for (const child of record.details) {
          exportOutput.instances = exportOutput.instances.concat(this.getProcessedRecords(child, visibleColumns));
        }
      }

      if (visibleColumns.find(visibleColumn => visibleColumn.section === SECTION_LABELS.RISK_CONTROL)) {
        exportOutput.riskLinks = exportOutput.riskLinks.concat(this.getRiskDetails(record));
      }

      exportOutput.documents = exportOutput.documents.concat(this.getDocuments(record, visibleColumns));

      if (visibleColumns.find(visibleColumn => visibleColumn.section === SECTION_LABELS.ACCEPTANCE_CRITERIA)) {
        exportOutput.acceptanceCriterias = exportOutput.acceptanceCriterias.concat(this.getAcceptanceCriterias(record));
      }
    }
    exportOutput.riskLinks.sort(UIUtils.sortBy("parentId", {name: "criticality", reverse: true}));
    exportOutput.documents.sort(UIUtils.sortBy("parentId", {name: "sectionLabel", reverse: false}));
    exportOutput.acceptanceCriterias.sort(UIUtils.sortBy("parentId", {name: "isDefault", reverse: false}));
  }

  getAcceptanceCriterias(record) {
    if (!record?.Requirement?.AcceptanceCriteriaRanges) {
      return [];
    }

    const acceptanceCriterias = [];
    record.Requirement.AcceptanceCriteriaRanges.forEach(criteria => {
      acceptanceCriterias.push({
        parentId: record.id,
        parentName: record.name,
        parentTypeCode: record.typeCode,
        dataSpace: record.dataSpace,
        measure: record.measure,
        status: criteria.isDefault ? "Primary reporting criteria" : "",
        ...criteria
      });
    });

    return acceptanceCriterias;
  }

  getDocuments(record, visibleColumns) {
    let documents = [];

    const sections = [{
      prop: FIELD_PROPS.LINKS,
      section: SECTION_LABELS.ABOUT
    }, {
      prop: FIELD_PROPS.CRITICALITY_ASSESSMENT_LINKS,
      section: SECTION_LABELS.CRITICALITY
    }, {
      prop: FIELD_PROPS.ACCEPTANCE_CRITERIA_LINKS,
      section: SECTION_LABELS.ACCEPTANCE_CRITERIA
    }, {
      prop: FIELD_PROPS.RISK_CONTROL_LINKS,
      section: SECTION_LABELS.RISK_CONTROL
    }, {
      prop: FIELD_PROPS.REFERENCES,
      section: SECTION_LABELS.REFERENCES
    }];

    sections.forEach(section => {
      if (visibleColumns.find(visibleColumn => visibleColumn.id === (section.relatedProp ? section.relatedProp : section.prop))) {
        documents = documents.concat(this.getDocumentFromSection(record, section.prop, section.section));
      }
    });

    return documents;
  }

  getDocumentFromSection(record, sectionName, sectionLabel) {
    if (!record[sectionName]) {
      return [];
    }

    let links = [];
    if (Array.isArray(record[sectionName])) {
      links = record[sectionName];
    } else {
      try {
        links = JSON.parse(record[sectionName]);
      } catch (err) {
        return [];
      }
    }


    return links.map((link) => {
      return {
        parentId: record.id,
        parentName: record.name,
        parentTypeCode: record.typeCode,
        name: link.name || link.description,
        link: link.linkType === "Attachment" ? link.fileName : link.link,
        appliesTo: (Array.isArray(link.appliesTo) ? link.appliesTo :
          link.appliesTo.toString()
            .split(",")
            .map(reference => reference.trim())
            .filter(reference => reference.length > 0))
          .map(reference => !UIUtils.startsWithCapital(reference) ? UIUtils.convertCamelCaseToSpacedOutWords(reference) : reference)
          .join("; "),
        section: sectionLabel,
        description: link.description
      };
    });
  }

  getRiskDetails(record) {
    let riskLinks = [];
    if (record?.riskLinksDetails?.length > 0) {
      riskLinks = riskLinks.concat(record.riskLinksDetails.map(link => {
        const impact = generateRiskValue(FIELD_PROPS.IMPACT, RISK_TYPE_ENUM.IMPACT, link);
        const uncertainty = generateRiskValue(FIELD_PROPS.UNCERTAINTY, RISK_TYPE_ENUM.UNCERTAINTY, link);
        const criticality = generateRiskValue(FIELD_PROPS.CRITICALITY, RISK_TYPE_ENUM.CRITICALITY, link);
        return {
          parentId: record.id,
          parentName: record.name,
          parentTypeCode: record.typeCode,
          id: `${link.typeCode}-${link.id}`,
          name: link.name,
          effect: link.effect,
          impactValue: impact?.value,
          impactColor: impact?.backgroundColor,
          uncertaintyValue: uncertainty?.value,
          uncertaintyColor: uncertainty?.backgroundColor,
          criticalityValue: criticality?.value,
          criticalityColor: criticality?.backgroundColor,
          criticality: criticality?.riskValue,
          justification: link.justification,
          links: generateDocumentsValue(link, "links"),
          label: UIUtils.getRecordLabelForDisplay(link.typeCode, link.id, link.name),
        };
      }));
    }
    return riskLinks;
  }

  get exportTemplateName() {
    return configurable_tables_export;
  }

  exportToExcel(exportOutput) {
    const {
      selectedModelType,
      projectName,
      unitOperationName,
    } = this;

    let report = new window.Stimulsoft.Report.StiReport();
    report.load(this.exportTemplateName);

    Logger.verbose("Export output:", JSON.stringify(exportOutput));

    let dataSet = new window.Stimulsoft.System.Data.DataSet("DataSet");
    dataSet.readJson(JSON.stringify(exportOutput));
    report.dictionary.databases.clear();
    report.regData(dataSet.dataSetName, "", dataSet);

    report.renderAsync(() => {
      exportAs(getFileType("xlsx"), "." + "xlsx", report,
        REPORT_TYPES_ENUM.ConfigurableTables, selectedModelType, false,
        this.getFileName(projectName, selectedModelType, unitOperationName));
    });
  }

  getFileName() {
    const {
      selectedModelType,
      projectName,
      unitOperationName,
      feature,
    } = this;

    let exportFileName = `${feature}_${projectName.substring(0, EXPORT_WORD_CHARS_LIMIT)}`;
    exportFileName += `_${selectedModelType}`;
    exportFileName += unitOperationName ? `_${unitOperationName.substring(0, EXPORT_WORD_CHARS_LIMIT)}` : "";
    exportFileName += `_${moment().format(UIUtils.DATE_FORMAT_FOR_STORAGE)}`;
    return exportFileName;
  }

  getProcessedRecords(record, visibleColumns) {
    const exportColumnGenerator = new this.columnGenerator({record});
    const instances = [];
    const variable = exportColumnGenerator.getIdColumnLabel();

    for (const column of visibleColumns.filter(column => column.prop !== FIELD_PROPS.ID)) {
      exportColumnGenerator.column = column;
      const value = exportColumnGenerator.getColumnValue();
      instances.push({
        columnName: exportColumnGenerator.getColumnTitle(),
        field: column.prop,
        variable,
        ...value,
      });
    }

    const storageKey = getPreferencesStorageKey(this.selectedModelType);
    const preferences = getColumnPreferences(storageKey);
    return applyColumnOrder(instances, preferences).sort(CommonUtils.sortBy("orderIndex"));
  }
}
