// @ts-ignore
import {nameToEditableMap as editableMap} from "../../helpers/editables_map";
import * as UIUtils from "../../ui_utils";
import {EXPERIMENTS} from "../../helpers/constants/constants";
import {Project} from "../../common/models/project";
import {isLinkAttribute} from "../utils/parser/elementParser/document_link_parser";

/**
 * This file will override the nameToEditableMap to include some hardcode
 * models and attributes of a model like AcceptanceCriteriaRange and Criticality
 */
type ModelAttribute = {
  name: string;
  displayName?: string;
  singleContentName?: string;
};

export type FieldItem = {
  name: string;
  displayName?: string;
  attributesMap?: Map<string, FieldItem>;
  singleContentName?: string;
  order?: number;
}
const nameToEditableMap: Map<string, FieldItem> = new Map(editableMap);
const FIELDS: Map<string, FieldItem> = new Map();

export const FIELD_KEY = {
  RecordFields: "RecordFields",
  DocBuilderFields: "DocBuilderFields",
};

const MODAL_TO_FULL_NAME = {
  FQA: "Final Quality Attribute",
  FPA: "Final Performance Attribute",
  IQA: "Intermediate Quality Attribute",
  IPA: "Intermediate Performance Attribute",
  TPPSection: "Target Product Profile",
};

// The order of modals in the tree will be the order in this array
// If you add a new modal, you should add them here
const MODALS_ORDER = [
  "Project",
  "TPPSection",
  "GeneralAttribute",
  "FQA",
  "FPA",
  "Process",
  "UnitOperation",
  "Step",
  "Material",
  "MaterialAttribute",
  "ProcessComponent",
  "ProcessParameter",
  "IQA",
  "IPA",
  "ControlMethod",
  "Document",
  "Supplier",
];

// We want to exclude some attributes and set display name for them
const MODEL_TO_ATTRIBUTES = {
  Common: {
    fieldsToExclude: [
      "clonedFromModel",
      "clonedFromVersionId",
      "rmpVersionId",
      "isTechTransferOnlyChange",
      "riskLinks"
    ],
    id: "ID",
    ccp: "CCP",
    referencesLinks: "Reference and Standards: Supporting Documents",
    acceptanceCriteriaLinks: "Acceptance Criteria: Supporting Documents",
    currentState: "Record Status",
    detailedRiskLinks: "Criticality Assessment: Supporting Documents",
    riskControlLinks: "Risk Control: Supporting Documents",
    techTransferLinks: "Tech Transfer: Supporting Documents",
    qualificationLinks: "Qualification: Supporting Documents",
    links: "Supporting Documents",
    auditLinks: "Audit: Supporting Documents",
    riskLinks: "Risk: Supporting Document",
    gmp: "GMP",
    capabilityRisk: "Capability Risk/Occurrence",
    lowerLimit: "LSL",
    upperLimit: "USL",
    techTransferAssessmentStatus: "Tech Transfer Assessment Status",
    techTransferComment: "Tech Transfer Comment or Description of Change",
    techTransferControlStrategy: "Tech Transfer Detectability / Current Control Strategy",
    techTransferDetectability: "Tech Transfer Detectability Risk",
    techTransferImpact: "Tech Transfer Impact/Severity",
    techTransferImpactDescription: "Tech Transfer Impact/Severity Description",
    techTransferRiskMitigationStrategy: "Tech Transfer Risk Mitigation Strategy and Limitations",
    techTransferUncertainty: "Tech Transfer Uncertainty/Likelihood",
    techTransferUncertaintyJustification: "Tech Transfer Uncertainty Justification",
    certificateOfAnalysis: "COA/COC",
    partNumber: "Supplier Part Number",
  },
  Document: {
    fieldsToExclude: [
      "isBulkAdd",
      "isQbdSrcDoc",
      "trainingRequired",
      "documentContentVersionId",
      "originalDocumentLinks",
      "uploadDocumentLinks",
      "size",
    ],
    customID: "Custom ID",
    dhfSection: "DHF Section",
    dmrSection: "DMR Section",
    ectdSection: "eCTD Section",
    k510Section: "510(k) Section",
    pmaSection: "PMA Section",
    sme: "SME",
    author: "Author(s)"
  },
  FQA: {
    obligatoryCQA: "Obligatory CQA",
  },
  Material: {
    fieldsToExclude: [
      "fromLibraryModel",
      "fromLibraryStatus",
      "fromLibraryVersionId",
    ],
    chemicalNameCAS: "Chemical Name (CAS)",
    chemicalNameIUPAC: "Chemical Name (IUPAC)",
    innUsan: "INN/USAN",
    links: "Material: Supporting Documents",
    propertiesLinks: "Properties: Supporting Documents",
    formulationQuantityLinks: "Formulation Quantity: Supporting Documents",
    regulatoryLinks: "Regulatory: Supporting Documents",
    authorizationLetter: "Letter of Authorization",
    casRegistryNumber: "CAS Registry Number",
    descriptiveUnitAbsolute: "Descriptive Unit (Absolute)",
    materialQualified: "Material/Part Qualified",
    quantityAbsolute: "Quantity (Absolute)",
    quantityRelative: "Quantity (Relative)",
    quantityPerDose: "Quantity per Dose (Absolute)"
  },
  ProcessComponent: {
    componentQualificationLinks:
      "Component Qualification: Supporting Documents",
    componentRiskLinks: "Component Risk: Supporting Documents",
    links: "Process Component: Supporting Documents",
    unitId: "Unit ID",
    unitQualificationLinks: "Unit Qualification: Supporting Documents",
  },
  ProcessParameter: {
    lowerOperatingLimit: "LOL",
    upperOperatingLimit: "UOL",
  },
  Project: {
    fieldsToExclude: ["isDemo"],
    cmcLeadId: "CMC Lead",
    cmcPhase: "CMC Phase",
    customProjectId: "Custom Project ID",
    deviceLeadId: "Device/Engineering Lead",
    links: "Project: Supporting Documents",
    manufacturingLeadId: "Manufacturing Lead",
    projectManagerId: "Project Manager",
    projectSponsorId: "Project Sponsor",
    purposeLinks: "Purpose: Supporting Documents",
    qaLeadId: "QA Lead",
    qcLeadId: "QC Lead",
    regulatoryLeadId: "Regulatory Lead",
    qualityByDesignPhase: "Quality-By-Design Phase",
  },
};

const AC_RANGE_FIELDS = [
  {
    name: "group",
  },
  {
    name: "label",
  },
  {
    name: "lowerLimit",
    displayName: "LSL",
  },
  {
    name: "upperLimit",
    displayName: "USL",
  },
  {
    name: "target",
  },
  {
    name: "measurementUnits",
    displayName: "Measurement Units",
  },
  {
    name: "measure",
  },
  {
    name: "justification",
  },
];


export function getFields(project: Project) {
  modifyOriginalNameToEditable(project);

  FIELDS.set(FIELD_KEY.RecordFields, {
    name: FIELD_KEY.RecordFields,
    attributesMap: new Map(),
  });

  const classNames = Array.from(nameToEditableMap.keys());
  for (const clazzName of classNames) {
    const clazz = nameToEditableMap.get(clazzName);
    FIELDS.get(FIELD_KEY.RecordFields).attributesMap.set(clazzName, clazz);
  }

  addModel(FIELDS, FIELD_KEY.DocBuilderFields, [
    "companyName",
    "currentUser",
    "informationDate",
    "pageNumber",
    "pageNumberExcludingCoverPage",
    "softwareVersion",
    "totalPages",
  ]);

  return FIELDS;
}

function modifyOriginalNameToEditable(project: Project) {
  const COMMON_EDITABLES = [
    "ProcessParameter",
    "MaterialAttribute",
    "IQA",
    "IPA",
    "FQA",
    "FPA",
  ];
  const classNames = Array.from(nameToEditableMap.keys());
  for (const clazzName of classNames) {
    const clazz = nameToEditableMap.get(clazzName);
    if (MODAL_TO_FULL_NAME[clazzName]) {
      clazz.displayName = MODAL_TO_FULL_NAME[clazzName];
    }
    clazz.order = MODALS_ORDER.indexOf(clazzName);

    if (COMMON_EDITABLES.includes(clazzName)) {
      addAttributesForModel(nameToEditableMap, clazzName, ["detailedRiskLinks"]);
    }

    const linkAttributes = [];
    for (const [
      attributeKey,
      attributeValue,
    ] of clazz.attributesMap.entries()) {
      if (
        MODEL_TO_ATTRIBUTES.Common.fieldsToExclude.includes(attributeKey) ||
        MODEL_TO_ATTRIBUTES[clazzName]?.fieldsToExclude?.includes(attributeKey)
      ) {
        clazz.attributesMap.delete(attributeKey);
      }

      if (MODEL_TO_ATTRIBUTES.Common[attributeKey]) {
        attributeValue.displayName = MODEL_TO_ATTRIBUTES.Common[attributeKey];
      }

      if (
        MODEL_TO_ATTRIBUTES[clazzName] &&
        MODEL_TO_ATTRIBUTES[clazzName][attributeKey]
      ) {
        attributeValue.displayName =
          MODEL_TO_ATTRIBUTES[clazzName][attributeKey];
      }

      if (isLinkAttribute(attributeKey)) {
        linkAttributes.push(attributeKey);
      }
    }

    if (MODEL_TO_ATTRIBUTES[clazzName] && MODEL_TO_ATTRIBUTES[clazzName].models) {
      for (const model of MODEL_TO_ATTRIBUTES[clazzName].models) {
        addModel(clazz.attributesMap, UIUtils.capitalize(model.name), model.attributes);
      }
    }

    if (
      clazzName === "FQA" &&
      !UIUtils.isExperimentEnabled(EXPERIMENTS.ObligatoryCQA)
    ) {
      clazz.attributesMap.delete("obligatoryCQA");
    }

    for (const linkAttribute of linkAttributes) {
      const linkAttributeKey = UIUtils.convertToCamelCaseId(linkAttribute);
      if (clazz.attributesMap.has(linkAttributeKey)) {
        const attribute = clazz.attributesMap.get(linkAttributeKey);
        clazz.attributesMap.delete(linkAttributeKey);
        addModel(
          clazz.attributesMap,
          UIUtils.capitalize(linkAttribute),
          [
            {
              name: "linkOrAttachment",
              displayName: "Link or Attachment",
            },
            {
              name: "name",
            },
            {
              name: "description",
            },
            {
              name: "appliesTo",
            },
          ],
          {
            displayName:
              attribute.displayName ||
              UIUtils.convertCamelCaseToSpacedOutWords(linkAttribute),
          }
        );
      }
    }

    if (["UnitOperation", "Material", "ProcessComponent", "ControlMethod", "Process"].includes(clazzName)) {
      addAttributesForModel(nameToEditableMap, clazzName, ["supplier"]);
    }

    if (COMMON_EDITABLES.includes(clazzName)) {
      removeAttributeFromModel(clazz.attributesMap, AC_RANGE_FIELDS);
      addModel(
        clazz.attributesMap,
        "AcceptanceCriteriaRange",
        AC_RANGE_FIELDS,
        {displayName: "Acceptance Criteria Range"}
      );
      // When you added a new field in CALCULATED_ATTRIBUTES, please add it in
      // CALCULATED_RISK_ATTRIBUTES in src/main/server/common/generic/common_constants.js
      const CALCULATED_ATTRIBUTES = [
        {name: "scoreLabelColor"},
        {name: "scoreLabel"},
        {name: "riskLabelColor"},
        {name: "riskLabel"},
        {name: "maxCriticalityRaw", displayName: "Criticality (Raw)"},
        {name: "maxCriticalityPercentage", displayName: "Criticality (%)"},
        {name: "processRiskRaw", displayName: "Process Risk (Raw)"},
        {name: "processRiskPercentage", displayName: "Process Risk (%)"},
        {name: "rpnRaw", displayName: "RPN (Raw)"},
        {name: "rpnPercentage", displayName: "RPN (%)"},
        {name: "techTransferRPNRaw", displayName: "Tech Transfer RPN (Raw)"},
        {name: "techTransferRPNPercentage", displayName: "Tech Transfer RPN (%)"},
      ];
      for (const calculatedAttribute of CALCULATED_ATTRIBUTES) {
        addAttributeToModel(nameToEditableMap, clazzName, calculatedAttribute);
      }

      if (["FQA", "FPA"].includes(clazzName)) {
        addAttributeToModel(nameToEditableMap, clazzName, {
          name: "criticalityRaw",
          displayName: "Criticality (Raw)",
        });
        addAttributeToModel(nameToEditableMap, clazzName, {
          name: "criticalityPercentage",
          displayName: "Criticality (%)",
        });
        addModel(
          clazz.attributesMap,
          "Criticality",
          [
            {
              name: "generalAttributeName",
            },
            {
              name: "impact",
              displayName: "Impact/Severity",
            },
            {
              name: "uncertainty",
              displayName: "Uncertainty/Likelihood",
            },
            {
              name: "criticality",
            },
            {
              name: "justification",
            },
          ],
          {displayName: "Criticality Assessment: Risk Links"}
        );
      } else {
        addModel(
          clazz.attributesMap,
          "Criticality",
          [
            {
              name: "intermediateOrFinalAttributes",
              displayName: "Intermediate or Final Attributes",
            },
            {
              name: "impact",
              displayName: "Impact/Severity",
            },
            {
              name: "uncertainty",
              displayName: "Uncertainty/Likelihood",
            },
            {
              name: "criticality",
            },
            {
              name: "justification",
            },
            {
              name: "riskLabelColor",
              displayName: "Risk Link Label Color",
            },
            {
              name: "riskLabel",
              displayName: "Risk Link Label",
            },
            {
              name: "scoreLabelColor",
              displayName: "Risk Link Score Label Color",
            },
            {
              name: "scoreLabel",
              displayName: "Risk Link Score Label",
            },
            {
              name: "effect",
              displayName: "Effect on Attribute",
            },
          ],
          {displayName: "Criticality Assessment: Risk Links"}
        );
      }

      addAttributesForModel(nameToEditableMap, clazzName, ["controlMethod"]);
    }

    // This method needs to be called last
    modifyAttributesOnProjectRiskType(project, clazzName, clazz);
  }
}

function addModel(map: Map<string, FieldItem>, modelName: string, attributes: Array<ModelAttribute | string>, options = {}) {
  map.set(modelName, {
    name: modelName,
    attributesMap: new Map(),
    ...options,
  });

  addAttributesForModel(map, modelName, attributes);
}

function addAttributesForModel(map: Map<string, FieldItem>, modelName: string, attributes: Array<ModelAttribute | string>) {
  for (const attribute of attributes) {
    addAttributeToModel(map, modelName, attribute);
  }
}

function addAttributeToModel(map: Map<string, FieldItem>, modelName: string, attribute: ModelAttribute | string) {
  let attributeName: string;
  let displayName: string;
  let singleContentName: string;

  if (typeof attribute === "string") {
    attributeName = attribute;
    displayName = UIUtils.convertCamelCaseToSpacedOutWords(attributeName);
  } else {
    attributeName = attribute.name;
    displayName = attribute.displayName || UIUtils.convertCamelCaseToSpacedOutWords(attributeName);
    singleContentName = attribute.singleContentName;
  }

  const attributeValue: FieldItem = {
    displayName: displayName,
    name: attributeName,
  };
  if (singleContentName) {
    attributeValue.singleContentName = singleContentName;
  }

  map.get(modelName).attributesMap.set(attributeName, attributeValue);
}

function modifyAttributesOnProjectRiskType(project: Project, clazzName: string, clazz: FieldItem) {
  if (!["FQA", "FPA"].includes(clazzName)) {
    return;
  }

  let hiddenAttributes: Array<string>;
  const HIDDEN_ATTRIBUTES_FOR_PHA_PROJECT = [
    "Criticality",
    "maxCriticalityRaw",
    "maxCriticalityPercentage",
    "processRiskRaw",
    "processRiskPercentage",
    "rpnRaw",
    "rpnPercentage",
    "techTransferRPNRaw",
    "techTransferRPNPercentage",
  ];
  const HIDDEN_ATTRIBUTES_FOR_RISK_RANKING_PROJECT = [
    "impact",
    "uncertainty",
    "maxCriticalityRaw",
    "maxCriticalityPercentage",
  ];
  
  if (!project) {
    hiddenAttributes = [
      ...HIDDEN_ATTRIBUTES_FOR_PHA_PROJECT,
      ...HIDDEN_ATTRIBUTES_FOR_RISK_RANKING_PROJECT,
    ];
  } else if (
    project.productRiskAssessmentType === "Preliminary Hazards Analysis (PHA)"
  ) {
    hiddenAttributes = HIDDEN_ATTRIBUTES_FOR_PHA_PROJECT;
  } else {
    hiddenAttributes = HIDDEN_ATTRIBUTES_FOR_RISK_RANKING_PROJECT;
  }

  for (const attributeName of hiddenAttributes) {
    clazz.attributesMap.delete(attributeName);
  }
}

function removeAttributeFromModel(map, attributes) {
  for (const attribute of attributes) {
    map.delete(attribute.name);

  }
}

export function getDisplayNameOfModel(
  fields: Map<string, FieldItem>,
  modelName: string,
  subModelName?: string,
) {
  const fieldsOfModelName = fields
    .get(FIELD_KEY.RecordFields)
    ?.attributesMap.get(modelName);
  if (!fieldsOfModelName) {
    return null;
  }

  if (subModelName) {
    return fieldsOfModelName.attributesMap.get(subModelName)?.displayName;
  }

  return fieldsOfModelName.displayName;
}

