var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, Draggable } from '@progress/kendo-react-common';
import { ResizeHandlers } from './ResizeHandlers';
/**
 * @hidden
 */
var ANIMATION_DURATION = 200;
/**
 * @hidden
 */
var InternalTile = /** @class */ (function (_super) {
    __extends(InternalTile, _super);
    function InternalTile() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            rtl: false
        };
        _this.oldSize = {};
        _this.draggable = null;
        _this.dragging = false;
        _this.resizing = false;
        _this.element = null;
        _this.ignoreDrag = false;
        _this.pressOffset = { x: 0, y: 0 };
        _this.pressXY = { x: 0, y: 0 };
        _this.currentTranslate = { x: 0, y: 0 };
        _this.preventDataOps = undefined;
        _this.handleResize = function (e, q) {
            if (q.end) {
                _this.handleRelease();
                return;
            }
            if (!_this.element) {
                return;
            }
            var x = e.clientX;
            var y = e.clientY;
            _this.resizing = true;
            var dX = (q.direction !== 'ns' ? x - _this.pressXY.x : 0) * (_this.state.rtl ? -1 : 1);
            var dY = q.direction !== 'ew' ? y - _this.pressXY.y : 0;
            if (_this.dragElement) {
                if (_this.state.rtl) {
                    _this.dragElement.style.marginLeft = -dX + 'px';
                }
                else {
                    _this.dragElement.style.marginRight = -dX + 'px';
                }
                _this.dragElement.style.height = "calc(100% + ".concat(dY, "px)");
            }
            _this.element.classList.add('k-layout-item-hint', 'k-layout-item-hint-resize');
            if (_this.preventDataOps) {
                return;
            }
            var col = 0;
            var row = 0;
            var wrapBox = _this.element.getBoundingClientRect();
            if (dX > wrapBox.width / _this.props.defaultPosition.colSpan / 3) {
                col = 1;
            }
            if (dX < -wrapBox.width / _this.props.defaultPosition.colSpan / 1.25) {
                col = -1;
            }
            // vertical
            if (dY > wrapBox.height / _this.props.defaultPosition.rowSpan / 3) {
                row = 1;
            }
            if (dY < -wrapBox.height / _this.props.defaultPosition.rowSpan / 1.25) {
                row = -1;
            }
            if (col !== 0 || row !== 0) {
                _this.props.update(_this.props.index, 0, 0, row, col);
            }
        };
        _this.handlePress = function (e) {
            if (!_this.dragElement) {
                return;
            }
            _this.ignoreDrag = false;
            if (_this.props.ignoreDrag && _this.props.ignoreDrag(e.event.originalEvent)) {
                _this.ignoreDrag = true;
                return;
            }
            if (_this.element) {
                _this.element.style.zIndex = '10';
                _this.element.classList.add('k-layout-item-hint');
            }
            _this.dragElement.classList.remove('k-cursor-grab');
            _this.dragElement.classList.add('k-cursor-grabbing');
            var rec = _this.dragElement.getBoundingClientRect();
            _this.pressXY = {
                x: e.event.clientX,
                y: e.event.clientY
            };
            _this.pressOffset = {
                x: e.event.clientX - rec.x,
                y: e.event.clientY - rec.y
            };
        };
        _this.handleDrag = function (e) {
            if (_this.ignoreDrag) {
                return;
            }
            var dragElement = _this.dragElement;
            if (e.event.originalEvent.defaultPrevented || !dragElement) {
                return;
            }
            _this.dragging = true;
            e.event.originalEvent.preventDefault();
            var rec = dragElement.getBoundingClientRect();
            _this.currentTranslate = {
                x: e.event.clientX - rec.x - _this.pressOffset.x + _this.currentTranslate.x,
                y: e.event.clientY - rec.y - _this.pressOffset.y + _this.currentTranslate.y
            };
            dragElement.style.transform = "translate(".concat(_this.currentTranslate.x, "px, ").concat(_this.currentTranslate.y, "px)");
            dragElement.style.transition = 'transform 0s';
            if (_this.preventDataOps) {
                return;
            }
            var col = 0;
            var row = 0;
            if (_this.currentTranslate.y > 0.7 * rec.height / _this.props.defaultPosition.rowSpan) {
                row = 1;
            }
            if (_this.currentTranslate.y < 0.7 * -rec.height / _this.props.defaultPosition.rowSpan) {
                row = -1;
            }
            if (_this.currentTranslate.x > 0.7 * rec.width / _this.props.defaultPosition.colSpan) {
                col = 1;
            }
            if (_this.currentTranslate.x < 0.7 * -rec.width / _this.props.defaultPosition.colSpan) {
                col = -1;
            }
            _this.props.update(_this.props.index, row, _this.state.rtl ? -col : col, 0, 0);
        };
        _this.handleRelease = function () {
            _this.dragging = _this.resizing = false;
            _this.currentTranslate = { x: 0, y: 0 };
            if (_this.element) {
                _this.element.style.zIndex = '1';
                _this.element.classList.remove('k-layout-item-hint', 'k-layout-item-hint-resize');
            }
            var dragElement = _this.dragElement;
            if (dragElement) {
                dragElement.style.transform = 'translate(0px, 0px)';
                dragElement.style.transition = "transform ".concat(ANIMATION_DURATION, "ms cubic-bezier(0.2, 0, 0, 1) 0s");
                dragElement.style.marginRight = '0px';
                dragElement.style.marginLeft = '0px';
                dragElement.style.height = '100%';
                dragElement.classList.remove('k-cursor-grabbing');
                dragElement.classList.add('k-cursor-grab');
            }
        };
        return _this;
    }
    Object.defineProperty(InternalTile.prototype, "reorderable", {
        get: function () {
            return this.props.reorderable !== undefined ? this.props.reorderable : InternalTile.defaultProps.reorderable;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InternalTile.prototype, "dragElement", {
        get: function () {
            return this.draggable ? this.draggable.element : undefined;
        },
        enumerable: false,
        configurable: true
    });
    InternalTile.prototype.componentDidMount = function () {
        if (!this.element) {
            return;
        }
        if (getComputedStyle(this.element).direction === 'rtl') {
            this.setState({
                rtl: true
            });
        }
    };
    InternalTile.prototype.render = function () {
        var _this = this;
        if (clearTimeout && typeof (clearTimeout) === 'function') {
            clearTimeout(this.preventDataOps);
            this.preventDataOps = window.setTimeout(function () { _this.preventDataOps = undefined; }, 200);
        }
        var position = this.props.defaultPosition;
        var resizable = this.props.resizable !== undefined ? this.props.resizable : InternalTile.defaultProps.resizable;
        var itemStyles = __assign({ gridColumnStart: position.col, gridColumnEnd: "span ".concat(position.colSpan), gridRowStart: position.row, gridRowEnd: "span ".concat(position.rowSpan), outline: 'none', order: position.order }, this.props.hintStyle);
        var card = (React.createElement("div", { ref: function (e) { _this.draggable = e ? ({ element: e }) : null; }, className: classNames('k-tilelayout-item k-card', { 'k-cursor-grab': this.reorderable }, this.props.className), style: __assign({ height: '100%' }, this.props.style) },
            this.props.children,
            React.createElement(ResizeHandlers, { onPress: this.handlePress, onResize: this.handleResize, resizable: resizable, rtl: this.state.rtl })));
        return (React.createElement("div", { ref: function (e) { _this.element = e; }, style: itemStyles, className: this.props.hintClassName },
            React.createElement(Draggable, { ref: function (e) { _this.draggable = e; }, onDrag: this.props.reorderable ? this.handleDrag : undefined, onRelease: this.props.reorderable ? this.handleRelease : undefined, onPress: this.props.reorderable ? this.handlePress : undefined }, card)));
    };
    /**
     * @hidden
     */
    InternalTile.prototype.getSnapshotBeforeUpdate = function (_) {
        this.oldSize = {};
        if (this.dragElement) {
            this.oldSize = this.dragElement.getBoundingClientRect();
        }
        return null;
    };
    /**
     * @hidden
     */
    InternalTile.prototype.componentDidUpdate = function (prevProps) {
        var _this = this;
        var dragElement = this.dragElement;
        if (!dragElement) {
            return;
        }
        var newBox = dragElement.getBoundingClientRect();
        var oldBox = this.oldSize;
        if (this.resizing) {
            var diffCol = newBox.width - oldBox.width;
            if (this.state.rtl) {
                var currentM = parseFloat(dragElement.style.marginLeft || '0');
                dragElement.style.marginLeft = (currentM - diffCol) + 'px';
            }
            else {
                var currentM = parseFloat(dragElement.style.marginRight || '0');
                dragElement.style.marginRight = (currentM + diffCol) + 'px';
            }
            this.pressXY.x += this.state.rtl ? -diffCol : diffCol;
            var diffRow = newBox.height - oldBox.height;
            var currentBot = parseFloat(dragElement.style.height.substring(12));
            dragElement.style.height = "calc(100% + ".concat((currentBot + diffRow), "px)");
            this.pressXY.y += diffRow;
        }
        var deltaX = oldBox.left - newBox.left;
        var deltaY = oldBox.top - newBox.top;
        if (deltaX === 0 && deltaY === 0) {
            return;
        }
        if (this.dragging) {
            if ((prevProps.defaultPosition.order !== this.props.defaultPosition.order ||
                prevProps.defaultPosition.col !== this.props.defaultPosition.col)) {
                this.currentTranslate.x = 0;
                this.currentTranslate.y = 0;
                dragElement.style.transform = '';
            }
            return;
        }
        if (Math.abs(deltaY) < 15 && Math.abs(deltaX) < 15) {
            // improves performance and removes random flickering
            return;
        }
        requestAnimationFrame(function () {
            var domNode = _this.element;
            if (!domNode) {
                return;
            }
            domNode.style.transform = "translate(".concat(deltaX, "px, ").concat(deltaY, "px)");
            domNode.style.transition = 'transform 0s';
            requestAnimationFrame(function () {
                domNode.style.transform = '';
                domNode.style.transition = "transform ".concat(ANIMATION_DURATION, "ms cubic-bezier(0.2, 0, 0, 1) 0s");
            });
        });
    };
    /**
     * @hidden
     */
    InternalTile.propTypes = {
        defaultPosition: PropTypes.object.isRequired,
        style: PropTypes.object,
        className: PropTypes.string,
        hintStyle: PropTypes.object,
        hintClassName: PropTypes.string,
        header: PropTypes.any,
        body: PropTypes.any,
        item: PropTypes.any,
        resizable: PropTypes.oneOf(['horizontal', 'vertical', true, false]),
        reorderable: PropTypes.bool
    };
    /**
     * @hidden
     */
    InternalTile.displayName = 'KendoTileLayoutItem';
    /**
     * @hidden
     */
    InternalTile.defaultProps = {
        resizable: true,
        reorderable: true
    };
    return InternalTile;
}(React.Component));
export { InternalTile };
