"use strict";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";

export const TooltipIcon = () => {
  return (
    <span id={"assessmentModeSelectorTooltipIcon"} className="field-tooltip-icon">
        <FontAwesomeIcon icon={faInfoCircle} className={""} />
    </span>
  );
};