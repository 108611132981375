"use strict";

import React from "react";

export const NewRmpWarning = ({ rmpUrl,changeRmpUrl }) => {
  return (
    <div id="newRmpVersionAvailableWarning" className="row">
      <div className="col-sm-12 alert alert-warning">
        <span>A new <a id="newRmpLink" href={rmpUrl} rel="noreferrer">RMP version</a> is available. Please <a id="changeRmpLink" href={changeRmpUrl} rel="noreferrer">update or change</a> the project RMP.</span>
      </div>
    </div>
  );
};