import ImplementationNeededError from "../../../utils/implementation_needed_error";
import * as UIUtils from "../../../ui_utils";
import { FileType } from "../../common/constants";
import { getCSSClassForLegend } from "../../../reports/risk_map_reports/utilities/risk_map_report_helper";

export class BaseParser {
  constructor(
    globalNode,
    parentNode,
    node,
    data,
    fileType,
    rmp = null,
    project = null,
    process = null,
    parseOnly = true
  ) {
    this.globalNode = globalNode;
    this.parentNode = parentNode;
    this.node = node;
    this.data = data;
    this.rmp = rmp;
    this.project = project;
    this.process = process;
    this.parseOnly = parseOnly;
    this.fileType = fileType;
    this.fields = {};
  }

  /**
   * @return []
   */
  parse() {
    throw new ImplementationNeededError();
  }

  addModelAndColumnToField(currentField, modelName, columnName) {
    if (!modelName) {
      return currentField;
    }

    if (!currentField[modelName]) {
      currentField[modelName] = {
        attributes: [],
      };
    }

    if (
      columnName &&
      !currentField[modelName].attributes.includes(columnName)
    ) {
      currentField[modelName].attributes.push(columnName);
    }

    return currentField[modelName];
  }

  createElementFromText(htmlText, multiple = false) {
    const placeholder = document.createElement("div");
    htmlText = htmlText.trim();
    placeholder.innerHTML = htmlText;
    return multiple
      ? Array.from(placeholder.childNodes)
      : placeholder.firstElementChild;
  }

  getValueForDocBuilderField(columnName) {
    switch (columnName) {
      case "pageNumber":
      case "pageNumberExcludingCoverPage":
        if (this.fileType === FileType.DOC) {
          return this.createElementFromText(
            "<span style='mso-field-code:PAGE'></span>"
          );
        }
        return UIUtils.isNumber(columnName) ? columnName : "#";
      case "totalPages":
        if (this.fileType === FileType.DOC) {
          return this.createElementFromText(
            "<span style='mso-field-code:NUMPAGES'></span>"
          );
        }
        return UIUtils.isNumber(columnName) ? columnName : "#";
      case "softwareVersion":
        return UIUtils.getSoftwareVersion();
      case "companyName":
        return UIUtils.getCompany();
      case "currentUser":
        return UIUtils.getUserFullName();
      case "informationDate":
        return UIUtils.getDateForDisplayToUser(new Date());
      default:
        return columnName;
    }
  }

  getNodeInfo(element = null) {
    const node = element || this.node;
    const parts = node.innerText.split(".");
    let parentModelName, modelName, columnName;
    if (parts.length >= 3) {
      [parentModelName, modelName, columnName] = parts;
    } else {
      [modelName, columnName] = parts;
    }

    return {parentModelName, modelName, columnName};
  }

  createColorElement(color) {
    return createColorElement(color, this.fileType);
  }

  get parentModelName() {
    const parts = this.node.getAttribute("model").split(".");
    return parts[0];
  }

  get subModelName() {
    const parts = this.node.getAttribute("model").split(".");
    return parts.length > 1 ? parts[1] : null;
  }

  /**
   * Find the closest widget node the wrap the target node
   * @param node
   * @param modelName
   * @return {*|null}
   */
  findParentWidgetNode(node, modelName = null) {
    if (!node) {
      return null;
    }

    if (typeof node.getAttribute === "function" && node.getAttribute("kind")) {
      if (!modelName) {
        return node;
      }

      if (node.getAttribute("model") === modelName) {
        return node;
      }
    }

    return this.findParentWidgetNode(node.parentElement, modelName);
  }

  createErrorElement(element, className) {
    let data =
      "Data cannot be found because there is an issue with the record or the structure of the widget.";
    // for some unknown reason, process sometimes is represented as a setImmediate result object
    if (!this.process?.id) {
      data = "Data cannot be found because a Process has not been selected.";
    }
    if (!this.project?.id) {
      data =
        "Data cannot be found because a Project and Process have not been selected.";
    }
    return `<span class="alert alert-danger smart-content-error ${className}" data-error="${data}">${UIUtils.secureString(
      element.innerText,
    )}</span>`;
  }
}

export function createColorElement(color, fileType = null) {
  const spanElement = document.createElement("span");
  spanElement.className = `square-sm ${getCSSClassForLegend(
    color
  )}`;

  if (fileType === FileType.DOC) {
    const element = document.createTextNode("▇");
    spanElement.appendChild(element);
    spanElement.style.color = color;
  }

  return spanElement;
}
