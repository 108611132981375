var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { messages, colorGradientContrastRatio, colorGradientAALevel, colorGradientAAALevel, colorGradientPass, colorGradientFail } from '../messages';
import { getContrastFromTwoRGBAs } from './utils/color-parser';
/**
 * @hidden
 */
var ColorContrastLabels = /** @class */ (function (_super) {
    __extends(ColorContrastLabels, _super);
    function ColorContrastLabels() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ColorContrastLabels.prototype.render = function () {
        var localizationService = provideLocalizationService(this);
        var contrastRatioMessage = localizationService.toLanguageString(colorGradientContrastRatio, messages[colorGradientContrastRatio]);
        var AALevelMessage = localizationService.toLanguageString(colorGradientAALevel, messages[colorGradientAALevel]);
        var AAALevelMessage = localizationService.toLanguageString(colorGradientAAALevel, messages[colorGradientAAALevel]);
        var passMessage = localizationService.toLanguageString(colorGradientPass, messages[colorGradientPass]);
        var failMessage = localizationService.toLanguageString(colorGradientFail, messages[colorGradientFail]);
        var contrast = getContrastFromTwoRGBAs(this.props.rgba, this.props.bgColor);
        var requiredAAContrast = (4.5).toFixed(1);
        var requiredAAAContrast = (7.0).toFixed(1);
        var contrastText = "".concat(contrastRatioMessage, ": ").concat(contrast.toFixed(2));
        var aaText = "".concat(AALevelMessage, ": ").concat(requiredAAContrast);
        var aaaText = "".concat(AAALevelMessage, ": ").concat(requiredAAAContrast);
        var success = (React.createElement("span", { className: "k-contrast-validation k-text-success" },
            passMessage,
            " ",
            React.createElement("span", { className: "k-icon k-i-check" })));
        var error = (React.createElement("span", { className: "k-contrast-validation k-text-error" },
            failMessage,
            " ",
            React.createElement("span", { className: "k-icon k-i-close" })));
        return (React.createElement("div", { className: "k-vbox k-colorgradient-color-contrast" },
            React.createElement("div", { className: "k-contrast-ratio" },
                React.createElement("span", { className: "k-contrast-ratio-text" }, contrastText),
                contrast >= 4.5 ? (React.createElement("span", { className: "k-contrast-validation k-text-success" },
                    React.createElement("span", { className: "k-icon k-i-check" }),
                    contrast >= 7 && React.createElement("span", { className: "k-icon k-i-check" }))) : (React.createElement("span", { className: "k-contrast-validation k-text-error" },
                    React.createElement("span", { className: "k-icon k-i-close" })))),
            React.createElement("div", null,
                React.createElement("span", null, aaText),
                contrast >= 4.5 ? success : error),
            React.createElement("div", null,
                React.createElement("span", null, aaaText),
                contrast >= 7 ? success : error)));
    };
    return ColorContrastLabels;
}(React.Component));
export { ColorContrastLabels };
registerForLocalization(ColorContrastLabels);
