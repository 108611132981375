"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import { EXPERIMENTS } from "../../helpers/constants/constants";
import ImplementationNeededError from "../../utils/implementation_needed_error";
import BaseReactComponent from "../../base_react_component";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";

/* This is a base react control for showing the title bar at the top of the screen . */
export default class BasePageTitleBar extends BaseReactComponent {
  constructor(props) {
    super(props);

    if (this.props.projectId && this.props.projectId > 0 && !this.props.isProjectsPage) {
      new TypeaheadObjectCache("Project").loadOptions(this.handleTypeaheadResultsFromServer);
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.projectId !== this.props.projectId ||
           nextProps.currentState !== this.props.currentState ||
           nextProps.currentStateTooltip !== this.props.currentStateTooltip ||
           nextProps.isProjectsPage !== this.props.isProjectsPage;
  }

  handleTypeaheadResultsFromServer() {
    this.forceUpdateSafely();
  }

  renderTitleBar() {
    return new ImplementationNeededError();
  }

  render() {
    const {
      currentState,
      currentStateTooltip,
      renderButton,
    } = this.props;

    return (
      <div className={"page-title-bar" + (UIUtils.isExperimentEnabled(EXPERIMENTS.PartyMode) ? " page-title-bar-party-mode" : "")}>
        <div className="container-fluid container-fluid-body">
          {this.renderTitleBar()}
          {currentState ?
            // Shrink the status if it's too big (ex. "Proposed for Restore (Cascaded)")
            <div id="pageTitleBarStatus"
                 className={currentState.length > 20 ?
                   " page-title-bar-status-mini" : "page-title-bar-status"}
                 title={currentStateTooltip}>
              [{currentState}]
            </div>
            : ""}
          {typeof renderButton === "function"
            ? (
              <span className="page-title-bar-cta-button">
                {renderButton()}
              </span>
            ) : ""}
        </div>
      </div>
    );
  }
}
