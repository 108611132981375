"use strict";

import React from "react";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { DELETE_ROW_OPTIONS, WIDGET_FIELDS } from "./delete_risk_links_confirmation_constants";
import { BaseRiskLinksConfirmationPopup } from "./base_risk_links_confirmation_popup";

/**
 *
 */
class DeleteRiskLinksConfirmationPopup extends BaseRiskLinksConfirmationPopup {
  constructor(props) {
    super(props, WIDGET_FIELDS);
  }

  handleRowOptionChange(e) {

    super.handleRowOptionChange(e, DELETE_ROW_OPTIONS);

  }

  // eslint-disable-next-line no-unused-vars
  handleConfirmAction(event) {
    const {documents} = this.state;
    this.props.onDeleteRiskLinksAction(documents);
  }

}

export default I18NWrapper.wrap(DeleteRiskLinksConfirmationPopup, "editor");

// i18next-extract-mark-ns-stop editor
