var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { PanelBarItem } from './PanelBarItem';
/**
 * @hidden
 */
export var renderChildren = function (_a) {
    var _b = _a.animation, animation = _b === void 0 ? true : _b, _c = _a.keepItemsMounted, keepItemsMounted = _c === void 0 ? false : _c, state = _a.state, expanded = _a.expanded, handleSelect = _a.handleSelect, children = _a.children, _d = _a.parentExpanded, parentExpanded = _d === void 0 ? true : _d, _e = _a.level, level = _e === void 0 ? 0 : _e, _f = _a.parentPrivateKey, parentPrivateKey = _f === void 0 ? [] : _f;
    return React.Children.map(children, function (child, idx) {
        if (child && child.type === PanelBarItem) {
            var nestedChildren = void 0;
            var privateKey = getId(child, parentPrivateKey, idx);
            if (child.props.children) {
                var renderState = {
                    animation: animation,
                    keepItemsMounted: keepItemsMounted,
                    state: state,
                    expanded: expanded,
                    handleSelect: handleSelect,
                    children: child.props.children,
                    parentExpanded: (expanded || []).indexOf(privateKey) > -1,
                    level: level + 1,
                    parentPrivateKey: __spreadArray(__spreadArray([], parentPrivateKey, true), [privateKey], false)
                };
                nestedChildren = renderChildren(renderState);
            }
            return React.cloneElement(child, __assign(__assign({}, child.props), { animation: child.props.animation !== undefined ? child.props.animation : animation, keepItemsMounted: keepItemsMounted, id: child.props.id || "k-panelbar-item-default-".concat(privateKey), uniquePrivateKey: privateKey, parentUniquePrivateKey: parentPrivateKey, parentExpanded: parentExpanded, level: level, expanded: (expanded || []).indexOf(privateKey) > -1, focused: state.focused === privateKey && state.wrapperFocused, selected: state.selected === privateKey, children: nestedChildren, onSelect: handleSelect }));
        }
        else {
            return React.createElement("div", { className: "k-panelbar-content k-content" }, child);
        }
    });
};
/**
 * @hidden
 */
export var getFirstId = function (props) {
    var firstItem = React.Children.toArray(props.children)[0];
    if (firstItem) {
        return getId(firstItem, [], 0);
    }
    return '';
};
/**
 * @hidden
 */
export var getInitialState = function (props, expandMode, result, parentExpanded, parentPrivateKey) {
    if (result === void 0) { result = {
        expanded: props.expanded || [],
        selected: props.selected || '',
        focused: props.focused || '',
        wrapperFocused: false
    }; }
    if (parentExpanded === void 0) { parentExpanded = true; }
    if (parentPrivateKey === void 0) { parentPrivateKey = []; }
    React.Children.map(props.children, function (child, idx) {
        if (child && child.type === PanelBarItem) {
            var privateKey = getId(child, parentPrivateKey, idx);
            if (!child.props.disabled && parentExpanded) {
                if (child.props.selected) {
                    result.selected = privateKey;
                }
                if (child.props.focused) {
                    result.focused = privateKey;
                }
                if (child.props.expanded) {
                    if (expandMode === 'multiple') {
                        result.expanded.push(privateKey);
                    }
                    else if (expandMode === 'single') {
                        result.expanded = [privateKey];
                    }
                }
                if (child.props.children) {
                    result = getInitialState(child.props, expandMode, result, !!child.props.expanded, __spreadArray(__spreadArray([], parentPrivateKey, true), [privateKey], false));
                }
            }
        }
    });
    return result;
};
/**
 * @hidden
 */
var getId = function (child, parentPrivateKey, idx) {
    return child && child.props && child.props.id
        ? child.props.id
        : parentPrivateKey.length
            ? parentPrivateKey[parentPrivateKey.length - 1] + ".".concat(idx)
            : ".".concat(idx);
};
/**
 * @hidden
 */
export function flatVisibleItems(data, flattedItems) {
    if (flattedItems === void 0) { flattedItems = []; }
    (data || []).forEach(function (item) {
        if (!item.disabled) {
            flattedItems.push(item);
            if (item.expanded && item.children) {
                flatVisibleItems(item.children, flattedItems);
            }
        }
    });
    return flattedItems;
}
/**
 * @hidden
 */
export function flatChildren(children, flattenChildren) {
    if (flattenChildren === void 0) { flattenChildren = []; }
    React.Children.forEach(children, function (child) {
        if (child && child.props && !child.props.disabled) {
            flattenChildren.push(child);
            if (child.props.children) {
                flatChildren(child.props.children, flattenChildren);
            }
        }
    });
    return flattenChildren;
}
;
/**
 * @hidden
 */
export function flatVisibleChildren(children, flattenVisibleChildren) {
    if (flattenVisibleChildren === void 0) { flattenVisibleChildren = []; }
    React.Children.forEach(children, function (child) {
        if (child && child.props && !child.props.disabled && (child.props.expanded || child.props.parentExpanded)) {
            flattenVisibleChildren.push(child);
            if (child.props.children) {
                flatVisibleChildren(child.props.children, flattenVisibleChildren);
            }
        }
    });
    return flattenVisibleChildren;
}
;
/**
 * @hidden
 */
export var isPresent = function (value) { return value !== null && value !== undefined; };
/**
 * Represents the PanelBarUtils functions.
 */
export var PanelBarUtils;
(function (PanelBarUtils) {
    /**
     * Maps a collection of PanelBarItemProps to PanelBarItem components
     * ([see example]({% slug customization_panelbar %})).
     * Contained in the `PanelBarUtils` object.
     *
     * @param items - A collection of PanelBarItemProps.
     * @return {PanelBarItem[]} Returns an array of PanelBarItem components.
     */
    function mapItemsToComponents(items) {
        return items.map(function (item, idx) {
            var children;
            if (item.content) {
                children = item.content;
            }
            if (item.children) {
                children = (mapItemsToComponents(item.children));
            }
            return (React.createElement(PanelBarItem, __assign({}, item, { children: children, key: item.id || idx })));
        });
    }
    PanelBarUtils.mapItemsToComponents = mapItemsToComponents;
})(PanelBarUtils || (PanelBarUtils = {}));
/**
 * @hidden
 */
export var isArrayEqual = function (firstArray, secondArray) {
    if (firstArray.length !== secondArray.length) {
        return false;
    }
    return firstArray.every(function (ex, i) { return ex === secondArray[i]; });
};
