"use strict";

import React, { Fragment } from "react";
import TechTransferReviewRow from "./widgets/tech_transfer_review_row";
import TechTransferReviewHeader from "./widgets/tech_transfer_review_header";
import ImplementationNeededError from "../../utils/implementation_needed_error";
import TechTransferReviewBase from "./tech_transfer_review_base";
import TechTransferViewAnalyticsWidget from "../widgets/tech_transfer_view_analytics_widget";

/**
 *  This class shows the review screen when a user is reviewing a tech transfer record.
 */
export default class TechTransferVariableReviewBase extends TechTransferReviewBase {
  renderCriticalityAssessment() {
    throw new ImplementationNeededError();
  }

  renderAcceptanceCriteria() {
    throw new ImplementationNeededError();
  }

  renderDiff() {
    const {hideUnchangedFields} = this.state;
    const {reviewRecord, fromProcess, toProcess, onViewChangeCriteria} = this.props;
    const transferredTo = reviewRecord;
    const transferredFrom = reviewRecord.techTransferredFrom || {};
    const {totalChangesCount, changeCriteria,} = reviewRecord;

    const commonProps = {
      hideUnchangedFields,
      transferredFrom,
      transferredTo,
      changeCriteria,
      totalChangesCount,
      onViewChangeCriteria,
    };

    const usePartialDiffCommonProps = {
      usePartialDiff: true,
      ...commonProps,
    };

    return (
      <Fragment>
        <TechTransferReviewHeader name="name"
                                  onHideUnchangedFields={this.handleHideUnchangedFields}
                                  receivingSite={fromProcess}
                                  sendingSite={toProcess}
        />

        <div className="row">
          <div className="col-12 tech-transfer-review-section-title-container">
            ABOUT
          </div>
        </div>
        <TechTransferReviewRow name="name" {...commonProps} />
        <TechTransferReviewRow name="unitOperation" {...commonProps} />
        <TechTransferReviewRow name="step" {...commonProps} />
        <TechTransferReviewRow name="type" {...commonProps} />
        <TechTransferReviewRow name="description" {...usePartialDiffCommonProps} />

        {this.renderCriticalityAssessment()}
        {this.renderAcceptanceCriteria()}

        <div className="row">
          <div className="col-4 tech-transfer-review-section-title-container">
            PROCESS CAPABILITY
          </div>
          <div className="col-4 tech-transfer-review-section-title-container">
            <TechTransferViewAnalyticsWidget record={transferredFrom}
                                             parent={this}
            />
          </div>
          <div className="col-4 tech-transfer-review-section-title-container">
            <TechTransferViewAnalyticsWidget record={transferredTo}
                                             parent={this}
            />
          </div>
        </div>
        <TechTransferReviewRow name="capabilityRisk"
                               displayName="Capability Risk / Occurrence"
                               {...commonProps}
        />
        <TechTransferReviewRow name="processRisk"
                               displayName="Process Risk (Raw)"
                               {...commonProps}
        />
        <TechTransferReviewRow name="processRiskPercWithoutLabel"
                               prop="processRiskPerc"
                               displayName="Process Risk (%)"
                               {...commonProps}
        />
        <TechTransferReviewRow name="estimatedSampleSize"
                               {...commonProps}
        />
        <TechTransferReviewRow name="capabilityJustification"
                               displayName="Capability Justification"
                               {...usePartialDiffCommonProps}
        />

        <div className="row">
          <div className="col-12 tech-transfer-review-section-title-container">
            RISK CONTROL
          </div>
        </div>
        <TechTransferReviewRow name="detectabilityRisk"  {...commonProps} />
        <TechTransferReviewRow name="RPN"
                               displayName="RPN (Raw)"
                               {...commonProps}
        />
        <TechTransferReviewRow name="RPNPercWithoutLabel"
                               prop="RPNPerc"
                               displayName="RPN (%)"
                               {...commonProps}
        />
        <TechTransferReviewRow name="detectabilityJustification" {...usePartialDiffCommonProps} />
        <TechTransferReviewRow name="controlStrategy" {...usePartialDiffCommonProps} />
        <TechTransferReviewRow name="ccp" {...commonProps} />
        <TechTransferReviewRow name="controlStrategyJustification" {...usePartialDiffCommonProps} />
        <TechTransferReviewRow name="riskControlLinks"
                               displayName="Source Documents"
                               isDocs={true}
                               {...usePartialDiffCommonProps} />

        <div className="row">
          <div className="col-12 tech-transfer-review-section-title-container">
            References & Standards
          </div>
        </div>
        <TechTransferReviewRow name="referencesLinks"
                               displayName="Source Documents"
                               isDocs={true}
                               {...usePartialDiffCommonProps} />
      </Fragment>
    );
  }
}
