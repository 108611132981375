"use strict";

import React from "react";
import BasePageTitleBar from "./base_page_title_bar";

/* This shows the title bar for the bulk proposal/approval flow */
export default class BulkProposalApprovalTitleBar extends BasePageTitleBar {
  constructor(props) {
    super(props);
  }

  renderTitleBar() {
    const {title} = this.props;

    return (
      <div className="page-title-bar-title"
           id="pageTitleBar">
        <span>
          <a href="/homePage/dashboard.html"
             id="gotoHomePageTitleBar">
            &larr;
          </a>
        </span>
        <span className="page-title-bar-primary"
              id="pageTitleBarPrimary">
            &nbsp;{title}
        </span>
      </div>
    );
  }
}
