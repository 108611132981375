var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames } from '@progress/kendo-react-common';
import { AnimationChild } from './AnimationChild';
import { TransitionGroup } from 'react-transition-group';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from './package-metadata';
import util from './util';
var styles = util.styles;
// tslint:enable:max-line-length
var Animation = /** @class */ (function (_super) {
    __extends(Animation, _super);
    function Animation(props) {
        var _this = _super.call(this, props) || this;
        validatePackage(packageMetadata);
        return _this;
    }
    /**
     * @hidden
     */
    Animation.prototype.render = function () {
        var _a = this.props, id = _a.id, style = _a.style, children = _a.children, component = _a.component, className = _a.className, childFactory = _a.childFactory, stackChildren = _a.stackChildren, componentChildStyle = _a.componentChildStyle, componentChildClassName = _a.componentChildClassName, other = __rest(_a, ["id", "style", "children", "component", "className", "childFactory", "stackChildren", "componentChildStyle", "componentChildClassName"]);
        var transitionProps = {
            id: id,
            style: style,
            component: component,
            childFactory: childFactory,
            className: classNames(styles['animation-container'], styles['animation-container-relative'], className)
        };
        var content = React.Children.map(children || null, function (child) { return (React.createElement(AnimationChild, __assign({}, other, { style: componentChildStyle, className: componentChildClassName }), child)); });
        return (React.createElement(TransitionGroup, __assign({}, transitionProps), content));
    };
    /**
     * @hidden
     */
    Animation.propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ]),
        childFactory: PropTypes.any,
        className: PropTypes.string,
        component: PropTypes.string,
        id: PropTypes.string,
        style: PropTypes.any,
        transitionName: PropTypes.string.isRequired,
        appear: PropTypes.bool.isRequired,
        enter: PropTypes.bool.isRequired,
        exit: PropTypes.bool.isRequired,
        transitionEnterDuration: PropTypes.number.isRequired,
        transitionExitDuration: PropTypes.number.isRequired
    };
    /**
     * @hidden
     */
    Animation.defaultProps = {
        component: 'div'
    };
    return Animation;
}(React.Component));
export { Animation };
