"use strict";

import React from "react";
import Heading from "../../widgets/headers/heading";
import { v4 as uuidv4 } from "uuid";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import { BaseUserRecord } from "./base_user_record";
import TextAttribute from "../../editor/attributes/text_attribute";
import Section from "../../editor/widgets/section";

/**
 * This class responsible for showing the api key view/add/edit page. An API Key is actually just a user, with different
 * information.
 */
export default class APIKey extends BaseUserRecord {
  constructor(props) {
    super(props);


    // Initialize Data and Breadcrumb for API Keys
    this.setStateSafely({
      isAPIKey: true,
      apiKey: uuidv4().replaceAll(/-/g, ""),
      breadcrumb: {
        current: "New API Key",
        parent: this.getBreadcrumbParentName(),
        parentLink: this.getBreadcrumbParentLink(),
      }
    });
  }

  componentDidMount() {
    document.title = "QbDVision API Key";

    super.componentDidMount();
  }

  onDataReceivedFromServer() {
    super.onDataReceivedFromServer();

    // We set the name in the firstName field to cut down on column bloat.
    this.setStateSafely({name: this.state.firstName});
  }

  beforeDataSavedToServer(callback) {
    // We set the name in the firstName field to cut down on column bloat.
    this.setStateSafely({firstName: this.state.name});
    super.beforeDataSavedToServer(callback);
  }

  getBreadcrumbParentLink() {
    return super.getBreadcrumbParentLink() + "?showAPIKeys=true";
  }

  getGeneralHeader() {
    return (
      <span id="baseEditorGeneralHeader">
        {"API Key"} {this.getInfoTooltip()}
      </span>
    );
  }

  getInfoTooltip() {
    return <InfoTooltip id="infoAPI"
                        verbiage={<div>
                          An API key can be used to interact with QbDVision programmatically, allowing for other systems
                          to
                          communicate with QbDVision. The OpenAPI documentation can be found
                          &nbsp;
                          <a href="/restAPI/index.html"
                             rel="noopener noreferrer"
                             target="_blank"
                             id="infoAPILink"
                          >here</a>.
                        </div>}
    />;
  }

  renderTypeHeader() {
    return (
      <Heading level={1}>
        API Key
      </Heading>
    );
  }

  getBreadcrumbParentName() {
    return "API Keys";
  }

  getRecordTypeName() {
    return "API key";
  }

  getCapitalizedRecordTypeName() {
    return "API Key";
  }

  getHTMLURLPrefix() {
    return "/" + this.pluralizedBaseTypeName + "/apiKeys";
  }

  renderHeaderWarning() {
    return this.isAdd() ? (
      <div className="row">
        <div className="alert alert-warning col-12">
          WARNING: Copy the API Key below somewhere secure. This is your last chance to view the entire key.
        </div>
      </div>
    ) : "";
  }

  renderGeneralSection() {
    return (
      <div className="row">
        <TextAttribute name="name" displayName="Name" parent={this} required={true} className="col-6" />
        <TextAttribute name="apiKey" parent={this} className="col-6"
                       disabled={true} // Users never get to set this.
                       tooltipText={this.isAdd() ? "Copy this value somewhere secure." : "This is masked because it is confidential. There is no way to access the entire key after it is created."}
        />
      </div>
    );
  }

  renderAttachmentsSection() {
    return (<Section id="attachments"
                     parent={this}
                     showDocLinks={true}
                     addOptions={[
                       {id: "contactInfo", label: "Owner Contact Info"},
                       {id: "description", label: "Description"},
                       {id: "sourceCode", label: "Source Code"},
                       {id: "other", label: "Other"},
                     ]}
                     header="Attachments & Links"
    />);
  }

  renderContactSection() {
    // API Keys aren't human. You can't call them on the phone.
    return "";
  }

  renderExperimentalSection() {
    return super.renderExperimentalSection(true);
  }
}