import * as React from 'react';
import * as PropTypes from 'prop-types';
import { validatePackage } from '@progress/kendo-licensing';
import { classNames, getTabIndex, useDir } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
/**
 * Represents the BreadcrumbOrderedList component.
 */
export var BreadcrumbOrderedList = React.forwardRef(function (props, ref) {
    validatePackage(packageMetadata);
    var target = React.useRef(null);
    var orderedListRef = React.useRef(null);
    React.useImperativeHandle(target, function () { return ({
        element: orderedListRef.current,
        props: props
    }); });
    React.useImperativeHandle(ref, function () { return target.current; });
    return (React.createElement("ol", { id: props.id, ref: orderedListRef, style: props.style, dir: useDir(orderedListRef, props.dir), tabIndex: getTabIndex(props.tabIndex, props.disabled), className: classNames('k-breadcrumb-container k-flex-wrap', {
            'k-disabled': props.disabled
        }, props.className) }, props.children));
});
var propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.element,
    tabIndex: PropTypes.number,
    style: PropTypes.object,
    dir: PropTypes.string,
    disabled: PropTypes.bool
};
BreadcrumbOrderedList.displayName = 'KendoReactBreadcrumbOrderedList';
BreadcrumbOrderedList.propTypes = propTypes;
