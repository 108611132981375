var divideByMagnitude = function (magnitude) { return function (x) { return Math.floor(x / magnitude); }; };
var powerByMagnitude = function (magnitude) { return function (x) { return x * magnitude; }; };
/**
 * @hidden
 */
var ScrollSyncService = /** @class */ (function () {
    function ScrollSyncService(dom) {
        this.dom = dom;
        this.divideByMagnitude = null;
        this.powerByMagnitude = null;
        this.navigator = null;
        this.view = null;
        this.monthScrolled = false;
        this.navScrolled = false;
    }
    ScrollSyncService.prototype.configure = function (activeView) {
        var magnitude = Math.max(this.dom.viewHeight(activeView) / this.dom.navigationItemHeight, 1);
        this.divideByMagnitude = divideByMagnitude(magnitude);
        this.powerByMagnitude = powerByMagnitude(magnitude);
    };
    ScrollSyncService.prototype.sync = function (navigator, view, e) {
        if (!navigator || !view) {
            return;
        }
        this.navigator = navigator;
        this.view = view;
        if (e.target === this.navigator.element) {
            if (this.monthScrolled) {
                this.monthScrolled = false;
                return;
            }
            this.navScrolled = true;
            this.scrollSiblingOf(this.navigator.element);
        }
        if (e.target === this.view.element) {
            if (this.navScrolled) {
                this.navScrolled = false;
                return;
            }
            this.monthScrolled = true;
            this.scrollSiblingOf(this.view.element);
        }
    };
    ScrollSyncService.prototype.scrollSiblingOf = function (scrolledElement) {
        var component = this.siblingComponent(scrolledElement);
        var scrollTop = this.calculateScroll(component, scrolledElement.scrollTop);
        component.scrollTo(scrollTop);
    };
    ScrollSyncService.prototype.siblingComponent = function (scrollableElement) {
        return this.navigator.element === scrollableElement ? this.view : this.navigator;
    };
    ScrollSyncService.prototype.calculateScroll = function (component, scrollTop) {
        var modifier = component === this.navigator ? this.divideByMagnitude : this.powerByMagnitude;
        return modifier ? modifier(scrollTop) : 0;
    };
    return ScrollSyncService;
}());
export { ScrollSyncService };
