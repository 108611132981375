var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as shortcuts from './../config/shortcuts';
import { marks as schemaMarks, nodes as schemaNodes } from './../config/schema';
import { alignBlocks as alignBlocksCommon, applyInlineStyle as applyInlineStyleCommon, applyLink as applyLinkCommon, canIndentAsListItem, canOutdentAsListItem, parseContent, canInsert as canInsertCommon, createTable as createTableCommon, formatBlockElements as formatBlockElementsCommon, getBlockFormats as getBlockFormatsCommon, getHtml as getHtmlCommon, getInlineStyles as getInlineStylesCommon, getMark as getMarkCommon, hasMark as hasMarkCommon, hasNode as hasNodeCommon, indentBlocks as indentBlocksCommon, setHtml as setHtmlCommon, insertNode as insertNodeCommon, isAligned as isAlignedCommon, isIndented as isIndentedCommon, toggleInlineFormat as toggleInlineFormatCommon, toggleList as toggleListCommon, indentHtml as indentHtmlCommon, convertMsLists as convertMsListsCommon, removeComments as removeCommentsCommon, removeTag as removeTagCommon, sanitize as sanitizeCommon, pasteCleanup as pasteCleanupCommon, sanitizeClassAttr as sanitizeClassAttrCommon, sanitizeStyleAttr as sanitizeStyleAttrCommon, removeAttribute as removeAttributeCommon, replaceImageSourcesFromRtf as replaceImageSourcesFromRtfCommon, textHighlight as textHighlightCommon, imageResizing as imageResizingCommon, imageResizeKey as imageResizeKeyCommon, tableResizing as tableResizingCommon, trimWhitespace } from '@progress/kendo-editor-common';
import { editorPropsKey } from './props-key';
/**
 * Represents a wrapping namespace for the utility functions, `nodes`, and `marks` objects of the Editor.
 */
export var EditorUtils;
(function (EditorUtils) {
    /**
     * Aligns the block elements in the selection.
     *
     * @returns {boolean}&mdash;If alignment is applied to any of the elements, returns `true`.
     */
    function alignBlocks(view, actions, command) {
        return Boolean(alignBlocksCommon(actions, command)(view.state, view.dispatch));
    }
    EditorUtils.alignBlocks = alignBlocks;
    /**
     * Wraps the selection in a `span` element with inline styles.
     *
     * @returns {boolean}&mdash;If a style is applied to any of the elements, returns `true`.
     */
    function applyInlineStyle(view, options, command) {
        return applyInlineStyleCommon(options, command)(view.state, view.dispatch);
    }
    EditorUtils.applyInlineStyle = applyInlineStyle;
    /**
     * Applies the link mark.
     *
     * @returns {boolean}&mdash;If the link is applied, returns `true`.
     */
    function applyLink(view, options, command) {
        return applyLinkCommon(options, command)(view.state, view.dispatch);
    }
    EditorUtils.applyLink = applyLink;
    /**
     * Checks if any of the `list` elements in the selection can be indented.
     *
     * @returns {boolean}
     */
    function canIndentList(state, nodeType) {
        return canIndentAsListItem(state, nodeType);
    }
    EditorUtils.canIndentList = canIndentList;
    /**
     * Checks if a node can be inserted in the current selection.
     *
     * @param {EditorState} state&mdash;The `state` object of the Editor.
     * @param {NodeType} nodeType&mdash;The type of the node that will be inserted.
     * @returns {boolean}&mdash;The node of this type can be inserted in the current selection.
     */
    function canInsert(state, nodeType) {
        return canInsertCommon(state, nodeType);
    }
    EditorUtils.canInsert = canInsert;
    /**
     * Checks if any of the `list` elements in the selection can be outdented.
     *
     * @returns {boolean}
     */
    function canOutdentList(state, listsTypes) {
        return canOutdentAsListItem(state, { listsTypes: listsTypes, nodes: [] });
    }
    EditorUtils.canOutdentList = canOutdentList;
    /**
     * Converts the MS Word lists into HTML lists.
     *
     * @param {string} html&mdash;The input HTML.
     * @returns {string}&mdash;The result HTML.
     */
    function convertMsLists(html) {
        return convertMsListsCommon(html);
    }
    EditorUtils.convertMsLists = convertMsLists;
    /**
     * Creates an Editor document from HTML content.
     *
     * @param {Schema} schema&mdash;The `schema` object of the Editor.
     * @param {string} html&mdash;The HTML content.
     * @param {ParseOptions} parseOptions&mdash;The HTML parsing options. Defaults to `{ preserveWhitespace: 'full' }`.
     * @returns {Node}&mdash;The `document` object of the Editor.
     */
    function createDocument(schema, html, parseOptions) {
        return parseContent(trimWhitespace(html), schema, parseOptions !== undefined ? parseOptions : { preserveWhitespace: 'full' });
    }
    EditorUtils.createDocument = createDocument;
    /**
     * Creates a table.
     *
     * @param {object} tableTypes&mdash;An object which contains `table`, `table_row`, and `table_cell` node types.
     * @param {number} rows&mdash;The number of rows.
     * @param {number} columns&mdash;The number of columns.
     * @returns {Node}&mdash;The generated table.
     *
     * @example
     * ```jsx-no-run
     * import { EditorUtils } from '@progress/kendo-react-editor';
     *
     * const nodes = editorRef.view.state.schema.nodes;
     * const rows = 3;
     * const columns = 4;
     *
     * const table = EditorUtils.createTable(nodes, rows, columns);
     * ```
     */
    function createTable(tableTypes, rows, columns) {
        return createTableCommon(tableTypes, rows, columns);
    }
    EditorUtils.createTable = createTable;
    /**
     * Formats the paragraph and heading nodes in the selection.
     *
     * @returns {boolean}&mdash;If an element is formatted, returns `true`.
     */
    function formatBlockElements(view, value, commandName) {
        return formatBlockElementsCommon(value, commandName)(view.state, view.dispatch);
    }
    EditorUtils.formatBlockElements = formatBlockElements;
    /**
     * Returns the paragraph and heading nodes in the selection.
     *
     * @returns {string[]}
     */
    function getBlockFormats(state) {
        return getBlockFormatsCommon(state);
    }
    EditorUtils.getBlockFormats = getBlockFormats;
    /**
     * Gets the HTML from the `EditorState` object.
     *
     * @param {EditorState} state&mdash;The `state` object of the Editor or an object containing editor's `doc` and `schema`
     *&mdash;{ doc: value, schema: value.types.schema } where the `value` variable is the editor's value prop.
     * @returns {string}&mdash;The HTML content.
     */
    function getHtml(state) {
        return getHtmlCommon(state);
    }
    EditorUtils.getHtml = getHtml;
    /**
     * @returns {string[]}&mdash;An array of matched styles that are found in the selection.
     */
    function getInlineStyles(state, style) {
        return getInlineStylesCommon(state, style);
    }
    EditorUtils.getInlineStyles = getInlineStyles;
    /**
     * Returns a mark of the specified type from the nodes in selection.
     *
     * @returns {Mark}
     */
    function getMark(state, markType) {
        return getMarkCommon(state, markType);
    }
    EditorUtils.getMark = getMark;
    /**
     * Checks if according to the specified `InlineFormatOptions` a node in the selection is present.
     *
     * @returns {boolean}
     */
    function hasMark(state, options) {
        return hasMarkCommon(state, options);
    }
    EditorUtils.hasMark = hasMark;
    /**
     * Checks if the selection contains a specific type of node.
     *
     * @returns {boolean}
     */
    function hasNode(state, nodeType) {
        return hasNodeCommon(state, nodeType);
    }
    EditorUtils.hasNode = hasNode;
    /**
     * Indents the block elements in the selection.
     *
     * @returns {boolean}&mdash;If indentation is applied to any of the elements, returns `true`.
     */
    function indentBlocks(view, actions, command, dir) {
        return Boolean(indentBlocksCommon(actions, command, dir)(view.state, view.dispatch));
    }
    EditorUtils.indentBlocks = indentBlocks;
    /**
     * Adds new lines after block elements and hard breaks.
     *
     * @param {string} content&mdash;The HTML content.
     * @returns {string}&mdash;The indented HTML.
     */
    function indentHtml(content) {
        return indentHtmlCommon(content);
    }
    EditorUtils.indentHtml = indentHtml;
    /**
     * Inserts a node in the selection.
     *
     * @param {EditorView} view&mdash;The `view` object of the Editor.
     * @param {Node} node&mdash;A `node` object of the Editor.
     * @param {boolean} scrollIntoView&mdash;An optional parameter.
     * Defines if the content element will be scrolled to the current selection.
     */
    function insertNode(view, node, scrollIntoView) {
        insertNodeCommon(node, scrollIntoView)(view.state, view.dispatch);
    }
    EditorUtils.insertNode = insertNode;
    /**
     * Checks if any of the block elements in the selection is aligned.
     *
     * @returns {boolean}
     */
    function isAligned(state, actions) {
        return isAlignedCommon(state, actions);
    }
    EditorUtils.isAligned = isAligned;
    /**
     * Checks if any of the block elements in the selection is indented.
     *
     * @returns {boolean}
     */
    function isIndented(state, actions, dir) {
        return isIndentedCommon(state, actions, dir);
    }
    EditorUtils.isIndented = isIndented;
    /**
     * Removes the comments from the HTML.
     *
     * @param {string} html&mdash;The input HTML.
     * @returns {string}&mdash;The result HTML.
     *
     * @example
     * ```jsx-no-run
     * import { EditorUtils } from '@progress/kendo-react-editor';
     * const html = EditorUtils.removeComments('<p>some content<!-- comment --></p>');
     * ```
     */
    function removeComments(html) {
        return removeCommentsCommon(html);
    }
    EditorUtils.removeComments = removeComments;
    /**
     * Removes the specified tag from the HTML and keeps its child nodes.
     *
     * @param {string} html&mdash;The input HTML.
     * @param {string} tag&mdash;A tag or multiple tags separated by a vertical slash which will be removed.
     * For example, `span` or `b|i|u|span`.
     * @returns {string}&mdash;The resulting HTML.
     *
     * @example
     * ```jsx-no-run
     * import { EditorUtils } from '@progress/kendo-react-editor';
     * const html = EditorUtils.removeTag('<p>some <span>content</span></p>', 'span|p');
     * ```
     */
    function removeTag(html, tag) {
        return removeTagCommon(html, tag);
    }
    EditorUtils.removeTag = removeTag;
    /**
     * A function for sanitizing the content on paste ([see example]({% slug paste_editor %})).
     *
     * @param {string} html&mdash;The input HTML.
     * @param {PasteCleanupSettings} settings&mdash;The settings used for sanitizing the content.
     * @returns {string}&mdash;The resulting HTML.
     */
    function pasteCleanup(html, settings) {
        return pasteCleanupCommon(html, settings);
    }
    EditorUtils.pasteCleanup = pasteCleanup;
    /**
     * A function for sanitizing the CSS classes of the pasted from MS Word content ([see example]({% slug paste_editor %})).
     * The function will remove any class attribute which value starts with `Mso`.
     * For example `<p class="MsoNormal">pasted from MS Word</p>` will result in `<p>pasted from MS Word</p>`.
     *
     * @param {Attr} attr&mdash;The DOM class attribute that will be sanitized.
     */
    function sanitizeClassAttr(attr) {
        sanitizeClassAttrCommon(attr);
    }
    EditorUtils.sanitizeClassAttr = sanitizeClassAttr;
    /**
     * A function for sanitizing the style attributes of the pasted from MS Word content ([see example]({% slug paste_editor %})).
     * The function will loop through all styles and will remove those that are invalid.
     * For example `<p><span style='color:#7C7C7C;mso-themecolor:accent3;mso-themeshade:191;background:silver;'>content</span></p>`,
     * will result in `<p><span style="color: #7C7C7C; background: silver;">content</span></p>`.
     *
     * @param {Attr} attr&mdash;The DOM style attribute that will be sanitized.
     */
    function sanitizeStyleAttr(attr) {
        sanitizeStyleAttrCommon(attr);
    }
    EditorUtils.sanitizeStyleAttr = sanitizeStyleAttr;
    /**
     * A function that will remove a DOM attribute from the pasted content ([see example]({% slug paste_editor %})).
     *
     * @param {Attr} attr&mdash;The DOM attribute that will be removed.
     */
    function removeAttribute(attr) {
        removeAttributeCommon(attr);
    }
    EditorUtils.removeAttribute = removeAttribute;
    /**
     * Removes the invalid HTML.
     *
     * @param {string} html&mdash;The HTML which will be sanitized.
     * @returns {string}&mdash;The sanitized HTML.
     *
     * @example
     * ```jsx-no-run
     * import { EditorUtils } from '@progress/kendo-react-editor';
     * const html = EditorUtils.sanitize('something pasted from MS Word, containing <o:p>, <w:sdtPr>, <v:shapes tags');
     * ```
     */
    function sanitize(html) {
        return sanitizeCommon(html);
    }
    EditorUtils.sanitize = sanitize;
    /**
     * If the input `html` contains images with 'src' pointing to local file system (it happens when pasting images and text from MS Word),
     * the function will extract the images sources from the RTF in base64 format and replace them in the input `html`.
     *
     * @param html&mdash;The input HTML (pasted HTML).
     * @param clipboardData&mdash;The paste event clipboardData object (event.clipboardData).
     * @returns&mdash;The html with the replaced images sources.
     */
    function replaceImageSourcesFromRtf(html, clipboardData) {
        return replaceImageSourcesFromRtfCommon(html, clipboardData);
    }
    EditorUtils.replaceImageSourcesFromRtf = replaceImageSourcesFromRtf;
    /**
     * Creates a plugin which highlights the matches of Find and Replace dialog.
     *
     * @param {PluginKey} key&mdash;The key of the plugin (Optional).
     * @returns {Plugin}&mdash;The text highlight plugin.
     */
    function textHighlight(key) {
        return textHighlightCommon(key);
    }
    EditorUtils.textHighlight = textHighlight;
    /**
     * Creates a plugin which adds image resizing functionality.
     *
     * @param {ImageResizeOptions} options&mdash;The resizing option (Optional).
     * @returns {Plugin}&mdash;The image resizing plugin.
     */
    function imageResizing(options) {
        return imageResizingCommon(options);
    }
    EditorUtils.imageResizing = imageResizing;
    /**
     * Returns a collection of plugins that adds table resizing functionality.
     *
     * @returns {Plugin[]}&mdash;The table resizing plugins.
     */
    function tableResizing() {
        return tableResizingCommon();
    }
    EditorUtils.tableResizing = tableResizing;
    /**
     * Sets the HTML to the `EditorView`.
     *
     * @param {EditorView} view&mdash;The `view` object of the Editor.
     * @param {string} html&mdash;The HTML content.
     * @param {Command} command&mdash;An optional parameter.
     * Defines the type of the command that will be set to the `setHtml` metadata of the transaction.
     * Defaults to `SetContent`.
     * @param {ParseOptions} parseOptions&mdash;An optional parameter.
     * Defines the options that will be used for parsing the HTML. Defaults to `{ preserveWhitespace: 'full' }`.
     */
    function setHtml(view, html, command, parseOptions) {
        var trimmed = trimWhitespace(html);
        setHtmlCommon(trimmed, command, parseOptions)(view.state, view.dispatch);
    }
    EditorUtils.setHtml = setHtml;
    /**
     * Toggles the inline element formatting according to the `InlineFormatOptions` and `markAttrs` settings.
     *
     * @returns {boolean}
     */
    function toggleInlineFormat(view, options, transaction, markAttrs) {
        return toggleInlineFormatCommon(options, transaction, markAttrs)(view.state, view.dispatch);
    }
    EditorUtils.toggleInlineFormat = toggleInlineFormat;
    /**
     * Toggles a list of the specified type.
     *
     * @returns {boolean}
     */
    function toggleList(view, types, command) {
        return toggleListCommon(view.state, view.dispatch, view, types, command);
    }
    EditorUtils.toggleList = toggleList;
    /* eslint-disable max-len */
    /**
     * A function which returns the mapped `Shortcuts` object based on the passed settings.
     * Useful when the default Editor nodes or tool settings are changed and the `Shortcuts` object has to be regenerated.
     *
     * @params&mdash;An object which holds specific types of nodes and tool settings that are used by the default `Shortcuts` handlers.
     * @returns&mdash;An object which holds the shortcuts.
     */
    // tslint:enable:max-line-length
    function getShortcuts(settings) {
        return shortcuts.getShortcuts(settings);
    }
    EditorUtils.getShortcuts = getShortcuts;
    /**
     * The `PluginKey` used in the Editor to pass editor props to the tools.
     */
    EditorUtils.propsKey = editorPropsKey;
    /**
     * The `PluginKey` used in the Editor by the image resizing plugin.
     */
    EditorUtils.imageResizeKey = imageResizeKeyCommon;
    /**
     * Represents the `marks` object of the Editor.
     */
    EditorUtils.marks = __assign({}, schemaMarks);
    /**
     * Represents the `nodes` object of the Editor.
     */
    EditorUtils.nodes = __assign({}, schemaNodes);
})(EditorUtils || (EditorUtils = {}));
