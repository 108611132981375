"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import BaseReactComponent from "../../base_react_component";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import { getRiskScale, getRawRiskScore } from "../../../server/common/misc/common_risk_utils";
import { getCSSClassForLegend } from "../../reports/risk_map_reports/utilities/risk_map_report_helper";
import { RISK_TYPE_ENUM } from "../../helpers/constants/constants";
import MemoryCache from "../../utils/cache/memory_cache";

/**
 *  This is the base class for classes that show the title bar at the top of the screen to identify an editable record.
 **/
export default class BaseTitleBar extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextStateIgnored, nextContextIgnored) {
    return nextProps.id !== this.props.id ||
      nextProps.dataModified !== this.props.dataModified ||
      nextProps.editorOperation !== this.props.editorOperation ||
      nextProps.editorType !== this.props.editorType ||
      nextProps.parent?.id !== this.props.parent?.id ||
      nextProps.id !== this.props.id ||
      nextProps.versionId !== this.props.versionId ||
      nextProps.riskType !== this.props.riskType ||
      nextProps.currentOperation !== this.props.currentOperation ||
      nextProps.currentState !== this.props.currentState ||
      nextProps.riskType !== this.props.riskType ||
      nextProps.isLoading !== this.props.isLoading ||
      nextProps.instance?.id !== this.props.instance?.id ||
      nextProps.instance?.name !== this.props.instance?.name ||
      nextProps.effectiveRMP?.id !== this.props.effectiveRMP?.id ||
      nextProps.instance?.impact !== this.props.instance?.impact ||
      nextProps.instance?.uncertainty !== this.props.instance?.uncertainty ||
      this.getRiskScale()?.riskLabel !== this.state[this.props.riskType + "riskLabel"] ||
      JSON.stringify(nextProps.instance?.riskInfo) !== JSON.stringify(this.props.instance?.riskInfo);
  }

  getPrimaryName() {
    const {instance, instanceTypeCode, sectionName, displayName, currentOperation} = this.props;

    let primaryName = "General";

    if (!instance.typeCode) {
      instance.typeCode = instanceTypeCode;
    }

    if (instance && instance.name && instance.id) {
      const modelName = instance.modelName || UIUtils.getModelNameForTypeCode(instance.typeCode);
      const idField = UIUtils.convertToId(modelName) + "Id";
      const id = instance[idField] || instance.id;

      primaryName = UIUtils.getRecordCustomLabelForDisplay({...instance, id});
    } else if (instance && instance.name) {
      primaryName = instance.name;
    } else if (instance && instance.customID) {
      primaryName = instance.customID;
    } else if (instanceTypeCode && currentOperation === "Add") {
      primaryName = "New " + UIUtils.getModelNameForTypeCode(instanceTypeCode) + " (" + instanceTypeCode + ")"; // We're adding a new instance, and the name hasn't been typed in yet.
    } else if (displayName) {
      primaryName = displayName;
    } else if (sectionName) {
      primaryName = sectionName;
    }
    return primaryName;
  }

  getRiskLabel() {
    const {effectiveRMP, riskType, instance, currentOperation} = this.props;
    const {detailedRiskLinks} = instance;

    let riskScale;
    if (riskType && effectiveRMP) {
      if (instance.riskInfo && currentOperation !== "Add" && currentOperation !== "Edit") {
        riskScale = riskType === RISK_TYPE_ENUM.CRITICALITY ? instance.riskInfo[riskType].scaleForRiskLabel : instance.riskInfo[riskType].scale;
      } else {
        // this is used when we add a new record and have to compute risk based on the new inputs
        // this may return wrong result as it cannot take in consideration downstream links
        const rawRiskScore = getRawRiskScore(riskType, effectiveRMP, instance, detailedRiskLinks, false, true);
        riskScale = effectiveRMP && getRiskScale(riskType, effectiveRMP, rawRiskScore, instance, true, true);
      }
    }

    return riskScale
      ? <Fragment>
        {
          <div className={`list-table-risk-label list-table-risk-label-title-bar ${getCSSClassForLegend(riskScale.color)}`} />
        }
        {riskScale.riskLabel}
      </Fragment>
      : "";
  }

  getProcessName() {
    const {instance} = this.props;
    const {ProcessId, projectId, ProjectId, modelName} = instance;
    let process;
    if (modelName && modelName !== "Process") {
      if (!this.memoryCache) {
        this.memoryCache = MemoryCache.getNamedInstance("base_title_bar_process");
      }

      const cacheKey = "process_" + ProcessId;
      if (this.memoryCache.get(cacheKey)) {
        process = this.memoryCache.get(cacheKey);
      } else {
        const processes = new TypeaheadObjectCache("Process", projectId || ProjectId).getOptionsFromCache();
        process = processes.find(process => process.id === ProcessId);

        if (process) {
          this.memoryCache.set(cacheKey, process);
        }
      }
    }

    return process ? process.name : "";
  }

  componentDidUpdate(prevPropsIgnore, prevStateIgnore, snapshotIgnore) {
    const riskScale = this.getRiskScale();

    if (riskScale?.riskLabel && riskScale.riskLabel !== this.state[this.props.riskType + "riskLabel"]) {
      this.setStateSafely({
        [this.props.riskType + "riskLabel"]: riskScale.riskLabel
      });
    }
  }

  getRiskLabelComponent() {
    const riskScale = this.getRiskScale();

    return riskScale
      ? <Fragment>
        {
          <div className={`list-table-risk-label list-table-risk-label-title-bar ${getCSSClassForLegend(riskScale.color)}`} />
        }
        {riskScale.riskLabel}
      </Fragment>
      : "";
  }

  getRiskScale() {
    const {effectiveRMP, riskType, instance, currentOperation} = this.props;
    const {detailedRiskLinks} = instance;

    let riskScale;
    if (riskType && effectiveRMP) {
      if (instance.riskInfo && currentOperation !== "Add" && currentOperation !== "Edit") {
        riskScale = riskType === RISK_TYPE_ENUM.CRITICALITY ? instance.riskInfo[riskType].scaleForRiskLabel : instance.riskInfo[riskType].scale;
      } else {
        // this is used when we add a new record and have to compute risk based on the new inputs
        // this may return wrong result as it cannot take in consideration downstream links
        const rawRiskScore = getRawRiskScore(riskType, effectiveRMP, instance, detailedRiskLinks, false, true);
        riskScale = effectiveRMP && getRiskScale(riskType, effectiveRMP, rawRiskScore, instance, true, true);
      }
    }

    return riskScale;
  }
}
