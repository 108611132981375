var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { Popup } from '@progress/kendo-react-popup';
import { useLocalization } from '@progress/kendo-react-intl';
import { isCompositeFilterDescriptor } from '@progress/kendo-data-query';
import { ColumnMenuItem } from './ColumnMenuItem';
import { ColumnMenuTextFilter, ColumnMenuNumericFilter, ColumnMenuDateFilter, ColumnMenuBooleanFilter } from './ColumnMenuFilters';
import { ColumnMenuFilterLogic } from './ColumnMenuFilterLogic';
import { ColumnMenuFilterForm } from './ColumnMenuFilterForm';
import { messages, columnMenuFilterTitle, columnMenuSortAscending, columnMenuSortDescending } from '../messages';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
/** @hidden */
var ColumnMenuWrapper = function (props) {
    validatePackage(packageMetadata);
    var emptySort = React.useRef([]);
    var field = props.field, filter = props.filter, _a = props.sort, sort = _a === void 0 ? emptySort.current : _a, onSortChange = props.onSortChange, onFilterChange = props.onFilterChange, initialFilter = props.initialFilter, _b = props.sortAsc, sortAsc = _b === void 0 ? false : _b, _c = props.sortDesc, sortDesc = _c === void 0 ? false : _c, _d = props.filterContent, filterContent = _d === void 0 ? [] : _d, _e = props.expandFilters, expandFilters = _e === void 0 ? false : _e;
    var _f = React.useState(false), show = _f[0], setShow = _f[1];
    var _g = React.useState(expandFilters), filterExpand = _g[0], setFilterExpand = _g[1];
    var _h = React.useState(field && initialFilter ? initialFilter(field) : null), currentFilter = _h[0], setCurrentFilter = _h[1];
    var anchor = React.useRef(null);
    var popupRef = React.useRef(null);
    var blurTimeout = React.useRef(null);
    var willBlur = React.useRef(false);
    var menuFilter = React.useCallback(function () {
        return (filter || []).find(function (f) { return f.filters.every(function (inF) { return !isCompositeFilterDescriptor(inF) && inF.field === field; }); });
    }, [filter, field]);
    var closeMenu = React.useCallback(function () {
        setShow(false);
    }, []);
    var onBlur = React.useCallback(function () {
        if (willBlur.current) {
            willBlur.current = false;
            return;
        }
        clearTimeout(blurTimeout.current);
        blurTimeout.current = window.setTimeout(function () { closeMenu(); });
    }, [closeMenu]);
    var onFocus = React.useCallback(function () { return clearTimeout(blurTimeout.current); }, []);
    var onAnchorMouseDown = React.useCallback(function (event) {
        willBlur.current = show && event.currentTarget === anchor.current;
    }, [show]);
    var onColumnMenuButtonClick = React.useCallback(function () {
        setShow(!show);
        if (field && initialFilter) {
            setFilterExpand(!show ? expandFilters : false);
            setCurrentFilter(show ?
                currentFilter :
                (menuFilter() || initialFilter(field)));
        }
    }, [menuFilter, field, show, currentFilter, initialFilter, expandFilters]);
    React.useEffect(function () {
        if (show && popupRef.current) {
            popupRef.current.focus();
        }
    }, [show]);
    var filterItemClick = React.useCallback(function () { return setFilterExpand(!filterExpand); }, [filterExpand]);
    var changeSort = React.useCallback(function (event, dir) {
        var newSort = __spreadArray([], sort, true);
        var currentSortIndex = newSort.findIndex(function (d) { return d.field === field; });
        var newSortDescriptor = { field: field, dir: dir };
        if (currentSortIndex >= 0) {
            if (newSort[currentSortIndex].dir !== newSortDescriptor.dir) {
                newSort.splice(currentSortIndex, 1, newSortDescriptor);
            }
            else {
                newSort.splice(currentSortIndex, 1);
            }
        }
        else {
            newSort.push(newSortDescriptor);
        }
        closeMenu();
        if (onSortChange) {
            onSortChange.call(undefined, event, newSort, field);
        }
    }, [sort, field, onSortChange, closeMenu]);
    var filterChange = React.useCallback(function (_event, nextFilter, prevFilter) {
        var stateFilter = __assign(__assign({}, currentFilter), { filters: __spreadArray([], currentFilter.filters, true) });
        var index = stateFilter.filters.indexOf(prevFilter);
        stateFilter.filters.splice(index, 1, nextFilter);
        setCurrentFilter(stateFilter);
    }, [currentFilter]);
    var onFilterSubmit = React.useCallback(function (event) {
        event.preventDefault();
        var newFilter = (filter || []).slice();
        var prev = menuFilter();
        var index = prev ? newFilter.indexOf(prev) : -1;
        if (index >= 0) {
            newFilter.splice(index, 1, currentFilter);
        }
        else {
            newFilter.push(currentFilter);
        }
        closeMenu();
        if (onFilterChange) {
            onFilterChange.call(undefined, event, newFilter, field);
        }
    }, [filter, currentFilter, field, onFilterChange, closeMenu, menuFilter]);
    var onFilterReset = React.useCallback(function (event) {
        var newFilter = (filter || []).slice();
        var prev = menuFilter();
        var index = prev ? newFilter.indexOf(prev) : -1;
        if (index >= 0) {
            newFilter.splice(index, 1);
        }
        if (initialFilter) {
            setCurrentFilter(initialFilter(field));
        }
        closeMenu();
        if (onFilterChange) {
            onFilterChange.call(undefined, event, newFilter, field);
        }
    }, [filter, field, onFilterChange, initialFilter, closeMenu, menuFilter]);
    var sortAscChange = React.useCallback(function (e) { return changeSort(e, 'asc'); }, [changeSort]);
    var sortDescChange = React.useCallback(function (e) { return changeSort(e, 'desc'); }, [changeSort]);
    var onLogicChange = React.useCallback(function (event) { return currentFilter && setCurrentFilter(__assign(__assign({}, currentFilter), { logic: event.value.logic })); }, [currentFilter]);
    var currentSort = sort.find(function (d) { return d.field === field; }) || { dir: '' };
    var FirstFilterComponent = filterContent[0], FilterLogicComponent = filterContent[1], SecondFilterComponent = filterContent[2];
    var localization = useLocalization();
    var sorting = (sortAsc || sortDesc) ? (React.createElement("div", { className: "k-columnmenu-item-wrapper", key: "sorting" },
        sortAsc &&
            (React.createElement(ColumnMenuItem, { title: localization.toLanguageString(columnMenuSortAscending, messages[columnMenuSortAscending]), iconClass: "k-i-sort-asc-sm", selected: currentSort.dir === 'asc', onClick: sortAscChange })),
        sortDesc &&
            (React.createElement(ColumnMenuItem, { title: localization.toLanguageString(columnMenuSortDescending, messages[columnMenuSortDescending]), iconClass: "k-i-sort-desc-sm", selected: currentSort.dir === 'desc', onClick: sortDescChange })))) : null;
    // filter content empty when no field is supplied
    var filtering = currentFilter && filterContent.length > 0 ? (React.createElement("div", { className: "k-columnmenu-item-wrapper", key: "filtering" },
        React.createElement(ColumnMenuItem, { iconClass: "k-i-filter", title: localization.toLanguageString(columnMenuFilterTitle, messages[columnMenuFilterTitle]), onClick: filterItemClick }),
        React.createElement(ColumnMenuFilterForm, { show: filterExpand, onSubmit: onFilterSubmit, onReset: onFilterReset },
            FirstFilterComponent &&
                React.createElement(FirstFilterComponent, { filter: currentFilter.filters[0], onFilterChange: filterChange }),
            FilterLogicComponent &&
                React.createElement(FilterLogicComponent, { value: currentFilter.logic, onChange: onLogicChange }),
            SecondFilterComponent &&
                React.createElement(SecondFilterComponent, { filter: currentFilter.filters[1], onFilterChange: filterChange })))) : null;
    var menuItems = [sorting, filtering];
    var button = (React.createElement("span", { ref: anchor, onClick: onColumnMenuButtonClick, onMouseDown: onAnchorMouseDown, className: 'k-grid-header-menu k-grid-column-menu' + (Boolean(menuFilter()) ? ' k-active' : ''), key: "button" },
        React.createElement("span", { className: "k-icon k-i-more-vertical" })));
    var popup = (React.createElement(Popup, { anchor: anchor.current, show: show, key: "popup" },
        React.createElement("div", { className: "k-grid-columnmenu-popup", ref: popupRef, tabIndex: 0, onBlur: onBlur, onFocus: onFocus, style: { outline: 'none' } }, props.itemsRender ?
            props.itemsRender.call(undefined, menuItems, { closeMenu: closeMenu, field: field }) :
            menuItems)));
    var rendering = [button, popup];
    return React.createElement(React.Fragment, null, props.render ? props.render.call(undefined, rendering) : rendering);
};
/**
 * The `ColumnMenuTextColumn` component. Use it for `string` type columns.
 */
export var ColumnMenuTextColumn = function (props) {
    var field = props.field, others = __rest(props, ["field"]);
    var initialFilter = React.useCallback(function (currentField) {
        return ({ logic: 'and', filters: [{ operator: 'contains', field: currentField, value: '' }, { operator: 'contains', field: currentField, value: '' }] });
    }, []);
    var hasField = Boolean(props.field);
    var filterContent = hasField ? [
        ColumnMenuTextFilter,
        ColumnMenuFilterLogic,
        ColumnMenuTextFilter
    ] : [];
    return (React.createElement(ColumnMenuWrapper, __assign({ sortAsc: true && hasField, sortDesc: true && hasField, filterContent: filterContent, initialFilter: hasField ? initialFilter : undefined, field: field }, others)));
};
/**
 * The `ColumnMenuNumericColumn` component. Use it for `number` type columns.
 */
export var ColumnMenuNumericColumn = function (props) {
    var field = props.field, others = __rest(props, ["field"]);
    var initialFilter = React.useCallback(function (currentField) {
        return ({ logic: 'or', filters: [{ operator: 'eq', field: currentField, value: null }, { operator: 'eq', field: currentField, value: null }] });
    }, []);
    var hasField = Boolean(props.field);
    var filterContent = hasField ? [
        ColumnMenuNumericFilter,
        ColumnMenuFilterLogic,
        ColumnMenuNumericFilter
    ] : [];
    return (React.createElement(ColumnMenuWrapper, __assign({ sortAsc: true && hasField, sortDesc: true && hasField, filterContent: filterContent, initialFilter: hasField ? initialFilter : undefined, field: field }, others)));
};
/**
 * The `ColumnMenuDateColumn` component. Use it for `Date` type columns.
 */
export var ColumnMenuDateColumn = function (props) {
    var field = props.field, others = __rest(props, ["field"]);
    var initialFilter = React.useCallback(function (currentField) {
        return ({ logic: 'or', filters: [{ operator: 'eq', field: currentField, value: null }, { operator: 'eq', field: currentField, value: null }] });
    }, []);
    var hasField = Boolean(props.field);
    var filterContent = hasField ? [
        ColumnMenuDateFilter,
        ColumnMenuFilterLogic,
        ColumnMenuDateFilter
    ] : [];
    return (React.createElement(ColumnMenuWrapper, __assign({ sortAsc: true && hasField, sortDesc: true && hasField, filterContent: filterContent, initialFilter: hasField ? initialFilter : undefined, field: field }, others)));
};
/**
 * The `ColumnMenuBooleanColumn` component. Use it for `boolean` type columns.
 */
export var ColumnMenuBooleanColumn = function (props) {
    var field = props.field, others = __rest(props, ["field"]);
    var initialFilter = React.useCallback(function (currentField) {
        return ({ logic: 'and', filters: [{ operator: 'eq', field: currentField, value: false }] });
    }, []);
    var hasField = Boolean(props.field);
    var filterContent = hasField ? [
        ColumnMenuBooleanFilter
    ] : [];
    return (React.createElement(ColumnMenuWrapper, __assign({ sortAsc: true && hasField, sortDesc: true && hasField, filterContent: filterContent, initialFilter: hasField ? initialFilter : undefined, field: field }, others)));
};
