"use strict";
import { RiskTablesTransformStrategy } from "./risk_tables_transform_strategy";
import { RISK_TABLE_REPORT_TO_RMP_ENUM } from "../../../../constants/report_constants";

/**
 * This class is responsible for Material Attributes - Intermediate / Final Attributes Risk Tables strategy
 */
export class MAToXQARiskTableTransformStrategy extends RiskTablesTransformStrategy {
  /**
   * This sets result instances in a prop name coming from this function
   * @returns {string}
   */
  get parentReportType() {
    return "XQAs_to_MAs";
  }

  get modelType() {
    return RISK_TABLE_REPORT_TO_RMP_ENUM[this.parentReportType];
  }
}
