"use strict";

import { FIELD_PROPS } from "../constants/configurable_tables_field_props_config";
import { ABOUT_COL_SPAN } from "../constants/configurable_tables_col_spans_config";
import { ABOUT_SECTION } from "../constants/configurable_tables_sections_config";
import { generateDocumentsValue, generateVersionValue } from "../../export/configurable_tables_export_value_generator";
import RecordFields from "../record_fields";
import { FILTER_TYPES } from "../../columnOperations/configurable_tables_column_operations_constants";

// eslint-disable-next-line no-undef
export default class StepFields extends RecordFields {
  get fields() {
    return [
      // About
      {
        title: "Name",
        prop: FIELD_PROPS.NAME,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        tableValue: (columnGenerator) => columnGenerator.generateNameColumn(),
      },
      {
        title: "Version",
        prop: FIELD_PROPS.VERSION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        value: generateVersionValue,
        tableValue: (columnGenerator) => columnGenerator.generateVersionColumn("Version"),
      },
      {
        title: "Unit Operation",
        prop: FIELD_PROPS.UNIT_OPERATION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        sortByOrder: true,
        filterType: FILTER_TYPES.LOOKUP,
        value: (record) => record[FIELD_PROPS.UNIT_OPERATION_NAME],
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateParentColumn("Unit Operation", FIELD_PROPS.UNIT_OPERATION, process),
      },
      {
        title: "Previous Unit",
        prop: FIELD_PROPS.PREVIOUS_STEP,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
      },
      {
        title: "Description",
        prop: FIELD_PROPS.DESCRIPTION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        tableValue: (columnGenerator) =>
          columnGenerator.generateTextAreaColumn("Description", FIELD_PROPS.DESCRIPTION),
      },
      {
        title: "Source Documents",
        prop: FIELD_PROPS.LINKS,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        filterType: FILTER_TYPES.LINKS,
        value: generateDocumentsValue,
        tableValue: (columnGenerator) =>
          columnGenerator.generateDocumentsColumn("Source Documents", null, FIELD_PROPS.LINKS),
      }
    ];
  }
}
