"use strict";

import CommonSecurity from "../../../server/common/generic/common_security";
import { TYPE_CODE } from "../../../server/common/generic/common_constants";

export const TABLE_COLUMN_WIDTH = {
  DESCRIPTION: 350,
  ID: 75,
};

export const IMPORT_TABS_ENUM = {
  BY_TYPE: {
    title: "By Type"
  },
  PROCESS_DATA: {
    title: "Process Data"
  }
};

export const DATE_COLUMNS = [
  "startDate",
  "manufactureDate",
  "releaseDate",
  "expirationDate",
  "effectiveDate",
];

export const IMPORT_STEPS = {
  DependencySelection: "Dependency Selection",
  FileSelection: "Upload File",
  DataReview: "Review Data",
  SmartUpload: "Upload and Select",
  DataExtract: "Extract and Edit",
  DataImport: "Import",
};

/**
 * @typedef ImportConfig
 * @param isMultiProcess This is set to true when a model should be imported on the process level, i.e IQAs, IPAs, etc...
 * It is set to false when the model can be imported to the project level only, i.e Final Attributes, Control Methods etc...
 */

export const DATA_TYPES = {
  DRUG_SUBSTANCE_PRODUCT: "Drug Substance/Product",
  LIBRARY_MATERIAL: "LibraryMaterial",
  MATERIAL: "Material"
};

export const DATA_CATEGORIES = {
  RELEASE: "Release",
  MANUFACTURING: "Manufacturing",
  CROSS_BATCH_IMPORT: "Cross Batch Import",
};

export const IMPORT_TYPE_KEY = {
  PROJECT: "PROJECT",
  SUPPLIER: "SUPPLIER",
  USER: "USER",
  TPP: "TPP",
  FQA: "FQA",
  FPA: "FPA",
  UO: "UO",
  STP: "STP",
  IQA: "IQA",
  IPA: "IPA",
  MT: "MT",
  MT_MA: "MT_MA",
  PRC: "PRC",
  PRC_MA: "PRC_MA",
  PP: "PP",
  CM: "CM",
  COA: "COA",
  LIBRARY_COA: "LIBRARY_COA",
  RELEASE_DATA: "RELEASE_DATA",
  MANUFACTURING_DATA: "MANUFACTURING_DATA",
  CONTINUOUS_MANUFACTURING_DATA: "CONTINUOUS_MANUFACTURING_DATA",
  SINGLE_ATTRIBUTE_DATA: "SINGLE_ATTRIBUTE_DATA",
  LIBRARY_MATERIAL: "LIBRARY_MATERIAL"
};

/**
 * @typedef IImportConfigurationProperties
 * @property {IMPORT_TYPE_KEY} key A unique key for each import flow.
 * @property {string} modelName The model name of the records being imported by a given import flow.
 * @property {string} importButtonTitle The caption of the import button.
 * @property {boolean} showInstructions True if instructions should be displayed for the given import flow.
 * @property {string} instructions The instructions to show for the given import flow.
 * @property {string} infoDescription Any additional instructions displayed when the given import flow is disabled
 * explaining why.
 * @property {array} requiredDependencies A list of dependent record types this import flow would require to pre-exist
 * in the system, before allowing records to be imported.
 * @property {boolean} requireAnyDependency Set this to true when at least one of the required dependencies is mandatory
 * to exist in the project before enabling the import flow.
 * @property {boolean} showDownloads This controls the sample/template download links for each import flow.
 * @property {CommonSecurity.Actions} securityAction The permissions required by the user executing the import flow.
 * @property {string} supportedFileTypes The permissions required by the user executing the import flow.
 * @property {boolean} isOptional This displays the optional label below some import flow. It is set to true when the
 * specific import flow is not mandatory for the rest of the flows to be enabled.
 * @property {boolean} isMultiProcess This is true for import flows which can import data across multiple processes.
 * @property {boolean} isMultiProcess This is true for import flows which can import data across multiple processes.
 * @property {boolean} isPaperImport True for the CoA import only.
 * @property {boolean} importDataForDependency True if the user should be selecting from parent project dependency
 * record for generating an import template. I.e in some import flows the user is requested to pich one or more UOs
 * and the generated import template contains records for those UOs. In these cases set this to true.
 * @property {boolean} singleDependencySelection Set this to true when a single record can be selected only when
 * generating an import template.
 * @property {string} supportedFileTypes The file types the user can upload for the given import flow.
 * @property {string} importTemplateFileName The import template file name used for generating an import file for the user.
 * @property {DATA_TYPES} dataType It can be either a Drug substance/project or a Material. Used to differentiate the
 * type of data being imported between batch data and CoA data.
 * @property {DATA_CATEGORIES} dataCategory The type of data being imported from a given import flow. It can be release,
 * manufacturing or both release & manufacturing data.
 * @property {boolean} showDataCategory Set this to true when you want the data category to show up in the import data
 * card displayed when the user selects to import data directly from the batch record.a
 * @property {boolean} hasProjectTypeDependedTemplates Set this to true when the import template depends on the project
 * type for which data are imported by the given import flow.
 */

const COMMON_IMPORT_BY_TYPE_PROPS = {
  showDownloads: true,
  securityAction: CommonSecurity.Actions.IMPORT,
  supportedFileTypes: "Excel",
};

const COMMON_IMPORT_PROCESS_DATA_PROPS = {
  showDownloads: false,
  isOptional: false,
  dataSectionName: "Process Capability",
  securityAction: CommonSecurity.Actions.EDIT,
  isMultiProcess: true,
};

export const NON_PROCESS_RELATED_MODELS = ["Supplier", "User", "LibraryMaterial"];

export const IMPORT_TYPES = {
  PROJECTS: {
    key: IMPORT_TYPE_KEY.PROJECT,
    title: "Projects",
    modelName: "Project",
    importButtonTitle: "Import Project Records",
    showDownloads: false,
    showInstructions: true,
    importProjectData: true,
    securityAction: CommonSecurity.Actions.EDIT,
    instructions: "Import records for an existing project such as TPPs, FQAs, etc."
  },
  USERS: {
    key: IMPORT_TYPE_KEY.USER,
    title: "Users",
    modelName: "User",
    importButtonTitle: "Import Users",
    showDownloads: true,
    showInstructions: true,
    importProjectData: false,
    securityAction: CommonSecurity.Actions.IMPORT,
    instructions: "Use this to create or update multiple users from an Excel (.xls/.xlsx) file."
  },
  SUPPLIERS: {
    key: IMPORT_TYPE_KEY.SUPPLIER,
    title: "Suppliers",
    modelName: "Supplier",
    importButtonTitle: "Import Suppliers",
    showDownloads: true,
    showInstructions: true,
    importProjectData: false,
    securityAction: CommonSecurity.Actions.IMPORT,
    instructions: "Use this to create or update multiple suppliers from an Excel (.xls/.xlsx) file."
  },
  LIBRARY_MATERIAL: {
    key: IMPORT_TYPE_KEY.LIBRARY_MATERIAL,
    title: "Library Materials",
    modelName: "Library Material",
    importButtonTitle: "Import Library Materials",
    showDownloads: true,
    showInstructions: true,
    importProjectData: false,
    isSystem: true,
    securityAction: CommonSecurity.Actions.IMPORT,
    instructions: "Use this to create or update multiple library Materials from an Excel (.xls/.xlsx) file."
  },
  BY_TYPE: [
    {
      key: IMPORT_TYPE_KEY.TPP,
      ...COMMON_IMPORT_BY_TYPE_PROPS,
      isOptional: true,
      modelName: "TPP",
      requiredDependencies: [],
      infoDescription: null,
      dependency: null,
      title: "Target Product Profiles (TPPs)",
      isMultiProcess: false,
    },
    {
      key: IMPORT_TYPE_KEY.FQA,
      ...COMMON_IMPORT_BY_TYPE_PROPS,
      isOptional: false,
      modelName: "FQA",
      requiredDependencies: [],
      infoDescription: null,
      dependency: null,
      title: "Final Quality Attributes (FQAs)",
      isMultiProcess: false,
      hasProjectTypeDependedTemplates: true,
    },
    {
      key: IMPORT_TYPE_KEY.FPA,
      ...COMMON_IMPORT_BY_TYPE_PROPS,
      isOptional: false,
      modelName: "FPA",
      requiredDependencies: [],
      infoDescription: null,
      dependency: null,
      title: "Final Performance Attributes (FPAs)",
      isMultiProcess: false,
      hasProjectTypeDependedTemplates: true,
      notIncludedProjectType: true
    },
    {
      key: IMPORT_TYPE_KEY.UO,
      ...COMMON_IMPORT_BY_TYPE_PROPS,
      isOptional: false,
      modelName: "Unit Operation",
      requiredDependencies: [],
      infoDescription: null,
      dependency: null,
      title: "Unit Operations (UOs)",
      isMultiProcess: true,
    },
    {
      key: IMPORT_TYPE_KEY.STP,
      ...COMMON_IMPORT_BY_TYPE_PROPS,
      isOptional: false,
      modelName: "Step",
      requiredDependencies: ["UnitOperation"],
      infoDescription: "Add UOs before this step.",
      dependency: null,
      title: "Steps (STPs)",
      isMultiProcess: true,
    },
    {
      key: IMPORT_TYPE_KEY.IQA,
      ...COMMON_IMPORT_BY_TYPE_PROPS,
      isOptional: false,
      modelName: "IQA",
      requiredDependencies: ["UnitOperation"],
      infoDescription: "Add UOs before this step.",
      dependency: null,
      title: "Intermediate Quality Attributes (IQAs)",
      isMultiProcess: true,
    },
    {
      key: IMPORT_TYPE_KEY.IPA,
      ...COMMON_IMPORT_BY_TYPE_PROPS,
      isOptional: false,
      modelName: "IPA",
      requiredDependencies: ["UnitOperation"],
      infoDescription: "Add UOs before this step.",
      dependency: null,
      title: "Intermediate Performance Attributes (IPAs)",
      isMultiProcess: true,
    },
    {
      key: IMPORT_TYPE_KEY.MT,
      ...COMMON_IMPORT_BY_TYPE_PROPS,
      isOptional: false,
      modelName: "Material",
      requiredDependencies: ["UnitOperation"],
      infoDescription: "Add UOs before this step.",
      dependency: null,
      title: "Materials (MTs)",
      isMultiProcess: true,
      hasProjectTypeDependedTemplates: true,
    },
    {
      key: IMPORT_TYPE_KEY.MT_MA,
      ...COMMON_IMPORT_BY_TYPE_PROPS,
      isOptional: false,
      modelName: "Material Attribute",
      requiredDependencies: ["UnitOperation"],
      infoDescription: "Add UOs before this step.",
      dependency: "Materials",
      title: "Material Attributes for Materials (MAs)",
      isMultiProcess: true,
    },
    {
      key: IMPORT_TYPE_KEY.PRC,
      ...COMMON_IMPORT_BY_TYPE_PROPS,
      isOptional: false,
      modelName: "Process Component",
      requiredDependencies: ["UnitOperation"],
      infoDescription: "Add UOs before this step.",
      dependency: null,
      title: "Process Components (PRCs)",
      isMultiProcess: true,
    },
    {
      key: IMPORT_TYPE_KEY.PRC_MA,
      ...COMMON_IMPORT_BY_TYPE_PROPS,
      isOptional: false,
      modelName: "Material Attribute",
      requiredDependencies: ["UnitOperation"],
      infoDescription: "Add UOs before this step.",
      dependency: "Process Components",
      title: "Material Attributes for Process Components (MAs)",
      isMultiProcess: true,
    },
    {
      key: IMPORT_TYPE_KEY.PP,
      ...COMMON_IMPORT_BY_TYPE_PROPS,
      isOptional: false,
      modelName: "Process Parameter",
      requiredDependencies: ["UnitOperation"],
      infoDescription: "Add UOs before this step.",
      dependency: null,
      title: "Process Parameters (PPs)",
      isMultiProcess: true,
    },
    {
      key: IMPORT_TYPE_KEY.CM,
      ...COMMON_IMPORT_BY_TYPE_PROPS,
      isOptional: false,
      modelName: "Control Method",
      requiredDependencies: [],
      infoDescription: null,
      dependency: null,
      title: "Control Methods (CMs)",
      isMultiProcess: false,
    }
  ],
  PROCESS_DATA: [
    {
      key: IMPORT_TYPE_KEY.LIBRARY_COA,
      ...COMMON_IMPORT_PROCESS_DATA_PROPS,
      isPaperImport: true,
      isLibrary: true,
      modelName: "Specification",
      requiredDependencies: ["LibraryMaterial"],
      dependency: "LibraryMaterial",
      importDataForDependency: false,
      singleDependencySelection: true,
      infoDescription: "Add specifications before this step",
      selectionTableModelTypes: ["LibraryMaterial"],
      supportedFileTypes: "PDF / PNG / JPG",
      instructions: "Directly scan and ingest data by uploading a Certificate of Analysis review.",
      importButtonLabel: "COA Import",
      title: "Material Specification Data (Single Lot/Batch)",
      dataType: DATA_TYPES.LIBRARY_MATERIAL,
    },
    {
      key: IMPORT_TYPE_KEY.COA,
      ...COMMON_IMPORT_PROCESS_DATA_PROPS,
      isPaperImport: true,
      modelName: "Material Attribute",
      requiredDependencies: ["Material"],
      dependency: "Material",
      deprecatedWhenLibraryMaterialIsActive: true,
      importDataForDependency: false,
      singleDependencySelection: true,
      infoDescription: "Add MTs before this step",
      selectionTableModelTypes: ["Material"],
      supportedFileTypes: "PDF / PNG / JPG",
      instructions: "Directly scan and ingest data by uploading a Certificate of Analysis review.",
      importButtonLabel: "COA Import",
      title: "Material Attribute Data (Single Lot/Batch)",
      dataType: DATA_TYPES.MATERIAL,
    },
    {
      key: IMPORT_TYPE_KEY.RELEASE_DATA,
      ...COMMON_IMPORT_PROCESS_DATA_PROPS,
      isPaperImport: false,
      modelName: "FQA",
      requiredDependencies: ["FQA", "FPA"],
      requireAnyDependency: true,
      dependency: "FQA",
      importDataForDependency: true,
      singleDependencySelection: false,
      infoDescription: "Add FQAs or FPAs before this step.",
      selectionTableModelTypes: ["FQA", "FPA"],
      supportedFileTypes: "Excel",
      importTemplateFileName: "Batch_Generic_Template.xlsx",
      instructions: "For a selected batch, upload data across multiple Final Attributes using a generated Excel template.",
      importButtonLabel: "Excel Import",
      isMultiProcess: false,
      title: "Final Attribute Data (Single Lot/Batch)",
      dataType: DATA_TYPES.DRUG_SUBSTANCE_PRODUCT,
      dataCategory: DATA_CATEGORIES.RELEASE,
      showDataCategory: true,
    },
    {
      key: IMPORT_TYPE_KEY.MANUFACTURING_DATA,
      ...COMMON_IMPORT_PROCESS_DATA_PROPS,
      isPaperImport: false,
      modelName: "Unit Operation",
      requiredDependencies: ["UOAttributes"],
      dependency: "Unit Operation",
      importDataForDependency: false,
      singleDependencySelection: false,
      infoDescription: "Add UOs with at least one IQA, IPA, MA, or PP before this step.",
      selectionTableModelTypes: ["UnitOperation"],
      importTemplateModelTypes: ["IQA", "IPA", "MaterialAttribute", "ProcessParameter"],
      supportedFileTypes: "Excel",
      importTemplateFileName: "Batch_UOs_Template.xlsx",
      instructions: "For one or more Unit Operations, upload data for Intermediate & Material Attributes, "
        + "and Process Parameters using a generated Excel template.",
      hideBorder: true,
      importButtonLabel: "Excel Import",
      title: "Unit Operation Data (Single Lot/Batch)",
      dataType: DATA_TYPES.DRUG_SUBSTANCE_PRODUCT,
      dataCategory: DATA_CATEGORIES.MANUFACTURING,
      showDataCategory: true,
    },
    {
      key: IMPORT_TYPE_KEY.CONTINUOUS_MANUFACTURING_DATA,
      ...COMMON_IMPORT_PROCESS_DATA_PROPS,
      isPaperImport: false,
      modelName: "Multiple Attributes",
      requiredDependencies: ["UOAttributes"],
      dependency: "Multiple Attributes",
      importDataForDependency: true,
      singleDependencySelection: false,
      infoDescription: "Add UOs with at least one IQA, IPA, MA, or PP before this step.",
      selectionTableModelTypes: ["IQA", "IPA", "MaterialAttribute", "ProcessParameter"],
      supportedFileTypes: "Excel",
      importTemplateFileName: "Batch_Template_Continuous_Data.xlsx",
      instructions: "Upload data for Continuous variables using a generated Excel template.",
      importButtonLabel: "Excel Import",
      importContinuousData: true,
      hideTitle: true,
      title: "Unit Operation Continuous Data (Single Lot/Batch)",
      dataType: DATA_TYPES.DRUG_SUBSTANCE_PRODUCT,
      dataCategory: DATA_CATEGORIES.MANUFACTURING,
      showDataCategory: false,
    },
    {
      key: IMPORT_TYPE_KEY.SINGLE_ATTRIBUTE_DATA,
      ...COMMON_IMPORT_PROCESS_DATA_PROPS,
      isPaperImport: false,
      modelName: "Attribute",
      requiredDependencies: ["Attribute"],
      dependency: "Attribute",
      importDataForDependency: true,
      singleDependencySelection: true,
      infoDescription: "Add FQAs, FPAs, IQAs, IPAs, MAs or PPs before this step",
      selectionTableModelTypes: ["FQA", "FPA", "IQA", "IPA", "MaterialAttribute", "ProcessParameter"],
      supportedFileTypes: "Excel",
      importTemplateFileName: "Batches_SingleAttribute_Template.xlsx",
      instructions: "Upload multiple batches for a single attribute using a generated Excel template.",
      importButtonLabel: "Excel Import",
      title: "Single Attribute/Parameter Data (Multiple Lots/Batches)",
      dataType: DATA_TYPES.DRUG_SUBSTANCE_PRODUCT,
      dataCategory: DATA_CATEGORIES.CROSS_BATCH_IMPORT,
      showDataCategory: true,
    },
  ]
};

/**
 * This returns batch an import config given the config import key.
 * @param importKey The import key for a set of import flow configuration options.
 */
module.exports.getImportConfigFromKey = function(importKey) {
  switch (importKey) {
    case IMPORT_TYPE_KEY.PROJECT:
      return IMPORT_TYPES.PROJECTS;
    case IMPORT_TYPE_KEY.SUPPLIER:
      return IMPORT_TYPES.SUPPLIERS;
    case IMPORT_TYPE_KEY.LIBRARY_MATERIAL:
      return IMPORT_TYPES.LIBRARY_MATERIAL;
    case IMPORT_TYPE_KEY.USER:
      return IMPORT_TYPES.USERS;
    default:
      return IMPORT_TYPES.BY_TYPE.find(config => config.key === importKey)
        || IMPORT_TYPES.PROCESS_DATA.find(config => config.key === importKey);
  }
};

export const TEXTRACT_CONFIDENCE_LEVELS = {
  GREY: {
    color: "grey",
    level: 99,
    label: "High confidence",
    description: "This is accurate 99% of the time.",
  },
  YELLOW: {
    color: "yellow",
    level: 80,
    label: "Medium confidence",
    description: "This is accurate 80% of the time.",
  },
  ORANGE: {
    color: "orange",
    level: 0,
    label: "Low confidence",
    description: "This is accurate less than 80% of the time.",
  }
};

export const PAPER_DATA_UPDATE_TYPE = {
  MODIFY_MEASUREMENT: "Modify Measurement",
  ADD_MEASUREMENT: "Add Measurement",
  DELETE_MEASUREMENT: "Delete Measurement",
  MODIFY_ATTRIBUTE: "Modify Attribute",
  ADD_ATTRIBUTE: "Add Attribute",
  DELETE_ATTRIBUTE: "Delete Attribute",
  MODIFY_BATCH_ID: "Modify BatchId",
  MODIFY_BATCH_START_DATE: "Modify Batch Start Date",
  MODIFY_BATCH_MANUFACTURE_DATE: "Modify Batch Manufacture Date",
  MODIFY_BATCH_RELEASE_DATE: "Modify Batch Release Date",
  MODIFY_BATCH_EXPIRATION_DATE: "Modify Batch Expiration Date",
  MODIFY_SCALE: "Modify Scale",
  MODIFY_SUPPLIER: "Modify Supplier",
};

export const SMART_IMPORT_ALLOWED_FILE_TYPES = ".pdf, .png, .jpg";
export const DOM_UPDATE_TIMEOUT = 100;

/**
 * This is used to control the maximum number of allowed pages in PDFs used in the COA import
 * @type {number}
 */
export const MAX_PDF_PAGES = 50;

/**
 * This is the maximum file size AWS Textract accepts for image size.
 * @type {number}
 */
export const MAX_IMAGE_FILE_SIZE = 10485760;

/**
 * The tags the user can use in the COA import to tag a column detected in the uploaded COA document
 * @type {{NONE: string, RESULT: string, MATERIAL_ATTRIBUTE: string}}
 */
export const COA_COLUMN_TAGS = {
  MATERIAL_ATTRIBUTE: "Material Attributes",
  RESULT: "Results",
  NONE: "None"
};

/**
 * Used in the Smart import flow to indicate if the attributes to measurements mapping used columns identified by
 Textract or just all the converted text Textract generated.
 * @type {{RESULTS_COLUMN: string, ALL_DATA: string}}
 */
export const SELECTED_CONTENT = {
  ALL_DATA: "allData",
  RESULTS_COLUMN: "resultsColumn"
};

export const TYPE_CODE_TO_IMPORT_KEY_MAPPING = {
  [TYPE_CODE.UNIT_OPERATION]: IMPORT_TYPE_KEY.UO,
  [TYPE_CODE.LIBRARY_MATERIAL]: IMPORT_TYPE_KEY.LIBRARY_MATERIAL,
  [TYPE_CODE.STEP]: IMPORT_TYPE_KEY.STP,
  [TYPE_CODE.MATERIAL_ATTRIBUTE]: IMPORT_TYPE_KEY.MT_MA,
  [TYPE_CODE.PROCESS_PARAMETER]: IMPORT_TYPE_KEY.PP,
  [TYPE_CODE.IQA]: IMPORT_TYPE_KEY.IQA,
  [TYPE_CODE.IPA]: IMPORT_TYPE_KEY.IPA,
  [TYPE_CODE.FQA]: IMPORT_TYPE_KEY.FQA,
  [TYPE_CODE.FPA]: IMPORT_TYPE_KEY.FPA,
  [TYPE_CODE.PROCESS_COMPONENT]: IMPORT_TYPE_KEY.PRC,
  [TYPE_CODE.MATERIAL]: IMPORT_TYPE_KEY.MT,
  [TYPE_CODE.CONTROL_METHOD]: IMPORT_TYPE_KEY.CM,
  [TYPE_CODE.TPP]: IMPORT_TYPE_KEY.TPP,
  [TYPE_CODE.SUPPLIER]: IMPORT_TYPE_KEY.SUPPLIER,
};