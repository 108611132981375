/* stylelint-disable-next-line import-notation */
@import "../../../../css/brand_colors";

/* stylelint-disable-next-line import-notation */
@import "../../../../css/globals";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.buttons-container {
  display: flex;
  gap: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.blank-message {
  max-width: 360px;
  text-align: center;
}

.action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.action-btn-icon {
  /* stylelint-disable-next-line function-no-unknown */
  font-size: calculateRem(15px);
}