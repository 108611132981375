"use strict";
// This file is responsible for overriding the Vis.js SelectionHandler to allow dragging Unit Operations

module.exports.overrideSelectionHandler = function(getFirstUONodeAtPointMethod) {
  let originalGetNodeAt = window.network.selectionHandler.getNodeAt.bind(window.network.selectionHandler);
  window.network.selectionHandler.getNodeAt = function(pointer, returnNode = true) {
    // call the original to get a node
    let node = originalGetNodeAt(pointer, returnNode);

    if (node) {
      return node;
    } else {
      // We check if this is a UO header
      let positionObject = window.network.selectionHandler._pointerToPositionObject(pointer);
      return getFirstUONodeAtPointMethod(positionObject);
    }
  };
};

module.exports.getNodeForId = function(nodeId) {
  return window.network.selectionHandler.body.nodes[nodeId];
};
