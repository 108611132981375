"use strict";

import React from "react";
import ReactDOMServer from "react-dom/server";
import { RequirementSummaryDataTransform } from "./requirement_summary_data_transform";
import { MaxCriticalityInfo } from "../../canned_reports/widgets/max_crtiticality_info";
import * as UIUtils from "../../../ui_utils";
import * as CommonEditablesFormatter from "../../../../server/common/editables/common_editables_formatter";

export class BillOfMaterialsDataTransform extends RequirementSummaryDataTransform {

  get type() {
    return "billOfMaterials";
  }

  getSupportedRecordTypes() {
    return ["materials", "mas", "pps"];
  }

  getRecordInstance(requirement) {
    return {...requirement};
  }

  sortRecords(records) {
    const result = [...records];
    result.sort(UIUtils.sortBy("MaterialId"));
    return result;
  }

  getModelNameForView(recordType) {
    return recordType;
  }

  formatCriticality(requirement) {
    return requirement.criticality
      ? requirement.criticalityRiskLabel
      : "";
  }

  /**
   * Retrieves the accepted record types for this report
   * @param options
   */
  getFilteredRecordTypes() {
    return ["materials"];
  }

  wrapUp(result, options) {
    const wrappedData = super.wrapUp(result, options);
    const instances = wrappedData.instances;
    const {mas, pps, materials} = instances;

    // combines MAs and PPs into a single array
    const allChildren = [
      ...(mas || []),
      ...(pps || []),
    ];

    for (let material of materials) {
      material.compendialStandard = UIUtils.secureString(
        CommonEditablesFormatter.formatMultiSelectValueForDisplay(material.compendialStandard)
      );
      const children = allChildren.filter(item => item.MaterialId === material.MaterialId).sort(UIUtils.sortBy("id"));
      const criticalityComponent = <MaxCriticalityInfo children={children} />;
      material.criticality = ReactDOMServer.renderToStaticMarkup(criticalityComponent);
    }
    instances.isEmptySummary = materials && materials.length === 0;
    return wrappedData;
  }
}
