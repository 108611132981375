var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { StepperContext } from './context/StepperContext';
import { classNames, focusFirstFocusableChild, dispatchEvent } from '@progress/kendo-react-common';
import { useLocalization } from '@progress/kendo-react-intl';
import { DEFAULT_ANIMATION_DURATION, NO_ANIMATION } from './contants';
import { messages, optionalText } from './messages';
/**
 * Represents the [KendoReact Step component]({% slug overview_stepper %}).
 *
 * @example
 * ```jsx
 * const steps = [
 *    { label: 'Step 1' },
 *    { label: 'Step 2' },
 *    { label: 'Step 3', optional: true }
 * ];
 *
 * const App = () => {
 *   return (
 *       <Stepper items={steps} value={1}/>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var Step = React.forwardRef(function (props, target) {
    var 
    // content
    children = props.children, className = props.className, current = props.current, disabled = props.disabled, focused = props.focused, icon = props.icon, index = props.index, isValid = props.isValid, label = props.label, optional = props.optional, style = props.style, tabIndex = props.tabIndex, text = props.text, others = __rest(props, ["children", "className", "current", "disabled", "focused", "icon", "index", "isValid", "label", "optional", "style", "tabIndex", "text"]);
    var _a = React.useContext(StepperContext), animationDuration = _a.animationDuration, isVertical = _a.isVertical, item = _a.item, linear = _a.linear, mode = _a.mode, numOfSteps = _a.numOfSteps, value = _a.value, successIcon = _a.successIcon, errorIcon = _a.errorIcon, onChange = _a.onChange, onFocus = _a.onFocus;
    var elementRef = React.useRef(null);
    var focusElement = React.useCallback(function () {
        if (elementRef.current) {
            focusFirstFocusableChild(elementRef.current);
        }
    }, []);
    var getImperativeHandle = React.useCallback(function () { return ({
        element: elementRef.current,
        focus: focusElement
    }); }, [focusElement]);
    React.useImperativeHandle(target, getImperativeHandle);
    var allowClick = !linear || (index === value - 1 || index === value || index === value + 1);
    var isInLabel = mode === 'labels' || (Boolean(icon) && Boolean(label));
    var localizationService = useLocalization();
    var localizeMessage = function (message) {
        return localizationService.toLanguageString(message, messages[message]);
    };
    var optionalMessage = localizeMessage(optionalText);
    var progressAnimation = typeof animationDuration === 'number'
        ? animationDuration
        : animationDuration !== false
            ? DEFAULT_ANIMATION_DURATION
            : NO_ANIMATION;
    var handleClick = React.useCallback(function (event) {
        if (onChange && !disabled) {
            dispatchEvent(onChange, event, getImperativeHandle(), { value: index });
        }
    }, [onChange, value, disabled]);
    var handleFocus = React.useCallback(function (event) {
        if (onFocus && !disabled) {
            dispatchEvent(onFocus, event, getImperativeHandle(), undefined);
        }
    }, [onFocus, disabled]);
    var itemClassNames = React.useMemo(function () { return classNames('k-step', {
        'k-step-first': index === 0,
        'k-step-last': numOfSteps && index === numOfSteps - 1,
        'k-step-done': index < value,
        'k-step-current': current,
        'k-step-optional': optional,
        'k-step-error': isValid !== undefined && !isValid,
        'k-step-success': isValid
    }, {
        'k-disabled': disabled,
        'k-focus': focused
    }, className); }, [index, numOfSteps, value, current, optional, disabled, focused, isValid, className]);
    var itemStyles = React.useMemo(function () { return (__assign({ maxWidth: !isVertical ? "calc(100% / ".concat(numOfSteps, ")") : undefined, maxHeight: isVertical ? "calc(100% / ".concat(numOfSteps, ")") : undefined, pointerEvents: !allowClick ? 'none' : undefined }, style)); }, [isVertical, numOfSteps, style, allowClick]);
    var validationIconClasses = (isValid
        ? successIcon ? "".concat(successIcon) : 'k-icon k-i-check-circle'
        : errorIcon ? "".concat(errorIcon) : 'k-icon k-i-exclamation-circle');
    var validationIcons = (React.createElement("span", { className: 'k-step-indicator-icon ' + validationIconClasses, "aria-hidden": "true" }));
    var stepIndicator = (React.createElement(React.Fragment, null, mode !== 'labels' ?
        React.createElement("span", { className: "k-step-indicator", "aria-hidden": true, style: { transitionDuration: progressAnimation + 'ms' } }, icon
            ? !isInLabel && isValid !== undefined
                ? validationIcons
                : React.createElement("span", { className: "k-step-indicator-icon k-icon ".concat(icon) })
            : isValid !== undefined
                ? validationIcons
                : React.createElement("span", { className: "k-step-indicator-text" }, text ? text : index + 1)) :
        null));
    var stepLabel = (React.createElement("span", { className: "k-step-label" },
        label && React.createElement("span", { className: "k-step-text" }, label),
        isInLabel && isValid !== undefined && validationIcons,
        optional && React.createElement("span", { className: "k-step-label-optional" }, optionalMessage)));
    var stepLink = (React.createElement(React.Fragment, null,
        stepIndicator,
        stepLabel));
    return (React.createElement("li", __assign({ ref: elementRef, className: itemClassNames, style: itemStyles }, others),
        React.createElement("a", { className: "k-step-link", title: label ? label : undefined, onClick: handleClick, onFocus: handleFocus, tabIndex: tabIndex ? tabIndex : focused ? 0 : -1, "aria-current": current ? 'step' : undefined, "aria-disabled": disabled || !allowClick || undefined, "aria-invalid": isValid !== undefined && !isValid || undefined }, !item ? stepLink : children)));
});
Step.propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    // content: PropTypes.any,
    current: PropTypes.bool,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    index: PropTypes.number,
    isValid: PropTypes.bool,
    label: PropTypes.string,
    optional: PropTypes.bool,
    style: PropTypes.object,
    tabIndex: PropTypes.number,
    text: PropTypes.string
};
var defaultProps = {
    tabIndex: 0
};
Step.defaultProps = defaultProps;
Step.displayName = 'KendoStep';
