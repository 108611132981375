"use strict";

import * as UIUtils from "../../../ui_utils";
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import * as go from "gojs";
import { TYPE_HEADER } from "../../adapters/diagram_results_adapter";
import { TYPE_CODE } from "../../process_explorer_constants";

/*
  * The functions in this file are responsible for providing the button that expands and collapses the nodes in the
  * process explorer.
  *
  * The basics of this were originally borrowed from: https://gojs.net/latest/extensions/Buttons.js
 */

const GO = go.GraphObject.make;

go.GraphObject.defineBuilder("ExpanderButton", function(args) {
  let button = /** @type {Panel} */ (
    GO("Button",
      {
        // Remove the "button" look of the button.
        "ButtonBorder.fill": "transparent",
        "ButtonBorder.stroke": null,
        "_buttonFillOver": "transparent",
        "_buttonStrokeOver": null,
        "_buttonFillPressed": "transparent",
        "_buttonStrokePressed": null,
      },
      GO(go.Shape,  // the icon
        {
          name: "ButtonIcon",
          stroke: "#859099",
          strokeWidth: 2,
        },
        // bind the text to the Node.isTreeExpanded value using this converter:
        new go.Binding("geometry", "isTreeExpanded",
          function(isExpanded) {
            return getGeometry(isExpanded ? faCaretDown : faCaretRight);
          }
        ).ofObject(),
        new go.Binding("desiredSize", "isTreeExpanded",
          function(isExpanded) {
            return isExpanded ? new go.Size(9, 10)  // Caret down size
              : new go.Size(6, 11); // Caret right size
          }
        ).ofObject(),
        new go.Binding("margin", "isTreeExpanded",
          function(isExpanded) {
            return new go.Margin(0, isExpanded ? 0 : 3, 5, 5); // So the text doesn't move when the expander is toggled
          }
        ).ofObject(),
      ),
      // assume initially not visible because there are no links coming out
      {visible: false},
      // bind the button visibility to whether it's not a leaf node
      new go.Binding("visible", "",
        function(node) {
          const {data, isTreeLeaf} = node;
          const nodeHasChildren = typeof data.hasChildren === "boolean" ? data.hasChildren : true;
          return !isTreeLeaf && nodeHasChildren;
        }
      ).ofObject()
    )
  );

  function getGeometry(faIcon) {
    // I figured this out by debugging it and finding the SVG text string that GoJS needs for the path
    return go.Geometry.parse(faIcon.icon[4], true);
  }

  // tree expand/collapse behavior
  button.click = function(e, btn) {
    UIUtils.incrementReactComponentDidUpdateCounter();
    let node = btn.part;
    if (node instanceof go.Adornment) {
      node = node.adornedPart;
    }
    if (!(node instanceof go.Node)) {
      return;
    }
    let diagram = node.diagram;
    if (diagram === null) {
      return;
    }
    let cmd = diagram.commandHandler;
    if (node.isTreeExpanded) {
      if (!cmd.canCollapseTree(node)) return;
    } else {
      if (!cmd.canExpandTree(node)) return;
    }
    e.handled = true;
    if (node.isTreeExpanded) {
      cmd.collapseTree(node);
    } else {
      diagram.startTransaction("Expand Tree");
      if (node.data.category === TYPE_CODE.UNIT_OPERATION) {
        node.expandTree(1); // Expand the UO
      } else {
        /**
         *  Here we expand the Material record (or whatever) that you clicked on, but also the "Process Components (2)" category
         *  underneath it (but not any of the process components underneath that).
         */
        node.expandTree(node.data.category === TYPE_HEADER ? 1 : 3);
      }
      diagram.commitTransaction("Expand Tree");
      UIUtils.incrementReactComponentDidUpdateCounter();
    }
  };

  return button;
});


