var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useLocalization } from '@progress/kendo-react-intl';
import { messages, columnMenuFilterAndLogic as and, columnMenuFilterOrLogic as or } from '../messages';
var logicData = [{ text: and, logic: 'and' }, { text: or, logic: 'or' }];
export var ColumnMenuFilterLogic = function (props) {
    var localization = useLocalization();
    var data = (props.data || logicData)
        .map(function (i) { return (__assign(__assign({}, i), { text: localization.toLanguageString(i.text, messages[i.text]) })); });
    return (React.createElement(DropDownList, __assign({ onChange: props.onChange, className: "k-filter-and", textField: "text" }, props, { data: data, value: data.find(function (i) { return i.logic === props.value; }) })));
};
