import React, {Fragment, ReactElement, ReactNode} from "react";
import BaseAttribute, {IAttributeProps} from "./base_attribute";
import {ChangeProjectAndProcessModal} from "../../documents/widgets/change_project_and_process_modal";
import {IBaseComponentStateAndProps} from "../../base_react_component";
import MultipleTypeaheadObjectCache from "../../utils/cache/multiple_typeahead_object_cache.js";
import {IProjectAndProcessLabelProps, ProjectAndProcessLabel} from "./project_and_process_label";
import {Modal} from "react-bootstrap";

/**
 * The props for this component
 */
interface IDocumentProjectAndProcessAttributeProps extends IAttributeProps {
  projectId?: number,
  processId?: number,
  // eslint-disable-next-line no-unused-vars
  onOK: (event: { project: any, process: any }) => void;
}

interface IDocumentProjectAndProcessAttributeState extends IBaseComponentStateAndProps {
  ProcessId?: number;
  ProjectId?: number;
  project?: any;
  process?: any;
  showDocumentAndProcessDialog?: boolean;
}

/**
 * Edits / shows the project and process name for a document.
 */
export default class DocumentProjectAndProcessAttribute extends BaseAttribute<{ project: any, process: any }, IDocumentProjectAndProcessAttributeProps, IDocumentProjectAndProcessAttributeState> {
  private _dialog: React.Ref<typeof Modal>;
  private _cache: MultipleTypeaheadObjectCache;

  private _processes: any[];
  private _projects: any[];

  private readonly _label: React.RefObject<ProjectAndProcessLabel>;

  constructor(props) {
    super(props);
    this._label = React.createRef<ProjectAndProcessLabel>();
    this.setStateSafely({isLoading: true});
    this.runPromise(this.updateCache(props));
  }

  private async updateCache(props): Promise<any> {
    this._cache = new MultipleTypeaheadObjectCache(["Project", "Process"], props.projectId, null);
    await this._cache.loadOptions(this.handleOptionsLoaded, {allProcesses: true});
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    super.componentDidUpdate(prevProps, prevState, snapshot);
  }

  handleOptionsLoaded(options: any[], typeCode: string) {
    // console.warn(">> Options received: ", typeCode, options);
    if (typeCode === "PRJ") {
      this._projects = options;
    } else if (typeCode === "PR") {
      this._processes = options;
    }

    if (this._projects && this._processes) {
      // console.warn(">> Updating loading state to false. Was:", this.state.isLoading, "\n Projects", this._projects, "\n Processes", this._processes);
      setImmediate(() => this.setStateSafely({isLoading: false}));
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext): any {
    let shouldUpdate = this.stateIsDifferent(nextState, "showDocumentAndProcessDialog", true)
      || this.propsAreDifferent(nextProps, "projectId")
      || this.propsAreDifferent(nextProps, "processId")
      || this.stateIsDifferent(nextState, "isLoading", true)
      || super.shouldComponentUpdate(nextProps, nextState, nextContext);
    // console.warn(">> Should component update", shouldUpdate, "\nCurrentState: ", this.state, "\nNextState", nextState);
    return shouldUpdate;
  }

  renderInput(): ReactNode {
    // console.warn(">> Render input");
    const {parent} = this.props;
    const {isLoading} = this.state;

    let isView = this.isView();

    let project = this.getProject();
    let process = this.getProcess();
    let isDiffingVersions: boolean = this.isDiffingVersions();

    let inputProps: IProjectAndProcessLabelProps = {
      id: "DocumentProjectAndProcess",
      isDiff: isDiffingVersions,
      project,
      process,
      isLoading,
    };

    if (isDiffingVersions) {
      let oldProjectId = this.getOldValue("ProjectId");
      let oldProject = this.getProject(oldProjectId);

      let oldProcessId = this.getOldValue("ProcessId");
      let oldProcess = this.getProcess(oldProcessId);

      let currentProjectId = parent.state["ProjectId"];
      let currentProject = this.getProject(currentProjectId);

      let currentProcessId = parent.state["ProcessId"];
      let currentProcess = this.getProcess(currentProcessId);

      inputProps = {
        ...inputProps,
        project: currentProject,
        process: currentProcess,
        oldProject: oldProject,
        oldProcess: oldProcess,
      };
    }

    const input = (
      <ProjectAndProcessLabel
        {...inputProps}
        ref={this._label}
      />);

    return (
      <Fragment>
        {this.renderProjectAndProcessDialog()}
        {input}
        {isView ? "" : (
          <span>&nbsp;
            <a id="changeProjectButton" onClick={this.handleEditProcessClick}>{project ? "Edit" : "Add Project"}</a></span>
        )}
      </Fragment>
    );
  }

  private getProcess(id: number = this.props.processId) {
    let {process} = this.state;
    if (!process && this._processes) {
      process = this._processes.find(p => p.id === id);
    }
    return process;
  }

  private getProject(id: number = this.props.projectId): any {
    let {project} = this.state;

    if (!project && this._projects) {
      project = this._projects.find(p => p.id === id);
    }
    return project;
  }

  isLoading(): boolean {
    return super.isLoading() || this.state.isLoading;
  }

  renderProjectAndProcessDialog() {
    // console.warn(">> Render dialog");
    const {
      showDocumentAndProcessDialog,
    } = this.state;

    const {parent} = this.props;

    const {
      ProcessId,
      ProjectId,
    } = parent.state;

    return (
      <ChangeProjectAndProcessModal
        dialogRef={this._dialog}
        projectId={ProjectId}
        processId={ProcessId}
        project={this.getProject()}
        process={this.getProcess()}
        parent={parent}
        subject={parent.state}
        show={showDocumentAndProcessDialog}
        isLoading={this.isLoading()}
        parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
        parentId={this.state.id}
        onClose={() => this.setStateSafely({showDocumentAndProcessDialog: false})}
        onOK={this.handleOK}
      />
    );
  }

  private handleOK(projectId: number, processId: number) {
    this.props.onOK({
      project: this.getProject(projectId),
      process: this.getProcess(processId),
    });
  }

  private handleEditProcessClick() {
    this.setStateSafely({showDocumentAndProcessDialog: true});
  }

  getInitialValue() {
    return {project: null, process: null};
  }
}
