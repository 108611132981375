"use strict";

import React, { Fragment } from "react";
import * as UIUtils from "../../ui_utils";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { SingleAcceptanceCriterion } from "./single_acceptance_criterion";
import BaseReactComponent from "../../base_react_component";
import { ParentProxy } from "../parent_proxy";
import {
  MultipleAcceptanceCriteriaAttribute
} from "../attributes/multiple_acceptance_criteria_attribute";
import { TABLE_ITEM_MODE, TABLE_MODE } from "../attributes/base_json_attribute";

// i18next-extract-mark-ns-start editables
export class AcceptanceCriteria extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.setStateSafely({editedRows: []});
  }

  shouldComponentUpdate(nextProps) {
    const shouldUpdate = JSON.stringify(nextProps.allAcceptanceCriteriaRanges) !== JSON.stringify(this.props.allAcceptanceCriteriaRanges) ||
          JSON.stringify(nextProps.requirement) !== JSON.stringify(this.props.requirement) ||
          JSON.stringify(nextProps.requirementVersion) !== JSON.stringify(this.props.requirementVersion) ||
          nextProps.measure !== this.props.measure ||
          nextProps.parent?.id !== this.props.parent?.id ||
          nextProps.primaryReportingCriteria !== this.props.primaryReportingCriteria ||
          nextProps.isDiff !== this.props.isDiff;
    return shouldUpdate;
  }

  render() {
    const {t, parent} = this.props;

    const measure = this.getDefaultMeasureValue();
    const requirement = this.getRequirementValue();
    const allAcceptanceCriteria = this.getAssociationPropertyValue();

    let parentProxy = new ParentProxy(parent);

    const renderParams = {
      allAcceptanceCriteria,
      parent,
      measure,
      parentProxy,
      requirement,
    };
    const displayMultipleAcceptanceCriteriaEditor = this.shouldDisplayMultipleAcceptanceCriteriaEditor();

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <h3>{t("Criteria Range")}</h3>
          </div>
        </div>
        {
          displayMultipleAcceptanceCriteriaEditor
            ? this.renderMultipleAcceptanceCriteria(renderParams)
            : this.renderSingleAcceptanceCriteria(renderParams)
        }
      </Fragment>
    );
  }

  getAssociationPropertyName() {
    return this.shouldUseVersion()
      ? "AcceptanceCriteriaRangeLinkedVersions"
      : "AcceptanceCriteriaRanges";
  }

  getAssociationPropertyValue() {
    const requirement = this.getRequirementValue();
    let result = (
      requirement
        ? requirement[this.getAssociationPropertyName()]
        : []
    );

    if (!result || result.length === 0) {
      result = [this.createAcceptanceCriteria()];
    }

    // Ensures there's a UUID for each item.
    // By default we use the item id itself, if non existing (not saved yet), we create a GUID for it
    for (let item of result) {
      if (!item.uuid) {
        item.uuid = item.uuid || UIUtils.generateUUID();
      }
    }

    return result;
  }

  shouldUseVersion() {
    const {parent} = this.props;
    return parent.isDiffingVersions() || parent.isApproved();
  }

  handleAddCriterion() {
    const {parent, onEditorModeChanged} = this.props;

    let requirement = this.getRequirementValue();

    let editedRow = this.createAcceptanceCriteria();
    editedRow.selectedOption = editedRow.Requirement;
    editedRow.mode = TABLE_ITEM_MODE.ADD;

    const associationProperty = this.getAssociationPropertyValue();
    associationProperty.push(editedRow);
    this.setStateSafely({
      editedRows: [...this.state.editedRows, editedRow],
    });

    parent.handleChangeValue(this.getRequirementPropertyName(), requirement);

    if (typeof onEditorModeChanged === "function") {
      onEditorModeChanged(TABLE_MODE.EDITOR_OPEN);
    }
  }

  createAcceptanceCriteria() {
    return {
      typeCode: "ACR",
      uuid: UIUtils.generateUUID(),
      label: "",
      group: "",
      lowerLimit: null,
      upperLimit: null,
      target: null,
      measurementUnits: null,
      justification: "",
      isDefault: false,
    };
  }

  getRequirementPropertyName() {
    return (
      this.shouldUseVersion()
        ? "RequirementVersion"
        : "Requirement"
    );
  }

  getRequirementValue() {
    const {parent} = this.props;
    const {state} = parent;
    const requirement = state[this.getRequirementPropertyName()];

    return requirement ? {...requirement} : null;
  }

  getDefaultMeasureValue() {
    const {parent} = this.props;
    const {state} = parent;
    return state.measure;
  }

  shouldDisplayAddCriterionButton() {
    const {parent} = this.props;
    return !parent.isView();
  }

  shouldDisplayMultipleAcceptanceCriteriaEditor() {
    const allAcceptanceCriteria = this.getAssociationPropertyValue();
    return allAcceptanceCriteria && allAcceptanceCriteria.length > 1;
  }

  renderSingleAcceptanceCriteria(params) {
    const {allAcceptanceCriteria, parent, measure, requirement, parentProxy} = params;

    const canAdd = this.shouldDisplayAddCriterionButton();
    const acceptanceCriterion = allAcceptanceCriteria[0];
    const requirementPropertyName = this.getRequirementPropertyName();

    if (canAdd) {
      allAcceptanceCriteria[0].isDefault = true;
    }

    let patchedParent = parentProxy.createPatchedParent(
      {
        state: {
          measure,
          ...acceptanceCriterion
        },
        handleChangeValue(key, value) {
          allAcceptanceCriteria[0][key] = value;
          parent.handleChangeValue(requirementPropertyName, requirement);

          if (acceptanceCriterion.isDefault || allAcceptanceCriteria.length === 1) {
            parent.handleChangeValue(key, value);
          }
        },
      }
    );

    return (
      <SingleAcceptanceCriterion
        key={acceptanceCriterion.group + "$" + acceptanceCriterion.label}
        name={acceptanceCriterion.group + "$" + acceptanceCriterion.label}
        parent={patchedParent}
        measure={measure}
        lowerLimit={acceptanceCriterion.lowerLimit}
        upperLimit={acceptanceCriterion.upperLimit}
        target={acceptanceCriterion.target}
        instance={acceptanceCriterion}
        canAdd={canAdd}
        onAdd={this.handleAddCriterion.bind(this)}
      />
    );
  }

  renderMultipleAcceptanceCriteria(params) {
    const {isDiff, allAcceptanceCriteriaRanges, primaryReportingCriteria} = this.props;
    const {parent, requirement, parentProxy, measure} = params;
    const {editedRows} = this.state;

    const requirementPropertyName = this.getRequirementPropertyName();
    let self = this;

    let patchedParent = parentProxy.createPatchedParent({
      state: {
        measure,
        ...requirement,
        AcceptanceCriteriaRanges: (
          parent.isApproved()
            ? self.filterAcceptanceCriteriaRanges(requirement.AcceptanceCriteriaRangeLinkedVersions)
            : self.filterAcceptanceCriteriaRanges(requirement.AcceptanceCriteriaRanges)
        ),
        AcceptanceCriteriaRangeLinkedVersions: self.filterAcceptanceCriteriaRanges(requirement.AcceptanceCriteriaRangeLinkedVersions),
      },
      handleChangeValue(key, value) {
        requirement.AcceptanceCriteriaRanges = value;
        parent.handleChangeValue(requirementPropertyName, requirement);
      },
      forceUpdate() {
        parent.forceUpdateSafely();
      },
      getOlderVersion() {
        let olderVersion = parent.getOlderVersion();
        if (!olderVersion || !olderVersion.RequirementVersion || !isDiff) {
          return olderVersion;
        }

        return {
          ...olderVersion,
          AcceptanceCriteriaRangeLinkedVersions: self.filterAcceptanceCriteriaRanges(olderVersion.RequirementVersion.AcceptanceCriteriaRangeLinkedVersions),
        };
      }
    });

    return (
      <div className="row">
        <MultipleAcceptanceCriteriaAttribute
          name={"acceptanceCriteriaRanges"}
          linkObject={["AcceptanceCriteriaRange"]}
          className="col-sm-12"
          measure={measure}
          ref={(macTable) => {
            this.macTable = macTable;
            if (!this._macTableInitialized) {
              this._macTableInitialized = true;
              this.forceUpdateSafely();
            }
          }}
          editedRows={editedRows}
          parent={patchedParent}
          onAdd={this.handleAddCriterion}
          allRanges={allAcceptanceCriteriaRanges}
          primaryReportingCriteria={primaryReportingCriteria}
          addButtonText={"Add criteria range"}
          onModeChanged={this.handleModeChanged}
        />
      </div>
    );
  }

  handleModeChanged(mode) {
    const {onEditorModeChanged} = this.props;
    if (typeof onEditorModeChanged === "function") {
      onEditorModeChanged(mode);
    }
  }

  filterAcceptanceCriteriaRanges(acceptanceCriteriaRanges) {
    const {primaryReportingCriteria} = this.state;
    const result = acceptanceCriteriaRanges ? acceptanceCriteriaRanges.filter(r => !r.deletedAt) : [];
    result.sort(UIUtils.sortBy("group", "label"));

    let defaultItem = result.find(item => !!item.isDefault || item.uuid === primaryReportingCriteria);

    if (!defaultItem && result.length > 0) {
      for (let item of result) {
        item.isDefault = (item.uuid === primaryReportingCriteria);

        if (item.isDefault) {
          defaultItem = item;
        }
      }

      if (!defaultItem) {
        result[0].isDefault = true;
      }
    }
    return result;
  }
}

AcceptanceCriteria.defaultProps = {
  onEditorModeChanged: () => {},
};

// i18next-extract-mark-ns-stop editables

export default I18NWrapper.wrap(AcceptanceCriteria, "editables");
