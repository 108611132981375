import {
  buildKeymap,
  keymap,
  setBlockType,
  buildListKeymap,
  chainCommands,
  EditorView,
  EditorState, Transaction,
} from "@progress/kendo-editor-common";

export default function editorKeyMap() {
  return keymap({
    Enter: enterKeyBinding,
  });
}

function enterKeyBinding(
  state: EditorState,
  // eslint-disable-next-line no-unused-vars
  dispatch: (tr: Transaction) => void,
  view: EditorView
) {
  // Bind the Enter key and preserve the style by transferring the current formatting to the new line
  // @ts-ignore
  const {$from, $to, $head, node} = state.selection;
  let grandParent = null;
  if (!((node && node.isBlock) || $from.depth < 2 || !$from.sameParent($to))) {
    grandParent = $from.node(-1);
  }

  let result;
  if (grandParent?.type?.name === "list_item") {
    result = chainCommands(
      buildListKeymap(view.state.schema).Enter,
    )(state, dispatch);
  } else {
    result = chainCommands(
      buildKeymap(view.state.schema).Enter,
    )(state, dispatch);
  }

  const blockType = $head.parent;
  const marks = $head.marks() || [];
  if (result && blockType && blockType.isTextblock) {
    setBlockType(blockType.type, blockType.attrs)(view.state, (tr) => {
      marks.forEach((m) => {
        tr.addStoredMark(m);
      });
      dispatch(tr);
    });
  }
  return result;
}
