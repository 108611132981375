import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import logoIcon from "../../images/logo.svg";
import * as docStyles from "../document.module.scss";
import * as UIUtils from "../../ui_utils";
import {
  BLANK_TEMPLATE,
  CQAA_PHA_TEMPLATE,
  CQAA_RISK_RANKING_RMP_TEMPLATE,
  PROCESS_DESCRIPTION_TEMPLATE,
} from "../../documentEditor/common/data";
import {EditablesService} from "../../services/editables/editables_service";
import DocumentSwitchSourceConfirmationPopup from "./document_switch_source_confirmation_popup";
import DocumentSelectTemplatePopup, {TEMPLATE_VALUE} from "./document_select_template_popup";
import {getProjectFromCache} from "../../utils/project_helper";

type DocumentHeaderLinkProps = {
  projectId: number;
  documentFileData: { fileName: string };
  isQbdSrcDoc: boolean;
  inputId: string;
  // eslint-disable-next-line no-unused-vars
  onSwitchDocumentSource: (isQbdSrcDoc: boolean) => void;
  onFileRemove?: () => void;
  // eslint-disable-next-line no-unused-vars
  onCreateDocument?: (newDocumentContent: { id: string, lastVersionId: number }) => void;
};

/**
 * The custom header link for Document for a Section component
 * @param props {DocumentHeaderLinkProps}
 */
const DocumentHeaderLink = forwardRef((props: DocumentHeaderLinkProps, ref) => {
  const {
    documentFileData,
    isQbdSrcDoc,
    inputId,
    onSwitchDocumentSource,
    onFileRemove,
    onCreateDocument,
    projectId,
  } = props;
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showSelectTemplateDialog, setShowSelectTemplateDialog] =
    useState(false);
  const [showSelectTemplateOnly, setShowSelectTemplateOnly] = useState(false);
  let confirmationPopupRef = useRef();
  let selectTemplatePopupRef = useRef();

  useImperativeHandle(ref, () => {
    return {
      showSelectTemplatePopup: () => {
        setShowSelectTemplateOnly(true);
        setSelectTemplatePopupOpened(true);
      },
    };
  });

  const toggleSrcDoc = (e: React.MouseEvent) => {
    e.preventDefault();
    // Show confirmation dialog when the document record has a PDF file
    if (documentFileData && documentFileData.fileName) {
      setConfirmationPopupOpened(true);
    } else if (!isQbdSrcDoc) {
      setShowSelectTemplateDialog(true);
    } else {
      onSwitchDocumentSource(!isQbdSrcDoc);
    }
  };

  const setConfirmationPopupOpened = (showDialog: boolean) => {
    setShowConfirmationDialog(showDialog);
    if (!showDialog && confirmationPopupRef) {
      // @ts-ignore
      $(confirmationPopupRef).modal("hide");
    }
  };

  const handleSwitchSource = (template: string) => {
    setConfirmationPopupOpened(false);
    if (!isQbdSrcDoc) {
      handleCreateDocument(template);
    } else {
      onSwitchDocumentSource(!isQbdSrcDoc);
      onFileRemove();
    }
  };

  const setSelectTemplatePopupOpened = (showDialog: boolean) => {
    setShowSelectTemplateDialog(showDialog);
    if (!showDialog && selectTemplatePopupRef) {
      // @ts-ignore
      $(selectTemplatePopupRef).modal("hide");
    }
  };

  const handleCreateDocument = (template: string) => {
    if (!showSelectTemplateOnly) {
      onSwitchDocumentSource(!isQbdSrcDoc);
    }
    if (documentFileData && documentFileData.fileName) {
      onFileRemove();
    }
    if (showSelectTemplateDialog) {
      setSelectTemplatePopupOpened(false);
    }

    const isLoadingDisabled = UIUtils.getLoadingDisabled();
    if (isLoadingDisabled) {
      UIUtils.setLoadingDisabled(!isLoadingDisabled);
    }
    UIUtils.showLoadingImage();
    let content;
    const cachedProject = getProjectFromCache(projectId);
    switch (template) {
      case TEMPLATE_VALUE.CQAA:
        if (cachedProject?.productRiskAssessmentType === "Preliminary Hazards Analysis (PHA)") {
          content = CQAA_PHA_TEMPLATE;
        } else {
          content = CQAA_RISK_RANKING_RMP_TEMPLATE;
        }
        break;
      case TEMPLATE_VALUE.PROCESS_DESCRIPTION:
        content = PROCESS_DESCRIPTION_TEMPLATE;
        break;
      default:
        content = BLANK_TEMPLATE;
    }
    new EditablesService()
      .save(
        {
          name: "Default Custom Document",
          content,
        },
        {
          urlPrefix: "editables/DocumentContent",
          model: "DocumentContent",
        }
      )
      .then((result) => {
        UIUtils.hideLoadingImage();
        UIUtils.setLoadingDisabled(isLoadingDisabled);
        onCreateDocument(result);
      });
  };

  const id = `${inputId}CreateCustomDoc`;
  const logo = (
    <img
      src={logoIcon}
      className={docStyles["doc-editor-link-icon"]}
      alt="qbdLogo"
      role="button"
    />
  );

  return (
    <>
      {isQbdSrcDoc ? (
        <a id="useExternalDocumentLink" href="" className="links-btn-enabled" onClick={toggleSrcDoc}>
          Use external document
        </a>
      ) : (
        <a
          id={id}
          className={"links-btn-enabled"}
          href=""
          onClick={toggleSrcDoc}
        >
          {logo} Draft in QbDVision
        </a>
      )}
      {showConfirmationDialog && (
        <DocumentSwitchSourceConfirmationPopup
          modalRef={(modalRef) => (confirmationPopupRef = modalRef)}
          setPopupOpened={setConfirmationPopupOpened}
          onSwitch={handleSwitchSource}
          isQbdSrcDoc={isQbdSrcDoc}
        />
      )}
      {showSelectTemplateDialog && (
        <DocumentSelectTemplatePopup
          modalRef={(modalRef) => (selectTemplatePopupRef = modalRef)}
          setPopupOpened={setSelectTemplatePopupOpened}
          onCreate={handleCreateDocument}
          projectId={projectId}
        />
      )}
    </>
  );
});

export default DocumentHeaderLink;
