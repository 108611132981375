"use strict";

import React, { memo, useEffect, useState } from "react";
import { ListOption } from "../../editor/widgets/list_option";
import { DEFAULT_CATEGORY } from "../library";
import * as UIUtils from "../../ui_utils";

/**
 * Global material library filter on the table page header.
 * @param filter
 * @param categories
 * @param onCategoryChange
 * @returns {JSX.Element}
 * @constructor
 */
const CategoryTableFilter = ({categories, onCategoryChange}) => {

  const [category, setCategory] = useState();

  useEffect(() => {
    const filter = UIUtils.getParameterByName("category") || DEFAULT_CATEGORY;
    setCategory(filter);
    onCategoryChange(filter);
  }, []);

  const onChange = ({target}) => {
    const {value} = target;
    setCategory(value);
    onCategoryChange(value);
  };

  return (
    <div className="category-filter-outer-div">
      <span className="select-label">Category</span>
      <select
        id="categoryComboBox"
        defaultValue={DEFAULT_CATEGORY}
        className="form-control category-select"
        value={category}
        onChange={onChange}
        title="Category"
      >
        {categories.map((category, idx) => <ListOption item={category} key={idx} />)}
      </select>
    </div>
  );
};

export default memo(CategoryTableFilter);

