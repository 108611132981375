import { BaseParser } from "../base_parser";
import {
  getNormalizedRiskScore,
  getRawRiskScore,
} from "../../../../helpers/risk_helper";
import { RISK_TYPE_ENUM } from "../../../../helpers/constants/constants";
import {
  getRiskLabel,
  getRiskScaleColor,
  getRiskScaleLabel
} from "../../../../reports/canned_reports/canned_report_helper";
import RiskUtils from "../../../../../server/common/misc/common_risk_utils";

export class CalculatedRiskAttributesParser extends BaseParser {
  parse() {
    const {modelName, columnName} = this.getNodeInfo();
    const newElement = document.createElement(this.node.tagName);
    const content = this.parseCalculatedRiskField(modelName, columnName);

    if (content?.element) {
      return [content.element];
    }

    if (content) {
      newElement.innerText = content.textContent;
    }
    return [newElement];
  }

  parseCalculatedRiskField(modelName, columnName) {
    this.addModelAndColumnToField(this.fields, modelName, columnName);
    const COLUMN_NAMES_FOR_CALCULATION = [
      "impact",
      "uncertainty",
      "capabilityRisk",
      "detectabilityRisk",
      "techTransferDetectability",
      "techTransferImpact",
      "techTransferUncertainty",
    ];

    if (["FQA", "FPA"].includes(modelName)) {
      COLUMN_NAMES_FOR_CALCULATION.push("detailedRiskLinks");
    }

    for (const column of COLUMN_NAMES_FOR_CALCULATION) {
      this.addModelAndColumnToField(this.fields, modelName, column);
    }

    let textContent = "";
    if (!this.rmp || !this.data || !this.data.riskInfo) {
      return {textContent};
    }

    const content = getCalculatedRiskAttributeContent(this.data, columnName, this.rmp);
    if (["riskLabelColor", "scoreLabelColor"].includes(columnName)) {
      return {element: this.createColorElement(content)};
    }

    if (columnName.endsWith("Percentage")) {
      return {textContent: `${content}%`};
    }

    return {textContent: content};
  }
}

export function getCalculatedRiskAttributeContent(record, columnName, rmp) {
  let content = "";
  const {riskInfo} = record;
  if (!riskInfo) {
    return content;
  }

  if (
    riskInfo.Criticality &&
    ["riskLabelColor", "riskLabel", "scoreLabelColor", "scoreLabel"].includes(
      columnName
    )
  ) {
    const scaleForRiskLabel = riskInfo.Criticality.scaleForRiskLabel;
    const scale = riskInfo.Criticality.scale;
    switch (columnName) {
      case "riskLabelColor":
        return getRiskScaleColor(scaleForRiskLabel);
      case "scoreLabelColor":
        return getRiskScaleColor(scale);
      case "scoreLabel":
        content = getRiskScaleLabel(scale);
        break;
      default:
        content = getRiskLabel(scaleForRiskLabel);
    }
  }

  if (
    riskInfo.Criticality &&
    [
      "maxCriticalityRaw",
      "maxCriticalityPercentage",
      "criticalityRaw",
      "criticalityPercentage",
    ].includes(columnName)
  ) {
    const criticality = riskInfo.Criticality;
    switch (columnName) {
      case "maxCriticalityPercentage":
        content = criticality.maxNormalizedValue;
        break;
      case "criticalityRaw":
        content = criticality.value;
        break;
      case "criticalityPercentage":
        content = criticality.normalizedValue;
        break;
      default:
        content = criticality.maxValue;
    }

    content = content || RiskUtils.NOT_ASSESSED_LABEL;
  }

  if (
    riskInfo["Process Risk"] &&
    ["processRiskRaw", "processRiskPercentage"].includes(columnName)
  ) {
    const processRisk = riskInfo["Process Risk"];
    switch (columnName) {
      case "processRiskPercentage":
        content = processRisk.normalizedValue;
        break;
      default:
        content = processRisk.value;
    }
  }

  if (riskInfo.RPN && ["rpnRaw", "rpnPercentage"].includes(columnName)) {
    const rpn = riskInfo.RPN;
    switch (columnName) {
      case "rpnPercentage":
        content = rpn.normalizedValue;
        break;
      default:
        content = rpn.value;
    }
  }

  if (
    ["techTransferRPNRaw", "techTransferRPNPercentage"].includes(columnName)
  ) {
    const rawScore = getRawRiskScore(
      RISK_TYPE_ENUM.RPN,
      rmp,
      record,
      false,
      true,
      true
    );
    switch (columnName) {
      case "techTransferRPNPercentage":
        content = getNormalizedRiskScore(
          RISK_TYPE_ENUM.RPN,
          rmp,
          rawScore
        );
        break;
      default:
        content = rawScore;
    }
  }

  return content || "";
}
