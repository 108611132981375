"use strict";

import React from "react";
import * as UIUtils from "../../../ui_utils";
import TechTransferColumnGenerator from "./tech_transfer_column_generator";
import { getURLByTypeCodeAndId } from "../../../helpers/url_helper";
import {
  EXTRA_LARGE_COLUMN, VARIABLE_PARENTS,
} from "../../../configurableTables/tables/configurable_tables_constants";
import ShowMoreTooltip from "../../../widgets/tooltips/show_more_tooltip";
import { GridColumn } from "@progress/kendo-react-grid";
import { getClassName, getProcessName, getValueWithTitle } from "../tech_transfer_table_helper";
import { FIELD_PROPS } from "../../../configurableTables/fieldsConfig/constants/configurable_tables_field_props_config";

/**
 * This class is used to generate all common columns for the Telerik tables
 * for parent model types (PRC/MT).
 */
export default class TechTransferVariableParentColumnGenerator extends TechTransferColumnGenerator {

  generateSupplierAndMaterialSummaryColumn(processName, isReceivingSite = false) {
    const className = getClassName(FIELD_PROPS.SUPPLIER_OR_ACCEPTANCE_CRITERIA, isReceivingSite);
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  key={className}
                  title={getProcessName(processName, isReceivingSite, true)}
                  headerClassName={`two-lines-header ${className}`}
                  cell={this.supplierAndMaterialSummaryCreatedCell.bind(this, isReceivingSite)}
      />
    );
  }

  supplierAndMaterialSummaryCreatedCell(isReceivingSite, {dataItem, dataIndex}) {
    const record = isReceivingSite ? dataItem : dataItem.techTransferredFrom;
    if (dataItem.typeCode === "MT" &&
      ["Drug Substance (API)", "Excipient"].includes(record.category)) {
      const supplierLink = this.getSupplierInformation(record);
      return (
        <td>
          {getValueWithTitle("Empirical Formula", record.empiricalFormula)}
          {getValueWithTitle("Molecular Weight", record.molecularWeight)}
          {getValueWithTitle("Supplier", supplierLink)}
          {getValueWithTitle("Supplier Part Number", record.partNumber)}
          {getValueWithTitle("Internal Part Number", record.internalPartNumber)}
          {getValueWithTitle("Compendial Standard", record.compendialStandard)}
          {getValueWithTitle("Description",
            record.description ?
              <ShowMoreTooltip text={record.description}
                               id={`showMore_${dataIndex}`}
              /> : "")}
        </td>);
    } else if (VARIABLE_PARENTS.includes(dataItem.typeCode)) {
      const supplierLink = this.getSupplierInformation(record);
      return (
        <td>
          {getValueWithTitle("Supplier", supplierLink)}
          {getValueWithTitle("Supplier Part Number", record.partNumber)}
          {getValueWithTitle("Description",
            record.description ?
              <ShowMoreTooltip text={record.description}
                               id={`showMore_${dataIndex}`}
              /> : "")}
        </td>);
    }
  }

  getSupplierInformation(record) {
    const supplier = record.Supplier;
    return supplier ?
      <div>
        <a href={getURLByTypeCodeAndId("SUP", "View", supplier.id)}
           rel="noopener noreferrer"
           target="_blank"
        >
          {UIUtils.getRecordLabelForDisplay("SUP", supplier.id, supplier.name)}
        </a>
      </div> : "";
  }

  generateChangeSummary(rowData) {
    const underlyingDiffsCount = rowData.underlyingDiffsCount;
    return underlyingDiffsCount > 0 ?
      <div className="tech-transfer-process-component-diff-summary">
        {underlyingDiffsCount} underlying {`${UIUtils.pluralize("change", underlyingDiffsCount)}`}
      </div> : "";
  }

  generateCategoryUseColumn(process, isReceivingSite = false) {
    const className = getClassName(FIELD_PROPS.CATEGORY_USE);
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  key={className}
                  headerClassName={className}
                  headerCell={(props) => this.generateTwoLinesHeader(props, "Category / Use", process, isReceivingSite)}
                  cell={this.createCategoryUseCell}
      />
    );
  }

  createCategoryUseCell({dataItem}) {
    return (<td>{dataItem.category || "None"} / {dataItem.use || "None"}</td>);
  }
}
