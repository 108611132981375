var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Window } from '@progress/kendo-react-dialogs';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { messages } from './../messages';
import { EditorToolsSettings } from './../config/toolsSettings';
import { formatString } from './../tools/utils';
import { find, replace, replaceAll, findAll, TextSelection, textHighlightKey, selectedLineTextOnly } from '@progress/kendo-editor-common';
import { Icon } from '@progress/kendo-react-common';
var enter = 13, esc = 27;
var checkboxProps = { className: 'k-checkbox k-checkbox-md k-rounded-md', type: 'checkbox' };
var settings = EditorToolsSettings.findAndReplace;
/**
 * @hidden
 */
var FindAndReplaceDialog = /** @class */ (function (_super) {
    __extends(FindAndReplaceDialog, _super);
    function FindAndReplaceDialog(props) {
        var _this = _super.call(this, props) || this;
        _this.onTabSelect = function (event) {
            _this.setState({ selectedTab: event.selected });
        };
        _this.onClose = function () {
            var view = _this.props.view;
            var state = view.state;
            var transaction = state.tr.setSelection(TextSelection.create(state.doc, state.selection.from, state.selection.to));
            view.updateState(state.apply(transaction));
            view.focus();
            _this.props.onClose.call(undefined);
        };
        _this.matchesMessage = function (locMessage) {
            var matches = _this.state.matches;
            var nextMatch = _this.state.nextMatch;
            var cur = 0, all = 0;
            if (matches && nextMatch) {
                var current = matches.findIndex(function (s) { return s.eq(nextMatch); });
                cur = current === -1 ? matches.length : current + 1;
                all = matches.length;
            }
            return formatString(locMessage, cur, all);
        };
        _this.onFindNext = function () {
            _this.onFind();
        };
        _this.onFindPrev = function () {
            _this.onFind(true);
        };
        _this.onFind = function (backward) {
            if (backward === void 0) { backward = false; }
            var view = _this.props.view;
            var _a = _this.state, text = _a.searchText, matchCase = _a.matchCase, matchCyclic = _a.matchCyclic, matchWord = _a.matchWord, useRegExp = _a.useRegExp;
            var searchOptions = { text: text, matchWord: matchWord, matchCase: matchCase, useRegExp: useRegExp, backward: backward, matchCyclic: matchCyclic };
            var selectionResult = find(view.state, searchOptions);
            if (selectionResult) {
                var transaction = view.state.tr.setSelection(selectionResult);
                transaction.scrollIntoView();
                view.updateState(view.state.apply(transaction));
                _this.setState({ nextMatch: selectionResult });
            }
        };
        _this.onReplace = function () {
            var view = _this.props.view;
            var selection = view.state.selection;
            var replaceText = _this.state.replaceText;
            if (!selection.empty) {
                var start = selection.from;
                var end = start + replaceText.length;
                var transaction = replace(selection, replaceText, view.state.tr);
                transaction.setSelection(TextSelection.create(transaction.doc, start, end));
                transaction.scrollIntoView();
                view.dispatch(transaction);
                _this.setNextState({});
            }
        };
        _this.onReplaceAll = function () {
            var view = _this.props.view;
            var _a = _this.state, searchText = _a.searchText, replaceText = _a.replaceText, matchCase = _a.matchCase, matchWord = _a.matchWord, useRegExp = _a.useRegExp;
            var searchOptions = { text: searchText, matchWord: matchWord, matchCase: matchCase, useRegExp: useRegExp };
            var tr = replaceAll(view.state, replaceText, searchOptions);
            if (tr) {
                view.dispatch(tr);
            }
            _this.setNextState({});
        };
        _this.onKeyDown = function (event) {
            if (event.keyCode === enter) {
                _this.onFindNext();
            }
            else if (event.keyCode === esc) {
                _this.onClose();
            }
        };
        _this.onMatchCaseChecked = function (e) { return _this.setNextState({ matchCase: e.target.checked }); };
        _this.onMatchWordChecked = function (e) { return _this.setNextState({ matchWord: e.target.checked }); };
        _this.onMatchCyclicChecked = function (e) { return _this.setNextState({ matchCyclic: e.target.checked }); };
        _this.onUseRegExpChecked = function (e) { return _this.setNextState({ useRegExp: e.target.checked }); };
        _this.onSearchChange = function (e) { return _this.setNextState({ searchText: e.target.value }); };
        _this.onReplaceChange = function (e) { return _this.setNextState({ replaceText: e.target.value }); };
        _this.setNextState = function (next) {
            var nextState = __assign(__assign(__assign({}, _this.state), next), { matches: undefined, nextMatch: undefined });
            var view = _this.props.view;
            if (nextState.searchText) {
                var text = nextState.searchText, matchWord = nextState.matchWord, matchCase = nextState.matchCase, useRegExp = nextState.useRegExp, matchCyclic = nextState.matchCyclic;
                var searchOptions = { text: text, matchWord: matchWord, matchCase: matchCase, useRegExp: useRegExp };
                var selection_1 = view.state.selection;
                var matches = findAll(view.state.doc, searchOptions);
                var nextMatch = (!_this.state.searchText && matches[0]) ||
                    matches.find(function (match) { return match.from >= selection_1.from; }) ||
                    (matchCyclic && matches[0]) ||
                    undefined;
                _this.setState(__assign(__assign({}, nextState), { matches: matches, nextMatch: nextMatch }));
            }
            else {
                _this.setState(nextState);
            }
        };
        _this.state = {
            selectedTab: 0,
            searchText: selectedLineTextOnly(props.view.state),
            replaceText: '',
            matchCase: false,
            matchWord: false,
            matchCyclic: false,
            useRegExp: false
        };
        return _this;
    }
    Object.defineProperty(FindAndReplaceDialog.prototype, "settings", {
        get: function () {
            return this.props.settings || settings;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    FindAndReplaceDialog.prototype.componentDidUpdate = function (_, prevState) {
        var view = this.props.view;
        var _a = this.state, _b = _a.matches, matches = _b === void 0 ? [] : _b, nextMatch = _a.nextMatch;
        if (prevState.nextMatch !== nextMatch) {
            var state = view.state;
            // highlight selection
            var tr = state.tr;
            var highlights_1 = [];
            matches.forEach(function (m) {
                highlights_1.push({
                    from: m.from,
                    to: m.to,
                    attrs: {
                        class: (nextMatch && m.eq(nextMatch)) ?
                            'k-text-selected' :
                            'k-text-highlighted'
                    }
                });
            });
            tr.setMeta(textHighlightKey, highlights_1);
            tr.setSelection(nextMatch || TextSelection.create(state.doc, state.selection.from));
            view.dispatch(tr);
        }
    };
    /**
     * @hidden
     */
    FindAndReplaceDialog.prototype.render = function () {
        var localization = provideLocalizationService(this);
        var _a = this.settings.messages, findReplaceDialogTitle = _a.findReplaceDialogTitle, findReplaceTabFind = _a.findReplaceTabFind, findReplaceTabReplace = _a.findReplaceTabReplace, findReplaceFindWhat = _a.findReplaceFindWhat, findReplaceReplaceWith = _a.findReplaceReplaceWith, findReplaceReplace = _a.findReplaceReplace, findReplaceReplaceAll = _a.findReplaceReplaceAll, findReplaceMatchCase = _a.findReplaceMatchCase, findReplaceMatchWord = _a.findReplaceMatchWord, findReplaceMatchCyclic = _a.findReplaceMatchCyclic, findReplaceUseRegExp = _a.findReplaceUseRegExp, findReplacePrevMatch = _a.findReplacePrevMatch, findReplaceNextMatch = _a.findReplaceNextMatch, findReplaceMatches = _a.findReplaceMatches;
        var _b = this.state, matchCase = _b.matchCase, matchWord = _b.matchWord, matchCyclic = _b.matchCyclic, useRegExp = _b.useRegExp, searchText = _b.searchText, replaceText = _b.replaceText, nextMatch = _b.nextMatch;
        var checkboxes = (React.createElement("div", { className: "k-search-options" },
            React.createElement("span", null,
                React.createElement("input", __assign({}, checkboxProps, { id: "match-case", checked: matchCase, onChange: this.onMatchCaseChecked })),
                React.createElement("label", { htmlFor: "match-case", className: "k-checkbox-label" }, localization.toLanguageString(findReplaceMatchCase, messages[findReplaceMatchCase]))),
            React.createElement("span", null,
                React.createElement("input", __assign({}, checkboxProps, { id: "match-whole", checked: matchWord, onChange: this.onMatchWordChecked })),
                React.createElement("label", { htmlFor: "match-whole", className: "k-checkbox-label" }, localization.toLanguageString(findReplaceMatchWord, messages[findReplaceMatchWord]))),
            React.createElement("span", null,
                React.createElement("input", __assign({}, checkboxProps, { id: "match-cyclic", checked: matchCyclic, onChange: this.onMatchCyclicChecked })),
                React.createElement("label", { htmlFor: "match-cyclic", className: "k-checkbox-label" }, localization.toLanguageString(findReplaceMatchCyclic, messages[findReplaceMatchCyclic]))),
            React.createElement("span", null,
                React.createElement("input", __assign({}, checkboxProps, { id: "regular-expression", checked: useRegExp, onChange: this.onUseRegExpChecked })),
                React.createElement("label", { htmlFor: "regular-expression", className: "k-checkbox-label" }, localization.toLanguageString(findReplaceUseRegExp, messages[findReplaceUseRegExp])))));
        var navigation = (React.createElement("div", { className: "k-matches-container" },
            React.createElement(Button, { fillMode: 'flat', themeColor: 'primary', onClick: this.onFindPrev },
                React.createElement(Icon, { name: "chevron-left" }),
                localization.toLanguageString(findReplacePrevMatch, messages[findReplacePrevMatch])),
            React.createElement("span", null, this.matchesMessage(localization.toLanguageString(findReplaceMatches, messages[findReplaceMatches]))),
            React.createElement(Button, { fillMode: 'flat', themeColor: 'primary', onClick: this.onFindNext },
                localization.toLanguageString(findReplaceNextMatch, messages[findReplaceNextMatch]),
                React.createElement(Icon, { name: "chevron-right" }))));
        var findWhat = (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "k-edit-label" },
                React.createElement("label", { htmlFor: "findWhat" }, localization.toLanguageString(findReplaceFindWhat, messages[findReplaceFindWhat]))),
            React.createElement("div", { className: "k-edit-field" },
                React.createElement("input", { id: "findWhat", type: "text", className: "k-input", value: searchText, onChange: this.onSearchChange, onFocus: this.onSearchChange, onKeyDown: this.onKeyDown, autoFocus: true }))));
        var replaceWith = (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "k-edit-label" },
                React.createElement("label", { htmlFor: "replaceWith" }, localization.toLanguageString(findReplaceReplaceWith, messages[findReplaceReplaceWith]))),
            React.createElement("div", { className: "k-edit-field" },
                React.createElement("input", { id: "replaceWith", className: "k-input", type: "text", value: replaceText, onChange: this.onReplaceChange }))));
        return (React.createElement(Window, { title: localization.toLanguageString(findReplaceDialogTitle, messages[findReplaceDialogTitle]), onClose: this.onClose, style: { width: 'auto', height: 'auto', userSelect: 'none' }, resizable: false, minimizeButton: function () { return null; }, maximizeButton: function () { return null; }, appendTo: document.body },
            React.createElement(TabStrip, { selected: this.state.selectedTab, className: "k-editor-find-replace", onSelect: this.onTabSelect, animation: false },
                React.createElement(TabStripTab, { title: localization.toLanguageString(findReplaceTabFind, messages[findReplaceTabFind]) },
                    React.createElement("div", { className: "k-edit-form-container" }, findWhat),
                    checkboxes,
                    navigation),
                React.createElement(TabStripTab, { title: localization.toLanguageString(findReplaceTabReplace, messages[findReplaceTabReplace]) },
                    React.createElement("div", { className: "k-edit-form-container" },
                        findWhat,
                        replaceWith),
                    React.createElement("div", { className: "k-actions k-hstack k-justify-content-end" },
                        React.createElement(Button, { disabled: !Boolean(nextMatch), onClick: this.onReplace }, localization.toLanguageString(findReplaceReplace, messages[findReplaceReplace])),
                        React.createElement(Button, { disabled: !Boolean(nextMatch), onClick: this.onReplaceAll }, localization.toLanguageString(findReplaceReplaceAll, messages[findReplaceReplaceAll]))),
                    checkboxes,
                    navigation))));
    };
    return FindAndReplaceDialog;
}(React.Component));
export { FindAndReplaceDialog };
registerForLocalization(FindAndReplaceDialog);
