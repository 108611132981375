"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import TrialBar from "../../widgets/bars/trial_bar";
import CookiesBar from "../../widgets/bars/cookies_bar";
import CompanyHeader from "../../widgets/headers/company_header";
import { getGraphData, RiskMap } from "./risk_map";
import RiskMapTimeline from "./widgets/risk_map_timeline";
import RiskMapFilter from "./widgets/risk_map_filter";
import RiskMapLegend from "./widgets/risk_map_legend";
import RiskMapToolbar from "./widgets/risk_map_toolbar";
import RiskMapReportLayout from "./utilities/risk_map_report_layout";
import RiskMapLayoutSavePopup from "./dialogs/risk_map_layout_save_popup";
import RiskMapLayoutLoadPopup from "./dialogs/risk_map_layout_load_popup";
import ConfirmationPopup from "../../widgets/generic/confirmation_popup";
import {
  getApprovedStateWarning,
  getHistoricalData,
  getHistoryPointForDate,
  getLastApprovedDate,
  isHistoryPointApproved,
  showByRiskLabel
} from "./utilities/risk_map_report_helper";
import { exportReportLayout } from "./utilities/risk_map_report_canvas_helper";
import { resetGraphToDefaultView } from "./utilities/risk_map_layout_helper";
import { RISK_TYPE_ENUM } from "../../helpers/constants/constants";
import PropTypes from "prop-types";
import {
  getEffectiveRMPForDate,
  getRiskBoundaries,
  getRiskFilterNameFromRiskScale,
  getSortedRiskScales,
  loadRMP
} from "../../helpers/risk_helper";
import TermsPopup from "../../terms/terms_popup";
import moment from "moment/moment";
import { getProjectFromCache } from "../../utils/project_helper";
import CommonEditables from "../../../server/common/editables/common_editables";
import ErrorBar from "../../widgets/bars/error_bar";
import BaseFullScreenPage from "../../base_full_screen_page";
import * as ProcessCache from "../../processExplorer/process/process_cache";
import FullObjectCache from "../../utils/cache/full_object_cache";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import { RiskMapObjectVersionChangeTracker } from "./risk_map_object_version_change_tracker";


const RISK_MAP_REPORT_TYPES = {
  CompositeRiskMapReport: {
    key: "Composite Risk Map",
    displayValue: "Risk Map",
  },
};


/* This class shows the risk map report */
export default class RiskMapReportPage extends BaseFullScreenPage {
  constructor(props) {
    super(props);

    this.projectId = UIUtils.getParameterByName("projectId");
    this.reportType = UIUtils.getParameterByName("reportType");
    this.autoSaveLayoutWait = 2; // Waits 2 seconds before auto-saving a modified layout

    let stateObject = {};

    const processId = UIUtils.getParameterByName("processId") || ProcessCache.getProcessIdUsedRecently(this.props.projectId);
    if (processId) {
      stateObject.processId = processId;
    }

    this.setStateSafely(stateObject, () => {
      new TypeaheadObjectCache("Project", this.projectId);
    });

    loadRMP(this.loadReportData);
  }

  componentDidMount() {
    document.title = "QbDVision Risk Map";

    super.componentDidMount();
  }

  static handleReportRenderingStarted() {
    $("#riskMapContainer")[0].style.visibility = "hidden";
  }

  loadReportData(rmp, project, processIdParam, callback) {
    const processId = processIdParam || ProcessCache.getProcessIdUsedRecently(this.props.projectId);
    let processParam = processId ? "&processId=" + processId : "";
    UIUtils.secureAjaxGET("reports/" + this.reportType + "?projectId=" + this.projectId + processParam)
      .done(results => {
        this.handleReceiveDataFromServer(rmp, project, results);
        if (callback) {
          callback();
        }
      });
  }

  handleReceiveDataFromServer(cachedRMP, project, results) {
    // Uncomment for verbose logging
    // console.log("Received risk map results: " + UIUtils.stringify(results));

    let rmpVersions = cachedRMP.approvedVersionsWithDetails.map(rmpVersion => {
      rmpVersion.boundaries = getRiskBoundaries(rmpVersion);
      return rmpVersion;
    });

    let mapHistory = getHistoricalData(project, rmpVersions, results.instances, new RiskMapObjectVersionChangeTracker());
    let selectedDate = new Date();

    /*
    In case RMP was configured by type the effective RMP can't equal to something like historyPoints[historyPoints.length = 1].rmp
    because each one of this history points has its own special RMP depending on the object type. So instead we are getting the effective
    RMP from RMP versions using getEffectiveRMPForDate.
     */
    let rmp = getEffectiveRMPForDate(project, rmpVersions, selectedDate);
    let riskMapReportLayout;

    const autoSavedLayout = results.instances.autoSavedLayout;
    let riskTracedToFilter;

    if (autoSavedLayout) {
      riskMapReportLayout = RiskMapReportLayout.deepClone(autoSavedLayout);

      const layout = JSON.parse(autoSavedLayout.layout);
      riskTracedToFilter = layout.riskTracedToFilter;
    } else {
      riskMapReportLayout = new RiskMapReportLayout(null, this.projectId, results.process.id, RISK_MAP_REPORT_TYPES[this.reportType].key);
    }

    let historyPointForSelectedDate = getHistoryPointForDate(selectedDate, mapHistory);
    this.setStateSafely({
      mapHistory,
      effectiveRMP: rmp,
      rmpVersions,
      projectWithAllVersions: project,
      selectedDate,
      reportType: RISK_MAP_REPORT_TYPES[this.reportType].key,
      riskMapReportLayout,
      riskTracedToFilter,
      typeaheadOptions: [],
      selectedTypeaheadOptions: [],
      layouts: [],
      project: results.instances.project,
      process: results.process,
      title: RISK_MAP_REPORT_TYPES[this.reportType].displayValue,
      lastDateSelected: true,
      riskType: RISK_TYPE_ENUM.CRITICALITY,
      unitOperationVersions: historyPointForSelectedDate ? historyPointForSelectedDate.unitOperationVersionsMap : []
    });
  }

  handleFiltersUpdated(filters) {
    this.setStateSafely({
      filters
    });
  }

  handleRiskMapReportSearchSelectionChanged(event) {
    this.setStateSafely({
      selectedTypeaheadOptions: event
    });
  }

  onTypeaheadOptionsReady(typeaheadOptions) {
    this.setStateSafely({
      typeaheadOptions,
      shouldRenderGraph: false
    });
  }

  triggerAutoSaveLayout(immediate) {
    if (!immediate) {
      clearTimeout(this.autoSaveLayoutTimeout);
      this.autoSaveLayoutTimeout = setTimeout(() => {
        this.autoSaveLayout();
      }, this.autoSaveLayoutWait * 1000);
    } else {
      this.autoSaveLayout();
    }
  }

  autoSaveLayout() {
    let network = window.network;
    let nodePositions = network.getPositions();

    //Save the nodes positions, zoom level and focus
    let riskMapReportLayout = RiskMapReportLayout.deepClone(this.state.riskMapReportLayout);
    let graphLayoutObject = riskMapReportLayout.layout;

    for (let nodeId of Object.keys(nodePositions)) {
      let type = window.nodesToObjects[nodeId].type;
      let id = window.nodesToObjects[nodeId].id;

      if (!graphLayoutObject[type]) {
        graphLayoutObject[type] = {};
      }

      graphLayoutObject[type][id] = {
        x: nodePositions[nodeId].x,
        y: nodePositions[nodeId].y
      };
    }

    graphLayoutObject.scale = network.getScale();
    graphLayoutObject.viewPosition = network.getViewPosition();

    const filters = JSON.parse(this.state.filters);
    graphLayoutObject.riskTracedToFilter = filters.riskTracedToFilter;

    if (!riskMapReportLayout.ProcessId) {
      // This can happen if it's a saved layout since those don't have Process Ids.
      riskMapReportLayout.ProcessId = this.state.processId;
    }

    this.setStateSafely({
      riskMapReportLayout,
    }, () => {
      const clonedReportLayout = RiskMapReportLayout.deepClone(riskMapReportLayout);
      clonedReportLayout.layout = JSON.stringify(clonedReportLayout.layout);
      clonedReportLayout.clientTime = moment().utc().format();

      UIUtils.secureAjaxPUT("entities/reportAutoSavedLayout/addOrEdit", clonedReportLayout, false);
    });
  }

  handleReportRenderingCompleted() {
    $("#riskMapContainer")[0].style.visibility = "visible";
    this.setStateSafely({
      shouldRenderGraph: false
    });
  }

  scaleToFitNetwork() {
    this.setStateSafely({
      scaleNetworkToFit: true
    }, () => {
      window.callsToComponentDidUpdate++;
      window.network.fit({
        animation: {
          duration: 500,
          easingFunction: "easeInOutQuint"
        }
      });

      this.setStateSafely({
        scaleNetworkToFit: false
      }, () => {
        window.callsToComponentDidUpdate++;
      });
    });
  }

  resetNetworkLayout() {
    this.setStateSafely({
      showResetLayoutConfirmationPopup: true
    });
  }

  handleRiskMapDateChanged(date, lastDateSelected) {
    let historyPoint = this.state.mapHistory && getHistoryPointForDate(date, this.state.mapHistory);

    this.setStateSafely({
      selectedDate: date,
      lastDateSelected: lastDateSelected,
      effectiveRMP: getEffectiveRMPForDate(this.state.projectWithAllVersions, this.state.rmpVersions, date),
      unitOperationVersions: historyPoint ? historyPoint.unitOperationVersionsMap : []
    });
  }

  getReportLastApprovedDate() {
    let latestSystemApprovedDate = null;
    if (this.state.mapHistory && window.nodesToObjects) {
      let visibleNodes = Object.keys(window.nodesToObjects).map(key => {
        return window.nodesToObjects[key].type + "-" + window.nodesToObjects[key].id;
      });
      latestSystemApprovedDate = getLastApprovedDate(this.state.mapHistory.historyPoints.filter(historyPoint => {
        return visibleNodes.includes(historyPoint.type + "-" + historyPoint.id);
      }), visibleNodes);
    }

    return latestSystemApprovedDate;
  }

  reportIsInApprovedState() {
    let allElementsAreApproved = false;
    if (this.state.mapHistory && window.nodesToObjects) {
      let visibleNodes = Object.keys(window.nodesToObjects).map(key => {
        return window.nodesToObjects[key].type + "-" + window.nodesToObjects[key].id;
      });
      allElementsAreApproved = isHistoryPointApproved(getHistoryPointForDate(this.state.selectedDate, this.state.mapHistory), visibleNodes);
    }

    return allElementsAreApproved;
  }

  /*Modal related events*/

  handleShowLoadLayoutsPopup(layouts) {
    this.setStateSafely({
      layouts: layouts,
      showLoadLayoutPopup: true
    });
  }

  handleShowSaveLayoutsPopup() {
    this.setStateSafely({
      showSaveLayoutPopup: true
    });
  }

  handleHideModal() {
    if (this.saveLayoutConfirmationPopup) {
      $(this.saveLayoutConfirmationPopup).modal("hide");
    }
    if (this.deleteLayoutConfirmationPopup) {
      $(this.deleteLayoutConfirmationPopup).modal("hide");
    }
    if (this.resetLayoutConfirmationPopup) {
      $(this.resetLayoutConfirmationPopup).modal("hide");
    }

    this.setStateSafely({
      showSaveLayoutPopup: false,
      showLoadLayoutPopup: false
    });
  }

  handleHideSaveLayoutConfirmationPopup() {
    this.setStateSafely({
      showSaveLayoutConfirmationPopup: false
    });
  }

  handleHideDeleteLayoutConfirmationPopup() {
    this.setStateSafely({
      showDeleteLayoutConfirmationPopup: false
    });
  }

  handleHideResetLayoutConfirmationPopup() {
    this.setStateSafely({
      showResetLayoutConfirmationPopup: false
    });
  }

  handleHideMessagePopup() {
    this.setStateSafely({
      showMessagePopup: false
    });
  }

  handleLayoutOverwriteConfirmation() {
    this.saveLayout();
  }

  handleLayoutDeleteConfirmation() {
    this.deleteLayout();
  }

  handleResetLayoutConfirmation() {
    this.resetLayout();
    if (this.resetLayoutConfirmationPopup) {
      $(this.resetLayoutConfirmationPopup).modal("hide");
    }
  }

  handleMessagePopupOkButton() {
    if (this.messagePopup) {
      $(this.messagePopup).modal("hide");
    }
  }

  /*Saving, Loading, Deleting, Editing layouts related events*/
  handleLoadLayout(riskMapReportLayout) {
    if (riskMapReportLayout) {
      let filters = JSON.parse(this.state.filters);

      // Find nodes that aren't layed out by this layout, and put them where they'd be in the default layout
      riskMapReportLayout.adaptLayoutForNewNodes(window.network, getGraphData().nodes, filters);

      this.setStateSafely({
        riskMapReportLayout,
        shouldRenderGraph: true,
        title: RISK_MAP_REPORT_TYPES[this.reportType].displayValue + " - " + riskMapReportLayout.name
      });
    }

    if (this.riskMapLayoutLoadPopup) {
      $(this.riskMapLayoutLoadPopup).modal("hide");
    }
  }

  handleSaveLayout() {
    this.triggerAutoSaveLayout(true);

    UIUtils.secureAjaxGET("entities/reportLayout/list", {
      projectId: this.projectId,
      reportType: RISK_MAP_REPORT_TYPES[this.reportType].key
    }, true).done(this.handleReceiveLayoutsBeforeSave);
  }

  handleReceiveLayoutsBeforeSave(results) {
    let existingLayout = results.find(layout => {
      return layout.name === this.state.riskMapReportLayout.name;
    });

    if (existingLayout) {
      this.setStateSafely({
        showSaveLayoutConfirmationPopup: true,
        existingLayoutId: existingLayout.id
      });
    } else {
      this.setStateSafely({
        existingLayoutId: null
      }, () => {
        this.saveLayout();
      });
    }
  }

  handleProcessChange(processId) {
    let project = getProjectFromCache(this.projectId);
    this.loadReportData(new FullObjectCache("RMP", project.RMPId).getOptionsFromCache(),
      new FullObjectCache("Project", project.id).getOptionsFromCache(),
      processId);
    this.setStateSafely({
      processId,
    });
  }

  saveLayout() {
    // Uncomment for verbose logging
    // console.log("Saving: " + JSON.stringify(this.state.riskMapReportLayout));
    let clonedReportLayout = RiskMapReportLayout.deepClone(this.state.riskMapReportLayout);
    clonedReportLayout.layout = JSON.stringify(clonedReportLayout.layout);
    clonedReportLayout.id = this.state.existingLayoutId ? this.state.existingLayoutId : null;
    UIUtils.secureAjaxPUT("entities/reportLayout/addOrEdit", clonedReportLayout, true)
      .done(() => {
        clonedReportLayout.layout = JSON.parse(clonedReportLayout.layout);
        this.setStateSafely({
          riskMapReportLayout: clonedReportLayout,
          shouldRenderGraph: false,
          title: RISK_MAP_REPORT_TYPES[this.reportType].displayValue + " - " + clonedReportLayout.name
        });
      });

    if (this.saveLayoutConfirmationPopup) {
      $(this.saveLayoutConfirmationPopup).modal("hide");
    }
    if (this.riskMapLayoutSavePopup) {
      $(this.riskMapLayoutSavePopup).modal("hide");
    }
  }

  handleDeleteLayout(layout) {
    this.setStateSafely({
      layoutToDelete: layout,
      showDeleteLayoutConfirmationPopup: true
    });
  }

  deleteLayout() {
    UIUtils.secureAjaxDELETE("entities/reportLayout/" + this.state.layoutToDelete.id, {
      projectId: this.projectId
    }, true, null, true).done(() => {
      this.setStateSafely({
        layouts: this.state.layouts.filter(layout => {
          return layout.id !== this.state.layoutToDelete.id;
        }).map(layout => {
          return new RiskMapReportLayout(layout.id, layout.ProjectId, layout.ProcessId, layout.type, layout.layout, layout.name, layout.description);
        })
      }, () => {
        if (this.deleteLayoutConfirmationPopup) {
          $(this.deleteLayoutConfirmationPopup).modal("hide");
        }
      });
    });
  }

  handleLayoutNameChange(name) {
    const riskMapReportLayout = RiskMapReportLayout.deepClone(this.state.riskMapReportLayout);
    riskMapReportLayout.name = name;
    this.setStateSafely({riskMapReportLayout});
  }

  handleDescriptionChange(description) {
    const riskMapReportLayout = RiskMapReportLayout.deepClone(this.state.riskMapReportLayout);
    riskMapReportLayout.description = description;
    this.setStateSafely({riskMapReportLayout});
  }

  handleInitializationChanged(isInitialized) {
    const riskMapReportLayout = RiskMapReportLayout.deepClone(this.state.riskMapReportLayout);
    riskMapReportLayout.isInitialized = isInitialized;
    this.setStateSafely({riskMapReportLayout});
  }

  handleExportLayout() {
    exportReportLayout(this.state.effectiveRMP,
      this.state.title,
      this.state.project.id,
      this.state.project.name,
      this.state.process.id,
      this.state.process.name,
      this.state.selectedDate,
      this.getReportLastApprovedDate(),
      this.getReportFiltersValues(),
      this.reportIsInApprovedState(),
      this.state.riskType,
      UIUtils.getSoftwareVersion());
  }

  handleCriticalityViewChange(riskType) {
    this.setStateSafely({
      riskType: RISK_TYPE_ENUM[riskType]
    });
  }

  resetLayout() {
    resetGraphToDefaultView(window.network, getGraphData().nodes, JSON.parse(this.state.filters));

    clearTimeout(this.resetLayoutTimeout);
    this.setStateSafely({
      riskMapReportLayout: new RiskMapReportLayout(null, this.projectId, this.state.processId, RISK_MAP_REPORT_TYPES[this.reportType].key),
    });

    this.resetLayoutTimeout = setTimeout(() => {
      window.network.fit({
        animation: {
          duration: 500,
          easingFunction: "easeInOutQuint"
        }
      });
    }, 100);
  }

  getReportFiltersValues() {
    let rmp = this.state.effectiveRMP;
    let appliedFilters = {
      elements: [],
      risk: [],
      onlySearched: [],
      unitOperations: [],
      mtCategories: [],
    };

    if (this.state.filters && rmp) {
      let filters = JSON.parse(this.state.filters);

      for (let key of Object.keys(filters)) {
        if (key === "linksFilter") {
          appliedFilters.links = UIUtils.convertCamelCaseToSpacedOutWords(filters[key]);
        } else if ((key === "showTPPs") && filters[key] && filters.riskTracedToFilter === "traceToTPPs") {
          appliedFilters.elements.push("Target Product Profiles");
        } else if ((key === "showGeneralAttributes") && filters[key] && filters.riskTracedToFilter === "traceToGeneralAttributes") {
          appliedFilters.elements.push("General Attributes");
        } else if ((key === "showFQAs") && filters[key]) {
          appliedFilters.elements.push("Final Quality Attributes");
        } else if ((key === "showFPAs") && filters[key]) {
          appliedFilters.elements.push("Final Performance Attributes");
        } else if ((key === "showIQAs") && filters[key]) {
          appliedFilters.elements.push("Intermediate Quality Attributes");
        } else if ((key === "showIPAs") && filters[key]) {
          appliedFilters.elements.push("Intermediate Performance Attributes");
        } else if ((key === "showPPs") && filters[key]) {
          appliedFilters.elements.push("Process Parameters");
        } else if ((key === "showMAs") && filters[key]) {
          appliedFilters.elements.push("Material Attributes");
        } else if ((key === "riskTracedToFilter") && filters[key]) {
          appliedFilters.riskTracedToFilter = filters.riskTracedToFilter === "traceToTPPs" ? "Target Product Profile" : "General Attributes";
        }

        if (key.startsWith("uo_") && filters[key]) {
          let uoId = UIUtils.parseKey(key, {splitSeparator: "_"}).id;
          let unitOperation = this.state.unitOperationVersions && this.state.unitOperationVersions[uoId];
          if (unitOperation) {
            appliedFilters.unitOperations.push(unitOperation.name);
          }
        }

        if (key.startsWith("mt_") && filters[key]) {
          let category = CommonEditables.MATERIAL_CATEGORIES.find(category => `mt_${UIUtils.convertToId(category)}` === key);
          if (category) {
            appliedFilters.mtCategories.push(category);
          }
        }
      }

      if (this.state.selectedTypeaheadOptions.length) {
        appliedFilters.onlySearched = this.state.selectedTypeaheadOptions.map(option => {
          return option.label;
        });
      }

      for (let riskScale of getSortedRiskScales(this.state.riskType, rmp, true)) {
        let showRiskLabel = showByRiskLabel(filters, this.state.riskType);
        let filterKey = getRiskFilterNameFromRiskScale(riskScale, false, showRiskLabel);

        if (filters[filterKey]) {
          appliedFilters.risk.push({
            label: showRiskLabel ? riskScale.riskLabel : riskScale.scoreLabel,
            color: riskScale.color,
            modelName: riskScale.modelName
          });
        }
      }
    }

    return appliedFilters;
  }

  renderPage() {
    return (
      <div className="risk-map-report-container full-screen-container">
        <RiskMapToolbar RMP={this.state.effectiveRMP}
                        projectId={this.projectId}
                        filters={this.state.filters ? JSON.parse(this.state.filters) : null}
                        typeaheadOptions={this.state.typeaheadOptions}
                        reportType={this.state.reportType}
                        selectedDate={this.state.selectedDate}
                        riskType={this.state.riskType}
                        lastDateSelected={!!this.state.lastDateSelected}
                        onShowLoadLayoutsPopup={this.handleShowLoadLayoutsPopup}
                        onShowSaveLayoutsPopup={this.handleShowSaveLayoutsPopup}
                        onRiskMapReportSearchSelectionChanged={this.handleRiskMapReportSearchSelectionChanged}
                        onFitRiskMapReport={this.scaleToFitNetwork}
                        onResetRiskMapReportLayout={this.resetNetworkLayout}
                        onExportLayout={this.handleExportLayout}
        />
        <div className="risk-map-canvas-container">
          {this.state.showSaveLayoutConfirmationPopup ?
            <ConfirmationPopup
              modalRef={saveLayoutConfirmationPopup => this.saveLayoutConfirmationPopup = saveLayoutConfirmationPopup}
              headerText={"Confirmation required"}
              message={"A layout with name " + this.state.riskMapReportLayout.name + " already exists. Do you want to overwrite it?"}
              showCancelButton={true}
              onOkButtonClick={this.handleLayoutOverwriteConfirmation}
              onHideConfirmationPopup={this.handleHideSaveLayoutConfirmationPopup}
            />
            : ""}
          {this.state.showDeleteLayoutConfirmationPopup ?
            <ConfirmationPopup
              modalRef={deleteLayoutConfirmationPopup => this.deleteLayoutConfirmationPopup = deleteLayoutConfirmationPopup}
              headerText={"Confirmation required"}
              message={"Are you sure you want to delete " + this.state.layoutToDelete.name + " layout?"}
              showCancelButton={true}
              onOkButtonClick={this.handleLayoutDeleteConfirmation}
              onHideConfirmationPopup={this.handleHideDeleteLayoutConfirmationPopup}
            />
            : ""}
          {this.state.showResetLayoutConfirmationPopup ?
            <ConfirmationPopup
              modalRef={resetLayoutConfirmationPopup => this.resetLayoutConfirmationPopup = resetLayoutConfirmationPopup}
              headerText={"Confirmation required"}
              message={"The layout of this report will be reset. You will lose your changes!\nContinue?"}
              showCancelButton={true}
              onOkButtonClick={this.handleResetLayoutConfirmation}
              onHideConfirmationPopup={this.handleHideResetLayoutConfirmationPopup}
            />
            : ""}
          {this.state.showMessagePopup ?
            <ConfirmationPopup modalRef={messagePopup => this.messagePopup = messagePopup}
                               headerText={"Information"}
                               message={this.state.informativePopupMessage}
                               showCancelButton={false}
                               onOkButtonClick={this.handleMessagePopupOkButton}
                               onHideConfirmationPopup={this.handleHideMessagePopup}
            />
            : ""}
          {this.state.showSaveLayoutPopup ?
            <RiskMapLayoutSavePopup
              modalRef={riskMapLayoutSavePopup => this.riskMapLayoutSavePopup = riskMapLayoutSavePopup}
              onHideModal={this.handleHideModal}
              onSaveLayout={this.handleSaveLayout}
              onLayoutNameChange={this.handleLayoutNameChange}
              onDescriptionChange={this.handleDescriptionChange}
            />
            : ""}
          {this.state.showLoadLayoutPopup ?
            <RiskMapLayoutLoadPopup
              modalRef={riskMapLayoutLoadPopup => this.riskMapLayoutLoadPopup = riskMapLayoutLoadPopup}
              layouts={this.state.layouts}
              onHideModal={this.handleHideModal}
              onLoadLayout={this.handleLoadLayout}
              onDeleteLayout={this.handleDeleteLayout}
            />
            : ""}
          <div id="riskMapCanvas"
               className="risk-map-canvas"
          >
            <RiskMap parent={this}
                     RMP={this.state.effectiveRMP}
                     mapHistory={this.state.mapHistory}
                     selectedDate={this.state.selectedDate}
                     graphLayout={this.state.riskMapReportLayout}
                     shouldRenderGraph={!!this.state.shouldRenderGraph}
                     filters={this.state.filters ? JSON.parse(this.state.filters) : null}
                     riskType={this.state.riskType}
                     selectedTypeaheadOptions={this.state.selectedTypeaheadOptions}
                     onReportRenderingCompleted={this.handleReportRenderingCompleted}
                     onReportRenderingStarted={RiskMapReportPage.handleReportRenderingStarted}
                     onInitializationChanged={this.handleInitializationChanged}
                     onAutoSaveReportLayout={this.triggerAutoSaveLayout}
                     onTypeaheadOptionsReady={this.onTypeaheadOptionsReady}
            />
            <RiskMapFilter RMP={this.state.effectiveRMP}
                           rmpVersions={this.state.rmpVersions}
                           riskType={this.state.riskType}
                           onFiltersUpdated={this.handleFiltersUpdated}
                           scaleNetworkToFit={this.state.scaleNetworkToFit}
                           onCriticalityViewChange={this.handleCriticalityViewChange}
                           onRiskTracedToChange={this.resetLayout}
                           mapHistory={this.state.mapHistory}
                           selectedDate={this.state.selectedDate}
                           mtCategories={CommonEditables.MATERIAL_CATEGORIES}
                           defaultRiskTracedToFilter={this.state.riskTracedToFilter}
                           ref={filter => this.riskMapFilter = filter}
            />
            <RiskMapLegend RMP={this.state.effectiveRMP}
                           riskType={this.state.riskType}
                           filters={this.state.filters ? JSON.parse(this.state.filters) : {}}
                           scaleNetworkToFit={this.state.scaleNetworkToFit}
                           ref={legend => this.riskMapLegend = legend}
            />
          </div>
        </div>
        <RiskMapTimeline parent={this}
                         rmpVersions={this.state.rmpVersions}
                         mapHistory={this.state.mapHistory}
                         graphLayout={this.state.riskMapReportLayout ? this.state.riskMapReportLayout.layout : null}
                         filters={this.state.filters ? JSON.parse(this.state.filters) : null}
                         selectedTypeaheadOptions={this.state.selectedTypeaheadOptions}
                         riskType={this.state.riskType}
                         onRiskMapDateChanged={this.handleRiskMapDateChanged}
                         onAutoSaveReportLayout={this.triggerAutoSaveLayout}
                         scaleNetworkToFit={this.state.scaleNetworkToFit}
                         riskMapFilter={this.riskMapFilter}
                         riskMapLegend={this.riskMapLegend}
        />
      </div>
    );
  }

  render() {
    // Add the extra items needed for printing
    let selectedDate = UIUtils.getDateForDisplayToUser(this.state.selectedDate);
    let lastApprovedDate = this.getReportLastApprovedDate();
    let lastApprovedDateData = " - ";
    if (lastApprovedDate) {
      lastApprovedDateData = moment(lastApprovedDate).tz(moment.tz.guess()).format(UIUtils.LONG_DATE_FORMAT_FOR_DISPLAY);
    }
    const allItemsInApprovedState = this.reportIsInApprovedState();
    this.setExtraPrintItems([
      {
        order: 1,
        label: "Showing map snapshot as of",
        data: selectedDate
      },
      {
        order: 2,
        label: "Last Approved Status",
        data: lastApprovedDateData
      },
      {
        order: 3,
        label: "Note",
        data: (<span className={"risk-map-print-note-" + (allItemsInApprovedState ? "green" : "orange")}>
          {getApprovedStateWarning(allItemsInApprovedState)}
        </span>)
      }
    ]);

    return (
      <div id="bodyDiv" className="risk-map-report-page-body">
        <TrialBar minified={this.isMinified()} />
        <CompanyHeader minified={this.isMinified()}
                       breadcrumb={this.state.breadcrumb}
                       project={this.state.project}
                       extraPrintItems={this.extraPrintItems}
                       renderProcessSelector={true}
                       onProcessChange={this.handleProcessChange}
                       processId={this.state.processId}
                       title={this.state.title}
                       shouldShowArchivedProcesses={false}
        />
        <ErrorBar className="risk-map-error-bar" />
        {this.renderPage()}
        <CookiesBar />
        <TermsPopup modalRef={termsPopup => this.termsPopup = termsPopup}
                    onApproveButtonCLick={this.handleTermsPopupApproveButtonClick}
        />
      </div>
    );
  }
}

RiskMapReportPage.propTypes = {
  minified: PropTypes.bool.isRequired,
};

RiskMapReportPage.defaultProps = {
  minified: true,
};
