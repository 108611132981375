"use strict";
import { FIELD_INPUT_TYPE, FIELD_REQUIRED_FOR } from "../../editor/widgets/widget_field";

/**
 * @type {IWidgetField[]}
 */
export const WIDGET_FIELDS = [
  {
    fieldName: "integrationSystem",
    displayName: "System",
    forceUpdate: false,
    defaultValue: "",
    belongsToMasterRow: true,
    requiredFor: FIELD_REQUIRED_FOR.save,
    order: 1,
    inputType: FIELD_INPUT_TYPE.text,
  },
];