"use strict";

/* This class shows the PCM dashboard */
import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseChartsReportPage from "./base_charts_report_page";
import Heading from "../../widgets/headers/heading";
import ChartReport from "./chart_report";


/**kj
 * This shows the Process Analytics dashboard (but not the control charts).
 */
export default class ChartsReportPage extends BaseChartsReportPage {
  constructor(props) {
    super(props);

    let selectedSubgroupIds = UIUtils.getParameterByName("selectedIds") || undefined;
    if (selectedSubgroupIds) {
      selectedSubgroupIds = JSON.parse(selectedSubgroupIds);
    }

    this.setStateSafely({
      selectedSubgroupIds,
    });
  }

  handleReceiveDataFromServer(results) {
    let requirement = results.instances.requirement;
    let {selectedSubgroupIds} = this.state;
    let {allSubgroups, subgroups: selectedSubgroups, subgroupsFiltered} = requirement;
    selectedSubgroupIds = subgroupsFiltered ? selectedSubgroupIds : null;
    this.setStateSafely({allSubgroups, selectedSubgroups, selectedSubgroupIds}, this.pushURLHistory);
  }

  loadDataFromServer(shouldPushHistory = true) {
    super.loadDataFromServer(shouldPushHistory);
    // Loading the actual data is taken care of by the <ChartReport/> object rendered below.
  }

  handleTypeaheadChange(value) {
    this.setStateSafely({
      selectedTypeaheadOption: value,
    }, () => {
      this.loadDataFromServer();
    });
  }

  renderLeftPanel() {
    let commonProps = this.getCommonPropsForRender();
    return (
      <div className="chart-report-background-container">
        <div className="chart-report-outer-container container">
          <Heading level={1}
                   className={"header-banner" +
                   (this.reportOptions.fullScreen ? " canned-report-container-full-screen-header" : "")}
          >
            {this.state.title}
          </Heading>

          <ChartReport {...commonProps}
                       dependencyTypeahead={this.state.dependenciesTypeahead}
                       onChangeData={this.openRightPanel}
                       onReceivedDataFromServer={this.handleReceiveDataFromServer}
                       onTypeaheadChange={this.handleTypeaheadChange}
                       allSubgroups={this.state.allSubgroups || []}
                       selectedSubgroups={this.state.selectedSubgroups || []}
                       selectedSubgroupIds={this.state.selectedSubgroupIds}
                       parentId={this.state.parentId}
                       versionId={this.state.versionId}
          />
        </div>
      </div>
    );
  }

}
