"use strict";
// Disable the unused vars errors because either the classes are needed for the generated output or the classes are used in the JSX below.
/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom";
import BaseReactComponent from "../../../base_react_component";

/* eslint-enable no-unused-vars */

export default class RiskMapLayoutSavePopup extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      errorText: ""
    };
  }

  componentDidUpdate() {
    $("#riskMapReportSaveLayoutForm").validator("update");
  }

  componentDidMount() {
    super.componentDidMount();
    $(ReactDOM.findDOMNode(this)).modal("show");
    $(ReactDOM.findDOMNode(this)).on("hidden.bs.modal", this.props.onHideModal);
    $("#riskMapReportSaveLayoutForm").validator("update");
  }

  handleSaveLayout(event) {
    this.eventForm = event.target.form;
    if (this.eventForm.checkValidity()) {
      this.props.onSaveLayout();
    } else {
      this.setStateSafely({
        errorText: "A name is required for the layout."
      });
    }
  }

  handleNameChange(event) {
    this.eventForm = event.target.form;
    this.props.onLayoutNameChange(event.target.value);
    if (this.eventForm.checkValidity()) {
      this.setStateSafely({
        errorText: null
      });
    }
  }

  handleDescriptionChange(event) {
    this.props.onDescriptionChange(event.target.value);
  }

  handleFormSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    return (
      <div className="modal fade"
           ref={this.props.modalRef}
      >
        <form data-toggle="validator"
              id="riskMapReportSaveLayoutForm"
              role="form"
              onSubmit={this.handleFormSubmit}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title">Save Layout</h1>
                <button type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="modal-container risk-map-popup-layout-container">
                  <div className="row">
                    <div className={"col-sm-12 alert alert-danger" + (this.state.errorText ? "" : " d-none")}
                         id="approvalRequestPopupErrorMessage"
                    >
                      {this.state.errorText}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="riskMapReportLayoutName">
                        Name
                      </label>
                    </div>
                    <div className="col-sm-12"
                         id="riskMapReportLayoutNameDiv"
                    >
                      <input type="text"
                             className="form-control"
                             id="nameInput"
                             data-validate="true"
                             required
                             onChange={this.handleNameChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="riskMapReportLayoutDescription">
                        Description
                      </label>
                    </div>
                    <div className="col-sm-12"
                         id="riskMapReportLayoutDescriptionDiv"
                    >
                      <textarea className="form-control"
                                id="descriptionInput"
                                data-validate="true"
                                rows={4}
                                onChange={this.handleDescriptionChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="btn-group">
                  <button id="saveLayoutButton"
                          type="button"
                          className="btn btn-primary"
                          onClick={this.handleSaveLayout}
                  >
                    Save
                  </button>
                  <button id="cancelSaveLayoutButton"
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
