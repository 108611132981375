"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import Cookies from "js-cookie";

// noinspection NpmUsedModulesInstalled
import * as PropTypes from "prop-types";

import ErrorBar from "../../widgets/bars/error_bar";
import ApprovalPopupTable from "./approval_popup_table";
import LabelTooltip from "../../widgets/tooltips/label_tooltip";
import { getLatestApprovalRequestVersionTransition } from "../../helpers/approval_helper";
import { AttributeLink } from "../../widgets/generic/attribute_link";
import ReferenceDocuments from "@cherrycircle/fda-docs";
import BasePopup from "./base_popup";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import { RetryPleaseError, RetryWrapper } from "../../helpers/retry_wrapper";
import { Auth } from "aws-amplify";
import * as CognitoHelper from "../../helpers/cognito_helper";

const Logger = Log.group(LOG_GROUP.Users, "ApprovalResponsePopup");

export const APPROVAL_SUCCEEDED = "APPROVAL_SUCCEEDED";

/**
 *  This class shows the popup when a user is approving or approving for archive/restore.
 */
export class ApprovalResponsePopup extends BasePopup {
  constructor(props) {
    super(props);

    const state = {
      errorText: "",
      acceptResponsibility: false,
      isHandlingApproveButton: false
    };

    if (ApprovalResponsePopup.hasApprovalSucceededInThisSession()) {
      state.email = UIUtils.getEmail();
      props.onApprovalChangeValue("email", UIUtils.getEmail());
    }

    this.setStateSafely(state);
  }

  static hasApprovalSucceededInThisSession() {
    return !!Cookies.get(APPROVAL_SUCCEEDED);
  }

  static setApprovalSucceededInThisSession() {
    Cookies.set(APPROVAL_SUCCEEDED, true);
  }

  handleCheckboxChange(event) {
    this.setStateSafely({acceptResponsibility: event.target.checked});
  }

  handleCommentChange(event) {
    this.setStateSafely({
      comment: event.target.value
    });

    UIUtils.clearError("#approvalRequestAlertDiv");
  }

  handlePasswordChange(event) {
    this.setStateSafely({
      password: event.target.value
    });

    UIUtils.clearError("#approvalRequestAlertDiv");
  }

  handleSigningPinChange(event) {
    this.setStateSafely({
      signingPin: event.target.value
    });

    UIUtils.clearError("#approvalRequestAlertDiv");
  }

  handleEmailChange(event) {
    this.setStateSafely({
      email: event.target.value
    });

    UIUtils.clearError("#approvalRequestAlertDiv");
  }

  async handleApproveButton(event) {
    UIUtils.ignoreHandler(event);
    if (!this.state.acceptResponsibility) {
      UIUtils.showError("You must click the checkbox to accept responsibility to approve.", null, "#approvalRequestAlertDiv");
      return;
    }

    if (!ApprovalResponsePopup.hasApprovalSucceededInThisSession()) {
      const email = this.state.email;
      if (!email || email.toLowerCase() !== UIUtils.getEmail()) {
        UIUtils.showError("Email address is incorrect.", null, "#approvalRequestAlertDiv");
        return;
      }
    }

    const password = this.state.password;
    const signingPin = this.state.signingPin;
    const identityProvider = Cookies.get("IDENTITY_PROVIDER");
    const username = UIUtils.getUserName();

    const stateForParent = {
      comment: this.state.comment,
      password: this.state.password,
      signingPin: this.state.signingPin,
      email: this.state.email
    };

    if (!password && !identityProvider) {
      UIUtils.showError("A password is required.", null, "#approvalRequestAlertDiv");
    } else if (!signingPin && identityProvider) {
      UIUtils.showError("A signing pin is required.", null, "#approvalRequestAlertDiv");
    } else if (signingPin) {
      this.setStateSafely({isHandlingApproveButton: true});
      const result = await UIUtils.secureAjaxGET(`users/userAPI?activity=verifySigningPin`, {username, signingPin});
      const {isValidSigningPin} = result;

      if (!isValidSigningPin) {
        UIUtils.showError("Signing pin is incorrect.", null, "#approvalRequestAlertDiv");
        UIUtils.hideLoadingImage();
        this.setStateSafely({isHandlingApproveButton: false});
      } else {
        if (this.props.isPopupOpened) {
          this.props.isPopupOpened(false);
        }

        UIUtils.hideLoadingImage();

        // This Promise.resolve() here makes it easier to concatenate the promises even if the prop returns a non-promise object.
        UIUtils.setLoadingDisabled(false);

        this.props.onApprovalChangeValues(stateForParent, () => {
          Promise.resolve()
            .then(() => this.props.onSendApproval(true))
            .then(() => this.clearParentApprovalResponseData())
            .catch(err => UIUtils.defaultFailFunction(err))
            .finally(() => {
              UIUtils.setLoadingDisabled(true);
              this.setStateSafely({isHandlingApproveButton: false});
            });
        });
      }
    } else {

      CognitoHelper.configureUserPool();

      this.setStateSafely({isHandlingApproveButton: true});
      UIUtils.setLoadingDisabled(false);
      UIUtils.showLoadingImage("Verifying Access");

      this.props.onApprovalChangeValues(stateForParent, () => {
        setTimeout(() => {
          new RetryWrapper(() => this.attemptLogin(username, password),
            (ignored, waitInMS) => UIUtils.showError(`Cannot verify password. Retrying in ${waitInMS / 1000} seconds...`),
            () => this.setStateSafely({isHandlingApproveButton: false})
          ).retryFunction();
        }, 50);
      });
    }
  }

  attemptLogin(username, password) {
    Logger.info(() => "Constructing login credentials...");

    return new Promise((resolve, reject) => {
      Auth.signIn(username, password)
        .then(async() => {
          if (this.props.setPopupOpened) {
            this.props.setPopupOpened(false);
          }
          UIUtils.hideLoadingImage();
          try {
            await this.props.onSendApproval(true);
            await this.clearParentApprovalResponseData();
            resolve();
          } catch (err) {
            UIUtils.defaultFailFunction(err);
            reject(err);
          } finally {
            UIUtils.setLoadingDisabled(true);
          }
        })
        .catch((err) => {
          Logger.info(() => "Error received. Maybe retryable:", Log.error(err));
          if (["NotAuthorizedException", "InvalidParameterException"].includes(err.code)) {
            UIUtils.showError("Password is incorrect.", null, "#approvalRequestAlertDiv");
            UIUtils.hideLoadingImage();
            resolve();
          } else if (CognitoHelper.isCognitoErrorRetryable(err)) {
            Logger.info(() => "Retrying because of " + UIUtils.stringify(err));
            throw new RetryPleaseError();
          } else {
            Logger.info(() => "Caught unknown Cognito error:", Log.error(err));
            reject(err);
            UIUtils.hideLoadingImage();
          }

          this.setStateSafely({isHandlingApproveButton: false});
        });
    });
  }

  handleRejectButton() {
    if (this.state.acceptResponsibility) {
      UIUtils.showError("You clicked to accept responsibility for approval but then clicked to reject it. Uncheck the checkbox if you want to reject.", null, "#approvalRequestAlertDiv");
      return;
    }
    if (this.props.setPopupOpened) {
      this.props.setPopupOpened(false);
    }

    const stateForParent = {
      comment: this.state.comment,
      password: this.state.password,
      signingPin: this.state.signingPin,
      email: this.state.email
    };

    this.props.onApprovalChangeValues(stateForParent, () => {
      this.props.onSendApproval(false).then(() => this.clearParentApprovalResponseData());
    });
  }

  handleCancel() {
    super.handleCancel();
    this.clearParentApprovalResponseData();
    if (this.props.setPopupOpened) {
      this.props.setPopupOpened(false);
    }
  }

  clearParentApprovalResponseData() {
    if (this.props.onApprovalClearValue) {
      this.props.onApprovalClearValue();
    }
  }

  renderRecordInfo() {
    return this.props.isBulkApproval ? (
      <div className="approval-proposal-response-popup-table">
        <ApprovalPopupTable data={this.props.selectedRequirements} />
      </div>
    ) : (
      <>
        <AttributeLink
          instance={this.props.instance}
          baseTypeName={this.props.baseTypeName}
        />:
      </>
    );
  }

  render() {

    let currentUserHasApproved = null;
    if (this.props.versions && this.props.currentDiffingVersion) {
      let currentDiffingVersion = this.props.currentDiffingVersion;
      let latestVersion = this.props.versions[0];
      let versionTransitions = latestVersion.versionTransitions;
      let approvalRequestTransition = getLatestApprovalRequestVersionTransition(versionTransitions);

      let userResponse = null;

      if (approvalRequestTransition.approvalResponses && (currentDiffingVersion.versionId === latestVersion.id || currentDiffingVersion.versionId < 0)) {
        userResponse = approvalRequestTransition.approvalResponses.find(response => {
          return UIUtils.isCurrentUser(response.createdByUser.cognitoUUID);
        });
        currentUserHasApproved = userResponse && userResponse.approve;
      }
    }

    const identityProvider = Cookies.get("IDENTITY_PROVIDER");

    return (
      <div className="modal fade"
           ref={this.setModalRef}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">{this.props.title}</h1>
              <button type="button"
                      className="close"
                      onClick={this.handleCancel}
                      aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {/*This makes LastPass work (it needs to be inside a form)*/}
              <form className="modal-container" id="approvalForm">
                <ErrorBar id="approvalRequestAlertDiv"
                          className="approval-popup-error-div"
                />
                <div className="row">
                  {this.props.warning ? (
                    <div className="col-sm-12 alert alert-warning"
                         id="approvalRequestWarning"
                    >
                      {this.props.warning}
                    </div>
                  ) : ""}
                  <div className="col-sm-12">
                    {this.props.children || (
                      <>
                        You are approving / rejecting {" "}
                        {this.renderRecordInfo()}
                      </>
                    )}
                  </div>
                </div>
                {
                  this.props.showCommentBox
                    ? (<div className="row approval-pad-row">
                      <div className="col-sm-12">
                        <label htmlFor="approvalComments">
                          Comments
                        </label>
                        <textarea id="approvalComments"
                                  rows="3"
                                  className="full-width"
                                  onChange={this.handleCommentChange}
                        />
                      </div>
                    </div>)
                    : ""
                }
                <div className="row approval-pad-row">
                  <div className="col-sm-12">
                    <div className="approval-only-box">
                      {
                        this.props.showRejectButton
                          ? (
                            <>Approval only:<br /></>
                          )
                          : ""
                      }
                      <div className="row approval-pad-row">
                        <div className="col-sm-12">
                          <input type="checkbox"
                                 id="approvalAcceptResponsibility"
                                 checked={this.state.acceptResponsibility}
                                 onChange={this.handleCheckboxChange}
                          />
                          <label htmlFor="approvalAcceptResponsibility" style={{display: "inline"}}>
                            {this.props.disclaimer}
                          </label>
                        </div>
                      </div>
                      {ApprovalResponsePopup.hasApprovalSucceededInThisSession() ? "" : (
                        <div className="row approval-pad-row">
                          <div className="col-sm-12">
                            <LabelTooltip id="approvalEmailInputLabel"
                                          tooltipText="FDA regulations insist that the first approval in every session must include two distinct identification components."
                                          tooltipGuidanceURL={ReferenceDocuments.FDA_21CFR_PART_11}
                                          text="Your email for verification *"
                            />
                            <input id="approvalEmail"
                                   type="text"
                                   autoComplete={"username"}
                                   className="full-width"
                                   onChange={this.handleEmailChange}
                                   disabled={!this.state.acceptResponsibility}
                            />
                          </div>
                        </div>
                      )}
                      <div className="row approval-pad-row">
                        {identityProvider ?
                          <div className="col-sm-12">
                            <label htmlFor="approvalPassword">
                              Your signing pin for verification *:
                            </label>
                            <input id="approvalSigningPin"
                                   type="password"
                                   autoComplete="one-time-code"
                                   className="full-width"
                                   onChange={this.handleSigningPinChange}
                                   disabled={!this.state.acceptResponsibility}
                            />
                          </div> :
                          <div className="col-sm-12">
                            <label htmlFor="approvalPassword">
                              Your password for verification *:
                            </label>
                            <input id="approvalPassword"
                                   type="password"
                                   autoComplete={"current-password"}
                                   className="full-width"
                                   onChange={this.handlePasswordChange}
                                   disabled={!this.state.acceptResponsibility}
                            />
                          </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <div className="modal-container">
                <div className="btn-group">
                  <button id="approveButton"
                          type="submit"
                          form="approvalForm"
                          className="btn btn-primary"
                          disabled={(typeof currentUserHasApproved === "boolean" && currentUserHasApproved) || this.state.isHandlingApproveButton}
                          title={typeof currentUserHasApproved === "boolean" && currentUserHasApproved ? "You have already approved this request." : ""}
                          onClick={this.handleApproveButton}
                  >
                    {this.props.approveButtonText}
                  </button>
                  {this.props.showRejectButton ? (
                    <button id="rejectButton"
                            type="button"
                            className="btn btn-danger"
                            disabled={typeof currentUserHasApproved === "boolean" && !currentUserHasApproved}
                            title={typeof currentUserHasApproved === "boolean" && !currentUserHasApproved ? "You have already rejected this request." : ""}
                            onClick={this.handleRejectButton}
                    >
                      Reject
                    </button>
                  ) : ""}
                  <button id="cancelResponseButton"
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={this.handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


ApprovalResponsePopup.propTypes = {
  selectedRequirements: PropTypes.array,
  projectId: PropTypes.any,
  isBulkApproval: PropTypes.bool,
  onSendApproval: PropTypes.func,
  onApprovalChangeValue: PropTypes.func,
  onApprovalChangeValues: PropTypes.func,
  onApprovalClearValue: PropTypes.func,
  baseTypeName: PropTypes.string,
  instance: PropTypes.any,
  showRejectButton: PropTypes.bool,
  showCommentBox: PropTypes.bool,
  disclaimer: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.string,
  approveButtonText: PropTypes.string,
};

ApprovalResponsePopup.defaultProps = {
  isBulkApproval: false,
  onApprovalClearValue: null,
  showRejectButton: true,
  showCommentBox: true,
  title: "Approval",
  disclaimer: `I understand that by providing the ${Cookies.get("IDENTITY_PROVIDER") ? "pin" : "password"} below, I am electronically signing indicating that I have read, understood, and approve all the information in this electronic record.`,
  approveButtonText: "Approve",
};

export default ApprovalResponsePopup;
