var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { classNames, guid, Draggable, kendoThemeMaps } from '@progress/kendo-react-common';
import { createId } from './utils/itemIdUtils';
import { isItemExpandedAndWithChildren, hasChildren } from './utils/itemUtils';
import { Reveal } from '@progress/kendo-react-animation';
import { DOM_KENDO_ITEM_ID_FIELD, DOM_KENDO_TREEVIEW_GUID_FIELD } from './utils/consts';
var sizeMap = kendoThemeMaps.sizeMap;
/**
 * @hidden
 */
export var TreeViewItemPropsContext = React.createContext(function (props) { return props; });
/**
 * @hidden
 */
var TreeViewItemWithoutContext = /** @class */ (function (_super) {
    __extends(TreeViewItemWithoutContext, _super);
    function TreeViewItemWithoutContext() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.itemGuid = guid();
        _this.onCheckChange = function (e) {
            _this.props.onCheckChange(e, _this.item, _this.itemId);
        };
        _this.onExpandChange = function (e) {
            _this.props.onExpandChange(e, _this.item, _this.itemId);
        };
        _this.onItemClick = function (e) {
            _this.props.onItemClick(e, _this.item, _this.itemId);
        };
        _this.onPress = function (e) {
            _this.props.onPress(e.event, _this.item, _this.itemId);
        };
        _this.onDrag = function (e) {
            _this.props.onDrag(e.event, _this.item, _this.itemId);
        };
        _this.onRelease = function (e) {
            _this.props.onRelease(e.event, _this.item, _this.itemId);
        };
        _this.assignDraggableMeta = function (element) {
            if (element) {
                element[DOM_KENDO_ITEM_ID_FIELD] = _this.props.itemId;
                element[DOM_KENDO_TREEVIEW_GUID_FIELD] = _this.props.treeGuid;
            }
        };
        return _this;
    }
    TreeViewItemWithoutContext.prototype.render = function () {
        var _this = this;
        var subitems = this.renderSubitemsIfApplicable();
        var itemInPart = this.renderItemInPart();
        return (React.createElement("li", { className: classNames('k-treeview-item'), tabIndex: this.tabIndex, role: "treeitem", "aria-expanded": this.ariaExpanded, "aria-selected": this.ariaSelected, "aria-checked": this.ariaChecked, "aria-disabled": this.disabled ? true : undefined, ref: function (el) { return _this.itemElement = el; } },
            React.createElement("div", { className: "k-treeview-".concat(this.props.position), ref: this.assignDraggableMeta },
                this.renderExpandIcon(),
                this.renderCheckbox(),
                this.props.draggable ?
                    React.createElement(Draggable, { onPress: this.onPress, onDrag: this.onDrag, onRelease: this.onRelease }, itemInPart) : itemInPart),
            this.props.animate ?
                React.createElement(Reveal, { transitionEnterDuration: 200, transitionExitDuration: 200, style: { display: 'block' }, children: subitems }) : subitems));
    };
    TreeViewItemWithoutContext.prototype.componentDidMount = function () {
        var focusedItemId = this.props.focusedItemId;
        var currentItemId = this.itemId;
        if (focusedItemId && focusedItemId === currentItemId) {
            this.props.onFocusDomElNeeded(this.itemElement);
        }
        if (this.checkboxElement) {
            this.checkboxElement.indeterminate = this.fieldsSvc.checkIndeterminate(this.item);
        }
    };
    TreeViewItemWithoutContext.prototype.componentDidUpdate = function (prevProps) {
        var focusedItemId = this.props.focusedItemId;
        if (focusedItemId &&
            focusedItemId !== prevProps.focusedItemId &&
            focusedItemId === this.itemId) {
            this.props.onFocusDomElNeeded(this.itemElement);
        }
        if (this.checkboxElement) {
            var indeterminate = this.fieldsSvc.checkIndeterminate(this.item);
            if (indeterminate !== prevProps.fieldsService.checkIndeterminate(prevProps.item)) {
                this.checkboxElement.indeterminate = indeterminate;
            }
        }
    };
    TreeViewItemWithoutContext.prototype.renderCheckbox = function () {
        var _a;
        var _this = this;
        if (this.props.checkboxes) {
            var size = this.props.size;
            return (React.createElement("div", { className: classNames('k-checkbox-wrap') },
                React.createElement("input", { type: "checkbox", className: classNames('k-checkbox k-rounded-md', (_a = {},
                        _a["k-checkbox-".concat(sizeMap[size] || size)] = size,
                        _a['k-disabled'] = this.disabled,
                        _a)), "aria-label": this.item.text, checked: Boolean(this.fieldsSvc.checked(this.item)), id: this.itemGuid, tabIndex: -1, onChange: this.onCheckChange, ref: function (el) { return _this.checkboxElement = el; } }),
                React.createElement("label", { className: "k-checkbox-label", htmlFor: this.itemGuid })));
        }
        else {
            return undefined;
        }
    };
    TreeViewItemWithoutContext.prototype.renderExpandIcon = function () {
        var renderExpand = this.props.expandIcons &&
            // If it is explicitly said that the item has children (even not loaded yet)
            // or if the item actually has children, then render the icon.
            (this.fieldsSvc.hasChildren(this.item) || hasChildren(this.item, this.fieldsSvc.getChildrenField()));
        return renderExpand && (
        // Allowing the toggle-button even with `disabled=true` might be a valid case!
        // Re-evaluate the classes bellow if such scenario occurs
        React.createElement("span", { className: classNames('k-treeview-toggle', { 'k-disabled': this.disabled }) },
            React.createElement("span", { className: this.getIconClassName(), onClick: this.onExpandChange })));
    };
    TreeViewItemWithoutContext.prototype.renderSubitemsIfApplicable = function () {
        var _this = this;
        var data = this.fieldsSvc.children(this.item);
        return isItemExpandedAndWithChildren(this.item, this.fieldsSvc) ?
            (React.createElement("ul", { className: "k-treeview-group", role: "group" }, data.map(function (item, index) {
                return (React.createElement(TreeViewItem, { item: item, position: index === 0 ? 'top' : index === data.length - 1 ? 'bot' : 'mid', itemId: createId(index, _this.itemId), treeGuid: _this.props.treeGuid, animate: _this.props.animate, focusedItemId: _this.props.focusedItemId, tabbableItemId: _this.props.tabbableItemId, fieldsService: _this.props.fieldsService, itemUI: _this.props.itemUI, checkboxes: _this.props.checkboxes, ariaMultiSelectable: _this.props.ariaMultiSelectable, onItemClick: _this.props.onItemClick, onFocusDomElNeeded: _this.props.onFocusDomElNeeded, draggable: _this.props.draggable, onPress: _this.props.onPress, onDrag: _this.props.onDrag, onRelease: _this.props.onRelease, expandIcons: _this.props.expandIcons, onExpandChange: _this.props.onExpandChange, onCheckChange: _this.props.onCheckChange, key: index, size: _this.props.size, disabled: _this.disabled }));
            }))) : undefined;
    };
    TreeViewItemWithoutContext.prototype.renderItemInPart = function () {
        return (React.createElement("span", { className: classNames('k-treeview-leaf', {
                'k-focus': this.props.focusedItemId === this.itemId,
                'k-selected': this.fieldsSvc.selected(this.item),
                'k-disabled': this.disabled
            }), style: { touchAction: 'none' }, onClick: this.onItemClick },
            React.createElement("span", { className: "k-treeview-leaf-text" }, this.props.itemUI ?
                React.createElement(this.props.itemUI, { item: this.item, itemHierarchicalIndex: this.itemId }) :
                this.fieldsSvc.text(this.item))));
    };
    Object.defineProperty(TreeViewItemWithoutContext.prototype, "fieldsSvc", {
        get: function () {
            return this.props.fieldsService;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TreeViewItemWithoutContext.prototype, "itemId", {
        get: function () {
            return this.props.itemId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TreeViewItemWithoutContext.prototype, "item", {
        get: function () {
            return this.props.item;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TreeViewItemWithoutContext.prototype, "tabIndex", {
        get: function () {
            return (this.props.focusedItemId || this.props.tabbableItemId) === this.itemId ? 0 : -1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TreeViewItemWithoutContext.prototype, "ariaExpanded", {
        get: function () {
            return this.fieldsSvc.hasChildren(this.item) || hasChildren(this.item, this.fieldsSvc.getChildrenField()) ?
                Boolean(this.fieldsSvc.expanded(this.item)) : undefined;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TreeViewItemWithoutContext.prototype, "disabled", {
        get: function () {
            return this.props.disabled || this.fieldsSvc.disabled(this.item);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TreeViewItemWithoutContext.prototype, "ariaChecked", {
        get: function () {
            if (this.props.checkboxes) {
                if (this.fieldsSvc.checked(this.item)) {
                    return 'true';
                }
                else {
                    return this.fieldsSvc.checkIndeterminate(this.item) ? 'mixed' : 'false';
                }
            }
            else {
                return undefined;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TreeViewItemWithoutContext.prototype, "ariaSelected", {
        get: function () {
            if (this.fieldsSvc.selected(this.item)) {
                return true;
            }
            else if (this.props.ariaMultiSelectable) {
                return this.disabled ? undefined : false;
            }
            else {
                return undefined;
            }
        },
        enumerable: false,
        configurable: true
    });
    TreeViewItemWithoutContext.prototype.getIconClassName = function () {
        var expanded = this.fieldsSvc.expanded(this.item);
        return classNames('k-icon', {
            'k-i-caret-alt-right': !expanded,
            'k-i-caret-alt-down': expanded,
            'k-i-loading': expanded && !hasChildren(this.item, this.fieldsSvc.getChildrenField())
        });
    };
    TreeViewItemWithoutContext.defaultProps = {
        position: 'top'
    };
    return TreeViewItemWithoutContext;
}(React.Component));
/**
 * @hidden
 */
export var TreeViewItem = React.forwardRef(function (props, ref) {
    var contextPropsCallback = React.useContext(TreeViewItemPropsContext);
    var treeViewItemProps = contextPropsCallback.call(undefined, props);
    return (React.createElement(TreeViewItemWithoutContext, __assign({ ref: ref }, treeViewItemProps)));
});
TreeViewItem.displayName = 'TreeViewItem';
