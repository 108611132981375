"use strict";

import {
  LEADING_SECTION,
  SECTIONS,
  TECH_TRANSFER_SECTION
} from "../../../../../../fieldsConfig/constants/configurable_tables_sections_config";
import { SENDING_SITE_LABEL } from "../../../../../../fieldsConfig/constants/configurable_tables_columns_config";
import React, { Fragment, useEffect, useState } from "react";

import * as styles from "./column_selection.module.scss";
import { ColumnSelectionHeader } from "./column_selection_header";
import { ColumnSelectionFooter } from "./column_selection_footer";
import {
  ASSESSMENT_MODE_SELECTOR,
  ASSESSMENT_MODE_SELECTOR_ACCEPTANCE_CRITERIA_RANGES,
  ASSESSMENT_MODE_SELECTOR_CRITICALITY_ASSESSMENT,
} from "../../../../../../columnSelection/constants";
import { useSidePanel } from "../../widgets/side_panel_provider";
import {
  filterFields,
  getColumnProp
} from "../../../../../../columnSelection/configurable_tables_column_selection_helper";
import { AssessmentModeSelector } from "./assessment_mode_selector";

const filterSections = (sections) => {
  return sections.filter(section => ![LEADING_SECTION, TECH_TRANSFER_SECTION].includes(section.name));
};

const filteredVisibleColumns = (name, visibleTableColumns = []) =>
  visibleTableColumns.filter(column => column.section === name && (!column.siteForValueEvaluation || column.siteForValueEvaluation === SENDING_SITE_LABEL));

export const ColumnSelection = (
  {
    selectedModelType,
    showAssessmentOptions,
    visibleTableColumns,
    onResetToDefaults,
    onVisibleTableColumnsChanged,
    enableAssessmentOptions = false,
  }) => {

  const {toggle} = useSidePanel();
  const [selectedColumns, setSelectedColumns] = useState({});
  const [assessmentModeChange, setAssessmentModeChange] = useState("");
  const [resetToDefault, setResetToDefault] = useState(false);

  useEffect(() => {

    if (!enableAssessmentOptions) {
      localStorage.setItem(ASSESSMENT_MODE_SELECTOR, ASSESSMENT_MODE_SELECTOR_ACCEPTANCE_CRITERIA_RANGES);
    }

    const value = enableAssessmentOptions ? localStorage.getItem(ASSESSMENT_MODE_SELECTOR) || ASSESSMENT_MODE_SELECTOR_CRITICALITY_ASSESSMENT : ASSESSMENT_MODE_SELECTOR_ACCEPTANCE_CRITERIA_RANGES;
    setAssessmentModeChange(value);

  }, []);

  useEffect(() => {
    if (Object.keys(selectedColumns).length) {
      setSelectedColumns({});
    }
  }, [selectedModelType]);

  const onSelectChange = ({target}) => {
    const {id, checked} = target;
    setSelectedColumns({...selectedColumns, [id]: checked});
  };

  const onResetColumnSelection = () => {
    const fields = filterFields(visibleTableColumns, false);
    let defaults = {};
    for (const column of fields) {
      const selectorId = getColumnProp(column, false);
      defaults[selectorId] = column["defaultValue"];
    }
    setSelectedColumns({...defaults});
    setResetToDefault(true);
  };

  const handleApply = () => {

    if (assessmentModeChange) {
      localStorage.setItem(ASSESSMENT_MODE_SELECTOR, assessmentModeChange);
      [...document.querySelectorAll("#DetailsTableContainer")].forEach(item => {
        item.className = assessmentModeChange;
      });
    }

    const selectorIds = new Map(Object.entries(selectedColumns));
    if (resetToDefault) {
      onResetToDefaults(selectorIds);
      onVisibleTableColumnsChanged(selectorIds);
      setResetToDefault(false);
      setSelectedColumns({});
    } else {
      onVisibleTableColumnsChanged(selectorIds);
    }
    toggle();

  };

  const onAssessmentChange = (value) => {
    setAssessmentModeChange(value);
  };

  return (
    <div id="columnSelectionTooltipDiv" className={styles["side-panel"]}>

      <ColumnSelectionHeader onResetSelection={onResetColumnSelection} />
      <div className="configurable-tables-column-selection-body-container">

        <AssessmentModeSelector enableAssessmentOptions={enableAssessmentOptions}
                                assessmentModeChange={assessmentModeChange}
                                showAssessmentOptions={showAssessmentOptions}
                                onAssessmentChange={onAssessmentChange}
        />

        {selectedModelType && filterSections(SECTIONS[selectedModelType])
          .map(({name, title}, index) =>
            (
              <Fragment key={index}>
                {title && <div className="column-selection-entry-group">{title}</div>}
                {filteredVisibleColumns(name, visibleTableColumns)
                  .map(({id, title, checked, columnSelectionTitle}, index) => {
                    return (
                      <div className="column-selection-entry"
                           key={index}
                      >
                        <div className="col-10">
                          {columnSelectionTitle || title}
                        </div>
                        <div className="col-2 column-selection-checkbox">
                          <input type="checkbox"
                                 id={id}
                                 checked={selectedColumns[id] ?? checked}
                                 onChange={onSelectChange}
                                 disabled={title === "ID"}
                          />
                        </div>
                      </div>
                    );
                  })}
              </Fragment>
            ))}
      </div>
      <ColumnSelectionFooter onColumnSelectionChange={handleApply} />
    </div>
  );
};