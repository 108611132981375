"use strict";

const CommonUtils = require("../generic/common_utils");

/**
 * This class is responsible for sorting process parameters (PPs) and their associated parent Process Components (PRCs)/Materials (MTs) in a given UO/Step.
 * The word "parent" in this file always refers to the PRC/MT for the PP.
 */
class ProcessParameterIndexer {
  constructor(results, uoToPPMap, stpToPPMap) {
    this.ppMap = results.ppMap;
    this.uoMap = results.uoMap;
    this.stpMap = results.stpMap;
    this.prcMap = results.prcMap;
    this.mtMap = results.mtMap;
    this.uoToPPMap = uoToPPMap;
    this.stpToPPMap = stpToPPMap;
  }

  /**
   * @return {[integer]} the ids of the process parameters that this UO has tracked, in order for the side panel.
   */
  static getProcessParameterOrder(operation) {
    const {RecordOrder} = operation;
    let recordOrder = RecordOrder && RecordOrder.processParameterOrder ? RecordOrder.processParameterOrder : [];
    return typeof recordOrder === "string" ? JSON.parse(recordOrder) : recordOrder;
  }

  /**
   * Sorts the records based on the order of the ids of the records in sortedIds.
   * @param sortedIds {[integer]} A list of sorted Ids
   * @param records {[*]} An array of records with an "id" attribute to sort on.
   * @return {[*]} The record array except the elements will be sorted properly.
   */
  static sortRecordsBasedOnIDArray(sortedIds, records) {
    if (records.length === 0) {
      return [];
    }

    const idToRecordMap = new Map();
    for (const record of records) {
      idToRecordMap.set(CommonUtils.parseInt(record.id), record);
    }
    return ProcessParameterIndexer.sortRecordMapBasedOnIDArray(sortedIds, idToRecordMap);
  }

  /**
   * Sorts a map of id -> records based on the order of the ids of the records in sortedIds.
   * @param sortedIds {[integer]} A list of sorted Ids
   * @param idToRecordMap {Map<Integer, Object>} A map of integer ids to records that needs to be sorted.
   * @return {[*]} An array of sorted records.
   */
  static sortRecordMapBasedOnIDArray(sortedIds, idToRecordMap) {
    let sortedRecords = [];
    idToRecordMap = idToRecordMap || new Map();
    for (const id of sortedIds) {
      const record = idToRecordMap.get(id);
      if (record) {
        sortedRecords.push(record);
      }

      idToRecordMap.delete(id);
    }

    // Go through the remaining values and sort them by createdAt
    const remainingRecords = Array.from(idToRecordMap.values()).sort(CommonUtils.sortBy("name"));
    sortedRecords = sortedRecords.concat(remainingRecords);

    return sortedRecords;
  }

  /**
   * Given a unit operation Id, returns an array of objects that are sorted and contain the PP and the PRC/MT.
   * @param uoId The ID of the Unit Operation (UO)
   * @return [{*}] An array of objects that contain the process parameter, and it's parent.
   */
  prepareRecordsInUO(uoId) {
    const uo = this.uoMap[uoId];
    const recordOrder = ProcessParameterIndexer.getProcessParameterOrder(uo);
    const processParameters = this.uoToPPMap.get(uoId);
    return this._prepareRecordsInOperation(recordOrder, processParameters);
  }

  /**
   * Given a step Id, returns an array of objects that are sorted and contain the PP and the PRC/MT.
   * @param stepId The ID of the Step (STP)
   * @return [{*}] An array of objects that contain the process parameter, and it's parent.
   */
  prepareRecordsInStep(stepId) {
    const step = this.stpMap[stepId];
    const recordOrder = ProcessParameterIndexer.getProcessParameterOrder(step);
    const processParameters = this.stpToPPMap.get(stepId);
    return this._prepareRecordsInOperation(recordOrder, processParameters);
  }

  _prepareRecordsInOperation(recordOrder, processParameters) {
    processParameters = ProcessParameterIndexer.sortRecordMapBasedOnIDArray(recordOrder, processParameters);

    // Add in the parent info
    for (const processParameter of processParameters.filter(pp => pp)) {
      if (processParameter.ProcessComponentId) {
        processParameter.parent = this.prcMap[processParameter.ProcessComponentId];
      } else {
        processParameter.parent = this.mtMap[processParameter.MaterialId];
      }
    }
    return processParameters;
  }
}

module.exports.ProcessParameterIndexer = ProcessParameterIndexer;
