"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseLinkedEntitiesAttribute from "../../editor/attributes/base_linked_entities_attribute";
import { RISK_ATTRIBUTES_TABS_TO_RISK_TYPES, RISK_COLORS } from "../constants/rmp_constants";
import { CUSTOM_ERROR_TYPE } from "../../editor/attributes/base_json_attribute";
import { getRMPForModel } from "../rmp_helper";
import * as RiskHelper from "../../helpers/risk_helper";
import * as CommonUtils from "../../../server/common/generic/common_utils";
import { Ensure } from "../../../server/common/generic/common_ensure";
import RiskUtils from "../../../server/common/misc/common_risk_utils";

export const RMP_RISK_SCORE_ATTRIBUTE_TYPE = {
  IMPACT: "Impact",
  UNCERTAINTY: "Uncertainty",
  CAPABILITY_RISK: "Capability Risk",
  DETECTABILITY_RISK: "Detectability Risk"
};

/**
 * This is class is responsible for handling Risk Management Plan risk score attributes.
 */
export default class RMPRiskScoreAttribute extends BaseLinkedEntitiesAttribute {
  constructor(props, widgetFields) {
    super(props, widgetFields);

    // Bind functions to this
    this.getAvailableRiskScoresForEditedRow = this.getAvailableRiskScoresForEditedRow.bind(this);
    this.getRiskScoreOptions = this.getRiskScoreOptions.bind(this);
    this.getRiskScoreDefaultOption = this.getRiskScoreDefaultOption.bind(this);
    this.getRiskColors = this.getRiskColors.bind(this);
    this.isRiskScoreDisabled = this.isRiskScoreDisabled.bind(this);

    this.loaded = false;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (((nextProps.configureByType && !nextProps.modelName) ||
      (!nextProps.configureByType && nextProps.modelName))) {
      return false;
    } else if (!nextProps.isParentTabActive && nextProps.formValidationMode !== CommonUtils.FORM_VALIDATION_MODE.PROPOSE) {
      return false;
    }

    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      let rmp = UIUtils.deepClone(this.props.rmp);
      let nextRmp = UIUtils.deepClone(nextProps.rmp);

      let modelRMP = getRMPForModel(rmp, this.props.modelName, nextProps.parent);
      modelRMP.boundaries = RiskHelper.getRiskBoundaries(modelRMP);
      let nextModelRMP = getRMPForModel(nextRmp, nextProps.modelName, nextProps.parent);
      nextModelRMP.boundaries = RiskHelper.getRiskBoundaries(nextModelRMP);
      let riskScales = RiskHelper.getRiskScales(RISK_ATTRIBUTES_TABS_TO_RISK_TYPES[nextProps.riskType], modelRMP) || [];
      let nextRiskScales = RiskHelper.getRiskScales(RISK_ATTRIBUTES_TABS_TO_RISK_TYPES[nextProps.riskType], nextModelRMP) || [];

      let rowRiskScores = riskScales.map(riskScale => riskScale.riskScore).join(",");
      let nextRowRiskScores = nextRiskScales.map(riskScale => riskScale.riskScore).join(",");

      return !this.loaded
        || nextProps.minRiskScore !== this.props.minRiskScore
        || nextProps.maxRiskScore !== this.props.maxRiskScore
        || nextProps.isParentTabActive !== this.props.isParentTabActive
        || nextProps.configureByType !== this.props.configureByType
        || nextProps.useUncertainty !== this.props.useUncertainty
        || nextProps.useDetectability !== this.props.useDetectability
        || nextProps.useNotAssessed !== this.props.useNotAssessed
        || rowRiskScores !== nextRowRiskScores
        || (nextProps.riskScoreOptions ? nextProps.riskScoreOptions.join() : "") !== (this.props.riskScoreOptions ? this.props.riskScoreOptions.join() : "");
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    if (((this.props.configureByType && !this.props.modelName) ||
      (!this.props.configureByType && this.props.modelName))) {
      return false;
    } else if (!this.props.isParentTabActive && this.props.formValidationMode !== CommonUtils.FORM_VALIDATION_MODE.PROPOSE) {
      return false;
    }

    super.componentDidUpdate(prevProps);
    if (!this.loaded) {
      this.props.onRowsUpdated(this.getValueAsObject(), this.type);
      this.loaded = true;
    } else if (this.props.useUncertainty !== prevProps.useUncertainty ||
      this.props.useDetectability !== prevProps.useDetectability) {
      this.props.onRowsUpdated(this.getValueAsObject(), this.type);
    }
    return true;
  }

  handleAddToList(editedRow, e) {
    let availableOptions = this.getAvailableRiskScoresForEditedRow();

    if (availableOptions.length === 0) {
      e.preventDefault();
      this.setError(`No more ${this.type} rows can be added. All available risk score range has been used.`);
      this.forceUpdateSafely();
      return false;
    } else {
      editedRow = editedRow ? editedRow : {modelName: this.props.modelName};
      editedRow.uuid = this.generateUUID(editedRow);
      super.handleAddToList(editedRow, e);
    }
  }

  handleSave(rowData, rows, setValueCallback) {
    let editedRow = this.getEditedRowByUUID(rowData.uuid);
    rows = rows ? rows : this.getValueAsObject();

    if (editedRow.alwaysCritical) {
      rows.filter(row => UIUtils.parseInt(row.riskScore) > UIUtils.parseInt(editedRow.riskScore)).map(row => {
        row.alwaysCritical = true;
      });

      this.editedRows.filter(row => UIUtils.parseInt(row.riskScore) > UIUtils.parseInt(editedRow.riskScore)).map(row => {
        row.alwaysCritical = true;
      });
    }

    rowData.potential = editedRow.potential;
    rowData.modelName = this.props.modelName;
    super.handleSave(rowData, rows, setValueCallback);
  }

  getRiskColors() {
    return Object.keys(RISK_COLORS).map(color => {
      return <option key={color}
                     value={RISK_COLORS[color]}
      >
        {RISK_COLORS[color]}
      </option>;
    });
  }

  isRiskColorDisabled(rowData) {
    return this.isNotAssessedRow(rowData);
  }

  isRiskScoreDisabled(rowData) {
    return this.isNotAssessedRow(rowData);
  }

  isAlwaysCriticalDisabled(rowData) {
    if (this.isNotAssessedRow(rowData)) {
      return true;
    }

    let editedRow = this.getEditedRowByUUID(rowData.uuid);
    let rows = this.getValueAsObject();
    return rows.find(row => UIUtils.parseInt(row.riskScore) < UIUtils.parseInt(editedRow.riskScore) && row.alwaysCritical);
  }

  getAlwaysCriticalDefaultValue(field, rowData) {
    let rows = this.getValueAsObject();
    return !!rows.find(row => UIUtils.parseInt(row.riskScore) < UIUtils.parseInt(rowData.riskScore) && row.alwaysCritical);
  }

  /**
   * Overwrite this method in child classes to control how a table is reloaded provided a collection of rows
   * @param rowData
   */
  reloadDataTable(rowData) {
    rowData.forEach(row => {
      row.riskScoreOriginal = UIUtils.extactNumberFromReactElement(row.riskScore);
    });

    rowData = rowData.sort(CommonUtils.sortBy("riskScoreOriginal"));

    super.reloadDataTable(rowData);

    if (this.tableRef) {
      const table = $(this.tableRef).DataTable();
      try {
        // uncomment this when debugging
        // console.warn(">> [RMPRiskScoreAttribute] Adjusting data table widths: ", this.tableRef, table);
        table.columns.adjust();
      } catch (error) {
        // if an error happens while adjusting column widths, we can ignore it
        console.error("[RMPRiskScoreAttribute] Error adjusting column widths", error, this.tableRef, table);
      }
    }
  }


  /**
   * Overwrite this in child classes to customize the rendering of a particular cell.
   * This method will cover the field types returned by BaseJsonAttribute.getSupportedFieldTypes()
   * Overwrite getFieldInput to further customize the input controls used in table cell inline editors
   * @param field The field to customize the column cell for
   * @param rowData The row data object
   */
  getColumnDef(field, rowData) {
    let columnDef = super.getColumnDef(field, rowData);

    if (field.fieldName === "color" && !this.isRowInEditMode(rowData.uuid) && !this.isDiffingVersions()) {
      const id = this.props.name + "_" + CommonUtils.capitalize(field.fieldName) + "_" + rowData.index;
      let fieldValue = field.getValue(rowData);

      columnDef = (
        <div className="links-manage">
          <div id={id}
               className={"rmp-criticality-color rmp-criticality-color-" + fieldValue.toLowerCase()}
          />
        </div>
      );
    }
    return columnDef;
  }

  /**
   * Generic handler to update the edited row field with a user provided value
   * @param rowData The row to update the edited row for
   * @param field The field to update with the provided data
   * @param e The event object holding the user provided data
   */
  handleChangeField(rowData, field, e) {
    super.handleChangeField(rowData, field, e);

    if (field.fieldName === "riskScore") {
      let rows = this.getValueAsObject();
      if (rows && rows.length > 1) {
        let editedRow = this.getEditedRowByUUID(rowData.uuid);
        rowData.alwaysCritical = !!rows.find(row => UIUtils.parseInt(row.riskScore) < UIUtils.parseInt(editedRow.riskScore) && row.alwaysCritical);
      }
    }

    if (field.fieldName === "riskScore" && this._isMounted) {
      this.forceUpdate();
    }
  }

  /**
   * Updates the state of the parent object with the value that need to be passed to the backed for persisting it into the database.
   * You can overwrite this in child classes to support different structures other than JSON for more complex scenarios.
   * @param rows
   */
  setValue(rows) {
    super.setValue(rows);
    this.props.onRowsUpdated(rows, this.type);
  }

  /**
   * Updates the UI with a custom error
   * @param error The error to show on the UI
   * @param errorType The error type based on if it was raised due to a save or propose action.
   */
  setError(error, errorType = CUSTOM_ERROR_TYPE.FOR_SAVE) {
    let errorStr = "";
    if (Array.isArray(error)) {
      errorStr = error.length > 1 ? error.map(e => e.includes("•") ? e : `• ${e}`) : error.map(e => e.replace("•", "").trim());
    } else {
      errorStr = error.replace("•", "").trim();
    }

    super.setError(errorStr, errorType);
    this.props.parent.setError(this.props.modelName + this.tabContainerType.title + "TabError", errorStr);
  }

  clearErrorText(forceUpdate) {
    super.clearErrorText(forceUpdate);
    this.props.parent.clearError(this.props.modelName + this.tabContainerType.title + "TabError");
  }

  /**
   * Returns true or false, depending on if the controls add button should be disabled or not during rendering.
   */
  isAddButtonDisabled() {
    let isDisabled = super.isAddButtonDisabled();
    let availableOptions = this.getAvailableRiskScoresForEditedRow();

    isDisabled = isDisabled || (availableOptions.length === 0);
    return isDisabled;
  }

  /**
   * Returns the add button disabled reason which shows up as a tooltip for the add button when it is disabled.
   */
  getAddButtonDisabledReason() {
    return "No more rows can be added as all risk score options have been specified.";
  }

  /**
   * Returns true or false, depending on if the delete row button should be disabled or not
   * @param rowData {*} The data for the row being evaluated.
   */
  isDeleteButtonDisabled(rowData) {
    Ensure.virtual("isDeleteButtonDisabled", {rowData});
    return this.isNotAssessedRow(rowData);
  }

  /**
   * Returns the delete button disabled reason which shows up as a tooltip for the add button when it is disabled.
   */
  getDeleteButtonDisabledReason(rowData) {
    if (this.isNotAssessedRow(rowData)) {
      return "It is not possible to delete risk score row because this RMP has been marked to use the 'Not Assessed Score'.";
    } else {
      return "It is not possible to delete a risk score row because this RMP is already used by one or more projects.";
    }
  }

  /**
   * Turns on the inline editor for the provided row.
   * @param rowData The row data to populate the inline editor controls with
   * @param editedRow Optional the edited row, if this method is called from a child class where the edited row is already initialized.
   * @param forceUpdate Set to true to clear any control errors and force a react update
   */
  handleEdit(rowData, editedRow, forceUpdate = true) {
    editedRow = editedRow ? editedRow : {};
    super.handleEdit(rowData, editedRow, false);
    this.clearErrorText(forceUpdate);
  }

  getAvailableRiskScoresForEditedRow(rowId) {
    let editedRowsUUIDs = this.editedRows.map(editedRow => {
      return editedRow.uuid;
    });

    let editedRowOptions = this.editedRows.filter(editedRow => {
      return (!rowId || editedRow.uuid !== rowId);
    }).map(editedRow => {
      return UIUtils.parseInt(editedRow.riskScore);
    });

    let nonEditedRowOptions = this.getValueAsObject().filter(row => {
      return !editedRowsUUIDs.includes(row.uuid);
    }).map(row => {
      return UIUtils.parseInt(row.riskScore);
    });

    let allUsedOptions = editedRowOptions.concat(nonEditedRowOptions);
    return this.props.riskScoreOptions.filter(option => {
      return !allUsedOptions.includes(option);
    });
  }

  getRiskScoreOptions(field, rowId) {
    let availableRiskScores = this.getAvailableRiskScoresForEditedRow(rowId);
    const editedRow = this.getEditedRowByUUID(rowId);

    const riskScoreOptins = [...this.props.riskScoreOptions];
    if (editedRow && this.isNotAssessedRow(editedRow)) {
      riskScoreOptins.unshift(RiskUtils.NOT_ASSESSED_SCORE);
      availableRiskScores.unshift(RiskUtils.NOT_ASSESSED_SCORE);
    }

    return riskScoreOptins.filter(option => {
      return availableRiskScores.includes(option);
    }).map(option => <option key={option}
                             value={option}
      >
        {option}
      </option>
    );
  }

  getRiskScoreDefaultOption(field, editedRow) {
    let availableOptions = this.getAvailableRiskScoresForEditedRow(editedRow.uuid);
    return availableOptions.length > 0 ? availableOptions[0] : null;
  }

  /**
   * Validates the data this specific attribute holds against proposing. For each field in each row that is not
   * good for proposing, it renders an error on the UI.
   * @param formValidationMode Checks if validation is running against save or propose
   * @returns {boolean}
   */
  validate(formValidationMode) {
    if (formValidationMode === CommonUtils.FORM_VALIDATION_MODE.SAVE) {
      return this.validateEditedRows();
    }

    let isValid = super.validate(formValidationMode);
    if (isValid) {
      let errors = [];
      let minRiskScore = UIUtils.parseInt(this.props.minRiskScore);
      let maxRiskScore = UIUtils.parseInt(this.props.maxRiskScore);
      let allRows = this.getValueAsObject();

      if (allRows.length === 0) {
        errors.push(`No ${CommonUtils.capitalize(this.type)} rows have been specified.`);
      }

      for (let row of allRows.filter(row => !this.isNotAssessedRow(row))) {
        if ((UIUtils.parseInt(row.riskScore) < minRiskScore) || (UIUtils.parseInt(row.riskScore) > maxRiskScore)) {
          errors.push("Risk score " + row.riskScore + " for row " + row.scoreLabel + " is outside the defined min & max risk score boundaries [" + minRiskScore + "-" + maxRiskScore + "]");
        }
      }

      if (errors.length > 0) {
        this.setError(errors);
        this.forceUpdateSafely();
        isValid = false;
      }
    }

    return isValid;
  }

  isNotAssessedRow(row) {
    return RiskUtils.scoreIsNotAssessed(row.riskScore);
  }
}
