var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as PropTypes from 'prop-types';
import { DialogTitleBar } from './DialogTitleBar';
import { DialogActionsBar } from './DialogActionsBar';
import { guid, dispatchEvent, Keys, canUseDOM, ZIndexContext, createPropsContext, withPropsContext } from '@progress/kendo-react-common';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from './package-metadata';
import { getMaxZIndex } from './utils';
import { DATA_DIALOGS_ID, DEFAULT_DIALOGS_ZINDEX, ZINDEX_DIALOGS_STEP } from './constants';
// tslint:enable:max-line-length
/** @hidden */
var DialogWithoutContext = /** @class */ (function (_super) {
    __extends(DialogWithoutContext, _super);
    function DialogWithoutContext(props) {
        var _this = _super.call(this, props) || this;
        /**
         * @hidden
         */
        _this.context = 0;
        _this._id = guid();
        _this.titleId = _this.generateTitleId();
        _this.contentId = _this.generateContentId();
        /**
         * @hidden
         */
        _this.onCloseDialog = function (event) {
            event.preventDefault();
            dispatchEvent(_this.props.onClose, event, _this, undefined);
        };
        /**
         * @hidden
         */
        _this.onKeyDown = function (event) {
            if (event.keyCode === Keys.esc && _this.props.onClose) {
                event.preventDefault();
                _this.onCloseDialog(event);
            }
            var kDialog = _this.element;
            if (kDialog && (event.keyCode === Keys.tab)) {
                var focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
                var focusableContent = kDialog.querySelectorAll(focusableElements);
                var firstFocusableElement = focusableContent[0];
                var lastFocusableElement = focusableContent[focusableContent.length - 1];
                var currentDocument = _this.getDocument();
                if (event.shiftKey) {
                    if ((currentDocument && currentDocument.activeElement === firstFocusableElement) ||
                        currentDocument && currentDocument.activeElement === _this.element) {
                        lastFocusableElement.focus();
                        event.preventDefault();
                    }
                }
                else {
                    if (currentDocument && currentDocument.activeElement === lastFocusableElement) {
                        firstFocusableElement.focus();
                        event.preventDefault();
                    }
                }
            }
        };
        _this.getCurrentZIndex = function () {
            if (!_this.state || _this.context === undefined) {
                return _this.context ? _this.context : DEFAULT_DIALOGS_ZINDEX;
            }
            return _this.state.zIndex > (_this.context ? _this.context + ZINDEX_DIALOGS_STEP : 0) ? _this.state.zIndex : _this.context + ZINDEX_DIALOGS_STEP;
        };
        _this.getDocument = function () {
            return _this.props.appendTo ? _this.props.appendTo.ownerDocument : document;
        };
        validatePackage(packageMetadata);
        _this.state = {
            zIndex: getMaxZIndex(_this.getCurrentZIndex(), _this.getDocument(), _this._id)
        };
        return _this;
    }
    /**
     * @hidden
     */
    DialogWithoutContext.prototype.componentDidMount = function () {
        if (this.element) {
            if (this.props.autoFocus) {
                this.element.focus();
            }
        }
    };
    /**
     * @hidden
     */
    DialogWithoutContext.prototype.render = function () {
        var _a;
        var _this = this;
        var id = this.props.id !== undefined ? this.props.id : this.titleId;
        var _b = this.props, title = _b.title, width = _b.width, height = _b.height, children = _b.children, minWidth = _b.minWidth, dir = _b.dir, style = _b.style, contentStyle = _b.contentStyle;
        var elementsArray = React.Children.toArray(children);
        var content = this.getContent(elementsArray);
        var actions = this.getActionBar(elementsArray);
        var aria = title ? {
            'aria-labelledby': id
        } : null;
        var closeIcon = this.props.closeIcon !== undefined ? this.props.closeIcon : true;
        var currentZIndex = this.getCurrentZIndex();
        var dialogElement = (React.createElement(ZIndexContext.Provider, { value: currentZIndex },
            React.createElement("div", __assign({}, (_a = {}, _a[DATA_DIALOGS_ID] = this._id, _a), { className: 'k-dialog-wrapper' + (this.props.className ? ' ' + this.props.className : ''), onKeyDown: this.onKeyDown, tabIndex: 0, dir: dir, style: __assign({ zIndex: currentZIndex }, style), ref: (function (el) { return _this.element = el; }) }),
                React.createElement("div", { className: "k-overlay" }),
                React.createElement("div", __assign({}, aria, { className: "k-widget k-window k-dialog", role: "dialog", "aria-modal": true, "aria-describedby": this.contentId, style: { width: width, height: height, minWidth: minWidth } }),
                    this.props.title &&
                        React.createElement(DialogTitleBar, { closeIcon: closeIcon, onCloseButtonClick: this.onCloseDialog, id: id }, title),
                    React.createElement("div", { className: "k-window-content k-dialog-content", style: contentStyle, id: this.contentId }, content),
                    actions))));
        if (canUseDOM) {
            return this.props.appendTo !== null ?
                ReactDOM.createPortal(dialogElement, this.props.appendTo || document.body) :
                dialogElement;
        }
        return null;
    };
    DialogWithoutContext.prototype.getActionBar = function (children) {
        return children.filter(function (child) { return child && child.type === DialogActionsBar; });
    };
    DialogWithoutContext.prototype.getContent = function (children) {
        return children.filter(function (child) {
            return child && child.type !== DialogActionsBar;
        });
    };
    DialogWithoutContext.prototype.generateTitleId = function () {
        return 'dialog-title' + this._id;
    };
    DialogWithoutContext.prototype.generateContentId = function () {
        return 'dialog-content' + this._id;
    };
    DialogWithoutContext.displayName = 'Dialog';
    /**
     * @hidden
     */
    DialogWithoutContext.propTypes = {
        title: PropTypes.any,
        id: PropTypes.string,
        dir: PropTypes.string,
        style: PropTypes.object,
        closeIcon: PropTypes.bool,
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        autoFocus: PropTypes.bool
    };
    /**
     * @hidden
     */
    DialogWithoutContext.defaultProps = {
        autoFocus: false
    };
    /**
     * @hidden
     */
    DialogWithoutContext.contextType = ZIndexContext;
    return DialogWithoutContext;
}(React.Component));
/**
 * Represents the PropsContext of the `Dialog` component.
 * Used for global configuration of all `Dialog` instances.
 */
export var DialogPropsContext = createPropsContext();
/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the component the same as the type */
/**
 * Represents the [KendoReact Dialog component]({% slug overview_dialog %}).
 *
 * Accepts properties of type [DialogProps]({% slug api_dialogs_dialogprops %}).
 * Obtaining the `ref` returns an object of type [DialogHandle]({% slug api_dialogs_dialoghandle %}).
 */
export var Dialog = withPropsContext(DialogPropsContext, DialogWithoutContext);
Dialog.displayName = 'KendoReactDialog';
