"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import { BaseUserRecord } from "./base_user_record";
import TextAttribute from "../../editor/attributes/text_attribute";
import CheckboxAttribute from "../../editor/attributes/checkbox_attribute";
import ComboBoxAttribute from "../../editor/attributes/combo_box_attribute";
import DateAttribute from "../../editor/attributes/date_attribute";
import TypeaheadAttribute from "../../editor/attributes/typeahead_attribute";
import { EXPERIMENTS } from "../../helpers/constants/constants";


/**
 * This class responsible for showing the user profile, and allowing it to be edited.
 */
export default class User extends BaseUserRecord {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();

    document.title = "QbDVision User";

    this.setStateSafely({
      breadcrumb: {
        current: "New User",
        parent: this.getBreadcrumbParentName(),
        parentLink: "/users/list.html",
      }
    });
  }

  getBreadcrumbParentName() {
    return "Users";
  }

  getRecordTypeName() {
    return "user";
  }

  getCapitalizedRecordTypeName() {
    return "User";
  }

  renderGeneralSection() {
    const {
      id,
      loggedInUser,
      usernameRequired,
      isMasterAccount,
      showIdentityProvider,
      identityProviders,
      hasUniqueEmailInCompany,
      username,
    } = this.state;

    const isTranslationExperimentEnabled = UIUtils.isExperimentEnabled(EXPERIMENTS.Translation);
    const isInternalUserEditingOtherCompaniesUsers = this.userConditionChecker.isInternalUserEditingOtherCompaniesUsers();
    const showLanguageDropdown = id && UIUtils.getUserId() === id && isTranslationExperimentEnabled;

    let identityProvidersOptions = identityProviders || loggedInUser.identityProviders || [];
    identityProvidersOptions = identityProvidersOptions.filter(provider => provider);

    return (
      <Fragment>
        <div className="row">
          <TextAttribute name="firstName" isLoading={this.state.isLoading} parent={this} required={true} className="col-sm-4" />
          <TextAttribute name="lastName" isLoading={this.state.isLoading} parent={this} required={true} className="col-sm-4" />
          <TextAttribute name="suffix" isLoading={this.state.isLoading} parent={this} className="col-sm-2" />
        </div>
        <div className="row">
          {showLanguageDropdown ? this.userRecordRenderer.renderLanguagesField() : ""}
          <CheckboxAttribute name="enableEmailNotifications" parent={this} className="col-sm-4" />
          {((!usernameRequired && isMasterAccount) || this.isAdd()) && showIdentityProvider ?
            <ComboBoxAttribute name="identityProvider"
                               className="col-sm-4"
                               options={identityProvidersOptions}
                               default={identityProvidersOptions && identityProvidersOptions.length >= 1 && this.isAdd() ?
                                 identityProvidersOptions[1] : identityProvidersOptions[0]}
                               disabled={!this.userConditionChecker.isExternalProviderFieldEnabled()}
                               parent={this}
                               isLoading={this.state.isLoading || identityProvidersOptions.length === 0}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            /> : ""}
          {usernameRequired || (username && !hasUniqueEmailInCompany) ? (
            <TextAttribute name="username"
                           parent={this}
                           dataPatternError="The username must start with an alphanumeric character, can contain any of '-', '_', '+', '.' characters and must end with an alphanumeric character."
                           pattern="^[a-zA-Z0-9]+(([\.\+_\-]*)?[a-zA-Z0-9])*$"
                           required={true}
                           className="col-sm-4"
            />
          ) : ""}
        </div>
        <div className="row">
          <TextAttribute name="title" isLoading={this.state.isLoading} parent={this} displayName="Job Title" className="col-sm-4" />
          <DateAttribute
            name="hireDate"
            isLoading={this.state.isLoading}
            parent={this}
            displayName="Hire Date"
            className="col-sm-4"
          />
          {
            isInternalUserEditingOtherCompaniesUsers
              ? ""
              : <TypeaheadAttribute
                allowEmpty
                name="supervisor"
                displayName={"Supervisor"}
                tooltip={"Should be the user's direct supervisor"}
                parent={this}
                parentId={this.state.id}
                typeaheadType="User"
                filter={this.filterOutSelf}
                className="col-sm-4"
                disabled={!this.userConditionChecker.isSupervisorEnabled()}
              />
          }
        </div>
      </Fragment>
    );
  }
}