"use strict";

/**
 * This file implements security related functions for all import flows on the client side.
 */

import { can } from "../../utils/ui_permissions";
import * as UIUtils from "../../ui_utils";

/**
 * This loads the import dependencies given some project Id.
 * @param projectId The projectId
 * @param processId The processId
 */
module.exports.getImportDependencies = function(projectId, processId) {
  if (projectId) {
    return UIUtils.secureAjaxGET(`import/get/getDependencies`, {
      projectId,
      processId: JSON.stringify([processId])
    }).then(result => {
      return new Map(Object.entries(result));
    });
  }

  return Promise.resolve();
};

/**
 * This checks if the necessary dependencies are met in the database for a given project and process so that a
 * particular import flow should be allowed.
 * @param dependenciesStatus The status of the dependencies in the database.
 * @param importConfig The configuration of the import flow, as defined in the import constants.
 * @param processId The process Id for the process being checked
 * @returns {boolean}
 */
module.exports.hasSufficientDependencies = function(dependenciesStatus, importConfig, processId) {
  const {explicitDisable, requireAnyDependency, requiredDependencies} = importConfig;
  let enableImport = !requireAnyDependency;
  if (dependenciesStatus && dependenciesStatus.size > 0) {
    for (let dep of requiredDependencies) {
      let dependencyStatus = dependenciesStatus.get(dep);
      if (!dependencyStatus) {
        continue;
      }

      if (typeof dependencyStatus !== "boolean") {
        dependencyStatus = !processId || dependencyStatus[processId];
      }

      if (requireAnyDependency) {
        enableImport = enableImport || dependencyStatus;
      } else {
        enableImport = enableImport && dependencyStatus;
      }
    }
  }

  return !explicitDisable && enableImport;
};

/**
 * This checks if the currently logged in user has permissions to import through a specific import flow.
 * @param importConfig The import configuration as defined in the import constants.
 * @returns {*}
 */
module.exports.hasPermissions = function(importConfig) {
  let hasPermission = can(importConfig.securityAction, importConfig.modelName);
  if (importConfig.selectionTableModelTypes) {
    // if there's a model selection table (usually for process capability), what matters is
    // to check whether the user has access to ANY of the model types supported by the selection table
    for (let modelType of importConfig.selectionTableModelTypes) {
      hasPermission = hasPermission || can(importConfig.securityAction, UIUtils.convertCamelCaseToSpacedOutWords(modelType));
    }
  }
  return hasPermission;
};