var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { validatePackage } from '@progress/kendo-licensing';
import { packageMetadata } from '../package-metadata';
import { guid } from '@progress/kendo-react-common';
/**
 * Represents the [KendoReact GridLayoutItem component]({% slug overview_gridlayout %}).
 *
 * @example
 * ```jsx
 *
 * const App = () => {
 *   return (
 *     <GridLayout
 *       rows={[{height: 50}, {height: 50}, {height: 50}]}
 *       cols={[{width: 50}, {width: 50}, {width: 50}]}
 *       gap={{rows: 5, cols: 5}}
 *       align={{horizontal: 'stretch', vertical: 'stretch'}}
 *     >
 *       <GridLayoutItem row={1} col={1}>Box</GridLayoutItem>
 *       <GridLayoutItem row={1} col={2} colSpan={2}>Box</GridLayoutItem>
 *       <GridLayoutItem row={2} col={1} colSpan={2} rowSpan={2}>Box</GridLayoutItem>
 *       <GridLayoutItem row={2} col={3}>Box</GridLayoutItem>
 *       <GridLayoutItem row={3} col={3}>Box</GridLayoutItem>
 *     </GridLayout>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var GridLayoutItem = React.forwardRef(function (props, ref) {
    validatePackage(packageMetadata);
    var elementRef = React.useRef(null);
    var getImperativeHandle = React.useCallback(function () { return ({
        element: elementRef.current
    }); }, []);
    React.useImperativeHandle(ref, getImperativeHandle);
    var className = props.className, style = props.style, id = props.id, children = props.children, row = props.row, col = props.col, rowSpan = props.rowSpan, colSpan = props.colSpan;
    var itemId = React.useMemo(function () { return guid(); }, []);
    var gridLayoutItemStyles = __assign({ gridArea: "".concat(row || 'auto', " / ").concat(col || 'auto', " / ").concat(rowSpan ? 'span ' + rowSpan : 'auto', " / ").concat(colSpan ? 'span ' + colSpan : 'auto') }, style);
    return (React.createElement("div", { ref: elementRef, className: className, style: gridLayoutItemStyles, id: id || itemId }, children));
});
GridLayoutItem.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any,
    id: PropTypes.string
};
GridLayoutItem.displayName = 'KendoReactGridLayoutItem';
