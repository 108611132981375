"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import Heading from "../../widgets/headers/heading";
import DocLinksAttribute from "../attributes/doc_links_attribute";
import BaseAttribute from "../attributes/base_attribute";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { EDITOR_OPERATIONS, EDITOR_TYPES } from "../editor_constants";
import BaseReactComponent from "../../base_react_component";
import * as PropTypes from "prop-types";
import IntegrationsAttribute from "../attributes/integrations_attribute";
import TagsAttribute from "../attributes/tagsAttribute/tags_attributes";

const HIDDEN_STATUS_TOKEN = "HIDDEN_STATUS_TOKEN";

/**
 * This class is responsible for encapsulating a section of an editable and optionally providing a Doc Links attribute
 * at the bottom if the showDocLinks prop is set to true.
 */
export default class Section extends BaseReactComponent {
  constructor(props) {
    super(props);
    const wasHiddenBefore = localStorage.getItem(this.getHiddenLocalStorageToken());
    this.tagsAttributesRef = React.createRef();

    // We hide the section only if we remember the user hiding it before, or if this is in the quick edit / view panel (but not review or the full screen editor)
    if (this.props.collapsible) {
      this.setStateSafely({
        hideDetails: (wasHiddenBefore ? wasHiddenBefore === "true"
          : props.parent && this.props.parent.shouldCollapseAllSections()),
      });
    }
  }

  /**
   * @return {string} The string used to store the user's previous preference about whether this section should be open.
   */
  getHiddenLocalStorageToken() {
    let hiddenStatusToken = HIDDEN_STATUS_TOKEN;
    // Remember the quick panel status differently from the full view
    if (this.props.parent && this.props.parent.getEditorType() !== EDITOR_TYPES.FULL_SCREEN) {
      hiddenStatusToken += "-quick";
    }
    return hiddenStatusToken + "-" + UIUtils.convertToId(this.props.id || "");
  }

  getAttributeOptions() {
    let attributeOptions = [];
    let children = React.Children.toArray(this.props.children);

    for (let child of children) {
      attributeOptions = attributeOptions.concat(this.recursivelyFindAttributeOptions(child, attributeOptions));
    }

    // Add / replace / remove options as specified in the props
    if (this.props.addOptions) {
      attributeOptions = attributeOptions.concat(this.props.addOptions);
    }

    if (this.props.removeOptions) {
      attributeOptions = attributeOptions.filter(option => !this.props.removeOptions.includes(option.id));
    }

    // Shows the attributes in an order that makes sense
    UIUtils.sortBy(attributeOptions, "label");
    return attributeOptions;
  }

  recursivelyFindAttributeOptions(someObject) {
    let attributeOptions = [];
    if (someObject.type && someObject.type.prototype instanceof BaseAttribute) {
      let refForObject = this.props.parent.getAllNamesToRefs()[someObject.props.name];
      /* The refs may not exist yet when this object is created.  They'll be there though by the time the data comes
          back from the server or when we add a new row on add.
       */
      if (refForObject) {
        attributeOptions.push({
          id: someObject.props.name,
          label: refForObject.getDisplayName()
        });
      }
    } else if (someObject.props && someObject.props.children) {
      let children = React.Children.toArray(someObject.props.children);
      for (let child of children) {
        attributeOptions = attributeOptions.concat(this.recursivelyFindAttributeOptions(child, attributeOptions));
      }
    } else if (someObject.props && someObject.props.requirement && someObject.props.requirement.AcceptanceCriteriaRanges) {
      attributeOptions.push(
        {id: "lowerLimit", label: "LSL"},
        {id: "target", label: "Target"},
        {id: "upperLimit", label: "USL"},
        {id: "measurementUnits", label: "Measurement Units"},
        {id: "targetJustification", label: "Justification"},);
    }

    return attributeOptions;
  }

  /**
   * Expand or collapse this section.
   * @param shouldHide true to collapse, false to expand
   * @param callback
   */
  handleExpandOrCollapse(shouldHide, callback) {
    localStorage.setItem(this.getHiddenLocalStorageToken(), shouldHide);
    this.setStateSafely({
      hideDetails: shouldHide
    }, typeof callback === "function" ? callback : null);
  }

  updateTagsAttributesInputValue(value) {
    this.tagsAttributesRef?.current?.updateInputValue(value);
  }

  render() {
    let {
      visible,
      id,
      header,
      headingLevel,
      collapsible,
      children,
      showDocLinks,
      showDocLinksInViewMode,
      hideAppliesToColumn,
      parent,
      headerLink,
      showIntegrationsTable,
      showTagsTable,
      processId,
    } = this.props;

    visible = typeof visible === "boolean" ? visible : true;
    return visible ? (
      <div className="section">
        {header ? (
          <Heading level={headingLevel ? headingLevel : 2}
                   className={collapsible ? "" : "section-non-collapsible-header"}
          >
            {collapsible ? (
              this.state.hideDetails ? (
                <FontAwesomeIcon id={"Section_" + UIUtils.convertToCamelCaseId(id) + "_ExpandButton"}
                                 icon={faCaretRight}
                                 size="3x"
                                 className="section-details-control"
                                 aria-label="Show details"
                                 onClick={() => this.handleExpandOrCollapse(false)}
                />
              ) : (
                <FontAwesomeIcon id={"Section_" + UIUtils.convertToCamelCaseId(id) + "_CollapseButton"}
                                 icon={faCaretDown}
                                 size="3x"
                                 className="section-details-control"
                                 aria-label="Hide details"
                                 onClick={() => this.handleExpandOrCollapse(true)}
                />
              )
            ) : ""}
            {header}
            {headerLink ? (
              <div className="section-header-link">
                {headerLink}
              </div>
            ) : ""}
          </Heading>
        ) : ""}
        <div className={"section-content " + (this.state.hideDetails ? "section-hidden" : "")}>
          <div className="section-content-wrapper">
            {children}
            {showDocLinks ? (
              <div className="row">
                <DocLinksAttribute name={id ? id + "Links" : "links"}
                                   displayName="Source Documents"
                                   isLoading={this.props.isLoading}
                                   hideAppliesToColumn={hideAppliesToColumn}
                                   getAttributeOptions={this.getAttributeOptions}
                                   className="col-sm-12"
                                   parent={parent}
                                   editorOperation={showDocLinksInViewMode && EDITOR_OPERATIONS.VIEW}
                />
              </div>
            ) : ""}
          </div>
          <div className="section-content-wrapper">
            {showIntegrationsTable ? (
              <div className="row">
                <IntegrationsAttribute name={"integrations"}
                                       displayName="Integration systems"
                                       hideAppliesToColumn={hideAppliesToColumn}
                                       getAttributeOptions={this.getAttributeOptions}
                                       className="col-sm-12"
                                       parent={parent}
                                       editorOperation={showDocLinksInViewMode && EDITOR_OPERATIONS.VIEW}
                />
              </div>
            ) : ""}
          </div>
          <div className="section-content-wrapper">
            {showTagsTable ? (
              <div className="row">
                <TagsAttribute parent={parent}
                               ediotorOperation={EDITOR_OPERATIONS.VIEW}
                               processId={processId}
                               name={"tags"}
                               displayName="Tags"
                               className="col-sm-12"
                               header={header}
                               addButtonText={"Add tags"}
                               linkObject={["Tags"]}
                               onActionButtonClick={this.props.onActionButtonClick}
                               ref={this.tagsAttributesRef}
                >
                </TagsAttribute>
              </div>
            ) : ""}
          </div>
        </div>
      </div>
    ) : null;
  }
}

Section.propTypes = {
  id: PropTypes.string,
  parent: PropTypes.any,
  header: PropTypes.any,
  showDocLinks: PropTypes.bool,
  showDocLinksInViewMode: PropTypes.bool,
  collapsible: PropTypes.bool,
  addOptions: PropTypes.arrayOf(PropTypes.object),
  removeOptions: PropTypes.arrayOf(PropTypes.string),
  visible: PropTypes.bool,
  headerLink: PropTypes.element,
};

Section.defaultProps = {
  showDocLinks: false,
  showDocLinksInViewMode: false,
  collapsible: true,
};

