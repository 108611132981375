"use strict";

import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownAZ, faArrowUpAZ } from "@fortawesome/free-solid-svg-icons";
import BaseReactComponent from "../../../base_react_component";
import { Fragment } from "react";

export const sortedAsc = (sortedIndex, sort) => {
  return !!(sort && sortedIndex > -1 && sort[sortedIndex].dir === "asc");
};

export const sortedDesc = (sortedIndex, sort) => {
  return !!(sort && sortedIndex > -1 && sort[sortedIndex].dir === "desc");
};

export const sortIndex = (field, sort) => {
  if (!sort) {
    return -1;
  }

  return sort.findIndex((s) => s.field === field);
};

export default class ColumnOperationsSortAlphabetically extends BaseReactComponent {
  handleAscClick(e) {
    const {props} = this;
    props.onSortChange([{field: props.column.field, dir: "asc"}], e);
    props.onCloseMenu();
  }

  handleDescClick(e) {
    const {props} = this;
    props.onSortChange([{field: props.column.field, dir: "desc"}], e);
    props.onCloseMenu();
  }

  renderSortingOptions() {
    const {sort, column} = this.props;
    const currentSortIndex = sortIndex(column.field, sort);

    return (
      <Fragment>
        <div onClick={this.handleAscClick}
             className={`pb-1 k-columnmenu-item ${
               sortedAsc(currentSortIndex, sort) ? "k-state-selected" : ""
             }`}
        >
          <FontAwesomeIcon className="pr-1" icon={faArrowDownAZ} /> <span>Ascending (A-Z)</span>
        </div>
        <div onClick={this.handleDescClick} className={`k-columnmenu-item  ${
          sortedDesc(currentSortIndex, sort) ? "k-state-selected" : ""}`}
        >
          <FontAwesomeIcon className="pr-1" icon={faArrowUpAZ} /> <span>Descending (Z-A)</span>
        </div>
      </Fragment>
    );
  }

  render() {
    return (
      <div className="sort-table-view">
        <span>Sort</span>
        {this.renderSortingOptions()}
      </div>
    );
  }
}
