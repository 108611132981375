"use strict";

import * as React from "react";
import {
  GridColumnMenuFilter,
  GridColumnMenuCheckboxFilter,
} from "@progress/kendo-react-grid";
import ColumnOperationsSortAlphabetically from "./column_operations_sort_alphabetically";
import ColumnOperationsSortByOrder from "./column_operations_sort_by_order";

const filterOperators = {
  "text": [
    {text: "grid.filterContainsOperator", operator: "contains"},
    {text: "grid.filterNotContainsOperator", operator: "doesnotcontain"},
    {text: "grid.filterEqOperator", operator: "eq"},
    {text: "grid.filterNotEqOperator", operator: "neq"},
    {text: "grid.filterStartsWithOperator", operator: "startswith"},
    {text: "grid.filterEndsWithOperator", operator: "endswith"},
    {text: "grid.filterIsEmptyOperator", operator: "isnull"},
    {text: "grid.filterIsNotEmptyOperator", operator: "isnotnull"}
  ],
  "numeric": [
    {text: "grid.filterEqOperator", operator: "eq"},
    {text: "grid.filterNotEqOperator", operator: "neq"},
    {text: "grid.filterGteOperator", operator: "gte"},
    {text: "grid.filterGtOperator", operator: "gt"},
    {text: "grid.filterLteOperator", operator: "lte"},
    {text: "grid.filterLtOperator", operator: "lt"},
  ],
  "date": [
    {text: "grid.filterEqOperator", operator: "eq"},
    {text: "grid.filterNotEqOperator", operator: "neq"},
    {text: "grid.filterAfterOrEqualOperator", operator: "gte"},
    {text: "grid.filterAfterOperator", operator: "gt"},
    {text: "grid.filterBeforeOperator", operator: "lt"},
    {text: "grid.filterBeforeOrEqualOperator", operator: "lte"},
  ],
  "boolean": [
    {text: "grid.filterEqOperator", operator: "eq"}
  ]
};

export const ParentLookupColumnOperationsMenu = (props) => {

  return (
    <div>
      <ColumnOperationsSortByOrder {...props} />
      <GridColumnMenuFilter {...props}
                            hideSecondFilter={true}
                            filterOperators={filterOperators}
                            expanded={true}
      />
    </div>
  );
};

export const ColumnOperationsMenu = (props) => {
  return (
    <div>
      <ColumnOperationsSortAlphabetically {...props} />
      <GridColumnMenuFilter {...props}
                            hideSecondFilter={true}
                            filterOperators={filterOperators}
                            expanded={true}
      />
    </div>
  );
};

export const ComboBoxColumnOperationsMenu = (options, props) => {
  return (
    <div>
      <ColumnOperationsSortAlphabetically {...props} />
      <GridColumnMenuCheckboxFilter
        {...props}
        data={options}
        expanded={true}
      />
    </div>
  );
};

