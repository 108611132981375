var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Draggable } from '@progress/kendo-react-common';
/**
 * @hidden
 */
var ColumnResizer = /** @class */ (function (_super) {
    __extends(ColumnResizer, _super);
    function ColumnResizer() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.draggable = null;
        /**
         * @hidden
         */
        _this.onDrag = function (data) {
            var element = _this.draggable && _this.draggable.element;
            if (element) {
                _this.props.resize(data.event, element, false);
            }
        };
        /**
         * @hidden
         */
        _this.onRelease = function (data) {
            var element = _this.draggable && _this.draggable.element;
            if (element) {
                _this.props.resize(data.event, element, true);
            }
        };
        return _this;
    }
    ColumnResizer.prototype.render = function () {
        var _this = this;
        return (React.createElement(Draggable, { onDrag: this.onDrag, onRelease: this.onRelease, ref: function (component) { _this.draggable = component; } },
            React.createElement("span", { className: "k-column-resizer", draggable: false })));
    };
    return ColumnResizer;
}(React.PureComponent));
export { ColumnResizer };
