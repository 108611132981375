"use strict";

import React from "react";
import { WIDGET_FIELDS } from "../constants/rmp_risk_scale_attribute_constants";
import { RISK_ATTRIBUTES_TABS } from "../constants/rmp_constants";
import RMPRiskScaleAttribute, { RMP_RISK_ATTRIBUTE_TYPE } from "./rmp_risk_scale_attribute";

/**
 * This is class is responsible for handling Risk Management Plan RPN records.
 */
export default class RMPRPNAttribute extends RMPRiskScaleAttribute {
  constructor(props) {
    super(props, WIDGET_FIELDS);

    this.errorMessages.editedRowPending = "At least one RPN row is in edit state. Please save or cancel all RPN rows first.";
    this.type = RMP_RISK_ATTRIBUTE_TYPE.RPN;
    this.tabContainerType = RISK_ATTRIBUTES_TABS.RPN_SCALE;
    this.sourceParameter1 = "Detectability Risk";
    this.sourceParameter2 = "Process Risk";
    this.minSourceParameter1 = "minDetectabilityRisk";
    this.maxSourceParameter1 = "maxDetectabilityRisk";
    this.minSourceParameter2 = "minProcessRisk";
    this.maxSourceParameter2 = "maxProcessRisk";
  }
}
