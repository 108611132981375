"use strict";

/**
 * This class creates a middleman object to decouple a bit the editor attributes from the editor page.
 * Right now, we have several calls to the parent object, which makes it really hard to use editor attributes
 * in any slightly different way.
 */
export class ParentProxy {
  constructor(parent) {
    this.originalParent = parent;
  }

  createPatchedParent(patch) {
    let self = this;
    return {
      ...this.originalParent,
      addRef: self.originalParent.addRef,
      ...patch,
    };
  }
}
