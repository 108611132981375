import { BaseDataTransform } from "../base_data_transform";
import { convertLinksToJson, fillInRiskLinks, formatJustification } from "../../canned_reports/canned_report_helper";
import * as UIUtils from "../../../ui_utils";
import * as CommonEditablesFormatter from "../../../../server/common/editables/common_editables_formatter";
import { getHistoricalData, getHistoryPointForDate, } from "../../risk_map_reports/utilities/risk_map_report_helper";
import { EMPTY_STRING, RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import {
  CQAObjectVersionChangeTracker
} from "../../canned_reports/utils/version_history/cqa_object_version_change_tracker";
import CommonRiskUtils from "../../../../server/common/misc/common_risk_utils";
import {
  getRiskScale,
  getRiskScores
} from "../../../helpers/risk_helper";

const CRITICAL_ATTRIBUTE_EMPTY_LABEL = "No Critical CPPs or CMAs defined";
const TYPES_MAPPING = new Map([
  ["PP", "CPP"],
  ["MA", "CMA"],
  ["FQA", "CQA"],
  ["IQA", "CIQA"],
  ["IPA", "ICPA"],
  ["FPA", "CPA"],
  ["PRC", "PRC"],
  ["MT", "MT"],
]);

export class CQAControlStrategyTransform extends BaseDataTransform {

  get type() {
    return "cqaControlStrategy";
  }

  // eslint-disable-next-line no-unused-vars
  shouldRun(options) {
    return true;
  }

  transform(input, options) {
    const result = input;
    const {
      rmpVersions,
      projectWithAllVersions,
      typeaheadOptions,
      selectedTypeaheadLabel,
    } = options;


    this.formatGeneralInformation(result);
    this.formatAcceptanceCriteria(result);
    this.fillInCriticality(result, typeaheadOptions);
    this.fillInProcessControl(projectWithAllVersions, rmpVersions, result, selectedTypeaheadLabel);

    return result;
  }

  formatGeneralInformation(result) {
    let record = result.instances.fqa;
    result.instances.id = record.id;
    record.category = UIUtils.secureString(CommonEditablesFormatter.formatMultiSelectValueForDisplay(record.category));
    record.scope = UIUtils.secureString(CommonEditablesFormatter.formatMultiSelectValueForDisplay(record.scope));
    record.controlMethods = CommonEditablesFormatter.formatMultiSelectValueForDisplay(JSON.stringify(record?.ControlMethods?.map(cm => cm.name)));
    record.controlStrategy = CommonEditablesFormatter.formatMultiSelectValueForDisplay(record.controlStrategy);
    record.riskControlLinks = convertLinksToJson(record.riskControlLinks);
    record.stabilityIndicating = record.stabilityIndicating ? "Yes" : "No";
  }

  formatAcceptanceCriteria(record) {
    const {instances} = record;
    const {fqa} = instances;
    const setIsDefault = (ac) => {
      ac.isDefault = ac.isDefault ? "Yes" : "No";
      return ac;
    };
    if (fqa.Requirement) {
      fqa.Requirement.AcceptanceCriteriaRanges = fqa?.Requirement?.AcceptanceCriteriaRanges.map(setIsDefault);
    }
  }

  fillInCriticality(record, typeaheadOptions) {
    const {instances} = record;
    const {fqa, project} = instances;

    if (!fqa.riskInfo) {
      throw new Error("Missing RiskInfo");
    }

    const getRiskLabel = (normalizedRiskScore, riskScale) => {
      const riskScore = normalizedRiskScore + "%";
      return `${riskScale.scoreLabel} (${riskScore})`;
    };

    let riskLinks = fillInRiskLinks(fqa, typeaheadOptions);
    const criticalityInfo = fqa.riskInfo[RISK_TYPE_ENUM.CRITICALITY];

    if (riskLinks && riskLinks.length > 0) {
      riskLinks = riskLinks.map(link => {
        return {
          ...link,
          justification: link.justification || "None",
        };
      });
      if (riskLinks.length > 0) {
        riskLinks.sort((a, b) => b.criticality - a.criticality);
      }

      const maxCriticality = fqa.riskInfo[RISK_TYPE_ENUM.CRITICALITY].maxValue;
      const maxCriticalityNormalized = criticalityInfo.maxNormalizedValue;
      const maxCriticalityScale = criticalityInfo.maxScale;
      let risksWithHighestCriticality = riskLinks.filter(link => link.criticality === maxCriticality);
      fqa.justification = formatJustification(risksWithHighestCriticality);
      fqa.criticality = getRiskLabel(maxCriticalityNormalized, maxCriticalityScale);
      fqa.links = riskLinks.flatMap(riskLink => riskLink.links);
    } else {
      const normalizedRiskScore = criticalityInfo.normalizedValue;
      const scale = criticalityInfo.scale;
      fqa.criticality = getRiskLabel(normalizedRiskScore, scale);
    }

    const isDetailedRiskLinks = project.productRiskAssessmentType === "Risk Ranking";
    if (!isDetailedRiskLinks) {
      fqa.links = convertLinksToJson(fqa.riskLinks);
      fqa.justification = fqa.criticalityJustification || "None";
      delete fqa.riskLinks;
      delete fqa.criticalityJustification;
    }

  }

  fillInProcessControl(projectWithAllVersions, rmpVersions, results, selectedTypeaheadLabel) {
    const historicalResult = getHistoricalData(projectWithAllVersions, rmpVersions, results.instances, new CQAObjectVersionChangeTracker());
    const historyPointForSelectedDate = getHistoryPointForDate(new Date(), historicalResult);
    const {historySnapshot} = historyPointForSelectedDate;
    const criticalAttributes = new Map();

    const filterCriticalOnly = (historyItem) => {
      const rmp = CommonRiskUtils.filterRMPByType(historyItem.rmpForModelType, historyItem.type);
      const riskType = RISK_TYPE_ENUM.CRITICALITY;
      const rowRiskScore = getRiskScores(historyItem, riskType).rawRiskScore;
      const riskScale = getRiskScale(riskType, rmp, rowRiskScore, historyItem, true);
      return riskScale?.critical === true;
    };

    const fillCriticalAttributes = (criticalAttributes, key, record) => {
      if (key && !criticalAttributes.has(key)) {

        const directLinks = record?.incomingLinks
          .filter(link => ["PP", "MA"].includes(link.type))
          .filter(link => filterCriticalOnly(historySnapshot.get(`${link.type}-${link.id}`)));

        criticalAttributes.set(key, directLinks);

        // add other critical attributes
        const indirectLinks = record?.incomingLinks
          .filter(link => ["IQA", "IPA"].includes(link.type))
          .filter(link => filterCriticalOnly(historySnapshot.get(`${link.type}-${link.id}`)));
        for (let criticalAttribute of indirectLinks) {
          let attributeKey = `${criticalAttribute.type}-${criticalAttribute.id}`;
          fillCriticalAttributes(criticalAttributes, attributeKey, historySnapshot.get(attributeKey));
        }
      }
    };

    const getStep = (attribute) => {
      let steps = historyPointForSelectedDate.stepVersionsMap;
      return steps[attribute.stepId];
    };

    const getUnitOperation = (unitOperationId) => {
      let unitOperations = historyPointForSelectedDate.unitOperationVersionsMap;
      return unitOperations[unitOperationId];
    };

    const formatRecordForDisplay = (attribute) => {
      return `${TYPES_MAPPING.get(attribute.type)}: ${attribute.name}`;
    };

    const fillRecordDetails = (attribute) => {
      const key = `${attribute.type}-${attribute.id}`;
      const originalAttribute = historySnapshot.get(key);

      const appendHTTPToURLIfRequired = (link) => {
        const startsWithHTTP = !!(link.startsWith("https://") || link.startsWith("http://"));
        return !startsWithHTTP ? "https://" + link : link;
      };
      const mapLinkToDocument = (link) => {
        if (link.linkType !== "Link") {
          return link.fileName;
        }

        link.link = appendHTTPToURLIfRequired(link.link);
        return `<a href="${link.link}">${link.link}</a>`;
      };

      if (originalAttribute) {
        attribute.stepId = originalAttribute.stepId;
        attribute.scope = originalAttribute.stepId
          ? getStep(originalAttribute).name
          : (
            originalAttribute.unitOperationId ? "Global" : ""
          );
        attribute.processParameterType = originalAttribute.processParameterType ? originalAttribute.processParameterType : "NA";
        attribute.criticalAttributeLabel = `${formatRecordForDisplay(originalAttribute)}${originalAttribute.parent ? " > " + formatRecordForDisplay(originalAttribute.parent) : ""}`;
        attribute.criticalAttributeControlMethod = originalAttribute.controlMethods.map(cm => `CM: ${cm}`).join("<br>");

        let {controlStrategy} = originalAttribute;
        if (controlStrategy && controlStrategy !== EMPTY_STRING) {
          controlStrategy = JSON.parse(controlStrategy);
          attribute.criticalAttributeControlStrategy = controlStrategy.map(cs => `CS: ${cs}`).join("<br>");
        }

        attribute.criticalAttributeSupportDocuments = "None";
        if (originalAttribute.supportDocuments && originalAttribute.supportDocuments !== "[]" && originalAttribute.supportDocuments.length > 0) {
          attribute.criticalAttributeSupportDocuments = originalAttribute.supportDocuments
            .map(doc => {
              let htmlText = $(doc);

              // If length is zero then this is an attachment
              if (htmlText.length === 0) {
                return doc;
              }

              htmlText = appendHTTPToURLIfRequired(htmlText.text());
              return `<a href="${htmlText}">${htmlText}</a>`;
            })
            .filter(doc => doc !== EMPTY_STRING)
            .map(doc => `• ${doc}`).join("<br>");
        }

        attribute.criticalAttributeRiskControlDocuments = "None";
        if (originalAttribute?.riskControlLinks !== "[]" && originalAttribute?.riskControlLinks.length > 0) {
          attribute.criticalAttributeRiskControlDocuments =
            convertLinksToJson(originalAttribute.riskControlLinks)
              .map(mapLinkToDocument)
              .filter(doc => doc !== EMPTY_STRING)
              .map(doc => `• ${doc}`).join("<br>");
        }

        attribute.criticalAttributeAcceptanceCriteriaDocuments = "None";
        if (originalAttribute?.acceptanceCriteriaLinks !== "[]" && originalAttribute?.acceptanceCriteriaLinks.length > 0) {
          attribute.criticalAttributeAcceptanceCriteriaDocuments =
            convertLinksToJson(originalAttribute.acceptanceCriteriaLinks)
              .map(mapLinkToDocument)
              .filter(doc => doc !== EMPTY_STRING)
              .map(doc => `• ${doc}`).join("<br>");
        }

      } else {
        attribute.scope = attribute.stepId
          ? getStep(attribute).name
          : (
            attribute.unitOperationId ? "Global" : ""
          );
        attribute.processParameterType = "NA";
        attribute.criticalAttributeLabel = CRITICAL_ATTRIBUTE_EMPTY_LABEL;
        attribute.criticalAttributeControlMethod = "";
        attribute.criticalAttributeControlStrategy = "";
        attribute.criticalAttributeSupportDocuments = "None";
        attribute.criticalAttributeRiskControlDocuments = "None";
        attribute.criticalAttributeAcceptanceCriteriaDocuments = "None";
      }

      const originalCriticalAttribute = historySnapshot.get(attribute.criticalKey);
      attribute.qualityAttributeLabel = `${formatRecordForDisplay(originalCriticalAttribute)}`;
      attribute.qualityAttributeType = originalCriticalAttribute.type;
      attribute.qualityAttributeControlMethod = originalCriticalAttribute.controlMethods.map(cm => `CM: ${cm}`).join("<br>");

      let controlStrategy = originalCriticalAttribute.controlStrategy;
      if (controlStrategy && controlStrategy !== EMPTY_STRING) {
        controlStrategy = JSON.parse(controlStrategy);
        attribute.qualityAttributeControlStrategy = controlStrategy.map(cs => `CS: ${cs}`).join("<br>");
      }

      return attribute;
    };

    const getUnitOperationOrder = (attribute) => {
      let unitOperations = historyPointForSelectedDate.unitOperationVersionsMap;
      return unitOperations[attribute.unitOperationId]?.order || -1;
    };

    const getStepOrder = (attribute) => {
      let steps = historyPointForSelectedDate.stepVersionsMap;
      return steps[attribute.stepId]?.order || -1;
    };

    const groupRecordsByUnitOperation = () => {
      // group records by unit operation
      let groupedByUORecords = new Map();
      for (let record of pCriticalAttributes) {
        const unitOperationId = record.unitOperationId || -1;
        if (!groupedByUORecords.has(unitOperationId)) {
          groupedByUORecords.set(unitOperationId, [record]);
        } else {
          groupedByUORecords.get(unitOperationId).push(record);
        }
      }

      const instances = [];
      for (let [unitOperationId, records] of groupedByUORecords) {
        const {
          name = EMPTY_STRING,
          description = EMPTY_STRING,
          risk = "Unknown"
        } = getUnitOperation(unitOperationId) ?? {};
        let instance = {
          unitOperationLabel: unitOperationId > 0 ? name : "Unassigned Unit Operation",
          unitOperationDescription: description,
          unitOperationRisk: risk,
          records
        };
        instances.push(instance);
      }
      return instances;
    };

    const preserveTheQualityAttributeRow = (key, records) => {
      // Make sure we always have a row for a critical attribute (CQA/ CIQA) even though there is no affected records,
      // If there is no impacted critical CPPs or CMAs, a string will be displayed instead as "No Critical CPPs or CMAs defined"
      const [typeCode] = key.split("-");
      const {stepId, unitOperationId} = historySnapshot.get(key);
      if (records.length === 0 && typeCode !== "FQA") {
        records.push({
          id: null,
          type: null,
          name: null,
          stepId,
          unitOperationId
        });
      }
    };

    const [fqaKey] = selectedTypeaheadLabel ? selectedTypeaheadLabel.split(" - ") : "";
    fillCriticalAttributes(criticalAttributes, fqaKey, historySnapshot.get(fqaKey));

    let pCriticalAttributes = [];
    for (let [key, values] of criticalAttributes.entries()) {
      preserveTheQualityAttributeRow(key, values);
      pCriticalAttributes.push(
        ...values.map(value => {
          return {
            criticalKey: key, ...value
          };
        }));
    }

    // fill in the record details
    pCriticalAttributes = pCriticalAttributes.map(fillRecordDetails);
    pCriticalAttributes.sort((rec1, rec2) => {
      // sort by unit operations first
      let sortResult = getUnitOperationOrder(rec1) - getUnitOperationOrder(rec2);

      // then sort by steps, note that UO records should show first then Step records.
      if (sortResult === 0) {
        sortResult = !rec1.stepId
          ? -1 : (
            !rec2.stepId
              ? 1
              : getStepOrder(rec1) - getStepOrder(rec2)
          );
      }
      return sortResult;
    });

    const instances = groupRecordsByUnitOperation();

    // UO based records comes as last in the table
    instances.sort((rec1, rec2) => {
      const UNASSIGNED_RECORDS = "Unassigned Unit Operation";
      return Number(rec2.unitOperationLabel.includes(UNASSIGNED_RECORDS)) - Number(rec1.unitOperationLabel.includes(UNASSIGNED_RECORDS));
    });

    // Order the rows so the CQAs comes before the ICQAs/ICPAs
    instances.forEach(instance => {
      const TYPES = ["IQA", "IPA"];
      const SortValue = (record, attributeName) => Number(TYPES.includes(record[attributeName]));
      const SortByScope = (rec1, rec2) => {
        return rec1.scope === "Global" && rec2.scope !== "Global"
          ? -1
          : rec1.scope === "Global" && rec2.scope === "Global"
            ? 0
            : rec1.scope !== "Global" && rec2.scope === "Global"
              ? 1
              : getStepOrder(rec1) - getStepOrder(rec2);
      };
      /**
       * The below sorting works as the follows,
       * First, sort by scope, Global comes first then step records, the order of steps match the same process explorer order
       *  if they match then sort by the qualityAttributeType,
       *  if they match then rows with No Critical CPPs or CMAs should be displayed first,
       *  if they match then show up CPPs first then CMAs.
       */
      return instance.records
        .sort((rec1, rec2) =>
          SortByScope(rec1, rec2)
          ||
          (
            SortValue(rec1, "qualityAttributeType") - SortValue(rec2, "qualityAttributeType")
          )
          ||
          (
            Number(rec2.criticalAttributeLabel.includes(CRITICAL_ATTRIBUTE_EMPTY_LABEL)) - Number(rec1.criticalAttributeLabel.includes(CRITICAL_ATTRIBUTE_EMPTY_LABEL))
          )
          ||
          (
            Number(rec2.type === "PP") - Number(rec1.type === "PP")
          )
        );
    });

    results.instances.instances = instances;
  }
}
