import { BaseParser } from "./base_parser";
import { WidgetParserFactory } from "./widget_parser";
import { FileType } from "../../common/constants";
import { WIDGET_KIND } from "../../components/sideMenu/widget/widget_constants";

/**
 * Parser will be used to parse table widget
 */
export class HeaderFooterParser extends BaseParser {
  get selector() {
    return ".widget-content";
  }

  getElements() {
    const contentElement = this.node.querySelector(this.selector);
    if (!contentElement) {
      return [];
    }
    return Array.from(contentElement.childNodes);
  }

  parse() {
    const elements = this.getElements();
    const kind = this.node.getAttribute("kind");
    const container = document.createElement("div");
    container.className = `${kind.toLowerCase()}-container`;

    for (const element of elements) {
      const parser = WidgetParserFactory.getParser(
        this.globalNode,
        this.node,
        element,
        this.data,
        this.fileType,
        this.rmp,
        this.project,
        this.process,
        this.parseOnly
      );
      container.appendChild(...parser.parse());
    }

    if (kind === WIDGET_KIND.Header) {
      let line = this.createElementFromText(`
        <div style="border-bottom: 5px solid #1fbcff; height: 2pt;">
          &nbsp;
        </div>
      `);

      if (this.fileType === FileType.DOC) {
        line = this.createElementFromText(`
          <p style="border-top: 3px solid #1fbcff; height: 2pt;">
            &nbsp;
          </p>
        `);
      }

      container.appendChild(line);
    }

    return [container];
  }
}
