"use strict";
import { TEXT_DIFF_METHOD_ENUM } from "../../helpers/constants/constants";
import { FIELD_INPUT_TYPE, FIELD_REQUIRED_FOR } from "../../editor/widgets/widget_field";

/**
 * @type {IWidgetField[]}
 */
export const WIDGET_FIELDS = [
  {
    fieldName: "riskScore",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    displayName: "Risk Score",
    requiredFor: FIELD_REQUIRED_FOR.save,
    forceUpdate: true,
    defaultValue: "getRiskScoreDefaultOption",
    placeholder: "",
    belongsToMasterRow: true,
    order: 1,
    inputType: FIELD_INPUT_TYPE.select,
    getOptions: "getRiskScoreOptions",
    disabled: "isRiskScoreDisabled",
    width: 100
  },
  {
    fieldName: "scoreLabel",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    displayName: "Score Label",
    requiredFor: FIELD_REQUIRED_FOR.save,
    forceUpdate: false,
    defaultValue: "",
    placeholder: "",
    belongsToMasterRow: true,
    order: 2,
    inputType: FIELD_INPUT_TYPE.text,
    width: 150
  },
  {
    fieldName: "color",
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    displayName: "Color",
    requiredFor: FIELD_REQUIRED_FOR.save,
    forceUpdate: false,
    defaultValue: "Blue",
    placeholder: "",
    belongsToMasterRow: true,
    order: 3,
    inputType: FIELD_INPUT_TYPE.select,
    getOptions: "getRiskColors",
    disabled: "isRiskColorDisabled",
    width: 100
  },
  {
    fieldName: "description",
    diffMethod: TEXT_DIFF_METHOD_ENUM.differential,
    displayName: "Description",
    requiredFor: FIELD_REQUIRED_FOR.proposal,
    forceUpdate: false,
    defaultValue: "",
    placeholder: "",
    belongsToMasterRow: true,
    order: 5,
    rows: 4,
    inputType: FIELD_INPUT_TYPE.textarea,
    width: 500
  }
];
