"use strict";

import React, { memo } from "react";
import * as styles from "../view/columnSelection/column_selection.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useSidePanel } from "../widgets/side_panel_provider";
import * as headerStyles from "./styles/bulk_edit_header.module.scss";
import { useTableState } from "../../../../table_state_provider";

const BulkEditHeader = () => {

  const {toggle} = useSidePanel();
  const {selectedCount} = useTableState();

  return (
    <div className={styles["column-selection-header"]}>
      <span className={headerStyles["header"]}>
        Bulk edit {selectedCount} records
      </span>
      <div id="bulkEditClose" onClick={toggle}>
        <FontAwesomeIcon className={headerStyles["close-icon"]}
                         size="sm"
                         icon={faTimes}
        />
      </div>
    </div>
  );
};

export default memo(BulkEditHeader);