import React from "react";
import NumberAttribute from "../attributes/number_attribute";
import * as UIUtils from "../../ui_utils";
import TextAttribute from "../attributes/debounced_text_attribute";
import { isUnitsFieldDisabled } from "../../helpers/attribute_helper";
import TextAreaAttribute from "../attributes/text_area_attribute";
import { ActionButton } from "../../widgets/generic/action_button";
import * as PropTypes from "prop-types";
import {
  isLowerLimitDisabled,
  isUpperLimitDisabled,
  getAcceptanceCriteriaValidationProps,
} from "../../../server/common/editables/common_editables";
import BaseReactComponent from "../../base_react_component";

export class SingleAcceptanceCriterion extends BaseReactComponent {
  render() {
    let {canAdd, parent, instance, measure} = this.props;
    const addDisabledReasons = this.getAddDisabledReasons(parent);

    return <div className="acceptance-criteria-field-group">
      <div className="row">
        <NumberAttribute name="lowerLimit"
                         className="col-sm-4"
                         displayName="LSL"
                         tooltipText="LSL - Lower specification limit"
                         parent={parent}
                         disabled={isLowerLimitDisabled(measure)}
                         instructions={"Lower Specification Limit is not required when using Defects (Pass/Fail), Conforms(Pass/Fail), or Upper Limit (NMT) for a Measure."}
                         {...getAcceptanceCriteriaValidationProps(measure, "lowerLimit", instance)}
        />
        {measure === UIUtils.MEASUREMENT_TYPES.CONFORMS ? (
          <TextAttribute name="target"
                         className="col-sm-4"
                         parent={parent}
          />
        ) : (
          <NumberAttribute name="target"
                           className="col-sm-4"
                           parent={parent}
                           {...getAcceptanceCriteriaValidationProps(measure, "target", instance)}
          />)}

        <NumberAttribute name="upperLimit"
                         className="col-sm-4"
                         displayName="USL"
                         tooltipText="USL - Upper specification limit"
                         parent={parent}
                         disabled={isUpperLimitDisabled(measure)}
                         instructions={"Upper Specification Limit is not required when using Conforms (Pass/Fail), or Lower Limit (NLT) for a Measure."}
                         {...getAcceptanceCriteriaValidationProps(measure, "upperLimit", instance)}
        />
        <TextAttribute name="measurementUnits"
                       className="col-sm-4"
                       disabled={isUnitsFieldDisabled(measure)}
                       parent={parent}
        />

      </div>
      <div className="row">
        <TextAreaAttribute name="targetJustification"
                           displayName="Justification"
                           className="col-sm-12"
                           parent={parent}
        />
      </div>
      {
        canAdd
          ? (
            <ActionButton
              label="Add criteria range"
              id="addAcceptanceCriteriaRange"
              attributeName="criterionRange"
              onClick={() => this.handleAddNewRange()}
              disabled={addDisabledReasons.length > 0}
              disabledReason={addDisabledReasons.join("\n")}
            />
          )
          : ""
      }
    </div>;
  }

  getAddDisabledReasons(parent) {
    const {measure} = this.props;
    const addedAcceptanceCriteriaRanges = parent?.state?.Requirement?.AcceptanceCriteriaRanges?.length || 0;
    const hasSingleAcceptanceCriteriaRange = addedAcceptanceCriteriaRanges > 1;
    const addDisabledReasons = [];

    if (hasSingleAcceptanceCriteriaRange) {
      // just to ensure people won't click twice really fast and break the logic
      addDisabledReasons.push("Please wait...");
    }
    if (!measure) {
      addDisabledReasons.push("You must select a Measure before adding a new Acceptance Criteria Range.");
    }
    return addDisabledReasons;
  }

  isCriteriaRangeValid() {
    return $(".acceptance-criteria-field-group").find(".has-error").length === 0;
  }

  handleAddNewRange() {
    const {onAdd} = this.props;

    if (this.isCriteriaRangeValid()) {
      onAdd();
    } else {
      // Scrolls to the field that has data verification errors.
      UIUtils.scrollToField(".acceptance-criteria-field-group .has-error", 130);
    }
  }
}

SingleAcceptanceCriterion.propTypes = {
  parent: PropTypes.any,
  measure: PropTypes.any,
  lowerLimit: PropTypes.any,
  upperLimit: PropTypes.any,
  canAdd: PropTypes.bool,
  onAdd: PropTypes.func,
};
