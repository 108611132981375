"use strict";

import React from "react";
import ValidationIcon from "../../widgets/generic/validation_icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import BaseReactComponent from "../../base_react_component";

/**
 *  This implements the status icon used in the bulk propose review table.
 */
export default class BulkProposeStatusIcon extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return this.props.showWarning ? (
      <ValidationIcon id={this.props.id}
                      trigger="hover click"
                      isInformative={this.props.showAsInformative}
                      tooltip={this.props.warningTooltip}
                      visible={true}
                      hideBullets={true}
      />
    ) : (
      <FontAwesomeIcon icon={faCheck} color="#2dc9a4" />
    );
  }
}
