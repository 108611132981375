"use strict";

import * as styles from "./table_tool_bar.module.scss";
import ColumnFilters from "./columnFilters/column_filters";
import RowsCount from "./rows_count";
import React, { memo } from "react";
import { Configure } from "./configure";

export const ViewTableToolBar = memo(({records, quickFiltersZone, ...props}) => {
  return (
    <div id="toolBarDiv" className={styles["container"]}>

      <div className={styles["configure-zone"]}>
        <Configure />
      </div>
      <div className={styles["filters-zone"]}>
        {quickFiltersZone && (
          <>
            <span className={styles["vertical-separator"]} />
            {quickFiltersZone}
          </>
        )}
        <ColumnFilters records={records} {...props} />
      </div>
      <div className={styles["rows-count-zone"]}>
        <RowsCount records={records} {...props} />
      </div>

    </div>
  );
});