"use strict";

import * as UIUtils from "../ui_utils";

/**
 * Given a collection of version transitions, this function finds the transtition where an approval request was made.
 * @param versionTransitions The collection of version transitions
 * @returns {*}
 */
export function getLatestApprovalRequestVersionTransition(versionTransitions) {
  let approvalRequestTransition = null;

  if (versionTransitions) {
    let latestVersionTransition = versionTransitions[0];
    let previousVersionTransition = versionTransitions[1];

    approvalRequestTransition = latestVersionTransition;

    if ((latestVersionTransition.transition === UIUtils.VERSION_STATES.REJECTED
      || latestVersionTransition.transition === UIUtils.VERSION_STATES.ROUTING)
      && previousVersionTransition) {
      approvalRequestTransition = previousVersionTransition;
    }
  }

  return approvalRequestTransition;
}

export function createApprovalInfo({email = "", password = "", comment = ""} = {}) {
  return {
    email,
    password,
    comment,
  };
}
