"use strict";

import * as UIUtils from "../../../../ui_utils";
import { BaseTrainingDataTransform } from "./base_training_data_transform";

/**
 * This class is responsible for converting the data returned from the server into JSON format Stimulsoft needs
 * to create the Document Training Report.
 */
export class DocumentTrainingReportTransform extends BaseTrainingDataTransform {

  get type() {
    return "individualTrainingReport";
  }

  shouldRun(ignoredOptions) {
    return true;
  }

  transform(input, ignoredOptions) {
    let data = input.instances.reportData;
    let {instances, users} = data;
    let results = [];

    let uniqueDocumentIds = new Set(instances.map(instance => instance.documentId));
    for (let id of uniqueDocumentIds) {
      let uniqueVersions = new Set(instances
        .filter(instance => instance.documentId === id)
        .map(instance => instance.documentVersion));

      for (let version of uniqueVersions) {
        let document = instances.find(instance =>
          UIUtils.parseInt(instance.documentVersion) === UIUtils.parseInt(version) &&
          instance.documentId === id);

        if (document) {
          document.id = document.documentId;
          document.modelName = "Document";

          let usersForDocument = instances.filter(instance =>
            UIUtils.parseInt(instance.documentVersion) === UIUtils.parseInt(version) &&
            instance.documentId === id).map(instance => {

            let user = users.find(user => user.id === instance.userId);
            let assignmentDate = UIUtils.getDateForDisplayToUser(instance.assignmentDate, UIUtils.DATE_FORMAT_FOR_DISPLAY);

            let trainingStatus = this.getTrainingStatus(instance, instances);
            return {
              userName: `${user.firstName} ${user.lastName}`,
              assignmentDate,
              completionDate: trainingStatus,
              specifier: this.getRetrainingSpecifier(instance),
              incomplete: this.getIncompleteStatus(instance, instances),
            };
          });

          results.push({
            id: document.documentId,
            customId: UIUtils.getRecordCustomIdForDisplay(document),
            name: UIUtils.getRecordCustomLabelForDisplay(document),
            sortingId: UIUtils.getRecordCustomIdForSorting(document),
            version: document.documentVersion,
            users: usersForDocument.sort(
              UIUtils.sortBy(
                {
                  name: "userName",
                }))
          });
        }
      }
    }

    results = results.sort(
      UIUtils.sortBy(
        {
          name: "sortingId",
        },
        {
          name: "version",
          primer: UIUtils.parseInt,
          reverse: true
        },
        {
          name: "name",
        }));

    return {
      instances: results,
      isEmptySummary: instances.length === 0,
    };
  }
}
