"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseReactComponent from "../../base_react_component";
import CompanyLoginHeader from "../../widgets/headers/company_login_header";
import PasswordRequirementsPanel from "../../widgets/user/password_requirements_panel";
import Cookies from "js-cookie";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { RetryPleaseError, RetryWrapper } from "../../helpers/retry_wrapper";
import * as CognitoHelper from "../../helpers/cognito_helper";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import { Button } from "@qbdvision-inc/component-library";
import { Auth } from "aws-amplify";

const Logger = Log.group(LOG_GROUP.Users, "UserSignup");

// i18next-extract-mark-ns-start users
class UserAccount extends BaseReactComponent {
  constructor(props) {
    super(props);

    const {t} = props;
    document.title = t("Join QbDVision");

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phone: "",
      company: "",
      title: ""
    };
  }

  handleCreateCompany(event) {
    const {t} = this.props;
    event.preventDefault();
    event.stopPropagation();

    //data-match does not work with bootstrap validator
    //https://github.com/1000hz/bootstrap-validator/issues/449
    //https://github.com/1000hz/bootstrap-validator/issues/450
    $("[data-toggle='validator']").validator("update");
    let inputs = $(".form-control");
    let formIsValid = true;
    $.each(inputs, (key, val) => {
      formIsValid = formIsValid && !($(val).closest(".form-group").is(".has-error"));
    });

    if (event.target.checkValidity() && formIsValid) {
      UIUtils.showLoadingImage();
      console.log("Checking if the company exists...");
      $.ajax({
        url: UIUtils.getURL("users/userAPI?activity=checkCompany&companyName=" + encodeURIComponent(this.state.company)),
        type: "GET",
        global: false,
        idempotent: true
      }).done((result) => {
        if (result === "Available") {
          this.submitToCognito();
        } else {
          UIUtils.hideLoadingImage();
          UIUtils.showError(t(`The company "{{ company }}" is already setup. Please contact your system administrator to have them add you.`, {company: UIUtils.secureString(this.state.company)}));
        }
      }).fail(UIUtils.defaultFailFunction);
    }
  }

  submitToCognito() {
    console.log("Submitting user to Cognito...");
    const {t} = this.props;

    let phoneNumber = UIUtils.convertPhoneNumberToCognitoFormat(this.state.phone);

    let attributeList = {
      given_name: this.state.firstName,
      family_name: this.state.lastName,
      name: `${this.state.firstName} ${this.state.lastName}`,
      email: this.state.email,
      "custom:title": this.state.title,
      "custom:company": this.state.company,
    };

    if (phoneNumber) {
      attributeList = {phone_number: phoneNumber, ...attributeList};
    }

    // Code Purpose: Test Validation Strategy
    //
    // Breakage Scenario 1.1: This test will be broken by providing an invalid email to Cognito where no verification code will be received
    //
    // DANGEROUS: Uncomment this to break the signup feature intentionally.
    // attributeList = {email: "invalidemail@qbdvision.com", ...attributeList};

    // So Google Tag Manager can pick it up on the next page.
    Cookies.set("EMAIL", this.state.email);

    // Send to Cognito
    let username = UIUtils.computeUsername(this.state.username ? this.state.username : this.state.email);

    CognitoHelper.configureUserPool();

    new RetryWrapper(
      async() => {
        try {
          await Auth.signUp({
            username,
            password: this.state.password,
            attributes: attributeList,
          });
          window.location.href = UIUtils.getSecuredURL(`./signupVerification.html?username=${encodeURIComponent(username)}&email=${encodeURIComponent(this.state.email)}`);
        } catch (err) {
          // Uncomment for verbose logging.
          // console.log("Received response err: " + JSON.stringify(err) + " | result: " + JSON.stringify(result));
          if (CognitoHelper.isCognitoErrorRetryable(err)) {
            Logger.info(() => "Retrying because of " + UIUtils.stringify(err));
            throw new RetryPleaseError();
          } else {
            UIUtils.hideLoadingImage();
            console.log("Error from Cognito: " + UIUtils.stringify(err));
            let errorMessage = err.message;
            switch (err.code) {
              case "InvalidPasswordException":
                UIUtils.showError(errorMessage);
                break;
              case "InvalidParameterException":
                if (errorMessage === "Invalid phone number format.") {
                  errorMessage = t("Invalid phone number or format.");
                }
                UIUtils.showError(errorMessage);
                break;
              case "UsernameExistsException":
                if (this.state.username) {
                  UIUtils.showError(t("This username is already registered. Please use a different one for signing up."));
                } else {
                  UIUtils.showInfo(t("This email address is already registered. Please specify a username to continue creating a new account."));
                }
                this.setStateSafely({
                  usernameRequired: true,
                });
                break;
              default:
                errorMessage ? UIUtils.showError(errorMessage) : UIUtils.showError("", errorMessage);
                break;
            }
          }
        }
      },
      (ignored, waitInMS) => UIUtils.showError(t("Cannot complete signing up. Retrying in {{ retryWait }} seconds...", {retryWait: waitInMS / 1000}))
    ).retryFunction();
  }

  handleChange(event) {
    this.setStateSafely({[event.target.name]: event.target.value});
  }

  render() {
    const {t} = this.props;

    let header = (<span>{t("Let's get started.")}</span>);
    return (
      <div>
        <div className="container-fluid">
          <CompanyLoginHeader firstHeader={header} />
          <PasswordRequirementsPanel />
          <br />
          <div className="center-single-column-grid">
            <div className="alert alert-danger d-none" role="alert" id="alertDiv" />
            <div className="row">
              <div className="col-sm-12">
                <form data-toggle="validator" role="form" id="createAccountForm" onSubmit={this.handleCreateCompany}>
                  <div className="form-group">
                    <input type="text" className="form-control" name="firstName" id="firstNameInput"
                           data-error={t("First name is required.")}
                           required placeholder={t("First Name")} onChange={this.handleChange}
                    />
                    <div id="firstNameInputErrorDiv" className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" name="lastName" id="lastNameInput"
                           data-error={t("Last name is required.")}
                           required placeholder={t("Last Name")} onChange={this.handleChange}
                    />
                    <div id="lastNameInputErrorDiv" className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input type="email" className="form-control" name="email" id="emailInput" disabled={!!this.state.usernameRequired}
                           data-error={t("Email address is invalid.")} data-required-error={t("Email is required.")}
                           required placeholder={t("Email")} onChange={this.handleChange}
                    />
                    <div id="emailInputErrorDiv" className="help-block with-errors" />
                  </div>
                  {this.state.usernameRequired ? (
                    <div className="form-group">
                      <input type="text" className="form-control" name="username" id="usernameInput"
                             data-error={t("Username is invalid.")} data-required-error={t("Username is required.")} required
                             pattern="^[a-zA-Z0-9]+(([\.\+_\-]*)?[a-zA-Z0-9])*$" placeholder={t("Username")}
                             data-pattern-error={t("The username must start with an alphanumeric character, can contain any of '-', '_', '+', '.' characters and must end with an alphanumeric character.")}
                             onChange={this.handleChange}
                      />
                      <div id="usernameInputErrorDiv" className="help-block with-errors" />
                    </div>
                  ) : ""}
                  <div className="form-group">
                    <input type="text" className="form-control" name="title" id="titleInput"
                           placeholder={t("Job Title")} onChange={this.handleChange}
                           data-required-error={t("Job Title is required.")} required
                    />
                    <div id="titleInputErrorDiv" className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" name="phone" placeholder={t("Phone")}
                           id="phoneInput" data-pattern-error={t("Phone can contain only (+-), spaces and numbers.")}
                           pattern="[\-\+\s0-9]{1,}" data-minlength="10" data-minlength-error={t("Should be at least 10 digits long.")}
                           data-required-error={t("Phone number is required.")} required
                           onChange={this.handleChange}
                    />
                    <div id="phoneInputErrorDiv" className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input type="text" className="form-control" name="company" id="companyInput"
                           data-error={t("Company name is required.")} required placeholder={t("Company")}
                           onChange={this.handleChange}
                    />
                    <div id="companyInputErrorDiv" className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control" name="password" id="passwordInput"
                           data-minlength="8" data-error={t("Password is required.")} pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$"
                           data-pattern-error={t("Password is incorrect. See password requirements above.")}
                           required placeholder={t("Password")} onChange={this.handleChange}
                    />
                    <div id="passwordInputErrorDiv" className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input type="password" className="form-control" name="confirmPassword"
                           id="confirmPasswordInput" data-match="#passwordInput"
                           data-error={t("Password does not match.")}
                           data-required-error={t("Confirm Password is required.")}
                           required placeholder={t("Confirm Password")}
                    />
                    <div id="confirmPasswordInputErrorDiv" className="help-block with-errors" />
                  </div>
                  <br />
                  <div className="form-group">
                    <Button id={"createButton"} label={t("Create")} isSubmit isFullWidth />
                  </div>
                  <div className="form-group">
                    <p className="text-center loginPageSeparator-text">{t("or")}</p>
                    <p className="text-center loginPageLinks-text">
                      <a href="../index.html">{t("Login")}</a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(UserAccount, "users");
// i18next-extract-mark-ns-stop users
