"use strict";

import React, { Fragment } from "react";
import QuickPanelTitleBar from "./quick_panel_title_bar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { EDITOR_TYPES } from "../../editor/editor_constants";
import * as StickyAdapter from "../../utils/sticky_adapter";
import * as UIUtils from "../../ui_utils";
import { hasSupportForRisk } from "../../helpers/risk_helper";

/**
 *  This shows the title bar at the top of the static panel on the Process Explorer, etc.
 */
export default class StaticPanelTitleBar extends QuickPanelTitleBar {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    StickyAdapter.makeItSticky(this, $(this.thisRef).closest(".quick-panel-static-container"));
  }

  renderNameOnEdit(instanceTypeCode, instance, primaryName) {
    const riskLabelComponent = !this.props.isLoading && hasSupportForRisk(instanceTypeCode) ? this.getRiskLabelComponent() : null;
    const processName = this.getProcessName();

    return (
      <Fragment>
        <a id="titleBarPrimary"
           className="title-bar-primary-name"
           href={this.getURLForInstance(instanceTypeCode, instance)}
           rel="noopener noreferrer"
           target="_blank"
        >
          {primaryName}
        </a>
        {
          instance && typeof UIUtils.isInteger(instance.majorVersion) && UIUtils.isInteger(instance.minorVersion)
            ? (
              <span id="titleBarVersion" className="title-bar-version">v{instance.majorVersion}.{instance.minorVersion}</span>
            )
            : ""
        }
        {riskLabelComponent ? (
          <span id="titleBarRiskLabel" className="static-title-bar-risk-label">{riskLabelComponent}</span>
        ) : ""}
        {processName ? (
          <span id="titleBarProcessName" className="static-title-bar-process-name">{processName}</span>
        ) : ""}
      </Fragment>
    );
  }

  render() {
    const {instance, instanceTypeCode, editorType} = this.props;
    const primaryName = this.getPrimaryName();

    return (
      <div className="row static-panel-title-bar-container" ref={ref => this.thisRef = ref}>
        <div className="title-bar">
          {this.renderTitle(instance, instanceTypeCode, primaryName)}
          <a id="staticPanelViewInNewTabButton"
             href={this.getURLForInstance(instanceTypeCode, instance)}
             className="static-panel-icon-link"
             rel="noopener noreferrer"
             target="_blank"
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </a>
          {editorType === EDITOR_TYPES.STATIC_PANEL ? (
            <a id="staticPanelCloseButton"
               onClick={this.props.onClosePanel ? this.props.onClosePanel : undefined}
               className="static-panel-icon-link"
            >
              <FontAwesomeIcon icon={faTimes} />
            </a>
          ) : ""}
          <div className="title-bar-bottom-border" />
        </div>
      </div>
    );
  }
}
