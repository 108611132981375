import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import * as UIUtils from "../ui_utils";
import BaseReactComponent from "../base_react_component";

/**
 * This is the base class for all context menus that provide options such as: View, Edit, Archive, etc.
 */
export class BaseOptionsMenu extends BaseReactComponent {

  renderChildOptions(options) {
    return options.map((option, index) => {
      let {title, subTitle, disabled, url, openUrlInNewTab, onClick, action, id} = option;
      if (action) {
        if (action.title === "Separator") {
          return <li key={index} role="separator" className="dropdown-divider" />;
        }

        return (
          <li key={index}
              className="dropdown-item"
              onClick={disabled ? null : url ? this.onClickURL.bind(this, url) : this.onClickWrapper.bind(this, onClick)}
              title={title}
          >
            <a href={disabled ? null : url}
               className={disabled ? "anchor-disabled" : ""}
               onClick={disabled ? null : this.onClickWrapper.bind(this, onClick)}
               id={id}
               disabled={disabled}
               ref={openUrlInNewTab ? "noopener noreferrer" : ""}
            >
              {action.icon ? (
                <FontAwesomeIcon className="table-row-options-button-icon"
                                 icon={action.icon}
                />
              ) : null}
              <span>{action.title}</span>
              {subTitle ? <div><small>{subTitle}</small></div> : null}
            </a>
          </li>
        );
      }

      return null;
    });
  }

  renderItems() {
    let {options} = this.props;
    options = options.filter(option => !!option);

    return options.map((option, index) => {
      let {title, subTitle, disabled, url, openUrlInNewTab, onClick, action, id, childOptions} = option;
      if (action) {
        return action.title === "Separator" ?
          (index === options.length - 1 ? "" :
            <li key={index} role="separator" className="dropdown-divider" />) :
          (<li key={index}
               className="dropdown-item"
               onClick={disabled ? null : url ? this.onClickURL.bind(this, url) : this.onClickWrapper.bind(this, onClick)}
               title={title}
          >
            <a href={disabled ? null : url}
               className={disabled ? "anchor-disabled" : ""}
               onClick={disabled ? null : this.onClickWrapper.bind(this, onClick)}
               id={id}
               disabled={disabled}
               ref={openUrlInNewTab ? "noopener noreferrer" : ""}
            >
              {action.icon ?
                typeof action.icon === "object" ?
                  <FontAwesomeIcon className="table-row-options-button-icon"
                                   icon={action.icon}
                  />
                  : <img className="table-row-options-button-icon ml-1 context-menu-icon"
                         src={action.icon}
                         alt="contextMenuIcon"
                  />
                : null}
              <span>{action.title}</span>
              {subTitle ? <div><small>{subTitle}</small></div> : null}
              {action.rightIcon ? (
                <FontAwesomeIcon className="table-row-options-button-icon table-row-options-button-right-icon"
                                 icon={action.rightIcon}
                />
              ) : null}
            </a>
            {
              childOptions && (
                <ul className="dropdown-menu dropdown-submenu">
                  {this.renderChildOptions(childOptions)}
                </ul>
              )
            }
          </li>);
      }

      return null;
    });
  }

  onClickURL(url, event) {
    UIUtils.ignoreHandler(event); // Makes sure that this event isn't given to divs/elements that wrap this element.
    window.open(UIUtils.getSecuredURL(url), event.ctrlKey ? "_blank" : "_self");
  }

  onClickWrapper(actualOnClickFunction, event) {
    if (actualOnClickFunction) {
      UIUtils.ignoreHandler(event); // Makes sure that this event isn't given to divs/elements that wrap this element.
      actualOnClickFunction(event);
    }
  }
}
