"use strict";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import Typeahead from "../typeahead";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import * as UIUtils from "../../ui_utils";
import BaseReactComponent from "../../base_react_component";

// i18next-extract-mark-ns-start documents
class SearchBox extends BaseReactComponent {
  constructor() {
    super();

    this.typeahead = null;
    this.lastSearchTerm = null;

    this.state = {
      suggestion: null,
    };
  }

  onSearchTermChange(newSearchTerm) {
    if (typeof this.props.onSearchTermChange === "function") {
      if (!this.state.suggestion) {
        this.props.onSearchTermChange(newSearchTerm);
      }
    }
  }

  onSuggestionSelected(suggestion) {
    if (typeof this.props.onSuggestionSelected === "function") {
      this.setStateSafely({suggestion});
      this.props.onSuggestionSelected(suggestion);
    }
  }

  renderOption(option) {
    const {
      showId,
      editableName,
      showVersion,
    } = this.props;

    // if the object doesn't have the type defined on itself, we use the current editable name to retrieve it
    const defaultType = UIUtils.convertToId(editableName);

    if (!option.modelName && !option.typeCode) {
      option.modelName = defaultType;
    }

    const version = showVersion && UIUtils.isNumber(option.minorVersion) && UIUtils.isNumber(option.majorVersion)
      ? ` (v${option.majorVersion}.${option.minorVersion})`
      : "";
    const modelName = option.modelName || UIUtils.findModelNameForTypeCode(option.typeCode) || editableName;
    const idField = UIUtils.convertToId(modelName) + "Id";
    const id = option[idField] || option.id;

    const record = {...option, id};
    return (
      <div key={id} className={"search-item"}>
        <span className={"search-item-icon"}><FontAwesomeIcon icon={faStickyNote} /></span>
        <div className={"search-item-info"}>
          {
            showId
              ? (<div className={"search-item-text"}>{UIUtils.getRecordCustomLabelForDisplay(record)}{version}</div>)
              : (<div className={"search-item-text"}>{option.label}</div>)
          }
          <div className={"search-item-author"}>{option.author}</div>
        </div>
      </div>
    );
  }

  componentDidUpdate() {
    if (Object.prototype.hasOwnProperty.call(this.props, "clearSearchTerm") &&
      this.props.clearSearchTerm) {
      this.typeahead.getInstance().clear();
    }
  }

  render() {
    const {
      t,
      filterSelector,
      showId,
    } = this.props;

    return <div className="search-box">
      <FontAwesomeIcon icon={faSearch} className="search-icon" />
      <Typeahead
        id={this.props.id}
        options={this.props.items}
        placeholder={t("Type here to search")}
        ignoredExpression={showId ? /-/g : null}
        filterSelector={filterSelector}
        renderMenuItemChildren={this.renderOption}
        onChange={(selected) => {
          if (selected && selected.length === 1) {
            this.onSuggestionSelected(selected[0]);
          }
        }}
        inputProps={{id: this.props.id + "Input", autoComplete: "off"}}
        onKeyDown={(event) => {
          const instance = this.typeahead.getInstance();

          if (instance && event.key.toString() === "Enter") {
            instance.blur();
          }
        }}
        onBlur={() => {
          const instance = this.typeahead.getInstance();
          if (instance) {
            const input = instance.getInput();
            const newSearchTerm = (input && input.value ? input.value : "").trim();
            const lastSearchTerm = (this.lastSearchTerm || "").trim();

            if (newSearchTerm !== lastSearchTerm) {
              // ensures this happens after all events processing, so the method can read from the updated state
              setTimeout(() => {
                this.onSearchTermChange(newSearchTerm);
              }, 0);
              this.lastSearchTerm = newSearchTerm;
            }
          }
        }}
        ref={instance => this.typeahead = instance}
      />
    </div>;
  }
}

export default I18NWrapper.wrap(SearchBox, "documents");
// i18next-extract-mark-ns-stop documents
