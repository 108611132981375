var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { classNames } from '@progress/kendo-react-common';
import { getItemValue } from './utils';
// tslint:enable:max-line-length
/**
 * @hidden
 */
var ListItem = /** @class */ (function (_super) {
    __extends(ListItem, _super);
    function ListItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleClick = function (e) { return _this.props.onClick(_this.props.index, e); };
        return _this;
    }
    ListItem.prototype.render = function () {
        var _a = this.props, selected = _a.selected, group = _a.group, dataItem = _a.dataItem, virtual = _a.virtual, renderer = _a.render;
        var item = (React.createElement("li", { id: this.props.id, role: "option", "aria-selected": selected, className: classNames('k-list-item', {
                'k-selected': selected,
                'k-focus': this.props.focused,
                'k-first': Boolean(group)
            }), onClick: this.handleClick, style: { position: virtual ? 'relative' : 'unset' } },
            React.createElement("span", { className: 'k-list-item-text' }, getItemValue(dataItem, this.props.textField).toString()),
            group !== undefined ? React.createElement("div", { className: "k-list-item-group-label" }, group) : null));
        return renderer !== undefined ? renderer.call(undefined, item, this.props) : item;
    };
    return ListItem;
}(React.Component));
export default ListItem;
