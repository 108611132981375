import { Keys } from '@progress/kendo-react-common';
/**
 * @hidden
 */
var navigate = function (focusedIndex, keyCode, altKey, total) {
    if (altKey) {
        return focusedIndex;
    }
    var index = Math.min(total - 1, Math.max(0, focusedIndex));
    switch (keyCode) {
        case Keys.enter:
        case Keys.space:
        case Keys.esc:
            return -1;
        case Keys.up:
        case Keys.left:
            return index - 1 < 0 ? total - 1 : index - 1;
        case Keys.down:
        case Keys.right:
            return index + 1 >= total ? 0 : index + 1;
        case Keys.home:
            return 0;
        case Keys.end:
            return total - 1;
        default:
            return focusedIndex;
    }
};
export { navigate };
