"use strict";

import ConfigurableTablesAdapterHelper from "./configurable_tables_adapter_helper";
import RecordsIndexer from "../../processExplorer/indexers/records_indexer";

/**
 * This class adapts/transforms the data coming from the server
 * to be suitable for UI digestion.
 */
export default class ConfigurableTablesAdapter {

  /**
   * @param params includes the following params
   * @param activeMapName Main map of the adapter
   * @param modelName ModelName the adapter is for
   * @param parentPropName Parent of the model (ex. PRC or MT)
   * results {ProcessExplorerResults} The results from the process explorer handler
   * fromProcessId {int} The id of the from Process in the comparison
   * typeaheadOptions Options for risk links
   * effectiveRMP Effective RMP
   * selectedModelType Adapt results depending on requested model type
   */
  constructor(params, activeMapName, modelName, parentPropName) {
    this.params = params;
    this.results = params.results;
    this.project = this.results.project;
    this.typeaheadOptions = params.typeaheadOptions;
    this.effectiveRMP = params.effectiveRMP;
    this.selectedModelType = params.selectedModelType;
    this.activeMap = this.results[activeMapName];
    this.modelName = modelName;
    this.parentPropName = parentPropName;
    this.recordsIndexer = new RecordsIndexer(params, activeMapName, modelName);
    this.configurableTablesAdapterHelper = new ConfigurableTablesAdapterHelper({
      results: this.results,
      activeMap: this.activeMap,
      parentPropName: this.parentPropName,
      project: this.project,
    });
  }

  sanitizeEmptyValues(rows) {
    return rows.map((record) =>
      Object.keys(record).reduce((acc, key) => {
        acc[key] = record[key] === "" ? null : record[key];
        return acc;
      }, {})
    );
  }

  adaptRows(rows) {
    for (const record of rows) {
      const versionInfo = record.LastVersion;
      record.version = `${versionInfo?.majorVersion}.${versionInfo?.minorVersion}`;
    }
  }

  getRows() {
    const records = Object.values(this.activeMap);
    this.adaptRows(records);
    return this.sanitizeEmptyValues(records);
  }

  wrapResults(records) {
    const sortedByOrderRows = this.recordsIndexer.fillInSortByOrderProperty(records);
    const groupedRows = this.recordsIndexer.groupRowsByUOAndStep(sortedByOrderRows);
    return this.sanitizeEmptyValues(groupedRows);
  }
}
