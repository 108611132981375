"use strict";


import LinkTechTransferBaseStep from "./link_tech_transfer_base_step";
import React, { Fragment } from "react";
import * as UIUtils from "../../../../ui_utils";
import { DropdownButton } from "@qbdvision-inc/component-library";
import Typeahead from "../../../../widgets/typeahead";
import * as FailHandlers from "../../../../utils/fail_handlers";

export default class RecordMatcherStep extends LinkTechTransferBaseStep {
  constructor(props) {
    super(props);
    this.props.config.disabled = false;
    this.matchUOs();
  }

  matchUOs() {
    const {
      receivingRecords,
      sendingRecords,
      parent,
    } = this.props;

    UIUtils.setLoadingDisabled(false);
    UIUtils.showLoadingImage("Matching records for you, please wait...");

    // Map Unit Operations first
    const receivingUOs = receivingRecords.UnitOperations.map(receivingUO => {
      // See if the UO was cloned from a UO in the sending process
      let sendingUO = sendingRecords.UnitOperations.find(sendingUO => sendingUO.id === receivingUO.clonedFromVersionId);

      // If not cloned, try to match by name
      if (!sendingUO) {
        sendingUO = sendingRecords.UnitOperations.find(sendingUO => sendingUO.name.toLowerCase() === receivingUO.name.toLowerCase());
      }

      receivingUO.transferredFromId = sendingUO ? sendingUO.id : null;
      return receivingUO;
    });

    const receivingSTPs = receivingRecords.Steps.map(receivingSTP => {
      //Get the receiving UO, see get it's sendingId and look for the STPs with that ID
      const parentUO = receivingUOs.find(UO => UO.id === receivingSTP.UnitOperationId);

      // If the parent UO doesnt have a match then skip this step
      if (!parentUO.transferredFromId) {
        return receivingSTP;
      }

      const childSendingSteps = sendingRecords.Steps.filter(step => step.UnitOperationId === parentUO.transferredFromId);
      let matchedStep = childSendingSteps.find(step => step.id === receivingSTP.clonedFromVersionId);

      if (!matchedStep) {
        matchedStep = childSendingSteps.find(step => step.name.toLowerCase() === receivingSTP.name.toLowerCase());
      }

      receivingSTP.transferredFromId = matchedStep ? matchedStep.id : null;
      return receivingSTP;
    });
    const matchedReceivingRecords = {
      UnitOperations: receivingUOs,
      Steps: receivingSTPs
    };
    parent.setStateSafely({
      receivingRecords: matchedReceivingRecords,
    });
    UIUtils.setLoadingDisabled(true);
  }

  handleUOChange(event, receivingUO) {
    const {
      sendingRecords,
      receivingRecords,
      parent
    } = this.props;

    const selectedUODisplayName = event.target.innerText;
    const selectedUO = sendingRecords.UnitOperations.find(UO => UO.displayName === selectedUODisplayName);
    const receivingUnitOperations = UIUtils.deepClone(receivingRecords.UnitOperations);
    let receivingSteps = UIUtils.deepClone(receivingRecords.Steps);

    receivingUnitOperations.find(UO => UO.id === receivingUO.id).transferredFromId = selectedUO.id;
    receivingSteps = receivingSteps.map(step => {
      if (step.UnitOperationId === receivingUO.id) {
        step.transferredFromId = null;
        let matchedStep = sendingRecords.Steps.find(sendingSTP => sendingSTP.id === step.clonedFromVersionId && sendingSTP.UnitOperationId === selectedUO.id);

        if (!matchedStep) {
          matchedStep = sendingRecords.Steps.find(sendingSTP => sendingSTP.name.toLowerCase() === step.name.toLowerCase() && sendingSTP.UnitOperationId === selectedUO.id);
        }

        step.transferredFromId = matchedStep ? matchedStep.id : null;
      }
      return step;
    });
    parent.setStateSafely({
      receivingRecords: {
        UnitOperations: receivingUnitOperations,
        Steps: receivingSteps
      }
    });
  }

  handleSTPChange(event, receivingSTP) {
    const {
      receivingRecords,
      sendingRecords,
      parent,
    } = this.props;

    const selectedSTPDisplayName = event.target.innerText;
    const selectedSTP = sendingRecords.Steps.find(UO => UO.displayName === selectedSTPDisplayName);
    const receivingUnitOperations = UIUtils.deepClone(receivingRecords.UnitOperations);
    let receivingSteps = UIUtils.deepClone(receivingRecords.Steps);
    receivingSteps.find(step => step.id === receivingSTP.id).transferredFromId = selectedSTP.id;

    parent.setStateSafely({
      receivingRecords: {
        UnitOperations: receivingUnitOperations,
        Steps: receivingSteps
      }
    });
  }

  handleLinkAndEnableTechTransfer() {
    const payload = {
      projectId: this.props.project.id,
      process: this.props.receivingProcess,
      records: [...this.props.receivingRecords.UnitOperations, ...this.props.receivingRecords.Steps],
    };
    UIUtils.setLoadingDisabled(false);
    UIUtils.showLoadingImage("Enabling Tech Transfer...");
    UIUtils.secureAjaxPUT("techTransfer/linkSendingSite",
      payload,
      true,
      FailHandlers.defaultStopLoadingFailFunction.bind(this))
      .done(() => {
        const url = "/processExplorer/processExplorer.html?" + `projectId=${this.props.project.id}` + `&processId=${this.props.receivingProcess.id}`;

        window.location.href = UIUtils.getSecuredURL(url);
      });
  }

  renderStep() {
    const {
      selectedProcess,
      receivingProcess,
      receivingRecords,
      sendingRecords,
    } = this.props;
    return (
      <Fragment>
        <div className="col-12">
          <div className="row import-wizard-step-inner-container">
            <table className="record-matching-table">
              <thead>
              <tr>
                <th className="record-matching-table-heading"><b>Sending Process</b></th>
                <th>{/*empty*/}</th>
                <th className="record-matching-table-heading"><b>Receiving Process</b></th>
              </tr>
              <tr>
                <th className="record-matching-table-subheading" id="sendingSiteSubheading">{selectedProcess[0].name}</th>
                <th>{/*empty*/}</th>
                <th className="record-matching-table-subheading" id="receivingSiteSubheading">{receivingProcess.name}</th>
              </tr>
              </thead>
              <tbody>
              {receivingRecords.UnitOperations.map(UnitOperation => {
                const unitOperationSelectionRowId = UIUtils.convertToCamelCaseId(UIUtils.stripSpecialChars(UnitOperation.name));
                const unitOperationRow = (
                  <tr className="record-matching-unit-operation-row"
                      id={unitOperationSelectionRowId + "Row"}
                  >
                    <td className="record-matching-sending-site">
                      <DropdownButton label={sendingRecords.UnitOperations.find(UO => UO.id === UnitOperation.transferredFromId)?.displayName || "Select a Unit Operation"}
                                      id="sendingUnitOperation"
                                      type="secondary"
                                      options={sendingRecords.UnitOperations.map(UO => {
                                        return {
                                          value: UO.displayName,
                                          key: UIUtils.stripAllWhitespaces(UIUtils.stripSpecialChars(UO.name)),
                                          disabled: false,
                                          title: `${UO.name}Selection`,
                                        };
                                      })}
                                      onOptionsSelect={(event) => this.handleUOChange(event, UnitOperation)}
                                      width="445"
                      />
                    </td>
                    <td className="arrow-row">
                      <div id="arrow">
                        <div className="bar" />
                        <div className="chevron-right" />
                      </div>
                    </td>
                    <td className="record-matching-receiving-site">
                      <Typeahead id={UnitOperation.id}
                                 options={[UnitOperation.displayName]}
                                 defaultSelected={[UnitOperation.displayName]}
                                 disabled={true}
                      />
                    </td>
                  </tr>
                );

                const stepRows = (receivingRecords.Steps
                    .filter((step) => step.UnitOperationId === UnitOperation.id)
                    .map(Step => {
                      const stepSelectionRowId = UIUtils.convertToCamelCaseId(Step.name);
                      return (
                        <tr key={Step.id}
                            id={stepSelectionRowId + "Row"}
                        >
                          <td className="record-matching-sending-site step">
                            <DropdownButton label={sendingRecords.Steps.find(step => step.id === Step.transferredFromId)?.displayName || "Select a Step"}
                                            id="sendingStep"
                                            type="secondary"
                                            options={sendingRecords.Steps.filter(step => step.UnitOperationId === UnitOperation.transferredFromId).map(step => {
                                              return {
                                                value: step.displayName,
                                                key: UIUtils.stripAllWhitespaces(UIUtils.stripSpecialChars(step.name)),
                                                disabled: false,
                                                title: `${step.name}Selection`,
                                              };
                                            })}
                                            onOptionsSelect={(event) => this.handleSTPChange(event, Step)}
                                            width="410"
                            />
                          </td>
                          <td className="arrow-row">
                            <div id="arrow">
                              <div className="bar" />
                              <div className="chevron-right" />
                            </div>
                          </td>
                          <td className="record-matching-receiving-site receiving-step"
                          >
                            <Typeahead id={Step.id}
                                       options={[Step.displayName]}
                                       defaultSelected={[Step.displayName]}
                                       disabled={true}
                            />
                          </td>
                        </tr>
                      );
                    })
                );
                return [unitOperationRow, ...stepRows];
              })}

              </tbody>

            </table>
          </div>
        </div>

        <div className="col-12 link-sending-site-screen-footer">
          <button
            title="Link and Enable Tech Transfer"
            className="btn btn-lg btn-primary link-sending-site-footer-btn"
            id="enableTechTransferButton"
            onClick={this.handleLinkAndEnableTechTransfer}
          >
            Link & Enable Tech Transfer
          </button>
          {this.renderExitButton()}
        </div>
      </Fragment>
    );
  }

}