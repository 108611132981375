"use strict";

import React, { Fragment } from "react";
import * as UIUtils from "../ui_utils";
import DocumentEditor from "./document_editor";
import BasePage from "../base_page";
import { EditablesService } from "../services/editables/editables_service";


export default class DocumentEditorPage extends BasePage {
  constructor(props) {
    super(props);

    this.editableService = new EditablesService();

    this.documentId = UIUtils.parseInt(
      UIUtils.getParameterByName("documentId")
    );
    this.documentRecordLocalStorageKey = UIUtils.getParameterByName(
      "documentRecordLocalStorageKey"
    );

    this.setStateSafely({
      breadcrumb: {
        current: "Doc Builder",
        parent: "Documents",
        parentLink: "/documents/list.html",
      },
      documentRecord: null,
    });
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = "QbDVision Document";
    if (this.documentRecordLocalStorageKey) {
      this.handleReceiveDocumentFromServer(
        JSON.parse(
          localStorage.getItem(this.documentRecordLocalStorageKey) || "{}"
        )
      );
    } else {
      UIUtils.setHideLoadingOnAjaxStop(false);
      this.editableService
        .get({
          urlPrefix: "editables/Document",
          action: this.documentId,
          urlParameters: {
            includeHistory: true,
            approved: false,
          },
        })
        .then(this.handleReceiveDocumentFromServer);
    }
  }

  handleReceiveDocumentFromServer(result) {
    let { breadcrumb } = this.state;
    if (result.project) {
      const { project } = result;
      breadcrumb = {
        current: "Doc Builder",
        paths: [
          { parent: "Projects", parentLink: "/projects/list.html" },
          {
            parent: `PRJ-${project.id} - ${project.name}`,
            parentLink: `/projects/dashboard.html?projectId=${project.id}`,
          },
        ],
      };
    }
    this.setStateSafely({ documentRecord: result, breadcrumb });
  }

  renderPage() {
    const { documentRecord } = this.state;
    return (
      <Fragment>
        {documentRecord && (
          <DocumentEditor
            documentRecord={documentRecord}
          />
        )}
      </Fragment>
    );
  }
}
