"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ValidationIcon from "../generic/validation_icon";
import TabNavBar from "./tab_nav_bar";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { isEqual } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* This is a tab navigation control that meant to be under the main navigation top bar */

// i18next-extract-mark-ns-start widgets
class SubTopNavBar extends TabNavBar {
  render() {
    let {tabs, selected, modelName, errors, className, subTopNavClassName, t} = this.props;
    modelName = modelName || "";

    const selectedTab = selected;

    return (
      <div className={`tab-nav-bar ${subTopNavClassName || "sub-top-nav"} ` + (className || "")}>
        {
          Object.keys(tabs).filter(key => tabs[key].visible !== false).map(key => {

            const tab = tabs[key];
            const {title, disabled, icon} = tab;
            const anchorClassName = isEqual(selectedTab, tab) ? "sub-top-nav-selected" : "";
            const tooltip = tab.disabled && tab.disabledTooltip ? tab.disabledTooltip : "";
            const linkClickEventHandler = !tab.disabled ? this.handleClickTab.bind(this, tab) : null;

            if (tab.experiment && !UIUtils.isExperimentEnabled(tab.experiment)) {
              return <div key={modelName + title} />;
            } else {
              return (
                <div key={modelName + title}
                     id={`${UIUtils.convertToCamelCaseId(modelName + title)}Tab`}
                     className={"tab-nav-bar-item home-page-nav-bar-item"
                     + (disabled ? " links-btn-disabled" : "")}
                >
                  <a onClick={linkClickEventHandler}
                     title={tooltip}
                     className={anchorClassName}
                     disabled={disabled}
                  >
                    {icon ?
                      <FontAwesomeIcon className="tab-icon m-0"
                                       size="sm"
                                       icon={icon}
                      /> : ""}
                    {t(title)}
                  </a>
                  <ValidationIcon id={`${UIUtils.convertToCamelCaseId(modelName + tab.title)}TabIcon`}
                                  tooltip={errors ? errors[`${modelName}${title}TabError`] : ""}
                                  visible={errors && !!errors[modelName + title + "TabError"]}
                  />
                </div>
              );
            }
          })
        }
      </div>
    );
  }
}

export default I18NWrapper.wrap(SubTopNavBar, "widgets");
// i18next-extract-mark-ns-stop widgets
