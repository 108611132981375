"use strict";

import {BaseDataTransform} from "../base_data_transform";
import * as UIUtils from "../../../ui_utils";
import {
  getLocalDateFromDate,
  processEmptyFields
} from "../../canned_reports/canned_report_helper";

export class SupplierDataTransform extends BaseDataTransform {

  get type() {
    return "supplier";
  }

  shouldRun(options) {
    return true;
  }

  transform(input, options) {
    const result = input;
    const data = [];

    const approvedSuppliers = (result.instances.suppliers || [])
      .filter(version => version.majorVersion > 0 && version.minorVersion === 0 && !version.deletedAt)
      .sort((s1, s2) => s1.name < s2.name ? -1 : (s1.name > s2.name ? 1 : 0));

    for (let supplier of approvedSuppliers) {

      const newItem = {
        typeCode: "SUP",
        id: UIUtils.secureString(supplier.SupplierId),
        name: UIUtils.secureString(supplier.name),
        majorVersion: UIUtils.parseInt(supplier.majorVersion),
        minorVersion: UIUtils.parseInt(supplier.minorVersion),
        productOrService: UIUtils.secureString(supplier.servicesOrProducts),
        supplierRank: UIUtils.secureString(supplier.supplierRank),
        qualificationStatus: UIUtils.secureString(supplier.qualificationStatus),
        effectiveDate: getLocalDateFromDate(supplier.effectiveDate, UIUtils.DATE_FORMAT_FOR_DISPLAY),
        expirationDate: getLocalDateFromDate(supplier.expirationDate, UIUtils.DATE_FORMAT_FOR_DISPLAY),
        approvalDate: getLocalDateFromDate(supplier.createdAt, UIUtils.DATE_FORMAT_FOR_DISPLAY),
        supplierRisk: UIUtils.secureString(supplier.riskRating),
      };

      processEmptyFields(newItem);
      data.push(newItem);
    }
    result.instances.suppliers = data;
    result.instances.isEmptySummary = data.length === 0;
    return result;
  }
}
