"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import moment from "moment";
import { getUserPageURL } from "../../helpers/url_helper";
import ApproverTooltips from "./approver_tooltips";
import ApprovalRequestTooltip from "./approval_request_tooltip";
import BaseReactComponent from "../../base_react_component";

/* This shows a single transition of a particular version of a given object. */
export default class HistoryItemTransition extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    return (nextProps.visible !== this.props.visible ||
      nextProps.parentVersion !== this.props.parentVersion ||
      nextProps.currentTransition !== this.props.currentTransition ||
      nextProps.previousTransition !== this.props.previousTransition ||
      nextProps.nextTransition !== this.props.nextTransition);
  }

  render() {
    let parentVersion = this.props.parentVersion;
    let currentTransition = this.props.currentTransition;
    let previousVersionTransitions = this.props.previousVersion ? this.props.previousVersion.versionTransitions : null;
    let approvalRequestTransition;
    let versionTransitionInfo = "";
    let versionTransitionApproverTooltips = "";
    let showInfo = false;
    let showTooltips = false;
    let filteredPreviousTransitions = (previousVersionTransitions ? previousVersionTransitions.filter(versionTransition => {
      return (versionTransition.transition !== UIUtils.VERSION_STATES.ROUTING);
    }) : []);

    if (currentTransition.transition === UIUtils.VERSION_STATES.REJECTED ||
      currentTransition.transition === UIUtils.VERSION_STATES.ROUTING ||
      currentTransition.transition === UIUtils.VERSION_STATES.ARCHIVED ||
      currentTransition.transition === UIUtils.VERSION_STATES.RESTORED) {
      approvalRequestTransition = this.props.previousTransition;
    } else if ((currentTransition.transition === UIUtils.VERSION_STATES.APPROVED
        || currentTransition.transition === UIUtils.VERSION_STATES.OBSOLETE)
      && filteredPreviousTransitions.length > 0) {
      approvalRequestTransition = filteredPreviousTransitions[0];
    } else {
      approvalRequestTransition = currentTransition;
    }

    if (approvalRequestTransition && approvalRequestTransition.approvalRequest) {
      let approvalRequest = approvalRequestTransition.approvalRequest;
      let userIdToApprovalResponse = new Map();

      // Figure out if this version transition was unanimously approved.
      let isApproved = approvalRequest.approvalReqToUsers.length === approvalRequestTransition.approvalResponses.length;
      if (approvalRequestTransition.approvalResponses) {
        for (let approvalResponse of approvalRequestTransition.approvalResponses) {
          userIdToApprovalResponse[approvalResponse.createdByUserId] = approvalResponse;
          isApproved = isApproved && approvalResponse.approve;
        }
      }

      // We only show the info tooltip on the transition where the request was submitted.
      showInfo = (approvalRequestTransition === currentTransition);
      if (showInfo) {
        versionTransitionInfo = (
          <ApprovalRequestTooltip
            version={parentVersion}
            versionTransition={approvalRequestTransition}
            userIdToApprovalResponse={userIdToApprovalResponse}
            isVersionTransitionTooltip={true}
            index={this.props.index}
          />
        );
      }

      /*We only show the approval tooltips when
        1. The approval request transition is not approved and this is the last transition in the parent version
           or this is not the last one but the next transition is in withdrawn state
           or the approval request transition is the previous transition in the same version (which would mean that the
           current transition is rejected)
        2. OR The approval request is approved and the approval request transition is the last transition of the previous
           version (which means that this transition is Approved) or the approval request transition is the previous transition
           (which means that this was approved for archive or restore). */
      showTooltips = (
        (!isApproved &&
          (!this.props.nextTransition || this.props.nextTransition.transition === UIUtils.VERSION_STATES.WITHDRAWN ||
            approvalRequestTransition === this.props.previousTransition)
        ) ||
        (isApproved
          && (currentTransition.transition !== UIUtils.VERSION_STATES.ROUTING)
          && ((approvalRequestTransition === filteredPreviousTransitions[0])
            || (approvalRequestTransition === this.props.previousTransition))
        )
      );
      if (showTooltips) {
        versionTransitionApproverTooltips = (
          <ApproverTooltips
            version={parentVersion}
            versionTransition={approvalRequestTransition}
            isVersionTransitionTooltip={true}
            index={this.props.index}
          />
        );
      }
    }

    return this.props.visible ? (
      <div className="row history-item-transition">
        <div className="col-12 history-item-date">
          {moment(currentTransition.createdAt).format(UIUtils.LONG_DATE_FORMAT_FOR_DISPLAY)}
          {showInfo ? versionTransitionInfo : ""}
        </div>
        <div className="col-12">
          {currentTransition.transition === UIUtils.VERSION_STATES.APPROVED
          || currentTransition.transition === UIUtils.VERSION_STATES.ARCHIVED
          || currentTransition.transition === UIUtils.VERSION_STATES.RESTORED
          || currentTransition.transition === UIUtils.VERSION_STATES.OBSOLETE
          || currentTransition.transition === UIUtils.VERSION_STATES.ROUTING
          || currentTransition.transition === UIUtils.VERSION_STATES.REJECTED ? (
            <div className="col-12 history-updated-by history-updated-by-state">
              {currentTransition.transition}
            </div>
          ) : (
            <div className="col-12 history-updated-by">
              <span className="history-updated-by-state">{currentTransition.transition === UIUtils.VERSION_STATES.DRAFT ? "Drafted" : currentTransition.transition} </span>
              <span>by </span>
              <a href={getUserPageURL(currentTransition.createdByUser)}
                 rel="noopener noreferrer"
                 target="_blank"
              >
                <span className="history-updated-by-user">{currentTransition.createdByUser.firstName} {currentTransition.createdByUser.lastName}&nbsp;</span>
              </a>
            </div>
          )}
        </div>
        {showTooltips ? versionTransitionApproverTooltips : ""}
      </div>
    ) : "";
  }
}
