"use strict";

/*
 * The functions in this file are responsible for sorting and indexing all PE variables by order
 */

import { RecordsIndexer, RecordsIndexerBuilder } from "../../../server/common/indexers/common_records_indexer";

export { RecordsIndexerBuilder };

export default RecordsIndexer;