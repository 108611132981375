import RecordFields from "../record_fields";
import { FIELD_PROPS } from "../constants/configurable_tables_field_props_config";

/**
 * This class lists all IQA/IPA fields to be shown in export, column selection and tables.
 */
export default class IntermediateAttributeFields extends RecordFields {
  get criticalityAssessmentSectionFields() {
    return super.criticalityAssessmentSectionFields.filter(field => field.prop !== FIELD_PROPS.POTENTIAL_FAILURES);
  }

  get riskControlSectionFields() {
    return super.riskControlSectionFields;
  }
}
