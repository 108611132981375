"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import BaseQuickPanel from "./base_quick_panel";

/**
 * @typedef {Object} QuickPanelConfig
 * @property {boolean} showHistory Set to true if you want the quick panel to show the records history when in view mode
 * @property {string} quickPanelButtons An array of button to show in the quick panel title bar
 * @property {SAVE_BEHAVIOR_ENUM} saveBehavior Control if a save action should save and reload the whole page or just save
 * the quick panel record but not reload the page
 * @property {boolean} sectionsCollapsed Set this to true to display a record in the quick panel with all sections being
 * @property {AUTO_VALIDATION_MODE_ENUM} set this to control the initial validation triggered in a quick edit form when it
 * opens for the first time.
 */

/**
 * Use this to show the editor panel in a div on the screen that is larger and more persistent, such as in the
 * process explorer.
 */
export default class StaticPanel extends BaseQuickPanel {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    /* This is required for registering the bootstrap tooltips that are created/updated
       dynamically after UIUtils is loaded.
    */
    $("[data-toggle='popover']").popover({sanitizeFn: UIUtils.sanitizePopoverData});
  }

  render() {
    return (
      <Fragment>
        <div
          id="quickPanelInnerContainer"
          className="quick-panel-static-container container-fluid"
          style={this.props.forcedHeight ? {height: this.props.forcedHeight} : {}}
          ref={this.containerRef}
        >
          {this.renderPanelContent()}
        </div>
      </Fragment>
    );
  }
}
