"use strict";

import * as styles from "./widget_fields_tree.module.scss";

import React, {useEffect, useState} from "react";
import {TreeViewExpandChangeEvent, TreeViewItemClickEvent,} from "@progress/kendo-react-treeview";
import {createTreeItem, TreeItemType} from "../../tree_item";
import {WIDGET_KIND_VALUE} from "./widget_constants";
import * as UIUtils from "../../../../ui_utils";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Project} from "../../../../common/models/project";
import {FIELD_KEY, FieldItem, getDisplayNameOfModel, getFields,} from "../../../common/editables_map";
import {groupFieldsBySection} from "../../../common/editables_section_map";
import SideMenuTree from "../side_menu_tree";
import {Filter} from "../../multiple_filters";

type WidgetFieldsTreeProps = {
  modelName: string;
  subModelName?: string;
  widgetType: WIDGET_KIND_VALUE;
  filters?: Array<Filter>;
  project: Project;
  onBackClick: () => void;
  // eslint-disable-next-line no-unused-vars
  onAddField: (field: string) => void;
};

export default function WidgetFieldsTree(props: WidgetFieldsTreeProps) {
  const {
    widgetType,
    modelName,
    subModelName,
    filters,
    project,
    onBackClick,
    onAddField,
  } = props;
  const [treeData, setTreeData] = useState<Array<TreeItemType>>([]);
  const FIELDS = getFields(project);

  const getFieldsTreeDataForRecord = () => {
    const fieldsTreeData: Array<TreeItemType> = [];
    if (!modelName) {
      return fieldsTreeData;
    }

    const recordFields = FIELDS.get(FIELD_KEY.RecordFields).attributesMap.get(
      modelName,
    );
    if (!recordFields) {
      return fieldsTreeData;
    }

    if (subModelName) {
      const subModelFields = recordFields.attributesMap.get(subModelName);
      const sectionTreeNode = createTreeItem({
        text: subModelFields.displayName,
        className: "section-tree-node",
      });
      fieldsTreeData.push(sectionTreeNode);
      for (const fieldItem of subModelFields.attributesMap.values()) {
        sectionTreeNode.items.push(
          createSectionTreeLeaf(fieldItem, 1, modelName, subModelName),
        );
      }
      return fieldsTreeData;
    }

    const sectionToFields = groupFieldsBySection(
      modelName,
      Array.from(recordFields.attributesMap.values()),
    );
    for (const [section, fieldItems] of sectionToFields.entries()) {
      const sectionTreeNode = createTreeItem({
        text: section,
        className: "section-tree-node",
      });
      fieldsTreeData.push(sectionTreeNode);

      for (const fieldItem of fieldItems) {
        // Here we want to skip secondary fields as they require a Repeater/Table to be displayed
        if (fieldItem.attributesMap && fieldItem.name !== "AcceptanceCriteriaRange") {
          continue;
        }
        if (fieldItem.attributesMap) {
          const subModelTreeNode = createTreeItem({
            level: 1,
            text: fieldItem.displayName,
            className: "subsection-tree-node",
          });
          for (const field of fieldItem.attributesMap.values()) {
            subModelTreeNode.items.push(
              createSectionTreeLeaf(field, 2, modelName, fieldItem.name),
            );
          }
          sectionTreeNode.items.push(subModelTreeNode);
          continue;
        }

        sectionTreeNode.items.push(
          createSectionTreeLeaf(fieldItem, 1, modelName),
        );
      }
    }

    return fieldsTreeData;
  };

  const createSectionTreeLeaf = (
    fieldItem: FieldItem,
    level: number,
    modelName: string,
    subModelName?: string,
  ) => {
    const value = subModelName
      ? `${modelName}.${subModelName}.${fieldItem.name}`
      : `${modelName}.${fieldItem.name}`;
    return createTreeItem<string>({
      level,
      value,
      text: fieldItem.displayName,
      isLeaf: true,
      className: "text-truncate section-tree-leaf",
    });
  };

  useEffect(() => {
    setTreeData(getFieldsTreeDataForRecord());
  }, [widgetType, subModelName, modelName]);

  const handleExpandChange = (event: TreeViewExpandChangeEvent) => {
    event.item.expanded = !event.item.expanded;
    setTreeData([...treeData]);
  };

  const handleFieldChange = (event: TreeViewItemClickEvent) => {
    const item = event.item as TreeItemType;
    if (item.isLeaf) {
      onAddField(item.value);
    }
  };

  const subModelDisplayName = getDisplayNameOfModel(
    FIELDS,
    modelName,
    subModelName,
  );
  return (
    <div id="widget-fields-tree">
      <div className="border-bottom py-2 px-3">
        <div className="row m-0 flex-row align-items-center">
          <FontAwesomeIcon
            id="widget-field-tree-back-button"
            className={`m-0 p-0 btn btn-sm ${styles["record-header-back-button"]}`}
            icon={faArrowLeft}
            onClick={onBackClick}
          />
          <div
            data-widget-type={widgetType}
            className={`font-weight-bold ${styles["widget-type-header"]}`}
          >
            {widgetType}
          </div>
        </div>
        <div data-model-name={modelName} className="row m-0 pl-4 flex-column">
          <div>
            Model: {UIUtils.convertCamelCaseToSpacedOutWords(modelName)}
          </div>
          {subModelDisplayName && <div>Submodel: {subModelDisplayName}</div>}
          {filters && filters.length ? (
            <div>
              Filter:{" "}
              {filters
                .map(
                  (filter) =>
                    `${filter.attribute} ${filter.operator} ${filter.targetValue}`,
                )
                .join(" ")}
            </div>
          ) : null}
        </div>
      </div>
      <div
        className={`border-bottom py-2 font-weight-bold ${styles["widget-info-header"]}`}
      >
        ADD SMART CONTENT
      </div>
      <SideMenuTree
        treeData={treeData}
        onExpandChange={handleExpandChange}
        onItemClick={handleFieldChange}
      />
    </div>
  );
}
