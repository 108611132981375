var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { registerForLocalization, provideLocalizationService, provideIntlService } from '@progress/kendo-react-intl';
import { messages, pagerOf, pagerPage, pagerTotalPages } from './../messages';
/**
 * @hidden
 */
var PagerInput = /** @class */ (function (_super) {
    __extends(PagerInput, _super);
    function PagerInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this._text = undefined;
        _this.onChange = function (e) {
            var text = _this._text = e.target.value.replace(/\D/g, '');
            _this.forceUpdate();
            if (text) {
                _this.props.pageChange(parseInt(text, 10), e);
            }
        };
        _this.value = function () {
            var value = _this._text === undefined ? _this.props.currentPage.toString() : _this._text;
            _this._text = undefined;
            return value;
        };
        return _this;
    }
    PagerInput.prototype.render = function () {
        var _this = this;
        var intlService = provideIntlService(this);
        var localizationService = provideLocalizationService(this);
        var pageMessage = this.props.messagesMap ? this.props.messagesMap(pagerPage) :
            ({ messageKey: pagerPage, defaultMessage: messages[pagerPage] });
        var ofMessage = this.props.messagesMap ? this.props.messagesMap(pagerOf) :
            ({ messageKey: pagerOf, defaultMessage: messages[pagerOf] });
        var totalPagesMessage = this.props.messagesMap ? this.props.messagesMap(pagerTotalPages) :
            ({ messageKey: pagerTotalPages, defaultMessage: messages[pagerTotalPages] });
        return (React.createElement("span", { className: "k-pager-input k-label" },
            localizationService.toLanguageString(pageMessage.messageKey, pageMessage.defaultMessage),
            React.createElement("span", { className: 'k-textbox k-input k-input-md k-rounded-md k-input-solid' },
                React.createElement("input", { className: "k-input-inner", value: this.value(), onBlur: function () { return _this.forceUpdate(); }, onChange: this.onChange })), "".concat(localizationService.toLanguageString(ofMessage.messageKey, ofMessage.defaultMessage), " ").concat(intlService
            .format(localizationService.toLanguageString(totalPagesMessage.messageKey, totalPagesMessage.defaultMessage), [
            this.props.totalPages
        ]))));
    };
    return PagerInput;
}(React.Component));
export { PagerInput };
registerForLocalization(PagerInput);
