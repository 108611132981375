"use strict";

import React, { Fragment } from "react";
import * as UIUtils from "../../../ui_utils";
import { getURLByTypeCodeAndId } from "../../../helpers/url_helper";
import { getRiskScale, getTableCellClassFromRiskScore, } from "../../../helpers/risk_helper";
import { RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import TechTransferChangeColumnTooltip from "../../widgets/tooltips/tech_transfer_change_column_tooltip";

import {
  EXTRA_LARGE_COLUMN,
  FORMATTED_FIELD_LABEL,
  LARGE_COLUMN,
  TEXT_COLUMN
} from "../../../configurableTables/tables/configurable_tables_constants";
import ShowMoreTooltip from "../../../widgets/tooltips/show_more_tooltip";
import {
  faArrowCircleDown,
  faArrowCircleRight,
  faArrowDownAZ,
  faArrowDownWideShort,
  faArrowUpAZ,
  faArrowUpWideShort,
  faEye
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NO_RECEIVING_SITE, NO_SENDING_SITE } from "../../tech_transfer_constants";
import { getAttributeOperationInfo, getChangeInfo } from "../../tech_transfer_column_value_generator";

import { GridColumn, GridColumnMenuWrapper } from "@progress/kendo-react-grid";
import { getClassName, getFieldName, getProcessName, getValueWithTitle } from "../tech_transfer_table_helper";
import ConfigurableTablesColumnGeneratorBase
  from "../../../configurableTables/tables/columnGenerator/configurable_tables_columns_generator_base";
import { showBullet } from "../../../configurableTables/export/configurable_tables_export_value_generator";
import { downloadFile } from "../../../import/helpers/import_helper";
import {
  FILTER_TYPES
} from "../../../configurableTables/columnOperations/configurable_tables_column_operations_constants";
import { FIELD_PROPS } from "../../../configurableTables/fieldsConfig/constants/configurable_tables_field_props_config";
import {
  TECH_TRANSFER_SECTION
} from "../../../configurableTables/fieldsConfig/constants/configurable_tables_sections_config";
import {
  RECEIVING_SITE_LABEL
} from "../../../configurableTables/fieldsConfig/constants/configurable_tables_columns_config";
import * as CommonEditablesFormatter from "../../../../server/common/editables/common_editables_formatter";
import RiskUtils from "../../../../server/common/misc/common_risk_utils";
import { CommonUtils } from "../../../../server/common/generic/common_utils";

/**
 * This class is used to generate all common columns for the Telerik tables
 * for different model types (PRC, MT, PP, and MA).
 */
export default class TechTransferColumnGenerator extends ConfigurableTablesColumnGeneratorBase {
  constructor(props) {
    super(props);
    this.props = props;

    const {columnOperationsAdapter} = this.props;
    this.getColumnOperationsProps = columnOperationsAdapter.getColumnOperationsProps;
  }

  generateColumn(title, prop, className, isReceiving, filterType = FILTER_TYPES.TEXT, enableColumnOperations = true) {
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  title={title}
                  key={className}
                  headerClassName={`two-lines-header ${className}`}
                  field={isReceiving ? prop : `techTransferredFrom.${prop}`}
                  {...this.getColumnOperationsProps(enableColumnOperations, filterType, this.props, className, true)}
      />
    );
  }

  generateAttributeColumnTitle(attributeType) {
    return (
      <div>
        <div className="two-lines-header techTransferTableHeader">{attributeType}</div>
        <div className="tech-transfer-attribute-column-sub-header">
          <span>Sending </span>
          <FontAwesomeIcon className="tech-transfer-compare-icon m-0"
                           size="sm"
                           icon={faArrowCircleRight}
          />
          <span> Receiving</span>
        </div>
      </div>
    );
  }

  generateNameColumn(title, prop, process, isReceivingSite) {
    const className = getClassName(FIELD_PROPS.NAME, isReceivingSite);
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  title={title}
                  key={className}
                  headerClassName={`two-lines-header ${className}`}
                  headerCell={(props) => this.generateTwoLinesHeader(props, title, process, isReceivingSite)}
                  field={isReceivingSite ? prop : `techTransferredFrom.${prop}`}
                  {...this.getColumnOperationsProps(true, FILTER_TYPES.TEXT, this.props, className, true)}
      />
    );
  }

  generateAttributeColumn(attributeType, onReviewRecord) {
    return (
      <GridColumn width={250}
                  key="attribute_column"
                  headerCell={() => this.generateAttributeColumnTitle(attributeType)}
                  cell={(event) => this.createAttributeCell(onReviewRecord, event)}
                  locked
      />
    );
  }

  createAttributeCell(onReviewRecord, {dataItem, dataIndex, style, className}) {
    const transferredFrom = dataItem.techTransferredFrom;

    if (transferredFrom) {
      const urlTransferredTo = dataItem && dataItem.id ? getURLByTypeCodeAndId(dataItem.typeCode, "View", dataItem.id) : null;
      const urlTransferredFrom = transferredFrom && transferredFrom.id ? getURLByTypeCodeAndId(transferredFrom.typeCode, "View", transferredFrom.id) : null;
      const modelTypeId = UIUtils.convertToCamelCaseId(UIUtils.getModelNameForTypeCode(dataItem.typeCode));

      return (
        <td style={style} className={className}>
          <div className="tech-transfer-variable-review-div">
            <div>
              <div>
                {transferredFrom.id ?
                  <a href={urlTransferredFrom}
                     rel="noopener noreferrer"
                     target="_blank"
                  >
                    {UIUtils.getRecordCustomLabelForDisplay(transferredFrom)}
                  </a> :
                  <span className="tech-transfer-missing-record">
                  {NO_SENDING_SITE}
                </span>
                }
              </div>
              <div>
                <FontAwesomeIcon className="tech-transfer-compare-icon m-0 d-block"
                                 size="sm"
                                 icon={faArrowCircleDown}
                />
              </div>
              <div>
                {dataItem.id ?
                  <a href={urlTransferredTo}
                     rel="noopener noreferrer"
                     target="_blank"
                  >
                    {UIUtils.getRecordCustomLabelForDisplay(dataItem)}
                  </a> :
                  <span className="tech-transfer-missing-record">
                  {NO_RECEIVING_SITE}
                </span>
                }
              </div>
            </div>
            <div>
              {dataItem.id && transferredFrom.id ?
                <a rel="noopener noreferrer"
                   id={`${modelTypeId}_reviewButton_${dataIndex}`}
                   onClick={onReviewRecord.bind(this, dataItem)}
                >
                  <FontAwesomeIcon className="tech-transfer-review-icon ml-1"
                                   size="sm"
                                   icon={faEye}
                  />
                </a> :
                <FontAwesomeIcon className="tech-transfer-disabled-review-icon ml-1"
                                 size="sm"
                                 icon={faEye}
                />
              }
            </div>
          </div>
        </td>);
    }
    return <td />;
  }

  generateLinkedAssetsColumn() {
    const className = getClassName(FIELD_PROPS.LINKED_ASSETS);
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  key={className}
                  title={"Linked Assets"}
                  headerClassName={`two-lines-header ${className}`}
                  cell={(event) => this.createLinkedAssetsCell(event)}
      />
    );
  }

  createLinkedAssetsCell({dataItem}) {
    const transferredTo = dataItem || {};
    const transferredFrom = dataItem.techTransferredFrom || {};

    if (transferredTo) {
      const transferredToParent = transferredTo.source;
      const transferredFromParent = transferredFrom.source;

      const urlTransferredTo = transferredToParent && transferredToParent.id ?
        getURLByTypeCodeAndId(transferredToParent.typeCode, "View", transferredToParent.id) : null;
      const urlTransferredFrom = transferredFromParent && transferredFromParent.id ?
        getURLByTypeCodeAndId(transferredFromParent.typeCode, "View", transferredFromParent.id) : null;

      return (
        <td>
          {transferredFromParent ?
            <div>
              <a href={urlTransferredFrom}
                 rel="noopener noreferrer"
                 target="_blank"
              >
                {`${transferredToParent ? "• " : ""}`}
                {UIUtils.getRecordLabelForDisplay(transferredFromParent.typeCode, transferredFromParent.id, transferredFromParent.name)}
              </a>
            </div> : ""}
          {transferredToParent ? (
            <div>
              <a href={urlTransferredTo}
                 rel="noopener noreferrer"
                 target="_blank"
              >
                {`${transferredFromParent ? "• " : ""}`}
                {UIUtils.getRecordLabelForDisplay(transferredToParent.typeCode, transferredToParent.id, transferredToParent.name)}
              </a>
            </div>
          ) : ""}
        </td>);
    }
    return <td />;
  }

  generateControlColumn(process, isReceivingSite = false) {
    const className = getClassName(FIELD_PROPS.CONTROL);
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  key={className}
                  headerClassName={className}
                  cell={(event) => this.createControlCell(isReceivingSite, event)}
                  headerCell={(props) => this.generateTwoLinesHeader(props, "Control", process, isReceivingSite)}
      />
    );
  }

  createControlCell(isReceivingSite, {dataItem, dataIndex}) {
    const record = isReceivingSite ? dataItem : dataItem.techTransferredFrom || {};
    const controlMethods = record.ControlMethods || [];
    const {controlStrategy} = record;
    const controlMethodOptions = controlMethods.map((controlMethod, idx) => {
      const controlMethodURL = getURLByTypeCodeAndId("CM", "View", controlMethod.id);
      return (
        <div key={idx}>
          <a href={controlMethodURL}
             rel="noopener noreferrer"
             target="_blank"
          >
            {showBullet(controlMethods)}{UIUtils.getRecordLabelForDisplay("CM", controlMethod.id, controlMethod.name)}
          </a>
        </div>
      );
    });

    return (
      <td>
        {getValueWithTitle("Control Strategy",
          controlStrategy ?
            <ShowMoreTooltip text={controlStrategy}
                             id={`showMore_${dataIndex}`}
            /> : "")}
        {controlMethodOptions.length > 1 ?
          getValueWithTitle("Control Methods", controlMethodOptions) : ""}
      </td>);
  }

  generateAcceptanceCriteriaColumn(processName, isReceivingSite) {
    const className = getClassName(FIELD_PROPS.ACCEPTANCE_CRITERIA, isReceivingSite);
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  key={className}
                  title={getProcessName(processName, isReceivingSite, true)}
                  headerClassName={`two-lines-header ${className}`}
                  cell={(event) => this.acceptanceCriteriaCreatedCell(isReceivingSite, event)}
      />
    );
  }

  acceptanceCriteriaCreatedCell(isReceivingSite, {dataItem, dataIndex}) {
    const record = isReceivingSite ? dataItem : dataItem.techTransferredFrom;
    const acceptanceCriteriaInfo = CommonEditablesFormatter.getAttributeMeasurementInfoTechTransferFormat(record);
    const operatingLimitsInfo = getAttributeOperationInfo(record);
    const description = record.description;

    return (
      <td>
        {getValueWithTitle("Acceptance Criteria", acceptanceCriteriaInfo)}
        {getValueWithTitle("Operating Limits", operatingLimitsInfo)}
        {getValueWithTitle("Description",
          description ?
            <ShowMoreTooltip text={description}
                             id={`showMore_${dataIndex}`}
            /> : "")}
      </td>);
  }


  generateObjectListColumn(process, prop, typeCode, title, isReceivingSite = false) {
    const className = getClassName(prop, isReceivingSite);
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  key={className}
                  title={title}
                  headerClassName={`two-lines-header ${className}`}
                  headerCell={(props) => this.generateTwoLinesHeader(props, UIUtils.convertCamelCaseToSpacedOutWords(prop), process, isReceivingSite)}
                  cell={(event) => this.createObjectListCell(isReceivingSite, event, `${prop}${FORMATTED_FIELD_LABEL}`, typeCode)}
      />
    );
  }

  createObjectListCell(isReceivingSite, {dataItem}, prop, typeCode) {
    const record = (isReceivingSite ? dataItem : dataItem.techTransferredFrom) || {};
    const objectsList = record[prop] || [];
    const objectsListOptions = objectsList.map((record, idx) => {
      const objectURL = getURLByTypeCodeAndId(typeCode, "View", record.id);
      return (
        <div key={idx}>
          <a href={objectURL}
             rel="noopener noreferrer"
             target="_blank"
          >
            {showBullet(objectsList)}{UIUtils.getRecordLabelForDisplay(typeCode, record.id, record.name)}
          </a>
        </div>
      );
    });
    return (<td>{objectsListOptions}</td>);
  }

  generateTextColumn(title, prop, process, isReceivingSite = false, discardProcessInHeader = false, enableColumnOperations = true) {
    const className = getClassName(prop, isReceivingSite);
    const propName = getFieldName(prop, isReceivingSite);
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  key={className}
                  field={propName}
                  headerClassName={`two-lines-header ${className}`}
                  headerCell={(props) => this.generateTwoLinesHeader(props, title, process, isReceivingSite, discardProcessInHeader)}
                  cell={(event) => this.textCreatedCell(event, prop, isReceivingSite)}
                  {...this.getColumnOperationsProps(enableColumnOperations, FILTER_TYPES.TEXT, null, propName)}
      />
    );
  }

  textCreatedCell({dataItem, dataIndex}, prop, isReceivingSite) {
    const record = (isReceivingSite ? dataItem : dataItem.techTransferredFrom) || {};
    const text = record[prop];
    return (
      <td>
        {text ?
          <ShowMoreTooltip text={text}
                           id={`showMore_${dataIndex}`}
          /> : ""}
      </td>);
  }

  generateRiskLinksColumn(process, isReceivingSite = false) {
    const className = getClassName(FIELD_PROPS.RISK_LINKS, isReceivingSite);
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  key={className}
                  headerCell={(props) => this.generateTwoLinesHeader(props, "Risk Links", process, isReceivingSite)}
                  headerClassName={`two-lines-header ${className}`}
                  cell={(event) => this.riskLinksCreatedCell(isReceivingSite, event)}
      />
    );
  }

  riskLinksCreatedCell(isReceivingSite, {dataItem}) {
    const record = (isReceivingSite ? dataItem : dataItem.techTransferredFrom) || {};
    const riskLinks = record.riskLinks || [];
    const riskLinkOptions = riskLinks.map((link, idx) => {
      const riskLink = link.riskLink;
      if (riskLink && riskLink.id) {
        const riskLinkURL = getURLByTypeCodeAndId(riskLink.typeCode, "View", riskLink.id);
        return (
          <div key={idx}>
            <a href={riskLinkURL}
               rel="noopener noreferrer"
               target="_blank"
            >
              {showBullet(riskLinks)}{UIUtils.getRecordLabelForDisplay(riskLink.typeCode, riskLink.id, riskLink.name)}
            </a>
          </div>
        );
      }
    });
    return <td>{riskLinkOptions}</td>;
  }

  generateParentColumn(title, prop, process, isReceivingSite = false) {
    const className = getClassName(prop, isReceivingSite);

    return (
      <GridColumn width={TEXT_COLUMN}
                  key={className}
                  title={title}
                  field={getFieldName(prop, isReceivingSite)}
                  headerCell={(props) => this.generateTwoLinesHeader(props, title, process, isReceivingSite, false, true)}
                  headerClassName={`two-lines-header ${className}`}
                  cell={(event) => this.createParentCell(isReceivingSite ? event.dataItem : event.dataItem.techTransferredFrom, prop)}
                  {...this.getColumnOperationsProps(true, FILTER_TYPES.LOOKUP, this.props, className)}
      />
    );
  }

  generateDocumentsColumn(title, process, linksProp, isReceivingSite = false, discardProcessInHeader = false) {
    const className = getClassName(FIELD_PROPS.DOCUMENT_REFERENCE, isReceivingSite);
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  key={className}
                  headerCell={(props) => this.generateTwoLinesHeader(props, title, process, isReceivingSite, discardProcessInHeader)}
                  headerClassName={`two-lines-header ${className}`}
                  cell={(event) => this.documentCreatedCell(isReceivingSite, linksProp, event)}
      />
    );
  }

  documentCreatedCell(isReceivingSite, linksProp, {dataItem}) {
    const record = isReceivingSite ? dataItem : dataItem.techTransferredFrom || {};
    if (record) {
      const value = record[linksProp];
      let documentReferences = value ? Array.isArray(value) ? value : JSON.parse(record[linksProp]) : "None";
      if (Array.isArray(documentReferences) && documentReferences.length === 0) {
        documentReferences = "None";
      }

      const bullet = showBullet(documentReferences);
      const documentReferenceOptions =
        typeof documentReferences === "string" ? documentReferences :
          documentReferences.map((reference, idx) => {
            return (
              <div key={idx}>
                {reference.linkType === "Link" ?
                  <a href={UIUtils.cleanUpURL(reference.link)}
                     rel="noopener noreferrer"
                     target="_blank"
                  >
                    {bullet}{reference.name || reference.fileName}
                  </a> :
                  <a id="downloadImportFile"
                     onClick={downloadFile.bind(this, reference)}
                  >
                    {bullet}{reference.name || reference.fileName}
                  </a>
                }
              </div>
            );
          });

      return <td>{documentReferenceOptions}</td>;
    }
    return <td />;
  }

  generateChangeColumn(onViewDetailedComparison, enableColumnOperations = true) {
    const className = getClassName(FIELD_PROPS.CHANGE, true);
    return (
      <GridColumn width={LARGE_COLUMN}
                  key={className}
                  title={"Change"}
                  field={"change"}
                  headerClassName={`two-lines-header ${className}`}
                  cell={(event) => this.changeCreatedCell(onViewDetailedComparison, event)}
                  {...this.getColumnOperationsProps(enableColumnOperations, FILTER_TYPES.COMBO_BOX, this.props, className)}
      />
    );
  }

  changeCreatedCell(onViewDetailedComparison, {dataItem}) {
    const changeInfo = getChangeInfo(dataItem);

    if (changeInfo) {
      const {diff, areChangesIgnored, recordChanged, isSiteMissing} = changeInfo;
      const YELLOW_COLOR = "#fff9e6";

      dataItem.totalChangesCount = diff.totalChangesCount;
      dataItem.totalDiffsCount = diff.totalDiffsCount;

      return (recordChanged ?
        <td ref={
          (el) => {
            if (el) {
              el.style.setProperty("background-color", YELLOW_COLOR, "important");
            }
          }}
        >
          <div>{areChangesIgnored ? "No" : "Yes"}</div>
          {isSiteMissing ? "" : <TechTransferChangeColumnTooltip
            diff={diff}
            reviewRecord={dataItem}
            onViewDetailedComparison={onViewDetailedComparison}
          />}
          {this.generateChangeSummary(dataItem)}
        </td> :
        <td>
          <div>No</div>
          {this.generateChangeSummary(dataItem)}
        </td>);
    } else {
      return <td>No</td>;
    }
  }

  generateRiskColumn(title, prop, riskType, className, process, isReceivingSite = false, enableColumnOperations = true) {
    const isTechTransferRiskProp = prop.startsWith("techTransfer");
    const field = isReceivingSite ? prop : `techTransferredFrom.${prop}`;
    const titleProp = isTechTransferRiskProp ?
      {title} : {
        headerCell: (props) =>
          this.generateTwoLinesHeader(props, title, process, isReceivingSite, isTechTransferRiskProp),
      };
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  key={className}
                  field={field}
                  headerClassName={`two-lines-header ${className}`}
                  cell={(event) => this.riskCreatedCell(isTechTransferRiskProp, isReceivingSite, riskType, prop, event)}
                  {...titleProp}
                  {...this.getColumnOperationsProps(enableColumnOperations, FILTER_TYPES.COMBO_BOX, this.props, field)}
      />
    );
  }

  riskCreatedCell(isTechTransferRiskProp, isReceivingSite, riskType, prop, {dataItem}) {
    const record = isReceivingSite ? dataItem : dataItem.techTransferredFrom || {};
    if (record) {
      const rmp = isTechTransferRiskProp ?
        record.effectiveTechTransferRMP : record.effectiveModelRMP;
      let riskValue = RiskUtils.parseRiskValue(parseFloat(record[prop]), riskType, rmp);

      if (rmp && !isNaN(riskValue) && riskValue) {
        let rawRiskValue = RiskUtils.parseRiskValue(parseFloat(record[this.adaptRiskFilterName(prop)]), riskType, rmp);

        const riskColorClass = getTableCellClassFromRiskScore(riskType, rmp, rawRiskValue, record);
        const riskScale = getRiskScale(riskType, rmp, rawRiskValue, record);

        return (
          <td>
            <div className={`configurable-tables-risk-color-column ${riskColorClass}`} />
            <span>{`${riskValue}${prop.includes("Perc") ? "%" : ""}` || ""}</span>
            <div className="configurable-tables-risk-color-column-label">
              {riskScale ? (riskScale.riskLabel || riskScale.scoreLabel) : ""}
            </div>
          </td>
        );
      }
    }
    return <td>{RiskUtils.NOT_ASSESSED_LABEL}</td>;
  }

  generateOutcomeColumn(enableColumnOperations = true) {
    return (
      <GridColumn width={LARGE_COLUMN}
                  key="outcome_column"
                  title={"Outcome"}
                  field={FIELD_PROPS.OUTCOME}
                  headerClassName={`two-lines-header ${getClassName(FIELD_PROPS.OUTCOME, true)}`}
                  cell={(event) => this.outcomeCreatedCell(event)}
                  {...this.getColumnOperationsProps(enableColumnOperations, FILTER_TYPES.COMBO_BOX, this.props, FIELD_PROPS.OUTCOME)}
      />
    );
  }

  outcomeCreatedCell({dataItem}) {
    const transferredTo = dataItem;

    if (transferredTo) {
      const {effectiveTechTransferRMP} = dataItem;
      if (effectiveTechTransferRMP) {
        const riskValue = transferredTo.techTransferImpact * transferredTo.techTransferUncertainty * transferredTo.techTransferDetectability;
        const riskColorClass = getTableCellClassFromRiskScore(RISK_TYPE_ENUM.RPN, effectiveTechTransferRMP, riskValue, transferredTo, true);
        const riskScale = getRiskScale(RISK_TYPE_ENUM.RPN, effectiveTechTransferRMP, riskValue, transferredTo, true);

        return (
          <td>
            <div className={`configurable-tables-risk-color-column ${riskColorClass}`} />
            <span>{riskValue || ""}</span>
            <div className="configurable-tables-risk-color-column-label">
              {riskScale ? riskScale.riskLabel : ""}
            </div>
          </td>
        );
      }
    }
    return <td />;
  }

  generateChangeSummary() {
    return "";
  }

  generateTwoLinesHeader(props, title, process, isReceivingSite, discardProcessInHeader = false, isSortingByOrder = false) {
    const name = getProcessName(process, isReceivingSite, true);
    discardProcessInHeader = typeof discardProcessInHeader === "object" ? false : discardProcessInHeader;
    const sortingProps = props?.onClick ? {
      onClick: props.onClick,
      className: "two-lines-header k-cursor-pointer",
    } : {className: "two-lines-header"};

    let icon = false;
    const sortIcon = props?.children ? props.children[0] : null;
    let classNameForSorting = "";
    if (sortIcon) {
      icon =
        <FontAwesomeIcon title={isSortingByOrder ? "Sorted by Order (First to Last)" : "Sorted Ascending (A-Z)"}
                         icon={
                           sortIcon.props.className === "k-icon k-i-sort-asc-small" ?
                             isSortingByOrder ? faArrowDownWideShort : faArrowDownAZ :
                             isSortingByOrder ? faArrowUpWideShort : faArrowUpAZ
                         }
                         className={`sort-icon ${sortIcon.props.className}`}
        />;

      if (sortIcon.props.className === "k-icon k-i-sort-asc-small") {
        classNameForSorting = "sort-asc";
      } else {
        classNameForSorting = "sort-desc";
      }
    }

    return (
      <Fragment>
        <span>
          {props?.columnMenuWrapperProps?.columnMenu && (
            <GridColumnMenuWrapper {...props.columnMenuWrapperProps} />
          )}
        </span>
        <div {...sortingProps}>
          <div className={classNameForSorting}>{title}</div>
          {discardProcessInHeader ? "" : <span>{name}</span>}
          {icon}
        </div>
      </Fragment>
    );
  }

  generateColumns(fields, enableColumnOperations = true) {
    const {fromProcess, toProcess} = this.props;
    const generatedColumns = [];

    for (const field of fields) {
      let generatedColumn;
      const {prop, siteForValueEvaluation, tableValue, filterType} = field;
      const isReceivingSite = siteForValueEvaluation === RECEIVING_SITE_LABEL;
      const process = isReceivingSite ? toProcess : fromProcess;

      generatedColumn = tableValue ? tableValue(this, process, enableColumnOperations) :
        this.generateColumn(
          this.generateTwoLinesHeader(null, field.title, process, isReceivingSite, field.section === TECH_TRANSFER_SECTION),
          prop, getClassName(prop, isReceivingSite),
          isReceivingSite, filterType, enableColumnOperations);

      generatedColumns.push({
        ...generatedColumn,
        props: {
          ...generatedColumn.props,
          colspan: field.colspan,
        }
      });
    }

    return this.generateColSpans(generatedColumns);
  }

  generateRecordRelatedColumns() {
    const {visibleTableColumns} = this.props;
    const visibleColumns = visibleTableColumns.filter(column => column.checked);
    return this.generateColumns(visibleColumns);
  }

  generateTechTransferCommonColumns(enableColumnOperations) {
    const {fieldsConfig} = this.props;
    const {fields} = fieldsConfig;
    const techTransferFields = fields.filter(field => field.section === TECH_TRANSFER_SECTION);
    return this.generateColumns(techTransferFields, enableColumnOperations);
  }


  // eslint-disable-next-line no-unused-vars
  generateMultiSelectionColumn(title, prop, isFormatted = false, isReceivingSite = false) {
    const {fromProcess, toProcess} = this.props;
    const process = isReceivingSite ? toProcess : fromProcess;
    const headerTitle = this.generateTwoLinesHeader(null, title, process, isReceivingSite, false);
    return this.generateColumn(headerTitle, prop, getClassName(prop, isReceivingSite), isReceivingSite);
  }

  generateBooleanColumn(title, prop, isReceivingSite) {
    const {fromProcess, toProcess} = this.props;
    const process = isReceivingSite ? toProcess : fromProcess;
    const headerTitle = this.generateTwoLinesHeader(null, title, process, isReceivingSite, false);
    return this.generateColumn(headerTitle, prop, getClassName(prop, isReceivingSite), isReceivingSite);
  }


  generateTextAreaColumn(title, prop, process, isReceivingSite = false, enableColumnOperations = true) {
    return this.generateTextColumn(title, prop, process, isReceivingSite, false, enableColumnOperations);
  }

  createParentCell(dataItem, prop) {
    let parents = dataItem[`sorted${CommonUtils.capitalize(CommonUtils.pluralize(prop))}`] || [];

    const parentsList = parents.map((parent, idx) => {
      const parentUrl = getURLByTypeCodeAndId(parent.typeCode, "View", parent.id);
      return (
        <div key={idx}>
          <a href={parentUrl}
             rel="noopener noreferrer"
             target="_blank"
          >
            {showBullet(parents)}{UIUtils.getRecordCustomLabelForDisplay(parent)}
          </a>
        </div>
      );
    });

    return <td>{parentsList}</td>;
  }

}
