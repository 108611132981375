"use strict";

import React from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import { getURLByKey } from "../helpers/url_helper";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import * as UIUtils from "../ui_utils";
import { REPORT_OPTIONS_ENUM, REPORT_TYPES_ENUM } from "../reports/constants/report_constants";
import ReportURLGenerator from "../reports/report_url_builder";
import BaseDiffableTable from "../widgets/tables/base_diffable_table";
import { createHTMLForWholeTextDiff } from "../helpers/diff_helper";
import GroupableTablePlugin from "../widgets/tables/tablePlugins/groupable_table_plugin";
import { acceptanceCriteriaToStringForBatches } from "../../server/common/editables/common_editables_formatter";
import { isQualitativeMeasure } from "../../server/common/editables/common_editables";
import { CommonSorting } from "../../server/common/generic/common_sorting";
import { ModelFormatter } from "../../server/common/generic/common_model_formatter";
import { MODEL_DECLARATIONS } from "../../server/common/generic/common_models";
import { BATCH_RECORD_TYPE_ENUM } from "./batch_constants";

const GROUP_NAME_COLUMN_INDEX = 1;
const GROUP_NAME_COLUMN_SPAN = 8;
const GROUP_BY_RECORD_PROPERTIES = {
  measurementInfo: "measurementInfo",
  typeCode: "typeCode",
  unitOperationId: "unitOperationId",
};

// i18next-extract-mark-ns-start batches
/**
 * This is responsible for rendering the batch attribute records for a given batch or batch version.
 */
class BatchAttributesTable extends BaseDiffableTable {
  constructor(props) {
    super(props);

    this.groupableTablePlugin = new GroupableTablePlugin("typeCode", "unitOperationId", GROUP_NAME_COLUMN_INDEX, GROUP_NAME_COLUMN_SPAN);
  }

  componentDidMount() {
    super.componentDidMount();
    this.groupableTablePlugin.registerTable(this.tableRef);
  }

  shouldComponentUpdate(nextProps) {
    const {records, diffedVersion, searchTerm, orderedUOList, isLoading, type} = this.props;
    const nextDiffedVersion = nextProps.diffedVersion;
    const nextSearchTerm = nextProps.searchTerm;
    const nextOrderedUOList = nextProps.orderedUOList;
    const recordsHash = (records || []).map(rec => rec.attributeKey).join("-");
    const nextRecordsHash = (nextProps.records || []).map(rec => rec.attributeKey).join("-");

    return (!records && nextProps.records)
      || nextProps.isLoading !== isLoading
      || nextProps.type !== type
      || diffedVersion !== nextDiffedVersion
      || searchTerm !== nextSearchTerm
      || (records && nextProps.records && records.length !== nextProps.records.length)
      || (!orderedUOList && nextOrderedUOList)
      || (orderedUOList || []).length !== (nextOrderedUOList || []).length
      || recordsHash !== nextRecordsHash;
  }

  getTableInitializationOptions() {
    return {
      dom: "<t>",
      data: this.prepareRecordsForDisplay(this.props.records),
      columns: this.getColumns(),
      paging: false,
      ordering: false,
      stateSave: false,
      headerCallback: this.renderAttributesColumnHeader,
      rowCallback: this.groupableTablePlugin.renderRow,
      autoWidth: false,
    };
  }

  getColumns() {
    const {t} = this.props;

    return [
      this.groupableTablePlugin.generateExpandIndicatorColumn(),
      this.generateAttributeColumn(),
      this.generateColumn(t("Count"), "totalMeasurements", "totalMeasurementsText", "dt-body-right"),
      this.generateColumn(t("Min"), "min", "minText", "dt-body-right"),
      this.generateColumn(t("Max"), "max", "maxText", "dt-body-right"),
      this.generateColumn(t("Avg"), "average", "averageText", "dt-body-right"),
      this.generateColumn(t("Sd"), "sd", "sdText", "dt-body-right"),
      this.generateColumn(t("Def %"), "defectivePercentage", "defectivePercentageText", "dt-body-right"),
      this.generateChartsLinkColumn(),
    ];
  }

  generateChartsLinkColumn() {
    return {
      width: 0,
      orderable: false,
      data: result => UIUtils.secureString(this.props.isDiffingVersions ? result.attributeKeyText : result.attributeKey),
      createdCell: (td, cellData, rowData, rowIndex) => {
        ReactDOM.render(
          (
            rowData.isGroupRow ? "" : (
              <FontAwesomeIcon id={"processCapabilityContinuesReportLink_" + rowIndex}
                               icon={faChartLine}
                               className="pc-link-icon"
                               onClick={this.handleProcessCapabilityReportLinkClick.bind(this, cellData, rowData)}
                               aria-label="Click to view the process capability continues report for the attribute."
              />)
          ), td);
      }
    };
  }

  generateAttributeColumn() {
    const {t} = this.props;

    return {
      title: t("Attributes"),
      width: 300,
      containsHTML: true,
      data: (result) => {
        return UIUtils.secureString(this.props.isDiffingVersions ? result.fullNameText : result.fullName);
      },
      createdCell: this.createAttributeCell,
    };
  }

  createAttributeCell(td, cellData, rowData) {
    if ((rowData.isGroupRow && !rowData.isLinkHeader) || this.props.isDiffingVersions) {
      ReactDOM.render(
        <div>
          <div>
            {rowData.fullName}
          </div>
        </div>, td);
    } else {
      let titleElement = (<span>{rowData.fullName}</span>);
      if (rowData.typeCode !== MODEL_DECLARATIONS.SPECIFICATION.typeCode) {
        const url = getURLByKey(rowData.fullName, "View");
        titleElement = (<a href={url}
                           rel="noopener noreferrer"
                           target="_blank"
        >
          {rowData.fullName}
        </a>);
      }

      ReactDOM.render(
        <div>
          <div>
            { titleElement }
          </div>
          {rowData.isGroupRow ? "" : (
            <div>
              <span className="batch-attributes-sub-text">{rowData.measurementInfo}</span>
            </div>
          )}
        </div>, td);
    }
  }

  renderAttributesColumnHeader(thead) {
    const {t, type} = this.props;

    $(thead).find("th").eq(1).html(
      ReactDOMServer.renderToStaticMarkup(
        <div>
          <div>
            <span>{t(type === BATCH_RECORD_TYPE_ENUM.LIBRARY_COA ? "Specifications" : "Attributes")}</span>
          </div>
          {this.props.isDiffingVersions ? "" : (
            <div>
              <span className="batch-attributes-sub-header">{t("Measure Type, Unit, LSL-USL")}</span>
            </div>
          )}
        </div>
      )
    );
  }

  handleProcessCapabilityReportLinkClick(attributeID, rowData) {
    let key = UIUtils.parseKey(attributeID);
    let reportOptions, reportType;

    const isLibrary = this.props.type === BATCH_RECORD_TYPE_ENUM.LIBRARY_COA;
    if (this.props.linkToDashboard) {
      reportOptions = isLibrary ? REPORT_OPTIONS_ENUM.LibraryProcessCapabilityDashboard : REPORT_OPTIONS_ENUM.ProcessCapabilityDashboard;
      reportType = isLibrary ? REPORT_TYPES_ENUM.LibraryProcessCapabilityDashboard : REPORT_TYPES_ENUM.ProcessCapabilityDashboard;
    } else if (isQualitativeMeasure(rowData.measure)) {
      reportOptions = isLibrary ? REPORT_OPTIONS_ENUM.LibraryControlChartsDefectivesReport : REPORT_OPTIONS_ENUM.ControlChartsDefectivesReport;
      reportType = isLibrary ? REPORT_TYPES_ENUM.LibraryControlChartsDefectivesReport : REPORT_TYPES_ENUM.ControlChartsDefectivesReport;
    } else {
      reportOptions = isLibrary ? REPORT_OPTIONS_ENUM.LibraryControlChartsContinuousReport : REPORT_OPTIONS_ENUM.ControlChartsContinuousReport;
      reportType = isLibrary ? REPORT_TYPES_ENUM.LibraryControlChartsContinuousReport : REPORT_TYPES_ENUM.ControlChartsContinuousReport;
    }

    const {processId, projectId} = this.props;
    let urlParams = {
      reportType: reportType,
      projectId,
      processId,
      modelLabel: `${key.typeCode}-${key.id}`,
    };

    window.open(ReportURLGenerator.generateURL(reportOptions, urlParams));
  }

  expandCollapseAllRows(expandAllRows) {
    this.groupableTablePlugin.expandCollapseAllRows(expandAllRows);
  }

  /**
   * Prepares the batch attributes for display depending on whether the user is looking at the version diff view or
   * just the record display view.
   * @param batchRecords
   * @returns {*|*[]}
   */
  prepareRecordsForDisplay(batchRecords = []) {
    let {previousVersionRecords} = this.props;

    const updateRecordInfo = record => {
      const key = UIUtils.parseKey(record.attributeKey);
      record.measurementInfo = this.getAttributeMeasurementInfo(record);
      record.typeCode = key.typeCode;
      record.id = key.id;
      return record;
    };

    let records = [];
    if (!this.isLoading()) {
      records = batchRecords.map(updateRecordInfo);
      records = this.applyBatchRecordsFilter(records);
      this.groupBatchRecords(records);

      if (this.props.isDiffingVersions) {
        let diffedRows = [];
        previousVersionRecords = previousVersionRecords.map(updateRecordInfo);
        previousVersionRecords = this.applyBatchRecordsFilter(previousVersionRecords);
        this.groupBatchRecords(previousVersionRecords);

        // This creates diffed rows for rows which have been added or modified in the current version
        for (let record of records) {
          let diffedAttribute = {};
          let oldRecord;

          if (record.isGroupRow) {
            // If this is a group row try find the respective group row from the previous version, if any.
            oldRecord = this.groupableTablePlugin.findGroupRow(record, previousVersionRecords);
            const oldGroupTitle = oldRecord && oldRecord.fullName;
            const newGroupTitle = record.fullName;

            diffedAttribute = {
              ...record,
              fullName: createHTMLForWholeTextDiff(oldGroupTitle, newGroupTitle),
              fullNameText: newGroupTitle,
            };
          } else {
            // If this is not a group row, then just find the previous version record, if any.
            oldRecord = previousVersionRecords.find(oldRecord => oldRecord.attributeKey === record.attributeKey);
            diffedAttribute = {...record};

            /* The code below creates a diff for all the properties of each record, besides ones that re not used for
               display purposes.
             */
            for (let prop in record) {
              if (Object.prototype.hasOwnProperty.call(record, prop) && !GROUP_BY_RECORD_PROPERTIES[prop]) {
                const oldAttributeProp = UIUtils.isNumber(oldRecord && oldRecord[prop])
                  ? oldRecord[prop].toString()
                  : oldRecord && oldRecord[prop];
                const newAttributeProp = UIUtils.isNumber(record && record[prop])
                  ? record[prop].toString()
                  : record && record[prop];

                diffedAttribute[prop] = createHTMLForWholeTextDiff(oldAttributeProp, newAttributeProp);
                diffedAttribute[`${prop}Text`] = newAttributeProp;
              }
            }
          }

          diffedRows.push(diffedAttribute);
        }

        // This creates diffed rows for rows which have been deleted in the current version
        for (let oldRecord of previousVersionRecords) {
          let diffedAttribute = {};

          if (oldRecord.isGroupRow) {
            const newRecord = this.groupableTablePlugin.findGroupRow(oldRecord, records);

            if (!newRecord) {
              const oldGroupTitle = oldRecord && oldRecord.fullName;
              const newGroupTitle = "";

              diffedAttribute = {
                ...oldRecord,
                fullName: createHTMLForWholeTextDiff(oldGroupTitle, newGroupTitle),
                fullNameText: oldGroupTitle,
              };

              if (diffedAttribute.isCollapsibleGroup) {
                diffedRows.push(diffedAttribute);
              } else {
                const rowIndexToInsertDiff = diffedRows.findIndex(row => row.isCollapsibleGroup
                  && row.groupRowId === diffedAttribute.unitOperationId);
                diffedRows.splice(rowIndexToInsertDiff + 1, 0, diffedAttribute);
              }
            }
          } else {
            const newAttribute = records.find(attribute => attribute.attributeKey === oldRecord.attributeKey);
            if (!newAttribute) {
              diffedAttribute = {...oldRecord};

              for (let prop in oldRecord) {
                if (Object.prototype.hasOwnProperty.call(oldRecord, prop) && !GROUP_BY_RECORD_PROPERTIES[prop]) {
                  diffedAttribute[prop] = createHTMLForWholeTextDiff(oldRecord[prop], newAttribute && newAttribute[prop]);
                  diffedAttribute[`${prop}Text`] = oldRecord[prop];
                }
              }

              const rowIndexToInsertDiff = diffedRows.findIndex(row => row.isGroupRow
                && row.groupTypeCode === diffedAttribute.typeCode
                && (!diffedAttribute.unitOperationId || row.unitOperationId === diffedAttribute.unitOperationId));
              diffedRows.splice(rowIndexToInsertDiff + 1, 0, diffedAttribute);
            }
          }
        }

        /* Returns the diffed rows sorted by the attribute key so that they always show up in the same order when
           browsing the version diff.
         */
        return diffedRows;
      }
    }

    return records;
  }

  applyBatchRecordsFilter(records) {
    const {searchTerm} = this.props;
    return (records || []).filter(attribute => {
      return searchTerm ? attribute.fullName.match(searchTerm) : attribute;
    });
  }

  /**
   * This groups the batch attributes based on UO and type code. The process level attributes, like FQAs and FPAs are
   * grouped on the top and the UO groups are created based on the order the UOs are defined in the process explorer.
   * @param records
   */
  groupBatchRecords(records) {
    const {orderedUOList} = this.props;
    if (orderedUOList) {
      records = records.sort(CommonSorting.sortAttributesByParentAndTypeCodeAndSomeField(orderedUOList));
    }

    for (let i = 0; i < records.length; i++) {
      const attribute = records[i];
      let previousAttribute = records[i - 1];
      const {typeCode, unitOperationId, isGroupRow} = attribute;

      if (isGroupRow || (previousAttribute && previousAttribute.isGroupRow)) {
        continue;
      }

      let groupRowInserted = false;
      if (!unitOperationId && (!previousAttribute || previousAttribute.typeCode !== typeCode)) {
        const totalRecordsInGroup = records.filter(attribute => !attribute.isGroupRow && attribute.typeCode === typeCode).length;
        records.splice(i, 0,
          this.groupableTablePlugin.createGroupRow(true, false, typeCode, null,
            this.getGroupTitle(attribute, false, totalRecordsInGroup)));
        previousAttribute = records[i];
        groupRowInserted = true;
      } else if (unitOperationId && (!previousAttribute || previousAttribute.unitOperationId !== unitOperationId)) {
        const totalRecordsInGroup = records.filter(attribute => !attribute.isGroupRow && attribute.unitOperationId === unitOperationId).length;
        records.splice(i, 0,
          this.groupableTablePlugin.createGroupRow(true, true, null, unitOperationId,
            this.getGroupTitle(attribute, true, totalRecordsInGroup)));
        previousAttribute = records[i];
        groupRowInserted = true;
      }

      if (unitOperationId && (!previousAttribute || previousAttribute.typeCode !== typeCode)) {
        const totalRecordsInGroup = records.filter(attribute => !attribute.isGroupRow
          && attribute.typeCode === typeCode
          && attribute.unitOperationId === unitOperationId).length;
        records.splice(groupRowInserted ? i + 1 : i, 0,
          this.groupableTablePlugin.createGroupRow(false, false, typeCode, unitOperationId,
            this.getGroupTitle(attribute, false, totalRecordsInGroup)));
      }
    }
  }

  /**
   * This gets the title for a group header row.
   * @param attribute Any of the attributes belonging to the group.
   * @param isLinkHeader True if this is a UO group header.
   * @param totalRecordsInGroup The total number of attributes in the group.
   * @returns {string}
   */
  getGroupTitle(attribute, isLinkHeader, totalRecordsInGroup) {
    const {typeCode} = attribute;

    if (!isLinkHeader) {
      let attributeTypeHeader = ModelFormatter.instance().getModelFullNameFromTypeCode(typeCode);
      return `${attributeTypeHeader}s (${totalRecordsInGroup})`;
    } else {
      return `${attribute.unitOperation} (${totalRecordsInGroup})`;
    }
  }

  getAttributeMeasurementInfo(attribute) {
    let measurementInfo = "";
    let acceptanceCriteria = acceptanceCriteriaToStringForBatches(attribute);

    if (attribute.measure) {
      measurementInfo += attribute.measure;
    }
    if (attribute.measurementUnits) {
      measurementInfo += (measurementInfo !== "" ? `, ` : "") + attribute.measurementUnits;
    }

    if (acceptanceCriteria) {
      measurementInfo += (measurementInfo !== "" ? `, ` : "") + acceptanceCriteria;
    }

    return measurementInfo;
  }

  getAdditiveSkeletonClass() {
    return "skeleton-table skeleton-table-batches";
  }

  render() {
    return (
      <div id={`${this.props.id}Container`}
           className="col-12 group-by-table-container"
      >
        <table id={this.props.id}
               ref={ref => this.tableRef = ref}
               className={"table" + this.getClassForLoading()}
               style={{width: "100%"}}
        />
      </div>
    );
  }

  shouldForceRedraw() {
    return true;
  }
}

export default I18NWrapper.wrap(BatchAttributesTable, ["batches", "base_page"]);

// i18next-extract-mark-ns-stop batches
