"use strict";

import { PROCESS_BAR_PANELS, PROCESS_LEVEL_MODEL_TYPES } from "../../../process_explorer_constants";
import * as UIUtils from "../../../../ui_utils";
import React, { useEffect, useState } from "react";
import { getURLByTypeCodeAndId } from "../../../../helpers/url_helper";
import { ProcessDropdown } from "../../process_dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faSlash } from "@fortawesome/free-solid-svg-icons";
import * as styles from "./styles/title_area.module.scss";
import { CustomDropdownButton } from "../../../../configurableTables/widgets/dropDown/dropdown_button";

const modelTypeOptions = PROCESS_LEVEL_MODEL_TYPES.map(type => ({
  disabled: false,
  key: UIUtils.stripAllWhitespaces(type),
  title: type,
  value: type
}));

const onAddProcess = (e, projectId) => {
  e.preventDefault();
  window.location.href = UIUtils.getSecuredURL(getURLByTypeCodeAndId("PR", "Add", projectId));
};

export const TitleArea = (
  {
    projectId,
    processId,
    processes,
    isLoading,
    selectedModelType,
    selectedPanel,
    onModelTypeChange,
    onUnitOperationChange
  }) => {

  const [modelType, setModelType] = useState(selectedModelType || "Process Parameters");

  const onSelectedModelTypeChange = ({target}) => {
    const newSelectedModelTypeValue = target.textContent;
    onModelTypeChange(newSelectedModelTypeValue);
    setModelType(newSelectedModelTypeValue);
    if (newSelectedModelTypeValue === "Unit Operations") {
      onUnitOperationChange("All");
    }
  };

  useEffect(() => {
    onModelTypeChange(modelType);
    onUnitOperationChange("All");
  }, []);

  const onProcessChange = (processId, event) => {
    UIUtils.ignoreHandler(event);
    let showArchived = UIUtils.getParameterByName("showArchived");
    UIUtils.setLoadingDisabled(false);
    UIUtils.showLoadingImage();
    window.location.href = UIUtils.getSecuredURL(UIUtils.FRONT_END_URL
      + "/processExplorer/processExplorer.html?projectId=" + projectId
      + "&processId=" + processId
      + "&selectedPanel=" + selectedPanel
      + `&showArchived=${showArchived || false}`);
  };

  return (
    <div className={styles["container"]}>
      <ProcessDropdown projectId={projectId}
                       processId={processId}
                       processes={processes}
                       onProcessChange={onProcessChange}
                       onAddProcess={e => onAddProcess(e, projectId)}
                       isLoading={isLoading}
      />
      {selectedPanel === PROCESS_BAR_PANELS.TABLE && (
        <>
          <FontAwesomeIcon className={styles["slash-icon"]} icon={faSlash} rotation={90} />
          <CustomDropdownButton
            id="processModelTypeSelect"
            options={modelTypeOptions}
            label={modelType}
            className={styles["drop-down-container"]}
            onOptionsSelect={onSelectedModelTypeChange}
            size="small"
            title="Record Type Selection"
            type="secondary"
            icon={faChevronDown}
          />
        </>
      )}
    </div>
  );
};
