import React, { Fragment } from "react";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { LIBRARY_MATERIAL_TYPE_CODE } from "../library_constants";
import CommonEditablesFormatter from "../../../server/common/editables/common_editables_formatter";

export const RecordSpecificationStatusBar = ({record}) => {
  if (record.modelName !== "MaterialAttribute" ||
    !record.linkedToSpecificationVersionId ||
    !record.fromLibrary ||
    !record.fromLibrary.specification) {
    return <Fragment />;
  }

  const systemMaterial = record.fromLibrary;
  const specification = systemMaterial.specification;

  return (
    <div className="row">
      <div className="col-12 record-specification-from-library" id="specificationDetail">
        <p className="info">Specification <strong id="specificationDetailName">{specification.name}</strong> from Library <a
            rel="noreferrer noopener"
            className="font-weight-bold"
            target="_blank"
            id="specificationDetailLibrary"
            href={getURLByTypeCodeAndId(LIBRARY_MATERIAL_TYPE_CODE, "View", systemMaterial.LibraryMaterialId, true)}>
          {systemMaterial.name} v{systemMaterial.majorVersion}.{systemMaterial.minorVersion}</a>.
        </p>
        <p className="specification" id="specificationDetailDescription">{CommonEditablesFormatter.getAttributeMeasurementInfoTechTransferFormat(specification)}</p>
      </div>
    </div>
  );
};