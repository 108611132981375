"use strict";

import * as UIUtils from "../../../ui_utils";
import { EMPTY_STRING, NEW_LINE } from "../../../helpers/constants/constants";
import { getJustification } from "./risk_tables_helper";
import { TYPE_CODE } from "../../../processExplorer/process_explorer_constants";

/**
 * This function returns a proper location by adding UO Label followed by Step Label if found.
 * @param riskData
 * @param unitOperations
 * @param steps
 * @returns {string}
 */
export function formatLocationForExport(riskData, unitOperations, steps) {
  const {stepId, unitOperationId} = riskData;

  let unitOperation = unitOperations.find(uo => uo.id === unitOperationId);
  let step = steps.find(step => step.id === stepId);

  const unitOperationLabel = unitOperation ? UIUtils.getRecordCustomLabelForDisplay(unitOperation) : EMPTY_STRING;
  const stepLabel = step ? UIUtils.getRecordCustomLabelForDisplay(step) : EMPTY_STRING;

  return `${unitOperationLabel}\n${stepLabel}`;
}

/**
 * This fills in a property called groupingLabel before sending to stimulsoft, to ensure we have right label to group by, either a UO or a Step.
 * @param instance
 * @returns {string}
 */
export function formatGroupingLabelForExport(instance) {
  const {stepId, stepName, groupByUO, groupByUOId} = instance;

  return (stepId && stepName)
    ? `${TYPE_CODE.STEP + "-" + stepId + " - " + stepName}`
    : `${TYPE_CODE.UNIT_OPERATION + "-" + groupByUOId + " - " + groupByUO}`;
}

/**
 * Formats the criticality justification to be displayed in a Stimulsoft Report
 * @returns {string} The formatted string to include in the report.
 */
export function formatCriticalityJustificationForExport(justifications) {
  let criticalityJustification;
  if (justifications && justifications.length > 0) {
    criticalityJustification = justifications.reduce((result, item, index) => {
      const itemSeparator = index > 0 ? NEW_LINE + NEW_LINE : "";
      return result + (
        itemSeparator
        + (item.attributeKey && item.attributeName ? (item.attributeKey + " - " + item.attributeName + NEW_LINE) : "")
        + getJustification(item)
      );
    }, "");
  } else {
    criticalityJustification = "None";
  }

  return criticalityJustification;
}
