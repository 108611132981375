"use strict";

import * as UIUtils from "../../../ui_utils";
import { BaseDataTransform } from "../base_data_transform";
import {
  getApprovedProposedVersions,
  getLocalDateFromDate,
  getVersionsForModel,
} from "../../canned_reports/canned_report_helper";
import { REQUIREMENT_TABS, RMP_RISK_TABLES_DISPLAY_LABEL_ENUM } from "../../../rmps/constants/rmp_constants";
import RMPRiskTable from "../../../rmps/rmp_risk_table";
import * as RiskHelper from "../../../helpers/risk_helper";
import CommonRiskUtils from "../../../../server/common/misc/common_risk_utils";
import RiskUtils from "../../../../server/common/misc/common_risk_utils";

const RMP_TO_RISK_SCORE_ATTRIBUTES = [
  "RMPToImpact",
  "RMPToUncertainty",
  "RMPToCapabilityRisk",
  "RMPToDetectabilityRisk"
];

const RMP_TO_RISK_SCALE_ATTRIBUTES = [
  "RMPToProcessRiskScale",
  "RMPToCriticalityScale",
  "RMPToRPNScale"
];

const RMP_RISK_TABLE_SCALE_ATTRIBUTES = [
  "Criticality",
  "Process",
  "RPN"
];

const RISK_TABLE_TITLE_TO_RISK_SCALE = {
  Criticality: "Uncertainty/Likelihood",
  Process: "Capability Risk/Occurrence",
  RPN: "Detectability Risk",
};

export class RMPDataTransform extends BaseDataTransform {

  get type() {
    return "rmp";
  }

  shouldRun(options) {
    return true;
  }

  transform(input, options) {
    const {
      modelType,
    } = options;

    const result = input;
    let data = result.instances.requirement;
    data.fullElementName = UIUtils.secureString(data.fullElementName);
    data.approvedVersions = getApprovedProposedVersions(getVersionsForModel(data, modelType), modelType);
    data.riskScoreDescription = data.configureByType ?
      "This RMP uses different risk definitions and risk scales for each of the data types listed in Section 7.1. The definitions and scales are listed by data type below." :
      "This RMP uses the same risk definitions and risk scores for each of the data types listed in Section 7.1.";
    data.riskScaleDescription = data.configureByType ?
      "This RMP uses different risk definitions and risk scales for each of the data types listed in Section 7.1. The resulting Criticality, Process Risk, and RPN tables are listed by data type below.\n\n         Each cell in the table displays the raw value for the risk, along with the corresponding risk and score label in the format of “(risk label / score label)”." :
      "This RMP uses the same risk definitions and risk scales for each of the data types listed in Section 7.1.\n\n         Each cell in the table displays the raw value for the risk, along with the corresponding risk and score label in the format of “(risk label / score label)”.";
    options.showDraftAlerts = data.approvedVersions.length === 0 && data.lastVersion;
    data.assignedProject = data.Projects && data.Projects.length === 1 ? UIUtils.getRecordLabelForDisplay("PRJ", data.Projects[0].id, data.Projects[0].name) : " ########";

    if (data.lastVersion) {
      let riskScoresInformationArray = [];
      if (data.lastVersion.configureByType) {
        for (let key of Object.keys(REQUIREMENT_TABS)) {
          const riskScoresInformation = {};
          const model = REQUIREMENT_TABS[key];
          const modelName = UIUtils.getTypeCodeForModelName(model.id);
          riskScoresInformation.modelName = model.fullName;

          // Prepare risk scores and scale data for display
          for (let riskType of [...RMP_TO_RISK_SCORE_ATTRIBUTES, ...RMP_TO_RISK_SCALE_ATTRIBUTES]) {
            const riskProp = `${riskType}LinkedVersions`;
            const riskArray = data.lastVersion[riskProp]
              .filter(record => record.modelName === modelName).map(record => {

                return {
                  ...record,
                  alwaysCritical: record.alwaysCritical ? "Yes" : "No",
                  critical: record.critical ? "Yes" : "No"
                };

              });
            riskArray.sort(
              UIUtils.sortBy({
                name: "riskScore",
                primer: UIUtils.parseInt
              })
            );
            riskScoresInformation[UIUtils.pluralize(riskType)] = riskArray;
          }

          // Prepare risk scales table data for display
          const rmp = UIUtils.deepClone(data.lastVersion);
          const modelRMP = CommonRiskUtils.filterRMPByType(rmp, modelName);
          modelRMP.boundaries = RiskHelper.getRiskBoundaries(modelRMP);
          const riskScorePermutations = RMPRiskTable.recalculatePermutations(modelRMP);

          for (let riskScale of RMP_RISK_TABLE_SCALE_ATTRIBUTES) {
            const pivotTable = RMPRiskTable.getPivotTableForRiskScaleAndModel(modelRMP, riskScorePermutations,
              riskScale, RMP_RISK_TABLES_DISPLAY_LABEL_ENUM.BOTH, true);
            const columnRiskScores = RMPRiskTable.getRiskScoresForPivotTableHeaders(modelRMP, riskScorePermutations, riskScale, true);
            const riskType = RMPRiskTable.getRiskType(riskScale, null);

            const riskScaleArray = [];
            for (let row of pivotTable) {
              for (const prop in row) {
                if (Object.prototype.hasOwnProperty.call(row, prop) && prop !== "riskRowHeader") {
                  const riskScaleObject = RiskHelper.getRiskScale(riskType, modelRMP, row[prop].rawRiskScore, row[prop].riskObject);
                  riskScaleArray.push({
                    source: RMPRiskTable.getColumnTitle(columnRiskScores.find(score => score.to === UIUtils.parseInt(prop))),
                    target: row.riskRowHeader.toString(),
                    value: this.getRiskScaleValue(riskScaleObject, row, prop),
                    color: riskScaleObject ? riskScaleObject.color : null,
                    title: RISK_TABLE_TITLE_TO_RISK_SCALE[riskScale]
                  });
                  riskScaleArray.sort(
                    UIUtils.sortBy({
                      name: "source",
                      primer: UIUtils.parseInt
                    })
                  );
                }
              }
            }
            riskScoresInformation[`RMPTo${riskScale}ScalesTable`] = riskScaleArray;
          }
          riskScoresInformationArray.push(riskScoresInformation);
        }
      } else {
        let riskInformation = {};
        riskInformation.modelName = null;

        // Prepare risk scores and scales data for display
        for (let riskType of [...RMP_TO_RISK_SCORE_ATTRIBUTES, ...RMP_TO_RISK_SCALE_ATTRIBUTES]) {
          const riskScoreProp = `${riskType}LinkedVersions`;
          const riskArray = data.lastVersion[riskScoreProp].map(record => {
            return {
              ...record,
              alwaysCritical: record.alwaysCritical ? "Yes" : "No",
              critical: record.critical ? "Yes" : "No"
            };

          });
          riskArray.sort(
            UIUtils.sortBy({
              name: "riskScore",
              primer: UIUtils.parseInt
            })
          );
          riskInformation[UIUtils.pluralize(riskType)] = riskArray;
        }

        // Prepare risk scales data for display
        const rmp = UIUtils.deepClone(data.lastVersion);
        rmp.boundaries = RiskHelper.getRiskBoundaries(rmp);
        const riskScorePermutations = RMPRiskTable.recalculatePermutations(rmp);

        for (let riskScale of RMP_RISK_TABLE_SCALE_ATTRIBUTES) {
          const pivotTable = RMPRiskTable.getPivotTableForRiskScaleAndModel(rmp, riskScorePermutations,
            riskScale, RMP_RISK_TABLES_DISPLAY_LABEL_ENUM.BOTH, true);
          const columnRiskScores = RMPRiskTable.getRiskScoresForPivotTableHeaders(rmp, riskScorePermutations, riskScale, true);
          const riskType = RMPRiskTable.getRiskType(riskScale, null);

          const riskScaleArray = [];
          for (let row of pivotTable) {
            for (const prop in row) {
              if (Object.prototype.hasOwnProperty.call(row, prop) && prop !== "riskRowHeader") {
                const riskScaleObject = RiskHelper.getRiskScale(riskType, rmp, row[prop].rawRiskScore, row[prop].riskObject);
                riskScaleArray.push({
                  source: RMPRiskTable.getColumnTitle(columnRiskScores.find(score => score.to === UIUtils.parseInt(prop))),
                  target: row.riskRowHeader.toString(),
                  value: this.getRiskScaleValue(riskScaleObject, row, prop),
                  color: riskScaleObject ? riskScaleObject.color : null,
                  title: RISK_TABLE_TITLE_TO_RISK_SCALE[riskScale]
                });
                riskScaleArray.sort(
                  UIUtils.sortBy({
                    name: "source",
                    primer: UIUtils.parseInt
                  })
                );
              }
            }
          }
          riskInformation[`RMPTo${riskScale}ScalesTable`] = riskScaleArray;
        }
        riskScoresInformationArray.push(riskInformation);
      }

      data.lastVersion = {
        ...data.lastVersion,
        ...JSON.parse(data.lastVersion.configureByTypeSettings),
        riskScoresInformationArray
      };
    } else {
      UIUtils.showError(`No data exists as of ${getLocalDateFromDate(options.reportDate, UIUtils.DATE_FORMAT_FOR_DISPLAY)}`);
    }

    // Cleaning object sent to Stimulsoft
    if (data.approvedVersions && data.approvedVersions.length > 0) {
      for (let version of data.approvedVersions) {
        delete version.versionTransitions;
      }
    }

    if (data.lastVersion) {
      for (let riskType of [...RMP_TO_RISK_SCORE_ATTRIBUTES, ...RMP_TO_RISK_SCALE_ATTRIBUTES]) {
        let riskScoreProp = `${riskType}LinkedVersions`;
        delete data.lastVersion[riskScoreProp];
      }
    }

    delete data.configureByTypeSettings;
    delete data.versionTransitions;
    delete data.LastVersionTransitionId;
    delete data.clonedFromVersionId;
    delete data.clonedFromModel;
    delete data.rMPLinks;
    delete data.RMPVersions;
    delete data.RMPToImpacts;
    delete data.RMPToUncertainties;
    delete data.RMPToCapabilityRisks;
    delete data.RMPToDetectabilityRisks;
    delete data.RMPToProcessRiskScales;
    delete data.RMPToCriticalityScales;
    delete data.RMPToRPNScales;

    result.instances = data;
    return result;
  }

  getRiskScaleValue(riskScaleObject, row, prop) {
    // Stimulsoft cannot render 0 (Not Assessed) in Matrix. I think it considers an empty string if it starts with 0
    // if we find a way to overcome the stimulsoft problem, the value should be `${NOT_ASSESSED_SCALE} (${riskScaleObject.riskLabel})`
    return RiskUtils.scaleIsNotAssessed(riskScaleObject) ? riskScaleObject.riskLabel : row[prop].displayValue;
  }
}
