"use strict";

import React, { Fragment } from "react";
import BaseReactComponent from "../../../base_react_component";
import { getURLByTypeCodeAndId } from "../../../helpers/url_helper";
import * as UIUtils from "../../../ui_utils";

/**
 * This renders the process info field used in various places in the import flow.
 */
export default class ImportProcessInfo extends BaseReactComponent {
  render() {
    const {process} = this.props;

    return process ? (
      <Fragment>
        <div>
          Process:
        </div>
        <div>
          <a target="_blank"
             id="processSelectionLink"
             rel="noreferrer noopener"
             href={getURLByTypeCodeAndId("PR", "View", process.id)}
          >
            {UIUtils.getRecordLabelForDisplay("PR", process.id, process.name)}
          </a>
        </div>
      </Fragment>
    ) : "";
  }
}