"use strict";

import * as styles from "./smart_content_record_header.module.scss";
import React, {MouseEventHandler} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {DirectScopeRecord} from "../../../services/direct_scope_service";
import {getURLByTypeCodeAndId} from "../../../../helpers/url_helper";
import {EDITOR_OPERATIONS} from "../../../../editor/editor_constants";

type SideMenuRecordHeaderProps = {
  record: DirectScopeRecord;
  onBackClick: MouseEventHandler<SVGSVGElement>;
};

export default function SmartContentRecordHeader(props: SideMenuRecordHeaderProps) {
  const {record, onBackClick} = props;
  return (
    <div className="row m-0 align-items-center">
      <FontAwesomeIcon
        id="smart-content-field-tree-back-button"
        className={`btn btn-sm ${styles["record-header-back-button"]}`}
        icon={faArrowLeft}
        onClick={onBackClick}
      />
      <div className={`text-truncate ${styles["record-header-title"]}`}>
        <a
          className="text-decoration-none"
          target="_blank"
          rel="noreferrer"
          href={getURLByTypeCodeAndId(
            record.typeCode,
            EDITOR_OPERATIONS.VIEW,
            record.id,
            false,
          )}
        >
          {record.name}
        </a>
      </div>
      <small className={`${styles["record-header-subtitle"]}`}>
        {record.typeCode}-{record.id}
      </small>
    </div>
  );
}
