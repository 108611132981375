"use strict";

import React, { useState } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { SpecificationCell } from "./specification_cell";
import { HeaderCell } from "./header_cell";
import { MaterialAttributeCell } from "./material_attribute_cell";
import { faLink, faUnlink } from "@fortawesome/free-solid-svg-icons";

export const MaterialAttributesReconciliation = ({dataSource, dropDownSource, onDataChange, readonly, isMandatory = false, isShowIcon = true}) => {

  const DATA_ITEM_KEY = "id";
  const [data, setData] = useState(dataSource);

  const handleItemChange = (event) => {
    const field = event.field || "";
    const newData = data.map((item) =>
      item[DATA_ITEM_KEY] === event.dataItem[DATA_ITEM_KEY]
        ? {...item, [field]: event.value}
        : item
    );
    setData(newData);
    onDataChange(newData);
  };

  return (
    <div className="reconciliation-table-outer-div">
      <Grid
        data={data}
        dataItemKey={DATA_ITEM_KEY}
        onItemChange={handleItemChange}
        editField="inEdit"
      >
        <Column field="name" title="Specifications (Library)"
                cell={(props) => isShowIcon
                  ? <SpecificationCell {...props} icon={readonly ? faUnlink : faLink} />
                  : <SpecificationCell {...props} />
                }
                headerCell={(props) => <HeaderCell {...props} rightBorder="none" />}
        />
        <Column field="materialAttribute"
                title="Material Attributes"
                cell={(props) => <MaterialAttributeCell {...props} readonly={readonly} data={dropDownSource} />}
                headerCell={(props) => <HeaderCell {...props} leftBorder="none" isMandatory={isMandatory} />}
        />
      </Grid>
    </div>
  );
};