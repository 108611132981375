"use strict";

import ConfigurableTablesExport from "../../configurableTables/export/configurable_tables_export";
import moment from "moment/moment";
import * as UIUtils from "../../ui_utils";
import { FIELD_PROPS } from "../../configurableTables/fieldsConfig/constants/configurable_tables_field_props_config";
import { EXPORT_WORD_CHARS_LIMIT } from "../../configurableTables/tables/configurable_tables_constants";

/**
 * This class adapts/transforms the data before exporting it.
 */
export class ControlMethodConfigurableTablesExport extends ConfigurableTablesExport {

  constructor(props) {
    super(props);

    const {showArchived} = props;
    this.records = showArchived ? this.records : this.records.filter(record => !record.deletedAt);
  }

  getProcessedRecords(record, visibleColumns) {
    let instances = super.getProcessedRecords(record, visibleColumns);

    return instances
      .filter(column =>
        column.columnName !== UIUtils.convertCamelCaseToSpacedOutWords(FIELD_PROPS.ACTIONS)
      );
  }

  getFileName() {
    const {
      selectedModelType,
      projectName,
    } = this;

    let exportFileName = `${selectedModelType}_${projectName.substring(0, EXPORT_WORD_CHARS_LIMIT)}`;
    exportFileName += `_${moment().format(UIUtils.DATE_FORMAT_FOR_STORAGE)}`;
    return exportFileName;
  }
}
