"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import BaseQuickEditableListTable from "../base_quick_editable_list_table";
import { getArchivedFilterFunctionOrDefault } from "../utils/filter_helper";
import { COLUMN_FORMATTERS } from "../widgets/tables/column_formatters";
import moment from "moment";
import { CommonString } from "../../server/common/generic/common_string";
import { CommonUtils } from "../../server/common/generic/common_utils";
import CopyRecordPopup from "../widgets/cloning/copy_record_popup";
import { CLONING_PLUGINS } from "../widgets/cloning/copy_record_popup_plugins/plugins_config";

/* This shows the table of Documents when viewing the Documents page. */

// i18next-extract-mark-ns-start documents
class DocumentsListTable extends BaseQuickEditableListTable {
  constructor(props) {
    super(props, "Document");
  }

  generateColumns(props) {
    const {t} = props;

    return [
      this.generateIDColumn(),
      this.generateNameColumn("View", 180),
      {
        ...COLUMN_FORMATTERS.VERSION,
        data: this.createMappedCell((result) => {
          return `${result.majorVersion}.${result.minorVersion}`;
        }),
        title: t("Version"),
      },
      {
        title: t("Type"),
        width: 50,
        data: this.createMappedCell((result) => {

          let type = result.type ? result.type.substring(0, result.type.indexOf("-")) : "";
          return type ? type : "";
        }),
      },
      {
        ...COLUMN_FORMATTERS.CURRENT_STATE,
        data: this.createMappedCell(result => this.getCurrentState(result)),
        title: t("Status"),
      },
      {
        title: t("Last Modified"),
        width: 80,
        data: this.createMappedCell((result, type) => {
          const documentVersions = result.DocumentVersions?.sort(CommonUtils.sortBy({
            name: "updatedAt",
            reverse: true
          }));
          if (!documentVersions) {
            return ``;
          }

          let versionTransitions = documentVersions.reduce((arr, curr) => {
            arr = arr.concat(curr.versionTransitions);
            return arr;
          }, []);

          // if no drafts are available, take the latest Approved version (this happens in Bulk uploads)
          if (versionTransitions.length === 0) {
            versionTransitions = documentVersions.reduce((arr, curr) => {
              arr = arr.concat(curr.versionTransitions.filter(transition => transition.transition === "Approved"));
              return arr;
            }, []);
          }

          if (versionTransitions.length === 0) {
            return ``;
          }

          versionTransitions = versionTransitions.sort(CommonUtils.sortBy({name: "createdAt", reverse: true}));

          const lastModifiedTransition = versionTransitions[0];
          const createdByUser = lastModifiedTransition.createdByUser;
          const lastModifier = CommonString.forceMaxLength(`${createdByUser?.firstName} ${createdByUser?.lastName}`, 30);
          let lastModifiedDate = moment(lastModifiedTransition.createdAt);
          const lastModifiedDateString = lastModifiedDate.format(UIUtils.LONG_DATE_FORMAT_FOR_DISPLAY);

          if (type === "display") {
            return `${lastModifiedDateString} ${lastModifier && ("by " + lastModifier)}`;
          } else {
            return lastModifiedDate.format(UIUtils.DATE_TIME_FORMAT_FOR_STORAGE);
          }
        }),
      },
      this.generateManageColumn()
    ];
  }

  /**
   * Once bulk add process is completed, the document table is loaded
   * with the new values brought from the database. The super function will be
   * called but from a different path after the handleReceiveDataFromServer is
   * called and reloadData is set to false by the list page.
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.reloadData && (prevProps.reloadData !== this.props.reloadData)) {
      UIUtils.secureAjaxGET("editables/Document/list" + "?" + UIUtils.objectToURLParameters({
        showRemoved: true,
        includeHistory: true,
        loadFullHistory: true,
        shouldCompress: true,
        projectId: this.props.projectId
      })).done(this.handleReceiveDataFromServer);
    } else {
      super.componentDidUpdate(prevProps, prevState);
    }
  }

  generateColumnDefsArray() {
    return [
      {className: "results-table-column-id", targets: [0]},
      {className: "results-table-column-date", targets: [5]},
      this.generateManageColumnDef(6)
    ];
  }

  createdRow(row, data) {
    const item = data && data.LastVersion || data;
    if (!item.DocumentVersions) {
      item.DocumentVersions = data.DocumentVersions;
    }

    const filterFunction = getArchivedFilterFunctionOrDefault(this.props.archivedItemsFilter);
    const shouldShow = !filterFunction(item);

    // this may look repeated if you see the default filter function, but it is not
    if (shouldShow && item.deletedAt !== null) {
      $(row).addClass("results-table-row-archived");
    }
  }

  renderCopyPopup() {
    return <div>
      {this.state.showCopyPopup ? (
        <CopyRecordPopup modalRef={copyRecordPopup => this.copyRecordPopup = copyRecordPopup}
                         onHideModal={this.handleHideCopyButtonClick}
                         onCopyCompletion={this.handleCopyCompletion}
                         parent={this}
                         objectToCopy={this.state.objectToCopy}
                         plugins={[
                           CLONING_PLUGINS.DOC_CUSTOM_ID,
                         ]}
        />
      ) : ""}
    </div>;
  }
}


export default I18NWrapper.wrap(DocumentsListTable, ["documents", "base_page"]);
// i18next-extract-mark-ns-stop documents
