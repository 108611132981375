"use strict";

import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import BaseReactComponent from "../../../base_react_component";

/**
 * Use this to provide group by functionality to datatables.
 */
export default class GroupableTablePlugin extends BaseReactComponent {
  /**
   * Initialize the plugin using a field holding the type code, the the unique id of the records being grouped, the
   * column index in the table where the group header should be rendered and the column span of the column holding the
   * group header.
   * @param typeCodeGroupField The field holding type code information.
   * @param idGroupField The field holding the records unique id.
   * @param groupColIndex The column index of the group header row where the header text is going to be rendered in.
   * @param groupColSpan The column span for the group header row.
   */
  constructor(typeCodeGroupField, idGroupField, groupColIndex, groupColSpan) {
    super({typeCodeGroupField, idGroupField, groupColIndex, groupColSpan});

    this.typeCodeGroupField = typeCodeGroupField;
    this.idGroupField = idGroupField;
    this.groupColIndex = groupColIndex;
    this.groupColSpan = groupColSpan;
  }

  /**
   * You need to call this when the tableRef for you table is available on the page you are using datatables. This will
   * initialize datatables API and keep an instance of if locally to this class for manipulating the table features.
   * @param tableRef
   */
  registerTable(tableRef) {
    this.tableAPI = $(tableRef).DataTable();
  }

  /**
   * Generates the expand/collapse button on the header row.
   * @returns {{data: (function(*)), orderable: boolean, width: number, title: string, createdCell: createdCell}}
   */
  generateExpandIndicatorColumn() {
    return {
      title: "",
      width: 1,
      orderable: false,
      data: result => !!result.isGroupRow,
      createdCell: (td, cellData, rowData, rowIndex) => {
        ReactDOM.render(
          (
            rowData.isGroupRow && rowData.isCollapsibleGroup ? (
              <div>
                <div className={`chevron-down-icon ${rowData.isExpanded ? "visible" : "hidden"}`}>
                  <FontAwesomeIcon id={"collapseButton_" + rowIndex}
                                   icon={faChevronDown}
                                   className="pc-link-icon"
                                   onClick={this.handleExpandButtonClick.bind(this, rowIndex)}
                                   aria-label="Expand/Collapse header"
                  />
                </div>
                <div className={`chevron-right-icon ${rowData.isExpanded ? "hidden" : "visible"}`}>
                  <FontAwesomeIcon id={"expandButton_" + rowIndex}
                                   icon={faChevronRight}
                                   className="pc-link-icon"
                                   onClick={this.handleExpandButtonClick.bind(this, rowIndex)}
                                   aria-label="Expand/Collapse header"
                  />
                </div>
              </div>) : ""
          ), td);
      }
    };
  }

  /**
   * This creates a special column in the table used for sorting. This column should also use the orderFixed datatables
   * option, so that it is used for presorting the rows, no matter how the user sorts them on the UI. Presorting allows
   * the groups in the table to hold their position and the rows within each group to be sorted by the user selection
   * within their group. The sorting key for each record should is created in such a way so that the groups are sorted by
   * this in ascending order while it is at the same time the same for the rows within each group, different however for
   * rows in different groups.
   * @returns {{visible: boolean, data: (function(*): string|*), width: number, title: string}}
   */
  generateFixedSortingColumn() {
    return {
      title: "",
      width: 1,
      visible: false,
      data: result => {
        return result.sortingKey;
      },
    };
  }

  handleExpandButtonClick(rowIndex) {
    const row = this.tableAPI.row(rowIndex);
    const rowData = row.data();

    this.expandCollapseRow(row, !rowData.isExpanded);
    rowData.isExpanded = !rowData.isExpanded;
  }

  expandCollapseAllRows(expandAllRows) {
    this.tableAPI.rows().every(rowIndex => {
      const row = this.tableAPI.row(rowIndex);
      let rowData = row.data();
      if (rowData.isCollapsibleGroup) {
        this.expandCollapseRow(row, expandAllRows);
        rowData.isExpanded = expandAllRows;
      }
    });
  }

  expandCollapseRow(row, expand) {
    const rowNode = $(row.node());
    const rowData = row.data();
    const {groupRowId, groupTypeCode} = rowData;

    if (expand) {
      rowNode.find(".chevron-down-icon").removeClass("hidden");
      rowNode.find(".chevron-down-icon").addClass("visible");
      rowNode.find(".chevron-right-icon").addClass("hidden");
      rowNode.find(".chevron-right-icon").removeClass("visible");
    } else {
      rowNode.find(".chevron-down-icon").addClass("hidden");
      rowNode.find(".chevron-down-icon").removeClass("visible");
      rowNode.find(".chevron-right-icon").removeClass("hidden");
      rowNode.find(".chevron-right-icon").addClass("visible");
    }

    this.tableAPI.rows().every(rowIndex => {
      const row = this.tableAPI.row(rowIndex);
      let data = row.data();
      if (groupRowId && data[this.idGroupField] === groupRowId
        || groupTypeCode && data[this.typeCodeGroupField] === groupTypeCode) {
        const rowNode = $(row.node());
        if (expand) {
          $(rowNode).removeClass("hidden");
        } else {
          $(rowNode).addClass("hidden");
        }
      }
    });
  }

  /**
   * This creates a special row for a table, a group row. A group row can be collapsible in which case,
   * when the user clicks on the special expand/collapse indicator on it, it will hide a subset of table records.
   * The criteria base on which records are hidden/shown for each group row are determined by the groupTypeCode and
   * groupRowId parameters. When either one or both are defined, the respective rows matching those values are
   * hidden/shown with each group row.
   * @param isCollapsibleGroup Set this to true if you want this group to expand collapse certain row.
   * @param isLinkHeader Set this to true if the group row is a link header row or not. Link headers are rendered as
   * links and the user can click on them and open the group by attribute in a new tab.
   * @param groupTypeCode This is a type code filter. All table rows matching this type code will be hidden/shows
   * when the respective group row is collapsed/expanded.
   * @param groupRowId This is a Group Id filter. All table rows matching group id should be hidden/shows
   * when the respective group row is collapsed/expanded.
   * @param groupHeader This is what shows up on the group by header row.
   * @param sortingKey This is a string used for sorting the groups in ascending order. This is also used in combination
   * with the orderFixed datatables option to provide a pre fixed ordering for all table rows, including the group by
   * rows. This pre-fixed ordering in applied in a hidden table column, allowing the user to sort by any other column,
   * while at the same time the group rows remain static. This creates a visual effect where it looks like column
   * sorting in datatables happens on the group by row level. Pretty cool stuff heh?
   * @returns {{attributeKey, isGroupRow, isCollapsibleGroup, groupTypeCode, isLinkHeader, groupRowId, unitOperationId, isExpanded, fullName: string}}
   */
  createGroupRow(isCollapsibleGroup, isLinkHeader, groupTypeCode, groupRowId, groupHeader, sortingKey) {
    const virtualGroupRowId = isCollapsibleGroup ? -1 : groupRowId;

    return {
      attributeKey: `${groupTypeCode}-${groupRowId}-${virtualGroupRowId}`,
      sortingKey,
      isGroupRow: true,
      isCollapsibleGroup,
      groupTypeCode,
      isLinkHeader,
      groupRowId,
      [this.idGroupField]: virtualGroupRowId,
      isExpanded: true,
      fullName: groupHeader,
    };
  }

  findGroupRow(keyRecord, recordsToSearchIn) {
    return recordsToSearchIn.find(record => record.isGroupRow
      && record.isCollapsibleGroup === keyRecord.isCollapsibleGroup
      && record.groupRowId === keyRecord.groupRowId
      && record.groupTypeCode === keyRecord.groupTypeCode
      && record[this.idGroupField] === keyRecord[this.idGroupField]);
  }

  renderRow(row, data) {
    if (data.isGroupRow) {
      $(row).addClass("group-row");
      $(`td:eq(${this.groupColIndex})`, row).attr("colspan", this.groupColSpan);
      for (let i = this.groupColIndex + 1; i <= this.groupColSpan; i++) {
        $(`td:eq(${i})`, row).addClass("hidden");
      }
    }
  }
}
