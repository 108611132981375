"use strict";
import React from "react";
import BaseAttribute from "./base_attribute";
import { createHTMLForTextDiff } from "../../helpers/diff_helper";

/**
 * This class presents a text field for a parent record (and is integrated with it) so that the data can be saved back
 * to the database.
 *
 * NOTE: IF YOU AREN'T USING THIS FOR SAVING DATA TO A RECORD, IT's NOT WHAT YOU ARE LOOKING FOR.
 */
export default class TextAttribute extends BaseAttribute {
  constructor(props) {
    super(props);

    // Bind functions to this
    this.renderInput = this.renderInput.bind(this);
  }

  // noinspection JSMethodCanBeStatic
  getInitialValue() {
    return this.props.value || "";
  }

  getValue() {
    const value = this.props.parent.state[this.getAttributeName()];
    return value || value === 0 ? value : this.getInitialValue();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      let value = this.getNextValue(nextProps);
      return (this.currentValue !== value) ||
        (nextProps.pattern !== this.props.pattern) ||
        (nextProps.dataPatternError !== this.props.dataPatternError);
    }
    return true;
  }

  renderInput() {
    let input;
    let inputId = this.getInputId();
    this.currentValue = this.getValue();
    // Uncomment for verbose logging
    // console.log("Render: Generating " + this.getInputId() + " with value: " + value);
    if (this.isView()) {
      if (this.isDiffingVersions()) {
        let oldValue = this.getOldValue(this.props.name);
        input = createHTMLForTextDiff(oldValue, this.currentValue);
      } else {
        input = (<span id={inputId}>{this.currentValue}</span>);
      }
    } else {
      let dataRequiredErrorMsg = this.getCapitalizedName() + " is required.";
      let dataPatternError = "Invalid data entered for " + this.getCapitalizedName();
      input = (
        <input type="text"
               className="form-control"
               id={inputId}
               data-validate="true"
               data-required-error={dataRequiredErrorMsg}
               data-pattern-error={this.props.dataPatternError ? this.props.dataPatternError : dataPatternError}
               pattern={this.props.pattern ? this.props.pattern : "^.*$"}
               required={this.isRequired()}
               value={this.currentValue}
               maxLength="255"
               disabled={this.isDisabled()}
               onChange={this.handleChange}
        />
      );
    }

    return input;
  }
}
