var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { filterEqOperator, filterNotEqOperator, filterIsNullOperator, filterIsNotNullOperator, filterIsEmptyOperator, filterIsNotEmptyOperator, filterStartsWithOperator, filterContainsOperator, filterNotContainsOperator, filterEndsWithOperator, filterGteOperator, filterGtOperator, filterLteOperator, filterLtOperator, filterAfterOrEqualOperator, filterAfterOperator, filterBeforeOperator, filterBeforeOrEqualOperator } from './../messages';
var textOperators = [
    { text: filterContainsOperator, operator: 'contains' },
    { text: filterNotContainsOperator, operator: 'doesnotcontain' },
    { text: filterEqOperator, operator: 'eq' },
    { text: filterNotEqOperator, operator: 'neq' },
    { text: filterStartsWithOperator, operator: 'startswith' },
    { text: filterEndsWithOperator, operator: 'endswith' },
    { text: filterIsNullOperator, operator: 'isnull' },
    { text: filterIsNotNullOperator, operator: 'isnotnull' },
    { text: filterIsEmptyOperator, operator: 'isempty' },
    { text: filterIsNotEmptyOperator, operator: 'isnotempty' }
];
var numericOperators = [
    { text: filterEqOperator, operator: 'eq' },
    { text: filterNotEqOperator, operator: 'neq' },
    { text: filterGteOperator, operator: 'gte' },
    { text: filterGtOperator, operator: 'gt' },
    { text: filterLteOperator, operator: 'lte' },
    { text: filterLtOperator, operator: 'lt' },
    { text: filterIsNullOperator, operator: 'isnull' },
    { text: filterIsNotNullOperator, operator: 'isnotnull' }
];
var dateOperators = [
    { text: filterEqOperator, operator: 'eq' },
    { text: filterNotEqOperator, operator: 'neq' },
    { text: filterAfterOrEqualOperator, operator: 'gte' },
    { text: filterAfterOperator, operator: 'gt' },
    { text: filterBeforeOperator, operator: 'lt' },
    { text: filterBeforeOrEqualOperator, operator: 'lte' },
    { text: filterIsNullOperator, operator: 'isnull' },
    { text: filterIsNotNullOperator, operator: 'isnotnull' }
];
var booleanOperators = [
    { text: filterEqOperator, operator: 'eq' },
    { text: filterNotEqOperator, operator: 'neq' }
];
/**
 * Represents the operators for the TextFilter, NumericFilter, DateFilter and BooleanFilter components.
 *
 * The text field of each operator object will be resolved according to the
 * [localization messages]({% slug globalization_datatools %}#toc-messages).
 */
var Operators = /** @class */ (function () {
    function Operators() {
    }
    Object.defineProperty(Operators, "text", {
        /**
         * An array containing the operators for the TextFilter component.
         *
         * The operators are:
         *
         * - { text: 'filter.containsOperator', operator: 'contains' }
         * - { text: 'filter.notContainsOperator', operator: 'doesnotcontain' }
         * - { text: 'filter.eqOperator', operator: 'eq' }
         * - { text: 'filter.notEqOperator', operator: 'neq' }
         * - { text: 'filter.startsWithOperator', operator: 'startswith' }
         * - { text: 'filter.endsWithOperator', operator: 'endswith' }
         * - { text: 'filter.isNullOperator', operator: 'isnull' }
         * - { text: 'filter.isNotNullOperator', operator: 'isnotnull' }
         * - { text: 'filter.isEmptyOperator', operator: 'isempty' }
         * - { text: 'filter.isNotEmptyOperator', operator: 'isnotempty' }
         */
        get: function () {
            return __spreadArray([], textOperators, true);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Operators, "numeric", {
        /**
         * An array containing the operators for the NumericFilter component.
         *
         * The operators are:
         *
         * - { text: 'filter.eqOperator', operator: 'eq' }
         * - { text: 'filter.notEqOperator', operator: 'neq' }
         * - { text: 'filter.gteOperator', operator: 'gte' }
         * - { text: 'filter.gtOperator', operator: 'gt' }
         * - { text: 'filter.lteOperator', operator: 'lte' }
         * - { text: 'filter.ltOperator', operator: 'lt' }
         * - { text: 'filter.isNullOperator', operator: 'isnull' }
         * - { text: 'filter.isNotNullOperator', operator: 'isnotnull' }
         */
        get: function () {
            return __spreadArray([], numericOperators, true);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Operators, "date", {
        /**
         * An array containing the operators for the DateFilter component.
         *
         * The operators are:
         *
         * - { text: 'filter.eqOperator', operator: 'eq' }
         * - { text: 'filter.notEqOperator', operator: 'neq' }
         * - { text: 'filter.afterOrEqualOperator', operator: 'gte' }
         * - { text: 'filter.afterOperator', operator: 'gt' }
         * - { text: 'filter.beforeOperator', operator: 'lt' }
         * - { text: 'filter.beforeOrEqualOperator', operator: 'lte' }
         * - { text: 'filter.isNullOperator', operator: 'isnull' }
         * - { text: 'filter.isNotNullOperator', operator: 'isnotnull' }
         */
        get: function () {
            return __spreadArray([], dateOperators, true);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Operators, "boolean", {
        /**
         * An array containing the operators for the BooleanFilter component.
         *
         * The operators are:
         *
         * - { text: 'filter.eqOperator', operator: 'eq' }
         * - { text: 'filter.notEqOperator', operator: 'neq' }
         */
        get: function () {
            return __spreadArray([], booleanOperators, true);
        },
        enumerable: false,
        configurable: true
    });
    return Operators;
}());
export { Operators };
/**
 * @hidden
 */
export var stringOperator = function (operator) {
    return operator === 'contains' || operator === 'doesnotcontain' || operator === 'startswith' || operator === 'endswith';
};
/**
 * @hidden
 */
export var unaryOperator = function (operator) {
    return operator === 'isnull' || operator === 'isnotnull' || operator === 'isempty' || operator === 'isnotempty';
};
