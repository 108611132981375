"use strict";

import React, {Fragment} from "react";
import BaseCopyRecordPlugin from "./base_copy_record_plugin";
import * as UIUtils from "../../../ui_utils";
import { CommonUtils } from "../../../../server/common/generic/common_utils";

export class CopyTextFieldPlugin extends BaseCopyRecordPlugin {

  constructor(props) {
    super(props);

    const {configProps, objectToCopy} = this.props;
    let {attributeToUpdate} = configProps;

    if (!CommonUtils.isEmptyString(objectToCopy[attributeToUpdate])) {
      this.handleChangeValue(`Copy of ${objectToCopy[attributeToUpdate]}`);
    }
  }

  render() {
    const {configProps} = this.props;
    let {attributeToUpdate, attributeLabel, required} = configProps;

    if (!attributeLabel) {
      attributeLabel = UIUtils.convertCamelCaseToSpacedOutWords(attributeToUpdate);
    }

    let fieldId = `new${UIUtils.capitalize(attributeToUpdate)}_Copy_Input`;

    return (
      <Fragment>
        <div key={fieldId} className="row">
          <div className="form-group">
            <label htmlFor={fieldId} className="col-form-label">
              New {attributeLabel}:
            </label>
            <input type="text"
                   className="form-control"
                   id={fieldId}
                   data-validate="true"
                   required={!!required}
                   value={this.state[attributeToUpdate]}
                   onChange={(event) => this.handleChangeValue(event.target.value)}
            />
            <div id={`${fieldId}ErrorDiv`} className="help-block with-errors" />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CopyTextFieldPlugin;
