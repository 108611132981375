"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import BaseEditableListTable from "../base_editable_list_table";
import ReactDOMServer from "react-dom/server";
import { getURLByTypeCodeAndId } from "../helpers/url_helper";
import { CLONING_PLUGINS } from "../widgets/cloning/copy_record_popup_plugins/plugins_config";
import CopyRecordPopup from "../widgets/cloning/copy_record_popup";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";

/* This shows the table of projects. */

// i18next-extract-mark-ns-start projects
class ProjectListTable extends BaseEditableListTable {
  constructor(props) {
    super(props, "Project");
  }

  generateColumns(props) {
    const {t} = props;

    return [
      this.generateIDColumn("Dashboard"),
      this.generateNameColumn("Dashboard"),
      {
        title: t("Project Manager"),
        width: 70,
        data: (result) => {
          return ProjectListTable.describeUser(result.projectManager);
        }
      },
      this.generateManageColumn()
    ];
  }

  static describeUser(user) {
    if (user) {
      return user.firstName + " " + user.lastName;
    } else {
      return "";
    }
  }

  generateNameColumn(action = "View", width = 300) {
    const {t} = this.props;
    return {
      title: t("Name"),
      width: width,
      containsHTML: true,
      data: (result) => {
        return ReactDOMServer.renderToStaticMarkup(
          <div>
            <a href={getURLByTypeCodeAndId(this.baseTypeCode, action, result.id)}>
              {result.name}
              {result.currentState === UIUtils.VERSION_STATES.APPROVED ? (
                <sup className="results-table-approved-flag"> {t("approved")}</sup>
              ) : ""}
            </a>
            {result.isDemo ? (
              <sup className="results-table-demo-flag"> {t("demo")}</sup>
            ) : ""}
          </div>
        );
      }
    };
  }

  handleReceiveDataFromServer(results) {
    super.handleReceiveDataFromServer(results);
    new TypeaheadObjectCache("Project").invalidateCacheOptions();
  }

  generateColumnDefsArray() {
    return [
      {className: "results-table-column-id", targets: [0]},
      {className: "results-table-column-user", targets: [2]},
      this.generateManageColumnDef(3)
    ];
  }

  renderCopyPopup() {
    return <div>
      {this.state.showCopyPopup ? (
        <CopyRecordPopup modalRef={copyRecordPopup => this.copyRecordPopup = copyRecordPopup}
                         onHideModal={this.handleHideCopyButtonClick}
                         onCopyCompletion={this.handleCopyCompletion}
                         parent={this}
                         showAsOfDate={this.props.showAsOfDate}
                         objectToCopy={this.state.objectToCopy}
                         plugins={[
                           CLONING_PLUGINS.COPY_BATCH_DATA,
                           CLONING_PLUGINS.PRODUCT_RISK_ASSESSMENT_CHANGE,
                           CLONING_PLUGINS.RMP_CHANGE,
                         ]}
        />
      ) : ""}
    </div>;
  }
}

export default I18NWrapper.wrap(ProjectListTable, ["projects", "base_page"]);
// i18next-extract-mark-ns-stop projects
