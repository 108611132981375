"use strict";

import React from "react";
import { WIDGET_FIELDS } from "../constants/rmp_impact_attribute_constants";
import RMPRiskScoreAttribute, { RMP_RISK_SCORE_ATTRIBUTE_TYPE } from "./rmp_risk_score_attribute";
import { RISK_ATTRIBUTES_TABS } from "../constants/rmp_constants";

/**
 * This is class is responsible for handling Risk Management Plan Impact records.
 */
export default class RMPImpactsAttribute extends RMPRiskScoreAttribute {
  constructor(props) {
    super(props, [...WIDGET_FIELDS]);

    this.errorMessages.editedRowPending = "At least one impact row is in edit state. Please save or cancel all impact rows first.";
    this.type = RMP_RISK_SCORE_ATTRIBUTE_TYPE.IMPACT;
    this.tabContainerType = RISK_ATTRIBUTES_TABS.IMPACT;

    this.adjustFieldDisplayName(props);
  }

  adjustFieldDisplayName(props) {
    if (props.modelName === "PA") {
      this.widgetFields.find(field => field.fieldName === "alwaysCritical").displayName = props.rmp.useRulesBasedCriticality ? "Always Key" : "Always Critical";
    }

    if (props.modelName === "PP" || props.modelName === "MA") {
      this.widgetFields.find(field => field.fieldName === "alwaysCritical").displayName = props.rmp.useRulesBasedCriticality ? "Always Critical / Key" : "Always Critical";
    }
  }

  reloadDataTable(rowData) {
    if (this.refTable) {
      const table = $(this.refTable.current).DataTable();
      this.adjustFieldDisplayName(this.props);
      const field = this.widgetFields.find(field => field.fieldName === "alwaysCritical");
      $(table.column(3).header()).html(field.displayName);
    }

    super.reloadDataTable(rowData);
  }
}
