"use strict";

import React, { memo, useEffect, useState } from "react";
import { CustomDropdownButton } from "../../../../configurableTables/widgets/dropDown/dropdown_button";
import { ALL_OPTION, EMPTY_OPTION } from "../../../../configurableTables/tables/configurable_tables_constants";
import * as UIUtils from "../../../../ui_utils";
import { ALL_UOS_OPTION } from "../../../../reports/risk_tables/risk_tables_report_page";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

function getDropdownAttributes(orderedUOList, unitOperationId) {
  const unitOperations = [...orderedUOList ?? []];
  const unitOperationOptions = [EMPTY_OPTION, ALL_OPTION, ...unitOperations.map(uo => ({
    disabled: false,
    key: UIUtils.stripAllWhitespaces(uo.name),
    title: uo.id,
    value: uo.id === ALL_UOS_OPTION.id ? uo.name : UIUtils.getRecordLabelForDisplay("UO", uo.id, uo.name)
  }))];
  const unitOperationDropdownLabel = unitOperationOptions?.find(option => option.title === unitOperationId)?.value || "";
  return {unitOperations, unitOperationOptions, unitOperationDropdownLabel};
}

const QuickFilterArea = ({selectedModelType, orderedUOList, onUnitOperationChange}) => {

  const [unitOperationId, setUnitOperationId] = useState("All");

  useEffect(() => {
    onUnitOperationChange(unitOperationId);
  }, []);

  const onChange = ({target}) => {
    const value = target.textContent;
    const id = value === "All" ? "All" : UIUtils.parseKey(value).id;
    setUnitOperationId(id);
    if (id !== unitOperationId) {
      onUnitOperationChange(id);
    }
  };

  const {
    unitOperations,
    unitOperationOptions,
    unitOperationDropdownLabel
  } = getDropdownAttributes(orderedUOList, unitOperationId);

  return (
    <CustomDropdownButton
      id="processUnitOperationSelect"
      options={unitOperationOptions}
      label={unitOperationDropdownLabel}
      onOptionsSelect={onChange}
      size="small"
      title="Unit Operation Selection"
      type="secondary"
      isDisabled={unitOperations.length === 0 || selectedModelType === "Unit Operations"}
      icon={faChevronDown}
    />
  );
};


export default memo(QuickFilterArea);