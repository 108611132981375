"use strict";

import { BaseDataTransform } from "./base_data_transform";

/**
 * Creates a data transform that will execute a series of other transforms in the order they are declared.
 */
export class CompositeDataTransform extends BaseDataTransform {
  /**
   * Creates a new instance of the {CompositeDataTransform} class
   * @param config {*} The reports configuration object
   * @param options {ITransformCreationOptions} The creation options for this transform
   */
  constructor(config, options) {
    super(config, options);

    const {children, factory} = options;

    this._children = children && children.length > 0
      ? children.map(child => factory.createTransform(this.addCompositeMetadataToChild(options, child)))
      : [factory.NO_OP];
  }

  /**
   * Copies metatdata from this transform to its children
   * @param options
   * @param child
   * @returns {{reportType: *, factory: *}}
   * @protected
   */
  addCompositeMetadataToChild(options, child) {
    const {factory, reportType} = options;

    return {
      factory,
      reportType,
      ...child,
    };
  }

  /**
   * @inheritDoc
   */
  transform(input, options) {
    let result = input;

    for (let currentTransform of this.children) {
      if (currentTransform.shouldRun(options)) {
        result = currentTransform.transform(result, options);
      }
    }
    return result;
  }

  get type() {
    return "composite";
  }

  /**
   * Returns the array of {BaseDataTransform} objects that will be executed by this instance.
   * @return {BaseDataTransform[]}
   */
  get children() {
    return this._children;
  }

  /**
   * @inheritDoc
   */
  shouldRun(options) {
    // If any child transform returns true, we return true
    for (let currentTransform of this.children) {
      if (currentTransform.shouldRun(options)) {
        return true;
      }
    }
    return false;
  }
}
