import * as React from 'react';
/**
 * @hidden
 */
export var useInheritedState = function (context, defaultValue) {
    var _a = React.useContext(context), contextValue = _a[0], contextSetter = _a[1];
    var _b = React.useState(defaultValue), localValue = _b[0], localSetter = _b[1];
    var value = contextValue !== undefined ? contextValue : localValue;
    var handleValueChange = function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        contextValue !== undefined
            ? contextSetter.apply(void 0, args) : localSetter(args[0]);
    };
    return [
        value,
        handleValueChange
    ];
};
