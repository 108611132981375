"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import { getColumnProp } from "./configurable_tables_column_selection_helper";
import { LOCAL_STORAGE_VERSION, RISK_RANKING_ENABLED_MODELS } from "../tables/configurable_tables_constants";
import BaseReactComponent from "../../base_react_component";

export default class ConfigurableTablesColumnSelectionHandler extends BaseReactComponent {
  constructor(props, isTechTransfer, defaultValueProp) {
    super();

    this.parent = props.parent;
    this.modelsConfig = props.modelsConfig;
    this.columnOperationsAdapter = props.columnOperationsAdapter;
    this.exportColumnGenerator = props.exportColumnGenerator;
    this.isTechTransfer = isTechTransfer;
    this.defaultValueProp = defaultValueProp;

    this.state.visibleColumnsFromUrlFilterApplied = false;
    this.state.visibleColumnsFromUrlFilter = this.getVisibleColumnsFromURL();
  }

  handleResetColumnVisibility(callback) {
    UIUtils.hideLoadingImage();
    this.resetColumnSelection(this.getRememberedVisibleColumns(), callback);
  }

  resetColumnSelection(visibleColumns, callback) {
    const {visibleColumnsFromUrlFilterApplied, visibleColumnsFromUrlFilter} = this.state;
    let visibleTableColumns;
    if (visibleColumns && visibleColumns.length > 0) {
      visibleTableColumns = visibleColumns;
    } else {
      const {selectedModelType} = this.parent.state;
      const fieldsConfig = this.modelsConfig[selectedModelType].fieldsConfig;
      const columnGenerator = new this.exportColumnGenerator();
      visibleTableColumns = fieldsConfig.fields.map(field => {
        columnGenerator.column = field;
        return {
          ...field,
          checked: field[this.defaultValueProp],
          id: field.prop,
          title: columnGenerator.getColumnTitle(false),
        };
      });
    }

    if (!visibleColumnsFromUrlFilterApplied) {
      for (let visibleColumnProp of visibleColumnsFromUrlFilter) {
        const column = visibleTableColumns.find(column => column.prop === visibleColumnProp);
        if (column) {
          column.checked = true;
        }
      }
      UIUtils.pushHistoryURLWithParameterChanges(this.parent.state, null, {visibleColumns: true});
    }

    /* We only want the visible columns for the URL to be applied in both the URL history and the table view once.
       Subsequent calls of this event should not modify the visible columns in the bale based on the URL params, as this
       is caused my the user manually changing the user selection.
     */
    this.setStateSafely({
      visibleColumnsFromUrlFilterApplied: true
    }, () => {
      this.parent.setStateSafely({
        visibleTableColumns
      }, () => {
        if (callback) {
          callback(visibleTableColumns);
        }

        this.rememberColumnSelections(visibleTableColumns);
        UIUtils.setHideLoadingOnAjaxStop(true);
        UIUtils.hideLoadingImage();
      });
    });
  }

  rememberColumnSelections(columns) {
    const {selectedModelType, project} = this.parent.state;
    const storageName = this.getLocalStorageRecordName(selectedModelType, project);
    localStorage[storageName] = JSON.stringify(
      columns.map(column => {
        return {
          id: column.prop,
          prop: column.prop,
          checked: column.checked,
          originatedFromSite: column.originatedFromSite,
        };
      }));
  }

  mapModelTypeColumnRecord(record) {
    return record;
  }

  getRememberedVisibleColumns() {
    const {selectedModelType, project} = this.parent.state;
    const storageName = this.getLocalStorageRecordName(selectedModelType, project);
    let modelTypeToColumns = localStorage[storageName];
    if (modelTypeToColumns) {
      const fieldsConfig = this.modelsConfig[selectedModelType].fieldsConfig;
      const {fields} = fieldsConfig;
      const unAddedColumns = fields.filter(field => !modelTypeToColumns.includes(field.prop));
      modelTypeToColumns = JSON.parse(modelTypeToColumns);
      modelTypeToColumns = modelTypeToColumns
        .map(record => {
          const originalColumn = fields.find(field =>
            field.prop === record.prop &&
            field.originatedFromSite === record.originatedFromSite);

          return {
            ...originalColumn,
            ...this.mapModelTypeColumnRecord(record),
          };
        }).concat(unAddedColumns);
    }
    return modelTypeToColumns || null;
  }

  getVisibleColumnsFromURL() {
    let visibleColumnsFromURL = UIUtils.getParameterByName("visibleColumns");
    return (visibleColumnsFromURL && JSON.parse(visibleColumnsFromURL)) || [];
  }

  handleVisibleTableColumnsChanged(selectorIdToColumnSelectionMap, callback) {
    const {visibleTableColumns, tableState} = this.parent.state;

    for (let [key, value] of selectorIdToColumnSelectionMap) {
      const column = visibleTableColumns.find(column => getColumnProp(column, this.isTechTransfer) === key);
      column.checked = value;
    }

    const {filter} = tableState || {};
    const {filters} = filter || {};
    const validFilters = [];

    if (filters && filters.length > 0) {
      for (const filter of filters) {
        const internalFilters = filter.filters;
        const {field} = internalFilters[0];
        if (this.columnOperationsAdapter.getFieldInformation(visibleTableColumns, field)) {
          validFilters.push(filter);
        }
      }
    }

    this.rememberColumnSelections(visibleTableColumns);
    this.parent.setStateSafely({
      visibleTableColumns,
      tableState: tableState ? {
        sort: tableState.sort,
        filter: {
          filters: validFilters,
          logic: "and",
        },
      } : null,
    }, () => {
      if (callback) {
        callback(visibleTableColumns);
      }
    });
  }

  handleResetToDefaults(selectorIdToColumnSelectionMap) {
    this.resetColumnSelection(null,
      () => this.handleVisibleTableColumnsChanged(selectorIdToColumnSelectionMap));
    const {selectedModelType, project} = this.parent.state;
    const storageName = this.getLocalStorageRecordName(selectedModelType, project);
    localStorage.removeItem(storageName);
  }

  getLocalStorageRecordName(selectedModelType, project) {
    if (!selectedModelType) {
      return null;
    }

    return UIUtils.getTypeCodeForModelName(selectedModelType).toUpperCase() +
      `${this.getLocalStorageProcessName(selectedModelType, project)}` +
      `_VISIBLE_COLUMNS_v${LOCAL_STORAGE_VERSION}`;
  }

  getLocalStorageProcessName(selectedModelType, project) {
    if (this.isTechTransfer) {
      return "_TECH_TRANSFER";
    }
    if (RISK_RANKING_ENABLED_MODELS.includes(selectedModelType) && project &&
      project.productRiskAssessmentType === "Risk Ranking") {
      return "_RISK_RANKING";
    }
    return "";
  }
}
