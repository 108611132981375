"use strict";

import * as UIUtils from "../ui_utils";
import React, { Fragment } from "react";
import TabNavBar from "../widgets/bars/tab_nav_bar";
import { RISK_ATTRIBUTES_TABS } from "./constants/rmp_constants";
import RMPImpactsAttribute from "./scores/rmp_impact_attribute";
import RMPUncertaintiesAttribute from "./scores/rmp_uncertainty_attribute";
import RMPCriticalitiesAttribute from "./scales/rmp_criticality_attribute";
import RMPCapabilityRisksAttribute from "./scores/rmp_capability_risk_attribute";
import RMPProcessRisksAttribute from "./scales/rmp_process_risk_attribute";
import RMPDetectabilityRisksAttribute from "./scores/rmp_detectability_risk_attribute";
import RMPRPNAttribute from "./scales/rmp_rpn_attribute";
import { RMP_RISK_SCORE_ATTRIBUTE_TYPE } from "./scores/rmp_risk_score_attribute";
import { RMP_RISK_ATTRIBUTE_TYPE } from "./scales/rmp_risk_scale_attribute";
import RMPRiskTable from "./rmp_risk_table";
import Section from "../editor/widgets/section";
import BaseReactComponent from "../base_react_component";

const RMP_RISK_TABLES_CONFIGURATION = [
  {
    id: "criticality",
    header: "Criticality Risk Table",
    riskScale: "Criticality"
  },
  {
    id: "process",
    header: "Process Risk Table",
    riskScale: "Process"
  },
  {
    id: "rpn",
    header: "RPN Risk Table",
    riskScale: "RPN"
  }
];

export default class RMPRiskSchemaTab extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: RISK_ATTRIBUTES_TABS.IMPACT
    };
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !this.props.parent.state.isPopupOpened;
  }

  handleTabChanged(tab) {
    if ((tab.title !== RISK_ATTRIBUTES_TABS.UNCERTAINTY.title && tab.title !== RISK_ATTRIBUTES_TABS.DETECTABILITY_RISK.title)
      || this.props.rmp[`use${this.props.modelName}${tab.title}`]) {
      this.setStateSafely({
        selectedTab: tab
      }, () => {
        this.props.onRiskTabChange(tab);
      });
    }
  }

  handleRiskRowsUpdated(rows, type) {
    let min = Number.MAX_SAFE_INTEGER;
    let max = Number.MIN_SAFE_INTEGER;

    for (let row of rows) {
      if (row.riskScore) {
        if (row.riskScore < min) {
          min = UIUtils.parseInt(row.riskScore);
        }
        if (row.riskScore > max) {
          max = UIUtils.parseInt(row.riskScore);
        }
      } else if (row.from && row.to) {
        if (row.from < min) {
          min = UIUtils.parseInt(row.from);
        }
        if (row.to > max) {
          max = UIUtils.parseInt(row.to);
        }
      }
    }

    let {onRiskTypeMinMaxUpdated, modelName} = this.props;
    if (type === RMP_RISK_SCORE_ATTRIBUTE_TYPE.IMPACT) {
      onRiskTypeMinMaxUpdated({
        [`${modelName}minImpact`]: min,
        [`${modelName}maxImpact`]: max
      });
    } else if (type === RMP_RISK_SCORE_ATTRIBUTE_TYPE.UNCERTAINTY) {
      onRiskTypeMinMaxUpdated({
        [`${modelName}minUncertainty`]: min,
        [`${modelName}maxUncertainty`]: max
      });
    } else if (type === RMP_RISK_SCORE_ATTRIBUTE_TYPE.DETECTABILITY_RISK) {
      onRiskTypeMinMaxUpdated({
        [`${modelName}minDetectabilityRisk`]: min,
        [`${modelName}maxDetectabilityRisk`]: max
      });
    } else if (type === RMP_RISK_SCORE_ATTRIBUTE_TYPE.CAPABILITY_RISK) {
      onRiskTypeMinMaxUpdated({
        [`${modelName}minCapabilityRisk`]: min,
        [`${modelName}maxCapabilityRisk`]: max
      });
    } else if (type === RMP_RISK_ATTRIBUTE_TYPE.CRITICALITY) {
      onRiskTypeMinMaxUpdated({
        [`${modelName}minCriticality`]: min,
        [`${modelName}maxCriticality`]: max
      });
    } else if (type === RMP_RISK_ATTRIBUTE_TYPE.PROCESS_RISK) {
      onRiskTypeMinMaxUpdated({
        [`${modelName}minProcessRisk`]: min,
        [`${modelName}maxProcessRisk`]: max
      });
    }
  }

  getRiskScoreOptions() {
    let {
      minRiskScore,
      maxRiskScore
    } = this.props.rmp;

    minRiskScore = UIUtils.parseInt(minRiskScore ? minRiskScore : 1);
    maxRiskScore = UIUtils.parseInt(maxRiskScore ? maxRiskScore : 10);
    maxRiskScore = maxRiskScore > 1000 ? 1000 : maxRiskScore;

    let riskScaleOptions = [];
    for (let i = minRiskScore; i <= maxRiskScore; i++) {
      riskScaleOptions.push(i);
    }
    return riskScaleOptions;
  }

  render() {
    let {
      parent,
      modelName,
      rmp,
      className,
      useUncertainty,
      useDetectability,
      useRulesBasedCriticality,
      usePotential,
      potentialLabel,
      keyLabel,
      isParentTabActive,
      useNotAssessed,
    } = this.props;
    let {
      minRiskScore,
      maxRiskScore,
      configureByType,
      formValidationMode,
    } = rmp;

    let minImpact = rmp[`${modelName}minImpact`];
    let maxImpact = rmp[`${modelName}maxImpact`];
    let minUncertainty = rmp[`${modelName}minUncertainty`];
    let maxUncertainty = rmp[`${modelName}maxUncertainty`];
    let minCriticality = rmp[`${modelName}minCriticality`];
    let maxCriticality = rmp[`${modelName}maxCriticality`];
    let minCapabilityRisk = rmp[`${modelName}minCapabilityRisk`];
    let maxCapabilityRisk = rmp[`${modelName}maxCapabilityRisk`];
    let minProcessRisk = rmp[`${modelName}minProcessRisk`];
    let maxProcessRisk = rmp[`${modelName}maxProcessRisk`];
    let minDetectabilityRisk = rmp[`${modelName}minDetectabilityRisk`];
    let maxDetectabilityRisk = rmp[`${modelName}maxDetectabilityRisk`];

    let selectedTab = this.state.selectedTab;
    let riskAttributesTabs = {...RISK_ATTRIBUTES_TABS};
    riskAttributesTabs.UNCERTAINTY.disabled = !useUncertainty;
    riskAttributesTabs.DETECTABILITY_RISK.disabled = !useDetectability;

    if ((!useUncertainty && selectedTab.title === RISK_ATTRIBUTES_TABS.UNCERTAINTY.title) ||
      (!useDetectability && selectedTab.title === RISK_ATTRIBUTES_TABS.DETECTABILITY_RISK.title)) {
      selectedTab = RISK_ATTRIBUTES_TABS.IMPACT;
    }

    return (
      <Fragment>
        <div className={`row mt-2 ${className}`}>
          <TabNavBar errors={rmp}
                     selected={selectedTab}
                     modelName={modelName}
                     onTabChanged={this.handleTabChanged}
                     tabs={UIUtils.deepClone(riskAttributesTabs)}
                     parent={this}
                     parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                     parentId={this.state.id}
          />
        </div>
        <div className={`row ${className}`}>
          <RMPImpactsAttribute name={`${modelName}RMPImpacts`}
                               linkObject={[`${modelName}RMPToImpact`]}
                               displayName="Impact"
                               modelName={modelName}
                               hideTableCaption={true}
                               isParentTabActive={isParentTabActive}
                               tableDescription="Impact to patient safety and efficacy or downstream quality attributes in manufacturing process. (Equivalent to Severity of Harm)."
                               minRiskScore={minRiskScore ? UIUtils.parseInt(minRiskScore) : 1}
                               maxRiskScore={maxRiskScore ? UIUtils.parseInt(maxRiskScore) : Number.MAX_SAFE_INTEGER}
                               useUncertainty={useUncertainty}
                               useDetectability={useDetectability}
                               useNotAssessed={useNotAssessed}
                               riskScoreOptions={this.getRiskScoreOptions()}
                               className={(selectedTab.title === RISK_ATTRIBUTES_TABS.IMPACT.title ? "" : "tab-nav-bar-tab-content-hidden ") + "col-sm-12"}
                               onRowsUpdated={this.handleRiskRowsUpdated}
                               formValidationMode={formValidationMode}
                               configureByType={configureByType}
                               riskType={selectedTab.title}
                               rmp={rmp}
                               parent={parent}
          />
          <RMPUncertaintiesAttribute name={`${modelName}RMPUncertainties`}
                                     linkObject={[`${modelName}RMPToUncertainty`]}
                                     displayName="Uncertainty"
                                     modelName={modelName}
                                     hideTableCaption={true}
                                     isParentTabActive={isParentTabActive}
                                     tableDescription={"Uncertainty about the level of knowledge around this defined impact. Can also be the probability of the hazardous situation leading to the defined impact (ISO 14971:2019)."}
                                     minRiskScore={minRiskScore ? UIUtils.parseInt(minRiskScore) : 1}
                                     maxRiskScore={maxRiskScore ? UIUtils.parseInt(maxRiskScore) : Number.MAX_SAFE_INTEGER}
                                     useUncertainty={useUncertainty}
                                     useDetectability={useDetectability}
                                     useNotAssessed={useNotAssessed}
                                     riskScoreOptions={this.getRiskScoreOptions()}
                                     className={(selectedTab.title === RISK_ATTRIBUTES_TABS.UNCERTAINTY.title ? "" : "tab-nav-bar-tab-content-hidden ") + "col-sm-12"}
                                     onRowsUpdated={this.handleRiskRowsUpdated}
                                     formValidationMode={formValidationMode}
                                     configureByType={configureByType}
                                     riskType={selectedTab.title}
                                     usePotential={usePotential}
                                     rmp={rmp}
                                     potentialLabel={potentialLabel}
                                     parent={parent}
          />
          <RMPCriticalitiesAttribute name={`${modelName}RMPCriticalities`}
                                     linkObject={[`${modelName}RMPToCriticalityScale`]}
                                     displayName="Criticality Scale"
                                     modelName={modelName}
                                     hideTableCaption={true}
                                     isParentTabActive={isParentTabActive}
                                     tableDescription="Identification of the criticality score in table highlighting the resulting criticality raw score."
                                     className={(selectedTab.title === RISK_ATTRIBUTES_TABS.CRITICALITY_SCALE.title ? "" : "tab-nav-bar-tab-content-hidden ") + "col-sm-12"}
                                     minImpact={minImpact}
                                     maxImpact={maxImpact}
                                     minUncertainty={minUncertainty}
                                     maxUncertainty={maxUncertainty}
                                     useUncertainty={useUncertainty}
                                     useDetectability={useDetectability}
                                     useRulesBasedCriticality={useRulesBasedCriticality}
                                     keyLabel={keyLabel}
                                     formValidationMode={formValidationMode}
                                     configureByType={configureByType}
                                     riskType={selectedTab.title}
                                     rmp={rmp}
                                     onRowsUpdated={this.handleRiskRowsUpdated}
                                     parent={parent}
          />
          <RMPCapabilityRisksAttribute name={`${modelName}RMPCapabilityRisks`}
                                       linkObject={[`${modelName}RMPToCapabilityRisk`]}
                                       displayName="Capability Risk"
                                       modelName={modelName}
                                       hideTableCaption={true}
                                       isParentTabActive={isParentTabActive}
                                       tableDescription="Risk that process capability is not within defined limits as defined by the acceptance criteria. Can also be interpreted as Probability of Occurrence that requirement is out of specification."
                                       minRiskScore={minRiskScore ? UIUtils.parseInt(minRiskScore) : 1}
                                       maxRiskScore={maxRiskScore ? UIUtils.parseInt(maxRiskScore) : Number.MAX_SAFE_INTEGER}
                                       riskScoreOptions={this.getRiskScoreOptions()}
                                       className={(selectedTab.title === RISK_ATTRIBUTES_TABS.CAPABILITY_RISK.title ? "" : "tab-nav-bar-tab-content-hidden ") + "col-sm-12"}
                                       onRowsUpdated={this.handleRiskRowsUpdated}
                                       formValidationMode={formValidationMode}
                                       configureByType={configureByType}
                                       riskType={selectedTab.title}
                                       useNotAssessed={useNotAssessed}
                                       rmp={rmp}
                                       parent={parent}
          />
          <RMPProcessRisksAttribute name={`${modelName}RMPProcessRisks`}
                                    linkObject={[`${modelName}RMPToProcessRiskScale`]}
                                    displayName="Process Risk Scale"
                                    modelName={modelName}
                                    hideTableCaption={true}
                                    isParentTabActive={isParentTabActive}
                                    tableDescription="The product of the Criticality and the Capability Risk (Occurrence). Follows ISO 14971:2019 risk definition."
                                    className={(selectedTab.title === RISK_ATTRIBUTES_TABS.PROCESS_RISK_SCALE.title ? "" : "tab-nav-bar-tab-content-hidden ") + "col-sm-12"}
                                    minCriticality={minCriticality}
                                    maxCriticality={maxCriticality}
                                    minCapabilityRisk={minCapabilityRisk}
                                    maxCapabilityRisk={maxCapabilityRisk}
                                    useUncertainty={useUncertainty}
                                    useDetectability={useDetectability}
                                    formValidationMode={formValidationMode}
                                    configureByType={configureByType}
                                    riskType={selectedTab.title}
                                    rmp={rmp}
                                    onRowsUpdated={this.handleRiskRowsUpdated}
                                    parent={parent}
          />
          <RMPDetectabilityRisksAttribute name={`${modelName}RMPDetectabilityRisks`}
                                          linkObject={[`${modelName}RMPToDetectabilityRisk`]}
                                          displayName="Detectability Risk"
                                          modelName={modelName}
                                          hideTableCaption={true}
                                          isParentTabActive={isParentTabActive}
                                          tableDescription="Risk of not detecting failure or out-of-specification before it causes harm or impacts downstream processes."
                                          minRiskScore={minRiskScore ? UIUtils.parseInt(minRiskScore) : 1}
                                          maxRiskScore={maxRiskScore ? UIUtils.parseInt(maxRiskScore) : Number.MAX_SAFE_INTEGER}
                                          useUncertainty={useUncertainty}
                                          useDetectability={useDetectability}
                                          riskScoreOptions={this.getRiskScoreOptions()}
                                          className={(selectedTab.title === RISK_ATTRIBUTES_TABS.DETECTABILITY_RISK.title ? "" : "tab-nav-bar-tab-content-hidden ") + "col-sm-12"}
                                          onRowsUpdated={this.handleRiskRowsUpdated}
                                          formValidationMode={formValidationMode}
                                          configureByType={configureByType}
                                          riskType={selectedTab.title}
                                          useNotAssessed={useNotAssessed}
                                          rmp={rmp}
                                          parent={parent}
          />
          <RMPRPNAttribute name={`${modelName}RMPToRPN`}
                           linkObject={[`${modelName}RMPToRPNScale`]}
                           displayName="RPN Scale"
                           modelName={modelName}
                           hideTableCaption={true}
                           isParentTabActive={isParentTabActive}
                           tableDescription="RPN is the Risk Priority Number and calculated as the product of the Process Risk and the Detectability Risk."
                           className={(selectedTab.title === RISK_ATTRIBUTES_TABS.RPN_SCALE.title ? "" : "tab-nav-bar-tab-content-hidden ") + "col-sm-12"}
                           minProcessRisk={minProcessRisk}
                           maxProcessRisk={maxProcessRisk}
                           minDetectabilityRisk={minDetectabilityRisk}
                           maxDetectabilityRisk={maxDetectabilityRisk}
                           useUncertainty={useUncertainty}
                           useDetectability={useDetectability}
                           formValidationMode={formValidationMode}
                           configureByType={configureByType}
                           riskType={selectedTab.title}
                           rmp={rmp}
                           onRowsUpdated={this.handleRiskRowsUpdated}
                           parent={parent}
          />
        </div>
        {RMP_RISK_TABLES_CONFIGURATION.map(rmpRiskTableConfig => {
          return (
            <div key={`${rmpRiskTableConfig.id}RMPRiskTableSectionRow`}
                 className={`row rmp-risk-table-section-row ${className}`}
            >
              <div className="col-sm-12">
                <Section id={`${rmpRiskTableConfig.id}RMPRiskTableSection`}
                         parent={parent}
                         showDocLinks={false}
                         headingLevel={4}
                         header={rmpRiskTableConfig.header}
                >
                  <div className="row rmp-risk-table-flex-row-container">
                    <div className="col-sm-12 rmp-risk-table-container rmp-risk-table-flex-cell-container">
                      <RMPRiskTable key={`${modelName}${rmpRiskTableConfig.riskScale}RiskTable`}
                                    id={`${modelName}${rmpRiskTableConfig.riskScale}RMPRiskTable`}
                                    rmp={rmp}
                                    riskScale={rmpRiskTableConfig.riskScale}
                                    riskType={selectedTab.title}
                                    modelName={modelName}
                                    parent={parent}
                                    useUncertainty={useUncertainty}
                                    useDetectability={useDetectability}
                                    formValidationMode={formValidationMode}
                                    configureByType={configureByType}
                                    isParentTabActive={isParentTabActive}
                                    visible={this.props.visible}
                      />
                    </div>
                  </div>
                </Section>
              </div>
            </div>
          );
        })}
      </Fragment>);
  }
}
