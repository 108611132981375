"use strict";

import React, {
  createContext,
  memo,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import {
  addWindowListener,
  calculateTableWidth,
  countVisibleColumns,
  removeWindowListener
} from "./configurable_tables_helper";
import { debounce } from "lodash";
import useAdjustHeight from "./hooks/use_adjust_height";

const TableSettingsContext = createContext();
const INITIAL_WIDTH = window.innerWidth * 0.96;

export const useTableSettings = () => useContext(TableSettingsContext);

/**
 * This is a React Context that holds table dimensions state and functions that work upon those dimensions,
 * try not to add more logic to it and respect Single Responsibility design principle.
 */
export const TableSettingsProvider = memo(({visibleTableColumns, children}) => {

  const columns = useRef(visibleTableColumns);
  const numOfColumns = countVisibleColumns(visibleTableColumns);
  const container = useRef();

  const [
    width,
    setWidth
  ] = useState(INITIAL_WIDTH);

  const height = useAdjustHeight(container);

  const calculateWidth = () => {
    if (columns.current) {
      setWidth(calculateTableWidth(columns.current));
    }
  };

  useLayoutEffect(() => {
    columns.current = visibleTableColumns;
    calculateWidth();
  }, [numOfColumns, visibleTableColumns]);

  useEffect(() => {
    const debouncedCallback = debounce(() => calculateWidth(), 200);
    addWindowListener("resize", debouncedCallback);
    return () => removeWindowListener("resize", debouncedCallback);
  }, []);

  return (
    <div ref={container}>
      <TableSettingsContext.Provider value={{width, height}}>
        {children}
      </TableSettingsContext.Provider>
    </div>
  );
});
