"use strict";

/*
This configuration file contains the main rules for each model type. That includes
- Table
- Data Adapter
- Naming and Security
 */

import React from "react";
import CommonSecurity from "../../../server/common/generic/common_security";
import { ConfigurableTable } from "../../configurableTables/tables/configurable_table";
import ConfigurableTablesVariableAdapter from "../../configurableTables/adapters/configurable_tables_variable_adapter";
import ConfigurableTablesVariableParentAdapter
  from "../../configurableTables/adapters/configurable_tables_variable_parent_adapter";
import ConfigurableTablesUnitOperationAdapter
  from "../../configurableTables/adapters/configurable_tables_unit_operation_adapter";
import ConfigurableTablesStepAdapter from "../../configurableTables/adapters/configurable_tables_step_adapter";
import {
  ConfigurableMasterDetailsTable
} from "../../configurableTables/tables/configurable_master_details_table";
import ProcessParameterFields from "../../configurableTables/fieldsConfig/variables/process_parameter_fields";
import MaterialAttributeFields from "../../configurableTables/fieldsConfig/variables/material_attribute_fields";
import StepFields from "../../configurableTables/fieldsConfig/operations/step_fields";
import UnitOperationFields from "../../configurableTables/fieldsConfig/operations/unit_operation_fields";
import IntermediateAttributeFields
  from "../../configurableTables/fieldsConfig/attributes/intermediate_attribute_fields";
import MaterialFields from "../../configurableTables/fieldsConfig/assets/material_fields";
import ProcessComponentFields from "../../configurableTables/fieldsConfig/assets/process_component_fields";
import DetailsTableContainer from "../../configurableTables/tables/details_table_container";
import ConfigurableTablesCustomFiltersHandler
  from "../../configurableTables/columnOperations/configurable_tables_custom_filters_handler";

export const getResultActiveMap = (modelName) => {
  switch (modelName) {
    case "ProcessParameter":
      return "ppMap";
    case "MaterialAttribute":
      return "maMap";
    case "ProcessComponent":
      return "prcMap";
    case "Material":
      return "mtMap";
    case "IQA":
      return "iqaMap";
    case "IPA":
      return "ipaMap";
    case "UnitOperation":
      return "uoMap";
    case "Step":
      return "stpMap";
  }
};

export const PROCESS_LEVEL_MODELS_CONFIG = {
  ["Process Parameters"]: {
    table: (props) => <ConfigurableMasterDetailsTable {...{
      ...props,
      modelName: "Process Parameter",
      detailsTable: DetailsTableContainer
    }} />,
    adapter: (params) => new ConfigurableTablesVariableAdapter(params, "ppMap", "ProcessParameters"),
    customFiltersHandler: new ConfigurableTablesCustomFiltersHandler(),
    fieldsConfig: new ProcessParameterFields(),
    modelName: "ProcessParameter",
    securityModelType: CommonSecurity.Types.PROCESS_PARAMETER,
  },
  ["Material Attributes"]: {
    table: (props) => <ConfigurableMasterDetailsTable {...{
      ...props,
      modelName: "Material Attribute",
      detailsTable: DetailsTableContainer
    }} />,
    adapter: (params) => new ConfigurableTablesVariableAdapter(params, "maMap", "MaterialAttributes"),
    customFiltersHandler: new ConfigurableTablesCustomFiltersHandler(),
    fieldsConfig: new MaterialAttributeFields(),
    modelName: "MaterialAttribute",
    securityModelType: CommonSecurity.Types.MATERIAL_ATTRIBUTE,
  },
  ["Process Components"]: {
    table: (props) => <ConfigurableTable {...props} />,
    adapter: (params) => new ConfigurableTablesVariableParentAdapter(params, "prcMap", "ProcessComponents"),
    customFiltersHandler: new ConfigurableTablesCustomFiltersHandler(),
    fieldsConfig: new ProcessComponentFields(),
    modelName: "ProcessComponent",
    securityModelType: CommonSecurity.Types.PROCESS_COMPONENT,
  },
  ["Materials"]: {
    table: (props) => <ConfigurableTable {...props} />,
    adapter: (params) => new ConfigurableTablesVariableParentAdapter(params, "mtMap", "Materials"),
    customFiltersHandler: new ConfigurableTablesCustomFiltersHandler(),
    fieldsConfig: new MaterialFields(),
    modelName: "Material",
    securityModelType: CommonSecurity.Types.MATERIAL,
  },
  ["IQAs"]: {
    table: (props) => <ConfigurableMasterDetailsTable {...{
      ...props,
      modelName: "IQA",
      detailsTable: DetailsTableContainer
    }} />,
    adapter: (params) => new ConfigurableTablesVariableAdapter(params, "iqaMap", "IQAs"),
    customFiltersHandler: new ConfigurableTablesCustomFiltersHandler(),
    fieldsConfig: new IntermediateAttributeFields(),
    modelName: "IQA",
    securityModelType: CommonSecurity.Types.IQA,
  },
  ["IPAs"]: {
    table: (props) => <ConfigurableMasterDetailsTable {...{
      ...props,
      modelName: "IPA",
      detailsTable: DetailsTableContainer
    }} />,
    adapter: (params) => new ConfigurableTablesVariableAdapter(params, "ipaMap", "IPAs"),
    customFiltersHandler: new ConfigurableTablesCustomFiltersHandler(),
    fieldsConfig: new IntermediateAttributeFields(),
    modelName: "IPA",
    securityModelType: CommonSecurity.Types.IPA,
  },
  ["Unit Operations"]: {
    table: (props) => <ConfigurableTable {...props} />,
    adapter: (params) => new ConfigurableTablesUnitOperationAdapter(params),
    customFiltersHandler: new ConfigurableTablesCustomFiltersHandler(),
    fieldsConfig: new UnitOperationFields(),
    modelName: "UnitOperation",
    securityModelType: CommonSecurity.Types.UNIT_OPERATION,
  },
  ["Steps"]: {
    table: (props) => <ConfigurableTable {...props} />,
    adapter: (params) => new ConfigurableTablesStepAdapter(params),
    customFiltersHandler: new ConfigurableTablesCustomFiltersHandler(),
    fieldsConfig: new StepFields(),
    modelName: "Step",
    securityModelType: CommonSecurity.Types.STEP,
  },
};
