"use strict";

import { ServiceBase } from "../service_base";
import { StatusDisplayService } from "../status_display_service";
import { EditablesMessageHandler } from "./editables_message_handler";

/**
 * @typedef IEditablesServiceParams {Object}
 * @property {string} [model]
 * @property {string} [urlPrefix]
 * @property {boolean} [useTwoWayCommunication]
 * @property {string} [action]
 * @property {boolean} [idempotent]
 * @property {boolean} [clearUIErrors]
 * @property {boolean} [global]
 * @property {Map<string, string>} [statusMap]
 * @property {boolean} [autoClose]
 * @property {boolean} [keepStatusAfterSuccess]
 */

/**
 * @typedef ISaveResult
 * @property {?*} instance
 */

/**
 * The default values for the save parameters.
 * @type {IEditablesServiceParams}
 */
export const DEFAULT_PARAMETERS = {
  urlPrefix: null,
  useTwoWayCommunication: true,
  idempotent: false,
  clearUIErrors: true,
  global: true,
  autoClose: true,
  keepStatusAfterSuccess: false,
  statusMap: new Map([
    ["emails", "Sending emails..."],
    ["emails sent", "Emails sent."],
  ]),
};

/**
 * Service layer for Editables functionality. Use this to execute editables related operations on the backend.
 */
export class EditablesService extends ServiceBase {
  constructor(messageHandler = null, statusService = null) {
    // If no message handler of status service is set, we initialize with the defaults for the Editables Service
    statusService = statusService || new StatusDisplayService();
    messageHandler = messageHandler || new EditablesMessageHandler(statusService);

    super(messageHandler, statusService);
  }

  get(parameters) {
    return this.requestResponseService.submitGetRequest(parameters);
  }

  /**
   * Saves the payload contained in the parameters
   * @param {*} payload The payload to be saved.
   * @param {IEditablesServiceParams} parameters
   * @returns {Promise<ISaveResult>}
   */
  async save(payload, parameters) {
    /**
     * @type {IEditablesServiceParams}}
     */
    const actualParameters = {
      ...DEFAULT_PARAMETERS,
      action: "addOrEdit",
      ...(parameters || {}),
    };

    return await this.sendToBackend(payload, actualParameters);
  }

  async bulkAdd(payload, parameters) {
    /**
     * @type {IEditablesServiceParams}}
     */
    const actualParameters = {
      ...DEFAULT_PARAMETERS,
      action: "bulkAdd",
      ...(parameters || {}),
    };

    return await this.sendToBackend(payload, actualParameters);
  }

  async getAttributesInformation(payload, parameters) {
    /**
     * @type {IEditablesServiceParams}}
     */
    const actualParameters = {
      ...DEFAULT_PARAMETERS,
      action: "getAttributesInformation",
      ...parameters,
    };

    return await this.sendToBackend(payload, actualParameters);
  }

  /**
   * Approves the editable contained in the parameters
   * @param {boolean} approved Indicates whether the record was approved (if false, rejects)
   * @param {*} payload The payload to be saved.
   * @param {IEditablesServiceParams} parameters
   * @returns {Promise<ISaveResult>}
   */
  async approve(approved, payload, parameters) {
    /**
     * @type {IEditablesServiceParams}}
     */
    const actualParameters = {
      ...DEFAULT_PARAMETERS,
      action: "approve",
      ...(parameters || {}),
    };

    // if not using websockets, use the old lambda instead
    if (!actualParameters.useTwoWayCommunication) {
      actualParameters.action = `approveOrReject/${payload.versionId}`;
    }

    const actualPayload = {
      ...payload,
      approve: approved,
    };

    return await this.sendToBackend(actualPayload, actualParameters);
  }

  /**
   * Proposes the editable contained in the parameters
   * @param {*} payload The payload to be saved.
   * @param {IEditablesServiceParams} parameters
   * @returns {Promise<ISaveResult>}
   */
  async propose(payload, parameters) {
    /**
     * @type {IEditablesServiceParams}}
     */
    const actualParameters = {
      ...DEFAULT_PARAMETERS,
      action: "propose",
      ...(parameters || {}),
    };

    const actualPayload = {
      ...payload,
    };
    return await this.sendToBackend(actualPayload, actualParameters);
  }

  /**
   * Withdraws the editable contained in the parameters
   * @param {*} versionId The version id of the record being rejected
   * @param {*} payload The payload to be saved.
   * @param {IEditablesServiceParams} parameters
   * @returns {Promise<ISaveResult>}
   */
  async reject(versionId, payload, parameters) {
    /**
     * @type {IEditablesServiceParams}}
     */
    const actualParameters = {
      ...DEFAULT_PARAMETERS,
      action: "approve",
      ...parameters,
    };

    const actualPayload = {
      ...payload,
      versionId,
    };
    return await this.sendToBackend(actualPayload, actualParameters);
  }

  async buildQuery(payload, parameters) {
    const actualParameters = {
      ...DEFAULT_PARAMETERS,
      action: "buildQuery",
      ...(parameters || {}),
    };

    return await this.requestResponseService.submitPostRequest(payload, actualParameters);
  }

  async loadRiskInfo(payload, parameters) {
    const actualParameters = {
      ...DEFAULT_PARAMETERS,
      action: "loadRiskInfo",
      ...(parameters || {}),
    };

    return await this.requestResponseService.submitPostRequest(payload, actualParameters);
  }
}
