import { DOMSerializer, DOMParser as ProseMirrorDOMParser } from 'prosemirror-model';
import { AllSelection } from 'prosemirror-state';
import { rowTypeAttr, colgroupAttr } from './config/constants';
var blockWrappers = [
    'div', 'ol', 'ul', 'li', 'table', 'tbody', 'thead', 'tfoot', 'td', 'th', 'p',
    'tr', 'col', 'colgroup', 'article', 'main', 'nav', 'header', 'footer', 'aside', 'section'
];
var removeRowType = function (table, nodeName) {
    var wrapper = (table.ownerDocument || document).createElement(nodeName);
    Array.from(table.rows).filter(function (r) { return r.getAttribute(rowTypeAttr) === nodeName; }).forEach(function (row) {
        row.removeAttribute(rowTypeAttr);
        wrapper.appendChild(row);
    });
    if (wrapper.children.length) {
        table.appendChild(wrapper);
    }
};
var restoreTables = function (fragment) {
    Array.from(fragment.querySelectorAll('table')).forEach(function (table) {
        removeRowType(table, 'thead');
        removeRowType(table, 'tbody');
        removeRowType(table, 'tfoot');
        var emptyElement = Array.from(table.children).find(function (el) { return el.children.length === 0; });
        if (emptyElement) {
            emptyElement.remove();
        }
    });
};
var setRowType = function (children, nodeName) {
    var tag = nodeName.toUpperCase();
    children.filter(function (c) { return c.nodeName === tag; }).forEach(function (rowsWrapper) {
        Array.from(rowsWrapper.children).forEach(function (row) {
            row.setAttribute(rowTypeAttr, nodeName);
            if (rowsWrapper.parentNode) {
                rowsWrapper.parentNode.insertBefore(row, rowsWrapper);
            }
        });
        rowsWrapper.remove();
    });
};
var validateTablesToPmSchema = function (fragment) {
    Array.from(fragment.querySelectorAll('table')).forEach(function (table) {
        var children = Array.from(table.children);
        if (children.some(function (e) { return e.nodeName === 'THEAD' || e.nodeName === 'TFOOT'; })) {
            setRowType(children, 'thead');
            setRowType(children, 'tbody');
            setRowType(children, 'tfoot');
        }
        var colgroup = children.find(function (c) { return c.nodeName === 'COLGROUP'; });
        if (colgroup) {
            table.setAttribute(colgroupAttr, colgroup.outerHTML);
            colgroup.remove();
        }
    });
};
/**
 * Trims the whitespace around the provided block nodes.
 *
 * @param html - Input HTML content
 * @param trimAroundTags - Block elements to which trimming will be applied.
 * Defaults to block nodes of the current default schema:
 * 'div', 'ol', 'ul', 'li', 'table', 'tbody', 'thead', 'tfoot', 'td', 'th', 'p'
 * and additional table and semantic nodes from the default Angular Editor schema:
 * 'tr', 'col', 'colgroup', 'article', 'main', 'nav', 'header', 'footer', 'aside', 'section'
 *
 * @returns The trimmed HTML content
 */
export var trimWhitespace = function (html, trimAroundTags) {
    if (trimAroundTags === void 0) { trimAroundTags = blockWrappers; }
    var tags = trimAroundTags.join('|');
    return html.replace(new RegExp('\\s*(<(?:' + tags + ')(?:\\s[^>]*?)?>)', 'g'), '$1')
        .replace(new RegExp('(<\\/(?:' + tags + ')(?:\\s[^>]*?)?>)\\s*', 'g'), '$1');
};
/**
 * Creates a DocumentFragment from the given HTML content.
 *
 * @param html
 * @returns DocumentFragment
 */
export var htmlToFragment = function (html) {
    var template = document.createElement('template');
    var fragment;
    if ('content' in template) {
        template.innerHTML = html;
        fragment = template.content;
    }
    else {
        // Internet Explorer
        var parsedDocument = new DOMParser().parseFromString(html, 'text/html');
        fragment = document.createDocumentFragment();
        var dom = parsedDocument.body;
        while (dom && dom.firstChild) {
            fragment.appendChild(dom.firstChild);
        }
    }
    return fragment;
};
/**
 * Creates a DocumentFragment from the given ProseMirrorNode.
 * @param doc ProseMirrorNode
 * @returns DocumentFragment
 */
export var pmDocToFragment = function (doc) {
    var fragment = DOMSerializer.fromSchema(doc.type.schema).serializeFragment(doc.content);
    restoreTables(fragment);
    return fragment;
};
/**
 * Creates a ProseMirrorNode from the given DOM element.
 *
 * @param dom
 * @param schema
 * @param parseOptions
 * @returns ProseMirrorNode
 */
export var domToPmDoc = function (dom, schema, parseOptions) {
    return ProseMirrorDOMParser.fromSchema(schema).parse(dom, parseOptions);
};
/**
 * Creates a ProseMirrorNode from the given HTML content.
 *
 * @param content - The new HTML content.
 * @param schema - The document schema.
 * @param parseOptions - ProseMirror parse options recognized by the `parse` and `parseSlice` methods.
 * @returns - New ProseMirrorNode instance.
 */
export var parseContent = function (content, schema, parseOptions) {
    var dom = htmlToFragment(content);
    validateTablesToPmSchema(dom);
    return domToPmDoc(dom, schema, parseOptions);
};
/**
 * A function that serializes the Editor State content as HTML string.
 *
 * @param state - The Editor State
 * @returns - The serialized content
 */
export var getHtml = function (state) {
    var fragment = pmDocToFragment(state.doc);
    var container = document.createElement('div');
    container.appendChild(fragment);
    return container.innerHTML;
};
/**
 * Replaces the content of the editor with a new one.
 *
 * @param content - The new HTML content.
 * @param commandName - The name of the command.
 * @param parseOptions - ProseMirror parse options recognized by the `parse` and `parseSlice` methods.
 * @returns - Command function that takes an editor `state` and `dispatch` function.
 */
export var setHtml = function (content, command, parseOptions) {
    if (command === void 0) { command = 'setHTML'; }
    if (parseOptions === void 0) { parseOptions = { preserveWhitespace: 'full' }; }
    return function (state, dispatch) {
        return dispatch(state.tr
            .setSelection(new AllSelection(state.doc))
            .replaceSelectionWith(parseContent(content, state.schema, parseOptions))
            .setMeta('commandName', command));
    };
};
