"use strict";

import { Log, LOG_GROUP } from "../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.React, "BaseAutoBind");

/**
 * This is a base class auto-binds all methods to the class.
 */
export default class BaseAutoBind {
  constructor() {
    this.autoBind();
  }

  getAllFunctions(object) {
    const functions = new Set();

    do {
      for (const key of Reflect.ownKeys(object)) {
        functions.add([object, key]);
      }
    } while ((object = Reflect.getPrototypeOf(object)) && object !== Object.prototype);

    return functions;
  }

  autoBind() {
    for (const [object, key] of this.getAllFunctions(this)) {
      if (key === "constructor") {
        continue;
      }

      try {
        const descriptor = Reflect.getOwnPropertyDescriptor(object, key);
        if (descriptor && typeof descriptor.value === "function") {
          this[key] = this[key].bind(this);
        }
      } catch (someError) {
        Logger.error(`Cannot auto-bind ${key} because`, Log.error(someError));
      }
    }
  }
}
