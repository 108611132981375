"use strict";

import * as UIUtils from "../../ui_utils";
import moment from "moment-timezone";

module.exports.getDateAsData = function(type, someDate) {
  if (type === "display" || type === "filter") {
    return (someDate ? moment(someDate, UIUtils.DATE_FORMAT_FOR_STORAGE)
      .tz(moment.tz.guess()).format(UIUtils.DATE_FORMAT_FOR_DISPLAY) : "");
  }
  return moment(someDate, UIUtils.DATE_FORMAT_FOR_STORAGE).toDate();
};
