"use strict";

import React from "react";
import { mapToListRecordData } from "../../../library/widgets/relatedProjects/related_projects_utils";
import { ProjectUsageInformationPanel } from "../../../library/widgets/relatedProjects/project_usage_information_panel";
import { PillsCell } from "./pills_cell";
import * as styles from "./style/project_usage_cell.module.scss";

export const UsedInProjectsCell = ({dataItem, className, dataIndex, prop, cache}) => {

  const records = mapToListRecordData(dataItem[prop] || []);

  const renderOverlayContent = () =>
    <ProjectUsageInformationPanel
      id={`projectUsageDataColumn_${dataIndex}`}
      showHeader={false}
      showFromTooltip={true}
      records={records}
    />;

  return (
    <PillsCell
      pills={records.map(record => record?.project?.name)}
      className={className}
      overlayCssClass={styles["tooltip-container"]}
      renderOverlayContent={renderOverlayContent}
      cache={cache}
    />
  );
};

