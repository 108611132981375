import PropTypes from "prop-types";
import React from "react";

export function ExtraPrintItem(props) {
  return <div className="print-row">
    <div className="print-label">{props.extraPrintItem.label}:</div>
    <div className="print-data">{props.extraPrintItem.data}</div>
  </div>;
}

ExtraPrintItem.propTypes = {extraPrintItem: PropTypes.any};
