"use strict";

import React from "react";
import { GridColumn } from "@progress/kendo-react-grid";

import {
  EXTRA_LARGE_COLUMN,
  FORMATTED_FIELD_LABEL,
  LARGE_COLUMN,
  MEDIUM_COLUMN,
  SMALL_COLUMN,
  TEXT_COLUMN,
} from "../configurable_tables_constants";
import { getURLByTypeCodeAndId } from "../../../helpers/url_helper";
import * as UIUtils from "../../../ui_utils";
import ConfigurableTablesColumnGeneratorBase from "./configurable_tables_columns_generator_base";
import { FILTER_TYPES } from "../../columnOperations/configurable_tables_column_operations_constants";
import { FIELD_PROPS } from "../../fieldsConfig/constants/configurable_tables_field_props_config";
import { NameCell } from "../cells/name_cell";
import { VersionCell } from "../cells/version_cell";
import { RiskCell } from "../cells/risk_cell";
import * as commonStyles from "../cells/style/common_cell.module.scss";
import { LinksCell } from "../cells/links_cell";
import { TextAreaCell } from "../cells/text_area_cell";
import { TextCell } from "../cells/text_cell";
import { getClassName } from "../../../techTransfer/tables/tech_transfer_table_helper";
import { BooleanCell } from "../cells/boolean_cell";
import { PillsCell } from "../cells/pills_cell";
import { CommonString } from "../../../../server/common/generic/common_string";
import { Link } from "../cells/widgets/link";

export default class ConfigurableTablesColumnGenerator extends ConfigurableTablesColumnGeneratorBase {

  constructor(props) {
    super(props);
    this.pillsCache = {};
  }

  generateIdColumn(enableColumnOperations = true) {
    const className = FIELD_PROPS.ID;
    return (
      <GridColumn width={SMALL_COLUMN}
                  key={className}
                  title="ID"
                  field="id"
                  headerCell={(props) => this.columnHeaderCell(props)}
                  headerClassName={`column-header ${className}`}
                  cell={(props) => this.idCreatedCell(props)}
                  {...this.getColumnOperationsProps(enableColumnOperations, FILTER_TYPES.NUMERIC, this.props)}
                  locked
      />
    );
  }

  idCreatedCell({dataItem, style, className}) {
    const recordURL = getURLByTypeCodeAndId(dataItem.typeCode, "View", dataItem.id);
    return (
      <td style={style} className={className}>
        <a href={recordURL}
           rel="noopener noreferrer"
           target="_blank"
        >
          {dataItem.typeCode}-{dataItem.id}
        </a>
      </td>
    );
  }

  generateNameColumn(enableColumnOperations = true) {
    const className = FIELD_PROPS.NAME;
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  key={className}
                  title="Name"
                  headerCell={(props) => this.columnHeaderCell(props)}
                  headerClassName={`column-header ${className}`}
                  cell={(props) => this.nameCreatedCell(props)}
                  field="name"
                  reorderable={false}
                  orderIndex={0}
                  {...this.getColumnOperationsProps(enableColumnOperations, FILTER_TYPES.TEXT, this.props)}
                  locked
      />
    );
  }

  nameCreatedCell(props, renderActions = false) {

    const {
      t,
      onCheckPermissions,
      handleArchiveButton,
      handleRestoreButton
    } = this.props;

    return (
      <NameCell
        t={t}
        renderActions={renderActions}
        onCheckPermissions={onCheckPermissions}
        handleArchiveButton={handleArchiveButton}
        handleRestoreButton={handleRestoreButton}
        {...props}
      />
    );
  }


  generateVersionColumn(title, enableColumnOperations = true) {
    const prop = FIELD_PROPS.VERSION;
    return (
      <GridColumn width={SMALL_COLUMN}
                  key={prop}
                  title={title}
                  headerCell={(props) => this.columnHeaderCell(props)}
                  headerClassName={`column-header ${prop}`}
                  cell={(props) => this.versionCreatedCell(props)}
                  field={prop}
                  {...this.getColumnOperationsProps(enableColumnOperations, FILTER_TYPES.TEXT, this.props)}
      />
    );
  }

  versionCreatedCell(props) {
    return (
      <VersionCell {...props} />
    );
  }

  generateRiskLinksColumn() {
    const className = FIELD_PROPS.RISK_LINKS;
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  key={className}
                  title="Risk Links"
                  headerCell={(props) => this.columnHeaderCell(props)}
                  headerClassName={`column-header ${className}`}
                  cell={(props) => this.riskLinksCreatedCell(props)}
      />
    );
  }

  riskLinksCreatedCell({dataItem}) {

    const {
      riskLinks = []
    } = dataItem;

    const links = riskLinks
      .map(link => link.riskLink)
      .filter(link => link && link.id);

    return (
      <LinksCell links={links} />
    );
  }

  generateParentColumnForMaterialProcessComponent() {
    const prop = FIELD_PROPS.PARENT;

    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  key={prop}
                  title={"Material/Process Component"}
                  field={prop}
                  headerCell={(props) => this.columnHeaderCell(props, false)}
                  headerClassName={`two-lines-header ${prop}`}
                  cell={(event) => this.createParentCellForMaterialProcessComponent(event)}
                  {...this.getColumnOperationsProps(true, FILTER_TYPES.TEXT)}
      />
    );
  }

  createParentCellForMaterialProcessComponent({dataItem}) {
    const parent = dataItem?.source;
    const {id, name, typeCode} = parent || {};
    return (
      <td className={commonStyles["cell-container"]}>
        {parent && (
          <Link href={getURLByTypeCodeAndId(typeCode, "View", id)}
                text={name}
          />
        )}
      </td>);
  }

  generateDocumentsColumn(title, process, linksProp, enableColumnOperations = true) {
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  key={linksProp}
                  title={title}
                  headerCell={(props) => this.columnHeaderCell(props)}
                  headerClassName={`column-header ${linksProp}`}
                  cell={(props) => this.documentCreatedCell(linksProp, props)}
                  field={linksProp}
                  {...this.getColumnOperationsProps(enableColumnOperations, FILTER_TYPES.TEXT, this.props)}
      />
    );
  }

  documentCreatedCell(linksProp, {dataItem}) {

    let documents = [];

    if (dataItem) {
      const value = dataItem[linksProp];
      documents = value
        ? Array.isArray(value) ? value : JSON.parse(value)
        : [];
      documents = documents.filter(reference => ![reference.linkType, reference.fileName].includes("None"));
    }

    return (
      <LinksCell links={documents} />
    );
  }

  generateObjectListColumn(process, prop, typeCode, title) {
    return (
      <GridColumn width={EXTRA_LARGE_COLUMN}
                  key={prop}
                  field={prop}
                  headerCell={(props) => this.columnHeaderCell(props)}
                  headerClassName={`column-header ${UIUtils.convertToCamelCaseId(prop)}`}
                  title={title}
                  cell={(props) => this.createObjectListCell(props, `${prop}${FORMATTED_FIELD_LABEL}`, typeCode)}
                  {...this.getColumnOperationsProps(true, FILTER_TYPES.COMBO_BOX, this.props, prop)}
      />
    );
  }

  createObjectListCell({dataItem, className}, prop, typeCode) {

    const objects = dataItem[prop] || [];
    const links = objects.sort(UIUtils.sortBy("name"))
      .map(obj => ({...obj, typeCode}));

    return (
      <LinksCell links={links} className={className} />
    );
  }

  generateRiskColumn(title, prop, riskType, enableColumnOperations = true) {
    return (
      <GridColumn width={LARGE_COLUMN}
                  key={prop}
                  title={title}
                  field={prop}
                  headerCell={(props) => this.columnHeaderCell(props)}
                  headerClassName={`column-header ${UIUtils.convertToCamelCaseId(prop)}`}
                  cell={(props) => this.riskCreatedCell(riskType, prop, props)}
                  {...this.getColumnOperationsProps(enableColumnOperations, FILTER_TYPES.COMBO_BOX, this.props, prop)}
      />
    );
  }

  riskCreatedCell(riskType, prop, props) {
    return (
      <RiskCell
        riskType={riskType}
        prop={prop}
        adaptRiskFilterName={this.adaptRiskFilterName}
        {...props}
      />
    );
  }

  /**
   *
   * @param title {string} used for title prop in GridColumn component
   * @param prop {string} used for key and field props in GridColumn component
   * @param [enableColumnOperations] {boolean}
   * @param width
   * @returns {JSX.Element}
   */
  generateTextAreaColumn(title, prop, enableColumnOperations = true, width = TEXT_COLUMN) {
    return (
      <GridColumn width={width}
                  key={prop}
                  title={title}
                  field={prop}
                  headerCell={(props) => this.columnHeaderCell(props)}
                  headerClassName={`column-header ${prop}`}
                  cell={(props) => this.textAreaCreatedCell(props, title, prop)}
                  {...this.getColumnOperationsProps(enableColumnOperations, FILTER_TYPES.TEXT)}
      />
    );
  }

  textAreaCreatedCell(props, title, prop) {
    return (
      <TextAreaCell
        title={title}
        prop={prop}
        {...props}
      />
    );
  }

  /**
   *
   * @param title {string} used for title prop in GridColumn component
   * @param prop {string} used for key and field props in GridColumn component
   * @param [enableColumnOperations] {boolean}
   * @param width
   * @param filterType
   * @returns {JSX.Element}
   */
  generateTextColumn(title, prop, enableColumnOperations = true, width = TEXT_COLUMN, filterType = FILTER_TYPES.TEXT) {
    return (
      <GridColumn width={width}
                  key={prop}
                  title={title}
                  field={prop}
                  headerCell={(props) => this.columnHeaderCell(props)}
                  headerClassName={`column-header ${prop}`}
                  cell={(props) => this.textCreatedCell(props, title, prop)}
                  {...this.getColumnOperationsProps(enableColumnOperations, filterType, this.props, prop)}
      />
    );
  }

  textCreatedCell(props, title, prop, filterType = FILTER_TYPES.TEXT) {
    return (
      <TextCell
        {...props}
        title={title}
        prop={prop}
        filterType={filterType}
      />
    );
  }


  generateBooleanColumn(title, prop) {
    return (
      <GridColumn width={MEDIUM_COLUMN}
                  key={getClassName(prop)}
                  title={title}
                  field={prop}
                  headerCell={(props) => this.columnHeaderCell(props)}
                  headerClassName={`column-header`}
                  cell={(props) => this.createBooleanCell(props, prop)}
                  {...this.getColumnOperationsProps(true, FILTER_TYPES.COMBO_BOX, this.props, prop)}
      />
    );
  }

  createBooleanCell({dataItem, className}, prop) {
    const value = dataItem[prop];
    return (
      <BooleanCell value={value} className={className} />
    );
  }

  generateMultiSelectionColumn(title, prop, isFormatted = true, width = LARGE_COLUMN) {
    return (
      <GridColumn width={width}
                  key={prop}
                  field={prop}
                  title={title}
                  headerCell={(props) => this.columnHeaderCell(props, false)}
                  headerClassName={`column-header ${prop}`}
                  cell={(event) => this.multiSelectionCreatedCell(event, `${prop}${isFormatted ? FORMATTED_FIELD_LABEL : ""}`)}
                  {...this.getColumnOperationsProps(true, FILTER_TYPES.COMBO_BOX, this.props, prop)}
      />
    );
  }

  multiSelectionCreatedCell({dataItem}, prop) {
    return (
      <PillsCell pills={CommonString.toArray(dataItem[prop]) || []} cache={this.pillsCache} />
    );
  }

}
