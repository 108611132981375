"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import CannedReport from "../canned_report";
import * as CannedReportHelper from "../canned_report_helper";
import Typeahead from "../../../widgets/typeahead";
import { Radio, RadioGroup } from "react-radio-group";
import ImplementationNeededError from "../../../utils/implementation_needed_error";
import { TRAINING_STATUS } from "../../data_transform/canned_reports/training/constants/constants";
import CannedReportTrainingInfoBox from "./canned_report_training_infoBox";

const {Log, LOG_GROUP} = UIUtils.CommonLog;
const Logger = Log.group(LOG_GROUP.Reports, "TrainingReportBase");

/* This is the base class for all training reports */
export default class TrainingReportBase extends CannedReport {
  constructor(props) {
    super(props);

    this.state.filterOptions = this.defaultFilterValues;
  }

  renderStatusFilter() {
    return (
      <div id="statusFilter">
        <label className="base-attribute data-report-filter-title">
          Status: <CannedReportTrainingInfoBox />
        </label>
        <RadioGroup name={"statusLabelsRadioGroup"}
                    htmlFor="statusRadioGroup"
                    selectedValue={this.state.filterOptions.status}
                    onChange={this.handleStatusChange}
        >
          <label id={"pendingStatusLabelFilterLabel"}
                 className="col-form-label document-training-report-label"
          >
            <Radio id={"pendingStatusLabelFilter"}
                   value={TRAINING_STATUS.PENDING}
            />{TRAINING_STATUS.PENDING}
          </label>
          <label id={"completedStatusLabelFilterLabel"}
                 className="col-form-label document-training-report-label"
          >
            <Radio id={"completeStatusLabelFilter"}
                   value={TRAINING_STATUS.COMPLETED}
            />{TRAINING_STATUS.COMPLETED}
          </label>
          <label id={"overdueStatusLabelFilterLabel"}
                 className="col-form-label document-training-report-label"
          >
            <Radio id={"overdueStatusLabelFilter"}
                   value={TRAINING_STATUS.OVERDUE}
            />{TRAINING_STATUS.OVERDUE}
          </label>
          <label id={"allStatusLabelFilterLabel"}
                 className="col-form-label document-training-report-label"
          >
            <Radio id={"allStatusLabelFilter"}
                   value="all"
            />All
          </label>
        </RadioGroup>
      </div>
    );
  }

  handleStatusChange(event) {
    let filterOptions = this.state.filterOptions;
    filterOptions.status = event;

    this.setStateSafely({reportResults: null, filterOptions}, () => {
      this.filterResults();
    });
  }

  renderTypeaheadFilter() {
    let {label, options, user} = this.typeaheadFilterInfo;
    const selectedUser = user ? options.filter(option => option.id === user) : undefined;
    return (
      <div id="dataReportTypeaheadDiv">
        <label className="base-attribute data-report-filter-title">
          {label}:
        </label>
        <Typeahead id="dataReportTypeahead"
                   inputProps={{id: "dataReportTypeaheadInput", autoComplete: "off"}}
                   options={options || []}
                   onChange={this.handleTypeaheadChange}
                   maxResults={10}
                   className="risk-map-search-typeahead"
                   selectHintOnEnter={true}
                   multiple={true}
                   defaultSelected={user ? selectedUser : []}
        />
      </div>
    );
  }

  handleTypeaheadChange(event) {
    let {statePropName} = this.typeaheadFilterInfo;
    let filterOptions = this.state.filterOptions;
    filterOptions[statePropName] = event.map(option => option.id);

    this.setStateSafely({reportResults: null, filterOptions}, () => {
      this.filterResults();
    });
  }

  renderVersionFilter() {
    return (
      <div id="statusFilter">
        <label className="base-attribute data-report-filter-title">
          Version:
        </label>
        <RadioGroup name={"versionLabelsRadioGroup"}
                    htmlFor="versionRadioGroup"
                    selectedValue={this.state.filterOptions.version}
                    onChange={this.handleVersionChange}
        >
          <label id={"allVersionsLabelFilterLabel"}
                 className="col-form-label document-training-report-label"
          >
            <Radio id={"allVersionsLabelFilter"}
                   value="all"
            />All Versions
          </label>
          <label id={"latestVersionLabelFilterLabel"}
                 className="col-form-label document-training-report-label"
          >
            <Radio id={"latestVersionLabelFilter"}
                   value="latest"
            />Latest
          </label>
        </RadioGroup>
      </div>
    );
  }

  handleVersionChange(event) {
    let filterOptions = this.state.filterOptions;
    filterOptions.version = event;

    this.setStateSafely({reportResults: null, filterOptions}, () => {
      this.filterResults();
    });
  }

  filterResults() {
    throw new ImplementationNeededError();
  }

  get typeaheadFilterInfo() {
    throw new ImplementationNeededError();
  }

  get defaultFilterValues() {
    throw new ImplementationNeededError();
  }

  filterDataAfterReceivedFromServer() {
    this.filterResults();
  }

  wrapUp(results) {
    UIUtils.showLoadingImage("Transforming data...");

    const {reportDate, filterOptions} = this.state;
    const {reportType, reportOptions} = this.props;

    if (results && results.instances) {
      const editableName = this.getEditableName();
      const reportResults = CannedReportHelper.jsonPreProcessing(results, reportType,
        reportOptions, null, null, editableName, [],
        null, reportDate, filterOptions);

      Logger.local(">> Report Results", Log.object(reportResults), Log.json(reportResults));
      Logger.verbose("Report results", Log.json(reportResults));

      this.setStateSafely({
        reportResults,
        isPrintDisabled: false,
        isExportDisabled: false,
        editableName,
        filterOptions
      });
    }
  }
}
