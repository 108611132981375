var _this = this;
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { DrawerContext } from './context/DrawerContext';
import { DrawerNavigation } from './DrawerNavigation';
import { classNames, useDir } from '@progress/kendo-react-common';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
/**
 * Represents the [KendoReact Drawer component]({% slug overview_drawer %}).
 *
 * @example
 * ```jsx
 * const App = () => {
 *   const items = [
 *       { text: 'Inbox', icon: 'k-i-inbox', selected: true },
 *       { separator: true },
 *       { text: 'Notifications', icon: 'k-i-bell', disabled: true },
 *       { text: 'Calendar', icon: 'k-i-calendar' },
 *       { separator: true },
 *       { text: 'Attachments', icon: 'k-i-hyperlink-email' },
 *       { text: 'Favourites', icon: 'k-i-star-outline' }
 *   ];
 *
 *   const [expanded, setExpanded] = React.useState(true);
 *   const handleClick = () => setExpanded(prevState => !prevState);
 *
 *   return (
 *       <Drawer expanded={expanded} position='start' mode='push' items={items}>
 *           <DrawerContent>
 *               <button className="k-button" onClick={handleClick}>Toggle the drawer state</button>
 *           </DrawerContent>
 *       </Drawer>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var Drawer = React.forwardRef(function (props, target) {
    validatePackage(packageMetadata);
    var expanded = props.expanded, mode = props.mode, position = props.position, className = props.className, children = props.children, style = props.style, animation = props.animation, mini = props.mini, width = props.width, miniWidth = props.miniWidth, items = props.items, item = props.item, tabIndex = props.tabIndex, onOverlayClick = props.onOverlayClick, onSelect = props.onSelect;
    var elementRef = React.useRef(null);
    var focusElement = React.useCallback(function () {
        if (elementRef.current) {
            elementRef.current.focus();
        }
    }, []);
    React.useImperativeHandle(target, function () { return ({
        element: elementRef.current,
        focus: focusElement
    }); });
    var handleSelect = React.useCallback(function (itemTarget, itemIndex, event) {
        if (items && onSelect) {
            var ev = {
                itemTarget: itemTarget,
                itemIndex: itemIndex,
                syntheticEvent: event,
                nativeEvent: event && event.nativeEvent,
                target: _this
            };
            onSelect.call(undefined, ev);
        }
    }, [items, onSelect]);
    var dir = useDir(elementRef, props.dir);
    var drawerClassNames = classNames({
        'k-drawer-container': true,
        'k-drawer-expanded': expanded,
        'k-drawer-overlay': mode === 'overlay',
        'k-drawer-push': mode === 'push',
        'k-drawer-mini': mini
    }, className);
    return (React.createElement(DrawerContext.Provider, { value: {
            animation: animation,
            expanded: expanded,
            mode: mode,
            position: position,
            mini: mini,
            dir: dir,
            items: items,
            item: item,
            width: width,
            miniWidth: miniWidth,
            onOverlayClick: onOverlayClick,
            onSelect: handleSelect
        } },
        React.createElement("div", { className: drawerClassNames, ref: elementRef, dir: dir, style: style, tabIndex: tabIndex },
            items && React.createElement(DrawerNavigation, null),
            children)));
});
Drawer.propTypes = {
    animation: PropTypes.any,
    expanded: PropTypes.bool,
    children: PropTypes.any,
    className: PropTypes.string,
    dir: PropTypes.string,
    mode: PropTypes.string,
    position: PropTypes.string,
    mini: PropTypes.bool,
    style: PropTypes.object,
    tabIndex: PropTypes.number,
    width: PropTypes.number,
    miniWidth: PropTypes.number,
    selected: PropTypes.number,
    onSelect: PropTypes.func,
    onOverlayClick: PropTypes.func
};
var defaultProps = {
    animation: true,
    expanded: false,
    mode: 'overlay',
    position: 'start',
    mini: false,
    dir: 'ltr',
    width: 240,
    miniWidth: 50
};
Drawer.defaultProps = defaultProps;
Drawer.displayName = 'KendoDrawer';
