"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import CommonSecurity from "../../server/common/generic/common_security";
import CommonUtils from "../../server/common/generic/common_utils";
import CommonURLs from "../../server/common/generic/common_urls";
import BaseReactComponent from "../base_react_component";
import CompanyLoginHeader from "../widgets/headers/company_login_header";
import ErrorBar from "../widgets/bars/error_bar";
import MFASetup from "./accountManagement/mfa_setup";
import UserNewPasswordPopup from "./passwordManagement/user_new_password_popup";
import UserNewSigningPinPopup from "./passwordManagement/user_new_signing_pin_popup";
import FooterBar from "../widgets/bars/footer_bar";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import { RetryWrapper, RetryPleaseError } from "../helpers/retry_wrapper";
import Cookies from "js-cookie";
import { Auth } from "aws-amplify";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";
import { Button } from "@qbdvision-inc/component-library";
import * as CognitoHelper from "../helpers/cognito_helper";
import { Trans } from "react-i18next";


const Logger = Log.group(LOG_GROUP.Users, "UserLogin");
const HEADERS = {
  mfaSubmission: <span id="pageTitleBar">Multi-Factor Authentication</span>,
  mfaSetup: <span id="pageTitleBar">Set up Multi-Factor Authentication</span>,
  defaultHeader: <span id="pageTitleBar">Welcome back to
                  <span className="qbd"> QbD</span>
                  <span className="vision">Vision</span>
                  <sup>&reg;</sup>
                 </span>
};

/**
 * This renders the login page.
 */
// i18next-extract-mark-ns-start users
class UserLogin extends BaseReactComponent {
  constructor(props) {
    super(props);

    window.addEventListener("popstate", this.handleHistoryChange);
    CognitoHelper.configureUserPool();

    this.initialize();
  }

  initialize() {
    const {t} = this.props;

    const reason = UIUtils.getParameterByName("reason");
    if (reason) {
      UIUtils.clearSessionInfoForLogout();
    }

    if (UIUtils.getCognitoUUID()) {
      let returnTo = UIUtils.getParameterByName("returnTo");
      if (!returnTo) {
        returnTo = UIUtils.FRONT_END_URL + UIUtils.DEFAULT_RETURN_TO_URL;
      }
      window.location.href = UIUtils.getSecuredURL(returnTo, {
        enforceHostWithinQbDVisionDomain: true
      });
      UIUtils.setLoadingDisabled(false);
      UIUtils.showLoadingImage();
      return;
    }

    this.setStateSafely({
      username: "",
      password: "",
      showNewPasswordPopup: false,
      showNewPinPopup: false,
      cognitoUser: null,
      hasValidEmail: false,
      error: null,
      step: 0,
      isMfaRequired: false,
      mfaSecret: "",
      mfaCode:"",
    });

    const error = UIUtils.getFragmentByName("error_description");
    const code = UIUtils.getFragmentByName("access_token");
    const email = Cookies.get("EMAIL");

    if (error) {

      UIUtils.setHideLoadingOnAjaxStop(false);
      UIUtils.showLoadingImage();
      this.clearHistory();

      UIUtils.secureAjaxGET(`users/userAPI?activity=handleFirstExternalLogin`, {email})
        .done((result) => {

          if (result) {
            if (error.includes("Cognito users with a username/password may not be passed")) {
              this.handleLoginError(t("You seem to have an active session with different email that uses {{ result.identityProvider }}. Please sign out of {{ identityProvider }} and try to login to QbDVision again.", result));
            } else if (error.includes("User does not exist")) {
              const tempPassword = window.atob(result.externalProviderAuth);

              Auth.federatedSignIn({provider: result.identityProvider})
                .then(user => {
                  UIUtils.setHideLoadingOnAjaxStop(true);
                  UIUtils.hideLoadingImage();
                  if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
                    Auth.completeNewPassword(user, tempPassword, [])
                      .then(result => {
                        this.setStateSafely({showNewPinPopup: true, accessInformation: result});
                      })
                      .catch(error => {
                        this.handleLoginError(error);
                      });
                  }
                })
                .catch(error => {
                  UIUtils.setHideLoadingOnAjaxStop(true);
                  UIUtils.hideLoadingImage();
                  this.handleLoginError(error);
                });
            } else if (error) {
              this.handleLoginError(error);
            }
          } else {
            this.handleLoginError(t(`{{ email }} is not recognized as part of your configured IDP. Contact your administrator.`, {email}));
          }
        });
    } else if (code) {
      this.clearHistory();

      UIUtils.secureAjaxGET(`users/userAPI?activity=userExists&accessToken=${code}`, {email}, false, (error) => {

        const externalIdentityProvider = Cookies.get("IDENTITY_PROVIDER");
        const errorText = error.responseText;

        if (externalIdentityProvider && errorText === "Your username/password did not match.") {
          sessionStorage["externalLoginError"] = errorText;
          CommonURLs.logoutExternalUser();
        }
      }).done((user) => this.handleExternalUserLogin(user));
    }
  }

  clearHistory(url = window.location.pathname + window.location.search) {
    if (this.state.cognitoUser) {
      delete this.state.cognitoUser;
      // this.setStateSafely({mfaSecret: ""});
    }
    history.replaceState(UIUtils.cleanStateForHistory(this.state), "", url);
  }

  handleExternalUserLogin(user) {
    const {t} = this.props;

    if (user) {
      const {name, sub, email, accessToken, username} = user;

      if (user.newSigningPinIsRequired) {
        this.setStateSafely({
          showNewPinPopup: true,
          accessInformation: {
            encodedAccessToken: accessToken,
            ...user,
          }
        });
      } else {
        UIUtils.showLoadingImage();
        UIUtils.recordSuccessfulLogin({
          encodedAccessToken: accessToken,
        }, {name, sub, email, username});
      }
    } else {
      this.handleLoginError(t("Email/Username is incorrect"));
    }
  }

  hidePasswordModal(mfaSetupChallenge) {
    if (this.state.showNewPasswordPopup) {
      $(this.newPasswordPopup).modal("hide");
      document.body.classList.remove("modal-open");
      const modalBackdrop = document.querySelector(".modal-backdrop");
      if (modalBackdrop) {
        modalBackdrop.remove();
      }
      this.setStateSafely({showNewPasswordPopup: false});
    }
    if (mfaSetupChallenge?.username) {
      const {cognitoUser} = this.state;
      this.handleMfaSetup(cognitoUser).then(() => {
        UIUtils.hideLoadingImage();
      });
    }
  }

  hidePinModal() {
    if (this.newPinPopup) {
      $(this.newPinPopup).modal("hide");
    }

    this.setStateSafely({
      showNewPinPopup: false,
    });
  }

  handleUserLogin(event) {
    event.preventDefault();
    const {username, password} = this.state;
    const {t} = this.props;

    UIUtils.clearError();
    UIUtils.showLoadingImage();
    $.ajax({
      url: UIUtils.getURL(`users/userAPI?activity=getUsername&username=${encodeURIComponent(username)}`),
      type: "GET",
      global: false,
      idempotent: true,
      error: result => {
        let responseJSON = result.responseJSON;
        let error = responseJSON && responseJSON.error ? responseJSON.error : responseJSON;

        if (error && (
          (error.stack && error.stack.startsWith("InvalidParameterException"))
          || (error.code && error.code === "InvalidParameterException")
        )) {
          UIUtils.hideLoadingImage();
          this.handleLoginError(t("Incorrect username or password"));
        } else {
          this.handleLoginError(error);
        }
      }
    }).done((username) => {
      // noinspection JSIgnoredPromiseFromCall
      new RetryWrapper(() => this.attemptLogin(username, password),
        (ignored, waitInMS) => UIUtils.showError(t("Cannot login to QbDVision. Retrying in {{ retryWait }} seconds...", {retryWait: waitInMS / 1000}))
      ).retryFunction();
    });
  }

  newPasswordRequired(cognitoUser) {
    // User was signed up by an admin and must provide new
    // password and required attributes, if any, to complete
    // authentication.

    Logger.info(() => "New Password required received.");
    this.setStateSafely({showNewPasswordPopup: true, cognitoUser});
    UIUtils.hideLoadingImage();
  }

  logUserIn(user) {
    Logger.info(() => "Received successful login: " + UIUtils.stringify(user));
    Logger.info(() => "Attributes: " + UIUtils.stringify(user.attributes));
    UIUtils.recordSuccessfulLogin(user, user.attributes);
  }

  /**
   * This is tightly integrated but separated out from the handleUserLogin handler above so that it can be retried.
   */
  async attemptLogin(username, password) {
    const {t} = this.props;
    try {
      let user = await Auth.signIn(username, password);
      this.setStateSafely({cognitoUser: user});

      if (this.newPasswordPopup) {
        $(this.newPasswordPopup).modal("hide");
      }

      // handle Cognito challenges
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.newPasswordRequired(user);
        return;
      }
      else if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
        UIUtils.clearError();
        this.setStateSafely({isMfaRequired: true});
        UIUtils.hideLoadingImage();
        return;
      }
      else if (user.challengeName === "MFA_SETUP" || this.state.forceMfa) {
        await this.handleMfaSetup(user);
        UIUtils.clearError();
        UIUtils.hideLoadingImage();
        return;
      }

      this.logUserIn(user);
    } catch (error) {
      Logger.info(() => "Error received. Maybe retryable:", Log.error(error));
      if (error.code === "PasswordResetRequiredException") {
        try {
          const data = await Auth.forgotPassword(username);
          // successfully initiated reset password request
          Logger.info(() => "CodeDeliveryData from forgotPassword: " + JSON.stringify(data));
          window.location.href = UIUtils.getSecuredURL(`./users/resetPassword.html?username=${encodeURIComponent(username)}`);
        } catch (error) {
          this.handleLoginError(error);
          throw error;
        }
      } else if (CognitoHelper.isCognitoErrorRetryable(error)) {
        Logger.warn(() => "Retrying because of " + UIUtils.stringify(error));
        throw new RetryPleaseError();
      } else {
        if (["NotAuthorizedException", "InvalidParameterException"].includes(error.code)) {
          const errorMessage = t("Incorrect username or password");
          this.handleLoginError(errorMessage);
        } else {
          this.handleLoginError(error);
        }
      }
    }
  }

  async handleMfaSetup(user) {
    const mfaSecret = await Auth.setupTOTP(user);
    this.setStateSafely({
      mfaSecret: mfaSecret,
    });
  }

  async handleMfaSubmission(e, action, passedCode) {
    e.preventDefault();
    UIUtils.showLoadingImage();
    let {cognitoUser: user, mfaCode} = this.state;
    let code = passedCode || mfaCode;

    try {
      if (!code || code.length !== 6) {
        throw new Error(`Invalid authentication code. Please make sure you enter exactly 6 digits.`);
      }
      if (action === "setup") {
        await Auth.verifyTotpToken(user, code);
        await Auth.setPreferredMFA(user, "TOTP");
      }
      else if (action === "verify") {
        user = await Auth.confirmSignIn(user, code, "SOFTWARE_TOKEN_MFA");
      }
      this.logUserIn(user);
    } catch (error) {
      this.handleLoginError(error);
    }
  }

  componentDidMount() {
    super.componentDidMount();
    const {t} = this.props;

    document.title = t("User login to QbDVision");

    const reason = UIUtils.getParameterByName("reason");
    const externalLoginError = sessionStorage["externalLoginError"];
    let error;

    if (reason === "Terms") {
      error = t("You must accept the terms and conditions to access this site.");
    } else if (reason === "Expired") {
      error = t("Your session timed out.  Please login again.");
    } else if (reason === "UserDisabled") {
      error = t(CommonSecurity.USER_DISABLED_MESSAGE);
    } else if (reason === "CognitoDeveloperError") {
      error = t(CommonSecurity.COGNITO_DEVELOPER_ERROR);
    } else if (externalLoginError) {
      error = externalLoginError;
      sessionStorage["externalLoginError"] = "";
    }

    if (error) {
      this.setStateSafely({error});
    }
  }

  handleChange(event) {
    this.setStateSafely({[event.target.name]: event.target.value});
  }

  handleHistoryChange(event) {
    let {state} = event;
    const stepValue = (state && state.step) || UIUtils.getParameterByName("step");
    let step = UIUtils.isInteger(stepValue) ? UIUtils.parseInt(stepValue) : 0;
    let stateChange = null;
    let shouldClearHistory = false;

    if (step === 0 || !state) {
      UIUtils.showLoadingImage();
      stateChange = {
        error: null,
        step: 0,
        hasValidEmail: false,
        username: "",
        password: "",
        mfaSecret: "",
        cognitoUser: null,
        isMfaRequired: false,
      };
      $("#usernameInput").val("");
      $("#passwordInput").val("");
      shouldClearHistory = true;
    } else if (step !== this.state.step) {
      UIUtils.showLoadingImage();
      let error = (state && state.error) || null;

      stateChange = {
        ...(state || {}),
        step: step,
        error,
      };
    } else {
      this.initialize();
    }

    if (stateChange) {
      this.setStateSafely(stateChange, () => {
        if (shouldClearHistory) {
          this.clearHistory(window.location.pathname);
        }
        UIUtils.hideLoadingImage();
      });
    }
  }

  handleLoginError(error) {
    const stepValue = this.state.step;
    let step = UIUtils.isInteger(stepValue) ? UIUtils.parseInt(stepValue) : 0;

    if (typeof error === "string") {
      error = new Error(error);
      error.isValidation = true;
    }

    error.username = this.state.username;
    error.identityProvider = this.state.identityProvider;
    error.hasValidEmail = this.state.hasValidEmail;

    UIUtils.setHideLoadingOnAjaxStop(true);
    UIUtils.hideLoadingImage();

    const stateChange = {step};
    Logger.error(() => "Login error", Log.error(error));
    this.setStateSafely({
      ...stateChange,
      error: error,
    }, () => {
      UIUtils.pushHistoryURLWithParameterChanges(this.state, stateChange, {step: step === 0});
    });
  }

  handleCheckValidEmail(event) {
    event.preventDefault();

    UIUtils.clearError();
    UIUtils.setHideLoadingOnAjaxStop(false);
    UIUtils.showLoadingImage();

    const {t} = this.props;
    const {username} = this.state;

    UIUtils.secureAjaxGET(`users/userAPI?activity=checkLoginFlow&username=${encodeURIComponent(username)}`,
      null, true, this.handleLoginError).done(user => {
      const {userExists, identityProvider, forceMfa} = user;

      if (!userExists) {
        const error = new Error(t("Email/Username is incorrect"));
        error.doNotSendStackTrace = true;
        error.isValidation = true;
        this.setStateSafely({
          step: 0,
          hasValidEmail: false,
        }, () => this.handleLoginError(error));
      } else if (identityProvider) {
        this.setStateSafely({
          identityProvider,
          error: null,
          hasValidEmail: true,
          step: 3,
        }, () => {
          const historyState = {
            ...this.state,
          };
          delete historyState.password;

          UIUtils.pushHistoryURLWithParameterChanges(historyState, {step: historyState.step});

          Cookies.set("EMAIL", username);
          Cookies.set("IDENTITY_PROVIDER", identityProvider);

          Auth.federatedSignIn({provider: identityProvider}).catch(error => this.handleLoginError(error));
        });
      } else {
        Cookies.remove("IDENTITY_PROVIDER", identityProvider);

        this.setStateSafely({
          hasValidEmail: true,
          error: null,
          step: 2,
          forceMfa: forceMfa,
        }, () => {
          const historyState = {
            ...this.state,
          };
          delete historyState.password;

          UIUtils.pushHistoryURLWithParameterChanges(historyState, {step: historyState.step});
          UIUtils.setHideLoadingOnAjaxStop(true);
          UIUtils.hideLoadingImage();
        });
      }
    }).fail(
      (error) => {
        this.setStateSafely({
          hasValidEmail: false,
          step: 0,
        }, () => this.handleLoginError(error));
      }
    );
  }

  render() {
    const {t} = this.props;
    const {
      hasValidEmail,
      accessInformation,
      showNewPasswordPopup,
      showNewPinPopup,
      cognitoUser,
      step,
      error,
      isMfaRequired,
      mfaSecret,
      username
    } = this.state;

    if (error) {
      this.showErrorMessage(error);
    } else {
      UIUtils.clearError();
    }

    const showPasswordField = step === 2;
    const isMfaSetupStep = !!mfaSecret;
    const stepHeader = isMfaSetupStep ? "mfaSetup" : isMfaRequired ? "mfaSubmission" : "defaultHeader";
    const showCreateCompanyButton = !CommonUtils.isCommercialEnvironment();
    const mfaSubmission =
      <>
        <p className="form-group">
          Enter the code generated by your authenticator app
        </p>
        <div className="form-group">
          <input
            autoFocus
            className="form-control"
            name="mfaCode"
            id="mfaInput"
            data-error={t("MFA code is incorrect.")}
            data-required-error={t("Please enter a valid authentication code")}
            inputMode="numeric"
            onChange={this.handleChange}
            placeholder={t("Enter Authentication Code")}
            required={true}
            title={"Please enter the code generated by your authenticator app."}
            type="number"
          />
        </div>
        <br />
        <div className="form-group">
          <Button onClick={e => {
            this.handleMfaSubmission(e, "verify");
          }}
                  id="mfaSubmitButton"
                  label={t("Verify")}
                  isSubmit
                  isFullWidth
                  isDisabled={!this.state.mfaCode}
          />
        </div>
      </>;

    return (
      <div>
        <div className="container-fluid">
          <CompanyLoginHeader firstHeader={<Trans t={t}>{HEADERS[stepHeader]}</Trans>} customLogoSize={isMfaSetupStep ? 128 : null}/>
          <br />
          <div className={isMfaSetupStep ? "center-double-column-grid" : "center-single-column-grid"}>
            <div className="row">
              <div className="col-sm-12">
                <ErrorBar className={"error-bar login-error-bar"} />
                { mfaSecret ? <MFASetup mfaSecret={mfaSecret} handleMfaSubmission={this.handleMfaSubmission} username={username}/>
                  : isMfaRequired ? mfaSubmission :
                  <form data-toggle="validator" role="form" id="loginForm" onSubmit={this.handleCheckValidEmail}>
                    <div className="form-group">
                      <input type="text"
                             className="form-control"
                             placeholder={t("Email / Username")}
                             name="username"
                             id="usernameInput"
                             data-required-error={t("An email or username is required")}
                             autoComplete="username"
                             required={true}
                             disabled={showPasswordField}
                             onChange={this.handleChange}
                      />
                      <div className="help-block with-errors" />
                    </div>
                    <>
                      {/*
                    For password helpers just as LastPass to work fine,
                    the password field must be present in the DOM, but hidden.
                    */}
                        <div className={`form-group ${showPasswordField ? "" : "d-none"}`}>
                          <input type="password"
                                 placeholder={t("Password")}
                                 className="form-control"
                                 name="password"
                                 id="passwordInput"
                                 autoComplete="current-password"
                                 data-minlength={8}
                                 data-error={t("Password is incorrect. See password requirements above.")}
                                 required={showPasswordField}
                                 disabled={isMfaRequired}
                                 onChange={this.handleChange}
                          />
                          <div className="help-block with-errors" />
                        </div>
                        <div className={`form-group ${showPasswordField ? "" : "d-none"}`}>
                          <a id="forgotPasswordLink" href="./users/forgotPassword.html">{t("Forgot Password")}</a>
                        </div>
                      </>
                      <br />
                      {hasValidEmail ?
                        <div className="form-group">
                          <Button onClick={this.handleUserLogin}
                                  id="loginButton"
                                  label={t("Login")}
                                  isSubmit
                                  isFullWidth
                          />
                        </div> :
                        <div className="form-group">
                          <Button id={"nextButton"}
                                  label={t("Next")}
                                  isSubmit
                                  isFullWidth
                          />
                        </div>
                      }
                      {showCreateCompanyButton &&
                        <div id="createCompanyButton" className="form-group">
                          <p className="text-center loginPageSeparator-text">{t("or")}</p>
                          <p className="text-center loginPageLinks-text">
                            <a href="./users/createCompany.html">{t("Create Company")}</a>
                          </p>
                        </div>}
                    </form>
                }</div>
            </div>
          </div>
        </div>
        {showNewPasswordPopup ?
          <UserNewPasswordPopup modalRef={newPasswordPopup => this.newPasswordPopup = newPasswordPopup}
                                onHideModal={this.hidePasswordModal}
                                cognitoUser={cognitoUser}
                                id="userNewPasswordPopup"
          /> : ""}
        {showNewPinPopup ?
          <UserNewSigningPinPopup modalRef={newPinPopup => this.newPinPopup = newPinPopup}
                                  onHideModal={this.hidePinModal}
                                  accessInformation={accessInformation}
                                  id="UserNewSigningPinPopup"
          /> : ""}
        <div className="footer-login">
          <FooterBar />
        </div>
      </div>
    );
  }

  showErrorMessage(error) {
    if (typeof error === "string") {
      error = new Error(error);
      error.isValidation = true;
    }

    const {username} = this.state;

    error.username = username;
    error.cognitoUser = username;
    UIUtils.defaultFailFunction(error);
  }
}

export default I18NWrapper.wrap(UserLogin, "users");
// i18next-extract-mark-ns-stop users
