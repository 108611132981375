"use strict";

import React from "react";
import TableEditorBase from "./table_editor_base";

/**
 *  This shows the text box used in the review table.
 */
export default class TableTextBox extends TableEditorBase {
  constructor(props) {
    super(props);
  }

  renderInput() {
    let {value, id, required, onChange, pattern} = this.props;

    return (
      <input id={id}
             type="text"
             onChange={onChange}
             defaultValue={value}
             pattern={pattern}
             className="form-control document-bulk-add-validation-editor-input"
             required={required} />
    );
  }
}
