var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { NumericTextBox } from '../main';
import HexInput from './HexInput';
import { Button } from '@progress/kendo-react-buttons';
import { Label } from '@progress/kendo-react-labels';
import { registerForIntl, registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { messages, colorGradientR, colorGradientHex, colorGradientA, colorGradientB, colorGradientG, colorGradientToggleInputsButton } from '../messages';
/**
 * @hidden
 */
var modes = ['rgba', 'rgb', 'hex'];
/**
 * @hidden
 */
var ColorInput = /** @class */ (function (_super) {
    __extends(ColorInput, _super);
    function ColorInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            inputMode: modes[1]
        };
        _this.onRgbaRChange = function (event) {
            _this.dispatchRgbaChange({ r: event.value }, event);
        };
        _this.onRgbaGChange = function (event) {
            _this.dispatchRgbaChange({ g: event.value }, event);
        };
        _this.onRgbaBChange = function (event) {
            _this.dispatchRgbaChange({ b: event.value }, event);
        };
        _this.onRgbaAChange = function (event) {
            _this.dispatchRgbaChange({ a: event.value }, event);
        };
        return _this;
    }
    ColorInput.prototype.render = function () {
        var localizationService = provideLocalizationService(this);
        var hexMessage = localizationService.toLanguageString(colorGradientR, messages[colorGradientHex]);
        var rMessage = localizationService.toLanguageString(colorGradientR, messages[colorGradientR]);
        var gMessage = localizationService.toLanguageString(colorGradientR, messages[colorGradientG]);
        var bMessage = localizationService.toLanguageString(colorGradientR, messages[colorGradientB]);
        var aMessage = localizationService.toLanguageString(colorGradientR, messages[colorGradientA]);
        var toggleButtonMessage = localizationService.toLanguageString(colorGradientToggleInputsButton, messages[colorGradientToggleInputsButton]);
        return (React.createElement("div", { className: "k-colorgradient-inputs k-hstack" },
            React.createElement("div", { className: "k-vstack" },
                React.createElement(Button, { "aria-label": toggleButtonMessage, fillMode: 'flat', icon: 'caret-alt-expand', className: "k-colorgradient-toggle-mode k-icon-button", onClick: this.onToggleModeChange.bind(this) })),
            this.state.inputMode === 'hex' &&
                React.createElement("div", { className: "k-vstack k-flex-1" },
                    React.createElement("span", { className: "k-hex-value k-textbox k-input" },
                        React.createElement(HexInput, { hex: this.props.hex, onHexChange: this.props.onHexChange, disabled: this.props.disabled })),
                    React.createElement(Label, { className: "k-colorgradient-input-label" }, hexMessage)),
            (this.state.inputMode === 'rgb' || this.state.inputMode === 'rgba') &&
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "k-vstack" },
                        React.createElement(NumericTextBox, { inputType: 'number', value: this.props.rgba.r, ariaLabel: String(this.props.rgba.r), min: 0, max: 255, spinners: false, format: "n", onChange: this.onRgbaRChange, disabled: this.props.disabled }),
                        React.createElement(Label, { className: "k-colorgradient-input-label" }, rMessage)),
                    React.createElement("div", { className: "k-vstack" },
                        React.createElement(NumericTextBox, { inputType: 'number', value: this.props.rgba.g, ariaLabel: String(this.props.rgba.g), min: 0, max: 255, spinners: false, format: "n", onChange: this.onRgbaGChange, disabled: this.props.disabled }),
                        React.createElement(Label, { className: "k-colorgradient-input-label" }, gMessage)),
                    React.createElement("div", { className: "k-vstack" },
                        React.createElement(NumericTextBox, { inputType: 'number', value: this.props.rgba.b, ariaLabel: String(this.props.rgba.b), min: 0, max: 255, spinners: false, format: "n", onChange: this.onRgbaBChange, disabled: this.props.disabled }),
                        React.createElement(Label, { className: "k-colorgradient-input-label" }, bMessage))),
            this.state.inputMode === 'rgba' &&
                React.createElement("div", { className: "k-vstack" },
                    this.props.opacity &&
                        React.createElement(NumericTextBox, { inputType: 'number', value: this.props.rgba.a, ariaLabel: String(this.props.rgba.a), min: 0, max: 1, step: 0.01, spinners: false, format: "n2", onChange: this.onRgbaAChange, disabled: this.props.disabled }),
                    this.props.opacity && (React.createElement(Label, { className: "k-colorgradient-input-label" }, aMessage)))));
    };
    ColorInput.prototype.dispatchRgbaChange = function (newValue, event) {
        var rgba = __assign({}, this.props.rgba);
        if (newValue.r !== undefined) {
            rgba.r = newValue.r;
        }
        if (newValue.g !== undefined) {
            rgba.g = newValue.g;
        }
        if (newValue.b !== undefined) {
            rgba.b = newValue.b;
        }
        if (newValue.a !== undefined) {
            rgba.a = newValue.a;
        }
        this.props.onRgbaChange(rgba, event);
    };
    ;
    ColorInput.prototype.onToggleModeChange = function () {
        var index = modes.length - 1 === modes.indexOf(this.state.inputMode) ? 0 : modes.indexOf(this.state.inputMode) + 1;
        if (!this.props.opacity) {
            var nextIndex = modes[index] === 'rgba' ? index + 1 : index;
            this.setState({ inputMode: modes[nextIndex] });
        }
        else {
            this.setState({ inputMode: modes[index] });
        }
    };
    return ColorInput;
}(React.Component));
export default ColorInput;
registerForIntl(ColorInput);
registerForLocalization(ColorInput);
