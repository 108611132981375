"use strict";

import React from "react";
import ReactDOM from "react-dom";
import ContextButtonBuilder from "./context_button_builder";

/*
  * This class is responsible for assembling all of the GoJS parts to provide the meatball button that shows the
  * context menu in the process explorer. See also OptionsMenuBuilder that builds the data for the menu that drops down.
  *
  * The basics of this were originally borrowed from: https://gojs.net/latest/extensions/Buttons.js
 */
export default class ParentContextButtonBuilder extends ContextButtonBuilder {
  /**
   *                           on and the second arg is true for a left click and false for a right click.
   *                                      parameter is an entry in ACTION_TO_ICON_ENUM that tells you which option was
   *                                      clicked on.
   *                                      UIPermissions.generateTooltip())
   * @param goJSButtonName {string} The name to use when registering this button with GoJS.
   * @param diagramFacade {{}} The DiagramFacade we're rendering to.
   * @param diagram {{}} The GoJS diagram we're rendering to.
   * @param onClick {function} A function that is called on either left or right click. The first arg is the node clicked
   * @param t {function} For translation
   * @param projectId {int} The id of the project.
   * @param processId {int} The id of the process.
   * @param onContextMenuClick {function} A function that is called when the user clicks in the context menu. The first
   * @param onCheckPermissions {function} A function called when checking for permissions (see UIPermissions.can() and
   */
  constructor(goJSButtonName, diagramFacade, diagram, onClick, t, projectId, processId, onContextMenuClick, onCheckPermissions) {
    super(goJSButtonName, diagramFacade, diagram, onClick, t, projectId, processId, onContextMenuClick, onCheckPermissions);
    // TODO: Make this only show up when hovering over the parent
  }

  /**
   * Override this to decide if the button should be shown to the user on a given node.
   *
   * @return {boolean}
   */
  isVisible(node) {
    return !!(node.isSelected || node.data.showParentContextMenuButton);
  }

  annotateEvent(event) {
    event.isParent = true;
    return event;
  }
}


