"use strict";

import { RiskMapObjectVersionChangeTracker } from "../../../risk_map_reports/risk_map_object_version_change_tracker";

/**
 * To track the CQA object version changes for visual display on CQA Report.
 */
export class CQAObjectVersionChangeTracker extends RiskMapObjectVersionChangeTracker {

  trackVersionChanges(objectVersion, previousObjectVersion, changes, uoVersionsMap, stepVersionsMap) {
    super.trackVersionChanges(objectVersion, previousObjectVersion, changes, uoVersionsMap, stepVersionsMap);

    if (previousObjectVersion.supportDocuments !== objectVersion.supportDocuments) {
      changes.changesDescription = changes.changesDescription + `modified support documents`;
    }
    if (previousObjectVersion.processParameterType !== objectVersion.processParameterType) {
      changes.changesDescription = changes.changesDescription + `process param type`;
    }
    if (previousObjectVersion.controlMethods !== objectVersion.controlMethods) {
      changes.changesDescription = changes.changesDescription + `modified control methods`;
    }
    if (previousObjectVersion.controlStrategy !== objectVersion.controlStrategy) {
      changes.changesDescription = changes.changesDescription + `modified control strategy`;
    }
  }
}