"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import { can, generateTooltip } from "../../utils/ui_permissions";
import CommonSecurity from "../../../server/common/generic/common_security";
import BaseReactComponent from "../../base_react_component";

/**
 * This shows the drag and drop area for empty state and documents list.
 */
export default class DragAndDropPanel extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.dragEnterVsLeaveCounter = 0; // Learn more about this here: https://stackoverflow.com/a/21002544/491553
  }

  handleDragEnter(e) {
    UIUtils.ignoreHandler(e);
    this.dragEnterVsLeaveCounter++;
    $("#filesDragAndDrop").addClass("highlight-for-dnd");
  }

  handleDragOver(e) {
    UIUtils.ignoreHandler(e);
    $("#filesDragAndDrop").addClass("highlight-for-dnd");
  }

  handleDragLeave(e) {
    UIUtils.ignoreHandler(e);
    this.dragEnterVsLeaveCounter--;
    if (this.dragEnterVsLeaveCounter === 0) {
      $("#filesDragAndDrop").removeClass("highlight-for-dnd");
    }
  }

  handleDrop(e) {
    UIUtils.ignoreHandler(e);
    $("#filesDragAndDrop").removeClass("highlight-for-dnd");
    let files = e.dataTransfer && e.dataTransfer.files;

    if (files.length > 0) {
      if (can(CommonSecurity.Actions.BULK_ADD, CommonSecurity.Types.DOCUMENT)) {
        this.props.onFilesSelection(null, Array.from(files));
      } else {
        UIUtils.showError(generateTooltip(CommonSecurity.Actions.BULK_ADD, CommonSecurity.Types.DOCUMENT));
      }
    }
  }

  render() {
    const {
      children,
    } = this.props;

    return (
      <div id="filesDragAndDrop"
           onDragEnter={this.handleDragEnter}
           onDragOver={this.handleDragOver}
           onDragLeave={this.handleDragLeave}
           onDrop={this.handleDrop}
      >
        {children}
      </div>
    );
  }
}

