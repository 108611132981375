import moment from "moment";
import {CommonString} from "../../../server/common/generic/common_string";
import {EditorView} from "@progress/kendo-editor-common";
import RiskUtils from "../../../server/common/misc/common_risk_utils";

/**
 * Get all attributes of an element
 * @param node {Element}
 * @return {string:string}
 */
export function getAttributes(node: HTMLElement): Record<string, string> {
  const attributes = {};
  for (const attributeName of node.getAttributeNames()) {
    attributes[attributeName] = node.getAttribute(attributeName);
  }
  return attributes;
}

/**
 * Merge two objects
 * @param target {Object}
 * @param source {Object}
 */
export function merge(target: object, source: object) {
  for (const [key, value] of Object.entries(source)) {
    if (!target[key]) {
      target[key] = value;
      continue;
    }

    if (Array.isArray(value)) {
      target[key] = Array.from(new Set([...target[key], ...value]));
    } else {
      merge(target[key], value);
    }
  }
}

/**
 * Set error text for an element with id
 * @param elementId
 * @param errorText
 */
export function setErrorText(elementId: string, errorText: string) {
  const element = document.querySelector<HTMLElement>(`#${elementId}`);
  if (element) {
    element.innerText = errorText;
  }
}

/**
 * Find the position for a given node
 * @param view
 * @param target
 * @param callback
 * @returns any
 */
// eslint-disable-next-line no-unused-vars
export function findNodePosition(
  view: EditorView,
  target: any,
  // eslint-disable-next-line no-unused-vars
  callback: (pos: number) => void,
) {
  if (!view || !target) {
    return callback(-1);
  }

  view.state.doc.descendants((child, pos) => {
    if (target.eq(child)) {
      return callback(pos);
    }
  });

  return callback(-1);
}

/**
 * Set attributes for an element from a target element
 * @param source
 * @param target
 * @return {HTMLElement}
 */
export function setElementAttributes(
  source: HTMLElement,
  target: HTMLElement,
): HTMLElement {
  for (const attribute of target.getAttributeNames()) {
    source.setAttribute(attribute, target.getAttribute(attribute));
  }
  return source;
}

/**
 * Download a file with data uri
 *
 * @param dataUri {string}
 * @param fileName {string}
 */
export function downloadFile(dataUri: string, fileName: string) {
  const element = document.createElement("a");
  element.setAttribute("href", dataUri);
  element.setAttribute("download", fileName);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

/**
 * Get all values of a field from an object or list of objects
 * @param records {any}
 * @param field {string}
 * @param visitedKeys
 * @returns {any[]}
 */
export function getFieldValuesFromRecords<T>(
  records: Array<any> | object,
  field: string,
  visitedKeys = new Set<string>(),
): Array<T> {
  const values = [];
  if (!records) {
    return values;
  }

  if (Array.isArray(records)) {
    for (const record of records) {
      values.push(...getFieldValuesFromRecords(record, field, visitedKeys));
    }
    return values;
  }

  for (const key of Object.keys(records)) {
    // This field is for accessing upper scope, so we can skip it
    if (key === "parentData") {
      continue;
    }

    if (key === field) {
      values.push(records[key]);
    }

    if (visitedKeys.has(key)) {
      continue;
    }

    if (typeof records[key] === "object") {
      visitedKeys.add(key);
      values.push(
        ...getFieldValuesFromRecords(records[key], field, visitedKeys),
      );
    }
  }

  return values;
}

/**
 * Check the document content data has changed. If it changed, we will
 * return true
 * @param currentData
 * @param previousData
 * @returns {boolean}
 */
export function isSmartContentChanged(
  currentData: string,
  previousData: string,
): boolean {
  if (!currentData && !previousData) {
    return false;
  }

  if (!currentData || !previousData) {
    return true;
  }

  return currentData.toString() !== previousData.toString();
}

export function createSmartContentHash(
  documentContent: string,
  fields: Record<string, any>,
  smartContent: Record<string, any>,
  level = 1,
) {
  let tmpString = "";

  for (const [key, value] of Object.entries(fields)) {
    /* When we propose a document record when we create it, the document doesn't have an id.
       We need to skip comparing the id field in this case, so it doesn't give a false notification.
     */
    if (
      key === "id" &&
      smartContent.modelType === "Document" &&
      !smartContent[key]
    ) {
      continue;
    }

    if (key === "attributes") {
      for (const attribute of value) {
        tmpString = tmpString.concat(
          smartContent[attribute] ? smartContent[attribute].toString() : "",
        );
      }
      continue;
    }

    if (smartContent[key] && typeof smartContent[key] === "object") {
      const dataKey = smartContent[key] ? key : `${key}s`;
      if (smartContent[dataKey] && Array.isArray(smartContent[dataKey])) {
        const records = smartContent[dataKey].sort();

        for (let i = 0; i < records.length; i++) {
          tmpString = tmpString.concat(
            createSmartContentHash(null, value, records[i], level + 1),
          );
        }
      } else {
        tmpString = tmpString.concat(
          createSmartContentHash(null, value, smartContent[key], level + 1),
        );
      }
    } else {
      tmpString = tmpString.concat(
        smartContent[key] ? smartContent[key].toString() : "",
      );
    }
  }

  return level === 1
    ? CommonString.cyrb53(tmpString.concat(documentContent))
    : tmpString;
}

/**
 * Get max date from a list of dates
 * @param dates {String[]}
 * @returns {moment.Moment}
 */
export function getMaxDate(dates) {
  const moments = dates.map((date) => moment(date));
  return moment.max(moments);
}

/**
 * Reconstructs the tree like hierarchical widget data combining the tree structure
 * of ids coming from the backend and the maps of records Ids to records for each
 * different model. Each id in the tree is replaced with the respective data from
 * the model maps. Eventually the whole tree is reconstructed and contains the full
 * information of records.
 * @param treeData A hierarchical structure replicating the structure of
 * the widgets in the document template.
 * @param listDataMap A map of models to records
 * @param modelName
 * @param parentData
 * @returns {any}
 */
export function reconstructSmartContent(
  treeData: any,
  listDataMap: Record<string, any>,
  modelName?: string,
  parentData?: any,
): any {
  // If treeData is an array, we want to reconstruct each row in it
  if (Array.isArray(treeData)) {
    return treeData.map((recordRow) =>
      reconstructSmartContent(recordRow, listDataMap, modelName, parentData),
    );
  } else {
    // Else if the treeData is an object, we want to reconstruct every property
    // of that object which is a model in the data models map.
    for (let childModelName of Object.keys(treeData).filter(
      (key) => listDataMap[key],
    )) {
      treeData[childModelName] = reconstructSmartContent(
        treeData[childModelName],
        listDataMap,
        childModelName,
        modelName && listDataMap[modelName]
          ? {...listDataMap[modelName][treeData.id], modelName, parentData}
          : null,
      );
    }
  }

  // Setting related record xQA/kPA for a risk link
  const COMMON_EDITABLES = [
    "ProcessParameter",
    "MaterialAttribute",
    "IQA",
    "IPA",
    "FQA",
    "FPA",
  ];
  if (COMMON_EDITABLES.includes(modelName)) {
    const record = listDataMap[modelName][treeData.id];
    const riskModels = RiskUtils.RISK_MODELS.filter(
      (riskModel) => riskModel.isRiskLink && riskModel.from === modelName
    );
    for (const riskModel of riskModels) {
      let riskLinkName = `${riskModel.from}To${riskModel.to}s`;
      if (riskModel.to === "GA") {
        riskLinkName = `${riskModel.model}s`;
      }
      const riskLinks = record[riskLinkName];
      if (!riskLinks) {
        continue;
      }

      for (const riskLink of riskLinks) {
        const riskModelToName = riskModel.to === "GA" ? "GeneralAttribute" : riskModel.to;
        if (
          riskLink &&
          listDataMap[riskModelToName] &&
          listDataMap[riskModelToName][riskLink[`${riskModelToName}Id`]]
        ) {
          const toRecord = listDataMap[riskModelToName][riskLink[`${riskModelToName}Id`]];
          riskLink[riskModelToName] = cleanToRecordForRiskLink({...toRecord});
        }
      }
    }
  }

  // Eventually, we return an object that consists of the treeData object itself
  // and the record from the list data map. The treeData object contains model
  // related information, like for example which child models the current record
  // is related with. The record coming from the listDataMap is the actual record
  // data. We also set the parentData for accessing the upper scope data
  return modelName
    ? {
      ...treeData,
      ...listDataMap[modelName][treeData.id],
      parentData: {...parentData},
    }
    : treeData;
}

/**
 * We only need to copy riskInfo and non-object field to prevent circular dependency,
 * and we only need those info for the record in risk link.
 *
 * @param record
 * @return any
 */
function cleanToRecordForRiskLink(record: any) {
  if (!record) {
    return null;
  }

  for (const key of Object.keys(record)) {
    if (key !== "riskInfo" && (typeof record[key] === "object")) {
      delete record[key];
    }
  }

  return record;
}
