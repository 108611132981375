"use strict";

import * as UIUtils from "../ui_utils";
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { RISK_TYPE_ENUM } from "../helpers/constants/constants";
import * as RiskHelper from "../helpers/risk_helper";
import { getRMPForModel } from "./rmp_helper";
import RiskTablesFilter from "../widgets/tables/risk_tables/risk_tables_filter";
import { RMP_RISK_TABLES_DISPLAY_LABEL_ENUM } from "./constants/rmp_constants";
import { RISK_LABEL_ACTION } from "../reports/constants/report_constants";
import BaseReactComponent from "../base_react_component";

/**
 * This class is responsible for rendering the RMP risk tables.
 */
export default class RMPRiskTable extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      showRiskLabel: true,
      showRawScore: true
    };

    this.riskScorePermutations = null;
    this.isInitialized = false;
  }

  componentDidMount() {
    super.componentDidMount();
    this.initializeDataTable();
    this.isInitialized = true;
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    $(this.tableRef)
      .DataTable()
      .destroy(true);
  }

  componentDidUpdate() {
    $(this.tableRef).DataTable().destroy();
    $(this.tableRef).empty();

    this.initializeDataTable();
    this.isInitialized = true;
  }

  /**
   * The special thing about this shouldComponentUpdate method is that it returns true or false as soon as it knows the
   * result, without checking if anything else has changed on the props or the state. This is mostly done for performance
   * reasons, since some of the checks in this method could be more intensive.
   * @param nextProps The next props
   * @param nextState The next state
   * @returns {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    const {modelName, visible} = this.props;
    const {showRawScore, showRiskLabel} = this.state;

    /* Return false in the following cases:
       1. The parent tab is not active.
       2. The RMP risk table is not visible
       3. Configured by type is enabled but the model name is empty or the opposite
     */
    if ((!this.props.isParentTabActive && !nextProps.isParentTabActive)
      || (!visible && !nextProps.visible)
      || (nextProps.configureByType && !nextProps.modelName)
      || (!nextProps.configureByType && nextProps.modelName)
      || !this.props.parent.state.updateRiskTables) {
      return false;
    }

    if (!this.props.rmp || !nextProps.rmp || !this.isInitialized
      || this.props.useUncertainty !== nextProps.useUncertainty
      || this.props.useDetectability !== nextProps.useDetectability
      || this.props.configureByType !== nextProps.configureByType
      || this.props.rmp.currentDiffingVersion.versionId !== nextProps.rmp.currentDiffingVersion.versionId
      || this.props.rmp.showMajorVersionsOnly !== nextProps.rmp.showMajorVersionsOnly
      || this.props.rmp.activeRiskSchemaModelTab !== nextProps.rmp.activeRiskSchemaModelTab
      || showRawScore !== nextState.showRawScore
      || showRiskLabel !== nextState.showRiskLabel
      || visible !== nextProps.visible) {
      return true;
    }

    let rmp = UIUtils.deepClone(this.props.rmp);
    let nextRmp = UIUtils.deepClone(nextProps.rmp);

    let modelRMP = getRMPForModel(rmp, modelName, this.props.parent);
    modelRMP.boundaries = RiskHelper.getRiskBoundaries(modelRMP);
    let nextModelRMP = getRMPForModel(nextRmp, nextProps.modelName, this.props.parent);
    nextModelRMP.boundaries = RiskHelper.getRiskBoundaries(nextModelRMP);

    /* Return true if any RMP field that affects the rendering of the risk tables has changed.
       The fields that can affect the rendering of an RMP are
       1. For Impact, the risk score and the always critical flag
       2. For Uncertainty, Capability and Detectability risk, the risk score
       3. For any of the Risk scales (Criticality, Process, RPN), the from, to, color, riskLabel, scoreLabel
          and alwaysCritical fields
     */
    let riskScales = [];
    let nextRiskScales = [];
    /* A local function for converting a risk scale to it's string representation, holding all fields which can affect
       the risk tables.
     */
    const riskScaleToStr = (riskScale) => {
      return typeof riskScale.riskScore !== "undefined"
        ? `${riskScale.riskScore}${(typeof riskScale.alwaysCritical !== "undefined" ? "|" + riskScale.alwaysCritical : "")}`
        : `${riskScale.from}|${riskScale.to}|${riskScale.color}|${riskScale.riskLabel}|${riskScale.scoreLabel}|${riskScale.alwaysCritical}`;
    };

    // Stringify all risk scales for this RMP and the updated one
    for (let riskType of Object.values(RISK_TYPE_ENUM)) {
      riskScales = riskScales.concat((RiskHelper.getRiskScales(riskType, modelRMP) || []).map(riskScale => riskScaleToStr(riskScale)));
      nextRiskScales = nextRiskScales.concat((RiskHelper.getRiskScales(riskType, nextModelRMP) || []).map(riskScale => riskScaleToStr(riskScale)));
    }

    // Return true if the 2 stringified values are not the same
    return riskScales.join(",") !== nextRiskScales.join(",");
  }

  initializeDataTable() {
    const {modelName, riskScale} = this.props;
    let rmp = UIUtils.deepClone(this.props.rmp);
    let modelRMP = getRMPForModel(rmp, modelName, this.props.parent);
    modelRMP.boundaries = RiskHelper.getRiskBoundaries(modelRMP);
    this.riskScorePermutations = RMPRiskTable.recalculatePermutations(rmp);

    this.table = $(this.tableRef).DataTable({
      dom: "tr",
      paging: false,
      order: [],
      class: "rmp-risk-table-flex-cell-container",
      drawCallback: () => {
        $(".dataTables_wrapper").addClass("rmp-risk-table-flex-cell-container");
      },
      data: RMPRiskTable.getPivotTableForRiskScaleAndModel(modelRMP, this.riskScorePermutations,
        riskScale, this.state.showRiskLabel ?
          RMP_RISK_TABLES_DISPLAY_LABEL_ENUM.RISK_LABEL :
          RMP_RISK_TABLES_DISPLAY_LABEL_ENUM.SCORE_LABEL,
        this.state.showRawScore),
      columns: this.getColumns(modelRMP, riskScale),
      columnDefs: this.getColumnDefs(modelRMP, riskScale),
      stateSave: false,
    });
  }

  /**
   * Recalculates the permutations for all possible raw risk scores a risk scale can have given an RPM
   * @param rmp The RMP used for the calculations
   */
  static recalculatePermutations(rmp) {
    return {
      [RISK_TYPE_ENUM.CRITICALITY]: RiskHelper.getPermutationsForRiskType(RISK_TYPE_ENUM.CRITICALITY, rmp),
      [RISK_TYPE_ENUM.PROCESS_RISK]: RiskHelper.getPermutationsForRiskType(RISK_TYPE_ENUM.PROCESS_RISK, rmp),
      [RISK_TYPE_ENUM.RPN]: RiskHelper.getPermutationsForRiskType(RISK_TYPE_ENUM.RPN, rmp),
    };
  }

  /**
   * This will return the right risk type as defined in RISK_TYPE_ENUM depending on the risk scale of the RMP risk table
   * (Criticality/Process/RPN) and the cell type it is going to be used for. For example, in the RPN RMP risk table, the
   * column headers represent process risk values, the row headers detectability risk values and the cells RMP risk values.
   * @param riskScale The RMP Risk table risk scale (Criticality/Process/RPN)
   * @param forColumn True if this is requested for a column header, false if it is for a row header, not defined if
   * this is requested for a cell.
   * @returns {string}
   */
  static getRiskType(riskScale, forColumn) {
    switch (riskScale) {
      case "Criticality":
        return forColumn === true
          ? RISK_TYPE_ENUM.IMPACT
          : forColumn === false
            ? RISK_TYPE_ENUM.UNCERTAINTY
            : RISK_TYPE_ENUM.CRITICALITY;
      case "Process":
        return forColumn === true
          ? RISK_TYPE_ENUM.CRITICALITY
          : forColumn === false
            ? RISK_TYPE_ENUM.CAPABILITY_RISK
            : RISK_TYPE_ENUM.PROCESS_RISK;
      case "RPN":
        return forColumn === true
          ? RISK_TYPE_ENUM.PROCESS_RISK
          : forColumn === false
            ? RISK_TYPE_ENUM.DETECTABILITY_RISK
            : RISK_TYPE_ENUM.RPN;
    }
  }

  /**
   * This returns the risk scores to display in the RMP risk table pivot headers. Those could be either the row
   * headers or the column headers. In the row headers we always show the raw risk score since only risk scores show
   * up there, like impact, uncertainty, capability or detectability. On the column headers on the other hand, we show
   * either the raw risk score (Impact) for the Criticality risk table or a range of values for the Process and RPN risk tables.
   * For all risk tables the risk score on the header needs to be a value among the permutations of variables making up
   * the risk score. For example, for the Criticality RMP risk table, the column header risk score is the Impact risk
   * score. In this case, the Impact scores are discrete values entered by the user and not the multiplication of other
   * factors. For this reason, we show single Impact values on the table header. For the RMP Process Risk Table on the
   * other hand, we show Criticality risk score ranges on the table column headers. The total Criticality raw risk score
   * permutations are defined by the risk levels of Impact X Uncertainty. So, for given Impact (1, 3, 5) and Uncertainty
   * (4, 6) the total risk score permutations are 4, 6, 12, 18, 20, 30. These are the only allowed values in the score
   * range we are showing on the column headers. This method relies on pre-calculated risk score permutations for each
   * risk type. It calculates the from and to values being displayed on the column headers of the Process and RPN RMP
   * risk tables, bases on those pre-calculated risk permutations.
   * @param rmp The RMP based on which all risk calculations are made
   * @param riskScorePermutations permutations for given score
   * @param riskScale The risk scale of the risk table (Criticality/Process/RPN)
   * @param forColumn true if we are retrieving the risk scores for a column header, false if we are getting those for
   * a row header
   * @returns {*}
   */
  static getRiskScoresForPivotTableHeaders(rmp, riskScorePermutations, riskScale, forColumn) {
    let riskType = RMPRiskTable.getRiskType(riskScale, forColumn);
    let riskScales = RiskHelper.getSortedRiskScales(riskType, rmp);
    let headerRawRiskScores = [];
    if (riskType === RISK_TYPE_ENUM.UNCERTAINTY
      || riskType === RISK_TYPE_ENUM.CAPABILITY_RISK
      || riskType === RISK_TYPE_ENUM.DETECTABILITY_RISK) {
      headerRawRiskScores = riskScales.map(riskScale => {
        return riskScale.riskScore;
      });
    } else if (riskType === RISK_TYPE_ENUM.IMPACT) {
      headerRawRiskScores = riskScales.map(riskScale => {
        return {from: null, to: riskScale.riskScore};
      });
    } else {
      for (let i = 0; i < riskScales.length; i++) {
        if (UIUtils.isNumber(riskScales[i].to)) {
          let fromPermutationRiskScore = RiskHelper.getRawRiskScoreFromNormalzedRiskScore(riskType, rmp, riskScales[i].from, riskScorePermutations[riskType]);
          let toPermutationRiskScore = RiskHelper.getRawRiskScoreFromNormalzedRiskScore(riskType, rmp, riskScales[i].to, riskScorePermutations[riskType]);
          fromPermutationRiskScore = headerRawRiskScores.length === 0 || (fromPermutationRiskScore + 1) > toPermutationRiskScore ?
            fromPermutationRiskScore : fromPermutationRiskScore + 1;
          let rawRiskScore = {from: fromPermutationRiskScore, to: toPermutationRiskScore};

          headerRawRiskScores.push(rawRiskScore);
        }
      }
    }

    const hasNotAssessedScale = RiskHelper.hasNotAssessedRiskScale(riskType, rmp);
    headerRawRiskScores = headerRawRiskScores.map(riskScore => {
      if (hasNotAssessedScale && riskScore.from === null && riskScore.to === null) {
        riskScore.from = 0;
        riskScore.to = 0;
      }

      return riskScore;
    });

    return headerRawRiskScores.filter(score => (forColumn && score.to !== null && typeof score.to !== "undefined")
      || (!forColumn && score !== null && typeof score !== "undefined"));
  }

  /**
   * This creates a dummy object with risk scores based on which Criticality/Process and RPN risk score calculations
   * can be made using the RiskHelper library.
   * @param rowRiskScore The risk score shown on the row header of the RMP risk table
   * @param columnRiskScore The risk score shown on the column header of the RMP risk table
   * @param cellRiskType The risk type of the cell for which risk calculation should be made. Based on this, this method
   * is aware of which risk score attributes it needs to populate on the dummy object.
   * @returns {{impact: *, uncertainty: number, capabilityRisk: *}|{impact: *, uncertainty: *}|{detectabilityRisk: *, impact: *, uncertainty: number, capabilityRisk: number}}
   */
  static createRiskObject(rowRiskScore, columnRiskScore, cellRiskType) {
    /* Setting certain risk attributes to 1 below is just a trick for the RiskHelper methods to work. This would not
       affect the risk calculations since the result of the aggregation between the risk attributes is already stored
       in the column header.
     */

    switch (cellRiskType) {
      case RISK_TYPE_ENUM.CRITICALITY:
        return {impact: columnRiskScore, uncertainty: rowRiskScore};
      case RISK_TYPE_ENUM.PROCESS_RISK:
        return {impact: columnRiskScore, uncertainty: rowRiskScore, capabilityRisk: 1};
      case RISK_TYPE_ENUM.RPN:
        return {impact: columnRiskScore, uncertainty: rowRiskScore, capabilityRisk: 1, detectabilityRisk: 1};
      default:
        throw new Error("Unsupported cell risk type in createRiskObject.");
    }
  }

  /**
   * This calculates the row values each RMP risk table shows. It uses the column header risk score and the row header
   * risk score along with the RiskHelper to calculate wither the raw or the normalized risk score we show in the table
   * cells.
   * @param rmp The RMP based on which all calculations are made.
   * @param riskScorePermutations permutation for given risk score
   * @param riskScale The risk scale of the RMP risk table (Criticality/Process/RPN)
   * @param showRiskLabel either to show risk label or score label or both
   * @param showRawScore either to show raw or percentage score
   * @returns {*}
   */
  static getPivotTableForRiskScaleAndModel(rmp, riskScorePermutations, riskScale, showRiskLabel, showRawScore) {
    let columnRiskScores = RMPRiskTable.getRiskScoresForPivotTableHeaders(rmp, riskScorePermutations, riskScale, true);
    let rowRiskScores = RMPRiskTable.getRiskScoresForPivotTableHeaders(rmp, riskScorePermutations, riskScale, false);
    let cellRiskType = RMPRiskTable.getRiskType(riskScale, null);

    // This creates the pivot table to display
    return rowRiskScores.map(rowRiskScore => {
      let row = {riskRowHeader: rowRiskScore};
      for (let columnRiskScore of columnRiskScores) {
        let tmpRiskObject = RMPRiskTable.createRiskObject(rowRiskScore, columnRiskScore.to, cellRiskType);
        let rawRiskScore = RiskHelper.getRawRiskScore(cellRiskType, rmp, tmpRiskObject);
        let riskScale = RiskHelper.getRiskScale(cellRiskType, rmp, rawRiskScore, tmpRiskObject,
          showRiskLabel === RMP_RISK_TABLES_DISPLAY_LABEL_ENUM.RISK_LABEL);

        let displayValue = showRawScore
          ? rawRiskScore
          : RiskHelper.getNormalizedRiskScore(cellRiskType, rmp, rawRiskScore) + "%";

        if (riskScale) {
          displayValue += this.getTableDisplayLabel(showRiskLabel, riskScale);
        }

        row[columnRiskScore.to] = {
          rawRiskScore: rawRiskScore,
          riskObject: tmpRiskObject,
          displayValue
        };
      }
      return row;
    });
  }

  /**
   * This function gets column title from a risk score
   * @param columnRiskScore risk score for that column
   * @returns {string} title
   */
  static getColumnTitle(columnRiskScore) {
    return columnRiskScore.from !== null
      ? `${columnRiskScore.from.toString()} - ${columnRiskScore.to.toString()}`
      : columnRiskScore.to.toString();
  }

  /**
   * This gets the column headers of the RMP risk table
   * @param rmp The RMP based on which all calculations are made.
   * @param riskScale The risk scale of the RMP risk table (Criticality/Process/RPN)
   * @returns {{data: (function(*): string), orderable: boolean, title: string}[]}
   */
  getColumns(rmp, riskScale) {
    let columnRiskScores = RMPRiskTable.getRiskScoresForPivotTableHeaders(rmp, this.riskScorePermutations, riskScale, true);

    let columns = [
      {
        title: "",
        orderable: false,
        data: (result) => result.riskRowHeader ? result.riskRowHeader : ""
      }
    ];

    return columns.concat(columnRiskScores.map(columnRiskScore => {
      return {
        title: RMPRiskTable.getColumnTitle(columnRiskScore),
        orderable: false,
        render: $.fn.dataTable.render.text(),
        data: (result) => result[columnRiskScore.to] ? result[columnRiskScore.to].displayValue : ""
      };
    }));
  }

  /**
   * This gets the columnDefs for the RMP risk table
   * @param rmp The RMP based on which all calculations are made.
   * @param riskScale The risk scale of the RMP risk table (Criticality/Process/RPN)
   * @returns {*[]}
   */
  getColumnDefs(rmp, riskScale) {
    let columnDefinitions = [];
    let columnRiskScores = RMPRiskTable.getRiskScoresForPivotTableHeaders(rmp, this.riskScorePermutations, riskScale, true);
    let cellRiskType = RMPRiskTable.getRiskType(riskScale, null);
    let counter = 0;

    columnDefinitions.push({
      targets: counter++,
      orderable: false,
      class: "rmp-risk-table-row-header-cell",
      width: 1,
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render((<span>{rowData.riskRowHeader}</span>), td);
      }
    });

    columnDefinitions = columnDefinitions.concat(columnRiskScores.map(columnRiskScore => {
      return {
        targets: counter++,
        orderable: false,
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render((<span>{rowData[columnRiskScore.to].displayValue}</span>), td);
          $(td).addClass(RiskHelper.getTableCellClassFromRiskScore(cellRiskType, rmp, rowData[columnRiskScore.to].rawRiskScore,
            rowData[columnRiskScore.to].riskObject, !!this.state.showRiskLabel));
        }
      };
    }));

    return columnDefinitions;
  }

  handleRiskCoreTypeChange(value) {
    this.setStateSafely({
      showRawScore: value === "showRawScore"
    });
  }

  handleShowRiskLabelChange(value) {
    this.setStateSafely({
      showRiskLabel: value === RISK_LABEL_ACTION.SHOW_RISK_LABEL
    });
  }

  getColumnsHeaderTitle(riskScale) {
    switch (riskScale) {
      case "Criticality":
        return "Impact/Severity";
      case "Process":
        return "Criticality";
      case "RPN":
        return "Process Risk";
    }
    return null;
  }

  getRowsHeaderTitle(riskScale) {
    switch (riskScale) {
      case "Criticality":
        return "Uncertainty/Likelihood";
      case "Process":
        return "Capability Risk/Occurrence";
      case "RPN":
        return "Detectability Risk";
    }
    return null;
  }

  static getTableDisplayLabel(showRiskLabel, riskScale) {
    switch (showRiskLabel) {
      case RMP_RISK_TABLES_DISPLAY_LABEL_ENUM.RISK_LABEL:
        return ` (${riskScale.riskLabel})`;
      case RMP_RISK_TABLES_DISPLAY_LABEL_ENUM.SCORE_LABEL:
        return ` (${riskScale.scoreLabel})`;
      case RMP_RISK_TABLES_DISPLAY_LABEL_ENUM.BOTH:
        return ` (${riskScale.riskLabel} / ${riskScale.scoreLabel})`;
      default:
        return "";
    }
  }

  render() {
    const {riskScale, id} = this.props;
    const {showRawScore, showRiskLabel} = this.state;

    return (
      <Fragment>
        <div className="row">
          <RiskTablesFilter
            id={id}
            showRawScore={showRawScore}
            showRiskLabel={showRiskLabel}
            onRiskValueTypeChange={this.handleRiskCoreTypeChange}
            onRiskLabelTypeChange={this.handleShowRiskLabelChange}
          />
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div id="RMPRiskTableContainer"
                 className="rmp-risk-table-flex-cell-container"
            >
              <div id="RMPRiskTableContainerColumnHeader">
                {this.getColumnsHeaderTitle(riskScale)}
              </div>
              <div className="rmp-risk-table-container-row-header-panel">
                <div id="RMPRiskTableContainerRowHeader">
                  {this.getRowsHeaderTitle(riskScale)}
                </div>
                <div className="rmp-risk-table-matrix-container rmp-risk-table-flex-cell-container">
                  <table ref={ref => this.tableRef = ref} className="table table-bordered table-hover" id={id}
                         style={{width: "100%", height: "100%"}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
