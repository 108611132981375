"use strict";

import React from "react";

export const WizardContext = React.createContext({});

export const useWizardContext = () => {
  const context = React.useContext(WizardContext);
  if (!context) {
    throw new Error("There is no context defined!");
  }
  return context;
};