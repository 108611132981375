import React, {useEffect, useState} from "react";
import {EditorToolsSettings, EditorUtils} from "@progress/kendo-react-editor";
import * as UIUtils from "../../ui_utils";
import {FormatBlockFontSize} from "../common/constants";
import {ToolProps} from "../common/types";
import {NumericStepper} from "@qbdvision-inc/component-library";
import * as styles from "../document_editor.module.scss";

/**
 * The editor tool to pick font size
 */
export default function FontSize(props: ToolProps): React.ReactElement {
  const {view} = props;
  const [currentFontSize, setCurrentFontSize] = useState(0);

  useEffect(() => {
    setCurrentFontSize(getCurrentFontSize());
  }, [view?.state]);

  const getCurrentFontSize = (): number => {
    if (!view || !view.state) {
      return 0;
    }

    const {state} = view;
    const styles = EditorUtils.getInlineStyles(state, {
      name: "font-size",
      value: /^.+$/,
    });
    const currentFontSize = new Set(styles).size === 1 ? styles[0] : null;
    if (currentFontSize) {
      return getFontSizeNumber(currentFontSize);
    }

    const blockTypes = EditorUtils.getInlineStyles(state, {
      name: "block-type",
      value: /^.+$/,
    });
    const currentBlockType = new Set(blockTypes).size === 1 ? blockTypes[0] : null;
    if (currentBlockType) {
      return getFontSizeNumber(FormatBlockFontSize[currentBlockType]);
    }

    const formats = EditorUtils.getBlockFormats(state);
    const currentFormatBlock = new Set(formats).size === 1 ? formats[0] : null;
    return getFontSizeNumber(FormatBlockFontSize[currentFormatBlock]);
  };

  const getFontSizeNumber = (fontTextInPoint): number => {
    if (!fontTextInPoint) {
      return 0;
    }

    return UIUtils.convertToNumber(fontTextInPoint.replace("pt", ""));
  };

  const updateFontSize = (fontSize: number) => {
    return EditorUtils.applyInlineStyle(view, {
      style: "font-size",
      value: `${fontSize}pt`
    }, EditorToolsSettings.fontSize.commandName);
  };

  return (
    <div className={styles["font-size-tool"]}>
      <NumericStepper min={1} max={600} onChange={updateFontSize} defaultValue={currentFontSize} />
    </div>
  );
}
