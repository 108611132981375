"use strict";

import React, { Fragment } from "react";
import EmptyTable from "./empty_table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { GLOBAL_COLLAPSE_STATES } from "../constants/constants";
import BaseReactComponent from "../../base_react_component";

/**
 * This class is responsible of showing a collapsible panel used to hold data tables
 */
export default class TableCollapseCard extends BaseReactComponent {

  constructor(props) {
    super(props);

    this.isCollapsed = false;
    this.handleCollapse = this.handleCollapse.bind(this);
  }

  handleCollapse(event) {
    event.preventDefault();
    event.stopPropagation();

    this.isCollapsed = !this.isCollapsed;
    this.props.onLocalExpandStatusChange(this.isCollapsed);
  }

  componentDidMount() {
    super.componentDidMount();
    this.setStateSafely({isCollapsed: this.props.collapsedByDefault});
  }

  render() {
    const {className, body, title, name, modelName, infoTooltip, rowsCount, globalExpandStatus} = this.props;

    if (globalExpandStatus) {
      if (globalExpandStatus === GLOBAL_COLLAPSE_STATES.EXPANDED) {
        this.isCollapsed = false;
      } else if (globalExpandStatus === GLOBAL_COLLAPSE_STATES.COLLAPSED) {
        this.isCollapsed = true;
      }
    }

    let input = (
      <Fragment>
        <span>
          <FontAwesomeIcon className="collapse-card-icon"
                           id="collapseChevron"
                           icon={this.isCollapsed ? faChevronRight : faChevronDown}
                           size="sm"
          />
        </span>
        <span className="table-collapse-card-title-text">{title}</span>
      </Fragment>
    );

    return (
      <div className={"table-collapse-card-group " + (className || "")}>
        <div className="card card-default table-collapse-card-group-bottom-margin table-collapse-card-zero-border-radius"
        >
          <div className={"card-header"}>
            <h4 className="card-title card-title-left-margin">
              <a data-toggle="collapse"
                 className="table-collapse-card-anchor"
                 onClick={this.handleCollapse}
                 href={`#collapse${name}`}
                 aria-expanded={!!this.props.collapsedByDefault}
                 aria-controls={`collapse${name}`}
              >
                {input}
              </a>
              <span className="table-collapse-card-title-tooltip">{infoTooltip}</span>
            </h4>
          </div>
          <div id={`collapse${name}`}
               className={"collapse" + (this.isCollapsed ? "" : " show")}
          >
            <div className="card-body">
              {
                rowsCount === null || rowsCount > 0 ?
                  body :
                  <EmptyTable modelName={modelName} />
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

