var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { ButtonGroup, Toolbar, ToolbarSeparator, toolbarButtons } from '@progress/kendo-react-buttons';
import { classNames } from '@progress/kendo-react-common';
import { EditorState, Plugin, PluginKey, EditorView, Schema, baseKeymap, keymap, history, dropCursor, gapCursor, getMark, spacesFix, tableEditing, caretColor } from '@progress/kendo-editor-common';
import { marks, nodes } from './config/schema';
import { defaultStyle, tablesStyles, rtlStyles } from './config/defaultStyles';
import { EditorToolsSettings } from './config/toolsSettings';
import { EditorDialogs } from './dialogs/main';
import { EditorUtils } from './utils';
import { editorPropsKey } from './utils/props-key';
import { updateEditorValue } from './utils/controlled-value';
var link = EditorToolsSettings.link, bold = EditorToolsSettings.bold, italic = EditorToolsSettings.italic, underline = EditorToolsSettings.underline;
import { firefox } from './utils/browser-detection';
import * as licensing from '@progress/kendo-licensing';
import { packageMetadata } from './package-metadata';
import { messages, keys } from './messages';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
/**
 * Represents the [KendoReact Editor component]({% slug overview_editor %}).
 *
 * @example
 * ```jsx
 * class App extends React.Component {
 *    render() {
 *       return (
 *           <Editor
 *               defaultContent="<p>Hello World</p>"
 *               tools={[
 *                  [ EditorTools.Bold, EditorTools.Italic ]
 *               ]}
 *           />
 *       );
 *    }
 * }
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var Editor = /** @class */ (function (_super) {
    __extends(Editor, _super);
    function Editor(props) {
        var _this = _super.call(this, props) || this;
        /**
         * @hidden
         */
        _this.state = {
            view: undefined,
            linkDialog: false
        };
        _this._element = null;
        _this._contentElement = null;
        _this.iframe = null;
        _this.trOnChange = null;
        _this.htmlOnChange = null;
        /**
         * @hidden
         */
        _this.focus = function () {
            if (_this.view) {
                _this.view.focus();
            }
        };
        _this.renderDialog = function (Component, settings, stateFlag) {
            return _this.state[stateFlag] && (React.createElement(Component, { view: _this.view, settings: settings, dir: _this.props.dir, onClose: function () {
                    var _a;
                    return _this.setState((_a = {}, _a[stateFlag] = false, _a));
                } }));
        };
        _this.renderTool = function (Tool, index) {
            var tool = (React.createElement(Tool, { view: _this.view, dir: _this.props.dir, key: index }));
            return tool.type === ToolbarSeparator ? React.createElement(Tool, { key: index }) : tool;
        };
        _this.updateTools = function (view, _prevViewState) {
            _this.setState({ view: view });
        };
        _this.filterTransaction = function (transaction, state) {
            var event = { target: _this, transaction: transaction, state: state };
            return (_this.props.onExecute &&
                _this.props.onExecute.call(undefined, event)) !== false;
        };
        _this.onPasteHtml = function (html) {
            if (_this.props.onPasteHtml && _this.pasteEvent) {
                var event_1 = {
                    target: _this,
                    pastedHtml: html,
                    nativeEvent: _this.pasteEvent
                };
                var newHtml = _this.props.onPasteHtml.call(undefined, event_1);
                _this.pasteEvent = undefined;
                if (typeof newHtml === 'string') {
                    return newHtml;
                }
            }
            return html;
        };
        _this.dispatchTransaction = function (transaction) {
            var docChanged = transaction.docChanged;
            if (_this.props.onChange && docChanged) {
                _this.trOnChange = transaction;
                var doc_1 = transaction.doc, schema_1 = transaction.doc.type.schema;
                var target_1 = _this;
                var event_2 = {
                    target: target_1,
                    value: doc_1,
                    get html() {
                        target_1.htmlOnChange = EditorUtils.getHtml({ doc: doc_1, schema: schema_1 });
                        return target_1.htmlOnChange;
                    },
                    transaction: transaction,
                    schema: schema_1
                };
                _this.props.onChange.call(undefined, event_2);
            }
            if (_this.view && (_this.props.value === undefined || !docChanged)) {
                _this.view.updateState(_this.view.state.apply(transaction));
            }
        };
        _this.onFocus = function (_view, nativeEvent) {
            if (_this.props.onFocus) {
                var event_3 = {
                    target: _this,
                    nativeEvent: nativeEvent
                };
                _this.props.onFocus.call(undefined, event_3);
            }
            return false;
        };
        _this.onBlur = function (_view, nativeEvent) {
            if (_this.props.onBlur) {
                var event_4 = {
                    target: _this,
                    nativeEvent: nativeEvent
                };
                _this.props.onBlur.call(undefined, event_4);
            }
            return false;
        };
        _this.onPaste = function (_view, nativeEvent) {
            if (_this.props.onPasteHtml) {
                _this.pasteEvent = nativeEvent;
            }
            return false;
        };
        if (typeof licensing !== 'undefined') {
            licensing.validatePackage(packageMetadata);
        }
        else {
            var message = "License activation failed for ".concat(packageMetadata.name, "\n");
            message += 'The @progress/kendo-licensing script is not loaded.\n';
            message += "See ".concat(packageMetadata.licensingDocsUrl, " for more information.\n");
            console.warn(message);
        }
        return _this;
    }
    Object.defineProperty(Editor.prototype, "value", {
        /**
         * The value of the Editor.
         */
        get: function () {
            if (this.trOnChange !== null) {
                return this.trOnChange.doc;
            }
            else if (this.props.value !== undefined) {
                return this.props.value;
            }
            else if (this.view) {
                return this.view.state.doc;
            }
            return this.props.defaultContent || '';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Editor.prototype, "element", {
        /**
         * Returns the DOM element of the Editor.
         */
        get: function () {
            return this._element;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Editor.prototype, "contentElement", {
        /**
         * Returns the content-editable DOM element of the Editor.
         */
        get: function () {
            return this._contentElement;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Editor.prototype, "view", {
        /**
         * Returns the `view` object of the Editor.
         */
        get: function () {
            return this._view;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * @hidden
     */
    Editor.prototype.componentDidMount = function () {
        if (!this.iframe || !firefox) {
            this.initialize();
        }
    };
    /**
     * @hidden
     */
    Editor.prototype.componentDidUpdate = function (prevProps) {
        var value = this.props.value;
        var view = this.view;
        if (value === undefined || !view) {
            return;
        }
        updateEditorValue(view, value, prevProps.value, this.trOnChange, this.htmlOnChange);
        this.trOnChange = null;
        this.htmlOnChange = null;
    };
    /**
     * @hidden
     */
    Editor.prototype.componentWillUnmount = function () {
        if (this.view) {
            this.view.destroy();
        }
        this._view = undefined;
        var iframeWindow = this.iframe && this.iframe.contentWindow;
        if (iframeWindow) {
            if (this._contentElement && this._contentElement.parentNode) {
                this._contentElement.parentNode.removeChild(this._contentElement);
            }
            var head = iframeWindow.document.head;
            while (head && head.firstChild) {
                head.removeChild(head.firstChild);
            }
        }
    };
    /**
     * @hidden
     */
    Editor.prototype.render = function () {
        var _this = this;
        var _a = this.props, _b = _a.tools, tools = _b === void 0 ? [] : _b, _c = _a.defaultEditMode, defaultEditMode = _c === void 0 ? 'iframe' : _c, _d = _a.preserveWhitespace, preserveWhitespace = _d === void 0 ? 'full' : _d, style = _a.style, className = _a.className;
        var localization = provideLocalizationService(this);
        if (this.view) {
            var editorProps = editorPropsKey.getState(this.view.state);
            editorProps.preserveWhitespace = preserveWhitespace;
        }
        var contentStyle = this.props.contentStyle;
        if (contentStyle === undefined && (style || {}).height === undefined) {
            contentStyle = { height: '300px' };
        }
        var buttons = tools.map(function (item, index) {
            return Array.isArray(item) ?
                React.createElement(ButtonGroup, { key: index }, item.map(_this.renderTool, index)) :
                _this.renderTool(item, index);
        });
        return (React.createElement("div", { ref: function (e) { return _this._element = e; }, className: classNames('k-widget k-editor', className, { 'k-editor-resizable': this.props.resizable }), dir: this.props.dir, style: style },
            buttons.length > 0 && (React.createElement(Toolbar, { keyboardNavigation: this.props.keyboardNavigation }, buttons)),
            defaultEditMode === 'iframe' ?
                (React.createElement("div", { className: "k-editor-content" },
                    React.createElement("iframe", { onLoad: firefox ? function () {
                            _this.initialize();
                        } : undefined, ref: function (e) { return _this.iframe = e; }, frameBorder: "0", title: localization.toLanguageString(keys.iframeTitle, messages[keys.iframeTitle]), style: contentStyle, className: "k-iframe" }))) :
                (React.createElement("div", { style: contentStyle, className: "k-editor-content" },
                    React.createElement("div", { ref: function (e) { return _this._contentElement = e; }, suppressContentEditableWarning: true, role: "textbox", "aria-labelledby": this.props.ariaLabelledBy, "aria-describedby": this.props.ariaDescribedBy, "aria-label": this.props.ariaLabel }))),
            this.renderDialog(EditorDialogs.InsertLinkDialog, link, 'linkDialog')));
    };
    Editor.prototype.initialize = function () {
        var _this = this;
        var iframeWindow = this.iframe && this.iframe.contentWindow;
        if (iframeWindow) {
            var iframeDocument_1 = iframeWindow.document;
            [defaultStyle, tablesStyles, this.props.dir === 'rtl' ? rtlStyles : undefined].forEach(function (styles) {
                if (styles) {
                    var style = iframeDocument_1.createElement('style');
                    style.appendChild(iframeDocument_1.createTextNode(styles));
                    iframeDocument_1.head.appendChild(style);
                }
            });
            var meta = iframeDocument_1.createElement('meta');
            meta.setAttribute('charset', 'utf-8');
            iframeDocument_1.head.appendChild(meta);
            // The content has to be wrapped because the `dropCursor` plugin raises a `body.offsetParent is null` error.
            this._contentElement = iframeDocument_1.createElement('div');
            iframeDocument_1.body.appendChild(this._contentElement);
            this._contentElement.classList.add('k-content');
        }
        var dom = this._contentElement;
        if (!dom) {
            return;
        }
        var _a = this.props.preserveWhitespace, preserveWhitespace = _a === void 0 ? 'full' : _a;
        var target = this;
        var plugins = [
            // https://prosemirror.net/docs/ref/#state.PluginSpec
            new Plugin({
                view: function () { return ({ update: _this.updateTools }); },
                key: new PluginKey('toolbar-tools-update-plugin')
            }),
            new Plugin({
                filterTransaction: this.filterTransaction,
                key: new PluginKey('onExecute-event-plugin')
            }),
            new Plugin({
                key: editorPropsKey,
                state: {
                    init: function () { return ({ preserveWhitespace: preserveWhitespace }); },
                    apply: function (_, val) { return val; }
                }
            }),
            spacesFix(),
            caretColor(),
            history(),
            dropCursor(),
            gapCursor(),
            tableEditing()
        ];
        var shortcuts = __assign(__assign({}, EditorUtils.getShortcuts({
            types: { listItem: 'list_item', hardBreak: 'hard_break' },
            toolsSettings: { bold: bold, italic: italic, underline: underline }
        })), { 'Mod-k': function () {
                var linkDialog = _this.state.linkDialog;
                var editorView = _this.view;
                if (editorView) {
                    var editorState = editorView.state;
                    var collapsed = editorState.selection.empty;
                    var linkMark = getMark(editorState, editorState.schema.marks[link.mark]);
                    var disabled = collapsed && !linkMark;
                    if (!linkDialog && !disabled) {
                        _this.setState({ linkDialog: true });
                    }
                }
                return !linkDialog;
            }, 'Alt-F10': function () {
                var _a;
                var toolbar = (_a = _this.element) === null || _a === void 0 ? void 0 : _a.querySelector('.k-toolbar');
                if (toolbar) {
                    var button = toolbar.querySelector(toolbarButtons.join(','));
                    if (button) {
                        button.focus();
                        return true;
                    }
                }
                return false;
            } });
        var _b = this.props, _c = _b.defaultContent, defaultContent = _c === void 0 ? '' : _c, value = _b.value, onMount = _b.onMount;
        var doc = (value && typeof value !== 'string') ? value :
            EditorUtils.createDocument(new Schema({ nodes: nodes, marks: marks }), value || defaultContent, { preserveWhitespace: preserveWhitespace });
        var viewProps = {
            state: EditorState.create({
                plugins: __spreadArray(__spreadArray([], plugins, true), [keymap(shortcuts), keymap(baseKeymap)], false),
                doc: doc
            }),
            transformPastedHTML: this.onPasteHtml,
            dispatchTransaction: this.dispatchTransaction,
            handleDOMEvents: {
                focus: this.onFocus,
                blur: this.onBlur,
                paste: this.onPaste
            }
        };
        var mountEvent = { plugins: plugins, shortcuts: shortcuts, target: target, viewProps: viewProps, dom: dom };
        var view = this._view = (onMount && onMount.call(undefined, mountEvent)) || new EditorView({ mount: dom }, viewProps);
        this.setState({
            view: view
        });
    };
    /**
     * @hidden
     */
    Editor.propTypes = {
        defaultContent: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        defaultEditMode: PropTypes.oneOf(['iframe', 'div']),
        contentStyle: PropTypes.object,
        dir: PropTypes.string,
        className: PropTypes.string,
        ariaDescribedBy: PropTypes.string,
        ariaLabelledBy: PropTypes.string,
        ariaLabel: PropTypes.string,
        style: PropTypes.object,
        tools: PropTypes.arrayOf(PropTypes.any),
        keyboardNavigation: PropTypes.bool,
        resizable: PropTypes.bool,
        preserveWhitespace: PropTypes.oneOf([true, false, 'full']),
        onMount: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onPasteHtml: PropTypes.func,
        onExecute: PropTypes.func
    };
    return Editor;
}(React.Component));
export { Editor };
registerForLocalization(Editor);
