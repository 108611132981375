"use strict";

/*
This file includes all logic needed to get value, title, etc. for each column exported
to the excel sheet.
 */

import * as UIUtils from "../../ui_utils";
import { formatValue } from "../fieldsConfig/fields_formatter";
import { FILTER_TYPES } from "../columnOperations/configurable_tables_column_operations_constants";

export default class ConfigurableTablesExportColumnGenerator {
  constructor(props) {
    this.record = props?.record;
  }

  get column() {
    return this._column;
  }

  set column(column) {
    this._column = column;
  }

  getColumnTitle() {
    return this.column.title;
  }

  getColumnValue() {
    const {record, column} = this;
    const {value, title, prop, filterType} = column;
    return this.wrapValue(value ? value(record, prop) :
      formatValue(record[UIUtils.convertToCamelCaseId(prop ? prop : UIUtils.convertToCamelCaseId(title))],
        record, prop, false, filterType === FILTER_TYPES.DATE));
  }

  wrapValue(value) {
    return typeof value === "object" ? value : {value};
  }

  getVariableColumnLabel() {
    return UIUtils.getRecordCustomLabelForDisplay(this.record);
  }

  getIdColumnLabel() {
    return `${this.record.typeCode}-${this.record.id}`;
  }
}
