import BasePage from "../../base_page";
import * as UIUtils from "../../ui_utils";
import * as FailHandlers from "../../utils/fail_handlers";
import React, { Fragment } from "react";
import { LibraryHelper, MATERIAL_ATTRIBUTE_ACTIONS } from "../helpers/library_helper";
import CommonEditablesPageTitleBar from "../../widgets/pageTitleBar/common_editables_page_title_bar";
import { MaterialAttributeSpecifications } from "./components/material_attribute_specifications_table";

/**
 * This contains common methods to link and unlink material attributes from library
 *  @abstract
 */
export default class ProjectMaterialAttributeBasePage extends BasePage {
  constructor(props) {
    super(props);

    this.setStateSafely({
      material: null,
      materialAttribute: null,
    });
  }

  componentDidMount() {
    super.componentDidMount();
    this.loadData();
  }

  loadData() {
    this.setStateSafely({isLoading: true});

    const urlParameters = {
      includeHistory: true,
      loadFullHistory: true,
      includeChildRecords: true,
    };

    const materialId = UIUtils.getParameterByName("materialId");
    UIUtils.secureAjaxGET(
      `editables/Material/${materialId}`,
      urlParameters,
      true,
      FailHandlers.defaultStopLoadingFailFunction.bind(this),
    ).then(results => this.handleMaterialResultsReceivedFromServer(results));

    const materialAttributeId = UIUtils.getParameterByName("materialAttributeId");
    UIUtils.secureAjaxGET(
      `editables/MaterialAttribute/${materialAttributeId}`,
      urlParameters,
      true,
      FailHandlers.defaultStopLoadingFailFunction.bind(this),
    ).then(results => this.handleMaterialAttributeResultsReceivedFromServer(results));
  }

  handleMaterialResultsReceivedFromServer(result) {
    const libraryMaterial = result.fromLibrary;
    libraryMaterial.id = libraryMaterial.LibraryMaterialId;
    libraryMaterial.Specifications = libraryMaterial.specifications.map(spec => {
      return {
        ...spec,
        LibraryMaterialId: libraryMaterial.LibraryMaterialId,
        specificationId: spec.recordId,
      };
    });

    this.setStateSafely({
      material: result,
      libraryMaterial,
    });
  }

  handleMaterialAttributeResultsReceivedFromServer(result) {
    this.setStateSafely({
      materialAttribute: result,
    });
  }

  // eslint-disable-next-line no-unused-vars
  getTitle(materialAttribute) {
    throw Error("You must override this method.");
  }

  getSubtitle() {
    throw Error("You must override this method.");
  }

  getAction() {
    throw Error("You must override this method.");
  }

  getActionButton() {
    throw Error("You must override this method.");
  }

  onTableDataChange() {

  }

  onActionButtonClick() {
    LibraryHelper.onActionClick(
      () => {
        return {
          materialAttribute: this.state.materialAttribute,
        };
      }, LibraryHelper.redirectToMaterialAttribute, this.getAction());
  }

  renderPage() {
    const {material, materialAttribute, libraryMaterial} = this.state;

    if (!material || !materialAttribute || !libraryMaterial) {
      return (
        <Fragment>
          <div className="skeleton">Loading...</div>
        </Fragment>);
    }

    const specifications = libraryMaterial.Specifications;

    const materialAttributes = [{
      ...materialAttribute,
      specification: specifications.find(spec => spec.id === materialAttribute.linkedToSpecificationVersionId),
      specifications,
    }];

    const title = this.getTitle(materialAttribute);
    const action = this.getAction();

    return (
      <Fragment>
        <CommonEditablesPageTitleBar name={title} />
        <div className="container-spacer" />
        <div className="container material-library-wizard-div min-vh-100">
          <div className="row-white material-library-wizard-steps-container">
            <div className="col-12 material-library-wizard-step-inner-container material-options-container">
              <div className="row">
                <div className="col">
                  <h2>Material Attribute</h2>
                  <p>{ this.getSubtitle() }</p>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="material-attributes-container">
                    <MaterialAttributeSpecifications
                      dataSource={materialAttributes}
                      dropDownSource={specifications}
                      onDataChange={this.onTableDataChange}
                      readonly={ action === MATERIAL_ATTRIBUTE_ACTIONS.UNLINK }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row action-buttons-box">
              <div className="col">
                { this.getActionButton() }
                <button className="btn btn-lg btn-secondary float-left" onClick={() => LibraryHelper.redirectToProcessExplorer()}>
                  Exit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}