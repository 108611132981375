"use strict";

import React, { Component } from "react";
import { NoDataCell } from "./no_data_cell";

/**
 * Renders a simple value cell in the standard way for a risk table report.
 */
export class ValueCell extends Component {
  render() {
    const {value, showNoneIfEmpty} = this.props;

    if (!value && showNoneIfEmpty) {
      return <NoDataCell />;
    } else {
      return (
        <div>
          <span className="risk-tables-cell">{value}</span>
        </div>
      );
    }
  }
}

ValueCell.defaultProps = {
  showNoneIfEmpty: false,
};
