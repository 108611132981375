"use strict";
import React from "react";

export default function(props) {
  let headerClassName = props.headerClassName || "";
  let {id, className, children, level} = props;
  return (
    <div id={id || ""}
         className={"row" + (className ? " " + className : "")}>
      <div className="col-sm-12">
        {level === 1 || !level ?
          <h1 className={headerClassName}>{children}</h1>
          : level === 2 ?
            <h2 className={headerClassName}>{children}</h2>
            : level === 3 ?
              <h3 className={headerClassName}>{children}</h3>
              :
              <h4 className={headerClassName}>{children}</h4>
        }
      </div>
    </div>
  );
}
