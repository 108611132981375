"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseAttribute from "./base_attribute";
import LabelTooltip from "../../widgets/tooltips/label_tooltip";
import * as DocumentTransferHelper from "../../helpers/document_transfer_helper";
import { FILE_STATUS } from "../../helpers/document_transfer_helper";
import * as CommonUtils from "../../../server/common/generic/common_utils";
import DocumentAttachmentViewAttribute from "./document_attachment_view_attribute";

export default class DocumentAttachmentEditAttribute extends BaseAttribute {
  constructor(props) {
    super(props);

    this.state.customError = "";

    this.inputFileRef = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();

    this.props.parent.registerCustomValidatedAttribute(this);
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    this.props.parent.unregisterCustomValidatedAttribute(this);
  }

  static getInitialValue() {
    return JSON.stringify([{
      linkType: "",
      S3TmpKey: "",
      S3TmpVersion: "",
      xhr: null,
      link: "",
      linkVersion: "",
      fileName: "",
      progress: 0,
      fileStatus: FILE_STATUS.NOT_SPECIFIED,
      size: ""
    }]);
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
    if (!shouldUpdate) {
      let value = this.getNextValue(nextProps);
      shouldUpdate = this.currentValue !== value || this.state.customError !== nextState.customError;
    }

    if (shouldUpdate) {
      UIUtils.incrementReactComponentDidUpdateCounter();
    }

    return shouldUpdate;
  }

  getValue() {
    let value = this.props.parent.state[this.getAttributeName()];
    return (value && typeof value === "string") ? JSON.parse(value) : (value ? value : DocumentAttachmentEditAttribute.getInitialValue());
  }

  hasValue() {
    let value = this.getValue();
    return value && value[0].fileName;
  }

  validate(formValidationMode) {
    let value = this.getValue();

    if (formValidationMode === CommonUtils.FORM_VALIDATION_MODE.PROPOSE) {
      const documentRecord = this.props.parent.state;
      if (!this.hasValue()) {
        // For the QbD document, we will generate the PDF file before proposing
        if (documentRecord.isQbdSrcDoc) {
          if (!documentRecord.documentContent) {
            this.setErrorText("Document is required. Please create a custom QbD Doc before proposing.");
            return false;
          }
        } else {
          this.setErrorText("Document is required.");
          return false;
        }
      }
    }

    if (value && (value[0].fileStatus === FILE_STATUS.UPLOADING || value[0].fileStatus === FILE_STATUS.NEW_FILE_SELECTED)) {
      this.setErrorText("Document upload is pending. Please wait until it is complete.");
      return false;
    }

    return super.validate();
  }

  getInputId() {
    return this.props.name;
  }

  clearValidationErrors() {
    this.clearErrorText(true);
    if (!this.isView() && this._isMounted) {
      this.forceUpdate();
    }
  }

  handleChange(fileData) {
    const newValue = JSON.stringify([fileData]);

    // Uncomment for verbose logging
    // console.log("Changing " + this.props.name + " to '" + newValue + "'");

    this.props.parent.handleChangeValue(this.getAttributeName(), newValue);
  }

  handleFileDownload(fileData, e) {
    {
      e && e.preventDefault();
    }

    if (typeof this.props.onDownload === "function") {
      this.props.onDownload(this.getFileData());
    }
    return false;
  }

  handleFileUpload(fileData, e) {
    DocumentTransferHelper.handleUpload(e, fileData,
      "#" + this.props.name + "UploadProgressBar",
      this.clearErrorText,
      this.setError,
      this.forceUpdate.bind(this),
      this.handleChange,
      this.validateFileData);
  }

  handleFileRemove() {
    const defaultValue = JSON.parse(DocumentAttachmentEditAttribute.getInitialValue())[0];
    this.handleChange(defaultValue);
  }

  getFileData() {
    return this.getValue()[0];
  }

  validateFileData(fileData) {
    const fileExtension = UIUtils.getFileExtension(fileData.fileName);
    if (fileExtension !== ".pdf") {
      this.setErrorText("Only PDF files may be uploaded.");
      return false;
    } else {
      return true;
    }
  }

  setErrorText(error) {
    this.setStateSafely({customError: error});
  }

  getErrorText() {
    let htmlError;
    if (!this.state.customError || typeof this.state.customError === "string") {
      return this.state.customError;
    } else {
      let key = 1;
      htmlError = this.state.customError.map(error => {
        return (<div key={key++}>
          {error}
        </div>);
      });
    }

    return htmlError;
  }

  clearErrorText(shouldNotForceUpdate) {
    this.setStateSafely({customError: null});
    if (!shouldNotForceUpdate && this._isMounted) {
      this.forceUpdate();
    }
  }

  renderProgressBar() {
    const fileData = this.getFileData();
    return <>
      {fileData.fileStatus === FILE_STATUS.UPLOADING &&
        <div id={this.props.name + "LinkFormGroup"}
             style={{width: "200px", display: "inline-block", verticalAlign: "sub"}}
             className={"form-group"}
        >
          <div className="progress">
            <div id={this.props.name + "UploadProgressBar"}
                 className="progress-bar active"
                 role="progressbar"
                 aria-valuenow={fileData.progress}
                 aria-valuemin="0"
                 aria-valuemax="100"
                 style={{
                   width: fileData.progress + "%",
                 }}
            >
              {fileData.progress + "%"}
            </div>
          </div>
        </div>}
    </>;
  }

  renderLabel() {
    return (
      <div>
        <LabelTooltip id={this.props.name + "Label"}
                      text={"Final document"}
                      indicator={(
                        <span className={this.getRequiredFieldIndicatorClass()}>
                          {!this.isView() && this.isRequiredForSaving() ? " *" : !this.isView() && this.isRequiredForProposing() ? " **" : ""}
                        </span>
                      )}
                      className={"col-form-label base-attribute" + (this.isView() ? "" : " col-form-label-add-or-edit")}
        />
        {this.renderProgressBar()}
      </div>
    );
  }

  renderInput() {
    this.currentValue = this.props.parent.state[this.getAttributeName()];
    return (
      <div className="form-group document-upload-group">
        {!this.isView() &&
          <input id={this.props.name + "UploadDocumentButton"}
                 type="file"
                 ref={this.inputFileRef}
                 accept=".pdf"
                 className="links-file-input form-control"
                 onChange={e => this.handleFileUpload(this.getFileData(), e)}
          />
        }
      </div>
    );
  }

  render() {
    const inputId = this.getInputId();
    const fileData = this.getFileData();

    const input = this.renderInput();
    const label = this.renderLabel();

    const visible = input && (typeof this.props.visible === "boolean" ? this.props.visible : true);
    return (
      visible ?
        (<div className={"attribute-container "}>
          {label}
          <div
            className={(this.isView() ? "view-attribute" : "") + (this.state.customError ? " has-danger has-error" : "") + this.getClassForLoading()}
            id={inputId + "Div"}
          >
            <DocumentAttachmentViewAttribute
              name="uploadDocumentLinks"
              docAttachmentControlName="uploadDocumentLinks"
              instance={this.props.parent.state}
              isView={false}
              isLoading={fileData.fileStatus === FILE_STATUS.UPLOADING}
              onDownload={this.handleFileDownload}
              onUpload={() => this.inputFileRef.current.click()}
              onFileRemove={this.handleFileRemove}
              olderVersion={this.props.parent.getOlderVersion()}
              isDiffingVersions={this.props.parent.isDiffingVersions()}
            />
            {input}
            <div className={"help-block with-errors" + (this.state.customError ? "" : " hidden")}
                 id={inputId + "CustomErrorDiv"}
            >
              {this.getErrorText()}
            </div>
          </div>
        </div>) :
        null
    );
  }
}
