"use strict";

import pdfJs from "pdfjs-dist";

/**
 * Returns the number of pages for a pdf file
 * @param dataUrl The pdf file in dataUrl format
 */
module.exports.getNumberOfPages = function(dataUrl) {
  return pdfJs.getDocument(dataUrl).promise.then(pdf => pdf.numPages);
};
