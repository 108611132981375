var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a, _b, _c;
/**
 * @hidden
 */
export var KEYBOARD_NAV_DATA_LEVEL = 'data-keyboardnavlevel';
/**
 * @hidden
 */
export var KEYBOARD_NAV_DATA_SCOPE = 'data-keyboardnavscope';
/**
 * @hidden
 */
export var KEYBOARD_NAV_DATA_HEADER = 'data-keyboardnavheader';
/**
 * @hidden
 */
export var KEYBOARD_NAV_DATA_BODY = 'data-keyboardnavbody';
/**
 * @hidden
 */
export var KEYBOARD_NAV_DATA_ID = 'data-keyboardnavid';
/**
 * @hidden
 */
export var KEYBOARD_NAV_DATA_ZONE = 'data-keyboardnavzone';
/**
 * @hidden
 */
export var KEYBOARD_NAV_FILTER_COL_SUFFIX = '_filter';
var FOCUSABLE_ELEMENTS_BASE = [
    'input:not([disabled]):not([type=hidden])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    'button:not([disabled])',
    'a[href]',
    'area[href]',
    'summary',
    'iframe',
    'object',
    'embed',
    'audio[controls]',
    'video[controls]',
    '[contenteditable]'
];
/**
 * @hidden
 */
export var FOCUSABLE_ELEMENTS = __spreadArray(__spreadArray([], FOCUSABLE_ELEMENTS_BASE, true), ['[tabindex]'], false);
/**
 * @hidden
 */
export var TABBABLE_ELEMENTS = __spreadArray(__spreadArray([], FOCUSABLE_ELEMENTS_BASE, true), ['[tabindex]'], false).map(function (selector) { return selector + ':not([tabindex="-1"])'; });
/**
 * @hidden
 */
export var tableKeyboardNavigationScopeAttributes = (_a = {},
    _a[KEYBOARD_NAV_DATA_SCOPE] = true,
    _a);
/**
 * @hidden
 */
export var tableKeyboardNavigationHeaderAttributes = (_b = {},
    _b[KEYBOARD_NAV_DATA_HEADER] = true,
    _b);
/**
 * @hidden
 */
export var tableKeyboardNavigationBodyAttributes = (_c = {},
    _c[KEYBOARD_NAV_DATA_BODY] = true,
    _c);
