"use strict";

import React, { useEffect, useState } from "react";
import ErrorBar from "../../../widgets/bars/error_bar";
import { MaterialLibraryTable } from "../createProjectMaterial/components/library_materials_table";
import { faExternalLinkAlt, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LibraryHelper } from "../../helpers/library_helper";
import { useWizardContext } from "../../hooks/use_wizard_context_hook";
import { debounce } from "lodash";
import { WizardExitStrategies } from "../wizard_exit_strategies";

function searchTermDefaultValue({filters}) {
  const [nameFilter] = filters;
  const value = nameFilter.value;
  return value === "*" ? "" : value;
}

function shouldDisplaySearchResultCount({filters}) {
  const [nameFilter] = filters;
  const value = nameFilter.value;
  return value !== "*";
}

function resetInitialState(filter, searchTerm) {
  let newFilter = {
    ...filter,
    filters: filter.filters.map(filter => {
      return {
        ...filter,
        value: searchTerm === "" ? "*" : searchTerm,
      };
    })
  };
  return {searchFilter: newFilter};
}

const DEFAULT_MATERIALS_COUNT = 0;

/**
 * The first flow step, the user will search for a library material to start creating project material from it.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function ProjectMaterialSelectionStep(props) {

  const {
    config,
    action,
    currentStep,
    totalSteps,
    libraryMaterials,
    wizardStepsConfig,
    selectionState
  } = props;

  const context = useWizardContext();
  const [materials, setMaterials] = useState(libraryMaterials);
  const [numberOfMaterials, setNumberOfMaterials] = useState(DEFAULT_MATERIALS_COUNT);

  const {initialState} = config;
  const {searchFilter} = initialState || {};
  const [searchTerm, setSearchTerm] = useState(searchTermDefaultValue(searchFilter));

  const {
    t,
    WizardHelper,
  } = context;

  const wizardHelper = new WizardHelper(context);

  const onStepDataUpdatedWrapper = (material, selectedState) => {
    wizardHelper.onStepDataUpdated({
      config,
      totalSteps,
      isNextDisabled: false,
      state: material,
      selectedState,
      wizardStepsConfig,
    });
  };

  const onButtonClick = () => {
    wizardHelper.onNextButtonClick({
      config,
      wizardStepsConfig,
      totalSteps
    });
  };

  const onSearchBoxChange = searchTerm => {
    setSearchTerm(searchTerm);
    const debouncedCall = debounce(() => {
      wizardHelper.onSearchFilterChange({
        config,
        wizardStepsConfig,
        initialState: resetInitialState(searchFilter, searchTerm),
      });
    }, 400);

    debouncedCall();
  };

  useEffect(() => {
    setMaterials(
      libraryMaterials
    );
  }, [libraryMaterials]);

  const onExitClick = () => {
    const onExit = WizardExitStrategies[action];
    return onExit(props);
  };

  return (
    <div className={"row row-white material-library-wizard-steps-container"
      + (currentStep === 1 ? " first-wizard-step" : "")
      + (!config.visible ? "hidden" : "")}
    >
      <div className="col-12 material-library-wizard-step-inner-container">
        <ErrorBar className="create-from-project-error-bar mt-4 mb-2" />
        <div className="row">
          <div className="col-6 search-box mb-3">
            <FontAwesomeIcon icon={faSearch} className="search-icon" />
            <input type="text"
                   id="materialLibraryTableSearch"
                   onChange={e => onSearchBoxChange(e.target.value)}
                   value={searchTerm}
                   autoFocus
                   placeholder={t("Search Library Placeholder")}
                   className="form-control"
            />
          </div>
          <div className="col-6">
            <a className="link-to-library"
               onClick={() => LibraryHelper.onLookupInLibrary()}
            >
              {t("Refer To Library")}<FontAwesomeIcon icon={faExternalLinkAlt} />
            </a>
          </div>
        </div>
        {
          shouldDisplaySearchResultCount(searchFilter) &&
          <span className="materials-count">
            <strong>{numberOfMaterials} Materials</strong>
          </span>
        }
        <MaterialLibraryTable
          t={t}
          materials={materials}
          isEmptySearchResult={numberOfMaterials === 0 && shouldDisplaySearchResultCount(searchFilter)}
          filter={searchFilter}
          onResultChange={setNumberOfMaterials}
          initialSelection={selectionState}
          onSelectRow={onStepDataUpdatedWrapper}
        />
      </div>
      <div className="row action-buttons-box">
        <div className="col">
          <button className="btn btn-lg btn-primary float-right" id="nextButton"
                  disabled={wizardHelper.isNextStepDisabled({config, totalSteps, wizardStepsConfig})}
                  onClick={() => onButtonClick()}
          >
            Next
          </button>
          <button className="btn btn-lg btn-secondary float-left" id="exitButton" onClick={onExitClick}>
            Exit
          </button>
        </div>
      </div>

    </div>
  );
}
