import { BaseParser } from "./base_parser";

/**
 * Parser that parses all image elements
 */
export class ImageParser extends BaseParser {
  get selector() {
    return "img";
  }

  get fieldKey() {
    return "images";
  }

  getElements() {
    return Array.from(this.node.querySelectorAll(this.selector)).filter(
      (element) =>
        element.getAttribute("filedata") || element.getAttribute("title")
    );
  }

  parse() {
    this.fields = [];
    const imageElements = this.getElements();
    for (const imageElement of imageElements) {
      const fileDataStr =
        imageElement.getAttribute("filedata") ||
        imageElement.getAttribute("title");
      const fileData = JSON.parse(fileDataStr);
      if (fileData) {
        this.fields.push(fileData);
      }

      if (this.parseOnly) {
        imageElement.src = "";
      } else if (
        this.data &&
        this.data[this.fieldKey] &&
        this.data[this.fieldKey][fileData.S3TmpKey]
      ) {
        const imageData = this.data[this.fieldKey][fileData.S3TmpKey];
        imageElement.setAttribute(
          "filedata",
          JSON.stringify(imageData.uploadImageLink)
        );
        const parentClassName = imageElement.parentElement.className;
        if (!imageData.url) {
          imageElement.parentElement.className = parentClassName
            ? `${parentClassName} image-skeleton`
            : "image-skeleton";
        } else {
          imageElement.src = imageData.url;
          imageElement.parentElement.className = parentClassName.replace(
            "image-skeleton",
            ""
          );
        }
      }

      // Previously we saved the imageData in title. Now, we use the filedata instead, so
      // we remove it.
      if (imageElement.getAttribute("filedata")) {
        imageElement.removeAttribute("title");
      }
    }
    return imageElements;
  }
}
