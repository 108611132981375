var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
import { GridColumnMenuItem } from './GridColumnMenuItem';
import { GridColumnMenuItemGroup } from './GridColumnMenuItemGroup';
import { GridColumnMenuItemContent } from './GridColumnMenuItemContent';
import { Checkbox, Input } from '@progress/kendo-react-inputs';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { messages, filterClearButton, filterSubmitButton, filterTitle, searchPlaceholder, filterCheckAll, filterSelectedItems } from '../messages';
import { filterBy } from '@progress/kendo-data-query';
import { clone } from '@progress/kendo-react-common';
import { Button } from '@progress/kendo-react-buttons';
import { getNestedValue } from '../utils';
/**
 * @hidden
 */
var isArrayEqual = function (firstArray, secondArray) {
    if (firstArray.length !== secondArray.length) {
        return false;
    }
    return firstArray.every(function (ex, i) { return ex === secondArray[i]; });
};
var GridColumnMenuCheckboxFilter = /** @class */ (function (_super) {
    __extends(GridColumnMenuCheckboxFilter, _super);
    function GridColumnMenuCheckboxFilter(props) {
        var _this = _super.call(this, props) || this;
        /**
         * @hidden
         */
        _this.defaultFilter = function () {
            if (_this.props.filter) {
                return clone(_this.props.filter);
            }
            return { filters: [], logic: 'and' };
        };
        /**
         * @hidden
         */
        _this.isControlled = function () { return _this.props.expanded !== undefined; };
        /**
         * @hidden
         */
        _this.parseData = function (originalData, isUnique) {
            var field = _this.props.column.field || '';
            var data = originalData.map(function (item) { return getNestedValue(field, item); });
            if (isUnique) {
                return data.filter(function (item, index) { return data.indexOf(item) === index; });
            }
            return data;
        };
        /**
         * @hidden
         */
        _this.getFilterIndex = function () {
            var field = _this.props.column.field;
            var currentFilter = _this.defaultFilter();
            var compositeFilterIndex = currentFilter.filters.findIndex(function (filter) {
                return filter.filters && filter.filters.length > 0 && filter.filters[0].field === field;
            });
            return compositeFilterIndex;
        };
        /**
         * @hidden
         */
        _this.onFilterExpand = function () {
            var isControlled = _this.isControlled();
            var nextValue = !(isControlled ? _this.props.expanded : _this.state.expanded);
            if (_this.props.onExpandChange) {
                _this.props.onExpandChange(nextValue);
            }
            if (!isControlled) {
                _this.setState({
                    expanded: nextValue
                });
            }
        };
        /**
         * @hidden
         */
        _this.handleSearchChange = function (e) {
            var filterOperator = _this.props.searchBoxFilterOperator ? _this.props.searchBoxFilterOperator : 'startswith';
            var filterExpression = {
                logic: 'and',
                filters: [
                    { field: _this.props.column.field, operator: filterOperator, value: e.target.value, ignoreCase: true }
                ]
            };
            _this.setState({
                value: e.target.value,
                data: _this.parseData(filterBy(_this.props.data || [], filterExpression), _this.props.uniqueData)
            });
        };
        /**
         * @hidden
         */
        _this.handleClear = function () {
            var filterOperator = _this.props.searchBoxFilterOperator ? _this.props.searchBoxFilterOperator : 'startswith';
            var filterExpression = {
                logic: 'and',
                filters: [
                    { field: _this.props.column.field, operator: filterOperator, value: '', ignoreCase: true }
                ]
            };
            _this.setState({
                value: '',
                data: _this.parseData(filterBy(_this.props.data || [], filterExpression), _this.props.uniqueData)
            });
        };
        /**
         * @hidden
         */
        _this.clear = function (e) {
            e.preventDefault();
            if (!_this.props.onFilterChange) {
                return;
            }
            var updatedFilter = _this.state.currentFilter || null;
            if (updatedFilter !== null && updatedFilter.filters.length > 0) {
                if (_this.compositeFilterIndex >= 0) {
                    updatedFilter.filters.splice(_this.compositeFilterIndex, 1);
                }
                _this.props.onFilterChange(updatedFilter, e);
            }
            else {
                _this.props.onFilterChange(null, e);
            }
            if (_this.props.onCloseMenu) {
                _this.props.onCloseMenu();
            }
        };
        /**
         * @hidden
         */
        _this.submit = function (e) {
            e.preventDefault();
            if (!_this.props.onFilterChange) {
                return;
            }
            var updatedFilter = _this.state.currentFilter || null;
            _this.props.onFilterChange(updatedFilter, e);
            if (_this.props.onCloseMenu) {
                _this.props.onCloseMenu();
            }
        };
        /**
         * @hidden
         */
        _this.handleCheckBoxChange = function (e, value) {
            var field = _this.props.column.field || '';
            var newFilter = __assign({}, _this.state.currentFilter);
            var filters = __spreadArray([], _this.state.currentFilter.filters, true) || [];
            var fieldFilters = [];
            if (_this.compositeFilterIndex !== -1 && newFilter.filters[_this.compositeFilterIndex].filters && value !== 'all') {
                fieldFilters = newFilter.filters[_this.compositeFilterIndex].filters;
            }
            if (e.value && value === 'all') {
                _this.state.data.forEach(function (item) {
                    fieldFilters.push({ field: field, operator: 'eq', value: item });
                });
            }
            else if (e.value) {
                fieldFilters.push({ field: field, operator: 'eq', value: value });
            }
            else if (_this.state.currentFilter) {
                var index = fieldFilters.findIndex(function (filter) { return filter.value === value; });
                fieldFilters.splice(index, 1);
            }
            newFilter.logic = 'and';
            if (_this.compositeFilterIndex !== -1) {
                filters[_this.compositeFilterIndex] = {
                    logic: 'or',
                    filters: fieldFilters
                };
            }
            else {
                filters.push({
                    logic: 'or',
                    filters: fieldFilters
                });
            }
            if (!e.value && value === 'all' || fieldFilters.length === 0) {
                filters.splice(_this.compositeFilterIndex, 1);
            }
            newFilter.filters = filters;
            _this.setState({ currentFilter: newFilter });
        };
        /**
         * @hidden
         */
        _this.isAllSelected = function () {
            var isAllChecked = false;
            if (_this.state.currentFilter) {
                var filters_1 = __spreadArray([], _this.state.currentFilter.filters, true);
                if (_this.compositeFilterIndex === -1) {
                    return false;
                }
                isAllChecked = _this.state.data.every(function (item) {
                    if (_this.compositeFilterIndex !== -1 && filters_1[_this.compositeFilterIndex].filters) {
                        var index = filters_1[_this.compositeFilterIndex].filters.findIndex(function (filter) { return filter.value === item; });
                        return index >= 0;
                    }
                    return false;
                });
                return isAllChecked;
            }
            return isAllChecked;
        };
        _this.compositeFilterIndex = _this.getFilterIndex();
        _this.state = {
            expanded: props.expanded || false,
            value: '',
            data: _this.parseData(_this.props.data, _this.props.uniqueData) || [],
            dataFromProps: _this.parseData(_this.props.data, false) || [],
            currentFilter: _this.defaultFilter()
        };
        return _this;
    }
    /**
     * @hidden
     */
    GridColumnMenuCheckboxFilter.prototype.componentDidUpdate = function () {
        var field = this.props.column.field || '';
        var data = this.props.data.map(function (item) { return getNestedValue(field, item); });
        if (!isArrayEqual(data, this.state.dataFromProps)) {
            this.setState({
                data: data,
                dataFromProps: data
            });
        }
    };
    /**
     * @hidden
     */
    GridColumnMenuCheckboxFilter.prototype.render = function () {
        var _this = this;
        var column = this.props.column;
        if (!column || !column.field) {
            return React.createElement("div", null);
        }
        var localizationService = provideLocalizationService(this);
        var expandState = this.isControlled() ? this.props.expanded : this.state.expanded;
        var filterValues = [];
        if (this.state.currentFilter) {
            var currentFilter = __spreadArray([], this.state.currentFilter.filters, true);
            this.compositeFilterIndex = currentFilter.findIndex(function (filter) {
                if (filter.filters && filter.filters.length > 0) {
                    return filter.filters[0].field === column.field;
                }
                return false;
            });
            if (this.compositeFilterIndex !== -1 && currentFilter[this.compositeFilterIndex].filters.length > 0) {
                currentFilter[this.compositeFilterIndex].filters.forEach(function (filterItem) {
                    if (filterItem.field === _this.props.column.field) {
                        filterValues.push(filterItem.value);
                    }
                });
            }
        }
        var searchBox = this.props.searchBox ?
            React.createElement(this.props.searchBox, { value: this.state.value, onChange: this.handleSearchChange }) :
            (React.createElement("div", { className: "k-searchbox k-textbox k-input k-input-md k-input-solid" },
                React.createElement("span", { className: "k-input-icon k-icon k-i-search" }),
                React.createElement(Input, { className: "k-input-inner", type: "text", placeholder: localizationService.toLanguageString(searchPlaceholder, messages[searchPlaceholder]), value: this.state.value, onChange: function (e) { return _this.handleSearchChange(e.nativeEvent); } }),
                React.createElement(Button, { type: "button", rounded: null, className: "k-input-button", onClick: this.handleClear, icon: "close" })));
        var uniqueFilterValues = filterValues.filter(function (item, index) { return filterValues.indexOf(item) === index; });
        return (React.createElement(GridColumnMenuItemGroup, null,
            React.createElement(GridColumnMenuItem, { title: localizationService.toLanguageString(filterTitle, messages[filterTitle]), iconClass: "k-i-filter", onClick: this.onFilterExpand }),
            React.createElement(GridColumnMenuItemContent, { show: !!expandState },
                React.createElement("div", { className: "kendo-grid-filter-menu-container" },
                    React.createElement("form", { className: "k-filter-menu k-group k-reset k-state-border-up", onSubmit: this.submit, onReset: this.clear },
                        React.createElement("div", { className: "k-filter-menu-container" },
                            searchBox,
                            React.createElement("ul", { className: "k-reset k-multicheck-wrap" },
                                React.createElement("li", { className: "k-item k-check-all-wrap" },
                                    React.createElement(Checkbox, { label: localizationService.toLanguageString(filterCheckAll, messages[filterCheckAll]), onChange: function (e) { return _this.handleCheckBoxChange(e, 'all'); }, checked: this.isAllSelected() })),
                                this.state.data.map(function (item, index) {
                                    return (React.createElement("li", { className: "k-item", key: index },
                                        React.createElement(Checkbox, { label: String(item), onChange: function (e) { return _this.handleCheckBoxChange(e, item); }, checked: uniqueFilterValues.includes(item) })));
                                })),
                            React.createElement("div", { className: "k-filter-selected-items" }, uniqueFilterValues.length + ' ' + localizationService.toLanguageString(filterSelectedItems, messages[filterSelectedItems])),
                            React.createElement("div", { className: "k-actions k-hstack k-justify-content-stretch" },
                                React.createElement(Button, { themeColor: "primary", type: 'submit' }, localizationService.toLanguageString(filterSubmitButton, messages[filterSubmitButton])),
                                React.createElement(Button, { className: "k-button", type: 'reset' }, localizationService.toLanguageString(filterClearButton, messages[filterClearButton])))))))));
    };
    /**
     * @hidden
     */
    GridColumnMenuCheckboxFilter.defaultProps = {
        uniqueData: true
    };
    return GridColumnMenuCheckboxFilter;
}(React.Component));
export { GridColumnMenuCheckboxFilter };
registerForLocalization(GridColumnMenuCheckboxFilter);
