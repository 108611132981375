var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { onDownPreventDefault } from './../utils';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { messages } from './../../messages';
import { InsertTablePopupNS } from './popup';
import { canInsert, createTable, insertNode } from '@progress/kendo-editor-common';
import { classNames } from '@progress/kendo-react-common';
/**
 * @hidden
 */
export var InsertTableToolNS;
(function (InsertTableToolNS) {
    /**
     * @hidden
     */
    InsertTableToolNS.createInsertTableTool = function (settings) {
        var Tool = /** @class */ (function (_super) {
            __extends(InsertTableTool, _super);
            function InsertTableTool() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.state = {
                    openedPopup: false
                };
                _this.button = null;
                _this.togglePopup = function () {
                    _this.setState({
                        openedPopup: !_this.state.openedPopup
                    });
                };
                _this.closePopup = function () {
                    if (_this.state.openedPopup) {
                        _this.togglePopup();
                    }
                };
                _this.onTableInsert = function (row, col) {
                    var view = _this.props.view;
                    if (!view) {
                        return;
                    }
                    var _a = view.state.schema.nodes, table = _a.table, table_row = _a.table_row, table_cell = _a.table_cell;
                    var newTable = createTable({ table: table, table_row: table_row, table_cell: table_cell }, row, col);
                    if (newTable) {
                        insertNode(newTable, true)(view.state, view.dispatch);
                    }
                    view.focus();
                    _this.closePopup();
                };
                return _this;
            }
            InsertTableTool.prototype.render = function () {
                var _this = this;
                var _a = this.props, view = _a.view, render = _a.render, props = __rest(_a, ["view", "render"]);
                var state = view && view.state;
                var localization = provideLocalizationService(this);
                var _b = settings.messages, createTableMsgKey = _b.createTable, createTableHint = _b.createTableHint;
                var createTableMsg = localization.toLanguageString(createTableMsgKey, messages[createTableMsgKey]);
                var createTableHintMsg = localization.toLanguageString(createTableHint, messages[createTableHint]);
                var disabled = !state || !canInsert(state, state.schema.nodes.table);
                var rendering = [(React.createElement(Button, __assign({ onClick: disabled ? undefined : this.togglePopup, "aria-disabled": disabled ? true : undefined, ref: function (e) { return _this.button = e && e.element; }, key: "InsertTable" }, onDownPreventDefault, { title: createTableMsg }, settings.props, props, { className: classNames(props.className, settings.props.className, { 'k-disabled': disabled }) }))), (view && !disabled && (React.createElement(InsertTablePopupNS.InsertTablePopup, { key: "insertTablePopup", createTableMessage: createTableMsg, createTableHintMessage: createTableHintMsg, dir: props.dir, anchor: this.button, show: this.state.openedPopup, onClose: this.closePopup, onTableInsert: this.onTableInsert })) || null)];
                return render ? render.call(undefined, rendering, { view: view }) : rendering;
            };
            return InsertTableTool;
        }(React.Component));
        registerForLocalization(Tool);
        return Tool;
    };
})(InsertTableToolNS || (InsertTableToolNS = {}));
