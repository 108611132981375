"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import Section from "../../editor/widgets/section";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import TextAreaAttribute from "../../editor/attributes/text_area_attribute";
import TextAttribute from "../../editor/attributes/text_attribute";
import BaseQuickEditor from "../../editor/base_quick_editor";
import TabNavBar from "../../widgets/bars/tab_nav_bar";
import HistoryContainer from "../../editor/history/history_container";
import RelatedRecordsPanel from "../../widgets/relatedRecords/related_records_panel";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { RelatedPanelNotice } from "../../editor/widgets/related_panel_notice";

// i18next-extract-mark-ns-start tpp
class TPPSection extends BaseQuickEditor {
  constructor(props) {
    super(props, "tppSection", "TPPSection", "Target Product Profile (TPP)");

    this.projectId = UIUtils.getParameterByName("projectId");
    this.state.project = {id: this.projectId};

    // Bind stuff
    this.getDisplayName = TPPSection.getDisplayName;
    this.getHTMLURLPrefix = TPPSection.getHTMLURLPrefix;
  }

  static getDisplayName() {
    return "TPP Section";
  }

  static getHTMLURLPrefix() {
    return "product/tpp";
  }

  getURLToLoadData(shouldShowApproved) {
    return `${super.getURLToLoadData(shouldShowApproved)}&includeRelatedRecords=true`;
  }

  hasRelatedItemsTab() {
    return true;
  }

  renderSidePanel(versions) {

    const {
      selectedTab, sidePanelTabs, clonedFrom,
      showMajorVersionsOnly, childInstances
    } = this.state;

    return (
      <Fragment>
        <TabNavBar selected={selectedTab}
                   className="related-tab-nav-margin"
                   onTabChanged={this.handleTabChanged}
                   tabs={UIUtils.deepClone(sidePanelTabs)}
                   parent={this}
                   parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                   parentId={this.state.id}
        />
        {selectedTab?.title === sidePanelTabs.HISTORY?.title ? (
          <HistoryContainer parent={this}
                            isLoading={this.isLoading()}
                            versions={versions}
                            showWithRelatedRecords={true}
                            baseTypeName={this.baseTypeName}
                            clonedFrom={clonedFrom}
                            showMajorVersionsOnly={showMajorVersionsOnly}
          />
        ) : (
          <div>
            <RelatedPanelNotice isApproved={this.isApproved()} />

            <RelatedRecordsPanel id="tppFQARelatedRecords"
                                 header="Final Quality Attributes"
                                 modelName="FQA"
                                 RMP={this.state.RMP}
                                 projectId={this.getProjectId()}
                                 parent={this}
                                 parentId={this.state.id}
                                 isLoading={this.state.isLoading}
                                 records={childInstances
                                   .filter(record => record.modelName === "FQA") || []}
            />
            <RelatedRecordsPanel id="tppFPARelatedRecords"
                                 header="Final Performance Attributes"
                                 modelName="FPA"
                                 RMP={this.state.RMP}
                                 projectId={this.getProjectId()}
                                 parent={this}
                                 parentId={this.state.id}
                                 isLoading={this.state.isLoading}
                                 records={childInstances
                                   .filter(record => record.modelName === "FPA") || []}
            />
          </div>
        )}
      </Fragment>
    );
  }

  getInfoTooltip() {
    return (
      <InfoTooltip id="infoTPP"
                   verbiage={
                     <div>
                       The Target Product Profile (TPP) provides a statement of the overall intent of the drug development program and
                       gives information about the drug at a particular time in development. The TPP is
                       organized according to the key sections in the drug labeling and links drug development
                       activities to specific concepts intended for inclusion in the drug labeling.
                     </div>
                   }
      />
    );
  }

  getTabName() {
    return "TPP";
  }

  renderAttributes() {
    if (!this.projectId) {
      this.projectId = this.state.ProjectId;
    }
    return (
      <div>
        <Section parent={this}
                 header={this.getGeneralHeader()}
                 collapsible={false}
                 showDocLinks={true}
                 removeOptions={["name", "FQA"]}
        >
          <div className="row">
            <TextAttribute name="name"
                           parent={this}
                           className="col-sm-6"
            />
          </div>
          <div className="row">
            <TextAreaAttribute name="target"
                               rows={6}
                               tooltipText={<div>
                                 This area should include labeling language sponsors hope to achieve based on the
                                 outcome of the indicated studies.
                               </div>}
                               className="col-sm-12"
                               parent={this}
            />
          </div>
          <div className="row">
            <TextAreaAttribute name="annotations"
                               rows={6}
                               tooltipText={
                                 <div>
                                   This area should include summary information regarding completed or planned studies
                                   to support the target. Sponsors should also include the protocol number, serial
                                   number, and submission date that will help guide discussion about the overall
                                   development program, the number of studies, and how sponsors will conduct the
                                   studies. Sponsors can include additional information about the studies, but they
                                   should avoid repeating detail contained elsewhere in the Briefing Document.
                                 </div>
                               }
                               className="col-sm-12"
                               parent={this}
            />
          </div>
          <div className="row">
            <TextAreaAttribute name="comments"
                               rows={6}
                               tooltipText={
                                 <div>
                                   This area should include additional information that can aid
                                   communication and understanding (e.g., date of discussions with FDA review staff,
                                   progress toward target, key points during discussions, key issues for discussions,
                                   questions). Sponsors are encouraged to use this area to provide clarity.
                                 </div>}
                               className="col-sm-12" parent={this}
            />
          </div>
        </Section>
      </div>
    );
  }
}

export default I18NWrapper.wrap(TPPSection, "tpp");
// i18next-extract-mark-ns-stop tpp
