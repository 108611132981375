"use strict";

import * as UIUtils from "../../ui_utils";
import MultipleTypeaheadObjectCache from "../../utils/cache/multiple_typeahead_object_cache";
import { ALL_TYPES_FOR_TYPEAHEADS, TYPE_CODE } from "../process_explorer_constants";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import BaseAutoBind from "../../base_auto_bind";
const Logger = Log.group(LOG_GROUP.ProcessExplorer, "ProcessExplorerPage");

export default class RecordCopyHandler extends BaseAutoBind {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.projectId = props.projectId;
  }

  handleHideCopyFromButtonClick() {
    this.parent.setStateSafely({showCopyFromPopup: false});
  }

  handleCopyButton(selectedNode, typeCode) {
    const {parent} = this;
    let newState = {
      showCopyPopup: true,
    };
    if (typeCode) {
      newState = {
        showCopyFromPopup: true,
        copyFromForTypeCode: typeCode
      };
    }
    UIUtils.setLoadingDisabled(false);
    parent.processExplorerHelper.waitForRecordLoading().then(() => {
      parent.setStateSafely({
        ...newState,
        showCopySelectedNode: selectedNode,
      });
    });
  }

  handleCopyStarted() {
    UIUtils.setLoadingDisabled(false);
    UIUtils.setHideLoadingOnAjaxStop(false);
  }

  clearCacheAndReloadOptions(projectId, processId) {
    const {parent} = this;
    const cache = new MultipleTypeaheadObjectCache(ALL_TYPES_FOR_TYPEAHEADS, projectId, processId);
    return cache.invalidateCacheOptionsAsync().then(() => {
      let loadTypeaheadPromise = cache.loadOptions().promise().then((results, typeCode) => {
        return parent.dataReceiver.handleReceivedTypeaheadResults(results, typeCode);
      });
      Logger.info(() => "Loading the data again for the process explorer...");
      const loadPEPromise = parent.dataLoader.loadProcessExplorerData(true, loadTypeaheadPromise);
      return Promise.all([loadPEPromise, loadTypeaheadPromise]);
    });
  }

  handleCopyFromCompletion(projectId, processId) {
    const {parent} = this;
    $(parent.copyRecordFromPopup).modal("hide");
    parent.setStateSafely({showCopyFromPopup: false});

    this.clearCacheAndReloadOptions(projectId, processId)
      .then(() => {
        parent.staticPanel.current.forceUpdateAfterClone();
        UIUtils.setHideLoadingOnAjaxStop(true);
        UIUtils.hideLoadingImage();
        UIUtils.setLoadingDisabled(true);
      });
  }

  handleCopyCompletion(result) {
    const {parent} = this;
    $(parent.copyRecordPopup).modal("hide");
    parent.setStateSafely({showCopyPopup: false});

    this.clearCacheAndReloadOptions(this.projectId, parent.state.processId)
      .then(() => {
        const {selectedNode} = parent.state;
        const {typeCode} = UIUtils.parseKey(selectedNode.staticPanelKey);
        let processExplorerUrl = UIUtils.FRONT_END_URL
          + "/processExplorer/processExplorer.html?projectId=" + this.projectId + "&processId=";
        if (typeCode === TYPE_CODE.PROCESS) {
          let {id} = result;
          window.location.href = UIUtils.getSecuredURL(processExplorerUrl + id);
        } else {
          parent.staticPanel.current.forceUpdateAfterClone();
          UIUtils.setHideLoadingOnAjaxStop(true);
          UIUtils.hideLoadingImage();
          UIUtils.setLoadingDisabled(true);
        }
      });
  }

  handleHideCopyButtonClick() {
    this.parent.setStateSafely({showCopyPopup: false});
  }
}
