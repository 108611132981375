"use strict";

import * as UIUtils from "../../../ui_utils";
import React, { Fragment } from "react";
import * as CannedReportTrainingFilter from "./canned_report_training_filter";
import TrainingReportBase from "./canned_report_training_base";
import * as CommonTraining from "../../../../server/common/editables/common_training";

/* This class shows Document Training canned report */
export default class DocumentTrainingCannedReport extends TrainingReportBase {

  get defaultFilterValues() {
    return {
      reportDate: this.state.reportDate,
      documents: "",
      status: "all",
      version: "latest"
    };
  }

  get typeaheadFilterInfo() {
    return {
      options: this.typeaheadOptions,
      label: "Documents",
      statePropName: "documents",
    };
  }

  get typeaheadOptions() {
    let data = this.results.instances.reportData;
    let {instances} = data;

    let uniqueDocumentIds = new Set(instances.map(instance => instance.documentId));
    let instanceLastVersions = CommonTraining.getDocumentsLatestVersions(instances);

    return Array.from(uniqueDocumentIds).map(id => {
      let documentInstance = instanceLastVersions.find(instance => instance.documentId === id);
      return {
        id,
        label: UIUtils.getRecordCustomLabelForDisplay(documentInstance),
      };
    }).sort(UIUtils.sortBy("label"));
  }

  renderFilters() {
    if (this.results) {
      return <Fragment>
        <div className="col-sm-3">
          {this.renderTypeaheadFilter()}
        </div>
        <div className="col-sm-2">
          {this.renderStatusFilter()}
        </div>
        <div className="col-sm-2">
          {this.renderVersionFilter()}
        </div>
      </Fragment>;
    }
    return null;
  }

  filterResults() {
    let filterOptions = this.state.filterOptions;
    let {documents, status, version} = filterOptions;

    let data = this.results.instances.reportData;
    let {instances, users} = data;

    // Filter by documents
    if (documents && documents.length > 0) {
      instances = instances.filter(instance => documents.includes(instance.documentId));
    }

    // Filter by versions
    if (version === "latest") {
      instances = CommonTraining.getDocumentsLatestVersions(instances);
    }

    // Filter by status
    instances = CannedReportTrainingFilter.filterByStatus(instances, status);

    let results = {
      instances: {
        reportData: {
          instances,
          users
        }
      }
    };

    this.wrapUp(results);
  }
}
