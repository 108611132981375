"use strict";

import React from "react";
import HistoryItemTransition from "./history_item_transition";
import BaseReactComponent from "../../base_react_component";

/* This shows the history of a particular version of a given object. */
export default class HistoryItemTransitionsContainer extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    let versionTransitions = this.props.version.versionTransitions;
    let nextVersionTransitions = nextProps.version.versionTransitions;

    return (nextProps.visible !== this.props.visible ||
      nextProps.version !== this.props.version ||
      nextVersionTransitions !== versionTransitions ||
      nextVersionTransitions.length !== versionTransitions.length);
  }

  render() {
    let versionTransitions = this.props.version.versionTransitions;

    return this.props.visible ? (
      <div className="offset-3 col-9  history-transitions-container">
        <div id="historyVersionTransitionsHeader"
             className="history-transitions-header"
        >Version History:
        </div>
        <div className="history-transitions-body">
          {
            versionTransitions.map((transition, index, transitions) => {
              return (
                <div key={transition.id}>
                  <div>
                    <HistoryItemTransition key={transition.id}
                                           parent={this.props.parent}
                                           index={transition.id}
                                           parentVersionApproved={this.props.versionApproved}
                                           parentVersion={this.props.version}
                                           previousVersion={this.props.previousVersion}
                                           currentTransition={transition}
                                           previousTransition={transitions[index + 1]}
                                           nextTransition={transitions[index - 1]}
                                           visible={!!this.props.visible}
                    />
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    ) : "";
  }
}
