"use strict";

import React from "react";
import ReactDOM from "react-dom";
import BaseReactComponent from "../../base_react_component";
import ImplementationNeededError from "../../utils/implementation_needed_error";
import { Ensure } from "../../../server/common/generic/common_ensure";

// i18next-extract-mark-ns-start base_page
/**
 * This puts the table info & pagination in nice equal sized columns so they aren't just randomly floating around.
 */
export const DATATABLES_BOTTOM_DOM = `<"row dataTables-nav"<"col-6"l><"col-6"i><"col-6"><"col-6"p>>>`;
export const DATATABLES_DOM = `<t${DATATABLES_BOTTOM_DOM}`;
export const DATATABLES_DOM_WITH_SEARCH = `<ft${DATATABLES_BOTTOM_DOM}`;
export const DATATABLES_DOM_WITH_SEARCH_AND_BUTTONS = `<Bft${DATATABLES_BOTTOM_DOM}`;

/**
 * This is a base class for implementing a component based on datatables.
 */
export default class BaseTable extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  getAdditiveSkeletonClass() {
    return "skeleton-table";
  }

  /**
   * Use this to initialize datatables with a set of options. This should return an object with all the options
   * you would want to pass to the DataTable constructor.
   */
  getTableInitializationOptions() {
    throw new ImplementationNeededError();
  }

  /**
   * Implement this to return an array of columns used by datatables
   * @abstract
   * @returns {[]}
   */
  getColumns() {
    throw new ImplementationNeededError();
  }

  /**
   * Apply any pre-processing on the data before they are fed into datatables.
   */
  prepareRecordsForDisplay(records) {
    return records;
  }

  generateColumn(title, prop, className) {
    let column = {
      title: title,
      data: result => result[prop],
      createdCell: (td, cellData) => {
        ReactDOM.render(cellData, td);
      }
    };

    if (className) {
      column.className = className;
    }

    return column;
  }

  componentDidMount() {
    super.componentDidMount();
    this.initializeDataTables();
  }

  componentDidUpdate() {
    this.reloadDataTable();
  }

  componentWillUnmount() {
    const table = $(this.tableRef).DataTable();
    if (table) {
      table.destroy(true);
    }
    super.componentWillUnmount();
  }

  initializeDataTables() {
    if (this.shouldInitializeDataTables()) {
      this.table = $(this.tableRef).DataTable({
        ...this.getTableInitializationOptions(),
      });

      setTimeout(() => {
        this.reloadDataTable();
      }, 200);
    }
  }


  /**
   * This is an extension point so a class that inherits this can optionally add logic before reloading the data table.
   */
  handleBeforeReloadDataTable(table) {
    Ensure.virtual("handleBeforeReloadDataTable", {table});
  }

  /**
   * This is an extension point so a class that inherits this can optionally add logic after reloading the data table.
   */
  handleAfterReloadDataTable(table) {
    Ensure.virtual("handleAfterReloadDataTable", {table});
  }

  /**
   * Returns a boolean that indicates whether or not the data tables can be initialized.
   * This is an extension point.
   * @return {boolean}
   */
  shouldInitializeDataTables() {
    return Ensure.virtual("shouldInitializeDataTables", {}, true);
  }

  reloadDataTable() {
    if (this.tableRef) {
      const table = $(this.tableRef).DataTable();
      if (!table) {
        throw new Error(`The element with id "${this.tableRef.getAttribute("id")}" is not a valid data table.`);
      }
      this.handleBeforeReloadDataTable(table);
      table.clear();
      let preparedRecords = this.prepareRecordsForDisplay(this.records);
      if (preparedRecords.length > 0 || this.shouldForceRedraw()) {
        table.rows.add(preparedRecords);
        /* This is required for recalculating the columns widths
           https://datatables.net/reference/api/columns.adjust()
         */
        try {
          table.draw();
          // uncomment this when debugging
          // console.warn(">> [BaseTable] Adjusting data table widths: ", this.tableRef, table);
          table.columns.adjust().draw();
        } catch (error) {
          // if an error happens while adjusting column widths, we can ignore it
          console.error("[BaseTable] Error adjusting column widths", error, this.tableRef, table);
        }
      }
      this.handleAfterReloadDataTable(table);
    }
  }

  get records() {
    return this.props.records;
  }

  /**
   * Override this if you need to show a diff to a different table state.
   * @return {boolean}
   */
  shouldForceRedraw() {
    return false;
  }
}
// i18next-extract-mark-ns-stop base_page
