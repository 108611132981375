"use strict";

import { Link, useLocation, useNavigate } from "react-router-dom";
import React, {createContext, useCallback, useContext, useEffect, useState} from "react";
import * as UIUtils from "../ui_utils";
import { RouterContext } from "./router_context";

const NavigationContext = createContext({
  isBlocked: false
});

const clearpPreventNavigationInRouterAndBrower = (context) => {
  context.isBlocked = false;
  UIUtils.clearPreventNavigationForBrowser();
};

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useCallback(useNavigate(), []);
    const {search} = useLocation();
    let context = useContext(NavigationContext);

    const getParameterByName = useCallback((paramName) => {
      return search ? UIUtils.getParameterByName(paramName, "?", search) : UIUtils.getParameterByName(paramName);
    }, [search]);

    const customNavigate = useCallback((url) => {
      if (context.isBlocked) {
        const response = confirm(UIUtils.navigationWarning);
        if (response) {
          clearPreventNavigation();
          navigate(url);
        }
      } else {
        navigate(url);
      }
    }, []);

    const clearPreventNavigation = useCallback(() => {
      clearpPreventNavigationInRouterAndBrower(context);
    }, []);

    const preventNavigation = useCallback(() => {
      context.isBlocked = true;

      UIUtils.preventNavigationForBrowser();
    }, []);

    // properties on a component are required because we have consumers that require the context to be available in the constructor.
    // we need to refactor those components to move the code that requires context from constructor to componentDidMount event

    return (
      <RouterContext.Provider value={{navigate, getParameterByName, preventNavigation, clearPreventNavigation}}>
        <Component
          navigate={customNavigate}
          getParameterByName={getParameterByName}
          preventNavigation={preventNavigation}
          clearPreventNavigation={clearPreventNavigation}
          {...props}
        />
      </RouterContext.Provider>
    );
  };

  return Wrapper;
};

export const QBDLink = (props) => {
  let context = useContext(NavigationContext);
  const customOnClick = (event) => {
    if (context.isBlocked) {
      const response = confirm(UIUtils.navigationWarning);
      if (!response) {
        event.preventDefault();
      } else {
        clearpPreventNavigationInRouterAndBrower(context);

        if (props.onClick) {
          props.onClick(event);
        }
      }
    }

    if (props.onClick) {
      props.onClick(event);
    }
  };

  return (
    <Link {...props}
          onClick={customOnClick}
    >{props.children}</Link>
  );
};