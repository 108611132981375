"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { getURLByKey } from "../../helpers/url_helper";
import ReactDOM from "react-dom";
import ValidationIcon from "../../widgets/generic/validation_icon";
import moment from "moment";
import BulkOperationTableBase from "../bulk_operation_table_base";

/* This shows the bulk "Approval results" table on the dashboard. */
export default class BulkApprovalResultsTable extends BulkOperationTableBase {
  constructor(props) {
    super(props);
    this.id = UIUtils.getParameterByName("id");
  }

  componentDidUpdate() {
    let {approvalResult} = this.props;

    if (approvalResult) {
      let rowIndex = null;
      this.table.rows((index, data) => {
        if (data.id === approvalResult.id && data.modelName === approvalResult.modelName) {
          rowIndex = index;
          return true;
        }
      });

      if (approvalResult.result === "success") {
        $(`#approvalStatus_${rowIndex}`).parent().html(
          ReactDOMServer.renderToStaticMarkup(
            <FontAwesomeIcon id={`approvalStatus_${rowIndex}`}
                             icon={faCheckCircle}
                             size="lg" />));
      } else {
        $(`#approvalStatus_${rowIndex}`).parent().html(
          ReactDOMServer.renderToStaticMarkup(
            <ValidationIcon id={UIUtils.generateUUID()}
                            trigger="hover click"
                            hideBullets={true}
                            tooltip={approvalResult.message.split("\n")}
                            visible={true} />));
        $("[data-toggle='popover']").popover({sanitizeFn: UIUtils.sanitizePopoverData});
      }
    }
  }

  getColumns() {
    return [
      {
        title: "Result",
        width: 1,
        class: "bulk-approve-results-approved-table-header",
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          return ReactDOM.render((
            <FontAwesomeIcon id={`approvalStatus_${row}`}
                             className="fa-pulse"
                             icon={faSpinner}
                             size="lg" />), td);
        }
      },
      {
        title: "ID",
        width: 50,
        data: (result, type) => {
          let fullKey = UIUtils.getTypeCodeForModelName(result.modelName) + "-" + result.id;
          if (type === "display") {
            return ReactDOMServer.renderToStaticMarkup(
              <a href={getURLByKey(fullKey, "View", false)}
                 target="_blank" rel="noreferrer">
                {UIUtils.getRecordCustomIdForDisplay(result)}
              </a>
            );
          } else if (type === "sort") {
            return UIUtils.getKeyForSorting(fullKey);
          } else {
            // Checkboxes send in type === "type" and break if we use the sorting key above. "Filter" is another type that gets sent in.
            return fullKey;
          }
        }
      },
      {
        title: "Name",
        width: 300,
        data: (result) => {
          let fullKey = UIUtils.getTypeCodeForModelName(result.modelName) + "-" + result.id;
          return ReactDOMServer.renderToStaticMarkup(
            <a href={getURLByKey(fullKey, "View", false)}
               target="_blank" rel="noreferrer">
              {result.name}
            </a>
          );
        }
      },
      {
        title: "Latest Draft",
        width: 100,
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            result.lastDraftVersion
          );
        }
      },
      {
        title: "Last Viewed",
        width: 100,
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            result.lastViewedVersion
          );
        }
      },
      {
        title: "Proposed By",
        width: 100,
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            <span>{result.proposedBy.firstName} {result.proposedBy.lastName}</span>
          );
        }
      },
      {
        title: "Due Date",
        width: 100,
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            <span>{result.dueDate ? moment(result.dueDate, UIUtils.DATE_FORMAT_FOR_STORAGE).format(UIUtils.DATE_FORMAT_FOR_DISPLAY) : ""}</span>
          );
        }
      },
      {
        title: "Comments",
        width: 300,
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            result.approvalRequestComment
          );
        }
      },
    ];
  }
}
