/* stylelint-disable-next-line import-notation */
@import "../../../../css/_brand_colors.scss";

.widget-item {
  border-bottom: 1px solid $brand-slight-grey;
}

.widget-item:hover {
  background-color: $brand-light-grey;
  cursor: pointer;
}
