import { POPUP_SETTINGS, POPUP_SETTINGS_RTL } from '../consts';
import { isIdZeroLevel } from './itemsIdsUtils';
/**
 * @hidden
 */
export function getPopupSettings(itemId, isMenuVertical, isDirectionRightToLeft) {
    var childrenPosition = getChildrenPosition(itemId, isMenuVertical, isDirectionRightToLeft);
    if (isDirectionRightToLeft) {
        return childrenPosition === 'downward' ? POPUP_SETTINGS_RTL.downward : POPUP_SETTINGS_RTL.leftward;
    }
    else {
        return childrenPosition === 'downward' ? POPUP_SETTINGS.downward : POPUP_SETTINGS.rightward;
    }
}
/**
 * @hidden
 */
export function getChildrenPosition(itemId, isMenuVertical, isDirectionRightToLeft) {
    if (isIdZeroLevel(itemId)) {
        if (isMenuVertical) {
            return isDirectionRightToLeft ? 'leftward' : 'rightward';
        }
        else {
            return 'downward';
        }
    }
    else {
        return isDirectionRightToLeft ? 'leftward' : 'rightward';
    }
}
/**
 * @hidden
 */
export function convertBoolDirectionToString(isDirectionRightToLeft) {
    return isDirectionRightToLeft ? 'rtl' : 'ltr';
}
/**
 * @hidden
 */
export function getDOMElementId(menuGuid, itemId) {
    return "".concat(menuGuid, "_").concat(itemId);
}
