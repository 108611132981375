"use strict";

import React from "react";
import * as styles from "./styles/bulk_edit.module.scss";

const renderInput = (type, value, onChange) => {

  if (!type || type === "STRING") {
    return (
      <input type="text"
             id="valueField"
             name="value"
             className="form-control"
             placeholder={"Value"}
             value={value}
             onChange={onChange}
      />
    );
  } else if (type === "TEXT") {
    return (
      <textarea className="form-control"
                id="valueField"
                name="value"
                placeholder={"Value"}
                value={value}
                onChange={onChange}
      />
    );
  }
};
export const ValueInput = ({type, value, onChange}) => {

  const onInputChange = ({target}) => {
    const {value} = target;
    onChange(value);
  };

  return (
    <label className={styles["drop-down-label"]}>
      Value*
      {renderInput(type, value, onInputChange)}
    </label>
  );
};