/**
 * @hidden
 */
var outerHeight = function (element) {
    if (!element || !element.ownerDocument.defaultView) {
        return 0;
    }
    var wnd = element.ownerDocument.defaultView;
    var computedStyles = wnd.getComputedStyle(element);
    var marginTop = parseFloat(computedStyles.marginTop);
    var marginBottom = parseFloat(computedStyles.marginBottom);
    return element.offsetHeight + marginTop + marginBottom;
};
/**
 * @hidden
 */
var outerWidth = function (element) {
    if (!element || !element.ownerDocument.defaultView) {
        return 0;
    }
    var wnd = element.ownerDocument.defaultView;
    var computedStyles = wnd.getComputedStyle(element);
    var marginLeft = parseFloat(computedStyles.marginLeft);
    var marginRight = parseFloat(computedStyles.marginRight);
    return element.offsetWidth + marginLeft + marginRight;
};
/**
 * @hidden
 */
var styles = {
    'animation-container': 'k-animation-container',
    'animation-container-relative': 'k-animation-container-relative',
    'animation-container-fixed': 'k-animation-container-fixed',
    'push-right-enter': 'k-push-right-enter',
    'push-right-appear': 'k-push-right-appear',
    'push-right-enter-active': 'k-push-right-enter-active',
    'push-right-appear-active': 'k-push-right-appear-active',
    'push-right-exit': 'k-push-right-exit',
    'push-right-exit-active': 'k-push-right-exit-active',
    'push-left-enter': 'k-push-left-enter',
    'push-left-appear': 'k-push-left-appear',
    'push-left-enter-active': 'k-push-left-enter-active',
    'push-left-appear-active': 'k-push-left-appear-active',
    'push-left-exit': 'k-push-left-exit',
    'push-left-exit-active': 'k-push-left-exit-active',
    'push-down-enter': 'k-push-down-enter',
    'push-down-appear': 'k-push-down-appear',
    'push-down-enter-active': 'k-push-down-enter-active',
    'push-down-appear-active': 'k-push-down-appear-active',
    'push-down-exit': 'k-push-down-exit',
    'push-down-exit-active': 'k-push-down-exit-active',
    'push-up-enter': 'k-push-up-enter',
    'push-up-appear': 'k-push-up-appear',
    'push-up-enter-active': 'k-push-up-enter-active',
    'push-up-appear-active': 'k-push-up-appear-active',
    'push-up-exit': 'k-push-up-exit',
    'push-up-exit-active': 'k-push-up-exit-active',
    'expand': 'k-expand',
    'expand-vertical-enter': 'k-expand-vertical-enter',
    'expand-vertical-appear': 'k-expand-vertical-appear',
    'expand-vertical-enter-active': 'k-expand-vertical-enter-active',
    'expand-vertical-appear-active': 'k-expand-vertical-appear-active',
    'expand-vertical-exit': 'k-expand-vertical-exit',
    'expand-vertical-exit-active': 'k-expand-vertical-exit-active',
    'expand-horizontal-enter': 'k-expand-horizontal-enter',
    'expand-horizontal-appear': 'k-expand-horizontal-appear',
    'expand-horizontal-enter-active': 'k-expand-horizontal-enter-active',
    'expand-horizontal-appear-active': 'k-expand-horizontal-appear-active',
    'expand-horizontal-exit': 'k-expand-horizontal-exit',
    'expand-horizontal-exit-active': 'k-expand-horizontal-exit-active',
    'child-animation-container': 'k-child-animation-container',
    'fade-enter': 'k-fade-enter',
    'fade-appear': 'k-fade-appear',
    'fade-enter-active': 'k-fade-enter-active',
    'fade-appear-active': 'k-fade-appear-active',
    'fade-exit': 'k-fade-exit',
    'fade-exit-active': 'k-fade-exit-active',
    'zoom-in-enter': 'k-zoom-in-enter',
    'zoom-in-appear': 'k-zoom-in-appear',
    'zoom-in-enter-active': 'k-zoom-in-enter-active',
    'zoom-in-appear-active': 'k-zoom-in-appear-active',
    'zoom-in-exit': 'k-zoom-in-exit',
    'zoom-in-exit-active': 'k-zoom-in-exit-active',
    'zoom-out-enter': 'k-zoom-out-enter',
    'zoom-out-appear': 'k-zoom-out-appear',
    'zoom-out-enter-active': 'k-zoom-out-enter-active',
    'zoom-out-appear-active': 'k-zoom-out-appear-active',
    'zoom-out-exit': 'k-zoom-out-exit',
    'zoom-out-exit-active': 'k-zoom-out-exit-active',
    'slide-in-appear': 'k-slide-in-appear',
    'centered': 'k-centered',
    'slide-in-appear-active': 'k-slide-in-appear-active',
    'slide-down-enter': 'k-slide-down-enter',
    'slide-down-appear': 'k-slide-down-appear',
    'slide-down-enter-active': 'k-slide-down-enter-active',
    'slide-down-appear-active': 'k-slide-down-appear-active',
    'slide-down-exit': 'k-slide-down-exit',
    'slide-down-exit-active': 'k-slide-down-exit-active',
    'slide-up-enter': 'k-slide-up-enter',
    'slide-up-appear': 'k-slide-up-appear',
    'slide-up-enter-active': 'k-slide-up-enter-active',
    'slide-up-appear-active': 'k-slide-up-appear-active',
    'slide-up-exit': 'k-slide-up-exit',
    'slide-up-exit-active': 'k-slide-up-exit-active',
    'slide-right-enter': 'k-slide-right-enter',
    'slide-right-appear': 'k-slide-right-appear',
    'slide-right-enter-active': 'k-slide-right-enter-active',
    'slide-right-appear-active': 'k-slide-right-appear-active',
    'slide-right-exit': 'k-slide-right-exit',
    'slide-right-exit-active': 'k-slide-right-exit-active',
    'slide-left-enter': 'k-slide-left-enter',
    'slide-left-appear': 'k-slide-left-appear',
    'slide-left-enter-active': 'k-slide-left-enter-active',
    'slide-left-appear-active': 'k-slide-left-appear-active',
    'slide-left-exit': 'k-slide-left-exit',
    'slide-left-exit-active': 'k-slide-left-exit-active',
    'reveal-vertical-enter': 'k-reveal-vertical-enter',
    'reveal-vertical-appear': 'k-reveal-vertical-appear',
    'reveal-vertical-enter-active': 'k-reveal-vertical-enter-active',
    'reveal-vertical-appear-active': 'k-reveal-vertical-appear-active',
    'reveal-vertical-exit': 'k-reveal-vertical-exit',
    'reveal-vertical-exit-active': 'k-reveal-vertical-exit-active',
    'reveal-horizontal-enter': 'k-reveal-horizontal-enter',
    'reveal-horizontal-appear': 'k-reveal-horizontal-appear',
    'reveal-horizontal-enter-active': 'k-reveal-horizontal-enter-active',
    'reveal-horizontal-appear-active': 'k-reveal-horizontal-appear-active',
    'reveal-horizontal-exit': 'k-reveal-horizontal-exit',
    'reveal-horizontal-exit-active': 'k-reveal-horizontal-exit-active'
};
/**
 * @hidden
 */
var stylesMap = {
    outerHeight: outerHeight,
    outerWidth: outerWidth,
    styles: styles
};
export default stylesMap;
