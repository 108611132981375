"use strict";

import * as UIUtils from "../../../../ui_utils";
import React from "react";
import ImplementationNeededError from "../../../../utils/implementation_needed_error";
import ErrorBar from "../../../../widgets/bars/error_bar";
import BaseReactComponent from "../../../../base_react_component";
import { WIZARD_STEPS_CONFIG } from "../constants/link_tech_transfer_constants";


/**
 * This provides base functionality for the Import Wizard steps.
 * Certain properties are not provided directly in the source code but are native supported by the react-step-wizard
 * control, like:
 * - this.props.currentStep
 * - this.props.nextStep()
 * - this.props.previousStep()
 * https://www.npmjs.com/package/react-step-wizard
 */
export default class LinkTechTransferBaseStep extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: this.props.config.visible
    };
  }

  componentDidUpdate() {
    const {stepError, onWizardStepDataUpdated} = this.props;
    if (stepError) {
      UIUtils.showError(stepError);
      onWizardStepDataUpdated({
        stepError: null
      });
    }
  }

  handleMoveToNextStep() {
    const {config, totalSteps, handleMoveToStep} = this.props;
    let currentStepIndex = WIZARD_STEPS_CONFIG.indexOf(config);
    if (totalSteps > currentStepIndex + 1) {
      let newStepConfig = WIZARD_STEPS_CONFIG[currentStepIndex + 1];
      handleMoveToStep(newStepConfig.step);
    }
  }

  activate() {
    UIUtils.clearError();
    this.setStateSafely({
      visible: true
    });
  }

  deactivate(stepIndexToMoveTo) {
    UIUtils.clearError();
    this.props.goToStep(stepIndexToMoveTo);
  }

  handleExit() {
    const projectId = UIUtils.parseInt(UIUtils.getParameterByName("projectId"));
    const processId = UIUtils.parseInt(UIUtils.getParameterByName("processId"));

    const url ="/processExplorer/processExplorer.html?" + `projectId=${projectId}` + `&processId=${processId}`;

    window.location.href = UIUtils.getSecuredURL(url);
  }

  renderExitButton() {
    return (
      <button className="btn btn-lg btn-secondary"
              id="exitImportButton"
              onClick={this.handleExit}
      >
        Exit
      </button>
    );
  }

  renderStep() {
    throw new ImplementationNeededError();
  }

  render() {
    const {currentStep} = this.props;
    const {visible} = this.state;
    return (
      <div className={"row row-white import-wizard-steps-container"
        + (currentStep === 1 ? " first-wizard-step" : "")
        + (!visible ? " hidden" : "")}
      >
        <ErrorBar className="link-tech-transfer-error-bar" />
        {this.renderStep()}
      </div>
    );
  }
}
