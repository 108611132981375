"use strict";

import React, { Component } from "react";

export class ValueTooltip extends Component {
  render() {
    let {label, value, defaultValue} = this.props;
    if (!defaultValue) {
      defaultValue = "";
    }

    return (
      <div>
        <span>{label}: {value ? value : defaultValue}</span>
      </div>
    );
  }
}
