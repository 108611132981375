"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ReactDOMServer from "react-dom/server";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { getLabelTooltip } from "./helpers/document_bulk_add_table_renderer";
import { TABLE_FIELDS } from "./constants/constants";
import DocumentBulkAddTableBase from "./document_bulk_add_table_base";
import moment from "moment/moment";
import { getURLByTypeCodeAndId } from "../../../helpers/url_helper";
import { renderResultsColumn } from "./helpers/document_bulk_add_table_renderer";

/**
 * This class is what shows the bulk add results table.
 */
export default class DocumentBulkAddResultsTable extends DocumentBulkAddTableBase {
  constructor(props) {
    super(props, "documentBulkAddResultsTable");

    this.processedRows = [];
  }

  componentDidUpdate() {
    let {processedRowsData, documents, isBulkAddCompleted} = this.props;

    // Update the status icon to be the green mark for processed rows
    for (let record of processedRowsData
      .filter(row => !this.processedRows.includes(row.id))) {

      let rowIndex = this.UUIDToRowIndex.get(record.uuid);
      this.processedRows.push(record.id);

      $(`#uploadStatus_${rowIndex}`).parent().html(
        ReactDOMServer.renderToStaticMarkup(
          <FontAwesomeIcon id={`uploadStatus_${rowIndex}`}
                           className="document-bulk-add-upload-success"
                           icon={faCheckCircle}
                           size="sm" />));
    }


    // Once bulk add is done we update the file names to be links to documents
    if (isBulkAddCompleted) {
      for (let record of processedRowsData) {
        let rowIndex = this.UUIDToRowIndex.get(record.uuid);
        let file = documents.find(file => record.name === file.name);

        $(`#fileName_${rowIndex}`).parent().html(
          ReactDOMServer.renderToStaticMarkup(
            <a target="_blank"
               href={getURLByTypeCodeAndId("DOC", "View", record.id)}>
              {file.fileName}
            </a>));
      }
    }
  }

  generateColumns() {
    return [
      {
        title: "",
        width: 1,
        class: "bulk-approve-results-approved-table-header",
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          return ReactDOM.render((
            <FontAwesomeIcon id={`uploadStatus_${row}`}
                             className="fa-pulse"
                             icon={faSpinner}
                             size="sm" />), td);
        }
      },
      {
        title: "File Name",
        width: 1,
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          return renderResultsColumn(td, `fileName_${row}`, rowData.fileName);
        },
      },
      {
        title: getLabelTooltip(TABLE_FIELDS.DOCUMENT_NAME.title, TABLE_FIELDS.DOCUMENT_NAME.tooltip),
        width: 125,
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          return renderResultsColumn(td, `documentName_${row}`, rowData.name);
        },
      },
      {
        title: getLabelTooltip(TABLE_FIELDS.CUSTOM_ID.title, TABLE_FIELDS.CUSTOM_ID.tooltip),
        width: 50,
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          return renderResultsColumn(td, `customID_${row}`, rowData.customID);
        },
      },
      {
        title: getLabelTooltip(TABLE_FIELDS.VERSION.title, TABLE_FIELDS.VERSION.tooltip),
        width: 25,
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          return renderResultsColumn(td, `version_${row}`, rowData.version);
        },
      },
      {
        title: "Document Type",
        width: 200,
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          return renderResultsColumn(td, `documentType_${row}`, rowData.type);
        },
      },
      {
        title: "Category",
        width: 125,
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          return renderResultsColumn(td, `documentCategory_${row}`, rowData.category);
        },
      },
      {
        title: "Effective Date",
        width: 125,
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          return renderResultsColumn(td, `effectiveDate_${row}`,
            moment(rowData.effectiveDate).isValid() ?
              moment(rowData.effectiveDate).format(UIUtils.DATE_FORMAT_FOR_DISPLAY) : "");
        },
      }
    ];
  }
}
