import * as UIUtils from "../../ui_utils";
import moment from "moment/moment";
import ReactDOM from "react-dom";
import React from "react";

/**
 * Defines convenient templates to create columns. Just use them with the spread operator (e.g: ...COLUMN_FORMATTERS.DATE).
 */
export const COLUMN_FORMATTERS = {
  DATE: (dateFormat = UIUtils.LONG_DATE_FORMAT_FOR_DISPLAY) => {
    return {
      createdCell: (td, cellData) => {
        cellData = cellData || "";
        if (cellData) {
          cellData = moment(cellData).isValid()
            ? moment(cellData).format(dateFormat)
            : cellData;
        }

        ReactDOM.render(
          <>
            {cellData}
          </>, td);
      },
    };
  },
  VERSION: {
    width: 50,
    data: result => `${result.majorVersion}.${result.minorVersion}`,
  },
  CURRENT_STATE: {
    width: 50,
    data: result => result.currentState,
  },
};
