"use strict";

import React from "react";
import BatchesFilteredList from "./batches_filtered_list";
import BasePage from "../base_page";
import EditablesPageTitleBar from "../widgets/pageTitleBar/editables_page_title_bar";
import NavBar from "../widgets/bars/nav_bar";
import ErrorBar from "../widgets/bars/error_bar";
import SplashScreen from "../widgets/generic/splash_screen";
import * as UIUtils from "../ui_utils";
import { can, canViewPage, generateTooltip } from "../utils/ui_permissions";
import TabNavBar from "../widgets/bars/tab_nav_bar";
import SearchBox from "../widgets/tables/search_box";
import { getURLByTypeCodeAndId } from "../helpers/url_helper";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import { DATA_TYPES, IMPORT_TYPES } from "../import/constants/import_constants";
import BatchDataImportButton from "./widgets/batch_data_import_button";
import { BATCH_RECORD_TYPE_ENUM } from "./batch_constants";
import * as FailHandlers from "../utils/fail_handlers";
import CommonSecurity from "../../server/common/generic/common_security";
import { StatusDisplayService } from "../services/status_display_service";
import { BatchesService } from "../services/import/batches_service";
import { WebSocketsMessageHandler } from "../services/web_sockets_message_handler";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Entities, "BatchListPage");

const BATCH_LIST_TABS_ENUM = {
  BATHES_TAB: {
    id: "drugSubstanceProductTab",
    key: "batch",
    title: "Drug Substance/Product",
    disabled: false,
  },
  MATERIALS_TAB: {
    id: "rawMaterialsTab",
    key: "coa",
    title: "Raw Materials",
    disabled: false,
  },
};

/* This shows the Data tab. */

// i18next-extract-mark-ns-start batches
class BatchListPage extends BasePage {
  constructor(props) {
    super(props);

    UIUtils.setLoadingDisabled(true);

    this.batchesDisplayService = new StatusDisplayService();
    this.batchDataMessageService = new WebSocketsMessageHandler(this.batchesDisplayService);
    this.batchesDataService = new BatchesService(this.batchDataMessageService, this.batchesDisplayService);

    this.state = {
      ...this.state,
      projectId: UIUtils.parseInt(UIUtils.getParameterByName("projectId")),
      batchListsTabs: UIUtils.deepClone(BATCH_LIST_TABS_ENUM),
      showRemoved: false,
      allBatches: [],
      batches: [],
      coas: [],
      filteredBatchesNum: 0,
      filteredCoAsNum: 0,
      selectedTab: BATCH_LIST_TABS_ENUM.BATHES_TAB,
      hasLibraryMaterial: true
    };

  }

  componentDidMount() {
    super.componentDidMount();

    const {t} = this.props;
    document.title = t("Manufacturing Data");
    this.loadData(false);
  }

  componentDidUpdate() {
    const {filteredBatchesNum, filteredCoAsNum} = this.state;

    if (this.batchesList && this.coaList) {
      const filteredBatchRowsNum = this.batchesList.getFilteredRowsNum();
      const filteredCoARowsNum = this.coaList.getFilteredRowsNum();

      if (filteredBatchesNum !== filteredBatchRowsNum
        || filteredCoAsNum !== filteredCoARowsNum) {
        this.setStateSafely({
          filteredBatchesNum: filteredBatchRowsNum,
          filteredCoAsNum: filteredCoARowsNum
        });
      }
    }
  }

  loadData(useWriterDB) {
    this.setStateSafely({isLoading: true});
    const urlParameters = {
      showRemoved: true,
      includeHistory: true,
      loadFullHistory: true,
      shouldCompress: true,
      ...(useWriterDB ? {useWriterDB: true} : {}),
    };

    UIUtils.secureAjaxGET(
      `editables/Batch/list/${this.state.projectId}`,
      urlParameters,
      true,
      FailHandlers.defaultStopLoadingFailFunction.bind(this)
    ).then(results => this.handleResultsReceivedFromServer(results));
  }


  handleResultsReceivedFromServer(results) {
    let {batchListsTabs} = this.state;

    const batches = this.getRecordsForList(results.instances, BATCH_RECORD_TYPE_ENUM.BATCH);
    const coas = this.getRecordsForList(results.instances, BATCH_RECORD_TYPE_ENUM.COA);
    this.updateTabCaptions(batchListsTabs, batches.length, coas.length);
    Logger.debug(`Received ${batches.length} batch records.`);
    Logger.debug(`Received ${coas.length} CoA records.`);

    this.checkLibraryMaterial();

    this.setStateSafely({
      allBatches: results.instances,
      materials: results.materials,
      batches,
      coas,
      importDependenciesStatus: new Map(Object.entries(results.importDependencies)),
      batchListsTabs,
      selectedTab: batchListsTabs.BATHES_TAB,
      isLoading: false,
    });
  }

  checkLibraryMaterial() {
    this.setStateSafely({
      hasLibraryMaterial: true
    });

    UIUtils.secureAjaxGET(
      `materialLibrary/hasData`,
      {},
      true,
      FailHandlers.defaultStopLoadingFailFunction.bind(this)
    ).then(results => {
      this.setStateSafely(results);
    });
  }

  handleAdd() {
    window.location.href = UIUtils.getSecuredURL("/batches/viewEdit.html?operation=Add&projectId=" + this.state.projectId);
  }

  handleTabChanged(tab) {
    this.setStateSafely({
      selectedTab: tab,
    });
  }

  handleMapItemForTypeahead(item) {
    return {
      ...item,
      id: item.id,
      label: `${item.customID}`,
    };
  }

  handleSuggestionSelect(suggestion) {
    UIUtils.setLoadingDisabled(false);
    UIUtils.showLoadingImage();

    const typeCode = UIUtils.getTypeCodeForModelName("Batch");
    let url = getURLByTypeCodeAndId(typeCode, "View", suggestion.id, true);
    window.location.href = UIUtils.getSecuredURL(url);
  }

  handleSearchTermChange(searchTerm) {
    this.setStateSafely({searchTerm});
  }

  getRecordsForList(allRecords, recordType) {
    return allRecords.filter(rec => rec.type === recordType);
  }

  handleDataRefreshRequired() {
    this.loadData(true);
  }

  handleShowRemovedToggle(showRemoved) {
    this.setStateSafely({
      showRemoved,
    });
  }

  renderBatchList(cachedInstances, dataType) {
    const {projectId, searchTerm, materials, importDependenciesStatus} = this.state;
    const tableIsVisible = this.isListVisible(dataType);
    const useGrouping = dataType === DATA_TYPES.MATERIAL;

    return (
      <div className={"group-by-table-container list-tables " + (tableIsVisible ? "" : "batch-list-hidden")}>
        <BatchesFilteredList
          key={dataType}
          id={dataType === DATA_TYPES.MATERIAL ? "coaListTable" : "batchesListTable"}
          ref={ref => {
            if (dataType === DATA_TYPES.MATERIAL) {
              this.coaList = ref;
            } else {
              this.batchesList = ref;
            }
          }}
          hideQuickPanelUI={!tableIsVisible}
          parent={this}
          useGrouping={useGrouping}
          fixedOrderedColumn={useGrouping ? 0 : null}
          projectId={projectId}
          dataType={dataType}
          showImportButton={dataType === DATA_TYPES.DRUG_SUBSTANCE_PRODUCT}
          cachedInstances={cachedInstances}
          materials={materials}
          searchTerm={searchTerm}
          filterSelector={item => [item.customID]}
          importDependenciesStatus={importDependenciesStatus}
          onDataRefreshRequired={this.handleDataRefreshRequired}
          onShowRemovedToggle={this.handleShowRemovedToggle}
          isLoading={this.isLoading()}
        />
      </div>
    );
  }

  isListVisible(dataType) {
    const {selectedTab} = this.state;
    return dataType === DATA_TYPES.MATERIAL && selectedTab.key === BATCH_LIST_TABS_ENUM.MATERIALS_TAB.key
      || dataType === DATA_TYPES.DRUG_SUBSTANCE_PRODUCT && selectedTab.key === BATCH_LIST_TABS_ENUM.BATHES_TAB.key;
  }

  getImportTypeConfigs() {
    const {selectedTab, hasLibraryMaterial} = this.state;
    if (selectedTab.key === BATCH_LIST_TABS_ENUM.BATHES_TAB.key) {
      return IMPORT_TYPES.PROCESS_DATA.filter(config => config.dataType === DATA_TYPES.DRUG_SUBSTANCE_PRODUCT);
    } else {
      if (!hasLibraryMaterial) {
        return IMPORT_TYPES.PROCESS_DATA.filter(config => config.dataType === DATA_TYPES.MATERIAL);
      } else {
        return [];
      }
    }
  }

  updateTabCaptions(batchListsTabs, filteredBatchesNum, filteredCoAsNum) {
    batchListsTabs.BATHES_TAB.title = `${BATCH_LIST_TABS_ENUM.BATHES_TAB.title} (${this.isLoading() ? "" : filteredBatchesNum})`;
    batchListsTabs.MATERIALS_TAB.title = `${BATCH_LIST_TABS_ENUM.MATERIALS_TAB.title} (${this.isLoading() ? "" : filteredCoAsNum})`;
  }

  renderPage() {
    const {
      projectId,
      project,
      selectedTab,
      batchListsTabs,
      allBatches,
      batches,
      coas,
      filteredBatchesNum,
      filteredCoAsNum,
      importDependenciesStatus
    } = this.state;
    const {t} = this.props;

    this.updateTabCaptions(batchListsTabs, filteredBatchesNum, filteredCoAsNum);
    const totalBatchRecordsInList = selectedTab.key === BATCH_LIST_TABS_ENUM.BATHES_TAB.key ? batches.length : coas.length;
    const disableAllFlows = totalBatchRecordsInList === 0
      && selectedTab.key === BATCH_LIST_TABS_ENUM.BATHES_TAB.key;

    return (
      <div>
        <EditablesPageTitleBar projectId={projectId}
                               projectName={project ? project.name : ""}
                               projectDeletedAt={project ? project.deletedAt : ""}
                               isDemoProject={project ? project.isDemo : ""}
                               currentState={project ? project.currentState : null}
        />
        <NavBar selected="Data" projectId={projectId} />
        {canViewPage(CommonSecurity.Types.CONTROL_METHOD)
          ? (
            <div className="container batch-list-page">
              <ErrorBar />
              <div className="row row-white shadow">
                <div className="col">
                  <div className="row">
                    <div className="col-sm-12">
                      <SearchBox
                        items={allBatches.map(this.handleMapItemForTypeahead)}
                        id="batchesSearchTypeahead"
                        onSuggestionSelected={this.handleSuggestionSelect}
                        onSearchTermChange={this.handleSearchTermChange}
                        filterSelector={item => [item.customID]}
                        showId={false}
                        editableName="Batch"
                        showVersion={false}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col batch-list-page-nav-bar">
                      <TabNavBar selected={selectedTab}
                                 className="batches-list-tab"
                                 onTabChanged={this.handleTabChanged}
                                 tabs={UIUtils.deepClone(batchListsTabs)}
                                 parent={this}
                                 parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                 parentId={this.state.id}
                      />
                      {this.getImportTypeConfigs().length > 0 ?
                        <BatchDataImportButton parent={this}
                                               projectId={projectId}
                                               batchId={this.state && this.state.id}
                                               disableAllFlows={disableAllFlows}
                                               disabledAllFlowsReason={disableAllFlows ? "Create a batch before importing data." : ""}
                                               importDependenciesStatus={importDependenciesStatus}
                                               importTypeConfigs={this.getImportTypeConfigs()}
                        /> : ""}
                      {selectedTab.key === BATCH_LIST_TABS_ENUM.BATHES_TAB.key ? (
                        <button className="btn btn-lg btn-primary" id="addButton"
                                disabled={!can(CommonSecurity.Actions.ADD, CommonSecurity.Types.BATCH)}
                                title={t(generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.BATCH))}
                                onClick={this.handleAdd}
                        >
                          {t("Add")}
                        </button>
                      ) : ""}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      {this.renderBatchList(batches, DATA_TYPES.DRUG_SUBSTANCE_PRODUCT)}
                      {this.renderBatchList(coas, DATA_TYPES.MATERIAL)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : <SplashScreen />}
      </div>
    );
  }
}

export default I18NWrapper.wrap(BatchListPage, "batches");
// i18next-extract-mark-ns-stop batches
