var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { NumericTextBox, Checkbox, Input } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { messages } from '../messages';
import { ColumnMenuOperators } from './ColumnMenuOperators';
var ColumnMenuFilter = function (props) {
    var filter = props.filter, onFilterChange = props.onFilterChange, operators = props.operators, initialFilter = props.initialFilter;
    var operatorChange = React.useCallback(function (event) {
        var prevFilter = filter;
        var operator = event.target.value.operator;
        var nextFilter = prevFilter ? __assign(__assign({}, prevFilter), { operator: operator }) : { value: initialFilter.value, operator: operator };
        onFilterChange.call(undefined, event.syntheticEvent, nextFilter, prevFilter);
    }, [filter, onFilterChange, initialFilter]);
    var inputChange = React.useCallback(function (event) {
        var value = event.target.value;
        var prevFilter = filter;
        var nextFilter = prevFilter ? __assign(__assign({}, prevFilter), { value: value }) : { value: value, operator: operators[0].operator };
        onFilterChange.call(undefined, event, nextFilter, prevFilter);
    }, [filter, onFilterChange, operators]);
    var localization = useLocalization();
    var data = operators.map(function (o) { return (__assign(__assign({}, o), { text: localization.toLanguageString(o.text, messages[o.text]) })); });
    return (React.createElement(React.Fragment, null,
        React.createElement(DropDownList, { value: data.find(function (item) { return item.operator === filter.operator; }) || null, onChange: operatorChange, data: data, textField: "text" }),
        React.createElement(props.input, { value: filter.value, onChange: inputChange })));
};
export var ColumnMenuBooleanFilter = function (props) {
    var filter = props.filter, onFilterChange = props.onFilterChange;
    var inputChange = React.useCallback(function (event) { return onFilterChange.call(undefined, event.syntheticEvent, __assign(__assign({}, filter), { value: event.value }), filter); }, [filter, onFilterChange]);
    var isTrue = ColumnMenuOperators.boolean[0].text;
    return (React.createElement(Checkbox, { onChange: inputChange, checked: filter.value, label: useLocalization().toLanguageString(isTrue, messages[isTrue]), labelPlacement: "after" }));
};
export var ColumnMenuTextFilter = function (props) {
    var input = React.useCallback(function (pr) { return React.createElement(Input, __assign({}, pr, { value: pr.value || '' })); }, []);
    var operators = React.useRef(ColumnMenuOperators.text);
    var initialFilter = React.useRef({ operator: '', value: '' });
    return (React.createElement(ColumnMenuFilter, __assign({ initialFilter: initialFilter.current, input: input, operators: props.operators || operators.current }, props)));
};
export var ColumnMenuNumericFilter = function (props) {
    var input = React.useCallback(function (pr) { return React.createElement(NumericTextBox, __assign({}, pr, { value: pr.value || null })); }, []);
    var operators = React.useRef(ColumnMenuOperators.numeric);
    var initialFilter = React.useRef({ operator: '', value: null });
    return (React.createElement(ColumnMenuFilter, __assign({ initialFilter: initialFilter.current, input: input, operators: props.operators || operators.current }, props)));
};
export var ColumnMenuDateFilter = function (props) {
    var input = React.useCallback(function (pr) { return React.createElement(DatePicker, __assign({}, pr, { value: pr.value || null })); }, []);
    var operators = React.useRef(ColumnMenuOperators.date);
    var initialFilter = React.useRef({ operator: '', value: null });
    return (React.createElement(ColumnMenuFilter, __assign({ initialFilter: initialFilter.current, input: input, operators: props.operators || operators.current }, props)));
};
