"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import { getURLByTypeNameAndId } from "../../helpers/url_helper";
import TypeaheadObjectCacheFactory from "../../utils/cache/typeahead_object_cache_factory";
import TypeaheadAttribute from "./typeahead_attribute";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import MemoryCache from "../../utils/cache/memory_cache";
import { measurePerformanceStart } from "../../ui_utils";
import BaseObjectCache from "../../utils/cache/base_object_cache";

const Logger = Log.group(LOG_GROUP.Editables, "MultiTypeTypeaheadAttribute");


/**
 * Use this to set one or more attributes in an editable with multiple types using a Typeahead control. See LinksAttribute
 * if you need a multi-select typeahead with a join table.
 *
 * NOTE: If you want to set the props `multiple={true}` or `options={someArrayOfStrings}` that still need to be implemented.
 */
export default class MultiTypeTypeaheadAttribute extends TypeaheadAttribute {
  constructor(props) {
    super(props);
  }

  /**
   * Get the name of the attribute on the parent object that contains the value for this typeahead.
   */
  getParentAttributeName(parent = this.props.parent.state) {
    const {attributeNameToTypeaheadType} = this.props;

    const attributeNames = Object.keys(attributeNameToTypeaheadType);
    for (const attributeName of attributeNames) {
      if (parent[attributeName]) {
        return attributeName;
      }
    }

    // Nothing matches. Use the first one.
    return attributeNames[0];
  }

  usesTypeaheadCache() {
    return true;
  }

  async loadTypeaheadOptionsInner(props = this.props, forceRetrieve) {
    const typesToCache = this.getTypeCodesForCache();
    const memoryCache = this.getInMemoryCache();
    const remainingTypesToCache = typesToCache.filter(typeCode => !memoryCache.get(this.getMemoryCacheKeyForTypeahead(typeCode)));

    if (remainingTypesToCache.length > 0 || forceRetrieve) {
      await this.loadTypeaheadOptionsFromServer(props, remainingTypesToCache, forceRetrieve);
    } else {
      this.checkForTypeaheadsAlreadyAvailableInMemoryCache();
    }
  }

  async loadTypeaheadOptionsFromServer(props, remainingTypesToCache, forceRetrieve) {
    // We cannot use MultipleTypeaheadObjectCache because it doesn't have the concept of Archived records

    for (let typeCode of remainingTypesToCache) {
      await this.loadTypeaheadOptionsFromServerForTypeCode(props, typeCode, forceRetrieve);
    }
  }

  async loadTypeaheadOptionsFromServerForTypeCode(props, typeCode, forceRetrieve) {
    const typeaheadType = UIUtils.getModelNameForTypeCode(typeCode).replace(" ", "");
    const memoryCache = this.getInMemoryCache();
    const cacheKey = this.getMemoryCacheKeyForTypeahead(typeCode);
    if (!memoryCache.get(cacheKey) || forceRetrieve) {
      Logger.debug(`MultipleTypeaheadAttribute :: loading ${typeCode}`);

      const interactionName = `MultipleTypeaheadAttribute :: ${cacheKey} :: Load options from cache/sessionStorage for Model: ${typeaheadType}. ProjectId: ${this.getProjectId()}. ProcessId: ${this.getProcessId()}`;
      measurePerformanceStart(interactionName);

      const typeaheadObjectCache = TypeaheadObjectCacheFactory.createTypeaheadObjectCacheIfPossible(
        typeaheadType, this.getProjectId, this.getProcessId);
      if (!typeaheadObjectCache) {
        Logger.debug(() => `MultipleTypeaheadAttribute :: loading ${typeCode} failure - Cannot build cache for project ${this.getProjectId()} and processid ${this.getProcessId()}`);
        return;
      }

      const cachedResults = typeaheadObjectCache.getOptionsFromCacheIncludingArchived();
      if (!BaseObjectCache.isObjectCacheStillLoading(cachedResults)) {
        Logger.debug(() => `MultipleTypeaheadAttribute :: already loaded from cache ${typeCode}`, Log.object(cachedResults));
        memoryCache.set(cacheKey, cachedResults.map(option => this.convertTypeaheadOption(option, typeCode)));
        this.checkForTypeaheadsAlreadyAvailableInMemoryCache();
      } else {
        const loadeResults = await typeaheadObjectCache.loadOptions().promise();
        if (loadeResults) {
          const results = typeaheadObjectCache.getOptionsFromCacheIncludingArchived();
          memoryCache.set(cacheKey, results.map(option => this.convertTypeaheadOption(option, typeCode)));

          this.displayDebugInformation(interactionName);
          Logger.debug(`MultipleTypeaheadAttribute :: loaded ${typeCode}`);
          this.checkForTypeaheadsAlreadyAvailableInMemoryCache();
        }
      }
    } else {
      Logger.debug(`MultipleTypeaheadAttribute :: already loaded ${typeCode}`);
      this.checkForTypeaheadsAlreadyAvailableInMemoryCache();
    }
  }

  convertTypeaheadOption(option, typeCode) {
    return {
      id: typeCode + "-" + option.id,
      label: option.label,
      name: option.name,
      instanceId: option.id,
      deletedAt: option.deletedAt,
      attributeName: this.getParentAttributeNameForTypeCode(typeCode),
    };
  }

  getParentAttributeNameForTypeCode(typeCode) {
    return this.getModels()[typeCode] + "Id";
  }

  getTypeCodesForCache() {
    return Object.keys(this.getModels());
  }

  getModels() {
    return Object.values(this.props.attributeNameToTypeaheadType).reduce((map, modelName) => {
      map[UIUtils.getTypeCodeForModelName(modelName)] = modelName;
      return map;
    }, {});
  }


  checkForTypeaheadsAlreadyAvailableInMemoryCache() {
    const memoryCache = this.getInMemoryCache();
    const typesToCache = this.getTypeCodesForCache();

    let typeaheadOptions = [];
    let loadedCaches = [];
    for (let typeCode of typesToCache) {
      const typeaheadOptionsFromCache = memoryCache.get(this.getMemoryCacheKeyForTypeahead(typeCode));
      if (typeaheadOptionsFromCache) {
        loadedCaches.push(typeCode);
      }
      typeaheadOptions = typeaheadOptions.concat(typeaheadOptionsFromCache ?? []);
    }

    Logger.debug(() => `MultipleTypeaheadAttribute - checkForTypeaheadsAlreadyAvailableInMemoryCache - Caches loaded ${loadedCaches.join(", ")} from ${typesToCache.join(", ")}`);

    this.setStateSafely({
      isTypeaheadLoaded: typesToCache.length === loadedCaches.length,
      typeaheadOptions: typeaheadOptions
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      return JSON.stringify(nextProps.attributeNameToTypeaheadType) !== JSON.stringify(this.props.attributeNameToTypeaheadType) ||
        nextProps.parent.baseTypeName !== this.props.parent.baseTypeName;
    }

    return true;
  }

  getInputId() {
    return (this.props.name || UIUtils.uncapitalize(UIUtils.convertToId(this.props.displayName))) + "Typeahead";
  }

  handleChange(options) {
    const {parent} = this.props;
    const parentAttributeName = this.getParentAttributeName();
    if (options.length === 0) {
      parent.handleChangeValue(parentAttributeName, null);
    } else {
      let {instanceId, attributeName} = options[0];
      if (attributeName !== parentAttributeName) {
        parent.handleChangeValue(parentAttributeName, null); // Clear out the old value when changing types.
      }
      parent.handleChangeValue(attributeName, instanceId);
      this.currentSelectedOptions = this.getSelectedOptionsForValue(attributeName, instanceId, this.state.typeaheadOptions);
      this.clearValidationErrors();
    }
  }

  getSelectedOptionsForValue(parentAttributeName, value, typeaheadOptions) {
    let selectedOptions = [];
    if (typeaheadOptions) {
      const option = typeaheadOptions.find(option => {
        return option.attributeName === parentAttributeName && option.instanceId === value;
      });
      if (option) {
        selectedOptions.push(option);
      } else if (value && typeaheadOptions && typeaheadOptions.length > 0 && value !== "UNDEFINED") {
        Logger.warn(() => `WARNING: Could not find an option for ${parentAttributeName}-${value} in typeahead ${this.getInputId()} with values: ${Log.json(typeaheadOptions)}`);
      }
    }
    return selectedOptions;
  }

  getOldSelectedOptions(olderVersion, typeaheadOptions) {
    const oldAttributeName = this.getParentAttributeName(olderVersion);
    let oldValue = olderVersion[oldAttributeName];
    return this.getSelectedOptionsForValue(oldAttributeName, oldValue, typeaheadOptions);
  }

  renderViewTypeaheadSelection(inputId, selectedOptions) {
    const {attributeNameToTypeaheadType} = this.props;
    return <span id={inputId}>
              {selectedOptions && selectedOptions.length > 0 ?
                selectedOptions.map(selectedOption =>
                  <a href={getURLByTypeNameAndId(attributeNameToTypeaheadType[selectedOption.attributeName], "View", selectedOption.instanceId)}
                     key={selectedOption.id}
                  >
                    {selectedOption.label}
                  </a>
                ) : ""}
            </span>;
  }

  getInMemoryCache() {
    return MemoryCache.getNamedInstance(`multi_typeahead_attribute`);
  }
}
