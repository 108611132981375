"use strict";

import ImplementationNeededError from "../../../../../utils/implementation_needed_error";

export const NONE = "None";


/**
 * This class acts like an interface to ensure all derived types implement its methods
 * @abstract
 */
export class RiskTablesTransformStrategy {
  /**
   * return which report this strategy serves
   * @abstract
   */
  get parentReportType() {
    throw new ImplementationNeededError("The method 'parentReportType' needs to be implemented on a derived class");
  }

  /**
   * Returns the modeType this strategy serves
   * @abstract
   */
  get modelType() {
    throw new ImplementationNeededError("The method 'modelType' needs to be implemented on a derived class");
  }
}
