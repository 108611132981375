"use strict";

import { ServiceBase } from "../service_base";

/**
 * @typedef IImportServiceParams {Object}
 * @property {boolean} [useTwoWayCommunication]
 * @property {boolean} [idempotent]
 * @property {boolean} [clearUIErrors]
 * @property {boolean} [global]
 * @property {Map<string, string>} [statusMap]
 * @property {boolean} [autoClose]
 */

/**
 * Service layer for Cloning functionality. Use this to execute cloning operations on the backend.
 */
export class CloningService extends ServiceBase {
  /**
   * Clones a record
   * @param payload The payload for the request
   */
  clone(payload) {
    let parameters = {
      action: "clone",
      useTwoWayCommunication: true,
    };

    return this.sendToBackend(payload, parameters);
  }
}