"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import BasePage from "../base_page";
import EditablesPageTitleBar from "../widgets/pageTitleBar/editables_page_title_bar";
import ErrorBar from "../widgets/bars/error_bar";
import ProjectListTable from "./project_list_table";
import { can, generateTooltip } from "../utils/ui_permissions";
import { EXPERIMENTS } from "../helpers/constants/constants";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import CommonSecurity from "../../server/common/generic/common_security";

/* This class shows the list of projects. */

// i18next-extract-mark-ns-start projects
class ProjectListPage extends BasePage {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {t} = this.props;
    document.title = t("QbDVision Projects");

    this.state.accountSetup = false;

    this.setStateSafely({
      breadcrumb: {
        current: t("Projects"),
      },
    });

    super.componentDidMount();
  }

  static handleAdd() {
    this.context.navigate(UIUtils.getSecuredURL("/projects/viewEdit.html?operation=Add"));
  }

  renderPage() {
    const {t} = this.props;
    return (
      <div>
        <EditablesPageTitleBar />
        <div className="container-spacer" />
        <div className="container">
          <ErrorBar />
          <div className="row row-white shadow">
            <div className="col-sm-12">
              <div className="btn-group">
                <button className="btn btn-lg btn-primary" id="addButton"
                        style={{marginTop: "20px"}}
                        disabled={!can(CommonSecurity.Actions.ADD, CommonSecurity.Types.PROJECT)}
                        title={t(generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.PROJECT))}
                        onClick={ProjectListPage.handleAdd.bind(this)}
                >
                  {t("Add")}
                </button>
              </div>
            </div>
            <div className="col-sm-12">
              <ProjectListTable parent={this}
                                topLevel
                                allowRecordCopy
                                showAsOfDate
              />
            </div>
            {UIUtils.isExperimentEnabled(EXPERIMENTS.PartyMode) ? (
              <div>
                <img src="/images/party/partyCat.gif" />
              </div>
            ) : ""}
          </div>
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(ProjectListPage, ["projects", "base_page"]);
// i18next-extract-mark-ns-stop projects
