var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { validatePackage } from '@progress/kendo-licensing';
import { packageMetadata } from '../package-metadata';
import { classNames, guid } from '@progress/kendo-react-common';
/**
 * Represents the [KendoReact GridLayout component]({% slug overview_gridlayout %}).
 * Arranges the contents of the component in rows and columns in a grid structure.
 *
 * @example
 * ```jsx
 *
 * const App = () => {
 *   return (
 *     <GridLayout
 *       rows={[{height: 50}, {height: 50}, {height: 50}]}
 *       cols={[{width: 50}, {width: 50}, {width: 50}]}
 *       gap={{rows: 5, cols: 5}}
 *       align={{horizontal: 'stretch', vertical: 'stretch'}}
 *     >
 *       <GridLayoutItem row={1} col={1}>Box</GridLayoutItem>
 *       <GridLayoutItem row={1} col={2} colSpan={2}>Box</GridLayoutItem>
 *       <GridLayoutItem row={2} col={1} colSpan={2} rowSpan={2}>Box</GridLayoutItem>
 *       <GridLayoutItem row={2} col={3}>Box</GridLayoutItem>
 *       <GridLayoutItem row={3} col={3}>Box</GridLayoutItem>
 *     </GridLayout>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var GridLayout = React.forwardRef(function (props, ref) {
    validatePackage(packageMetadata);
    var elementRef = React.useRef(null);
    var getImperativeHandle = React.useCallback(function () { return ({
        element: elementRef.current
    }); }, []);
    React.useImperativeHandle(ref, getImperativeHandle);
    var className = props.className, style = props.style, id = props.id, children = props.children, gap = props.gap;
    var layoutId = React.useMemo(function () { return guid(); }, []);
    var hAlign = React.useMemo(function () { return props.align && props.align.horizontal ? props.align.horizontal : defaultProps.hAlign; }, [props.align]);
    var vAlign = React.useMemo(function () { return props.align && props.align.vertical ? props.align.vertical : defaultProps.vAlign; }, [props.align]);
    var gridLayoutClasses = React.useMemo(function () { return classNames('k-grid-layout', {
        'k-justify-items-start': hAlign === 'start',
        'k-justify-items-center': hAlign === 'center',
        'k-justify-items-end': hAlign === 'end',
        'k-justify-items-stretch': hAlign === 'stretch',
        'k-align-items-start': vAlign === 'top',
        'k-align-items-center': vAlign === 'middle',
        'k-align-items-end': vAlign === 'bottom',
        'k-align-items-stretch': vAlign === 'stretch'
    }, className); }, [hAlign, vAlign, className]);
    var gapStyle = gap
        ? "".concat(typeof gap.rows === 'number' ? gap.rows + 'px' : gap.rows) + ' ' + "".concat(typeof gap.cols === 'number' ? gap.cols + 'px' : gap.cols)
        : undefined;
    var gridTemplateRowsStyle = props.rows && props.rows.map(function (row) { return "".concat(typeof row.height === 'number' ? row.height + 'px' : row.height); }).join(' ');
    var gridTemplateColumnsStyle = props.cols && props.cols.map(function (col) { return "".concat(typeof col.width === 'number' ? col.width + 'px' : col.width); }).join(' ');
    var gridLayoutStyles = __assign({ gap: gapStyle, gridTemplateColumns: gridTemplateColumnsStyle, gridTemplateRows: gridTemplateRowsStyle }, style);
    return (React.createElement("div", { ref: elementRef, className: gridLayoutClasses, style: gridLayoutStyles, id: id || layoutId }, children));
});
var defaultProps = {
    hAlign: 'stretch',
    vAlign: 'stretch',
    gap: undefined
};
GridLayout.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any,
    id: PropTypes.string,
    gap: PropTypes.shape({
        rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        columns: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    align: PropTypes.shape({
        vertical: PropTypes.oneOf(['top', 'middle', 'bottom', 'stretch']),
        horizontal: PropTypes.oneOf(['start', 'center', 'end', 'stretch'])
    })
};
GridLayout.defaultProps = defaultProps;
GridLayout.displayName = 'KendoReactGridLayout';
