"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseAttribute from "./base_attribute";
import { createHTMLForWholeTextDiff } from "../../helpers/diff_helper";
import RiskUtils from "../../../server/common/misc/common_risk_utils";
import { getNormalizedRiskScore } from "../../helpers/risk_helper";
import { valueExists } from "../../../server/common/generic/common_utils";

export default class CalculatedRiskAttribute extends BaseAttribute {

  // noinspection JSMethodCanBeStatic
  getInitialValue() {
    return null;
  }

  shouldRenderSubscriptLabel() {
    return this.state.shouldRenderSubscriptLabel;
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.state.shouldRenderSubscriptLabel !== !!(this.getValue())) {
      this.setStateSafely({
        shouldRenderSubscriptLabel: !!(this.getValue()),
      });
    }
  }

  componentDidUpdate() {
    if (this.state.shouldRenderSubscriptLabel !== !!(this.getValue())) {
      this.setStateSafely({
        shouldRenderSubscriptLabel: !!(this.getValue()),
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      let value = this.getNextValue(nextProps);
      return (this.currentValue !== value) ||
        JSON.stringify(nextProps.olderVersion) !== JSON.stringify(this.props.olderVersion) ||
        (nextProps.valueType !== this.props.valueType) ||
        (nextProps.riskType !== this.props.riskType) ||
        (this.isRequired());
    }
    return true;
  }

  getNextValue(nextProps) {

    const {riskFactor1, riskFactor2, isTechTransfer, riskType, RMP, valueType, parent} = nextProps;
    const {state, isDetailedRiskLinks} = parent;

    let calculatedRiskFactor1 = typeof riskFactor1 === "function" ? riskFactor1(state) : UIUtils.parseInt(state[riskFactor1]);
    let calculatedRiskFactor2 = typeof riskFactor2 === "function" ?
      riskFactor2(state, isDetailedRiskLinks(), isTechTransfer, true) :
      UIUtils.parseInt(state[riskFactor2]);

    let nextValue = null;
    if (valueExists(calculatedRiskFactor1) && valueExists(calculatedRiskFactor2)) {
      let rawValue = calculatedRiskFactor1 * calculatedRiskFactor2;
      let normalizedValue = getNormalizedRiskScore(riskType, RMP, rawValue);

      switch (valueType) {
        case "raw":
          nextValue = rawValue;
          break;
        case "percentage":
          nextValue = valueExists(rawValue) ? `${normalizedValue}%` : null;
          break;
      }
    }

    if (RiskUtils.riskValueIsNotAssessed(nextValue, this.props.riskType, this.props.RMP)) {
      nextValue = RiskUtils.getRiskLabel(this.props.riskType, this.props.RMP, nextValue, parent.state, false, true, true, this.ignoreRiskInfo());
    }

    return valueExists(nextValue) ? nextValue : this.getInitialValue();
  }

  getValue() {

    const {riskFactor1, riskFactor2, isTechTransfer, riskType, RMP, valueType, parent} = this.props;
    const {state} = parent;
    let {currentValue} = this;

    const calculatedRiskFactor1 = typeof riskFactor1 === "function" ? riskFactor1(state) : UIUtils.parseInt(state[riskFactor1]);
    const calculatedRiskFactor2 = typeof riskFactor2 === "function" ?
      riskFactor2(state, parent.isDetailedRiskLinks(), isTechTransfer, true) :
      UIUtils.parseInt(state[riskFactor2]);

    currentValue = null;
    if (valueExists(calculatedRiskFactor1) && valueExists(calculatedRiskFactor2)) {
      let rawValue = calculatedRiskFactor1 * calculatedRiskFactor2;
      let normalizedValue = getNormalizedRiskScore(riskType, RMP, rawValue);

      switch (valueType) {
        case "raw":
          currentValue = rawValue;
          break;
        case "percentage":
          currentValue = valueExists(rawValue) ? `${normalizedValue}%` : null;
          break;
      }
    }

    if (RiskUtils.riskValueIsNotAssessed(currentValue, this.props.riskType, this.props.RMP)) {
      currentValue = RiskUtils.getRiskLabel(this.props.riskType, this.props.RMP, currentValue, parent.state, false, true, true, this.ignoreRiskInfo());
    }

    return valueExists(currentValue) ? currentValue : this.getInitialValue();
  }

  getOldValue() {

    const {riskFactor1, riskFactor2, isTechTransfer, riskType, valueType} = this.props;
    let olderVersion = this.props.olderVersion;
    const olderRMP = this.props.olderRMP ?? this.props.RMP;
    let oldValue = null;

    if (olderVersion) {
      let calculatedRiskFactor1 = typeof riskFactor1 === "function" ? riskFactor1(olderVersion) :
        UIUtils.parseInt(olderVersion[riskFactor1]);
      let calculatedRiskFactor2 = typeof riskFactor2 === "function" ?
        riskFactor2(olderVersion, olderVersion.detailedRiskLinks, isTechTransfer, true) :
        UIUtils.parseInt(olderVersion[riskFactor2]);

      if (valueExists(calculatedRiskFactor1) && valueExists(calculatedRiskFactor2)) {
        let oldRawValue = calculatedRiskFactor1 * calculatedRiskFactor2;
        let oldNormalizedValue = getNormalizedRiskScore(riskType, olderRMP, oldRawValue);

        if (RiskUtils.riskValueIsNotAssessed(oldNormalizedValue, this.props.riskType, olderRMP)) {
          return RiskUtils.getRiskLabel(this.props.riskType, olderRMP, oldNormalizedValue, olderVersion, false, true, true, this.ignoreRiskInfo());
        }

        switch (valueType) {
          case "raw":
            oldValue = oldRawValue;
            break;
          case "percentage":
            oldValue = valueExists(oldRawValue) ? `${oldNormalizedValue}%` : null;
            break;
        }
      }
    }

    return oldValue;
  }

  renderInput() {
    let input = "Input type not defined: " + this.props.type;
    let inputId = this.getInputId();
    this.currentValue = this.getValue() ? this.getValue() : "";
    // Uncomment for verbose logging
    // console.log("Render: Generating " + this.getInputId() + " with value: " + value);
    if (this.isDiffingVersions()) {
      this.oldValue = this.getOldValue();
      input = createHTMLForWholeTextDiff(this.oldValue, this.currentValue);
    } else {
      input = (<span id={inputId}>{this.currentValue}</span>);
    }

    return input;
  }

  /**
   * Ignore server calculated risk values
   * Use values coming from UI fields
   * @returns {boolean} true when we want to use risk values coming from UI. false when we want to use risk values computed on server side
   */
  ignoreRiskInfo() {
    return this.isEdit();
  }
}
