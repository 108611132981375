"use strict";

import {Tag} from "../../../common/models/editable_record";
import * as DirectScopeWidgetSetter from "../../../documentEditor/utils/setter/direct_scope_widget_setter";
import MemoryCache from "../../../utils/cache/memory_cache";
import {CALCULATED_RISK_ATTRIBUTES} from "../../../../server/common/generic/common_constants";

/**
 * If user change a field of a record and that is used by the tag, we will need to
 * make sure to update it in the tag as well.
 * @param record
 */
export function setLatestDataForTags(record: any) {
  if (!record || !record.tags || !record.RMP) {
    return null;
  }

  let hasChanged = false;
  const tags = JSON.parse(record.tags);
  for (const tag of tags) {
    if (!tag.appliesToHtmlContent) {
      continue;
    }

    const htmlContent = DirectScopeWidgetSetter.setLatestDataForAllElements(
      tag.appliesToHtmlContent,
      {[record.modelName]: [record]},
      record.RMP,
    );
    if (htmlContent !== tag.appliesToHtmlContent) {
      const node = new DOMParser().parseFromString(htmlContent, "text/html");
      tag.appliesToHtmlContent = htmlContent;
      tag.appliesTo = [node.documentElement.innerText];
      hasChanged = true;
    }
  }

  return hasChanged ? JSON.stringify(tags) : null;
}

export function getRecord(record: any, cache: MemoryCache, attributeName: string, attributeValue: string) {
  let updatedState = {};
  if (attributeName === "SupplierId") {
    const supplierTypeaheadOptions = cache.get(getInMemoryCacheKey("SUP")) || [];
    record.Supplier = supplierTypeaheadOptions.find(option => option.id === attributeValue);
    updatedState = {Supplier: record.Supplier};
  }
  return {record: {...record, [attributeName]: attributeValue}, updatedState};
}

export function hasRiskLinksOrCalculatedRiskAttributes(tagsText: string) {
  const tags: Array<Tag> = JSON.parse(tagsText);
  for (const tag of tags) {
    const node = new DOMParser().parseFromString(
      tag.appliesToHtmlContent,
      "text/html",
    );
    const directScopeWidgets = Array.from(
      node.querySelectorAll(".qbd-output-direct-scope-widget"),
    );
    for (const directScopeWidget of directScopeWidgets) {
      const subModelName = directScopeWidget.getAttribute(
        "data-record-sub-model-name",
      );
      if (subModelName === "Criticality") {
        return true;
      }
      const columnName = directScopeWidget.getAttribute(
        "data-record-column-name",
      );
      if (CALCULATED_RISK_ATTRIBUTES.includes(columnName)) {
        return true;
      }
    }
  }
}

function getInMemoryCacheKey(typeCode: string) {
  return `multipleTypeaheads_${typeCode}`;
}
