/**
 * Change approval information value
 */
export function handleApprovalChangeValue(attributeName, attributeValue, additionalState = {}) {
  let newState = {};
  let newApprovalState = Object.assign({}, this.state.approvalInfo);
  newApprovalState[attributeName] = attributeValue;
  newState.approvalInfo = newApprovalState;
  Object.assign(newState, additionalState);
  this.setStateSafely(newState);
}

/**
 * Change multiple values in approval information
 */
export function handleApprovalChangeValues(keyValuePair, callback, additionalState = {}) {
  let newState = {};
  let newApprovalState = Object.assign({}, this.state.approvalInfo);
  Object.assign(newApprovalState, keyValuePair);
  newState.approvalInfo = newApprovalState;
  Object.assign(newState, additionalState);
  this.setStateSafely(newState, callback);
}

export function getApprovalValue(attributeName) {
  if (this.state.approvalInfo) {
    return this.state.approvalInfo[attributeName];
  } else {
    return null;
  }
}

export function handleApprovalClearValue(callback) {
  this.setStateSafely({
    approvalInfo: null,
  }, callback);
}