"use strict";

import * as UIUtils from "../../ui_utils";
import MultipleTypeaheadObjectCache from "../../utils/cache/multiple_typeahead_object_cache";
import { DiagramResultsAdapter } from "../adapters/diagram_results_adapter";
import { PROCESS_BAR_PANELS } from "../process_explorer_constants";
import { EDITOR_OPERATIONS } from "../../editor/editor_constants";
import { addOrUpdateInResults } from "../adapters/process_explorer_results_adapter";
import CannotAdaptError from "../errors/cannot_adapt_error";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import RecordLoader from "../recordLoader/record_loader";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import BaseAutoBind from "../../base_auto_bind";
import { pushHistoryURLWithParameterChanges } from "../../configurableTables/tables/configurable_tables_helper";
import StaticPanelHelper from "../utils/static_panel_helper";
const Logger = Log.group(LOG_GROUP.ProcessExplorer, "ProcessExplorerPage");

export default class StaticPanelOperator extends BaseAutoBind {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.projectId = props.projectId;
  }

  handleCancelLoad(oldTypeCode, oldId) {
    this.parent.setStateSafely({
      staticPanelInfo: {typeCode: oldTypeCode, id: oldId},
      shouldShowRightPanel: true,
    });
  }

  handleChangePanel(selectedPanel) {
    const {parent} = this;
    if (selectedPanel === PROCESS_BAR_PANELS.ABOUT) {
      const typeCode = "PR";
      const id = parent.state.processId;
      const aboutPanelRecord = parent.processExplorerHelper.findStaticPanelRecord(typeCode, id);
      parent.setStateSafely({
        aboutPanelRecord,
        aboutPanelInfo: {typeCode, id, operation: EDITOR_OPERATIONS.VIEW},
        selectedPanel,
      });
    } else if (selectedPanel === PROCESS_BAR_PANELS.TABLE) {
      parent.setStateSafely({
        shouldShowRightPanel: false
      }, () => {
        parent.setStateSafely({selectedPanel});
      });
    } else {
      parent.setStateSafely({selectedPanel});
    }
    pushHistoryURLWithParameterChanges({selectedPanel});
  }

  handleChangeEditorType(newEditorOperation, data, callback) {
    const {parent} = this;
    let isAboutPanel = parent.state.selectedPanel === PROCESS_BAR_PANELS.ABOUT;
    const panelInfo = UIUtils.deepClone(isAboutPanel ? parent.state.aboutPanelInfo : parent.state.staticPanelInfo);
    panelInfo.operation = newEditorOperation;
    parent.setStateSafely({
      [isAboutPanel ? "aboutPanelInfo" : "staticPanelInfo"]: panelInfo,
      shouldShowRightPanel: true,
      /**
       * Sets the selected record with the latest draft data for approved records.
       * This is used to ensure the revision data is reloaded properly for approved records.
       * @see the comment below (in the callback) for more information.
       */
      selectedRecord: data ?? parent.state.selectedRecord,
    }, () => {
      /**
       * This callback is used to ensure the revision data is reloaded properly for approved records.
       * @see the insanely huge comment in {@link StaticApprovalContainer.openPage} for more information.
       */
      UIUtils.invokeCallback(callback, data);
    });
  }

  handleCloseStaticPanel() {
    const {parent} = this;
    parent.setStateSafely({shouldShowRightPanel: false}, () => {
      const endTime = Date.now() + 1000;
      // Update the PE diagram as it disappears
      let reLayoutDiagramFunc = () => {
        parent.processExplorerHelper.reLayoutDiagram();
        if (Date.now() < endTime) {
          setTimeout(reLayoutDiagramFunc, 20);
        }
      };
      reLayoutDiagramFunc();
    });
  }

  async loadStaticPanelData(useWriterDB, results, loadTypeaheadPromise) {
    const {parent} = this;
    const process = results.processes[0];
    let allRecords = StaticPanelHelper.getRecordsFromProcessExplorerResult(results, process.id);
    parent.recordLoader = new RecordLoader(allRecords, this.projectId, useWriterDB, process.SendingId);
    let instances = await parent.recordLoader.startLoading();
    parent.dataReceiver.handleReceiveStaticPanelDataFromServer(instances, loadTypeaheadPromise);
  }

  async handleSaveInStaticPanel(requirement, isProposeForArchiveOrRestore, modelName) {
    const {parent} = this;
    const {
      selectedPanel,
      aboutPanelInfo,
      staticPanelInfo,
      showArchived,
      processId,
    } = parent.state;

    Logger.info(() => "Waiting to save " + requirement.name + "...");

    // Don't let this happen until the records are loaded or else you'll deep clone an empty keyToFullRecordMap.
    UIUtils.setLoadingDisabled(false);

    await parent.processExplorerHelper.waitForRecordLoading("Save completed. Waiting for Process Explorer to finish loading...");
    Logger.info(() => "Updating " + requirement.name + " after save...");
    let isAboutPanel = selectedPanel === PROCESS_BAR_PANELS.ABOUT;
    const panelInfo = UIUtils.deepClone(isAboutPanel ? aboutPanelInfo : staticPanelInfo) || {};
    if (!modelName) {
      modelName = requirement.modelName;

      if (!modelName) {
        modelName = UIUtils.getModelNameForTypeCode(panelInfo.typeCode);
      }
    }
    modelName = UIUtils.stripAllWhitespaces(modelName);
    requirement.modelName = modelName;
    requirement.isHistoryPartial = true;
    delete requirement.editorOperation;
    const typeCode = UIUtils.getTypeCodeForModelName(modelName);
    let modifiedRequirementKey = typeCode + "-" + requirement.id;
    const keyToFullRecordMap = UIUtils.deepClone(parent.state.keyToFullRecordMap);
    if (keyToFullRecordMap.has(modifiedRequirementKey)) {
      if (isProposeForArchiveOrRestore) {
        // When proposing for Archive or Restore, the full data isn't returned.
        const recordFromMap = keyToFullRecordMap.get(modifiedRequirementKey);
        recordFromMap.currentState = requirement.currentState;
        recordFromMap[modelName + "Versions"] = requirement[modelName + "Versions"];
        requirement = keyToFullRecordMap.get(modifiedRequirementKey);
      } else {
        keyToFullRecordMap.set(modifiedRequirementKey, requirement);
      }
    } else {
      keyToFullRecordMap.set(modifiedRequirementKey, requirement);
    }

    panelInfo.id = requirement.id;
    panelInfo.typeCode = typeCode;
    panelInfo.operation = EDITOR_OPERATIONS.VIEW;
    let setNewStateFunction;
    try {
      const processExplorerResults = addOrUpdateInResults(UIUtils.deepClone(parent.state.processExplorerResults), requirement);

      const diagramResultsAdapter = new DiagramResultsAdapter(processExplorerResults,
        parent.isProjectArchived(), showArchived, parent.props.t);
      const {nodes, links} = diagramResultsAdapter.getNodesAndLinks();
      processExplorerResults.orderedUOList = diagramResultsAdapter.getOrderedUOList();

      setNewStateFunction = (results, typeCode) => {
        // Set the state
        parent.setStateSafely({
          keyToFullRecordMap,
          [isAboutPanel ? "aboutPanelInfo" : "staticPanelInfo"]: panelInfo,
          nodes,
          links,
          processExplorerResults,
        }, () => {
          // Force other components to update
          if (results) {
            // noinspection JSIgnoredPromiseFromCall
            parent.dataReceiver.handleReceivedTypeaheadResults(results, typeCode);
            parent.dataReceiver.handleReceiveStaticPanelDataFromServer(keyToFullRecordMap);
            // We cache the PE table view data after it has been adapted, reset the cached data for selected model if something has been changed.
            parent.clearPreviousAdapterData();
          }
          parent.staticPanel.current.forceResetToCachedData();
        });
      };

    } catch (err) {
      if (err instanceof CannotAdaptError) {
        Logger.info(() => "Loading the data again for the process explorer...");
        setNewStateFunction = () => {
          parent.setStateSafely({
            [isAboutPanel ? "aboutPanelInfo" : "staticPanelInfo"]: panelInfo,
          });
        };
        parent.dataLoader.loadProcessExplorerData(true, Promise.resolve(true));
      } else {
        // Not for us
        throw err;
      }
    }
    await parent.setStateSafelyAsync({selectedRecord: requirement, isProposeForArchiveOrRestore});
    if (["Process", "UnitOperation", "Step", "IQA", "IPA", "MaterialAttribute", "ProcessParameter"].includes(modelName)) {
      const cache = new TypeaheadObjectCache(modelName, this.projectId, processId);
      await cache.invalidateCacheOptionsAsync();
      cache.loadOptions(setNewStateFunction, {useWriterDB: true,});
    } else if (["Material", "ProcessComponent"].includes(modelName)) {
      const cache = new MultipleTypeaheadObjectCache([modelName, "MaterialAttribute", "ProcessParameter"], this.projectId, processId);
      await cache.invalidateCacheOptionsAsync();
      cache.loadOptions(setNewStateFunction, {useWriterDB: true});
    } else {
      setNewStateFunction();
    }
  }
}
