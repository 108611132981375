"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import Typeahead from "../../widgets/typeahead";
import { getURLByTypeNameAndId } from "../../helpers/url_helper";
import { createHTMLForTypeaheadDiff } from "../../helpers/diff_helper";
import BaseCustomErrorAttribute from "./base_custom_error_attribute";
import TypeaheadObjectCacheFactory from "../../utils/cache/typeahead_object_cache_factory";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import MemoryCache from "../../utils/cache/memory_cache";
import { measurePerformanceEnd, measurePerformanceStart } from "../../ui_utils";
import BaseObjectCache from "../../utils/cache/base_object_cache";

const Logger = Log.group(LOG_GROUP.Editables, "TypeaheadAttribute");

const EMPTY_ID = "null";

/**
 * Use this to set a value in an editable with a Typeahead control. See LinksAttribute if you need a multi-select
 * typeahead with a join table.
 */
export default class TypeaheadAttribute extends BaseCustomErrorAttribute {

  undefinedValue = "UNDEFINED";

  constructor(props) {
    super(props);

    this.currentSelectedOptions = []; // This isn't part of the state because BaseEditor doesn't properly propagate state changes when the server responds.

    // this is needed when taking the current value from parent
    // the parent loads slower than the typeahead
    if (this.props.parent && Object.prototype.hasOwnProperty.call(this.props.parent, "addOnDataReceivedListener")) {
      this.props.parent.addOnDataReceivedListener(() => {
        this.loadTypeaheadOptions();
      });
    }
  }

  componentDidMount() {
    super.componentDidMount();

    if (!this.state.isTypeaheadLoaded) {
      this.loadTypeaheadOptions();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
    if (!shouldUpdate) {
      let value = this.getValue(true);


      let typeaheadOptions = this.state.typeaheadOptions;
      // when we archive an element we loose the typeaheadOptions
      const parentAttributeName = this.getParentAttributeName();
      let selectedOptions = this.getSelectedOptionsForValue(this.getParentAttributeName(), value, typeaheadOptions);

      let hasTheSameValues = JSON.stringify(this.currentSelectedOptions) === JSON.stringify(selectedOptions ?? []);
      shouldUpdate = (
        !hasTheSameValues
        || nextState.isTypeaheadLoaded !== this.state.isTypeaheadLoaded
        || JSON.stringify(this.state.typeaheadOptions) !== JSON.stringify(nextState.typeaheadOptions)
        || this.props.parent.state[parentAttributeName] !== nextProps.parent.state[parentAttributeName]
        || nextProps.multiple !== this.props.multiple
        || nextProps.typeaheadType !== this.props.typeaheadType
        || nextProps.projectId !== this.props.projectId
        || nextProps.processId !== this.props.processId
        || nextProps.relatedRecordId !== this.props.relatedRecordId ||
        JSON.stringify(nextProps.options) !== JSON.stringify(this.props.options)
      ) && !this.isPopupOpened();
    }

    return shouldUpdate;
  }

  componentDidUpdate(prevProps) {
    const optionsAreDifferent = JSON.stringify(this.props.options) !== JSON.stringify(prevProps.options);
    if (optionsAreDifferent ||
      prevProps.projectId !== this.props.projectId ||
      prevProps.processId !== this.props.processId ||
      JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options)
    ) {
      this.setStateSafely({
        isTypeaheadLoaded: !this.usesTypeaheadCache(),
      }, () => this.loadTypeaheadOptionsInner(this.props, optionsAreDifferent));
    }

    super.componentDidUpdate(prevProps);
  }

  loadTypeaheadOptions() {
    this.setStateSafely({
      isTypeaheadLoaded: !this.usesTypeaheadCache(),
      typeaheadOptions: !this.usesTypeaheadCache() ? this.getStaticOptions() : []
    }, () => {
      if (!this.state.isTypeaheadLoaded) {
        this.loadTypeaheadOptionsInner(this.props);
      }
    });
  }

  getAttributeName() {
    return this.getParentAttributeName();
  }

  getParentAttributeName() {
    const {name, typeaheadType, capitalizeAttributeName} = this.props;

    if (!typeaheadType) {
      return UIUtils.uncapitalize(name);
    } else if (typeaheadType === "User" && !capitalizeAttributeName) {
      return UIUtils.uncapitalize(name) + "Id";
    } else {
      return UIUtils.capitalize(name) + "Id";
    }
  }

  getCapitalizedName() {
    if (this.props.displayName) {
      return this.props.displayName;
    } else {
      let displayName = this.props.name.replace(/([A-Z])/g, " $1");
      displayName = UIUtils.capitalize(displayName);
      return displayName;
    }
  }

  usesTypeaheadCache() {
    return this.props.typeaheadType;
  }

  // noinspection JSMethodCanBeStatic
  getInitialValue() {
    if (!this.state.typeaheadOptions) {
      return;
    }

    if (!this.isParentInitializedWithDefault && this.props && (this.props.default || this.props.useFirstValueAsDefault)) {
      let typeaheadOptions = this.state.typeaheadOptions;
      let initialValue;

      if (this.props.default) {
        if (this.props.typeaheadType) {
          initialValue = typeaheadOptions.find(option => {
            return option.id ? option.id === this.props.default : false;
          });
        } else {
          initialValue = typeaheadOptions.find(option => {
            return option.label === this.props.default;
          });
        }
      }

      if (this.props.useFirstValueAsDefault) {
        initialValue = typeaheadOptions[0];
        if (initialValue) {
          if (this.props.typeaheadType) {
            this.props.parent.handleChangeValue(this.getParentAttributeName(), initialValue.id);
          } else {
            this.props.parent.handleChangeValue(this.getParentAttributeName(), initialValue.label);
          }
        }
      }

      return initialValue ? initialValue.id : null;
    }

    return null;
  }

  getValue(ignoreInitialValue = false) {
    const value = this.props.parent.state[this.getParentAttributeName()];
    return value && value !== -1 ? value : (!ignoreInitialValue ? this.getInitialValue() : this.undefinedValue);
  }

  handleChange(options) {
    let value;
    if (this.props.multiple) {
      value = JSON.stringify(options.map(option => option.id));
    } else {
      value = options.length === 0 ? null : options[0].id;
    }
    this.props.parent.handleChangeValue(this.getParentAttributeName(), value);
    if (this.props.onChangeValue) {
      this.props.onChangeValue(value);
    }
    this.currentSelectedOptions = this.getSelectedOptionsForValue(this.getParentAttributeName(), value, this.state.typeaheadOptions);
    this.clearValidationErrors();
  }

  handleInputChange(textTypedIn) {
    this.setStateSafely({textTypedIn});
  }

  handleTypeaheadResultsFromServer() {
    this.forceUpdateSafely();
  }

  getInputId() {
    return this.props.name + "Typeahead";
  }

  /**
   * Get the typeahead options that should be presented to the user.
   *
   * @return {[{id: string, label: string}]} An array of options.
   */
  async loadTypeaheadOptionsInner(props = this.props, forceRetrieve) {
    const {typeaheadType} = props;
    let typeaheadOptions = this.getStaticOptions();

    if (typeaheadType) {
      await this.loadTypeaheadOptionsFromServer(typeaheadOptions, forceRetrieve);
    } else {
      this.setStateSafely({
        isTypeaheadLoaded: true,
        typeaheadOptions: typeaheadOptions
      });
    }
  }

  getStaticOptions() {
    if (!this.props.options || !Array.isArray(this.props.options)) {
      return [];
    }

    return this.props.options.map(entry => {
      return typeof entry === "string"
        ? ({id: entry, label: entry})
        : entry;
    });
  }

  async loadTypeaheadOptionsFromServer(typeaheadOptions, forceRetrieve) {
    const {typeaheadType} = this.props;
    const memoryCache = this.getInMemoryCache();
    const cacheKey = this.getInMemoryCacheKey();

    if (!memoryCache.get(cacheKey) || forceRetrieve) {
      const interactionName = `TypeaheadAttribute :: ${this.getInMemoryCacheKey()} :: Load options from cache/sessionStorage for Model: ${typeaheadType}. ProjectId: ${this.getProjectId()}. ProcessId: ${this.getProcessId()}`;
      measurePerformanceStart(interactionName);

      const typeaheadObjectCache = TypeaheadObjectCacheFactory.createTypeaheadObjectCacheIfPossible(
        typeaheadType, this.getProjectId, this.getProcessId);
      if (typeaheadObjectCache) {
        Logger.debug(interactionName + " :: loading using typeaheadObjectCache");
        let loadedResult = await typeaheadObjectCache.loadOptions().promise();
        Logger.debug(interactionName + " :: loaded using typeaheadObjectCache");
        if (loadedResult) {
          typeaheadOptions = typeaheadOptions.concat(typeaheadObjectCache.getOptionsFromCacheIncludingArchived());
          this.setStateSafely({
            isTypeaheadLoaded: true,
            typeaheadOptions: typeaheadOptions
          });
        }

        this.displayDebugInformation(interactionName);
      } else {
        this.setStateSafely({
          isTypeaheadLoaded: true,
          typeaheadOptions: typeaheadOptions
        });

        this.displayDebugInformation(interactionName);
      }
    } else {
      typeaheadOptions = memoryCache.get(cacheKey);

      this.setStateSafely({
        isTypeaheadLoaded: true,
        typeaheadOptions: typeaheadOptions
      });
    }
  }

  getFilteredTypeaheadOptions(typeaheadOptions) {
    if (!this.isView() && this.props.filter && typeaheadOptions) { // Don't filter when showing in View mode because the filter might have been different when the data was set.
      typeaheadOptions = typeaheadOptions.filter(this.props.filter);
    }

    return typeaheadOptions;
  }

  displayDebugInformation(interactionName) {
    measurePerformanceEnd(interactionName);
  }

  isLoading() {
    // edge case when archiving records
    if (this.props.parent.state.modelName === this.props.typeaheadType &&
      this.props.parent.state.currentState === "Proposed for Archive" || this.props.parent.state.currentState === "Archived") {
      return false;
    }

    const isLoading = !this.state.isTypeaheadLoaded || this.props.isLoading;
    if (this.props.name === "Material / Process Component" && isLoading) {
      Logger.debug(() => `MultipleTypeaheadAttribute - isloading - isTypeaheadLoaded: ${this.state.isTypeaheadLoaded}, isLoading: ${this.props.isLoading}`);
    }

    return isLoading;
  }

  getSelectedOptionsForValue(parentAttributeName, value, typeaheadOptions) {
    if (!typeaheadOptions) {
      return;
    }

    let selectedOptions = [];
    if (this.props.multiple) {
      let values;
      if (!value) {
        values = [];
      } else if (typeof value === "string" && value.startsWith("[")) {
        values = JSON.parse(value);
      } else {
        // This can happen when a non-multiple field is converted to a multiple one.
        values = [value];
      }
      let idSet = new Set(values);
      selectedOptions = typeaheadOptions.filter(option => {
        return idSet.has(option.id);
      }) || [];
    } else if (!BaseObjectCache.isObjectCacheStillLoading(typeaheadOptions)) {
      const option = typeaheadOptions.find(option => {
        return option.id === value;
      });
      if (option) {
        selectedOptions.push(option);
      } else if (value && typeaheadOptions && typeaheadOptions.length > 0 && value !== this.undefinedValue) {
        Logger.warn(() => `WARNING: Could not find an option for ${value} in typeahead ${this.getInputId()} with values: ${Log.json(typeaheadOptions)}`);
      }
    }

    if (this.isAdd()) {
      selectedOptions = selectedOptions.filter(option => !option.deletedAt);
    }

    return selectedOptions;
  }

  isValueSet() {
    let value = this.getValue();
    let typeaheadOptions = this.state.typeaheadOptions;
    let selectedOptions = this.getSelectedOptionsForValue(this.props, value, typeaheadOptions);
    return selectedOptions && selectedOptions.length > 0;
  }

  getProcessId() {
    if (this.props.processId) {
      return this.props.processId;
    }

    // TO DO: getting processId from parent is buggy. We need to remove it and pass projectId as a parameter
    const getProcessIdFunc = this.props.getProcessId || this.props.parent.getProcessId;
    return typeof getProcessIdFunc === "function" ? getProcessIdFunc() : null;
  }

  getProjectId() {
    if (this.props.projectId) {
      return this.props.projectId;
    }

    // TO DO: getting projectid from parent is buggy. We need to remove it and pass projectId as a parameter
    const getProjectIdFunc = this.props.getProjectId || this.props.parent.getProjectId;
    return typeof getProjectIdFunc === "function" ? getProjectIdFunc() : null;
  }

  validate() {
    if (!this.state.isTypeaheadLoaded) {
      return true; // Don't validate if the options are still coming in.
    }

    if (!super.validate()) {
      return false;
    }

    if (!this.getValue() && this.state.textTypedIn) {
      // Look to see if they've typed in the exact text of one of the options
      let {textTypedIn} = this.state;
      let {emptyOption} = this.props;

      const matchingOption = this.state.typeaheadOptions.find(option => (this.parseIInputValue(option.name) === this.parseIInputValue(textTypedIn) ||
        this.parseIInputValue(option.label).endsWith(this.parseIInputValue(textTypedIn))));

      if (matchingOption) {
        if (emptyOption !== textTypedIn) {
          this.handleChange([matchingOption]);
        } else {
          return true;
        }
      } else {
        Logger.debug("This is not a valid option for " + textTypedIn + ". Available options:", this.state.typeaheadOptions);
        this.setErrorText("This is not a valid option: " + textTypedIn);
        return false;
      }
    }

    return true;
  }

  parseIInputValue(input) {
    if (!input) {
      return "";
    }

    return input.toLowerCase().trim();
  }

  getOldSelectedOptions(olderVersion, typeaheadOptions) {
    let oldValue = this.getOldValue(olderVersion);
    return this.getSelectedOptionsForValue(this.getParentAttributeName(), oldValue, typeaheadOptions);
  }

  getOldValue(olderVersion) {
    return olderVersion[this.getParentAttributeName()];
  }

  renderMenuItemChildren(option) {
    const isEmptyKey = option.id === EMPTY_ID;
    return <div key={option.id} className={isEmptyKey ? "typeahead-empty-option" : null}>
      {option.label}
    </div>;
  }

  renderViewTypeaheadSelection(inputId, selectedOptions) {
    return <span id={inputId}>
              {selectedOptions && selectedOptions.length > 0 ?
                selectedOptions.map(selectedOption =>
                  UIUtils.parseInt(selectedOption.id) ? (
                    <a href={getURLByTypeNameAndId(this.props.typeaheadType, "View", selectedOption.id)}
                       key={selectedOption.id}
                    >
                      {selectedOption.label}
                    </a>
                  ) : selectedOption.label
                ) : ""}
            </span>;
  }

  renderInput() {
    let input;
    let inputId = this.getInputId();

    // Find the options and the selected one.
    let typeaheadOptions = this.state.typeaheadOptions;
    if (!this.state.isTypeaheadLoaded) {
      return <Fragment />;
    }

    let value = this.getValue();
    let selectedOptions = this.getSelectedOptionsForValue(this.getParentAttributeName(), value, typeaheadOptions);
    this.currentSelectedOptions = selectedOptions;

    /* Archived records should show up on typeaheads only when the user is viewing a record and in
       special cases when the user is editing a record. When the user is editing a record an archived
       option should show up only when the record attribute value displayed in the typeahead is pointing to
       an archived record. This covers a special case for the users which are treated as archived when
       disabled, but still need to show up in the projects page, when the user is editing a project.
     */

    typeaheadOptions = this.getFilteredTypeaheadOptions(typeaheadOptions);
    typeaheadOptions = typeaheadOptions.filter(option => {
      return !option.deletedAt
        || (this.isView() && selectedOptions.find(selectedOption => selectedOption.id === option.id));
    });

    // Figure out what to render
    if (this.isView()) {
      if (this.isDiffingVersions()) {
        let olderVersion = this.props.parent.getOlderVersion();
        let oldSelectedOptions = null;
        if (olderVersion) {
          oldSelectedOptions = this.getOldSelectedOptions(olderVersion, typeaheadOptions);
        }
        input = createHTMLForTypeaheadDiff(oldSelectedOptions, selectedOptions);
      } else {
        if (this.props.emptyOption && (!selectedOptions || selectedOptions.length === 0)) {
          selectedOptions = typeaheadOptions.filter(option => option.id === this.props.emptyOption);
        }

        if (this.usesTypeaheadCache()) {
          input = this.renderViewTypeaheadSelection(inputId, selectedOptions);
        } else {
          input = (
            <span id={inputId}>
              {selectedOptions ? selectedOptions.map(selectedOption => selectedOption.label).join(", ") : ""}
            </span>
          );
        }
      }
    } else {
      input = (<Typeahead options={typeaheadOptions ? typeaheadOptions : []}
                          renderMenuItemChildren={this.renderMenuItemChildren}
                          id={inputId}
                          inputProps={{id: inputId + "Input"}}
                          multiple={this.props.multiple}
                          selected={selectedOptions}
                          disabled={this.isDisabled()}
                          onChange={this.handleChange}
                          onInputChange={this.handleInputChange}
      />);
    }
    return input;
  }

  getInMemoryCache() {
    return MemoryCache.getNamedInstance("typeahead_attribute");
  }

  getInMemoryCacheKey() {
    return this.getMemoryCacheKeyForTypeahead(this.props.typeaheadType);
  }

  getMemoryCacheKeyForTypeahead(typeCode) {
    return `typeahead_${typeCode}_${this.getInputId()}_${this.getProjectId()}_${this.getProcessId()}`;
  }
}
