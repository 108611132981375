"use strict";

import { generateDocumentsValue, generateVersionValue } from "../../export/configurable_tables_export_value_generator";
import {
  COMMON_VARIABLE_PARENT_COLUMNS, RECEIVING_SITE_COMMON_COLUMNS_PROPS,
  SENDING_SITE_COMMON_COLUMNS_PROPS, TECH_TRANSFER_ASSESSMENT_COLUMNS
} from "../constants/configurable_tables_columns_config";
import { FIELD_PROPS } from "../constants/configurable_tables_field_props_config";
import {
  ABOUT_COL_SPAN,
  COMPONENT_QUALIFICATION_COL_SPAN, COMPONENT_RISK_COL_SPAN, REFERENCE_AND_STANDARD_COL_SPAN,
  UNIT_QUALIFICATION_COL_SPAN
} from "../constants/configurable_tables_col_spans_config";
import {
  ABOUT_SECTION,
  COMPONENT_QUALIFICATION_SECTION, COMPONENT_RISK_SECTION, REFERENCES_SECTION,
  UNIT_QUALIFICATION_SECTION
} from "../constants/configurable_tables_sections_config";
import { FILTER_TYPES } from "../../columnOperations/configurable_tables_column_operations_constants";
import RecordFields from "../record_fields";
import CommonEditables from "../../../../server/common/editables/common_editables";

/**
 * This class lists all Process Component fields to be shown in export, column selection and tables.
 */
export default class ProcessComponentFields extends RecordFields {

  get title() {
    return "Process Components";
  }

  get fields() {
    return super.filterTechTransferFields([
      ...COMMON_VARIABLE_PARENT_COLUMNS,
      // About
      {
        title: "Name",
        prop: FIELD_PROPS.NAME,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) => columnGenerator.generateNameColumn("Name", FIELD_PROPS.NAME, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Name",
        prop: FIELD_PROPS.NAME,
        colspan: ABOUT_COL_SPAN,
        tableValue: (columnGenerator, process) => columnGenerator.generateNameColumn("Name", FIELD_PROPS.NAME, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Version",
        prop: FIELD_PROPS.VERSION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateVersionValue,
        tableValue: (columnGenerator) => columnGenerator.generateVersionColumn("Version"),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Version",
        prop: FIELD_PROPS.VERSION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        value: generateVersionValue,
        tableValue: (columnGenerator) => columnGenerator.generateVersionColumn("Version", null, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Unit Operations",
        prop: FIELD_PROPS.UNIT_OPERATIONS,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        sortByOrder: true,
        filterType: FILTER_TYPES.LOOKUP,
        value: (record) => record[FIELD_PROPS.UNIT_OPERATION_NAME],
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateParentColumn("Unit Operations", FIELD_PROPS.UNIT_OPERATIONS, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Unit Operations",
        prop: FIELD_PROPS.UNIT_OPERATIONS,
        colspan: ABOUT_COL_SPAN,
        sortByOrder: true,
        filterType: FILTER_TYPES.LOOKUP,
        value: (record) => record[FIELD_PROPS.UNIT_OPERATION_NAME],
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateParentColumn("Unit Operations", FIELD_PROPS.UNIT_OPERATIONS, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Steps",
        prop: FIELD_PROPS.STEPS,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        sortByOrder: true,
        filterType: FILTER_TYPES.LOOKUP,
        value: (record) => record[FIELD_PROPS.STEP_NAME],
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateParentColumn("Steps", FIELD_PROPS.STEPS, process),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Steps",
        prop: FIELD_PROPS.STEPS,
        colspan: ABOUT_COL_SPAN,
        sortByOrder: true,
        filterType: FILTER_TYPES.LOOKUP,
        value: (record) => record[FIELD_PROPS.STEP_NAME],
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateParentColumn("Steps", FIELD_PROPS.STEPS, process, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Type",
        prop: FIELD_PROPS.TYPE,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        defaultTechTransferValue: true,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Type",
        prop: FIELD_PROPS.TYPE,
        colspan: ABOUT_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Function",
        prop: FIELD_PROPS.FUNCTION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        defaultTechTransferValue: true,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextColumn(
          "Function", FIELD_PROPS.FUNCTION, process
        ),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Function",
        prop: FIELD_PROPS.FUNCTION,
        colspan: ABOUT_COL_SPAN,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextColumn(
          "Function", FIELD_PROPS.FUNCTION, process, true
        ),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "COA/COC",
        prop: FIELD_PROPS.CERTIFICATE_OF_ANALYSIS,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
        filterOptions: () => CommonEditables.BOOLEAN_VALUES,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("COA/COC", FIELD_PROPS.CERTIFICATE_OF_ANALYSIS),
      },
      {
        title: "COA/COC",
        prop: FIELD_PROPS.CERTIFICATE_OF_ANALYSIS,
        colspan: ABOUT_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
        filterOptions: () => CommonEditables.BOOLEAN_VALUES,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("COA/COC", FIELD_PROPS.CERTIFICATE_OF_ANALYSIS),
      },
      {
        title: "Description",
        prop: FIELD_PROPS.DESCRIPTION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextAreaColumn(
          "Description", FIELD_PROPS.DESCRIPTION, process
        ),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Description",
        prop: FIELD_PROPS.DESCRIPTION,
        colspan: ABOUT_COL_SPAN,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextAreaColumn(
          "Description", FIELD_PROPS.DESCRIPTION, process, true
        ),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Source Documents",
        prop: FIELD_PROPS.LINKS,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.LINKS),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Source Documents",
        prop: FIELD_PROPS.LINKS,
        colspan: ABOUT_COL_SPAN,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.LINKS, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      // Component Qualification
      {
        title: "Supplier",
        prop: FIELD_PROPS.SUPPLIER,
        colspan: COMPONENT_QUALIFICATION_COL_SPAN,
        section: COMPONENT_QUALIFICATION_SECTION,
        defaultValue: false,
        defaultChangeCriteriaValue: true,
        defaultTechTransferValue: false,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Supplier",
        prop: FIELD_PROPS.SUPPLIER,
        colspan: COMPONENT_QUALIFICATION_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Component Qualified",
        prop: FIELD_PROPS.COMPONENT_QUALIFIED,
        colspan: COMPONENT_QUALIFICATION_COL_SPAN,
        section: COMPONENT_QUALIFICATION_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Component Qualified",
        prop: FIELD_PROPS.COMPONENT_QUALIFIED,
        colspan: COMPONENT_QUALIFICATION_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Supplier Part Number",
        prop: FIELD_PROPS.PART_NUMBER,
        colspan: COMPONENT_QUALIFICATION_COL_SPAN,
        section: COMPONENT_QUALIFICATION_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Supplier Part Number",
        prop: FIELD_PROPS.PART_NUMBER,
        colspan: COMPONENT_QUALIFICATION_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Internal Part Number",
        prop: FIELD_PROPS.INTERNAL_PART_NUMBER,
        colspan: COMPONENT_QUALIFICATION_COL_SPAN,
        section: COMPONENT_QUALIFICATION_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Internal Part Number",
        prop: FIELD_PROPS.INTERNAL_PART_NUMBER,
        colspan: COMPONENT_QUALIFICATION_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Effective Date",
        prop: FIELD_PROPS.EFFECTIVE_DATE,
        colspan: COMPONENT_QUALIFICATION_COL_SPAN,
        section: COMPONENT_QUALIFICATION_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        filterType: FILTER_TYPES.DATE,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Effective Date",
        prop: FIELD_PROPS.EFFECTIVE_DATE,
        colspan: COMPONENT_QUALIFICATION_COL_SPAN,
        filterType: FILTER_TYPES.DATE,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Expiration Date",
        prop: FIELD_PROPS.EXPIRATION_DATE,
        colspan: COMPONENT_QUALIFICATION_COL_SPAN,
        section: COMPONENT_QUALIFICATION_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        filterType: FILTER_TYPES.DATE,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Expiration Date",
        prop: FIELD_PROPS.EXPIRATION_DATE,
        colspan: COMPONENT_QUALIFICATION_COL_SPAN,
        filterType: FILTER_TYPES.DATE,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Component Qualification Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.COMPONENT_QUALIFICATION_LINKS,
        colspan: COMPONENT_QUALIFICATION_COL_SPAN,
        section: COMPONENT_QUALIFICATION_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.COMPONENT_QUALIFICATION_LINKS),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Component Qualification Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.COMPONENT_QUALIFICATION_LINKS,
        colspan: COMPONENT_QUALIFICATION_COL_SPAN,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.COMPONENT_QUALIFICATION_LINKS, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      // Unit Qualification
      {
        title: "Acceptance Testing",
        prop: FIELD_PROPS.ACCEPTANCE_TESTING,
        colspan: UNIT_QUALIFICATION_COL_SPAN,
        section: UNIT_QUALIFICATION_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Acceptance Testing",
        prop: FIELD_PROPS.ACCEPTANCE_TESTING,
        colspan: UNIT_QUALIFICATION_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Qualification Status",
        prop: FIELD_PROPS.QUALIFICATION_STATUS,
        colspan: UNIT_QUALIFICATION_COL_SPAN,
        section: UNIT_QUALIFICATION_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Qualification Status",
        prop: FIELD_PROPS.QUALIFICATION_STATUS,
        colspan: UNIT_QUALIFICATION_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Unit ID",
        prop: FIELD_PROPS.UNIT_ID,
        colspan: UNIT_QUALIFICATION_COL_SPAN,
        section: UNIT_QUALIFICATION_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Unit ID",
        prop: FIELD_PROPS.UNIT_ID,
        colspan: UNIT_QUALIFICATION_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Unit Qualification Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.UNIT_QUALIFICATION_LINKS,
        colspan: UNIT_QUALIFICATION_COL_SPAN,
        section: UNIT_QUALIFICATION_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: false,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.UNIT_QUALIFICATION_LINKS),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Unit Qualification Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.UNIT_QUALIFICATION_LINKS,
        colspan: UNIT_QUALIFICATION_COL_SPAN,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.UNIT_QUALIFICATION_LINKS, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      // Component Risk
      {
        title: "Drug Product Contact",
        prop: FIELD_PROPS.DRUG_PRODUCT_CONTACT,
        colspan: COMPONENT_RISK_COL_SPAN,
        section: COMPONENT_RISK_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
        filterOptions: () => CommonEditables.BOOLEAN_VALUES,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("Drug Product Contact", FIELD_PROPS.DRUG_PRODUCT_CONTACT),
      },
      {
        title: "Drug Product Contact",
        prop: FIELD_PROPS.DRUG_PRODUCT_CONTACT,
        colspan: COMPONENT_RISK_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
        filterOptions: () => CommonEditables.BOOLEAN_VALUES,
        tableValue: (columnGenerator) => columnGenerator.generateBooleanColumn("Drug Product Contact", FIELD_PROPS.DRUG_PRODUCT_CONTACT, true),
      },
      {
        title: "Contact Risk",
        prop: FIELD_PROPS.CONTACT_RISK,
        colspan: COMPONENT_RISK_COL_SPAN,
        section: COMPONENT_RISK_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
        tableValue: (columnGenerator) => columnGenerator.generateMultiSelectionColumn("Contact Risk", FIELD_PROPS.CONTACT_RISK, false),
      },
      {
        title: "Contact Risk",
        prop: FIELD_PROPS.CONTACT_RISK,
        colspan: COMPONENT_RISK_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
        tableValue: (columnGenerator) => columnGenerator.generateMultiSelectionColumn("Contact Risk", FIELD_PROPS.CONTACT_RISK, false, true),
      },
      {
        title: "Contact Risk Justification",
        prop: FIELD_PROPS.CONTACT_RISK_JUSTIFICATION,
        colspan: COMPONENT_RISK_COL_SPAN,
        section: COMPONENT_RISK_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextColumn(
          "Contact Risk Justification", FIELD_PROPS.CONTACT_RISK_JUSTIFICATION, process
        ),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Contact Risk Justification",
        prop: FIELD_PROPS.CONTACT_RISK_JUSTIFICATION,
        colspan: COMPONENT_RISK_COL_SPAN,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
        tableValue: (columnGenerator, process) => columnGenerator.generateTextColumn(
          "Contact Risk Justification", FIELD_PROPS.CONTACT_RISK_JUSTIFICATION, process, true
        ),
      },
      {
        title: "Cleaning Validation",
        prop: FIELD_PROPS.CLEANING_VALIDATION,
        colspan: COMPONENT_RISK_COL_SPAN,
        section: COMPONENT_RISK_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Cleaning Validation",
        prop: FIELD_PROPS.CLEANING_VALIDATION,
        colspan: COMPONENT_RISK_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Sterilization Validation",
        prop: FIELD_PROPS.STERILIZATION_VALIDATION,
        colspan: COMPONENT_RISK_COL_SPAN,
        section: COMPONENT_RISK_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Sterilization Validation",
        prop: FIELD_PROPS.STERILIZATION_VALIDATION,
        colspan: COMPONENT_RISK_COL_SPAN,
        filterType: FILTER_TYPES.COMBO_BOX,
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Component Risk Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.COMPONENT_RISK_LINKS,
        colspan: COMPONENT_RISK_COL_SPAN,
        section: COMPONENT_RISK_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn( "Source Documents", process,
            FIELD_PROPS.COMPONENT_RISK_LINKS),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "Component Risk Source Documents",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.COMPONENT_RISK_LINKS,
        colspan: COMPONENT_RISK_COL_SPAN,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.COMPONENT_RISK_LINKS, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      // References & Standards
      {
        title: "References & Standards",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.REFERENCES,
        colspan: REFERENCE_AND_STANDARD_COL_SPAN,
        section: REFERENCES_SECTION,
        defaultValue: false,
        defaultTechTransferValue: false,
        defaultChangeCriteriaValue: true,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.REFERENCES),
        ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      },
      {
        title: "References & Standards",
        columnSelectionTitle: "Source Documents",
        prop: FIELD_PROPS.REFERENCES,
        colspan: REFERENCE_AND_STANDARD_COL_SPAN,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateDocumentsColumn("Source Documents", process,
            FIELD_PROPS.REFERENCES, true),
        ...RECEIVING_SITE_COMMON_COLUMNS_PROPS,
      },
      ...TECH_TRANSFER_ASSESSMENT_COLUMNS,
    ]);
  }
}
