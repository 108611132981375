"use strict";

import * as go from "gojs";
import { REPORT_OPTIONS_ENUM } from "../../constants/report_constants";

export const MIN_HEADER_HEIGHT = 100;  // This controls the minimum height of the swimlane headers
const MIN_SWIMLANE_HEIGHT = 50;  // This controls the minimum length of any swimlane
const MIN_SWIMLANE_WIDTH = 50;  // this controls the minimum width of any swimlane

/**
 * Custom layout inheriting from the grid layout for properly positioning the swimlanes.
 * The pool layout makes sure that all the swimlanes have the same height and that they are
 * broad enough to hold their sub-graphs.
 */
export class PoolLayout extends go.GridLayout {
  constructor() {
    super();

    this.computeDesiredLaneWidth = this.computeDesiredLaneWidth.bind(this);
    this.computeMinPoolHeight = this.computeMinPoolHeight.bind(this);

    this.cellSize = new go.Size(0, 0);
    this.wrappingColumn = Infinity;
    this.wrappingWidth = Infinity;
    this.isRealtime = false;  // don't continuously layout while dragging
    this.alignment = go.GridLayout.Position;
    this.isInitialLayout = true;
  }

  // Compute the minimum size of a Pool Group needed to hold all of the Lane Groups
  computeMinPoolHeight(pool) {
    let swimLaneHeight = MIN_SWIMLANE_HEIGHT;
    pool.memberParts.each((part) => {
      // pools ought to only contain lanes, not plain Nodes
      if (!(part instanceof go.Group)) {
        return;
      }

      let holder = part.placeholder;
      if (holder !== null) {
        let placeHolderSize = holder.actualBounds;
        swimLaneHeight = Math.max(swimLaneHeight, placeHolderSize.height);
      }
    });
    return swimLaneHeight;
  }

  // Compute the minimum width for a particular Lane Group
  // noinspection JSMethodCanBeStatic
  computeDesiredLaneWidth(lane) {
    let desiredLaneWidth = MIN_SWIMLANE_WIDTH;
    let holder = lane.placeholder;

    // The minimum breadth needs to be big enough to hold the lane placeholder
    if (holder !== null) {
      let placeholderSize = holder.actualBounds;
      desiredLaneWidth = Math.max(desiredLaneWidth, placeholderSize.width);
    }

    // The minimum breadth needs to be big enough to hold the header
    let fixedHeaderPanelCaptionPanel = this.diagram.findNodeForKey(lane.key + "Header").findObject("FixedHeaderCaptionPanel");
    if (fixedHeaderPanelCaptionPanel !== null) {
      desiredLaneWidth = Math.max(desiredLaneWidth, fixedHeaderPanelCaptionPanel.actualBounds.width);
    }

    return desiredLaneWidth;
  }

  /**
   * Assign the positions of the diagram parts, ignoring any links.
   * @param params A Diagram or a Group or a collection of GoJs Parts.
   */
  doLayout(params) {
    if (this.diagram === null) return;

    this.diagram.startTransaction("PoolLayout");
    let pool = this.group;

    if (pool !== null && pool.category === REPORT_OPTIONS_ENUM.ProcessFlowMapReport.PoolGroupCategory) {
      // Make sure all of the Group Shapes are big enough
      let minPoolHeight = this.computeMinPoolHeight(pool);
      let headerHeight = MIN_HEADER_HEIGHT;
      let headers = {};

      this.diagram.nodes.each((node) => {
        if (node.category === REPORT_OPTIONS_ENUM.ProcessFlowMapReport.SwimlaneHeaderCategory) {
          headers[node.key] = node;
        }
      });

      pool.memberParts.each((lane) => {
        if (lane.category === REPORT_OPTIONS_ENUM.ProcessFlowMapReport.SwimlanesGroupCategory) {
          let laneShape = lane.resizeObject;
          let laneHeaderPanel = lane.placeholder.panel.panel.findObject("HeaderPanel");
          let laneHeaderText = lane.placeholder.panel.panel.findObject("PanelTextBlock");
          let fixedLaneHeaderPanel = headers[lane.key + "Header"].findObject("FixedHeaderPanel");
          let fixedLaneHeaderText = fixedLaneHeaderPanel.findObject("PanelTextBlock");

          if (laneShape !== null) {  // Change the desiredSize for the swimlane to be big enough in both directions
            let laneDesiredWidth = this.computeDesiredLaneWidth(lane);

            /*
            The first time the report renders we want all lanes to be allocated the maximum of the width between the layout's configurable width or
            the lane's desired width.
             */
            if (this.isInitialLayout) {
              laneShape.width = Math.max(lane.layout.cellSize.width, laneDesiredWidth);
            } else {
              laneShape.width = !isNaN(laneShape.width) ? Math.max(laneShape.width, laneDesiredWidth) : laneDesiredWidth;
            }
            laneShape.height = isNaN(laneShape.height) ? minPoolHeight : Math.max(laneShape.height, minPoolHeight);

            //The header panel should have the same width as the lane
            laneHeaderPanel.width = laneShape.width;
            fixedLaneHeaderPanel.width = laneShape.width;
          }

          //The header text width needs to be calculated manually so that the header text can wrap if needed.
          if (laneHeaderText) {
            laneHeaderText.width = laneHeaderPanel.actualBounds.width - 80;
          }

          if (fixedLaneHeaderText) {
            fixedLaneHeaderText.width = fixedLaneHeaderPanel.actualBounds.width - 80;
          }

          //Set the height for all lane headers to be the same.
          if (laneHeaderPanel) {
            if (laneHeaderPanel.actualBounds.height !== headerHeight) {
              laneHeaderPanel.desiredSize = new go.Size(NaN, headerHeight);
            }
          }

          if (fixedLaneHeaderPanel) {
            if (fixedLaneHeaderPanel.actualBounds.height !== headerHeight) {
              fixedLaneHeaderPanel.desiredSize = new go.Size(NaN, headerHeight);
            }
          }
        }
      });

      // Invoke the base doLayout method and let the grid layout take care of positioning the lanes onto the canvas.
      super.doLayout.call(this, params);

      this.isInitialLayout = false;
    }
  }
}

