"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DATATABLES_DOM } from "../../../widgets/tables/base_table";
import BaseReactComponent from "../../../base_react_component";

export default class AdminListTableBase extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    // Prevent xss attacks
    for (const column of this.props.columns) {
      if (!column.containsHTML) {
        column.render = $.fn.dataTable.render.text();
      }
    }

    const table = $(this.tableRef).DataTable({
      dom: DATATABLES_DOM,
      data: this.props.data,
      columns: this.props.columns,
      columnDefs: this.props.columnDefs,
      scrollX: !!this.props.scrollX,
      stateSave: true,
      lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
    });

    // Retrieve search values from table state and set value for the custom filter columns
    const state = table.state.loaded();
    if (state) {
      table.columns().eq(0).each((colIdx) => {
        const colSearch = state.columns[colIdx].search;
        if (colSearch.search) {
          $("input", table.column(colIdx).footer()).val(colSearch.search);
        }
      });
      table.draw();
    }

    // Set value for the custom filter columns if there is default filtered value from a column
    table.columns().eq(0).each((colIdx) => {
      const column = this.props.columns[colIdx];
      if (column.defaultFilteredValue) {
        $("input", table.column(colIdx).footer()).val(column.defaultFilteredValue);
        table.columns(colIdx).search(column.defaultFilteredValue).draw();
      }
    });
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    $(this.tableRef)
      .DataTable()
      .destroy(true);
  }

  reloadDataTable() {
    if (this.tableRef) {
      const table = $(this.tableRef).DataTable();
      table.clear();
      table.rows.add(this.props.data);
      table.draw();
    }
  }

  updateTableValue(colIdx, value) {
    const table = $(this.tableRef).DataTable();
    table.columns(colIdx).search(value).draw();
    $("input", table.column(colIdx).footer()).val(value);
    if (!value && this.props.onClearColumnSearch) {
      this.props.onClearColumnSearch(this.props.columns[colIdx]);
    }
  }

  handleClearColumnSearch(colIdx) {
    this.updateTableValue(colIdx, "");
  }

  handleFilterChange(event, colIdx) {
    const value = event.target.value;
    this.updateTableValue(colIdx, value);
  }

  render() {
    return (
      <div>
        <table
          ref={ref => this.tableRef = ref}
          className="table table-bordered table-hover nowrap"
          id={this.props.id}
        >
          <tfoot>
          <tr>
            {this.props.columns.map((column, colIdx) => {
              return (
                <th key={column.title}>
                  {!column.hideSearch &&
                    <div className="input-with-button-container">
                      <input
                        onChange={(event) => this.handleFilterChange(event, colIdx)}
                        className={`form-control filter-${UIUtils.stripAllWhitespaces(column.title)}`}
                        type="text"
                        placeholder={`Search ${column.title}`}
                      />
                      <span onClick={() => this.handleClearColumnSearch(colIdx)} className="icon-wrapper">
                        <FontAwesomeIcon icon={faTimes} />
                      </span>
                    </div>}
                </th>
              );
            })}
          </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}

AdminListTableBase.defaultProps = {
  id: "resultsTable"
};
