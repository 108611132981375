"use strict";

import * as pillStyles from "../filter.module.scss";
import React from "react";

export const Clear = ({filters, onClick}) => {

  if (!filters.length) {
    return null;
  }

  return (
    <div className={pillStyles["clear"]}>
      <a id="configurableTablesClearAllFiltersButton"
         onClick={onClick}
      >
        Clear
      </a>
    </div>
  );
};