"use strict";



import ProcessSelectionStep from "../steps/process_selection_step";
import RecordMatcherStep from "../steps/record_matcher_step";

export const WIZARD_STEPS = {
  ProcessSelection: "Select a process to link",
  RecordMatcher: "Map Unit Operations and Steps",

};

export const WIZARD_STEPS_CONFIG = [{
  type: ProcessSelectionStep,
  navTabId: "processSelectionNavItem",
  step: WIZARD_STEPS.ProcessSelection,
  disabled: false,
  stepIndex: 1,
  visible: true,
}, {
  type: RecordMatcherStep,
  navTabId: "recordMatcherNavItem",
  step: WIZARD_STEPS.RecordMatcher,
  disabled: true,
  stepIndex: 2,
  visible: true,
}];