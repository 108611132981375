"use strict";

import React, {useState, useEffect} from "react";
import {RecordHeader} from "@qbdvision-inc/component-library";
import {getURLForSection} from "./utils";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import * as UIUtils from "../../ui_utils";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";

const LibraryMaterialTitleBar = ({currentState, instanceTypeCode, instance, sectionName, currentOperation}) => {
  const [primaryName, setPrimaryName] = useState(instance.name);

  const [secondaryInfo, setSecondaryInfo] = useState([]);

  const [isTitleGray, setIsTitleGray] = useState(false);

  useEffect(() => {
    getPrimaryName(instance.name);
  }, [instance.name]);

  useEffect(() => {
    getSecondaryInfo(instance.SupplierId, instance.internalPartNumber);
  }, [instance.SupplierId, instance.internalPartNumber]);

  const getPrimaryName = (name?: string) => {
    if (name) {
      setPrimaryName(name);
      setIsTitleGray(false);
    } else if (currentOperation === "Add") {
      setPrimaryName("New " + UIUtils.getModelNameForTypeCode(instanceTypeCode));
      setIsTitleGray(true);
    } else { //Edit Mode
      setPrimaryName(UIUtils.getModelNameForTypeCode(instanceTypeCode));
      setIsTitleGray(true);
    }
  };

  const getSecondaryInfo = (supplierId: string, internalPartNumber: string) => {
    let arr = [];
    let supplierURL;
    let supplierName;


    new TypeaheadObjectCache("Supplier", supplierId).loadOptions(data => {
      const supplierData = data.find(obj => obj.id === supplierId);

      if (supplierData?.name) {
        supplierName = supplierData.name;
        supplierURL = getURLByTypeCodeAndId(supplierData.typeCode, "View", supplierId);

        arr.push({label: supplierName, url: supplierURL});

        internalPartNumber && arr.push({label: `Part# ${internalPartNumber}`});
      }

      setSecondaryInfo(arr);
    });
  };

  const shouldShowLabel = instanceTypeCode && instance.id;
  return (
    <RecordHeader title={primaryName}
                  backButtonLink={getURLForSection(sectionName)}
                  currentState={currentState}
                  isTitleGray={isTitleGray}
                  {...secondaryInfo.length && {breadcrumb: secondaryInfo}}
                  {...shouldShowLabel && {label: `${instanceTypeCode}-${instance.id}`}}
    />
  );
};

export default LibraryMaterialTitleBar;
