"use strict";

import * as UIUtils from "../../ui_utils";
import TypeaheadObjectCache from "./typeahead_object_cache";
import Promisable from "./promisable";
import BaseObjectCache from "./base_object_cache";
import * as ProcessCache from "../../processExplorer/process/process_cache";
import MemoryCache from "./memory_cache";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Framework, "Cache");


/**
 * This class is just like the TypeaheadObjectCache except that multiple types can be handled at once, in bulk.
 */
export default class MultipleTypeaheadObjectCache extends TypeaheadObjectCache {
  /**
   * @param types {[string]} The types that will be loaded.
   * @param [projectId] {integer} Optionally the project ID that we want the typeaheads for.
   * @param [processId] {integer} Optionally the process ID that we want the typeaheads for. `projectId` must be provided
   * if this is provided.
   */
  constructor(types, projectId, processId, options) {
    // noinspection JSCheckFunctionSignatures
    super(null, projectId, processId, options);
    this.types = types;

    for (const type of this.types) {
      // Initialize the structures that organize saving functions to be called when data is loaded.
      if (!this.getIdToOnLoadedFunctions(type)) {
        this.clearIdToOnLoadedFunctions(type);
      }
    }
  }

  /**
   * Call this method when the page loads to preload multiple types' typeahead options to optimize a page.
   *
   * @param [onLoadedFunction] {function} The callback function to invoke when objects are loaded from the backend. It
   * will be called once per type loaded, with the results as the first argument and the type code as the second argument.
   * @param [requestData] {object} Extra QueryString parameters that will be passed in the Ajax call for loading the object cache options.
   * @return {Promisable} An object that can provide a promise that is complete once the data for all of the types has
   * been loaded. It will contain the results.
   */
  loadOptions(onLoadedFunction, requestData) {
    // Figure out which types still need to be loaded

    Logger.debug("MultipleTypeaheadObjectCache :: loadOptions for " + this.types.join(", ") + ". Project: " + this.projectId + ". Process: " + this.processId + " Cache is loaded " + BaseObjectCache.isStorageLoaded.toString());
    const returnPromise = this.waitForStorageToLoad("multiple").then(() => {

      const promises = [];
      for (const type of this.types) {
        const idForThisType = this.getIdForType(type);
        let options = this.getOptionsFromInMemory(idForThisType, this.getCacheName(), type);
        promises.push(Promise.resolve({
          type,
          options
        }));
      }

      return Promise.all(promises).then(results => {
        const typesNotLoadedYet = [];

        if (!Array.isArray(results)) {
          return;
        }

        for (const result of results) {
          const type = result.type;
          const options = result.options;
          if (type) {
            const idForThisType = this.getIdForType(type);
            let notAlreadyLoaded = !options || BaseObjectCache.isObjectCacheStillLoading(options);

            if (notAlreadyLoaded) {
              let idToOnLoadedFunctions = this.getIdToOnLoadedFunctions(type);
              if (!idToOnLoadedFunctions || !idToOnLoadedFunctions[idForThisType]) {
                typesNotLoadedYet.push(type);
              }
              this.addOnLoadedFunction(onLoadedFunction, this.getIdForType(type), type);
            } else if (onLoadedFunction) {
              // The options have already been loaded, possibly on a different page.
              const typeCode = UIUtils.getTypeCodeForModelName(type);
              onLoadedFunction(options, typeCode);
            }
          }
        }

        // Load the typeaheads remaining
        if (typesNotLoadedYet.length > 0) {
          let ajaxRequestData = {
            models: JSON.stringify(typesNotLoadedYet),
            approved: false,
            isCacheRequest: true,
            processId: this.processId ? this.processId : undefined,
            ...requestData,
          };

          if (typesNotLoadedYet.includes("RMP")) {
            ajaxRequestData.includeAllApprovedVersions = true;
          }

          if (typesNotLoadedYet.includes("ProcessComponent") ||
            typesNotLoadedYet.includes("Material") ||
            typesNotLoadedYet.includes("IQA") ||
            typesNotLoadedYet.includes("IPA") ||
            typesNotLoadedYet.includes("FQA") ||
            typesNotLoadedYet.includes("FPA") ||
            typesNotLoadedYet.includes("MaterialAttribute") ||
            typesNotLoadedYet.includes("ProcessParameter")
          ) {
            ajaxRequestData.includeLinks = true;
          }

          return new Promise(resolve => {
            UIUtils.secureAjaxGET(`editables/multiple/findForTypeahead/${this.projectId}`, ajaxRequestData,
              true, this.defaultFailFunction, false)
              .done(result => {
                this.handleTypeaheadResultsFromServer(result, typesNotLoadedYet);
                resolve(result);
              });
          });
        } else {
          return true;
        }
      });
    });

    return new Promisable(returnPromise);
  }

  /**
   * Get the Id for the given type. Since the process ID can change (it can appear with the results), optionally get a
   * different ID by passing in the processId.
   *
   * @param type {string} The type we need an ID for.
   * @param [processId] {number} The process Id, which might be different than `this.processId`.
   */
  getIdForType(type, processId) {
    let id = -1;
    processId = processId || this.processId;
    if (processId && !TypeaheadObjectCache.canTypeIgnoreProcessId(type)) {
      id = this.projectId + "--" + processId;
    } else if (!TypeaheadObjectCache.canTypeIgnoreProjectId(type)) {
      id = "" + this.projectId;
    }
    return id;
  }

  handleTypeaheadResultsFromServer(result, typesNotLoadedYet) {
    Logger.debug("MultipleTypeaheadObjectCache :: handleTypeaheadResultsFromServer", result);

    let processId = this.processId;
    if (!processId && result.process && result.process.id) {
      processId = result.process.id;
      ProcessCache.setProcessIdUsedRecently(this.projectId, processId);
    }

    // Set all of the cache options first.
    const typeaheadContexts = [];
    for (const type of typesNotLoadedYet) {
      // It's possible the id for type has changed now that we know the process Id.
      const oldIdForType = this.getIdForType(type);
      const newIdForType = this.getIdForType(type, processId);
      const typeCode = UIUtils.getTypeCodeForModelName(type);

      let typeaheads = result.instances.filter(option => option.typeCode === typeCode);

      this.setCacheOptions(typeaheads, type, newIdForType, true);
      typeaheadContexts.push({type, typeCode, oldIdForType, typeaheads});
    }

    Logger.debug("MultipleTypeaheadObjectCache :: saving to storage");
    BaseObjectCache.persistInMemoryCacheToStorage(this.getCacheName()).then(() => {
      BaseObjectCache.persistInMemoryCacheToStorage(this.getArchivedCacheName()).then(() => {
        Logger.debug("MultipleTypeaheadObjectCache :: saved to storage");
      });
    });


    // Then call the onLoaded functions
    for (const {type, typeCode, oldIdForType, typeaheads} of typeaheadContexts) {
      const oldIdToOnLoadedFunctions = this.getIdToOnLoadedFunctions(type);
      const onLoadedFunctions = oldIdToOnLoadedFunctions[oldIdForType];
      if (onLoadedFunctions) {
        for (let onLoadedFunction of onLoadedFunctions) {
          onLoadedFunction(typeaheads, typeCode);
        }
      }
    }
  }

  /**
   * Call this method to invalidate cache options so they're reloaded for the type passed into the constructor the next
   * time one of the load() * methods are called.
   */
  invalidateCacheOptions() {
    for (const type of this.types) {
      Logger.debug("MultipleTypeaheadObjectCache :: invalidateCacheOptions for " + type);
      this.clearIdToOnLoadedFunctions(type);
      this.invalidateCacheHelper(this.getCacheName(), type);
      this.invalidateCacheHelper(this.getArchivedCacheName(), type);
    }

    MemoryCache.clearAllInstances();
  }

  async invalidateCacheOptionsAsync() {
    const promises = [];
    for (const type of this.types) {
      Logger.debug("MultipleTypeaheadObjectCache :: invalidateCacheOptionsAsync for " + type);
      this.clearIdToOnLoadedFunctions(type);
      promises.push(this.invalidateCacheHelperAsync(this.getCacheName(), type));
      promises.push(this.invalidateCacheHelperAsync(this.getArchivedCacheName(), type));
    }

    return Promise.all(promises);
  }

  async invalidateCacheHelperAsync(cacheName, type) {
    // Set the id first for this type.
    this.id = this.getIdForType(type);
    return super.invalidateCacheHelperAsync(cacheName, type);
  }

  invalidateCacheHelper(cacheName, type) {
    // Set the id first for this type.
    this.id = this.getIdForType(type);
    super.invalidateCacheHelper(cacheName, type);
  }

  getOptionsFromCache() {
    throw new Error("The multiple typeahead cache can't be used to get a single type");
  }
}
