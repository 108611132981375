var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from 'react';
/**
 * An utility function for asynchronous focus/blur handling.
 *
 * By default, the `onFocus` and `onBlur` callbacks are called every time a child components receives/loses focus.
 * Use this utility hook for scenarios where you need to know if the parent has received focus for the first time, or completely lost focus.
 *
 * @returns
 */
export function useAsyncFocusBlur(_a) {
    var onFocus = _a.onFocus, onBlur = _a.onBlur, onSyncFocus = _a.onSyncFocus, onSyncBlur = _a.onSyncBlur;
    var focused = React.useRef(false);
    var tick = React.useRef(0);
    var nextTick = React.useCallback(function (f) {
        clearTimeout(tick.current);
        tick.current = window.setTimeout(function () { return f(); });
    }, [tick]);
    var handleFocus = React.useCallback(function () {
        var event = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            event[_i] = arguments[_i];
        }
        clearTimeout(tick.current);
        if (onSyncFocus) {
            onSyncFocus.call(undefined, event[0]);
        }
        if (focused.current) {
            return;
        }
        ;
        focused.current = true;
        if (onFocus) {
            onFocus.call.apply(onFocus, __spreadArray([undefined], event, false));
        }
    }, [focused, onFocus, onSyncFocus]);
    var handleBlur = React.useCallback(function () {
        var event = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            event[_i] = arguments[_i];
        }
        if (onSyncBlur) {
            onSyncBlur.call(undefined, event[0]);
        }
        nextTick(function () {
            if (!focused.current) {
                return;
            }
            if (onBlur) {
                focused.current = false;
                onBlur.call.apply(onBlur, __spreadArray([undefined], event, false));
            }
        });
    }, [focused, nextTick, onBlur, onSyncBlur]);
    React.useEffect(function () {
        return function () {
            clearTimeout(tick.current);
        };
    }, []);
    return {
        onFocus: handleFocus,
        onBlur: handleBlur
    };
}
