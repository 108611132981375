/* stylelint-disable-next-line import-notation */
@import "../../../css/_brand_colors.scss";

.drop-down-element {
  z-index: 300;

  :global(button) {
    margin: unset;
    padding: 8px 12px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    outline: 0 none !important;
    box-shadow: none;
    color: $brand-dark-grey;
    font-size: 13px;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid #C0C8CC;
    width: max-content;


    &::after {
      cursor: pointer;
      display: none !important;
    }

    &:hover {
      box-shadow: unset;
      border: 1px solid #c3c3c2 !important;
      background-color: $brand-light-grey !important;
    }

    &:focus {
      box-shadow: unset;
      background-color: $brand-light-grey !important;
    }

    :global(.fa-caret-down) {
      display: none !important;
    }

    :global(.fa-chevron-down) {
      color: $brand-medium-grey !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 900;
      line-height: 24px;
    }
  }

}



