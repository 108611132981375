"use strict";

import React, { Fragment } from "react";
import CannedReport from "./canned_report";
import * as CommonUtils from "../../../server/common/generic/common_utils";

/* This class shows Specification canned report */
export default class SpecificationCannedReport extends CannedReport {
  constructor(props) {
    super(props);
  }

  renderFilters() {
    if (this.results) {
      return <Fragment>
        <div className="col-sm-6">
          {this.renderSubReportFilter()}
        </div>
      </Fragment>;
    }
  }

  renderSubReportFilter() {
    const {editableModelNames, defaultSubReport} = this.props.reportOptions;
    const subtypes = Object.entries(editableModelNames);
    return (
      <div id="subtypeFilter" className="attribute-container">
        <label id="subtypeComboBoxLabel"
               htmlFor="subtypeComboBox"
               className="col-form-label base-attribute"
        >
          Subtype:
        </label>
        <select
          id="subtypeComboBox"
          className="form-control"
          defaultValue={defaultSubReport}
          onChange={this.handleSubReportFilterChange}
        >
          {subtypes.map(entry => {
            const [key, value] = entry;
            return (
              <option value={key} key={key}>
                {value.header ? value.header : CommonUtils.convertCamelCaseToSpacedOutWords(key)}
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  handleSubReportFilterChange(event) {
    const {value} = event.target;
    const {filterOptions} = this.state;

    filterOptions.subReport = value;

    this.clearReport();
    this.setStateSafely(
      {
        reportResults: null,
        filterOptions,
      },
      () => {
        this.loadHistoricalReportData();
      }
    );
  }
}
