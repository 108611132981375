import { generateNavigatableId } from '../../navigation/utils';
import { ColumnDefaultProps } from '../ColumnProps';
function nextColumn(columns, current) {
    var currentDepth = columns[current].depth;
    var next = null;
    for (var index = current + 1; index < columns.length; index++) {
        if (columns[index].depth === currentDepth) {
            next = columns[index];
            break;
        }
    }
    return next;
}
var getColumnWidth = function (column) {
    var width = column.width ? parseFloat(column.width.toString()) : 0;
    if (!width && column.children && column.children.length) {
        width = column.children.reduce(function (currentSum, child) { return getColumnWidth(child) + currentSum; }, 0);
    }
    return width;
};
/**
 * @hidden
 */
export function mapColumns(columns) {
    var columnsMap = [[]];
    var maxDepth = 0;
    // set colspans, get maxdepth
    for (var q = columns.length - 1; q >= 0; q--) {
        maxDepth = Math.max(maxDepth, columns[q].depth);
        columns[q].colSpan = columns[q].colSpan || 1;
        if (columns[q].parentIndex !== -1) {
            columns[columns[q].parentIndex].colSpan =
                (columns[columns[q].parentIndex].colSpan || 0) + columns[q].colSpan;
        }
    }
    var colIndexByLevel = [];
    var rowSpan = 1;
    // set rowspan, kFirst, index AND create columnsMap
    columns.forEach(function (column, i) {
        columnsMap[column.depth] = columnsMap[column.depth] || [];
        var needKFirst = false;
        if (columnsMap[column.depth].length === 0) {
            if (rowSpan <= 1) {
                rowSpan = 1 + (column.children.length > 0 ? 0 : maxDepth - column.depth);
            }
            else {
                rowSpan--;
                needKFirst = true;
            }
        }
        column.rowSpan = 1 + (column.children.length > 0 ? 0 : maxDepth - column.depth);
        column.kFirst = needKFirst;
        column.index = columnsMap[column.depth].length;
        columnsMap[column.depth].push(i);
        column.ariaColumnIndex = colIndexByLevel[column.depth] ? colIndexByLevel[column.depth] + 1 : 1;
        for (var ci = column.depth; ci < column.depth + column.rowSpan; ci++) {
            colIndexByLevel[ci] = (colIndexByLevel[ci] || 0) + column.colSpan;
        }
    });
    var stickyLeftWidth = new Array(columnsMap.length).fill(0);
    var width = 0;
    // set left AND create stickyLeftWidth
    columns.forEach(function (column) {
        if (column.locked) {
            column.left = stickyLeftWidth[column.depth];
            width = getColumnWidth(column);
            if (column.children.length === 0) {
                for (var i = column.depth; i < stickyLeftWidth.length; i++) {
                    stickyLeftWidth[i] += width;
                }
            }
            else {
                stickyLeftWidth[column.depth] += width;
            }
        }
    });
    var stickyRightWidth = new Array(columnsMap.length).fill(0);
    // set right, rightBorder AND create stickyRightWidth
    for (var i = columns.length - 1; i >= 0; i--) {
        var column = columns[i];
        if (column.locked) {
            column.right = stickyRightWidth[column.depth];
            width = getColumnWidth(column);
            if (column.children.length === 0) {
                for (var j = column.depth; j < stickyRightWidth.length; j++) {
                    stickyRightWidth[j] += width;
                }
            }
            else {
                stickyRightWidth[column.depth] += width;
            }
            var next = nextColumn(columns, i);
            column.rightBorder = !(next && next.locked);
        }
    }
    return columnsMap;
}
/**
 * @hidden
 */
export function readColumns(elements, idInfo, depth) {
    if (depth === void 0) { depth = 0; }
    var columns = [];
    if (!elements || !elements.length) {
        return [];
    }
    elements.forEach(function (columnProps) {
        var children = readColumns(columnProps.children || [], idInfo, depth + 1);
        columns.push(Object.assign({}, { depth: depth }, ColumnDefaultProps, columnProps, {
            id: columnProps.id ? columnProps.id : generateNavigatableId("".concat(idInfo.prevId++), idInfo.idPrefix, 'column'),
            declarationIndex: columns.length,
            children: children,
            rowSpan: 0,
            colSpan: 0,
            isAccessible: true
        }));
    });
    var comparator = function (a, b) {
        return a.orderIndex === b.orderIndex ?
            a.declarationIndex - b.declarationIndex :
            ((a.orderIndex || 0) - (b.orderIndex || 0));
    };
    columns.sort(comparator);
    if (depth === 0) {
        var ret_1 = [];
        var flat_1 = function (cols, pIndex) {
            return cols.forEach(function (c) {
                c.parentIndex = pIndex;
                flat_1(c.children, ret_1.push(c) - 1);
            });
        };
        flat_1(columns, -1);
        return ret_1;
    }
    return columns;
}
/**
 * @hidden
 */
export function isRtl(element) {
    return Boolean(element && getComputedStyle(element).direction === 'rtl');
}
/**
 * @hidden
 */
export function getIndex(event, parent) {
    if (!parent || !event || !event.originalEvent) {
        return -1;
    }
    var target = parent.ownerDocument ? parent.ownerDocument.elementFromPoint(event.clientX, event.originalEvent.clientY) : null;
    while (target && target.parentElement !== parent) {
        target = target.parentElement;
    }
    var children = parent.children;
    for (var i = 0; i < children.length; i++) {
        if (children[i] === target) {
            return i;
        }
    }
    return -1;
}
