"use strict";

import * as UIUtils from "../ui_utils";
import InfoTooltip from "../widgets/tooltips/info_tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment } from "react";
import { generateRecordDiff } from "./diff/tech_transfer_diff_generator";
import { SENDING_SITE_LABEL } from "../configurableTables/fieldsConfig/constants/configurable_tables_columns_config";
import { getIsSiteMissing } from "./tech_transfer_utils";

/*
Functions on this file are used for common functions needed by both Telerik tables and export.
 */

export function getAttributeOperationInfo(record = {}) {
  let {
    lowerOperatingLimit,
    upperOperatingLimit,
    measurementUnits,
  } = record;

  let OperationSummary = "";
  if (record.typeCode === "PP") {
    // "LOL" - "UOL" "Measurement Units"
    OperationSummary +=
      lowerOperatingLimit && upperOperatingLimit ? `${lowerOperatingLimit} - ${upperOperatingLimit}` :
        lowerOperatingLimit || upperOperatingLimit ? `${lowerOperatingLimit || "None"} - ${upperOperatingLimit || "None"}` : "";

    OperationSummary = OperationSummary ? `Operating Limits: ${OperationSummary}` : "";
    OperationSummary += OperationSummary ? ` ${measurementUnits}` : "";
  }

  return OperationSummary;
}

export function getChangeInfo(record) {
  const transferredTo = record;
  const transferredFrom = record.techTransferredFrom || {};
  const ignoredChangesInformation = transferredTo.changes || transferredFrom.changes;
  const areChangesIgnored = ignoredChangesInformation &&
    ignoredChangesInformation.sendingVersion === transferredFrom.version &&
    ignoredChangesInformation.receivingVersion === transferredTo.version;
  const diff = generateRecordDiff(record.pluralizedModelName, record.techTransferredFrom, record, record.changeCriteria);

  const isSiteMissing = getIsSiteMissing(transferredTo, transferredFrom);
  const recordChanged = (diff && diff.totalChangesCount > 0) || isSiteMissing;

  return {
    areChangesIgnored,
    diff,
    recordChanged,
    isSiteMissing,
  };
}

export function renderAcceptanceCriteriaSubHeader(hideBackgroundColor = true) {
  return (
    <Fragment>
      <span className={hideBackgroundColor ? "" : "tech-transfer-acceptance-criteria-column-group"}>
        Acceptance Criteria
      </span>
      <InfoTooltip id="infoTechTransferAssessment"
                   textDirection="right"
                   className="ml-0"
                   verbiage={
                     <div>
                       These columns compare a set of selected fields between the sending and receiving sites.
                       <br />
                       <br />
                       <span className="font-weight-bold">
                         Process Component
                       </span>
                       <br />
                       <ul>
                         <li>Supplier</li>
                         <li>Supplier Part Number</li>
                         <li>Description</li>
                       </ul>
                       <span className="font-weight-bold">
                         Material
                       </span>
                       <br />
                       <ul>
                         <li>Empirical Formula</li>
                         <li>Molecular Weight</li>
                         <li>Supplier</li>
                         <li>Supplier Part Number</li>
                         <li>Internal Part Number</li>
                         <li>Compendial Standard</li>
                         <li>Description</li>
                       </ul>
                       <span className="font-weight-bold">
                          Process Parameters / Material Attributes
                       </span>
                       <br />
                       <ul>
                         <li>Specification Limits</li>
                         <li>Operating Limits</li>
                         <li>Description</li>
                       </ul>
                       <span>
                         Please use the
                         <FontAwesomeIcon className="tech-transfer-review-icon ml-1 mr-1"
                                          size="sm"
                                          icon={faEye}
                         />
                         button to see additional side-by-side comparisons.
                       </span>
                     </div>
                   }
      />
    </Fragment>);
}

export function getModelChangeCriteria(changeCriteria, selectedModelType) {
  return (changeCriteria || []).find(criteria =>
    criteria.modelName === UIUtils.getTypeCodeForModelName(selectedModelType)) ||
    {};
}

export function getPluralizedModelName(record) {
  return UIUtils.pluralize(UIUtils.getModelNameForTypeCode(record.typeCode));
}

export function formatField(field, fromProcess, toProcess) {
  const isSendingField = isSendingSiteField(field);
  field = isSendingField ?
    `${field.replace("techTransferredFrom.", "")}` :
    `${field}`;
  field = UIUtils.convertCamelCaseToSpacedOutWords(field);
  return `${field} (${isSendingField ? fromProcess.name : toProcess.name})`;
}

export function getFieldClass(field) {
  const fieldText = typeof field === "object" ? field.prop : field;
  return isSendingSiteField(field) ?
    `${fieldText.replace("techTransferredFrom.", "")}_sending` :
    `${fieldText}_receiving`;
}

export function isSendingSiteField(field) {
  return typeof field === "object" ?
    field.originatedFromSite === SENDING_SITE_LABEL :
    (field.includes("techTransferredFrom.") ||
    field.includes("_sending"));
}
