/**
 * @hidden
 */
export var toRound = function (num, base) {
    if (num % 1) {
        var percent = Math.pow(10, (base.toString().split('.')[1] || base.toString().split('.')[0]).length);
        return Math.round(num * percent) / percent;
    }
    return num;
};
// Opting out of JS `modulo` calculation due to floating-point problems in JS
// We're looking for the reminder - R.
// x / y = a + R
// R = (x / y) - a | *y
// R = x - (y * a)
// : a = Math.floor(x /y );
/**
 * @hidden
 */
export var getRemainder = function (x, y) { return x - (y * Math.floor(x / y)); };
/**
 * @hidden
 */
export var isHalf = function (index, value, step) {
    return (toRound(index - step, step) < value) && (value < index);
};
/**
 * @hidden
 */
export var isSelected = function (index, value, step, selection) {
    if (value === null) {
        return false;
    }
    if (selection === 'continues') {
        return isHalf(index, value, step)
            ? toRound(index - step / 2, step / 2) <= value
            : index <= value;
    }
    if (selection === 'single') {
        return isHalf(index, value, step)
            ? toRound(index - step / 2, step / 2) === value
            : index === value;
    }
    return false;
};
/**
 * @hidden
 */
export var calcIsFirstHalf = function (dir, rect, clientX) {
    return dir === 'ltr'
        ? clientX < (rect.x + (rect.width / 2))
        : clientX > (rect.x + (rect.width / 2));
};
/**
 * @hidden
 */
export var isCorrectValue = function (min, max, step, value) {
    for (var i = min; i <= max; i += step) {
        if (value === i) {
            return true;
        }
    }
    return false;
};
