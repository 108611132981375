import { can } from "../../utils/ui_permissions";
import CommonSecurity from "../../../server/common/generic/common_security";
import * as UIUtils from "../../ui_utils";
import { getProjectFromCache } from "../../utils/project_helper";
import BaseRelatedRecordsTable from "./base_related_records_table";

/**
 * Class responsible for rendering related record that are part of a process
 */
export default class BaseRelatedRecordsTableWithProcess extends BaseRelatedRecordsTable {

  constructor(props) {
    super(props);

    this.setStateSafely({
      canEdit: this.canEdit(),
      canAdd: this.canAdd(),
      process: this.getProcessFromCache(),
      project: getProjectFromCache(this.props.projectId)
    });
  }

  /**
   * WARNING: shouldComponentUpdate is not being called when the component is viewed in process explorer.
   * I suspect we have a context defined that sends the upstream parameters
   * https://stackoverflow.com/questions/58132922/shouldcomponentupdate-is-not-being-called
   * @param nextProps
   * @param nextState
   * @returns {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (super.shouldComponentUpdate(nextProps, nextState)) {
      return true;
    }

    if (this.supportsProcess()) {
      if (!nextState.process || nextState.process?.id !== this.state.process?.id) {
        return true;
      }
    }

    if (this.supportsProject()) {
      if (!nextState.project || nextState.project?.id !== this.state.project?.id) {
        return true;
      }
    }

    return false;
  }

  supportsProcess() {
    return this.props.processId || this.state.processId;
  }

  supportsProject() {
    return this.props.projectId || this.state.projectId;
  }

  componentDidUpdate(previousProps, previousState) {
    const stateObject = {};
    if (previousState.canEdit !== this.canEdit()) {
      stateObject.canEdit = can(CommonSecurity.Actions.EDIT, this.modelFullName);
    }

    if (previousState.canAdd !== this.canAdd()) {
      stateObject.canAdd = can(CommonSecurity.Actions.ADD, this.modelFullName);
    }

    if (this.supportsProcess()) {
      if (!this.state.process || previousState.process?.id !== this.state.process?.id) {
        const process = this.getProcessFromCache();
        if (process) {
          stateObject.process = process;
        }
      }

      if (!this.state.project || previousState.project?.id !== this.state.project?.id) {
        const project = getProjectFromCache(this.props.projectId);
        if (project) {
          stateObject.project = project;
        }
      }
    }

    if (Object.keys(stateObject).length > 0) {
      this.setStateSafely(stateObject);
    } else {
      super.componentDidUpdate(previousProps, previousState);
    }
  }

  canEdit() {
    return can(CommonSecurity.Actions.EDIT, this.modelFullName);
  }

  canAdd() {
    return can(CommonSecurity.Actions.EDIT, this.modelFullName);
  }

  get modelFullName() {
    return UIUtils.convertCamelCaseToSpacedOutWords(this.props.modelName);
  }

  getProject() {
    return this.state.project ?? getProjectFromCache(this.props.projectId);
  }

  getProcess() {
    return this.state.process ?? this.getProcessFromCache();
  }
}