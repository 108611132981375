"use strict";

import React, { Fragment } from "react";
import BaseReactComponent from "../../base_react_component";
import { REPORT_OPTIONS_ENUM, REPORT_TYPES_ENUM } from "../../reports/constants/report_constants";
import ReportURLGenerator from "../../reports/report_url_builder";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* This shows the view analytics link on the record review */

const REPORT_OPTIONS = REPORT_OPTIONS_ENUM.ProcessCapabilityDashboard;
const REPORT_TYPE = REPORT_TYPES_ENUM.ProcessCapabilityDashboard;

export default class TechTransferViewAnalyticsWidget extends BaseReactComponent {
  render() {
    const {record, parent} = this.props;
    const {projectId} = parent.props;
    const {typeCode, id, processId} = record;
    const urlParams = {
      reportType: REPORT_TYPE,
      projectId,
      processId,
      modelLabel: `${typeCode}-${id}`,
    };

    const reportUrl = ReportURLGenerator.generateURL(REPORT_OPTIONS, urlParams);
    return id ? <Fragment>
      <a
        href={reportUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>
          <FontAwesomeIcon icon={faChartLine} /> View Analytics
        </span>
      </a>
    </Fragment> : "";
  }
}
