@import "../../../../css/_brand_colors.scss";

.risk-pill-container {
  display: inline-flex;
  max-width: 100%;
  padding: 4px 8px;
  align-items: center;
  justify-items: center;
  gap: 4px;
  border-radius: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.label {
  color: $brand-black;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.002px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.not-assessed-label {
  font-weight: 400;
  color: #434952;
  background-color: #F0F3F5;
}