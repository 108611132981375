"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

var _default = _propTypes["default"].oneOfType([_propTypes["default"].arrayOf(_propTypes["default"].object.isRequired), _propTypes["default"].arrayOf(_propTypes["default"].string.isRequired)]);

exports["default"] = _default;