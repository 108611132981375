"use strict";

import { createHtmlElement } from "../../../../../cells/cells_helper";

export default class HtmlBuilder {
  constructor(tagName, className, parent, width) {
    this.element = createHtmlElement(tagName, className, parent, width);
  }

  addChild(tagName, className, content, width) {
    createHtmlElement(tagName, className, this.element, width, content);
    return this;
  }

  build() {
    return this.element;
  }
}
