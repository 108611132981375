"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseLinkedEntitiesAttribute from "../../editor/attributes/base_linked_entities_attribute";
import CommonUtils from "../../../server/common/generic/common_utils";
import { TEXT_DIFF_METHOD_ENUM } from "../../helpers/constants/constants";
import { createRowDataDiffForLinkedEntities, } from "../../helpers/diff_helper";
import { TrainingService } from "../services/training_service";
import { FIELD_INPUT_TYPE, FIELD_REQUIRED_FOR } from "../../editor/widgets/widget_field";

/**
 * @type {IWidgetField[]}
 */
export const WIDGET_FIELDS = [
  {
    isUnique: true,
    diffMethod: TEXT_DIFF_METHOD_ENUM.whole,
    requiredFor: FIELD_REQUIRED_FOR.save,
    forceUpdate: false,
    belongsToMasterRow: true,
    order: 1,
    inputType: FIELD_INPUT_TYPE.linkedTypeahead,
    width: 250
  },
];

export default class TrainingContentsAttribute extends BaseLinkedEntitiesAttribute {
  constructor(props) {
    super(props, WIDGET_FIELDS);

    this.documentMap = new Map();
    this.trainingService = new TrainingService();
    this.errorMessages.editedRowPending = "At least one document link is in edit state. Please save or cancel all document links in edit state.";
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.documentMap = new Map((this.props.allDocuments || []).map((doc) => ([doc.id, doc])));
    super.componentDidUpdate(prevProps, prevState, snapshot);
  }

  /**
   * @inheritDoc
   */
  needsProjectId() {
    return false;
  }

  /**
   * @inheritDoc
   */
  isView() {
    return this.props.readOnly || super.isView();
  }

  /**
   * @inheritDoc
   */
  getTypeaheadRequestData() {
    return {
      approved: true,
      includeChildren: true,
    };
  }

  /**
   * @inheritDoc
   */
  getItemTooltip(item, typeaheadItem) {
    const tooltipContents = [];

    // if the item is a CurriculumAssignment or CurriculumAssignmentLinkedVersion, we have
    if (typeaheadItem && !item.Documents) {
      item = typeaheadItem;
    }

    if (item.Documents) {
      tooltipContents.push(...item.Documents.map(doc => {
        const loadedDocument = this.documentMap.get(doc.id);
        const docToDisplay = {...loadedDocument, typeCode: "DOC"};
        return <div key={doc.id}>{UIUtils.getRecordCustomLabelForDisplay(docToDisplay)}</div>;
      }));
    }

    return tooltipContents.length > 0 ? (
      <div className="item-link-tooltip qbdvision-tooltip">
        {tooltipContents}
      </div>
    ) : null;
  }

  /**
   * Loads and returns all typeahead options for all linked entities
   */
  getAllTypeaheadOptions() {
    if (this.hasLinkToObject()) {
      const typeaheadItems = (this.props.typeaheadItems || [])
        .filter(this.trainingService.isApprovedVersion);

      const options = typeaheadItems.map(item => {
        return {
          id: item.id,
          label: CommonUtils.getRecordCustomLabelForDisplay(item),
          name: item.name,
          Documents: item.Documents || [],
          typeCode: item.typeCode,
          sortField: item.sortField,
          customID: item.customID || item.customId || null,
        };
      });
      options.sort(CommonUtils.sortBy("sortField", "id"));
      return options;
    }
    return [];
  }

  /**
   * Updates the raw data row with file and attachment object information from the inline editor and the edited row attached to it
   */
  setFileData() {
    // does nothing as we don't have attached files here
  }


  /**
   * @inheritDoc
   */
  showRecordValidationErrors(editedRow, errorReport) {
    let index = editedRow.index;
    if (index >= 0) {
      const errorDiv = $("#" + this.props.name + "_LinkTo_" + index + "ErrorDiv");
      errorDiv.show();
      if (errorReport.has("unique")) {
        errorDiv.text(this.getLinkToObjectDisplayName() + " must be unique.");
      } else {
        errorDiv.text(this.getLinkToObjectDisplayName() + " is required.");
      }
      const formGroupDiv = $("#" + this.props.name + "_LinkToFormGroup_" + index);
      formGroupDiv.addClass("has-error");
    }
  }

  /**
   * This method intercepts the raw data coming from DB and fixes row index inconsistencies that might be caused
   * from features outside of the UI. It will reorganize the indexes of the rows as they are retrieved from the database
   * prioritizing first the rows with index 0. It will also fill in the uuid for any records that are missing it.
   * https://cherrycircle.atlassian.net/browse/QI-2159
   */
  prepareRawData(rows) {
    // ensures an item without id shows up in the end and that items are sorted by custom id or typeCode + id
    rows = rows.map(
      item => {
        const typeCode = item.typeCode || CommonUtils.getTypeCodeForModelName(this.props.linkToObject[0]);
        const idForItem = this.getCustomIdForItem(item, typeCode);
        return ({
          ...item,
          // workaround to make sorting by custom ID work
          sortField: idForItem,
        });
      }
    );

    // only sorts rows if nothing is being edited or added
    if (this.editedRows.length === 0) {
      rows.sort(CommonUtils.sortBy("sortField", "id"));
    }

    let index = 0;
    for (let row of rows) {
      row.index = index++;
    }
    return rows;
  }

  getCustomIdForItem(item, typeCode) {
    return item.customId || item.customID || (item.id ? typeCode + "-" + (item.id).toString().padStart(5, "0") : "ZZZZZZZ");
  }

  /**
   * Returns the value (rows) of this control as an object
   * @returns {*[] | *}
   */
  getValueAsObject(linkObjectFilter) {
    return this.prepareRawData(this.getValue(linkObjectFilter));
  }

  /**
   * Gets the value for the specified field within the specified row
   * @param row {*} The row to get the value from
   * @param field {*} The field to get the value from
   * @return {*} The value of the field
   */
  getFieldValueInRow(field, row) {
    const value = row && (row.selectedOption || row);
    return value.id;
  }

  /**
   * Returns the typeahead option if it exists from the selected id on the underlying row
   * @param row The row given which the typeahead option is searched
   * @returns {null}
   */
  getLinkSelectedOption(row) {
    if (this.hasLinkToObject()) {
      for (let linkToObject of this.props.linkToObject) {
        if (row[this.getLinkToId(linkToObject)]) {
          let typeaheadOptions = this.getAllTypeaheadOptions();
          return typeaheadOptions.find(option => {
            return option.id === row[this.getLinkToId(linkToObject)];
          });
        }
      }
    }

    return null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      return (
        JSON.stringify(this.props.typeaheadItems) !== JSON.stringify(nextProps.typeaheadItems)
        || JSON.stringify(this.props.items || []) !== JSON.stringify(nextProps.items || [])
      );
    }
    return true;
  }


  initializeLink(editedRow) {
    editedRow.links = [];
  }

  /**
   * Prepares the table data for display when we are viewing the attribute in version diff mode and
   * just returns the underlying data when viewing in edit or view mode
   */
  preProcessDataForTable() {
    if (this.isDiffingVersions() && !this.props.readOnly) {
      let diffedRowData = [];
      for (let i = 0; i < this.props.linkObject.length; i++) {
        let linkObject = this.props.linkObject[i];
        let linkToObject = this.hasLinkToObject() ? this.props.linkToObject[i] : null;
        let parentStateVersionsName = this.getParentStateVersionsName(linkObject);
        let oldValue = this.getOldValue(parentStateVersionsName);
        let currentValue = this.getValueAsObject(linkObject);

        diffedRowData = diffedRowData.concat(createRowDataDiffForLinkedEntities(this.props.name,
          oldValue,
          currentValue,
          this.widgetFields,
          this.getLinkToId(linkToObject),
          this.handleFileDownload));
      }

      for (let i = 0; i < diffedRowData.length; i++) {
        diffedRowData[i].index = i;
      }

      return diffedRowData;
    } else {
      return this.getValueAsObject();
    }
  }

  /**
   * Handles the edit even of a datatables row. This method will populate the edited row properties from the raw data row and will
   * display the inline editor
   * @param editedRow The edited row to populate with the row fields and values
   * @param row The raw data row for which the inline editor will be displayed
   */
  handleEditLink() {
    // does nothing
  }

  /**
   * Returns the versions attribute name used to persist in the parent object state the linked entity row information
   * @param linkObject The linked entity for which the state attribute name will be returned
   * @returns {*}
   */
  getParentStateVersionsName(linkObject) {
    let result;
    let typeName = this.props.linkObject && this.props.linkObject[0]
      ? this.props.linkObject[0]
      : linkObject;

    if (this.props.isSimpleManyToMany) {
      result = CommonUtils.pluralize(typeName);
    } else {
      result = super.getParentStateVersionsName(linkObject);
    }
    return result;
  }
}
