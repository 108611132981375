"use strict";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BaseReactComponent from "../../base_react_component";

/**
 * This class is for producing a button on a table
 */
export default class TableButton extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <span title={this.props.title}> {/* This is separate because disabled anchors don't show the title tooltip correctly. */}
        <a href={this.props.disabled ? null : this.props.href}
           target={this.props.target || "_self"}
           rel="noreferrer noopener"
           onClick={this.props.disabled ? null : this.props.onClick}
           className={this.props.className}
           id={this.props.id}
           disabled={this.props.disabled}
           aria-haspopup={this.props.ariaHaspopup}
           aria-expanded={this.props.ariaExpanded}
           data-toggle={this.props.dataToggle}
        >
          <FontAwesomeIcon icon={this.props.fontAwesomeIcon} />
        </a>
      </span>
    );
  }

}
