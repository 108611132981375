"use strict";

import * as style from "./base_approvable_editor_page.module.scss";

import * as UIUtils from "../ui_utils";
import React, { Fragment } from "react";
import BaseApprovableEditor from "./base_approvable_editor";
import TrialBar from "../widgets/bars/trial_bar";
import CookiesBar from "../widgets/bars/cookies_bar";
import CompanyHeader from "../widgets/headers/company_header";
import EditablesPageTitleBar from "../widgets/pageTitleBar/editables_page_title_bar";
import NavBar from "../widgets/bars/nav_bar";
import ErrorBar from "../widgets/bars/error_bar";
import ApprovalContainer from "./approval/approval_container";
import StaticApprovalContainer from "./approval/static_approval_container";
import HistoryContainer from "./history/history_container";
import TermsPopup from "../terms/terms_popup";
import { EDITOR_TYPES, SIDE_PANEL_TABS_ENUM, RECORD_OPERATIONS } from "./editor_constants";
import QuickPanelTitleBar from "../widgets/recordTitleBars/quick_panel_title_bar";
import TitleBar from "../widgets/recordTitleBars/title_bar";
import { EMPTY_STRING, RISK_TYPE_ENUM } from "../helpers/constants/constants";
import { getLatestApprovalRequestVersionTransition } from "../helpers/approval_helper";
import StaticPanelTitleBar from "../widgets/recordTitleBars/static_panel_title_bar";
import TabNavBar from "../widgets/bars/tab_nav_bar";
import { getProjectFromCache } from "../utils/project_helper";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";
import MemoryCache from "../utils/cache/memory_cache";
import { measurePerformanceEnd, measurePerformanceStart } from "../ui_utils";
import { getApprovalValue, handleApprovalChangeValue, handleApprovalChangeValues } from "./approval/approval_utils";
import { RelatedPanelNotice } from "./widgets/related_panel_notice";
import SideMenu from "../documentEditor/components/sideMenu/side_menu";
import RiskUtils from "../../server/common/misc/common_risk_utils";

/**
 * For saving the history showing state to local storage.
 * @type {string}
 */
const IS_HISTORY_SHOWING = "IS_HISTORY_SHOWING";

/**
 * This is the base class that every approvable editable that wants to be displayed as (at least) a full page should
 * extend from. Extend from BaseQuickEditor if you want to be able to be displayed as either a full page or in a
 * quick/static editor.
 */
export default class BaseApprovableEditorPage extends BaseApprovableEditor {
  constructor(props, baseTypeName, capitalizedBaseTypeName, displayName) {
    super(props, baseTypeName, capitalizedBaseTypeName, displayName);

    this.handleApprovalChangeValue = handleApprovalChangeValue.bind(this);
    this.handleApprovalChangeValues = handleApprovalChangeValues.bind(this);
    this.getApprovalValue = getApprovalValue.bind(this);

    this._securityTypeName = UIUtils.convertCamelCaseToSpacedOutWords(this.capitalizedBaseTypeName);
    this.extraPrintItems = {};
    this.setExtraPrintItems([{
      order: Number.MAX_SAFE_INTEGER,
      label: "QbDVision Version",
      data: UIUtils.getSoftwareVersion(),
    }]);
    const isHistoryShowing = localStorage[IS_HISTORY_SHOWING];
    this.setHistoryShowing(typeof isHistoryShowing === "undefined" ? true : JSON.parse(isHistoryShowing));

    this.bodyDiv = React.createRef();
    this.attributesContainer = React.createRef();

    if (this.isAdd() && this.projectId) {
      const memoryCache = MemoryCache.getNamedInstance("project_helper");
      const cacheKey = "project_" + this.projectId;
      const project = memoryCache.get(cacheKey);
      const otherProps = {
        parent: "Projects",
        parentLink: "/projects/list.html",
      };
      if (project) {
        this.setStateSafely({
          breadcrumb: {
            current: UIUtils.getRecordCustomLabelForDisplay(project),
            ...otherProps
          },
        });
      } else {
        new TypeaheadObjectCache("Project").loadOptions(() => {
          this.setStateSafely({
            breadcrumb: {
              current: UIUtils.getRecordCustomLabelForDisplay(getProjectFromCache(this.projectId)),
              ...otherProps
            },
          });
        });
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (!props?.cachedData?.modelName) {
      return null;
    }

    const propertyName = props.cachedData.modelName + "Versions";
    const propertyVersions = props.cachedData[propertyName];
    const recordStateFromProperty = propertyVersions && propertyVersions.length > 0 ? propertyVersions[0].currentState : null;
    const stateVersions = state && state[propertyName];
    const recordStateFromState = stateVersions && stateVersions[0].currentState;
    if (recordStateFromProperty && recordStateFromProperty !== recordStateFromState) {
      return JSON.parse(JSON.stringify(props.cachedData));
    }

    return null;
  }


  /**
   * Quick editor clears whole state to guarantee consistent data showing
   * on the editor that's not affected by the cached data.
   */
  loadDefaultState() {
    if (this.hasRelatedItemsTab() &&
      this.props.operation !== RECORD_OPERATIONS.BULK_PROPOSE &&
      !(this.getEditorType() === EDITOR_TYPES.QUICK_PANEL && (this.isAdd() || this.isEdit()))) {

      this.setStateSafely({
        sidePanelTabs: this.getSidePanelTabs(),
        selectedTab: this.getDefaultSidePanelTab(),
        childInstances: this.state.childInstances || [],
      });

      if (this.isRecordCached()) {
        this.updateTabsState();
      }
    }
  }

  /**
   * @return {{disabled: boolean, title: string}} The tab that should be selected by default.
   */
  getDefaultSidePanelTab() {
    return SIDE_PANEL_TABS_ENUM.RELATED_RECORDS;
  }

  /**
   * @return {*} An enum object that specifies what are the tabs supported by this screen
   */
  getSidePanelTabs() {
    return SIDE_PANEL_TABS_ENUM;
  }

  onDataReceivedFromServer() {
    super.onDataReceivedFromServer();
    this.updateTabsState();

    if (this.getProjectId()) {
      let project = {typeCode: "PRJ", id: this.getProjectId(), name: this.getProjectName()};
      this.setStateSafely({
        breadcrumb: {
          current: UIUtils.getRecordCustomLabelForDisplay(project),
          parent: "Projects",
          parentLink: "/projects/list.html",
        },
      });
    }
  }

  handleReceiveRevisionDataFromServer(versionMap, callback) {
    super.handleReceiveRevisionDataFromServer(versionMap, callback);
    this.updateTabsState();
  }

  handleHistory(event) {
    super.handleHistory(event);
    this.updateTabsState();
  }

  hasRelatedItemsTab() {
    return false;
  }

  handleTabChanged(tab) {
    this.setStateSafely({
      selectedTab: {...tab},
    });
  }

  handleTermsPopupApproveButtonClick() {
    if (this.termsPopup) {
      $(this.termsPopup).modal("hide");
    }
  }

  /**
   * This will update the state of the tabs when the user navigates through the history panel and based on the record
   * status and logged in user. There are 2 rules this method enforces:
   * 1. The related records tab should be disabled when looking at a previous version
   * 2. The history tab should be auto selected when the logged in user is the reviewer and the record is in proposed state.
   */
  updateTabsState() {
    const {currentState} = this.state;
    if (this.hasRelatedItemsTab() && this.state.sidePanelTabs) {
      const sidePanelTabs = UIUtils.deepClone(this.state.sidePanelTabs);

      // Re-enable the related records panel (if existing).
      // It will be disabled if needed a few steps below.
      if (sidePanelTabs.RELATED_RECORDS) {
        sidePanelTabs.RELATED_RECORDS.disabled = false;
      }

      if (this.isDiffingVersions()) {
        this.disableAllSidePanelTabsButOne(sidePanelTabs, sidePanelTabs.HISTORY);
        this.setStateSafely({
          selectedTab: {...sidePanelTabs.HISTORY},
          sidePanelTabs,
        });

      } else if (this.isApproved() && this.isView() && this.showSidebarOnApproved()) {
        sidePanelTabs.HISTORY.visible = false;
        this.setStateSafely({
          selectedTab: {...this.getDefaultSidePanelTab()},
          sidePanelTabs,
        });
      } else {

        const recordVersions = this.state[`${this.capitalizedBaseTypeName}Versions`];
        if (currentState && currentState.startsWith(UIUtils.VERSION_STATES.PROPOSED) && recordVersions) {
          const latestVersion = recordVersions[0];
          const versionTransitions = latestVersion && latestVersion.versionTransitions;
          const approvalRequestTransition = versionTransitions && getLatestApprovalRequestVersionTransition(versionTransitions);
          const approvalRequest = approvalRequestTransition && approvalRequestTransition.approvalRequest;
          const approvalReqToUsers = approvalRequest && approvalRequest.approvalReqToUsers;
          const currentUserIsReviewer = approvalReqToUsers
            && approvalReqToUsers.length
            && approvalReqToUsers.find(approvalReq => UIUtils.isCurrentUser(approvalReq?.user?.cognitoUUID));
          if (currentUserIsReviewer) {
            this.setStateSafely({
              selectedTab: {...sidePanelTabs.HISTORY},
            });
          }
        }
      }
    }
  }

  disableAllSidePanelTabsButOne(sidePanelTabs, tabToKeep) {
    for (let [key, value] of Object.entries(sidePanelTabs)) {
      if (value.title !== tabToKeep.title) {
        sidePanelTabs[key].disabled = true;
      }
    }
  }

  setExtraPrintItems(items) {
    // This is so when we use this as a prop later on, we don't end up changing the in-memory reference to some other component's props.
    this.extraPrintItems = {...this.extraPrintItems};
    for (let item of items) {
      this.extraPrintItems[item.label] = item;
    }
  }

  setHistoryShowing(isHistoryShowing) {
    localStorage[IS_HISTORY_SHOWING] = !!isHistoryShowing;
    this.setStateSafely({isHistoryShowing});
  }

  get securityTypeName() {
    return this._securityTypeName;
  }

  /**
   * Figure out if the current user can do said action with the given type and instance.
   * @param params.permissions {*[]} The permissions for the user
   * @param params.action {*} An action from CommonSecurity.Actions
   * @param params.type {*} An type from CommonSecurity.Types
   * @param params.instance {*} An instance of data.  While this isn't used yet, pass it in if you can so that supporting instance level security will be easier later on.
   * @param params.defaultVerificationResult {boolean} The result of the default permissions verification
   * @param [params.user] {*} The user being checked
   */
  handleCheckPermissions(params) {
    // does nothing. just returns the default validation result
    const {defaultVerificationResult} = params;
    return defaultVerificationResult;
  }

  renderPageTitleBar() {
    return <EditablesPageTitleBar projectId={this.getProjectId()}
                                  projectName={this.getProjectName()}
                                  projectDeletedAt={this.getProjectDeletedAt()}
                                  isDemoProject={this.isDemoProject()}
                                  currentState={this.getCurrentlyViewedState()}
    />;
  }

  renderCompanyHeader() {
    return <CompanyHeader extraPrintItems={this.extraPrintItems} breadcrumb={this.state.breadcrumb} />;
  }

  getTabName() {
    // Implement this in child classes for the Title bar's bread crumbs
    return null;
  }

  renderDefaultTitleBar(BarContent = EMPTY_STRING) {
    return (
      <TitleBar
        currentOperation={this.getCurrentOperation()}
        isLoading={this.isLoading()}
        currentState={this.getCurrentState()}
        effectiveRMP={this.getEffectiveRMPByModelName(this.capitalizedBaseTypeName)}
        instance={this.state}
        instanceTypeCode={UIUtils.getTypeCodeForModelName(this.baseTypeName)}
        riskType={RISK_TYPE_ENUM.CRITICALITY}
        sectionName={this.getTabName()}
        displayName={this.displayName}
        projectId={this.getProjectId()}
        BarContent={BarContent}
        selectedTab={this.getSelectedEditorTab()}
      />
    );
  }

  processQuickPanelButtons(props) {
    const parsedButtons = [];

    if (!props.quickPanelConfig?.quickPanelButtons) {
      return parsedButtons;
    }

    for (let button of props.quickPanelConfig.quickPanelButtons) {
      parsedButtons.push(this.processQuickPanelButton(button, props));
    }

    return parsedButtons;
  }

  processQuickPanelButton(button, props) {
    const {editorType, editorOperation} = props;

    const parsedButton = {...button};
    parsedButton.arguments = this.getButtonProperty(button.arguments, this.state, props, editorType, editorOperation);
    parsedButton.className = this.getButtonProperty(button.className, this.state, props, editorType, editorOperation) || "";
    parsedButton.tooltipText = this.getButtonProperty(button.tooltipText, this.state, props);
    parsedButton.disabled = this.getButtonProperty(button.disabled, this.state, props);
    parsedButton.icon = this.getButtonProperty(button.icon, this.state, props, editorType, editorOperation);
    parsedButton.text = this.getButtonProperty(button.text) || "";

    parsedButton.visible = typeof button.visible === "function"
      ? button.visible(editorType, editorOperation, props, this.state)
      : button.visible;

    return parsedButton;
  }

  getButtonProperty(property, state, props, ...args) {
    return typeof property === "function" ? property(...args, props, state) : property;
  }

  renderTitleBar() {
    const editorType = this.getEditorType();
    switch (editorType) {
      case EDITOR_TYPES.TRAINING_PANEL:
      case EDITOR_TYPES.QUICK_PANEL:
        return this.renderQuickPanelTitleBar();
      case EDITOR_TYPES.STATIC_PANEL:
      case EDITOR_TYPES.STATIC_PANEL_FULL_WIDTH:
        return this.renderStaticPanelTitleBar();
      default:
        return this.renderDefaultTitleBar();
    }
  }

  renderStaticPanelTitleBar() {
    return (
      <StaticPanelTitleBar
        isLoading={this.isLoading()}
        dataModified={!!this.state.dataModified}
        editorOperation={this.getCurrentOperation()}
        editorType={this.props.editorType}
        effectiveRMP={this.getEffectiveRMPByModelName(this.capitalizedBaseTypeName)}
        id={this.props.id}
        instance={this.props.isProposeForArchiveOrRestore && this.isRecordCached() ? this.props.cachedData : this.state}
        currentState={this.state.currentState}
        instanceTypeCode={UIUtils.getTypeCodeForModelName(this.baseTypeName)}
        riskType={RISK_TYPE_ENUM.CRITICALITY}
        onClosePanel={this.props.eventListeners.onCloseWindow}
        selectedTab={this.getSelectedEditorTab()}
        parent={this}
        versionId={this.getVersionId()}
      />);
  }

  renderQuickPanelTitleBar() {
    const properties = {
      editorOperation: this.getCurrentOperation(),
      dataModified: this.state.dataModified,
      quickPanelConfig: this.props.quickPanelConfig,
      editorType: this.props.editorType,
      operation: this.props.operation,
      projectId: UIUtils.parseInt(UIUtils.getParameterByName("projectId")),
      id: this.props.id,
      instance: this.state,
      instanceTypeCode: UIUtils.getTypeCodeForModelName(this.baseTypeName),
      currentState: this.state.currentState,
      parent: this,
      versionId: this.getVersionId()
    };

    const quckPanelButtons = this.processQuickPanelButtons(properties);

    return (
      <QuickPanelTitleBar
        buttons={quckPanelButtons}
        dataModified={!!this.state.dataModified}
        editorOperation={this.getCurrentOperation()}
        operation={this.props.operation}
        editorType={this.props.editorType}
        projectId={UIUtils.parseInt(UIUtils.getParameterByName("projectId"))}
        id={this.props.id}
        instance={this.state}
        instanceTypeCode={UIUtils.getTypeCodeForModelName(this.baseTypeName)}
        onActionClick={this.handleActionClick}
        onMarkAsReviewed={(button, ...args) => this.handleMarkAsReviewed(...args)}
        onReset={(button, ...args) => this.handleReset(...args)}
        onSave={(button, ...args) => this.handleSave(...args)}
        onSwitchViewMode={this.handleSwitchViewMode}
        onVerifyForPropose={(button, ...args) => this.handleVerifyForPropose.bind(this, true)(...args)}
        onView={(button, ...args) => this.handleView(...args)}
        currentState={this.state.currentState}
        parent={this}
        showButtonsInNewLine={this.props.quickPanelConfig.showButtonsInNewLine}
        versionId={this.getVersionId()}
        tabs={this.getEditorTabs()}
        selectedTab={this.getSelectedEditorTab()}
        onTabChanged={this.setSelectedEditorTab}
      />
    );
  }

  // For child classes to overwrite.
  shouldShowNav() {
    return true;
  }

  // For child classes to overwrite.
  shouldShowCompany() {
    return true;
  }

  // For child classes to overwrite.
  shouldShowCookies() {
    return true;
  }

  getErrorBar() {
    return <ErrorBar />;
  }

  renderSidePanel(versions) {
    const {
      selectedTab, sidePanelTabs,
    } = this.state;

    return this.hasRelatedItemsTab() ? (
      <Fragment>
        <TabNavBar selected={selectedTab}
                   className="related-tab-nav-margin"
                   onTabChanged={this.handleTabChanged}
                   tabs={UIUtils.deepClone(sidePanelTabs)}
                   parent={this}
                   parentVersionId={this.getVersionId()}
                   parentId={this.state.id}
        />
        {
          selectedTab?.title === sidePanelTabs.HISTORY.title
            ? this.renderHistoryContainer(versions)
            : <div><RelatedPanelNotice isApproved={this.isApproved()} />{this.renderSidePanelWidget()}</div>
        }
      </Fragment>
    ) : this.renderHistoryContainer(versions);
  }

  getVersionId() {
    return this.props.versionId ?? this.state.versionId ?? this.state.currentDiffingVersion?.versionId;
  }

  renderHistoryContainer(versions) {
    return <HistoryContainer parent={this}
                             isLoading={this.isLoading()}
                             versions={versions}
                             baseTypeName={this.baseTypeName}
                             className="history-title-left-margin"
                             editorType={this.getEditorType()}
                             clonedFrom={this.state.clonedFrom}
                             fromLibrary={this.state.fromLibrary}
                             isBulkAdd={this.state.isBulkAdd}
                             showMajorVersionsOnly={this.state.showMajorVersionsOnly}
    />;
  }

  getApprovalContainerType() {
    let ApprovalContainerType;

    switch (this.getEditorType()) {
      case EDITOR_TYPES.FULL_SCREEN:
        ApprovalContainerType = ApprovalContainer;
        break;
      case EDITOR_TYPES.STATIC_PANEL:
      case EDITOR_TYPES.STATIC_PANEL_FULL_WIDTH:
        ApprovalContainerType = StaticApprovalContainer;
        break;
      default:
        ApprovalContainerType = null;
    }
    return ApprovalContainerType;
  }

  handleBeforeSaveButtonClick(callback) {
    callback();
  }

  handleAfterSaveButtonClick() {
    // Implement in derived classes to provide customized functionality right after the save handler completes
  }

  handleBeforeProposeButtonClick(callback) {
    callback();
  }

  handleAfterProposeButtonClick() {
    // Implement in derived classes to provide customized functionality right after the proposed handler completes
  }

  isQuickPanel() {
    return this.getEditorType() === EDITOR_TYPES.QUICK_PANEL;
  }

  renderInternalLayout() {
    const interactionName = "BaseAprovableEditorPage :: renderInternalLayout";
    measurePerformanceStart(interactionName);

    let versions = this.getVersions();

    const isQuickPanel = this.isQuickPanel();
    const ApprovalContainerType = this.getApprovalContainerType();

    const isFullScreen = this.getEditorType() === EDITOR_TYPES.FULL_SCREEN;
    const shouldShowHistory = this.props.quickPanelConfig && this.props.quickPanelConfig.showHistory === false;
    const isHistoryShowing = this.state.isHistoryShowing === false;

    let internalLayout;

    if (this.isEdit() || this.isAdd() || (isFullScreen && this.isApproved() && !this.showSidebarOnApproved()) || shouldShowHistory || isHistoryShowing) {
      internalLayout = (
        <div ref={this.attributesContainer}>
          {ApprovalContainerType ? (
            <ApprovalContainerType parent={this}
                                   parentId={this.state.id}
                                   versions={versions}
                                   versionId={this.getVersionId()}
                                   location="Top"
                                   projectId={this.getProjectId() || this.projectId}
                                   processId={this.getProcessId(true)}
                                   isLoading={this.isLoading()}
                                   objectCurrentState={this.getCurrentState()}
                                   dataModified={this.state.dataModified}
                                   onCheckPermissions={this.handleCheckPermissions}
                                   onBeforeSaveButtonClick={this.handleBeforeSaveButtonClick}
                                   onAfterSaveButtonClick={this.handleAfterSaveButtonClick}
                                   onBeforeProposeButtonClick={this.handleBeforeProposeButtonClick}
                                   onAfterProposeButtonClick={this.handleAfterProposeButtonClick}
                                   eventListeners={this.props.eventListeners}
                                   additionalButtons={this.getAdditionalButtons()}
                                   additionalPopups={this.getAdditionalPopups()}
                                   onLoadNewVersion={this.loadNewVersion}
                                   currentDiffingVersion={this.state.currentDiffingVersion}
                                   isInProjectContext={this.state.isInProjectContext}
                                   isApproved={this.isApproved()}

            />
          ) : ""}
          {this.props.showAlert /* Set this to include an alert at the top of the page. */}
          {this.renderAttributes()}
          {ApprovalContainerType && (this.isEdit() || this.isAdd()) ? (
            <ApprovalContainerType parent={this}
                                   parentId={this.state.id}
                                   versions={versions}
                                   location="Bottom"
                                   projectId={this.getProjectId() || this.projectId}
                                   processId={this.getProcessId(true)}
                                   versionId={this.getVersionId()}
                                   isLoading={this.isLoading()}
                                   objectCurrentState={this.getCurrentState()}
                                   className="approval-container-bottom"
                                   dataModified={this.state.dataModified}
                                   onBeforeSaveButtonClick={this.handleBeforeSaveButtonClick}
                                   onAfterSaveButtonClick={this.handleAfterSaveButtonClick}
                                   onBeforeProposeButtonClick={this.handleBeforeProposeButtonClick}
                                   onAfterProposeButtonClick={this.handleAfterProposeButtonClick}
                                   onCheckPermissions={this.handleCheckPermissions}
                                   eventListeners={this.props.eventListeners}
                                   additionalButtons={this.getAdditionalButtons()}
                                   additionalPopups={this.getAdditionalPopups()}
                                   onLoadNewVersion={this.loadNewVersion}
                                   currentDiffingVersion={this.state.currentDiffingVersion}
                                   isInProjectContext={this.state.isInProjectContext}
                                   isApproved={this.isApproved()}
            />
          ) : ""}
        </div>
      );
    } else {
      internalLayout = (
        <div className="row no-gutters">
          <div className="col-12">
            {ApprovalContainerType ? (
              <ApprovalContainerType parent={this}
                                     parentId={this.state.id}
                                     versions={versions}
                                     versionId={this.getVersionId()}
                                     location="Top"
                                     projectId={this.getProjectId() || this.projectId}
                                     processId={this.getProcessId(true)}
                                     isLoading={this.isLoading()}
                                     objectCurrentState={this.getCurrentState()}
                                     dataModified={this.state.dataModified}
                                     onBeforeSaveButtonClick={this.handleBeforeSaveButtonClick}
                                     onAfterSaveButtonClick={this.handleAfterSaveButtonClick}
                                     onBeforeProposeButtonClick={this.handleBeforeProposeButtonClick}
                                     onAfterProposeButtonClick={this.handleAfterProposeButtonClick}
                                     onCheckPermissions={this.handleCheckPermissions}
                                     eventListeners={this.props.eventListeners}
                                     additionalButtons={this.getAdditionalButtons()}
                                     additionalPopups={this.getAdditionalPopups()}
                                     onLoadNewVersion={this.loadNewVersion}
                                     currentDiffingVersion={this.state.currentDiffingVersion}
                                     isInProjectContext={this.state.isInProjectContext}
                                     isApproved={this.isApproved()}
              />
            ) : ""}
            {this.props.showAlert /* Set this to include an alert at the top of the page. */}
            <div className="row">
              <div className="col-sm-8">
                <div className={"row " +
                  (isQuickPanel ? "base-approval-editor-top-margin" : "")}
                >
                  <div className="col-sm-12" ref={this.attributesContainer}>
                    {this.renderAttributes()}
                  </div>
                </div>
              </div>
              <div className={"col-sm-4" +
                (isQuickPanel ? " history-container-top-margin" : "")}
              >
                <div className="history-container row-white shadow">
                  {this.renderSidePanel(versions)}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    measurePerformanceEnd(interactionName);

    return internalLayout;
  }

  // eslint-disable-next-line no-unused-vars
  handleAddRecordFromSideMenu(field) {
  }

  renderSideMenu() {
    const {showSideMenu} = this.state;
    if (!showSideMenu) {
      return null;
    }

    let record = {...this.state};
    let className = `col-3 ${style["side-menu-container"]}`;
    if (this.getEditorType() === EDITOR_TYPES.STATIC_PANEL) {
      className = `col-4 pl-0 pr-0 h-100 overflow-auto ${style["side-menu-static-panel-container"]}`;
      // We don't include the parentRecord in the risk link when loading them in process explorer
      // since it is too much data and browser cannot handle it. We need to manually get them from
      // the typeahead cache
      RiskUtils.RISK_MODELS.filter(
        (riskModel) =>
          riskModel.isRiskLink && riskModel.from === this.state.modelName,
      ).forEach((riskModel) => {
        const riskLinks = record[`${riskModel.from}To${riskModel.to}s`] || [];
        const typeCode = UIUtils.getTypeCodeForModelName(riskModel.to);
        const results = this.memoryCacheForMultipleTypeaheads.get(this.getInMemoryCacheKey(typeCode)) || [];
        for (const riskLink of riskLinks) {
          riskLink.targetRecord = results.find(result => result.id === riskLink[`${riskModel.to}Id`]);
        }
      });
    }

    return (
      <div className={className}>
        <SideMenu
          record={this.state}
          project={getProjectFromCache(this.projectId)}
          rmp={this.getEffectiveRMPByModelName(this.capitalizedBaseTypeName)}
          onAddRecord={this.handleAddRecordFromSideMenu}
        />
      </div>
    );
  }

  render() {
    const {showSideMenu} = this.state;
    const interactionName = "BaseAprovableEditorPage :: render";
    measurePerformanceStart(interactionName);

    let selectedEditorTab = this.getSelectedEditorTab();

    if (this.isDiffingVersions()) {
      const currentDiffingVersion = this.getCurrentDiffingVersion();
      this.setExtraPrintItems([{
        order: 1,
        label: "Version",
        data: currentDiffingVersion.majorVersion + "." + currentDiffingVersion.minorVersion,
      }]);
    }

    let editorBodyContainerClass = "editor-body-container";
    let containerClass = "container";
    let titleBarClass = "";
    let internalLayoutClass = "";
    let editorClass = this.isView()
      ? "editor-view"
      : (this.isEdit() ? "editor-edit" : "editor-add");

    if (selectedEditorTab) {
      if (selectedEditorTab.fullWidth) {
        containerClass = "container-fluid p-0 m-0";
        titleBarClass = "container";
      }

      if (selectedEditorTab.fullHeight) {
        editorClass += " mb-0";
      }
    }

    if (showSideMenu) {
      if (this.getEditorType() === EDITOR_TYPES.STATIC_PANEL) {
        editorBodyContainerClass = `${editorBodyContainerClass} col-8 pr-3 h-100 overflow-auto`;
      } else {
        editorBodyContainerClass = `${editorBodyContainerClass} p-0`;
        editorClass = `${editorClass} row`;
        internalLayoutClass = "col-9 pl-5 pr-5";
        containerClass = `p-0 m-0 ${style["side-menu-visible"]}`;
        titleBarClass = "pl-5 pr-5";
      }
    }

    const section = (
      <div id="bodyDiv"
           className={editorBodyContainerClass}
           ref={this.bodyDiv}
      >
        {this.shouldShowCompany() ? (
          <div>
            <TrialBar />
            {this.renderCompanyHeader()}
          </div>
        ) : ""}
        {this.renderPageTitleBar()}
        {this.shouldShowNav() ? (
          <NavBar selected={this.getTabName()}
                  projectId={this.getProjectId()}
          />
        ) : ""}
        <div className={containerClass}>
          <form
            data-toggle="validator"
            role="form"
            id="baseEditorForm"
            onSubmit={UIUtils.ignoreHandler}
          >
            {
              selectedEditorTab && selectedEditorTab.fullWidth ? (
                <div className={titleBarClass}>
                  {this.renderTitleBar()}
                </div>
              ) : (
                this.renderTitleBar()
              )
            }
            <div className="container">
              {this.getErrorBar()}
            </div>
            <div className={editorClass}>
              <div className={internalLayoutClass}>
                {this.renderInternalLayout()}
              </div>
              {this.getEditorType() !== EDITOR_TYPES.STATIC_PANEL && this.renderSideMenu()}
            </div>
          </form>
        </div>
        {this.shouldShowCookies() ? (
          <CookiesBar />
        ) : ""}
        <TermsPopup modalRef={termsPopup => this.termsPopup = termsPopup}
                    onApproveButtonCLick={this.handleTermsPopupApproveButtonClick}
        />
      </div>
    );

    if (this.getEditorType() === EDITOR_TYPES.STATIC_PANEL) {
      return (
        <div className={`row flex-row h-100 ${showSideMenu ? "overflow-hidden" : ""}`}>
          {section}
          {this.renderSideMenu()}
        </div>
      );
    }

    measurePerformanceEnd(interactionName);

    return section;
  }

  getAdditionalButtons() {
    return [];
  }

  getAdditionalPopups() {
    return [];
  }

  renderSidePanelWidget() {
    return "";
  }

  showSidebarOnApproved() {
    return true;
  }
}
