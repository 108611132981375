var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as PropTypes from 'prop-types';
import { WindowTitleBar } from './WindowTitlebar';
import { dispatchEvent, Keys, classNames, guid, ZIndexContext, canUseDOM } from '@progress/kendo-react-common';
import { Draggable, createPropsContext, withPropsContext } from '@progress/kendo-react-common';
import { ResizeHandlers } from './WindowResizeHandlers';
import { MiddleLayerOptimization } from './MiddleLayerOptimization';
import { windowStage } from './StageEnum';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from './package-metadata';
import { WindowActionsBar } from './WindowActionsBar';
import { DATA_DIALOGS_ID, DEFAULT_DIALOGS_ZINDEX, ZINDEX_DIALOGS_STEP } from './constants';
import { getMaxZIndex } from './utils';
var DEFAULT_WIDTH = 300;
var DEFAULT_HEIGHT = 300;
var DEFAULT_MIN_WIDTH = 120;
var DEFAULT_MIN_HEIGHT = 100;
var DEFAULT_STEP = 5;
/** @hidden */
var WindowWithoutContext = /** @class */ (function (_super) {
    __extends(WindowWithoutContext, _super);
    function WindowWithoutContext(props) {
        var _this = _super.call(this, props) || this;
        /**
         * @hidden
         */
        _this.context = 0;
        _this.draggable = null;
        _this.offSetCoordinates = { x: 0, y: 0 };
        _this._id = guid();
        _this.titleId = _this.generateTitleId();
        /**
         * @hidden
         */
        _this.onKeyDown = function (event) {
            if (event.target !== event.currentTarget) {
                return;
            }
            var minWidth = _this.props.minWidth || DEFAULT_MIN_WIDTH;
            var minHeight = _this.props.minHeight || DEFAULT_MIN_HEIGHT;
            if (event.ctrlKey && _this.props.resizable) {
                switch (event.keyCode) {
                    case Keys.up:
                        event.preventDefault();
                        if (minHeight <= (_this.height - DEFAULT_STEP)) {
                            _this.setState({ height: _this.height - DEFAULT_STEP });
                        }
                        break;
                    case Keys.down:
                        event.preventDefault();
                        _this.setState({ height: _this.height + DEFAULT_STEP });
                        break;
                    case Keys.left:
                        if (minWidth <= (_this.width - DEFAULT_STEP)) {
                            _this.setState({ width: _this.width - DEFAULT_STEP });
                        }
                        break;
                    case Keys.right:
                        _this.setState({ width: _this.width + DEFAULT_STEP });
                        break;
                    default: return;
                }
                _this.dispatchMoveEvent(_this.props.onResize, event, false, undefined);
                return;
            }
            if (event.altKey) {
                switch (event.keyCode) {
                    case Keys.up:
                        if (_this.windowStage === windowStage.MINIMIZED) {
                            _this.handleRestore(event);
                            dispatchEvent(_this.props.onStageChange, event, _this, { state: windowStage.DEFAULT });
                        }
                        else if (_this.windowStage === windowStage.DEFAULT) {
                            _this.handleFullscreen(event);
                            dispatchEvent(_this.props.onStageChange, event, _this, { state: windowStage.FULLSCREEN });
                        }
                        break;
                    case Keys.down:
                        if (_this.windowStage === windowStage.FULLSCREEN) {
                            _this.handleRestore(event);
                            dispatchEvent(_this.props.onStageChange, event, _this, { state: windowStage.DEFAULT });
                        }
                        else if (_this.windowStage === windowStage.DEFAULT) {
                            _this.handleMinimize(event);
                            dispatchEvent(_this.props.onStageChange, event, _this, { state: windowStage.MINIMIZED });
                        }
                        break;
                    default:
                }
                return;
            }
            if (!event.ctrlKey) {
                switch (event.keyCode) {
                    case Keys.esc:
                        if (_this.props.onClose) {
                            _this.handleCloseWindow(event);
                        }
                        return;
                    case Keys.up:
                        _this.setState({ top: _this.state.top - DEFAULT_STEP });
                        break;
                    case Keys.down:
                        _this.setState({ top: _this.state.top + DEFAULT_STEP });
                        break;
                    case Keys.left:
                        _this.setState({ left: _this.state.left - DEFAULT_STEP });
                        break;
                    case Keys.right:
                        _this.setState({ left: _this.state.left + DEFAULT_STEP });
                        break;
                    default: return;
                }
            }
            _this.dispatchMoveEvent(_this.props.onMove, event, false, undefined);
        };
        /**
         * @hidden
         */
        _this.onPress = function (data) {
            var e = data.event;
            _this.windowCoordinatesState.differenceLeft = e.pageX - _this.left;
            _this.windowCoordinatesState.differenceTop = e.pageY - _this.top;
        };
        /**
         * @hidden
         */
        _this.onDrag = function (data) {
            var e = data.event;
            e.originalEvent.preventDefault();
            if (_this.windowStage !== windowStage.FULLSCREEN && _this.props.draggable) {
                _this.setState({
                    top: Math.max(e.pageY - _this.windowCoordinatesState.differenceTop, 0),
                    left: e.pageX - _this.windowCoordinatesState.differenceLeft,
                    isDragging: true
                });
                if (_this.props.onMove) {
                    // eslint-disable-next-line max-len
                    _this.dispatchMoveEvent(_this.props.onMove, e, true, false);
                }
            }
        };
        /**
         * @hidden
         */
        _this.onRelease = function (data) {
            var e = data.event;
            if (_this.windowStage !== windowStage.FULLSCREEN && _this.props.draggable) {
                if (_this.props.onMove) {
                    _this.dispatchMoveEvent(_this.props.onMove, e, true, true);
                }
            }
            _this.setState({
                isDragging: false
            });
        };
        /**
         * @hidden
         */
        _this.onFocus = function () {
            if (_this._blurTimeout) {
                clearTimeout(_this._blurTimeout);
                _this._blurTimeout = undefined;
            }
            else {
                _this.setState({
                    focused: true,
                    zIndex: getMaxZIndex(_this.getCurrentZIndex(), _this.getDocument(), _this._id)
                });
            }
        };
        /**
         * @hidden
         */
        _this.onBlur = function () {
            clearTimeout(_this._blurTimeout);
            var currentWindow = _this.getWindow();
            if (currentWindow) {
                _this._blurTimeout = currentWindow.setTimeout(function () {
                    _this.setState({ focused: false });
                    _this._blurTimeout = undefined;
                });
            }
        };
        // Get initial coordinates of the Window
        _this.getInitialTop = function () {
            if (_this.props.top !== undefined) {
                return _this.props.top;
            }
            if (_this.props.initialTop !== undefined) {
                return _this.props.initialTop;
            }
            var height = DEFAULT_HEIGHT;
            if (_this.props.height !== undefined) {
                height = _this.props.height;
            }
            else if (_this.props.initialHeight !== undefined) {
                height = _this.props.initialHeight;
            }
            if (_this.props.appendTo) {
                return (_this.props.appendTo.offsetHeight / 2) - (height / 2);
            }
            var currentWindow = _this.getWindow();
            return currentWindow ? (currentWindow.innerHeight / 2) - (height / 2) : 0;
        };
        _this.getInitialLeft = function () {
            if (_this.props.left !== undefined) {
                return _this.props.left;
            }
            if (_this.props.initialLeft !== undefined) {
                return _this.props.initialLeft;
            }
            var width = DEFAULT_WIDTH;
            if (_this.props.width !== undefined) {
                width = _this.props.width;
            }
            else if (_this.props.initialWidth !== undefined) {
                width = _this.props.initialWidth;
            }
            if (_this.props.appendTo) {
                return (_this.props.appendTo.offsetWidth / 2) - (width / 2);
            }
            var currentWindow = _this.getWindow();
            return currentWindow ? (currentWindow.innerWidth / 2) - (width / 2) : 0;
        };
        _this.getInitialWidth = function () {
            var width = DEFAULT_WIDTH;
            if (_this.props.width !== undefined) {
                width = _this.props.width;
            }
            else if (_this.props.initialWidth !== undefined) {
                width = _this.props.initialWidth;
            }
            return width;
        };
        _this.getInitialHeight = function () {
            var height = DEFAULT_HEIGHT;
            if (_this.props.height !== undefined) {
                height = _this.props.height;
            }
            else if (_this.props.initialHeight !== undefined) {
                height = _this.props.initialHeight;
            }
            return height;
        };
        // Handle actions
        _this.handleMinimize = function (event) {
            event.preventDefault();
            _this.windowCoordinatesState.leftBeforeAction = _this.left;
            _this.windowCoordinatesState.topBeforeAction = _this.top;
            _this.windowCoordinatesState.widthBeforeAction = _this.width;
            _this.windowCoordinatesState.heightBeforeAction = _this.height;
            _this.setState({
                stage: windowStage.MINIMIZED,
                height: 0
            });
            dispatchEvent(_this.props.onStageChange, event, _this, { state: windowStage.MINIMIZED });
        };
        _this.handleFullscreen = function (event) {
            event.preventDefault();
            _this.windowCoordinatesState.leftBeforeAction = _this.left;
            _this.windowCoordinatesState.topBeforeAction = _this.top;
            _this.windowCoordinatesState.widthBeforeAction = _this.width;
            _this.windowCoordinatesState.heightBeforeAction = _this.height;
            var currentWindow = _this.getWindow();
            var innerWidth = currentWindow ? currentWindow.innerWidth : 0;
            var innerHeight = currentWindow ? currentWindow.innerHeight : 0;
            _this.setState({
                left: 0,
                top: 0,
                width: _this.props.appendTo ? _this.props.appendTo.offsetWidth : innerWidth,
                height: _this.props.appendTo ? _this.props.appendTo.offsetHeight : innerHeight,
                stage: windowStage.FULLSCREEN
            });
            dispatchEvent(_this.props.onStageChange, event, _this, { state: windowStage.FULLSCREEN });
        };
        _this.handleRestore = function (event) {
            event.preventDefault();
            if (_this.windowStage === windowStage.FULLSCREEN) {
                _this.setState({
                    stage: windowStage.DEFAULT,
                    left: _this.windowCoordinatesState.leftBeforeAction,
                    top: _this.windowCoordinatesState.topBeforeAction,
                    width: _this.windowCoordinatesState.widthBeforeAction,
                    height: _this.windowCoordinatesState.heightBeforeAction
                });
            }
            else if (_this.windowStage === windowStage.MINIMIZED) {
                _this.setState({
                    stage: windowStage.DEFAULT,
                    height: _this.windowCoordinatesState.heightBeforeAction
                });
            }
            dispatchEvent(_this.props.onStageChange, event, _this, { state: windowStage.DEFAULT });
        };
        _this.handleCloseWindow = function (event) {
            event.preventDefault();
            dispatchEvent(_this.props.onClose, event, _this, { state: undefined });
        };
        _this.handleDoubleClick = function (e) {
            if (_this.windowStage === windowStage.FULLSCREEN || _this.windowStage === windowStage.MINIMIZED) {
                _this.handleRestore(e);
            }
            else {
                _this.handleFullscreen(e);
            }
        };
        // Handle resizing
        _this.handleResize = function (event, props) {
            var x = _this.props.appendTo ? event.pageX - _this.offSetCoordinates.x : event.pageX;
            var y = _this.props.appendTo ? event.pageY - _this.offSetCoordinates.y : event.pageY;
            var currentWidth = _this.width;
            var currentHeight = _this.height;
            var minWidth = _this.props.minWidth || DEFAULT_MIN_WIDTH;
            var minHeight = _this.props.minHeight || DEFAULT_MIN_HEIGHT;
            var heightDifference = _this.top - y;
            var widthDifference = _this.left - x;
            var newWidth = x - _this.left;
            var newHeight = y - _this.top;
            var newState = Object.assign({}, _this.state, { isDragging: !props.end });
            if (props.direction.indexOf('n') >= 0 && minHeight - (currentHeight + heightDifference) < 0) {
                if (_this.top > 0) {
                    newState.height = currentHeight + heightDifference;
                }
                newState.top = y;
            }
            if (props.direction.indexOf('s') >= 0 && (minHeight - newHeight < 0)) {
                newState.height = newHeight;
            }
            if (props.direction.indexOf('w') >= 0 && minWidth - (currentWidth + widthDifference) < 0) {
                if (_this.left > 0) {
                    newState.width = currentWidth + widthDifference;
                }
                newState.left = x;
            }
            if (props.direction.indexOf('e') >= 0 && (minWidth - newWidth < 0)) {
                newState.width = newWidth;
            }
            _this.setState(newState);
            _this.dispatchMoveEvent(_this.props.onResize, event, true, props.end);
        };
        // Dispatcher for the resize and move events
        _this.dispatchMoveEvent = function (callback, event, drag, end) {
            if (!callback) {
                return;
            }
            callback.call(undefined, {
                nativeEvent: event.nativeEvent ? event.nativeEvent : event.originalEvent,
                drag: drag,
                end: end,
                target: _this,
                left: _this.state.left,
                top: _this.state.top,
                width: _this.state.width,
                hight: _this.state.height,
                height: _this.state.height
            });
        };
        _this.handleBrowserWindowResize = function () {
            if (_this.windowStage === windowStage.FULLSCREEN) {
                var currentWindow = _this.getWindow();
                var innerWidth_1 = currentWindow ? currentWindow.innerWidth : 0;
                var innerHeight_1 = currentWindow ? currentWindow.innerHeight : 0;
                _this.setState({
                    width: _this.props.appendTo ? _this.props.appendTo.offsetWidth : innerWidth_1,
                    height: _this.props.appendTo ? _this.props.appendTo.offsetHeight : innerHeight_1
                });
            }
        };
        _this.getCurrentZIndex = function () {
            if (!_this.state || _this.context === undefined) {
                return _this.context ? _this.context : DEFAULT_DIALOGS_ZINDEX;
            }
            return _this.state.zIndex > (_this.context ? _this.context + ZINDEX_DIALOGS_STEP : 0) ? _this.state.zIndex : _this.context + ZINDEX_DIALOGS_STEP;
        };
        _this.getDocument = function () {
            return _this.props.appendTo ? _this.props.appendTo.ownerDocument : document;
        };
        _this.getWindow = function () {
            var currentDocument = _this.getDocument();
            return currentDocument && currentDocument.defaultView;
        };
        validatePackage(packageMetadata);
        _this.state = {
            stage: _this.props.stage || windowStage.DEFAULT,
            isDragging: false,
            top: _this.getInitialTop(),
            left: _this.getInitialLeft(),
            width: _this.getInitialWidth(),
            height: _this.getInitialHeight(),
            focused: true,
            zIndex: getMaxZIndex(_this.getCurrentZIndex(), _this.getDocument(), _this._id)
        };
        _this.windowCoordinatesState = {
            leftBeforeAction: _this.getInitialLeft(),
            topBeforeAction: _this.getInitialTop(),
            widthBeforeAction: _this.getInitialWidth(),
            heightBeforeAction: _this.getInitialHeight()
        };
        return _this;
    }
    /**
     * @hidden
     */
    WindowWithoutContext.prototype.componentDidMount = function () {
        var currentWindow = this.getWindow();
        if (currentWindow) {
            currentWindow.addEventListener('resize', this.handleBrowserWindowResize);
        }
        var currentDocument = this.getDocument();
        if (this.props.appendTo && currentDocument) {
            var containerCoordinates = this.props.appendTo.getBoundingClientRect();
            var bodyRect = currentDocument.body.getBoundingClientRect();
            this.offSetCoordinates.x = containerCoordinates.left - bodyRect.left;
            this.offSetCoordinates.y = containerCoordinates.top - bodyRect.top;
        }
    };
    /**
     * @hidden
     */
    WindowWithoutContext.prototype.componentWillUnmount = function () {
        var currentWindow = this.getWindow();
        if (currentWindow) {
            currentWindow.removeEventListener('resize', this.handleBrowserWindowResize);
        }
    };
    /**
     * @hidden
     */
    WindowWithoutContext.prototype.componentDidUpdate = function () {
        var currentDocument = this.getDocument();
        if (this.props.appendTo && currentDocument) {
            var containerCoordinates = this.props.appendTo.getBoundingClientRect();
            var bodyRect = currentDocument.body.getBoundingClientRect();
            this.offSetCoordinates.x = containerCoordinates.left - bodyRect.left;
            this.offSetCoordinates.y = containerCoordinates.top - bodyRect.top;
        }
    };
    /**
     * @hidden
     */
    WindowWithoutContext.prototype.render = function () {
        var _a;
        var _this = this;
        var elementsArray = React.Children.toArray(this.props.children);
        var content = this.getContent(elementsArray);
        var actions = this.getActionBar(elementsArray);
        var currentZIndex = this.getCurrentZIndex();
        var classNamesWindow = classNames('k-widget', 'k-window', this.props.className, { 'k-window-minimized': this.state.stage === 'MINIMIZED' }, { 'k-window-focused': this.state.focused });
        var windowElement = (React.createElement(ZIndexContext.Provider, { value: currentZIndex },
            React.createElement(React.Fragment, null,
                this.props.modal && (React.createElement("div", { className: "k-overlay", style: __assign({ zIndex: currentZIndex }, this.props.overlayStyle) })),
                React.createElement("div", __assign({}, (_a = {}, _a[DATA_DIALOGS_ID] = this._id, _a), { tabIndex: 0, role: 'dialog', "aria-labelledby": this.titleId, onFocus: this.onFocus, onBlur: this.onBlur, onKeyDown: this.onKeyDown, ref: function (el) {
                        _this.windowElement = el;
                        _this.element = el;
                    }, className: classNamesWindow, style: __assign({ top: this.top, left: this.left, width: this.width, height: this.height || '', zIndex: currentZIndex }, this.props.style) }),
                    React.createElement(MiddleLayerOptimization, { shouldUpdateOnDrag: this.props.shouldUpdateOnDrag || false, isDragging: this.state.isDragging },
                        React.createElement(Draggable, { onPress: this.onPress, onDrag: this.onDrag, onRelease: this.onRelease, ref: function (component) { return _this.draggable = component; } },
                            React.createElement(WindowTitleBar, { stage: this.windowStage, onDoubleClick: this.props.doubleClickStageChange ? this.handleDoubleClick : undefined, onMinimizeButtonClick: this.handleMinimize, onFullScreenButtonClick: this.handleFullscreen, onRestoreButtonClick: this.handleRestore, onCloseButtonClick: this.handleCloseWindow, closeButton: this.props.closeButton, minimizeButton: this.props.minimizeButton, maximizeButton: this.props.maximizeButton, restoreButton: this.props.restoreButton, id: this.titleId }, this.props.title)),
                        this.windowStage !== windowStage.MINIMIZED
                            ? (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: "k-window-content" }, content),
                                actions))
                            : null,
                        this.windowStage === windowStage.DEFAULT &&
                            this.props.resizable
                            ? React.createElement(ResizeHandlers, { onResize: this.handleResize })
                            : null)))));
        if (canUseDOM) {
            return this.props.appendTo !== null ?
                ReactDOM.createPortal(windowElement, this.props.appendTo || document.body) :
                windowElement;
        }
        return null;
    };
    Object.defineProperty(WindowWithoutContext.prototype, "top", {
        // Getters
        get: function () {
            if (this.windowStage !== windowStage.FULLSCREEN) {
                return Math.max(this.props.top || this.state.top, 0);
            }
            return 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowWithoutContext.prototype, "left", {
        get: function () {
            if (this.windowStage !== windowStage.FULLSCREEN) {
                return Math.max(this.props.left || this.state.left, 0);
            }
            return 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowWithoutContext.prototype, "width", {
        get: function () {
            var width = this.props.width || this.state.width;
            if (this.windowStage === windowStage.FULLSCREEN) {
                if (this.props.appendTo) {
                    width = this.props.appendTo.offsetWidth;
                    return width;
                }
                var currentWindow = this.getWindow();
                width = currentWindow ? currentWindow.innerWidth : 0;
            }
            return width;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowWithoutContext.prototype, "height", {
        get: function () {
            var height = this.props.height || this.state.height;
            if (this.windowStage === windowStage.FULLSCREEN) {
                if (this.props.appendTo) {
                    height = this.props.appendTo.offsetHeight;
                    return height;
                }
                var currentWindow = this.getWindow();
                height = currentWindow ? currentWindow.innerHeight : 0;
            }
            else if (this.windowStage === windowStage.MINIMIZED) {
                height = 0;
            }
            return height;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(WindowWithoutContext.prototype, "windowStage", {
        get: function () {
            return this.props.stage || this.state.stage;
        },
        enumerable: false,
        configurable: true
    });
    WindowWithoutContext.prototype.getActionBar = function (children) {
        return children.filter(function (child) { return child && child.type === WindowActionsBar; });
    };
    WindowWithoutContext.prototype.getContent = function (children) {
        return children.filter(function (child) {
            return child && child.type !== WindowActionsBar;
        });
    };
    WindowWithoutContext.prototype.generateTitleId = function () {
        return 'window-title-' + this._id;
    };
    WindowWithoutContext.displayName = 'Window';
    /**
     * @hidden
     */
    WindowWithoutContext.propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        left: PropTypes.number,
        top: PropTypes.number,
        initialWidth: PropTypes.number,
        initialHeight: PropTypes.number,
        initialLeft: PropTypes.number,
        initialTop: PropTypes.number,
        minWidth: PropTypes.number,
        minHeight: PropTypes.number,
        resizable: PropTypes.bool,
        draggable: PropTypes.bool,
        title: PropTypes.any,
        shouldUpdateOnDrag: PropTypes.bool,
        stage: PropTypes.oneOf(['DEFAULT', 'MINIMIZED', 'FULLSCREEN']),
        className: PropTypes.string,
        style: PropTypes.object,
        overlayStyle: PropTypes.object
    };
    /**
     * @hidden
     */
    WindowWithoutContext.defaultProps = {
        minWidth: DEFAULT_MIN_WIDTH,
        minHeight: DEFAULT_MIN_HEIGHT,
        resizable: true,
        draggable: true,
        modal: false,
        doubleClickStageChange: true
    };
    /**
     * @hidden
     */
    WindowWithoutContext.contextType = ZIndexContext;
    return WindowWithoutContext;
}(React.Component));
/**
 * Represents the PropsContext of the `Window` component.
 * Used for global configuration of all `Window` instances.
 */
export var WindowPropsContext = createPropsContext();
/* eslint-disable @typescript-eslint/no-redeclare -- intentionally naming the component the same as the type */
/**
 * Represents the [KendoReact Window component]({% slug overview_window %}).
 *
 * Accepts properties of type [WindowProps]({% slug api_dialogs_windowprops %}).
 * Obtaining the `ref` returns an object of type [WindowHandle]({% slug api_dialogs_windowhandle %}).
 */
export var Window = withPropsContext(WindowPropsContext, WindowWithoutContext);
Window.displayName = 'KendoReactWindow';
