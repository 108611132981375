"use strict";

import React from "react";
import BaseReactComponent from "../../base_react_component";
import * as UIUtils from "../../ui_utils";
import { EXPERIMENTS } from "../../helpers/constants/constants";
import { faExclamationTriangle, faQuestion, faBinoculars, faBug, faHourglass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class DeveloperTools extends BaseReactComponent {
  getButtons() {
    return [
      {
        name: "About",
        title: "Developer Tools",
        color: "warning",
        onClick:
          () => alert(`QbDVision Developer Tools: ${UIUtils.getSoftwareVersion()}`),
        icon: faBug,
      },
      {
        name: "ThrowError",
        title: "Throw Error",
        color: "danger",
        onClick: () => this.causeError(),
        icon: faExclamationTriangle,
      },
      {
        name: "ThrowTimedErrors",
        title: "Throw Timed Errors",
        color: "danger",
        onClick: () => {
          if (!this.errorInterval) {
            this.errorInterval = setInterval(this.causeError, 5000);
            setImmediate(this.causeError);
          } else {
            clearInterval(this.errorInterval);
            this.errorInterval = null;
          }
        },
        icon: faHourglass,
      },
      {
        name: "MakeRequest",
        title: "Make request to nowhere",
        onClick: () => this.makeRequest("http://qbdvision.com/thispagedoesnotexist"),
        icon: faQuestion,
      },
      {
        name: "InstrumentRequests",
        title: "InstrumentRequests",
        color: localStorage.getItem("instrumentRequests") === "true" ? "success" : "secondary",
        onClick: () => {
          let isEnabled = localStorage.getItem("instrumentRequests") === "true";
          localStorage.setItem("instrumentRequests", isEnabled ? "false" : "true");
          window.location.reload();
        },
        icon: faBinoculars,
      },
    ];
  }

  causeError() {
    throw new Error(`Developer caused error: ${new Date()} - ${Date.now()}`);
  }

  makeRequest(url) {
    UIUtils.secureAjaxGET(url);
  }

  render() {
    const isEnabled = UIUtils.isExperimentEnabled(EXPERIMENTS.DeveloperTools);
    if (!isEnabled) {
      return "";
    }
    const style = {
      top: 120,
      left: 0,
      width: 36,
      maxWidth: 36,
      minWidth: 36,
    };

    return (
      <div className="devtools btn-group-vertical fixed-top" role="group" style={style}>
        {
          this.getButtons().map(button => (
            <button
              key={button.name}
              id={`devTools_${button.name}`}
              type="button"
              className={`btn btn-sm btn-${button.color || "primary"}`}
              onClick={button.onClick}
              title={button.title}
            >
              <FontAwesomeIcon icon={button.icon} />
            </button>
          ))
        }
      </div>
    );
  }
}