/* stylelint-disable-next-line import-notation */
@import "../../../../../../../css/brand_colors";


.header {
  color: $brand-black;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto, sans-serif;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 700;
}

.close-icon {
  cursor: pointer;
  color: #859399;
  text-align: center;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}