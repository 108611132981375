"use strict";

import React from "react";
import BaseReactComponent from "../../../base_react_component";

/**
 * This class is responsible for rendering the diff row header of the review screen.
 */
export default class TechTransferReviewHeader extends BaseReactComponent {
  constructor() {
    super();
    this.setStateSafely({hideUnchangedFields: false});
  }

  handleHideUnchangedFields(event) {
    const {onHideUnchangedFields} = this.props;
    const checked = event.target.checked;

    this.setStateSafely({
      hideUnchangedFields: checked
    }, onHideUnchangedFields(checked));
  }

  render() {
    const {name, receivingSite, sendingSite} = this.props;
    const {hideUnchangedFields} = this.state;

    return (
      <div className="row">
        <div className="col-4 tech-transfer-review-header">
          <label id="hideUnchangedFieldsInput">
            <input type="checkbox"
                   defaultValue={hideUnchangedFields}
                   onChange={this.handleHideUnchangedFields}
                   id="hideUnchangedFieldsFilter"
            /> Hide unchanged fields
          </label>
        </div>
        <div className="col-4 tech-transfer-review-header">
          {receivingSite[name]}
        </div>
        <div className="col-4 tech-transfer-review-header">
          {sendingSite[name]}
        </div>
      </div>
    );
  }
}
