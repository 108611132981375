/* eslint-disable max-len */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { updateItem, resolveItemsIds } from './utils/itemUtils';
import { EXPAND_FIELD, SELECT_FIELD, CHECK_FIELD, CHECK_INDETERMINATE_FIELD, CHILDREN_FIELD } from './utils/consts';
import { getNestedValue, isArray } from './utils/misc';
/**
 * A helper function which applies the specified operation descriptors to the data.
 * * [Expanding and collapsing items]({% slug expansion_ways_treeview %}#toc-using-a-helper-function)
 * * [Selecting and deselecting items]({% slug selection_ways_treeview %}#toc-using-a-helper-function)
 * * [Checking and unchecking items]({% slug check_helper_funcs_treeview %})
 *
 * @param data - The data that will be processed.
 * @param operations - The operation descriptors that will be applied to the data.
 * @returns - The processed copy of the input data.
 *
 * @example
 * ```jsx
 * class App extends React.Component {
 *     state = { items: tree, expand: [], select: [], check: [] };
 *     render() {
 *         const { expand, select, check } = this.state;
 *         return (
 *             <TreeView
 *                 data={processTreeViewItems(this.state.items, { expand, select, check })}
 *                 expandIcons={true} onExpandChange={this.onExpandChange} checkboxes={true}
 *                 onCheckChange={event => this.setState({ check: [ event.itemHierarchicalIndex ] })}
 *                 onItemClick={event => this.setState({ select: [ event.itemHierarchicalIndex ] })}
 *             />
 *         );
 *     }
 *     onExpandChange = (event) => {
 *         let expand = this.state.expand.slice();
 *         const index = expand.indexOf(event.itemHierarchicalIndex);
 *         index === -1 ? expand.push(event.itemHierarchicalIndex) : expand.splice(index, 1);
 *         this.setState({ expand });
 *     }
 * }
 *
 * const tree = [{
 *     text: 'Item1',
 *     items: [
 *         { text: 'Item1.1' },
 *         { text: 'Item1.2' },
 *         { text: 'Item1.3', items: [{ text: 'Item1.3.1' }] }]
 * }, {
 *     text: 'Item2', disabled: true,
 *     items: [{ text: 'Item2.1' }, { text: 'Item2.2' }, { text: 'Item2.3' }]
 * }, {
 *     text: 'Item3'
 * }];
 *
 * ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export function processTreeViewItems(data, operations) {
    if (!data || !data.length) {
        return [];
    }
    var result = data;
    var cloneField = operations.cloneField || 'cloned';
    var expandField = operations.expandField || EXPAND_FIELD;
    var selectField = operations.selectField || SELECT_FIELD;
    var checkField = operations.checkField || CHECK_FIELD;
    var childrenField = operations.childrenField || CHILDREN_FIELD;
    result = handleOperation(result, expandField, operations.expand, cloneField, childrenField);
    result = handleOperation(result, selectField, operations.select, cloneField, childrenField);
    result = handleOperation(result, checkField, operations.check, cloneField, childrenField);
    applyCheckIndeterminate(result, childrenField, operations.check);
    return result;
}
function handleOperation(items, defaultField, operation, cloneField, childrenField) {
    if (operation) {
        var _a = parseOperation(operation, defaultField), ids = _a.ids, field = _a.field;
        var indices = !isArray(operation) && operation.idField ? resolveItemsIds(ids, operation.idField, items, childrenField) : ids;
        return raiseFlags(items, indices, field, cloneField, childrenField);
    }
    else {
        return items;
    }
}
function parseOperation(operation, defaultField) {
    var ids;
    var field;
    if (isArray(operation)) {
        ids = operation;
        field = defaultField;
    }
    else {
        ids = operation.ids || [];
        field = operation.operationField || defaultField;
    }
    return { ids: ids, field: field };
}
function raiseFlags(items, indices, field, cloneField, childrenField) {
    var result = items;
    indices.forEach(function (itemIndex) {
        result = updateItem(result, itemIndex, function (item) { return raiseNestedFlag(field, item); }, cloneField, childrenField);
    });
    return result;
}
function raiseNestedFlag(fieldName, dataItem) {
    var fields = (fieldName || '').split('.');
    var item = dataItem;
    for (var index = 0; index < fields.length; index++) {
        var field = fields[index];
        if (index === fields.length - 1) {
            item[field] = true;
        }
        else if (item[field] !== undefined) {
            item[field] = __assign({}, item[field]);
            item = item[field];
        }
        else {
            return;
        }
    }
}
function applyCheckIndeterminate(items, childrenField, check) {
    if (check && !isArray(check) && check.applyCheckIndeterminate) {
        var checkField = parseOperation(check, CHECK_FIELD).field;
        var checkIndeterminateField = check.checkIndeterminateField || CHECK_INDETERMINATE_FIELD;
        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            var subItems = item[childrenField];
            if (subItems) {
                applyCheckIndeterminateHelper(subItems, getNestedValue(checkField, item) ? [] : [item], childrenField, checkField, checkIndeterminateField);
            }
        }
    }
}
function applyCheckIndeterminateHelper(data, parents, childrenField, checkField, checkIndeterminateField) {
    // The updates will be direct because the corresponding items
    // are already cloned because of their checked children.
    var parentsAlreadyUpdated = false;
    for (var i = 0; i < data.length; i++) {
        var item = data[i];
        if (getNestedValue(checkField, item)) {
            if (!parentsAlreadyUpdated) {
                for (var j = 0; j < parents.length; j++) {
                    raiseNestedFlag(checkIndeterminateField, parents[j]);
                }
            }
            parentsAlreadyUpdated = true;
            if (item[childrenField]) {
                applyCheckIndeterminateHelper(item[childrenField], [], childrenField, checkField, checkIndeterminateField);
            }
        }
        else {
            if (item[childrenField]) {
                applyCheckIndeterminateHelper(item[childrenField], parentsAlreadyUpdated ? [item] : parents.concat([item]), childrenField, checkField, checkIndeterminateField);
            }
        }
    }
}
