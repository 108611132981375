var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useDir } from '@progress/kendo-react-common';
/**
 * @hidden
 */
export var SLIDER_LABEL_ATTRIBUTE = 'data-slider-label';
/**
 * Represents the KendoReact SliderLabel component.
 */
export var SliderLabel = function (props) {
    var _a, _b;
    var sliderLabelRef = React.useRef(null);
    var dir = useDir(sliderLabelRef);
    var style = props.vertical
        ? { bottom: "".concat(props.position, "%") }
        : (_a = {}, _a[dir === 'rtl' ? 'right' : 'left'] = "".concat(props.position, "%"), _a);
    var tickStyle = props.vertical ? 'k-tick-vertical' : 'k-tick-horizontal';
    return (React.createElement("li", { ref: sliderLabelRef, className: "k-tick k-tick-large ".concat(tickStyle), title: props.title, style: __assign({ zIndex: 1, position: 'absolute' }, style) }, props.children && React.createElement("span", __assign({}, (_b = {}, _b[SLIDER_LABEL_ATTRIBUTE] = true, _b), { className: "k-label", onClick: props.onClick }), props.children)));
};
