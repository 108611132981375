"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import { getURLByTypeNameAndId } from "../../helpers/url_helper";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { Trans } from "react-i18next";
import BaseReactComponent from "../../base_react_component";
import { formatDate } from "../../configurableTables/fieldsConfig/fields_formatter";
import { QBDLink } from "../../utils/withRouter";
import { RouterContext } from "../../utils/router_context";

/**
 * This class handle project summary information
 */
// i18next-extract-mark-ns-start homePage
class ProjectInfoCard extends BaseReactComponent {
  static contextType = RouterContext;

  constructor(props) {
    super(props);
    this.handleProjectCardClick = this.handleProjectCardClick.bind(this);
  }

  handleProjectCardClick(event) {
    event.stopPropagation();

    let {object, isEmpty} = this.props;
    if (!isEmpty) {
      this.context.navigate(UIUtils.getSecuredURL(`/projects/dashboard.html?projectId=${object.id}`));
    }
  }

  render() {
    let {object, className, isEmpty, cannotAddProjects, t} = this.props;
    let {id, name, activity, stats} = object || {};

    return (
      <div onClick={this.handleProjectCardClick}
           className={"home-page-project-info-card shadow " + (className || "") + (this.isLoading() ? " containsSkeleton" : "")}
      >
        {isEmpty ?
          <div className={"home-page-empty-data" + this.getClassForLoading()}>
            {cannotAddProjects ? // Don't try to simplify this without making sure that the translation isn't screwed up.
              <Trans t={t}>
                No projects created yet. <span>Add a project</span> and get started today!
              </Trans>
              :
              <Trans t={t}>
                No projects created yet. <QBDLink to="/projects/viewEdit.html?operation=Add">Add a project</QBDLink> and get started today!
              </Trans>
            }
          </div>
          :
          <Fragment>
            <div className="col-5">
              <div className="home-page-project-info-card-left-title">
                <QBDLink to={`/projects/dashboard.html?projectId=${object.id}`}>
                  {UIUtils.getRecordLabelForDisplay("PRJ", id, name)}
                </QBDLink>
              </div>
              {activity && activity.user ?
                <div className="home-page-project-info-card-left-details">
                  <a href={getURLByTypeNameAndId("user", "View", activity.user.id)}>
                    {activity.user.name}
                  </a>&nbsp;{formatDate(activity.date, UIUtils.LONG_DATE_FORMAT_FOR_DISPLAY)}
                </div> : ""}
            </div>
            <div className="home-page-project-info-card-vertical-separator" />
            <div className="col-7">
              <div className="row">
                {
                  stats.map((stat, index) => {
                    return (
                      <div key={index} className="col-4">
                        <div className="home-page-project-info-card-right-title">
                          {stat.count}
                        </div>
                        <div className="home-page-project-info-card-right-details">
                          {stat.title}
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </Fragment>
        }
      </div>
    );
  }
}

export default I18NWrapper.wrap(ProjectInfoCard, "homePage");
// i18next-extract-mark-ns-stop homePage
