"use strict";

import * as UIUtils from "../../../ui_utils";
import { computeDefaultView } from "./risk_map_layout_helper";

/**
 * This class encapsulates a report layout for the risk map. It's used to autosave the layout so the next time the user
 * opens the Risk Map their previous layout is there. It's also used for saving & loading report layouts.
 */
export default class RiskMapReportLayout {
  constructor(id, projectId, processId, type, layout, name, description) {
    if (id) {
      this.id = id;
    }

    if (projectId) {
      this.project = {
        id: projectId
      };
      this.ProjectId = projectId;
    }

    if (processId) {
      this.ProcessId = processId;
    }

    if (typeof layout === "string") {
      this.layout = JSON.parse(layout);
      this.isInitialized = true;
    } else if (typeof layout === "undefined" || layout === null) {
      this.layout = {
        fqa: {},
        fpa: {},
        generalAttribute: {},
        tpp: {}
      };
      this.isInitialized = false;
    } else {
      this.layout = Object.assign({}, layout);
      this.isInitialized = true;
    }

    this.name = name;
    this.type = type;
    this.description = description;
  }

  /**
   * This takes a saved layout, possibly one that was saved against a different process, and adapts it so that the
   * nodes in the current network & nodesDataset will still appear in the right place (the default, if not in the saved
   * layout).
   *
   * @param network The vis.js network.
   * @param nodesDataset The nodes dataset with which the network was initialized.
   * @param filters The filters object
   */
  adaptLayoutForNewNodes(network, nodesDataset, filters) {
    const defaultNodeLocations = computeDefaultView(network, nodesDataset, filters);

    for (const node of defaultNodeLocations) {
      const nodeObject = window.nodesToObjects[node.id];
      let layoutForType = this.layout[nodeObject.type];
      if (!layoutForType) {
        layoutForType = {};
        this.layout[nodeObject.type] = layoutForType;
      }

      if (!layoutForType[nodeObject.id]) {
        layoutForType[nodeObject.id] = {x: node.x, y: node.y};
      }
    }
  }


  static deepClone(riskMapReportLayout) {
    let riskMapReportLayoutObj = typeof riskMapReportLayout === "string" ? JSON.parse(riskMapReportLayout) : riskMapReportLayout;
    let layoutObject = typeof riskMapReportLayoutObj.layout === "string" ? JSON.parse(riskMapReportLayoutObj.layout) : riskMapReportLayoutObj.layout;
    return new RiskMapReportLayout(riskMapReportLayout.id ? riskMapReportLayout.id : null,
      riskMapReportLayoutObj.ProjectId ? riskMapReportLayoutObj.ProjectId : null,
      riskMapReportLayoutObj.ProcessId ? riskMapReportLayoutObj.ProcessId : null,
      riskMapReportLayoutObj.type,
      UIUtils.deepClone(layoutObject),
      riskMapReportLayoutObj.name ? riskMapReportLayoutObj.name : "",
      riskMapReportLayoutObj.description ? riskMapReportLayoutObj.description : ""
    );
  }
}
