"use strict";


import * as I18NWrapper from "../../../i18n/i18n_wrapper";
import React from "react";
import { MATERIAL_ACTIONS } from "../../helpers/library_helper";
import ProjectMaterialBasePage from "../project_material_base_page";
import { WizardExitStrategies } from "../wizard_exit_strategies";

class UnsyncProjectMaterialPage extends ProjectMaterialBasePage {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {t} = this.props;
    document.title = "QbDVision " + t("Unsync And Keep Linked");

    super.componentDidMount();
  }

  getTitle(material) {
    const {t} = this.props;
    return t("Unsync And Keep Linked") + " " + material.name;
  }

  getSubtitle() {
    const {t} = this.props;
    return t("Unsync Subtitle");
  }

  getSpecificationSubtitle() {
    const {t} = this.props;
    return t("Unsync Specification Subtitle");
  }

  getAction() {
    return MATERIAL_ACTIONS.UNSYNC;
  }

  getActionButtonLabel() {
    const {t} = this.props;
    return t("Unsync And Keep Linked");
  }

  handleOnExit() {
    const {material} = this.state;
    const onExit = WizardExitStrategies.Unsync;
    onExit(material);
  }

  isLinkIconShowing() {
    return false;
  }
}

export default I18NWrapper.wrap(UnsyncProjectMaterialPage, "library");
