"use strict";

import React, { memo, useState } from "react";
import * as sidePanelStyles from "../view/columnSelection/column_selection.module.scss";
import BulkEditHeader from "./bulk_edit_header";
import BulkEditFooter from "./bulk_edit_footer";
import * as styles from "./styles/bulk_edit.module.scss";
import RadioButton from "../../../../../widgets/radioButton/radio_button";
import * as UIUtils from "../../../../../../ui_utils";
import { ConfigurableTableService } from "./configurable_table_service";
import { useSidePanel } from "../widgets/side_panel_provider";
import { ConfirmationPopup } from "../widgets/confirmation_popup";
import { ValueInput } from "./value_input";
import { FieldDropdown } from "./field_dropdown";
import { BulkEditBody } from "./bulk_edit_body";
import { useTableState } from "../../../../table_state_provider";
import { useNotification } from "../../../notification/notification_provider";

const ACTION_SELECTOR = "bulk-action-selector";
const ACTIONS = {
  REPLACE: "replace",
  APPEND: "append",
  CLEAR: "clear",
};

const onEditConfirm = async(
  {
    modelName,
    selectedState,
    clearSelectedRows,
    field,
    value,
    action,
    toggle,
    afterCallingBulkEdit,
  }) => {

  UIUtils.setLoadingDisabled(false);
  UIUtils.setHideLoadingOnAjaxStop(false);

  const service = new ConfigurableTableService();

  const payload = {
    ids: Object.keys(selectedState),
    field,
    value,
    model: modelName,
    fieldAction: action,
  };

  try {
    toggle();
    await service.processBulkEdit(payload);
    afterCallingBulkEdit();
    clearSelectedRows();
  } catch (e) {
    UIUtils.setHideLoadingOnAjaxStop(true);
    UIUtils.hideLoadingImage();
    UIUtils.defaultFailFunction(e);
  } finally {
    UIUtils.setLoadingDisabled(true);
  }
};

const BulkEdit = ({modelName, afterBulkEdit}) => {

  const [
    action,
    setAction
  ] = useState(ACTIONS.REPLACE);

  const [
    value,
    setValue
  ] = useState();

  const [
    showPopup,
    setShowPopup
  ] = useState(false);

  const [
    field,
    setField
  ] = useState();

  const {showNotification} = useNotification();

  const [
    type,
    setType
  ] = useState();

  const {selectedState, clearSelectedRows} = useTableState();

  const {toggle} = useSidePanel();

  const onSelectionChange = ({target}) => {
    setAction(target.value);
  };

  const onFieldChange = (field, type) => {
    setField(field);
    setType(type);
  };

  const onValueChange = (value) => {
    setValue(value);
  };

  const afterCallingBulkEdit = () => {
    afterBulkEdit();
  };

  const editArgs = {
    modelName,
    selectedState,
    clearSelectedRows,
    field,
    value,
    action,
    toggle,
    afterCallingBulkEdit,
    showNotification
  };
  const onConfirm = async() => {
    await onEditConfirm(editArgs);
  };

  const togglePopup = () => {
    setShowPopup(prev => !prev);
  };

  return (
    <>
      <div id="bulkEditDiv" className={sidePanelStyles["side-panel"]}>
        <BulkEditHeader />
        <BulkEditBody>
          <div className={styles["field"]}>
            <FieldDropdown modelName={modelName}
                           onFieldChange={onFieldChange}
            />
          </div>
          <div className={styles["actions"]}>
            <div className={styles["action-item"]}>
              <RadioButton id={ACTIONS.REPLACE}
                           name={ACTION_SELECTOR}
                           label="Replace"
                           checked={action === ACTIONS.REPLACE}
                           onChange={onSelectionChange}
              />
              <div className={styles["action-label"]}>
                Removes the prior value and replaces it with the current value.
              </div>
            </div>
            <div className={styles["action-item"]}>
              <RadioButton id={ACTIONS.APPEND}
                           name={ACTION_SELECTOR}
                           label="Append"
                           checked={action === ACTIONS.APPEND}
                           onChange={onSelectionChange}
              />
              <div className={styles["action-label"]}>
                Adds to the end of the current value.
              </div>
            </div>
          </div>
          <div className={styles["value"]}>
            <ValueInput type={type} value={value} onChange={onValueChange} />
          </div>
        </BulkEditBody>
        <BulkEditFooter disableSubmit={!value && !field}
                        onBulkEdit={togglePopup}
        />
      </div>
      <ConfirmationPopup toggle={togglePopup}
                         onConfirm={onConfirm}
                         showPopup={showPopup}
      />
    </>
  );
};

export default memo(BulkEdit);