"use strict";

import React, { useEffect, useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

import { generateTableIds } from "./configurable_tables_helper";
import { MEDIUM_COLUMN, EXTRA_LARGE_COLUMN } from "./configurable_tables_constants";
import AcceptanceCriteriaRangesCell from "./acceptance_criteria_ranges_cell";
import * as UIUtils from "../../ui_utils";

const TABLE_ID = "acceptanceCriteriaRangesTable";
/**
 *
 * @type {{title: string, field?: string,width: number}[]}
 */
const TABLE_HEADERS = [
  {
    title: "group",
    width: EXTRA_LARGE_COLUMN,
  },
  {
    title: "label",
    width: EXTRA_LARGE_COLUMN,
  },
  {
    title: "lowerLimit",
    label: "Lower Limit",
    width: MEDIUM_COLUMN,
  },
  {
    title: "target",
    width: MEDIUM_COLUMN,
  },
  {
    title: "upperLimit",
    label: "Upper Limit",
    width: MEDIUM_COLUMN,
  },
  {
    title: "units",
    field: "measurementUnits",
    width: EXTRA_LARGE_COLUMN,
  },
];

const  AcceptanceCriteriaRangesDetailsTable = ({requirement}) => {
  const tableRef = useRef();

  useEffect(() => {
    generateTableIds(tableRef, TABLE_ID);
  }, []);

  return (
    <div className="acceptance-criteria-ranges-details-table">
      {
        requirement?.AcceptanceCriteriaRanges?.length > 0 && (
            <div>
              <h2>Acceptance Criteria Ranges</h2>
              <Grid style={{ width: "fit-content", textTransform: "capitalize" }}
                    data={requirement.AcceptanceCriteriaRanges.sort(UIUtils.sortBy("group", "label"))}
                    ref={tableRef}>
                {
                  TABLE_HEADERS.map(({title, label, field, width}) =>
                    <GridColumn key={title}
                                field={field || title}
                                title={label || title}
                                headerClassName={`column-header ${title}`}
                                width={width}
                                cell={AcceptanceCriteriaRangesCell}
                    />)
                }
              </Grid>
            </div>
        )
      }
    </div>
  );
};

export default React.memo(AcceptanceCriteriaRangesDetailsTable);
