/**
 * @hidden
 */
export var THEME_COLOR_CLASSES = {
    'primary': 'k-bottom-nav-primary',
    'secondary': 'k-bottom-nav-secondary',
    'tertiary': 'k-bottom-nav-tertiary',
    'info': 'k-bottom-nav-info',
    'success': 'k-bottom-nav-success',
    'warning': 'k-bottom-nav-warning',
    'error': 'k-bottom-nav-error',
    'dark': 'k-bottom-nav-dark',
    'light': 'k-bottom-nav-light',
    'inverse': 'k-bottom-nav-inverse'
};
/**
 * @hidden
 */
export var FILL_CLASSES = {
    'flat': 'k-bottom-nav-flat',
    'solid': 'k-bottom-nav-solid'
};
/**
 * @hidden
 */
export var ITEM_FLOW_CLASSES = {
    'vertical': 'k-bottom-nav-item-flow-vertical',
    'horizontal': 'k-bottom-nav-item-flow-horizontal'
};
/**
 * @hidden
 */
export var POSITION_MODE_CLASSES = {
    'fixed': 'k-pos-fixed',
    'sticky': 'k-pos-sticky'
};
