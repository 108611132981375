"use strict";

import * as UIUtils from "../../ui_utils";
import { getURLByKey } from "../../helpers/url_helper";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { ProcessDropdown } from "../../processExplorer/widgets/process_dropdown";
import { CommonString } from "../../../server/common/generic/common_string";

export function MinifiedHeader(props) {
  return <div className="company-header-container-minified">

    {
      props.project ? (
        <Fragment>

          <div id="companyHeaderProjectDiv"
               className="company-header-project"
          >
            <a href={getURLByKey(props.keyString, "View", false)}
               target="_blank"
               rel="noopener noreferrer"
            >
              {props.keyString + " - "}
              {CommonString.forceMaxLength(props.project.name, 50)}
            </a>
            <sup className="superscript-label">{props.project.isDemo ? "demo" : ""}</sup>
          </div>

          <div className="company-header-separator">
            <span>/</span>
          </div>

          {props.title ? (props.title) : null }

          {props.renderProcessSelector ? (
              <ProcessDropdown projectId={UIUtils.parseInt(props.project.id)}
                               processId={props.processId}
                               onProcessChange={props.onProcessChange}
                               shouldShowArchivedProcesses={props.shouldShowArchivedProcesses}
              />
          ) : null}
        </Fragment>
      ) : (props.title ?? "")
    }
  </div>;
}

MinifiedHeader.propTypes = {
  project: PropTypes.any,
  keyString: PropTypes.string,
  renderProcessSelector: PropTypes.bool,
  onProcessChange: PropTypes.func,
  processId: PropTypes.number,
  title: PropTypes.any,
  shouldShowArchivedProcesses: PropTypes.bool,
};
