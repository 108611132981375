import { BaseParser } from "../base_parser";
import * as DocumentTransferHelper from "../../../../helpers/document_transfer_helper";
import * as UIUtils from "../../../../ui_utils";
import { APPLIES_TO_ID_TO_DISPLAY_NAME } from "../../../../../server/common/editables/common_editables";

export class DocumentLinkParser extends BaseParser {
  parse() {
    const {modelName, columnName} = this.getNodeInfo();
    const newElement = document.createElement(this.node.tagName);
    this.addModelAndColumnToField(this.fields, modelName, columnName);
    const referenceContent = this.parseDocumentLink(columnName, this.data?.link);
    if (referenceContent?.element) {
      return [referenceContent.element];
    }

    if (referenceContent) {
      newElement.innerText = referenceContent.textContent;
    }
    return [newElement];
  }

  parseDocumentLink(columnName, referenceLink) {
    if (!referenceLink) {
      return null;
    }

    if (
      columnName === "linkOrAttachment" ||
      columnName === "name"
    ) {
      const linkElement = document.createElement("a");
      linkElement.className = "links-table-link";

      let innerText;
      if (referenceLink.linkType === "Attachment") {
        // The content will be rendered by PDFExport, if we do linkElement.onclick, it won't
        // work since PDFExport will remove that. We have to set an attribute onclick and make
        // this function a global function
        window.downloadFile = function(fileData) {
          DocumentTransferHelper.handleFileDownload(fileData);
        };

        linkElement.setAttribute(
          "onclick",
          `downloadFile(${JSON.stringify(referenceLink)})`
        );
        linkElement.download = referenceLink.fileName;
        innerText = referenceLink.fileName;
      } else {
        linkElement.href = UIUtils.cleanUpURL(referenceLink.link);
        linkElement.target = "_blank";
        linkElement.rel = "noopener noreferrer";
        innerText = referenceLink.link;
      }

      if (columnName === "name") {
        innerText = referenceLink[columnName];
      }

      linkElement.innerText = innerText;

      return {element: linkElement};
    }

    if (columnName === "appliesTo") {
      return {
        textContent: (referenceLink[columnName] || [])
          .map(
            (text) =>
              APPLIES_TO_ID_TO_DISPLAY_NAME[text] ||
              UIUtils.convertCamelCaseToSpacedOutWords(text)
          )
          .join(", "),
      };
    }

    return {textContent: referenceLink[columnName] || ""};
  }
}

export function getValues(data, parentModelName, modelName) {
  let links = [];
  if (!data) {
    return links;
  }

  if (data[UIUtils.convertToCamelCaseId(modelName)]) {
    links = JSON.parse(data[UIUtils.convertToCamelCaseId(modelName)]);
  }

  if (data[modelName] && typeof data[modelName] === "string") {
    links = JSON.parse(data[modelName]);
  }

  // The DetailedRiskLinks are in the risk links, so we need to extract it from
  // them to get all the links
  if (modelName === "DetailedRiskLinks") {
    links = [];
    const riskLinkModelNames = Object.keys(data).filter((key) =>
      key.includes(`${parentModelName}To`)
    );
    for (const riskLinkModelName of riskLinkModelNames) {
      data[riskLinkModelName].forEach((riskLink) =>
        links.push(...JSON.parse(riskLink.links || "[]"))
      );
    }

    // Combine all the source documents of risk links with the record riskLinks
    links.push(...JSON.parse(data.riskLinks || "[]"));

    // Filtering duplicated document link
    links = links.filter(
      (currentLink, index) =>
        links.findIndex((link) => link.uuid === currentLink.uuid) === index
    );
  }

  return links.map(link => {
    // We do this to include the parent record itself, so we can read the value later
    return {link, [parentModelName]: data};
  });
}

/**
 * Check whether an attribute is a link or not
 * @param text {String}
 * @return boolean
 */
export function isLinkAttribute(text) {
  return (text || "").toLowerCase().endsWith("links");
}
