"use strict";

export const WIDGET_KIND = {
  Repeater: "Repeater",
  Table: "Table",
  ReferenceBlock: "Reference Block",
  ReferenceList: "Reference List",
  TableOfContents: "Table of Contents",
  Header: "Header",
  Footer: "Footer",
};

export type WIDGET_KIND_VALUE = (typeof WIDGET_KIND)[keyof typeof WIDGET_KIND];

export const COMMON_ATTRIBUTES = {
  Model: {
    key: "model",
    displayName: "Model",
  },
  Filters: {
    key: "filters",
    displayName: "Filter",
  },
};

type WidgetAttribute = {
  key: string;
  displayName: string;
};

type WidgetAttributesMap = {
  [key: WIDGET_KIND_VALUE]: {
    [attr: string]: WidgetAttribute;
  }
};

export const WIDGET_NAME_TO_ATTRIBUTES: WidgetAttributesMap = {
  [WIDGET_KIND.ReferenceBlock]: {
    ReferenceBlockName: {
      key: "referenceblockname",
      displayName: "Name",
    },
  },
  [WIDGET_KIND.ReferenceList]: {
    ReferenceBlock: {
      key: "referenceblock",
      displayName: "Reference Block",
    },
  },
  [WIDGET_KIND.Table]: COMMON_ATTRIBUTES,
  [WIDGET_KIND.Repeater]: COMMON_ATTRIBUTES,
};

export const WIDGET_ATTRIBUTES = Object.values(WIDGET_NAME_TO_ATTRIBUTES)
  .reduce((acc, attributes) => {
    acc.push(...Object.values(attributes));
    return acc;
  }, [])
  .filter((attribute, index, self) => self.indexOf(attribute) === index);
