"use strict";

import React from "react";
import BaseReactComponent from "../../base_react_component";

// noinspection JSFileReferences
import ImplementationNeededError from "../../utils/implementation_needed_error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

/**
 * This is the base class that should be extended to provide a button that acts as a dropdown.
 *
 * Read more here: https://getbootstrap.com/docs/4.5/components/dropdowns/
 */
export class BaseDropdown extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    $(this.popoverRef).popover();
  }

  /**
   * @return the text that goes on the button.
   */
  renderDropDownButtonText() {
    throw new ImplementationNeededError();
  }

  /**
   * @return the DOM id for the button. Useful for tests.
   */
  getButtonId() {
    throw new ImplementationNeededError();
  }

  /**
   * Render whatever should go in the div that comes up when the button is clicked on.
   */
  renderMenuItems() {
    throw new ImplementationNeededError();
  }

  /**
   * Add an outer class name, if needed.
   */
  getOuterClassName() {
    return "";
  }

  isMenuRightAligned() {
    return false;
  }

  render() {
    return (
      <div id="baseDropDown"
           className={"dropdown base-dropdown " + this.getOuterClassName() + this.getClassForLoading()}
      >
        <button className="btn dropdown-toggle base-dropdown-button" type="button" id={this.getButtonId()}
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="true"
        >
          {this.renderDropDownButtonText()}
          <FontAwesomeIcon className="dropdown-icon" size="2xs" icon={faChevronDown} />
        </button>
        <div className={"dropdown-menu" + (this.isMenuRightAligned() ? " dropdown-menu-right" : "")}
             aria-labelledby={this.getButtonId()}
        >
          {this.renderMenuItems()}
        </div>
      </div>
    );
  }
}

BaseDropdown.propTypes = {};
