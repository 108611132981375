var _a;
/**
 * @hidden
 */
export var pagerInfo = 'pager.info';
/**
 * @hidden
 */
export var pagerFirstPage = 'pager.firstPage';
/**
 * @hidden
 */
export var pagerPreviousPage = 'pager.previousPage';
/**
 * @hidden
 */
export var pagerNextPage = 'pager.nextPage';
/**
 * @hidden
 */
export var pagerLastPage = 'pager.lastPage';
/**
 * @hidden
 */
export var pagerItemPerPage = 'pager.itemsPerPage';
/**
 * @hidden
 */
export var pagerPageSelection = 'pager.pageSelection';
/**
 * @hidden
 */
export var pagerPage = 'pager.page';
/**
 * @hidden
 */
export var pagerOf = 'pager.of';
/**
 * @hidden
 */
export var pagerTotalPages = 'pager.totalPages';
/**
 * @hidden
 */
export var pagerPageNumberLabel = 'pager.pageLabel';
/**
 * @hidden
 */
export var pagerAriaKeyshortcuts = 'pager.ariaKeyshortcuts';
/**
 * @hidden
 */
export var pagerAriaLabel = 'pager.ariaLabel';
/**
 * @hidden
 */
export var pagerMobileSelect = 'pager.mobileSelect';
/**
 * @hidden
 */
export var filterEqOperator = 'filter.eqOperator';
/**
 * @hidden
 */
export var filterNotEqOperator = 'filter.notEqOperator';
/**
 * @hidden
 */
export var filterIsNullOperator = 'filter.isNullOperator';
/**
 * @hidden
 */
export var filterIsNotNullOperator = 'filter.isNotNullOperator';
/**
 * @hidden
 */
export var filterIsEmptyOperator = 'filter.isEmptyOperator';
/**
 * @hidden
 */
export var filterIsNotEmptyOperator = 'filter.isNotEmptyOperator';
/**
 * @hidden
 */
export var filterStartsWithOperator = 'filter.startsWithOperator';
/**
 * @hidden
 */
export var filterContainsOperator = 'filter.containsOperator';
/**
 * @hidden
 */
export var filterNotContainsOperator = 'filter.notContainsOperator';
/**
 * @hidden
 */
export var filterEndsWithOperator = 'filter.endsWithOperator';
/**
 * @hidden
 */
export var filterGteOperator = 'filter.gteOperator';
/**
 * @hidden
 */
export var filterGtOperator = 'filter.gtOperator';
/**
 * @hidden
 */
export var filterLteOperator = 'filter.lteOperator';
/**
 * @hidden
 */
export var filterLtOperator = 'filter.ltOperator';
/**
 * @hidden
 */
export var filterIsTrue = 'filter.isTrue';
/**
 * @hidden
 */
export var filterIsFalse = 'filter.isFalse';
/**
 * @hidden
 */
export var filterAfterOrEqualOperator = 'filter.afterOrEqualOperator';
/**
 * @hidden
 */
export var filterAfterOperator = 'filter.afterOperator';
/**
 * @hidden
 */
export var filterBeforeOperator = 'filter.beforeOperator';
/**
 * @hidden
 */
export var filterBeforeOrEqualOperator = 'filter.beforeOrEqualOperator';
/**
 * @hidden
 */
export var filterAndLogic = 'filter.andLogic';
/**
 * @hidden
 */
export var filterOrLogic = 'filter.orLogic';
/**
 * @hidden
 */
export var filterAddExpression = 'filter.addExpression';
/**
 * @hidden
 */
export var filterAddGroup = 'filter.addGroup';
/**
 * @hidden
 */
export var filterClose = 'filter.close';
/**
 * @hidden
 */
export var filterGroupAriaLabel = 'filter.groupAriaLabel';
/**
 * @hidden
 */
export var filterExpressionAriaLabel = 'filter.expressionAriaLabel';
/**
 * @hidden
 */
export var filterExpressionDropdownAriaLabel = 'filter.expressionDropdownAriaLabel';
/**
 * @hidden
 */
export var filterExpressionOperatorDropdownAriaLabel = 'filter.expressionOperatorDropdownAriaLabel';
/**
 * @hidden
 */
export var filterEnumFilterDropdownAriaLabel = 'filter.enumFilterDropdownAriaLabel';
/**
 * @hidden
 */
export var filterNumericFilterAriaLabel = 'filter.numericFilterAriaLabel';
/**
 * @hidden
 */
export var filterTextFilterAriaLabel = 'filter.textFilterAriaLabel';
/**
 * @hidden
 */
export var columnMenuFilterClearButton = 'columnMenu.filterClearButton';
/**
 * @hidden
 */
export var columnMenuFilterSubmitButton = 'columnMenu.filterSubmitButton';
/**
 * @hidden
 */
export var columnMenuFilterTitle = 'columnMenu.filterTitle';
/**
 * @hidden
 */
export var columnMenuSortAscending = 'columnMenu.sortAscending';
/**
 * @hidden
 */
export var columnMenuSortDescending = 'columnMenu.sortDescending';
/**
 * @hidden
 */
export var columnMenuFilterEqOperator = 'columnMenu.filterEqOperator';
/**
 * @hidden
 */
export var columnMenuFilterNotEqOperator = 'columnMenu.filterNotEqOperator';
/**
 * @hidden
 */
export var columnMenuFilterIsNullOperator = 'columnMenu.filterIsNullOperator';
/**
 * @hidden
 */
export var columnMenuFilterIsNotNullOperator = 'columnMenu.filterIsNotNullOperator';
/**
 * @hidden
 */
export var columnMenuFilterIsEmptyOperator = 'columnMenu.filterIsEmptyOperator';
/**
 * @hidden
 */
export var columnMenuFilterIsNotEmptyOperator = 'columnMenu.filterIsNotEmptyOperator';
/**
 * @hidden
 */
export var columnMenuFilterStartsWithOperator = 'columnMenu.filterStartsWithOperator';
/**
 * @hidden
 */
export var columnMenuFilterContainsOperator = 'columnMenu.filterContainsOperator';
/**
 * @hidden
 */
export var columnMenuFilterNotContainsOperator = 'columnMenu.filterNotContainsOperator';
/**
 * @hidden
 */
export var columnMenuFilterEndsWithOperator = 'columnMenu.filterEndsWithOperator';
/**
 * @hidden
 */
export var columnMenuFilterGteOperator = 'columnMenu.filterGteOperator';
/**
 * @hidden
 */
export var columnMenuFilterGtOperator = 'columnMenu.filterGtOperator';
/**
 * @hidden
 */
export var columnMenuFilterLteOperator = 'columnMenu.filterLteOperator';
/**
 * @hidden
 */
export var columnMenuFilterLtOperator = 'columnMenu.filterLtOperator';
/**
 * @hidden
 */
export var columnMenuFilterIsTrue = 'columnMenu.filterIsTrue';
/**
 * @hidden
 */
export var columnMenuFilterAfterOrEqualOperator = 'columnMenu.filterAfterOrEqualOperator';
/**
 * @hidden
 */
export var columnMenuFilterAfterOperator = 'columnMenu.filterAfterOperator';
/**
 * @hidden
 */
export var columnMenuFilterBeforeOperator = 'columnMenu.filterBeforeOperator';
/**
 * @hidden
 */
export var columnMenuFilterBeforeOrEqualOperator = 'columnMenu.filterBeforeOrEqualOperator';
/**
 * @hidden
 */
export var columnMenuFilterAndLogic = 'columnMenu.filterAndLogic';
/**
 * @hidden
 */
export var columnMenuFilterOrLogic = 'columnMenu.filterOrLogic';
/**
 * @hidden
 */
export var sortAriaLabel = 'sort.ariaLabel';
/**
 * @hidden
 */
export var filterAriaLabel = 'filter.ariaLabel';
/**
 * @hidden
 */
export var messages = (_a = {},
    _a[pagerItemPerPage] = 'items per page',
    _a[pagerPageSelection] = 'Select page',
    _a[pagerInfo] = '{0} - {1} of {2} items',
    _a[pagerFirstPage] = 'Go to the first page',
    _a[pagerPreviousPage] = 'Go to the previous page',
    _a[pagerNextPage] = 'Go to the next page',
    _a[pagerLastPage] = 'Go to the last page',
    _a[pagerPage] = 'Page',
    _a[pagerOf] = 'Of',
    _a[pagerTotalPages] = '{0}',
    _a[pagerPageNumberLabel] = 'Page',
    _a[pagerAriaKeyshortcuts] = 'Enter ArrowRight ArrowLeft',
    _a[pagerAriaLabel] = 'Pager',
    _a[pagerMobileSelect] = 'Select',
    _a[sortAriaLabel] = 'Sortable',
    _a[filterAriaLabel] = 'Filter',
    _a[filterEqOperator] = 'Is equal to',
    _a[filterNotEqOperator] = 'Is not equal to',
    _a[filterIsNullOperator] = 'Is null',
    _a[filterIsNotNullOperator] = 'Is not null',
    _a[filterIsEmptyOperator] = 'Is empty',
    _a[filterIsNotEmptyOperator] = 'Is not empty',
    _a[filterStartsWithOperator] = 'Starts with',
    _a[filterContainsOperator] = 'Contains',
    _a[filterNotContainsOperator] = 'Does not contain',
    _a[filterEndsWithOperator] = 'Ends with',
    _a[filterGteOperator] = 'Is greater than or equal to',
    _a[filterGtOperator] = 'Is greater than',
    _a[filterLteOperator] = 'Is less than or equal to',
    _a[filterLtOperator] = 'Is less than',
    _a[filterIsTrue] = 'Is true',
    _a[filterIsFalse] = 'Is false',
    _a[filterAfterOrEqualOperator] = 'Is after or equal to',
    _a[filterAfterOperator] = 'Is after',
    _a[filterBeforeOperator] = 'Is before',
    _a[filterBeforeOrEqualOperator] = 'Is before or equal to',
    _a[filterAndLogic] = 'And',
    _a[filterOrLogic] = 'Or',
    _a[filterAddExpression] = 'Add Expression',
    _a[filterAddGroup] = 'Add Group',
    _a[filterClose] = 'Close',
    _a[filterGroupAriaLabel] = 'Filter toolbar',
    _a[filterExpressionAriaLabel] = 'Filter expression row',
    _a[filterExpressionDropdownAriaLabel] = 'Filter dropdown',
    _a[filterExpressionOperatorDropdownAriaLabel] = 'Filter operator dropdown',
    _a[filterEnumFilterDropdownAriaLabel] = 'Select True/False',
    _a[filterNumericFilterAriaLabel] = 'Enter number',
    _a[filterTextFilterAriaLabel] = 'Enter number',
    _a[columnMenuFilterClearButton] = 'Clear',
    _a[columnMenuFilterSubmitButton] = 'Filter',
    _a[columnMenuFilterTitle] = 'Filter',
    _a[columnMenuSortAscending] = 'Sort Ascending',
    _a[columnMenuSortDescending] = 'Sort Descending',
    _a[columnMenuFilterEqOperator] = 'Is equal to',
    _a[columnMenuFilterNotEqOperator] = 'Is not equal to',
    _a[columnMenuFilterIsNullOperator] = 'Is null',
    _a[columnMenuFilterIsNotNullOperator] = 'Is not null',
    _a[columnMenuFilterIsEmptyOperator] = 'Is empty',
    _a[columnMenuFilterIsNotEmptyOperator] = 'Is not empty',
    _a[columnMenuFilterStartsWithOperator] = 'Starts with',
    _a[columnMenuFilterContainsOperator] = 'Contains',
    _a[columnMenuFilterNotContainsOperator] = 'Does not contain',
    _a[columnMenuFilterEndsWithOperator] = 'Ends with',
    _a[columnMenuFilterGteOperator] = 'Is greater than or equal to',
    _a[columnMenuFilterGtOperator] = 'Is greater than',
    _a[columnMenuFilterLteOperator] = 'Is less than or equal to',
    _a[columnMenuFilterLtOperator] = 'Is less than',
    _a[columnMenuFilterIsTrue] = 'Is true',
    _a[columnMenuFilterAfterOrEqualOperator] = 'Is after or equal to',
    _a[columnMenuFilterAfterOperator] = 'Is after',
    _a[columnMenuFilterBeforeOperator] = 'Is before',
    _a[columnMenuFilterBeforeOrEqualOperator] = 'Is before or equal to',
    _a[columnMenuFilterAndLogic] = 'And',
    _a[columnMenuFilterOrLogic] = 'Or',
    _a);
