"use strict";

import React from "react";
import BaseReactComponent from "../../../../base_react_component";
import * as UIUtils from "../../../../ui_utils";
import moment from "moment";
import { getURLByTypeCodeAndId } from "../../../../helpers/url_helper";

/**
 * This implements the batch panel showing batch information while reviewing continuous data in a process data import flow.
 */
export default class ContinuousDataBatchPanel extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  createBatchField(batchProperty, isDateField = false) {
    const value = this.props.batchInfo && this.props.batchInfo[batchProperty];

    return (
      <div className="col">
        <div>
          <span className="batch-attribute-label">{UIUtils.convertCamelCaseToSpacedOutWords(batchProperty)}</span>
        </div>
        <div>
          <span id={batchProperty}>{isDateField && value ? moment(value).format(UIUtils.DATE_FORMAT_FOR_DISPLAY) : value}</span>
        </div>
      </div>
    );
  }

  createBatchIdField(id, batchId) {
    return (
      <div className="col">
        <div>
          <span className="batch-attribute-label">Batch ID</span>
        </div>
        <div>
          <a id="batchId"
             href={getURLByTypeCodeAndId("BAT", "View", id)}
             target="_blank"
             rel="noreferrer noopener"
          >
            {batchId}
          </a>
        </div>
      </div>
    );
  }

  render() {
    const batch = this.props.batchInfo || {};
    const {batchId, id} = batch;

    return (
      <div className="col-12">
        <div className="row">
          {this.createBatchIdField(id, batchId)}
          {this.createBatchField("startDate", true)}
          {this.createBatchField("manufactureDate", true)}
          {this.createBatchField("releaseDate", true)}
          {this.createBatchField("expirationDate", true)}
          {this.createBatchField("site",)}
          {this.createBatchField("scale",)}
          {this.createBatchField("batchType",)}
        </div>
      </div>
    );
  }

}
