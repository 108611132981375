"use strict";

import {getAttributes} from "../../utils";
import {
  WIDGET_ATTRIBUTES,
  WIDGET_KIND,
  WIDGET_KIND_VALUE,
} from "../../components/sideMenu/widget/widget_constants";
import * as UIUtils from "../../../ui_utils";
import {EditorView, NodeSpec, Node} from "@progress/kendo-editor-common";
import {EditorExecuteEvent} from "@progress/kendo-react-editor";

function getDefaultAttributes() {
  const defaultAttrs = {};
  for (const attribute of WIDGET_ATTRIBUTES) {
    defaultAttrs[attribute.key] = {default: null};
  }
  return defaultAttrs;
}

function getNodeAttributes(node: Node) {
  const attrs: Record<string, any> = {};
  const NON_CONTENT_EDITABLE_WIDGETS = [
    WIDGET_KIND.ReferenceBlock,
    WIDGET_KIND.TableOfContents,
  ];
  for (const attribute of WIDGET_ATTRIBUTES) {
    attrs[attribute.key] = node.attrs[attribute.key];
  }

  if (node.attrs && NON_CONTENT_EDITABLE_WIDGETS.includes(node.attrs.kind)) {
    attrs.contenteditable = false;
  }

  return attrs;
}

// NOTE: Please do not change the class in toDom, it will break our parser
export const WIDGET_NODE: NodeSpec = {
  name: "widget",
  inline: false,
  group: "block",
  content: "block+",
  marks: "",
  attrs: {
    contenteditable: {default: true},
    class: {default: null},
    style: {default: null},
    kind: {default: null},
    ...getDefaultAttributes(),
  },
  atom: false,
  isolating: true,
  parseDOM: [
    {
      tag: "div.widget",
      getAttrs: (node) =>
        node instanceof HTMLElement ? getAttributes(node) : {},
      contentElement: (node) => {
        return (node as HTMLElement).querySelector("div.widget-content");
      },
      priority: 51,
    },
  ],
  toDOM: (node) => {
    const items = [
      "div",
      {class: "widget-main"},
      [
        "div",
        {class: "widget-item"},
        ["div", `Type: ${node.attrs.kind || ""}`],
      ],
    ];

    for (const attribute of WIDGET_ATTRIBUTES) {
      if (!node.attrs[attribute.key]) {
        continue;
      }

      let value = node.attrs[attribute.key];
      if (attribute.key === "model") {
        value = value.includes(".")
          ? value
          : UIUtils.convertCamelCaseToSpacedOutWords(value);
      } else if (attribute.key === "filters") {
        value = JSON.parse(value)
          .map((filter: any) => {
            return `${filter.attribute} ${filter.operator} ${filter.targetValue}`;
          })
          .join(",");
      }

      items.push([
        "div",
        {class: "widget-item"},
        ["div", `${attribute.displayName}: ${value}`],
      ]);
    }

    return [
      "div",
      {
        class: "widget",
        kind: node.attrs.kind,
        ...getNodeAttributes(node),
      },
      items,
      [
        "div",
        {class: "widget-content-container"},
        ["div", {class: "widget-content"}, 0],
      ],
    ];
  },
};

/**
 * Find the closest widget at the current selection
 * @param view
 * @return {any}
 */
export function getClosestWidget(view: EditorView): any {
  const state = view?.state;

  if (!state) {
    return null;
  }

  const anchor = state.selection.$anchor;
  for (let d = anchor.depth; d > 0; d--) {
    const node = anchor.node(d);
    if (isWidget(node)) {
      return node;
    }
  }
  return null;
}

/**
 * Check whether the current position is in a widget or not
 * @param view
 * @param kind
 * @return {boolean}
 */
export function isInWidget(
  view: EditorView | EditorExecuteEvent,
  kind: WIDGET_KIND_VALUE = null,
): boolean {
  const state = view?.state;

  if (!state) {
    return false;
  }

  const anchor = state.selection.$anchor;
  for (let d = anchor.depth; d > 0; d--) {
    const node = anchor.node(d);
    if (isWidget(node, kind)) {
      return true;
    }
  }
  return false;
}

/**
 * Check whether a node is a widget or not
 * @param node
 * @param kind
 * @return {boolean}
 */
export function isWidget(node: any, kind: WIDGET_KIND_VALUE = null): boolean {
  const isWidgetNode =
    node.attrs &&
    node.attrs.kind &&
    (node.attrs.class as string).includes("widget");
  if (kind) {
    return isWidgetNode && node.attrs.kind === kind;
  }
  return isWidgetNode;
}
