@import "../../../../css/_brand_colors.scss";

.link {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  :global(a) {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    font-style: normal;
    font-weight: 400 !important;
    color: $brand-primary-color !important;
  }

  :global(a):not([href]):not([class]):hover {
    text-decoration: underline;
  }
}

.show-more {
  display: inline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tooltip-container {
  :global(.tooltip-inner) {
    padding: 16px;
    max-width: 250px;
  }
}

.tooltip-container > :global(.tooltip-inner) {
  padding: 8px 17px 8px 12px;
  background-color: #fff;
  color: $brand-black;
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px 0 rgba(0, 0, 0, 0.20);
  text-align: left;
}

.tooltip-container > :global(.arrow::before) {
  border-bottom-color: $brand-white;
  border-width: 7px;
}