"use strict";

import React from "react";
import ReactDOMServer from "react-dom/server";
import TableWithCheckboxes from "../../widgets/tables/table_with_checkboxes";
import { isQualitativeMeasure } from "../../../server/common/editables/common_editables";
import { getURLByKey } from "../../helpers/url_helper";

const APPLY_BUTTON_ID = "applyButton";

/**
 * This shows the "Subgroups" table on the Analytics Dashboard.
 QI-2728 - As a user, I want to see subgroups on the Process Capability Reports
 */
export default class PCMDashboardProcessDataTable extends TableWithCheckboxes {
  constructor(props) {
    super(props);
    this.areCheckboxesSelectedByDefault = true;
  }

  getColumns() {
    let columns = [];

    if (!this.props.hideCheckBoxes) {
      columns = columns.concat([
        {
          title: "",
          width: 1,
          data: (result) => result.id,
        }
      ]);
    }

    columns = columns.concat(
      {
        title: "ID",
        width: 50,
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            <a href={getURLByKey(result.fullKey, "View", false)}>
              { result.id }
            </a>
          );
        }
      },
    );

    if(this.props.data.find(batch => batch.type === "Batch")) {
      columns = columns.concat(
        {
          title: "Type",
          data: (result) => {
            return ReactDOMServer.renderToStaticMarkup(
              result.batchType
            );
          }
        }
      );
    }

    columns = columns.concat([
      {
        title: "Supplier",
        width: 200,
        data: (result) => {
          if(result.supplierFullKey !== "") {
            return ReactDOMServer.renderToStaticMarkup(
              <a href={getURLByKey(result.supplierFullKey, "View", false)}>
                { result.supplierFullKey }
              </a>
            );
          } else {
            return ReactDOMServer.renderToStaticMarkup(
              ""
            );
          }
        }
    }]);

    if(this.props.data.find(batch => batch.type === "Batch")) {
      columns = columns.concat(
        {
          title: "Purpose",
          data: (result) => {
            return ReactDOMServer.renderToStaticMarkup(
              result.purpose
            );
          }
        }
      );
    }

    columns = columns.concat([
      {
        title: "Start Date",
        width: 200,
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            result.startDate
          );
        }
      },
      {
        title: "Manufacture Date",
        width: 200,
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            result.manufactureDate
          );
        }
      },
      {
        title: "Release Date",
        width: 200,
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            result.releaseDate
          );
        }
      },
      {
        title: "Expiration Date",
        width: 200,
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            result.expirationDate
          );
        }
      },
      {
        title: "Site",
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            result.site
          );
        }
      },
      {
        title: "Scale",
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            result.scale
          );
        }
      },
      {
        title: "Count",
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            result.totalMeasurements
          );
        }
      }
    ]);

    if (isQualitativeMeasure(this.props.measure)) {
      columns = columns.concat([{
        title: "Defective %",
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            result.defectivePercentage
          );
        }
      }]);
    } else {
      columns = columns.concat([{
        title: "Min",
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            result.minValue
          );
        }
      }, {
        title: "Max",
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            result.maxValue
          );
        }
      }, {
        title: "Average",
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            result.average
          );
        }
      }, {
        title: "SD",
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            result.sd
          );
        }
      }]);
    }

    return columns;
  }

  // noinspection JSMethodCanBeStatic
  getColumnsDefs() {
    let columnsDefs = super.getColumnsDefs();
    if (!this.props.hideCheckBoxes) {
      columnsDefs = columnsDefs.concat(
        [
          {
            className: "results-table-column-id",
            targets: 1
          }
        ]
      );
    }
    return columnsDefs;
  }

  componentDidMount() {
    super.componentDidMount();

    if (!this.props.hideCheckBoxes) {
      this.table.button().add(0,
        {
          text: "Apply",
          className: "btn btn-lg btn-primary pcm-dashboard-data-apply-button",
          action: this.handleApply,
          attr: {
            id: APPLY_BUTTON_ID,
          }
        }
      );
    }
  }

  /**
   * This updates the select all checkbox status on the header of the table based on the user selection.
   * Due to an incompatibility between the datatables and the checkboxes plugins, it also updates the select-info
   * span to show the correct information
   */
  handleSelectedRowsChanged() {
    super.handleSelectedRowsChanged();

    let selectedRows = this.getSelectedRows().length;
    let applyButton = $("#" + APPLY_BUTTON_ID);

    // Update the approve button status based on the number of selected rows.
    applyButton.prop("disabled", selectedRows < 2);
    applyButton.prop("title", selectedRows < 2 ? "At least two subgroups are required." : "");
  }

  handleApply() {
    let selectedRows = this.getSelectedRows();

    if (selectedRows.length > 0) {
      this.props.onApply && this.props.onApply(selectedRows);
    }
  }
}
