var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
/** @hidden */
export var useControlledState = function (defaultProp, prop, callback) {
    var _a = React.useState(prop || defaultProp), state = _a[0], setState = _a[1];
    var handleSetEdit = React.useCallback(function (value, args) {
        setState(value);
        if (callback) {
            callback.call(undefined, __assign(__assign({}, args), { value: value }));
        }
    }, [callback, setState]);
    return [prop !== undefined ? prop : state, handleSetEdit];
};
