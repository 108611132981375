"use strict";

import React from "react";
import BaseReactComponent from "../../../base_react_component";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as UIUtils from "../../../ui_utils";
import { generateTableId } from "../../../configurableTables/tables/configurable_tables_helper";
import { process } from "@progress/kendo-data-query";
import { DEFAULT_TECH_TRANSFER_SORTING } from "../../tech_transfer_constants";

export default class TechTransferVariableGridDetailsTable extends BaseReactComponent {
  constructor(props) {
    super(props);
    this["processParametersTable"] = React.createRef();
    this["materialAttributesTable"] = React.createRef();
  }

  render() {
    const {dataItem} = this.props;
    const {details} = dataItem;
    const materialAttributes = details.filter(record => record.typeCode === "MA");
    const processParameters = details.filter(record => record.typeCode === "PP");
    return (
      <div>
        {this.renderGrid("Process Parameters", processParameters, dataItem)}
        {this.renderGrid("Material Attributes", materialAttributes, dataItem)}
      </div>
    );
  }

  componentDidMount() {
    super.componentDidMount();
    generateTableId(this, "processParametersTable");
    generateTableId(this, "materialAttributesTable");
  }

  renderGrid(title, records, {columnGenerator, fromProcess, toProcess, onReviewRecord}) {
    return records && records.length > 0 ? <div>
      <h2>{title}</h2>
      <Grid style={{width: "fit-content"}}
            data={process(records.slice(0), {sort: DEFAULT_TECH_TRANSFER_SORTING})}
            ref={this[`${UIUtils.convertToCamelCaseId(title)}Table`]}
      >
        {columnGenerator.generateAttributeColumn(title, onReviewRecord)}
        <GridColumn key="acceptanceCriteria"
                    headerClassName="text-center two-lines-header"
                    title="Acceptance Criteria Summary"
        >
          {columnGenerator.generateAcceptanceCriteriaColumn(fromProcess)}
          {columnGenerator.generateAcceptanceCriteriaColumn(toProcess, true)}
        </GridColumn>
        {columnGenerator.generateTechTransferCommonColumns(false)}
      </Grid>
    </div> : "";
  }
}
