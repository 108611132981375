"use strict";

import * as UIUtils from "../../../ui_utils";
import {
  getRiskFilterNameFromRiskScale,
  getRiskScales
} from "../../../helpers/risk_helper";
import { orderAndIndexSteps, orderAndIndexUnits } from "../../../processExplorer/indexers/uo_indexer";
import { EMPTY_STRING, FINAL_ATTRIBUTE_TYPE_CODES, RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import CommonRiskUtils from "../../../../server/common/misc/common_risk_utils";
import { RISK_COLORS } from "../../../rmps/constants/rmp_constants";
import { RISK_LABEL_ACTION } from "../../constants/report_constants";

const RISK_TYPES_ARRAY = Object.values(RISK_TYPE_ENUM);

export function getHistoricalData(projectWithAllVersions, rmpVersions, data, objectVersionChangeTracker) {
  let mapHistory = {
    project: data.project,
    historyPoints: []
  };

  let mapHistorySnapshot = new Map();
  let sortedByDateVersions = new Map();
  let unitOperationVersions = {};
  let stepVersions = {};

  if (data.tppVersions) {
    addObjectsToMap(data, sortedByDateVersions, "TPP", data.tppVersions, "TPPSectionId",
      "TPPSectionVersionTransitions", null, null,
      null, null, rmpVersions, projectWithAllVersions);
  }

  if (data.gaVersions) {
    addObjectsToMap(data, sortedByDateVersions, "GA", data.gaVersions, "GeneralAttributeId",
      "GeneralAttributeVersionTransitions", null, null,
      null, null, rmpVersions, projectWithAllVersions);
  }

  if (data.fqaVersions) {
    addObjectsToMap(data, sortedByDateVersions, "FQA", data.fqaVersions, "FQAId",
      "FQAVersionTransitions", null, ["TPPSections", "GeneralAttributes", "FQAToGeneralAttributeRiskLinkedVersions"],
      ["id", "id", "GeneralAttributeId"], ["TPP", "GA", "GA"], rmpVersions, projectWithAllVersions);
  }

  if (data.fpaVersions) {
    addObjectsToMap(data, sortedByDateVersions, "FPA", data.fpaVersions, "FPAId",
      "FPAVersionTransitions", null, ["TPPSections", "GeneralAttributes", "FPAToGeneralAttributeRiskLinkedVersions"],
      ["id", "id", "GeneralAttributeId"], ["TPP", "GA", "GA"], rmpVersions, projectWithAllVersions);
  }

  if (data.unitOperations) {
    for (let unitOperationVersion of data.unitOperations) {
      let unitOperationVersionTransitions = unitOperationVersion.UnitOperationVersionTransitions
        .filter(uoTransition => uoTransition.transition === UIUtils.VERSION_STATES.DRAFT
          || uoTransition.transition === UIUtils.VERSION_STATES.ARCHIVED
          || uoTransition.transition === UIUtils.VERSION_STATES.RESTORED);
      for (let unitOperationTransition of unitOperationVersionTransitions) {
        let uoVersion = {
          unitOperationId: unitOperationVersion.unitOperationId,
          name: unitOperationVersion.name,
          description: unitOperationVersion.description,
          risk: unitOperationVersion.risk,
          status: unitOperationTransition.transition,
          PreviousUnitId: unitOperationVersion.PreviousUnitId,
          deletedAt: unitOperationTransition.transition === UIUtils.VERSION_STATES.ARCHIVED ? unitOperationTransition.createdAt : null
        };
        if (!unitOperationVersions[unitOperationVersion.unitOperationId]) {
          unitOperationVersions[unitOperationVersion.unitOperationId] = [{
            from: new Date(unitOperationTransition.createdAt),
            to: null,
            unitOperation: uoVersion
          }];
        } else {
          unitOperationVersions[unitOperationVersion.unitOperationId].push({
            from: new Date(unitOperationTransition.createdAt),
            to: null,
            unitOperation: uoVersion
          });
          unitOperationVersions[unitOperationVersion.unitOperationId][unitOperationVersions[unitOperationVersion.unitOperationId].length - 2].to = new Date(unitOperationTransition.createdAt);
        }
      }
    }
  }

  if (data.steps) {
    for (let stepVersion of data.steps) {
      let stepVersionTransitions = stepVersion.StepVersionTransitions
        .filter(stepTransition => stepTransition.transition === UIUtils.VERSION_STATES.DRAFT
          || stepTransition.transition === UIUtils.VERSION_STATES.ARCHIVED
          || stepTransition.transition === UIUtils.VERSION_STATES.RESTORED);
      for (let stepTransition of stepVersionTransitions) {
        let stpVersion = {
          stepId: stepVersion.stepId,
          unitOperationId: stepVersion.UnitOperationId,
          name: stepVersion.name,
          status: stepTransition.transition,
          PreviousStepId: stepVersion.PreviousStepId,
          deletedAt: stepTransition.transition === UIUtils.VERSION_STATES.ARCHIVED ? stepTransition.createdAt : null
        };
        if (!stepVersions[stepVersion.stepId]) {
          stepVersions[stepVersion.stepId] = [{
            from: new Date(stepTransition.createdAt),
            to: null,
            step: stpVersion
          }];
        } else {
          stepVersions[stepVersion.stepId].push({
            from: new Date(stepTransition.createdAt),
            to: null,
            step: stpVersion
          });
          stepVersions[stepVersion.stepId][stepVersions[stepVersion.stepId].length - 2].to = new Date(stepTransition.createdAt);
        }
      }
    }
  }

  if (data.iqaVersions) {
    addObjectsToMap(data, sortedByDateVersions, "IQA", data.iqaVersions, "IQAId", "IQAVersionTransitions",
      unitOperationVersions,
      ["IQAToFQALinkedVersions", "IQAToIQALinkedVersions", "IQAToIPALinkedVersions", "IQAToFPALinkedVersions"],
      ["FQAId", "TargetIQAId", "IPAId", "FPAId"],
      ["FQA", "IQA", "IPA", "FPA"], rmpVersions, projectWithAllVersions);
  }

  if (data.ipaVersions) {
    addObjectsToMap(data, sortedByDateVersions, "IPA", data.ipaVersions, "IPAId", "IPAVersionTransitions",
      unitOperationVersions,
      ["IPAToFQALinkedVersions", "IPAToIQALinkedVersions", "IPAToIPALinkedVersions", "IPAToFPALinkedVersions"],
      ["FQAId", "IQAId", "TargetIPAId", "FPAId"],
      ["FQA", "IQA", "IPA", "FPA"], rmpVersions, projectWithAllVersions);
  }

  if (data.ppVersions) {
    addObjectsToMap(data, sortedByDateVersions, "PP", data.ppVersions,
      "ProcessParameterId", "ProcessParameterVersionTransitions",
      unitOperationVersions,
      ["ProcessParameterToIQALinkedVersions", "ProcessParameterToFQALinkedVersions",
        "ProcessParameterToIPALinkedVersions", "ProcessParameterToFPALinkedVersions"],
      ["IQAId", "FQAId", "IPAId", "FPAId"],
      ["IQA", "FQA", "IPA", "FPA"], rmpVersions, projectWithAllVersions);
  }

  if (data.maVersions) {
    addObjectsToMap(data, sortedByDateVersions, "MA", data.maVersions, "MaterialAttributeId",
      "MaterialAttributeVersionTransitions", unitOperationVersions,
      ["MaterialAttributeToIQALinkedVersions", "MaterialAttributeToFQALinkedVersions",
        "MaterialAttributeToIPALinkedVersions", "MaterialAttributeToFPALinkedVersions"],
      ["IQAId", "FQAId", "IPAId", "FPAId"],
      ["IQA", "FQA", "IPA", "FPA"], rmpVersions, projectWithAllVersions);
  }

  sortedByDateVersions = new Map([...sortedByDateVersions.entries()].sort());

  /**
   * Here we create a huge array (i.e. `mapHistory.historyPoints`) where each entry is a meaningful change in the risk
   * map. This array is what the timeline will be created from on the bottom of the risk map. This algorithm is fairly
   * well optimized so be careful when modifying it. First, try out your changes with a regular magic cake and then
   * make sure it works with the stress test project named "Large Project w/ 900+ IQAs".
   *
   * On every iteration of the for loop below, an entire map of the system is generated. This would be pretty intensive
   * if we created a unique map with unique objects for every modification, so we share memory by basically doing something
   * like the following:
   *
   * Iteration 1: Add PP-1
   *   mapHistorySnapshot = {"PP-1": PP-1-at-memory-reference-0001}
   *   mapHistory.historyPoints = [
   *     {"PP-1": PP-1-at-memory-reference-0001} // <-- This is a shallow copy of mapHistorySnapshot
   *   ]
   *
   * Iteration 2: Add PP-2
   *   mapHistorySnapshot = {"PP-1": PP-1-at-memory-reference-0001, "PP-2": PP-1-at-memory-reference-0002}
   *   mapHistory.historyPoints = [
   *     {"PP-1": PP-1-at-memory-reference-0001},
   *     {"PP-1": PP-1-at-memory-reference-0001, "PP-2": PP-1-at-memory-reference-0002} // <-- This is a shallow copy of mapHistorySnapshot pushed on top of the previous iteration
   *   ]
   *
   * Iteration 3: Modify PP-1
   *   mapHistorySnapshot = {"PP-1": PP-1-at-memory-reference-0003, "PP-2": PP-1-at-memory-reference-0002}
   *   mapHistory.historyPoints = [
   *     {"PP-1": PP-1-at-memory-reference-0001},
   *     {"PP-1": PP-1-at-memory-reference-0001, "PP-2": PP-1-at-memory-reference-0002},
   *     {"PP-1": PP-1-at-memory-reference-0003, "PP-2": PP-1-at-memory-reference-0002}, // <-- Again, this is a shallow copy of mapHistorySnapshot pushed on top of the previous iteration
   *   ]
   *
   * In iteration 3, see how PP-1 changed? We cloned PP-1 in order to not disturb the previous history points. If we just
   * modified PP-1-at-memory-reference-0001 with new data then all of the old versions would be ruined. Also, notice
   * how little memory is being used up. We have only 3 distinct object but the pointer to these objects is being used
   * over and over again. When you have 2.1k objects in 3.5k history points, this really adds up, both in memory and
   * compute intensity in deep cloning compared to deep cloning the entire mapHistorySnapShot for every iteration.
   */
  let index = 1;
  for (let [mapKey, objectVersion] of sortedByDateVersions) {
    const key = objectVersion.type + "-" + objectVersion.id;
    let changes = {
      removedLinks: [],
      addedLinks: [],
      modifiedLinks: [],
      changesDescription: "",
      affectedRiskTypes: []
    };
    let uoVersionsMap = {};
    let stepVersionsMap = {};
    let orderedUOVersionsList = findAndOrderUOVersionsForDate(unitOperationVersions, index !== sortedByDateVersions.size ? new Date(objectVersion.createdAt) : null);
    let orderedStepVersionsList = findAndOrderStepVersionsForDate(stepVersions, index !== sortedByDateVersions.size ? new Date(objectVersion.createdAt) : null);

    for (let uoVersion of orderedUOVersionsList) {
      uoVersionsMap[uoVersion.unitOperationId] = uoVersion;
    }

    for (let stepVersion of orderedStepVersionsList) {
      stepVersionsMap[stepVersion.stepId] = stepVersion;
    }

    if (mapHistorySnapshot.has(key)) {
      let previousObjectVersion = mapHistorySnapshot.get(key);
      objectVersionChangeTracker.trackVersionChanges(objectVersion, previousObjectVersion, changes, uoVersionsMap, stepVersionsMap);
    } else {
      changes = objectVersionChangeTracker.summarizeNewObjectChanges(objectVersion, changes);
      changes.affectedRiskTypes.push(...RISK_TYPES_ARRAY);
    }

    if (changes.changesDescription !== "") {
      setIncomingLinks(mapHistorySnapshot, objectVersion, changes);
      let affectedRiskTypes = RISK_TYPES_ARRAY.filter(riskType => {
        return changes.affectedRiskTypes.includes(riskType);
      });

      // Clone the existing item so previous versions in the history stay intact.
      let historySnapshotItem;
      if (mapHistorySnapshot.has(key)) {
        historySnapshotItem = UIUtils.deepClone(mapHistorySnapshot.get(key)); // So that we don't modify something already in the history.
      } else {
        historySnapshotItem = {};
        historySnapshotItem.type = objectVersion.type;
        historySnapshotItem.id = objectVersion.id;
        historySnapshotItem.incomingLinks = objectVersion.incomingLinks;
      }
      mapHistorySnapshot.set(key, historySnapshotItem);

      historySnapshotItem.name = objectVersion.name;
      historySnapshotItem.versionId = objectVersion.versionId;
      historySnapshotItem.createdAt = objectVersion.createdAt;
      historySnapshotItem.majorVersion = objectVersion.majorVersion;
      historySnapshotItem.minorVersion = objectVersion.minorVersion;
      historySnapshotItem.rmpForModelType = objectVersion.rmpForModelType;
      historySnapshotItem.outgoingLinks = objectVersion.outgoingLinks;
      historySnapshotItem.currentState = objectVersion.currentState;
      historySnapshotItem.approved = objectVersion.approved;
      historySnapshotItem.archived = objectVersion.archived;
      historySnapshotItem.restored = objectVersion.restored;
      historySnapshotItem.affectedRiskTypes = affectedRiskTypes;
      historySnapshotItem.parent = objectVersion.parent;
      historySnapshotItem.unitOperationId = objectVersion.unitOperationId;
      historySnapshotItem.stepId = objectVersion.stepId;
      historySnapshotItem.controlStrategy = objectVersion.controlStrategy;
      historySnapshotItem.controlMethods = objectVersion.controlMethods ?? [];
      historySnapshotItem.riskInfo = objectVersion.riskInfo;
      historySnapshotItem.effectiveRMP = rmpVersions.find(rmpVersion => rmpVersion.id === objectVersion.effectiveRMPVersionId);

      if (objectVersion.processCapabilities) {
        historySnapshotItem.processCapabilities = objectVersion.processCapabilities;
      }

      fillModelSpecificAttributes(objectVersion.type, historySnapshotItem, objectVersion);

      if (rmpVersions && objectVersion.type !== "TPP" && objectVersion.type !== "GA") {
        historySnapshotItem.impact = objectVersion.impact;
        historySnapshotItem.uncertainty = objectVersion.uncertainty;
        historySnapshotItem.capabilityRisk = objectVersion.capabilityRisk;
        historySnapshotItem.detectabilityRisk = objectVersion.detectabilityRisk;
        historySnapshotItem.rawCriticality = objectVersion.rawCriticality;
        historySnapshotItem.rawProcessRisk = objectVersion.rawProcessRisk;
        historySnapshotItem.rawRPN = objectVersion.rawRPN;
        historySnapshotItem.criticality = objectVersion.criticality;
        historySnapshotItem.processRisk = objectVersion.processRisk;
        historySnapshotItem.RPN = objectVersion.RPN;
      } else {
        if (objectVersion.impact) {
          historySnapshotItem.impact = objectVersion.impact;
        }
        if (objectVersion.uncertainty) {
          historySnapshotItem.uncertainty = objectVersion.uncertainty;
        }
        if (objectVersion.capabilityRisk) {
          historySnapshotItem.capabilityRisk = objectVersion.capabilityRisk;
        }
        if (objectVersion.detectabilityRisk) {
          historySnapshotItem.detectabilityRisk = objectVersion.detectabilityRisk;
        }
      }

      if (objectVersion.obligatoryCQA) {
        historySnapshotItem.obligatoryCQA = objectVersion.obligatoryCQA;
      }

      if (objectVersion.parent) {
        historySnapshotItem.parent = objectVersion.parent;
      }

      let historyPoint = {
        uniqueId: index,
        type: objectVersion.type,
        id: objectVersion.id,
        date: new Date(mapKey),
        historySnapshot: shallowCopyHistorySnapshot(mapHistorySnapshot),
        versionId: objectVersion.versionId,
        versionTransitionId: objectVersion.versionTransitionId,
        majorVersion: objectVersion.majorVersion,
        minorVersion: objectVersion.minorVersion,
        name: objectVersion.name,
        currentState: objectVersion.currentState,
        controlStrategy: objectVersion.controlStrategy,
        controlMethods: objectVersion.controlMethods,
        approved: objectVersion.approved,
        archived: objectVersion.archived,
        restored: objectVersion.restored,
        changes: changes.changesDescription,
        rmpForModelType: objectVersion.rmpForModelType,
        affectedRiskTypes: affectedRiskTypes,
        unitOperationVersions: orderedUOVersionsList,
        unitOperationVersionsMap: uoVersionsMap,
        stepVersions: orderedStepVersionsList,
        stepVersionsMap: stepVersionsMap,
        parent: objectVersion.parent,
        createdAt: objectVersion.createdAt,
        processCapabilities: objectVersion.processCapabilities,
        riskInfo: objectVersion.riskInfo
      };

      mapHistory.historyPoints.push(historyPoint);
    }
    index++;
  }

  mapHistory.unitOperationVersions = unitOperationVersions;
  mapHistory.stepVersions = stepVersions;
  return mapHistory;
}

export function findAndOrderUOVersionsForDate(allUOVersions, date) {
  date = date ? date : new Date();
  let uoVersionsForDate = Object.keys(allUOVersions).map(id => {
    let uoVersionForDate = allUOVersions[id].find(uoVersion => uoVersion.from < date && (!uoVersion.to || uoVersion.to >= date));
    return uoVersionForDate ? uoVersionForDate.unitOperation : null;
  }).filter(uo => !!uo && !uo.deletedAt);
  let orderedUOVersionsList = orderAndIndexUnits(uoVersionsForDate, "unitOperationId");
  //Add archived UOs to the list at the end of the ordered list, since orderAndIndexUnits will exclude these
  orderedUOVersionsList = orderedUOVersionsList.concat(uoVersionsForDate.filter(uo => {
    return !orderedUOVersionsList.includes(uo);
  }));
  let order = 1;
  for (let unitOperationVersion of orderedUOVersionsList) {
    unitOperationVersion.order = order++;
  }
  return uoVersionsForDate;
}

export function findAndOrderStepVersionsForDate(allStepVersions, date) {
  date = date ? date : new Date();
  let stepVersionsForDate = Object.keys(allStepVersions).map(id => {
    let stepVersionForDate = allStepVersions[id].find(stepVersion => stepVersion.from < date && (!stepVersion.to || stepVersion.to >= date));
    return stepVersionForDate ? stepVersionForDate.step : null;
  }).filter(step => !!step && !step.deletedAt);

  // group steps by UO.
  let stepsbyUO = {};
  if (stepVersionsForDate && stepVersionsForDate.length > 0) {
    stepsbyUO = stepVersionsForDate.reduce((stepsbyUO, step) => {
      if (!stepsbyUO[step.unitOperationId]) {
        stepsbyUO[step.unitOperationId] = [step];
      } else {
        stepsbyUO[step.unitOperationId].push(step);
      }
      return stepsbyUO;
    }, {});
  }

  let resultVersionsForDate = [];
  for (let key of Object.keys(stepsbyUO)) {
    let steps = stepsbyUO[key];
    let stepVersionsList = orderAndIndexSteps(steps, "stepId", true);
    let order = 1;
    for (let stepVersion of stepVersionsList) {
      stepVersion.order = order++;
    }
    resultVersionsForDate.push(...stepVersionsList);
  }
  return resultVersionsForDate;

}

export function shouldRender(historySnapshotItem, filters) {
  if (!filters) {
    return false;
  }

  switch (historySnapshotItem.type) {
    case "GA":
      return filters.riskTracedToFilter === "traceToGeneralAttributes";
    case "TPP":
      return filters.riskTracedToFilter === "traceToTPPs";
    default:
      return true;
  }
}

export function shouldRenderFullColor(rmp, historySnapshotItem, filters, selectedTypeaheadOptions, scope, riskType) {
  if (!filters) {
    return false;
  }

  let showItem = true;
  let {outgoingLinks} = historySnapshotItem;
  const {incomingLinks} = historySnapshotItem;

  if (FINAL_ATTRIBUTE_TYPE_CODES.includes(historySnapshotItem.type) && filters.linksFilter !== "all") {
    if (filters.riskTracedToFilter === "traceToTPPs") {
      outgoingLinks = outgoingLinks.filter(link => link.type === "TPP");
    } else {
      outgoingLinks = outgoingLinks.filter(link => link.type === "GA");
    }
  }

  const linksFilterCondition = ((filters.linksFilter === "all") || //And no links filter is specified
    ((filters.linksFilter === "onlyLinked") && ((outgoingLinks.length + incomingLinks.length) > 0)) || //Or show only linked is specified and the item has at least one incoming or outgoing link
    ((filters.linksFilter === "onlyUnlinked") && ((outgoingLinks.length + incomingLinks.length) === 0))); //Or show only unlinked is specified and the item does not have any links

  switch (scope) {
    case "map":
      showItem = (selectedTypeaheadOptions.length === 0 ||
          (selectedTypeaheadOptions.find(option => { //Or it is specified and the rendered item is selected in the search criteria
            return (option.objId === historySnapshotItem.id) &&
              (option.type === historySnapshotItem.type);
          }) !== undefined))
        && linksFilterCondition
        && !historySnapshotItem.archived;
      break;
    case "timeline":
      showItem = (selectedTypeaheadOptions.length === 0 ||
        (selectedTypeaheadOptions.find(option => { //Or it is specified and the rendered item is selected in the search criteria
          return (option.objId === historySnapshotItem.id) &&
            (option.type === historySnapshotItem.type);
        }) !== undefined));
      break;
    case "typeahead":
      showItem = linksFilterCondition
        && !historySnapshotItem.archived;
      break;
  }

  switch (historySnapshotItem.type) {
    case "FQA":
      showItem = showItem && filters.showFQAs;
      break;
    case "FPA":
      showItem = showItem && filters.showFPAs;
      break;
    case "TPP":
      showItem = showItem && filters.showTPPs;
      break;
    case "GA":
      showItem = showItem && filters.showGeneralAttributes;
      break;
    case "IQA":
      showItem = showItem && filters.showIQAs;
      break;
    case "IPA":
      showItem = showItem && filters.showIPAs;
      break;
    case "PP":
      showItem = showItem && filters.showPPs;
      break;
    case "MA":
      showItem = showItem && filters.showMAs;
      break;
  }

  let effectiveRMPForModelType = CommonRiskUtils.filterRMPByType(rmp, historySnapshotItem.type);
  if (historySnapshotItem.type === "TPP" || historySnapshotItem.type === "GA") {
    for (let riskScale of getRiskScales(riskType, effectiveRMPForModelType)) {
      let filterName = getRiskFilterNameFromRiskScale(riskScale, false, showByRiskLabel(filters, riskType));
      showItem = showItem && filters[filterName];
      showItem = showItem && filters[getRiskFilterNameFromRiskScale(null)];
    }
  } else {
    let riskScale = historySnapshotItem.riskInfo[riskType].scale;
    if (riskType === RISK_TYPE_ENUM.CRITICALITY && showByRiskLabel(filters, riskType)) {
      riskScale = historySnapshotItem.riskInfo[riskType].scaleForRiskLabel;
    }

    let filterName = getRiskFilterNameFromRiskScale(riskScale, false, showByRiskLabel(filters, riskType));

    showItem = showItem && (filters[filterName]);
  }

  if (historySnapshotItem.unitOperationId) {
    showItem = showItem && (filters[`uo_${historySnapshotItem.unitOperationId}`]);
  }

  const parent = historySnapshotItem.parent;

  if (parent && parent.type === "MT" && parent.category) {
    showItem = showItem && (filters[`mt_${UIUtils.convertToId(parent.category)}`]);
  }

  return showItem;
}

/**
 * This gets a list of control strategies specified for a given node in the risk map report, based on the user filters
 * in the filter panel and the search panel. The tooltip should contain the control
 * strategy section for all nodes that are not of TPP or GA type and when the user has selected to view control
 * strategy information from the filter panel and at least one attribute is being included in the search panel.
 * Also, if the control strategy of any node includes 'Release Testing' then by default we should show for that node
 * the control strategy section of the Tooltip, no matter of the previous user selections.
 * @param controlStrategyOptions
 */
export function getControlStrategiesToShowForNode(controlStrategyOptions) {
  let returnValue = [];
  const {
    node,
    filters,
    nodeHighlightedInRiskMap,
    selectedTypeaheadOptions,
    isForTooltip
  } = controlStrategyOptions;
  const userIsSearching = selectedTypeaheadOptions && selectedTypeaheadOptions.length > 0;

  const controlStrategyField = node?.controlStrategy || "[]";
  let controlStrategies = controlStrategyField.startsWith("[") && JSON.parse(controlStrategyField);

  if ((controlStrategies?.length > 0
      && filters.showControlStrategy
      && nodeHighlightedInRiskMap
      && userIsSearching)
    || isForTooltip) {
    returnValue = controlStrategies;
  }

  return returnValue;
}

export function applyStreamFilters(node, nodesMap, showUpstream, showDownstream, redrawCallback) {
  if (!(showUpstream || showDownstream)) {
    return;
  }

  node.forceRenderFullColor = true;
  redrawCallback(node);

  if (showUpstream && !node.upstreamProcessed) {
    node.upstreamProcessed = true;
    for (let link of node.incomingLinks) {
      let nodeKey = link.type + "-" + link.id;
      applyStreamFilters(nodesMap.get(nodeKey), nodesMap, true, false, redrawCallback);
    }
  }

  if (showDownstream && !node.downstreamProcessed) {
    node.downstreamProcessed = true;
    for (let link of node.outgoingLinks) {
      let nodeKey = link.type + "-" + link.id;
      applyStreamFilters(nodesMap.get(nodeKey), nodesMap, false, true, redrawCallback);
    }
  }
}

export function getLastApprovedDate(historyPoints, visibleNodeKeys) {
  let lastApprovedDate = null;
  for (let i = historyPoints.length - 1; i >= 0; i--) {
    const historyPoint = historyPoints[i];
    let approved = true;
    for (let j = 0; j < visibleNodeKeys.length; j++) {
      const key = visibleNodeKeys[j];
      approved = approved && historyPoint.historySnapshot.has(key) && historyPoint.historySnapshot.get(key).approved;
    }
    if (approved) {
      lastApprovedDate = historyPoint.date;
      break;
    }
  }

  return lastApprovedDate;
}

export function isHistoryPointApproved(historyPoint, visibleNodeKeys) {
  let approved = !!historyPoint;
  for (let j = 0; j < visibleNodeKeys.length; j++) {
    const key = visibleNodeKeys[j];
    approved = approved && historyPoint.historySnapshot.has(key) && historyPoint.historySnapshot.get(key).approved;
  }

  return approved;
}

export function getHistoryPointForDate(date, mapHistory) {
  let projectCreationDate = new Date(mapHistory.project.createdAt);
  let filteredHistoryPoints = mapHistory.historyPoints.filter(point => {
    return projectCreationDate.getTime() <= date.getTime() &&
      (point.date.getTime() <= date.getTime() ||
        ((point.type === "TPP" || point.type === "GA") && point.majorVersion === 0 && point.minorVersion === 1));
  });

  return filteredHistoryPoints.length > 0 ? filteredHistoryPoints[filteredHistoryPoints.length - 1] : null;
}

function addObjectsToMap(data, sortedByDateVersions, type, objectVersions, objectIdProperty, versionTransitionsProperty,
                         unitOperationVersions, linksCollectionName, linkedObjectIdProperty, linkType, rmpVersions, projectWithAllVersions) {
  //Add the objects to the report
  for (let i = 0; i < objectVersions.length; i++) {
    let objectVersion = objectVersions[i];
    let createdAt = new Date(objectVersion.createdAt);

    let outgoingLinks = [];
    if (linksCollectionName) {
      for (let i = 0; i < linksCollectionName.length; i++) {
        const currentLinkType = linkType[i];
        const currentLinkedObjectIdProperty = linkedObjectIdProperty[i];
        const objectOutgoingLinks = objectVersion[linksCollectionName[i]];

        // Ensures a meaningful error message is thrown if the array is invalid
        if (typeof (objectOutgoingLinks) === "undefined" || objectOutgoingLinks === null) {
          const message = `The list of outgoing links for object ${objectVersion.name} must be a non-null array.`;
          console.error(message, JSON.stringify(objectOutgoingLinks));
          throw new Error(message);
        }

        outgoingLinks = outgoingLinks.concat((objectOutgoingLinks).map(link => {
          const currentID = link[currentLinkedObjectIdProperty];

          return {
            type: currentLinkType,
            id: currentID,
            name: getNameFromId(data, currentID, currentLinkType),
            impact: link.impact,
            uncertainty: link.uncertainty,
            effect: link.effect,
          };
        }));
      }
    }

    let isApproved = function() {
      return this.currentState === UIUtils.VERSION_STATES.APPROVED || this.currentState === UIUtils.VERSION_STATES.OBSOLETE;
    };
    let isArchived = function() {
      return this.currentState === UIUtils.VERSION_STATES.ARCHIVED || this.currentState === UIUtils.VERSION_STATES.ARCHIVED_CASCADED;
    };
    let isRestored = function() {
      return this.currentState === UIUtils.VERSION_STATES.RESTORED || this.currentState === UIUtils.VERSION_STATES.RESTORED_CASCADED;
    };

    let riskMapVersionObject = {
      type,
      id: objectVersion[objectIdProperty],
      createdAt: objectVersion.createdAt,
      versionId: objectVersion.id,
      versionTransitionId: objectVersion[versionTransitionsProperty][0].id,
      majorVersion: objectVersion.majorVersion,
      minorVersion: objectVersion.minorVersion,
      name: objectVersion.name,
      unitOperationId: objectVersion.UnitOperationId,
      stepId: objectVersion.StepId,
      controlStrategy: objectVersion.controlStrategy,
      controlMethods: objectVersion.controlMethods,
      outgoingLinks,
      incomingLinks: [],
      currentState: objectVersion[versionTransitionsProperty][0].transition,
      rmpForModelType: rmpVersions.find(rmpVersion => rmpVersion.id === objectVersion.effectiveRMPVersionId),
      riskInfo: objectVersion.riskInfo,
      get approved() {
        return isApproved.bind(this)();
      },
      get isArchived() {
        return isApproved.bind(this)();
      },
      get isRestored() {
        return isApproved.bind(this)();
      }
    };

    fillModelSpecificAttributes(type, riskMapVersionObject, objectVersion);

    if (rmpVersions && (type !== "TPP" && type !== "GA")) {
      riskMapVersionObject.impact = riskMapVersionObject.riskInfo[RISK_TYPE_ENUM.IMPACT].value;
      riskMapVersionObject.uncertainty = riskMapVersionObject.riskInfo[RISK_TYPE_ENUM.UNCERTAINTY].value;
      riskMapVersionObject.capabilityRisk = riskMapVersionObject.riskInfo[RISK_TYPE_ENUM.CAPABILITY_RISK].value;
      riskMapVersionObject.detectabilityRisk = riskMapVersionObject.riskInfo[RISK_TYPE_ENUM.DETECTABILITY_RISK].value;
      riskMapVersionObject.rawCriticality = riskMapVersionObject.riskInfo[RISK_TYPE_ENUM.CRITICALITY].value;
      riskMapVersionObject.rawProcessRisk = riskMapVersionObject.riskInfo[RISK_TYPE_ENUM.PROCESS_RISK].value;
      riskMapVersionObject.rawRPN = riskMapVersionObject.riskInfo[RISK_TYPE_ENUM.RPN].value;
      riskMapVersionObject.criticality = riskMapVersionObject.riskInfo[RISK_TYPE_ENUM.CRITICALITY].normalizedValue;
      riskMapVersionObject.processRisk = riskMapVersionObject.riskInfo[RISK_TYPE_ENUM.PROCESS_RISK].normalizedValue;
      riskMapVersionObject.RPN = riskMapVersionObject.riskInfo[RISK_TYPE_ENUM.RPN].normalizedValue;
    }

    if (type === "FQA") {
      riskMapVersionObject.obligatoryCQA = objectVersion.obligatoryCQA;
    }

    if (objectVersion.ProcessComponent) {
      riskMapVersionObject.parent = {
        type: "PRC",
        typeName: "Component",
        id: objectVersion.ProcessComponent.id,
        name: objectVersion.ProcessComponent.name,
      };
    }

    if (objectVersion.Material) {
      riskMapVersionObject.parent = {
        type: "MT",
        typeName: "Material",
        id: objectVersion.Material.id,
        name: objectVersion.Material.name,
        category: objectVersion.Material.category,
      };
    }

    if (objectVersion.ControlMethods) {
      riskMapVersionObject.controlMethods = objectVersion.ControlMethods.map(cm => cm.name);
    }

    if (objectVersion.processCapabilities) {
      riskMapVersionObject.processCapabilities = objectVersion.processCapabilities;
    }

    addObjectVersionToMap(sortedByDateVersions, createdAt, riskMapVersionObject);

    for (let versionTransition of objectVersion[versionTransitionsProperty].filter(versionTransition => {
      return versionTransition.transition === UIUtils.VERSION_STATES.ARCHIVED
        || versionTransition.transition === UIUtils.VERSION_STATES.ARCHIVED_CASCADED
        || versionTransition.transition === UIUtils.VERSION_STATES.RESTORED
        || versionTransition.transition === UIUtils.VERSION_STATES.RESTORED_CASCADED;
    })) {
      let riskMapVersionTransitionObject = Object.assign({}, riskMapVersionObject);
      riskMapVersionTransitionObject.versionTransitionId = versionTransition.id;
      riskMapVersionTransitionObject.currentState = versionTransition.transition;
      riskMapVersionTransitionObject.outgoingLinks = outgoingLinks;
      riskMapVersionTransitionObject.incomingLinks = [];
      riskMapVersionTransitionObject.createdAt = versionTransition.createdAt;
      Object.defineProperty(riskMapVersionTransitionObject, "approved", {get: isApproved.bind(riskMapVersionTransitionObject)});
      Object.defineProperty(riskMapVersionTransitionObject, "archived", {get: isArchived.bind(riskMapVersionTransitionObject)});
      Object.defineProperty(riskMapVersionTransitionObject, "restored", {get: isRestored.bind(riskMapVersionTransitionObject)});

      addObjectVersionToMap(sortedByDateVersions, new Date(versionTransition.createdAt), riskMapVersionTransitionObject);
    }
  }
}

function getNameFromId(data, id, type) {
  let objectsCollection;
  let idProperty;
  switch (type) {
    case "FQA":
      objectsCollection = data.fqaVersions;
      idProperty = "FQAId";
      break;
    case "FPA":
      objectsCollection = data.fpaVersions;
      idProperty = "FPAId";
      break;
    case "IQA":
      objectsCollection = data.iqaVersions;
      idProperty = "IQAId";
      break;
    case "IPA":
      objectsCollection = data.ipaVersions;
      idProperty = "IPAId";
      break;
    case "TPP":
      objectsCollection = data.tppVersions;
      idProperty = "TPPSectionId";
      break;
    case "GA":
      objectsCollection = data.gaVersions;
      idProperty = "GeneralAttributeId";
      break;
    case "MA":
      objectsCollection = data.maVersions;
      idProperty = "MaterialAttributeId";
      break;
    case "PP":
      objectsCollection = data.ppVersions;
      idProperty = "ProcessParameterId";
      break;
  }

  const item = objectsCollection.find(obj => {
    return obj[idProperty] === id;
  });
  return item ? item.name : null;
}

function shallowCopyHistorySnapshot(mapHistorySnapshot) {
  return new Map(mapHistorySnapshot);
}

function setIncomingLinks(mapHistorySnapshot, objectVersion, linksModifications) {
  // Add new incoming links
  for (let i = 0; i < linksModifications.addedLinks.length; i++) {
    let newLink = linksModifications.addedLinks[i];
    let key = newLink.type + "-" + newLink.id;
    let historySnapshotItem = mapHistorySnapshot.get(key);
    if (historySnapshotItem) {
      historySnapshotItem = UIUtils.deepClone(historySnapshotItem);
      historySnapshotItem.incomingLinks.push({
        type: objectVersion.type,
        id: objectVersion.id,
        name: objectVersion.name,
        stepId: objectVersion.stepId,
        unitOperationId: objectVersion.unitOperationId,
      });
      mapHistorySnapshot.set(key, historySnapshotItem);
    }
  }

  // Remove any deleted link from the incoming links as well
  for (let i = 0; i < linksModifications.removedLinks.length; i++) {
    let removedLink = linksModifications.removedLinks[i];
    let key = removedLink.type + "-" + removedLink.id;
    let historySnapshotItem = mapHistorySnapshot.get(key);
    if (historySnapshotItem) {
      let indexOfLink = historySnapshotItem.incomingLinks.findIndex(link => link.type === objectVersion.type && link.id === objectVersion.id);
      if (indexOfLink >= 0) {
        historySnapshotItem = UIUtils.deepClone(historySnapshotItem);
        historySnapshotItem.incomingLinks.splice(indexOfLink, 1);
        mapHistorySnapshot.set(key, historySnapshotItem);
      }
    }
  }
}

/**
 * Retrieves both the raw and normalized risk scores for the specified risk type in the specified object.
 * @param riskType The risk type
 * @param entity The entity that contains the risk information.
 * @returns {{rawRiskScore: *, normalizedRiskScore: *}} An object containing the raw and normalized scores.
 */
export function getRiskInfo(riskType, entity) {
  const {riskAttribute, rawRiskAttribute} = getRiskAttributes(riskType);
  return {
    rawRiskScore: entity[rawRiskAttribute],
    normalizedRiskScore: entity[riskAttribute],
  };
}

/**
 * Retrieves the CSS class equivalent for input color
 * @param color The color to get CSS class for
 * @returns {string} CSS class name
 */
export function getCSSClassForLegend(color) {
  switch (color) {
    case RISK_COLORS.BLUE:
      return "risk-map-negligible";
    case RISK_COLORS.GREEN:
      return "risk-map-minor";
    case RISK_COLORS.YELLOW:
      return "risk-map-moderate";
    case RISK_COLORS.ORANGE:
      return "risk-map-critical";
    case RISK_COLORS.GREY:
      return "risk-map-not-assessed";
    case RISK_COLORS.RED:
      return "risk-map-catastrophic";
    default:
      return "risk-map-not-evaluated";
  }
}

/**
 * Retrieves the names of the normalized and raw risk attributes for the specified risk type..
 * @param riskType The type of risk to retrieve the attributes for.
 * @returns {{normalizedRiskAttribute: string, rawRiskAttribute: string}} An object with the names of the used attributes for the specified risk type
 */
export function getRiskAttributes(riskType) {
  let normalizedRiskAttribute, rawRiskAttribute;
  switch (riskType) {
    case RISK_TYPE_ENUM.RPN:
      normalizedRiskAttribute = "RPN";
      rawRiskAttribute = "rawRPN";
      break;
    case RISK_TYPE_ENUM.CRITICALITY:
    case RISK_TYPE_ENUM.PROCESS_RISK:
      normalizedRiskAttribute = UIUtils.convertToCamelCaseId(riskType);
      rawRiskAttribute = UIUtils.convertToCamelCaseId("raw" + riskType);
      break;
    default:
      normalizedRiskAttribute = UIUtils.convertToCamelCaseId(riskType);
      rawRiskAttribute = UIUtils.convertToCamelCaseId(riskType);
      break;
  }

  return {
    normalizedRiskAttribute,
    rawRiskAttribute,
  };
}

function fillModelSpecificAttributes(typeCode, historyItem, objectVersion) {

  const fillRiskSupportDocuments = (typeCode) => {
    const typeCodeToModel = new Map([
      ["PP", "ProcessParameter"],
      ["MA", "MaterialAttribute"]
    ]);
    // eslint-disable-next-line no-case-declarations
    const models = ["FQA", "IQA"];
    const supportDocuments = [];
    for (let model of models) {
      const riskLinks = objectVersion[`${typeCodeToModel.get(typeCode)}To${model}LinkedVersions`];
      if (riskLinks) {
        const links = riskLinks
          .flatMap(rLink => JSON.parse(rLink.links))
          .map(dLink => dLink.linkType === "Link" ? `<a href="${dLink.link}">${dLink.link}</a>` : dLink.fileName);
        supportDocuments.push(...links);
      }
    }
    return [...new Set([...supportDocuments])].sort();
  };

  const {supportDocuments = []} = objectVersion;
  switch (typeCode) {
    // eslint-disable-next-line no-fallthrough
    case "PP":
      // eslint-disable-next-line no-case-declarations
      const {type, processParameterType} = objectVersion;
      historyItem.processParameterType = (typeCode === type)
        ? (
          (processParameterType !== EMPTY_STRING) ? processParameterType : "NA"
        ) : (
          (type !== EMPTY_STRING) ? type : "NA"
        );
    // eslint-disable-next-line no-fallthrough
    case "MA":
      historyItem.supportDocuments = (supportDocuments.length === 0) ? fillRiskSupportDocuments(typeCode) : supportDocuments;
      historyItem.riskControlLinks = objectVersion.riskControlLinks;
      historyItem.acceptanceCriteriaLinks = objectVersion.acceptanceCriteriaLinks;
      break;
  }
}

function addObjectVersionToMap(sortedByDateVersions, date, objectVersion) {
  if (sortedByDateVersions.has(date.getTime())) {
    while (sortedByDateVersions.has(date.getTime())) {
      date.setMilliseconds(date.getMilliseconds() + 10);
    }
  }

  sortedByDateVersions.set(date.getTime(), objectVersion);
}

export function getApprovedStateWarning(allItemsInApprovedState) {
  return (allItemsInApprovedState ? "All items of this exported report are in an Approved State." : "Not all items of this exported report are in an Approved State.");
}

export function showByRiskLabel(filters, riskType) {
  if (riskType === RISK_TYPE_ENUM.IMPACT || riskType === RISK_TYPE_ENUM.UNCERTAINTY ||
    riskType === RISK_TYPE_ENUM.DETECTABILITY_RISK || riskType === RISK_TYPE_ENUM.CAPABILITY_RISK) {
    return false;
  }
  return typeof filters === "string" ? filters === RISK_LABEL_ACTION.SHOW_RISK_LABEL : filters["riskLabelTypeFilter"] === RISK_LABEL_ACTION.SHOW_RISK_LABEL;
}
