"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import { can, generateTooltip } from "../utils/ui_permissions";
import CommonSecurity from "../../server/common/generic/common_security";
import { ACTION_TO_ICON_ENUM } from "../helpers/constants/constants";

/**
 *  This class is responsible for figuring out which View/Edit/Archive/Restore/Copy buttons should show on a table or
 *  in the process explorer.
 */
// i18next-extract-mark-ns-start base_page
export default class OptionsMenuBuilder {
  /**
   * @param record {{}} An object with at least the `deletedAt` and `currentState` properties (ideally with isParentArchived and isProjectArchived too).
   * @param securityTypeName {string} The type name that will be passed into can() and generatedTooltip() in ui_permissions.
   * @param project {{}} The project that this record belongs to, or null if it's a top level record (ex. Document, Supplier, etc).
   * @param process The process that this record belongs to, or null if it's a top level record (ex. Document, Supplier, etc).
   * @param onCheckPermissions {function} A function called when checking for permissions (see UIPermissions.can() and
   *                                      UIPermissions.generateTooltip())
   * @param t {function} For translation
   */
  constructor(record, securityTypeName, project, process, onCheckPermissions, t) {
    this.record = record;
    this.project = project;
    this.process = process;
    this.securityTypeName = securityTypeName;
    this.onCheckPermissions = onCheckPermissions;
    this.t = t;
    this.options = [];

    this.currentState = record && record.LastVersion ?
      record.LastVersion.currentState : record.currentState;
  }

  /**
   * Add a view button.
   *
   * @param additionalParameters use this to add an id, a url (to go to when clicked on), or an onClick method
   */
  addView(additionalParameters = {}) {
    this.options.push(
      {
        action: ACTION_TO_ICON_ENUM.VIEW,
        title: this.t("View"),
        ...additionalParameters,
      }
    );
  }

  /**
   * Add an edit button.
   *
   * @param additionalParameters use this to add an id, a url (to go to when clicked on), or an onClick method
   */
  addEdit(additionalParameters = {}) {
    if (this.isTopLevelOrInAValidProject()
      && !(this.record.deletedAt || this.record.isParentArchived || this.record.isProjectArchived)
      && this.currentState
      && !this.currentState.startsWith(UIUtils.VERSION_STATES.PROPOSED)
      && this.currentState !== UIUtils.VERSION_STATES.ROUTING) {
      this.options.push({
        action: ACTION_TO_ICON_ENUM.EDIT,
        title: this.t(generateTooltip(CommonSecurity.Actions.EDIT, this.securityTypeName, this.record, additionalParameters.tooltipIfAccessAllowed, this.onCheckPermissions)),
        ...additionalParameters,
        disabled: !can(CommonSecurity.Actions.EDIT, this.securityTypeName, this.record, this.onCheckPermissions) || additionalParameters.disabled,
      });
    }
  }

  isTopLevelOrInAValidProject() {
    return (!this.project || !this.project.deletedAt)
      && (!this.process || !this.process.deletedAt || this.securityTypeName === "Process");
  }

  /**
   * Add an archive button.
   *
   * @param additionalParameters use this to add an id, a url (to go to when clicked on), or an onClick method
   */
  addArchive(additionalParameters = {}) {
    if (this.isTopLevelOrInAValidProject()
      && !(this.record.deletedAt || this.record.isParentArchived || this.record.isProjectArchived)
      && this.currentState
      && !this.currentState.startsWith(UIUtils.VERSION_STATES.PROPOSED)
      && this.currentState !== UIUtils.VERSION_STATES.ROUTING) {
      const {
        disabled,
        title
      } = this.getDisabledAndTitle(CommonSecurity.Actions.PROPOSE_FOR_ARCHIVE, additionalParameters);
      this.options.push({
        action: ACTION_TO_ICON_ENUM.ARCHIVE,
        ...additionalParameters,
        disabled,
        title,
      });
    }
  }

  /**
   * Add a propose button.
   *
   * @param additionalParameters use this to add an id, a url (to go to when clicked on), or an onClick method
   */
  addPropose(additionalParameters = {}) {
    if (this.isTopLevelOrInAValidProject()
      && !(this.record.deletedAt || this.record.isParentArchived || this.record.isProjectArchived)
      && this.currentState
      && !this.currentState.startsWith(UIUtils.VERSION_STATES.PROPOSED)
      && this.currentState !== UIUtils.VERSION_STATES.ROUTING) {
      const {
        disabled,
        title
      } = this.getDisabledAndTitle(CommonSecurity.Actions.PROPOSE, additionalParameters);
      this.options.push({
        action: ACTION_TO_ICON_ENUM.PROPOSE,
        ...additionalParameters,
        disabled,
        title,
      });
    }
  }

  /**
   * Add a separator in the list.
   */
  addSeparator() {
    this.options.push({action: ACTION_TO_ICON_ENUM.SEPARATOR});
  }

  /**
   * Add a copy button.
   *
   * @param additionalParameters use this to add an id, a url (to go to when clicked on), or an onClick method
   */
  addCopy(additionalParameters) {
    if (this.isTopLevelOrInAValidProject()
      && !(this.record.isParentArchived || this.record.isProjectArchived)
    ) {
      const {disabled, title} = this.getDisabledAndTitle(CommonSecurity.Actions.ADD, additionalParameters);
      this.options.push({
        action: ACTION_TO_ICON_ENUM.COPY,
        ...additionalParameters,
        disabled,
        title,
      });
    }
  }

  /**
   * Add a restore button.
   *
   * @param additionalParameters use this to add an id, a url (to go to when clicked on), or an onClick method
   */
  addRestore(additionalParameters) {
    if (this.isTopLevelOrInAValidProject()
      && this.currentState
      && (this.record.deletedAt || this.currentState === UIUtils.VERSION_STATES.ARCHIVED)
      && this.currentState !== UIUtils.VERSION_STATES.PROPOSED_FOR_RESTORE) {
      let {
        disabled,
        title
      } = this.getDisabledAndTitle(CommonSecurity.Actions.PROPOSE_FOR_RESTORE, additionalParameters);
      if (!disabled && this.currentState.endsWith(" (Cascaded)")) {
        disabled = true;
        title = "Cascaded records cannot be restored. Restore the higher level object that was originally archived.";
      }
      this.options.push({
        action: ACTION_TO_ICON_ENUM.RESTORE,
        ...additionalParameters,
        disabled,
        title,
      });
    }
  }

  /**
   * Get the final list of options that can be passed to TableOptionsButton.
   *
   * @return {[]}
   */
  build() {
    return this.options;
  }

  getDisabledAndTitle(actionRequired, additionalParameters) {
    let title;
    let disabled = !can(actionRequired, this.securityTypeName, this.record, this.onCheckPermissions);
    if (disabled) {
      title = this.t(generateTooltip(actionRequired, this.securityTypeName, this.record, null, this.onCheckPermissions));
    } else if (additionalParameters.disabled) {
      disabled = additionalParameters.disabled;
      title = additionalParameters.tooltipIfDisabled;
    } else {
      title = additionalParameters.tooltipIfAccessAllowed;
    }

    return {disabled, title};
  }
}
// i18next-extract-mark-ns-stop base_page
