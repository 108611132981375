var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Draggable } from '@progress/kendo-react-common';
import { Button } from '@progress/kendo-react-buttons';
import { ungroupColumn, messages } from '../messages';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
/**
 * @hidden
 */
var GroupingIndicator = /** @class */ (function (_super) {
    __extends(GroupingIndicator, _super);
    function GroupingIndicator() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.draggable = null;
        /**
         * @hidden
         */
        _this.onPress = function (data) {
            var element = _this.draggable && _this.draggable.element;
            if (element && _this.props.onPress) {
                _this.props.onPress(data.event, element);
            }
        };
        /**
         * @hidden
         */
        _this.onDrag = function (data) {
            var element = _this.draggable && _this.draggable.element;
            if (element && _this.props.onDrag) {
                _this.props.onDrag(data.event, element);
            }
        };
        /**
         * @hidden
         */
        _this.onRelease = function (data) {
            var element = _this.draggable && _this.draggable.element;
            if (element && _this.props.onRelease) {
                _this.props.onRelease(data.event);
            }
        };
        _this.sortChange = function (event) {
            event.preventDefault();
            if (_this.props.onSortChange) {
                var sort = (_this.props.dir === 'asc') ? 'desc' : 'asc';
                _this.props.onSortChange(event, sort);
            }
        };
        _this.groupRemove = function (event) {
            event.preventDefault();
            if (_this.props.onRemove) {
                _this.props.onRemove(event);
            }
        };
        return _this;
    }
    GroupingIndicator.prototype.render = function () {
        var _this = this;
        var localizationService = provideLocalizationService(this);
        return (React.createElement(Draggable, { onPress: this.onPress, onDrag: this.onDrag, onRelease: this.onRelease, ref: function (component) { _this.draggable = component; } },
            React.createElement("div", { className: "k-indicator-container", style: { touchAction: 'none' } },
                React.createElement("div", { className: "k-group-indicator" },
                    React.createElement("a", { className: "k-link", href: "#", tabIndex: -1, onClick: this.sortChange },
                        React.createElement("span", { className: 'k-icon k-i-sort-' + this.props.dir + '-small' }),
                        this.props.title),
                    React.createElement(Button, { fillMode: 'flat', tabIndex: -1, onClick: this.groupRemove, icon: 'close', "aria-label": localizationService.toLanguageString(ungroupColumn, messages[ungroupColumn]) })))));
    };
    return GroupingIndicator;
}(React.Component));
export { GroupingIndicator };
registerForLocalization(GroupingIndicator);
