"use strict";

import React, { Fragment } from "react";
import TrainingReportBase from "./canned_report_training_base";
import * as CannedReportTrainingFilter from "./canned_report_training_filter";
import { TRAINING_STATUS } from "../../data_transform/canned_reports/training/constants/constants";

/* This class shows Training Matrix canned report */
export default class TrainingMatrixReport extends TrainingReportBase {
  constructor(props) {
    super(props);
  }

  get defaultFilterValues() {
    return {
      reportDate: this.state.reportDate,
      departments: "",
      status: TRAINING_STATUS.COMPLETED,
      version: "latest",
    };
  }

  get typeaheadFilterInfo() {
    return {
      options: this.typeaheadOptions,
      label: "Departments",
      statePropName: "departments",
    };
  }

  get typeaheadOptions() {
    let data = this.results.instances.reportData;
    let {users} = data;
    let departments = new Set(users.map(user => user.department));
    return Array.from(departments).map(department => ({label: department}));
  }

  renderFilters() {
    if (this.results) {
      return <Fragment>
        <div className="col-sm-2">
          {this.renderTypeaheadFilter()}
        </div>
        <div className="col-sm-2">
          {this.renderStatusFilter()}
        </div>
      </Fragment>;
    }
  }

  handleTypeaheadChange(event) {
    let filterOptions = this.state.filterOptions;
    filterOptions.departments = event.map(option => option.label);

    this.setStateSafely({filterOptions}, () => {
      this.filterResults();
    });
  }

  filterResults() {
    let filterOptions = this.state.filterOptions;
    let {departments, status} = filterOptions;

    let data = this.results.instances.reportData;
    let {instances, users} = data;
    let allInstances = [...instances];

    // Filter by departments
    if (departments && departments.length > 0) {
      users = users.filter(user => departments.includes(user.department));

      let uniqueUserIds = new Set(users.map(user => user.id));
      instances = instances.filter(instance => uniqueUserIds.has(instance.userId));
    }

    // Filter by status
    instances = CannedReportTrainingFilter.filterByStatus(instances, status, allInstances);

    let results = {
      instances: {
        reportData: {
          instances,
          users
        }
      }
    };

    this.wrapUp(results);
  }

  renderInput() {
    return !this.props.reportOptions.renderOnEmptyData && this.state.reportResults ? "" : (
      <div id="dataReportViewer" className="data-report-viewer-full-screen" />
    );
  }
}
