import { BaseParser } from "../base_parser";
import * as UIUtils from "../../../../ui_utils";

export class ControlMethodParser extends BaseParser {
  parse() {
    const newElement = document.createElement(this.node.tagName);
    const {modelName} = this.getNodeInfo();
    const modelField = this.addModelAndColumnToField(this.fields, modelName);
    this.addModelAndColumnToField(modelField, "ControlMethod");
    newElement.innerText = getControlMethodValue(this.data);
    return [newElement];
  }
}

export function getControlMethodValue(record) {
  if (!record) {
    return "";
  }

  if (record.ControlMethods && Array.isArray(record.ControlMethods)) {
    return record.ControlMethods.map(
      (record) =>
        `${UIUtils.getRecordLabelForDisplay(
          UIUtils.getTypeCodeForModelName("ControlMethod"),
          record.id,
          record.name,
        )}`,
    ).join(", ");
  }

  return "";
}
