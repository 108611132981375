"use strict";

import React from "react";
import { PRODUCT_VIEW_TABS } from "../product_constants";
import BaseReactComponent from "../../base_react_component";
import * as UIUtils from "../../ui_utils";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import TableTitleBar from "../../configurableTables/tables/widgets/topBar/titleBar/table_title_bar";
import { NotificationProvider } from "../../configurableTables/tables/widgets/notification/notification_provider";
import InfoNotification from "../../configurableTables/tables/widgets/notification/types/info_notification";
import ShowArchivedToggle from "./show_archived_toggle";
import ListViewTitleArea from "./list_view_title_area";
import TableViewTitleArea from "./table_view_title_area";


const getViewsConfigs = (handleTabsChange) => {
  const {LISTS, TABLES} = PRODUCT_VIEW_TABS;
  return [
    {
      id: "listsTab",
      onClick: () => handleTabsChange(LISTS),
      title: LISTS.title,
      panel: LISTS.code,
      icon: LISTS.icon,
    },
    {
      id: "tablesTab",
      onClick: () => handleTabsChange(TABLES),
      title: TABLES.title,
      panel: TABLES.code,
      icon: TABLES.icon,
    },
  ];
};

// i18next-extract-mark-ns-start widgets
class ProductTopBar extends BaseReactComponent {
  constructor(props) {
    super(props);
    const storedProductViewTab = UIUtils.getParameterByName("selectedProductViewTab") === "TABLES" ? PRODUCT_VIEW_TABS.TABLES : PRODUCT_VIEW_TABS.LISTS;
    this.setStateSafely({
      selectedProductViewTab: storedProductViewTab ?? PRODUCT_VIEW_TABS.LISTS,
    });
  }

  componentDidMount() {
    super.componentDidMount();

    const {onProductTabChange} = this.props;
    const {selectedProductViewTab} = this.state;
    onProductTabChange("selectedProductViewTab", selectedProductViewTab);
  }

  handleTabsChange(tab) {
    const {onProductTabChange} = this.props;
    this.setStateSafely({
      selectedProductViewTab: tab,
    }, () => onProductTabChange("selectedProductViewTab", tab));
  }

  render() {
    const {
      t,
      recordsCount,
      onShowArchivedChange,
      onProductTabChange,
      onModelTypeChange,
      selectedModelType,
      onExport,
      projectId,
    } = this.props;

    const {selectedProductViewTab} = this.state;
    const {code} = selectedProductViewTab;

    return (
      <NotificationProvider>
        <TableTitleBar t={t}
                       projectId={projectId}
                       table={"Product"}
                       selectedPanel={code}
                       selectedModelType={selectedModelType}
                       viewsConfig={getViewsConfigs(this.handleTabsChange)}
                       onExport={onExport}
                       titleArea={
                         selectedProductViewTab === PRODUCT_VIEW_TABS.LISTS
                           ? <ListViewTitleArea onProductTabChange={onProductTabChange} />
                           : <TableViewTitleArea onModelTypeChange={onModelTypeChange} />
                       }
                       actionsArea={
                         selectedProductViewTab === PRODUCT_VIEW_TABS.LISTS &&
                         <ShowArchivedToggle title={t("{{ recordsCount}} all", {recordsCount})}
                                             onShowArchivedChange={onShowArchivedChange}
                         />
                       }
        />
        <InfoNotification />
      </NotificationProvider>
    );
  }

}

export default I18NWrapper.wrap(ProductTopBar, "widgets");
// i18next-extract-mark-ns-stop widgets
