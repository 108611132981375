"use strict";

import * as UIUtils from "../../ui_utils";
import CommonMath from "../../../server/common/generic/common_math";
import { PROCESS_CAPABILITY_TYPES } from "../../../server/common/misc/common_risk_utils";

const GRAPH_HEIGHT = 400;

const COMMON_SERIES_PROPERTIES = {
  boostThreshold: 1,
  turboThreshold: 0,
};

/**
 * This function generates chart configs needed to draw X-bar, R and S charts
 * @param parameters holds all params needed by the chart configuration builder
 * @param enableExport This controls if we need chart-level export options or not
 * @param showTitle This flag controls chart title visibility
 * @param showBorders This flag controls showing borders around charts
 * @param height Specifies height of the chart
 * @param cleanChartUI This flag configure chart for minimal spacing
 * @param isCenterLineDashed if the center line is dashed and grayed
 * @returns {*} Returns configs needed for the X-bar, R and S charts to be drawn
 */
export function generateControlChart(parameters, enableExport, showTitle, showBorders, height = GRAPH_HEIGHT, cleanChartUI, isCenterLineDashed = false) {

  let {
    lcl,
    ucl,
    centerLine,
    graphData,
    batchIdData,
    graphMinPoint,
    graphMaxPoint,
    numberOfMostDecimalPlaces,
    title,
    yAxisLabel,
  } = parameters;

  // Pre-processing before building the chart config
  lcl = parseFloat(lcl);
  ucl = parseFloat(ucl);
  centerLine = parseFloat(centerLine);
  graphMinPoint = parseFloat(graphMinPoint);
  graphMaxPoint = parseFloat(graphMaxPoint);
  numberOfMostDecimalPlaces = UIUtils.parseInt(numberOfMostDecimalPlaces);
  graphData = graphData ? graphData.map(point => [point[0], parseFloat(point[1])]) : [];
  const indexToBatchIdMap = new Map(batchIdData);

  let centerLinePlotLine;
  if (parameters.isCenterLineDashed || isCenterLineDashed) {
    centerLinePlotLine = {
      color: "grey",
      value: centerLine,
      width: 2,
      zIndex: 3,
      dashStyle: "ShortDash"
    };
  } else {
    centerLinePlotLine = addPlotLine("green", CommonMath.getToFixed(centerLine, numberOfMostDecimalPlaces), 1, 3,
      cleanChartUI ? "" : getCenterLineSymbol(title), "solid", true, getLabelClass(title),
      " = " + CommonMath.getToFixed(centerLine, numberOfMostDecimalPlaces));
  }

  let graphConfig = {
    xAxis: {
      title: {
        text: cleanChartUI ? null : getXAxisLabelForControlChart(title)
      },
      tickInterval: 1,
      type: "category",
      labels: {
        rotation: -45,
      },
    },
    yAxis: {
      title: {
        text: cleanChartUI ? null : yAxisLabel
      },
      plotLines: [centerLinePlotLine]
    },
    tooltip: {
      formatter: function() {
        let yAxisLabel = getYAxisLabelForControlChart(title);
        let xAxisLabel = title === "MR Chart" ? "Index" : "Batch Id";
        return "<span style=\"color: #7CB5EC\">\u25CF </span>" + xAxisLabel + ": <b>" + getBatchId(title, indexToBatchIdMap, this.x) + "</b> <br/>" +
          "<span style=\"color: #7CB5EC\">\u25CF </span>" + yAxisLabel + ": <b>" + graphData.find(point => point[0] === this.x)[1] + "</b>";
      }
    },
    legend: {
      enabled: false
    },
    series: [{
      ...COMMON_SERIES_PROPERTIES,
      dataLabels: {
        enabled: false
      },
      label: {
        enabled: false
      },
      name: "Average",
      type: "line",
      data: graphData.map(point => ({name: indexToBatchIdMap.get(point[0]), x: point[0], y: parseFloat(point[1])})),
      lineWidth: 1,
      marker: {
        radius: 4
      }
    }]
  };

  if (graphMinPoint || graphMinPoint === 0) {
    graphConfig.yAxis.min = CommonMath.getToFixed(graphMinPoint, numberOfMostDecimalPlaces);
  }

  if (graphMaxPoint || graphMaxPoint === 0) {
    graphConfig.yAxis.max = CommonMath.getToFixed(graphMaxPoint, numberOfMostDecimalPlaces);
  }

  let allLimitsEqual = ucl === centerLine && lcl === centerLine;
  if (ucl !== null) {
    graphConfig.yAxis.plotLines.push(
      addPlotLine("red", CommonMath.getToFixed(ucl, numberOfMostDecimalPlaces), 1,
        3, (cleanChartUI || allLimitsEqual) ? "" : ("UCL = " + CommonMath.getToFixed(ucl, numberOfMostDecimalPlaces)))
    );
  }

  if (lcl !== null) {
    graphConfig.yAxis.plotLines.push(
      addPlotLine("red", CommonMath.getToFixed(lcl, numberOfMostDecimalPlaces), 1, 3,
        (cleanChartUI || allLimitsEqual) ? "" : ("LCL = " + CommonMath.getToFixed(lcl, numberOfMostDecimalPlaces)))
    );
  }

  graphConfig = addCommonConfigs(graphConfig, title, "", "", height, false, cleanChartUI, showTitle, showBorders);
  graphConfig = addChartExportConfig(graphConfig, false, enableExport);

  // console.log("Chart Config:", graphConfig);
  return graphConfig;
}

function getYAxisLabelForControlChart(chartType) {
  switch (chartType) {
    case "Xbar Chart":
    case "X-bar Chart":
      return "Average";
    case "R Chart":
      return "Range";
    case "S Chart":
      return "Std";
    case "X Chart":
      return "Individual Value";
    case "MR Chart":
      return "Moving Range";
    case "P Chart":
      return "Proportion";
    case "Cumulative %Defective Chart":
      return "%Defective";
  }
  return null;
}

function getBatchId(chartType, indexToBatchIdMap, index) {
  if (chartType.match(new RegExp("Last (\\d+ )?Subgroups"))) {
    return indexToBatchIdMap.get(index);
  }

  switch (chartType) {
    case "Xbar Chart":
    case "X-bar Chart":
    case "R Chart":
    case "S Chart":
    case "P Chart":
    case "X Chart":
    case "Cumulative %Defective Chart":
      return indexToBatchIdMap.get(index);
    default:
      return index;
  }
}

function getXAxisLabelForControlChart(chartType) {
  switch (chartType) {
    case "Xbar Chart":
    case "X-bar Chart":
    case "R Chart":
    case "S Chart":
    case "P Chart":
    case "Cumulative %Defective Chart":
      return "Batch";
    case "X Chart":
    case "MR Chart":
      return "Observation";
  }
  return null;
}

/**
 * This function is used to return each center line symbol for different control charts
 * @param chartType
 * @returns * symbol for each different control chart
 */
function getCenterLineSymbol(chartType) {
  switch (chartType) {
    case "Xbar Chart":
    case "X-bar Chart":
      return "X";
    case "R Chart":
      return "R";
    case "S Chart":
      return "S";
    case "X Chart":
      return "X";
    case "MR Chart":
      return "MR";
    case "P Chart":
      return "P";
    case "Cumulative %Defective Chart Chart":
      return "";
  }
  return null;
}

/**
 * This function is used to return styling for each center line label
 * @param chartType
 * @returns {string} class for chart center line label styling
 */
function getLabelClass(chartType) {
  switch (chartType) {
    case "Xbar Chart":
    case "X-bar Chart":
      return "pcm-chart-plot-line-double-bar";
    case "R Chart":
    case "S Chart":
    case "X Chart":
    case "MR Chart":
    case "P Chart":
      return "pcm-chart-plot-line-single-bar";
  }
}

/**
 * This function generates chart configs needed to draw last subgroups chart
 * @param parameters holds all params needed by the chart configuration builder
 * @param enableExport This controls if we need chart-level export options or not
 * @param showTitle This flag controls chart title visibility
 * @param showBorders This flag controls showing borders around charts
 * @param height Specifies height of the chart
 * @returns {*} Returns configs needed for the last subgroups chart to be drawn
 */
export function generateScatteredChart(parameters, enableExport, showTitle, showBorders, height = GRAPH_HEIGHT) {
  let {
    lsl,
    usl,
    centerLine,
    graphData,
    batchIdData,
    graphMinPoint,
    graphMaxPoint,
    subgroupsCount,
    xAxisLabel,
    yAxisLabel,
    showLSL,
    showUSL,
    fixToMostDecimalPlaces,
    numberOfMostDecimalPlaces,
    title,
  } = parameters;

  // Pre-processing before building the chart config
  lsl = parseFloat(lsl);
  usl = parseFloat(usl);
  centerLine = parseFloat(centerLine);
  graphMinPoint = parseFloat(graphMinPoint);
  graphMaxPoint = parseFloat(graphMaxPoint);
  title = title ? title.replace(" {COUNT} ", graphData && graphData.length > 0 ? ` ${subgroupsCount} ` : " ") : "";
  numberOfMostDecimalPlaces = UIUtils.parseInt(numberOfMostDecimalPlaces);
  graphData = graphData ? graphData.map(point => [point[0], parseFloat(point[1])]) : [];
  const indexToBatchIdMap = new Map(batchIdData);

  let graphConfig = {
    xAxis: {
      title: {
        enabled: true,
        text: xAxisLabel ? xAxisLabel : "Batch"
      },
      tickInterval: 1,
      type: "category",
      labels: {
        rotation: -45,
      },
    },
    yAxis: {
      title: {
        text: yAxisLabel ? yAxisLabel : "Values"
      },
      plotLines: [
        {
          color: "grey",
          value: centerLine,
          width: 2,
          zIndex: 3,
          dashStyle: "ShortDash"
        }]
    },
    legend: {
      enabled: false
    },
    tooltip: {
      formatter: function() {
        let xAxisLabel = title === "Rate of Defectives" ? "Sample Size" : "Batch Id";
        return "<span style=\"color: #7CB5EC\">\u25CF </span>" + xAxisLabel + ": <b>" + getBatchId(title, indexToBatchIdMap, this.x) + "</b> <br/>" +
          "<span style=\"color: #7CB5EC\">\u25CF </span>Value: <b>" + (fixToMostDecimalPlaces ? this.y.toFixed(numberOfMostDecimalPlaces) : this.y) + "</b>";
      }
    },
    series: [{
      ...COMMON_SERIES_PROPERTIES,
      type: "scatter",
      name: "Value",
      label: {
        enabled: false
      },
      data: graphData.map(point => ({name: indexToBatchIdMap.get(point[0]), x: point[0], y: parseFloat(point[1])})),
      marker: {
        symbol: "diamond"
      }
    }]
  };

  if (graphMinPoint || graphMinPoint === 0) {
    graphConfig.yAxis.min = graphMinPoint;
  }

  if (graphMaxPoint || graphMaxPoint === 0) {
    graphConfig.yAxis.max = graphMaxPoint;
  }

  if (usl && showUSL) {
    graphConfig.yAxis.plotLines.push(
      addPlotLine("red", usl, 1, 3, `USL = ${CommonMath.getToFixed(usl, 2)}`)
    );
  }

  if (lsl && showLSL) {
    graphConfig.yAxis.plotLines.push(
      addPlotLine("red", lsl, 1, 3, `LSL = ${CommonMath.getToFixed(lsl, 2)}`)
    );
  }

  graphConfig = addCommonConfigs(graphConfig, title, "", "", height, false, false, showTitle, showBorders);
  graphConfig = addChartExportConfig(graphConfig, false, enableExport);
  // console.log("Chart Config:", JSON.stringify(graphConfig));

  return graphConfig;
}

/**
 * This function generates chart configs needed to draw capability histogram chart
 * @param parameters holds all params needed by the chart configuration builder
 * @param enableExport This controls if we need chart-level export options or not
 * @returns {*} Returns configs needed for the capability histogram chart to be drawn
 */
export function generateHistogramChart(parameters, enableExport) {

  let {
    lsl,
    usl,
    target,
    graphData,
    graphMinPoint,
    graphMaxPoint,
    xAxisLabel,
    title
  } = parameters;

  // Pre-processing before building the chart config
  lsl = parseFloat(lsl);
  usl = parseFloat(usl);
  target = parseFloat(target);
  graphMinPoint = parseFloat(graphMinPoint);
  graphMaxPoint = parseFloat(graphMaxPoint);
  graphData = graphData.map(record => parseFloat(record));

  let graphConfig = {
    xAxis: [{
      alignTicks: false,
      visible: false
    }, {
      title: {text: xAxisLabel ? xAxisLabel : "Values"},
      alignTicks: false,
      plotLines: []
    }],
    yAxis: [{
      visible: false,
      opposite: true
    }, {
      title: {text: "Frequency"},
      visible: true
    }],
    tooltip: {
      pointFormat: `<span style="color: #7CB5EC">\u25CF </span>
        Range: <b>{point.x:.2f} - {point.x2:.2f}<br/>
        <span style="color: #7CB5EC">\u25CF </span>Frequency: <b>{point.y}</b><br/>`
    }
  };

  if (graphMinPoint || graphMinPoint === 0) {
    graphConfig.xAxis[1].min = graphMinPoint;
  }

  if (graphMaxPoint || graphMaxPoint === 0) {
    graphConfig.xAxis[1].max = graphMaxPoint;
  }

  if (lsl) {
    graphConfig.xAxis[1].plotLines.push(
      addPlotLine("red", lsl, 1.5, 100,
        `LSL = ${CommonMath.getToFixed(lsl, 2)}`, "Dash")
    );
  }

  if (target) {
    graphConfig.xAxis[1].plotLines.push(
      addPlotLine("green", target, 1.5, 100,
        `Target = ${CommonMath.getToFixed(target, 2)}`, "Dash")
    );
  }

  if (usl) {
    graphConfig.xAxis[1].plotLines.push(
      addPlotLine("red", usl, 1.5, 100,
        `USL = ${CommonMath.getToFixed(usl, 2)}`, "Dash")
    );
  }

  graphConfig.series = graphData.length > 0 ? [{
    name: "Histogram",
    type: "histogram",
    xAxis: 1,
    yAxis: 1,
    baseSeries: "s1",
    zIndex: -1,
    showInLegend: false
  }, {
    name: "Data",
    type: "scatter",
    data: graphData,
    id: "s1",
    marker: {
      radius: 2
    },
    visible: false,
    showInLegend: false
  }] : [{
    type: "line",
    label: {
      enabled: false
    },
    data: graphData
  }];

  graphConfig = addCommonConfigs(graphConfig, title, "", "", GRAPH_HEIGHT, true);
  graphConfig = addChartExportConfig(graphConfig, true, enableExport);

  graphConfig.chart.events = {
    load: function() {
      let histogramSeries = this.series[0];
      if (histogramSeries && histogramSeries.data.length > 0) {
        if (histogramSeries.binsNumber() < 3) {
          histogramSeries.update({
            pointWidth: 40
          });
        }
      }
    }
  };

  // console.log("Chart Config:", JSON.stringify(graphConfig));
  return graphConfig;
}

/**
 * This function generates chart configs needed to draw normal probability plot chart
 * @param parameters holds all params needed by the chart configuration builder
 * @param enableExport This controls if we need chart-level export options or not
 * @returns {*} Returns configs needed for the normal probability plot chart to be drawn
 */
export function generateNormalPropPlotChart(parameters, enableExport = true) {

  let {
    graphData,
    title,
    regressionLineP1,
    regressionLineP2,
    AD,
    pValue,
    numberOfMostDecimalPlaces
  } = parameters;

  let graphConfig = {
    xAxis: {
      title: {
        enabled: true,
        text: "Values"
      }
    },
    yAxis: {
      title: {
        text: "z Value"
      }
    },
    tooltip: {
      formatter: function() {
        return "<span style=\"color: #7CB5EC\">\u25CF </span>Value: <b>" + this.x + "</b> <br/> " +
          "<span style=\"color: #7CB5EC\">\u25CF </span>z: <b>" + this.y.toFixed(numberOfMostDecimalPlaces) + "</b>";
      }
    },
    series: [
      {
        ...COMMON_SERIES_PROPERTIES,
        type: "scatter",
        name: "Values",
        data: graphData,
        marker: {
          symbol: "diamond"
        }
      },
      {
        ...COMMON_SERIES_PROPERTIES,
        type: "line",
        name: "Normality Line",
        color: "red",
        data: [regressionLineP1, regressionLineP2],
        marker: {
          enabled: false
        }
      }]
  };

  graphConfig = addCommonConfigs(graphConfig, title,
    AD && pValue ? `AD: ${CommonMath.getToFixed(AD, 3)}, P: ${pValue > 0.05 ? CommonMath.getToFixed(pValue, 3) : `< 0.05`}` : "",
    "", GRAPH_HEIGHT, true);
  graphConfig = addChartExportConfig(graphConfig, true, enableExport);
  // console.log("Chart Config:", JSON.stringify(graphConfig));

  return graphConfig;
}

/**
 * This function generates chart configs needed to draw normal probability plot chart
 * @param parameters holds all params needed by the chart configuration builder
 * @param cleanChartUI This flag configure chart for minimal spacing
 * @param enableExport This controls if we need chart-level export options or not
 * @param height Specifies height of the chart
 * @param showTitle This flag controls chart title visibility
 * @param isDashboard This flag is used to indicate if this request coming for the dashboard
 * @returns {*} Returns configs needed for the normal probability plot chart to be drawn
 */
export function generateCapabilityPlotChart(parameters, enableExport, height = 250, cleanChartUI, showTitle, isDashboard) {

  let {
    overall,
    within,
    specs,
    title
  } = parameters;

  // Pre-processing before building the chart config
  overall = overall ? overall.map(point => [point[0], parseFloat(point[1])]) : [];
  specs = specs ? specs.map(point => [point[0], parseFloat(point[1])]) : [];
  within = within ? within.map(point => [point[0], parseFloat(point[1])]) : [];

  let graphConfig = {
    xAxis: {
      visible: false,
      crosshair: {
        width: 1.5
      }
    },
    yAxis: {
      endOnTick: false,
      visible: false
    },
    tooltip: {
      formatter: function() {
        return CommonMath.getToFixed(this.x, 2);
      },
      positioner: function(labelWidth, labelHeight, point) {
        const chartPosition = this.chart.pointer.chartPosition;
        return {x: chartPosition.left + point.plotX, y: chartPosition.top + point.plotY};
      },
      outside: true,
    },
    series: []
  };

  if (isDashboard) {
    graphConfig.legend = {
      backgroundColor: "#f5f5f5",
      itemDistance: 0,
      itemWidth: 60,
      width: "110%",
      symbolPadding: 2,
      symbolWidth: 15,
      itemMarginBottom: 3,
      itemStyle: {
        fontWeight: "normal",
        fontSize: "10px"
      }
    };
  }

  if (overall.length > 0) {
    graphConfig.series.push({
      type: "line",
      data: overall,
      name: "Overall",
      color: "#4c8cad"
    });
  }

  if (within.length > 0) {
    graphConfig.series.push({
      type: "line",
      data: within,
      name: "Within",
      color: "#000"
    });
  }

  if (specs.length > 0) {
    graphConfig.series.push({
      type: "line",
      data: specs,
      name: "Specs",
      color: "#1c8a10"
    });
  }

  graphConfig = addCommonConfigs(graphConfig, title, "", "", height, true, cleanChartUI, showTitle);
  graphConfig = addChartExportConfig(graphConfig, true, enableExport);
  // console.log("Chart Config:", JSON.stringify(graphConfig));

  return graphConfig;
}

/**
 * This function generates configs needed for pcm indices bullet chart
 * @param value target value for the pcm index
 * @returns {*} Returns configs needed for the normal probability plot chart to be drawn
 */
export function generateCapabilityIndexChart(value) {
  let graphConfig = {
    chart: {
      inverted: true,
      type: "bullet",
      backgroundColor: "#effafe",
      spacing: [0, 35, 10, 35]
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    xAxis: {
      visible: false
    },
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    plotOptions: {
      series: {
        ...COMMON_SERIES_PROPERTIES,
        pointPadding: 0.25,
        borderWidth: 0,
        color: "#fff",
        targetOptions: {
          width: "200%",
          color: value !== "N/A" ? "#000" : "transparent"
        },
        animation: true
      }
    },
    yAxis: {
      gridLineWidth: 0,
      max: 2,
      title: null,
      labels: {
        formatter: function() {
          if (this.isLast || this.isFirst) {
            return this.value;
          }
        },
        y: 2
      }
    },
    series: [{
      ...COMMON_SERIES_PROPERTIES,
      data: [
        {
          x: 1,
          y: 2,
          target: null,
          color: PROCESS_CAPABILITY_TYPES.Good.color
        },
        {
          x: 1,
          y: 1.33,
          target: null,
          color: PROCESS_CAPABILITY_TYPES.Capable.color
        },
        {
          x: 1,
          y: 1,
          target: value > 2 ? 2 : (value < 0 || value === "N/A") ? 0 : value,
          color: PROCESS_CAPABILITY_TYPES.Poor.color
        }
      ],
      grouping: false
    }],
    tooltip: {
      enabled: false
    }
  };

  // console.log("Chart Config:", JSON.stringify(graphConfig));
  return graphConfig;
}

/**
 * This function adds needed configuration to draw plot lines over charts
 * @param color Color of the plot line
 * @param value Value where plot line will be drawn
 * @param width Width of the plot line
 * @param zIndex In which layer plot line will be shawn
 * @param text Text to be drawn over the plot line
 * @param dashStyle Dash style for the plot line
 * @param useHTML Specifies if plot line needs html tags or not
 * @param className custom classname to style the plotline label
 * @param additionalText add more text to a styled primary text
 * @returns plot line configuration
 */
function addPlotLine(color, value, width, zIndex, text, dashStyle = "solid", useHTML = true, className = "pcm-chart-plot-line", additionalText) {
  return {
    color,
    value,
    width,
    zIndex,
    dashStyle,
    label: {
      text: `<span class=${className}>${text}</span>` + (additionalText ? additionalText : ""),
      useHTML,
      align: "right",
      textAlign: "left"
    }
  };
}

/**
 * This function adds common configs used by all charts
 * @param config chart config to add common configs to
 * @param title of the chart
 * @param subtitle of the chart
 * @param credits of the chart
 * @param height of the chart
 * @param minimizeRightSpacing minimize the right padding
 * @param cleanChartUI This flag configure chart for minimal spacing
 * @param showTitle This flag controls chart title visibility
 * @param showBorders This flag controls showing borders around charts
 * @returns {*} new config is returned after common configs are added
 */
function addCommonConfigs(config, title, subtitle = "", credits = "", height,
                          minimizeRightSpacing = false, cleanChartUI = false, showTitle = true,
                          showBorders = false) {
  config.credits = {
    text: credits
  };

  config.plotOptions = {
    series: {
      ...COMMON_SERIES_PROPERTIES,
      animation: true
    }
  };

  config.chart = {
    spacing: cleanChartUI ? 10 :
      [30, minimizeRightSpacing ? 30 : 100, 30, 30],
    shadow: false,
    height: height,
    plotAreaHeight: height,
    borderColor: "#e6e6e6",
    borderWidth: showBorders ? 1 : 0
  };

  config.title = {
    text: showTitle ? title : null
  };

  config.subtitle = {
    useHTML: true,
    text: subtitle
  };

  config.tooltip = {
    backgroundColor: "#F8F8F8",
    style: {
      zIndex: 1000,
    },
    outside: true,
    ...(config.tooltip ? config.tooltip : {}),
  };

  return config;
}

/**
 * This function adds the export config part to chart config
 * @param config chart config to add common configs to
 * @param minimizeRightSpacing minimize the right padding
 * @param enabled {boolean} true if exporting is allowed, false otherwise.
 * @returns {*} new config is returned after common configs are added
 */
function addChartExportConfig(config, minimizeRightSpacing = false, enabled = true) {
  config.navigation = {
    menuItemStyle: {
      "font-size": "14px",
      "font-family": "Roboto, sans-serif"
    }
  };

  config.exporting = enabled ? {
    allowHTML: true,
    buttons: {
      contextButton: {
        y: -23,
        x: minimizeRightSpacing ? 20 : 90,
        menuItems: [
          {
            text: "Print",
            onclick: function() {
              this.print();
            }
          },
          {
            text: "Export to PDF",
            onclick: function() {
              this.exportChart({
                type: "application/pdf"
              });
            }
          },
          {
            text: "Export to PNG",
            onclick: function() {
              this.exportChart();
            }
          },
          {
            text: "Export to XLSX",
            onclick: function() {
              this.downloadCSV();
            }
          },
          {
            text: "Toggle Data Table",
            onclick: function() {
              this.viewData();
            }
          }]
      }
    }
  } : {
    enabled: false
  };

  return config;
}
