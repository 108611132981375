/* eslint-disable no-unused-vars */
export enum EditorState {
  VIEW = "VIEW",
  EDIT = "EDIT",
}

export enum FileType {
  PDF = "pdf",
  DOC = "doc",
}

export enum NumberedListOption {
  StaticList = "staticlist",
  DynamicList = "dynamiclist",
  Continuous = "continuous",
  PrefixNearest = "prefixnearest",
}

/* eslint-enabled */

export enum FormatBlockType {
  Title = "title",
  Heading1 = "h1",
  Heading2 = "h2",
  Heading3 = "h3",
  Heading4 = "h4",
  NormalText = "p",
  Caption = "caption",
}

// If you change the font size for a format block, please don't forget to update it in
// src/main/client/documentEditor/document_editor.module.scss as well
export const FormatBlockFontSize = {
  [FormatBlockType.Title]: "32pt",
  [FormatBlockType.Heading1]: "20pt",
  [FormatBlockType.Heading2]: "16pt",
  [FormatBlockType.Heading3]: "14pt",
  [FormatBlockType.Heading4]: "12pt",
  [FormatBlockType.NormalText]: "11pt",
  [FormatBlockType.Caption]: "10pt",
};

export const DOCUMENT_RECORD_SESSION_STORAGE_KEY = "DOCUMENT_RECORD";

export const GLOBAL_MODELS = ["Project", "Process", "Document"];

// The common color palette that is used by all Doc Builder color picker
export const COLOR_PALETTE = [
  "#000000",
  "#434343",
  "#666666",
  "#999999",
  "#B7B7B7",
  "#CCCCCC",
  "#D9D9D9",
  "#EFEFEF",
  "#F3F3F3",
  "#FFFFFF",
  "#980000",
  "#FF0000",
  "#FF9900",
  "#FFFF00",
  "#02FF00",
  "#02FFFF",
  "#4986E8",
  "#0000FF",
  "#9900FF",
  "#FF00FF",
  "#E6B8AF",
  "#F5CBCC",
  "#FCE5CD",
  "#FFF2CC",
  "#D9EAD3",
  "#D0DFE2",
  "#C9DAF8",
  "#D0E2F3",
  "#D9D2E9",
  "#EAD1DB",
  "#DD7E6B",
  "#E99999",
  "#F9CB9C",
  "#FFE59A",
  "#B5D7A8",
  "#A2C4C9",
  "#A4C2F4",
  "#A0C5E8",
  "#B4A7D5",
  "#D5A6BD",
  "#CD4025",
  "#E06666",
  "#F6B26B",
  "#FFD966",
  "#93C47D",
  "#76A5AF",
  "#6D9EEB",
  "#70A8DC",
  "#8E7CC3",
  "#C27BA0",
  "#A61D01",
  "#CC0100",
  "#E69138",
  "#F1C231",
  "#6AA84F",
  "#45818E",
  "#3C78D8",
  "#3D85C6",
  "#674EA7",
  "#A64D78",
  "#85210C",
  "#990001",
  "#B45F06",
  "#BF9001",
  "#38761D",
  "#134F5C",
  "#1255CC",
  "#0C5394",
  "#361C75",
  "#751A47",
  "#5B0F00",
  "#660000",
  "#783F04",
  "#7F6001",
  "#284E13",
  "#0C343D",
  "#1C4586",
  "#073763",
  "#20124D",
  "#4C1130",
];

export const GENERIC_ERROR_MESSAGE = "An error has been encountered during content parsing. Please review your most recent changes or contact us for assistance.";
