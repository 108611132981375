"use strict";

import React from "react";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseFinalAttributeListTable from "./base_final_attribute_list";

/* This shows the table of FQAs when viewing the QTPP. */

// i18next-extract-mark-ns-start qtpp
class FQAList extends BaseFinalAttributeListTable {
  constructor(props) {
    super(props, "FQA", true, false);
  }
}
export default I18NWrapper.wrap(FQAList, ["qtpp", "base_page"]);
// i18next-extract-mark-ns-stop qtpp

