"use strict";

/*
 * The functions in this file are responsible for sorting and indexing all PE variables by order
 */

const CommonUtils = require("../generic/common_utils");
const {orderAndIndexSteps, orderAndIndexUnits} = require("./common_uo_indexer");
const {ProcessParameterIndexer} = require("./common_process_parameter_indexer");
const {PROCESS_LEVEL_ID} = require("../generic/common_constants");
const {CommonSorting} = require("../generic/common_sorting");

class RecordsIndexerBuilder {
  constructor(unitOperations) {
    this._results = {
      uoMap: {},
      stpMap: {},
      ppMap: {},
      prcMap: {},
      maMap: {},
      mtMap: {},
      iqaMap: {},
      ipaMap: {}
    };
    this._activeMapName = null;
    this._modelName = null;
    this.setUOMap(unitOperations);
  }

  setModelName(modelName) {
    this._modelName = modelName;
    return this;
  }

  setUOMap(instances) {
    return this.setMap(instances, "uoMap");
  }

  setStpMap(instances) {
    return this.setMap(instances, "stpMap");
  }

  setMap(instances, key) {
    instances.forEach(instance => this._results[key][instance.id] = {...instance});
    return this;
  }

  build() {
    return new RecordsIndexer({results: this._results}, this._activeMapName, this._modelName);
  }
}

module.exports.RecordsIndexerBuilder = RecordsIndexerBuilder;

class RecordsIndexer {
  constructor(params, activeMapName, modelName) {
    this.results = params.results;
    this.activeMap = this.results[activeMapName];
    this.modelName = modelName;
    this.typeCode = CommonUtils.getTypeCodeForModelName(modelName);
  }

  /**
   * Sort records using the position from process explorer
   * Order index is recorded into property sortByOrderIndex
   * @param unsortedRecords
   * @returns {(*&{sortByOrderIndex})[]} Sorted records with an additional property sortByOrderIndex containing the ordering index
   */
  fillInSortByOrderProperty(unsortedRecords) {
    const orderedUOList = orderAndIndexUnits(Object.values(this.results.uoMap));

    let sortByOrderIndex = 0;
    let allRecords = [];

    for (const unitOperation of orderedUOList) {
      let orderedRecords = [];

      orderedRecords = orderedRecords.concat(this.orderChildrenInUnitOperation(unsortedRecords, unitOperation));

      const unitOperationRelatedSteps = unitOperation.Steps.map(step => Object.values(this.results.stpMap)
        .find(stepWithDetails => stepWithDetails.id === step.id));
      const orderedStepList = orderAndIndexSteps(unitOperationRelatedSteps.filter(step => step));

      for (const step of orderedStepList) {
        orderedRecords = orderedRecords.concat(this.orderChildrenInStep(unsortedRecords, unitOperation, step));
      }

      allRecords = allRecords.concat(orderedRecords);
    }

    allRecords = allRecords.concat(this.orderGlobalMaterials(unsortedRecords));
    allRecords = allRecords.concat(this.orderGlobalProcessComponents(unsortedRecords));

    allRecords = allRecords.map(record => {
      return {sortByOrderIndex: sortByOrderIndex++, ...record};
    });

    return allRecords;
  }

  /**
   * Order children from current unit operations
   * @param unsortedRecords
   * @param unitOperation
   * @returns {*[]}
   */
  orderChildrenInUnitOperation(unsortedRecords, unitOperation) {
    let orderedRecords = [];
    const uoChilren = unsortedRecords
      .filter(variable => (variable.unitOperationId === unitOperation.id && (!variable.stepId || variable.stepId === -1)) ||
        (variable.UnitOperations && variable.UnitOperations.find(uo => {
          return uo.id === unitOperation.id &&
            !variable.Steps.find(variableStep => unitOperation.Steps.find(unitStep => unitStep.id === variableStep.id));
        }))
      );

    if (this.typeCode === "PP") {
      orderedRecords = orderedRecords.concat(this.orderPP(unitOperation, uoChilren));
    } else if (this.typeCode === "MA") {
      orderedRecords = orderedRecords.concat(this.orderMAInUnitOperation(unsortedRecords, unitOperation));
    } else {
      orderedRecords = orderedRecords.concat(this.orderGenericChildrenInUnitOperation(unitOperation, uoChilren));
    }

    return orderedRecords;
  }

  /**
   * Order children in unit operation
   * @param unitOperation
   * @param uoChildren
   * @returns {*}
   */
  orderGenericChildrenInUnitOperation(unitOperation, uoChildren) {
    return uoChildren.map(uoChild => {
      const clonedChild = {...uoChild};
      clonedChild.unitOperation = unitOperation.name;
      clonedChild.unitOperationId = unitOperation.id;
      return clonedChild;
    }).sort(CommonSorting.sortRecord);
  }

  /**
   * Order material unsortedRecords in a unit operation
   * @param records
   * @param unitOperation
   * @returns {*[]}
   */
  orderMAInUnitOperation(records, unitOperation) {
    let orderedRecords = [];

    orderedRecords = orderedRecords.concat(this.orderMAInUnitOperationAndMT(records, unitOperation));
    orderedRecords = orderedRecords.concat(this.orderMAInUnitOperationAndPRC(records, unitOperation));
    orderedRecords = orderedRecords.concat(this.orderMAInRootOfUnitOperation(records, unitOperation));

    return orderedRecords;
  }

  /**
   * Order material attribute located in root of the current unit operation
   * @param prcFilterCondition Process component filter condition
   * @param unsortedRecords
   * @param unitOperation
   * @returns {*[]} Sorted records
   */
  orderMAInRootOfUnitOperation(unsortedRecords, unitOperation) {
    return unsortedRecords.filter(attribute => attribute.unitOperationId === unitOperation.id &&
      (!unitOperation.stepId || unitOperation.stepId === -1) &&
      (!attribute.MaterialId || attribute.MaterialId === -1) &&
      (!attribute.ProcessComponentId || attribute.ProcessComponentId === -1))
      .sort(CommonSorting.sortRecord);
  }

  /**
   * Order material attribute located in unit operation -> process component
   * @param prcFilterCondition Process component filter condition
   * @param unsortedRecords
   * @param unitOperation
   * @returns {*[]} Sorted records
   */
  orderMAInUnitOperationAndPRC(unsortedRecords, unitOperation) {
    let orderedRecords = [];

    const processComponents = Object.values(this.results.prcMap).filter(this.getFilterConditionForMatOrPrcInUnitOperation(unitOperation))
      .sort(CommonSorting.sortRecord);

    for (const processComponent of processComponents) {
      orderedRecords = orderedRecords.concat(unsortedRecords.filter(record => record.unitOperationId === unitOperation.id &&
        (!record.stepId || record.stepId === -1) &&
        record.ProcessComponentId === processComponent.id)
        .sort(CommonSorting.sortRecord));
    }

    return orderedRecords;
  }

  /**
   * Order material attribute located in unit operation -> material
   * @param naterialFilterCondition Material filter condition
   * @param unsortedRecords
   * @param unitOperation
   * @returns {*[]} Sorted records
   */
  orderMAInUnitOperationAndMT(unsortedRecords, unitOperation) {
    let orderedRecords = [];
    const materials = Object.values(this.results.mtMap).filter(this.getFilterConditionForMatOrPrcInUnitOperation(unitOperation))
      .sort(CommonSorting.sortRecord);

    for (const material of materials) {
      orderedRecords = orderedRecords.concat(unsortedRecords.filter(attribute => attribute.unitOperationId === unitOperation.id &&
        (!attribute.stepId || attribute.stepId === -1) &&
        attribute.MaterialId === material.id)
        .sort(CommonSorting.sortRecord));
    }

    return orderedRecords;
  }

  /**
   * Filter condition for getting materials and process components in a unit operation
   * @param unitOperation
   * @returns {function(*): *}
   */
  getFilterConditionForMatOrPrcInUnitOperation(unitOperation) {
    return (procOrMaterial) =>
      procOrMaterial.UnitOperations.find(uo => {
        return uo.id === unitOperation.id &&
          !procOrMaterial.Steps.find(materialStep => unitOperation.Steps.find(unitStep => unitStep.id === materialStep.id));
      });
  }

  /**
   * Order children in step
   * @param unsortedRecords
   * @param unitOperation
   * @param step
   * @returns {*[]}
   */
  orderChildrenInStep(unsortedRecords, unitOperation, step) {
    let orderedRecords = [];
    const stepChildren = unsortedRecords.filter(variable => variable.stepId === step.id ||
      (variable.Steps && variable.Steps.find(variableStep => {
        return variableStep.id === step.id;
      })));

    if (this.typeCode === "PP") {
      orderedRecords = orderedRecords.concat(this.orderPP(step, stepChildren));
    } else if (this.typeCode === "MA") {
      orderedRecords = orderedRecords.concat(this.orderMAInStep(unsortedRecords, step, unitOperation));
    } else {
      orderedRecords = orderedRecords.concat(this.orderGenericChildrenInStep(step, unitOperation, stepChildren));
    }

    return orderedRecords;
  }

  /**
   * Order children in the current step
   * @param step
   * @param stepChildren
   * @returns {*}
   */
  orderGenericChildrenInStep(step, unitOperation, stepChildren) {
    return stepChildren.map(child => {
      const cloneChild = {...child};
      cloneChild.unitOperation = unitOperation.name;
      cloneChild.unitOperationId = unitOperation.id;
      cloneChild.step = step.name;
      cloneChild.stepId = step.id;
      return cloneChild;
    }).sort(CommonSorting.sortRecord);
  }

  /**
   * Order material attribute in step
   * @param unsortedRecords
   * @param step
   * @param unitOperation
   * @returns {*[]}
   */
  orderMAInStep(unsortedRecords, step, unitOperation) {
    let orderedRecords = [];

    orderedRecords = orderedRecords.concat(this.orderMAInStepAndMT(unsortedRecords, unitOperation, step));
    orderedRecords = orderedRecords.concat(this.orderMAInStepAndPRC(unsortedRecords, unitOperation, step));
    orderedRecords = orderedRecords.concat(this.orderMAInRootOfStep(unsortedRecords, step));

    return orderedRecords;
  }

  /**
   * Get material attributes in root of the current step
   * @param records
   * @param step
   * @returns {*}
   */
  orderMAInRootOfStep(records, step) {
    return records.filter(record =>
      record.stepId === step.id &&
      (!record.MaterialId || record.MaterialId === -1) &&
      (!record.ProcessComponentId || record.ProcessComponentId === -1))
      .sort(CommonSorting.sortRecord);
  }

  /**
   * Order material attributes in step -> process component
   * @param records
   * @param unitOperation
   */
  orderMAInStepAndPRC(records, unitOperation, step) {
    let orderedRecords = [];

    const processComponents = Object.values(this.results.prcMap).filter((prc) =>
      prc.Steps.find(stp => {
        return stp.id === step.id;
      }) ||
      records.find(record => record.stepId === step.id && record.ProcessComponentId === prc.id))
      .sort(CommonSorting.sortRecord);

    for (const processComponent of processComponents) {
      orderedRecords = orderedRecords.concat(records.filter(record => record.unitOperationId === unitOperation.id &&
        record.stepId === step.id &&
        record.ProcessComponentId === processComponent.id)
        .sort(CommonSorting.sortRecord));
    }

    return orderedRecords;
  }

  /**
   * Order material attributes in step -> material
   * @param records
   * @param unitOperation
   */
  orderMAInStepAndMT(records, unitOperation, step) {
    let orderedRecords = [];

    const materials = Object.values(this.results.mtMap).filter((material) =>
      material.Steps.find(stp => {
        return stp.id === step.id;
      }) ||
      records.find(attr => attr.stepId === step.id && attr.MaterialId === material.id))
      .sort(CommonSorting.sortRecord);

    for (const material of materials) {
      orderedRecords = orderedRecords.concat(records.filter(record => record.unitOperationId === unitOperation.id &&
        record.stepId === step.id &&
        record.MaterialId === material.id)
        .sort(CommonSorting.sortRecord));
    }

    return orderedRecords;
  }

  /**
   * Order process parameters
   *
   * @param record
   * @param allRecordsOnTheSameLevel
   * @returns {*[]}
   */
  orderPP(record, allRecordsOnTheSameLevel) {
    const stepProcessParametersMap = new Map(allRecordsOnTheSameLevel.map(record => [record.id, record]));
    return ProcessParameterIndexer
      .sortRecordMapBasedOnIDArray(record.RecordOrder ?
        JSON.parse(record.RecordOrder.processParameterOrder) :
        [], stepProcessParametersMap);
  }

  /**
   * Order material globally defined in process explorer
   * @param unsortedRecords
   * @returns {*[]}
   */
  orderGlobalMaterials(unsortedRecords) {
    let allRecords = [];
    const globalMaterials = Object.values(this.results.mtMap).filter((material) =>
      material.Steps.length === 0 && material.UnitOperations.length === 0)
      .sort(CommonSorting.sortRecord);

    if (this.typeCode === "MT") {
      allRecords = allRecords.concat(globalMaterials);
    } else {
      for (const material of globalMaterials) {
        allRecords = allRecords.concat(unsortedRecords
          .filter(record => record.MaterialId === material.id && (!record.unitOperationId || record.unitOperationId === PROCESS_LEVEL_ID))
          .sort(CommonSorting.sortRecord));
      }
    }

    return allRecords;
  }

  /**
   * Order process components globally defined in process explorer
   * @param unsortedRecords
   * @returns {*[]}
   */
  orderGlobalProcessComponents(unsortedRecords) {
    let allRecords = [];
    const globalProcessComponents = Object.values(this.results.prcMap).filter((prc) =>
      prc.Steps.length === 0 && prc.UnitOperations.length === 0)
      .sort(CommonSorting.sortRecord);

    if (this.typeCode === "PRC") {
      allRecords = allRecords.concat(globalProcessComponents);
    } else {
      for (const processComponent of globalProcessComponents) {
        allRecords = allRecords.concat(unsortedRecords
          .filter(record => record.ProcessComponentId === processComponent.id && (!record.unitOperationId || record.unitOperationId === PROCESS_LEVEL_ID))
          .sort(CommonSorting.sortRecord));
      }
    }

    return allRecords;
  }

  /**
   * Group rows by unit operation and step
   * @param sortedByOrderRows
   * @returns {*}
   */
  groupRowsByUOAndStep(sortedByOrderRows) {
    sortedByOrderRows = sortedByOrderRows.map(record => {
      return {
        ...record,
        unitOperationName: record.unitOperation,
        unitOperation: record.sortByOrderIndex,
        unitOperations: record.sortByOrderIndex,
        stepName: record.step,
        step: record.sortByOrderIndex,
        steps: record.sortByOrderIndex,
        sortedUnitOperations: [],
        sortedSteps: []
      };
    });

    // group by UO and Step
    const groupedRows = sortedByOrderRows.reduce((distinctRows, currentRow) => {
      let existingRow = distinctRows.find(x => x.id === currentRow.id);
      if (!existingRow) {
        const clonedRow = {...currentRow};
        distinctRows.push(clonedRow);
        existingRow = clonedRow;
      }

      if (currentRow.unitOperationId && currentRow.unitOperationId !== -1) {
        if (!existingRow.sortedUnitOperations.find(x => x.id === currentRow.unitOperationId)) {
          existingRow.sortedUnitOperations.push({
            id: currentRow.unitOperationId,
            typeCode: CommonUtils.getTypeCodeForModelName("UnitOperation"),
            name: currentRow.unitOperationName
          });
        }
      }

      if (currentRow.stepId && currentRow.stepId !== -1) {
        if (!existingRow.sortedSteps.find(x => x.id === currentRow.stepId)) {
          existingRow.sortedSteps.push({
            id: currentRow.stepId,
            typeCode: CommonUtils.getTypeCodeForModelName("Step"),
            name: currentRow.stepName
          });
        }
      }

      existingRow.unitOperationName = existingRow.sortedUnitOperations.map(x => CommonUtils.getRecordCustomLabelForDisplay(x)).join(", ");
      existingRow.stepName = existingRow.sortedSteps.map(x => CommonUtils.getRecordCustomLabelForDisplay(x)).join(", ");

      // properties used by techTransfer record diff
      existingRow.stepNameSimple = existingRow.sortedSteps.map(x => x.name).join(", ");
      existingRow.unitOperationNameSimple = existingRow.sortedUnitOperations.map(x => x.name).join(", ");

      return distinctRows;
    }, []);


    return groupedRows;
  }
}

module.exports.RecordsIndexer = RecordsIndexer;