var _a;
/**
 * @hidden
 */
export var noRecords = 'grid.noRecords';
/**
 * @hidden
 */
export var pagerInfo = 'grid.pagerInfo';
/**
 * @hidden
 */
export var pagerFirstPage = 'grid.pagerFirstPage';
/**
 * @hidden
 */
export var pagerPreviousPage = 'grid.pagerPreviousPage';
/**
 * @hidden
 */
export var pagerNextPage = 'grid.pagerNextPage';
/**
 * @hidden
 */
export var pagerLastPage = 'grid.pagerLastPage';
/**
 * @hidden
 */
export var pagerItemPerPage = 'grid.pagerItemsPerPage';
/**
 * @hidden
 */
export var pagerPage = 'grid.pagerPage';
/**
 * @hidden
 */
export var pagerOf = 'grid.pagerOf';
/**
 * @hidden
 */
export var pagerTotalPages = 'grid.pagerTotalPages';
/**
 * @hidden
 */
export var groupPanelEmpty = 'grid.groupPanelEmpty';
/**
 * @hidden
 */
export var groupColumn = 'grid.groupColumn';
/**
 * @hidden
 */
export var ungroupColumn = 'grid.ungroupColumn';
/**
 * @hidden
 */
export var filterClearButton = 'grid.filterClearButton';
/**
 * @hidden
 */
export var filterSubmitButton = 'grid.filterSubmitButton';
/**
 * @hidden
 */
export var filterTitle = 'grid.filterTitle';
/**
 * @hidden
 */
export var sortAscending = 'grid.sortAscending';
/**
 * @hidden
 */
export var sortDescending = 'grid.sortDescending';
/**
 * @hidden
 */
export var searchPlaceholder = 'grid.searchPlaceholder';
/**
 * @hidden
 */
export var filterCheckAll = 'grid.filterCheckAll';
/**
 * @hidden
 */
export var filterChooseOperator = 'grid.filterChooseOperator';
/**
 * @hidden
 */
export var filterSelectedItems = 'grid.filterSelectedItems';
/**
 * @hidden
 */
export var sortAriaLabel = 'grid.sortAriaLabel';
/**
 * @hidden
 */
export var filterAriaLabel = 'grid.filterAriaLabel';
/**
 * @hidden
 */
export var groupExpand = 'grid.groupExpand';
/**
 * @hidden
 */
export var groupCollapse = 'grid.groupCollapse';
/**
 * @hidden
 */
export var detailExpand = 'grid.detailExpand';
/**
 * @hidden
 */
export var detailCollapse = 'grid.detailCollapse';
/**
 * @hidden
 */
export var messages = (_a = {},
    _a[detailExpand] = 'Expand detail row',
    _a[detailCollapse] = 'Collapse detail row',
    _a[groupExpand] = 'Expand group',
    _a[groupCollapse] = 'Collapse Group',
    _a[noRecords] = 'No records available',
    _a[groupPanelEmpty] = 'Drag a column header and drop it here to group by that column',
    _a[filterClearButton] = 'Clear',
    _a[filterSubmitButton] = 'Filter',
    _a[filterTitle] = 'Filter',
    _a[sortAscending] = 'Sort Ascending',
    _a[sortDescending] = 'Sort Descending',
    _a[sortAriaLabel] = 'Sortable',
    _a[groupColumn] = 'Group Column',
    _a[ungroupColumn] = 'Ungroup Column',
    _a[pagerItemPerPage] = 'items per page',
    _a[pagerInfo] = '{0} - {1} of {2} items',
    _a[pagerFirstPage] = 'Go to the first page',
    _a[pagerPreviousPage] = 'Go to the previous page',
    _a[pagerNextPage] = 'Go to the next page',
    _a[pagerLastPage] = 'Go to the last page',
    _a[pagerPage] = 'Page',
    _a[pagerOf] = 'of',
    _a[pagerTotalPages] = '{0}',
    _a[searchPlaceholder] = 'Search',
    _a[filterCheckAll] = 'Check All',
    _a[filterChooseOperator] = 'Choose Operator',
    _a[filterSelectedItems] = 'selected items',
    _a[filterAriaLabel] = 'Filter',
    _a['grid.filterEqOperator'] = 'Is equal to',
    _a['grid.filterNotEqOperator'] = 'Is not equal to',
    _a['grid.filterIsNullOperator'] = 'Is null',
    _a['grid.filterIsNotNullOperator'] = 'Is not null',
    _a['grid.filterIsEmptyOperator'] = 'Is empty',
    _a['grid.filterIsNotEmptyOperator'] = 'Is not empty',
    _a['grid.filterStartsWithOperator'] = 'Starts with',
    _a['grid.filterContainsOperator'] = 'Contains',
    _a['grid.filterNotContainsOperator'] = 'Does not contain',
    _a['grid.filterEndsWithOperator'] = 'Ends with',
    _a['grid.filterGteOperator'] = 'Is greater than or equal to',
    _a['grid.filterGtOperator'] = 'Is greater than',
    _a['grid.filterLteOperator'] = 'Is less than or equal to',
    _a['grid.filterLtOperator'] = 'Is less than',
    _a['grid.filterIsTrue'] = 'Is true',
    _a['grid.filterIsFalse'] = 'Is false',
    _a['grid.filterBooleanAll'] = '(All)',
    _a['grid.filterAfterOrEqualOperator'] = 'Is after or equal to',
    _a['grid.filterAfterOperator'] = 'Is after',
    _a['grid.filterBeforeOperator'] = 'Is before',
    _a['grid.filterBeforeOrEqualOperator'] = 'Is before or equal to',
    _a['grid.filterAndLogic'] = 'And',
    _a['grid.filterOrLogic'] = 'Or',
    _a);
/**
 * @hidden
 */
export function pagerMessagesMap(pagerMessageKey) {
    var messageKey = pagerMessageKey.replace(/^pager\.([a-z])/, function (_match, group) { return 'grid.pager' + group.toUpperCase(); });
    return ({ messageKey: messageKey, defaultMessage: messages[messageKey] });
}
