"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseReactComponent from "../../base_react_component";

export class FilterBar extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.onSelectedFilterChange = this.onSelectedFilterChange.bind(this);
    this.getDefaultSelectedItem = this.getDefaultSelectedItem.bind(this);
    this.getFilterItemByKey = this.getFilterItemByKey.bind(this);
    this.applyFilter = this.applyFilter.bind(this);

    const filter = UIUtils.getParameterByName("filter");

    const defaultSelectedItem = this.getDefaultSelectedItem();
    const selected = filter ? filter : (defaultSelectedItem ? defaultSelectedItem.key : null);

    this.state = {
      selected,
    };

    this.onSelectedFilterChange(selected);
  }

  getDefaultSelectedItem() {
    const queryStringValue = UIUtils.getParameterByName("filter");
    const {items} = this.props;

    const defaultFilter = queryStringValue
      ? this.getFilterItemByKey(queryStringValue)
      : this.props.filters.find(filter => (
        typeof filter.selected === "function"
          ? filter.selected(this.applyFilter(items || [], filter))
          : filter.selected
      ));

    return defaultFilter || this.props.filters[0];
  }

  componentDidMount() {
    super.componentDidMount();
    const defaultSelectedItem = this.getDefaultSelectedItem();

    window.addEventListener("popstate", (event) => {
      const selected = event.state && event.state.selected ? event.state.selected : defaultSelectedItem.key;
      this.setStateSafely({selected});
      this.notifyFilterChange(this.getFilterItemByKey(selected));
    });
  }

  componentDidUpdate(oldProps, oldStateIgnore, snapshotIgnore) {
    if (JSON.stringify(this.props.items) !== JSON.stringify(oldProps.items)) {
      const defaultFilter = this.getDefaultSelectedItem();

      if (defaultFilter && defaultFilter.key) {
        this.setStateSafely({selected: defaultFilter.key});
        this.notifyFilterChange(defaultFilter);
      }
    }
  }

  onSelectedFilterChange(newKey, oldKey) {
    // do nothing if the key didn't change
    if (newKey === oldKey) {
      return;
    }

    const newFilter = this.getFilterItemByKey(newKey);
    const oldFilter = oldKey ? this.getFilterItemByKey(oldKey) : null;

    this.setStateSafely({selected: newKey});
    if (this._isMounted) {
      const historyState = {...this.state, selected: newKey};
      UIUtils.pushHistoryURLWithParameterChanges(historyState, {filter: newKey});
    }
    this.notifyFilterChange(newFilter, oldFilter);
  }

  notifyFilterChange(newFilter, oldFilter) {
    if (this.props && this.props.onSelectedFilterChange) {
      this.props.onSelectedFilterChange(newFilter, oldFilter);
    }
  }

  getFilterItemByKey(key) {
    return this.props.filters.find(item => (item.key || "").toLowerCase() === (key || "").toLowerCase());
  }

  render() {
    const {items} = this.props;
    const oldKey = this.state.selected;

    return (
      <nav className={"list-table-filter-bar" + this.getClassForLoading()}>
        <ul>
          {
            this.props.filters.map(filterItem => {
              const key = filterItem.key;
              const isSelected = this.state.selected.toLowerCase() === key.toLowerCase();
              const onClick = () => this.onSelectedFilterChange(key, oldKey);

              const filteredItems = this.applyFilter(items, filterItem);

              return (
                <li key={key} id={`listingFilter_${UIUtils.convertToCamelCaseId(filterItem.name)}`}>
                  <a className={isSelected ? "selected" : ""} onClick={onClick}>
                    <span className="filter-count">{filteredItems.length}</span>
                    <span className="filter-name">{filterItem.name}</span>
                  </a>
                </li>
              );
            })
          }
        </ul>
      </nav>
    );
  }

  applyFilter(items, filterItem) {
    const filteredItems = items.filter((item, index) => filterItem.filter(item, index, items, true));
    return filteredItems;
  }
}
