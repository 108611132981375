"use strict";

import React from "react";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import ReferenceDocuments from "@cherrycircle/fda-docs";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseFinalAttribute from "./base_final_attribute";
import CheckboxAttribute from "../../editor/attributes/checkbox_attribute";


// i18next-extract-mark-ns-start qtpp
class FQA extends BaseFinalAttribute {
  constructor(props) {
    const {t} = props;
    super(props, "fqa", "FQA", t("Final Quality Attribute (FQA)"));
  }

  componentDidMount() {
    document.title = "QbDVision Final Quality Attribute (FQA)";

    return super.componentDidMount();
  }

  renderStabilityIndicating() {
    return (
      <CheckboxAttribute name="stabilityIndicating"
                         parent={this}
      />
    );
  }

  getInfoTooltip() {
    const {t} = this.props;

    return (
      <InfoTooltip id="infoFQA"
                   fdaGuidanceURL={ReferenceDocuments.Q8_GUIDELINE}
                   fdaGuidancePage={16}
                   fdaGuidanceOffset={200}
                   verbiage={<div>
                     {t("A physical, chemical, biological, or microbiological property or characteristic that should be within an appropriate limit, range, or distribution to ensure the desired product quality.")}
                   </div>}
      />
    );
  }
}

export default I18NWrapper.wrap(FQA, "qtpp");
// i18next-extract-mark-ns-stop qtpp
