var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useAnimation } from '@progress/kendo-react-animation';
import { classNames, useRtl, getTabIndex } from '@progress/kendo-react-common';
import { calculatePercentage, updateProgress, truncateNumber } from '../common/utils';
import { usePrevious } from './hooks/usePrevious';
import { DEFAULT_ANIMATION_DURATION, NO_ANIMATION } from '../common/constants';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
/**
 * Represents the [KendoReact ProgressBar component]({% slug overview_progressbar %}).
 *
 * @example
 * ```jsx
 * const App = () => {
 *   return (
 *       <ProgressBar value={75}/>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var ProgressBar = React.forwardRef(function (props, target) {
    validatePackage(packageMetadata);
    var animation = props.animation, disabled = props.disabled, _a = props.reverse, reverse = _a === void 0 ? defaultProps.reverse : _a, orientation = props.orientation, labelVisible = props.labelVisible, labelPlacement = props.labelPlacement, _b = props.max, max = _b === void 0 ? defaultProps.max : _b, _c = props.min, min = _c === void 0 ? defaultProps.min : _c, tabIndex = props.tabIndex, className = props.className, style = props.style, emptyStyle = props.emptyStyle, emptyClassName = props.emptyClassName, progressStyle = props.progressStyle, progressClassName = props.progressClassName;
    var elementRef = React.useRef(null);
    var progressStatusRef = React.useRef(null);
    var progressStatusWrapRef = React.useRef(null);
    var focusElement = React.useCallback(function () {
        if (elementRef.current) {
            elementRef.current.focus();
        }
    }, []);
    React.useImperativeHandle(target, function () { return ({
        element: elementRef.current,
        progressStatusElement: progressStatusRef.current,
        progressStatusWrapElement: progressStatusWrapRef.current,
        focus: focusElement
    }); });
    var value = (props.value || defaultProps.value);
    var prevValue = usePrevious(value);
    var indeterminateProp = props.value === null;
    var dir = useRtl(elementRef, props.dir);
    var isVertical = orientation === 'vertical';
    var formattedLabel = truncateNumber(value);
    var labelProps = {
        value: value
    };
    var renderLabel = (labelVisible
        ? props.label
            ? React.createElement("span", { className: 'k-progress-status' },
                React.createElement(props.label, __assign({}, labelProps)))
            : React.createElement("span", { className: 'k-progress-status' }, formattedLabel)
        : undefined);
    var wrapperProps = {
        className: classNames('k-widget k-progressbar', {
            'k-progressbar-horizontal': !isVertical,
            'k-progressbar-vertical': isVertical,
            'k-progressbar-reverse': reverse,
            'k-progressbar-indeterminate': indeterminateProp,
            'k-disabled': disabled
        }, className),
        ref: elementRef,
        dir: dir,
        tabIndex: getTabIndex(tabIndex, disabled),
        role: 'progressbar',
        'aria-label': props.ariaLabel,
        'aria-valuemin': min,
        'aria-valuemax': max,
        'aria-valuenow': indeterminateProp ? undefined : value,
        'aria-disabled': disabled,
        style: style
    };
    var positionClasses = classNames('k-progress-status-wrap', {
        'k-progress-start': labelPlacement === 'start',
        'k-progress-center': labelPlacement === 'center',
        'k-progress-end': labelPlacement === 'end' || labelPlacement === undefined
    });
    var animationDuration = typeof animation !== 'boolean' && animation !== undefined
        ? animation.duration
        : animation
            ? DEFAULT_ANIMATION_DURATION
            : NO_ANIMATION;
    var handleStart = React.useCallback(function () {
        var percentage = calculatePercentage(min, max, prevValue);
        updateProgress(progressStatusRef, progressStatusWrapRef, percentage, isVertical);
    }, [isVertical, max, min, prevValue]);
    var handleUpdate = React.useCallback(function (progress) {
        var percentage = calculatePercentage(min, max, prevValue + (value - prevValue) * progress);
        updateProgress(progressStatusRef, progressStatusWrapRef, percentage, isVertical);
    }, [min, max, prevValue, value, isVertical]);
    var handleEnd = React.useCallback(function () {
        var percentage = calculatePercentage(min, max, value);
        updateProgress(progressStatusRef, progressStatusWrapRef, percentage, isVertical);
    }, [isVertical, max, min, value]);
    useAnimation({
        duration: animationDuration,
        onStart: handleStart,
        onUpdate: handleUpdate,
        onEnd: handleEnd
    }, [value, animationDuration]);
    return (React.createElement("div", __assign({}, wrapperProps),
        React.createElement("span", { className: positionClasses + (emptyClassName ? ' ' + emptyClassName : ''), style: emptyStyle }, renderLabel),
        React.createElement("div", { className: 'k-selected', style: progressStyle, ref: progressStatusRef },
            React.createElement("span", { className: positionClasses + (progressClassName ? ' ' + progressClassName : ''), ref: progressStatusWrapRef }, renderLabel))));
});
ProgressBar.propTypes = {
    animation: PropTypes.any,
    ariaLabel: PropTypes.string,
    disabled: PropTypes.bool,
    reverse: PropTypes.bool,
    label: PropTypes.any,
    labelVisible: PropTypes.bool,
    labelPlacement: PropTypes.oneOf(['start', 'center', 'end']),
    max: PropTypes.number,
    min: PropTypes.number,
    value: PropTypes.number,
    tabIndex: PropTypes.number,
    emptyStyle: PropTypes.object,
    emptyClassName: PropTypes.string,
    progressStyle: PropTypes.object,
    progressClassName: PropTypes.string
};
var defaultProps = {
    animation: false,
    min: 0,
    max: 100,
    value: 0,
    disabled: false,
    reverse: false,
    labelVisible: true
};
ProgressBar.defaultProps = defaultProps;
ProgressBar.displayName = 'KendoProgressBar';
