"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import ImportFileUploader from "../widgets/general/import_file_upload";
import ImportRecordInstructions from "../widgets/general/import_record_instructions";
import { IMPORT_STEPS, NON_PROCESS_RELATED_MODELS } from "../constants/import_constants";
import { FILE_STATUS } from "../../helpers/document_transfer_helper";
import { ListOption } from "../../editor/widgets/list_option";
import FieldTooltip from "../../widgets/tooltips/field_tooltip";
import ImportProcessInfo from "../widgets/general/import_process_info";
import ImportProcessSelect from "../widgets/general/import_process_select";
import ImportBaseStep from "./import_base_step";
import ImportBatchInfo from "../widgets/general/import_batch_info";
import ImportBatchSelect from "../widgets/general/import_batch_select";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import GenerateImportTemplateLink from "../../batches/widgets/generate_import_template_link";
import { IMPORT_ACTION } from "../../../server/common/editables/common_batches";

/**
 * This implements the Import wizard import file selection step
 */
export default class ImportFileSelectionStep extends ImportBaseStep {
  constructor(props) {
    super(props);

    this.setStateSafely({
      fileData: this.props.fileData ? this.props.fileData : {fileName: ""},
      supportFileData: this.props.supportFileData ? this.props.supportFileData : {fileName: ""},
      importType: IMPORT_ACTION.APPEND,
    });

    this.batchesLoaded = false;
  }

  componentDidUpdate() {
    super.componentDidUpdate();

    const {process} = this.props;

    if (process && this.batchSelectionRequired() && !this.batchesLoaded) {
      this.loadBatches();
      this.batchesLoaded = true;
    }
  }

  activate(changeInfo) {
    const {onWizardStepDataUpdated} = this.props;

    onWizardStepDataUpdated({currentImportStep: IMPORT_STEPS.FileSelection}, () => {
      UIUtils.clearError();
      super.activate(changeInfo);
    });
  }

  batchSelectionRequired() {
    const {dependency} = this.props;
    return this.isProcessDataImport() && dependency && dependency !== "Attribute";
  }

  loadBatches() {
    const {projectId, process} = this.props;
    if (projectId && process) {
      new TypeaheadObjectCache("Batch", projectId, process.id).loadOptions(this.handleBatchesLoaded);
    }
  }

  isDefaultBatchValueSet() {
    return this.props.batchIdFromURLParam;
  }

  isDefaultProcessSet() {
    return this.props.processIdFromURLParam;
  }

  handleBatchesLoaded(batches) {
    const {batchIdFromURLParam, selectedBatch, onWizardStepDataUpdated} = this.props;
    const externalBatchId = batchIdFromURLParam ? UIUtils.parseInt(batchIdFromURLParam) : -1;

    // This is important. We would not want to expose CoA records to the user to select from at this point.
    batches = batches.filter(batch => batch.type === "Batch");
    const newSelectedBatch = selectedBatch || batches.find(batch => batch.id === externalBatchId) || (batches && batches[0]);
    onWizardStepDataUpdated({
      selectedBatch: newSelectedBatch,
      stepsDisabledStatus: {
        [IMPORT_STEPS.DataReview]: this.nextStepIsDisabled(newSelectedBatch),
      },
      dataReviewRequired: true,
    }, () => {
      this.setStateSafely({batches});
    });
  }

  handleBatchSelected(e) {
    const {onWizardStepDataUpdated, importType} = this.props;
    const {batches} = this.state;
    const selectedBatch = batches.find(batch => batch.customID === e.target.value);

    onWizardStepDataUpdated({
      selectedBatch,
      importType: selectedBatch.hasBatchData ? importType : IMPORT_ACTION.APPEND,
      dataReviewRequired: true,
      stepsDisabledStatus: {
        [IMPORT_STEPS.DataReview]: this.nextStepIsDisabled(selectedBatch)
      },
    });
  }

  async handleNewFileSelection(fileData) {
    let {loadingCursorState} = this.state;
    if (!loadingCursorState) {
      await UIUtils.showLoadingCursor();
      loadingCursorState = true;
    }

    this.setStateSafely({
      fileData,
      loadingCursorState
    }, () => {
      let {onWizardStepDataUpdated} = this.props;
      const {fileData} = this.state;

      onWizardStepDataUpdated({
        fileData,
        dataReviewRequired: true,
        validations: [],
        recordsCount: 0,
        stepsDisabledStatus: {
          [IMPORT_STEPS.DataImport]: true,
          [IMPORT_STEPS.DataReview]: true,
        },
      });
    });
  }

  isFileUploadInProgress() {
    const {fileData, supportFileData} = this.state;

    return fileData.fileStatus === FILE_STATUS.NEW_FILE_SELECTED
      || fileData.fileStatus === FILE_STATUS.UPLOADING
      || supportFileData.fileStatus === FILE_STATUS.NEW_FILE_SELECTED
      || supportFileData.fileStatus === FILE_STATUS.UPLOADING;
  }

  isFileSelected() {
    const {fileData} = this.state;
    return fileData.fileStatus === FILE_STATUS.UPLOADED;
  }

  nextStepIsDisabled(newSelectedBatch) {
    let {hasPermissionToImport} = this.props;
    const selectedBatch = newSelectedBatch || this.props.selectedBatch;
    const fileUploadInProgress = this.isFileUploadInProgress();
    const isFileSelected = this.isFileSelected();

    return !isFileSelected
      || fileUploadInProgress
      || !hasPermissionToImport
      || (this.batchSelectionRequired() && !selectedBatch);
  }

  handleFileDataUpdated(fileData) {
    this.setStateSafely({
      fileData,
    }, () => {
      const {fileData} = this.state;
      let {isPaperImport, onWizardStepDataUpdated} = this.props;
      const fileUploadInProgress = this.isFileUploadInProgress();
      let stepsDisabledStatus = {
        [IMPORT_STEPS.DataReview]: this.nextStepIsDisabled(),
      };

      if (!isPaperImport) {
        stepsDisabledStatus[IMPORT_STEPS.DataImport] = true;
      }

      onWizardStepDataUpdated({
        fileData,
        stepsDisabledStatus
      });

      if (!fileUploadInProgress) {
        this.setStateSafely({loadingCursorState: false});
        UIUtils.hideLoadingCursor();
      }
    });
  }

  handleFileUploadCancel(fileData) {
    this.setStateSafely({
      fileData
    }, () => {
      let {onWizardStepDataUpdated} = this.props;
      const {fileData} = this.state;

      onWizardStepDataUpdated({
        fileData,
        dataReviewRequired: false,
        validations: [],
        recordsCount: 0,
        stepsDisabledStatus: {
          [IMPORT_STEPS.DataImport]: true,
          [IMPORT_STEPS.DataReview]: true,
        },
      });
    });
  }

  async handleNewSupportFileSelection(supportFileData) {
    let {supportFileLoadingCursorState} = this.state;
    if (!supportFileLoadingCursorState) {
      await UIUtils.showLoadingCursor();
      supportFileLoadingCursorState = true;
    }

    this.setStateSafely({
      supportFileData,
      supportFileLoadingCursorState
    }, () => {
      let {onWizardStepDataUpdated} = this.props;
      const {supportFileData} = this.state;

      onWizardStepDataUpdated({
        supportFileData,
        stepsDisabledStatus: {
          [IMPORT_STEPS.DataImport]: true,
          [IMPORT_STEPS.DataReview]: true,
        },
      });
    });
  }

  handleSupportFileDataUpdated(supportFileData) {
    this.setStateSafely({
      supportFileData
    }, () => {
      const {supportFileData} = this.state;
      let {onWizardStepDataUpdated} = this.props;
      const fileUploadInProgress = this.isFileUploadInProgress();
      let stepsDisabledStatus = {
        [IMPORT_STEPS.DataReview]: this.nextStepIsDisabled(),
      };

      onWizardStepDataUpdated({
        supportFileData,
        stepsDisabledStatus,
      });

      if (!fileUploadInProgress) {
        this.setStateSafely({supportFileLoadingCursorState: false});
        UIUtils.hideLoadingCursor();
      }
    });
  }

  handleSupportFileUploadCancel(supportFileData) {
    this.setStateSafely({
      supportFileData
    }, () => {
      const {supportFileData} = this.state;
      let {onWizardStepDataUpdated} = this.props;

      onWizardStepDataUpdated({
        supportFileData,
        stepsDisabledStatus: {
          [IMPORT_STEPS.DataReview]: this.nextStepIsDisabled()
        },
      });
    });
  }

  handlerImportTypeChange(e) {
    const {onWizardStepDataUpdated} = this.props;
    onWizardStepDataUpdated({
      importType: e.target.value,
      dataReviewRequired: true,
    });
  }

  renderBatchInfo() {
    const {selectedBatch, modelName, isMultiProcess} = this.props;
    const batches = this.state.batches || [];

    if (this.isDefaultBatchValueSet() && selectedBatch && modelName !== "Attribute") {
      return (
        <div className="col-4">
          <ImportBatchInfo selectedBatch={selectedBatch} />
        </div>
      );
    } else if (!this.isDefaultBatchValueSet() && modelName !== "Attribute") {
      return (
        <div className="col-4">
          <ImportBatchSelect batches={batches}
                             isMultiProcess={isMultiProcess}
                             onBatchSelected={this.handleBatchSelected}
          />
        </div>);
    }

    return "";
  }

  renderProcessInfo() {
    const {process, processes, isMultiProcess, modelName} = this.props;

    // We shouldn't show the process dropdown in Supplier/User
    if (NON_PROCESS_RELATED_MODELS.includes(modelName)) {
      return "";
    }

    if (this.isDefaultProcessSet() && process) {
      return isMultiProcess ? (
        <div className="col-4">
          <ImportProcessInfo process={this.props.process} />
        </div>
      ) : "";
    } else {
      return (
        <div className="col-4">
          <ImportProcessSelect processes={processes}
                               process={process}
                               showRequiredMark={true}
                               onProcessChange={this.handleProcessSelected}
          />
        </div>);
    }
  }

  handleProcessSelected(e) {
    const {processes, onWizardStepDataUpdated} = this.props;
    const process = processes.find(process => process.name === e.target.value);
    onWizardStepDataUpdated({
      process,
      selectedBatch: null,
      dataReviewRequired: true,
    }, () => {
      if (this.batchSelectionRequired()) {
        this.loadBatches();
      }
    });
  }

  renderImportTypeDropDown() {
    const importType = this.props.importType || this.state.importType;
    const {modelName, selectedBatch} = this.props;
    const dataToReplace = (modelName === "FQA"
      ? "release"
      : "manufacturing");
    const isEmptyOrMissingBach = (modelName !== "Attribute"
      && (!selectedBatch || !selectedBatch.hasBatchData));

    return (
      <div className="col-12">
        <div className="row">
          <div className="col-4">
            <label id="importTypeDropDownLabel"
                   htmlFor="importTypeDropDown"
            >
                  <span>
                    Import Type
                  </span>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-4 import-type-selection-div">
            <select className="form-control"
                    id="importTypeDropDown"
                    value={importType}
                    disabled={!!isEmptyOrMissingBach}
                    onChange={this.handlerImportTypeChange}
            >
              <ListOption item={IMPORT_ACTION.APPEND} key={IMPORT_ACTION.APPEND} />
              <ListOption item={IMPORT_ACTION.REPLACE} key={IMPORT_ACTION.REPLACE} />
            </select>
            <FieldTooltip id="batchAppendReplaceInfo"
                          text={isEmptyOrMissingBach ? "This option is not required. There is no data in the selected batch."
                            : (<div>
                                <p>
                                  <b>Append to batch data:</b> Adds all data from your import file to any existing
                                  records. Use this type for adding new attribute and parameter records or to adding
                                  supplemental measurements to existing records. All the measurement data in your
                                  input file should not have been previously uploaded.
                                </p>
                                {
                                  modelName === "Attribute" ? (
                                    <p>
                                      <b>Replace batch data:</b> Overwrites the prior data in the batches specified in the
                                      import file and replaces it with the new data in the import file.
                                      Prior values are available in the batch history.
                                    </p>
                                  ) : (
                                    <p>
                                      <b>Replace batch data:</b> Overwrites the prior {dataToReplace} data in the batch and
                                      replaces it with the new data in the import file. Prior values are available in the batch history.
                                    </p>
                                  )
                                }

                                <p>
                                  {"For more information, please see our "}
                                  <a href="https://cherrycircle.atlassian.net/wiki/spaces/QK/pages/1096679433/Importing+Manufacturing+Data+-+Append+vs+Replace"
                                     id="importingManufacturingDataUrl"
                                     target="_blank"
                                     rel="noreferrer noopener"
                                  >
                                    how-to guide
                                  </a>.
                                </p>
                              </div>
                            )}
            />
          </div>
        </div>
      </div>
    );
  }

  handleGenerateImportTemplateLinkClick() {
    const {onWizardStepDataUpdated} = this.props;
    onWizardStepDataUpdated({showGenerateImportTemplatePopup: true});
  }

  isProcessDataImport() {
    return this.props.dataSectionName === "Process Capability";
  }

  renderStep() {
    let {
      fileData, supportFileData, modelName, dataSectionName, dependency, projectType, isPaperImport,
      modelNameForDisplay, hasProjectTypeDependedTemplates, notIncludedProjectType
    } = this.props;
    let nextStepDisabled = this.isNextStepDisabled();
    let showSupportFileData = !!dataSectionName && !isPaperImport;
    let allowedFiles = isPaperImport ? ".pdf,.png,.jpg" : ".xlsx,.xls";
    let fileDataLabel = isPaperImport ? "Batch Record:" : "Please choose a file to import:";

    let instructions;
    if (isPaperImport) {
      instructions = `Use this to upload a PDF or PNG to import batch data for multiple attributes.`;
    } else if (dataSectionName) {
      instructions = `Populate the file that was just downloaded with your batch data.
                    Then upload it here to review importing the data into QbDVision. Please attach or link to 
                    any supporting documentation for your upload. For example, a PDF of your original batch record. 
                    This supporting record will be available with the data uploaded above when viewing your batch 
                    records in QbDVision.`;
    } else {
      instructions = `Use this to create or update multiple
                    ${UIUtils.pluralize(modelNameForDisplay)}
                    ${dependency ? ` for
                    ${dependency.toLowerCase()}` : ""} from an Excel (.xls/.xlsx) file.`;
    }

    return (
      <Fragment>
        <div className="col-12">
          <div className="row import-wizard-step-inner-container">
            <div className="col">
              <div className="row import_row_div">
                {this.renderProcessInfo()}
                {this.isProcessDataImport() ? this.renderBatchInfo() : ""}
              </div>
              {
                this.isProcessDataImport() ? (
                  <div className="row import_row_div">
                    {this.renderImportTypeDropDown()}
                  </div>
                ) : ""}
              <div className="row">
                <div className="col-12">
                  <div className="row import-file-upload-div">
                    <ImportFileUploader
                      id="importFileUpload"
                      label={fileDataLabel}
                      onUploadCancel={this.handleFileUploadCancel}
                      onUpdateFileData={this.handleFileDataUpdated}
                      onNewFileSelection={this.handleNewFileSelection}
                      fileData={fileData}
                      allowedFiles={allowedFiles}
                    />
                    {showSupportFileData ? (
                      <ImportFileUploader
                        id="importSupportFileUpload"
                        label="Supporting Batch Record:"
                        className="import-support-file-uploader"
                        onUploadCancel={this.handleSupportFileUploadCancel}
                        onUpdateFileData={this.handleSupportFileDataUpdated}
                        onNewFileSelection={this.handleNewSupportFileSelection}
                        fileData={supportFileData}
                      />
                    ) : ""}
                    <ImportRecordInstructions
                      modelName={modelName}
                      showDownloads={!dataSectionName}
                      showInstructions={true}
                      showNewProjectLink={false}
                      dependency={dependency}
                      projectType={projectType}
                      hasProjectTypeDependedTemplates={hasProjectTypeDependedTemplates}
                      notIncludedProjectType={notIncludedProjectType}
                      className="import-record-file-upload-instructions-panel"
                      instructions={instructions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row import-screen-footer">
            <div className="col-12">
              <button
                disabled={nextStepDisabled}
                className="btn btn-lg btn-primary import-footer-btn"
                id="importFileStepNextButton"
                onClick={this.handleMoveToNextStep}
              >
                {isPaperImport ? "Extract Data >" : "Review Data >"}
              </button>
              <div className="import-file-step-exit-div">
                {this.renderImportExitButton()}
                {this.isProcessDataImport() ? (
                  <div className="import-file-step-template-link-container">
                    <span>&gt; </span>
                    <GenerateImportTemplateLink onGenerateImportTemplateLinkClick={this.handleGenerateImportTemplateLinkClick} />
                  </div>
                ) : ""}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
