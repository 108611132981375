var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { bezierCommand, controlPoint, line, svgPath } from './utils/svg-calc';
import { getColorFromHSV, getRGBA, getContrastFromTwoRGBAs } from './utils/color-parser';
var AA_CONTRAST = 4.5;
var AAA_CONTRAST = 7;
var STEP_COUNT = 16;
;
/**
 * @hidden
 */
var ColorContrastSvg = /** @class */ (function (_super) {
    __extends(ColorContrastSvg, _super);
    function ColorContrastSvg() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ColorContrastSvg.prototype.renderSvgCurveLine = function () {
        var _this = this;
        var gradientRectMetrics = this.props.metrics;
        var findValue = function (contrast, saturation, low, high, comparer) {
            var mid = (low + high) / 2;
            var hsva = Object.assign({}, _this.props.hsva, { s: saturation / gradientRectMetrics.width, v: 1 - mid / gradientRectMetrics.height });
            var currentContrast = getContrastFromTwoRGBAs(getRGBA(getColorFromHSV(hsva)), getRGBA(_this.props.backgroundColor || ''));
            if (low + 0.5 > high) {
                if (currentContrast < contrast + 1 && currentContrast > contrast - 1) {
                    return mid;
                }
                else {
                    return null;
                }
            }
            if (comparer(currentContrast, contrast)) {
                return findValue(contrast, saturation, low, high - (high - low) / 2, comparer);
            }
            return findValue(contrast, saturation, low + (high - low) / 2, high, comparer);
        };
        var getPaths = function (contrast, stepCount, reversed) {
            if (reversed === void 0) { reversed = false; }
            var points = [];
            for (var i = 0; i <= gradientRectMetrics.width; i += gradientRectMetrics.width / stepCount) {
                var value = findValue(contrast, i, 0, gradientRectMetrics.height, reversed ? (function (a, b) { return a < b; }) : (function (a, b) { return a > b; }));
                if (value !== null) {
                    points.push([i, value]);
                }
            }
            return points;
        };
        var bezierCommandCalc = bezierCommand(controlPoint(line));
        return svgPath(getPaths(AA_CONTRAST, STEP_COUNT), bezierCommandCalc) +
            svgPath(getPaths(AA_CONTRAST, STEP_COUNT, true), bezierCommandCalc) +
            svgPath(getPaths(AAA_CONTRAST, STEP_COUNT), bezierCommandCalc) +
            svgPath(getPaths(AAA_CONTRAST, STEP_COUNT, true), bezierCommandCalc);
    };
    ColorContrastSvg.prototype.shouldComponentUpdate = function (nextProps) {
        if (nextProps.hsva.h === this.props.hsva.h &&
            nextProps.hsva.a === this.props.hsva.a &&
            this.props.backgroundColor === nextProps.backgroundColor) {
            return false;
        }
        return true;
    };
    ColorContrastSvg.prototype.render = function () {
        return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", className: 'k-color-contrast-svg', dangerouslySetInnerHTML: { __html: this.renderSvgCurveLine() }, style: {
                position: 'absolute',
                overflow: 'visible',
                pointerEvents: 'none',
                left: 0,
                top: 0,
                zIndex: 3
            } }));
    };
    return ColorContrastSvg;
}(React.Component));
export { ColorContrastSvg };
