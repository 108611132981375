"use strict";

import * as CommonUtils from "../../server/common/generic/common_utils";
import * as UIUtils from "../ui_utils";
import { REPORT_THUMBNAILS_ENUM } from "../reports/constants/report_constants";

/**
 * The functions in this file are responsible for helping with figure out the URL given some information about the type of record.
 */
export function getURLByKey(keyString, operation, approved, projectId = null) {
  let key = UIUtils.parseKey(keyString);
  let url = getURLByTypeCodeAndId(key.typeCode, operation, key.id, approved);
  if (projectId) {
    url += "&projectId=" + projectId;
  }

  return url;
}

export function getUserPageURL(user) {
  const defaultURL = getURLByTypeNameAndId("User", "View", user.id, false);
  const isUserAPIKey = user.email === null;
  return isUserAPIKey ? defaultURL.replace("users", "users/apiKeys") : defaultURL;
}

export function getURLByTypeNameAndId(type, operation, id, approved) {
  return getURLByTypeCodeAndId(UIUtils.getTypeCodeForModelName(type), operation, id, approved);
}

export function getURLByTypeCodeAndId(typeCode, operation, idOrProjectId, approved) {
  return CommonUtils.getURLByTypeCodeAndId(typeCode, operation, idOrProjectId, approved);
}

export function getURLByTypeCodeAndIdAndVersionId(typeCode, operation, idOrProjectId, approved, versionId) {
  return CommonUtils.getURLByTypeCodeAndId(typeCode, operation, idOrProjectId, approved, versionId);
}

export function getReportURLByTypeAndId(reportType, projectId) {
  let reportEntry = Object.values(REPORT_THUMBNAILS_ENUM)
    .find((value => value.type === reportType));

  if (!reportEntry) {
    const message = `Error:  You clicked on an unknown report type (${reportType}).  Please report this to support.`;
    UIUtils.showError(message);
    throw new Error(message);
  }

  const {baseURL, fixedURLParams} = reportEntry;
  return `/${baseURL}?projectId=${projectId}&reportType=${reportType}${fixedURLParams ? `&${fixedURLParams}` : ""}`;
}

