import BaseTechTransferAttribute from "../../processExplorer/attributes/base_tech_transfer_attribute";
import EditRiskLinksConfirmationPopup from "../../widgets/risk/edit_risk_links_confirmation_popup";
import { EMPTY_STRING } from "../../helpers/constants/constants";
import React from "react";
import DeleteRiskLinksConfirmationPopup from "../../widgets/risk/delete_risk_links_confirmation_popup";
import CommonUtils from "../../../server/common/generic/common_utils";
import { DELETE_ROW_OPTIONS } from "../../widgets/risk/delete_risk_links_confirmation_constants";
import { UPDATE_ROW_OPTIONS } from "../../widgets/risk/edit_risk_links_confirmation_constants";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import * as UIUtils from "../../ui_utils";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
export const TYPE_CODE = {
  GENERAL_ATTRIBUTE: "GA",
  FQA: "FQA",
  FPA: "FPA",
  IQA: "IQA",
  IPA: "IPA",
};

/**
 * This the base react class for attributes that support multiple criticality source documents
 */
export class BaseMultipleSupportDocumentsAttribute extends BaseTechTransferAttribute {

  constructor(props, baseTypeName, capitalizedBaseTypeName, displayName) {
    super(props, baseTypeName, capitalizedBaseTypeName, displayName);

    this.setStateSafely({
      showEditRiskConfirmationPopup: false,
      showDeleteRiskConfirmationPopup: false,
    });

    this.onRiskLinksDeletedListeners = [];
    this.onRiskLinksEditedListeners = [];
    this.onRiskDocumentUpdatedListeners = [];
  }

  addOnRiskLinksDeletedHandler(callback) {
    this.onRiskLinksDeletedListeners.push(callback);
  }

  addOnRiskLinksEditedHandler(callback) {
    this.onRiskLinksEditedListeners.push(callback);
  }

  addOnRiskDocumentUpdatedHandler(callback) {
    this.onRiskDocumentUpdatedListeners.push(callback);
  }

  handleDelete(row) {

    // Don't show the confirmation if the user is deleting the row that is just added'
    if (row.mode === "Add") {
      return;
    }

    // Don't show the confirmation if the user is deleting risk link with no documents.
    let links = typeof row.links === "string" ? JSON.parse(row.links) : row.links;
    if (links.length === 0) {
      this.onRiskLinksDeletedListeners
        .forEach(callback => callback(row));
      return;
    }

    this.setStateSafely({
      deletedRiskLink: row,
    });

    this.toggleDeleteRiskConfirmationOnQuickPanel(true, row);
  }

  handleRiskLinkSave(row) {
    // Uncomment for verbose logging.
    // console.log(JSON.stringify(row));

    // Confirmation popup should be rendered in 2 cases:
    //    1) The row is not in add mode (newly added row)
    //    2) The row is not new, but does not have changes in its document attachments.
    //    3) The row does not have source documents.
    if (row.mode === "Add") {
      return;
    }

    // Don't show the confirmation if the user is deleting risk link with no documents.
    let links = typeof row.links === "string" ? JSON.parse(row.links) : row.links;
    if (links.length === 0) {
      return;
    }

    this.setStateSafely({
      editedRow: row,
    });

    this.toggleEditRiskConfirmationOnQuickPanel(true, row);
  }

  handleReset() {
    super.handleReset(this.preprocessReceivedData);
  }

  handleSaveResults(result) {
    super.handleSaveResults(result);
    this.setStateSafely({
      triggerChildUpdate: !this.state.triggerChildUpdate,
    });
  }

  handleSwitchViewMode(button, editorOperation, data, done) {
    super.handleSwitchViewMode(button, editorOperation, data, done);
    this.setStateSafely({
      areTypeaheadsLoading: false,
    });
  }


  /**
   * Override this method in the child react component to do the required state changes after risk links has been deleted.
   * @param state
   */
  handleUpdateRiskLinksStateChange(state) {
    const {riskLinks, newRiskLinksState} = state;
    this.setStateSafely({
      ...newRiskLinksState,
      editedRow: undefined,
      showEditRiskConfirmationPopup: false,
      triggerChildUpdate: !this.state.triggerChildUpdate,
      riskLinks: JSON.stringify(riskLinks),
    });
  }

  /**
   * Override this method in the child react component to do the required state changes after risk links has been deleted.
   * @param state
   */
  handleDeleteRiskLinksStateChange(state) {
    const {newRiskLinksState, remainingRiskLinkDocs} = state;
    this.setStateSafely({
      ...newRiskLinksState,
      dataModified: true,
      deletedRiskLink: undefined,
      triggerChildUpdate: !this.state.triggerChildUpdate,
      showDeleteRiskConfirmationPopup: false,
      riskLinks: JSON.stringify(remainingRiskLinkDocs),
    });
  }

  handleHideDeleteRiskConfirmationPopup() {
    this.setStateSafely({
      showDeleteRiskConfirmationPopup: false,
      deletedRiskLink: undefined,
    });
  }

  handleHideEditRiskConfirmationPopup() {
    this.setStateSafely({
      showEditRiskConfirmationPopup: false,
      editedRow: undefined,
    });
  }

  /**
   * Override this method on the child class to construct the record label to show on the confirmation popup.
   * @param riskLink
   */
  getRiskLabelForDisplay(riskLink) {
    if (riskLink && riskLink !== EMPTY_STRING) {
      const {
        typeCode,
        riskIdAttributeValue,
      } = this.getRiskIdAttributeInfoFromRiskLink(riskLink);
      const modelName = CommonUtils.stripAllWhitespaces(CommonUtils.getModelNameForTypeCode(typeCode));
      let cache = new TypeaheadObjectCache(modelName, this.getProjectId(), this.getProcessId());
      const optionsFromCache = cache.getOptionsFromCache();
      const attribute = optionsFromCache.find(attribute => attribute.id === riskIdAttributeValue);
      return attribute ? UIUtils.getRecordCustomLabelForDisplayAlternate(attribute) : EMPTY_STRING;
    }
    return EMPTY_STRING;
  }

  getRiskIdAttributeInfoFromRiskLink(riskLink) {
    const riskLinkedObjects = this.getRiskLinkedObjects();
    for (let typeCode of riskLinkedObjects) {
      const modelName = CommonUtils.stripAllWhitespaces(CommonUtils.getModelNameForTypeCode(typeCode));
      let riskIdAttributeName = this.getTargetRiskIdAttribute(modelName, typeCode);
      let riskIdAttributeValue = riskLink[riskIdAttributeName];
      if (riskIdAttributeValue) {
        return {
          typeCode,
          riskIdAttributeName,
          riskIdAttributeValue,
        };
      }

    }
    return {};
  }

  /**
   * Override this method on the child class to construct the links to show on the confirmation popup.
   * @param riskLink
   * @param isDelete
   */
  getRiskLinkSourceDocuments(riskLink, isDelete) {
    // Return if the riskLink is being added for the first time.
    if (!riskLink || riskLink.mode === "Add") {
      return [];
    }

    let rowOptions = isDelete ? DELETE_ROW_OPTIONS : UPDATE_ROW_OPTIONS;
    const links = Array.isArray(riskLink.links)
      ? riskLink.links
      : JSON.parse(riskLink.links || "[]");

    const {
      typeCode,
      riskIdAttributeValue,
    } = this.getRiskIdAttributeInfoFromRiskLink(riskLink);


    if (riskIdAttributeValue) {

      const riskCompositeId = `${typeCode}-${riskIdAttributeValue}`;
      links.forEach(
        link => {

          const {appliesTo = []} = link;
          if (!appliesTo.includes(riskCompositeId)) {
            appliesTo.push(riskCompositeId);
          }
          Object.assign(link, rowOptions);

          isDelete ? link.keep = true : link.update = true;
          const linksLength = appliesTo.length;

          if (linksLength > 1) {
            link.disableRemove = true;
          }

          link.tooltipLinks = appliesTo.map(
            compositeId => {
              const [typeCode, id] = compositeId.split("-");
              const modelName = CommonUtils.stripAllWhitespaces(CommonUtils.getModelNameForTypeCode(typeCode));
              return {
                link: this.getRiskLabelForDisplay({[this.getTargetRiskIdAttribute(modelName, typeCode)]: CommonUtils.parseInt(id)}),
                href: getURLByTypeCodeAndId(typeCode, "View", id),
              };
            });

          link.appliesTo = `${linksLength} ${linksLength > 1 ? "links" : "link"}`;
        });
      return links;
    }
  }

  /**
   * Override this method on the child class to perform the required changes after pressing confirm on delete risk link popup
   * @param records
   */
  handleDeleteRiskLinkPopupAction(records) {

    const {deletedRiskLink} = this.state;

    const {
      typeCode,
      riskIdAttributeValue,
    } = this.getRiskIdAttributeInfoFromRiskLink(deletedRiskLink);

    const updateAppliesTo = (link) => {
      return {
        ...link, appliesTo: link.appliesTo.filter(id => id !== `${typeCode}-${riskIdAttributeValue}`),
      };
    };

    const newRiskLinksState = {};
    const riskLinkedObjects = this.getRiskLinkedObjects();
    let idx = -1;
    for (let model of riskLinkedObjects) {
      const currentRiskLinks = this.getRiskLinksFromState(model);
      let remainingRiskLinkRecords = currentRiskLinks
        .filter(risk => (risk.id && deletedRiskLink.id && risk.id !== deletedRiskLink.id) || (risk.uuid !== deletedRiskLink.uuid))
        .map((risk) => {

          const links = JSON.parse(risk.links);
          const updatedLinks = links
            .filter(
              link => {
                return records.filter(rec => link.uuid !== rec.uuid || (link.uuid === rec.uuid && rec?.remove === false));
              },
            ).map(updateAppliesTo);

          return {
            ...risk,
            index: ++idx,
            links: JSON.stringify(updatedLinks),
          };
        });
      newRiskLinksState[this.getRiskLinkAttributeName(model)] = remainingRiskLinkRecords;
    }

    let {riskLinks = "[]"} = this.state;
    let remainingRiskLinkDocs = JSON.parse(riskLinks)
      .filter(
        link => {
          let rec = records.find(rec => rec.uuid === link.uuid);
          return (!rec || (rec && rec.remove === false));
        },
      ).map(updateAppliesTo);

    if (this.deleteRiskConfirmationPopup) {
      $(this.deleteRiskConfirmationPopup).modal("hide");
    }

    this.toggleDeleteRiskConfirmationOnQuickPanel(false);

    const stateUpdateContext = {
      newRiskLinksState, remainingRiskLinkDocs,
    };

    this.handleDeleteRiskLinksStateChange(stateUpdateContext);
  }

  /**
   * Override this method on the child class to perform the required changes after pressing confirm on edit risk link popup
   * @param records
   */
  handleEditRiskLinkPopupAction(records) {
    let {editedRow, riskLinks = "[]"} = this.state;

    const {
      typeCode,
      riskIdAttributeValue,
    } = this.getRiskIdAttributeInfoFromRiskLink(editedRow);

    const updateAppliesTo = (link) => {
      return {
        ...link, appliesTo: link.appliesTo.filter(id => id !== `${typeCode}-${riskIdAttributeValue}`),
      };
    };

    const links = JSON.parse(editedRow.links);
    const documentsKept = records
      .filter(
        link => link.remove === false && link.removeAppliesTo === false,
      ).map(
        rec => links.find(link => link.uuid === rec.uuid),
      );

    const documentsUnlinked = records
      .filter(
        link => link.removeAppliesTo === true,
      ).map(
        rec => links.find(link => link.uuid === rec.uuid),
      ).map(updateAppliesTo);

    const documentsRemoved = records
      .filter(
        link => link.remove === true,
      ).map(
        rec => links.find(link => link.uuid === rec.uuid),
      );


    let {
      id,
      typeCode: editedTypeCode,
    } = this.getEditedRiskLinkDetailsFromState(editedRow);

    // If user changed risk from Efficacy To Safety, we need to ensure all Eficacy links is reset.
    this.resetDocsIfUserChangedTheRiskLinkTo({id, typeCode, riskIdAttributeValue});

    const newRiskLinksState = {};
    const riskLinkedObjects = this.getRiskLinkedObjects();
    for (let model of riskLinkedObjects) {
      let currentRiskLinks = this.getRiskLinksFromState(model);
      currentRiskLinks = currentRiskLinks
        .map(riskLink => {
          return {...riskLink, links: JSON.parse(riskLink.links)};
        }) // convert all links to be an objects for next processing
        .map(riskLink => (riskLink.uuid === editedRow.uuid) ? {
          ...riskLink,
          links: documentsKept,
        } : riskLink) // Update the documents for the current edited risk
        .map(riskLink => {
          let {links} = riskLink;
          // From every document, remove it if it is in the documents_removed bucket.
          links = links.filter(link => !documentsRemoved.find(removedLink => removedLink.uuid === link.uuid));

          // Then update any of the remaining if in the current list of edited documents and marked to be unlinked from the current edited risk object
          links = links.map(link => {
            let document = records.find(record => record.uuid === link.uuid
              && record.removeAppliesTo === true);
            if (document) {
              return updateAppliesTo(link);
            }
            return link;
          });
          return {...riskLink, links};
        })
        .map(riskLink => {
          // Take care of the risk records that has been edited and its value has been changed.
          // Example, User made a risk link to Efficacy, added a support document to google.com. then later on, he edited the Efficacy risk link and changed it to Safety.
          // Now google.com should be pointing out to Safety instead of Efficacy.
          let {links} = riskLink;
          links = links.map(
            link => {
              let editedLink = records.find(rec => rec.uuid === link.uuid);
              if (editedLink) {
                link = {
                  ...link,
                  appliesTo: link.appliesTo.map(applyTo => {
                    let [code, idValue] = applyTo.split("-");
                    let inIdValue = CommonUtils.parseInt(idValue);
                    if (
                      (id !== inIdValue && riskIdAttributeValue === inIdValue) ||
                      (code !== editedTypeCode && riskIdAttributeValue === inIdValue)
                    ) {
                      return `${editedTypeCode}-${id}`;
                    }
                    return applyTo;
                  }),
                };
              }
              return link;
            },
          );
          // Return the updated documents after applying all actions.
          return {...riskLink, links: JSON.stringify(links)};
        });

      newRiskLinksState[this.getRiskLinkAttributeName(model)] = currentRiskLinks;
    }

    riskLinks = JSON.parse(riskLinks)
      .filter(link => !documentsRemoved.find(removedDocument => removedDocument.uuid === link.uuid))
      .map(link => {
        let unlinkedDocument = documentsUnlinked.find(unlinkedDocument => unlinkedDocument.uuid === link.uuid);
        if (unlinkedDocument) {
          return updateAppliesTo(unlinkedDocument);
        }
        return link;
      })
      .map(link => {
        // Take care of the risk records that has been edited and its value has been changed.
        // Example, User made a risk link to Efficacy, added a support document to google.com. then later on, he edited the Efficacy risk link and changed it to Safety.
        // Now google.com should be pointing out to Safety instead of Efficacy.
        let editedLink = records.find(rec => rec.uuid === link.uuid);
        if (editedLink) {
          link = {
            ...link,
            appliesTo: link.appliesTo.map(applyTo => {
              let [code, idValue] = applyTo.split("-");
              let inIdValue = CommonUtils.parseInt(idValue);
              if (
                (id !== inIdValue && riskIdAttributeValue === inIdValue) ||
                (code !== editedTypeCode && riskIdAttributeValue === inIdValue)
              ) {
                return `${editedTypeCode}-${id}`;
              }
              return applyTo;
            }),
          };
        }
        return link;
      });

    this.toggleEditRiskConfirmationOnQuickPanel(false);

    if (this.editRiskConfirmationPopup) {
      $(this.editRiskConfirmationPopup).modal("hide");
    }

    const stateUpdateContext = {
      riskLinks, newRiskLinksState,
    };

    this.handleUpdateRiskLinksStateChange(stateUpdateContext);
  }

  getEditedRiskLinkDetailsFromState({uuid}) {
    const riskLinkedObjects = this.getRiskLinkedObjects();
    for (let typeCode of riskLinkedObjects) {
      const model = CommonUtils.stripAllWhitespaces(CommonUtils.getModelNameForTypeCode(typeCode));
      let riskLinks = this.getRiskLinksFromState(model);
      let risk = riskLinks.find(risk => risk.uuid === uuid);
      if (risk) {
        let id = risk[this.getTargetRiskIdAttribute(model, typeCode)];
        return {
          id,
          typeCode,
        };
      }
    }
  }

  resetDocsIfUserChangedTheRiskLinkTo({id, typeCode, riskIdAttributeValue}) {
    if (id !== riskIdAttributeValue) {
      const model = CommonUtils.stripAllWhitespaces(CommonUtils.getModelNameForTypeCode(typeCode));
      let idAttribute = this.getTargetRiskIdAttribute(model, typeCode);
      const riskLinks = this.getRiskLinksFromState(typeCode);

      this.setStateSafely({
        [this.getRiskLinkAttributeName(model)]: riskLinks.map(
          risk => {
            if (risk[idAttribute] && risk[idAttribute] === riskIdAttributeValue) {
              return {
                ...risk,
                links: "[]",
              };
            }
            return risk;
          },
        ),
      });
    }
  }


  /**
   * Override in child class to specify the risk linked objects per record type.
   */
  getRiskLinkedObjects() {
    return ["IPA", "IQA", "FPA", "FQA"];
  }

  getTargetRiskIdAttribute(modelName, typeCode) {
    return this.capitalizedBaseTypeName === typeCode ? `Target${modelName}Id` : `${modelName}Id`;
  }

  /**
   * Override this method on the child class to get the appliesTo typeahead field options for display in supporting documents widget.
   */
  getRiskLinksTypeaheadOptions() {
    const typeAheadOptions = [];
    const typeCodes = this.getRiskLinkedObjects();
    for (let typeCode of typeCodes) {
      const modelName = CommonUtils.stripAllWhitespaces(CommonUtils.getModelNameForTypeCode(typeCode));
      const cache = new TypeaheadObjectCache(modelName, this.getProjectId(), this.getProcessId());
      const options = cache.getOptionsFromCache();
      const riskLinks = this.getRiskLinksFromState(modelName);
      const riskIds = riskLinks.map(risk => risk[this.getTargetRiskIdAttribute(modelName, typeCode)]);

      typeAheadOptions.push(
        ...options
          .filter(attribute => riskIds.includes(attribute.id))
          .map(attribute => {
            return {
              id: `${typeCode}-${attribute.id}`,
              label: UIUtils.getRecordCustomLabelForDisplayAlternate(attribute),
            };
          }),
      );
    }

    return typeAheadOptions;
  }

  toggleEditRiskConfirmationOnQuickPanel(show, editedRow) {

    if (this.props?.eventListeners?.onRiskLinkConfirmationDialogRender) {

      if (show) {
        const documents = this.getRiskLinkSourceDocuments(editedRow, false);
        const message = `Would you like to keep the below documents/links with the criticality risk ${this.getRiskLabelForDisplay(editedRow)} ?`;
        this.props.eventListeners.onRiskLinkConfirmationDialogRender(
          show,
          false,
          message,
          documents,
          this.handleHideEditRiskConfirmationPopup,
          this.handleEditRiskLinkPopupAction,
        );
      } else {
        this.props.eventListeners.onRiskLinkConfirmationDialogRender(show, false);
      }

    } else {
      this.setStateSafely({
        showEditRiskConfirmationPopup: true,
      });
    }
  }

  toggleDeleteRiskConfirmationOnQuickPanel(show, deletedRiskLink) {

    if (this.props?.eventListeners?.onRiskLinkConfirmationDialogRender) {

      if (show) {
        const message = `Are you sure you want to remove the risk ${this.getRiskLabelForDisplay(deletedRiskLink)} ?`;
        const documents = this.getRiskLinkSourceDocuments(deletedRiskLink, true);
        this.props.eventListeners.onRiskLinkConfirmationDialogRender(
          show,
          true,
          message,
          documents,
          this.handleHideDeleteRiskConfirmationPopup,
          this.handleDeleteRiskLinkPopupAction,
        );
      } else {
        this.props.eventListeners.onRiskLinkConfirmationDialogRender(show, true);
      }

    } else {
      this.setStateSafely({
        showDeleteRiskConfirmationPopup: true,
      });
    }
  }

  renderEditRiskConfirmation() {
    const {showEditRiskConfirmationPopup, editedRow} = this.state;
    const message = `Would you like to keep the below documents/links with the criticality risk ${this.getRiskLabelForDisplay(editedRow)} ?`;
    const documents = this.getRiskLinkSourceDocuments(editedRow, false);
    const showEditRiskPopup = showEditRiskConfirmationPopup && documents && documents.length > 0;
    return showEditRiskPopup ?
      (
        <EditRiskLinksConfirmationPopup
          modalRef={riskConfirmationPopup => this.editRiskConfirmationPopup = riskConfirmationPopup}
          action={"Change Risk"}
          message={message}
          documents={documents}
          onHideModal={this.handleHideEditRiskConfirmationPopup}
          onEditRiskLinksAction={this.handleEditRiskLinkPopupAction}
          parent={this}
        />
      ) : (
        EMPTY_STRING
      );
  }

  renderDeleteRiskConfirmation() {
    let {showDeleteRiskConfirmationPopup, deletedRiskLink} = this.state;
    const message = `Are you sure you want to remove the risk ${this.getRiskLabelForDisplay(deletedRiskLink)} ?`;
    const documents = this.getRiskLinkSourceDocuments(deletedRiskLink, true);
    const showPopup = showDeleteRiskConfirmationPopup && documents && documents.length > 0;

    return showPopup ?
      (
        <DeleteRiskLinksConfirmationPopup
          modalRef={riskConfirmationPopup => this.deleteRiskConfirmationPopup = riskConfirmationPopup}
          action={"Remove Risk"}
          message={message}
          documents={documents}
          onHideModal={this.handleHideDeleteRiskConfirmationPopup}
          onDeleteRiskLinksAction={this.handleDeleteRiskLinkPopupAction}
          parent={this}
        />
      ) : (
        EMPTY_STRING
      );
  }

  handleRiskDocumentDelete() {
    this.onRiskDocumentUpdatedListeners
      .forEach(callback => callback());
  }

  handleRiskDocumentSave() {
    this.onRiskDocumentUpdatedListeners
      .forEach(callback => callback());
  }

  getRiskLinkAttributeName(model) {
    return `${this.capitalizedBaseTypeName}To${model}s`;
  }

  getRiskLinkVersionAttributeName(model) {
    return `${this.capitalizedBaseTypeName}To${model}LinkedVersions`;
  }

  /**
   * Override in child class to return the risk links records from the server result.
   * @param model
   * @param result
   */
  getRiskLinksFromResult(model, result) {
    let riskLinks = result[this.getRiskLinkAttributeName(model)];
    let riskLinksLinkedVersion = result[this.getRiskLinkVersionAttributeName(model)];

    return this.isDiffingVersions()
      ? riskLinksLinkedVersion || []
      : riskLinks || riskLinksLinkedVersion || [];
  }

  getRiskLinksFromState(model) {
    return this.getRiskLinksFromResult(model, this.state);
  }

  handleReceiveRevisionDataFromServer(versionMap, callback) {
    const handleReceivedData = () => {
      super.handleReceiveRevisionDataFromServer(versionMap, callback);
      this.setStateSafely({
        triggerChildUpdate: !this.state.triggerChildUpdate,
      });
    };
    this.loadMultipleTypeaheads(() => handleReceivedData(versionMap, callback));
  }

  handleTypeaheadResultsFromServer(callback, results, typeCode, remainingTypesToCache) {
    super.handleTypeaheadResultsFromServer(callback, results, typeCode, remainingTypesToCache);
    if (this.isLastTypeaheadModelLoaded(typeCode, remainingTypesToCache)) {
      if (!this.isAdd() && !this.isLoadingDiff() && !this.isDiffingVersions()) {
        this.fillRiskDocumentsAttributeDetails(() => {
          this.setStateSafely({
            areTypeaheadsLoading: false,
          });
        });
      } else {
        this.setStateSafely({
          areTypeaheadsLoading: false,
        });
      }
    }
  }

  getTypesToCache() {
    return ["GeneralAttribute", "IQA", "IPA", "FQA", "FPA", ...super.getTypesToCache()];
  }

  /**
   * Override this method on the child class to determine if the links has been updated correctly or not.
   */
  isLoadingRiskLinks() {
    return this.state.areTypeaheadsLoading || this.isLoading() || this.isLoadingDiff();
  }

  isLastTypeaheadModelLoaded(typeCode, remainingTypesToCache) {
    let {loadedTypeAheadOptions} = this;
    if (remainingTypesToCache.includes(typeCode) && !loadedTypeAheadOptions.includes(typeCode)) {
      loadedTypeAheadOptions.push(typeCode);
    }

    return loadedTypeAheadOptions.length >= remainingTypesToCache.length;
  }

  getTypeCodeFromId(id) {
    return id.split("-")[0];
  }

  preprocessResult(result) {
    const riskDocuments = [];
    const typeCodesToLoad = this.getRiskLinkedObjects();
    for (let typeCode of typeCodesToLoad) {
      const modelName = CommonUtils.stripAllWhitespaces(CommonUtils.getModelNameForTypeCode(typeCode));
      const risks = this.getRiskLinksFromResult(modelName, result);
      let links = risks
        .flatMap(risk => JSON.parse(risk.links));
      riskDocuments.push(...links);
    }

    // Remove any duplication of the aggregated documents from all the risk links.
    const uniqueDocuments = Array.from(
      new Set(
        riskDocuments.map(link => JSON.stringify(link)),
      ),
    ).map(link => JSON.parse(link)).filter(link => link.linkType !== EMPTY_STRING);

    // Add the documents without linked risks as a risk record level.
    let {riskLinks} = result;
    riskLinks = (!riskLinks || riskLinks === EMPTY_STRING) ? "[]" : riskLinks;
    const orphanLinks = JSON.parse(riskLinks).filter(link => link.appliesTo.length === 0);
    return JSON.stringify(uniqueDocuments.concat(orphanLinks));
  }

  cleanRiskLinksBeforeSentToServer(record) {
    const {riskLinks = "[]"} = this.state;

    // Save the orphan links/documents on the record level.
    let links = JSON.parse(riskLinks);
    links = links.filter(link => link && link.appliesTo.length === 0);

    record.riskLinks = JSON.stringify(links);
  }

  handleRiskLinksChangeValue(value) {
    const links = JSON.parse(value);

    const riskLinks = JSON.parse(this.state.riskLinks ?? "[]");
    let removedLinks = riskLinks.filter(link => !links.find(lnk => lnk.uuid === link.uuid));
    let updatedLinks = links;

    for (let link of removedLinks) {
      const {appliesTo} = link;
      for (let applyTo of appliesTo) {
        this.updateLinks(applyTo, links);
      }
    }

    for (let link of updatedLinks) {
      const {appliesTo} = link;
      for (let applyTo of appliesTo) {
        this.updateLinks(applyTo, updatedLinks);
      }

      // removed entities from updates links
      const riskLinkFromState = riskLinks.find(riskLinkFromState => riskLinkFromState.uuid === link.uuid);
      if (riskLinkFromState) {
        const removedEntityIds = riskLinkFromState.appliesTo.filter(applyToId => !link.appliesTo.includes(applyToId));
        for (let applyTo of removedEntityIds) {
          this.updateLinks(applyTo, updatedLinks);
        }
      }
    }
    // flip the flag to trigger child components to rerender itself.
    this.setStateSafely({
      triggerChildUpdate: !this.state.triggerChildUpdate,
    });
  }

  updateLinks(applyTo, links) {
    const typeCode = this.getTypeCodeFromId(applyTo);
    const modelName = CommonUtils.stripAllWhitespaces(CommonUtils.getModelNameForTypeCode(typeCode));
    const attributeRisks = this.getRiskLinksFromState(modelName);

    this.setStateSafely({
      [this.getRiskLinkAttributeName(modelName)]: attributeRisks
        .map(riskAttribute => {
          const id = `${typeCode}-` + riskAttribute[this.getTargetRiskIdAttribute(modelName, typeCode)];
          const riskAttributeLinks = links.filter(link => link.appliesTo.includes(id));
          return {
            ...riskAttribute,
            links: JSON.stringify(riskAttributeLinks),
          };
        }),
    });
  }
}
