"use strict";

import { Ensure } from "../../../../server/common/generic/common_ensure";
import { Log, LOG_GROUP } from "../../../../server/common/logger/common_log";

// eslint-disable-next-line no-unused-vars
const Logger = Log.group(LOG_GROUP.Documents, "CachedPDFPage");

/**
 * @typedef {PDFPageProxy|{renderObject: !IPDFPage}} ICachedPDFPage
 */


// noinspection JSClosureCompilerSyntax
/**
 * Wraps an instance of a PDF page and adds additional functionality required for our context.
 * @extends {PDFPageProxy|ICachedPDFPage}
 */
export class CachedPDFPage {
  /**
   * Extends a {@link PDFPageProxy} (the class used by pdfJS) so that it can store information
   * we use during the rendering and also cache its pages.
   * @param pdfPage {PDFPageProxy|ICachedPDFPage} The page to be extended
   * @returns {ICachedPDFPage}
   */
  static fromPage(pdfPage) {
    Ensure.that({pdfPage}).isTruthy();
    pdfPage.renderObject = {};
    Logger.verbose(() => "Extended PDFPageProxy with additional properties", Log.object(pdfPage));
    return pdfPage;
  }
}
