"use strict";

import React, { Fragment } from "react";
import BaseReactComponent from "../../../base_react_component";
import * as I18NWrapper from "../../../i18n/i18n_wrapper";
import ImportFlowOptionsPanel from "./import_flow_options_panel";
import { registerInactiveItemsBehavior } from "../../../widgets/dropdowns/dropdown_extensions";

// i18next-extract-mark-ns-start import
/**
 * This renders a drop down of various import flows. The import flows are displayed within cards, each showing
 * general information about each flow.
 */
class ImportFlowSelect extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();

    registerInactiveItemsBehavior(".import-data-type-select-div", "batch-import-button-option-container", "disabled");
  }

  handleImportDataTypeSelected(importConfig) {
    this.setStateSafely({importConfig}, () => {
      if (this.props.onImportDataTypeSelected) {
        this.props.onImportDataTypeSelected(this.state.importConfig);
      }
    });
  }

  render() {
    const {
      t,
      importTypeConfigs,
      projectId,
      processId,
      importDependenciesStatus,
      enableImportConfigSelection
    } = this.props;
    const importConfig = this.state.importConfig || this.props.importConfig;

    return (
      <Fragment>
        <div>
          Data Type:
        </div>
        {enableImportConfigSelection ? (
          <div className="btn-group import-data-type-select-div">
            <button id="importImportDataTypeSelect"
                    type="button"
                    className="btn btn-secondary dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
            >
              {importConfig ? importConfig.title : t("Select Data Type")} <span className="caret" />
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <ImportFlowOptionsPanel id="importDataTypeOptionsPanel"
                                      projectId={projectId}
                                      processId={processId}
                                      importConfigs={importTypeConfigs}
                                      importDependenciesStatus={importDependenciesStatus}
                                      onImportDataTypeSelected={this.handleImportDataTypeSelected}
              />
            </div>
          </div>
        ) : (
          <span id="selectedImportTypeSpan"
                className="import-data-type-selection"
          >
            {importConfig ? importConfig.title : ""}
          </span>
        )}
      </Fragment>
    );
  }
}

export default I18NWrapper.wrap(ImportFlowSelect, ["import"]);

// i18next-extract-mark-ns-stop import