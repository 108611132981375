"use strict";

import { WebSocketsMessageHandler } from "../web_sockets_message_handler";

/**
 * A WebSockets message handler for Import.
 */
export class ImportMessageHandler extends WebSocketsMessageHandler {
  constructor(statusService) {
    super(statusService);
  }

  getDisplayMessage(message, parameters) {
    if (typeof message.data === "string") {
      return super.getDisplayMessage(message, parameters);
    } else {
      return message.data;
    }
  }
}
