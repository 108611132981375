"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import { generateTooltip } from "../../utils/ui_permissions";
import ImportBaseStep from "./import_base_step";
import OCRWidget from "../widgets/smartUpload/ocrWidget/ocr_widget";
import BatchDataEditorWidget
  from "../widgets/smartUpload/extractAndEditWizardStep/batchDataEditorWidget/batch_data_editor_widget";
import Split from "react-split";
import ETLData from "../widgets/smartUpload/extractAndEditWizardStep/etl_data";
import { IMPORT_STEPS, MAX_PDF_PAGES } from "../constants/import_constants";
import { ImportMessageHandler } from "../../services/import/import_message_handler";
import { ImportService } from "../../services/import/import_service";
import { StatusDisplayService } from "../../services/status_display_service";

/**
 * This implements the Import wizard data extract step from .png or .pdf step
 */
export default class ImportExtractAndEditStep extends ImportBaseStep {
  constructor(props) {
    super(props);

    this.setStateSafely({
      panelSizes: [50, 50],
      numOfWarnings: 0
    });

    this.statusService = new StatusDisplayService();
    this.messageService = new ImportMessageHandler(this.statusService);
    this.importService = new ImportService(this.messageService, this.statusService);
  }

  handleDataMappingCompleted(changeInfo, data) {
    let {hasPermissionToImport, onWizardStepDataUpdated, etlData} = this.props;
    let newEtlData = new ETLData({
      batchData: data.batchData,
      dataAttributes: etlData.dataAttributes,
      userUpdates: etlData.userUpdates
    });
    newEtlData.resetErrorsAndVerifyData(true);

    onWizardStepDataUpdated({
      currentImportStep: IMPORT_STEPS.DataExtract,
      dataReviewRequired: true,
      dataMappingRequired: false,
      stepsDisabledStatus: {
        [IMPORT_STEPS.DataImport]: !newEtlData.hasData() || !hasPermissionToImport,
      },
      etlData: newEtlData,
      originalETLData: newEtlData,
    }, () => {
      this.setStateSafely({
        dataMappingCompleted: true
      }, () => {
        super.activate(changeInfo);
      });
    });
  }

  activate(changeInfo) {
    const {
      textractResults, projectId, selectedDependencyRecord, dependencyRecords, dataMappingRequired, etlData
    } = this.props;

    if (dataMappingRequired) {
      let selectedOption = dependencyRecords.find(option => option.label === selectedDependencyRecord.label);
      let materialId = selectedOption && UIUtils.parseKey(selectedOption.id).id;
      let userTaggedColumns = textractResults && textractResults.columns
        ? textractResults.columns.filter(column => column.isAttributesColumn || column.isResultsColumn)
        : [];

      let payload = {
        jobId: textractResults.jobId,
        userTaggedColumns,
        projectId,
        materialId,
      };

      this.importService.mapData(payload)
        .then(this.handleDataMappingCompleted.bind(this, changeInfo))
        .catch(this.failCallback);
    } else {
      this.handleDataMappingCompleted(changeInfo, etlData);
    }
  }

  deactivate(stepIndexToMoveTo) {
    if (this.props.currentStep < stepIndexToMoveTo) {
      let {etlData, onWizardStepDataUpdated} = this.props;
      const {batchData, dataAttributes, userUpdates} = etlData;
      let validatedETLData = new ETLData({batchData, dataAttributes, userUpdates});
      validatedETLData.resetErrorsAndVerifyData();
      onWizardStepDataUpdated({
        etlData: validatedETLData,
        dataMappingRequired: false,
      }, () => {
        if (validatedETLData.hasErrors()) {
          UIUtils.showError("Please fix all the errors to proceed.");
          UIUtils.scrollToField(".tab-nav-bar-error-icon-red", 20, ".attribute-measurements-container");
        } else {
          UIUtils.clearError();
          const numOfWarnings = validatedETLData ? validatedETLData.getNumOfWarnings() : 0;
          if (numOfWarnings) {
            onWizardStepDataUpdated({
              showConfirmationPopup: true,
              stepToMoveOnConfirmation: IMPORT_STEPS.DataImport,
              confirmationMessage: `There are ${numOfWarnings} warnings pending. Continue?`,
            });
          } else {
            super.deactivate(stepIndexToMoveTo);
          }
        }
      });
    } else {
      super.deactivate(stepIndexToMoveTo);
    }

  }

  handleETLDataUpdated(etlData) {
    let {hasPermissionToImport, onWizardStepDataUpdated} = this.props;

    onWizardStepDataUpdated({
      etlData,
      stepsDisabledStatus: {
        [IMPORT_STEPS.DataImport]: !etlData.hasData() || !hasPermissionToImport,
      },
    });
  }

  handleSplitPanelDragEnd(panelSizes) {
    this.setStateSafely({
      panelSizes
    });
  }

  renderStep() {
    let {
      etlData, fileData, textractResults, onWizardStepDataUpdated, modelNameForSecurity, selectedDependencyRecord,
      dataUrl, batchData, zoomLevel, selectedContent, securityAction
    } = this.props;
    let {panelSizes, dataMappingCompleted} = this.state;

    let nextStepDisabled = this.isNextStepDisabled();

    return dataMappingCompleted ? (
      <Fragment>
        <div className="import-data-extract-step">
          <div className="import-paper-div">
            <Split sizes={[50, 50]}
                   minSize={440}
                   gutterSize={2}
                   gutterAlign="center"
                   snapOffset={0}
                   dragInterval={1}
                   direction="horizontal"
                   cursor="col-resize"
                   onDragEnd={this.handleSplitPanelDragEnd}
            >
              <OCRWidget panelSizes={panelSizes}
                         batchData={batchData}
                         fileData={fileData}
                         selectedContent={selectedContent}
                         hideNonTaggedColumns={true}
                         dataUrl={dataUrl}
                         isLibraryMaterial={!(this.props.projectId > 0)}
                         visible={true}
                         zoomLevel={zoomLevel}
                         textractResults={textractResults}
                         maxPDFPages={MAX_PDF_PAGES}
                         onWizardStepDataUpdated={onWizardStepDataUpdated}
              />
              <BatchDataEditorWidget etlData={etlData}
                                     selectedDependencyRecord={selectedDependencyRecord}
                                     disabled={false}
                                     onETLDataUpdated={this.handleETLDataUpdated}
              />
            </Split>
          </div>
        </div>
        <div className="col-sm-12 import-smart-upload-screen-footer">
          <button
            disabled={nextStepDisabled}
            title={generateTooltip(securityAction, modelNameForSecurity, null, "")}
            className="btn btn-lg btn-primary import-footer-btn"
            id="nextButton"
            onClick={this.handleMoveToNextStep}
          >
            Next &gt;
          </button>
          <button className="btn btn-lg btn-primary import-footer-btn"
                  id="backButton"
                  onClick={this.handleMoveToPreviousStep}
          >
            {"< Back"}
          </button>
          {this.renderImportExitButton()}
        </div>
      </Fragment>
    ) : "";
  }
}
