"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import { getURLByKey, getURLByTypeCodeAndId } from "../../../helpers/url_helper";
import ImportResultSummaryTable from "./import_result_summary_table";
import * as ImportHelper from "../../helpers/import_helper";
import moment from "moment";
import BaseReactComponent from "../../../base_react_component";
import ContinuousDataReviewPanel from "../dataImportWizardStep/continuousData/continuous_data_review_panel";
import { LIBRARY_MATERIAL_TYPE_CODE } from "../../../library/library_constants";

/**
 * This shows the imports results, after an import workflow is completed.
 */
export default class ImportResultsPanel extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  handleFileDownload(e) {
    ImportHelper.downloadFile(e, this.props.fileData);
  }

  getImportedByLink(importResults) {
    let importedBy = importResults && (importResults.createdByUser || importResults.importedBy);
    let firstName = importedBy && importedBy.firstName;
    let lastName = importedBy && importedBy.lastName;
    return importedBy ? (
      <a id="importedByLink"
         href={getURLByTypeCodeAndId("USR", "View", importedBy.id, false)}
         rel="noopener noreferrer"
         target="_blank"
      >
        {firstName} {lastName}
      </a>
    ) : "";
  }

  getImportFileLink(fileData) {
    return (
      <a id="downloadImportFile"
         onClick={this.handleFileDownload}
      >{fileData.fileName}</a>
    );
  }

  getImportResultsAttributeLink(dependency, isPaperImport, importedRecords) {
    const attributeId = importedRecords[0] && importedRecords[0].attributeID;
    const attributeName = importedRecords[0] && importedRecords[0].attributeName;
    return dependency === "Attribute" && !isPaperImport ? (
      <>
        for {" "}
        <a id="importResultsAttributeLink"
           href={attributeId ? getURLByKey(attributeId, "View", false) : ""}
           rel="noopener noreferrer"
           target="_blank"
        >
          {attributeId} - {attributeName}
        </a>
      </>
    ) : "";
  }

  render() {
    const {
      importResults, dataSectionName, modelNameForDisplay, dependency, isPaperImport, importContinuousData,
      fileData, modelName, allowDynamicColumnsCreation, showImportCompleteLabel, projectId, processId,
      selectedDependencyRecord,
    } = this.props;

    const batch = importResults && importResults.batches && importResults.batches[0] || {};
    const importedRecords = importResults ? importResults.importedRecords : [];
    const importResultsAttributeLink = this.getImportResultsAttributeLink(dependency, isPaperImport, importedRecords);
    const importFileLink = this.getImportFileLink(fileData);
    const importedBy = this.getImportedByLink(importResults);
    const importDate = importResults ? moment(importResults.createdAt).format(UIUtils.DATE_FORMAT_FOR_DISPLAY) : "";

    let totalMeasurements = 0;
    if (importContinuousData) {
      totalMeasurements = importedRecords.reduce((total, attribute) => {
        total += attribute.measurements.length;
        return total;
      }, 0);
    }

    return (
      <div className={"row row-white" + (showImportCompleteLabel ? " import-results-review-container" : "")}>
        <div className="col-12">
          <div className="row import-wizard-step-inner-container">
            <div className="col-12">
              {showImportCompleteLabel && !importContinuousData ? (
                <span className="row import-results-summary-title">Import complete!</span>
              ) : ""}
              <span className="row import-results-summary-info">
                {
                  importContinuousData ? (
                    <span>Imported using {importFileLink} by {importedBy} on {importDate}.</span>
                  ) : (
                    <span>{importedRecords.length} {dataSectionName ? "records" : UIUtils.pluralize(modelNameForDisplay)} successfully imported {importResultsAttributeLink} using {importFileLink} by {importedBy} on {importDate}. They contain:</span>
                  )
                }
              </span>
              {showImportCompleteLabel && importContinuousData ? (
                <span className="row import-results-summary-message import-results-success-message alert-success">
                  Great job! You successfully imported {new Intl.NumberFormat().format(totalMeasurements)} measurements across {new Intl.NumberFormat().format(importedRecords.length)} records.
                </span>
              ) : ""}
              <div className="import-results-summary-table-div">
                {importResults
                  ? importContinuousData
                    ? (
                      <ContinuousDataReviewPanel attributes={importedRecords}
                                                 batchInfo={batch}
                      />
                    ) : (
                      <ImportResultSummaryTable
                        disableNameColumnLink={modelName === "ProcessCapability(Attribute)" && !isPaperImport}
                        allowDynamicColumnsCreation={allowDynamicColumnsCreation}
                        records={importedRecords}
                        dataSectionName={dataSectionName}
                        dependency={dependency}
                        modelName={modelName}
                        projectId={projectId}
                        processId={processId}
                      />
                    )
                  : ""}
              </div>
              <div className="import-exit-link">
                {(selectedDependencyRecord?.typeCode === LIBRARY_MATERIAL_TYPE_CODE) ? (
                    <a id="returnToLibraryMaterial" className={"import-return-link"} onClick={() => {
                      window.location.href = getURLByTypeCodeAndId(selectedDependencyRecord?.typeCode, "View", selectedDependencyRecord.getInternalId(), true);
                    }}>{"< Return to " + selectedDependencyRecord.name}</a>
                  ) :
                  (<a id="returnToImportDataLink" className={"import-return-link"} onClick={() => {
                    window.location.href = UIUtils.getSecuredURL("/import/importDashboard.html");
                  }}
                  >{"< Return to Import Data"}</a>)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
