"use strict";

import React, { memo, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { SHOW_REMOVED } from "../../utils/filter_helper";
import { PRODUCT_RELATED_TYPES } from "../product_constants";
import FilterDropdown from "../../widgets/dropdowns/filter_dropdown";

const ShowArchivedToggle = ({onShowArchivedChange, title}) => {

  const [
    showArchived,
    setShowArchived
  ] = useState(false);

  useEffect(() => {
    let show = false;
    const cookies = Object.keys(Cookies.get());
    for (let cookie of cookies) {
      if (cookie.startsWith(SHOW_REMOVED)) {
        const type = cookie.match(`${SHOW_REMOVED}_(\\S*)`)[1];
        if (PRODUCT_RELATED_TYPES.includes(type)) {
          const value = Cookies.get(cookie);
          show = show || value;
        }
      }
    }
    setShowArchived(show);
  }, []);

  const onShowArchiveChange = () => {
    setShowArchived(prev => !prev);
    onShowArchivedChange(!showArchived);
  };

  return (
    <div className="float-right align-self-center show-archived-filter">
      <FilterDropdown title={title}
                      showArchived={showArchived}
                      onShowArchivedChange={onShowArchiveChange}
      />
    </div>
  );
};

export default memo(ShowArchivedToggle);