"use strict";

import * as UIUtils from "../../ui_utils";
import * as DocumentTransferHelper from "../../helpers/document_transfer_helper";
import { ServiceBase } from "../../services/service_base";
import { StatusDisplayService } from "../../services/status_display_service";
import { EditablesMessageHandler } from "../../services/editables/editables_message_handler";
import { DEFAULT_PARAMETERS } from "../../services/editables/editables_service";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import Cookies from "js-cookie";

const Logger = Log.group(LOG_GROUP.Training, "TrainingService");

const DOWNLOADED_DOCUMENTS_CACHE_KEY = "QbDVision_DownloadedDocumentVersions";

/**
 * Service layer for Training functionality. Use this to execute editables related operations on the backend.
 */
export class TrainingService extends ServiceBase {
  constructor(messageHandler = null, statusService = null, stateStorage = localStorage) {
    // If no message handler of status service is set, we initialize with the defaults for the Editables Service
    statusService = statusService || new StatusDisplayService();
    messageHandler = messageHandler || new EditablesMessageHandler(statusService);

    super(messageHandler, statusService);

    this.signOff = this.signOff.bind(this);
    this.handleCheckPermissions = this.handleCheckPermissions.bind(this);
    this.loadTrainingInfo = this.loadTrainingInfo.bind(this);
    this.fetchTrainingInfo = this.fetchTrainingInfo.bind(this);
    this.getUniqueCurriculaIds = this.getUniqueCurriculaIds.bind(this);
    this.getDownloadedDocumentVersions = this.getDownloadedDocumentVersions.bind(this);
    this.updateMyUserDownloadedVersions = this.updateMyUserDownloadedVersions.bind(this);
    this.addDownloadedDocumentVersion = this.addDownloadedDocumentVersion.bind(this);
    this.hasDownloadedVersion = this.hasDownloadedVersion.bind(this);
    this.downloadDocument = this.downloadDocument.bind(this);
    this.handleCheckPermissions = this.handleCheckPermissions.bind(this);
    this.groupTrainingRecordsByDocumentVersionId = this.groupTrainingRecordsByDocumentVersionId.bind(this);
    this.isCurrentUserATrainingCoordinator = this.isCurrentUserATrainingCoordinator.bind(this);

    this.stateStorage = stateStorage;
    this.downloadedDocsStateKey = `${DOWNLOADED_DOCUMENTS_CACHE_KEY}_${UIUtils.getUserId()}`;
  }

  /**
   * Signs off training for the document version specified in the payload parameter.
   * @param {boolean} trained Indicates whether the record was trained (if false, rejects)
   * @param {*} payload The payload to be saved.
   * @param {IEditablesServiceParams} parameters
   * @returns {Promise<ISaveResult>}
   */
  async signOff(trained, payload, parameters) {
    /**
     * @type {IEditablesServiceParams}}
     */
    const actualParameters = {
      ...DEFAULT_PARAMETERS,
      model: "TrainingRecord",
      urlPrefix: "editables/TrainingRecord",
      action: parameters.useTwoWayCommunication ? "approve" : `approveOrReject/${payload.versionId}`,
      ...(parameters || {}),
    };

    const actualPayload = {
      ...payload,
      approve: trained,
    };
    return this.sendToBackend(actualPayload, actualParameters);
  }

  /**
   * Signs off training for the document version specified in the payload parameter.
   * @param {{userId, documentVersionId}} payload The payload to be saved.
   * @param parameters
   * @returns {Promise<ISaveResult>}
   */
  async assignRetraining(payload, parameters) {
    /**
     * @type {IEditablesServiceParams}}
     */
    const actualParameters = {
      ...DEFAULT_PARAMETERS,
      model: "TrainingRecord",
      urlPrefix: "training/retraining",
      ...parameters,
      action: "assign"
    };

    const actualPayload = {
      ...payload,
    };
    return this.sendToBackend(actualPayload, actualParameters);
  }

  loadTrainingInfo(userId, documentVersions = []) {
    const urlParameters = {
      includeHistory: true,
      loadFullHistory: true,
      includeLatestApprovedOrDraft: true,
      showRemoved: true,
      includeVersionRelationships: true,
      userId,
      documentVersions,
    };
    return this.fetchTrainingInfo(urlParameters);
  }

  fetchTrainingInfo(urlParameters) {
    return new Promise(resolve => {
      UIUtils.secureAjaxGET(`editables/ITP/trainingInfo?${UIUtils.objectToURLParameters(urlParameters)}`).done(trainingRecords => resolve({trainingRecords}));
    });
  }

  getUniqueCurriculaIds(result) {
    const idSet = new Set();

    for (const item of result.itp.departmentCurricula) {
      idSet.add("CUR-" + item.id);
    }
    if (result.itp && result.itp.CurriculumAssignments) {
      for (const item of result.itp.CurriculumAssignments) {
        idSet.add("CUR-" + item.CurriculumId);
      }
    }
    return [...idSet];
  }

  getDownloadedDocumentVersions() {
    return JSON.parse(this.stateStorage.getItem(this.downloadedDocsStateKey) || "[]");
  }

  updateMyUserDownloadedVersions(versions) {
    const storageValue = JSON.stringify(versions);
    this.stateStorage.setItem(this.downloadedDocsStateKey, storageValue);
  }

  addDownloadedDocumentVersion(version) {
    const versions = new Set(this.getDownloadedDocumentVersions());
    if (!versions.has(version)) {
      versions.add(version);
      this.updateMyUserDownloadedVersions([...versions]);
    }
  }

  hasDownloadedVersion(version) {
    const downloadedDocVersions = this.getDownloadedDocumentVersions();
    return downloadedDocVersions.includes(version);
  }

  downloadDocument(fileData, versionId) {
    // there is some lag between getting the S3 token and actually making the button clickable, so we use a loader
    // to make tests more reliable.
    UIUtils.setHideLoadingOnAjaxStop(false);
    UIUtils.showLoadingImage();
    return DocumentTransferHelper.handleFileDownload(fileData).then(() => {
      if (versionId) {
        this.addDownloadedDocumentVersion(versionId);
      }
    }).finally(() => {
      // ensures the hiding happens on the next tick, so we are 100% sure the local storage got updated.
      setImmediate(() => {
        UIUtils.hideLoadingImage();
        UIUtils.setHideLoadingOnAjaxStop(true);
      });
    });
  }

  /**
   * Figure out if the current user can do said action with the given type and instance.
   * @param params.permissions {[]} The permissions for the user
   * @param params.action {*} An action from CommonSecurity.Actions
   * @param params.type {*} An type from CommonSecurity.Types
   * @param params.instance {*} An instance of data.  While this isn't used yet, pass it in if you can so that supporting instance level security will be easier later on.
   * @param params.defaultVerificationResult {boolean} The result of the default permissions verification
   * @param [params.user] {*} The user being checked
   */
  handleCheckPermissions(params) {
    let {instance, defaultVerificationResult, user} = params;

    if (!user) {
      user = {
        id: UIUtils.getUserId(),
        isTrainingCoordinator: this.isCurrentUserATrainingCoordinator(),
      };
    }

    const currentUserIsSupervisorForITPUser = !!(instance && instance.user && user && UIUtils.parseInt(instance.user.supervisorId) === UIUtils.parseInt(user.id));
    const currentUserIsTrainingCoordinator = !!(user && user.isTrainingCoordinator);

    const result = defaultVerificationResult || currentUserIsSupervisorForITPUser || currentUserIsTrainingCoordinator;

    return !!result;
  }

  groupTrainingRecordsByDocumentVersionId(trainingRecords) {
    return new Map(trainingRecords.map(item => ([item.documentVersionId, item])));
  }

  /**
   * Returns a boolean indicating if the currently logged-in user is a training coordinator
   * @return {boolean}
   */
  isCurrentUserATrainingCoordinator() {
    const value = Cookies.get("IS_TRAINING_COORDINATOR");
    return value === "true";
  }

  isApprovedVersion(record) {
    return record.minorVersion === 0 &&
      !record.deletedAt &&
      ![UIUtils.VERSION_STATES.OBSOLETE, UIUtils.VERSION_STATES.ARCHIVED].includes(record.currentState);
  }

  getTrainingSignOffStatus(userId, instance, versionId = undefined) {
    let result = {
      tooltipText: "You can only sign off your own training.",
      disabled: true,
      visible: false,
    };

    if (instance) {
      versionId = versionId || instance.LastApprovedVersionId;

      if (UIUtils.parseInt(userId) === UIUtils.getUserId()) {
        Logger.info(">> Training instance: ", Log.object(instance));

        if (instance.trainingStatus === "Trained") {
          result.tooltipText = "This document version has already been trained.";
          result.visible = true;
        } else if (instance.currentState === "Obsolete") {
          result.tooltipText = "This document version has been superseded. You should train on the latest version of this document.";
        } else if (instance.trainingStatus === "Assigned") {
          if (this.hasDownloadedVersion(versionId)) {
            result.tooltipText = "Signs off this document as trained.";
            result.visible = true;
            result.disabled = false;
          } else {
            result.tooltipText = "You need to download and read a document to be able to mark it as trained.";
            result.visible = true;
          }
        }
      }
    }
    return result;
  }
}
