import React from "react";
import BaseAttribute from "./base_attribute";
import { getCSSClassForLegend } from "../../reports/risk_map_reports/utilities/risk_map_report_helper";

export class RiskScoreAttribute extends BaseAttribute {
  constructor(props) {
    super(props);
  }

  getInitialValue() {
    return "";
  }

  getValue() {
    return this.props.scale;
  }

  getInputId() {
    return this.getAttributeName() + "ComboBox";
  }

  renderInput() {
    if (this.isView()) {
      let inputId = this.getInputId();
      const scale = this.getValue();
      if (!scale) {
        return <span className="skeleton"></span>;
      }

      return (
        <div className="scoreInfo" id={inputId}>
          <div className="value">{scale.riskScore}</div>
          <div className={"list-table-risk-label " + getCSSClassForLegend(scale.color)}></div>
          <div className="label">{scale.scoreLabel}</div>
        </div>
      );
    } else {
      return <span>It is only supports in VIEW mode</span>;
    }
  }
}