"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import pdfJs from "pdfjs-dist";
import * as pdfJsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfJs.GlobalWorkerOptions.workerSrc = pdfJsWorker;

import PDFDocument from "./pdf_document";
import BaseReactComponent from "../../base_react_component";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import PDFControlBar, { ZOOM } from "./pdf_control_bar";

const Logger = Log.group(LOG_GROUP.Documents, "PDFViewer");

/**
 * This is responsible for displaying a pdf in a div.
 */
export default class PDFViewer extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.canvas = null;
    this.renderingInProcess = false;

    this.previousSource = null;

    this.setStateSafely({
      pages: [],
      initialDisplayPage: 1,
      finalDisplayPage: 1,
      currentPage: 1,
      lastLoadedPage: 0,
      zoomLevel: props.zoomLevel,
    });

    this.documentRef = React.createRef();

    this.parentDomContainer = React.createRef();
    this.controlBar = React.createRef();
    this.controlBarContainer = React.createRef();

    this.originalWindowTitle = document.title;
  }

  get document() {
    return this.documentRef.current;
  }

  componentDidMount() {
    super.componentDidMount();
  }

  handleScrollCompleted(scrollTop, scrollLeft, scrollCompleted) {
    Logger.verbose(() => "Scroll: ", Log.symbol(scrollTop), Log.symbol(scrollLeft), "Scroll Completed:", Log.symbol(scrollCompleted));
    if (scrollCompleted) {
      this.setStateSafely({
        scrollTop,
        scrollLeft
      });
    } else {
      let scrolledPage = this.document.findFocusedPage();
      if (this.state.currentPage !== scrolledPage) {
        Logger.debug(() => "Current page changed: ", Log.symbol(scrolledPage));
        this.setStateSafely({
          currentPage: scrolledPage
        });
      }
    }
  }

  /**
   * Adjusts the PDF zoom so that you can zoom in or out.
   * @param direction {ZOOM}
   */
  handleZoom(direction) {
    const {zoomLevel} = this.state;
    let isReset = direction === ZOOM.RESET;
    let scaleFactor;

    if (isReset) {
      Logger.debug(() => "Resetting zoom");
      scaleFactor = 100 / zoomLevel;
    } else {
      Logger.debug(() => "Zooming ", Log.symbol(direction === ZOOM.IN ? "in" : "out"), Log.symbol(zoomLevel));
      scaleFactor = 1 + (0.1 * direction);
    }
    this.setStateSafely({
      zoomLevel: zoomLevel * scaleFactor,
    }, () => {
      this.document.scaleView(scaleFactor);
    });

  }

  handlePageChange(currentPage) {
    let page;
    if (UIUtils.isInteger(currentPage)) {
      page = UIUtils.parseInt(currentPage);
    }

    this.setStateSafely({currentPage: page}, () => {
      Logger.debug(() => "Changing page to", Log.symbol(currentPage));
      this.document.goToPage(page);
    });
  }

  restoreScrollPosition() {
    this.document.setScrollPosition(this.state.scrollTop, this.state.scrollLeft);
  }

  handleViewChanged(view) {
    this.setStateSafely({
      selectedView: view
    }, () => {
      this.restoreScrollPosition();
    });
  }

  handleCalculateHeight({viewerRoot}) {
    const {elementToFill, isVisible} = this.props;
    const {height} = this.state;
    let newHeight = height || $(window).height();
    let stretchToElementDomObject = elementToFill && elementToFill.current;
    if (isVisible && stretchToElementDomObject) {
      /**
       * @type {Element}
       */
      const container = viewerRoot && viewerRoot.current;

      if (container) {
        const controlBar = this.controlBarContainer && this.controlBarContainer.current;
        if (controlBar) {
          let controlBarHeight = controlBar.offsetHeight || 0;
          Logger.verbose("Stretching to element: ", Log.object(stretchToElementDomObject), Log.object(stretchToElementDomObject.clientTop), controlBarHeight);
          const clientHeight = stretchToElementDomObject.clientHeight || stretchToElementDomObject.offsetHeight;
          newHeight = ($(stretchToElementDomObject).offset().top + clientHeight) - $(container).offset().top - controlBarHeight;

          if (newHeight !== height) {
            this.setStateSafely({height: newHeight});
          }
        }
      }
    } else {
      Logger.debug("PDF viewer is not visible. Using previous height: ", Log.object(newHeight));
    }
    return newHeight;
  }

  render() {
    const {
      name,
      isVisible,
      documentSource,
      panelSize,
      showingState,
      downloadLink,
      externalUrl,
      onLoaded,
      onLoading,
      onDownload,
      onRendering,
      onReady,
      onRenderCompleted,
      onValidationError,
    } = this.props;
    const {
      currentPage,
      pageCount,
      zoomLevel,
      isLoaded,
    } = this.state;
    const visibilityClass = isVisible && isLoaded ? "" : "d-none";
    return (
      <div
        className={`document-preview card h-100 ${visibilityClass}`}
        ref={this.parentDomContainer}
        id={`${name}DocumentPreview`}
      >
        <div className="card-img-top">
          <PDFDocument
            documentSource={documentSource}
            ref={this.documentRef}
            fileData={downloadLink}
            zoomLevel={zoomLevel}
            currentPage={currentPage || 1}
            panelSize={panelSize}
            onScroll={this.handleScrollCompleted}
            showingState={showingState}
            isVisible={isVisible}
            onLoading={(status) => {
              UIUtils.showLoadingCursor(this.parentDomContainer.current);
              return onLoading(status);
            }}
            onLoaded={(status) => {
              this.setStateSafely({pageCount: status.pageCount, isLoaded: true});
              return onLoaded(status);
            }}
            onReady={(status) => {
              this.setStateSafely({isReady: true});
              return onReady(status);
            }}
            onRendering={(status => {
              this.renderingInProcess = true;
              document.title = `${this.originalWindowTitle} (${Math.ceil(status.percentage)}%)`;
              UIUtils.showLoadingCursor(this.parentDomContainer.current);
              return onRendering(status);
            })}
            onRenderCompleted={(status) => {
              document.title = this.originalWindowTitle;
              UIUtils.hideLoadingCursor(this.parentDomContainer.current);
              this.renderingInProcess = false;
              return onRenderCompleted(status);
            }}
            onCalculateHeight={this.handleCalculateHeight}
            onValidationError={onValidationError}
          />
        </div>
        <div
          className="card-footer py-0"
          ref={this.controlBarContainer}
        >
          <PDFControlBar
            onDownload={onDownload}
            downloadLink={downloadLink}
            externalUrl={externalUrl}
            totalPages={pageCount}
            currentPage={currentPage}
            panelSize={panelSize}
            ref={this.controlBar}
            onViewChange={this.handleViewChanged}
            onZoomChanged={(direction) => this.handleZoom(direction)}
            onPageChange={this.handlePageChange}
            document={this.documentRef}
          />
        </div>
      </div>
    );
  }
}

PDFViewer.defaultProps = {
  onDownload: () => {
    Logger.warn("PDF Control Bar download handler not set");
  },
  downloadLink: null,
  externalUrl: null,
  maxPDFPages: PDFDocument.defaultProps.maxPDFPages,
  zoomLevel: PDFDocument.defaultProps.zoomLevel,
  originalDocumentWidth: 0,
  originalDocumentHeight: 0,
  totalPages: PDFDocument.defaultProps.totalPages,
  renderer: PDFDocument.defaultProps.renderer,
  preloadedPages: PDFDocument.defaultProps.preloadedPages,
  onCalculateHeight: PDFDocument.defaultProps.onCalculateHeight,
  onLoading: PDFDocument.defaultProps.onLoading,
  onLoaded: PDFDocument.defaultProps.onLoaded,
  onReady: PDFDocument.defaultProps.onReady,
  onRendering: PDFDocument.defaultProps.onRendering,
  onRenderCompleted: PDFDocument.defaultProps.onRenderCompleted,
  onValidationError: PDFDocument.defaultProps.onValidationError,
  onCriticalError: PDFDocument.defaultProps.onCriticalError,
};
