"use strict";

import {getAttributes} from "../../utils";
import {NodeSpec} from "@progress/kendo-editor-common";

export const NON_EDITABLE_QBD_FIELD_NODE: NodeSpec = {
  name: "nonEditableQbDField",
  inline: true,
  group: "inline",
  content: "inline+",
  marks: "",
  attrs: {
    contenteditable: {default: false},
    class: {default: null},
    style: {default: null},
    "data-record-path": {default: null},
    "data-record-id": {default: null},
    "data-record-model-name": {default: null},
    "data-record-column-name": {default: null},
    "data-record-sub-model-name": {default: null},
    "data-record-sub-model-data": {default: null},
  },
  atom: true,
  parseDOM: [
    {
      tag: "span.qbd-output",
      getAttrs: (node) => {
        if (!(node instanceof HTMLElement)) {
          return {};
        }
        const nodeAttrs = getAttributes(node);
        const squareElement =
          node instanceof HTMLElement && node.querySelector(".square-sm");
        if (squareElement) {
          nodeAttrs.class = `${nodeAttrs.class} ${squareElement.className}`;
        }
        return nodeAttrs;
      },
      contentElement: (node) => {
        return (
          (node instanceof HTMLElement && node.querySelector(".square-sm")) ||
          (node as HTMLElement)
        );
      },
      priority: 51,
    },
  ],
  toDOM: (node) => {
    // This is for special case to display risk label color element
    if (node.attrs["class"]?.includes("square-sm")) {
      const className = node.attrs["class"].replace(
        "qbd-output qbd-output-direct-scope-widget",
        "",
      );
      return [
        "span",
        {
          ...node.attrs,
          class: "qbd-output qbd-output-direct-scope-widget",
        },
        ["span", {class: className}, 0],
      ];
    }
    return ["span", {...node.attrs}, 0];
  },
};
