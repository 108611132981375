"use strict";

import React from "react";
import BaseReactComponent from "../../../base_react_component";

/**
 *  This shows the training onboarding step
 */
export default class TrainingOnboardingStep extends BaseReactComponent {

  render() {
    let {title, description, action, disabled, handler, id} = this.props;
    return (
      <div className="training-onboarding-step-container">
        <div className="row training-onboarding-header">
          {title}
        </div>
        <div className="row">
          <div className=" col-sm-10">
            {description}
          </div>
          <div className="col-sm-2">
            <button
              id={id}
              type="button"
              disabled={disabled}
              onClick={handler}
              className="btn btn-primary btn-secondary training-onboarding-step-button"
            >
              {action}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
