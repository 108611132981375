"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import moment from "moment-timezone";
import { EDITOR_OPERATIONS } from "../editor_constants";
import { getURLByTypeNameAndId } from "../../helpers/url_helper";
import quickPanelIconTiny from "../../images/quickPanel/quick-panel-icon-tiny.png";
import BaseReactComponent from "../../base_react_component";
import { EditorFactory } from "../editor_factory";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouterContext } from "../../utils/router_context";

const Logger = Log.group(LOG_GROUP.Editables, "BaseQuickPanel");

/**
 * This is the base class for the quick panel, regardless of whether it's in a popout panel (ie. the original QuickPanel)
 * or in a stationary panel on the screen (ie. StaticQuickPanel).
 */
export default class BaseQuickPanel extends BaseReactComponent {
  static contextType = RouterContext;

  constructor(props) {
    super(props);

    this.hideLoadingTimeout = null;
    this.loadingProcessCount = 0;

    this.setStateSafely({
      lastSeenKey: null,
      shouldShowLoading: false,
      hasDataChanged: false,
    });

    this.ignoreNextComponentUpdate = false;

    this.containerRef = React.createRef();
  }

  isVisible() {
    return !!(this.containerRef.current && UIUtils.isVisible(this.containerRef.current));
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.ignoreNextComponentUpdate) {
      this.ignoreNextComponentUpdate = false;
      return false;
    } else {
      let shouldComponentUpdate = (!this.props.id && nextProps.id)
        || this.props.id !== nextProps.id
        || this.props.typeCode !== nextProps.typeCode
        || this.props.versionId !== nextProps.versionId;

      if (shouldComponentUpdate && this.state.hasDataChanged) {
        shouldComponentUpdate = window.confirm(UIUtils.onWindowBeforeUnload());
        if (!shouldComponentUpdate) {
          if (this.props.onCancelLoad) {
            // The next update will be because we're going back to the previous value.
            this.ignoreNextComponentUpdate = true;
            this.props.onCancelLoad(this.props.typeCode, this.props.id, this.props.versionId);
          }
          return false;
        }
      }

      const nextVersions = nextProps.data && nextProps.data[nextProps.data.modelName + "Versions"];
      const nextVersionState = nextVersions && nextVersions[0].currentState;
      const versions = this.props.data && this.props.data[this.props.data.modelName + "Versions"];
      const versionState = versions && versions[0].currentState;

      shouldComponentUpdate = (
        shouldComponentUpdate
        || (nextState && nextState.dataSaved)
        || this.state.showingState !== nextState.showingState
        || this.state.shouldShowLoading !== nextState.shouldShowLoading
        || this.props.editorType !== nextProps.editorType
        || this.props.editorOperation !== nextProps.editorOperation
        || this.props.data?.updatedAt !== nextProps.data?.updatedAt
        || nextVersionState !== versionState
        // this is required to resize document preview
        || (this.props.editorOperation === EDITOR_OPERATIONS.VIEW && this.state.panelSize !== nextState.panelSize)
      );

      return shouldComponentUpdate;
    }
  }

  componentDidUpdate(prevProps) {
    // Show the spinner for a very short time when the key changes just to notify the user that something changed.
    let key = this.getItemKey();

    if (this.state.lastSeenKey !== key) {
      this.setStateSafely({
        lastSeenKey: key,
        shouldShowLoading: true,
        hasDataChanged: false,
      });
      let msToShowLoadingIndicator = this.getMsToShowLoadingIndicator();
      setTimeout(() => {
        if (!this.state.loading) {
          this.setStateSafely({
            shouldShowLoading: false
          });
        }
      }, msToShowLoadingIndicator);
    }

    let stateChanges = [];
    // If the parent has changed the editor type/operation, then propagate that internally.
    if (this.props.editorType !== prevProps.editorType) {
      stateChanges.push({editorType: this.props.editorType});
    }
    if (this.props.editorOperation !== prevProps.editorOperation) {
      stateChanges.push({editorOperation: this.props.editorOperation});
    }

    if (stateChanges.length > 0) {
      const stateChange = Object.assign({}, ...stateChanges);
      this.setStateSafely(stateChange);
    }
  }

  getContainerRef() {
    return this.containerRef;
  }

  getItemKey() {
    return this.props.versionId
      ? this.props.typeCode + "-" + this.props.id + "-" + this.props.versionId + "-" + this.getEditorType() + "-" + this.getEditorOperation()
      : this.props.typeCode + "-" + this.props.id + "-" + this.getEditorType() + "-" + this.getEditorOperation();
  }

  /**
   * Override this to show the loading indicator for longer.
   * @return {number} The number of ms to wait while showing the loading indicator when changing records.
   */
  getMsToShowLoadingIndicator() {
    return 200;
  }

  handleSwitchViewMode(button, newEditorOperation, data, done) {
    let discardChanges = true;
    if (!newEditorOperation) {
      newEditorOperation = this.getEditorOperation() === EDITOR_OPERATIONS.VIEW
        ? EDITOR_OPERATIONS.EDIT
        : EDITOR_OPERATIONS.VIEW;
    }
    /**
     * Tip: {@link Log.object} returns a function that is only evaluated in case the log level
     * used in the {@link Logger} call is enabled  so there won't be additional cost here if the log is disabled.
     */
    Logger.debug(
      "Switch View mode called with editorOperation:", Log.object(this.getEditorOperation()),
      "\n - hasDataChanged:", Log.object(this.state.hasDataChanged),
      "\n - button: ", Log.object(button && button.id),
      "\n - newEditorOperation", Log.object(newEditorOperation),
      "\n - data: ", Log.object(data),
      "\n - done: ", Log.object(typeof done),
    );
    if (this.getEditorOperation() === EDITOR_OPERATIONS.EDIT && this.state.hasDataChanged) {
      discardChanges = window.confirm(UIUtils.onWindowBeforeUnload());
    }

    if (discardChanges) {
      if (this.props.onChangeEditorOperation) {
        /**
         * The {@link done} callback is used to ensure the revision data is reloaded properly for approved records.
         * @see the insanely huge comment in {@link StaticApprovalContainer.openPage} for more information.
         */
        this.props.onChangeEditorOperation(newEditorOperation, data, done);
      }

      if (newEditorOperation !== EDITOR_OPERATIONS.EDIT) {
        this.context.clearPreventNavigation();
      }
    }
  }

  handleActionClick(action, ...args) {
    if (typeof this.props.onActionClick === "function") {
      return this.props.onActionClick(action, ...args);
    }
    return null;
  }

  handleDataChanged() {
    this.setStateSafely({
      hasDataChanged: true,
      dataSaved: false
    });
  }

  handleDataSaved(requirement) {
    this.setStateSafely({
      hasDataChanged: false,
      dataSaved: true
    }, () => {
      if (this.props.onSaveCompleted) {
        this.props.onSaveCompleted(requirement);
      }
    });
  }

  handleReset() {
    this.setStateSafely({
      hasDataChanged: false,
      dataSaved: false
    });
  }

  forceUpdateAfterClone() {
    this.setStateSafely({
      hasDataChanged: false,
      dataSaved: true
    });
  }

  isAsOfDateInThePast() {
    let {asOfDate} = this.props;
    return asOfDate && UIUtils.getEndOfDayForDate(moment()).diff(moment(asOfDate)) > 1000; // 1 second of difference is fine.
  }

  findVersionForAsOfDate() {
    let {data, asOfDate} = this.props;
    let versionsName = data.modelName + "Versions";
    let versions = data[versionsName];
    asOfDate = moment(asOfDate);

    let sortedVersions = versions.filter(version => asOfDate.isSameOrAfter(version.createdAt)).sort((a, b) => b.id - a.id);
    return sortedVersions[0];
  }

  getEditorType() {
    return this.props.editorType;
  }

  getEditorOperation() {
    let editorOperation = this.props.editorOperation;
    if (this.props.data &&
      (this.isAsOfDateInThePast() || this.shouldForceViewMode())) {
      editorOperation = EDITOR_OPERATIONS.VIEW;
    }
    return editorOperation;
  }

  shouldForceViewMode() {
    let {data} = this.props;
    const isInBadState = data && data.currentState && (
      data.currentState.startsWith(UIUtils.VERSION_STATES.PROPOSED)
      || data.currentState.startsWith(UIUtils.VERSION_STATES.ROUTING)
      || data.currentState.startsWith(UIUtils.VERSION_STATES.ARCHIVED)
    );
    const isArchived = data && data.deletedAt;
    return isInBadState || isArchived ? {isInBadState, isArchived} : false;
  }

  forceResetToCachedData() {
    this.recordShowing && this.recordShowing.handleReset();
  }

  renderPanelContent() {
    let {
      id, versionId, typeCode, data,
      config, operation, emptySelectionMessage,
      onMarkAsReviewed, isProposeForArchiveOrRestore, onRiskLinkConfirmationDialogRender,
      processExplorerHelper,
    } = this.props;

    let {
      panelSize,
      showingState,
      selectedEditorTab,
    } = this.state;

    let key = this.getItemKey();
    let panelInfo;

    if (data && this.isAsOfDateInThePast()) {
      // We're viewing a date that's not today
      data = UIUtils.deepClone(this.findVersionForAsOfDate());
      data.id = id;
      panelInfo = (
        <div className="alert alert-info quick-panel-record-info-box">
          Viewing historical record <b>v{data.majorVersion}.{data.minorVersion}</b> from <b>{UIUtils.getDateForDisplayToUser(data.createdAt)}</b>. <br />
          <br />
          To edit the latest draft, change the report date to today.
        </div>
      );
    } else if (this.props.editorOperation === EDITOR_OPERATIONS.EDIT) {
      const forceViewModeReason = this.shouldForceViewMode();
      if (forceViewModeReason) {
        // A new draft can't be made on this record
        panelInfo = (
          <div className="alert alert-info quick-panel-record-info-box" id="quickPanelAlertInfoDiv">
            A new draft cannot be created because this record is <b>{forceViewModeReason.isInBadState ? data.currentState : " archived."}</b>. <br />
            <br />
            To edit, {" "}
            <a id="quickPanelOpenRecord"
               href={getURLByTypeNameAndId(data.modelName, "View", data.id)}
               rel="noopener noreferrer"
               target="_blank"
            >
              click here
            </a>
            {" "} to open the record and change the state.
          </div>
        );
      }
    }

    return (!id && this.getEditorOperation() !== EDITOR_OPERATIONS.ADD) || !typeCode ? (
      <div id="bodyDiv">
        <div className="container">
          <div className="row row-white shadow mt-3">
            <div className="col-sm-12">
              <a id="staticPanelDefaultCloseButton"
                 onClick={this.props.onCloseWindow}
                 className="static-panel-icon-link float-right"
              >
                <FontAwesomeIcon icon={faTimes} />
              </a>
            </div>
            <div className="col-sm-12">
              <div className="quick-panel-help-message alert alert-info">
                {emptySelectionMessage ?
                  <span>{emptySelectionMessage}</span> :
                  <Fragment>
                    <span>Click on any record marked with</span>
                    <img className="quick-panel-icon"
                         src={quickPanelIconTiny}
                         alt="Quick Edit Icon"
                    />
                    <span>to edit it here.</span>
                  </Fragment>}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (() => {
      let eventListeners = {
        onMarkAsReviewed: onMarkAsReviewed,
        onSaveCompleted: this.handleDataSaved,
        onDataChanged: this.handleDataChanged,
        onReset: this.handleReset,
        onSwitchViewMode: this.handleSwitchViewMode,
        onActionClick: this.handleActionClick,
        onCloseWindow: this.props.onCloseWindow,
        onChangeEditorTab: (selectedEditorTab) => setImmediate(() => this.setStateSafely({selectedEditorTab})),
        onRiskLinkConfirmationDialogRender: onRiskLinkConfirmationDialogRender,
      };

      let attributeControlParams = {
        key,
        id,
        versionId,
        editorType: this.getEditorType(),
        editorOperation: this.getEditorOperation(),
        showAlert: panelInfo,
        quickPanelConfig: config,
        cachedData: data,
        eventListeners,
        panelSize,
        showingState,
        operation,
        selectedEditorTab,
        isProposeForArchiveOrRestore,
        processExplorerHelper,
        containerRef: this.getContainerRef(),
        ref: recordShowing => this.recordShowing = recordShowing,
        onLoading: (status) => this.startLoading(status),
        onLoaded: (status) => this.stopLoading(status),
        onError: (error, details) => this.stopLoading(status, () => UIUtils.defaultFailFunction(error, details)),
        onReady: (status) => this.stopLoading(status),
      };

      /**
       * Creates the correct editor given a type code. No need to edit this file anymore.
       * YOU CAN FIND THE MAPPING TO THE EDITORS IN {@link EDITOR_COMPONENT_MAP}
       * @type {EditorFactory}
       */
      const editorFactory = new EditorFactory();
      const EditorComponent = editorFactory.fromTypeCode(typeCode);

      if (EditorComponent) {
        return <EditorComponent {...attributeControlParams} />;
      } else {
        return <div className="alert alert-danger error-bar">There is no implementation for type ${typeCode}</div>;
      }
    })();
  }

  startLoading(status, callback) {
    return setImmediate(() => {
      // This will ensure the loading indicator will not flicker
      this.loadingProcessCount++;
      Logger.debug("Starting quick panel loading: ",
        Log.object(this.loadingProcessCount), Log.object(status && status.message),
      );

      if (status && status.message) {
        $("#quickPanelLoadingMessage").text(status.message);
      }
      this.setStateSafely({shouldShowLoading: true, loading: true, loadingStatus: status}, callback);
    });
  }

  stopLoading(status, callback) {
    this.hideLoadingTimeout = setTimeout(() => {
      // ensures it will only hide the loading indicator after the loading actually completes
      this.loadingProcessCount = Math.max(--this.loadingProcessCount, 0);

      if (this.loadingProcessCount === 0) {
        Logger.debug("Stopping quick panel loading: ",
          Log.object(this.loadingProcessCount), Log.object(status),
        );
        this.loadingProcessCount = 0;
        this.setStateSafely({
          shouldShowLoading: false,
          loading: false,
          loadingStatus: status
        }, callback);
      } else {
        Logger.debug(
          () => "Cannot stop quick panel loading. Still in progress: ",
          Log.object(this.loadingProcessCount), Log.object(status),
        );
      }
    }, 10);
    return this.hideLoadingTimeout;
  }
}
