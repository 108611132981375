"use strict";

import * as UIUtils from "../../../../../ui_utils";
import DatePicker from "../../../../../widgets/date_picker";
import moment from "moment";
import React from "react";
import BaseEditor from "./base_editor";

/**
 * This class is responsible for rendering the date editors in the AI Import edit screen.
 */
export default class DateEditor extends BaseEditor {
  constructor(props) {
    super(props);

    this.setStateSafely({
      rawValue: this.props.value ? moment(this.props.value, UIUtils.DATE_FORMAT_FOR_STORAGE).format(UIUtils.DATE_FORMAT_FOR_DISPLAY) : ""
    });
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (!super.shouldComponentUpdate(nextProps, nextState, nextContext)) {
      const {value, minValue, maxValue} = this.props;
      const {rawVale} = this.state;
      const nextValue = nextProps.value;
      const nextMinValue = nextProps.value;
      const nextMaxValue = nextProps.value;
      const nextRawValue = nextState.rawValue;

      return value !== nextValue
        || minValue !== nextMinValue
        || maxValue !== nextMaxValue
        || rawVale !== nextRawValue;
    }

    return true;
  }

  handleChange(date) {
    const momentDate = date ? moment(date) : null;
    let dateString = momentDate ? momentDate.format(UIUtils.DATE_FORMAT_FOR_STORAGE) : null;
    let displayDateString = momentDate ? momentDate.format(UIUtils.DATE_FORMAT_FOR_DISPLAY) : "";

    this.setStateSafely({
      rawValue: displayDateString
    });

    if (this.props.onChange) {
      this.props.onChange(dateString);
    }
  }

  handleChangeRaw(event) {
    this.setStateSafely({
      rawValue: event.target.value
    });
  }

  getSelectedDate() {
    const {value} = this.props;
    return value ? moment(this.props.value, UIUtils.DATE_FORMAT_FOR_STORAGE) : null;
  }

  getSelectedDateStringForDisplay() {
    const selectedDate = this.getSelectedDate();
    return selectedDate ? selectedDate.format(UIUtils.DATE_FORMAT_FOR_DISPLAY) : "";
  }

  handleClickOutside() {
    this.setStateSafely({
      rawValue: this.getSelectedDateStringForDisplay()
    });
  }

  getTextForMarkup() {
    if (typeof this.props.confidence !== "undefined") {
      return this.state.rawValue;
    } else {
      return super.getTextForMarkup();
    }
  }

  getEditorHighlightsClass() {
    return "input";
  }

  renderInput() {
    const {id} = this.props;

    return (
      <div className="import-data-editor-input-div date-editor">
        <DatePicker key="importDataEditorInput"
                    id={id}
                    selected={UIUtils.convertMomentToDate(this.getSelectedDate())}
                    disabled={this.props.disabled}
                    className={"form-control import-paper-editor" + this.getEditorBorderClass()}
                    onChange={this.handleChange.bind(this)}
                    onChangeRaw={this.handleChangeRaw}
                    onClickOutside={this.handleClickOutside}
        />
      </div>
    );
  }
}
