"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import BaseApprovableEditorPage from "./base_approvable_editor_page";
import ErrorBar from "../widgets/bars/error_bar";
import { EDITOR_TYPES } from "./editor_constants";
import { AUTO_VALIDATION_MODE_ENUM, SAVE_BEHAVIOR_ENUM } from "./base_approvable_editor";
import CommonUtils from "../../server/common/generic/common_utils";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";
import Section from "./widgets/section";
import MemoryCache from "../utils/cache/memory_cache";
import { EXPERIMENTS } from "../../server/common/generic/common_experiments";
import InfoTooltip from "../widgets/tooltips/info_tooltip";

const AjaxWrapper = require("../utils/ajax_wrapper");

const AUTO_VALIDATION_TRIGGER_TIMEOUT = 2000;

const Logger = Log.group(LOG_GROUP.Editables, "BaseQuickEditor");

/**
 * This is the base class for showing records in the QuickPanel.  This class follows the naming convention of its
 * parents. It's not necessarily in edit mode.  The `editorOperation` decides that. It could be in view or add mode.
 */
export default class BaseQuickEditor extends BaseApprovableEditorPage {
  constructor(props, baseTypeName, capitalizedBaseTypeName, displayName) {
    super(props, baseTypeName, capitalizedBaseTypeName, displayName);
    this.sectionRef = React.createRef();

    if (this.getEditorType() !== EDITOR_TYPES.FULL_SCREEN) {
      this.resetState(props.cachedData, () => {
        if (this.isEdit() && this.state.approved) {
          // Reload the latest data so we're not editing old stuff/link version info (probably the approved version).
          this.loadNewVersion(false);
        }
      });
    }

    const recordFields = MemoryCache.getNamedInstance("section_children");
    recordFields.clear();

    this.loadDefaultState();
  }

  // Override all of the header info
  renderPageTitleBar() {
    return this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? super.renderPageTitleBar() : "";
  }

  shouldShowCompany() {
    return this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? super.shouldShowCompany() : false;
  }

  shouldShowNav() {
    return this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? super.shouldShowNav() : false;
  }

  shouldShowCookies() {
    return this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? super.shouldShowCookies() : false;
  }

  getErrorBar() {
    return this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? super.getErrorBar() : (
      <ErrorBar id="quickPanelAlertDiv" />
    );
  }

  failCallback(result) {
    if (this.getEditorType() === EDITOR_TYPES.FULL_SCREEN) {
      super.failCallback(result);
    } else {
      UIUtils.hideLoadingImage();
      Logger.debug(() => `Unexpected failure occurred.  Result = ${JSON.stringify(result)}`);
      const errorDetails = AjaxWrapper.extractErrorDetails(result);

      if (errorDetails) {
        let {errorText, detailText} = errorDetails;
        UIUtils.clearError("#quickPanelAlertDiv");
        UIUtils.showError(errorText, detailText, "#quickPanelAlertDiv");
        $("#quickPanelInnerContainer").animate({scrollTop: 0}, 500);
        UIUtils.setHideLoadingOnAjaxStop(true);
      }
    }
  }

  handleSaveResults(result) {
    if (this.getEditorType() === EDITOR_TYPES.FULL_SCREEN) {
      super.handleSaveResults(result);
    } else {
      if (this.props.quickPanelConfig && this.props.quickPanelConfig.saveBehavior === SAVE_BEHAVIOR_ENUM.SAVE_AND_RELOAD) {
        window.location.reload();
      } else {
        let record = this.state;

        /* Update the state with the results from the server, so that any server controlled attributes like
           the LastVersionId are updated on the client as well. This is done to avoid an extra server load and
           get the new draft details from the server again. Without this, the client will not be able to save again
           the same record, without first updating certain attributes.
         */
        for (let attribute in result) {
          if (Object.prototype.hasOwnProperty.call(result, attribute)) {
            record[attribute] = result[attribute];
          }
        }
        record.versionId = record.LastVersionId;


        this.setStateSafely({
          ...record,
          ...this.preprocessReceivedData(record),
        }, () => {
          if (this.props.eventListeners && this.props.eventListeners.onSaveCompleted) {
            this.props.eventListeners.onSaveCompleted(record);
          }
        }, () => {
          UIUtils.setHideLoadingOnAjaxStop(true);
          UIUtils.hideLoadingImage();
        });
      }
    }
  }

  handleApprovalResult(result, approve) {
    super.handleApprovalResult(result, approve);
    if (this.getEditorType() !== EDITOR_TYPES.FULL_SCREEN) {
      if (this.props.eventListeners && this.props.eventListeners.onSaveCompleted) {
        this.props.eventListeners.onSaveCompleted(result[1]);
      }
    }
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.shouldVerifyForm()) {
      this.handleVerifyForPropose(false);
    }
  }

  shouldVerifyForm() {
    return this.props.quickPanelConfig
      && (this.isAdd() || this.isEdit())
      && this.props.quickPanelConfig.autoValidationMode === AUTO_VALIDATION_MODE_ENUM.PROPOSE;
  }

  handleChangeValue(attributeName, attributeValue, callback, attributeType) {
    super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
    if (this.shouldVerifyForm()) {
      clearTimeout(this.verifyForProposeTimeout);
      this.verifyForProposeTimeout = setTimeout(() => {
        this.handleVerifyForPropose(false);
      }, AUTO_VALIDATION_TRIGGER_TIMEOUT);
    }
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    const {id, cachedData} = this.props;

    if (this.getEditorType() !== EDITOR_TYPES.FULL_SCREEN && !cachedData && (prevProps.id !== id)) {
      // Load the data for the new Record since it's not cached
      this.id = id;
      this.getDataFromServer();
    }

    if (cachedData
      && prevProps
      && prevProps.cachedData
      && (cachedData.id !== prevProps.cachedData.id
        || cachedData.LastApprovedVersionId !== prevProps.cachedData.LastApprovedVersionId
        || cachedData.LastVersionId !== prevProps.cachedData.LastVersionId
        || cachedData.LastVersionTransitionId !== prevProps.cachedData.LastVersionTransitionId)) {
      /* Even though this is in componentDidUpdate, React will make sure the new state set here is pushed before the user sees it.
       * Read more here: https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html#invoking-external-callbacks
       */
      this.resetState(cachedData);
    }
  }

  handleAfterProposeButtonClick() {
    this.scrollToFirstValidationError();
  }

  /**
   * This handler is meant to be used from within the quick edit panel. It may need to be adjusted if you want to use it
   * in the Full Screen forms.
   * @param autoFocus Set this to true if you want the form to focus automatically on the first invalid field
   * @param event
   */
  handleVerifyForPropose(autoFocus, event) {
    if (this.getEditorType() !== EDITOR_TYPES.FULL_SCREEN) {
      // Stop the event from bubbling up
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      this.updateControls(true, CommonUtils.FORM_VALIDATION_MODE.PROPOSE, () => {
        // Run any custom validators
        for (let i = 0; i < this.customValidatedChildElements.length; i++) {
          const element = this.customValidatedChildElements[i];
          element.validate(CommonUtils.FORM_VALIDATION_MODE.PROPOSE);
        }

        this.updateControls(false, null, () => {
          this.triggerFormValidation();
          if (autoFocus) {
            this.scrollToFirstValidationError();
          }
        });
      });
    }
  }

  /**
   *  Handle the scrolling to the next field with issues in the quick panel manually.
   *  Find the first field with errors and scroll to it.
   */
  scrollToFirstValidationError() {
    /* We need to trigger the form validation at this point again to register any dynamically added error divs
         in the controls with custom validation.
       */
    this.triggerFormValidation();

    let errorElements = $(".has-error.has-danger");
    if (errorElements.length > 0) {
      const errorElement = $(errorElements[0]);
      this.scrollToElement(errorElement);
    }
  }

  getScrollableContainer() {
    let quickPanelContainer = $("html,#quickPanelInnerContainer");
    return quickPanelContainer && quickPanelContainer.length > 0
      ? quickPanelContainer
      : super.getScrollableContainer();
  }

  handleAddRecordFromSideMenu(field) {
    this.sectionRef?.current?.updateTagsAttributesInputValue(field);
  }

  toggleSideMenu(value) {
    if (this.isEdit() && this.state.showSideMenu !== value) {
      this.setStateSafely({showSideMenu: typeof value === "boolean" ? value : !this.state.showSideMenu});
    }
  }

  renderTagsSectionHeader() {
    return (
      <span>
        Integration Mappings
        <InfoTooltip
          id="infoTags"
          verbiage={
            <div>
              <div>
                Tag your content for easy organization. Use this section to store relevant
                keywords and labels that help store, label, categorize and/or describe any
                content that is related to the sections above.
              </div>
              <br />
              <div>
                This section can be especially useful when integrating with other systems.
              </div>
            </div>
          }
        />
      </span>
    );
  }

  renderTagsSection() {
    return (
      <Section
        id="integrationMappings"
        ref={this.sectionRef}
        parent={this}
        showTagsTable={true}
        processId={this.getProcessId()}
        header={this.renderTagsSectionHeader()}
        onActionButtonClick={this.toggleSideMenu}
      />
    );
  }
}
