var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { classNames } from '@progress/kendo-react-common';
import { Popup } from '@progress/kendo-react-popup';
import { shouldOpenItem, isFirstItemFromSiblings } from '../utils/itemsIdsUtils';
import { getPopupSettings, convertBoolDirectionToString, getDOMElementId } from '../utils/misc';
import { MenuItemLink } from './MenuItemLink';
import { MenuItemInternalsList } from './MenuItemInternalsList';
import { MenuItemArrow } from './MenuItemArrow';
/**
 * @hidden
 */
var MenuItemInternal = /** @class */ (function (_super) {
    __extends(MenuItemInternal, _super);
    function MenuItemInternal(props) {
        var _this = _super.call(this, props) || this;
        _this.isFirstRender = true;
        _this.onMouseOver = function (event) {
            _this.props.onMouseOver(_this.props.item.id);
            event.stopPropagation();
        };
        _this.onMouseLeave = function (event) {
            _this.props.onMouseLeave(_this.props.item.id);
            event.stopPropagation();
        };
        _this.state = { opened: false };
        return _this;
    }
    MenuItemInternal.prototype.componentDidMount = function () {
        var focusedItemId = this.props.focusedItemId;
        var currentItemId = this.props.item.id;
        // If the menu item component has been just mounted due to
        // keyboard navigation and it is the selected one.
        if (focusedItemId && focusedItemId === currentItemId) {
            this.itemElement.focus();
        }
        this.isFirstRender = false;
    };
    MenuItemInternal.prototype.componentDidUpdate = function (prevProps) {
        var focusedItemId = this.props.focusedItemId;
        var currentItemId = this.props.item.id;
        if (focusedItemId) {
            // If the item has been navigated to via the keyboard navigation
            // (Clicking and focusing an item also come here).
            if (prevProps.focusedItemId !== focusedItemId
                && focusedItemId === currentItemId
                // https://github.com/telerik/kendo-react/issues/216 :
                // No need to focus the wrapping menu item DOM element
                // when a child DOM element was clicked.
                && !this.itemElement.contains(document.activeElement)) {
                this.itemElement.focus();
            }
        }
        else if (document.activeElement === this.itemElement) {
            this.itemElement.blur();
        }
    };
    MenuItemInternal.prototype.render = function () {
        var _this = this;
        var item = this.props.item;
        var itemId = item.id;
        return (React.createElement(React.Fragment, null,
            React.createElement("li", { className: this.getMenuItemClassName(item), style: item.cssStyle, tabIndex: itemId === this.props.tabbableItemId ? 0 : -1, onMouseOver: this.onMouseOver, onMouseLeave: this.onMouseLeave, onMouseDown: function (event) { return _this.props.onMouseDown(event); }, onBlur: function () { return _this.props.onBlur(itemId); }, onFocus: function () { return _this.props.onFocus(itemId); }, onClick: function (event) { return _this.props.onClick(event, itemId); }, role: "menuitem", "aria-disabled": item.disabled ? true : undefined, "aria-haspopup": item.items.length > 0 ? true : undefined, "aria-expanded": item.items.length > 0 ? this.Opened : undefined, "aria-label": item.text, "aria-owns": this.Opened ? getDOMElementId(this.props.menuGuid, itemId) : undefined, ref: function (el) { return _this.itemElement = el; }, key: "0" }, this.contentRender ? this.renderContent() : this.renderMenuItemLink()),
            this.renderPopupIfOpened()));
    };
    MenuItemInternal.prototype.renderContent = function () {
        var itemId = this.props.item.contentParentItemId;
        return (React.createElement("div", { className: "k-content", role: "presentation" },
            React.createElement(this.contentRender, { item: this.props.onOriginalItemNeeded(itemId), itemId: itemId })));
    };
    MenuItemInternal.prototype.renderMenuItemLink = function () {
        var item = this.props.item;
        if (this.linkRender) {
            return (React.createElement(this.linkRender, { item: this.props.onOriginalItemNeeded(item.id), itemId: item.id, opened: this.Opened, dir: convertBoolDirectionToString(this.props.isDirectionRightToLeft) }));
        }
        else {
            var textOrItemRender = this.itemRender ?
                React.createElement(this.itemRender, { item: this.props.onOriginalItemNeeded(item.id), itemId: item.id, key: "1" }) :
                React.createElement("span", { className: "k-menu-link-text" }, item.text);
            return (React.createElement(MenuItemLink, { url: item.url, opened: this.Opened },
                this.renderMenuIconIfApplicable(),
                textOrItemRender,
                this.renderArrowIfApplicable()));
        }
    };
    MenuItemInternal.prototype.renderPopupIfOpened = function () {
        if (this.Opened) {
            var itemId = this.props.item.id;
            var _a = getPopupSettings(itemId, this.props.isMenuVertical, this.props.isDirectionRightToLeft), anchorAlign = _a.anchorAlign, popupAlign = _a.popupAlign, collision = _a.collision;
            return (React.createElement(Popup, { anchor: this.itemElement, show: true, popupClass: this.getPopupClassName(), anchorAlign: anchorAlign, popupAlign: popupAlign, collision: collision, animate: false, key: "1" },
                React.createElement(MenuItemInternalsList, { parentItemId: itemId, items: this.props.item.items, menuGuid: this.props.menuGuid, focusedItemId: this.props.focusedItemId, lastItemIdToBeOpened: this.props.lastItemIdToBeOpened, tabbableItemId: this.props.tabbableItemId, itemRender: this.props.itemRender, linkRender: this.props.linkRender, isMenuVertical: this.props.isMenuVertical, isDirectionRightToLeft: this.props.isDirectionRightToLeft, className: "k-group k-menu-group k-reset", onMouseOver: this.props.onMouseOver, onMouseLeave: this.props.onMouseLeave, onMouseDown: this.props.onMouseDown, onBlur: this.props.onBlur, onFocus: this.props.onFocus, onClick: this.props.onClick, onOriginalItemNeeded: this.props.onOriginalItemNeeded })));
        }
        else {
            return null;
        }
    };
    MenuItemInternal.prototype.renderMenuIconIfApplicable = function () {
        return this.props.item.icon ?
            React.createElement("span", { className: "k-icon k-i-".concat(this.props.item.icon), role: "presentation", key: "0" }) : null;
    };
    MenuItemInternal.prototype.renderArrowIfApplicable = function () {
        return this.props.item.items.length > 0 ?
            (React.createElement(MenuItemArrow, { itemId: this.props.item.id, verticalMenu: this.props.isMenuVertical, dir: convertBoolDirectionToString(this.props.isDirectionRightToLeft), key: "2" })) : null;
    };
    Object.defineProperty(MenuItemInternal.prototype, "itemRender", {
        get: function () {
            return this.props.item.render || this.props.itemRender;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MenuItemInternal.prototype, "linkRender", {
        get: function () {
            return this.props.item.linkRender || this.props.linkRender;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MenuItemInternal.prototype, "contentRender", {
        get: function () {
            return this.props.item.contentParentItemId ? this.props.item.contentRender : null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MenuItemInternal.prototype, "Opened", {
        get: function () {
            var props = this.props;
            return props.item.items.length > 0 &&
                shouldOpenItem(props.item.id, props.lastItemIdToBeOpened) &&
                // HACK: Wait for the second render because otherwise the scenario of
                // popup inside popup throws an error (for example, hover of item with id '0_0').
                !this.isFirstRender;
        },
        enumerable: false,
        configurable: true
    });
    MenuItemInternal.prototype.getPopupClassName = function () {
        return classNames('k-menu-popup', { 'k-rtl': this.props.isDirectionRightToLeft });
    };
    MenuItemInternal.prototype.getMenuItemClassName = function (item) {
        return classNames('k-item', 'k-menu-item', {
            'k-first': isFirstItemFromSiblings(item.id),
            'k-last': item.isLastFromSiblings,
            'k-disabled': item.disabled
        }, item.cssClass);
    };
    return MenuItemInternal;
}(React.Component));
export { MenuItemInternal };
