"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import CommonEditablesPageTitleBar from "../../widgets/pageTitleBar/common_editables_page_title_bar";
import { EDITOR_TYPES } from "../../editor/editor_constants";
import Section from "../../editor/widgets/section";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import TrainingEditorAttribute, { TRAINING_EDITOR_TYPE } from "./training_editor_attribute";
import { TrainingBaseEditor } from "./training_base_editor";
import * as I18NWrapper from "../../i18n/i18n_wrapper";

import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import RelatedRetrainingPanel from "../widgets/related_retraining_panel";

const TRAINING_SIDE_PANEL_TABS_ENUM = {
  HISTORY: {
    title: "History",
    disabled: false,
    visible: true,
  },
  RETRAINING: {
    title: "Retraining",
    disabled: false,
    visible: true,
  },
};


// i18next-extract-mark-ns-start documents
/**
 * This class is responsible for showing the Individual Training Plan (ITP) record. This is one or more
 * documents/curriculum that's assigned to a single person for them to train on.
 */
class Training extends TrainingBaseEditor {
  constructor(props) {
    super(props, "ITP", "ITP", "Individual Training Plan", ["Document", "Curriculum"]);

    const {t} = props;

    this.setStateSafely({
      breadcrumb: {
        current: this.isAdd() ? t("New ITP") : "",
        parent: t("Training"),
        parentLink: "/training/list.html",
      },
      isLoadingTrainingInfo: true,
    });
  }

  componentDidMount() {
    document.title = "QbDVision Training";

    super.componentDidMount();
  }

  getInitialState(state = {}) {
    return super.getInitialState({...state, trainingRecords: []});
  }

  getTabName() {
    const {t} = this.props;
    return t("Individual Training Plans");
  }

  getSidePanelTabs() {
    return TRAINING_SIDE_PANEL_TABS_ENUM;
  }

  getDefaultSidePanelTab() {
    return TRAINING_SIDE_PANEL_TABS_ENUM.RETRAINING;
  }

  showSidebarOnApproved() {
    return true;
  }

  async handleReceiveDataFromServer(result) {
    this.setStateSafely({
      isLoading: true
    }, async() => {
      let {trainingRecords} = await this.trainingService.loadTrainingInfo(result.user.id);
      let data = ({...result, trainingRecords});

      // If the options are not yet in the state, we attempt to retrieve from the cache.
      let options = this.state.CurriculumInstances;
      if (!options) {
        this.curriculumCache = this.curriculumCache || new TypeaheadObjectCache("Curriculum");
        options = await this.curriculumCache.loadOptions(null, this.cacheRequestData).promise();
      }
      await this.updateCurriculaForDepartment(options, data.user);
      await super.handleReceiveDataFromServer(data);
    });
  }

  onDataReceivedFromServer() {
    super.onDataReceivedFromServer();
    let itp = {typeCode: "ITP", id: this.state.id, name: this.state.name};
    const {t} = this.props;
    this.setStateSafely({
      breadcrumb: {
        current: UIUtils.getRecordCustomLabelForDisplay(itp),
        parent: t("Training"),
        parentLink: "/training/list.html",
      }
    });
  }

  /**
   * Figure out if the current user can do said action with the given type and instance.
   * @param params.permissions {*[]} The permissions for the user
   * @param params.action {*} An action from CommonSecurity.Actions
   * @param params.type {*} An type from CommonSecurity.Types
   * @param params.instance {*} An instance of data.  While this isn't used yet, pass it in if you can so that supporting instance level security will be easier later on.
   * @param params.defaultVerificationResult {boolean} The result of the default permissions verification
   * @param [params.user] {*} The user being checked
   */
  handleCheckPermissions(params) {
    return this.trainingService.handleCheckPermissions(params);
  }

  /**
   * @inheritDoc
   */
  preprocessReceivedData(result) {
    // the version has an instance of CurriculumAssignmentLinkedVersion instead of CurriculumAssignment
    if (result.CurriculumAssignmentLinkedVersions && (!result.CurriculumAssignments || !result.CurriculumAssignments.length)) {
      result.CurriculumAssignments = result.CurriculumAssignmentLinkedVersions;
    }
    return result;
  }

  renderPageTitleBar() {
    const {t} = this.props;

    return (this.getEditorType() === EDITOR_TYPES.FULL_SCREEN)
      ? (<CommonEditablesPageTitleBar
          recordName={this.state.name || t("Individual Training Plans")}
          recordId={this.state.id}
          name={"Training"}
          typeCode={UIUtils.getTypeCodeForModelName(this.baseTypeName)}
          currentState={this.getCurrentState()}
        />
      )
      : "";
  }

  async handleTrainingTypeaheadResults(stateEntryName, typeCode, result) {
    result = result || [];
    await super.handleTrainingTypeaheadResults(stateEntryName, typeCode, result);

    if (typeCode === "CUR") {
      const {user} = this.state;
      const instances = result.filter(this.trainingService.isApprovedVersion);
      await this.updateCurriculaForDepartment(instances, user);
    }
  }

  /**
   * Updates the state with the curricula attached to this user's departments
   * @param curriculumInstances {*[]} All existing curricula
   * @param user {*} The user related to this ITP
   */
  updateCurriculaForDepartment(curriculumInstances, user) {
    // does nothing if both are not loaded
    if (!curriculumInstances || !user) {
      return;
    }

    let currentDepartment = user.department;
    let curricula = [];

    for (let curriculum of curriculumInstances) {
      curriculum.departments = (
        curriculum.departments && typeof curriculum.departments === "string"
          ? JSON.stringify(curriculum.departments)
          : curriculum.departments
      );

      if (curriculum.departments.includes(currentDepartment)) {
        const item = {
          ...curriculum,
          sortField: this.getCurriculaSortField(curriculum),
        };
        curricula.push(item);
      }
    }

    curricula.sort(UIUtils.sortBy("sortField"));

    let uniqueItems = new Map(curricula.map(item => ([item.CurriculumId || item.id, item])));

    curricula = [...uniqueItems.values()].sort(UIUtils.sortBy("sortField"));

    const newState = {
      CurriculaFromDepartmentInstances: curricula,
      isLoadingTrainingInfo: false,
    };

    return this.setStateSafelyAsync(newState);
  }

  getDepartmentCurricula() {
    const instances = this.state.CurriculaFromDepartmentInstances || [];
    return instances.map(item => ({CurriculumId: item.CurriculumId || item.id}));
  }

  handleRetrainingSuccess() {
    this.runPromise(async() => {
      try {
        UIUtils.setLoadingDisabled(false);
        UIUtils.showLoadingImage();
        let {trainingRecords} = await this.trainingService.loadTrainingInfo(this.state.userId);
        await this.setStateSafelyAsync({trainingRecords});
      } catch (e) {
        UIUtils.defaultFailFunction(e);
      } finally {
        UIUtils.hideLoadingImage();
        UIUtils.setLoadingDisabled(true);
      }
    });
  }

  isRetrainingEnabled() {
    return true;
  }

  hasRelatedItemsTab() {
    return true;
  }

  renderSidePanelWidget() {
    const {usersMap} = this.state;
    const documents = this.getTypeaheadDocuments();

    return (
      <RelatedRetrainingPanel
        id="retrainingRelatedData"
        parent={this}
        records={this.getRetrainingList()}
        documents={documents}
        users={usersMap}
        ignoreRowClick
      />
    );
  }

  getRetrainingList() {
    const {trainingRecords} = this.state;
    let result = [];
    if (trainingRecords && trainingRecords.length > 0) {
      result = trainingRecords.filter(record => record.retrainingCount > 0);
    }
    return result;
  }

  renderAttributes() {
    const {
      CurriculaFromDepartmentInstances,
      trainingRecords,
      CurriculumAssignments,
      Documents,
      usersMap,
      userId,
      isLoading,
      isLoadingTypeahead,
      isLoadingTrainingInfo,
    } = this.state;

    const {t} = this.props;

    const user = usersMap.get(userId);
    const documents = this.getTypeaheadDocuments();
    const curricula = this.getTypeaheadCurricula();

    const hideDetails = this.getEditorType() !== EDITOR_TYPES.FULL_SCREEN;
    const allowRetraining = this.isRetrainingEnabled();
    const assignedDepartmentCurricula = this.getDepartmentCurricula();

    return (
      <Fragment>
        <Section parent={this}
                 header={this.getGeneralHeader()}
                 collapsible={false}
        >
          <div className="row">
            <TrainingEditorAttribute
              id={"documents"}
              name={"Document"}
              type={TRAINING_EDITOR_TYPE.DOCUMENT}
              displayName={t("Documents")}
              typeaheadItems={documents}
              allDocuments={documents}
              allTrainingRecords={trainingRecords}
              assignedDocuments={Documents}
              records={trainingRecords.filter(item => item.isFromITP)}
              parent={this}
              allowRetraining={allowRetraining}
              isView={this.isView()}
              hideDetails={hideDetails}
              isDiffingVersions={this.isDiffingVersions()}
              user={user}
              collapsed
              onRetrainedSuccess={this.handleRetrainingSuccess}
              onCheckPermissions={this.handleCheckPermissions}
              isLoading={isLoading || isLoadingTypeahead || isLoadingTrainingInfo}
            />
          </div>
          <div className="row">
            <TrainingEditorAttribute
              id={"curricula"}
              name={"Curriculum"}
              type={TRAINING_EDITOR_TYPE.CURRICULUM}
              displayName={t("Curricula")}
              typeaheadItems={curricula}
              allCurricula={curricula}
              allDocuments={documents}
              allTrainingRecords={trainingRecords}
              assignedCurricula={CurriculumAssignments}
              parent={this}
              records={trainingRecords.filter(item => item.curricula.length > 0)}
              allowRetraining={allowRetraining}
              isView={this.isView()}
              hideDetails={hideDetails}
              isDiffingVersions={this.isDiffingVersions()}
              user={user}
              collapsed
              onRetrainedSuccess={this.handleRetrainingSuccess}
              onCheckPermissions={this.handleCheckPermissions}
              isLoading={isLoading || isLoadingTypeahead || isLoadingTrainingInfo}
            />
          </div>
        </Section>
        <Section
          id="curriculaFromDepartment"
          parent={this}
          showDocLinks={false}
          header={(
            <span>
              {t("Curricula from Department")}
              <InfoTooltip
                id="infoRefAndStandardsControl"
                verbiage={
                  <div>{t("Department-based training is assigned via each Curriculum record in the table below. These assignments are based on the user's current department in QbDVision. The user is already assigned training from these curricula. These assignments are not part of the user's individual training plan (this record) but are shown here for reference purposes.")}</div>}
              />
            </span>
          )}
        >
          <div className="row">
            <TrainingEditorAttribute
              name={"CurriculaFromDepartmentInstances"}
              id={"departmentCurricula"}
              type={TRAINING_EDITOR_TYPE.DEPARTMENT_CURRICULUM}
              displayName={t("Curricula from Department")}
              className={"col-sm-12 table-read-only"}
              typeaheadItems={curricula}
              items={CurriculaFromDepartmentInstances}
              allCurricula={curricula}
              allDocuments={documents}
              allTrainingRecords={trainingRecords}
              assignedCurricula={assignedDepartmentCurricula}
              parent={this}
              records={trainingRecords.filter(item => item.departmentCurricula.length > 0)}
              allowRetraining={allowRetraining}
              isView={this.isView()}
              hideDetails={hideDetails}
              isDiffingVersions={this.isDiffingVersions()}
              user={user}
              collapsed
              onRetrainedSuccess={this.handleRetrainingSuccess}
              onCheckPermissions={this.handleCheckPermissions}
              isLoading={isLoading || isLoadingTypeahead || isLoadingTrainingInfo}
            />
          </div>
        </Section>
        <Section
          id="references"
          parent={this}
          showDocLinks={true}
          addOptions={[
            {id: "references", label: "References"},
            {id: "standards", label: "Standards"},
            {id: "guidances", label: "Guidances"}]}
          header={
            <span>References & Standards
             <InfoTooltip
               id="infoRefAndStandardsControl"
               verbiage={
                 <div>{t("Use this section to link or upload any relevant references, standards, or guidelines supporting the training or its related parts.")}</div>
               }
             /></span>}
        />
      </Fragment>
    );
  }
}

export default I18NWrapper.wrap(Training, ["documents", "users"]);
// i18next-extract-mark-ns-stop documents
