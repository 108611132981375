"use strict";

import {
  generateDocumentsValue,
  generateRiskLinksValue,
  generateRiskValue, generateVersionValue,
} from "../../export/configurable_tables_export_value_generator";
import { RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import { FIELD_PROPS } from "../constants/configurable_tables_field_props_config";
import {
  ABOUT_SECTION,
  CRITICALITY_ASSESSMENT_SECTION, RISK_CONTROL_SECTION,
} from "../constants/configurable_tables_sections_config";
import { FILTER_TYPES } from "../../columnOperations/configurable_tables_column_operations_constants";
import {
  ABOUT_COL_SPAN,
  CRITICALITY_ASSESSMENT_COL_SPAN,
  RISK_CONTROL_COL_SPAN
} from "../constants/configurable_tables_col_spans_config";
import RecordFields from "../record_fields";
import {
  HIDDEN,
  SENDING_SITE_COMMON_COLUMNS_PROPS,
  SENDING_SITE_LABEL
} from "../constants/configurable_tables_columns_config";
import CommonEditables from "../../../../server/common/editables/common_editables";

/**
 * This class lists all Intermediate Attributes fields to be shown in export, column selection and tables.
 */
export default class IntermediateAttributeFields extends RecordFields {

  get title() {
    return "Intermediate Attributes";
  }

  get riskControlSectionFields() {
    let parentFields = super.riskControlSectionFields;

    const controlStrategyIndex = parentFields.findIndex(field => field.prop === FIELD_PROPS.CONTROL_STRATEGY
      && field.originatedFromSite === SENDING_SITE_LABEL);

    parentFields[controlStrategyIndex] = {
      title: "Control Strategy",
      prop: FIELD_PROPS.CONTROL_STRATEGY,
      colspan: RISK_CONTROL_COL_SPAN,
      section: RISK_CONTROL_SECTION,
      defaultValue: true,
      defaultTechTransferValue: false,
      defaultChangeCriteriaValue: true,
      filterType: FILTER_TYPES.COMBO_BOX,
      filterLogic: "contains",
      filterOptions: () => CommonEditables.INTERMEDIATE_ATTRIBUTE_CONTROL_STRATEGY_OPTIONS,
      ...SENDING_SITE_COMMON_COLUMNS_PROPS,
      tableValue: (columnGenerator) => columnGenerator.generateMultiSelectionColumn("Control Strategy", FIELD_PROPS.CONTROL_STRATEGY),
    };

    return parentFields;
  }

  get fields() {
    return super.filterTechTransferFields([
      // About
      {
        title: "Name",
        prop: FIELD_PROPS.NAME,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        tableValue: (columnGenerator) => columnGenerator.generateNameColumn(),
      },
      {
        title: "Version",
        prop: FIELD_PROPS.VERSION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: true,
        showColumnSelectionOn: HIDDEN,
        value: generateVersionValue,
        tableValue: (columnGenerator) => columnGenerator.generateVersionColumn("Version"),
      },
      {
        title: "Unit Operation",
        prop: FIELD_PROPS.UNIT_OPERATION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        sortByOrder: true,
        filterType: FILTER_TYPES.LOOKUP,
        value: (record) => record[FIELD_PROPS.UNIT_OPERATION_NAME],
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateParentColumn("Unit Operation", FIELD_PROPS.UNIT_OPERATION, process),
      },
      {
        title: "Step",
        prop: FIELD_PROPS.STEP,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        sortByOrder: true,
        filterType: FILTER_TYPES.LOOKUP,
        value: (record) => record[FIELD_PROPS.STEP_NAME],
        tableValue: (columnGenerator, process) =>
          columnGenerator.generateParentColumn("Step", FIELD_PROPS.STEP, process),
      },
      {
        title: "Type",
        prop: FIELD_PROPS.TYPE,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        filterType: FILTER_TYPES.COMBO_BOX,
      },
      {
        title: "Description",
        prop: FIELD_PROPS.DESCRIPTION,
        colspan: ABOUT_COL_SPAN,
        section: ABOUT_SECTION,
        defaultValue: false,
        tableValue: (columnGenerator) =>
          columnGenerator.generateTextAreaColumn("Description", FIELD_PROPS.DESCRIPTION),
      },
      // Criticality Assessment
      {
        title: "Risk Links",
        prop: FIELD_PROPS.RISK_LINKS,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: true,
        value: generateRiskLinksValue,
        tableValue: (columnGenerator) => columnGenerator.generateRiskLinksColumn(),
      },
      {
        title: "Criticality (Raw)",
        prop: FIELD_PROPS.MAX_CRITICALITY,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.MAX_CRITICALITY),
        value: generateRiskValue.bind(this, FIELD_PROPS.MAX_CRITICALITY, RISK_TYPE_ENUM.CRITICALITY),
        tableValue: (columnGenerator) =>
          columnGenerator.generateRiskColumn("Criticality (Raw)", FIELD_PROPS.MAX_CRITICALITY, RISK_TYPE_ENUM.CRITICALITY),
      },
      {
        title: "Criticality (%)",
        prop: FIELD_PROPS.MAX_CRITICALITY_PERCENTAGE,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        isRiskScaleField: true,
        filterLogic: "equals",
        filterType: FILTER_TYPES.COMBO_BOX,
        filterOptions: (records, columnOperationsAdapter) =>
          columnOperationsAdapter.getRiskFilterOptions(records, FIELD_PROPS.MAX_CRITICALITY_PERCENTAGE),
        value: generateRiskValue.bind(this, FIELD_PROPS.MAX_CRITICALITY_PERCENTAGE, RISK_TYPE_ENUM.CRITICALITY),
        tableValue: (columnGenerator) =>
          columnGenerator.generateRiskColumn("Criticality (%)", FIELD_PROPS.MAX_CRITICALITY_PERCENTAGE, RISK_TYPE_ENUM.CRITICALITY),
      },
      {
        title: "Recommended Actions",
        prop: FIELD_PROPS.RECOMMENDED_ACTIONS,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        tableValue: (columnGenerator) =>
          columnGenerator.generateTextColumn("Recommended Actions", FIELD_PROPS.RECOMMENDED_ACTIONS),
      },
      {
        title: "Source Documents",
        prop: FIELD_PROPS.CRITICALITY_ASSESSMENT_LINKS,
        colspan: CRITICALITY_ASSESSMENT_COL_SPAN,
        section: CRITICALITY_ASSESSMENT_SECTION,
        defaultValue: false,
        value: generateDocumentsValue,
        filterType: FILTER_TYPES.LINKS,
        tableValue: (columnGenerator) =>
          columnGenerator.generateDocumentsColumn("Source Documents", null,
            FIELD_PROPS.CRITICALITY_ASSESSMENT_LINKS),
      },
      ...this.acceptanceCriteriaSectionFields,
      ...this.processCapabilitySectionFields,
      ...this.riskControlSectionFields,
      ...this.referenceAndStandardsSectionFields,
    ]);
  }
}
