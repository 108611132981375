var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames, useDir, Keys, getTabIndex, createPropsContext, usePropsContext } from '@progress/kendo-react-common';
import { RatingItem } from './RatingItem';
import { ratingReducer, RATING_ACTION } from './rating-reducer';
import { isSelected, isHalf, calcIsFirstHalf, isCorrectValue, toRound, getRemainder } from './utils';
import { messages, ratingAriaLabel } from '../messages';
import { useLocalization } from '@progress/kendo-react-intl';
/**
 * @hidden
 */
var useRating = function (defaultValue, args, callback) {
    var _a = React.useState(defaultValue), state = _a[0], setState = _a[1];
    var handleDispatchAction = function (action) {
        var newState = ratingReducer(args.state || state, __assign(__assign({}, action), args));
        if (callback) {
            callback(newState, action.event);
        }
        setState(newState);
    };
    return [state, handleDispatchAction];
};
/**
 * Represents the PropsContext of the `Rating` component.
 * Used for global configuration of all `Rating` instances.
 *
 * For more information, refer to the [Inputs Props Context]({% slug props-context_inputs %}) article.
 */
export var RatingPropsContext = createPropsContext();
/**
 * Represents the [KendoReact Rating component]({% slug overview_rating %}).
 *
 * Accepts properties of type [RatingProps]({% slug api_inputs_ratingprops %}).
 * Obtaining the `ref` returns an object of type [RatingHandle]({% slug api_inputs_ratinghandle %}).
 */
export var Rating = React.forwardRef(function (directProps, ref) {
    var _a, _b, _c, _d;
    var props = usePropsContext(RatingPropsContext, directProps);
    var localization = useLocalization();
    var target = React.useRef(null);
    var ratingRef = React.useRef(null);
    var dir = useDir(ratingRef, props.dir);
    React.useImperativeHandle(target, function () { return ({
        element: ratingRef.current,
        props: props
    }); });
    React.useImperativeHandle(ref, function () { return target.current; });
    var handleChange = React.useCallback(function (newValue, event) {
        if (props.onChange && target.current) {
            props.onChange.call(undefined, {
                value: newValue,
                target: target.current,
                syntheticEvent: event
            });
        }
    }, [props.onChange]);
    var min = React.useMemo(function () { return props.min !== undefined ? props.min : defaultProps.min; }, [props.min]);
    var max = React.useMemo(function () { return props.max !== undefined ? props.max : defaultProps.max; }, [props.max]);
    var precision = React.useMemo(function () { return props.precision || defaultProps.precision; }, [props.precision]);
    var step = React.useMemo(function () { return props.step !== undefined ? props.step : defaultProps.step; }, [props.step]);
    var readonly = React.useMemo(function () { return props.readonly || defaultProps.readonly; }, [props.readonly]);
    var disabled = React.useMemo(function () { return props.disabled || defaultProps.disabled; }, [props.disabled]);
    var Item = React.useMemo(function () { return props.item || defaultProps.item; }, [props.item]);
    var _e = useRating(props.defaultValue || defaultProps.defaultValue, {
        state: props.value,
        min: min,
        max: max,
        step: precision === 'half' ? step / 2 : step
    }, handleChange), stateValue = _e[0], dispatchStateValue = _e[1];
    var _f = useRating(null, {
        state: props.value,
        min: min,
        max: max,
        step: precision === 'half' ? step / 2 : step,
        precision: precision
    }), stateHover = _f[0], dispatchStateHover = _f[1];
    var value = React.useMemo(function () { return props.value || stateValue; }, [props.value, stateValue]);
    var hoveredValue = React.useMemo(function () { return stateHover; }, [stateHover]);
    var selection = React.useMemo(function () { return props.selection || defaultProps.selection; }, [props.selection]);
    var base = React.useMemo(function () { return step / (precision === 'half' ? 2 : 1); }, [step, precision]);
    var handleItemClick = React.useCallback(function (event) {
        if (!event.target || !event.target.element || readonly || disabled) {
            return;
        }
        if (precision === 'half') {
            var rect = event.target.element.getBoundingClientRect();
            var isFirstHalf = calcIsFirstHalf(dir ? dir : 'ltr', rect, event.syntheticEvent.clientX);
            var payload = isFirstHalf ? toRound(event.target.value - (step / 2), base) : event.target.value;
            dispatchStateValue({ type: RATING_ACTION.select, payload: payload, event: event.syntheticEvent });
        }
        else {
            dispatchStateValue({ type: RATING_ACTION.select, payload: event.target.value, event: event.syntheticEvent });
        }
        if (props.onClick) {
            var ev = {
                value: value,
                target: target.current,
                syntheticEvent: event.syntheticEvent
            };
            props.onClick.call(undefined, ev);
        }
    }, [props.onClick, dispatchStateValue, value]);
    var handleKeyDown = React.useCallback(function (syntheticEvent) {
        if (readonly || disabled) {
            return;
        }
        switch (syntheticEvent.keyCode) {
            case Keys.right:
                syntheticEvent.preventDefault();
                dispatchStateValue({
                    type: dir === 'rtl' ? RATING_ACTION.decrease : RATING_ACTION.increase, event: syntheticEvent
                });
                break;
            case Keys.left:
                syntheticEvent.preventDefault();
                dispatchStateValue({
                    type: dir === 'rtl' ? RATING_ACTION.increase : RATING_ACTION.decrease, event: syntheticEvent
                });
                break;
            case Keys.home:
                syntheticEvent.preventDefault();
                dispatchStateValue({
                    type: dir === 'rtl' ? RATING_ACTION.min : RATING_ACTION.max, event: syntheticEvent
                });
                break;
            case Keys.end:
                syntheticEvent.preventDefault();
                dispatchStateValue({
                    type: dir === 'rtl' ? RATING_ACTION.max : RATING_ACTION.min, event: syntheticEvent
                });
                break;
            case Keys.esc:
                syntheticEvent.preventDefault();
                dispatchStateValue({
                    type: RATING_ACTION.deselect, event: syntheticEvent
                });
                break;
            default:
                break;
        }
        if (props.onKeyDown) {
            props.onKeyDown.call(undefined, {
                value: value,
                target: target.current,
                syntheticEvent: syntheticEvent
            });
        }
    }, [props.onKeyDown, dispatchStateValue, value]);
    var handleFocus = React.useCallback(function (syntheticEvent) {
        if (props.onFocus) {
            var ev = {
                target: target.current,
                syntheticEvent: syntheticEvent
            };
            props.onFocus.call(undefined, ev);
        }
    }, [props.onFocus]);
    var handleBlur = React.useCallback(function (syntheticEvent) {
        if (props.onBlur) {
            var ev = {
                target: target.current,
                syntheticEvent: syntheticEvent
            };
            props.onBlur.call(undefined, ev);
        }
    }, [props.onBlur]);
    var handleItemMouseMove = React.useCallback(function (event) {
        if (!event.target || !event.target.element) {
            return;
        }
        if (precision === 'half') {
            var rect = event.target.element.getBoundingClientRect();
            var isFirstHalf = calcIsFirstHalf(dir ? dir : 'ltr', rect, event.syntheticEvent.clientX);
            var payload = isFirstHalf ? event.target.value - (step / 2) : event.target.value;
            dispatchStateHover({ type: RATING_ACTION.select, payload: payload, event: event.syntheticEvent });
        }
        else {
            dispatchStateHover({ type: RATING_ACTION.select, payload: event.target.value, event: event.syntheticEvent });
        }
    }, [precision, step, dir]);
    var handleMouseLeave = React.useCallback(function (event) {
        dispatchStateHover({ type: RATING_ACTION.reset, event: event.syntheticEvent });
    }, []);
    var items = [];
    var remainder = getRemainder(toRound(max - min, base), step);
    for (var i = min; i <= max; i = toRound(i + step, base)) {
        var itemValue = toRound(i + remainder, base);
        var half = precision === 'half'
            ? isHalf(itemValue, hoveredValue !== null ? hoveredValue : (value !== null ? value : 0), step)
            : false;
        var selectedValue = isSelected(itemValue, value, step, selection);
        var selected = isSelected(itemValue, hoveredValue !== null ? hoveredValue : value, step, selection);
        var hovered = isSelected(itemValue, hoveredValue, step, selection);
        items.push(React.createElement(Item, { key: itemValue, value: itemValue, dir: dir, title: String(half ? toRound(itemValue - (step / 2), base) : itemValue), icon: props.icon, half: half, selected: selectedValue || selected, hovered: hovered, onClick: handleItemClick, onMouseMove: handleItemMouseMove, onMouseLeave: handleMouseLeave },
            half && (React.createElement("span", { className: 'k-rating-precision-complement', style: (_a = { width: '12px' }, _a[dir === 'rtl' ? 'right' : 'left'] = '50%', _a) },
                React.createElement("span", { className: classNames('k-icon', (_b = {
                            'k-i-star-outline': !props.icon
                        },
                        _b["".concat(props.icon, "-outline")] = props.icon,
                        _b)) }))),
            half && (React.createElement("span", { className: 'k-rating-precision-part', style: { width: '12px' } },
                React.createElement("span", { className: classNames('k-icon', (_c = {
                            'k-i-star': !props.icon
                        },
                        _c["".concat(props.icon)] = props.icon,
                        _c)) }))),
            half && (React.createElement("span", { style: { width: '24px', height: '24px', display: 'block' } })),
            !half && (React.createElement("span", { className: classNames('k-icon', (_d = {
                        'k-i-star': !props.icon && (hovered || (selected && !hovered)),
                        'k-i-star-outline': !props.icon && (!hovered)
                    },
                    _d["".concat(props.icon)] = props.icon && (hovered || (selected && !hovered)),
                    _d["".concat(props.icon, "-outline")] = props.icon && (!hovered),
                    _d)) }))));
    }
    return (React.createElement("span", { id: props.id, style: props.style, ref: ratingRef, role: "slider", dir: dir, tabIndex: getTabIndex(props.tabIndex, props.disabled, undefined), className: classNames('k-rating k-widget', {
            'k-rtl': dir === 'rtl',
            'k-readonly': readonly,
            'k-disabled': disabled
        }, props.className), onKeyDown: handleKeyDown, onFocus: handleFocus, onBlur: handleBlur, "aria-valuemin": min, "aria-valuemax": max, "aria-valuenow": value !== null ? value : undefined, "aria-disabled": disabled ? 'true' : undefined, "aria-label": localization.toLanguageString(ratingAriaLabel, messages[ratingAriaLabel]), "aria-labelledby": props.ariaLabelledBy, "aria-describedby": props.ariaDescribedBy },
        React.createElement("input", { id: 'rating', className: 'k-hidden', readOnly: readonly, disabled: disabled }),
        React.createElement("span", { className: 'k-rating-container' }, items),
        props.label &&
            React.createElement("span", { className: 'k-rating-label' }, props.label)));
});
var propTypes = {
    id: PropTypes.string,
    dir: PropTypes.oneOf(['ltr', 'rtl']),
    selection: PropTypes.oneOf(['continues', 'single']),
    precision: PropTypes.oneOf(['item', 'half']),
    value: function (props, propName, componentName) {
        if (props.value && props.min && props.max && props.step) {
            var correctValue = props.precision === 'half'
                ? isCorrectValue(props.min, props.max, props.step / 2, props.value)
                : isCorrectValue(props.min, props.max, props.step, props.value);
            if (!correctValue && props.precision === 'half') {
                return new Error("Invalid prop + ".concat(propName, " supplied to ").concat(componentName, ".\n                    The value of the { value } property is not correct, please check your values.\n                    "));
            }
        }
        return null;
    },
    defaultValue: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    step: function (props, propName, componentName) {
        if (props.step !== undefined && props.step <= 0) {
            return new Error("Invalid prop + ".concat(propName, " supplied to ").concat(componentName, ".\n                The value of the { step } property is cannot be equal or less than \"0\", please check your values.\n                "));
        }
        return null;
    },
    hovered: PropTypes.number,
    label: PropTypes.string,
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    half: PropTypes.bool,
    icon: PropTypes.string,
    ariaDescribedBy: PropTypes.string
};
var defaultProps = {
    dir: 'ltr',
    min: 1,
    max: 5,
    step: 1,
    item: RatingItem,
    defaultValue: null,
    readonly: false,
    disabled: false,
    selection: 'continues',
    precision: 'item'
};
Rating.displayName = 'KendoReactRating';
// TODO: delete casting when @types/react is updated!
Rating.propTypes = propTypes;
Rating.defaultProps = defaultProps;
