"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import moment from "moment";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import Section from "../../editor/widgets/section";
import TextAttribute from "../../editor/attributes/text_attribute";
import NumberAttribute from "../../editor/attributes/number_attribute";
import CheckboxAttribute from "../../editor/attributes/checkbox_attribute";
import ComboBoxAttribute from "../../editor/attributes/combo_box_attribute";
import TextAreaAttribute from "../../editor/attributes/text_area_attribute";
import TypeaheadAttribute from "../../editor/attributes/typeahead_attribute";
import DateAttribute, { SET_EXPLICITLY } from "../../editor/attributes/date_attribute";
import LinksAttribute from "../../editor/attributes/links_attribute";
import ReferenceDocuments from "@cherrycircle/fda-docs";
import TabNavBar from "../../widgets/bars/tab_nav_bar";
import HistoryContainer from "../../editor/history/history_container";
import RelatedRecordsPanel from "../../widgets/relatedRecords/related_records_panel";
import COAImportsTable from "../../widgets/tables/coa_imports_table";

// i18next-extract-mark-ns-start process_explorer
import * as CommonEditables from "../../../server/common/editables/common_editables";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseAttributeParent from "../attributes/base_attribute_parent";
import { EDITOR_TYPES } from "../../editor/editor_constants";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import { EMPTY_STRING } from "../../helpers/constants/constants";
import { LibraryStatusBar } from "../../library/widgets/library_status_bar";
import { LibraryFieldsContainer } from "../../library/widgets/library_fields_container";
import { FROM_LIBRARY_STATUS } from "../../library/library_constants";
import * as FailHandlers from "../../utils/fail_handlers";
import { RelatedPanelNotice } from "../../editor/widgets/related_panel_notice";

/**
 * This is the class that renders the Add/Edit material editor in the process explorer.
 */
class Material extends BaseAttributeParent {
  constructor(props) {
    super(props, "material", "Material", "Material (MT)");

    // Bind stuff
    this.getHTMLURLPrefix = Material.getHTMLURLPrefix;

    new TypeaheadObjectCache("Project").loadOptions(this.handleProjectResultsFromServer);
    this.checkLibraryMaterial();
    this.setStateSafely({
      isUseFromLibraryDisabled: this.isMaterialShared(),
    });
  }

  componentDidMount() {
    document.title = "QbDVision Material";

    super.componentDidMount();
  }
  componentDidUpdate(prevProps, prevState) {
    const isUseFromLibraryDisabled = this.isMaterialShared();
    if (prevState.isUseFromLibraryDisabled !== isUseFromLibraryDisabled) {
      this.setStateSafely({
        isUseFromLibraryDisabled: isUseFromLibraryDisabled,
      });
    }
  }

  isMaterialShared() {
    return this.state.UnitOperations?.length > 1 || this.state.Steps?.length > 1;
  }

  getAttributeNames() {
    return ["MaterialAttribute", "ProcessParameter"];
  }

  getAttributeParentId() {
    return "MaterialId";
  }

  static getHTMLURLPrefix() {
    return "/processExplorer/materials";
  }

  /**
   * Override this to return additional url parameters for the requests
   * @returns {loadFullHistory: boolean}
   */
  getAdditionalRequestData() {
    return {
      loadFullHistory: true,
    };
  }

  cleanRecordBeforeSentToServer(record) {
    const {
      fromLibraryStatus,
      LibraryMaterialId,
      UnitOperations = [],
      Steps = [],
      UnitOperationId,
      StepId,
    } = this.state;
    const isLinkedToLibrary =
      this.isAdd() || [FROM_LIBRARY_STATUS.LINKED, FROM_LIBRARY_STATUS.SYNCED].includes(fromLibraryStatus);
    const newRecord = UIUtils.deepClone(record);

    if (isLinkedToLibrary) {
      if (this.isAdd()) {
        newRecord.fromLibraryStatus = LibraryMaterialId ? FROM_LIBRARY_STATUS.LINKED : FROM_LIBRARY_STATUS.PROJECT_ONLY;
      } else {
        newRecord.fromLibraryStatus = fromLibraryStatus;
      }

      if (UnitOperationId) {
        const unitOperation = UnitOperations.find(uo => uo.id === UnitOperationId);
        newRecord.UnitOperations = unitOperation ? UnitOperations : [{id: UnitOperationId}];
      }

      if (StepId) {
        const step = Steps.find(step => step.id === StepId);
        newRecord.Steps = step ? Steps : [{id: StepId}];
      }
    }

    return super.cleanRecordBeforeSentToServer(newRecord);
  }


  getURLToLoadData(shouldShowApproved) {
    return `${super.getURLToLoadData(shouldShowApproved)}&includeChildRecords=true&includeCOAImports=true`;
  }

  getInfoTooltip() {
    return (
      <InfoTooltip id="infoMT"
                   fdaGuidanceURL={ReferenceDocuments.Q8_GUIDELINE}
                   fdaGuidancePage={8}
                   fdaGuidanceOffset={0}
                   verbiage={
                     <div>
                       The physicochemical and biological properties of the drug substance that can influence the
                       performance of the drug product and its manufacturability, or were specifically designed into the
                       drug substance (e.g., solid state properties), should be identified and discussed. Examples of
                       physicochemical and biological properties that should be examined, as appropriate, include
                       solubility, water content, particle size, crystal properties, biological activity, and
                       permeability.
                       These properties could be interrelated and, when appropriate, should be considered in
                       combination.
                     </div>
                   }
      />
    );
  }

  getTabName() {
    return "Process Explorer";
  }

  onDataReceivedFromServer() {
    super.onDataReceivedFromServer();
    if (this.state.expirationDate) {
      this.handleChangeValue("expirationDate" + SET_EXPLICITLY, true);
    }
    this.setStateSafely({originalUOs: UIUtils.deepClone(this.state.UnitOperations)});
    this.handleTypeaheadLoaded();
  }

  handleSaveResults(result) {
    result.isUseFromLibraryDisabled = result.UnitOperations?.length > 1 || result.Steps?.length > 1;
    super.handleSaveResults(result);
  }

  handleProjectResultsFromServer() {
    if (this.isAdd()) {
      this.initializeValue("category", "Drug Substance (API)");
    }
  }

  checkLibraryMaterial() {
    this.setStateSafely({
      hasLibraryMaterial: true
    });

    UIUtils.secureAjaxGET(
      `materialLibrary/hasData`,
      {},
      true,
      FailHandlers.defaultStopLoadingFailFunction.bind(this)
    ).then(results => {
      this.setStateSafely(results);
    });
  }

  handleChangeValue(attributeName, attributeValue, callback, attributeType) {
    super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
    const {fromLibraryStatus} = this.state;
    const isSynced = fromLibraryStatus && fromLibraryStatus !== FROM_LIBRARY_STATUS.PROJECT_ONLY;
    if (attributeName === "SupplierId") {
      if (this.isSupplierNotQualified(attributeValue)) {
        this.handleChangeValue("materialQualified", "No", callback);
      }
      if (!this.showSupplierQualificationError("materialQualified", "No", attributeValue)) {
        this.materialQualifiedField.clearValidationErrors();
      }
    } else if (attributeName === "effectiveDate") {
      if (!this.state["expirationDate" + SET_EXPLICITLY]) {
        let attributeDate = attributeValue ? moment(attributeValue, UIUtils.DATE_FORMAT_FOR_STORAGE) : attributeValue;
        this.expirationDateRef.setDate(attributeDate ? attributeDate.add(1, "year") : attributeDate);
      }
    } else if (attributeName === "UnitOperationId" && isSynced) {
      this.initializeValue("UnitOperations", [{id: attributeValue}]);
    } else if (attributeName === "StepId" && isSynced) {
      this.initializeValue("Steps", [{id: attributeValue}]);
    }
  }

  handleValidateMaterialQualificationStatus() {
    return this.showSupplierQualificationError("materialQualified")
      ? `The Material/Part Qualified field cannot be set to Yes. The linked Supplier's "Qualification Status" needs to be "Qualified" or "Re-qualified" to set this value.`
      : EMPTY_STRING;
  }

  filterSteps(option) {
    const {UnitOperations} = this.state;
    const uoIds = UnitOperations ? UnitOperations.map(uo => uo.id) : [];
    return uoIds.includes(option.UnitOperationId);
  }

  renderAttributes() {
    const generalHeaderClass = "material-general-header";
    const {fromLibraryStatus} = this.state;
    const showSectionDocLinksInViewMode = fromLibraryStatus === FROM_LIBRARY_STATUS.SYNCED;
    return (
      <div>
        {(!this.isAdd() ?
          (<LibraryStatusBar record={this.state}
                             isDiffingVersions={this.isDiffingVersions}
                             getOlderVersion={this.getOlderVersion}
          />) : EMPTY_STRING)}
        <Section parent={this}
                 header={this.getGeneralHeader(generalHeaderClass)}
                 collapsible={false}
                 showDocLinks={true}
        >
          <LibraryFieldsContainer section="General" fromLibraryStatus={fromLibraryStatus}>
            <div className="row">
              <TextAttribute name="name"
                             parent={this}
              />
              <ComboBoxAttribute name="category"
                                 options={CommonEditables.MATERIAL_CATEGORIES}
                                 tooltipText={(
                                   <div>
                                     <p>
                                       {`This field tracks the type of raw or process material.  Note: the "Source Raw Material" 
                                     and "Biologically-Derived" categories are legacy selections that will be phased out over time. 
                                     If you have selected this type for any previously entered materials, please change them to one of the other listed selections.`}
                                     </p>
                                   </div>
                                 )}
                                 parent={this}
                                 isLoading={this.state.isLoading}
                                 parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                 parentId={this.state.id}
              />
            </div>
            <div className="row ">
              <CheckboxAttribute name="gmp"
                                 displayName="GMP"
                                 parent={this}
                                 tooltipText="Indicates if the material has been manufactured, collected, or processed according to GMP regulations."
              />
            </div>
            <div className="row">
              <TextAreaAttribute name={(!fromLibraryStatus || fromLibraryStatus === FROM_LIBRARY_STATUS.PROJECT_ONLY) ? "description" : "libraryDescription"}
                                 className="col-sm-12"
                                 parent={this}
              />
            </div>
          </LibraryFieldsContainer>
          <div className="row">
            <TextAttribute name="function"
                           tooltipText="Describe the function of the component in the category.  For example, if the component is the drug substance in the drug product, then it will function as an active pharmaceutical ingredient.  If the component is a piston in a syringe, it functions as a container seal."
                           parent={this}
            />
          </div>
          <div className="row">
            <ComboBoxAttribute name="use"
                               options={["DS/DP",
                                 "Process"]}
                               tooltipText="Specifies if the Material is related to the Drug Substance or Drug Product (DS/DP) or the Process. An example of a Process Material is a cleaning agent."
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
            <TypeaheadAttribute name="process"
                                default={this.getProcessId()}
                                typeaheadType="Process"
                                target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
                                instructions={`This can only be modified on add.`}
                                disabled={!this.isAdd()}
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
                                projectId={this.getProjectId()}
                                processId={this.getProcessId()}
            />
          </div>
          <div className="row">
            {this.renderUnitOperationAttribute()}
            {this.renderStepAttribute()}
          </div>
          <div className="row">
            {
              (fromLibraryStatus && fromLibraryStatus !== FROM_LIBRARY_STATUS.PROJECT_ONLY)
              &&
              <TextAreaAttribute name="description"
                                 className="col-sm-12"
                                 parent={this}
              />}
          </div>
        </Section>
        <Section id="formulationQuantity"
                 parent={this}
                 showDocLinks={true}
                 header="Formulation Quantity"
        >
          <div className="row">
            <TextAttribute name="descriptiveUnitAbsolute"
                           displayName="Descriptive Unit (Absolute)"
                           tooltipText="The descriptive unit is the unit of measure for quantity being tracked (e.g. mg, mg/ml, etc.)"
                           parent={this}
            />
            <NumberAttribute name="quantityAbsolute"
                             displayName="Quantity per Batch (Absolute)"
                             tooltipText="Absolute quantity of material per batch by weight or volume"
                             parent={this}
            />
            <NumberAttribute name="quantityRelative"
                             displayName="Quantity (Relative %)"
                             tooltipText="Relative quantity of material as a percentage of weight by total weight or volume by total volume"
                             min={0}
                             max={100}
                             parent={this}
            />
            <NumberAttribute name="quantityPerDose"
                             displayName="Quantity per Dose (Absolute)"
                             tooltipText="Absolute quantity of material per dose by weight or volume"
                             min={0}
                             parent={this}
            />
          </div>
        </Section>
        <LibraryFieldsContainer section="Qualification" fromLibraryStatus={fromLibraryStatus}>
          <Section id="qualification"
                   parent={this}
                   showDocLinks={true}
                   header="Qualification"
                   showDocLinksInViewMode={showSectionDocLinksInViewMode}
          >
            <div className="row">
              <TypeaheadAttribute name="supplier"
                                  typeaheadType="Supplier"
                                  parent={this}
                                  isLoading={this.state.isLoading}
                                  parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                  parentId={this.state.id}
              />
              <ComboBoxAttribute name="materialQualified"
                                 ref={ref => this.materialQualifiedField = ref}
                                 displayName="Material/Part Qualified"
                                 options={["Yes", "No", "Pending"]}
                                 default="No"
                                 tooltipText="Material qualification should be in accordance with your corporate SOPs. The Supplier should be qualified prior to qualifying the material."
                                 instructions={"To set a value of Yes, the \"Supplier\" above must have its \"Qualification Status\" field set to either \"Qualified\" or \"Re-qualified.\""}
                                 onValidate={this.handleValidateMaterialQualificationStatus}
                                 parent={this}
                                 isLoading={this.state.isLoading}
                                 parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                 parentId={this.state.id}
              />
            </div>
            <div className="row">
              <TextAttribute name="partNumber"
                             displayName="Supplier Part Number"
                             parent={this}
              />
              <TextAttribute name="internalPartNumber"
                             parent={this}
              />
            </div>
            <div className="row">
              <DateAttribute name="effectiveDate"
                             instructions={`To edit this field, the "Material Qualified" field above must be set to "Yes"`}
                             disabled={this.state.materialQualified !== "Yes"}
                             tooltipText="The date the material qualification goes into effect."
                             parent={this}
              />
              <DateAttribute name="expirationDate"
                             ref={dateAttribute => this.expirationDateRef = dateAttribute}
                             instructions={`To edit this field, the "Material Qualified" field above must be set to "Yes"`}
                             disabled={this.state.materialQualified !== "Yes"}
                             tooltipText="The date the material qualification expires."
                             parent={this}
              />
            </div>
          </Section>
        </LibraryFieldsContainer>
        <LibraryFieldsContainer section="Regulatory" fromLibraryStatus={fromLibraryStatus}>
          <Section id="regulatory"
                   parent={this}
                   showDocLinks={true}
                   header="Regulatory"
                   showDocLinksInViewMode={showSectionDocLinksInViewMode}
          >
            <div className="row">
              <ComboBoxAttribute name="regulatoryFiling"
                                 options={CommonEditables.MATERIAL_REGULATORY_FILING}
                                 default="Not Applicable"
                                 tooltipText={(
                                   <div>
                                     <b>A Drug Master File (DMF)</b> is a submission to the Food and Drug Administration
                                     (FDA) that may be used to provide confidential detailed information about facilities,
                                     processes, or articles used in the manufacturing, processing, packaging, and storing
                                     of one or more human drugs.<br />
                                     <br />
                                     <a target="_blank"
                                        rel="noopener noreferrer"
                                        className="label-tooltip-guidance"
                                        href="https://www.fda.gov/drugs/forms-submission-requirements/drug-master-files-dmfs"
                                     >
                                       Official FDA Guidance
                                     </a><br />
                                     <br />
                                     A <b>Medical Device Master File (MAF)</b> is a submission to the U.S. FDA that may be
                                     used in support of premarket submissions to provide confidential detailed information
                                     about establishments, processes, or articles used in the manufacturing, processing,
                                     packaging, and storing of one or more medical devices. <br />
                                     <br />
                                     A <b>Biologics Master File (BMF)</b> is a submission to the Food and Drug
                                     Administration (FDA) specific to biologics drugs that may be used to provide
                                     confidential detailed information about facilities, processes, or articles used in
                                     the
                                     manufacturing, processing, packaging, and storing of one or more of these human
                                     drugs.
                                   </div>
                                 )}
                                 parent={this}
                                 isLoading={this.state.isLoading}
                                 parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                 parentId={this.state.id}
              />
              <TextAttribute name="referenceNumber"
                             tooltipText="This reference number identifies the supplier filing with the FDA which can be referenced with a Letter of Authorization from the supplier"
                             parent={this}
              />
              <ComboBoxAttribute name="authorizationLetter"
                                 displayName="Letter of Authorization"
                                 options={["Yes", "No", "Not Applicable"]}
                                 default="Not Applicable"
                                 tooltipText="Letter of authorization means a written statement by the holder or designated agent or representative permitting FDA to refer to information in the DMF, MAF, or BMF in support of another person's submission."
                                 parent={this}
                                 isLoading={this.state.isLoading}
                                 parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                 parentId={this.state.id}
              />
            </div>
          </Section>
        </LibraryFieldsContainer>
        <LibraryFieldsContainer section="Properties" fromLibraryStatus={fromLibraryStatus}>
          <Section id="properties"
                   parent={this}
                   header="Properties"
                   showDocLinks={true}
                   showDocLinksInViewMode={showSectionDocLinksInViewMode}
          >
            <div className="row">
              <ComboBoxAttribute name="drugSubstanceType"
                                 options={[...[EMPTY_STRING],
                                   ...CommonEditables.MATERIAL_DRUG_SUBSTANCE_TYPE]}
                                 instructions={`To edit this field, the "Category" field above must be set to "Drug Substance (API)"`}
                                 disabled={this.state.category !== "Drug Substance (API)"}
                                 parent={this}
                                 isLoading={this.state.isLoading}
                                 parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                 parentId={this.state.id}
              />
              <TypeaheadAttribute name="form"
                                  options={CommonEditables.MATERIAL_FORM_OPTIONS}
                                  parent={this}
                                  isLoading={this.state.isLoading}
                                  parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                  parentId={this.state.id}
              />
            </div>
            <div className="row">
              <TextAttribute name="empiricalFormula"
                             parent={this}
              />
              <div className="row col-sm-6">
                <NumberAttribute name="density"
                                 displayName="Density (g/ml)"
                                 instructions={`To edit this field, the “Form” field above cannot be set to “Solid”`}
                                 min={0.000001}
                                 disabled={this.state.form === "Solid"}
                                 parent={this}
                />
                <TextAttribute name="densityConditions"
                               instructions={`To edit this field, the “Form” field above cannot be set to “Solid”`}
                               disabled={this.state.form === "Solid"}
                               parent={this}
                />
              </div>
            </div>
            <div className="row">
              <TextAttribute name="chemicalStructure"
                             tooltipText="Can be a text description of the chemical structure.  Diagram of structure can be added as an attachment."
                             parent={this}
              />

              <NumberAttribute name="molecularWeight"
                               displayName="Molecular Weight (g/mol)"
                               min={0.000001}
                               parent={this}
              />
            </div>
            <div className="row">
              <TextAttribute name="chemicalNameCAS"
                             displayName="Chemical Name (CAS)"
                             tooltipText={
                               <p>Name assigned to chemical by Chemical Abstracts Service&nbsp;
                                 <a target="_blank"
                                    rel="noopener noreferrer"
                                    className="tooltip-link"
                                    href="https://commonchemistry.cas.org"
                                 >
                                   (CAS)
                                 </a>
                               </p>
                             }
                             parent={this}
              />
              <TextAttribute name="chemicalNameIUPAC"
                             displayName="Chemical Name (IUPAC)"
                             tooltipText={
                               <p>Name assigned to chemical by International Union of Pure and Applied Chemistry&nbsp;
                                 <a target="_blank"
                                    rel="noopener noreferrer"
                                    className="tooltip-link"
                                    href="https://iupac.org/"
                                 >
                                   (IUPAC)
                                 </a>
                               </p>
                             }
                             parent={this}
              />
            </div>
            <div className="row">
              <TextAttribute name="otherNames"
                             tooltipText="Any other name that might be used to identify the chemical substance"
                             parent={this}
              />
              <TextAttribute name="innUsan"
                             displayName="INN/USAN"
                             tooltipText={
                               <p>An International Nonproprietary Name (INN) is an official generic and nonproprietary name given to a pharmaceutical drug or active ingredient. The USAN (United States Adopted Name) is a national naming scheme is almost always identical to the&nbsp;
                                 <a target="_blank"
                                    rel="noopener noreferrer"
                                    className="tooltip-link"
                                    href="http://www.who.int/medicines/services/inn/innquidance/en/"
                                 >
                                   INN.
                                 </a>
                               </p>
                             }
                             parent={this}
              />
            </div>
            <div className="row">
              <TextAttribute name="casRegistryNumber"
                             displayName="CAS Registry Number"
                             tooltipText={
                               <p>The&nbsp;
                                 <a target="_blank"
                                    rel="noopener noreferrer"
                                    className="tooltip-link"
                                    href="https://commonchemistry.cas.org"
                                 >
                                   CAS Registry Number
                                 </a>
                                 &nbsp;is a unique numeric identifier for a single chemical substance.
                               </p>
                             }
                             parent={this}
              />
              <TypeaheadAttribute name="compendialStandard"
                                  options={CommonEditables.COMPENDIAL_STANDARD}
                                  parent={this}
                                  isLoading={this.state.isLoading}
                                  parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                  parentId={this.state.id}
                                  multiple={true}
                                  instructions={`To edit this field, the "Category" field above must be set to "Drug Substance (API)" or "Excipient"`}
                                  disabled={this.state.category !== "Drug Substance (API)" && this.state.category !== "Excipient"}
                                  tooltipText={(<div>
                                      <p>
                                        Compendial Name: The name of an article for which a monograph is provided in an
                                        official compendia (e.g., United States Pharmacopeia, National Formulary, or
                                        Homeopathic Pharmacopeia) recognized by regulatory agencies.
                                      </p>
                                      <p>
                                        An article may be an official substance or official preparation. Examples of
                                        Compendial Standards include:
                                        <ul>
                                          <li>
                                            <b>USP</b> - US Pharmacopeia
                                          </li>
                                          <li>
                                            <b>NF</b> - National Formulary
                                          </li>
                                          <li>
                                            <b>BP</b> - British Pharmacopeia
                                          </li>
                                          <li>
                                            <b>EP</b> - European Pharmacopeia
                                          </li>
                                          <li>
                                            <b>JP</b> - Japanese Pharmacopeia
                                          </li>
                                          <li>
                                            <b>CP</b> - Chinese Pharmacopeia
                                          </li>
                                        </ul>
                                      </p>
                                    </div>
                                  )}
              />
            </div>
            <div className="row">
              <CheckboxAttribute name="certificateOfAnalysis"
                                 displayName="COA/COC"
                                 tooltipText="A Certificate of Analysis (COA) or Conformance (COC) is a document issued by Quality Assurance that confirms that a regulated product meets its product specification. They commonly contain the actual results obtained from testing performed as part of quality control of an individual batch of a product."
                                 parent={this}
              />
            </div>
          </Section>
        </LibraryFieldsContainer>
        {this.renderTechTransferAssessmentSection()}
        {this.renderTagsSection()}
        <LibraryFieldsContainer section="References" fromLibraryStatus={fromLibraryStatus}>
          <Section id="references"
                   parent={this}
                   showDocLinks={true}
                   addOptions={[
                     {id: "references", label: "References"},
                     {id: "standards", label: "Standards"},
                     {id: "guidances", label: "Guidances"}]}
                   header={<span>
                   References & Standards
                   <InfoTooltip id="infoRefAndStandardsControl"
                                verbiage={
                                  <div>
                                    References, standards, safety data sheets, etc. for highly characterized specimens
                                    of drug substances, excipients, food ingredients, impurities, degradation products,
                                    dietary supplements, compendial reagents, tests, assay, and performance calibrators.
                                    (e.g. USP, BP, EP, JP, ISO, etc.)
                                  </div>}
                   />
                   </span>}
                   showDocLinksInViewMode={showSectionDocLinksInViewMode}
          >
          </Section>
        </LibraryFieldsContainer>
      </div>
    );
  }

  hasRelatedItemsTab() {
    return true;
  }

  renderSidePanel(versions) {

    const {
      selectedTab,
      sidePanelTabs,
      clonedFrom,
      showMajorVersionsOnly,
      childInstances,
      coaImports,
    } = this.state;

    const materialAttributes = (childInstances || [])
      .filter(childInstance => childInstance.typeCode === "MA");

    return (
      <Fragment>
        <TabNavBar selected={selectedTab}
                   className="related-tab-nav-margin"
                   onTabChanged={this.handleTabChanged}
                   tabs={UIUtils.deepClone(sidePanelTabs)}
                   parent={this}
                   parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                   parentId={this.state.id}
        />
        {selectedTab?.title === sidePanelTabs.HISTORY.title ? (
          <HistoryContainer parent={this}
                            isLoading={this.isLoading()}
                            versions={versions}
                            showWithRelatedRecords={true}
                            baseTypeName={this.baseTypeName}
                            clonedFrom={clonedFrom}
                            showMajorVersionsOnly={showMajorVersionsOnly}
          />
        ) : (
          <div>
            <RelatedPanelNotice isApproved={this.isApproved()} />
            <RelatedRecordsPanel id="materialRelatedRecords"
                                 header="Material Attributes"
                                 parentModelName="Material"
                                 modelName="MaterialAttribute"
                                 projectId={this.getProjectId()}
                                 processId={this.getProcessId()}
                                 parent={this}
                                 parentId={this.state.id}
                                 isLoading={this.state.isLoading}
                                 records={materialAttributes || []}
            />
            {!this.state.hasLibraryMaterial ?
              <COAImportsTable id="coaImportRecords"
                               header="Imported CoAs"
                               parentModelName="Material"
                               modelName="MaterialAttribute"
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentId={this.state.id}
                               projectId={this.getProjectId()}
                               processId={this.getProcessId()}
                               records={coaImports || []}
                               hasMaterialAttributes={(materialAttributes || []).length > 0}
              /> : ""}
          </div>
        )}
      </Fragment>
    );
  }

  /**
   * Library synced/linked materials can not be shared.
   * To get the assigned UnitOperation, we can safely access the first object from the UnitOperations list.
   */
  getUnitOperationId() {
    const {fromLibraryStatus, UnitOperations = []} = this.state;
    if (fromLibraryStatus && fromLibraryStatus !== FROM_LIBRARY_STATUS.PROJECT_ONLY) {
      const [unitOperation] = UnitOperations;
      return unitOperation?.id;
    }
  }

  getStepId() {
    const {fromLibraryStatus, Steps = []} = this.state;
    if (fromLibraryStatus && fromLibraryStatus !== FROM_LIBRARY_STATUS.PROJECT_ONLY) {
      const [step] = Steps;
      return step?.id;
    }
  }

  renderUnitOperationAttribute() {
    const {fromLibraryStatus} = this.state;
    const isFullScreen = this.getEditorType() === EDITOR_TYPES.FULL_SCREEN;
    const target = isFullScreen ? null : "_blank";

    return (
      <>
        {!fromLibraryStatus || fromLibraryStatus === FROM_LIBRARY_STATUS.PROJECT_ONLY ? (
          <LinksAttribute
            name="UnitOperation"
            displayName="Unit Operations"
            onValidate={this.handleUOVerification}
            target={target}
            parent={this}
            parentId={this.state.id}
            isLoading={this.state.isLoading}
            projectId={this.getProjectId()}
            processId={this.getProcessId()}
          />
        ) : (
          <TypeaheadAttribute
            name="unitOperation"
            typeaheadType="UnitOperation"
            default={this.getUnitOperationId()}
            onValidate={this.handleUOVerification}
            target={target}
            parent={this}
            isLoading={this.state.isLoading}
            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
            parentId={this.state.id}
            projectId={this.getProjectId()}
            processId={this.getProcessId()}
          />
        )}
      </>
    );
  }

  renderStepAttribute() {
    const {fromLibraryStatus} = this.state;
    const isFullScreen = this.getEditorType() === EDITOR_TYPES.FULL_SCREEN;
    const target = isFullScreen ? null : "_blank";

    return (
      <>
        {!fromLibraryStatus || fromLibraryStatus === FROM_LIBRARY_STATUS.PROJECT_ONLY ? (
          <LinksAttribute
            name="Step"
            displayName="Steps"
            onValidate={this.handleStepVerification}
            target={target}
            filter={this.filterSteps}
            parent={this}
            parentId={this.state.id}
            isLoading={this.state.isLoading}
            projectId={this.getProjectId()}
            processId={this.getProcessId()}
            relatedRecord={this.state.UnitOperations}
          />
        ) : (
          <TypeaheadAttribute
            name="step"
            typeaheadType="Step"
            target={this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? null : "_blank"}
            default={this.getStepId()}
            onValidate={this.handleStepVerification}
            filter={this.filterSteps}
            parent={this}
            isLoading={this.state.isLoading}
            parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
            parentId={this.state.id}
            relatedRecordId={this.state.UnitOperations}
          />
        )}
      </>
    );
  }
}

export default I18NWrapper.wrap(Material, ["process_explorer", "editor"]);
// i18next-extract-mark-ns-stop process_explorer
