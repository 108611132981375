"use strict";

import React from "react";
import BasePage from "./base_page";

/**
 * This is the base class for every page that extends to the full size of the screen.
 *
 * You'll also want to add class="full-screen-display" in your route in index.jsx.
 */
export default class BaseFullScreenPage extends BasePage {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    /**
     *  Set the Initial Containing Block (ICB) to fixed position and 100% width and height to work around the issue for
     *  mobile browsers as described in: https://developers.google.com/web/updates/2016/12/url-bar-resizing.
     *
     *  This fixes the full screen display on mobile devices on Android and IOS.
     */
    let html = $("html");
    html.css("position", "fixed");
    html.css("width", "100%");
    html.css("height", "100%");

    let body = $("body");
    body.css("position", "relative");
    body.css("width", "100%");
    body.css("height", "100%");

    //root div used by React to render everything, we need to add full-screen-display to it in case of full screen page
    $("#root").addClass("full-screen-display");

  }
}
