"use strict";

const {CommonAggregateError} = require("../common/generic/common_aggregate_error");
const {Log, LOG_GROUP} = require("../common/logger/common_log");

const Logger = Log.group(LOG_GROUP.Framework, "TaskExecutor");

/**
 * Executes an array of promises using different strategies.
 */
class TaskExecutor {
  /**
   * Executes the specified array of tasks (functions that return promises) in parallel.
   * If a number of maximum concurrent tasks is specified, throttles the execution so that there are
   * no more than the maximum number set.
   * @param tasks {(function(): Promise<*>)[]} The promises to be executed.
   * @param [maxConcurrentTasks] {number} The maximum number of concurrent tasks.
   */
  async parallel(tasks, maxConcurrentTasks = Number.MAX_SAFE_INTEGER) {
    let errors = [];
    let result = [];

    const originalTasksLength = tasks.length;
    let currentTaskIndex = 1;

    while (tasks.length > 0) {
      let runningTasks = [];

      for (let index = 0; index < maxConcurrentTasks && tasks.length > 0; index++) {
        const task = tasks.shift();

        if (task) {
          let wrappedTask = async() => {
            Logger.debug(() => `Starting task ${Log.symbol(currentTaskIndex)} of ${Log.symbol(originalTasksLength)}`);
            try {
              const result = await task();
              Logger.debug(() => `Completed task ${Log.symbol(currentTaskIndex)} of ${Log.symbol(originalTasksLength)}`);
              return result;
            } catch (e) {
              errors.push(new CommonAggregateError(e));
              Logger.debug(() => `An error happened while running task ${Log.symbol(currentTaskIndex)} of ${Log.symbol(originalTasksLength + 1)}`, Log.error(e));
            }
          };
          currentTaskIndex++;
          runningTasks.push(wrappedTask);
        }
      }

      try {
        // TODO: refactor this to use a queue and Promise.race, so that we run at max capacity at all times
        result = [
          ...result,
          ...(await Promise.all(runningTasks.map(task => task())))
        ];
      } catch (e) {
        errors.push(CommonAggregateError.standardizeError(e));
      }
    }

    if (errors.length > 0) {
      throw new CommonAggregateError(errors);
    }
    return result;
  }

  /**
   * Executes the specified tasks (functions that return promises) sequentially.
   * @param tasks {(function(): Promise<*>)[]}
   * @param [defaultResult] {*} The default result to return if no result found (the default value is {@link undefined})
   * @returns {Promise<*>}
   */
  async sequential(tasks, defaultResult = undefined) {
    let result = defaultResult;
    for (let task of tasks) {
      result = await task(result);
    }
    return result;
  }
}

module.exports = {
  TaskExecutor: new TaskExecutor(),
};
