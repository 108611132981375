"use strict";

import { WIDGET_FIELDS as RiskLinksWidgetFields } from "../../editor/attributes/constants/risk_links_attribute_constants";
import { WIDGET_FIELDS as DocLinksWidgetFields } from "../../editor/attributes/constants/doc_links_attribute_constants";
import CommonJSONSchemasValidator from "../../../server/common/json/common_json_schemas_validator";

// noinspection JSFileReferences
import CommonModelVerifier from "../../../server/common/verification/common_model_verifier";

module.exports.verifyRecordForPropose = function(instance) {
  let errorsFound = [];
  // Run the regular model validator first to detect missing fields
  try {
    CommonModelVerifier.validate[instance.modelName](instance);
  } catch (error) {
    errorsFound = errorsFound.concat(error.message.split("\n"));
  }

  let jsonAttributeErrors = [];
  for (let attribute in instance) {
    if (Object.prototype.hasOwnProperty.call(instance, attribute) && !attribute.endsWith("Versions") && attribute !== "risksFromAllRecords") {
      if (CommonJSONSchemasValidator.isLinksJSON(instance[attribute])) {
        jsonAttributeErrors = jsonAttributeErrors.concat(verifyJSONAttributeForPropose("Document Link", JSON.parse(instance[attribute]), DocLinksWidgetFields));
      } else if (CommonJSONSchemasValidator.isRiskLinksArray(instance[attribute])) {
        jsonAttributeErrors = jsonAttributeErrors.concat(verifyJSONAttributeForPropose("Risk Link", instance[attribute], RiskLinksWidgetFields));
      }
    }
  }

  if (jsonAttributeErrors.length > 0) {
    if (errorsFound.length === 0) {
      errorsFound.push("The following fields are required for proposal but currently missing:");
    }
    errorsFound = errorsFound.concat(jsonAttributeErrors);
  }

  return errorsFound;
};

/**
 * Verifies whether a JSON attribute can be proposed
 * @param attributeName {string}
 * @param attribute {*}
 * @param widgetFields {WidgetField[]}
 * @return {*[]}
 */
function verifyJSONAttributeForPropose(attributeName, attribute, widgetFields) {
  let errorsFound = [];
  let fieldNamesWithErrors = [];
  let links = Array.isArray(attribute) ? attribute : [attribute];
  let requiredForProposeFields = widgetFields.filter(field => field.fieldName && field.requiredFor === "proposal");
  for (let link of links) {
    for (let {fieldName, displayName} of requiredForProposeFields.filter(field => !fieldNamesWithErrors.includes(field.fieldName))) {
      if (typeof link[fieldName] === "undefined" || link[fieldName] === null || link[fieldName] === "" || link[fieldName] === []) {
        fieldNamesWithErrors.push(fieldName);
        errorsFound.push(` - ${attributeName}: ${displayName ? displayName : fieldName}`);
      }
    }
  }

  return errorsFound;
}
