"use strict";

import * as React from "react";
import BaseReactComponent from "../../base_react_component";

/**
 * This class implements the rendering of a generic list option that accepts either key-value pairs or scalar values
 */
export class ListOption extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  isKeyValuePair(item) {
    return item ? Object.prototype.hasOwnProperty.call(item, "key") : false;
  }

  convertItemToKeyValuePair(item) {
    let option;

    if (item) {
      option = this.isKeyValuePair(item) ? item : {key: item, value: item};
    } else {
      option = {key: null, value: ""};
    }
    return option;
  }

  render() {
    const {item} = this.props;
    const {key, value} = this.convertItemToKeyValuePair(item);

    return (
      <option value={key} key={key}>{value}</option>
    );
  }
}
