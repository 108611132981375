"use strict";

import React from "react";
import ExpandColumnCell from "./widgets/expand_column_cell";
import * as UIUtils from "../../ui_utils";

export const TABLE_MODE = {
  VIEW: "view",
  EDIT: "edit",
};

const shouldRenderExpandColumnCell = (dataItem, detailsProp) => {
  return detailsProp === "AcceptanceCriteriaRanges" ?
    dataItem.Requirement?.AcceptanceCriteriaRanges?.length > 0
    : dataItem[detailsProp] && dataItem[detailsProp].length > 0;
};

export function generateTableId(parent, tableId) {
  const table = parent[tableId];

  if (table.current) {
    const header = table.current.element.querySelector(".k-grid-header");
    const container = table.current.element.querySelector(".k-grid-container");
    header?.setAttribute("id", `${tableId}Header`);
    container?.setAttribute("id", `${tableId}Container`);
  }
}

/**
 * Add id attribute to Table's Header and Container using tableId param
 * @param ref {React.RefObject} Reference of the table
 * @param tableId {string} The id that should be used to add id attributes to the table's header & container
 */
export function generateTableIds(ref, tableId) {
  const {current} = ref;

  if (current) {
    const header = current.element.querySelector(".k-grid-header");
    const container = current.element.querySelector(".k-grid-container");

    header?.setAttribute("id", `${tableId}Header`);
    container?.setAttribute("id", `${tableId}Container`);
  }
}

export function renderRow(tr, {dataIndex}) {
  return React.cloneElement(
    tr,
    {
      ...tr.props,
      id: `row_${dataIndex}`,
    }
  );
}

export function createExpandColumnCell(props, onExpandChange, detailsProp = "details") {
  return shouldRenderExpandColumnCell(props.dataItem, detailsProp) ?
    <ExpandColumnCell {...props} onExpandChange={onExpandChange} /> :
    <td className={props.className} style={props.style} />;
}

export function createExpandHeaderCell() {
  return <div className="k-header k-hierarchy-cell k-header" />;
}

export function updateExpandButtonStyle(dataItem, dataIndex) {
  const row = $(`#row_${dataIndex}`)[0];
  $(row).find("td").each(function() {
    if (dataItem.expanded) {
      $(this).addClass("configurable-tables-expanded-header");
    } else {
      $(this).removeClass("configurable-tables-expanded-header");
    }
  });
}

export function pushHistoryURLWithParameterChanges(object) {
  UIUtils.pushHistoryURLWithParameterChanges({}, object);
}

export const calculateTableWidth = visibleColumns => {
  const shownColumns = visibleColumns.filter(col => col.checked);
  return shownColumns.length >= 6
    ? window.innerWidth * 0.96 // 96% of the window's width
    : window.innerWidth * 0.75; // 75% of the window's width
};

export const addWindowListener = (event, adjustHeight) => {
  window.addEventListener(event, adjustHeight);
};

export const removeWindowListener = (event, adjustHeight) => {
  window.removeEventListener(event, adjustHeight);
};

export const countVisibleColumns = (visibleTableColumns) => {
  return visibleTableColumns?.filter(column => column.checked).length;
};