"use strict";

import React from "react";
import { EMPTY_STRING } from "../../../../helpers/constants/constants";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getURLByTypeCodeAndId } from "../../../../helpers/url_helper";
import * as CommonLibraryMaterial from "../../../../../server/common/editables/common_library_material";


export const MaterialAttributeCell = ({onChange, dataItem, data, readonly, field = EMPTY_STRING}) => {

  const defaultItem = {
    id: CommonLibraryMaterial.DEFAULT_ID,
    name: "Select an attribute to link",
  };

  const itemRender = (li, itemProps) => {

    let itemChildren;

    if (CommonLibraryMaterial.isSyncActionID(itemProps.dataItem.id)) {
      itemChildren = (
        <span className={"dropdown-item"}>
          <span className="d-block item-part-1">
            {itemProps.dataItem.name}
          </span>
          <span className={"action-dropdown-item item-part-2"} style={{whiteSpace: "pre-wrap"}}>
            {itemProps.dataItem.description}
          </span>
        </span>
      );
    } else {
      itemChildren =
        <span className="dropdown-item">
          <span className="item-part-1">{li.props.children}</span> <span className="item-part-2">MA-{itemProps.dataItem.id}</span>
        </span>;
    }

    return React.cloneElement(li, li.props, itemChildren);

  };

  const valueRender = (element, elementValue) => {

    let itemChildren;
    const getCssClass = (name) => name === defaultItem.name ? "default-dropdown-item" : "";

    if (!CommonLibraryMaterial.isSyncActionID(elementValue.id)) {
      itemChildren =
        <span
          key={1}
        >
          {elementValue.name} MA-{elementValue.id}
        </span>;
    } else {
      itemChildren =
        <span key={1}
              className={getCssClass(elementValue.name)}
        >
          {elementValue.name}
        </span>;
    }
    return React.cloneElement(element, {...element.props}, itemChildren);

  };

  const handleChange = (e) => {
    if (onChange) {
      onChange({
        dataIndex: 0,
        dataItem: dataItem,
        field: field,
        syntheticEvent: e.syntheticEvent,
        value: e.value,
      });
    }
  };

  const dataValue = dataItem[field] ? dataItem[field] : "";

  if (readonly) {
    return (
      <td>
        {dataValue &&
          <div>
            <a data-test-spececificationname={dataItem.name} target="_blank" rel="noreferrer" href={getURLByTypeCodeAndId("MA", "View", dataValue.id, false)}>
              {dataValue.name}
            </a><span className="material-attribute-cell-secondary">MA-{dataValue.id}</span>
          </div>
        }
      </td>
    );
  }

  return (
    <td data-test-spececificationname={dataItem.name}>
      <DropDownList
        style={{
          width: "100%",
        }}
        data={data}
        itemRender={itemRender}
        valueRender={valueRender}
        textField="name"
        value={data.find((c) => c.id === dataValue?.id)}
        defaultItem={defaultItem}
        onChange={handleChange}
      />
    </td>
  );
};