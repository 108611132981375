"use strict";

import * as UIUtils from "../ui_utils";
import Cookies from "js-cookie";
import * as CommonSecurity from "../../server/common/generic/common_security";
import { LOADING_PLEASE_WAIT } from "./cache/base_object_cache";
import { REPORT_TITLES_ENUM } from "../reports/constants/report_constants";

const TypeMap = new Map(
  [
    ["Intermediate Quality Attribute", CommonSecurity.Types.IQA],
    ["TPP", CommonSecurity.Types.TPP_SECTION],
  ]
);

/**
 * @typedef AdditionalCheckFunction {function(params: {permissions: [], action: *, type: *, instance: *, defaultVerificationResult: boolean, [user]: *}): boolean}
 */

/**
 * The functions in this file are responsible for determining in the client side UI, if a user has permissions to
 * do something.
 */

/**
 * Figure out if the current user can do said action with the given type and instance.
 * @param action An action from CommonSecurity.Actions
 * @param type An type from CommonSecurity.Types
 * @param [instance] An instance of data.  While this isn't used yet, pass it in if you can so that supporting instance level security will be easier later on.
 * @param [additionalCheck] {AdditionalCheckFunction} Specifies a function that can be executed to perform an additional
 * validation that is going to be executed after the regular validation process.
 */
export function can(action, type, instance = null, additionalCheck = null) {
  let permissions = getCurrentUserPermissions();
  return canWithPermissions({permissions, action, type, instance, additionalCheck});
}

/**
 * Figure out if the given user can perform the said action with the given type and instance
 * @param user The user with a permissions structure attached (ex. from ObjectCache)
 * @param action An action from CommonSecurity.Actions
 * @param type An type from CommonSecurity.Types
 * @param [instance] An instance of data.  While this isn't used yet, pass it in if you can so that supporting instance level security will be easier later on.
 * even when not explicitly granted
 * @param [additionalCheck] {AdditionalCheckFunction} Specifies a function that can be executed to perform an additional
 * validation that is going to be executed after the regular validation process.
 */
export function canUser(user, action, type, instance = null, additionalCheck = null) {
  // For users in a typeahead cache that are still loading.
  if (user === LOADING_PLEASE_WAIT) {
    return true;
  }

  return canWithPermissions({permissions: user.permissions, action, type, instance, additionalCheck, user});
}

/**
 * Checks if the user has access to view information for the given project
 * @param user The user with a list of projects attached
 * @param projectId The project Id to check user access for
 */
export function canUserAccessProject(user, projectId) {
  return !projectId || !!user.isAdmin || (user.projects && user.projects.includes(UIUtils.parseInt(projectId)));
}

// These act as a cache so we don't have to lookup the cookie value & parse the JSON constantly.
let isPermissionCookieSet = false;
let permissionCookieObj = null;

function getCurrentUserPermissions() {
  if (isPermissionCookieSet) {
    return permissionCookieObj;
  } else {
    permissionCookieObj = Cookies.getJSON("PERMISSIONS");
    isPermissionCookieSet = !!permissionCookieObj;
    return permissionCookieObj || {Actions: [], Types: [], Reports: []};
  }
}

/**
 * Checks if the user has access to view page
 * @param type The record type
 * @returns {boolean|*|*}
 */
export function canViewPage(type) {
  let license = Cookies.get("LICENSE");
  let permissions = getCurrentUserPermissions();

  if (license === CommonSecurity.UserLicenseTypes.ESSENTIAL) {
    return permissions.Types.includes(type);
  }
  return true;
}

/**
 * Checks if the user has access to view a specific report
 * @param type The report type
 * @returns {boolean|*|*}
 */
export function canViewReport(type) {
  let permissions = getCurrentUserPermissions();
  return permissions.Reports.includes(REPORT_TITLES_ENUM[type]);
}

/**
 * Figure out if the current user can do said action with the given type and instance.
 * @param params.action {*} An action from CommonSecurity.Actions
 * @param params.type {*} An type from CommonSecurity.Types
 * @param params.instance {*} An instance of data.  While this isn't used yet, pass it in if you can so that supporting instance level security will be easier later on.
 * @param [params.user] {*} The user to check permissions for
 * @param [params.permissions] {[]} The permissions for the user
 * @param params.additionalCheck {function(params: {permissions: [], action: *, type: *, instance: *, defaultVerificationResult: boolean, [user]: *}): boolean}
 */
function canWithPermissions(params) {
  let {permissions, action, type, instance, additionalCheck, user} = params;

  if (!permissions && user) {
    permissions = user.permissions;
  }

  if (!permissions) {
    throw new Error("Programmer error: No permissions specified.");
  }

  let result;

  // This makes life easier on the process explorer code base (ex. detailed_data_section.jsx)
  if (TypeMap?.has(type)) {
    type = TypeMap.get(type);
  }

  switch (type) {
    case CommonSecurity.Types.USER:
      if (action === CommonSecurity.Actions.EDIT && instance && UIUtils.isCurrentUser(instance.cognitoUUID)) {
        // Users can edit the base data about themselves
        result = true;
      } else {
        result = permissions.Actions.includes(action) && permissions.Types.includes(type);
      }
      break;
    case CommonSecurity.Types.ITP:
      // ITPs cannot be added
      if (action === CommonSecurity.Actions.ADD) {
        result = false;
        break;
      }
    // fall through to default
    default:
      result = permissions.Actions.includes(action) && permissions.Types.includes(type);
  }

  if (additionalCheck && typeof additionalCheck === "function") {
    result = additionalCheck({...params, type, defaultVerificationResult: result});
  }
  return result;
}

/**
 * Generate the appropriate tooltip depending on the user's access level.
 * @param action {SecurityActionTypes} An action from CommonSecurity.Actions
 * @param type {SecurityTypes} A type from CommonSecurity.Types
 * @param [instance] {*} An instance of data.  While this isn't used yet, pass it in if you can so that supporting instance level security will be easier later on.
 * @param [tooltipIfAccessAllowed] {string} An alternative tooltip to the action if the user has access to this function.
 * @param [additionalCheck] {function(params: {permissions: [], action: *, type: *, instance: *, defaultVerificationResult: boolean, [user]: *}): boolean}
 */
export function generateTooltip(action, type, instance, tooltipIfAccessAllowed, additionalCheck) {
  if (can(action, type, instance, additionalCheck)) {
    return tooltipIfAccessAllowed ? tooltipIfAccessAllowed : action;
  } else {
    return "You do not have permission. Contact your administrator.";
  }
}
