var _a;
/**
 * @hidden
 */
export var nodata = 'dropdowns.nodata';
/**
 * @hidden
 */
export var clear = 'dropdowns.clear';
/**
 * @hidden
 */
export var messages = (_a = {},
    _a[clear] = 'clear',
    _a[nodata] = 'NO DATA FOUND.',
    _a);
