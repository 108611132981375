"use strict";

import * as UIUtils from "../ui_utils";
import React, { Fragment } from "react";
import { handleCopyCurrentRecordToTarget } from "./rmp_helper";
import CommonEditablesPageTitleBar from "../widgets/pageTitleBar/common_editables_page_title_bar";
import TextAreaAttribute from "../editor/attributes/text_area_attribute";
import TextAttribute from "../editor/attributes/text_attribute";
import NumberAttribute from "../editor/attributes/number_attribute";
import RMPAttachmentsAttribute from "./rmp_attachment_attribute";
import ValidationIcon from "../widgets/generic/validation_icon";
import NonEditableAttribute from "../editor/attributes/non_editable_attribute";
import InfoTooltip from "../widgets/tooltips/info_tooltip";
import {
  COPY_RMP_REQUIREMENT_OPTIONS,
  REQUIREMENT_TABS,
  RISK_ATTRIBUTES,
  RISK_ATTRIBUTES_TABS,
  RISK_BOUNDARIES_ATTRIBUTES,
  RISK_COLORS,
  RMP_VERBIAGE,
} from "./constants/rmp_constants";
import CheckboxAttribute from "../editor/attributes/checkbox_attribute";
import RMPRiskSchemaTab from "./rmp_risk_schema_tab";
import Section from "../editor/widgets/section";
import ToggleInput from "react-switch";
import { faChevronCircleRight, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LabelTooltip from "../widgets/tooltips/label_tooltip";
import ConfirmationPopup from "../widgets/generic/confirmation_popup";
import { ATTRIBUTE_TYPE } from "../editor/attributes/constants/attribute_type";
import BaseQuickEditor from "../editor/base_quick_editor";
import { EDITOR_TYPES } from "../editor/editor_constants";
import DropdownButton from "../widgets/generic/dropdown_button";
import { t } from "../i18n/i18n";


const CommonUtils = require("../../server/common/generic/common_utils");
const RiskUtils = require("../../server/common/misc/common_risk_utils");

const RMP_TO_RISK_ATTRIBUTES = UIUtils.RMP_TO_RISK_ATTRIBUTES;
const USE_RISK_SCORE_PATTERN = /use(\w*)(Uncertainty|Detectability)/g;

export default class RMP extends BaseQuickEditor {
  constructor(props) {
    super(props, "rmp", "RMP", "Risk Management Plan");

    // Forces the page to be redirected upon saving (due to the complexity of the logic with all tables)
    this.redirectOnSave = true;
    this.useTwoWayCommunication = true;
    this.flexibleRmpIsEnabled = true;

    const defaultState = {
      useDetectability: true,
      useUncertainty: true,
      configureByType: true,
      useNotAssessed: true,
      configureByTypeSettings: JSON.stringify({
        useFQADetectability: true,
        useFQAUncertainty: true,
        useIQADetectability: true,
        useIQAUncertainty: true,
        useMADetectability: true,
        useMAUncertainty: true,
        usePPDetectability: true,
        usePPUncertainty: true,
        usePAUncertainty: true,
        usePADetectability: true,
        useTTUncertainty: true,
        useTTDetectability: true,
      }),
      selectedRiskTab: RISK_ATTRIBUTES_TABS.IMPACT,
      updateRiskTables: true,
    };

    if (this.isAdd()) {
      defaultState.useRulesBasedCriticality = true;
      defaultState.usePotential = true;
      Object.assign(defaultState, this.getDefaultKeyLabelProperties());
      Object.assign(defaultState, this.getDefaultPotentialLabelProperties(true));
    }

    this.setStateSafely({
      ...this.state,
      ...defaultState
    });

    let min = Number.MAX_SAFE_INTEGER;
    let max = Number.MIN_SAFE_INTEGER;

    for (let riskAttribute of RISK_BOUNDARIES_ATTRIBUTES) {
      this.state = {
        ...this.state,
        [`min${riskAttribute}`]: min,
        [`max${riskAttribute}`]: max,
      };
    }

    /*
    To make sure when user selects to configure RMP by type every tab has separated state
    different states values are being inserted into main state object.
     */
    for (let key of Object.keys(REQUIREMENT_TABS)) {
      let tab = REQUIREMENT_TABS[key];
      let modelName = UIUtils.getTypeCodeForModelName(tab.id);

      for (let riskAttribute of RISK_BOUNDARIES_ATTRIBUTES) {
        this.state = {
          ...this.state,
          [`${modelName}min${riskAttribute}`]: min,
          [`${modelName}max${riskAttribute}`]: max,
        };
      }

      for (let option of tab.riskAttributeOptions) {
        this.state[`use${modelName}${option}`] = true;
      }

      for (let riskAttribute of tab.riskAttributes) {
        this.state[`${modelName}RMPTo${UIUtils.pluralize(riskAttribute)}`] = [];
      }
    }

    this.setStateSafely({
      breadcrumb: {
        current: "New RMP",
        parent: "RMPs",
        parentLink: "/rmps/list.html",
      },
    });

    // add not assessed records
    if (this.state.useNotAssessed) {
      this.setStateSafely(this.generateAddNotAssessedRecords(this.state));
    }
  }

  showsVersionsInView() {
    return true;
  }

  isDiffingVersions(state = this.state) {
    return state?.currentDiffingVersion?.versionId > 0 || this.isLoadingDiff();
  }

  renderHeaderLink() {
    return (
      <a href={this.getHeaderLinkURL()}
         rel="noopener noreferrer"
         target="_blank"
         className="editor-page-header-link"
         id="riskManagementPlanDocumentLink"
      >
        <FontAwesomeIcon icon={faChevronCircleRight} /> Generate RMP Document
      </a>
    );
  }

  getHeaderLinkURL() {
    if (this._isMounted) {
      let {approved, versionId} = this.state;
      versionId = versionId && approved ? versionId : this.getLatestVersionId();
      return `/reports/cannedReports/base.html?reportType=RiskManagementPlan&modelLabel=RMP-${this.id}&versionId=${versionId}`;
    }
    return null;
  }

  handleRiskSchemaModelChange(tabId) {
    this.setStateSafely({
      activeRiskSchemaModelTab: tabId,
    });
  }

  onRiskTypeMinMaxUpdated(newLimits) {
    this.setStateSafely(newLimits);
  }

  handleRiskTabChange(tab) {
    this.setStateSafely({
      selectedRiskTab: tab,
    });
  }

  shouldShowNav() {
    return false;
  }

  renderPageTitleBar() {
    return this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? (
      <CommonEditablesPageTitleBar
        recordName={this.state.name}
        recordId={this.state.id}
        name="RMPs"
        typeCode={UIUtils.getTypeCodeForModelName(this.baseTypeName)}
        currentState={this.getCurrentlyViewedState()}
      />) : "";
  }

  getTabName() {
    return "RMPs";
  }

  getInfoTooltip() {
    return <InfoTooltip id="infoRMP" verbiage={RMP_VERBIAGE} />;
  }

  setError(errorId, error) {
    if (this.state[errorId] !== error) {
      this.setStateSafely({
        [errorId]: error,
      });
    }
  }

  clearError(errorId) {
    if (this.state[errorId]) {
      this.setError(errorId, null);
    }
  }

  /**
   * This function wipes out all attribute custom validations along with the visual errors which appear on the tabs
   * when the user moves between the configure RMP by type option back and forth. Once the page is re-rendered the
   * right attributes will be added to the customValidatedChildElements through registerCustomValidatedAttribute
   * in the base_json_attribute.
   */
  resetCustomErrors() {
    for (let customValidateChildElement of this.customValidatedChildElements) {
      customValidateChildElement.clearValidationErrors(this.state.formValidationMode === CommonUtils.FORM_VALIDATION_MODE.SAVE);
    }

    let errorProps = {};
    for (const prop in this.state) {
      if (Object.prototype.hasOwnProperty.call(this.state, prop) && prop.includes("TabError")) {
        errorProps[prop] = null;
      }
    }
    return errorProps;
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = "QbDVision RMP";
    this.allCustomValidatedChildElements = this.customValidatedChildElements;
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
  }

  /**
   * When editing an existing RMP using the configured by type option the page will load all risk attributes including
   * the ones that are not visible, so all RMP tabs (visible and not) will be loaded to the page and will register
   * themselves through the registerCustomValidatedAttribute method. This function filters the non visible
   * custom validated controls in save and propose so that React does not through console warnings of non mounted react
   * controls. This will prevent errors like (This is a no-op, but it indicates a memory leak in your application.
   * To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.) to show up in console.
   */
  filterCustomValidationsByType() {
    let riskNames = Object.keys(RMP_TO_RISK_ATTRIBUTES).map(key => RMP_TO_RISK_ATTRIBUTES[key].name);
    this.customValidatedChildElements = this.allCustomValidatedChildElements
      .filter(child => {
        if (this.state.configureByType) {
          return !riskNames.includes(child.props.name);
        } else {
          return riskNames.includes(child.props.name);
        }
      });
  }

  handleChangeValue(attributeName, attributeValue, callback, attributeType) {
    attributeType = ATTRIBUTE_TYPE.JSON_ATTRIBUTE;

    if (attributeName === "formValidationMode" &&
      attributeValue === CommonUtils.FORM_VALIDATION_MODE.PROPOSE) {
      this.filterCustomValidationsByType();
      super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
    } else if (attributeName === "configureByType" && attributeValue) {
      // Copy FQA risk schema to all tabs if exists
      let riskSchema = {};
      for (let key of Object.keys(REQUIREMENT_TABS)) {
        let tab = REQUIREMENT_TABS[key];
        let modelName = UIUtils.getTypeCodeForModelName(tab.id);

        for (let option of tab.riskAttributeOptions) {
          riskSchema[`use${modelName}${option}`] = this.state[`use${option}`];
        }

        for (let riskAttribute of tab.riskAttributes) {
          const modelToRiskName = `${modelName}RMPTo${UIUtils.pluralize(riskAttribute)}`;
          const maxRiskAttributeName = `max${riskAttribute}`.replace("Scale", "");
          const minRiskAttributeName = `min${riskAttribute}`.replace("Scale", "");
          riskSchema[`${modelName}${maxRiskAttributeName}`] = this.state[maxRiskAttributeName];
          riskSchema[`${modelName}${minRiskAttributeName}`] = this.state[minRiskAttributeName];
          riskSchema[modelToRiskName] = UIUtils.deepClone(this.state[`RMPTo${UIUtils.pluralize(riskAttribute)}`] || []);
          riskSchema[modelToRiskName].map(record => {
            this.configureRiskRecord(record, modelName);
          });
        }
      }

      this.setStateSafely({
        ...riskSchema,
        ...this.resetCustomErrors(),
        configureByType: true,
        dataModified: true,
        attributeType,
      });
    } else if (attributeName === "configureByType" && !attributeValue) {
      this.setStateSafely({
        showConfigureByTypeConfirmationPopup: true,
      });
    } else if (attributeName.match(USE_RISK_SCORE_PATTERN) && (!attributeValue || (attributeValue && this.state.useNotAssessed))) {
      this.filterCustomValidationsByType();
      /*
      When useUncertainty or useDetectability is unchecked a new empty record with
      risk score equals to 1 will be created and linked to these risk score's state array
      respectively so it doesnt affect the calculations because something * 1 = 1.
       */
      let regex = new RegExp(USE_RISK_SCORE_PATTERN).exec(attributeName);
      let modelName = regex[1];
      let riskType = regex[2];
      let riskTitle = riskType === "Detectability" ? "DetectabilityRisk" : riskType;

      /*
      This code will prevent useUncertainty or useDetectability from being disabled if any
      of them has rows in edit mode.
       */
      let riskScoreCustomErrorChildElement = this.customValidatedChildElements
        .find(child => child.props.name === `${modelName}RMP${UIUtils.pluralize(riskTitle)}`);
      if (riskScoreCustomErrorChildElement.hasRowInEditMode()) {
        riskScoreCustomErrorChildElement.validate();
        this.triggerFormValidation();
        return;
      }

      let RMPToRiskScores = [];
      if (this.state.useNotAssessed && attributeValue) {
        RMPToRiskScores.push(this.getNotUsedRiskScoreRecord(modelName));
      }
      RMPToRiskScores.push(this.getEmptyRiskScoreRecord(modelName));

      this.recomputeRowIndex(RMPToRiskScores);

      this.setStateSafely({
        [`${modelName}RMPTo${UIUtils.pluralize(riskTitle)}`]: RMPToRiskScores,
        [`${modelName}max${riskTitle}`]: 1,
        [`use${modelName}${riskType}`]: attributeValue,
        attributeType,
        dataModified: true,
      }, () => {
        this.updateControls(false, null, () => {
          /*
          This will make sure once useUncertainty or useDetectability is unchecked
          the right error message appears/disappears instantly.
         */
          let errorLinkedObjects = riskType === "Uncertainty" ? ["Uncertainty", "Criticality"] :
            ["DetectabilityRisk", "RPN"];

          for (let object of errorLinkedObjects) {
            let riskScoreCustomErrorChildElement = this.customValidatedChildElements
              .find(child => child.props.name ===
                `${modelName}RMP${object === "RPN" ? `To${object}` : UIUtils.pluralize(object)}`);
            riskScoreCustomErrorChildElement.validate();
          }

          super.triggerFormValidation();
        });
      });
    } else if (attributeName === "useNotAssessed") {
      const stateObj = attributeValue ? this.generateAddNotAssessedRecords(this.state) : this.generateRemoveNotAssessedRecords();

      stateObj.attributeType = attributeType;
      stateObj.dataModified = true;

      this.setStateSafely(stateObj, () => {
        this.updateControls(false, null, () => {
          super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
        });
      });
    } else if (attributeName === "useRulesBasedCriticality") {
      if (attributeValue === true && this.state.configureByType) {
        const stateObj = this.getDefaultKeyLabelProperties();
        stateObj.useRulesBasedCriticality = true;

        if (Object.keys(stateObj).length > 0) {
          this.setStateSafely(stateObj, () => {
            super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
          });
          return;
        }
      }
      
      super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
    } else if (attributeName === "usePotential") {
      if (attributeValue === true) {
        const stateObj = this.getDefaultPotentialLabelProperties(this.state.configureByType);
        stateObj.usePotential = true;

        if (Object.keys(stateObj).length > 0) {
          this.setStateSafely(stateObj, () => {
            super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
          });
          return;
        }
      }

      super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
    }
    else {
      super.handleChangeValue(attributeName, attributeValue, callback, attributeType);
    }
  }

  getDefaultPotentialLabelProperties(configureByType) {
    const stateObj = {};

    const fqaLabel = "pCQA";
    if (!configureByType) {
      stateObj.potentialLabel = fqaLabel;
      return stateObj;
    }

    const models = this.getModelsWithPotentialLabel();
    for (let modelName of models) {
      const stateKey = `potentialLabel${modelName}`;
      if (this.state[stateKey]) {
        continue;
      }

      let label = "pC" + modelName;
      switch (modelName) {
        case "FQA":
          label = fqaLabel;
          break;
        case "PA":
          label = "pKPA";
          break;
      }

      stateObj[stateKey] = label;
    }

    return stateObj;
  }

  getDefaultKeyLabelProperties() {
    const stateObj = {};
    const models = this.getModelsWithSupportForKeyLabel();
    for (let modelName of models) {
      const stateKey = `keyLabel${modelName}`;
      if (!this.state[stateKey]) {
        stateObj[stateKey] = "K" + modelName;
      }
    }

    return stateObj;
  }

  generateAddNotAssessedRecords(currentState) {
    const stateObj = {};
    for (let key of Object.keys(REQUIREMENT_TABS)) {
      let tab = REQUIREMENT_TABS[key];
      let modelName = this.state.configureByType ? UIUtils.getTypeCodeForModelName(tab.id) : "";

      for (let riskAttribute of RISK_ATTRIBUTES) {
        if (riskAttribute === "Uncertainty" && !currentState["use" + modelName + "Uncertainty"]) {
          continue;
        }

        if (riskAttribute === "DetectabilityRisk" && !currentState["use" + modelName + "Detectability"]) {
          continue;
        }

        const isRiskScale = riskAttribute.endsWith("Scale");
        const scores = [...this.state[`${modelName}RMPTo${UIUtils.pluralize(riskAttribute)}`]];
        if (!scores.find(score => (isRiskScale && RiskUtils.scaleIsNotAssessed(score)) || (!isRiskScale && RiskUtils.scoreIsNotAssessed(score.riskScore)))) {
          scores.unshift(isRiskScale ? this.getNotUsedScaleRecord(modelName) : this.getNotUsedRiskScoreRecord(modelName));
          this.recomputeRowIndex(scores);
          stateObj[`${modelName}RMPTo${UIUtils.pluralize(riskAttribute)}`] = scores;
        }
      }
    }

    return stateObj;
  }

  generateRemoveNotAssessedRecords() {
    const stateObj = {};

    for (let key of Object.keys(REQUIREMENT_TABS)) {
      let tab = REQUIREMENT_TABS[key];
      let modelName = this.state.configureByType ? UIUtils.getTypeCodeForModelName(tab.id) : "";

      for (let riskAttribute of RISK_ATTRIBUTES) {
        const isRiskScale = riskAttribute.endsWith("Scale");
        let scores = [...this.state[`${modelName}RMPTo${UIUtils.pluralize(riskAttribute)}`]];

        if (scores.find(score => (isRiskScale && RiskUtils.scaleIsNotAssessed(score)) || (!isRiskScale && RiskUtils.scoreIsNotAssessed(score.riskScore)))) {
          scores = scores.filter(score => (isRiskScale && !RiskUtils.scaleIsNotAssessed(score)) || (!isRiskScale && !RiskUtils.scoreIsNotAssessed(score.riskScore)));

          this.recomputeRowIndex(scores);

          stateObj[`${modelName}RMPTo${UIUtils.pluralize(riskAttribute)}`] = scores;
        }
      }
    }

    return stateObj;
  }

  recomputeRowIndex(rows) {
    for (let i=0; i < rows.length; i++) {
      rows[i].index = i;
    }
  }

  triggerFormValidation() {
    this.updateControls(false, null, () => {
      super.triggerFormValidation();
    });
  }

  /**
   * This function returns an empty risk score object
   * @param modelName model to attach to the risk score record
   * @returns {object} empty risk score object
   */
  getEmptyRiskScoreRecord(modelName) {
    return {
      description: "This risk type is not in use.",
      index: 0,
      color: "",
      modelName,
      riskScore: 1,
      scoreLabel: "Not Used",
      uuid: UIUtils.generateUUID(),
    };
  }

  getNotUsedRiskScoreRecord(modelName) {
    return {
      description: RiskUtils.NOT_ASSESSED_DESCRIPTION_LABEL,
      index: 0,
      color: RISK_COLORS.GREY,
      modelName,
      riskScore: RiskUtils.NOT_ASSESSED_SCORE,
      scoreLabel: RiskUtils.NOT_ASSESSED_LABEL,
      uuid: UIUtils.generateUUID(),
    };
  }

  getNotUsedScaleRecord(modelName) {
    return {
      index: 0,
      color: RISK_COLORS.GREY,
      modelName,
      controlStrategy: RiskUtils.NOT_ASSESSED_LABEL,
      developmentActivity: RiskUtils.NOT_ASSESSED_LABEL,
      from: RiskUtils.NOT_ASSESSED_SCALE,
      to: RiskUtils.NOT_ASSESSED_SCALE,
      riskLabel: RiskUtils.NOT_ASSESSED_LABEL,
      scoreLabel: RiskUtils.NOT_ASSESSED_LABEL,
      uuid: UIUtils.generateUUID(),
    };
  }

  /**
   * This function will update the record for saving and cloning
   * uuid: will make sure cloning works fine
   * id: deleting the id will make sure when user moves back and
   * forth between configure by type a new record will be created.
   * @param record the record to be updated
   * @param modelName the model this record linked to
   * @returns {*}
   */
  configureRiskRecord(record, modelName) {
    record.modelName = modelName;
    record.uuid = UIUtils.generateUUID();
    delete record.id;
  }

  handleConfigureByTypeChange(checked) {
    this.filterCustomValidationsByType();
    this.areCustomControlsValid(CommonUtils.FORM_VALIDATION_MODE.SAVE).then((customControlsValid) => {
      super.triggerFormValidation();
      if (customControlsValid) {
        this.handleChangeValue("configureByType", checked);
      }
    });
  }

  handleBeforeSaveButtonClick(callback) {
    this.setStateSafely({
      updateRiskTables: false,
    }, () => {
      callback();
    });
  }

  handleAfterSaveButtonClick() {
    this.setStateSafely({
      updateRiskTables: true,
    });
  }

  handleBeforeProposeButtonClick(callback) {
    this.handleBeforeSaveButtonClick(callback);
  }

  handleAfterProposeButtonClick() {
    this.handleAfterSaveButtonClick();
  }

  handleSave(event, callback) {
    UIUtils.setLoadingDisabled(false);
    this.filterCustomValidationsByType();
    this.updateControls(false, CommonUtils.FORM_VALIDATION_MODE.SAVE, () => {
      this.setStateSafely({
        ...this.resetCustomErrors(),
      }, () => {
        if (this.state.configureByType) {
          // Constructing configureByTypeSettings object before saving to the database
          let riskAttributeRecord = {};
          for (let key of Object.keys(REQUIREMENT_TABS)) {
            let tab = REQUIREMENT_TABS[key];
            let modelName = UIUtils.getTypeCodeForModelName(tab.id);

            for (let option of tab.riskAttributeOptions) {
              riskAttributeRecord[`use${modelName}${option}`] = this.state[`use${modelName}${option}`];
            }

            if (this.supportsKeyLabel(modelName)) {
              riskAttributeRecord[`keyLabel${modelName}`] = this.state[`keyLabel${modelName}`];
            }

            if (this.supportsPotentialLabel(modelName)) {
              riskAttributeRecord[`potentialLabel${modelName}`] = this.state[`potentialLabel${modelName}`];
            }
          }

          // Mapping all requirement risk attributes to the original tables to be saved
          let riskAttributeRecordsStateObject = {};
          for (let key of Object.keys(RMP_TO_RISK_ATTRIBUTES)) {
            let riskRequirement = UIUtils.pluralize(RMP_TO_RISK_ATTRIBUTES[key].linkedObject);
            let riskAttributeRecords = [];
            for (let key of Object.keys(REQUIREMENT_TABS)) {
              let tab = REQUIREMENT_TABS[key];
              let modelName = UIUtils.getTypeCodeForModelName(tab.id);
              riskAttributeRecords = riskAttributeRecords.concat(this.state[`${modelName}${riskRequirement}`]);
            }
            riskAttributeRecordsStateObject[riskRequirement] = riskAttributeRecords;
          }

          // Uncomment this for verbose logging
          // console.log(JSON.stringify(riskAttributeRecordsStateObject));

          this.setStateSafely({
            ...riskAttributeRecordsStateObject,
            configureByTypeSettings: JSON.stringify(riskAttributeRecord),
          }, () => {
            super.handleSave(event, callback);
          });
        } else {
          super.handleSave(event, callback);
        }
        super.triggerFormValidation();
      });
    });
  }

  handleReceiveRevisionDataFromServer(versionMap, callback) {
    super.handleReceiveRevisionDataFromServer(versionMap, callback);
    if (this.state.configureByType && this.isDiffingVersions()) {
      /*
      After receiving revision data from server objects are mapped back from one object to the 4 requirements version objects.
      As well the olderVersion is mapped so the version diffs work properly
       */
      let riskAttributeRecordsStateObject = {};
      let olderVersion = this.state.olderVersion;
      for (let key of Object.keys(RMP_TO_RISK_ATTRIBUTES)) {
        let riskRequirement = `${RMP_TO_RISK_ATTRIBUTES[key].linkedObject}LinkedVersions`;
        for (let key of Object.keys(REQUIREMENT_TABS)) {
          let tab = REQUIREMENT_TABS[key];
          let modelName = UIUtils.getTypeCodeForModelName(tab.id);
          if (olderVersion) {
            olderVersion[`${modelName}${riskRequirement}`] = olderVersion[riskRequirement]
              .filter(requirement => requirement.modelName === modelName);
            let configureByTypeSettings = olderVersion.configureByTypeSettings = typeof olderVersion.configureByTypeSettings === "string" ?
              JSON.parse(olderVersion.configureByTypeSettings) : olderVersion.configureByTypeSettings;
            olderVersion = {
              ...olderVersion,
              ...configureByTypeSettings,
            };
          }

          riskAttributeRecordsStateObject[`${modelName}${riskRequirement}`] = this.state[riskRequirement]
            .filter(requirement => requirement.modelName === modelName);
        }
      }

      if (olderVersion) {
        olderVersion.useNotAssessed = this.rmpUsesNotAssessed(olderVersion);
      }

      const useNotAssessed = this.rmpUsesNotAssessed({
        ...this.state,
        ...riskAttributeRecordsStateObject
      }, true);

      this.setStateSafely({
        ...riskAttributeRecordsStateObject,
        ...JSON.parse(this.state.configureByTypeSettings),
        olderVersion,
        useNotAssessed
      });
    }

    if (!this.state.configureByType && this.isDiffingVersions()) {
      let olderVersion = this.state.olderVersion;
      if (olderVersion) {
        olderVersion.useNotAssessed = this.rmpUsesNotAssessed(olderVersion, true);
      }

      const useNotAssessed = this.rmpUsesNotAssessed(this.state, true);
      this.setStateSafely({
        useNotAssessed
      });
    }

  }

  preprocessReceivedData(result) {
    if (result?.configureByType) {
      // After receiving data from server objects are mapped from one object to the 4 requirements tabs
      let riskAttributeRecordsStateObject = {};
      for (let key of Object.keys(RMP_TO_RISK_ATTRIBUTES)) {
        let riskRequirement = UIUtils.pluralize(RMP_TO_RISK_ATTRIBUTES[key].linkedObject);
        let riskRequirementLinkedVersion = `${RMP_TO_RISK_ATTRIBUTES[key].linkedObject}LinkedVersions`;

        for (let key of Object.keys(REQUIREMENT_TABS)) {
          let tab = REQUIREMENT_TABS[key];
          let modelName = UIUtils.getTypeCodeForModelName(tab.id);
          let riskArray = result[riskRequirement];
          riskArray = riskArray || result[riskRequirementLinkedVersion];

          riskAttributeRecordsStateObject[`${modelName}${riskRequirement}`] = riskArray
            .filter(requirement => requirement.modelName === modelName);
        }
      }
      result = {
        ...result,
        ...riskAttributeRecordsStateObject,
      };
    }

    for (let key of Object.keys(result)) {
      if (Array.isArray(result[key])) {
        result[key] = result[key].sort(CommonUtils.sortBy("riskScore", "from", "to"));
      }
    }

    return result;
  }

  onDataReceivedFromServer() {
    super.onDataReceivedFromServer();

    if (this.state.configureByType) {
      let riskAttributeRecords = {};
      for (let key of Object.keys(RMP_TO_RISK_ATTRIBUTES)) {
        let riskAttribute = UIUtils.pluralize(RMP_TO_RISK_ATTRIBUTES[key].linkedObject);
        riskAttributeRecords[riskAttribute] = [];
      }

      const useNotAssessed = this.rmpUsesNotAssessed({
        ...this.state,
        ...riskAttributeRecords
      });

      this.setStateSafely({
        ...riskAttributeRecords,
        ...JSON.parse(this.state.configureByTypeSettings),
        useNotAssessed,
        updateControls: false,
      });
    } else {
      const useNotAssessed = this.rmpUsesNotAssessed(this.state);

      this.setStateSafely({
        useNotAssessed,
        updateControls: false,
      });
    }

    let rmp = {typeCode: "RMP", id: this.state.RMPId || this.state.id, name: this.state.name};
    this.setStateSafely({
      breadcrumb: {
        current: UIUtils.getRecordCustomLabelForDisplay(rmp),
        parent: "RMP's",
        parentLink: "/rmps/list.html",
      },
    });
  }

  rmpUsesNotAssessed(state, useLinkedVersion = false) {
    if (state.configureByType) {
      for (let key of Object.keys(REQUIREMENT_TABS)) {
        let tab = REQUIREMENT_TABS[key];
        let modelName = UIUtils.getTypeCodeForModelName(tab.id);

        for (let riskAttribute of RISK_ATTRIBUTES) {
          let key = `${modelName}RMPTo${UIUtils.pluralize(riskAttribute)}`;
          if (useLinkedVersion) {
            key = `${modelName}RMPTo${riskAttribute}LinkedVersions`;
          }

          if (this.scoresHaveNotAssessed(state, key)) {
            return true;
          }
        }
      }
    } else {
      for (let riskAttribute of RISK_ATTRIBUTES) {
        let key = `RMPTo${UIUtils.pluralize(riskAttribute)}`;
        if (useLinkedVersion) {
          key = `RMPTo${riskAttribute}LinkedVersions`;
        }

        if (this.scoresHaveNotAssessed(state, key)) {
          return true;
        }
      }
    }

    return false;
  }

  scoresHaveNotAssessed(state, key) {
    const scores = state[key];
    if (!scores) {
      console.error(`Cannot find key ${key} in state`);
    }

    if (scores?.find(score => RiskUtils.scoreIsNotAssessed(score.riskScore))) {
      return true;
    }

    return false;
  }

  handleHideCopyRecordConfirmationPopup() {
    this.setStateSafely({
      showCopyRecordConfirmationPopup: false,
    });
  }

  handleHideConfigureByTypeConfirmationPopup() {
    this.setStateSafely({
      showConfigureByTypeConfirmationPopup: false,
    });
  }

  handleConfigureByTypeConfirmation() {
    // Copy FQA risk schema back to main single schema
    let riskSchema = {};
    for (let riskAttribute of REQUIREMENT_TABS["FQA"].riskAttributes) {
      const modelToRiskName = `RMPTo${UIUtils.pluralize(riskAttribute)}`;
      const maxRiskAttributeName = `max${riskAttribute}`.replace("Scale", "");
      const minRiskAttributeName = `min${riskAttribute}`.replace("Scale", "");
      riskSchema[maxRiskAttributeName] = this.state[`FQA${maxRiskAttributeName}`];
      riskSchema[minRiskAttributeName] = this.state[`FQA${minRiskAttributeName}`];
      riskSchema[modelToRiskName] = UIUtils.deepClone(this.state[`FQARMPTo${UIUtils.pluralize(riskAttribute)}`]) || [];
      riskSchema[modelToRiskName].map(record => {
        this.configureRiskRecord(record, "");
      });
    }

    const stateForPotentialLabel = this.getDefaultPotentialLabelProperties();

    this.setStateSafely({
      ...riskSchema,
      ...stateForPotentialLabel,
      useDetectability: this.state.useFQADetectability,
      useUncertainty: this.state.useFQAUncertainty,
      usePotential: this.state.usePotential,
      useRulesBasedCriticality: false,
      configureByType: false,
      ...this.resetCustomErrors(),
      dataModified: true,
      attributeType: ATTRIBUTE_TYPE.JSON_ATTRIBUTE,
    }, () => {
      if (this.configureByTypeConfirmationPopup) {
        $(this.configureByTypeConfirmationPopup).modal("hide");
      }
    });
  }

  handleCopyRecordConfirmation() {
    handleCopyCurrentRecordToTarget(this);
  }

  handleCopyRecordButtonClick(event) {
    const selectedRMPRecord = event.target.textContent;
    const selectedRMPRecordObject = COPY_RMP_REQUIREMENT_OPTIONS.filter(value => value.value === selectedRMPRecord)[0];
    const copyConfirmationVerbiage = selectedRMPRecord === "Copy to all" ?
      "This will override all records with the current tab's values. Are you sure you want to copy?" :
      `${selectedRMPRecord} already has values in it. If you copy, we will override. Are you sure you want to copy?`;
    this.setStateSafely({
      selectedCopyToRecord: selectedRMPRecord,
      selectedCopyToRecordTitle: selectedRMPRecordObject.title,
      copyRecordMessage: copyConfirmationVerbiage,
    }, () => {
      if (this.isRecordEmpty(selectedRMPRecordObject) && selectedRMPRecord !== "Copy to all") {
        handleCopyCurrentRecordToTarget(this);
      } else {
        this.setStateSafely({
          showCopyRecordConfirmationPopup: true,
        });
      }
    });
  }


  isRecordEmpty(selectedRecordObject) {
    return RISK_ATTRIBUTES.every(attribute =>
      UIUtils.isEmpty(this.state[`${selectedRecordObject.title}RMPTo${UIUtils.pluralize(attribute)}`]),
    );
  }

  supportsKeyLabel(modelName, ignoreState = false) {
    if (!this.state.useRulesBasedCriticality && !ignoreState) {
      return false;
    }

    const modelsWithKeyLabel = this.getModelsWithSupportForKeyLabel();
    return modelsWithKeyLabel.includes(modelName);
  }

  getKeyLabelTooltip(modelName) {
    if (!this.supportsKeyLabel(modelName, true)) {
      return `${modelName} does not support Key Label`;
    }

    if (!this.state.useRulesBasedCriticality) {
      return "Enable `Use Rules Based Criticality` to enable Key Label";
    }

    return "";
  }

  getPotentialLabelTooltip(modelName) {
    if (modelName && !this.state[`use${modelName}Uncertainty`]) {
      return `Potential Label is not available for ${modelName} when Use Uncertainty is disabled`;
    }

    if (!modelName && !this.state[`useUncertainty`]) {
      return `Potential Label is not available when Use Uncertainty is disabled`;
    }

    if (!this.supportsPotentialLabel(modelName, true)) {
      return `${modelName} does not support Potential Label`;
    }

    if (!this.state.usePotential) {
      return "Enable `Use Potential` to enable Potential Label";
    }

    return "";
  }

  supportsPotentialLabel(modelName = null, ignoreState = false) {
    if (!this.state.usePotential && !ignoreState) {
      return false;
    }

    if (!modelName && this.state[`useUncertainty`]) {
      return true;
    }

    const modelsWithPotentialLabel = this.getModelsWithPotentialLabel();
    return modelsWithPotentialLabel.includes(modelName) && this.state[`use${modelName}Uncertainty`];
  }

  getModelsWithPotentialLabel() {
    return ["FQA", "IQA", "MA", "PP", "PA"];
  }

  getModelsWithSupportForKeyLabel() {
    return ["MA", "PP", "PA"];
  }

  supportsRulesBasedCriticality() {
    return this.state.configureByType;
  }

  getRulesBasedCriticalityLabelTooltip() {
    if (!this.supportsRulesBasedCriticality()) {
      return "Rules Based Criticality is only available when byType option is on";
    }

    return <div>Rules-Based Criticality Labeling enhances the risk assessment model by enforcing additional logic in specific cases commonly seen in industry examples. This includes:<br/>
      1. Providing a “Key” label override for risks that only impact performance attributes. For example, a parameter with a very high impact on Yield (set up as a performance attribute) will be assigned the Key label instead of the calculated Critical label. Key Process Parameters or Key Attributes (ex. Final Quality Attribute) are commonly seen in case studies where “Key” is used to distinguish them from items impacting patient safety or efficacy, which are labeled “Critical.”<br/>
      2. Not allowing parameters or attributes to be marked as Critical if they don’t impact a Critical Quality Attribute (CQA). For example, suppose a user marks a Process Parameter as Critical, but the parameter doesn’t impact a CQA. In that case, the parameter will be downgraded to a non-critical level in the RMP.</div>;
  }

  renderAttributes() {
    // Uncomment for verbose logging
    //console.log(JSON.stringify(this.state));

    /*
    Collect all tabs with validation errors so we
    can show a validation icon next to the requirement tab
     */
    const requirementTabsWithErrors = [];
    if (this.state.configureByType) {
      for (const prop in this.state) {
        let errorTabRegexPattern = /(FQA|IQA|PP|MA|PA|TT)(\w+)TabError/g;
        if (Object.prototype.hasOwnProperty.call(this.state, prop) && prop.match(errorTabRegexPattern)) {
          let modelName = new RegExp(errorTabRegexPattern).exec(prop)[1];
          if (!requirementTabsWithErrors.includes(modelName) && this.state[prop]) {
            requirementTabsWithErrors.push(modelName);
          }
        }
      }
    }

    const minRmpScoreClassName = "col-sm-4" + (this.flexibleRmpIsEnabled ? " rmp-hidden" : "");

    return (
      <div>
        <Section parent={this}
                 header={
                   <span>
                       <span className="editor-page-header-text">
                         {this.getGeneralHeader()}
                       </span>
                      {!this.isAdd() ?
                       <span className="editor-page-header-link-div">
                         {this.renderHeaderLink()}
                       </span> : ""}
                   </span>
                 }
        >
          {this.state.showConfigureByTypeConfirmationPopup ?
            <ConfirmationPopup
              modalRef={configureByTypeConfirmationPopup => this.configureByTypeConfirmationPopup = configureByTypeConfirmationPopup}
              headerText={"Confirmation required"}
              message="WARNING: The risk schema will reset to your current values for FQAs for all types. Are you sure?"
              useYesNoOptions={true}
              showCancelButton={true}
              onOkButtonClick={this.handleConfigureByTypeConfirmation}
              onHideConfirmationPopup={this.handleHideConfigureByTypeConfirmationPopup}
            />
            : ""}
          {this.state.showCopyRecordConfirmationPopup ?
            <ConfirmationPopup
              modalRef={copyRecordConfirmationPopup => this.copyRecordConfirmationPopup = copyRecordConfirmationPopup}
              headerText={`Copy to ${this.state.selectedCopyToRecord === "Copy to all" ? "all" : this.state.selectedCopyToRecord}`}
              message={this.state.copyRecordMessage}
              useYesNoOptions={true}
              showCancelButton={true}
              yesButtonText={"Copy"}
              noButtonText={"Cancel"}
              onOkButtonClick={this.handleCopyRecordConfirmation}
              onHideConfirmationPopup={this.handleHideCopyRecordConfirmationPopup}
            />
            : ""}
          <div className="row">
            <TextAttribute name="name"
                           className="col-sm-4"
                           parent={this}
            />
            <TextAreaAttribute name="description"
                               tooltipText="Describe the overall function and/or breadth of the project"
                               className="col-sm-8"
                               parent={this}
            />
          </div>
          <div className="row">
            <NonEditableAttribute name="minRiskScore"
                                  className={minRmpScoreClassName}
                                  displayName="Min Risk Score"
                                  defaultValue="1"
                                  parent={this}
            />
            <NumberAttribute name="maxRiskScore"
                             className="col-sm-4"
                             displayName="Max Risk Score"
                             max={1000}
                             min={this.state.minRiskScore ? UIUtils.parseInt(this.state.minRiskScore) + 1 : 1}
                             parent={this}
            />
          </div>

          <div className="row">
            <CheckboxAttribute name="useNotAssessed"
                               displayName="Allow 'Not Assessed' scoring"
                               tooltipText="Not Assessed scoring allows the deferral of risk scoring. It provides flexibility when a record is created, but the for that particular risk has yet to be assessed. If not selected, the system will default to the highest risk value."
                               className="col-sm-4"
                               parent={this}
            />

            <CheckboxAttribute name="useRulesBasedCriticality"
                               displayName="Use Rules Based Criticality Labeling"
                               tooltipText={this.getRulesBasedCriticalityLabelTooltip()}
                               disabled={!this.supportsRulesBasedCriticality()}
                               className="col-sm-4"
                               parent={this}
            />

            <CheckboxAttribute name="usePotential"
                               displayName="Use Potential"
                               tooltipText="The Potential option allows labeling attributes and parameters with high uncertainty scores as potentially critical (e.g., pCQA). Potential scoring is typically used in early development processes where there are high unknowns about the impact."
                               className="col-sm-4"
                               parent={this}
            />
          </div>


        </Section>
        <Section id="riskSchema"
                 parent={this}
                 showDocLinks={false}
                 header={<div className="rmp-riskSchema-section-header">
                   <span>Risk Schema</span>
                   {this.isView() ? "" :
                     <div className="rmp-control-checkbox-toggle">
                       <ToggleInput id="configureByTypeInput"
                                    className="configure-by-type-checkbox-toggle"
                                    checked={this.state.configureByType}
                                    onChange={this.handleConfigureByTypeChange}
                                    height={22}
                                    width={38}
                                    checkedIcon={false}
                                    uncheckedIcon={false}
                                    activeBoxShadow="0 0 2px 3px #014768"
                                    onColor="#DBE1E4"
                                    offColor="#DBE1E4"
                                    onHandleColor="#1F46A1"
                       />
                       <LabelTooltip id="configureByTypeInputLabel"
                                     tooltipText={
                                       <div>
                                         The By Type option allows you to build a different risk management
                                         schema for each type of record in QbDVision (e.g., you can use it to specify a
                                         different risk scales for FQA and Process Parameters).
                                         <br /><br />
                                         Warning: While the By Type option meets the flexibility required by certain
                                         real-world applications of risk management, we recommend confirming with your
                                         quality and risk management teams before using this option. Adding multiple
                                         schemas can exponentially increase the complexity of risk assignment, comparison, and analysis.
                                       </div>}
                                     text="By Type"
                                     className="rmp-control-checkbox-label-toggle"
                       />
                     </div>}
                 </div>}
        >
          {this.state.configureByType ? "" :
            <Fragment>
              <div className="row">
                <CheckboxAttribute name="useUncertainty"
                                   displayName="Use Uncertainty"
                                   className="col-sm-4"
                                   parent={this}
                />
                <CheckboxAttribute name="useDetectability"
                                   displayName="Use Detectability"
                                   className="col-sm-4"
                                   parent={this}
                />

                <TextAttribute name="potentialLabel"
                               displayName="Potential Label"
                               tooltipText={this.getPotentialLabelTooltip()}
                               className="col-sm-4"
                               disabled={!this.supportsPotentialLabel()}
                               parent={this}
                />
              </div>
            </Fragment>}
          <div>
            {this.isView() ?
              <div className="row">
                <CheckboxAttribute name="configureByType"
                                   className="col-sm-4"
                                   parent={this}
                />

              </div> : ""
            }
            <Fragment>
              <div className={"nav nav-tabs" + (this.state.configureByType ? "" : " rmp-hidden")}>
                {
                  Object.keys(REQUIREMENT_TABS).map(key => {
                    let tab = REQUIREMENT_TABS[key];
                    let modelName = UIUtils.getTypeCodeForModelName(tab.id);
                    let isTabActive = this.state.activeRiskSchemaModelTab === tab.id || (typeof this.state.activeRiskSchemaModelTab === "undefined" && tab.active);
                    return <a data-toggle="tab"
                              key={tab.id}
                              id={`${tab.id}RequirementTab`}
                              href={`#${tab.id}`}
                              className={"nav-link" + (isTabActive ? " active" : "")}
                              onClick={this.handleRiskSchemaModelChange.bind(this, tab.id)}
                              aria-controls={tab.id}
                    >
                      {tab.displayName}
                      {requirementTabsWithErrors.includes(modelName) ?
                        <FontAwesomeIcon icon={faExclamationTriangle}
                                         className="tab-nav-bar-error-icon-red rmp-tab-nav-bar-icon"
                                         id={`${modelName}TabErrorIcon`}
                        />
                        : ""}
                    </a>;
                  })
                }
              </div>
              <div className={"tab-content " + (this.state.configureByType ? "rmp-tab-content-configured-by-type" : "rmp-hidden")}>
                {
                  Object.keys(REQUIREMENT_TABS).map(key => {
                    let tab = REQUIREMENT_TABS[key];
                    let modelName = UIUtils.getTypeCodeForModelName(tab.id);
                    let useUncertainty = `use${modelName}Uncertainty`;
                    let useDetectability = `use${modelName}Detectability`;
                    let keyLabel = `keyLabel${modelName}`;
                    let potentialLabel = `potentialLabel${modelName}`;
                    let isTabActive = this.state.activeRiskSchemaModelTab === tab.id || (typeof this.state.activeRiskSchemaModelTab === "undefined" && tab.active);
                    return <div key={tab.id} id={tab.id}
                                className={"rmp-requirement-risk-tab tab-pane fade" + (isTabActive ? " show active" : "")}
                                role="tabpanel"
                                aria-labelledby={`${tab.id}RequirementTab`}
                    >
                      <div className="row rmp-requirement-risk-tab-options align-items-center">
                        <div className="col-sm-5">
                          <div className="row">
                            <CheckboxAttribute name={useUncertainty}
                                               displayName="Use Uncertainty"
                                               className="col-sm-6"
                                               parent={this}
                            />
                            <CheckboxAttribute name={useDetectability}
                                               displayName="Use Detectability"
                                               className="col-sm-6"
                                               parent={this}
                            />
                          </div>
                        </div>

                        <div className="col-sm-5">
                          <div className="row">
                            <TextAttribute name={keyLabel}
                                           displayName="Key Label"
                                           tooltipText={this.getKeyLabelTooltip(modelName)}
                                           className="col-sm-6"
                                           disabled={!this.supportsKeyLabel(modelName)}
                                           parent={this}
                            />
                              <TextAttribute name={potentialLabel}
                                             displayName="Potential Label"
                                             tooltipText={this.getPotentialLabelTooltip(modelName)}
                                             disabled={!this.supportsPotentialLabel(modelName)}
                                             className="col-sm-6"
                                             parent={this}
                              />
                          </div>
                        </div>


                        {!this.isView() ? <div className="col-sm-2 rmp-copy-btn-flex">
                            <DropdownButton options={COPY_RMP_REQUIREMENT_OPTIONS}
                                            removeTitleIcon={true}
                                            isSecondaryButton={false}
                                            buttonClassName=""
                                            className={"btn-group"}
                                            id="rmpRecordCopy"
                                            text={t("Copy to")}
                                            isDisabled={this.hasApprovedVersion() && !this.flexibleRmpIsEnabled}
                                            onOptionsSelect={this.handleCopyRecordButtonClick}
                                            title={this.hasApprovedVersion() && !this.flexibleRmpIsEnabled ?
                                              "Copy to is disabled because this RMP has an approved version" :
                                              "Copy to"}
                            />
                            <InfoTooltip id="copyButtonToolTip"
                                         verbiage={"Copy to button will allow you to copy this risk schema to risk schema of another record type."}
                            />
                          </div>
                          : ""}
                      </div>
                      <RMPRiskSchemaTab
                                parent={this}
                                key={tab.id}
                                modelName={modelName}
                                onRiskTabChange={this.handleRiskTabChange}
                                useUncertainty={this.state[useUncertainty]}
                                useDetectability={this.state[useDetectability]}
                                useRulesBasedCriticality={this.state.useRulesBasedCriticality && this.supportsKeyLabel(modelName)}
                                keyLabel={this.state[keyLabel]}
                                usePotential={this.state.usePotential && this.supportsPotentialLabel(modelName)}
                                potentialLabel={this.state[potentialLabel]}
                                useNotAssessed={this.state.useNotAssessed}
                                rmp={this.state}
                                visible={isTabActive}
                                isParentTabActive={isTabActive}
                                onRiskTypeMinMaxUpdated={this.onRiskTypeMinMaxUpdated}
                      />
                    </div>;
                  })
                }
              </div>
            </Fragment>
            <RMPRiskSchemaTab
              className={this.state.configureByType ? "rmp-hidden" : ""}
              parent={this}
              modelName=""
              key="allAttributes"
              onRiskTabChange={this.handleRiskTabChange}
              useUncertainty={this.state.useUncertainty}
              useDetectability={this.state.useDetectability}
              useNotAssessed={this.state.useDetectability}
              usePotential={this.state.usePotential && this.supportsPotentialLabel()}
              potentialLabel={this.state.potentialLabel}
              useRulesBasedCriticality={false}
              rmp={this.state}
              visible={true}
              isParentTabActive={true}
              onRiskTypeMinMaxUpdated={this.onRiskTypeMinMaxUpdated}
            />
          </div>
        </Section>
        <Section id="attachments"
                 parent={this}
                 showDocLinks={false}
                 header={<div className="rmp-attachments-section-header">
                   <span>Attachments</span>
                   <ValidationIcon id="attachmentsTabIcon"
                                   tooltip={this.state["attachmentsSectionError"]}
                                   visible={!!this.state["attachmentsSectionError"]}
                   />

                 </div>}
        >
          <div className="row">
            <RMPAttachmentsAttribute name="rMPLinks"
                                     hideTableCaption={true}
                                     className="col-sm-12"
                                     parent={this}
            />
          </div>
        </Section>
      </div>
    );
  }
}
