"use strict";

import React from "react";


import * as I18NWrapper from "../../i18n/i18n_wrapper";
import ImportFlowOptionsPanel from "../../import/widgets/general/import_flow_options_panel";
import { registerInactiveItemsBehavior } from "../../widgets/dropdowns/dropdown_extensions";
import BaseReactComponent from "../../base_react_component";

// i18next-extract-mark-ns-start batches

/**
 * This renders the import data button on the batch record page.
 */
class BatchDataImportButton extends BaseReactComponent {
  componentDidMount() {
    super.componentDidMount();

    registerInactiveItemsBehavior(".batch-data-import-button-div", "batch-import-button-option-container", "disabled");
  }

  render() {
    const {t, parent, projectId, processId, batchId, importDependenciesStatus, disableAllFlows, disabledAllFlowsReason} = this.props;

    return (
      <div className="btn-group batch-data-import-button-div">
        <button id="batchDataImportButton"
                type="button"
                className={"btn btn-secondary dropdown-toggle" + this.getClassForLoading()}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                disabled={(parent.isAdd && parent.isAdd()) || this.isLoading()}
        >
          {t("Import")} <span className="caret" />
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          <ImportFlowOptionsPanel id="importFlowOptionsPanel"
                                  projectId={projectId}
                                  processId={processId}
                                  batchId={batchId}
                                  disableAllFlows={disableAllFlows}
                                  disabledAllFlowsReason={disabledAllFlowsReason}
                                  importConfigs={this.props.importTypeConfigs}
                                  importDependenciesStatus={importDependenciesStatus}
          />
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(BatchDataImportButton, ["batches"]);

// i18next-extract-mark-ns-stop batches
