"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import ReactDOMServer from "react-dom/server";
import DataReportBase from "../data_report_base";
import { getShortElementName } from "../canned_reports/canned_report_helper";
import ValidationIcon from "../../widgets/generic/validation_icon";
import {
  overwriteHighChartsViewTableDefinition,
  getReportCharts
} from "./chart_report_helper";
import { REPORT_OPTIONS_ENUM, REPORT_TYPES_ENUM } from "../constants/report_constants";
import ReportURLGenerator from "../report_url_builder";
import { isQualitativeMeasure } from "../../../server/common/editables/common_editables";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { MODEL_DECLARATIONS } from "../../../server/common/generic/common_models";

/* This class shows all data chart reports */
export default class ChartReport extends DataReportBase {
  constructor(props) {
    super(props);

    this.setStateSafely({
      chartConfigGroups: [],
      modelLabel: "",
      clearCharts: false,
      shouldShowRightPanel: false,
      dataLoaded: false,
    });
  }

  getReportCategoryType() {
    return "chartReports";
  }

  onTypeaheadChange(options, callback) {
    const value = options.length === 0 ? null : options[0];
    super.onTypeaheadChange(options, () => {
      if (typeof callback === "function") {
        callback();
      }
      if (this.props.onTypeaheadChange) {
        this.props.onTypeaheadChange(value);
      }
    });
  }

  hideLoadingIndicator() {
    UIUtils.setHideLoadingOnAjaxStop(true);
    UIUtils.hideLoadingImage();
  }

  handleReceivedDataFromServer(cachedRMP, project, results) {
    // This line hides all high chart data tables as part of QI-2320 fix
    $(".highcharts-data-table").hide();

    if (results && results.instances) {
      let requirement = results.instances.requirement;
      let {subgroups: selectedSubgroups} = requirement;

      let shortElementName = getShortElementName(this.props.reportType, this.modelType);
      let modelLabel = UIUtils.getRecordLabelForDisplay(shortElementName, requirement.id, requirement.name);
      const subgroupWithOneMeasurementExists = !!selectedSubgroups.find(subgroup => subgroup.totalMeasurements === 1);
      const subgroupWithOtherMeasurementsExists = !!selectedSubgroups.find(subgroup => subgroup.totalMeasurements !== 1);

      if (selectedSubgroups.length < 2) {
        UIUtils.showError(`At least 2 subgroups are required for the process analytics reports.`);
        this.setStateSafely({selectedSubgroups, clearCharts: true}, this.hideLoadingIndicator);
      } else if (!selectedSubgroups || selectedSubgroups.length === 0) {
        UIUtils.showError(UIUtils.secureString(`${this.props.reportOptions.elementLabel} (${modelLabel}) needs more subgroups to calculate process analytics.`));
        this.setStateSafely({selectedSubgroups, clearCharts: true}, this.hideLoadingIndicator);
      } else if (subgroupWithOneMeasurementExists && subgroupWithOtherMeasurementsExists) {
        UIUtils.showError(`Calculations are different for subgroups that have 1 sample vs multiple samples. Ensure all subgroups have either 1 sample or more than 1 sample.`);
        this.setStateSafely({selectedSubgroups, clearCharts: true}, this.hideLoadingIndicator);
      } else if (isQualitativeMeasure(requirement.measure) &&
        this.props.reportType === REPORT_TYPES_ENUM.ControlChartsContinuousReport) {

        let urlRequestParams = `reportType=ControlChartsDefectivesReport&projectId=${this.props.projectId}`;
        urlRequestParams += `&modelLabel=${this.state.selectedTypeaheadOption.typeCode}-${this.state.selectedTypeaheadOption.id}`;

        let errorMessage = ReactDOMServer.renderToStaticMarkup(
          <span>
            For Defects (Pass/Fail) and Conforms (Pass/Fail) attributes please check&nbsp;
            <a target="_blank"
               rel="noopener noreferrer"
               href={`chartReports.html?${urlRequestParams}`}
            >
              Control Charts (Defectives)
            </a>.
          </span>);
        UIUtils.showError(errorMessage, null, null, true);

        this.setStateSafely({selectedSubgroups, clearCharts: true}, this.hideLoadingIndicator);
      } else if (!isQualitativeMeasure(requirement.measure) && this.props.reportType === REPORT_TYPES_ENUM.ControlChartsDefectivesReport) {

        let commonURLParams = {
          projectId: this.props.projectId,
          modelLabel: `${this.state.selectedTypeaheadOption.typeCode}-${this.state.selectedTypeaheadOption.id}`,
        };
        let continuesReportURLParams = {
          ...commonURLParams,
          reportType: REPORT_TYPES_ENUM.ControlChartsContinuousReport,
        };

        let errorMessage = ReactDOMServer.renderToStaticMarkup(
          <span>
            For (NLT, NMT and Range) attributes please check&nbsp;
            <a rel="noopener noreferrer"
               target="_blank"
               href={ReportURLGenerator.generateURL(REPORT_OPTIONS_ENUM.ControlChartsContinuousReport, continuesReportURLParams)}
            >
              Control Charts (Continuous)
            </a>.
          </span>);
        UIUtils.showError(errorMessage, null, null, true);

        this.setStateSafely({selectedSubgroups, clearCharts: true}, this.hideLoadingIndicator);
      } else if (!isQualitativeMeasure(requirement.measure) && this.props.reportType === REPORT_TYPES_ENUM.LibraryControlChartsDefectivesReport) {
        let commonURLParams = {
          projectId: this.props.projectId,
          modelLabel: `${this.state.selectedTypeaheadOption.typeCode}-${this.state.selectedTypeaheadOption.id}`,
        };
        let continuesReportURLParams = {
          ...commonURLParams,
          reportType: REPORT_TYPES_ENUM.LibraryControlChartsContinuousReport,
        };

        let errorMessage = ReactDOMServer.renderToStaticMarkup(
          <span>
            For (NLT, NMT and Range) attributes please check&nbsp;
            <a rel="noopener noreferrer"
               target="_blank"
               href={ReportURLGenerator.generateURL(REPORT_OPTIONS_ENUM.LibraryControlChartsContinuousReport, continuesReportURLParams)}
            >
              Control Charts (Continuous)
            </a>.
          </span>);
        UIUtils.showError(errorMessage, null, null, true);

        this.setStateSafely({selectedSubgroups, clearCharts: true}, this.hideLoadingIndicator);
      } else {
        overwriteHighChartsViewTableDefinition();

        let chartConfigGroups = [];
        const reportConfigs = getReportCharts(this.props.reportType);

        for (let chartGroup of requirement.chartGroups) {
          let chartConfigs = [];

          for (let chart of chartGroup) {
            let chartConfig = {};
            let reportConfig = reportConfigs.find(config => `${config.renderIn}Config` === chart.key);
            let chartData = chart.data;

            chartData.title = reportConfig.title;
            chartData.yAxisLabel = reportConfig.yAxisLabel;
            chartData.xAxisLabel = reportConfig.xAxisLabel;
            chartData.subgroupsCount = requirement.subgroupsCount;
            chartData.isCenterLineDashed = reportConfig.isCenterLineDashed;
            chartData.showLSL = reportConfig.showLSL;
            chartData.showUSL = reportConfig.showUSL;
            chartData.fixToMostDecimalPlaces = reportConfig.fixToMostDecimalPlaces;

            chartConfig.indices = chartData.indices;
            chartConfig.chartData = chartData;
            chartConfig.validations = chartData.validations;
            chartConfig.chartKey = chart.key;
            chartConfigs.push(Object.assign({}, chartConfig));
          }

          chartConfigGroups.push(chartConfigs);
        }

        this.setStateSafely({
          selectedSubgroups, chartConfigGroups, modelLabel, clearCharts: false, dataLoaded: true
        });
      }

      if (this.props.onReceivedDataFromServer) {
        this.props.onReceivedDataFromServer(results);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
    if (!shouldUpdate) {
      const {chartConfigGroups, dataLoaded} = this.state;
      const nextChartConfigGroups = nextState.chartConfigGroups;
      const nextDataLoaded = nextState.dataLoaded;
      const allChartConfigs = chartConfigGroups.reduce((chartConfigs, chartConfigGroup) => {
        chartConfigs = chartConfigs.concat(chartConfigGroup);
        return chartConfigs;
      }, []);
      const allNextChartConfigs = nextChartConfigGroups.reduce((chartConfigs, chartConfigGroup) => {
        chartConfigs = chartConfigs.concat(chartConfigGroup);
        return chartConfigs;
      }, []);

      let reportConfigsWithIndices = getReportCharts(this.props.reportType).filter(chart => chart.renderOverallIndices);

      for (let reportConfig of reportConfigsWithIndices) {
        const config = allChartConfigs.find(chartConfig => chartConfig.chartKey === `${reportConfig.renderIn}Config`);
        const nextConfig = allNextChartConfigs.find(chartConfig => chartConfig.chartKey === `${reportConfig.renderIn}Config`);
        shouldUpdate = shouldUpdate || (config && nextConfig && JSON.stringify(config.indices) !== JSON.stringify(nextConfig.indices));
      }

      shouldUpdate = shouldUpdate
        || (JSON.stringify(this.props.selectedSubgroups) !== JSON.stringify(nextProps.selectedSubgroups))
        || dataLoaded !== nextDataLoaded;
    }
    return shouldUpdate;
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    if (prevProps.processId !== this.props.processId && this.typeahead) {
      this.setStateSafely({clearCharts: true});
    }

    if (!this.isReportLoading && this.props.selectedSubgroups && prevProps.selectedSubgroups && (JSON.stringify(this.props.selectedSubgroups) !== JSON.stringify(prevProps.selectedSubgroups))) {
      // The list of selectedSubgroups changed, so reload the data
      this.setStateSafely({clearCharts: false, dataLoaded: false}, () => {
        this.loadData();
      });
      return;
    }

    if (this.state.dataLoaded) {
      /**
       * This part uses chart reflow to adapt to window resizing. If this part is
       * removed, charts will be cut on whatever UI structure before UI is updated.
       */
      const reportConfigs = getReportCharts(this.props.reportType);
      for (let chartConfigGroup of this.state.chartConfigGroups) {
        for (let chartConfig of chartConfigGroup) {
          let reportConfig = reportConfigs.find(config => `${config.renderIn}Config` === chartConfig.chartKey);
          const {chartData} = chartConfig;
          const chart = window.HighCharts.chart(reportConfig.renderIn, reportConfig.loader(chartData));

          // Uncomment for verbose logging
          // let series1 = chartConfig.chart.series[0];
          // console.log(reportConfig.name, series1 ? JSON.stringify(series1.points.map(p => `[${p.x},${p.y}]`)) : "Series 1 not found");
          // let series2 = chartConfig.chart.series.length > 1 ? chartConfig.chart.series[1] : null;
          // console.log(reportConfig.name, series2 ? JSON.stringify(series2.points.map(p => `[${p.x},${p.y}]`)) : "Series 2 not found");
          // console.log(window.HighCharts.charts);

          let pointsOutsideLimits = chartData.pointsOutsideLimits;
          if (pointsOutsideLimits && pointsOutsideLimits.length > 0) {
            for (let point of pointsOutsideLimits) {
              chart.series[0].data[point].update({
                color: "#cc3300"
              }, false);
            }
          }

          chart.reflow();
        }
      }

      this.setStateSafely({dataLoaded: false}, () => this.hideLoadingIndicator());
    }
  }

  openRightPanel() {
    this.setStateSafely({shouldShowRightPanel: true});
  }

  closeRightPanel() {
    this.setStateSafely({shouldShowRightPanel: false});
  }

  renderFilters() {
    let {selectedTypeaheadOption, selectedSubgroups} = this.state;
    const isSelectedTypeaheadOptionSet = selectedTypeaheadOption && selectedTypeaheadOption.label;
    return <Fragment>
      {isSelectedTypeaheadOptionSet ? (
        <div className="col-auto pl-0 align-self-end mb-2">
          {selectedTypeaheadOption.typeCode !== MODEL_DECLARATIONS.SPECIFICATION.typeCode &&
            (<a id="chartViewRequirement"
                href={getURLByTypeCodeAndId(selectedTypeaheadOption.typeCode, "View", selectedTypeaheadOption.id)}
                target="_blank"
                rel="noopener noreferrer"
                title={"View " + selectedTypeaheadOption.label}
            >
              <FontAwesomeIcon id="chartsViewRecord"
                               icon={faExternalLinkAlt}
              />
            </a>)
          }
        </div>
      ) : ""}
      {selectedSubgroups && this.props.allSubgroups ? (
        <div className="col align-self-center">
          <label className="col-form-label base-attribute">
            Showing:
          </label>
          <div>
          <span id="chartReportShowingMessage">
            {selectedSubgroups.length} of {this.props.allSubgroups.length} subgroups.
          </span>
            <a id="chartsChangeSubgroups" onClick={this.props.onChangeData}>Change</a>
          </div>
        </div>
      ) : ""}
    </Fragment>;
  }

  renderCharts() {
    const {chartConfigGroups} = this.state;

    return chartConfigGroups.length > 0 ? chartConfigGroups.map((chartConfigGroup, index) => {
      return (
        <div key={index} className={chartConfigGroup.length > 0 ?
          "row chart-report-container-default chart-report-container" : ""}
        >
          {chartConfigGroup.map((chartConfig, index) => {
            const {validations, indices} = chartConfig;
            const reportConfigs = getReportCharts(this.props.reportType);
            const reportConfig = reportConfigs.find(config => `${config.renderIn}Config` === chartConfig.chartKey);
            const hasMultipleCols = reportConfig && reportConfig.renderWithinIndices;
            const isLastGroup = index === chartConfigGroups.length - 1;

            return (<div key={index} className={"col-12 "
              + (hasMultipleCols ? "col-xl-6" : "col-lg-6")
              + (isLastGroup ? " chart-report-container-last-chart" : "")}
            >
              <div className="row">
                {reportConfig && reportConfig.renderWithinIndices ? reportConfig.renderWithinIndices(indices) : ""}
                <div className={hasMultipleCols ? "col-12 col-md-6" : "col"}>
                  <div className="chart-reports-validation-icon">
                    <ValidationIcon
                      id={`${reportConfig.name}ErrorValidationIcon`}
                      tooltip={validations.errors}
                      visible={validations.errors.length > 0}
                    />
                    <ValidationIcon
                      id={`${reportConfig.name}WarningValidationIcon`}
                      tooltip={validations.warnings}
                      visible={validations.warnings.length > 0}
                      isWarning={true}
                    />
                  </div>
                  <div className="chart-report-charts-container"
                       id={reportConfig.renderIn}
                  />
                </div>
                {reportConfig && reportConfig.renderOverallIndices ? reportConfig.renderOverallIndices(indices) : ""}
              </div>
            </div>);
          })}
        </div>
      );
    }) : "";
  }

  renderInput() {
    return (
      <div className="col-12 px-0">{!this.state.clearCharts ? this.renderCharts() : ""}</div>
    );
  }

  filterOptions(options) {
    let {
      parentId,
      reportType,
    } = this.props;

    if (parentId && reportType === REPORT_TYPES_ENUM.LibraryControlChartsContinuousReport) {
      return options.filter(option => option.libraryMaterialId === parentId);
    }
    return options;
  }
}
