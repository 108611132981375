"use strict";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { BaseOptionsMenu } from "../../widgets/base_options_menu";
import { createPortal } from "react-dom";
import * as UIUtils from "../../ui_utils";


/**
 * Options Menu Component for all Dynamic tables.
 */
export default class TableOptionsMenu extends BaseOptionsMenu {
  constructor(props) {
    super(props);
  }

  handleClickOutside = ({target}) => {
    const dataAttribute = target?.getAttribute("data-menu-target")
      || target?.parentElement?.getAttribute("data-menu-target");

    if (!dataAttribute || UIUtils.parseInt(dataAttribute) !== this.props.id) {
      this.setState({showMenu: false});
    }
  };


  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.showMenu !== nextState.showMenu) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  }

  handleMenuToggle(event) {
    UIUtils.ignoreHandler(event);
    const buttonRect = event.target.getBoundingClientRect();
    const divTop = buttonRect.bottom;
    const divLeft = buttonRect.left;

    UIUtils.clearError();

    this.setState(prev => ({
      showMenu: !prev.showMenu,
      position: {top: divTop, left: divLeft}
    }));
  }

  renderMenuPortal() {
    const {id} = this.props;
    const {showMenu, position} = this.state;

    if (!showMenu) return null;

    return createPortal(
      <div className={"dropdown-wrapper shown"}
           style={{
             top: `${position.top}px`,
             left: `${position.left}px`,
           }}
           data-menu-target={id}
      >
        <ul id={`optionsUl_${id}`}
            className={`dropdown-menu table-row-options-button table-options-menu shown`}
        >
          {this.renderItems()}
        </ul>
      </div>,
      document.body,
    );
  }

  render() {
    const {showMenu} = this.state;
    const {id, iconOrientation, className} = this.props;

    return (
      <div className={`dropdown ${showMenu ? "show" : ""}`}>
        <button id={`actionsButton_${id}`}
                className={`btn table-options-button ${className ?? ""}`}
                type="button"
                data-menu-target={id}
                onClick={this.handleMenuToggle.bind(this)}
        >
          <FontAwesomeIcon
            data-menu-target={id}
            icon={iconOrientation === "horizontal" ? faEllipsisH : faEllipsisV}
          />
        </button>
        {this.renderMenuPortal()}
      </div>
    );
  }
}
