"use strict";

import React, { memo, useEffect, useState } from "react";
import { process } from "@progress/kendo-data-query";
import { EMPTY_STRING } from "../../../../../../helpers/constants/constants";

const pluralize = (count) => {
  if (count !== 1) {
    return "s";
  }
  return EMPTY_STRING;
};

const RowsCount = ({records, tableState, visibleTableColumns, customFilters, customFiltersHandler, operationsAdapter}) => {

  if (!records) {
    return null;
  }

  const [
    filterResultCount,
    setResultCount
  ] = useState(null);


  useEffect(() => {
    if (tableState) {
      const {filters = []} = tableState.filter || {};
      const state = operationsAdapter.adaptFilterNames({tableState, visibleTableColumns});
      const {data} = process(customFiltersHandler.process(customFilters, records), state);
      setResultCount(filters.length ? data.length : null);
    } else {
      setResultCount(records.length);
    }
  }, [tableState?.filter, visibleTableColumns]);

  return (
    <span id="configurableTablesRecordsCountSummary">
      {filterResultCount === null
        ? `${records.length} Record${pluralize(records.length)}`
        : (<span>
          <span id="configurableTablesFilteredRecordsCountSummary">{filterResultCount}</span>
          <span> / {records.length} Records</span>
        </span>)}
    </span>
  );

};

export default memo(RowsCount);
