@import "../../../../css/_brand_colors.scss";

.container {
  display: flex;
  gap: 1px;
  flex-wrap: wrap;
}

.pills-container {
  display: block;
}

.pills-container:empty {
  &::before {
    content: "\2014"; /* unicode for em dash */
    color: $brand-mild-grey;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}

.pills-row {
  display: block;
  max-width: 100%;
}

.pills-row:last-of-type {
  margin-top: 5px;
}

.pill-item {
  padding: 2px 4px;
  border-radius: 4px;
  border: 1px solid $brand-slight-grey;
  background: var(--brand-light-grey, #F0F3F5);
  margin-right: 3px;
  white-space: nowrap;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.002px;
  max-width: 100%;
}

.content-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hidden-pills {
  cursor: pointer;
}

.overlay-content {
  color: $brand-dark-grey;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.tooltip-container {
  :global(.tooltip-inner) {
    padding: 16px;
  }
}

.tooltip-container > :global(.tooltip-inner) {
  padding: 8px 17px 8px 12px;
  background-color: #fff;
  color: $brand-black;
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px 0 rgba(0, 0, 0, 0.20);
  text-align: left;
}

.tooltip-container > :global(.arrow::before) {
  border-bottom-color: $brand-white;
  border-width: 7px;
}