import { __assign } from "tslib";
import { addRowAfter as pmAddRowAfter, addRowBefore as pmAddRowBefore, addColumnBefore as pmAddColumnBefore, addColumnAfter as pmAddColumnAfter, deleteColumn as pmDeleteColumn, TableMap } from 'prosemirror-tables';
import { colgroupAttr, rowTypeAttr } from './config/constants';
import { parseStrColgroup } from './config/schema';
/**
 * Creates a table.
 * @returns Node
 */
export var createTable = function (nodes, rows, columns) {
    var table = nodes.table, table_row = nodes.table_row, table_cell = nodes.table_cell;
    var tableRows = [];
    var cells;
    for (var r = 0; r < rows + 1; r++) {
        cells = [];
        for (var c = 0; c < columns + 1; c++) {
            cells.push(table_cell.createAndFill());
        }
        tableRows.push(table_row.createAndFill(undefined, cells));
    }
    return table.createAndFill(undefined, tableRows);
};
var reTable = /^table$/;
var reRow = /^table_row$/;
var reCell = /^table_cell|table_header$/;
var closest = function (selection, name) {
    var pos = selection.$head;
    for (var i = pos.depth; i > 0; i--) {
        var node = pos.node(i);
        if (name.test(node.type.name)) {
            return {
                pos: pos.before(i),
                node: node
            };
        }
    }
    return null;
};
export var addRowBefore = function (state, dispatch) {
    var cmdDispatch = dispatch && (function (tr) {
        var _a;
        var row = closest(tr.selection, reRow);
        var table = closest(tr.selection, reTable);
        if (row && table && row.node.attrs[rowTypeAttr]) {
            var index = 0;
            for (var i = 0; i < table.node.nodeSize; i++) {
                if (table.node.child(i).eq(row.node)) {
                    index = i;
                    break;
                }
            }
            var next = table.node.child(index - 1);
            var from = row.pos - next.nodeSize;
            tr.setNodeMarkup(from, undefined, (_a = {}, _a[rowTypeAttr] = row.node.attrs[rowTypeAttr], _a));
        }
        return dispatch(tr);
    });
    return pmAddRowBefore(state, cmdDispatch);
};
export var addRowAfter = function (state, dispatch) {
    var cmdDispatch = dispatch && (function (tr) {
        var _a;
        var row = closest(tr.selection, reRow);
        if (row && row.node.attrs[rowTypeAttr]) {
            var from = row.pos + row.node.nodeSize;
            tr.setNodeMarkup(from, undefined, (_a = {}, _a[rowTypeAttr] = row.node.attrs[rowTypeAttr], _a));
        }
        return dispatch(tr);
    });
    return pmAddRowAfter(state, cmdDispatch);
};
var columnIndex = function (table, selection) {
    if (!table) {
        return -1;
    }
    var map = TableMap.get(table.node);
    var tableCell = closest(selection, reCell);
    var idx = map.map.indexOf(tableCell.pos - table.pos - 1);
    var colIndex = -1;
    if (idx !== -1) {
        colIndex = idx % map.width;
    }
    return colIndex;
};
var addCol = function (tr, table, to) {
    var _a;
    var colIndex = columnIndex(table, tr.selection);
    var colgroup = parseStrColgroup(table.node.attrs[colgroupAttr]);
    colgroup.insertBefore(colgroup.ownerDocument.createElement('col'), colgroup.children[colIndex + to]);
    tr.setNodeMarkup(table.pos, null, __assign(__assign({}, table.node.attrs), (_a = {}, _a[colgroupAttr] = colgroup.outerHTML, _a)));
};
var deleteCol = function (tr, table, colIndex) {
    var _a;
    var colgroup = parseStrColgroup(table.node.attrs[colgroupAttr]);
    colgroup.removeChild(colgroup.children[colIndex]);
    tr.setNodeMarkup(table.pos, null, __assign(__assign({}, table.node.attrs), (_a = {}, _a[colgroupAttr] = colgroup.outerHTML, _a)));
};
export var addColumnBefore = function (state, dispatch) {
    var cmdDispatch = dispatch && (function (tr) {
        var table = closest(tr.selection, reTable);
        if (!table.node.attrs[colgroupAttr]) {
            return dispatch(tr);
        }
        addCol(tr, table, -1);
        return dispatch(tr);
    });
    return pmAddColumnBefore(state, cmdDispatch);
};
export var addColumnAfter = function (state, dispatch) {
    var cmdDispatch = dispatch && (function (tr) {
        var table = closest(tr.selection, reTable);
        if (!table.node.attrs[colgroupAttr]) {
            return dispatch(tr);
        }
        addCol(tr, table, 1);
        return dispatch(tr);
    });
    return pmAddColumnAfter(state, cmdDispatch);
};
export var deleteColumn = function (state, dispatch) {
    var colIndex = columnIndex(closest(state.selection, reTable), state.selection);
    var cmdDispatch = dispatch && (function (tr) {
        var table = closest(tr.selection, reTable);
        if (!table.node.attrs[colgroupAttr]) {
            return dispatch(tr);
        }
        deleteCol(tr, table, colIndex);
        return dispatch(tr);
    });
    return pmDeleteColumn(state, cmdDispatch);
};
export { pmAddRowBefore, pmAddRowAfter, pmAddColumnBefore, pmAddColumnAfter, pmDeleteColumn };
