"use strict";

import ConfigurableTablesAdapter from "./configurable_tables_adapter";
import { PROCESS_LEVEL_ID } from "../../techTransfer/tech_transfer_constants";

/**
 * This class adapts/transforms the tech transfer data from server for Steps.
 */
export default class ConfigurableTablesStepAdapter extends ConfigurableTablesAdapter {
  constructor(params) {
    super(params, "stpMap", "Steps");
  }

  getRows() {
    const rows = [];
    const uos = Object.values(this.activeMap);
    super.adaptRows(uos);
    for (const record of uos) {
      record.unitOperationId = record.UnitOperation ? record.UnitOperation.id : PROCESS_LEVEL_ID;
      record.unitOperation = record.UnitOperation ? record.UnitOperation.name : "";

      const previousStep = record.PreviousStepId ?
        uos.find(uo => uo.id === record.PreviousStepId) : null;
      record.previousStep = previousStep ? previousStep.name : "Start";
      rows.push(record);
    }

    return this.wrapResults(rows);
  }
}
