"use strict";

import * as style from "./tags_attributes.module.scss";

import { WIDGET_FIELDS } from "../../../integrations/constants/tags_constants";
import MemoryCache from "../../../utils/cache/memory_cache";
import React from "react";
import BaseJsonAttribute from "../base_json_attribute";
import sidePanelToggleIcon from "../../../images/editor/side-panel-toggle-icon.svg";
import CommonUtils from "../../../../server/common/generic/common_utils";
import Editor from "../../../documentEditor/qbd_editor";
import { NON_EDITABLE_QBD_FIELD_NODE } from "../../../documentEditor/common/editorSchemas/qbd_field_node";
import { EditorUtils } from "@progress/kendo-react-editor";
import * as DirectScopeWidgetSetter from "../../../documentEditor/utils/setter/direct_scope_widget_setter";

export default class TagsAttribute extends BaseJsonAttribute {
  constructor(props) {
    super(props, WIDGET_FIELDS);
    this.getIntegrationSystems = this.getIntegrationSystems.bind(this);
    this.integrationSystems = [];
    this.editedRows = props.editedRows && Array.isArray(props.editedRows) ? props.editedRows : [];
    this.view = null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState) ? true : this.editedRows.length > 0;
    if (!shouldUpdate) {
      // In the base_editor, the handleChangeValue will set the updatedTags to true if we made
      // change to any record field that the tags has it. We need to do this to reflect the
      // Applies To column reflect the latest value
      shouldUpdate = nextProps.parent?.state?.updatedTags || false;
    }
    return shouldUpdate;
  }

  /**
   * Retrieves the integration systems.
   * @returns {Array} The integration systems.
   */
  getIntegrationSystems() {
    if (this.integrationSystems && this.integrationSystems.length === 0) {
      let memoryCache = MemoryCache.getNamedInstance("base_title_bar_process");
      let process = memoryCache.get("process_" + this.props.processId);
      if (process && process.integrations) {
        this.setIntegrationSystems(process);
      } else {
        // If we can't find integrations, its either they don't exist or not loaded yet so we try to find them in another object
        memoryCache = MemoryCache.getNamedInstance("approvalContainerForBottom");
        process = memoryCache.get("Process");
        if (process && process.length > 0) {
          process = process.find(record => {
            return record.id === this.props.processId;
          });
          if (process && typeof process === "object" && process.integrations) {
            this.setIntegrationSystems(process);
          }
        }
      }
    }
    return this.integrationSystems;
  }

  setIntegrationSystems(process) {
    const integrations = JSON.parse(process.integrations);
    this.integrationSystems = integrations.map(system => ({
      id: system.integrationSystem,
      label: system.integrationSystem,
    }));
  }

  updateInputValue(field) {
    if (!this.view) {
      return;
    }

    const record = this.props.parent?.state;
    const {schema} = this.view.state;
    const nodeType = schema.nodes[NON_EDITABLE_QBD_FIELD_NODE.name];
    const node = nodeType.createAndFill(
      {
        class: `qbd-output qbd-output-direct-scope-widget ${
          field.actualValue.element ? field.actualValue.element.className : ""
        }`,
        "data-record-id": record?.id,
        "data-record-model-name": record?.modelName,
        "data-record-column-name": field.name,
        "data-record-sub-model-name": field.subModelName,
        "data-record-sub-model-data": field.subModelData,
      },
      // If it has an element, it means it is color element for risk color. We couldn't find
      // a way to create a node from the html. This is a hacking solution to make it show the
      // small color square
      field.actualValue.element
        ? schema.text(" ")
        : schema.text(`${field.actualValue.content || " "}`),
    );
    EditorUtils.insertNode(this.view, node);
    this.view.focus();
  }

  handleCancel(rowData) {
    super.handleCancel(rowData);
    const {onActionButtonClick} = this.props;
    // Close the side menu if there is no row is in editing mode
    if (!this.editedRows.length && onActionButtonClick) {
      onActionButtonClick(false);
    }
  }

  handleSave(rowData, rows, setValueCallback, errorReport = new Map()) {
    const {onActionButtonClick} = this.props;
    if (super.handleSave(rowData, rows, setValueCallback, errorReport)) {
      // Close the side menu if there is no row is in editing mode
      if (!this.editedRows.length && onActionButtonClick) {
        onActionButtonClick(false);
      }
      return true;
    }
    return false;
  }

  handleChange(rowData) {
    if (!this.view) {
      return;
    }

    const appliesToWidgetField = this.widgetFields.find(
      (field) => field.fieldName === "appliesTo",
    );
    const appliesToHtmlContentWidgetField = this.widgetFields.find(
      (field) => field.fieldName === "appliesToHtmlContent",
    );

    const appliesToFieldsWidgetField = this.widgetFields.find(
      (field) => field.fieldName === "appliesToFields",
    );
    const htmlContent = EditorUtils.getHtml(this.view.state);
    if (appliesToWidgetField) {
      const node = new DOMParser().parseFromString(htmlContent, "text/html");
      this.handleChangeField(rowData, appliesToWidgetField, [
        node.documentElement.innerText,
      ]);
    }
    if (appliesToHtmlContentWidgetField) {
      this.handleChangeField(rowData, appliesToHtmlContentWidgetField, htmlContent);
    }
    if (appliesToFieldsWidgetField) {
      let dummyElement = document.createElement("div");
      dummyElement.innerHTML = htmlContent;

      // Extract all values of data-record-column-name
      const appliesToRawFields = dummyElement.querySelectorAll("[data-record-column-name]");
      let appliesToFieldsText = "";
      appliesToRawFields.forEach(node => {
        const value = node.getAttribute("data-record-column-name");
        if (value) {
          appliesToFieldsText += value + " ";
        }
      });
      this.handleChangeField(rowData, appliesToFieldsWidgetField, appliesToFieldsText);
    }
  }

  renderCustomInputForAppliesTo(field, editedRowValue, rowData) {
    let editorContent = "";
    const editedRow = this.getEditedRowByUUID(rowData.uuid);
    if (!editedRow) {
      return null;
    }

    if (editedRow.appliesTo && editedRow.appliesTo.length) {
      editorContent = editedRow.appliesTo.join(" ");
    }

    if (editedRow.appliesToHtmlContent) {
      editorContent = editedRow.appliesToHtmlContent;
    }

    const record = this.props.parent.state;
    if (record) {
      editorContent = DirectScopeWidgetSetter.setLatestDataForAllElements(
        editorContent,
        {[record.modelName]: [record]},
        record.RMP,
      );
    }

    const id = `${this.props.name}_${CommonUtils.capitalize(field.fieldName)}_${rowData.index}`;
    return (
      <div id={id} className="row flex-row m-0">
        <div className={style["editor-wrapper"]}>
          <Editor
            hideToolbar
            oneLineOnly
            ref={this.editorRef}
            editorContent={editorContent}
            onFocus={(view) => {
              // We set the view, so we can insert the smart content on the last editor
              // was on focus
              this.view = view;
              return false;
            }}
            onChange={() => {
              this.handleChange(rowData);
            }}
          />
        </div>
        <div className="input-group-append">
          <button
            id="toggleSmartContentAndWidgetMenu"
            className={`btn btn-secondary m-0 ${style["toggle-side-menu-button"]}`}
            type="button"
            onClick={this.props.onActionButtonClick}
          >
            <img className="align-baseline ml-0" src={sidePanelToggleIcon} alt="Toggle Icon" />
          </button>
        </div>
      </div>
    );
  }
}
