"use strict";

import React from "react";
import * as StickyAdapter from "../../utils/sticky_adapter";
import BaseTitleBar from "./base_title_bar";
import { hasSupportForRisk } from "../../helpers/risk_helper";

/* This shows the title bar in at the top of the screen */
export default class TitleBar extends BaseTitleBar {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    StickyAdapter.makeItSticky(this);
  }

  getURLForSection() {
    const {sectionName, projectId} = this.props;

    switch (sectionName) {
      case "Dashboard":
        return "/projects/dashboard.html?projectId=" + projectId;
      case "Control Methods":
        return "/controlMethods/list.html?projectId=" + projectId;
      case "Data":
        return "/batches/list.html?projectId=" + projectId;
      case "Documents":
        if (projectId) {
          return "/documents/documentsListInProject.html?projectId=" + projectId;
        } else {
          return "/documents/list.html";
        }
      case "TPP":
        return "/product/list.html?projectId=" + projectId + "&selectedProductListTab=TPP";
      case "QTPP":
        return "/product/list.html?projectId=" + projectId + "&selectedProductListTab=QTPP";
      case "Product":
        return "/product/list.html?projectId=" + projectId;
      case "Process Explorer":
        return "/processExplorer/processExplorer.html?projectId=" + projectId;
      case "RMPs":
        return "/rmps/list.html";
      case "Suppliers":
        return "/suppliers/list.html";
      case "Training":
      case "Individual Training Plans":
        return "/training/list.html";
      case "Curriculum":
      case "Curricula":
        return "/training/curricula/list.html";
      case "Library Material":
      case "Library Data":
        return "/library/list.html";
      default:
        throw new Error("You need to add the URL for " + sectionName + " to title_bar");
    }
  }

  shouldShowPrimaryName() {
    const {instance} = this.props;
    return instance
      && (instance.name || instance.customID);
  }

  renderTitleBarPrimaryName(primaryName) {
    return (
      <span id="titleBarPrimary" className="title-bar-primary-name">{primaryName}</span>
    );
  }

  render() {
    const {sectionName, instanceTypeCode, BarContent, isLoading} = this.props;
    const primaryName = this.getPrimaryName();
    const riskLabel = !isLoading && hasSupportForRisk(instanceTypeCode) ? this.getRiskLabelComponent() : null;
    const processName = this.getProcessName();

    return (
      <div className="title-bar">
        <div className="title-bar-title" id="titleBar">
          <div className="title-bar-primary">
            {this.renderTitleBarPrimaryName(primaryName)}
            {riskLabel ? <span id="titleBarRiskLabel" className="page-title-bar-risk-label">{riskLabel}</span> : ""}
            {processName ?
              <span id="titleBarProcessName" className="page-title-bar-process-name">{processName}</span> : ""}
          </div>
          {this.shouldShowPrimaryName() ? (
            <div className="title-bar-breadcrumbs">
            <span>
              <a id="sectionLinkInTitleBar" href={this.getURLForSection()}>
                {sectionName}
              </a>
              {" > "}
              {primaryName}
            </span>
            </div>
          ) : ""}
        </div>
        {this.props.currentState ? (
          <div id="titleBarStatus" className="title-bar-status">
            [{this.props.currentState}]
          </div>
        ) : ""}
        <div className="bar-content">
          {BarContent}
        </div>
        <div className="title-bar-bottom-border" />
      </div>
    );
  }
}
