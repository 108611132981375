var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { FloatingLabel } from '@progress/kendo-react-labels';
/**
 * @hidden
 */
export var usePickerFloatingLabel = function (dateInput) {
    var _a = React.useState(false), show = _a[0], setShow = _a[1];
    var syncText = function () {
        if (dateInput.current) {
            setShow(Boolean(dateInput.current.text));
        }
    };
    React.useEffect(syncText);
    return {
        editorValue: show
    };
};
/**
 * @hidden
 */
export var PickerFloatingLabel = function (props) {
    var floatingLabelProps = usePickerFloatingLabel(props.dateInput);
    return (React.createElement(FloatingLabel, __assign({}, props, floatingLabelProps)));
};
