/**
 * @hidden
 */
var isPresent = function (value) { return value !== null && value !== undefined; };
/**
 * @hidden
 */
var sameCharsOnly = function (word, character) {
    for (var idx = 0; idx < word.length; idx++) {
        if (word.charAt(idx) !== character) {
            return false;
        }
    }
    return true;
};
/**
 * @hidden
 */
var shuffleData = function (data, splitIndex, defaultItem) {
    var result = data;
    if (defaultItem) {
        result = [defaultItem].concat(result);
    }
    return result.slice(splitIndex).concat(result.slice(0, splitIndex));
};
/**
 * @hidden
 */
var matchText = function (text, word, ignoreCase) {
    if (!isPresent(text)) {
        return false;
    }
    var temp = String(text);
    if (ignoreCase) {
        temp = temp.toLowerCase();
    }
    return temp.indexOf(word) === 0;
};
/**
 * @hidden
 */
var scrollToItem = function (scrollElem, list, itemIndex, translate, virtualScroll) {
    var viewportHeight = scrollElem.offsetHeight;
    var li = list.children.item(itemIndex);
    var itemOffsetTop = li.offsetTop + (virtualScroll ? translate - scrollElem.scrollTop : 0);
    var itemHeight = li.offsetHeight;
    if (virtualScroll) {
        var diff = 0;
        if (itemOffsetTop + itemHeight > viewportHeight) {
            diff = itemOffsetTop + itemHeight - viewportHeight;
        }
        else if (itemOffsetTop < 0) {
            diff = itemOffsetTop;
        }
        if (diff !== 0) {
            scrollElem.scrollTop += diff;
        }
        else if (scrollElem.scrollTop === 0 && translate !== 0) {
            scrollElem.scrollTop = translate;
        }
    }
    else {
        if (itemOffsetTop + itemHeight > viewportHeight + scrollElem.scrollTop) {
            scrollElem.scrollTop = (itemOffsetTop + itemHeight - viewportHeight);
        }
        else if (itemOffsetTop < scrollElem.scrollTop) {
            scrollElem.scrollTop -= scrollElem.scrollTop - itemOffsetTop;
        }
    }
};
/**
 * @hidden
 */
var itemIndexStartsWith = function (items, text, field) {
    var result = -1;
    if (text) {
        text = text.toLowerCase();
        for (var i = 0; i < items.length; i++) {
            var itemText = (getItemValue(items[i], field) || '') + '';
            if (itemText && itemText.toLowerCase().startsWith(text)) {
                result = i;
                break;
            }
        }
    }
    return result;
};
/**
 * @hidden
 */
var getItemIndexByText = function (data, text, textField, matchCase) {
    if (matchCase === void 0) { matchCase = false; }
    var casing = function (value) { return matchCase ? value : value.toLowerCase(); };
    return data.findIndex(function (item) {
        return textField ? casing(getItemValue(item, textField)) === casing(text) :
            casing(text) === casing(item.toString());
    });
};
/**
 * @hidden
 */
var getItemValue = function (item, field) {
    if (field && isPresent(item)) {
        var path = field.split('.');
        var data_1 = item;
        path.forEach(function (p) {
            data_1 = data_1 ? data_1[p] : undefined;
        });
        return data_1;
    }
    return item;
};
/**
 * @hidden
 */
var matchDataCollections = function (data1, data2, key) {
    if (data1 === void 0) { data1 = []; }
    if (data2 === void 0) { data2 = []; }
    if (data1 === data2) {
        return true;
    }
    if (data1.length !== data2.length) {
        return false;
    }
    for (var i = 0; i < data1.length; i++) {
        if (!areSame(data1[i], data2[i], key)) {
            return false;
        }
    }
    return true;
};
/**
 * @hidden
 */
var removeDataItems = function (items, toRemove, key) {
    toRemove.forEach(function (item) {
        var itemIndex = items.findIndex(function (i) { return areSame(i, item, key); });
        if (itemIndex !== -1) {
            items.splice(itemIndex, 1);
        }
    });
};
/**
 * @hidden
 */
var areSame = function (item1, item2, key) {
    return item1 === item2 ||
        (isPresent(item1) === isPresent(item2) && getItemValue(item1, key) === getItemValue(item2, key));
};
/**
 * @hidden
 */
var getFocusedItem = function (data, value, textField) {
    if (value) {
        var selectedIndex = getItemIndexByText(data, value, textField, true);
        return selectedIndex !== -1 ? data[selectedIndex] : data[itemIndexStartsWith(data, value, textField)];
    }
    return data[0];
};
/**
 * @hidden
 */
var suggestValue = function (value, data, textField) {
    if (data === void 0) { data = []; }
    var suggested = '';
    if (value) {
        var suggestedItem = data[itemIndexStartsWith(data, value, textField)];
        if (suggestedItem) {
            var suggestedText = getItemValue(suggestedItem, textField);
            if (value.toLowerCase() !== suggestedText.toLowerCase()) {
                suggested = suggestedText.substring(value.length);
            }
        }
    }
    return suggested;
};
/**
 * @hidden
 */
var preventDefaultNonInputs = function (event) {
    if (event.target.nodeName !== 'INPUT') {
        event.preventDefault();
    }
};
/**
 * @hidden
 */
var matchTags = function (tag1, tag2, key) {
    if (!!tag1 !== !!tag2 || tag1.text !== tag2.text) {
        return false;
    }
    return tag1 === tag2 || matchDataCollections(tag1.data, tag2.data, key);
};
export { isPresent, sameCharsOnly, shuffleData, matchText, scrollToItem, itemIndexStartsWith, getItemIndexByText, getItemValue, matchDataCollections, removeDataItems, areSame, getFocusedItem, preventDefaultNonInputs, suggestValue, matchTags };
