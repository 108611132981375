.record-header-back-button {
  width: 1rem;
  height: 1rem;
}

.widget-type-header {
  padding-left: 0.9375rem;
}

.widget-info-header {
  padding-left: 3.5rem;
}
