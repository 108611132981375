"use strict";
// The functions in this file are responsible for generating random questions for Menta.
import {Log, LOG_GROUP} from "../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.ClientServices, "ExampleQuestionGenerator");

const STATIC_QUESTIONS: string[] = [
  "What is this?",
  "What types of buffers are useful for a cell therapy project?",
  "Do you have access to my company data?",
  "What are the biggest bottlenecks in drug discovery and development?",
  "What is involved in Pharma Tech Transfer?",
  "How can digitization speed pharmaceutical drug discovery and development?",
];

const ACRONYMS: string[] = [
  "PR",
  "FQA",
  "CQA",
  "COA",
  "FPA",
  "UO",
  "STP",
  "PRC",
  "MT",
  "PP",
  "CPP",
  "MA",
  "IQA",
  "IPA",
  "CM",
];

// Asking for processes or steps (without UOs) doesn't make sense. It often gets FPA/IPAs wrong (returning FQAs/IQAs) so I took it out too.
const ACRONYMS_FOR_PROJECTS: string[] = ACRONYMS.filter(acronym => !["PR", "STP", "FPA", "IPA"].includes(acronym));

const MODALITIES: string[] = [
  "mRNA",
  "gene therapy",
  "cell therapy",
  "small molecule",
  "A-mab",
  "biologics",
  "vaccine",
  "biosimilars",
  "Peptides",
  "Liposomes",
  "Nanoparticles",
  "Oligonucleotides",
];

const STRANGE_WORDS: string[] = [
  "Afucosylation",
  "Aggregation",
  "Emulsion",
  "Galactose Content",
  "Granulation",
  "Homogenization",
  "Immunogenicity",
  "Lyophilization",
  "PK/PD",
  "Sialylation",
  "Sterilization",
  "a Buffer",
  "a Substrate",
  "an API",
  "an Ampoule",
  "an Excipient",
];
/**
 * This class creates a random set of questions to give users ideas of things they can ask Menta.
 */
export default class ExampleQuestionGenerator {
  private allQuestions: string[];

  constructor() {
    this.allQuestions = [...STATIC_QUESTIONS];
    for (const acronym of ACRONYMS) {
      this.allQuestions.push(`What is a ${acronym}?`);
    }
    for (const modality of MODALITIES) {
      this.allQuestions.push(`Can you give me some advice for a ${modality} project?`);
    }
    for (const strangeWord of STRANGE_WORDS) {
      this.allQuestions.push(`What is ${strangeWord}?`);
    }
    // This generates too many questions, so we just randomly add some of them.
    const avgNumOfOtherTypesOfQuestions = (MODALITIES.length + STRANGE_WORDS.length + ACRONYMS.length) / 3;
    for (let i = 0; i < avgNumOfOtherTypesOfQuestions; i++) {
      const acronym = ACRONYMS_FOR_PROJECTS[this.getRandomIndexInArray(ACRONYMS_FOR_PROJECTS)];
      const modality = MODALITIES[this.getRandomIndexInArray(MODALITIES)];
      this.allQuestions.push(`What are some sample ${acronym}s for a ${modality} project?`);
    }
    // This generates too many questions, so we just randomly add some of them.
    for (let i = 0; i < avgNumOfOtherTypesOfQuestions; i++) {
      const acronym = ACRONYMS_FOR_PROJECTS[this.getRandomIndexInArray(ACRONYMS_FOR_PROJECTS)];
      const modality = MODALITIES[this.getRandomIndexInArray(MODALITIES)];
      this.allQuestions.push(`Can you make a table of ${acronym}s for a ${modality} project?`);
    }
    Logger.info(() => `Generated ${this.allQuestions.length} questions...`);
  }

  /**
   * Generate questions!
   * @param numOfQuestions {number} The number of example questions requested.
   * @return {[string]} An array of questions.
   */
  generateQuestions(numOfQuestions: number): string[] {
    let generatedQuestions: string[] = [];
    let i = 0;
    while (i < numOfQuestions) {
      const randomQuestionIndex = this.getRandomIndexInArray(this.allQuestions);
      const randomQuestion = this.allQuestions[randomQuestionIndex];
      Logger.info(() => `Considering: ${randomQuestion}`);
      if (generatedQuestions.includes(randomQuestion)) {
        continue;
      }
      generatedQuestions.push(randomQuestion);
      i++;
    }
    return generatedQuestions;
  }

  private getRandomIndexInArray(array: string[]) {
    return Math.min(Math.floor(Math.random() * array.length), array.length - 1);
  }
}
