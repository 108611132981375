"use strict";
import React from "react";
import BaseAttribute from "./base_attribute";
import { createHTMLForTextDiff } from "../../helpers/diff_helper";
import * as UIUtils from "../../ui_utils";

export default class RadioAttribute extends BaseAttribute {
  constructor(props) {
    super(props);
  }

  // noinspection JSMethodCanBeStatic
  getInitialValue() {
    return this.props.default ? this.props.default : this.props.options[0];
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      let value = this.getNextValue(nextProps);
      return (this.currentValue !== !!value);
    }
    return true;
  }

  getOptionId(optionValue) {
    let camelCaseOptionValue = optionValue.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g,
      match => {
        if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
        return match.toUpperCase();
      });

    camelCaseOptionValue = UIUtils.stripSpecialChars(camelCaseOptionValue);
    return this.props.name + camelCaseOptionValue + "Radio";
  }

  getInputId() {
    return this.props.name + "Radio";
  }

  renderInput() {
    let input;
    let inputId = this.getInputId();

    if (this.isView()) {
      let value = this.getValue() ? this.getValue() : "";
      if (this.isDiffingVersions()) {
        let oldValue = this.getOldValue(this.props.name);
        input = createHTMLForTextDiff(oldValue, value);
      } else {
        input = (<span id={inputId}>{value}</span>);
      }
    } else {
      let value = this.getValue() ? this.getValue() : this.props.options[0];
      this.currentValue = value;

      input = this.props.options.map(optionValue =>
        <label id={this.getOptionId(optionValue) + "Label"} key={this.getOptionId(optionValue)}
               className="radio-button-container"
        >
          <input type="radio" value={optionValue} key={optionValue}
                 id={this.getOptionId(optionValue)}
                 checked={optionValue === value ? "checked" : ""}
                 disabled={this.isDisabled()}
                 onChange={this.handleChange}
          />
          {optionValue}
        </label>
      );
    }

    return input;
  }
}
