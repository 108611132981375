import React from "react";
import {ToolProps} from "../common/types";
import {EditorToolsSettings, EditorUtils} from "@progress/kendo-react-editor";
import {SplitButton, SplitButtonItemClickEvent,} from "@progress/kendo-react-buttons";
import {findNodePosition} from "../utils";
import {NumberedListOption} from "../common/constants";
import {isInWidget} from "../common/editorSchemas/widget_node";
import * as styles from "./numbered_list_tool.module.scss";

type NumberedListProps = {
  selectedNodePos: number | null;
};

export default function NumberedList(
  props: ToolProps & NumberedListProps
): React.ReactElement {
  const {view, selectedNodePos} = props;

  let targetNode = null;
  if (view) {
    const anchor = view.state.selection.$anchor;
    for (let d = anchor.depth; d > 0; d--) {
      const currentNode = anchor.node(d);
      if (currentNode.type && currentNode.type.name === "ordered_list") {
        targetNode = currentNode;
        break;
      }
    }
  }

  const onButtonClick = () => {
    let listAttrs = {};
    // We will set dynamiclist by default if it is in a widget
    if (isInWidget(view)) {
      listAttrs = {dynamiclist: "true"};
    }
    toggleList(listAttrs);
  };

  const onItemClick = (event: SplitButtonItemClickEvent) => {
    let {tr} = view.state;
    if (selectedNodePos) {
      tr = tr.setNodeAttribute(selectedNodePos, "selected", "false");
    }

    if (
      event.item.key === NumberedListOption.Continuous ||
      event.item.key === NumberedListOption.PrefixNearest
    ) {
      const resetKey =
        event.item.key === NumberedListOption.Continuous
          ? NumberedListOption.PrefixNearest
          : NumberedListOption.Continuous;
      const value =
        targetNode && targetNode.attrs[event.item.key] === "true"
          ? "false"
          : "true";
      findNodePosition(view, targetNode, (pos) => {
        if (pos !== -1) {
          view.dispatch(
            tr
              .setNodeAttribute(pos, event.item.key, value)
              .setNodeAttribute(pos, resetKey, "false")
          );
          view.focus();
        }
      });
      return;
    }

    let listAttrs = {};
    if (event.item.key === NumberedListOption.DynamicList) {
      listAttrs = {dynamiclist: true};
      if (targetNode && targetNode.attrs[event.item.key] !== "true") {
        findNodePosition(view, targetNode, (pos) => {
          if (pos !== -1) {
            view.dispatch(tr.setNodeAttribute(pos, event.item.key, "true"));
          }
        });
        return;
      }
    }

    if (event.item.key === NumberedListOption.StaticList) {
      if (targetNode && targetNode.attrs.dynamiclist === "true") {
        findNodePosition(view, targetNode, (pos) => {
          if (pos !== -1) {
            view.dispatch(tr.setNodeAttribute(pos, "dynamiclist", "false"));
          }
        });
        return;
      }
    }

    toggleList(listAttrs);
  };

  const toggleList = (listAttrs: Record<string, string> = {}) => {
    EditorUtils.toggleList(
      view,
      {
        // @ts-ignore
        listAttrs,
        ...EditorToolsSettings.orderedList.types,
        listType: EditorToolsSettings.orderedList.listType,
      },
      EditorToolsSettings.orderedList.commandName
    );
    view.focus();
  };

  const items = [];
  // If the list is not in widget and static, it doesn't have those options
  if (
    selectedNodePos &&
    (!isInWidget(view) || targetNode?.attrs?.dynamiclist === "true")
  ) {
    items.push(
      {
        key: NumberedListOption.Continuous,
        text: "Continue numbering",
        description: "Continue numbering from the previous list"
      },
      {
        key: NumberedListOption.PrefixNearest,
        text: "Start multilevel numbering",
        description: "Start numbering at the level below the previous list. For example, if the previous list ends at 3, this tool will start numbering at 3.1"
      }
    );
  }

  return (
    <SplitButton
      title="Numbered List"
      icon={EditorToolsSettings.orderedList.props.icon}
      items={items}
      itemRender={NumberedListOptionItem}
      onButtonClick={onButtonClick}
      onItemClick={onItemClick}
    />
  );
}

const NumberedListOptionItem = (option: any, props: any) => {
  const {item, itemIndex} = option;
  return (
    <div className={styles["numbered-list-option-item"]} key={itemIndex} {...props} title={item.text}>
      <div>{item.text}</div>
      <div><small className="text-wrap">{item.description}</small></div>
    </div>
  );
};
