"use strict";

import * as UIUtils from "../ui_utils";
import React, { Fragment } from "react";
import BasePage from "../base_page";
import EditablesPageTitleBar from "../widgets/pageTitleBar/editables_page_title_bar";
import NavBar from "../widgets/bars/nav_bar";
import ErrorBar from "../widgets/bars/error_bar";
import Heading from "../widgets/headers/heading";
import ProjectSummary from "../projects/project_summary";
import DraftsPendingTable from "./proposal/drafts_pending_table";
import ApprovalsPendingTable from "./approval/approvals_pending_table";
import { DATATABLES_DOM_WITH_SEARCH_AND_BUTTONS } from "../widgets/tables/base_table";
import * as FailHandlers from "../utils/fail_handlers";
import { can } from "../utils/ui_permissions";
import CommonSecurity from "../../server/common/generic/common_security";
import { getURLByTypeCodeAndId } from "../helpers/url_helper";
import { NewRmpWarning } from "../projects/components/new_rmp_warning";


/* This class shows the project dashboard. */
class ProjectDashboardPage extends BasePage {

  constructor(props) {
    super(props);

    UIUtils.setLoadingDisabled(true);
    this.projectId = UIUtils.getParameterByName("projectId");
    this.state = {project: {id: this.projectId}, isLoading: true};

    UIUtils.secureAjaxGET("projects/dashboard/" + this.projectId, undefined, true, FailHandlers.defaultStopLoadingFailFunction.bind(this))
      .done(this.handleReceiveDataFromServer);

    this.currentUserId = UIUtils.getUserId();
  }

  componentDidMount() {
    document.title = "QbDVision Project Dashboard";

    super.componentDidMount();
  }

  handleReceiveDataFromServer(results) {
    // Uncomment for verbose logging
    // console.log("Received dashboard results: " + UIUtils.stringify(results));
    const projectFromResult = results.project;
    const newRmpVersionAvailable = projectFromResult?.rmpVersionId && projectFromResult.RMP?.LastApprovedVersionId > projectFromResult.rmpVersionId;

    this.setStateSafely({...results, newRmpVersionAvailable, isLoading: false});

    let project = {typeCode: "PRJ", id: this.projectId, name: this.state.project.name};
    this.setStateSafely({
      breadcrumb: {
        current: UIUtils.getRecordCustomLabelForDisplay(project),
        parent: "Projects",
        parentLink: "/projects/list.html",
      },
    });
  }

  formatApprovalsPending(approvalsPending) {
    for (let approval of approvalsPending || []) {
      if (UIUtils.parseInt(approval.userId) !== UIUtils.parseInt(this.currentUserId)) {
        approval.disabled = true;
        approval.disabledReason = "You are not listed among the approvers for this requirement.";
      }
    }

    return approvalsPending;
  }

  renderPage() {
    return (
      <div>
        <EditablesPageTitleBar projectId={this.state.project.id}
                               projectName={this.state.project.name}
                               projectDeletedAt={this.state.project.deletedAt}
                               isDemoProject={this.state.project.isDemo}
                               currentState={this.state.project.currentState}
        />
        <NavBar selected="Dashboard" projectId={this.projectId} />
        <div className="container">
          <div id="dashboardHeader" className="mt-2">
            <Heading level={1} className="header-banner">Dashboard</Heading>
          </div>
          <ErrorBar />
          {this.renderWarningBar()}
          <div className="row">
            <div className="col-sm-12">
              <div className="section">
                <ProjectSummary parent={this}
                                cachedData={this.state.project}
                                isLoading={this.state.isLoading}
                                operation="View"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 mb-3">
              <div className={"col-left-white shadow"}>
                <DraftsPendingTable id="draftsPendingTable"
                                    data={this.state.draftsPending}
                                    tableHeader="Drafts Pending"
                                    showSelectedRowsInfo={true}
                                    datatablesDomOptions={DATATABLES_DOM_WITH_SEARCH_AND_BUTTONS}
                                    projectId={this.projectId}
                                    parent={this}
                />
              </div>
            </div>
            <div className="col-12 col-md-6 mb-3">
              <div className="col-right-white shadow">
                <ApprovalsPendingTable id="approvalsPendingTable"
                                       data={this.formatApprovalsPending(this.state.approvalsPending)}
                                       tableHeader="Approvals Pending"
                                       showSelectedRowsInfo={true}
                                       datatablesDomOptions={DATATABLES_DOM_WITH_SEARCH_AND_BUTTONS}
                                       projectId={this.projectId}
                                       parent={this}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderWarningBar() {
    const rmpUrl = getURLByTypeCodeAndId("RMP", "View", this.state.project?.RMPId, true);
    const changeRmpUrl = `/projects/changeRMP.html?projectId=${this.state.project?.id}`;
    const hasEditPermission = can(CommonSecurity.Actions.EDIT, CommonSecurity.Types.PROJECT, this.state);

    if (!hasEditPermission || !this.state.newRmpVersionAvailable) {
      return <Fragment />;
    }

    return (<NewRmpWarning rmpUrl={rmpUrl} changeRmpUrl={changeRmpUrl} />);
  }
}

export default ProjectDashboardPage;