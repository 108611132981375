import { cleanTextBlockFormatting } from './blockNode';
import { cleanMarks } from './mark';
/**
 * A command which removes the inline formatting, class and style attributes of paragraph and heading elements in the selection.
 */
export var cleanFormatting = function (options) {
    if (options === void 0) { options = { blocksInSelection: true }; }
    return function (state, dispatch) {
        var tr = state.tr;
        var blocksInSelection = options.blocksInSelection, blockNodeType = options.blockNodeType, exceptMarks = options.exceptMarks;
        var except = (exceptMarks || [state.schema.marks.link]).filter(Boolean);
        cleanMarks(tr, { except: except });
        cleanTextBlockFormatting(tr, { blocksInSelection: blocksInSelection, blockNodeType: blockNodeType });
        var result = tr.docChanged;
        if (result && dispatch) {
            dispatch(tr);
        }
        return result;
    };
};
