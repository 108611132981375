/**
 * @hidden
 */
export var Keys = {
    backspace: 8,
    tab: 9,
    enter: 13,
    shift: 16,
    esc: 27,
    space: 32,
    pageUp: 33,
    pageDown: 34,
    end: 35,
    home: 36,
    left: 37,
    up: 38,
    right: 39,
    down: 40,
    delete: 46
};
