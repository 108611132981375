"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import { getURLByKey, getURLByTypeCodeAndId } from "../../helpers/url_helper";

import RelatedRecordsPanel from "../../widgets/relatedRecords/related_records_panel";
import ShowMoreText from "../../widgets/generic/show_more_text";
import * as ReactDOM from "react-dom";
import { FormattedDate } from "../../widgets/generic/formatted_date";

/**
 * This is responsible for rendering the related records of an attribute in the view attribute page.
 */
export default class RelatedRetrainingPanel extends RelatedRecordsPanel {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (super.shouldComponentUpdate(nextProps, nextState)) {
      return true;
    }

    return JSON.stringify(nextProps.records) !== JSON.stringify(this.props.records) ||
          JSON.stringify(nextProps.documents) !== JSON.stringify(this.props.documents);
  }

  getTableInitializationOptions() {
    const baseSettings = super.getTableInitializationOptions();
    baseSettings.scrollY = "500px";
    return baseSettings;
  }

  handleRowClick(e) {
    UIUtils.ignoreHandler(e);
  }

  /**
   * Returns the full key of a record given the model name and the record id
   * @param record
   * @returns {string}
   */
  getRecordFullKey(record) {
    return `${UIUtils.getTypeCodeForModelName(this.modelFullName)}-${record.trainingDocumentId}`;
  }

  /**
   * Creates a react element that can be used as a link on the UI and redirect the user to a particular record.
   * @param record The training record used to create the retraining info from.
   * @returns {*}
   */
  generateRecordLink(record) {
    if (!record) {
      return null;
    }
    const {users} = this.props;
    let recordKey = this.getRecordFullKey(record);
    const document = this.getDocumentForRecord(record);

    // Documents may not be loaded on first render, so we just do nothing in such cases.
    if (!document) {
      return null;
    }

    let displayText = UIUtils.getRecordCustomLabelForDisplay(document);
    let id = `${record.trainingDocumentVersionId}`;

    const creatorUser = users.get(record.assignedByUserId) || {firstName: "Unknown", lastName: "User"};

    return <div
      id={`relatedRetraining-${id}`}
      className="related-retraining-panel"
    >
      <div>
        <div>
          <a href={getURLByKey(recordKey, "View", false)}
             target="_blank" rel="noopener noreferrer"
             className="related-retraining-link"
          >
            {displayText}
            {record.isApproved ? (
              <sup className="results-table-approved-flag">approved</sup>
            ) : ""}
          </a>
        </div>
        <div className="related-retraining-date">
          Asked to retrain by <a href={getURLByTypeCodeAndId("USR", creatorUser.id)}>{creatorUser.firstName} {creatorUser.lastName}</a> on <FormattedDate value={record.assignmentDate} />
        </div>
        <div className="related-retraining-justification-title">
          Justification:
        </div>
        <div className="related-retraining-justification-body">
          <ShowMoreText text={record.justification} />
        </div>
      </div>
    </div>;
  }

  getAddNewRecordURL() {
    const {modelName, parent, parentModelName, projectId} = this.props;

    let typeCode = UIUtils.getTypeCodeForModelName(modelName);
    let parentTypeCode = parentModelName ? UIUtils.getTypeCodeForModelName(parentModelName) : "";
    let parentRecordId = parent.state.id;

    let redirectionURL = UIUtils.getSecuredURL(getURLByTypeCodeAndId(typeCode, "Add", projectId));

    if (parentRecordId && parentTypeCode) {
      redirectionURL += "&parentId=" + parentRecordId;
      redirectionURL += "&parentTypeCode=" + parentTypeCode;
      redirectionURL += "&returnURL=" + encodeURIComponent(UIUtils.FRONT_END_URL + getURLByTypeCodeAndId(parentTypeCode, "View", parentRecordId));
    }

    return redirectionURL;
  }

  getColumns() {
    return [
      {
        className: "related-records-table-column",
        data: (item, type) => {
          if (type === "display") {
            return item;
          } else {
            return item.id;
          }
        },
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(this.generateRecordLink(rowData), td);
        },
      }
    ];
  }

  prepareRecordsForDisplay(records) {
    const parentState = this.props.parent.currentState;
    return records.filter(record => parentState?.startsWith(UIUtils.VERSION_STATES.ARCHIVED) || !record.deletedAt).map(record => {
      return record;
    }).sort((a, b) => {
      return (a.isApproved ? -1 : 1) - (b.isApproved ? -1 : 1);
    });
  }

  render() {
    return (
      <div className="related-records-container row-white">
        <div className="related-records-title">
          <div>
            {this.props.header}
          </div>
        </div>
        <div>
          <table ref={ref => this.tableRef = ref}
                 className="table table-hover related-records-table"
                 id={`${this.props.id}Table`}
                 style={{width: "100%"}}
          />
        </div>
      </div>
    );
  }

  getDocumentForRecord(record) {
    let {documents} = this.props;
    documents = documents || [];

    return documents.find(document => document.versionId === record.trainingDocumentVersionId);
  }
}

RelatedRetrainingPanel.defaultProps = {
  modelName: "Document",
  users: new Map(),
};
