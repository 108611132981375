"use strict";

/*
  Common constants used for the batches in both client and server side.
 */
const decimal = require("decimal.js");
const {isQualitativeMeasure} = require("./common_editables");
const {isQuantitativeMeasure} = require("./common_editables");
const CommonMath = require("../generic/common_math");
const CommonUtils = require("../generic/common_utils");
const mathjs = require("mathjs");

const IMPORT_ACTION = {
  APPEND: "Append to batch data",
  REPLACE: "Replace batch data",
};

const BATCH_TYPE_OPTIONS = [
  "Characterization",
  "Commercial",
  "Confirmation",
  "Engineering",
  "GMP",
  "Material Generation",
  "POC",
  "PPQ (Validation)",
  "Registration",
  "Toxicity"
];

const Z_SCORE_PAST_MEASUREMENTS_REQUIRES_NUM = 5;

module.exports.IMPORT_ACTION = IMPORT_ACTION;
module.exports.Z_SCORE_PAST_MEASUREMENTS_REQUIRES_NUM = Z_SCORE_PAST_MEASUREMENTS_REQUIRES_NUM;
module.exports.BATCH_TYPE_OPTIONS = BATCH_TYPE_OPTIONS;

/**
 * This calculates the ratio of defective measurements.
 * @param measurements The qualitative measurements, pass/fail.
 * @param calculatePercentage If set to true, it returns back a percentage instead of a ratio.
 * @returns {number|number}
 */
module.exports.calculateDefectiveProportion = function(measurements, calculatePercentage = false) {
  if (!measurements || measurements.length === 0) {
    return 0;
  }

  let n = measurements.length;
  let defectivesCount = measurements.filter(m => m.toLowerCase() === "fail").length;
  let proportion = CommonUtils.fixToMostSignificantDigit(decimal.div(defectivesCount, n).toNumber(),
    n.toString().length + 1);
  return calculatePercentage ? decimal.mul(proportion, 100).toNumber() : proportion;
};

/**
 * Calculates the min, max, average, sd and defective percentage of the measurements, given an attribute.
 * @param attribute The attribute record.
 * @param measurements The measurements for that record.
 * @param measure The attribute measure.
 */
module.exports.addBatchAttributeAggregateData = function(attribute, measurements, measure) {
  if (isQualitativeMeasure(measure)) {
    attribute.defectivePercentage = `${exports.calculateDefectiveProportion(measurements, true)}%`;
  } else {
    attribute.min = CommonMath.getCorrectedMin(measurements);
    attribute.max = CommonMath.getCorrectedMax(measurements);
    attribute.average = CommonMath.getCorrectedAverage(measurements, true);
    attribute.sd = CommonMath.getCorrectedStd(measurements, true);
  }
};

/**
 * Calculates the total measurements out of limits, given an attribute and it's measurements. If the attribute is not
 * quantitative, then this returns 0.
 * @param attribute The attribute.
 * @param measurements The measurements of the attribute.
 * @returns {number}
 */
module.exports.calculateTotalMeasurementsOutOfLimits = function(attribute, measurements) {
  let totalMeasurementsOFL = 0;

  if (isQuantitativeMeasure(attribute.measure)) {
    let attributeLowerLimit = attribute.lowerLimit || attribute.lsl;
    attributeLowerLimit = attributeLowerLimit && Number(attributeLowerLimit);
    let attributeUpperLimit = attribute.upperLimit || attribute.usl;
    attributeUpperLimit = attributeUpperLimit && Number(attributeUpperLimit);
    const allMeasurementValues = measurements
      .filter(measurement => CommonUtils.isNumber(measurement.value))
      .map(measurement => Number(measurement.value));

    for (let measurement of allMeasurementValues) {
      if (CommonUtils.isNumber(attributeLowerLimit) && measurement < attributeLowerLimit
        || CommonUtils.isNumber(attributeUpperLimit) && measurement > attributeUpperLimit) {
        totalMeasurementsOFL++;
      }
    }
  }

  return totalMeasurementsOFL;
};

/**
 * This calculates the zScore of a value given the past measurements, when the number of past measurements is above 5.
 * @param value The value to calculate the zScore for
 * @param pastMeasurements The past measurements for the attribute the value is measured for
 * @returns {number}
 */
module.exports.calculateZScore = function(value, pastMeasurements) {
  if (pastMeasurements && pastMeasurements.length >= exports.Z_SCORE_PAST_MEASUREMENTS_REQUIRES_NUM) {
    let mean = mathjs.mean(pastMeasurements);
    let std = mathjs.std(pastMeasurements);
    return (Number(value) - mean) / std;
  } else {
    return NaN;
  }
};