/* stylelint-disable-next-line import-notation */
@import "../../../../css/brand_colors";

.direct-scope-record-tree-item:hover {
  background-color: $brand-slight-grey;
  cursor: pointer;
}

:global(#direct-scope-smart-content-tree .tree-leaf.direct-scope-record-tree-item) {
  width: 100%;
  border: none;
  border-radius: 0;
  margin-bottom: 0;
  border-bottom: 1px solid $brand-slight-grey;
}

:global(#direct-scope-smart-content-tree .k-treeview-item .k-treeview-top:has(.direct-scope-group-tree-item)),
:global(#direct-scope-smart-content-tree .k-treeview-item .k-treeview-mid:has(.direct-scope-group-tree-item)),
:global(#direct-scope-smart-content-tree .k-treeview-item .k-treeview-bot:has(.direct-scope-group-tree-item)) {
  border-bottom: 1px solid $brand-slight-grey;
}
