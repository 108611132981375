import * as React from 'react';
import { KEYBOARD_NAV_DATA_ID, KEYBOARD_NAV_DATA_LEVEL } from './constants';
import { TableKeyboardNavigationContext } from './TableKeyboardNavigationContext';
/**
 * @hidden
 */
export var useTableKeyboardNavigation = function (elementId, navigatable) {
    var _a;
    var navigationContext = React.useContext(TableKeyboardNavigationContext);
    if (!navigationContext || !elementId || navigatable === false) {
        return {};
    }
    return _a = {
            tabIndex: navigationContext && navigationContext.activeId && navigationContext.activeId === elementId ? 0 : -1
        },
        _a[KEYBOARD_NAV_DATA_LEVEL] = navigationContext.level,
        _a[KEYBOARD_NAV_DATA_ID] = elementId,
        _a;
};
