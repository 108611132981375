"use strict";

import * as UIUtils from "../../../../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { TEXTRACT_CONFIDENCE_LEVELS } from "../../../../constants/import_constants";
import BaseReactComponent from "../../../../../base_react_component";

const CONFIDENCE_LEVELS_ACKNOWLEDGED_KEY = "SMART_IMPORT_CONFIDENCE_LEVELS_ACKNOWLEDGED";

/**
 * This renders the confidence levels popup panel in the AI import wizard flow
 */
export default class ConfidenceLevelsPanel extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.confidenceTooltipDisplayed = false;
    this.confidenceTooltipAcknowledged = false;
  }

  shouldComponentUpdate(nextPropsIgnore, nextStateIgnore, nextContextIgnore) {
    return false;
  }

  componentDidMount() {
    super.componentDidMount();

    $(this.popover).popover({
      content: this.renderPopupContent,
      sanitizeFn: UIUtils.sanitizePopoverData,
    });

    $(this.popover).on("shown.bs.popover", () => {
      $("#confidenceGotItButton").on("click", () => {
        localStorage.setItem(CONFIDENCE_LEVELS_ACKNOWLEDGED_KEY, "true");
        this.confidenceTooltipAcknowledged = true;
        $(this.popover).popover("hide");
      });
    });

    $(this.popover).on("hidden.bs.popover", function() {
      $("#confidenceGotItButton").off("click");
    });

    this.confidenceTooltipAcknowledged = localStorage.getItem(CONFIDENCE_LEVELS_ACKNOWLEDGED_KEY);
    if (!this.confidenceTooltipAcknowledged && !this.confidenceTooltipDisplayed) {
      clearTimeout(this.tooltipTimeout);
      this.tooltipTimeout = setTimeout(() => {
        $(this.popover).popover("show");
        this.confidenceTooltipDisplayed = true;
      }, 200);
    }
  }

  handlePopoverIconClick() {
    $(this.popover).popover("show");
  }

  renderPopupContent() {
    const confidenceLevels = Object.values(TEXTRACT_CONFIDENCE_LEVELS).sort((a, b) => b.level - a.level);

    return ReactDOMServer.renderToStaticMarkup(
      <div id="confidenceLevelsIconTooltipDiv"
           className="confidence-level-tooltip"
      >
        <div className="confidence-level-tooltip-header">Confidence level</div>
        <div className="confidence-level-tooltip-message">
          These colors define the confidence level of artificial intelligence in reading the data from the uploaded file.
        </div>
        <div className="confidence-level-tooltip-message">
          Text will be highlighted with these colors in the preview, and underlined in the ‘converted to text’ view.
        </div>
        <div>
          {confidenceLevels.map(confidenceLevel => {
            return (
              <div key={confidenceLevel.level}
                   className="confidence-level"
              >
                <div className={`confidence-level-shape confidence-level-${confidenceLevel.color}-shape`} />
                <div className="confidence-level-shape-text">
                  <div className="confidence-level-shape-label">
                    <span>{confidenceLevel.label}</span>
                  </div>
                  <div className="confidence-level-shape-description">
                    <span>{confidenceLevel.description}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {!this.confidenceTooltipAcknowledged ? (
          <div>
            <button className="btn btn-xs btn-secondary"
                    id="confidenceGotItButton"
            >
              Ok, got it!
            </button>
          </div>
        ) : ""}
      </div>
    );
  }

  render() {
    return (
      <span id="confidenceLevelsIcon"
            className="confidence-level-icon"
            data-toggle="popover"
            data-html="true"
            data-trigger="manual"
            data-placement="bottom"
            ref={popover => this.popover = popover}
      >
        <FontAwesomeIcon onClick={this.handlePopoverIconClick} icon={faQuestionCircle} />
      </span>
    );
  }
}
