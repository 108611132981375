"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import PropTypes from "prop-types";
import logo from "../../static/images/logo-full.png";
import ReactDOMServer from "react-dom/server";
import Cookies from "js-cookie";
import { EXPERIMENTS } from "../../helpers/constants/constants";
import { MinifiedHeader } from "./minified_header";
import UserSettings from "../user/user_settings";
import { HeaderPrintInfo } from "./header_print_info";
import BaseReactComponent from "../../base_react_component";
import HeaderBreadcrumb from "./header_breadcrumb";
import CommonURLs from "../../../server/common/generic/common_urls";
import { Auth } from "aws-amplify";
import * as Sentry from "@sentry/react";
import { QBDLink } from "../../utils/withRouter";

/**
 * This is the header that shows "QbDVision" as well as the username & it's dropdown.
 */
export default class CompanyHeader extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      userName: UIUtils.getUserFullName(),
      userId: UIUtils.getUserId(),
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.title !== this.props.title ||
      nextState.userName !== this.state.userName ||
      nextState.userId !== this.state.userId ||
      nextProps.shouldShowArchivedProcesses !== this.props.shouldShowArchivedProcesses ||
      nextProps.renderProcessSelector !== this.props.renderProcessSelector ||
      nextProps.processId !== this.props.processId ||
      nextProps.project?.id !== this.props.project?.id ||
      nextProps.minified !== this.props.minified ||
      JSON.stringify(nextProps.project) !== JSON.stringify(this.props.project) ||
      this.serializeExtraPrintItems(nextProps.extraPrintItems) !== this.serializeExtraPrintItems(this.props.extraPrintItems) ||
      JSON.stringify(nextProps.breadcrumb) !== JSON.stringify(this.props.breadcrumb);
  }

  /**
   * Serialize extra print items ignoring react component embedded in data property
   * @param extraPrintItems
   * @returns {string}
   */
  serializeExtraPrintItems(extraPrintItems) {
    if (!extraPrintItems) {
      return;
    }

    const newObject = {};
    for (let key in extraPrintItems) {
      let data = extraPrintItems[key]["data"];
      const isReactElement = data && React.isValidElement(data);

      newObject[key] = {
        label: extraPrintItems[key]["label"],
        data: !isReactElement ? data : null,
        order: extraPrintItems[key]["order"]
      };
    }

    return JSON.stringify(newObject);
  }

  static handleLogout(e) {
    e.preventDefault();
    UIUtils.clearSessionInfoForLogout();

    const externalIdentityProvider = Cookies.get("IDENTITY_PROVIDER");

    if (externalIdentityProvider) {
      CommonURLs.logoutExternalUser();
    } else {
      Auth.signOut().then(() => {
        window.location.href = UIUtils.getSecuredURL("/index.html");
      });
    }
  }

  componentDidUpdate() {
    const processId = this.props.processId;
    const projectId = this.props.project?.id;
    Sentry.setTag("projectId", projectId);
    Sentry.setTag("processId", processId);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    $("#companyLogoImage").popover("hide");
  }

  componentDidMount() {
    super.componentDidMount();

    const processId = this.props.processId;
    const projectId = this.props.project?.id;
    Sentry.setTag("projectId", projectId);
    Sentry.setTag("processId", processId);

    let license = Cookies.get("LICENSE");
    let releaseTooltip = ReactDOMServer.renderToStaticMarkup(
      <div>
        <div>
          <span className="company-header-version-label">QbDVision Version: </span>
          <span>{UIUtils.getSoftwareVersion()}</span>
        </div>
        <div>
          <span className="company-header-version-label">License: </span>
          <span>{license}</span>
        </div>
      </div>,
    );

    // Make the release tooltip stay around while it is being hovered over, so the user can copy the version.
    // See https://stackoverflow.com/a/19684440/491553
    $("#companyLogoImage").popover({
      trigger: "manual",
      html: true,
      animation: false,
      content: releaseTooltip,
      container: "body",
      sanitizeFn: UIUtils.sanitizePopoverData,
    }).on("mouseenter", function() {
      $("#companyLogoImage").popover("show");
      $(".popover").on("mouseleave", () => {
        $("#companyLogoImage").popover("hide");
      });
    }).on("mouseleave", function() {
      setTimeout(function() {
        if (!$(".popover:hover").length) {
          $("#companyLogoImage").popover("hide");
        }
      }, 300);
    });
  }

  render() {
    // Add the user name & drop down to the top bar
    let companyLogo = (
      <div className={this.props.minified ? "logo-container-minified" : "logo-container"}>
        <div className="logo-container-div">
          <QBDLink to={UIUtils.DEFAULT_RETURN_TO_URL}>
            <img id="companyLogoImage"
                 height={34}
                 src={logo} className="logo-icon"
                 alt="logo"
            />
            <div className={
              "company-header-title"
              + (UIUtils.isExperimentEnabled(EXPERIMENTS.PartyMode) ? " company-header-party-mode" : "")
            }
            >
            </div>
          </QBDLink>
        </div>
      </div>
    );
    let title = this.props.title ? (
      <div id="companyHeaderTitleDiv"
           className="company-header-minified-title"
      >
        {this.props.title}
      </div>
    ) : "";
    let projectKey = this.props.project ? "PRJ-" + this.props.project.id : null;
    let userProfileURL = "/users/viewEdit.html?operation=View&id=" + this.state.userId;

    return (
      <div id="companyHeader"
           className={this.props.minified ? "company-header-minified" : "company-header"}
      >
        <div className={"container-fluid container-fluid-body"}>
          <div className="company-header-container-flex">
            <div className="logo-version-container">
              {companyLogo}
            </div>

            {(this.props.minified) ? (
              <MinifiedHeader project={this.props.project}
                              keyString={projectKey}
                              renderProcessSelector={this.props.renderProcessSelector}
                              onProcessChange={this.props.onProcessChange}
                              shouldShowArchivedProcesses={this.props.shouldShowArchivedProcesses}
                              processId={this.props.processId}
                              title={title ?? this.props.breadcrumb?.current}
              />
            ) : ""}
            {(!this.props.minified) ? (
              <HeaderBreadcrumb breadcrumb={this.props.breadcrumb} />
            ) : ""}
            <UserSettings userName={this.state.userName} minified={this.props.minified} href={userProfileURL} />
            <HeaderPrintInfo userName={this.state.userName} extraPrintItems={this.props.extraPrintItems} />
          </div>
        </div>
      </div>
    );
  }
}

CompanyHeader.propTypes = {
  minified: PropTypes.bool.isRequired,
  title: PropTypes.any,
  project: PropTypes.object,
  breadcrumb: PropTypes.object,
  extraPrintItems: PropTypes.any,
  renderProcessSelector: PropTypes.bool,
};

CompanyHeader.defaultProps = {
  minified: false,
  renderProcessSelector: false,
};
