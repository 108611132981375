"use strict";

import * as React from "react";
import BaseReactComponent from "../../../base_react_component";

export default class ExpandColumnCell extends BaseReactComponent {
  render() {
    const {dataItem, dataIndex, className, style, onExpandChange} = this.props;
    return (
      <td className={`k-hierarchy-cell ${className} ${dataItem.expanded ? "configurable-tables-expanded-header" : ""}`}
          style={style}
      >
        {dataItem.expanded ? (
          <a onClick={onExpandChange.bind(this, this.props)}
             id={`expandButton_${dataIndex}`}
             className="k-icon k-minus"
             href="#"
             tabIndex="-1"
          />
        ) : (
          <a onClick={onExpandChange.bind(this, this.props)}
             id={`expandButton_${dataIndex}`}
             className="k-icon k-plus"
             href="#"
             tabIndex="-1"
          />
        )}
      </td>
    );
  }
}
