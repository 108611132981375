import { ACTION_TO_ICON_ENUM, EMPTY_STRING } from "../../helpers/constants/constants";
import { FROM_LIBRARY_STATUS, LIBRARY_MATERIAL_TYPE_CODE } from "../library_constants";
import React, { Fragment, useState } from "react";
import { createHTMLForTextDiff } from "../../helpers/diff_helper";
import { VERSION_STATES } from "../../../server/common/generic/common_utils";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import Section from "../../editor/widgets/section";
import LibraryOptionButton from "./library_option_button";
import { LibraryHelper } from "../helpers/library_helper";
import { faInfoCircle, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LinkTooltip from "../../widgets/tooltips/link_tooltip";

function isLinked(record) {
  return record?.fromLibrary?.specification && record?.fromLibrary?.status === FROM_LIBRARY_STATUS.LINKED;
}

function isSynced(record) {
  return record?.fromLibrary?.specification && record?.fromLibrary?.status === FROM_LIBRARY_STATUS.SYNCED;
}

function getLabel(record) {
  if (!record) {
    return EMPTY_STRING;
  }

  if (isSynced(record) || isLinked(record)) {
    return (
      <Fragment><strong id="librarySpecificationStatus">Linked</strong> to Library Specification <strong id="librarySpecificationName">{record.fromLibrary?.specification?.name}</strong> of</Fragment>);
  } else {
    return EMPTY_STRING;
  }
}

function getLabelText(record) {
  if (!record) {
    return EMPTY_STRING;
  }

  if (isLinked(record) || isSynced(record)) {
    return `Linked to Library Specification ${record.fromLibrary?.specification?.name} of`;
  } else {
    return "";
  }
}

function buildOptions(record, isDiffingVersions) {
  const options = [];

  if ((isLinked(record) || isSynced(record)) && !isDiffingVersions) {
    options.push({
      title: "Unlink",
      subTitle: <span>Unlink Material Attribute <br /> from the Library.</span>,
      action: ACTION_TO_ICON_ENUM.UNLINK_MATERIAL_ATTRIBUTE,
      id: "unlinkButton",
      onClick: () => LibraryHelper.onUnlinkMaterialAttributeClick(record),
    });
  }

  return options;
}

/**
 * Display the link information with the library specification
 * @returns {JSX.Element || string}
 * @constructor
 */
export const LibrarySpecificationStatusBar = ({record, olderVersion, isDiffingVersions}) => {

  const [showStatus, setShowStatus] = useState(true);


  const {
    isLoading,
    currentState,
    editedSinceLastMaterialAttributeView,
  } = record;

  if (!LibraryHelper.recordHasLibraryAttached(record, olderVersion, isDiffingVersions)) {
    return EMPTY_STRING;
  }

  const fromLibrary = record.fromLibrary ?? {};

  const {
    name,
    instanceId,
    majorVersion,
    minorVersion,
  } = fromLibrary ?? {};

  let nameElement;
  let minorVersionElement;
  let majorVersionElement;
  let fromLibraryStatusElement;

  const allowedStatuses = [
    VERSION_STATES.APPROVED, VERSION_STATES.DRAFT,
    VERSION_STATES.WITHDRAWN, VERSION_STATES.WITHDRAWN_CASCADED,
    VERSION_STATES.REJECTED, VERSION_STATES.REJECTED_CASCADED,
    VERSION_STATES.RESTORED, VERSION_STATES.RESTORED_CASCADED
  ];

  if (isDiffingVersions) {
    fromLibraryStatusElement = createHTMLForTextDiff(getLabelText(olderVersion), getLabelText(record));
    nameElement = createHTMLForTextDiff(olderVersion?.fromLibrary?.name, name);
    minorVersionElement = createHTMLForTextDiff(olderVersion?.fromLibrary?.minorVersion, minorVersion);
    majorVersionElement = createHTMLForTextDiff(olderVersion?.fromLibrary?.majorVersion?.toString(), majorVersion?.toString());
  } else {
    fromLibraryStatusElement = (isLinked(record) || isSynced(record)) ?
      <span>{getLabel(record)}&nbsp;
        <span id="librarySpecificationParentName">{name} v{majorVersion}.{minorVersion}</span>
      </span> :
      <LinkTooltip id="librarySpecificationStatusNotLinked"
                   link={LibraryHelper.getLinkMaterialAttributeUrl(record)}
                   text="Link to Library Specification"
                   tooltipText={`Link to compare Library Specification of
                                 ${name} (v${majorVersion}.${minorVersion})
                                 to Material Attribute Acceptance Criteria.`}
      />;
  }

  const options = buildOptions(record, isDiffingVersions);
  return (
    <div className="library-status-bar-outer-div">
      <Section>
        <div className={"row library-status-bar" + (isLoading ? " skeleton" : "")}>
          <span>{fromLibraryStatusElement} </span>
          {isDiffingVersions ? <span className="ml-1 font-weight-bold">
              <a href={getURLByTypeCodeAndId(LIBRARY_MATERIAL_TYPE_CODE, "View", instanceId, true)}
                 rel="noreferrer noopener"
                 target="_blank"
                 id="librarySpecificationParentName"
                 className="view-library-material-cell"
              >
              {nameElement} v{majorVersionElement}.{minorVersionElement}
                </a>
            </span> : ""}
          {allowedStatuses.includes(currentState) && options.length > 0 ?
            <LibraryOptionButton key="libraryOptionsButton"
                                 id="libraryOptionsButton"
                                 options={options}
            /> : EMPTY_STRING}
        </div>
        {renderInfoBar(editedSinceLastMaterialAttributeView, showStatus, setShowStatus)}
      </Section>
    </div>);
};

const renderInfoBar = (isEdited, showStatus, setShowStatus) => {
  if (isEdited === "Yes" && showStatus) {
    return <div className={"row alert alert-info d-flex justify-content-between library-spec-status"}>
      <div>
        <FontAwesomeIcon icon={faInfoCircle}
                         className="library-status-info-icon library-spec-status-info-icon"
                         id="libraryStatusInfoIcon"
        />
        <span>{"The Library Specification has been updated since this Material Attribute record was last viewed."}</span>
      </div>
      <FontAwesomeIcon icon={faClose}
                       className={"library-spec-status-close-btn"}
                       id="closeInfoIcon"
                       onClick={() => setShowStatus(!showStatus)}
      />
    </div>;
  } else {
    return <Fragment />;
  }
};
