"use strict";


import * as UIUtils from "../../../ui_utils";
import CommonUtils from "../../../../server/common/generic/common_utils";


/**
 * Make different items in the related panel for the material that is linked to multiple UnitOperations or Steps.
 * @param material
 */
function copyMaterialAcrossLocations(material) {

  const materialCopies = [];
  const {UnitOperations, Steps} = material;

  for (let Step of Steps) {
    let {UnitOperation} = Step;
    materialCopies.push(
      {
        ...material,
        Step,
        UnitOperation,
      }
    );
  }

  for (let UnitOperation of UnitOperations) {
    if (!Steps.find(step => step.UnitOperationId === UnitOperation.id)) {
      materialCopies.unshift(
        {
          ...material,
          UnitOperation
        }
      );
    }
  }

  if (
    (!UnitOperations && !Steps)
    ||
    (CommonUtils.isEmptyArray(UnitOperations) && CommonUtils.isEmptyArray(Steps))
  ) {
    materialCopies.push(material);
  }

  return materialCopies;

}

/**
 * Change from DB object to a UI object. An object that can be rendered by the component...
 * @param records {Material[]}
 */
export function mapToListRecordData(records) {

  const materials = new Map();
  const projects = new Map();
  const processes = new Map();

  for (let record of records) {

    const {ProjectId, ProcessId, Process, Project} = record;
    const key = `${ProjectId}-${ProcessId}`;

    if (materials.has(key)) {
      materials.get(key).push(
        ...copyMaterialAcrossLocations(record)
      );
    } else {
      materials.set(key, [...copyMaterialAcrossLocations(record)]);
      projects.set(ProjectId, Project);
      processes.set(ProcessId, Process);
    }
  }

  const newRecords = [];
  for (const [key, value] of materials) {

    const [
      projectId,
      processId
    ] = key.split("-").map(id => UIUtils.parseInt(id));

    const project = projects.get(projectId);
    const process = processes.get(processId);
    newRecords.push(
      {
        project,
        process,
        materials: value,
      }
    );
  }

  return newRecords;
}