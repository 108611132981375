"use strict";

import * as UIUtils from "../ui_utils";
import { can, generateTooltip } from "../utils/ui_permissions";
import CommonSecurity from "../../server/common/generic/common_security";
import * as CommonConstants from "../../server/common/generic/common_constants";

export const ALL_TYPES_FOR_TYPEAHEADS = [
  "Batch",
  "Project",
  "Process",
  "FQA",
  "FPA",
  "UnitOperation",
  "Material",
  "ProcessComponent",
  "IQA",
  "IPA",
  "MaterialAttribute",
  "ProcessParameter",
  "ControlMethod",
  "Step",
  "Supplier",
  "LibraryMaterial",
];

export const PROCESS_BAR_PANELS = {
  ABOUT: "about",
  TREE: "tree",
  TABLE: "table",
};

export const VERSION_STATES_THAT_DO_NOT_CASCADE = [
  UIUtils.VERSION_STATES.ROUTING,
  UIUtils.VERSION_STATES.APPROVED,
  UIUtils.VERSION_STATES.PROPOSED,
];

/**
 * This constant contains the type codes used by the Process Explorer.
 *
 * @type {{PROJECT: string, PROCESS: string, IQA: string, UNIT_OPERATION: string, PROCESS_COMPONENT: string, MATERIAL: string, PROCESS_PARAMETER: string, MATERIAL_ATTRIBUTE: string}}
 */
export const TYPE_CODE = CommonConstants.TYPE_CODE;

export const PROCESS_LEVEL_MODEL_TYPES = [
  "Unit Operations",
  "Steps",
  "Process Parameters",
  "Material Attributes",
  "Process Components",
  "Materials",
  "IQAs",
  "IPAs"
];

export const ADD_PROCESS_MODEL_TYPES = [
  {
    value: "Unit Operation",
    key: "addUnitOperation",
    disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.UNIT_OPERATION),
    title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.UNIT_OPERATION),
  },
  {
    value: "Step",
    key: "addStep",
    disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.STEP),
    title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.STEP),
  },
  {
    value: "Process Parameter",
    key: "addProcessParameter",
    disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.PROCESS_PARAMETER),
    title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.PROCESS_PARAMETER),
  },
  {
    value: "Material Attribute",
    key: "addMaterialAttribute",
    disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.MATERIAL_ATTRIBUTE),
    title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.MATERIAL_ATTRIBUTE),
  },
  {
    value: "Process Component",
    key: "addProcessComponent",
    disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.PROCESS_COMPONENT),
    title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.PROCESS_COMPONENT),
  },
  {
    value: "Material",
    key: "addMaterial",
    disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.MATERIAL),
    title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.MATERIAL),
  },
  {
    value: "IQA",
    key: "addIntermediateQualityAttribute",
    disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.IQA),
    title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.IQA),
  },
  {
    value: "IPA",
    key: "addIntermediatePerformanceAttribute",
    disabled: !can(CommonSecurity.Actions.ADD, CommonSecurity.Types.IPA),
    title: generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.IPA),
  }
];

export const TYPE_CODE_TO_BORDER_COLOR = new Map([
  [TYPE_CODE.PROCESS, "#DBE1E4"],
  [TYPE_CODE.MATERIAL, "#CAEEFC"],
  [TYPE_CODE.PROCESS_COMPONENT, "#D0EBFF"],
  [TYPE_CODE.MATERIAL_ATTRIBUTE, "#CAEEFC"],
  [TYPE_CODE.PROCESS_PARAMETER, "#9FD7F5"],
  [TYPE_CODE.UNIT_OPERATION, "#FEF5B2"],
  [TYPE_CODE.STEP, "#FEF5B2"],
  [TYPE_CODE.STEP, "#FEF5B2"],
  [TYPE_CODE.IQA, "#E3E4E6"],
  [TYPE_CODE.IPA, "#E3E4E6"],
  [TYPE_CODE.IA, "#E3E4E6"],
  [TYPE_CODE.IQA, "#E3E4E6"],
  [TYPE_CODE.IPA, "#E3E4E6"],
  [TYPE_CODE.FA, "#B8B9BA"],
]);

export const TYPE_CODE_TO_BACKGROUND_COLOR = new Map([
  [TYPE_CODE.PROCESS, "#f5f5f5"],
  [TYPE_CODE.MATERIAL, "#F0FBFC"],
  [TYPE_CODE.PROCESS_COMPONENT, "#E3F6FF"],
  [TYPE_CODE.MATERIAL_ATTRIBUTE, "#D1EAFF"],
  [TYPE_CODE.PROCESS_PARAMETER, "#C4E4F5"],
  [TYPE_CODE.UNIT_OPERATION, "#FEFBE5"],
  [TYPE_CODE.STEP, "#FEFBE5"],
  [TYPE_CODE.IA, "#EEF0F1"],
  [TYPE_CODE.IQA, "#EEF0F1"],
  [TYPE_CODE.IPA, "#EEF0F1"],
  [TYPE_CODE.FA, "#D5D7D8"],
]);

export const TYPE_CODE_TO_HEADER_BACKGROUND_COLOR = new Map([
  [TYPE_CODE.MATERIAL, "#E5F5FC"],
  [TYPE_CODE.PROCESS_COMPONENT, "#E5F5FC"],
  [TYPE_CODE.MATERIAL_ATTRIBUTE, "#C2DFEE"],
  [TYPE_CODE.PROCESS_PARAMETER, "#A4C9DC"],
  [TYPE_CODE.UNIT_OPERATION, "#FDF7C8"],
  [TYPE_CODE.IA, "#74787A"],
  [TYPE_CODE.FA, "#4F5152"],
]);

