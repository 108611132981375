import { undo, redo } from '@progress/kendo-editor-common';
import { createProseMirrorTool } from './proseMirrorTool';
/**
 * @hidden
 */
export var HistoryToolNS;
(function (HistoryToolNS) {
    /**
     * @hidden
     */
    HistoryToolNS.createUndoTool = function (settings) { return createProseMirrorTool(settings, undo); };
    /**
     * @hidden
     */
    HistoryToolNS.createRedoTool = function (settings) { return createProseMirrorTool(settings, redo); };
})(HistoryToolNS || (HistoryToolNS = {}));
