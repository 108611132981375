var _a;
/**
 * @hidden
 */
export var optionalText = 'stepper.optionalText';
/**
 * @hidden
 */
export var messages = (_a = {},
    _a[optionalText] = '(Optional)',
    _a);
