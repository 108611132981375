"use strict";

import * as UIUtils from "../ui_utils";
import TableWithCheckboxes from "../widgets/tables/table_with_checkboxes";

/**
 * This is the base table used for bulk approval/proposal review and results tables.
 */
export default class BulkOperationTableBase extends TableWithCheckboxes {
  constructor(props) {
    super(props);
    this.shouldSelectAlloptions = true;
    this.shouldBeSelected = [];
    this.selectableRows = [];
  }

  getColumnsDefs() {
    let selectAllCheckboxId = this.props.id + "selectAllCheckbox";

    let checkboxesColumnDef = {
      orderable: false,
      targets: 0,
      createdCell: function(td, cellData, rowData, row) {
        $(this.api().row(td).node().childNodes[0].lastChild).addClass(`rowIndex-${row}`);

        if (rowData.disabled) {
          this.api().cell(td).checkboxes.disable();
          $(this.api().row(td).node()).prop("class", "disabled-bulk-approval-row");
          if (rowData.disabledReason) {
            $(this.api().cell(td).node()).prop("title", rowData.disabledReason);
          }
        } else {
          this.api().cell(td).checkboxes.enable();
          $(this.api().row(td).node()).removeClass("disabled-bulk-approval-row");
        }
      },
      checkboxes: {
        selectAllRender: () => {
          return "<input id=\"" + selectAllCheckboxId + "\" type=\"checkbox\" class=\"datatables-select-all-checkbox\">";
        },
        selectAll: true,
      }
    };

    return this.props.hideCheckBoxes ? [] : [checkboxesColumnDef];
  }

  handleSelectedRowsChanged() {
    // Do nothing
  }

  reloadDataTable() {
    if (this.tableRef) {
      const table = $(this.tableRef).DataTable();
      const selectAllCheckbox = $("#" + this.props.id + "selectAllCheckbox");

      this.shouldBeSelected = this.shouldSelectAlloptions ? this.props.data : this.getSelectedRows().toArray();
      this.selectableRows = this.props.data.filter(record => !record.disabled)
        .map(record => `${UIUtils.getTypeCodeForModelName(record.modelName)}-${record.id}`);
      this.shouldBeSelected = this.shouldBeSelected.filter(record => !record.disabled)
        .map(record => `${UIUtils.getTypeCodeForModelName(record.modelName)}-${record.id}`);
      this.shouldSelectAlloptions = false;

      table.clear();
      table.rows.add(this.props.data);
      table.draw();
      setTimeout(function() {
        for (let row of $(`table#${this.props.id} tbody tr`)) {
          let rowIdx = UIUtils.parseInt(row.children[0].children[0]?.className.match(/rowIndex-(\d+)/)[1]);
          if (rowIdx ===  null || rowIdx === undefined) {
            continue;
          }

          let recordLabel = table.cell(rowIdx, 2).data();
          let checkbox = row.children[0].children[0];
          checkbox.onchange = this.handleRowSelectionChanged;
          checkbox.checked = !checkbox.disabled && this.shouldBeSelected.includes(recordLabel);
        }

        this.selectEligibleRows(this.shouldBeSelected);

        selectAllCheckbox[0].onchange = this.handleRowSelectionChanged;
        selectAllCheckbox.prop("checked", this.selectableRows.length === this.getSelectedRows().length);
        selectAllCheckbox.prop("indeterminate", this.selectableRows.length !== this.getSelectedRows().length && this.getSelectedRows().length > 0);

        UIUtils.setHideLoadingOnAjaxStop(true);
        UIUtils.hideLoadingImage();
      }.bind(this));
    }
  }

  /**
   * This function selects rows in datatables given an array of records.
   * Reference for usage of eq(): https://datatables.net/reference/api/eq()
   * Reference for usage of filter(): https://datatables.net/reference/api/filter()
   * @param rowsToUpdate Array to select rows for in datatables.net
   */
  selectEligibleRows(rowsToUpdate) {
    const table = $(this.tableRef).DataTable();
    let indexes = table.rows().eq(0).filter(function(rowIdx) {
      let label = table.cell(rowIdx, 2).data();
      return rowsToUpdate.includes(label);
    }).toArray();

    /*
    Once the filtered list of labels are ready it can be given to
    rows() as an input parameter and use the select function instead
    of selecting a row by another using .every() which is a performance
    killer.
     */

    table.rows(indexes).select();
  }

  handleRowSelectionChanged(e) {
    const {checked, className} = e.target;
    const table = $(this.tableRef).DataTable();
    const selectAllCheckbox = $("#" + this.props.id + "selectAllCheckbox");

    if (className === "datatables-select-all-checkbox") {
      if (checked) {
        this.selectEligibleRows(this.selectableRows);
      } else {
        table.rows().deselect();
      }
    } else {
      const index = UIUtils.parseInt(className.match(/rowIndex-(\d+)/)[1]);
      if (checked) {
        table.row(index).select();
      } else {
        table.row(index).deselect();
      }

      selectAllCheckbox.prop("checked", this.selectableRows.length === this.getSelectedRows().length);
      selectAllCheckbox.prop("indeterminate", this.selectableRows.length !== this.getSelectedRows().length && this.getSelectedRows().length > 0);
    }
  }
}
