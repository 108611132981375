@import "../../../../css/_brand_colors.scss";

.version-style {
  padding: 2px 4px;
  color: $brand-dark-grey;
  text-align: right;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.002px;
}

.approved {
  border-radius: 4px;
  border: 0 solid $brand-slight-grey;
  background: rgba(45, 201, 164, 0.20);
}

.archived {
  text-decoration: line-through;
}