"use strict";

import React from "react";
import { formatField, getFieldClass } from "../tech_transfer_column_value_generator";
import ConfigurableTablesColumnOperationsBar
  from "../../configurableTables/columnOperations/configurable_tables_column_operations_bar";

export default class TechTransferColumnOperationsBar extends ConfigurableTablesColumnOperationsBar {
  getFormattedField(field) {
    const {fromProcess, toProcess} = this.props;
    return fromProcess && toProcess ? formatField(field, fromProcess, toProcess) : field;
  }

  getSummaryTitleSuffix() {
    return "Comparisons";
  }

  getFieldClass(field) {
    return getFieldClass(field);
  }
}
