"use strict";

import React from "react";
import BaseHorizontalCollapsiblePanel from "./base_horizontal_collapsible_panel";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

/**
 * This is the base class for the legend on the Process Flow Map and the Risk Map reports.
 * @abstract
 */
export default class BaseLegend extends BaseHorizontalCollapsiblePanel {
  constructor(props) {
    super(props, "legend");
  }

  getIcon() {
    return faQuestionCircle;
  }
}
