"use strict";

import React from "react";
import BaseReactComponent from "../../base_react_component";

/**
 * This class is responsible for showing a banner when user can't access a set of reports
 */
export default class UpgradeNowBanner extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="upgradeNowBanner" className="upgrade-now-banner-container col">
        <span className="upgrade-now-banner-container-text">
          Upgrade now to see the reports below.&nbsp;
          <a rel="noopener noreferrer" target="_blank" href="https://qbdvision.com/products">Contact sales.</a>
        </span>
      </div>
    );
  }
}
