import { BaseDataTransform } from "../base_data_transform";
import { isDraft } from "../../canned_reports/canned_report_helper";
import * as CommonEditablesFormatter from "../../../../server/common/editables/common_editables_formatter";
import { RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import { isCritical } from "../../../helpers/risk_helper";
import { REPORT_DRAFT_ENUM } from "../../constants/report_constants";
import { ProcessParameterIndexer } from "../../../processExplorer/indexers/process_parameter_indexer";

export class UOSTPControlStrategyTransform extends BaseDataTransform {

  get type() {
    return "unitOperationControlStrategy";
  }

  // eslint-disable-next-line no-unused-vars
  shouldRun(options) {
    return true;
  }

  // eslint-disable-next-line no-unused-vars
  transform(input, options) {
    const scopeToImpactedCQAsKPAs = new Map();
    const result = { ...input };
    const { rmpVersions } = options;
    const rmp = rmpVersions[0];
    const lastUnitOperationVersion =
      result.instances.unitOperations[result.instances.unitOperations.length - 1];
    const uo = {
      ...result.instances.uos[0],
      majorVersion: lastUnitOperationVersion.majorVersion,
      minorVersion: lastUnitOperationVersion.minorVersion,
      draftMarker: isDraft(lastUnitOperationVersion)
        ? REPORT_DRAFT_ENUM.DraftMarker
        : "",
    };

    const mas = result.instances.mas
      .filter((ma) => isCritical(ma, rmp, "MA"))
      .map((ma) => this.fillGeneralInformation(ma, rmp, "MA"))
      .map((ma) =>
        this.fillImpactedCQAsKPAs(
          ma,
          options,
          ma.MaterialAttributeToFQAs,
          result.instances.fqas,
          result.instances.iqas,
          scopeToImpactedCQAsKPAs,
          "MaterialAttribute"
        )
      );

    const processParameterOrder = JSON.parse(uo.RecordOrder?.processParameterOrder || "[]");
    for (const step of result.instances.steps) {
      if (step.RecordOrder && step.RecordOrder.processParameterOrder) {
        processParameterOrder.push(...JSON.parse(step.RecordOrder.processParameterOrder));
      }
    }
    const pps = ProcessParameterIndexer
      .sortRecordsBasedOnIDArray(processParameterOrder, result.instances.pps)
      .filter((pp) => isCritical(pp, rmp, "PP"))
      .map((pp) => this.fillGeneralInformation(pp, rmp, "PP"))
      .map((pp) =>
        this.fillImpactedCQAsKPAs(
          pp,
          options,
          pp.ProcessParameterToFQAs,
          result.instances.fqas,
          result.instances.iqas,
          scopeToImpactedCQAsKPAs,
          "ProcessParameter"
        )
      );

    const iqas = result.instances.iqas
      .filter((iqa) => iqa.unitOperationId === uo.id)
      .filter((iqa) => isCritical(iqa, rmp, "IQA"))
      .map((iqa) => this.fillGeneralInformation(iqa, rmp, "IQA"))
      .map((iqa) =>
        this.fillImpactedCQAsKPAs(
          iqa,
          options,
          iqa.IQAToFQAs,
          result.instances.fqas,
          result.instances.iqas,
          scopeToImpactedCQAsKPAs,
          "IQA",
        )
      );

    const steps = result.instances.steps.map((step) => {
      return {
        ...step,
        impactedCQAsKPAs: (scopeToImpactedCQAsKPAs.get(step.name) || [])
          .sort()
          .map((value) => `• ${value}`)
          .join("\n"),
      };
    });

    return {
      ...result,
      id: uo.id,
      uo: {
        ...uo,
        impactedCQAsKPAs: Array.from(
          new Set(scopeToImpactedCQAsKPAs.get("Global") || [])
        )
          .sort()
          .map((value) => `• ${value}`)
          .join("\n"),
      },
      instances: {
        ...result.instances,
        steps,
        mas: this.groupByStep(mas),
        pps: this.groupByStep(pps),
        iqas: this.groupByStep(iqas),
      },
    };
  }


  fillGeneralInformation(record, rmp, modelName) {
    if (!record.riskInfo) {
      throw new Error("Missing RiskInfo");
    }

    const rawRiskScore = record.riskInfo[RISK_TYPE_ENUM.CRITICALITY].value;
    const riskScale = rawRiskScore ? record.riskInfo[RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel : null;
    const acceptanceCriteriaRanges =
      record?.Requirement?.AcceptanceCriteriaRanges.map(
        (ac) =>
          `${ac.group}:${
            ac.label
          }: ${CommonEditablesFormatter.getAttributeMeasurementInfoTechTransferFormat(
            { ...ac, measure: record.measure }
          )}`
      );

    let name = record.name;
    if (record.Material) {
      name = `${record.Material.name} - ${record.name}`;
    } else if (record.ProcessComponent) {
      name = `${record.ProcessComponent.name} - ${record.name}`;
    }

    return {
      ...record,
      controlStrategy:
        CommonEditablesFormatter.formatMultiSelectValueForDisplay(
          record.controlStrategy,
          "• ",
          "\n"
        ),
      name,
      description: record.description || "None",
      scope: record.Step ? `Step:${record.Step.name}` : "Global",
      riskLabel: riskScale?.riskLabel || "",
      riskColor: riskScale?.color || "",
      acceptanceCriteriaRanges: (acceptanceCriteriaRanges || []).join("\n\n"),
    };
  }

  fillIndirectCQAs(rmp, riskLinks, fqas, iqas, impactedCQAsKPAs, model) {

    const impactedCQAs = new Set([...impactedCQAsKPAs]);
    let indirectCQAs;

    const targetIQAId = model === "IQA" ? "TargetIQAId" : "IQAId";
    for (let iqaRisk of riskLinks) {

      const iqa = iqas.find(iqa => iqa.id === iqaRisk[targetIQAId]);
      if (!iqa) {
        return;
      }

      const fqaRiskLinks = [...iqa.IQAToFQAs];
      for (let fqaRisk of fqaRiskLinks) {
        const fqa = fqas.find(fqa => fqa.id === fqaRisk.FQAId);
        if (!fqa) {
          return;
        }
        if (isCritical(fqa, rmp, "FQA")) {
          const newImpactedCQA = `${fqa.name}`;
          impactedCQAs.add(newImpactedCQA);
        }
      }
      indirectCQAs = this.fillIndirectCQAs(rmp, iqa.IQAToIQAs, fqas, iqas, Array.from(impactedCQAs), "IQA");

    }
    return Array.from(
      new Set([...impactedCQAs, ...(indirectCQAs || [])])
    );
  }

  fillImpactedCQAsKPAs(record, options, fqaRiskLinks, fqas, iqas, scopeToImpactedCQAsKPAs, model) {
    const rmp = options.rmpVersions[0];
    let impactedCQAsKPAs = [];
    const elements = [...fqaRiskLinks];

    elements.forEach((element) => {
      const fqa = fqas.find(fqa => fqa.id === element.FQAId);
      if (!fqa) {
        return;
      }

      if (isCritical(fqa, rmp, "FQA")) {
        const newImpactedCQAsKPAs = `${element.FQA.name}`;
        impactedCQAsKPAs.push(newImpactedCQAsKPAs);
        if (record.Step) {
          scopeToImpactedCQAsKPAs.set(record.Step.name, [
            ...(scopeToImpactedCQAsKPAs.get(record.Step.name) || []),
            newImpactedCQAsKPAs,
          ]);
        }
        scopeToImpactedCQAsKPAs.set("Global", [
          ...(scopeToImpactedCQAsKPAs.get("Global") || []),
          newImpactedCQAsKPAs,
        ]);
      }
    });

    impactedCQAsKPAs = this.fillIndirectCQAs(rmp, record[`${model}ToIQAs`], fqas, iqas, impactedCQAsKPAs, model) || [];

    return {
      ...record,
      impactedCQAsKPAs: impactedCQAsKPAs.sort().map(value => `• ${value}`).join("\n"),
    };
  }

  groupByStep(items) {
    const scopeToRecords = new Map();

    items.forEach((item) => {
      if (scopeToRecords.has(item.scope)) {
        scopeToRecords.get(item.scope).push(item);
      } else {
        scopeToRecords.set(item.scope, [item]);
      }
    });

    const instances = [];
    if (scopeToRecords.has("Global")) {
      instances.push({scope: "Global", records: scopeToRecords.get("Global")});
      scopeToRecords.delete("Global");
    }

    for (let [scope, items] of scopeToRecords) {
      instances.push({scope, records: items});
    }

    return instances;
  }
}
