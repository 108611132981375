"use strict";

import React, { Fragment } from "react";
import BaseReactComponent from "../../base_react_component";
import BasePopup from "../../editor/approval/base_popup";
import * as UIUtils from "../../ui_utils";

/**
 * The popup that contains all the editable record and its attribute
 * where user can insert them to the editor
 */
export const TEMPLATE_VALUE = {
  BLANK_TEMPLATE: "blank_template",
  CQAA: "cqaa",
  PROCESS_DESCRIPTION: "process_description",
};

const TEMPLATE_OPTIONS = [
  {text: "Blank document", value: TEMPLATE_VALUE.BLANK_TEMPLATE},
  {text: "CQA Assessment (Template)", value: TEMPLATE_VALUE.CQAA},
  {text: "Process Description (Template)", value: TEMPLATE_VALUE.PROCESS_DESCRIPTION},
];

export default class DocumentSelectTemplatePopup extends BasePopup {
  constructor(props) {
    super(props);

    this.state = {
      template: TEMPLATE_OPTIONS[0].value,
    };
  }

  handleChange(template) {
    this.setStateSafely({
      template,
    });
  }

  render() {
    const {onCreate, projectId} = this.props;

    return (
      <div className="modal fade" ref={this.setModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Create a document</h1>
              <button
                type="button"
                className="close"
                onClick={this.handleCancel}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-container">
                <TemplateSelection onChange={this.handleChange} projectId={projectId} />
              </div>
            </div>
            <div className="modal-footer p-0">
              <div className="modal-container">
                <div className="btn-group">
                  <button
                    id="createButtonOnPopup"
                    type="button"
                    className="btn btn-primary"
                    onClick={() => onCreate(this.state.template)}
                  >
                    Create
                  </button>
                  <button
                    id="cancelButtonOnPopup"
                    type="button"
                    className="btn btn-secondary"
                    onClick={this.handleCancel}
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class TemplateSelection extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      template: TEMPLATE_OPTIONS[0].value,
    };
  }

  renderTemplateOptions() {
    const {template} = this.state;
    const {projectId} = this.props;

    return TEMPLATE_OPTIONS.filter(
      (optionValue) => projectId || optionValue.value !== TEMPLATE_VALUE.CQAA
    ).map((optionValue, index) => {
      return (
        <div key={index}>
          <label
            id={UIUtils.convertToCamelCaseId(optionValue.text) + "Label"}
            className="radio-button-container"
          >
            <input
              type="radio"
              value={optionValue.value}
              id={`${UIUtils.convertToCamelCaseId(optionValue.text)}Radio`}
              checked={optionValue.value === template}
              onChange={this.handleChange}
            />
            {optionValue.text}
          </label>
        </div>
      );
    });
  }

  handleChange(event) {
    this.setStateSafely(
      {
        template: event.target.value,
      },
      () => {
        this.props.onChange(this.state.template);
      }
    );
  }

  render() {
    return <Fragment>{this.renderTemplateOptions()}</Fragment>;
  }
}
