/**
 * @hidden
 */
export var EXPAND_FIELD = 'expanded';
/**
 * @hidden
 */
export var TEXT_FIELD = 'text';
/**
 * @hidden
 */
export var DISABLED_FIELD = 'disabled';
/**
 * @hidden
 */
export var CHILDREN_FIELD = 'items';
/**
 * @hidden
 */
export var HAS_CHILDREN_FIELD = 'hasChildren';
/**
 * @hidden
 */
export var SELECT_FIELD = 'selected';
/**
 * @hidden
 */
export var CHECK_FIELD = 'checked';
/**
 * @hidden
 */
export var CHECK_INDETERMINATE_FIELD = 'checkIndeterminate';
/**
 * @hidden
 */
export var DOM_KENDO_ITEM_ID_FIELD = '_kendoItemId';
/**
 * @hidden
 */
export var DOM_KENDO_TREEVIEW_GUID_FIELD = '_kendoTreeViewGuid';
