"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseHorizontalCollapsiblePanel from "./base_horizontal_collapsible_panel";
import { faBars } from "@fortawesome/free-solid-svg-icons";

/**
 * This is the base class for the filter panel on the process map and risk map screens.
 *
 * @abstract
 */
export default class BaseFilter extends BaseHorizontalCollapsiblePanel {
  constructor(props) {
    super(props, "filter");

    this.setStateSafely({filtersInitialized: false});
  }

  componentDidMount() {
    super.componentDidMount();

    if (!this.state.filtersInitialized) {
      this.initializeDefaultFilters();
    }
  }

  getIcon() {
    return faBars;
  }

  componentDidUpdate() {
    super.componentDidUpdate();
    if (!this.state.filtersInitialized) {
      this.initializeDefaultFilters();
    }
  }

  /**
   * This is called when somebody presses the "Reset" button on the top left of the filter panel.
   * @abstract
   */
  resetFilters(event) {
    UIUtils.ignoreHandler(event);
  }

  /**
   * Use this to do a one time initialization of the filters on the first componentDidUpdate.
   * @abstract
   */
  initializeDefaultFilters() {
    this.setStateSafely({filtersInitialized: true});
  }

  resizePanelOnly(panel) {
    panel.css("height", this.getContentsHeight() + 5);
  }

  renderExtraButtons() {
    return (
      <div id="filterResetLink"
           className="filter-reset-link"
      >
        <a id="clearAllFiltersButton"
           onClick={this.resetFilters}
        >Reset</a>
      </div>
    );
  }
}
