import * as React from 'react';
/**
 * @hidden
 */
export var cloneDate = function (date) { return date ? new Date(date.getTime()) : null; };
/**
 * @hidden
 */
export function clone(obj) {
    var result = {};
    cloneObject(obj, result);
    return result;
}
/**
 * @hidden
 */
export function cloneObject(obj, result) {
    for (var field in obj) {
        if (obj.hasOwnProperty(field)) {
            var value = obj[field];
            result[field] = cloneValue(value, result[field]);
        }
    }
}
/**
 * @hidden
 */
export function cloneValue(value, nextValue) {
    if (Array.isArray(value)) {
        return cloneArray(value);
    }
    else if (value instanceof Date) {
        return cloneDate(value);
    }
    else if (React.isValidElement(value)) {
        return React.cloneElement(value, value.props);
    }
    else if (value && typeof value === 'object') {
        var newNextValue = nextValue || {};
        cloneObject(value, newNextValue);
        return newNextValue;
    }
    else {
        return value;
    }
}
/**
 * @hidden
 */
export function cloneArray(array) {
    return array.map(function (value) { return cloneValue(value, undefined); });
}
