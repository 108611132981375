"use strict";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseInfoTooltip from "./base_info_tooltip";

/**
 * This class is for showing a tooltip with both an explanation and possibly a link to the FDA doc.
 *
 * YSK this class uses a lot of JQuery because it's using bootstrap's popover functionality that doesn't play nicely
 * with React.
 */
// i18next-extract-mark-ns-start widgets
class InfoTooltip extends BaseInfoTooltip {
  render() {
    const {className, id, textDirection} = this.props;
    return (
      <span className={`info-tooltip-icon ${className || ""}`} id={id}
            data-toggle="popover"
            data-html="true"
            data-container="body"
            data-placement={textDirection || "top"}
            ref={popover => this.popover = popover}
            data-content={this.renderPopupContent()}
            data-boundary="viewport"
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </span>
    );
  }

}

export default I18NWrapper.wrap(InfoTooltip, "widgets");
// i18next-extract-mark-ns-stop widgets
