/* stylelint-disable-next-line import-notation */
@import "../../../../../css/brand_colors";

.container {
  display: inline-flex;
  align-items: center;
  gap: 4px;

  &.link {
    color: $brand-primary-color;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-right: 12px;

    .icon {
      color: $brand-primary-color;
      text-align: center;
      font-size: 12px;
      margin-left: unset;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
    }
  }
}

