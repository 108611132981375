"use strict";

import * as UIUtils from "../../../ui_utils";
import { BaseDataTransform } from "../base_data_transform";
import {
  checkEmptySummaryData,
  convertLinksToJson,
  convertLinksToOneLine, processDraftRegularities,
  processEmptyFields,
  isDraft,
} from "../../canned_reports/canned_report_helper";
import { REPORT_DRAFT_ENUM } from "../../constants/report_constants";

export class GeneralAttributesSummaryDataTransform extends BaseDataTransform {

  get type() {
    return "generalAttributeSummary";
  }

  shouldRun() {
    return true;
  }

  getSupportedRecordTypes() {
    return ["generalattributes"];
  }

  transform(input) {
    const result = input;
    const data = result.instances;

    if (data.generalattributes) {
      for (const generalAttribute of data.generalattributes) {
        generalAttribute.name = UIUtils.secureString(generalAttribute.name);
        generalAttribute.draftMarker = isDraft(generalAttribute) ? REPORT_DRAFT_ENUM.DraftMarker : "";
        generalAttribute.links = convertLinksToJson(generalAttribute.links, generalAttribute.id);
        generalAttribute.linkSummary = convertLinksToOneLine(generalAttribute.links, {allowHTML: true});
        processEmptyFields(generalAttribute);
      }
    }

    processDraftRegularities(data);
    checkEmptySummaryData(data, data.generalattributes);

    result.instances = data;
    return result;
  }
}
