"use strict";

import React from "react";
import { DropdownButton } from "@qbdvision-inc/component-library";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import * as UIUtils from "../../ui_utils";
import { ADD_PROCESS_MODEL_TYPES } from "../process_explorer_constants";
import * as styles from "./addButton.module.css";

// i18next-extract-mark-ns-start widgets

export const getModelTypeForURL = (modelType) => {
  return ["IQA", "IPA"].includes(modelType) ?
    UIUtils.pluralize(modelType.toLowerCase()) :
    UIUtils.pluralize(UIUtils.convertToCamelCaseId(modelType));
};

export const handleAddClick = (event, projectId, processId) => {
  const modelType = event.target.textContent;
  const url = `/processExplorer/${getModelTypeForURL(modelType)}` +
    `/viewEdit.html?operation=Add&projectId=${projectId}&processId=${processId}`;
  window.open(UIUtils.getSecuredURL(url));
};


const AddButton = ({t, project, isSecondaryButton, projectId, processId}) => {

  return (
    <div className={styles["wrapper"]}>
      <DropdownButton label={t("Add")}
                      id="processElementsAdd"
                      type={isSecondaryButton ? "secondary" : "primary"}
                      options={ADD_PROCESS_MODEL_TYPES}
                      onOptionsSelect={e => handleAddClick(e, projectId, processId)}
                      isDisabled={project && project.deletedAt}
                      size="small"
      />
    </div>);
};

export default I18NWrapper.wrap(AddButton, "widgets");
// i18next-extract-mark-ns-stop widgets
