"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import * as go from "gojs";
import * as URLHelper from "../../../helpers/url_helper";
import { getRiskLabel } from "../../../helpers/risk_helper";
import { RISK_TYPE_ENUM } from "../../../helpers/constants/constants";

/*
*  Copyright (C) 1998-2019 by Northwoods Software Corporation. All Rights Reserved.
*/

// https://github.com/NorthwoodsSoftware/GoJS/blob/master/extensions/HyperlinkText.js
// A "HyperlinkText" is either a TextBlock or a Panel containing a TextBlock that when clicked
// opens a new browser window with a given or computed URL.
// When the user's mouse passes over a "HyperlinkText", the text is underlined.
// When the mouse hovers over a "HyperlinkText", it shows a tooltip that displays the URL.

// This "HyperlinkText" builder is not pre-defined in the GoJS library, so you will need to load this definition.

// Typical usages:
//    $("HyperlinkText", "https://gojs.net", "Visit GoJS")
//
//    $("HyperlinkText",
//        function(node) { return "https://gojs.net/" + node.data.version; },
//        function(node) { return "Visit GoJS version " + node.data.version; })
//
//    $("HyperlinkText",
//        function(node) { return "https://gojs.net/" + node.data.version; },
//        $(go.Panel, "Auto",
//            $(go.Shape, ...),
//            $(go.TextBlock, ...)
//        )
//    )

// The first argument to the "HyperlinkText" builder should be either the URL string or a function
// that takes the data-bound Panel and returns the URL string.
// If the URL string is empty or if the function returns an empty string,
// the text will not be underlined on a mouse-over and a click has no effect.

// The second argument to the "HyperlinkText" builder may be either a string to display in a TextBlock,
// or a function that takes the data-bound Panel and returns the string to display in a TextBlock.
// If no text string or function is provided, it assumes all of the arguments are used to
// define the visual tree for the "HyperlinkText", in the normal fashion for a Panel.

// The result is either a TextBlock or a Panel.

go.GraphObject.defineBuilder("HyperlinkText", function(args) {
  // The first argument provided to the HyperlinkText is the node data
  let data = go.GraphObject.takeBuilderArgument(args, undefined, function(x) {
    return typeof x === "string" || typeof x === "function";
  });

  // see if the visual tree is supplied in the arguments to the "HyperlinkText"
  let anyGraphObjects = false;
  for (let i = 0; i < args.length; i++) {
    let a = args[i];
    if (a && a instanceof go.GraphObject) anyGraphObjects = true;
  }

  // define the click behavior
  let click =
    function(e, obj) {
      let nodeData = obj._data;
      if (typeof nodeData === "function") {
        nodeData = nodeData(obj.findTemplateBinder());
        if (nodeData.typeCode) {
          let url = URLHelper.getURLByTypeCodeAndId(nodeData.typeCode, "View", nodeData.id, false);
          if (url) {
            window.open(url, "_blank");
          }
        }
      }
    };

  // define the tooltip
  let tooltip = go.GraphObject.make(go.HTMLInfo, {
    show: showToolTip,
    hide: hideTooltip
  });

  // if the data is provided, use a new TextBlock; otherwise assume the TextBlock is provided
  if (typeof (data) === "string" || typeof (data) === "function" || !anyGraphObjects) {
    let tb = go.GraphObject.make(go.TextBlock,
      {
        name: "PanelTextBlock",
        "_data": data,
        mouseEnter: function(e, obj) {
          let nodeData = obj._data;
          let url = null;
          if (typeof nodeData === "function") {
            nodeData = nodeData(obj.findTemplateBinder());
            if (nodeData.typeCode) {
              url = URLHelper.getURLByTypeCodeAndId(nodeData.typeCode, "View", nodeData.id, false);
            }
          }
          if (url) {
            obj.isUnderline = true;
            obj.cursor = "pointer";
          }
        },
        mouseLeave: function(e, obj) {
          obj.isUnderline = false;
          obj.cursor = "auto";
        },
        click: click,  // defined above
        toolTip: tooltip // shared by all HyperlinkText textblocks
      }
    );
    if (typeof (data) === "string") {
      tb.text = data;
    } else if (typeof (data) === "function") {
      tb.bind(new go.Binding("text", "", (node) => data(node).name).ofObject());
    }
    return tb;
  }
});

/**
 * This shows a tooltip when the user hovers over a link item in the process flow map.
 * @param object {go.TextBlock} The GoJS TextBlock object the user is hovering over
 * @param diagram {go.Diagram} The GoJS diagram
 */
function showToolTip(object, diagram) {
  let mousePoint = diagram.lastInput.viewPoint;
  let processFlowMapTooltipDiv = $("#processFlowMapTooltipDiv");
  let processMapContainerDiv = $("#processMapContainerDiv");
  let nodeData = object._data(object.findTemplateBinder());

  // Clear the tooltip contents
  processFlowMapTooltipDiv.empty();

  // Show a tooltip for everything except headers
  if (nodeData.id) {
    // Create the tooltip on the fly, based on the node data attributes. First add a table for the tooltip rows.
    let tooltipTable = `<table><tbody id="processFlowMapTooltipTable" /></table>`;
    processFlowMapTooltipDiv.append(tooltipTable);
    let processFlowMapTooltipTable = $("#processFlowMapTooltipTable");

    // Then add the name of the attribute including the typeCode and id
    processFlowMapTooltipTable.append(ReactDOMServer.renderToStaticMarkup(
      <tr>
        <td>
          <span className="process-flow-map-tooltip-header">Name: </span>
        </td>
        <td>
        <span id="processFlowMapTooltipName"
              className="process-flow-map-tooltip-value"
        >
          {UIUtils.getRecordLabelForDisplay(nodeData.typeCode, nodeData.id, nodeData.name)}
        </span>
        </td>
      </tr>));

    // Add the attribute criticality information
    if (nodeData.rawCriticality) {
      processFlowMapTooltipTable.append(ReactDOMServer.renderToStaticMarkup(
        <tr>
          <td>
            <span className="process-flow-map-tooltip-header">Criticality:</span>
          </td>
          <td>
        <span id="processFlowMapTooltipName" className="process-flow-map-tooltip-value">
          {nodeData.rawCriticality}
        </span>
          </td>
        </tr>));
    }

    if (nodeData.criticality) {
      let criticalityLabel = getRiskLabel(RISK_TYPE_ENUM.CRITICALITY, nodeData.effectiveRMP, nodeData.rawCriticality, nodeData);
      processFlowMapTooltipTable.append(ReactDOMServer.renderToStaticMarkup(
        <tr>
          <td>
            <span className="process-flow-map-tooltip-header">Criticality (%):</span>
          </td>
          <td>
        <span id="processFlowMapTooltipName"
              className="process-flow-map-tooltip-value"
        >
          {criticalityLabel}
        </span>
          </td>
        </tr>));
    }

    // Add the parent attribute information
    if (nodeData.parent && nodeData.parent.id) {
      processFlowMapTooltipTable.append(ReactDOMServer.renderToStaticMarkup(
        <tr>
          <td>
            <span className="process-flow-map-tooltip-header">{nodeData.parent.typeName}:</span>
          </td>
          <td>
          <span id="processFlowMapTooltipName"
                className="process-flow-map-tooltip-value"
          >
            {UIUtils.getRecordLabelForDisplay(nodeData.parent.type, nodeData.parent.id, nodeData.parent.name)}
          </span>
          </td>
        </tr>));
    }

    /* When the tooltip exceeds the right or bottom of the browser window, then show the tooltip on the left and/or
       above the mouse cursor
     */
    let mouseLeft = mousePoint.x;
    let mouseTop = mousePoint.y;
    let tooltipRight = mouseLeft + processFlowMapTooltipDiv.width();
    let tooltipBottom = mouseTop + processFlowMapTooltipDiv.height();
    if (processMapContainerDiv.width() - 10 <= tooltipRight) {
      processFlowMapTooltipDiv.css("left", (mouseLeft - processFlowMapTooltipDiv.width() - 20) + "px");
    } else {
      processFlowMapTooltipDiv.css("left", (mouseLeft + 20) + "px");
    }

    if (processMapContainerDiv.height() - 10 <= tooltipBottom) {
      processFlowMapTooltipDiv.css("top", (mouseTop - processFlowMapTooltipDiv.height() - 10) + "px");
    } else {
      processFlowMapTooltipDiv.css("top", (mouseTop) + "px");
    }

    processFlowMapTooltipDiv.css("display", "block");
  }
}

/**
 * This hides the tooltip being shown when the user moves the mouse out of a link in the process flow map.
 */
function hideTooltip() {
  let processFlowMapTooltipDiv = $("#processFlowMapTooltipDiv");

  // Clear the tooltip contents
  processFlowMapTooltipDiv.empty();

  // Hide the tooltip
  processFlowMapTooltipDiv.css("display", "none");
}
