"use strict";

import {
  adjustColumnWidths,
  filterTableFields,
  generateColumns,
  getColumnPreferences,
  getPreferencesStorageKey, saveColumnPreferences,
} from "./tables_column_preferences_helper";
import { useEffect, useState } from "react";
import ConfigurableTablesColumnGenerator from "../columnGenerator/configurable_tables_columns_generator";
import { useTableSettings } from "../table_settings_provider";
import { countVisibleColumns } from "../configurable_tables_helper";

export const useColumnGenerator = (props) => {

  const {
    project,
    fieldsConfig,
    selectedModelType,
    visibleTableColumns,
    ColumnGeneratorClass = ConfigurableTablesColumnGenerator
  } = props;

  const count = countVisibleColumns(visibleTableColumns);
  const storageKey = getPreferencesStorageKey(selectedModelType);
  const columnGenerator = new ColumnGeneratorClass(props);

  const [
    columnPreferences,
    setColumnPreferences
  ] = useState(getColumnPreferences(storageKey));

  const {
    width
  } = useTableSettings();

  const onColumnResize = (event) => {
    const {end, targetColumnId, newWidth, columns} = event;

    if (!end) return;

    const adjustedColumns = columns.map(column =>
      // If NewWidth is falsy or Zero, just ignore it and get the defined width from the column.
      column.id === targetColumnId ? {...column, width: newWidth || column.width} : column
    );

    const resizedColumns = adjustColumnWidths(adjustedColumns, width, storageKey);
    setColumnPreferences(resizedColumns);
  };


  const onColumnReorder = ({columns}) => {


    for (const column of columns) {
      const {orderIndex, field} = column;

      if (field === "name" && orderIndex !== 0) {
        const wrongColumn = columns.find(col => col.orderIndex === 1);
        if (wrongColumn) {
          wrongColumn.orderIndex = orderIndex;
          column.orderIndex = 0;
        }
      } else if (field === "selected" && orderIndex !== 0) {
        return;
      }

    }

    const preferences = getColumnPreferences(storageKey);
    if (preferences) {

      for (const column of columns) {
        const colPreference = preferences.find(col => col.field === column.field);
        if (colPreference) {
          colPreference.orderIndex = column.orderIndex;
        }
      }

      const orderedColumns = saveColumnPreferences(preferences, storageKey);
      setColumnPreferences(orderedColumns);

    } else {
      const orderedColumns = saveColumnPreferences(columns, storageKey);
      setColumnPreferences(orderedColumns);
    }

  };

  const [
    columns,
    setColumns
  ] = useState();

  useEffect(() => {
    const fields = filterTableFields(fieldsConfig.fields, project);
    setColumns(
      generateColumns(columnGenerator, fields, columnPreferences, width)
    );
  }, [count, columnPreferences, width]);

  return {onColumnResize, onColumnReorder, columns};

};