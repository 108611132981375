"use strict";

import React, { Fragment } from "react";
import BaseCopyRecordPlugin from "./base_copy_record_plugin";
import TypeaheadObjectCache from "../../../utils/cache/typeahead_object_cache";
import * as I18NWrapper from "../../../i18n/i18n_wrapper";
import * as CommonEditables from "../../../../server/common/editables/common_editables";
import * as UIUtils from "../../../ui_utils";

/**
 * A plugin to change the Product Risk Assessment Type while cloning
 */

// i18next-extract-mark-ns-start widgets
class ProductRiskAssessmentPlugin extends BaseCopyRecordPlugin {
  constructor(props) {
    super(props);
    this.setStateSafely({showMoreDetails: false, isLoading: true});

    new TypeaheadObjectCache("Project")
      .loadOptions(this.handleTypeaheadResultsFromServer);
  }

  handleTypeaheadResultsFromServer(projects) {
    const parent = this.props.parent;
    const projectId = parent && parent.state ? parent.state.projectId : null;
    const project = projects.find(project => project.id === projectId);
    this.setStateSafely({project, isLoading: false});
  }

  handleChange(event) {
    this.handleChangeValue(event.target.value);
  }

  handleShowDetails(event) {
    event.preventDefault();
    this.setStateSafely({showMoreDetails: true});
  }

  render() {
    const {t} = this.props;
    const {showMoreDetails, project, newProductRiskAssessmentType} = this.state;
    const {productRiskAssessmentType} = project || {};
    const checkedProductRiskAssessmentType = newProductRiskAssessmentType || productRiskAssessmentType;

    const options = CommonEditables.PRODUCT_RISK_ASSESSMENT_TYPES
      .map((optionValue, index) =>
        <label id={UIUtils.convertToCamelCaseId(optionValue) + "Label"}
               key={index}
               className="radio-button-container"
        >
          <input type="radio" value={optionValue} key={optionValue}
                 id={`${UIUtils.convertToCamelCaseId(optionValue)}Radio`}
                 checked={optionValue === checkedProductRiskAssessmentType ? "checked" : ""}
                 onChange={this.handleChange}
          />
          {optionValue}
          {optionValue === productRiskAssessmentType ? <span> (Current)</span> : ""}
        </label>
      );

    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="form-group">
              <label htmlFor="newRMPInput" className="col-form-label">
                {t("Product Risk Assessment Type:")}
              </label>
              <div className={"rmp-comparison-widget" + this.getClassForLoading()}>
                {showMoreDetails ?
                  <Fragment>
                    {checkedProductRiskAssessmentType === "Risk Ranking" &&
                    productRiskAssessmentType === "Preliminary Hazards Analysis (PHA)" ?
                      <div className="alert alert-warning">
                        This project uses a Product Assessment Type of Preliminary Hazards Analysis (PHA). The new project will not carry over the previous criticality risk values for your final attributes.
                      </div> : ""}
                    {options}
                  </Fragment>
                  :
                  <div className="rmp-comparison-widget-less-details">
                    {`${productRiskAssessmentType} `}
                    <a id="productRiskAssessmentTypeLink"
                       onClick={this.handleShowDetails}
                    >
                      Change
                    </a>
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(ProductRiskAssessmentPlugin, "widgets");
// i18next-extract-mark-ns-stop widgets
