@import "../css/_brand_colors.scss";
@import "../css/globals";

.menta-icon {
  width: 24px;
  height: 24px;
  margin-top: -8px; // To make the bot head appear in the middle
}

.menta-icon-header {
  width: 38px;
  height: 38px;
  margin-top: -16px; // To make the bot head appear in the middle
}

.modal-header {
  .chat-icon {
    width: 35px;
    height: 35px;
    margin-top: -20px; // To make the bot head appear in the middle
  }
}

.chat-header-menta {
  display: inline-block; // remove the space between Chat and QbD
  margin-left: 10px;
}

.chat-header-beta-pill {
  background: $brand-light-grey;
  border-radius: 4px;
  border: 1px solid $brand-slight-grey;
  color: $brand-dark-grey;
  font-size: calculateRem(12px);
  font-weight: 400;
  padding-left: 3px;
  padding-right: 3px;

  position: relative;
  top: -3px;
  left: 2px;
}

:global(#infoTooltipTop) {
  position: relative;
  top: -5px;
  margin-left: 5px;

  font-size: calculateRem(10px);
}

.chat-person-icon {
  margin-right: 10px;
  font-size: calculateRem(15px);
}

.chat-close {
  font-size: calculateRem(24px);
}

.chat-question-input {
  height: calc(1.5em + 1rem + 2px);
}

.chat-transcript {
  min-height: 500px;
  max-height: 60vh;
  overflow-x: clip;
  overflow-y: auto;

  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: -5px;
  margin-right: -5px;
  padding-top: 20px;
}

.chat-transcript-welcome {
  font-size: calculateRem(16px);
  font-weight: 600;
  padding-top: 40px;
  text-align: center;
}

.chat-transcript-welcome-examples {
  font-size: calculateRem(14px);
  padding-top: 10px;
  text-align: left;
}

.chat-popup-examples-link-box {
  background: $brand-white;
  padding: 10px;
  margin-bottom: 10px;
}

.chat-popup-examples-link {
  color: $brand-dark-grey;
}

.chat-popup-examples-arrow {
  color: $brand-medium-grey;
  float: right;
}

.chat-transcript-role {
  font-size: 20px;
}

.chat-transcript-dialog-assistant {
  table {
    margin-bottom: 20px;

    th {
      border: 1px solid $brand-mild-grey;
      background: $brand-white;
      padding-top: 5px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 5px;
    }

    td {
      border: 1px solid $brand-mild-grey;
      padding-top: 5px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 5px;
    }
  }
}

.chat-waiting {
  width: 100px;
}

#chatModal {
  .modal-header {
    background: $brand-white;
  }

  .modal-body {
    background: $brand-light-grey;
    padding-right: 50px;
  }
}

a.beta-link {
  color: #1f46a1 !important;
}
