var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { ColorPicker } from '@progress/kendo-react-inputs';
import { applyInlineStyle } from '@progress/kendo-editor-common';
import { useLocalization } from '@progress/kendo-react-intl';
import { messages } from '../messages';
/**
 * @hidden
 */
export var ApplyColorTool = function (props) {
    var view = props.view, style = props.style, colorPickerProps = props.colorPickerProps, commandName = props.commandName;
    var titleKey = colorPickerProps.title;
    var _a = colorPickerProps.view, paletteView = _a === void 0 ? 'palette' : _a;
    var onChange = React.useCallback(function (event) {
        if (view) {
            applyInlineStyle({ style: style, value: event.value }, commandName)(view.state, view.dispatch);
            if (paletteView === 'palette') {
                view.focus();
            }
        }
    }, [view, style, paletteView, commandName]);
    return (React.createElement(ColorPicker, __assign({ onChange: onChange, onActiveColorClick: onChange }, colorPickerProps, { title: titleKey ? useLocalization().toLanguageString(titleKey, messages[titleKey]) : titleKey })));
};
