import {FieldItem} from "./editables_map";

export const MODEL_NAME_TO_SECTIONS = new Map<
  string,
  Map<string, Array<string>>
>();

// Project
MODEL_NAME_TO_SECTIONS.set(
  "Project",
  new Map([
    [
      "About",
      [
        "id",
        "name",
        "customProjectId",
        "type",
        "category",
        "rmpVersionId",
        "productRiskAssessmentType",
        "currentState",
        "Links",
      ],
    ],
    ["Purpose", ["purposeAndScope", "objectives", "PurposeLinks"]],
    ["Development", ["qualityByDesignPhase", "cmcPhase", "validationPhase"]],
    [
      "Regulatory",
      [
        "dosageForm",
        "routeOfAdministration",
        "regulatoryPath",
        "regulatoryPhase",
        "referenceListedDrug",
      ],
    ],
    [
      "Team",
      [
        "projectSponsorId",
        "projectManagerId",
        "cmcLeadId",
        "manufacturingLeadId",
        "deviceLeadId",
        "qcLeadId",
        "qaLeadId",
        "regulatoryLeadId",
      ],
    ],
  ]),
);

// TPPSection
MODEL_NAME_TO_SECTIONS.set(
  "TPPSection",
  new Map([
    [
      "About",
      [
        "id",
        "name",
        "target",
        "annotations",
        "comments",
        "currentState",
        "Links",
      ],
    ],
  ]),
);

// General Attribute
MODEL_NAME_TO_SECTIONS.set(
  "GeneralAttribute",
  new Map([
    [
      "About",
      [
        "id",
        "name",
        "target",
        "currentState",
        "Links",
      ],
    ],
  ]),
);

const COMMON_FIELDS = new Map([
  [
    "About",
    [
      "id",
      "name",
      "type",
      "scope",
      "category",
      "description",
      "currentState",
      "linkedToSpecificationVersionId"
    ],
  ],
  [
    "Criticality Assessment",
    [
      "impact",
      "uncertainty",
      "potentialFailureModes",
      "totalCriticality",
      "criticalityRaw",
      "criticalityPercentage",
      "maxCriticalityRaw",
      "maxCriticalityPercentage",
      "riskLabel",
      "riskLabelColor",
      "scoreLabel",
      "scoreLabelColor",
      "criticalityJustification",
      "scaleDependent",
      "scaleJustification",
      "recommendedActions",
      "obligatoryCQA",
      "Criticality",
      "RiskLinks",
      "DetailedRiskLinks"
    ],
  ],
  [
    "Acceptance Criteria",
    [
      "dataSpace",
      "measure",
      "lowerLimit",
      "lowerOperatingLimit",
      "target",
      "upperLimit",
      "upperOperatingLimit",
      "measurementUnits",
      "targetJustification",
      "controlMethod",
      "samplingPlan",
      "stabilityIndicating",
      "AcceptanceCriteriaLinks",
      "AcceptanceCriteriaRange"
    ],
  ],
  [
    "Process Capability ",
    [
      "capabilityRisk",
      "processRiskRaw",
      "processRiskPercentage",
      "estimatedSampleSize",
      "capabilityJustification"
    ],
  ],
  [
    "Risk Control",
    [
      "detectabilityRisk",
      "rpnRaw",
      "rpnPercentage",
      "detectabilityJustification",
      "controlStrategy",
      "ccp",
      "controlStrategyJustification",
      "RiskControlLinks"
    ],
  ],
  [
    "Tech Transfer Assessment",
    [
      "techTransferAssessmentStatus",
      "techTransferImpact",
      "techTransferImpactDescription",
      "techTransferUncertainty",
      "techTransferUncertaintyJustification",
      "techTransferDetectability",
      "techTransferControlStrategy",
      "techTransferRPNRaw",
      "techTransferRPNPercentage",
      "techTransferRiskMitigationStrategy",
      "techTransferRecommendedActions",
      "techTransferComment",
      "TechTransferLinks",
    ],
  ],
  ["Tags", ["tags"]],
  [
    "References & Standards",
    [
      "ReferencesLinks",
    ],
  ]
]);

const COMMON_FIELDS_FOR_FQA_AND_FPA = new Map(COMMON_FIELDS);
COMMON_FIELDS_FOR_FQA_AND_FPA.delete("Tech Transfer Assessment");
// FQA
MODEL_NAME_TO_SECTIONS.set(
  "FQA",
  COMMON_FIELDS_FOR_FQA_AND_FPA
);

// FPA
MODEL_NAME_TO_SECTIONS.set(
  "FPA",
  COMMON_FIELDS_FOR_FQA_AND_FPA
);

// MaterialAttribute
MODEL_NAME_TO_SECTIONS.set(
  "MaterialAttribute",
  COMMON_FIELDS,
);

// ProcessParameter
MODEL_NAME_TO_SECTIONS.set(
  "ProcessParameter",
  COMMON_FIELDS
);

// IQA
MODEL_NAME_TO_SECTIONS.set(
  "IQA",
  COMMON_FIELDS
);

// IPA
MODEL_NAME_TO_SECTIONS.set(
  "IPA",
  COMMON_FIELDS
);

// Process
MODEL_NAME_TO_SECTIONS.set(
  "Process",
  new Map([
    [
      "About",
      [
        "id",
        "name",
        "description",
        "site",
        "gmp",
        "scale",
        "supplier",
        "currentState"
      ],
    ],
    [
      "Tech Transfer",
      [
        "techTransferEnabled",
        "integrations"
      ],
    ],
    [
      "References & Standards",
      [
        "ReferencesLinks",
      ],
    ]
  ]),
);

// UnitOperation
MODEL_NAME_TO_SECTIONS.set(
  "UnitOperation",
  new Map([
    [
      "About",
      [
        "id",
        "name",
        "description",
        "input",
        "output",
        "risk",
        "supplier",
        "currentState",
        "Links",
      ],
    ],
    ["Tags", ["tags"]]
  ]),
);

// Step
MODEL_NAME_TO_SECTIONS.set(
  "Step",
  new Map([
    [
      "About",
      [
        "id",
        "name",
        "description",
        "currentState",
        "Links",
      ],
    ],
    ["Tags", ["tags"]]
  ]),
);

// Material
MODEL_NAME_TO_SECTIONS.set(
  "Material",
  new Map([
    [
      "About",
      [
        "id",
        "name",
        "category",
        "gmp",
        "description",
        "libraryDescription",
        "function",
        "use",
        "Links",
        "currentState"
      ],
    ],
    [
      "Formulation Quantity",
      [
        "descriptiveUnitAbsolute",
        "quantityAbsolute",
        "quantityRelative",
        "quantityPerDose",
        "FormulationQuantityLinks"
      ],
    ],
    [
      "Qualification",
      [
        "supplier",
        "materialQualified",
        "partNumber",
        "internalPartNumber",
        "effectiveDate",
        "expirationDate",
        "QualificationLinks"
      ],
    ],
    [
      "Regulatory",
      [
        "regulatoryFiling",
        "referenceNumber",
        "authorizationLetter",
        "RegulatoryLinks"
      ],
    ],
    [
      "Properties",
      [
        "drugSubstanceType",
        "form",
        "empiricalFormula",
        "density",
        "densityConditions",
        "chemicalStructure",
        "molecularWeight",
        "chemicalNameCAS",
        "chemicalNameIUPAC",
        "otherNames",
        "innUsan",
        "casRegistryNumber",
        "compendialStandard",
        "certificateOfAnalysis",
        "PropertiesLinks"
      ],
    ],
    [
      "Tech Transfer Assessment",
      [
        "techTransferAssessmentStatus",
        "techTransferImpact",
        "techTransferImpactDescription",
        "techTransferUncertainty",
        "techTransferUncertaintyJustification",
        "techTransferDetectability",
        "techTransferControlStrategy",
        "techTransferRPN",
        "techTransferRPNPercentage",
        "techTransferRiskMitigationStrategy",
        "techTransferRecommendedActions",
        "techTransferComment",
        "TechTransferLinks",
      ],
    ],
    ["Tags", ["tags"]],
    [
      "References & Standards",
      [
        "ReferencesLinks",
      ],
    ]
  ]),
);

// ProcessComponent
MODEL_NAME_TO_SECTIONS.set(
  "ProcessComponent",
  new Map([
    [
      "About",
      [
        "id",
        "name",
        "type",
        "certificateOfAnalysis",
        "function",
        "description",
        "currentState",
        "Links"
      ],
    ],
    [
      "Component Qualification",
      [
        "supplier",
        "componentQualified",
        "partNumber",
        "internalPartNumber",
        "effectiveDate",
        "expirationDate",
        "ComponentQualificationLinks"
      ],
    ],
    [
      "Unit Qualification",
      [
        "acceptanceTesting",
        "qualificationStatus",
        "unitId",
        "calibration",
        "UnitQualificationLinks"
      ],
    ],
    [
      "Component Risk",
      [
        "drugProductContact",
        "contactRisk",
        "contactRiskJustification",
        "cleaningValidation",
        "sterilizationValidation",
        "ComponentRiskLinks"
      ],
    ],
    [
      "Tech Transfer Assessment",
      [
        "techTransferAssessmentStatus",
        "techTransferImpact",
        "techTransferImpactDescription",
        "techTransferUncertainty",
        "techTransferUncertaintyJustification",
        "techTransferDetectability",
        "techTransferControlStrategy",
        "techTransferRPNRaw",
        "techTransferRPNPercentage",
        "techTransferRiskMitigationStrategy",
        "techTransferRecommendedActions",
        "techTransferComment",
        "TechTransferLinks",
      ],
    ],
    ["Tags", ["tags"]],
    [
      "References & Standards",
      [
        "ReferencesLinks",
      ],
    ]
  ]),
);

// ControlMethod
MODEL_NAME_TO_SECTIONS.set(
  "ControlMethod",
  new Map([
    [
      "About",
      [
        "id",
        "name",
        "category",
        "type",
        "compendialStandard",
        "description",
        "equipment",
        "currentState",
        "ControlMethodLinks"
      ],
    ],
    [
      "Method Development",
      [
        "supplier",
        "status",
        "stabilityIndicating",
        "DevelopmentLinks"
      ],
    ],
    [
      "References & Standards",
      [
        "ReferencesLinks",
      ],
    ]
  ]),
);

// Document
MODEL_NAME_TO_SECTIONS.set(
  "Document",
  new Map([
    [
      "About",
      [
        "id",
        "name",
        "customID",
        "type",
        "controlled",
        "category",
        "effectiveDate",
        "site",
        "purpose",
        "scope",
        "author",
        "sme",
        "currentState"
      ],
    ],
    [
      "Regulatory",
      [
        "regulatoryDocument",
        "ectdSection",
        "dhfSection",
        "dmrSection",
        "k510Section",
        "pmaSection"
      ],
    ],
    [
      "References & Standards",
      [
        "ReferencesLinks",
        "TrainingLinks"
      ],
    ]
  ]),
);

// Document
MODEL_NAME_TO_SECTIONS.set(
  "Supplier",
  new Map([
    [
      "About",
      [
        "id",
        "name",
        "address",
        "phone",
        "website",
        "servicesOrProducts",
        "supplierRank",
        "currentState",
        "classification"
      ],
    ],
    [
      "Audit",
      [
        "auditMethod",
        "dateCompleted",
        "nextAudit",
        "additionalAuditComments",
        "AuditLinks",
      ],
    ],
    [
      "Qualification",
      [
        "qualificationStatus",
        "effectiveDate",
        "expirationDate",
        "qualityAgreement",
        "supplyAgreement",
        "additionalQualificationComments",
        "QualificationLinks"
      ],
    ],
    [
      "Risk",
      [
        "riskRating",
        "riskJustification",
        "riskMitigation",
        "riskControl",
        "RiskLinks",
      ],
    ],
    [
      "Contacts",
      [
        "primaryContactName",
        "primaryContactPhone",
        "primaryContactEmail",
        "primaryContactTitle",
        "regulatoryContactName",
        "regulatoryContactPhone",
        "regulatoryContactEmail",
        "regulatoryContactTitle",
        "qualityContactName",
        "qualityContactPhone",
        "qualityContactEmail",
        "qualityContactTitle",
        "otherContactName",
        "otherContactPhone",
        "otherContactEmail",
        "otherContactTitle"
      ],
    ]
  ]),
);

export function groupFieldsBySection(
  modelName: string,
  fieldItems: Array<FieldItem>,
) {
  const addedFieldName = new Set();
  const sectionToFields: Map<string, Array<FieldItem>> = new Map();
  if (MODEL_NAME_TO_SECTIONS.get(modelName)) {
    for (const [section, fields] of MODEL_NAME_TO_SECTIONS.get(
      modelName,
    ).entries()) {
      sectionToFields.set(section, []);
      for (const fieldItem of fieldItems) {
        if (fields.includes(fieldItem.name)) {
          sectionToFields.get(section).push(fieldItem);
          addedFieldName.add(fieldItem.name);
        }
      }
    }
  }

  if (addedFieldName.size !== fieldItems.length) {
    sectionToFields.set("Uncategorized", []);
    for (const fieldItem of fieldItems) {
      if (!addedFieldName.has(fieldItem.name)) {
        sectionToFields.get("Uncategorized").push(fieldItem);
      }
    }
  }

  const HIDDEN_SECTIONS = ["Tags"];
  for (const hiddenSection of HIDDEN_SECTIONS) {
    sectionToFields.delete(hiddenSection);
  }

  return sectionToFields;
}
