import { LABEL_DECIMALS, MIN_RATIO } from './constants';
/**
 * @hidden
 */
export var truncateNumber = function (value) {
    var numberParts = value.toString().split('.');
    return numberParts.length === 1 ? "".concat(numberParts[0]) : "".concat(numberParts[0], ".").concat(numberParts[1].substr(0, LABEL_DECIMALS));
};
/**
 * @hidden
 */
export var calculatePercentage = function (min, max, value) {
    var onePercent = Math.abs((max - min) / 100);
    return Math.abs((value - min) / onePercent);
};
/**
 * @hidden
 */
export var updateProgress = function (progressRef, progressWrapRef, percentage, isVertical) {
    var progressPercentage = Math.max(percentage, 0.01);
    var progressWrapPercentage = (100 / progressPercentage) * 100;
    if (progressRef.current && progressWrapRef.current) {
        progressRef.current.style.width = !isVertical ? "".concat(progressPercentage, "%") : '100%';
        progressWrapRef.current.style.width = !isVertical ? "".concat(progressWrapPercentage, "%") : '100%';
        progressRef.current.style.height = isVertical ? "".concat(progressPercentage, "%") : '100%';
        progressWrapRef.current.style.height = isVertical ? "".concat(progressWrapPercentage, "%") : '100%';
    }
};
/**
 * @hidden
 */
export var calculateRatio = function (min, max, value) {
    return Math.max((value - min) / (max - min), MIN_RATIO);
};
