"use strict";

import BaseAutoBind from "../../base_auto_bind";
import { orderAndIndexSteps, orderAndIndexUnits } from "../../processExplorer/indexers/uo_indexer";
import ProcessMapKey from "./process_map_key";

/**
 * This transforms the results from the server to:
 * 1. Sort the operations (UOs & Steps) into order.
 * 2. Map all of the risk links so the Final Attributes are available per operation.
 */
export class ProcessMapResultsAdapter extends BaseAutoBind {
  constructor(results) {
    super();
    this.results = results;
  }

  /**
   * Creates maps used for indexing report attributes for faster report building. Finding all the relations between
   * the report attributes using javascript `find` is time consuming. The indexed bring down the time it requires to
   * build big reports from minutes to seconds. An indicative amount of time for a report with a few hundreds of
   * attributes without the indexes is 5 minutes without the indexes and 20 seconds with the indexes.
   *
   * @returns {Object} results The rawData with additional transformations applied.
   * @returns {[{}]} results.uos An array of all unit operations, in order.
   * @returns {Map<number, [{}]>} results.uoToSteps A map of unit operation Ids to ordered step objects.
   * @returns {Map<string, [Set<string>]>} results.outputKeyToOperationMap A map of output keys (ex. "FQA-56") to a set
   *          of operations (ex. new Set(["UO-2", "STP-6", "STP-23"])).
   */
  transformData() {
    // A map of outputs (IQA/IPA/FQA/FPA) to operations (UO/STP).
    this.results.outputKeyToOperationMap = new Map();

    // Sort and index the UOs & Steps
    for (const uo of this.results.uos) {
      uo.id = uo.UnitOperation.id; // By default it's the version ID.
    }
    for (const step of this.results.steps) {
      step.id = step.StepId;
    }
    this.results.uos = orderAndIndexUnits(this.results.uos);
    this.allUOIds = this.results.uos.map(uo => uo.id);
    this.results.uoToSteps = new Map();
    for (const uo of this.results.uos) {
      this.results.uoToSteps.set(uo.id, orderAndIndexSteps(this.results.steps.filter(step => step.UnitOperationId === uo.id)) || []);
    }

    for (const modelType of ["FQA", "FPA"]) {
      // Find the outputs that are related to a UO through a Material Attribute risk link
      for (const ma of this.results.mas) {
        for (const linkedVersion of ma[`MaterialAttributeTo${modelType}LinkedVersions`]) {
          this.addToOutputToOperationMap(ma, modelType, linkedVersion[`${modelType}Id`]);
        }
      }

      // Find the outputs that are related to a UO through a PP risk link
      for (const pp of this.results.pps) {
        for (const linkedVersion of pp[`ProcessParameterTo${modelType}LinkedVersions`]) {
          this.addToOutputToOperationMap(pp, modelType, linkedVersion[`${modelType}Id`]);
        }
      }

      // Find the outputs that are related to a UO through an IQA risk link
      for (const iqa of this.results.iqas) {
        for (const linkedVersion of iqa[`IQATo${modelType}LinkedVersions`]) {
          this.addToOutputToOperationMap(iqa, modelType, linkedVersion[`${modelType}Id`]);
        }
      }

      // Find the outputs that are related to a UO through an IPA risk link
      for (const ipa of this.results.ipas) {
        for (const linkedVersion of ipa[`IPATo${modelType}LinkedVersions`]) {
          this.addToOutputToOperationMap(ipa, modelType, linkedVersion[`${modelType}Id`]);
        }
      }
    }

    return this.results;
  }

  addToOutputToOperationMap(record, modelType, linkedId) {
    const newOperations = this.detectOperations(record);
    const key = modelType + "-" + linkedId;
    let existingOpsKeyToObjMap = this.results.outputKeyToOperationMap.get(key);
    if (!existingOpsKeyToObjMap) {
      // Using a map ensures the keys remain unique and the same FXA doesn't show up twice in the same box on the process flow map.
      existingOpsKeyToObjMap = new Map(newOperations.map(op => [op.typeCode + "-" + op.id, op]));
      this.results.outputKeyToOperationMap.set(key, existingOpsKeyToObjMap);
    } else {
      for (const op of newOperations) {
        existingOpsKeyToObjMap.set(op.typeCode + "-" + op.id, op);
      }
    }
  }

  detectOperations(record) {
    if (record.StepId) {
      return [new ProcessMapKey("STP", record.StepId)];
    } else if (record.UnitOperationId) {
      return [new ProcessMapKey("UO", record.UnitOperationId)];
    } else {
      return this.allUOIds.map(uoId => new ProcessMapKey("UO", uoId));
    }
  }
}
