"use strict";

import * as UIUtils from "../ui_utils";
import * as RiskHelper from "../helpers/risk_helper";
import * as CommonRiskUtils from "../../server/common/misc/common_risk_utils";
import { COPY_RMP_REQUIREMENT_OPTIONS, RISK_ATTRIBUTES } from "./constants/rmp_constants";


/**
 * This returns back a filtered RMP given a model name or just the RMP if no model name is specified. In addition
 * to filtering the RMP it does some cleanup by removing empty array attributes from the RMP, coming from the parent
 * page.
 * @param rmp The RMP to filter and cleanup. This is a clone of the props, so we can safely modify it.
 * @param modelName The model name
 * @param parent
 * @returns {*}
 */
export function getRMPForModel(rmp, modelName, parent) {
  for (let key of Object.keys(CommonRiskUtils.RMP_TO_RISK_ATTRIBUTES)) {
    let riskAttribute = UIUtils.pluralize(CommonRiskUtils.RMP_TO_RISK_ATTRIBUTES[key].linkedObject);
    let riskAttributeVersion = CommonRiskUtils.RMP_TO_RISK_ATTRIBUTES[key].linkedObjectVersion;
    let modelRiskAttribute = `${modelName}${riskAttribute}`;
    if (rmp[riskAttribute] && typeof rmp[riskAttribute].length !== "undefined" && rmp[riskAttribute].length === 0) {
      delete rmp[riskAttribute];
    }
    if (rmp[riskAttributeVersion] && typeof rmp[riskAttributeVersion].length !== "undefined" && rmp[riskAttributeVersion].length === 0) {
      delete rmp[riskAttributeVersion];
    }
    if (rmp[modelRiskAttribute] && typeof rmp[modelRiskAttribute].length !== "undefined" && rmp[modelRiskAttribute].length === 0) {
      delete rmp[modelRiskAttribute];
    }

    if (parent && parent.isDiffingVersions()) {
      delete rmp[riskAttribute];
      delete rmp[modelRiskAttribute];
    } else {
      delete rmp[riskAttributeVersion];
    }

    if (rmp[modelRiskAttribute]) {
      rmp[riskAttribute] = rmp[modelRiskAttribute];
    }
  }

  rmp = CommonRiskUtils.filterRMPByType(rmp, modelName);
  rmp.boundaries = RiskHelper.getRiskBoundaries(rmp);

  return rmp;
}

export function handleCopyCurrentRecordToTarget(parent) {
  let currentRecord;
  if (parent.state.activeRiskSchemaModelTab) {
    currentRecord = UIUtils.getTypeCodeForModelName(parent.state.activeRiskSchemaModelTab);
  } else {
    currentRecord = "FQA";
  }

  if (parent.state.selectedCopyToRecord === "Copy to all") {
    for (const record of COPY_RMP_REQUIREMENT_OPTIONS) {
      if (record.title !== "copyToAll") {
        copyRMPRecordToTarget(currentRecord, record.title, parent);
      }
      parent.setStateSafely({
        [`use${record.title}Uncertainty`]: parent.state[`use${currentRecord}Uncertainty`],
        [`use${record.title}Detectability`]: parent.state[`use${currentRecord}Detectability`]
      });
    }
  } else {
    copyRMPRecordToTarget(currentRecord, parent.state.selectedCopyToRecordTitle, parent);
    parent.setStateSafely({
      [`use${parent.state.selectedCopyToRecordTitle}Uncertainty`]: parent.state[`use${currentRecord}Uncertainty`],
      [`use${parent.state.selectedCopyToRecordTitle}Detectability`]: parent.state[`use${currentRecord}Detectability`]
    });
  }

  parent.setStateSafely({
    dataModified: true,
  }, () => {
    if (parent.copyRecordConfirmationPopup) {
      $(parent.copyRecordConfirmationPopup).modal("hide");
    }
  });
}

export function isCritical(criticalityScale) {
  return !!(criticalityScale.critical || criticalityScale.alwaysCritical);
}

/**
 * This functions copies values from one RMP record to another, it is intended as a helper to the
 * handleCopyCurrentRecordToTarget function.
 * @param fromRecordTitle shorthand title of the record to copy from (Ex. FQA)
 * @param toRecordTitle shorthand title of the record to copy to (Ex. TechTransfer)
 * @param parent
 */
function copyRMPRecordToTarget(fromRecordTitle, toRecordTitle, parent) {
  for (const attribute of RISK_ATTRIBUTES) {
    let pluralizedAttribute = UIUtils.pluralize(attribute);
    let currentAttributes = UIUtils.deepClone(parent.state[`${fromRecordTitle}RMPTo${pluralizedAttribute}`]);
    let modifiedRisks = [];
    for (let recordAttribute of currentAttributes) {
      recordAttribute.modelName = toRecordTitle;
      recordAttribute.uuid = UIUtils.generateUUID();
      delete recordAttribute.id;
      delete recordAttribute.RMPId;
      delete recordAttribute.createdAt;
      delete recordAttribute.deletedAt;
      delete recordAttribute.updatedAt;
      delete recordAttribute.createdByUserId;
      modifiedRisks.push(recordAttribute);
    }
    parent.setStateSafely({
      [`${toRecordTitle}RMPTo${pluralizedAttribute}`]: modifiedRisks
    });
  }
}