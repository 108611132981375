"use strict";

import React from "react";
import BaseAttribute from "./base_attribute";
import { EXPERIMENTS } from "../../helpers/constants/constants";
import { ExperimentManager } from "../../../server/common/generic/common_experiment_manager";
import * as UIUtils from "../../ui_utils";

const experimentManager = ExperimentManager.instance();

/**
 * This shows the list of available experiments on the user page.
 */
export default class ExperimentsAttribute extends BaseAttribute {
  constructor(props) {
    super(props);
    this.state = {};

    // Bind functions to this
    this.getInitialValue = this.getInitialValue.bind(this);
  }

  getInitialValue() {
    let initialValue = {};
    for (const experiment of experimentManager.getAllExperiments()) {
      initialValue[experiment.key] = experiment.default;
    }
    return JSON.stringify(initialValue);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      let value = this.getNextValue(nextProps);
      return this.currentValue !== value;
    }
    return true;
  }

  getValueAsObject() {
    return JSON.parse(this.getValue());
  }

  handleChange(experimentKey, event) {
    let currentExperiments = this.getValueAsObject();

    currentExperiments[experimentKey] = (event.target.value || "").toString().toLowerCase() === "enabled";
    let currentExperimentsAsString = JSON.stringify(currentExperiments);

    // Uncomment for verbose logging
    // console.log("Changing " + this.props.name + " to '" + currentExperimentsAsString + "'");

    this.props.parent.handleChangeValue(this.getAttributeName(), currentExperimentsAsString);
  }

  render() {
    const hiddenExperiments = [EXPERIMENTS.PartyMode.key];
    this.currentValue = this.getValue();
    let experimentValues = this.getValueAsObject();
    const experiments = experimentManager.getAllExperiments(this.props.isAPI);
    if (experiments.length > 0) {
      return experiments.map((experiment) => {
        const experimentKey = experiment.key;
        if (hiddenExperiments.includes(experimentKey) && !this.props.showHiddenExperiments && !UIUtils.isExperimentEnabled(experiment)) {
          return null;
        } else {
          const experimentEnabled = experimentValues[experimentKey] === true;
          return (<div key={experimentKey} className="row">
            <div className="col-9">
              <div className="experiment-name">{experiment.name}{experiment.internal ? <span className="badge badge-info ml-2">INTERNAL</span> : ""}</div>
              <div className="experiment-description">
                {experiment.description}
                {experiment.internal ? <div className="text-info">This experiment is only visible in non-client facing environments.</div> : ""}
              </div>
            </div>
            <div className="col-3">
              {this.isView() ? (
                <span id={"experiment" + experimentKey + "ComboBox"}>
                    {experimentEnabled ? "Enabled" : "Disabled"}
                  </span>
              ) : (
                <select className="form-control" id={"experiment" + experimentKey + "ComboBox"}
                        value={experimentEnabled ? "enabled" : "disabled"}
                        onChange={this.handleChange.bind(this, experimentKey)} disabled={this.isView()}>
                  <option value="enabled">Enabled</option>
                  <option value="disabled">Disabled</option>
                </select>
              )}
            </div>
          </div>);
        }
      });
    } else {
      return <div className="col-sm-12">
        No experimental features available at this time.
      </div>;
    }
  }
}
