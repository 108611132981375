"use strict";

import React from "react";
import ReactDOMServer from "react-dom/server";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faColumns, faTimes } from "@fortawesome/free-solid-svg-icons";
import ImplementationNeededError from "../../utils/implementation_needed_error";
import ConfigurableTablesTooltipBase from "../widgets/configurable_tables_tooltip_base";
import { filterFields, getColumnProp } from "./configurable_tables_column_selection_helper";
import {
  ASSESSMENT_MODE_SELECTOR,
  ASSESSMENT_MODE_SELECTOR_ACCEPTANCE_CRITERIA_RANGES,
  ASSESSMENT_MODE_SELECTOR_CRITICALITY_ASSESSMENT, ASSESSMENT_MODE_SELECTOR_TOOLTIP_TEXT,
} from "./constants";

export default class ConfigurableTablesColumnSelectionBase extends ConfigurableTablesTooltipBase {

  constructor() {
    super();
    this.selectorIdToColumnSelectionMap = new Map();
    this.setStateSafely({isResetColumnsSelection: false});
    if (!this.props?.enableAssessmentOptions) {
      localStorage.setItem(ASSESSMENT_MODE_SELECTOR, ASSESSMENT_MODE_SELECTOR_ACCEPTANCE_CRITERIA_RANGES);
    }
  }

  show() {
    //TODO (debt, QI-5306) replace bootstrap's popover with react-bootstrap to assign handlers properly to elements
    $("#cancelColumnSelection").on("click", this.handleClose);
    $("#columnSelectionClose").on("click", this.handleClose);
    $("#columnSelectionApplyButton").on("click", this.handleApply);
    $("#columnSelectionResetToDefaultButton").on("click", this.handleResetToDefault);
    $("#columnSelectionTooltipDiv :checkbox").on("change", this.handleSelectionChange);
    $(`#${ASSESSMENT_MODE_SELECTOR} :radio`).on("change", this.handleRadioChange);
    $(`#assessmentModeSelectorTooltipIcon`).tooltip({
      boundary: "viewport",
      container: ".configurable-tables-column-selection-body-container",
      html: true,
      placement: "top",
      title: ASSESSMENT_MODE_SELECTOR_TOOLTIP_TEXT,
      trigger: "click",
    });

    //Update radio buttons with the latest selection from localState
    const assessmentModeSelectorValue = this.props.enableAssessmentOptions ? localStorage.getItem(ASSESSMENT_MODE_SELECTOR) || ASSESSMENT_MODE_SELECTOR_CRITICALITY_ASSESSMENT : ASSESSMENT_MODE_SELECTOR_ACCEPTANCE_CRITERIA_RANGES;

    if (assessmentModeSelectorValue) {
      const element = document.querySelector(`input[id="${assessmentModeSelectorValue}"]`);
      if (element) {
        element.checked = true;
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {selectedModelType} = this.props;
    if (selectedModelType !== prevProps.selectedModelType) {
      this.selectorIdToColumnSelectionMap.clear();
    }
  }

  handleApply() {
    const {onVisibleTableColumnsChanged, onResetToDefaults} = this.props;
    const {isResetColumnsSelection} = this.state;
    $(this.popover).popover("hide");

    if (this.state[ASSESSMENT_MODE_SELECTOR]) {
      localStorage.setItem(ASSESSMENT_MODE_SELECTOR, this.state[ASSESSMENT_MODE_SELECTOR]);

      [...document.querySelectorAll("#DetailsTableContainer")].forEach(item => {
        item.className = this.state[ASSESSMENT_MODE_SELECTOR];
      });
    }

    if (isResetColumnsSelection) {
      onResetToDefaults(this.selectorIdToColumnSelectionMap);
      this.setStateSafely({isResetColumnsSelection: false},
        () => onVisibleTableColumnsChanged(this.selectorIdToColumnSelectionMap));
    } else {
      onVisibleTableColumnsChanged(this.selectorIdToColumnSelectionMap);
    }
  }

  handleSelectionChange(event) {
    const {id, checked} = event.target;

    if (this.selectorIdToColumnSelectionMap.has(id)) {
      this.selectorIdToColumnSelectionMap.delete(id);
    }

    this.selectorIdToColumnSelectionMap.set(id, checked);
  }

  handleRadioChange() {
    const selectedValue = document.querySelector(`input[name="${ASSESSMENT_MODE_SELECTOR}"]:checked`).value;

    selectedValue && this.setStateSafely({[ASSESSMENT_MODE_SELECTOR]: selectedValue});
  }

  get defaultValueProp() {
    return "defaultValue";
  }

  handleClose() {
    $(this.popover).popover("hide");
  }

  renderPopupContent() {
    throw new ImplementationNeededError();
  }

  renderHeader() {
    return (
      <div className="column-selection-tooltip-title">
        <div>
          Table settings&nbsp;
          <a id="columnSelectionResetToDefaultButton">
            Reset to default
          </a>
        </div>
        <div id="columnSelectionClose">
          <FontAwesomeIcon className="conlumn-selection-close-icon"
                           size="sm"
                           icon={faTimes}
          />
        </div>
      </div>
    );
  }

  renderFooter() {
    return (
      <div className="column-selection-footer-container">
        <div className="btn-group">
          <button id="columnSelectionApplyButton"
                  type="button"
                  className="btn btn-primary"
          >
            Apply
          </button>
          <button id="cancelColumnSelection"
                  type="button"
                  className="btn btn-secondary"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  tooltipBody() {
    return <span id={"changeColumnTooltip"}
                 data-toggle="popover"
                 data-html="true"
                 data-container="body"
                 data-placement={"left"}
                 data-template={ReactDOMServer.renderToString(
                   <div className="popover column-selection-popup" role="tooltip">
                     <div className="arrow" />
                     <h3 className="popover-header" />
                     <div className="popover-body p-0 column-selection-popup" />
                   </div>
                 )}
                 ref={popover => this.popover = popover}
                 data-content={this.renderPopupContent()}
                 data-boundary="viewport"
    >
      {this.renderBody()}
    </span>;
  }

  get isTechTransfer() {
    return false;
  }

  handleResetToDefault() {
    const {visibleTableColumns} = this.props;
    const filteredFields = filterFields(visibleTableColumns, this.isTechTransfer);
    for (const column of filteredFields) {
      const selectorId = getColumnProp(column, this.isTechTransfer);
      $(`#${selectorId}`).prop("checked", column[this.defaultValueProp]);
    }

    this.selectorIdToColumnSelectionMap.clear();
    this.setStateSafely({isResetColumnsSelection: true});
  }

  renderBody() {
    return (
      <button id="columnSelectionButton"
              type="button"
              className="btn btn-secondary configurable-export-button mt-0"
              aria-label="Column selection"
              title="Select columns to show in the table"
      >
        <FontAwesomeIcon icon={faColumns} />
      </button>
    );
  }
}
