"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import BaseReactComponent from "../../base_react_component";

/**
 * This class is for showing an error icon with a tooltip next to a tab
 * item and can be used as a validation icon in any control.
 */
export default class ValidationIcon extends BaseReactComponent {
  constructor(props) {
    super(props);

    /* In cases where the id of the control would not make sense to be used, i.e when using the ValidationIcon
       within a table, then an auto generated guid is assigned as an id.
     */
    this.id = (this.props.id || UIUtils.generateUUID());
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    $(this.popover).popover("dispose");
  }

  shouldComponentUpdate(nextProps) {
    const {tooltip, hideBullets, visible, className, trigger, isWarning} = this.props;
    const nextTooltip = nextProps.tooltip;
    const nextHideBullets = nextProps.hideBullets;
    const nextVisible = nextProps.visible;
    const nextClassName = nextProps.className;
    const nextTrigger = nextProps.trigger;
    const nextIsWarning = nextProps.isWarning;

    return tooltip !== nextTooltip
      || hideBullets !== nextHideBullets
      || visible !== nextVisible
      || className !== nextClassName
      || trigger !== nextTrigger
      || isWarning !== nextIsWarning;
  }

  componentDidMount() {
    super.componentDidMount();
    $("[data-toggle='popover']").popover({sanitizeFn: UIUtils.sanitizePopoverData});
  }

  componentDidUpdate() {
    $("[data-toggle='popover']").popover({sanitizeFn: UIUtils.sanitizePopoverData});
  }

  renderPopupContent() {
    /*
      This gets converted to HTML and passed around, so don't bother putting onClick handlers here.
      Put them in componentDidMount() above instead.
     */
    return ReactDOMServer.renderToStaticMarkup(
      <div id={this.id + "TooltipDiv"}>
        <div id={this.id + "TooltipDivContent"}>
          {this.prepareTooltipForDisplay(this.props.tooltip)}
        </div>
      </div>
    );
  }

  prepareTooltipForDisplay(tooltip) {
    if (typeof tooltip === "string" || (tooltip instanceof Array && tooltip.length === 1)) {
      return tooltip;
    } else if (tooltip instanceof Array) {
      let counter = 0;
      return tooltip.map(tooltipRow => {
        counter++;
        return (
          <div id={this.id + "ContentRow_" + counter}
               key={this.id + "ContentRow_" + counter}
               className="info-tooltip-content-row"
          >
            {(tooltipRow.includes("•") || this.props.hideBullets ? "" : "• ") + tooltipRow}
          </div>
        );
      });
    } else {
      return tooltip;
    }
  }

  getIconClass() {
    let {isWarning, isInformative} = this.props;

    if (isWarning) {
      return "tab-nav-bar-warning-icon-yellow";
    } else if (isInformative) {
      return "tab-nav-bar-warning-icon-blue";
    } else {
      return "tab-nav-bar-error-icon-red";
    }
  }

  render() {
    let tooltip = this.renderPopupContent();
    let {className, visible, trigger, isInformative} = this.props;

    return (
      visible ? (
        <span id={`${this.id}_icon`}
              className={"tab-nav-bar-icon" + (className ? (" " + className) : "")}
              data-toggle="popover"
              data-html="true"
              data-container="body"
              data-content={tooltip}
              data-trigger={trigger || "hover"}
              data-placement="top"
              ref={popover => this.popover = popover}
        >
            <FontAwesomeIcon icon={isInformative ? faInfoCircle : faExclamationTriangle}
                             className={this.getIconClass()}
                             id={this.id + "Shape"}
            />
        </span>
      ) : null
    );
  }

}
