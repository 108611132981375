"use strict";

import * as UIUtils from "../ui_utils";
import React, { Fragment } from "react";
import BaseFullScreenPage from "../base_full_screen_page";
import EditablesPageTitleBar from "../widgets/pageTitleBar/editables_page_title_bar";
import NavBar from "../widgets/bars/nav_bar";
import { PROCESS_BAR_PANELS } from "./process_explorer_constants";
import ErrorBar from "../widgets/bars/error_bar";
import { EDITOR_OPERATIONS } from "../editor/editor_constants";
import { getURLByTypeCodeAndId } from "../helpers/url_helper";
import ProcessExplorerDiagram from "./diagram/process_explorer_diagram";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import { canViewPage } from "../utils/ui_permissions";
import * as ProcessCache from "./process/process_cache";
import MultipleTypeaheadObjectCache from "../utils/cache/multiple_typeahead_object_cache";
import FlyoutPanel from "../widgets/layout/flyout_panel";
import { ALL_TYPES_FOR_TYPEAHEADS } from "./process_explorer_constants";
import RecordCopyHandler from "./handlers/record_copy_handler";
import ProcessExplorerHelper from "./utils/process_explorer_helper";
import DataReceiver from "./utils/data_receiver";
import ProcessExplorerRenderer from "./utils/process_explorer_renderer";
import RecordOperator from "./operators/record_operator";
import RecordOperationsHandler from "./handlers/record_operations_handler";
import StaticPanelOperator from "./operators/static_panel_operator";
import { Converters } from "../../server/common/generic/common_converters";
import CommonSecurity from "../../server/common/generic/common_security";
import { getProjectFromCache } from "../utils/project_helper";
import DataLoader from "./recordLoader/data_loader";
import TableViewPage from "./parentPages/table_view_page";
import { process } from "@progress/kendo-data-query";
import ConfigurableTablesExport from "../configurableTables/export/configurable_tables_export";
import { filterFields, } from "../configurableTables/columnSelection/configurable_tables_column_selection_helper";
import { PROCESS_LEVEL_MODELS_CONFIG } from "./tables/process_explorer_tables_config";
import { LibraryHelper } from "../library/helpers/library_helper";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";
import * as RiskHelper from "../helpers/risk_helper";
import {
  getApprovalValue,
  handleApprovalChangeValue,
  handleApprovalChangeValues
} from "../editor/approval/approval_utils";
import { NotificationProvider } from "../configurableTables/tables/widgets/notification/notification_provider";
import { handleAddClick } from "./widgets/add_button";
import InfoNotification from "../configurableTables/tables/widgets/notification/types/info_notification";
import { faInfoCircle, faProjectDiagram, faTable } from "@fortawesome/free-solid-svg-icons";
import { ProcessExplorerTitleBar } from "./process_explorer_title_bar";

const getPEViewsConfigs = (staticPanelOperator) => {
  return [
    {
      id: "processAboutView",
      onClick: () => staticPanelOperator.handleChangePanel(PROCESS_BAR_PANELS.ABOUT),
      title: "About",
      panel: PROCESS_BAR_PANELS.ABOUT,
      icon: faInfoCircle,
    },
    {
      id: "processTreeView",
      onClick: () => staticPanelOperator.handleChangePanel(PROCESS_BAR_PANELS.TREE),
      title: "Tree",
      panel: PROCESS_BAR_PANELS.TREE,
      icon: faProjectDiagram,
    },
    {
      id: "processTableView",
      onClick: () => staticPanelOperator.handleChangePanel(PROCESS_BAR_PANELS.TABLE),
      title: "Table",
      panel: PROCESS_BAR_PANELS.TABLE,
      icon: faTable,
    }
  ];
};

// i18next-extract-mark-ns-start process_explorer
export class ProcessExplorerPage extends BaseFullScreenPage {
  constructor(props) {
    super(props);

    UIUtils.setLoadingDisabled(true);
    this.projectId = UIUtils.parseInt(UIUtils.getParameterByName("projectId"));
    this.useWriterDB = UIUtils.getParameterByName("useWriterDB");

    const commonProps = {parent: this, projectId: this.projectId};
    this.staticPanelOperator = new StaticPanelOperator(commonProps);
    this.copyHandler = new RecordCopyHandler(commonProps);
    this.libraryHelper = new LibraryHelper();
    this.dataReceiver = new DataReceiver(commonProps);
    this.processExplorerHelper = new ProcessExplorerHelper(commonProps);
    this.renderer = new ProcessExplorerRenderer(commonProps);
    this.recordOperator = new RecordOperator(commonProps);
    this.recordOperationsHandler = new RecordOperationsHandler(commonProps);
    this.dataLoader = new DataLoader(commonProps);
    this.previousAdapterData = {};

    let showArchived = UIUtils.getParameterByName("showArchived");
    showArchived = typeof showArchived !== "undefined" ? Converters.toBoolean(showArchived) : false;

    let selectedModelType = UIUtils.getParameterByName("selectedModelType");

    this.staticPanel = React.createRef();

    let selectedPanel = UIUtils.getParameterByName("selectedPanel");
    selectedPanel = selectedPanel || PROCESS_BAR_PANELS.TREE;

    this.handleApprovalChangeValue = handleApprovalChangeValue.bind(this);
    this.handleApprovalChangeValues = handleApprovalChangeValues.bind(this);
    this.getApprovalValue = getApprovalValue.bind(this);

    this.setStateSafely({
      approvalInfo: {},
      links: [],
      nodes: [],
      processes: [],
      typeaheadOptions: [],
      visibleProcesses: [],
      keyToFullRecordMap: new Map(),
      aboutPanelInfo: {operation: EDITOR_OPERATIONS.VIEW},
      staticPanelInfo: {operation: EDITOR_OPERATIONS.VIEW},
      securityTypeName: null,
      selectedNode: null,
      showApprovalPopup: false,
      shouldShowRightPanel: false,
      isProposeForArchiveOrRestore: false,
      isLoading: true,
      showArchived,
      selectedPanel,
      selectedModelType
    });

    window.onpopstate = this.processExplorerHelper.handleHistory;

    const processId = ProcessCache.getProcessIdUsedRecently(this.projectId);
    const processPromise = processId > 0 ? Promise.resolve(processId) : this.getProcessId();
    processPromise.then((processId) => {
      this.setStateSafely({
        processId,
      });

      if (canViewPage(CommonSecurity.Types.UNIT_OPERATION)) {
        let loadTypeaheadPromise = Promise.resolve();
        if (processId) {
          loadTypeaheadPromise = new MultipleTypeaheadObjectCache(ALL_TYPES_FOR_TYPEAHEADS, this.projectId, processId).loadOptions(
            this.dataReceiver.handleReceivedTypeaheadResults,
            {useWriterDB: this.useWriterDB}
          ).promise().then(() => {
            // this is required due to some weird behaviou in the base page when the CompanyHeader doesn't get the project and breadcrump
            if (!this.projectInformationIsLoaded()) {
              return this.loadProject();
            }

            return true;
          });
        }

        this.loadTypeaheadPromise = loadTypeaheadPromise;

        RiskHelper.loadRMP((effectiveRMP, project) => this.rmpLoaded(effectiveRMP, project));
      }
    });
  }

  rmpLoaded(effectiveRMP, project) {
    let stateObject = {
      effectiveRMP,
      ProjectWithAllVersions: project,
    };

    this.setStateSafely(stateObject, () => {
      this.dataLoader.loadProcessExplorerData(this.useWriterDB, this.loadTypeaheadPromise);
    });
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = "QbDVision Process Explorer";
    UIUtils.incrementReactComponentDidUpdateCounter();
  }

  get securityTypeName() {
    return this.state.securityTypeName;
  }

  async getProcessId() {
    const cache = new TypeaheadObjectCache("Process", this.projectId);
    await cache.loadOptions().promise();

    console.log("ProcessExplorerPage - processes loaded including archived", cache.getOptionsFromCacheIncludingArchived());
    const processes = cache.getOptionsFromCache();
    if (processes.length === 0) {
      return null;
    }

    return processes[0].id;
  }

  isProjectArchived() {
    let parentProject = getProjectFromCache(this.projectId);
    return (parentProject && !!parentProject.deletedAt);
  }

  getProjectId() {
    return this.projectId;
  }

  setPreviousAdapterData(selectedModelType, data) {
    this.previousAdapterData[selectedModelType] = data;
  }

  getPreviousAdapterData(selectedModelType) {
    return this.previousAdapterData[selectedModelType];
  }

  clearPreviousAdapterData() {
    const {category} = this.state.selectedNode || {};
    if (category) {
      const model = UIUtils.getModelNameForTypeCode(category);
      const selectedModelType = UIUtils.pluralize(model);
      delete this.previousAdapterData[selectedModelType];
    }
  }

  handleTableDataUpdated(selectedModelType, adaptedData, tableData, project) {
    this.setPreviousAdapterData(selectedModelType, adaptedData);
    this.setStateSafely({tableData, project});
  }

  handleVisibleColumnsChange(visibleTableColumns) {
    this.setStateSafely({visibleTableColumns: [...visibleTableColumns]});
  }

  loadPreviousAdaptedData(selectedModelType) {
    return this.getPreviousAdapterData(selectedModelType);
  }

  handleTableStateChange(tableState) {
    this.setStateSafely({tableState});
  }

  handleModelTypeChange(selectedModelType) {
    const {
      columnOperationsHandler,
    } = this.state;
    columnOperationsHandler?.handleClearAllFilters(true);
    UIUtils.pushHistoryURLWithNewParameter({}, "selectedModelType", selectedModelType);
    const {modelName} = PROCESS_LEVEL_MODELS_CONFIG[selectedModelType];
    this.setStateSafely({
      selectedModelType,
      modelName: modelName,
      securityTypeName: UIUtils.singularize(selectedModelType)
    });
  }

  handleUpdateTableDependencies(dependencies) {
    this.setStateSafely(dependencies);
  }

  handleUnitOperationsLoad(orderedUOList) {
    this.setStateSafely({orderedUOList});
  }

  handleUnitOperationChange(unitOperationId) {
    UIUtils.pushHistoryURLWithNewParameter({}, "unitOperationId", unitOperationId);
    this.setStateSafely({unitOperationId});
  }

  handleExport() {
    const {
      selectedModelType,
      tableData,
      project,
      visibleTableColumns,
      columnOperationsAdapter,
      unitOperationId,
      orderedUOList,
    } = this.state;

    const records = process(tableData.slice(0),
      columnOperationsAdapter.adaptFilterNames(this.state));

    const unitOperation = orderedUOList.find(uo => uo.id === unitOperationId);
    new ConfigurableTablesExport({
      records: records.data,
      selectedModelType,
      projectName: project.name,
      feature: "ProcessExplorer",
      modelsConfig: PROCESS_LEVEL_MODELS_CONFIG,
      visibleTableColumns: filterFields(visibleTableColumns, project),
      unitOperationName: unitOperation ? unitOperation.name : "All",
    }).exportReport();
  }

  renderPage() {
    const {t} = this.props;

    const {
      project, nodes, links,
      processId,
      processes,
      panelSizes,
      selectedRecord,
      staticPanelInfo,
      shouldShowRightPanel,
      showArchived,
      keyToFullRecordMap,
      nonArchivedCount,
      selectedPanel,
      aboutPanelInfo,
      aboutPanelRecord,
      typeaheadOptions,
      modelName,
      selectedModelType,
      unitOperationId,
    } = this.state;

    const elementsFromAbove = [".trial-bar", ".nav-bar", ".page-title-bar", "#companyHeader", ".process-explorer-container > div:first-child"];
    const aboveHeight = Array.from(document.querySelectorAll(elementsFromAbove)).reduce((sum, curr) => sum + curr.clientHeight, 0);
    const aboutHeight = (window.innerHeight - aboveHeight - 3) + "px";

    const viewsConfig = getPEViewsConfigs(this.staticPanelOperator);
    return canViewPage(CommonSecurity.Types.UNIT_OPERATION) ? (
      <Fragment>
        <EditablesPageTitleBar projectId={this.projectId}
                               projectName={project?.name ?? ""}
                               projectDeletedAt={project?.deletedAt ?? null}
                               isDemoProject={project?.isDemo ?? ""}
                               currentState={project?.currentState ?? null}
        />
        <NavBar selected="Process Explorer"
                projectId={this.projectId}
        />
        <div className="process-explorer-container">
          <NotificationProvider>
            <ProcessExplorerTitleBar t={t}
                                     projectId={this.projectId}
                                     selectedModelType={selectedModelType}
                                     onAddClick={(e) => handleAddClick(e, this.projectId, processId)}
                                     onExport={this.handleExport}
                                     selectedPanel={selectedPanel}
                                     viewsConfig={viewsConfig}
                                     processes={processes}
                                     processId={processId}
                                     onModelTypeChange={this.handleModelTypeChange}
                                     onUnitOperationChange={this.handleUnitOperationChange}
                                     loading={this.isLoading()}
                                     showArchived={showArchived}
                                     keyToFullRecordMap={keyToFullRecordMap}
                                     nonArchivedCount={nonArchivedCount}
                                     aboutPanelRecord={aboutPanelRecord}
                                     recordOperationsHandler={this.recordOperationsHandler}
            />
            <InfoNotification />
            <ErrorBar />
            <div className={selectedPanel === PROCESS_BAR_PANELS.ABOUT ? "mt-1 static-panel-title-bar-hidden" : "d-none"}>
              {this.renderer.renderStaticPanel(aboutPanelInfo, aboutPanelRecord, true, aboutHeight)}
            </div>
            <FlyoutPanel
              id="procexpSplitPanel"
              className={[PROCESS_BAR_PANELS.TREE, PROCESS_BAR_PANELS.TABLE]
                .includes(selectedPanel) ? "" : "d-none"}
              shouldShowRightPanel={shouldShowRightPanel ? staticPanelInfo : null}
              maxLeftWidth={1100}
              minSize={[440, 580]}
              leftPanel={
                <Fragment>
                  {selectedPanel === PROCESS_BAR_PANELS.TREE ?
                    <div className={"process-explorer-graph" + this.getClassForLoading()}>
                      <ProcessExplorerDiagram
                        nodes={nodes}
                        links={links}
                        processes={processes}
                        panelSizes={panelSizes}
                        selectedRecord={selectedRecord}
                        onArchiveOrRestoreButton={this.recordOperationsHandler.handleArchiveOrRestoreButton}
                        onProposeButton={this.recordOperationsHandler.handleBulkPropose}
                        onCopyButton={this.copyHandler.handleCopyButton}
                        onCopyFrom={this.copyHandler.handleCopyButton}
                        onCreateFromLibrary={this.libraryHelper.onCreateFromLibraryClick}
                        onClick={this.recordOperator.handleClickRecord}
                        onAdd={this.recordOperator.handleAddRecord}
                        projectId={this.projectId}
                        processId={processId}
                        showArchived={showArchived}
                        ref={thisRef => this.processExplorerDiagram = thisRef}
                        isLoading={this.isLoading()}
                      />
                    </div> :
                    <TableViewPage projectId={this.projectId}
                                   modelName={modelName}
                                   processId={processId}
                                   selectedModelType={selectedModelType || "Process Parameters"}
                                   typeaheadOptions={typeaheadOptions}
                                   onUnitOperationsLoad={this.handleUnitOperationsLoad}
                                   unitOperationId={unitOperationId}
                                   loadTypeaheadPromise={this.loadTypeaheadPromise}
                                   onLoadPreviousAdaptedData={this.loadPreviousAdaptedData}
                                   onTableStateChange={this.handleTableStateChange}
                                   onTableDataUpdated={this.handleTableDataUpdated}
                                   onUnitOperationChange={this.handleUnitOperationChange}
                                   onVisibleColumnsChange={this.handleVisibleColumnsChange}
                                   onUpdateTableDependencies={this.handleUpdateTableDependencies}
                    />
                  }
                </Fragment>
              }
              rightPanel={this.renderer.renderStaticPanel()}
              onSplitPanelDrag={this.processExplorerHelper.reLayoutDiagram}
              onSplitPanelDragEnd={this.processExplorerHelper.reLayoutDiagram}
              onSplitPanelAnimationEnd={this.processExplorerHelper.reLayoutDiagram}
              onOpenInNewWindow={() => {
                if (staticPanelInfo.operation === EDITOR_OPERATIONS.VIEW || staticPanelInfo.operation === EDITOR_OPERATIONS.EDIT) {
                  window.open(getURLByTypeCodeAndId(staticPanelInfo.typeCode, staticPanelInfo.operation, staticPanelInfo.id, false), "_blank");
                } else {
                  window.open(UIUtils.getSecuredURL(getURLByTypeCodeAndId(staticPanelInfo.typeCode, "Add", this.projectId, false) + "&processId=" + processId), "_blank");
                }
              }}
            />
          </NotificationProvider>
        </div>
        {this.state.showApprovalPopup ? this.renderer.renderApprovalRequestPopup() : ""}
        {this.state.showCopyPopup ? this.renderer.renderCopyPopup() : ""}
        {this.state.showCopyFromPopup ? this.renderer.renderCopyFromPopup(project, processes.find(p => p.id === processId)) : null}
      </Fragment>) : this.renderer.renderSplashScreen(project);
  }
}

export default I18NWrapper.wrap(ProcessExplorerPage, ["process_explorer", "editor"]);
// i18next-extract-mark-ns-stop process_explorer
