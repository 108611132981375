/**
 * @hidden
 */
export var EMPTY_ID = '';
/**
 * @hidden
 */
export var ZERO_LEVEL_ZERO_NODE_ID = '0';
/**
 * @hidden
 */
export var SEPARATOR = '_';
/**
 * @hidden
 */
export function getItemById(itemId, items, childrenField) {
    if (isIdZeroLevel(itemId)) {
        return items[Number(itemId)];
    }
    else {
        var rootParentItem = items[Number(getRootParentId(itemId))];
        var subItems = (rootParentItem && rootParentItem[childrenField]) || [];
        return subItems.length ?
            getItemById(getIdWithoutRootParentId(itemId), subItems, childrenField) : undefined;
    }
}
/**
 * @hidden
 */
export function getRootParentId(itemId) {
    return isIdEmptyOrZeroLevel(itemId) ? itemId : itemId.split(SEPARATOR)[0];
}
/**
 * @hidden
 */
export function getIdWithoutRootParentId(itemId) {
    if (isIdEmptyOrZeroLevel(itemId)) {
        return itemId;
    }
    else {
        var firstSeparatorIndex = itemId.indexOf(SEPARATOR);
        return itemId.substring(firstSeparatorIndex + 1);
    }
}
/**
 * @hidden
 */
export function getFirstChildId(itemId) {
    return createId('0', itemId);
}
/**
 * @hidden
 */
export function createId(childId, parentId) {
    childId = childId.toString();
    return parentId ? parentId + SEPARATOR + childId : childId;
}
/**
 * @hidden
 */
export function getDirectParentId(itemId) {
    var lastSeparatorIndex = itemId.lastIndexOf(SEPARATOR);
    return lastSeparatorIndex < 0 ? EMPTY_ID : itemId.substring(0, lastSeparatorIndex);
}
/**
 * @hidden
 */
export function isIdEmptyOrZeroLevel(itemId) {
    return itemId === EMPTY_ID || itemId.indexOf(SEPARATOR) < 0;
}
/**
 * @hidden
 */
export function isIdZeroLevel(itemId) {
    return itemId !== EMPTY_ID && itemId.indexOf(SEPARATOR) < 0;
}
/**
 * @hidden
 */
export function getAllShortIds(itemId) {
    return itemId.split(SEPARATOR);
}
/**
 * @hidden
 */
export function getShortId(itemId) {
    var lastSeparatorIndex = itemId.lastIndexOf(SEPARATOR);
    return lastSeparatorIndex < 0 ? itemId : itemId.substring(lastSeparatorIndex + 1);
}
/**
 * @hidden
 */
export function isItemFirstFromSiblings(itemId) {
    return getShortId(itemId) === ZERO_LEVEL_ZERO_NODE_ID;
}
/**
 * @hidden
 */
export function getDecrementedItemIdAfterRemoval(removedItemId, itemId) {
    var preservedItemId = itemId;
    removedItemId = 'r' + SEPARATOR + removedItemId;
    itemId = 'r' + SEPARATOR + itemId;
    var itemSharedPath = getDirectParentId(removedItemId) + SEPARATOR;
    if (itemId.startsWith(itemSharedPath)) {
        var itemNotSharedPath = itemId.substring(itemSharedPath.length);
        if (itemNotSharedPath) {
            var siblingShortId = getRootParentId(itemNotSharedPath);
            if (Number(getShortId(removedItemId)) < Number(siblingShortId)) {
                var decrementedId = itemSharedPath +
                    (Number(siblingShortId) - 1).toString() +
                    itemNotSharedPath.substring(siblingShortId.length);
                return getIdWithoutRootParentId(decrementedId);
            }
        }
    }
    return preservedItemId;
}
