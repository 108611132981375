var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useTableKeyboardNavigation } from '../navigation/hooks';
/**
 * @hidden
 */
export var HeaderThElement = function (props) {
    var ariaSort = props.ariaSort, colSpan = props.colSpan, rowSpan = props.rowSpan, className = props.className, style = props.style, columnId = props.columnId, onKeyDown = props.onKeyDown, navigatable = props.navigatable, ariaColumnIndex = props.ariaColumnIndex, ariaLabel = props.ariaLabel, role = props.role, ariaSelected = props.ariaSelected;
    var navigationAttributes = useTableKeyboardNavigation(columnId, navigatable);
    return (React.createElement("th", __assign({ "aria-sort": ariaSort, "aria-label": ariaLabel, "aria-colindex": ariaColumnIndex, "aria-selected": ariaSelected, 
        // TODO: Enable this with React 18. Currently throws warnings
        // aria-description={ariaDescription}
        colSpan: colSpan, rowSpan: rowSpan, className: className, style: style, onKeyDown: onKeyDown, role: role }, navigationAttributes), props.children));
};
HeaderThElement.displayName = 'KendoReactHeaderThElement';
