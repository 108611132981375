/** @hidden */
export var kendoThemeMaps = {
    sizeMap: {
        small: 'sm',
        medium: 'md',
        large: 'lg'
    },
    roundedMap: {
        small: 'sm',
        medium: 'md',
        large: 'lg'
    }
};
