export const SELECTED_TABLES = {
  CONTROL_METHODS: "Control Methods",
  LIBRARY_MATERIALS: "Library Materials",
  PRODUCT: "Product",
  PROCESS: "Process",
  SUPPLIERS: "Suppliers",
};
export const MODELS = {
  CONTROL_METHODS: "ControlMethod",
  LIBRARY_MATERIALS: "LibraryMaterial",
};

export const SIDE_PANEL_ACTIONS = {
  CONFIGURE: "configure",
  BULK_EDIT: "bulkEdit",
};

export const NOTIFICATION = {
  ERROR: "error",
  INFO: "info",
};