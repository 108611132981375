@import "../../../../css/_brand_colors.scss";

.text-area-cell-container {
  position: relative;
  overflow: hidden;
  color: $brand-dark-grey;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  max-height: 40px;
}

.text-cell-container {
  overflow: hidden;
  white-space: nowrap;
  color: $brand-dark-grey;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-overflow: ellipsis;
}

.show-more {
  position: absolute;
  cursor: pointer;
  right: 0;
  bottom: 0;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: $brand-primary-color;
  background-color: $brand-white;
  margin-bottom: unset;

  &:hover {
    background-color: $brand-light-grey;
  }

  &::before {
    content: '...\00a0';
    cursor: default;
    color: $brand-black;
  }

  :global(a) {
    font-size: 13px !important;
    font-weight: 600 !important;
  }
}

:global(.k-selected) .show-more,
:global(.k-selected.k-master-row:hover td) .show-more {
  background-color: #E8F8FF !important;
}


:global(.k-master-row:hover td) .show-more {
  background-color: $brand-light-grey !important;
}
