import { merge } from "../index";
import { WidgetParserFactory } from "./widget_parser";
import { GLOBAL_MODELS } from "../../common/constants";
import { BaseWidgetParser } from "./base_widget_parser";
import * as DocumentLinkParser from "./elementParser/document_link_parser";

/**
 * Parser that parses all repeater widgets
 */
export class RepeaterParser extends BaseWidgetParser {
  get selector() {
    return ".widget-content";
  }

  getElements() {
    const contentElement = this.node.querySelector(this.selector);
    if (!contentElement) {
      return [];
    }
    return Array.from(contentElement.childNodes);
  }

  parse() {
    const newElements = [];
    const contentElements = this.getElements();

    if (this.parseOnly) {
      this.addAttributesInFiltersToFields();
      for (const contentElement of contentElements) {
        let modelRepeaterField = this.fields;
        const kind = contentElement.getAttribute("kind");
        if (kind) {
          modelRepeaterField = this.addModelAndColumnToField(
            modelRepeaterField,
            this.parentModelName
          );
        }

        const parser = WidgetParserFactory.getParser(
          this.globalNode,
          this.node,
          contentElement,
          this.data,
          this.fileType,
          this.rmp,
          this.project,
          this.process,
          this.parseOnly
        );
        parser.parse();
        merge(modelRepeaterField, parser.fields);
      }
    }

    if (!this.parseOnly) {
      // This is special case for global models like Project or Process. The result from
      // server may look like {Project: [{Links: "[]"}]. The Project will only have one
      // record, and we need to extract the Links to render document links for the project
      if (
        DocumentLinkParser.isLinkAttribute(this.subModelName) &&
        GLOBAL_MODELS.includes(this.parentModelName) &&
        Array.isArray(this.data[this.parentModelName]) &&
        this.data[this.parentModelName].length > 0
      ) {
        this.data = this.data[this.parentModelName][0];
      }

      const records = this.getRecords(this.parentModelName, this.subModelName);
      if (!records.length) {
        return newElements;
      }

      for (const record of records) {
        for (const contentElement of contentElements) {
          const parser = WidgetParserFactory.getParser(
            this.globalNode,
            this.node,
            contentElement,
            record,
            this.fileType,
            this.rmp,
            this.project,
            this.process,
            this.parseOnly
          );
          newElements.push(...parser.parse());
        }
      }

      return this.mergeOrderedList(newElements);
    }

    return newElements;
  }

  mergeOrderedList(elements) {
    let previousOrderedList = null;
    const newElements = [];
    for (const element of elements) {
      if (element.tagName !== "OL") {
        previousOrderedList = null;
        newElements.push(element);
        continue;
      }

      if (!previousOrderedList) {
        previousOrderedList = element;
        newElements.push(element);
        continue;
      }

      if (previousOrderedList.getAttribute("uuid") === element.getAttribute("uuid")) {
        previousOrderedList.append(...element.childNodes);
        continue;
      }

      previousOrderedList = element;
      newElements.push(element);
    }
    return newElements;
  }
}
