{
  "License": {
    "Professional": {
      "Statements": [
        {
          "Actions": [],
          "Types": [
            "Batch",
            "Company",
            "Curriculum",
            "Control Method",
            "Document",
            "Document Content",
            "FPA",
            "FQA",
            "IPA",
            "IQA",
            "General Attribute",
            "ITP",
            "Material",
            "Material Attribute",
            "Process",
            "Process Component",
            "Process Parameter",
            "Project",
            "RMP",
            "Step",
            "Supplier",
            "TPP Section",
            "Unit Operation",
            "User",
            "Library Material"
          ],
          "Reports": [
            "TPP Full Report",
            "TPP Section Report",
            "TPP Summary Report",
            "QTPP Summary Report",
            "QTPP Full Report",
            "Requirement Historical Summary Report",
            "Requirement Report",
            "Requirements Summary Report",
            "Product Quality Attributes Report",
            "Product Release Specification Report",
            "Product Stability Testing Panel Report",
            "Risk Map (All)",
            "Risk Tables",
            "Failure Modes and Effects Analysis (FMEA) - Unit Operation",
            "FQA Risk Ranking Report",
            "Process Flow Map",
            "Process Analytics Dashboard",
            "Control Charts (Continuous)",
            "Control Charts (Defectives)",
            "Specification Report",
            "Individual Material Report",
            "Individual Process Component Report",
            "Influence Map Report",
            "Unit Operations Summary",
            "Bill of Raw Materials",
            "Bill of Process Components",
            "Risk Tables - QTPP (Quality)",
            "Risk Tables - QTPP (Performance)",
            "Risk Tables - PPs",
            "Risk Tables - MAs",
            "Risk Tables - PP - IQAs/FQAs",
            "Risk Tables - MA - IQAs/FQAs",
            "Risk Tables - IQA - IQAs/FQAs",
            "Approved Suppliers List",
            "Risk Management Plan",
            "Individual Training Report",
            "Document Training Report",
            "Training Matrix Report",
            "CQA Control Strategy Report",
            "Unit Operation Control Strategy Report"
          ]
        }
      ]
    },
    "Essential": {
      "Statements": [
        {
          "Actions": [],
          "Types": [
            "Curriculum",
            "Document",
            "Document Content",
            "FPA",
            "FQA",
            "ITP",
            "General Attribute",
            "Project",
            "RMP",
            "Supplier",
            "TPP Section",
            "User"
          ],
          "Reports": [
            "TPP Summary Report",
            "QTPP Summary Report",
            "Approved Suppliers List",
            "Risk Management Plan",
            "Individual Training Report",
            "Document Training Report",
            "Training Matrix Report"
          ]
        }
      ]
    }
  },
  "Role": {
    "Administrator": {
      "Statements": [
        {
          "Actions": [
            "View",
            "Add",
            "Bulk Add",
            "Edit",
            "Propose",
            "Withdraw",
            "Approve",
            "Propose for Archive",
            "Approve for Archive",
            "Propose for Restore",
            "Approve for Restore",
            "Delete",
            "Import",
            "Change Criteria"
          ],
          "Types": [
            "Batch",
            "Company",
            "Curriculum",
            "Control Method",
            "Document",
            "Document Content",
            "FPA",
            "FQA",
            "IPA",
            "IQA",
            "General Attribute",
            "ITP",
            "Material",
            "Material Attribute",
            "Process",
            "Process Component",
            "Process Parameter",
            "Project",
            "RMP",
            "Step",
            "Supplier",
            "TPP Section",
            "Unit Operation",
            "User",
            "Library Material"
          ]
        }
      ]
    },
    "Super User": {
      "Statements": [
        {
          "Actions": [
            "View",
            "Add",
            "Edit",
            "Propose",
            "Withdraw",
            "Approve",
            "Propose for Archive",
            "Approve for Archive",
            "Propose for Restore",
            "Import",
            "Change Criteria"
          ],
          "Types": [
          ]
        }
      ]
    },
    "Standard User": {
      "Statements": [
        {
          "Actions": [
            "View",
            "Add",
            "Edit",
            "Propose",
            "Withdraw",
            "Approve",
            "Propose for Archive"
          ],
          "Types": [
          ]
        }
      ]
    },
    "Reviewer/Approver": {
      "Statements": [
        {
          "Actions": [
            "View",
            "Approve",
            "Withdraw"
          ],
          "Types": [
          ]
        }
      ]
    },
    "Viewer": {
      "Statements": [
        {
          "Actions": [
            "View"
          ],
          "Types": [
          ]
        }
      ]
    }
  },
  "Department": {
    "CMC": {
      "Statements": [
        {
          "Actions": [],
          "Types": [
            "Batch",
            "Control Method",
            "Document",
            "Document Content",
            "FPA",
            "FQA",
            "IPA",
            "IQA",
            "General Attribute",
            "Material",
            "Material Attribute",
            "Process",
            "Process Component",
            "Process Parameter",
            "Project",
            "Step",
            "Supplier",
            "Unit Operation",
            "RMP",
            "Library Material"
          ]
        }
      ]
    },
    "R&D": {
      "Statements": [
        {
          "Actions": [],
          "Types": [
            "Batch",
            "Document",
            "Document Content",
            "FPA",
            "FQA",
            "IPA",
            "IQA",
            "General Attribute",
            "Material",
            "Material Attribute",
            "Process",
            "Process Component",
            "Process Parameter",
            "Project",
            "Step",
            "Supplier",
            "TPP Section",
            "Unit Operation",
            "RMP",
            "Library Material"
          ]
        }
      ]
    },
    "Clinical": {
      "Statements": [
        {
          "Actions": [],
          "Types": [
            "Document",
            "Document Content",
            "TPP Section"
          ]
        }
      ]
    },
    "Quality Control": {
      "Statements": [
        {
          "Actions": [],
          "Types": [
            "Batch",
            "Control Method",
            "Document",
            "Document Content",
            "FPA",
            "FQA",
            "IPA",
            "IQA",
            "General Attribute",
            "Material",
            "Material Attribute",
            "Process",
            "Process Component",
            "Process Parameter",
            "Project",
            "Step",
            "Supplier",
            "Unit Operation",
            "Library Material"
          ]
        }
      ]
    },
    "Quality Assurance": {
      "Statements": [
        {
          "Actions": [],
          "Types": [
            "Batch",
            "Control Method",
            "Curriculum",
            "Document",
            "Document Content",
            "FPA",
            "FQA",
            "IPA",
            "IQA",
            "ITP",
            "General Attribute",
            "Material",
            "Material Attribute",
            "Process",
            "Process Component",
            "Process Parameter",
            "Project",
            "Step",
            "Supplier",
            "TPP Section",
            "Unit Operation",
            "RMP",
            "Library Material"
          ]
        }
      ]
    },
    "Regulatory": {
      "Statements": [
        {
          "Actions": [],
          "Types": [
            "Document",
            "Document Content",
            "FPA",
            "FQA",
            "IPA",
            "IQA",
            "General Attribute",
            "Process",
            "Project",
            "RMP",
            "Supplier",
            "TPP Section"
          ]
        }
      ]
    },
    "Finance": {
      "Statements": [
        {
          "Actions": [],
          "Types": [
            "Document",
            "Document Content",
            "Supplier"
          ]
        }
      ]
    },
    "Operations": {
      "Statements": [
        {
          "Actions": [],
          "Types": [
            "Batch",
            "Document",
            "Document Content",
            "FPA",
            "FQA",
            "IPA",
            "IQA",
            "General Attribute",
            "Material",
            "Material Attribute",
            "Process Component",
            "Process Parameter",
            "Step",
            "Supplier",
            "Unit Operation",
            "Library Material"
          ]
        }
      ]
    },
    "Management": {
      "Statements": [
        {
          "Actions": [],
          "Types": [
            "Batch",
            "Company",
            "Control Method",
            "Document",
            "Document Content",
            "FPA",
            "FQA",
            "IPA",
            "IQA",
            "General Attribute",
            "Material",
            "Material Attribute",
            "Process",
            "Process Component",
            "Process Parameter",
            "Project",
            "RMP",
            "Step",
            "Supplier",
            "TPP Section",
            "Unit Operation",
            "Library Material"
          ]
        }
      ]
    },
    "Other": {
      "Statements": [
        {
          "Actions": [],
          "Types": [
            "Document",
            "Document Content"
          ]
        }
      ]
    }
  }
}
