"use strict";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { BaseOptionsMenu } from "../../widgets/base_options_menu";


/**
 * This class is for producing the meatball button that provide View, Edit, etc options on most tables.
 */


export default class LibraryOptionButton extends BaseOptionsMenu {

  constructor(props) {
    super(props);
  }

  render() {
    const {id} = this.props;
    return (
      <div className="dropdown ml-auto">
        <button id={id}
                className={`btn btn-secondary library-options-button`}
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
        >
          <FontAwesomeIcon icon={faEllipsisH} />
        </button>
        <ul id={`optionsUl_${id}`} className="dropdown-menu table-row-options-button dropdown-menu-right">
          {this.renderItems()}
        </ul>
      </div>
    );
  }

}
