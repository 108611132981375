"use strict";

const ajvValidator = require("ajv");
const ajv = new ajvValidator();

const linkAttachmentSchema = {
  "type": "array",
  "minItems": 1,
  "items": {
    "type": "object",
    "properties": {
      "S3TmpKey": {
        "type": ["string", "null"]
      },
      "S3TmpVersion": {
        "type": ["string", "null"]
      },
      "link": {
        "type": ["string", "null"]
      },
      "linkVersion": {
        "type": ["string", "null"]
      }
    },
    "required": ["S3TmpKey", "S3TmpVersion", "link", "linkVersion"]
  }
};

const riskLinkAttachmentSchema = {
  "type": "array",
  "minItems": 1,
  "items": {
    "type": "object",
    "properties": {
      "impact": {
        "type": "integer"
      },
      "uncertainty": {
        "type": "integer"
      }
    },
    "required": ["impact", "uncertainty"]
  }
};

const linksValidator = ajv.compile(linkAttachmentSchema);
const riskLinksValidator = ajv.compile(riskLinkAttachmentSchema);

//Validate a json string against a known schema
function validateJson(schema, data, validator) {
  try {
    data = JSON.parse(data);
    return validator(data);
  } catch (e) {
    return false;
  }
}

module.exports.isLinksJSON = function(instanceProperty) {
  return instanceProperty
    && typeof instanceProperty === "string"
    && validateJson(linkAttachmentSchema, instanceProperty, linksValidator);
};

module.exports.isRiskLinksArray = function(instanceProperty) {
  return instanceProperty
    && Array.isArray(instanceProperty)
    && validateJson(riskLinkAttachmentSchema, JSON.stringify(instanceProperty), riskLinksValidator);
};
