/**
 * @hidden
 */
export function getHoverOpenDelay(props) {
    if (props.hoverOpenDelay !== undefined) {
        return props.hoverOpenDelay;
    }
    else {
        return props.openOnClick ? 0 : 100;
    }
}
/**
 * @hidden
 */
export function getHoverCloseDelay(props) {
    return props.hoverCloseDelay !== undefined ? props.hoverCloseDelay : 100;
}
