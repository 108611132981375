var _a;
/**
 * @hidden
 */
var bold = 'editor.bold';
/**
 * @hidden
 */
var italic = 'editor.italic';
/**
 * @hidden
 */
var underline = 'editor.underline';
/**
 * @hidden
 */
var strikethrough = 'editor.strikethrough';
/**
 * @hidden
 */
var subscript = 'editor.subscript';
/**
 * @hidden
 */
var superscript = 'editor.superscript';
/**
 * @hidden
 */
var insertHyperlink = 'editor.hyperlink';
/**
 * @hidden
 */
var insertFile = 'editor.insertFile';
/**
 * @hidden
 */
var print = 'editor.print';
/**
 * @hidden
 */
var selectAll = 'editor.selectAll';
/**
 * @hidden
 */
var cleanFormatting = 'editor.cleanFormatting';
/**
 * @hidden
 */
var pdf = 'editor.pdf';
/**
 * @hidden
 */
var foregroundColor = 'editor.foregroundColor';
/**
 * @hidden
 */
var backgroundColor = 'editor.backgroundColor';
/**
 * @hidden
 */
var createTable = 'editor.createTable';
/**
 * @hidden
 */
var createTableHint = 'editor.insertTableHint';
/**
 * @hidden
 */
var addRowBefore = 'editor.addRowBefore';
/**
 * @hidden
 */
var addRowAfter = 'editor.addRowAfter';
/**
 * @hidden
 */
var addColumnBefore = 'editor.addColumnBefore';
/**
 * @hidden
 */
var addColumnAfter = 'editor.addColumnAfter';
/**
 * @hidden
 */
var deleteRow = 'editor.deleteRow';
/**
 * @hidden
 */
var deleteColumn = 'editor.deleteColumn';
/**
 * @hidden
 */
var deleteTable = 'editor.deleteTable';
/**
 * @hidden
 */
var mergeCells = 'editor.mergeCells';
/**
 * @hidden
 */
var splitCell = 'editor.splitCell';
/**
 * @hidden
 */
var insertHyperlinkDialogTitle = 'editor.hyperlink-dialog-title';
/**
 * @hidden
 */
var insertHyperlinkAddress = 'editor.hyperlink-dialog-content-address';
/**
 * @hidden
 */
var insertHyperlinkTitle = 'editor.hyperlink-dialog-content-title';
/**
 * @hidden
 */
var insertHyperlinkNewWindow = 'editor.hyperlink-dialog-content-newwindow';
/**
 * @hidden
 */
var insertHyperlinkCancel = 'editor.hyperlink-dialog-cancel';
/**
 * @hidden
 */
var insertHyperlinkInsert = 'editor.hyperlink-dialog-insert';
/**
 * @hidden
 */
var insertFileDialogTitle = 'editor.insertfile-dialog-title';
/**
 * @hidden
 */
var insertFileAddress = 'editor.insertfile-dialog-content-address';
/**
 * @hidden
 */
var insertFileTitle = 'editor.insertfile-dialog-content-title';
/**
 * @hidden
 */
var insertFileCancel = 'editor.insertfile-dialog-cancel';
/**
 * @hidden
 */
var insertFileInsert = 'editor.insertfile-dialog-insert';
/**
 * @hidden
 */
var insertImage = 'editor.image';
/**
 * @hidden
 */
var insertImageDialogTitle = 'editor.image-dialog-title';
/**
 * @hidden
 */
var insertImageAddress = 'editor.image-address';
/**
 * @hidden
 */
var insertImageTitle = 'editor.image-title';
/**
 * @hidden
 */
var insertImageAltText = 'editor.image-altText';
/**
 * @hidden
 */
var insertImageWidth = 'editor.image-width';
/**
 * @hidden
 */
var insertImageHeight = 'editor.image-height';
/**
 * @hidden
 */
var insertImageCancel = 'editor.image-cancel';
/**
 * @hidden
 */
var insertImageInsert = 'editor.image-insert';
/**
 * @hidden
 */
var viewHtml = 'editor.viewHtml';
/**
 * @hidden
 */
var viewHtmlDialogTitle = 'editor.viewHtml-dialog-title';
/**
 * @hidden
 */
var viewHtmlCancel = 'editor.viewHtml-cancel';
/**
 * @hidden
 */
var viewHtmlUpdate = 'editor.viewHtml-update';
/**
 * @hidden
 */
var unlink = 'editor.unlink';
/**
 * @hidden
 */
var undo = 'editor.undo';
/**
 * @hidden
 */
var redo = 'editor.redo';
/**
 * @hidden
 */
var fontSize = 'editor.fontSize';
/**
 * @hidden
 */
var fontName = 'editor.fontName';
/**
 * @hidden
 */
var format = 'editor.format';
/**
 * @hidden
 */
var alignLeft = 'editor.alignLeft';
/**
 * @hidden
 */
var alignRight = 'editor.alignRight';
/**
 * @hidden
 */
var alignCenter = 'editor.alignCenter';
/**
 * @hidden
 */
var alignJustify = 'editor.alignJustify';
/**
 * @hidden
 */
var indent = 'editor.indent';
/**
 * @hidden
 */
var outdent = 'editor.outdent';
/**
 * @hidden
 */
var orderedList = 'editor.orderedList';
/**
 * @hidden
 */
var bulletList = 'editor.bulletList';
/**
 * @hidden
 */
var findReplaceToolTitle = 'editor.findReplace-tool-title';
/**
 * @hidden
 */
var findReplaceDialogTitle = 'editor.findReplace-dialog-title';
/**
 * @hidden
 */
var findReplaceTabFind = 'editor.findReplace-tab-find';
/**
 * @hidden
 */
var findReplaceTabReplace = 'editor.findReplace-tab-replace';
/**
 * @hidden
 */
var findReplaceFindWhat = 'editor.findReplace-findWhat';
/**
 * @hidden
 */
var findReplaceReplaceWith = 'editor.findReplace-replaceWith';
/**
 * @hidden
 */
var findReplaceReplace = 'editor.findReplace-replace';
/**
 * @hidden
 */
var findReplaceReplaceAll = 'editor.findReplace-replaceAll';
/**
 * @hidden
 */
var findReplaceMatchCase = 'editor.findReplace-matchCase';
/**
 * @hidden
 */
var findReplaceMatchWord = 'editor.findReplace-matchWord';
/**
 * @hidden
 */
var findReplaceMatchCyclic = 'editor.findReplace-matchCyclic';
/**
 * @hidden
 */
var findReplaceUseRegExp = 'editor.findReplace-useRegExp';
/**
 * @hidden
 */
var findReplacePrevMatch = 'editor.findReplace-prevMatch';
/**
 * @hidden
 */
var findReplaceNextMatch = 'editor.findReplace-nextMatch';
/**
 * @hidden
 */
var findReplaceMatches = 'editor.findReplace-matches';
/**
 * @hidden
 */
var iframeTitle = 'editor.iframeTitle';
/**
 * @hidden
 */
export var messages = (_a = {},
    _a[bold] = 'Bold',
    _a[italic] = 'Italic',
    _a[underline] = 'Underline',
    _a[strikethrough] = 'Strikethrough',
    _a[subscript] = 'Subscript',
    _a[superscript] = 'Superscript',
    _a[unlink] = 'Remove hyperlink',
    _a[undo] = 'Undo',
    _a[redo] = 'Redo',
    _a[fontSize] = 'Font Size',
    _a[fontName] = 'Font Name',
    _a[format] = 'Format',
    _a[alignLeft] = 'Align text left',
    _a[alignRight] = 'Align text right',
    _a[alignCenter] = 'Center text',
    _a[alignJustify] = 'Justify',
    _a[indent] = 'Indent',
    _a[outdent] = 'Outdent',
    _a[orderedList] = 'Insert ordered list',
    _a[bulletList] = 'Insert unordered list',
    _a[print] = 'Print',
    _a[selectAll] = 'Select All',
    _a[cleanFormatting] = 'Clean formatting',
    _a[pdf] = 'Export as PDF',
    _a[foregroundColor] = 'Color',
    _a[backgroundColor] = 'Background color',
    // Tables
    _a[createTable] = 'Create a table',
    _a[createTableHint] = 'Create a {0} x {1} table',
    _a[addRowBefore] = 'Add row above',
    _a[addRowAfter] = 'Add row below',
    _a[addColumnBefore] = 'Add column on the left',
    _a[addColumnAfter] = 'Add column on the right',
    _a[deleteRow] = 'Delete row',
    _a[deleteColumn] = 'Delete column',
    _a[deleteTable] = 'Delete table',
    _a[mergeCells] = 'Merge cells',
    _a[splitCell] = 'Split cell',
    // Insert Link
    _a[insertHyperlink] = 'Insert hyperlink',
    _a[insertHyperlinkDialogTitle] = 'Insert hyperlink',
    _a[insertHyperlinkAddress] = 'Web address',
    _a[insertHyperlinkTitle] = 'Title',
    _a[insertHyperlinkNewWindow] = 'Open link in new window',
    _a[insertHyperlinkCancel] = 'Cancel',
    _a[insertHyperlinkInsert] = 'Insert',
    // Insert File
    _a[insertFile] = 'Insert file',
    _a[insertFileDialogTitle] = 'Insert file',
    _a[insertFileAddress] = 'Web address',
    _a[insertFileTitle] = 'Title',
    _a[insertFileCancel] = 'Cancel',
    _a[insertFileInsert] = 'Insert',
    // Insert Image
    _a[insertImage] = 'Insert image',
    _a[insertImageDialogTitle] = 'Insert image',
    _a[insertImageAddress] = 'Web address',
    _a[insertImageTitle] = 'Title',
    _a[insertImageAltText] = 'Alternate text',
    _a[insertImageWidth] = 'Width (px)',
    _a[insertImageHeight] = 'Height (px)',
    _a[insertImageCancel] = 'Cancel',
    _a[insertImageInsert] = 'Insert',
    // View HTML
    _a[viewHtml] = 'View HTML',
    _a[viewHtmlDialogTitle] = 'View HTML',
    _a[viewHtmlCancel] = 'Cancel',
    _a[viewHtmlUpdate] = 'Update',
    // Find and Replace
    _a[findReplaceToolTitle] = 'Find and Replace',
    _a[findReplaceDialogTitle] = 'Find and Replace',
    _a[findReplaceTabFind] = 'Find',
    _a[findReplaceTabReplace] = 'Replace',
    _a[findReplaceFindWhat] = 'Find What:',
    _a[findReplaceReplaceWith] = 'Replace With',
    _a[findReplaceReplace] = 'Replace',
    _a[findReplaceReplaceAll] = 'Replace All',
    _a[findReplaceMatchCase] = 'Match Case',
    _a[findReplaceMatchWord] = 'Match whole word only',
    _a[findReplaceMatchCyclic] = 'Match cyclic (Wrap around)',
    _a[findReplaceUseRegExp] = 'Regular Expression',
    _a[findReplacePrevMatch] = 'Prev',
    _a[findReplaceNextMatch] = 'Next',
    _a[findReplaceMatches] = '{0} of {1} matches',
    _a[iframeTitle] = 'Editable area. Press Alt + F10 for toolbar.',
    _a);
/**
 * @hidden
 */
export var keys = {
    bold: bold,
    italic: italic,
    underline: underline,
    strikethrough: strikethrough,
    subscript: subscript,
    superscript: superscript,
    unlink: unlink,
    undo: undo,
    redo: redo,
    fontSize: fontSize,
    fontName: fontName,
    format: format,
    alignLeft: alignLeft,
    alignRight: alignRight,
    alignCenter: alignCenter,
    alignJustify: alignJustify,
    indent: indent,
    outdent: outdent,
    orderedList: orderedList,
    bulletList: bulletList,
    print: print,
    selectAll: selectAll,
    cleanFormatting: cleanFormatting,
    pdf: pdf,
    foregroundColor: foregroundColor,
    backgroundColor: backgroundColor,
    createTable: createTable,
    createTableHint: createTableHint,
    addRowBefore: addRowBefore,
    addRowAfter: addRowAfter,
    addColumnBefore: addColumnBefore,
    addColumnAfter: addColumnAfter,
    deleteRow: deleteRow,
    deleteColumn: deleteColumn,
    deleteTable: deleteTable,
    mergeCells: mergeCells,
    splitCell: splitCell,
    insertHyperlink: insertHyperlink,
    insertHyperlinkDialogTitle: insertHyperlinkDialogTitle,
    insertHyperlinkAddress: insertHyperlinkAddress,
    insertHyperlinkTitle: insertHyperlinkTitle,
    insertHyperlinkNewWindow: insertHyperlinkNewWindow,
    insertHyperlinkCancel: insertHyperlinkCancel,
    insertHyperlinkInsert: insertHyperlinkInsert,
    insertFile: insertFile,
    insertFileDialogTitle: insertFileDialogTitle,
    insertFileAddress: insertFileAddress,
    insertFileTitle: insertFileTitle,
    insertFileCancel: insertFileCancel,
    insertFileInsert: insertFileInsert,
    insertImage: insertImage,
    insertImageDialogTitle: insertImageDialogTitle,
    insertImageAddress: insertImageAddress,
    insertImageTitle: insertImageTitle,
    insertImageAltText: insertImageAltText,
    insertImageWidth: insertImageWidth,
    insertImageHeight: insertImageHeight,
    insertImageCancel: insertImageCancel,
    insertImageInsert: insertImageInsert,
    viewHtml: viewHtml,
    viewHtmlDialogTitle: viewHtmlDialogTitle,
    viewHtmlCancel: viewHtmlCancel,
    viewHtmlUpdate: viewHtmlUpdate,
    findReplaceToolTitle: findReplaceToolTitle,
    findReplaceDialogTitle: findReplaceDialogTitle,
    findReplaceTabFind: findReplaceTabFind,
    findReplaceTabReplace: findReplaceTabReplace,
    findReplaceFindWhat: findReplaceFindWhat,
    findReplaceReplaceWith: findReplaceReplaceWith,
    findReplaceReplace: findReplaceReplace,
    findReplaceReplaceAll: findReplaceReplaceAll,
    findReplaceMatchCase: findReplaceMatchCase,
    findReplaceMatchWord: findReplaceMatchWord,
    findReplaceMatchCyclic: findReplaceMatchCyclic,
    findReplaceUseRegExp: findReplaceUseRegExp,
    findReplacePrevMatch: findReplacePrevMatch,
    findReplaceNextMatch: findReplaceNextMatch,
    findReplaceMatches: findReplaceMatches,
    iframeTitle: iframeTitle
};
