"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import CommonSecurity from "../../../server/common/generic/common_security";
import CommonEditablesPageTitleBar from "../../widgets/pageTitleBar/common_editables_page_title_bar";
import TextAttribute from "../../editor/attributes/text_attribute";
import { EDITOR_TYPES } from "../../editor/editor_constants";
import Section from "../../editor/widgets/section";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import TextAreaAttribute from "../../editor/attributes/text_area_attribute";
import { TrainingBaseEditor } from "./training_base_editor";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { CheckboxListAttribute } from "../../editor/attributes/checkbox_list_attribute";
import { SortingFunctions } from "../../utils/filter_helper";
import { AttributeLink } from "../../widgets/generic/attribute_link";
import { Trans } from "react-i18next";
import TrainingContentsAttribute from "../widgets/training_contents_attribute";

// i18next-extract-mark-ns-start documents
/**
 * This class is responsible for showing Curriculum records. This is a group of documents that users should train on, often
 * assigned to a department or certain group of people.
 */
class Curriculum extends TrainingBaseEditor {
  constructor(props) {
    super(props, "Curriculum", "Curriculum", "Curriculum", ["Document"]);

    const {t} = props;

    this.setStateSafely({
      departments: this.isRecordCached() && props.cachedData.departments || "[]",
    });

    if (this.isAdd()) {
      this.setStateSafely({
        breadcrumb: {
          current: t("New Curriculum"),
          parent: t("Training"),
          parentLink: "/training/list.html",
        }
      });
    }
  }

  componentDidMount() {
    document.title = "QbDVision Trainingß";

    super.componentDidMount();
  }

  onDataReceivedFromServer() {
    super.onDataReceivedFromServer();

    const {t} = this.props;
    let curriculum = {typeCode: "CUR", id: this.state.id, name: this.state.name};
    this.setStateSafely({
      breadcrumb: {
        current: UIUtils.getRecordCustomLabelForDisplay(curriculum),
        parent: t("Training"),
        parentLink: "/training/list.html",
      }
    });
  }

  getDepartmentTooltipText(option) {
    const {users} = this.state;
    const {t} = this.props;
    const usersInDept = users.filter(user => user.enabled && user.department === option.value);
    return (
      <>
        <div className={"info-tooltip-title"}>
          {option.displayName}
        </div>
        <div className={"info-tooltip-subtitle"}>
          <Trans t={t} count={usersInDept.length}>
            <span className="header-item-count">{{count: usersInDept.length}}</span><span> User</span>
          </Trans>
        </div>
        {usersInDept.map(item => {
          return (
            <AttributeLink
              key={item.email}
              baseTypeName={"User"}
              instance={item}
              className="curriculum-department-tooltip"
              renderName={item => item.fullName}
              noId
            />
          );
        })}
      </>
    );
  }

  renderPageTitleBar() {
    return (this.getEditorType() === EDITOR_TYPES.FULL_SCREEN)
      ? (<CommonEditablesPageTitleBar
          recordName={this.state.name}
          recordId={this.state.id}
          name="Training"
          typeCode={UIUtils.getTypeCodeForModelName(this.baseTypeName)}
          currentState={this.getCurrentState()}
        />
      )
      : "";
  }

  getTabName() {
    const {t} = this.props;
    return t("Curricula");
  }

  shouldShowNav() {
    return false;
  }

  renderAttributes() {
    const {t} = this.props;

    const departments = Object.entries(CommonSecurity.Departments)
      .map(([key, value]) => ({key, value, displayName: t(value)}))
      .sort(SortingFunctions.stringAscending(item => item.displayName));

    return <Section parent={this}
                    header={this.getGeneralHeader()}
                    collapsible={false}
    >
      <div className="row">
        <TextAttribute name="name" parent={this} />
        <TextAreaAttribute
          name="description"
          displayName={t("Description")}
          className="col-sm-12"
          parent={this}
        />
        <CheckboxListAttribute
          name={"departments"}
          displayName={t("Assign to Departments")}
          className={"col-sm-12"}
          t={t}
          options={departments}
          parent={this}
          formatTooltip={this.getDepartmentTooltipText}
        />
      </div>
      <Section
        id="documents"
        parent={this}
        showDocLinks={false}
        header={t("Documents")}
      >
        <TrainingContentsAttribute
          name={"Document"}
          isSimpleManyToMany={true}
          linkToObject={["Document"]}
          linkToObjectId={["id"]}
          linkObject={["Document"]}
          displayName={t("Documents")}
          className={"col-sm-12"}
          typeaheadItems={this.getTypeaheadDocuments()}
          parent={this}
          isLoading={this.state.isLoadingTypeahead}
        />
      </Section>
      <Section id="references"
               parent={this}
               showDocLinks={true}
               addOptions={[
                 {id: "references", label: "References"},
                 {id: "standards", label: "Standards"},
                 {id: "guidances", label: "Guidances"}]}
               header={
                 <span>References & Standards
                     <InfoTooltip
                       id="infoRefAndStandardsControl"
                       verbiage={
                         <div>{t("Use this section to link or upload any relevant references, standards, or guidelines supporting the training or its related parts.")}</div>}
                     /></span>}
      />
    </Section>;
  }
}

export default I18NWrapper.wrap(Curriculum, ["documents", "users"]);
// i18next-extract-mark-ns-stop documents
