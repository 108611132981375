"use strict";

import * as UIUtils from "../../ui_utils";
import { EDITOR_OPERATIONS } from "../../editor/editor_constants";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import { orderAndIndexSteps } from "../indexers/uo_indexer";
import { TYPE_CODE } from "../process_explorer_constants";
import BaseAutoBind from "../../base_auto_bind";
import MemoryCache from "../../utils/cache/memory_cache";
const Logger = Log.group(LOG_GROUP.ProcessExplorer, "ProcessExplorerPage");

export default class RecordOperator extends BaseAutoBind {
  constructor(props) {
    super(props);
    this.parent = props.parent;
    this.projectId = props.projectId;
  }

  handleClickRecord(record, isLeftClick) {
    if (record && isLeftClick) {
      let key = UIUtils.parseKey(record.staticPanelKey);
      if (key) {
        Logger.verbose(() => `Setting selected object to: ${key.typeCode}-${key.id}, ${record.key} (${record.order})`);
        this.handleViewRecord(key.typeCode, key.id);
      }
    }
    this.parent.setStateSafely({selectedNode: record});
  }

  handleViewRecord(typeCode, id) {
    const {parent} = this;
    let selectedRecord = parent.processExplorerHelper.findStaticPanelRecord(typeCode, id);
    parent.setStateSafely({
      selectedRecord,
      staticPanelInfo: {typeCode, id, operation: EDITOR_OPERATIONS.VIEW},
      shouldShowRightPanel: true,
    });
  }

  handleAddRecord(obj) {
    const {parent} = this;
    const {typeCode, parentId, parentTypeCode} = obj;
    const {processExplorerResults, processId} = parent.state;

    const selectedRecord = {
      ProjectId: this.projectId,
      ProcessId: processId,
      modelName: UIUtils.getModelNameForTypeCode(typeCode),
      currentState: UIUtils.VERSION_STATES.DRAFT,
    };

    // Save the user a few clicks by setting the data we know about on the new record based on the context of where they came from.
    if (parentId) {
      const memoryCache = MemoryCache.getNamedInstance("record_operator");
      const cacheKey = parentTypeCode;
      let nodes = memoryCache.get(cacheKey);
      if (!nodes) {
        const parentTypeaheadCache = new TypeaheadObjectCache(
          UIUtils.convertToId(UIUtils.getModelNameForTypeCode(parentTypeCode)),
          this.projectId, processId).getOptionsFromCache();

        if (!TypeaheadObjectCache.isObjectCacheStillLoading(parentTypeaheadCache)) {
          nodes = parentTypeaheadCache;
          memoryCache.set(cacheKey, nodes);
        }
      }

      if (nodes) {
        const parent = nodes.find(obj => obj.id === parentId);
        switch (typeCode) {
          case TYPE_CODE.UNIT_OPERATION: {
            let orderedUOList = processExplorerResults.orderedUOList;
            orderedUOList = orderedUOList ? orderedUOList.filter(uo => !uo.deletedAt) : orderedUOList;
            if (orderedUOList && orderedUOList.length > 0) {
              selectedRecord.PreviousUnitId = orderedUOList[orderedUOList.length - 1].id;
            }
          }
            break;
          case TYPE_CODE.PROCESS_COMPONENT:
          case TYPE_CODE.MATERIAL:
            switch (parentTypeCode) {
              case TYPE_CODE.UNIT_OPERATION:
                selectedRecord.UnitOperations = [parent];
                break;
              case TYPE_CODE.STEP:
                selectedRecord.Steps = [parent];
                selectedRecord.UnitOperations = [{
                  id: obj.unitOperationId,
                  typeCode: "UO"
                }];
                break;
            }
            break;
          case TYPE_CODE.MATERIAL_ATTRIBUTE:
          case TYPE_CODE.PROCESS_PARAMETER:
            selectedRecord.UnitOperationId = obj.unitOperationId;
            selectedRecord.StepId = obj.stepId;
            switch (parentTypeCode) {
              case TYPE_CODE.MATERIAL:
                selectedRecord.MaterialId = obj.parentId;
                break;
              case TYPE_CODE.PROCESS_COMPONENT:
                selectedRecord.ProcessComponentId = obj.parentId;
                break;
            }
            break;
          case TYPE_CODE.IQA:
          case TYPE_CODE.IPA:
            selectedRecord.UnitOperationId = obj.unitOperationId;
            selectedRecord.StepId = obj.stepId;
            break;
          case TYPE_CODE.STEP: {
            let stepsList = Object.values(processExplorerResults.stpMap).filter(stp => stp.UnitOperation.id === obj.parentId);
            stepsList = orderAndIndexSteps(stepsList, "id", false).filter(step => !step.deletedAt);
            if (stepsList && stepsList.length > 0) {
              selectedRecord.PreviousStepId = stepsList[stepsList.length - 1].id;
            }

            selectedRecord.UnitOperationId = parent.id;
          }
            break;
          default:
          // Do nothing for UOs
        }
      }
    }

    // Set the selected record data and show the right panel.
    parent.setStateSafely({
      selectedRecord,
      staticPanelInfo: {typeCode, operation: EDITOR_OPERATIONS.ADD},
      shouldShowRightPanel: true,
    }, () => {
      // Scroll up to the top on add
      let staticPanel = $("#quickPanelInnerContainer");
      staticPanel.scrollTop(0);
    });
  }
}
