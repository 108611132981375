/**
 * @hidden
 */
export var isPresent = function (value) { return value !== null && value !== undefined; };
/**
 * @hidden
 *
 * Fits the contender number into the specified bounds.
 * If the number is NaN or null, the minimum is returned.
 *
 * @param contender Represents the number you want to fit into the specified bounds.
 * @param min The inclusive lower bound number.
 * @param max The inclusive upper bound number.
 */
export var fitIntoBounds = function (contender, min, max) {
    if (!isPresent(contender) || isNaN(contender)) {
        return min;
    }
    return contender <= min ? min : contender >= max ? max : contender;
};
