"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import AdminBaseListTable from "./admin_list_table_base";
import AdminUtils from "../admin_utils";
import { getURLByTypeNameAndId } from "../../../helpers/url_helper";
import ReactDOM from "react-dom";
import { ACTION_TO_ICON_ENUM } from "../../../helpers/constants/constants";
import TableOptionsButton from "../../../widgets/tables/table_options_button";
import TableButton from "../../../widgets/tables/table_button";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import BaseReactComponent from "../../../base_react_component";

export default class UserListTable extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "ID",
        width: 60,
        data: result => result.id ? result.id : ""
      },
      {
        title: "First Name",
        width: 60,
        data: result => result.firstName ? result.firstName : ""
      },
      {
        title: "Last Name",
        width: 60,
        data: result => result.lastName ? result.lastName : ""
      },
      {
        title: "Email",
        width: 350,
        data: result => result.email ? result.email : ""
      },
      {
        title: "Company Name",
        width: 200,
        data: result => this.extractCompanyInfo(result, "name"),
        createdCell: (td, cellData, rowData) =>
          ReactDOM.render(
            <a onClick={() => this.handleClickCompany(rowData.Company)}>
              {cellData}
            </a>,
            td
          )
      },
      {
        title: "Created On",
        width: 60,
        data: (result, type) => AdminUtils.getDateAsData(type, result.createdAt)
      },
      {
        title: "Title",
        width: 60,
        data: result => result.title ? result.title : ""
      },
      {
        title: "Enabled",
        width: 60,
        data: result => (result.enabled ? "Yes" : "No")
      },
      {
        title: "Email Notifications",
        width: 60,
        data: result => (result.enableEmailNotifications ? "Yes" : "No")
      },
      {
        title: "Role",
        width: 60,
        data: result => (result.role ? result.role : "")
      },
      {
        title: "Department",
        width: 60,
        data: result => (result.department ? result.department : "")
      },
      {
        title: "License",
        width: 60,
        data: result => this.extractCompanyInfo(result, "license")
      },
      {
        title: "Trial Expiry Date",
        width: 60,
        data: (result, type) => {
          const trialExpiryDate = this.extractCompanyInfo(result, "trialExpiryDate");
          return AdminUtils.getDateAsData(type, trialExpiryDate);
        }
      },
      {
        title: "Last Login",
        width: 60,
        data: (result, type) => AdminUtils.getDateAsData(type, result.LastLogin)
      },
      {
        title: "Total Logins",
        width: 60,
        data: result => (result.TotalLogins ? result.TotalLogins : 0)
      },
      {
        title: "Total Projects",
        width: 60,
        data: result => (result.TotalProjects ? result.TotalProjects : 0)
      },
      {
        title: "Total Records in Draft",
        width: 60,
        data: result => (result.TotalDrafts ? result.TotalDrafts : 0)
      },
      {
        title: "Total Records Approved",
        width: 60,
        data: result => (result.TotalApprovals ? result.TotalApprovals : 0)
      },
      {
        title: "",
        width: "150px",
        containsHTML: true,
        hideSearch: true,
        orderable: false,
        data: result => result,
        createdCell: (td, cellData, rowData, row) => {
          let options = [
            {
              action: ACTION_TO_ICON_ENUM.EDIT,
              id: "editButton_" + row,
              title: "Edit",
              url: getURLByTypeNameAndId("user", "Edit", rowData.id),
            },
          ];
          return ReactDOM.render(
            (
              <div className="table-manage">
                <TableOptionsButton options={options} />
                <TableButton href={getURLByTypeNameAndId("user", "View", rowData.id)}
                             className="table-icon"
                             title="View"
                             id={"viewUsersButton_" + row}
                             fontAwesomeIcon={faChevronRight}
                />
              </div>
            ), td);
        }
      },
    ];
    this.columnDefs = [{type: "date", targets: [5, 10, 11]}];
  }

  extractCompanyInfo(item, field) {
    const company = item ? item.Company : null;
    const billingState =
      company && company.BillingStates && company.BillingStates.length
        ? company.BillingStates[0]
        : null;

    if (field === "name" && company) {
      return company.name;
    } else if (billingState && field === "license") {
      return UIUtils.capitalize(item.license);
    } else if (billingState && field === "trialExpiryDate") {
      return billingState.trialExpiryDate;
    }

    return "";
  }

  handleClickCompany(company) {
    this.props.onClickCompany(company);
  }

  render() {
    return (
      <AdminBaseListTable
        id={this.props.id ? this.props.id : "usersTable"}
        columns={this.columns}
        columnDefs={this.columnDefs}
        data={this.props.users}
        scrollX
      />
    );
  }
}
