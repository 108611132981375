"use strict";

import * as UIUtils from "../../../../../ui_utils";
import React from "react";
import { PAPER_DATA_UPDATE_TYPE } from "../../../../constants/import_constants";
import DateEditor from "../editors/date_editor";
import BatchDataAttributesEditor from "./batch_data_attributes_editor";
import TextEditor from "../editors/text_editor";
import ETLData from "../etl_data";
import { getURLByKey } from "../../../../../helpers/url_helper";
import ConfidenceLevelsPanel from "./confidence_levels_panel";
import Typeahead from "../../../../../widgets/typeahead";
import TypeaheadObjectCache from "../../../../../utils/cache/typeahead_object_cache";
import BaseReactComponent from "../../../../../base_react_component";
import { MODEL_DECLARATIONS } from "../../../../../../server/common/generic/common_models";

/**
 * This is responsible for rendering the batch data information and attributes measurements edit panel in the AI Import
 * edit screen. This is the right side panel of the Upload and Extract step in the AI import batch data edit screen.
 */
export default class BatchDataEditorWidget extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.handleETLDataModified = this.handleETLDataModified.bind(this);

    this.state = {
      suppliers: []
    };

    new TypeaheadObjectCache("Supplier", props.projectId).loadOptions(this.handleSupplierLoaded.bind(this));
  }

  componentDidUpdate() {
    $("[data-toggle='popover']").popover({sanitizeFn: UIUtils.sanitizePopoverData});
  }

  handleSupplierLoaded(suppliers) {
    suppliers = suppliers.filter(supplier => !supplier.deletedAt);
    this.setStateSafely({suppliers});
  }

  handleSupplierRecordSelected(value) {
    let supplier = null;
    if (value && value[0]) {
      supplier = value[0];
    }

    this.handleETLDataModified({
      type: PAPER_DATA_UPDATE_TYPE.MODIFY_SUPPLIER,
    },
    supplier, null);
  }

  handleETLDataModified(updateMetaData, newValue, callback) {
    //We need to work on a copy of the data here so that the props and nextProps of child components work as expected.
    const {batchData, dataAttributes, userUpdates} = this.props.etlData;
    let newETLData = new ETLData({batchData, dataAttributes, userUpdates});
    newETLData.updateData(updateMetaData, newValue, callback);
    this.props.onETLDataUpdated(newETLData);
  }

  render() {
    const {etlData, selectedDependencyRecord} = this.props;
    const {batchData} = etlData;
    let dependencyKey, dependencyName;
    if (selectedDependencyRecord) {
      let dependencyParts = selectedDependencyRecord.label.split(" - ");
      dependencyKey = dependencyParts.shift();
      dependencyName = dependencyParts.join(" - ");
    }

    const { suppliers} = this.state;
    const selectedSupplier = suppliers.find(supplier => supplier.id === batchData?.batchInfo?.supplierId);

    return (
      <div className="batch-data-editor-div">
        <div className="batch-data-editor-header-div">
          <div className="batch-data-editor-dependency-div">
            {selectedDependencyRecord ? (
              <a href={getURLByKey(dependencyKey, "View", false)}
                 rel="noreferrer noopener"
                 target="_blank">
                {`${dependencyKey} - ${dependencyName}`}
              </a>
            ) : ""}
          </div>
          <div className="batch-data-editor-help-div">
            <ConfidenceLevelsPanel />
          </div>
        </div>
        <div className="import-paper-batch-div">
          <div className="import-paper-batch-attribute">
            <span className="col-form-label">{ this.props.selectedDependencyRecord?.typeCode !== MODEL_DECLARATIONS.LIBRARY_MATERIAL.typeCode ? "Batch / Lot ID" : "Lot ID"} *</span>
            <div>
              <TextEditor id="batchIdEditor"
                          value={batchData.batchInfo.batchId}
                          validationStatus={batchData.batchInfo.batchIdValidationStatus}
                          confidence={batchData.batchInfo.confidence}
                          disabled={this.props.disabled}
                          onChange={this.handleETLDataModified.bind(this, {
                            type: PAPER_DATA_UPDATE_TYPE.MODIFY_BATCH_ID,
                          })} />
            </div>
          </div>
          <div className="import-paper-batch-attribute">
            <span className="col-form-label">Scale</span>
            <div>
              <TextEditor id="batchScaleEditor"
                          value={batchData.batchInfo.scale}
                          disabled={this.props.disabled}
                          onChange={this.handleETLDataModified.bind(this, {
                            type: PAPER_DATA_UPDATE_TYPE.MODIFY_SCALE,
                          })} />
            </div>
          </div>
          <div className="import-paper-batch-attribute">
            <span className="col-form-label">Supplier</span>
            <div>
              <div id="supplierTypeaheadDiv"
                   className="smart-select-dependency-field-with-tooltip-div"
              >
                <Typeahead id="supplierTypeahead"
                           inputProps={{id: "supplierTypeaheadInput"}}
                           ref={(ref) => this.supplierSelectionTypeahead = ref}
                           options={suppliers}
                           multiple={false}
                           selected={selectedSupplier ? [selectedSupplier] : null}
                           onChange={this.handleSupplierRecordSelected}
                />
              </div>
            </div>
          </div>
          <div className="import-paper-batch-attribute date-attribute">
            <span className="col-form-label">Start Date</span>
            <div>
              <DateEditor id="batchStartDateEditor"
                          value={batchData.batchInfo.startDate}
                          confidence={batchData.batchInfo.startDateConfidence}
                          validationStatus={batchData.batchInfo.startDateValidationStatus}
                          disabled={this.props.disabled}
                          maxDate={batchData.batchInfo.manufactureDate}
                          onChange={this.handleETLDataModified.bind(this, {
                            type: PAPER_DATA_UPDATE_TYPE.MODIFY_BATCH_START_DATE,
                          })} />
            </div>
          </div>

          <div className="import-paper-batch-attribute date-attribute">
            <span className="col-form-label">Manufacture Date</span>
            <div>
              <DateEditor id="batchDateOfManufactureEditor"
                          value={batchData.batchInfo.manufactureDate}
                          confidence={batchData.batchInfo.manufactureDateConfidence}
                          validationStatus={batchData.batchInfo.manufactureDateValidationStatus}
                          disabled={this.props.disabled}
                          minDate={batchData.batchInfo.startDate}
                          maxDate={batchData.batchInfo.releaseDate}
                          onChange={this.handleETLDataModified.bind(this, {
                            type: PAPER_DATA_UPDATE_TYPE.MODIFY_BATCH_MANUFACTURE_DATE,
                          })} />
            </div>
          </div>
          <div className="import-paper-batch-attribute date-attribute">
            <span className="col-form-label">Release Date</span>
            <div>
              <DateEditor id="batchDateOfReleaseEditor"
                          value={batchData.batchInfo.releaseDate}
                          confidence={batchData.batchInfo.releaseDateConfidence}
                          validationStatus={batchData.batchInfo.releaseDateValidationStatus}
                          disabled={this.props.disabled}
                          minDate={batchData.batchInfo.manufactureDate}
                          maxDate={batchData.batchInfo.expirationDate}
                          onChange={this.handleETLDataModified.bind(this, {
                            type: PAPER_DATA_UPDATE_TYPE.MODIFY_BATCH_RELEASE_DATE,
                          })} />
            </div>
          </div>
          <div className="import-paper-batch-attribute date-attribute">
            <span className="col-form-label">Expiration Date</span>
            <div>
              <DateEditor id="batchDateOfExpirationEditor"
                          value={batchData.batchInfo.expirationDate}
                          confidence={batchData.batchInfo.expirationDateConfidence}
                          validationStatus={batchData.batchInfo.expirationDateValidationStatus}
                          disabled={this.props.disabled}
                          minDate={batchData.batchInfo.releaseDate}
                          onChange={this.handleETLDataModified.bind(this, {
                            type: PAPER_DATA_UPDATE_TYPE.MODIFY_BATCH_EXPIRATION_DATE,
                          })} />
            </div>
          </div>
        </div>
        <div className="import-paper-batch-measurements-div">
          <BatchDataAttributesEditor batchData={batchData}
                                     disabled={this.props.disabled}
                                     etlData={etlData}
                                     onChange={this.handleETLDataModified} />
        </div>
      </div>
    );
  }
}
