"use strict";

import { DEFAULT_ACTIONS, MATERIAL_OPTIONS_ACTIONS } from "./project_material_actions";
import { SYNC_MATERIAL_STEPS, FROM_LIBRARY_STATUS } from "../../library_constants";
import { MATERIAL_ACTIONS } from "../../helpers/library_helper";

function showOptionsWizardStep(wizardStepsConfig, materialFromLibrary) {

  const [selectionStep, optionsStep] = wizardStepsConfig;
  const selectionState = {[materialFromLibrary.id]: true};
  const wizardCfg = [
    {
      ...selectionStep,
      visible: false,
      disabled: true
    },
    {
      ...optionsStep,
      visible: true,
      disabled: false,
      initialState: materialFromLibrary
    }
  ];

  return {
    forceRedirectToStepId: optionsStep.wizardStepId,
    wizardStepsConfig: wizardCfg,
    currentFlowStep: SYNC_MATERIAL_STEPS.SyncMaterialOptions,
    selectionState
  };
}

/**
 * The reducer for the whole flow
 * @param state
 * @param action
 * @returns The latest state after mutations.
 */
export const defaultReducer = (state, action) => {
  switch (action.type) {
    case DEFAULT_ACTIONS.StepDataUpdated:
      return {
        ...state,
        wizardStepsConfig: [...action.wizardStepsConfig],
        selectionState: action.selectionState,
      };
    case DEFAULT_ACTIONS.NavTabClicked:
    case DEFAULT_ACTIONS.PreviousStep:
    case DEFAULT_ACTIONS.NextButtonClicked: {
      return {
        ...state,
        wizardStepsConfig: [...action.wizardStepsConfig],
        currentFlowStep: action.currentFlowStep
      };
    }
    case DEFAULT_ACTIONS.LibraryMaterialsLoaded: {
      const {libraryMaterials} = action;
      const {material, fromLibraryStatus, wizardStepsConfig} = state;
      const libMaterial = libraryMaterials.find(libMaterial => libMaterial.id === material?.fromLibrary?.LibraryMaterialId);

      const shouldUpdateWizard = libMaterial && state.action === MATERIAL_ACTIONS.SYNC
        && fromLibraryStatus === FROM_LIBRARY_STATUS.LINKED;

      const wizardState = shouldUpdateWizard
        ? showOptionsWizardStep(wizardStepsConfig, libMaterial)
        : {};

      return {
        ...state,
        ...action.state,
        ...wizardState,
        libraryMaterials,
      };
    }
    case DEFAULT_ACTIONS.UpdateStepState:
      return {
        ...state,
        wizardStepsConfig: [...action.wizardStepsConfig],
      };
    default:
      return state;
  }
};

/**
 * The reducer for the material options step.
 * @param state
 * @param action
 * @returns The latest state after mutation
 */
export const preferencesReducer = (state, action) => {
  switch (action.type) {
    case MATERIAL_OPTIONS_ACTIONS.UpdateFieldValue: {
      return {
        ...state,
        [action.attributeName]: action.attributeValue,
      };
    }
    default:
      return state;
  }
};