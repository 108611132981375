"use strict";
import * as UIUtils from "../../../ui_utils";

import { Log, LOG_GROUP } from "../../../../server/common/logger/common_log";
import { Ensure } from "../../../../server/common/generic/common_ensure";
import pdfJS from "./pdf_js_bridge";
import { CachedPDFDocument } from "./cached_pdf_document";

// eslint-disable-next-line no-unused-vars
const Logger = Log.group(LOG_GROUP.Documents, "PDFCache");

/**
 * @typedef {import("pdfjs-dist/index.d.ts").PDFSource} IPDFSource
 * @property [url] {string}
 * @property [data] {Buffer|ArrayBuffer|Uint8Array}
 * @property [uuid] {string}
 */

/**
 * The maximum number of attempts to retry a document download on error.
 * @type {number}
 */
const MAX_DOWNLOAD_RETRIES = 10;

/**
 * The interval between download retry attempts.
 * @type {number}
 */
const DOWNLOAD_RETRY_INTERVAL = 1000;

/**
 * Manages a cache of loaded PDF documents so that you don't need to reload them all the time.
 */
export class PDFCache {
  constructor() {
    /**
     * The internal cache instance.
     * @type {Map<IPDFSource, ICachedPDFDocument>}
     * @private
     */
    this._cache = new Map();
  }

  async invalidateRenderCache(src) {
    let cacheKey = this.getUniqueCacheKey(src);
    let cachedDocument = this.getCachedDocument(cacheKey);

    if (cachedDocument) {
      Logger.info("Invalidating render cache for document", Log.object(src));
      cachedDocument._pageCache.clear();
      cachedDocument.renderObject = {};
    }
  }

  /**
   * Loads a document or retrieves it from cache given its source (url or data buffer)
   * @param src {IPDFSource|PDFSource} The source of the PDF document (it's an object that can hold a URL or a buffer/byte array).
   * @returns {ICachedPDFDocument}
   */
  async getDocument(src) {
    let cacheKey = this.getUniqueCacheKey(src);
    let cachedDocument = this.getCachedDocument(cacheKey);

    if (!cachedDocument) {
      /**
       * @type {PDFDocumentProxy}
       */
      let pdfDocument;
      /**
       * @type {Error}
       */
      let downloadError;
      for (let i = 0; i < MAX_DOWNLOAD_RETRIES; i++) {
        try {
          pdfDocument = await (pdfJS.getDocument(src).promise);
          cachedDocument = CachedPDFDocument.fromDocument(pdfDocument);
          downloadError = null;
        } catch (error) {
          downloadError = new Error(error.message);
          downloadError.name = error.name;
          downloadError.code = error.code;
          downloadError.stack += "\n\n --- INNER ERROR --- \n\n" + error.stack;

          if (i < MAX_DOWNLOAD_RETRIES - 1) {
            Logger.warn("An error occurred while downloading the document", Log.error(downloadError));
          }
          await new Promise(resolve => setTimeout(resolve, DOWNLOAD_RETRY_INTERVAL));
        }
      }

      if (downloadError) {
        Logger.error("An error occurred while downloading the document", Log.error(downloadError));
        throw downloadError;
      }
      this.setCachedDocument(cacheKey, cachedDocument);
    }
    return cachedDocument;
  }

  /**
   *
   * @param src {IPDFSource} The source of the PDF document (it's an object that can hold a URL or a buffer/byte array).
   * @param document {ICachedPDFDocument} The document to store in cache.
   */
  setCachedDocument(src, document) {
    let cacheKey = this.getUniqueCacheKey(src);
    this._cache.set(cacheKey, document);
    return document;
  }

  /**
   * Retrieves a document from cache given its source (url or data)
   * @param src {IPDFSource} The source of the PDF document (it's an object that can hold a URL or a buffer/byte array).
   * @returns {ICachedPDFDocument}
   */
  getCachedDocument(src) {
    let document = null;
    let key = this.getUniqueCacheKey(src);

    // we don't need to compare source url and data again, since we may have already done that in the step before
    if (this.isDocumentCached(key)) {
      document = this._cache.get(key);
    }
    return document;
  }

  /**
   * Checks whether or not a document with the specified source (url or data) is in cache
   * @param src {IPDFSource} The source of the PDF document (it's an object that can hold a URL or a buffer/byte array).
   * @returns {boolean}
   */
  isDocumentCached(src) {
    Ensure.that({src}).isNotFalsy();

    let key = this.getUniqueCacheKey(src);
    return this._cache.has(key);
  }

  /**
   * Since we're using an instance of {@link IPDFSource} as the cache key, and that instance may have different properties
   * with large values, such as data URLs, we have a way to retrieve a unique cache key
   * (we add a UUID to the src object and use that).
   *
   * That may be an expensive operation, though, so we can disable that additional check if we want.
   * @param src {IPDFSource|string} The source of the PDF document or a UUID string.
   * @private
   * @returns {string}
   */
  getUniqueCacheKey(src) {
    let key;
    if (typeof src === "object" && !src.uuid) {
      src.uuid = UIUtils.generateUUID();
      key = src.uuid;
    } else {
      key = src;
    }
    return key;
  }

  /**
   * Determines whether or not the specified source is the same as the one already stored.
   * @param firstSource {IPDFSource} The source of the PDF document (it's an object that can hold a URL or a buffer/byte array)
   * @param secondSource {IPDFSource} The source of the PDF document (it's an object that can hold a URL or a buffer/byte array)
   * @returns {boolean}
   */
  static isSameSource(firstSource, secondSource) {
    // this code is intentionally split into multiple variables for clarity
    const hasFirstSource = !!secondSource;
    const hasSecondSource =  !!firstSource;

    // if the UUID is the same, we're good and we don't need to proceed.
    if (hasFirstSource && hasSecondSource && firstSource.uuid === secondSource.uuid) {
      return true;
    }

    const didDataChange = hasFirstSource && hasSecondSource && secondSource.data !== firstSource;
    const didURLChange = hasFirstSource && hasSecondSource && secondSource.url !== firstSource;

    let sourceChanged = hasFirstSource !== hasSecondSource || didDataChange || didURLChange;

    return !sourceChanged;
  }

  /**
   * Retrieves the default instance of the PDF cache (this instance will be shared).
   * @returns {PDFCache}
   */
  static getDefault() {
    return defaultInstance;
  }
}


const defaultInstance = new PDFCache();
