var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { validatePackage } from '@progress/kendo-licensing';
import { packageMetadata } from '../package-metadata';
import { classNames, useDir, dispatchEvent } from '@progress/kendo-react-common';
import { BreadcrumbListItem } from './BreadcrumbListItem';
import { BreadcrumbDelimiter } from './BreadcrumbDelimiter';
import { BreadcrumbLink } from './BreadcrumbLink';
import { BreadcrumbOrderedList } from './BreadcrumbOrderedList';
/**
 * Represents the Breadcrumb component.
 */
export var Breadcrumb = React.forwardRef(function (props, ref) {
    validatePackage(packageMetadata);
    var target = React.useRef(null);
    var breadcrumbRef = React.useRef(null);
    var OrderedListComponent = React.useMemo(function () { return props.breadcrumbOrderedList || BreadcrumbOrderedList; }, [props.breadcrumbOrderedList]);
    var ListItemComponent = React.useMemo(function () { return props.breadcrumbListItem || BreadcrumbListItem; }, [props.breadcrumbListItem]);
    var DelimiterComponent = React.useMemo(function () { return props.breadcrumbDelimiter || BreadcrumbDelimiter; }, [props.breadcrumbDelimiter]);
    var LinkComponent = React.useMemo(function () { return props.breadcrumbLink || BreadcrumbLink; }, [props.breadcrumbLink]);
    var focus = React.useCallback(function () {
        if (breadcrumbRef.current) {
            breadcrumbRef.current.focus();
        }
    }, [breadcrumbRef]);
    var disabled = React.useMemo(function () { return props.disabled || false; }, [props.disabled]);
    React.useImperativeHandle(target, function () { return ({
        element: breadcrumbRef.current,
        focus: focus,
        props: props
    }); });
    React.useImperativeHandle(ref, function () { return target.current; });
    var dir = useDir(breadcrumbRef, props.dir);
    var handleItemSelect = function (event) {
        if (target.current) {
            dispatchEvent(props.onItemSelect, event, event.target, { id: event.target.id });
        }
    };
    var handleKeyDown = function (event) {
        if (target.current) {
            dispatchEvent(props.onKeyDown, event, event.target, { id: event.target.id });
        }
    };
    var valueField = (props.valueField || defaultProps.valueField);
    var iconField = (props.iconField || defaultProps.iconField);
    var iconClassField = (props.iconClassField || defaultProps.iconClassField);
    var textField = (props.textField || defaultProps.textField);
    return (React.createElement("nav", { "aria-label": props.ariaLabel, id: props.id, style: props.style, ref: breadcrumbRef, dir: dir, className: classNames('k-breadcrumb', {
            'k-rtl': dir === 'rtl',
            'k-disabled': disabled
        }, props.className) },
        React.createElement(OrderedListComponent, null,
            React.createElement(React.Fragment, null, props.data.map(function (item, index, data) {
                var key = item[valueField];
                return (React.createElement(ListItemComponent, { key: key },
                    index !== 0 && React.createElement(DelimiterComponent, null),
                    React.createElement(LinkComponent, __assign({ isLast: data.length - 1 === index, id: String(key), icon: item[iconField] || undefined, iconClass: item[iconClassField] ? String(item[iconClassField]) : undefined, text: item[textField] ? String(item[textField]) : undefined, disabled: item.disabled || false, onItemSelect: handleItemSelect, onKeyDown: handleKeyDown }, props))));
            })))));
});
var propTypes = {
    id: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    breadcrumbOrderedList: PropTypes.elementType,
    breadcrumbListItem: PropTypes.elementType,
    breadcrumbDelimiter: PropTypes.elementType,
    breadcrumbLink: PropTypes.elementType,
    data: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
        icon: PropTypes.any,
        iconClass: PropTypes.string
    })),
    dir: PropTypes.oneOf(['ltr', 'rtl']),
    disabled: PropTypes.bool,
    valueField: PropTypes.string,
    textField: PropTypes.string,
    iconField: PropTypes.string,
    iconClassField: PropTypes.string,
    onItemSelect: PropTypes.func,
    ariaLabel: PropTypes.string
};
var defaultProps = {
    valueField: 'id',
    textField: 'text',
    iconField: 'icon',
    iconClassField: 'iconClass'
};
Breadcrumb.displayName = 'KendoReactBreadcrumb';
// TODO: delete casting when @types/react is updated!
Breadcrumb.propTypes = propTypes;
Breadcrumb.defaultProps = defaultProps;
