"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import PropTypes from "prop-types";
import { ProcessExplorerDiagramFacade } from "./process_explorer_diagram_facade";
import DiagramBottomBar, { MAX_NODES_TO_EXPAND_OR_COLLAPSE } from "./diagram_bottom_bar";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { getURLByKey } from "../../helpers/url_helper";
import ImplementationNeededError from "../../utils/implementation_needed_error";
import { ACTION_TO_ICON_ENUM } from "../../helpers/constants/constants";
import BaseReactComponent from "../../base_react_component";
import CommonConstants from "../../../server/common/generic/common_constants";

/**
 *  This shows the process diagram in the process explorer.
 */
// i18next-extract-mark-ns-start process_explorer
class ProcessExplorerDiagram extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return JSON.stringify(this.props.nodes) !== JSON.stringify(nextProps.nodes)
      || JSON.stringify(this.props.links) !== JSON.stringify(nextProps.links)
      || JSON.stringify(this.props.panelSizes) !== JSON.stringify(nextProps.panelSizes)
      || this.props.showArchived !== nextProps.showArchived
      || this.state.selectedNode !== nextState.selectedNode
      || this.props.isLoading !== nextProps.isLoading;
  }

  componentDidMount() {
    super.componentDidMount();
    this.diagram = new ProcessExplorerDiagramFacade("processExplorerContainerDiv", this.props.nodes, this.props.links,
      this.props.showArchived, this.handleOnClick, this.props.onAdd, this.handleContextMenuClick, this.handleHeaderMenuClick,
      this.props.onCheckPermissions, this.props.t, this.props.projectId, this.props.processId);
    this.diagram.reLayoutDiagram();

    /* This is required to make the diagram accessible by the tests. The tests use the browser.execute method
      to access the GoJS diagram object and the API exposed by it. This way they can get the on-screen status
      of the diagram and compare it with the expected output.
     */
    window.processExplorerDiagram = this.diagram.diagram;
  }

  componentDidUpdate(prevProps) {
    const didNodesOrLinksChange = JSON.stringify(prevProps.nodes) !== JSON.stringify(this.props.nodes)
      || JSON.stringify(prevProps.links) !== JSON.stringify(this.props.links);

    // This causes all the nodes to get reset back to their original state
    if (didNodesOrLinksChange) {
      this.diagram.mergeInNodesAndLinks(this.props.nodes, this.props.links);
      this.diagram.reLayoutDiagram();
      // Only reselect if the current selected record belongs to current selected process.
      // if we did not do this, we will get an error from process diagram saying that "The selected node just disappeared off of the diagram"
      let {selectedRecord, processId} = this.props;
      if (!selectedRecord || selectedRecord && processId === selectedRecord.ProcessId) {
        this.diagram.select(this.props.selectedRecord);
      }
    }

    if (prevProps.showArchived !== this.props.showArchived) {
      this.diagram.setShowArchived(this.props.showArchived);
    }
  }

  handleOnClick(nodeData, isLeft) {
    this.props.onClick(nodeData, isLeft);
    this.setStateSafely({selectedNode: nodeData});
  }

  handleHeaderMenuClick(typeCode, action, e) {
    const {projectId, processId} = this.props;
    UIUtils.ignoreHandler(e);
    const selectedNodes = this.diagram.getSelectedNodeData();
    if (selectedNodes.length > 0) {
      const firstSelectedNode = this.chooseParentOrChildNode(selectedNodes[0], e);
      switch (action) {
        case ACTION_TO_ICON_ENUM.COPY.title:
          this.props.onCopyFrom(firstSelectedNode, typeCode);
          break;
        case ACTION_TO_ICON_ENUM.ADD_FROM_LIBRARY.title:
          this.props.onCreateFromLibrary(typeCode, projectId, processId, selectedNodes);
          break;
        default:
          throw new ImplementationNeededError();
      }
    }
  }

  handleContextMenuClick(action, e) {
    UIUtils.ignoreHandler(e);
    let selectedNodes = this.diagram.getSelectedNodeData();
    if (selectedNodes.length > 0) {
      let firstSelectedNode = this.chooseParentOrChildNode(selectedNodes[0], e);
      switch (action) {
        case ACTION_TO_ICON_ENUM.VIEW.title:
          for (let i = 0; i < selectedNodes.length; i++) {
            let selectedNode = this.chooseParentOrChildNode(selectedNodes[i], e);
            window.open(getURLByKey(selectedNode.staticPanelKey, "View", true), "_blank");
          }
          break;
        case ACTION_TO_ICON_ENUM.EDIT.title:
          for (let i = 0; i < selectedNodes.length; i++) {
            let selectedNode = this.chooseParentOrChildNode(selectedNodes[i], e);
            window.open(getURLByKey(selectedNode.staticPanelKey, "Edit", true), "_blank");
          }
          break;
        case ACTION_TO_ICON_ENUM.ARCHIVE.title:
          this.props.onArchiveOrRestoreButton(firstSelectedNode, CommonConstants.ACTIONS.ARCHIVE);
          break;
        case ACTION_TO_ICON_ENUM.RESTORE.title:
          this.props.onArchiveOrRestoreButton(firstSelectedNode, CommonConstants.ACTIONS.RESTORE);
          break;
        case ACTION_TO_ICON_ENUM.COPY.title:
          this.props.onCopyButton(firstSelectedNode);
          break;
        case ACTION_TO_ICON_ENUM.PROPOSE.title:
          this.props.onProposeButton(firstSelectedNode);
          break;
        default:
          throw new ImplementationNeededError();
      }
    }
  }

  chooseParentOrChildNode(object, e) {
    return e.isParent ? object.parent : object;
  }

  handleZoomIn() {
    this.diagram.zoomIn();
  }

  handleZoomOut() {
    this.diagram.zoomOut();
  }

  handleZoomExpand() {
    this.diagram.zoomExpand();
  }

  handleExpandAll() {
    if (this.props.nodes.length < MAX_NODES_TO_EXPAND_OR_COLLAPSE) {
      this.diagram.expandAll();
    }
  }

  handleCollapseAll() {
    if (this.props.nodes.length < MAX_NODES_TO_EXPAND_OR_COLLAPSE) {
      this.diagram.collapseAll();
    }
  }

  getAdditiveSkeletonClass() {
    return "d-none";
  }

  render() {
    const {nodes} = this.props;
    const numberOfNodes = nodes.length;

    return (
      <div className={"procexp-flow-diagram dropdown" + this.getClassForLoading()}>
        <div id="processExplorerContainerDiv" className="process-explorer-canvas-container" />
        <DiagramBottomBar onZoomIn={this.handleZoomIn}
                          onZoomOut={this.handleZoomOut}
                          onZoomExpand={this.handleZoomExpand}
                          onExpandAll={this.handleExpandAll}
                          onCollapseAll={this.handleCollapseAll}
                          numberOfNodes={numberOfNodes}
        />
      </div>
    );
  }
}

ProcessExplorerDiagram.propTypes = {
  nodes: PropTypes.array,
  links: PropTypes.array,
  panelSizes: PropTypes.array,
  selectedRecord: PropTypes.object,
  onArchiveOrRestoreButton: PropTypes.func,
  onCopyButton: PropTypes.func,
  onClick: PropTypes.func,
  projectId: PropTypes.any,
};

export default I18NWrapper.wrap(ProcessExplorerDiagram, "process_explorer");
// i18next-extract-mark-ns-stop process_explorer
