/* stylelint-disable-next-line import-notation */
@import "../../../css/brand_colors";

.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: unset;
  gap: 15px;

  :global(.tab-nav-bar-item.home-page-nav-bar-item) {
    padding: unset !important;
    margin: unset !important;
  }
}

