"use strict";


import LinkTechTransferBaseStep from "./link_tech_transfer_base_step";
import React, { Fragment } from "react";
import Typeahead from "../../../../widgets/typeahead";
import MultipleTypeaheadObjectCache from "../../../../utils/cache/multiple_typeahead_object_cache";
import * as UIUtils from "../../../../ui_utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";


export default class ProcessSelectionStep extends LinkTechTransferBaseStep {
  constructor(props) {
    super(props);
  }

  isReceivingProcessWithProposedRecords() {
    const {
      receivingRecords
    } = this.props;

    for (let record of [...receivingRecords.UnitOperations, ...receivingRecords.Steps]) {
      if (record.currentState === UIUtils.VERSION_STATES.PROPOSED
        || record.currentState === UIUtils.VERSION_STATES.PROPOSED_FOR_ARCHIVE
        || record.currentState === UIUtils.VERSION_STATES.PROPOSED_FOR_ARCHIVE_CASCADED) {
        return true;
      }
    }
    return false;
  }

  isProcessSelected() {
    return this.props.receivingProcess?.SendingId;
  }

  handleSendingSiteChange(selectedProcess) {
    const {project, parent} = this.props;
    const selectedProcessId = selectedProcess[0].id;
    const requestData = {
      excludeArchived: true,
    };
    this.props.receivingProcess.SendingId = selectedProcessId;
    this.props.receivingProcess.techTransferEnabled = true;

    let sendingRecords = {
      Steps: [],
      UnitOperations: [],
    };

    new MultipleTypeaheadObjectCache(["UnitOperation", "Step"], project.id, selectedProcessId).loadOptions((records) => {
      if (records.length) {
        if (records[0].typeCode === "UO") {
          sendingRecords.UnitOperations.push(...records.map(record => {
            record["displayName"] = `${record.name} (${record.typeCode}-${record.id})`;
            return record;
          }));
        } else {
          sendingRecords.Steps.push(...records.map(record => {
            record["displayName"] = `${record.name} (${record.typeCode}-${record.id})`;
            return record;
          }));
        }
      }
    }, requestData);
    parent.setStateSafely({
      selectedProcess,
      sendingRecords
    });
  }


  renderStep() {
    const {processes, receivingProcess, selectedProcess} = this.props;
    return (
      <Fragment>
        <div className="col-12">
          <div className="row import-wizard-step-inner-container">
            <div className="link-sending-site-wizard-steps-container">
              {processes && receivingProcess ?
                <div className="link-sending-site-container">
                  <div className="container link-sending-site-selector">
                    <label
                      htmlFor="projectDropDown"
                      className="col-form-label"
                    >
                      Sending Process
                    </label>
                    <Typeahead id="sendingSiteTypeahead"
                               inputProps={{id: "sendingSiteTypeaheadInput", autoComplete: "off"}}
                               options={processes}
                               selected={selectedProcess}
                               onChange={this.handleSendingSiteChange}
                               disabled={this.isReceivingProcessWithProposedRecords()}
                    />
                    {this.isReceivingProcessWithProposedRecords() ?
                      <div
                        className="alert alert-error link-sending-site-error-container"
                        role="alert"
                        id="sendingSiteAlert"
                      >
                        <div className="row">
                          <div className="col-1 alert-icon"><FontAwesomeIcon icon={faExclamationTriangle} size={"lg"} />
                          </div>
                          <div className="col-11">You have proposed records in this process! Approve or reject to enable tech transfer</div>
                        </div>
                      </div> : <div className="alert alert-warning link-sending-site-warning-container" role="alert" id="sendingSiteWarning">
                        <FontAwesomeIcon icon={faExclamationTriangle} size={"lg"} />
                        &nbsp;You cannot change the process later
                      </div>}
                  </div>
                  <div id="arrow">
                    <div className="bar" />
                    <div className="chevron-down" />
                  </div>
                  <div className="container link-sending-site-selector">
                    <label
                      htmlFor="processTypeahead"
                      id="receivingSiteTypeaheadLabel"
                      className="col-form-label"
                    >
                      Receiving Process
                    </label>
                    <Typeahead id="receivingSiteTypeahead"
                               inputProps={{id: "receivingSiteTypeaheadInput", autoComplete: "off"}}
                               options={[receivingProcess]}
                               defaultSelected={[receivingProcess]}
                               disabled={true}
                    />
                  </div>
                </div> : ""}
            </div>
          </div>
        </div>


        <div className="col-12 link-sending-site-screen-footer">
          <button
            disabled={!this.isProcessSelected()}
            title={this.isProcessSelected() ? "Next" : "Select a sending process to continue"}
            className="btn btn-lg btn-primary link-sending-site-footer-btn"
            id="nextButton"
            onClick={this.handleMoveToNextStep}
          >
            Next
          </button>
          {this.renderExitButton()}
        </div>
      </Fragment>
    );
  }
}