import pdf from "./pdf.png";
import pdfGrey from "./pdfGrey.png";
import pdfSigned from "./pdfSigned.png";
import qbdDoc from "./qbdDoc.png";

export {
  pdf,
  pdfGrey,
  pdfSigned,
  qbdDoc
};
