var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames } from '../classNames';
import { SIZE_CLASSES } from './constants';
/**
 * Represents the [KendoReact SvgIcon component]({% slug overview_svgicon %}).
 *
 * @example
 * ```jsx
 * import { accessibility } from '@progress/kendo-svg-icons';
 *
 * const App = () => {
 *   return (
 *       <SvgIcon icon={accessibility} />
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var SvgIcon = React.forwardRef(function (props, ref) {
    var children = props.children, className = props.className, svgClassName = props.svgClassName, icon = props.icon, flip = props.flip, id = props.id, tabIndex = props.tabIndex, size = props.size, style = props.style, svgStyle = props.svgStyle, themeColor = props.themeColor, viewBox = props.viewBox, others = __rest(props, ["children", "className", "svgClassName", "icon", "flip", "id", "tabIndex", "size", "style", "svgStyle", "themeColor", "viewBox"]);
    var elementRef = React.useRef(null);
    React.useImperativeHandle(ref, function () { return ({
        element: elementRef.current
    }); });
    var iconNameProp = React.useMemo(function () { return icon ? icon.name : defaultProps.icon; }, [icon]);
    var themeColorProp = React.useMemo(function () { return themeColor || defaultProps.themeColor; }, [themeColor]);
    var sizeProp = React.useMemo(function () { return size || defaultProps.size; }, [size]);
    var flipProp = React.useMemo(function () { return flip || defaultProps.flip; }, [flip]);
    var viewBoxProp = React.useMemo(function () { return viewBox || defaultProps.viewBox; }, [viewBox]);
    var elementClassNames = React.useMemo(function () { return classNames('k-svg-icon', 'k-color-' + themeColorProp, 'k-svg-i-' + iconNameProp, {
        'k-flip-h': flipProp === 'horizontal' || flipProp === 'both',
        'k-flip-v': flipProp === 'vertical' || flipProp === 'both'
    }, SIZE_CLASSES[sizeProp], className); }, [iconNameProp, themeColorProp, sizeProp, flipProp, className]);
    var elementStyle = React.useMemo(function () {
        if (props.width && props.height) {
            return __assign({ width: props.width, height: props.height }, style);
        }
        else if (props.width) {
            return __assign({ width: props.width, height: props.width }, style);
        }
        else if (props.height) {
            return __assign({ width: props.height, height: props.height }, style);
        }
        else {
            return __assign({}, style);
        }
    }, [props.width, props.height, style]);
    return (React.createElement("span", { className: elementClassNames, style: elementStyle, ref: elementRef },
        React.createElement("svg", __assign({ id: id, className: svgClassName, style: svgStyle, "aria-hidden": true, tabIndex: tabIndex, focusable: "false", xmlns: "http://www.w3.org/2000/svg", viewBox: icon ? icon.viewBox : viewBoxProp, dangerouslySetInnerHTML: icon ? { __html: icon.content } : undefined }, others), icon ? undefined : children)));
});
SvgIcon.propTypes = {
    style: PropTypes.object,
    classNames: PropTypes.string,
    children: PropTypes.any,
    icon: PropTypes.object,
    themeColor: PropTypes.oneOf([
        'inherit', 'primary', 'secondary', 'tertiary',
        'info', 'success', 'error', 'warning',
        'dark', 'light', 'inverse'
    ]),
    size: PropTypes.oneOf(['default', 'xsmall', 'small', 'medium', 'large', 'xlarge']),
    flip: PropTypes.oneOf(['default', 'horizontal', 'vertical', 'both'])
};
var defaultProps = {
    size: 'default',
    themeColor: 'inherit',
    flip: 'default',
    icon: '',
    viewBox: '0 0 24 24'
};
SvgIcon.displayName = 'KendoSvgIcon';
