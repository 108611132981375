"use strict";

import React from "react";
import * as I18NWrapper from "../../../../i18n/i18n_wrapper";
import * as UIUtils from "../../../../ui_utils";
import {ProjectMaterialWizardHelper} from "./project_material_wizard_helper";
import BasePage from "../../../../base_page";
import {ProjectMaterialWizardController} from "./project_material_wizard_container";
import {COMMON_MATERIAL_STEPS} from "../../../library_constants";
import {MATERIAL_SELECTION_STEP_CONFIG, MATERIAL_OPTIONS_STEP_CONFIG} from "../../steps/config";
import {MATERIAL_ACTIONS} from "../../../helpers/library_helper";

const initialState = () => {
  const start = UIUtils.getParameterByName("start");
  const isStartingFromProject = (start === "Project");
  return {
    currentFlowStep: COMMON_MATERIAL_STEPS.MaterialLibrarySelection,
    wizardStepsConfig: [MATERIAL_SELECTION_STEP_CONFIG, MATERIAL_OPTIONS_STEP_CONFIG],
    ProjectId: UIUtils.getParameterByName("projectId"),
    ProcessId: UIUtils.getParameterByName("processId"),
    UnitOperationId: UIUtils.parseInt(UIUtils.getParameterByName("unitOperationId")),
    StepId: UIUtils.parseInt(UIUtils.getParameterByName("stepId")),
    action: MATERIAL_ACTIONS.CREATE,
    isStartingFromProject,
    libraryMaterials: [],
    selectionState: {}
  };
};

class CreateProjectMaterialPage extends BasePage {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const {t} = this.props;
    document.title = t("Create Project Material Title");

    super.componentDidMount();
  }

  renderPage() {
    const {t} = this.props;

    const props = {
      t,
      WizardHelper: ProjectMaterialWizardHelper,
      initialState: initialState(),
    };

    return (
      <ProjectMaterialWizardController title="Add Material from Library" {...props} />
    );
  }
}

export default I18NWrapper.wrap(CreateProjectMaterialPage, "library");
