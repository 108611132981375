import { FIELD_PROPS } from "../fieldsConfig/constants/configurable_tables_field_props_config";

export function sortByParent(sortDirection) {
  const sortField = FIELD_PROPS.PARENT;

  const getParentName = (parent) => {
    if (!parent) {
      return "";
    }

    const index = parent.indexOf(" - ");
    return parent.substring(index + 3);
  };

  return (a, b) => {
    const aParent= getParentName(a[sortField]);
    const bParent = getParentName(b[sortField]);
    let asc = sortDirection == "asc" ? 1 : -1;
    if (aParent > bParent) {
      return 1 * asc;
    }

    if (aParent < bParent) {
      return -1 * asc;
    }

    return 0;
  };
}