/* stylelint-disable-next-line import-notation */
@import "../../../../../../../css/brand_colors";

.drop-down-label {
  color: $brand-dark-grey;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.container {
  padding: 5px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 310px;
  margin-left: 33px;
  position: absolute;
  top: 100px;
}

.actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-left: 33px;
  position: absolute;
  top: 177px;

  .action-item {
    display: inline-flex;
    align-items: flex-start;
    flex-direction: column;
    width: 327px;

    :global(label) {
      display: inline-flex;
      margin-bottom: unset;
      align-items: center;
    }
  }

  .action-label {
    color: $brand-medium-grey;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Open Sans", sans-serif;
    margin-left: 18px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.002px;
  }
}

.value {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 310px;
  margin-left: 33px;
  position: absolute;
  top: 310px;
}