"use strict";

import SubTopNavBar from "../../widgets/bars/sub_top_nav_bar";
import { PRODUCT_LIST_TABS } from "../product_constants";
import React, { memo, useEffect, useState } from "react";
import * as UIUtils from "../../ui_utils";
import * as styles from "./styles/list_view_title_area.module.scss";

const ListViewTitleArea = ({onProductTabChange}) => {

  const [storedProductListTab, setStoredProductListTab] = useState(null);

  useEffect(() => {
    const activeTab = UIUtils.getParameterByName("selectedProductListTab") === "TPP"
      ? PRODUCT_LIST_TABS.TPP
      : PRODUCT_LIST_TABS.QTPP;
    setStoredProductListTab(activeTab);
    onProductTabChange("selectedProductListTab", activeTab);
  }, []);

  const onTabChange = (tab) => {
    setStoredProductListTab(tab);
    onProductTabChange("selectedProductListTab", tab);
  };

  return (
    <SubTopNavBar selected={storedProductListTab}
                  subTopNavClassName={styles["container"]}
                  onTabChanged={onTabChange}
                  tabs={PRODUCT_LIST_TABS}
    />
  );
};

export default memo(ListViewTitleArea);