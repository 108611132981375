"use strict";

import React from "react";
import ImplementationNeededError from "../../utils/implementation_needed_error";
import SearchBox from "../../widgets/tables/search_box";
import BaseReactComponent from "../../base_react_component";
import { RouterContext } from "../../utils/router_context";

/**
 *  This creates a DataTable with checkboxes down the side for selecting rows.
 */
export default class TableWithCheckboxes extends BaseReactComponent {
  static contextType = RouterContext;

  constructor(props) {
    super(props);
    this.areCheckboxesSelectedByDefault = false; // Set this in child classes to have everything selected.
    this.isInitializingTable = true;
  }

  getColumns() {
    throw new ImplementationNeededError("You need to implement this in a derived class and also provide unique data for the column where the checkboxes are rendered.");
  }

  // noinspection JSMethodCanBeStatic
  getColumnsDefs() {
    let selectAllCheckboxId = this.props.id + "selectAllCheckbox";


    let checkboxesColumnDef = {
      orderable: false,
      targets: 0,
      createdCell: function(td, cellData, rowData) {
        if (td.className.includes("dt-checkboxes-cell")) { // Make sure jquery-datatables-checkboxes has been initialized.
          if (rowData.disabled) {
            this.api().cell(td).checkboxes.disable();
            $(this.api().row(td).node()).prop("class", "disabled-bulk-approval-row");
            if (rowData.disabledReason) {
              $(this.api().cell(td).node()).prop("title", rowData.disabledReason);
            }
          } else {
            this.api().cell(td).checkboxes.enable();
            $(this.api().row(td).node()).removeClass("disabled-bulk-approval-row");
          }
        }
      },
    };

    if (!this.props.hideCheckBoxes) {
      checkboxesColumnDef.checkboxes = {
        selectRow: true,
        selectAll: true,
        selectAllPages: true,
        selectAllRender: () => {
          return `<input id="${selectAllCheckboxId}" type="checkbox" class="datatables-select-all-checkbox">`;
        }
      };
    }

    return this.props.hideCheckBoxes ? [] : [checkboxesColumnDef];
  }

  componentDidMount() {
    super.componentDidMount();
    this.isInitializingTable = true;
    this.table = $(this.tableRef).DataTable({
      dom: this.props.datatablesDomOptions,
      iDisplayLength: this.props.pageLength ? this.props.pageLength : 10,
      buttons: [],
      data: this.props.data,
      deferRender: this.isDeferred(),
      columns: this.getColumns(),
      columnDefs: this.getColumnsDefs(),
      paging: Object.prototype.hasOwnProperty.call(this.props, "paging") ? this.props.paging : true,
      lengthMenu: [[10, 25, 50, 100, -1], [10, 25, 50, 100, "All"]],
      select: {
        style: "multi",
        items: "row",
        selector: "td:first-child input",
      },
      order: [[this.props.defaultSortColumnIndex ? this.props.defaultSortColumnIndex : 1, "asc"]],
      stateSave: false,
    });
    let table = this.table;

    let hasOnlyACoupleSelected = false;
    if (this.props.selectByDefault) {
      table.rows(this.getAllOptions()).every((rowIdx) => {
        const rowData = table.row(rowIdx).data();
        if (!rowData.disabled) {
          if (this.props.selectByDefault(rowData)) {
            table.row(rowIdx).select();
            hasOnlyACoupleSelected = true;
          }
        }
      });
    }

    if (this.areCheckboxesSelectedByDefault && !hasOnlyACoupleSelected) {
      table.rows(this.getAllOptions()).every((rowIdx) => {
        const rowData = table.row(rowIdx).data();
        if (!rowData.disabled) {
          table.row(rowIdx).select();
        }
      });
    }

    if (this.props.showSearchBar) {
      table.search("").draw();
    }
    table.on("select", this.handleSelectedRowsChanged);
    table.on("deselect", this.handleSelectedRowsChanged);
    table.on("draw", this.handleSelectedRowsChanged);

    /* We can't use an arrow function in the onClick event below, so we need to temporary store the table on a local
       variable to make it accessible. */
    let handleRowClick = this.handleRowClick;
    $(`#${this.props.id} tbody`).on("click", "td", function() {
      let rowData = table.row(this).data();
      handleRowClick(rowData, this.cellIndex);
    });

    this.isInitializingTable = false;
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    $(this.tableRef)
      .DataTable()
      .destroy(true);
  }

  componentDidUpdate() {
    this.reloadDataTable();
  }

  reloadDataTable() {
    const {selectedIds, rowIdProp} = this.props;
    if (this.tableRef) {
      this.isInitializingTable = true;
      const table = $(this.tableRef).DataTable();
      let selectAllCheckbox = $("#" + this.props.id + "selectAllCheckbox");

      // Store the previous row selection if the selected rows are not controlled through the props.
      let selectedRowIndexes = [];
      if (!Array.isArray(selectedIds) || !rowIdProp) {
        table.rows(this.getSelectedOptions()).every((rowIdx) => {
          const rowData = table.row(rowIdx).data();
          if (!rowData.disabled) {
            selectedRowIndexes.push(rowIdx);
          }
        });
      }

      table.clear();
      table.rows.add(this.props.data || []);
      table.draw();

      // Select the specified rows explicitly if the selectedIds and rowIdProp is set in the props.
      if (Array.isArray(selectedIds) && selectedIds.length > 0 && rowIdProp) {
        table.rows(this.getAllOptions()).every((rowIdx) => {
          const rowData = table.row(rowIdx).data();
          if (!rowData.disabled
            && selectedIds.includes(rowData[rowIdProp])
            && !selectedRowIndexes.includes(rowIdx)) {
            selectedRowIndexes.push(rowIdx);
          }
        });
      }

      if (!this.props.hideCheckBoxes) {
        // Remember the selection
        if (selectedRowIndexes.length === 0) {
          selectAllCheckbox.prop("indeterminate", false);
          selectAllCheckbox.prop("checked", false);
          table.rows(this.getAllOptions()).deselect();
        } else {
          table.rows(this.getAllOptions()).every((rowIdx) => {
            if (selectedRowIndexes.includes(rowIdx)) {
              table.row(rowIdx).select();
            } else {
              table.row(rowIdx).deselect();
            }
          });
        }
      }
      this.isInitializingTable = false;
    }
  }

  handleRowClick(row, cellIndex, callback) {
    if (this.props.onRowClick) {
      this.props.onRowClick(row, cellIndex, callback);
    }
  }

  /**
   * This updates the select all checkbox status on the header of the table based on the user selection.
   * Due to an incompatibility between the datatables and the checkboxes plugins, it also updates the select-info
   * span to show the correct information
   */
  handleSelectedRowsChanged() {
    let selectAllCheckboxId = this.props.id + "selectAllCheckbox";
    let selectedRows = this.getSelectedRows().length;
    let numOfSelectableRows = this.getAllRows().filter(row => !row.disabled).length;

    /* This is a hack since the rows info does not work along with the datatables checkboxes plugin and the datatables
       select plugging.*/
    let dtInfo = $(`#${this.props.id}_info .select-info`);
    if (dtInfo[0] && dtInfo[0].innerText) {
      if (this.props.showSelectedRowsInfo) {
        dtInfo[0].innerText = `Selected ${selectedRows} row${selectedRows === 1 ? "" : "s"}`;
      } else {
        dtInfo[0].innerText = "";
      }
    }

    $("#" + selectAllCheckboxId).prop("disabled", numOfSelectableRows === 0);

    if (this.props.onSelectedRowsChanged && !this.isInitializingTable) {
      this.props.onSelectedRowsChanged(this.getSelectedRows());
    }
  }

  isRowSelected(row) {
    let selectedRows = this.getSelectedRows();

    if (selectedRows.length > 0) {
      for (let i = 0; i < selectedRows.length; i++) {
        if (selectedRows[i].id === row.id && selectedRows[i].modelName === row.modelName) {
          return true;
        }
      }
    }

    return false;
  }

  getSelectedRows() {
    return this.table && this.table.rows(this.getSelectedOptions()).data().filter(row => !row.disabled);
  }

  getAllRows() {
    return this.table && this.table.rows(this.getAllOptions()).data();
  }

  updateRow(row, attribute, value) {
    if (row) {
      let rowData = row.data()[0];
      let isRowSelected = this.isRowSelected(rowData);

      rowData[attribute] = value;
      row.data(rowData);
      row.invalidate();

      if (isRowSelected) {
        row.select();
      }
    }
  }

  getAdditiveSkeletonClass() {
    return "skeleton-table";
  }

  render() {
    return (
      <div>
        <h2>{this.props.tableHeader}</h2>
        {this.props.showSearchBar ?
          <div className="table-with-checkboxes-search-box">
            <SearchBox
              id={this.props.id + "SearchTypeahead"}
              items={this.props.searchItems}
              onSuggestionSelected={this.onSuggestionSelect}
              onSearchTermChange={this.onSearchTermChange}
            />
          </div> : ""}
        <table ref={ref => this.tableRef = ref}
               className={"table table-bordered table-hover" + this.getClassForLoading()}
               id={this.props.id}
               style={{width: "100%"}}
        />
      </div>
    );
  }

  isDeferred() {
    return true;
  }

  getAllOptions() {
    return this.isDeferred() ? {page: "all"} : {};
  }

  getSelectedOptions() {
    return {...this.getAllOptions(), ...{selected: true}};
  }
}
