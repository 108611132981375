"use strict";

import { BaseDataTransform } from "../base_data_transform";
import { getEffectiveRMPForDate } from "../../../helpers/risk_helper";
import moment from "moment-timezone";
import { exportRiskTables } from "../../risk_tables/utils/risk_tables_export";
import { MAToXQARiskTableTransformStrategy } from "./strategies/riskTables/ma_to_xqa_risk_table_transform_strategy";
import { PPToXQARiskTableTransformStrategy } from "./strategies/riskTables/pp_to_xqa_risk_table_transform_strategy";
import { orderAndIndexUnits } from "../../../processExplorer/indexers/uo_indexer";

export class RiskTablesDataTransform extends BaseDataTransform {
  constructor(config, options) {
    super(config, options);
    this.strategies = new Map([
      ["ma_prc_to_xqa", new MAToXQARiskTableTransformStrategy()],
      ["pp_to_xqa", new PPToXQARiskTableTransformStrategy()],
    ]);
  }

  get type() {
    return "riskTables";
  }

  shouldRun(options) {
    return true;
  }

  getStrategy() {
    const strategyType = this.defaultStrategy;

    if (this.strategies.has(strategyType)) {
      return this.strategies.get(strategyType);
    }
    throw new Error(`There is no transform strategy specified for sub-report '${strategyType}'`);
  }

  transform(input, options) {
    const strategy = this.getStrategy();
    const {rmpVersions, projectWithAllVersions} = options;
    const {parentReportType, modelType} = strategy;
    const effectiveRMP = getEffectiveRMPForDate(projectWithAllVersions, rmpVersions, moment(), modelType);
    const objectToExport = {
      instances: {
        riskTablesData: input.instances[this.defaultStrategy]
      }
    };

    const orderedUOList = input.instances && input.instances.uos ?
      orderAndIndexUnits(input.instances.uos, "UnitOperationId") : [];

    return exportRiskTables(parentReportType, effectiveRMP, objectToExport, null,
      moment(), options.reportOptions || options, false, true, false, orderedUOList, null,"UnitOperationId");
  }

  getSupportedRecordTypes() {
    return "";
  }
}

