import { FOCUSABLE_ELEMENTS, KEYBOARD_NAV_DATA_BODY, KEYBOARD_NAV_DATA_HEADER, KEYBOARD_NAV_DATA_ID, KEYBOARD_NAV_DATA_LEVEL, KEYBOARD_NAV_DATA_SCOPE, KEYBOARD_NAV_FILTER_COL_SUFFIX, TABBABLE_ELEMENTS } from './constants';
/**
 * @hidden
 */
export var generateNavigatableId = function (navigationId, idPrefix, type) {
    if (type === void 0) { type = 'cell'; }
    return "".concat(idPrefix, "_").concat(navigationId, "_").concat(type);
};
/**
 * @hidden
 */
export var getNavigatableLevel = function (element) {
    if (!element) {
        return;
    }
    return parseInt(element.getAttribute(KEYBOARD_NAV_DATA_LEVEL) || '', 10);
};
/**
 * @hidden
 */
export var getNavigatableId = function (element) {
    if (!element) {
        return;
    }
    var dataId = element.getAttribute(KEYBOARD_NAV_DATA_ID);
    return dataId ? dataId : undefined;
};
/**
 * @hidden
 */
export var isNavigatable = function (element) {
    if (!element) {
        return false;
    }
    var dataId = element.getAttribute(KEYBOARD_NAV_DATA_ID);
    return Boolean(dataId);
};
/**
 * @hidden
 */
export var getNavigatableElement = function (scope, options) {
    if (options === void 0) { options = { level: 0 }; }
    return scope.querySelector("[".concat(KEYBOARD_NAV_DATA_LEVEL, "='").concat(options.level, "']"));
};
/**
 * @hidden
 */
export var getClosestNavigatableElement = function (target) {
    return (target.parentElement &&
        target.parentElement.closest("[".concat(KEYBOARD_NAV_DATA_LEVEL, "]")));
};
/**
 * @hidden
 */
export var getActiveElement = function (scope, activeId) {
    return scope.querySelector("[".concat(KEYBOARD_NAV_DATA_ID, "='").concat(activeId, "']"));
};
/**
 * @hidden
 */
export var getClosestScope = function (target) {
    return (target.parentElement &&
        target.parentElement.closest("[".concat(KEYBOARD_NAV_DATA_SCOPE, "]")));
};
/**
 * @hidden
 */
export var getHeaderElement = function (scope) {
    return scope.querySelector("[".concat(KEYBOARD_NAV_DATA_HEADER, "]"));
};
/**
 * @hidden
 */
export var getBodyElement = function (scope) {
    return scope.querySelector("[".concat(KEYBOARD_NAV_DATA_BODY, "]"));
};
/**
 * @hidden
 */
export var getFocusableElements = function (scope, options) {
    if (options === void 0) { options = { focusable: false }; }
    var selectors = options.focusable ? FOCUSABLE_ELEMENTS : TABBABLE_ELEMENTS;
    return Array.from(scope.querySelectorAll(selectors.join(',')));
};
/**
 * @hidden
 */
export var getNavigatableElements = function (scope, options) {
    if (options === void 0) { options = { level: 0 }; }
    if (!scope) {
        return [];
    }
    var selector = FOCUSABLE_ELEMENTS.map(function (el) { return el + "[".concat(KEYBOARD_NAV_DATA_LEVEL, "='").concat(options.level, "']"); }).join(',');
    return Array.from(scope.querySelectorAll(selector));
};
/**
 * @hidden
 */
export var filterNavigatableElements = function (options) {
    if (options === void 0) { options = { level: 0 }; }
    var selector = FOCUSABLE_ELEMENTS.map(function (sel) { return sel + "[".concat(KEYBOARD_NAV_DATA_LEVEL, "='").concat(options.level, "']"); }).join(',');
    return function (element) { return element.matches(selector); };
};
/**
 * @hidden
 */
export var focusElement = function (options) {
    var elementForFocus = options.elementForFocus, event = options.event, contextStateRef = options.contextStateRef, prevElement = options.prevElement;
    if (contextStateRef.current && elementForFocus && elementForFocus.focus) {
        event.preventDefault();
        elementForFocus.focus();
        if (isNavigatable(elementForFocus)) {
            elementForFocus.setAttribute('tabIndex', '0');
            contextStateRef.current.activeId = getNavigatableId(elementForFocus);
        }
        if (prevElement && isNavigatable(prevElement)) {
            prevElement.setAttribute('tabIndex', '-1');
        }
    }
};
/**
 * @hidden
 */
export var getIdPrefix = function (navigationStateRef) {
    return navigationStateRef.current ? navigationStateRef.current.idPrefix : '';
};
/**
 * @hidden
 */
export var findNextIdByRowIndex = function (initialRowIndex, cellIndex, elementId, matrix, isReverse) {
    if (!elementId) {
        return [];
    }
    var currentRowIndex = initialRowIndex + (isReverse ? -1 : 1);
    while (currentRowIndex >= 0 && currentRowIndex < matrix.length) {
        var currentId = matrix[currentRowIndex][cellIndex];
        if (currentId !== elementId) {
            return [currentId, [currentRowIndex, cellIndex]];
        }
        currentRowIndex = currentRowIndex + (isReverse ? -1 : 1);
    }
    return [];
};
/**
 * @hidden
 */
export var findNextIdByCellIndex = function (rowIndex, initialCellIndex, elementId, matrix, isReverse) {
    if (!elementId) {
        return [];
    }
    var currentCellIndex = initialCellIndex + (isReverse ? -1 : 1);
    while (currentCellIndex >= 0 && currentCellIndex < matrix[rowIndex].length) {
        var currentId = matrix[rowIndex][currentCellIndex];
        if (currentId !== elementId) {
            return [currentId, [rowIndex, currentCellIndex]];
        }
        currentCellIndex = currentCellIndex + (isReverse ? -1 : 1);
    }
    return [];
};
/**
 * @hidden
 */
export var findId = function (navigationMatrix, cellId) {
    if (!cellId) {
        return;
    }
    for (var rowIndex = 0; rowIndex < navigationMatrix.length; rowIndex++) {
        for (var cellIndex = 0; cellIndex < navigationMatrix[rowIndex].length; cellIndex++) {
            if (navigationMatrix[rowIndex][cellIndex] === cellId) {
                return [rowIndex, cellIndex];
            }
        }
    }
};
/**
 * @hidden
 */
export var getNextNavigationIndex = function (navigationStateRef) {
    return navigationStateRef.current ? navigationStateRef.current.navigationMatrix.length : 0;
};
/**
 * @hidden
 */
export var getFilterColumnId = function (columnId) {
    return columnId ? "".concat(columnId).concat(KEYBOARD_NAV_FILTER_COL_SUFFIX) : '';
};
/**
 * @hidden
 */
export var tableKeyboardNavigationTools = {
    generateNavigatableId: generateNavigatableId,
    getNavigatableId: getNavigatableId,
    getNavigatableLevel: getNavigatableLevel,
    getNavigatableElement: getNavigatableElement,
    getClosestNavigatableElement: getClosestNavigatableElement,
    getActiveElement: getActiveElement,
    getClosestScope: getClosestScope,
    getHeaderElement: getHeaderElement,
    getBodyElement: getBodyElement,
    getFocusableElements: getFocusableElements,
    getNavigatableElements: getNavigatableElements,
    filterNavigatableElements: filterNavigatableElements,
    focusElement: focusElement,
    getIdPrefix: getIdPrefix,
    isNavigatable: isNavigatable,
    findNextIdByRowIndex: findNextIdByRowIndex,
    findNextIdByCellIndex: findNextIdByCellIndex,
    findId: findId,
    getNextNavigationIndex: getNextNavigationIndex,
    getFilterColumnId: getFilterColumnId
};
