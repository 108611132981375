"use strict";

/*
Functions on this file is responsible for generating the full UI for
record's diff on the review split screen.
 */

import {TECH_TRANSFER_MODELS_CONFIG} from "../tech_transfer_config";
import {formatValue} from "../../configurableTables/fieldsConfig/fields_formatter";
import {
  COLUMN_SELECTION_ONLY_SECTIONS,
  SECTIONS
} from "../../configurableTables/fieldsConfig/constants/configurable_tables_sections_config";
import {SENDING_SITE_LABEL} from "../../configurableTables/fieldsConfig/constants/configurable_tables_columns_config";
import CommonUtils from "../../../server/common/generic/common_utils";

module.exports.generateRecordDiff = (selectedModelType, transferredFrom = {}, transferredTo = {}, changeCriteria) => {

  if (!transferredFrom.id || !transferredTo.id) {
    return {
      diffs: {},
      totalChangesCount: 0,
      totalDiffsCount: 0,
    };
  }

  const sections = SECTIONS[selectedModelType].filter(section => !COLUMN_SELECTION_ONLY_SECTIONS.includes(section.name));
  const fieldsConfig = TECH_TRANSFER_MODELS_CONFIG[selectedModelType].fieldsConfig;

  let fields = fieldsConfig.fields.filter(field =>
    field.originatedFromSite === SENDING_SITE_LABEL &&
    !COLUMN_SELECTION_ONLY_SECTIONS.includes(field.section)
  );

  const diffs = [];
  let {config} = changeCriteria || {};
  if (!config) {
    config = fields.filter(field => field.defaultChangeCriteriaValue).map(field => field.prop);
  }

  let totalChangesCount = 0;
  let totalDiffsCount = 0;

  for (const section of sections) {
    let sectionChangesCount = 0;

    for (const field of fields.filter(field => field.section === section.name)) {
      const {prop} = field;

      const propertyInObject = getPropertyWithFormattedValue(transferredFrom, prop);
      let transferredFromValue = formatValue(transferredFrom[propertyInObject]);
      let transferredToValue = formatValue(transferredTo[propertyInObject]);

      const isString = (typeof transferredFromValue === "string") || (typeof transferredToValue === "string");
      if (isString) {
        transferredFromValue = transferredFromValue ?? "";
        transferredToValue = transferredToValue ?? "";
        transferredFromValue = CommonUtils.stripAllWhitespaces(transferredFromValue);
        transferredToValue = CommonUtils.stripAllWhitespaces(transferredToValue);
      }

      if (transferredFromValue !== transferredToValue) {
        if (config && config.includes(prop)) {
          sectionChangesCount++;
        } else if (!config) {
          sectionChangesCount++;
        }

        totalDiffsCount++;
      }
    }

    totalChangesCount += sectionChangesCount;

    diffs.push({
      title: section.title,
      sectionChangesCount
    });
  }

  return {
    diffs,
    totalChangesCount,
    totalDiffsCount
  };
};

export function getPropertyWithFormattedValue(transferredFrom, prop) {
  let propertyInObject = prop;
  const propertyMap = new Map([["unitOperations", "unitOperationNameSimple"],
    ["unitOperation", "unitOperationNameSimple"],
    ["steps", "stepNameSimple"],
    ["step", "stepNameSimple"]]);

  if (propertyMap.has(prop) && transferredFrom.hasOwnProperty(propertyMap.get(prop))) {
    propertyInObject = propertyMap.get(prop);
  }

  return propertyInObject;
};