"use strict";

import React from "react";
import * as UIUtils from "../ui_utils";
import { reconstructSmartContent } from "./utils";
import * as HeaderAndFooterSetter from "./utils/setter/header_footer_setter";
import * as DirectScopeWidgetSetter from "./utils/setter/direct_scope_widget_setter";
import { EditorState, GENERIC_ERROR_MESSAGE } from "./common/constants";
import { EditablesService } from "../services/editables/editables_service";
import { loadRMP } from "../helpers/risk_helper";
import BaseReactComponent from "../base_react_component";
import CommonEditablesPageTitleBar from "../widgets/pageTitleBar/common_editables_page_title_bar";
import ErrorBar from "../widgets/bars/error_bar";
import EditorParser from "./utils/parser/parser";
import Editor from "./qbd_editor";
import FullObjectCache from "../utils/cache/full_object_cache";
import EditorPreview from "./qbd_editor_preview";
import DocumentEditorToolbar from "./document_editor_toolbar";
import SideMenu from "./components/sideMenu/side_menu";
import { getCurrentPageOrientation, PAGE_ORIENTATION } from "./tools/page_orientation";
import * as styles from "./document_editor.module.scss";
import { RouterContext } from "../utils/router_context";

/**
 * The main component of DocumentEditor. It will take responsibility of
 * rendering and loading necessary information
 */
export default class DocumentEditor extends BaseReactComponent {
  static contextType = RouterContext;

  constructor(props) {
    super(props);

    this.editableService = new EditablesService();

    this.editorPreview = React.createRef();
    this.editor = React.createRef();

    this.windowOnUnloadHandlerSet = false;
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      editorState: EditorState.VIEW,
      editorContent: "",
      smartContent: {},
      documentRecord: {},
      numberOfLoadedImages: 0,
      areAllImagesLoaded: false,
      hasError: false,
      showSideMenu: false,
      editorView: null,
    };
  }

  async componentDidMount() {
    super.componentDidMount();

    document.title = "Document Editor";
    this.setStateSafely(this.getInitialState());
    await this.initialize(this.props.documentRecord);
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  async componentDidUpdate(prevProps, prevState) {
    const {documentContent: prevDocumentContent} = prevState;
    const {documentContent} = this.state;
    if (
      prevDocumentContent &&
      documentContent &&
      prevDocumentContent.content !== documentContent.content
    ) {
      this.loadSmartContent();
    }
  }

  shouldComponentUpdate(nextProps, nextStateIgnored, nextContextIgnored) {
    // deferRender is used for not rendering conditionally the contents of the document
    // previewer, when the client wants to load other UI components first. In big documents,
    // the UI can take a couple of seconds to render all the HTML in the document preview,
    // which could be noticeable from a user. defer render will allow the UI to load quickly
    // and then only load the html in the preview when needed, in which case the client will
    // set the deferRender to true. We want to re-render the document editor whenever deferRender
    // changes or when it is not set at all
    if (this.props.deferRender !== nextProps.deferRender) {
      return true;
    }
    return !nextProps.deferRender;
  }

  async initialize(result) {
    UIUtils.setHideLoadingOnAjaxStop(false);
    UIUtils.showLoadingImage();

    if (result?.ProjectId) {
      loadRMP(async(rmp) => {
        const cachedProject = await (new FullObjectCache("Project", result.ProjectId, {invalidate: true}).loadOptions()).promise();
        if (result?.ProjectId && !cachedProject) {
          throw new Error(`Cannot identify project with id ${result?.ProjectId}`);
        }

        let cachedProcess = null;
        if (result.ProcessId) {
          cachedProcess = await (new FullObjectCache("Process", result.ProcessId, {invalidate: true}).loadOptions()).promise();

          if (result.ProcessId && !cachedProcess) {
            throw new Error(`Cannot identity process with id ${result.ProcessId}`);
          }
        }
        await this.loadDocumentContent(result, cachedProject, cachedProcess, rmp);
      }, result.ProjectId);
    } else {
      await this.loadDocumentContent(result);
    }
  }

  async loadDocumentContent(result, cachedProject, cachedProcess, rmp) {
    this.setStateSafely({
      rmp,
      cachedProject,
      cachedProcess,
      documentRecord: result,
      ProjectId: result.ProjectId,
      ProcessId: result.ProcessId,
      DocumentId: result.id,
    });

    if (!result.documentContent) {
      this.editableService
        .get({
          urlPrefix: "editables/DocumentContent",
          action: result.DocumentContentId,
        })
        .then(this.handleReceiveDocumentContent);
    } else {
      await this.handleReceiveDocumentContent(result.documentContent);
    }
  }

  async handleReceiveDocumentContent(result) {
    const {onFinishedDownloadingImages} = this.props;
    const uploadImageLinks = JSON.parse(result.uploadImageLinks || "[]");
    if (!uploadImageLinks.length) {
      if (onFinishedDownloadingImages) {
        onFinishedDownloadingImages();
      }
      this.setStateSafely({
        areAllImagesLoaded: true,
        orientation: result.orientation
      });
    }

    EditorParser.downloadImagesAsync(
      uploadImageLinks,
      (uploadImageLink, imageData) => {
        // It is possible that two or more images can finish downloading at the
        // same time, and it can cause a race condition. We pass a function to the
        // setStateSafely to ensure the atomicity's operation. You can read more here
        // https://legacy.reactjs.org/docs/react-component.html#setstate
        this.setStateSafely((prevState) => {
          const newEditorContent = EditorParser.parseImagesAndUpdateSrc(
            prevState.editorContent,
            [uploadImageLink],
            imageData.url,
          );
          const {numberOfLoadedImages} = prevState;
          if (numberOfLoadedImages + 1 === uploadImageLinks.length) {
            if (onFinishedDownloadingImages) {
              onFinishedDownloadingImages();
            }
            // We cannot update the image src one by one in the Telerik Editor like the
            // preview mode. It will mess up the state of the editor. We can only do it
            // when all images finish downloading
            this.editor?.current?.updateEditorState(newEditorContent);
          }

          return {
            editorContent: newEditorContent,
            numberOfLoadedImages: (numberOfLoadedImages || 0) + 1,
            areAllImagesLoaded:
              numberOfLoadedImages + 1 === uploadImageLinks.length,
          };
        });
      },
    );
    const editorContent = await EditorParser.parseImagesAndUpdateSrc(
      result.content,
      uploadImageLinks,
    );
    this.setStateSafely(
      {
        documentContent: result,
        editorContent: HeaderAndFooterSetter.setHeaderAndFooter(editorContent),
      },
      () => {
        this.loadSmartContent();
      },
    );
  }

  handleChangeEditorState() {
    const {editorState, documentContent, editorView} = this.state;
    if (editorState === EditorState.EDIT) {
      this.editor.current.resetSelectedListNodeItem(editorView);
      const editorContent = this.editor.current.getEditorContent();
      const {uploadImageLinks, content} =
        EditorParser.cleanImageElementsBeforeSave(editorContent);

      this.editableService
        .save(
          {
            ...documentContent,
            uploadImageLinks: JSON.stringify(uploadImageLinks),
            content,
            orientation: getCurrentPageOrientation(),
          },
          {urlPrefix: "editables/DocumentContent", model: "DocumentContent"},
        )
        .then((result) => {
          this.setStateSafely({
            editorState: EditorState.VIEW,
            editorContent,
            documentContent: result,
          });
          this.context.clearPreventNavigation();
          this.windowOnUnloadHandlerSet = false;
        })
        .catch(this.failCallback);
    } else if (editorState === EditorState.VIEW) {
      this.setStateSafely({
        editorState: EditorState.EDIT,
      });
    }
  }

  async exportToFile(fileType) {
    await this.editorPreview.current.exportToFile(fileType);
  }

  uploadFile(documentRecord, callback) {
    this.editorPreview.current.uploadFile(documentRecord, (fileData) => {
      this.setStateSafely({fileData});
      if (callback) {
        callback(fileData);
      }
    });
  }

  loadSmartContent() {
    const {onLoadSmartContent, onLoadSmartContentFail} = this.props;
    const {documentRecord, smartContent, editorContent, rmp} = this.state;
    let fields;
    try {
      fields = JSON.stringify(
        EditorParser.getFields(editorContent, this.state)
      );
    } catch (err) {
      this.setStateSafely({hasError: true});
    }

    this.editableService
      .buildQuery(
        {
          fields,
          projectId: documentRecord.ProjectId,
          processId: documentRecord.ProcessId,
        },
        {
          urlPrefix: "editables",
        },
      )
      .then((result) => {
        result.qbdFields.Document = documentRecord;
        if (result?.widgets?.treeData && result?.widgets?.treeData) {
          result.widgets = reconstructSmartContent(
            result.widgets.treeData,
            result.widgets.listDataMap,
          );
        }

        if (result.directScopeWidgets) {
          this.setStateSafely(prevState => {
            return {
              editorContent: DirectScopeWidgetSetter.setLatestDataForAllElements(
                prevState.editorContent,
                result.directScopeWidgets,
                rmp,
              )
            };
          });
        }

        this.setStateSafely(
          {
            smartContent: {
              ...smartContent,
              ...result,
            },
          },
          () => {
            if (onLoadSmartContent) {
              onLoadSmartContent(this.state.smartContent);
            }
          },
        );
        UIUtils.hideLoadingImage();
        UIUtils.setHideLoadingOnAjaxStop(true);
      })
      .catch((err) => {
        if (onLoadSmartContentFail) {
          onLoadSmartContentFail(err);
        }
        UIUtils.hideLoadingImage();
        UIUtils.setHideLoadingOnAjaxStop(true);
      });
  }

  handleCancelButton(event) {
    if (
      this.windowOnUnloadHandlerSet &&
      !confirm(UIUtils.onWindowBeforeUnload())
    ) {
      event.preventDefault();
    } else {
      this.setStateSafely({editorState: EditorState.VIEW});
      UIUtils.clearError();
      this.context.clearPreventNavigation();
      this.windowOnUnloadHandlerSet = false;
    }
  }

  failCallback(results) {
    UIUtils.defaultFailFunction(results);
  }

  handleEditorChange() {
    if (!this.windowOnUnloadHandlerSet) {
      this.context.preventNavigation();
      this.windowOnUnloadHandlerSet = true;
    }
  }

  renderEditorMain() {
    const {
      editorState,
      editorContent,
      documentRecord,
      smartContent,
      rmp,
      cachedProject,
      cachedProcess,
      hasError,
      showSideMenu,
      editorView,
    } = this.state;
    const {deferRender, previewOnly, isVisible} = this.props;

    const previewComponent = (
      <EditorPreview
        ref={this.editorPreview}
        className={styles["document-editor-pdf-viewer"]}
        editorContent={hasError ? GENERIC_ERROR_MESSAGE : editorContent}
        documentRecord={documentRecord}
        smartContent={smartContent}
        rmp={rmp}
        project={cachedProject}
        process={cachedProcess}
        deferRender={deferRender}
      />
    );

    const editorComponent = (
      <Editor
        ref={this.editor}
        editorContent={editorContent}
        onChange={this.handleEditorChange}
        onToggleMenu={() => {
          this.setStateSafely({showSideMenu: !showSideMenu});
          editorView?.focus();
        }}
        onMount={(view) => this.setStateSafely({editorView: view})}
        onExecute={(view) => this.setStateSafely({editorView: view})}
      />
    );

    if (previewOnly) {
      return (
        <div
          className={isVisible ? "on-screen" : styles["document-preview-hidden"]}
        >
          <div
            className={`document-editor-main ${styles["document-editor-main"]}`}
          >
            <div
              className={`container ${styles["container"]} ${styles["container-preview-only"]}`}
            >
              <ErrorBar />
              <div className="row-white row shadow">
                <div
                  id="document-editor-viewer"
                  className={`${this.state.orientation === PAGE_ORIENTATION.LANDSCAPE ? "col-12-landscape" : "col-12"} ${styles["document-editor-viewer"]}`}
                >
                  {previewComponent}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (editorState === EditorState.VIEW) {
      return (
        <div
          className={`document-editor-main ${styles["document-editor-main"]}`}
        >
          <div className={`container ${styles["container"]}`}>
            <ErrorBar />
            <div className="row-white row shadow">
              <div
                id="document-editor-viewer"
                className={`${this.state.orientation === PAGE_ORIENTATION.LANDSCAPE ? "col-12-landscape" : "col-12"} ${styles["document-editor-viewer"]}`}
              >
                {previewComponent}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={`row m-0 document-editor-main ${
          styles["document-editor-main"]
        } ${showSideMenu && styles["document-editor-main-with-side-menu"]}`}
      >
        <div
          className={`${showSideMenu && "col-9 m-0 p-0"} container ${
            styles["container"]
          }`}
        >
          <ErrorBar />
          <div
            className={`${
              showSideMenu
                ? styles["document-editor-viewer-container-with-side-menu"]
                : "row-white row shadow col-12 mx-auto"
            }`}
          >
            <div
              id="document-editor-viewer"
              className={`${showSideMenu ? "col-8 offset-2" : this.state.orientation === PAGE_ORIENTATION.LANDSCAPE ? "col-12-landscape" : "col-12"} ${
                styles["document-editor-viewer"]
              }`}
            >
              <div
                className={`${
                  showSideMenu && "row row-white shadow pl-4 pr-4"
                }`}
              >
                {editorComponent}
              </div>
            </div>
          </div>
        </div>
        {showSideMenu && (
          <div className={`col-3 p-0 pb-5 ${styles["document-editor-side-menu"]}`}>
            <SideMenu
              project={cachedProject}
              editorView={editorView}
              documentRecord={documentRecord}
              rmp={rmp}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      editorState,
      editorContent,
      documentRecord,
      smartContent,
      areAllImagesLoaded,
    } = this.state;
    const {offScreen, previewOnly} = this.props;

    if (previewOnly) {
      return this.renderEditorMain();
    } else {
      return (
        <div className={offScreen ? styles["off-screen"] : "on-screen"}>
          <CommonEditablesPageTitleBar
            name={`${documentRecord.name || ""}`}
            recordName={documentRecord.name || ""}
            backLink={`/documents/viewEdit.html?operation=View&id=${documentRecord.id}`}
          />
          <div className={`company-header ${styles["document-editor-toolbar"]}`}>
            <DocumentEditorToolbar
              editorState={editorState}
              editorContent={editorContent}
              smartContent={smartContent}
              documentRecord={documentRecord}
              areAllImagesLoaded={areAllImagesLoaded}
              onExportToFile={this.exportToFile}
              onCancelButtonClick={this.handleCancelButton}
              onChangeEditorState={this.handleChangeEditorState}
            />
          </div>
          {this.renderEditorMain()}
        </div>
      );
    }
  }
}