/* stylelint-disable-next-line import-notation */
@import "../../../../../css/brand_colors";

.container {
  &:global(.quick-panel-title-state) {
    padding-right: unset;
  }
}


