"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import BaseJsonAttribute from "./base_json_attribute";
import LinkAttachment from "../widgets/link_attachment";
import * as DocumentTransferHelper from "../../helpers/document_transfer_helper";
import { FILE_STATUS } from "../../helpers/document_transfer_helper";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import { FIELD_REQUIRED_FOR } from "../widgets/widget_field";

const Logger = Log.group(LOG_GROUP.JSONAttribute, "BaseLinksAttachmentAttribute");

/**
 * This is a child class of the BaseJsonAttribute. It provides the same functionality as the parent class and
 * saves all provided fields in the widgetFields in a json structure and eventually as text in a database table field.
 * Additionally, it supports for a new input type, the link, which allows the user to specify a link or upload a document
 * as part of the row being edited. All row fields including the link attributes are stringified and persisted to the DB as text.
 */
export default class BaseLinksAttachmentsAttribute extends BaseJsonAttribute {
  constructor(props, widgetFields) {
    super(props, props.widgetFields ? props.widgetFields : widgetFields);

    this.errorMessages.editedRowPending = "At least one link is in edit state. Please save or cancel all links in edit state.";
  }

  /**
   * Returns an array of field types supported by this base class in the WIDGET_FIELDS JSON object
   */
  static getSupportedFieldTypes() {
    return ["link"];
  }

  /**
   * Overwrite this in child classes to customize the rendering of a particular field in a table column
   * This method returns the DOM object used to populate the data property of the datatables columns element.
   * @param field The field to render a column for
   * @param result The datatables row data
   * @param type The datatables display mode flag
   * @returns {*}
   */
  getColumn(field, result, type) {
    let column = super.getColumn(field, result, type);
    if (!column && field.inputType === "link") {
      let fieldValue = field.getValue(result);

      column = this.isDiffingVersions() && result.linkDiffHTML ? (
        result.linkDiffHTML
      ) : ReactDOMServer.renderToStaticMarkup(
        <a href={UIUtils.cleanUpURL(fieldValue)}
           rel="noopener noreferrer"
           target="_blank"
           className="links-table-link"
        >
          {fieldValue}
        </a>
      );
    }

    return column;
  }

  /**
   * Overwrite this in child classes to customize the rendering of a particular cell.
   * This method will cover the field types returned by BaseJsonAttribute.getSupportedFieldTypes()
   * Overwrite getFieldInput to further customize the input controls used in table cell inline editors
   * @param field The field to customize the column cell for
   * @param rowData The row data object
   */
  getColumnDef(field, rowData) {
    Logger.verbose("Entering BaseLinksAttachmentAttribute.getColumnDef", Log.object(field), Log.object(rowData));
    let columnDef = super.getColumnDef(field, rowData);
    if (!columnDef && field.inputType === "link") {
      const {index, uuid} = rowData;
      let linksRowData = this.isRowInEditMode(uuid) ? this.getEditedFileDataById(uuid) : rowData;
      columnDef = (
        <LinkAttachment name={this.props.name}
                        index={index}
                        data={linksRowData}
                        showCaption={false}
                        required={field.requiredFor === FIELD_REQUIRED_FOR.save}
                        disabled={this.isFieldDisabled(field)}
                        rowIsInEditMode={this.isRowInEditMode(uuid)}
                        isDiffingVersions={this.isDiffingVersions()}
                        onFileDownload={this.handleFileDownload}
                        onFileUpload={this.handleFileUpload}
                        onCancelUpload={this.handleCancelUpload}
                        onDeleteAttachment={this.handleDeleteAttachment}
        />
      );
    }

    return columnDef;
  }

  /**
   * Overwrite this in child classes to customize the cell input control properties used for rendering a table cell
   * @param field The field to customize the input control for
   * @param rowData The row data use for assigning a value to the input control from session
   */
  getFieldInput(field, rowData) {
    let fieldInput = super.getFieldInput(field, rowData);
    if (!fieldInput && field.inputType === "link") {
      let linksRowData = this.getLinkData(rowData);
      let editedRow = this.getEditedRowByUUID(rowData.uuid);
      const {index, uuid} = rowData;
      console.warn("Rendering field", field);
      fieldInput = (
        <LinkAttachment name={this.props.name}
                        index={index}
                        data={linksRowData}
                        caption={field.displayName + field.belongsToMasterRow ? "" : ":"}
                        showCaption={!field.belongsToMasterRow}
                        rowIsInEditMode={this.isRowInEditMode(uuid)}
                        isDiffingVersions={this.isDiffingVersions()}
                        disabled={this.isFieldDisabled(field)}
                        onFileDownload={this.handleFileDownload}
                        onFileUpload={this.handleFileUpload}
                        onCancelUpload={this.handleCancelUpload}
                        onDeleteAttachment={this.handleDeleteAttachment}
                        {...field.getAdditionalProps(this, editedRow)}
        />
      );
    }

    return fieldInput;
  }

  /**
   * Returns the child row input field used DOM element. Overwrite this in the child classes to
   * customize the child row input and view controls. Overwrite getLinkData to customize the object used to
   * initialize the LinkAttachment control
   * @param field The field for which the input control will be returned
   * @param rowData The rowData for which the input control will be used for
   */
  getChildRowFieldInput(field, rowData) {
    let fieldInput = super.getChildRowFieldInput(field, rowData);
    if (!fieldInput && field.inputType === "link") {
      fieldInput = this.getFieldInput(field, rowData);
    }
    return fieldInput;
  }

  /**
   * Returns the link or Attachment object used for initializing the LinkAttachment control in the getChildRowFieldInput
   * method. This can be overwritten in child classes to customize the way this link/attachment object is returned
   * @param rowData
   */
  getLinkData(rowData) {
    let uuid = rowData.uuid;
    return this.isRowInEditMode(uuid) ? this.getEditedFileDataById(uuid) : rowData;
  }

  /**
   * Returns file attachment data information provided the id of the editing row
   * @param id The unique id of the editing row
   */
  getEditedFileDataById(id) {
    return this.getEditedRowByUUID(id);
  }

  /**
   * Returns file attachment data information for the row in the attribute's state, given the row id of the datatables row
   * @param id The unique id of the row in the datatables
   */
  getNonEditedFileDataById(id) {
    let rows = this.getValueAsObject();
    return rows.find(row => row.uuid === id);
  }

  /**
   * Overwrite in child classes to customize the way attachment file attachment data is extracted from an underlying datatables row data
   * @param rowData The row data to extract file attachment information from
   * @returns {*}
   */
  getFileData(rowData) {
    return rowData;
  }

  /**
   * Adds a new row to the rows collection managed by this attribute and assigns the default value for each field
   * as specified in the WIDGET_FIELDS.
   * @param editedRow Optional row provided by a child class, where it is already initialized..
   */
  handleAddToList(editedRow) {
    editedRow = editedRow ? editedRow : {};
    editedRow.uuid = this.generateUUID(editedRow);
    this.initializeLink(editedRow);
    super.handleAddToList(editedRow);
  }

  /**
   * Turns on the inline editor for the provided row.
   * @param rowData The row data to populate the inline editor controls with
   * @param editedRow Optional the edited row, if this method is called from a child class where the edited row is already initialized.
   * @param forceUpdate Set to true to clear any control errors and force a react update
   */
  handleEdit(rowData, editedRow, forceUpdate = true) {
    editedRow = editedRow ? editedRow : {};
    super.handleEdit(rowData, editedRow, false);
    let row = this.getValueAsObject().find(row => row.uuid === rowData.uuid);
    this.handleEditLink(editedRow, row);
    this.clearErrorText(forceUpdate);
  }

  /**
   * Updates the rows collection with the updated edited row data
   * @param rowData The datatables row to update with the edited data
   * @param rows The rows object passed in from the child object updated with the child object edited row fields and values
   * @param setValueCallback Callback to execute to set the value of edited row to the parent state
   * @param errorReport {Map<string, string>} A map containing the errors in this operation
   * @returns {boolean} A boolean indicating whether the validation inside this method was successful or not.
   */
  handleSave(rowData, rows, setValueCallback, errorReport = new Map()) {
    $("[data-toggle='validator']").validator("update");

    const editedRow = this.getEditedRowByUUID(rowData.uuid);
    if (this.isEditingRecordValid(editedRow, rows, errorReport)) {
      rows = rows ? rows : this.getValueAsObject();
      // Since each inheritance level can have its own validation logic, we return a boolean indicating whether the
      // validation succeeded. If it failed, we don't proceed.
      if (super.handleSave(rowData, rows, null, errorReport)) {
        let row = rows.find(row => row.uuid === editedRow.uuid);
        this.setFileData(row, editedRow);

        if (setValueCallback) {
          setValueCallback(rows);
        }
        return true;
      }
    }

    this.showRecordValidationErrors(editedRow, errorReport);
    return false;
  }

  /**
   * Verifies if the given field is missing while it is being required for proposal. This is used in validate method to
   * show an error when at least one or more fields are required before being able to propose an object for approval.
   * @param row The data row which is being validated for proposal
   * @param field The field which is being validated for proposal
   */
  isFieldMissingForProposal(row, field) {
    if (field.inputType === "link") {
      let fieldValue = field.getValue(row);

      return (!fieldValue || fieldValue === "") && ((row.linkType === "Link") || (row.linkType === ""));
    } else {
      return super.isFieldMissingForProposal(row, field);
    }
  }

  /**
   * Validates the input field before saving for the row being edited and return true if it is valid, false otherwise.
   * Overwrite this method in child classes to customize the default validation behavior of any input field.
   * @param editedRow {*} The rows data of the row being edited
   * @param field {WidgetField} The field to validate
   * @param rows {[]} The array with all the rows in the control
   * @param errorReport {Map<string, *>} An object containing information about the errors that occurred
   */
  isEditingRowFieldValid(editedRow, field, rows, errorReport) {
    let result = true;
    if (field.inputType === "link") {
      let isValid;
      //Check first if the provided URL is a valid URL
      let fileData = this.getFileData(editedRow, field.fieldName);
      let value = fileData.link;

      let linkInput = $("#" + this.props.name + "_InputLink_" + editedRow.index);
      if (!value && fileData.linkType !== "Attachment" && field.requiredFor === FIELD_REQUIRED_FOR.save) {
        linkInput.trigger("input");
        isValid = false;
        Logger.verbose(field.getFieldPath(), "IS VALID (link):", isValid);
      } else if (value && !UIUtils.isValidURL(value) && linkInput[0]) {
        linkInput[0].setCustomValidity("Not a valid URL.");
        linkInput.trigger("input");
        isValid = false;
        Logger.verbose(field.getFieldPath(), "IS VALID (url):", isValid);
      } else {
        if (linkInput && linkInput.trigger("input")[0]) {
          isValid = linkInput.trigger("input")[0].validity.valid;
          Logger.verbose(field.getFieldPath(), "IS VALID (input):", isValid);
        } else {
          isValid = true; //If the input cannot be found, then a file has been uploaded.
        }
      }

      result = this.isEntryUnique(isValid, editedRow, field, rows, errorReport);
    } else {
      result = super.isEditingRowFieldValid(editedRow, field, rows, errorReport);
    }

    Logger.verbose(field.getFieldPath(), "IS VALID (final):", result);
    return result;
  }

  /**
   * Initializes the links/attachment object properties when adding a new row
   * @param editedRow The new row to initialize the link information for
   */
  initializeLink(editedRow) {
    editedRow.linkType = "";
    editedRow.fileName = "";
    editedRow.S3TmpKey = "";
    editedRow.S3TmpVersion = "";
    editedRow.link = "";
    editedRow.linkVersion = "";
  }

  /**
   * Handles the edit even of a datatables row. This method will populate the edited row properties from the raw data row and will
   * display the inline editor
   * @param editedRow The edited row to populate with the row fields and values
   * @param row The raw data row for which the inline editor will be displayed
   */
  handleEditLink(editedRow, row) {
    editedRow.uuid = row.uuid;
    editedRow.linkType = row.linkType ? row.linkType : "Link";
    editedRow.fileName = row.fileName;
    editedRow.S3TmpKey = row.S3TmpKey;
    editedRow.S3TmpVersion = row.S3TmpVersion;
    editedRow.link = row.link;
    editedRow.linkVersion = row.linkVersion;
  }

  /**
   * Updates the raw data row with file and attachment object information from the inline editor and the edited row attached to it
   * @param row The raw data to assign file & attachment information to
   * @param editedRow The EditedRow object being edited by the user
   */
  setFileData(row, editedRow) {
    row.linkType = editedRow.linkType;
    row.fileName = editedRow.fileName;
    row.S3TmpKey = editedRow.S3TmpKey;
    row.S3TmpVersion = editedRow.S3TmpVersion;
    row.link = editedRow.link;
    row.linkVersion = editedRow.linkVersion;
  }

  /**
   * Handles downloading an attachment when the corresponding link is clicked
   * @param rowData The datatables roeData on which the attachment download is triggered
   * @param e
   */
  handleFileDownload(rowData, e) {
    e.preventDefault();
    let fileData;
    if (this.isView()) {
      fileData = this.getFileData(rowData);
    } else {
      const {uuid} = rowData;
      fileData = this.isRowInEditMode(uuid)
        ? this.getEditedFileDataById(uuid)
        : this.getNonEditedFileDataById(uuid);
    }

    // noinspection JSIgnoredPromiseFromCall
    DocumentTransferHelper.handleFileDownload(fileData);

    return false;
  }

  /**
   * Handles deleting an attachment from a row being edited through the inline editor
   * @param rowData
   */
  handleDeleteAttachment(rowData) {
    let editedRowData = this.getEditedFileDataById(rowData.uuid);
    editedRowData.linkType = "";
    editedRowData.fileName = "";
    editedRowData.S3TmpKey = "";
    editedRowData.S3TmpVersion = "";
    editedRowData.link = "";
    editedRowData.linkVersion = "";

    this.clearErrorText(true);
  }

  /**
   * Handles uploading an attachment through the row inline editor
   * @param rowData The rowData on which the attachment will be uploaded to
   * @param e
   */
  handleFileUpload(rowData, e) {
    let editedRow = this.getEditedFileDataById(rowData.uuid);

    DocumentTransferHelper.handleUpload(e, editedRow,
      "#" + this.props.name + "_UploadProgressBar_" + editedRow.index,
      this.clearErrorText,
      this.setError,
      this.forceUpdate.bind(this));
  }

  /**
   * Handles canceling an in progress attachment upload
   * @param rowData
   */
  handleCancelUpload(rowData) {
    let editedRow = this.getEditedFileDataById(rowData.uuid);

    editedRow.linkType = "";
    editedRow.fileName = "";
    editedRow.S3TmpKey = "";
    editedRow.S3TmpVersion = "";
    editedRow.fileStatus = FILE_STATUS.NOT_SPECIFIED;

    editedRow.xhr.abort();
  }
}
