import { BaseParser } from "../base_parser";
import * as RiskHelper from "../../../../helpers/risk_helper";
import { RISK_TYPE_ENUM } from "../../../../helpers/constants/constants";
import * as CommonRiskUtils from "../../../../../server/common/misc/common_risk_utils";
import { getRiskLabel, getRiskScaleColor, getRiskScaleLabel } from "../../../../reports/canned_reports/canned_report_helper";

export class CriticalityParser extends BaseParser {
  parse() {
    const {modelName, columnName} = this.getNodeInfo();
    this.addModelAndColumnToField(this.fields, modelName, columnName);
    const newElement = document.createElement(this.node.tagName);

    // If the record doesn't support multiple risk link (PHA risk assessment type), we don't
    // allow it to continue to parse the criticality
    if (this.data && !CommonRiskUtils.recordSupportsMultipleLinks(this.data)) {
      return [newElement];
    }

    const content = this.parseCriticality();
    if (content?.element) {
      return [content.element];
    }

    if (content) {
      newElement.innerText = content.textContent;
    }

    return [newElement];
  }

  parseCriticality() {
    let textContent = "";
    if (!this.data) {
      return {textContent};
    }

    const {columnName} = this.getNodeInfo();
    const content = getCriticalityContent(this.data, columnName, this.rmp);
    if (["riskLabelColor", "scoreLabelColor"].includes(columnName)) {
      return {element: this.createColorElement(content)};
    }
    return {textContent: content};
  }
}

export function getCriticalityContent(record, columnName, rmp) {
  let content = "";
  if (!record) {
    return content;
  }

  const obj = getDataAndModel(record);
  if (
    ["riskLabelColor", "riskLabel", "scoreLabelColor", "scoreLabel"].includes(
      columnName
    )
  ) {
    const scaleForRiskLabel =
      record.riskInfo?.Criticality?.scaleForRiskLabel;
    const scale = record.riskInfo?.Criticality?.scale;
    switch (columnName) {
      case "riskLabelColor":
        return getRiskScaleColor(scaleForRiskLabel);
      case "scoreLabelColor":
        return getRiskScaleColor(scale);
      case "scoreLabel":
        content = getRiskScaleLabel(scale);
        break;
      default:
        content = getRiskLabel(scaleForRiskLabel);
    }
  } else if (columnName === "criticality") {
    content = record.riskInfo?.Criticality?.value;
  } else if (columnName === "impact") {
    content = RiskHelper.getRiskLabel(
      RISK_TYPE_ENUM.IMPACT,
      rmp,
      record.impact,
      record,
      false,
      false
    );
  } else if (columnName === "uncertainty") {
    content = RiskHelper.getRiskLabel(
      RISK_TYPE_ENUM.UNCERTAINTY,
      rmp,
      record.uncertainty,
      record,
      false,
      false
    );
  } else if (
    columnName === "intermediateOrFinalAttributes" ||
    columnName === "generalAttributeName"
  ) {
    content = obj?.data?.name;
  } else {
    content = record[columnName];
  }

  return content;
}

function getDataAndModel(record) {
  if (!record) {
    return null;
  }

  if (record.GeneralAttributeId || record.targetRecord?.typeCode === "GA") {
    return {data: record.GeneralAttribute || record.targetRecord, model: "GeneralAttribute"};
  }

  if (record.IQA || record.targetRecord?.typeCode === "IQA") {
    return {data: record.IQA || record.targetRecord, model: "IQA"};
  }

  if (record.IPA || record.targetRecord?.typeCode === "IPA") {
    return {data: record.IPA || record.targetRecord, model: "IPA"};
  }

  if (record.FQA || record.targetRecord?.typeCode === "FQA") {
    return {data: record.FQA || record.targetRecord, model: "FQA"};
  }

  if (record.FPA || record.targetRecord?.typeCode === "FPA") {
    return {data: record.FPA || record.targetRecord, model: "FPA"};
  }

  return null;
}
