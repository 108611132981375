"use strict";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import BaseReactComponent from "../../base_react_component";

/**
 * This class is responsible of showing a collapsible panel
 */
export default class CollapsePanel extends BaseReactComponent {

  constructor(props) {
    super(props);

    this.setStateSafely({
      isCollapsed: false,
    });
  }

  handleCollapse(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setStateSafely({isCollapsed: !this.state.isCollapsed});
  }

  getCollapsePanelIconSpanId() {
    let {isError, isWarning,} = this.props;
    return `collapsePanelIconSpan_${isError ? "error" : isWarning ? "warning" : "text"}`;
  }

  componentDidMount() {
    super.componentDidMount();
    this.setStateSafely({isCollapsed: this.props.collapsedByDefault});
  }

  render() {
    let {
      isError, isWarning, numOfErrors, className,
      numOfWarnings, body, text, warningBodyClass,
    } = this.props;

    let {isCollapsed} = this.state;
    let title = isError ? `Errors (${numOfErrors})` :
      isWarning ? `Warnings (${numOfWarnings})` : text;

    let input = (
      <span>
        <span id={this.getCollapsePanelIconSpanId()}>
          <FontAwesomeIcon className="collapse-card-icon"
                           id="collapseChevron"
                           icon={isCollapsed ? faChevronRight : faChevronDown}
                           size="sm"
          />
        </span>
        <span>{title}</span>
      </span>
    );

    return (
      <div className={"card-group " + className || ""}>
        <div className="card card-default">
          <div className={isError ? "card-error" : isWarning ? "card-warning" : "card-header"}>
            <h4 className="card-title">
              <a data-toggle="collapse"
                 onClick={this.handleCollapse}
                 href={"#collapse" + (isError ? "Error" : isWarning ? "Warning" : "")}
              >
                {input}
              </a>
            </h4>
          </div>
          <div id={"collapse" + (isError ? "Error" : isWarning ? "Warning" : "")}
               className={"card-collapse collapse" + (isCollapsed ? "" : " show")}
          >
            <div className={"card-body" +
              (isError ? " card-error-body" : isWarning ? " card-error-warning" : "") +
              (` ${warningBodyClass}` || "")}
            >
              {body}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

