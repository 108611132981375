var cache = {};
/**
 * @hidden
 */
export function cacheHsva(componentGuid, value, hsva) {
    cache[componentGuid] = { value: value, hsva: hsva };
}
/**
 * @hidden
 */
export function cacheRgba(componentGuid, value, rgba) {
    cache[componentGuid] = { value: value, rgba: rgba };
}
/**
 * @hidden
 */
export function cacheHex(componentGuid, value, hex) {
    cache[componentGuid] = { value: value, hex: hex };
}
/**
 * @hidden
 */
export function removeCachedColor(componentGuid) {
    delete cache[componentGuid];
}
/**
 * @hidden
 */
export function getCachedHex(componentGuid, value) {
    if (componentGuid) {
        var cachedColor = cache[componentGuid];
        return cachedColor && cachedColor.value === value ? cachedColor.hex : undefined;
    }
    else {
        return undefined;
    }
}
/**
 * @hidden
 */
export function getCachedHsva(componentGuid, value) {
    if (componentGuid) {
        var cachedColor = cache[componentGuid];
        return cachedColor && cachedColor.value === value ? cachedColor.hsva : undefined;
    }
    else {
        return undefined;
    }
}
/**
 * @hidden
 */
export function getCachedRgba(componentGuid, value) {
    if (componentGuid) {
        var cachedColor = cache[componentGuid];
        return cachedColor && cachedColor.value === value ? cachedColor.rgba : undefined;
    }
    else {
        return undefined;
    }
}
