"use strict";

import * as UIUtils from "../../ui_utils";
import CannotAdaptError from "../errors/cannot_adapt_error";
import ImplementationNeededError from "../../utils/implementation_needed_error";
import { TYPE_CODE, VERSION_STATES_THAT_DO_NOT_CASCADE } from "../process_explorer_constants";
/**
 * The functions in this file are responsible for handling changes to records and merging them into the process explorer results.
 */

/**
 * Add or update an entry in the process explorer results.
 *
 * @param processExplorerResults the array of results to mutate
 * @param newInstance The requirement to add / update
 */
module.exports.addOrUpdateInResults = function(processExplorerResults, newInstance) {
  const typeCodeToUpdate = UIUtils.getTypeCodeForModelName(newInstance.modelName);
  let mapToUpdate;
  switch (typeCodeToUpdate) {
    case TYPE_CODE.PROCESS: {
      const oldInstance = processExplorerResults.processes[0];

      // If the state was changed, we need to reload because the state of child objects could be
      // changed too (ex. Propose for Archive (Cascaded))
      if (oldInstance.currentState !== newInstance.currentState && !VERSION_STATES_THAT_DO_NOT_CASCADE.includes(newInstance.currentState)) {
        throw new CannotAdaptError();
      }

      oldInstance.name = newInstance.name;
    }
      break;

    case TYPE_CODE.UNIT_OPERATION:
      mapToUpdate = processExplorerResults.uoMap;
      if (mapToUpdate[newInstance.id]) {
        const oldInstance = mapToUpdate[newInstance.id];

        // If the previous UO changed, we need to reload
        if (oldInstance.PreviousUnitId !== newInstance.PreviousUnitId) {
          throw new CannotAdaptError();
        }

        // If the state was changed, we need to reload because the state of child objects could be
        // changed too (ex. Propose for Archive (Cascaded))
        if (oldInstance.currentState !== newInstance.currentState && !VERSION_STATES_THAT_DO_NOT_CASCADE.includes(newInstance.currentState)) {
          throw new CannotAdaptError();
        }

        mapToUpdate[newInstance.id] = newInstance;
      } else {
        // We don't know where the UO was added. Trigger a reload.
        throw new CannotAdaptError();
      }
      break;

    case TYPE_CODE.MATERIAL:
      mapToUpdate = processExplorerResults.mtMap;
      if (mapToUpdate[newInstance.id]) {
        const oldInstance = mapToUpdate[newInstance.id];

        // If the state was changed, we need to reload because the state of child objects could be
        // changed too (ex. Propose for Archive (Cascaded))
        if (oldInstance.currentState !== newInstance.currentState && !VERSION_STATES_THAT_DO_NOT_CASCADE.includes(newInstance.currentState)) {
          throw new CannotAdaptError();
        }

        if (newInstance.UnitOperations.length === 1 && oldInstance.UnitOperations.length === 0) {
          // The server modified the MAs too then
          throw new CannotAdaptError();
        }
      }
      if (checkIfNewInstanceCanBeAddedToProcessResult(newInstance, processExplorerResults)) {
        mapToUpdate[newInstance.id] = newInstance;
      }
      break;

    case TYPE_CODE.PROCESS_COMPONENT:
      mapToUpdate = processExplorerResults.prcMap;
      if (mapToUpdate[newInstance.id]) {
        const oldInstance = mapToUpdate[newInstance.id];

        // If the state was changed, we need to reload because the state of child objects could be
        // changed too (ex. Propose for Archive (Cascaded))
        if (oldInstance.currentState !== newInstance.currentState && !VERSION_STATES_THAT_DO_NOT_CASCADE.includes(newInstance.currentState)) {
          throw new CannotAdaptError();
        }

        if (newInstance.UnitOperations.length === 1 && oldInstance.UnitOperations.length === 0) {
          // The server modified the MAs & PPs too then
          throw new CannotAdaptError();
        }
      }
      if (checkIfNewInstanceCanBeAddedToProcessResult(newInstance, processExplorerResults)) {
        mapToUpdate[newInstance.id] = newInstance;
      }
      break;

    case TYPE_CODE.IQA:
      updateUOAndStep(newInstance);
      processExplorerResults.iqaMap[newInstance.id] = newInstance;
      break;

    case TYPE_CODE.IPA:
      updateUOAndStep(newInstance);
      processExplorerResults.ipaMap[newInstance.id] = newInstance;
      break;

    case TYPE_CODE.MATERIAL_ATTRIBUTE:
      updateUOAndStep(newInstance);
      processExplorerResults.maMap[newInstance.id] = newInstance;
      break;

    case TYPE_CODE.PROCESS_PARAMETER:
      updateUOAndStep(newInstance);
      processExplorerResults.ppMap[newInstance.id] = newInstance;
      break;

    case TYPE_CODE.STEP:
      mapToUpdate = processExplorerResults.stpMap;
      if (mapToUpdate[newInstance.id]) {
        const oldInstance = mapToUpdate[newInstance.id];

        // If the previous Step changed or the UnitOperation changed, we need to reload
        if (oldInstance.UnitOperationId !== newInstance.UnitOperationId
          || oldInstance.PreviousStepId !== newInstance.PreviousStepId) {
          throw new CannotAdaptError();
        }

        // If the state was changed, we need to reload because the state of child objects could be
        // changed too (ex. Propose for Archive (Cascaded))
        if (oldInstance.currentState !== newInstance.currentState && !VERSION_STATES_THAT_DO_NOT_CASCADE.includes(newInstance.currentState)) {
          throw new CannotAdaptError();
        }
        updateUOAndStep(newInstance);
        mapToUpdate[newInstance.id] = newInstance;
      } else {
        // We don't know where the Step was added. Trigger a reload.
        throw new CannotAdaptError();
      }
      break;

    default:
      throw new ImplementationNeededError();
  }

  return processExplorerResults;
};

/**
 * Check if the newInstance has the same ProcessId of the current process, in that case we will update Process Diagram to rerender the nodes.
 * If it belong to a different process that has been changed from Process type ahead while creating the MT/PRC then do not update the current process diagram.
 * @param newInstance
 * @param processExplorerResults
 * @returns {boolean}
 */
function checkIfNewInstanceCanBeAddedToProcessResult(newInstance, processExplorerResults) {
  return UIUtils.parseInt(newInstance.ProcessId) === UIUtils.parseInt(processExplorerResults.processes[0].id);
}

/**
 * Update an instance that points to a single Unit Operation (UO).
 *
 * @param newInstance The instance to update.
 */
function updateUOAndStep(newInstance) {
  if (newInstance.UnitOperationId) {
    newInstance.UnitOperation = {id: newInstance.UnitOperationId};
  } else {
    newInstance.UnitOperation = null;
  }

  if (newInstance.StepId) {
    newInstance.Step = {id: newInstance.StepId};
  } else {
    newInstance.Step = null;
  }
}

