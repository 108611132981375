"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import ReactDOMServer from "react-dom/server";
import ImportBaseStep from "./import_base_step";
import {
  IMPORT_STEPS, IMPORT_TYPE_KEY, MAX_IMAGE_FILE_SIZE,
  MAX_PDF_PAGES,
  SELECTED_CONTENT,
  SMART_IMPORT_ALLOWED_FILE_TYPES,
} from "../constants/import_constants";
import Split from "react-split";
import { generateTooltip } from "../../utils/ui_permissions";
import SmartUploadFileDropPanel from "../widgets/smartUpload/smartUploadStep/smart_upload_file_drop_panel";
import SmartUploadInputsPanel from "../widgets/smartUpload/smartUploadStep/smart_upload_inputs_panel";
import OCRWidget, { WIDGET_VIEW } from "../widgets/smartUpload/ocrWidget/ocr_widget";
import * as DocumentTransferHelper from "../../helpers/document_transfer_helper";
import { FILE_STATUS } from "../../helpers/document_transfer_helper";
import { ImportService } from "../../services/import/import_service";
import { BatchesService } from "../../services/import/batches_service";
import { StatusCallbackService } from "../../services/status_callback_service";
import { ImportMessageHandler } from "../../services/import/import_message_handler";
import ETLData from "../widgets/smartUpload/extractAndEditWizardStep/etl_data";
import OCRResultsColumn from "../widgets/smartUpload/ocrWidget/ocr_results_column";
import { StatusDisplayService } from "../../services/status_display_service";
import { MODEL_DECLARATIONS } from "../../../server/common/generic/common_models";

export const SMART_IMPORT_UPLOAD_STATUS = {
  IDLE: "Idle",
  NOT_STARTED: "Not started",
  UPLOADING_FILE: "Uploading File",
  PROCESSING_FILE: "Processing File",
  EXTRACTING_TEXT: "Extracting Text",
  CANCELLED: "Cancelled",
  COMPLETED: "Completed",
};

/**
 * This implements the Import wizard upload and select step
 */
export default class ImportSmartUploadStep extends ImportBaseStep {
  constructor(props) {
    super(props);

    this.setStateSafely({
      panelSizes: [50, 50],
      textractResults: null,
      showAdvancedOptions: false,
    });

    this.statusService = new StatusCallbackService(this.handlePartialTextExtractionResultsReceived);
    this.messageService = new ImportMessageHandler(this.statusService);
    this.importService = new ImportService(this.messageService, this.statusService);

    this.statusDisplayService = new StatusDisplayService();
    this.detectColumnsMessageService = new ImportMessageHandler(this.statusDisplayService);
    this.detectColumnsService = new ImportService(this.detectColumnsMessageService, this.statusDisplayService);

    this.batchesDisplayService = new StatusDisplayService();
    this.batchDataMessageService = new ImportMessageHandler(this.batchesDisplayService);
    this.batchesDataService = new BatchesService(this.batchDataMessageService, this.batchesDisplayService);


  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {totalPages, smartImportUploadProgressStatus, fileData, isPDF} = this.props;
    super.componentDidUpdate(prevProps, prevState, snapshot);

    if (totalPages) {
      if (isPDF && totalPages > MAX_PDF_PAGES) {
        UIUtils.showError(`PDF files more than ${MAX_PDF_PAGES} pages are not supported.`);
      } else if (!isPDF && fileData && fileData.file && this.props.fileData.file.size > MAX_IMAGE_FILE_SIZE) {
        UIUtils.showError(`Image files larger than ${MAX_IMAGE_FILE_SIZE / 1024 / 1024}MB are not supported.`);
      } else if (smartImportUploadProgressStatus === SMART_IMPORT_UPLOAD_STATUS.NOT_STARTED) {
        UIUtils.clearError();
        this.uploadFile();
      }
    }

    if (smartImportUploadProgressStatus === SMART_IMPORT_UPLOAD_STATUS.CANCELLED) {
      this.importService.disconnect();
      this.props.onWizardStepDataUpdated({
        textractResults: null,
        smartImportUploadProgressStatus: SMART_IMPORT_UPLOAD_STATUS.IDLE,
      });
    }
  }

  handleSplitPanelDragEnd(panelSizes) {
    this.setStateSafely({
      panelSizes,
    });
  }

  handleMaterialSelected(material) {
    const {textractResults, projectId, dependencyRecords} = this.props;
    let {etlData} = this.props;
    etlData = !material ? new ETLData() : etlData;

    if (material) {
      material.getInternalId = () => UIUtils.parseInt(material.id.replace(material.typeCode + "-", ""));
    }

    this.props.onWizardStepDataUpdated({
      selectedDependencyRecord: material,
      stepsDisabledStatus: {
        [IMPORT_STEPS.DataExtract]: !this.props.textractResults || !material,
        [IMPORT_STEPS.DataImport]: true,
      },
      dataMappingRequired: true,
      etlData,
    }, () => {
      const selectedMaterial = this.props.selectedDependencyRecord;

      let attributeKey = "materialAttributes";
      let modelName = "MaterialAttribute";
      if (selectedMaterial?.typeCode === MODEL_DECLARATIONS.LIBRARY_MATERIAL.typeCode) {
        attributeKey = "specifications";
        modelName = "Specification";
      }

      UIUtils.setHideLoadingOnAjaxStop(false);

      if (selectedMaterial && !selectedMaterial.batchMeasurementsLoaded
        && selectedMaterial[attributeKey]
        && selectedMaterial[attributeKey].length > 0) {
        const materialAttributesIds = selectedMaterial[attributeKey].map(ma => ma.id);
        this.batchesDataService.loadMeasurements(modelName, {
          attributeIds: materialAttributesIds,
        })
          .then(this.handleBatchMeasurementsForMaterialLoaded)
          .catch(this.failCallback);
      }

      if (textractResults) {
        let selectedOption = material && dependencyRecords.find(option => option.label === material.label);
        let materialId = selectedOption && UIUtils.parseKey(selectedOption.id).id;

        if (materialId) {
          let payload = {
            jobId: textractResults.jobId,
            projectId,
            materialId,
          };

          this.detectColumnsService.extractColumns(payload)
            .then(this.handleColumnsExtractionCompleted)
            .catch(this.failCallback);
        } else {
          UIUtils.setHideLoadingOnAjaxStop(true);
          UIUtils.hideLoadingImage();
        }
      } else {
        UIUtils.setHideLoadingOnAjaxStop(true);
        UIUtils.hideLoadingImage();
      }
    });
  }

  handleBatchMeasurementsForMaterialLoaded(results) {
    const materialAttributesWithMeasurements = results;
    const selectedMaterial = this.props.selectedDependencyRecord;
    let dataAttributes = selectedMaterial.materialAttributes;
    if (selectedMaterial.typeCode === MODEL_DECLARATIONS.LIBRARY_MATERIAL.typeCode) {
      dataAttributes = selectedMaterial.specifications;
    }

    selectedMaterial.batchMeasurementsLoaded = true;

    for (let materialAttribute of dataAttributes) {
      const materialAttributeWithMeasurements = materialAttributesWithMeasurements.find(maWithMeasurements => maWithMeasurements.id === materialAttribute.id);
      materialAttribute.batches = materialAttributeWithMeasurements && materialAttributeWithMeasurements.batches;
    }
    const {batchData, userUpdates} = this.props.etlData;
    let newETLData = new ETLData({batchData, dataAttributes, userUpdates});

    this.props.onWizardStepDataUpdated({
      selectedDependencyRecord: selectedMaterial,
      etlData: newETLData,
    });
  }

  handleFileSelected(file) {
    let fileData = {fileName: ""};
    fileData.uuid = UIUtils.generateUUID();
    fileData.file = file;

    this.smartUploadInputsPanel.handleFileSelected();

    UIUtils.clearError();

    let {batchData} = this.props;
    batchData = {
      ...(batchData || {}),
      userTaggedColumns: [],
    };

    this.props.onWizardStepDataUpdated({
      selectedFile: file,
      fileData,
      textractResults: null,
      smartImportUploadProgressStatus: SMART_IMPORT_UPLOAD_STATUS.NOT_STARTED,
      totalPages: null,
      zoomLevel: 100,
      selectedContent: SELECTED_CONTENT.ALL_DATA,
      batchData,
      stepsDisabledStatus: {
        [IMPORT_STEPS.DataExtract]: true,
        [IMPORT_STEPS.DataImport]: true,
      },
      dataMappingRequired: true,
    }, () => {
      this.setStateSafely({
        textractResults: null,
      });
    });
  }

  uploadFile() {
    let fileData = UIUtils.deepClone(this.props.fileData);
    /* The file object for some reason cannot be cloned or stringified
       https://stackoverflow.com/questions/24139216/js-input-file-to-json-with-for-example-json-stringify
     */
    fileData.file = this.props.fileData.file;

    if (DocumentTransferHelper.handleUpload(fileData.file, fileData,
      null,
      UIUtils.clearError,
      UIUtils.showError,
      this.forceUpdate.bind(this),
      this.handleUpdateFileData,
      this.validateFileData)) {
      this.props.onWizardStepDataUpdated({
        fileData,
        showUploadAndTextExtractPopup: true,
        smartImportUploadProgressStatus: SMART_IMPORT_UPLOAD_STATUS.UPLOADING_FILE,
      });
    }
  }

  validateFileData(fileData) {
    const fileExtension = UIUtils.getFileExtension(fileData.fileName);
    if (!SMART_IMPORT_ALLOWED_FILE_TYPES.toLowerCase().includes(fileExtension)) {
      UIUtils.showError(`Only ${SMART_IMPORT_ALLOWED_FILE_TYPES} files may be uploaded.`);
      return false;
    } else {
      return true;
    }
  }

  handleUpdateFileData(fileData) {
    const {smartImportUploadProgressStatus} = this.props;

    if (smartImportUploadProgressStatus && smartImportUploadProgressStatus !== SMART_IMPORT_UPLOAD_STATUS.CANCELLED) {
      if (fileData.fileStatus === FILE_STATUS.UPLOADED) {
        this.extractTextFromFile();
      }

      this.props.onWizardStepDataUpdated({
        fileData,
      });
    }
  }

  extractTextFromFile() {
    const {fileData, projectId, dependencyRecords, selectedDependencyRecord} = this.props;
    let selectedOption = selectedDependencyRecord
      ? dependencyRecords.find(option => option.label === selectedDependencyRecord.label)
      : null;
    let materialId = selectedOption && UIUtils.parseKey(selectedOption.id).id;
    let payload = {
      fileData,
      projectId,
      materialId,
    };

    this.props.onWizardStepDataUpdated({
      smartImportUploadProgressStatus: SMART_IMPORT_UPLOAD_STATUS.PROCESSING_FILE,
    });

    this.importService.extractText(payload)
      .then(this.handleTextExtractionCompleted)
      .catch(this.failCallback);
  }

  handlePartialTextExtractionResultsReceived(message) {
    const {smartImportUploadProgressStatus} = this.props;

    if (smartImportUploadProgressStatus && smartImportUploadProgressStatus !== SMART_IMPORT_UPLOAD_STATUS.CANCELLED) {
      if (message && message.containsPartialResults) {
        let receivedBlocks = this.state.textractResults
          ? (this.state.textractResults.blocks || []).concat(message.blocks)
          : message.blocks;

        this.setStateSafely({
          textractResults: {blocks: receivedBlocks},
        }, () => {
          this.props.onWizardStepDataUpdated({
            smartImportUploadProgressStatus: SMART_IMPORT_UPLOAD_STATUS.EXTRACTING_TEXT,
          });
        });
      } else if (typeof message === "string") {
        this.props.onWizardStepDataUpdated({
          textractMessage: message,
        });
      }
    }
  }

  handleColumnsExtractionCompleted(results) {
    let {textractResults} = this.props;
    let newTextractResults = {
      ...textractResults,
      columns: results.columns,
    };

    let selectedContent = results.columns.length > 0
      ? SELECTED_CONTENT.RESULTS_COLUMN
      : SELECTED_CONTENT.ALL_DATA;

    this.props.onWizardStepDataUpdated({
      textractResults: newTextractResults,
      selectedContent,
    }, () => {
      this.redrawColumns();
      this.handleAdvancedColumnsToggleChange(false);
      UIUtils.setHideLoadingOnAjaxStop(true);
      UIUtils.hideLoadingImage();
    });
  }

  handleTextExtractionCompleted(results) {
    if (results) {
      const {smartImportUploadProgressStatus, selectedDependencyRecord} = this.props;

      if (smartImportUploadProgressStatus !== SMART_IMPORT_UPLOAD_STATUS.CANCELLED) {
        let textractResults = {
          ...results,
          ...this.state.textractResults,
        };

        let selectedContent = textractResults && textractResults.columns && textractResults.columns.length > 0
          ? SELECTED_CONTENT.RESULTS_COLUMN
          : SELECTED_CONTENT.ALL_DATA;

        this.props.onWizardStepDataUpdated({
          smartImportUploadProgressStatus: SMART_IMPORT_UPLOAD_STATUS.COMPLETED,
          textractResults,
          selectedContent,
          stepsDisabledStatus: {
            [IMPORT_STEPS.DataExtract]: !selectedDependencyRecord,
            [IMPORT_STEPS.DataImport]: true,
          },
        }, () => {
          this.redrawColumns();
          this.handleAdvancedColumnsToggleChange(false);
        });
      }
    }
  }

  redrawColumns() {
    const {
      zoomLevel, originalDocumentWidth, originalDocumentHeight, paperWidth, paperHeight, pdfPageHeight,
      selectedView, isPDF, selectedContent, textractResults,
    } = this.props;
    const {showAdvancedOptions} = this.state;

    if (isPDF && selectedView === WIDGET_VIEW.PDF) {
      /* This is a hack. Since at this point we are not re-rendering the pdf to avoid the extra flickering on the
         screen, we need to somehow draw the results columns rectangles on the screen. We first remove the columns
         from the current view and then redraw them again.
     */
      const columns = textractResults && textractResults.columns ? textractResults.columns : [];
      for (let column of columns) {
        const {tableId, columnIndex} = column;
        const columnId = OCRResultsColumn.getOCRColumnId(selectedView.title, tableId, columnIndex);
        const columnElement = $(`#${columnId}`);
        if (columnElement.length > 0) {
          columnElement.remove();
        }
      }

      $("#importOCRPDFViewerDiv").append(ReactDOMServer.renderToStaticMarkup(
        <OCRResultsColumn zoomLevel={zoomLevel ? zoomLevel : 100}
                          selectedView={selectedView}
                          isLibraryMaterial={this.props.isLibraryMaterial}
                          pageHeight={pdfPageHeight}
                          selectedContent={selectedContent}
                          textractResults={textractResults}
                          hideNonTaggedColumns={false}
                          originalDocumentWidth={originalDocumentWidth}
                          originalDocumentHeight={originalDocumentHeight}
                          showAdvancedOptions={showAdvancedOptions}
                          paperWidth={paperWidth}
                          paperHeight={paperHeight}
        />,
      ));
    }
  }

  handleAdvancedColumnsToggleChange(showAdvancedOptions) {
    this.setStateSafely({
      showAdvancedOptions,
    }, () => {
      this.props.onWizardStepDataUpdated({
        dataMappingRequired: true,
      }, () => {
        let columnAdvancedButtons = $(".import-result-column button");
        if (this.state.showAdvancedOptions) {
          columnAdvancedButtons.removeClass("hidden");
        } else {
          columnAdvancedButtons.addClass("hidden");
        }
      });
    });
  }

  failCallback(results) {
    $("#smartUploadProgressPopup").modal("hide");
    this.props.onWizardStepDataUpdated({
      showUploadAndTextExtractPopup: false,
    });
    UIUtils.defaultFailFunction(results);
  }

  handleETLDataUpdated(etlData) {
    this.props.onWizardStepDataUpdated({
      etlData,
    });
  }

  activate(changeInfo) {
    const {onWizardStepDataUpdated, selectedDependencyRecord, textractResults} = this.props;

    onWizardStepDataUpdated({
      currentImportStep: IMPORT_STEPS.SmartUpload,
      dataMappingRequired: false,
      stepsDisabledStatus: {
        [IMPORT_STEPS.DataExtract]: !selectedDependencyRecord || !textractResults,
        [IMPORT_STEPS.DataImport]: true,
      },
    }, () => {
      super.activate(changeInfo);
    });
  }

  deactivate(stepIndexToMoveTo) {
    if (this.props.currentStep < stepIndexToMoveTo) {
      let {textractResults, dataMappingRequired} = this.props;
      const columns = textractResults && textractResults.columns ? textractResults.columns : [];
      let uniqueTableIds = new Set(columns.map(column => column.tableId));
      let columnsTagged = true;
      for (let tableId of uniqueTableIds) {
        let tableColumns = columns.filter(column => column.tableId === tableId);
        let resultColumns = tableColumns.filter(column => column.isResultsColumn);
        let attributeColumns = tableColumns.filter(column => column.isAttributesColumn);
        columnsTagged = resultColumns.length > 0 && attributeColumns.length > 0;
        if (!columnsTagged) {
          break;
        }
      }

      if (dataMappingRequired && !columnsTagged) {
        this.props.onWizardStepDataUpdated({
          showConfirmationPopup: true,
          stepToMoveOnConfirmation: IMPORT_STEPS.DataExtract,
          confirmationMessage: `Material Attributes and Results columns are not specified for all tables. Continue?`,
        });
      } else {
        super.deactivate(stepIndexToMoveTo);
      }
    } else {
      super.deactivate(stepIndexToMoveTo);
    }

  }

  renderStep() {
    const {
      modelNameForSecurity, smartImportUploadProgressStatus, fileData, textractResults, onWizardStepDataUpdated,
      selectedDependencyRecord, dataUrl, dependencyRecords, selectedContent, zoomLevel, selectedFile, projectId,
      process, allowProcessSelection, onSelectedProcessChanged, securityAction,
    } = this.props;
    let {panelSizes, showAdvancedOptions} = this.state;

    const importConfig = this.props?.importConfig;
    if (importConfig && importConfig.materialId && !this.state.materialFromParamsLoaded) {
      const selectedMaterial = this.props.dependencyRecords.find(record =>
        record.id === ((importConfig.key === IMPORT_TYPE_KEY.LIBRARY_COA ? MODEL_DECLARATIONS.LIBRARY_MATERIAL.typeCode : MODEL_DECLARATIONS.MA.typeCode) + "-" + importConfig.materialId));
      if (selectedMaterial) {
        this.setStateSafely({
          materialFromParamsLoaded: true,
        });

        this.handleMaterialSelected(selectedMaterial);
      }
    }

    const hideDependencyTypeahead = this.props.importConfig?.key === IMPORT_TYPE_KEY.LIBRARY_COA &&
      this.props.selectedDependencyRecord &&
      this.props.selectedDependencyRecord.getInternalId() === UIUtils.getParameterByName("selectedDependencyRecordId");

    let nextStepDisabled = this.isNextStepDisabled();

    return (
      <Fragment>
        <div className="import-smart-upload-step">
          <div className="import-paper-div">
            <Split sizes={[50, 50]}
                   minSize={440}
                   gutterSize={2}
                   gutterAlign="center"
                   snapOffset={0}
                   dragInterval={1}
                   direction="horizontal"
                   cursor="col-resize"
                   onDragEnd={this.handleSplitPanelDragEnd}
            >
              <div>
                <OCRWidget panelSizes={panelSizes}
                           fileData={fileData}
                           zoomLevel={zoomLevel}
                           dataUrl={dataUrl}
                           selectedContent={selectedContent}
                           isLibraryMaterial={!(projectId > 0)}
                           hideNonTaggedColumns={false}
                           visible={smartImportUploadProgressStatus !== SMART_IMPORT_UPLOAD_STATUS.NOT_STARTED}
                           textractResults={textractResults}
                           showAdvancedOptions={showAdvancedOptions}
                           maxPDFPages={MAX_PDF_PAGES}
                           onWizardStepDataUpdated={onWizardStepDataUpdated}
                />
                <SmartUploadFileDropPanel smartImportUploadProgressStatus={smartImportUploadProgressStatus}
                                          visible={smartImportUploadProgressStatus === SMART_IMPORT_UPLOAD_STATUS.NOT_STARTED}
                                          onFileDroped={this.handleFileSelected}
                />
              </div>
              <SmartUploadInputsPanel ref={(ref) => this.smartUploadInputsPanel = ref}
                                      allowedFiles={SMART_IMPORT_ALLOWED_FILE_TYPES}
                                      projectId={projectId}
                                      process={process}
                                      selectedContent={selectedContent}
                                      textractResults={textractResults}
                                      dependencyRecords={dependencyRecords}
                                      selectedDependencyRecord={selectedDependencyRecord}
                                      hideDependencyTypeahead={hideDependencyTypeahead}
                                      selectedFile={selectedFile}
                                      showAdvancedOptions={!!showAdvancedOptions}
                                      onFileSelected={this.handleFileSelected}
                                      onMaterialSelected={this.handleMaterialSelected}
                                      onETLDataUpdated={this.handleETLDataUpdated}
                                      onWizardStepDataUpdated={onWizardStepDataUpdated}
                                      onAdvancedColumnsToggleChange={this.handleAdvancedColumnsToggleChange}
                                      onSelectedProcessChanged={onSelectedProcessChanged}
                                      allowProcessSelection={allowProcessSelection}
              />
            </Split>
          </div>
        </div>
        <div className="col-sm-12 import-smart-upload-screen-footer">
          <button
            disabled={nextStepDisabled}
            title={generateTooltip(securityAction, modelNameForSecurity, null, "")}
            className="btn btn-lg btn-primary import-footer-btn"
            id="nextButton"
            onClick={this.handleMoveToNextStep}
          >
            Next &gt;
          </button>
          {this.renderImportExitButton()}
        </div>
      </Fragment>
    );
  }
}

ImportSmartUploadStep.defaultProps = {
  selectedContent: SELECTED_CONTENT.ALL_DATA,
};
