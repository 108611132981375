import { BaseParser } from "./base_parser";
import { merge } from "../index";
import { WidgetParserFactory } from "./widget_parser";
import * as UIUtils from "../../../ui_utils";
import {
  WIDGET_KIND,
  WIDGET_NAME_TO_ATTRIBUTES
} from "../../components/sideMenu/widget/widget_constants";

/**
 * Parser that parses all reference list widgets
 */
export class ReferenceListParser extends BaseParser {
  get selector() {
    return ".widget-content";
  }

  getElements() {
    const contentElement = this.node.querySelector(this.selector);
    if (!contentElement) {
      return [];
    }
    return Array.from(contentElement.childNodes);
  }

  parse() {
    const newElements = [];
    const parentModelName = this.parentNode.getAttribute
      ? this.parentNode.getAttribute("model")
      : null;
    const contentElements = this.getElements();

    for (const contentElement of contentElements) {
      const parser = WidgetParserFactory.getParser(
        this.globalNode,
        this.parentNode,
        contentElement,
        this.data,
        this.fileType,
        this.rmp,
        this.project,
        this.process,
        this.parseOnly
      );
      newElements.push(...parser.parse());

      // The "Reference List" doesn't have model like Repeater and Table, so we need to handle
      // merging fields differently. parser.fields may return the result like this
      // { Material: { attributes: ["name"] }, UnitOperation: { attributes: ["name"] } }. If it
      // stays inside a Repeater with Material model, the parentModelName will be "Material".
      // If we return the fields result above, and we merge it with the fields of the parentModel,
      // the result will be
      // { Material: { Material: { attributes: ["name"] }, UnitOperation: { attributes: ["name"] } } }
      // which is not correct since Material cannot include another Material and our query_generator
      // will have an error. Therefore, we will need to transform the result from parser.fields to
      // { attributes: ["name"], UnitOperation: { attributes: ["name"] } }
      const fields = {};
      for (const [key, value] of Object.entries(parser.fields)) {
        if (key === parentModelName) {
          for (const [itemKey, itemValue] of Object.entries(value)) {
            fields[itemKey] = itemValue;
          }
        } else {
          fields[key] = value;
        }
      }

      if (this.parseOnly) {
        merge(this.fields, fields);
      }
    }

    const returnedElements = [];
    // Find the Reference Block that this widget will aggregate in by the Reference
    // Block Name.
    const widgets = Array.from(
      this.globalNode.querySelectorAll(".widget")
    ).filter(
      (widget) =>
        widget.getAttribute("kind") === WIDGET_KIND.ReferenceBlock &&
        widget.getAttribute(
          WIDGET_NAME_TO_ATTRIBUTES[WIDGET_KIND.ReferenceBlock].ReferenceBlockName.key
        ) ===
          this.node.getAttribute(
            WIDGET_NAME_TO_ATTRIBUTES[WIDGET_KIND.ReferenceList].ReferenceBlock.key
          )
    );
    if (widgets.length) {
      const referenceBlock = widgets[0];
      referenceBlock.append(...newElements);

      // We set the "Total" attribute for Reference Block, so we can keep track
      // of how many child elements we append to it.
      const total = UIUtils.parseInt(
        referenceBlock.getAttribute("total") || "0"
      );
      referenceBlock.setAttribute("total", `${total + newElements.length}`);

      for (let i = 0; i < newElements.length; i++) {
        const indexElement = document.createElement("p");
        indexElement.innerText = `(${total + i + 1})`;
        returnedElements.push(indexElement);
      }
    }

    return returnedElements;
  }
}
