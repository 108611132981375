"use strict";

import React, { Fragment } from "react";
import BaseReactComponent from "../../base_react_component";

/**
 *  This shows any large text with a see/read more link
 **/
export default class ShowMoreText extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.setStateSafely({showingMore: false});
  }

  toggleShowMore() {
    const {showingMore} = this.state;
    this.setStateSafely({showingMore: !showingMore});
  }

  render() {
    const {text, id} = this.props;
    const {showingMore} = this.state;

    const words = text ? text.split(" ") : [];
    const lines = text ? text.split("\n") : [];
    const shouldSummarize = words.length > 30;
    const summarizedVersion = shouldSummarize ? words.splice(0, 30).join(" ") + "... " : text;

    const textToShow = shouldSummarize
      ? (
        <Fragment>
          {
            showingMore
              ? lines.map(line => (<Fragment key={line}>{line}<br /></Fragment>))
            : summarizedVersion
          }
          <a onClick={this.toggleShowMore}>{
            showingMore
              ? "Show less"
              : "Show more"
          }</a>
        </Fragment>
      )
      : text;

    return (
      <div id={id}>
        {textToShow}
      </div>
    );
  }
}
