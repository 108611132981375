"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import CollapsePanel from "../../../widgets/generic/collapse_panel";
import BaseReactComponent from "../../../base_react_component";

export default class ImportDataReviewWidget extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let errorsBody = [];
    let warningsBody = [];
    let numOfErrors = 0;
    let numOfWarnings = 0;
    let counter = 0;

    if (this.props.validations && this.props.validations.length > 0) {
      for (let validation of this.props.validations) {
        if (validation.errors.length > 0) {
          errorsBody.push(
            <div key={counter++}>
              {validation.name}
              <ul>
                {
                  validation.errors.map((error, index) => {
                    return <li key={index}>{error}</li>;
                  })
                }
              </ul>
            </div>
          );
          numOfErrors += validation.errors.length;
        }

        if (validation.warnings.length > 0) {
          warningsBody.push(
            <div key={counter++}>
              {validation.name}
              <ul>
                {
                  validation.warnings.map((warning, index) => {
                    return <li key={index}>{warning}</li>;
                  })
                }
              </ul>
            </div>
          );
          numOfWarnings += validation.warnings.length;
        }
      }
    }

    return (
      <div className={"import-data-review-widget " + (this.props.className ? this.props.className : "")}>
        <div className="import-data-review-widget-title">
          {UIUtils.pluralize(this.props.dataSectionName ? this.props.dataSectionName : this.props.title)}
        </div>
        <div>

          <div className="progress import-data-review-progress">
            {this.props.dataReviewRequired ?
              <div id="importDataReviewProgressBar"
                   className="progress-bar"
                   role="progressbar"
                   aria-valuenow={0}
                   aria-valuemin="0"
                   aria-valuemax="100"
              />
              :
              <div id="importDataReviewProgressBar"
                   className="progress-bar"
                   role="progressbar"
                   style={{width: 100 + "%"}}
                   aria-valuenow={100}
                   aria-valuemin="0"
                   aria-valuemax="100"
              >
                100%
              </div>
            }
          </div>
          {this.props.recordsCount > 0 ?
            <div className="import-data-review-widget-summary-div">
              <div className="col-sm-12 import-data-review-widget-summary">
                {`Obtained ${this.props.recordsCount}/${this.props.recordsCount} ${errorsBody.length === 0 ? "(No errors found)" : ""}`}
              </div>
            </div>
            : ""}
          {errorsBody.length > 0 ?
            <CollapsePanel
              isError={true}
              numOfErrors={numOfErrors}
              body={<div>{errorsBody}</div>}
            />
            : ""}
          {warningsBody.length > 0 ?
            <CollapsePanel
              isWarning={true}
              numOfWarnings={numOfWarnings}
              body={
                <div>
                  {warningsBody}
                </div>
              }
            />
            : ""}
        </div>
      </div>
    );
  }
}
