"use strict";

import * as UIUtils from "../../../ui_utils";
import React, { Fragment } from "react";
import BaseFilter from "../../common/base_filter";
import { Radio, RadioGroup } from "react-radio-group";
import { RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import {
  getRiskFilterNameFromRiskScale,
  getRiskScales,
  getSortedRiskScales,
  hasNotAssessedRiskScale
} from "../../../helpers/risk_helper";
import { NEEDS_ANALYSIS_TERM, RISK_LABEL_ACTION } from "../../constants/report_constants";
import { getHistoryPointForDate, showByRiskLabel } from "../utilities/risk_map_report_helper";
import FieldTooltip from "../../../widgets/tooltips/field_tooltip";
import { BATCH_TYPE_OPTIONS } from "../../../../server/common/editables/common_batches";

const DEFAULT_FILTER_VALUES = {
  linksFilter: "all",
  riskLabelTypeFilter: RISK_LABEL_ACTION.SHOW_SCORE_LABEL,
  showTPPs: true,
  showGeneralAttributes: true,
  showFQAs: true,
  showFPAs: true,
  showIQAs: true,
  showIPAs: true,
  showPPs: true,
  showMAs: true,
  showUpstream: false,
  showDownstream: false,
  showAllStreams: false,
  showAllElements: true,
  disableRiskLabelTypeFilter: false,
  disableEffectLabelTypeFilter: false,
  showControlStrategy: false,
  showProcessCapability: false,
  batchType: "PPQ (Validation)"
};

const ELEMENT_FILTERS = [
  "showGeneralAttributes",
  "showTPPs",
  "showFQAs",
  "showFPAs",
  "showIQAs",
  "showIPAs",
  "showPPs",
  "showMAs",
];

export default class RiskMapFilter extends BaseFilter {
  constructor(props) {
    super(props);
    this.uoVersions = [];
  }

  resizePanelOnly(filterPanel) {
    let canvasHeight = $("#riskMapCanvas")[0].clientHeight;
    let timelineHeight = $("#riskMapTimelineContainer")[0].clientHeight;
    let showHideTimelineButtonHeight = $("#riskMapTimelineShowButtonDiv")[0].clientHeight;
    let panelHeight = canvasHeight - timelineHeight - showHideTimelineButtonHeight - 48; //Subtracting margins
    panelHeight = Math.min(panelHeight, this.getContentsHeight() + 5);
    filterPanel.css("height", panelHeight);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {RMP, mtCategories, scaleNetworkToFit, selectedDate} = this.props;
    const {filters, riskType, allUOsSelected} = this.state;

    return !this.state
      || !this.state.filters
      || (nextProps.RMP !== RMP)
      || (nextProps.mtCategories.join("") !== mtCategories.join(""))
      || (nextProps.scaleNetworkToFit !== scaleNetworkToFit)
      || (nextProps.selectedDate !== selectedDate)
      || (JSON.stringify(nextState.filters) !== JSON.stringify(filters))
      || (nextState.riskType !== riskType)
      || (nextState.allUOsSelected !== allUOsSelected);
  }

  // handlers for "select all" checkboxes
  handleToggleAllUOs(event) {
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    for (let uoVersion of this.uoVersions) {
      filters[`uo_${uoVersion.unitOperationId}`] = event.target.checked;
    }
    this.setStateSafely({
      allUOsSelected: event.target.checked,
      filters: filters
    }, () => {
      this.props.onFiltersUpdated(JSON.stringify(this.state.filters));
    });
  }

  handleToggleAllMTs(event) {
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    for (let category of this.mtCategories) {
      filters[`mt_${UIUtils.convertToId(category)}`] = event.target.checked;
    }
    this.setStateSafely({
      allMTCategoriesSelected: event.target.checked,
      filters: filters,
    }, () => {
      this.props.onFiltersUpdated(JSON.stringify(this.state.filters));
    });
  }

  handleToggleAllStreams(event) {
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    filters.showUpstream = event.target.checked;
    filters.showDownstream = event.target.checked;
    this.setStateSafely({
      allStreamsSelected: event.target.checked,
      filters: filters,
    }, () => {
      this.props.onFiltersUpdated(JSON.stringify(this.state.filters));
    });
  }

  handleToggleAllRisk(event) {
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    for (let filter in filters) {
      if (this.shouldChangeRiskFilter(filter)) {
        filters[filter] = event.target.checked;
      }
    }
    this.setStateSafely({
      allRiskSelected: event.target.checked,
      filters: filters,
    }, () => {
      this.props.onFiltersUpdated(JSON.stringify(this.state.filters));
    });
  }

  handleToggleAllElements(event) {
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    for (let element of ELEMENT_FILTERS) {
      if (this.shouldChangeElementFilter(element))
        filters[element] = event.target.checked;
    }

    this.setStateSafely({
      allElementsSelected: event.target.checked,
      filters: filters
    }, () => {
      this.props.onFiltersUpdated(JSON.stringify(this.state.filters));
    });
  }

  componentDidUpdate() {
    super.componentDidUpdate();

    if (this.state.filtersInitialized) {
      if (typeof this.props.scaleNetworkToFit === "boolean" && this.props.scaleNetworkToFit) {
        this.collapse();
      }

      let requiresNewUpdate = false;
      let filters = JSON.parse(JSON.stringify(this.state.filters));
      for (let uoVersion of this.uoVersions) {
        let filterId = `uo_${uoVersion.unitOperationId}`;
        if (typeof filters[filterId] === "undefined") {
          filters[filterId] = true;
          requiresNewUpdate = true;
        }
      }

      if (requiresNewUpdate) {
        this.setStateSafely({filters: filters}, () => {
          this.props.onFiltersUpdated(JSON.stringify(this.state.filters));
        });
      }
    }
  }

  initializeDefaultFilters() {
    if (this.props.rmpVersions) {
      let filters = DEFAULT_FILTER_VALUES;
      filters.riskTracedToFilter = this.props.defaultRiskTracedToFilter || "traceToTPPs";
      filters["scoreLabelFilters"] = [];
      filters["riskLabelFilters"] = [];
      filters[RISK_LABEL_ACTION.SHOW_EFFECT_LABEL] = false;

      let filterName = getRiskFilterNameFromRiskScale(null);
      filters[filterName] = true;
      for (let rmpVersion of this.props.rmpVersions) {
        for (let riskScale of getRiskScales(RISK_TYPE_ENUM.CRITICALITY, rmpVersion)) {
          let scoreLabelFilterName = getRiskFilterNameFromRiskScale(riskScale);
          let riskLabelFilterName = getRiskFilterNameFromRiskScale(riskScale, false, true);
          filters[scoreLabelFilterName] = true;
          filters[riskLabelFilterName] = true;
          filters.scoreLabelFilters.push(scoreLabelFilterName);
          filters.riskLabelFilters.push(riskLabelFilterName);
        }

        for (let filter of this.getRMPDynamicFilters(rmpVersion)) {
          filters[filter.name] = true;
          filters.riskLabelFilters.push(filter.name);
        }
      }
      for (let uoVersion of this.uoVersions) {
        filters[`uo_${uoVersion.unitOperationId}`] = true;
      }

      for (let category of this.mtCategories) {
        filters[`mt_${UIUtils.convertToId(category)}`] = true;
      }

      this.setStateSafely({
        filters: DEFAULT_FILTER_VALUES,
        riskType: "CRITICALITY",
        allUOsSelected: true
      }, () => {
        this.props.onFiltersUpdated(JSON.stringify(this.state.filters));
        this.props.onCriticalityViewChange(this.state.riskType);

      });

      super.initializeDefaultFilters();
    }
  }

  getRMPDynamicFilters(RMP) {
    return this.getRMPPotentialRiskFilters(RMP).concat(this.getRMPRulesBasedCriticalityFilters(RMP));
  }

  getRMPPotentialRiskFilters(RMP) {
    return this.getRMPDynamicFiltersForType(RMP, "usePotential", "potentialLabel");
  }

  getRMPRulesBasedCriticalityFilters(RMP) {
    return this.getRMPDynamicFiltersForType(RMP, "useRulesBasedCriticality", "keyLabel");
  }

  getRMPDynamicFiltersForType(RMP, type, prefix) {
    const filters = [];
    const rmpSettings = JSON.parse(RMP.configureByTypeSettings);
    if (RMP[type]) {
      const keys = Object.keys(rmpSettings).filter(objectKey => objectKey.startsWith(prefix));
      for (let key of keys) {
        if (rmpSettings[key]) {
          let riskLabelFilterName = getRiskFilterNameFromRiskScale({
            riskLabel: rmpSettings[key]
          }, false, true);
          filters.push({
            label: rmpSettings[key],
            name: riskLabelFilterName
          });
        }
      }
    }

    return filters;
  }

  getCSSPrefix() {
    return "risk-map";
  }

  getContentsHeight() {
    return 1220;
  }

  resetFilters(event) {
    super.resetFilters(event);
    let riskType = "CRITICALITY";
    let defaultFilterValue = UIUtils.deepClone(DEFAULT_FILTER_VALUES);
    let filterName = getRiskFilterNameFromRiskScale(null);
    defaultFilterValue[filterName] = true;
    for (let rmpVersion of this.props.rmpVersions) {
      for (let riskScale of getRiskScales(RISK_TYPE_ENUM[riskType], rmpVersion)) {
        let scoreLabelFilterName = getRiskFilterNameFromRiskScale(riskScale, false, false);
        let riskLabelFilterName = getRiskFilterNameFromRiskScale(riskScale, false, true);
        defaultFilterValue[scoreLabelFilterName] = true;
        defaultFilterValue[riskLabelFilterName] = true;
      }
    }

    for (let uoVersion of this.uoVersions) {
      defaultFilterValue[`uo_${uoVersion.unitOperationId}`] = true;
    }

    for (let category of this.mtCategories) {
      defaultFilterValue[`mt_${UIUtils.convertToId(category)}`] = true;
    }

    this.setStateSafely({
      filters: defaultFilterValue,
      riskType: riskType,
      allUOsSelected: true,
      allMTCategoriesSelected: true,
      disableRiskLabelTypeFilter: false,
    }, () => {
      this.props.onFiltersUpdated(JSON.stringify(this.state.filters));
      this.props.onCriticalityViewChange(riskType);
    });
  }

  filtersChanged(filter, value) {
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    filters[filter] = value;
    this.setStateSafely({
      filters,
      allUOsSelected: filter.startsWith("uo_") && !value ? false : this.state.allUOsSelected,
      allMTCategoriesSelected: filter.startsWith("mt_") && !value ? false : this.state.allMTCategoriesSelected,
      allStreamsSelected: filter.endsWith("stream") && !value ? false : this.state.allStreamsSelected,
      allRiskSelected: this.shouldChangeRiskFilter(filter) && !value ? false : this.state.allRiskSelected,
      allElementsSelected: this.shouldChangeElementFilter(filter) && !value ? false : this.state.allElementsSelected,
    }, () => {
      this.props.onFiltersUpdated(JSON.stringify(this.state.filters));
    });
  }

  shouldChangeElementFilter(filter) {
    let isElementFilter;

    if (this.state.filters.riskTracedToFilter === "traceToTPPs") {
      isElementFilter = ELEMENT_FILTERS.includes(filter) && filter !== "showGeneralAttributes";
    } else {
      isElementFilter = ELEMENT_FILTERS.includes(filter) && filter !== "showTPPs";
    }

    return isElementFilter;
  }

  shouldChangeRiskFilter(filter) {
    let isRiskFilter;
    let riskLabelTypeFilter = this.state.filters.riskLabelTypeFilter;
    let scoreLabelFilters = JSON.parse(JSON.stringify(this.state.filters.scoreLabelFilters));
    let riskLabelFilters = JSON.parse(JSON.stringify(this.state.filters.riskLabelFilters));

    if (riskLabelTypeFilter === RISK_LABEL_ACTION.SHOW_SCORE_LABEL) {
      isRiskFilter = scoreLabelFilters.includes(filter) || filter === "showWithUnspecifiedRisk";
    } else {
      isRiskFilter = riskLabelFilters.includes(filter) || filter === "showWithUnspecifiedRisk";
    }

    return isRiskFilter;
  }

  showLinkedFilterChange(value) {
    this.filtersChanged("linksFilter", value);
  }

  handleCheckBoxFilterChange(filter, event) {
    this.filtersChanged(filter, event.target.checked);
  }

  handleTraceToChange(value) {
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    filters["riskTracedToFilter"] = value;

    this.setStateSafely({
      filters,
      allElementsSelected: true,
    }, () => {
      this.filtersChanged("riskTracedToFilter", value);
      this.props.onRiskTracedToChange();
    });
  }

  handleShowControlStrategyChange(event) {
    this.filtersChanged("showControlStrategy", event.target.checked);
  }

  handleRiskLabelTypeChange(value) {
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    for (let key of Object.keys(filters)) {
      if (key.startsWith("show") && key.endsWith("Risk")) {
        filters[key] = true;
      }
    }

    let riskScales = getSortedRiskScales(RISK_TYPE_ENUM[this.state.riskType], this.props.RMP);
    for (let riskScale of riskScales) {
      let showRiskLabel = showByRiskLabel(value);
      let filterName = getRiskFilterNameFromRiskScale(riskScale, false, showRiskLabel);
      filters[filterName] = true;
    }

    this.setStateSafely({
      filters,
    }, () => {
      this.filtersChanged("riskLabelTypeFilter", value);
    });
  }

  handleShowProcessCapabilityChange(event) {
    this.filtersChanged("showProcessCapability", event.target.checked);
  }

  handleBatchTypeChange(event) {
    const batchType = event.target.value;
    console.log("batch type", batchType);

    let filters = JSON.parse(JSON.stringify(this.state.filters));
    filters.batchType = batchType;

    this.setStateSafely({
      filters
    }, () => {
      this.props.onFiltersUpdated(JSON.stringify(this.state.filters));
    });
  }

  handleCriticalityViewChange(event) {
    let riskType = event.target.value;
    let riskTypeValue = RISK_TYPE_ENUM[riskType];
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    for (let key of Object.keys(filters)) {
      if (key.startsWith("show") && key.endsWith("Risk")) {
        filters[key] = true;
      }
    }

    for (let rmpVersion of this.props.rmpVersions) {
      for (let riskScale of getRiskScales(riskTypeValue, rmpVersion)) {
        let filterName = getRiskFilterNameFromRiskScale(riskScale, false, showByRiskLabel(filters, riskTypeValue));
        filters[filterName] = true;
      }
    }

    let disableRiskLabelTypeFilter = false;
    if (riskTypeValue === RISK_TYPE_ENUM.IMPACT || riskTypeValue === RISK_TYPE_ENUM.UNCERTAINTY ||
      riskTypeValue === RISK_TYPE_ENUM.DETECTABILITY_RISK || riskTypeValue === RISK_TYPE_ENUM.CAPABILITY_RISK) {
      filters["riskLabelTypeFilter"] = RISK_LABEL_ACTION.SHOW_SCORE_LABEL;
      disableRiskLabelTypeFilter = true;
    }

    this.setStateSafely({
      filters,
      riskType,
      disableRiskLabelTypeFilter
    }, () => {
      this.props.onFiltersUpdated(JSON.stringify(this.state.filters));
      this.props.onCriticalityViewChange(riskType);
    });
  }

  getRiskFilters() {
    if (this.props.RMP) {
      let riskLabels = [];
      const hasNotAssessed = hasNotAssessedRiskScale(RISK_TYPE_ENUM.CRITICALITY, this.props.RMP);
      let riskScales = getSortedRiskScales(RISK_TYPE_ENUM[this.state.riskType], this.props.RMP);
      let riskFilters = riskScales.map(riskScale => {
        let showRiskLabel = showByRiskLabel(this.state.filters);
        let filterName = getRiskFilterNameFromRiskScale(riskScale, false, showRiskLabel);
        let riskLabel = showRiskLabel ? riskScale.riskLabel : riskScale.scoreLabel;

        let filterHTML = this.getRiskFilterComponent(filterName, riskLabel);

        if (!riskLabels.includes(riskLabel)) {
          riskLabels.push(riskLabel);
          return filterHTML;
        }
        return undefined;
      });

      let filterName = getRiskFilterNameFromRiskScale(null);
      if (!hasNotAssessed) {
        riskFilters.unshift(this.getRiskFilterComponent(filterName, NEEDS_ANALYSIS_TERM));
      }

      if (RISK_TYPE_ENUM[this.state.riskType] === RISK_TYPE_ENUM.CRITICALITY && showByRiskLabel(this.state.filters)) {
        riskFilters = riskFilters.concat(this.getRMPDynamicFilters(this.props.RMP).map(filter => {
          return this.getRiskFilterComponent(filter.name, filter.label);
        }));
      }

      return riskFilters;
    }
    return null;
  }

  getRiskFilterComponent(filterName, riskLabel) {
    return (<div key={filterName} className="risk-map-filter-item-row">
      <div className="risk-map-filter-field risk-map-checkbox-filter">
        <label id={filterName + "FilterLabel"}
               className="col-form-label"
        >
          <input type="checkbox"
                 id={filterName + "Filter"}
                 checked={!!this.state.filters[filterName]}
                 onChange={this.handleCheckBoxFilterChange.bind(this, filterName)}
          />
          {riskLabel}
        </label>
      </div>
    </div>);
  }

  getUOsFilters() {
    return this.uoVersions.sort((a, b) => a.order - b.order).map(uoVersion => {
      let filterName = uoVersion.name;
      let filterId = `uo_${uoVersion.unitOperationId}`;

      return (
        <div key={filterId} className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-checkbox-filter">
            <label id={UIUtils.convertToId(filterName) + "_FilterLabel"}
                   className="col-form-label"
            >
              <input type="checkbox"
                     id={UIUtils.convertToId(filterName) + "_Filter"}
                     checked={!!this.state.filters[filterId]}
                     onChange={this.handleCheckBoxFilterChange.bind(this, filterId)}
              />
              {filterName}
            </label>
          </div>
        </div>
      );
    });
  }

  getMTsFilters() {
    return this.mtCategories.map(category => {
      let filterName = category;
      let filterId = `mt_${UIUtils.convertToId(category)}`;

      return (
        <div key={filterId} className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-checkbox-filter">
            <label id={UIUtils.convertToId(filterName) + "_FilterLabel"}
                   className="col-form-label"
            >
              <input type="checkbox"
                     id={UIUtils.convertToId(filterName) + "_Filter"}
                     checked={!!this.state.filters[filterId]}
                     onChange={this.handleCheckBoxFilterChange.bind(this, filterId)}
              />
              {filterName}
            </label>
          </div>
        </div>
      );
    });
  }

  get mtCategories() {
    return this.props.mtCategories || [];
  }

  renderPanelContent() {
    let historyPoint = this.props.mapHistory ? getHistoryPointForDate(this.props.selectedDate, this.props.mapHistory) : null;
    this.uoVersions = historyPoint && historyPoint.unitOperationVersions ? historyPoint.unitOperationVersions : [];
    let allUOsSelected = this.state && this.state.filters
      && Object.keys(this.state.filters)
        .filter(key => key.startsWith("uo_") && !!this.uoVersions.find(uoVersion => uoVersion.unitOperationId === UIUtils.parseInt(key.replace("uo_", ""))))
        .reduce((result, key) => result && this.state.filters[key], true);

    let allMTCategoriesSelected = this.state && this.state.filters
      && Object.keys(this.state.filters)
        .filter(key => key.startsWith("mt_") && !!this.mtCategories.find(category => `mt_${UIUtils.convertToId(category)}` === key))
        .reduce((result, key) => result && this.state.filters[key], true);

    let allStreamsSelected = this.state && this.state.filters
      && Object.keys(this.state.filters)
        .filter(key => key.endsWith("stream"))
        .reduce((result, key) => result && this.state.filters[key], true);

    let allRiskSelected = this.state && this.state.filters
      && Object.keys(this.state.filters)
        .filter(key => key.startsWith("show") && key.endsWith("Risk"))
        .reduce((result, key) => result && this.state.filters[key], true);

    let allElementsSelected = this.state && this.state.filters
      && Object.keys(this.state.filters)
        .filter(key => this.shouldChangeElementFilter(key))
        .reduce((result, key) => result && this.state.filters[key], true);

    return this.state && this.state.filters ? (
      <Fragment>
        <div className="risk-map-filter-item-row-header">
          <div className="risk-map-filter-header">Show:</div>
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-color-showing-div">
            <label id="showVeryHighRiskFilterLabel"
                   htmlFor="riskTypeViewRiskMapDropDown"
                   className="col-form-label risk-map-filter-label risk-map-color-showing-label"
            >
              Color:&nbsp;
            </label>
            <select id="riskTypeViewRiskMapDropDown"
                    className="form-control risk-type-drop-down"
                    value={this.state.riskType}
                    onChange={this.handleCriticalityViewChange}
            >
              {Object.keys(RISK_TYPE_ENUM).map(key => {
                return <option value={key}
                               key={key}
                >{RISK_TYPE_ENUM[key]}
                </option>;
              })}
            </select>
          </div>
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-color-showing-div">
            <label id="showVeryHighRiskFilterLabel"
                   htmlFor="riskTypeViewRiskMapDropDown"
                   className="col-form-label risk-map-filter-label risk-map-color-showing-label"
            >
              Label:&nbsp;
            </label>
            <RadioGroup name={`riskMapLabelsRadioGroup`}
                        htmlFor="riskTypeViewRiskMapRadioGroup"
                        selectedValue={typeof this.state.filters.riskLabelTypeFilter !== "undefined" ? this.state.filters.riskLabelTypeFilter : RISK_LABEL_ACTION.SHOW_SCORE_LABEL}
                        onChange={this.handleRiskLabelTypeChange}
            >
              <label id={`showByRiskLabelFilterLabel`}
                     className="col-form-label"
              >
                <Radio id={`showByRiskLabelFilter`}
                       disabled={this.state.disableRiskLabelTypeFilter}
                       value={RISK_LABEL_ACTION.SHOW_RISK_LABEL}
                />Risk Label
              </label>
              <label id={`showByScoreLabelFilterLabel`}
                     className="col-form-label"
              >
                <Radio id={`showByScoreLabelFilter`}
                       disabled={this.state.disableRiskLabelTypeFilter}
                       value={RISK_LABEL_ACTION.SHOW_SCORE_LABEL}
                />Score Label
              </label>
            </RadioGroup>
          </div>
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-color-showing-div">
            <label id="showTraceToFilterLabel"
                   htmlFor="traceToDropDown"
                   className="col-form-label risk-map-filter-label risk-map-color-showing-label"
            >
              Risk traced to:&nbsp;
            </label>
            <RadioGroup name={`traceToLabelsRadioGroup`}
                        htmlFor="traceToRadioGroup"
                        selectedValue={typeof this.state.filters.riskTracedToFilter !== "undefined" ? this.state.filters.riskTracedToFilter : "traceToTPPs"}
                        onChange={this.handleTraceToChange}
            >
              <label id={`traceToGeneralAttributesFilterLabel`}
                     className="col-form-label"
              >
                <Radio id={`traceToGeneralAttributesFilter`}
                       value="traceToGeneralAttributes"
                />General Attributes
              </label>
              <label id={`traceToTPPsFilterLabel`}
                     className="col-form-label"
              >
                <Radio id={`traceToTPPsFilter`}
                       value="traceToTPPs"
                />Target Product Profile
              </label>
            </RadioGroup>
          </div>
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-color-showing-div">
            <div id="riskMapFilterControlStrategyTypeDiv"
                 className="risk-map-filter-label"
            >
              <input type="checkbox"
                     id="showControlStrategy"
                     checked={typeof this.state.filters.showControlStrategy !== "undefined" ? this.state.filters.showControlStrategy : false}
                     onChange={this.handleShowControlStrategyChange}
              />
              Control Strategy
              <FieldTooltip id="controlStrategyFilterTooltip"
                            text="On searching, you will see control strategy labels for the requirement(s)"
              />
            </div>
          </div>
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-color-showing-div">
            <div id="riskMapFilterEffectLabelTypeDiv"
                 className="risk-map-filter-label"
            >
              <input type="checkbox"
                     id="showEffectOnAttribute"
                     checked={typeof this.state.filters[RISK_LABEL_ACTION.SHOW_EFFECT_LABEL] !== "undefined" ? this.state.filters[RISK_LABEL_ACTION.SHOW_EFFECT_LABEL] : false}
                     onChange={this.handleShowEffectLabelChange}
              />
              Effect on Attribute
              <FieldTooltip id="effectOnAttributeFilterTooltip"
                            text="Shows the Effect on Attribute arrows in the risk map"
              />
            </div>
          </div>
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-color-showing-div">
            <div id="riskMapFilterProcessCapabilityTypeDiv"
                 className="risk-map-filter-label"
            >
              <input type="checkbox"
                     id="showProcessCapability"
                     checked={typeof this.state.filters.showProcessCapability !== "undefined" ? this.state.filters.showProcessCapability : false}
                     onChange={this.handleShowProcessCapabilityChange}
              />
              Process Capability
              <FieldTooltip id="processCapabilityFilterTooltip"
                            text="Select the type of batches used for process capability calculations (CPK/CPM) when viewing control strategy representation"
              />
            </div>
          </div>
        </div>

        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-color-showing-div" style={{paddingLeft: "33px"}}>
            <label id="batchTypeFilterLabel"
                   htmlFor="batchTypeDropDown"
                   className="col-form-label risk-map-filter-label risk-map-color-showing-label"
            >
              Batch:
            </label>
          </div>
        </div>

        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field" style={{paddingLeft: "33px"}}>
            <select id="batchTypeDropDown"
                    disabled={!this.state.filters.showProcessCapability}
                    className="form-control risk-type-drop-down"
                    value={this.state.filters.batchType}
                    onChange={this.handleBatchTypeChange}
            >
              {BATCH_TYPE_OPTIONS.map(key => {
                return <option value={key}
                               key={key}
                >{key}
                </option>;
              })}
            </select>
          </div>
        </div>

        <div className="risk-map-filter-item-row-header">
          <div className="risk-map-filter-header">Filter by:</div>
        </div>
        <div className="risk-map-filter-item-row">
          <div id="riskMapFilterRiskTypeDiv"
               className="risk-map-filter-category"
          >
            <input type="checkbox"
                   id="showAllRisk"
                   checked={!!this.state.allRiskSelected || allRiskSelected}
                   onChange={this.handleToggleAllRisk}
            />
            {RISK_TYPE_ENUM[this.state.riskType] + ":"}</div>
        </div>
        <div key="dynamicFilters"
             id="riskMapRiskFiltersDiv"
        >
          {this.getRiskFilters()}
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-category">Linked:</div>
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-radio-filter">
            <RadioGroup name="filter"
                        selectedValue={typeof this.state.filters.linksFilter !== "undefined" ? this.state.filters.linksFilter : "all"}
                        onChange={this.showLinkedFilterChange}
            >
              <label id="showAllFilterLabel"
                     className="col-form-label"
              >
                <Radio id="showAllFilter"
                       value="all"
                />All
              </label>
              <label id="showLinkedFilterLabel"
                     className="col-form-label"
              >
                <Radio id="showLinkedFilter"
                       value="onlyLinked"
                />Only Linked
              </label>
              <label id="showUnlinkedFilterLabel"
                     className="col-form-label"
              >
                <Radio id="showUnlinkedFilter"
                       value="onlyUnlinked"
                />Only Unlinked
              </label>
            </RadioGroup>
          </div>
        </div>
        <div className="risk-map-filter-item-row">

          <div className="risk-map-filter-category">
            <input type="checkbox"
                   id="showAllElements"
                   checked={!!this.state.filters.allElementsSelected || allElementsSelected}
                   onChange={this.handleToggleAllElements}
            />
            Element:
          </div>
        </div>
        {this.state.filters.riskTracedToFilter === "traceToTPPs" ?
          <div className="risk-map-filter-item-row">
            <div className="risk-map-filter-field risk-map-checkbox-filter">
              <label id="showTPPsFilterLabel"
                     className="col-form-label"
              >
                <input type="checkbox"
                       id="showTPPsFilter"
                       checked={!!this.state.filters.showTPPs}
                       onChange={this.handleCheckBoxFilterChange.bind(this, "showTPPs")}
                /> Target Product Profile
              </label>
            </div>
          </div> :
          <div className="risk-map-filter-item-row">
            <div className="risk-map-filter-field risk-map-checkbox-filter">
              <label id="showGeneralAttributesFilterLabel"
                     className="col-form-label"
              >
                <input type="checkbox"
                       id="showGeneralAttributesFilter"
                       checked={!!this.state.filters.showGeneralAttributes}
                       onChange={this.handleCheckBoxFilterChange.bind(this, "showGeneralAttributes")}
                /> General Attribute
              </label>
            </div>
          </div>}
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-checkbox-filter">
            <label id="showFQAsFilterLabel"
                   className="col-form-label"
            >
              <input type="checkbox"
                     id="showFQAsFilter"
                     checked={!!this.state.filters.showFQAs}
                     onChange={this.handleCheckBoxFilterChange.bind(this, "showFQAs")}
              /> Final Quality Attribute
            </label>
          </div>
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-checkbox-filter">
            <label id="showFPAsFilterLabel"
                   className="col-form-label"
            >
              <input type="checkbox"
                     id="showFPAsFilter"
                     checked={!!this.state.filters.showFPAs}
                     onChange={this.handleCheckBoxFilterChange.bind(this, "showFPAs")}
              /> Final Performance Attribute
            </label>
          </div>
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-checkbox-filter">
            <label id="showIQAsFilterLabel"
                   className="col-form-label"
            >
              <input type="checkbox"
                     id="showIQAsFilter"
                     checked={!!this.state.filters.showIQAs}
                     onChange={this.handleCheckBoxFilterChange.bind(this, "showIQAs")}
              /> Intermediate Quality
              Attribute
            </label>
          </div>
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-checkbox-filter">
            <label id="showIPAsFilterLabel"
                   className="col-form-label"
            >
              <input type="checkbox"
                     id="showIPAsFilter"
                     checked={!!this.state.filters.showIPAs}
                     onChange={this.handleCheckBoxFilterChange.bind(this, "showIPAs")}
              /> Intermediate Performance
              Attribute
            </label>
          </div>
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-checkbox-filter">
            <label id="showPPsFilterLabel"
                   className="col-form-label"
            >
              <input type="checkbox"
                     id="showPPsFilter"
                     checked={!!this.state.filters.showPPs}
                     onChange={this.handleCheckBoxFilterChange.bind(this, "showPPs")}
              /> Process Parameter
            </label>
          </div>
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-checkbox-filter">
            <label id="showMAsFilterLabel"
                   className="col-form-label"
            >
              <input type="checkbox"
                     id="showMAsFilter"
                     checked={!!this.state.filters.showMAs}
                     onChange={this.handleCheckBoxFilterChange.bind(this, "showMAs")}
              /> Material Attribute
            </label>
          </div>
        </div>
        <div className="risk-map-filter-item-row">

          <div className="risk-map-filter-category">
            <input type="checkbox"
                   id="showAllStreams"
                   checked={!!this.state.filters.allStreamsSelected || allStreamsSelected}
                   onChange={this.handleToggleAllStreams}
            />
            Filter Overrides:
          </div>
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-checkbox-filter">
            <label id="showUpstreamFilterLabel"
                   className="col-form-label"
            >
              <input type="checkbox"
                     id="showUpstreamFilter"
                     checked={!!this.state.filters.showUpstream}
                     onChange={this.handleCheckBoxFilterChange.bind(this, "showUpstream")}
              /> Upstream
            </label>
          </div>
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-field risk-map-checkbox-filter">
            <label id="showDownstreamFilterLabel"
                   className="col-form-label"
            >
              <input type="checkbox"
                     id="showDownstreamFilter"
                     checked={!!this.state.filters.showDownstream}
                     onChange={this.handleCheckBoxFilterChange.bind(this, "showDownstream")}
              /> Downstream
            </label>
          </div>
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-category">
            <div className="unit-operations-select-all-checkbox">
              <input type="checkbox"
                     id="uosFilterCategory"
                     checked={!!this.state.allUOsSelected || allUOsSelected}
                     onChange={this.handleToggleAllUOs}
              />
            </div>
            <div className="unit-operations-select-all-checkbox-label">
              <label id="uosFilterCategoryLabel">
                Unit Operations:
              </label>
            </div>
          </div>
        </div>
        <div key="uosFilters"
             id="riskMapUOsFiltersDiv"
        >
          {this.getUOsFilters()}
        </div>
        <div className="risk-map-filter-item-row">
          <div className="risk-map-filter-category">
            <div className="raw-materials-select-all-checkbox">
              <input type="checkbox"
                     id="mtFilterCategory"
                     checked={!!this.state.allMTCategoriesSelected || allMTCategoriesSelected}
                     onChange={this.handleToggleAllMTs}
              />
            </div>
            <div className="raw-materials-select-all-checkbox-label">
              <label id="mtFilterCategoryLabel">
                Raw Materials:
              </label>
            </div>
          </div>
        </div>
        <div key="rawMaterialFilters"
             id="riskMapMTsFiltersDiv"
        >
          {this.getMTsFilters()}
        </div>
      </Fragment>
    ) : null;
  }

  handleShowEffectLabelChange() {
    this.filtersChanged(RISK_LABEL_ACTION.SHOW_EFFECT_LABEL, event.target.checked);
  }
}
