"use strict";

import React from "react";
import { StateEnum } from "../../editor/quick/quick_panel";
import BaseReactComponent from "../../base_react_component";
import PropTypes from "prop-types";
import Split from "react-split";

const ANIMATION_TIME = 1000; // This needs to match the transition time in _editables.scss

/**
 * This is the base class for every page that wants to have a static panel that flies in from the right when a record
 * is clicked on.
 */
export default class FlyoutPanel extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.setStateSafely({
      showingState: StateEnum.CLOSED,
    });

    this.handleWindowResize();
  }

  componentDidMount() {
    super.componentDidMount();
    if (!this.props.id) {
      throw new Error("You must set a unique ID on the FlyoutPanel");
    }
    window.addEventListener("resize", this.handleWindowResize);

    let panelSizes = localStorage.getItem(this.getLocalStorageKey());
    panelSizes = panelSizes ? JSON.parse(panelSizes) : [40, 60];
    this.setStateSafely({panelSizes});
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener("resize", this.handleWindowResize);

    this.setStateSafely({
      showingState: StateEnum.CLOSED,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shouldShowRightPanel !== this.props.shouldShowRightPanel) {
      if (this.state.isWindowBigEnoughForStaticPanel) {
        if (this.props.shouldShowRightPanel && this.state.showingState !== StateEnum.OPEN) {
          this.expandToOpen();
        } else if (!this.props.shouldShowRightPanel && this.state.showingState !== StateEnum.CLOSED) {
          this.collapseToClosed();
        }
      } else if (this.props.onOpenInNewWindow) {
        this.props.onOpenInNewWindow();
      }
    }
  }

  handleWindowResize() {
    this.setStateSafely({
      isWindowBigEnoughForStaticPanel: !this.props.onOpenInNewWindow || ($(window).width() >= 768 && $(window).height() >= 500),
    }, () => {
      this.recomputePanelSizes(true);
    });
  }

  handleSplitPanelDrag() {
    let panelSizes = this.splitRef.split.getSizes();
    this.setStateSafely({panelSizes}, () => {
      this.recomputePanelSizes(false, () => {
        this.props.onSplitPanelDrag && this.props.onSplitPanelDrag();
      });
    });
  }

  handleSplitPanelDragEnd() {
    let panelSizes = this.splitRef.split.getSizes();
    this.setStateSafely({panelSizes}, () => {
      this.recomputePanelSizes(true, () => {
        this.props.onSplitPanelDragEnd && this.props.onSplitPanelDragEnd();
      });
    });
  }

  expandToOpen() {
    if (this.state.isWindowBigEnoughForStaticPanel && this.state.showingState !== StateEnum.OPEN) {
      this.turnOnAnimationTemporarily();
      this.setStateSafely(
        {
          showingState: StateEnum.EXPANDING_TO_OPEN,
        },
        () => {
          setTimeout(() => {
            this.setStateSafely({showingState: StateEnum.OPEN}, this.props.onSplitPanelAnimationEnd);
          }, ANIMATION_TIME);
        });
    }
  }

  turnOnAnimationTemporarily() {
    $(".flyout-right-panel").addClass("with-animation");
    $(".flyout-left-panel").addClass("with-animation");
    setTimeout(() => {
      $(".flyout-right-panel").removeClass("with-animation");
      $(".flyout-left-panel").removeClass("with-animation");
    }, ANIMATION_TIME + 200);
  }

  collapseToClosed() {
    this.turnOnAnimationTemporarily();
    if (this.state.isWindowBigEnoughForStaticPanel && this.state.showingState !== StateEnum.CLOSED) {
      this.setStateSafely({
        showingState: StateEnum.COLLAPSING,
      }, () => {
        setTimeout(() => {
          this.setStateSafely({showingState: StateEnum.CLOSED}, this.props.onSplitPanelAnimationEnd);
        }, ANIMATION_TIME);
      });
    }
  }

  /**
   * Makes sure the new panel sizes that the user has caused by dragging or resizing the window still works.
   *
   * @param storeSizes True if the size should be stored in localStorage so it'll open up that size later.
   * @param [callback] An optional callback function to be called after the sizes have been recomputed.
   */
  recomputePanelSizes(storeSizes, callback) {
    if (this.splitRef && [StateEnum.EXPANDING_TO_OPEN, StateEnum.OPEN].includes(this.state.showingState)) {
      let width = $(window).width();

      let maxWidthLeft = this.props.maxLeftWidth || width;
      let maxWidthLeftPercent = (maxWidthLeft / width) * 100;
      let minWidthLeft = this.props.minSize ? this.props.minSize[0] : 0;
      let minWidthLeftPercent = (minWidthLeft / width) * 100;
      let minWidthRight = this.props.minSize ? this.props.minSize[1] : 0;
      let minWidthRightPercent = (minWidthRight / width) * 100;

      let panelSizes = this.state.panelSizes || [40, 60];

      if (minWidthLeftPercent > (100 - minWidthRightPercent)) {
        // We can't fit both the minimums. The right side minimum wins as long as it's less than 80%.
        minWidthRightPercent = Math.min(minWidthRightPercent, 80);
        panelSizes = [100 - minWidthRightPercent, minWidthRightPercent];
      } else if (panelSizes[1] < minWidthRightPercent) {
        panelSizes = [100 - minWidthRightPercent, minWidthRightPercent];
      } else if (panelSizes[0] > maxWidthLeftPercent) {
        panelSizes = [maxWidthLeftPercent, 100 - maxWidthLeftPercent];
      } else if (panelSizes[0] < minWidthLeftPercent) {
        panelSizes = [minWidthLeftPercent, 100 - minWidthLeftPercent];
      }

      this.setStateSafely({panelSizes}, () => {
        if (storeSizes) {
          localStorage.setItem(this.getLocalStorageKey(), JSON.stringify(panelSizes));
        }

        callback && callback();
      });
    }
  }

  getLocalStorageKey() {
    return this.props.id + "-sizes";
  }

  render() {
    let {isWindowBigEnoughForStaticPanel, showingState, panelSizes} = this.state;
    const {className} = this.props;

    let hideRight = !isWindowBigEnoughForStaticPanel || showingState === StateEnum.CLOSED || showingState === StateEnum.COLLAPSING;
    if (hideRight) {
      panelSizes = [100, 0];
    }

    return (
      <div className={"flyout-panel" + (className ? " " + className : "")} id={this.props.id}>
        <Split sizes={panelSizes}
               ref={ref => this.splitRef = ref}
               gutterSize={hideRight ? 0 : 4}
               gutterAlign="center"
               minSize={0}
               snapOffset={0}
               dragInterval={1}
               direction="horizontal"
               cursor="col-resize"
               onDrag={this.handleSplitPanelDrag}
               onDragEnd={this.handleSplitPanelDragEnd}
        >
          <div className="flyout-left-panel">
            {this.props.leftPanel}
          </div>
          <div className="flyout-right-panel">
            {this.props.rightPanel}
          </div>
        </Split>
      </div>
    );
  }

}

FlyoutPanel.propTypes = {
  maxLeftWidth: PropTypes.number,
  minSize: PropTypes.arrayOf(PropTypes.number),
  leftPanel: PropTypes.any,
  rightPanel: PropTypes.any,
  shouldShowRightPanel: PropTypes.any, // When this is falsy, the flyout panel will disappear. When it changes, this panel will update/call the onOpenInNewWindow method on small screens
  onSplitPanelDrag: PropTypes.func, // Called when the split panel is dragged
  onSplitPanelDragEnd: PropTypes.func, // Called when the split panel dragging is done
  onSplitPanelAnimationEnd: PropTypes.func, // Called when animation has completed if the panels need to update themselves.
  onOpenInNewWindow: PropTypes.func, // Called when there is not enough space to show the right panel (ie. on mobile). Don't set it to open the panel regardless of screen size.
  className: PropTypes.string,
};
