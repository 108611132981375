import { AllSelection } from '@progress/kendo-editor-common';
import { EditorUtils } from './index';
var setValue = function (view, value) {
    var transaction = value.tr ||
        (view.state.tr
            .setSelection(new AllSelection(view.state.doc))
            .replaceSelectionWith(value.doc || EditorUtils.createDocument(view.state.schema, value.html || ''))
            .setMeta('commandName', 'setHTML'));
    view.updateState(view.state.apply(transaction));
};
/**
 * @hidden
 */
export var updateEditorValue = function (view, value, prevValue, trOnChange, htmlOnChange) {
    if (typeof value === 'string') {
        if (trOnChange && value === htmlOnChange) {
            setValue(view, { tr: trOnChange });
        }
        else if (value !== prevValue) {
            setValue(view, { html: value });
        }
    }
    else {
        if (trOnChange && value.eq(trOnChange.doc)) {
            setValue(view, { tr: trOnChange });
        }
        else if (!view.state.doc.eq(value)) {
            setValue(view, { doc: value });
        }
    }
};
