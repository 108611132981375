var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { getBlockFormats, formatBlockElements } from '@progress/kendo-editor-common';
import { userSelectNone, itemRender } from './utils';
import { registerForLocalization, provideLocalizationService } from '@progress/kendo-react-intl';
import { messages } from './../messages';
/**
 * @hidden
 */
export var FormatBlockToolNS;
(function (FormatBlockToolNS) {
    /**
     * @hidden
     */
    FormatBlockToolNS.createFormatBlockDropDownList = function (settings) {
        var Tool = /** @class */ (function (_super) {
            __extends(FormatBlockDropDownList, _super);
            function FormatBlockDropDownList() {
                var _this = _super !== null && _super.apply(this, arguments) || this;
                _this.onChange = function (event) {
                    var item = event.target.value;
                    var view = _this.props.view;
                    if (view && formatBlockElements(item.value, settings.commandName)(view.state, view.dispatch)) {
                        var syntheticEvent = event.syntheticEvent;
                        if (syntheticEvent && syntheticEvent.type === 'click') {
                            view.focus();
                        }
                    }
                };
                return _this;
            }
            FormatBlockDropDownList.prototype.render = function () {
                var _a = this.props, view = _a.view, render = _a.render, data = _a.data, defaultItemProps = _a.defaultItem, ddlProps = __rest(_a, ["view", "render", "data", "defaultItem"]);
                var items = data || settings.items;
                var defaultItem = defaultItemProps || settings.defaultItem;
                var state = view && view.state;
                var formats = state ? getBlockFormats(state) : [];
                var localization = provideLocalizationService(this);
                if (defaultItem && defaultItem.localizationKey) {
                    defaultItem = __assign({}, defaultItem);
                    defaultItem.text = defaultItem.localizationKey ? localization.toLanguageString(defaultItem.localizationKey, messages[defaultItem.localizationKey]) : defaultItem.text;
                }
                var value = new Set(formats).size === 1 ? formats[0] : null;
                var dropdown = (React.createElement(DropDownList, __assign({ value: value ? items.find(function (i) { return i.value === value; }) : value, onChange: this.onChange, itemRender: itemRender, data: items, defaultItem: defaultItem, textField: "text", dataItemKey: "value", style: userSelectNone, leftRightKeysNavigation: false, title: defaultItem.text }, ddlProps)));
                return render ? render.call(undefined, dropdown, { view: view }) : dropdown;
            };
            return FormatBlockDropDownList;
        }(React.Component));
        registerForLocalization(Tool);
        return Tool;
    };
})(FormatBlockToolNS || (FormatBlockToolNS = {}));
