"use strict";

import React from "react";
import * as CommonSecurity from "../../../../server/common/generic/common_security";

export const INVALID_FILE_EXTENSION_ERROR = (
  <div>
    <p>Only the following extensions can be uploaded:</p>
    <ul>
      {Object.keys(CommonSecurity.VALID_FILE_EXTENSIONS).map(fileCategory => {
        return <li key={fileCategory} className="no-list-type-style"><i>{fileCategory}</i>: {CommonSecurity.VALID_FILE_EXTENSIONS[fileCategory].join(", ")}
        </li>;
      })}
    </ul>
    <p>If you believe this is an error, please click <a href="https://support.qbdvision.com" rel="noopener noreferrer" target="_blank" id="footerFeedback">here</a> to let us know.</p>
  </div>
);
