"use strict";

import React, { memo, useEffect, useRef } from "react";
import { DropdownButton } from "@qbdvision-inc/component-library";
import * as styles from "./drop_down.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * It is hard to customize the DropdownButton by giving a class to the component as it doesn't have this
 * attribute, this component is mainly wrapping so that you can customize its style.
 * @param className
 * @param label
 * @param icon
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const CustomDropdownButton = memo(({className, label, icon, ...props}) => {

  const ref = useRef();

  useEffect(() => {
    const [container] = ref.current.children;
    if (className) {
      container.classList.add(className);
    } else {
      container.classList.add(styles["drop-down-element"]);
    }
  }, []);

  return (
    <div ref={ref}>
      <DropdownButton {...props}>
        {label && <span>{label}</span>}
        {icon && <FontAwesomeIcon icon={icon} size="2xs" />}
      </DropdownButton>
    </div>
  );
});
