var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
import { classNames, focusFirstFocusableChild, guid, useDir, dispatchEvent, Keys } from '@progress/kendo-react-common';
import { FILL_CLASSES, ITEM_FLOW_CLASSES, POSITION_MODE_CLASSES, THEME_COLOR_CLASSES } from './models/utils';
import { BottomNavigationItem } from './BottomNavigationItem';
/**
 * Represents the [KendoReact BottomNavigation component]({% slug overview_bottomnavigation %}).
 * Used to switch between primary destinations in an application.
 *
 * @example
 * ```jsx
 * const items = [
 *    { text: 'Photos', icon: 'photo', selected: true },
 *    { text: 'Albums', icon: 'folder' },
 *    { text: 'Search', icon: 'search' },
 *    { text: 'More', icon: 'more-horizontal' },
 * ];
 *
 * const App = () => {
 *   return (
 *       <BottomNavigation items={items} />
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var BottomNavigation = React.forwardRef(function (props, ref) {
    validatePackage(packageMetadata);
    var elementRef = React.useRef(null);
    var focusElement = React.useCallback(function () {
        if (elementRef.current) {
            focusFirstFocusableChild(elementRef.current);
        }
    }, []);
    var getImperativeHandle = React.useCallback(function () { return ({
        element: elementRef.current,
        focus: focusElement
    }); }, [focusElement]);
    React.useImperativeHandle(ref, getImperativeHandle);
    var _a = props.themeColor, themeColor = _a === void 0 ? defaultProps.themeColor : _a, _b = props.fill, fill = _b === void 0 ? defaultProps.fill : _b, _c = props.positionMode, positionMode = _c === void 0 ? defaultProps.positionMode : _c, _d = props.itemFlow, itemFlow = _d === void 0 ? defaultProps.itemFlow : _d, _e = props.border, border = _e === void 0 ? defaultProps.border : _e, className = props.className, items = props.items, item = props.item, itemRender = props.itemRender, disabled = props.disabled, style = props.style, id = props.id, onSelect = props.onSelect, onKeyDown = props.onKeyDown;
    var navId = React.useMemo(function () { return guid(); }, []);
    var dir = useDir(elementRef, props.dir);
    var navClasses = React.useMemo(function () { return classNames('k-bottom-nav', POSITION_MODE_CLASSES[positionMode], THEME_COLOR_CLASSES[themeColor], FILL_CLASSES[fill], ITEM_FLOW_CLASSES[itemFlow], {
        'k-bottom-nav-border': border,
        'k-disabled': disabled
    }, className); }, [positionMode, themeColor, fill, itemFlow, border, disabled, className]);
    var dispatchSelectEvent = React.useCallback(function (dispatchedEvent, index) {
        if (!items) {
            return;
        }
        if (!items[index].disabled && onSelect) {
            dispatchEvent(onSelect, dispatchedEvent, getImperativeHandle(), {
                itemTarget: items[index],
                itemIndex: index
            });
        }
    }, [items, onSelect]);
    var handleSelect = React.useCallback(function (event, clickedItemIndex) {
        dispatchSelectEvent(event, clickedItemIndex);
    }, [dispatchSelectEvent]);
    var handleKeyDown = React.useCallback(function (event, clickedItemIndex) {
        switch (event.keyCode) {
            case Keys.enter:
            case Keys.space:
                dispatchSelectEvent(event, clickedItemIndex);
                event.preventDefault();
                break;
            default:
                break;
        }
        dispatchEvent(onKeyDown, event, getImperativeHandle(), undefined);
    }, [dispatchSelectEvent, onKeyDown]);
    return (React.createElement("nav", { ref: elementRef, className: navClasses, style: style, id: id || navId, dir: dir }, items && (items.map(function (element, index) {
        return (React.createElement(BottomNavigationItem, __assign({}, element, { key: index, index: index, id: "".concat(id || navId, "-").concat(index), disabled: disabled || element.disabled, selected: element.selected, dataItem: element, item: item, render: itemRender, onSelect: handleSelect, onKeyDown: handleKeyDown })));
    }))));
});
var defaultProps = {
    themeColor: 'primary',
    fill: 'flat',
    itemFlow: 'vertical',
    positionMode: 'fixed',
    border: true
};
BottomNavigation.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    id: PropTypes.string,
    dir: PropTypes.string,
    themeColor: PropTypes.oneOf([
        'primary', 'secondary', 'tertiary',
        'info', 'success', 'warning',
        'error', 'dark', 'light', 'inverse'
    ]),
    fill: PropTypes.oneOf(['solid', 'flat']),
    itemFlow: PropTypes.oneOf(['vertical', 'horizontal']),
    border: PropTypes.bool,
    disabled: PropTypes.bool,
    selected: PropTypes.number,
    onSelect: PropTypes.func
};
BottomNavigation.defaultProps = defaultProps;
BottomNavigation.displayName = 'KendoReactBottomNavigation';
