/* stylelint-disable-next-line import-notation */
@import "../../../../css/brand_colors";

.record-header-back-button {
  width: 1rem;
  height: 1rem;
}

.record-header-title {
  font-size: 1.125rem;
  color: $brand-primary-color;
  font-weight: 700;
  max-width: 70%;
  margin-right: 0.3rem;
}

.record-header-subtitle {
  color: $brand-dark-grey;
}
