"use strict";

import React from "react";
import { WIDGET_FIELDS } from "../constants/rmp_uncertainty_attribute_constants";
import RMPRiskScoreAttribute, { RMP_RISK_SCORE_ATTRIBUTE_TYPE } from "./rmp_risk_score_attribute";
import { RISK_ATTRIBUTES_TABS } from "../constants/rmp_constants";

/**
 * This is class is responsible for handling Risk Management Plan Uncertainty records.
 */
export default class RMPUncertaintiesAttribute extends RMPRiskScoreAttribute {
  constructor(props) {
    super(props, WIDGET_FIELDS);

    this.setPotentialColumnVisibility(props);

    this.errorMessages.editedRowPending = "At least one uncertainty row is in edit state. Please save or cancel all uncertainty rows first.";
    this.type = RMP_RISK_SCORE_ATTRIBUTE_TYPE.UNCERTAINTY;
    this.tabContainerType = RISK_ATTRIBUTES_TABS.UNCERTAINTY;
  }

  reloadDataTable(rowData) {
    if (this.refTable) {
      const table = $(this.refTable.current).DataTable();
      table.column(3).visible(this.isPotentialColumnVisible());
    }

    super.reloadDataTable(rowData);
  }

  setPotentialColumnVisibility(props = this.props) {
    WIDGET_FIELDS.find(widget => widget.fieldName === "potential").visible = this.isPotentialColumnVisible(props);
  }

  isPotentialColumnVisible(props = this.props) {
    return props.modelName !== "TT";
  }

  validate(formValidationMode) {
    if (this.props.usePotential && !this.props.potentialLabel) {
      this.setError(`Potential Label needs to be specified.`);
      this.forceUpdateSafely();
      return false;
    }

    return super.validate(formValidationMode);
  }

  isPotentialDisabled(rowData) {
    return this.isNotAssessedRow(rowData) || !this.props.usePotential;
  }
}
