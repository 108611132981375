"use strict";

import * as UIUtils from "../ui_utils";
import React, { Fragment } from "react";
import * as ReactDOM from "react-dom";
import TextAttribute from "../editor/attributes/text_attribute";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import BaseQuickEditor from "../editor/base_quick_editor";
import DateAttribute from "../editor/attributes/date_attribute";
import BatchAttributesTable from "./batch_attributes_table";
import BatchDataImportButton from "./widgets/batch_data_import_button";
import ComboBoxAttribute from "../editor/attributes/combo_box_attribute";
import BatchLinksTable from "./batch_links_table";
import * as DocumentTransferHelper from "../helpers/document_transfer_helper";
import moment from "moment";
import ImplementationNeededError from "../utils/implementation_needed_error";
import Section from "../editor/widgets/section";
import TextAreaAttribute from "../editor/attributes/text_area_attribute";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";
import * as ProcessCache from "../processExplorer/process/process_cache";
import { orderAndIndexUnits } from "../processExplorer/indexers/uo_indexer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompressAlt, faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { EDITOR_TYPES } from "../editor/editor_constants";
import { getImportDependencies } from "../import/helpers/import_security";
import GenerateImportTemplateLink from "./widgets/generate_import_template_link";
import GenerateTemplatePopup from "../import/widgets/general/generate_template_popup";
import { DATA_CATEGORIES, IMPORT_TYPE_KEY, IMPORT_TYPES } from "../import/constants/import_constants";
import TabNavBar from "../widgets/bars/tab_nav_bar";
import {
  BATCH_DATA_TYPE_ENUM,
  BATCH_DATES_ENUM,
  BATCH_PURPOSE_OPTIONS,
  BATCH_RECORD_TYPE_ENUM,
} from "./batch_constants";
import SupportingDocumentsAttribute from "./widgets/supporting_documents_attribute";
import TypeaheadAttribute from "../editor/attributes/typeahead_attribute";
import { BATCH_TYPE_OPTIONS } from "../../server/common/editables/common_batches";
import CommonEditablesPageTitleBar from "../widgets/pageTitleBar/common_editables_page_title_bar";

const BATCH_DATA_TYPE_LIST_TABS_ENUM = {
  RELEASE_TAB: {
    key: "releaseData",
    title: "Release",
    disabled: false,
  },
  MANUFACTURING_TAB: {
    key: "manufacturingData",
    title: "Manufacturing",
    disabled: false,
  },
};

// i18next-extract-mark-ns-start batches
/**
 * This shows the batch controlled record
 */
class Batch extends BaseQuickEditor {
  constructor(props) {
    super(props, "Batch", "Batch", "Batch");

    this.projectId = UIUtils.parseInt(UIUtils.getParameterByName("projectId"));
    this.setStateSafely({
      isLoadingProcesses: true,
      isLoadingUOs: true,
      processes: [],
      defaultProcessId: null,
      batchAttributesTableExpandState: true,
      releaseAttributesTableExpandState: true,
      manufacturingAttributesTableExpandState: true,
      searchTerm: "",
      isInProjectContext: this.projectId > 0,
      selectedTab: BATCH_DATA_TYPE_LIST_TABS_ENUM.RELEASE_TAB,
    });
    this._processesLoaded = false;

    /* The process typeahead needs to load after the data has been loaded from the server. This is because there is
       certain logic in the handleProcessesLoaded handler which will fail if the batch data is not in the state.
       However, when adding a batch, there is no data to load, the processes typeahead can load right away. The same
       applies when the batch record is loaded through the quick panel also.
     */
    if (this.isAdd() || (this.getEditorType() === EDITOR_TYPES.QUICK_PANEL)) {
      this.loadSupportingData();
    }

    this.coaAttributesTable = React.createRef();
    this.releaseAttributesTable = React.createRef();
    this.manufacturingAttributesTable = React.createRef();
  }

  componentDidMount() {
    document.title = "QbDVision Batch";

    super.componentDidMount();
  }

  shouldShowNav() {
    return this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ? !this.isLibraryCoA() : false;
  }

  renderPageTitleBar() {
    if (this.isLibraryCoA()) {
      return this.getEditorType() === EDITOR_TYPES.FULL_SCREEN ?
        <CommonEditablesPageTitleBar recordName={this.state.name}
                                     recordId={this.state.id}
                                     name="Batch"
                                     typeCode={UIUtils.getTypeCodeForModelName("batch")}
                                     currentState={this.getCurrentlyViewedState()}
        /> : false;
    } else {
      return super.renderPageTitleBar();
    }
  }

  getTypesToCache() {
    return ["Project", "Process", "UnitOperation", ...super.getTypesToCache()];
  }

  loadSupportingData() {
    const projectId = this.getProjectId();
    const processId = this.getProcessId();

    if (projectId) {
      let processCache = new TypeaheadObjectCache("Process", projectId);
      processCache.invalidateCacheOptionsAsync().then(() => {
        processCache.loadOptions(this.handleProcessesLoaded);
      });
    } else {
      this.setStateSafely({
        isLoadingProcesses: false,
        isLoadingUOs: false
      });
    }

    if (this.importDataWidgetsVisible()) {
      getImportDependencies(projectId, processId).then(result => this.handleDependenciesLoaded(result));
    }
  }

  onDataReceivedFromServer() {
    super.onDataReceivedFromServer();
    this.loadSupportingData(true);

    this.setStateSafely({
      isInProjectContext: this.state.ProjectId > 0
    });
  }

  handleDependenciesLoaded(result) {
    if (result) {
      this.setStateSafely({importDependenciesStatus: result});
    }
  }

  getTabName() {
    return this.isLibraryCoA() ? "Library Data" : "Data";
  }

  /**
   * Downloads an import file when the corresponding link is clicked
   * @param fileData An object with the file related information
   * @param e
   */
  handleFileDownload(fileData, e) {
    UIUtils.ignoreHandler(e);

    // noinspection JSIgnoredPromiseFromCall
    DocumentTransferHelper.handleFileDownload(fileData);
    return false;
  }

  /**
   * Receives the processes loaded by the typeahead cache, prepares them and
   * save the proper values in the state
   * @param loadedProcesses {Process[]}
   */
  handleProcessesLoaded(loadedProcesses = []) {

    if (this.isAdd()) {
      loadedProcesses = loadedProcesses.filter(process => !process.deletedAt);
    }
    const projectId = this.getProjectId();
    const processes = this.prepareProcessesArray(loadedProcesses);
    let defaultProcessId = ProcessCache.getProcessIdUsedRecently(projectId, loadedProcesses)
      || (processes && processes[0] && processes[0].key);

    if (!processes.find(process => process.key === defaultProcessId)) {
      defaultProcessId = processes[0].key;
    }

    this.setStateSafely({
      processes,
      defaultProcessId,
      ProcessId: this.isAdd() ? defaultProcessId : this.getProcessId(),
      isLoadingProcesses: false
    }, () => {
      const typeaheadObjectCache = new TypeaheadObjectCache("UnitOperation", projectId, this.getProcessId());
      typeaheadObjectCache.invalidateCacheOptionsAsync().then(() => {
        typeaheadObjectCache.loadOptions(this.handleUOsLoaded);
      });
    });
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    /* The reason we are explicitly calling ReactDOM.render and ReactDOM.unmountComponentAtNode below is because
       we need to force the Generate Template popup to render next to the root page bodydiv. This way, it would belong
       to the same stacking context as the popup modal-backdrop div and we would be able to make both the popup and the
       modal-backdrop show over the quick panel, when the modal is opened from the quick panel itself. If we rendered the
       modal dialog within some div of this page, then, when the user opens it from the quick panel, it would belong
       to within the stacking context of the quick panel. Since the quick panel already has a z-index which is lower than
       the modal-backdrop z-index, both the quick panel and the modal would show below the modal-backdrop, which in turn
       would make the modal dialog useless. We could try to work around this by raising the quick panel z-index to be
       greater than the modal-backdrop index, but then this would have the ugly effect of the quick panel showing up
       on top of the modal-backdrop. So, in this case, we need somehow to force the modal-backdrop be on top of the
       quick panel but below the modal dialog. The only way to achieve is is by rendering the modal dialog to a stacking
       context that has a higher z-index than the backdrop and does not need to show below the backdrop.
       For more information about how the z-index works in respect to the stacking context, please read below:
       Stacking Context: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context
       z-index: https://developer.mozilla.org/en-US/docs/Web/CSS/z-index
     */

    const projectId = this.getProjectId();
    const {
      importDependenciesStatus,
      showGenerateImportTemplatePopup,
    } = this.state;
    const processId = this.getProcessId();
    const modalContainerSelector = "html body #modalContainer";

    let modalContainer = $(modalContainerSelector);
    if (!modalContainer || !modalContainer[0]) {
      let bodyDiv = $("html body");
      bodyDiv.append(`<div id="modalContainer"></div>`);
      modalContainer = $(modalContainerSelector);
    }

    if (showGenerateImportTemplatePopup) {
      ReactDOM.render(
        <GenerateTemplatePopup modalRef={importTemplatePopup => this.importTemplatePopup = importTemplatePopup}
                               projectId={projectId}
                               processId={processId}
                               selectedBatch={this.state}
                               importDependenciesStatus={importDependenciesStatus}
                               importTypeConfigs={this.getImportTypeConfigs()}
                               importConfig={this.getDefaultImportConfig()}
                               enableImportConfigSelection={true}
                               parent={this}
                               onHidePopup={this.handleHideImportTemplatePopup}
        />, modalContainer[0]);
      this.rendered = true;
    } else if (this.rendered) {
      ReactDOM.unmountComponentAtNode(modalContainer[0]);
      this.rendered = false;
    }
  }

  /**
   * Sorts and maps the array of processes received from the cache load method so it
   * can be used in this page.
   * @param processes {Process[]} The processes to be formatted
   * @return {({key: number, value: string})[]}
   */
  prepareProcessesArray(processes) {
    return processes
      .sort(UIUtils.sortBy("id"))
      .map(process => {
        return {
          key: process.id,
          value: process.name,
        };
      });
  }

  handleUOsLoaded(unitOperations) {
    const orderedUOList = orderAndIndexUnits(unitOperations).map(uo => uo.label);
    this.setStateSafely({orderedUOList, isLoadingUOs: false});
  }

  /**
   * This gets the date given a date type.
   * @param dateType This can be any of BATCH_DATES_ENUM
   * @returns {moment.Moment|null}
   */
  getDate(dateType) {
    switch (dateType) {
      case BATCH_DATES_ENUM.START_DATE:
        return this.state.startDate ? moment(this.state.startDate) : null;
      case BATCH_DATES_ENUM.MANUFACTURE_DATE:
        return this.state.manufactureDate ? moment(this.state.manufactureDate) : null;
      case BATCH_DATES_ENUM.RELEASE_DATE:
        return this.state.releaseDate ? moment(this.state.releaseDate) : null;
      case BATCH_DATES_ENUM.EXPIRATION_DATE:
        return this.state.expirationDate ? moment(this.state.expirationDate) : null;
      default:
        throw new ImplementationNeededError();
    }
  }

  /**
   * This gets the minimum allowed date given a date type.
   * @param dateType This can be any of BATCH_DATES_ENUM
   * @returns {moment.Moment|null|*}
   */
  getMinDate(dateType) {
    switch (dateType) {
      case BATCH_DATES_ENUM.START_DATE:
        return null;
      case BATCH_DATES_ENUM.MANUFACTURE_DATE:
        return this.getDate(BATCH_DATES_ENUM.START_DATE);
      case BATCH_DATES_ENUM.RELEASE_DATE:
        return this.getDate(BATCH_DATES_ENUM.MANUFACTURE_DATE) || this.getMinDate(BATCH_DATES_ENUM.MANUFACTURE_DATE);
      case BATCH_DATES_ENUM.EXPIRATION_DATE:
        return this.getDate(BATCH_DATES_ENUM.RELEASE_DATE) || this.getMinDate(BATCH_DATES_ENUM.RELEASE_DATE);
    }
  }

  /**
   * This gets the maximum allowed date given a date type.
   * @param dateType This can be any of BATCH_DATES_ENUM
   * @returns {null|moment.Moment|*}
   */
  getMaxDate(dateType) {
    switch (dateType) {
      case BATCH_DATES_ENUM.START_DATE:
        return this.getDate(BATCH_DATES_ENUM.MANUFACTURE_DATE) || this.getMaxDate(BATCH_DATES_ENUM.MANUFACTURE_DATE);
      case BATCH_DATES_ENUM.MANUFACTURE_DATE:
        return this.getDate(BATCH_DATES_ENUM.RELEASE_DATE) || this.getMaxDate(BATCH_DATES_ENUM.RELEASE_DATE);
      case BATCH_DATES_ENUM.RELEASE_DATE:
        return this.getDate(BATCH_DATES_ENUM.EXPIRATION_DATE);
      case BATCH_DATES_ENUM.EXPIRATION_DATE:
        return null;
    }
  }

  /**
   * This verifies a date given its type.
   * @param dateType This can be any of BATCH_DATES_ENUM.
   * @param dateStr The date to verify.
   * @param isRequired This is set to true when the specific date is required for either save or propose.
   * @returns {string}
   */
  verifyBatchDate(dateType, dateStr, isRequired) {
    const date = moment(dateStr, UIUtils.DATE_FORMAT_FOR_STORAGE);
    let minDate = this.getMinDate(dateType);
    let maxDate = this.getMaxDate(dateType);
    if (!date.isValid() && isRequired) {
      return `${dateType} is required.`;
    } else if (minDate && maxDate && date < minDate && date > maxDate) {
      return `The ${dateType} should be after ${minDate.format(UIUtils.DATE_FORMAT_FOR_DISPLAY)} or before ${maxDate.format(UIUtils.DATE_FORMAT_FOR_DISPLAY)}.`;
    } else if (minDate && date < minDate) {
      return `The ${dateType} should be after ${minDate.format(UIUtils.DATE_FORMAT_FOR_DISPLAY)}.`;
    } else if (maxDate && date > maxDate) {
      return `The ${dateType} should be before ${maxDate.format(UIUtils.DATE_FORMAT_FOR_DISPLAY)}.`;
    }

    return "";
  }

  /**
   * Triggers a search within the batch attributes table.
   * @param e
   */
  handleSearchInAttributes(e) {
    clearTimeout(this.typingInSearchBox);
    const searchTerm = e.target.value;
    this.typingInSearchBox = setTimeout(() => {
      this.setStateSafely({
        searchTerm,
      });
    }, 200);
  }

  /**
   * Expands or collapses all groups in the batch records table
   */
  handleExpandCollapseBatchAttributes() {
    const {
      batchAttributesTableExpandState,
      releaseAttributesTableExpandState,
      manufacturingAttributesTableExpandState,
      selectedTab,
    } = this.state;

    this.setStateSafely({
      batchAttributesTableExpandState: !batchAttributesTableExpandState,
      releaseAttributesTableExpandState: selectedTab.title === BATCH_DATA_TYPE_LIST_TABS_ENUM.RELEASE_TAB.title
        ? !releaseAttributesTableExpandState
        : releaseAttributesTableExpandState,
      manufacturingAttributesTableExpandState: selectedTab.title === BATCH_DATA_TYPE_LIST_TABS_ENUM.MANUFACTURING_TAB.title
        ? !manufacturingAttributesTableExpandState
        : manufacturingAttributesTableExpandState,
    }, () => {
      this.toggleExpandCollapseAllRows();
    });
  }

  handleGenerateImportTemplateLinkClick() {
    this.setStateSafely({showGenerateImportTemplatePopup: true});
  }

  handleHideImportTemplatePopup() {
    this.setStateSafely({showGenerateImportTemplatePopup: false});
  }

  getImportTypeConfigs() {
    const batchRecordType = this.state.type;
    const batchDataType = this.getBatchDataType();

    if (batchRecordType === BATCH_RECORD_TYPE_ENUM.BATCH) {
      return IMPORT_TYPES.PROCESS_DATA.filter(config =>
        (batchDataType === BATCH_DATA_TYPE_ENUM.RELEASE_DATA
          && config.dataCategory === DATA_CATEGORIES.RELEASE)
        ||
        (batchDataType === BATCH_DATA_TYPE_ENUM.MANUFACTURING_DATA
          && config.dataCategory === DATA_CATEGORIES.MANUFACTURING));
    }

    return [];
  }

  getDefaultImportConfig() {
    const batchDataType = this.getBatchDataType();
    const availableImportConfigs = this.getImportTypeConfigs();

    return availableImportConfigs.find(config => (batchDataType === BATCH_DATA_TYPE_ENUM.RELEASE_DATA
        && config.key === IMPORT_TYPE_KEY.RELEASE_DATA)
      || (batchDataType === BATCH_DATA_TYPE_ENUM.MANUFACTURING_DATA
        && config.key === IMPORT_TYPE_KEY.MANUFACTURING_DATA),
    );
  }

  importDataWidgetsVisible() {
    return this.isView()
      && !this.isDiffingVersions()
      && !this.isCoA();
  }

  getBatchAttributesForTable() {
    const batchRecordType = this.state.type;
    const batchAttributes = this.state.attributes || [];
    const batchDataType = this.getBatchDataType();

    if (this.isCoA()) {
      return batchAttributes;
    } else if (batchRecordType === BATCH_RECORD_TYPE_ENUM.BATCH) {
      return this.filterAttributesForDataType(batchAttributes, batchDataType);
    }

    return [];
  }

  getPreviousBatchVersionAttributesForTable() {
    const {olderVersion} = this.state;
    const batchRecordType = this.state.type;
    const batchDataType = this.getBatchDataType();

    if (this.isDiffingVersions() && olderVersion) {
      const oldVersionAttributes = olderVersion.attributes;
      if (this.isCoA()) {
        return oldVersionAttributes;
      } else if (batchRecordType === BATCH_RECORD_TYPE_ENUM.BATCH) {
        return this.filterAttributesForDataType(oldVersionAttributes, batchDataType);
      }
    }

    return [];
  }

  filterAttributesForDataType(attributes = [], dataType) {
    return attributes.filter(attribute =>
      (dataType === BATCH_DATA_TYPE_ENUM.RELEASE_DATA
        && (attribute.attributeKey.startsWith("FQA") || attribute.attributeKey.startsWith("FPA")))
      ||
      (dataType === BATCH_DATA_TYPE_ENUM.MANUFACTURING_DATA
        && !attribute.attributeKey.startsWith("FQA")
        && !attribute.attributeKey.startsWith("FPA")));
  }

  renderBatchAttributesTables() {
    const projectId = this.getProjectId();
    const processId = this.getProcessId();
    const {selectedTab} = this.state;
    const commonBatchListProps = {
      projectId,
      processId,
      orderedUOList: this.state.orderedUOList,
      searchTerm: this.state.searchTerm,
      isDiffingVersions: this.isDiffingVersions(),
      diffedVersion: this.state.currentDiffingVersion && this.state.currentDiffingVersion.versionId,
      linkToDashboard: true,
      isLoading: this.state.isLoadingUOs,
      ...this.props,
    };

    return (
      <BatchAttributesTable id="batchAttributesTable"
                            parent={this}
                            ref={ref => {
                              switch (selectedTab.title) {
                                case BATCH_RECORD_TYPE_ENUM.COA.title:
                                  this.coaAttributesTable = ref;
                                  break;
                                case BATCH_DATA_TYPE_LIST_TABS_ENUM.RELEASE_TAB.title:
                                  this.releaseAttributesTable = ref;
                                  break;
                                case BATCH_DATA_TYPE_LIST_TABS_ENUM.MANUFACTURING_TAB.title:
                                  this.manufacturingAttributesTable = ref;
                                  break;
                              }
                            }}
                            type={this.state.type}
                            records={this.getBatchAttributesForTable()}
                            previousVersionRecords={this.getPreviousBatchVersionAttributesForTable()}
                            {...commonBatchListProps}
      />);
  }

  handleTabChanged(tab) {
    this.setStateSafely({
      selectedTab: tab,
    }, () => {
      this.toggleExpandCollapseAllRows();
    });
  }

  getAllAttributesTables() {
    return [{
      table: this.coaAttributesTable,
      status: this.state.batchAttributesTableExpandState,
    }, {
      table: this.releaseAttributesTable,
      status: this.state.releaseAttributesTableExpandState,
    }, {
      table: this.manufacturingAttributesTable,
      status: this.state.manufacturingAttributesTableExpandState,
    }];
  }

  toggleExpandCollapseAllRows() {
    for (let attributeTable of this.getAllAttributesTables()) {
      attributeTable.table
      && attributeTable.table.expandCollapseAllRows
      && attributeTable.table.expandCollapseAllRows(attributeTable.status);
    }
  }

  getBatchDataType() {
    const {selectedTab} = this.state;
    return (selectedTab.title === BATCH_DATA_TYPE_LIST_TABS_ENUM.RELEASE_TAB.title)
      ? BATCH_DATA_TYPE_ENUM.RELEASE_DATA
      : BATCH_DATA_TYPE_ENUM.MANUFACTURING_DATA;
  }

  getTableRowsExpandedStatus() {
    const {
      batchAttributesTableExpandState,
      releaseAttributesTableExpandState,
      manufacturingAttributesTableExpandState
    } = this.state;
    const batchDataType = this.getBatchDataType();

    return this.isCoA()
      ? batchAttributesTableExpandState
      : batchDataType === BATCH_DATA_TYPE_ENUM.RELEASE_DATA
        ? releaseAttributesTableExpandState
        : manufacturingAttributesTableExpandState;
  }

  isCoA() {
    return this.state.type === BATCH_RECORD_TYPE_ENUM.COA || this.isLibraryCoA();
  }

  isLibraryCoA() {
    return this.state?.type === BATCH_RECORD_TYPE_ENUM.LIBRARY_COA;
  }

  getBatchImportFiles() {
    let imports = this.state.imports || [];
    if (this.isDiffingVersions()) {
      imports = imports.concat(this.state.olderVersion && this.state.olderVersion.imports || []);
    }

    return (imports || []).map(importRecord => {
      const importFiles = JSON.parse(importRecord.links || "[]");
      return {
        id: importRecord.id,
        label: importFiles[0] && importFiles[0].fileName,
      };
    });
  }

  renderAttributes() {
    const {t} = this.props;
    const projectId = this.getProjectId();
    const {
      processes,
      defaultProcessId,
      importDependenciesStatus,
      type,
      selectedTab,
      isLoadingProcesses,
    } = this.state;
    const processId = this.getProcessId();
    const tableRowsExpandStatus = this.getTableRowsExpandedStatus();
    const batchAttributes = this.getBatchAttributesForTable();
    const importRecords = this.state.imports || [];

    if (!type) {
      this.setStateSafely({
        type: "Batch",
      });
    }

    return (
      <Fragment>
        <Section parent={this}
                 header={"Batch"}
                 collapsible={false}
        >
          <div className="row">
            <TextAttribute name="customID"
                           displayName={t("ID")}
                           className="col-sm-3"
                           tooltipText={(<div>
                             {t("Unique identifier for the manufactured batch or lot.")}
                           </div>)}
                           parent={this}
            />
            {type === BATCH_RECORD_TYPE_ENUM.BATCH && (
              <TypeaheadAttribute name="batchType"
                                  className="col-sm-3"
                                  displayName="Type"
                                  multiple="true"
                                  options={BATCH_TYPE_OPTIONS}
                                  parent={this}
                                  isLoading={this.state.isLoading}
                                  parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                  parentId={this.state.id}
              />)}
            {!this.isLibraryCoA() && (
              <ComboBoxAttribute name="process"
                                 attributeName="ProcessId"
                                 displayName={t("Process")}
                                 className="col-sm-3"
                                 options={processes}
                                 default={defaultProcessId}
                                 disabled={batchAttributes.length > 0}
                                 isLinkToDBObject={{typeCode: "PR"}}
                                 instructions={batchAttributes.length > 0 ? `The process this record is associated with cannot change after data is imported into it. If this value is incorrect, please archive this record and create a new one.` : ""}
                                 parent={this}
                                 isLoading={isLoadingProcesses || this.state.isLoading}
                                 parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                 parentId={this.state.id}
              />)
            }
            <TypeaheadAttribute name="supplier"
                                className="col-sm-3"
                                typeaheadType="Supplier"
                                parent={this}
                                isLoading={this.state.isLoading}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
            />
          </div>
          <div className="row">
            <DateAttribute name="startDate"
                           className="col-sm-3"
                           parent={this}
                           validateDate={this.verifyBatchDate.bind(this, BATCH_DATES_ENUM.START_DATE)}
                           maxDate={this.getMaxDate(BATCH_DATES_ENUM.START_DATE)}
            />
            <DateAttribute name="manufactureDate"
                           className="col-sm-3"
                           parent={this}
                           validateDate={this.verifyBatchDate.bind(this, BATCH_DATES_ENUM.MANUFACTURE_DATE)}
                           minDate={this.getMinDate(BATCH_DATES_ENUM.MANUFACTURE_DATE)}
                           maxDate={this.getMaxDate(BATCH_DATES_ENUM.MANUFACTURE_DATE)}
            />
            <DateAttribute name="releaseDate"
                           className="col-sm-3"
                           parent={this}
                           validateDate={this.verifyBatchDate.bind(this, BATCH_DATES_ENUM.RELEASE_DATE)}
                           minDate={this.getMinDate(BATCH_DATES_ENUM.RELEASE_DATE)}
                           maxDate={this.getMaxDate(BATCH_DATES_ENUM.RELEASE_DATE)}
            />
            <DateAttribute name="expirationDate"
                           className="col-sm-3"
                           parent={this}
                           validateDate={this.verifyBatchDate.bind(this, BATCH_DATES_ENUM.EXPIRATION_DATE)}
                           minDate={this.getMinDate(BATCH_DATES_ENUM.EXPIRATION_DATE)}
            />
          </div>
          <div className="row">
            <TextAttribute name="site"
                           className="col-sm-3"
                           parent={this}
            />
            <TextAttribute name="scale"
                           className="col-sm-3"
                           tooltipText={(<div>
                             {t("Refers to the size of the manufactured batch or lot.")}
                           </div>)}
                           parent={this}
            />
            {type === "Batch" &&
              (<TypeaheadAttribute name="purpose"
                                   className="col-sm-3"
                                   displayName={t("Purpose")}
                                   multiple="true"
                                   options={BATCH_PURPOSE_OPTIONS}
                                   parent={this}
                                   isLoading={this.state.isLoading}
                                   parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                   parentId={this.state.id}
              />)}
          </div>
          <div className="row">
            <TextAreaAttribute name="description"
                               className="col-sm-12"
                               parent={this}
            />
          </div>
        </Section>
        <Section parent={this}
                 header={(
                   <Fragment>
                     {type === BATCH_RECORD_TYPE_ENUM.BATCH ? (
                       <TabNavBar selected={selectedTab}
                                  className="batches-tab"
                                  onTabChanged={this.handleTabChanged}
                                  tabs={BATCH_DATA_TYPE_LIST_TABS_ENUM}
                                  parent={this}
                                  parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                  parentId={this.state.id}
                       />
                     ) : ""}
                     <div className="batch-attributes-table-header">
                       <div className="batch-attributes-table-records-header">
                         <span>{batchAttributes.length + " " + (this.isLibraryCoA() ? "Specifications" : "Attributes")}</span>
                       </div>
                     </div>
                     <div className="batch-attributes-table-header">
                       <div className="batch-attributes-table-header-toolbar">
                         <input type="text"
                                id="batchAttributesTableSearch"
                                onChange={this.handleSearchInAttributes}
                                className="form-control"
                         />
                         <button id="batchAttributesTableExpandButton"
                                 type="button"
                                 className="btn btn-outline-secondary"
                                 aria-label="Expand/Collapse"
                                 onClick={this.handleExpandCollapseBatchAttributes}
                         >
                           <FontAwesomeIcon icon={tableRowsExpandStatus ? faCompressAlt : faExpandAlt} />
                         </button>
                       </div>
                       <div className="batch-attributes-table-header-toolbar">
                         {this.importDataWidgetsVisible() ? (
                           <Fragment>
                             <div className="generate-import-template-link-container">
                               <GenerateImportTemplateLink onGenerateImportTemplateLinkClick={this.handleGenerateImportTemplateLinkClick} />
                             </div>
                             <BatchDataImportButton parent={this}
                                                    projectId={projectId}
                                                    processId={processId}
                                                    batchId={this.state && this.state.id}
                                                    importDependenciesStatus={importDependenciesStatus}
                                                    isLoading={typeof importDependenciesStatus === "undefined"}
                                                    importTypeConfigs={this.getImportTypeConfigs()}
                             />
                           </Fragment>
                         ) : ""}
                       </div>
                     </div>
                   </Fragment>
                 )}
                 collapsible={false}
        >
          <div className="row">
            {this.renderBatchAttributesTables()}
          </div>
        </Section>
        <Section parent={this}
                 header={"Import Details"}
                 collapsible={false}
        >
          <div className="row">
            <BatchLinksTable parent={this}
                             projectId={projectId}
                             capitalizedBaseTypeName={this.capitalizedBaseTypeName}
                             records={importRecords}
                             oldBatchVersion={this.state.olderVersion}
                             isDiffingVersions={this.isDiffingVersions()}
                             onFileDownload={this.handleFileDownload}
                             diffedVersion={this.state.currentDiffingVersion && this.state.currentDiffingVersion.versionId}
                             {...this.props}
            />
          </div>
          <div className="row">
            <SupportingDocumentsAttribute name="links"
                                          displayName="Supporting Documents"
                                          hideAppliesToColumn={false}
                                          getBatchImportFiles={this.getBatchImportFiles}
                                          className="col-12"
                                          parent={this}
            />
          </div>
        </Section>
      </Fragment>
    );
  }
}

export default I18NWrapper.wrap(Batch, ["batches"]);
// i18next-extract-mark-ns-stop batches
