"use strict";

import React from "react";
import * as UIUtils from "../../../ui_utils";
import { getURLByKey } from "../../../helpers/url_helper";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SpecificationCell = ({ dataItem, field }) => {
  if (field === "name" && dataItem.model) {
    const dataKey = `${UIUtils.getTypeCodeForModelName(dataItem.model)}-${dataItem.id}`;
    return (
      <td>
        <p className="m-0">
          <a className="record-name link" href={getURLByKey(dataKey, "View", false)}
             target="_blank"
             rel="noreferrer noopener"
          >
            {UIUtils.getSecuredURL(dataItem[field])}
          </a>
          <span className="key">{dataKey}</span>
        </p>
     </td>
    );
  }

  if (field === "analyticsUrl") {
    return (
      <td>
        <p className="m-0">
          <a className="record-name link" href={UIUtils.getSecuredURL(dataItem[field])}
             target="_blank"
             rel="noreferrer noopener"
          >
            <FontAwesomeIcon className="report-link" title={"Control Charts (Continuous)"} icon={faChartLine} />
          </a>
        </p>
      </td>
    );
  }

  return (
    <td>
      <p className="m-0">{dataItem[field]}</p>
    </td>);
};

export default SpecificationCell;