"use strict";

module.exports.DOCUMENT_TYPES = [
  "APL - Advertising, Promotional, Labeling Materials",
  "AGR - Agreements",
  "BUS - Business Procedures",
  "CAPA - Corrective and Preventative Action",
  "CR - Change Request",
  "DOC - Other Document",
  "DWG - Drawing",
  "FRM - Forms",
  "FRS - Function Requirement Specification",
  "LST - Lists or Index",
  "MEM - Memo",
  "NC - Non-Conformance/Deviation",
  "PLN - Plan",
  "POL - Policy",
  "PSP - Product Specification",
  "PRO - Protocol",
  "RPT - Report", "SPC - Specification",
  "SOP - Standard Operating Procedure (SOP)",
  "SRS - Software Requirement Specification",
  "STU - Study", "TRN - Training",
  "URS - User Requirement Specification",
  "WRK - Work Instruction",
];
module.exports.Document_CATEGORIES = [
  "Corporate",
  "Finance",
  "Legal",
  "Quality  ",
  "Regulatory",
  "Technical"
];
