"use strict";

import React from "react";
import Typeahead from "../../../../../widgets/typeahead";
import BaseEditor from "./base_editor";

/**
 * This class is responsible for rendering the Attribute typeaheads in the AI Import edit screen.
 */
export default class TypeaheadEditor extends BaseEditor {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (!super.shouldComponentUpdate(nextProps, nextState, nextContext)) {
      const {value, options} = this.props;
      const nextValue = nextProps.value;
      const nextOptions = nextProps.options;

      return value !== nextValue
        || options.length !== nextOptions.length;
    }

    return true;
  }

  handleChange(options) {
    const value = options && options.length > 0 ? options[0].id : null;
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  renderValidationIcon() {
    return (
      <div className="typeahead-import-error-div">
        {super.renderValidationIcon()}
      </div>
    );
  }

  getTextForMarkup() {
    if (typeof this.props.confidence !== "undefined") {
      const selectedOption = this.props.options.find(option => option.id === this.props.value);
      return selectedOption ? selectedOption.label : "";
    } else {
      return super.getTextForMarkup();
    }
  }

  getEditorHighlightsClass() {
    return "typeahead";
  }

  renderInput() {
    const {id} = this.props;
    const options = this.props.options ? this.props.options : [];
    const selectedOptions = [];
    const selectedOption = options.find(option => option.id === this.props.value);
    if (selectedOption) {
      selectedOptions.push(selectedOption);
    }

    return (
      <div className="import-data-editor-input-div">
        <Typeahead id={id}
                   inputProps={{id: id + "Input"}}
                   options={options}
                   disabled={this.props.disabled}
                   className={"import-paper-editor" + this.getEditorBorderClass()}
                   selected={selectedOptions}
                   multiple={false}
                   onChange={this.handleChange}
        />
      </div>
    );
  }
}

TypeaheadEditor.defaultProps = {
  inputProps: {autoComplete: "off"}, // QI-3016 Turn off autocomplete in Chrome
};
