"use strict";

import { WebSocketsMessageHandler } from "../web_sockets_message_handler";

/**
 * @callback BulkResultMessageCallback
 * @param message {*}
 * @param context {IConnectionContext}
 */

export class BulkOperationMessageHandler extends WebSocketsMessageHandler {
  /**
   * @param onMessage {BulkResultMessageCallback}
   * @param statusService {StatusDisplayService}
   */
  constructor(onMessage, statusService) {
    super(statusService);

    this.onSuccess = this.onSuccess.bind(this);
    this.onCustomMessage = this.onCustomMessage.bind(this);
    this.getDisplayMessage = this.getDisplayMessage.bind(this);
    this.displayStatus = this.displayStatus.bind(this);
    this.getInProgressMessage = this.getInProgressMessage.bind(this);

    this.onMessage = onMessage;
  }

  onSuccess(message, context) {
    this.onMessage(message, context);
  }

  onCustomMessage(message, context) {
    this.onMessage(message, context);
  }

  /**
   * Creates a handler for the the onOpen event of the websocket
   * @param event {Event}
   * @param context {IConnectionContext}
   * @returns {void}
   */
  handleOpen(event, context) {
    const {payload, parameters} = context;
    const {model} = parameters || {};

    context.payload = {
      model,
      ...payload,
    };

    super.handleOpen(event, context);
  }

  getDisplayMessage(message, parameters) {
    // console.log("Getting message for:", message);
    let actualMessage = (typeof(message) === "object") ? message.status : message;

    switch(actualMessage) {
      case "progress":
        if (message.status && message.data === "emails") {
          return "Sending emails";
        }
        return this.getInProgressMessage();
      case "done":
        return "Completed";
    }
    return null;
  }

  /**
   * @abstract
   * @return {string}
   */
  getInProgressMessage() {
    return "";
  }
}
