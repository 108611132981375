"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import BasePage from "../base_page";
import CommonEditablesPageTitleBar from "../widgets/pageTitleBar/common_editables_page_title_bar";
import ErrorBar from "../widgets/bars/error_bar";
import RMPListTable from "./rmp_list_table";
import { can, generateTooltip } from "../utils/ui_permissions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import CommonSecurity from "../../server/common/generic/common_security";

/* This class shows the list of RMPs. */
// i18next-extract-mark-ns-start rmps
class RMPListPage extends BasePage {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { t } = this.props;

    document.title = t("QbDVision RMPs");

    this.setStateSafely({
      breadcrumb: {
        current: t("Risk Management Plans"),
      },
    });

    super.componentDidMount();
  }

  static handleAdd() {
    window.location.href = UIUtils.getSecuredURL("viewEdit.html?operation=Add");
  }

  renderPage() {
    const { t } = this.props;

    return (
      <div>
        <CommonEditablesPageTitleBar name={t("Risk Management Plans")}
                                     hasNoLinksInTitleBar={true} />
        <div className="container-spacer" />
        <div className="container">
          <ErrorBar />
          <div className="row row-white shadow">
            <div className="col-sm-6">
              <div className="btn-group">
                <button className="btn btn-lg btn-primary"
                        id="addButton"
                        style={{marginTop: "20px"}}
                        onClick={RMPListPage.handleAdd}
                        title={t(generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.RMP))}
                        disabled={!can(CommonSecurity.Actions.ADD, CommonSecurity.Types.RMP)}>
                  {t("Add")}
                </button>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="text-right risk-management-plan-report-margin-top">
                <a href="/reports/cannedReports/base.html?reportType=RiskManagementPlan"
                   rel="noopener noreferrer"
                   target="_blank"
                   id="riskManagementPlanDocumentLink">
                  <FontAwesomeIcon icon={faChevronCircleRight} /> {t("Risk Management Plan Document")}
                </a>
              </div>
            </div>
            <div className="col-sm-12">
              <RMPListTable parent={this}
                            topLevel
                            allowRecordCopy />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(RMPListPage, "rmps");
// i18next-extract-mark-ns-stop rmps
