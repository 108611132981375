"use strict";

import { useNotification } from "./notification_provider";
import React from "react";

/**
 * HOC (Higher order component) that wraps any class component with a functional component that uses useNotification custom hook.
 * @param WrappedComponent
 * @returns {function(*): *}
 * @constructor
 */
export const WithNotification = (WrappedComponent) => {
  return (props) => {
    const {notification, showNotification, hideNotification} = useNotification();

    return (
      <WrappedComponent
        {...props}
        notification={notification}
        showNotification={showNotification}
        hideNotification={hideNotification}
      />
    );
  };
};