"use strict";

import React from "react";

export const BooleanIcon = ({value}) => {
  switch (value) {
    case "Yes":
      return (
        <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.39258 9.79688L1.17773 5.58203C0.923828 5.32812 0.923828 4.89648 1.17773 4.64258L2.0918 3.72852C2.3457 3.47461 2.75195 3.47461 3.00586 3.72852L5.875 6.57227L11.9688 0.478516C12.2227 0.224609 12.6289 0.224609 12.8828 0.478516L13.7969 1.39258C14.0508 1.64648 14.0508 2.07812 13.7969 2.33203L6.33203 9.79688C6.07812 10.0508 5.64648 10.0508 5.39258 9.79688Z" fill="#859099" />
        </svg>
      );
    case "No":
      return (
        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.14453 5.125L9.68359 7.68945C10.0137 7.99414 10.0137 8.50195 9.68359 8.80664L9.125 9.36523C8.82031 9.69531 8.3125 9.69531 8.00781 9.36523L5.46875 6.82617L2.9043 9.36523C2.59961 9.69531 2.0918 9.69531 1.78711 9.36523L1.22852 8.80664C0.898438 8.50195 0.898438 7.99414 1.22852 7.68945L3.76758 5.125L1.22852 2.58594C0.898438 2.28125 0.898438 1.77344 1.22852 1.46875L1.78711 0.910156C2.0918 0.580078 2.59961 0.580078 2.9043 0.910156L5.46875 3.44922L8.00781 0.910156C8.3125 0.580078 8.82031 0.580078 9.125 0.910156L9.68359 1.46875C10.0137 1.77344 10.0137 2.28125 9.68359 2.58594L7.14453 5.125Z" fill="#859099" />
        </svg>
      );
    default:
      return null;
  }
};