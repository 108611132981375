"use strict";

import * as styles from "./tree_item.module.scss";

import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

export type TreeItemType = {
  level: number;
  text: string;
  isLeaf: boolean;
  isRootNode: boolean;
  items: Array<TreeItemType>;
  parent?: any;
  value: any;
  renderTreeItemComponent?: () => React.ReactElement;
  className?: string;
  title?: string;
  expanded?: boolean;
  tooltipMessage?: string;
};

type TreeItemProps = {
  item: TreeItemType;
};

/**
 * The component that will be used for rendering each of the TreeView item
 * @param props {TreeItemProps}
 */
export default function TreeItem(props: TreeItemProps) {
  const {item} = props;
  const {
    level,
    text,
    isLeaf,
    isRootNode,
    renderTreeItemComponent,
    className,
    title,
    tooltipMessage,
  } = item;

  const renderContent = () => {
    if (tooltipMessage) {
      return (
        <OverlayTrigger
          placement="auto"
          overlay={<Tooltip id="tree-item-tooltip">{tooltipMessage}</Tooltip>}
        >
          {renderTreeItemComponent ? (
            renderTreeItemComponent()
          ) : (
            <div>{text}</div>
          )}
        </OverlayTrigger>
      );
    }

    return renderTreeItemComponent ? renderTreeItemComponent() : text;
  };

  return (
    <div
      title={title || ""}
      className={`tree-level-${level} ${
        styles[isLeaf ? "tree-leaf" : "tree-node"]
      } ${isRootNode ? "root-node" : isLeaf ? "tree-leaf" : "tree-node"} ${
        className ? className : ""
      }`}
    >
      {renderContent()}
    </div>
  );
}

export function createTreeItem<T>(options: {
  text: string;
  level?: number;
  value?: T;
  isLeaf?: boolean;
  renderTreeItemComponent?: () => React.ReactElement;
  className?: string;
  title?: string;
}): TreeItemType {
  const {
    text,
    level,
    value,
    isLeaf,
    renderTreeItemComponent,
    className,
    title,
  } = options;
  return {
    text,
    value,
    className,
    title,
    renderTreeItemComponent,
    level: level || 0,
    isLeaf: isLeaf || false,
    items: [],
    isRootNode: !level || level === 0,
  };
}
