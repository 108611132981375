"use strict";

import React from "react";
import AlertsEntry from "./alerts_entry";
import { faCheck, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { Trans } from "react-i18next";
import BaseReactComponent from "../../base_react_component";

/**
 * This class handles alerts section of the home page
 */
// i18next-extract-mark-ns-start homePage
class AlertsPanel extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  /**
   * @return {string} the loading class to display the custom rows loading.
   */
  getClassForRowsLoading() {
    return this.isLoading() ? " skeleton skeleton-home-page" : "";
  }

  /**
   * Function to filter training overdue alerts based on the current user
   * @param instances Array of alerts
   * @return {Array} Filetered array of alerts to be displayed
   */
  filterAlerts(instances) {
    const currentUser = this.props.currentUser;
    let filteredInstances = [];
    if (currentUser?.department === "Management" || currentUser?.department === "Quality Assurance" ||
      currentUser?.isTrainingCoordinator) {
      return instances;
    } else {
      filteredInstances = instances.filter(
        instance => (instance.modelType !== "USR" || Number(instance.id) === currentUser?.id || Number(instance.supervisorId) === currentUser?.id)
      );
    }
    return filteredInstances;
  }

  render() {
    let {className, data, t} = this.props;
    let {count, instances} = data;
    instances = this.filterAlerts(instances);
    count = instances.length;
    const hasAlerts = count > 0;
    return (
      <div className={"home-page-alerts-card shadow " + (className || "")}>
        <div className={"home-page-alerts-card-header"}>
          <span className="home-page-alerts-card-title">
            <span className={this.getClassForLoading()}>
            <FontAwesomeIcon icon={hasAlerts ? faExclamationTriangle : faCheck}
                             className={(hasAlerts ? "home-page-alerts-card-error-icon-red" : "home-page-alerts-card-error-icon-green") + this.getClassForLoading()}
            />
              {count}
            </span>
            &nbsp;{t("Alert", {count})}
          </span>
          <a href="#" className="home-page-alerts-card-link">{t("View All")}</a>
        </div>
        <div className={
          "home-page-alerts-card-details scroller"
          + (instances.length === 0 ? " home-page-empty-alerts" : "")
          + this.getClassForRowsLoading()
        }
        >
          {instances.length > 0 ?
            instances.map((instance, index) => {
              return (
                <AlertsEntry key={index} object={instance} currentUser={this.props.currentUser}/>
              );
            }) :
            <Trans t={t}>
              <div className="home-page-empty-data home-page-empty-data-alerts">
                No alerts yet! Great work. This area tracks important dates in the system.
              </div>
            </Trans>
          }
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(AlertsPanel, "homePage");
// i18next-extract-mark-ns-stop homePage

