"use strict";

import * as UIUtils from "../../ui_utils";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";
import BaseAutoBind from "../../base_auto_bind";
import { PROCESS_BAR_PANELS } from "../process_explorer_constants";

const Logger = Log.group(LOG_GROUP.ProcessExplorer, "ProcessExplorerPage");

export default class ProcessExplorerHelper extends BaseAutoBind {
  constructor(props) {
    super(props);
    this.parent = props.parent;
  }

  getFullRecordForCopy() {
    const {parent} = this;
    const selectedNode = parent.state.showCopySelectedNode;
    const {typeCode, id} = UIUtils.parseKey(selectedNode.staticPanelKey);
    const fullRecord = parent.processExplorerHelper.findStaticPanelRecord(typeCode, id);
    if (typeCode === "MT" || typeCode === "PRC") {
      const parentUOMatch = selectedNode.key?.match(/UO-(\d+)-.*/i);
      const parentStepMatch = selectedNode.key?.match(/STP-(\d+)-.*/i);
      if (parentUOMatch && parentUOMatch[1]) {
        fullRecord.parentUOId = parentUOMatch[1];
      }
      if (parentStepMatch && parentStepMatch[1]) {
        fullRecord.parentStepId = parentStepMatch[1];
      }
    }
    return fullRecord;
  }

  setChildInstances(instance, allInstances) {
    /* Currently only the Material Attributes of Materials need to be set as child instances, as this is the only
       record we are showing the related tables for.
     */
    if (instance.modelName === "Material") {
      instance.childInstances = allInstances
        .filter(childInstance => childInstance.MaterialId === instance.id && childInstance.modelName === "MaterialAttribute")
        .sort(UIUtils.sortBy("name"));
    }
  }

  countRecordsExcludeNonArchived(allRecords) {
    return allRecords.filter(instance => instance.deletedAt === null).length;
  }

  waitForRecordLoading(message = "Waiting for Process Explorer to finish loading...") {
    const {parent} = this;
    let loadingPromise;
    if (parent.recordLoader?.isLoading()) {
      loadingPromise = parent.recordLoader.getRecordLoadingPromise();
      UIUtils.showLoadingImage(message);
      parent.recordLoader.registerLoadingStatusHandler((recordsLoadedSoFar, totalNumberOfRecords) => {
        UIUtils.showLoadingImage(`${message} (${recordsLoadedSoFar}/${totalNumberOfRecords})`);
      });
      loadingPromise = loadingPromise.then(() => {
        Logger.info(() => "Hiding image...");
        UIUtils.hideLoadingImage();
        UIUtils.setLoadingDisabled(true);
      });
    } else {
      loadingPromise = Promise.resolve();
    }
    return loadingPromise;
  }

  findStaticPanelRecord(typeCode, id) {
    const {parent} = this;
    let record;
    if (parent.recordLoader?.isLoading()) {
      record = parent.recordLoader.findRecordIfLoaded(typeCode + "-" + id);
      Logger.info(() => "Found record from partial load:", record);
    } else {
      record = parent.state.keyToFullRecordMap.get(typeCode + "-" + id);
    }
    return record;
  }

  reLayoutDiagram() {
    if (this.parent.state.selectedPanel === PROCESS_BAR_PANELS.TREE) {
      const result = this.parent.processExplorerDiagram.diagram.reLayoutDiagram();
      UIUtils.incrementReactComponentDidUpdateCounter();
      return result;
    }
  }

  validateDuplicateNames(name) {
    let {processes} = this.parent.state;
    let matchedProcess = processes.find(process => process.name === name);
    const matchedProcessIsArchived = matchedProcess && !!matchedProcess.deletedAt;
    return matchedProcess ? {error: `A${matchedProcessIsArchived ? "n archived" : ""} process with the same name already exists, please use a different name.`} : {};
  }

  handleHistory(event) {
    Logger.verbose(() => "Moving to state: " + UIUtils.stringify(event.state));
    this.parent.setStateSafely(event.state);
  }
}
