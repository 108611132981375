"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import RiskMapReportLayout from "../utilities/risk_map_report_layout";
import Typeahead from "../../../widgets/typeahead";
import { Menu, MenuItem, Highlighter } from "react-bootstrap-typeahead";
import { getURLByTypeCodeAndIdAndVersionId } from "../../../helpers/url_helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpandArrowsAlt, faFileExport, faFolderOpen, faTh, faSave } from "@fortawesome/free-solid-svg-icons";
import BaseReactComponent from "../../../base_react_component";

export default class RiskMapToolbar extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.filtersSearchTypeaheadWarning = "Warning! Some Options are filtered";
  }

  riskMapSearchTypeaheadRenderMenu(results, menuProps) {
    let filters = this.props.filters;

    let filtersApplied = (filters &&
      !(
        !!filters.showTPPs &&
        !!filters.showGeneralAttributes &&
        !!filters.showFQAs &&
        !!filters.showFPAs &&
        !!filters.showIQAs &&
        !!filters.showIPAs &&
        !!filters.showPPs &&
        !!filters.showMAs &&
        filters.linksFilter === "all"
      ));

    let warningMenuItem = filtersApplied ?
      [
        (<MenuItem key={0}
                   option={{
                     id: 0,
                     label: this.filtersSearchTypeaheadWarning
                   }}
                   position={1} // Don't change this to 0 or you will get https://cherrycircle.atlassian.net/browse/QI-4049
                   className="risk-map-search-typeahead-warning-item"
                   disabled
        >
          {"Warning! Some Options are filtered"}
        </MenuItem>)
      ] :
      [];

    return (
      <Menu {...menuProps}>
        {warningMenuItem.concat(results.map((result, index) => (
          <MenuItem key={warningMenuItem.length > 0 ? index + 1 : index}
                    option={result}
                    position={warningMenuItem.length > 0 ? index + 1 : index}
          >
            <Highlighter search={menuProps.text}>
              {result.label}
            </Highlighter>
          </MenuItem>
        )))}
      </Menu>
    );
  }

  handleLoadLayoutButtonPress() {
    UIUtils.secureAjaxGET("entities/reportLayout/list", {
      projectId: this.props.projectId,
      reportType: this.props.reportType
    }, true)
      .done(this.handleSavedLayoutsReceivedFromServer);
  }

  handleSavedLayoutsReceivedFromServer(results) {
    let layouts = results.map(layout => {
      return new RiskMapReportLayout(layout.id, layout.ProjectId, layout.ProcessId, layout.type, layout.layout, layout.name, layout.description);
    });
    this.props.onShowLoadLayoutsPopup(layouts);
  }

  handleSaveLayoutButtonPress() {
    this.props.onShowSaveLayoutsPopup();
  }

  handleExportLayoutButtonPress() {
    this.props.onExportLayout();
  }

  handleFitLayoutButtonPress() {
    this.props.onFitRiskMapReport();
  }

  handleResetNetworkLayoutButtonPress() {
    this.props.onResetRiskMapReportLayout();
  }

  handleSearchSelectionChanged(event) {
    this.props.onRiskMapReportSearchSelectionChanged(event.filter(option => {
      return option.label !== this.filtersSearchTypeaheadWarning;
    }));
  }

  render() {
    let selectedDate = UIUtils.getDateForDisplayToUser(this.props.selectedDate);

    return (
      <div id="riskMapOptionsPanel"
           className="canvas-report-options-panel canvas-report-options-vcenter"
      >
        <div id="riskMapOptionsContent"
             className="canvas-report-options-content"
        >
          <div className="canvas-report-options-row canvas-report-options-vcenter">
            <div className="canvas-report-options-field canvas-report-options-button">
              <button id="loadRiskMapLayoutDialogButton"
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleLoadLayoutButtonPress}
                      aria-label="Open Layout"
                      title="Loads a saved Risk Map report layout"
              >
                <FontAwesomeIcon icon={faFolderOpen} />
              </button>
            </div>
            <div className="canvas-report-options-field canvas-report-options-button">
              <button id="saveRiskMapLayoutDialogButton"
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleSaveLayoutButtonPress}
                      aria-label="Save Layout"
                      title="Saves the current Risk Map report layout"
              >
                <FontAwesomeIcon icon={faSave} />
              </button>
            </div>
            <div className="canvas-report-options-field divider-vertical" />
            <div className="canvas-report-options-field canvas-report-options-button">
              <button id="riskMapExportButton"
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleExportLayoutButtonPress}
                      aria-label="Export Layout"
                      title="Export the Risk Map report as an image"
              >
                <FontAwesomeIcon icon={faFileExport} />
              </button>
            </div>
            <div className="canvas-report-options-field divider-vertical" />
            <div className="canvas-report-options-field canvas-report-options-field-sm">
              <div>
                <span>
                  Showing:&nbsp;
                </span>
                <label id="riskMapReportDateSpan"
                       className={this.props.lastDateSelected ? "risk-map-date" : "risk-map-historic-date"}
                >{(selectedDate ? selectedDate : "")}</label>
              </div>
              <div>
                <span>
                  Color showing:&nbsp;
                </span>
                <label className="risk-map-risk-type"
                       id="riskMapToolbarRiskTypeSpan"
                >{this.props.riskType}</label>
              </div>
            </div>
            <div className="canvas-report-options-field divider-vertical" />
            <div className="canvas-report-options-field canvas-report-options-field-sm">
              <span>
                Effective RMP:
              </span>
              {this.props.RMP ? (
                <a href={getURLByTypeCodeAndIdAndVersionId("RMP", "View", this.props.RMP.RMPId, false, this.props.RMP.id)}
                   className="risk-map-effective-rmp"
                   id="riskMapEffectiveRMP"
                   rel="noopener noreferrer"
                   target="_blank"
                >
                  {`${this.props.RMP.name} V${this.props.RMP.majorVersion}.${this.props.RMP.minorVersion}`}
                </a>
              ) : ""}
            </div>
            <div className="canvas-report-options-field canvas-report-options-button canvas-report-options-right-align">
              <button id="resetNetworkLayoutButton"
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleResetNetworkLayoutButtonPress}
                      aria-label="Reset Layout"
                      title="Resets the Risk Map report layout to the default view."
              >
                <FontAwesomeIcon icon={faTh} />
              </button>
            </div>
            <div className="canvas-report-options-field canvas-report-options-button">
              <button id="fitNetworkLayoutButton"
                      type="button"
                      className="btn btn-primary"
                      onClick={this.handleFitLayoutButtonPress}
                      aria-label="Fit Layout"
                      title="Fits the Risk Map report layout to the screen"
              >
                <FontAwesomeIcon icon={faExpandArrowsAlt} />
              </button>
            </div>
            <div className="canvas-report-options-field divider-vertical" />
            <div className="canvas-report-options-field-container canvas-report-options-vcenter">
              <div className="risk-map-options-label">
                <label htmlFor="riskMapSearchTypehead"
                       className="col-form-label risk-map-search-label"
                >
                  Search:
                </label>
              </div>
              <div id="riskMapSearchTypeheadDiv"
                   className="canvas-report-options-field risk-map-options-search-field"
              >
                <Typeahead id="riskMapSearchTypehead"
                           inputProps={{id: "riskMapSearchTypeheadInput", autoComplete: "off"}}
                           options={this.props.typeaheadOptions || []}
                           onChange={this.handleSearchSelectionChanged}
                           maxResults={6}
                           className="risk-map-search-typeahead"
                           selectHintOnEnter={true}
                           multiple={true}
                           renderMenu={this.riskMapSearchTypeaheadRenderMenu}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
