"use strict";

import { TableAdapter } from "./table_adapter";
import { RISK_TYPE_ENUM } from "../../../../helpers/constants/constants";
import { getTableCellClassFromRiskScore } from "../../../../helpers/risk_helper";
import * as UIUtils from "../../../../ui_utils";
import ReactDOM from "react-dom";
import QuickPanelLink from "../../../../editor/quick/quick_panel_link";
import * as CommonRiskTable from "../../utils/risk_tables_helper";
import { getURLByTypeCodeAndId } from "../../../../helpers/url_helper";
import * as RiskTableRenderer from "../../utils/risk_tables_renderer";
import { ComplexCriticalityJustification } from "../../widgets/complex_criticality_justification";
import { NoDataCell } from "../../widgets/no_data_cell";

import React, { Fragment } from "react";
import { getTypeCodeForModelName } from "../../../../../server/common/generic/common_utils";

/**
 * This is parent table adapter for all list tables. Table adapter is mainly intended to adapt from our data model types to datatables.net dom objects.
 */
export class ListTableAdapter extends TableAdapter {

  constructor({reportKey, tableData, props}) {
    super(reportKey, tableData, props);
    this.includeLocationColumn = this.reportSettings.includeLocationColumn;
  }

  getColumnsForReport() {
    return [
      {
        title: this.reportTitle,
        width: 50,
        data: result => result.name,
      },
      ...this.includeLocationColumn ? [{
        title: "Location",
        width: 100,
        data: result => result.sortOrder,
      }] : [],
      this.getRiskColumn(RISK_TYPE_ENUM.CRITICALITY),
      {
        title: "Criticality Justification",
        width: 50,
        containsHTML: true,
        data: (result, type) => {
          return type === "sort" || type === "filter"
            ? result.CriticalityJustification ? result.CriticalityJustification.toString() : null
            : result.CriticalityJustification;
        },
      },
      this.getRiskColumn(RISK_TYPE_ENUM.PROCESS_RISK),
      this.getRiskColumn(RISK_TYPE_ENUM.RPN),
      {
        title: "Recommended Actions",
        width: 50,
        data: (result) => result.recommendedActions
      },
      {
        title: "Control Strategy",
        width: 50,
        data: (result) => result.controlStrategy
      },
      {
        title: "Control Methods",
        width: 50,
        // Return the Id only if this is being called for sorting / filtering
        data: (result, type) => type === "display"
          ? result.controlMethods
          : (result.controlMethods ? result.controlMethods[0].id : null)
      }
    ];
  }

  getRiskColumn(riskType) {
    return {
      title: riskType,
      width: 50,
      data: result => result.riskInfo[riskType].value,
    };
  }

  getColumnDefinitionsForReport() {
    let target = 0;
    return [
      {
        targets: target++,
        createdCell: (td, cellData, rowData) => {
          const id = rowData.id;
          const name = rowData.name;
          const currentState = rowData.currentState;
          const typeCode = getTypeCodeForModelName(this.modelName);
          const label = UIUtils.getRecordLabelForDisplay(typeCode, id, name);
          const quickPanelLinkId = CommonRiskTable.getQuickPanelLinkId(typeCode, id);

          ReactDOM.render(
            (
              <div>
                <div>
                  <div>
                    <QuickPanelLink
                      id={quickPanelLinkId}
                      onClick={window.handleQuickEditRecord.bind(this, typeCode, id,
                        rowData.isLastVersion ? null : rowData.versionId)}
                      className="risk-tables-row-header-cell"
                    >
                      {label}
                    </QuickPanelLink>
                  </div>
                  {this.reportSettings.showParentInRows && rowData.sourceParentModelType ? (
                    <div className="risk-tables-row-header-cell-uo">
                      <a href={getURLByTypeCodeAndId(rowData.sourceParentModelType, "View", rowData.sourceParentModelId)}
                         rel="noopener noreferrer"
                         target="_blank"
                      >
                        {rowData.sourceParentModelType + "-" + rowData.sourceParentModelId + " - " + rowData.sourceParentModel}
                      </a>
                    </div>
                  ) : ""}
                  <div>
                    {rowData.isLastVersion ? "" : (
                      <span className="risk-tables-column-version">
                        {"Version: " + rowData.majorVersion + "." + rowData.minorVersion}&nbsp;
                      </span>
                    )}
                    <span className="risk-tables-column-stamp">{CommonRiskTable.getStampFromState(currentState)}</span>
                  </div>
                </div>
              </div>
            ), td);
        }
      },
      ...this.includeLocationColumn ? [{
        targets: target++,
        createdCell: (td, cellData, rowData) => {

          const {stepId, unitOperationId} = rowData;
          const {steps, unitOperations} = this.props;
          const unitOperation = unitOperations.find(uo => uo.id === unitOperationId);
          const step = steps.find(step => step.id === stepId);

          ReactDOM.render(
            (
              <Fragment>
                {unitOperation && <div className="risk-tables-multi-link">
                  <a href={getURLByTypeCodeAndId("UO", "View", unitOperationId)}
                     rel="noopener noreferrer"
                     target="_blank"
                     className="risk-tables-row-header-cell d-block"
                  >
                    {getTypeCodeForModelName("UnitOperation") + "-" + unitOperationId + " - " + unitOperation.name}
                  </a>
                </div>
                }
                {step &&
                  <div className="risk-tables-multi-link">
                    <a href={getURLByTypeCodeAndId("STP", "View", stepId)}
                       rel="noopener noreferrer"
                       target="_blank"
                       className="risk-tables-row-header-cell d-block"
                    >
                      {getTypeCodeForModelName("Step") + "-" + stepId + " - " + step.name}
                    </a>
                  </div>
                }
              </Fragment>
            ), td);
        }
      }] : [],
      {
        targets: target++,
        createdCell: (td, cellData, rowData) => {
          let rawRiskScore = rowData.riskInfo[RISK_TYPE_ENUM.CRITICALITY].value;
          let labelAction = this.props.labelAction;
          let showRiskLabel = CommonRiskTable.getShowRiskLabelFromLabelAction(labelAction);
          ReactDOM.render(
            (
              <span data-trigger="click hover"
                    data-html="true"
                    data-placement="right"
                    data-container="body"
                    data-toggle="popover"
                    className={getTableCellClassFromRiskScore(RISK_TYPE_ENUM.CRITICALITY, this.props.RMP, rawRiskScore, rowData, showRiskLabel)}
                    data-content={RiskTableRenderer.renderCompositeCriticalityTooltip(this.props.RMP, rowData)}
              >
                {CommonRiskTable.getRiskScoreWithLabel(rowData, RISK_TYPE_ENUM.CRITICALITY, this.props.RMP, this.props.showRawScore, labelAction, true, "")}
              </span>
            ), td);
          $(td).addClass(getTableCellClassFromRiskScore(RISK_TYPE_ENUM.CRITICALITY, this.props.RMP, rawRiskScore, rowData, showRiskLabel));
        }
      },
      {
        targets: target++,
        createdCell: (td, cellData) => {
          ReactDOM.render(<ComplexCriticalityJustification justification={cellData} />, td);
        }
      },
      {
        targets: target++,
        createdCell: (td, cellData, rowData) => {
          let rawRiskScore = rowData.riskInfo[RISK_TYPE_ENUM.PROCESS_RISK].value;
          let labelAction = this.props.labelAction;
          let showRiskLabel = CommonRiskTable.getShowRiskLabelFromLabelAction(labelAction);
          ReactDOM.render(
            (
              <span data-trigger="click hover"
                    data-html="true"
                    data-placement="right"
                    data-container="body"
                    data-toggle="popover"
                    className={getTableCellClassFromRiskScore(RISK_TYPE_ENUM.PROCESS_RISK, this.props.RMP, rawRiskScore, rowData, showRiskLabel)}
                    data-content={RiskTableRenderer.renderCapabilityRiskTooltip(rowData.capabilityRisk, this.props.RMP)}
              >
                {CommonRiskTable.getRiskScoreWithLabel(rowData, RISK_TYPE_ENUM.PROCESS_RISK, this.props.RMP, this.props.showRawScore, labelAction, true, "")}
              </span>
            ), td);
          $(td).addClass(getTableCellClassFromRiskScore(RISK_TYPE_ENUM.PROCESS_RISK, this.props.RMP, rawRiskScore, rowData, showRiskLabel));
        }
      },
      {
        targets: target++,
        createdCell: (td, cellData, rowData) => {
          let rawRiskScore = rowData.riskInfo[RISK_TYPE_ENUM.RPN].value;
          let labelAction = this.props.labelAction;
          let showRiskLabel = CommonRiskTable.getShowRiskLabelFromLabelAction(labelAction);
          ReactDOM.render(
            (
              <span data-trigger="click hover"
                    data-html="true"
                    data-placement="right"
                    data-container="body"
                    data-toggle="popover"
                    className={getTableCellClassFromRiskScore(RISK_TYPE_ENUM.RPN, this.props.RMP, rawRiskScore, rowData, showRiskLabel)}
                    data-content={RiskTableRenderer.renderDetectabilityRiskTooltip(rowData.detectabilityRisk, this.props.RMP)}
              >
                {CommonRiskTable.getRiskScoreWithLabel(rowData, RISK_TYPE_ENUM.RPN, this.props.RMP, this.props.showRawScore, labelAction, true, "")}
              </span>
            ), td);
          $(td).addClass(getTableCellClassFromRiskScore(RISK_TYPE_ENUM.RPN, this.props.RMP, rawRiskScore, rowData, showRiskLabel));
        }
      },
      {
        targets: target++,
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            (
              <span>{rowData.recommendedActions}</span>
            ), td);
        },
      },
      {
        targets: target++,
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            (
              <span data-trigger="click hover"
                    data-html="true"
                    data-placement="right"
                    data-container="body"
                    data-toggle="popover"
                    data-content={RiskTableRenderer.renderJustificationTooltip(rowData.controlStrategyJustification)}
              >
              {rowData.controlStrategy}
            </span>
            ), td);
        },
      },
      {
        targets: target++,
        createdCell: (td, cellData, rowData) => {
          ReactDOM.render(
            (
              <div>
                {rowData.controlMethods && rowData.controlMethods.length > 0 ? rowData.controlMethods.map(controlMethod => (
                  <div key={controlMethod.id}
                       className="risk-tables-multi-link"
                  >
                    <div key={controlMethod.id + "-1"}>
                         <span key={controlMethod.id}
                               data-trigger="hover"
                               data-html="true"
                               data-placement="right"
                               data-container="body"
                               data-toggle="popover"
                               data-content={RiskTableRenderer.renderControlMethodTooltip(controlMethod.status)}
                         >
                           <a href={getURLByTypeCodeAndId("CM", "View", controlMethod.id)}
                              rel="noopener noreferrer"
                              target="_blank"
                              className="risk-tables-row-header-cell"
                           >
                             {getTypeCodeForModelName("ControlMethod") + "-" + controlMethod.id + " - " + controlMethod.name}
                           </a>
                         </span>
                    </div>
                    <div key={controlMethod.id + "-2"}>
                      <span className="risk-tables-column-stamp">{CommonRiskTable.getStampFromState(controlMethod.state)}</span>
                    </div>
                  </div>)
                ) : (
                  <NoDataCell />
                )}
              </div>
            ), td);
        }
      },
    ];
  }

  createReportHeader() {
    throw new Error("Not supported for ListTables!");
  }

  createReportFooter() {
    throw new Error("Not supported for ListTables!");
  }

  handleCreatedHeaderRowCallback(thead) {
    super.handleCreatedHeaderRowCallback(thead);
  }

  // eslint-disable-next-line no-unused-vars
  handleCreatedFooterRowCallback(tableId) {
  }

  markColumnsRequiresSorting(tableId, defaultOrder = "asc") {
    // Set initial order for Location column...
    if (this.includeLocationColumn) {
      let api = $(tableId).dataTable().api();
      api
        .table()
        .order([1, defaultOrder])
        .draw();
    }
  }


}
