import TypeaheadAttribute from "../../editor/attributes/typeahead_attribute";
import * as UIUtils from "../../ui_utils";

/**
 * Use this to show the right tech transferTransferredFrom value as it has a logic where
 * if there is a TechTransferFromId it will return it back and if not it will return back
 * the ClonedFromVersion value.
 */
export default class TechTransferTypeaheadAttribute extends TypeaheadAttribute {
  getValue() {
    const {parent} = this.props;
    return this.getTransferredFromId(parent.state);
  }

  getOldValue(olderVersion) {
    return this.getTransferredFromId(olderVersion);
  }

  getTransferredFromId(object) {
    const {typeaheadType, parent} = this.props;
    const {clonedFrom} = parent.state;
    const {TransferredFromId, id} = object;
    return TransferredFromId === id ? null : TransferredFromId || (clonedFrom ? clonedFrom[`${UIUtils.capitalize(typeaheadType)}Id`] : undefined);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.getProcessId() !== nextProps.getProcessId()) {
      return true;
    }

    return super.shouldComponentUpdate(nextProps, nextState);
  }
}
