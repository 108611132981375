"use strict";

import ImplementationNeededError from "../../utils/implementation_needed_error";

/**
 * @typedef IFilterOptions {Object}
 * @property type {*}
 * @property criticality {*}
 * @property subReport {string}
 */

/**
 * @typedef ITransformOptions {Object}
 * @property reportType {string}
 * @property reportOptions {*}
 * @property rmpVersions {*[]}
 * @property modelType {string}
 * @property typeaheadOptions {*}
 * @property dependenciesTypeaheadOptions {*}
 * @property reportDate {moment}
 * @property filterOptions {IFilterOptions}
 */

/***
 * This class defines an object that receives a report JSON and transforms it into a format that
 * fits better the presentation layer
 * @abstract
 */
export class BaseDataTransform {
  /**
   * Creates a new instance of the {CompositeDataTransform} class
   * @param config {*} The reports configuration object
   * @param options {ITransformCreationOptions} The creation options for this transform
   */
  constructor(config, options) {
    /**
     * The entire reports configuration object
     * @type {*}
     */
    this.config = config;
    /**
     * The object used to create this transform from the factory
     * @type {ITransformCreationOptions}
     */
    this.creationOptions = options;
  }

  get type() {
    throw new ImplementationNeededError("The 'type' method must be implemented on a derived class.");
  }

  get defaultStrategy() {
    return this.creationOptions.strategy;
  }

  get modelType() {
    return this.creationOptions.modelType;
  }

  /**
   * Receives an input object and transforms it into an output value, according to the specified options.
   * @param input {*} The input data to be transformed
   * @param options {ITransformOptions} An object that contains the options for this transformation
   * @returns {*} The result of the data transformation
   * @abstract
   */
  transform(input, options) {
    throw new ImplementationNeededError("The 'transform' method must be implemented on a derived class.");
  }

  /**
   * Checks whether this transform should be executed or not.
   * @param options
   * @returns {boolean} A boolean indicating whether to run or not.
   * @abstract
   */
  shouldRun(options) {
    throw new ImplementationNeededError("The 'shouldRun' method must be implemented on a derived class.");
  }

  getSupportedRecordTypes() {
    return ["fqas", "iqas", "fpas", "ipas", "pps", "mas", "uos", "processcomponents", "materials"];
  }
}
