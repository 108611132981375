"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import { HOME_PAGE_TABS_ENUM } from "../constants";
import { getURLByTypeCodeAndId, getURLByTypeNameAndId } from "../../helpers/url_helper";
import BaseReactComponent from "../../base_react_component";
import { QBDLink } from "../../utils/withRouter";
import { RouterContext } from "../../utils/router_context";

/**
 * This class handles adding entries to the notifications panel
 */
export default class NotificationEntry extends BaseReactComponent {
  static contextType = RouterContext;

  constructor(props) {
    super(props);
  }

  getAction(action) {
    switch (action) {
      case "Edit":
        return "Edited";
      case "Add":
        return "Added";
      case "Clone":
        return "Cloned";
    }
  }

  onNotificationEntryClick(event) {
    event.stopPropagation();

    let {object} = this.props;
    let {id, type} = object;
    this.context.navigate(getURLByTypeCodeAndId(type, "View", id));
  }

  render() {
    let {className, object, tab} = this.props;
    let {id, name, type, user, lastActivity, action} = object;

    return (type && id && name) ? (
      <Fragment>
        <div onClick={this.onNotificationEntryClick}
             className={"home-page-notification-entry " + (className || "")}
        >
          <QBDLink className="home-page-notification-entry-title" to={getURLByTypeCodeAndId(type, "View", id)}
          >
            {UIUtils.getRecordCustomLabelForDisplay(object)}
          </QBDLink>
          <Fragment>
            {tab === HOME_PAGE_TABS_ENUM.ACTIVITY ?
              <span className="home-page-notification-entry-user">{this.getAction(action)} By&nbsp;
                <QBDLink to={getURLByTypeNameAndId("user", "View", object.user.id)}>
                  {user.name}
                </QBDLink>
              </span> : ""}
            <span className="home-page-notification-entry-last-activity">{lastActivity}</span>
          </Fragment>
        </div>
        <div className="home-page-notification-entry-separator" />
      </Fragment>
    ) : "";
  }
}