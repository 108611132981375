import React from "react";
import * as I18NWrapper from "../../../i18n/i18n_wrapper";
import ProjectMaterialAttributeBasePage from "../project_material_attribute_base_page";
import { MATERIAL_ATTRIBUTE_ACTIONS } from "../../helpers/library_helper";

class UnlinkProjectMaterialAttributePage extends ProjectMaterialAttributeBasePage {

  componentDidMount() {
    document.title = "Unlink Project Material Attribute";

    super.componentDidMount();
  }

  getTitle(materialAttribute) {
    return "Unlink " + materialAttribute.name + " from Library Specification";
  }

  getSubtitle() {
    return "Unlink Material Attribute from Library Specification.";
  }

  getAction() {
    return MATERIAL_ATTRIBUTE_ACTIONS.UNLINK;
  }

  getActionButton() {
    return (<button id="unlinkButton" className="btn btn-lg btn-primary float-right"
                    onClick={this.onActionButtonClick}
    >
      Unlink
    </button>);
  }
}

export default I18NWrapper.wrap(UnlinkProjectMaterialAttributePage, "library");