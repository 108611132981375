"use strict";

import { useMenu } from "../menu_provider";
import * as pillStyles from "../filter.module.scss";
import React from "react";

export const Menu = ({children = []}) => {
  const {close, isOpen, position} = useMenu();

  if (!isOpen) {
    return null;
  }

  if (!children.length && isOpen) {
    close();
  }

  return (
    <div className={pillStyles["menu"]}
         style={{
           top: `${position.top}px`,
           left: `${position.left}px`,
         }}
    >
      {children}
    </div>
  );
};