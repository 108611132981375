import React, {Fragment, useState} from "react";
import {ListOption} from "../../editor/widgets/list_option";
import {getFields, FIELD_KEY} from "../common/editables_map";
import {ActionButton} from "../../widgets/generic/action_button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import * as UIUtils from "../../ui_utils";
import {Project} from "../../common/models/project";
import {RMP} from "../../common/models/rmp";
import * as DirectScopeWidgetParser from "../utils/parser/direct_scope_widget_parser";

/**
 * The component will render filters for a widget like Repeater or Table.
 */
type MultipleFiltersProps = {
  model: string;
  subModel: string;
  project: Project;
  disabledAddButton: boolean;
  // eslint-disable-next-line no-unused-vars
  onUpdateFilters: (filters: Array<Filter>, isValid: boolean) => void;
};

export default function MultipleFilters(props: MultipleFiltersProps) {
  const {model, subModel, project, disabledAddButton, onUpdateFilters} =
    props;
  const [filters, setFilters] = useState<Array<Filter>>([]);

  const addFilter = () => {
    const filter = new Filter(model);
    const newFilters = [...filters, filter];
    setFilters(newFilters);
    onUpdateFilters(newFilters, validateFilter());
  };

  const handleInputChange = (
    key: string,
    filterIndex: number,
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    if (filters.length <= filterIndex) {
      return;
    }

    const filter = filters[filterIndex];
    filter[key] = event.target.value;
    setFilters([...filters]);
    onUpdateFilters(filters, validateFilter());
  };

  const handleDeleteFilter = (filterIndex: number) => {
    if (filters.length <= filterIndex) {
      return;
    }

    filters.splice(filterIndex, 1);
    setFilters([...filters]);
    onUpdateFilters(filters, validateFilter());
  };

  const validateFilter = () => {
    for (const filter of filters) {
      if (!filter.isValid()) {
        return false;
      }
    }
    return true;
  };

  const renderFilter = (filter: Filter, index: number) => {
    const operators = ["=", "!=", ">", "<", ">=", "<=", "contains"];
    const {attribute, operator, targetValue} = filter;
    const FIELDS = getFields(project);
    const mergedMap = FIELDS.get(FIELD_KEY.RecordFields).attributesMap;
    let modelToAttributes = mergedMap.get(model).attributesMap;
    let attributeNames = Array.from(modelToAttributes.keys())
      .filter((attribute) => !modelToAttributes.get(attribute).attributesMap)
      .sort((str1, str2) =>
        str1.localeCompare(str2, undefined, {sensitivity: "accent"})
      );
    if (
      subModel &&
      modelToAttributes.get(subModel) &&
      modelToAttributes.get(subModel).attributesMap
    ) {
      modelToAttributes = modelToAttributes.get(subModel).attributesMap;
      attributeNames = Array.from(modelToAttributes.keys()).sort((str1, str2) =>
        str1.localeCompare(str2, undefined, {sensitivity: "accent"})
      );
    }

    return (
      <Fragment key={index}>
        <div id="multiple-filters" className="row m-0 col-sm-12">
          <div className="col-sm-4 m-0 p-0 pr-1 form-group">
            <label htmlFor="attributeComboBox" className="col-form-label">
              Attributes:
            </label>
            <select
              id="attributeComboBox"
              className="form-control"
              value={attribute}
              onChange={(event) =>
                handleInputChange("attribute", index, event)
              }
              data-validate="true"
              title="Attribute"
            >
              <ListOption item="Choose Here" key="Choose Here" />
              {attributeNames.map((attributeName) => {
                return (
                  <ListOption
                    item={{
                      key: subModel
                        ? `${model}.${subModel}.${attributeName}`
                        : `${model}.${attributeName}`,
                      value: modelToAttributes.get(attributeName).displayName,
                    }}
                    key={attributeName}
                  />
                );
              })}
            </select>
          </div>
          <div className="col-sm-3 m-0 p-0 pr-1 form-group">
            <label htmlFor="operatorComboBox" className="col-form-label">
              Operators:
            </label>
            <select
              id="operatorComboBox"
              className="form-control"
              value={operator}
              onChange={(event) =>
                handleInputChange("operator", index, event)
              }
              data-validate="true"
              title="Operator"
            >
              <ListOption item="Choose Here" key="Choose Here" />
              {operators.map((operator) => {
                return <ListOption item={operator} key={operator} />;
              })}
            </select>
          </div>
          <div className="col-sm-3 m-0 p-0 pr-1 form-group">
            <label htmlFor="valueInput" className="col-form-label">
              Value:
            </label>
            <input
              id="valueInput"
              className="form-control"
              value={targetValue}
              onChange={(event) =>
                handleInputChange("targetValue", index, event)
              }
              title="Value"
            />
          </div>
          <div className="p-0 pl-2 pt-4 col-sm-2 align-self-center">
            <button
              type="button"
              className="btn btn-secondary btn-links mx-auto"
              aria-label="Remove"
              onClick={() => handleDeleteFilter(index)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </button>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {model && project
        ? filters.map((filter, index) => renderFilter(filter, index))
        : null}
      <div className="col-sm-12 form-group">
        <ActionButton
          id="addFilterButton"
          label="Add Filter"
          onClick={addFilter}
          disabled={disabledAddButton || filters.length > 0}
        />
      </div>
    </Fragment>
  );
}

export class Filters {
  private _filters: Array<Filter>;

  constructor(filters: Array<Filter>) {
    this._filters = filters;
  }

  get filters() {
    return this._filters;
  }

  apply(records: Array<any>, rmp: RMP) {
    if (!this._filters.length) {
      return records;
    }

    // We only have one filter now, we will extend it once
    // we want to have multiple filters
    const filter = this._filters[0];
    const operatorFilter = new OperatorFilter(filter);
    return operatorFilter.apply(records, rmp);
  }
}

export class OperatorFilter {
  private attribute: string;
  private operator: string;
  private targetValue: string;

  constructor(filter: Filter) {
    const {attribute, operator, targetValue} = filter;
    this.attribute = attribute;
    this.operator = operator;
    this.targetValue = targetValue;
  }

  apply(records: Array<any>, rmp: RMP) {
    return records.filter((record) => this.meetCriteria(record, rmp));
  }

  meetCriteria(record: any, rmp: RMP) {
    let targetValues = this.targetValue;
    const parts = this.attribute.split(".");
    let modelName: string, columnName: string;
    if (parts.length >= 3) {
      [, modelName, columnName] = parts;
    } else {
      [modelName, columnName] = parts;
    }
    let sourceValue: string = DirectScopeWidgetParser.getActualValue(
      record,
      modelName,
      columnName,
      rmp,
    )?.content;
    let result = false;
    for (let targetValue of targetValues.split("|")) {
      if (UIUtils.isNumber(sourceValue) && UIUtils.isNumber(targetValue)) {
        sourceValue = UIUtils.convertToNumber(sourceValue);
        targetValue = UIUtils.convertToNumber(targetValue);
      } else {
        sourceValue = sourceValue?.toLowerCase();
        targetValue = targetValue.toLowerCase();
      }

      switch (this.operator) {
        case "=":
          result = sourceValue === targetValue;
          break;
        case "!=":
          result = sourceValue !== targetValue;
          break;
        case ">":
          result = sourceValue > targetValue;
          break;
        case "<":
          result = sourceValue < targetValue;
          break;
        case ">=":
          result = sourceValue >= targetValue;
          break;
        case "<=":
          result = sourceValue <= targetValue;
          break;
        case "contains":
          result = sourceValue?.toString().includes(targetValue?.toString());
          break;
        default:
          throw new Error(`Unknown operator ${this.operator}`);
      }

      if (result) {
        return true;
      }
    }

    return result;
  }
}

export class Filter {
  constructor(
    public model: string,
    public attribute = "",
    public operator = "",
    public targetValue = "",
  ) {
    this.model = model;
    this.attribute = attribute;
    this.operator = operator;
    this.targetValue = targetValue;
  }

  isValid() {
    return this.attribute && this.operator && this.targetValue;
  }
}
