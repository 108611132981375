import React, { useState } from "react";
import { GridColumn as Column } from "@progress/kendo-react-grid/dist/npm/GridColumn";
import { HeaderCell } from "./header_cell";
import { MaterialAttributeCell } from "./material_attribute_cell";
import { faLink, faUnlink } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "@progress/kendo-react-grid";
import { SpecificationCell } from "./specification_cell";

export const MaterialAttributeSpecifications = ({dataSource, dropDownSource, onDataChange, readonly}) => {
  const DATA_ITEM_KEY = "id";
  const [data, setData] = useState(dataSource);

  const handleItemChange = (event) => {
    const field = event.field || "";
    const newData = data.map((item) =>
      item[DATA_ITEM_KEY] === event.dataItem[DATA_ITEM_KEY]
        ? {...item, [field]: event.value}
        : item,
    );
    setData(newData);
    onDataChange(newData);
  };

  return (
    <div className="reconciliation-table-outer-div">
      <Grid
        data={data}
        dataItemKey={DATA_ITEM_KEY}
        onItemChange={handleItemChange}
        editField="inEdit"
      >
        <Column field="materialAttribute"
                title="Material Attributes"
                cell={(props) => <MaterialAttributeCell {...props} field="name" icon={readonly ? faUnlink : faLink} />}
                headerCell={(props) => <HeaderCell {...props} rightBorder="none" />}
        />

        <Column field="specification" title="Specifications (in library)"
                cell={(props) => <SpecificationCell {...props} readonly={readonly} data={dropDownSource} /> }
                headerCell={(props) => <HeaderCell {...props} leftBorder="none" />}
        />
      </Grid>
    </div>
  );
};