"use strict";

import React from "react";
import * as PropTypes from "prop-types";
import BaseReactComponent from "../../base_react_component";


/**
 *  This class has base methods for modal windows.
 */
export class BasePopup extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    $(this.modalRef).modal({show: true, backdrop: "static", keyboard: false});
    $(this.modalRef).on("hidden.bs.modal", this.props.onHideModal);
  }

  /**
   * Add `ref={this.setModalRef}` to the first rendered div.
   *
   * @param modalRef The ref to keep track of
   */
  setModalRef(modalRef) {
    this.modalRef = modalRef;
    this.props.modalRef(modalRef);
  }

  handleCancel() {
    $(this.modalRef).modal("hide");

    if (this.props.setPopupOpened) {
      this.props.setPopupOpened(false);
    }
  }
}


BasePopup.propTypes = {
  onHideModal: PropTypes.func,
  modalRef: PropTypes.func,
  setPopupOpened: PropTypes.func, // Called with true/false when the modal window is opened/closed respectively.
};

export default BasePopup;
