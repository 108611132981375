"use strict";

import RMPChangePlugin from "./rmp_change_plugin";
import CopyCheckboxPlugin from "./copy_checkbox_plugin";
import CopyDropDownPlugin from "./copy_drop_down_plugin";
import ResetTechTransferPlugin from "./reset_tech_transfer_plugin";
import { t } from "../../../i18n/i18n";
import ProductRiskAssessmentPlugin from "./product_risk_assessment_plugin";
import CopyTextFieldPlugin from "./copy_text_field_plugin";

// i18next-extract-mark-ns-start widgets

/***
 * @enum {String}
 */
export const PLUGIN_TYPE = {
  DOC_CUSTOM_ID: "DOC_CUSTOM_ID",
  RMP_CHANGE: "RMP_CHANGE",
  COPY_BATCH_DATA: "COPY_BATCH_DATA",
  COPY_TO_IQA: "COPY_TO_IQA",
  COPY_TO_IPA: "COPY_TO_IPA",
  COPY_TO_NEW_UO: "COPY_TO_NEW_UO",
  COPY_AS_SHARED: "COPY_AS_SHARED",
  PRODUCT_RISK_ASSESSMENT_CHANGE: "PRODUCT_RISK_ASSESSMENT_CHANGE",
  RESET_TECH_TRANSFER: "RESET_TECH_TRANSFER",
};

export const CLONING_PLUGINS = {
  DOC_CUSTOM_ID: {
    plugin: CopyTextFieldPlugin,
    type: PLUGIN_TYPE.DOC_CUSTOM_ID,
    props: {
      attributeToUpdate: "customID",
      attributeLabel: "Custom ID",
      required: false,
    }
  },
  RMP_CHANGE: {
    plugin: RMPChangePlugin,
    type: PLUGIN_TYPE.RMP_CHANGE,
    props: {
      attributeToUpdate: "newRMPId"
    }
  },
  PRODUCT_RISK_ASSESSMENT_CHANGE: {
    plugin: ProductRiskAssessmentPlugin,
    type: PLUGIN_TYPE.PRODUCT_RISK_ASSESSMENT_CHANGE,
    props: {
      attributeToUpdate: "newProductRiskAssessmentType"
    }
  },
  COPY_BATCH_DATA: {
    plugin: CopyCheckboxPlugin,
    type: PLUGIN_TYPE.COPY_BATCH_DATA,
    props: {
      id: "copyBatchDataInput",
      label: t("Copy Batch/Lot data, if it exists"),
      attributeToUpdate: "cloneProcessData"
    }
  },
  COPY_TO_IQA: {
    plugin: CopyCheckboxPlugin,
    type: PLUGIN_TYPE.COPY_TO_IQA,
    props: {
      id: "cloneIntoIQAInput",
      label: t("Copy into IQA"),
      attributeToUpdate: "cloneIntoIQA",
      enabledStatusControlAttribute: "UnitOperationId",
      disabledTooltipText: t("You cannot copy the selected FQA into an IQA because there are no Unit Operations defined in the project. An IQA requires a Unit Operation to be created into.")
    }
  },
  COPY_TO_IPA: {
    plugin: CopyCheckboxPlugin,
    type: PLUGIN_TYPE.COPY_TO_IPA,
    props: {
      id: "cloneIntoIPAInput",
      label: t("Copy into IPA"),
      attributeToUpdate: "cloneIntoIPA",
      enabledStatusControlAttribute: "UnitOperationId",
      disabledTooltipText: t("You cannot copy the selected FPA into an IPA because there are no Unit Operations defined in the project. An IPA requires a Unit Operation to be created into.")
    }
  },
  COPY_TO_NEW_UO: {
    plugin: CopyDropDownPlugin,
    type: PLUGIN_TYPE.COPY_TO_NEW_UO,
    props: {
      id: "cloneToUOInput",
      label: t("UO to copy into"),
      attributeToUpdate: "UnitOperationId",
      modelToLoad: "UnitOperation",
      dataCollectionAttribute: "unitOperations",
      visibilityStatusControlAttribute: "cloneIntoIQA",
      indentationControlAttribute: "cloneIntoIQA"
    }
  },
  COPY_AS_SHARED: {
    plugin: CopyCheckboxPlugin,
    type: PLUGIN_TYPE.COPY_AS_SHARED,
    props: {
      id: "createCopiesOfComponents",
      label: t("Make new copies of Process Components and Materials"),
      infoIconText: t("If left unchecked, the system will link (share) the Process Components and Material records across the Unit Operations (original and copied). By selecting this option, the system will instead create new and unrelated copies of each Process Component and Material in the new Unit Operation."),
      attributeToUpdate: "makeCopiesOfSharedEntities",
    }
  },
  RESET_TECH_TRANSFER: {
    plugin: ResetTechTransferPlugin,
    type: PLUGIN_TYPE.RESET_TECH_TRANSFER,
    props: {
      id: "resetTechTransferFields",
      label: t("Reset Tech Transfer Fields"),
      infoIconText: t("If unchecked, the system will retain the values in the Tech Transfer Assessment for all records."),
      attributeToUpdate: "clearTechTransferAssessment",
    }
  }
};
// i18next-extract-mark-ns-stop widgets
