"use strict";

import { ServiceBase } from "../service_base";

export class MaterialLibraryService extends ServiceBase {

  create(payload) {
    let parameters = {
      action: "create",
      useTwoWayCommunication: true,
    };
    return this.sendToBackend(payload, parameters);
  }

  sync(payload) {
    let parameters = {
      action: "sync",
      useTwoWayCommunication: true,
    };
    return this.sendToBackend(payload, parameters);
  }

  swap(payload) {
    let parameters = {
      action: "swap",
      useTwoWayCommunication: true,
    };
    return this.sendToBackend(payload, parameters);
  }

  unlink(payload) {
    let parameters = {
      action: "unlink",
      useTwoWayCommunication: true,
    };

    return this.sendToBackend(payload, parameters);
  }

  unsync(payload) {
    let parameters = {
      action: "unsync",
      useTwoWayCommunication: true,
    };

    return this.sendToBackend(payload, parameters);
  }

  linkSpecification(payload) {
    let parameters = {
      action: "linkSpecification",
      useTwoWayCommunication: true,
    };

    return this.sendToBackend(payload, parameters);
  }

  unlinkSpecification(payload) {
    let parameters = {
      action: "unlinkSpecification",
      useTwoWayCommunication: true,
    };

    return this.sendToBackend(payload, parameters);
  }
}