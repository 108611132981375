/* eslint-disable radix */
/**
 * @hidden
 */
var DISABLED_TABINDEX = -1;
/**
 * @hidden
 */
var DEFAULT_TABINDEX = 0;
/**
 * @hidden
 */
export var getTabIndex = function (tabIndex, disabled, useDefaultTabIndexWhenDisabled) {
    var parsedTabIndex = typeof tabIndex === 'string' ? parseInt(tabIndex, undefined) : tabIndex;
    if (parsedTabIndex === NaN) {
        return undefined;
    }
    return parsedTabIndex !== undefined
        ? parsedTabIndex
        : disabled ?
            (useDefaultTabIndexWhenDisabled ? undefined : DISABLED_TABINDEX)
            : DEFAULT_TABINDEX;
};
