"use strict";

import React, { Component } from "react";
import { getRiskLabel, getRawRiskScore } from "../../../helpers/risk_helper";
import { ValueTooltip } from "./value_tooltip";

export class RiskTooltip extends Component {
  render() {
    let {type, rmp, attribute, value, ignoreRiskInfo} = this.props;

    if (ignoreRiskInfo) {
      value = attribute ? getRawRiskScore(type, attribute, attribute.detailedRiskLinks, false, false, true) : value;
    } else {
      if (attribute && !attribute.riskInfo) {
        throw new Error("Missing RiskInfo");
      }

      value = attribute ? attribute.riskInfo[type].value : value;
    }

    return (
      <ValueTooltip label={type} value={getRiskLabel(type, rmp, value, attribute, false, false, false, ignoreRiskInfo)} />
    );
  }
}
