import { Keys } from '@progress/kendo-react-common';
import { getFirstChildId, isIdZeroLevel, getRootParentId, isIdFirstLevel, getDirectParentId, getShortId, createId, getDirectSiblingIdForLevelZero, getItemById as getItemByIdUtil } from './itemsIdsUtils';
var NO_WHITESPACE_REGEX = /\S/;
/**
 * @hidden
 * Returns the `itemId` (string) for applicable key codes even when the id has not changed.
 * For key codes that are not applicable, returns `undefined`.
 */
export function getNewItemIdUponKeyboardNavigation(sourceItems, sourceItemId, keyCode, key, isMenuVertical, isDirectionRightToLeft) {
    var sourceItem = getItemById();
    switch (keyCode) {
        case Keys.left:
            return getIdUponLeftKey();
        case Keys.right:
            return getIdUponRightKey();
        case Keys.up:
            return getIdUponUpKey();
        case Keys.down:
            return getIdUponDownKey();
        case Keys.enter:
        case Keys.space:
            return getIdUponEnterAndSpaceKeys();
        case Keys.home:
            return getIdUponHomeKey();
        case Keys.end:
            return getIdUponEndKey();
        case Keys.esc:
            return getIdUponEscKey();
        default:
            return isSearchableKey() ? getIdUponSearchByChar() : sourceItemId;
    }
    function getIdUponLeftKey() {
        if (isMenuVertical) {
            return isDirectionRightToLeft ? getIdUponRightKeyForVerticalMenu() : getIdUponLeftKeyForVerticalMenu();
        }
        else {
            return isDirectionRightToLeft ?
                getIdUponRightKeyForHorizontalMenu() : getIdUponLeftKeyForHorizontalMenu();
        }
    }
    function getIdUponRightKey() {
        if (isMenuVertical) {
            return isDirectionRightToLeft ? getIdUponLeftKeyForVerticalMenu() : getIdUponRightKeyForVerticalMenu();
        }
        else {
            return isDirectionRightToLeft ?
                getIdUponLeftKeyForHorizontalMenu() : getIdUponRightKeyForHorizontalMenu();
        }
    }
    function getIdUponDownKey() {
        if (isMenuVertical) {
            return getNextSiblingId();
        }
        else {
            if (isIdZeroLevel(sourceItemId)) {
                return getFirstChildIdForEnabledItemOrGetSameId();
            }
            else {
                return getNextSiblingId();
            }
        }
    }
    function getIdUponUpKey() {
        if (isMenuVertical) {
            return getPrevSiblingId();
        }
        else {
            if (isIdZeroLevel(sourceItemId)) {
                return getLastChildIdForEnabledItemOrGetSameId();
            }
            else {
                return getPrevSiblingId();
            }
        }
    }
    function getIdUponEnterAndSpaceKeys() {
        if (sourceItem.disabled) {
            return sourceItemId;
        }
        else {
            return hasChildren() ? getFirstChildId(sourceItemId) : getRootParentId(sourceItemId);
        }
    }
    function getIdUponHomeKey() {
        return getSiblings()[0].id;
    }
    function getIdUponEndKey() {
        var siblings = getSiblings();
        return siblings[siblings.length - 1].id;
    }
    function getIdUponEscKey() {
        return isIdZeroLevel(sourceItemId) ? sourceItemId : getDirectParentId(sourceItemId);
    }
    function getIdUponSearchByChar() {
        var searchChar = key.toLowerCase();
        var siblings = getSiblings();
        var currentItemIndex = Number(getShortId(sourceItemId));
        var itemsToSearch = siblings.slice(currentItemIndex + 1).concat(siblings.slice(0, currentItemIndex + 1));
        var matchedSibling = itemsToSearch.find(function (item) {
            return (item.text || '').toLowerCase().startsWith(searchChar);
        });
        return matchedSibling ? matchedSibling.id : sourceItemId;
    }
    // #region Left Key Internals
    function getIdUponLeftKeyForHorizontalMenu() {
        if (isIdZeroLevel(sourceItemId)) {
            return getPrevSiblingId();
        }
        else if (isIdFirstLevel(sourceItemId)) {
            return getFirstChildIdForEnabledItemOrGetSameId(getPrevSiblingId(getRootParentId(sourceItemId)));
        }
        else {
            return getDirectParentId(sourceItemId);
        }
    }
    function getIdUponLeftKeyForVerticalMenu() {
        if (isIdZeroLevel(sourceItemId)) {
            return getLastChildIdForEnabledItemOrGetSameId();
        }
        else {
            return getDirectParentId(sourceItemId);
        }
    }
    // #endregion
    // #region Right Key Internals
    function getIdUponRightKeyForHorizontalMenu() {
        if (isIdZeroLevel(sourceItemId)) {
            return getNextSiblingId();
        }
        else {
            return getFirstChildIdForEnabledItemOrGetSameId(hasChildren() ? sourceItemId : getNextSiblingId(getRootParentId(sourceItemId)));
        }
    }
    function getIdUponRightKeyForVerticalMenu() {
        return getFirstChildIdForEnabledItemOrGetSameId(isIdZeroLevel(sourceItemId) || hasChildren() ?
            sourceItemId : getNextSiblingId(getRootParentId(sourceItemId)));
    }
    // #endregion
    // #region Utils
    function getFirstChildIdForEnabledItemOrGetSameId(itemId) {
        return getFirstOrLastChildIdForEnabledItemOrGetSameId(true, itemId);
    }
    function getLastChildIdForEnabledItemOrGetSameId(itemId) {
        return getFirstOrLastChildIdForEnabledItemOrGetSameId(false, itemId);
    }
    function isSearchableKey() {
        return key.length === 1 && NO_WHITESPACE_REGEX.test(key);
    }
    function getItemById(itemId, items) {
        if (itemId === undefined) {
            itemId = sourceItemId;
        }
        if (items === undefined) {
            items = sourceItems;
        }
        return getItemByIdUtil(itemId, items);
    }
    function hasChildren(itemId) {
        return getItemById(itemId).items.length > 0;
    }
    function getPrevSiblingId(itemId) {
        return getSiblingId(false, itemId);
    }
    function getNextSiblingId(itemId) {
        return getSiblingId(true, itemId);
    }
    function getLastChildId(itemId) {
        var itemChildren = getItemById(itemId).items;
        return itemChildren[itemChildren.length - 1].id;
    }
    function getSiblings() {
        return isIdZeroLevel(sourceItemId) ?
            sourceItems : getItemById(getDirectParentId(sourceItemId), sourceItems).items;
    }
    function getFirstOrLastChildIdForEnabledItemOrGetSameId(getFirstChild, itemId) {
        if (itemId === undefined) {
            itemId = sourceItemId;
        }
        var item = getItemById(itemId);
        if (hasChildren(itemId) && !item.disabled) {
            return getFirstChild ? getFirstChildId(itemId) : getLastChildId(itemId);
        }
        else {
            return itemId;
        }
    }
    function getSiblingId(next, itemId) {
        if (itemId === undefined) {
            itemId = sourceItemId;
        }
        if (isIdZeroLevel(itemId)) {
            return getDirectSiblingIdForLevelZero(next, itemId, sourceItems.length);
        }
        else {
            var directParentId = getDirectParentId(itemId);
            var shortId = getShortId(itemId);
            var siblingsCount = getItemById(directParentId).items.length;
            return createId(getDirectSiblingIdForLevelZero(next, shortId, siblingsCount), directParentId);
        }
    }
    // #endregion
}
