"use strict";

import * as UIUtils from "../../ui_utils";
import { getRiskLabel } from "../../helpers/risk_helper";
import { RISK_TYPE_ENUM } from "../../helpers/constants/constants";
import { getRiskAttributes } from "./utilities/risk_map_report_helper";

const RISK_TYPES_ARRAY = Object.values(RISK_TYPE_ENUM);

const changesTracked = new Set([
  UIUtils.VERSION_STATES.DRAFT,
  UIUtils.VERSION_STATES.APPROVED,
  UIUtils.VERSION_STATES.ARCHIVED,
  UIUtils.VERSION_STATES.ARCHIVED_CASCADED,
  UIUtils.VERSION_STATES.RESTORED,
  UIUtils.VERSION_STATES.RESTORED_CASCADED,
  UIUtils.VERSION_STATES.OBSOLETE
]);

/**
 * To track the risk map object version changes for visual display on Risk Map Report.
 */
export class RiskMapObjectVersionChangeTracker {

  trackVersionChanges(objectVersion, previousObjectVersion, changes, uoVersionsMap, stepVersionsMap) {
    let type = objectVersion.type.toUpperCase();
    let effectiveRMPForModelType = objectVersion.rmpForModelType;
    let previousRMPForModeType = previousObjectVersion.rmpForModelType;

    if (previousObjectVersion.name !== objectVersion.name) {
      changes.changesDescription = changes.changesDescription + `<div>- Modified name to ${UIUtils.secureString(objectVersion.name)}</div>`;
      changes.affectedRiskTypes.push(...RISK_TYPES_ARRAY);
    }

    if (previousObjectVersion.unitOperationId !== objectVersion.unitOperationId) {
      if (objectVersion.unitOperationId) {
        const newUnitOperation = uoVersionsMap[objectVersion.unitOperationId];
        const newUnitOperationName = UIUtils.getRecordLabelForDisplay("UO", newUnitOperation.unitOperationId, newUnitOperation.name);
        changes.changesDescription = changes.changesDescription + `<div>- Modified Unit Operation to ${UIUtils.secureString(newUnitOperationName)}</div>`;
      } else {
        const parentName = UIUtils.getRecordLabelForDisplay(objectVersion.parent.type, objectVersion.parent.id, objectVersion.parent.name);
        changes.changesDescription = changes.changesDescription + `<div>- Moved ${UIUtils.secureString(parentName)} under the Process</div>`;
      }

      changes.affectedRiskTypes.push(...RISK_TYPES_ARRAY);
    }

    if (previousObjectVersion.stepId !== objectVersion.stepId) {
      if (objectVersion.stepId) {
        const newStep = stepVersionsMap[objectVersion.stepId];
        const newStepName = UIUtils.getRecordLabelForDisplay("STP", newStep.stepId, newStep.name);
        changes.changesDescription = changes.changesDescription + `<div>- Modified Step to ${UIUtils.secureString(newStepName)}</div>`;
      } else {
        if (objectVersion.parent) {
          const parentName = UIUtils.getRecordLabelForDisplay(objectVersion.parent.type, objectVersion.parent.id, objectVersion.parent.name);
          changes.changesDescription = changes.changesDescription + `<div>- Moved ${UIUtils.secureString(parentName)} under the Unit Operation</div>`;
        } else {
          changes.changesDescription = changes.changesDescription + `<div>- Moved ${UIUtils.secureString(objectVersion.name)} under the Unit Operation</div>`;
        }
      }

      changes.affectedRiskTypes.push(...RISK_TYPES_ARRAY);
    }

    if (previousObjectVersion.controlStrategy !== objectVersion.controlStrategy) {
      changes.changesDescription = changes.changesDescription + `<div>- Modified control strategy to ${UIUtils.secureString(objectVersion.controlStrategy)}</div>`;
      changes.affectedRiskTypes.push(...RISK_TYPES_ARRAY);
    }

    if (UIUtils.secureString(previousObjectVersion.obligatoryCQA) !== UIUtils.secureString(objectVersion.obligatoryCQA)) {
      changes.changesDescription = changes.changesDescription + `<div>- Obligatory CQA changed to ${UIUtils.secureString(objectVersion.obligatoryCQA)}</div>`;
      changes.affectedRiskTypes.push(...RISK_TYPES_ARRAY);
    }

    if (changesTracked.has(objectVersion.currentState) && (previousObjectVersion.currentState !== objectVersion.currentState)) {
      if (objectVersion.approved) {
        changes.changesDescription = changes.changesDescription + `<div>- Approved ${type} "${UIUtils.secureString(objectVersion.name)}"</div>`;
      } else if (objectVersion.archived) {
        changes.changesDescription = changes.changesDescription + `<div>- Archived ${type} "${UIUtils.secureString(objectVersion.name)}"</div>`;
      } else if (objectVersion.restored) {
        changes.changesDescription = changes.changesDescription + `<div>- Restored ${type} "${UIUtils.secureString(objectVersion.name)}"</div>`;
      } else {
        changes.changesDescription = changes.changesDescription + `<div>- Created draft from approved version for ${type} "${UIUtils.secureString(objectVersion.name)}"</div>`;
      }

      changes.affectedRiskTypes.push(...RISK_TYPES_ARRAY);
    }

    for (let riskType of RISK_TYPES_ARRAY) {
      const riskAttributes = getRiskAttributes(riskType);
      const rawRiskAttribute = riskAttributes.rawRiskAttribute;
      const fromRiskLabel = getRiskLabel(riskType, previousRMPForModeType, previousObjectVersion[rawRiskAttribute], previousObjectVersion, false);
      const toRiskLabel = getRiskLabel(riskType, effectiveRMPForModelType, objectVersion[rawRiskAttribute], objectVersion, false);

      let hasDifferenceInRiskScoreLabeling = false;
      if (fromRiskLabel && toRiskLabel && fromRiskLabel !== toRiskLabel) {
        changes.changesDescription = changes.changesDescription + `<div>- Modified ${riskType} level from ${fromRiskLabel} to ${toRiskLabel}</div>`;
        changes.affectedRiskTypes.push(riskType);
        hasDifferenceInRiskScoreLabeling = true;
      }

      if (riskType === RISK_TYPE_ENUM.CRITICALITY && !hasDifferenceInRiskScoreLabeling) {
        const fromRiskLabel = getRiskLabel(riskType, previousRMPForModeType, previousObjectVersion[rawRiskAttribute], previousObjectVersion, true);
        const toRiskLabel = getRiskLabel(riskType, effectiveRMPForModelType, objectVersion[rawRiskAttribute], objectVersion, true);
        if (fromRiskLabel && toRiskLabel && fromRiskLabel !== toRiskLabel) {
          changes.changesDescription = changes.changesDescription + `<div>- Modified ${riskType} level from ${fromRiskLabel} to ${toRiskLabel}</div>`;
          changes.affectedRiskTypes.push(riskType);
        }
      }
    }

    let linksModifications = this.getLinksModifications(previousObjectVersion, objectVersion, changes);
    if (linksModifications !== "") {
      changes.changesDescription = changes.changesDescription + `<div>- Modified risk links as below:</div>`;
      changes.changesDescription = changes.changesDescription + `<div>${linksModifications}</div>`;
      changes.affectedRiskTypes.push(...RISK_TYPES_ARRAY);
    }
  }

  summarizeNewObjectChanges(newObjectVersion, changes) {
    let type = newObjectVersion.type.toUpperCase();
    changes.changesDescription = "<div>- Added " + type + " " + UIUtils.secureString(newObjectVersion.name) + "</div>";
    if (newObjectVersion.approved) {
      changes.changesDescription = changes.changesDescription + "<div>- Approved " + type + " " + UIUtils.secureString(newObjectVersion.name) + "</div>";
    }
    if (newObjectVersion.outgoingLinks.length > 0) {
      let newLinkNames = "";
      for (let i = 0; i < newObjectVersion.outgoingLinks.length; i++) {
        let link = newObjectVersion.outgoingLinks[i];
        changes.addedLinks.push(link);
        if (link.impact && link.uncertainty) {
          newLinkNames = newLinkNames + "<div>&nbsp;&nbsp;&nbsp;&nbsp;" + link.type.toUpperCase() + " - " + UIUtils.secureString(link.name) +
            " (Impact: " + getRiskLabel(RISK_TYPE_ENUM.IMPACT, newObjectVersion.rmpForModelType, link.impact) +
            ", Uncertainty: " + getRiskLabel(RISK_TYPE_ENUM.UNCERTAINTY, newObjectVersion.rmpForModelType, link.uncertainty) + ")</div>";
        } else {
          newLinkNames = newLinkNames + "<div>&nbsp;&nbsp;&nbsp;&nbsp;" + link.type.toUpperCase() + " - " + UIUtils.secureString(link.name) + "</div>";
        }
      }

      changes.changesDescription = changes.changesDescription + (newLinkNames !== "" ? "<div>- Linked with:" + newLinkNames + "</div>" : "");
    }

    return changes;
  }

  getLinksModifications(oldObjectVersion, newObjectVersion, changes) {
    let oldLinks = new Map();
    let newLinks = new Map();
    let linksModifications;

    // the corresponding test was failing intermittently because the order not always matched
    // enforcing an order here to ensure we don't have tooltip tests failing due to that
    // also, it makes more sense to have that sorted anyway
    const oldOutgoingLinks = this.sortLinksAlphabetically(oldObjectVersion);
    const newOutgoingLinks = this.sortLinksAlphabetically(newObjectVersion);

    for (let i = 0; i < oldOutgoingLinks.length; i++) {
      let link = oldOutgoingLinks[i];
      oldLinks.set(link.type + "-" + link.id, link);
    }

    for (let i = 0; i < newOutgoingLinks.length; i++) {
      let link = newOutgoingLinks[i];
      newLinks.set(link.type + "-" + link.id, link);
    }

    let newLinkNames = "";
    let modifiedLinkNames = "";
    if (!newObjectVersion.archived) {
      for (let i = 0; i < newOutgoingLinks.length; i++) {
        let link = newOutgoingLinks[i];
        const linkTypeAndName = this.getLinkTypeAndName(link);

        if (!oldLinks.has(link.type + "-" + link.id) || newObjectVersion.restored) {
          changes.addedLinks.push(link);
          if (!newObjectVersion.restored) {
            if (link.impact && link.uncertainty) {
              const impactLabel = getRiskLabel(RISK_TYPE_ENUM.IMPACT, newObjectVersion.rmpForModelType, link.impact);
              const uncertaintyLabel = getRiskLabel(RISK_TYPE_ENUM.UNCERTAINTY, newObjectVersion.rmpForModelType, link.uncertainty);
              const effect = this.getEffectLabel(link);
              newLinkNames = newLinkNames + `<div>&nbsp;&nbsp;&nbsp;&nbsp;${linkTypeAndName} (Impact: ${impactLabel}, Uncertainty: ${uncertaintyLabel}${effect})</div>`;
            } else {
              newLinkNames = newLinkNames + `<div>&nbsp;&nbsp;&nbsp;&nbsp;${linkTypeAndName}</div>`;
            }
          }
        }
        if (oldLinks.has(link.type + "-" + link.id)) {
          let oldLink = oldLinks.get(link.type + "-" + link.id);
          if ((link.impact && link.uncertainty && (link.impact !== oldLink.impact || link.uncertainty !== oldLink.uncertainty)) || (link?.effect !== oldLink?.effect)) {
            changes.modifiedLinks.push(link);
            const impactLabel = getRiskLabel(RISK_TYPE_ENUM.IMPACT, newObjectVersion.rmpForModelType, link.impact);
            const uncertaintyLabel = getRiskLabel(RISK_TYPE_ENUM.UNCERTAINTY, newObjectVersion.rmpForModelType, link.uncertainty);
            const effect = this.getEffectLabel(link);
            modifiedLinkNames = modifiedLinkNames + `<div>&nbsp;&nbsp;&nbsp;&nbsp;${linkTypeAndName} (Impact: ${impactLabel}, Uncertainty: ${uncertaintyLabel}${effect})</div>`;
          }
        }
      }
    }

    let removedLinkNames = "";
    if (!newObjectVersion.restored)
      for (let i = 0; i < oldOutgoingLinks.length; i++) {
        let link = oldOutgoingLinks[i];
        if (!newLinks.has(link.type + "-" + link.id) || newObjectVersion.archived) {
          changes.removedLinks.push(link);
          if (!newObjectVersion.archived) {
            if (link.impact && link.uncertainty) {
              const impactLabel = getRiskLabel(RISK_TYPE_ENUM.IMPACT, oldObjectVersion.rmpForModelType, link.impact);
              const uncertaintyLabel = getRiskLabel(RISK_TYPE_ENUM.UNCERTAINTY, oldObjectVersion.rmpForModelType, link.uncertainty);
              const effect = this.getEffectLabel(link);
              removedLinkNames = removedLinkNames + `<div>&nbsp;&nbsp;&nbsp;&nbsp;${link.type.toUpperCase()} - ${UIUtils.secureString(link.name)} (Impact: ${impactLabel}, Uncertainty: ${uncertaintyLabel}${effect})</div>`;
            } else {
              removedLinkNames = removedLinkNames + `<div>&nbsp;&nbsp;&nbsp;&nbsp;${link.type.toUpperCase()} - ${UIUtils.secureString(link.name)}</div>`;
            }
          }
        }
      }

    linksModifications = (newLinkNames !== "" ? `<div>- Linked with:${newLinkNames}</div>` : "");
    linksModifications = linksModifications + (modifiedLinkNames !== "" ? `<div>- Modified Links: ${modifiedLinkNames}</div>` : "");
    linksModifications = linksModifications + (removedLinkNames !== "" ? `<div>- Unlinked with: ${removedLinkNames}</div>` : "");

    return linksModifications;
  }

  sortLinksAlphabetically(objectVersion) {
    const sorter = (link1, link2) => {
      // TPPs and General Attributes should be sorted by ID
      if (link1.type === "TPP" || link1.type === "GA") {
        return link1.id - link2.id;
      }

      // The remaining ones get sorted by type and name
      const value1 = this.getLinkTypeAndName(link1);
      const value2 = this.getLinkTypeAndName(link2);
      return value1 > value2 ? 1 : (value1 < value2 ? -1 : 0);
    };
    return objectVersion.outgoingLinks.sort(sorter);
  }

  getLinkTypeAndName(link) {
    return `${link.type.toUpperCase()} - ${UIUtils.secureString(link.name)}`;
  }

  getEffectLabel(link) {
    return link?.effect ? `, Effect: ${link.effect}` : "";
  }
}
