"use strict";

import React from "react";
import BaseEditor from "./base_editor";

/**
 * This class is responsible for rendering text editors in the AI Import edit screen.
 */
export default class TextEditor extends BaseEditor {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (!super.shouldComponentUpdate(nextProps, nextState, nextContext)) {
      const {value} = this.props;
      const nextValue = nextProps.value;

      return value !== nextValue;
    }

    return true;
  }

  handleChange(event) {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
  }

  getTextForMarkup() {
    if (typeof this.props.confidence !== "undefined") {
      return this.props.value;
    } else {
      return super.getTextForMarkup();
    }
  }

  getEditorHighlightsClass() {
    return "input";
  }

  renderInput() {
    const {id} = this.props;

    return (
      <div className="import-data-editor-input-div">
        <input key="importDataEditorInput"
               id={id}
               disabled={this.props.disabled}
               className={"form-control import-paper-editor" + this.getEditorBorderClass()}
               value={typeof this.props.value !== "undefined" ? this.props.value : ""}
               onChange={this.handleChange} />
      </div>
    );
  }
}
