"use strict";

/**
 * Exposes a series of reusable sorting functions to be used with {@see Array.Sort}
 */
class CommonSorting {
  static stringAscending(getterFunction) {
    return (item1, item2) => {
      const value1 = getterFunction(item1);
      const value2 = getterFunction(item2);
      return value1 > value2 ? 1 : (value1 < value2 ? -1 : 0);
    };
  }

  static stringDescending(getterFunction) {
    return (item1, item2) => this.stringAscending(getterFunction)(item1, item2) * -1;
  }

  static numberAscending(getterFunction) {
    return (item1, item2) => {
      const value1 = getterFunction(item1);
      const value2 = getterFunction(item2);
      return value1 - value2;
    };
  }

  static numberDescending(getterFunction) {
    return (item1, item2) => this.numberAscending(getterFunction)(item1, item2) * -1;
  }

  /**
   * This will sort a combination of strings with an integer suffix, like for instance Cycle1, Cycle2, cycle 100, etc...
   * in ascending order based on the numerical suffix of the string. This way, Cycle2 will be sorted before cycle10.
   * @param getterFunction A function that returns the item to sort
   * @returns {function(*=, *=): (*)}
   */
  static stringAndNumberAscending(getterFunction) {
    const MEASUREMENT_INDEX_REGEX = /(.*?)(\d+)/;

    return (item1, item2) => {
      const value1 = getterFunction(item1);
      const value2 = getterFunction(item2);

      const index1Match = value1 && value1.match(MEASUREMENT_INDEX_REGEX);
      const index2Match = value2 && value2.match(MEASUREMENT_INDEX_REGEX);
      const string1 = index1Match && index1Match[1];
      const string2 = index2Match && index2Match[1];
      const index1 = index1Match && index1Match[2];
      const index2 = index2Match && index2Match[2];

      if (index1 && index2) {
        return string1 === string2
          ? parseInt(index1, 10) - parseInt(index2, 10)
          : CommonSorting.stringAscending(getterFunction)(item1, item2);
      } else {
        return CommonSorting.stringAscending(getterFunction)(item1, item2);
      }
    };
  }

  /**
   * This sorts a list of attributes by some parent record, then by type code and finally by some specified field.
   * The parent records could be sorted in any way, i.e they could be Unit Operations sorted as they are found in the
   * process explorer. It is left to whoever consumes this method to define how the parent records are sorted.
   * @param parentRecords An ordered array of Parent records. For example, this could be the UOs ordered as in the
   * process explorer.
   * @param attributeParentField A field of the sorted attributes based on which the parent record related to each
   * attribute can be accessed.
   * @param attributeSortField A field of the sorted attributes used to sort the attributes.
   * @param parentRecordIndexGetterFunc A getter function for getting a sort index for the Parent record.
   * @param typeCodeGetterFunc A getter function for getting the typeCode of the attribute.
   * @param fieldGetterFunc A getter function for getting some field of the attribute.
   * @returns {function(*=, *=): (number|number)}
   */
  static sortAttributesByParentAndTypeCodeAndSomeField(parentRecords,
                                                       attributeParentField = "unitOperation",
                                                       attributeSortField = "name",
                                                       parentRecordIndexGetterFunc = (parentRecords, attribute) => parentRecords.indexOf(attribute[attributeParentField]),
                                                       typeCodeGetterFunc = attribute => attribute.typeCode,
                                                       fieldGetterFunc = attribute => attribute[attributeSortField]) {
    return (item1, item2) => {
      const aParentRecordIndex = parentRecordIndexGetterFunc(parentRecords, item1);
      const bParentRecordIndex = parentRecordIndexGetterFunc(parentRecords, item2);
      const aTypeCode = typeCodeGetterFunc(item1);
      const bTypeCode = typeCodeGetterFunc(item2);
      const aField = fieldGetterFunc(item1);
      const bField = fieldGetterFunc(item2);

      if (aParentRecordIndex < bParentRecordIndex) {
        return -1;
      } else if (aParentRecordIndex > bParentRecordIndex) {
        return 1;
      } else {
        if (aTypeCode !== bTypeCode) {
          return aTypeCode < bTypeCode ? -1 : 1;
        } else {
          if (aField !== bField) {
            return aField < bField ? -1 : 1;
          } else {
            return 0;
          }
        }
      }
    };
  }

  /**
   * Used to sort two records.
   */
  static sortRecord(r1, r2) {
    return r1.name.localeCompare(r2.name);
  }
}

module.exports = {
  CommonSorting,
};
