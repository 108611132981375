"use strict";


import * as UIUtils from "../../../ui_utils";
import React from "react";
import BaseCopyRecordPlugin from "./base_copy_record_plugin";
import * as I18NWrapper from "../../../i18n/i18n_wrapper";
import InfoTooltip from "../../tooltips/info_tooltip";
import { PLUGIN_TYPE } from "./plugins_config";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

/**
 * A checkbox control that can be used as a plugin for the copy record popup. The user can use this
 * to turn on/off certain features related to cloning records.
 */

// i18next-extract-mark-ns-start widgets
class CopyCheckboxPlugin extends BaseCopyRecordPlugin {
  constructor(props) {
    super(props);

    //bind stuff
    this.handleChangeCheckboxValue = this.handleChangeCheckboxValue.bind(this);

    this.state = {};
  }

  componentDidMount() {
    super.componentDidMount();
    this.handleChangeValue(false);
  }

  handleChangeCheckboxValue(event) {
    this.handleChangeValue(event.target.checked);
  }

  render() {
    const {t} = this.props;
    const {
      id,
      label,
      attributeToUpdate,
      enabledStatusControlAttribute,
      visibilityStatusControlAttribute,
      disabledTooltipText,
      infoIconText
    } = this.props.configProps;

    let isDisabled = Object.prototype.hasOwnProperty.call(this.props.parent.state, enabledStatusControlAttribute)
      && !this.props.parent.state[enabledStatusControlAttribute];

    let isVisible = !Object.prototype.hasOwnProperty.call(this.props.parent.state, visibilityStatusControlAttribute)
      || !!this.props.parent.state[visibilityStatusControlAttribute];

    const asOfDate = moment(this.props.asOfDate);
    const today = UIUtils.getEndOfDayForDate(moment()).tz("UTC");
    const asOfDateStr = asOfDate.format(UIUtils.DATE_FORMAT_FOR_DISPLAY);
    let warning = "";

    if (this.props.type === PLUGIN_TYPE.COPY_AS_SHARED) {
      if (today.diff(asOfDate, "days") >= 1 && !this.state[attributeToUpdate]) {
        warning = (
          <div>
            <p>
              {t(`The following types of records will not be updated as of ${asOfDateStr} in the copy of the Unit Operation.`)}
            </p>
            <ul>
              <li>{t(`Materials`)}</li>
              <li>{t(`Process Components`)}</li>
            </ul>
            <p>
              {t(`Use the option below to make copies of these records if you want their data as of this date. Otherwise, the existing shared records will be linked to the copied Unit Operation.`)}
            </p>
          </div>
        );
      }
    }

    return isVisible ? (
      <div key={`${id}Div`} className="row">
        <div className="form-group">
          {warning ? (
            <div id="checkboxInfoDiv"
                 className={"col-sm-12 alert alert-warning copy-checkbox-plugin-warning"}
            >
              {warning}
            </div>
          ) : ""}
          <span>
            <input type="checkbox"
                   id={id}
                   disabled={isDisabled}
                   checked={!!this.state[attributeToUpdate]}
                   onChange={this.handleChangeCheckboxValue}
            />
            <span className={"col-form-label" + (isDisabled ? " copy-record-popup-disabled-plugin" : "")}>{t(label)}</span>
            {infoIconText ? (<InfoTooltip id={`${id}InfoTooltip`}
                                          verbiage={<div>
                                            {infoIconText}
                                          </div>}
            />) : ""}
          </span>
          <div id={`${id}ErrorDiv`} className="help-block with-errors" />
          {isDisabled ? <a href="javascript:void(0);"
                           data-toggle="popover"
                           data-container="body"
                           data-html="true"
                           data-content={t(disabledTooltipText)}
          >
            <FontAwesomeIcon id={`${id}InfoIcon`} icon={faInfoCircle} />
          </a> : ""}
        </div>
      </div>
    ) : "";
  }
}

export default I18NWrapper.wrap(CopyCheckboxPlugin, "widgets");
// i18next-extract-mark-ns-stop widgets
