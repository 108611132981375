"use strict";

import React, { memo, useEffect, useRef, useState } from "react";
import * as styles from "./column_filters.module.scss";
import Filter from "./filter";
import { MenuProvider } from "./menu_provider";
import { More } from "./widgets/more";
import { Clear } from "./widgets/clear";
import { Menu } from "./widgets/menu";
import { MenuItem } from "./widgets/menu_item";
import { buildFilters, stringifyFiltersForURL } from "./column_filters_utils";

const ColumnFilters = (
  {
    tableState,
    records = [],
    visibleTableColumns,
    operationsAdapter,
    operationsHandler
  }) => {

  const container = useRef();
  const [filters, setFilters] = useState([]);
  const [more, setMore] = useState([]);

  useEffect(() => {

    if (tableState?.filter && visibleTableColumns?.length) {
      const {
        filters,
        more
      } = buildFilters(container.current, tableState.filter, visibleTableColumns, operationsAdapter);

      setFilters(filters);
      setMore(more);
      stringifyFiltersForURL(tableState, filters);
    }

  }, [tableState?.filter, records, visibleTableColumns]);

  return (
    <MenuProvider>
      <div ref={container} className={styles["container"]}>
        {filters.map((filter, idx) => {
          const {field, value, fieldClass} = filter;
          return (
            <Filter key={idx}
                    field={field}
                    value={value}
                    fieldClass={fieldClass}
                    operationsHandler={operationsHandler}
            />
          );
        })}
        <More more={more} operationsHandler={operationsHandler} />
        <Clear filters={filters} onClick={() => operationsHandler.handleClearAllFilters()} />
      </div>
      <Menu>
        {more.map((filter, idx) => {
          const {field, value, fieldClass} = filter;
          return (
            <MenuItem key={idx}>
              <Filter field={field}
                      value={value}
                      fieldClass={fieldClass}
                      operationsHandler={operationsHandler}
              />
            </MenuItem>
          );
        })}
      </Menu>
    </MenuProvider>
  );

};


export default memo(ColumnFilters);