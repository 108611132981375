"use strict";

import React from "react";
import CommonSecurity from "../../../server/common/generic/common_security";
import ControlMethodFields from "../../configurableTables/fieldsConfig/assets/control_method_fields";
import ControlMethodTableAdapter from "./control_method_table_adapter";
import { ConfigurableTable } from "../../configurableTables/tables/configurable_table";
import ConfigurableTablesCustomFiltersHandler
  from "../../configurableTables/columnOperations/configurable_tables_custom_filters_handler";

export const CONTROL_METHODS_CONFIG = {
  ["Control Methods"]: {
    table: (props) => <ConfigurableTable {...props} />,
    adapter: (params) => new ControlMethodTableAdapter(params, "cmMap", "ControlMethods"),
    customFiltersHandler: new ConfigurableTablesCustomFiltersHandler(),
    fieldsConfig: new ControlMethodFields(),
    modelName: "ControlMethod",
    securityModelType: CommonSecurity.Types.CONTROL_METHOD
  },
};
