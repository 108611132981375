"use strict";

import * as UIUtils from "../ui_utils";
import React, { Fragment } from "react";
import BaseFullScreenPage from "../base_full_screen_page";
import EditablesPageTitleBar from "../widgets/pageTitleBar/editables_page_title_bar";
import NavBar from "../widgets/bars/nav_bar";
import TechTransferTopBar from "./topBar/tech_transfer_top_bar";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";
import ErrorBar from "../widgets/bars/error_bar";
import { TECH_TRANSFER_MODELS_CONFIG } from "./tech_transfer_config";
import FlyoutPanel from "../widgets/layout/flyout_panel";
import {
  getModelChangeCriteria
} from "./tech_transfer_column_value_generator";
import { PROCESS_CONSTANTS, PROJECT_TO_PROCESS_COMPARISONS } from "./tech_transfer_constants";
import TechTransferChangeCriteria from "./topBar/tech_transfer_change_criteria";
import TechTransferTableHandler from "./handlers/tech_transfer_table_handlers";
import TechTransferReviewScreenHandler from "./handlers/tech_transfer_review_screen_handlers";
import TechTransferCommonHandler from "./handlers/tech_transfer_common_handlers";
import TechTransferTopBarHandler from "./handlers/tech_transfer_top_bar_handlers";
import TechTransferExportColumnGenerator from "./export/tech_transfer_export_column_generator";
import ConfigurableTablesColumnOperationsHandler
  from "../configurableTables/columnOperations/configurable_tables_column_operations_handler";
import ConfigurableTablesColumnSelectionHandler
  from "../configurableTables/columnSelection/configurable_tables_column_selection_handler";
import TechTransferColumnOperationsAdapter from "./adapters/tech_transfer_column_operations_adapter";
import TechTransferColumnOperationsBar from "./widgets/tech_transfer_column_operations_bar";

// i18next-extract-mark-ns-start tech_transfer
export class TechTransferPage extends BaseFullScreenPage {
  constructor(props) {
    super(props);

    this.projectId = UIUtils.parseInt(UIUtils.getParameterByName("projectId"));

    const stateObject = {
      typeaheadOptions: [],
      shouldShowRightPanel: false,
      ignoredChangesInfo: {},
      techTransferUpdateInfo: {},
      viewChangeCriteria: false,
    };

    this.columnOperationsAdapter = new TechTransferColumnOperationsAdapter();

    const selectedModelType = UIUtils.getParameterByName("selectedModelType") || "Process Parameters";
    let handlerProps = {
      parent: this,
      modelsConfig: TECH_TRANSFER_MODELS_CONFIG,
      exportColumnGenerator: TechTransferExportColumnGenerator,
      projectId: this.projectId,
      columnOperationsAdapter: this.columnOperationsAdapter,
    };

    this.columnOperationsHandler = new ConfigurableTablesColumnOperationsHandler(handlerProps);
    this.columnOperationsHandler.handleDefaultColumnOperationsFromURL(stateObject);
    this.comlumnSelectionHandler = new ConfigurableTablesColumnSelectionHandler(handlerProps, true,
      "defaultTechTransferValue");

    handlerProps.columnOperationsHandler = this.columnOperationsHandler;
    this.reviewScreenHandler = new TechTransferReviewScreenHandler(handlerProps);
    this.commonHandler = new TechTransferCommonHandler(handlerProps);
    this.tableHandler = new TechTransferTableHandler(handlerProps);
    this.topBarHandler = new TechTransferTopBarHandler(handlerProps);

    this.setStateSafely({...stateObject});

    let fromProcessId = UIUtils.parseInt(UIUtils.getParameterByName("fromProcessId"));
    let toProcessId = UIUtils.parseInt(UIUtils.getParameterByName("toProcessId"));
    let projectToProcessComparisons = localStorage[PROJECT_TO_PROCESS_COMPARISONS];

    const unitOperationIdParam = UIUtils.getParameterByName("unitOperationId");
    const unitOperationId = unitOperationIdParam === "All" ? "All" : UIUtils.parseInt(unitOperationIdParam);

    if (projectToProcessComparisons && !fromProcessId && !toProcessId) {
      projectToProcessComparisons = JSON.parse(projectToProcessComparisons);
      const processComparisons = projectToProcessComparisons[this.projectId];
      if (processComparisons) {
        fromProcessId = processComparisons[PROCESS_CONSTANTS.FROM];
        toProcessId = processComparisons[PROCESS_CONSTANTS.TO];
      }
    }

    const {initializeAndLoadData} = this.commonHandler;

    if (fromProcessId && toProcessId) {
      initializeAndLoadData(fromProcessId, toProcessId, unitOperationId, selectedModelType);
    } else {
      // Lookup the available processes and pick the first available one if there's none already chosen/saved.
      new TypeaheadObjectCache("Process", this.projectId).loadOptions(() => {
        const processes = new TypeaheadObjectCache("Process", this.projectId).getOptionsFromCache();
        const firstToProcess = processes.find(process => process.SendingId);
        if (firstToProcess) {
          initializeAndLoadData(firstToProcess.SendingId, firstToProcess.id, unitOperationId, selectedModelType);
        }
      });
    }
  }

  componentDidMount() {
    document.title = "QbDVision Tech Transfer";

    super.componentDidMount();
  }

  renderPage() {
    let {project, projectId} = this;
    const {
      fromProcess,
      fromProcessId,
      toProcess,
      toProcessId,
      techTransferRows,
      orderedUOList,
      processesSelection,
      effectiveModelRMP,
      effectiveTechTransferRMP,
      processes,
      unitOperationId,
      selectedModelType,
      reviewRecord,
      shouldShowRightPanel,
      ignoredChangesInfo,
      techTransferUpdateInfo,
      visibleTableColumns,
      viewChangeCriteria,
      changeCriteria,
      isChangeCriteriaForReviewRecord,
      tableState,
    } = this.state;

    const {
      handleReviewRecord,
    } = this.tableHandler;

    const {
      handleVisibleTableColumnsChanged,
      handleResetToDefaults,
    } = this.comlumnSelectionHandler;

    const {
      handleColumnOperationsChange,
    } = this.columnOperationsHandler;

    const {
      getDefaultSorting,
    } = this.columnOperationsAdapter;

    const {
      handleTechTransferSave,
      handleHideReviewScreen,
      handleIgnoreChanges,
    } = this.reviewScreenHandler;

    const {
      handleViewChangeCriteria,
      handleExport,
      handleCloseChangeCriteriaPopup,
      handleUpdateChangeCriteria,
      handleProcessChanged,
      handleUnitOperationChange,
      handleModelTypeChange,
    } = this.topBarHandler;

    const commonProps = {
      fromProcess,
      toProcess,
      fromProcessId,
      toProcessId,
      effectiveModelRMP,
      effectiveTechTransferRMP,
      projectId,
      parent: this,
    };

    const config = TECH_TRANSFER_MODELS_CONFIG[selectedModelType];
    const {fieldsConfig, table, securityModelType,} = config || {};
    const sort = getDefaultSorting(tableState);
    const techTransferTable = config ?
      table({
        id: "techTransferTable",
        records: techTransferRows || [],
        processes,
        onReviewRecord: handleReviewRecord,
        onColumnOperationsChange: handleColumnOperationsChange,
        columnOperationsAdapter: this.columnOperationsAdapter,
        orderedUOList: orderedUOList || [],
        ignoredChangesInfo,
        techTransferUpdateInfo,
        visibleTableColumns: visibleTableColumns || [],
        selectedModelType,
        unitOperationId,
        fieldsConfig,
        tableState: {
          ...tableState,
          sort,
        },
        ...commonProps,
      }) : "";

    /*
    The review gets its value from the review record being selected. This is not the selectedModelName.
    For example, in the PRC view the PRC contains PRC, PP and MA so each one being selected for review
    has a different review screen.
     */
    const reviewConfig = reviewRecord ? TECH_TRANSFER_MODELS_CONFIG[reviewRecord.pluralizedModelName] : null;
    const TechTransferReviewScreen = reviewConfig && shouldShowRightPanel ?
      reviewConfig.review({
        reviewRecord,
        securityModelType: reviewConfig.securityModelType,
        onTechTransferSave: handleTechTransferSave,
        onHideReviewScreen: handleHideReviewScreen,
        onIgnoreChanges: handleIgnoreChanges,
        onViewChangeCriteria: handleViewChangeCriteria,
        ...commonProps,
      }) : "";

    const changeCriteriaSelectedModelType = isChangeCriteriaForReviewRecord ? reviewRecord.pluralizedModelName : selectedModelType;
    const changeCriteriaSecurityModelType = isChangeCriteriaForReviewRecord ? reviewConfig.securityModelType : securityModelType;
    const modelChangeCriteria = getModelChangeCriteria(changeCriteria, changeCriteriaSelectedModelType);

    let viewChangeCriteriaKey = UIUtils.convertToCamelCaseId(changeCriteriaSelectedModelType);
    viewChangeCriteriaKey += isChangeCriteriaForReviewRecord ? `_${reviewRecord.id}` : "";
    viewChangeCriteriaKey += "_change_criteria";

    return (
      <Fragment>
        <EditablesPageTitleBar projectId={projectId}
                               projectName={project ? project.name : ""}
                               projectDeletedAt={project ? project.deletedAt : null}
                               isDemoProject={project ? project.isDemo : ""}
                               currentState={project ? project.currentState : null}
        />
        <NavBar selected="Process Explorer"
                projectId={projectId}
        />
        <div className="tech-transfer-page-container">
          <TechTransferTopBar parent={this}
                              projectId={projectId}
                              fromProcessId={fromProcessId}
                              toProcessId={toProcessId}
                              orderedUOList={orderedUOList}
                              unitOperationId={unitOperationId}
                              selectedModelType={selectedModelType}
                              onExport={handleExport}
                              visibleTableColumns={visibleTableColumns}
                              onVisibleTableColumnsChanged={handleVisibleTableColumnsChanged}
                              onResetToDefaults={handleResetToDefaults}
                              onprocessChange={handleProcessChanged}
                              onUnitOperationChange={handleUnitOperationChange}
                              onModelTypeChange={handleModelTypeChange}
                              onViewChangeCriteria={handleViewChangeCriteria}
                              effectiveTechTransferRMP={effectiveTechTransferRMP}
                              processes={processesSelection}
          />
          <TechTransferColumnOperationsBar tableState={tableState}
                                           fromProcess={fromProcess}
                                           toProcess={toProcess}
                                           records={techTransferRows}
                                           selectedModelType={selectedModelType}
                                           visibleTableColumns={visibleTableColumns}
                                           columnOperationsAdapter={this.columnOperationsAdapter}
                                           columnOperationsHandler={this.columnOperationsHandler}
          />
          <FlyoutPanel
            id="techTransferSplitPanel"
            style={{height: "100%"}}
            shouldShowRightPanel={shouldShowRightPanel}
            maxLeftWidth={895}
            minSize={[440, 580]}
            leftPanel={
              <div className="tech-transfer-container">
                <div id="techTransfer" className="tech-transfer-inner-container">
                  <ErrorBar className="mt-2" />
                  {techTransferTable}
                </div>
              </div>
            }
            rightPanel={
              shouldShowRightPanel && reviewRecord ?
                <Fragment>
                  {TechTransferReviewScreen}
                </Fragment> : ""
            }
            onOpenInNewWindow={() => {
              console.log("TODO: Figure out what to do here.");
            }}
          />
        </div>
        {viewChangeCriteria ?
          <TechTransferChangeCriteria
            key={viewChangeCriteriaKey}
            modalRef={changeCriteriaPopup => this.changeCriteriaPopup = changeCriteriaPopup}
            onCloseChangeCriteriaPopup={handleCloseChangeCriteriaPopup}
            onUpdateChangeCriteria={handleUpdateChangeCriteria}
            selectedModelType={changeCriteriaSelectedModelType}
            securityModelType={changeCriteriaSecurityModelType}
            projectId={projectId}
            changeCriteria={modelChangeCriteria}
          /> : ""}
      </Fragment>
    );
  }
}

export default I18NWrapper.wrap(TechTransferPage, ["tech_transfer", "editor"]);
// i18next-extract-mark-ns-stop tech_transfer
