var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { parseColor } from './utils/color-parser';
import { isPresent } from './utils/misc';
import { Input } from '../input/Input';
/**
 * @hidden
 */
var HexInput = /** @class */ (function (_super) {
    __extends(HexInput, _super);
    function HexInput(props) {
        var _this = _super.call(this, props) || this;
        _this.onChange = function (event) {
            var hex = event.target.value;
            var value = parseColor(hex, 'rgba');
            _this.setState({ hex: hex });
            if (isPresent(value)) {
                _this.props.onHexChange(hex, value, event);
            }
        };
        _this.onBlur = function () {
            if (!isPresent(parseColor(_this.state.hex, 'rgba'))) {
                _this.setState({ hex: _this.state.originalHex });
            }
        };
        _this.state = { hex: _this.props.hex, originalHex: _this.props.hex };
        return _this;
    }
    HexInput.prototype.render = function () {
        return (React.createElement(Input, { value: this.state.hex, onChange: this.onChange, onBlur: this.onBlur, disabled: this.props.disabled }));
    };
    HexInput.getDerivedStateFromProps = function (props, state) {
        if (props.hex !== state.originalHex) {
            return { hex: props.hex, originalHex: props.hex };
        }
        return null;
    };
    return HexInput;
}(React.Component));
export default HexInput;
