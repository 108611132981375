/**
 * @hidden
 */
var styles = {
    button: 'k-button',
    'flat': 'k-flat',
    'outline': 'k-outline',
    'clear': 'k-button-clear',
    'primary': 'k-primary',
    'state-selected': 'k-selected',
    'button-icon': 'k-button-icon',
    'button-icontext': 'k-button-icontext',
    'state-disabled': 'k-disabled',
    'group-start': 'k-group-start',
    'group-end': 'k-group-end',
    'button-group': 'k-button-group',
    'button-group-stretched': 'k-button-group-stretched',
    'ltr': 'k-ltr',
    'rtl': 'k-rtl'
};
// const notDisabled = ':not(.k-disabled):not([disabled]):not([disabled="true"])';
var noInnerButton = ':not(.k-dropdownlist button):not(.k-colorpicker button):not(.k-split-button .k-split-button-arrow)';
/**
 * @hidden
 */
export var toolbarButtons = [
    'button' + noInnerButton,
    '.k-button-group > button' + noInnerButton,
    '.k-dropdownlist',
    '.k-colorpicker'
];
/**
 * @hidden
 */
export default {
    styles: styles
};
