"use strict";

import PropTypes from "prop-types";
import React from "react";
import BaseReactComponent from "../../base_react_component";
import moment from "moment";
import * as UIUtils from "../../ui_utils";

/**
 * Formats a date value for display as a short date in the UI, but showing the long date size
 * as the tooltip for that element.
 */
export class FormattedDate extends BaseReactComponent {
  render() {
    const {value} = this.props;
    let displayDate = "";
    let tooltipDate = "";
    let rawDate = value || "";

    if (value) {
      displayDate = moment(rawDate).isValid()
        ? moment(rawDate).format(UIUtils.DATE_FORMAT_FOR_DISPLAY)
        : rawDate;

      tooltipDate = moment(rawDate).isValid()
        ? moment(rawDate).format(UIUtils.LONG_DATE_FORMAT_FOR_DISPLAY)
        : rawDate;
    }

    return (
      displayDate
        ? <span title={tooltipDate}>{displayDate}</span>
        : ""
    );
  }
}

FormattedDate.propTypes = {
  value: PropTypes.any,
  displayFormat: PropTypes.string,
};

FormattedDate.defaultProps = {
  displayFormat: UIUtils.DATE_FORMAT_FOR_DISPLAY,
};
