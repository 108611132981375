"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import BasePageTitleBar from "./base_page_title_bar";
import { Trans } from "react-i18next";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { getProjectFromCache } from "../../utils/project_helper";

/* This shows the title bar for all project records*/

// i18next-extract-mark-ns-start widgets
class EditablesPageTitleBar extends BasePageTitleBar {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    let shouldUpdate = super.shouldComponentUpdate(nextProps, nextState);
    if (!shouldUpdate) {
      shouldUpdate = nextProps.projectName !== this.props.projectName ||
        nextProps.projectDeletedAt !== this.props.projectDeletedAt ||
        nextProps.projectId !== this.props.projectId ||
        nextProps.isDemoProject !== this.props.isDemoProject ||
        nextProps.primaryName !== this.props.primaryName ||
        nextProps.isProjectsPage !== this.props.isProjectsPage;
    }

    return shouldUpdate;
  }


  renderTitleBar() {
    let {projectName, projectId, projectDeletedAt, isDemoProject, t} = this.props;
    let isArchived = false;
    let primaryName = this.props.primaryName || t("Projects");
    const thisProject = getProjectFromCache(projectId);

    if (projectName && projectId) {
      isArchived = !!projectDeletedAt;
      primaryName = (
        <Fragment>
          <Trans t={t}>
            {{recordLabel: UIUtils.getRecordCustomLabelForDisplay({typeCode: t("PRJ"), id: projectId, name: projectName}, {isTypeCodeTranslated: true, useCustomId: false})}}
            <sup className="page-title-bar-title-superscript">{{demoLabel: isDemoProject ? t("demo") : ""}}</sup>
          </Trans>
        </Fragment>
      );
    } else if (projectId && projectId !== -1 && thisProject) {
      let match = /PRJ-\d+ - (.*)/.exec(thisProject.label);
      projectName = match[1];
      primaryName = (
        <Fragment>
          <Trans t={t}>
            {{recordLabel: UIUtils.getRecordCustomLabelForDisplay({typeCode: t("PRJ"), id: thisProject.id, name: projectName}, {isTypeCodeTranslated: true, useCustomId: false})}}
            <sup className="page-title-bar-title-superscript">{{demoLabel: thisProject.isDemo ? t("demo") : ""}}</sup>
          </Trans>
        </Fragment>
      );
      isArchived = !!thisProject.deletedAt;
    } else if (projectName) {
      primaryName = projectName;
    }

    return (
      <div className={"page-title-bar-title" + (this.props.isProjectsPage ? " page-title-bar-title-project" : "") +
      (primaryName === "Projects" ? " page-title-bar-title-center" : "")} id="pageTitleBar"
      >
        <span className="page-title-bar-breadcrumbs">
          {projectName ? (
            <a href="/projects/list.html" id="projectsLinkInTitleBar">&larr;</a>
          ) : ""}
        </span>
        <span className="page-title-bar-primary"
              id="pageTitleBarPrimary"
        >
              {primaryName}
            </span>
        {isArchived ? (
          <div className="page-title-bar-archived-stamp" id="pageTitleBarArchivedStamp">
            [{t("ARCHIVED")}]
          </div>
        ) : ""}
      </div>
    );
  }
}

export default I18NWrapper.wrap(EditablesPageTitleBar, "widgets");
// i18next-extract-mark-ns-stop widgets
