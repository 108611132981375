"use strict";

import "./qbd_editor_preview.module.scss";

import React, {useState, useEffect, forwardRef, useImperativeHandle} from "react";
import * as DocumentTransferHelper from "../helpers/document_transfer_helper";
import {FILE_STATUS} from "../helpers/document_transfer_helper";
import * as UIUtils from "../ui_utils";
import * as TableOfContentsSetter from "./utils/setter/table_of_contents_setter";
import {FileType, GENERIC_ERROR_MESSAGE} from "./common/constants";
import {PDFExporter} from "./utils/exporter/pdf_exporter";
import {WordExporter} from "./utils/exporter/word_exporter";
import EditorParser from "./utils/parser/parser";
import {setNumberedListItems} from "./utils/setter/numbered_list_item_setter";
import {SmartContent} from "./common/types";
import {DocumentRecord} from "../common/models/document";
import {Project} from "../common/models/project";
import {Process} from "../common/models/process";
import {PAGE_ORIENTATION, getCurrentPageOrientation} from "./tools/page_orientation";

type EditorPreviewProps = {
  editorContent: string;
  documentRecord: DocumentRecord;
  smartContent: SmartContent;
  rmp: any;
  project: Project;
  process: Process;
  className: string;
  deferRender?: boolean;
};

// eslint-disable-next-line no-unused-vars
type UploadFileCallback = (documentRecord: any) => void;

const EditorPreview = forwardRef((props: EditorPreviewProps, ref) => {
  const {
    editorContent,
    documentRecord,
    smartContent,
    deferRender,
    rmp,
    project,
    className,
    process
  } = props;

  // TO-DO Uncomment when https://cherrycircle.atlassian.net/browse/QI-6149 is implemented
  /*useEffect(() => {
    const node = document.querySelector<HTMLElement>("#qbd-editor-preview");
    TableOfContentsSetter.setPageNumber(node.firstElementChild as HTMLElement);
  }, [editorContent, smartContent]);*/

  const [htmlContent, setHtmlContent] = useState("");

  useImperativeHandle(ref, () => ({
    async exportToFile(fileType: FileType) {
      const exportPageOrientation = getCurrentPageOrientation();
      switch (fileType) {
        case FileType.PDF: {
          UIUtils.showLoadingImage();

          setTimeout(async () => {
            // Only set the page number in the ToC if it has not been set before
            let node = document.querySelector<HTMLElement>("#qbd-editor-preview");
            const tableOfContentItems = Array.from(
              document.querySelectorAll(".table-of-content-item")
            );
            const somePageElement = tableOfContentItems[0]?.querySelector<HTMLDivElement>(".table-of-content-page-number");
            if (!somePageElement?.innerText) {
              await TableOfContentsSetter.setPageNumber(
                node.firstElementChild as HTMLElement
              );
            }

            // Then export the pdf
            node = document.querySelector<HTMLElement>("#qbd-editor-preview");
            await new PDFExporter().export(
              node.firstElementChild as HTMLElement,
              `${documentRecord.name}.pdf`,
              exportPageOrientation === PAGE_ORIENTATION.LANDSCAPE
            );
            UIUtils.hideLoadingImage();
          }, 100);

          break;
        }
        case FileType.DOC:
          UIUtils.showLoadingImage();
          setTimeout(async () => {
            new WordExporter().export(
              getHTMLContent(FileType.DOC),
              `${documentRecord.name}.doc`,
              exportPageOrientation === PAGE_ORIENTATION.LANDSCAPE
            );
            UIUtils.hideLoadingImage();
          });
          break;
        default:
          throw new Error(`Format Not Supported ${fileType}`);
      }
    },
    async uploadFile(documentRec, callback?: UploadFileCallback) {
      // For some reason the loading indicator disappears when generating the pdf, so we just show it again.
      const BIG_PDF_LOADING_MESSAGE = "Generating the PDF document. Please hold on, this may take a few minutes.";
      UIUtils.setHideLoadingOnAjaxStop(false);
      UIUtils.setLoadingDisabled(false);
      UIUtils.showLoadingImage(BIG_PDF_LOADING_MESSAGE);

      setTimeout(async () => {
        let node = document.querySelector<HTMLElement>("#qbd-editor-preview");
        node = await TableOfContentsSetter.setPageNumber(
          node.firstElementChild as HTMLElement
        );

        UIUtils.setHideLoadingOnAjaxStop(false);
        UIUtils.setLoadingDisabled(false);
        UIUtils.showLoadingImage(BIG_PDF_LOADING_MESSAGE);

        setTimeout(async () => {
          const dataUri = await PDFExporter.getDataUri(node, false);

          UIUtils.setHideLoadingOnAjaxStop(false);
          UIUtils.setLoadingDisabled(false);
          UIUtils.showLoadingImage(BIG_PDF_LOADING_MESSAGE);
          const base64Response = await fetch(dataUri);
          const blob = await base64Response.blob();
          const file = new File([blob], `${documentRec.name}.pdf`, {
            type: "application/pdf",
          });
          const fileData = {
            linkType: "",
            S3TmpKey: "",
            S3TmpVersion: "",
            xhr: null,
            link: "",
            linkVersion: "",
            fileName: "",
            progress: 0,
            fileStatus: FILE_STATUS.NOT_SPECIFIED,
            size: "",
            documentContentVersionId:
            documentRec?.documentContent?.LastVersionId,
          };
          UIUtils.showLoadingImage();
          DocumentTransferHelper.handleUpload(
            file,
            fileData,
            null,
            UIUtils.clearError,
            UIUtils.showError,
            () => {
            },
            (fileData) => handleUpdateFileData(fileData, callback)
          );
        });
      });
    },
  }));

  const handleUpdateFileData = (fileData, callback?: UploadFileCallback) => {
    if (fileData.fileStatus === FILE_STATUS.UPLOADED) {
      if (callback) {
        callback(fileData);
      }
    }
  };

  useEffect(() => {
    setHtmlContent(getHTMLContent());
  }, [smartContent, deferRender, editorContent]);

  useEffect(() => {
    const targetNode = document.getElementById("qbd-editor-preview");
    const handleMouseEnter = (element: HTMLSpanElement) => {
      const errorExplanationTooltip = document.getElementById(
        "error-explanation-tooltip",
      );
      if (errorExplanationTooltip) {
        return;
      }

      const dataError = element.getAttribute("data-error");
      if (!dataError) {
        return;
      }

      const elementRect = element.getBoundingClientRect();
      const errorTooltip = document.createElement("div");
      errorTooltip.id = "error-explanation-tooltip";
      // We want to show the tooltip in the middle of the element
      errorTooltip.style.top = `${elementRect.top - 55}px`;
      // The errorTooltip width is 400px, so we need to subtract 200 here
      errorTooltip.style.left = `${
        elementRect.left + elementRect.width / 2 - 200
      }px`;
      errorTooltip.innerText = dataError;
      targetNode.appendChild(errorTooltip);
    };

    const handleMouseLeave = () => {
      const errorExplanationTooltip = document.getElementById(
        "error-explanation-tooltip",
      );
      if (errorExplanationTooltip) {
        errorExplanationTooltip.remove();
      }
    };

    // We use MutationObserver to listen to the change for the mutation childList. We will find all the
    // error elements and add mouseenter and mouseleave event, so we can show the explanation tooltip
    let errorElements: Array<HTMLSpanElement> = [];
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          errorElements = Array.from<HTMLSpanElement>(
            document.querySelectorAll(".smart-content-error"),
          );
          for (const errorElement of errorElements) {
            errorElement.addEventListener("mouseenter", () =>
              handleMouseEnter(errorElement),
            );
            errorElement.addEventListener("mouseleave", handleMouseLeave);
          }
        }
      });
    });
    const config = {attributes: true, childList: true, subtree: true};
    observer.observe(targetNode, config);

    return () => {
      observer.disconnect();
      errorElements.forEach((errorElement) => {
        errorElement.removeEventListener("mouseenter", () =>
          handleMouseEnter(errorElement),
        );
        errorElement.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []);

  const getHTMLContent = (fileType: FileType = FileType.PDF): string => {
    if (deferRender) {
      return "";
    }

    try {
      return setNumberedListItems(
        EditorParser.parseHTMLAndReplaceWithValue(
          editorContent,
          smartContent,
          rmp,
          project,
          process,
          fileType,
        ),
      );
    } catch (error) {
      console.error(error);
      return GENERIC_ERROR_MESSAGE;
    }
  };

  return (
    <div id="qbd-editor-preview">
      <div
        className={className}
        dangerouslySetInnerHTML={{
          __html: htmlContent,
        }}
      ></div>
    </div>
  );
});

export default EditorPreview;
