"use strict";

import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import {
  Editor as TelerikEditor,
  EditorChangeEvent,
  EditorExecuteEvent,
  EditorMountEvent,
  EditorPasteEvent,
  EditorTools,
  EditorToolsSettings,
  EditorUtils,
  ProseMirror,
} from "@progress/kendo-react-editor";
import * as UIUtils from "../ui_utils";
import InsertImage from "./tools/insert_image_tool";
import DeleteWidget from "./tools/delete_widget_tool";
import FormatBlock from "./tools/format_block";
import PageBreak, {NON_EDITABLE_PAGE_BREAK} from "./tools/page_break_tool";
import PageOrientationTool from "./tools/page_orientation";
import FontSize from "./tools/font_size";
import NumberedList from "./tools/numbered_list_tool";
import setDataNumberedList from "./plugins/set_data_numbered_list";
import editorKeyMap from "./plugins/key_map";
import TableCellColor from "./tools/table_cell_color";
import {COLOR_PALETTE} from "./common/constants";
import ToggleSideMenuTool from "./tools/toggle_side_menu_tool";
import {isInWidget, WIDGET_NODE} from "./common/editorSchemas/widget_node";
import {NON_EDITABLE_QBD_FIELD_NODE} from "./common/editorSchemas/qbd_field_node";
import {WIDGET_KIND} from "./components/sideMenu/widget/widget_constants";
import {
  VerticalAlignTopTool,
  VerticalAlignMiddleTool,
  VerticalAlignBottomTool,
} from "./tools/vertical_align_tool";
import {ToolProps} from "./common/types";
import BackColorProps = EditorTools.BackColorProps;
import ForeColorProps = EditorTools.ForeColorProps;
import {
  Node,
  NodeSpec,
  EditorView as EditorViewType,
} from "@progress/kendo-editor-common";

const {
  Bold,
  Italic,
  Underline,
  AlignLeft,
  AlignCenter,
  AlignRight,
  Indent,
  Outdent,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteColumn,
  DeleteRow,
  MergeCells,
  SplitCell,
  ForeColor,
  BackColor,
} = EditorTools;
const { Schema, EditorView, EditorState } = ProseMirror;

type EditorProps = {
  editorContent: string;
  hideToolbar?: boolean;
  oneLineOnly?: boolean;
  onToggleMenu?: () => void;
  // eslint-disable-next-line no-unused-vars
  onChange?: (event: EditorChangeEvent) => void;
  // eslint-disable-next-line no-unused-vars
  onMount?: (view: EditorViewType) => void;
  // eslint-disable-next-line no-unused-vars
  onExecute?: (view: EditorViewType) => void;
  // eslint-disable-next-line no-unused-vars
  onFocus?: (view: EditorViewType) => boolean;
  // eslint-disable-next-line no-unused-vars
  onBlur?: (view: EditorViewType) => boolean;
};

/**
 * The text editor with modified schema and plugins from Telerik editor
 */
const Editor = forwardRef((props: EditorProps, ref) => {
  const {editorContent, hideToolbar, oneLineOnly, onToggleMenu, onChange, onMount, onExecute, onFocus, onBlur} = props;
  const editor = useRef<TelerikEditor>();
  const selectedNodePosRef = useRef<number>(null);

  useImperativeHandle(ref, () => ({
    getView,
    getEditorContent,
    resetSelectedListNodeItem,
    updateEditorState(editorContent: string) {
      const domNode = new DOMParser().parseFromString(
        editorContent,
        "text/html",
      );
      const {view} = editor.current;
      let {tr} = view.state;
      view.state.doc.descendants((node, pos) => {
        const {attrs} = node;
        if (attrs.class?.includes("image-skeleton")) {
          const newClassName = attrs.class.replace("image-skeleton", "");
          tr = tr.setNodeAttribute(pos, "class", newClassName);
        }

        if (node.type.name === "image") {
          const filedata = node.attrs.filedata;
          const imgNode = domNode.querySelector<HTMLImageElement>(
            `img[filedata='${filedata}']`,
          );
          if (imgNode?.src) {
            tr = tr.setNodeAttribute(pos, "src", imgNode.src);
          }
        }
      });

      if (tr.docChanged) {
        tr = tr.setMeta("shouldNotUpdate", true);
        view.dispatch(tr.setMeta("addToHistory", false));
      }
    },
  }));

  const getEditorContent = () => {
    const {view} = editor.current;
    return EditorUtils.getHtml(view.state);
  };

  const getView = () => {
    return editor?.current?.view;
  };

  const handleClickOn = (
    view: EditorViewType,
    _pos: number,
    node: Node,
    nodePos: number,
    _event: MouseEvent,
    direct: boolean,
  ) => {
    if (!direct || !view) {
      return;
    }

    resetSelectedListNodeItem(view);
    if (node.type && node.type.name === "list_item") {
      view.dispatch(
        view.state.tr
          .setNodeAttribute(nodePos, "selected", "true")
          .setMeta("addToHistory", false),
      );
      selectedNodePosRef.current = nodePos;
    }
  };

  const handleKeyDown = (view: EditorViewType, e: KeyboardEvent) => {
    if (oneLineOnly && e.key === "Enter") {
      return true;
    }

    resetSelectedListNodeItem(view);
  };

  const resetSelectedListNodeItem = (view: EditorViewType) => {
    if (selectedNodePosRef.current) {
      const node = view.state.doc.nodeAt(selectedNodePosRef.current);
      if (node && !node.isTextblock && !(node.type?.name === "text")) {
        view.dispatch(
          view.state.tr
            .setNodeAttribute(selectedNodePosRef.current, "selected", "false")
            .setMeta("addToHistory", false),
        );
      }
      selectedNodePosRef.current = null;
    }
  };

  const handleMount = (event: EditorMountEvent) => {
    const {viewProps} = event;
    const {schema} = viewProps.state;

    let nodes = schema.spec.nodes;
    // Add more attributes for schema of some nodes
    const NODE_NAME_TO_ATTRIBUTES = {
      [schema.nodes.list_item.name]: {
        selected: {default: "false"},
        data: {default: null},
      },
      [schema.nodes.ordered_list.name]: {
        continuous: {default: "false"},
        prefixnearest: {default: "false"},
        dynamiclist: {default: null},
        uuid: {default: null},
        level: {default: "0"},
        dependon: {default: null},
      },
      [schema.nodes.image.name]: {
        filedata: {default: null},
      },
    };
    for (const [nodeName, attrs] of Object.entries(NODE_NAME_TO_ATTRIBUTES)) {
      const node = nodes.get(nodeName);
      node.attrs = {
        ...node.attrs,
        ...attrs,
      };
    }

    const customNodes: Array<NodeSpec> = [
      NON_EDITABLE_QBD_FIELD_NODE,
      WIDGET_NODE,
      NON_EDITABLE_PAGE_BREAK,
    ];
    for (const customNode of customNodes) {
      nodes = nodes.addToEnd(customNode.name, customNode);
    }
    const customSchema = new Schema({ nodes: nodes, marks: schema.spec.marks });
    const doc = EditorUtils.createDocument(customSchema, editorContent);
    const plugins = [
      setDataNumberedList(),
      editorKeyMap(),
      ...EditorUtils.tableResizing(),
      EditorUtils.imageResizing(),
      ...viewProps.state.plugins,
    ];
    const view = new EditorView(
      {mount: event.dom},
      {
        ...event.viewProps,
        state: EditorState.create({
          doc,
          plugins,
        }),
        handleClickOn,
        handleKeyDown,
        handleDOMEvents: {
          focus: (view) => {
            if (onFocus) {
              return onFocus(view);
            }
            return false;
          },
          blur: (view: EditorViewType) => {
            if (onBlur) {
              return onBlur(view);
            }
            return false;
          }
        }
      },
    );
    // @ts-ignore
    onMount && onMount(view);
    return view;
  };

  /**
   * Fires each time the Editor is about to apply a transaction. To prevent the
   * transaction, return false
   * @param event
   * @returns {boolean}
   */
  const handleExecute = (event: EditorExecuteEvent): boolean => {
    const {target, state, transaction} = event;
    const {selection, doc} = state;

    // A hacking solution to get the latest editor view. We put it the setTimeout,
    // so it will run after the editor actually update its state
    setTimeout(() => {
      // @ts-ignore
      onExecute && onExecute(target.view);
    });

    // The method handleExecute will be fired when the selection is changed as well.
    // We only want to check when the actual content changes and the last node
    // is Footer
    if (
      state.doc.eq(transaction.doc) ||
      (doc.lastChild &&
        doc.lastChild.attrs &&
        doc.lastChild.attrs.kind !== WIDGET_KIND.Footer)
    ) {
      return true;
    }

    // If the meta is not empty, it means we are changing text style, and we want
    // to allow the transaction is executed for that action
    // @ts-ignore
    if (!UIUtils.isEmpty(transaction.meta)) {
      return true;
    }

    // We don't allow user to enter anything before and after the table in the table widget
    if (
      isInWidget(event, WIDGET_KIND.Table) &&
      (selection?.$anchor?.nodeAfter?.type?.name === "table" ||
        selection?.$anchor?.nodeBefore?.type?.name === "table")
    ) {
      return false;
    }

    // If there are Footer and Header, we cannot insert anything before Header
    // and after Footer
    return !(
      selection.from === 0 ||
      selection.to === 0 ||
      selection.from === doc.content.size ||
      selection.to === doc.content.size
    );
  };

  /**
   * Handle onChange event
   * @param event
   */
  const handleChange = (event: EditorChangeEvent) => {
    const {transaction} = event;
    let shouldNotifyOnChange = true;
    // @ts-ignore
    const isShouldNotUpdate = transaction.meta.shouldNotUpdate;
    // @ts-ignore
    const firstStepAttr = transaction.steps.length && transaction.steps[0].attr;
    if (isShouldNotUpdate || firstStepAttr === "selected") {
      shouldNotifyOnChange = false;
    }

    if (shouldNotifyOnChange) {
      // A hacking solution to get the latest editor view. We put it the setTimeout,
      // so it will run after the editor actually update its state
      setTimeout(() => {
        onChange && onChange(event);
      });
    }
  };

  /**
   * Fires each time the Editor is about to insert pasted content
   * @param event
   * @returns {string|void}
   */
  const handlePasteHtml = (event: EditorPasteEvent): string | void => {
    const {pastedHtml} = event;
    // We allow to have only 1 TOC, so we don't allow user to paste anything
    // that contains a TOC
    const content = getEditorContent();
    if (
      content.includes("kind=\"Table of Contents\"") &&
      pastedHtml.includes("kind=\"Table of Contents\"")
    ) {
      return "";
    }

    // We want to copy and paste smart content and widget
    if (
      pastedHtml.includes("class=\"qbd-output") ||
      pastedHtml.includes("class=\"widget")
    ) {
      return pastedHtml;
    }

    const pasteSettings = {
      convertMsLists: true,
      stripTags: "a|pre|code",
      attributes: {
        "*": EditorUtils.removeAttribute,
      },
    };

    return EditorUtils.pasteCleanup(
      EditorUtils.sanitize(pastedHtml),
      pasteSettings,
    );
  };

  const CustomForeColor = (props: ForeColorProps) => (
    <ForeColor
      {...props}
      colorPickerProps={{
        ...EditorToolsSettings.foreColor.colorPickerProps,
        paletteSettings: {palette: COLOR_PALETTE},
        defaultValue: "#000000",
      }}
    />
  );

  const CustomBackColor = (props: BackColorProps) => (
    <BackColor
      {...props}
      colorPickerProps={{
        ...EditorToolsSettings.backColor.colorPickerProps,
        paletteSettings: {palette: COLOR_PALETTE},
        defaultValue: "#FFFFFF",
      }}
    />
  );

  const CustomNumberedList = (props: ToolProps) => (
    <NumberedList {...props} selectedNodePos={selectedNodePosRef.current} />
  );
  const CustomToggleSideMenuTool = (props: ToolProps) => (
    <ToggleSideMenuTool {...props} onToggleMenu={onToggleMenu} />
  );
  const tools = hideToolbar ? [] : [
    [Bold, Italic, Underline],
    [AlignLeft, AlignCenter, AlignRight],
    [Indent, Outdent, CustomNumberedList],
    [FormatBlock, FontSize, CustomForeColor, CustomBackColor],
    [InsertImage, CustomToggleSideMenuTool, PageBreak, DeleteWidget],
    [
      InsertTable,
      AddRowBefore,
      AddRowAfter,
      AddColumnBefore,
      AddColumnAfter,
      DeleteRow,
      DeleteColumn,
      MergeCells,
      SplitCell,
      TableCellColor,
      VerticalAlignTopTool,
      VerticalAlignMiddleTool,
      VerticalAlignBottomTool,
    ],
    [PageOrientationTool],
  ];

  return (
    <TelerikEditor
      tools={tools}
      contentStyle={{}}
      defaultEditMode="div"
      ref={editor}
      onMount={handleMount}
      onChange={handleChange}
      onExecute={handleExecute}
      onPasteHtml={handlePasteHtml}
    />
  );
});

export default Editor;
