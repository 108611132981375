"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import * as DocumentTransferHelper from "../../../helpers/document_transfer_helper";
import { FILE_STATUS } from "../../../helpers/document_transfer_helper";
import TableTextBox from "./widgets/table_text_box";
import { getLabelTooltip } from "./helpers/document_bulk_add_table_renderer";
import { TABLE_FIELDS, VERSION_REGEX, FIELD_NAMES } from "./constants/constants";
import TableDatePicker from "./widgets/table_date_picker";
import { DO_NOTHING } from "../../../dashboard/constants/bulk_operations_constants";

import { BulkUploadService } from "../../../services/bulkOperations/bulk_upload_service";
import {
  BulkOperationsStatusDisplayService
} from "../../../services/bulkOperations/bulk_operations_status_display_service";
import { BulkDocumentAddMessageHandler } from "../../../services/bulkOperations/bulk_document_add_message_handler";
import CommonDocuments from "../../../../server/common/editables/common_documents";

import DocumentBulkAddTableBase from "./document_bulk_add_table_base";
import { DocumentBulkAddTableFieldValidator } from "./helpers/document_bulk_add_table_field_validator";
import { DocumentBulkAddTableFetcher } from "./helpers/document_bulk_add_table_fetcher";

const DOCUMENT_TYPES = [
  "APL", "AGR", "BUS",
  "DWG", "FRM", "FORM",
  "FRS", "LST", "MEM",
  "PLN", "POL", "PSP",
  "PRO", "RPT", "SPC",
  "SOP", "SRS", "STU",
  "URS", "WRK", "DOC",
  "TRN", "LIST",
];

/**
 * This class is what shows the bulk add documents information.
 */

export default class DocumentBulkAddTable extends DocumentBulkAddTableBase {

  constructor(props) {
    super(props, "documentBulkAddTable");

    this.documents = [];
    this.uploadedDocuments = [];
    this.validator = new DocumentBulkAddTableFieldValidator();
    this.dataFetcher = new DocumentBulkAddTableFetcher();

    this.updateParent = this.updateParent.bind(this);
    this.handleFileUploadChange = this.handleFileUploadChange.bind(this);
    this.validateAndFetchTableData = this.validateAndFetchTableData.bind(this);
    this.handleReceivedPreSignedURL = this.handleReceivedPreSignedURL.bind(this);

    this.statusService = new BulkOperationsStatusDisplayService(this.handleReceivedPreSignedURL, DO_NOTHING);
    this.messageHandler = new BulkDocumentAddMessageHandler(DO_NOTHING, this.statusService);
    this.bulkUploadService = new BulkUploadService(this.messageHandler, this.statusService);
  }

  handleReceivedPreSignedURL(message) {
    if (message && message.url) {
      let document = this.documents.find(document => document.uuid === message.uuid);
      if (document && !this.uploadedDocuments.includes(document.uuid)) {
        const {file} = document;

        document.linkType = "Attachment";
        document.fileName = file.name;
        document.size = UIUtils.formatBytes(file.size);
        document.lastModified = file.lastModified;

        DocumentTransferHelper.upload(document, message.url, document.file, null,
          UIUtils.clearError, this.props.onShowError, DO_NOTHING, this.handleFileUploadChange);
      }
    }
  }

  bulkUploadDocuments(documents) {
    let instances = documents.map(document => {
      return {
        fileName: document.file.name,
        S3TmpKey: "",
        S3TmpVersion: "",
        uuid: document.uuid,
      };
    });

    this.bulkUploadService.bulkUpload({
      instances,
      operation: "put",
    }).catch(UIUtils.defaultFailFunction);
  }

  /**
   * This function gets all table using jQuery as this table never refreshes for performance.
   * @returns {Array} An array of documents with data from the UI
   */
  validateAndFetchTableData() {
    let records = this.table.rows().data().toArray();
    let instances = [];

    for (let i = 0; i < records.length; i++) {

      let record = records[i];
      let document = this.documents.find(document => document.uuid === record.uuid);

      let rowIndex = this.UUIDToRowIndex.get(record.uuid);
      this.validator.rowIndex = rowIndex;
      this.dataFetcher.rowIndex = rowIndex;

      this.validator.validateVersion();
      this.validator.validateEffectiveDate();
      this.validator.validateDocumentName();

      instances.push({
        ...document,
        fileName: this.dataFetcher.getText(FIELD_NAMES.FILE_NAME),
        effectiveDate: this.dataFetcher.effectiveDate,
        name: this.dataFetcher.getValue(FIELD_NAMES.DOCUMENT_NAME),
        customID: this.dataFetcher.getValue(FIELD_NAMES.CUSTOM_ID),
        version: this.dataFetcher.getValue(FIELD_NAMES.VERSION),
        type: this.dataFetcher.getValue(FIELD_NAMES.TYPE),
        category: this.dataFetcher.getValue(FIELD_NAMES.CATEGORY),
      });
    }

    return instances;
  }

  /**
   * This function updates the parent with data from the UI. Parent is updated
   * every time there is a change in the UI.
   */
  updateParent() {

    let {parent} = this.props;
    let isMounted = parent.isMounted();

    // Make sure both table exists and loaded and as well the parent is mounted
    if (this.table && isMounted) {
      this.props.onChildrenChange({
        documents: this.validateAndFetchTableData(),
        isChildrenValid: this.validator.isTableValid(),
      });
    }
  }

  /**
   * This function handle a row removal. It removes it from documents array and
   * as well abort any active uploads then updates the parent.
   * @param uuid Each document is assigned a UUID for the file attached to it for identification and tracking
   */
  handleRemove(uuid) {
    let records = this.table.rows().data().toArray();

    if (records.length > 1) {
      let UUIDs = records.map(record => record.uuid);

      // Remove the row from the table as this table doesn't globally refreshes
      let rowIndex = UUIDs.indexOf(uuid);
      this.table.row(rowIndex).remove().draw();
      UUIDs.splice(rowIndex, 1);

      // Cleans the documents array
      let file = this.documents.find(file => file.uuid === uuid);

      if (file.xhr) {
        file.xhr.abort();
      }

      this.validator.removeRowValidations(this.UUIDToRowIndex.get(uuid));
      this.documents = this.documents.filter(document => UUIDs.includes(document.uuid));
      this.updateParent();
    } else {
      UIUtils.showError("At least one document is required. Use the Cancel button below to exit the upload process.",
        "", "#documentBulkAddError");
    }
  }

  generateColumns() {
    return [
      {
        title: "",
        width: 1,
        class: "bulk-approve-results-approved-table-header",
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          return ReactDOM.render((
            <FontAwesomeIcon id={`uploadStatus_${row}`}
                             className="fa-pulse"
                             icon={faSpinner}
                             size="sm"
            />), td);
        }
      },
      {
        title: "File Name",
        width: 1,
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          return ReactDOM.render((
            <span id={`fileName_${row}`}>
              {rowData.fileName}
            </span>), td);
        }
      },
      {
        title: getLabelTooltip(TABLE_FIELDS.DOCUMENT_NAME.title, TABLE_FIELDS.DOCUMENT_NAME.tooltip, true),
        width: 125,
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          return ReactDOM.render((
            <TableTextBox
              value={rowData.name}
              onChange={this.updateParent}
              required={true}
              id={`documentName_${row}`}
              tooltip={"This is required."}
            />), td);
        }
      },
      {
        title: getLabelTooltip(TABLE_FIELDS.CUSTOM_ID.title, TABLE_FIELDS.CUSTOM_ID.tooltip),
        width: 50,
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          return ReactDOM.render((
            <div className="form-group">
              <input type="text"
                     id={`customID_${row}`}
                     defaultValue={rowData.customID}
                     onChange={this.updateParent}
                     className="form-control document-bulk-add-custom-input"
              />
            </div>), td);
        }
      },
      {
        title: getLabelTooltip(TABLE_FIELDS.VERSION.title, TABLE_FIELDS.VERSION.tooltip),
        width: 25,
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          return ReactDOM.render((
            <TableTextBox
              id={`version_${row}`}
              value={rowData.version}
              pattern={VERSION_REGEX}
              onChange={this.updateParent}
              required={false}
              tooltip={"Version can either be 0, 0.1, integer number or left empty."}
            />), td);
        }
      },
      {
        title: "Document Type",
        width: 200,
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          let options = CommonDocuments.DOCUMENT_TYPES.map((type, index) => {
            return (
              <option key={index} value={type}>
                {type}
              </option>);
          });

          return ReactDOM.render((
            <select id={`documentType_${row}`}
                    defaultValue={rowData.type}
                    onChange={this.updateParent}
                    className="form-control"
            >
              {options}
            </select>), td);
        }
      },
      {
        title: "Category",
        width: 125,
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          let options = CommonDocuments.Document_CATEGORIES.map((category, index) => {
            return (
              <option key={index} value={category}>
                {category}
              </option>);
          });

          return ReactDOM.render((
            <select id={`documentCategory_${row}`}
                    defaultValue={rowData.category}
                    onChange={this.updateParent}
                    className="form-control"
            >
              {options}
            </select>), td);
        }
      },
      {
        title: "Effective Date",
        width: 124,
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {

          return ReactDOM.render((
            <TableDatePicker id={`effectiveDate_${row}`}
                             onDateChage={this.updateParent}
                             tooltip={"This document will be created as " +
                               "a draft based on the version field. Therefore, " +
                               "the effective date cannot be in the past."}
            />), td);
        }
      },
      {
        width: 50,
        data: () => "",
        class: "document-bulk-add-table-cancel-link-cell",
        createdCell:
          (td, cellData, rowData, row) => {
            return ReactDOM.render((
              <a id={`remove_${row}`}
                 onClick={this.handleRemove.bind(this, rowData.uuid)}
                 className="document-bulk-add-table-cancel-link"
              >
                <FontAwesomeIcon icon={faTimesCircle} size="lg" />
              </a>), td);
          }
      }
    ];
  }

  componentDidMount() {
    super.componentDidMount();

    let {files, onShowWarnings, existingCustomIds} = this.props;
    files = files.filter(file => file.type === "application/pdf");

    let documents = [];
    for (let file of files) {

      // This part auto detects document name, type and version out of a document name
      const typeMatch = new RegExp("((" + DOCUMENT_TYPES.join("|") + ")-\\d+)").exec(file.name);
      const versionMatch = new RegExp(/v(\d+)/).exec(file.name);
      const nameMatch = new RegExp(/v(\d+)(.*).pdf/).exec(file.name);

      let document = {
        uuid: UIUtils.generateUUID(),
        file,
        fileName: file.name,
        name: nameMatch ? nameMatch[2].trim() : file.name.replace(".pdf", ""),
        customID: typeMatch ? typeMatch[1] : "",
        version: versionMatch ? Number(versionMatch[1]) : "",
        type: typeMatch ? CommonDocuments.DOCUMENT_TYPES
          .find(type => type.includes(typeMatch[2])) : "",
        category: "",
      };

      documents.push(document);
    }

    this.documents = documents;
    this.bulkUploadDocuments(documents);
    this.validator.validateCustomIDs(documents, existingCustomIds, onShowWarnings);

    this.initializeTable(documents);
    this.updateParent();

    $("[data-toggle='popover']").popover({sanitizeFn: UIUtils.sanitizePopoverData});
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    // Abort all uploads
    for (let file of this.documents.filter(record => record.xhr)) {
      file.xhr.abort();
    }

    // Clean other resources
    this.documents = [];
    this.bulkUploadService.disconnect();
    super.componentWillUnmount();
  }

  /**
   * The callback given to the document transfer helper to get invoked when file
   * upload status changes.
   * @param file The file gets uploaded.
   */
  handleFileUploadChange(file) {

    if (!this.documents.find(document => document.uuid === file.uuid)) {

      // Aborted upload! Should be cancelled!
      file.xhr.abort();
    } else if (file.fileStatus === FILE_STATUS.UPLOADED &&
      !this.uploadedDocuments.includes(file.uuid)) {

      this.uploadedDocuments.push(file.uuid);
      let rowIndex = this.UUIDToRowIndex.get(file.uuid);

      $(`#uploadStatus_${rowIndex}`).parent().html(
        ReactDOMServer.renderToStaticMarkup(
          <FontAwesomeIcon id={`uploadStatus_${rowIndex}`}
                           className="document-bulk-add-upload-success"
                           icon={faCheckCircle}
                           size="sm"
          />));

      this.updateParent();
    }
  }
}
