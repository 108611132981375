"use strict";

import * as UIUtils from "../ui_utils";
import { measurePerformanceEnd, measurePerformanceStart } from "../ui_utils";
import React from "react";
import BaseEditor from "./base_editor";
import Cookies from "js-cookie";
import ApprovalResponsePopup from "./approval/approval_response_popup";
import { ensureProjectId, getEffectiveRMPByModelName, loadRMP } from "../helpers/risk_helper";
import { EditablesService } from "../services/editables/editables_service";
import { getURLByTypeCodeAndIdAndVersionId } from "../helpers/url_helper";
import * as ProcessCache from "../processExplorer/process/process_cache";
import { EDITOR_TYPES } from "./editor_constants";
import TypeaheadObjectCacheFactory from "../utils/cache/typeahead_object_cache_factory";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";
import * as FailHandlers from "../utils/fail_handlers";
import MemoryCache from "../utils/cache/memory_cache";
import {
  getApprovalValue,
  handleApprovalChangeValue,
  handleApprovalChangeValues,
  handleApprovalClearValue
} from "./approval/approval_utils";

const CommonUtils = require("../../server/common/generic/common_utils");

const Logger = Log.group(LOG_GROUP.Editables, "BaseEditor");

const SHOW_MAJOR_VERSIONS_ONLY = "SHOW_MAJOR_VERSIONS_ONLY";

export const SAVE_BEHAVIOR_ENUM = {
  SAVE_AND_RELOAD: "SAVE_AND_RELOAD",
  SAVE: "SAVE",
};

export const AUTO_VALIDATION_MODE_ENUM = {
  PROPOSE: "PROPOSE",
  SAVE: "SAVE",
  NONE: "NONE",
};

/**
 *  This class is responsible for being the base class for add/view/editing of
 *  data that has a history and can be approved.
 */
export default class BaseApprovableEditor extends BaseEditor {
  /*
    @see BaseEditor to learn about the arguments & other required methods
   */
  constructor(props, baseTypeName, capitalizedBaseTypeName, displayName) {
    super(props, baseTypeName, capitalizedBaseTypeName, displayName);

    this.handleApprovalChangeValue = handleApprovalChangeValue.bind(this);
    this.handleApprovalChangeValues = handleApprovalChangeValues.bind(this);
    this.getApprovalValue = getApprovalValue.bind(this);
    this.handleApprovalClearValue = handleApprovalClearValue.bind(this);

    const initialState = {
      showApprovalRequest: false,
      modelType: capitalizedBaseTypeName,
      versionId: null,
      currentDiffingVersion: {
        isLoadingVersionDiff: false,
        versionId: -1,
        majorVersion: -1,
        minorVersion: -1,
      },
      isRMPLoading: false,
    };

    this.projectId = this.baseTypeName === "project" ? UIUtils.getParameterByName("id")
      : UIUtils.getParameterByName("projectId");

    this.projectId = this.projectId ? UIUtils.parseInt(this.projectId) : this.projectId;

    if (this.projectId && this.baseTypeName !== "project") {
      initialState.project = {
        id: this.projectId,
      };
    }

    const processId = UIUtils.getParameterByName("processId") || ProcessCache.getProcessIdUsedRecently(this.projectId);
    if (processId) {
      initialState.processId = processId;
    }

    initialState.showMajorVersionsOnly = UIUtils.getParameterByName("onlyApproved") === "true"
      || Cookies.get(SHOW_MAJOR_VERSIONS_ONLY) === "true";

    this.setStateSafely(initialState, () => this._loadRMP(this.updateComponent));

    window.addEventListener("popstate", this.handleHistory);
    this.onDataReceivedListeners = [];
    this.onTypeaheadsLoadedListeners = [];
    this.loadedTypeAheadOptions = [];

    // Load Typeaheads in bulk to save time.
    if (this.isAdd()
      || this.isRecordCached()
    ) {
      this.loadMultipleTypeaheads();
    } else {
      this.addOnDataReceivedListener(this.loadMultipleTypeaheads);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const interactionName = "BaseApprovableEditor :: shouldComponentUpdate";
    measurePerformanceStart(interactionName);

    const shouldUpdate = nextProps.id !== this.props.id ||
      nextProps.versionId !== this.props.versionId ||
      JSON.stringify(nextProps.cachedData) !== JSON.stringify(this.props.cachedData) ||
      nextProps.editorOperation !== this.props.editorOperation ||
      nextProps.editorType !== this.props.editorType ||
      nextProps.isProposeForArchiveOrRestore !== this.props.isProposeForArchiveOrRestore ||
      nextProps.operation !== this.props.operation ||
      nextProps.panelSize !== this.props.panelSize ||
      nextProps.selectedEditorTab !== this.props.selectedEditorTab ||
      nextProps.showAlert !== this.props.showAlert ||
      nextProps.showingState !== this.props.showingState ||
      nextProps.isLoading !== this.props.isLoading ||
      nextState.isLoading !== this.state.isLoading ||
      nextState.versionId !== this.state.versionId ||
      nextState.showMajorVersionsOnly !== this.state.showMajorVersionsOnly ||
      JSON.stringify(this.cleanStateForComponentUpdate(nextState)) !== JSON.stringify(this.cleanStateForComponentUpdate(this.state));

    measurePerformanceEnd(interactionName);

    return shouldUpdate;
  }

  cleanStateForComponentUpdate(state) {
    return state;
  }

  /**
   * This method is for loading multiple typeaheads at once in a single request rather than loading them individually
   * for performance reasons.
   * @param callback A method that's called once everything has been loaded.
   */
  loadMultipleTypeaheads(callback) {
    const typesToCache = this.getTypesToCache();

    if (!this.memoryCacheForMultipleTypeaheads) {
      this.memoryCacheForMultipleTypeaheads = MemoryCache.getNamedInstance(`loadMultipleTypeaheads_${typesToCache.join("_")}_${this.getProjectId()}_${this.getProcessId()}`);
    }

    let remainingTypesToCache = [...new Set(typesToCache.filter(modelName => !this.memoryCacheForMultipleTypeaheads.get(this.getInMemoryCacheKey(UIUtils.getTypeCodeForModelName(modelName)))))];
    if (remainingTypesToCache.length > 0) {
      const multipleTypeaheadObjectCache = TypeaheadObjectCacheFactory.createAllTypeaheadObjectsPossible(remainingTypesToCache, this.getProjectId, this.getProcessId);
      if (multipleTypeaheadObjectCache) {
        // TypeaheadObjectCacheFactory can decide to load less types
        remainingTypesToCache = multipleTypeaheadObjectCache.types;
        this.setStateSafely({
          areTypeaheadsLoading: true
        }, () => {
          multipleTypeaheadObjectCache.loadOptions((results, typeCode) => {
            this.handleTypeaheadResultsFromServer(callback, results, typeCode, remainingTypesToCache.map(modelName => UIUtils.getTypeCodeForModelName(modelName)));
          });
        });
      } else if (typeof callback === "function") {
        callback();
      }
    } else {
      for (let typeCode of typesToCache.map(modelName => UIUtils.getTypeCodeForModelName(modelName))) {
        const results = this.memoryCacheForMultipleTypeaheads.get(this.getInMemoryCacheKey(typeCode));
        if (results) {
          this.handleTypeaheadResultsFromServer(callback, results, typeCode, typesToCache.map(modelName => UIUtils.getTypeCodeForModelName(modelName)));
        }
      }
    }
  }

  /**
   * Speed up the typeahead loading by loading everything at once, instead of 1 for each type.
   * @returns {string[]}
   */
  getTypesToCache() {
    return [];
  }

  /**
   * Typeahead results will come back here (but also to any other call to TypeaheadOptionsCache.loadOptions()).
   *
   * @param callback A callback that should be called once the results are loaded.
   * @param results The typeahead options for the given typecode.
   * @param typeCode The typecode that we were trying to load.
   * @override
   */
  // eslint-disable-next-line no-unused-vars
  handleTypeaheadResultsFromServer(callback, results, typeCode, remainingTypesToCache) {
    if (this.memoryCacheForMultipleTypeaheads) {
      this.memoryCacheForMultipleTypeaheads.set(this.getInMemoryCacheKey(typeCode), results);
    }

    const typesToCache = remainingTypesToCache;
    const modelName = CommonUtils.convertToId(CommonUtils.findModelNameForTypeCode(typeCode));
    if (modelName === typesToCache[typesToCache.length - 1] || typeCode === typesToCache[typesToCache.length - 1]) {
      // This is the last type to be loaded.
      if (typeof callback === "function") {
        callback();
      }

      for (const someFunc of this.onTypeaheadsLoadedListeners) {
        someFunc();
      }
    }
  }

  getInMemoryCacheKey(typeCode) {
    return "multipleTypeaheads_" + typeCode;
  }

  resetState(newStateData, callback) {
    let newState;
    try {
      newState = newStateData ? JSON.parse(JSON.stringify(newStateData)) : {};
    } catch (error) {
      Logger.error("Invalid JSON:", Log.object(newStateData), Log.error(error));
      throw error;
    }

    // older version will be retrieved after state reset
    newState.olderVersion = null;

    newState.currentDiffingVersion = {
      isLoadingVersionDiff: false,
      versionId: -1,
      majorVersion: -1,
      minorVersion: -1,
    };

    /* https://stackoverflow.com/questions/34845650/clearing-state-es6-react
       At this point we need to clear up the state of the form completely, removing any left overs from
       any previously loaded cached data. setStateSafely is not a method that replaces the state. Instead it is
       appending new attributes and replacing existing ones that already leave on the state. So if you
       really want to replace the state with something else, then this approach will do the job.
     */
    let keysToReset = Object.keys(this.state);
    const keysToKeepSet = this.getKeysToKeepWhenResettingState();
    keysToReset = keysToReset.filter(key => !keysToKeepSet.has(key));
    const stateReset = keysToReset.reduce((previousValue, key) => ({...previousValue, [key]: undefined}), {});
    const state = {...stateReset, ...newState};
    this.setStateSafely(state, () => {
      this.fillRiskDocumentsAttributeDetails(() => {
        this.triggerFormValidation();
        if (callback && typeof callback === "function") {
          callback();
        }
      });
    });

  }

  /**
   * Override this method to return a set of state variables that should be kept on reset.  Things like typeahead values
   * that don't change between types.
   *
   * @return {Set} A set of keys to keep when resetting the state
   */
  getKeysToKeepWhenResettingState() {
    return new Set(["project", "RMP", "ProjectWithAllVersions", "isHistoryShowing", "editorOperation", "selectedTab", "sidePanelTabs", "formValidationMode", "isLoading", "areTypeaheadsLoading", "isLoadingRMP", "riskTableInitialized"]);
  }

  handleReset(preprocessCallback) {
    this.context.clearPreventNavigation();
    if (preprocessCallback) {
      this.resetState(preprocessCallback(this.props.cachedData));
    } else {
      this.resetState(this.props.cachedData);
    }
    if (this._isMounted) {
      this.forceUpdate();
      let allNamesToRefs = this.getAllNamesToRefs();
      for (const childName of Object.keys(allNamesToRefs)) {
        allNamesToRefs[childName].forceUpdate();
      }
    }

    if (this.props.eventListeners && this.props.eventListeners.onReset) {
      this.props.eventListeners.onReset();
    }
  }

  handleView() {
    let {id, modelName} = this.state;
    let url = getURLByTypeCodeAndIdAndVersionId(UIUtils.getTypeCodeForModelName(modelName), "View", id);
    const projectId = UIUtils.parseInt(UIUtils.getParameterByName("projectId"));
    if (projectId) {
      url += "&projectId=" + projectId;
    }

    window.open(url, "_blank");
  }

  handleSwitchViewMode(button, editorOperation, data, done) {
    if (this.props.eventListeners && this.props.eventListeners.onSwitchViewMode) {
      this.props.eventListeners.onSwitchViewMode(button, editorOperation, data, done);
    }
  }

  handleMarkAsReviewed() {
    if (this.props.eventListeners && this.props.eventListeners.onMarkAsReviewed) {
      this.props.eventListeners.onMarkAsReviewed(this.state);
    }
  }

  handleActionClick(action) {
    if (this.props.eventListeners && this.props.eventListeners.onActionClick) {
      this.handleQuickPanelActionClick(action);
      this.props.eventListeners.onActionClick(action, this.state, () => this.initialize());
    }
  }

  // eslint-disable-next-line no-unused-vars
  handleQuickPanelActionClick(action) {
  }

  /**
   * This function de-registers the default bootstrap validator and registers a new one that does not auto scroll
   */
  triggerFormValidation() {
    let baseForm = $("#baseEditorForm");
    baseForm.validator("destroy");
    baseForm.validator({focus: false});
    baseForm.validator("update");
    baseForm.validator("validate");
  }

  updateComponent(RMP, project) {
    this.setStateSafely({RMP, ProjectWithAllVersions: project, isRMPLoading: false});
  }

  addOnDataReceivedListener(someFunc) {
    this.onDataReceivedListeners.push(someFunc);
  }

  onDataReceivedFromServer() {
    super.onDataReceivedFromServer();

    let versionId = UIUtils.getParameterByName("versionId");
    if (versionId) {
      this.handleRevisionLoad(versionId);
    } else {
      history.replaceState(UIUtils.cleanStateForHistory(this.state), "", window.location.pathname + window.location.search);
    }

    for (const someFunc of this.onDataReceivedListeners) {
      someFunc();
    }

    if (this.state.project) {
      this._loadRMP(this.updateComponent, this.state.project.id);
    }

    this.handleApprovalClearValue();
  }

  /**
   * An internal version of Risk Helper's loadRMP that sets the state, so we know if it's loading or not.
   * @param callback {function} A function that's called after the RMP has been loaded (if it needs to be loaded).
   * @param projectId {number} The project for which to load the RMP. If no projectId is passed in, no RMP will be loaded.
   * @private
   */
  _loadRMP(callback, projectId) {
    projectId = ensureProjectId(projectId);
    if (projectId) {
      this.setStateSafely({isRMPLoading: true}, () => {
        loadRMP(callback, projectId);
      });
    }
  }

  isLoading() {
    /*
     * Note: There's no need to add areTypeaheadsLoading here because the attributes that need a given typeahead will
     * show the loader until the typeahead data they need becomes available.
     */
    return super.isLoading() || this.state.isRMPLoading || this.state.currentDiffingVersion?.isLoadingVersionDiff;
  }

  getURLToLoadData(shouldShowApproved) {
    this.shouldShowApproved = typeof shouldShowApproved !== "undefined" ? shouldShowApproved : UIUtils.getParameterByName("approved");
    this.shouldShowApproved = !(this.shouldShowApproved === false || this.shouldShowApproved === "false"); // Undefined is true.
    this.state.approved = false;

    let url = this.getURLPrefix() + "/" + this.id;
    let useWriterDB = UIUtils.getParameterByName("useWriterDB");

    if (this.isView()) {
      url += "?includeHistory=true&shouldCompress=true&approved=" + this.shouldShowApproved;
      url += (useWriterDB ? "&useWriterDB=true" : "");
      url += "&includeFromLibrary=true";
    } else {
      // For add/edit
      url += "?includeHistory=false&approved=false";
    }
    return url;
  }

  getURLPrefix() {
    return "editables/" + this.capitalizedBaseTypeName;
  }

  getProjectId() {
    const projectId = this.state.project ? this.state.project.id
      : (this.capitalizedBaseTypeName === "Project" ? this.state.id
        : this.state.ProjectId ? this.state.ProjectId
          : null);

    return projectId ? UIUtils.parseInt(projectId) : projectId;
  }

  getProcessId(ignoreRecent = false) {
    // PrcoessId comes from the database record
    // processId comes from current project or recently used project
    return this.state.ProcessId || (!ignoreRecent && (this.state.processId || ProcessCache.getProcessIdUsedRecently(this.getProjectId())));
  }

  getSupplierId() {
    return this.state.SupplierId || this.state.supplierId;
  }

  isProjectEditor() {
    return this.capitalizedBaseTypeName === "Project";
  }

  isProcessEditor() {
    return this.capitalizedBaseTypeName === "Process";
  }

  getRMPId() {
    if (this.state.RMP) {
      return this.state.RMP.id;
    } else if (this.state.project) {
      return this.state.project.RMPId;
    }
    return null;
  }

  getRMP() {
    const instance = {...this.state};
    return this.state.RMP && this.state.ProjectWithAllVersions ? getEffectiveRMPByModelName(this.state.ProjectWithAllVersions, this.state.RMP.approvedVersionsWithDetails, instance, this.capitalizedBaseTypeName, this.isEdit()) : null;
  }

  getEffectiveRMPByModelName(modelName, instance = null) {
    if (!this.state.RMP || !this.state.ProjectWithAllVersions) {
      return;
    }

    if (this.state.requestedByHomePage && !instance) {
      instance = this.state;
    }

    return getEffectiveRMPByModelName(this.state.ProjectWithAllVersions, this.state.RMP.approvedVersionsWithDetails, instance ?? this.state, modelName, this.isEdit());
  }

  getProjectName() {
    return this.state.project ? this.state.project.name : this.state.name;
  }

  getProjectDeletedAt() {
    return this.state.project ? this.state.project.deletedAt : this.state.deletedAt;
  }

  isDemoProject() {
    return this.state.project ? this.state.project.isDemo : false;
  }

  getDisplayName() {
    return this.capitalizedBaseTypeName;
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    // If there's no object ID, this means the instance was not loaded, so we can't check yet
    const isInstanceLoaded = !!this.state.id;
    // Ensures we only show the error if the page is trying to load anm approved version
    const shouldShowApproved = UIUtils.getParameterByName("approved") === "true";

    if (isInstanceLoaded && shouldShowApproved && !this.state.approved) {
      UIUtils.showError("Sorry, there is no approved version.");
    }
  }

  handleSendApprovalRequest(event, callback) {
    // Close the dialog only when no callback is specified, otherwise let the callback handle showing an error or closing the dialog itself.
    if (!callback && this.approvalRequestPopup) {
      callback = () => $(this.approvalRequestPopup).modal("hide");
    }
    this.handleSave(event, callback);
  }

  handleShowMajorVersionsChange(versionId, showMajorVersionsOnly) {
    this.setStateSafely({
      showMajorVersionsOnly: showMajorVersionsOnly,
      updateWindowURLHistory: true,
      currentDiffingVersion: {
        isLoadingVersionDiff: true,
      },
    }, () => {
      Cookies.set(SHOW_MAJOR_VERSIONS_ONLY, showMajorVersionsOnly);
      if (this.isDiffingVersions()) {
        this.loadRevision(versionId, true, true);
      } else {
        this.handleReceiveRevisionDataFromServer({});
      }
    });
  }

  handleRevisionChange(versionId) {
    this.loadRevision(versionId, true);
  }

  handleRevisionLoad(versionId) {
    this.loadRevision(versionId, false);
  }

  loadNewVersion(shouldShowApproved, callback) {
    this.setStateSafely({
      versionId: null,
      isLoading: true,
      currentDiffingVersion: {
        isLoadingVersionDiff: true,
      },
    }, () => {
      UIUtils.secureAjaxGET(this.getURLToLoadData(shouldShowApproved), undefined, true, FailHandlers.defaultStopLoadingFailFunction.bind(this))
        .done((results) => this.handleReceiveRevisionDataFromServer(results, callback));
    });
  }

  loadRevision(versionId, updateWindowURLHistory, forceUpdate) {
    if (!versionId) {
      Logger.info(() => "Turning off diff mode.");
    } else {
      Logger.info(() => "Changing to show the diff of version " + versionId);
    }

    // Check custom validated controls and trigger validation messages
    for (let i = 0; i < this.customValidatedChildElements.length; i++) {
      this.customValidatedChildElements[i].clearValidationErrors();
    }

    if (forceUpdate || this.state.currentDiffingVersion.versionId !== versionId) {
      this.setStateSafely({
        updateWindowURLHistory: !!updateWindowURLHistory,
        isLoading: true,
        versionId: null,
        currentDiffingVersion: {
          isLoadingVersionDiff: true,
        },
      }, () => {
        if (!this.props.cachedData || (versionId && this.props.cachedData.isHistoryPartial)) {
          if (versionId) {
            UIUtils.secureAjaxGET(`${this.getURLPrefix()}/${this.id}?versionId=${versionId}&onlyApproved=${this.state.showMajorVersionsOnly}&includeFromLibrary=true"`, undefined, true, FailHandlers.defaultStopLoadingFailFunction.bind(this))
              .done((response) => {
                this.handleReceiveRevisionDataFromServer(response);
              });
          } else {
            UIUtils.secureAjaxGET(this.getURLToLoadData(), undefined, true, FailHandlers.defaultStopLoadingFailFunction.bind(this))
              .done(this.handleReceiveRevisionDataFromServer);
          }
        } else { // At this point all data may have been pre-loaded and might be found in state
          let versionMap = {};
          if (versionId) {
            let newObjIndex = -1;

            // Just get all the requirement versions from state
            let allVersions = [].concat(this.state[this.capitalizedBaseTypeName + "Versions"]).sort((a, b) => a.id - b.id);

            // Find the version that was requested
            versionMap.newObj = allVersions.find(version => version.id === versionId);
            // Filter all versions with the approved versions if that is what the user is looking at

            allVersions = allVersions.filter(version => !this.state.showMajorVersionsOnly
              || (this.state.showMajorVersionsOnly && version.majorVersion > 0 && version.minorVersion === 0 && !version.deletedAt));

            // Find the previous version of the one that was requested
            for (let i = 0; i < allVersions.length; i++) {
              if (allVersions[i].id === versionMap.newObj.id) {
                newObjIndex = i;
                break;
              }
            }
            if (newObjIndex > 0) {
              versionMap.oldObj = allVersions[newObjIndex - 1];
            }
          }

          // Just call the same callback handler with the date retrieved from state this time.
          this.handleReceiveRevisionDataFromServer(versionMap);
        }
      });
    }
  }

  /**
   * This method is called when record data is received from the server.
   *
   * @param results {object} This can either be a single instance (ex. results.id, results.currentState) or it can be
   * multiple revision that can be used to diff two versions (ex. results.newObj vs results.oldObj).
   * @param [callback] {Function} An optional function that may be passed from the caller to detect that data has been loaded
   */
  handleReceiveRevisionDataFromServer(results, callback) {
    /**
     * A local function that contains the operations that must be performed
     * after handling the revision data from the server.
     */
    const done = async(results) => {
      /**
       * This callback is used to ensure the revision data is reloaded properly for approved records.
       * @see the insanely huge comment in {@link StaticApprovalContainer.openPage} for more information.
       */
      await UIUtils.invokeCallbackAsync(callback, results);

      for (let someFunc of this.onDataReceivedListeners) {
        someFunc(results);
      }
      UIUtils.hideLoadingImage();
    };

    if (results.newObj) {
      // Uncomment for verbose logging
      // Logger.debug("Received from server: " + UIUtils.stringify(results));
      let instanceId = this.state.id;
      let newObj = results.newObj;
      this.setStateSafely({
        ...this.preprocessReceivedData(newObj),
        olderVersion: results.oldObj ? {...this.preprocessReceivedData(results.oldObj)} : null,
        clonedFrom: results.clonedFrom,
        currentDiffingVersion: {
          isLoadingVersionDiff: false,
          versionId: newObj.id,
          majorVersion: newObj.majorVersion,
          minorVersion: newObj.minorVersion,
          currentState: newObj.currentState,
        },
        versionId: newObj.id,
        id: instanceId,
        isLoading: false,
      }, () => {
        if (this.state.updateWindowURLHistory && this.getEditorType() === EDITOR_TYPES.FULL_SCREEN) {
          UIUtils.pushHistoryURLWithNewParameter(this.state, "versionId", newObj.id);
        } else {
          history.replaceState(UIUtils.cleanStateForHistory(this.state), "", window.location.pathname + window.location.search);
        }
        this.runPromise(done(results));
      });
    } else {
      this.setStateSafely({
        ...this.preprocessReceivedData(results),
        olderVersion: null,
        clonedFrom: results.clonedFrom,
        fromLibrary: results.fromLibrary,
        versionId: results.versionId,
        approved: results.minorVersion === 0,
        currentDiffingVersion: {
          isLoadingVersionDiff: false,
          versionId: -1,
          majorVersion: -1,
          minorVersion: -1,
        },
        isLoading: false,
      }, () => {
        const paramsToAdd = {onlyApproved: this.state.showMajorVersionsOnly};
        const paramsToRemove = {versionId: true};
        UIUtils.pushHistoryURLWithParameterChanges(this.state, paramsToAdd, paramsToRemove);
        this.runPromise(done(results));
      });
    }
  }

  // This is called when the user hits the back button to see a previous Unit Operation
  handleHistory(event) {
    // Uncomment for verbose logging
    // Logger.debug("Moving to state: " + UIUtils.stringify(event.state));
    this.setStateSafely(event.state);
  }

  isDiffingVersions(state = this.state) {
    return state?.currentDiffingVersion?.versionId > 0 || this.isLoadingDiff();
  }

  /**
   * This returns true/false based on if the version diff is loading or not. It is used to avoid race conditions when
   * updating the state across different asynchronous events, i.e. loading typeaheads. When the typeaheads get loaded,
   * the UI needs to know in certain cases if there is a process of loading the version diff or not. Since the
   * version diff information is updated in the state only after the diff is loaded, we need to know in advance if there
   * is an ongoing operation of loading the version diff.
   * @param state
   * @returns {boolean}
   */
  isLoadingDiff(state = this.state) {
    const {currentDiffingVersion} = state;
    return !!currentDiffingVersion?.isLoadingVersionDiff;
  }

  /**
   * This should always be false. However in edge cases like the RMP.jsx, where we present information
   * in the view page through the version records of the editable, it should return true. The base_linked_entities.jsx
   * control relies on this to show the right information in the view page base on the editable type.
   */
  showsVersionsInView() {
    return false;
  }

  isApproved() {
    return this.state.approved === true || this.state.approved === "true";
  }

  hasApprovedVersion() {
    let allVersions = this.getVersions();
    return allVersions && allVersions.find(version => version.minorVersion === 0);
  }

  isArchived() {
    return this.state.deletedAt;
  }

  isRestored() {
    return this.state.currentState === CommonUtils.VERSION_STATES.RESTORED;
  }

  isEditAllowed() {
    return true;
  }

  getEditButtonDisabledTooltip() {
    return null;
  }

  getCurrentDiffingVersion() {
    return this.state.currentDiffingVersion;
  }

  /**
   * Get the version the user is currently viewing.
   * @return {Object.versionId|null} If null, the user is viewing the latest version. Otherwise it's an integer (the Id
   * from the record's version table).
   */
  getCurrentViewingVersionId() {
    const {currentDiffingVersion, versionId} = this.state;
    if (currentDiffingVersion.versionId > 0) {
      return currentDiffingVersion.versionId;
    } else {
      if (!versionId && this.props.cachedData) {
        const propertyName = this.props.cachedData.modelName + "Versions";
        const propertyVersions = this.props.cachedData[propertyName];
        return propertyVersions && propertyVersions.length > 0 ? propertyVersions[0].id : null;
      }

      return versionId;
    }
  }

  getOlderVersion(state = this.state) {
    return state.olderVersion;
  }

  getOlderRMP(modelName = this.getDisplayName()) {
    const version = this.getOlderVersion();
    return this.getEffectiveRMPByModelName(modelName, version);
  }

  /**
   * This method is called when a user tries to approve or reject this record.
   *
   * @param approve {boolean} true if the user chose to approve the record, false if they chose to reject it.
   * @return {Promise<boolean>} A promise that will return true if the operation was successful, or false otherwise.
   * For example, if this record cannot be archived because of a risk link to it,  this promise will return false. But if
   * the user chose to reject this record and that was registered correctly with the back end, this will return true.
   */
  handleSendApproval(approve) {
    let versionId = this.getLatestVersionId();
    const editablesService = new EditablesService();


    let payload = {
      versionId,
      comment: this.getApprovalValue("comment"),
      email: this.getApprovalValue("email"),
      password: this.getApprovalValue("password"),
      signingPin: this.getApprovalValue("signingPin"),
      baseId: this.id,
    };

    let parameters = {
      model: this.capitalizedBaseTypeName,
      urlPrefix: this.getURLPrefix(),
      useTwoWayCommunication: this.useTwoWayCommunication,
    };

    return editablesService.approve(approve, payload, parameters)
      .then((result) => {
        this.handleApprovalResult(result, approve);
        return true;
      })
      .catch(error => {
        this.failCallback(error);
        return false;
      });
  }

  handleApprovalResult(result, approve) {
    // Uncomment for verbose logging
    // Logger.debug("Result of approval: " + UIUtils.stringify(result));
    this.setStateSafely({
      ...this.preprocessReceivedData(result),
    });
    if (approve) {
      ApprovalResponsePopup.setApprovalSucceededInThisSession();
    }
    const historyParams = {approved: false, useWriterDB: true};
    UIUtils.pushHistoryURLWithParameterChanges(this.getCurrentState(), historyParams);
    this.initialize();
  }

  getLatestVersionId() {
    let versionId = this.state.currentDiffingVersion.versionId;
    if (versionId < 0) {
      let versions = this.getVersions();
      versionId = versions && versions.length > 0 ? versions[0].id : null;
    }
    return versionId;
  }

  handleSendWithdraw() {
    UIUtils.setLoadingDisabled(false);
    let versionId = this.getLatestVersionId();
    UIUtils.secureAjaxPUT(this.getURLPrefix() + "/withdraw/" + versionId, {
      comment: this.getApprovalValue("comment"),
      baseId: this.id,
    }, true, FailHandlers.defaultStopLoadingFailFunction.bind(this)).done((result) => {
      // Uncomment for verbose logging
      // Logger.debug("Result of approval: " + UIUtils.stringify(result));
      if (this.isDiffingVersions()) {
        result.currentDiffingVersion = this.state.currentDiffingVersion;
        result.currentDiffingVersion.currentState = result.currentState;
      }

      this.setStateSafely({
        ...this.preprocessReceivedData(result),
      });

      if (this.props.eventListeners && this.props.eventListeners.onSaveCompleted) {
        this.props.eventListeners.onSaveCompleted(result);
      }
      UIUtils.setLoadingDisabled(true);
    });
  }

  getCurrentState() {
    let versionId = this.state.currentDiffingVersion.versionId;
    let currentState;
    if (versionId > 0 && !this.shouldShowApproved) {
      currentState = this.getVersions().filter(version => version.id === versionId)[0].currentState;
    } else if (this.isAdd()) {
      currentState = UIUtils.VERSION_STATES.DRAFT;
    } else {
      currentState = this.state.currentState;
    }

    return currentState;
  }

  getCurrentlyViewedState() {
    let versionId = this.state.currentDiffingVersion.versionId;
    let currentState;
    if (versionId > 0 && !this.shouldShowApproved) {
      currentState = this.getVersions().filter(version => version.id === versionId)[0].currentState;
    } else if (this.isAdd()) {
      currentState = UIUtils.VERSION_STATES.DRAFT;
    } else if (this.state.currentState === UIUtils.VERSION_STATES.PROPOSED) {
      currentState = this.state.approved ? UIUtils.VERSION_STATES.APPROVED : UIUtils.VERSION_STATES.PROPOSED;
    } else {
      currentState = this.state.currentState;
    }

    return currentState;
  }

  getVersions() {
    return UIUtils.deepClone(this.state[this.capitalizedBaseTypeName + "Versions"]);
  }
}
