import {NumberedListOption} from "../../common/constants";
import {parseListItemData} from "../../plugins/set_data_numbered_list";
import {Converters} from "../../../../server/common/generic/common_converters";
import * as UIUtils from "../../../ui_utils";

export function setNumberedListItems(htmlContent: string) {
  const node = new DOMParser().parseFromString(htmlContent, "text/html");
  const olElements = Array.from(node.querySelectorAll("ol"));
  const uuidToLastOLElement: Map<string, HTMLOListElement> = new Map();
  const levelToLastOLElement: Map<string, HTMLOListElement> = new Map();
  for (const olElement of olElements) {
    // We only want to set list item data of the first level ol element only. We will recursively
    // set data for the nested list item when setting the list item
    if (olElement.getAttribute("level") !== "1") {
      continue;
    }
    setLIElementDataForOLElement(
      olElement,
      uuidToLastOLElement,
      levelToLastOLElement,
    );
  }
  alignListItems(olElements);
  return node.documentElement.outerHTML;
}

function setLIElementDataForOLElement(
  olElement: HTMLOListElement,
  uuidToLastOLElement: Map<string, HTMLOListElement>,
  levelToLastOLElement: Map<string, HTMLOListElement>,
  prefix?: string,
) {
  const isContinuous = Converters.toBoolean(
    olElement.getAttribute(NumberedListOption.Continuous),
  );
  const isPrefixNearest = Converters.toBoolean(
    olElement.getAttribute(NumberedListOption.PrefixNearest),
  );
  const level = olElement.getAttribute("level");
  const uuid = olElement.getAttribute("uuid");
  let numberOfListItems = 0;
  const {listItemData, hasNestedNumberedList, isNotMatchedUUID} =
    getLastListItemDataOfOlElement(
      olElement,
      uuidToLastOLElement,
      uuid,
      levelToLastOLElement,
      level,
    );
  // If it is continuous, we need to continue from the last list item of the previous
  // ol element. We also need to use the same prefix of the last list item.
  if (isContinuous) {
    numberOfListItems = listItemData.order;
    if (listItemData.prefix) {
      prefix = prefix
        ? `${listItemData.prefix}.${prefix}`
        : `${listItemData.prefix}.`;
    }
  }

  if (isPrefixNearest) {
    if (hasNestedNumberedList) {
      // If the li element has a direct nested numbered list, we need to continue it with
      // the nested numbered list of the last ol element
      const nestedLIElement = getLastListItemDataOfOlElement(
        olElement,
        uuidToLastOLElement,
        uuid,
        levelToLastOLElement,
        `${UIUtils.convertToNumber(level) + 1}`,
      );
      numberOfListItems = nestedLIElement.listItemData.order;
    }
    prefix = prefix
      ? `${listItemData.order}.${prefix}`
      : `${listItemData.order}.`;
    if (listItemData.prefix) {
      prefix = `${listItemData.prefix}.${prefix}`;
    }

    // If it is not matched UUID, it means it is the start of the repeater widget. The prefix
    // needs to be the same with the last one with the same UUID and continue from there.
    if (isNotMatchedUUID && listItemData.prefix) {
      prefix = `${listItemData.prefix}.`;
      numberOfListItems = listItemData.order;
    }
  }

  // We only want to get all the direct li elements. We ignore the nested list items since
  // we will recursively traverse them for each nested ol elements
  const liElements = Array.from<HTMLLIElement>(
    olElement.querySelectorAll(":scope > li"),
  );
  for (const liElement of liElements) {
    numberOfListItems++;
    const dataValue = `${prefix || ""}${numberOfListItems}`;
    liElement.setAttribute("data", dataValue);
    // Getting all the direct ol elements to set the data for all the nested list item
    // elements
    const olElements = Array.from<HTMLOListElement>(
      liElement.querySelectorAll(":scope > ol"),
    );
    for (const olElement of olElements) {
      setLIElementDataForOLElement(
        olElement,
        uuidToLastOLElement,
        levelToLastOLElement,
        `${dataValue}.`,
      );
    }
  }

  // We keep the last ol element, so we can easily get it and continue the list if
  // the next one is continuous or prefix nearest
  uuidToLastOLElement.set(uuid, olElement);
  levelToLastOLElement.set(level, olElement);
}

function getLastListItemDataOfOlElement(
  olElement: HTMLOListElement,
  uuidToLastOLElement: Map<string, HTMLOListElement>,
  uuid: string,
  levelToLastOLElement: Map<string, HTMLOListElement>,
  level: string,
) {
  const isContinuous = Converters.toBoolean(
    olElement.getAttribute(NumberedListOption.Continuous),
  );
  const isPrefixNearest = Converters.toBoolean(
    olElement.getAttribute(NumberedListOption.PrefixNearest),
  );
  let lastOlElement = levelToLastOLElement.get(level);
  const isLastOlContinuous = Converters.toBoolean(
    lastOlElement?.getAttribute(NumberedListOption.Continuous),
  );
  let isNotMatchedUUID = false;
  // If the current element with dependon attribute doesn't match with the last ol element,
  // it means that it is the start of the repeater widget and the last ol element is the end
  // of the widget. That's why we cannot use the last ol element. We need to continue from
  // the last ol element with the same uuid. We only need to do it for the same level numbered
  // list and the ol element is either prefix nearest or it is continuous and the last ol is not
  // continuous. We check the last ol is not continuous since if it is continuous we need to continue
  // the list from the last one.
  if (
    (isPrefixNearest || (isContinuous && !isLastOlContinuous)) &&
    olElement.getAttribute("level") === level &&
    olElement.getAttribute("dependon") !==
    lastOlElement?.getAttribute("uuid") &&
    uuidToLastOLElement.get(uuid)
  ) {
    lastOlElement = uuidToLastOLElement.get(uuid);
    // Set the flag, so we can use it to set the prefix of the list item
    isNotMatchedUUID = true;
  }
  const lastDirectLIElement = lastOlElement?.querySelector(
    ":scope > li:last-child",
  );
  // If the li element has a direct ol element, it means it has a nested numbered list
  const nestedOLElement = lastDirectLIElement?.querySelector(":scope > ol");
  return {
    isNotMatchedUUID,
    lastOlElement,
    listItemData: parseListItemData(lastDirectLIElement?.getAttribute("data")),
    hasNestedNumberedList: !!nestedOLElement,
  };
}

function alignListItems(olElements: Array<HTMLOListElement>) {
  for (const olElement of olElements) {
    // If it is a nested list, we don't need to align it since we already align
    // its parent
    if (olElement.parentElement.tagName === "LI") {
      continue;
    }

    const liElement = olElement.querySelector(":scope > li:first-child");
    if (!liElement) {
      continue;
    }

    const dataValue = liElement.getAttribute("data");
    const liElementData = parseListItemData(dataValue);
    // The ol has padding left 30px
    olElement.style.marginLeft = `${
      (liElementData.level - 1) * 30
    }px`;
  }
}
