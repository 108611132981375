"use strict";

import React from "react";
import { WIDGET_FIELDS } from "../constants/rmp_risk_score_attribute_constants";
import RMPRiskScoreAttribute, { RMP_RISK_SCORE_ATTRIBUTE_TYPE } from "./rmp_risk_score_attribute";
import { RISK_ATTRIBUTES_TABS } from "../constants/rmp_constants";

/**
 * This is class is responsible for handling Risk Management Plan Detectability records.
 */
export default class RMPDetectabilityRisksAttribute extends RMPRiskScoreAttribute {
  constructor(props) {
    super(props, WIDGET_FIELDS);

    this.errorMessages.editedRowPending = "At least one detectability risk row is in edit state. Please save or cancel all detectability risk rows first.";
    this.type = RMP_RISK_SCORE_ATTRIBUTE_TYPE.DETECTABILITY_RISK;
    this.tabContainerType = RISK_ATTRIBUTES_TABS.DETECTABILITY_RISK;
  }
}
