"use strict";

import React, { Fragment } from "react";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import * as UIUtils from "../../ui_utils";
import { getResultActiveMap, PROCESS_LEVEL_MODELS_CONFIG } from "../tables/process_explorer_tables_config";
import ConfigurableTablesColumnOperationsAdapter
  from "../../configurableTables/columnOperations/configurable_tables_column_operations_adapter";
import ConfigurableTablesColumnOperationsHandler
  from "../../configurableTables/columnOperations/configurable_tables_column_operations_handler";
import ConfigurableTablesColumnSelectionHandler
  from "../../configurableTables/columnSelection/configurable_tables_column_selection_handler";
import ConfigurableTablesExportColumnGenerator
  from "../../configurableTables/export/configurable_tables_export_column_generator";
import { orderAndIndexUnits } from "../indexers/uo_indexer";
import { PROCESS_LEVEL_ID } from "../../techTransfer/tech_transfer_constants";
import * as RiskHelper from "../../helpers/risk_helper";
import * as styles from "../../configurableTables/tables/styles/configurable_table.module.scss";
import ConfigurableTablePage from "../../configurableTables/tables/configurable_table_page";
import { TableToolBar } from "../../configurableTables/tables/widgets/topBar/toolBar/table_tool_bar";
import { PROCESS_MODELS_WITH_ACCEPTANCE_CRITERIA } from "../../../server/processExplorer/constants";
import QuickFilterArea from "../widgets/topBar/toolBar/quick_filter_area";
import { TableStateProvider } from "../../configurableTables/tables/table_state_provider";
import { WithNotification } from "../../configurableTables/tables/widgets/notification/with_notification";
import ErrorBar from "../../widgets/bars/error_bar";
import { TableSettingsProvider } from "../../configurableTables/tables/table_settings_provider";

// i18next-extract-mark-ns-start processExplorer
class TableViewPage extends ConfigurableTablePage {
  constructor(props) {
    super(props);

    this.projectId = props.projectId;
    this.processId = props.processId;

    this.columnOperationsAdapter = new ConfigurableTablesColumnOperationsAdapter();
    this.columnOperationsHandler = new ConfigurableTablesColumnOperationsHandler({
      parent: this,
      columnOperationsAdapter: this.columnOperationsAdapter,
    });

    this.columnSelectionHandler = new ConfigurableTablesColumnSelectionHandler({
      parent: this,
      modelsConfig: PROCESS_LEVEL_MODELS_CONFIG,
      columnOperationsAdapter: this.columnOperationsAdapter,
      exportColumnGenerator: ConfigurableTablesExportColumnGenerator,
    }, false, "defaultValue");

    props.onUpdateTableDependencies({
      columnSelectionHandler: this.columnSelectionHandler,
      columnOperationsHandler: this.columnOperationsHandler,
      columnOperationsAdapter: this.columnOperationsAdapter,
    });

    UIUtils.setLoadingDisabled(false);
    UIUtils.showLoadingImage();
    RiskHelper.loadRMP((effectiveRMP, project) => this.rmpLoaded(effectiveRMP, project, props.selectedModelType));
  }

  rmpLoaded(effectiveRMP, project, selectedModelType) {
    let stateObject = {
      effectiveRMP,
      ProjectWithAllVersions: project,
      selectedModelType,
    };
    this.columnOperationsHandler.handleDefaultColumnOperationsFromURL(stateObject);
    this.setStateSafely(stateObject, this.loadTableData);
  }

  loadTableData() {
    if (this.projectId && this.processId && this.state.effectiveRMP) {
      UIUtils.secureAjaxGET(`configurableTables/${this.projectId}?source=processExplorer&processId=${this.processId}&latestVersion=true`)
        .done(this.handleReceivedDataFromServer);
    }
  }

  async handleReceivedDataFromServer(records) {
    const project = records.project;
    const {
      onVisibleColumnsChange,
      onUnitOperationsLoad,
      selectedModelType,
      loadTypeaheadPromise
    } = this.props;
    await loadTypeaheadPromise;

    const unitOperations = Object.values(records.uoMap);
    const orderedUOList = orderAndIndexUnits(unitOperations, "id", false);

    this.setStateSafely({
      records,
      project,
      selectedModelType,
      orderedUOList,
    }, () => {
      this.setStateSafely({
        adaptedRecords: this.getAdaptedInfo(records, project),
      }, () => {
        this.columnSelectionHandler.handleResetColumnVisibility(onVisibleColumnsChange);
        onUnitOperationsLoad(this.state.orderedUOList);
        UIUtils.hideLoadingImage();
      });
    });
  }

  getAdaptedInfo(records, project) {
    const {effectiveRMP, orderedUOList} = this.state;
    const {
      selectedModelType,
      onTableDataUpdated,
      typeaheadOptions,
      onLoadPreviousAdaptedData,
    } = this.props;

    const {modelName} = PROCESS_LEVEL_MODELS_CONFIG[selectedModelType];
    const activeMapName = getResultActiveMap(modelName);
    const activeMap = records[activeMapName];
    const unitOperationId = this.props.unitOperationId || (orderedUOList?.length > 0 && orderedUOList[0].id) || null;

    let adaptedData = onLoadPreviousAdaptedData(selectedModelType);
    if (!adaptedData) {

      const resultCopy = {
        [activeMapName]: UIUtils.deepClone(activeMap),
        project: records.project,
      };

      const models = ["UnitOperation", "Step", "Material", "ProcessComponent"];
      const mapNamesToInclude = models.map((modelName) => getResultActiveMap(modelName));

      mapNamesToInclude.forEach((mapName) => {
        if (activeMapName !== mapName) {
          resultCopy[mapName] = records[mapName];
        }
      });

      const processResultsAdapter = PROCESS_LEVEL_MODELS_CONFIG[selectedModelType].adapter({
        results: resultCopy,
        effectiveRMP,
        selectedModelType,
        typeaheadOptions,
      });
      adaptedData = processResultsAdapter.getRows();
    }

    let filteredRows = unitOperationId === "All" ? adaptedData : [];
    if (unitOperationId && unitOperationId !== "All") {
      filteredRows = adaptedData.filter(this.createFilterByUOFunction(unitOperationId));
    }

    onTableDataUpdated(selectedModelType, adaptedData, filteredRows, project);
    return filteredRows;
  }

  createFilterByUOFunction(unitOperationId) {
    return row => {
      if (Array.isArray(row.UnitOperations)) {
        return row.UnitOperations.find(uo => uo.id === unitOperationId) || row.UnitOperations.length === 0;
      } else if (row.unitOperationId) {
        return row.unitOperationId === unitOperationId || row.unitOperationId === PROCESS_LEVEL_ID;
      }
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      selectedModelType,
      onVisibleColumnsChange,
      onTableStateChange,
      unitOperationId,
      processId,
    } = this.props;

    const oldSelectedModelType = prevProps.selectedModelType;
    const oldUnitOperationId = prevProps.unitOperationId;
    const {records, project, tableState} = this.state;

    if (records &&
      (selectedModelType !== oldSelectedModelType ||
        unitOperationId !== oldUnitOperationId)) {
      const adaptedRecords = this.getAdaptedInfo(records, project);
      this.setStateSafely(
        {
          adaptedRecords,
          selectedModelType,
          securityTypeName: this.getSecurityTypeName(selectedModelType),
        }, () =>
          this.columnSelectionHandler.handleResetColumnVisibility(onVisibleColumnsChange));
    }

    if (processId !== prevProps.processId) {
      this.processId = processId;
      this.loadTableData();
    }

    if (JSON.stringify(tableState) !== JSON.stringify(prevState.tableState)) {
      onTableStateChange(tableState);
    }
  }

  render() {
    const {t, selectedModelType, onVisibleColumnsChange, onUnitOperationChange} = this.props;
    const {
      adaptedRecords,
      effectiveRMP,
      project,
      visibleTableColumns,
      tableState,
      orderedUOList,
    } = this.state;

    const commonProps = {
      effectiveRMP,
      projectId: this.projectId,
      project,
      parent: this,
    };

    const {
      handleColumnOperationsChange,
    } = this.columnOperationsHandler;

    const {customFilters} = tableState || {};

    const config = PROCESS_LEVEL_MODELS_CONFIG[selectedModelType];
    const {fieldsConfig, customFiltersHandler, modelName, table} = config || {};
    const sort = this.columnOperationsAdapter.getDefaultSorting(tableState);
    const processExplorerTable = config ?
      table({
        id: "processExplorerTable",
        records: adaptedRecords || [],
        selectedModelType,
        fieldsConfig,
        tableState: {
          ...tableState,
          sort,
        },
        t,
        customFilters,
        customFiltersHandler,
        handleArchiveButton: this.handleArchiveButton,
        handleRestoreButton: this.handleRestoreButton,
        onImportButtonClick: this.handleImport,
        onEmptyTableAddClicked: this.handleEmptyTableAddButton,
        visibleTableColumns: visibleTableColumns || [],
        columnOperationsAdapter: this.columnOperationsAdapter,
        onColumnOperationsChange: handleColumnOperationsChange,
        ...commonProps,
      }) : "";

    return (
      <TableStateProvider>
        <Fragment>
          <TableToolBar modelName={modelName}
                        selectedModelType={selectedModelType}
                        enableAssessmentOptions={true}
                        visibleTableColumns={visibleTableColumns}
                        tableState={tableState}
                        operationsAdapter={this.columnOperationsAdapter}
                        operationsHandler={this.columnOperationsHandler}
                        showAssessmentOptions={PROCESS_MODELS_WITH_ACCEPTANCE_CRITERIA.includes(selectedModelType)}
                        onResetToDefaults={this.columnSelectionHandler?.handleResetToDefaults}
                        onVisibleTableColumnsChanged={(map) =>
                          this.columnSelectionHandler?.handleVisibleTableColumnsChanged(map, onVisibleColumnsChange)}
                        customFilters={customFilters}
                        customFiltersHandler={customFiltersHandler}
                        records={adaptedRecords}
                        quickFiltersZone={
                          <QuickFilterArea orderedUOList={orderedUOList}
                                           selectedModelType={selectedModelType}
                                           onUnitOperationChange={onUnitOperationChange}
                          />
                        }
                        afterBulkEdit={this.handleBulkEdit}
          />
          <div className={styles["configurable-table"]}>
            <div id="processContainer" className={`${styles["configurable-table-inner-container"]} table-container`}>
              <ErrorBar className={`mt-2 ${styles["error-bar"]}`} />
              <TableSettingsProvider visibleTableColumns={visibleTableColumns}>
                {processExplorerTable}
              </TableSettingsProvider>

            </div>
          </div>
          {this.renderArchivePopup()}
        </Fragment>
      </TableStateProvider>
    );
  }
}


export default I18NWrapper.wrap(WithNotification(TableViewPage), "processExplorer");
// i18next-extract-mark-ns-stop product
