export const getURLForSection = (sectionName: string, projectId?: string): string => {
  switch (sectionName) {
    case "Dashboard":
      return "/projects/dashboard.html?projectId=" + projectId;
    case "Control Methods":
      return "/controlMethods/list.html?projectId=" + projectId;
    case "Data":
      return "/batches/list.html?projectId=" + projectId;
    case "Documents":
      if (projectId) {
        return "/documents/documentsListInProject.html?projectId=" + projectId;
      } else {
        return "/documents/list.html";
      }
    case "TPP":
      return "/product/list.html?projectId=" + projectId + "&selectedProductListTab=TPP";
    case "QTPP":
      return "/product/list.html?projectId=" + projectId + "&selectedProductListTab=QTPP";
    case "Product":
      return "/product/list.html?projectId=" + projectId;
    case "Process Explorer":
      return "/processExplorer/processExplorer.html?projectId=" + projectId;
    case "RMPs":
      return "/rmps/list.html";
    case "Suppliers":
      return "/suppliers/list.html";
    case "Training":
    case "Individual Training Plans":
      return "/training/list.html";
    case "Curriculum":
    case "Curricula":
      return "/training/curricula/list.html";
    case "Library Material":
      return "/library/list.html";
    default:
      throw new Error("You need to add the URL for " + sectionName + " to title_bar");
  }
};
