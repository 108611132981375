"use strict";

import React from "react";
import ReactDOM from "react-dom";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import * as go from "gojs";
import * as UIUtils from "../../../ui_utils";
import AddMenuBuilder from "../add_menu_builder";
import ContextOptionsMenu from "../../widgets/context_options_menu";
import { TYPE_HEADER } from "../../adapters/diagram_results_adapter";
import BaseRecordButtonBuilder from "./base_record_button_builder";
import { TYPE_CODE } from "../../process_explorer_constants";

const GO = go.GraphObject.make;

/**
 * A div with this ID will house the menu.
 */
const PROCESS_EXPLORER_ADD_MENU_ID = "processExplorerAddMenu";
/**
 * This is where the PROCESS_EXPLORER_ADD_MENU_ID div will be rendered into.
 */
const PROCESS_EXPLORER_ADD_MENU_OUTER_DIV_ID = PROCESS_EXPLORER_ADD_MENU_ID + "OuterDiv";

const TYPES_WITHOUT_ADD_BUTTON = new Set([
  TYPE_CODE.MATERIAL_ATTRIBUTE,
  TYPE_CODE.PROCESS_PARAMETER,
  TYPE_CODE.IQA,
  TYPE_CODE.IPA,
]);

/*
  * This class is responsible for assembling all of the GoJS parts to provide the button that allows adding new items
  * in the process explorer. See also AddMenuBuilder that builds the data for the menu that drops down.
  *
  * The basics of this were originally borrowed from: https://gojs.net/latest/extensions/Buttons.js
 */
export default class AddButtonBuilder extends BaseRecordButtonBuilder {
  /**
   * @param diagramFacade {{}} The DiagramFacade we're rendering to.
   * @param diagram {{}} The GoJS diagram we're rendering to.
   * @param onClick {function} A function that is called on either left or right click. The first arg is the node clicked
   *                           on and the second arg is true for a left click and false for a right click.
   * @param t {function} For translation
   * @param projectId {int} The id of the project.
   * @param processId {int} The id of the process.
   * @param onAdd {function} A function that is called when the user tries to add a new instance.
   */
  constructor(diagramFacade, diagram, onClick, t, projectId, processId, onAdd, onHeaderMenuClick) {
    super("AddButton", PROCESS_EXPLORER_ADD_MENU_ID, PROCESS_EXPLORER_ADD_MENU_OUTER_DIV_ID, diagramFacade, diagram, onClick, t, projectId, processId);
    this.onAdd = onAdd;
    this.onHeaderMenuClick = onHeaderMenuClick;
  }

  handleAdd(nodeData, typeCode) {
    const parentKey = nodeData.staticPanelKey || nodeData.headerParentKey;
    const keyObj = parentKey ? UIUtils.parseKey(parentKey) : {};

    this.onAdd({
      typeCode: typeCode,
      parentId: keyObj.id,
      parentKey: parentKey,
      parentTypeCode: keyObj.typeCode,
      unitOperationId: nodeData.unitOperationId,
      stepId: nodeData.stepId,
    });
    this.diagram.currentTool.stopTool();
  }

  /**
   * See parent for definition.
   */
  getButtonGoJSProperties() {
    return {
      margin: 0,
      toolTip:
        GO(go.Adornment, "Auto",
          {
            isShadowed: false,
          },
          GO(go.Shape, "RoundedRectangle", {
            background: "#ffffff",
            margin: 3,
            stroke: "#c0c6cc",
            strokeWidth: 1,
            fill: null,
          }),
          GO(go.TextBlock,
            {
              background: "#ffffff",
              margin: 10,
              text: "You cannot add to an archived record.",

            },
          ),
          new go.Binding("visible", "",
            (data) => !!(data.deletedAt || data.isParentArchived || data.isProjectArchived)),
        ),
    };
  }

  /**
   * See parent for definition.
   */
  getIcon() {
    return faPlusCircle;
  }

  /**
   * See parent for definition.
   */
  getIconSize() {
    return {width: 15, height: 15};
  }

  /**
   * See parent for definition.
   */
  isVisible(node) {
    const data = node.data;
    return !!(node.isSelected || data.showAddButton || data.alwaysShowAddButton) && !TYPES_WITHOUT_ADD_BUTTON.has(data.category);
  }

  /**
   * See parent for definition.
   */
  fireShowMenu(event, obj) {
    const data = obj.part.data;
    if (!(data.deletedAt || data.isParentArchived || data.isProjectArchived)) {
      if (data.category === TYPE_HEADER && !data.showMenu) {
        this.handleAdd(data, data.headerTypeCode);
      } else {
        event.diagram.commandHandler.showContextMenu(obj);
      }
    }
  }

  /**
   * See parent for definition.
   */
  showMenu(button, ignored) {

    // Get the data on this node that the user clicked on.
    const data = button.part.data;

    if (data.category !== TYPE_HEADER || data.showMenu) {
      this.renderMenu(data);
      const menuElement = this.getMenuElement();
      menuElement.classList.add("d-block");
      menuElement.classList.remove("d-none");

      // Set the position for where the menu should render
      let lastPoint = this.diagram.lastInput.documentPoint;
      lastPoint.x = lastPoint.x - 10;
      lastPoint.y = lastPoint.y + 11;
      lastPoint = this.diagram.transformDocToView(lastPoint);
      const diagramCanvasOffset = $("#processExplorerContainerDiv").offset();
      menuElement.style.left = (diagramCanvasOffset.left + lastPoint.x) + "px";
      menuElement.style.top = this.getTopButNotOffScreen(diagramCanvasOffset.top + lastPoint.y, menuElement) + "px";
    }
  }

  /**
   * See parent for definition.
   */
  renderMenu(nodeData) {
    // Figure out what should go in the menu.
    let addMenuBuilder = new AddMenuBuilder(
      nodeData ? nodeData.category : "UO",
      nodeData ? nodeData.headerTypeCode : "UO",
      this.projectId,
      this.handleAdd.bind(this, nodeData),
      this.t,
      this.onHeaderMenuClick
    );

    // Make sure there's a place to render the menu.
    let elementToRenderIn = this.ensureElementToRenderInExists();

    // Render the menu.
    const addOptionsMenu = (
      <ContextOptionsMenu
        id={this.menuDivId}
        options={addMenuBuilder ? addMenuBuilder.build() : []}
      />
    );
    ReactDOM.render(addOptionsMenu, elementToRenderIn);
  }
}
