"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import moment from "moment";
import ApprovalRequestTooltip from "./approval_request_tooltip";
import ApproverTooltips from "./approver_tooltips";
import { getURLByTypeCodeAndIdAndVersionId, getUserPageURL } from "../../helpers/url_helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import HistoryItemTransitionsContainer from "./history_item_transitions_container";
import { FROM_LIBRARY_STATUS } from "../../library/library_constants";
import { EMPTY_STRING } from "../../helpers/constants/constants";
import BaseReactComponent from "../../base_react_component";

function FromLibraryHistoryLabel({version, previousVersion}) {
  const {fromLibrary} = version;
  const fromLibraryPrevious = previousVersion?.fromLibrary;

  if (!fromLibrary) {
    return EMPTY_STRING;
  }

  let {
    id,
    instanceId,
    name,
    status,
    model,
    majorVersion,
    minorVersion,
  } = fromLibrary;


  const isCreatedFromLibrary = (status === FROM_LIBRARY_STATUS.LINKED || status === FROM_LIBRARY_STATUS.SYNCED) && !fromLibraryPrevious;
  const isUnlinkedFromLibrary = (status === FROM_LIBRARY_STATUS.PROJECT_ONLY && (fromLibraryPrevious?.status === FROM_LIBRARY_STATUS.LINKED || fromLibraryPrevious?.status === FROM_LIBRARY_STATUS.SYNCED));
  const isLinkedTo = status === FROM_LIBRARY_STATUS.LINKED && fromLibraryPrevious && fromLibraryPrevious.status !== FROM_LIBRARY_STATUS.LINKED;
  const isSyncedTo = status === FROM_LIBRARY_STATUS.SYNCED && fromLibraryPrevious && fromLibraryPrevious.status !== FROM_LIBRARY_STATUS.SYNCED;

  let label = "";
  if (isCreatedFromLibrary) {
    label = "Created from ";
  }

  if (isLinkedTo) {
    label = "Linked to ";
  }

  if (isSyncedTo) {
    label = "Synced with";
  }

  if (isUnlinkedFromLibrary) {
    label = "Unlinked from ";
    name = fromLibraryPrevious.name;
    model = fromLibraryPrevious.model;
    minorVersion = fromLibraryPrevious.minorVersion;
    majorVersion = fromLibraryPrevious.majorVersion;
  }

  // Check if there are no changes in the material's `library connection` between 'version' and 'previousVersion'
  const noChangeInFromLibrary = fromLibrary
    && fromLibraryPrevious
    && fromLibrary?.instanceId === fromLibraryPrevious?.instanceId
    && fromLibrary?.majorVersion === fromLibraryPrevious?.majorVersion;

  // Check if no status changes occurred (Created, Linked, Synced, Unlinked)
  const noStatusChange = !isCreatedFromLibrary && !isLinkedTo && !isSyncedTo && !isUnlinkedFromLibrary;

  // Check if there are no changes in the material attribute's 'library specification connection' between 'version' and 'previousVersion'
  const noChangeInLinkedToSpecVersionId = version?.linkedToSpecificationVersionId
    && previousVersion?.linkedToSpecificationVersionId
    && version?.linkedToSpecificationVersionId === previousVersion?.linkedToSpecificationVersionId;

  // Check if there are no state changes: no changes in material library, and no changes in material attribute's library specification
  const noStateChange = (noChangeInFromLibrary && noStatusChange) || noChangeInLinkedToSpecVersionId;

  if (noStateChange) {
    return EMPTY_STRING;
  }

  if (fromLibrary?.instanceId !== fromLibraryPrevious?.instanceId) {
    if (status === FROM_LIBRARY_STATUS.SYNCED) {
      label = "Synced with";
    } else if (status === FROM_LIBRARY_STATUS.LINKED) {
      label = "Linked to ";
    } else if (fromLibraryPrevious && (!status || status === FROM_LIBRARY_STATUS.PROJECT_ONLY)) {
      label = "Unlinked from ";
      name = fromLibraryPrevious.name;
      model = fromLibraryPrevious.model;
      minorVersion = fromLibraryPrevious.minorVersion;
      majorVersion = fromLibraryPrevious.majorVersion;
    }
  } else if (fromLibrary?.majorVersion !== fromLibraryPrevious?.majorVersion) {
    if (status === FROM_LIBRARY_STATUS.SYNCED) {
      label = "Synced with";
    } else if (status === FROM_LIBRARY_STATUS.LINKED) {
      label = "Linked to ";
    }
  }

  if (fromLibrary.specification) {
    label = `Linked to Library Specification ${fromLibrary.specification.name} of`;
  }

  return label ? (
    <div className="offset-4 col-8 library-change">
      <span className="col-form-label library-change-status">{label}</span>
      <a href={getURLByTypeCodeAndIdAndVersionId(UIUtils.getTypeCodeForModelName(model),
        "View", instanceId, false, id)}
         rel="noopener noreferrer"
         target="_blank"
         className="library-change-label"
      >
        {`${name} v${majorVersion}.${minorVersion}`}
      </a>
    </div>
  ) : EMPTY_STRING;
}

/* This shows the history of a particular version of a given object. */
export default class HistoryItem extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      showTransitions: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    $(this.popover).popover({sanitizeFn: UIUtils.sanitizePopoverData});
  }

  handleClick(event) {
    event.preventDefault();
    this.props.onClick(this.props.version.id, event);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (nextProps.version !== this.props.version) ||
      (nextProps.isSelected !== this.props.isSelected) ||
      (nextState.showTransitions !== this.state.showTransitions);
  }

  handleCollapseVersionTransitions() {
    this.setStateSafely({
      showTransitions: false,
    });
  }

  handleExpandVersionTransitions() {
    this.setStateSafely({
      showTransitions: true,
    });
  }

  render() {
    let selectedClassName = this.props.isSelected ? "history-item-selected" : "";
    let version = this.props.version;
    let previousVersion = this.props.previousVersionAnyState;
    let versionTransitions = version.versionTransitions;
    let previousVersionTransitions = this.props.previousVersion ? this.props.previousVersion.versionTransitions : null;
    let versionApproved = versionTransitions[versionTransitions.length - 1].transition === UIUtils.VERSION_STATES.APPROVED;
    let versionObsolete = versionTransitions[versionTransitions.length - 1].transition === UIUtils.VERSION_STATES.OBSOLETE;
    let currentVersionTransition = versionTransitions[0];
    let currentVersionPreviousTransition = versionTransitions[1] ? versionTransitions[1] : null;
    let currentVersionTransitionInfo = "";
    let currentVersionTransitionApproverTooltips = "";
    let showCurrentVersionTransitionInfo = false;
    let showCurrentVersionTransitionTooltips = false;
    let historyItemId = `historyItem${version.majorVersion}-${version.minorVersion}`;
    let filteredPreviousTransitions = previousVersionTransitions ? previousVersionTransitions.filter(versionTransition => {
      return (versionTransition.transition !== UIUtils.VERSION_STATES.ROUTING);
    }) : [];

    let updateApprovalRequestTransition = currentVersionTransition;
    if (currentVersionTransition
      && currentVersionPreviousTransition
      && currentVersionPreviousTransition.approvalRequest) {
      updateApprovalRequestTransition = currentVersionPreviousTransition;
    } else if ((currentVersionTransition.transition === UIUtils.VERSION_STATES.APPROVED
        || currentVersionTransition.transition === UIUtils.VERSION_STATES.OBSOLETE)
      && filteredPreviousTransitions.length > 0) {
      updateApprovalRequestTransition = filteredPreviousTransitions[0];
    }

    if (updateApprovalRequestTransition && updateApprovalRequestTransition.approvalRequest) {
      let approvalRequest = updateApprovalRequestTransition.approvalRequest;
      let userIdToApprovalResponse = new Map();

      // Figure out if this version transition was unanimously approved.
      let isApproved = approvalRequest.approvalReqToUsers.length === updateApprovalRequestTransition.approvalResponses.length;
      if (updateApprovalRequestTransition.approvalResponses) {
        for (let approvalResponse of updateApprovalRequestTransition.approvalResponses) {
          userIdToApprovalResponse[approvalResponse.createdByUserId] = approvalResponse;
          isApproved = isApproved && approvalResponse.approve;
        }
      }

      showCurrentVersionTransitionInfo = (updateApprovalRequestTransition === currentVersionTransition);
      if (showCurrentVersionTransitionInfo) {
        currentVersionTransitionInfo = (
          <ApprovalRequestTooltip
            version={version}
            versionTransition={updateApprovalRequestTransition}
            userIdToApprovalResponse={userIdToApprovalResponse}
            isVersionTransitionTooltip={false}
            index="updated"
          />
        );
      }

      // If it's approved, we'll show these icons on the approved version.
      // If it's not approved, we will only show them on the latest version if the latest state is not Withdrawn
      showCurrentVersionTransitionTooltips = (
        (!isApproved
          && currentVersionTransition.transition !== UIUtils.VERSION_STATES.WITHDRAWN) ||
        (isApproved
          && currentVersionTransition.transition !== UIUtils.VERSION_STATES.ROUTING
          && (updateApprovalRequestTransition === filteredPreviousTransitions[0]
            || updateApprovalRequestTransition === currentVersionPreviousTransition)
        )
      );
      if (showCurrentVersionTransitionTooltips) {
        currentVersionTransitionApproverTooltips = (
          <ApproverTooltips
            version={version}
            versionTransition={updateApprovalRequestTransition}
            isVersionTransitionTooltip={false}
            index={0}
          />
        );
      }
    }

    const {
      name,
      customID,
      instanceId,
      id,
      model,
      minorVersion,
      majorVersion,
    } = this.props.clonedFrom || {};

    const autoGenerated = !currentVersionTransition.createdByUser;
    // This is dealing with Batches which do not have a name field but instead a customID
    const clonedFromName = name || customID;

    let rmpChanged = false;
    let rmpUrl = "#";
    if (version && previousVersion && version.rmpVersionId !== previousVersion.rmpVersionId) {
      rmpChanged = true;
      rmpUrl = getURLByTypeCodeAndIdAndVersionId("RMP", "View", version.rmpId, true, version.rmpVersionId);
    }

    let documentContentChanged = false;
    if (
      version &&
      previousVersion &&
      (version.DocumentContentId !== previousVersion.DocumentContentId ||
        version.documentContentVersionId !==
        previousVersion.documentContentVersionId)
    ) {
      documentContentChanged = true;
    }

    return (
      <div className={"row history-item no-gutters " + selectedClassName + (versionApproved ? " history-item-approved" : versionObsolete ? " history-item-obsolete" : "")}
           id={historyItemId}
      >
        {currentVersionTransition ? (
          <Fragment>
            <div className="col-2 history-version">
              <a onClick={this.handleClick}>
                {version.majorVersion}.{version.minorVersion}
              </a>
            </div>
            <div className="col-2 col-xl-1">
              {versionTransitions.length > 1 ? (
                this.state.showTransitions ? (
                  <FontAwesomeIcon id={"historyItem" + version.majorVersion + "-" + version.minorVersion + "_CollapseButton"}
                                   icon={faCaretDown}
                                   className="history-version-show-transitions"
                                   aria-label="Hide version transitions"
                                   onClick={this.handleCollapseVersionTransitions}
                  />
                ) : (
                  <FontAwesomeIcon id={"historyItem" + version.majorVersion + "-" + version.minorVersion + "_ExpandButton"}
                                   icon={faCaretRight}
                                   className="history-version-hide-transitions"
                                   aria-label="Show version transitions"
                                   onClick={this.handleExpandVersionTransitions}
                  />
                )
              ) : ""}
            </div>
            <div id={historyItemId + "-updatedOn"}
                 className="col-8 col-xl-9 history-item-date"
            >
              <a onClick={this.handleClick} title={moment(version.currentState !== UIUtils.VERSION_STATES.OBSOLETE ? version.updatedAt : version.createdAt).format(UIUtils.LONG_DATE_FORMAT_WITH_SECONDS_FOR_DISPLAY)}>
                {moment(version.currentState !== UIUtils.VERSION_STATES.OBSOLETE ? version.updatedAt : version.createdAt).format(UIUtils.LONG_DATE_FORMAT_FOR_DISPLAY)}
              </a>
              {showCurrentVersionTransitionInfo ? currentVersionTransitionInfo : ""}
            </div>
            {currentVersionTransition.transition === UIUtils.VERSION_STATES.APPROVED
            || currentVersionTransition.transition === UIUtils.VERSION_STATES.ARCHIVED
            || currentVersionTransition.transition === UIUtils.VERSION_STATES.RESTORED
            || currentVersionTransition.transition === UIUtils.VERSION_STATES.OBSOLETE
            || currentVersionTransition.transition === UIUtils.VERSION_STATES.ROUTING
            || currentVersionTransition.transition === UIUtils.VERSION_STATES.REJECTED ? (
              <div className="offset-4 col-8 history-updated-by history-updated-by-state">
                {currentVersionTransition.transition}
              </div>
            ) : (
              <div className="offset-4 col-8 offset-xl-3 col-xl-9 history-updated-by">
                <span className="history-updated-by-state">{version.currentState === UIUtils.VERSION_STATES.DRAFT ? "Drafted" : version.currentState}{autoGenerated ? " automatically" : ""} </span>
                <span>by </span>
                {
                  currentVersionTransition.createdByUser
                    ? (
                      <a href={getUserPageURL(currentVersionTransition.createdByUser)}
                         rel="noopener noreferrer"
                         target="_blank"
                      >
                        <span id="historyUserID" className="history-updated-by-user">{`${currentVersionTransition.createdByUser.firstName} ${currentVersionTransition.createdByUser.lastName}`.trim()}&nbsp;</span>
                      </a>
                    )
                    : (
                      <span
                        className="history-updated-by-user"
                        title="Version automatically generated by the system."
                      >QbDVision&nbsp;</span>
                    )
                }
              </div>
            )}
            <div className="offset-4 col-8 offset-xl-3 col-xl-9 " id={historyItemId + "-updatedOn-approverTooltips"}>
              {showCurrentVersionTransitionTooltips ? currentVersionTransitionApproverTooltips : ""}
            </div>
          </Fragment>
        ) : ""}
        {this.props.clonedFrom && version.majorVersion === 0 && version.minorVersion === 1 ? (
          <div className="offset-4 col-8 history-cloned-from">
            <span className="col-form-label history-cloned-from-label">Copied From:</span>
            <a href={getURLByTypeCodeAndIdAndVersionId(UIUtils.getTypeCodeForModelName(model ? model : this.props.baseTypeName),
              "View", instanceId, false, id)}
               rel="noopener noreferrer"
               target="_blank"
            >
              {`${clonedFromName} v${majorVersion}.${minorVersion}`}
            </a>
          </div>
        ) : ""}
        {rmpChanged ? (
          <div className="offset-4 col-8 offset-xl-3 col-xl-9 rmp-changed">
            <span className="col-form-label rmp-changed-label">RMP changed to:</span>
            <a href={rmpUrl} rel="noopener noreferrer"
               target="_blank"
            >{version.rmpLabel}</a>
          </div>
        ) : ""}
        {documentContentChanged ? (
          <div className="offset-4 col-8 history-cloned-from">
            <span className="col-form-label history-cloned-from-label history-updated-by-qbdvision">Updated QbDVision Doc</span>
          </div>
        ) : ""}
        <FromLibraryHistoryLabel version={version} previousVersion={previousVersion} />
        {this.props.isBulkAdd ? (
          <div className="offset-4 col-8 offset-xl-3 col-xl-9 history-bulk-added-by">
            <span className="col-form-label history-bulk-added-by-label">Bulk added by:</span>
            <a href={getUserPageURL(currentVersionTransition.createdByUser)}
               rel="noopener noreferrer"
               target="_blank"
            >
              <span className="history-updated-by-user">{currentVersionTransition.createdByUser.firstName} {currentVersionTransition.createdByUser.lastName}</span>
            </a>
          </div>
        ) : ""}
        <HistoryItemTransitionsContainer parent={this.props.parent}
                                         visible={!!this.state.showTransitions}
                                         versionApproved={this.props.isApproved}
                                         version={version}
                                         previousVersion={this.props.previousVersion}
        />
      </div>
    );
  }
}
