"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import { getReportURLByTypeAndId } from "../helpers/url_helper";
import BaseReactComponent from "../base_react_component";

const DEFAULT_PROPS = {
  thumbnailWidth: 77,
  thumbnailHeight: 107,
  thumbnailSrc: "/images/logo.svg",
};

/* This report thumbnail control used in the reports page for selecting a report
 * It displays a thumbnail of the report it links to, a Header link and a small description */
export default class ReportThumbnail extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  getThumbnailImage() {
    let {
      name, thumbnailSrc,
      thumbnailWidth, thumbnailHeight,
    } = this.props;

    return (<img id={`${UIUtils.convertToId(name)}ReportImage`}
                 src={thumbnailSrc || DEFAULT_PROPS.thumbnailSrc}
                 alt={name}
                 width={thumbnailWidth}
                 height={thumbnailHeight}
    />);
  }

  render() {
    let {
      name, type, projectId,
      description, disabled
    } = this.props;

    return (
      <div className="report-thumbnail-container col-6">
        <div className="row">
          <div className="report-thumbnail-image col-3">
            {disabled ?
              this.getThumbnailImage()
              :
              <a id={`${UIUtils.convertToId(name)}ReportImageLink`}
                 href={getReportURLByTypeAndId(type, projectId)}
                 rel="noopener noreferrer"
                 target="_blank"
              >
                {this.getThumbnailImage()}
              </a>
            }
          </div>
          <div className="report-thumbnail-link col">
            <div>
              {disabled ?
                <span id={`${UIUtils.convertToId(name)}ReportName`}
                      className="report-thumbnail-title-disabled"
                >
                {name}
              </span>
                :
                <a id={`${UIUtils.convertToId(name)}ReportName`}
                   href={getReportURLByTypeAndId(type, projectId)}
                   rel="noopener noreferrer"
                   target="_blank"
                >
                  {name}
                </a>}
            </div>
            <div id={`${UIUtils.convertToId(name)}ReportDescription`}
                 className={"report-thumbnail-description" +
                   (disabled ? " report-thumbnail-description-disabled" : "")}
            >
              {description}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReportThumbnail.defaultProps = DEFAULT_PROPS;
