var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
/**
 * @hidden
 */
export function HeaderCell(props) {
    var clickProps = props.onClick ? {
        onClick: props.onClick
    } : {};
    var defaultRendering = (React.createElement("span", __assign({ className: 'k-link' }, clickProps),
        React.createElement("span", { className: 'k-column-title' }, props.title || props.field || '\u00A0'),
        props.children));
    return (React.createElement(React.Fragment, null, props.render ?
        props.render.call(undefined, defaultRendering, props) :
        defaultRendering));
}
