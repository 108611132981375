import { __assign } from "tslib";
import { chainCommands, exitCode, newlineInCode, createParagraphNear, liftEmptyBlock, splitBlockKeepMarks, deleteSelection, joinBackward, selectNodeBackward } from 'prosemirror-commands';
import { undo, redo } from 'prosemirror-history';
import { undoInputRule } from 'prosemirror-inputrules';
import { splitListItemKeepMarks } from '../lists';
import { bold, italic, underline } from './commands';
import { toggleInlineFormat } from './../inline-style';
import { expandToWordWrap } from '../utils';
var mac = typeof navigator !== 'undefined' ? /Mac/.test(navigator.platform) : false;
// https://github.com/ProseMirror/prosemirror-example-setup/blob/master/src/keymap.js
export var buildKeymap = function (schema, options) {
    var keys = {};
    var applyToWord = (options && options.applyToWord) || false;
    keys['Mod-z'] = undo;
    keys['Shift-Mod-z'] = redo;
    // tslint:disable-next-line:no-string-literal
    keys['Backspace'] = chainCommands(undoInputRule, deleteSelection, joinBackward, selectNodeBackward);
    // tslint:disable-next-line:no-string-literal
    keys['Enter'] = chainCommands(newlineInCode, createParagraphNear, liftEmptyBlock, splitBlockKeepMarks);
    if (!mac) {
        keys['Mod-y'] = redo;
    }
    if (schema.marks.strong) {
        keys['Mod-b'] = expandToWordWrap(toggleInlineFormat, __assign(__assign({}, bold), { applyToWord: applyToWord }));
    }
    if (schema.marks.em) {
        keys['Mod-i'] = expandToWordWrap(toggleInlineFormat, __assign(__assign({}, italic), { applyToWord: applyToWord }));
    }
    if (schema.marks.u) {
        keys['Mod-u'] = expandToWordWrap(toggleInlineFormat, __assign(__assign({}, underline), { applyToWord: applyToWord }));
    }
    if (schema.nodes.hard_break) {
        var br_1 = schema.nodes.hard_break;
        var cmd = chainCommands(exitCode, function (state, dispatch) {
            dispatch(state.tr.replaceSelectionWith(br_1.create()).scrollIntoView());
            return true;
        });
        keys['Shift-Enter'] = cmd;
    }
    return keys;
};
export var buildListKeymap = function (schema) {
    var keys = {};
    if (schema.nodes.list_item) {
        // tslint:disable-next-line:no-string-literal
        keys['Enter'] = splitListItemKeepMarks(schema.nodes.list_item);
    }
    return keys;
};
