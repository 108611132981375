import { isPresent } from './utils';
import { Keys } from '@progress/kendo-react-common';
/**
 * @hidden
 */
var Navigation = /** @class */ (function () {
    function Navigation() {
    }
    Navigation.prototype.navigate = function (args) {
        var keyCode = args.keyCode;
        if (keyCode === Keys.up || keyCode === Keys.left) {
            return this.next({ current: args.current, min: args.min, max: args.max, step: -1 });
        }
        else if (keyCode === Keys.down || keyCode === Keys.right) {
            return this.next({ current: args.current, min: args.min, max: args.max, step: 1 });
        }
        else if (keyCode === Keys.home) {
            return 0;
        }
        else if (keyCode === Keys.end) {
            return args.max;
        }
    };
    Navigation.prototype.next = function (args) {
        if (!isPresent(args.current)) {
            return args.min;
        }
        else {
            return Math.min(args.max, Math.max(args.current + args.step, args.min));
        }
    };
    return Navigation;
}());
export { Navigation };
