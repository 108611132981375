"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import HistoryItem from "./history_item";
import ToggleAttribute from "../attributes/toggle_attribute";
import BaseReactComponent from "../../base_react_component";

/* This shows the history of a given object. */
export default class HistoryContainer extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  handleShowMajorVersionsOnlyChange(attributeName, checked) {
    let versions = this.filterHistoryVersions(!!checked);
    let selectedVersionId = this.props.parent.getCurrentDiffingVersion().versionId;
    let selectedVersion = versions.find(version => version.id === selectedVersionId);
    this.props.parent.handleShowMajorVersionsChange(selectedVersion ? selectedVersion.id : null, !!checked);
  }

  filterHistoryVersions(showMajorVersionsOnly) {
    return this.props.versions.filter(version => {
      return !showMajorVersionsOnly
        || (showMajorVersionsOnly && version.majorVersion !== 0 && version.minorVersion === 0);
    });
  }

  render() {
    // Create an array of all the previous versions
    let historyItems = [];
    let allVersions = this.props.versions;
    if (allVersions) {
      let selectedId = this.props.parent.getCurrentDiffingVersion().versionId;
      // Select the approved version only if we're showing the approved data and it's not the most recent version.
      if (selectedId === -1 && this.props.parent.getCurrentState() === UIUtils.VERSION_STATES.APPROVED && this.props.versions[0].minorVersion !== 0) {
        selectedId = this.props.versions.find(version => version.minorVersion === 0 && !version.deletedAt).id;
      }
      historyItems = this.filterHistoryVersions(this.props.showMajorVersionsOnly)
        .map(version => {
          let isApproved = (version.majorVersion >= 1 && version.minorVersion === 0 && !version.deletedAt);
          let indexOfVersion = allVersions.findIndex(currentVersion => currentVersion.id === version.id);
          let isBulkAdded = (indexOfVersion === allVersions.length - 1) && this.props.isBulkAdd;

          return (
            <HistoryItem key={version.id}
                         parent={this.props.parent}
                         version={version}
                         previousVersion={isApproved && allVersions.length > 1 ? allVersions[indexOfVersion + 1] : null}
                         previousVersionAnyState={allVersions.length > 1 ? allVersions[indexOfVersion + 1] : null}
                         isSelected={version.id === selectedId}
                         isApproved={isApproved}
                         isBulkAdd={isBulkAdded}
                         baseTypeName={this.props.baseTypeName}
                         clonedFrom={this.props.clonedFrom}
                         onClick={this.props.parent.handleRevisionChange}
            />
          );
        });
    } else {
      historyItems.push(<div key="1" className={"history-item-empty" + this.getClassForLoading()} />);
    }

    return (
      <div className={(this.props.className ? (" " + this.props.className) : "") +
        (this.props.showWithRelatedRecords ? " history-panel" : "")}
      >
        {!this.props.showWithRelatedRecords ? (
          <div className="row history-title">
            History
          </div>
        ) : ""}
        <div className="row history-filter-control">
          <ToggleAttribute id="showMajorVersionsOnlyHistoryFilter"
                           name="showMajorVersionsOnly"
                           displayName="Show major versions only"
                           onChange={this.handleShowMajorVersionsOnlyChange}
                           parent={this.props.parent}
                           tooltipText="Use this to toggle minor versions visibility on or off."
          />
        </div>
        {historyItems}
      </div>
    );
  }
}
