"use strict";

import { CompositeDataTransform } from "./composite_data_transform";
import { BuildUpDataTransform } from "./build_up_data_transform";
import { AddTopLevelInformationTransform } from "./canned_reports/add_top_level_information_transform";

export function cannedReportTransform(children) {
  return {
    type: CompositeDataTransform,
    children: [
      ...children,
      {type: AddTopLevelInformationTransform}
    ]
  };
}

export function cannedReportWithMultipleTransforms(children) {
  return {
    type: BuildUpDataTransform,
    children: [
      ...children,
      {type: AddTopLevelInformationTransform}
    ]
  };
}
