"use strict";

import {
  RISK_TABLE_REPORTS_ENUM
} from "../../../constants/report_constants";
import React from "react";
import * as StickyAdapter from "../../../../utils/sticky_adapter";
import ReactDOMServer from "react-dom/server";
import QuickPanelLink from "../../../../editor/quick/quick_panel_link";
import { getURLByTypeCodeAndId } from "../../../../helpers/url_helper";
import * as CommonRiskTable from "../../utils/risk_tables_helper";
import * as UIUtils from "../../../../ui_utils";


/**
 * This is the base table adaptor. Table adapter is mainly intended to adapt from our data model types to datatables.net dom objects.
 */
export class TableAdapter {

  constructor(reportKey, tableData, props) {

    this.reportSettings = CommonRiskTable.getReportSettings(reportKey);

    this.props = props;
    this.modelName = this.reportSettings.modelName;
    this.report = RISK_TABLE_REPORTS_ENUM[reportKey];
    this.reportTitle = this.reportSettings.rowModelName;

    this.dynamicColumns = tableData.uoIdToColumnsMap;
    this.columnAttributes = tableData.columnAttributes;
  }

  getColumnsForReport() {
    UIUtils.Ensure.notImplemented("getColumnsForReport");
  }

  getColumnDefinitionsForReport() {
    UIUtils.Ensure.notImplemented("getColumnDefinitionsForReport");
  }

  createReportHeader() {
    UIUtils.Ensure.notImplemented("createReportHeader");
  }

  createReportFooter() {
    UIUtils.Ensure.notImplemented("createReportFooter");
  }


  handleCreatedHeaderRowCallback(thead) {

    // Make the header sticky if the user scrolls
    for (const th of $(thead).find("th")) {
      StickyAdapter.makeItSticky(th);
      $(th).addClass("risk-tables-report-table-th");
    }

    // Make the links for dynamic columns
    let counter = 1;
    for (let [key, value] of this.dynamicColumns) {
      let columnAttributes = this.columnAttributes[key];
      $(thead).find("th").eq(counter++).html(
        ReactDOMServer.renderToStaticMarkup(
          <div>
            <div>
              <div>
                <QuickPanelLink href={`javascript:window.handleQuickEditRecord('${columnAttributes.sourceModelType}', ${columnAttributes.sourceModelId}, ${columnAttributes.isLastVersion ? null : columnAttributes.versionId});`}>
                  {columnAttributes.sourceModelType + "-" + columnAttributes.sourceModelId + " - " + value}
                </QuickPanelLink>
              </div>

              {this.reportSettings.showParentInCols && columnAttributes.sourceParentModelType ? (
                <div className="risk-tables-row-header-cell-uo">
                  <a href={getURLByTypeCodeAndId(columnAttributes.sourceParentModelType, "View", columnAttributes.sourceParentModelId)}
                     rel="noopener noreferrer"
                     target="_blank"
                  >
                    {columnAttributes.sourceParentModelType + "-" + columnAttributes.sourceParentModelId + " - " + columnAttributes.sourceParentModel}
                  </a>
                </div>
              ) : null}
              <div>
                {
                  columnAttributes.isLastVersion ? "" : (
                    <span className="risk-tables-column-version">{"Version: " + columnAttributes.majorVersion + "." + columnAttributes.minorVersion}&nbsp;</span>
                  )
                }
                <span className="risk-tables-column-stamp">{CommonRiskTable.getStampFromState(columnAttributes.columnState)}</span>
              </div>
            </div>
          </div>
        ));
    }
  }

  // eslint-disable-next-line no-unused-vars
  handleCreatedFooterRowCallback(tableId) {
    UIUtils.Ensure.notImplemented("handleCreatedFooterRowCallback", {tableId});
  }

  // eslint-disable-next-line no-unused-vars
  markColumnsRequiresSorting(tableId, defaultOrder = "asc") {
    UIUtils.Ensure.notImplemented("markColumnsRequiresSorting", {tableId, defaultOrder});
  }
}
