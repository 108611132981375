"use strict";

import React from "react";
import * as I18NWrapper from "../../i18n/i18n_wrapper";

// i18next-extract-mark-ns-start batches
import BaseReactComponent from "../../base_react_component";

/**
 * This renders the generate import link template.
 */
class GenerateImportTemplateLink extends BaseReactComponent {
  render() {
    const {t, onGenerateImportTemplateLinkClick} = this.props;

    return (
      <a id="generateImportTemplateLink"
         onClick={onGenerateImportTemplateLinkClick}
         rel="noreferrer noopener"
      >
        {t("Generate Template")}
      </a>
    );
  }
}

export default I18NWrapper.wrap(GenerateImportTemplateLink, ["batches"]);

// i18next-extract-mark-ns-stop batches
