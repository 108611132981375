"use strict";

import React from "react";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import BaseReactComponent from "../../base_react_component";
import { QBDLink } from "../../utils/withRouter";

const PRODUCT_TABS = ["TPP", "QTPP", "Product"];

/* This shows the title bar in red at the top of the screen */

// i18next-extract-mark-ns-start widgets
class NavBar extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {t, projectId, selected} = this.props;
    return (
      <div className="nav-bar">
        <div className="container-fluid container-fluid-body">
          <div className="row">
            <div
              className={"col nav-bar-item" + (selected === "Dashboard" ? " nav-bar-item-selected" : "")}
              id="dashboardNavBarLink"
            >
              <QBDLink to={"/projects/dashboard.html?projectId=" + projectId}>{t("Dashboard")}</QBDLink>
            </div>
            <div className={"col nav-bar-item" + (PRODUCT_TABS.includes(selected) ? " nav-bar-item-selected" : "")}
                 id="productNavBarLink"
            >
              <QBDLink to={"/product/list.html?projectId=" + projectId}>{t("Product")}</QBDLink>
            </div>
            <div className={"col nav-bar-item" + (selected === "Process Explorer" ? " nav-bar-item-selected" : "")}
                 id="processExplorerNavBarLink"
            >
              <a href={
                "/processExplorer/processExplorer.html?projectId=" + projectId}
              >{t("Process")}</a>
            </div>
            <div className={"col-xs-4 col-sm-2 nav-bar-item" + (selected === "Data" ? " nav-bar-item-selected" : "")}
                 id="dataNavBarLink"
            >
              <QBDLink to={
                "/batches/list.html?projectId=" + projectId}
              >{t("Data")}</QBDLink>
            </div>
            <div
              className={"col nav-bar-item" + (selected === "Control Methods" ? " nav-bar-item-selected" : "")}
              id="controlMethodsNavBarLink"
            >
              <QBDLink to={"/controlMethods/list.html?projectId=" + projectId}>{t("Control")}</QBDLink>
            </div>
            <div className={"col nav-bar-item" + (selected === "Reports" ? " nav-bar-item-selected" : "")}
                 id="reportsNavBarLink"
            >
              <QBDLink to={"/reports/list.html?projectId=" + projectId}>{t("Reports")}</QBDLink>
            </div>
            <div className={"col nav-bar-item" + (selected === "Documents" ? " nav-bar-item-selected" : "")}
                 id="documentsNavBarLink"
            >
              <QBDLink to={"/documents/documentsListInProject.html?projectId=" + projectId}>{t("Documents")}</QBDLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(NavBar, "widgets");
// i18next-extract-mark-ns-stop widgets
