"use strict";

import React from "react";
import RMPRiskComparisonWidget from "./rmp_risk_comparison_widget";
import BaseReactComponent from "../../../base_react_component";

export default class RMPRiskComparisonTab extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let {sourceRMP, newRMP, tab} = this.props;
    return (
      <div id={tab ? `${tab}RmpConversionMatrixWidgetDetails` : "rmpConversionMatrixWidgetDetails"}
           className="row conversion-matrix-widget-details"
      >
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <RMPRiskComparisonWidget
                name={tab ? `${tab}ImpactTable` : "ImpactTable"}
                showHeaderColumns={true}
                riskTitle="Impact:"
                sourceRiskScale={sourceRMP.RMPToImpacts}
                targetRiskScale={newRMP.RMPToImpacts}
              />
            </div>
            <div className="col-6">
              <RMPRiskComparisonWidget
                name={tab ? `${tab}CapabilityTable` : "CapabilityTable"}
                showHeaderColumns={true}
                riskTitle="Capability:"
                sourceRiskScale={sourceRMP.RMPToCapabilityRisks}
                targetRiskScale={newRMP.RMPToCapabilityRisks}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <RMPRiskComparisonWidget
                name={tab ? `${tab}UncertaintyTable` : "UncertaintyTable"}
                showHeaderColumns={false}
                riskTitle="Uncertainty:"
                sourceRiskScale={sourceRMP.RMPToUncertainties}
                targetRiskScale={newRMP.RMPToUncertainties}
              />
            </div>
            <div className="col-6">
              <RMPRiskComparisonWidget
                name={tab ? `${tab}DetectabilityTable` : "DetectabilityTable"}
                showHeaderColumns={false}
                riskTitle="Detectability:"
                sourceRiskScale={sourceRMP.RMPToDetectabilityRisks}
                targetRiskScale={newRMP.RMPToDetectabilityRisks}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
