import { Plugin } from 'prosemirror-state';
import { TableMap, tableNodeTypes } from 'prosemirror-tables';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { cellIndexes, domCellAround, otherResizeHandle, otherResizing, parseStyle, setNodeStyle, tableRowResizeKey as key, edgeCell } from './utils';
var TableRowView = /** @class */ (function () {
    function TableRowView() {
    }
    TableRowView.prototype.ignoreMutation = function (record) {
        return record.type === 'attributes' && record.attributeName === 'style' && record.target.nodeName === 'TR';
    };
    return TableRowView;
}());
export function rowResizing() {
    var handleWidth = 5;
    var plugin = new Plugin({
        key: key,
        state: {
            init: function (_, state) {
                this.spec.props.nodeViews[tableNodeTypes(state.schema).row.name] = function (_node, _view) { return new TableRowView(); };
                return new ResizeState(-1, null);
            },
            apply: function (tr, prev) {
                return prev.apply(tr);
            }
        },
        props: {
            attributes: function (state) {
                if (otherResizeHandle(key, state)) {
                    return null;
                }
                var pluginState = key.getState(state);
                return pluginState.activeHandle > -1 ? { class: 'resize-cursor-vertical' } : null;
            },
            handleDOMEvents: {
                mousemove: function (view, event) {
                    if (!otherResizing(key, view.state)) {
                        handleMouseMove(view, event, handleWidth);
                    }
                    return false;
                },
                mouseleave: function (view) {
                    handleMouseLeave(view);
                    return false;
                },
                mousedown: function (view, event) {
                    return handleMouseDown(view, event);
                }
            },
            decorations: function (state) {
                if (otherResizing(key, state)) {
                    return DecorationSet.empty;
                }
                var pluginState = key.getState(state);
                if (pluginState.activeHandle > -1) {
                    return handleDecorations(state, pluginState.activeHandle);
                }
            },
            nodeViews: {}
        }
    });
    return plugin;
}
function pointsAtCell($pos) {
    return $pos.parent.type.spec.tableRole === 'row' && $pos.nodeAfter;
}
var ResizeState = /** @class */ (function () {
    function ResizeState(activeHandle, dragging) {
        this.activeHandle = activeHandle;
        this.dragging = dragging;
    }
    ResizeState.prototype.apply = function (tr) {
        var state = this, action = tr.getMeta(key);
        if (action && action.setHandle != null) {
            return new ResizeState(action.setHandle, null);
        }
        if (action && action.setDragging !== undefined) {
            return new ResizeState(state.activeHandle, action.setDragging);
        }
        if (state.activeHandle > -1) {
            var handle = tr.mapping.map(state.activeHandle, -1);
            if (!pointsAtCell(tr.doc.resolve(handle))) {
                handle = null;
            }
            state = new ResizeState(handle, state.dragging);
        }
        return state;
    };
    return ResizeState;
}());
function handleMouseMove(view, event, handleWidth) {
    var pluginState = key.getState(view.state);
    if (!pluginState.dragging) {
        var target = domCellAround(event.target), cell = -1;
        if (target) {
            var indexes = cellIndexes(target);
            var _a = target.getBoundingClientRect(), top_1 = _a.top, bottom = _a.bottom;
            if (Math.abs(event.clientY - top_1) <= handleWidth && indexes.rowIndex > 0) {
                indexes.rowIndex -= target.rowSpan;
                cell = edgeCell(view, event, indexes);
            }
            else if (bottom - event.clientY > 0 && bottom - event.clientY <= handleWidth) {
                cell = edgeCell(view, event, indexes);
            }
        }
        if (cell !== pluginState.activeHandle) {
            updateHandle(view, cell);
        }
    }
}
function handleMouseLeave(view) {
    var pluginState = key.getState(view.state);
    if (pluginState.activeHandle > -1 && !pluginState.dragging) {
        updateHandle(view, -1);
    }
}
function handleMouseDown(view, event) {
    var pluginState = key.getState(view.state);
    if (pluginState.activeHandle === -1 || pluginState.dragging) {
        return false;
    }
    var doc = view.state.doc;
    var $cell = doc.resolve(pluginState.activeHandle);
    var row = $cell.parent;
    var table = $cell.node(-1);
    var rowHeightStr = parseStyle(row.attrs.style).height;
    var tableHeight = parseStyle(table.attrs.style).height;
    var rowHeight = rowHeightStr ? parseFloat(rowHeightStr) : 0;
    if (!rowHeightStr) {
        var tr = view.nodeDOM(pluginState.activeHandle);
        rowHeight = tr.offsetHeight;
    }
    view.dispatch(view.state.tr.setMeta(key, {
        setDragging: {
            startY: event.clientY,
            startHeight: { rowHeight: rowHeight, tableHeight: tableHeight }
        }
    }));
    function finish(ev) {
        ev.view.removeEventListener('mouseup', finish);
        ev.view.removeEventListener('mousemove', move);
        var curPluginState = key.getState(view.state);
        if (curPluginState.dragging) {
            var tr = view.state.tr.setMeta(key, { setDragging: null });
            updateRowHeight(view, tr, curPluginState.activeHandle);
            view.dispatch(tr);
        }
    }
    function move(ev) {
        if (!ev.which) {
            return finish(ev);
        }
        var curPluginState = key.getState(view.state);
        var dragged = draggedHeight(curPluginState.dragging, ev);
        var offset = ev.clientY - curPluginState.dragging.startY;
        displayRowHeight(view, curPluginState.activeHandle, dragged, offset, tableHeight);
    }
    event.view.addEventListener('mouseup', finish);
    event.view.addEventListener('mousemove', move);
    event.preventDefault();
    return true;
}
function draggedHeight(dragging, event) {
    var offset = event.clientY - dragging.startY;
    return dragging.startHeight.rowHeight + offset;
}
function updateHandle(view, value) {
    var tr = view.state.tr;
    tr.setMeta('addToHistory', false);
    tr.setMeta(key, { setHandle: value });
    view.dispatch(tr);
}
function updateRowHeight(view, tr, cellPos) {
    var doc = view.state.doc;
    var $cell = doc.resolve(cellPos);
    var row = $cell.parent;
    var rowPos = $cell.posAtIndex(0) - 1;
    var dom = view.nodeDOM(rowPos);
    var win = (dom.ownerDocument && dom.ownerDocument.defaultView) || window;
    dom.style.height = win.getComputedStyle(dom).height;
    tr.setNodeMarkup(rowPos, null, setNodeStyle(row.attrs, 'height', dom.style.height));
    var table = dom && dom.closest('table');
    var tableHeight = table && table.style.height;
    if (tableHeight) {
        var $pos = doc.resolve(rowPos);
        var tablePos = $pos.start($pos.depth) - 1;
        tr.setNodeMarkup(tablePos, null, setNodeStyle($pos.parent.attrs, 'height', tableHeight));
    }
}
function displayRowHeight(view, cellPos, height, offset, tableHeight) {
    var cell = view.nodeDOM(cellPos);
    var row = cell && cell.parentNode;
    if (row) {
        row.style.height = height + 'px';
        var win = (row.ownerDocument && row.ownerDocument.defaultView) || window;
        row.style.height = win.getComputedStyle(row).height;
        var table = row.closest('table');
        var newHeight = (parseFloat(tableHeight) + offset) + 'px';
        var current = table && table.style.height;
        if (current && current !== newHeight) {
            table.style.height = (parseFloat(tableHeight) + offset) + 'px';
            table.style.height = win.getComputedStyle(table).height;
        }
    }
}
function handleDecorations(state, cell) {
    var decorations = [];
    if (typeof cell !== 'number') {
        return DecorationSet.empty;
    }
    var $cell = state.doc.resolve(cell), table = $cell.node(-1), map = TableMap.get(table), start = $cell.start(-1);
    var cellsPositions = [];
    var cellPos = cell - start;
    var rowIndex = -1;
    for (var i = 0; i < map.height; i++) {
        cellsPositions.push(map.map.slice(i * map.width, (i * map.width) + map.width));
        if (cellsPositions[i].indexOf(cellPos) !== -1) {
            rowIndex = i;
        }
    }
    var cells = [];
    cellsPositions[rowIndex].forEach(function (c, i) {
        if (!cellsPositions[rowIndex + 1] || c !== cellsPositions[rowIndex + 1][i]) {
            cells.push(c);
        }
    });
    cells.forEach(function (cPos) {
        var cellNode = table.nodeAt(cPos);
        var widgetPos = start + cPos + (cellNode ? cellNode.nodeSize : 0) - 1;
        var dom = document.createElement('div');
        dom.className = 'row-resize-handle';
        decorations.push(Decoration.widget(widgetPos, dom));
    });
    return DecorationSet.create(state.doc, decorations);
}
