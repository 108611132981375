"use strict";

import React, { Component } from "react";
import { RiskTooltip } from "./risk_tooltip";

export class CompositeRiskTooltip extends Component {
  constructor(props) {
    super(props);
    const {risks, rmp} = this.props;

    if (!risks || risks.length <= 0) {
      throw new TypeError("The prop 'risks' must be a non-empty array");
    }

    if (!rmp) {
      throw new TypeError("The prop 'rmp' must be defined");
    }

    this.getTooltip = this.getTooltip.bind(this);
  }

  getTooltip(risk, attribute) {
    const {rmp} = this.props;
    return <RiskTooltip key={risk.type} type={risk.type} value={risk.value} attribute={attribute} rmp={rmp} />;
  }

  render() {
    const {risks, attribute} = this.props;

    if (risks.length > 1) {
      return (
        <div>
          {risks.map(risk => this.getTooltip(risk, attribute))}
        </div>
      );
    } else {
      return (
        this.getTooltip(risks[0], attribute)
      );
    }
  }
}
