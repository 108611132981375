"use strict";

import React from "react";
import * as go from "gojs";
import ImplementationNeededError from "../../../utils/implementation_needed_error";
import { TYPE_CODE, TYPE_CODE_TO_BORDER_COLOR } from "../../process_explorer_constants";

const GO = go.GraphObject.make;

/*
  * This base class is responsible for assembling all of the GoJS parts to provide a button that shows a menu of options
  * under a record in the process explorer.
  *
  * The basics of this were originally borrowed from: https://gojs.net/latest/extensions/Buttons.js
 */
export default class BaseRecordButtonBuilder {
  /**
   * @param goJSButtonName {string} The name to use when registering this button with GoJS.
   * @param menuDivId {string} The ID of the div that contains the menu that will be recreated every time somebody
   *                           clicks on this button.
   * @param menuOuterDivId {string} The ID of the div around the menuDivId that contains the menu (and won't change).
   * @param diagramFacade {{}} The DiagramFacade we're rendering to.
   * @param diagram {{}} The GoJS diagram we're rendering to.
   * @param onClick {function} A function that is called on either left or right click. The first arg is the node clicked
   *                           on and the second arg is true for a left click and false for a right click.
   * @param t {function} For translation
   * @param projectId {int} The id of the project.
   * @param processId {int} The id of the process.
   */
  constructor(goJSButtonName, menuDivId, menuOuterDivId, diagramFacade, diagram, onClick, t, projectId, processId) {
    this.goJSButtonName = goJSButtonName;
    this.menuDivId = menuDivId;
    this.menuOuterDivId = menuOuterDivId;
    this.diagramFacade = diagramFacade;
    this.diagram = diagram;
    this.onClick = onClick;
    this.t = t;
    this.projectId = projectId;
    this.processId = processId;

    this.buildButton = this.buildButton.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.buildMenu = this.buildMenu.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
  }

  /**
   * @param isParent true if the button menu is for PP parent
   */
  buildButton(isParent) {
    // Render a default menu so there's something to attach to.
    this.renderMenu();
    go.GraphObject.defineBuilder(this.goJSButtonName, () => {
      let button = /** @type {Panel} */ (
        GO("Button",
          new go.Binding("_buttonFillOver", "", this.getButtonFillOver),
          new go.Binding("visible", "", this.isVisible).ofObject(),
          {
            alignment: new go.Spot(1, 0.5, 0, 0),
            alignmentFocus: go.Spot.Left,
            // Colors normally
            "ButtonBorder.stroke": "transparent",
            "ButtonBorder.fill": null,
            // Colors on hover
            "_buttonStrokeOver": "transparent",
            contextMenu: this.buildMenu(true, true, isParent),
            ...this.getButtonGoJSProperties(),
          },
          GO(go.Shape,
            {
              stroke: "#ffffff",
              fill: "#859099",
              strokeWidth: 0.5,
              desiredSize: GO(go.Size, this.getIconSize()),
              geometry: go.Geometry.parse(this.getIcon().icon[4], true),
            },
          ),
        )
      );

      button.click = (event, obj) => {
        // For some reason, GoJS sends this event in again in an infinite loop when we call showContextMenu() below. So ignore it the second time.
        // noinspection JSUnresolvedVariable
        if (!event._hasBeenSeen) {
          event._hasBeenSeen = true;
          // noinspection JSUndefinedPropertyAssignment
          event.event._hasBeenSeen = true;
          event.diagram.select(obj.part);
          this.fireShowMenu(event, obj);

          // GoJS also seems to reuse event objects, which is annoying.
          setTimeout(() => event._hasBeenSeen = false, 100);
        }
      };


      return button;
    });
  }

  /**
   * @param positionUnderButton if true, locates the menu close to the most recently button pressed. Otherwise it ends up
   *                        where the mouse was last seen (which is only used by the right click context menu).
   * @param isLeftClick false means that this a context menu 
   * @param isParent true if the menu is for PP parent 
   * @return {*} The GoJS context menu that shows on the screen
   */
  buildMenu(positionUnderButton, isLeftClick, isParent) {
    // This is the HTML context menu:
    this.contextMenu = GO(go.HTMLInfo, {
      show: (obj) => {
        this.showMenu(obj, positionUnderButton);
        let record = isParent ? obj.part.data.parent : obj.part.data;
        this.onClick(record, isLeftClick);

        // Use a window click listener to remove the context menu if the user clicks elsewhere on the page.
        setImmediate(() => {
          window.addEventListener("click", this.hideMenu, true);
        });
      },
      hide: () => {
        const addMenuElement = this.getMenuElement();
        addMenuElement.classList.add("d-none");
        addMenuElement.classList.remove("d-block");

        window.removeEventListener("click", this.hideMenu, true);
      }
    });

    // We don't want the div acting as a context menu to have a (browser) context menu!
    const addMenuElement = this.getMenuElement();
    if (addMenuElement) {
      addMenuElement.addEventListener("contextmenu", function(e) {
        e.preventDefault();
        return false;
      }, false);
    }
    return this.contextMenu;
  }

  /**
   * This utility to decide if archive context option will be disabled for the selected node category...
   * @param node The selected node
   * @param processes The cache of processes in this project.
   * @returns {boolean} Whether archive should be disabled for this node.
   */
  isArchiveDisabled(node, processes) {
    let disableArchiveOption = false;
    if (node.category && node.category === TYPE_CODE.PROCESS) {
      disableArchiveOption = (processes && processes.length === 1);
    }
    return disableArchiveOption;
  }

  /**
   * This utility to decide if copy context option can be activated for the selected node category, by default all node categories can activate copy options.
   * @param node the selected node
   * @returns {boolean}
   */
  isCopyDisabled(node) {
    return node.deletedAt != null;
  }

  hideMenu() {
    if (this.diagram.currentTool instanceof go.ContextMenuTool) {
      this.diagram.currentTool.doCancel();
    }
  }

  ensureElementToRenderInExists() {
    let elementToRenderIn = document.getElementById(this.menuOuterDivId);
    if (!elementToRenderIn) {
      // Create it
      $(document.body).append(`<div id="${this.menuOuterDivId}"></div>`);
      elementToRenderIn = document.getElementById(this.menuOuterDivId);
    }
    return elementToRenderIn;
  }

  /**
   * @return {HTMLElement} The DOM element that holds the menu to be built.
   */
  getMenuElement() {
    return document.getElementById(this.menuDivId);
  }

  /**
   * Implement this to return properties on the button.
   *
   * @return {{}} an object of properties
   * @abstract
   */
  getButtonGoJSProperties() {
    throw new ImplementationNeededError();
  }

  /**
   * Implement this to return the icon for the button.
   *
   * @return {{}}
   * @abstract
   */
  getIcon() {
    throw new ImplementationNeededError();
  }

  /**
   * Implement this to return the width and height of the icon.
   * @return {{width: number, height: number}}
   * @abstract
   */
  getIconSize() {
    return {width: 5, height: 15};
  }

  /**
   * Override this to decide if the button should be shown to the user on a given node.
   *
   * @return {boolean}
   */
  isVisible(node) {
    return !!(node.isSelected || node.data.showContextMenuButton);
  }

  getButtonFillOver(data) {
    return TYPE_CODE_TO_BORDER_COLOR.get(data.category) || "#dbe1e4";
  }

  /**
   * Override this to instruct GoJS to show the context menu.
   *
   * @param event The GoJS click event.
   * @param obj The object clicked on.
   */
  fireShowMenu(event, obj) {
    event.diagram.commandHandler.showContextMenu(obj);
  }

  /**
   * Implement this to set the display on the HTML menu div to show it.
   *
   * @param obj {{}} The object clicked on.
   * @param positionUnderButton if true, locates the menu close to the most recently button pressed. Otherwise it ends up
   *                        where the mouse was last seen.
   * @abstract
   */
  // eslint-disable-next-line no-unused-vars
  showMenu(obj, positionUnderButton) {
    throw new ImplementationNeededError();
  }

  /**
   * Implement this to add the HTML for the menu to the page (but not actually show it).
   * @abstract
   */
  renderMenu() {
    throw new ImplementationNeededError();
  }

  /**
   * This method ensures that the menu does not fall off the bottom of the browser.
   * @param newTop The new top value the menu should be at, if it appeared properly below the button.
   * @param addMenuElement The menu to be shown.
   * @return {number} The final top value
   */
  getTopButNotOffScreen(newTop, addMenuElement) {
    let menuHeight = addMenuElement.offsetHeight;
    if ((newTop + menuHeight) > window.innerHeight) {
      // Oh no! It would appear below the bottom of the screen. So we push it up so the user can still see it.
      newTop = window.innerHeight - menuHeight;
    }
    return newTop;
  }
}


