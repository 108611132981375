"use strict";

import React from "react";
import * as I18NWrapper from "../../../i18n/i18n_wrapper";
import * as UIUtils from "../../../ui_utils";
import BasePage from "../../../base_page";
import {MATERIAL_SELECTION_STEP_CONFIG, SYNC_OPTIONS_STEP_CONFIG} from "../steps/config";
import {COMMON_MATERIAL_STEPS, FROM_LIBRARY_STATUS} from "../../library_constants";
import {
  ProjectMaterialWizardController
} from "../createProjectMaterial/fromProject/project_material_wizard_container";
import {ProjectMaterialWizardHelper} from "../createProjectMaterial/fromProject/project_material_wizard_helper";
import * as FailHandlers from "../../../utils/fail_handlers";
import {MATERIAL_ACTIONS} from "../../helpers/library_helper";


class SyncProjectMaterialPage extends BasePage {

  constructor(props) {
    super(props);

    const initialState = {
      currentFlowStep: COMMON_MATERIAL_STEPS.MaterialLibrarySelection,
      wizardStepsConfig: [MATERIAL_SELECTION_STEP_CONFIG, SYNC_OPTIONS_STEP_CONFIG],
      libraryMaterials: [],
      selectionState: {},
      action: MATERIAL_ACTIONS.SYNC,
      ProjectId: UIUtils.getParameterByName("projectId"),
      ProcessId: UIUtils.getParameterByName("processId"),
    };

    this.setStateSafely({initialState});
  }

  componentDidMount() {
    super.componentDidMount();
    this.loadData();
  }

  loadData() {

    this.setStateSafely({isLoading: true});
    const urlParameters = {
      includeHistory: true,
      loadFullHistory: true,
      includeChildRecords: true,
    };

    const materialId = UIUtils.getParameterByName("materialId");
    UIUtils.secureAjaxGET(
      `editables/Material/${materialId}`,
      urlParameters,
      true,
      FailHandlers.defaultStopLoadingFailFunction.bind(this)
    ).then(results => this.handleResultsReceivedFromServer(results));
  }

  handleResultsReceivedFromServer(result) {
    const {t} = this.props;
    const {initialState} = this.state;
    const {material} = initialState || {};
    const {fromLibraryStatus} = material || result || {};
    this.setDocumentTitle(t, fromLibraryStatus);

    this.setStateSafely({
      initialState: {
        ...initialState,
        fromLibraryStatus,
        material: result,
        reload: true
      }
    });
  }

  renderPage() {
    const {t} = this.props;
    const {initialState: {material}} = this.state;

    return material && (
      <ProjectMaterialWizardController
        t={t}
        initialState={this.state.initialState}
        WizardHelper={ProjectMaterialWizardHelper}
      />
    );
  }


  setDocumentTitle(t, fromLibraryStatus) {
    document.title = fromLibraryStatus === FROM_LIBRARY_STATUS.LINKED
      ? t("Sync Material Page Title")
      : "QbDVision " + t("Use Material From Library");
  }

}

export default I18NWrapper.wrap(SyncProjectMaterialPage, "library");
