/* stylelint-disable-next-line import-notation */
@import "../css/brand_colors";

.side-menu-container {
  /* The sticky title bar has 90px in height */
  top: 90px;
  background-color: $brand-white;
  position: sticky;
  height: 100vh;
  overflow: auto;
  scrollbar-width: none;
  padding-bottom: 100px;
}

.side-menu-visible {
  :global(.title-bar) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.side-menu-static-panel-container {
  z-index: 999;
  background-color: $brand-white;
}

:global(.quick-panel-static-container:has(.side-menu-static-panel-container)) {
  overflow: hidden;
}
