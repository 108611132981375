"use strict";

import * as UIUtils from "../../../ui_utils";

/**
 * Holds the logic to deal with criticality justifications
 */
export class CriticalityJustificationEntry {
  constructor({attributeKey, attributeName, state, justification, showAttributeInfo}) {
    this.justification = justification;
    this.attributeKey = attributeKey;
    this.attributeName = attributeName;
    this.state = state;
    this.showAttributeInfo = !!showAttributeInfo;
  }

  toString() {
    const justification = this.justification !== null &&
    typeof this.justification !== "undefined" &&
    this.justification.toString().trim() !== ""
      ? this.justification : "None";

    return UIUtils.secureString(
      this.showAttributeInfo
        ? `${this.attributeKey} - ${this.attributeName}\n[${this.state}] \n${justification}`
        : justification
    );
  }
}
