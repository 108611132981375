"use strict";

import React from "react";
import TabNavBar from "../../widgets/bars/tab_nav_bar";
import NotificationEntry from "./notification_entry";
import { HOME_PAGE_TABS_ENUM } from "../constants";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import { Trans } from "react-i18next";
import BaseReactComponent from "../../base_react_component";

/**
 * This class handles the notifications panel (frequently viewed and activity)
 */
// i18next-extract-mark-ns-start homePage
class NotificationsPanel extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: HOME_PAGE_TABS_ENUM.FREQUENTLY_VIEWED
    };
  }

  handleTabChanged(tab) {
    this.setStateSafely({
      selectedTab: tab
    });
  }

  isEmptyTab(notifications) {
    let {activities, frequentlyAccessed} = notifications;
    let {selectedTab} = this.state;

    return (activities.length === 0 && selectedTab.title === HOME_PAGE_TABS_ENUM.ACTIVITY.title) ||
      (frequentlyAccessed.length === 0 && selectedTab.title === HOME_PAGE_TABS_ENUM.FREQUENTLY_VIEWED.title);
  }

  /**
   * Override the loading class to display the custom rows loading.
   */
  getAdditiveSkeletonClass() {
    return "skeleton-home-page";
  }

  render() {
    let {className, notifications, t} = this.props;
    let {activities, frequentlyAccessed} = notifications;
    let {selectedTab} = this.state;

    let input;
    if (activities.length > 0 && selectedTab.title === HOME_PAGE_TABS_ENUM.ACTIVITY.title) {
      input = activities.map((record, index) => {
        return <NotificationEntry index={index} tab={selectedTab} key={index} object={record} />;
      });
    } else if (frequentlyAccessed.length > 0 && selectedTab.title === HOME_PAGE_TABS_ENUM.FREQUENTLY_VIEWED.title) {
      input = frequentlyAccessed.map((record, index) => {
        return <NotificationEntry index={index} tab={selectedTab} key={index} object={record} />;
      });
    } else if (activities.length === 0 && selectedTab.title === HOME_PAGE_TABS_ENUM.ACTIVITY.title) {
      input = (
        <Trans t={t}>
          <div className="home-page-empty-data home-page-empty-data-activity">
            See what's happening across your project. The recent activities across your team will aggregate in this view.
          </div>
        </Trans>);
    } else if (frequentlyAccessed.length === 0 && selectedTab.title === HOME_PAGE_TABS_ENUM.FREQUENTLY_VIEWED.title) {
      input = (
        <Trans t={t}>
          <div className="home-page-empty-data home-page-empty-data-frequently-viewed"
          >
            Get quick access to your most commonly viewed records.
          </div>
        </Trans>);
    }

    return (
      <div className={"home-page-notifications-panel shadow " + (className || "")}>
        <TabNavBar selected={selectedTab}
                   navItemClassName="home-page-nav-bar-item"
                   onTabChanged={this.handleTabChanged}
                   tabs={HOME_PAGE_TABS_ENUM}
        />
        <div className="home-page-notification-panel-separator" />
        <div className={
          "home-page-notifications-panel-data scroller"
          + (this.isEmptyTab(notifications) ? " home-page-empty-notifications" : "")
          + this.getClassForLoading()
        }
        >
          {input}
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(NotificationsPanel, "homePage");
// i18next-extract-mark-ns-stop homePage
