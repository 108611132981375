"use strict";

import * as UIUtils from "../ui_utils";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import React, { Fragment } from "react";
import { can } from "../utils/ui_permissions";
import CommonSecurity from "../../server/common/generic/common_security";
import EditablesPageTitleBar from "../widgets/pageTitleBar/editables_page_title_bar";
import NavBar from "../widgets/bars/nav_bar";
import { loadRMP } from "../helpers/risk_helper";
import ComboBoxAttribute from "../editor/attributes/combo_box_attribute";
import { EDITOR_OPERATIONS } from "../editor/editor_constants";
import * as FailHandlers from "../utils/fail_handlers";
import * as RiskUtils from "../../server/common/misc/common_risk_utils";
import { getURLByTypeCodeAndId, getURLByTypeCodeAndIdAndVersionId } from "../helpers/url_helper";
import { ChangeRMPService } from "../services/project/change_rmp_service";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";
import FullObjectCache from "../utils/cache/full_object_cache";
import ToggleInput from "react-switch";
import LabelTooltip from "../widgets/tooltips/label_tooltip";
import { isPropertyDefined } from "../../server/common/generic/common_utils";
import { DATATABLES_DOM_WITH_SEARCH_AND_BUTTONS } from "../widgets/tables/base_table";
import ApprovalsPendingTable from "../dashboard/approval/approvals_pending_table";
import { REQUIREMENT_TABS } from "../rmps/constants/rmp_constants";
import BasePage from "../base_page";


// i18next-extract-mark-ns-start project_change_rmp
export class ProjectChangeRMPPage extends BasePage {
  constructor(props) {
    super(props);

    UIUtils.setLoadingDisabled(true);
    this.allNamesToRefs = {};
    this.customValidatedChildElements = [];

    this.setStateSafely({
      rmps: [],
      RMP: null,
      selectedRMP: null,
      riskMapping: null,
      userChangedMapping: {},
      useNotAssessed: false,
      isLoading: true,
    });

    this.changeRmpService = new ChangeRMPService();
    this.currentUserId = UIUtils.getUserId();
    this.checkProject();
  }
  handleRMPAndProject(RMP, project) {
    if (!project) {
      UIUtils.displayErrorModal(`404 - Not Found`, `Project not found: PRJ-${this.projectId}.`, {
        allowHide: false,
        hideBody: true,
      });
    }

    const hasSupportForNotAsssessed = RMP.RMPToImpacts.find(link => link.riskScore === 0) !== undefined;
    this.setStateSafely({RMP, ProjectWithAllVersions: project, useNotAssessed: hasSupportForNotAsssessed});

    this.loadAllRMPs();
  }

  loadAllRMPs() {
    new Promise((resolve) => {
      const urlParameters = {
        showRemoved: false,
        includeHistory: true,
        loadFullHistory: true,
        shouldCompress: true,
        includeAllApprovedVersions: true,
      };

      UIUtils.secureAjaxGET(
        `editables/RMP/list`,
        urlParameters,
        true,
        FailHandlers.defaultStopLoadingFailFunction.bind(this),
      ).done((results) => {
        return resolve(results);
      });
    }).then(this.handleLoadAllRMPsFromServer);
  }

  handleLoadAllRMPsFromServer(results) {
    let instances = results.instances;
    this.setStateSafely({
      rmps: instances,
      isLoading: false,
    }, () => {
      this.setStateSafely({
        riskMapping: this.computeRiskMappings(),
        userChangedMapping: {},
      });
    });
  }

  computeRiskMappings() {
    const riskMapping = {};
    const sourceRMPVersion = this.getCurrentRmpVersion();
    const newRMPVersion = this.getNewRmpVersion();

    if (!sourceRMPVersion || !newRMPVersion) {
      return;
    }

    const configureByType = sourceRMPVersion.configureByType || newRMPVersion.configureByType;
    let tabs = this.getTabs();
    if (!configureByType) {
      tabs = [{
        modelName: "Universal",
        id: "Universal",
        label: "Universal",
      }];
    }

    tabs.forEach(tab => {
      const model = tab.modelName;

      let sourceRMPVersionForModel = UIUtils.deepClone(sourceRMPVersion);
      let newRMPVersionForModel = UIUtils.deepClone(newRMPVersion);
      if (model !== "Universal") {
        sourceRMPVersionForModel = RiskUtils.getRMPForRiskComparisonMatrix(sourceRMPVersionForModel, model);
        newRMPVersionForModel = RiskUtils.getRMPForRiskComparisonMatrix(newRMPVersionForModel, model);
      }

      if (!riskMapping[model]) {
        riskMapping[model] = {};
        riskMapping[model]["sourceRMPVersion"] = RiskUtils.filterRMPByType(sourceRMPVersion, model !== "Universal" ? model : null);
        riskMapping[model]["newRMPVersion"] = RiskUtils.filterRMPByType(newRMPVersion, model !== "Universal" ? model : null);

      }

      this.getRiskProperties().forEach((riskProperty) => {
        if (!riskMapping[model][riskProperty.name]) {
          riskMapping[model][riskProperty.name] = [];
        }

        let riskScoreConversionMap = RiskUtils.getRMPRiskScoreConversionMap(sourceRMPVersionForModel[riskProperty.scaleProperty], newRMPVersionForModel[riskProperty.scaleProperty]);
        const hasSupportForNotAssessed = newRMPVersionForModel[riskProperty.scaleProperty].find(link => link.riskScore === 0) !== undefined;
        if (this.state.useNotAssessed && hasSupportForNotAssessed && riskScoreConversionMap) {
          for (let key in riskScoreConversionMap) {
            if (isPropertyDefined(riskScoreConversionMap, key) && parseInt(key) !== riskScoreConversionMap[key]) {
              riskScoreConversionMap[key] = 0;
            }
          }
        }

        if (!riskMapping[model][riskProperty.name]) {
          riskMapping[model][riskProperty.name] = [];
        }

        const currentSourceRiskScales = sourceRMPVersionForModel[riskProperty.scaleProperty];
        currentSourceRiskScales.forEach(currentSourceRiskScale => {
          const mapping = {
            from: currentSourceRiskScale.riskScore,
            to: riskScoreConversionMap && riskScoreConversionMap[currentSourceRiskScale.riskScore] ? riskScoreConversionMap[currentSourceRiskScale.riskScore] : 0,
          };

          let userSelectedScore = null;
          if (this.state.userChangedMapping[model] &&
            this.state.userChangedMapping[model][riskProperty.name] &&
            this.state.userChangedMapping[model][riskProperty.name][currentSourceRiskScale.riskScore] != null) {
            userSelectedScore = this.state.userChangedMapping[model][riskProperty.name][currentSourceRiskScale.riskScore];
          }

          if (userSelectedScore !== null) {
            mapping.to = userSelectedScore;
          }

          riskMapping[model][riskProperty.name].push(mapping);
        });
      });
    });

    return riskMapping;
  }

  getTabs() {
    const tabs = [];
    for (let key of Object.keys(REQUIREMENT_TABS)) {
      let tab = REQUIREMENT_TABS[key];
      tabs.push({
        modelName: UIUtils.getTypeCodeForModelName(tab.id),
        id: tab.id,
        label: tab.displayName,
      });
    }

    return tabs;
  }

  componentDidMount() {
    super.componentDidMount();

    document.title = "Change Project RMP";
    UIUtils.incrementReactComponentDidUpdateCounter();
  }

  getProjectId() {
    return this.projectId;
  }

  getCurrentOperation() {
    return EDITOR_OPERATIONS.EDIT;
  }

  addRef(someAttributeRef) {
    this.allNamesToRefs[someAttributeRef.props.name] = someAttributeRef;
  }

  getAllNamesToRefs() {
    return this.allNamesToRefs;
  }

  getInstance() {
    return this.state;
  }

  handleChildDidMount() {
  }

  registerCustomValidatedAttribute(childElement) {
    this.customValidatedChildElements.push(childElement);
  }

  unregisterCustomValidatedAttribute(childElement) {
    this.customValidatedChildElements = this.customValidatedChildElements.filter(el => el !== childElement);
  }

  handleChangeValue(attributeName, value) {
    let newState = null;
    if (attributeName === "rMP") {
      const selectedRMP = this.state.rmps.find(rmp => rmp.id === parseInt(value));
      newState = {
        selectedRMP,
        userChangedMapping: {},
      };
    }

    if (newState) {
      const lastApprovedVersion = newState.selectedRMP.approvedVersionsWithDetails.find(rmpVersion => rmpVersion.id === newState.selectedRMP.LastApprovedVersionId);
      const hasSupportForNotAsssessed = lastApprovedVersion.RMPToImpactLinkedVersions.find(link => link.riskScore === 0) !== undefined;
      this.setStateSafely({
        useNotAssessed: hasSupportForNotAsssessed,
        riskMapping: null,
        ...newState,
      }, () => {
        this.setStateSafely({
          riskMapping: this.computeRiskMappings(),
        });
      });
    }
  }

  getSelectedRMP() {
    const rmps = this.state.rmps;
    const RMP = this.state.RMP;
    let selectedRMP = this.state.selectedRMP;
    if (!selectedRMP && RMP && rmps) {
      selectedRMP = rmps.find(rmp => rmp.id === RMP.id);
    }

    return selectedRMP;
  }

  getNewRmpVersion() {
    const selectedRMP = this.getSelectedRMP();
    return selectedRMP && selectedRMP.approvedVersionsWithDetails.find(rmpVersion => rmpVersion.id === selectedRMP.LastApprovedVersionId);
  }

  getCurrentRmpVersion() {
    const RMP = this.state.RMP;
    const project = this.state.project;
    return RMP && RMP.approvedVersionsWithDetails.find(rmpVersion => rmpVersion.id === (project.rmpVersionId ?? RMP.LastApprovedVersionId));
  }

  hasNewRmpSelected() {
    const newRMPVersion = this.getNewRmpVersion();
    const sourceRMPVersion = this.getCurrentRmpVersion();
    return newRMPVersion && sourceRMPVersion && newRMPVersion.id !== sourceRMPVersion.id;
  }

  selectedRmpIsValid() {
    const newRMPVersion = this.getNewRmpVersion();
    const selectedRMPIsConfiguredByType = newRMPVersion?.configureByType;
    const projectHasTechTransfer = this.state.ProjectWithAllVersions?.techTransferIsInUse;
    return !(projectHasTechTransfer && !selectedRMPIsConfiguredByType);
  }

  renderPage() {
    const {
      project,
      pendingRecords,
    } = this.state;

    const rmpInfo = this.getRmpInfo();
    let rmpLabel = "";
    let rmpUrl = "";
    if (rmpInfo) {
      rmpLabel = rmpInfo.rmpLabel;
      rmpUrl = rmpInfo.rmpUrl;
    }

    const hasNewRMPSelected = this.hasNewRmpSelected();
    const hasPendingRecords = pendingRecords && pendingRecords.length > 0;
    const canRender = can(CommonSecurity.Actions.EDIT, CommonSecurity.Types.PROJECT, this.state);

    let saveButtonIsEnabled = hasNewRMPSelected && this.selectedRmpIsValid();

    return canRender ? (
      <div>
        <EditablesPageTitleBar projectId={this.getProjectId()}
                               projectName={project?.name ?? ""}
                               projectDeletedAt={project?.deletedAt ?? null}
                               isDemoProject={project?.isDemo ?? ""}
                               currentState={project?.currentState ?? null}
        />
        <NavBar selected="Dashboard"
                projectId={this.projectId}
        />

        <div className="container">
          <div className="title-bar">
            <div className="title-bar-title full-screen-page" id="titleBar">
              <div className="title-bar-primary">
                    <span id="titleBarPrimary" className="title-bar-primary-name">
                      {project?.name}
                    </span>
              </div>
              <div className="title-bar-breadcrumbs">
                <span>Current RMP: <a href={rmpUrl} target="_blank" rel="noreferrer">{rmpLabel}</a></span>
              </div>
              <div className="btn-group">
                <button id="changeRmpButton"
                        disabled={!saveButtonIsEnabled}
                        className={"btn btn-lg btn-primary"}
                        onClick={this.handleSaveButton}
                >
                  Save
                </button>
                <button id="cancelButton"
                        className={"btn btn-lg btn-secondary"}
                        onClick={this.handleCancelButton}

                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          {hasPendingRecords ? this.renderPendingRecords() : this.renderChangeRiskManagementPlan()}
        </div>
      </div>
    ) : <Fragment />;
  }

  renderIncompatibleRMP() {
    return (
      <div className="section">
        <div className="section-content ">
          <div className="row">
            <div className="attribute-container col-sm-12" id="invalidRMPTechTransferMessage">
              <p>Your current project has Tech Transfer enabled</p>
              <p>The selected RMP does not have support for Tech Transfer. Please select a Tech Transfer enabled RMP.</p>
            </div>
          </div>
        </div>
      </div>);
  }

  renderPendingRecords() {
    return (
      <div className="section">
        <div className="section-content ">
          <div className="row">
            <div className="attribute-container col-sm-12">
              <p>You cannot change the RMP on this project because you have approval pending records.</p>
              <p>Please approve/reject the following records:</p>
              <ApprovalsPendingTable id="approvalsPendingTable"
                                     data={this.formatApprovalsPending(this.state.pendingRecords)}
                                     tableHeader="Approvals Pending"
                                     showSelectedRowsInfo={true}
                                     datatablesDomOptions={DATATABLES_DOM_WITH_SEARCH_AND_BUTTONS}
                                     projectId={this.getProjectId()}
                                     parent={this}
              />
            </div>
          </div>
        </div>
      </div>);
  }

  formatApprovalsPending(approvalsPending) {
    for (let approval of approvalsPending || []) {
      if (UIUtils.parseInt(approval.userId) !== UIUtils.parseInt(this.currentUserId)) {
        approval.disabled = true;
        approval.disabledReason = "You are not listed among the approvers for this requirement.";
      }
    }

    return approvalsPending;
  }

  renderChangeRiskManagementPlan() {
    const {
      rmps,
    } = this.state;

    const {t} = this.props;

    const rmpOptions = rmps ? rmps.filter(rmp => rmp.LastApprovedVersionId).map(rmp => ({
      key: rmp.id,
      value: `${rmp.name} (v${rmp.majorVersion}.${rmp.minorVersion})`,
    })) : [];

    let selectedRMP = this.getSelectedRMP();

    const defaultRmp = rmpOptions.length > 0 ? selectedRMP?.id : null;
    const selectedRmpVersionUrl = getURLByTypeCodeAndId("RMP", "View", selectedRMP?.id, true);
    const hasNewRMPSelected = this.hasNewRmpSelected() && this.state.riskMapping;
    let control = <div />;
    if (hasNewRMPSelected) {
      if (this.selectedRmpIsValid()) {
        control = this.renderScoreMapping();
      } else {
        control = this.renderIncompatibleRMP();
      }
    }

    return (
      <Fragment>
        <div className="section">
          <div className="row section-non-collapsible-header">
            <div className="col-sm-12">
              <h2>New Risk Management Plan</h2>
            </div>
          </div>
          <div className="section-content ">
            <div className="row align-items-center">
              <ComboBoxAttribute name="rMP"
                                 displayName={t("Risk Management Plan")}
                                 className="col-sm-6"
                                 options={rmpOptions}
                                 default={defaultRmp}
                                 parent={this}
                                 isLoading={this.state.isLoading}
                                 parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                 parentId={this.state.id}
              />
              <div className="col-sm-6">
                <a href={selectedRmpVersionUrl} target="_blank" className={this.getClassForLoading()} rel="noreferrer">View selected RMP</a>
              </div>
            </div>
          </div>
        </div>
        {control}
      </Fragment>
    );
  }

  renderScoreMapping() {
    const newRMPVersion = this.getNewRmpVersion();
    const newRMPVersionLabel = newRMPVersion ? `${newRMPVersion.name} (v${newRMPVersion.majorVersion}.${newRMPVersion.minorVersion})` : "";
    const sourceRMPVersion = this.getCurrentRmpVersion();
    const hasSupportForNotAsssessed = newRMPVersion?.RMPToImpactLinkedVersions.find(link => link.riskScore === 0) !== undefined;
    const unmappedLabelTooltip = hasSupportForNotAsssessed ? "All mismatches will be set to 'Not Assessed'" : "The `Not Assessed` option disabled on RMP";
    const {t} = this.props;

    return (<div className="section">
      <div className="row section-non-collapsible-header">
        <div className="col-sm-6">
          <h2>Values</h2>
        </div>
        <div className="col-sm-6 text-right">
          <LabelTooltip id="configureByTypeInputLabel" tooltipText={unmappedLabelTooltip} text="Set unmapped values to Not Assessed" />
          <ToggleInput id="setUnmappedValuesToNotAssessedInput"
                       className="use-not-assessed"
                       checked={this.state.useNotAssessed}
                       onChange={this.handleUseNotAssessedChange}
                       height={22}
                       width={38}
                       checkedIcon={false}
                       uncheckedIcon={false}
                       activeBoxShadow="0 0 2px 3px #014768"
                       disabled={!hasSupportForNotAsssessed}
                       onColor="#DBE1E4"
                       offColor="#DBE1E4"
                       onHandleColor="#1F46A1"
          />
        </div>
      </div>
      <div className="section-content ">
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div id="rmpConversionMatrixWidget" className="col-sm-12 rmp-comparison-widget">

                <div className="row">
                  <div className="col-sm-12 col-form-label rmp-comparison-widget-title">
                    {t("Choosing RMP " + newRMPVersionLabel + " will cause all records in the current project to update to:")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    {this.renderRiskComparisonTabs(sourceRMPVersion, newRMPVersion)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
  }

  getRmpInfo() {
    const {
      project,
      RMP,
    } = this.state;

    if (!RMP || !project) {
      return;
    }

    let rmpLabel = RMP.name;
    let rmpUrl;
    const rmpVersion = RMP.approvedVersionsWithDetails.find(approvedVersion => approvedVersion.id === (project.rmpVersionId ?? RMP.LastApprovedVersionId));
    if (rmpVersion) {
      rmpLabel = `${rmpVersion.name} (v${rmpVersion.majorVersion}.${rmpVersion.minorVersion})`;
      rmpUrl = getURLByTypeCodeAndIdAndVersionId("RMP", "View", RMP.id, false, rmpVersion.id);
    } else {
      rmpUrl = getURLByTypeCodeAndId("RMP", "View", RMP.id);
    }


    return {rmpLabel, rmpUrl};
  }

  renderRiskComparisonTabs(sourceRMPVersion, newRMPVersion) {
    if (sourceRMPVersion.configureByType || newRMPVersion.configureByType) {
      return (<Fragment>
        <div className="nav nav-tabs">
          {
            this.getTabs().map(tab => {
              const model = tab.modelName;
              const label = tab.label;
              return <a data-toggle="tab"
                        key={model}
                        className={"nav-link" + (model === "FQA" ? " active" : "")}
                        id={`${model}RiskComparisonTab`}
                        href={`#${model}`}
                        aria-controls={model}
              >
                {label}
              </a>;
            })
          }
        </div>
        <div className="tab-content">
          {
            this.getTabs().map(tab => {
              const model = tab.modelName;
              return (<div key={model} id={model}
                           className={"rmp-requirement-risk-tab tab-pane fade" + (model === "FQA" ? " show active" : "")}
                           role="tabpanel"
                           aria-labelledby={`${model}RiskComparisonTab`}
              >
                {
                  this.renderRiskComparisonTab(
                    RiskUtils.getRMPForRiskComparisonMatrix(UIUtils.deepClone(sourceRMPVersion), model),
                    RiskUtils.getRMPForRiskComparisonMatrix(UIUtils.deepClone(newRMPVersion), model),
                    tab)}
              </div>);
            })
          }
        </div>
      </Fragment>);
    } else {
      return (
        <div className="tab-content single-tab" id="UniversalRiskComparisonTab">
          {this.renderRiskComparisonTab(sourceRMPVersion, newRMPVersion)}
        </div>
      );
    }
  }

  renderRiskComparisonTab(sourceRMPVersion, newRMPVersion, tab) {
    const modelTypeCode = tab?.modelName ?? "Universal";
    const tabId = tab?.modelName;
    return (
      <div id={tabId ? `${tabId}RmpConversionMatrixWidgetDetails row` : "rmpConversionMatrixWidgetDetails"}
           className="conversion-matrix-widget-details"
      >
        <div className="col-12">
          <div className="row">
            <div className="col-3">
              {this.renderRIskComparisonWidget(tabId ? `${tabId}ImpactTable` : "ImpactTable", "Impact", "impact", sourceRMPVersion.RMPToImpactLinkedVersions, newRMPVersion.RMPToImpactLinkedVersions, modelTypeCode)}
            </div>
            <div className="col-3">
              {this.renderRIskComparisonWidget(tabId ? `${tabId}UncertaintyTable` : "UncertaintyTable", "Uncertainty", "uncertainty", sourceRMPVersion.RMPToUncertaintyLinkedVersions, newRMPVersion.RMPToUncertaintyLinkedVersions, modelTypeCode)}
            </div>
            <div className="col-3">
              {this.renderRIskComparisonWidget(tabId ? `${tabId}CapabilityTable` : "CapabilityTable", "Capability", "capabilityRisk", sourceRMPVersion.RMPToCapabilityRiskLinkedVersions, newRMPVersion.RMPToCapabilityRiskLinkedVersions, modelTypeCode)}
            </div>
            <div className="col-3">
              {this.renderRIskComparisonWidget(tabId ? `${tabId}DetectabilityTable` : "DetectabilityTable", "Detectability", "detectabilityRisk", sourceRMPVersion.RMPToDetectabilityRiskLinkedVersions, newRMPVersion.RMPToDetectabilityRiskLinkedVersions, modelTypeCode)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRIskComparisonWidget(tabName, riskLabel, riskName, sourceRiskScales, targetRiskScales, modeTypeCode) {
    return (
      <div id={tabName + "ConversionWidget"}>
        <div className="row">
          <div className="col-12 rmp-risk-comparison-widget-title">
            {riskLabel}
          </div>
        </div>
        <div className="row rmp-risk-comparison-widget-header-row">
          <div className="col-6 rmp-risk-comparison-widget-header">
            <div className="col-form-label">
              Current
            </div>
          </div>
          <div className="col-6 rmp-risk-comparison-widget-header new">
            <div className="col-form-label">
              New
            </div>
          </div>
        </div>
        <div className="row">
          <div id="riskConversionTable"
               className="col-12"
          >
            {sourceRiskScales ? sourceRiskScales.sort(UIUtils.sortBy("riskScore")).map(scale => {
              return this.renderRIskComparisonWidgetSection(targetRiskScales, scale, modeTypeCode, riskName, riskLabel);
            }) : ""}
          </div>
        </div>
      </div>);
  }

  renderRIskComparisonWidgetSection(targetRiskScales, currentSourceRiskScale, modeTypeCode, riskName, riskLabel) {
    const scales = targetRiskScales.map(targetRiskScale => ({
      label: `${targetRiskScale.riskScore} (${this.trimLabel(targetRiskScale.scoreLabel)})`,
      labelFull: `${targetRiskScale.riskScore} (${targetRiskScale.scoreLabel})`,
      riskScore: targetRiskScale.riskScore,
      sourceRiskObject: currentSourceRiskScale,
      targetRiskObject: targetRiskScale,
      riskName,
      riskLabel,
      color: targetRiskScale.color.toLowerCase(),
      description: targetRiskScale.description,
      typeCode: modeTypeCode,
    })).sort(UIUtils.sortBy("riskScore"));

    const riskScoreConversionMap = this.state.riskMapping[modeTypeCode][riskName];
    const riskScoreConversionMapScore = riskScoreConversionMap?.find(x => x.from === currentSourceRiskScale.riskScore)?.to;

    let sourceIsSameAsTarget = currentSourceRiskScale.riskScore === riskScoreConversionMapScore;

    let selectedScales = scales.filter(tmpScale => tmpScale.riskScore === riskScoreConversionMapScore);
    if (selectedScales.length === 0) {
      selectedScales = [scales[0]];
    }

    const selectedScale = selectedScales?.length > 0 ? selectedScales[0] : null;
    return (
      <div key={currentSourceRiskScale.riskScore} className="row rmp-risk-comparison-widget-score-row align-items-center text-left ">
        <div key={"source_" + currentSourceRiskScale.riskScore}
             className={"col-6 rmp-risk-comparison-widget-score-cell " + (!sourceIsSameAsTarget ? "rmp-risk-comparison-widget-score-diff" : "")}
        >
          <span className="score">{currentSourceRiskScale.riskScore}</span> ({this.trimLabel(currentSourceRiskScale.scoreLabel)})
        </div>
        <div key={"new_" + currentSourceRiskScale.riskScore}
             className={"col-6 dropdown rmp-risk-comparison-widget-score-dropdown "}
        >
          {selectedScale ? (<div>
            <button
              id={"score" + modeTypeCode + riskLabel + currentSourceRiskScale.riskScore}
              type="button"
              className="btn dropdown-toggle"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span>{selectedScale.label}</span>
            </button>
            <div id={"scoreDropdown" + modeTypeCode + riskLabel + currentSourceRiskScale.riskScore} className="dropdown-menu" aria-labelledby={"dropdown" + modeTypeCode + riskLabel + currentSourceRiskScale.riskScore}>
              {
                scales.map(scale => {
                  return (
                    <div key={scale.id} onClick={() => this.handleScoreClick(scale)} className={"dropdown-item risk-item score-" + scale.riskScore} href="#">
                      <div>
                        <span className={"box rmp-criticality-color-" + scale.color}></span> {scale.labelFull}
                      </div>
                      <p>
                        {scale.description}
                      </p>
                    </div>);
                })
              }
            </div>
          </div>) : (<div>Current RMP is missing score definitions. Please check the RMP configuration page.</div>)}
        </div>
      </div>
    );
  }

  handleScoreClick(selectedScale) {
    const riskMapping = UIUtils.deepClone(this.state.riskMapping);
    const existingMapping = riskMapping[selectedScale.typeCode][selectedScale.riskName].find(x => x.from === selectedScale.sourceRiskObject.riskScore);
    existingMapping.to = selectedScale.riskScore;

    const userChangedMapping = this.state.userChangedMapping;
    if (!userChangedMapping[selectedScale.typeCode]) {
      userChangedMapping[selectedScale.typeCode] = {};
    }

    if (!userChangedMapping[selectedScale.typeCode][selectedScale.riskName]) {
      userChangedMapping[selectedScale.typeCode][selectedScale.riskName] = {};
    }

    userChangedMapping[selectedScale.typeCode][selectedScale.riskName][existingMapping.from] = existingMapping.to;

    this.setStateSafely({
      riskMapping,
      userChangedMapping,
    });
  }

  handleSaveButton() {
    UIUtils.setLoadingDisabled(false);
    const selectedRMP = this.getSelectedRMP();
    if (!selectedRMP || !this.hasNewRmpSelected()) {
      throw new Error("No RMP selected");
    }

    this.changeRmpService.changeRmp({
      model: "Project",
      projectId: this.getProjectId(),
      rmpId: selectedRMP.id,
      rmpVersionId: selectedRMP.LastApprovedVersionId,
      rmpMappings: this.state.riskMapping,
    }).then(async() => this.handleChangeCompleted())
      .catch(result => {
        UIUtils.defaultFailFunction(result, null, null, UIUtils.PRIMARY_ALERT_DIV);
        $(this.modalRef).modal("hide");
      })
      .finally(() => {
        UIUtils.setLoadingDisabled(true);
      });
  }

  handleCancelButton() {
    location.href = getURLByTypeCodeAndId("PRJ", "Edit", this.getProjectId());
  }

  getRiskPropertyName(riskLabel) {
    const riskLabelMap = new Map();
    riskLabelMap.set("Impact", "impact");
    riskLabelMap.set("Uncertainty", "uncertainty");
    riskLabelMap.set("Capability", "capabilityRisk");
    riskLabelMap.set("Detectability", "detectabilityRisk");

    return riskLabelMap.get(riskLabel);
  }

  getRiskProperties() {
    return [{
      name: "impact",
      label: "Impact",
      scaleProperty: "RMPToImpactLinkedVersions",
    }, {
      name: "uncertainty",
      label: "Uncertainty",
      scaleProperty: "RMPToUncertaintyLinkedVersions",
    }, {
      name: "capabilityRisk",
      label: "Capability",
      scaleProperty: "RMPToCapabilityRiskLinkedVersions",
    }, {
      name: "detectabilityRisk",
      label: "Detectability",
      scaleProperty: "RMPToDetectabilityRiskLinkedVersions",
    }];
  }

  async handleChangeCompleted() {
    await new TypeaheadObjectCache("Project").invalidateCacheOptionsAsync();
    await new TypeaheadObjectCache("RMP").invalidateCacheOptionsAsync();

    const cache = new FullObjectCache("Project", this.getProjectId());
    await cache.invalidateCacheOptionsAsync();

    const cacheRMP = new FullObjectCache("RMP", this.getSelectedRMP().id);
    await cacheRMP.invalidateCacheOptionsAsync();

    location.href = getURLByTypeCodeAndId("PRJ", "View", this.getProjectId()) + "&rmpChanged=" + this.getSelectedRMP().id;
  }

  handleUseNotAssessedChange(checked) {
    this.setStateSafely({
      useNotAssessed: checked,
    }, () => {
      this.setStateSafely({
        riskMapping: this.computeRiskMappings(),
      });
    });
  }

  checkProject() {
    UIUtils.setLoadingDisabled(false);

    this.changeRmpService.checkProject({
      model: "Project",
      projectId: this.getProjectId(),
    }).then(result => this.handleCheckProjectCompleted(result))
      .catch(result => {
        UIUtils.defaultFailFunction(result, null, null, UIUtils.PRIMARY_ALERT_DIV);
        $(this.modalRef).modal("hide");
      })
      .finally(() => {
        UIUtils.setLoadingDisabled(true);
      });
  }

  handleCheckProjectCompleted(result) {
    if (result && result[0] && result[0].pendingRecords?.length > 0) {
      this.setStateSafely({
        pendingRecords: result[0].pendingRecords,
      });
    }

    loadRMP(this.handleRMPAndProject);
  }

  trimLabel(value) {
    if (!value) {
      return "";
    }

    return value.length > 6 ? value.substring(0, 9) + "..." : value;
  }
}

export default I18NWrapper.wrap(ProjectChangeRMPPage, ["projects", "base_page"]);
// i18next-extract-mark-ns-stop project_change_rmp
