/* stylelint-disable-next-line import-notation */
@import "../../css/brand_colors";

.tree-node {
  width: 100%;
  padding: 10px 0 10px 5px;
  font-weight: 600;
}

.tree-leaf {
  border: 1px solid $brand-mild-grey;
  border-radius: 5px;
  padding: 4px 6px;
  width: fit-content;
  margin-bottom: 10px;
}

.tree-leaf:hover {
  background-color: $brand-light-grey;
}
