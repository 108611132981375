"use strict";

import React, { Fragment } from "react";
import { can, generateTooltip } from "../../../utils/ui_permissions";
import CommonSecurity from "../../../../server/common/generic/common_security";
import { getProjectFromCache } from "../../../utils/project_helper";
import RelatedRecordsPanel from "../related_records_panel";
import { closestCenter, DndContext } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToParentElement, restrictToVerticalAxis, restrictToWindowEdges } from "@dnd-kit/modifiers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import reorderIcon from "../../../images/icons/reorder-icon.png";
import { SortableItem } from "./sortable_item";
import PropTypes from "prop-types";
import { ProcessParameterIndexer } from "../../../processExplorer/indexers/process_parameter_indexer";

/**
 * This is responsible for rendering the related records of an attribute in the view attribute page. In this
 * implementation, the records can be reordered by drag and drop (dnd).
 */
export default class RelatedDnDRecordsPanel extends RelatedRecordsPanel {
  constructor(props) {
    super(props);

    const sortedRecords = ProcessParameterIndexer.sortRecordsBasedOnIDArray(props.recordOrder, props.records);
    this.setStateSafely({
      isInReorderMode: false,
      records: sortedRecords,
      recordOrder: props.recordOrder,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (super.shouldComponentUpdate(nextProps, nextState)) {
      return true;
    }

    if (this.props.isSavingReorderInProgress !== nextProps.isSavingReorderInProgress) {
      return true;
    }

    const renderModeChanged = nextState.isInReorderMode !== this.state.isInReorderMode;
    if (renderModeChanged) {
      return true;
    }

    const nextRecordOrder = (nextState.records || nextProps.records).map(record => record.id).join(",");
    const recordOrder = (this.state.records || this.props.records).map(record => record.id).join(",");

    if (nextRecordOrder !== recordOrder) {
      return true;
    }

    return false;
  }

  componentDidUpdate(previousProps, previousState) {
    super.componentDidUpdate(previousProps, previousState);

    const {recordOrder, records} = this.props;

    // The recordOrder/records might not be available when first created, so we need to update it when loaded.
    if (JSON.stringify(recordOrder) !== JSON.stringify(this.state.recordOrder)
      || records?.length !== this.state.records?.length) {
      const sortedRecords = ProcessParameterIndexer.sortRecordsBasedOnIDArray(recordOrder, records);
      this.setStateSafely({
        records: sortedRecords.filter(record => record),
        recordOrder: recordOrder,
      });
    }
  }

  handleReorderButtonClick() {
    if (can(CommonSecurity.Actions.ADD, this.modelFullName)) {
      this.setStateSafely({
        isInReorderMode: true,
      });
    }
  }

  handleSaveButtonClick() {
    this.props.onSaveReorder(this.state.records);
    this.setStateSafely({
      isInReorderMode: false,
    });
  }

  handleCancelButtonClick() {
    this.setStateSafely({
      records: [...this.props.records],
      isInReorderMode: false,
    });
  }

  handleOnDragReorder(event) {
    const {active, over} = event;

    if (active.id !== over.id) {
      let records = this.state.records.filter(record => record);
      const oldIndex = records.findIndex(record => record.id === active.id);
      const newIndex = records.findIndex(record => record.id === over.id);
      records = arrayMove([...records], oldIndex, newIndex);
      this.setStateSafely({records});
    }
  }

  get records() {
    return this.state.records;
  }

  /**
   * Render the buttons that allow you to change to re-order mode, save or cancel.
   *
   * @param thisProject {*} An instance that contains the information about the current project.
   * @param thisProcess {*} An instance that contains the information about the current process.
   * @param records {[*]} An array of records to be rendered in the panel.
   * @param isInReorderMode {boolean} True if the user is re-ordering records, false otherwise.
   * @return {JSX.Element|string}
   */
  renderButtons(thisProject, thisProcess, records, isInReorderMode) {
    if (!(thisProject && thisProject.deletedAt) && !(thisProcess && thisProcess.deletedAt)) {
      if (isInReorderMode) {
        return <Fragment>
          <button id={`save${this.props.modelName}RelatedRecordButton`}
                  title="Save"
                  className="btn btn-sm btn-primary"
                  onClick={this.handleSaveButtonClick}
          >
            <FontAwesomeIcon icon={faCheck} />
          </button>
          <button id={`cancel${this.props.modelName}RelatedRecordButton`}
                  className="btn btn-sm btn-secondary"
                  onClick={this.handleCancelButtonClick}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </Fragment>;
      } else {
        return <button id={`reorder${this.props.modelName}RelatedRecordButton`}
                       title={generateTooltip(CommonSecurity.Actions.EDIT, this.modelFullName, undefined, "Reorder")}
                       className="btn btn-sm btn-light"
                       onClick={this.handleReorderButtonClick}
                       disabled={!can(CommonSecurity.Actions.EDIT, this.modelFullName) || records.length <= 1}
        >
          <img src={reorderIcon}
               width={13} height={18.2}
               aria-label="Reorder"
               alt="Reorder"
          />
        </button>;
      }
    }

    return "";
  }

  render() {
    let {records, isInReorderMode} = this.state;
    records = records.filter(record => record);

    const {isSavingReorderInProgress, header, id, projectId} = this.props;
    const thisProject = getProjectFromCache(projectId);
    const thisProcess = this.getProcessFromCache();

    return (
      <div className={"related-records-container row-white" + this.getClassForLoading()}>
        <div className="related-records-title row">
          <div className="col">
            {header}
          </div>
          <div className={"btn-group col-auto" + (isSavingReorderInProgress ? " skeleton" : "")}>
            {this.renderButtons(thisProject, thisProcess, records, isInReorderMode || isSavingReorderInProgress)}
          </div>
        </div>
        <div className={"related-dnd-records-container" + (isInReorderMode ? "" : " d-none")}>
          <DndContext
            collisionDetection={closestCenter}
            modifiers={[restrictToParentElement, restrictToVerticalAxis, restrictToWindowEdges]}
            onDragEnd={this.handleOnDragReorder}
            autoScroll={{
              threshold: {
                x: 0.0, // Never scroll in the X direction
                y: 0.1, // Scroll within 10% of the Up/Down direction
              },
            }}
          >
            <SortableContext
              items={records}
              strategy={verticalListSortingStrategy}
            >
              {records.map(record =>
                <SortableItem key={record.id}
                              id={record.id}
                              name={record.name}
                              label={`${this.getRecordFullKey(record)} ${record.name}`}
                />)
              }
            </SortableContext>
          </DndContext>
        </div>
        <div className={isInReorderMode ? " d-none" : ""}>
          <table ref={ref => this.tableRef = ref}
                 className={"table table-hover related-records-table" + this.getClassForLoading()}
                 id={`${id}Table`}
                 style={{width: "100%"}}
          />
        </div>
      </div>
    );
  }
}

RelatedDnDRecordsPanel.propTypes = {
  id: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  projectId: PropTypes.number,
  parent: PropTypes.object.isRequired,
  records: PropTypes.arrayOf(PropTypes.object),
  onSaveReorder: PropTypes.func.isRequired,
  isSavingReorderInProgress: PropTypes.bool,
};
