import React from "react";
import * as I18NWrapper from "../../../i18n/i18n_wrapper";
import ProjectMaterialAttributeBasePage from "../project_material_attribute_base_page";
import { MATERIAL_ATTRIBUTE_ACTIONS } from "../../helpers/library_helper";

class LinkProjectMaterialAttributePage extends ProjectMaterialAttributeBasePage {

  componentDidMount() {
    document.title = "Link Project Material Attribute";

    super.componentDidMount();
  }

  onTableDataChange = (materialAttributes) => {
    if (materialAttributes.length > 0) {
      const materialAttribute = materialAttributes[0];
      materialAttribute.linkedToSpecificationVersionId = materialAttribute.specification.id;
      this.setStateSafely({
        materialAttribute,
      });
    }
  };

  getTitle(materialAttribute) {
    return "Link " + materialAttribute.name + " to Library Specification";
  }

  getSubtitle() {
    return "Link Material Attribute to Library Specification.";
  }

  getAction() {
    return MATERIAL_ATTRIBUTE_ACTIONS.LINK;
  }

  getActionButton() {
    return (
      <button className="btn btn-lg btn-primary float-right"
              disabled={!this.state.materialAttribute?.specification}
              id="linkButton"
              onClick={this.onActionButtonClick}
      >
        Link
      </button>
    );
  }
}

export default I18NWrapper.wrap(LinkProjectMaterialAttributePage, "library");
