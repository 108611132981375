"use strict";

import { ServiceBase } from "../service_base";

/**
 * Service layer for changing the rmp on a project functionality. Use this to execute change rmp operations on the backend.
 */
export class ChangeRMPService extends ServiceBase {
  /**
   * Clones a record
   * @param payload The payload for the request
   */
  changeRmp(payload) {
    let parameters = {
      action: "changeRmp",
      useTwoWayCommunication: true,
    };

    return this.sendToBackend(payload, parameters);
  }

  checkProject(payload) {
    let parameters = {
      action: "checkProjectBeforeChangingRMP",
      useTwoWayCommunication: true,
    };

    return this.sendToBackend(payload, parameters);
  }
}