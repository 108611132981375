var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { getNestedValue } from './../utils';
import { guid } from '@progress/kendo-react-common';
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';
/**
 * @hidden
 */
export var GridSelectionCell = function (props) {
    var handleOnChange = React.useCallback(function (syntheticEvent) {
        if (props.selectionChange) {
            props.selectionChange({ syntheticEvent: syntheticEvent });
        }
    }, [props.selectionChange]);
    var inputId = React.useMemo(function () { return guid(); }, []);
    var selectedValue = getNestedValue(props.field, props.dataItem);
    var navigationAttributes = useTableKeyboardNavigation(props.id);
    var defaultRendering = props.rowType !== 'groupHeader' ? (React.createElement("td", __assign({ colSpan: props.colSpan, style: props.style, className: props.className, "aria-colindex": props.ariaColumnIndex, role: 'gridcell' }, navigationAttributes),
        React.createElement("input", { checked: typeof selectedValue === 'boolean' && selectedValue, id: inputId, type: "checkbox", className: "k-checkbox k-checkbox-md k-rounded-md", onChange: handleOnChange }),
        React.createElement("label", { className: "k-checkbox-label", htmlFor: inputId }))) : null;
    return props.render ?
        props.render.call(undefined, defaultRendering, props) :
        defaultRendering;
};
