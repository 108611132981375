"use strict";

import React from "react";
import BasePopup from "../../editor/approval/base_popup";

/**
 * The popup notifies user to save the record before leaving it
 */
export default class DocumentSaveBeforeLeavingPopup extends BasePopup {
  render() {
    return (
      <div className="modal fade" ref={this.setModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Save changes before leaving</h1>
              <button
                type="button"
                className="close"
                onClick={this.handleCancel}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-container">
                <p id="popup-info-message">
                  You haven&apos;t saved your changes. Please save the Document
                  Record before moving on.
                </p>
              </div>
            </div>
            <div className="modal-footer p-0">
              <div className="modal-container">
                <div className="btn-group">
                  <button
                    id="stayToSaveButtonOnPopup"
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleCancel}
                  >
                    Stay to Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
