"use strict";

import React, { Fragment } from "react";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import * as UIUtils from "../../ui_utils";
import BasePage from "../../base_page";
import LinkTechTransferWizard from "./techTransferWizard/link_tech_transfer_wizard";
import LinkTechTransferPageTitleBar from "./link_tech_transfer_page_title_bar";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import MultipleTypeaheadObjectCache from "../../utils/cache/multiple_typeahead_object_cache";

class LinkTechTransferPage extends BasePage {

  constructor(props) {
    super(props);

    this.setStateSafely({
      projectId: UIUtils.getParameterByName("projectId"),
      processId: UIUtils.getParameterByName("processId"),
    });
    const requestData = {
      excludeArchived: true,
    };

    new TypeaheadObjectCache("Project").loadOptions((projects) => {
      const project = projects.find(project => project.id === this.state.projectId);
      new TypeaheadObjectCache("Process", project.id).loadOptions((allProcesses) => {
        const receivingProcess = allProcesses.find(process => process.id === this.state.processId);
        const processes = allProcesses.filter(process => process.id !== receivingProcess.id && !process.SendingId);
        let receivingRecords = {
          Steps: [],
          UnitOperations: [],
        };

        new MultipleTypeaheadObjectCache(["UnitOperation", "Step"], project.id, receivingProcess.id).loadOptions((records) => {
          if (records.length) {
            if (records[0].typeCode === "UO") {
              receivingRecords.UnitOperations.push(...records.map(record => {
                record["displayName"] = `${record.name} (${record.typeCode}-${record.id})`;
                return record;
              }));
            } else {
              receivingRecords.Steps.push(...records.map(record => {
                record["displayName"] = `${record.name} (${record.typeCode}-${record.id})`;
                return record;
              }));
            }
          }
          this.setStateSafely({
            project,
            receivingProcess,
            processes,
            receivingRecords
          });
        }, requestData);
      }, requestData);


    }, requestData);
  }

  componentDidMount() {
    document.title = "QbDVision Link Processes for Tech Transfer";

    super.componentDidMount();
  }

  getProjectUrl() {
    const {projectId, processId} = this.state;

    let url = "/processExplorer/processExplorer.html?";
    url += projectId ? `projectId=${projectId}` : "";
    url += processId ? `&processId=${processId}` : "";

    return UIUtils.getSecuredURL(url);
  }

  renderPage() {
    const {
      project,
      receivingProcess,
      processes,
      receivingRecords
    } = this.state;
    return (
      <Fragment>

        <LinkTechTransferPageTitleBar backLink={this.getProjectUrl()}
        />
        <div className="container-spacer" />
        <div className="container">
          <LinkTechTransferWizard
            project={project}
            receivingProcess={receivingProcess}
            processes={processes}
            receivingRecords={receivingRecords}
          />
        </div>
      </Fragment>
    );
  }
}

export default I18NWrapper.wrap(LinkTechTransferPage, "linkTechTransferPage");

