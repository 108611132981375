"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import moment from "moment";
import * as CannedReportHelper from "./canned_report_helper";
import { loadRMP } from "../../helpers/risk_helper";
import DataReportBase from "../data_report_base";
import { MODEL_TYPES_ENUM, REPORT_TYPES_ENUM } from "../constants/report_constants";
import * as ProcessCache from "../../processExplorer/process/process_cache";
import { Log, LOG_GROUP } from "../../../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.Reports, "CannedReport");

/* This class shows all canned reports */
export default class CannedReport extends DataReportBase {
  constructor(props) {
    super(props);

    this.setStateSafely({
      filterOptions: {
        type: ["fqas", "iqas"],
        criticality: [],
      }
    });

    this.allRiskLabels = [];

    if (this.props.reportOptions.runAtStart) {
      this.loadHistoricalReportData();
    }
  }

  getReportCategoryType() {
    return "cannedReports";
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);
    this.clearReport();
    if (prevProps.processId !== this.props.processId) {
      if (this.props.reportOptions.runAtStart) {
        this.loadHistoricalReportData();
      }
    } else if (!this.props.reportOptions.renderOnEmptyData && this.state.reportResults && !this.state.reportResults.lastVersion) {
      $("#dataReportViewer").show();
    } else if (this.state.reportResults) {
      $("#dataReportViewer").show();
      this.renderReport();
    }
  }

  clearReport() {
    const dataReportViewer = $("#dataReportViewer");
    if (dataReportViewer.length > 0) {
      dataReportViewer.empty();
    }
  }

  renderReport() {
    let editableName = this.getEditableName();
    let report = new window.Stimulsoft.Report.StiReport();
    report.loadFile(CannedReportHelper.getReportTemplateFullPath(this.props.reportType, editableName));

    let dataSet = new window.Stimulsoft.System.Data.DataSet("DataSet");
    let {reportResults} = this.state;

    // Code Purpose: Test Validation Strategy
    //
    // Breakage Scenario 6.1: This test will be broken by corrupting report data.
    //
    // DANGEROUS: Uncomment this to break the Canned reports feature intentionally.
    // reportResults = {};
    dataSet.readJson(JSON.stringify(reportResults));
    report.dictionary.databases.clear();
    report.regData(dataSet.dataSetName, "", dataSet);

    UIUtils.showLoadingImage("Rendering...");
    report.renderAsync(() => {
      Logger.info("Render completed");
      let viewer = new window.Stimulsoft.Viewer.StiViewer(
        CannedReportHelper.getViewerOptions(this.props.reportType, this.props.reportOptions.enableZoom),
        "StiViewer",
        false
      );

      viewer.report = report;
      viewer.renderHtml("dataReportViewer");

      this.report = report;
      this.waitForHTMLRenderAndReplaceURLs();
    });
  }

  waitForHTMLRenderAndReplaceURLs(remainingAttempts = 600, pagesFound = 0, linksFound = 0) {
    const ATTEMPTS_INTERVAL = 100;
    const EXTRA_ATTEMPTS_TO_WAIT_FOR_NEW_LINKS = 10;
    const URL_FORMAT_REGEX = /(http)(s?):\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/;

    // Stimulsoft will take out all links and replace with a <font> tag.
    // We then need to get all <font> tags inside a document page and try to find out if it's a URL.
    const $reportPages = $(".stiJsViewerPage");
    const currentRenderedPagesCount = $reportPages.toArray().length;
    let currentLinksFoundCount = linksFound;

    if (currentRenderedPagesCount > 0) {
      Logger.info(
        Log.object(new Date()),
        "\nFound rendered pages",
        "\n - Remaining attempts:", Log.object(remainingAttempts),
        "\n - Current rendered pages count:", Log.object(currentRenderedPagesCount),
        "\n - Total Pages found:", Log.object(pagesFound),
        "\n - Current links found count:", Log.object(currentLinksFoundCount),
        "\n - Total links found:", Log.object(linksFound),
      );

      const allFontElements = $reportPages.find("font").toArray() || [];
      Logger.verbose("Post render: Font elements returned", Log.object(allFontElements));

      let links = allFontElements.filter(item => URL_FORMAT_REGEX.test($(item).text()));

      currentLinksFoundCount = links.length;

      if (currentLinksFoundCount > 0) {
        Logger.info("Links found:", Log.object(currentLinksFoundCount));

        for (let link of links) {
          const $link = $(link);
          const linkUrl = ($link.text() || "").trim();
          const clickableLinkHTML = `<a href="${UIUtils.secureString(linkUrl)}" target="_blank" rel="noopener noreferrer">${UIUtils.secureString(linkUrl)}</a>`;
          Logger.verbose("Replacing link:", Log.object(link), "With: ", Log.object(clickableLinkHTML));
          link.innerHTML = clickableLinkHTML;
          Logger.verbose("Link replaced: ", Log.object(link.innerHTML));
        }
      }

      // This will sound lame, but since Stimulsoft does not have an event to check whether, all rendering is
      // complete, we need to keep checking whether new pages or new links are added for a shorter while.
      // To do the same on them, so there's a some buffer time to do that.
      // If a new page is found, the buffer is reset.
      if (currentRenderedPagesCount > pagesFound || currentLinksFoundCount > linksFound) {
        remainingAttempts = EXTRA_ATTEMPTS_TO_WAIT_FOR_NEW_LINKS;
      }
    } else {
      Logger.info(
        Log.object(new Date()),
        "\nNo links found so far:",
        "\n - Remaining attempts:", Log.object(remainingAttempts),
        "\n - Total Rendered pages:", Log.object(currentRenderedPagesCount),
        "\n - Total Pages found:", Log.object(pagesFound),
        "\n - Current links found count:", Log.object(currentLinksFoundCount),
        "\n - Total links found:", Log.object(linksFound),
      );
    }

    // If no page is found, keeps trying until the report starts getting rendered into the DOM or
    // the remaining attempts are exhausted.
    if (remainingAttempts > 0) {
      remainingAttempts--;
      // there must be a single timer alive
      if (this.renderRetry) {
        clearTimeout(this.renderRetry);
      }
      this.renderRetry = setTimeout(
        () => this.waitForHTMLRenderAndReplaceURLs(remainingAttempts, currentRenderedPagesCount, currentLinksFoundCount),
        ATTEMPTS_INTERVAL,
      );
    } else {
      UIUtils.setHideLoadingOnAjaxStop(true);
      UIUtils.hideLoadingImage();
    }
  }

  getEditableName() {
    // If report has a single type, editableName would equal
    // this.props.reportOptions.editableName (Ex. TPP Section Report).
    // If report has multiple values within (ex. Requirement Report), then
    // editableName would be the name of editable chosen (Ex. FQA, IQA, ... etc)
    if (this.state.filterOptions && this.state.filterOptions.subReport) {
      return this.state.filterOptions.subReport;
    }
    return this.props.reportOptions.editableName ? this.props.reportOptions.editableName : this.modelType;
  }

  handleDateChange(parsedDate, event) {
    Logger.verbose(() => "handleDateChange called with: " + parsedDate + " event: " + (event ? event.target.value : undefined));
    if (parsedDate && parsedDate instanceof Date) {
      UIUtils.setHideLoadingOnAjaxStop(false);
      UIUtils.showLoadingImage("Processing Data");

      this.setStateSafely({
        reportResults: null,
        reportDate: CannedReportHelper.getCorrectedDateForDatePicker(moment(parsedDate)),
      }, () => {
        this.loadHistoricalReportData();
      });
    }
  }

  loadHistoricalReportData() {
    const {
      reportDate,
      filterOptions,
      selectedTypeaheadOption
    } = this.state;

    const processId = this.props.processId || ProcessCache.getProcessIdUsedRecently(this.props.projectId);
    const requestPayload = {
      ...this.getCustomPayloadData(),
      date: CannedReportHelper.getUTCDateForURL(reportDate),
      projectId: this.isCompanyLevelReport() ? null : this.props.projectId,
      subReport: filterOptions && filterOptions.subReport ? filterOptions.subReport : null
    };

    if (processId) {
      requestPayload.processId = processId;
    }

    if (this.props.reportOptions.needsTypeaheadInput && !this.props.reportOptions.hideTypeahead) {
      requestPayload.editableId = selectedTypeaheadOption.id;
      requestPayload.editableName = MODEL_TYPES_ENUM[selectedTypeaheadOption.typeCode];
    }

    // company level reports (such as suppliers or admin reports) don't have a project, so they don't have RMPs
    if (this.isCompanyLevelReport()) {
      this.getDataFromServer(requestPayload);
    } else {
      loadRMP(this.getDataFromServer.bind(this, requestPayload));
    }
  }

  handleReceivedDataFromServer(cachedRMP, project, results) {
    UIUtils.showLoadingImage("Transforming data...");
    let rmpVersions;
    if (cachedRMP) {
      rmpVersions = cachedRMP.approvedVersionsWithDetails;
    }

    const {
      reportDate,
      filterOptions,
      selectedTypeaheadOption
    } = this.state;

    const {
      reportType,
      typeaheadOptions,
      reportOptions,
      dependencyTypeahead
    } = this.props;


    if (results && results.instances) {
      let editableName = this.getEditableName();
      let riskLabels = this.isCompanyLevelReport() ? []
        : CannedReportHelper.getRiskLabelsForRequirementsSummaryReport(project, rmpVersions, reportDate);
      let selectedTypeaheadLabel = selectedTypeaheadOption ? selectedTypeaheadOption.label : "";

      filterOptions.criticality = riskLabels;
      this.allRiskLabels = riskLabels.slice(0);

      const reportResults = CannedReportHelper.jsonPreProcessing(results, reportType,
        reportOptions, project, rmpVersions, editableName, typeaheadOptions,
        dependencyTypeahead, reportDate, filterOptions, selectedTypeaheadLabel);

      this.results = results;
      this.initialRenderingSkipped = false;

      // Uncomment for verbose logging / Getting the results to put them into Stimulsoft to test it there.
      // console.log(JSON.stringify(reportResults));

      this.setStateSafely({
        reportResults,
        isPrintDisabled: false,
        isExportDisabled: false,
        editableName,
        rmpVersions,
        projectWithAllVersions: project,
        filterOptions
      }, () => {
        this.filterDataAfterReceivedFromServer();
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {reportOptions} = this.props;
    if (reportOptions.skipInitialRendering && !this.initialRenderingSkipped) {
      this.initialRenderingSkipped = true;
      return false;
    }

    return super.shouldComponentUpdate(nextProps, nextState);
  }

  handlePrint() {
    if (this.props.reportType === REPORT_TYPES_ENUM.RiskManagementPlan ||
      this.props.reportType === REPORT_TYPES_ENUM.QTPPFullReport) {
      /*
      RMP and QTPP Full Reports are the only ones with unlimited height pages which need to
      be broken to multiple pages which wont happen with printToPdf thus using print function.
       */
      this.report.print();
    } else {
      this.report.printToPdf();
    }
  }

  exportAs(event) {
    UIUtils.ignoreHandler(event);
    let fileExt = event.target.name;
    if (!fileExt) {
      fileExt = event.target.children[0].name; // The user clicked outside of the <a></a> block and on the parent.
    }
    CannedReportHelper.exportAs(CannedReportHelper.getFileType(fileExt), "." + fileExt,
      this.report, this.props.reportType, this.state.editableName);
  }

  /**
   * @returns {jsx.Element|String}
   */
  renderInput() {
    const {renderOnEmptyData, fullScreen} = this.props.reportOptions;
    const {reportResults} = this.state;

    const skipRender = !renderOnEmptyData && reportResults && !reportResults.lastVersion;
    return !skipRender ? (
      <div id="dataReportViewer" className={`col-12 ${fullScreen ? "data-report-viewer-full-screen" : ""}`} />
    ) : "";
  }
}
