"use strict";

import React from "react";
import BaseReactComponent from "../../../base_react_component";
import { hasSufficientDependencies } from "../../helpers/import_security";
import ImportFlowCard from "./import_flow_card";
import * as UIUtils from "../../../ui_utils";

/**
 * This renders the import data types options panel
 */
export default class ImportFlowOptionsPanel extends BaseReactComponent {
  renderImportFlowCards(importConfigs) {
    return importConfigs
      .map(config => this.getImportTypeContents(config))
      .sort(UIUtils.sortBy("order"))
      .map(rec => rec.contents);
  }

  getImportTypeContents(importConfig) {
    const {
      id,
      projectId,
      processId,
      batchId,
      importDependenciesStatus,
      onImportDataTypeSelected,
      disableAllFlows,
      disabledAllFlowsReason
    } = this.props;
    let enabled = !disableAllFlows
      && importDependenciesStatus
      && hasSufficientDependencies(importDependenciesStatus, importConfig, processId);

    return {
      contents: <ImportFlowCard key={importConfig.key}
                                id={`${id}_${importConfig.key}`}
                                enabled={enabled}
                                disabledReason={disabledAllFlowsReason}
                                projectId={projectId}
                                processId={processId}
                                batchId={batchId}
                                importConfig={importConfig}
                                importDependenciesStatus={importDependenciesStatus}
                                onImportDataTypeSelected={onImportDataTypeSelected}
      />,
      order: this.props.importConfigs.indexOf(importConfig),
    };
  }

  render() {
    const {importConfigs} = this.props;

    return (
      <div id={this.props.id}
           className="data-import-menu-options-container row-white"
      >
        <div className="col">
          {this.renderImportFlowCards(importConfigs)}
        </div>
      </div>
    );
  }
}