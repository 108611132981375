var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { classNames } from '@progress/kendo-react-common';
var TabStripNavigationItem = /** @class */ (function (_super) {
    __extends(TabStripNavigationItem, _super);
    function TabStripNavigationItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onClick = function () {
            if (_this.props.onSelect) {
                _this.props.onSelect(_this.props.index);
            }
        };
        return _this;
    }
    /**
     * @hidden
     */
    TabStripNavigationItem.prototype.render = function () {
        var _a;
        var _b = this.props, id = _b.id, active = _b.active, disabled = _b.disabled, _c = _b.title, title = _c === void 0 ? 'Untitled' : _c, first = _b.first, last = _b.last;
        var props = {
            'id': id,
            'aria-selected': active,
            'aria-controls': id,
            'aria-disabled': disabled,
            'aria-hidden': disabled,
            'role': 'tab',
            onClick: !disabled ? this.onClick : undefined
        };
        var itemClasses = classNames('k-item', (_a = {},
            _a['k-first'] = first,
            _a['k-last'] = last,
            _a['k-disabled'] = disabled,
            _a['k-active'] = active,
            _a));
        return (React.createElement("li", __assign({}, props, { className: itemClasses }),
            React.createElement("span", { className: "k-link" }, title)));
    };
    /**
     * @hidden
     */
    TabStripNavigationItem.propTypes = {
        active: PropTypes.bool,
        disabled: PropTypes.bool,
        index: PropTypes.number,
        onSelect: PropTypes.func,
        title: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.element
        ]),
        first: PropTypes.bool,
        last: PropTypes.bool
    };
    return TabStripNavigationItem;
}(React.Component));
export { TabStripNavigationItem };
