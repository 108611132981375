"use strict";


import ConfigurableTablesAdapter from "../configurableTables/adapters/configurable_tables_adapter";

export default class SupplierTableAdapter extends ConfigurableTablesAdapter {

  constructor(params) {
    super(params, "supMap", "Suppliers");
  }

  getRows() {
    return this.results?.instances;
  }

}