var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var DEFAULT_NUMBER = 1111111.1111111;
var MAX_DECIMAL = 0.31111111111111117;
var MIN_DECIMAL = 1;
var ONE_NUMBER = 1;
var ZERO_NUMBER = 0;
var DIGITS_REGEX = /\d/;
var MAX_PRECISION = 15;
/**
 * @hidden
 */
export var getInitialState = function () { return ({
    eventValue: null,
    prevLooseValue: '',
    currentLooseValue: '',
    focused: false,
    selectionStart: undefined,
    selectionEnd: undefined,
    decimalSelect: false,
    valueIsCorrected: false,
    valueIsOutOfRange: false
}); };
/**
 * @hidden
 */
export var getStateOrPropsValue = function (value, stateValue) {
    return value !== undefined ? value : stateValue;
};
/**
 * @hidden
 */
export var formatValue = function (value, format, intlService) {
    if (value === null && value === undefined) {
        return '';
    }
    if (typeof value === 'string') {
        return value;
    }
    return intlService.formatNumber(value, format);
};
/**
 * @hidden
 */
export var fractionLength = function (value) {
    return (String(value).split('.')[1] || '').length;
};
/**
 * @hidden
 */
var maxFractionLength = function (value1, value2) {
    return Math.max(fractionLength(value1), fractionLength(value2));
};
/**
 * @hidden
 */
export var limitPrecision = function (precision) { return Math.min(precision, MAX_PRECISION); };
/**
 * @hidden
 */
export var toFixedPrecision = function (value, precision) {
    var maxPrecision = limitPrecision(precision);
    return parseFloat(value.toFixed(maxPrecision));
};
/**
 * @hidden
 */
export var increaseValue = function (value, newState, step, min, max, format, intlService) {
    var maxPrecision = maxFractionLength(value || 0, step || 0);
    var increasedValue = rangeValue(toFixedPrecision((value || 0) + (step || 0), maxPrecision), min, max);
    newState.eventValue = increasedValue;
    newState.currentLooseValue = formatValue(increasedValue, format, intlService);
    newState.selectionStart = newState.selectionEnd = getLastNumberIndex(newState.currentLooseValue, DIGITS_REGEX);
};
/**
 * @hidden
 */
export var decreaseValue = function (value, newState, step, min, max, format, intlService) {
    var maxPrecision = maxFractionLength(value || 0, step || 0);
    var decreasedValue = rangeValue(toFixedPrecision((value || 0) - (step || 0), maxPrecision), min, max);
    newState.eventValue = decreasedValue;
    newState.currentLooseValue = formatValue(decreasedValue, format, intlService);
    newState.selectionStart = newState.selectionEnd = getLastNumberIndex(newState.currentLooseValue, DIGITS_REGEX);
};
/**
 * @hidden
 */
export var rangeValue = function (value, min, max) {
    if (value == null) {
        return value;
    }
    if (!(value > 1 || value < 1 || value === 1)) {
        // null, undefined or NaN
        return null;
    }
    if (max !== undefined && min !== undefined && max < min) {
        return null;
    }
    if (max !== undefined && value > max) {
        value = max;
    }
    if (min !== undefined && value < min) {
        value = min;
    }
    return value;
};
/**
 * @hidden
 */
export var getMaxCursorPosition = function (nextValue, formatInfo) {
    var formatSuffixIndex = formatInfo.findIndex(function (_a) {
        var _ = _a[0], currSuffix = _a[1];
        return Boolean(currSuffix) && nextValue.indexOf(currSuffix) === nextValue.length - currSuffix.length;
    });
    if (formatSuffixIndex === -1) {
        return -1;
    }
    var suffix = formatInfo[formatSuffixIndex][1];
    return nextValue.length - suffix.length;
};
/**
 * @hidden
 */
export var getMinCursorPosition = function (nextValue, formatInfo) {
    var formatPrefixIndex = formatInfo.findIndex(function (_a) {
        var currPrefix = _a[0], _ = _a[1];
        return Boolean(currPrefix) && nextValue.indexOf(currPrefix) === 0;
    });
    if (formatPrefixIndex === -1) {
        return -1;
    }
    var prefix = formatInfo[formatPrefixIndex][0];
    return prefix.length;
};
/**
 * @hidden
 */
export var rangeSelection = function (nextLooseValue, formatInfo, newState) {
    var maxPosition = getMaxCursorPosition(nextLooseValue, formatInfo);
    if (maxPosition !== -1 && newState.selectionStart > maxPosition) {
        newState.selectionStart = newState.selectionEnd = maxPosition;
        return;
    }
    if (newState.selectionStart > nextLooseValue.length) {
        newState.selectionStart = newState.selectionEnd = nextLooseValue.length;
    }
    var minPosition = getMinCursorPosition(nextLooseValue, formatInfo);
    if (minPosition !== -1 && newState.selectionStart < minPosition) {
        newState.selectionStart = newState.selectionEnd = minPosition;
    }
    if (newState.selectionStart === -1) {
        newState.selectionStart = newState.selectionEnd = 0;
    }
};
/**
 * @hidden
 */
export var setSelection = function (newState, newIndex, nextLooseValue, formatInfo) {
    newState.selectionStart = newState.selectionEnd = newIndex;
    rangeSelection(nextLooseValue, formatInfo, newState);
};
/**
 * @hidden
 */
export var setInvalid = function (newState, format, formatInfo, intlService) {
    newState.eventValue = intlService.parseNumber(newState.prevLooseValue, format);
    newState.currentLooseValue = newState.prevLooseValue;
    newState.valueIsCorrected = true;
    setSelection(newState, newState.selectionStart, newState.currentLooseValue, formatInfo);
};
/**
 * @hidden
 */
export var isMinusSymbolAdded = function (newState, symbols) {
    var newText = String(newState.currentLooseValue);
    var oldText = String(newState.prevLooseValue);
    return (newText.split(symbols.minusSign).length !== oldText.split(symbols.minusSign).length &&
        newText.length === oldText.length + symbols.minusSign.length);
};
/**
 * @hidden
 */
export var isMinusSymbolRemoved = function (newState, symbols) {
    var newText = String(newState.currentLooseValue);
    var oldText = String(newState.prevLooseValue);
    return (newText.indexOf(symbols.minusSign) === -1 && oldText.indexOf(symbols.minusSign) !== -1);
};
/**
 * @hidden
 */
export var isDecimalDuplicated = function (newState, symbols) {
    var newText = String(newState.currentLooseValue);
    return newText.split(symbols.decimal).length > 2;
};
/**
 * @hidden
 */
export var getFormatPrefixSufix = function (format, intlService) {
    var positiveResult = intlService.formatNumber(DEFAULT_NUMBER, format);
    var negativeResult = intlService.formatNumber(-DEFAULT_NUMBER, format);
    var zeroResult = intlService.formatNumber(ZERO_NUMBER, format);
    var oneResult = intlService.formatNumber(ONE_NUMBER, format);
    var positivePrefix = getPrefix(positiveResult);
    var negativePrefix = getPrefix(negativeResult);
    var zeroPrefix = getPrefix(zeroResult);
    var onePrefix = getPrefix(oneResult);
    var positiveSuffix = getSuffix(positiveResult);
    var negativeSuffix = getSuffix(negativeResult);
    var zeroSuffix = getSuffix(zeroResult);
    var oneSuffix = getSuffix(oneResult);
    return {
        positiveInfo: [positivePrefix, positiveSuffix],
        negativeInfo: [negativePrefix, negativeSuffix],
        zeroInfo: [zeroPrefix, zeroSuffix],
        oneInfo: [onePrefix, oneSuffix]
    };
};
/**
 * @hidden
 */
export var getFormatSymbols = function (format, intlService) {
    var positiveResult = intlService.formatNumber(DEFAULT_NUMBER, format);
    var negativeResult = intlService.formatNumber(-DEFAULT_NUMBER, format);
    var zeroResult = intlService.formatNumber(ZERO_NUMBER, format);
    var oneResult = intlService.formatNumber(ONE_NUMBER, format);
    var symbols = intlService.numberSymbols();
    var sanitizeRegex = new RegExp("[\\d\\".concat(symbols.decimal).concat(symbols.group, "]"), 'g');
    var resultWithDuplicates = [positiveResult, negativeResult, zeroResult, oneResult]
        .map(function (result) { return result.replace(sanitizeRegex, ''); })
        .join('');
    return resultWithDuplicates
        .split('')
        .filter(function (x, n, s) { return s.indexOf(x) === n; })
        .join('');
};
/**
 * @hidden
 */
export var getInitialPosition = function (nextLooseValue, symbols) {
    var decimalIdex = nextLooseValue.indexOf(symbols.decimal);
    if (decimalIdex > -1) {
        return decimalIdex;
    }
    return getLastNumberIndex(nextLooseValue, DIGITS_REGEX);
};
/**
 * @hidden
 */
export var reverseString = function (str) {
    return str.split('').reverse().join('');
};
/**
 * @hidden
 */
export var getLastNumberIndex = function (currentLooseValue, inputRegex) {
    return currentLooseValue.length - reverseString(currentLooseValue).search(inputRegex);
};
/**
 * @hidden
 */
export var getPrefix = function (str) {
    return str.split(str[str.search(DIGITS_REGEX)])[0];
};
/**
 * @hidden
 */
export var getSuffix = function (str) {
    var reversedString = reverseString(str);
    return reverseString(reversedString.split(reversedString[reversedString.search(DIGITS_REGEX)])[0]);
};
/**
 * @hidden
 */
export var getFirstNumberIndex = function (prevLooseValue, inputRegex) {
    return prevLooseValue.search(inputRegex);
};
/**
 * @hidden
 */
export var getDecimalCount = function (value, decimal) {
    var currentDecimalPlace = value.indexOf(decimal);
    return currentDecimalPlace > -1 ? value.length - currentDecimalPlace - 1 : 0;
};
/**
 * @hidden
 */
export var changeBasedSelection = function (currentValue, nextValue, selectionPosition, isDelete, sanitizeRegex) {
    var isCurrentLeadingZero = currentValue.replace(sanitizeRegex, '')[0] === '0';
    var isNextLeadingZero = nextValue.replace(sanitizeRegex, '')[0] === '0';
    if (isCurrentLeadingZero && !isNextLeadingZero) {
        return selectionPosition - 1;
    }
    if (isNextLeadingZero && isDelete) {
        return selectionPosition + 1;
    }
    var numberCounter = 0;
    for (var idx = 0; idx < selectionPosition; idx++) {
        if (DIGITS_REGEX.test(currentValue.charAt(idx))) {
            numberCounter++;
        }
    }
    var newSelection = 0;
    while (numberCounter > 0 && nextValue.length > newSelection) {
        if (DIGITS_REGEX.test(nextValue.charAt(newSelection))) {
            numberCounter--;
        }
        newSelection++;
    }
    return newSelection;
};
/**
 * @hidden
 */
export var sanitizeNumber = function (state, format, intlService) {
    var newState = __assign({}, state);
    var prevLooseValue = newState.prevLooseValue;
    var symbols = intlService.numberSymbols();
    var restrictedSymbols = getFormatSymbols(format, intlService);
    var currentLooseValueAsString = String(newState.currentLooseValue);
    var prevLooseValueAsString = String(prevLooseValue);
    var sanitizeRegex = new RegExp("[^\\d\\".concat(symbols.decimal, "]"), 'g');
    var sanitizeGroupRegex = new RegExp("[^\\d\\".concat(symbols.decimal, "\\").concat(symbols.group, "]"), 'g');
    var allSymbolsRegex = new RegExp("[\\d\\".concat(symbols.decimal, "\\").concat(symbols.group, "]"));
    var sanitizedString = currentLooseValueAsString.replace(sanitizeRegex, '');
    var numberStart = getFirstNumberIndex(currentLooseValueAsString, DIGITS_REGEX);
    var numberEnd = numberStart === -1 ? -1 : getLastNumberIndex(currentLooseValueAsString, DIGITS_REGEX);
    var decimalIndex = currentLooseValueAsString.indexOf(symbols.decimal);
    var sanitizedFormattedString = (currentLooseValueAsString.substring(0, numberStart) +
        currentLooseValueAsString.substring(numberStart, numberEnd).replace(sanitizeGroupRegex, '') +
        currentLooseValueAsString.substring(numberEnd, currentLooseValueAsString.length))
        .split('')
        .filter(function (s) { return restrictedSymbols.indexOf(s) !== -1 || s.search(allSymbolsRegex) !== -1; })
        .join('');
    var formattedMax = intlService.formatNumber(MAX_DECIMAL, format).replace(sanitizeRegex, '');
    var maxDecimalIndex = formattedMax.indexOf(symbols.decimal);
    var maxDecimalCount = maxDecimalIndex > -1 ? formattedMax.length - maxDecimalIndex - 1 : 0;
    var formattedMin = intlService.formatNumber(MIN_DECIMAL, format).replace(sanitizeRegex, '');
    var minDecimalIndex = formattedMin.indexOf(symbols.decimal);
    var minDecimalCount = minDecimalIndex > -1 ? formattedMin.length - minDecimalIndex - 1 : 0;
    var _a = getFormatPrefixSufix(format, intlService), positiveInfo = _a.positiveInfo, negativeInfo = _a.negativeInfo, zeroInfo = _a.zeroInfo, oneInfo = _a.oneInfo;
    var formatInfo = [positiveInfo, negativeInfo, zeroInfo, oneInfo];
    var isFormatContainPrefixSuffix = formatInfo.findIndex(function (info) { return info.findIndex(function (nestedInfo) { return Boolean(nestedInfo); }) !== -1; }) !== 1;
    var isDelete = currentLooseValueAsString.length > 0 && currentLooseValueAsString.length < prevLooseValueAsString.length;
    var isPercentFormat = typeof format === 'string' &&
        format[0] === 'p' &&
        currentLooseValueAsString &&
        currentLooseValueAsString.indexOf(symbols.percentSign) === -1;
    if (!newState.isPaste) {
        // 1. Empty input
        if (currentLooseValueAsString === '') {
            newState.eventValue = null;
            newState.currentLooseValue = '';
            return newState;
        }
        // 2. Check is minus sign
        if (newState.currentLooseValue === symbols.minusSign && intlService.formatNumber(-0, format) !== prevLooseValueAsString) {
            newState.eventValue = -0;
            newState.currentLooseValue = formatValue(newState.eventValue, format, intlService);
            setSelection(newState, getInitialPosition(newState.currentLooseValue, symbols), newState.currentLooseValue, formatInfo);
            return newState;
        }
        // 3. Minus sign toggle
        if (isMinusSymbolAdded(newState, symbols)) {
            var nextValue = intlService.parseNumber(prevLooseValue, format);
            newState.eventValue = -(nextValue !== null ? nextValue : 0);
            newState.currentLooseValue = formatValue(newState.eventValue, format, intlService);
            var currentNumberStart = getFirstNumberIndex(newState.currentLooseValue, DIGITS_REGEX);
            var oldNumberStart = getFirstNumberIndex(prevLooseValueAsString, DIGITS_REGEX);
            setSelection(newState, newState.selectionEnd - 1 + (currentNumberStart - oldNumberStart), newState.currentLooseValue, formatInfo);
            return newState;
        }
        if (isMinusSymbolRemoved(newState, symbols)) {
            newState.eventValue = intlService.parseNumber(newState.currentLooseValue, format);
            setSelection(newState, newState.selectionStart, newState.currentLooseValue, formatInfo);
            return newState;
        }
        // 4. Check is decimal symbol
        if (newState.currentLooseValue === symbols.decimal) {
            newState.eventValue = 0;
            var valueCandidate = formatValue(newState.eventValue, format, intlService);
            if (minDecimalCount === 0 && maxDecimalCount > 0) {
                var currentLastNumberIndex = getLastNumberIndex(valueCandidate, DIGITS_REGEX);
                newState.currentLooseValue = valueCandidate.substring(0, currentLastNumberIndex) +
                    symbols.decimal +
                    valueCandidate.substring(currentLastNumberIndex);
            }
            else {
                newState.currentLooseValue = valueCandidate;
            }
            setSelection(newState, getInitialPosition(newState.currentLooseValue, symbols) + 1, newState.currentLooseValue, formatInfo);
            return newState;
        }
        // 5. Duplicate decimal - it's possible only as trailing
        if (isDecimalDuplicated(newState, symbols)) {
            setInvalid(newState, format, formatInfo, intlService);
            return newState;
        }
        // 6. Percent format
        if (isPercentFormat) {
            newState.eventValue = intlService.parseNumber(currentLooseValueAsString, format) / 100;
            newState.currentLooseValue = formatValue(newState.eventValue, format, intlService);
            return newState;
        }
        // 7. More than 15 numeric symbols
        var numericSymbols = String(newState.currentLooseValue).replace(/[^\d]/g, '');
        if (numericSymbols.length > MAX_PRECISION) {
            setInvalid(newState, format, formatInfo, intlService);
            return newState;
        }
        // 8. Check prefix / suffix for modifications
        if (sanitizedString !== currentLooseValueAsString &&
            currentLooseValueAsString &&
            isFormatContainPrefixSuffix) {
            var formatInfoIndex = formatInfo.findIndex(function (_a) {
                var prefix = _a[0], suffix = _a[1];
                var prefixIndex = currentLooseValueAsString.indexOf(prefix);
                var suffixIndex = currentLooseValueAsString.indexOf(suffix);
                var prefixFound = prefixIndex === 0;
                var suffixFound = suffixIndex === currentLooseValueAsString.length - suffix.length;
                var prefixGap = prefixIndex + prefix.length !== numberStart &&
                    numberStart !== -1 &&
                    currentLooseValueAsString[prefixIndex + prefix.length] !== symbols.decimal;
                var suffixGap = suffixIndex !== numberEnd &&
                    numberEnd !== -1 &&
                    currentLooseValueAsString[suffixIndex - 1] !== symbols.decimal;
                if (prefix && suffix) {
                    if (prefixGap || suffixGap) {
                        return false;
                    }
                    return prefixFound && suffixFound;
                }
                if (prefix) {
                    if (prefixGap) {
                        return false;
                    }
                    return prefixFound;
                }
                if (suffix) {
                    if (suffixGap) {
                        return false;
                    }
                    return suffixFound;
                }
                return false;
            });
            if (formatInfoIndex === -1) {
                setInvalid(newState, format, formatInfo, intlService);
                return newState;
            }
        }
        // 9. Value ending on decimal separator (here as decimal might be typed inside format)
        if (sanitizedString[sanitizedString.length - 1] === symbols.decimal && maxDecimalCount > 0) {
            newState.eventValue = intlService.parseNumber(currentLooseValueAsString, format);
            newState.currentLooseValue = sanitizedFormattedString;
            return newState;
        }
        // 10. prevent deleting decimal and group symbols
        if (newState.currentLooseValue && prevLooseValue) {
            var isSpecialSymbolDeleted = (restrictedSymbols + symbols.decimal + symbols.group)
                .split('')
                .findIndex(function (s) {
                if (currentLooseValueAsString.split('').filter(function (x) { return x === s; }).length <
                    prevLooseValueAsString.split('').filter(function (x) { return x === s; }).length &&
                    currentLooseValueAsString.length + 1 === prevLooseValueAsString.length) {
                    if (s === symbols.decimal &&
                        getDecimalCount(prevLooseValueAsString.replace(sanitizeRegex, ''), symbols.decimal) === 0) {
                        return false;
                    }
                    return true;
                }
                return false;
            }) > -1;
            if (isSpecialSymbolDeleted) {
                newState.eventValue = intlService.parseNumber(state.prevLooseValue, format);
                newState.currentLooseValue = state.prevLooseValue;
                return newState;
            }
        }
        var currentDecimalCount = getDecimalCount(sanitizedString, symbols.decimal);
        var endsOnDecimal = sanitizedString[sanitizedString.length - 1] === '0';
        // 11. Deleting more decimals than allowed
        if (isDelete && endsOnDecimal && currentDecimalCount < minDecimalCount) {
            newState.eventValue = intlService.parseNumber(newState.currentLooseValue, format);
            newState.currentLooseValue = formatValue(newState.eventValue, format, intlService);
            return newState;
        }
        // 12. Ending on zero OR more decimals than allowed
        if (currentDecimalCount > 0) {
            var valueUntillDecimal = currentLooseValueAsString.substring(0, decimalIndex);
            if (endsOnDecimal && (!valueUntillDecimal || prevLooseValueAsString.indexOf(valueUntillDecimal) !== 0)) {
                // ending on zero but typing before decimal separator
                newState.eventValue = intlService.parseNumber(newState.currentLooseValue, format);
                var nextLooseValue = formatValue(newState.eventValue, format, intlService);
                setSelection(newState, changeBasedSelection(currentLooseValueAsString, nextLooseValue, newState.selectionEnd, isDelete, sanitizeRegex), nextLooseValue, formatInfo);
                newState.currentLooseValue = nextLooseValue;
                return newState;
            }
            if (currentDecimalCount > maxDecimalCount) {
                // typing more symbols than format allows
                var looseDecimalPlace = currentLooseValueAsString.indexOf(symbols.decimal);
                var result = currentLooseValueAsString.substring(0, looseDecimalPlace) +
                    currentLooseValueAsString.substring(looseDecimalPlace, looseDecimalPlace + 1 + maxDecimalCount) +
                    currentLooseValueAsString.substring(numberEnd, String(newState.currentLooseValue).length);
                newState.eventValue = intlService.parseNumber(result, format);
                newState.currentLooseValue = result;
                setSelection(newState, newState.selectionStart, result, formatInfo);
                return newState;
            }
            if (minDecimalCount !== maxDecimalCount && currentDecimalCount <= maxDecimalCount && endsOnDecimal) {
                // adding trailing zeroes
                newState.eventValue = intlService.parseNumber(newState.currentLooseValue, format);
                newState.currentLooseValue = sanitizedFormattedString;
                return newState;
            }
            if (currentDecimalCount < minDecimalCount) {
                // deleting more decimals than allowed
                newState.eventValue = intlService.parseNumber(newState.currentLooseValue, format);
                newState.currentLooseValue = formatValue(newState.eventValue, format, intlService);
                return newState;
            }
        }
    }
    // X. All other values should be parsed
    newState.eventValue = intlService.parseNumber(newState.currentLooseValue, format);
    if (isPercentFormat) {
        newState.eventValue = newState.eventValue / 100;
    }
    if (typeof newState.eventValue === 'number') {
        var nextLooseValue = formatValue(newState.eventValue, format, intlService);
        // First digit add
        if (currentLooseValueAsString.length === 1) {
            setSelection(newState, getInitialPosition(nextLooseValue, symbols), nextLooseValue, formatInfo);
        }
        else {
            setSelection(newState, changeBasedSelection(currentLooseValueAsString, nextLooseValue, newState.selectionEnd, isDelete, sanitizeRegex), nextLooseValue, formatInfo);
        }
        newState.currentLooseValue = nextLooseValue;
    }
    else {
        // Case when deleting last number
        newState.currentLooseValue = formatValue(intlService.parseNumber(sanitizedString), format, intlService);
    }
    return newState;
};
