/**
 * @hidden
 */
export var classNames = function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    var result = {};
    var addLeafKeys = function (arg) { return typeof arg === 'object' ? Object
        .keys(arg)
        .forEach(function (key) {
        result[key] = arg[key];
    }) : result[arg] = true; };
    ;
    var addKeys = function (list) { return list
        .filter(function (arg) { return arg !== true && !!arg; })
        .map(function (arg) {
        return Array.isArray(arg) ?
            addKeys(arg) :
            addLeafKeys(arg);
    }); };
    ;
    addKeys(args);
    return Object
        .keys(result)
        .map(function (key) { return (result[key] && key) || null; })
        .filter(function (el) { return el !== null; })
        .join(' ');
};
