import ReactDOM from "react-dom";
import React from "react";
import BaseTable from "./base_table";
import * as UIUtils from "../../ui_utils";

// i18next-extract-mark-ns-start base_page
/**
 * This is a base table class for supporting table diffs when viewing historical records
 */
export default class BaseDiffableTable extends BaseTable {
  constructor(props) {
    super(props);
  }

  generateColumn(title, prop, propForSorting = prop, className = "", format = (rowData) => rowData[prop]) {
    const column = {
      title: title,
      data: result => UIUtils.secureString(this.props.isDiffingVersions ? result[propForSorting] : result[prop]) || "",
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          format(rowData), td);
      }
    };

    if (className) {
      column.className = className;
    }

    return column;
  }

  shouldForceRedraw() {
    return this.props.isDiffingVersions;
  }
}
// i18next-extract-mark-ns-stop base_page

