var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Group } from './Group';
import { validatePackage } from '@progress/kendo-react-common';
import { packageMetadata } from '../package-metadata';
/**
 * Represents the [KendoReact Filter component]({% slug overview_filter %}).
 */
var Filter = /** @class */ (function (_super) {
    __extends(Filter, _super);
    function Filter(props) {
        var _this = _super.call(this, props) || this;
        _this.onFilterChange = function (event) {
            var changeEvent = {
                filter: event.nextFilter,
                syntheticEvent: event.syntheticEvent,
                nativeEvent: event.nativeEvent,
                target: _this
            };
            _this.props.onChange.call(undefined, changeEvent);
        };
        _this.onGroupRemove = function (event) {
            var changeEvent = {
                filter: __assign(__assign({}, _this.props.value), { filters: [] }),
                syntheticEvent: event.syntheticEvent,
                nativeEvent: event.nativeEvent,
                target: _this
            };
            _this.props.onChange.call(undefined, changeEvent);
        };
        validatePackage(packageMetadata);
        return _this;
    }
    /**
     * @hidden
     */
    Filter.prototype.render = function () {
        return (React.createElement("div", { className: 'k-widget k-filter' + (this.props.className ? ' ' + this.props.className : ''), style: this.props.style },
            React.createElement("ul", { className: "k-filter-container" },
                React.createElement("li", { className: "k-filter-group-main" },
                    React.createElement(Group, { filter: this.props.value, fields: this.props.fields, ariaLabel: this.props.ariaLabelGroup, ariaLabelExpression: this.props.ariaLabelExpression, onChange: this.onFilterChange, onRemove: this.onGroupRemove, defaultGroupFilter: this.props.defaultGroupFilter || { logic: 'and', filters: [] } })))));
    };
    /**
     * @hidden
     */
    Filter.propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
        fields: function (props, propName) {
            var prop = props[propName];
            if (prop === undefined) {
                return new Error("Property '".concat(propName, "' is missing."));
            }
            else if (!Array.isArray(prop)) {
                return new Error("Property '".concat(propName, "' needs to be Array<FieldSettings>."));
            }
            else if (Object.keys(prop.reduce(function (acc, cur) {
                var _a;
                return (__assign(__assign({}, acc), (_a = {}, _a[cur.name] = 1, _a)));
            }, {})).length !== prop.length) {
                return new Error("Property '".concat(propName, "' needs to contain objects with unique 'name' field."));
            }
            return null;
        },
        ariaLabelGroup: PropTypes.string,
        ariaLabelExpression: PropTypes.string,
        value: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired
    };
    return Filter;
}(React.Component));
export { Filter };
