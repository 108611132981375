"use strict";

import React, { createContext, useContext, useState } from "react";
import * as UIUtils from "../../../../../../../ui_utils";

const MenuContext = createContext();

export const useMenu = () => useContext(MenuContext);

export const MenuProvider = ({children}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState(null);


  const toggle = (e) => {
    UIUtils.ignoreHandler(e);
    const btn = e.target.getBoundingClientRect();

    const centerX = btn.left - 12;
    const centerY = btn.top + btn.height + 7;

    setPosition({top: centerY, left: centerX});
    setIsOpen(prev => !prev);
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <MenuContext.Provider value={{toggle, close, position, isOpen}}>
      {children}
    </MenuContext.Provider>
  );
};
