import { __assign } from "tslib";
import { NodeSelection, Plugin } from 'prosemirror-state';
import { colgroupAttr, dataResizeDirTable, resizableAttr } from '../../config/constants';
import { getTable, parentNode, parseStyle, setNodeStyle, tableResizeKey as key } from './utils';
import { directions } from './../resize-utils';
var commonDir = {
    'southeast': true,
    'southwest': true,
    'northwest': true,
    'northeast': true
};
var horizontalDir = __assign({ 'east': true, 'west': true }, commonDir);
var verticalDir = __assign({ 'south': true, 'north': true }, commonDir);
var ResizeState = /** @class */ (function () {
    function ResizeState(activeHandle, dragging, nodePosition) {
        this.activeHandle = activeHandle;
        this.dragging = dragging;
        this.nodePosition = nodePosition;
    }
    ResizeState.prototype.apply = function (tr) {
        var state = this, next = tr.getMeta(key);
        if (next) {
            var nextState = new ResizeState(next.activeHandle, next.setDragging, next.nodePosition);
            return nextState;
        }
        return state;
    };
    return ResizeState;
}());
var handleMouseMove = function (view, event) {
    var state = key.getState(view.state);
    var dragging = state.dragging, nodePosition = state.nodePosition, activeHandle = state.activeHandle;
    if (nodePosition < 0 || !dragging) {
        return;
    }
    var tableDom = getTable(view.nodeDOM(nodePosition));
    var rect = tableDom.getBoundingClientRect();
    var dir = directions[activeHandle];
    var diffX = (event.clientX - dragging.startX) * dir.x;
    var diffY = (event.clientY - dragging.startY) * dir.y;
    var win = (tableDom.ownerDocument && tableDom.ownerDocument.defaultView) || window;
    var compStyles = win.getComputedStyle(tableDom);
    var nodeWidth = /px/.test(compStyles.width) ? parseFloat(compStyles.width) : tableDom.offsetWidth;
    var nodeHeight = /px/.test(compStyles.height) ? parseFloat(compStyles.height) : tableDom.offsetHeight;
    var width = dir.x ? diffX + nodeWidth : rect.width;
    var height = dir.y ? diffY + nodeHeight : rect.height;
    dragging.startX = dir.x ? event.clientX : dragging.startX;
    dragging.startY = dir.y ? event.clientY : dragging.startY;
    if (horizontalDir[activeHandle]) {
        tableDom.style.width = width + 'px';
    }
    if (verticalDir[activeHandle]) {
        tableDom.style.height = height + 'px';
    }
};
var toPercents = function (view, tr, tablePos) {
    var tableNode = view.state.doc.nodeAt(tablePos);
    var tableDom = getTable(view.nodeDOM(tablePos));
    var _a = tableSize(tableDom), width = _a.width, height = _a.height, colsWidth = _a.colsWidth, rowsHeight = _a.rowsHeight, offsetWidth = _a.offsetWidth, offsetHeight = _a.offsetHeight;
    var colgroup = tableDom.firstChild;
    var cols = Array.from((colgroup && colgroup.children) || []);
    var widthChanged = false;
    cols.forEach(function (col, i) {
        if (col.style.width && !/%$/.test(col.style.width)) {
            col.style.width = ((colsWidth[i]) * 100 / width) + '%';
            widthChanged = true;
        }
    });
    var heightChange = false;
    tableNode.forEach(function (row, offset, index) {
        var rowHeight = parseStyle(row.attrs.style).height;
        if (rowHeight && !/%$/.test(rowHeight)) {
            tr.setNodeMarkup(tablePos + offset + 1, null, setNodeStyle(row.attrs, 'height', (rowsHeight[index] * 100 / height) + '%'));
            heightChange = true;
        }
    });
    var tableAttrs = tableNode.attrs;
    if (parseStyle(tableAttrs.style).width !== offsetWidth + 'px') {
        tableAttrs = setNodeStyle(tableAttrs, 'width', offsetWidth + 'px');
    }
    if (widthChanged) {
        tableAttrs[colgroupAttr] = colgroup.outerHTML;
    }
    if (heightChange) {
        tableAttrs = setNodeStyle(tableAttrs, 'height', offsetHeight + 'px');
    }
    if (widthChanged || heightChange) {
        tr.setNodeMarkup(tablePos, null, tableAttrs);
    }
};
var toPixels = function (view, tr, tablePos, attrs) {
    var tableNode = view.state.doc.nodeAt(tablePos);
    var tableDom = getTable(view.nodeDOM(tablePos));
    var win = (tableDom.ownerDocument && tableDom.ownerDocument.defaultView) || window;
    var calcStyle = win.getComputedStyle;
    var rows = Array.from(tableDom.rows);
    tableNode.forEach(function (row, offset, index) {
        var rowHeight = parseStyle(row.attrs.style).height;
        if (rowHeight && !/px$/.test(rowHeight)) {
            tr.setNodeMarkup(tablePos + offset + 1, null, setNodeStyle(row.attrs, 'height', calcStyle(rows[index]).height));
        }
    });
    var colgroup = tableDom.firstChild;
    var cols = Array.from((colgroup && colgroup.children) || []);
    var widthChanged = false;
    cols.forEach(function (col, i) {
        if (col.style.width && !/px$/.test(col.style.width)) {
            col.style.width = calcStyle(cols[i]).width;
            widthChanged = true;
        }
    });
    var tableAttrs = __assign({}, attrs);
    if (widthChanged) {
        tableAttrs[colgroupAttr] = colgroup.outerHTML;
    }
    return tableAttrs;
};
var tableSize = function (table) {
    var cols = Array.from(table.firstChild.children);
    var colsWidth = cols.map(function (c) { return c.offsetWidth; });
    var rowsHeight = Array.from(table.rows).map(function (row) { return row.offsetHeight; });
    var width = colsWidth.reduce(function (acc, cur) { return acc + cur; }, 0);
    var height = rowsHeight.reduce(function (acc, cur) { return acc + cur; }, 0);
    var offsetHeight = table.offsetHeight;
    var offsetWidth = table.offsetWidth;
    return { width: width, height: height, colsWidth: colsWidth, rowsHeight: rowsHeight, offsetWidth: offsetWidth, offsetHeight: offsetHeight };
};
var handleMouseUp = function (view) {
    var _a = key.getState(view.state), dragging = _a.dragging, nodePosition = _a.nodePosition, activeHandle = _a.activeHandle;
    if (dragging) {
        var node = view.state.doc.nodeAt(nodePosition);
        var dom = getTable(view.nodeDOM(nodePosition));
        var rect = tableSize(dom);
        if (node) {
            var width = rect.offsetWidth + 'px';
            var height = rect.offsetHeight + 'px';
            var tr = view.state.tr;
            var attrs = node.attrs;
            var parsedStyles = parseStyle(attrs.style);
            if (horizontalDir[activeHandle] && dom.style.width && parsedStyles.width !== width) {
                attrs = setNodeStyle(attrs, 'width', width);
            }
            if (verticalDir[activeHandle] && dom.style.height && parsedStyles.height !== height) {
                attrs = setNodeStyle(attrs, 'height', height);
            }
            attrs = toPixels(view, tr, nodePosition, attrs);
            tr.setNodeMarkup(nodePosition, null, attrs);
            tr.setMeta('commandName', 'node-resize');
            tr.setMeta('args', attrs);
            tr.setMeta(key, {
                setDragging: null,
                activeHandle: null,
                nodePosition: nodePosition
            });
            view.dispatch(tr);
        }
    }
};
var handleMouseDown = function (view, event) {
    var target = event.target;
    var activeHandle = target.getAttribute(dataResizeDirTable);
    if (!activeHandle) {
        return false;
    }
    var resizeState = key.getState(view.state);
    event.preventDefault();
    var transaction = view.state.tr;
    transaction.setMeta(key, {
        setDragging: { startX: event.clientX, startY: event.clientY },
        activeHandle: activeHandle,
        nodePosition: resizeState.nodePosition
    });
    transaction.setMeta('addToHistory', false);
    toPercents(view, transaction, resizeState.nodePosition);
    view.dispatch(transaction);
    var curWindow = event.view || window;
    function move(e) {
        handleMouseMove(view, e);
    }
    function finish(_e) {
        curWindow.removeEventListener('mouseup', finish);
        curWindow.removeEventListener('mousemove', move);
        handleMouseUp(view);
    }
    curWindow.addEventListener('mouseup', finish);
    curWindow.addEventListener('mousemove', move);
    return true;
};
export var tableResizing = function (options) {
    if (options === void 0) { options = { node: 'table' }; }
    return new Plugin({
        key: key,
        view: function (_viewObj) { return ({
            selectedNode: function (state, nodeType) {
                var selection = state.selection;
                var isNodeSelected = selection instanceof NodeSelection && nodeType === selection.node.type;
                if (isNodeSelected && selection instanceof NodeSelection) {
                    return { node: selection.node, pos: selection.from };
                }
                var parent = parentNode(selection.$from, function (n) { return n.type === nodeType; });
                var node = parent && parent.node;
                if (node) {
                    var pos = selection.$from.start(parent.depth) - 1;
                    return { node: node, pos: pos };
                }
                return null;
            },
            update: function (view, prevState) {
                var _a, _b, _c, _d, _f;
                var state = view.state;
                var nodeType = state.schema.nodes[options.node];
                var selected = this.selectedNode(state, nodeType);
                var prevSelected = this.selectedNode(prevState, nodeType);
                if (!selected && prevSelected && !prevState.doc.eq(view.state.doc)) {
                    // selected table is deleted
                    return;
                }
                if (selected || prevSelected) {
                    var tr = state.tr;
                    tr.setMeta('addToHistory', false);
                    if (selected && prevSelected && selected.pos !== prevSelected.pos) {
                        tr.setMeta(key, { nodePosition: selected.pos });
                        tr.setNodeMarkup(prevSelected.pos, nodeType, __assign(__assign({}, prevSelected.node.attrs), (_a = {}, _a[resizableAttr] = false, _a)));
                        tr.setNodeMarkup(selected.pos, nodeType, __assign(__assign({}, selected.node.attrs), (_b = {}, _b[resizableAttr] = true, _b)));
                        view.dispatch(tr);
                    }
                    else if (selected && prevSelected && selected.pos === prevSelected.pos &&
                        !selected.node.attrs[resizableAttr] && !state.selection.eq(prevState.selection)) {
                        tr.setMeta(key, { nodePosition: selected.pos });
                        view.dispatch(tr.setNodeMarkup(selected.pos, nodeType, __assign(__assign({}, selected.node.attrs), (_c = {}, _c[resizableAttr] = true, _c))));
                    }
                    else if (selected && !prevSelected) {
                        tr.setMeta(key, { nodePosition: selected.pos });
                        view.dispatch(tr.setNodeMarkup(selected.pos, nodeType, __assign(__assign({}, selected.node.attrs), (_d = {}, _d[resizableAttr] = true, _d))));
                    }
                    else if (!selected && prevSelected) {
                        tr.setMeta(key, { nodePosition: -1 });
                        view.dispatch(tr.setNodeMarkup(prevSelected.pos, nodeType, __assign(__assign({}, prevSelected.node.attrs), (_f = {}, _f[resizableAttr] = false, _f))));
                    }
                }
            }
        }); },
        state: {
            init: function () {
                return new ResizeState('', null, -1);
            },
            apply: function (tr, prev) {
                return prev.apply(tr);
            }
        },
        props: {
            handleDOMEvents: {
                mousedown: function (view, event) {
                    return handleMouseDown(view, event);
                }
            }
        }
    });
};
