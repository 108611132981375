"use strict";

import React from "react";
import BaseReactComponent from "../../../../base_react_component";

/**
 * This implements the header panel in the import review panel, showing the total number of attributes, the number of
 * attributes with measurements outside of the acceptance criteria and the total measurements outside of the acceptance
 * criteria.
 */
export default class DataReviewHeaderPanel extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {totalAttributes, totalAttributesWithMeasurementsOutOfLimits, totalMeasurementsOutOfLimits} = this.props;

    return (
      <div className="col-12">
        <div className="row header-panel">
          <div className="total-attributes">
            <span id="totalAttributes">{totalAttributes}</span> Records
          </div>
          <div className="total-ofl-attributes">
            <span id="totalAttributesWithWarnings">{totalAttributesWithMeasurementsOutOfLimits}</span> records with a total of
          </div>
          <div className="total-ofl-measurements">
            <span id="numOfMeasurementsOutOfSpecs">{totalMeasurementsOutOfLimits}</span> measurements outside acceptance criteria
          </div>
        </div>
      </div>
    );
  }
}
