"use strict";

import { sortObjectLocationsByPreviousId } from "../../utils/risk_tables_sorter";
import * as CommonRiskTable from "../../utils/risk_tables_helper";

export const UNIT_OPERATION_DATA_MODEL = {
  unitOperationId: -1,
  unitOperation: "",
  unitOperationState: "",
  data: [],
  uoIdToColumnsMap: new Map(),
  columnAttributes: [],
};

/**
 * This is the parent data adapter for All tables.
 * It will contain any common functionality for preparing and transforming from backend model structure
 * to the table view model structure.
 */
export class DataAdapter {

  constructor(reportData, steps, unitOperations) {
    this.steps = steps;
    this.reportData = reportData;
    this.unitOperations = unitOperations;
  }

  convertToRiskTableDataModel(showCriticalOnly, rmp) {
    let reportSortedData = sortObjectLocationsByPreviousId(this.reportData, this.steps, this.unitOperations);
    return CommonRiskTable.getCriticalOnlyData(reportSortedData, showCriticalOnly, rmp);
  }
}
