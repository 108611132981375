"use strict";

import * as UIUtils from "../../ui_utils";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import * as ProcessCache from "../process/process_cache";
import { CommonString } from "../../../server/common/generic/common_string";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import BaseObjectCache from "../../utils/cache/base_object_cache";
import { BaseDropdown } from "../../widgets/dropdowns/base_dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faObjectGroup } from "@fortawesome/free-solid-svg-icons";
import { can, generateTooltip } from "../../utils/ui_permissions";
import CommonSecurity from "../../../server/common/generic/common_security";

/**
 * This class is used to provide a dropdown for the page that will modify the current process.
 */
export class ProcessDropdown extends BaseDropdown {
  constructor(props) {
    super(props);

    this.hasSavedProcessBeenConfirmed = false;

    this.setStateSafely({
      processes: [],
    });

    /*
     * If the parent wants to manage the processes (ie. the process explorer) then use their processes. Otherwise (ex. reports)
     * get them from the cache.
     */
    if (typeof props.processes === "undefined") {
      this.setStateSafely({isLoading: true});
      new TypeaheadObjectCache("Process", props.projectId).loadOptions(this.handleTypeaheadLoaded);
    } else {
      this.confirmSavedProcessExists();
    }
  }

  static getDerivedStateFromProps(props) {
    if (props.processes && props.processes.length > 0) {
      return {processes: ProcessDropdown.sortProcesses(props.processes)};
    }
    return null;
  }

  static sortProcesses(unsortedProcesses) {
    return unsortedProcesses.sort(UIUtils.sortBy({
      name: "name",
    }));
  }

  handleTypeaheadLoaded(processes) {
    this.setStateSafely({processes: ProcessDropdown.sortProcesses(processes), isLoading: false},
      this.confirmSavedProcessExists);
  }

  /**
   * Make sure the user doesn't have a process ID in their browser that a migration has removed.
   */
  confirmSavedProcessExists() {
    if (!this.hasSavedProcessBeenConfirmed) {
      const projectId = this.props.projectId;
      const savedProcessId = ProcessCache.getProcessIdUsedRecently(projectId);
      let processes = this.getProcesses();
      if (savedProcessId && processes.length > 0 && !BaseObjectCache.isObjectCacheStillLoading(processes)) {
        const savedProcess = processes.find(process => process.id === savedProcessId);
        if (!savedProcess) {
          let processId;
          if (this.props.processId !== savedProcessId) {
            processId = this.props.processId;
          } else {
            processId = this.findDefaultProcessId();
          }
          ProcessCache.setProcessIdUsedRecently(projectId, processId);
          this.handleProcessChanged(processId);
        }
        this.hasSavedProcessBeenConfirmed = true;
      }
    }
  }

  forceResetToCachedData() {
    const processes = ProcessDropdown.sortProcesses(new TypeaheadObjectCache("Process").getOptionsFromCacheIncludingArchived());
    this.setStateSafely({processes});
  }

  findDefaultProcessId() {
    let processId = null;
    let processes = this.getProcesses();
    const projectId = this.props.projectId;
    if (processes.length > 0 && !BaseObjectCache.isObjectCacheStillLoading(processes)) {
      processId = UIUtils.parseInt(
        processes.sort(UIUtils.sortBy({
          name: "id",
          primer: parseInt,
        }))[0].id
      );
      if (!ProcessCache.getProcessIdUsedRecently(projectId)) {
        ProcessCache.setProcessIdUsedRecently(projectId, processId);
      }
    }
    return processId;
  }

  handleProcessChanged(processId, event) {
    UIUtils.ignoreHandler(event);
    const previousProcessId = this.props.processId || ProcessCache.getProcessIdUsedRecently(this.props.projectId);
    if (processId !== previousProcessId) {
      if (!this.props.doNotSetCachedProcessId) {
        ProcessCache.setProcessIdUsedRecently(this.props.projectId, processId);
      }
      this.props.onProcessChange(processId, event);
    }

  }

  getProcesses() {
    if (this.props.processes) {
      return this.props.processes;
    } else if (this.props.shouldShowArchivedProcesses) {
      return this.state.processes;
    } else {
      return this.state.processes.filter(process => process.deletedAt === null);
    }
  }

  getButtonId() {
    return "processDropDownButton";
  }

  isLoading() {
    const processes = this.getProcesses();
    return !processes || processes.length === 0; // At least the default process should be there.
  }

  renderDropDownButtonText() {
    let {projectId, processId} = this.props;
    let processes = this.getProcesses();
    processId = processId ? UIUtils.parseInt(processId) : ProcessCache.getProcessIdUsedRecently(projectId);
    if (!processId) {
      processId = this.findDefaultProcessId();
    }
    this.confirmSavedProcessExists();

    const process = processes.find(process => process.id === processId);

    return (
      <span data-toggle="popover"
            data-delay="700"
            data-html="true"
            data-container="body"
            data-placement="top"
            data-trigger="hover"
            data-content="Pick a Process"
            ref={popover => this.popoverRef = popover}
      >
        {CommonString.forceMaxLength(process ? process.name : "", 50)}
      </span>
    );
  }

  handleTechTransferAssessment() {
    let {projectId} = this.props;
    return UIUtils.getSecuredURL(`${UIUtils.FRONT_END_URL}/techTransfer/techTransfer.html?projectId=${projectId}`);
  }

  renderMenuItems() {
    const {onAddProcess} = this.props;
    const processes = this.getProcesses();
    const shouldShowTechTransfer = processes.find(process => process.techTransferEnabled);
    const canAddProcess =can(CommonSecurity.Actions.ADD, CommonSecurity.Types.PROCESS);

    return (
      <Fragment>
        <div key="header" className="disabled dropdown-menu-header">
          Processes
        </div>
        <div id="dropdown-items">
        {processes.map(process =>
          <div key={process.id || "loading"}
               id={"processDropDown-" + UIUtils.convertToId(process.name) + "-div"}
               onClick={this.handleProcessChanged.bind(this, process.id)}
               className={"dropdown-item " + (process.deletedAt !== null ? "archived-process" : "")}
          >
            <a onClick={this.handleProcessChanged.bind(this, process.id)}
               id={"processDropDown-" + UIUtils.convertToId(process.name)}
            >
              {CommonString.forceMaxLength(process.name, 50)}
            </a>
          </div>
        )}
        </div>
        {onAddProcess ? (
          <div key={"add"}
               onClick={canAddProcess ? onAddProcess: null}
               className="dropdown-item"
          >
            <a className={canAddProcess ? "process-bar-add" : "anchor-disabled"}
               onClick={canAddProcess ? onAddProcess: null}
               title={generateTooltip(CommonSecurity.Actions.ADD, CommonSecurity.Types.PROCESS)}
               id={"processDropDown-NewProcess"}
            >
              Add new process
            </a>
          </div>
        ) : ""}
        {shouldShowTechTransfer ?
          <div key={"techTransferAssessment"}
               className="dropdown-item tech-transfer-assessment-border"
          >
            <FontAwesomeIcon className="tech-transfer-assessment-icon"
                             icon={faObjectGroup}
            />
            <a className="process-bar-add"
               href={this.handleTechTransferAssessment()}
               id={"processDropDown-TechTransferAssessment"}
            >
              Tech Transfer Assessment
            </a>
          </div>
          : ""}
      </Fragment>
    );
  }


}

ProcessDropdown.propTypes = {
  projectId: PropTypes.number.isRequired,
  processId: PropTypes.number,
  processes: PropTypes.arrayOf(PropTypes.object),
  onProcessChange: PropTypes.func,
  onAddProcess: PropTypes.func,
  onTechTransferAssessment: PropTypes.func,
  doNotSetCachedProcessId: PropTypes.bool,
};

ProcessDropdown.defaultProps = {
  shouldShowArchivedProcesses: true,
};
