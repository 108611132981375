"use strict";

import React from "react";
import * as UIUtils from "../../../ui_utils";
import { RISK_TYPE_ENUM } from "../../../helpers/constants/constants";
import { getRiskLabel } from "../../../helpers/risk_helper";
import {
  getControlStrategiesToShowForNode,
  showByRiskLabel
} from "./risk_map_report_helper";
import { CONTROL_STRATEGY_DESCRIPTION } from "../../../../server/common/editables/common_editables";

/**
 * Responsible for building up the tooltip for a node on the risk map report
 */
export default class RiskMapTooltipBuilder {

  constructor(builderOptions) {
    const {
      node,
      unitOperation,
      step,
      riskType,
      riskScores,
      filters,
      effectiveRMPForModelType,
      nodeHighlightedInRiskMap,
      selectedTypeaheadOptions,
    } = builderOptions;

    this.node = node;
    this.unitOperation = unitOperation;
    this.step = step;
    this.riskType = riskType;
    this.riskScores = riskScores;
    this.filters = filters;
    this.effectiveRMPForModelType = effectiveRMPForModelType;
    this.nodeHighlightedInRiskMap = nodeHighlightedInRiskMap;
    this.selectedTypeaheadOptions = selectedTypeaheadOptions;
  }

  /**
   * Creates the HTML tooltip for a node on the risk map report.
   * @returns {JSX.Element}
   */
  getTooltip() {
    return (
      <table id="riskMapReportTooltip" className="risk-map-tooltip">
        <tbody>
        {this.buildBaseTooltip()}
        {this.buildControlStrategyTooltip()}
        {this.buildProcessCapabilityTooltip()}
        </tbody>
      </table>
    );
  }

  buildBaseTooltip() {
    const {
      node,
      unitOperation,
      step,
      riskType,
      riskScores,
      filters,
      effectiveRMPForModelType,
    } = this;
    return (
      <>
        <tr id="riskMapReportTooltipName">
          <td>
            <span className="risk-map-tooltip-header">Name:</span>
          </td>
          <td className="risk-map-tooltip-header-value-cell">
            {
              // Code Purpose: Test Validation Strategy
              //
              // Breakage Scenario 7.1: This test will be broken by messing with the objects' tooltips.
              //
              // DANGEROUS: Uncomment this (and comment the actual value below) to break the risk maps tooltip feature intentionally
              // <span>TPP-XX - This is an intentional bug</span>
            }
            <span className="risk-map-tooltip-value">{UIUtils.getRecordLabelForDisplay(node.type, node.id, node.name)}</span>
          </td>
        </tr>
        {
          unitOperation ?
            <tr id="riskMapReportTooltipUO">
              <td>
                <span className="risk-map-tooltip-header">UnitOp:</span>
              </td>
              <td className="risk-map-tooltip-header-value-cell">
              <span
                className="risk-map-tooltip-value"
              >{UIUtils.getRecordLabelForDisplay("UO", unitOperation.unitOperationId, unitOperation.name)}</span>
              </td>
            </tr>
            : null
        }
        {
          step ?
            <tr id="riskMapReportTooltipSTP">
              <td>
                <span className="risk-map-tooltip-header">Step:</span>
              </td>
              <td className="risk-map-tooltip-header-value-cell">
              <span
                className="risk-map-tooltip-value"
              >{UIUtils.getRecordLabelForDisplay("STP", step.stepId, step.name)}</span>
              </td>
            </tr>
            : null
        }
        {
          node.type !== "TPP" && node.type !== "GA" ?
            (<tr id="riskMapReportTooltipRawRiskScore">
              <td>
                <span className="risk-map-tooltip-header">{riskType + ":"}</span>
              </td>
              <td className="risk-map-tooltip-header-value-cell">
              <span className="risk-map-tooltip-value">{
                (riskType === RISK_TYPE_ENUM.CRITICALITY ||
                  riskType === RISK_TYPE_ENUM.PROCESS_RISK ||
                  riskType === RISK_TYPE_ENUM.RPN) ? riskScores.rawRiskScore :
                  getRiskLabel(riskType, effectiveRMPForModelType, riskScores.rawRiskScore, node)}
              </span>
              </td>
            </tr>)
            : null
        }
        {
          node.type !== "TPP" && node.type !== "GA" &&
          ((riskType === RISK_TYPE_ENUM.CRITICALITY) ||
            (riskType === RISK_TYPE_ENUM.PROCESS_RISK) ||
            (riskType === RISK_TYPE_ENUM.RPN)) ?
            (<tr id="riskMapReportTooltipNormalizedRiskScore">
              <td>
                <span className="risk-map-tooltip-header">{riskType + " (%):"}</span>
              </td>
              <td className="risk-map-tooltip-header-value-cell">
              <span className="risk-map-tooltip-value">
                {getRiskLabel(riskType, effectiveRMPForModelType, riskScores.rawRiskScore, node, showByRiskLabel(filters, riskType))}
              </span>
              </td>
            </tr>)
            : null
        }
        {
          (node.parent)
            ? (
              <tr id="riskMapReportTooltipParent">
                <td>
                  <span className="risk-map-tooltip-header">{node.parent.typeName}:</span>
                </td>
                <td className="risk-map-tooltip-header-value-cell">
                  <span className="risk-map-tooltip-value">{node.parent ? UIUtils.getRecordLabelForDisplay(node.parent.type, node.parent.id, node.parent.name) : null}</span>
                </td>
              </tr>
            )
            : null
        }
      </>
    );
  }

  buildProcessCapabilityTooltip() {
    const {
      filters,
      node
    } = this;

    return node.processCapability ? (
      <>
        <tr id="riskMapReportTooltipPCHeader">
          <td colSpan={2}>
            <span className="risk-map-tooltip-header">Process Capability</span>
          </td>
        </tr>
        <tr id="riskMapReportTooltipPCValue">
            <td colSpan={2}>
              <div className="risk-map-tooltip-cs-description">{node.processCapability.source} <span style={{color: node.processCapability.label === "Poor" ? "#ffffff": "#434952",
                                                                             paddingLeft: "5px",
                                                                             paddingRight: "5px",
                                                                             backgroundColor: node.processCapability.color}}>{node.processCapability.value}</span> {node.processCapability.label}</div>
            </td>
        </tr>
      </>) : ""
  }

  buildControlStrategyTooltip() {
    const {
      filters,
      node,
      selectedTypeaheadOptions,
    } = this;

    const controlStrategies = getControlStrategiesToShowForNode({
      node,
      filters,
      nodeHighlightedInRiskMap: this.nodeHighlightedInRiskMap,
      selectedTypeaheadOptions,
      isForTooltip: true,
    });
    let counter = 1;

    // Only show control strategy information
    return controlStrategies.length > 0 ? (
      <>
        <tr id="riskMapReportTooltipDivider">
          <td colSpan={2}>
            <div>
              <hr className="solid" />
            </div>
          </td>
        </tr>
        <tr id={`riskMapReportTooltipCSRow`}>
          <td colSpan={2}>
            <table>
              <tr id="riskMapReportTooltipCSHeader">
                <td colSpan={2}>
                  <span className="risk-map-tooltip-header">Control Strategy</span>
                </td>
              </tr>
              {
                controlStrategies.map(controlStrategy => {
                  return (
                    <tr id={`riskMapReportTooltipCSRow_${counter}`}
                        key={counter}
                    >
                      <td colSpan={2}>
                        <div className="risk-map-tooltip-cs-header">{controlStrategy}</div>
                        <div className="risk-map-tooltip-cs-description">{CONTROL_STRATEGY_DESCRIPTION[controlStrategy]}</div>
                      </td>
                    </tr>
                  );
                })
              }
            </table>
          </td>
        </tr>
      </>
    ) : "";
  }
}
