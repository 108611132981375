"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import { RISK_TYPE_ENUM } from "./constants/constants";
import Decimal from "decimal.js";
import FullObjectCache from "../utils/cache/full_object_cache";
import RiskUtils from "../../server/common/misc/common_risk_utils";
import CommonUtils from "../../server/common/generic/common_utils";
import TypeaheadObjectCache from "../utils/cache/typeahead_object_cache";
import { TOOLTIP_HEADERS as TECH_TRANSFER_TOOLTIP_HEADERS } from "../techTransfer/tech_transfer_constants";
import { RISK_COLORS } from "../rmps/constants/rmp_constants";
import MemoryCache from "../utils/cache/memory_cache";

const {Log, LOG_GROUP} = require("../../server/common/logger/common_log");

const Logger = Log.group(LOG_GROUP.Editables, "RiskHelper");

export const TOOLTIP_HEADERS = {
  IMPACT: "Severity of impact of an attribute or process parameter not meeting the acceptance criteria on selected FQA or IQA.",
  UNCERTAINTY: "Uncertainty about or Likelihood of the impact occurring if the attribute or process parameter does not meet the acceptance criteria. That is, the probability of an out-of-specification event leading to the defined impact (ISO 14971:2019).",
  CAPABILITY_RISK: "Risk that process capability is not within defined limits as defined by the acceptance criteria. Also often referred to as Occurrence. Can also be interpreted as the probability that the attribute or parameter is out of specification.",
  DETECTABILITY_RISK: "Risk of not detecting failure or out-of-specification (not meeting acceptance criteria) before it impacts patient safety or efficacy.",
  CRITICALITY: "Identification of the criticality score in table highlighting the resulting criticality raw score.",
  CRITICALITY_PERCENTAGE: "Identification of criticality score in table highlighting resulting criticality score as a percentage of the maximum.",
  PROCESS_RISK: "The product of the Criticality and the Capability Risk (Occurrence). Follows ISO 14971:2019 risk definition.",
  RPN: "RPN is the Risk Priority Number and calculated as the product of the Process Risk and the Detectability Risk.",
};

/**
 * This returns the effective risk scale for a record or record version and risk type.
 * @param project Project containing all versions
 * @param approvedRMPVersions
 * @param record The record to find the effective risk scale for
 * @param typeCode The record typeCode
 * @param riskType The risk type to find the risk scale for
 * @param forceCalculationThroughRiskLinks Forces risk calculation through risk links even if object has its own impact/uncertainty
 */
export function getRiskScaleForEffectiveRMP(project, approvedRMPVersions, record, typeCode, riskType, forceCalculationThroughRiskLinks = false) {
  const effectiveRMP = getEffectiveRMPByModelName(project, approvedRMPVersions, record, typeCode);
  if (!effectiveRMP) {
    return null;
  }

  const rawRiskScore = getRawRiskScore(riskType, effectiveRMP, record, forceCalculationThroughRiskLinks);
  return getRiskScale(riskType, effectiveRMP, rawRiskScore, record, true);
}

/**
 * Returns back key-value pair options to be used in drop downs for risk fields including impact, uncertainty,
 * capability risk and detectability risk
 * @param rmp The RMP holding risk information
 * @param field The field to retrieve the options for
 */
export function getRiskFieldOptions(rmp, field) {
  // Check to make sure the RMP is loaded.
  if (!rmp) {
    return null;
  }

  return rmp[field].map(riskField => {
    return {
      key: riskField.riskScore,
      value: riskField.riskScore + ". " + riskField.scoreLabel,
    };
  }).sort(UIUtils.sortBy("key"));
}

/**
 * Returns a risk field tooltip which is used in various UI controls explaining impact, uncertainty,
 * capability risk and detectability risk fields
 * @param {string} riskType - The field to return a tooltip for
 * @param {object} rmp - The RMP used for creating the risk field tooltip on the fly
 * @param {boolean} [isTechTransfer] - Determine if the function called inside Tech Transfer section
 * @returns {React.ReactNode} ReactNode that has the proper UI of the tooltip
 */
export function getRiskFieldTooltip(riskType, rmp, isTechTransfer = false) {
  // Check to make sure the RMP is loaded.
  if (!rmp) {
    return null;
  }

  let riskTypeKey = Object.keys(RISK_TYPE_ENUM).find(key => RISK_TYPE_ENUM[key] === riskType);
  const riskScales = sortRiskScales(getRiskScales(riskType, rmp) || []);

  return (
    <div>
      {isTechTransfer ? TECH_TRANSFER_TOOLTIP_HEADERS[riskTypeKey] : TOOLTIP_HEADERS[riskTypeKey]}
      <ul>
        {
          (riskScales.map(risk =>
            <li key={risk.id}>
              <b>{risk.riskScore + ". " + risk.scoreLabel}</b>{" - " + risk.description}
            </li>,
          ))
        }
      </ul>
    </div>
  );
}

/**
 * Returns a risk scale field tooltip which is used in various UI controls explaining criticality,
 * process risk and RPN fields
 * @param {RISK_TYPE_ENUM} riskType The type of risk to return a tooltip for. Can be any of RISK_TYPE_ENUM
 * @param rmp The RMP used for creating the risk scale tooltip on the fly
 * @param object The object to get the row risk values from and display the needed calculations
 * @param {boolean} [showRiskScales] When set to true, the risk scales will be included in the tooltip, otherwise not
 * @param {boolean} [showExample] If true an example will be displayed above the calculation formula. This is used in the tooltips
 * on the risk links criticality column, where more than one link can be specified.
 * @param {boolean} [showRawCalculation] If set to true, the tooltip will contain the formula for the raw calculation of the risk score
 * to include the aggregation in the calculation formula.
 * @param {boolean} [isTechTransfer] - Determine if the function called inside Tech Transfer section
 */
export function getRiskScaleTooltip(riskType, rmp, object, showRiskScales, showExample, showRawCalculation, isTechTransfer) {
  // Check to make sure the RMP is loaded.
  if (!rmp) {
    return null;
  }

  const hasNotAssessed = hasNotAssessedRiskScale(riskType, rmp);
  let riskTypeKey = Object.keys(RISK_TYPE_ENUM).find(key => RISK_TYPE_ENUM[key] === riskType);

  const riskScales = sortRiskScales(getRiskScales(riskType, rmp) || []);
  let tooltip = "";
  if (isTechTransfer) {
    tooltip = TECH_TRANSFER_TOOLTIP_HEADERS[riskTypeKey];
  } else {
    let tooltipKey = riskTypeKey;
    if ((riskType === RISK_TYPE_ENUM.CRITICALITY) && showRiskScales) {
      //We don't have a risk type enum for CRITICALITY with percentage,
      //so we have to do the check manually using the above condition and making sure showRiskScales is true
      //TODO: refactor this while we refactor this long helper
      //https://cherrycircle.atlassian.net/browse/QI-7068 
      //Can we introduce an new  RISK_TYPE_ENUM.CRITICALITY_PERCENTAGE?
      tooltipKey += "_PERCENTAGE";
    }
    tooltip = TOOLTIP_HEADERS[tooltipKey];
  }
  return (
    <div>
      {tooltip}
      {showRiskScales ? (
        <ul>
          {
            (riskScales.map(riskScale => {
              if (RiskUtils.scaleIsNotAssessed(riskScale) && hasNotAssessed) {
                return (<li key={riskScale.id}>
                  <b>{riskScale.from + "%"}</b>{" - " + riskScale.scoreLabel}
                </li>);
              } else {
                return (<li key={riskScale.id}>
                  <b>{"From " + riskScale.from + "% to " + riskScale.to + "%"}</b>{" - " + riskScale.scoreLabel}
                </li>);
              }
            }))
          }
        </ul>) : ""}
      {showExample ? getExampleTooltipCalculationLegend(riskType, rmp, object) : ""}
      {showRawCalculation ? getCalculationFormulaTooltip(riskType, rmp, object, true, false) : ""}
    </div>
  );
}

/**
 * Returns back the div element that can be used in tooltips for displaying the calculation formula of a given risk type
 * @param riskType The risk type to return the calculation formula for
 * @param rmp The rmp holding rmp related information
 * @param object The object to get the raw risk values from and make the needed calculations
 * @param showRawCalculation If set to true the tooltip will contain the formula for the raw calculation
 * @param showPercentage If set to true the tooltip will contain the formula for the percentage calculation
 * @param aggregation When set to Max or Total the tooltip on the pages that support risk links is transformed
 * @param forceCalculationThroughRiskLinks Forces risk calculation through risk links even if object has its own impact/uncertainty
 * to include the aggregation in the calculation formula.
 */
export function getCalculationFormulaTooltip(riskType, rmp, object, showRawCalculation, showPercentage, aggregation, forceCalculationThroughRiskLinks = false) {
  // Check to make sure the RMP is loaded.
  if (!rmp) {
    return null;
  }

  let counter = 0;
  if (!object || (!object.impact && !object.uncertainty && !object.obligatoryCQA && (getRiskLinks(object).length === 0))) {
    return (<div />);
  }

  if (!rmp.boundaries) {
    rmp.boundaries = getRiskBoundaries(rmp);
  }

  const hasNotAssessed = hasNotAssessedRiskScale(riskType, rmp);

  let riskLinks;
  switch (riskType) {
    case RISK_TYPE_ENUM.CRITICALITY:
      riskLinks = getRiskLinks(object);
      return (<div>
        {
          showRawCalculation &&
          <div>
            <table className="risk-calculation-tooltip-table">
              <tr>
                <td className="risk-calculation-tooltip-cell">
                  Criticality
                </td>
                <td>
                  =
                </td>
                <td className="risk-calculation-tooltip-cell">
                  Impact
                </td>
                <td>
                  X
                </td>
                <td className="risk-calculation-tooltip-cell">
                  Uncertainty
                </td>
              </tr>
              {aggregation !== "Sum" ?
                <tr>
                  <td />
                  <td>
                    =
                  </td>
                  <td className="risk-calculation-tooltip-cell">
                    {getImpact(rmp, object, forceCalculationThroughRiskLinks) || (hasNotAssessed ? "0" : "")}
                  </td>
                  <td>
                    X
                  </td>
                  <td className="risk-calculation-tooltip-cell">
                    {getUncertainty(rmp, object, forceCalculationThroughRiskLinks)}
                  </td>
                </tr> :
                riskLinks.map(riskLink => {
                  return (
                    <tr key={counter++}>
                      <td />
                      <td>
                        {counter === 1 ? "=" : ""}
                      </td>
                      <td />
                      <td className="risk-calculation-tooltip-cell">
                        {getImpact(rmp, riskLink) || (hasNotAssessed ? "0" : "")}
                      </td>
                      <td>
                        X
                      </td>
                      <td className="risk-calculation-tooltip-cell">
                        {getUncertainty(rmp, riskLink)}
                      </td>
                      <td>
                        {counter < riskLinks.length ? "+" : ""}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <td />
                <td>
                  =
                </td>
                <td className="risk-calculation-tooltip-cell">
                  {aggregation !== "Sum" && getCriticality(rmp, object, forceCalculationThroughRiskLinks)}
                </td>
              </tr>
            </table>
            {getCriticalityRiskLabelTooltip(object.riskInfo)}
          </div>
        }
        {showPercentage && aggregation !== "Sum" ?
          <table className="risk-calculation-tooltip-table">
            <tr>
              <td className="risk-calculation-tooltip-cell">
                Criticality (%)
              </td>
              <td>
                =
              </td>
              <td className="risk-calculation-tooltip-cell">
                Criticality
              </td>
              <td>
                /
              </td>
              <td className="risk-calculation-tooltip-cell">
                RMP Max Criticality
              </td>
            </tr>
            <tr>
              <td />
              <td>
                =
              </td>
              <td className="risk-calculation-tooltip-cell">
                {getCriticality(rmp, object, forceCalculationThroughRiskLinks)}
              </td>
              <td>
                /
              </td>
              <td className="risk-calculation-tooltip-cell">
                {rmp.boundaries.maxCriticality}
              </td>
            </tr>
            <tr>
              <td />
              <td>
                =
              </td>
              <td className="risk-calculation-tooltip-cell">
                {getNormalizedRiskScore(riskType, rmp, getCriticality(rmp, object, forceCalculationThroughRiskLinks)) + "%"}
              </td>
            </tr>
          </table>
          : ""}
      </div>);
    case RISK_TYPE_ENUM.PROCESS_RISK:
      return (<div>
        {showRawCalculation ?
          <table className="risk-calculation-tooltip-table">
            <tr>
              <td className="risk-calculation-tooltip-cell">
                Process Risk
              </td>
              <td>
                =
              </td>
              {aggregation ? (
                <td className="risk-calculation-tooltip-text">
                  {aggregation} (
                </td>) : ""}
              <td className="risk-calculation-tooltip-cell">
                Impact
              </td>
              <td>
                X
              </td>
              <td className="risk-calculation-tooltip-cell">
                Uncertainty
              </td>
              {aggregation ? (
                <td>
                  )
                </td>) : ""}
              <td>
                X
              </td>
              <td className="risk-calculation-tooltip-cell">
                Capability
              </td>
            </tr>
            <tr>
              <td />
              <td>
                =
              </td>
              {aggregation ? (
                <td>
                </td>) : ""}
              <td className="risk-calculation-tooltip-cell">
                {getImpact(rmp, object, forceCalculationThroughRiskLinks) || (hasNotAssessed ? "0" : "")}
              </td>
              <td>
                X
              </td>
              <td className="risk-calculation-tooltip-cell">
                {getUncertainty(rmp, object, forceCalculationThroughRiskLinks)}
              </td>
              {aggregation ? (
                <td>
                </td>) : ""}
              <td>
                X
              </td>
              <td className="risk-calculation-tooltip-cell">
                {object.capabilityRisk}
              </td>
            </tr>
            <tr>
              <td />
              <td>
                =
              </td>
              {aggregation ? (
                <td>
                </td>) : ""}
              <td className="risk-calculation-tooltip-cell">
                {getProcessRisk(rmp, object, forceCalculationThroughRiskLinks)}
              </td>
            </tr>
          </table> : ""}
        {showPercentage ?
          <table className="risk-calculation-tooltip-table">
            <tr>
              <td className="risk-calculation-tooltip-cell">
                Process Risk (%)
              </td>
              <td>
                =
              </td>
              <td className="risk-calculation-tooltip-cell">
                Process Risk
              </td>
              <td>
                /
              </td>
              <td className="risk-calculation-tooltip-cell">
                Max Process Risk
              </td>
            </tr>
            <tr>
              <td />
              <td>
                =
              </td>
              <td className="risk-calculation-tooltip-cell">
                {getProcessRisk(rmp, object, forceCalculationThroughRiskLinks)}
              </td>
              <td>
                /
              </td>
              <td className="risk-calculation-tooltip-cell">
                {rmp.boundaries.maxProcessRisk}
              </td>
            </tr>
            <tr>
              <td />
              <td>
                =
              </td>
              <td className="risk-calculation-tooltip-cell">
                {getNormalizedRiskScore(riskType, rmp, getProcessRisk(rmp, object, forceCalculationThroughRiskLinks)) + "%"}
              </td>
            </tr>
          </table> : ""}
      </div>);
    case RISK_TYPE_ENUM.RPN:
      return (<div>
        {showRawCalculation ?
          <table className="risk-calculation-tooltip-table">
            <tr>
              <td className="risk-calculation-tooltip-cell">
                RPN
              </td>
              <td>
                =
              </td>
              {aggregation ? (
                <td className="risk-calculation-tooltip-text">
                  {aggregation} (
                </td>) : ""}
              <td className="risk-calculation-tooltip-cell">
                Impact
              </td>
              <td>
                X
              </td>
              <td className="risk-calculation-tooltip-cell">
                Uncertainty
              </td>
              {aggregation ? (
                <td>
                  )
                </td>) : ""}
              <td>
                X
              </td>
              <td className="risk-calculation-tooltip-cell">
                Capability
              </td>
              <td>
                X
              </td>
              <td className="risk-calculation-tooltip-cell">
                Detectability
              </td>
            </tr>
            <tr>
              <td />
              <td>
                =
              </td>
              {aggregation ? (
                <td>
                </td>) : ""}
              <td className="risk-calculation-tooltip-cell">
                {getImpact(rmp, object, forceCalculationThroughRiskLinks) || (hasNotAssessed ? "0" : "")}
              </td>
              <td>
                X
              </td>
              <td className="risk-calculation-tooltip-cell">
                {getUncertainty(rmp, object, forceCalculationThroughRiskLinks)}
              </td>
              {aggregation ? (
                <td>
                </td>) : ""}
              <td>
                X
              </td>
              <td className="risk-calculation-tooltip-cell">
                {object.capabilityRisk}
              </td>
              <td>
                X
              </td>
              <td className="risk-calculation-tooltip-cell">
                {object.detectabilityRisk}
              </td>
            </tr>
            <tr>
              <td />
              <td>
                =
              </td>
              {aggregation ? (
                <td>
                </td>) : ""}
              <td className="risk-calculation-tooltip-cell">
                {getRPN(rmp, object, forceCalculationThroughRiskLinks)}
              </td>
            </tr>
          </table> : ""}
        {showPercentage ?
          <table className="risk-calculation-tooltip-table">
            <tr>
              <td className="risk-calculation-tooltip-cell">
                RPN (%)
              </td>
              <td>
                =
              </td>
              <td className="risk-calculation-tooltip-cell">
                RPN
              </td>
              <td>
                /
              </td>
              <td className="risk-calculation-tooltip-cell">
                Max RPN
              </td>
            </tr>
            <tr>
              <td />
              <td>
                =
              </td>
              <td className="risk-calculation-tooltip-cell">
                {getRPN(rmp, object, forceCalculationThroughRiskLinks)}
              </td>
              <td>
                /
              </td>
              <td className="risk-calculation-tooltip-cell">
                {rmp.boundaries.maxRPN}
              </td>
            </tr>
            <tr>
              <td />
              <td>
                =
              </td>
              <td className="risk-calculation-tooltip-cell">
                {getNormalizedRiskScore(riskType, rmp, getRPN(rmp, object, forceCalculationThroughRiskLinks)) + "%"}
              </td>
            </tr>
          </table> : ""}
      </div>);
  }
}

/**
 * Helper function that renders an example legend for the risk links criticality column.
 * @param riskType The risk type used for the calculation
 * @param rmp The RPM of the project
 * @param object The object which holds all risk related information
 */
function getExampleTooltipCalculationLegend(riskType, rmp, object) {
  if (!object || !rmp) {
    return (<div />);
  }

  let riskLinks = getRiskLinks(object);
  return riskLinks.length > 0 ? (
    <div>
      {"Example calculation for: " + getMaxCriticalityObjectName(riskLinks)}
    </div>
  ) : "";
}

/**
 * Helper function that retrieves the name of the link in the risk links table that corresponds to the max criticality of the table
 * @param riskLinks The risk links of the risk links table
 */
function getMaxCriticalityObjectName(riskLinks) {
  let maxCriticality = null;
  let objectName = null;

  for (let riskLink of riskLinks) {
    let criticality = getCriticality(null, riskLink);
    if (!maxCriticality || (maxCriticality < criticality)) {
      maxCriticality = criticality;
      objectName = riskLink.name;
    }
  }

  return objectName;
}

/**
 * Gets the permutations for a risk scale. Those are the total permutations of the risk dimensions the risk
 * scale depends on. For criticality for example that would be the total permutations of impact and uncertainty.
 * For process risk that would be the permutations of Impact, Uncertainty and Capability Risk.
 * @param rmp The RMP
 * @param riskType The risk type (Criticality, Process or RPN)
 */
export function getPermutationsForRiskType(riskType, rmp) {
  // Check to make sure the RMP is loaded.
  if (!rmp) {
    return null;
  }

  let riskScores1 = [];
  let riskScores2 = [];

  switch (riskType) {
    case RISK_TYPE_ENUM.IMPACT:
    case RISK_TYPE_ENUM.UNCERTAINTY:
    case RISK_TYPE_ENUM.DETECTABILITY_RISK:
    case RISK_TYPE_ENUM.CAPABILITY_RISK:
      return (getSortedRiskScales(riskType, rmp) || []).map(riskScale => riskScale.riskScore);
    case RISK_TYPE_ENUM.CRITICALITY:
      riskScores1 = getPermutationsForRiskType(RISK_TYPE_ENUM.IMPACT, rmp);
      riskScores2 = getPermutationsForRiskType(RISK_TYPE_ENUM.UNCERTAINTY, rmp);
      break;
    case RISK_TYPE_ENUM.PROCESS_RISK:
      riskScores1 = getPermutationsForRiskType(RISK_TYPE_ENUM.CRITICALITY, rmp);
      riskScores2 = getPermutationsForRiskType(RISK_TYPE_ENUM.CAPABILITY_RISK, rmp);
      break;
    case RISK_TYPE_ENUM.RPN:
      riskScores1 = getPermutationsForRiskType(RISK_TYPE_ENUM.PROCESS_RISK, rmp);
      riskScores2 = getPermutationsForRiskType(RISK_TYPE_ENUM.DETECTABILITY_RISK, rmp);
      break;
  }

  let permutations = new Set();
  for (let i = 0; i < riskScores1.length; i++) {
    for (let j = 0; j < riskScores2.length; j++) {
      permutations.add(riskScores1[i] * riskScores2[j]);
    }
  }

  return [...permutations].sort((a, b) => a - b);
}

/**
 * Returns risk scales array sorted either by riskScore or to/from risk scale
 * @param riskType The risk type as defined in RISK_TYPE_ENUM
 * @param rmp The RMP schema to get the risk scales from
 * @param sortByModelName include ModelName in sorting array
 * @returns {*}
 */
export function getSortedRiskScales(riskType, rmp, sortByModelName = false) {
  // Check to make sure the RMP is loaded.
  if (!rmp) {
    return null;
  }

  let riskScales = getRiskScales(riskType, rmp) || [];

  let sortByArray = [];
  if (sortByModelName) {
    sortByArray.push({
      name: "modelName",
    });
  }

  if (riskType === RISK_TYPE_ENUM.IMPACT || riskType === RISK_TYPE_ENUM.UNCERTAINTY ||
    riskType === RISK_TYPE_ENUM.CAPABILITY_RISK || riskType === RISK_TYPE_ENUM.DETECTABILITY_RISK) {
    sortByArray.push({
      name: "riskScore",
      primer: UIUtils.parseInt,
    });
  } else {
    sortByArray.push({
      name: "from",
      primer: parseFloat,
    });
    sortByArray.push({
      name: "to",
      primer: parseFloat,
    });
  }
  return riskScales.sort(UIUtils.sortBy(...sortByArray));
}

/**
 * Returns back a risk map filter name used in the Risk map filter panel given a risk scale
 * @param riskScale The risk scale to get the risk filter name for
 * @param includeModelName add modelName to the filter name in case RMP configured by type
 * @param getForRiskLabel return risk label instead of score label
 */
export function getRiskFilterNameFromRiskScale(riskScale, includeModelName = false, getForRiskLabel = false) {
  if (riskScale) {
    return `show${includeModelName ? riskScale.modelName : ""}${UIUtils.secureString(UIUtils.stripAllWhitespaces(getForRiskLabel ? riskScale.riskLabel : riskScale.scoreLabel))}Risk`;
  } else {
    return `showWithUnspecifiedRisk`;
  }
}

/**
 * This finds the closest integer in an array compared to a target value
 * Credits to:https://www.geeksforgeeks.org/find-closest-number-array/
 * @param array
 * @param n
 * @param target
 * @returns {*}
 */
function findClosestIntegerInArray(array, n, target) {
  // Corner cases
  if (target <= array[0]) {
    return array[0];
  } else if (target >= array[n - 1]) {
    return array[n - 1];
  }

  // Doing binary search
  let i = 0, j = n, mid = 0;

  while (i < j) {
    mid = Math.floor((i + j) / 2);

    if (array[mid] === target) {
      return array[mid];
    }

    /* If target is less than the array element,
        then search in left */
    if (target < array[mid]) {

      // If target is greater than previous
      // to mid, return closest of two
      if (mid > 0 && target > array[mid - 1]) {
        return getClosest(array[mid - 1], array[mid], target);
      }

      /* Repeat for left half */
      j = mid;
    } else {
      // If target is greater than mid
      if (mid < n - 1 && target < array[mid + 1]) {
        return getClosest(array[mid], array[mid + 1], target);
      }

      // update i
      i = mid + 1;
    }
  }

  // Return the single element that is left after searching.
  return array[mid];
}

/**
 * Method to find among 2 values which one the target is closer to.
 * We find the closest by taking the absolute difference between the target and both values.
 * @param value1
 * @param value2
 * @param target
 * @returns {*}
 */
function getClosest(value1, value2, target) {
  return Math.abs(target - value1) >= Math.abs(target - value2) ? value2 : value1;
}

/**
 * Calculates the raw risk score given a normalized risk score, the risk type and the RPN configuration. The raw risk
 * score returned is the one calculated by the reverse formula of the getNormalizedRiskScore method which is then rounded
 * to the closed risk value in all possible risk score permutations for the risk type.
 * @param riskType The risk type to calculate the risk score for
 * @param rmp The RMP configuration
 * @param normalizedRiskScore the normalized risk score
 * @param riskScorePermutations The permutations of all possible risk scores. If this is not provider, then this
 * method will calculate it, but beware! This is processing demanding if you have a big RMP configuration.
 * @returns {null|number|*}
 */
export function getRawRiskScoreFromNormalzedRiskScore(riskType, rmp, normalizedRiskScore, riskScorePermutations) {
  // Check to make sure the RMP is loaded.
  if (!rmp) {
    return null;
  }

  let maxValue;

  if (!riskScorePermutations) {
    riskScorePermutations = getPermutationsForRiskType(riskType, rmp);
  }

  if (riskType === RISK_TYPE_ENUM.CRITICALITY) {
    maxValue = rmp.boundaries.maxCriticality;
  } else if (riskType === RISK_TYPE_ENUM.PROCESS_RISK) {
    maxValue = rmp.boundaries.maxProcessRisk;
  } else if (riskType === RISK_TYPE_ENUM.RPN) {
    maxValue = rmp.boundaries.maxRPN;
  } else {
    return normalizedRiskScore;
  }

  if (maxValue && normalizedRiskScore && normalizedRiskScore > 0) {
    let rawDecimalScore = Decimal.div(Decimal.mul(maxValue, normalizedRiskScore), 100).toNumber();
    return findClosestIntegerInArray(riskScorePermutations, riskScorePermutations.length, rawDecimalScore);
  } else {
    return null;
  }
}

/**
 * This will return back the average criticality from a collection of risk links
 * @param riskLinks The risk links array to get the average criticality from
 * @returns riskLinks or null
 */
export function getAverageCriticality(riskLinks) {
  let averageCriticality = null;

  for (let riskLink of riskLinks) {
    let criticality = getCriticality(null, riskLink);
    averageCriticality = (averageCriticality ? averageCriticality : 0) + criticality;
  }
  if (averageCriticality && (riskLinks.length > 0)) {
    averageCriticality = (averageCriticality / riskLinks.length).toFixed(2);
  }

  return averageCriticality;
}

/**
 * This will get the correct css class to attach to a table cell so that its background is rendered based on the
 * criticality score it represents.
 * @param riskType The risk type being shown in the table
 * @param rmp The RMP used for the table
 * @param rawRiskScore The raw risk score
 * @param record The record with risk information
 * @param getForRiskLabel Set this to true if you want to get the color for the risk label. This will cause the
 * always critical flag on the impact score to overwrite the color based on the highest criticality score
 * @returns {null|string}
 */
export function getTableCellClassFromRiskScore(riskType, rmp, rawRiskScore, record, getForRiskLabel = false) {
  // Check to make sure the RMP is loaded.
  const recordHasValues = Object.keys(record).filter(key => record[key] !== undefined).length > 0;

  if (!rmp || !recordHasValues) {
    return null;
  }

  let hasNotAssessed = hasNotAssessedRiskScale(riskType, rmp);
  let riskScale = (rawRiskScore || hasNotAssessed) ? getRiskScale(riskType, rmp, rawRiskScore, record, getForRiskLabel) : null;
  let color = riskScale ? riskScale.color : null;

  // Code Purpose: Test Validation Strategy
  //
  // Breakage Scenario 5.1: This test will be broken by providing the risk with the wrong colors.
  //
  // DANGEROUS: Uncomment this to break the risk map colors.
  // if (color === RISK_COLORS.YELLOW) {
  //   return "risk-tables-cell-blue";
  // }

  switch (color) {
    case RISK_COLORS.BLUE:
      return "risk-tables-cell-blue";
    case RISK_COLORS.GREEN:
      return "risk-tables-cell-green";
    case RISK_COLORS.YELLOW:
      return "risk-tables-cell-yellow";
    case RISK_COLORS.ORANGE:
      return "risk-tables-cell-orange";
    case RISK_COLORS.GREY:
      return "risk-tables-cell-grey";
    case RISK_COLORS.RED:
      return "risk-tables-cell-red";
  }
  return null;
}

/**
 * This looks to find the project Id, if it's available from the URL.
 * @param {string|number} [projectId] An optional Project Id.
 * @returns {*|string|int} The project Id if it's available
 */
export function ensureProjectId(projectId) {
  return projectId ? projectId : UIUtils.getParameterByName("projectId");
}

/**
 * Loads and caches the project's RMP
 * @param callback An optional callback function to invoke once the RMP is loaded. The RMP will be passed to the callback
 * as a parameter
 * @param projectId Optionally the project id for which the RMP will be loaded. If not provided this function will try
 * @param date Optionally get the RMP effective at the specified date
 * to get it from the URL.
 */
export function loadRMP(callback, projectId) {
  projectId = ensureProjectId(projectId);
  if (projectId) {
    const memoryCache = MemoryCache.getNamedInstance("loadRMP");
    const cacheKey = "projects";
    const projects = memoryCache.get(cacheKey);
    if (projects) {
      handleReceiveProjectResultsFromServer(callback, projectId, projects);
    } else {
      new TypeaheadObjectCache("Project", projectId).loadOptions().promise().then((projects) => {
        handleReceiveProjectResultsFromServer(callback, projectId, projects);
      });
    }
  }
}

function handleReceiveProjectResultsFromServer(callback, projectId, results) {
  if (results) {
    const memoryCache = MemoryCache.getNamedInstance("loadRMP");
    memoryCache.set("projects", results);
    let project = results.find(project => {
      return project.id === UIUtils.parseInt(projectId);
    });

    if (project) {
      const cacheKey = "project_" + projectId;
      const fullProject = memoryCache.get(cacheKey);
      if (fullProject) {
        handleReceiveProjectWithVersionsResultsFromServer(callback, fullProject).then();
      } else {
        new FullObjectCache("Project", projectId).loadOptions(null, {includeAllVersions: true}).promise().then((project) => {
          handleReceiveProjectWithVersionsResultsFromServer(callback, project).then(() => {
          });
        });
      }
    } else {
      if (callback) {
        callback(null);
      }
    }
  } else {
    Logger.info("RiskHelper :: handleReceiveProjectResultsFromServer :: No result received from TypeaheadObjectCache");
  }
}

async function handleReceiveProjectWithVersionsResultsFromServer(callback, project) {
  if (project) {
    const memoryCache = MemoryCache.getNamedInstance("loadRMP");
    memoryCache.set("project_" + project.id, project);

    const rmpIdsForAllVersions = project?.allVersionsWithDetails?.map(approvedVersion => approvedVersion.RMPId) ?? [];
    const rmpIds = Array.from(new Set([project.RMPId].concat(rmpIdsForAllVersions)))
      .filter(rmpId => rmpId > 0);

    if (rmpIds.length === 0) {
      throw new Error("No RMPs defined on project");
    }

    const loadedRMPs = [];
    for (let i = 0; i < rmpIds.length; i++) {
      const cacheKey = "rmp_" + rmpIds[i];
      let rmp = memoryCache.get(cacheKey);
      if (!rmp) {
        const fullObjectCache = new FullObjectCache("RMP", rmpIds[i]);
        rmp = await fullObjectCache.loadOptions(null, {
          latestApproved: true,
          includeAllApprovedVersions: true,
        }).promise();
        memoryCache.set(cacheKey, rmp);
      }

      if (!rmp) {
        return;
      }

      if (!rmp.configureByType) {
        rmp.boundaries = getRiskBoundaries(rmp);
      }

      loadedRMPs.push(rmp);
    }

    if (callback) {
      // return the RMP from latest approved version of project
      let effectiveRMP = null;
      if (project.LastApprovedVersionId) {
        const lastApprovedProjectVersion = project.allVersionsWithDetails.find(projectVersion => projectVersion.id === project.LastApprovedVersionId);
        if (!lastApprovedProjectVersion) {
          throw new Error("Approved project version not found");
        }

        effectiveRMP = loadedRMPs.find(rmp => rmp.id === lastApprovedProjectVersion.RMPId);
      } else {
        effectiveRMP = loadedRMPs.find(rmp => rmp.id === project.RMPId);
      }

      if (!effectiveRMP) {
        Logger.error(() => "Could not find RMP for RMP Ids:", rmpIds);
        throw new Error("Effective RMP not found");
      }

      // add the rest of the RMP versions to effective RMP (it is needed if the RMP changes from between project versions)
      const otherRMPs = loadedRMPs.filter(rmp => rmp.id !== effectiveRMP.id);
      const otherRMPApprovedPVersions = otherRMPs.reduce((acc, curr) => {
        acc = acc.concat(curr.approvedVersionsWithDetails);
        return acc;
      }, []);

      effectiveRMP.approvedVersionsWithDetails = effectiveRMP.approvedVersionsWithDetails.concat(otherRMPApprovedPVersions);

      callback(effectiveRMP, project);
    }
  } else {
    Logger.info("RiskHelper :: handleReceiveProjectWithVersionsResultsFromServer :: No project was loaded from FullObjectCache");
    if (callback) {
      callback(null);
    }
  }
}

export function getRiskScores(entity, riskType) {
  let riskValues = {
    rawRiskScore: null,
    normalizedRiskScore: null,
  };

  if (entity.type !== "TPP" && entity.type !== "GA") {
    switch (riskType) {
      case RISK_TYPE_ENUM.IMPACT:
        riskValues.rawRiskScore = entity.impact;
        riskValues.normalizedRiskScore = entity.impact;
        break;
      case RISK_TYPE_ENUM.UNCERTAINTY:
        riskValues.rawRiskScore = entity.uncertainty;
        riskValues.normalizedRiskScore = entity.uncertainty;
        break;
      case RISK_TYPE_ENUM.CAPABILITY_RISK:
        riskValues.rawRiskScore = entity.capabilityRisk;
        riskValues.normalizedRiskScore = entity.capabilityRisk;
        break;
      case RISK_TYPE_ENUM.DETECTABILITY_RISK:
        riskValues.rawRiskScore = entity.detectabilityRisk;
        riskValues.normalizedRiskScore = entity.detectabilityRisk;
        break;
      case RISK_TYPE_ENUM.CRITICALITY:
        riskValues.rawRiskScore = entity.rawCriticality;
        riskValues.normalizedRiskScore = entity.criticality;
        break;
      case RISK_TYPE_ENUM.PROCESS_RISK:
        riskValues.rawRiskScore = entity.rawProcessRisk;
        riskValues.normalizedRiskScore = entity.processRisk;
        break;
      case RISK_TYPE_ENUM.RPN:
        riskValues.rawRiskScore = entity.rawRPN;
        riskValues.normalizedRiskScore = entity.RPN;
        break;
    }
  }

  return riskValues;
}

export function hasSupportForRisk(instanceTypeCode) {
  return ["FPA", "FQA", "PP", "MA", "IQA", "IPA"].includes(instanceTypeCode);
}

/**
 * This returns true when the criticality scale of the attribute is critical or the impact score for the attribute is
 * marked as always critical.
 * @param attribute The attribute to check if it should be considered critical.
 * @param effectiveRMP The effective RMP. This is supposed to be effective RMP given some date. CAUTION!!! You are
 * expected to have found the appropriate RMP version for the date you are interested in, before passing it in this faction.
 * @param modelName
 * @returns {boolean|*}
 */
export function isCritical(attribute, effectiveRMP, modelName) {
  if (!attribute.riskInfo) {
    throw new Error("Missing RiskInfo");
  }

  return attribute.riskInfo[RISK_TYPE_ENUM.CRITICALITY].isCritical;
}

export const getRiskBoundaries = RiskUtils.getRiskBoundaries;
export const getEffectiveRMPByModelName = (project, rmpVersions, instanceOrInstanceVersion, modelName, useCurrentDate = false) => {
  return RiskUtils.getEffectiveRMPByModelName(project, rmpVersions, instanceOrInstanceVersion, modelName, useCurrentDate);
};

export const getEffectiveRMPForDate = (project, rmpVersions, date, modelName) => {
  return RiskUtils.getEffectiveRMPForDate(project, rmpVersions, date, modelName);
};
export const filterRMPByType = RiskUtils.filterRMPByType;

export const getRiskLabel = RiskUtils.getRiskLabel;
export const hasNotAssessedRiskScale = RiskUtils.hasNotAssessedRiskScale;
export const getNormalizedRiskScore = RiskUtils.getNormalizedRiskScore;
export const getMaxScoreLength = RiskUtils.getMaxScoreLength;
export const getRiskScales = RiskUtils.getRiskScales;
export const getRiskScale = RiskUtils.getRiskScale;
export const sortRiskScales = RiskUtils.sortRiskScales;
export const getRawRiskScore = RiskUtils.getRawRiskScore;
export const getImpact = RiskUtils.getImpact;
export const getUncertainty = RiskUtils.getUncertainty;
export const getCriticality = RiskUtils.getCriticality;
export const getRiskLinks = RiskUtils.getRiskLinks;
export const getProcessRisk = RiskUtils.getProcessRisk;
export const getRPN = RiskUtils.getRPN;
export const getMaxCriticality = RiskUtils.getMaxCriticality;
export const getFilteredRMPForType = RiskUtils.getFilteredRMPForType;

export const loadRiskInfoForRecordMap = function(recordMap, RMP, project) {
  if (!RMP) {
    Logger.error("Failure computing risk information on all nodes. Effective RMP is missing");
    return;
  }

  if (!project) {
    Logger.error("Failure computing risk information on all nodes. Project information is missing");
    return;
  }

  const modelsWithRiskLinks = RiskUtils.getModelsWithRiskLinks();
  for (let modelName of modelsWithRiskLinks) {
    const instanceMap = recordMap[modelName.toLowerCase() + "Map"];
    for (let instanceId in instanceMap) {
      const instance = instanceMap[instanceId];

      loadRiskInfoForInstance(instance, modelName, recordMap, RMP, project);
    }
  }
};

function loadRiskInfoForInstance(instance, modelName, recordMap, RMP, project) {
  if (instance.riskInfo) {
    // already calculated, ignore
    return;
  }

  const riskAssociations = CommonUtils.getOneToManySourceAssociations(modelName);
  const effectiveRMP = RiskUtils.getEffectiveRMPByModelName(project, RMP.approvedVersionsWithDetails, instance, modelName);
  if (!effectiveRMP) {
    Logger.warn("Cannot detect effective RMP for instance", Log.object(instance));
    return;
  }

  for (let association of riskAssociations) {
    if (!instance[association]) {
      continue;
    }

    const associationModelName = association.endsWith("s") ? association.substring(0, association.length - 1) : association;
    const associationIdColumn = RiskUtils.getOneToManyAssociationsIdColumn(associationModelName);
    const riskModelInfo = RiskUtils.RISK_MODELS.find(modelInfo => modelInfo.model.toUpperCase() === associationModelName.toUpperCase() || modelInfo.modelAlias?.toUpperCase() === associationModelName.toUpperCase());
    if (!riskModelInfo) {
      throw Error("Cannot find risk model for " + associationModelName);
    }


    let riskModelInfoName = riskModelInfo.to;
    const childInstanceMap = recordMap[riskModelInfoName.toLowerCase() + "Map"];

    const linkedRecords = instance[association].map(relatedInfo => childInstanceMap[relatedInfo[associationIdColumn]])
      .filter(childInstance => childInstance)
      .map(record => {
        loadRiskInfoForInstance(record, riskModelInfoName, recordMap, RMP, project);
        return record;
      });
    if (linkedRecords.length > 0) {
      for (let childInstance of instance[association]) {
        childInstance.targetRecord = linkedRecords.find(record => record.id === childInstance[associationIdColumn]);
      }
    }
  }

  instance.riskInfo = RiskUtils.getRiskInformation(instance, effectiveRMP, false);
  instance.effectiveRMP = effectiveRMP;
}

export const loadRiskInfoForRecordMapVersion = function(recordMap, RMP, project) {
  if (!RMP) {
    Logger.error("Failure computing risk information on all nodes. Effective RMP is missing");
    return;
  }

  if (!project) {
    Logger.error("Failure computing risk information on all nodes. Project information is missing");
    return;
  }

  const modelsWithRiskLinks = RiskUtils.getModelsWithRiskLinks();
  let parsedRecordMap = [];
  // convert to map if needed
  for (let modelName of modelsWithRiskLinks) {
    let modelNameForMap = modelName;
    if (modelNameForMap === "ProcessParameter") {
      modelNameForMap = "PP";
    }

    if (modelNameForMap === "MaterialAttribute") {
      modelNameForMap = "MA";
    }

    let instanceMap = recordMap[modelNameForMap.toLowerCase() + "s"];
    if (!instanceMap) {
      throw new Error("Cannot find instance in map for model " + modelNameForMap);
    }

    if (Array.isArray(instanceMap)) {
      const idColumn = modelName + "Id";
      const newInstanceMap = instanceMap.reduce((map, obj) => {
        if (!obj[idColumn]) {
          throw new Error("Cannot find property " + idColumn + " on object", obj);
        }
        map[obj[idColumn]] = obj;
        return map;
      }, []);

      parsedRecordMap[modelName.toLowerCase() + "s"] = newInstanceMap;
    } else {
      parsedRecordMap[modelName.toLowerCase() + "s"] = recordMap[modelNameForMap];
    }
  }

  for (let modelName of modelsWithRiskLinks) {
    const instanceMap = parsedRecordMap[modelName.toLowerCase() + "s"];
    if (!instanceMap) {
      throw new Error("Cannot find instance in map for model " + modelName);
    }

    for (let instanceId in instanceMap) {
      const instance = instanceMap[instanceId];

      loadRiskInfoForInstanceVersion(instance, modelName, parsedRecordMap, RMP, project);
    }
  }
};

function loadRiskInfoForInstanceVersion(instance, modelName, recordMap, RMP, project) {
  if (instance.riskInfo) {
    // already calculated, ignore
    return;
  }

  const riskAssociations = CommonUtils.getOneToManySourceAssociations(modelName + "Version");
  const effectiveRMP = RiskUtils.getEffectiveRMPByModelName(project, RMP.approvedVersionsWithDetails, instance, modelName);
  if (!effectiveRMP) {
    Logger.warn("Cannot detect effective RMP for instance", Log.object(instance));
    return;
  }

  for (let association of riskAssociations) {
    if (!instance[association]) {
      continue;
    }

    const associationModelName = association.endsWith("LinkedVersions") ? association.replace("LinkedVersions", "") : association;
    const associationIdColumn = RiskUtils.getOneToManyAssociationsIdColumn(associationModelName);
    const riskModelInfo = RiskUtils.RISK_MODELS.find(modelInfo => modelInfo.model.toUpperCase() === associationModelName.toUpperCase() || modelInfo.modelAlias?.toUpperCase() === associationModelName.toUpperCase());
    if (!riskModelInfo) {
      throw Error("Cannot find risk model for " + associationModelName);
    }

    const childInstanceMap = recordMap[riskModelInfo.to.toLowerCase() + "s"];
    const linkedRecords = instance[association].map(relatedInfo => childInstanceMap[relatedInfo[associationIdColumn]])
      .filter(childInstance => childInstance)
      .map(record => {
        loadRiskInfoForInstanceVersion(record, riskModelInfo.to, recordMap, RMP, project);
        return record;
      });

    if (linkedRecords.length > 0) {
      for (let childInstance of instance[association]) {
        childInstance.targetRecord = linkedRecords.find(record => record[riskModelInfo.to + "Id"] === childInstance[associationIdColumn]);
      }
    }
  }


  instance.riskInfo = RiskUtils.getRiskInformation(instance, effectiveRMP, true);
  instance.effectiveRMP = effectiveRMP;
}

function getRiskLinkWinners(riskInfo) {
  return riskInfo?.[RISK_TYPE_ENUM.CRITICALITY]?.scaleForRiskLabel?.riskLinkWinners?.map(riskLink => riskLink.name);
}

export function getRiskLabelTooltip(riskInfo) {
  const riskLinkWinners = getRiskLinkWinners(riskInfo);
  if (riskLinkWinners?.length) {
    return <div>This risk label is driven by the criticality of {riskLinkWinners.join(", ")}</div>;
  }
}

export function getCriticalityRiskLabelTooltip(riskInfo) {
  const riskLinkWinners = getRiskLinkWinners(riskInfo);
  if (riskLinkWinners?.length) {
    return <div>Criticality is driven by the criticality of {riskLinkWinners.join(", ")}</div>;
  }
}
