"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import ReactDOM from "react-dom";
import BulkProposeStatusIcon from "./bulk_proposal_status_icon";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import BulkOperationTableBase from "../bulk_operation_table_base";

/**
 *  When going through the bulk proposal, this is the table that contains the records need to be proposed.
 */
export default class BulkProposalReviewTable extends BulkOperationTableBase {
  constructor(props) {
    super(props);

    this.id = UIUtils.getParameterByName("id");
  }

  shouldComponentUpdate(nextProps, nextState) {
    let tableData = JSON.stringify(this.props.data.map(row => {
      return {
        id: row.id,
        lastViewedVersion: row.lastViewedVersion
      };
    }));

    let nextTableData = JSON.stringify(nextProps.data.map(row => {
      return {
        id: row.id,
        lastViewedVersion: row.lastViewedVersion
      };
    }));

    return tableData !== nextTableData
      || JSON.stringify(this.props.searchItems) !== JSON.stringify(nextProps.searchItems)
      || (nextProps && nextProps.recordSaved);
  }

  handleRowClick(rowData, cellIndex) {
    if (!this.props.columnsToTriggerQuickPanel
      || this.props.columnsToTriggerQuickPanel.includes(this.getColumns()[cellIndex].title)) {
      super.handleRowClick(rowData, cellIndex, this.updateLastViewed);
    }
  }

  updateLastViewed() {
    //Remove the before unload handler every time a user switches to a new requirement
    this.context.clearPreventNavigation();

    let rowIndex = null;
    let row = this.table.rows((index, data) => {
      const isSelectedRow = data.id === this.props.selectedRequirement.id && data.modelName === this.props.selectedRequirement.modelName;
      if (isSelectedRow) {
        rowIndex = index;
      }
      return isSelectedRow;
    });

    let rowData = row.data()[0];
    if (rowData.lastViewedVersion !== rowData.lastDraftVersion) {
      UIUtils.secureAjaxGET(`editables/${rowData.modelName}/${rowData.id}?trackUserActivityAndReturn=true`, null, false).done(() => {
        if (this.props.onRecordLastViewedVersionUpdated) {
          this.props.onRecordLastViewedVersionUpdated(rowData.modelName, rowData.id, rowData.lastDraftVersion);
        }

        $(`#lastViewed_${rowIndex}`).text(rowData.lastDraftVersion);
      });
    }
  }

  getColumns() {
    let columns = [
      {
        title: "",
        width: 1,
        data: (result) => {
          // this has to be the raw record key
          return `${UIUtils.getTypeCodeForModelName(result.modelName)}-${result.id}`;
        }
      },
      {
        title: "Ready",
        width: 1,
        class: "bulk-propose-status-table-header",
        data: (result) => {
          return !!result.disabled;
        },
      },
      {
        title: "ID",
        width: 50,
        data: (result, type) => {
          // this has to be the raw record key
          let fullKey = UIUtils.getTypeCodeForModelName(result.modelName) + "-" + result.id;
          if (type === "display") {
            // Already filled by the createdCell attribute below
            return "";
          } else if (type === "sort") {
            return UIUtils.getKeyForSorting(fullKey);
          } else {
            // Checkboxes send in type === "type" and break if we use the sorting key above. "Filter" is another type that gets sent in.
            return fullKey;
          }
        },
        createdCell: (td, cellData, rowData) => {
          // eslint-disable-next-line react/no-render-return-value
          return ReactDOM.render((
            <a href="javascript:void(0);" onContextMenu={(e) => e.preventDefault()}>
              {UIUtils.getRecordCustomIdForDisplay(rowData)}
            </a>), td);
        }
      },
      {
        title: "Name",
        width: 300,
        data: (result) => result.name,
        createdCell: (td, cellData, rowData) => {
          // eslint-disable-next-line react/no-render-return-value
          return ReactDOM.render((
            <a href="javascript:void(0);" onContextMenu={(e) => e.preventDefault()}>
              {rowData.name}
            </a>), td);
        }
      },
      {
        title: "Latest Draft",
        width: 100,
        data: (result) => {
          return ReactDOMServer.renderToStaticMarkup(
            result.lastDraftVersion
          );
        }
      },
      {
        title: "Last Viewed",
        width: 100,
        data: () => "",
        createdCell: (td, cellData, rowData, row) => {
          // eslint-disable-next-line react/no-render-return-value
          return ReactDOM.render((
            <span id={`lastViewed_${row}`}>
              {rowData.lastViewedVersion}
            </span>), td);
        }
      },
      {
        title: "Latest Draft Author",
        width: 300,
        data: (result) => {
          let authorName = UIUtils.isSystemUserId(result.createdByUserId) || !result.lastDraftAuthor ? "System User" :
            `${result.lastDraftAuthor.firstName} ${result.lastDraftAuthor.lastName}`;

          return ReactDOMServer.renderToStaticMarkup(
            <span>{authorName}</span>
          );
        }
      },
    ];

    if (this.props.requestedByHomePage) {
      columns.splice(4, 0, {
        title: "Project",
        width: 250,
        data: (result) => {
          if (result.project || result.modelName === "Project") {
            let projectId = result.modelName === "Project" ? result.id : result.project.id;
            let projectName = result.modelName === "Project" ? result.name : result.project.name;

            return ReactDOMServer.renderToStaticMarkup(
              <a href={getURLByTypeCodeAndId("PRJ", "View", projectId)}
                 rel="noopener noreferrer"
                 target="_blank"
              >
                {UIUtils.getRecordLabelForDisplay("PRJ", projectId, projectName)}
              </a>
            );
          }
          return "";
        }
      });
    }

    return columns;
  }

  // noinspection JSMethodCanBeStatic
  getColumnsDefs() {
    return super.getColumnsDefs().concat(
      [
        {
          targets: 1,
          createdCell: (td, cellData, rowData, row, col) => {
            // eslint-disable-next-line react/no-render-return-value
            return ReactDOM.render((
              <BulkProposeStatusIcon id={`${row}_${col}`}
                                     showWarning={!!rowData.disabled}
                                     showAsInformative={rowData.showAsInformative}
                                     warningTooltip={rowData.disabledTooltip}
              />), td);
          },
        },
        {
          className: "results-table-column-id",
          targets: 2
        }
      ]
    );
  }

  onSearchTermChange(searchTerm) {
    this.table.search(searchTerm).draw();
  }

  onSuggestionSelect(suggestion) {
    let key = UIUtils.parseKey(suggestion.label);
    window.open(UIUtils.getSecuredURL(getURLByTypeCodeAndId(key.typeCode, "View", key.id)), "_blank");
  }

  handleSelectedRowsChanged() {
    super.handleSelectedRowsChanged();

    let selectedRows = [];
    let dtSelectedRows = this.getSelectedRows();
    for (let i = 0; i < dtSelectedRows.length; i++) {
      selectedRows.push(this.props.data.find(row => row.id === dtSelectedRows[i].id && row.modelName === dtSelectedRows[i].modelName));
    }

    if (this.props.onSelectedRowsChanged) {
      this.props.onSelectedRowsChanged({
        selectedRows,
      });
    }
  }
}
