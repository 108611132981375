"use strict";

import * as UIUtils from "../../../ui_utils";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import BaseReactComponent from "../../../base_react_component";

const TRAINING_STATUSES = [
  {
    color: "yellow",
    label: "Pending",
    description: "The assigned training is not complete, however, it is not yet due. Due date is derived from either a document's effective date or a 7-day training window.",
  },
  {
    color: "green",
    label: "Completed",
    description: "The assigned training is completed.",
  },
  {
    color: "red",
    label: "Overdue",
    description: "Training is not complete and it is past its due date.",
  },
  {
    color: "white",
    label: "Superseded",
    description: "The user has not trained to this version of the document and a new version is available.",
  },
  {
    color: "grey",
    label: "N/A",
    description: "No training is assigned for this document and user.",
  },
  {
    color: "white",
    label: "Not Tracked",
    description: "Training for this document is not tracked by QbDVision.",
  }
];

/**
 * This is the legend that pops out on the training reports
 */
export default class CannedReportTrainingInfoBox extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    super.componentDidMount();

    $(this.popover).popover({
      content: this.renderPopupContent,
      sanitizeFn: UIUtils.sanitizePopoverData,
    });
  }

  handlePopoverIconClick() {
    $(this.popover).popover("show");
  }

  renderPopupContent() {
    return ReactDOMServer.renderToStaticMarkup(
      <div id="confidenceLevelsIconTooltipDiv"
           className="confidence-level-tooltip"
      >
        <div className="confidence-level-tooltip-header">Training Statuses</div>
        <div>
          {TRAINING_STATUSES.map((status, index) => {
            return (
              <div key={index}
                   className="training-status"
              >
                <div className={`training-status-shape training-status-${status.color}-shape`} />
                <div className="training-status-shape-text">
                  <div className="training-status-shape-label">
                    <span>{status.label}</span>
                  </div>
                  <div className="training-status-shape-description">
                    <span>{status.description}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    return (
      <span id="trainingStatusIcon"
            className="training-status-info-icon"
            data-toggle="popover"
            data-html="true"
            data-trigger="manual"
            data-placement="bottom"
            ref={popover => this.popover = popover}
            onClick={this.handlePopoverIconClick}
      >
        <FontAwesomeIcon icon={faInfoCircle} size="sm" />
      </span>
    );
  }
}
