import { toRound } from './utils';
/**
 * @hidden
 */
export var RATING_ACTION;
(function (RATING_ACTION) {
    RATING_ACTION["select"] = "select";
    RATING_ACTION["deselect"] = "deselect";
    RATING_ACTION["increase"] = "increase";
    RATING_ACTION["decrease"] = "decrease";
    RATING_ACTION["min"] = "min";
    RATING_ACTION["max"] = "max";
    RATING_ACTION["reset"] = "reset";
})(RATING_ACTION || (RATING_ACTION = {}));
/**
 * @hidden
 */
export var ratingReducer = function (state, action) {
    switch (action.type) {
        case RATING_ACTION.select:
            if (action.payload === undefined || action.step === undefined) {
                return state;
            }
            if (action.payload === state) {
                return null;
            }
            return action.payload >= action.min
                ? action.payload < action.max
                    ? toRound(action.payload, action.step)
                    : action.max
                : action.min;
        case RATING_ACTION.deselect:
            return null;
        case RATING_ACTION.increase:
            if (action.step === undefined) {
                return state;
            }
            if (state < action.min) {
                return action.min;
            }
            return state + action.step < action.max
                ? toRound(state + action.step, action.step)
                : action.max;
        case RATING_ACTION.decrease:
            if (action.step === undefined) {
                return state;
            }
            return toRound(state - action.step, action.step) >= action.min
                ? toRound(state - action.step, action.step)
                : action.min;
        case RATING_ACTION.min:
            if (action.step === undefined) {
                return state;
            }
            return action.min;
        case RATING_ACTION.max:
            return action.max;
        case RATING_ACTION.reset:
            return null;
        default:
            return state;
    }
};
