"use strict";

import * as UIUtils from "../ui_utils";
import React from "react";
import InfoTooltip from "../widgets/tooltips/info_tooltip";
import Section from "../editor/widgets/section";
import ComboBoxAttribute from "../editor/attributes/combo_box_attribute";
import TextAttribute from "../editor/attributes/text_attribute";
import TypeaheadAttribute from "../editor/attributes/typeahead_attribute";
import TextAreaAttribute from "../editor/attributes/text_area_attribute";
import BaseQuickEditor from "../editor/base_quick_editor";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import CommonEditables from "../../server/common/editables/common_editables";

// i18next-extract-mark-ns-start controlMethod
class ControlMethod extends BaseQuickEditor {
  constructor(props) {
    super(props, "controlMethod", "ControlMethod", "Control (CM)");
  }

  componentDidMount() {
    document.title = "QbDVision Control Method (CM)";

    super.componentDidMount();
  }

  getInfoTooltip() {
    return (
      <InfoTooltip id="infoCM"
                   fdaGuidanceURL="https://www.fda.gov/media/87801/download"
                   verbiage={<div>
                     A control method or analytical method is used to test a defined characteristic of a substance
                     against established acceptance criteria for that characteristic. The important parameters that
                     may be evaluated during method development are specificity, linearity, limits of detection (LOD)
                     and quantitation limits (LOQ), range, accuracy, and precision.
                   </div>}
      />
    );
  }

  getTabName() {
    return "Control Methods";
  }

  renderAttributes() {
    const {t} = this.props;

    return (
      <div>
        <Section id="controlMethod"
                 parent={this}
                 header={this.getGeneralHeader()}
                 showDocLinks={true}
                 collapsible={false}
        >
          <div className="row">
            <TextAttribute name="name"
                           parent={this}
            />
            <ComboBoxAttribute name="category"
                               options={["Analytical Method",
                                 "Monitoring",
                                 "PAT",
                                 "SOP/Training",
                                 "Facility Class",
                                 "Other"]}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
          </div>
          <div className="row">
            <ComboBoxAttribute name="type"
                               options={["Chemical",
                                 "Biological",
                                 "Microbiological",
                                 "Physico-Chemical",
                                 "Not Applicable"]}
                               default="Chemical"
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
            <TypeaheadAttribute name="compendialStandard"
                                options={CommonEditables.COMPENDIAL_STANDARD}
                                parent={this}
                                isLoading={this.state.isLoading}
                                updatedAt={this.state.updatedAt}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
                                multiple={true}
                                tooltipText={(<div>
                                    <p>
                                      Compendial Name: The name of an article for which a monograph is provided in an
                                      official compendia (e.g., United States Pharmacopeia, National Formulary, or
                                      Homeopathic Pharmacopeia) recognized by regulatory agencies.
                                    </p>
                                    <p>
                                      An article may be an official substance or official preparation. Examples of
                                      Compendial Standards include:
                                      <ul>
                                        <li>
                                          <b>USP</b> - US Pharmacopeia
                                        </li>
                                        <li>
                                          <b>NF</b> - National Formulary
                                        </li>
                                        <li>
                                          <b>BP</b> - British Pharmacopeia
                                        </li>
                                        <li>
                                          <b>EP</b> - European Pharmacopeia
                                        </li>
                                        <li>
                                          <b>JP</b> - Japanese Pharmacopeia
                                        </li>
                                        <li>
                                          <b>CP</b> - Chinese Pharmacopeia
                                        </li>
                                      </ul>
                                    </p>
                                  </div>
                                )}
            />
          </div>
          <div className="row">
            <TextAreaAttribute name="description"
                               className="col-sm-12"
                               parent={this}
            />
            <TextAreaAttribute name="equipment"
                               className="col-sm-12"
                               parent={this}
            />
          </div>
        </Section>
        <Section id="development"
                 parent={this}
                 showDocLinks={true}
                 header="Method Development"
        >
          <div className="row">
            <TypeaheadAttribute name="supplier"
                                displayName="Supplier (performing test)"
                                typeaheadType="Supplier"
                                className="col-sm-12"
                                parent={this}
                                isLoading={this.state.isLoading}
                                updatedAt={this.state.updatedAt}
                                parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                                parentId={this.state.id}
            />
            <ComboBoxAttribute name="status"
                               options={[
                                 {key: "Pending", value: t("Pending")},
                                 {key: "Developed", value: t("Developed")},
                                 {key: "Qualified/Verified", value: t("Qualified/Verified")},
                                 {key: "Validated", value: t("Validated")},
                                 {key: "Transferred", value: t("Transferred")},
                               ]}
                               tooltipText={(<div>
                                 <b>Developed</b> - Performance of Control Method has been demonstrated via a
                                 scientifically sound and well-understood procedure taking robustness into account.<br />
                                 <br />
                                 <b>Qualified/Verified </b>- Completion of limited precision and robustness studies.
                                 These activities should follow Good Documentation Practices (GDP) with a written
                                 protocol and report. Standard (Compendial) methods usually only need qualification or
                                 verification as they are accepted legal standards.<br />
                                 <br />
                                 <b>Validated</b> - Extensive evaluations of precision, robustness, and other parameters
                                 of performance have been completed. These activities should follow GDP with a written
                                 protocol and full validation report. Standard (Compendial) methods usually do not need
                                 full validation as they are accepted legal standards.<br />
                                 <br />
                                 <b>Transferred</b> - The control method procedures have been transferred to another
                                 organization following appropriate protocols and GDP and the recipient organization has
                                 demonstrated the ability to execute the control method to the same standard as the
                                 organization developing the control method.<br />
                               </div>)}
                               tooltipGuidanceURL="https://www.fda.gov/media/87801/download"
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
            <ComboBoxAttribute name="stabilityIndicating"
                               options={["Yes", "No"]}
                               default="No"
                               tooltipText={(<div>
                                 <b>A Stability Indicating Method (SIM)</b> is defined as a validated analytical procedure that
                                 accurately and precisely measures active ingredients (drug substance or drug product) free
                                 from process impurities, excipients and degradation products. The main objective of
                                 a stability indicating method is to monitor results during stability studies in order to
                                 guarantee safety, efficacy and quality.<br />
                               </div>)}
                               parent={this}
                               isLoading={this.state.isLoading}
                               parentVersionId={this.state.currentDiffingVersion?.versionId ?? this.state.versionId}
                               parentId={this.state.id}
            />
          </div>
        </Section>
        <Section id="references"
                 parent={this}
                 showDocLinks={true}
                 addOptions={[
                   {id: "references", label: "References"},
                   {id: "standards", label: "Standards"},
                   {id: "guidances", label: "Guidances"}]}
                 header={<span>
                   References & Standards
                   <InfoTooltip id="infoRefAndStandardsControl"
                                verbiage={
                                  <div>
                                    References, standards, safety data sheets, etc. for highly characterized specimens
                                    of drug substances, excipients, food ingredients, impurities, degradation products,
                                    dietary supplements, compendial reagents, tests, assay, and performance calibrators.
                                    (e.g. USP, BP, EP, JP, ISO, etc.)
                                  </div>}
                   />
                   </span>}
        >
        </Section>
      </div>
    );
  }
}

export default I18NWrapper.wrap(ControlMethod, "controlMethod");
// i18next-extract-mark-ns-stop controlMethod
