"use strict";

import React from "react";
import * as RiskUtils from "../../../../server/common/misc/common_risk_utils";
import BaseReactComponent from "../../../base_react_component";

export default class RMPRiskComparisonWidget extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let riskScoreConversionMap;
    if (this.props.sourceRiskScale && this.props.sourceRiskScale.length > 0
      && this.props.targetRiskScale && this.props.targetRiskScale.length > 0) {
      riskScoreConversionMap = RiskUtils.getRMPRiskScoreConversionMap(this.props.sourceRiskScale, this.props.targetRiskScale);
    }

    return (
      <div id={this.props.name + "ConversionWidget"}>
        {this.props.showHeaderColumns ? (
          <div className="row">
            <div className="col-6 rmp-risk-comparison-widget-header">
              <div className="col-form-label">
                Current value
              </div>
            </div>
            <div className="col-6 rmp-risk-comparison-widget-header">
              <div className="col-form-label">
                New value
              </div>
            </div>
          </div>
        ) : ""}
        <div className="row">
          <div className="col-12 rmp-risk-comparison-widget-title">
            {this.props.riskTitle}
          </div>
        </div>
        <div className="row">
          <div id="riskConversionTable"
               className="col-12"
          >
            {this.props.sourceRiskScale ? this.props.sourceRiskScale.map(scale => {
              let sourceIsSameAsTarget = riskScoreConversionMap && scale.riskScore === riskScoreConversionMap[scale.riskScore];
              return (
                <div key={scale.riskScore} className="row rmp-risk-comparison-widget-score-row">
                  <div key={"source_" + scale.riskScore}
                       className={"col-6 rmp-risk-comparison-widget-score-cell " + (!sourceIsSameAsTarget ? "rmp-risk-comparison-widget-score-diff" : "")}
                  >
                    {scale.riskScore}
                  </div>
                  <div key={"new_" + scale.riskScore}
                       className={"col-6 rmp-risk-comparison-widget-score-cell " + (!sourceIsSameAsTarget ? "rmp-risk-comparison-widget-score-diff" : "")}
                  >
                    {riskScoreConversionMap ? riskScoreConversionMap[scale.riskScore] : ""}
                  </div>
                </div>
              );
            }) : ""}
          </div>
        </div>
      </div>
    );
  }
}

