"use strict";

import React from "react";
import * as UIUtils from "../../../ui_utils";
import { ImportDependencySelectionTable } from "./import_dependency_selection_table";
import * as ImportHelper from "../../helpers/import_helper";
import TypeaheadObjectCache from "../../../utils/cache/typeahead_object_cache";
import BasePopup from "../../../editor/approval/base_popup";
import { orderAndIndexSteps, orderAndIndexUnits } from "../../../processExplorer/indexers/uo_indexer";
import ImportProcessInfo from "./import_process_info";
import ImportBatchInfo from "./import_batch_info";
import ImportFlowSelect from "./import_flow_select";
import * as I18NWrapper from "../../../i18n/i18n_wrapper";
import CommonSecurity from "../../../../server/common/generic/common_security";
import { ProcessParameterIndexer } from "../../../processExplorer/indexers/process_parameter_indexer";

// i18next-extract-mark-ns-start import
/**
 * This implements a popup from which the user can generate an excel template file used for entering data used
 * in "By Process" import flows.
 */
class GenerateTemplatePopup extends BasePopup {
  constructor(props) {
    super(props);

    this.state.dependencySelectedRecords = [];
    this.state.dependencyRecords = [];
  }

  componentDidMount() {
    super.componentDidMount();
    const {projectId, processId, importConfig} = this.props;

    UIUtils.setHideLoadingOnAjaxStop(false);
    this.runPromise(
      new TypeaheadObjectCache("Process", projectId).loadOptions(processes => {
        const process = (processes || []).find(process => process.id === processId);
        this.setStateSafely({process});
      }).promise());
    this.runPromise(
      new TypeaheadObjectCache("Project").loadOptions(projects => {
        const project = (projects || []).find(project => project.id === projectId);
        this.setStateSafely({project});
      }).promise());
    this.runPromise(
      new TypeaheadObjectCache("UnitOperation", projectId, processId).loadOptions(unitOperations => {
        const orderedUOList = orderAndIndexUnits(unitOperations).map(uo => uo.label);
        this.setStateSafely({orderedUOList});
      }).promise());
    this.handleImportDataTypeSelected(importConfig);
  }

  handlePromiseCompleted(runningPromises) {
    if (runningPromises.size === 0) {
      UIUtils.setHideLoadingOnAjaxStop(true);
      UIUtils.hideLoadingImage();
    }
  }

  handleCancel() {
    super.handleCancel();
    if (this.props.onHidePopup) {
      this.props.onHidePopup();
    }
  }

  getDependencyRecords() {
    const {projectId, processId} = this.props;
    const importConfig = this.getSelectedImportConfig();
    const {selectionTableModelTypes, importContinuousData} = (importConfig || {});

    UIUtils.setHideLoadingOnAjaxStop(false);
    this.runPromise(
      UIUtils.secureAjaxGET(`import/get/getProcessCapabilityDataAttributes`, {
        projectId,
        processId,
        modelsToLoad: typeof selectionTableModelTypes === "string"
          ? selectionTableModelTypes
          : JSON.stringify(selectionTableModelTypes),
        importContinuousData,
      }, true)
        .done((result) => this.setStateSafely({dependencyRecords: result.instances})));
  }

  handleDownloadProcessCapabilityTemplate() {
    let {
      projectId,
      processId,
      selectedBatch,
    } = this.props;
    const {
      project,
      process,
      dependencySelectedRecords,
    } = this.state;
    const importConfig = this.getSelectedImportConfig();
    let {
      dependency,
      importDataForDependency,
      importContinuousData,
      importTemplateFileName,
      singleDependencySelection,
      importTemplateModelTypes
    } = (importConfig || {});

    dependency = UIUtils.stripAllWhitespaces(dependency);

    UIUtils.setHideLoadingOnAjaxStop(false);
    UIUtils.showLoadingImage("Downloading template");

    const hideLoadingIndicator = () => {
      UIUtils.setHideLoadingOnAjaxStop(true);
      UIUtils.hideLoadingImage();
    };

    if (!importDataForDependency) {
      let dependencyKeys;
      if (singleDependencySelection) {
        dependencyKeys = [dependencySelectedRecords[0].key];
      } else {
        dependencyKeys = dependencySelectedRecords.map(record => record.key);
      }
      UIUtils.secureAjaxGET(`import/get/getProcessCapabilityDataAttributes`, {
        projectId: projectId,
        processId: processId,
        modelsToLoad: typeof importTemplateModelTypes === "string"
          ? importTemplateModelTypes
          : JSON.stringify(importTemplateModelTypes),
        parentModelAttributes: typeof dependencyKeys === "string"
          ? dependencyKeys
          : JSON.stringify(dependencyKeys),
      }, true).done(data => {
        data.attributes = dependency === "UnitOperation" ?
          this.adaptDataBasedOnProcessParametersOrder(dependencySelectedRecords, data.instances) :
          data.instances;
        data.batch = selectedBatch;
        ImportHelper.downloadProcessCapabilityTemplate(importTemplateFileName, data, dependency,
          dependencySelectedRecords, importContinuousData).then(() => hideLoadingIndicator());
      });
    } else {
      ImportHelper.downloadProcessCapabilityTemplate(importTemplateFileName, {
        attributes: dependencySelectedRecords,
        project,
        process,
        batch: selectedBatch
      }, dependency, null, importContinuousData).then(() => {
        hideLoadingIndicator();
      });
    }

    this.handleCancel();
  }

  /**
   *
   * @param unitOperations The list of UO's we wish to sort its Process Parameters
   * @param attributes
   * @returns {*[]} A list of all the subsidiary elements of each UO, including sorted Process Parameters
   */
  adaptDataBasedOnProcessParametersOrder(unitOperations, attributes) {
    let allRecords = [];

    for (const unitOperation of unitOperations) {
      let orderedProcessParameters = [];

      const unitOperationAttributes = attributes.filter(attribute => attribute.UnitOperationId === unitOperation.id);
      const processParameters = unitOperationAttributes.filter(attribute => attribute.typeCode === "PP");
      const nonProcessParameters = unitOperationAttributes.filter(attribute => attribute.typeCode !== "PP");

      const unitOperationProcessParameters = processParameters.filter(pp => pp.UnitOperationId && !pp.StepId);
      const unitOperationProcessParametersMap = new Map(unitOperationProcessParameters.map(pp => [pp.id, pp]));

      orderedProcessParameters = orderedProcessParameters.concat(ProcessParameterIndexer
        .sortRecordMapBasedOnIDArray(unitOperation.RecordOrder ?
          JSON.parse(unitOperation.RecordOrder.processParameterOrder) :
          [], unitOperationProcessParametersMap));

      const orderedStepList = orderAndIndexSteps(unitOperation.steps);
      for (const step of orderedStepList) {
        const stepProcessParameters = processParameters.filter(pp => pp.StepId === step.id);
        const stepProcessParametersMap = new Map(stepProcessParameters.map(pp => [pp.id, pp]));

        orderedProcessParameters = orderedProcessParameters.concat(ProcessParameterIndexer
          .sortRecordMapBasedOnIDArray(step.RecordOrder ?
            JSON.parse(step.RecordOrder.processParameterOrder) :
            [], stepProcessParametersMap));
      }

      allRecords = allRecords.concat([...nonProcessParameters, ...orderedProcessParameters]);
    }

    return allRecords;
  }

  handleObjectsSelectionForImport(selectedRecords) {
    let {dependencySelectedRecords} = this.state;

    if (JSON.stringify(selectedRecords) !== JSON.stringify(dependencySelectedRecords)) {
      this.setStateSafely({
        dependencySelectedRecords: selectedRecords,
      });
    }
  }

  handleImportDataTypeSelected(importConfig) {
    this.setStateSafely({importConfig}, () => {
      this.getDependencyRecords();
    });
  }

  getSelectedImportConfig() {
    return this.state.importConfig || this.props.importConfig;
  }

  render() {
    let {
      t,
      modelName,
      projectId,
      processId,
      selectedBatch,
      importTypeConfigs,
      importDependenciesStatus,
      enableImportConfigSelection,
    } = this.props;
    const {process, dependencySelectedRecords, dependencyRecords, orderedUOList} = this.state;
    const importConfig = this.getSelectedImportConfig();

    let {
      dependency,
      singleDependencySelection,
      importContinuousData,
    } = importConfig;

    dependency = UIUtils.stripAllWhitespaces(dependency || "");
    let downloadButtonDisabled = dependencySelectedRecords.length === 0;

    return (
      <div className="modal fade"
           ref={this.setModalRef}
      >
        <div className="modal-dialog import-template-popup">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">
                {t("Generate Import Template")}
              </h1>
              <button id="closeGenerateImportTemplatePopup"
                      type="button"
                      className="close"
                      onClick={this.handleCancel}
                      aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="modal-container">
                <div className="col">
                  <div className="row">
                    <div className="col">
                      <ImportProcessInfo process={process} />
                    </div>
                    {selectedBatch && modelName !== "Attribute" ? (
                      <div className="col">
                        <ImportBatchInfo selectedBatch={selectedBatch} />
                      </div>
                    ) : ""}
                    <div className="col">
                      <ImportFlowSelect projectId={projectId}
                                        processId={processId}
                                        importConfig={importConfig}
                                        importTypeConfigs={importTypeConfigs}
                                        importDependenciesStatus={importDependenciesStatus}
                                        enableImportConfigSelection={enableImportConfigSelection}
                                        onImportDataTypeSelected={this.handleImportDataTypeSelected}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="import-dependencies-table-div">
                        <ImportDependencySelectionTable
                          key="attributeSelectionTable"
                          id="dependencySelection"
                          records={dependencyRecords}
                          orderedUOList={orderedUOList}
                          modelName={dependency}
                          importContinuousData={importContinuousData}
                          enableSingleSelection={singleDependencySelection}
                          securityAction={importConfig.securityAction || CommonSecurity.Actions.EDIT}
                          onObjectsSelection={this.handleObjectsSelectionForImport}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="modal-container">
                <div className="btn-group">
                  <button
                    disabled={downloadButtonDisabled}
                    className="btn btn-lg btn-primary"
                    id="generateTemplateButton"
                    onClick={this.handleDownloadProcessCapabilityTemplate}
                  >
                    Generate
                  </button>
                  <button id="cancelButton"
                          type="button"
                          className="btn btn-lg btn-secondary"
                          data-dismiss="modal"
                          onClick={this.handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default I18NWrapper.wrap(GenerateTemplatePopup, ["import"]);

// i18next-extract-mark-ns-stop import
