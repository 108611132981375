"use strict";

import * as DocumentTransferHelper from "../document_transfer_helper";

export default class CanvasDownloader {
  static downloadCanvas(canvas, fileName) {
    const b64Data = canvas.toDataURL("png").replace(/^data:image\/(png|jpg);base64,/, "");

    // MS Edge doesn't work with data URLs.  Also, Chrome keeps clamping down on their security.  Blobs are safer.
    let blob = new Blob([CanvasDownloader.b64toBlob(b64Data, "image/png")], {type: "image/png"});

    DocumentTransferHelper.download(fileName, window.URL.createObjectURL(blob));
  }

  static b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: contentType});
  }

}
