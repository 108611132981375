import { __assign } from "tslib";
import { Schema } from 'prosemirror-model';
import { tableNodes } from 'prosemirror-tables';
import { domToPmDoc, htmlToFragment, pmDocToFragment } from '../source';
import { rowTypeAttr, colgroupAttr, resizableAttr } from './constants';
var hole = 0;
var blockquoteDOM = ['blockquote', hole], hrDOM = ['hr'], preDOM = ['pre', ['code', hole]];
var olDOM = ['ol', 0], ulDOM = ['ul', 0], liDOM = ['li', 0];
var domAttributes = function (dom) {
    var result = {};
    var attributes = dom.attributes, attr;
    for (var i = 0; i < attributes.length; i++) {
        attr = attributes[i];
        result[attr.name] = attr.value;
    }
    return result;
};
var defaultAttrs = function (attrs) {
    var nodeAttrs = {};
    attrs.forEach(function (attr) {
        nodeAttrs[attr] = { default: null };
    });
    return nodeAttrs;
};
var commonAttributes = function () { return defaultAttrs(['style', 'class', 'id']); };
var hasAttrs = function (attrs, exclude) {
    for (var attr in attrs) {
        if (attr && attrs[attr] !== null && attr !== exclude) {
            return true;
        }
    }
    return false;
};
var pmAttributes = function (attrs, exclude) {
    if (exclude === void 0) { exclude = []; }
    var result = {};
    var _loop_1 = function (attr) {
        if (attr && attrs[attr] !== null && !exclude.find(function (e) { return e === attr; })) {
            result[attr] = attrs[attr];
        }
    };
    for (var attr in attrs) {
        _loop_1(attr);
    }
    return result;
};
var tagMark = function (tag) {
    var _a;
    return _a = {},
        _a[tag] = {
            name: tag,
            inclusive: true,
            parseDOM: [{ tag: tag }],
            toDOM: function () { return [tag, hole]; }
        },
        _a;
};
var marks = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({ 
    // :: MarkSpec A link. Has `href` and `title` attributes. `title`
    // defaults to the empty string. Rendered and parsed as an `<a>`
    // element.
    link: {
        attrs: __assign(__assign({}, commonAttributes()), { href: { default: null }, target: { default: null }, title: { default: null } }),
        inclusive: false,
        parseDOM: [{ tag: 'a', getAttrs: domAttributes }],
        toDOM: function (node) { return ['a', pmAttributes(node.attrs), hole]; }
    } }, tagMark('strong')), tagMark('b')), tagMark('em')), tagMark('i')), tagMark('u')), tagMark('del')), tagMark('sub')), tagMark('sup')), tagMark('code')), { style: {
        attrs: __assign({}, commonAttributes()),
        parseDOM: [{
                tag: 'span',
                getAttrs: domAttributes
            }],
        toDOM: function (node) { return hasAttrs(node.attrs) ?
            ['span', pmAttributes(node.attrs), hole] : ['span', hole]; }
    } });
var cellAttribute = function (name) {
    var _a;
    return _a = {},
        _a[name] = {
            default: null,
            getFromDOM: function (cell) { return cell.getAttribute(name); },
            setDOMAttr: function (value, attrs) { attrs[name] = value; }
        },
        _a;
};
var cellAttributes = __assign(__assign(__assign(__assign({}, cellAttribute('style')), cellAttribute('class')), cellAttribute('id')), cellAttribute('headers'));
var colgroupNodes = {
    doc: { content: 'colgroup*' },
    col: {
        attrs: defaultAttrs(['id', 'class', 'style', 'span']),
        parseDOM: [{ getAttrs: domAttributes, tag: 'col' }],
        toDOM: function (node) { return ['col', node.attrs]; }
    },
    colgroup: {
        attrs: defaultAttrs(['id', 'class', 'style', 'span']),
        content: 'col*',
        parseDOM: [{ getAttrs: domAttributes, tag: 'colgroup' }],
        toDOM: function (node) { return ['colgroup', node.attrs, 0]; }
    },
    text: { inline: true, group: 'inline' }
};
var colgroupSchema = new Schema({ nodes: colgroupNodes, marks: {} });
// will be removed when we implement our own columnResizing
var shouldSkipColgroup = function (node) {
    var shouldSkip = false;
    var row = node.child(0);
    for (var r = 0; r < row.childCount; r++) {
        var cell = row.child(r);
        if (cell.attrs.colwidth) {
            shouldSkip = true;
            break;
        }
    }
    return shouldSkip;
};
export var parseStrColgroup = function (colgroup) {
    var doc = domToPmDoc(htmlToFragment(colgroup), colgroupSchema, { preserveWhitespace: false });
    var fragment = pmDocToFragment(doc);
    var colgroupEl = fragment.firstChild;
    return colgroupEl;
};
var tNodes = tableNodes({ tableGroup: 'block', cellContent: 'block+', cellAttributes: cellAttributes });
tNodes.table_row.attrs = __assign(__assign({}, tNodes.table_row.attrs), defaultAttrs([rowTypeAttr, 'style', 'class', 'id']));
tNodes.table_row.toDOM = function (node) { return ['tr', pmAttributes(node.attrs), 0]; };
tNodes.table_row.parseDOM = [{ tag: 'tr', getAttrs: domAttributes }];
tNodes.table.attrs = __assign(__assign({}, tNodes.table.attrs), defaultAttrs(['style', 'class', 'id', colgroupAttr, resizableAttr]));
tNodes.table.toDOM = function (node) {
    var tableAttrs = hasAttrs(node.attrs) ? pmAttributes(node.attrs, [colgroupAttr, resizableAttr]) : {};
    var colgroup = null;
    if (node.attrs[colgroupAttr] && !shouldSkipColgroup(node)) {
        var colgroupEl = parseStrColgroup(node.attrs[colgroupAttr]);
        if (colgroupEl) {
            var cols = Array.from(colgroupEl.children).map(function (c) { return ['col', domAttributes(c)]; });
            colgroup = [
                'colgroup',
                domAttributes(colgroupEl)
            ].concat(cols);
        }
    }
    return colgroup ? ['table', tableAttrs, colgroup, ['tbody', 0]] :
        ['table', tableAttrs, ['tbody', 0]];
};
tNodes.table.parseDOM = [{
        tag: 'table',
        getAttrs: function (node) {
            var attrs = domAttributes(node);
            var colgroup = Array.from(node.childNodes).find(function (c) { return c.nodeName === 'COLGROUP'; });
            if (colgroup) {
                attrs[colgroupAttr] = colgroup.outerHTML;
            }
            return attrs;
        }
    }];
var nodes = __assign({ 
    // :: NodeSpec The top level document node.
    doc: {
        content: 'block+'
    }, 
    // :: NodeSpec A plain paragraph textblock. Represented in the DOM
    // as a `<p>` element.
    paragraph: {
        content: 'inline*',
        group: 'block',
        attrs: __assign({}, commonAttributes()),
        parseDOM: [{
                tag: 'p',
                getAttrs: domAttributes
            }],
        toDOM: function (node) { return hasAttrs(node.attrs) ? ['p', pmAttributes(node.attrs), hole] : ['p', hole]; }
    }, div: {
        // Uncaught SyntaxError: Mixing inline and block content (in content expression '(block | inline)*')
        // content: '(block | inline)*',
        content: 'block*',
        group: 'block',
        attrs: __assign({}, commonAttributes()),
        parseDOM: [{
                tag: 'div',
                getAttrs: domAttributes
            }],
        toDOM: function (node) { return hasAttrs(node.attrs) ? ['div', pmAttributes(node.attrs), hole] : ['div', hole]; }
    }, 
    // :: NodeSpec A blockquote (`<blockquote>`) wrapping one or more blocks.
    blockquote: {
        attrs: __assign({}, commonAttributes()),
        content: 'block+',
        group: 'block',
        defining: true,
        parseDOM: [{
                tag: 'blockquote',
                getAttrs: domAttributes
            }],
        toDOM: function (node) { return hasAttrs(node.attrs) ? ['blockquote', pmAttributes(node.attrs), hole] : blockquoteDOM; }
    }, 
    // :: NodeSpec A horizontal rule (`<hr>`).
    horizontal_rule: {
        group: 'block',
        parseDOM: [{ tag: 'hr' }],
        toDOM: function () { return hrDOM; }
    }, 
    // :: NodeSpec A heading textblock, with a `level` attribute that
    // has to hold the numbers from 1 to 6. Parsed and serialized as `<h1>` to
    // `<h6>` elements.
    heading: {
        attrs: __assign(__assign({}, commonAttributes()), { level: { default: 1 } }),
        content: 'inline*',
        group: 'block',
        defining: true,
        parseDOM: [
            { tag: 'h1', getAttrs: function (node) { return (__assign(__assign({}, domAttributes(node)), { level: 1 })); } },
            { tag: 'h2', getAttrs: function (node) { return (__assign(__assign({}, domAttributes(node)), { level: 2 })); } },
            { tag: 'h3', getAttrs: function (node) { return (__assign(__assign({}, domAttributes(node)), { level: 3 })); } },
            { tag: 'h4', getAttrs: function (node) { return (__assign(__assign({}, domAttributes(node)), { level: 4 })); } },
            { tag: 'h5', getAttrs: function (node) { return (__assign(__assign({}, domAttributes(node)), { level: 5 })); } },
            { tag: 'h6', getAttrs: function (node) { return (__assign(__assign({}, domAttributes(node)), { level: 6 })); } }
        ],
        toDOM: function (node) { return hasAttrs(node.attrs, 'level') ?
            ['h' + node.attrs.level, pmAttributes(node.attrs, ['level']), hole] :
            ['h' + node.attrs.level, hole]; }
    }, 
    // :: NodeSpec A code listing. Disallows marks or non-text inline
    // nodes by default. Represented as a `<pre>` element with a
    // `<code>` element inside it.
    code_block: {
        content: 'text*',
        marks: '',
        group: 'block',
        code: true,
        defining: true,
        parseDOM: [{ tag: 'pre', preserveWhitespace: 'full' }],
        toDOM: function () { return preDOM; }
    }, 
    // :: NodeSpec The text node.
    text: {
        inline: true,
        group: 'inline'
    }, 
    // :: NodeSpec An inline image (`<img>`) node. Supports `src`,
    // `alt`, and `href` attributes. The latter two default to an empty
    // string.
    image: {
        inline: true,
        attrs: __assign({ src: { default: null }, alt: { default: null }, title: { default: null }, width: { default: null }, height: { default: null } }, commonAttributes()),
        group: 'inline',
        draggable: true,
        parseDOM: [{ tag: 'img', getAttrs: domAttributes }],
        toDOM: function (node) { return hasAttrs(node.attrs) ? ['img', pmAttributes(node.attrs)] : ['img']; }
    }, 
    // :: NodeSpec A hard line break represented in the DOM as a `<br>` element.
    hard_break: {
        inline: true,
        attrs: __assign({}, commonAttributes()),
        group: 'inline',
        selectable: false,
        parseDOM: [{
                tag: 'br',
                getAttrs: domAttributes
            }],
        toDOM: function (node) { return hasAttrs(node.attrs) ? ['br', pmAttributes(node.attrs)] : ['br']; }
    }, 
    // :: NodeSpec
    // An ordered list [node spec](#model.NodeSpec). Has a single
    // attribute, `order`, which determines the number at which the list
    // starts counting, and defaults to 1. Represented as an `<ol>`
    // element.
    ordered_list: {
        content: 'list_item+',
        group: 'block',
        attrs: __assign(__assign({}, commonAttributes()), { type: { default: null }, order: { default: 1 } }),
        parseDOM: [{ tag: 'ol', getAttrs: function (dom) {
                    return __assign(__assign({}, domAttributes(dom)), { order: dom.hasAttribute('start') ? parseInt(dom.getAttribute('start') || '1', 10) : 1 });
                } }],
        toDOM: function (node) {
            return node.attrs.order === 1 ?
                (hasAttrs(node.attrs, 'order') ? ['ol', pmAttributes(node.attrs, ['order']), hole] : olDOM) :
                ['ol', __assign(__assign({}, pmAttributes(node.attrs, ['order'])), { start: node.attrs.order }), hole];
        }
    }, 
    // :: NodeSpec
    // A bullet list node specification represented in the DOM as a `<ul>` element.
    bullet_list: {
        content: 'list_item+',
        group: 'block',
        attrs: __assign({}, commonAttributes()),
        parseDOM: [{ tag: 'ul', getAttrs: domAttributes }],
        toDOM: function (node) { return hasAttrs(node.attrs) ? ['ul', pmAttributes(node.attrs), hole] : ulDOM; }
    }, 
    // :: NodeSpec
    // A list item (`<li>`) specification.
    list_item: {
        content: '(paragraph | heading) block*',
        attrs: __assign({}, commonAttributes()),
        parseDOM: [{ tag: 'li', getAttrs: domAttributes }],
        toDOM: function (node) { return hasAttrs(node.attrs) ? ['li', pmAttributes(node.attrs), hole] : liDOM; },
        defining: true
    } }, tNodes);
export { nodes, marks };
