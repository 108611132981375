"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import BasePage from "../../base_page";
import Heading from "../../widgets/headers/heading";
import InfoTooltip from "../../widgets/tooltips/info_tooltip";
import ErrorBar from "../../widgets/bars/error_bar";
import Cookies from "js-cookie";
import { RiskTables } from "./risk_tables";
import {
  RISK_LABEL_ACTION,
  RISK_TABLE_REPORT_TO_RMP_ENUM,
  RISK_TABLE_REPORTS_ENUM
} from "../constants/report_constants";
import DatePicker from "../../widgets/date_picker";
import { getEffectiveRMPForDate, loadRMP } from "../../helpers/risk_helper";
import { getURLByTypeCodeAndIdAndVersionId } from "../../helpers/url_helper";
import moment from "moment-timezone";
import { exportRiskTables } from "./utils/risk_tables_export";

import { orderAndIndexUnits } from "../../processExplorer/indexers/uo_indexer";
import { getUTCDateForURL } from "../canned_reports/canned_report_helper";
import RiskTablesFilter from "../../widgets/tables/risk_tables/risk_tables_filter";
import CompanyHeader from "../../widgets/headers/company_header";
import * as ProcessCache from "../../processExplorer/process/process_cache";
import FullObjectCache from "../../utils/cache/full_object_cache";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import MultipleTypeaheadObjectCache from "../../utils/cache/multiple_typeahead_object_cache";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faPrint } from "@fortawesome/free-solid-svg-icons";
import * as CommonRiskTable from "./utils/risk_tables_helper";
import { pluralize, getModelNameForTypeCode, convertToCamelCaseId, convertToId } from "../../ui_utils";
import { ServiceBase } from "../../services/service_base";


// This is the initial state until the typeaheads have loaded
export const EMPTY_UOS_OPTION = {
  id: 0,
  name: "",
};

// This is what the user picks when they want to see all UOs
export const ALL_UOS_OPTION = {
  id: -1,
  name: "All",
};

/* This class shows the risk map report */
export default class RiskTablesReportPage extends BasePage {
  constructor(props) {
    super(props);

    this.projectId = UIUtils.getParameterByName("projectId");
    let reportKey = UIUtils.getParameterByName("report");
    let showRawScore = UIUtils.getParameterByName("showRawScore");
    let labelAction = UIUtils.getParameterByName("labelAction");
    let showCriticalOnly = UIUtils.getParameterByName("showCriticalOnly");
    this.hideOptions = UIUtils.getParameterByName("hideOptions");
    let selectedUOId = UIUtils.getParameterByName("unitOperationId");

    const processId = UIUtils.getParameterByName("processId") || ProcessCache.getProcessIdUsedRecently(this.props.projectId);
    if (processId) {
      this.setStateSafely({processId});
    }

    this.reportNameToKeyMap = {};
    Object.keys(RISK_TABLE_REPORTS_ENUM).forEach(key => {
      this.reportNameToKeyMap[RISK_TABLE_REPORTS_ENUM[key]] = key;
    });

    if (!reportKey) {
      this.resetAutoScroll();
    }

    reportKey = reportKey || this.reportNameToKeyMap[RISK_TABLE_REPORTS_ENUM.QTPP_QUALITY];
    showRawScore = showRawScore === null ? true : showRawScore === "true";
    labelAction = labelAction ?? RISK_LABEL_ACTION.SHOW_SCORE_LABEL;
    showCriticalOnly = showCriticalOnly === null ? false : showCriticalOnly === "true";
    selectedUOId = selectedUOId ? UIUtils.parseInt(selectedUOId) : EMPTY_UOS_OPTION.id;

    this.setStateSafely({
      quickPanelKeyToInstanceMap: new Map(),
      reportKey,
      showRawScore,
      labelAction,
      showCriticalOnly,
      selectedUOId,
      reportDate: UIUtils.getEndOfDayForDate(moment()).toDate(),
      title: "Risk Tables Report",
    });

    window.addEventListener("popstate", this.handleHistory);
    this.handleReportChange(this.state.reportKey);
    new FullObjectCache("Project", this.projectId).loadOptions(this.handleProjectResultsFromServer, {includeAllVersions: true});

    // Pre-load the rest of the needed typeaheads so they're ready when the user opens records in the Quick Panel
    if (this.state.processId) {
      this.loadTypeaheads();
    }
  }

  componentDidMount() {
    super.componentDidMount();

    document.title = "QbDVision Risk Tables";
    this.setTooltipsToAutoClose();
  }

  componentDidUpdate() {
    this.setTooltipsToAutoClose();
  }

  setTooltipsToAutoClose() {
    $(`[data-toggle="popover"]`).on("show.bs.popover", function() {
      $("*").popover("hide");
    });
  }

  handleProjectResultsFromServer(results) {
    this.setExtraPrintItems([
      {
        order: 1,
        label: "Project",
        data: UIUtils.getRecordLabelForDisplay("PRJ", results.id, results.name),
      },
      {
        order: 3,
        label: "Report Date",
        data: this.getLocalDateFromState(UIUtils.DATE_FORMAT_FOR_DISPLAY),
      }
    ]);

    this.setStateSafely({project: results});
  }

  async handleTypeaheadResultsFromServer(ignored, typeCode) {
    if (typeCode === "UO") {
      // Uncomment for verbose logging
      // console.log("Received UO Typeahead results:", ignored);

      const typeaheadObjectCache = new TypeaheadObjectCache("UnitOperation", this.projectId, this.state.processId);
      const orderedUOList = orderAndIndexUnits(typeaheadObjectCache.getOptionsFromCache());
      const newState = {
        orderedUOList,
        reportDataComputed: false,
        unitOperationsFinishedOrdering: true,
        isLoadingUOTypeahead: false,
      };
      if (!this.state.selectedUOId) {
        newState.selectedUOId = orderedUOList && orderedUOList.length > 0 ? orderedUOList[0].id : ALL_UOS_OPTION.id;
      }

      this.setStateSafely(newState);
    } else if (typeCode === "STP" || typeCode === "MT" || typeCode === "PRC") {
      const modelName = convertToId(getModelNameForTypeCode(typeCode));
      const pluralizedModelName = pluralize(convertToCamelCaseId(modelName));
      const typeaheadObjectCache = new TypeaheadObjectCache(modelName, this.projectId, this.state.processId);
      const cachedInstances = typeaheadObjectCache.getOptionsFromCache();
      this.setStateSafely({[pluralizedModelName]: [...cachedInstances]});
    }
  }

  getLocalDateFromState(format) {
    return this.state.reportDate ? moment(this.state.reportDate).format(format) : null;
  }

  handleProcessChange(processId, event) {
    UIUtils.ignoreHandler(event);
    this.setStateSafely({
      processId,
      selectedUOId: EMPTY_UOS_OPTION.id,
    }, () => {
      this.loadTypeaheads();
      this.loadReportData();
    });
  }

  /**
   * Deletes the auto scroll in case
   * 1- This was the first time page loads
   * 2- Report dropdown changes
   * so it starts in the top
   */
  resetAutoScroll() {
    Cookies.remove("RISK_TABLES_SCROLL_TO_ID");
  }

  handleReportChange(event) {
    const reportKey = event.target ? event.target.value : event;
    $("[data-toggle='popover']").popover("hide");

    if (this._isMounted) {
      this.resetAutoScroll();
    }

    let reportSettings = CommonRiskTable.getReportSettings(reportKey);
    let labelAction = this.state.labelAction;
    if (!reportSettings.isPivot && labelAction === RISK_LABEL_ACTION.SHOW_EFFECT_LABEL) {
      labelAction = RISK_LABEL_ACTION.SHOW_SCORE_LABEL;
    }

    this.setStateSafely({
      reportKey,
      labelAction,
    }, () => {
      this.loadReportData();
    });
  }

  handleReportDateChange(event) {
    if (event && event instanceof Date) {
      let selectedDate = UIUtils.getEndOfDayForDate(event);

      this.setStateSafely({
        reportDate: selectedDate.toDate(),
      }, () => {
        this.loadReportData();
      });
    }
  }

  handleUOChange(event) {
    const selectedUOId = UIUtils.parseInt(event.target ? event.target.value : event);
    if (selectedUOId !== this.state.selectedUOId) {
      UIUtils.showLoadingImage();
      this.setStateSafely({selectedUOId}, () => {
        UIUtils.pushHistoryURLWithNewParameter(this.state, "unitOperationId", this.state.selectedUOId);
        UIUtils.hideLoadingImage();
      });
    }
  }

  loadTypeaheads() {
    this.setStateSafely({isLoadingUOTypeahead: true});
    this.typeaheadPromise = new MultipleTypeaheadObjectCache(
      ["TPPSection", "UnitOperation", "Step", "Supplier", "Material", "IQA", "FQA", "IPA", "FPA", "ControlMethod", "ProcessComponent"],
      this.projectId,
      this.state.processId).loadOptions(this.handleTypeaheadResultsFromServer).promise();
  }

  /**
   * This loads the RMP first and then calls loadReportDataHelper() to load the actual report data.
   */
  loadReportData() {
    loadRMP((rmp, project) => {
      let effectiveRMP;
      if (rmp) {
        effectiveRMP = getEffectiveRMPForDate(project, rmp.approvedVersionsWithDetails, this.state.reportDate,
          RISK_TABLE_REPORT_TO_RMP_ENUM[this.state.reportKey]);
      }

      this.setStateSafely({RMP: effectiveRMP, Project: project}, this.loadReportDataHelper);
    });
  }

  /**
   * This actually loads the report data, knowing the RMP data is available.
   */
  loadReportDataHelper() {
    const processId = this.state.processId || ProcessCache.getProcessIdUsedRecently(this.props.projectId);
    UIUtils.clearError();

    const requestPayload = {
      projectId: this.projectId,
      report: this.state.reportKey,
    };

    if (this.state.reportDate) {
      requestPayload.date = getUTCDateForURL(this.state.reportDate);
    }

    if (processId) {
      requestPayload.processId = processId;
    }

    let parameters = {
      action: "reports",
      useTwoWayCommunication: true,
    };

    const service = new ServiceBase();
    service.sendToBackend(requestPayload, parameters).then(this.handleReceiveReportDataFromServer).catch(result => {
      UIUtils.defaultFailFunction(result, null, null, UIUtils.PRIMARY_ALERT_DIV);
    });
  }

  loadQuickPanelData(riskTablesData) {
    let requirementIds = new Set();

    for (const uoData of riskTablesData) {

      // Get all of the keys to load from the row headers
      const data = uoData.data;
      const riskTableOptionsRowType = CommonRiskTable.getRiskTableOptions(this.state.reportKey).rowModelName;
      for (const datum of data) {
        const rowType = datum.targetModelType || riskTableOptionsRowType;
        const rowId = datum.targetModelId || datum.id;
        requirementIds.add(rowType + "-" + rowId);
      }

      // Get all of the keys to load from the column headers
      const columnAttributes = uoData.columnAttributes;
      if (columnAttributes) {
        for (const column of Object.values(columnAttributes)) {
          requirementIds.add(column.sourceModelType + "-" + column.sourceModelId);
        }
      }
    }

    if (this.state.unitOperationsFinishedOrdering) {
      UIUtils.setHideLoadingOnAjaxStop(true);
    }

    let includeHistory = this.state.reportDate && !this.isReportDateInTheFuture() ? "&includeLastViewedVersion=false" +
      "&includeHistory=true" +
      "&shouldCompress=true" +
      "&loadFullHistory=true" : "";
    UIUtils.secureAjaxPUT(
      `editables/multiple/list/${this.projectId}?onlyLoadDetails=true${includeHistory}&includeClonedFrom=true`,
      {
        requirementIds: [...requirementIds],
        sendingProcessId: this.state.originalReportData.process.sendingId
      }
    ).done(this.handleReceiveQuickPanelDataFromServer);
  }

  // This is called when the user hits the back button to see a previous Unit Operation
  handleHistory(event) {
    // Uncomment for verbose logging
    // console.log("History changed to state: " + UIUtils.stringify(event.state));
    this.setStateSafely(event.state);
  }

  handlePrint() {
    window.print();
  }

  handleReceiveReportDataFromServer(originalReportData) {
    // Uncomment for verbose logging
    // console.log("Received risk table (" + this.state.reportKey + ") results: ", originalReportData);

    const processId = originalReportData.process.id;
    this.setExtraPrintItems([
      {
        order: 1,
        label: "Project",
        data: UIUtils.getRecordLabelForDisplay("PRJ", originalReportData.project.id, originalReportData.project.name),
      },
      {
        order: 2,
        label: "Process",
        data: UIUtils.getRecordLabelForDisplay("PR", processId, originalReportData.process.name),
      },
      {
        order: 3,
        label: "Report Date",
        data: this.getLocalDateFromState(UIUtils.DATE_FORMAT_FOR_DISPLAY),
      },
    ]);

    // Load the typeaheads, if still needed.
    if (!this.state.processId) {
      this.setStateSafely({processId}, () => {
        this.loadTypeaheads();
      });
    }

    this.setStateSafely({
      originalReportData,
      reportDataComputed: false,
    }, () => {
      // By now, the typeaheads have started being loaded.
      this.typeaheadPromise.then(() => {
        this.computeRiskTablesData();
        UIUtils.setHideLoadingOnAjaxStop(true);
      });
    });
  }

  computeRiskTablesData(loadQuickPanelData = true) {
    if (this.state.originalReportData && this.state.orderedUOList && !this.state.reportDataComputed) {
      const loadedData = UIUtils.deepClone(this.state.originalReportData.instances.riskTablesData);

      const {reportKey, showCriticalOnly, RMP, orderedUOList, steps, materials, processComponents} = this.state;

      // Load data adapter class.
      const {DataAdapterClass} = CommonRiskTable.getReportSettings(reportKey);

      const adapterArgs = {
        reportKey,
        steps,
        unitOperations: orderedUOList,
        reportData: loadedData
      };

      const dataAdapter = new DataAdapterClass(adapterArgs);

      let {
        tableDataModel,
        criticalSortedReportData
      } = dataAdapter.convertToRiskTableDataModel(showCriticalOnly, RMP, {materials, processComponents});

      let riskTablesData = tableDataModel ?? [];
      let reportDataForExport = criticalSortedReportData ?? [];

      if (loadQuickPanelData) {
        this.loadQuickPanelData(riskTablesData);
      }

      this.setStateSafely({
        data: riskTablesData,
        reportDataForExport,
        reportDataComputed: true,
      }, () => {
        UIUtils.pushHistoryURLWithParameterChanges(this.state, {report: reportKey},
          !CommonRiskTable.allowSelectUO(reportKey) ? {unitOperationId: true} : null);
      });
    }
  }

  exportToExcel() {
    let {
      reportKey,
      RMP,
      originalReportData,
      reportDataForExport,
      reportDate,
      labelAction,
      showRawScore,
      steps,
      orderedUOList,
      selectedUOId,
    } = this.state;

    // Only export the selected UO
    if (CommonRiskTable.allowSelectUO(reportKey) && selectedUOId && selectedUOId !== ALL_UOS_OPTION.id) {
      reportDataForExport = reportDataForExport.filter(row => row.groupByUOId === this.state.selectedUOId);
    }

    // Avoid sending the orderedUOList to keep the order of instances by thier location. they are already sorted before in thier Data Adapters.
    exportRiskTables(reportKey, RMP, originalReportData, reportDataForExport, reportDate,
      CommonRiskTable.getReportSettings(reportKey), labelAction, showRawScore, true, orderedUOList,
      steps, "id");
  }

  handleReceiveQuickPanelDataFromServer(results) {
    // Uncomment for verbose logging
    // console.log("Received for Quick Edit: " + UIUtils.stringify(results));
    let quickPanelKeyToInstanceMap = new Map();
    for (const instance of results.instances) {
      quickPanelKeyToInstanceMap.set(UIUtils.getTypeCodeForModelName(instance.modelName) + "-" + instance.id, instance);
    }
    this.setStateSafely({quickPanelKeyToInstanceMap}, () => {
      const scrollToId = Cookies.get("RISK_TABLES_SCROLL_TO_ID");
      if (scrollToId) {
        UIUtils.scrollToField(`#${scrollToId}`);
      }
    });
  }

  getDateForDisplayToUser() {
    const now = UIUtils.getDateForDisplayToUser(moment());
    if (!this.state.reportDate) {
      return now;
    } else {
      const isFuture = this.isReportDateInTheFuture();
      if (isFuture) {
        return now;
      } else {
        return UIUtils.getDateForDisplayToUser(this.state.reportDate);
      }
    }
  }

  isReportDateInTheFuture() {
    return moment().diff(moment(this.state.reportDate)) < 0;
  }

  handleRiskScoreTypeChange(value) {
    this.setStateSafely({
      showRawScore: value === "showRawScore",
      reportDataComputed: false,
    }, () => {
      UIUtils.pushHistoryURLWithNewParameter(this.state, "showRawScore", this.state.showRawScore);
    });
  }

  handleShowRiskLabelChange(value) {
    this.setStateSafely({
      labelAction: value,
      reportDataComputed: false,
    }, () => {
      UIUtils.pushHistoryURLWithParameterChanges(this.state, {
        labelAction: this.state.labelAction
      });
    });
  }

  handleShowCriticalOnlyChange(event) {
    this.setStateSafely({
      showCriticalOnly: event.target.checked,
      reportDataComputed: false,
    }, () => {
      this.computeRiskTablesData(false);
      UIUtils.pushHistoryURLWithNewParameter(this.state, "showCriticalOnly", this.state.showCriticalOnly);
    });
  }

  renderCompanyHeader() {
    return <CompanyHeader minified={this.isMinified()}
                          project={this.state.project}
                          extraPrintItems={this.extraPrintItems}
                          renderProcessSelector={true}
                          onProcessChange={this.handleProcessChange}
                          processId={this.state.processId}
                          title={this.state.title}
    />;
  }

  renderPage() {
    let riskTableTypes = RISK_TABLE_REPORTS_ENUM;
    const reportTypeOptions = Object.entries(riskTableTypes).map(([key, value]) => {
      return <option value={key} key={key}>{value}</option>;
    });

    reportTypeOptions.unshift(
      <option key="list" className="risk-tables-select-label" disabled>
        Lists:
      </option>);

    reportTypeOptions.splice(5, 0,
      <option key="input - output" className="risk-tables-select-label" disabled>
        Input-Output:
      </option>);

    const {
      showRawScore,
      labelAction, title,
      reportKey, reportDate, RMP, data,
      quickPanelKeyToInstanceMap,
      showCriticalOnly,
      orderedUOList,
      steps,
      selectedUOId,
    } = this.state;
    const reportTitle = riskTableTypes[reportKey];
    const currentReport = RISK_TABLE_REPORTS_ENUM[reportKey];

    let unitOperationOptions = null;
    if (selectedUOId) {
      const unitOperations = orderedUOList ? [ALL_UOS_OPTION, ...orderedUOList] : [EMPTY_UOS_OPTION];
      unitOperationOptions = unitOperations.map(uo =>
        <option value={uo.id} key={uo.id}>{uo.id === ALL_UOS_OPTION.id ? uo.name : UIUtils.getRecordLabelForDisplay("UO", uo.id, uo.name)}</option>);
    }

    let reportSettings = CommonRiskTable.getReportSettings(reportKey);
    return (
      <div className="container">
        <Heading level={1}
                 className="header-banner"
        >
          <span>{title}</span>
          <InfoTooltip id="infoReports"
                       verbiage={
                         (<div>
                           The risk tables report should help provide insight into the risk implications between
                           different components of the manufacturing process.
                           {!this.hideOptions ?
                             " Use the risk table drop down to view risk associations between the specified components."
                             : ""}
                         </div>)
                       }
          />
        </Heading>
        <ErrorBar />
        <div className="row row-white shadow">
          <div className="col-sm-12">
            <div className="row">
              <div className="attribute-container col-sm-4">
                {!this.hideOptions ?
                  (<Fragment>
                    <label id={"riskTableReportSelectLabel"}
                           htmlFor="riskTableReportSelect"
                           className="col-form-label base-attribute"
                    >
                      Risk Table:
                    </label>
                    <div className="form-group">
                      <select className="form-control"
                              id="riskTableReportSelect"
                              value={reportKey}
                              onChange={this.handleReportChange}
                      >
                        {reportTypeOptions}
                      </select>
                    </div>
                  </Fragment>) : ""}
              </div>
              <div className="attribute-container col-sm-2">
                {!this.hideOptions ?
                  (<Fragment>
                    <label id={"riskTableReportDateLabel"}
                           htmlFor="riskTableReportDatePicker"
                           className="col-form-label base-attribute"
                    >
                      Date:
                    </label>
                    <div className="form-group">
                      <DatePicker id="riskTableReportDatePicker"
                                  selected={reportDate ? moment(reportDate).toDate() : null}
                                  maxDate={new Date()}
                                  ref={datePicker => this.datePicker = datePicker}
                                  onChange={this.handleReportDateChange}
                      />
                    </div>
                  </Fragment>) : ""}
              </div>
              <div className="attribute-container col-sm-2">
                {!this.hideOptions ?
                  (<Fragment>
                    <label id={"riskTableEffectiveRMPLabel"}
                           htmlFor="riskTableEffectiveRMP"
                           className="col-form-label base-attribute"
                    >
                      Effective RMP:
                    </label>
                    {RMP ?
                      (<div className="form-group">
                        <a href={getURLByTypeCodeAndIdAndVersionId("RMP", "View", RMP.RMPId, false, RMP.id)}
                           id="riskTableEffectiveRMP"
                           rel="noopener noreferrer"
                           target="_blank"
                        >
                          {`${RMP.name} V${RMP.majorVersion}.${RMP.minorVersion}`}
                        </a>
                      </div>) : ""}
                  </Fragment>) : ""}
              </div>
              <div className="attribute-container col-sm-4">
                <div className="btn-group btn-split data-btn-group">
                  <button className="btn btn-report btn-lg btn-primary"
                          id="riskTablesExportButton"
                          onClick={this.exportToExcel}
                  >
                    <FontAwesomeIcon icon={faFileExport} /> Export to Excel
                  </button>
                </div>
                <div className="btn-group btn-split data-btn-group">
                  <button className="btn btn-report btn-lg btn-primary"
                          id="riskTablesPrintButton"
                          onClick={this.handlePrint}
                  >
                    <FontAwesomeIcon icon={faPrint} /> Print
                  </button>
                </div>
              </div>
            </div>
            {CommonRiskTable.allowSelectUO(reportKey) ? (
              <div className="row">
                <div className="attribute-container col-sm-6">
                  {!this.hideOptions ?
                    (<Fragment>
                      <label id={"riskTableReportUOSelectLabel"}
                             htmlFor="riskTableReportSelect"
                             className="control-label base-attribute"
                      >
                        Unit Operation:
                      </label>
                      <div className={"form-group" + (this.state.isLoadingUOTypeahead ? " skeleton" : "")}>
                        <select className="form-control"
                                id="riskTableReportUOSelect"
                                value={selectedUOId}
                                onChange={this.handleUOChange}
                        >
                          {unitOperationOptions}
                        </select>
                      </div>
                    </Fragment>) : ""}
                </div>
              </div>
            ) : ""}
            <div className="row">
              <RiskTablesFilter
                id="riskTableReport"
                showRawScore={showRawScore}
                labelAction={labelAction}
                reportSettings={reportSettings}
                onRiskValueTypeChange={this.handleRiskScoreTypeChange}
                onRiskLabelTypeChange={this.handleShowRiskLabelChange}
                OnShowCriticalOnlyChange={this.handleShowCriticalOnlyChange}
                showCriticalOnlyChecked={showCriticalOnly}
                showCriticalOnlyFilter={true}
              />
            </div>
            <div className="row col-sm-12">
              <h1 className="risk-tables-report-header">{reportTitle}</h1>
              <span className="risk-tables-report-header-date-label">As of </span>
              <span id="riskTableAsOfDate"
                    className="risk-tables-report-header-date"
              >
                {reportDate ? this.getDateForDisplayToUser() : ""}
              </span>
            </div>
            <RiskTables report={currentReport}
                        reportKey={reportKey}
                        projectId={this.projectId}
                        data={data}
                        RMP={RMP}
                        showRawScore={showRawScore}
                        labelAction={labelAction}
                        quickPanelKeyToInstanceMap={quickPanelKeyToInstanceMap}
                        reportDate={reportDate}
                        reportSettings={reportSettings}
                        selectedUOId={selectedUOId}
                        unitOperations={orderedUOList}
                        steps={steps}
            />
          </div>
        </div>
      </div>
    );
  }
}

RiskTablesReportPage.defaultProps = {
  minified: true,
};
