import { parseColor as parse, Color } from '@progress/kendo-drawing';
import { isPresent, fitIntoBounds } from './misc';
/**
 * @hidden
 *
 * Returns the hex or RGBA string representation of the color.
 */
export var parseColor = function (value, format, safe) {
    if (safe === void 0) { safe = true; }
    var allowedFormats = ['hex', 'rgba']; // TODO: constant?
    // Angular supports third type: name : const allowedFormats: Array<string> = ['hex', 'rgba', 'name'];
    if (allowedFormats.indexOf(format) === -1) {
        throw new Error("Unsupported color output format '".concat(format, "'. The available options are 'hex' or 'rgba'."));
    }
    if (!isPresent(value)) {
        return;
    }
    var parsedColor = parse(value.trim(), safe);
    if (!isPresent(parsedColor)) {
        return;
    }
    return format === 'hex' ? parsedColor.toCss() : parsedColor.toCssRgba();
};
/**
 * @hidden
 *
 * Returns an HSV object representation of the color string.
 */
export var getHSV = function (value, safe) {
    if (safe === void 0) { safe = true; }
    var parsed = parse(value, safe);
    if (!isPresent(parsed)) {
        return {};
    }
    return parsed.toHSV();
};
/**
 * @hidden
 *
 * Returns an RGBA object representation of the color string.
 */
export var getRGBA = function (value, safe) {
    if (safe === void 0) { safe = true; }
    var parsed = parse(value, safe);
    if (!isPresent(parsed)) {
        return {};
    }
    return parsed.toBytes();
};
/**
 * @hidden
 *
 * Returns the RGBA string representation of the color.
 */
export var getColorFromHSV = function (hsva) {
    var hue = fitIntoBounds(hsva.h, 0, 359.9);
    var saturation = fitIntoBounds(hsva.s, 0, 1);
    var value = fitIntoBounds(hsva.v, 0, 1);
    var alpha = fitIntoBounds(hsva.a, 0, 1);
    return Color.fromHSV(hue, saturation, value, alpha).toCssRgba();
};
/**
 * @hidden
 *
 * Returns the RGBA string representation of the color based on the `hue` and
 * assuming the `value`, `saturation`, and `alpha` have a value of `1`.
 */
export var getColorFromHue = function (hue) {
    return getColorFromHSV({ h: hue, s: 1, v: 1, a: 1 });
};
/**
 * @hidden
 *
 * Returns the RGBA string representation of the color.
 */
export var getColorFromRGBA = function (rgba) {
    var red = fitIntoBounds(rgba.r, 0, 255);
    var green = fitIntoBounds(rgba.g, 0, 255);
    var blue = fitIntoBounds(rgba.b, 0, 255);
    var alpha = fitIntoBounds(rgba.a, 0, 1);
    return Color.fromBytes(red, green, blue, alpha).toCssRgba();
};
/**
 * @hidden
 *
 * Returns the RGB object representation of the color based on the background color.
 */
export var getRGBFromRGBA = function (foregroundColor, backgroundColor) {
    var r1 = fitIntoBounds(foregroundColor.r, 0, 255);
    var g1 = fitIntoBounds(foregroundColor.g, 0, 255);
    var b1 = fitIntoBounds(foregroundColor.b, 0, 255);
    var a1 = fitIntoBounds(foregroundColor.a, 0, 1);
    var r2 = fitIntoBounds(backgroundColor.r, 0, 255);
    var g2 = fitIntoBounds(backgroundColor.g, 0, 255);
    var b2 = fitIntoBounds(backgroundColor.b, 0, 255);
    return {
        r: Math.round(((1 - a1) * r2) + (a1 * r1)),
        g: Math.round(((1 - a1) * g2) + (a1 * g1)),
        b: Math.round(((1 - a1) * b2) + (a1 * b1))
    };
};
/**
 * @hidden
 *
 * Returns the relative luminance.
 */
export var getLuminance = function (rgb) {
    var a = [rgb.r || 0, rgb.g || 0, rgb.b || 0].map(function (v) {
        v /= 255;
        return v <= 0.03928
            ? v / 12.92
            : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};
/**
 * @hidden
 *
 * Returns the color contrast.
 */
export var getContrast = function (luminance1, luminance2) {
    var brightest = Math.max(luminance1, luminance2);
    var darkest = Math.min(luminance1, luminance2);
    return (brightest + 0.05)
        / (darkest + 0.05);
};
/**
 * @hidden
 *
 * Returns the color contrast from two RGBA colors.
 */
export var getContrastFromTwoRGBAs = function (a, b) {
    return getContrast(getLuminance(getRGBFromRGBA(a, b)), getLuminance(getRGBFromRGBA(b, { r: 0, g: 0, b: 0, a: 1 })));
};
