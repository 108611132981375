"use strict";

import TypeaheadObjectCache from "./typeahead_object_cache";
import MultipleTypeaheadObjectCache from "./multiple_typeahead_object_cache";

/**
 * This class is responsible for properly creating a TypeaheadObjectCache, but only if the conditions are right.
 */
export default class TypeaheadObjectCacheFactory {
  /**
   * Create the TypeaheadObjectCache if enough information is available.
   *
   * @param type {string} The type that the TypeaheadCache should be (ie. "Project" or "FQA")
   * @param [getProjectIdFunction] {function} A method that should be called to get the project Id, if it's available, and only if the type needs it.
   * @param [getProcessIdFunction] {function} A method that should be called to get the process Id, if it's available, and only if the type needs it.
   * @return {TypeaheadObjectCache|null} A TypeaheadObjectCache that's properly initialized, or null if there's not enough
   * information available yet to create it properly.
   */
  static createTypeaheadObjectCacheIfPossible(type, getProjectIdFunction, getProcessIdFunction) {
    const projectId = getProjectIdFunction && getProjectIdFunction();
    const processId = getProcessIdFunction && getProcessIdFunction();
    let canBeCreated = this._canTypeaheadObjectCacheBeCreated(projectId, processId, type);

    if (canBeCreated) {
      return new TypeaheadObjectCache(type, projectId, processId);
    } else {
      return null;
    }
  }

  /**
   * Create the MultipleTypeaheadObjectCache if enough information is available for all the types provided.
   *
   * @param types {[string]} An array of types that the MultiTypeaheadCache should be (ie. ["Project", "FQA"])
   * @param [getProjectIdFunction] {function} A method that should be called to get the project Id, if it's available, and only if the type needs it.
   * @param [getProcessIdFunction] {function} A method that should be called to get the process Id, if it's available, and only if the type needs it.
   * @return {TypeaheadObjectCache|null} A TypeaheadObjectCache that's properly initialized, or null if there's not enough
   * information available yet to create it properly.
   */
  static createMultipleTypeaheadObjectCacheIfPossible(types, getProjectIdFunction, getProcessIdFunction) {
    let canAllBeCreated = true;
    const projectId = getProcessIdFunction && getProjectIdFunction();
    const processId = getProcessIdFunction && getProcessIdFunction();
    for (const type of types) {
      canAllBeCreated = canAllBeCreated && this._canTypeaheadObjectCacheBeCreated(projectId, processId, type);
    }

    if (canAllBeCreated) {
      return new MultipleTypeaheadObjectCache(types, projectId, processId);
    } else {
      return null;
    }
  }

  /**
   * Creates a MultipleTypeaheadObjectCache for any of the provided types where enough information is available
   * @param types
   * @param getProjectIdFunction
   * @param getProcessIdFunction
   */
  static createAllTypeaheadObjectsPossible(types, getProjectIdFunction, getProcessIdFunction) {
    const projectId = getProcessIdFunction && getProjectIdFunction();
    const processId = getProcessIdFunction && getProcessIdFunction();
    const eligibleTypes = types.filter(type => this._canTypeaheadObjectCacheBeCreated(projectId, processId, type));

    if (eligibleTypes.length > 0) {
      return new MultipleTypeaheadObjectCache(eligibleTypes, projectId, processId);
    } else {
      return null;
    }
  }

  static _canTypeaheadObjectCacheBeCreated(projectId, processId, type) {
    let canBeCreated = false;
    if (type) {
      const canTypeIgnoreProjectId = TypeaheadObjectCache.canTypeIgnoreProjectId(type);
      const canTypeIgnoreProcessId = TypeaheadObjectCache.canTypeIgnoreProcessId(type);

      if (canTypeIgnoreProjectId) {
        canBeCreated = true;
      } else {
        if (canTypeIgnoreProcessId && projectId) {
          canBeCreated = true;
        } else if (!canTypeIgnoreProcessId && projectId) {
          if (processId) {
            canBeCreated = true;
          }
        }
      }
    }
    return canBeCreated;
  }
}
