import React from "react";

/**
 * @param id {string}
 * @param label {string}
 * @param name {string}
 * @param [checked] {boolean}
 * @param [onChange] {() => void}
 * @param disabled {boolean}
 * @returns {JSX.Element}
 */
const RadioButton = ({id, label, name, checked, onChange, disabled}) => {
  return (
    <label>
      <input
        id={id}
        type="radio"
        name={name}
        disabled={disabled}
        checked={checked}
        value={id}
        {...onChange && {onChange}}
      />
      {label}
    </label>
  );
};

export default RadioButton;
