"use strict";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { SMART_IMPORT_UPLOAD_STATUS } from "../../../../importWizardSteps/import_smart_upload_step";
import BaseReactComponent from "../../../../../base_react_component";

/**
 * This shows the progress of the smart upload in the Smart Upload progress popup
 */
export default class SmartUploadProgressPanel extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {progressStatus, textractMessage} = this.props;
    let allStatuses = Object.values(SMART_IMPORT_UPLOAD_STATUS);
    let uploadingFile = progressStatus === SMART_IMPORT_UPLOAD_STATUS.UPLOADING_FILE;
    let readingFile = progressStatus === SMART_IMPORT_UPLOAD_STATUS.PROCESSING_FILE;
    let extractingText = progressStatus === SMART_IMPORT_UPLOAD_STATUS.EXTRACTING_TEXT;
    let shouldShowFileInProcess = allStatuses.indexOf(progressStatus) >= allStatuses.indexOf(SMART_IMPORT_UPLOAD_STATUS.PROCESSING_FILE);
    let shouldShowExtractInProcess = allStatuses.indexOf(progressStatus) >= allStatuses.indexOf(SMART_IMPORT_UPLOAD_STATUS.EXTRACTING_TEXT);

    return (
      <div className="smart-upload-progress-status-panel">
        <div className="row">
          <div id="fileUploadStatusDiv"
               className="col-sm-12"
          >
            <FontAwesomeIcon className={"smart-upload-progress-status-icon" + (uploadingFile ? " fa-pulse" : "")}
                             icon={uploadingFile ? faSpinner : faCheckCircle}
            />
            <span id="fileUploadStatusText"
                  className="smart-upload-progress-status-label"
            >{uploadingFile ? "Uploading file..." : "Upload"}</span>
          </div>
        </div>
        <div className="row">
          <div id="fileReadStatusDiv"
               className="col-sm-12 smart-upload-progress-status-row"
          >
            {shouldShowFileInProcess ? (
              <FontAwesomeIcon className={"smart-upload-progress-status-icon" + (readingFile ? " fa-pulse" : "")}
                               icon={readingFile ? faSpinner : faCheckCircle}
              />
            ) : ""}
            <span id="fileReadStatusText"
                  className={"smart-upload-progress-status-label" + (!shouldShowFileInProcess ? " idle" : "")}
            >
              {!shouldShowFileInProcess || readingFile ? "Reading file..." : "Read"}
            </span>
          </div>
        </div>
        <div className="row">
          <div id="textExtractStatusDiv"
               className="col-sm-12 smart-upload-progress-status-row"
          >
            {shouldShowExtractInProcess ? (
              <FontAwesomeIcon className={"smart-upload-progress-status-icon" + (extractingText ? " fa-pulse" : "")}
                               icon={extractingText ? faSpinner : faCheckCircle}
              />
            ) : ""}
            <span id="extractTextStatusText"
                  className={"smart-upload-progress-status-label" + (!shouldShowExtractInProcess ? " idle" : "")}
            >
              {!shouldShowExtractInProcess ? "Extracting text..." : extractingText ? textractMessage : "Extract"}
            </span>
          </div>
        </div>
      </div>
    );
  }
}
