"use strict";

import * as WebSocketsWrapper from "../../utils/websockets/websockets_wrapper";
import { WebSocketsMessageHandler } from "../web_sockets_message_handler";
import { StatusDisplayService } from "../status_display_service";

/**
 * @typedef IConnectionContext {Object}
 * @property {Function} resolve
 * @property {Function} reject
 * @property {WebSocketsLibraryWrapper} client
 * @property {IEditablesServiceParams} parameters
 * @property {[]} data
 * @property {*} payload
 * @property {boolean} hasResult Indicates whether or not the websocket received results.
 * @property {boolean} isDone Indicates whether the websocket received a DONE message
 * @property {number} finalizeAttempt
 * @property {Error|*} error
 */

/**
 * This is a proxy class for establishing a WebSockets connection with the backend.
 */
export class WebSocketsServiceProxy {
  constructor(messageHandler = null, statusServiceParam = null) {

    this.statusService = statusServiceParam || new StatusDisplayService();
    /**
     * Handles websocket messages
     * @type {WebSocketsMessageHandler}
     * @protected
     */
    this.messageHandler = messageHandler || new WebSocketsMessageHandler(this.statusService);

    this.clients = [];
  }

  /**
   * Connects to the backend by opening a WebSockets connection
   * @param payload The payload to send when the connection is established
   * @param parameters A map of params used in WebSockets message handlers.
   * @returns {Promise<unknown>}
   */
  connect(payload, parameters) {
    this.statusService.displayStatus("Connecting...");
    /**
     * @type {IConnectionContext} The context of the websockets connection
     */
    const context = {
      resolve: () => {},
      reject: () => {},
      client: null,
      parameters,
      payload,
      data: [],
      hasResult: false,
      finalizeAttempt: 0,
      error: null,
    };

    return new Promise((resolve, reject) => {
      context.resolve = resolve;
      context.reject = reject;

      /**
       * @type {IWebSocketEvents}
       */
      const events = {
        onOpen: (event) => this.messageHandler.handleOpen(event, context),
        onMessage: (event) => this.messageHandler.handleMessage(event, context),
        onError: (event) => this.messageHandler.handleError(event, context),
        onClose: (event) => this.messageHandler.handleClose(event, context),
        onReconnect: (event) => this.messageHandler.handleReconnect(event, context),
        onMaximum: (event) => this.messageHandler.handleMaximum(event, context),
      };

      context.client = WebSocketsWrapper.getClient(events);
      this.clients.push(context.client);
    });
  }

  /**
   * Forces all clients to disconnect and clears up the internal collection of clients.
   */
  disconnect() {
    for (let client of this.clients) {
      client.close();
    }
    this.clients = [];
  }
}

