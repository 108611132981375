var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { FONT_SIZE_CLASSES, FONT_WEIGHT_CLASSES, MARGIN_ENUM_CLASSES, MARGIN_ENUM_VALUES, MARGIN_SIDES_CLASSES, TEXT_ALIGN_CLASSES, TEXT_TRANSFORM_CLASSES, THEME_COLOR_CLASSES } from './constants';
/**
 * @hidden
 */
var generateTypography = function (element) {
    var component = React.forwardRef(function (props, ref) {
        var id = props.id, style = props.style, className = props.className, fontSize = props.fontSize, fontWeight = props.fontWeight, textAlign = props.textAlign, textTransform = props.textTransform, themeColor = props.themeColor, margin = props.margin, others = __rest(props, ["id", "style", "className", "fontSize", "fontWeight", "textAlign", "textTransform", "themeColor", "margin"]);
        var target = React.useRef(null);
        var elementRef = React.useRef(null);
        React.useImperativeHandle(target, function () { return ({
            element: elementRef.current,
            props: props
        }); });
        React.useImperativeHandle(ref, function () { return target.current; });
        var defaultClass = function () {
            var classMap = element === 'p' ? 'paragraph' : element;
            return "k-".concat(classMap);
        };
        var Component = element;
        var setMarginClassPerSide = function (marginSide, value) {
            var val = typeof value === 'string' ? MARGIN_ENUM_VALUES[value] : value;
            return "".concat(MARGIN_SIDES_CLASSES[marginSide]).concat(val);
        };
        var setMarginClasses = function () {
            if (margin === undefined) {
                return;
            }
            if (typeof margin === 'string') {
                return [MARGIN_ENUM_CLASSES[margin]];
            }
            // The the predefined Kendo classes are starting from 0 to 24.
            if (typeof margin === 'number' && margin >= 0 && margin <= 24) {
                return ["k-m-".concat(margin)];
            }
            if (typeof margin === 'object') {
                var marginClassesList_1 = [];
                Object.keys(margin).forEach(function (key) {
                    if (margin[key] !== null && margin[key] !== undefined) {
                        marginClassesList_1.push(setMarginClassPerSide(key, margin[key]));
                    }
                });
                var classes = marginClassesList_1.join(' ');
                return classes;
            }
        };
        var setTypographyClasses = function () {
            var typographyArray = [
                defaultClass(),
                FONT_SIZE_CLASSES[fontSize],
                FONT_WEIGHT_CLASSES[fontWeight],
                TEXT_ALIGN_CLASSES[textAlign],
                TEXT_TRANSFORM_CLASSES[textTransform],
                THEME_COLOR_CLASSES[themeColor],
                setMarginClasses(),
                className
            ];
            var typographyClasses = typographyArray.filter(function (n) { return n !== undefined; }).join(' ');
            return typographyClasses;
        };
        return (React.createElement(Component, __assign({ id: id, ref: elementRef, className: setTypographyClasses(), style: style }, others), props.children));
    });
    component.propTypes = {
        style: PropTypes.object,
        className: PropTypes.string,
        fontSize: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge']),
        fontWeight: PropTypes.oneOf(['light', 'normal', 'bold']),
        margin: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.object,
            PropTypes.oneOf(['xsmall', 'small', 'medium', 'large', 'xlarge', 'thin', 'hair'])
        ]),
        textAlign: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
        textTransform: PropTypes.oneOf(['lowercase', 'uppercase', 'capitalize']),
        themeColor: PropTypes.oneOf([
            'inherit', 'primary', 'secondary', 'tertiary',
            'info', 'success', 'error', 'warning',
            'dark', 'light', 'inverse'
        ])
    };
    component.displayName = "KendoReactTypography".concat(element.toUpperCase());
    return component;
};
/**
 * Represents [KendoReact Typography Heading1 component]({% slug overview_typography %}).
 *
 * @example
 * ```jsx
 * const App = () => {
 *   return (
 *       <Typography.h1>Heading 1</Typography.h1>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var h1 = generateTypography('h1');
/**
 * Represents [KendoReact Typography Heading2 component]({% slug overview_typography %}).
 *
 * @example
 * ```jsx
 * const App = () => {
 *   return (
 *       <Typography.h2>Heading 2</Typography.h2>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var h2 = generateTypography('h2');
/**
 * Represents [KendoReact Typography Heading3 component]({% slug overview_typography %}).
 *
 * @example
 * ```jsx
 * const App = () => {
 *   return (
 *       <Typography.h3>Heading 3</Typography.h3>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var h3 = generateTypography('h3');
/**
 * Represents [KendoReact Typography Heading4 component]({% slug overview_typography %}).
 *
 * @example
 * ```jsx
 * const App = () => {
 *   return (
 *       <Typography.h4>Heading 4</Typography.h4>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var h4 = generateTypography('h4');
/**
 * Represents [KendoReact Typography Heading5 component]({% slug overview_typography %}).
 *
 * @example
 * ```jsx
 * const App = () => {
 *   return (
 *       <Typography.h5>Heading 5</Typography.h5>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var h5 = generateTypography('h5');
/**
 * Represents [KendoReact Typography Heading6 component]({% slug overview_typography %}).
 *
 * @example
 * ```jsx
 * const App = () => {
 *   return (
 *       <Typography.h6>Heading 6</Typography.h6>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var h6 = generateTypography('h6');
/**
 * Represents [KendoReact Typography Paragraph component]({% slug overview_typography %}).
 *
 * @example
 * ```jsx
 * const App = () => {
 *   return (
 *       <Typography.p>I'm a paragraph text.</Typography.p>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var p = generateTypography('p');
/**
 * Represents [KendoReact Typography Code component]({% slug overview_typography %}).
 * Use to create a single-line code snippet.
 *
 * @example
 * ```jsx
 * const App = () => {
 *   return (
 *       <Typography.code>code</Typography.code>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var code = generateTypography('code');
/**
 * Represents [KendoReact Typography Pre component]({% slug overview_typography %}).
 * Used along with [KendoReact Typography Code component]({% slug overview_typography %}) to create multi-line code snippet.
 *
 * @example
 * ```jsx
 * const App = () => {
 *   return (
 *     <Typography.pre>
 *       <Typography.code dangerouslySetInnerHTML={{__html: `
 *         const handleChange = ({ target }) => {
 *           const { name, value } = target;
 *           const newData = Object.assign({}, data, { [name]: value });
 *           setData(newData);
 *         }
 *       `}} />
 *     </Typography.pre>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
var pre = generateTypography('pre');
/**
 * Represents the [KendoReact Typography component]({% slug overview_typography %}).
 *
 * @example
 * ```jsx
 * const App = () => {
 *   return (
 *       <Typography.h2>Heading 2</Typography.h2>
 *   );
 * };
 *    ReactDOM.render(<App />, document.querySelector('my-app'));
 * ```
 */
export var Typography = {
    h1: h1,
    h2: h2,
    h3: h3,
    h4: h4,
    h5: h5,
    h6: h6,
    p: p,
    code: code,
    pre: pre
};
