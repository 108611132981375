/* stylelint-disable-next-line import-notation */
@import "../../../css/brand_colors";

.editor-wrapper {
  flex: 1;
  width: 250px;
  overflow: hidden;

  :global(.k-editor) {
    height: 33.5px;
    border: 1px solid #ced4da;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;

    /* This length should be enough for a tag, so it will be in one line to make it feels like a normal input */
    width: 10000px;

    p {
      font-size: 1rem;
      margin-bottom: 0;
    }

    :global(.qbd-output) {
      background-image: url("../../../images/logo-128.png");
      background-size: 20px 20px;
      padding-left: 22px;
      background-position-x: left;
      background-position-y: bottom;
      background-repeat: no-repeat;
      border: 1px dashed $brand-highlight-color;
    }
  }

  :global(.k-editor-content > div[role="textbox"]) {
    padding: 0.375rem 0.75rem;
  }
}

.toggle-side-menu-button {
  /* To make it has the same height with other inputs */
  height: 33.5px;
  box-shadow: none;
  border-color: #ced4da;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;

  &:active, &:hover {
    box-shadow: none !important;
    border-color: #ced4da !important;
    background-color: $brand-white !important;
  }
}

:global(.links-manage):has(.editor-wrapper) {
  max-width: 100%;
}
