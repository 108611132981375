"use strict";

import React from "react";
import BasePopup from "../../editor/approval/base_popup.jsx";

export default class ResetMFAPopup extends BasePopup {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="modal fade" ref={this.setModalRef}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title">Reset MFA</h1>
              <button type="button" className="close" onClick={this.handleCancel} aria-label="Close">
                <span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
              <div className="modal-container">
                <div className="row">
                  <div className="col-sm-12">
                    Are you sure you want to reset this user&apos;s authenticator based MFA?
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="modal-container">
                <div className="btn-group">
                  <button id="resetButtonOnPopup" type="button" className="btn btn-primary"
                          onClick={this.props.onMfaReset}
                  >
                    Reset
                  </button>
                  <button id="cancelButton" type="button" className="btn btn-secondary"
                          onClick={this.handleCancel} data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
