"use strict";

import { EMPTY_UNIT_OPERATION, NONE, SpecificationTransformStrategy } from "./specification_transform_strategy";

/**
 * This class is parent for the specification strategies that show variables like MAs/PPs.
 * @abstract
 */
export class VariableSpecificationTransformStrategy extends SpecificationTransformStrategy {
  constructor(parentIdAttribute, parentRecordType) {
    super();
    this.entitiesToMerge = new Set(["mas", "pps"]);
    this.entitiesToKeep = new Set(["uos", parentRecordType]);
    this.parentIdAttribute = parentIdAttribute;
    this.parentRecordType = parentRecordType;

    this.sortAttributes = this.sortAttributes.bind(this);
  }

  sortRecords(records) {
    return records; // Records are sorted in the wrapUp method.
  }

  fillGeneralInformation(record, requirement) {
    record.modelType = requirement.modelType;
    record.typeCode = requirement.modelType;

    if (requirement.ProcessParameterId) {
      record.id = Number(requirement.ProcessParameterId);
      record.unitOperationId = requirement.UnitOperationId ? Number(requirement.UnitOperationId) : -1;
    }

    if (requirement.MaterialAttributeId) {
      record.id = Number(requirement.MaterialAttributeId);
      record.unitOperationId = requirement.UnitOperationId ? Number(requirement.UnitOperationId) : -1;
    }

    if (requirement.modelType === "UO") {
      record.id = Number(requirement.UnitOperationId);
      record.currentState = requirement.currentState;
      record.PreviousUnitId = requirement.UnitOperation && requirement.UnitOperation.PreviousUnitId
        ? Number(requirement.UnitOperation.PreviousUnitId)
        : null;
    }
  }

  fillAcceptanceCriteria() {
    // does not get changed in this report
  }

  fillControlInformation() {
    // does not get changed in this report
  }

  mergeEntities(instances) {
    const entitiesToProcess = [];
    // merges the entities to be processed (MA/PP) into a single array
    for (let [key, entity] of Object.entries(instances)) {
      if (entity && entity.constructor === Array) {
        if (this.entitiesToMerge.has(key)) {
          entitiesToProcess.push(...entity);
        }
      }

      if (!this.entitiesToKeep.has(key)) {
        // if the entity is an array, we delete it.
        delete instances[key];
      }
    }
    return entitiesToProcess;
  }

  getFilteredRecordTypes() {
    return [
      "mas",
      "pps",
      this.parentRecordType,
      "uos",
      "attributes",
    ];
  }

  /**
   * This method takes MT/PRCs and duplicates the ones that are in multiple unit operations, so there's an item in the
   * array for every MT/PRC to UO combo.
   * @param parents
   * @return {[]}
   */
  flattenParents(parents) {
    parents = parents || [];

    // Flatten all parents with more than 1 unit operation
    let flattenedParents = [];
    for (let material of parents) {
      if (Array.isArray(material.unitOperationId)) {
        for (let unitOperation of material.unitOperationId) {
          material.unitOperationId = unitOperation;
          flattenedParents.push(Object.assign({}, material));
        }
      } else {
        flattenedParents.push(material);
      }
    }
    return flattenedParents;
  }

  sortAttributes(attribute1, attribute2) {
    let result = 0;
    if (attribute1.unitOperationId < attribute2.unitOperationId) {
      result = -1;
    } else if (attribute1.unitOperationId > attribute2.unitOperationId) {
      result = 1;
    } else if (attribute1[this.parentIdAttribute] < attribute2[this.parentIdAttribute]) {
      result = -1;
    } else if (attribute1[this.parentIdAttribute] > attribute2[this.parentIdAttribute]) {
      result = 1;
    } else if (attribute1.modelType < attribute2.modelType) {
      result = -1;
    } else if (attribute1.modelType > attribute2.modelType) {
      result = 1;
    } else if (attribute1.id < attribute2.id) {
      result = -1;
    } else if (attribute1.id > attribute2.id) {
      result = 1;
    }
    return result;
  }

  /**
   * Sets the unit operation value on the current attribute
   * @param attribute
   * @param context
   */
  setUnitOperation(attribute, context) {
    const {parent, knownUOs, instances, unitOperations} = context;
    attribute.unitOperationId = parent.unitOperationId;
    if (!knownUOs.has(attribute.unitOperationId)) {

      const uos = instances.uos || [];
      const unitOperation = attribute.unitOperationId === -1 ?
        EMPTY_UNIT_OPERATION : uos.find(uo => uo.id === attribute.unitOperationId);

      if (unitOperation) {
        unitOperations.push(unitOperation);
        knownUOs.add(attribute.unitOperationId);
      }
    }
  }

  createAttribute() {
    return {
      [this.parentIdAttribute]: 0,
      id: 0,
      modelType: "",
      name: NONE,
      riskLabel: NONE,
      LSL: NONE,
      target: NONE,
      USL: NONE,
      targetJustification: NONE,
      controlStrategy: NONE,
      controlMethods: NONE,
      controlStrategyJustification: NONE,
      showData: true,
      draftMarker: "",
      majorVersion: 0,
      minorVersion: 0,
    };
  }
}
