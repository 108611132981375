/**
 * The util to locate all the headings and set page number for table of content
 * item
 */
import pdfJs from "pdfjs-dist";
import {PDFExporter} from "../exporter/pdf_exporter";
import * as PdfJsFacade from "../../../import/widgets/smartUpload/ocrWidget/pdfjs_facade";

export async function setPageNumber(node: HTMLElement) {
  const tableOfContentItems = Array.from(
    document.querySelectorAll(".table-of-content-item"),
  );
  if (!tableOfContentItems.length) {
    return node;
  }

  const dataUri = await PDFExporter.getDataUri(node, false);
  if (!dataUri) {
    return node;
  }

  const numberOfPages = await PdfJsFacade.getNumberOfPages(dataUri);
  const pdf = await pdfJs.getDocument(dataUri).promise;
  let currentPage = 1;
  for (const item of tableOfContentItems) {
    const linkElement = item.querySelector(".table-of-content-link");
    const id = linkElement.getAttribute("href").replace("#", "");
    const targetHeader = document.getElementById(id);
    // Remove the temporary link that we set in TableOfContentsParser.
    // We need to have this temporary link to have annotations, so we
    // can use it to find the page number.
    if (targetHeader && targetHeader.querySelector("a")) {
      targetHeader.removeChild(targetHeader.querySelector("a"));
    }
    const pageElement = item.querySelector<HTMLDivElement>(".table-of-content-page-number");
    const pageNumber = await findPageNumber(
      pdf,
      currentPage,
      numberOfPages,
      id,
    );
    currentPage = pageNumber;
    pageElement.innerText = `${pageNumber}`;
  }

  return node;
}

async function findPageNumber(pdf, currentPage: number, numberOfPages: number, targetId: string) {
  const linkRegex = /\*{2}(?:[\w\d]+-?)+\*{2}/;

  while (currentPage <= numberOfPages) {
    const page = await pdf.getPage(currentPage);
    const annotations = await page.getAnnotations();
    for (const annotation of annotations) {
      const matches = annotation.url.match(linkRegex);
      if (matches) {
        const annotationId = matches[0].replaceAll("*", "");
        if (annotationId === targetId) {
          return currentPage;
        }
      }
    }
    currentPage++;
  }

  return currentPage;
}
