"use strict";

import { EDITOR_COMPONENT_MAP } from "./editor_config";

/**
 * This class is responsible for finding the correct editor for a model.
 */
export class EditorFactory {
  /**
   * Retrieves an editor {@link React.Component} that can edit models with the specified type code.
   * @param typeCode {string}
   * @returns {React.Component}
   */
  fromTypeCode(typeCode) {
    // noinspection JSValidateTypes
    return EDITOR_COMPONENT_MAP.get(typeCode);
  }
}