"use strict";

import { REPORT_OPTIONS_ENUM, REPORT_TYPES_ENUM } from "../constants/report_constants";
import React from "react";
import { Highlighter } from "react-bootstrap-typeahead";

/**
 * This function overwrites the original way table below chart appears,
 * it makes it toggle instead of only being visible without the ability
 * to make it disappear. Original method is borrowed from this link
 * https://github.com/highcharts/export-csv/issues/102
 */
export function overwriteHighChartsViewTableDefinition() {
  window.HighCharts.Chart.prototype.viewData = function() {
    if (!this.insertedTable) {
      let div = document.createElement("div");
      div.className = "highcharts-data-table";
      // Insert after the chart container
      this.renderTo.parentNode.insertBefore(div, this.renderTo.nextSibling);
      div.innerHTML = this.getTable();
      this.insertedTable = true;
      div.id = this.container.id + "-data-table";
    } else {
      $("#" + this.container.id + "-data-table").toggle();
    }
  };
}

export function getReportCharts(reportType) {
  return REPORT_OPTIONS_ENUM[reportType].charts;
}

/**
 * This function returns needed within capability information
 * @param withinIndices calculated within indices
 * @returns {*}
 */
export function renderWithinIndices(withinIndices) {
  return (
    <div className="col-12 col-md-3">
      <div id="withinIndicesDiv" className="capability-plot-info">
        <div className="capability-plot-info-title">Within</div>
        σ̃: {withinIndices.stdWithin}<br />
        Cp: {withinIndices.cp}<br />
        Cpl: {withinIndices.cpl}<br />
        Cpu: {withinIndices.cpu}<br />
        Cpk: {withinIndices.cpk}<br />
        Cpm: {withinIndices.cpm}
      </div>
    </div>);
}

/**
 * This function returns needed overall capability information
 * @param overallIndices calculated overall indices
 * @returns {*}
 */
export function renderOverallIndices(overallIndices) {
  return (
    <div className="col-12 col-md-3">
      <div id="overallIndicesDiv" className="capability-plot-info">
        <div className="capability-plot-info-title">Overall</div>
        σ: {overallIndices.stdOverall}<br />
        Pp: {overallIndices.pp}<br />
        Ppl: {overallIndices.ppl}<br />
        Ppu: {overallIndices.ppu}<br />
        Ppk: {overallIndices.ppk}
      </div>
    </div>);
}

function requirementHasSufficientData(requirement) {
  return requirement.subgroupsCount >= 2;
}

export function renderTypeaheadItems(requirement, menuProps) {
  return [
    <div key="label" className={((requirement.reportType === REPORT_TYPES_ENUM.ProcessCapabilityDashboard ||
      requirement.reportType === REPORT_TYPES_ENUM.ControlChartsContinuousReport ||
      requirement.reportType === REPORT_TYPES_ENUM.ControlChartsDefectivesReport)) && !requirementHasSufficientData(requirement) ?
      "pcm-typeahead-marker" : ""}
    >
      <Highlighter search={menuProps.text}>
        {requirement.label}
      </Highlighter>
    </div>
  ];
}
