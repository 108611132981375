var PROPS_COUNT_LIMIT = 50000;
/**
 * @hidden
 */
// An alternative is to use array.sort() and
// implement a binary search function.
var SortedPublicItemIds = /** @class */ (function () {
    function SortedPublicItemIds() {
        this.objects = [];
    }
    SortedPublicItemIds.prototype.init = function (publicItemIds) {
        var currentObj;
        for (var i = 0; i < publicItemIds.length; i++) {
            if (i % PROPS_COUNT_LIMIT === 0) {
                currentObj = {};
                this.objects.push(currentObj);
            }
            currentObj[publicItemIds[i]] = true;
        }
    };
    SortedPublicItemIds.prototype.hasId = function (publicItemId) {
        return this.objects.some(function (obj) { return obj[publicItemId]; });
    };
    return SortedPublicItemIds;
}());
export default SortedPublicItemIds;
