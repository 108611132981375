/*
- We should start using css-module to have scoped css and avoid loading unused css
- simply name your file *-module.css then import only classes that you need from the file and the bundler will do the rest
- You can read more abut it here: https://css-tricks.com/css-modules-part-1-need/
*/
.assessment-mode-selector-class {
  border-bottom: solid thin #dbe1e4;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.assessment-mode-selector-row {
  display: flex;
}
