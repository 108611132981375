"use strict";

import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpWideShort,
  faArrowDownWideShort
} from "@fortawesome/free-solid-svg-icons";
import ColumnOperationsSortAlphabetically, {
  sortedAsc,
  sortedDesc,
  sortIndex
} from "./column_operations_sort_alphabetically";
import { Fragment } from "react";

export default class ColumnOperationsSortByOrder extends ColumnOperationsSortAlphabetically {
  renderSortingOptions() {
    const {sort, column} = this.props;
    const currentSortIndex = sortIndex(column.field, sort);

    return (
      <Fragment>
        <div onClick={this.handleAscClick}
             className={`pb-1 k-columnmenu-item ${
               sortedAsc(currentSortIndex, sort) ? "k-state-selected" : ""
             }`}
        >
          <FontAwesomeIcon className="pr-1" icon={faArrowDownWideShort} /> <span>Order (First to Last)</span>
        </div>
        <div onClick={this.handleDescClick} className={`k-columnmenu-item  ${
          sortedDesc(currentSortIndex, sort) ? "k-state-selected" : ""}`}
        >
          <FontAwesomeIcon className="pr-1" icon={faArrowUpWideShort} /> <span>Order (Last to First)</span>
        </div>
      </Fragment>
    );
  }
}
