"use strict";

import React, { Fragment } from "react";
import { WIDGET_FIELDS } from "./constants/multi_documents_risk_links_attribute_constants";
import BaseRiskLinksAttribute from "./base_risk_links_attribute";
import { faFile, faLink, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import SupportDocumentsInfoTooltip from "../../widgets/tooltips/support_documents_info_tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EMPTY_STRING, RISK_TYPE_ENUM } from "../../helpers/constants/constants";
import * as UIUtils from "../../ui_utils";
import { Menu, MenuItem, Highlighter } from "react-bootstrap-typeahead";
import * as DocumentTransferHelper from "../../helpers/document_transfer_helper";
import { CRITICALITY_RULES } from "../../../server/common/misc/common_risk_utils";
export default class MultiDocumentsRiskLinksAttribute extends BaseRiskLinksAttribute {

  elementsWaitingForDiscovery = [];

  constructor(props) {
    super(props, WIDGET_FIELDS.filter(field => ((field.ignoredOnModels && !field.ignoredOnModels.includes(props?.modelName)) || !field.ignoredOnModels)));
    this.initializeStateObservers();
  }

  initializeStateObservers() {
    this.props.parent.addOnRiskLinksDeletedHandler((row) => {
      super.handleDelete(row);
    });
    this.props.parent.addOnRiskLinksEditedHandler(() => {
      this.forceUpdateSafely();
    });
    this.props.parent.addOnRiskDocumentUpdatedHandler(() => {
      this.forceUpdateSafely();
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      const {reload} = this.state;
      const nextReload = nextState.reload;
      return (nextReload !== reload)
        || (this.props.triggerChildUpdate !== nextProps.triggerChildUpdate);
    }
    return true;
  }

  handleDelete(rowData) {
    this.props.onDelete(rowData);
  }

  handleSave(rowData, rows, setValueCallback, errorReport = new Map()) {
    super.handleSave(rowData, rows, setValueCallback, errorReport);
    this.props.onSave(rowData);
  }

  initializeLink(editedRow) {
    editedRow.links = "[]";
  }

  /**
   * Updates the raw data row with file and attachment object information from the inline editor and the edited row attached to it
   * @param row The raw data to assign file & attachment information to
   * @param editedRow The EditedRow object being edited by the user
   */
  // eslint-disable-next-line no-unused-vars
  setFileData(row, editedRow) {
  }

  /**
   * Handles the edit even of a datatables row. This method will populate the edited row properties from the raw data row and will
   * display the inline editor
   * @param editedRow The edited row to populate with the row fields and values
   * @param row The raw data row for which the inline editor will be displayed
   */
  handleEditLink(editedRow, row) {
    let links = row.links;
    if (typeof links === "string") {
      links = JSON.parse(links);
    }
    editedRow.links = links;
  }

  setRowIds(rows) {
    for (let row of rows) {
      row.uuid = this.generateUUID(row);
    }
    return rows;
  }

  getManageColumn() {
    return {
      title: "",
      width: 1,
      class: "invisible-links-manage-column-header",
      data: result => result.index,
    };
  }

  getTypeheadInputProps(id) {
    return { className:"dropdown-with-risk-info", ...super.getTypeheadInputProps(id)};
  }

  getLabelKey(option) {
    return `${option.name} - ${option.typeCode}-${option.id}`;
  }

  renderMenu(options, menuProps) {
    const items = options.map((option, index) => {
      const className = index < (options.length - 1) ? "bottom-border" : "";

      const riskComponent = option && option.riskCriticalityStyle ? <Fragment>
        <div className={`list-table-risk-label list-table-risk-label-title-bar ${option.riskCriticalityStyle}`} />
        <span className="risk-label">{option.riskCriticalityLabel}</span>
      </Fragment> : "";

      const hasRiskInfo = option.riskCriticalityStyle;
      const hasParents = option.parents && option.parents.length > 0;
      const hasRiskInfoAndParents = hasRiskInfo && hasParents;

      const parentsComponent = option.parents ? <Fragment><div>{option.parents.map(parent => parent.name).join(" > ")}</div></Fragment> : "";

      const listComponent = (
        (hasRiskInfo || hasRiskInfoAndParents || hasParents) && <ul>
            {hasRiskInfo && (<li>{riskComponent}</li>)}
            {hasRiskInfoAndParents && (<li className="bullet"></li>)}
            {hasParents && (<li>{parentsComponent}</li>)}
          </ul>
      );

      return (
        <MenuItem className={"dropdown-item-with-criticality " + className} key={index} position={index} option={option}>
          <Highlighter search={menuProps.text}>
            {option.name}
          </Highlighter>
          <span className='identifier'>&nbsp;&nbsp;{option.typeCode}-{option.id}</span>
          {listComponent}
        </MenuItem>
      );
    });

    return <Menu {...menuProps}>{items}</Menu>;
  }

  renderLinkedTypeaheadLink(id, selectedOption, field, row) {

    const getLinkURL = (link) => {
      return link.fileName ? link.fileName : link.link;
    };

    const getFAIcon = (link) => {
      return link.fileName ? faPaperclip : faLink;
    };

    const attachClickToDownloadEvent = (anchorId, link) => {
      const element = $(`#${anchorId}`);
      if (element.length === 0) {
        setTimeout(() => {
          attachClickToDownloadEvent(anchorId, link);
        }, 1000);
      } else {
        element.on("click", (e) => {
          e.preventDefault();
          // this is necessary because tooltip is re-rendered when clicked
          setTimeout(() => {
            attachClickToDownloadEvent(anchorId, link);
          }, 1000);

          DocumentTransferHelper.handleFileDownload(link);
        });
      }
    };

    const getLinkAttributes = (link, idx) => {
      let anchorId = this.props.name + "_Link_" + idx;
      // this is necessary because we cannot use events in tooltip
      if (link.linkType === "Attachment" && !this.elementsWaitingForDiscovery.includes(link.uuid)) {
        this.elementsWaitingForDiscovery.push(link.uuid);
        attachClickToDownloadEvent(anchorId, link);
      }

      return link.fileName ?
        {
          id: anchorId,
          download: link.fileName,
          className: "links-table-link"
        } : {
          id: anchorId,
          href: UIUtils.cleanUpURL(link.link),
          rel: "noopener noreferrer",
          target: "_blank",
          className: "links-table-link",
        };
    };

    const color = "#859399";
    const links = (typeof row.links === "string") ? JSON.parse(row.links) : row.links;
    const linksSize = links?.length;

    const getRiskAndParentsInfo = (row) => {
      const sourceKey = this.props.modelName.toLowerCase() + "id";
      const key = Object.keys(row).find(rowKey => rowKey.toLowerCase() !== sourceKey &&
                                                  rowKey !== "id" &&
                                                  rowKey !== "uuid" &&
                                                  rowKey !== "parentRecordId" &&
                                                  rowKey !== "createdByUserId" &&
                                                  rowKey !== "updatedByUserId" &&
                                                  rowKey.toLowerCase().endsWith("id"));
      if (!key) {
        return null;
      }

      const rowTypeCode = key.substring(0, key.length - 2).replaceAll("Target", "");

      if (rowTypeCode === "" || !rowTypeCode) {
        return null;
      }

      return this.props.parent.getRiskAndParentsInfo(row, rowTypeCode, key);
    };

    const riskkInfo = getRiskAndParentsInfo(row);

    const riskComponent = riskkInfo?.riskCriticalityStyle ? <Fragment>
      <div className="risk-label-container">
        <div className={`list-table-risk-label list-table-risk-label-title-bar ${riskkInfo.riskCriticalityStyle}`} />
        <span className="risk-label">{riskkInfo.riskCriticalityLabel}</span>
      </div>
    </Fragment> : "";


    const parentsComponent = riskkInfo?.parents && riskkInfo?.parents?.length > 0 ? <Fragment><div className="hierarchy">{riskkInfo.parents.map(parent => parent.name).join(" > ")}</div></Fragment> : "";

    const shouldRenderTheTooltip = (links = []) => links
      .filter(
        link => link.uuid !== EMPTY_STRING && link.linkType !== EMPTY_STRING
      ).length > 0;

    return selectedOption ? (
      <div>
        <div>
          <a href={getURLByTypeCodeAndId(selectedOption.typeCode, "View", selectedOption.id)}
             rel="noopener noreferrer"
             target="_blank"
          >
            <span id={id} className='name'>{selectedOption.name}</span>
          </a>
          &nbsp;
          <span className='identifier'>{selectedOption.typeCode}-{selectedOption.id}</span>
          &nbsp;
          {shouldRenderTheTooltip(links) ?
            <SupportDocumentsInfoTooltip id={`linkedDocumentsInfo_${id}`}
                                         textDirection="bottom"
                                         icon={faFile}
                                         text={linksSize}
                                         verbiage={
                                           (<div className={"ml-1 p-2"}>
                                       <span id="tooltipLabel" className={"d-inline-block mb-2"}>
                                         Links or Attachments ({linksSize})
                                       </span>
                                             {
                                               links.map(
                                                 (link, idx) => <div key={idx}>
                                                   <FontAwesomeIcon icon={getFAIcon(link)} size={"sm"} color={color} className={"fa-align-middle ml-0 mr-1"} />
                                                   <span>
                                               <a
                                                 {...getLinkAttributes(link, idx)}
                                               >
                                                {getLinkURL(link)}
                                               </a>
                                             <span className={"ml-1 gray-color"}>{link.description}</span>
                                           </span>
                                                 </div>
                                               )
                                             }
                                           </div>)
                                         }
            /> : ""
          }
        </div>
        {riskComponent}
        {parentsComponent}
      </div>
    ) : "";
  }

  handleTypeaheadValueChanged(event, field, rowData) {
    let input = this.getTypeaheadInput(rowData, field);
    if (input && field?.autoComplete === true && this.isRowInEditMode(rowData.uuid)) {
      const value = input.value;
      let editedRow = this.getEditedRowByUUID(rowData.uuid);
      field.setValue(editedRow, value);
    }
  }

  getInstance() {

    const { riskLabel, riskColor, riskLinkWinners, riskRule } = this.getRiskLabelAndColor(this.props.parent.state.riskInfo);
    const { riskLabel: olderRiskLabel, riskColor: olderRiskColor } = this.props.parent.state.olderVersion ? this.getRiskLabelAndColor(this.props.parent.state.olderVersion.riskInfo) : {};

    return {
      maxCriticality: this.getMaxCriticality(),
      oldMaxCriticality: this.getOldMaxCriticality(),
      oldRowsForCriticalityCalculations: UIUtils.deepClone(this.getOldRowsForCriticalityCalculations()),
      isLoading: this.props.isLoading,
      rmpId: this.props.RMP?.id,
      parentId: this.props.parent?.id,
      riskLinkWinners,
      riskLabel: riskLabel,
      riskColor: riskColor,
      riskRule: riskRule,
      oldRiskLabel: olderRiskLabel,
      oldRiskColor: olderRiskColor
    };    
  }

  getRiskLabelAndColor(riskInfo) {
    let riskLabel = "";
    let riskColor = "";
    let riskLinkWinners = [];
    let riskRule = CRITICALITY_RULES.MAXIMUM;
    if (riskInfo) {
      const riskScale = riskInfo[RISK_TYPE_ENUM.CRITICALITY].scaleForRiskLabel;
      if (riskScale) {
        riskLabel = riskScale.riskLabel;
        riskColor = riskScale.color;
        riskLinkWinners = riskScale.riskLinkWinners;
        riskRule = riskScale.rule;
      }
    }

    return {riskLabel, riskColor, riskLinkWinners, riskRule};
  }
}
