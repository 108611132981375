"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import BaseQuickEditor from "../../editor/base_quick_editor";
import { TrainingService } from "../services/training_service";
import CommonUtils from "../../../server/common/generic/common_utils";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import MultipleTypeaheadObjectCache from "../../utils/cache/multiple_typeahead_object_cache";

const DOCUMENT_ATTRIBUTES_TO_PERSIST = new Set(["id", "documentid", "uuid"]);

/**
 * This is the base class for the Curriculum / ITP.
 *  @abstract
 */
export class TrainingBaseEditor extends BaseQuickEditor {

  constructor(props, baseTypeName, capitalizedBaseTypeName, displayName, editablesToLoad) {
    super(props, baseTypeName, capitalizedBaseTypeName, displayName);

    this.trainingService = new TrainingService();

    this.cacheRequestData = {
      showRemoved: false,
      includeChildren: true,
      allApproved: true,
      includeAllApprovedVersions: true,
      excludeArchived: true,
      includeLinks: true,
    };

    this.userCache = new TypeaheadObjectCache("User").loadOptions(this.handleUserLoad);

    this.setStateSafely({isLoadingTypeahead: true});
    this.cache = new MultipleTypeaheadObjectCache(editablesToLoad);
    this.cache.loadOptions(this.handleTypeaheadResults, this.cacheRequestData);

    this.setStateSafely(this.getInitialState());
  }


  async handleTypeaheadResults(results, typeCode) {
    const modelName = UIUtils.getModelNameForTypeCode(typeCode);
    await this.handleTrainingTypeaheadResults(`${modelName}Instances`, typeCode, results);
    this.setStateSafely({isLoadingTypeahead: false});
  }

  handleSaveResults(result) {
    new TypeaheadObjectCache(this.baseTypeName).invalidateCacheOptions();
    super.handleSaveResults(result);
  }

  getInitialState(state = {}) {
    const users = new TypeaheadObjectCache("User").getOptionsFromCacheIncludingArchived() || [];
    const usersMap = this.mapUsers(users);
    return {...state, users, usersMap};
  }

  cleanRecordBeforeSentToServer(record) {
    if (record) {
      const newRecord = UIUtils.deepClone(record);
      delete newRecord.DocumentInstances;
      delete newRecord.CurriculumInstances;
      delete newRecord.CurriculaFromDepartmentInstances;
      delete newRecord.currentDiffingVersion;
      delete newRecord.loggedInUser;
      delete newRecord.users;
      delete newRecord.ITPVersions;
      delete newRecord.trainingRecords;

      if (newRecord.Documents) {
        for (let document of newRecord.Documents) {
          for (let key of Object.keys(document)) {
            if (!DOCUMENT_ATTRIBUTES_TO_PERSIST.has(key.toLowerCase())) {
              delete document[key];
            }
          }
        }
      }

      return newRecord;
    }

    return super.cleanRecordBeforeSentToServer(record);
  }

  handleUserLoad(users) {
    const usersMap = this.mapUsers(users);
    this.setStateSafely({users, usersMap});
  }

  getTypeaheadDocuments() {
    const {DocumentInstances} = this.state;
    // ensures an item without id shows up in the end and that
    const result = [...(DocumentInstances || [])].map(item => (
      {
        ...item,
        sortField: this.getDocumentSortField(item),
      })
    );
    result.sort(CommonUtils.sortBy("sortField", "id"));
    return result;
  }

  getDocumentSortField(item) {
    return item.customId || item.customID || (item.id ? item.typeCode + "-" + (item.id).toString().padStart(5, "0") : "ZZZZZZZ");
  }

  getTypeaheadCurricula() {
    const {CurriculumInstances} = this.state;
    const result = [...(CurriculumInstances || [])].map(
      item => ({...item, sortField: this.getCurriculaSortField(item)})
    );

    result.sort(CommonUtils.sortBy("sortField"));
    return result;
  }

  getCurriculaSortField(item) {
    return item.id || item.CurriculumId || Number.MAX_SAFE_INTEGER;
  }

  async handleTrainingTypeaheadResults(stateEntryName, typeCode, results) {
    results = results || [];

    let itemsArray = results.map(item => {
      const result = {
        ...item,
        id: item.id,
        versionId: item.versionId ?? item.LastApprovedVersionId,
        typeCode,
      };
      return result;
    });
    itemsArray.sort(UIUtils.sortBy("versionId"));
    const newState = {
      [stateEntryName]: itemsArray,
    };
    await this.setStateSafelyAsync(newState);
  }

  shouldShowNav() {
    return false;
  }

  mapUsers(users) {
    let map = new Map();

    for (let user of users) {
      map.set(user.id, user);
    }
    return map;
  }
}
