"use strict";

import React from "react";
import BaseReactComponent from "../../base_react_component";
import * as UIUtils from "../../ui_utils";

export default class ConfigurableTablesColumnOperationsHandler extends BaseReactComponent {
  constructor(props) {
    super(props);
    this.parent = props.parent;

    const {columnOperationsAdapter} = props;
    this.adaptDateFilters = columnOperationsAdapter.adaptDateFilters;
    this.getDefaultSorting = columnOperationsAdapter.getDefaultSorting;
  }

  handleDefaultColumnOperationsFromURL(stateObject) {
    let filter = UIUtils.getParameterByName("filter", "?", window.location.search, false);
    filter = filter ? JSON.parse(filter) : null;

    let sort = UIUtils.getParameterByName("sort");
    sort = sort ? JSON.parse(sort) : null;

    let paging = UIUtils.getParameterByName("paging");
    paging = paging ? JSON.parse(paging) : {};

    if (filter) {
      stateObject.tableState = {
        filter: this.adaptDateFilters(filter),
      };
    }

    if (sort) {
      stateObject.tableState = stateObject.tableState ?
        {...stateObject.tableState, sort} :
        stateObject.tableState = {
          sort,
        };
    }

    if (paging && Number.isInteger(paging.skip) && Number.isInteger(paging.take)) {
      const skip = paging.skip;
      const take = paging.take;
      stateObject.tableState = stateObject.tableState ?
        {...stateObject.tableState, skip, take} :
        stateObject.tableState = {
          skip,
          take
        };
    }
  }

  handleColumnOperationsChange({dataState}) {
    const {parent} = this;
    let {visibleTableColumns} = parent.state;
    const filter = this.adaptDateFilters(dataState.filter, true);
    const {columnOperationsAdapter} = this.props;
    const customFilters = columnOperationsAdapter.extractCustomFilters(dataState, visibleTableColumns);
    const tableState = {
      ...dataState,
      filter,
      customFilters,
    };
    this.parent.setStateSafely({tableState});
  }

  handleClearAllFilters(resetSortingToDefault = false) {
    const {parent} = this;
    const {state} = parent;
    $(".filter-selected-entry").removeClass("filter-selected-entry");
    parent.setStateSafely({
      tableState: {
        sort: resetSortingToDefault ?
          this.getDefaultSorting(parent.state) :
          state.tableState ? state.tableState.sort : [],
        filter: {
          filters: [],
          logic: "and",
        },
        customFilters: null
      }
    });
  }

  handleColumnFilterCancelClick(field) {
    const {parent} = this;

    let {tableState} = parent.state;
    const {filter, customFilters} = tableState || {};
    const {filters} = filter || {};

    tableState = {
      ...tableState,
      filter: {
        filters: filters  .filter(parentFilter => parentFilter.filters.find(filter => filter.field !== field)),
        logic: "and",
      },
      customFilters: customFilters?.filter(filter => filter.field !== field)
    };

    parent.setStateSafely({tableState});
  }
}
