"use strict";

import React, { Fragment } from "react";
import * as I18NWrapper from "../../../i18n/i18n_wrapper";
import TechTransferReviewRow from "../widgets/tech_transfer_review_row";
import TechTransferReviewHeader from "../widgets/tech_transfer_review_header";
import TechTransferReviewBase from "../tech_transfer_review_base";

// i18next-extract-mark-ns-start tech_transfer
/**
 *  This class shows the review screen when a user is reviewing a tech transfer record.
 */
class TechTransferProcessComponentReview extends TechTransferReviewBase {
  renderDiff() {
    const {hideUnchangedFields} = this.state;
    const {reviewRecord, fromProcess, toProcess, onViewChangeCriteria,} = this.props;
    const transferredTo = reviewRecord;
    const transferredFrom = reviewRecord.techTransferredFrom || {};
    const {totalChangesCount, changeCriteria,} = reviewRecord;

    const commonProps = {
      hideUnchangedFields,
      transferredFrom,
      transferredTo,
      changeCriteria,
      totalChangesCount,
      onViewChangeCriteria,
    };

    const usePartialDiffCommonProps = {
      usePartialDiff: true,
      ...commonProps,
    };

    return (
      <Fragment>
        <TechTransferReviewHeader name="name"
                                  onHideUnchangedFields={this.handleHideUnchangedFields}
                                  receivingSite={fromProcess}
                                  sendingSite={toProcess}
        />

        <div className="row">
          <div className="col-12 tech-transfer-review-section-title-container">
            ABOUT
          </div>
        </div>
        <TechTransferReviewRow name="name" {...commonProps} />
        <TechTransferReviewRow name="unitOperations" {...commonProps} />
        <TechTransferReviewRow name="steps" {...commonProps} />
        <TechTransferReviewRow name="type" {...commonProps} />
        <TechTransferReviewRow name="function" {...usePartialDiffCommonProps} />
        <TechTransferReviewRow name="description" {...commonProps} />
        <TechTransferReviewRow name="certificateOfAnalysis"
                               displayName="COA/COC"
                               {...usePartialDiffCommonProps} />
        <TechTransferReviewRow name="links"
                               displayName="Source Documents"
                               isDocs={true}
                               {...usePartialDiffCommonProps} />

        <div className="row">
          <div className="col-12 tech-transfer-review-section-title-container">
            Component Qualification
          </div>
        </div>
        <TechTransferReviewRow name="supplier" {...commonProps} />
        <TechTransferReviewRow name="componentQualified" {...commonProps} />
        <TechTransferReviewRow name="effectiveDate" {...commonProps}
                               isDate={true} />
        <TechTransferReviewRow name="expirationDate" {...commonProps}
                               isDate={true} />
        <TechTransferReviewRow name="partNumber"
                               displayName="Supplier Part Number"
                               {...commonProps} />
        <TechTransferReviewRow name="internalPartNumber" {...commonProps} />
        <TechTransferReviewRow name="componentQualificationLinks"
                               displayName="Source Documents"
                               isDocs={true}
                               {...usePartialDiffCommonProps} />

        <div className="row">
          <div className="col-12 tech-transfer-review-section-title-container">
            Unit Qualification
          </div>
        </div>
        <TechTransferReviewRow name="acceptanceTesting" {...commonProps} />
        <TechTransferReviewRow name="qualificationStatus" {...commonProps} />
        <TechTransferReviewRow name="unitId" {...commonProps} />
        <TechTransferReviewRow name="unitQualificationLinks"
                               displayName="Source Documents"
                               isDocs={true}
                               {...usePartialDiffCommonProps} />

        <div className="row">
          <div className="col-12 tech-transfer-review-section-title-container">
            Component Risk
          </div>
        </div>
        <TechTransferReviewRow name="drugProductContact" {...commonProps} />
        <TechTransferReviewRow name="contactRisk" {...commonProps} />
        <TechTransferReviewRow name="contactRiskJustification" {...usePartialDiffCommonProps} />
        <TechTransferReviewRow name="cleaningValidation" {...commonProps} />
        <TechTransferReviewRow name="sterilizationValidation" {...commonProps} />
        <TechTransferReviewRow name="componentRiskLinks"
                               displayName="Source Documents"
                               isDocs={true}
                               {...usePartialDiffCommonProps} />

        <div className="row">
          <div className="col-12 tech-transfer-review-section-title-container">
            References & Standards
          </div>
        </div>
        <TechTransferReviewRow name="referencesLinks"
                               displayName="Source Documents"
                               isDocs={true}
                               {...usePartialDiffCommonProps} />
      </Fragment>
    );
  }
}

export default I18NWrapper.wrap(TechTransferProcessComponentReview, ["tech_transfer", "base_page"]);
// i18next-extract-mark-ns-stop tech_transfer
