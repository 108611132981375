"use strict";

import * as UIUtils from "../../ui_utils";
import React from "react";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
// noinspection NpmUsedModulesInstalled
import * as PropTypes from "prop-types";
import BaseReactComponent from "../../base_react_component";

/* This shows the title bar in red at the top of the screen */

// i18next-extract-mark-ns-start widgets
class TopNavBar extends BaseReactComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      t,
      items,
      selected,
      urlParams,
    } = this.props;

    return items && items.length > 0
      ? (
        <div className="nav-bar">
          <div className="container">
            <div className="row">
              {
                items
                  .filter(item => !item.experiment)
                  .map(item => {
                    const url = typeof item.url === "function" ? item.url(UIUtils.objectToURLParameters(urlParams)) : item.url;
                    return (
                      <div key={item.key}
                           className={`col-xs-4 col-sm-2 nav-bar-item${selected === item.key ? " nav-bar-item-selected" : ""}`}
                           id={`${item.key}NavLink`}
                      >
                        <a href={url}>{t(item.title)}</a>
                      </div>
                    );
                  })
              }
            </div>
          </div>
        </div>
      )
      : "";
  }
}

TopNavBar.propTypes = {
  items: PropTypes.array,
  selected: PropTypes.string,
  urlParams: PropTypes.any,
};

TopNavBar.defaultProps = {
  items: [],
  selected: null,
  urlParams: {},
};

export default I18NWrapper.wrap(TopNavBar, "widgets");
// i18next-extract-mark-ns-stop widgets
