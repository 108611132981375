"use strict";

export const NO_RECEIVING_SITE = "No receiving site";
export const NO_SENDING_SITE = "No sending site";
export const TECH_TRANSFER_ASSESSMENT_STATUSES = ["NA", "Not started", "In Progress", "In Review", "Complete"];
export const PROCESS_LEVEL_ID = -1;

export const ALL_TYPES_FOR_TYPEAHEADS = [
  "Process",
  "FQA",
  "FPA",
  "IQA",
  "IPA",
  "UnitOperation",
  "Step",
];

export const DEFAULT_TECH_TRANSFER_SORTING = [
  {
    "field": "techTransferredFrom.name",
    "dir": "asc"
  },
  {
    "field": "name",
    "dir": "asc"
  }
];

export const EMPTY_STATE = {
  results: [],
  originalTechTransferRows: [],
  techTransferRows: [],
  orderedUOList: [],
  fromProcess: {
    id: undefined,
    name: "Sending",
  },
  toProcess: {
    id: undefined,
    name: "Receiving",
  },
};

export const PROCESS_CONSTANTS = {
  FROM: "FROM",
  TO: "TO",
};

/**
 * For saving the last comparison in local storage.
 * @type {string}
 */
export const PROJECT_TO_PROCESS_COMPARISONS = "PROJECT_TO_PROCESS_COMPARISONS";

export const TOOLTIP_HEADERS = {
  IMPACT: "Severity/impact score for the changes due to the transfer on product quality or process performance.",
  IMPACT_SEVERITY_DESCRIPTION: "Justification for the selected severity/impact score derived from the current understanding of the sending and receiving processes.",
  UNCERTAINTY: "The level of uncertainty of the assessed impact which is based on the level of current understanding.",
  UNCERTAINTY_JUSTIFICATION: "Justification for the selected level of uncertainty. If the current understanding is based on literature references, the uncertainty will be high.  If it is based on comparative data and characterization of the processes, then the uncertainty will be low.",
  RPN: "RPN is the Risk Priority Number and is calculated as the product of the Impact/Severity Score, Uncertainty Score, and Detectability Risk Score.",
  DETECTABILITY_CURRENT_CONTROL_STRATEGY: "Detectability refers to the risk of being able to detect the specified impact related to the transfer.  This score should also consider when in the transfer process the impact is detected.  The earlier the impact is detected, the lower the detectability risk. The current control strategy describes the activities being undertaken to manage the existing risk of the changes related to this aspect of the transfer.",
  OUTCOME: "Qualitative label describing the RPN score.",
  RISK_MITIGATION_STRATEGY_AND_LIMITATIONS:"The current strategy and associated activities being undertaken to reduce the impact, uncertainty, or detectability risk of the changes related to this aspect of the transfer.  This includes any constraints or limitations of the strategy.",
  RECOMMENDED_ACTIONS: "Identify recommended actions further to evaluate the transfer of this attribute or parameter.",
};
