"use strict";

import * as UIUtils from "../../ui_utils";
import React, { Fragment } from "react";
import { FilterBar } from "./filter_bar";
import SearchBox from "./search_box";
import Cookies from "js-cookie";
import * as I18NWrapper from "../../i18n/i18n_wrapper";

// noinspection NpmUsedModulesInstalled
import * as PropTypes from "prop-types";
import {
  combineFilters,
  consolidateFilters,
  createArchivedFilter,
  createSearchTermFilter, defaultListingMap,
  filterItems,
  mapLatestVersionForFilter,
  SHOW_REMOVED,
  SortingFunctions,
} from "../../utils/filter_helper";

import GenericEditableListTable from "./generic_editable_list_table";
import { EDITOR_OPERATIONS, EDITOR_TYPES } from "../../editor/editor_constants";
import { QUICK_PANEL_BUTTONS } from "../quickPanel/quick_panel_buttons";
import { getURLByTypeCodeAndId, getURLByTypeCodeAndIdAndVersionId } from "../../helpers/url_helper";
import BaseReactComponent from "../../base_react_component";

/**
 * @typedef ITableFilter
 * @property filter {function(*=): boolean}
 * @property name {string}
 * @property disableShowArchived {boolean}
 * @property linkToLastDraft {boolean}
 * @property map {function(*, *, *, *): boolean}
 * @property key {string}
 * @property selected {boolean}
 * @property order {number[]}
 */


// i18next-extract-mark-ns-start documents
class FilteredList extends BaseReactComponent {

  constructor(props) {
    super(props);
    const {t} = props;

    this.baseTypeCode = props.editableName ? UIUtils.getTypeCodeForModelName(UIUtils.capitalize(props.editableName)) : null;

    const searchTermFilterFunction = createSearchTermFilter(() => this.state.searchTerm || this.props.searchTerm, props.filterSelector);
    const archivedFilterFunction = createArchivedFilter(() => this.state.showRemoved, props.archivedItemsFilter);
    const defaultFilters = [
      {
        key: "All",
        order: 0,
        selected: true,
        filter: combineFilters(searchTermFilterFunction, archivedFilterFunction),
        map: defaultListingMap,
        name: t("All"),
        disableShowArchived: false,
        linkToLastDraft: false,
      },
    ];

    this.state = {
      filterObject: defaultFilters[0],
      filteredItems: [],
      items: [],
      typeaheadItems: [],
      showRemoved: Cookies.get(SHOW_REMOVED + "_" + this.baseTypeCode) === "true",
      defaultFilters,
      searchTermFilterFunction,
      archivedFilterFunction,
      userDefinedFilters: props.filters,
      filters: consolidateFilters(props.filters, {archivedFilterFunction, searchTermFilterFunction, defaultFilters}),
      isLoading: true,
    };
  }

  applyFilters(items, filterObject = null) {
    let filterToUse = filterObject || this.getFilterObject();

    const filteredItems = filterToUse
      ? filterItems(items, filterToUse)
      : items;

    const typeaheadItems = filterToUse
      ? filterItems(items, filterToUse, true, SortingFunctions.stringAscending(item => item.name))
      : items;

    return {
      filteredItems,
      typeaheadItems,
    };
  }

  getFilterObject() {
    return this.state.filterObject;
  }

  raiseEvent(event, ...args) {
    if (this.hasEventHandler(event)) {
      return event(...args);
    }
    return undefined;
  }

  hasEventHandler(event) {
    return typeof event === "function";
  }

  /**
   * Happens when the currently selected filter in the filter bar is changed
   * @param newFilter {*} The new filter to be displayed
   * @param oldFilter {*} The previous filter to be displayed
   */
  handleSelectedFilterChange(newFilter, oldFilter) {
    if (newFilter) {
      if (this._isMounted) {
        const items = this.state.items;
        let {filteredItems, typeaheadItems} = this.applyFilters(items, newFilter);

        if (typeof newFilter.map === "function") {
          filteredItems = filteredItems.map(newFilter.map);
        }

        const stateChange = {
          filterObject: newFilter,
          filteredItems,
          typeaheadItems,
          searchTerm: this.getSearchTerm() || "",
        };

        this.setStateSafely(stateChange);
      } else {
        this.state.filterObject = newFilter;
      }
    }

    return this.raiseEvent(this.props.onSelectedFilterChange, newFilter, oldFilter);
  }

  /**
   * Happens when a suggestion is selected by the user in the typeahead
   * IMPORTANT: Setting this event handler prop overrides the default functionality.
   * @param suggestion
   */
  handleSuggestionSelect(suggestion) {
    if (this.hasEventHandler(this.props.onSuggestionSelect)) {
      this.raiseEvent(this.props.onSuggestionSelect, suggestion);
    } else {
      const filterObject = this.getFilterObject();
      const typeCode = UIUtils.getTypeCodeForModelName(this.props.editableName);
      let url;
      let approved;

      UIUtils.setLoadingDisabled(false);
      UIUtils.showLoadingImage();
      if (filterObject && filterObject.linkToLastDraft) {
        approved = false;
      }
      const idField = UIUtils.convertToId(UIUtils.getModelNameForTypeCode(typeCode)) + "Id";

      // the id without the version (if available)
      const baseRecordId = suggestion.baseRecordId || suggestion[idField] || suggestion.id;

      if (this.props.navigateToVersion && suggestion.versionId) {
        url = getURLByTypeCodeAndIdAndVersionId(typeCode, "View", baseRecordId, approved, suggestion.versionId);
      } else {
        url = getURLByTypeCodeAndId(typeCode, "View", baseRecordId, approved);
      }

      if (this.props.projectId) {
        url += "&projectId=" + this.props.projectId;
      }

      window.location.href = UIUtils.getSecuredURL(url);
    }
  }

  /**
   * Happens when the search term is changed in the search box
   * @param searchTerm
   */
  handleSearchTermChange(searchTerm) {
    this.setStateSafely({searchTerm});
    return this.raiseEvent(this.props.onSearchTermChange, searchTerm);
  }

  getSearchTerm() {
    return this.state.searchTerm || this.props.searchTerm;
  }

  getOldSearchTerm(oldProps, oldState) {
    return oldState.searchTerm || oldProps.searchTerm;
  }

  /**
   * Maps an item to the typeahead
   * IMPORTANT: Setting this event handler prop overrides the default functionality.
   * @param item {*} The item to be shown
   */
  handleMapItemForTypeahead(item) {
    const filterObject = this.getFilterObject();

    let itemToMap = (filterObject && filterObject.linkToLastDraft)
      ? mapLatestVersionForFilter(item)
      : item;

    if (this.hasEventHandler(this.props.onMapItemForTypeahead)) {
      return this.raiseEvent(this.props.onMapItemForTypeahead, itemToMap);
    } else {
      return itemToMap;
    }
  }

  /**
   * WORKAROUND:
   * Since the {@class BaseListTable} is responsible for retrieving the data (which is not the ideal solution),
   * This event handler will pass the responsibility to the parent page to handle that data.
   * Ideally, the list should only display information and receive the items to be displayed, but changing that would
   * require big changes in the system.
   * @param results {*} The data received from the backend.
   */
  handleListTableDataReceived(results) {
    if (this.hasEventHandler(this.props.onDataReceived)) {
      const args = {applyFilters: false};
      this.raiseEvent(this.props.onDataReceived, results, args);
      if (args.applyFilters) {
        this.handleDataReceived(results);
      }
    } else {
      this.handleDataReceived(results);
    }
  }

  handleGenerateColumns() {
    return this.raiseEvent(this.props.onGenerateColumns, this);
  }

  handleShowRemovedToggle(showRemoved) {
    this.setStateSafely({showRemoved});
    if (this.props.onShowRemovedToggle) {
      this.props.onShowRemovedToggle(showRemoved);
    }
  }

  handleRenderHeader(props, state) {
    return this.raiseEvent(this.props.onRenderHeader, props, state) || "";
  }

  getDataFromServer(useWriterDb) {
    if (this.table) {
      return this.table.getDataFromServer(useWriterDb);
    }
    return Promise.resolve([]);
  }

  componentDidUpdate(oldProps, oldState) {
    let {
      filterObject,
      showRemoved,
    } = this.state;

    const searchTermChanged = this.getOldSearchTerm(oldProps, oldState) !== this.getSearchTerm();
    const showRemovedChanged = oldState.showRemoved !== showRemoved;
    const hasChanges = JSON.stringify(this.props.filters) !== JSON.stringify(oldProps.filters)
      || oldState.filterObject !== filterObject
      || searchTermChanged
      || showRemovedChanged;

    if (hasChanges) {
      this.updateFilters(searchTermChanged || showRemovedChanged);
    }
  }

  updateFilters(filterObjectUpdated) {
    let {
      items,
      filterObject,
    } = this.state;

    const filters = consolidateFilters(this.props.filters, this.state);

    if (filterObject) {
      filterObject = filters.find(item => item.key === filterObject.key);
    }

    if (filterObject) {
      const filterResults = this.applyFilters(items, filterObject);

      this.setStateSafely({
        userDefinedFilters: this.props.filters,
        filters,
        ...filterResults,
      }, () => {
        if (filterObjectUpdated) {
          this.setStateSafely({filterObject});
        }
      });
    }
    return filterObject;
  }

  handleDataReceived(results) {
    const items = results ? results.instances : [];
    const filterResult = this.applyFilters(items);

    return this.setStateSafely(
      {
        items,
        ...filterResult,
      },
      /**
       * This is important to keep the isLoading separate from setting the table results. Once the results (table rows)
       * are set, the table will reload/redraw. Tests will immediately start scanning the table for results once the
       * isLoading indicator goes away and then the redraw will cause webdriver.io to fail with errors like:
       *
       *     Error: stale element reference: element is not attached to the page document
       */
      () => this.setStateSafely({isLoading: false}));
  }

  render() {
    const {
      editableName,
      parent,
      showFilterBar,
      showSearchBar,
      handleArchivedOnClient,
      listTableComponent,
      onGenerateColumns,
      disableQuickPanel,
      showQuickPanelButtonsInNewLine,
      filterSelector,
      showIdInSearchBox,
      showVersionInQuickPanel,
      hideApprovedLabel,
      showVersionInSearchBox,
    } = this.props;

    const {
      filters,
      items,
      typeaheadItems,
    } = this.state;

    const ListTableComponent = listTableComponent || GenericEditableListTable;
    const pageBaseId = UIUtils.convertToCamelCaseId(UIUtils.pluralize(editableName));
    const selectedFilter = this.state.filterObject;

    return (
      <Fragment>
        {showFilterBar && filters.length > 0 ? (
          <div className="row">
            <div>
              <FilterBar
                items={items}
                filters={filters}
                onSelectedFilterChange={this.handleSelectedFilterChange}
                isLoading={this.isLoading()}
              />
            </div>
          </div>
        ) : <div className="container-spacer" />}
        <div className="row row-white shadow">
          <div className="col-sm-12">
            <div className="row">
              {this.handleRenderHeader(this.props, this.state)}
              {showSearchBar ? (
                <div className="col-sm-12">
                  <SearchBox
                    items={typeaheadItems.map(this.handleMapItemForTypeahead)}
                    id={`${pageBaseId}SearchTypeahead`}
                    onSuggestionSelected={this.handleSuggestionSelect}
                    onSearchTermChange={this.handleSearchTermChange}
                    filterSelector={filterSelector}
                    showId={showIdInSearchBox}
                    editableName={editableName}
                    showVersion={showVersionInSearchBox}
                  />
                </div>
              ) : ""}
            </div>
            <div className="row">
              <div className="col-sm-12">
                <ListTableComponent
                  {...this.props}
                  ref={table => this.table = table}
                  editableName={editableName}
                  onQuickPanelActionClick={this.props.onQuickPanelActionClick}
                  parent={parent}
                  onDataReceived={this.handleListTableDataReceived}
                  filter={selectedFilter}
                  projectId={this.props.projectId}
                  pageLength={this.props.pageLength}
                  disableQuickPanel={disableQuickPanel}
                  onShowRemovedToggle={handleArchivedOnClient ? this.handleShowRemovedToggle : undefined}
                  onGenerateColumns={onGenerateColumns}
                  showButtonsInNewLine={showQuickPanelButtonsInNewLine}
                  showVersionInQuickPanel={showVersionInQuickPanel}
                  hideApprovedLabel={hideApprovedLabel}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

FilteredList.propTypes = {
  filters: PropTypes.any,
  onSelectedFilterChange: PropTypes.func,
  onRenderHeader: PropTypes.func,
  onMapItemForTypeahead: PropTypes.func,
  editableName: PropTypes.string,
  editableDisplayName: PropTypes.string,
  onSuggestionSelect: PropTypes.func,
  onSearchTermChange: PropTypes.func,
  parent: PropTypes.any,
  onDataReceived: PropTypes.func,
  listTableComponent: PropTypes.object,
  onGenerateColumns: PropTypes.func,
  handleArchivedOnClient: PropTypes.bool,
  showFilterBar: PropTypes.bool,
  showSearchBar: PropTypes.bool,
  topLevel: PropTypes.bool,
  disableQuickPanel: PropTypes.bool,
  hideRemoveButton: PropTypes.bool,
  hidePaging: PropTypes.bool,
  onInstanceReceived: PropTypes.func,
  onQuickPanelInstanceReceived: PropTypes.func,
  hideManageColumn: PropTypes.bool,
  editorType: PropTypes.string,
  editorOperation: PropTypes.string,
  quickPanelButtons: PropTypes.any,
  hideShowArchivedButton: PropTypes.bool,
  additionalRequestData: PropTypes.any,
  onDataPreProcess: PropTypes.func,
  onQuickPanelActionClick: PropTypes.func,
  showQuickPanelButtonsInNewLine: PropTypes.bool,
  filterSelector: PropTypes.func,
  showIdInSearchBox: PropTypes.bool,
  onCheckPermissions: PropTypes.func,
  archivedItemsFilter: PropTypes.func,
  showArchivedText: PropTypes.string,
  showVersionInQuickPanel: PropTypes.bool,
  hideApprovedLabel: PropTypes.bool,
  showVersionInSearchBox: PropTypes.bool,
};

FilteredList.defaultProps = {
  filters: [],
  onSelectedFilterChange: null,
  editableDisplayName: null,
  showFilterBar: true,
  showSearchBar: true,
  onGenerateColumns: null,
  topLevel: false,
  disableQuickPanel: false,
  onMapItemForTypeahead: (item) => {
    return {
      ...item,
      id: item.id,
      label: item.name,
    };
  },
  onInstanceReceived: null,
  onQuickPanelInstanceReceived: null,
  hideManageColumn: false,
  editorType: EDITOR_TYPES.QUICK_PANEL,
  editorOperation: EDITOR_OPERATIONS.VIEW,
  quickPanelButtons: [QUICK_PANEL_BUTTONS.VIEW],
  hideShowArchivedButton: false,
  additionalRequestData: {},
  onDataPreProcess: (data) => data,
  onQuickPanelActionClick: (action) => {
    console.warn("No handler found. Please add a handler to the prop 'onQuickPanelActionClick'. Action: ", action);
  },
  showQuickPanelButtonsInNewLine: false,
  filterSelector: (item) => item.name,
  showIdInSearchBox: false,
  onCheckPermissions: null,
  archivedItemsFilter: null,
  showArchivedText: null,
  showVersionInQuickPanel: false,
  hideApprovedLabel: false,
  showVersionInSearchBox: false,
  navigateToVersion: false,
};

module.exports = {
  FilteredList: I18NWrapper.wrap(FilteredList, "documents"),
  FilteredListUnwrapped: FilteredList,
};

// i18next-extract-mark-ns-stop documents
