"use strict";

import { BulkOperationMessageHandler } from "./bulk_operation_message_handler";
import { BULK_PROPOSAL_STATUS } from "../../dashboard/constants/bulk_operations_constants";

export class BulkProposalMessageHandler extends BulkOperationMessageHandler {

  constructor(onMessage, statusService) {
    super(onMessage, statusService);
  }

  getInProgressMessage() {
    return BULK_PROPOSAL_STATUS.EDITING;
  }
}
