var clearSlide = function (element) {
    element.style.transitionDuration = '';
    Array.from(element.classList).forEach(function (c) {
        if (c.startsWith('k-slide-')) {
            element.classList.remove(c);
        }
    });
};
var firefox = typeof window !== 'undefined' &&
    /Firefox/.test(window.navigator.userAgent);
/**
 * @hidden
 */
export var slide = function (element, direction, duration, type, callback) {
    if (duration === 0) {
        return callback();
    }
    var slideClass = 'k-slide-' + direction + '-' + type;
    var slideActiveClass = slideClass + '-active';
    var transitionend = function (e) {
        // if event is attached using react,
        // the animation does not work well in firefox,
        // when the show/hide button is toggled quickly.
        if (e.target === element) {
            element.removeEventListener('transitionend', transitionend);
            clearSlide(element);
            if (type === 'exit') {
                element.style.display = 'none';
            }
            else {
                element.style.display = '';
            }
            callback();
        }
    };
    element.addEventListener('transitionend', transitionend);
    var ownerDocument = element.ownerDocument;
    if (!ownerDocument) {
        return;
    }
    var defaultView = ownerDocument.defaultView;
    if (!defaultView) {
        return;
    }
    var animate = function () {
        clearSlide(element);
        element.classList.add(slideClass);
        defaultView.requestAnimationFrame(function () {
            element.style.transitionDuration = duration + 'ms';
            element.classList.add(slideActiveClass);
        });
    };
    if (firefox) {
        defaultView.requestAnimationFrame(animate);
    }
    else {
        animate();
    }
};
