"use strict";

import React from "react";
import BasePageTitleBar from "./base_page_title_bar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import * as UIUtils from "../../ui_utils";
import { getProjectFromCache } from "../../utils/project_helper";
import TypeaheadObjectCache from "../../utils/cache/typeahead_object_cache";
import { IMPORT_TYPE_KEY } from "../../import/constants/import_constants";
import { getURLByTypeCodeAndId } from "../../helpers/url_helper";
import { LIBRARY_MATERIAL_TYPE_CODE } from "../../library/library_constants";

/* This shows the title bar for the import flow */
export default class ImportPageTitleBar extends BasePageTitleBar {
  constructor(props) {
    super(props);

    if (this.props.projectId) {
      new TypeaheadObjectCache("Project").loadOptions(this.forceUpdateSafely);
    }
  }

  createTitleBarUrl() {
    const {
      projectId, projectType, processId, selectedTab,
    } = this.props;

    let baseUrl = `/import/importProject.html?projectId=${projectId}&projectType=${projectType}&selectedTab=${selectedTab}`;
    if (processId) {
      baseUrl += `&processId=${processId}`;
    }

    return baseUrl;
  }

  createTitleBarLabel() {
    const projectId = this.props.projectId;
    let thisProject;
    if (projectId) {
      thisProject = getProjectFromCache(projectId);
    }

    const projectLabel = thisProject ? UIUtils.getRecordLabelForDisplay("PRJ", thisProject.id, thisProject.name) : "";
    return `Import ${projectLabel} Records`;
  }

  getLink() {
    const { importConfig, selectedDependencyRecord } = this.props;

    if (importConfig?.key === IMPORT_TYPE_KEY.LIBRARY_COA && selectedDependencyRecord) {
      return (<a href={getURLByTypeCodeAndId(LIBRARY_MATERIAL_TYPE_CODE, "View", selectedDependencyRecord.getInternalId(), true)} id="importLinkInTitleBar">{selectedDependencyRecord.name}</a>);
    } else {
      return (<a href="/import/importDashboard.html" id="importLinkInTitleBar">Import Data</a>);
    }
  }

  renderTitleBar() {
    const {
      name,  hasNoLinksInTitleBar, importDataRecords,
    } = this.props;

    return (
      <div className={"page-title-bar-title" + (hasNoLinksInTitleBar ? " page-title-bar-title-center" : "")}
           id="pageTitleBar"
      >
        <span className="page-title-bar-primary"
              id="pageTitleBarPrimary"
        >
          {name}
        </span>
        <div className="page-title-bar-breadcrumbs">
          {!hasNoLinksInTitleBar ? (
            <span className="page-title-bar-breadcrumbs-import">
              <FontAwesomeIcon icon={faArrowLeft} />&nbsp;
              {this.getLink()}
              {importDataRecords ? (
                <span>&nbsp;&larr;
                  <a href={this.createTitleBarUrl()}
                     id="importRecordsLinkInTitleBar"
                  > {this.createTitleBarLabel()}</a>
                </span>
              ) : ""}
            </span>
          ) : ""}
        </div>
      </div>
    );
  }
}
