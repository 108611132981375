"use strict";
import React from "react";
import BaseAttribute from "./base_attribute";
import { createHTMLForTextDiff } from "../../helpers/diff_helper";
import { debounce } from "lodash";

/**
 * This class presents a text field for a parent record (and is integrated with it) so that the data can be saved back
 * to the database.
 *
 * NOTE: IF YOU AREN'T USING THIS FOR SAVING DATA TO A RECORD, IT's NOT WHAT YOU ARE LOOKING FOR.
 */
export default class DebouncedTextAttribute extends BaseAttribute {
  constructor(props) {
    super(props);

    this.state = {
      currentValue: this.getValue() || null, prevValueFromParent: null, gotValueFromParent: false,
    };

    // Bind functions to this
    this.debouncedHandleInputChange = debounce(this.debouncedHandleInputChange, 300);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!super.shouldComponentUpdate(nextProps, nextState)) {
      let value = this.getNextValue(nextProps);
      return (this.currentValue !== value) || (nextProps.pattern !== this.props.pattern) || (nextProps.dataPatternError !== this.props.dataPatternError);
    }
    return true;
  }

  componentDidUpdate() {
    const {currentValue, gotValueFromParent, prevValueFromParent} = this.state;
    const valueFromParent = this.props.parent?.state[this.getAttributeName()];
    const shouldInitiateStateFirstTime = !gotValueFromParent && valueFromParent && currentValue === null;
    const isValueChangedFromOutside = currentValue !== valueFromParent && currentValue === prevValueFromParent;
    const isValueChangedFromInside = currentValue === valueFromParent && prevValueFromParent !== valueFromParent;

    if (shouldInitiateStateFirstTime) {
      //This will run only this first time to initiate the state with the value that comes from the parent. This value takes time to be passes here that's why we use componentDidUpdate.
      this.setStateSafely({
        currentValue: valueFromParent, prevValueFromParent: valueFromParent, gotValueFromParent: true
      }, () => this.forceUpdate());
    } else if (isValueChangedFromOutside) {
      // This should run when changes comes from outside, like when we change measure dropdown then it will change the text attribute value
      this.setStateSafely({
        currentValue: valueFromParent,
        prevValueFromParent: valueFromParent
      }, () => this.forceUpdate());
    } else if (isValueChangedFromInside) {
      // This should run after we finish typing and debounce function runs and update the parent's value, then we just need to update the prev value of the prop
      this.setStateSafely({prevValueFromParent: valueFromParent});
    }
  }

  // noinspection JSMethodCanBeStatic
  getInitialValue() {
    return this.props.value || "";
  }

  getValue() {
    const value = this.props.parent.state[this.getAttributeName()];
    return value || value === 0 ? value : this.getInitialValue();
  }

  handleInputChange(event) {
    this.setStateSafely({currentValue: event.target.value}, () => this.forceUpdate());
    this.debouncedHandleInputChange();
  }

  debouncedHandleInputChange() {
    this.handleChange({
      target: {
        value: this.state.currentValue
      }
    });
  }

  renderInput() {
    let input;
    let inputId = this.getInputId();
    // Uncomment for verbose logging
    // console.log("Render: Generating " + this.getInputId() + " with value: " + value);
    if (this.isView()) {
      if (this.isDiffingVersions()) {
        let oldValue = this.getOldValue(this.props.name);
        input = createHTMLForTextDiff(oldValue, this.state.currentValue);
      } else {
        input = (<span id={inputId}>{this.state.currentValue}</span>);
      }
    } else {
      let dataRequiredErrorMsg = this.getCapitalizedName() + " is required.";
      let dataPatternError = "Invalid data entered for " + this.getCapitalizedName();
      input = (<input type="text"
                      className="form-control"
                      id={inputId}
                      data-validate="true"
                      data-required-error={dataRequiredErrorMsg}
                      data-pattern-error={this.props.dataPatternError ? this.props.dataPatternError : dataPatternError}
                      pattern={this.props.pattern ? this.props.pattern : "^.*$"}
                      required={this.isRequired()}
                      value={this.state.currentValue || ""}
                      maxLength="255"
                      disabled={this.isDisabled()}
                      onChange={this.handleInputChange}
      />);
    }

    return input;
  }
}
