"use strict";

export default class ConfigurableTablesCustomFiltersHandler {

  constructor() {
    this.attributeMatches = {
      contains: (attributeValue, filterValue) => attributeValue?.includes(filterValue),
      doesnotcontain: (attributeValue, filterValue) => !attributeValue?.includes(filterValue),
      eq: (attributeValue, filterValue) => attributeValue === filterValue,
      neq: (attributeValue, filterValue) => attributeValue !== filterValue,
      startswith: (attributeValue, filterValue) => attributeValue?.startsWith(filterValue),
      endswith: (attributeValue, filterValue) => attributeValue?.endsWith(filterValue),
    };
  }
  matchesLinkFilter(row, field, value, operator) {
    const links = Array.isArray(row[field]) ? row[field] : [];

    switch (operator) {
      case "isnull":
        return !links.length;
      case "isnotnull":
        return links.length;
    }

    return links.some(link =>
      Object.keys(link).some(attribute => {
        const attributeValue = link[attribute]?.toLowerCase();
        return this.attributeMatches[operator](attributeValue, value?.toString().toLowerCase());
      })
    );
  }

  process(customFilters, data) {
    if (!customFilters) {
      return data;
    }
    return data.filter(row => {
      // For each row, check if it matches all the link filters
      return customFilters.every(filter => {
        const { field, value, operator } = filter;
        return this.matchesLinkFilter(row, field, value, operator);
      });
    });
  }

}
