"use strict";

// This implements the Process Flow Map report toolbar

import React from "react";
import DropdownButton from "../../widgets/generic/dropdown_button";
import { faFileImage } from "@fortawesome/free-regular-svg-icons";
import * as UIUtils from "../../ui_utils";
import BaseReactComponent from "../../base_react_component";

export const exportAsProcessFlowMap = [
  {key: "screen", value: " Screen (.png)", icon: faFileImage},
  {key: "full", value: " Full Size (.png)", icon: faFileImage},
];

export default class ProcessMapToolbar extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  handleProcessMapExport(event) {
    UIUtils.ignoreHandler(event);
    if (this.props.onExportToImage) {
      this.props.onExportToImage(event.target.name);
    }
  }


  render() {
    return (
      <div id="processFlowMapOptionsPanel"
           className="canvas-report-options-panel canvas-report-options-vcenter"
      >
        <div id="processMapOptionsContent"
             className="canvas-report-options-content"
        >
          <div className="canvas-report-options-row canvas-report-options-vcenter">
            <div className="canvas-report-options-field canvas-report-options-button">
              <DropdownButton className="btn-group"
                              options={exportAsProcessFlowMap}
                              text="Export"
                              id="exportProcessMap"
                              onOptionsSelect={this.handleProcessMapExport}
                              aria-label="Export"
                              title="Export the Process Flow Map as an image"
              />
            </div>

          </div>
        </div>
      </div>
    );
  }
}
