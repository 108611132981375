"use strict";

/*
This file includes all logic needed to get value, title, etc. for each column exported
to the excel sheet.
 */

import * as UIUtils from "../../ui_utils";
import ConfigurableTablesExportColumnGenerator
  from "../../configurableTables/export/configurable_tables_export_column_generator";
import { getProcessName } from "../tables/tech_transfer_table_helper";
import { NO_RECEIVING_SITE, NO_SENDING_SITE } from "../tech_transfer_constants";
import { VARIABLE_PARENTS, VARIABLE_TYPES } from "../../configurableTables/tables/configurable_tables_constants";
import { formatValue } from "../../configurableTables/fieldsConfig/fields_formatter";
import {
  COMMON_SITE_LABEL,
  RECEIVING_SITE_LABEL,
  SENDING_SITE_LABEL
} from "../../configurableTables/fieldsConfig/constants/configurable_tables_columns_config";
import { FILTER_TYPES } from "../../configurableTables/columnOperations/configurable_tables_column_operations_constants";

export default class TechTransferExportColumnGenerator extends ConfigurableTablesExportColumnGenerator {

  constructor(props) {
    super(props);
    this.selectedModelType = props?.selectedModelType;
    this.fromProcess = props?.fromProcess;
    this.toProcess = props?.toProcess;
  }

  getColumnTitle(attachProcessName = true) {
    const {column} = this;
    const {title} = column;
    const process = column.siteForValueEvaluation === SENDING_SITE_LABEL ? this.fromProcess : this.toProcess;
    const processName = getProcessName(process, column.siteForValueEvaluation === RECEIVING_SITE_LABEL, true);
    return typeof title === "function" ? title(process) : `${title}${attachProcessName ? `\r\n${processName}` : ""}`;
  }

  getColumnValue() {
    const {record, column} = this;
    const transferredTo = record;
    const {techTransferredFrom} = record;
    const {value, siteForValueEvaluation, prop, title, filterType} = column;
    let finalValue;

    if (value) {
      if (siteForValueEvaluation === COMMON_SITE_LABEL) {
        finalValue = value(techTransferredFrom, transferredTo);
      } else {
        finalValue = value(siteForValueEvaluation === SENDING_SITE_LABEL ? techTransferredFrom : transferredTo, column.prop);
      }
    } else if (siteForValueEvaluation === SENDING_SITE_LABEL) {
      finalValue = techTransferredFrom[prop ? column.prop : UIUtils.convertToCamelCaseId(title)];
    } else if (siteForValueEvaluation === RECEIVING_SITE_LABEL) {
      finalValue = transferredTo[prop ? prop : UIUtils.convertToCamelCaseId(title)];
    }

    return this.wrapValue(finalValue ?
      formatValue(finalValue, record, prop, false, filterType === FILTER_TYPES.DATE) : "");
  }

  getVariableColumnLabel() {
    const {record, selectedModelType} = this;
    const transferredTo = record;
    const {techTransferredFrom} = record;

    const transferredToLabel = transferredTo.id ?
      UIUtils.getRecordCustomLabelForDisplay(transferredTo) :
      NO_RECEIVING_SITE;

    const transferredFromLabel = techTransferredFrom.id ?
      UIUtils.getRecordCustomLabelForDisplay(techTransferredFrom) :
      NO_SENDING_SITE;

    const typeCodeForSelectedModelType = UIUtils.getTypeCodeForModelName(selectedModelType);
    if (VARIABLE_PARENTS.includes(typeCodeForSelectedModelType)) {
      if (VARIABLE_TYPES.includes(record.typeCode)) {
        return `  • ${transferredFromLabel} to ${transferredToLabel}`;
      }
      return `${transferredFromLabel}\r\nto\r\n${transferredToLabel}`;
    }

    return `${transferredFromLabel}\r\nto\r\n${transferredToLabel}`;
  }
}
