"use strict";


import ConfigurableTablesAdapter from "../../configurableTables/adapters/configurable_tables_adapter";
import { formatValue } from "../../configurableTables/fieldsConfig/fields_formatter";
import * as CommonEditablesFormatter from "../../../server/common/editables/common_editables_formatter";

export default class MaterialLibraryTableAdapter extends ConfigurableTablesAdapter {

  constructor(params) {
    super(params, "mtlMap", "LibraryMaterials");
  }

  getRows() {
    const records = Object.values(this.activeMap);

    super.adaptRows(records);

    const sanitizedRecords = records.map(record => ({
      ...record,
      gmp: record.gmp === "No" || record.gmp === null || !record.gmp ? "No" : "Yes",
      certificateOfAnalysis: formatValue(record.certificateOfAnalysis),
      compendialStadard: CommonEditablesFormatter.formatMultiSelectValueForDisplay(record.compendialStandard),
      supplier: record.Supplier?.name,
    }));

    return super.sanitizeEmptyValues(sanitizedRecords);
  }

}