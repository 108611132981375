"use strict";

import * as styles from "./direct_scope_smart_content_tree.module.scss";

import React, {useEffect, useState} from "react";
import {createTreeItem, TreeItemType} from "../../tree_item";
import {
  TreeViewExpandChangeEvent,
  TreeViewItemClickEvent,
} from "@progress/kendo-react-treeview";
import {
  DirectScopeData,
  DirectScopeRecord,
} from "../../../services/direct_scope_service";
import {FIELD_KEY, FieldItem, getFields} from "../../../common/editables_map";
import {Project} from "../../../../common/models/project";
import DirectScopeRecordTreeItem from "./direct_scope_record_tree_item";
import {groupFieldsBySection} from "../../../common/editables_section_map";
import {DocumentRecord} from "../../../../common/models/document";
import * as DirectScopeWidgetParser from "../../../utils/parser/direct_scope_widget_parser";
import {RMP} from "../../../../common/models/rmp";
import * as DocumentLinkParser from "../../../utils/parser/elementParser/document_link_parser";
import {getRiskLinks} from "../../../../helpers/risk_helper";
import * as AcceptanceCriteriaRangeParser from "../../../utils/parser/elementParser/acceptance_criteria_range_parser";
import SmartContentRecordHeader from "./smart_content_record_header";
import SideMenuTree from "../side_menu_tree";

const GROUPS = [
  {name: "Project", typeCode: "PRJ"},
  {
    name: "Product",
    items: [
      {name: "Target Quality Product Profile", typeCode: "TPP"},
      {name: "General Attributes", typeCode: "GA"},
      {name: "Final Quality Attributes", typeCode: "FQA"},
      {name: "Final Performance Attributes", typeCode: "FPA"},
    ],
  },
  {
    name: "Process",
    items: [
      {name: "Processes", typeCode: "PR"},
      {name: "Unit Operations", typeCode: "UO"},
      {name: "Steps", typeCode: "STP"},
      {name: "Materials", typeCode: "MT"},
      {name: "Material Attributes", typeCode: "MA"},
      {name: "Process Components", typeCode: "PRC"},
      {name: "Process Parameters", typeCode: "PP"},
      {name: "Intermediate Quality Attributes", typeCode: "IQA"},
      {name: "Intermediate Performance Attributes", typeCode: "IPA"},
    ],
  },
  {name: "Control Methods", typeCode: "CM"},
  {name: "Documents", typeCode: "DOC", ignoreProjectId: true},
  {name: "Suppliers", typeCode: "SUP", ignoreProjectId: true},
  {name: "Doc Builder Fields", ignoreProjectId: true},
];

type DirectScopeSmartContentTreeProps = {
  selectedRecordData: any;
  selectedRecord: DirectScopeRecord;
  directScopeData: DirectScopeData;
  project: Project;
  documentRecord?: DocumentRecord;
  rmp: RMP;
  hideHeaderRecord?: boolean;
  // eslint-disable-next-line no-unused-vars
  onSelectedRecord: (record: DirectScopeRecord) => void;
  // eslint-disable-next-line no-unused-vars
  onAdd: (record: DirectScopeRecord, field: FieldItem) => void;
  // eslint-disable-next-line no-unused-vars
  onLoadRecordsForTypeCode: (typeCode: string) => void;
  onBackButtonClick: () => void;
};

export default function DirectScopeSmartContentTree(
  props: DirectScopeSmartContentTreeProps,
) {
  const {
    directScopeData,
    selectedRecordData,
    selectedRecord,
    project,
    documentRecord,
    rmp,
    hideHeaderRecord,
    onSelectedRecord,
    onAdd,
    onLoadRecordsForTypeCode,
    onBackButtonClick,
  } = props;
  const FIELDS = getFields(project);
  const [treeData, setTreeData] = useState<Array<TreeItemType>>([]);
  const [previousTreeData, setPreviousTreeData] =
    useState<Array<TreeItemType>>(null);

  const initializeTreeData = (
    typeCodeToRecords: Record<string, Array<DirectScopeRecord>>,
    saveExpandedState: boolean = false,
  ) => {
    const currentTreeData: Array<TreeItemType> = [];
    for (const group of GROUPS) {
      const parentTreeNode = createTreeItem({
        text: group.name,
        value: group,
        className: "direct-scope-group-tree-item",
      });

      if (!group.ignoreProjectId && !documentRecord?.ProjectId) {
        parentTreeNode.className = "disabled-tree-item";
        parentTreeNode.tooltipMessage =
          "To enable this menu, select a Project for this document.";
      }

      if (group.name === "Process" && !documentRecord?.ProcessId) {
        parentTreeNode.className = "disabled-tree-item";
        parentTreeNode.tooltipMessage =
          "To enable this menu, select a Process for this document.";
      }
      currentTreeData.push(parentTreeNode);
      const originalTreeData: TreeItemType = getOriginalTreeData(
        treeData,
        currentTreeData,
      );
      if (saveExpandedState) {
        parentTreeNode.expanded = originalTreeData?.expanded;
      }

      if (group.name === "Doc Builder Fields") {
        for (const field of FIELDS.get(
          FIELD_KEY.DocBuilderFields,
        ).attributesMap.values()) {
          parentTreeNode.items.push(
            createTreeItem({
              text: field.displayName,
              level: 1,
              isLeaf: true,
              value: {
                key: FIELD_KEY.DocBuilderFields,
                value: `${FIELD_KEY.DocBuilderFields}.${field.name}`,
                isField: true,
              },
            }),
          );
        }
        continue;
      }

      if (group.typeCode) {
        parentTreeNode.items = createTreeItemsByTypeCode(
          typeCodeToRecords,
          group.typeCode,
          1,
        );
        continue;
      }

      if (group.items) {
        for (const item of group.items) {
          const treeItem = createTreeItem({
            text: item.name,
            level: 1,
            value: item,
          });
          parentTreeNode.items.push(treeItem);
          const originalItemTreeData: TreeItemType = getOriginalTreeData(
            originalTreeData?.items,
            parentTreeNode.items,
          );
          if (saveExpandedState) {
            treeItem.expanded = originalItemTreeData?.expanded;
          }
          treeItem.items = createTreeItemsByTypeCode(
            typeCodeToRecords,
            item.typeCode,
            2,
          );
        }
      }
    }
    return currentTreeData;
  };

  const createTreeItemsByTypeCode = (
    typeCodeToRecords: Record<string, Array<DirectScopeRecord>>,
    typeCode: string,
    level: number,
  ): Array<TreeItemType> => {
    const records = typeCodeToRecords && typeCodeToRecords[typeCode];
    if (!records) {
      return [
        createTreeItem({level, text: "Loading records...", isLeaf: true, className: "skeleton"}),
        createTreeItem({level, text: "Loading records...", isLeaf: true, className: "skeleton"}),
      ];
    }

    if (!records.length) {
      return [
        createTreeItem({level, text: "There is no records", isLeaf: true}),
      ];
    }

    return records.map((record) =>
      createTreeItem<DirectScopeRecord>({
        level,
        text: `${record.typeCode}-${record.id} - ${record.name}`,
        value: record,
        isLeaf: true,
        className: `direct-scope-record-tree-item ${styles["direct-scope-record-tree-item"]}`,
        renderTreeItemComponent: () => (
          <DirectScopeRecordTreeItem record={record} />
        ),
      }),
    );
  };

  useEffect(() => {
    if (!selectedRecord) {
      setTreeData(previousTreeData);
    } else {
      setTreeData(getFieldsTreeDataForRecord(selectedRecord));
    }
  }, [selectedRecord, selectedRecordData]);

  useEffect(() => {
    setTreeData(initializeTreeData(directScopeData?.typeCodeToRecords, true));
  }, [directScopeData]);

  const handleExpandChange = (event: TreeViewExpandChangeEvent) => {
    event.item.expanded = !event.item.expanded;
    setTreeData([...treeData]);
    if (event.item.value && event.item.value.typeCode) {
      onLoadRecordsForTypeCode(event.item.value.typeCode);
    }
  };

  const handleFieldChange = (event: TreeViewItemClickEvent) => {
    const item = event.item as TreeItemType;
    if (item.value?.isField) {
      onAdd(selectedRecord, item.value);
      return;
    }

    if (!item.isLeaf) {
      return;
    }

    setPreviousTreeData(treeData);
    const record = item.value as DirectScopeRecord;
    if (record) {
      onSelectedRecord(record);
      // We don't want to keep the expanded state when selecting a record
      setTreeData([]);
    }
  };

  const getFieldsTreeDataForRecord = (record: DirectScopeRecord) => {
    const {modelName} = record;
    const recordFields = FIELDS.get(FIELD_KEY.RecordFields).attributesMap.get(
      modelName,
    );
    const fieldsTreeData: Array<TreeItemType> = [];
    if (!recordFields) {
      return fieldsTreeData;
    }
    const sectionToFields = groupFieldsBySection(
      modelName,
      Array.from(recordFields.attributesMap.values()),
    );
    for (const [section, fieldItems] of sectionToFields.entries()) {
      const sectionTreeNode = createTreeItem({
        text: section,
        className: "section-tree-node",
      });
      fieldsTreeData.push(sectionTreeNode);
      const originalTreeData: TreeItemType = getOriginalTreeData(
        treeData,
        fieldsTreeData,
      );
      sectionTreeNode.expanded = originalTreeData?.expanded;

      if (!selectedRecordData) {
        sectionTreeNode.items.push(
          createTreeItem({level: 1, text: "Loading records...", isLeaf: true, className: "skeleton"}),
          createTreeItem({level: 1, text: "Loading records...", isLeaf: true, className: "skeleton"}),
        );
        continue;
      }

      for (const fieldItem of fieldItems) {
        if (fieldItem.attributesMap) {
          const subModelTreeNode = createTreeItem({
            level: 1,
            text: fieldItem.displayName,
            className: "subsection-tree-node",
          });
          subModelTreeNode.items = getFieldsForSubModel(fieldItem, 2);
          sectionTreeNode.items.push(subModelTreeNode);
          continue;
        }

        sectionTreeNode.items.push(
          createSectionTreeLeaf(
            selectedRecordData,
            fieldItem,
            record.modelName,
            1,
          ),
        );
      }
    }

    return fieldsTreeData;
  };

  const getFieldsForSubModel = (fieldItem: FieldItem, level: number) => {
    const subModelTreeData: Array<TreeItemType> = [];
    let values = [];
    if (DocumentLinkParser.isLinkAttribute(fieldItem.name)) {
      values = (
        DocumentLinkParser.getValues(
          selectedRecordData,
          selectedRecord.modelName,
          fieldItem.name,
        ) || []
      ).map((value) => {
        return {...value, displayName: value.link?.name};
      });
    } else if (fieldItem.name === "Criticality") {
      values = (getRiskLinks(selectedRecordData) || []).map((value) => {
        return {...value, displayName: value.targetRecord?.name};
      });
    } else if (fieldItem.name === "AcceptanceCriteriaRange") {
      values = (
        AcceptanceCriteriaRangeParser.getValues(selectedRecordData) || []
      ).map((ac) => {
        return {...ac, displayName: `${ac.group} - ${ac.label}`};
      });
    }

    for (const value of values) {
      const treeNode = createTreeItem({
        level,
        value,
        text: value.displayName,
        className: "subsection-tree-node",
      });
      subModelTreeData.push(treeNode);

      for (const field of fieldItem.attributesMap.values()) {
        treeNode.items.push(
          createSectionTreeLeaf(value, field, fieldItem.name, level + 1, true),
        );
      }
    }
    return subModelTreeData;
  };

  const createSectionTreeLeaf = (
    data: any,
    fieldItem: FieldItem,
    modelName: string,
    level: number,
    isSubModel: boolean = false,
  ) => {
    const actualValue = DirectScopeWidgetParser.getActualValue(
      data,
      modelName,
      fieldItem.name,
      rmp,
    );
    let value: any = {...fieldItem, actualValue};
    if (isSubModel) {
      value = {
        ...value,
        subModelName: modelName,
        subModelData: JSON.stringify(data),
      };
    }
    return createTreeItem({
      level,
      text: actualValue.content
        ? `${fieldItem.displayName}: ${actualValue.content}`
        : `${fieldItem.displayName}: -`,
      renderTreeItemComponent: actualValue.element
        ? () => (
          <div>
            {fieldItem.displayName}:{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: actualValue.element.outerHTML,
              }}
            />
          </div>
        )
        : null,
      isLeaf: true,
      value: {...value, isField: true},
      className: "text-truncate section-tree-leaf",
    });
  };

  const getOriginalTreeData = (
    currentTreeData: Array<TreeItemType>,
    newTreeData: Array<TreeItemType>,
  ) => {
    let originalTreeData: TreeItemType = null;
    if (
      currentTreeData?.length &&
      currentTreeData.length >= newTreeData.length
    ) {
      const lastIndex = newTreeData.length - 1;
      originalTreeData = currentTreeData[lastIndex];
    }
    return originalTreeData;
  };

  const renderRecordHeader = () => {
    if (!selectedRecord || hideHeaderRecord) {
      return null;
    }

    return (
      <div className="mt-2">
        <SmartContentRecordHeader
          record={selectedRecord}
          onBackClick={onBackButtonClick}
        />
        <div className="border-bottom" />
      </div>
    );
  };

  return (
    <div id="direct-scope-smart-content-tree">
      {renderRecordHeader()}
      <SideMenuTree
        treeData={treeData}
        onExpandChange={handleExpandChange}
        onItemClick={handleFieldChange}
      />
    </div>
  );
}
