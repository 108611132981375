export const INVALID_DATE = "Invalid date";

/**
 * The user-visible status for the training
 * @enum {string}
 */
export const TRAINING_STATUS = {
  COMPLETED: "Completed",
  OVERDUE: "Overdue",
  SUPERSEDED: "Superseded",
  PENDING: "Pending",
  TRAINED_OUTSIDE: "Not Tracked",
};
