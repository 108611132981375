"use strict";

import React from "react";
import BaseReactComponent from "../../base_react_component";

export default class PCMDashboardIndexChart extends BaseReactComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id={`${this.props.id}Div`} className={"pcm-dashboard-index-chart" +
        (this.props.titleBackgroundClassName ? ` ${this.props.titleBackgroundClassName}` : "")}
      >
        <div id={`${this.props.id}Title`} className={"col-form-label pcm-dashboard-index-chart-label" +
          (this.props.value === "N/A" ? " pcm-dashboard-index-chart-top-margin" : "")}
        >
          <label className="pcm-dashboard-index-chart-title"> {this.props.title} </label>
        </div>
        {this.props.value ?
          <div id={`${this.props.id}Value`} className="col-form-label pcm-dashboard-index-chart-label">
            <label className="pcm-dashboard-index-chart-value"> {this.props.value} </label>
          </div> : ""}
        {this.props.hideChart ? "" :
          <div id={this.props.id}
               className={"pcm-dashboard-index-chart-plot-area-minified" +
                 (this.props.titleBackgroundClassName ? " pcm-dashboard-index-chart-plot-area" : "")}
          />}
      </div>
    );
  }
}
